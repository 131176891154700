export const mockDataPositions = {
  "5C5GANtAKokcPvJBGyLcFgY5fYuQaXC3MpVt75codZbLLZrZ": {
    all_time_returns: 1.057805161979576,
    n_positions: 18,
    percentage_profitable: 0.7777777777777778,
    positions: [
      {
        average_entry_price: 0.59559,
        close_ms: 1714156813363,
        current_return: 1.0002165919508386,
        initial_entry_price: 0.59559,
        is_closed_position: true,
        miner_hotkey: "5C5GANtAKokcPvJBGyLcFgY5fYuQaXC3MpVt75codZbLLZrZ",
        net_leverage: 0.0,
        open_ms: 1714139478990,
        orders: [
          {
            leverage: -0.1,
            order_type: "SHORT",
            order_uuid: "18ca3cdf-b785-4f88-90a9-d2c06e8653b1",
            price: 0.59559,
            price_sources: [],
            processed_ms: 1714139478990,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "c902c428-fcfb-43ca-ab79-117c957dbbfa",
            price: 0.5943,
            price_sources: [],
            processed_ms: 1714156813363,
          },
        ],
        position_type: "FLAT",
        position_uuid: "1f3f427f-6cbe-497c-af11-2fbef2ca3c10",
        return_at_close: 1.0002095904346948,
        trade_pair: ["NZDUSD", "NZD/USD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.813702016141041,
        close_ms: 1714410508187,
        current_return: 1.0003094479070975,
        initial_entry_price: 0.813702016141041,
        is_closed_position: true,
        miner_hotkey: "5C5GANtAKokcPvJBGyLcFgY5fYuQaXC3MpVt75codZbLLZrZ",
        net_leverage: 0.0,
        open_ms: 1714139450596,
        orders: [
          {
            leverage: 0.1,
            order_type: "LONG",
            order_uuid: "6159c854-9598-4497-a29c-2693c7a00afb",
            price: 0.813702016141041,
            price_sources: [],
            processed_ms: 1714139450595,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "e6feafdf-2683-4a48-befa-fd49d86a1742",
            price: 0.81622,
            price_sources: [],
            processed_ms: 1714410508187,
          },
        ],
        position_type: "FLAT",
        position_uuid: "cf3365d8-c4f8-4ae5-810f-aa5f7c4052b8",
        return_at_close: 1.0003024457409622,
        trade_pair: ["NZDCAD", "NZD/CAD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 1.46429,
        close_ms: 1714463421646,
        current_return: 1.0001349459464997,
        initial_entry_price: 1.46429,
        is_closed_position: true,
        miner_hotkey: "5C5GANtAKokcPvJBGyLcFgY5fYuQaXC3MpVt75codZbLLZrZ",
        net_leverage: 0.0,
        open_ms: 1714410647446,
        orders: [
          {
            leverage: 0.13,
            order_type: "LONG",
            order_uuid: "d523ae09-0def-44ab-9714-bd9888e4922d",
            price: 1.46429,
            price_sources: [],
            processed_ms: 1714410647445,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "176891b4-74d7-4b29-a9b6-6091c08a71bd",
            price: 1.46581,
            price_sources: [],
            processed_ms: 1714463421646,
          },
        ],
        position_type: "FLAT",
        position_uuid: "ebdded07-fdce-496a-a509-2994d3ae048d",
        return_at_close: 1.0001258447184915,
        trade_pair: ["EURCAD", "EUR/CAD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 1.07281,
        close_ms: 1714463436883,
        current_return: 1.0002678013814188,
        initial_entry_price: 1.07281,
        is_closed_position: true,
        miner_hotkey: "5C5GANtAKokcPvJBGyLcFgY5fYuQaXC3MpVt75codZbLLZrZ",
        net_leverage: 0.0,
        open_ms: 1714410716091,
        orders: [
          {
            leverage: -0.13,
            order_type: "SHORT",
            order_uuid: "a62d5579-7107-44c0-a609-7bdc80278c13",
            price: 1.07281,
            price_sources: [],
            processed_ms: 1714410716088,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "50463a60-3071-4261-a53f-46eba9cf5eb2",
            price: 1.0706,
            price_sources: [],
            processed_ms: 1714463436883,
          },
        ],
        position_type: "FLAT",
        position_uuid: "b8acaa40-5f0d-46a8-8c06-440714a0ee35",
        return_at_close: 1.0002586989444262,
        trade_pair: ["EURUSD", "EUR/USD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 167.108,
        close_ms: 1714463453273,
        current_return: 1.000421882854202,
        initial_entry_price: 167.108,
        is_closed_position: true,
        miner_hotkey: "5C5GANtAKokcPvJBGyLcFgY5fYuQaXC3MpVt75codZbLLZrZ",
        net_leverage: 0.0,
        open_ms: 1714410683162,
        orders: [
          {
            leverage: 0.1,
            order_type: "LONG",
            order_uuid: "7c13fff0-cb30-4409-bdde-d4ad37b506da",
            price: 167.108,
            price_sources: [],
            processed_ms: 1714410683160,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "fc1a9811-826c-4787-b58a-ab176d023ec2",
            price: 167.813,
            price_sources: [],
            processed_ms: 1714463453273,
          },
        ],
        position_type: "FLAT",
        position_uuid: "2234664a-9a9d-4f87-b3d4-32d9ace92592",
        return_at_close: 1.000414879901022,
        trade_pair: ["EURJPY", "EUR/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 102.37,
        close_ms: 1714486815295,
        current_return: 1.0001460388785777,
        initial_entry_price: 102.37,
        is_closed_position: true,
        miner_hotkey: "5C5GANtAKokcPvJBGyLcFgY5fYuQaXC3MpVt75codZbLLZrZ",
        net_leverage: 0.0,
        open_ms: 1714463575641,
        orders: [
          {
            leverage: -0.13,
            order_type: "SHORT",
            order_uuid: "f091baf6-0e49-48e9-959d-3dd4ae2ab0cd",
            price: 102.37,
            price_sources: [],
            processed_ms: 1714463575640,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "ff400bb8-001d-4de6-ae8f-b89177d0654d",
            price: 102.255,
            price_sources: [],
            processed_ms: 1714486815295,
          },
        ],
        position_type: "FLAT",
        position_uuid: "5af39d60-458b-4ffd-8a98-6dffd5ba7307",
        return_at_close: 1.0001369375496239,
        trade_pair: ["AUDJPY", "AUD/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.65288,
        close_ms: 1714630762348,
        current_return: 1.000268808969489,
        initial_entry_price: 0.65288,
        is_closed_position: true,
        miner_hotkey: "5C5GANtAKokcPvJBGyLcFgY5fYuQaXC3MpVt75codZbLLZrZ",
        net_leverage: 0.0,
        open_ms: 1714463609221,
        orders: [
          {
            leverage: 0.13,
            order_type: "LONG",
            order_uuid: "f5d6dc88-f5d0-47fb-9854-b1d007633ae4",
            price: 0.65288,
            price_sources: [],
            processed_ms: 1714463609219,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "a3c8ad31-4d31-4102-9165-8df1803f6ffe",
            price: 0.65423,
            price_sources: [],
            processed_ms: 1714630762348,
          },
        ],
        position_type: "FLAT",
        position_uuid: "681edab9-ef70-461e-9a85-885674224134",
        return_at_close: 1.0002597065233274,
        trade_pair: ["AUDUSD", "AUD/USD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.6722993895297286,
        close_ms: 1714711929913,
        current_return: 1.0005061766914356,
        initial_entry_price: 0.89388,
        is_closed_position: true,
        miner_hotkey: "5C5GANtAKokcPvJBGyLcFgY5fYuQaXC3MpVt75codZbLLZrZ",
        net_leverage: 0.0,
        open_ms: 1714463558073,
        orders: [
          {
            leverage: 0.13,
            order_type: "LONG",
            order_uuid: "e80355f7-9b17-4f2d-a59a-e8425ea1335b",
            price: 0.89388,
            price_sources: [],
            processed_ms: 1714463558071,
          },
          {
            leverage: -0.128,
            order_type: "SHORT",
            order_uuid: "4bff27f1-583c-4ffd-954b-303127649ce6",
            price: 0.897342197038598,
            price_sources: [],
            processed_ms: 1714630804458,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "a0a39861-a001-400e-907f-f0923a9d0521",
            price: 0.89853,
            price_sources: [],
            processed_ms: 1714711929913,
          },
        ],
        position_type: "FLAT",
        position_uuid: "ff15f6f0-cb7f-4ec7-882b-13f2c20a475d",
        return_at_close: 1.0004970720852278,
        trade_pair: ["AUDCAD", "AUD/CAD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 1.2439799999999908,
        close_ms: 1714712966063,
        current_return: 1.0006174250448783,
        initial_entry_price: 1.79374,
        is_closed_position: true,
        miner_hotkey: "5C5GANtAKokcPvJBGyLcFgY5fYuQaXC3MpVt75codZbLLZrZ",
        net_leverage: 0.0,
        open_ms: 1714410699838,
        orders: [
          {
            leverage: 0.13,
            order_type: "LONG",
            order_uuid: "cdd51f0e-197c-4bb2-aece-186600012dae",
            price: 1.79374,
            price_sources: [],
            processed_ms: 1714410699837,
          },
          {
            leverage: -0.128,
            order_type: "SHORT",
            order_uuid: "27a2b737-d43b-46a1-990a-76563ce14d79",
            price: 1.80233,
            price_sources: [],
            processed_ms: 1714463493362,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "9f56e3e1-c239-41f8-9194-46ce857a6676",
            price: 1.79773,
            price_sources: [],
            processed_ms: 1714712966063,
          },
        ],
        position_type: "FLAT",
        position_uuid: "7c13a664-bdde-44e8-bd50-9fdc03c70271",
        return_at_close: 1.0006083194263105,
        trade_pair: ["EURNZD", "EUR/NZD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 1.1681714235397256,
        close_ms: 1714713002824,
        current_return: 1.0001214110980017,
        initial_entry_price: 1.099099252669842,
        is_closed_position: true,
        miner_hotkey: "5C5GANtAKokcPvJBGyLcFgY5fYuQaXC3MpVt75codZbLLZrZ",
        net_leverage: 0.0,
        open_ms: 1714463591973,
        orders: [
          {
            leverage: -0.13,
            order_type: "SHORT",
            order_uuid: "0a5017b6-6059-462b-a587-d74522c6aa2c",
            price: 1.099099252669842,
            price_sources: [],
            processed_ms: 1714463591972,
          },
          {
            leverage: 0.128,
            order_type: "LONG",
            order_uuid: "14cdc810-7f21-4627-a296-87009ccdbc08",
            price: 1.09802,
            price_sources: [],
            processed_ms: 1714486837025,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "7d07f4ef-6971-44de-bfa5-632115166bd4",
            price: 1.10145,
            price_sources: [],
            processed_ms: 1714713002824,
          },
        ],
        position_type: "FLAT",
        position_uuid: "85b4291f-0c17-4351-8b05-6d445246d2d1",
        return_at_close: 1.0001123099931608,
        trade_pair: ["AUDNZD", "AUD/NZD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 1.366475,
        close_ms: 1714755346985,
        current_return: 0.987207915285371,
        initial_entry_price: 1.36647,
        is_closed_position: true,
        miner_hotkey: "5C5GANtAKokcPvJBGyLcFgY5fYuQaXC3MpVt75codZbLLZrZ",
        net_leverage: 0.0,
        open_ms: 1714712130575,
        orders: [
          {
            leverage: -7.0,
            order_type: "SHORT",
            order_uuid: "be666634-2262-463b-aaba-ed106ff29287",
            price: 1.36647,
            price_sources: [],
            processed_ms: 1714712130400,
          },
          {
            leverage: -1.0,
            order_type: "SHORT",
            order_uuid: "e460109a-7099-4762-b7e4-81e327dba4d2",
            price: 1.36651,
            price_sources: [],
            processed_ms: 1714712155372,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "4cc04a58-7dac-418a-8513-4156afddd88d",
            price: 1.36866,
            price_sources: [],
            processed_ms: 1714755346985,
          },
        ],
        position_type: "FLAT",
        position_uuid: "6dada066-20b1-46d5-ab1a-18afb0ff8190",
        return_at_close: 0.9866550788528112,
        trade_pair: ["USDCAD", "USD/CAD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.85363,
        close_ms: 1714755366593,
        current_return: 0.9993253517331865,
        initial_entry_price: 0.85363,
        is_closed_position: true,
        miner_hotkey: "5C5GANtAKokcPvJBGyLcFgY5fYuQaXC3MpVt75codZbLLZrZ",
        net_leverage: 0.0,
        open_ms: 1714410668690,
        orders: [
          {
            leverage: -0.13,
            order_type: "SHORT",
            order_uuid: "08d4f740-16ff-4b17-bf7e-422bf9a99d44",
            price: 0.85363,
            price_sources: [],
            processed_ms: 1714410667706,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "1375ddfc-95ee-4cc2-bdcc-dea212cc1dc9",
            price: 0.85806,
            price_sources: [],
            processed_ms: 1714755366593,
          },
        ],
        position_type: "FLAT",
        position_uuid: "a79131ff-c25a-4e1a-91be-e5ca1ebc396a",
        return_at_close: 0.9993162578724857,
        trade_pair: ["EURGBP", "EUR/GBP", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.898195740765085,
        close_ms: 1714755394793,
        current_return: 1.057259545785064,
        initial_entry_price: 0.898195740765085,
        is_closed_position: true,
        miner_hotkey: "5C5GANtAKokcPvJBGyLcFgY5fYuQaXC3MpVt75codZbLLZrZ",
        net_leverage: 0.0,
        open_ms: 1714712093343,
        orders: [
          {
            leverage: 8.0,
            order_type: "LONG",
            order_uuid: "8057c9ef-3b92-48e1-9e82-6e3b4d0ce6cd",
            price: 0.898195740765085,
            price_sources: [],
            processed_ms: 1714712093170,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "6ce00bfa-9700-404f-828c-b5bde69cca16",
            price: 0.904624525782871,
            price_sources: [],
            processed_ms: 1714755394793,
          },
        ],
        position_type: "FLAT",
        position_uuid: "322ba5b1-ef6b-429a-913e-9300feeb1a66",
        return_at_close: 1.0566674804394243,
        trade_pair: ["AUDCAD", "AUD/CAD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 188058.03000000044,
        close_ms: 1714755548229,
        current_return: 1.0019965142623568,
        initial_entry_price: 63289.33,
        is_closed_position: true,
        miner_hotkey: "5C5GANtAKokcPvJBGyLcFgY5fYuQaXC3MpVt75codZbLLZrZ",
        net_leverage: 0.0,
        open_ms: 1714463540414,
        orders: [
          {
            leverage: -0.05,
            order_type: "SHORT",
            order_uuid: "e4df2ddc-af26-4c78-9c20-652fa8ca9134",
            price: 63289.33,
            price_sources: [],
            processed_ms: 1714463540412,
          },
          {
            leverage: 0.049,
            order_type: "LONG",
            order_uuid: "9fcf445c-41c1-45ac-970b-fa67fb4874fa",
            price: 60743.03,
            price_sources: [],
            processed_ms: 1714486782533,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "e7a79073-eeff-4824-a559-c3cc9ccca6bd",
            price: 61699.98,
            price_sources: [],
            processed_ms: 1714755548229,
          },
        ],
        position_type: "FLAT",
        position_uuid: "b36347f5-9db3-4c56-ad5e-c5ab75b39fab",
        return_at_close: 1.0019464144366437,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 164.52,
        close_ms: 1715186244958,
        current_return: 1.0000159251154874,
        initial_entry_price: 164.52,
        is_closed_position: true,
        miner_hotkey: "5C5GANtAKokcPvJBGyLcFgY5fYuQaXC3MpVt75codZbLLZrZ",
        net_leverage: 0.0,
        open_ms: 1714755576309,
        orders: [
          {
            leverage: 0.001,
            order_type: "LONG",
            order_uuid: "5657519e-b46f-49c2-9375-57633a17e813",
            price: 164.52,
            price_sources: [],
            processed_ms: 1714755576098,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "cfcd3dd9-3d92-4300-8ba6-d65a84a85bd2",
            price: 167.14,
            price_sources: [],
            processed_ms: 1715186244958,
          },
        ],
        position_type: "FLAT",
        position_uuid: "321575c0-0852-4375-98ed-a6b52cbef3b6",
        return_at_close: 1.0000158551143727,
        trade_pair: ["EURJPY", "EUR/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 65744.60209999999,
        close_ms: 1718408064283,
        current_return: 1.0795529481255985,
        initial_entry_price: 67685.21,
        is_closed_position: true,
        miner_hotkey: "5C5GANtAKokcPvJBGyLcFgY5fYuQaXC3MpVt75codZbLLZrZ",
        net_leverage: 0.0,
        open_ms: 1717271430282,
        orders: [
          {
            leverage: 0.2,
            order_type: "LONG",
            order_uuid: "7aa4538d-8e99-4af3-b99f-ec17dde2ff31",
            price: 67685.21,
            price_sources: [],
            processed_ms: 1717271430090,
          },
          {
            leverage: 19.8,
            order_type: "LONG",
            order_uuid: "323cb7a9-d8bf-4e86-98ad-9b992680baa4",
            price: 65725.0,
            price_sources: [],
            processed_ms: 1718398576985,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "5e7a2913-5374-4140-acd1-3eafcbedd1bf",
            price: 66013.83,
            price_sources: [],
            processed_ms: 1718408064283,
          },
        ],
        position_type: "FLAT",
        position_uuid: "a30e5668-0a85-449d-9b5a-a4ebd9c17bc4",
        return_at_close: 1.0579618891630866,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 3103.35,
        close_ms: 1719411028066,
        current_return: 0.9998160955096912,
        initial_entry_price: 3103.35,
        is_closed_position: true,
        miner_hotkey: "5C5GANtAKokcPvJBGyLcFgY5fYuQaXC3MpVt75codZbLLZrZ",
        net_leverage: 0.0,
        open_ms: 1714815734684,
        orders: [
          {
            leverage: -0.002,
            order_type: "SHORT",
            order_uuid: "7cfd3a46-3791-49d0-aba4-b1426c486895",
            price: 3103.35,
            price_sources: [],
            processed_ms: 1714815734680,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "7be198e6-42fe-42ff-acb8-cffe8f095370",
            price: 3388.71,
            price_sources: [],
            processed_ms: 1719411028066,
          },
        ],
        position_type: "FLAT",
        position_uuid: "7fa3e199-7371-4684-8a2f-312fa6232c89",
        return_at_close: 0.9998140958775003,
        trade_pair: ["ETHUSD", "ETH/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: -28.439049999990747,
        close_ms: 1719411046138,
        current_return: 0.955721207972006,
        initial_entry_price: 0.6573,
        is_closed_position: true,
        miner_hotkey: "5C5GANtAKokcPvJBGyLcFgY5fYuQaXC3MpVt75codZbLLZrZ",
        net_leverage: 0.0,
        open_ms: 1714712114634,
        orders: [
          {
            leverage: -7.0,
            order_type: "SHORT",
            order_uuid: "c4bfe147-b82d-4f58-8a4d-bb2710a7aab3",
            price: 0.6573,
            price_sources: [],
            processed_ms: 1714712114363,
          },
          {
            leverage: -1.0,
            order_type: "SHORT",
            order_uuid: "adeeb93a-eccc-4270-95b2-62d03c7d852e",
            price: 0.6574,
            price_sources: [],
            processed_ms: 1714712186475,
          },
          {
            leverage: 7.999,
            order_type: "LONG",
            order_uuid: "d5050feb-ccb3-4280-8e93-41c067a84534",
            price: 0.66095,
            price_sources: [],
            processed_ms: 1714755412493,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "0d6cc170-7948-4ec2-83d0-0697b71eb1cb",
            price: 0.6654,
            price_sources: [],
            processed_ms: 1719411046138,
          },
        ],
        position_type: "FLAT",
        position_uuid: "cc746113-00c8-4157-a1e6-8fcb880171e5",
        return_at_close: 0.9551860040955417,
        trade_pair: ["AUDUSD", "AUD/USD", 7e-5, 0.001, 500],
      },
    ],
    thirty_day_returns: 1.0,
  },
  "5C8Wegdus2cAcwSNU47MdiLXwZdewFkSv93xUWQP3wn32QJV": {
    all_time_returns: 1.0257249344995512,
    n_positions: 23,
    percentage_profitable: 0.7391304347826086,
    positions: [
      {
        average_entry_price: 158.115,
        close_ms: 1718725762968,
        current_return: 1.0011194383834552,
        initial_entry_price: 158.115,
        is_closed_position: true,
        miner_hotkey: "5C8Wegdus2cAcwSNU47MdiLXwZdewFkSv93xUWQP3wn32QJV",
        net_leverage: 0.0,
        open_ms: 1718701575398,
        orders: [
          {
            leverage: -0.5,
            order_type: "SHORT",
            order_uuid: "f77ea066-f3a9-4341-b21c-57768a5a9cdc",
            price: 158.115,
            price_sources: [],
            processed_ms: 1718701575387,
          },
          {
            leverage: 0.01,
            order_type: "FLAT",
            order_uuid: "e469709c-5071-4519-af51-773238b0067a",
            price: 157.761,
            price_sources: [],
            processed_ms: 1718725762968,
          },
        ],
        position_type: "FLAT",
        position_uuid: "d3a8148d-d78d-402f-a14d-614d9c3403c9",
        return_at_close: 1.0010843992031118,
        trade_pair: ["USDJPY", "USD/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 169.513,
        close_ms: 1718725796015,
        current_return: 1.0003421566487527,
        initial_entry_price: 169.513,
        is_closed_position: true,
        miner_hotkey: "5C8Wegdus2cAcwSNU47MdiLXwZdewFkSv93xUWQP3wn32QJV",
        net_leverage: 0.0,
        open_ms: 1718701130638,
        orders: [
          {
            leverage: -0.5,
            order_type: "SHORT",
            order_uuid: "012ca220-8dac-487a-b4b7-ce9a17fc159b",
            price: 169.513,
            price_sources: [],
            processed_ms: 1718701130636,
          },
          {
            leverage: 0.01,
            order_type: "FLAT",
            order_uuid: "681b6b5a-3f40-45f2-9981-7de7ead5fc79",
            price: 169.397,
            price_sources: [],
            processed_ms: 1718725796015,
          },
        ],
        position_type: "FLAT",
        position_uuid: "8a4e70b6-5e9f-4fae-a0da-42e9bc715329",
        return_at_close: 1.00030714467327,
        trade_pair: ["EURJPY", "EUR/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 65321.0,
        close_ms: 1718863476851,
        current_return: 1.0001514444053214,
        initial_entry_price: 65321.0,
        is_closed_position: true,
        miner_hotkey: "5C8Wegdus2cAcwSNU47MdiLXwZdewFkSv93xUWQP3wn32QJV",
        net_leverage: 0.0,
        open_ms: 1718796269417,
        orders: [
          {
            leverage: 0.05,
            order_type: "LONG",
            order_uuid: "6d0617da-e5bd-4346-bd10-cef30c051b51",
            price: 65321.0,
            price_sources: [],
            processed_ms: 1718796269405,
          },
          {
            leverage: 0.01,
            order_type: "FLAT",
            order_uuid: "d620325a-e016-4940-ac5c-e9d34243b362",
            price: 65518.85,
            price_sources: [],
            processed_ms: 1718863476851,
          },
        ],
        position_type: "FLAT",
        position_uuid: "be24c486-0a60-4278-84ca-913408a92e3b",
        return_at_close: 1.0001014368331012,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 0.94865,
        close_ms: 1718871836474,
        current_return: 1.0054393084910136,
        initial_entry_price: 0.94865,
        is_closed_position: true,
        miner_hotkey: "5C8Wegdus2cAcwSNU47MdiLXwZdewFkSv93xUWQP3wn32QJV",
        net_leverage: 0.0,
        open_ms: 1718863554227,
        orders: [
          {
            leverage: 1.0,
            order_type: "LONG",
            order_uuid: "0d4c470d-7d7d-436a-9ad2-a4b7910de7d8",
            price: 0.94865,
            price_sources: [],
            processed_ms: 1718863554196,
          },
          {
            leverage: 0.01,
            order_type: "FLAT",
            order_uuid: "3052baab-b23e-4857-aca6-cf2dac4c9592",
            price: 0.95381,
            price_sources: [],
            processed_ms: 1718871836474,
          },
        ],
        position_type: "FLAT",
        position_uuid: "751528a5-0fed-49ce-bae3-9164ef8bcd54",
        return_at_close: 1.005368927739419,
        trade_pair: ["EURCHF", "EUR/CHF", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 3543.47,
        close_ms: 1718871899777,
        current_return: 1.00078891594962,
        initial_entry_price: 3543.47,
        is_closed_position: true,
        miner_hotkey: "5C8Wegdus2cAcwSNU47MdiLXwZdewFkSv93xUWQP3wn32QJV",
        net_leverage: 0.0,
        open_ms: 1718796239534,
        orders: [
          {
            leverage: 0.05,
            order_type: "LONG",
            order_uuid: "2214deea-414f-473a-aefd-9f3e5e41d1d0",
            price: 3543.47,
            price_sources: [],
            processed_ms: 1718796238796,
          },
          {
            leverage: 0.01,
            order_type: "FLAT",
            order_uuid: "24460901-3aed-4afb-94f7-bc8a808fa04e",
            price: 3599.38,
            price_sources: [],
            processed_ms: 1718871899777,
          },
        ],
        position_type: "FLAT",
        position_uuid: "7dcc6842-3fdb-46fc-a658-8a8364eedb6b",
        return_at_close: 1.0007388765038225,
        trade_pair: ["ETHUSD", "ETH/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 1.07176,
        close_ms: 1718871961080,
        current_return: 1.0001399567067253,
        initial_entry_price: 1.07176,
        is_closed_position: true,
        miner_hotkey: "5C8Wegdus2cAcwSNU47MdiLXwZdewFkSv93xUWQP3wn32QJV",
        net_leverage: 0.0,
        open_ms: 1718701362918,
        orders: [
          {
            leverage: -0.5,
            order_type: "SHORT",
            order_uuid: "edbffb70-a7eb-47a2-8f6c-26a3585eb85a",
            price: 1.07176,
            price_sources: [],
            processed_ms: 1718701362553,
          },
          {
            leverage: 0.01,
            order_type: "FLAT",
            order_uuid: "0fa65ea5-e355-4dac-a63b-fad7d232fab9",
            price: 1.07146,
            price_sources: [],
            processed_ms: 1718871961080,
          },
        ],
        position_type: "FLAT",
        position_uuid: "564a5af7-0a38-4ca1-b769-0065bca44943",
        return_at_close: 1.0001049518082405,
        trade_pair: ["EURUSD", "EUR/USD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 3507.28,
        close_ms: 1718950345772,
        current_return: 0.9993378059350836,
        initial_entry_price: 3507.28,
        is_closed_position: true,
        miner_hotkey: "5C8Wegdus2cAcwSNU47MdiLXwZdewFkSv93xUWQP3wn32QJV",
        net_leverage: 0.0,
        open_ms: 1718892795301,
        orders: [
          {
            leverage: -0.25,
            order_type: "SHORT",
            order_uuid: "366ee29f-50a6-4cb0-bd15-0b698c27946a",
            price: 3507.28,
            price_sources: [],
            processed_ms: 1718892794290,
          },
          {
            leverage: 0.01,
            order_type: "FLAT",
            order_uuid: "8d02a4e1-b657-4eab-a72c-9e32da0391c1",
            price: 3516.57,
            price_sources: [],
            processed_ms: 1718950345772,
          },
        ],
        position_type: "FLAT",
        position_uuid: "f9866252-9043-45ff-bcd9-572ba43dad97",
        return_at_close: 0.9990879714835998,
        trade_pair: ["ETHUSD", "ETH/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 64837.69,
        close_ms: 1718950350268,
        current_return: 1.000681278126966,
        initial_entry_price: 64837.69,
        is_closed_position: true,
        miner_hotkey: "5C8Wegdus2cAcwSNU47MdiLXwZdewFkSv93xUWQP3wn32QJV",
        net_leverage: 0.0,
        open_ms: 1718897573084,
        orders: [
          {
            leverage: -0.25,
            order_type: "SHORT",
            order_uuid: "189ea442-969e-494e-a251-eec6d508ae99",
            price: 64837.69,
            price_sources: [],
            processed_ms: 1718897572281,
          },
          {
            leverage: 0.01,
            order_type: "FLAT",
            order_uuid: "fa215dea-e65b-4543-ac0d-598d58d953e4",
            price: 64661.0,
            price_sources: [],
            processed_ms: 1718950350268,
          },
        ],
        position_type: "FLAT",
        position_uuid: "915d7548-53ba-4f30-8cae-983c38bdeef1",
        return_at_close: 1.0004311078074344,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 1.264635,
        close_ms: 1718955589806,
        current_return: 1.0002648985675708,
        initial_entry_price: 1.264635,
        is_closed_position: true,
        miner_hotkey: "5C8Wegdus2cAcwSNU47MdiLXwZdewFkSv93xUWQP3wn32QJV",
        net_leverage: 0.0,
        open_ms: 1718954126252,
        orders: [
          {
            leverage: -1.0,
            order_type: "SHORT",
            order_uuid: "b18d4285-3550-483a-9e59-bd63eb2a643b",
            price: 1.264635,
            price_sources: [],
            processed_ms: 1718954126237,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "06f14542-4f87-47b6-be32-b31ac48c14d0",
            price: 1.2643,
            price_sources: [],
            processed_ms: 1718955589806,
          },
        ],
        position_type: "FLAT",
        position_uuid: "3ba2b062-dc37-4dac-a60c-63ac4e5030a9",
        return_at_close: 1.0001948800246712,
        trade_pair: ["GBPUSD", "GBP/USD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 115.9785,
        close_ms: 1718956638543,
        current_return: 1.0002155571937903,
        initial_entry_price: 115.9785,
        is_closed_position: true,
        miner_hotkey: "5C8Wegdus2cAcwSNU47MdiLXwZdewFkSv93xUWQP3wn32QJV",
        net_leverage: 0.0,
        open_ms: 1718907849587,
        orders: [
          {
            leverage: -1.0,
            order_type: "SHORT",
            order_uuid: "d90cbb01-aa92-4c8a-adb7-fb2ab54d3a14",
            price: 115.9785,
            price_sources: [],
            processed_ms: 1718907849573,
          },
          {
            leverage: 0.01,
            order_type: "FLAT",
            order_uuid: "488bf5cb-b04c-4b55-8c20-c6c7a1b4399d",
            price: 115.95349999999999,
            price_sources: [],
            processed_ms: 1718956638543,
          },
        ],
        position_type: "FLAT",
        position_uuid: "db26dba6-aaec-4db7-a810-5f5bdeb4863c",
        return_at_close: 1.0001455421047867,
        trade_pair: ["CADJPY", "CAD/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 64319.79,
        close_ms: 1719160268770,
        current_return: 1.0009467148446847,
        initial_entry_price: 64319.79,
        is_closed_position: true,
        miner_hotkey: "5C8Wegdus2cAcwSNU47MdiLXwZdewFkSv93xUWQP3wn32QJV",
        net_leverage: 0.0,
        open_ms: 1719044394070,
        orders: [
          {
            leverage: -0.25,
            order_type: "SHORT",
            order_uuid: "f404e78e-e642-4cec-a448-b4044cce4874",
            price: 64319.79,
            price_sources: [],
            processed_ms: 1719044392332,
          },
          {
            leverage: 0.01,
            order_type: "FLAT",
            order_uuid: "816abfb6-560a-41d4-892a-36c4268e7b3a",
            price: 64076.22,
            price_sources: [],
            processed_ms: 1719160268770,
          },
        ],
        position_type: "FLAT",
        position_uuid: "418ef735-666e-4d3f-8f45-92706c567958",
        return_at_close: 1.0006964781659735,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 3480.5,
        close_ms: 1719160299467,
        current_return: 1.0002772590145095,
        initial_entry_price: 3480.5,
        is_closed_position: true,
        miner_hotkey: "5C8Wegdus2cAcwSNU47MdiLXwZdewFkSv93xUWQP3wn32QJV",
        net_leverage: 0.0,
        open_ms: 1719044349607,
        orders: [
          {
            leverage: -0.25,
            order_type: "SHORT",
            order_uuid: "b734b3be-20d3-4642-bb30-3ab3faa5444d",
            price: 3480.5,
            price_sources: [],
            processed_ms: 1719044347604,
          },
          {
            leverage: 0.01,
            order_type: "FLAT",
            order_uuid: "726662dc-40ee-4c82-afab-d2c6e225425d",
            price: 3476.64,
            price_sources: [],
            processed_ms: 1719160299467,
          },
        ],
        position_type: "FLAT",
        position_uuid: "bbf7de9f-8390-4001-95b8-659034f95f52",
        return_at_close: 1.000027189699756,
        trade_pair: ["ETHUSD", "ETH/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 1.0712,
        close_ms: 1719216072165,
        current_return: 0.9998319641523526,
        initial_entry_price: 1.0712,
        is_closed_position: true,
        miner_hotkey: "5C8Wegdus2cAcwSNU47MdiLXwZdewFkSv93xUWQP3wn32QJV",
        net_leverage: 0.0,
        open_ms: 1719215959934,
        orders: [
          {
            leverage: 2.0,
            order_type: "LONG",
            order_uuid: "ec66e67c-a470-40a9-9b68-a66afc29cf68",
            price: 1.0712,
            price_sources: [],
            processed_ms: 1719215959915,
          },
          {
            leverage: 2.0,
            order_type: "FLAT",
            order_uuid: "261783b9-6031-419e-b1a8-51416a585c7e",
            price: 1.07111,
            price_sources: [],
            processed_ms: 1719216072165,
          },
        ],
        position_type: "FLAT",
        position_uuid: "cd1e6ba8-1b0c-44f5-acff-6c48e128ac73",
        return_at_close: 0.9996919876773713,
        trade_pair: ["EURUSD", "EUR/USD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 3372.92,
        close_ms: 1719319736444,
        current_return: 1.000253489558009,
        initial_entry_price: 3372.92,
        is_closed_position: true,
        miner_hotkey: "5C8Wegdus2cAcwSNU47MdiLXwZdewFkSv93xUWQP3wn32QJV",
        net_leverage: 0.0,
        open_ms: 1719294530946,
        orders: [
          {
            leverage: -0.25,
            order_type: "SHORT",
            order_uuid: "db98fc65-2f58-4218-afc2-bf82804162c2",
            price: 3372.92,
            price_sources: [],
            processed_ms: 1719294530934,
          },
          {
            leverage: 2.0,
            order_type: "FLAT",
            order_uuid: "5b21f9f5-04f1-4b59-bcd6-122a3b1d0b16",
            price: 3369.5,
            price_sources: [],
            processed_ms: 1719319736444,
          },
        ],
        position_type: "FLAT",
        position_uuid: "a9d3ad82-d751-4bb4-b022-b7573aa460ef",
        return_at_close: 1.0000034261856197,
        trade_pair: ["ETHUSD", "ETH/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 1.36927,
        close_ms: 1719338419585,
        current_return: 0.9956454496594237,
        initial_entry_price: 1.367535,
        is_closed_position: true,
        miner_hotkey: "5C8Wegdus2cAcwSNU47MdiLXwZdewFkSv93xUWQP3wn32QJV",
        net_leverage: 0.0,
        open_ms: 1719318595687,
        orders: [
          {
            leverage: 3.0,
            order_type: "LONG",
            order_uuid: "49816d12-d842-4f33-83a7-f2f484cc9f7b",
            price: 1.367535,
            price_sources: [],
            processed_ms: 1719318595584,
          },
          {
            leverage: -1.5,
            order_type: "SHORT",
            order_uuid: "ec846e59-8f41-4050-a6ef-0d01474a2ec1",
            price: 1.3658,
            price_sources: [],
            processed_ms: 1719319814172,
          },
          {
            leverage: 1.5,
            order_type: "FLAT",
            order_uuid: "504a1142-5982-4578-a0b3-ecaead21cc5e",
            price: 1.3653,
            price_sources: [],
            processed_ms: 1719338419585,
          },
        ],
        position_type: "FLAT",
        position_uuid: "ba97db05-05c0-491a-a170-fbf8f0af2eff",
        return_at_close: 0.9954363641149951,
        trade_pair: ["USDCAD", "USD/CAD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 156.72949999999997,
        close_ms: 1719957772246,
        current_return: 0.9851231358316862,
        initial_entry_price: 157.6105,
        is_closed_position: true,
        miner_hotkey: "5C8Wegdus2cAcwSNU47MdiLXwZdewFkSv93xUWQP3wn32QJV",
        net_leverage: 0.0,
        open_ms: 1718780454954,
        orders: [
          {
            leverage: -0.5,
            order_type: "SHORT",
            order_uuid: "563180fa-d203-440a-8603-ff55fe2199c6",
            price: 157.6105,
            price_sources: [],
            processed_ms: 1718780454943,
          },
          {
            leverage: -0.5,
            order_type: "SHORT",
            order_uuid: "e85bbcb2-822f-424f-8591-e6d743dd5be4",
            price: 158.78,
            price_sources: [],
            processed_ms: 1718956724420,
          },
          {
            leverage: 0.5,
            order_type: "LONG",
            order_uuid: "53934999-d615-448a-ae05-b6da796e5f64",
            price: 159.661,
            price_sources: [],
            processed_ms: 1719319869593,
          },
          {
            leverage: 0.01,
            order_type: "FLAT",
            order_uuid: "147f9ade-5686-4f75-b533-89d22815c8a2",
            price: 161.419,
            price_sources: [],
            processed_ms: 1719957772246,
          },
        ],
        position_type: "FLAT",
        position_uuid: "b08ee6de-caa9-482a-8180-897ae43c2ce1",
        return_at_close: 0.9850541772121779,
        trade_pair: ["USDJPY", "USD/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 169.42849999999999,
        close_ms: 1719957802951,
        current_return: 0.9759457382669346,
        initial_entry_price: 169.18,
        is_closed_position: true,
        miner_hotkey: "5C8Wegdus2cAcwSNU47MdiLXwZdewFkSv93xUWQP3wn32QJV",
        net_leverage: 0.0,
        open_ms: 1718780363644,
        orders: [
          {
            leverage: -0.5,
            order_type: "SHORT",
            order_uuid: "b3ba865f-2102-4f86-886d-c31112fe4709",
            price: 169.18,
            price_sources: [],
            processed_ms: 1718780363632,
          },
          {
            leverage: -0.5,
            order_type: "SHORT",
            order_uuid: "091af587-116d-4cb3-857a-945ded82c2eb",
            price: 169.677,
            price_sources: [],
            processed_ms: 1718871997000,
          },
          {
            leverage: 0.01,
            order_type: "FLAT",
            order_uuid: "d656650c-68de-49b5-80e1-285ea865492c",
            price: 173.498,
            price_sources: [],
            processed_ms: 1719957802951,
          },
        ],
        position_type: "FLAT",
        position_uuid: "b85754a0-2079-41b5-be71-bdb9abd89a81",
        return_at_close: 0.975877422065256,
        trade_pair: ["EURJPY", "EUR/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 96.87049999999999,
        close_ms: 1719957836324,
        current_return: 0.9871534645775296,
        initial_entry_price: 96.563,
        is_closed_position: true,
        miner_hotkey: "5C8Wegdus2cAcwSNU47MdiLXwZdewFkSv93xUWQP3wn32QJV",
        net_leverage: 0.0,
        open_ms: 1718701171294,
        orders: [
          {
            leverage: -0.5,
            order_type: "SHORT",
            order_uuid: "65f6893d-6c0c-4972-8eb9-debd740c274c",
            price: 96.563,
            price_sources: [],
            processed_ms: 1718701171288,
          },
          {
            leverage: -0.5,
            order_type: "SHORT",
            order_uuid: "83b642f3-3441-4124-8647-2095f2f74ebf",
            price: 97.178,
            price_sources: [],
            processed_ms: 1718956689400,
          },
          {
            leverage: 0.01,
            order_type: "FLAT",
            order_uuid: "a5092441-95a3-4dc7-8eb5-057a09750b88",
            price: 98.111,
            price_sources: [],
            processed_ms: 1719957836324,
          },
        ],
        position_type: "FLAT",
        position_uuid: "57745eff-6ad8-4a98-a2ce-220ac93ae17d",
        return_at_close: 0.9870843638350092,
        trade_pair: ["NZDJPY", "NZD/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 3423.6,
        close_ms: 1720078156183,
        current_return: 1.0686966935389648,
        initial_entry_price: 3423.6,
        is_closed_position: true,
        miner_hotkey: "5C8Wegdus2cAcwSNU47MdiLXwZdewFkSv93xUWQP3wn32QJV",
        net_leverage: 0.0,
        open_ms: 1719957840392,
        orders: [
          {
            leverage: -1.0,
            order_type: "SHORT",
            order_uuid: "7515e1aa-ace6-42ba-bcfc-433b3d81e5e9",
            price: 3423.6,
            price_sources: [],
            processed_ms: 1719957840371,
          },
          {
            leverage: 0.01,
            order_type: "FLAT",
            order_uuid: "63abf803-980f-4305-9092-35178a336ba5",
            price: 3188.41,
            price_sources: [
              {
                close: 3188.41,
                high: 3188.46,
                lag_ms: 184,
                low: 3188.41,
                open: 3188.41,
                source: "Polygon_ws",
                start_ms: 1720078155999,
                timespan_ms: 0,
                volume: 0.15850314,
                vwap: 3188.41,
                websocket: true,
              },
              {
                close: 3190.91,
                high: 3190.91,
                lag_ms: 817,
                low: 3190.91,
                open: 3190.91,
                source: "TwelveData_ws",
                start_ms: 1720078157000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
              {
                close: 3188.9,
                high: 3189.03,
                lag_ms: 2184,
                low: 3187.35,
                open: 3187.35,
                source: "Polygon_rest",
                start_ms: 1720078153000,
                timespan_ms: 1000,
                volume: 0.04341142,
                vwap: 3188.8938,
                websocket: false,
              },
              {
                close: 3193.98999,
                high: 3194.34009,
                lag_ms: 256184,
                low: 3192.22998,
                open: 3192.22998,
                source: "TwelveData_rest",
                start_ms: 1720077840000,
                timespan_ms: 60000,
                volume: null,
                vwap: null,
                websocket: false,
              },
            ],
            processed_ms: 1720078156183,
          },
        ],
        position_type: "FLAT",
        position_uuid: "9b9a5110-e3ce-4474-bb16-a37ae48c5fd0",
        return_at_close: 1.0676279968454259,
        trade_pair: ["ETHUSD", "ETH/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 58363.425,
        close_ms: 1720086650662,
        current_return: 1.0055242729066565,
        initial_entry_price: 58417.19,
        is_closed_position: true,
        miner_hotkey: "5C8Wegdus2cAcwSNU47MdiLXwZdewFkSv93xUWQP3wn32QJV",
        net_leverage: 0.0,
        open_ms: 1720078226002,
        orders: [
          {
            leverage: -0.25,
            order_type: "SHORT",
            order_uuid: "c647efbe-377b-4f80-bf94-1c785393e5ce",
            price: 58417.19,
            price_sources: [
              {
                close: 58417.19,
                high: 58427.87,
                lag_ms: 79,
                low: 58415.08,
                open: 58417.19,
                source: "Polygon_ws",
                start_ms: 1720078223999,
                timespan_ms: 0,
                volume: 0.73394786,
                vwap: 58417.2598,
                websocket: true,
              },
              {
                close: 58412.26,
                high: 58451.2,
                lag_ms: 2921,
                low: 58412.26,
                open: 58416.87,
                source: "Polygon_rest",
                start_ms: 1720078220000,
                timespan_ms: 1000,
                volume: 0.02540061,
                vwap: 58443.6284,
                websocket: false,
              },
              {
                close: 58473.6,
                high: 58473.6,
                lag_ms: 7920,
                low: 58473.6,
                open: 58473.6,
                source: "TwelveData_ws",
                start_ms: 1720078216000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
              {
                close: 58457.05,
                high: 58543.23,
                lag_ms: 83921,
                low: 58423.51,
                open: 58542.55,
                source: "TwelveData_rest",
                start_ms: 1720078080000,
                timespan_ms: 60000,
                volume: null,
                vwap: null,
                websocket: false,
              },
            ],
            processed_ms: 1720078223920,
          },
          {
            leverage: -0.25,
            order_type: "SHORT",
            order_uuid: "c0d79a0f-02c2-40d9-8104-f3b140d104f5",
            price: 58309.66,
            price_sources: [
              {
                close: 58309.66,
                high: 58309.66,
                lag_ms: 1,
                low: 58309.66,
                open: 58309.66,
                source: "Polygon_ws",
                start_ms: 1720079521999,
                timespan_ms: 0,
                volume: 0.25455447,
                vwap: 58309.66,
                websocket: true,
              },
              {
                close: 58309.67,
                high: 58309.67,
                lag_ms: 2001,
                low: 58309.67,
                open: 58309.67,
                source: "Polygon_rest",
                start_ms: 1720079519000,
                timespan_ms: 1000,
                volume: 0.00043311,
                vwap: 58309.67,
                websocket: false,
              },
              {
                close: 58309.4,
                high: 58309.4,
                lag_ms: 3000,
                low: 58309.4,
                open: 58309.4,
                source: "TwelveData_ws",
                start_ms: 1720079525000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
              {
                close: 58257.76,
                high: 58340.46,
                lag_ms: 62001,
                low: 58242.16,
                open: 58328.01,
                source: "TwelveData_rest",
                start_ms: 1720079400000,
                timespan_ms: 60000,
                volume: null,
                vwap: null,
                websocket: false,
              },
            ],
            processed_ms: 1720079522000,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "90296a37-4149-4463-8dec-6336a94acaf4",
            price: 57718.0,
            price_sources: [
              {
                close: 57718.0,
                high: 57731.0,
                lag_ms: 337,
                low: 57647.0,
                open: 57718.0,
                source: "Polygon_ws",
                start_ms: 1720086650999,
                timespan_ms: 0,
                volume: 0.60721355,
                vwap: 57690.5071,
                websocket: true,
              },
              {
                close: 57662.5,
                high: 57662.5,
                lag_ms: 1338,
                low: 57662.5,
                open: 57662.5,
                source: "TwelveData_ws",
                start_ms: 1720086652000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
              {
                close: 57647.7,
                high: 57677.1,
                lag_ms: 2663,
                low: 57647.6,
                open: 57677.1,
                source: "Polygon_rest",
                start_ms: 1720086647000,
                timespan_ms: 1000,
                volume: 0.29359032,
                vwap: 57648.4044,
                websocket: false,
              },
              {
                close: 57707.97,
                high: 57721.39,
                lag_ms: 50663,
                low: 57675.49,
                open: 57692.25,
                source: "TwelveData_rest",
                start_ms: 1720086540000,
                timespan_ms: 60000,
                volume: null,
                vwap: null,
                websocket: false,
              },
            ],
            processed_ms: 1720086650662,
          },
        ],
        position_type: "FLAT",
        position_uuid: "b9c2c612-f3e3-41ef-b8c4-04e033eec791",
        return_at_close: 1.0050215107702032,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 1.47379,
        close_ms: 1720416775071,
        current_return: 1.0009295761268566,
        initial_entry_price: 1.47379,
        is_closed_position: true,
        miner_hotkey: "5C8Wegdus2cAcwSNU47MdiLXwZdewFkSv93xUWQP3wn32QJV",
        net_leverage: 0.0,
        open_ms: 1720386123687,
        orders: [
          {
            leverage: 0.5,
            order_type: "LONG",
            order_uuid: "5595c1f1-f398-4bd4-a552-dc04a72a4728",
            price: 1.47379,
            price_sources: [
              {
                close: 1.47379,
                high: 1.47379,
                lag_ms: 757,
                low: 1.47379,
                open: 1.47379,
                source: "TwelveData_ws",
                start_ms: 1720386124000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
              {
                close: 1.47362,
                high: 1.47362,
                lag_ms: 7757,
                low: 1.47362,
                open: 1.47362,
                source: "Polygon_ws",
                start_ms: 1720386131000,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 1.47362,
                websocket: true,
              },
              {
                close: 1.4787,
                high: 1.4788,
                lag_ms: 172923244,
                low: 1.4785,
                open: 1.4785,
                source: "TwelveData_rest",
                start_ms: 1720213140000,
                timespan_ms: 60000,
                volume: null,
                vwap: null,
                websocket: false,
              },
            ],
            processed_ms: 1720386123243,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "0135964f-681c-46de-977b-ab42317da33c",
            price: 1.47653,
            price_sources: [
              {
                close: 1.47653,
                high: 1.47653,
                lag_ms: 71,
                low: 1.47653,
                open: 1.47653,
                source: "Polygon_ws",
                start_ms: 1720416775000,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 1.47653,
                websocket: true,
              },
              {
                close: 1.47653,
                high: 1.47653,
                lag_ms: 929,
                low: 1.47653,
                open: 1.47653,
                source: "TwelveData_ws",
                start_ms: 1720416776000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1720416775071,
          },
        ],
        position_type: "FLAT",
        position_uuid: "61fbb7bc-9091-412e-a4fb-071a8c204787",
        return_at_close: 1.0008945435916923,
        trade_pair: ["EURCAD", "EUR/CAD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.8998,
        close_ms: 1720416872689,
        current_return: 1.0025394532118248,
        initial_entry_price: 0.8998,
        is_closed_position: true,
        miner_hotkey: "5C8Wegdus2cAcwSNU47MdiLXwZdewFkSv93xUWQP3wn32QJV",
        net_leverage: 0.0,
        open_ms: 1720109635044,
        orders: [
          {
            leverage: -0.5,
            order_type: "SHORT",
            order_uuid: "ce5ce940-675e-4e22-b7a6-3312fe623c84",
            price: 0.8998,
            price_sources: [
              {
                close: 0.8998,
                high: 0.8998,
                lag_ms: 14,
                low: 0.8998,
                open: 0.8998,
                source: "Polygon_ws",
                start_ms: 1720109634999,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 0.8998,
                websocket: true,
              },
              {
                close: 0.89979,
                high: 0.89979,
                lag_ms: 15,
                low: 0.89979,
                open: 0.89979,
                source: "TwelveData_ws",
                start_ms: 1720109635000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1720109634985,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "c7d827e6-a95c-4246-b8fb-d0b822c6782d",
            price: 0.89523,
            price_sources: [
              {
                close: 0.89523,
                high: 0.89523,
                lag_ms: 310,
                low: 0.89523,
                open: 0.89523,
                source: "Polygon_ws",
                start_ms: 1720416872999,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 0.89526,
                websocket: true,
              },
              {
                close: 0.89526,
                high: 0.89526,
                lag_ms: 311,
                low: 0.89526,
                open: 0.89526,
                source: "TwelveData_ws",
                start_ms: 1720416873000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1720416872689,
          },
        ],
        position_type: "FLAT",
        position_uuid: "ef68c3e0-68f4-4b47-940e-9ae2b467f4ae",
        return_at_close: 1.0025043643309623,
        trade_pair: ["USDCHF", "USD/CHF", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 3075.652,
        close_ms: 1720555123153,
        current_return: 1.0011392719917787,
        initial_entry_price: 3055.46,
        is_closed_position: true,
        miner_hotkey: "5C8Wegdus2cAcwSNU47MdiLXwZdewFkSv93xUWQP3wn32QJV",
        net_leverage: 0.0,
        open_ms: 1720291664539,
        orders: [
          {
            leverage: -0.1,
            order_type: "SHORT",
            order_uuid: "68604b1f-55c0-4d53-aaf7-f87c0444cdcb",
            price: 3055.46,
            price_sources: [
              {
                close: 3055.46,
                high: 3055.46,
                lag_ms: 348,
                low: 3055.4,
                open: 3055.46,
                source: "Polygon_ws",
                start_ms: 1720291662999,
                timespan_ms: 0,
                volume: 0.02383368,
                vwap: 3055.4586,
                websocket: true,
              },
              {
                close: 3055.36,
                high: 3055.6,
                lag_ms: 2652,
                low: 3055.36,
                open: 3055.36,
                source: "Polygon_rest",
                start_ms: 1720291659000,
                timespan_ms: 1000,
                volume: 0.16164608,
                vwap: 3055.461,
                websocket: false,
              },
              {
                close: 3055.08,
                high: 3055.08,
                lag_ms: 4349,
                low: 3055.08,
                open: 3055.08,
                source: "TwelveData_ws",
                start_ms: 1720291667000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
              {
                close: 3054.21997,
                high: 3055.91992,
                lag_ms: 642652,
                low: 3054.21997,
                open: 3055.13989,
                source: "TwelveData_rest",
                start_ms: 1720290960000,
                timespan_ms: 60000,
                volume: null,
                vwap: null,
                websocket: false,
              },
            ],
            processed_ms: 1720291662651,
          },
          {
            leverage: -0.4,
            order_type: "SHORT",
            order_uuid: "6dd5826e-4291-4e83-9ce5-3d091ae43b49",
            price: 3080.7,
            price_sources: [
              {
                close: 3080.7,
                high: 3080.7,
                lag_ms: 854,
                low: 3080.7,
                open: 3080.7,
                source: "Polygon_ws",
                start_ms: 1720507599999,
                timespan_ms: 0,
                volume: 0.0655869,
                vwap: 3080.7,
                websocket: true,
              },
              {
                close: 3079.88,
                high: 3079.88,
                lag_ms: 1853,
                low: 3079.88,
                open: 3079.88,
                source: "TwelveData_ws",
                start_ms: 1720507599000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1720507600853,
          },
          {
            leverage: 0.01,
            order_type: "FLAT",
            order_uuid: "54f9164e-aa87-4ebb-9a08-c7d025d4361d",
            price: 3068.69,
            price_sources: [
              {
                close: 3068.69,
                high: 3068.69,
                lag_ms: 153,
                low: 3068.69,
                open: 3068.69,
                source: "TwelveData_ws",
                start_ms: 1720555123000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
              {
                close: 3069.4,
                high: 3069.4,
                lag_ms: 154,
                low: 3069.21,
                open: 3069.4,
                source: "Polygon_ws",
                start_ms: 1720555122999,
                timespan_ms: 0,
                volume: 1.67523,
                vwap: 3069.3189,
                websocket: true,
              },
              {
                close: 3068.88,
                high: 3068.97,
                lag_ms: 3154,
                low: 3068.88,
                open: 3068.97,
                source: "Polygon_rest",
                start_ms: 1720555119000,
                timespan_ms: 1000,
                volume: 0.811517,
                vwap: 3068.88,
                websocket: false,
              },
              {
                close: 3065.08008,
                high: 3065.54004,
                lag_ms: 223154,
                low: 3063.22998,
                open: 3063.22998,
                source: "TwelveData_rest",
                start_ms: 1720554840000,
                timespan_ms: 60000,
                volume: null,
                vwap: null,
                websocket: false,
              },
            ],
            processed_ms: 1720555123153,
          },
        ],
        position_type: "FLAT",
        position_uuid: "84d5cffa-4924-4937-9b88-95249c6d32ae",
        return_at_close: 1.0006387023557828,
        trade_pair: ["ETHUSD", "ETH/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 161.2825,
        close_ms: 0,
        current_return: 0.9987025870754732,
        initial_entry_price: 161.2825,
        is_closed_position: false,
        miner_hotkey: "5C8Wegdus2cAcwSNU47MdiLXwZdewFkSv93xUWQP3wn32QJV",
        net_leverage: -0.5,
        open_ms: 1720555203949,
        orders: [
          {
            leverage: -0.5,
            order_type: "SHORT",
            order_uuid: "fd4d440a-ba31-436d-9bfb-50887fdfe209",
            price: 161.2825,
            price_sources: [
              {
                close: 161.2825,
                high: 161.2825,
                lag_ms: 310,
                low: 161.2825,
                open: 161.2825,
                source: "Polygon_ws",
                start_ms: 1720555202999,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 161.285,
                websocket: true,
              },
              {
                close: 161.285,
                high: 161.285,
                lag_ms: 311,
                low: 161.285,
                open: 161.285,
                source: "TwelveData_ws",
                start_ms: 1720555203000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1720555202689,
          },
        ],
        position_type: "SHORT",
        position_uuid: "4a89d3c6-4282-427f-b9f8-e85581d4b389",
        return_at_close: 0.9986851097801994,
        trade_pair: ["USDJPY", "USD/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 58938.85,
        close_ms: 0,
        current_return: 1.0013604778511966,
        initial_entry_price: 58938.85,
        is_closed_position: false,
        miner_hotkey: "5C8Wegdus2cAcwSNU47MdiLXwZdewFkSv93xUWQP3wn32QJV",
        net_leverage: -0.1,
        open_ms: 1720589966623,
        orders: [
          {
            leverage: -0.1,
            order_type: "SHORT",
            order_uuid: "669a417c-5645-4c49-b7f0-07c8aa050a10",
            price: 58938.85,
            price_sources: [
              {
                close: 58938.85,
                high: 58948.0,
                lag_ms: 154,
                low: 58938.85,
                open: 58938.85,
                source: "Polygon_ws",
                start_ms: 1720589963999,
                timespan_ms: 0,
                volume: 0.12574811,
                vwap: 58947.5747,
                websocket: true,
              },
              {
                close: 58950.41,
                high: 58950.41,
                lag_ms: 2846,
                low: 58950.41,
                open: 58950.41,
                source: "Polygon_rest",
                start_ms: 1720589960000,
                timespan_ms: 1000,
                volume: 0.00066467,
                vwap: 58950.41,
                websocket: false,
              },
              {
                close: 58952.34,
                high: 58966.23,
                lag_ms: 23846,
                low: 58892.56,
                open: 58909.9,
                source: "TwelveData_rest",
                start_ms: 1720589880000,
                timespan_ms: 60000,
                volume: null,
                vwap: null,
                websocket: false,
              },
              {
                close: 58919.4,
                high: 58919.4,
                lag_ms: 26155,
                low: 58919.4,
                open: 58919.4,
                source: "TwelveData_ws",
                start_ms: 1720589990000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1720589963845,
          },
        ],
        position_type: "SHORT",
        position_uuid: "9fde7bec-5c00-4890-9409-5cfc47480d67",
        return_at_close: 1.001310409827304,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
    ],
    thirty_day_returns: 1.0257249344995512,
  },
  "5CALivVcJBTjYJFMsAkqhppQgq5U2PYW4HejCajHMvTMUgkC": {
    all_time_returns: 1.0592577499333236,
    n_positions: 1,
    percentage_profitable: 1.0,
    positions: [
      {
        average_entry_price: 107722.50000000019,
        close_ms: 1719929750146,
        current_return: 1.0638856525217932,
        initial_entry_price: 71239.0,
        is_closed_position: true,
        miner_hotkey: "5CALivVcJBTjYJFMsAkqhppQgq5U2PYW4HejCajHMvTMUgkC",
        net_leverage: 0.0,
        open_ms: 1716250121920,
        orders: [
          {
            leverage: -0.2,
            order_type: "SHORT",
            order_uuid: "ac49210b-87fe-4372-9bec-00a38118e238",
            price: 71239.0,
            price_sources: [],
            processed_ms: 1716250121621,
          },
          {
            leverage: -0.2,
            order_type: "SHORT",
            order_uuid: "a45d31ec-a689-4385-8707-18c8ed6034c1",
            price: 70209.0,
            price_sources: [],
            processed_ms: 1716303819576,
          },
          {
            leverage: -0.4,
            order_type: "SHORT",
            order_uuid: "67ca106e-0d4c-4bc5-b192-3f2af348314a",
            price: 69680.0,
            price_sources: [],
            processed_ms: 1716385078278,
          },
          {
            leverage: 0.2,
            order_type: "LONG",
            order_uuid: "e8739143-b837-48cd-a5f6-d613a6c09c93",
            price: 67984.12,
            price_sources: [],
            processed_ms: 1716472386555,
          },
          {
            leverage: 0.2,
            order_type: "LONG",
            order_uuid: "2b189897-732e-4b0c-a59e-acc3c47642d2",
            price: 68037.0,
            price_sources: [],
            processed_ms: 1716473458831,
          },
          {
            leverage: 0.1,
            order_type: "LONG",
            order_uuid: "cc48b971-2f4c-4077-938a-26e2b609a202",
            price: 66656.79,
            price_sources: [],
            processed_ms: 1716494888524,
          },
          {
            leverage: -0.15,
            order_type: "SHORT",
            order_uuid: "50957afc-82d6-4484-843c-c0daae934d30",
            price: 67507.34,
            price_sources: [],
            processed_ms: 1716499101381,
          },
          {
            leverage: -0.15,
            order_type: "SHORT",
            order_uuid: "6c81af64-13bd-48f5-bb15-82a85b3882d0",
            price: 68193.95,
            price_sources: [],
            processed_ms: 1716566144298,
          },
          {
            leverage: -0.2,
            order_type: "SHORT",
            order_uuid: "d888cc7d-b945-4853-8999-7b89d5bddaee",
            price: 68872.01,
            price_sources: [],
            processed_ms: 1716573682610,
          },
          {
            leverage: -0.2,
            order_type: "SHORT",
            order_uuid: "4793940e-d8cd-4ecc-bc8b-04c3eaa12f91",
            price: 69108.0,
            price_sources: [],
            processed_ms: 1716574211492,
          },
          {
            leverage: 0.7,
            order_type: "LONG",
            order_uuid: "292a070e-363e-4e7c-aac1-94efe70abad9",
            price: 67450.53,
            price_sources: [],
            processed_ms: 1716997054797,
          },
          {
            leverage: 0.1,
            order_type: "LONG",
            order_uuid: "f59bf747-a232-4865-8d32-d2770c509765",
            price: 67672.23,
            price_sources: [],
            processed_ms: 1717033395466,
          },
          {
            leverage: -0.2,
            order_type: "SHORT",
            order_uuid: "850eeef9-d988-46b7-98b0-7f69a96f3a27",
            price: 69280.3,
            price_sources: [],
            processed_ms: 1717093747054,
          },
          {
            leverage: 0.2,
            order_type: "LONG",
            order_uuid: "57b0a20f-69d2-4f7b-9ec0-c8fa1b27c3d0",
            price: 68475.35,
            price_sources: [],
            processed_ms: 1717098635161,
          },
          {
            leverage: -0.2,
            order_type: "SHORT",
            order_uuid: "9f1371da-b495-417e-97ba-334de9605b5e",
            price: 68376.04,
            price_sources: [],
            processed_ms: 1717103670996,
          },
          {
            leverage: 0.2,
            order_type: "LONG",
            order_uuid: "3d9e8d62-9571-455c-85c4-910a28247225",
            price: 67398.0,
            price_sources: [],
            processed_ms: 1717167360879,
          },
          {
            leverage: 0.15,
            order_type: "LONG",
            order_uuid: "79602052-fc6e-4625-b616-9c71e1c2355f",
            price: 67381.21,
            price_sources: [],
            processed_ms: 1717183580720,
          },
          {
            leverage: -0.35,
            order_type: "SHORT",
            order_uuid: "181bae66-167e-4200-a2c2-d23ca530f455",
            price: 68612.3,
            price_sources: [],
            processed_ms: 1717380718305,
          },
          {
            leverage: -0.5,
            order_type: "SHORT",
            order_uuid: "424d9666-8023-40a0-8f2f-e7058ffc3da4",
            price: 69232.99,
            price_sources: [],
            processed_ms: 1717414334189,
          },
          {
            leverage: -1.1,
            order_type: "SHORT",
            order_uuid: "3b21c8ab-f82c-4cc9-bd0f-a3985ffbc5cf",
            price: 69779.0,
            price_sources: [],
            processed_ms: 1717421606374,
          },
          {
            leverage: 1.0,
            order_type: "LONG",
            order_uuid: "304af407-0084-4762-8f33-f65f44edcb1a",
            price: 69179.99,
            price_sources: [],
            processed_ms: 1717425730331,
          },
          {
            leverage: -0.5,
            order_type: "SHORT",
            order_uuid: "1e385f16-d0a9-4783-b0fa-bc6715edc11d",
            price: 69596.65,
            price_sources: [],
            processed_ms: 1717509116793,
          },
          {
            leverage: 1.4,
            order_type: "LONG",
            order_uuid: "dd841702-23e0-4499-ba6b-8c1295a42b3d",
            price: 69102.5,
            price_sources: [],
            processed_ms: 1717784518916,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "9033c436-d1d6-4dc9-b44c-7f691b959d12",
            price: 62211.0,
            price_sources: [],
            processed_ms: 1719929750146,
          },
        ],
        position_type: "FLAT",
        position_uuid: "cffb2b93-8032-4c4f-86a1-aa6906341baf",
        return_at_close: 1.0592577499333236,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 61160.969999999994,
        close_ms: 0,
        current_return: 0.9902942159179647,
        initial_entry_price: 62312.74,
        is_closed_position: false,
        miner_hotkey: "5CALivVcJBTjYJFMsAkqhppQgq5U2PYW4HejCajHMvTMUgkC",
        net_leverage: 0.2,
        open_ms: 1719930043718,
        orders: [
          {
            leverage: 0.1,
            order_type: "LONG",
            order_uuid: "14eeaba3-ab51-4418-8150-cc958fc5b405",
            price: 62312.74,
            price_sources: [],
            processed_ms: 1719930043168,
          },
          {
            leverage: 0.1,
            order_type: "LONG",
            order_uuid: "dbc67213-9c70-41a7-b377-9d9b6955f683",
            price: 60009.2,
            price_sources: [],
            processed_ms: 1720012275988,
          },
        ],
        position_type: "LONG",
        position_uuid: "14eeaba3-ab51-4418-8150-cc958fc5b405",
        return_at_close: 0.990195186496373,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
    ],
    thirty_day_returns: 1.0,
  },
  "5CAajxhosJYA5DrwL4CgAjGENaVmg9Va1DRzDTriGoXvPvyk": {
    all_time_returns: 1.0,
    n_positions: 0,
    percentage_profitable: 0.0,
    positions: [
      {
        average_entry_price: 65273.96,
        close_ms: 1711215055391,
        current_return: 0.9981614567279202,
        initial_entry_price: 65273.96,
        is_closed_position: true,
        miner_hotkey: "5CAajxhosJYA5DrwL4CgAjGENaVmg9Va1DRzDTriGoXvPvyk",
        net_leverage: 0.0,
        open_ms: 1711211516670,
        orders: [
          {
            leverage: -0.3,
            order_type: "SHORT",
            order_uuid: "84863a34-5e55-406b-9669-84961051109b",
            price: 65273.96,
            price_sources: [],
            processed_ms: 1711211516670,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "315cf694-0b0c-41a8-9325-87ea23ae123b",
            price: 65673.99,
            price_sources: [],
            processed_ms: 1711215055391,
          },
        ],
        position_type: "FLAT",
        position_uuid: "360b66e1-0cab-4f5e-846d-96ab2cb83577",
        return_at_close: 0.9975625598538834,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 65789.1,
        close_ms: 1711218713603,
        current_return: 1.0012120092842127,
        initial_entry_price: 65789.1,
        is_closed_position: true,
        miner_hotkey: "5CAajxhosJYA5DrwL4CgAjGENaVmg9Va1DRzDTriGoXvPvyk",
        net_leverage: 0.0,
        open_ms: 1711215142526,
        orders: [
          {
            leverage: -0.3,
            order_type: "SHORT",
            order_uuid: "70953856-537a-4399-8761-97a56f5b6eee",
            price: 65789.1,
            price_sources: [],
            processed_ms: 1711215142525,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "ff3697d7-0cd3-45e2-8af3-72bc6aac94f8",
            price: 65523.31,
            price_sources: [],
            processed_ms: 1711218713603,
          },
        ],
        position_type: "FLAT",
        position_uuid: "0458bfbd-3dcb-47bd-ab86-bb67dfb43dee",
        return_at_close: 1.0006112820786421,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 65516.97,
        close_ms: 1711222379826,
        current_return: 1.0021041266102508,
        initial_entry_price: 65516.97,
        is_closed_position: true,
        miner_hotkey: "5CAajxhosJYA5DrwL4CgAjGENaVmg9Va1DRzDTriGoXvPvyk",
        net_leverage: 0.0,
        open_ms: 1711218800430,
        orders: [
          {
            leverage: -0.3,
            order_type: "SHORT",
            order_uuid: "dd67dab6-e818-4e59-b2de-4152da8a2e09",
            price: 65516.97,
            price_sources: [],
            processed_ms: 1711218800429,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "15714410-05b9-4635-ae7f-07ad8fdaab0a",
            price: 65057.45,
            price_sources: [],
            processed_ms: 1711222379826,
          },
        ],
        position_type: "FLAT",
        position_uuid: "dcd75d58-88a1-43dc-ae20-6080b249a0f7",
        return_at_close: 1.0015028641342847,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 65026.82,
        close_ms: 1711226037608,
        current_return: 1.0001287622553279,
        initial_entry_price: 65026.82,
        is_closed_position: true,
        miner_hotkey: "5CAajxhosJYA5DrwL4CgAjGENaVmg9Va1DRzDTriGoXvPvyk",
        net_leverage: 0.0,
        open_ms: 1711222466595,
        orders: [
          {
            leverage: -0.3,
            order_type: "SHORT",
            order_uuid: "7a0a5f67-8f03-408e-bc7a-8bfc08d46eba",
            price: 65026.82,
            price_sources: [],
            processed_ms: 1711222466595,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "ec2344a3-5c7a-45b9-a56e-82196a8ff79a",
            price: 64998.91,
            price_sources: [],
            processed_ms: 1711226037608,
          },
        ],
        position_type: "FLAT",
        position_uuid: "b8123593-92c9-4491-8238-be1e9d7aa980",
        return_at_close: 0.9995286849979746,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 64982.71,
        close_ms: 1711229708317,
        current_return: 1.000010341212301,
        initial_entry_price: 64982.71,
        is_closed_position: true,
        miner_hotkey: "5CAajxhosJYA5DrwL4CgAjGENaVmg9Va1DRzDTriGoXvPvyk",
        net_leverage: 0.0,
        open_ms: 1711226126346,
        orders: [
          {
            leverage: -0.3,
            order_type: "SHORT",
            order_uuid: "b99cf4ba-3d29-46dc-a4b1-9ac7a1c1d0f0",
            price: 64982.71,
            price_sources: [],
            processed_ms: 1711226126345,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "831efee3-804f-45e6-bd7f-2c9122c4e8a2",
            price: 64980.47,
            price_sources: [],
            processed_ms: 1711229708317,
          },
        ],
        position_type: "FLAT",
        position_uuid: "6f1f996e-4318-445e-b755-9955267c885f",
        return_at_close: 0.9994103350075735,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 64948.4,
        close_ms: 1711233369493,
        current_return: 0.9996931102228847,
        initial_entry_price: 64948.4,
        is_closed_position: true,
        miner_hotkey: "5CAajxhosJYA5DrwL4CgAjGENaVmg9Va1DRzDTriGoXvPvyk",
        net_leverage: 0.0,
        open_ms: 1711229795573,
        orders: [
          {
            leverage: -0.3,
            order_type: "SHORT",
            order_uuid: "e62c3b5a-ee29-4f2f-983b-5cf1db4c9aa1",
            price: 64948.4,
            price_sources: [],
            processed_ms: 1711229795572,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "2329a976-3369-4278-ad9c-a8547f3b8723",
            price: 65014.84,
            price_sources: [],
            processed_ms: 1711233369493,
          },
        ],
        position_type: "FLAT",
        position_uuid: "28dbf8d0-1701-40d4-a19a-cb6b3db2cb81",
        return_at_close: 0.9990932943567509,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 64985.91,
        close_ms: 1711237025827,
        current_return: 1.0027047863144487,
        initial_entry_price: 64985.91,
        is_closed_position: true,
        miner_hotkey: "5CAajxhosJYA5DrwL4CgAjGENaVmg9Va1DRzDTriGoXvPvyk",
        net_leverage: 0.0,
        open_ms: 1711233456559,
        orders: [
          {
            leverage: -0.3,
            order_type: "SHORT",
            order_uuid: "627123b1-8e38-40eb-ad1f-d206a93540f8",
            price: 64985.91,
            price_sources: [],
            processed_ms: 1711233456558,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "b7e7c3ad-5f92-45c9-ae2e-a3fff78105d9",
            price: 64400.0,
            price_sources: [],
            processed_ms: 1711237025827,
          },
        ],
        position_type: "FLAT",
        position_uuid: "4f9318b0-d964-440a-8d29-a5cd495c3f24",
        return_at_close: 1.00210316344266,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 64445.41,
        close_ms: 1711244352477,
        current_return: 1.0001898350867813,
        initial_entry_price: 64445.41,
        is_closed_position: true,
        miner_hotkey: "5CAajxhosJYA5DrwL4CgAjGENaVmg9Va1DRzDTriGoXvPvyk",
        net_leverage: 0.0,
        open_ms: 1711240774791,
        orders: [
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "da13832c-4f15-47ed-9b40-4345f57c9991",
            price: 64445.41,
            price_sources: [],
            processed_ms: 1711240774789,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "4bc88ba3-aa2e-480b-9552-39092b2ecdd7",
            price: 64486.19,
            price_sources: [],
            processed_ms: 1711244352477,
          },
        ],
        position_type: "FLAT",
        position_uuid: "ff19d0cf-3cd2-4638-8697-62896ae439af",
        return_at_close: 0.9995897211857292,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 64315.950000000004,
        close_ms: 1711251678793,
        current_return: 0.9992474806016237,
        initial_entry_price: 64315.95,
        is_closed_position: true,
        miner_hotkey: "5CAajxhosJYA5DrwL4CgAjGENaVmg9Va1DRzDTriGoXvPvyk",
        net_leverage: 0.0,
        open_ms: 1711248128174,
        orders: [
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "67634c4e-57e4-4e97-8602-61a3a84261a1",
            price: 64315.95,
            price_sources: [],
            processed_ms: 1711248128173,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "17badd0b-2892-4f1b-bde6-4f8fd4283c3a",
            price: 64154.62,
            price_sources: [],
            processed_ms: 1711251678793,
          },
        ],
        position_type: "FLAT",
        position_uuid: "a7c2b4b9-0f4b-4ee5-b8db-88950aeaa457",
        return_at_close: 0.9986479321132627,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 64086.850000000006,
        close_ms: 1711255339442,
        current_return: 0.9995319320578246,
        initial_entry_price: 64086.85,
        is_closed_position: true,
        miner_hotkey: "5CAajxhosJYA5DrwL4CgAjGENaVmg9Va1DRzDTriGoXvPvyk",
        net_leverage: 0.0,
        open_ms: 1711251814757,
        orders: [
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "315edb5d-d7a0-45bd-8d31-07e3c69e38d6",
            price: 64086.85,
            price_sources: [],
            processed_ms: 1711251814756,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "829194dc-8672-4b83-81d7-b4b771d92460",
            price: 63986.86,
            price_sources: [],
            processed_ms: 1711255339442,
          },
        ],
        position_type: "FLAT",
        position_uuid: "06529db1-8ab8-4e8b-8eaf-10c777334ede",
        return_at_close: 0.9989322128985899,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 64022.9,
        close_ms: 1711260286973,
        current_return: 1.0010155584954759,
        initial_entry_price: 64022.9,
        is_closed_position: true,
        miner_hotkey: "5CAajxhosJYA5DrwL4CgAjGENaVmg9Va1DRzDTriGoXvPvyk",
        net_leverage: 0.0,
        open_ms: 1711255426555,
        orders: [
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "e3571524-ddc6-4a0d-bf75-adc4d53b4409",
            price: 64022.9,
            price_sources: [],
            processed_ms: 1711255426554,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "03b602e3-94ea-4b35-99c2-2ef994a309be",
            price: 64239.63,
            price_sources: [],
            processed_ms: 1711260286973,
          },
        ],
        position_type: "FLAT",
        position_uuid: "7fcc4288-3415-4276-92ef-beaea5b4db27",
        return_at_close: 1.0004149491603784,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 64249.9,
        close_ms: 1711263952580,
        current_return: 0.9977350159299859,
        initial_entry_price: 64249.9,
        is_closed_position: true,
        miner_hotkey: "5CAajxhosJYA5DrwL4CgAjGENaVmg9Va1DRzDTriGoXvPvyk",
        net_leverage: 0.0,
        open_ms: 1711260373500,
        orders: [
          {
            leverage: 1.25,
            order_type: "LONG",
            order_uuid: "7cf605a4-f7bd-4619-a7e2-c9fe8c69286a",
            price: 64249.9,
            price_sources: [],
            processed_ms: 1711260373498,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "da07d755-dba7-429e-859c-a26686c443c3",
            price: 64133.48,
            price_sources: [],
            processed_ms: 1711263952580,
          },
        ],
        position_type: "FLAT",
        position_uuid: "658242cb-7cd2-49b5-b915-cc4a2cc58579",
        return_at_close: 0.9952406783901611,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 64120.81,
        close_ms: 1711267617404,
        current_return: 1.0099330077084179,
        initial_entry_price: 64120.81,
        is_closed_position: true,
        miner_hotkey: "5CAajxhosJYA5DrwL4CgAjGENaVmg9Va1DRzDTriGoXvPvyk",
        net_leverage: 0.0,
        open_ms: 1711264039540,
        orders: [
          {
            leverage: 1.25,
            order_type: "LONG",
            order_uuid: "9e8cd249-e56b-4122-b32e-f33a3e12c2b5",
            price: 64120.81,
            price_sources: [],
            processed_ms: 1711264039539,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "2ac3517e-fd13-42ca-8131-3eb2e04fccb3",
            price: 64630.34,
            price_sources: [],
            processed_ms: 1711267617404,
          },
        ],
        position_type: "FLAT",
        position_uuid: "b483d9b0-c247-4793-adde-bdb172ee3a3e",
        return_at_close: 1.0074081751891468,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 64680.95,
        close_ms: 1711271275145,
        current_return: 1.0064555328887408,
        initial_entry_price: 64680.95,
        is_closed_position: true,
        miner_hotkey: "5CAajxhosJYA5DrwL4CgAjGENaVmg9Va1DRzDTriGoXvPvyk",
        net_leverage: 0.0,
        open_ms: 1711267747535,
        orders: [
          {
            leverage: 1.25,
            order_type: "LONG",
            order_uuid: "9018272b-a300-47e7-87f6-d429e8a7876d",
            price: 64680.95,
            price_sources: [],
            processed_ms: 1711267747534,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "5b25250f-e658-4871-a0ca-4eed89063e7a",
            price: 65014.99,
            price_sources: [],
            processed_ms: 1711271275145,
          },
        ],
        position_type: "FLAT",
        position_uuid: "9de8abe2-9ff3-486b-9828-9d52a355b75c",
        return_at_close: 1.0039393940565189,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 65074.58999999999,
        close_ms: 1711274934488,
        current_return: 0.9983424789921844,
        initial_entry_price: 65074.59,
        is_closed_position: true,
        miner_hotkey: "5CAajxhosJYA5DrwL4CgAjGENaVmg9Va1DRzDTriGoXvPvyk",
        net_leverage: 0.0,
        open_ms: 1711271400329,
        orders: [
          {
            leverage: 1.25,
            order_type: "LONG",
            order_uuid: "77083c2f-c62b-4a89-9a3b-ad5c6c0facfb",
            price: 65074.59,
            price_sources: [],
            processed_ms: 1711271400328,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "bb3f47ab-5677-452f-9272-86574867fc3a",
            price: 64988.3,
            price_sources: [],
            processed_ms: 1711274934488,
          },
        ],
        position_type: "FLAT",
        position_uuid: "f9b0d69d-7e92-4fbf-a08d-c94c197414ca",
        return_at_close: 0.995846622794704,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 64964.530000000006,
        close_ms: 1711285789915,
        current_return: 1.0108584253591921,
        initial_entry_price: 64964.53,
        is_closed_position: true,
        miner_hotkey: "5CAajxhosJYA5DrwL4CgAjGENaVmg9Va1DRzDTriGoXvPvyk",
        net_leverage: 0.0,
        open_ms: 1711278676487,
        orders: [
          {
            leverage: 1.25,
            order_type: "LONG",
            order_uuid: "2a18e489-2cae-48f5-89d4-efbf75675d76",
            price: 64964.53,
            price_sources: [],
            processed_ms: 1711278676486,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "2660436f-8989-4378-8928-7faf89fdb8c1",
            price: 65528.86,
            price_sources: [],
            processed_ms: 1711285789915,
          },
        ],
        position_type: "FLAT",
        position_uuid: "d0ec130f-69b4-48dd-ac2d-57cdf42aebd7",
        return_at_close: 1.0083312792957941,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 95216.15000000194,
        close_ms: 1711312806397,
        current_return: 1.0042921248036352,
        initial_entry_price: 65993.16,
        is_closed_position: true,
        miner_hotkey: "5CAajxhosJYA5DrwL4CgAjGENaVmg9Va1DRzDTriGoXvPvyk",
        net_leverage: 0.0,
        open_ms: 1711305341743,
        orders: [
          {
            leverage: -1.25,
            order_type: "SHORT",
            order_uuid: "0ea87795-3ecc-4444-929f-d722b118773b",
            price: 65993.16,
            price_sources: [],
            processed_ms: 1711305341740,
          },
          {
            leverage: 0.625,
            order_type: "LONG",
            order_uuid: "f666448f-4298-407b-a50d-dddca6836557",
            price: 65701.59,
            price_sources: [],
            processed_ms: 1711306278771,
          },
          {
            leverage: 0.3125,
            order_type: "LONG",
            order_uuid: "ce7c06c4-c942-4900-84ab-91475b7b764f",
            price: 65777.0,
            price_sources: [],
            processed_ms: 1711307210940,
          },
          {
            leverage: 0.15625,
            order_type: "LONG",
            order_uuid: "e0852fe2-ae9c-4cb9-a770-73bfc3b101fa",
            price: 65864.72,
            price_sources: [],
            processed_ms: 1711308134782,
          },
          {
            leverage: 0.078125,
            order_type: "LONG",
            order_uuid: "b0030614-41fe-47a7-82dd-a1e87b7c5bcc",
            price: 65846.34,
            price_sources: [],
            processed_ms: 1711309074092,
          },
          {
            leverage: 0.0390625,
            order_type: "LONG",
            order_uuid: "b394ce47-f258-456b-91d7-3e407f56b5cd",
            price: 65902.36,
            price_sources: [],
            processed_ms: 1711310000337,
          },
          {
            leverage: 0.01953125,
            order_type: "LONG",
            order_uuid: "d5e1e6fc-193f-45af-805f-86dbb386cf5a",
            price: 65756.83,
            price_sources: [],
            processed_ms: 1711310937334,
          },
          {
            leverage: 0.009765625,
            order_type: "LONG",
            order_uuid: "8f6c5cb7-ae2d-40b2-aa42-855f16de5f08",
            price: 66413.23,
            price_sources: [],
            processed_ms: 1711311863537,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "29f30783-0acd-4f18-931a-95c8b9ee4005",
            price: 66211.26,
            price_sources: [],
            processed_ms: 1711312806397,
          },
        ],
        position_type: "FLAT",
        position_uuid: "bc178091-862c-4688-aa48-196791922274",
        return_at_close: 1.001781394491626,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 45712.410000000295,
        close_ms: 1711323050152,
        current_return: 0.9897972652011248,
        initial_entry_price: 66259.9,
        is_closed_position: true,
        miner_hotkey: "5CAajxhosJYA5DrwL4CgAjGENaVmg9Va1DRzDTriGoXvPvyk",
        net_leverage: 0.0,
        open_ms: 1711315583893,
        orders: [
          {
            leverage: -1.25,
            order_type: "SHORT",
            order_uuid: "ffd1dec4-01bb-4610-a0c1-8f125a14789c",
            price: 66259.9,
            price_sources: [],
            processed_ms: 1711315583883,
          },
          {
            leverage: 0.125,
            order_type: "LONG",
            order_uuid: "244c88e9-e291-4736-a9b3-505478fb90cb",
            price: 66348.63,
            price_sources: [],
            processed_ms: 1711316555102,
          },
          {
            leverage: 0.125,
            order_type: "LONG",
            order_uuid: "2bb2df34-1cbb-45ac-89e4-a0bccaa14d4e",
            price: 66339.51,
            price_sources: [],
            processed_ms: 1711317457702,
          },
          {
            leverage: 0.5,
            order_type: "LONG",
            order_uuid: "4ff4bd20-bc2b-418c-802e-3286072a7958",
            price: 66821.21,
            price_sources: [],
            processed_ms: 1711318381897,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "ac5ffa14-5fca-470d-a26d-29977adc3023",
            price: 66962.22,
            price_sources: [],
            processed_ms: 1711319320812,
          },
          {
            leverage: 0.125,
            order_type: "LONG",
            order_uuid: "6d46457c-87c9-40b5-938a-4a49cf7c1b03",
            price: 66912.17,
            price_sources: [],
            processed_ms: 1711320249921,
          },
          {
            leverage: 0.0625,
            order_type: "LONG",
            order_uuid: "7829047e-63b1-435b-9935-4f830b62e857",
            price: 66953.69,
            price_sources: [],
            processed_ms: 1711321187599,
          },
          {
            leverage: 0.03125,
            order_type: "LONG",
            order_uuid: "09e85d78-a66c-419e-8985-2ab3d1e9b6b9",
            price: 67537.85,
            price_sources: [],
            processed_ms: 1711322123685,
          },
          {
            leverage: 0.125,
            order_type: "LONG",
            order_uuid: "b31689e5-4a5c-423f-8f9a-4ae0af82054f",
            price: 67345.44,
            price_sources: [],
            processed_ms: 1711323050152,
          },
        ],
        position_type: "FLAT",
        position_uuid: "1e606bfc-8dff-41c8-b30c-9f0cf75d9c1c",
        return_at_close: 0.987322772038122,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 99383.04999999794,
        close_ms: 1711343752710,
        current_return: 1.002984994796034,
        initial_entry_price: 66862.47,
        is_closed_position: true,
        miner_hotkey: "5CAajxhosJYA5DrwL4CgAjGENaVmg9Va1DRzDTriGoXvPvyk",
        net_leverage: 0.0,
        open_ms: 1711326779622,
        orders: [
          {
            leverage: -1.25,
            order_type: "SHORT",
            order_uuid: "01746a7c-b695-48b7-a6c9-52f7256f77ea",
            price: 66862.47,
            price_sources: [],
            processed_ms: 1711326779620,
          },
          {
            leverage: 0.125,
            order_type: "LONG",
            order_uuid: "26ae3ccb-5fce-4f3d-9192-80ceaf4ed483",
            price: 66612.79,
            price_sources: [],
            processed_ms: 1711327706852,
          },
          {
            leverage: 0.125,
            order_type: "LONG",
            order_uuid: "b979eccc-76c6-4daf-ba7a-a68c230bdf29",
            price: 66764.99,
            price_sources: [],
            processed_ms: 1711328671810,
          },
          {
            leverage: 0.5,
            order_type: "LONG",
            order_uuid: "d8b1651d-71e0-4eb3-8f5c-3cd0597d023e",
            price: 66579.77,
            price_sources: [],
            processed_ms: 1711332761894,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "49cf9819-4338-4393-a2b7-6cb8ddd800f4",
            price: 66756.88,
            price_sources: [],
            processed_ms: 1711334596996,
          },
          {
            leverage: 0.2,
            order_type: "LONG",
            order_uuid: "73d92ab3-a5f1-42b6-8287-c38f5913d4c6",
            price: 66833.42,
            price_sources: [],
            processed_ms: 1711336431279,
          },
          {
            leverage: 0.024999999999999994,
            order_type: "LONG",
            order_uuid: "2489fbbc-37bf-4ecd-9b41-b0d69639811d",
            price: 67111.66,
            price_sources: [],
            processed_ms: 1711338262801,
          },
          {
            leverage: 0.012499999999999997,
            order_type: "LONG",
            order_uuid: "d9d3fe67-4f5a-449e-aba4-e4a8590196b7",
            price: 67236.34,
            price_sources: [],
            processed_ms: 1711340094963,
          },
          {
            leverage: 0.006249999999999999,
            order_type: "LONG",
            order_uuid: "92842335-3ac9-4114-9ce3-7e006cd92b1d",
            price: 67309.79,
            price_sources: [],
            processed_ms: 1711341929844,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "eb0795c7-ceaa-42b2-8013-20e114cf7a82",
            price: 67449.59,
            price_sources: [],
            processed_ms: 1711343752710,
          },
        ],
        position_type: "FLAT",
        position_uuid: "f5ac3523-82ea-42b0-bc3e-786a52841f4e",
        return_at_close: 1.000477532309044,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: -1142.3400000022232,
        close_ms: 1711369683202,
        current_return: 0.9984060873953894,
        initial_entry_price: 66908.85,
        is_closed_position: true,
        miner_hotkey: "5CAajxhosJYA5DrwL4CgAjGENaVmg9Va1DRzDTriGoXvPvyk",
        net_leverage: 0.0,
        open_ms: 1711352930974,
        orders: [
          {
            leverage: -1.25,
            order_type: "SHORT",
            order_uuid: "a8204eda-0015-47a3-bf89-777bfc8e464d",
            price: 66908.85,
            price_sources: [],
            processed_ms: 1711352930972,
          },
          {
            leverage: 0.625,
            order_type: "LONG",
            order_uuid: "dbc8d244-2d6f-4778-8a04-2b31eb14359c",
            price: 67073.03,
            price_sources: [],
            processed_ms: 1711354740040,
          },
          {
            leverage: 0.3125,
            order_type: "LONG",
            order_uuid: "7c01054f-9b41-4c4e-9714-56f10051a1d3",
            price: 66940.25,
            price_sources: [],
            processed_ms: 1711356571280,
          },
          {
            leverage: 0.15625,
            order_type: "LONG",
            order_uuid: "aad3d5a9-b38a-4458-ab6e-4ea1f68a73d6",
            price: 66838.32,
            price_sources: [],
            processed_ms: 1711358432596,
          },
          {
            leverage: 0.125,
            order_type: "LONG",
            order_uuid: "20743b45-db82-4334-843f-2d29bf946682",
            price: 66901.87,
            price_sources: [],
            processed_ms: 1711360241868,
          },
          {
            leverage: 0.015625,
            order_type: "LONG",
            order_uuid: "322ea559-e423-4135-bfef-ed6616576ff8",
            price: 67245.27,
            price_sources: [],
            processed_ms: 1711362068299,
          },
          {
            leverage: 0.0125,
            order_type: "LONG",
            order_uuid: "daa4bb86-7e35-44be-a89b-8e19c5448cfd",
            price: 66963.4,
            price_sources: [],
            processed_ms: 1711363904471,
          },
          {
            leverage: 0.0015624999999999997,
            order_type: "LONG",
            order_uuid: "30cf52fa-b9e5-4cf3-bc5e-2d5b39da8e7c",
            price: 66818.84,
            price_sources: [],
            processed_ms: 1711365738500,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "d55b19f5-9efa-48ba-878c-f919d3412a10",
            price: 67111.65,
            price_sources: [],
            processed_ms: 1711369683202,
          },
        ],
        position_type: "FLAT",
        position_uuid: "8765950a-7b68-48a2-a166-76b99742bb8c",
        return_at_close: 0.995910072176901,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 59264.81714285717,
        close_ms: 1711376701666,
        current_return: 1.052675061402615,
        initial_entry_price: 67045.19,
        is_closed_position: true,
        miner_hotkey: "5CAajxhosJYA5DrwL4CgAjGENaVmg9Va1DRzDTriGoXvPvyk",
        net_leverage: 0.0,
        open_ms: 1711371496942,
        orders: [
          {
            leverage: 1.25,
            order_type: "LONG",
            order_uuid: "45c8d0b0-0414-459d-9682-6127db6c8d69",
            price: 67045.19,
            price_sources: [],
            processed_ms: 1711371496940,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "7418ff8f-0945-42db-a662-77b6c9df0283",
            price: 66931.79,
            price_sources: [],
            processed_ms: 1711373329230,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "e7d53403-8cc6-4535-b7b9-56bde340e5aa",
            price: 68007.14,
            price_sources: [],
            processed_ms: 1711375196011,
          },
          {
            leverage: -1.4,
            order_type: "SHORT",
            order_uuid: "8dc89da7-83ef-410f-9afe-dfe61a2ada2d",
            price: 69141.81,
            price_sources: [],
            processed_ms: 1711376516938,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "6addf523-c3ce-4e89-ac8a-e53452f143f8",
            price: 69355.13,
            price_sources: [],
            processed_ms: 1711376701666,
          },
        ],
        position_type: "FLAT",
        position_uuid: "a2023d08-a10f-48e9-884b-c1089d40abd7",
        return_at_close: 1.048990698687706,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 69358.28,
        close_ms: 1711376902602,
        current_return: 1.0015524462255985,
        initial_entry_price: 69358.28,
        is_closed_position: true,
        miner_hotkey: "5CAajxhosJYA5DrwL4CgAjGENaVmg9Va1DRzDTriGoXvPvyk",
        net_leverage: 0.0,
        open_ms: 1711376865064,
        orders: [
          {
            leverage: -1.25,
            order_type: "SHORT",
            order_uuid: "e5ea1d46-47ba-4232-bd60-5074daba6a29",
            price: 69358.28,
            price_sources: [],
            processed_ms: 1711376865062,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "425233bf-e34c-4151-97f9-e54e3a8b4f4c",
            price: 69272.14,
            price_sources: [],
            processed_ms: 1711376902602,
          },
        ],
        position_type: "FLAT",
        position_uuid: "6632fe48-f64a-4f83-bb20-7d27ed917185",
        return_at_close: 0.9990485651100345,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: -2514.7985578576768,
        close_ms: 1711443284276,
        current_return: 1.0028504384022197,
        initial_entry_price: 69144.78,
        is_closed_position: true,
        miner_hotkey: "5CAajxhosJYA5DrwL4CgAjGENaVmg9Va1DRzDTriGoXvPvyk",
        net_leverage: 0.0,
        open_ms: 1711378004147,
        orders: [
          {
            leverage: 1.25,
            order_type: "LONG",
            order_uuid: "4b21f9db-eb5d-4e66-b149-fd88ec29a064",
            price: 69144.78,
            price_sources: [],
            processed_ms: 1711378004146,
          },
          {
            leverage: -1.0,
            order_type: "SHORT",
            order_uuid: "408d28c7-990e-4427-8d2b-6df83d946784",
            price: 69212.28,
            price_sources: [],
            processed_ms: 1711378268094,
          },
          {
            leverage: -0.2,
            order_type: "SHORT",
            order_uuid: "28c97526-e53d-4c4d-be48-9d75b6cff946",
            price: 69808.57,
            price_sources: [],
            processed_ms: 1711381529939,
          },
          {
            leverage: -0.039999999999999994,
            order_type: "SHORT",
            order_uuid: "e995cdc1-5fa4-4117-a7b2-b51264e2951d",
            price: 69749.69,
            price_sources: [],
            processed_ms: 1711383345036,
          },
          {
            leverage: -0.007999999999999997,
            order_type: "SHORT",
            order_uuid: "9411ca36-e550-479a-852a-b86377b5d9e1",
            price: 70129.37,
            price_sources: [],
            processed_ms: 1711385161293,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "0526114e-e1c2-4def-8bc9-57ef50d004ef",
            price: 70635.36,
            price_sources: [],
            processed_ms: 1711387008389,
          },
          {
            leverage: -0.126,
            order_type: "SHORT",
            order_uuid: "714761d5-3ae7-4459-86ec-dd501a227959",
            price: 70404.43,
            price_sources: [],
            processed_ms: 1711388795128,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "47db1619-45e0-4b1f-8bfd-3d57fe51b998",
            price: 70594.54,
            price_sources: [],
            processed_ms: 1711390607899,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "f1a0bba7-c735-4ac1-ac44-a6f778c3ff6a",
            price: 70717.99,
            price_sources: [],
            processed_ms: 1711392422999,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "0adc09df-cb92-4280-b9e6-6d53419d9379",
            price: 70633.63,
            price_sources: [],
            processed_ms: 1711394239664,
          },
          {
            leverage: 0.2499999999999999,
            order_type: "LONG",
            order_uuid: "8cf39337-107b-4ddf-9ce3-bd97e0cd98dc",
            price: 70844.04,
            price_sources: [],
            processed_ms: 1711396101472,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "02de4aa2-a319-42db-8204-175583386775",
            price: 70863.38,
            price_sources: [],
            processed_ms: 1711397872426,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "2bab1c53-fad8-4940-8428-16182ea9766d",
            price: 70949.97,
            price_sources: [],
            processed_ms: 1711399693120,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "6026207c-b19a-4ce6-932f-a133ebf2487b",
            price: 70093.43,
            price_sources: [],
            processed_ms: 1711401510403,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "8243c5bf-900c-4856-b1a3-d62ffb148999",
            price: 69986.58,
            price_sources: [],
            processed_ms: 1711403323316,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "722f7c86-43de-4211-96d9-0d1b22b488a3",
            price: 70347.03,
            price_sources: [],
            processed_ms: 1711405186212,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "b6be8fb8-57e0-45d0-992e-388ae2ead277",
            price: 70466.67,
            price_sources: [],
            processed_ms: 1711406967197,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "c0a1e8b0-6a8b-4208-9dc4-ccadc78e5ac2",
            price: 70385.24,
            price_sources: [],
            processed_ms: 1711408776133,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "9930f06c-1637-45bc-9d6d-12a8997ba62d",
            price: 69796.25,
            price_sources: [],
            processed_ms: 1711410591027,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "300e1d0c-dad0-44f3-a79d-c20cb8b686fe",
            price: 69763.87,
            price_sources: [],
            processed_ms: 1711412409484,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "f87e0ffb-9fad-4978-8b3f-33f27d41d4ae",
            price: 70029.21,
            price_sources: [],
            processed_ms: 1711414216435,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "1400bef7-ffda-4903-85d4-be56fc53b7d1",
            price: 70235.88,
            price_sources: [],
            processed_ms: 1711416037547,
          },
          {
            leverage: 0.24999999999999956,
            order_type: "LONG",
            order_uuid: "e8c1f205-c6e2-4ddc-b415-e7e2d33a344e",
            price: 70235.6,
            price_sources: [],
            processed_ms: 1711417850245,
          },
          {
            leverage: -2.0629999999999997,
            order_type: "SHORT",
            order_uuid: "49a2c33e-2f5f-4cd3-86dc-74d938d667a5",
            price: 70187.21,
            price_sources: [],
            processed_ms: 1711419668972,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "682dfe4b-27f9-461a-a26e-30e6943d0fe3",
            price: 70315.29,
            price_sources: [],
            processed_ms: 1711421486403,
          },
          {
            leverage: -1.1564999999999999,
            order_type: "SHORT",
            order_uuid: "84226d24-605c-44fd-a67d-a988e7fe241c",
            price: 70502.95,
            price_sources: [],
            processed_ms: 1711423303941,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "8ffa9e4c-ebe8-4bc2-8f6e-86d9ca90d192",
            price: 70463.43,
            price_sources: [],
            processed_ms: 1711425116153,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "7a3e4b53-227e-4ae8-8e0a-b447d7909c16",
            price: 70537.82,
            price_sources: [],
            processed_ms: 1711426938179,
          },
          {
            leverage: -0.8282499999999999,
            order_type: "SHORT",
            order_uuid: "ca8caff2-4a74-4ea5-88f8-6086cd483edb",
            price: 70647.01,
            price_sources: [],
            processed_ms: 1711428756244,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "e46f17d6-e857-4375-ac8b-238b1dc25875",
            price: 70534.62,
            price_sources: [],
            processed_ms: 1711430570161,
          },
          {
            leverage: -0.539125,
            order_type: "SHORT",
            order_uuid: "22e82116-7226-4be9-9325-62a2ac8ca015",
            price: 70424.67,
            price_sources: [],
            processed_ms: 1711432387482,
          },
          {
            leverage: -0.2695625,
            order_type: "SHORT",
            order_uuid: "25c4eb16-3f25-4ff2-b7dc-52597614130c",
            price: 70331.21,
            price_sources: [],
            processed_ms: 1711434198472,
          },
          {
            leverage: -0.13478125,
            order_type: "SHORT",
            order_uuid: "a00062ba-40e0-4f9b-879b-c3e9d7a53625",
            price: 70485.97,
            price_sources: [],
            processed_ms: 1711436014379,
          },
          {
            leverage: -0.067390625,
            order_type: "SHORT",
            order_uuid: "11e48e34-05f1-4802-8e72-38441200db26",
            price: 71162.72,
            price_sources: [],
            processed_ms: 1711437835019,
          },
          {
            leverage: -0.053912499999999995,
            order_type: "SHORT",
            order_uuid: "7abe659e-8d23-4ded-a8f1-61763e7439d5",
            price: 70823.65,
            price_sources: [],
            processed_ms: 1711439649036,
          },
          {
            leverage: -0.0107825,
            order_type: "SHORT",
            order_uuid: "85cbad21-104b-4228-b69a-d6b5a6c9b07c",
            price: 70711.84,
            price_sources: [],
            processed_ms: 1711441459528,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "76259264-e4f7-479e-b7ba-b9a12143f419",
            price: 70601.06,
            price_sources: [],
            processed_ms: 1711443284276,
          },
        ],
        position_type: "FLAT",
        position_uuid: "489ba3a7-17e1-4526-b1db-19b170212eff",
        return_at_close: 0.9945749165845245,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 71324.61999999998,
        close_ms: 1711454180148,
        current_return: 1.0021380159234745,
        initial_entry_price: 71062.38,
        is_closed_position: true,
        miner_hotkey: "5CAajxhosJYA5DrwL4CgAjGENaVmg9Va1DRzDTriGoXvPvyk",
        net_leverage: 0.0,
        open_ms: 1711445099120,
        orders: [
          {
            leverage: -1.25,
            order_type: "SHORT",
            order_uuid: "4ed436a1-4068-4fe7-9788-88cc38483386",
            price: 71062.38,
            price_sources: [],
            processed_ms: 1711445099118,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "bedd8416-6941-4a11-a8e2-5ff1e967dae9",
            price: 71191.53,
            price_sources: [],
            processed_ms: 1711446913058,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "eba1f7dc-2e63-4927-8048-5a8712aa23c4",
            price: 70975.27,
            price_sources: [],
            processed_ms: 1711448725086,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "0513f91d-bded-4e30-b6b8-4503a5426994",
            price: 70961.87,
            price_sources: [],
            processed_ms: 1711450543026,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "c9c30a47-d8f0-4741-885d-3f80df1604cf",
            price: 70858.61,
            price_sources: [],
            processed_ms: 1711452362225,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "16f2e652-82f0-4df2-a512-af6783a7b0e0",
            price: 70716.89,
            price_sources: [],
            processed_ms: 1711454180148,
          },
        ],
        position_type: "FLAT",
        position_uuid: "f6b7a8da-fd3b-4166-8425-730517665f27",
        return_at_close: 0.9996326708836659,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 171123.99000000165,
        close_ms: 1711470525099,
        current_return: 1.0071556994280417,
        initial_entry_price: 70851.32,
        is_closed_position: true,
        miner_hotkey: "5CAajxhosJYA5DrwL4CgAjGENaVmg9Va1DRzDTriGoXvPvyk",
        net_leverage: 0.0,
        open_ms: 1711457815013,
        orders: [
          {
            leverage: -1.25,
            order_type: "SHORT",
            order_uuid: "dce8cdea-15f8-4632-aaad-ce4ce913bc35",
            price: 70851.32,
            price_sources: [],
            processed_ms: 1711457815011,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "ec81e126-c477-41cb-ac7f-71375fd24ff5",
            price: 70844.58,
            price_sources: [],
            processed_ms: 1711459630244,
          },
          {
            leverage: 0.5,
            order_type: "LONG",
            order_uuid: "c538d6c7-efb2-49e3-a16c-dceb6534a98c",
            price: 70629.17,
            price_sources: [],
            processed_ms: 1711461438935,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "dc765119-246c-4846-8552-31440ea568e7",
            price: 69884.23,
            price_sources: [],
            processed_ms: 1711463263849,
          },
          {
            leverage: 0.2,
            order_type: "LONG",
            order_uuid: "8e32129f-f223-4bf8-ac55-c7fed26becf7",
            price: 70272.98,
            price_sources: [],
            processed_ms: 1711465078982,
          },
          {
            leverage: 0.024999999999999994,
            order_type: "LONG",
            order_uuid: "d95de870-93f4-4886-9a06-a5c2aed84efd",
            price: 70058.39,
            price_sources: [],
            processed_ms: 1711466894011,
          },
          {
            leverage: 0.019999999999999997,
            order_type: "LONG",
            order_uuid: "e12d3a9c-1123-403b-a8c3-4492c3c9efd3",
            price: 70284.34,
            price_sources: [],
            processed_ms: 1711468712060,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "8a63c7c9-4581-4556-978c-b8f4f65b12be",
            price: 69725.84,
            price_sources: [],
            processed_ms: 1711470525099,
          },
        ],
        position_type: "FLAT",
        position_uuid: "6718e1d3-b067-43ef-8688-8a048ad6e240",
        return_at_close: 1.0046378101794717,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 70131.8238277512,
        close_ms: 1711524624345,
        current_return: 0.9692036315194832,
        initial_entry_price: 69711.04,
        is_closed_position: true,
        miner_hotkey: "5CAajxhosJYA5DrwL4CgAjGENaVmg9Va1DRzDTriGoXvPvyk",
        net_leverage: 0.0,
        open_ms: 1711472342129,
        orders: [
          {
            leverage: 1.25,
            order_type: "LONG",
            order_uuid: "db4d1028-027d-4473-b769-6f8a3e0a37fe",
            price: 69711.04,
            price_sources: [],
            processed_ms: 1711472342127,
          },
          {
            leverage: -0.625,
            order_type: "SHORT",
            order_uuid: "8882d77a-139b-42c0-b751-47d459819d3e",
            price: 70051.08,
            price_sources: [],
            processed_ms: 1711474157585,
          },
          {
            leverage: -0.3125,
            order_type: "SHORT",
            order_uuid: "488ec37d-ef8c-4586-9107-0c396689f4a2",
            price: 70061.89,
            price_sources: [],
            processed_ms: 1711475978164,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "fc6ded3b-7262-4cc6-afec-097723bc95f2",
            price: 70248.39,
            price_sources: [],
            processed_ms: 1711477787059,
          },
          {
            leverage: -0.28125,
            order_type: "SHORT",
            order_uuid: "bec9994f-5409-457c-a3f8-b5df1103c44e",
            price: 70088.88,
            price_sources: [],
            processed_ms: 1711479604835,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "b59c9e93-d241-405d-999a-b6720b6dcb4d",
            price: 69767.61,
            price_sources: [],
            processed_ms: 1711481422076,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "debf13cf-aa65-437e-8114-ecb2653143d6",
            price: 69445.99,
            price_sources: [],
            processed_ms: 1711483234235,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "f537d4c0-f380-40ac-86ef-8c1c6fa60232",
            price: 69740.78,
            price_sources: [],
            processed_ms: 1711485051260,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "762db8f3-9297-4b15-91be-d2d7e849ee55",
            price: 69820.75,
            price_sources: [],
            processed_ms: 1711486865720,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "3feefc9e-d41b-40a9-a590-20af024e08b5",
            price: 70201.88,
            price_sources: [],
            processed_ms: 1711488709544,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "4f895668-2149-492e-9f5e-168cc965fbf6",
            price: 70007.35,
            price_sources: [],
            processed_ms: 1711490527660,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "f8322bd5-9c94-464d-860a-5cce2f5c826b",
            price: 69802.16,
            price_sources: [],
            processed_ms: 1711492341819,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "5b61075a-7de4-47bd-82c7-3dcc8be9ea87",
            price: 70250.13,
            price_sources: [],
            processed_ms: 1711494162122,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "bb423de1-96f0-4e10-b341-876562984747",
            price: 70078.5,
            price_sources: [],
            processed_ms: 1711495975216,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "51f8b164-46cc-442b-8a1a-c90af324830f",
            price: 69976.4,
            price_sources: [],
            processed_ms: 1711497793213,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "fdf56f5c-e4d7-4ab7-8e25-80704bae0cce",
            price: 70136.2,
            price_sources: [],
            processed_ms: 1711499637427,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "86858f70-9e0c-4e41-baf3-7730f3b1a5c0",
            price: 70555.03,
            price_sources: [],
            processed_ms: 1711501434512,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "eddcc1bc-f0da-45e5-ae00-72c19efd33ba",
            price: 70519.7,
            price_sources: [],
            processed_ms: 1711503293688,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "e2ff4d43-1471-4068-b84a-c41db0d1167d",
            price: 70278.43,
            price_sources: [],
            processed_ms: 1711505066177,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "b0a4b7c1-2e2b-4c10-9694-3ceb526471c5",
            price: 70495.76,
            price_sources: [],
            processed_ms: 1711506876114,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "2af2cef2-c551-47c8-b834-b1da2daa5f83",
            price: 70585.35,
            price_sources: [],
            processed_ms: 1711508694621,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "b7a7183d-869b-4bb3-886e-6e765815fcf1",
            price: 70465.51,
            price_sources: [],
            processed_ms: 1711510511393,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "8dbf2605-6e66-4e1f-816c-87517bcd9426",
            price: 70429.17,
            price_sources: [],
            processed_ms: 1711512324085,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "1175f5ea-16c9-4333-910b-9d2f605cac7b",
            price: 70356.65,
            price_sources: [],
            processed_ms: 1711514148203,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "31159e41-3094-41d0-ae1b-7a6ef09e1529",
            price: 70288.68,
            price_sources: [],
            processed_ms: 1711515966089,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "6c27404a-6786-400a-9a4f-429a69dc89e9",
            price: 70421.48,
            price_sources: [],
            processed_ms: 1711517771090,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "51b23c5b-3fdb-4345-b110-49429186ef06",
            price: 70492.72,
            price_sources: [],
            processed_ms: 1711519593965,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "e13fc5be-c866-48f4-899a-3ead940fa294",
            price: 70310.17,
            price_sources: [],
            processed_ms: 1711521411294,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "a9fe4696-26f8-4e33-90fe-346d741fc6e9",
            price: 70263.17,
            price_sources: [],
            processed_ms: 1711523229082,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "ff21948b-529a-44dd-b936-782c1a2d5c05",
            price: 70255.79,
            price_sources: [],
            processed_ms: 1711523399672,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "7d5cb3a8-fab3-4448-88ec-edc13621e812",
            price: 69803.12,
            price_sources: [],
            processed_ms: 1711524624345,
          },
        ],
        position_type: "FLAT",
        position_uuid: "8e5abe0d-8f2e-4005-9724-c257fb470c78",
        return_at_close: 0.9565434090827599,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 69966.34258064516,
        close_ms: 1711545248421,
        current_return: 1.0694509119797564,
        initial_entry_price: 69835.98,
        is_closed_position: true,
        miner_hotkey: "5CAajxhosJYA5DrwL4CgAjGENaVmg9Va1DRzDTriGoXvPvyk",
        net_leverage: 0.0,
        open_ms: 1711526671033,
        orders: [
          {
            leverage: 1.25,
            order_type: "LONG",
            order_uuid: "1689fcfa-35ef-4cda-bd8d-9eafa5f7fe43",
            price: 69835.98,
            price_sources: [],
            processed_ms: 1711526671031,
          },
          {
            leverage: 0.375,
            order_type: "LONG",
            order_uuid: "a95ad40a-e9bf-46a1-93bd-fa5b39dbe1f6",
            price: 69850.0,
            price_sources: [],
            processed_ms: 1711528483967,
          },
          {
            leverage: 0.375,
            order_type: "LONG",
            order_uuid: "75763f31-10c9-4d8a-a75c-a0f4d0ca7048",
            price: 69561.53,
            price_sources: [],
            processed_ms: 1711530313719,
          },
          {
            leverage: 0.375,
            order_type: "LONG",
            order_uuid: "44464522-b32d-4044-84f2-7ea62631a447",
            price: 69747.82,
            price_sources: [],
            processed_ms: 1711532124742,
          },
          {
            leverage: 0.375,
            order_type: "LONG",
            order_uuid: "8c3bf48d-2e31-4015-9592-ec0d65f776cd",
            price: 69985.06,
            price_sources: [],
            processed_ms: 1711533928873,
          },
          {
            leverage: 0.375,
            order_type: "LONG",
            order_uuid: "493db97e-7ba3-48fd-a340-ec2e16bd09de",
            price: 70065.07,
            price_sources: [],
            processed_ms: 1711537468298,
          },
          {
            leverage: 0.375,
            order_type: "LONG",
            order_uuid: "c3dccc21-6f3a-4ba3-a2b2-3ca8b3049f3f",
            price: 70327.81,
            price_sources: [],
            processed_ms: 1711541105193,
          },
          {
            leverage: 0.375,
            order_type: "LONG",
            order_uuid: "8441012b-e9c0-44f6-9691-c681d49652f6",
            price: 70661.65,
            price_sources: [],
            processed_ms: 1711544705412,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "cb6ade17-7034-46e1-8796-cc2b48cb0c57",
            price: 71218.0,
            price_sources: [],
            processed_ms: 1711545248421,
          },
        ],
        position_type: "FLAT",
        position_uuid: "014047eb-1c35-41d0-b568-a8c36b8ae1be",
        return_at_close: 1.0611626674119132,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 57650.56000000017,
        close_ms: 1711560693837,
        current_return: 0.9935212627449325,
        initial_entry_price: 69133.36,
        is_closed_position: true,
        miner_hotkey: "5CAajxhosJYA5DrwL4CgAjGENaVmg9Va1DRzDTriGoXvPvyk",
        net_leverage: 0.0,
        open_ms: 1711548314263,
        orders: [
          {
            leverage: -1.25,
            order_type: "SHORT",
            order_uuid: "0e920939-bd2c-4be5-96b3-f3257a4424b4",
            price: 69133.36,
            price_sources: [],
            processed_ms: 1711548314260,
          },
          {
            leverage: 0.625,
            order_type: "LONG",
            order_uuid: "fa1e78e6-8ae1-4cf3-98b3-df62f2eaaaf5",
            price: 69861.09,
            price_sources: [],
            processed_ms: 1711551935548,
          },
          {
            leverage: 0.3125,
            order_type: "LONG",
            order_uuid: "97ef1820-dbd6-4d53-900f-e34319e7e70a",
            price: 69261.59,
            price_sources: [],
            processed_ms: 1711553423975,
          },
          {
            leverage: 0.15625,
            order_type: "LONG",
            order_uuid: "e6de0c51-6731-40eb-b468-242bc06e9f37",
            price: 68843.93,
            price_sources: [],
            processed_ms: 1711555246404,
          },
          {
            leverage: 0.078125,
            order_type: "LONG",
            order_uuid: "5f6c144d-4b20-431b-8d50-24a4302607bd",
            price: 69071.14,
            price_sources: [],
            processed_ms: 1711557059257,
          },
          {
            leverage: 0.0390625,
            order_type: "LONG",
            order_uuid: "58489f56-2c69-4167-9280-81f730420105",
            price: 69228.8,
            price_sources: [],
            processed_ms: 1711558873927,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "1e2667f3-4964-40eb-9662-30d369187bad",
            price: 69116.72,
            price_sources: [],
            processed_ms: 1711560693837,
          },
        ],
        position_type: "FLAT",
        position_uuid: "6dd9cae3-71ac-406e-8bee-e05221e9d38e",
        return_at_close: 0.9910374595880702,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 68378.80000000003,
        close_ms: 1711578928349,
        current_return: 0.9955378368317009,
        initial_entry_price: 68928.34,
        is_closed_position: true,
        miner_hotkey: "5CAajxhosJYA5DrwL4CgAjGENaVmg9Va1DRzDTriGoXvPvyk",
        net_leverage: 0.0,
        open_ms: 1711571650763,
        orders: [
          {
            leverage: -3.5,
            order_type: "SHORT",
            order_uuid: "f42b1d57-01d9-47fa-adc5-9e03031c546f",
            price: 68928.34,
            price_sources: [],
            processed_ms: 1711571650761,
          },
          {
            leverage: 1.0499999999999998,
            order_type: "LONG",
            order_uuid: "450cdf4d-2635-4661-8318-2449f87adee1",
            price: 68924.11,
            price_sources: [],
            processed_ms: 1711573464783,
          },
          {
            leverage: 1.05,
            order_type: "LONG",
            order_uuid: "9ee6ed23-3f3b-4035-94e2-d033ee336564",
            price: 69103.82,
            price_sources: [],
            processed_ms: 1711575288684,
          },
          {
            leverage: 1.05,
            order_type: "LONG",
            order_uuid: "74523dec-71c7-4d0b-995a-91f84ee444d7",
            price: 68940.27,
            price_sources: [],
            processed_ms: 1711577116188,
          },
          {
            leverage: 1.05,
            order_type: "LONG",
            order_uuid: "9d837683-01c9-4065-84c1-482f5df002fb",
            price: 69257.57,
            price_sources: [],
            processed_ms: 1711578928349,
          },
        ],
        position_type: "FLAT",
        position_uuid: "a9c8baf0-253f-4e20-9b94-cf79ad03c542",
        return_at_close: 0.988569071973879,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 69421.05999999995,
        close_ms: 1711588039270,
        current_return: 0.9985082254913022,
        initial_entry_price: 69363.03,
        is_closed_position: true,
        miner_hotkey: "5CAajxhosJYA5DrwL4CgAjGENaVmg9Va1DRzDTriGoXvPvyk",
        net_leverage: 0.0,
        open_ms: 1711580749171,
        orders: [
          {
            leverage: -3.5,
            order_type: "SHORT",
            order_uuid: "eda70c05-72da-49d1-834b-e79dc12ae933",
            price: 69363.03,
            price_sources: [],
            processed_ms: 1711580749168,
          },
          {
            leverage: 1.0499999999999998,
            order_type: "LONG",
            order_uuid: "17b50fd1-6c3a-4108-9a17-2da34df935b8",
            price: 69053.91,
            price_sources: [],
            processed_ms: 1711582567590,
          },
          {
            leverage: 1.05,
            order_type: "LONG",
            order_uuid: "50bd6642-abde-43f5-abe3-22bbfc0cf691",
            price: 69499.01,
            price_sources: [],
            processed_ms: 1711584398313,
          },
          {
            leverage: 0.7000000000000001,
            order_type: "LONG",
            order_uuid: "081317bc-7e12-41c1-ade0-e4384073870e",
            price: 69564.71,
            price_sources: [],
            processed_ms: 1711586215414,
          },
          {
            leverage: 1.05,
            order_type: "LONG",
            order_uuid: "5c7d56a3-5066-4620-bc43-d0dd96a707ff",
            price: 69568.88,
            price_sources: [],
            processed_ms: 1711588039270,
          },
        ],
        position_type: "FLAT",
        position_uuid: "1c9550f8-cbeb-432d-91a2-9cfc5bbf4278",
        return_at_close: 0.9915186679128631,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 94278.15000000094,
        close_ms: 1711618478642,
        current_return: 1.009287071400487,
        initial_entry_price: 69521.34,
        is_closed_position: true,
        miner_hotkey: "5CAajxhosJYA5DrwL4CgAjGENaVmg9Va1DRzDTriGoXvPvyk",
        net_leverage: 0.0,
        open_ms: 1711589868007,
        orders: [
          {
            leverage: -3.5,
            order_type: "SHORT",
            order_uuid: "d53d50a6-493f-4e0d-b819-26d729d0eaf6",
            price: 69521.34,
            price_sources: [],
            processed_ms: 1711589868003,
          },
          {
            leverage: 1.75,
            order_type: "LONG",
            order_uuid: "a29b83a8-0d9d-4190-b0b0-ee4a0b03051a",
            price: 69468.37,
            price_sources: [],
            processed_ms: 1711591681190,
          },
          {
            leverage: 0.875,
            order_type: "LONG",
            order_uuid: "da2ce120-5e47-4889-ae58-15f44e517129",
            price: 69109.59,
            price_sources: [],
            processed_ms: 1711593506062,
          },
          {
            leverage: 0.4375,
            order_type: "LONG",
            order_uuid: "00f291fd-d8bd-4d9a-a6a5-ae4c9347eb82",
            price: 69212.75,
            price_sources: [],
            processed_ms: 1711595338286,
          },
          {
            leverage: 0.21875,
            order_type: "LONG",
            order_uuid: "9db26c3f-ba95-4155-b881-7f9161424252",
            price: 69119.18,
            price_sources: [],
            processed_ms: 1711597163858,
          },
          {
            leverage: 0.109375,
            order_type: "LONG",
            order_uuid: "17ebf580-8ca8-425d-a00e-9d56a15d6e9e",
            price: 69400.55,
            price_sources: [],
            processed_ms: 1711598991542,
          },
          {
            leverage: 0.0546875,
            order_type: "LONG",
            order_uuid: "faf9daa6-70c1-4eeb-8b24-9d8e8e809a3f",
            price: 69677.85,
            price_sources: [],
            processed_ms: 1711600810832,
          },
          {
            leverage: 0.02734375,
            order_type: "LONG",
            order_uuid: "80001362-128c-4b53-94b1-f5af8acd1125",
            price: 69655.47,
            price_sources: [],
            processed_ms: 1711602632243,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "2ef8a681-d4c0-4db0-b7d3-ea320fb297bf",
            price: 70665.82,
            price_sources: [],
            processed_ms: 1711618478642,
          },
        ],
        position_type: "FLAT",
        position_uuid: "6483b5ba-3c2d-4678-b701-acc0ddb6613a",
        return_at_close: 1.0022220619006834,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 70799.42,
        close_ms: 1711634221118,
        current_return: 0.9949659898343799,
        initial_entry_price: 70799.42,
        is_closed_position: true,
        miner_hotkey: "5CAajxhosJYA5DrwL4CgAjGENaVmg9Va1DRzDTriGoXvPvyk",
        net_leverage: 0.0,
        open_ms: 1711634061595,
        orders: [
          {
            leverage: -3.5,
            order_type: "SHORT",
            order_uuid: "09874a47-a36a-43e6-b452-6f1f3af0e362",
            price: 70799.42,
            price_sources: [],
            processed_ms: 1711634061592,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "300098d7-0334-4061-8a4a-0e20d48be639",
            price: 70901.25,
            price_sources: [],
            processed_ms: 1711634221118,
          },
        ],
        position_type: "FLAT",
        position_uuid: "b01832e0-c879-463f-9a8b-3336ca483cb6",
        return_at_close: 0.9880012279055392,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 71382.35,
        close_ms: 1711637853248,
        current_return: 0.9944030281995482,
        initial_entry_price: 71382.35,
        is_closed_position: true,
        miner_hotkey: "5CAajxhosJYA5DrwL4CgAjGENaVmg9Va1DRzDTriGoXvPvyk",
        net_leverage: 0.0,
        open_ms: 1711637707652,
        orders: [
          {
            leverage: -3.5,
            order_type: "SHORT",
            order_uuid: "9a82dd92-60c8-42da-bceb-010818927786",
            price: 71382.35,
            price_sources: [],
            processed_ms: 1711637707649,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "b8f3953b-59f1-4f8f-8a52-c5bd46f13aeb",
            price: 71496.5,
            price_sources: [],
            processed_ms: 1711637853248,
          },
        ],
        position_type: "FLAT",
        position_uuid: "8eff5620-0aa7-4c1d-8e49-8bb65d1b6ddc",
        return_at_close: 0.9874422070021514,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 70736.06000000004,
        close_ms: 1711678317516,
        current_return: 0.9998081780876332,
        initial_entry_price: 70742.7,
        is_closed_position: true,
        miner_hotkey: "5CAajxhosJYA5DrwL4CgAjGENaVmg9Va1DRzDTriGoXvPvyk",
        net_leverage: 0.0,
        open_ms: 1711660227837,
        orders: [
          {
            leverage: -5.0,
            order_type: "SHORT",
            order_uuid: "6b7f7650-5b99-4568-8380-8e624a8bf2d7",
            price: 70742.7,
            price_sources: [],
            processed_ms: 1711660227834,
          },
          {
            leverage: 2.5,
            order_type: "LONG",
            order_uuid: "159e2d75-b4da-45c8-9211-778d064afab9",
            price: 70648.9,
            price_sources: [],
            processed_ms: 1711663845086,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "9afdea67-9c01-42e2-b912-12d615112b20",
            price: 70902.43,
            price_sources: [],
            processed_ms: 1711667464793,
          },
          {
            leverage: 0.5,
            order_type: "LONG",
            order_uuid: "7b3650ae-e71b-470d-938c-e0f98db42ba9",
            price: 70725.19,
            price_sources: [],
            processed_ms: 1711671076378,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "dc78978a-112e-4c41-99b2-4072a391edec",
            price: 70763.98,
            price_sources: [],
            processed_ms: 1711674705196,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "ea7c6be3-b878-46d4-9a34-e9c8e270de13",
            price: 70790.34,
            price_sources: [],
            processed_ms: 1711678317516,
          },
        ],
        position_type: "FLAT",
        position_uuid: "40014f09-0b45-4ca1-9952-7f911b067f32",
        return_at_close: 0.9898100963067569,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 70366.69157894737,
        close_ms: 1711697728112,
        current_return: 0.9642535094906537,
        initial_entry_price: 70397.68,
        is_closed_position: true,
        miner_hotkey: "5CAajxhosJYA5DrwL4CgAjGENaVmg9Va1DRzDTriGoXvPvyk",
        net_leverage: 0.0,
        open_ms: 1711685555142,
        orders: [
          {
            leverage: 5.0,
            order_type: "LONG",
            order_uuid: "3ea8f215-8848-43b5-a3fe-0416967dc5b5",
            price: 70397.68,
            price_sources: [],
            processed_ms: 1711685555139,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "c6a291ef-517e-4fbf-a3ab-153e68524496",
            price: 70438.55,
            price_sources: [],
            processed_ms: 1711689169895,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "68c8928b-e6f2-498e-9ba1-4eb90995116c",
            price: 70203.97,
            price_sources: [],
            processed_ms: 1711692798295,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "a5c1a206-ca29-4e7e-9616-d82bcca235b0",
            price: 70354.26,
            price_sources: [],
            processed_ms: 1711696418653,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "6cefe57e-df2b-4024-9e0e-cb8032dc3f99",
            price: 70101.8,
            price_sources: [],
            processed_ms: 1711697728112,
          },
        ],
        position_type: "FLAT",
        position_uuid: "16b33721-f567-4ed7-867b-ee716806d1d2",
        return_at_close: 0.9459326928103312,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 53591.20610169541,
        close_ms: 1711761552235,
        current_return: 1.054066257640286,
        initial_entry_price: 69796.13,
        is_closed_position: true,
        miner_hotkey: "5CAajxhosJYA5DrwL4CgAjGENaVmg9Va1DRzDTriGoXvPvyk",
        net_leverage: 0.0,
        open_ms: 1711700036526,
        orders: [
          {
            leverage: 5.0,
            order_type: "LONG",
            order_uuid: "a153e14b-70cb-4762-bbd1-fbeaebed8c7c",
            price: 69796.13,
            price_sources: [],
            processed_ms: 1711700036522,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "964e3d6d-f48a-40a3-ab06-e7b20c81af8b",
            price: 69972.74,
            price_sources: [],
            processed_ms: 1711703661225,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "e1e0f200-77b1-488a-a242-2f8d5e249a04",
            price: 69845.17,
            price_sources: [],
            processed_ms: 1711707280124,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "1d8b5032-f9be-4e0a-ac21-c5d5d174292d",
            price: 70186.25,
            price_sources: [],
            processed_ms: 1711710893101,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "5da35f8f-b702-4dc2-bceb-6fd5543e47fc",
            price: 69954.86,
            price_sources: [],
            processed_ms: 1711714511177,
          },
          {
            leverage: -5.5,
            order_type: "SHORT",
            order_uuid: "eb69e40f-9911-4644-be0a-4548c3979a0c",
            price: 70097.49,
            price_sources: [],
            processed_ms: 1711718126875,
          },
          {
            leverage: -2.75,
            order_type: "SHORT",
            order_uuid: "cab7fafa-b4ec-48d5-bdd8-bdbe66499150",
            price: 70403.86,
            price_sources: [],
            processed_ms: 1711721741438,
          },
          {
            leverage: -1.375,
            order_type: "SHORT",
            order_uuid: "015fda8f-7ced-4e53-bc32-d70c0b0b87e1",
            price: 70069.09,
            price_sources: [],
            processed_ms: 1711725357080,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "519f6465-b9f8-4865-9881-c5fe1bb03869",
            price: 69436.75,
            price_sources: [],
            processed_ms: 1711728976950,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "21fcdbce-9844-4d79-9096-5d9eb83ff82b",
            price: 69341.0,
            price_sources: [],
            processed_ms: 1711732600260,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "2310c53e-411d-4840-9118-8eded96585c7",
            price: 69487.04,
            price_sources: [],
            processed_ms: 1711736211128,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "313cf7d2-6e85-4ab0-9e50-e822d3b4c811",
            price: 69456.56,
            price_sources: [],
            processed_ms: 1711739867911,
          },
          {
            leverage: -3.6875,
            order_type: "SHORT",
            order_uuid: "57da64ed-368d-4cb8-aae6-2078f1895fa5",
            price: 69675.35,
            price_sources: [],
            processed_ms: 1711743448338,
          },
          {
            leverage: -1.84375,
            order_type: "SHORT",
            order_uuid: "e4935044-1bdf-4e9d-924f-128b79d077ed",
            price: 69543.56,
            price_sources: [],
            processed_ms: 1711747082323,
          },
          {
            leverage: -0.921875,
            order_type: "SHORT",
            order_uuid: "479857fd-5657-42d6-85c2-f8c9f5f591d7",
            price: 69618.25,
            price_sources: [],
            processed_ms: 1711750692396,
          },
          {
            leverage: -0.4609375,
            order_type: "SHORT",
            order_uuid: "6994dff4-a208-4457-8961-283182619cbc",
            price: 69920.47,
            price_sources: [],
            processed_ms: 1711754310697,
          },
          {
            leverage: -0.23046875,
            order_type: "SHORT",
            order_uuid: "b5e81ddb-eaae-4587-83ae-955bc8b86c80",
            price: 69862.9,
            price_sources: [],
            processed_ms: 1711757926875,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "e4ee04e5-ef96-46ad-bb91-c69740dff360",
            price: 69964.86,
            price_sources: [],
            processed_ms: 1711761552235,
          },
        ],
        position_type: "FLAT",
        position_uuid: "c566a776-b745-4186-92fd-579b3d228083",
        return_at_close: 1.0308767999721997,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 67181.60000000002,
        close_ms: 1711790496571,
        current_return: 1.0105305077969269,
        initial_entry_price: 69957.69,
        is_closed_position: true,
        miner_hotkey: "5CAajxhosJYA5DrwL4CgAjGENaVmg9Va1DRzDTriGoXvPvyk",
        net_leverage: 0.0,
        open_ms: 1711765163474,
        orders: [
          {
            leverage: 5.0,
            order_type: "LONG",
            order_uuid: "96b31ae0-d7fa-4777-a20f-6b33567765a3",
            price: 69957.69,
            price_sources: [],
            processed_ms: 1711765163470,
          },
          {
            leverage: -2.5,
            order_type: "SHORT",
            order_uuid: "50fdc10f-6d96-4237-a528-d5ceef092531",
            price: 69925.12,
            price_sources: [],
            processed_ms: 1711768791061,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "e4751a4d-d6b8-46b2-b44e-173fea65232b",
            price: 69910.32,
            price_sources: [],
            processed_ms: 1711772401087,
          },
          {
            leverage: -2.0,
            order_type: "SHORT",
            order_uuid: "fc2df501-0229-45af-b71d-96ce00b5fa83",
            price: 70212.0,
            price_sources: [],
            processed_ms: 1711776018988,
          },
          {
            leverage: -1.0,
            order_type: "SHORT",
            order_uuid: "07c9fbe5-706b-4d1d-abac-888984f39fc4",
            price: 70107.08,
            price_sources: [],
            processed_ms: 1711779647111,
          },
          {
            leverage: -0.5,
            order_type: "SHORT",
            order_uuid: "e25f6893-b845-4e88-adb0-722da9210316",
            price: 70034.65,
            price_sources: [],
            processed_ms: 1711783266843,
          },
          {
            leverage: -0.25,
            order_type: "SHORT",
            order_uuid: "606c23de-d386-4444-8984-b12ea265ad4a",
            price: 69989.3,
            price_sources: [],
            processed_ms: 1711786882903,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "a3aa0d7a-82ed-4fd1-96a7-67ad71c264e4",
            price: 70128.36,
            price_sources: [],
            processed_ms: 1711790496571,
          },
        ],
        position_type: "FLAT",
        position_uuid: "1db0e3ea-c43c-4544-8c8f-eba22de1e57a",
        return_at_close: 1.0004252027189575,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 69463.02571428577,
        close_ms: 1711808605892,
        current_return: 0.9906893638031182,
        initial_entry_price: 70037.91,
        is_closed_position: true,
        miner_hotkey: "5CAajxhosJYA5DrwL4CgAjGENaVmg9Va1DRzDTriGoXvPvyk",
        net_leverage: 0.0,
        open_ms: 1711794120019,
        orders: [
          {
            leverage: -5.0,
            order_type: "SHORT",
            order_uuid: "e8f8078b-6b1d-4af0-9280-8dfb252ce692",
            price: 70037.91,
            price_sources: [],
            processed_ms: 1711794120015,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "f51293e3-279a-4e9e-8f05-9091bdea985f",
            price: 70107.44,
            price_sources: [],
            processed_ms: 1711797733731,
          },
          {
            leverage: 1.75,
            order_type: "LONG",
            order_uuid: "e54a4912-e65c-4c0f-be3a-3c41fec65fb0",
            price: 70154.69,
            price_sources: [],
            processed_ms: 1711801364795,
          },
          {
            leverage: 0.875,
            order_type: "LONG",
            order_uuid: "de4404be-763a-4fd7-b656-a7d55a43a6ca",
            price: 70260.04,
            price_sources: [],
            processed_ms: 1711804985727,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "0a61d7a5-80a9-40df-a1f3-15e6f33b8e62",
            price: 70208.28,
            price_sources: [],
            processed_ms: 1711808605892,
          },
        ],
        position_type: "FLAT",
        position_uuid: "c53d507e-d134-46fc-ba84-5d8073ddf2d3",
        return_at_close: 0.980782470165087,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 70008.28999999988,
        close_ms: 1711830324120,
        current_return: 0.9995042638573047,
        initial_entry_price: 70041.04,
        is_closed_position: true,
        miner_hotkey: "5CAajxhosJYA5DrwL4CgAjGENaVmg9Va1DRzDTriGoXvPvyk",
        net_leverage: 0.0,
        open_ms: 1711812225948,
        orders: [
          {
            leverage: 5.0,
            order_type: "LONG",
            order_uuid: "07b25955-a987-476b-a659-4269cf5078db",
            price: 70041.04,
            price_sources: [],
            processed_ms: 1711812225945,
          },
          {
            leverage: -2.5,
            order_type: "SHORT",
            order_uuid: "0dcb2644-30eb-4c20-9ea5-9cf78cad095d",
            price: 70030.35,
            price_sources: [],
            processed_ms: 1711815842636,
          },
          {
            leverage: -1.25,
            order_type: "SHORT",
            order_uuid: "47d8f729-04e8-4f52-bb3e-65f717a43f5b",
            price: 70113.89,
            price_sources: [],
            processed_ms: 1711819469166,
          },
          {
            leverage: -0.625,
            order_type: "SHORT",
            order_uuid: "9cd70f9d-747d-49db-afa1-2690be70045e",
            price: 70009.43,
            price_sources: [],
            processed_ms: 1711823083743,
          },
          {
            leverage: -0.3125,
            order_type: "SHORT",
            order_uuid: "1668d3ad-8fee-4b63-9a0a-149783a19272",
            price: 69931.13,
            price_sources: [],
            processed_ms: 1711826763943,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "e4a9bb87-2f4d-4adb-8f72-8c0905d5fc3a",
            price: 69897.18,
            price_sources: [],
            processed_ms: 1711830324120,
          },
        ],
        position_type: "FLAT",
        position_uuid: "5c0b7f95-838b-4557-94db-44e6ce27ede0",
        return_at_close: 0.9895092212187316,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 70597.09000000004,
        close_ms: 1711855753152,
        current_return: 1.003000475544704,
        initial_entry_price: 69846.22,
        is_closed_position: true,
        miner_hotkey: "5CAajxhosJYA5DrwL4CgAjGENaVmg9Va1DRzDTriGoXvPvyk",
        net_leverage: 0.0,
        open_ms: 1711837569358,
        orders: [
          {
            leverage: -5.0,
            order_type: "SHORT",
            order_uuid: "124c9b7b-1630-41c5-98e6-016e2a818c5f",
            price: 69846.22,
            price_sources: [],
            processed_ms: 1711837569354,
          },
          {
            leverage: 2.5,
            order_type: "LONG",
            order_uuid: "37c60a5b-55f7-4265-9bb3-3f0144fdbd89",
            price: 69668.54,
            price_sources: [],
            processed_ms: 1711841193724,
          },
          {
            leverage: 1.25,
            order_type: "LONG",
            order_uuid: "52838ee6-5624-488b-8253-c5cc20c78732",
            price: 69883.34,
            price_sources: [],
            processed_ms: 1711844805961,
          },
          {
            leverage: 0.625,
            order_type: "LONG",
            order_uuid: "10408c6a-f6d3-4d55-af33-7ac3bb30ac64",
            price: 69980.15,
            price_sources: [],
            processed_ms: 1711848467145,
          },
          {
            leverage: 0.3125,
            order_type: "LONG",
            order_uuid: "f2742eee-80e7-473e-96bd-2c87b93932c9",
            price: 70100.45,
            price_sources: [],
            processed_ms: 1711852046288,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "c7e7cf26-63f8-401c-8f2c-5b3ad47b62d7",
            price: 69926.46,
            price_sources: [],
            processed_ms: 1711855753152,
          },
        ],
        position_type: "FLAT",
        position_uuid: "9f409010-38e9-4149-b491-c4fbdf4b2d2d",
        return_at_close: 0.9929704707892569,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 69875.72000000002,
        close_ms: 1711877406405,
        current_return: 1.003774228014109,
        initial_entry_price: 70214.69,
        is_closed_position: true,
        miner_hotkey: "5CAajxhosJYA5DrwL4CgAjGENaVmg9Va1DRzDTriGoXvPvyk",
        net_leverage: 0.0,
        open_ms: 1711862896183,
        orders: [
          {
            leverage: 5.0,
            order_type: "LONG",
            order_uuid: "54595938-69d0-4705-8c67-ad3d96c3d156",
            price: 70214.69,
            price_sources: [],
            processed_ms: 1711862896179,
          },
          {
            leverage: -2.5,
            order_type: "SHORT",
            order_uuid: "55a89432-e460-4374-aa98-3b32c32500c3",
            price: 70236.63,
            price_sources: [],
            processed_ms: 1711866516743,
          },
          {
            leverage: -1.25,
            order_type: "SHORT",
            order_uuid: "f7b27c77-a98e-4397-893c-801c4b572b39",
            price: 70233.79,
            price_sources: [],
            processed_ms: 1711870152087,
          },
          {
            leverage: -0.625,
            order_type: "SHORT",
            order_uuid: "086a9ce1-b421-4d44-8151-4c25bc611ca2",
            price: 70427.7,
            price_sources: [],
            processed_ms: 1711873748888,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "7040fad6-9915-4611-a806-c2eebfb62df3",
            price: 70299.73,
            price_sources: [],
            processed_ms: 1711877406405,
          },
        ],
        position_type: "FLAT",
        position_uuid: "ae557322-36da-42f5-906f-3b4317adf6ec",
        return_at_close: 0.993736485733968,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 69594.53500000005,
        close_ms: 1711895473622,
        current_return: 0.9846422264336797,
        initial_entry_price: 70295.02,
        is_closed_position: true,
        miner_hotkey: "5CAajxhosJYA5DrwL4CgAjGENaVmg9Va1DRzDTriGoXvPvyk",
        net_leverage: 0.0,
        open_ms: 1711881013607,
        orders: [
          {
            leverage: -5.0,
            order_type: "SHORT",
            order_uuid: "50be3c7c-d5c2-4fab-8b26-b7a5b72a3aed",
            price: 70295.02,
            price_sources: [],
            processed_ms: 1711881013604,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "e76eda86-c261-4d38-8db6-dfad373edaa7",
            price: 70353.12,
            price_sources: [],
            processed_ms: 1711884612974,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "6aba2360-d75a-4228-9389-627ff97c099d",
            price: 70444.33,
            price_sources: [],
            processed_ms: 1711888239044,
          },
          {
            leverage: 1.0,
            order_type: "LONG",
            order_uuid: "6aabdf32-814e-4023-99f6-4d924fb70feb",
            price: 70684.39,
            price_sources: [],
            processed_ms: 1711891853954,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "449d2621-a23b-4444-8e7c-1adf6312981f",
            price: 70674.11,
            price_sources: [],
            processed_ms: 1711895473622,
          },
        ],
        position_type: "FLAT",
        position_uuid: "1bb868bd-7a11-4c8c-97aa-249c60e0bbe3",
        return_at_close: 0.9747958041693429,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 68645.68999999989,
        close_ms: 1711913568965,
        current_return: 0.9835569158588772,
        initial_entry_price: 70353.59,
        is_closed_position: true,
        miner_hotkey: "5CAajxhosJYA5DrwL4CgAjGENaVmg9Va1DRzDTriGoXvPvyk",
        net_leverage: 0.0,
        open_ms: 1711899082580,
        orders: [
          {
            leverage: -5.0,
            order_type: "SHORT",
            order_uuid: "a7285ca5-4466-406c-a3ba-eb5b409877e1",
            price: 70353.59,
            price_sources: [],
            processed_ms: 1711899082574,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "870ce38a-6c39-41f2-a56f-7d68b812d8fa",
            price: 70526.09,
            price_sources: [],
            processed_ms: 1711902733192,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "19648bc4-9509-46ad-9d2d-7a521f10f689",
            price: 70491.91,
            price_sources: [],
            processed_ms: 1711906352604,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "836f76b3-d1e5-4e12-83ce-610cc786f8a6",
            price: 70612.07,
            price_sources: [],
            processed_ms: 1711909960463,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "379f8b09-46cb-4364-910b-bd8c2eefd849",
            price: 70959.35,
            price_sources: [],
            processed_ms: 1711913568965,
          },
        ],
        position_type: "FLAT",
        position_uuid: "46cc4520-c459-4337-a4a1-efba1c1ad22c",
        return_at_close: 0.9737213467002884,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 69342.43999999994,
        close_ms: 1711931713124,
        current_return: 0.9874831820096357,
        initial_entry_price: 70869.05,
        is_closed_position: true,
        miner_hotkey: "5CAajxhosJYA5DrwL4CgAjGENaVmg9Va1DRzDTriGoXvPvyk",
        net_leverage: 0.0,
        open_ms: 1711917189709,
        orders: [
          {
            leverage: -5.0,
            order_type: "SHORT",
            order_uuid: "cdeb8004-dd86-4799-9c68-26f393f47a1e",
            price: 70869.05,
            price_sources: [],
            processed_ms: 1711917189701,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "d7e14ed1-de81-44a6-abef-15736ee022b6",
            price: 70868.35,
            price_sources: [],
            processed_ms: 1711920916600,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "97963b56-61c7-4ee3-8702-fd05d6a06ee5",
            price: 71051.81,
            price_sources: [],
            processed_ms: 1711924423495,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "386af10d-e614-4d2a-8ae2-ec8046d2629d",
            price: 71195.86,
            price_sources: [],
            processed_ms: 1711928042273,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "755b951d-34c2-4f6b-b9ac-5dbff1eeced5",
            price: 71116.55,
            price_sources: [],
            processed_ms: 1711931713124,
          },
        ],
        position_type: "FLAT",
        position_uuid: "8bebe710-c84a-4156-bcac-ff3ec57364ae",
        return_at_close: 0.9776083501895393,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 86439.86999999982,
        close_ms: 1711964230784,
        current_return: 1.1192897748969284,
        initial_entry_price: 71099.43,
        is_closed_position: true,
        miner_hotkey: "5CAajxhosJYA5DrwL4CgAjGENaVmg9Va1DRzDTriGoXvPvyk",
        net_leverage: 0.0,
        open_ms: 1711935275093,
        orders: [
          {
            leverage: -5.0,
            order_type: "SHORT",
            order_uuid: "1d59f4c7-7635-4175-bfb6-2430bb6d645d",
            price: 71099.43,
            price_sources: [],
            processed_ms: 1711935275085,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "09ffbab7-c368-425a-a0cf-1a8bac018df7",
            price: 70834.92,
            price_sources: [],
            processed_ms: 1711938897834,
          },
          {
            leverage: -5.0,
            order_type: "SHORT",
            order_uuid: "362c334b-d6fd-4773-ad10-e647c8303e30",
            price: 70609.93,
            price_sources: [],
            processed_ms: 1711942526988,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "e70a9838-fa1b-4e72-b1e5-85589041fd7d",
            price: 70558.59,
            price_sources: [],
            processed_ms: 1711946140462,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "30eb1189-aa10-4e4e-9a7a-19de5609ae97",
            price: 70321.2,
            price_sources: [],
            processed_ms: 1711949758731,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "18379344-3294-4fa7-bdb5-0117345f2042",
            price: 69518.6,
            price_sources: [],
            processed_ms: 1711953384636,
          },
          {
            leverage: 2.0,
            order_type: "LONG",
            order_uuid: "cc84e25f-2820-4cd4-883d-1dfa39eebbfe",
            price: 69667.3,
            price_sources: [],
            processed_ms: 1711957008113,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "ae8bbe41-9567-443d-95c3-ff46a675cc07",
            price: 69428.2,
            price_sources: [],
            processed_ms: 1711960618935,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "9ec32edf-2272-4b9f-a1cb-47fd832165a2",
            price: 69477.0,
            price_sources: [],
            processed_ms: 1711964230784,
          },
        ],
        position_type: "FLAT",
        position_uuid: "da55fd78-21e5-4e2e-badd-bd231a0b738b",
        return_at_close: 1.1002618487236806,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 68059.9,
        close_ms: 1711985948851,
        current_return: 0.9948450623376099,
        initial_entry_price: 69508.6,
        is_closed_position: true,
        miner_hotkey: "5CAajxhosJYA5DrwL4CgAjGENaVmg9Va1DRzDTriGoXvPvyk",
        net_leverage: 0.0,
        open_ms: 1711971471450,
        orders: [
          {
            leverage: -5.0,
            order_type: "SHORT",
            order_uuid: "e0c41afb-199c-48d7-84a5-7bcc84a3276b",
            price: 69508.6,
            price_sources: [],
            processed_ms: 1711971471445,
          },
          {
            leverage: 2.5,
            order_type: "LONG",
            order_uuid: "fa6a1426-ffa8-41cc-beb0-4b2de8797ae7",
            price: 69697.5,
            price_sources: [],
            processed_ms: 1711975088837,
          },
          {
            leverage: 1.25,
            order_type: "LONG",
            order_uuid: "22d92ec1-1ab7-4aad-a58f-0cb398949553",
            price: 69985.9,
            price_sources: [],
            processed_ms: 1711978710395,
          },
          {
            leverage: 0.625,
            order_type: "LONG",
            order_uuid: "70096241-3ba4-472e-bcc0-e9707a26e9e7",
            price: 69247.1,
            price_sources: [],
            processed_ms: 1711982325708,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "3d6e0ac1-3ede-46f2-b6b1-50cc7effeca1",
            price: 68633.2,
            price_sources: [],
            processed_ms: 1711985948851,
          },
        ],
        position_type: "FLAT",
        position_uuid: "f1a0ffda-ee32-47e3-86af-101c76f82ec5",
        return_at_close: 0.9848966117142338,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 68604.00322580645,
        close_ms: 1712031045671,
        current_return: 0.7878992291954284,
        initial_entry_price: 68525.8,
        is_closed_position: true,
        miner_hotkey: "5CAajxhosJYA5DrwL4CgAjGENaVmg9Va1DRzDTriGoXvPvyk",
        net_leverage: 7.75,
        open_ms: 1711989565683,
        orders: [
          {
            leverage: 5.0,
            order_type: "LONG",
            order_uuid: "6462a0ef-96a3-4a5b-809d-048660b34a63",
            price: 68525.8,
            price_sources: [],
            processed_ms: 1711989565679,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "5e0dd328-daa0-4a8d-a966-e19c0a7bc959",
            price: 68270.6,
            price_sources: [],
            processed_ms: 1711993177684,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "f2707087-258b-48b5-b9f3-bfb14a9749b3",
            price: 68777.6,
            price_sources: [],
            processed_ms: 1711996800618,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "603c465a-db3f-462d-b9d8-b995010a1638",
            price: 69305.1,
            price_sources: [],
            processed_ms: 1712000412275,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "a86bd667-f6dc-49fd-b49f-1020defa8b73",
            price: 69588.8,
            price_sources: [],
            processed_ms: 1712004035695,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "79904159-b83f-4652-91e0-c11bb0f05cf1",
            price: 69792.8,
            price_sources: [],
            processed_ms: 1712007650291,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "f5cc5324-2442-4330-b204-16ee9ce294d1",
            price: 69741.4,
            price_sources: [],
            processed_ms: 1712011407043,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "45c9b96f-8d60-4658-9cab-a26b3286e6bd",
            price: 69830.1,
            price_sources: [],
            processed_ms: 1712014905871,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "d3f0e918-c319-4220-ba41-6e93d635d989",
            price: 69111.2,
            price_sources: [],
            processed_ms: 1712018521860,
          },
          {
            leverage: -9.25,
            order_type: "SHORT",
            order_uuid: "9a721ba7-0a7b-41d7-8df1-670aab0c5c23",
            price: 69467.5,
            price_sources: [],
            processed_ms: 1712022137625,
          },
        ],
        position_type: "FLAT",
        position_uuid: "8854ebd7-4167-4176-9301-abb02512e46a",
        return_at_close: 0.7611106554027839,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
    ],
    thirty_day_returns: 1.0,
  },
  "5CFPwGAQR27tMSUgRKHD33LJqph222Te37ppUS71T9RqSGNw": {
    all_time_returns: 1.0,
    n_positions: 0,
    percentage_profitable: 0.0,
    positions: [
      {
        average_entry_price: 64194.05,
        close_ms: 1711254267417,
        current_return: 0.9987365651489507,
        initial_entry_price: 64194.05,
        is_closed_position: true,
        miner_hotkey: "5CFPwGAQR27tMSUgRKHD33LJqph222Te37ppUS71T9RqSGNw",
        net_leverage: 0.0,
        open_ms: 1711250671696,
        orders: [
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "5a356711-bd19-49dd-a695-a600e208ba2c",
            price: 64194.05,
            price_sources: [],
            processed_ms: 1711250671696,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "ed71ecb0-3994-4b72-8f08-2c2d933f1fa3",
            price: 63923.7,
            price_sources: [],
            processed_ms: 1711254267417,
          },
        ],
        position_type: "FLAT",
        position_uuid: "4e09df47-46f1-4e1f-943e-1e4b23fec419",
        return_at_close: 0.9981373232098613,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 63924.47,
        close_ms: 1711256102011,
        current_return: 1.0010767707577395,
        initial_entry_price: 63924.47,
        is_closed_position: true,
        miner_hotkey: "5CFPwGAQR27tMSUgRKHD33LJqph222Te37ppUS71T9RqSGNw",
        net_leverage: 0.0,
        open_ms: 1711254372757,
        orders: [
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "1a4e4512-e696-4706-929b-861f1cec37bf",
            price: 63924.47,
            price_sources: [],
            processed_ms: 1711254372756,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "b524d19b-9d31-4b2b-b010-6f9e1b645f05",
            price: 64153.91,
            price_sources: [],
            processed_ms: 1711256102011,
          },
        ],
        position_type: "FLAT",
        position_uuid: "70991727-29f7-4b3e-a421-d195ac5daa81",
        return_at_close: 1.000476124695285,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 64128.549999999996,
        close_ms: 1711258732639,
        current_return: 1.0009006752842533,
        initial_entry_price: 64128.55,
        is_closed_position: true,
        miner_hotkey: "5CFPwGAQR27tMSUgRKHD33LJqph222Te37ppUS71T9RqSGNw",
        net_leverage: 0.0,
        open_ms: 1711256204772,
        orders: [
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "007df4bc-337d-40a2-ba7a-abc0dbd995bd",
            price: 64128.55,
            price_sources: [],
            processed_ms: 1711256204770,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "e55d6367-e688-419b-95b3-bc572a3ffe8a",
            price: 64321.08,
            price_sources: [],
            processed_ms: 1711258732639,
          },
        ],
        position_type: "FLAT",
        position_uuid: "0a19bfc7-6464-4d39-a341-84e8c1ffd01c",
        return_at_close: 1.0003001348790828,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 64317.0,
        close_ms: 1711262398038,
        current_return: 0.9989600338946157,
        initial_entry_price: 64317.0,
        is_closed_position: true,
        miner_hotkey: "5CFPwGAQR27tMSUgRKHD33LJqph222Te37ppUS71T9RqSGNw",
        net_leverage: 0.0,
        open_ms: 1711258819628,
        orders: [
          {
            leverage: 1.25,
            order_type: "LONG",
            order_uuid: "6f9ad2b8-725c-49b0-a1f6-33d675410314",
            price: 64317.0,
            price_sources: [],
            processed_ms: 1711258819627,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "ec739e55-2971-46d4-a635-17ffa182eff5",
            price: 64263.49,
            price_sources: [],
            processed_ms: 1711262398038,
          },
        ],
        position_type: "FLAT",
        position_uuid: "1b4c578a-3369-4f17-b8a2-4ce773a2b413",
        return_at_close: 0.9964626338098792,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 64215.77,
        close_ms: 1711266053089,
        current_return: 0.9976436862783084,
        initial_entry_price: 64215.77,
        is_closed_position: true,
        miner_hotkey: "5CFPwGAQR27tMSUgRKHD33LJqph222Te37ppUS71T9RqSGNw",
        net_leverage: 0.0,
        open_ms: 1711262500648,
        orders: [
          {
            leverage: 1.25,
            order_type: "LONG",
            order_uuid: "fa9452d4-9d26-4ecd-a8df-3cbb1b28ace8",
            price: 64215.77,
            price_sources: [],
            processed_ms: 1711262500647,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "dd083fe3-f48c-45c5-a7da-8df91a089ced",
            price: 64094.72,
            price_sources: [],
            processed_ms: 1711266053089,
          },
        ],
        position_type: "FLAT",
        position_uuid: "198b5b48-d696-4abc-8e20-345e4693b99b",
        return_at_close: 0.9951495770626126,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 64324.58999999999,
        close_ms: 1711269714719,
        current_return: 1.0089427309214098,
        initial_entry_price: 64324.59,
        is_closed_position: true,
        miner_hotkey: "5CFPwGAQR27tMSUgRKHD33LJqph222Te37ppUS71T9RqSGNw",
        net_leverage: 0.0,
        open_ms: 1711266191372,
        orders: [
          {
            leverage: 1.25,
            order_type: "LONG",
            order_uuid: "6bc46600-83c5-4cbc-be96-cc17ec27d6c1",
            price: 64324.59,
            price_sources: [],
            processed_ms: 1711266191371,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "04c51bc0-af28-4332-af1c-ba9adef2d219",
            price: 64784.78,
            price_sources: [],
            processed_ms: 1711269714719,
          },
        ],
        position_type: "FLAT",
        position_uuid: "b743ef43-dc94-40d6-a649-7b3273edefeb",
        return_at_close: 1.0064203740941065,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 64779.52,
        close_ms: 1711273373720,
        current_return: 1.0021142870462763,
        initial_entry_price: 64779.52,
        is_closed_position: true,
        miner_hotkey: "5CFPwGAQR27tMSUgRKHD33LJqph222Te37ppUS71T9RqSGNw",
        net_leverage: 0.0,
        open_ms: 1711269801766,
        orders: [
          {
            leverage: 1.25,
            order_type: "LONG",
            order_uuid: "ca43c9eb-9c79-4690-9ee1-7bb4c929032e",
            price: 64779.52,
            price_sources: [],
            processed_ms: 1711269801765,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "46d4856f-697b-4eaa-ab8c-81775e55769e",
            price: 64889.09,
            price_sources: [],
            processed_ms: 1711273373720,
          },
        ],
        position_type: "FLAT",
        position_uuid: "c67c1c3f-3af2-44bb-8566-4ecaf7c5482b",
        return_at_close: 0.9996090013286606,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 64913.45,
        close_ms: 1711277079265,
        current_return: 1.0050634729782504,
        initial_entry_price: 64913.45,
        is_closed_position: true,
        miner_hotkey: "5CFPwGAQR27tMSUgRKHD33LJqph222Te37ppUS71T9RqSGNw",
        net_leverage: 0.0,
        open_ms: 1711273460650,
        orders: [
          {
            leverage: 1.25,
            order_type: "LONG",
            order_uuid: "a012185c-25c7-4c61-8ebb-ef6b904e48ed",
            price: 64913.45,
            price_sources: [],
            processed_ms: 1711273460649,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "faab326b-9615-4bbb-a16c-6b0173e9b640",
            price: 65176.4,
            price_sources: [],
            processed_ms: 1711277079265,
          },
        ],
        position_type: "FLAT",
        position_uuid: "adef534d-cafb-475e-be0a-c5bfd6607d78",
        return_at_close: 1.0025508142958048,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 65176.4,
        close_ms: 1711282382298,
        current_return: 1.0029073023364286,
        initial_entry_price: 65176.4,
        is_closed_position: true,
        miner_hotkey: "5CFPwGAQR27tMSUgRKHD33LJqph222Te37ppUS71T9RqSGNw",
        net_leverage: 0.0,
        open_ms: 1711277098408,
        orders: [
          {
            leverage: 1.25,
            order_type: "LONG",
            order_uuid: "e2c22cb8-2c38-4bcb-b10b-a59882d00011",
            price: 65176.4,
            price_sources: [],
            processed_ms: 1711277098407,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "2f9bac90-d609-4fb2-a06c-bd0a813d6447",
            price: 65327.99,
            price_sources: [],
            processed_ms: 1711282382298,
          },
        ],
        position_type: "FLAT",
        position_uuid: "4378afcc-f794-4d6b-95bd-c04d7afc47b9",
        return_at_close: 1.0004000340805876,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 65706.06,
        close_ms: 1711309548086,
        current_return: 1.002701805586882,
        initial_entry_price: 65706.06,
        is_closed_position: true,
        miner_hotkey: "5CFPwGAQR27tMSUgRKHD33LJqph222Te37ppUS71T9RqSGNw",
        net_leverage: 0.0,
        open_ms: 1711308639402,
        orders: [
          {
            leverage: 1.25,
            order_type: "LONG",
            order_uuid: "1653dced-6c3e-4848-8d21-6db4fc7324f1",
            price: 65706.06,
            price_sources: [],
            processed_ms: 1711308639401,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "6d624173-9360-49ad-944d-4d207cdb1116",
            price: 65848.08,
            price_sources: [],
            processed_ms: 1711309548086,
          },
        ],
        position_type: "FLAT",
        position_uuid: "ef4908e4-0c78-45c7-9d23-02ae32ef3fcc",
        return_at_close: 1.0001950510729147,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 66855.72,
        close_ms: 1711326322672,
        current_return: 1.001408630405895,
        initial_entry_price: 66855.72,
        is_closed_position: true,
        miner_hotkey: "5CFPwGAQR27tMSUgRKHD33LJqph222Te37ppUS71T9RqSGNw",
        net_leverage: 0.0,
        open_ms: 1711325364791,
        orders: [
          {
            leverage: 1.25,
            order_type: "LONG",
            order_uuid: "c69f0506-bf9e-4134-83fb-5d49ffba34b1",
            price: 66855.72,
            price_sources: [],
            processed_ms: 1711325364790,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "a48a02a0-fd5a-4cc0-98bf-8ded24221c1e",
            price: 66931.06,
            price_sources: [],
            processed_ms: 1711326322672,
          },
        ],
        position_type: "FLAT",
        position_uuid: "50b7de7c-2859-45ed-a355-94e960b9f64a",
        return_at_close: 0.9989051088298803,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 66631.13,
        close_ms: 1711328170827,
        current_return: 0.9992355299992661,
        initial_entry_price: 66631.13,
        is_closed_position: true,
        miner_hotkey: "5CFPwGAQR27tMSUgRKHD33LJqph222Te37ppUS71T9RqSGNw",
        net_leverage: 0.0,
        open_ms: 1711327238783,
        orders: [
          {
            leverage: 1.25,
            order_type: "LONG",
            order_uuid: "154d6af1-fde1-46d1-a002-f32ac7612bb4",
            price: 66631.13,
            price_sources: [],
            processed_ms: 1711327238782,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "19544c2e-df02-4f18-a063-2925435ba584",
            price: 66590.38,
            price_sources: [],
            processed_ms: 1711328170827,
          },
        ],
        position_type: "FLAT",
        position_uuid: "eb4ec3d6-161e-4227-adfb-64fb4552f832",
        return_at_close: 0.996737441174268,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: -569972.8365765777,
        close_ms: 1711383633851,
        current_return: 1.021089486401729,
        initial_entry_price: 67336.87,
        is_closed_position: true,
        miner_hotkey: "5CFPwGAQR27tMSUgRKHD33LJqph222Te37ppUS71T9RqSGNw",
        net_leverage: 0.0,
        open_ms: 1711361408972,
        orders: [
          {
            leverage: 1.25,
            order_type: "LONG",
            order_uuid: "9026fc34-c06c-4f81-8594-9e03c33c4ab4",
            price: 67336.87,
            price_sources: [],
            processed_ms: 1711361408970,
          },
          {
            leverage: -0.625,
            order_type: "SHORT",
            order_uuid: "2ee72b0f-c75a-4bb9-9d35-79530c5b6dec",
            price: 66884.72,
            price_sources: [],
            processed_ms: 1711366419042,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "f796ff34-2167-448b-bc10-0f0052da328b",
            price: 67214.49,
            price_sources: [],
            processed_ms: 1711370033330,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "5722a06e-5668-4434-8009-3c9c45de05b4",
            price: 67127.84,
            price_sources: [],
            processed_ms: 1711371852931,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "02a17ba2-dcc5-4956-ab4f-0f99264fd907",
            price: 67185.56,
            price_sources: [],
            processed_ms: 1711373690244,
          },
          {
            leverage: -0.6875,
            order_type: "SHORT",
            order_uuid: "6bdcab87-ae0e-487a-b3f7-eb31484ec82a",
            price: 67982.74,
            price_sources: [],
            processed_ms: 1711375531040,
          },
          {
            leverage: -0.55,
            order_type: "SHORT",
            order_uuid: "a8aef18b-6159-4aeb-bf7b-6104de7c86dc",
            price: 68925.28,
            price_sources: [],
            processed_ms: 1711376409916,
          },
          {
            leverage: -0.10999999999999996,
            order_type: "SHORT",
            order_uuid: "23905f3d-ffb2-41a3-a0c3-4a276d6d3eb1",
            price: 69210.38,
            price_sources: [],
            processed_ms: 1711376739347,
          },
          {
            leverage: 0.24999999999999997,
            order_type: "LONG",
            order_uuid: "f41da1ec-2d3f-4f3b-9d12-a9ec7ec002bf",
            price: 69095.56,
            price_sources: [],
            processed_ms: 1711377879764,
          },
          {
            leverage: -0.22199999999999998,
            order_type: "SHORT",
            order_uuid: "1d7dc1e9-b27b-450d-8aaf-d541424dc74e",
            price: 69074.0,
            price_sources: [],
            processed_ms: 1711378171855,
          },
          {
            leverage: -0.044399999999999995,
            order_type: "SHORT",
            order_uuid: "ec32643f-7a7c-44f1-b3f8-0536850de6d8",
            price: 69226.32,
            price_sources: [],
            processed_ms: 1711379964533,
          },
          {
            leverage: -0.008879999999999999,
            order_type: "SHORT",
            order_uuid: "3af79f4b-0b38-46ca-928b-cfdaa6a4175e",
            price: 69941.84,
            price_sources: [],
            processed_ms: 1711381783741,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "8fa4933c-4736-4778-968a-59e0894f253f",
            price: 69711.85,
            price_sources: [],
            processed_ms: 1711383633851,
          },
        ],
        position_type: "FLAT",
        position_uuid: "5dc86d1d-0a78-4729-b4d6-52782b2432f7",
        return_at_close: 1.0182814903141242,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 65775.73000000001,
        close_ms: 1711392859747,
        current_return: 0.9909452510360006,
        initial_entry_price: 70016.85,
        is_closed_position: true,
        miner_hotkey: "5CFPwGAQR27tMSUgRKHD33LJqph222Te37ppUS71T9RqSGNw",
        net_leverage: 0.0,
        open_ms: 1711385418135,
        orders: [
          {
            leverage: -1.25,
            order_type: "SHORT",
            order_uuid: "712c6fb3-64be-40d8-8055-1b9db1f89cab",
            price: 70016.85,
            price_sources: [],
            processed_ms: 1711385418134,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "a47564ed-7cf2-430c-bb76-e06ed590f5f3",
            price: 70500.0,
            price_sources: [],
            processed_ms: 1711387232040,
          },
          {
            leverage: 0.5,
            order_type: "LONG",
            order_uuid: "8595ce22-19ef-4ef2-938c-d103c09e682f",
            price: 70329.54,
            price_sources: [],
            processed_ms: 1711389071560,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "c869f4f1-8166-4540-bb93-6552e4509968",
            price: 70654.52,
            price_sources: [],
            processed_ms: 1711390868182,
          },
          {
            leverage: 0.125,
            order_type: "LONG",
            order_uuid: "8b845f12-e3a7-4904-8ef4-a797c3da64f7",
            price: 70765.57,
            price_sources: [],
            processed_ms: 1711392680838,
          },
          {
            leverage: 0.125,
            order_type: "LONG",
            order_uuid: "e1341c48-5b27-4061-a540-222fe10cbd84",
            price: 70847.61,
            price_sources: [],
            processed_ms: 1711392859747,
          },
        ],
        position_type: "FLAT",
        position_uuid: "6778b921-7add-4303-83cf-a34e4121efc1",
        return_at_close: 0.9884678879084107,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 71623.58000000003,
        close_ms: 1711402414219,
        current_return: 1.017688383930026,
        initial_entry_price: 71038.57,
        is_closed_position: true,
        miner_hotkey: "5CFPwGAQR27tMSUgRKHD33LJqph222Te37ppUS71T9RqSGNw",
        net_leverage: 0.0,
        open_ms: 1711396968942,
        orders: [
          {
            leverage: -3.0,
            order_type: "SHORT",
            order_uuid: "8a9d99a3-8030-4661-afb5-3bf9b72a820f",
            price: 71038.57,
            price_sources: [],
            processed_ms: 1711396968941,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "90cd4abc-ba6c-4631-b264-db06c5e5ee81",
            price: 70824.47,
            price_sources: [],
            processed_ms: 1711398796619,
          },
          {
            leverage: 0.75,
            order_type: "LONG",
            order_uuid: "0893c7b9-ce24-4f6e-9fda-2f06833412ff",
            price: 70881.76,
            price_sources: [],
            processed_ms: 1711400599101,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "49343a3f-f090-4ce7-bdb5-46ab74d2dc7c",
            price: 69948.17,
            price_sources: [],
            processed_ms: 1711402414219,
          },
        ],
        position_type: "FLAT",
        position_uuid: "39e610f7-4af6-4178-8f69-45d4e0e0597e",
        return_at_close: 1.0115822536264458,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 72008.02000000002,
        close_ms: 1711416983332,
        current_return: 0.9924616376833284,
        initial_entry_price: 70021.84,
        is_closed_position: true,
        miner_hotkey: "5CFPwGAQR27tMSUgRKHD33LJqph222Te37ppUS71T9RqSGNw",
        net_leverage: 0.0,
        open_ms: 1711404232354,
        orders: [
          {
            leverage: 3.0,
            order_type: "LONG",
            order_uuid: "188aba31-0f09-475c-ad68-6ac989577368",
            price: 70021.84,
            price_sources: [],
            processed_ms: 1711404232353,
          },
          {
            leverage: 0.8999999999999999,
            order_type: "LONG",
            order_uuid: "5ec2ca20-2bc1-47ca-a18b-7ca44aac2be8",
            price: 70415.95,
            price_sources: [],
            processed_ms: 1711406052303,
          },
          {
            leverage: 0.8999999999999999,
            order_type: "LONG",
            order_uuid: "fe183b63-b2ad-460c-917a-07a8b371a556",
            price: 70309.85,
            price_sources: [],
            processed_ms: 1711407869341,
          },
          {
            leverage: -2.4,
            order_type: "SHORT",
            order_uuid: "c7897a61-941e-4f9a-8a69-277558bda7b2",
            price: 70129.39,
            price_sources: [],
            processed_ms: 1711409680393,
          },
          {
            leverage: -1.2,
            order_type: "SHORT",
            order_uuid: "72d4e031-d209-4ed8-8dd5-8fb212210b30",
            price: 69789.99,
            price_sources: [],
            processed_ms: 1711411498582,
          },
          {
            leverage: -0.6,
            order_type: "SHORT",
            order_uuid: "6d6d02a9-e5be-41d8-8507-722d741c6978",
            price: 70115.67,
            price_sources: [],
            processed_ms: 1711413310610,
          },
          {
            leverage: -0.3,
            order_type: "SHORT",
            order_uuid: "b9eabe79-e620-4b8f-8e3b-3ff92adcd114",
            price: 69961.36,
            price_sources: [],
            processed_ms: 1711415125492,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "71808f8b-9b10-49c5-99e4-7f6215a1edd4",
            price: 70248.52,
            price_sources: [],
            processed_ms: 1711416983332,
          },
        ],
        position_type: "FLAT",
        position_uuid: "442266ff-53a7-46e2-8d91-320ec865f0a8",
        return_at_close: 0.9829340059615684,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 69905.95625000005,
        close_ms: 1711438249199,
        current_return: 1.0546893576060148,
        initial_entry_price: 70310.59,
        is_closed_position: true,
        miner_hotkey: "5CFPwGAQR27tMSUgRKHD33LJqph222Te37ppUS71T9RqSGNw",
        net_leverage: 0.0,
        open_ms: 1711431479867,
        orders: [
          {
            leverage: 3.0,
            order_type: "LONG",
            order_uuid: "d04c0255-5f84-4843-aa35-dec3b23fa098",
            price: 70310.59,
            price_sources: [],
            processed_ms: 1711431479865,
          },
          {
            leverage: 0.8999999999999999,
            order_type: "LONG",
            order_uuid: "a2066564-7d3e-4f79-9901-3cbc94df0be8",
            price: 70402.43,
            price_sources: [],
            processed_ms: 1711433302143,
          },
          {
            leverage: 0.8999999999999999,
            order_type: "LONG",
            order_uuid: "3964b2cd-012f-4d14-9b75-87542627e500",
            price: 70500.1,
            price_sources: [],
            processed_ms: 1711435115587,
          },
          {
            leverage: -2.4,
            order_type: "SHORT",
            order_uuid: "13b4e961-4a96-4d3c-919b-0305fc25102d",
            price: 70820.73,
            price_sources: [],
            processed_ms: 1711436925464,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "8d98a386-a027-4f06-b3b0-083e574554b5",
            price: 71508.14,
            price_sources: [],
            processed_ms: 1711438249199,
          },
        ],
        position_type: "FLAT",
        position_uuid: "cccb74ac-affa-40d4-9c60-68df1a04b89f",
        return_at_close: 1.044564339772997,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 70908.41692307693,
        close_ms: 1711471610329,
        current_return: 0.9634611262228786,
        initial_entry_price: 70254.3,
        is_closed_position: true,
        miner_hotkey: "5CFPwGAQR27tMSUgRKHD33LJqph222Te37ppUS71T9RqSGNw",
        net_leverage: 0.0,
        open_ms: 1711467811467,
        orders: [
          {
            leverage: 3.0,
            order_type: "LONG",
            order_uuid: "8794ce03-67cc-4707-9fe4-1258f9ace42e",
            price: 70254.3,
            price_sources: [],
            processed_ms: 1711467811466,
          },
          {
            leverage: 0.8999999999999999,
            order_type: "LONG",
            order_uuid: "068de6b3-0eae-4f11-bd9d-e6062a34e95b",
            price: 70078.49,
            price_sources: [],
            processed_ms: 1711469659440,
          },
          {
            leverage: -1.95,
            order_type: "SHORT",
            order_uuid: "00817a0c-482a-4b51-9020-d3b1bc0f65d1",
            price: 69519.04,
            price_sources: [],
            processed_ms: 1711471457113,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "8233847b-07d7-456d-9b10-fe1fdb772c3d",
            price: 69592.0,
            price_sources: [],
            processed_ms: 1711471610329,
          },
        ],
        position_type: "FLAT",
        position_uuid: "71c3becd-3d9e-41f7-bd7e-03ee41de8355",
        return_at_close: 0.9559461294383401,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 69858.35,
        close_ms: 1711483216661,
        current_return: 0.9673151477443723,
        initial_entry_price: 69562.04,
        is_closed_position: true,
        miner_hotkey: "5CFPwGAQR27tMSUgRKHD33LJqph222Te37ppUS71T9RqSGNw",
        net_leverage: 0.0,
        open_ms: 1711473263197,
        orders: [
          {
            leverage: 3.0,
            order_type: "LONG",
            order_uuid: "f7f3e7cd-8177-43d0-a739-9849343da58b",
            price: 69562.04,
            price_sources: [],
            processed_ms: 1711473263192,
          },
          {
            leverage: 0.8999999999999999,
            order_type: "LONG",
            order_uuid: "67391384-9de8-448c-9096-f24565bdccf2",
            price: 70136.02,
            price_sources: [],
            processed_ms: 1711475094418,
          },
          {
            leverage: 0.8999999999999999,
            order_type: "LONG",
            order_uuid: "5865b902-b317-4fab-9629-4bb13fc12c40",
            price: 70238.68,
            price_sources: [],
            processed_ms: 1711476916505,
          },
          {
            leverage: 0.8999999999999995,
            order_type: "LONG",
            order_uuid: "c6a5c0b2-2763-407e-9d7a-8ccbde85a04c",
            price: 70301.92,
            price_sources: [],
            processed_ms: 1711478760216,
          },
          {
            leverage: 0.9000000000000004,
            order_type: "LONG",
            order_uuid: "aeb58076-7838-4eb2-98e9-43020f9cc36d",
            price: 69806.83,
            price_sources: [],
            processed_ms: 1711480601774,
          },
          {
            leverage: 0.9000000000000004,
            order_type: "LONG",
            order_uuid: "77bac8f0-7962-445b-8eed-2088b7b018ca",
            price: 69796.0,
            price_sources: [],
            processed_ms: 1711482349983,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "8b3b1fee-570e-4af7-b42e-41e0c8be1fce",
            price: 69555.2,
            price_sources: [],
            processed_ms: 1711483216661,
          },
        ],
        position_type: "FLAT",
        position_uuid: "80b209d3-b2fd-49cf-bd58-0f71951178c9",
        return_at_close: 0.9528054205282067,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 69905.85852941177,
        close_ms: 1711499961397,
        current_return: 1.0524884528132956,
        initial_entry_price: 69692.3,
        is_closed_position: true,
        miner_hotkey: "5CFPwGAQR27tMSUgRKHD33LJqph222Te37ppUS71T9RqSGNw",
        net_leverage: 0.0,
        open_ms: 1711484168207,
        orders: [
          {
            leverage: 3.0,
            order_type: "LONG",
            order_uuid: "d98b6036-0a65-4206-a576-1e13acddf75f",
            price: 69692.3,
            price_sources: [],
            processed_ms: 1711484168206,
          },
          {
            leverage: 0.8999999999999999,
            order_type: "LONG",
            order_uuid: "8182a75b-41c0-4ef9-b79a-5bee8c6a1558",
            price: 69758.0,
            price_sources: [],
            processed_ms: 1711485983565,
          },
          {
            leverage: 0.8999999999999999,
            order_type: "LONG",
            order_uuid: "c041e9a0-add5-4014-b250-32e64f9b783e",
            price: 69877.8,
            price_sources: [],
            processed_ms: 1711487798409,
          },
          {
            leverage: 0.8999999999999995,
            order_type: "LONG",
            order_uuid: "46f6062a-60ec-4e56-a2ff-6e1d7d72cf86",
            price: 70089.01,
            price_sources: [],
            processed_ms: 1711489698748,
          },
          {
            leverage: 0.9000000000000004,
            order_type: "LONG",
            order_uuid: "ceef24cc-c737-41b5-95a5-b22ecd8cff59",
            price: 69851.9,
            price_sources: [],
            processed_ms: 1711491474618,
          },
          {
            leverage: 0.9000000000000004,
            order_type: "LONG",
            order_uuid: "7a189c86-5bc0-453a-b3a3-4e3558ac0126",
            price: 70085.2,
            price_sources: [],
            processed_ms: 1711493254193,
          },
          {
            leverage: 0.9000000000000004,
            order_type: "LONG",
            order_uuid: "5b4ff3f8-5bc2-4c48-b3ad-77ed7ae82c0b",
            price: 70305.7,
            price_sources: [],
            processed_ms: 1711495074972,
          },
          {
            leverage: 0.9000000000000004,
            order_type: "LONG",
            order_uuid: "89705802-e9b7-449e-bce7-87e59d2f7565",
            price: 70051.19,
            price_sources: [],
            processed_ms: 1711496889875,
          },
          {
            leverage: 0.9000000000000004,
            order_type: "LONG",
            order_uuid: "f44a06a8-9afd-4532-9027-144fee92b990",
            price: 69939.93,
            price_sources: [],
            processed_ms: 1711498703392,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "e1bf2e91-4fbd-4510-91af-398f0f319c5e",
            price: 70264.49,
            price_sources: [],
            processed_ms: 1711499961397,
          },
        ],
        position_type: "FLAT",
        position_uuid: "7bf78207-f2df-4cb7-901c-75a9a214061c",
        return_at_close: 1.0310176883759044,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 70370.83321428571,
        close_ms: 1711515226356,
        current_return: 0.9670338366466946,
        initial_entry_price: 70398.82,
        is_closed_position: true,
        miner_hotkey: "5CFPwGAQR27tMSUgRKHD33LJqph222Te37ppUS71T9RqSGNw",
        net_leverage: 0.0,
        open_ms: 1711504155126,
        orders: [
          {
            leverage: 3.0,
            order_type: "LONG",
            order_uuid: "91e8efa3-d99b-46a3-9361-42bd14245ef8",
            price: 70398.82,
            price_sources: [],
            processed_ms: 1711504155124,
          },
          {
            leverage: 0.8999999999999999,
            order_type: "LONG",
            order_uuid: "50cbbd58-b300-4b4d-988b-63abf8cdeb1d",
            price: 70224.81,
            price_sources: [],
            processed_ms: 1711505971540,
          },
          {
            leverage: 0.8999999999999999,
            order_type: "LONG",
            order_uuid: "eb5e7127-9212-46b8-9167-ed5206948153",
            price: 70398.43,
            price_sources: [],
            processed_ms: 1711507786417,
          },
          {
            leverage: 0.8999999999999995,
            order_type: "LONG",
            order_uuid: "de15cd2e-e875-4c08-b1a5-5f6ac90c3c79",
            price: 70539.53,
            price_sources: [],
            processed_ms: 1711509599596,
          },
          {
            leverage: 0.9000000000000004,
            order_type: "LONG",
            order_uuid: "206fcff5-0517-4701-9cce-6befb9c15924",
            price: 70446.32,
            price_sources: [],
            processed_ms: 1711511424112,
          },
          {
            leverage: 0.9000000000000004,
            order_type: "LONG",
            order_uuid: "57e03abf-75c7-4b11-b304-9886db747442",
            price: 70267.28,
            price_sources: [],
            processed_ms: 1711513234960,
          },
          {
            leverage: 0.9000000000000004,
            order_type: "LONG",
            order_uuid: "7da27740-b941-4112-bd2d-91c363aa41f1",
            price: 70255.34,
            price_sources: [],
            processed_ms: 1711515052448,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "c2e1aab6-0f7e-4e31-bd07-2fa59961ff37",
            price: 70094.55,
            price_sources: [],
            processed_ms: 1711515226356,
          },
        ],
        position_type: "FLAT",
        position_uuid: "e70ec243-f049-4695-b3aa-85cdad487e4f",
        return_at_close: 0.9507876681910301,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 71518.25,
        close_ms: 1711525185993,
        current_return: 0.9716979041041549,
        initial_entry_price: 70381.36,
        is_closed_position: true,
        miner_hotkey: "5CFPwGAQR27tMSUgRKHD33LJqph222Te37ppUS71T9RqSGNw",
        net_leverage: 0.0,
        open_ms: 1711516870289,
        orders: [
          {
            leverage: 3.0,
            order_type: "LONG",
            order_uuid: "41d409aa-2718-4a43-8b48-a4c6bcc12ec3",
            price: 70381.36,
            price_sources: [],
            processed_ms: 1711516870286,
          },
          {
            leverage: 0.8999999999999999,
            order_type: "LONG",
            order_uuid: "d34ab28a-b5f9-406a-a256-c40acdca3861",
            price: 70431.7,
            price_sources: [],
            processed_ms: 1711518680282,
          },
          {
            leverage: 0.8999999999999999,
            order_type: "LONG",
            order_uuid: "59ab7b60-eef9-4e9b-9cb7-a235f7afd884",
            price: 70404.42,
            price_sources: [],
            processed_ms: 1711520499696,
          },
          {
            leverage: -2.4,
            order_type: "SHORT",
            order_uuid: "50915123-386d-4c3c-9f00-340e610c3047",
            price: 70186.12,
            price_sources: [],
            processed_ms: 1711522316488,
          },
          {
            leverage: -1.2,
            order_type: "SHORT",
            order_uuid: "05b01ae6-6a8a-427b-b24a-af80a669fed8",
            price: 69690.0,
            price_sources: [],
            processed_ms: 1711525083394,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "34e04996-86e9-4c54-aaee-66223c00f71a",
            price: 69858.3,
            price_sources: [],
            processed_ms: 1711525185993,
          },
        ],
        position_type: "FLAT",
        position_uuid: "a9e45293-a354-449d-a22b-cca2d008408d",
        return_at_close: 0.9623696042247549,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 68332.8300000013,
        close_ms: 1711563533365,
        current_return: 1.000195595816469,
        initial_entry_price: 69689.35,
        is_closed_position: true,
        miner_hotkey: "5CFPwGAQR27tMSUgRKHD33LJqph222Te37ppUS71T9RqSGNw",
        net_leverage: 0.0,
        open_ms: 1711530492125,
        orders: [
          {
            leverage: 3.0,
            order_type: "LONG",
            order_uuid: "0129f3d5-3e7f-47f8-8138-9c263d4ff0b8",
            price: 69689.35,
            price_sources: [],
            processed_ms: 1711530492123,
          },
          {
            leverage: -1.5,
            order_type: "SHORT",
            order_uuid: "6df0c1dd-4ad4-47fe-99ce-6516c930d350",
            price: 69893.56,
            price_sources: [],
            processed_ms: 1711532310351,
          },
          {
            leverage: 0.8999999999999999,
            order_type: "LONG",
            order_uuid: "6870efa4-016d-41a9-990e-10d14f7809e7",
            price: 69929.23,
            price_sources: [],
            processed_ms: 1711534125640,
          },
          {
            leverage: 0.8999999999999999,
            order_type: "LONG",
            order_uuid: "e99a816d-2497-4d2a-902c-b2bb322315e7",
            price: 70059.04,
            price_sources: [],
            processed_ms: 1711536209179,
          },
          {
            leverage: 0.8999999999999995,
            order_type: "LONG",
            order_uuid: "4a59e899-fb5d-410e-8921-2182181185a2",
            price: 70286.95,
            price_sources: [],
            processed_ms: 1711539827203,
          },
          {
            leverage: 0.9000000000000004,
            order_type: "LONG",
            order_uuid: "bb5a338b-ca41-4507-ad8c-eedd111fbefa",
            price: 70054.52,
            price_sources: [],
            processed_ms: 1711543518255,
          },
          {
            leverage: -2.55,
            order_type: "SHORT",
            order_uuid: "16f530bc-66df-499b-8b3c-8a11b3b1ee9f",
            price: 70384.62,
            price_sources: [],
            processed_ms: 1711547062551,
          },
          {
            leverage: -1.275,
            order_type: "SHORT",
            order_uuid: "31c2fbbb-a5b7-4902-91a1-bc84b23d5c5b",
            price: 69923.63,
            price_sources: [],
            processed_ms: 1711550672363,
          },
          {
            leverage: -0.6375,
            order_type: "SHORT",
            order_uuid: "a1c08161-4121-4bb8-8206-70e336eacf86",
            price: 69030.59,
            price_sources: [],
            processed_ms: 1711554449506,
          },
          {
            leverage: -0.31875,
            order_type: "SHORT",
            order_uuid: "22c284f4-0bf7-4227-a0e0-6e4984882181",
            price: 68655.73,
            price_sources: [],
            processed_ms: 1711556266813,
          },
          {
            leverage: -0.159375,
            order_type: "SHORT",
            order_uuid: "51e922bc-2498-4155-a7b1-e9de2013ccd7",
            price: 69064.3,
            price_sources: [],
            processed_ms: 1711558076383,
          },
          {
            leverage: -0.0796875,
            order_type: "SHORT",
            order_uuid: "eb84156a-380b-4d27-a54d-bf6bd3b93e18",
            price: 69265.74,
            price_sources: [],
            processed_ms: 1711559938397,
          },
          {
            leverage: -0.03984375,
            order_type: "SHORT",
            order_uuid: "5687ce11-8b8f-4a11-95c2-ca80f8aa4ad3",
            price: 69036.65,
            price_sources: [],
            processed_ms: 1711561712226,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "c4540f8f-f2cf-4950-b156-27669d58c03c",
            price: 68674.94,
            price_sources: [],
            processed_ms: 1711563533365,
          },
        ],
        position_type: "FLAT",
        position_uuid: "4821f033-b9fb-48f2-9b67-6e28eec892c3",
        return_at_close: 0.989993600739141,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 67712.91,
        close_ms: 1711578100166,
        current_return: 0.993416516021254,
        initial_entry_price: 68886.93,
        is_closed_position: true,
        miner_hotkey: "5CFPwGAQR27tMSUgRKHD33LJqph222Te37ppUS71T9RqSGNw",
        net_leverage: 0.0,
        open_ms: 1711570806737,
        orders: [
          {
            leverage: -3.5,
            order_type: "SHORT",
            order_uuid: "f35a3ddc-634e-4254-b231-2816259c4307",
            price: 68886.93,
            price_sources: [],
            processed_ms: 1711570806735,
          },
          {
            leverage: 1.0499999999999998,
            order_type: "LONG",
            order_uuid: "f0b95bf2-c8bd-43cd-981e-dc3939a03d80",
            price: 68847.22,
            price_sources: [],
            processed_ms: 1711572681328,
          },
          {
            leverage: 1.05,
            order_type: "LONG",
            order_uuid: "d2f0f89d-cc80-457c-ba71-b69749b6a7c9",
            price: 69113.12,
            price_sources: [],
            processed_ms: 1711574447312,
          },
          {
            leverage: 1.05,
            order_type: "LONG",
            order_uuid: "6b5eab9f-5d0c-4553-85bd-8fd6976d22fd",
            price: 69091.79,
            price_sources: [],
            processed_ms: 1711576272793,
          },
          {
            leverage: 1.05,
            order_type: "LONG",
            order_uuid: "11c1509b-ec75-4efb-b7b8-94aa1991c2da",
            price: 69008.67,
            price_sources: [],
            processed_ms: 1711578100166,
          },
        ],
        position_type: "FLAT",
        position_uuid: "2673b3b9-7ab3-4d19-b22f-cdd82e886401",
        return_at_close: 0.9864626004091053,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 68879.14000000003,
        close_ms: 1711587191641,
        current_return: 0.9924057552997975,
        initial_entry_price: 69326.71,
        is_closed_position: true,
        miner_hotkey: "5CFPwGAQR27tMSUgRKHD33LJqph222Te37ppUS71T9RqSGNw",
        net_leverage: 0.0,
        open_ms: 1711579919210,
        orders: [
          {
            leverage: -3.5,
            order_type: "SHORT",
            order_uuid: "31461474-e950-494a-b8aa-713a3bae70e2",
            price: 69326.71,
            price_sources: [],
            processed_ms: 1711579919208,
          },
          {
            leverage: 1.0499999999999998,
            order_type: "LONG",
            order_uuid: "58eb89f0-6db1-4565-bcbe-6e46fb13ecae",
            price: 69216.24,
            price_sources: [],
            processed_ms: 1711581738345,
          },
          {
            leverage: 1.05,
            order_type: "LONG",
            order_uuid: "d0de5399-8009-42f6-8558-1a48238a4da7",
            price: 69395.9,
            price_sources: [],
            processed_ms: 1711583552672,
          },
          {
            leverage: 0.7000000000000001,
            order_type: "LONG",
            order_uuid: "23469069-1a18-490a-b5e2-995835a5b619",
            price: 69836.2,
            price_sources: [],
            processed_ms: 1711585376604,
          },
          {
            leverage: 1.05,
            order_type: "LONG",
            order_uuid: "91845ab0-8dd2-4662-a8e9-a28cdff4f706",
            price: 69631.26,
            price_sources: [],
            processed_ms: 1711587191641,
          },
        ],
        position_type: "FLAT",
        position_uuid: "5425866d-9954-47e5-b0a3-5d7eddf5338d",
        return_at_close: 0.9854589150126989,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
    ],
    thirty_day_returns: 1.0,
  },
  "5CPwzn7JDkh9kjjqpC4zFHnZNBjL6JrBnY91cwLjyBwVUKy5": {
    all_time_returns: 0.9111176438654744,
    n_positions: 4,
    percentage_profitable: 0.25,
    positions: [
      {
        average_entry_price: 1.37281,
        close_ms: 1715252159655,
        current_return: 1.0000189392559786,
        initial_entry_price: 1.37281,
        is_closed_position: true,
        miner_hotkey: "5CPwzn7JDkh9kjjqpC4zFHnZNBjL6JrBnY91cwLjyBwVUKy5",
        net_leverage: 0.0,
        open_ms: 1715248970211,
        orders: [
          {
            leverage: -0.2,
            order_type: "SHORT",
            order_uuid: "a8503d43-be74-4f09-a048-d9b6cff368c1",
            price: 1.37281,
            price_sources: [],
            processed_ms: 1715248970032,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "c41f4e94-44f1-4c77-9c78-04ce15044884",
            price: 1.37268,
            price_sources: [],
            processed_ms: 1715252159655,
          },
        ],
        position_type: "FLAT",
        position_uuid: "5ef15b45-c591-46c4-812c-14dea397d70b",
        return_at_close: 1.000004938990829,
        trade_pair: ["USDCAD", "USD/CAD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 3681.684019975031,
        close_ms: 1718392408748,
        current_return: 0.9154882743896141,
        initial_entry_price: 2903.9,
        is_closed_position: true,
        miner_hotkey: "5CPwzn7JDkh9kjjqpC4zFHnZNBjL6JrBnY91cwLjyBwVUKy5",
        net_leverage: 0.0,
        open_ms: 1715369203214,
        orders: [
          {
            leverage: 0.001,
            order_type: "LONG",
            order_uuid: "61a8ecf8-ab65-4d62-8808-663122a7c1fe",
            price: 2903.9,
            price_sources: [],
            processed_ms: 1715369202997,
          },
          {
            leverage: 0.4,
            order_type: "LONG",
            order_uuid: "05587681-1115-4b0b-99c2-aa24947441f4",
            price: 3770.14,
            price_sources: [],
            processed_ms: 1716306735420,
          },
          {
            leverage: 0.5,
            order_type: "LONG",
            order_uuid: "b89ac64f-b94b-4343-9524-39a26c83e9e2",
            price: 3820.47,
            price_sources: [],
            processed_ms: 1716459935878,
          },
          {
            leverage: -0.7,
            order_type: "SHORT",
            order_uuid: "e1b525bc-c2e9-420e-9782-1ca9d8de4719",
            price: 3924.8,
            price_sources: [],
            processed_ms: 1716468332445,
          },
          {
            leverage: 0.6,
            order_type: "LONG",
            order_uuid: "7926e121-c6da-4361-8ec8-a85ba2f76443",
            price: 3791.99,
            price_sources: [],
            processed_ms: 1717585310681,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "c8b588ac-59a6-438a-b208-ac8eb04fe04f",
            price: 3375.3,
            price_sources: [],
            processed_ms: 1718392408748,
          },
        ],
        position_type: "FLAT",
        position_uuid: "4435d517-60c6-4968-8ba7-6353a5f62ef0",
        return_at_close: 0.9141141264897553,
        trade_pair: ["ETHUSD", "ETH/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 0.5483999999999929,
        close_ms: 1718392452303,
        current_return: 0.9982838045412376,
        initial_entry_price: 0.65753,
        is_closed_position: true,
        miner_hotkey: "5CPwzn7JDkh9kjjqpC4zFHnZNBjL6JrBnY91cwLjyBwVUKy5",
        net_leverage: 0.0,
        open_ms: 1715248928034,
        orders: [
          {
            leverage: -0.2,
            order_type: "SHORT",
            order_uuid: "ec795b16-5857-4e63-9c50-8e67604143c0",
            price: 0.65753,
            price_sources: [],
            processed_ms: 1715248927860,
          },
          {
            leverage: -0.2,
            order_type: "SHORT",
            order_uuid: "9b673e95-a658-41b5-aec0-093395702169",
            price: 0.6604,
            price_sources: [],
            processed_ms: 1715272641128,
          },
          {
            leverage: 0.39,
            order_type: "LONG",
            order_uuid: "bd6eaf2c-c466-4f72-9e4d-03f2f555a839",
            price: 0.6618,
            price_sources: [],
            processed_ms: 1715704380657,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "eef77268-e0d8-4691-a1e2-f25666ffc996",
            price: 0.6612450000000001,
            price_sources: [],
            processed_ms: 1718392452303,
          },
        ],
        position_type: "FLAT",
        position_uuid: "08a46ef3-1580-467c-a692-689e078ee723",
        return_at_close: 0.9982558525947104,
        trade_pair: ["AUDUSD", "AUD/USD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.9107222964501851,
        close_ms: 1718392500428,
        current_return: 0.9985144347958033,
        initial_entry_price: 0.902678782777939,
        is_closed_position: true,
        miner_hotkey: "5CPwzn7JDkh9kjjqpC4zFHnZNBjL6JrBnY91cwLjyBwVUKy5",
        net_leverage: 0.0,
        open_ms: 1715248905429,
        orders: [
          {
            leverage: 0.2,
            order_type: "LONG",
            order_uuid: "107ad717-51d3-401f-88be-8863a8013669",
            price: 0.902678782777939,
            price_sources: [],
            processed_ms: 1715248905428,
          },
          {
            leverage: -0.199,
            order_type: "SHORT",
            order_uuid: "2c8146e3-4a43-434f-8d2a-3e5052464333",
            price: 0.90364,
            price_sources: [],
            processed_ms: 1715257452896,
          },
          {
            leverage: 0.6,
            order_type: "LONG",
            order_uuid: "2f30723a-756f-4b02-9ba5-3a2039938837",
            price: 0.911054506018289,
            price_sources: [],
            processed_ms: 1716159997896,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "a9d1d626-abc0-4064-b029-6e88cec4b040",
            price: 0.908491034902329,
            price_sources: [],
            processed_ms: 1718392500428,
          },
        ],
        position_type: "FLAT",
        position_uuid: "6c968ba0-7c8e-4079-84e7-87621e21a9a1",
        return_at_close: 0.9984585179874548,
        trade_pair: ["AUDCAD", "AUD/CAD", 7e-5, 0.001, 500],
      },
    ],
    thirty_day_returns: 1.0,
  },
  "5CSHrvBiEJAFAAj7YAr5y8jzmpFajRsV9PahphPGi7P8PZrA": {
    all_time_returns: 1.2598846822091856,
    n_positions: 37,
    percentage_profitable: 0.918918918918919,
    positions: [
      {
        average_entry_price: 1.80018,
        close_ms: 1713344203665,
        current_return: 1.00000399960004,
        initial_entry_price: 1.80018,
        is_closed_position: true,
        miner_hotkey: "5CSHrvBiEJAFAAj7YAr5y8jzmpFajRsV9PahphPGi7P8PZrA",
        net_leverage: 0.0,
        open_ms: 1713343245441,
        orders: [
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "3dd2f600-c5ca-4fe3-b7b5-eff93383f981",
            price: 1.80018,
            price_sources: [],
            processed_ms: 1713343245440,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "19ffe97e-473e-4d67-abeb-2a238ec27b75",
            price: 1.8009,
            price_sources: [],
            processed_ms: 1713344203665,
          },
        ],
        position_type: "FLAT",
        position_uuid: "a6742d0b-998a-4fe3-9ac1-39f4285edd1c",
        return_at_close: 1.0000032995972403,
        trade_pair: ["EURNZD", "EUR/NZD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 1.246845,
        close_ms: 1713350103266,
        current_return: 1.000007534043457,
        initial_entry_price: 1.24767,
        is_closed_position: true,
        miner_hotkey: "5CSHrvBiEJAFAAj7YAr5y8jzmpFajRsV9PahphPGi7P8PZrA",
        net_leverage: 0.0,
        open_ms: 1713343705600,
        orders: [
          {
            leverage: -0.01,
            order_type: "SHORT",
            order_uuid: "4ceb71b4-b526-4ec7-862f-6f1c991e0803",
            price: 1.24767,
            price_sources: [],
            processed_ms: 1713343705597,
          },
          {
            leverage: -0.03,
            order_type: "SHORT",
            order_uuid: "2ac9c736-43b1-4098-ab24-d7314c4d2575",
            price: 1.24657,
            price_sources: [],
            processed_ms: 1713350038558,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "95656ddf-5690-44da-b977-3b8153d62726",
            price: 1.24661,
            price_sources: [],
            processed_ms: 1713350103266,
          },
        ],
        position_type: "FLAT",
        position_uuid: "c6a0b744-23f3-46b1-8fc2-1767771b742e",
        return_at_close: 1.000004734022362,
        trade_pair: ["GBPUSD", "GBP/USD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 91.341,
        close_ms: 1713350165846,
        current_return: 1.0000061308722261,
        initial_entry_price: 91.341,
        is_closed_position: true,
        miner_hotkey: "5CSHrvBiEJAFAAj7YAr5y8jzmpFajRsV9PahphPGi7P8PZrA",
        net_leverage: 0.0,
        open_ms: 1713343529456,
        orders: [
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "162b758c-3739-4520-9f44-7635888ad02c",
            price: 91.341,
            price_sources: [],
            processed_ms: 1713343527565,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "09d97ca1-946f-496b-b6d1-c903a4c9685f",
            price: 91.397,
            price_sources: [],
            processed_ms: 1713350165846,
          },
        ],
        position_type: "FLAT",
        position_uuid: "b5fb85f4-f255-4d41-bf41-7f5eeaf57b28",
        return_at_close: 1.0000054308679345,
        trade_pair: ["NZDJPY", "NZD/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.85337,
        close_ms: 1713350237380,
        current_return: 1.0000062106706353,
        initial_entry_price: 0.85337,
        is_closed_position: true,
        miner_hotkey: "5CSHrvBiEJAFAAj7YAr5y8jzmpFajRsV9PahphPGi7P8PZrA",
        net_leverage: 0.0,
        open_ms: 1713343134243,
        orders: [
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "2c3713f0-ce59-4278-8ff7-d87286b29de9",
            price: 0.85337,
            price_sources: [],
            processed_ms: 1713343134242,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "2de39a50-0519-4051-8ebe-0b5fc259b660",
            price: 0.8539,
            price_sources: [],
            processed_ms: 1713350237380,
          },
        ],
        position_type: "FLAT",
        position_uuid: "f100c078-cec8-4b53-a36a-cd69eca29279",
        return_at_close: 1.000005510666288,
        trade_pair: ["EURGBP", "EUR/GBP", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.6427,
        close_ms: 1713350384276,
        current_return: 1.0000046678076864,
        initial_entry_price: 0.6427,
        is_closed_position: true,
        miner_hotkey: "5CSHrvBiEJAFAAj7YAr5y8jzmpFajRsV9PahphPGi7P8PZrA",
        net_leverage: 0.0,
        open_ms: 1713342495339,
        orders: [
          {
            leverage: -0.01,
            order_type: "SHORT",
            order_uuid: "55a811c6-f1e6-4370-88de-f451d92505ee",
            price: 0.6427,
            price_sources: [],
            processed_ms: 1713342495338,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "b04d2a5c-3282-4095-9946-929e96028359",
            price: 0.6424,
            price_sources: [],
            processed_ms: 1713350384276,
          },
        ],
        position_type: "FLAT",
        position_uuid: "9f3f9bd6-76f6-4c22-afe7-1fba0147fe4f",
        return_at_close: 1.000003967804419,
        trade_pair: ["AUDUSD", "AUD/USD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.59119,
        close_ms: 1713350419381,
        current_return: 1.0000045670596593,
        initial_entry_price: 0.59119,
        is_closed_position: true,
        miner_hotkey: "5CSHrvBiEJAFAAj7YAr5y8jzmpFajRsV9PahphPGi7P8PZrA",
        net_leverage: 0.0,
        open_ms: 1713343570490,
        orders: [
          {
            leverage: -0.01,
            order_type: "SHORT",
            order_uuid: "17e28998-54db-4adf-9ae7-fbc539d3fbaa",
            price: 0.59119,
            price_sources: [],
            processed_ms: 1713343570488,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "167e722d-703a-46ef-9f13-3d3e38494777",
            price: 0.59092,
            price_sources: [],
            processed_ms: 1713350419381,
          },
        ],
        position_type: "FLAT",
        position_uuid: "63eb8ee8-b667-4cf7-8b3a-f85ff128505d",
        return_at_close: 1.0000038670564624,
        trade_pair: ["NZDUSD", "NZD/USD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 164.488,
        close_ms: 1713350904968,
        current_return: 1.0000046203978405,
        initial_entry_price: 164.488,
        is_closed_position: true,
        miner_hotkey: "5CSHrvBiEJAFAAj7YAr5y8jzmpFajRsV9PahphPGi7P8PZrA",
        net_leverage: 0.0,
        open_ms: 1713343202931,
        orders: [
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "4aaeb84d-c9f8-4f36-b222-2eca5926d162",
            price: 164.488,
            price_sources: [],
            processed_ms: 1713343202929,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "f8bbda49-3f6c-4c5c-8b30-66cbaae40bcb",
            price: 164.564,
            price_sources: [],
            processed_ms: 1713350904968,
          },
        ],
        position_type: "FLAT",
        position_uuid: "7f863b86-b936-4319-b1f5-3e57dfb22130",
        return_at_close: 1.0000039203946063,
        trade_pair: ["EURJPY", "EUR/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 99.332,
        close_ms: 1713352505463,
        current_return: 1.000004832279628,
        initial_entry_price: 99.332,
        is_closed_position: true,
        miner_hotkey: "5CSHrvBiEJAFAAj7YAr5y8jzmpFajRsV9PahphPGi7P8PZrA",
        net_leverage: 0.0,
        open_ms: 1713342550730,
        orders: [
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "4a9568bd-5877-413c-97e2-779c0f64d6de",
            price: 99.332,
            price_sources: [],
            processed_ms: 1713342550730,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "fc06fe39-0613-4371-906b-0f52405da369",
            price: 99.38,
            price_sources: [],
            processed_ms: 1713352505463,
          },
        ],
        position_type: "FLAT",
        position_uuid: "fb4e312e-6b22-4884-8c33-d5c78746a469",
        return_at_close: 1.0000041322762454,
        trade_pair: ["AUDJPY", "AUD/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 192.813,
        close_ms: 1713353897282,
        current_return: 1.0000059408920159,
        initial_entry_price: 192.732,
        is_closed_position: true,
        miner_hotkey: "5CSHrvBiEJAFAAj7YAr5y8jzmpFajRsV9PahphPGi7P8PZrA",
        net_leverage: 0.0,
        open_ms: 1713343775502,
        orders: [
          {
            leverage: -0.01,
            order_type: "SHORT",
            order_uuid: "db11f49b-191a-4a1c-8838-815f4ecec8af",
            price: 192.732,
            price_sources: [],
            processed_ms: 1713343775499,
          },
          {
            leverage: -0.015,
            order_type: "SHORT",
            order_uuid: "d4964027-8e8a-4678-a56d-2590a1bf4a2e",
            price: 192.575,
            price_sources: [],
            processed_ms: 1713351605632,
          },
          {
            leverage: 0.02,
            order_type: "LONG",
            order_uuid: "628bd8a9-e58c-48bf-927b-1a3be0c3e55b",
            price: 192.594,
            price_sources: [],
            processed_ms: 1713353850442,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "957224ad-1dc2-49f4-a62d-bd93d0fe5ff4",
            price: 192.584,
            price_sources: [],
            processed_ms: 1713353897282,
          },
        ],
        position_type: "FLAT",
        position_uuid: "1f3bc8d8-831f-45b4-8b72-d7e09e442fa4",
        return_at_close: 1.0000041908816193,
        trade_pair: ["GBPJPY", "GBP/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 60574.37999999996,
        close_ms: 1713393261928,
        current_return: 1.000015548691677,
        initial_entry_price: 61448.9,
        is_closed_position: true,
        miner_hotkey: "5CSHrvBiEJAFAAj7YAr5y8jzmpFajRsV9PahphPGi7P8PZrA",
        net_leverage: 0.0,
        open_ms: 1713389454057,
        orders: [
          {
            leverage: 0.003,
            order_type: "LONG",
            order_uuid: "e130a3d5-12dd-45be-8b71-ffc389d495f4",
            price: 61448.9,
            price_sources: [],
            processed_ms: 1713389454053,
          },
          {
            leverage: 0.001,
            order_type: "LONG",
            order_uuid: "36e297e1-1dad-4d2a-a255-49bec6272050",
            price: 61317.53,
            price_sources: [],
            processed_ms: 1713389699184,
          },
          {
            leverage: 0.001,
            order_type: "LONG",
            order_uuid: "2b35b2f0-3188-4689-91a1-dac6975dfe40",
            price: 61232.66,
            price_sources: [],
            processed_ms: 1713389990944,
          },
          {
            leverage: 0.001,
            order_type: "LONG",
            order_uuid: "f4f61f77-9552-4415-9b1a-2733d1cbfc2e",
            price: 61140.69,
            price_sources: [],
            processed_ms: 1713390834678,
          },
          {
            leverage: -0.005,
            order_type: "SHORT",
            order_uuid: "7dc5c146-bc33-4539-86d8-3309522f197b",
            price: 61492.64,
            price_sources: [],
            processed_ms: 1713393227558,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "867de874-95ab-4a32-8ac0-1d9d5fc1d4e6",
            price: 61529.83,
            price_sources: [],
            processed_ms: 1713393261928,
          },
        ],
        position_type: "FLAT",
        position_uuid: "aa8ebe87-8d5b-4406-92d6-b7e0308405d2",
        return_at_close: 1.000009548598385,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 169.8385,
        close_ms: 1713441711660,
        current_return: 1.0000090089029159,
        initial_entry_price: 169.832,
        is_closed_position: true,
        miner_hotkey: "5CSHrvBiEJAFAAj7YAr5y8jzmpFajRsV9PahphPGi7P8PZrA",
        net_leverage: 0.0,
        open_ms: 1713342838181,
        orders: [
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "ab4ac42c-d407-4bbd-b49a-5d4897f4881e",
            price: 169.832,
            price_sources: [],
            processed_ms: 1713342838178,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "f068c57d-71cf-450e-9024-b151c638a3e9",
            price: 169.845,
            price_sources: [],
            processed_ms: 1713441483244,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "dffe880a-c0d2-49fa-a462-48de00fbbbaf",
            price: 169.915,
            price_sources: [],
            processed_ms: 1713441711660,
          },
        ],
        position_type: "FLAT",
        position_uuid: "70bc26c7-c653-4ec3-8f29-fff033f05fc2",
        return_at_close: 1.0000076088903034,
        trade_pair: ["CHFJPY", "CHF/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 1.4644946153846152,
        close_ms: 1713790862704,
        current_return: 0.9995244884209954,
        initial_entry_price: 1.46947,
        is_closed_position: true,
        miner_hotkey: "5CSHrvBiEJAFAAj7YAr5y8jzmpFajRsV9PahphPGi7P8PZrA",
        net_leverage: 0.0,
        open_ms: 1713342891822,
        orders: [
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "cd4b53ab-1797-4dc3-a9f3-683c3b4ad79b",
            price: 1.46947,
            price_sources: [],
            processed_ms: 1713342891821,
          },
          {
            leverage: -0.001,
            order_type: "SHORT",
            order_uuid: "8b688a7b-631a-4fcc-be6d-321f66907c78",
            price: 1.46921,
            price_sources: [],
            processed_ms: 1713391402322,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "256cb80b-1007-46a0-9c82-3bcd82b31cdb",
            price: 1.46776,
            price_sources: [],
            processed_ms: 1713441405462,
          },
          {
            leverage: 0.05,
            order_type: "LONG",
            order_uuid: "46c044f0-1d59-4922-b8e1-139529758e53",
            price: 1.46501,
            price_sources: [],
            processed_ms: 1713475202692,
          },
          {
            leverage: 0.1,
            order_type: "LONG",
            order_uuid: "aa51a568-b78e-4d40-9c78-a6ec3151e4de",
            price: 1.46346,
            price_sources: [],
            processed_ms: 1713759118527,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "42203f59-3430-447a-94e9-0ab3f0631ab3",
            price: 1.46036,
            price_sources: [],
            processed_ms: 1713790862704,
          },
        ],
        position_type: "FLAT",
        position_uuid: "25c4b615-de3f-49a0-a6a1-3c7d9c564f4f",
        return_at_close: 0.9995125940795833,
        trade_pair: ["EURCAD", "EUR/CAD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.9711165,
        close_ms: 1713790963652,
        current_return: 1.0003504224072046,
        initial_entry_price: 0.96826,
        is_closed_position: true,
        miner_hotkey: "5CSHrvBiEJAFAAj7YAr5y8jzmpFajRsV9PahphPGi7P8PZrA",
        net_leverage: 0.0,
        open_ms: 1713342972414,
        orders: [
          {
            leverage: -0.01,
            order_type: "SHORT",
            order_uuid: "66d48a13-8107-4b9e-9b25-f01fa8e75501",
            price: 0.96826,
            price_sources: [],
            processed_ms: 1713342972412,
          },
          {
            leverage: -0.01,
            order_type: "SHORT",
            order_uuid: "4a2c7590-baa5-4fc9-966e-6023c47e3c38",
            price: 0.96927,
            price_sources: [],
            processed_ms: 1713353635286,
          },
          {
            leverage: -0.03,
            order_type: "SHORT",
            order_uuid: "78d47f3a-6ed2-45ac-9b48-2ed38d5e4d47",
            price: 0.9701,
            price_sources: [],
            processed_ms: 1713441336066,
          },
          {
            leverage: -0.05,
            order_type: "SHORT",
            order_uuid: "4542584d-31f7-47f1-b17b-34ef34d5cc98",
            price: 0.9709,
            price_sources: [],
            processed_ms: 1713475168689,
          },
          {
            leverage: -0.1,
            order_type: "SHORT",
            order_uuid: "89508483-c074-4ffe-b594-4b8803818802",
            price: 0.972,
            price_sources: [],
            processed_ms: 1713759151706,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "309490a8-a326-4c0f-98c2-b266f804ab4e",
            price: 0.96942,
            price_sources: [],
            processed_ms: 1713790963652,
          },
        ],
        position_type: "FLAT",
        position_uuid: "6a687f68-f06c-45b7-bca6-6f75052d3851",
        return_at_close: 1.000336417501291,
        trade_pair: ["EURCHF", "EUR/CHF", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.8842795,
        close_ms: 1713944247680,
        current_return: 1.0012923349709733,
        initial_entry_price: 0.88721,
        is_closed_position: true,
        miner_hotkey: "5CSHrvBiEJAFAAj7YAr5y8jzmpFajRsV9PahphPGi7P8PZrA",
        net_leverage: 0.0,
        open_ms: 1713342277136,
        orders: [
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "e7212220-a0e6-4d16-ae64-f8adbd6218b6",
            price: 0.88721,
            price_sources: [],
            processed_ms: 1713342277136,
          },
          {
            leverage: 0.02,
            order_type: "LONG",
            order_uuid: "bdbfdaf8-5099-4524-8979-3697274f0480",
            price: 0.88639,
            price_sources: [],
            processed_ms: 1713441387941,
          },
          {
            leverage: 0.07,
            order_type: "LONG",
            order_uuid: "f7dae572-44df-49ec-8fc2-e764e61bdf85",
            price: 0.884,
            price_sources: [],
            processed_ms: 1713475185911,
          },
          {
            leverage: 0.1,
            order_type: "LONG",
            order_uuid: "32d3189c-5803-417f-ad13-0ead28cd1da7",
            price: 0.88376,
            price_sources: [],
            processed_ms: 1713759134445,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "eb5ec54d-787f-4bd0-96b9-2207cc2d1648",
            price: 0.890012362547986,
            price_sources: [],
            processed_ms: 1713944247680,
          },
        ],
        position_type: "FLAT",
        position_uuid: "c17cb530-5068-4830-9548-77d954dfc81b",
        return_at_close: 1.0012783168782837,
        trade_pair: ["AUDCAD", "AUD/CAD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.9396400000000018,
        close_ms: 1714157276112,
        current_return: 1.0009698914977498,
        initial_entry_price: 0.85989,
        is_closed_position: true,
        miner_hotkey: "5CSHrvBiEJAFAAj7YAr5y8jzmpFajRsV9PahphPGi7P8PZrA",
        net_leverage: 0.0,
        open_ms: 1713945747556,
        orders: [
          {
            leverage: -0.3,
            order_type: "SHORT",
            order_uuid: "90dffe7e-3afd-44b7-b964-2fdc475eba99",
            price: 0.85989,
            price_sources: [],
            processed_ms: 1713945747548,
          },
          {
            leverage: 0.29,
            order_type: "LONG",
            order_uuid: "f40722d4-1552-477b-a113-8680491e103c",
            price: 0.85714,
            price_sources: [],
            processed_ms: 1714051513404,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "f77b614e-fe95-4cee-a0dd-8d67d3a2e315",
            price: 0.85624,
            price_sources: [],
            processed_ms: 1714157276112,
          },
        ],
        position_type: "FLAT",
        position_uuid: "b949137a-daa2-42c7-80c4-b647b3d2d6b3",
        return_at_close: 1.0009488711300283,
        trade_pair: ["EURGBP", "EUR/GBP", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.6667858579881658,
        close_ms: 1714410045189,
        current_return: 1.0011173302995833,
        initial_entry_price: 0.65925,
        is_closed_position: true,
        miner_hotkey: "5CSHrvBiEJAFAAj7YAr5y8jzmpFajRsV9PahphPGi7P8PZrA",
        net_leverage: 0.0,
        open_ms: 1713342653023,
        orders: [
          {
            leverage: -0.01,
            order_type: "SHORT",
            order_uuid: "29a3c03e-be13-4bdd-95ab-b98460a8c5f7",
            price: 0.65925,
            price_sources: [],
            processed_ms: 1713342652807,
          },
          {
            leverage: -0.02,
            order_type: "SHORT",
            order_uuid: "254bd4ca-c6b1-4d9a-80da-754919ea6af6",
            price: 0.6609,
            price_sources: [],
            processed_ms: 1713441371529,
          },
          {
            leverage: -0.07,
            order_type: "SHORT",
            order_uuid: "264ab0bc-fbca-4dae-b930-2c7c73944647",
            price: 0.66219,
            price_sources: [],
            processed_ms: 1713475151896,
          },
          {
            leverage: -0.1,
            order_type: "SHORT",
            order_uuid: "ff546b0c-77e9-413b-b98e-f5d01cead902",
            price: 0.66424,
            price_sources: [],
            processed_ms: 1713759186953,
          },
          {
            leverage: -1.0,
            order_type: "SHORT",
            order_uuid: "f9a0f1b3-5269-4334-b850-a026cfcb181a",
            price: 0.66531,
            price_sources: [],
            processed_ms: 1713882266575,
          },
          {
            leverage: -1.5,
            order_type: "SHORT",
            order_uuid: "d5916bf2-d76b-40a6-bb56-ee832e778860",
            price: 0.66815,
            price_sources: [],
            processed_ms: 1713952840834,
          },
          {
            leverage: 1.0,
            order_type: "LONG",
            order_uuid: "8c3df210-6905-43ac-88f0-de04ba197296",
            price: 0.66659,
            price_sources: [],
            processed_ms: 1714052679887,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "deab579b-b88e-42e2-a18e-f969c4e63ab0",
            price: 0.66647,
            price_sources: [],
            processed_ms: 1714057191063,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "2f763b51-014a-499e-8062-15bfefaeefce",
            price: 0.66635,
            price_sources: [],
            processed_ms: 1714410045189,
          },
        ],
        position_type: "FLAT",
        position_uuid: "8afa41fd-c7a2-4c93-bebf-0459e2aa0496",
        return_at_close: 1.0009281191241566,
        trade_pair: ["CADCHF", "CAD/CHF", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 171.666,
        close_ms: 1714476120910,
        current_return: 1.0003023312711894,
        initial_entry_price: 171.666,
        is_closed_position: true,
        miner_hotkey: "5CSHrvBiEJAFAAj7YAr5y8jzmpFajRsV9PahphPGi7P8PZrA",
        net_leverage: 0.0,
        open_ms: 1714465469045,
        orders: [
          {
            leverage: 0.15,
            order_type: "LONG",
            order_uuid: "b2147a79-5acc-4cec-bfe3-3c6fe5ce3f00",
            price: 171.666,
            price_sources: [],
            processed_ms: 1714465469039,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "071aa2d5-5f11-48b6-a310-6be2b8d09f8b",
            price: 172.012,
            price_sources: [],
            processed_ms: 1714476120910,
          },
        ],
        position_type: "FLAT",
        position_uuid: "947a2e4f-9e33-4345-bc74-e2613092cec4",
        return_at_close: 1.000291828096711,
        trade_pair: ["CHFJPY", "CHF/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.5931,
        close_ms: 1714476136965,
        current_return: 1.0002731411229135,
        initial_entry_price: 0.5931,
        is_closed_position: true,
        miner_hotkey: "5CSHrvBiEJAFAAj7YAr5y8jzmpFajRsV9PahphPGi7P8PZrA",
        net_leverage: 0.0,
        open_ms: 1714465607537,
        orders: [
          {
            leverage: 0.1,
            order_type: "LONG",
            order_uuid: "e6cb28a4-3eb8-4eb0-8b30-1ebfb114ade4",
            price: 0.5931,
            price_sources: [],
            processed_ms: 1714465607531,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "f3788c8e-739e-4462-a295-ef95e95e5331",
            price: 0.59472,
            price_sources: [],
            processed_ms: 1714476136965,
          },
        ],
        position_type: "FLAT",
        position_uuid: "c1b3bdfc-e5c6-41ac-8032-635858132614",
        return_at_close: 1.0002661392109258,
        trade_pair: ["NZDUSD", "NZD/USD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 1.06983,
        close_ms: 1714476153733,
        current_return: 1.0002467681781217,
        initial_entry_price: 1.06983,
        is_closed_position: true,
        miner_hotkey: "5CSHrvBiEJAFAAj7YAr5y8jzmpFajRsV9PahphPGi7P8PZrA",
        net_leverage: 0.0,
        open_ms: 1714465569897,
        orders: [
          {
            leverage: 0.1,
            order_type: "LONG",
            order_uuid: "d8f49ccb-1e1f-4e24-a262-9bc9a4f6214f",
            price: 1.06983,
            price_sources: [],
            processed_ms: 1714465569891,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "434b6c46-8ce4-4cb7-a714-74739f2ce805",
            price: 1.07247,
            price_sources: [],
            processed_ms: 1714476153733,
          },
        ],
        position_type: "FLAT",
        position_uuid: "947dfdc2-ee3b-469d-9f8a-0bb7fdfc1985",
        return_at_close: 1.0002397664507445,
        trade_pair: ["EURUSD", "EUR/USD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 1.4651399999999999,
        close_ms: 1714476170500,
        current_return: 1.0001938381315096,
        initial_entry_price: 1.46514,
        is_closed_position: true,
        miner_hotkey: "5CSHrvBiEJAFAAj7YAr5y8jzmpFajRsV9PahphPGi7P8PZrA",
        net_leverage: 0.0,
        open_ms: 1714465485507,
        orders: [
          {
            leverage: 0.1,
            order_type: "LONG",
            order_uuid: "f793de48-c657-4e3c-b103-6bdb7c9dec33",
            price: 1.46514,
            price_sources: [],
            processed_ms: 1714465485502,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "6712ce8c-de68-439e-b031-259599fea3e4",
            price: 1.46798,
            price_sources: [],
            processed_ms: 1714476170500,
          },
        ],
        position_type: "FLAT",
        position_uuid: "a8bb6401-1755-4e4d-a071-4a2754ef474d",
        return_at_close: 1.0001868367746427,
        trade_pair: ["EURCAD", "EUR/CAD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.8533,
        close_ms: 1714476187151,
        current_return: 1.0001617250673855,
        initial_entry_price: 0.8533,
        is_closed_position: true,
        miner_hotkey: "5CSHrvBiEJAFAAj7YAr5y8jzmpFajRsV9PahphPGi7P8PZrA",
        net_leverage: 0.0,
        open_ms: 1714465520303,
        orders: [
          {
            leverage: 0.1,
            order_type: "LONG",
            order_uuid: "66adcc32-90f3-443c-b66b-493deb323a97",
            price: 0.8533,
            price_sources: [],
            processed_ms: 1714465520265,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "c8965cfd-e584-410c-b86c-e02ceab5ea1b",
            price: 0.85468,
            price_sources: [],
            processed_ms: 1714476187151,
          },
        ],
        position_type: "FLAT",
        position_uuid: "023d4089-89d9-46fc-9172-4243c854b07d",
        return_at_close: 1.0001547239353101,
        trade_pair: ["EURGBP", "EUR/GBP", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 1.3696599999999999,
        close_ms: 1714479962354,
        current_return: 1.0001598936962457,
        initial_entry_price: 1.36966,
        is_closed_position: true,
        miner_hotkey: "5CSHrvBiEJAFAAj7YAr5y8jzmpFajRsV9PahphPGi7P8PZrA",
        net_leverage: 0.0,
        open_ms: 1714465630576,
        orders: [
          {
            leverage: -0.1,
            order_type: "SHORT",
            order_uuid: "da443fd5-4d4f-4f54-8de6-4d87fde0e9a9",
            price: 1.36966,
            price_sources: [],
            processed_ms: 1714465630570,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "d175bdaf-7e6b-491e-a3f5-829a178a6413",
            price: 1.36747,
            price_sources: [],
            processed_ms: 1714479962354,
          },
        ],
        position_type: "FLAT",
        position_uuid: "0a77407e-c465-4261-ad01-bee629f84ef2",
        return_at_close: 1.0001528925769898,
        trade_pair: ["USDCAD", "USD/CAD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 1.80255,
        close_ms: 1714486361807,
        current_return: 1.0004427061662644,
        initial_entry_price: 1.80255,
        is_closed_position: true,
        miner_hotkey: "5CSHrvBiEJAFAAj7YAr5y8jzmpFajRsV9PahphPGi7P8PZrA",
        net_leverage: 0.0,
        open_ms: 1714465552845,
        orders: [
          {
            leverage: 0.1,
            order_type: "LONG",
            order_uuid: "37fb803c-1084-49b3-822b-78342cf5ab84",
            price: 1.80255,
            price_sources: [],
            processed_ms: 1714465552840,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "7d7bb678-80d8-43e4-bff3-b86ae05d9359",
            price: 1.81053,
            price_sources: [],
            processed_ms: 1714486361807,
          },
        ],
        position_type: "FLAT",
        position_uuid: "6703f74d-1dd1-44cb-b39b-18303abe0f8e",
        return_at_close: 1.0004357030673212,
        trade_pair: ["EURNZD", "EUR/NZD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 167.69799999999998,
        close_ms: 1714594741481,
        current_return: 1.0001657741893164,
        initial_entry_price: 167.698,
        is_closed_position: true,
        miner_hotkey: "5CSHrvBiEJAFAAj7YAr5y8jzmpFajRsV9PahphPGi7P8PZrA",
        net_leverage: 0.0,
        open_ms: 1714465537156,
        orders: [
          {
            leverage: -0.1,
            order_type: "SHORT",
            order_uuid: "debd3578-c7ea-4b88-8abe-13f8c785f95c",
            price: 167.698,
            price_sources: [],
            processed_ms: 1714465537151,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "e6346b4d-f874-4af9-a278-7fba993ee9f4",
            price: 167.42,
            price_sources: [],
            processed_ms: 1714594741481,
          },
        ],
        position_type: "FLAT",
        position_uuid: "793817d7-25d3-43a9-931c-5dc78924aa94",
        return_at_close: 1.000158773028897,
        trade_pair: ["EURJPY", "EUR/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.97724,
        close_ms: 1714649421588,
        current_return: 1.0001606565429169,
        initial_entry_price: 0.97724,
        is_closed_position: true,
        miner_hotkey: "5CSHrvBiEJAFAAj7YAr5y8jzmpFajRsV9PahphPGi7P8PZrA",
        net_leverage: 0.0,
        open_ms: 1714465503280,
        orders: [
          {
            leverage: -0.1,
            order_type: "SHORT",
            order_uuid: "b1e4a45f-8228-4971-a2d3-7223efa2f7fb",
            price: 0.97724,
            price_sources: [],
            processed_ms: 1714465503269,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "0e9a057a-a414-4c5b-85ee-8b81b694e2df",
            price: 0.97567,
            price_sources: [],
            processed_ms: 1714649421588,
          },
        ],
        position_type: "FLAT",
        position_uuid: "23d25576-80d1-4898-8252-11b0c6debf38",
        return_at_close: 1.000153655418321,
        trade_pair: ["EURCHF", "EUR/CHF", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.65598,
        close_ms: 1714699321022,
        current_return: 1.0002835452300376,
        initial_entry_price: 0.65598,
        is_closed_position: true,
        miner_hotkey: "5CSHrvBiEJAFAAj7YAr5y8jzmpFajRsV9PahphPGi7P8PZrA",
        net_leverage: 0.0,
        open_ms: 1714403329591,
        orders: [
          {
            leverage: 0.1,
            order_type: "LONG",
            order_uuid: "848bdac0-ca91-40da-8fe4-5cb0d5f8c02c",
            price: 0.65598,
            price_sources: [],
            processed_ms: 1714403329587,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "b7dc78d3-18ba-435c-8c6d-c2d4f1e983eb",
            price: 0.65784,
            price_sources: [],
            processed_ms: 1714699321022,
          },
        ],
        position_type: "FLAT",
        position_uuid: "cf0fd080-b978-47e0-aa7f-7f58705e53f8",
        return_at_close: 1.000276543245221,
        trade_pair: ["AUDUSD", "AUD/USD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 1.2688900000000005,
        close_ms: 1714711750421,
        current_return: 1.0001100198658062,
        initial_entry_price: 1.25341,
        is_closed_position: true,
        miner_hotkey: "5CSHrvBiEJAFAAj7YAr5y8jzmpFajRsV9PahphPGi7P8PZrA",
        net_leverage: 0.0,
        open_ms: 1714465586582,
        orders: [
          {
            leverage: -0.1,
            order_type: "SHORT",
            order_uuid: "7c39f402-7b35-4706-8b42-6e3df5022141",
            price: 1.25341,
            price_sources: [],
            processed_ms: 1714465586577,
          },
          {
            leverage: 0.09,
            order_type: "LONG",
            order_uuid: "fb634d03-dbaa-483c-99fd-9e1902769c42",
            price: 1.25169,
            price_sources: [],
            processed_ms: 1714491587395,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "bd2abf0b-edee-4844-b35a-e65b30ffb4d8",
            price: 1.2551,
            price_sources: [],
            processed_ms: 1714711750421,
          },
        ],
        position_type: "FLAT",
        position_uuid: "288a73ac-87a8-4b3a-ab1c-4273a6ba3a20",
        return_at_close: 1.0001030190956672,
        trade_pair: ["GBPUSD", "GBP/USD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 152.85,
        close_ms: 1714725728789,
        current_return: 1.0115145567549886,
        initial_entry_price: 152.85,
        is_closed_position: true,
        miner_hotkey: "5CSHrvBiEJAFAAj7YAr5y8jzmpFajRsV9PahphPGi7P8PZrA",
        net_leverage: 0.0,
        open_ms: 1714711812873,
        orders: [
          {
            leverage: 5.0,
            order_type: "LONG",
            order_uuid: "d0952f24-fcb5-4fbc-8160-28118b32e341",
            price: 152.85,
            price_sources: [],
            processed_ms: 1714711812865,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "f3e44d3a-fdce-46f3-85db-676d56c198b7",
            price: 153.202,
            price_sources: [],
            processed_ms: 1714725728789,
          },
        ],
        position_type: "FLAT",
        position_uuid: "a6b3b8ff-8609-4dcb-894b-b20309fbee22",
        return_at_close: 1.0111605266601245,
        trade_pair: ["USDJPY", "USD/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 154.77200000000028,
        close_ms: 1714725744479,
        current_return: 1.0038123950405544,
        initial_entry_price: 111.948,
        is_closed_position: true,
        miner_hotkey: "5CSHrvBiEJAFAAj7YAr5y8jzmpFajRsV9PahphPGi7P8PZrA",
        net_leverage: 0.0,
        open_ms: 1713342696952,
        orders: [
          {
            leverage: -0.01,
            order_type: "SHORT",
            order_uuid: "1a09398a-1247-41b9-bdbd-8df37984a0a8",
            price: 111.948,
            price_sources: [],
            processed_ms: 1713342696951,
          },
          {
            leverage: -0.04,
            order_type: "SHORT",
            order_uuid: "cf6092c6-1239-4ecd-a9bc-fc403b11a704",
            price: 112.27,
            price_sources: [],
            processed_ms: 1713441301710,
          },
          {
            leverage: -0.1,
            order_type: "SHORT",
            order_uuid: "9844f45b-9dc6-4ed5-9a01-b49dbd8af5e3",
            price: 112.704,
            price_sources: [],
            processed_ms: 1713759170297,
          },
          {
            leverage: -1.0,
            order_type: "SHORT",
            order_uuid: "c2f0ac4b-ca28-45ad-a75f-3ceaafd43db9",
            price: 113.161,
            price_sources: [],
            processed_ms: 1713882281813,
          },
          {
            leverage: -0.5,
            order_type: "SHORT",
            order_uuid: "fe670e9c-36bb-4fac-aef4-71de4a6eaa40",
            price: 113.22,
            price_sources: [],
            processed_ms: 1713952824037,
          },
          {
            leverage: -1.0,
            order_type: "SHORT",
            order_uuid: "a50679a9-4157-4609-9dce-d009f7a43b7e",
            price: 114.774,
            price_sources: [],
            processed_ms: 1714130331266,
          },
          {
            leverage: -1.0,
            order_type: "SHORT",
            order_uuid: "6eb3ff06-7763-465d-a06e-af98f8b0c083",
            price: 114.769,
            price_sources: [],
            processed_ms: 1714130551758,
          },
          {
            leverage: 3.64,
            order_type: "LONG",
            order_uuid: "4177bb3d-0b1c-4b24-8c19-906efcbb49f2",
            price: 113.914,
            price_sources: [],
            processed_ms: 1714366077321,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "9d3e1efc-2e31-444e-ad86-6ce84d2181ac",
            price: 112.093,
            price_sources: [],
            processed_ms: 1714725744479,
          },
        ],
        position_type: "FLAT",
        position_uuid: "4b06c084-4b44-4afd-a86b-8846da3fe7d6",
        return_at_close: 1.0035559209736216,
        trade_pair: ["CADJPY", "CAD/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 1.25513,
        close_ms: 1714725761002,
        current_return: 1.003107247854804,
        initial_entry_price: 1.25513,
        is_closed_position: true,
        miner_hotkey: "5CSHrvBiEJAFAAj7YAr5y8jzmpFajRsV9PahphPGi7P8PZrA",
        net_leverage: 0.0,
        open_ms: 1714711779157,
        orders: [
          {
            leverage: 5.0,
            order_type: "LONG",
            order_uuid: "35c14954-5e06-42d3-8c00-f41b02f6ceff",
            price: 1.25513,
            price_sources: [],
            processed_ms: 1714711778133,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "07e78bb0-f97b-4a94-b5bb-027a5ce66b02",
            price: 1.25591,
            price_sources: [],
            processed_ms: 1714725761002,
          },
        ],
        position_type: "FLAT",
        position_uuid: "acc35a98-6efa-4cb0-91e7-9cf81bb69af6",
        return_at_close: 1.0027561603180548,
        trade_pair: ["GBPUSD", "GBP/USD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 1.0984,
        close_ms: 1715186512346,
        current_return: 1.0002867807720321,
        initial_entry_price: 1.0984,
        is_closed_position: true,
        miner_hotkey: "5CSHrvBiEJAFAAj7YAr5y8jzmpFajRsV9PahphPGi7P8PZrA",
        net_leverage: 0.0,
        open_ms: 1714403345934,
        orders: [
          {
            leverage: -0.15,
            order_type: "SHORT",
            order_uuid: "433046bf-58b3-4dd8-b407-1334ef93595f",
            price: 1.0984,
            price_sources: [],
            processed_ms: 1714403345930,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "963a3bc9-9934-4d47-bd18-f403d62c57cf",
            price: 1.0963,
            price_sources: [],
            processed_ms: 1715186512346,
          },
        ],
        position_type: "FLAT",
        position_uuid: "4e35fc4b-1435-4914-96cb-cf1a09ed1c1e",
        return_at_close: 1.000276277760834,
        trade_pair: ["AUDNZD", "AUD/NZD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 3124.5,
        close_ms: 1716579262368,
        current_return: 1.000196517842855,
        initial_entry_price: 3124.5,
        is_closed_position: true,
        miner_hotkey: "5CSHrvBiEJAFAAj7YAr5y8jzmpFajRsV9PahphPGi7P8PZrA",
        net_leverage: 0.0,
        open_ms: 1714840946671,
        orders: [
          {
            leverage: 0.001,
            order_type: "LONG",
            order_uuid: "6f6b798e-d73d-4a42-80ef-b32b7506570b",
            price: 3124.5,
            price_sources: [],
            processed_ms: 1714840946476,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "511d036f-321a-49e0-b7e7-4ce71e1fbfc1",
            price: 3738.52,
            price_sources: [],
            processed_ms: 1716579262368,
          },
        ],
        position_type: "FLAT",
        position_uuid: "db89c6c7-104f-4e2f-8c34-baf3b5efc541",
        return_at_close: 1.000195517646337,
        trade_pair: ["ETHUSD", "ETH/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: -939.4338095238069,
        close_ms: 1718392029966,
        current_return: 1.2677389538772061,
        initial_entry_price: 4994.06,
        is_closed_position: true,
        miner_hotkey: "5CSHrvBiEJAFAAj7YAr5y8jzmpFajRsV9PahphPGi7P8PZrA",
        net_leverage: 0.0,
        open_ms: 1713794560845,
        orders: [
          {
            leverage: 10.0,
            order_type: "LONG",
            order_uuid: "c5c1aa47-a539-49f8-a0ee-4e3b9391f2b3",
            price: 4994.06,
            price_sources: [],
            processed_ms: 1713794560838,
          },
          {
            leverage: 5.0,
            order_type: "LONG",
            order_uuid: "2a7ed29b-4b11-4763-bdd6-b8cbbf10ac84",
            price: 4984.81,
            price_sources: [],
            processed_ms: 1713797262274,
          },
          {
            leverage: -14.0,
            order_type: "SHORT",
            order_uuid: "21bb1167-a923-4afe-900c-af5eac8dfb3d",
            price: 5067.6,
            price_sources: [],
            processed_ms: 1713891328837,
          },
          {
            leverage: -0.99,
            order_type: "SHORT",
            order_uuid: "37c54023-752a-4913-9e8e-1ec03aa8c58f",
            price: 5230.49,
            price_sources: [],
            processed_ms: 1715699440308,
          },
          {
            leverage: 0.2,
            order_type: "LONG",
            order_uuid: "00415119-2fb4-4586-b19b-71d67597560c",
            price: 5313.27,
            price_sources: [],
            processed_ms: 1716302859641,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "c8e3209e-a340-401f-9770-f07cfa567a55",
            price: 5427.7300000000005,
            price_sources: [],
            processed_ms: 1718392029966,
          },
        ],
        position_type: "FLAT",
        position_uuid: "7e303e74-ef4a-48f3-8e28-fa6d8111d45b",
        return_at_close: 1.2660046869883022,
        trade_pair: ["SPX", "SPX", 9e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.8177607974670973,
        close_ms: 1718392067278,
        current_return: 1.008040523266296,
        initial_entry_price: 0.81614,
        is_closed_position: true,
        miner_hotkey: "5CSHrvBiEJAFAAj7YAr5y8jzmpFajRsV9PahphPGi7P8PZrA",
        net_leverage: 0.0,
        open_ms: 1713343439847,
        orders: [
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "0b4694ba-9da3-4c52-b15b-9ad08e7a8561",
            price: 0.81614,
            price_sources: [],
            processed_ms: 1713343439845,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "03b7f7bc-752a-4416-a937-251d2e3a953f",
            price: 0.81578,
            price_sources: [],
            processed_ms: 1713353652878,
          },
          {
            leverage: 0.03,
            order_type: "LONG",
            order_uuid: "097f2d3f-b188-4d90-8912-813175d89e2c",
            price: 0.81495,
            price_sources: [],
            processed_ms: 1713441317712,
          },
          {
            leverage: 0.05,
            order_type: "LONG",
            order_uuid: "3b436dd6-bdd6-44af-9880-b3d71dde36ab",
            price: 0.8124,
            price_sources: [],
            processed_ms: 1713475134728,
          },
          {
            leverage: 0.1,
            order_type: "LONG",
            order_uuid: "bf4fa906-613b-41e8-9d6e-93d82e6c575e",
            price: 0.81181,
            price_sources: [],
            processed_ms: 1713759204041,
          },
          {
            leverage: 0.15,
            order_type: "LONG",
            order_uuid: "8cdc442f-8879-4c5e-81b2-749943aafc49",
            price: 0.810056755831927,
            price_sources: [],
            processed_ms: 1713794827061,
          },
          {
            leverage: -0.34,
            order_type: "SHORT",
            order_uuid: "0bdece37-0d65-4168-b8f6-375c5ebc17e3",
            price: 0.81279,
            price_sources: [],
            processed_ms: 1714051546983,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "6963f56e-ed3b-430c-abee-39163951915b",
            price: 0.834837478680197,
            price_sources: [],
            processed_ms: 1716160965063,
          },
          {
            leverage: 1.0,
            order_type: "LONG",
            order_uuid: "f8b2be53-71c5-4131-b421-16d35ce87c0b",
            price: 0.8325770735546214,
            price_sources: [],
            processed_ms: 1716301100812,
          },
          {
            leverage: -1.0,
            order_type: "SHORT",
            order_uuid: "ad779a40-4b5b-4186-bae7-878a7b8f8a1b",
            price: 0.8363972492580145,
            price_sources: [],
            processed_ms: 1717158206181,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "2a2a4cd4-7a1e-4754-bbdf-347a963b5902",
            price: 0.843,
            price_sources: [],
            processed_ms: 1718392067278,
          },
        ],
        position_type: "FLAT",
        position_uuid: "f458318a-69cc-4a6e-8027-d2208cb9ca92",
        return_at_close: 1.00792762272769,
        trade_pair: ["NZDCAD", "NZD/CAD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 85.00500000000024,
        close_ms: 1718392094256,
        current_return: 1.001850797543624,
        initial_entry_price: 102.753,
        is_closed_position: true,
        miner_hotkey: "5CSHrvBiEJAFAAj7YAr5y8jzmpFajRsV9PahphPGi7P8PZrA",
        net_leverage: 0.0,
        open_ms: 1714403364057,
        orders: [
          {
            leverage: 0.1,
            order_type: "LONG",
            order_uuid: "0a4e9cd3-c179-4783-be9e-be87ba8092e5",
            price: 102.753,
            price_sources: [],
            processed_ms: 1714403364052,
          },
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "6d82a48c-e59f-43f2-8213-7ba7d327a24c",
            price: 100.825,
            price_sources: [],
            processed_ms: 1714670976300,
          },
          {
            leverage: -0.39,
            order_type: "SHORT",
            order_uuid: "4f6d2605-51c0-4c65-b6d5-369929306dbc",
            price: 101.725,
            price_sources: [],
            processed_ms: 1714963376167,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "1609811c-cd4a-4770-ba22-3093bd549013",
            price: 104.02250000000001,
            price_sources: [],
            processed_ms: 1718392094256,
          },
        ],
        position_type: "FLAT",
        position_uuid: "07246750-5b1d-4f05-81b2-e65d3300debf",
        return_at_close: 1.0018227457212927,
        trade_pair: ["AUDJPY", "AUD/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: -2727.565999999,
        close_ms: 1718392116143,
        current_return: 0.9847430023455827,
        initial_entry_price: 191.85,
        is_closed_position: true,
        miner_hotkey: "5CSHrvBiEJAFAAj7YAr5y8jzmpFajRsV9PahphPGi7P8PZrA",
        net_leverage: 0.0,
        open_ms: 1714711796638,
        orders: [
          {
            leverage: -5.0,
            order_type: "SHORT",
            order_uuid: "7f8cc68c-8a7d-4495-b900-2c6d9d9a64a5",
            price: 191.85,
            price_sources: [],
            processed_ms: 1714711796462,
          },
          {
            leverage: 4.999,
            order_type: "LONG",
            order_uuid: "f3b80768-5195-4751-bef9-8acfe3d49c4c",
            price: 192.434,
            price_sources: [],
            processed_ms: 1714725786146,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "d501be52-c974-4e1e-96b7-14e4536d3de8",
            price: 199.489,
            price_sources: [],
            processed_ms: 1718392116143,
          },
        ],
        position_type: "FLAT",
        position_uuid: "7c5e98a7-c1e7-434b-9e2f-6c21ccde6a69",
        return_at_close: 0.9843983422947618,
        trade_pair: ["GBPJPY", "GBP/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 69130.3425,
        close_ms: 1718392137897,
        current_return: 0.9784464565034466,
        initial_entry_price: 68904.54,
        is_closed_position: true,
        miner_hotkey: "5CSHrvBiEJAFAAj7YAr5y8jzmpFajRsV9PahphPGi7P8PZrA",
        net_leverage: 0.0,
        open_ms: 1716579521706,
        orders: [
          {
            leverage: 0.1,
            order_type: "LONG",
            order_uuid: "d66f2a34-2d53-4639-abd4-0738677963fe",
            price: 68904.54,
            price_sources: [],
            processed_ms: 1716579521526,
          },
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "19561306-4dfe-465a-8143-861220ae94bb",
            price: 69205.61,
            price_sources: [],
            processed_ms: 1716639396133,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "cf2bfd2e-2e0a-4d8f-b86b-561ad8503a21",
            price: 65417.5,
            price_sources: [],
            processed_ms: 1718392137897,
          },
        ],
        position_type: "FLAT",
        position_uuid: "d7384cef-9f87-46dc-bb05-654d09f3ece0",
        return_at_close: 0.9780550779208453,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 3453.68,
        close_ms: 0,
        current_return: 0.862438037108244,
        initial_entry_price: 3453.68,
        is_closed_position: false,
        miner_hotkey: "5CSHrvBiEJAFAAj7YAr5y8jzmpFajRsV9PahphPGi7P8PZrA",
        net_leverage: 1.5,
        open_ms: 1719577479400,
        orders: [
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "396c7fb9-8f0e-436e-aca5-84b4c8b1e9c7",
            price: 3453.68,
            price_sources: [],
            processed_ms: 1719577478564,
          },
        ],
        position_type: "LONG",
        position_uuid: "aceaa6ee-1710-4da3-878d-5c7fcf1d809c",
        return_at_close: 0.8617912085804128,
        trade_pair: ["ETHUSD", "ETH/USD", 0.003, 0.001, 20],
      },
    ],
    thirty_day_returns: 1.0,
  },
  "5CXkDb4mnFrneaoFfA2cQmPWpVc792fSvSMoQmuzrz78F9GY": {
    all_time_returns: 1.0,
    n_positions: 0,
    percentage_profitable: 0.0,
    positions: [
      {
        average_entry_price: 64510.23,
        close_ms: 1711193916461,
        current_return: 1.0004913639278608,
        initial_entry_price: 64510.23,
        is_closed_position: true,
        miner_hotkey: "5CXkDb4mnFrneaoFfA2cQmPWpVc792fSvSMoQmuzrz78F9GY",
        net_leverage: 0.0,
        open_ms: 1711190344743,
        orders: [
          {
            leverage: 0.6,
            order_type: "LONG",
            order_uuid: "081c3c5c-f600-4759-a17c-c2795645f252",
            price: 64510.23,
            price_sources: [],
            processed_ms: 1711190344743,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "ecf053a9-e17c-462b-a4aa-70af8708d528",
            price: 64563.06,
            price_sources: [],
            processed_ms: 1711193916461,
          },
        ],
        position_type: "FLAT",
        position_uuid: "259c74de-296a-4692-8232-9c8b584f0122",
        return_at_close: 0.9992907742911473,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 64523.35999999999,
        close_ms: 1711197583250,
        current_return: 1.001596910018325,
        initial_entry_price: 64523.36,
        is_closed_position: true,
        miner_hotkey: "5CXkDb4mnFrneaoFfA2cQmPWpVc792fSvSMoQmuzrz78F9GY",
        net_leverage: 0.0,
        open_ms: 1711194004266,
        orders: [
          {
            leverage: 0.6,
            order_type: "LONG",
            order_uuid: "16a4c4bf-394a-4033-9bca-8d02b4ad1e00",
            price: 64523.36,
            price_sources: [],
            processed_ms: 1711194004265,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "590322ad-7f1d-4c02-ada1-9de57960b805",
            price: 64695.09,
            price_sources: [],
            processed_ms: 1711197583250,
          },
        ],
        position_type: "FLAT",
        position_uuid: "c0bbf6ae-c47f-42a3-bb1a-678b0d6ea838",
        return_at_close: 1.000394993726303,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 64707.25,
        close_ms: 1711201241367,
        current_return: 0.9984347039937566,
        initial_entry_price: 64707.25,
        is_closed_position: true,
        miner_hotkey: "5CXkDb4mnFrneaoFfA2cQmPWpVc792fSvSMoQmuzrz78F9GY",
        net_leverage: 0.0,
        open_ms: 1711197669393,
        orders: [
          {
            leverage: 0.6,
            order_type: "LONG",
            order_uuid: "e04d7226-edcc-4c10-a98f-beec9ec8af45",
            price: 64707.25,
            price_sources: [],
            processed_ms: 1711197669392,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "13cf770d-2efa-47d8-9d3b-5e2bf82c4b66",
            price: 64538.44,
            price_sources: [],
            processed_ms: 1711201241367,
          },
        ],
        position_type: "FLAT",
        position_uuid: "3094d966-06ab-4121-bd79-099cc72ded2a",
        return_at_close: 0.9972365823489641,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 64497.5,
        close_ms: 1711204903288,
        current_return: 1.0060262800883755,
        initial_entry_price: 64497.5,
        is_closed_position: true,
        miner_hotkey: "5CXkDb4mnFrneaoFfA2cQmPWpVc792fSvSMoQmuzrz78F9GY",
        net_leverage: 0.0,
        open_ms: 1711201328423,
        orders: [
          {
            leverage: 0.6,
            order_type: "LONG",
            order_uuid: "4d023193-ebcc-4d80-9350-0733f1ac4826",
            price: 64497.5,
            price_sources: [],
            processed_ms: 1711201328422,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "3cb61e62-415e-46bc-8f01-7b28d49ccbf1",
            price: 65145.3,
            price_sources: [],
            processed_ms: 1711204903288,
          },
        ],
        position_type: "FLAT",
        position_uuid: "c54f6145-dc9f-409c-9ce2-5ad7ce7c5705",
        return_at_close: 1.0048190485522694,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 64845.45,
        close_ms: 1711212232633,
        current_return: 1.0076417697772164,
        initial_entry_price: 64845.45,
        is_closed_position: true,
        miner_hotkey: "5CXkDb4mnFrneaoFfA2cQmPWpVc792fSvSMoQmuzrz78F9GY",
        net_leverage: 0.0,
        open_ms: 1711208651344,
        orders: [
          {
            leverage: 0.6,
            order_type: "LONG",
            order_uuid: "511a55d5-de2a-4bfc-a0a4-a4cb2162183a",
            price: 64845.45,
            price_sources: [],
            processed_ms: 1711208651343,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "0eacf3cb-fdb6-4fdf-90b7-30c5c150e6ee",
            price: 65671.34,
            price_sources: [],
            processed_ms: 1711212232633,
          },
        ],
        position_type: "FLAT",
        position_uuid: "bb7ca187-b916-4fc2-816e-17ac3c9e8987",
        return_at_close: 1.0064325996534838,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 65709.63,
        close_ms: 1711219549504,
        current_return: 0.9972380304074151,
        initial_entry_price: 65709.63,
        is_closed_position: true,
        miner_hotkey: "5CXkDb4mnFrneaoFfA2cQmPWpVc792fSvSMoQmuzrz78F9GY",
        net_leverage: 0.0,
        open_ms: 1711215976459,
        orders: [
          {
            leverage: 0.6,
            order_type: "LONG",
            order_uuid: "6da38c40-b223-4bdc-98c9-43e4ce0c69f8",
            price: 65709.63,
            price_sources: [],
            processed_ms: 1711215976458,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "58a9f3ab-24bb-4296-af3d-c392ca1b50aa",
            price: 65407.15,
            price_sources: [],
            processed_ms: 1711219549504,
          },
        ],
        position_type: "FLAT",
        position_uuid: "a400cca8-e387-40f7-b196-97723720cf85",
        return_at_close: 0.9960413447709262,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 65355.240000000005,
        close_ms: 1711223212406,
        current_return: 0.9951620099627818,
        initial_entry_price: 65355.24,
        is_closed_position: true,
        miner_hotkey: "5CXkDb4mnFrneaoFfA2cQmPWpVc792fSvSMoQmuzrz78F9GY",
        net_leverage: 0.0,
        open_ms: 1711219636465,
        orders: [
          {
            leverage: 0.6,
            order_type: "LONG",
            order_uuid: "7c7033e0-917c-4c58-b24f-288d2e126aeb",
            price: 65355.24,
            price_sources: [],
            processed_ms: 1711219636464,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "e40f1e36-d038-44c6-b4f1-f25b3fdd0805",
            price: 64828.26,
            price_sources: [],
            processed_ms: 1711223212406,
          },
        ],
        position_type: "FLAT",
        position_uuid: "1b2307ed-0b5d-41f4-aa18-82bc79aa8a64",
        return_at_close: 0.9939678155508265,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 64962.85,
        close_ms: 1711234193036,
        current_return: 0.9991960943831744,
        initial_entry_price: 64962.85,
        is_closed_position: true,
        miner_hotkey: "5CXkDb4mnFrneaoFfA2cQmPWpVc792fSvSMoQmuzrz78F9GY",
        net_leverage: 0.0,
        open_ms: 1711230654237,
        orders: [
          {
            leverage: 0.6,
            order_type: "LONG",
            order_uuid: "069a193e-edad-467d-b7c3-9c0c345b26af",
            price: 64962.85,
            price_sources: [],
            processed_ms: 1711230654236,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "072a0010-f590-4cf5-9002-71020983ec0d",
            price: 64875.81,
            price_sources: [],
            processed_ms: 1711234193036,
          },
        ],
        position_type: "FLAT",
        position_uuid: "07f08e2c-2084-4d9e-8bc0-9e36da63403f",
        return_at_close: 0.9979970590699145,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 64849.77999999999,
        close_ms: 1711237859341,
        current_return: 0.9949742312155879,
        initial_entry_price: 64849.78,
        is_closed_position: true,
        miner_hotkey: "5CXkDb4mnFrneaoFfA2cQmPWpVc792fSvSMoQmuzrz78F9GY",
        net_leverage: 0.0,
        open_ms: 1711234318034,
        orders: [
          {
            leverage: 0.6,
            order_type: "LONG",
            order_uuid: "d8c40e3e-f804-4b8d-b978-1011ef5b5710",
            price: 64849.78,
            price_sources: [],
            processed_ms: 1711234318033,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "258dea7b-07a0-4b86-bed0-f6e28b6d021b",
            price: 64306.58,
            price_sources: [],
            processed_ms: 1711237859341,
          },
        ],
        position_type: "FLAT",
        position_uuid: "2005b2a6-441b-4e36-9739-fc619d341294",
        return_at_close: 0.9937802621381292,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 64434.74,
        close_ms: 1711241514478,
        current_return: 1.00123119919472,
        initial_entry_price: 64434.74,
        is_closed_position: true,
        miner_hotkey: "5CXkDb4mnFrneaoFfA2cQmPWpVc792fSvSMoQmuzrz78F9GY",
        net_leverage: 0.0,
        open_ms: 1711237946195,
        orders: [
          {
            leverage: 0.6,
            order_type: "LONG",
            order_uuid: "50d1a296-492a-4cda-bd65-934dc9a75665",
            price: 64434.74,
            price_sources: [],
            processed_ms: 1711237946194,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "dc45485b-c87a-4d10-a90d-f7a66e739f81",
            price: 64566.96,
            price_sources: [],
            processed_ms: 1711241514478,
          },
        ],
        position_type: "FLAT",
        position_uuid: "c2e22869-360c-4bf8-a1a4-c645ac0ae7ef",
        return_at_close: 1.0000297217556864,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 64543.100000000006,
        close_ms: 1711248842279,
        current_return: 0.9967798261936597,
        initial_entry_price: 64543.1,
        is_closed_position: true,
        miner_hotkey: "5CXkDb4mnFrneaoFfA2cQmPWpVc792fSvSMoQmuzrz78F9GY",
        net_leverage: 0.0,
        open_ms: 1711245306507,
        orders: [
          {
            leverage: 0.6,
            order_type: "LONG",
            order_uuid: "279a58e1-d634-41e1-ab87-f9c1370c579e",
            price: 64543.1,
            price_sources: [],
            processed_ms: 1711245306506,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "b49375eb-fb39-47c1-ba6a-22317cad41ba",
            price: 64196.7,
            price_sources: [],
            processed_ms: 1711248842279,
          },
        ],
        position_type: "FLAT",
        position_uuid: "e4d0c6cf-ed30-490f-853c-060019b1d0ef",
        return_at_close: 0.9955836904022273,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 64230.0,
        close_ms: 1711252513487,
        current_return: 0.9984941616067259,
        initial_entry_price: 64230.0,
        is_closed_position: true,
        miner_hotkey: "5CXkDb4mnFrneaoFfA2cQmPWpVc792fSvSMoQmuzrz78F9GY",
        net_leverage: 0.0,
        open_ms: 1711248929460,
        orders: [
          {
            leverage: 0.6,
            order_type: "LONG",
            order_uuid: "ad249d0d-2e8a-4e88-9098-dbe13af005b9",
            price: 64230.0,
            price_sources: [],
            processed_ms: 1711248929459,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "c25e7d0d-77db-4a4a-b844-6c0562afb2bc",
            price: 64068.8,
            price_sources: [],
            processed_ms: 1711252513487,
          },
        ],
        position_type: "FLAT",
        position_uuid: "e76fb744-86ae-4c63-bac7-46b933337a01",
        return_at_close: 0.9972959686127978,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 64099.49,
        close_ms: 1711256616016,
        current_return: 1.0014170783574097,
        initial_entry_price: 64099.49,
        is_closed_position: true,
        miner_hotkey: "5CXkDb4mnFrneaoFfA2cQmPWpVc792fSvSMoQmuzrz78F9GY",
        net_leverage: 0.0,
        open_ms: 1711252600280,
        orders: [
          {
            leverage: 0.6,
            order_type: "LONG",
            order_uuid: "cba5eb79-99b1-4a13-a277-d00c2062246a",
            price: 64099.49,
            price_sources: [],
            processed_ms: 1711252600279,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "abd22e25-3228-43d6-94ba-3ccda2c700af",
            price: 64250.88,
            price_sources: [],
            processed_ms: 1711256616016,
          },
        ],
        position_type: "FLAT",
        position_uuid: "be333ca9-8a30-4523-9465-dcd8ad618752",
        return_at_close: 1.0002153778633809,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 64314.67,
        close_ms: 1711263761284,
        current_return: 0.997693761003516,
        initial_entry_price: 64314.67,
        is_closed_position: true,
        miner_hotkey: "5CXkDb4mnFrneaoFfA2cQmPWpVc792fSvSMoQmuzrz78F9GY",
        net_leverage: 0.0,
        open_ms: 1711260183572,
        orders: [
          {
            leverage: 1.25,
            order_type: "LONG",
            order_uuid: "bfce0e8d-a93a-4fee-a7aa-0dc44af95217",
            price: 64314.67,
            price_sources: [],
            processed_ms: 1711260183571,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "2b3a1d20-0b1d-4f1c-aa8e-0eedfed95d54",
            price: 64196.01,
            price_sources: [],
            processed_ms: 1711263761284,
          },
        ],
        position_type: "FLAT",
        position_uuid: "fccb6def-eb81-45aa-8963-106ccb3cac36",
        return_at_close: 0.9951995266010073,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 64133.48,
        close_ms: 1711267417346,
        current_return: 1.0073191100810373,
        initial_entry_price: 64133.48,
        is_closed_position: true,
        miner_hotkey: "5CXkDb4mnFrneaoFfA2cQmPWpVc792fSvSMoQmuzrz78F9GY",
        net_leverage: 0.0,
        open_ms: 1711263864228,
        orders: [
          {
            leverage: 1.25,
            order_type: "LONG",
            order_uuid: "61425b4b-ae1f-425c-9965-017d2a147508",
            price: 64133.48,
            price_sources: [],
            processed_ms: 1711263864226,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "fe71ccd6-8b9c-4163-8bc3-3b4c9e63208a",
            price: 64509.0,
            price_sources: [],
            processed_ms: 1711267417346,
          },
        ],
        position_type: "FLAT",
        position_uuid: "3e7e7958-5d59-4c3c-8aa6-10ed76461dd0",
        return_at_close: 1.0048008123058347,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 65009.58999999999,
        close_ms: 1711278406186,
        current_return: 1.000675284984877,
        initial_entry_price: 65009.59,
        is_closed_position: true,
        miner_hotkey: "5CXkDb4mnFrneaoFfA2cQmPWpVc792fSvSMoQmuzrz78F9GY",
        net_leverage: 0.0,
        open_ms: 1711274849540,
        orders: [
          {
            leverage: 1.25,
            order_type: "LONG",
            order_uuid: "0bc2e586-0161-4c5c-8271-932f50f86d78",
            price: 65009.59,
            price_sources: [],
            processed_ms: 1711274849539,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "c82e6665-8877-4521-a21d-cb77c8d4906e",
            price: 65044.71,
            price_sources: [],
            processed_ms: 1711278406186,
          },
        ],
        position_type: "FLAT",
        position_uuid: "79d2288f-c4f8-415e-815a-b151c64ec51b",
        return_at_close: 0.9981735967724149,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 64982.259999999995,
        close_ms: 1711282176554,
        current_return: 1.0107985240894979,
        initial_entry_price: 64982.26,
        is_closed_position: true,
        miner_hotkey: "5CXkDb4mnFrneaoFfA2cQmPWpVc792fSvSMoQmuzrz78F9GY",
        net_leverage: 0.0,
        open_ms: 1711278492162,
        orders: [
          {
            leverage: 1.25,
            order_type: "LONG",
            order_uuid: "62e9a955-c0cd-406e-b377-87ae0b0a192f",
            price: 64982.26,
            price_sources: [],
            processed_ms: 1711278492160,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "f5522bdf-5a35-446f-b492-2e3d0b82a13a",
            price: 65543.63,
            price_sources: [],
            processed_ms: 1711282176554,
          },
        ],
        position_type: "FLAT",
        position_uuid: "81c2f885-db98-4117-b9ed-4cf2fbbe981e",
        return_at_close: 1.0082715277792742,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 65346.49999999998,
        close_ms: 1711306605488,
        current_return: 1.0061530632383298,
        initial_entry_price: 65465.36,
        is_closed_position: true,
        miner_hotkey: "5CXkDb4mnFrneaoFfA2cQmPWpVc792fSvSMoQmuzrz78F9GY",
        net_leverage: 0.0,
        open_ms: 1711285835453,
        orders: [
          {
            leverage: 1.25,
            order_type: "LONG",
            order_uuid: "c4691491-ea35-4a5f-9db8-08e7a8d70bc0",
            price: 65465.36,
            price_sources: [],
            processed_ms: 1711285835451,
          },
          {
            leverage: 1.25,
            order_type: "LONG",
            order_uuid: "b7746a35-d67f-441b-a9f5-9fdfa2ce81ff",
            price: 65423.67,
            price_sources: [],
            processed_ms: 1711289494698,
          },
          {
            leverage: -1.25,
            order_type: "SHORT",
            order_uuid: "e2804025-e119-4e36-bdef-c7d358ab359a",
            price: 65542.53,
            price_sources: [],
            processed_ms: 1711291323758,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "47d597d3-ac53-4555-8111-41526d372eb5",
            price: 65668.75,
            price_sources: [],
            processed_ms: 1711306605488,
          },
        ],
        position_type: "FLAT",
        position_uuid: "faf466d0-4e6c-466a-9b3c-85fb9df023f1",
        return_at_close: 1.0011222979221381,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 65753.15,
        close_ms: 1711311263808,
        current_return: 1.0084571993889269,
        initial_entry_price: 65753.15,
        is_closed_position: true,
        miner_hotkey: "5CXkDb4mnFrneaoFfA2cQmPWpVc792fSvSMoQmuzrz78F9GY",
        net_leverage: 0.0,
        open_ms: 1711307531416,
        orders: [
          {
            leverage: 1.25,
            order_type: "LONG",
            order_uuid: "dcac76c7-83d0-4e3a-8048-0f9341053d5a",
            price: 65753.15,
            price_sources: [],
            processed_ms: 1711307531414,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "e8689371-6d76-44d6-b35a-054c2cd812e6",
            price: 66198.02,
            price_sources: [],
            processed_ms: 1711311263808,
          },
        ],
        position_type: "FLAT",
        position_uuid: "89bc45d3-3688-44d6-825a-569903a1f345",
        return_at_close: 1.0059360563904547,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 66153.63,
        close_ms: 1711315002414,
        current_return: 1.0041862782133042,
        initial_entry_price: 66153.63,
        is_closed_position: true,
        miner_hotkey: "5CXkDb4mnFrneaoFfA2cQmPWpVc792fSvSMoQmuzrz78F9GY",
        net_leverage: 0.0,
        open_ms: 1711314053321,
        orders: [
          {
            leverage: 1.25,
            order_type: "LONG",
            order_uuid: "8220d93c-5d73-4394-917a-b2935a3e2e62",
            price: 66153.63,
            price_sources: [],
            processed_ms: 1711314053320,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "1897374c-add1-4ab8-8cc4-8670ba280ac9",
            price: 66375.18,
            price_sources: [],
            processed_ms: 1711315002414,
          },
        ],
        position_type: "FLAT",
        position_uuid: "5d9f4a4b-4bab-486e-bd23-e6149ccc8bc3",
        return_at_close: 1.001675812517771,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 66263.13,
        close_ms: 1711318698520,
        current_return: 1.0097773075313525,
        initial_entry_price: 66263.13,
        is_closed_position: true,
        miner_hotkey: "5CXkDb4mnFrneaoFfA2cQmPWpVc792fSvSMoQmuzrz78F9GY",
        net_leverage: 0.0,
        open_ms: 1711316836558,
        orders: [
          {
            leverage: 1.25,
            order_type: "LONG",
            order_uuid: "90bc0f1d-9aa6-44a2-830a-d8e83d7f8e9a",
            price: 66263.13,
            price_sources: [],
            processed_ms: 1711316836556,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "869be431-206b-44f1-beef-2a352355da94",
            price: 66781.43,
            price_sources: [],
            processed_ms: 1711318698520,
          },
        ],
        position_type: "FLAT",
        position_uuid: "0f5cff3e-ecb7-4cbd-8560-3ab8afde1678",
        return_at_close: 1.0072528642625243,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 66875.89,
        close_ms: 1711327069255,
        current_return: 0.9960443517088146,
        initial_entry_price: 66875.89,
        is_closed_position: true,
        miner_hotkey: "5CXkDb4mnFrneaoFfA2cQmPWpVc792fSvSMoQmuzrz78F9GY",
        net_leverage: 0.0,
        open_ms: 1711326149299,
        orders: [
          {
            leverage: 1.25,
            order_type: "LONG",
            order_uuid: "931df8b3-5c54-4943-8410-0b38b59002d0",
            price: 66875.89,
            price_sources: [],
            processed_ms: 1711326149298,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "a3c3b5e2-3fef-4723-8112-f059d5e975b4",
            price: 66664.26,
            price_sources: [],
            processed_ms: 1711327069255,
          },
        ],
        position_type: "FLAT",
        position_uuid: "c9a8b970-3f2d-4ec4-94e5-b91710770e37",
        return_at_close: 0.9935542408295426,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 66619.0,
        close_ms: 1711343208430,
        current_return: 1.0179481454239783,
        initial_entry_price: 66619.0,
        is_closed_position: true,
        miner_hotkey: "5CXkDb4mnFrneaoFfA2cQmPWpVc792fSvSMoQmuzrz78F9GY",
        net_leverage: 0.0,
        open_ms: 1711335877512,
        orders: [
          {
            leverage: 1.25,
            order_type: "LONG",
            order_uuid: "6c4c858e-5a86-4f7c-9e07-e964a557b464",
            price: 66619.0,
            price_sources: [],
            processed_ms: 1711335877510,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "1d355d49-8f14-480d-8f63-f7ba6621140c",
            price: 67575.55,
            price_sources: [],
            processed_ms: 1711343208430,
          },
        ],
        position_type: "FLAT",
        position_uuid: "bff43baa-f5f4-4760-8bef-3c9c4cba9e81",
        return_at_close: 1.0154032750604183,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 67563.15400000001,
        close_ms: 1711376517346,
        current_return: 1.0583449112298842,
        initial_entry_price: 67643.26,
        is_closed_position: true,
        miner_hotkey: "5CXkDb4mnFrneaoFfA2cQmPWpVc792fSvSMoQmuzrz78F9GY",
        net_leverage: 0.0,
        open_ms: 1711345032531,
        orders: [
          {
            leverage: 1.25,
            order_type: "LONG",
            order_uuid: "c419f860-9c94-4b25-9f55-f236b358dc58",
            price: 67643.26,
            price_sources: [],
            processed_ms: 1711345032529,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "2779e374-577d-467b-8b45-9a8cac8c0daa",
            price: 66898.35,
            price_sources: [],
            processed_ms: 1711368461876,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "5784ca3a-a597-40a6-9928-b4587b25275d",
            price: 67216.31,
            price_sources: [],
            processed_ms: 1711370296617,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "1388f6fe-64de-498b-a17a-43d1d424d129",
            price: 67160.87,
            price_sources: [],
            processed_ms: 1711372120487,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "da5586a3-e2e8-4497-81da-664ac963bbcc",
            price: 67143.56,
            price_sources: [],
            processed_ms: 1711373951686,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "59d509e8-3520-4d87-8d72-326ce3350c68",
            price: 68996.15,
            price_sources: [],
            processed_ms: 1711376350605,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "99b4e3fa-c6be-45d2-8402-c14aebb82384",
            price: 69141.81,
            price_sources: [],
            processed_ms: 1711376517346,
          },
        ],
        position_type: "FLAT",
        position_uuid: "e019d9f2-ad3c-415e-88d9-18e787ed3bed",
        return_at_close: 1.0530531866737347,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 107884.36000000106,
        close_ms: 1711384017542,
        current_return: 1.0027629063732926,
        initial_entry_price: 69210.38,
        is_closed_position: true,
        miner_hotkey: "5CXkDb4mnFrneaoFfA2cQmPWpVc792fSvSMoQmuzrz78F9GY",
        net_leverage: 0.0,
        open_ms: 1711376767551,
        orders: [
          {
            leverage: -1.25,
            order_type: "SHORT",
            order_uuid: "d1bb375e-d69e-4a94-8bde-fed11df094bf",
            price: 69210.38,
            price_sources: [],
            processed_ms: 1711376767549,
          },
          {
            leverage: 0.625,
            order_type: "LONG",
            order_uuid: "dab966de-427e-442d-9baf-35e583d407be",
            price: 68941.6,
            price_sources: [],
            processed_ms: 1711377823578,
          },
          {
            leverage: 0.5,
            order_type: "LONG",
            order_uuid: "71a4f2cf-ff69-425a-82ae-1174cd2dbf2e",
            price: 69074.0,
            price_sources: [],
            processed_ms: 1711378120547,
          },
          {
            leverage: 0.1,
            order_type: "LONG",
            order_uuid: "623d2b48-c5af-4418-9930-cd191e8fbfb8",
            price: 69524.96,
            price_sources: [],
            processed_ms: 1711380414364,
          },
          {
            leverage: 0.019999999999999997,
            order_type: "LONG",
            order_uuid: "62c690fe-da7e-4c94-a306-8624494427c3",
            price: 69777.86,
            price_sources: [],
            processed_ms: 1711382203536,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "b1b0cafc-11bc-458d-9e69-b39d6144b21f",
            price: 69640.0,
            price_sources: [],
            processed_ms: 1711384017542,
          },
        ],
        position_type: "FLAT",
        position_uuid: "29e97faa-db7d-42e5-b63f-3a8a8b146dd9",
        return_at_close: 1.0002559991073594,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 70518.94214285714,
        close_ms: 1711402303038,
        current_return: 0.9741403943741224,
        initial_entry_price: 70236.86,
        is_closed_position: true,
        miner_hotkey: "5CXkDb4mnFrneaoFfA2cQmPWpVc792fSvSMoQmuzrz78F9GY",
        net_leverage: 0.0,
        open_ms: 1711385862529,
        orders: [
          {
            leverage: 1.25,
            order_type: "LONG",
            order_uuid: "20a87c72-7318-43a9-9a81-f49f6cd68007",
            price: 70236.86,
            price_sources: [],
            processed_ms: 1711385862523,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "a94b7012-710f-4c8e-be01-daa02e1e4c0a",
            price: 70707.34,
            price_sources: [],
            processed_ms: 1711387648418,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "6f57ff77-91ea-403c-952f-3a15a8ac6cec",
            price: 70565.58,
            price_sources: [],
            processed_ms: 1711389458607,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "fc2fa907-2a4e-4ad2-8ede-c4edd3bb22f6",
            price: 70839.25,
            price_sources: [],
            processed_ms: 1711391274867,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "9563c928-420b-4504-96a3-f8d9c8e253b5",
            price: 70911.15,
            price_sources: [],
            processed_ms: 1711393098404,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "c8981e87-728a-4099-8f94-fbcb929cb75e",
            price: 70578.97,
            price_sources: [],
            processed_ms: 1711394913463,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "be439f28-30e7-4d5e-a7a2-ca104f714b9d",
            price: 71041.83,
            price_sources: [],
            processed_ms: 1711396739432,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "da25d0b2-b204-465a-866d-08463c92e3fc",
            price: 70871.16,
            price_sources: [],
            processed_ms: 1711398545663,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "2268a2ed-1986-4cbb-a8fa-d7bb30f04118",
            price: 70941.85,
            price_sources: [],
            processed_ms: 1711400366738,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "1096323d-bd69-4ae7-b00c-5e477796e688",
            price: 69623.76,
            price_sources: [],
            processed_ms: 1711402181362,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "ef191af2-3c6d-4b26-ba6e-f94eda1708ed",
            price: 70000.0,
            price_sources: [],
            processed_ms: 1711402303038,
          },
        ],
        position_type: "FLAT",
        position_uuid: "4db34984-52b8-4e36-a539-a3309edeace1",
        return_at_close: 0.9673214116135035,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: -49996.34999999934,
        close_ms: 1711418564568,
        current_return: 1.0067122770355061,
        initial_entry_price: 69924.25,
        is_closed_position: true,
        miner_hotkey: "5CXkDb4mnFrneaoFfA2cQmPWpVc792fSvSMoQmuzrz78F9GY",
        net_leverage: 0.0,
        open_ms: 1711404034706,
        orders: [
          {
            leverage: 1.25,
            order_type: "LONG",
            order_uuid: "f379f266-45ca-4e94-8cb8-c2e17239ac61",
            price: 69924.25,
            price_sources: [],
            processed_ms: 1711404034704,
          },
          {
            leverage: -0.625,
            order_type: "SHORT",
            order_uuid: "423eea68-39ac-4ab0-aaf8-29ea615e05de",
            price: 70395.73,
            price_sources: [],
            processed_ms: 1711405812611,
          },
          {
            leverage: -0.3125,
            order_type: "SHORT",
            order_uuid: "64473670-2833-4338-9bb7-47f9d246b7fb",
            price: 70332.71,
            price_sources: [],
            processed_ms: 1711407632566,
          },
          {
            leverage: -0.15625,
            order_type: "SHORT",
            order_uuid: "d460e045-1196-487d-aa4d-ee1630377147",
            price: 70192.79,
            price_sources: [],
            processed_ms: 1711409448877,
          },
          {
            leverage: -0.078125,
            order_type: "SHORT",
            order_uuid: "79b14ee4-37e0-469c-93b9-30a4d8dbb4de",
            price: 69890.28,
            price_sources: [],
            processed_ms: 1711411272474,
          },
          {
            leverage: -0.0390625,
            order_type: "SHORT",
            order_uuid: "1a2c43b7-cc43-46f0-8600-9f6b1fa05600",
            price: 69994.45,
            price_sources: [],
            processed_ms: 1711413088341,
          },
          {
            leverage: -0.01953125,
            order_type: "SHORT",
            order_uuid: "1ce366c2-12ea-4e3f-8597-dd5188effcd8",
            price: 69895.37,
            price_sources: [],
            processed_ms: 1711414898464,
          },
          {
            leverage: -0.015625,
            order_type: "SHORT",
            order_uuid: "0559396b-bc7b-41de-bd65-f7f220cf08c2",
            price: 70221.05,
            price_sources: [],
            processed_ms: 1711416712777,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "a8d5e8a9-2107-4e8b-91cb-7d6645b971e3",
            price: 70157.49,
            price_sources: [],
            processed_ms: 1711418564568,
          },
        ],
        position_type: "FLAT",
        position_uuid: "7d1f269b-823f-4e92-84f2-1d8032a98f59",
        return_at_close: 1.0041954963429174,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 69219.03999999998,
        close_ms: 1711429439416,
        current_return: 0.9952382630060312,
        initial_entry_price: 70250.1,
        is_closed_position: true,
        miner_hotkey: "5CXkDb4mnFrneaoFfA2cQmPWpVc792fSvSMoQmuzrz78F9GY",
        net_leverage: 0.0,
        open_ms: 1711420342853,
        orders: [
          {
            leverage: -1.25,
            order_type: "SHORT",
            order_uuid: "8914e785-6065-4b45-a136-261d9a8db3a2",
            price: 70250.1,
            price_sources: [],
            processed_ms: 1711420342851,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "4a678e9d-1db9-417c-8544-de99be365819",
            price: 70477.01,
            price_sources: [],
            processed_ms: 1711422162538,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "eb5a704c-8ccf-48c7-bdd9-15a6c824438d",
            price: 70458.06,
            price_sources: [],
            processed_ms: 1711423972613,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "40dbdf60-ff77-47f2-ba67-32e49b99f989",
            price: 70496.83,
            price_sources: [],
            processed_ms: 1711425794726,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "6c743bee-b022-4562-8cd7-7f3bccaba822",
            price: 70599.56,
            price_sources: [],
            processed_ms: 1711427666549,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "5eddee13-300c-49eb-8538-7acd4ef8c14a",
            price: 70557.09,
            price_sources: [],
            processed_ms: 1711429439416,
          },
        ],
        position_type: "FLAT",
        position_uuid: "951d86de-8da1-4254-a21b-d88b242060e3",
        return_at_close: 0.9927501673485162,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 65656.30000000002,
        close_ms: 1711443955753,
        current_return: 0.9910956767984453,
        initial_entry_price: 70332.83,
        is_closed_position: true,
        miner_hotkey: "5CXkDb4mnFrneaoFfA2cQmPWpVc792fSvSMoQmuzrz78F9GY",
        net_leverage: 0.0,
        open_ms: 1711433062905,
        orders: [
          {
            leverage: -1.25,
            order_type: "SHORT",
            order_uuid: "64cde1dd-e4d2-4d8d-913b-032fd378ec80",
            price: 70332.83,
            price_sources: [],
            processed_ms: 1711433062902,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "d863bb91-8a89-4ecd-9f95-fd73ef194dd1",
            price: 70582.55,
            price_sources: [],
            processed_ms: 1711434927387,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "4e381485-e023-42c7-8d82-b79c6e6b0814",
            price: 70870.53,
            price_sources: [],
            processed_ms: 1711436692276,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "fd2c685b-b732-41eb-b974-62c5a8b1eeb9",
            price: 71348.69,
            price_sources: [],
            processed_ms: 1711438508439,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "a249cf28-dc83-488b-a154-adfdff147682",
            price: 70710.67,
            price_sources: [],
            processed_ms: 1711440328001,
          },
          {
            leverage: 0.125,
            order_type: "LONG",
            order_uuid: "0d4018b8-8e3a-4f36-92b6-71a652731cd9",
            price: 70647.12,
            price_sources: [],
            processed_ms: 1711442146530,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "67a2f5b0-eeda-456c-b4d8-19f70e4da345",
            price: 70666.43,
            price_sources: [],
            processed_ms: 1711443955753,
          },
        ],
        position_type: "FLAT",
        position_uuid: "17f4ac3a-4ee3-49d1-90d7-411913bb6fe5",
        return_at_close: 0.9886179376064493,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 72783.79999999994,
        close_ms: 1711456681343,
        current_return: 1.0031730238668894,
        initial_entry_price: 71060.37,
        is_closed_position: true,
        miner_hotkey: "5CXkDb4mnFrneaoFfA2cQmPWpVc792fSvSMoQmuzrz78F9GY",
        net_leverage: 0.0,
        open_ms: 1711445772475,
        orders: [
          {
            leverage: -1.25,
            order_type: "SHORT",
            order_uuid: "7d2070a0-0049-4a51-be4c-6a81c74176b5",
            price: 71060.37,
            price_sources: [],
            processed_ms: 1711445772472,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "986c8184-49f2-4458-9567-e0eba3e514e0",
            price: 71091.99,
            price_sources: [],
            processed_ms: 1711447592648,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "0ba27493-479c-4907-b79a-c80523ec4122",
            price: 70961.87,
            price_sources: [],
            processed_ms: 1711449408656,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "4849ff59-a3dd-42f1-bca8-b6bd2539a1ab",
            price: 70872.21,
            price_sources: [],
            processed_ms: 1711451267229,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "014bca8c-98a3-4db4-a0af-aae2b84598ea",
            price: 70682.59,
            price_sources: [],
            processed_ms: 1711453036809,
          },
          {
            leverage: 0.125,
            order_type: "LONG",
            order_uuid: "4f6082a2-1af1-463f-ba62-bc585822caf4",
            price: 70602.58,
            price_sources: [],
            processed_ms: 1711454854386,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "b065f8a5-a849-475f-b136-9d1a44168330",
            price: 70979.99,
            price_sources: [],
            processed_ms: 1711456681343,
          },
        ],
        position_type: "FLAT",
        position_uuid: "0cf45f3d-4342-4737-82ad-5d73c01e3739",
        return_at_close: 1.0006650913072224,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 197329.5900000022,
        close_ms: 1711469400321,
        current_return: 1.011201607688326,
        initial_entry_price: 70935.08,
        is_closed_position: true,
        miner_hotkey: "5CXkDb4mnFrneaoFfA2cQmPWpVc792fSvSMoQmuzrz78F9GY",
        net_leverage: 0.0,
        open_ms: 1711458498002,
        orders: [
          {
            leverage: -1.25,
            order_type: "SHORT",
            order_uuid: "7d6874ab-0bcf-4a4b-af68-209977aa0ba6",
            price: 70935.08,
            price_sources: [],
            processed_ms: 1711458498000,
          },
          {
            leverage: 0.625,
            order_type: "LONG",
            order_uuid: "f613fdc8-31c2-43f4-be40-b262fcf9428e",
            price: 70335.29,
            price_sources: [],
            processed_ms: 1711460316569,
          },
          {
            leverage: 0.3125,
            order_type: "LONG",
            order_uuid: "45d7816b-1ce9-49d2-a5b6-38d7f6c0b7c8",
            price: 70644.31,
            price_sources: [],
            processed_ms: 1711462137936,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "40da3d8a-6f89-4f51-aeaa-79c514df47d1",
            price: 69862.59,
            price_sources: [],
            processed_ms: 1711463950890,
          },
          {
            leverage: 0.05,
            order_type: "LONG",
            order_uuid: "46d64e2c-53c1-416b-bb12-0f8dbe01a105",
            price: 69889.09,
            price_sources: [],
            processed_ms: 1711465764367,
          },
          {
            leverage: 0.006249999999999999,
            order_type: "LONG",
            order_uuid: "8073f890-bb5a-48cd-9c52-183b30ab16a7",
            price: 70325.59,
            price_sources: [],
            processed_ms: 1711467576856,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "890adb41-2fc0-43be-bd06-e8fe846bb185",
            price: 70195.68,
            price_sources: [],
            processed_ms: 1711469400321,
          },
        ],
        position_type: "FLAT",
        position_uuid: "e2f1c896-cc9b-416b-bb80-a0023035c945",
        return_at_close: 1.008673603669105,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: -80309.0537175899,
        close_ms: 1711525105547,
        current_return: 1.0094351010231166,
        initial_entry_price: 69651.98,
        is_closed_position: true,
        miner_hotkey: "5CXkDb4mnFrneaoFfA2cQmPWpVc792fSvSMoQmuzrz78F9GY",
        net_leverage: 0.0,
        open_ms: 1711473052951,
        orders: [
          {
            leverage: 1.25,
            order_type: "LONG",
            order_uuid: "1808098d-1bc4-4b19-afcb-9e886899073e",
            price: 69651.98,
            price_sources: [],
            processed_ms: 1711473052948,
          },
          {
            leverage: -0.625,
            order_type: "SHORT",
            order_uuid: "fe993d4f-c99f-4935-89ff-e6093bdbb6ec",
            price: 70130.01,
            price_sources: [],
            processed_ms: 1711474855444,
          },
          {
            leverage: -0.3125,
            order_type: "SHORT",
            order_uuid: "10ad50bb-72fc-4b77-99c1-41ec6fbe90db",
            price: 70201.4,
            price_sources: [],
            processed_ms: 1711476660325,
          },
          {
            leverage: -0.15625,
            order_type: "SHORT",
            order_uuid: "d6d0365c-e5b8-4754-935a-5ee6a67a3361",
            price: 70269.83,
            price_sources: [],
            processed_ms: 1711478482183,
          },
          {
            leverage: -0.078125,
            order_type: "SHORT",
            order_uuid: "76717f8e-5a79-4d33-8011-391ef4db6922",
            price: 69760.82,
            price_sources: [],
            processed_ms: 1711480295801,
          },
          {
            leverage: -0.0390625,
            order_type: "SHORT",
            order_uuid: "5f8acbf0-6c64-4a25-b235-e344cd386fb0",
            price: 69903.69,
            price_sources: [],
            processed_ms: 1711482109011,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "f7452394-f017-479c-9b01-a75e775e2101",
            price: 69770.56,
            price_sources: [],
            processed_ms: 1711483925050,
          },
          {
            leverage: -0.14453125,
            order_type: "SHORT",
            order_uuid: "9f7bf9bd-6100-4386-938d-be0770b80ded",
            price: 69792.43,
            price_sources: [],
            processed_ms: 1711485739815,
          },
          {
            leverage: -0.072265625,
            order_type: "SHORT",
            order_uuid: "5b367606-ea36-4330-b57c-fce5e26916df",
            price: 69850.21,
            price_sources: [],
            processed_ms: 1711487559651,
          },
          {
            leverage: -0.0361328125,
            order_type: "SHORT",
            order_uuid: "c9483828-5f96-409f-8844-3d8814d764b1",
            price: 70074.94,
            price_sources: [],
            processed_ms: 1711489369545,
          },
          {
            leverage: -0.01806640625,
            order_type: "SHORT",
            order_uuid: "62e2c8b6-1f69-4bd7-b6cb-10b0a1046e99",
            price: 69924.42,
            price_sources: [],
            processed_ms: 1711491220635,
          },
          {
            leverage: -0.009033203125,
            order_type: "SHORT",
            order_uuid: "da1ea04e-b9c0-45d7-81f8-a8c0d1424309",
            price: 70068.39,
            price_sources: [],
            processed_ms: 1711493023450,
          },
          {
            leverage: -0.0045166015625,
            order_type: "SHORT",
            order_uuid: "b7acad74-0f26-4e1d-a262-594d00a0f8ba",
            price: 70284.47,
            price_sources: [],
            processed_ms: 1711494839316,
          },
          {
            leverage: -0.00225830078125,
            order_type: "SHORT",
            order_uuid: "1085a4be-c1ca-488d-b364-f6730614f6ae",
            price: 70031.97,
            price_sources: [],
            processed_ms: 1711496648555,
          },
          {
            leverage: -0.001129150390625,
            order_type: "SHORT",
            order_uuid: "d4036a51-7207-471b-a7d3-486426986cbb",
            price: 69935.45,
            price_sources: [],
            processed_ms: 1711498475346,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "d46a9021-149e-4f6a-9823-3594fc69ad60",
            price: 70386.51,
            price_sources: [],
            processed_ms: 1711500292734,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "5e911979-2713-4e50-abe1-b0b467607444",
            price: 70694.78,
            price_sources: [],
            processed_ms: 1711502111359,
          },
          {
            leverage: -0.2505645751953125,
            order_type: "SHORT",
            order_uuid: "1a0feb86-f633-4087-8259-51400d0c900e",
            price: 70507.01,
            price_sources: [],
            processed_ms: 1711503927749,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "22ffb1a0-9174-417b-a1ff-adb6c304e81c",
            price: 70232.04,
            price_sources: [],
            processed_ms: 1711505736824,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "6c48ef10-2af2-462b-ab55-d556b6c8f1c0",
            price: 70383.27,
            price_sources: [],
            processed_ms: 1711507557636,
          },
          {
            leverage: -0.37528228759765625,
            order_type: "SHORT",
            order_uuid: "5499d960-64f5-4a19-8386-809fb9771747",
            price: 70528.79,
            price_sources: [],
            processed_ms: 1711509373558,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "d196b22b-efbc-426d-b591-cd1566fe68ea",
            price: 70450.52,
            price_sources: [],
            processed_ms: 1711511188459,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "564484bd-731d-4b20-a9c5-4bc754cb79b7",
            price: 70266.95,
            price_sources: [],
            processed_ms: 1711513003475,
          },
          {
            leverage: -0.4376411437988281,
            order_type: "SHORT",
            order_uuid: "59ba7f5d-f5dd-4d49-90c4-7281a55445da",
            price: 70334.8,
            price_sources: [],
            processed_ms: 1711514816667,
          },
          {
            leverage: -0.3501129150390625,
            order_type: "SHORT",
            order_uuid: "6b0195ac-d44c-420a-adfb-af1007ce2205",
            price: 70402.94,
            price_sources: [],
            processed_ms: 1711516642576,
          },
          {
            leverage: -0.04376411437988281,
            order_type: "SHORT",
            order_uuid: "39762689-e56b-41db-9f5d-fe9228658896",
            price: 70419.91,
            price_sources: [],
            processed_ms: 1711518463520,
          },
          {
            leverage: -0.021882057189941406,
            order_type: "SHORT",
            order_uuid: "b71c345a-74f0-4498-8268-a0215c6faa18",
            price: 70337.61,
            price_sources: [],
            processed_ms: 1711520275659,
          },
          {
            leverage: -0.017505645751953125,
            order_type: "SHORT",
            order_uuid: "ade4b71c-5dbf-4e86-b792-1183fa2ac174",
            price: 70215.66,
            price_sources: [],
            processed_ms: 1711522087620,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "5547138d-acda-4ef2-8f93-87e2118bf5d3",
            price: 69853.58,
            price_sources: [],
            processed_ms: 1711525105547,
          },
        ],
        position_type: "FLAT",
        position_uuid: "dd4edb0a-e67b-4500-a603-bb0456bbeda5",
        return_at_close: 1.0069115132705588,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 59917.44,
        close_ms: 1711553701664,
        current_return: 0.9958392711944118,
        initial_entry_price: 70125.56,
        is_closed_position: true,
        miner_hotkey: "5CXkDb4mnFrneaoFfA2cQmPWpVc792fSvSMoQmuzrz78F9GY",
        net_leverage: 0.0,
        open_ms: 1711535617618,
        orders: [
          {
            leverage: -1.25,
            order_type: "SHORT",
            order_uuid: "a997f2f6-da38-4ec8-92e7-2b5ee8bb1629",
            price: 70125.56,
            price_sources: [],
            processed_ms: 1711535617613,
          },
          {
            leverage: 0.625,
            order_type: "LONG",
            order_uuid: "e4f42300-119d-4c80-9b1e-922771e9df63",
            price: 70066.39,
            price_sources: [],
            processed_ms: 1711539228468,
          },
          {
            leverage: 0.3125,
            order_type: "LONG",
            order_uuid: "b4e4c9be-8318-410c-a724-9e1426cf0b8f",
            price: 70223.19,
            price_sources: [],
            processed_ms: 1711542868516,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "591f5431-2fe7-49f8-bf98-9dc9d38d92bd",
            price: 71488.43,
            price_sources: [],
            processed_ms: 1711546463802,
          },
          {
            leverage: 0.03125,
            order_type: "LONG",
            order_uuid: "2f22559b-0c75-4bd6-8152-69ea7874d343",
            price: 69637.82,
            price_sources: [],
            processed_ms: 1711550083613,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "9da87334-2ed1-4765-bc45-a88dec1352eb",
            price: 69254.19,
            price_sources: [],
            processed_ms: 1711553701664,
          },
        ],
        position_type: "FLAT",
        position_uuid: "8e9f0330-048c-4748-a766-a4fea0d1258e",
        return_at_close: 0.9933496730164258,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 63489.699999999735,
        close_ms: 1711629645220,
        current_return: 0.9969153618830581,
        initial_entry_price: 70411.42,
        is_closed_position: true,
        miner_hotkey: "5CXkDb4mnFrneaoFfA2cQmPWpVc792fSvSMoQmuzrz78F9GY",
        net_leverage: 0.0,
        open_ms: 1711611554822,
        orders: [
          {
            leverage: -1.25,
            order_type: "SHORT",
            order_uuid: "107e97dc-4cce-4c02-bfea-eae5cb68ba5e",
            price: 70411.42,
            price_sources: [],
            processed_ms: 1711611554819,
          },
          {
            leverage: 0.625,
            order_type: "LONG",
            order_uuid: "ba2620bf-1ea7-4bca-bb02-3bba8e9ef3bf",
            price: 70640.74,
            price_sources: [],
            processed_ms: 1711615164443,
          },
          {
            leverage: 0.3125,
            order_type: "LONG",
            order_uuid: "3d77d9f8-0031-4941-8f17-be54bc76ad56",
            price: 70639.71,
            price_sources: [],
            processed_ms: 1711618785633,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "571a39a1-f373-496f-9d14-de5a75d1e9ae",
            price: 70384.43,
            price_sources: [],
            processed_ms: 1711622414370,
          },
          {
            leverage: 0.03125,
            order_type: "LONG",
            order_uuid: "83004856-7ef9-4d6f-bb3b-1d56b5b60a10",
            price: 70679.76,
            price_sources: [],
            processed_ms: 1711626019767,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "f462b266-859e-4201-8edf-63b1e12ca731",
            price: 70439.9,
            price_sources: [],
            processed_ms: 1711629645220,
          },
        ],
        position_type: "FLAT",
        position_uuid: "8a921be1-6e8d-440b-9bec-fa1d36507c48",
        return_at_close: 0.9944230734783505,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 68362.26000000007,
        close_ms: 1711647716756,
        current_return: 0.995873255000101,
        initial_entry_price: 70823.07,
        is_closed_position: true,
        miner_hotkey: "5CXkDb4mnFrneaoFfA2cQmPWpVc792fSvSMoQmuzrz78F9GY",
        net_leverage: 0.0,
        open_ms: 1711633253668,
        orders: [
          {
            leverage: -1.25,
            order_type: "SHORT",
            order_uuid: "469a7934-1b2a-45b3-afab-7c10e9f35993",
            price: 70823.07,
            price_sources: [],
            processed_ms: 1711633253665,
          },
          {
            leverage: 0.375,
            order_type: "LONG",
            order_uuid: "0a9679d3-4bb3-4cde-b684-edd3f45ec195",
            price: 71376.89,
            price_sources: [],
            processed_ms: 1711636869721,
          },
          {
            leverage: 0.375,
            order_type: "LONG",
            order_uuid: "c22f00d6-d5a4-4d7e-8f42-3566bfc207e2",
            price: 71071.6,
            price_sources: [],
            processed_ms: 1711640478910,
          },
          {
            leverage: 0.375,
            order_type: "LONG",
            order_uuid: "7f76a3a4-b302-4f0d-a9a2-81cc30974596",
            price: 70840.99,
            price_sources: [],
            processed_ms: 1711644096096,
          },
          {
            leverage: 0.375,
            order_type: "LONG",
            order_uuid: "4a573e81-2584-4759-ac46-524267dcc4b0",
            price: 70700.41,
            price_sources: [],
            processed_ms: 1711647716756,
          },
        ],
        position_type: "FLAT",
        position_uuid: "65f87334-58d0-4804-a567-30b12e53550a",
        return_at_close: 0.9933835718626008,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 70504.88999999996,
        close_ms: 1711665792800,
        current_return: 0.9994363909697112,
        initial_entry_price: 70789.32,
        is_closed_position: true,
        miner_hotkey: "5CXkDb4mnFrneaoFfA2cQmPWpVc792fSvSMoQmuzrz78F9GY",
        net_leverage: 0.0,
        open_ms: 1711651327426,
        orders: [
          {
            leverage: -1.25,
            order_type: "SHORT",
            order_uuid: "4a5e91be-ac7c-4ed8-95cd-957701c83cfd",
            price: 70789.32,
            price_sources: [],
            processed_ms: 1711651327423,
          },
          {
            leverage: 0.375,
            order_type: "LONG",
            order_uuid: "bbfe7220-a98f-4e77-9451-3da7d9ad0b40",
            price: 70855.48,
            price_sources: [],
            processed_ms: 1711654943880,
          },
          {
            leverage: 0.375,
            order_type: "LONG",
            order_uuid: "b224de3a-2a62-400b-8730-ce77d372f2fb",
            price: 70798.6,
            price_sources: [],
            processed_ms: 1711658564365,
          },
          {
            leverage: 0.375,
            order_type: "LONG",
            order_uuid: "90de094d-0dd8-4b77-a0f1-4aef1b811c23",
            price: 70808.69,
            price_sources: [],
            processed_ms: 1711662192800,
          },
          {
            leverage: 0.375,
            order_type: "LONG",
            order_uuid: "faccbbcd-ed0a-4736-9c68-99ba8b119342",
            price: 70824.07,
            price_sources: [],
            processed_ms: 1711665792800,
          },
        ],
        position_type: "FLAT",
        position_uuid: "62f53202-4f8a-49d5-ae15-2a63253e5700",
        return_at_close: 0.9969377999922869,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 82005.02135760657,
        close_ms: 1711788785286,
        current_return: 0.995780824890483,
        initial_entry_price: 70671.96,
        is_closed_position: true,
        miner_hotkey: "5CXkDb4mnFrneaoFfA2cQmPWpVc792fSvSMoQmuzrz78F9GY",
        net_leverage: 0.0,
        open_ms: 1711680259376,
        orders: [
          {
            leverage: 1.25,
            order_type: "LONG",
            order_uuid: "e7454bce-6c61-48a6-88d1-bce6bc49f22a",
            price: 70671.96,
            price_sources: [],
            processed_ms: 1711680259373,
          },
          {
            leverage: 0.375,
            order_type: "LONG",
            order_uuid: "335b2ab6-5017-41c1-89f2-4371f38483e4",
            price: 70433.57,
            price_sources: [],
            processed_ms: 1711683872634,
          },
          {
            leverage: -0.8125,
            order_type: "SHORT",
            order_uuid: "dbd01384-c99d-4155-95d6-b81839d52d14",
            price: 70500.42,
            price_sources: [],
            processed_ms: 1711687490872,
          },
          {
            leverage: 0.375,
            order_type: "LONG",
            order_uuid: "f111ad93-e746-4a0f-aaa3-518482c6b6e5",
            price: 70256.0,
            price_sources: [],
            processed_ms: 1711691124096,
          },
          {
            leverage: 0.375,
            order_type: "LONG",
            order_uuid: "6cbce201-af1b-424b-9043-5a6a8edc5bde",
            price: 70458.17,
            price_sources: [],
            processed_ms: 1711694719246,
          },
          {
            leverage: 0.375,
            order_type: "LONG",
            order_uuid: "541005d1-ee88-470c-bd26-27162f392dc2",
            price: 70027.67,
            price_sources: [],
            processed_ms: 1711698335653,
          },
          {
            leverage: 0.375,
            order_type: "LONG",
            order_uuid: "a18fe467-63a0-4307-be22-01ad01d92a58",
            price: 69964.92,
            price_sources: [],
            processed_ms: 1711701983826,
          },
          {
            leverage: 0.375,
            order_type: "LONG",
            order_uuid: "921bfb51-6874-45c2-845e-62a9647cee95",
            price: 69660.34,
            price_sources: [],
            processed_ms: 1711705568770,
          },
          {
            leverage: 0.375,
            order_type: "LONG",
            order_uuid: "3a7a2965-69d5-467a-8cb9-4e05f1ac9682",
            price: 69882.92,
            price_sources: [],
            processed_ms: 1711709189707,
          },
          {
            leverage: -1.53125,
            order_type: "SHORT",
            order_uuid: "71056bef-479a-4538-a815-08d05db38bff",
            price: 70166.36,
            price_sources: [],
            processed_ms: 1711712801829,
          },
          {
            leverage: 0.375,
            order_type: "LONG",
            order_uuid: "98b9b333-7c56-4de8-be8c-42a8218d538d",
            price: 70050.76,
            price_sources: [],
            processed_ms: 1711716414922,
          },
          {
            leverage: 0.375,
            order_type: "LONG",
            order_uuid: "219bd0cb-f18e-48a6-9976-4df08b232b5a",
            price: 70482.45,
            price_sources: [],
            processed_ms: 1711720038113,
          },
          {
            leverage: -1.140625,
            order_type: "SHORT",
            order_uuid: "bb0f138e-4e47-4fd4-8774-41148f6ac50d",
            price: 70402.42,
            price_sources: [],
            processed_ms: 1711723650915,
          },
          {
            leverage: 0.375,
            order_type: "LONG",
            order_uuid: "c762501c-1ee8-41af-80af-65ec30c9fd8c",
            price: 69315.15,
            price_sources: [],
            processed_ms: 1711727265867,
          },
          {
            leverage: 0.375,
            order_type: "LONG",
            order_uuid: "122c2654-9f57-4f3b-b1b7-e6e26f27b781",
            price: 69359.01,
            price_sources: [],
            processed_ms: 1711730884670,
          },
          {
            leverage: 0.375,
            order_type: "LONG",
            order_uuid: "6f6faaea-b335-40c9-8587-2072344d88f3",
            price: 69416.1,
            price_sources: [],
            processed_ms: 1711734497910,
          },
          {
            leverage: 0.375,
            order_type: "LONG",
            order_uuid: "ce0e833d-46b9-42dc-9ba2-4ee6658d0d27",
            price: 69466.49,
            price_sources: [],
            processed_ms: 1711738177812,
          },
          {
            leverage: -1.3203125,
            order_type: "SHORT",
            order_uuid: "7016acdf-2ac2-4dca-b1c2-cae2788cf265",
            price: 69591.3,
            price_sources: [],
            processed_ms: 1711741747401,
          },
          {
            leverage: -0.66015625,
            order_type: "SHORT",
            order_uuid: "a105671a-cd2a-40e9-8818-41087b265f1c",
            price: 69612.34,
            price_sources: [],
            processed_ms: 1711745360389,
          },
          {
            leverage: -0.330078125,
            order_type: "SHORT",
            order_uuid: "457c740d-3683-4ba7-8400-d49e1a2fa4b2",
            price: 69497.27,
            price_sources: [],
            processed_ms: 1711748976437,
          },
          {
            leverage: -0.1650390625,
            order_type: "SHORT",
            order_uuid: "0859823d-ba5c-435a-9d9b-07099c0ddf40",
            price: 69814.57,
            price_sources: [],
            processed_ms: 1711752588661,
          },
          {
            leverage: -0.08251953125,
            order_type: "SHORT",
            order_uuid: "59d8fdb5-966d-45b9-9822-a22533eb829a",
            price: 69890.08,
            price_sources: [],
            processed_ms: 1711756202802,
          },
          {
            leverage: -0.041259765625,
            order_type: "SHORT",
            order_uuid: "46e89ee1-b5b8-478d-a9de-26a984c3250e",
            price: 69918.84,
            price_sources: [],
            processed_ms: 1711759827802,
          },
          {
            leverage: 0.375,
            order_type: "LONG",
            order_uuid: "fe5a3b7b-e1d3-403d-b099-64c63476c5a9",
            price: 70053.13,
            price_sources: [],
            processed_ms: 1711763436688,
          },
          {
            leverage: 0.375,
            order_type: "LONG",
            order_uuid: "83eb07af-cb11-4fb1-9f30-0c9ff089d417",
            price: 69950.01,
            price_sources: [],
            processed_ms: 1711767050803,
          },
          {
            leverage: -0.3956298828125,
            order_type: "SHORT",
            order_uuid: "b31260a5-39b5-48f2-b7a5-3d2e269ae25f",
            price: 69872.96,
            price_sources: [],
            processed_ms: 1711770666557,
          },
          {
            leverage: -0.19781494140625,
            order_type: "SHORT",
            order_uuid: "eebfbf80-361b-430b-ad56-d418ab7daa7a",
            price: 70026.69,
            price_sources: [],
            processed_ms: 1711774373228,
          },
          {
            leverage: -0.098907470703125,
            order_type: "SHORT",
            order_uuid: "c186e5e1-6af7-4d63-bbbb-4ca8c7cac1d0",
            price: 70120.0,
            price_sources: [],
            processed_ms: 1711777914598,
          },
          {
            leverage: -0.0494537353515625,
            order_type: "SHORT",
            order_uuid: "e81158cc-45bd-4895-a2e9-29074fdc6a97",
            price: 70046.74,
            price_sources: [],
            processed_ms: 1711781539436,
          },
          {
            leverage: -0.02472686767578125,
            order_type: "SHORT",
            order_uuid: "2deaf0d6-b959-4b8f-a89f-bf989e36328d",
            price: 69972.22,
            price_sources: [],
            processed_ms: 1711785161637,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "80dfef7b-d17f-4f98-9ce9-7a1bb37a5ba0",
            price: 69946.18,
            price_sources: [],
            processed_ms: 1711788785286,
          },
        ],
        position_type: "FLAT",
        position_uuid: "0b048bb1-32d3-4cfc-83af-4b6d58f4f386",
        return_at_close: 0.9896816673380288,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 68074.15499999975,
        close_ms: 1711810535925,
        current_return: 0.9985549157900849,
        initial_entry_price: 70074.29,
        is_closed_position: true,
        miner_hotkey: "5CXkDb4mnFrneaoFfA2cQmPWpVc792fSvSMoQmuzrz78F9GY",
        net_leverage: 0.0,
        open_ms: 1711792400738,
        orders: [
          {
            leverage: -1.25,
            order_type: "SHORT",
            order_uuid: "a5bf6dd7-a7b4-4a8f-8609-a9db904b0f04",
            price: 70074.29,
            price_sources: [],
            processed_ms: 1711792400734,
          },
          {
            leverage: 0.375,
            order_type: "LONG",
            order_uuid: "d95e0da5-9a7b-4768-8532-03adb1034bc7",
            price: 70070.05,
            price_sources: [],
            processed_ms: 1711796042314,
          },
          {
            leverage: 0.375,
            order_type: "LONG",
            order_uuid: "1b151a94-4d19-4640-aa67-b4d611458d64",
            price: 70194.44,
            price_sources: [],
            processed_ms: 1711799650352,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "3fc5b44d-d31b-43da-80b2-d4793b133ce7",
            price: 70204.9,
            price_sources: [],
            processed_ms: 1711803249399,
          },
          {
            leverage: 0.2,
            order_type: "LONG",
            order_uuid: "ecfac9af-12b7-4080-ab19-00113f0f13e2",
            price: 70193.73,
            price_sources: [],
            processed_ms: 1711806873403,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "6e663098-1439-43cc-85c8-575d9c69d6a3",
            price: 70099.42,
            price_sources: [],
            processed_ms: 1711810535925,
          },
        ],
        position_type: "FLAT",
        position_uuid: "156b659a-cf78-46c9-a151-2a1945c9164c",
        return_at_close: 0.9960585285006097,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 68707.17000000004,
        close_ms: 1711893771527,
        current_return: 0.9967939998593516,
        initial_entry_price: 70246.02,
        is_closed_position: true,
        miner_hotkey: "5CXkDb4mnFrneaoFfA2cQmPWpVc792fSvSMoQmuzrz78F9GY",
        net_leverage: 0.0,
        open_ms: 1711879278575,
        orders: [
          {
            leverage: -1.25,
            order_type: "SHORT",
            order_uuid: "7eeb58cc-978c-450f-89ee-c2077a692f3e",
            price: 70246.02,
            price_sources: [],
            processed_ms: 1711879278572,
          },
          {
            leverage: 0.375,
            order_type: "LONG",
            order_uuid: "91fabc64-5b63-4733-8d20-c1a2583c9fb3",
            price: 70296.8,
            price_sources: [],
            processed_ms: 1711882889374,
          },
          {
            leverage: 0.375,
            order_type: "LONG",
            order_uuid: "af7b3279-47c5-45f2-81c4-9023717b58ad",
            price: 70416.64,
            price_sources: [],
            processed_ms: 1711886518592,
          },
          {
            leverage: 0.375,
            order_type: "LONG",
            order_uuid: "85bebd68-1f12-476b-bd6e-b02f1abd5d67",
            price: 70537.57,
            price_sources: [],
            processed_ms: 1711890141539,
          },
          {
            leverage: 0.375,
            order_type: "LONG",
            order_uuid: "f36ce4bc-33b6-4e39-8b6b-f1458b3d03fe",
            price: 70508.84,
            price_sources: [],
            processed_ms: 1711893771527,
          },
        ],
        position_type: "FLAT",
        position_uuid: "fa2aad9a-4180-4fcf-bbca-417cba540ea1",
        return_at_close: 0.9943020148597033,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 70406.38000000016,
        close_ms: 1711915471445,
        current_return: 0.9993665828966461,
        initial_entry_price: 70498.57,
        is_closed_position: true,
        miner_hotkey: "5CXkDb4mnFrneaoFfA2cQmPWpVc792fSvSMoQmuzrz78F9GY",
        net_leverage: 0.0,
        open_ms: 1711897372796,
        orders: [
          {
            leverage: -1.25,
            order_type: "SHORT",
            order_uuid: "da0888e4-c509-4ead-ac4f-3e1b047722e6",
            price: 70498.57,
            price_sources: [],
            processed_ms: 1711897372790,
          },
          {
            leverage: 0.375,
            order_type: "LONG",
            order_uuid: "99ccbe0e-9e87-45c3-86b5-16acb4cb04d7",
            price: 70507.84,
            price_sources: [],
            processed_ms: 1711901024355,
          },
          {
            leverage: 0.375,
            order_type: "LONG",
            order_uuid: "ce4cb6e0-acb2-451a-bece-ba1f5025d322",
            price: 70469.41,
            price_sources: [],
            processed_ms: 1711904619830,
          },
          {
            leverage: 0.375,
            order_type: "LONG",
            order_uuid: "da7a1a44-ccb7-4d5b-98f0-1b8727977f32",
            price: 70492.08,
            price_sources: [],
            processed_ms: 1711908240410,
          },
          {
            leverage: 0.0625,
            order_type: "LONG",
            order_uuid: "c511882b-06fd-4848-a2c6-09a379078f9b",
            price: 70749.04,
            price_sources: [],
            processed_ms: 1711911873296,
          },
          {
            leverage: 0.375,
            order_type: "LONG",
            order_uuid: "23a23c92-8ee7-44fa-b26a-bb63ece53eb8",
            price: 71120.86,
            price_sources: [],
            processed_ms: 1711915471445,
          },
        ],
        position_type: "FLAT",
        position_uuid: "3f2f36e6-d75b-4816-89f5-df2fb7a8263f",
        return_at_close: 0.9968681664394046,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 68556.65999999997,
        close_ms: 1711933565978,
        current_return: 0.9954362935360579,
        initial_entry_price: 70823.19,
        is_closed_position: true,
        miner_hotkey: "5CXkDb4mnFrneaoFfA2cQmPWpVc792fSvSMoQmuzrz78F9GY",
        net_leverage: 0.0,
        open_ms: 1711919091958,
        orders: [
          {
            leverage: -1.25,
            order_type: "SHORT",
            order_uuid: "696482ad-5443-4dfd-989d-3b785b9ef8d6",
            price: 70823.19,
            price_sources: [],
            processed_ms: 1711919091954,
          },
          {
            leverage: 0.375,
            order_type: "LONG",
            order_uuid: "59085aba-75e5-451c-a82f-acc1d60eaa4d",
            price: 71015.05,
            price_sources: [],
            processed_ms: 1711922703195,
          },
          {
            leverage: 0.375,
            order_type: "LONG",
            order_uuid: "ee5e2146-5d8c-4ff0-b416-b78792a2711c",
            price: 70969.98,
            price_sources: [],
            processed_ms: 1711926323869,
          },
          {
            leverage: 0.375,
            order_type: "LONG",
            order_uuid: "df613bc7-e0e1-48d0-9937-78350004a41f",
            price: 71240.05,
            price_sources: [],
            processed_ms: 1711929941178,
          },
          {
            leverage: 0.375,
            order_type: "LONG",
            order_uuid: "54017f48-23f5-4d29-beb5-bd859dcfe745",
            price: 71142.39,
            price_sources: [],
            processed_ms: 1711933565978,
          },
        ],
        position_type: "FLAT",
        position_uuid: "66097df0-f2c4-4b64-a42e-96d32f1b9568",
        return_at_close: 0.9929477028022178,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 82852.61999999988,
        close_ms: 1711958886956,
        current_return: 1.005899698354178,
        initial_entry_price: 70861.25,
        is_closed_position: true,
        miner_hotkey: "5CXkDb4mnFrneaoFfA2cQmPWpVc792fSvSMoQmuzrz78F9GY",
        net_leverage: 0.0,
        open_ms: 1711937237570,
        orders: [
          {
            leverage: -1.25,
            order_type: "SHORT",
            order_uuid: "2954dee3-84d2-4481-b26b-14569d6891b3",
            price: 70861.25,
            price_sources: [],
            processed_ms: 1711937236700,
          },
          {
            leverage: 0.375,
            order_type: "LONG",
            order_uuid: "fbe372e4-7ddd-44db-a539-2addcd1c636b",
            price: 70851.58,
            price_sources: [],
            processed_ms: 1711940799347,
          },
          {
            leverage: 0.375,
            order_type: "LONG",
            order_uuid: "e7471744-2cf7-4a69-87d8-81fd86945597",
            price: 70642.34,
            price_sources: [],
            processed_ms: 1711944415634,
          },
          {
            leverage: 0.375,
            order_type: "LONG",
            order_uuid: "2aad0088-2363-429a-a9af-ef8ede345921",
            price: 70452.82,
            price_sources: [],
            processed_ms: 1711948030488,
          },
          {
            leverage: 0.0625,
            order_type: "LONG",
            order_uuid: "71c86b8e-8bb2-4229-b5fc-66816c102ba5",
            price: 69240.0,
            price_sources: [],
            processed_ms: 1711951692824,
          },
          {
            leverage: 0.03125,
            order_type: "LONG",
            order_uuid: "84daa6f0-86cc-47eb-88e2-96c92b6c1e46",
            price: 69756.5,
            price_sources: [],
            processed_ms: 1711955271822,
          },
          {
            leverage: 0.375,
            order_type: "LONG",
            order_uuid: "e206f6a0-ed74-4ea9-a11e-63f73081fe9a",
            price: 69474.7,
            price_sources: [],
            processed_ms: 1711958886956,
          },
        ],
        position_type: "FLAT",
        position_uuid: "d3284781-9aea-49b1-abd0-c65fce5bdbcf",
        return_at_close: 1.0033849491082925,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 65595.81428571453,
        close_ms: 1711987935922,
        current_return: 0.997758819639387,
        initial_entry_price: 69465.0,
        is_closed_position: true,
        miner_hotkey: "5CXkDb4mnFrneaoFfA2cQmPWpVc792fSvSMoQmuzrz78F9GY",
        net_leverage: 0.0,
        open_ms: 1711966134393,
        orders: [
          {
            leverage: -1.25,
            order_type: "SHORT",
            order_uuid: "8f5e963c-6fcb-45eb-8608-66e6812f0bae",
            price: 69465.0,
            price_sources: [],
            processed_ms: 1711966134390,
          },
          {
            leverage: 0.375,
            order_type: "LONG",
            order_uuid: "f553802a-1af5-4c5f-b8d2-e0b0c9a3d445",
            price: 69691.9,
            price_sources: [],
            processed_ms: 1711969760568,
          },
          {
            leverage: 0.4375,
            order_type: "LONG",
            order_uuid: "24728af5-d7df-4ac7-9aef-c6ff0c146219",
            price: 69586.9,
            price_sources: [],
            processed_ms: 1711973384899,
          },
          {
            leverage: 0.21875,
            order_type: "LONG",
            order_uuid: "4a039159-61f0-4483-881a-571bf4b996bd",
            price: 69893.2,
            price_sources: [],
            processed_ms: 1711977067690,
          },
          {
            leverage: 0.109375,
            order_type: "LONG",
            order_uuid: "7d0220f8-d74d-4809-9a75-6a2867bff5ef",
            price: 69756.2,
            price_sources: [],
            processed_ms: 1711980621223,
          },
          {
            leverage: 0.0546875,
            order_type: "LONG",
            order_uuid: "24134ac8-4f95-4a4b-923c-c543dd2f9d35",
            price: 68507.9,
            price_sources: [],
            processed_ms: 1711984228343,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "519e9789-b629-414e-b07f-a45995551abf",
            price: 68442.6,
            price_sources: [],
            processed_ms: 1711987935922,
          },
        ],
        position_type: "FLAT",
        position_uuid: "543a7332-0629-4513-86ff-267bac1a4b5f",
        return_at_close: 0.9952644225902886,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 71258.51323529406,
        close_ms: 1712040085297,
        current_return: 0.9475009383096124,
        initial_entry_price: 68474.2,
        is_closed_position: true,
        miner_hotkey: "5CXkDb4mnFrneaoFfA2cQmPWpVc792fSvSMoQmuzrz78F9GY",
        net_leverage: 0.85,
        open_ms: 1711991466514,
        orders: [
          {
            leverage: 1.25,
            order_type: "LONG",
            order_uuid: "db98a4e0-c656-4369-9f42-fc4a86fbe2af",
            price: 68474.2,
            price_sources: [],
            processed_ms: 1711991466510,
          },
          {
            leverage: 0.375,
            order_type: "LONG",
            order_uuid: "0bc24258-9e90-4305-a3a0-4aab0df68a1a",
            price: 68520.4,
            price_sources: [],
            processed_ms: 1711995116592,
          },
          {
            leverage: 0.375,
            order_type: "LONG",
            order_uuid: "10242ac2-35e4-4d0b-a380-ad89e08730ed",
            price: 69089.5,
            price_sources: [],
            processed_ms: 1711998709466,
          },
          {
            leverage: 0.375,
            order_type: "LONG",
            order_uuid: "8e7eb773-f88c-4190-976a-50eee4fd53ec",
            price: 69545.9,
            price_sources: [],
            processed_ms: 1712002333447,
          },
          {
            leverage: 0.375,
            order_type: "LONG",
            order_uuid: "bef3acc9-5732-451b-8052-dfb090e43b25",
            price: 69775.0,
            price_sources: [],
            processed_ms: 1712005970263,
          },
          {
            leverage: 0.375,
            order_type: "LONG",
            order_uuid: "4210dab5-089e-40f3-9054-5aa060a3f041",
            price: 69756.1,
            price_sources: [],
            processed_ms: 1712009564246,
          },
          {
            leverage: 0.375,
            order_type: "LONG",
            order_uuid: "fa8ab4ec-f331-4fcc-a47a-b3d8468473dd",
            price: 69808.3,
            price_sources: [],
            processed_ms: 1712013185547,
          },
          {
            leverage: 0.375,
            order_type: "LONG",
            order_uuid: "cde2baf8-07fa-42dd-94d5-a1bcf85736fc",
            price: 69604.6,
            price_sources: [],
            processed_ms: 1712016809237,
          },
          {
            leverage: -1.9375,
            order_type: "SHORT",
            order_uuid: "8e9ca3d6-ce3d-4d7c-92d2-cdba444b93ad",
            price: 69577.8,
            price_sources: [],
            processed_ms: 1712020425663,
          },
          {
            leverage: 0.375,
            order_type: "LONG",
            order_uuid: "be5fec96-b03e-4896-8104-cc478de81cf0",
            price: 66580.2,
            price_sources: [],
            processed_ms: 1712031341819,
          },
          {
            leverage: -1.3,
            order_type: "SHORT",
            order_uuid: "8303dd48-5168-45d6-aaca-f1ff47998e35",
            price: 66602.1,
            price_sources: [],
            processed_ms: 1712034947269,
          },
          {
            leverage: -0.16249999999999998,
            order_type: "SHORT",
            order_uuid: "37efd07e-63f1-4bf8-b4d5-4cf35b9c781b",
            price: 67004.9,
            price_sources: [],
            processed_ms: 1712038505831,
          },
        ],
        position_type: "FLAT",
        position_uuid: "820f4db3-7a7f-43e0-ab19-5f12dc0610b5",
        return_at_close: 0.9401578060377128,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
    ],
    thirty_day_returns: 1.0,
  },
  "5CXsrszdjWooHK3tfQH4Zk6spkkSsduFrEHzMemxU7P2wh7H": {
    all_time_returns: 1.0229178043264784,
    n_positions: 5,
    percentage_profitable: 0.2,
    positions: [
      {
        average_entry_price: 3502.29,
        close_ms: 1719177297688,
        current_return: 0.9945557049815977,
        initial_entry_price: 3502.29,
        is_closed_position: true,
        miner_hotkey: "5CXsrszdjWooHK3tfQH4Zk6spkkSsduFrEHzMemxU7P2wh7H",
        net_leverage: 0.0,
        open_ms: 1719039156798,
        orders: [
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "37545ecd-c0b6-4cf2-a873-bf7bf1fb7ffa",
            price: 3502.29,
            price_sources: [],
            processed_ms: 1719039156783,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "043009e1-c50d-4dd6-8289-521d54fc0b4d",
            price: 3426.02,
            price_sources: [],
            processed_ms: 1719177297688,
          },
        ],
        position_type: "FLAT",
        position_uuid: "a46c8487-9067-4098-8015-dffe9cd4529e",
        return_at_close: 0.9943070660553524,
        trade_pair: ["ETHUSD", "ETH/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 169.450875,
        close_ms: 1719224794580,
        current_return: 0.9826477618106171,
        initial_entry_price: 169.445,
        is_closed_position: true,
        miner_hotkey: "5CXsrszdjWooHK3tfQH4Zk6spkkSsduFrEHzMemxU7P2wh7H",
        net_leverage: 0.0,
        open_ms: 1718955434192,
        orders: [
          {
            leverage: -1.0,
            order_type: "SHORT",
            order_uuid: "dbd07feb-58a4-411e-85dc-be47e9b81e85",
            price: 169.445,
            price_sources: [],
            processed_ms: 1718955434178,
          },
          {
            leverage: -1.0,
            order_type: "SHORT",
            order_uuid: "d8c00272-4fe7-4ba1-a711-ca86df1be936",
            price: 169.5985,
            price_sources: [],
            processed_ms: 1718955982812,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "7c81d227-f78f-4de2-93ae-46258d0882b0",
            price: 170.4455,
            price_sources: [],
            processed_ms: 1718981374336,
          },
          {
            leverage: -1.5,
            order_type: "SHORT",
            order_uuid: "79811319-f088-4cf3-a53b-5c0fd56f5208",
            price: 170.351,
            price_sources: [],
            processed_ms: 1718981546759,
          },
          {
            leverage: 0.01,
            order_type: "FLAT",
            order_uuid: "9db024fa-5b91-4a48-b7e6-ef2650da7a3b",
            price: 170.921,
            price_sources: [],
            processed_ms: 1719224794580,
          },
        ],
        position_type: "FLAT",
        position_uuid: "40091992-8e7e-4e09-ab45-80a1a6ad607e",
        return_at_close: 0.9824070131089734,
        trade_pair: ["EURJPY", "EUR/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 62309.44,
        close_ms: 1719957898046,
        current_return: 0.9937306449873406,
        initial_entry_price: 62309.44,
        is_closed_position: true,
        miner_hotkey: "5CXsrszdjWooHK3tfQH4Zk6spkkSsduFrEHzMemxU7P2wh7H",
        net_leverage: 0.0,
        open_ms: 1719209997527,
        orders: [
          {
            leverage: 1.0,
            order_type: "LONG",
            order_uuid: "848ad3ec-f4e3-4f7f-abf0-d5b383b77f73",
            price: 62309.44,
            price_sources: [],
            processed_ms: 1719209996577,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "8025b388-ab7b-4f68-8638-04c017e5ea83",
            price: 61918.8,
            price_sources: [],
            processed_ms: 1719957898046,
          },
        ],
        position_type: "FLAT",
        position_uuid: "6f56b859-3beb-484c-a968-6e697ac3454a",
        return_at_close: 0.9927369143423533,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 159.33985714285714,
        close_ms: 1719957928312,
        current_return: 0.9539601623713772,
        initial_entry_price: 158.895,
        is_closed_position: true,
        miner_hotkey: "5CXsrszdjWooHK3tfQH4Zk6spkkSsduFrEHzMemxU7P2wh7H",
        net_leverage: 0.0,
        open_ms: 1718912305003,
        orders: [
          {
            leverage: -2.0,
            order_type: "SHORT",
            order_uuid: "18d4edb0-0e26-40c9-b6d2-2ccb00f0984d",
            price: 158.895,
            price_sources: [],
            processed_ms: 1718912304544,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "8c4a0b8e-4e27-444e-94af-0c64b7f10165",
            price: 158.888,
            price_sources: [],
            processed_ms: 1718964098216,
          },
          {
            leverage: -1.5,
            order_type: "SHORT",
            order_uuid: "4a5acb8a-7a89-4b44-a39c-6cff9ec0dca1",
            price: 159.252,
            price_sources: [],
            processed_ms: 1718979183220,
          },
          {
            leverage: -1.5,
            order_type: "SHORT",
            order_uuid: "0933a050-59fd-4891-9e31-1ef4e7ee0ca6",
            price: 159.569,
            price_sources: [],
            processed_ms: 1718981988791,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "402a9ac0-7f34-4065-87fe-cffd34a369a2",
            price: 161.43,
            price_sources: [],
            processed_ms: 1719957928312,
          },
        ],
        position_type: "FLAT",
        position_uuid: "e4fd4155-790d-43a2-b6a0-a41aa21f3bd1",
        return_at_close: 0.9536262763145472,
        trade_pair: ["USDJPY", "USD/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 61931.81,
        close_ms: 1720078003678,
        current_return: 1.1083727409226374,
        initial_entry_price: 61931.81,
        is_closed_position: true,
        miner_hotkey: "5CXsrszdjWooHK3tfQH4Zk6spkkSsduFrEHzMemxU7P2wh7H",
        net_leverage: 0.0,
        open_ms: 1719957967069,
        orders: [
          {
            leverage: -2.0,
            order_type: "SHORT",
            order_uuid: "2808c140-125d-490b-bead-7ff07105ef69",
            price: 61931.81,
            price_sources: [],
            processed_ms: 1719957966492,
          },
          {
            leverage: 0.01,
            order_type: "FLAT",
            order_uuid: "67bab995-ab21-4281-bbcc-908355a4d836",
            price: 58575.95,
            price_sources: [
              {
                close: 58575.95,
                high: 58579.9,
                lag_ms: 321,
                low: 58575.95,
                open: 58575.95,
                source: "Polygon_ws",
                start_ms: 1720078003999,
                timespan_ms: 0,
                volume: 0.00168159,
                vwap: 58578.3743,
                websocket: true,
              },
              {
                close: 58582.93,
                high: 58590.27,
                lag_ms: 2679,
                low: 58582.93,
                open: 58589.94,
                source: "Polygon_rest",
                start_ms: 1720078000000,
                timespan_ms: 1000,
                volume: 0.96318458,
                vwap: 58587.5588,
                websocket: false,
              },
              {
                close: 58575.9,
                high: 58575.9,
                lag_ms: 5322,
                low: 58575.9,
                open: 58575.9,
                source: "TwelveData_ws",
                start_ms: 1720078009000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
              {
                close: 58565.48,
                high: 58630.49,
                lag_ms: 43679,
                low: 58565.47,
                open: 58587.3,
                source: "TwelveData_rest",
                start_ms: 1720077900000,
                timespan_ms: 60000,
                volume: null,
                vwap: null,
                websocket: false,
              },
            ],
            processed_ms: 1720078003678,
          },
        ],
        position_type: "FLAT",
        position_uuid: "08919677-e547-4645-a29e-2cc603dd9c25",
        return_at_close: 1.106155995440792,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 164.30200000000002,
        close_ms: 0,
        current_return: 1.0016113444597257,
        initial_entry_price: 161.418,
        is_closed_position: false,
        miner_hotkey: "5CXsrszdjWooHK3tfQH4Zk6spkkSsduFrEHzMemxU7P2wh7H",
        net_leverage: -0.09999999999999998,
        open_ms: 1720078066136,
        orders: [
          {
            leverage: -0.5,
            order_type: "SHORT",
            order_uuid: "31b01ebe-24cb-4637-81b3-640a3784d508",
            price: 161.418,
            price_sources: [
              {
                close: 161.418,
                high: 161.418,
                lag_ms: 128,
                low: 161.418,
                open: 161.418,
                source: "Polygon_ws",
                start_ms: 1720078066000,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 161.42,
                websocket: true,
              },
              {
                close: 161.42,
                high: 161.42,
                lag_ms: 128,
                low: 161.42,
                open: 161.42,
                source: "TwelveData_ws",
                start_ms: 1720078066000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1720078066128,
          },
          {
            leverage: 0.4,
            order_type: "LONG",
            order_uuid: "3ee52450-f82c-448c-a873-5a550a1317bb",
            price: 160.697,
            price_sources: [
              {
                close: 160.697,
                high: 160.697,
                lag_ms: 406,
                low: 160.697,
                open: 160.697,
                source: "Polygon_ws",
                start_ms: 1720418418999,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 160.697,
                websocket: true,
              },
              {
                close: 160.689,
                high: 160.689,
                lag_ms: 407,
                low: 160.689,
                open: 160.689,
                source: "TwelveData_ws",
                start_ms: 1720418419000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1720418418593,
          },
        ],
        position_type: "SHORT",
        position_uuid: "e4d89373-e0f0-459b-8cb1-39675769e9be",
        return_at_close: 1.0015797937023752,
        trade_pair: ["USDJPY", "USD/JPY", 7e-5, 0.001, 500],
      },
    ],
    thirty_day_returns: 1.0229178043264784,
  },
  "5CZu4jZVbLkQRJwNCE4rMHNhDAK8XwQGvjEnWu5CYhKutbWj": {
    all_time_returns: 1.0,
    n_positions: 0,
    percentage_profitable: 0.0,
    positions: [
      {
        average_entry_price: 3364.07,
        close_ms: 1711670413924,
        current_return: 1.0581498006878571,
        initial_entry_price: 3364.07,
        is_closed_position: true,
        miner_hotkey: "5CZu4jZVbLkQRJwNCE4rMHNhDAK8XwQGvjEnWu5CYhKutbWj",
        net_leverage: 1.0,
        open_ms: 1711191119507,
        orders: [
          {
            leverage: 1.0,
            order_type: "LONG",
            order_uuid: "fb680b16-83b9-46cf-9ccd-07b2bb02dd0b",
            price: 3364.07,
            price_sources: [],
            processed_ms: 1711191119506,
          },
        ],
        position_type: "FLAT",
        position_uuid: "9d9892cb-559d-4378-b21a-59e112fa847b",
        return_at_close: 1.0560335010864814,
        trade_pair: ["ETHUSD", "ETH/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 64384.98,
        close_ms: 1711670413924,
        current_return: 0.9003463230088758,
        initial_entry_price: 64384.98,
        is_closed_position: true,
        miner_hotkey: "5CZu4jZVbLkQRJwNCE4rMHNhDAK8XwQGvjEnWu5CYhKutbWj",
        net_leverage: -1.0,
        open_ms: 1711191217384,
        orders: [
          {
            leverage: -1.0,
            order_type: "SHORT",
            order_uuid: "22908869-1eb4-4265-a88a-de49913a3827",
            price: 64384.98,
            price_sources: [],
            processed_ms: 1711191217384,
          },
        ],
        position_type: "FLAT",
        position_uuid: "12694e8b-07e5-440e-b0fc-e8d3bba5ad69",
        return_at_close: 0.8985456303628581,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
    ],
    thirty_day_returns: 1.0,
  },
  "5CaXrBFAaHZdMGKpD8mHCPxjZvX2uq9gRQjFEc2gWaqcwEfJ": {
    all_time_returns: 1.0,
    n_positions: 0,
    percentage_profitable: 0.0,
    positions: [
      {
        average_entry_price: 64330.73166666666,
        close_ms: 1711186662073,
        current_return: 0.9968287043653451,
        initial_entry_price: 64374.32,
        is_closed_position: true,
        miner_hotkey: "5CaXrBFAaHZdMGKpD8mHCPxjZvX2uq9gRQjFEc2gWaqcwEfJ",
        net_leverage: 0.0,
        open_ms: 1711184175653,
        orders: [
          {
            leverage: 5.0,
            order_type: "LONG",
            order_uuid: "98d8bad2-3f57-45c1-b385-11cd99ea3237",
            price: 64374.32,
            price_sources: [],
            processed_ms: 1711184175653,
          },
          {
            leverage: 5.0,
            order_type: "LONG",
            order_uuid: "901c59b0-e53d-46b6-b81d-f1d15275819a",
            price: 64393.82,
            price_sources: [],
            processed_ms: 1711184691624,
          },
          {
            leverage: 5.0,
            order_type: "LONG",
            order_uuid: "6026696d-3b42-4cde-b1fd-fa0998a6fa79",
            price: 64326.02,
            price_sources: [],
            processed_ms: 1711185195577,
          },
          {
            leverage: 5.0,
            order_type: "LONG",
            order_uuid: "88674d5e-2bff-4265-a169-2e64327e0d4a",
            price: 64239.52,
            price_sources: [],
            processed_ms: 1711185504332,
          },
          {
            leverage: -1.0,
            order_type: "SHORT",
            order_uuid: "13792e7f-31d4-45e8-9dde-484abc824633",
            price: 64344.25,
            price_sources: [],
            processed_ms: 1711186465442,
          },
          {
            leverage: -1.0,
            order_type: "SHORT",
            order_uuid: "9177a005-28ac-4d87-9f0f-79cd99aae798",
            price: 64370.98,
            price_sources: [],
            processed_ms: 1711186518227,
          },
          {
            leverage: 2.0,
            order_type: "FLAT",
            order_uuid: "8661d0e2-96c5-4438-b0c2-75d50342e0d6",
            price: 64319.39,
            price_sources: [],
            processed_ms: 1711186662073,
          },
        ],
        position_type: "FLAT",
        position_uuid: "8af08709-0e70-4130-8c49-1ec3d73a3315",
        return_at_close: 0.9569555561907312,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 3341.912,
        close_ms: 1711188128058,
        current_return: 1.0052458201441652,
        initial_entry_price: 3339.23,
        is_closed_position: true,
        miner_hotkey: "5CaXrBFAaHZdMGKpD8mHCPxjZvX2uq9gRQjFEc2gWaqcwEfJ",
        net_leverage: 0.0,
        open_ms: 1711186893223,
        orders: [
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "41c34c28-a4cf-408e-8b15-0b59cc974f23",
            price: 3339.23,
            price_sources: [],
            processed_ms: 1711186893222,
          },
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "a89ab239-3937-47ae-b8a5-5b18810a8a92",
            price: 3338.78,
            price_sources: [],
            processed_ms: 1711187152563,
          },
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "aef783e3-1330-428a-9899-8825287894d4",
            price: 3340.71,
            price_sources: [],
            processed_ms: 1711187380239,
          },
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "50f4f697-324f-48ea-85f5-e41ecae27397",
            price: 3345.17,
            price_sources: [],
            processed_ms: 1711187619110,
          },
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "5e407234-7035-43a8-b856-333b936e07c2",
            price: 3345.67,
            price_sources: [],
            processed_ms: 1711187921584,
          },
          {
            leverage: 0.3,
            order_type: "FLAT",
            order_uuid: "e0fe7a97-8e5b-48d2-970e-7f7c0f7cd7c3",
            price: 3353.59,
            price_sources: [],
            processed_ms: 1711188128058,
          },
        ],
        position_type: "FLAT",
        position_uuid: "e1233ec0-9ddd-41ca-b628-6cba55a2c77c",
        return_at_close: 1.0022300826837327,
        trade_pair: ["ETHUSD", "ETH/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 64433.93210526316,
        close_ms: 1711188985602,
        current_return: 0.9991271418804468,
        initial_entry_price: 64386.18,
        is_closed_position: true,
        miner_hotkey: "5CaXrBFAaHZdMGKpD8mHCPxjZvX2uq9gRQjFEc2gWaqcwEfJ",
        net_leverage: 0.0,
        open_ms: 1711187800814,
        orders: [
          {
            leverage: -10.0,
            order_type: "SHORT",
            order_uuid: "f737e279-ca6d-486d-a12e-5ce83cbccc71",
            price: 64386.18,
            price_sources: [],
            processed_ms: 1711187800813,
          },
          {
            leverage: -9.0,
            order_type: "SHORT",
            order_uuid: "f99948af-f840-4feb-b126-11817baec78b",
            price: 64486.99,
            price_sources: [],
            processed_ms: 1711188025142,
          },
          {
            leverage: 19.0,
            order_type: "FLAT",
            order_uuid: "34d63ff4-7336-40f6-a1f6-f2a59f89d1da",
            price: 64436.89,
            price_sources: [],
            processed_ms: 1711188985602,
          },
        ],
        position_type: "FLAT",
        position_uuid: "4b9e3a36-2498-423c-81a8-a0d1c1791291",
        return_at_close: 0.9611603104889898,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 3360.9480000000003,
        close_ms: 1711189559344,
        current_return: 1.001201010061954,
        initial_entry_price: 3362.17,
        is_closed_position: true,
        miner_hotkey: "5CaXrBFAaHZdMGKpD8mHCPxjZvX2uq9gRQjFEc2gWaqcwEfJ",
        net_leverage: 0.0,
        open_ms: 1711188335699,
        orders: [
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "339ce73f-8bdd-4259-b1c7-dc3efbcf83f9",
            price: 3362.17,
            price_sources: [],
            processed_ms: 1711188335698,
          },
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "8b2e6844-50c4-4e89-a9ec-b02bbf956bbc",
            price: 3364.14,
            price_sources: [],
            processed_ms: 1711188575275,
          },
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "fe1c62ce-704b-432d-8be8-f115208d69db",
            price: 3360.06,
            price_sources: [],
            processed_ms: 1711188834308,
          },
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "ee7011f6-83d5-4dc6-b6e6-aeeb441e0fba",
            price: 3358.99,
            price_sources: [],
            processed_ms: 1711189088284,
          },
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "e171d2e5-84cf-4610-a2f0-971e4c66e597",
            price: 3359.38,
            price_sources: [],
            processed_ms: 1711189301200,
          },
          {
            leverage: 0.3,
            order_type: "FLAT",
            order_uuid: "f05bf9c0-1dde-487b-a564-8154f2bd1e51",
            price: 3363.64,
            price_sources: [],
            processed_ms: 1711189559344,
          },
        ],
        position_type: "FLAT",
        position_uuid: "2af2ccf7-b1de-4390-b54a-f19a69f98f11",
        return_at_close: 0.9981974070317681,
        trade_pair: ["ETHUSD", "ETH/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 3365.802,
        close_ms: 1711190999412,
        current_return: 0.9992776321297826,
        initial_entry_price: 3368.09,
        is_closed_position: true,
        miner_hotkey: "5CaXrBFAaHZdMGKpD8mHCPxjZvX2uq9gRQjFEc2gWaqcwEfJ",
        net_leverage: 0.0,
        open_ms: 1711189779482,
        orders: [
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "e7f873c6-3667-4c96-ad17-3c5b2cb72187",
            price: 3368.09,
            price_sources: [],
            processed_ms: 1711189779481,
          },
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "52e0126b-0549-468c-8462-d6b29f22aee8",
            price: 3368.09,
            price_sources: [],
            processed_ms: 1711190019718,
          },
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "2ed7dd6a-2964-471f-83ab-a9c957490d6d",
            price: 3362.65,
            price_sources: [],
            processed_ms: 1711190279392,
          },
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "6b5991e2-7177-4ba0-b778-edd3b6aec967",
            price: 3366.38,
            price_sources: [],
            processed_ms: 1711190500562,
          },
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "dff7c2b3-ebf6-4a14-8762-9cb223f1c741",
            price: 3363.8,
            price_sources: [],
            processed_ms: 1711190740940,
          },
          {
            leverage: 0.3,
            order_type: "FLAT",
            order_uuid: "7351fbf1-1507-47be-814e-e1a91dc39836",
            price: 3364.18,
            price_sources: [],
            processed_ms: 1711190999412,
          },
        ],
        position_type: "FLAT",
        position_uuid: "ab9f7c63-2589-48fa-970e-05c8da28a1b8",
        return_at_close: 0.9962797992333933,
        trade_pair: ["ETHUSD", "ETH/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 3349.778,
        close_ms: 1711192420122,
        current_return: 1.0039711385243408,
        initial_entry_price: 3351.18,
        is_closed_position: true,
        miner_hotkey: "5CaXrBFAaHZdMGKpD8mHCPxjZvX2uq9gRQjFEc2gWaqcwEfJ",
        net_leverage: 0.0,
        open_ms: 1711191218177,
        orders: [
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "203b1042-fd51-4453-813a-f68efd335dee",
            price: 3351.18,
            price_sources: [],
            processed_ms: 1711191218176,
          },
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "40cb5309-1e98-40a8-bac9-0dbc1e6dc19b",
            price: 3345.11,
            price_sources: [],
            processed_ms: 1711191476382,
          },
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "fb5f88bb-1726-4a18-b5af-e227e13dbd1e",
            price: 3348.83,
            price_sources: [],
            processed_ms: 1711191698641,
          },
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "e8075191-701b-448d-97c9-a04f0d16a3d0",
            price: 3353.51,
            price_sources: [],
            processed_ms: 1711191937706,
          },
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "d0f113e9-23cb-4410-bc3b-df7046cc6e83",
            price: 3350.26,
            price_sources: [],
            processed_ms: 1711192197708,
          },
          {
            leverage: 0.3,
            order_type: "FLAT",
            order_uuid: "0996ddd9-7e07-4be8-bd72-1832b55df9d6",
            price: 3358.65,
            price_sources: [],
            processed_ms: 1711192420122,
          },
        ],
        position_type: "FLAT",
        position_uuid: "2d102fc2-5e10-416c-919f-d290f96c4a1e",
        return_at_close: 1.0009592251087678,
        trade_pair: ["ETHUSD", "ETH/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 3358.9240000000004,
        close_ms: 1711193862069,
        current_return: 1.002106178301659,
        initial_entry_price: 3358.69,
        is_closed_position: true,
        miner_hotkey: "5CaXrBFAaHZdMGKpD8mHCPxjZvX2uq9gRQjFEc2gWaqcwEfJ",
        net_leverage: 0.0,
        open_ms: 1711192660015,
        orders: [
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "57e58295-a1f2-4c8f-900d-6ce5b1bbe554",
            price: 3358.69,
            price_sources: [],
            processed_ms: 1711192660014,
          },
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "3bd0ae54-b6a4-4bd8-b52d-5c44b2974427",
            price: 3357.99,
            price_sources: [],
            processed_ms: 1711192900070,
          },
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "b0606bb7-87b4-47a2-be6f-19e2a8a6b90d",
            price: 3358.84,
            price_sources: [],
            processed_ms: 1711193160717,
          },
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "563a31c6-d3f5-4f25-8030-9882e565fead",
            price: 3359.68,
            price_sources: [],
            processed_ms: 1711193388653,
          },
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "c77b4d3a-6bb8-4107-823b-d1977351c962",
            price: 3359.42,
            price_sources: [],
            processed_ms: 1711193629245,
          },
          {
            leverage: 0.3,
            order_type: "FLAT",
            order_uuid: "26d1d1c6-4b00-4f01-b1cc-75731ec92164",
            price: 3363.64,
            price_sources: [],
            processed_ms: 1711193862069,
          },
        ],
        position_type: "FLAT",
        position_uuid: "3d2db137-ac84-4a2b-ad8c-8ef310ff68a8",
        return_at_close: 0.999099859766754,
        trade_pair: ["ETHUSD", "ETH/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 3357.9499999999994,
        close_ms: 1711195322953,
        current_return: 1.0027118068585932,
        initial_entry_price: 3357.54,
        is_closed_position: true,
        miner_hotkey: "5CaXrBFAaHZdMGKpD8mHCPxjZvX2uq9gRQjFEc2gWaqcwEfJ",
        net_leverage: 0.0,
        open_ms: 1711194120477,
        orders: [
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "ee13ba3c-a2d7-4bd1-b838-96b08fa92304",
            price: 3357.54,
            price_sources: [],
            processed_ms: 1711194120476,
          },
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "f6479f93-b87e-43bf-9efc-a40706fd2483",
            price: 3357.54,
            price_sources: [],
            processed_ms: 1711194352392,
          },
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "d3c8d26d-d437-4fad-84c4-c93bf90a2033",
            price: 3356.1,
            price_sources: [],
            processed_ms: 1711194584036,
          },
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "05d08523-7eb1-44d2-87a0-e762ec37185b",
            price: 3359.16,
            price_sources: [],
            processed_ms: 1711194823330,
          },
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "999b44b2-6894-4a4d-996e-741d379551be",
            price: 3359.41,
            price_sources: [],
            processed_ms: 1711195064334,
          },
          {
            leverage: 0.3,
            order_type: "FLAT",
            order_uuid: "28b33cba-be05-4ee5-88c7-444a156cf679",
            price: 3364.02,
            price_sources: [],
            processed_ms: 1711195322953,
          },
        ],
        position_type: "FLAT",
        position_uuid: "1904daad-c4bd-4d88-b009-aa2aacc7888f",
        return_at_close: 0.9997036714380174,
        trade_pair: ["ETHUSD", "ETH/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 3365.768,
        close_ms: 1711196762942,
        current_return: 1.0030004017199565,
        initial_entry_price: 3360.55,
        is_closed_position: true,
        miner_hotkey: "5CaXrBFAaHZdMGKpD8mHCPxjZvX2uq9gRQjFEc2gWaqcwEfJ",
        net_leverage: 0.0,
        open_ms: 1711195551640,
        orders: [
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "49ac5967-2f94-4d44-a69d-1b8d3d970a48",
            price: 3360.55,
            price_sources: [],
            processed_ms: 1711195551639,
          },
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "d79ea16f-c23d-4627-bdf0-27a44ec60f2b",
            price: 3368.08,
            price_sources: [],
            processed_ms: 1711195790420,
          },
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "d56974b3-d58b-4189-8f8e-5bd12eb032ff",
            price: 3367.55,
            price_sources: [],
            processed_ms: 1711196049971,
          },
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "18728a35-9fb5-4043-b2c2-70dd8710a379",
            price: 3367.55,
            price_sources: [],
            processed_ms: 1711196272216,
          },
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "dce4a966-eec8-4f7b-9a5d-a33ee130ea32",
            price: 3365.11,
            price_sources: [],
            processed_ms: 1711196503506,
          },
          {
            leverage: 0.3,
            order_type: "FLAT",
            order_uuid: "dbbd2774-4e92-46d3-82d7-47a8b2b395e5",
            price: 3372.49,
            price_sources: [],
            processed_ms: 1711196762942,
          },
        ],
        position_type: "FLAT",
        position_uuid: "702282bd-ea11-409f-8e33-57d6dd79fd81",
        return_at_close: 0.9999914005147966,
        trade_pair: ["ETHUSD", "ETH/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 3369.9519999999998,
        close_ms: 1711198212879,
        current_return: 0.9989582437525764,
        initial_entry_price: 3372.19,
        is_closed_position: true,
        miner_hotkey: "5CaXrBFAaHZdMGKpD8mHCPxjZvX2uq9gRQjFEc2gWaqcwEfJ",
        net_leverage: 0.0,
        open_ms: 1711196992106,
        orders: [
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "c50d73f3-4569-4aca-8b4d-a9033294c833",
            price: 3372.19,
            price_sources: [],
            processed_ms: 1711196992104,
          },
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "53297ad9-177d-4099-baf2-9d85abae2242",
            price: 3372.19,
            price_sources: [],
            processed_ms: 1711197224560,
          },
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "3caad4d4-24f0-4f96-9bf6-9cbdec435110",
            price: 3368.16,
            price_sources: [],
            processed_ms: 1711197483821,
          },
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "1b0eedd2-9577-4aa0-a129-f9858a148e5b",
            price: 3369.61,
            price_sources: [],
            processed_ms: 1711197712810,
          },
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "b788ee69-604f-4a1f-a53b-f616f228f865",
            price: 3367.61,
            price_sources: [],
            processed_ms: 1711197952713,
          },
          {
            leverage: 0.3,
            order_type: "FLAT",
            order_uuid: "d641ee74-1c53-49bf-affa-e76bc9626538",
            price: 3367.61,
            price_sources: [],
            processed_ms: 1711198212879,
          },
        ],
        position_type: "FLAT",
        position_uuid: "f5d2b88e-4abc-44ad-a47b-f62ae1a519e0",
        return_at_close: 0.9959613690213186,
        trade_pair: ["ETHUSD", "ETH/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 3369.5939999999996,
        close_ms: 1711199631836,
        current_return: 0.9982173627271598,
        initial_entry_price: 3377.58,
        is_closed_position: true,
        miner_hotkey: "5CaXrBFAaHZdMGKpD8mHCPxjZvX2uq9gRQjFEc2gWaqcwEfJ",
        net_leverage: 0.0,
        open_ms: 1711198431649,
        orders: [
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "8685d51a-f703-40a9-a97a-f592b868182f",
            price: 3377.58,
            price_sources: [],
            processed_ms: 1711198431647,
          },
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "1b3c3831-4cdd-4712-b389-b7fde3c39fc6",
            price: 3377.14,
            price_sources: [],
            processed_ms: 1711198670321,
          },
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "c7e348be-35d1-4da1-acaa-8bcbd8f406bc",
            price: 3369.54,
            price_sources: [],
            processed_ms: 1711198929945,
          },
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "af47a694-43bd-4a5b-853b-20fb6cc88b64",
            price: 3361.39,
            price_sources: [],
            processed_ms: 1711199148266,
          },
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "925b4e65-8c17-42b8-8ee6-bce4fd664b3b",
            price: 3362.32,
            price_sources: [],
            processed_ms: 1711199408835,
          },
          {
            leverage: 0.3,
            order_type: "FLAT",
            order_uuid: "e9849362-ccab-45ab-9708-2eae00bf90a8",
            price: 3365.58,
            price_sources: [],
            processed_ms: 1711199631836,
          },
        ],
        position_type: "FLAT",
        position_uuid: "39299a82-d66e-4703-a98b-992835fa89c1",
        return_at_close: 0.9952227106389783,
        trade_pair: ["ETHUSD", "ETH/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 3360.708,
        close_ms: 1711201086734,
        current_return: 0.9933159905457031,
        initial_entry_price: 3363.55,
        is_closed_position: true,
        miner_hotkey: "5CaXrBFAaHZdMGKpD8mHCPxjZvX2uq9gRQjFEc2gWaqcwEfJ",
        net_leverage: 0.0,
        open_ms: 1711199870619,
        orders: [
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "510d0068-2c0d-449d-bc08-2c17f551c700",
            price: 3363.55,
            price_sources: [],
            processed_ms: 1711199870617,
          },
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "bb8b412d-cf84-44b1-95ad-4a736684caf5",
            price: 3369.78,
            price_sources: [],
            processed_ms: 1711200109081,
          },
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "192ed8b4-6819-4dcc-b94e-f28b77c65a41",
            price: 3361.18,
            price_sources: [],
            processed_ms: 1711200386094,
          },
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "fdd24523-f38a-4ee8-93ee-4cdcc7084c5f",
            price: 3358.2,
            price_sources: [],
            processed_ms: 1711200588294,
          },
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "cb39901d-b746-4a67-babe-6913b72f6c11",
            price: 3350.83,
            price_sources: [],
            processed_ms: 1711200828977,
          },
          {
            leverage: 0.3,
            order_type: "FLAT",
            order_uuid: "841f8ad1-af69-4849-bba8-8586c2e3e4f5",
            price: 3345.72,
            price_sources: [],
            processed_ms: 1711201086734,
          },
        ],
        position_type: "FLAT",
        position_uuid: "642b8935-6614-4204-9e68-f6047659bb33",
        return_at_close: 0.9903360425740659,
        trade_pair: ["ETHUSD", "ETH/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 3355.5840000000003,
        close_ms: 1711202514506,
        current_return: 1.003615234479918,
        initial_entry_price: 3350.82,
        is_closed_position: true,
        miner_hotkey: "5CaXrBFAaHZdMGKpD8mHCPxjZvX2uq9gRQjFEc2gWaqcwEfJ",
        net_leverage: 0.0,
        open_ms: 1711201309821,
        orders: [
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "4bb3c326-fc68-4a8b-89de-3940b19649a9",
            price: 3350.82,
            price_sources: [],
            processed_ms: 1711201309819,
          },
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "f365665a-ec8f-454f-8ccc-74659c6b426e",
            price: 3351.99,
            price_sources: [],
            processed_ms: 1711201575693,
          },
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "627d2f80-7796-4217-a6fa-aa20304279bb",
            price: 3347.41,
            price_sources: [],
            processed_ms: 1711201794151,
          },
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "21078782-0375-4265-8692-261fde15dcf9",
            price: 3363.93,
            price_sources: [],
            processed_ms: 1711202054861,
          },
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "e26558b3-22bd-4b07-8120-b6c3ddc9b5b2",
            price: 3363.77,
            price_sources: [],
            processed_ms: 1711202274257,
          },
          {
            leverage: 0.3,
            order_type: "FLAT",
            order_uuid: "55e1fae2-4f2c-4bdd-b84b-0996d5901908",
            price: 3363.66,
            price_sources: [],
            processed_ms: 1711202514506,
          },
        ],
        position_type: "FLAT",
        position_uuid: "2a8a3cae-bd7a-4804-b493-aef7d0c43f88",
        return_at_close: 1.0006043887764784,
        trade_pair: ["ETHUSD", "ETH/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 3362.9719999999993,
        close_ms: 1711203973686,
        current_return: 1.0015638838529441,
        initial_entry_price: 3364.7,
        is_closed_position: true,
        miner_hotkey: "5CaXrBFAaHZdMGKpD8mHCPxjZvX2uq9gRQjFEc2gWaqcwEfJ",
        net_leverage: 0.0,
        open_ms: 1711202772843,
        orders: [
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "66cb8cee-1410-4142-9b3c-33a4843ee11f",
            price: 3364.7,
            price_sources: [],
            processed_ms: 1711202772841,
          },
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "00393104-b33f-4826-8207-f41b30b1010a",
            price: 3366.59,
            price_sources: [],
            processed_ms: 1711202994081,
          },
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "77490333-202f-4002-8279-c4307cf5cf66",
            price: 3366.2,
            price_sources: [],
            processed_ms: 1711203234182,
          },
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "874ac53f-dc7e-464e-aa07-fe08c5f2a0ba",
            price: 3359.07,
            price_sources: [],
            processed_ms: 1711203494132,
          },
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "66b08ef4-0756-4ed2-bd55-a5418711f423",
            price: 3358.3,
            price_sources: [],
            processed_ms: 1711203713633,
          },
          {
            leverage: 0.3,
            order_type: "FLAT",
            order_uuid: "cd1d3198-a4b7-4fe8-a20c-49ea9ebe24a0",
            price: 3366.48,
            price_sources: [],
            processed_ms: 1711203973686,
          },
        ],
        position_type: "FLAT",
        position_uuid: "df175592-4dfa-4e4e-98ae-d5e950c40809",
        return_at_close: 0.9985591922013853,
        trade_pair: ["ETHUSD", "ETH/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 3398.42,
        close_ms: 1711205398572,
        current_return: 1.0054493120410637,
        initial_entry_price: 3366.48,
        is_closed_position: true,
        miner_hotkey: "5CaXrBFAaHZdMGKpD8mHCPxjZvX2uq9gRQjFEc2gWaqcwEfJ",
        net_leverage: 0.0,
        open_ms: 1711204195965,
        orders: [
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "18c23fd4-43e0-4dda-8431-58584f427447",
            price: 3366.48,
            price_sources: [],
            processed_ms: 1711204195963,
          },
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "0d50ef91-95b6-4176-858a-05778d46cd42",
            price: 3405.98,
            price_sources: [],
            processed_ms: 1711204437111,
          },
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "15eedb22-bd72-40ae-979b-d78d72834ddc",
            price: 3411.34,
            price_sources: [],
            processed_ms: 1711204695798,
          },
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "47ecf442-ecaa-4b8e-b368-8b2176e7677e",
            price: 3404.16,
            price_sources: [],
            processed_ms: 1711204918057,
          },
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "5b4772dc-3190-4502-902d-44102080dc84",
            price: 3404.14,
            price_sources: [],
            processed_ms: 1711205176716,
          },
          {
            leverage: 0.3,
            order_type: "FLAT",
            order_uuid: "9d08e42f-9349-4a78-83d9-387bde055032",
            price: 3410.65,
            price_sources: [],
            processed_ms: 1711205398572,
          },
        ],
        position_type: "FLAT",
        position_uuid: "45059266-570c-4324-86d7-31de6d589129",
        return_at_close: 1.0024329641049405,
        trade_pair: ["ETHUSD", "ETH/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 3416.72,
        close_ms: 1711206835799,
        current_return: 0.9966441773842154,
        initial_entry_price: 3414.96,
        is_closed_position: true,
        miner_hotkey: "5CaXrBFAaHZdMGKpD8mHCPxjZvX2uq9gRQjFEc2gWaqcwEfJ",
        net_leverage: 0.0,
        open_ms: 1711205658122,
        orders: [
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "4b738667-b49a-4369-8585-83ac48a57e38",
            price: 3414.96,
            price_sources: [],
            processed_ms: 1711205658085,
          },
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "035cfa55-add8-4e20-b7d3-679779b90129",
            price: 3419.75,
            price_sources: [],
            processed_ms: 1711205876521,
          },
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "968319ae-7d6a-4bf1-ae97-db9a84908f7e",
            price: 3416.34,
            price_sources: [],
            processed_ms: 1711206116367,
          },
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "f811f17a-b9af-4554-8aff-7c01bb1b148f",
            price: 3414.43,
            price_sources: [],
            processed_ms: 1711206376050,
          },
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "96236b21-36dc-489a-93bd-f0ac1ff38709",
            price: 3418.12,
            price_sources: [],
            processed_ms: 1711206596564,
          },
          {
            leverage: 0.3,
            order_type: "FLAT",
            order_uuid: "083d15fd-c0f9-4061-ad39-c1a4f483429f",
            price: 3409.08,
            price_sources: [],
            processed_ms: 1711206835799,
          },
        ],
        position_type: "FLAT",
        position_uuid: "4f39cd6e-ac6b-412b-9079-3aef57e6ca70",
        return_at_close: 0.9936542448520628,
        trade_pair: ["ETHUSD", "ETH/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 3394.0659999999993,
        close_ms: 1711208299525,
        current_return: 1.0002745608785952,
        initial_entry_price: 3409.08,
        is_closed_position: true,
        miner_hotkey: "5CaXrBFAaHZdMGKpD8mHCPxjZvX2uq9gRQjFEc2gWaqcwEfJ",
        net_leverage: 0.0,
        open_ms: 1711207095004,
        orders: [
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "349da3f9-16a5-4b00-b095-d15da7bbc6b4",
            price: 3409.08,
            price_sources: [],
            processed_ms: 1711207095002,
          },
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "eab8d265-194e-4d21-a972-d2bf9dbdfe3e",
            price: 3404.61,
            price_sources: [],
            processed_ms: 1711207325519,
          },
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "65550c80-c6dc-472a-8394-f50de9b4ca2b",
            price: 3383.57,
            price_sources: [],
            processed_ms: 1711207565314,
          },
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "f6429139-078c-4a82-a566-9c88f59084e5",
            price: 3388.15,
            price_sources: [],
            processed_ms: 1711207798110,
          },
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "adfe53b9-158a-4f53-9747-de420f42040a",
            price: 3384.92,
            price_sources: [],
            processed_ms: 1711208038070,
          },
          {
            leverage: 0.3,
            order_type: "FLAT",
            order_uuid: "cba24ce6-e791-4a22-8945-7bcaca30177f",
            price: 3394.69,
            price_sources: [],
            processed_ms: 1711208299525,
          },
        ],
        position_type: "FLAT",
        position_uuid: "3474d492-9d2f-4dfc-8faa-3dcc8c6d4d20",
        return_at_close: 0.9972737371959595,
        trade_pair: ["ETHUSD", "ETH/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 3396.188,
        close_ms: 1711209738936,
        current_return: 1.0043773355809924,
        initial_entry_price: 3393.16,
        is_closed_position: true,
        miner_hotkey: "5CaXrBFAaHZdMGKpD8mHCPxjZvX2uq9gRQjFEc2gWaqcwEfJ",
        net_leverage: 0.0,
        open_ms: 1711208521544,
        orders: [
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "1473c230-b31e-4070-a6cc-8af28309bf6a",
            price: 3393.16,
            price_sources: [],
            processed_ms: 1711208521542,
          },
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "84891790-3e09-463a-9f2c-3b9b5ec20c84",
            price: 3389.01,
            price_sources: [],
            processed_ms: 1711208781905,
          },
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "1167065f-f406-40b0-ae48-e1cc174ba3bb",
            price: 3395.24,
            price_sources: [],
            processed_ms: 1711209002706,
          },
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "e0d8ff03-5657-40a1-9add-dcbdb54e8d15",
            price: 3397.44,
            price_sources: [],
            processed_ms: 1711209261745,
          },
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "38f8b551-7a9d-4058-9660-30f5e28ee913",
            price: 3406.09,
            price_sources: [],
            processed_ms: 1711209479443,
          },
          {
            leverage: 0.3,
            order_type: "FLAT",
            order_uuid: "ad84afda-7b7b-443f-a8d3-0d2d90008f51",
            price: 3406.09,
            price_sources: [],
            processed_ms: 1711209738936,
          },
        ],
        position_type: "FLAT",
        position_uuid: "3ebfd3d2-be7d-46cb-a371-428c561c4305",
        return_at_close: 1.0013642035742494,
        trade_pair: ["ETHUSD", "ETH/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 3394.8639999999996,
        close_ms: 1711211180797,
        current_return: 1.002914551844204,
        initial_entry_price: 3394.69,
        is_closed_position: true,
        miner_hotkey: "5CaXrBFAaHZdMGKpD8mHCPxjZvX2uq9gRQjFEc2gWaqcwEfJ",
        net_leverage: 0.0,
        open_ms: 1711209960707,
        orders: [
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "a4aa93b4-69d1-4bf4-88ae-38ea3406d695",
            price: 3394.69,
            price_sources: [],
            processed_ms: 1711209960705,
          },
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "20e6ac53-e074-4dbb-92ba-bc867722e6a9",
            price: 3391.26,
            price_sources: [],
            processed_ms: 1711210217842,
          },
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "a4056ff6-8a0f-4308-8bb8-f94dc13b7795",
            price: 3389.99,
            price_sources: [],
            processed_ms: 1711210447323,
          },
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "adf5e8b9-af77-4a4b-880e-fd08b271779f",
            price: 3399.48,
            price_sources: [],
            processed_ms: 1711210682240,
          },
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "7fc6725c-c057-4e8f-8ef9-8140828bc597",
            price: 3398.9,
            price_sources: [],
            processed_ms: 1711210921772,
          },
          {
            leverage: 0.3,
            order_type: "FLAT",
            order_uuid: "3407e438-d4ba-41e3-8126-4ff494c360a8",
            price: 3401.46,
            price_sources: [],
            processed_ms: 1711211180797,
          },
        ],
        position_type: "FLAT",
        position_uuid: "0f451cf6-1b99-411f-8f98-6942a7ef1315",
        return_at_close: 0.9999058081886714,
        trade_pair: ["ETHUSD", "ETH/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 3412.7499999999995,
        close_ms: 1711212623876,
        current_return: 1.0051558365186712,
        initial_entry_price: 3401.0,
        is_closed_position: true,
        miner_hotkey: "5CaXrBFAaHZdMGKpD8mHCPxjZvX2uq9gRQjFEc2gWaqcwEfJ",
        net_leverage: 0.0,
        open_ms: 1711211402751,
        orders: [
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "35b5ae11-7e87-44fb-81ed-31d3af09b877",
            price: 3401.0,
            price_sources: [],
            processed_ms: 1711211402749,
          },
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "d215b965-06b1-4bc7-bede-57629a20958c",
            price: 3414.34,
            price_sources: [],
            processed_ms: 1711211644362,
          },
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "090a9413-644b-4f6f-9052-947c7cbeddb9",
            price: 3416.24,
            price_sources: [],
            processed_ms: 1711211886029,
          },
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "41704ae6-9c63-4d62-876f-d9e6b0072dfd",
            price: 3415.33,
            price_sources: [],
            processed_ms: 1711212162307,
          },
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "a967d832-fbb5-4f63-acdf-4b0f430925c1",
            price: 3416.84,
            price_sources: [],
            processed_ms: 1711212364651,
          },
          {
            leverage: 0.3,
            order_type: "FLAT",
            order_uuid: "9a5d0d34-31e1-43b6-a966-53e1ef555967",
            price: 3424.44,
            price_sources: [],
            processed_ms: 1711212623876,
          },
        ],
        position_type: "FLAT",
        position_uuid: "9f1ad6f3-1c84-47ad-9c7f-968e4bfd01d1",
        return_at_close: 1.0021403690091153,
        trade_pair: ["ETHUSD", "ETH/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 3416.4359999999997,
        close_ms: 1711214047994,
        current_return: 0.996939937624838,
        initial_entry_price: 3424.44,
        is_closed_position: true,
        miner_hotkey: "5CaXrBFAaHZdMGKpD8mHCPxjZvX2uq9gRQjFEc2gWaqcwEfJ",
        net_leverage: 0.0,
        open_ms: 1711212844947,
        orders: [
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "b40b1e6f-3dee-4dc5-8364-be42582603ad",
            price: 3424.44,
            price_sources: [],
            processed_ms: 1711212844945,
          },
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "c2fd6865-a0a6-4d64-8cb5-8c439bd5417f",
            price: 3422.49,
            price_sources: [],
            processed_ms: 1711213103729,
          },
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "d241fee7-fb48-4d30-81b4-33f83de3cd5b",
            price: 3422.49,
            price_sources: [],
            processed_ms: 1711213334049,
          },
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "bebb30dd-a8ed-4b39-89ff-d4fa238dbf56",
            price: 3407.15,
            price_sources: [],
            processed_ms: 1711213565505,
          },
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "6939718c-34b2-43ed-b6dd-57eb7bbbb46c",
            price: 3405.61,
            price_sources: [],
            processed_ms: 1711213824812,
          },
          {
            leverage: 0.3,
            order_type: "FLAT",
            order_uuid: "5bc65d75-97a8-4ff9-8aba-4f345a676ac9",
            price: 3409.45,
            price_sources: [],
            processed_ms: 1711214047994,
          },
        ],
        position_type: "FLAT",
        position_uuid: "b29bf11d-414e-4bc9-99d6-96a0c2fa6d10",
        return_at_close: 0.9939491178119635,
        trade_pair: ["ETHUSD", "ETH/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 3404.624,
        close_ms: 1711215493144,
        current_return: 1.0053451467865644,
        initial_entry_price: 3402.9,
        is_closed_position: true,
        miner_hotkey: "5CaXrBFAaHZdMGKpD8mHCPxjZvX2uq9gRQjFEc2gWaqcwEfJ",
        net_leverage: 0.0,
        open_ms: 1711214284602,
        orders: [
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "fb71759f-35ab-48f7-bd66-6471028226a5",
            price: 3402.9,
            price_sources: [],
            processed_ms: 1711214284600,
          },
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "4d11465e-ae9d-4d00-a0c7-419460ec6f4a",
            price: 3401.96,
            price_sources: [],
            processed_ms: 1711214543716,
          },
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "ee8c9624-a76a-4cac-b7c9-120e1219a87d",
            price: 3402.17,
            price_sources: [],
            processed_ms: 1711214772876,
          },
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "46f2afb2-8973-42e3-bc5a-62bb9f92de80",
            price: 3402.96,
            price_sources: [],
            processed_ms: 1711215030893,
          },
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "ecfa2b8b-717f-4853-b648-491d1f47c68f",
            price: 3413.13,
            price_sources: [],
            processed_ms: 1711215252622,
          },
          {
            leverage: 0.3,
            order_type: "FLAT",
            order_uuid: "debe40d5-8bde-4747-b36d-73060322158b",
            price: 3416.75,
            price_sources: [],
            processed_ms: 1711215493144,
          },
        ],
        position_type: "FLAT",
        position_uuid: "c0cf063f-a791-4c59-84eb-996a719514b4",
        return_at_close: 1.0023291113462047,
        trade_pair: ["ETHUSD", "ETH/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 3405.678,
        close_ms: 1711216934145,
        current_return: 0.9984395982009349,
        initial_entry_price: 3410.66,
        is_closed_position: true,
        miner_hotkey: "5CaXrBFAaHZdMGKpD8mHCPxjZvX2uq9gRQjFEc2gWaqcwEfJ",
        net_leverage: 0.0,
        open_ms: 1711215732914,
        orders: [
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "320c1ce2-424c-44a3-99a3-f323a9b96552",
            price: 3410.66,
            price_sources: [],
            processed_ms: 1711215732912,
          },
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "53af3f2a-8421-40be-8abd-c280bdc31964",
            price: 3409.29,
            price_sources: [],
            processed_ms: 1711215992850,
          },
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "f9dd9501-dbbb-4034-88c7-55c3fc0d2cc2",
            price: 3405.0,
            price_sources: [],
            processed_ms: 1711216212497,
          },
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "75de66cd-bf47-47be-98a7-c8e1aab6b026",
            price: 3402.25,
            price_sources: [],
            processed_ms: 1711216451995,
          },
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "357fae1b-80d2-4a1a-9eb8-f1e6fdf92c93",
            price: 3401.19,
            price_sources: [],
            processed_ms: 1711216710717,
          },
          {
            leverage: 0.3,
            order_type: "FLAT",
            order_uuid: "6f6be136-b666-4f9c-b7fe-221fcd6f5c6d",
            price: 3402.13,
            price_sources: [],
            processed_ms: 1711216934145,
          },
        ],
        position_type: "FLAT",
        position_uuid: "ab6f36ed-992a-4c43-b508-e273211bcb3c",
        return_at_close: 0.9954442794063321,
        trade_pair: ["ETHUSD", "ETH/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 3407.19,
        close_ms: 1711218393022,
        current_return: 0.9991269815607924,
        initial_entry_price: 3401.99,
        is_closed_position: true,
        miner_hotkey: "5CaXrBFAaHZdMGKpD8mHCPxjZvX2uq9gRQjFEc2gWaqcwEfJ",
        net_leverage: 0.0,
        open_ms: 1711217169449,
        orders: [
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "98c1e572-5271-4125-b8bd-5c53dd94ae56",
            price: 3401.99,
            price_sources: [],
            processed_ms: 1711217169446,
          },
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "9421db13-7e83-4b69-b769-8fd862d1f95b",
            price: 3408.82,
            price_sources: [],
            processed_ms: 1711217429049,
          },
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "bc6f086a-99e6-4599-b511-0f200aa15403",
            price: 3408.38,
            price_sources: [],
            processed_ms: 1711217649381,
          },
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "6783da37-133b-488a-9d17-60ed17a2f16d",
            price: 3408.38,
            price_sources: [],
            processed_ms: 1711217908252,
          },
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "8d34ab32-7688-4b49-bb28-86b29b613838",
            price: 3408.38,
            price_sources: [],
            processed_ms: 1711218130621,
          },
          {
            leverage: 0.3,
            order_type: "FLAT",
            order_uuid: "28c1ab5c-3f1c-4147-8d8e-046a6299c04a",
            price: 3405.21,
            price_sources: [],
            processed_ms: 1711218393022,
          },
        ],
        position_type: "FLAT",
        position_uuid: "7e37db06-7feb-447f-845c-5fa9a4c5cb5b",
        return_at_close: 0.99612960061611,
        trade_pair: ["ETHUSD", "ETH/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 3402.153999999999,
        close_ms: 1711219816092,
        current_return: 1.00132855242408,
        initial_entry_price: 3405.21,
        is_closed_position: true,
        miner_hotkey: "5CaXrBFAaHZdMGKpD8mHCPxjZvX2uq9gRQjFEc2gWaqcwEfJ",
        net_leverage: 0.0,
        open_ms: 1711218618403,
        orders: [
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "0249f571-ff27-4538-ab0b-8a6c14f8ac7e",
            price: 3405.21,
            price_sources: [],
            processed_ms: 1711218618401,
          },
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "f1036724-377e-4e57-bab2-b26a4afc52fa",
            price: 3404.66,
            price_sources: [],
            processed_ms: 1711218851602,
          },
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "3203abf5-b03c-49c7-a5ea-350517902041",
            price: 3402.89,
            price_sources: [],
            processed_ms: 1711219112939,
          },
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "406c6195-7818-4be9-91af-d180785efbf5",
            price: 3400.01,
            price_sources: [],
            processed_ms: 1711219331720,
          },
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "3a625149-2aa9-4be2-8b65-6059319865a9",
            price: 3398.0,
            price_sources: [],
            processed_ms: 1711219590987,
          },
          {
            leverage: 0.3,
            order_type: "FLAT",
            order_uuid: "8b77d262-6e30-4376-97d4-f1f3c6f095b8",
            price: 3405.17,
            price_sources: [],
            processed_ms: 1711219816092,
          },
        ],
        position_type: "FLAT",
        position_uuid: "e1612649-cad2-4591-8814-5623f609e4ac",
        return_at_close: 0.9983245667668078,
        trade_pair: ["ETHUSD", "ETH/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 3407.9139999999993,
        close_ms: 1711221252347,
        current_return: 1.0004734734587912,
        initial_entry_price: 3408.85,
        is_closed_position: true,
        miner_hotkey: "5CaXrBFAaHZdMGKpD8mHCPxjZvX2uq9gRQjFEc2gWaqcwEfJ",
        net_leverage: 0.0,
        open_ms: 1711220056931,
        orders: [
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "c59496ef-154a-4203-8262-5d1b870a622b",
            price: 3408.85,
            price_sources: [],
            processed_ms: 1711220056928,
          },
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "527b5012-fddd-497d-a4f1-5fd6771f66a7",
            price: 3408.4,
            price_sources: [],
            processed_ms: 1711220315804,
          },
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "3a8d1056-ea39-4056-93e8-b3a2c6231167",
            price: 3408.4,
            price_sources: [],
            processed_ms: 1711220535953,
          },
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "0164585d-68be-424e-8b5b-c333b06f5a69",
            price: 3408.98,
            price_sources: [],
            processed_ms: 1711220771826,
          },
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "cbe60534-8a48-40aa-b183-e7be00681b5d",
            price: 3404.94,
            price_sources: [],
            processed_ms: 1711221031900,
          },
          {
            leverage: 0.3,
            order_type: "FLAT",
            order_uuid: "879e5680-4b34-42b1-b074-95050aa73dae",
            price: 3408.99,
            price_sources: [],
            processed_ms: 1711221252347,
          },
        ],
        position_type: "FLAT",
        position_uuid: "03c5a133-1324-47bd-9a27-60158a9be9e2",
        return_at_close: 0.9974720530384149,
        trade_pair: ["ETHUSD", "ETH/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 3402.368,
        close_ms: 1711222698754,
        current_return: 0.995833956685118,
        initial_entry_price: 3408.99,
        is_closed_position: true,
        miner_hotkey: "5CaXrBFAaHZdMGKpD8mHCPxjZvX2uq9gRQjFEc2gWaqcwEfJ",
        net_leverage: 0.0,
        open_ms: 1711221493972,
        orders: [
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "7c08912d-d215-4f49-8a85-15c8e2c2980b",
            price: 3408.99,
            price_sources: [],
            processed_ms: 1711221493969,
          },
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "78879628-4d99-4362-b428-532a67a7ed23",
            price: 3402.52,
            price_sources: [],
            processed_ms: 1711221753714,
          },
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "3bc72006-06ee-4e24-860c-dbc53daa39ca",
            price: 3404.82,
            price_sources: [],
            processed_ms: 1711221976648,
          },
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "69b14335-fa8f-4523-86bd-3bd1fb901ca6",
            price: 3399.75,
            price_sources: [],
            processed_ms: 1711222217625,
          },
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "d0f604b4-6a6b-460a-893c-b016af28320a",
            price: 3395.76,
            price_sources: [],
            processed_ms: 1711222477217,
          },
          {
            leverage: 0.3,
            order_type: "FLAT",
            order_uuid: "17b3e459-ee4a-42e4-8cf6-8eaf2bfcad90",
            price: 3392.9,
            price_sources: [],
            processed_ms: 1711222698754,
          },
        ],
        position_type: "FLAT",
        position_uuid: "f41db75b-1945-4320-b5ec-6d1a91846a38",
        return_at_close: 0.9928464548150626,
        trade_pair: ["ETHUSD", "ETH/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 3388.762,
        close_ms: 1711224138799,
        current_return: 0.9995745772305951,
        initial_entry_price: 3391.92,
        is_closed_position: true,
        miner_hotkey: "5CaXrBFAaHZdMGKpD8mHCPxjZvX2uq9gRQjFEc2gWaqcwEfJ",
        net_leverage: 0.0,
        open_ms: 1711222936122,
        orders: [
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "2498496c-a463-4315-b755-e018a9108255",
            price: 3391.92,
            price_sources: [],
            processed_ms: 1711222936119,
          },
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "46293f1e-ca2a-4017-9f20-7dd4b9320c83",
            price: 3385.58,
            price_sources: [],
            processed_ms: 1711223196234,
          },
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "a4d0ce7f-9799-4977-a218-2b3a6ce27fec",
            price: 3385.58,
            price_sources: [],
            processed_ms: 1711223416920,
          },
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "39616712-fdfb-4155-8c63-8a1349b87b84",
            price: 3392.93,
            price_sources: [],
            processed_ms: 1711223657029,
          },
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "3819d7c1-f75d-41d1-bbe7-21dc9e762e7d",
            price: 3387.8,
            price_sources: [],
            processed_ms: 1711223916877,
          },
          {
            leverage: 0.3,
            order_type: "FLAT",
            order_uuid: "4614fdb8-75a7-467d-9659-3ce355348e16",
            price: 3387.8,
            price_sources: [],
            processed_ms: 1711224138799,
          },
        ],
        position_type: "FLAT",
        position_uuid: "3264f20d-924b-4137-8087-b610b1c3c051",
        return_at_close: 0.9965758534989033,
        trade_pair: ["ETHUSD", "ETH/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 3387.85,
        close_ms: 1711225580174,
        current_return: 0.9986147459482425,
        initial_entry_price: 3389.27,
        is_closed_position: true,
        miner_hotkey: "5CaXrBFAaHZdMGKpD8mHCPxjZvX2uq9gRQjFEc2gWaqcwEfJ",
        net_leverage: 0.0,
        open_ms: 1711224397943,
        orders: [
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "e111400f-cd6c-426c-8067-6a43764553a6",
            price: 3389.27,
            price_sources: [],
            processed_ms: 1711224397940,
          },
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "473c8dce-6001-459e-a09a-b36e3f9ce257",
            price: 3384.53,
            price_sources: [],
            processed_ms: 1711224619890,
          },
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "8bda5818-86de-44ee-a1b0-d8915eb6d1b4",
            price: 3388.29,
            price_sources: [],
            processed_ms: 1711224881844,
          },
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "01ba8023-0afe-4bbd-90cf-8024f819d1c2",
            price: 3388.29,
            price_sources: [],
            processed_ms: 1711225100999,
          },
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "5353ef15-d789-462b-89e2-46f0463a796b",
            price: 3388.87,
            price_sources: [],
            processed_ms: 1711225359922,
          },
          {
            leverage: 0.3,
            order_type: "FLAT",
            order_uuid: "32bd2d49-68b1-460a-8737-9a34ebc7f8ac",
            price: 3384.72,
            price_sources: [],
            processed_ms: 1711225580174,
          },
        ],
        position_type: "FLAT",
        position_uuid: "7a397d54-8704-4b17-abf8-8335ba9b25bb",
        return_at_close: 0.9956189017103978,
        trade_pair: ["ETHUSD", "ETH/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 3388.8520000000003,
        close_ms: 1711227024708,
        current_return: 1.0002514475944255,
        initial_entry_price: 3388.38,
        is_closed_position: true,
        miner_hotkey: "5CaXrBFAaHZdMGKpD8mHCPxjZvX2uq9gRQjFEc2gWaqcwEfJ",
        net_leverage: 0.0,
        open_ms: 1711225820173,
        orders: [
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "1917c186-ec23-4d07-9614-994a22a0f6f5",
            price: 3388.38,
            price_sources: [],
            processed_ms: 1711225820169,
          },
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "2e6a4882-f608-4d70-ad4b-7fa4eae90fd3",
            price: 3388.38,
            price_sources: [],
            processed_ms: 1711226080829,
          },
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "72d6873d-aa1b-4b80-99ce-cca11b5ab125",
            price: 3388.38,
            price_sources: [],
            processed_ms: 1711226301828,
          },
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "50dddd6e-ba00-482b-8683-26708535a119",
            price: 3389.56,
            price_sources: [],
            processed_ms: 1711226541775,
          },
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "5dafe5e1-9ee6-4010-884a-f1cd5903e947",
            price: 3389.56,
            price_sources: [],
            processed_ms: 1711226802150,
          },
          {
            leverage: 0.3,
            order_type: "FLAT",
            order_uuid: "de102eea-ccbc-4570-aa3c-cfe18141602a",
            price: 3389.42,
            price_sources: [],
            processed_ms: 1711227024708,
          },
        ],
        position_type: "FLAT",
        position_uuid: "787ba937-3536-4daa-b183-5ae88d63a528",
        return_at_close: 0.9972506932516423,
        trade_pair: ["ETHUSD", "ETH/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 3384.859999999999,
        close_ms: 1711228465709,
        current_return: 0.996881957280453,
        initial_entry_price: 3386.74,
        is_closed_position: true,
        miner_hotkey: "5CaXrBFAaHZdMGKpD8mHCPxjZvX2uq9gRQjFEc2gWaqcwEfJ",
        net_leverage: 0.0,
        open_ms: 1711227284944,
        orders: [
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "b58798e7-89c8-407f-9526-7fe8045619bb",
            price: 3386.74,
            price_sources: [],
            processed_ms: 1711227284940,
          },
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "61eff987-c5b6-43c2-aadf-4de27d1a54db",
            price: 3388.55,
            price_sources: [],
            processed_ms: 1711227506886,
          },
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "4e50650f-53e1-40e3-a347-f6293492e0c1",
            price: 3388.55,
            price_sources: [],
            processed_ms: 1711227744169,
          },
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "8834e83f-dcf8-4cc8-b382-bb3d0bf6f731",
            price: 3382.73,
            price_sources: [],
            processed_ms: 1711227985625,
          },
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "b0cf53d8-4559-4673-92f7-a2040ba34678",
            price: 3377.73,
            price_sources: [],
            processed_ms: 1711228244734,
          },
          {
            leverage: 0.3,
            order_type: "FLAT",
            order_uuid: "4282c77e-26bd-445f-88f9-03d0ce7233fd",
            price: 3377.82,
            price_sources: [],
            processed_ms: 1711228465709,
          },
        ],
        position_type: "FLAT",
        position_uuid: "2c1ebef7-a128-4e65-805e-6dc77709146f",
        return_at_close: 0.9938913114086116,
        trade_pair: ["ETHUSD", "ETH/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 3378.8179999999998,
        close_ms: 1711229907799,
        current_return: 1.0003115974622414,
        initial_entry_price: 3379.36,
        is_closed_position: true,
        miner_hotkey: "5CaXrBFAaHZdMGKpD8mHCPxjZvX2uq9gRQjFEc2gWaqcwEfJ",
        net_leverage: 0.0,
        open_ms: 1711228726180,
        orders: [
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "f6088bf1-ee03-42a4-a5ec-a4c8615d21dd",
            price: 3379.36,
            price_sources: [],
            processed_ms: 1711228726177,
          },
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "c2d8975b-f356-4aaa-bfd3-6d5b71a50df8",
            price: 3381.27,
            price_sources: [],
            processed_ms: 1711228947874,
          },
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "a2dda500-393b-4505-9e95-e1ec3f31ac39",
            price: 3380.07,
            price_sources: [],
            processed_ms: 1711229185486,
          },
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "cbb69fd8-d825-4ffc-adcd-9527ae4b1b85",
            price: 3375.59,
            price_sources: [],
            processed_ms: 1711229426616,
          },
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "7d0c36a3-1d2e-4464-917a-62dec3685024",
            price: 3377.8,
            price_sources: [],
            processed_ms: 1711229685966,
          },
          {
            leverage: 0.3,
            order_type: "FLAT",
            order_uuid: "823aac88-4b1b-4df6-adff-e5b23c11eb68",
            price: 3379.52,
            price_sources: [],
            processed_ms: 1711229907799,
          },
        ],
        position_type: "FLAT",
        position_uuid: "20203e36-84a3-4f58-a55f-f7005e91e4ec",
        return_at_close: 0.9973106626698547,
        trade_pair: ["ETHUSD", "ETH/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 3388.0959999999995,
        close_ms: 1711231364965,
        current_return: 1.0004636139984193,
        initial_entry_price: 3377.81,
        is_closed_position: true,
        miner_hotkey: "5CaXrBFAaHZdMGKpD8mHCPxjZvX2uq9gRQjFEc2gWaqcwEfJ",
        net_leverage: 0.0,
        open_ms: 1711230142656,
        orders: [
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "60d12735-6399-4a91-bf6e-2820a6e2f044",
            price: 3377.81,
            price_sources: [],
            processed_ms: 1711230142652,
          },
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "894d8993-c19d-41e1-a534-35140fc1e762",
            price: 3389.59,
            price_sources: [],
            processed_ms: 1711230403022,
          },
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "44ed0744-d629-4a17-a757-9a7436dfa60e",
            price: 3389.59,
            price_sources: [],
            processed_ms: 1711230623850,
          },
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "7d13627c-d910-414d-bdaf-b8bc1a4127d2",
            price: 3390.31,
            price_sources: [],
            processed_ms: 1711230881836,
          },
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "9fec5ff4-5a9b-487b-8bad-3fd6727f06ab",
            price: 3393.18,
            price_sources: [],
            processed_ms: 1711231103860,
          },
          {
            leverage: 0.3,
            order_type: "FLAT",
            order_uuid: "3d8a45f1-aa16-4239-8d55-1d7a59b66c12",
            price: 3389.14,
            price_sources: [],
            processed_ms: 1711231364965,
          },
        ],
        position_type: "FLAT",
        position_uuid: "e82c55cb-d9b0-4c4d-9817-7f8ab182f4a7",
        return_at_close: 0.9974622231564241,
        trade_pair: ["ETHUSD", "ETH/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 3383.5479999999993,
        close_ms: 1711232788689,
        current_return: 0.9969896999737262,
        initial_entry_price: 3387.37,
        is_closed_position: true,
        miner_hotkey: "5CaXrBFAaHZdMGKpD8mHCPxjZvX2uq9gRQjFEc2gWaqcwEfJ",
        net_leverage: 0.0,
        open_ms: 1711231583945,
        orders: [
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "d9cdb4df-cd08-4860-8d06-962662351eb4",
            price: 3387.37,
            price_sources: [],
            processed_ms: 1711231583942,
          },
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "9889e6b4-a31f-42a2-9cd7-412fde0725bb",
            price: 3389.49,
            price_sources: [],
            processed_ms: 1711231842747,
          },
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "77ad3460-2b07-43e4-b1e1-f4e096dee076",
            price: 3386.95,
            price_sources: [],
            processed_ms: 1711232072677,
          },
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "2db7e057-a24b-47c6-968b-154fb4237f88",
            price: 3379.72,
            price_sources: [],
            processed_ms: 1711232307788,
          },
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "457bfa29-eaf3-4d74-8c1c-e821df6414b6",
            price: 3374.21,
            price_sources: [],
            processed_ms: 1711232568583,
          },
          {
            leverage: 0.3,
            order_type: "FLAT",
            order_uuid: "4c7cb7e7-112f-4c47-9b45-b591f98eb866",
            price: 3376.75,
            price_sources: [],
            processed_ms: 1711232788689,
          },
        ],
        position_type: "FLAT",
        position_uuid: "ac11c27d-5b5e-4584-b65f-e6a5af7ead63",
        return_at_close: 0.9939987308738051,
        trade_pair: ["ETHUSD", "ETH/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 3387.294,
        close_ms: 1711234233695,
        current_return: 0.9972885298258768,
        initial_entry_price: 3387.83,
        is_closed_position: true,
        miner_hotkey: "5CaXrBFAaHZdMGKpD8mHCPxjZvX2uq9gRQjFEc2gWaqcwEfJ",
        net_leverage: 0.0,
        open_ms: 1711233029887,
        orders: [
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "41fa2b20-7f15-40a3-a0d6-f6ffa4852e79",
            price: 3387.83,
            price_sources: [],
            processed_ms: 1711233029884,
          },
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "e6867763-64ca-42c0-b2ef-f9685929b422",
            price: 3387.83,
            price_sources: [],
            processed_ms: 1711233289173,
          },
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "2a457631-4fa3-4a5a-83cc-aa24401752fc",
            price: 3394.69,
            price_sources: [],
            processed_ms: 1711233512274,
          },
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "1aed619b-a74a-42b0-b049-68c943c9207b",
            price: 3386.31,
            price_sources: [],
            processed_ms: 1711233752599,
          },
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "b9cecbcf-382f-44cf-ac14-60b5b1d80b8a",
            price: 3379.81,
            price_sources: [],
            processed_ms: 1711234012036,
          },
          {
            leverage: 0.3,
            order_type: "FLAT",
            order_uuid: "d16b886a-d979-4ef7-80db-d9d7be85eac8",
            price: 3381.17,
            price_sources: [],
            processed_ms: 1711234233695,
          },
        ],
        position_type: "FLAT",
        position_uuid: "aa6a1b0a-ade2-4b88-80dd-871d9fe57780",
        return_at_close: 0.9942966642363992,
        trade_pair: ["ETHUSD", "ETH/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 3379.1739999999995,
        close_ms: 1711235674937,
        current_return: 0.9974912966063412,
        initial_entry_price: 3386.61,
        is_closed_position: true,
        miner_hotkey: "5CaXrBFAaHZdMGKpD8mHCPxjZvX2uq9gRQjFEc2gWaqcwEfJ",
        net_leverage: 0.0,
        open_ms: 1711234468967,
        orders: [
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "d9822e40-6152-448a-a2e8-24dc01be9137",
            price: 3386.61,
            price_sources: [],
            processed_ms: 1711234468963,
          },
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "72392fc1-3bd3-4d81-a8ae-9cf99eeb6c04",
            price: 3385.48,
            price_sources: [],
            processed_ms: 1711234729871,
          },
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "b7038473-e56f-4e83-a240-a830476fcd8f",
            price: 3375.75,
            price_sources: [],
            processed_ms: 1711234949825,
          },
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "d1c43c63-bcb1-4e3a-8538-4e63bc9e42c9",
            price: 3374.52,
            price_sources: [],
            processed_ms: 1711235211351,
          },
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "68edcb87-c4ed-4758-873b-76a326bca776",
            price: 3373.51,
            price_sources: [],
            processed_ms: 1711235433908,
          },
          {
            leverage: 0.3,
            order_type: "FLAT",
            order_uuid: "a6992db4-ceb5-4de1-bd2d-3226e54f0d38",
            price: 3373.51,
            price_sources: [],
            processed_ms: 1711235674937,
          },
        ],
        position_type: "FLAT",
        position_uuid: "53edd8e9-57fa-4d13-a26d-f0cc810c3ca9",
        return_at_close: 0.9944988227165221,
        trade_pair: ["ETHUSD", "ETH/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 3364.27,
        close_ms: 1711237118214,
        current_return: 0.9990633641580685,
        initial_entry_price: 3363.1,
        is_closed_position: true,
        miner_hotkey: "5CaXrBFAaHZdMGKpD8mHCPxjZvX2uq9gRQjFEc2gWaqcwEfJ",
        net_leverage: 0.0,
        open_ms: 1711235933745,
        orders: [
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "f920ea5f-892b-41b6-a5f0-a6c3d25440b8",
            price: 3363.1,
            price_sources: [],
            processed_ms: 1711235933742,
          },
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "49353d7c-6d48-4007-8d10-41329af07cca",
            price: 3370.23,
            price_sources: [],
            processed_ms: 1711236155827,
          },
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "7e950ad1-c86a-4f96-adb3-cff6dc99e6cb",
            price: 3369.47,
            price_sources: [],
            processed_ms: 1711236395833,
          },
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "f04ebf6e-c71b-49ad-8ef6-e0d362978320",
            price: 3361.85,
            price_sources: [],
            processed_ms: 1711236654233,
          },
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "0c62276a-43ea-4bef-9044-fdd51b826f97",
            price: 3356.7,
            price_sources: [],
            processed_ms: 1711236877069,
          },
          {
            leverage: 0.3,
            order_type: "FLAT",
            order_uuid: "8f72c861-4371-4740-bf50-472b68e71037",
            price: 3362.17,
            price_sources: [],
            processed_ms: 1711237118214,
          },
        ],
        position_type: "FLAT",
        position_uuid: "a1be7b6e-3d14-4a46-ae34-f96cf5c6b3e9",
        return_at_close: 0.9960661740655943,
        trade_pair: ["ETHUSD", "ETH/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 3350.8579999999997,
        close_ms: 1711238551193,
        current_return: 0.9915609378719353,
        initial_entry_price: 3360.8,
        is_closed_position: true,
        miner_hotkey: "5CaXrBFAaHZdMGKpD8mHCPxjZvX2uq9gRQjFEc2gWaqcwEfJ",
        net_leverage: 0.0,
        open_ms: 1711237350748,
        orders: [
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "a3797caa-2d26-424f-a2d9-a9affd3d4ab1",
            price: 3360.8,
            price_sources: [],
            processed_ms: 1711237350729,
          },
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "a851dc2d-8f28-478b-bc70-9b21db65e40f",
            price: 3355.64,
            price_sources: [],
            processed_ms: 1711237609940,
          },
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "daafd79c-90aa-44cf-8f34-0aed7947e48c",
            price: 3355.35,
            price_sources: [],
            processed_ms: 1711237835722,
          },
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "4e081031-8c6e-4b4b-91a6-1dc00c06fdff",
            price: 3344.88,
            price_sources: [],
            processed_ms: 1711238094046,
          },
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "e600c965-66ae-4321-ba6e-53fcee6b4686",
            price: 3337.62,
            price_sources: [],
            processed_ms: 1711238316714,
          },
          {
            leverage: 0.3,
            order_type: "FLAT",
            order_uuid: "1ebc2fb2-8ecf-4f7f-b4e3-a15e304939bd",
            price: 3331.95,
            price_sources: [],
            processed_ms: 1711238551193,
          },
        ],
        position_type: "FLAT",
        position_uuid: "868d062a-feda-4937-bd3e-2db643dc1eb4",
        return_at_close: 0.9885862550583195,
        trade_pair: ["ETHUSD", "ETH/USD", 0.003, 0.001, 20],
      },
    ],
    thirty_day_returns: 1.0,
  },
  "5CcjdJmYfTLMdReYzj4oouXLM8rN6FXEM3wPifMVpffenVK4": {
    all_time_returns: 1.0,
    n_positions: 0,
    percentage_profitable: 0.0,
    positions: [
      {
        average_entry_price: 64460.02,
        close_ms: 1711195694254,
        current_return: 1.0180150425023138,
        initial_entry_price: 64460.02,
        is_closed_position: true,
        miner_hotkey: "5CcjdJmYfTLMdReYzj4oouXLM8rN6FXEM3wPifMVpffenVK4",
        net_leverage: 0.0,
        open_ms: 1711191152896,
        orders: [
          {
            leverage: 5.0,
            order_type: "LONG",
            order_uuid: "089673f7-3219-44d0-9bcf-130fec76e22b",
            price: 64460.02,
            price_sources: [],
            processed_ms: 1711191152895,
          },
          {
            leverage: 5.0,
            order_type: "FLAT",
            order_uuid: "ccaf4a12-70cf-4a09-a38b-1737d6487a19",
            price: 64692.27,
            price_sources: [],
            processed_ms: 1711195694254,
          },
        ],
        position_type: "FLAT",
        position_uuid: "01f189e3-f79d-4a72-b5a8-35d2a6bb5843",
        return_at_close: 1.0078348920772906,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 168.74001,
        close_ms: 1711197002056,
        current_return: 1.0,
        initial_entry_price: 168.74001,
        is_closed_position: true,
        miner_hotkey: "5CcjdJmYfTLMdReYzj4oouXLM8rN6FXEM3wPifMVpffenVK4",
        net_leverage: 0.0,
        open_ms: 1711196675115,
        orders: [
          {
            leverage: 0.1,
            order_type: "LONG",
            order_uuid: "8552b685-08ea-4b6f-840d-f6801b3580f2",
            price: 168.74001,
            price_sources: [],
            processed_ms: 1711196675114,
          },
          {
            leverage: 0.1,
            order_type: "FLAT",
            order_uuid: "774293c6-d99b-43e1-ad6f-34e2b4a368ba",
            price: 168.74001,
            price_sources: [],
            processed_ms: 1711197002056,
          },
        ],
        position_type: "FLAT",
        position_uuid: "b92554b5-c59d-4b27-b7da-521ff352effb",
        return_at_close: 0.999993,
        trade_pair: ["CHFJPY", "CHF/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 5234.27002,
        close_ms: 1711197559911,
        current_return: 1.0,
        initial_entry_price: 5234.27002,
        is_closed_position: true,
        miner_hotkey: "5CcjdJmYfTLMdReYzj4oouXLM8rN6FXEM3wPifMVpffenVK4",
        net_leverage: 0.0,
        open_ms: 1711197088904,
        orders: [
          {
            leverage: 0.1,
            order_type: "LONG",
            order_uuid: "79e44177-d2b7-41c6-9e83-0ed617cfa078",
            price: 5234.27002,
            price_sources: [],
            processed_ms: 1711197088904,
          },
          {
            leverage: 0.1,
            order_type: "FLAT",
            order_uuid: "5a7b3ee4-694a-4701-9cdb-26aa9bd964cc",
            price: 5234.27002,
            price_sources: [],
            processed_ms: 1711197559911,
          },
        ],
        position_type: "FLAT",
        position_uuid: "e77c6481-1006-4e7f-9dbf-f9fc5a074e56",
        return_at_close: 0.999991,
        trade_pair: ["SPX", "SPX", 9e-5, 0.001, 500],
      },
      {
        average_entry_price: 3363.8,
        close_ms: 1711256501698,
        current_return: 1.0614929543968137,
        initial_entry_price: 3363.8,
        is_closed_position: true,
        miner_hotkey: "5CcjdJmYfTLMdReYzj4oouXLM8rN6FXEM3wPifMVpffenVK4",
        net_leverage: 0.0,
        open_ms: 1711190818466,
        orders: [
          {
            leverage: -5.0,
            order_type: "SHORT",
            order_uuid: "8ed94ea9-4ff9-4819-9af0-46823d366fa8",
            price: 3363.8,
            price_sources: [],
            processed_ms: 1711190818466,
          },
          {
            leverage: 5.0,
            order_type: "FLAT",
            order_uuid: "1f0fd8c4-93fa-4bb6-b1d0-430644e2d7b3",
            price: 3322.43,
            price_sources: [],
            processed_ms: 1711256501698,
          },
        ],
        position_type: "FLAT",
        position_uuid: "f122e1d9-2310-413e-83a9-55b7836eae7e",
        return_at_close: 1.0508780248528455,
        trade_pair: ["ETHUSD", "ETH/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 3322.4299999999994,
        close_ms: 1711324815702,
        current_return: 0.7978437468960958,
        initial_entry_price: 3322.43,
        is_closed_position: true,
        miner_hotkey: "5CcjdJmYfTLMdReYzj4oouXLM8rN6FXEM3wPifMVpffenVK4",
        net_leverage: -5.0,
        open_ms: 1711256655973,
        orders: [
          {
            leverage: -5.0,
            order_type: "SHORT",
            order_uuid: "516e0a9a-b9e9-4a2a-9b21-28098c9b7186",
            price: 3322.43,
            price_sources: [],
            processed_ms: 1711256655972,
          },
        ],
        position_type: "FLAT",
        position_uuid: "4b22c014-d6be-4741-ad06-3001ce37c04d",
        return_at_close: 0.7898653094271348,
        trade_pair: ["ETHUSD", "ETH/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 64649.17,
        close_ms: 1711324815702,
        current_return: 1.1976637905792136,
        initial_entry_price: 64649.17,
        is_closed_position: true,
        miner_hotkey: "5CcjdJmYfTLMdReYzj4oouXLM8rN6FXEM3wPifMVpffenVK4",
        net_leverage: 5.0,
        open_ms: 1711196029038,
        orders: [
          {
            leverage: 5.0,
            order_type: "LONG",
            order_uuid: "1a1f7738-ed76-416f-aefd-344fda847de7",
            price: 64649.17,
            price_sources: [],
            processed_ms: 1711196029038,
          },
        ],
        position_type: "FLAT",
        position_uuid: "4a3a8875-b79c-4af5-8fa8-fbc93d8758fa",
        return_at_close: 1.1856871526734214,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
    ],
    thirty_day_returns: 1.0,
  },
  "5CcsBjaLAVfrjsAh6FyaTK4rBikkfQVanEmespwVpDGcE7jP": {
    all_time_returns: 1.0431152906917385,
    n_positions: 5,
    percentage_profitable: 1.0,
    positions: [
      {
        average_entry_price: 3439.9115899581598,
        close_ms: 1718400692816,
        current_return: 1.0366986968645806,
        initial_entry_price: 3116.33,
        is_closed_position: true,
        miner_hotkey: "5CcsBjaLAVfrjsAh6FyaTK4rBikkfQVanEmespwVpDGcE7jP",
        net_leverage: 0.0,
        open_ms: 1716049317132,
        orders: [
          {
            leverage: 0.001,
            order_type: "LONG",
            order_uuid: "4f16e37d-eb21-41d1-8da4-293c0555e277",
            price: 3116.33,
            price_sources: [],
            processed_ms: 1716049315600,
          },
          {
            leverage: 0.15,
            order_type: "LONG",
            order_uuid: "f9176b64-6184-4aa2-b674-cad2f35252c6",
            price: 3763.69,
            price_sources: [],
            processed_ms: 1716634974939,
          },
          {
            leverage: 1.0,
            order_type: "LONG",
            order_uuid: "c0791803-583f-4060-9888-49177c847e89",
            price: 3413.28,
            price_sources: [],
            processed_ms: 1718398893317,
          },
          {
            leverage: 1.0,
            order_type: "LONG",
            order_uuid: "35a3c720-ac85-43a8-9643-f660c66cceee",
            price: 3418.3,
            price_sources: [],
            processed_ms: 1718398972138,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "afc5855c-5d07-46c5-b5e2-bec4b027c018",
            price: 3493.08,
            price_sources: [],
            processed_ms: 1718400692816,
          },
        ],
        position_type: "FLAT",
        position_uuid: "861da01b-cc13-4dd2-92ce-967e9889424c",
        return_at_close: 1.0344687579676248,
        trade_pair: ["ETHUSD", "ETH/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 3470.665,
        close_ms: 1718407945124,
        current_return: 1.0037809501278108,
        initial_entry_price: 3466.06,
        is_closed_position: true,
        miner_hotkey: "5CcsBjaLAVfrjsAh6FyaTK4rBikkfQVanEmespwVpDGcE7jP",
        net_leverage: 0.0,
        open_ms: 1718404309258,
        orders: [
          {
            leverage: 0.5,
            order_type: "LONG",
            order_uuid: "7e097958-80dc-4bfd-9d04-6e229acd363a",
            price: 3466.06,
            price_sources: [],
            processed_ms: 1718404308913,
          },
          {
            leverage: 0.5,
            order_type: "LONG",
            order_uuid: "f278bbe0-cbbf-43ed-8a75-cc9ec70e645d",
            price: 3475.27,
            price_sources: [],
            processed_ms: 1718404859928,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "53e9b87c-8a0f-4a2c-900d-f31bacb19893",
            price: 3483.77,
            price_sources: [],
            processed_ms: 1718407945124,
          },
        ],
        position_type: "FLAT",
        position_uuid: "cda8503b-cdaf-49c6-bafa-dd36ebe0223e",
        return_at_close: 1.002777169177683,
        trade_pair: ["ETHUSD", "ETH/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 156.416,
        close_ms: 1718625671840,
        current_return: 1.0000911991100654,
        initial_entry_price: 156.416,
        is_closed_position: true,
        miner_hotkey: "5CcsBjaLAVfrjsAh6FyaTK4rBikkfQVanEmespwVpDGcE7jP",
        net_leverage: 0.0,
        open_ms: 1715713489124,
        orders: [
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "77551b6e-8624-428c-8157-8cd112c19b93",
            price: 156.416,
            price_sources: [],
            processed_ms: 1715713488103,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "91553aab-efdf-46e2-b9c7-7dd581fe83f1",
            price: 157.8425,
            price_sources: [],
            processed_ms: 1718625671840,
          },
        ],
        position_type: "FLAT",
        position_uuid: "453ce206-3754-462f-aa6f-4018f89f07a8",
        return_at_close: 1.000090499046226,
        trade_pair: ["USDJPY", "USD/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 114.41221428571428,
        close_ms: 1718625698723,
        current_return: 1.0048648601429147,
        initial_entry_price: 114.474,
        is_closed_position: true,
        miner_hotkey: "5CcsBjaLAVfrjsAh6FyaTK4rBikkfQVanEmespwVpDGcE7jP",
        net_leverage: 0.0,
        open_ms: 1716161857874,
        orders: [
          {
            leverage: 0.4,
            order_type: "LONG",
            order_uuid: "762c6a7c-59e8-41fa-891f-dc0de4fb88ae",
            price: 114.474,
            price_sources: [],
            processed_ms: 1716161857859,
          },
          {
            leverage: 1.0,
            order_type: "LONG",
            order_uuid: "e0f8d79a-7b02-4a95-9ed9-4627fd1edd38",
            price: 114.3875,
            price_sources: [],
            processed_ms: 1716302943674,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "0241e430-e43c-4ac4-88b2-ecd82de7386f",
            price: 114.81,
            price_sources: [],
            processed_ms: 1718625698723,
          },
        ],
        position_type: "FLAT",
        position_uuid: "4530e6f3-f4ab-4347-a20f-3943d3ee82d6",
        return_at_close: 1.0047663833866205,
        trade_pair: ["CADJPY", "CAD/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 61420.76,
        close_ms: 1719560690920,
        current_return: 1.0027104842076195,
        initial_entry_price: 61420.76,
        is_closed_position: true,
        miner_hotkey: "5CcsBjaLAVfrjsAh6FyaTK4rBikkfQVanEmespwVpDGcE7jP",
        net_leverage: 0.0,
        open_ms: 1719559760072,
        orders: [
          {
            leverage: 2.0,
            order_type: "LONG",
            order_uuid: "db1b2b34-c1e1-46b0-b4ed-18cadf39662e",
            price: 61420.76,
            price_sources: [],
            processed_ms: 1719559759629,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "b6ebc982-9ac3-4c48-bdf3-7f5a24dcd8ff",
            price: 61504.0,
            price_sources: [],
            processed_ms: 1719560690920,
          },
        ],
        position_type: "FLAT",
        position_uuid: "e49a923a-c7d9-4a19-b440-9beb2622d5c0",
        return_at_close: 1.0007050632392043,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 3426.8416666666667,
        close_ms: 0,
        current_return: 1.0144160672527602,
        initial_entry_price: 3378.3,
        is_closed_position: false,
        miner_hotkey: "5CcsBjaLAVfrjsAh6FyaTK4rBikkfQVanEmespwVpDGcE7jP",
        net_leverage: -0.168,
        open_ms: 1719601338208,
        orders: [
          {
            leverage: -0.068,
            order_type: "SHORT",
            order_uuid: "32ac2f5e-54a9-4a83-ba71-066f0f990d11",
            price: 3378.3,
            price_sources: [],
            processed_ms: 1719601336692,
          },
          {
            leverage: -0.1,
            order_type: "SHORT",
            order_uuid: "6b0b81f3-eb4c-41ef-b5ea-03e0fd11eaff",
            price: 3459.85,
            price_sources: [],
            processed_ms: 1719834643293,
          },
        ],
        position_type: "SHORT",
        position_uuid: "2fb20ad4-5454-4794-a115-4a227916e9c6",
        return_at_close: 1.014330856303111,
        trade_pair: ["ETHUSD", "ETH/USD", 0.003, 0.001, 20],
      },
    ],
    thirty_day_returns: 1.0034841904967835,
  },
  "5Cd9bVVja2KdgsTiR7rTAh7a4UKVfnAuYAW1bs8BiedUE9JN": {
    all_time_returns: 1.0424569708737264,
    n_positions: 57,
    percentage_profitable: 0.8070175438596491,
    positions: [
      {
        average_entry_price: 0.66792,
        close_ms: 1716278142623,
        current_return: 1.0024553838783088,
        initial_entry_price: 0.66792,
        is_closed_position: true,
        miner_hotkey: "5Cd9bVVja2KdgsTiR7rTAh7a4UKVfnAuYAW1bs8BiedUE9JN",
        net_leverage: 0.0,
        open_ms: 1716207025887,
        orders: [
          {
            leverage: -1.0,
            order_type: "SHORT",
            order_uuid: "a2ac9e2d-0f85-4fba-ad4e-9b8d0fc16d1c",
            price: 0.66792,
            price_sources: [],
            processed_ms: 1716207025876,
          },
          {
            leverage: 0.01,
            order_type: "FLAT",
            order_uuid: "71ed6107-3636-489c-818f-0e6fdb628095",
            price: 0.66628,
            price_sources: [],
            processed_ms: 1716278142623,
          },
        ],
        position_type: "FLAT",
        position_uuid: "c3abfdaf-3b50-4fac-b348-a565ebf2ed42",
        return_at_close: 1.0023852120014374,
        trade_pair: ["AUDUSD", "AUD/USD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.908712420581745,
        close_ms: 1716279874900,
        current_return: 1.0003052510362194,
        initial_entry_price: 0.908712420581745,
        is_closed_position: true,
        miner_hotkey: "5Cd9bVVja2KdgsTiR7rTAh7a4UKVfnAuYAW1bs8BiedUE9JN",
        net_leverage: 0.0,
        open_ms: 1716210827447,
        orders: [
          {
            leverage: -1.0,
            order_type: "SHORT",
            order_uuid: "8e23763d-0759-4572-9840-267f76f7bf76",
            price: 0.908712420581745,
            price_sources: [],
            processed_ms: 1716210825312,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "fee220a2-af1a-49d0-b88c-16411cdd83a8",
            price: 0.908435035173737,
            price_sources: [],
            processed_ms: 1716279874900,
          },
        ],
        position_type: "FLAT",
        position_uuid: "b89444f1-508f-4f82-840c-0808eda986af",
        return_at_close: 1.0002352296686468,
        trade_pair: ["AUDCAD", "AUD/CAD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.83231,
        close_ms: 1716282039134,
        current_return: 1.0005827421536073,
        initial_entry_price: 0.83231,
        is_closed_position: true,
        miner_hotkey: "5Cd9bVVja2KdgsTiR7rTAh7a4UKVfnAuYAW1bs8BiedUE9JN",
        net_leverage: 0.0,
        open_ms: 1716207195016,
        orders: [
          {
            leverage: -1.0,
            order_type: "SHORT",
            order_uuid: "ff2349f9-50d9-4933-8aca-b952b7a44665",
            price: 0.83231,
            price_sources: [],
            processed_ms: 1716207195001,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "c037a0f8-0f8f-4ecd-9189-cdc82b894cfa",
            price: 0.831824977878131,
            price_sources: [],
            processed_ms: 1716282039134,
          },
        ],
        position_type: "FLAT",
        position_uuid: "fdcf1b2d-27dd-4376-8590-9b1404ce910c",
        return_at_close: 1.0005127013616566,
        trade_pair: ["NZDCAD", "NZD/CAD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 169.72,
        close_ms: 1716297240588,
        current_return: 1.0009014847984916,
        initial_entry_price: 169.72,
        is_closed_position: true,
        miner_hotkey: "5Cd9bVVja2KdgsTiR7rTAh7a4UKVfnAuYAW1bs8BiedUE9JN",
        net_leverage: 0.0,
        open_ms: 1716278224335,
        orders: [
          {
            leverage: -1.0,
            order_type: "SHORT",
            order_uuid: "3fbca999-3719-4cf4-8a77-43d5b2148a5f",
            price: 169.72,
            price_sources: [],
            processed_ms: 1716278224316,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "1ffbe21e-9c8f-47a6-9460-4e7322dcfa27",
            price: 169.567,
            price_sources: [],
            processed_ms: 1716297240588,
          },
        ],
        position_type: "FLAT",
        position_uuid: "2b281952-46d4-476e-a3a2-4bcb3c3ce3a5",
        return_at_close: 1.0008314216945557,
        trade_pair: ["EURJPY", "EUR/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 156.161,
        close_ms: 1716301095290,
        current_return: 1.000461062621269,
        initial_entry_price: 156.161,
        is_closed_position: true,
        miner_hotkey: "5Cd9bVVja2KdgsTiR7rTAh7a4UKVfnAuYAW1bs8BiedUE9JN",
        net_leverage: 0.0,
        open_ms: 1716279921746,
        orders: [
          {
            leverage: -1.0,
            order_type: "SHORT",
            order_uuid: "e08ba1f8-5018-4668-825b-ab7593738367",
            price: 156.161,
            price_sources: [],
            processed_ms: 1716279921731,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "04f66bf3-2810-40a4-8cdc-70c099a55433",
            price: 156.089,
            price_sources: [],
            processed_ms: 1716301095290,
          },
        ],
        position_type: "FLAT",
        position_uuid: "3ee32034-7e03-4fb9-98db-078cf21ccc04",
        return_at_close: 1.0003910303468857,
        trade_pair: ["USDJPY", "USD/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 1.08606,
        close_ms: 1716301302052,
        current_return: 1.0006077012319763,
        initial_entry_price: 1.08606,
        is_closed_position: true,
        miner_hotkey: "5Cd9bVVja2KdgsTiR7rTAh7a4UKVfnAuYAW1bs8BiedUE9JN",
        net_leverage: 0.0,
        open_ms: 1716206988743,
        orders: [
          {
            leverage: -1.0,
            order_type: "SHORT",
            order_uuid: "1267d0ae-6031-427e-afc2-b2a08c109f46",
            price: 1.08606,
            price_sources: [],
            processed_ms: 1716206988729,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "ae2995e6-fa5a-436c-b3da-250cf1a120ae",
            price: 1.0854,
            price_sources: [],
            processed_ms: 1716301302052,
          },
        ],
        position_type: "FLAT",
        position_uuid: "c2fcf7fe-286e-433f-a66c-245a7baebcc2",
        return_at_close: 1.00053765869289,
        trade_pair: ["EURUSD", "EUR/USD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.9096,
        close_ms: 1716301351182,
        current_return: 1.0008795074758137,
        initial_entry_price: 0.9096,
        is_closed_position: true,
        miner_hotkey: "5Cd9bVVja2KdgsTiR7rTAh7a4UKVfnAuYAW1bs8BiedUE9JN",
        net_leverage: 0.0,
        open_ms: 1716207051930,
        orders: [
          {
            leverage: 1.0,
            order_type: "LONG",
            order_uuid: "91d5ea4f-8a3f-40d0-8e1d-a63bd92d6e90",
            price: 0.9096,
            price_sources: [],
            processed_ms: 1716207051923,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "392fca18-da28-4f34-961d-dc0a1f921a0b",
            price: 0.9104,
            price_sources: [],
            processed_ms: 1716301351182,
          },
        ],
        position_type: "FLAT",
        position_uuid: "9d5efaa4-75da-4990-bed3-448da16b0f93",
        return_at_close: 1.0008094459102903,
        trade_pair: ["USDCHF", "USD/CHF", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.8329410938261975,
        close_ms: 1716302133333,
        current_return: 1.0008047113383116,
        initial_entry_price: 0.8329410938261975,
        is_closed_position: true,
        miner_hotkey: "5Cd9bVVja2KdgsTiR7rTAh7a4UKVfnAuYAW1bs8BiedUE9JN",
        net_leverage: 0.0,
        open_ms: 1716297307387,
        orders: [
          {
            leverage: -1.0,
            order_type: "SHORT",
            order_uuid: "b3310728-6550-431a-8cf7-4c7dd08fb1ab",
            price: 0.8329410938261975,
            price_sources: [],
            processed_ms: 1716297307370,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "d78d5ed5-e885-4caa-a189-f0dbb1139569",
            price: 0.83227081668385,
            price_sources: [],
            processed_ms: 1716302133333,
          },
        ],
        position_type: "FLAT",
        position_uuid: "5a4831c3-0a98-47fc-9d23-3478194a0c2e",
        return_at_close: 1.0007346550085179,
        trade_pair: ["NZDCAD", "NZD/CAD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.9110878229521331,
        close_ms: 1716302191954,
        current_return: 1.0005134773403264,
        initial_entry_price: 0.9110878229521331,
        is_closed_position: true,
        miner_hotkey: "5Cd9bVVja2KdgsTiR7rTAh7a4UKVfnAuYAW1bs8BiedUE9JN",
        net_leverage: 0.0,
        open_ms: 1716301207638,
        orders: [
          {
            leverage: -1.0,
            order_type: "SHORT",
            order_uuid: "bfa9bc93-4982-401d-8bd8-7bfd82f31b22",
            price: 0.9110878229521331,
            price_sources: [],
            processed_ms: 1716301207608,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "7546d888-d501-4869-bcf0-e0fb30f405d7",
            price: 0.91062,
            price_sources: [],
            processed_ms: 1716302191954,
          },
        ],
        position_type: "FLAT",
        position_uuid: "89532865-a3bc-4dab-b5f6-da2d9453a0bb",
        return_at_close: 1.0004434413969125,
        trade_pair: ["AUDCAD", "AUD/CAD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 1.3121899999999633,
        close_ms: 1716324458989,
        current_return: 1.0003268823302376,
        initial_entry_price: 1.26957,
        is_closed_position: true,
        miner_hotkey: "5Cd9bVVja2KdgsTiR7rTAh7a4UKVfnAuYAW1bs8BiedUE9JN",
        net_leverage: 0.0,
        open_ms: 1716207036684,
        orders: [
          {
            leverage: -1.0,
            order_type: "SHORT",
            order_uuid: "84e132ec-b104-487c-8189-215547cd43f5",
            price: 1.26957,
            price_sources: [],
            processed_ms: 1716207036673,
          },
          {
            leverage: -1.0,
            order_type: "SHORT",
            order_uuid: "5f3c00b5-f5d6-4826-820b-be13812e5634",
            price: 1.27111,
            price_sources: [],
            processed_ms: 1716231171135,
          },
          {
            leverage: -1.0,
            order_type: "SHORT",
            order_uuid: "58208688-81c2-47c2-9aa1-3c6772f12fa9",
            price: 1.27156,
            price_sources: [],
            processed_ms: 1716301245926,
          },
          {
            leverage: 2.99,
            order_type: "LONG",
            order_uuid: "39458b05-1255-4ae1-966c-073965c66c59",
            price: 1.27069,
            price_sources: [],
            processed_ms: 1716312745723,
          },
          {
            leverage: -1.0,
            order_type: "SHORT",
            order_uuid: "0e008561-171e-4350-b467-b846d8308ca2",
            price: 1.270945,
            price_sources: [],
            processed_ms: 1716312947428,
          },
          {
            leverage: 1.0,
            order_type: "LONG",
            order_uuid: "695670e6-1940-434e-9125-19cda8d39556",
            price: 1.2707,
            price_sources: [],
            processed_ms: 1716324314172,
          },
          {
            leverage: 0.01,
            order_type: "FLAT",
            order_uuid: "b30df7c3-634d-43c2-9dd7-19321d028cb9",
            price: 1.27069,
            price_sources: [],
            processed_ms: 1716324458989,
          },
        ],
        position_type: "FLAT",
        position_uuid: "a96aef21-6d1a-46f6-9f71-ab84dacfba83",
        return_at_close: 1.0000467908031851,
        trade_pair: ["GBPUSD", "GBP/USD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.90989,
        close_ms: 1716362384614,
        current_return: 1.0005575686361186,
        initial_entry_price: 0.90989,
        is_closed_position: true,
        miner_hotkey: "5Cd9bVVja2KdgsTiR7rTAh7a4UKVfnAuYAW1bs8BiedUE9JN",
        net_leverage: 0.0,
        open_ms: 1716318786015,
        orders: [
          {
            leverage: -1.0,
            order_type: "SHORT",
            order_uuid: "2e81476a-9985-4219-974b-7da9b9860866",
            price: 0.90989,
            price_sources: [],
            processed_ms: 1716318785989,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "60e29d64-2db6-4231-8b82-98d12021db1a",
            price: 0.909382673873682,
            price_sources: [],
            processed_ms: 1716362384614,
          },
        ],
        position_type: "FLAT",
        position_uuid: "8a2f1b80-c112-4aab-b073-087c74b9e000",
        return_at_close: 1.000487529606314,
        trade_pair: ["AUDCAD", "AUD/CAD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 1.2742,
        close_ms: 1716374662252,
        current_return: 1.0012635379061372,
        initial_entry_price: 1.2742,
        is_closed_position: true,
        miner_hotkey: "5Cd9bVVja2KdgsTiR7rTAh7a4UKVfnAuYAW1bs8BiedUE9JN",
        net_leverage: 0.0,
        open_ms: 1716362420934,
        orders: [
          {
            leverage: -1.0,
            order_type: "SHORT",
            order_uuid: "7e8831ec-520c-47d9-8697-e59277803775",
            price: 1.2742,
            price_sources: [],
            processed_ms: 1716362420914,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "813204c0-4d32-4f55-84b2-089a7dc8c64f",
            price: 1.27259,
            price_sources: [],
            processed_ms: 1716374662252,
          },
        ],
        position_type: "FLAT",
        position_uuid: "b52212b8-3b3b-4858-a87e-aa50fb0179fd",
        return_at_close: 1.0011934494584838,
        trade_pair: ["GBPUSD", "GBP/USD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 95.745,
        close_ms: 1716378459183,
        current_return: 1.0013786620711265,
        initial_entry_price: 95.745,
        is_closed_position: true,
        miner_hotkey: "5Cd9bVVja2KdgsTiR7rTAh7a4UKVfnAuYAW1bs8BiedUE9JN",
        net_leverage: 0.0,
        open_ms: 1716374876824,
        orders: [
          {
            leverage: -1.0,
            order_type: "SHORT",
            order_uuid: "50da6881-9619-4b6f-b980-5cd1460caaab",
            price: 95.745,
            price_sources: [],
            processed_ms: 1716374876800,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "c1fdf7d0-dada-47a7-b973-1836a274942e",
            price: 95.613,
            price_sources: [],
            processed_ms: 1716378459183,
          },
        ],
        position_type: "FLAT",
        position_uuid: "8d7b9c51-3ebf-4987-ba7e-11c50837cb92",
        return_at_close: 1.0013085655647815,
        trade_pair: ["NZDJPY", "NZD/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 1.27291,
        close_ms: 1716403140528,
        current_return: 1.0022171728687228,
        initial_entry_price: 1.27189,
        is_closed_position: true,
        miner_hotkey: "5Cd9bVVja2KdgsTiR7rTAh7a4UKVfnAuYAW1bs8BiedUE9JN",
        net_leverage: 0.0,
        open_ms: 1716380403970,
        orders: [
          {
            leverage: -1.0,
            order_type: "SHORT",
            order_uuid: "cb5cff90-717d-4aab-8356-c1f9a9de3930",
            price: 1.27189,
            price_sources: [],
            processed_ms: 1716380403830,
          },
          {
            leverage: -1.0,
            order_type: "SHORT",
            order_uuid: "b3d34037-7f90-4897-b387-aa7c9246f302",
            price: 1.27393,
            price_sources: [],
            processed_ms: 1716386558799,
          },
          {
            leverage: 0.01,
            order_type: "FLAT",
            order_uuid: "82a9b278-3dfe-4a02-9118-a5aeabe405f7",
            price: 1.2715,
            price_sources: [],
            processed_ms: 1716403140528,
          },
        ],
        position_type: "FLAT",
        position_uuid: "15fb3abe-a9e6-4710-bc8f-cfe8ff23e3e2",
        return_at_close: 1.002076862464521,
        trade_pair: ["GBPUSD", "GBP/USD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 1.08345,
        close_ms: 1716403233922,
        current_return: 1.0011906410078915,
        initial_entry_price: 1.08345,
        is_closed_position: true,
        miner_hotkey: "5Cd9bVVja2KdgsTiR7rTAh7a4UKVfnAuYAW1bs8BiedUE9JN",
        net_leverage: 0.0,
        open_ms: 1716386589759,
        orders: [
          {
            leverage: -1.0,
            order_type: "SHORT",
            order_uuid: "049b024f-fbad-4e77-9283-9ae67b912303",
            price: 1.08345,
            price_sources: [],
            processed_ms: 1716386589722,
          },
          {
            leverage: 0.01,
            order_type: "FLAT",
            order_uuid: "fbd09c80-c807-4fb4-a789-9c0c2f6dcf23",
            price: 1.08216,
            price_sources: [],
            processed_ms: 1716403233922,
          },
        ],
        position_type: "FLAT",
        position_uuid: "c430fae1-f373-40dc-ad81-b1dbcf9c706f",
        return_at_close: 1.0011205576630209,
        trade_pair: ["EURUSD", "EUR/USD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 1.082005,
        close_ms: 1716484283260,
        current_return: 1.000295747247009,
        initial_entry_price: 1.082005,
        is_closed_position: true,
        miner_hotkey: "5Cd9bVVja2KdgsTiR7rTAh7a4UKVfnAuYAW1bs8BiedUE9JN",
        net_leverage: 0.0,
        open_ms: 1716404905110,
        orders: [
          {
            leverage: -1.0,
            order_type: "SHORT",
            order_uuid: "bd1d4c81-d4c0-4b36-81e9-fc4f7ab2190a",
            price: 1.082005,
            price_sources: [],
            processed_ms: 1716404905078,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "24c3ed57-2d04-4644-a189-cc6569bf3b54",
            price: 1.081685,
            price_sources: [],
            processed_ms: 1716484283260,
          },
        ],
        position_type: "FLAT",
        position_uuid: "86599c6a-c2dd-4e6a-a588-eb007151f1e1",
        return_at_close: 1.0002257265447019,
        trade_pair: ["EURUSD", "EUR/USD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 1.273955,
        close_ms: 1716525823937,
        current_return: 1.0018818874721749,
        initial_entry_price: 1.27133,
        is_closed_position: true,
        miner_hotkey: "5Cd9bVVja2KdgsTiR7rTAh7a4UKVfnAuYAW1bs8BiedUE9JN",
        net_leverage: 0.0,
        open_ms: 1716404935504,
        orders: [
          {
            leverage: -1.0,
            order_type: "SHORT",
            order_uuid: "8e064c99-f850-4eda-af8b-eaf9bd5a4ea1",
            price: 1.27133,
            price_sources: [],
            processed_ms: 1716404935477,
          },
          {
            leverage: 0.5,
            order_type: "LONG",
            order_uuid: "5ca6974d-1505-4075-a477-27b4688c9a4b",
            price: 1.2687050000000002,
            price_sources: [],
            processed_ms: 1716490617186,
          },
          {
            leverage: 0.01,
            order_type: "FLAT",
            order_uuid: "dc6adb8b-67ca-406e-8440-acc4a48a1ab1",
            price: 1.26917,
            price_sources: [],
            processed_ms: 1716525823937,
          },
        ],
        position_type: "FLAT",
        position_uuid: "9242859a-8681-4ecb-8459-e4d58495ac64",
        return_at_close: 1.0018117557400519,
        trade_pair: ["GBPUSD", "GBP/USD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.837676274350295,
        close_ms: 1716558016426,
        current_return: 1.0012665289235891,
        initial_entry_price: 0.83167,
        is_closed_position: true,
        miner_hotkey: "5Cd9bVVja2KdgsTiR7rTAh7a4UKVfnAuYAW1bs8BiedUE9JN",
        net_leverage: 0.0,
        open_ms: 1716313753631,
        orders: [
          {
            leverage: -1.0,
            order_type: "SHORT",
            order_uuid: "5b8015f4-6b5d-425e-b676-90496e9a3437",
            price: 0.83167,
            price_sources: [],
            processed_ms: 1716313753569,
          },
          {
            leverage: -2.0,
            order_type: "SHORT",
            order_uuid: "0fcddd65-a2f1-4607-800e-f658d78d6780",
            price: 0.8344,
            price_sources: [],
            processed_ms: 1716362458187,
          },
          {
            leverage: 2.99,
            order_type: "LONG",
            order_uuid: "e6b64b5d-f709-4fa3-a1d7-56067468dc08",
            price: 0.836258579975774,
            price_sources: [],
            processed_ms: 1716386507245,
          },
          {
            leverage: -1.0,
            order_type: "SHORT",
            order_uuid: "9e1cd822-a8b2-4443-b99a-0e1bf864bacd",
            price: 0.8358776758600455,
            price_sources: [],
            processed_ms: 1716387040326,
          },
          {
            leverage: -4.0,
            order_type: "SHORT",
            order_uuid: "e64608d9-0b10-4a1f-ab74-ac830a55c418",
            price: 0.837563576199522,
            price_sources: [],
            processed_ms: 1716458943241,
          },
          {
            leverage: 3.0,
            order_type: "LONG",
            order_uuid: "2a3c4efd-0bcb-4c9f-a214-9dbe4a73594e",
            price: 0.836397159997798,
            price_sources: [],
            processed_ms: 1716471962667,
          },
          {
            leverage: -4.0,
            order_type: "SHORT",
            order_uuid: "30dd49ee-15d7-4ffa-b35d-dbff78b23675",
            price: 0.838168960711928,
            price_sources: [],
            processed_ms: 1716555118106,
          },
          {
            leverage: 5.0,
            order_type: "LONG",
            order_uuid: "1a38413f-37ff-427a-af99-d6218369b55a",
            price: 0.836724030458218,
            price_sources: [],
            processed_ms: 1716557881770,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "1d79f9db-6fee-4978-8cbc-2983ac5327d1",
            price: 0.8366333692910065,
            price_sources: [],
            processed_ms: 1716558016426,
          },
        ],
        position_type: "FLAT",
        position_uuid: "5a9cf2da-815b-4682-893b-0fc3adf50287",
        return_at_close: 1.0004254650392934,
        trade_pair: ["NZDCAD", "NZD/CAD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.666185,
        close_ms: 1716885410198,
        current_return: 1.0013358749981238,
        initial_entry_price: 0.66623,
        is_closed_position: true,
        miner_hotkey: "5Cd9bVVja2KdgsTiR7rTAh7a4UKVfnAuYAW1bs8BiedUE9JN",
        net_leverage: 0.0,
        open_ms: 1716883160779,
        orders: [
          {
            leverage: -1.0,
            order_type: "SHORT",
            order_uuid: "34bd9561-e983-414c-8265-69febb9a023d",
            price: 0.66623,
            price_sources: [],
            processed_ms: 1716883160771,
          },
          {
            leverage: -1.0,
            order_type: "SHORT",
            order_uuid: "bc299771-94c2-4a71-b146-09f45e8eb582",
            price: 0.66614,
            price_sources: [],
            processed_ms: 1716884128577,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "e7957b13-b4e6-4e81-84c7-15bf50ffb099",
            price: 0.66574,
            price_sources: [],
            processed_ms: 1716885410198,
          },
        ],
        position_type: "FLAT",
        position_uuid: "be5a6f38-066a-42e4-93ad-36cdc45301df",
        return_at_close: 1.001195687975624,
        trade_pair: ["AUDUSD", "AUD/USD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 1.0873766666666667,
        close_ms: 1716921388506,
        current_return: 1.0047209708559341,
        initial_entry_price: 1.0877,
        is_closed_position: true,
        miner_hotkey: "5Cd9bVVja2KdgsTiR7rTAh7a4UKVfnAuYAW1bs8BiedUE9JN",
        net_leverage: 0.0,
        open_ms: 1716883276304,
        orders: [
          {
            leverage: -1.0,
            order_type: "SHORT",
            order_uuid: "348a503e-933b-43e7-a054-6bc6762344c3",
            price: 1.0877,
            price_sources: [],
            processed_ms: 1716883276300,
          },
          {
            leverage: -2.0,
            order_type: "SHORT",
            order_uuid: "517e6805-8488-4e60-802e-24a6aa47d5b2",
            price: 1.087215,
            price_sources: [],
            processed_ms: 1716916883014,
          },
          {
            leverage: 0.01,
            order_type: "FLAT",
            order_uuid: "1b538f00-b2cc-418a-915e-5e04640e3f14",
            price: 1.085665,
            price_sources: [],
            processed_ms: 1716921388506,
          },
        ],
        position_type: "FLAT",
        position_uuid: "ffdc9bf1-447f-47ca-826b-08bec139a636",
        return_at_close: 1.0045099794520544,
        trade_pair: ["EURUSD", "EUR/USD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 1.277855,
        close_ms: 1716921455586,
        current_return: 1.0037503621174277,
        initial_entry_price: 1.27721,
        is_closed_position: true,
        miner_hotkey: "5Cd9bVVja2KdgsTiR7rTAh7a4UKVfnAuYAW1bs8BiedUE9JN",
        net_leverage: 0.0,
        open_ms: 1716883247158,
        orders: [
          {
            leverage: -1.0,
            order_type: "SHORT",
            order_uuid: "f873c079-27e4-463b-a70f-f81fd23262bd",
            price: 1.27721,
            price_sources: [],
            processed_ms: 1716883246241,
          },
          {
            leverage: -1.0,
            order_type: "SHORT",
            order_uuid: "41bb7f77-45fb-4f01-b9dd-347a60ee1254",
            price: 1.2785,
            price_sources: [],
            processed_ms: 1716907742417,
          },
          {
            leverage: 0.01,
            order_type: "FLAT",
            order_uuid: "2e636d13-39b0-42c4-a21f-efa087d0e71d",
            price: 1.27546,
            price_sources: [],
            processed_ms: 1716921455586,
          },
        ],
        position_type: "FLAT",
        position_uuid: "96ed239f-a406-4741-b0f8-3d429398a173",
        return_at_close: 1.0036098370667312,
        trade_pair: ["GBPUSD", "GBP/USD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.6657,
        close_ms: 1716960222686,
        current_return: 1.0016523959741626,
        initial_entry_price: 0.6657,
        is_closed_position: true,
        miner_hotkey: "5Cd9bVVja2KdgsTiR7rTAh7a4UKVfnAuYAW1bs8BiedUE9JN",
        net_leverage: 0.0,
        open_ms: 1716885469482,
        orders: [
          {
            leverage: -1.0,
            order_type: "SHORT",
            order_uuid: "b8e0e513-638d-4b00-8b40-0b58a830f14f",
            price: 0.6657,
            price_sources: [],
            processed_ms: 1716885469475,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "59f48ff0-211c-4ae4-bdf1-1839590cfa3e",
            price: 0.6646,
            price_sources: [],
            processed_ms: 1716960222686,
          },
        ],
        position_type: "FLAT",
        position_uuid: "4e79fbf4-e07a-49e5-b591-379196b20b04",
        return_at_close: 1.0015822803064443,
        trade_pair: ["AUDUSD", "AUD/USD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.989225,
        close_ms: 1717051807727,
        current_return: 1.0058884480274963,
        initial_entry_price: 0.989225,
        is_closed_position: true,
        miner_hotkey: "5Cd9bVVja2KdgsTiR7rTAh7a4UKVfnAuYAW1bs8BiedUE9JN",
        net_leverage: 0.0,
        open_ms: 1716527994796,
        orders: [
          {
            leverage: -1.0,
            order_type: "SHORT",
            order_uuid: "30dc5f09-b9c1-4f98-9c10-e253345cd2c5",
            price: 0.989225,
            price_sources: [],
            processed_ms: 1716527994789,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "fdaa438c-cde5-44b0-832c-c8bb32071d03",
            price: 0.9834,
            price_sources: [],
            processed_ms: 1717051807727,
          },
        ],
        position_type: "FLAT",
        position_uuid: "a8a87c9e-a8c9-437a-b35c-51bdcf33fe1f",
        return_at_close: 1.0058180358361344,
        trade_pair: ["EURCHF", "EUR/CHF", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 1.4827,
        close_ms: 1717160014841,
        current_return: 1.0005429284413572,
        initial_entry_price: 1.4827,
        is_closed_position: true,
        miner_hotkey: "5Cd9bVVja2KdgsTiR7rTAh7a4UKVfnAuYAW1bs8BiedUE9JN",
        net_leverage: 0.0,
        open_ms: 1716961489417,
        orders: [
          {
            leverage: 1.0,
            order_type: "LONG",
            order_uuid: "648a13e8-e997-4ed8-9c2d-d419c5f5f6a0",
            price: 1.4827,
            price_sources: [],
            processed_ms: 1716961489409,
          },
          {
            leverage: 0.01,
            order_type: "FLAT",
            order_uuid: "32245ede-cbb8-43f0-bc9e-b8265c1934dc",
            price: 1.483505,
            price_sources: [],
            processed_ms: 1717160014841,
          },
        ],
        position_type: "FLAT",
        position_uuid: "f06762e2-6fbd-496f-8b9c-3a83f57d60af",
        return_at_close: 1.0004728904363662,
        trade_pair: ["EURCAD", "EUR/CAD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.8191999999999969,
        close_ms: 1717413980340,
        current_return: 1.0008998430761464,
        initial_entry_price: 0.91127,
        is_closed_position: true,
        miner_hotkey: "5Cd9bVVja2KdgsTiR7rTAh7a4UKVfnAuYAW1bs8BiedUE9JN",
        net_leverage: 0.0,
        open_ms: 1716884209802,
        orders: [
          {
            leverage: 1.0,
            order_type: "LONG",
            order_uuid: "cf0d4bd6-da26-4f19-a7e7-c26815e7538a",
            price: 0.91127,
            price_sources: [],
            processed_ms: 1716884209791,
          },
          {
            leverage: -0.99,
            order_type: "SHORT",
            order_uuid: "81924235-a06e-45ed-841d-ab66795d6da9",
            price: 0.9122,
            price_sources: [],
            processed_ms: 1716961232099,
          },
          {
            leverage: 0.01,
            order_type: "FLAT",
            order_uuid: "d8728d3e-dd2a-4584-af84-fb4ac30b6fee",
            price: 0.9012,
            price_sources: [],
            processed_ms: 1717413980340,
          },
        ],
        position_type: "FLAT",
        position_uuid: "d920acfb-2e2b-4d60-b862-552449fb6f28",
        return_at_close: 1.000829780087131,
        trade_pair: ["USDCHF", "USD/CHF", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 1.36548,
        close_ms: 1717508756560,
        current_return: 1.0008634377995507,
        initial_entry_price: 1.36663,
        is_closed_position: true,
        miner_hotkey: "5Cd9bVVja2KdgsTiR7rTAh7a4UKVfnAuYAW1bs8BiedUE9JN",
        net_leverage: 0.0,
        open_ms: 1717137129552,
        orders: [
          {
            leverage: 1.0,
            order_type: "LONG",
            order_uuid: "30103618-5121-4694-8bc5-ad60ca994a78",
            price: 1.36663,
            price_sources: [],
            processed_ms: 1717137129547,
          },
          {
            leverage: -0.5,
            order_type: "SHORT",
            order_uuid: "0afdba29-4b84-4d03-a27f-2fc5a9ae8618",
            price: 1.36778,
            price_sources: [],
            processed_ms: 1717508725418,
          },
          {
            leverage: 0.5,
            order_type: "FLAT",
            order_uuid: "5e799628-43ef-407c-a119-dd4cc52e0cf2",
            price: 1.36784,
            price_sources: [],
            processed_ms: 1717508756560,
          },
        ],
        position_type: "FLAT",
        position_uuid: "77ec535a-56ed-40f2-8a25-55748c7ca5fa",
        return_at_close: 1.0007933773589046,
        trade_pair: ["USDCAD", "USD/CAD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 1.2781475,
        close_ms: 1717676465908,
        current_return: 1.0004365888898896,
        initial_entry_price: 1.276945,
        is_closed_position: true,
        miner_hotkey: "5Cd9bVVja2KdgsTiR7rTAh7a4UKVfnAuYAW1bs8BiedUE9JN",
        net_leverage: 0.0,
        open_ms: 1717573975616,
        orders: [
          {
            leverage: -0.5,
            order_type: "SHORT",
            order_uuid: "2a522a85-f334-410c-baf1-b327d8c9ac09",
            price: 1.276945,
            price_sources: [],
            processed_ms: 1717573975611,
          },
          {
            leverage: -0.5,
            order_type: "SHORT",
            order_uuid: "943a4845-a8d8-41c1-a869-a60663347694",
            price: 1.27935,
            price_sources: [],
            processed_ms: 1717652049091,
          },
          {
            leverage: 0.5,
            order_type: "FLAT",
            order_uuid: "b98e0fb9-3f70-4944-b012-6af0f3226b86",
            price: 1.27759,
            price_sources: [],
            processed_ms: 1717676465908,
          },
        ],
        position_type: "FLAT",
        position_uuid: "ebd623f1-f0c9-483d-8871-9d29234f3b53",
        return_at_close: 1.0003665583286674,
        trade_pair: ["GBPUSD", "GBP/USD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 155.99133663366337,
        close_ms: 1717743250360,
        current_return: 1.00357318541672,
        initial_entry_price: 156.124,
        is_closed_position: true,
        miner_hotkey: "5Cd9bVVja2KdgsTiR7rTAh7a4UKVfnAuYAW1bs8BiedUE9JN",
        net_leverage: 0.0,
        open_ms: 1716302310492,
        orders: [
          {
            leverage: -1.0,
            order_type: "SHORT",
            order_uuid: "2a05d7e5-c6f5-4890-8ecb-4927b593163a",
            price: 156.124,
            price_sources: [],
            processed_ms: 1716302310400,
          },
          {
            leverage: -1.0,
            order_type: "SHORT",
            order_uuid: "68936daf-1d25-4956-807c-419004d2cbf8",
            price: 156.595,
            price_sources: [],
            processed_ms: 1716378400365,
          },
          {
            leverage: 1.99,
            order_type: "LONG",
            order_uuid: "71c62df5-5a7b-4473-85d4-4a53115ae2c1",
            price: 156.675,
            price_sources: [],
            processed_ms: 1716404863224,
          },
          {
            leverage: -1.0,
            order_type: "SHORT",
            order_uuid: "00fc114d-d354-40a9-b067-f6f5094c1c75",
            price: 156.618,
            price_sources: [],
            processed_ms: 1716445816272,
          },
          {
            leverage: 0.75,
            order_type: "LONG",
            order_uuid: "57ca06be-8b6c-4a51-a634-b5efbc417f1b",
            price: 156.456,
            price_sources: [],
            processed_ms: 1717596936026,
          },
          {
            leverage: -0.75,
            order_type: "SHORT",
            order_uuid: "9f197610-1b9b-430b-9f25-efa0a329e42a",
            price: 156.126,
            price_sources: [],
            processed_ms: 1717608173118,
          },
          {
            leverage: 1.0,
            order_type: "LONG",
            order_uuid: "78beea0a-3e98-4c85-bacc-bd967d73c242",
            price: 155.893,
            price_sources: [],
            processed_ms: 1717652089491,
          },
          {
            leverage: -1.0,
            order_type: "SHORT",
            order_uuid: "c43a8cd0-0e27-454c-84c6-168dd08b41da",
            price: 156.138,
            price_sources: [],
            processed_ms: 1717682195034,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "50a99fca-7c64-4f04-b321-e0aab5aadcc7",
            price: 155.439,
            price_sources: [],
            processed_ms: 1717743250360,
          },
        ],
        position_type: "FLAT",
        position_uuid: "52bff3d6-5f5b-45d0-ac63-b9ebd1ce8ddb",
        return_at_close: 1.0032394973325691,
        trade_pair: ["USDJPY", "USD/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 169.495,
        close_ms: 1717743302570,
        current_return: 1.0008407327649782,
        initial_entry_price: 169.495,
        is_closed_position: true,
        miner_hotkey: "5Cd9bVVja2KdgsTiR7rTAh7a4UKVfnAuYAW1bs8BiedUE9JN",
        net_leverage: 0.0,
        open_ms: 1716318844004,
        orders: [
          {
            leverage: -1.0,
            order_type: "SHORT",
            order_uuid: "ea102f49-8441-4677-9d03-8112cb5419ca",
            price: 169.495,
            price_sources: [],
            processed_ms: 1716318843983,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "4afe4560-e789-4648-96e4-b13f395d1d03",
            price: 169.35250000000002,
            price_sources: [],
            processed_ms: 1717743302570,
          },
        ],
        position_type: "FLAT",
        position_uuid: "1bcce932-9e24-46a8-a762-fa3256809189",
        return_at_close: 1.0007706739136846,
        trade_pair: ["EURJPY", "EUR/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 199.031,
        close_ms: 1717743350631,
        current_return: 1.0008893087006547,
        initial_entry_price: 199.031,
        is_closed_position: true,
        miner_hotkey: "5Cd9bVVja2KdgsTiR7rTAh7a4UKVfnAuYAW1bs8BiedUE9JN",
        net_leverage: 0.0,
        open_ms: 1716403303442,
        orders: [
          {
            leverage: -1.0,
            order_type: "SHORT",
            order_uuid: "0d351c59-8d2b-4234-a7ac-e0de73381b0c",
            price: 199.031,
            price_sources: [],
            processed_ms: 1716403303425,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "d8424eef-2ebc-4de1-abce-9895b57b0363",
            price: 198.854,
            price_sources: [],
            processed_ms: 1717743350631,
          },
        ],
        position_type: "FLAT",
        position_uuid: "979f529b-5924-416a-aac6-b0010371d764",
        return_at_close: 1.0008192464490457,
        trade_pair: ["GBPJPY", "GBP/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 69499.11,
        close_ms: 1717964865792,
        current_return: 1.0004394300876658,
        initial_entry_price: 69499.11,
        is_closed_position: true,
        miner_hotkey: "5Cd9bVVja2KdgsTiR7rTAh7a4UKVfnAuYAW1bs8BiedUE9JN",
        net_leverage: 0.0,
        open_ms: 1717832501990,
        orders: [
          {
            leverage: 0.2,
            order_type: "LONG",
            order_uuid: "d1894c6b-fa94-41f9-a656-ab6138e682af",
            price: 69499.11,
            price_sources: [],
            processed_ms: 1717832501380,
          },
          {
            leverage: 0.2,
            order_type: "FLAT",
            order_uuid: "ab75f67b-a6d7-4db8-8dd5-58032b390fb8",
            price: 69651.81,
            price_sources: [],
            processed_ms: 1717964865792,
          },
        ],
        position_type: "FLAT",
        position_uuid: "1aa4d2c5-5108-4e34-9a37-6b165ea39750",
        return_at_close: 1.0002393422016482,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 0.67776,
        close_ms: 1717967893669,
        current_return: 1.0074792118582792,
        initial_entry_price: 0.66384,
        is_closed_position: true,
        miner_hotkey: "5Cd9bVVja2KdgsTiR7rTAh7a4UKVfnAuYAW1bs8BiedUE9JN",
        net_leverage: 0.0,
        open_ms: 1717137057675,
        orders: [
          {
            leverage: -1.0,
            order_type: "SHORT",
            order_uuid: "5088bcdf-880c-4946-a31f-32ead8d1bd95",
            price: 0.66384,
            price_sources: [],
            processed_ms: 1717137057426,
          },
          {
            leverage: 0.5,
            order_type: "LONG",
            order_uuid: "785eb307-49e6-48fd-b93f-a9cb0ff2089a",
            price: 0.65987,
            price_sources: [],
            processed_ms: 1717771582790,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "3a14a8ba-a121-40e9-bfc3-ae3d8e1f5529",
            price: 0.65786,
            price_sources: [],
            processed_ms: 1717967556994,
          },
          {
            leverage: 0.25,
            order_type: "FLAT",
            order_uuid: "8bcf81c4-5115-4636-8117-d78c9e6d5351",
            price: 0.6579,
            price_sources: [],
            processed_ms: 1717967893669,
          },
        ],
        position_type: "FLAT",
        position_uuid: "8b72ded4-d4a2-4441-b258-3bf86d6bfaf6",
        return_at_close: 1.007408688313449,
        trade_pair: ["AUDUSD", "AUD/USD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 1.1022500000000002,
        close_ms: 1717967995928,
        current_return: 0.9941987479576287,
        initial_entry_price: 1.08942,
        is_closed_position: true,
        miner_hotkey: "5Cd9bVVja2KdgsTiR7rTAh7a4UKVfnAuYAW1bs8BiedUE9JN",
        net_leverage: 0.0,
        open_ms: 1717744128364,
        orders: [
          {
            leverage: 0.5,
            order_type: "LONG",
            order_uuid: "081b5d36-071d-46bf-ae17-fa78f45668ca",
            price: 1.08942,
            price_sources: [],
            processed_ms: 1717744128360,
          },
          {
            leverage: -0.25,
            order_type: "SHORT",
            order_uuid: "22cb3d81-67ba-492b-bfb6-58d93d47fa8c",
            price: 1.07659,
            price_sources: [],
            processed_ms: 1717967471194,
          },
          {
            leverage: 0.25,
            order_type: "FLAT",
            order_uuid: "76011955-3a28-4d25-b622-a244205d422d",
            price: 1.07697,
            price_sources: [],
            processed_ms: 1717967995928,
          },
        ],
        position_type: "FLAT",
        position_uuid: "a9a8c0cd-a430-45b0-a665-73401d3efb80",
        return_at_close: 0.9941639510014503,
        trade_pair: ["EURUSD", "EUR/USD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.8561475000000002,
        close_ms: 1717999951415,
        current_return: 1.0025004107691946,
        initial_entry_price: 0.85206,
        is_closed_position: true,
        miner_hotkey: "5Cd9bVVja2KdgsTiR7rTAh7a4UKVfnAuYAW1bs8BiedUE9JN",
        net_leverage: 0.0,
        open_ms: 1717677271054,
        orders: [
          {
            leverage: -0.5,
            order_type: "SHORT",
            order_uuid: "3e202466-faad-4943-8c16-ba4e88e484d4",
            price: 0.85206,
            price_sources: [],
            processed_ms: 1717677270334,
          },
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "688a33d9-0764-43aa-bb57-0fb1205534ad",
            price: 0.849335,
            price_sources: [],
            processed_ms: 1717771890557,
          },
          {
            leverage: 0.01,
            order_type: "FLAT",
            order_uuid: "f39cf326-9e5c-407b-9099-f1cab6965e7d",
            price: 0.845495,
            price_sources: [],
            processed_ms: 1717999951415,
          },
        ],
        position_type: "FLAT",
        position_uuid: "09ad979c-1dff-4b6a-84ea-145e0c9b5c43",
        return_at_close: 1.0024653232548177,
        trade_pair: ["EURGBP", "EUR/GBP", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.96394,
        close_ms: 1718100857150,
        current_return: 1.0001179453148492,
        initial_entry_price: 0.96443,
        is_closed_position: true,
        miner_hotkey: "5Cd9bVVja2KdgsTiR7rTAh7a4UKVfnAuYAW1bs8BiedUE9JN",
        net_leverage: 0.0,
        open_ms: 1717999921066,
        orders: [
          {
            leverage: -0.5,
            order_type: "SHORT",
            order_uuid: "88b6ba9b-5fca-49ca-acc7-aea59e92665c",
            price: 0.96443,
            price_sources: [],
            processed_ms: 1717999920687,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "54153fc6-2262-45d1-bfa6-78c8a6683bf1",
            price: 0.96492,
            price_sources: [],
            processed_ms: 1718087781003,
          },
          {
            leverage: 0.2,
            order_type: "FLAT",
            order_uuid: "d64a189a-63da-4dec-b6f9-d817636944a9",
            price: 0.9634849999999999,
            price_sources: [],
            processed_ms: 1718100857150,
          },
        ],
        position_type: "FLAT",
        position_uuid: "8edc9524-c569-42a0-95df-01e9aaf2db60",
        return_at_close: 1.000082941186763,
        trade_pair: ["EURCHF", "EUR/CHF", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.6525,
        close_ms: 1718194990805,
        current_return: 1.0007049808429118,
        initial_entry_price: 0.6525,
        is_closed_position: true,
        miner_hotkey: "5Cd9bVVja2KdgsTiR7rTAh7a4UKVfnAuYAW1bs8BiedUE9JN",
        net_leverage: 0.0,
        open_ms: 1718168004292,
        orders: [
          {
            leverage: -0.5,
            order_type: "SHORT",
            order_uuid: "67377ed4-3e21-4823-b2eb-3e71a32a4808",
            price: 0.6525,
            price_sources: [],
            processed_ms: 1718168004266,
          },
          {
            leverage: 0.5,
            order_type: "FLAT",
            order_uuid: "bce20ed0-72fa-40b7-bce3-5a4863688c92",
            price: 0.65158,
            price_sources: [],
            processed_ms: 1718194990805,
          },
        ],
        position_type: "FLAT",
        position_uuid: "13f89a10-b2da-4573-b0ef-aff80bfad019",
        return_at_close: 1.0006699561685823,
        trade_pair: ["CADCHF", "CAD/CHF", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 1.050940000000001,
        close_ms: 1718263147179,
        current_return: 1.005611498087834,
        initial_entry_price: 1.0760049999999999,
        is_closed_position: true,
        miner_hotkey: "5Cd9bVVja2KdgsTiR7rTAh7a4UKVfnAuYAW1bs8BiedUE9JN",
        net_leverage: 0.0,
        open_ms: 1718004627841,
        orders: [
          {
            leverage: 0.5,
            order_type: "LONG",
            order_uuid: "80b3ce79-34ab-4ead-9746-80c8b5bbbcca",
            price: 1.0760049999999999,
            price_sources: [],
            processed_ms: 1718004627835,
          },
          {
            leverage: 0.5,
            order_type: "LONG",
            order_uuid: "580431b3-ca7a-4d38-835f-b8d13948c5cb",
            price: 1.07325,
            price_sources: [],
            processed_ms: 1718121653705,
          },
          {
            leverage: -0.5,
            order_type: "SHORT",
            order_uuid: "f4eb5725-9216-4733-b354-bc8ea9cf4c75",
            price: 1.07656,
            price_sources: [],
            processed_ms: 1718195046539,
          },
          {
            leverage: 0.5,
            order_type: "LONG",
            order_uuid: "d0c0f1b3-0dbd-4aeb-a165-92a0bac660b3",
            price: 1.080145,
            price_sources: [],
            processed_ms: 1718195410531,
          },
          {
            leverage: -0.3,
            order_type: "SHORT",
            order_uuid: "28de90eb-90af-4d16-b577-15684103f7d5",
            price: 1.0824150000000001,
            price_sources: [],
            processed_ms: 1718198030180,
          },
          {
            leverage: -0.5,
            order_type: "SHORT",
            order_uuid: "d5be6eb6-2b2d-4319-8ec6-42bb32293046",
            price: 1.083015,
            price_sources: [],
            processed_ms: 1718198808944,
          },
          {
            leverage: 0.01,
            order_type: "FLAT",
            order_uuid: "74b7b8cf-3db0-42cd-aab5-5595b489bdeb",
            price: 1.08113,
            price_sources: [],
            processed_ms: 1718263147179,
          },
        ],
        position_type: "FLAT",
        position_uuid: "008b3eca-26cb-4526-b175-1695a9ef6ee0",
        return_at_close: 1.0055059088805347,
        trade_pair: ["EURUSD", "EUR/USD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 1.482245,
        close_ms: 1718282286361,
        current_return: 1.0000640919686017,
        initial_entry_price: 1.482245,
        is_closed_position: true,
        miner_hotkey: "5Cd9bVVja2KdgsTiR7rTAh7a4UKVfnAuYAW1bs8BiedUE9JN",
        net_leverage: 0.0,
        open_ms: 1718280757311,
        orders: [
          {
            leverage: 1.0,
            order_type: "LONG",
            order_uuid: "690a60fe-0783-44bc-9ad6-a812b1d7f985",
            price: 1.482245,
            price_sources: [],
            processed_ms: 1718280756912,
          },
          {
            leverage: 0.01,
            order_type: "FLAT",
            order_uuid: "3d4bcb73-a007-486b-8ceb-5a70b145f393",
            price: 1.48234,
            price_sources: [],
            processed_ms: 1718282286361,
          },
        ],
        position_type: "FLAT",
        position_uuid: "22ca4680-28f8-4ff8-8958-8612a086cb23",
        return_at_close: 0.9999940874821639,
        trade_pair: ["EURCAD", "EUR/CAD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.8418410000000001,
        close_ms: 1718308587539,
        current_return: 0.9994903208258931,
        initial_entry_price: 0.84563,
        is_closed_position: true,
        miner_hotkey: "5Cd9bVVja2KdgsTiR7rTAh7a4UKVfnAuYAW1bs8BiedUE9JN",
        net_leverage: 0.0,
        open_ms: 1718263178332,
        orders: [
          {
            leverage: 0.1,
            order_type: "LONG",
            order_uuid: "3c78e7f1-0e6a-4735-a4dd-b2a227f19e3f",
            price: 0.84563,
            price_sources: [],
            processed_ms: 1718263178104,
          },
          {
            leverage: 0.9,
            order_type: "LONG",
            order_uuid: "6a14c353-3cd5-4f4f-a3eb-9dea5bb417d2",
            price: 0.84142,
            price_sources: [],
            processed_ms: 1718308502268,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "56bff542-beba-46d0-9e41-dbb1eaee4c08",
            price: 0.84141,
            price_sources: [],
            processed_ms: 1718308587539,
          },
        ],
        position_type: "FLAT",
        position_uuid: "b9fa20e5-f563-4206-967d-3bd001b53cae",
        return_at_close: 0.9994203565034352,
        trade_pair: ["EURGBP", "EUR/GBP", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.9132899999999996,
        close_ms: 1718346183004,
        current_return: 0.9992965919355918,
        initial_entry_price: 0.91197,
        is_closed_position: true,
        miner_hotkey: "5Cd9bVVja2KdgsTiR7rTAh7a4UKVfnAuYAW1bs8BiedUE9JN",
        net_leverage: 0.0,
        open_ms: 1718308641404,
        orders: [
          {
            leverage: 1.0,
            order_type: "LONG",
            order_uuid: "05099942-97b0-4ac7-ba42-6901af9a383c",
            price: 0.91197,
            price_sources: [],
            processed_ms: 1718308641044,
          },
          {
            leverage: -0.8,
            order_type: "SHORT",
            order_uuid: "fbbef552-3358-4a2c-9f1d-e75ba2402ec4",
            price: 0.91164,
            price_sources: [],
            processed_ms: 1718308814088,
          },
          {
            leverage: 0.01,
            order_type: "FLAT",
            order_uuid: "398eb38b-dd66-4062-8c3b-3f0c959ba747",
            price: 0.910082564737508,
            price_sources: [],
            processed_ms: 1718346183004,
          },
        ],
        position_type: "FLAT",
        position_uuid: "9157320f-99e4-4a69-9eab-b5799e407415",
        return_at_close: 0.9992266411741564,
        trade_pair: ["AUDCAD", "AUD/CAD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 201.48000000000002,
        close_ms: 1718390190182,
        current_return: 1.0010045662100457,
        initial_entry_price: 201.48000000000002,
        is_closed_position: true,
        miner_hotkey: "5Cd9bVVja2KdgsTiR7rTAh7a4UKVfnAuYAW1bs8BiedUE9JN",
        net_leverage: 0.0,
        open_ms: 1718346214417,
        orders: [
          {
            leverage: -0.1,
            order_type: "SHORT",
            order_uuid: "a9ef1157-3512-4a66-8dde-f3f1bbb262c2",
            price: 201.48000000000002,
            price_sources: [],
            processed_ms: 1718346214360,
          },
          {
            leverage: 0.01,
            order_type: "FLAT",
            order_uuid: "971d4faa-d65f-4e5d-b293-88de03828acd",
            price: 199.456,
            price_sources: [],
            processed_ms: 1718390190182,
          },
        ],
        position_type: "FLAT",
        position_uuid: "57d6df8a-8379-4ce8-909a-487c9d53e803",
        return_at_close: 1.0009975591780822,
        trade_pair: ["GBPJPY", "GBP/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 65311.12000000001,
        close_ms: 1718571498830,
        current_return: 1.0008813813022959,
        initial_entry_price: 65311.12,
        is_closed_position: true,
        miner_hotkey: "5Cd9bVVja2KdgsTiR7rTAh7a4UKVfnAuYAW1bs8BiedUE9JN",
        net_leverage: 0.0,
        open_ms: 1718389993521,
        orders: [
          {
            leverage: 0.05,
            order_type: "LONG",
            order_uuid: "c95cceda-7ef1-48e9-9c3a-e728d523f29b",
            price: 65311.12,
            price_sources: [],
            processed_ms: 1718389993065,
          },
          {
            leverage: 0.01,
            order_type: "FLAT",
            order_uuid: "92f380a8-e93b-4cc1-85b6-6fe61b052ae8",
            price: 66462.4,
            price_sources: [],
            processed_ms: 1718571498830,
          },
        ],
        position_type: "FLAT",
        position_uuid: "aa2130c3-8c4f-4723-91be-d55aa6a93e5d",
        return_at_close: 1.0008313372332307,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 0.659775,
        close_ms: 1718783107812,
        current_return: 0.9983301125383653,
        initial_entry_price: 0.659775,
        is_closed_position: true,
        miner_hotkey: "5Cd9bVVja2KdgsTiR7rTAh7a4UKVfnAuYAW1bs8BiedUE9JN",
        net_leverage: 0.0,
        open_ms: 1718371642245,
        orders: [
          {
            leverage: -0.15,
            order_type: "SHORT",
            order_uuid: "920001cb-48f9-464e-bcf0-8f3243acafa0",
            price: 0.659775,
            price_sources: [],
            processed_ms: 1718371642166,
          },
          {
            leverage: 0.01,
            order_type: "FLAT",
            order_uuid: "7fd3ca7e-3b80-4d50-8f2c-4d16322cabf9",
            price: 0.66712,
            price_sources: [],
            processed_ms: 1718783107812,
          },
        ],
        position_type: "FLAT",
        position_uuid: "d7941425-1f93-4683-93f0-1b4193da2964",
        return_at_close: 0.9983196300721837,
        trade_pair: ["AUDUSD", "AUD/USD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 3540.68,
        close_ms: 1718871145722,
        current_return: 0.9991322288373985,
        initial_entry_price: 3540.68,
        is_closed_position: true,
        miner_hotkey: "5Cd9bVVja2KdgsTiR7rTAh7a4UKVfnAuYAW1bs8BiedUE9JN",
        net_leverage: 0.0,
        open_ms: 1718783144253,
        orders: [
          {
            leverage: -0.05,
            order_type: "SHORT",
            order_uuid: "f91c874c-8643-4517-87cc-ff89beb67879",
            price: 3540.68,
            price_sources: [],
            processed_ms: 1718783140791,
          },
          {
            leverage: 0.01,
            order_type: "FLAT",
            order_uuid: "835d0698-8a94-48e7-b0c4-bbbf6bae6994",
            price: 3602.13,
            price_sources: [],
            processed_ms: 1718871145722,
          },
        ],
        position_type: "FLAT",
        position_uuid: "3270d760-55ca-43a2-ac2d-f9ceb335efac",
        return_at_close: 0.9990822722259566,
        trade_pair: ["ETHUSD", "ETH/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 178.973,
        close_ms: 1718906570101,
        current_return: 1.0039002081911763,
        initial_entry_price: 178.973,
        is_closed_position: true,
        miner_hotkey: "5Cd9bVVja2KdgsTiR7rTAh7a4UKVfnAuYAW1bs8BiedUE9JN",
        net_leverage: 0.0,
        open_ms: 1718863115675,
        orders: [
          {
            leverage: -1.0,
            order_type: "SHORT",
            order_uuid: "e6fa426d-f919-4384-81e5-41257a264eca",
            price: 178.973,
            price_sources: [],
            processed_ms: 1718863115580,
          },
          {
            leverage: 0.01,
            order_type: "FLAT",
            order_uuid: "778e5efb-5f0f-43b9-914b-6f00fc7e9aa8",
            price: 178.27496803940062,
            price_sources: [],
            processed_ms: 1718906570101,
          },
        ],
        position_type: "FLAT",
        position_uuid: "a83909c9-d7b1-45dd-95d5-824b3ab43f71",
        return_at_close: 1.003829935176603,
        trade_pair: ["CHFJPY", "CHF/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.66585,
        close_ms: 1718906926776,
        current_return: 0.9998948712172412,
        initial_entry_price: 0.66585,
        is_closed_position: true,
        miner_hotkey: "5Cd9bVVja2KdgsTiR7rTAh7a4UKVfnAuYAW1bs8BiedUE9JN",
        net_leverage: 0.0,
        open_ms: 1718896922196,
        orders: [
          {
            leverage: -0.5,
            order_type: "SHORT",
            order_uuid: "2029dbdd-a013-4ee1-8d3f-93b97c95c6ed",
            price: 0.66585,
            price_sources: [],
            processed_ms: 1718896922143,
          },
          {
            leverage: 0.01,
            order_type: "FLAT",
            order_uuid: "d79054c6-696d-4d85-83e2-7a6968050a02",
            price: 0.66599,
            price_sources: [],
            processed_ms: 1718906926776,
          },
        ],
        position_type: "FLAT",
        position_uuid: "5c857d97-29c4-48f5-8521-798efd0fb140",
        return_at_close: 0.9998598748967485,
        trade_pair: ["AUDUSD", "AUD/USD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 200.898,
        close_ms: 1718957008282,
        current_return: 1.000536341825205,
        initial_entry_price: 200.898,
        is_closed_position: true,
        miner_hotkey: "5Cd9bVVja2KdgsTiR7rTAh7a4UKVfnAuYAW1bs8BiedUE9JN",
        net_leverage: 0.0,
        open_ms: 1718884725535,
        orders: [
          {
            leverage: -0.5,
            order_type: "SHORT",
            order_uuid: "a6623ab1-fc56-43a5-8be9-68d68cba7b57",
            price: 200.898,
            price_sources: [],
            processed_ms: 1718884725502,
          },
          {
            leverage: 0.01,
            order_type: "FLAT",
            order_uuid: "526c5722-d202-4101-8734-586052c16ac9",
            price: 200.6825,
            price_sources: [],
            processed_ms: 1718957008282,
          },
        ],
        position_type: "FLAT",
        position_uuid: "9232729f-1bfe-4127-a0c7-52411fdc292e",
        return_at_close: 1.000501323053241,
        trade_pair: ["GBPJPY", "GBP/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.61225,
        close_ms: 1718957042136,
        current_return: 1.0000653327888933,
        initial_entry_price: 0.61225,
        is_closed_position: true,
        miner_hotkey: "5Cd9bVVja2KdgsTiR7rTAh7a4UKVfnAuYAW1bs8BiedUE9JN",
        net_leverage: 0.0,
        open_ms: 1718954434775,
        orders: [
          {
            leverage: -0.5,
            order_type: "SHORT",
            order_uuid: "c26451ae-927e-4ba1-9eee-d5691b0d780c",
            price: 0.61225,
            price_sources: [],
            processed_ms: 1718954434695,
          },
          {
            leverage: 0.01,
            order_type: "FLAT",
            order_uuid: "af3a28ef-ca95-4ab6-ad21-32a5a9d3a53a",
            price: 0.61217,
            price_sources: [],
            processed_ms: 1718957042136,
          },
        ],
        position_type: "FLAT",
        position_uuid: "8c5b6928-8f09-4594-bb6d-1204406918e9",
        return_at_close: 1.0000303305022458,
        trade_pair: ["NZDUSD", "NZD/USD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 64256.07,
        close_ms: 1719165043585,
        current_return: 1.0007109834137071,
        initial_entry_price: 64256.07,
        is_closed_position: true,
        miner_hotkey: "5Cd9bVVja2KdgsTiR7rTAh7a4UKVfnAuYAW1bs8BiedUE9JN",
        net_leverage: 0.0,
        open_ms: 1719068504198,
        orders: [
          {
            leverage: -0.25,
            order_type: "SHORT",
            order_uuid: "0307fcb5-598c-4882-a123-68d6f41d215d",
            price: 64256.07,
            price_sources: [],
            processed_ms: 1719068504156,
          },
          {
            leverage: 0.01,
            order_type: "FLAT",
            order_uuid: "a6bd723d-97e8-453d-8ef3-1833065ede17",
            price: 64073.33,
            price_sources: [],
            processed_ms: 1719165043585,
          },
        ],
        position_type: "FLAT",
        position_uuid: "a52f6dbf-4e02-4a5f-88fe-549b9fd85915",
        return_at_close: 1.0004608056678537,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 3379.71,
        close_ms: 1719225273716,
        current_return: 0.9949640649641537,
        initial_entry_price: 3379.71,
        is_closed_position: true,
        miner_hotkey: "5Cd9bVVja2KdgsTiR7rTAh7a4UKVfnAuYAW1bs8BiedUE9JN",
        net_leverage: 0.0,
        open_ms: 1719215329293,
        orders: [
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "acde76a8-6d6a-4530-aa2e-d5f381dc60bb",
            price: 3379.71,
            price_sources: [],
            processed_ms: 1719215326388,
          },
          {
            leverage: 0.01,
            order_type: "FLAT",
            order_uuid: "ec496329-80d0-4869-a88b-25f39be651a2",
            price: 3311.63,
            price_sources: [],
            processed_ms: 1719225273716,
          },
        ],
        position_type: "FLAT",
        position_uuid: "d627299e-5e46-447a-8efd-a784c5e26b1e",
        return_at_close: 0.9947153239479126,
        trade_pair: ["ETHUSD", "ETH/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 61007.94,
        close_ms: 1719293760274,
        current_return: 1.0008249336070025,
        initial_entry_price: 61007.94,
        is_closed_position: true,
        miner_hotkey: "5Cd9bVVja2KdgsTiR7rTAh7a4UKVfnAuYAW1bs8BiedUE9JN",
        net_leverage: 0.0,
        open_ms: 1719234906434,
        orders: [
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "ee014b1c-19e3-4ebe-8b4b-fa56abc8f329",
            price: 61007.94,
            price_sources: [],
            processed_ms: 1719234905759,
          },
          {
            leverage: 0.01,
            order_type: "FLAT",
            order_uuid: "ce038226-0890-4529-9b3d-80bf008e8906",
            price: 61209.25,
            price_sources: [],
            processed_ms: 1719293760274,
          },
        ],
        position_type: "FLAT",
        position_uuid: "2aeed861-d11b-46a5-922e-93fea1b00422",
        return_at_close: 1.0005747273736008,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 167.9210000000001,
        close_ms: 1719957055987,
        current_return: 0.9674679176292853,
        initial_entry_price: 170.109,
        is_closed_position: true,
        miner_hotkey: "5Cd9bVVja2KdgsTiR7rTAh7a4UKVfnAuYAW1bs8BiedUE9JN",
        net_leverage: 0.0,
        open_ms: 1718907879853,
        orders: [
          {
            leverage: -1.0,
            order_type: "SHORT",
            order_uuid: "31d0de8c-ac14-4ec7-97ba-6276d64810ec",
            price: 170.109,
            price_sources: [],
            processed_ms: 1718907879781,
          },
          {
            leverage: -1.0,
            order_type: "SHORT",
            order_uuid: "90eb8e44-de4d-4dde-bf49-50ee12a0f9ee",
            price: 169.52550000000002,
            price_sources: [],
            processed_ms: 1718957136132,
          },
          {
            leverage: -1.0,
            order_type: "SHORT",
            order_uuid: "6af64c46-70e7-4b93-95fb-f770bc028bd7",
            price: 170.40050000000002,
            price_sources: [],
            processed_ms: 1718981912403,
          },
          {
            leverage: 2.0,
            order_type: "LONG",
            order_uuid: "a0072613-243b-4ea5-a7f1-56779b4fdc15",
            price: 171.057,
            price_sources: [],
            processed_ms: 1719301016737,
          },
          {
            leverage: 0.01,
            order_type: "FLAT",
            order_uuid: "ca4d111f-65ce-4427-a032-9f23fe1b315b",
            price: 173.455,
            price_sources: [],
            processed_ms: 1719957055987,
          },
        ],
        position_type: "FLAT",
        position_uuid: "46525ce2-aeca-43be-b00a-aae72dd7cfb4",
        return_at_close: 0.9672647493665831,
        trade_pair: ["EURJPY", "EUR/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 156.69099999999992,
        close_ms: 1719957102659,
        current_return: 0.9849422312014644,
        initial_entry_price: 158.39,
        is_closed_position: true,
        miner_hotkey: "5Cd9bVVja2KdgsTiR7rTAh7a4UKVfnAuYAW1bs8BiedUE9JN",
        net_leverage: 0.0,
        open_ms: 1718871218857,
        orders: [
          {
            leverage: -1.0,
            order_type: "SHORT",
            order_uuid: "c1aaaeb9-c0e3-4129-8388-c4cb32c40586",
            price: 158.39,
            price_sources: [],
            processed_ms: 1718871218687,
          },
          {
            leverage: -0.5,
            order_type: "SHORT",
            order_uuid: "4d63fcb3-5ed8-47a8-83a1-06b83c5963b1",
            price: 158.871,
            price_sources: [],
            processed_ms: 1718906600429,
          },
          {
            leverage: 1.0,
            order_type: "LONG",
            order_uuid: "9675f745-ab17-4e62-bc06-0dba58044839",
            price: 159.48000000000002,
            price_sources: [],
            processed_ms: 1719301047552,
          },
          {
            leverage: 0.01,
            order_type: "FLAT",
            order_uuid: "5179fa55-55b4-416f-9920-5711fb7b71ef",
            price: 161.461,
            price_sources: [],
            processed_ms: 1719957102659,
          },
        ],
        position_type: "FLAT",
        position_uuid: "17a6019e-eec3-49d0-94b4-5024dd6c4578",
        return_at_close: 0.9848388122671883,
        trade_pair: ["USDJPY", "USD/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 97.54249999999999,
        close_ms: 1720417680006,
        current_return: 0.9888048799241355,
        initial_entry_price: 97.54249999999999,
        is_closed_position: true,
        miner_hotkey: "5Cd9bVVja2KdgsTiR7rTAh7a4UKVfnAuYAW1bs8BiedUE9JN",
        net_leverage: 0.0,
        open_ms: 1718981881351,
        orders: [
          {
            leverage: -1.0,
            order_type: "SHORT",
            order_uuid: "39759287-908a-4b61-96b2-dff6ff6e9ad0",
            price: 97.54249999999999,
            price_sources: [],
            processed_ms: 1718981881320,
          },
          {
            leverage: 0.01,
            order_type: "FLAT",
            order_uuid: "347f2233-0223-4f17-859e-36e317f2c318",
            price: 98.6345,
            price_sources: [
              {
                close: 98.6345,
                high: 98.6345,
                lag_ms: 6,
                low: 98.6345,
                open: 98.6345,
                source: "Polygon_ws",
                start_ms: 1720417680000,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 98.64,
                websocket: true,
              },
              {
                close: 98.626,
                high: 98.626,
                lag_ms: 6,
                low: 98.626,
                open: 98.626,
                source: "TwelveData_ws",
                start_ms: 1720417680000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1720417680006,
          },
        ],
        position_type: "FLAT",
        position_uuid: "566b21a3-0ea0-48ed-a58c-d79938346b61",
        return_at_close: 0.9887356635825407,
        trade_pair: ["NZDJPY", "NZD/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 162.63050000000004,
        close_ms: 1720417710518,
        current_return: 1.0124440945565225,
        initial_entry_price: 161.32150000000001,
        is_closed_position: true,
        miner_hotkey: "5Cd9bVVja2KdgsTiR7rTAh7a4UKVfnAuYAW1bs8BiedUE9JN",
        net_leverage: 0.0,
        open_ms: 1720079003502,
        orders: [
          {
            leverage: -3.0,
            order_type: "SHORT",
            order_uuid: "e3836e09-f3f7-4237-ab0a-62be7074078f",
            price: 161.32150000000001,
            price_sources: [
              {
                close: 161.32150000000001,
                high: 161.32150000000001,
                lag_ms: 100,
                low: 161.32150000000001,
                open: 161.32150000000001,
                source: "Polygon_ws",
                start_ms: 1720079002999,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 161.32,
                websocket: true,
              },
              {
                close: 161.321,
                high: 161.321,
                lag_ms: 101,
                low: 161.321,
                open: 161.321,
                source: "TwelveData_ws",
                start_ms: 1720079003000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1720079002899,
          },
          {
            leverage: 2.0,
            order_type: "LONG",
            order_uuid: "d82ea697-e725-47fe-8c30-48f358f717f2",
            price: 160.667,
            price_sources: [
              {
                close: 160.667,
                high: 160.667,
                lag_ms: 498,
                low: 160.667,
                open: 160.667,
                source: "Polygon_ws",
                start_ms: 1720160677999,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 160.667,
                websocket: true,
              },
              {
                close: 160.671,
                high: 160.671,
                lag_ms: 1497,
                low: 160.671,
                open: 160.671,
                source: "TwelveData_ws",
                start_ms: 1720160677000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1720160678497,
          },
          {
            leverage: 0.01,
            order_type: "FLAT",
            order_uuid: "76798411-51ed-4fd0-a8fd-01465f979b1c",
            price: 160.623,
            price_sources: [
              {
                close: 160.623,
                high: 160.623,
                lag_ms: 481,
                low: 160.623,
                open: 160.623,
                source: "Polygon_ws",
                start_ms: 1720417710999,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 160.623,
                websocket: true,
              },
              {
                close: 160.624,
                high: 160.624,
                lag_ms: 482,
                low: 160.624,
                open: 160.624,
                source: "TwelveData_ws",
                start_ms: 1720417711000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1720417710518,
          },
        ],
        position_type: "FLAT",
        position_uuid: "75f5d495-d2a5-471c-a424-aadbf8af16a0",
        return_at_close: 1.0122314812966655,
        trade_pair: ["USDJPY", "USD/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 2990.93,
        close_ms: 1720417741592,
        current_return: 1.026322916283564,
        initial_entry_price: 2990.93,
        is_closed_position: true,
        miner_hotkey: "5Cd9bVVja2KdgsTiR7rTAh7a4UKVfnAuYAW1bs8BiedUE9JN",
        net_leverage: 0.0,
        open_ms: 1720208807812,
        orders: [
          {
            leverage: -1.0,
            order_type: "SHORT",
            order_uuid: "00bf8b69-8d11-4249-8d84-468975193c4e",
            price: 2990.93,
            price_sources: [
              {
                close: 2990.93,
                high: 2990.93,
                lag_ms: 101,
                low: 2990.93,
                open: 2990.93,
                source: "Polygon_ws",
                start_ms: 1720208805000,
                timespan_ms: 0,
                volume: 0.01654716,
                vwap: 2990.93,
                websocket: true,
              },
              {
                close: 2991.33,
                high: 2991.33,
                lag_ms: 3102,
                low: 2991.32,
                open: 2991.32,
                source: "Polygon_rest",
                start_ms: 1720208801000,
                timespan_ms: 1000,
                volume: 1.0006562,
                vwap: 2991.3228,
                websocket: false,
              },
              {
                close: 2990.24,
                high: 2990.24,
                lag_ms: 3899,
                low: 2990.24,
                open: 2990.24,
                source: "TwelveData_ws",
                start_ms: 1720208809000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
              {
                close: 2990.23999,
                high: 2990.5,
                lag_ms: 45102,
                low: 2989.54004,
                open: 2990.40991,
                source: "TwelveData_rest",
                start_ms: 1720208700000,
                timespan_ms: 60000,
                volume: null,
                vwap: null,
                websocket: false,
              },
            ],
            processed_ms: 1720208805101,
          },
          {
            leverage: 0.01,
            order_type: "FLAT",
            order_uuid: "e56e5a02-47a8-4ef5-a0de-a92054129319",
            price: 2912.2,
            price_sources: [
              {
                close: 2912.2,
                high: 2912.7,
                lag_ms: 407,
                low: 2912.17,
                open: 2912.2,
                source: "Polygon_ws",
                start_ms: 1720417741999,
                timespan_ms: 0,
                volume: 1.63644955,
                vwap: 2912.6989,
                websocket: true,
              },
              {
                close: 2912.07,
                high: 2912.07,
                lag_ms: 1408,
                low: 2912.07,
                open: 2912.07,
                source: "TwelveData_ws",
                start_ms: 1720417743000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
              {
                close: 2912.84,
                high: 2912.84,
                lag_ms: 2593,
                low: 2912.83,
                open: 2912.83,
                source: "Polygon_rest",
                start_ms: 1720417738000,
                timespan_ms: 1000,
                volume: 0.09952899,
                vwap: 2912.8398,
                websocket: false,
              },
              {
                close: 2912.73999,
                high: 2914.47998,
                lag_ms: 61593,
                low: 2912.73999,
                open: 2914.47998,
                source: "TwelveData_rest",
                start_ms: 1720417620000,
                timespan_ms: 60000,
                volume: null,
                vwap: null,
                websocket: false,
              },
            ],
            processed_ms: 1720417741592,
          },
        ],
        position_type: "FLAT",
        position_uuid: "0fb2830b-9fd9-4261-87b1-78472223d682",
        return_at_close: 1.0252965933672806,
        trade_pair: ["ETHUSD", "ETH/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 3068.18,
        close_ms: 1720467635911,
        current_return: 1.0174044547581953,
        initial_entry_price: 3068.18,
        is_closed_position: true,
        miner_hotkey: "5Cd9bVVja2KdgsTiR7rTAh7a4UKVfnAuYAW1bs8BiedUE9JN",
        net_leverage: 0.0,
        open_ms: 1720429742013,
        orders: [
          {
            leverage: -0.75,
            order_type: "SHORT",
            order_uuid: "775d2ef7-7243-4b08-98cb-3e0f983bb371",
            price: 3068.18,
            price_sources: [
              {
                close: 3068.18,
                high: 3068.23,
                lag_ms: 365,
                low: 3068.18,
                open: 3068.18,
                source: "Polygon_ws",
                start_ms: 1720429741000,
                timespan_ms: 0,
                volume: 2e-8,
                vwap: 3068.205,
                websocket: true,
              },
              {
                close: 3068.35,
                high: 3068.35,
                lag_ms: 2366,
                low: 3068.11,
                open: 3068.11,
                source: "Polygon_rest",
                start_ms: 1720429738000,
                timespan_ms: 1000,
                volume: 0.0470539,
                vwap: 3068.2233,
                websocket: false,
              },
              {
                close: 3068.61,
                high: 3068.61,
                lag_ms: 3365,
                low: 3068.61,
                open: 3068.61,
                source: "TwelveData_ws",
                start_ms: 1720429738000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
              {
                close: 3051.27002,
                high: 3051.54004,
                lag_ms: 781366,
                low: 3048.32007,
                open: 3048.32007,
                source: "TwelveData_rest",
                start_ms: 1720428900000,
                timespan_ms: 60000,
                volume: null,
                vwap: null,
                websocket: false,
              },
            ],
            processed_ms: 1720429741365,
          },
          {
            leverage: 0.01,
            order_type: "FLAT",
            order_uuid: "56557052-cce3-4b51-9baf-c2ae7ca6cf76",
            price: 2996.98,
            price_sources: [
              {
                close: 2996.98,
                high: 2996.98,
                lag_ms: 88,
                low: 2996.98,
                open: 2996.98,
                source: "Polygon_ws",
                start_ms: 1720467635999,
                timespan_ms: 0,
                volume: 0.04733863,
                vwap: 2996.98,
                websocket: true,
              },
              {
                close: 2996.78,
                high: 3001.8,
                lag_ms: 2912,
                low: 2996.7,
                open: 3001.8,
                source: "Polygon_rest",
                start_ms: 1720467632000,
                timespan_ms: 1000,
                volume: 5.51079326,
                vwap: 2999.8,
                websocket: false,
              },
              {
                close: 2996.8,
                high: 2996.8,
                lag_ms: 3911,
                low: 2996.8,
                open: 2996.8,
                source: "TwelveData_ws",
                start_ms: 1720467632000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
              {
                close: 2996.27002,
                high: 2998.46997,
                lag_ms: 155912,
                low: 2996.1001,
                open: 2996.1001,
                source: "TwelveData_rest",
                start_ms: 1720467420000,
                timespan_ms: 60000,
                volume: null,
                vwap: null,
                websocket: false,
              },
            ],
            processed_ms: 1720467635911,
          },
        ],
        position_type: "FLAT",
        position_uuid: "d17afebd-f052-4717-8dc6-0ce00573e5d5",
        return_at_close: 1.0166414014171266,
        trade_pair: ["ETHUSD", "ETH/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 3058.79,
        close_ms: 0,
        current_return: 0.9872237061060093,
        initial_entry_price: 3058.79,
        is_closed_position: false,
        miner_hotkey: "5Cd9bVVja2KdgsTiR7rTAh7a4UKVfnAuYAW1bs8BiedUE9JN",
        net_leverage: -0.5,
        open_ms: 1720533320293,
        orders: [
          {
            leverage: -0.5,
            order_type: "SHORT",
            order_uuid: "71b78f57-bace-472f-b1fc-0caadefbb04b",
            price: 3058.79,
            price_sources: [
              {
                close: 3058.79,
                high: 3058.83,
                lag_ms: 891,
                low: 3058.79,
                open: 3058.79,
                source: "Polygon_ws",
                start_ms: 1720533321000,
                timespan_ms: 0,
                volume: 1.633e-5,
                vwap: 3058.798,
                websocket: true,
              },
              {
                close: 3059.92,
                high: 3059.92,
                lag_ms: 2891,
                low: 3059.92,
                open: 3059.92,
                source: "TwelveData_ws",
                start_ms: 1720533323000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
              {
                close: 3057.8,
                high: 3057.8,
                lag_ms: 3110,
                low: 3057.71,
                open: 3057.71,
                source: "Polygon_rest",
                start_ms: 1720533316000,
                timespan_ms: 1000,
                volume: 3.03235196,
                vwap: 3057.7994,
                websocket: false,
              },
              {
                close: 3054.23999,
                high: 3058.66992,
                lag_ms: 200110,
                low: 3053.79004,
                open: 3058.66992,
                source: "TwelveData_rest",
                start_ms: 1720533060000,
                timespan_ms: 60000,
                volume: null,
                vwap: null,
                websocket: false,
              },
            ],
            processed_ms: 1720533320109,
          },
        ],
        position_type: "SHORT",
        position_uuid: "e155ee07-7712-4a7e-b234-2720617dee0b",
        return_at_close: 0.9869769001794828,
        trade_pair: ["ETHUSD", "ETH/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 56715.710999999996,
        close_ms: 0,
        current_return: 0.9871684286552611,
        initial_entry_price: 55382.5,
        is_closed_position: false,
        miner_hotkey: "5Cd9bVVja2KdgsTiR7rTAh7a4UKVfnAuYAW1bs8BiedUE9JN",
        net_leverage: -0.5,
        open_ms: 1720417773446,
        orders: [
          {
            leverage: -0.25,
            order_type: "SHORT",
            order_uuid: "74d0c49a-1560-4df4-bd21-878553f1f3d4",
            price: 55382.5,
            price_sources: [
              {
                close: 55382.5,
                high: 55392.18,
                lag_ms: 488,
                low: 55382.5,
                open: 55382.5,
                source: "Polygon_ws",
                start_ms: 1720417773000,
                timespan_ms: 0,
                volume: 0.04152411,
                vwap: 55386.4718,
                websocket: true,
              },
              {
                close: 55392.65,
                high: 55398.34,
                lag_ms: 2513,
                low: 55392.65,
                open: 55398.34,
                source: "Polygon_rest",
                start_ms: 1720417769000,
                timespan_ms: 1000,
                volume: 0.00215949,
                vwap: 55397.1625,
                websocket: false,
              },
              {
                close: 55383.7,
                high: 55383.7,
                lag_ms: 3488,
                low: 55383.7,
                open: 55383.7,
                source: "TwelveData_ws",
                start_ms: 1720417776000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
              {
                close: 55374.66,
                high: 55403.58,
                lag_ms: 32513,
                low: 55353.35,
                open: 55381.43,
                source: "TwelveData_rest",
                start_ms: 1720417680000,
                timespan_ms: 60000,
                volume: null,
                vwap: null,
                websocket: false,
              },
            ],
            processed_ms: 1720417772512,
          },
          {
            leverage: 0.1,
            order_type: "LONG",
            order_uuid: "ecf1a903-13ea-40df-8a2c-988f65fee94d",
            price: 56288.1,
            price_sources: [
              {
                close: 56288.1,
                high: 56288.11,
                lag_ms: 68,
                low: 56288.1,
                open: 56288.1,
                source: "Polygon_ws",
                start_ms: 1720467679000,
                timespan_ms: 0,
                volume: 0.0188686,
                vwap: 56288.1094,
                websocket: true,
              },
              {
                close: 56292.41,
                high: 56292.41,
                lag_ms: 2069,
                low: 56292.41,
                open: 56292.41,
                source: "Polygon_rest",
                start_ms: 1720467676000,
                timespan_ms: 1000,
                volume: 0.0036369,
                vwap: 56292.41,
                websocket: false,
              },
              {
                close: 56307.3,
                high: 56307.3,
                lag_ms: 7068,
                low: 56307.3,
                open: 56307.3,
                source: "TwelveData_ws",
                start_ms: 1720467672000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
              {
                close: 56272.82,
                high: 56279.17,
                lag_ms: 79069,
                low: 56230.0,
                open: 56241.27,
                source: "TwelveData_rest",
                start_ms: 1720467540000,
                timespan_ms: 60000,
                volume: null,
                vwap: null,
                websocket: false,
              },
            ],
            processed_ms: 1720467679068,
          },
          {
            leverage: -0.35,
            order_type: "SHORT",
            order_uuid: "eb2760d1-b49f-432d-a5c2-6d9b4bff356d",
            price: 57545.83,
            price_sources: [
              {
                close: 57545.83,
                high: 57546.11,
                lag_ms: 34,
                low: 57545.78,
                open: 57545.83,
                source: "Polygon_ws",
                start_ms: 1720507397999,
                timespan_ms: 0,
                volume: 0.00066869,
                vwap: 57546.11,
                websocket: true,
              },
              {
                close: 57549.13,
                high: 57551.0,
                lag_ms: 1966,
                low: 57545.48,
                open: 57545.48,
                source: "Polygon_rest",
                start_ms: 1720507395000,
                timespan_ms: 1000,
                volume: 0.00503677,
                vwap: 57550.0656,
                websocket: false,
              },
              {
                close: 57526.5,
                high: 57526.5,
                lag_ms: 2965,
                low: 57526.5,
                open: 57526.5,
                source: "TwelveData_ws",
                start_ms: 1720507395000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
              {
                close: 57508.03,
                high: 57531.49,
                lag_ms: 77966,
                low: 57466.07,
                open: 57512.47,
                source: "TwelveData_rest",
                start_ms: 1720507260000,
                timespan_ms: 60000,
                volume: null,
                vwap: null,
                websocket: false,
              },
            ],
            processed_ms: 1720507397965,
          },
        ],
        position_type: "SHORT",
        position_uuid: "adef2055-1432-46e0-9f7d-f25a24a55cae",
        return_at_close: 0.9868229197052318,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
    ],
    thirty_day_returns: 0.9922741269029978,
  },
  "5Cfx8PtVZxXcdVLBW6suwyvU8QmnZCHom5fVPfexJhkQh16U": {
    all_time_returns: 1.026796141131409,
    n_positions: 13,
    percentage_profitable: 1.0,
    positions: [
      {
        average_entry_price: 194.579,
        close_ms: 1714051234619,
        current_return: 1.000357695331973,
        initial_entry_price: 194.579,
        is_closed_position: true,
        miner_hotkey: "5Cfx8PtVZxXcdVLBW6suwyvU8QmnZCHom5fVPfexJhkQh16U",
        net_leverage: 0.0,
        open_ms: 1714046003556,
        orders: [
          {
            leverage: -0.1,
            order_type: "SHORT",
            order_uuid: "e509b10a-5095-4480-b7ec-e4de8b05506b",
            price: 194.579,
            price_sources: [],
            processed_ms: 1714046003556,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "8dbcebdd-aa23-4799-b808-fdb2585bdf21",
            price: 193.883,
            price_sources: [],
            processed_ms: 1714051234619,
          },
        ],
        position_type: "FLAT",
        position_uuid: "74b8c717-7e03-4ae8-ac12-cc142375944d",
        return_at_close: 1.0003506928281058,
        trade_pair: ["GBPJPY", "GBP/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 1.25104,
        close_ms: 1714366541822,
        current_return: 1.0002885599181481,
        initial_entry_price: 1.25104,
        is_closed_position: true,
        miner_hotkey: "5Cfx8PtVZxXcdVLBW6suwyvU8QmnZCHom5fVPfexJhkQh16U",
        net_leverage: 0.0,
        open_ms: 1714045981218,
        orders: [
          {
            leverage: 0.1,
            order_type: "LONG",
            order_uuid: "7fd3b0a5-e684-4690-a852-9f9159558032",
            price: 1.25104,
            price_sources: [],
            processed_ms: 1714045981217,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "a0c65a54-2e80-4e3a-be31-33913080f8e9",
            price: 1.25465,
            price_sources: [],
            processed_ms: 1714366541822,
          },
        ],
        position_type: "FLAT",
        position_uuid: "59fddff8-12a2-488d-a73f-8d47ae80fa42",
        return_at_close: 1.0002815578982287,
        trade_pair: ["GBPUSD", "GBP/USD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 1.086789999999973,
        close_ms: 1714713103957,
        current_return: 1.0001343510230178,
        initial_entry_price: 1.2512,
        is_closed_position: true,
        miner_hotkey: "5Cfx8PtVZxXcdVLBW6suwyvU8QmnZCHom5fVPfexJhkQh16U",
        net_leverage: 0.0,
        open_ms: 1714649687635,
        orders: [
          {
            leverage: 0.1,
            order_type: "LONG",
            order_uuid: "0e078796-06a4-4c89-b2d8-0659025c4a56",
            price: 1.2512,
            price_sources: [],
            processed_ms: 1714649687456,
          },
          {
            leverage: 0.05,
            order_type: "LONG",
            order_uuid: "f021e3b6-f762-4120-b404-b99c50b165e2",
            price: 1.25116,
            price_sources: [],
            processed_ms: 1714649737981,
          },
          {
            leverage: -0.149,
            order_type: "SHORT",
            order_uuid: "fead2cf6-9321-473d-97f8-31d2a29413d6",
            price: 1.25229,
            price_sources: [],
            processed_ms: 1714654305264,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "eec88c37-ae02-4f6a-aa3c-d75112369c10",
            price: 1.25489,
            price_sources: [],
            processed_ms: 1714713103957,
          },
        ],
        position_type: "FLAT",
        position_uuid: "b016e183-32ae-4567-aa74-7878f5a3d579",
        return_at_close: 1.0001238496123321,
        trade_pair: ["GBPUSD", "GBP/USD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 165.597,
        close_ms: 1715014603142,
        current_return: 1.0001165480050966,
        initial_entry_price: 165.597,
        is_closed_position: true,
        miner_hotkey: "5Cfx8PtVZxXcdVLBW6suwyvU8QmnZCHom5fVPfexJhkQh16U",
        net_leverage: 0.0,
        open_ms: 1714649837673,
        orders: [
          {
            leverage: 0.1,
            order_type: "LONG",
            order_uuid: "19322fc5-f26f-4ceb-b34e-14b5d95d4db7",
            price: 165.597,
            price_sources: [],
            processed_ms: 1714649837483,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "9ebe6a01-9791-4751-9a28-d7fc688b3486",
            price: 165.79,
            price_sources: [],
            processed_ms: 1715014603142,
          },
        ],
        position_type: "FLAT",
        position_uuid: "13db2d41-0197-4017-a4ff-aa569a77e936",
        return_at_close: 1.0001095471892607,
        trade_pair: ["EURJPY", "EUR/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 227.84300000000337,
        close_ms: 1715241056879,
        current_return: 1.0001730044995945,
        initial_entry_price: 193.573,
        is_closed_position: true,
        miner_hotkey: "5Cfx8PtVZxXcdVLBW6suwyvU8QmnZCHom5fVPfexJhkQh16U",
        net_leverage: 0.0,
        open_ms: 1714649704429,
        orders: [
          {
            leverage: -0.15,
            order_type: "SHORT",
            order_uuid: "806b126c-4022-40d7-86ea-12ac106881a3",
            price: 193.573,
            price_sources: [],
            processed_ms: 1714649704256,
          },
          {
            leverage: 0.149,
            order_type: "LONG",
            order_uuid: "9f6e5692-a40c-42f1-9f47-c2986714a57c",
            price: 193.343,
            price_sources: [],
            processed_ms: 1714654823039,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "0f9bde90-fb0f-4261-9981-9e41c7c47dc1",
            price: 194.354,
            price_sources: [],
            processed_ms: 1715241056879,
          },
        ],
        position_type: "FLAT",
        position_uuid: "f4747c98-d8f2-4cb4-b55a-a2cc7521b752",
        return_at_close: 1.0001625026830472,
        trade_pair: ["GBPJPY", "GBP/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.6018,
        close_ms: 1715274834071,
        current_return: 1.0001146560319043,
        initial_entry_price: 0.6018,
        is_closed_position: true,
        miner_hotkey: "5Cfx8PtVZxXcdVLBW6suwyvU8QmnZCHom5fVPfexJhkQh16U",
        net_leverage: 0.0,
        open_ms: 1715273447772,
        orders: [
          {
            leverage: 0.1,
            order_type: "LONG",
            order_uuid: "5d6d1142-602c-44b2-a70b-5b95ca339a97",
            price: 0.6018,
            price_sources: [],
            processed_ms: 1715273447209,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "3934171d-8664-40fc-a8ce-b3fbe319ad67",
            price: 0.60249,
            price_sources: [],
            processed_ms: 1715274834071,
          },
        ],
        position_type: "FLAT",
        position_uuid: "989ff125-c6d3-46a1-a619-835304cbf375",
        return_at_close: 1.000107655229312,
        trade_pair: ["NZDUSD", "NZD/USD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.824065990306169,
        close_ms: 1715275910510,
        current_return: 1.0000465993862564,
        initial_entry_price: 0.824065990306169,
        is_closed_position: true,
        miner_hotkey: "5Cfx8PtVZxXcdVLBW6suwyvU8QmnZCHom5fVPfexJhkQh16U",
        net_leverage: 0.0,
        open_ms: 1715273487835,
        orders: [
          {
            leverage: 0.1,
            order_type: "LONG",
            order_uuid: "0fdf6fe4-5757-4721-8322-e3a7bcc4f222",
            price: 0.824065990306169,
            price_sources: [],
            processed_ms: 1715273487134,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "c971e51c-b335-4ef4-b21a-4965bc373f0a",
            price: 0.82445,
            price_sources: [],
            processed_ms: 1715275910510,
          },
        ],
        position_type: "FLAT",
        position_uuid: "35cd0011-75ce-4745-a421-33d3e0fb84f4",
        return_at_close: 1.0000395990600608,
        trade_pair: ["NZDCAD", "NZD/CAD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 137.9890000000009,
        close_ms: 1715306674625,
        current_return: 1.0001144708004421,
        initial_entry_price: 154.677,
        is_closed_position: true,
        miner_hotkey: "5Cfx8PtVZxXcdVLBW6suwyvU8QmnZCHom5fVPfexJhkQh16U",
        net_leverage: 0.0,
        open_ms: 1714649721456,
        orders: [
          {
            leverage: 0.15,
            order_type: "LONG",
            order_uuid: "b2e67d1f-d807-466e-a7e7-93ccaee0ac1c",
            price: 154.677,
            price_sources: [],
            processed_ms: 1714649721264,
          },
          {
            leverage: -0.149,
            order_type: "SHORT",
            order_uuid: "a88b350f-5c7c-443e-8325-435f7766ca82",
            price: 154.789,
            price_sources: [],
            processed_ms: 1714650105829,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "612d5021-a817-418b-a3ab-70abedf2abed",
            price: 155.695,
            price_sources: [],
            processed_ms: 1715306674625,
          },
        ],
        position_type: "FLAT",
        position_uuid: "e616f88f-28de-4c55-9dcf-f6edada2ccec",
        return_at_close: 1.0001039695984988,
        trade_pair: ["USDJPY", "USD/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 93.71199999999999,
        close_ms: 1715307425397,
        current_return: 1.000035214273519,
        initial_entry_price: 93.712,
        is_closed_position: true,
        miner_hotkey: "5Cfx8PtVZxXcdVLBW6suwyvU8QmnZCHom5fVPfexJhkQh16U",
        net_leverage: 0.0,
        open_ms: 1715273465958,
        orders: [
          {
            leverage: -0.1,
            order_type: "SHORT",
            order_uuid: "53091133-8e7a-4dfb-b685-35d98b83a57c",
            price: 93.712,
            price_sources: [],
            processed_ms: 1715273465757,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "021c6b75-9e1c-474a-b854-d734208a9d1d",
            price: 93.679,
            price_sources: [],
            processed_ms: 1715307425397,
          },
        ],
        position_type: "FLAT",
        position_uuid: "322c5edf-68bf-4b50-a110-5456a0449aed",
        return_at_close: 1.0000282140270191,
        trade_pair: ["NZDJPY", "NZD/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 113.72590322580646,
        close_ms: 1715320881496,
        current_return: 1.029388745746026,
        initial_entry_price: 113.717,
        is_closed_position: true,
        miner_hotkey: "5Cfx8PtVZxXcdVLBW6suwyvU8QmnZCHom5fVPfexJhkQh16U",
        net_leverage: 0.0,
        open_ms: 1715273382472,
        orders: [
          {
            leverage: -5.0,
            order_type: "SHORT",
            order_uuid: "130dc709-b3f3-476b-ba3b-95b2f011c784",
            price: 113.717,
            price_sources: [],
            processed_ms: 1715273382269,
          },
          {
            leverage: -27.0,
            order_type: "SHORT",
            order_uuid: "d13cc78e-3545-4df3-bd5a-bee046401106",
            price: 113.673,
            price_sources: [],
            processed_ms: 1715276944626,
          },
          {
            leverage: -30.0,
            order_type: "SHORT",
            order_uuid: "c028f0f0-79b4-49a2-b961-219f7c26a6ef",
            price: 113.775,
            price_sources: [],
            processed_ms: 1715306828408,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "a6b1cb49-48a7-4661-a6f2-2a615c1fd486",
            price: 113.672,
            price_sources: [],
            processed_ms: 1715320881496,
          },
        ],
        position_type: "FLAT",
        position_uuid: "f2fbfc59-797b-4677-bc8d-be2eda22547b",
        return_at_close: 1.0249211985894882,
        trade_pair: ["CADJPY", "CAD/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 3127.73,
        close_ms: 1715323040322,
        current_return: 1.0000293567539398,
        initial_entry_price: 3127.73,
        is_closed_position: true,
        miner_hotkey: "5Cfx8PtVZxXcdVLBW6suwyvU8QmnZCHom5fVPfexJhkQh16U",
        net_leverage: 0.0,
        open_ms: 1714834944910,
        orders: [
          {
            leverage: -0.001,
            order_type: "SHORT",
            order_uuid: "6be07dea-a664-4cf4-9a46-b955f7fe2354",
            price: 3127.73,
            price_sources: [],
            processed_ms: 1714834944720,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "2c34855e-6210-4473-966d-d7cde7475b56",
            price: 3035.91,
            price_sources: [],
            processed_ms: 1715323040322,
          },
        ],
        position_type: "FLAT",
        position_uuid: "917beb95-060a-45a2-96bf-d4ec90843dca",
        return_at_close: 1.000028356724583,
        trade_pair: ["ETHUSD", "ETH/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 155.691,
        close_ms: 1715323475135,
        current_return: 1.0001605744712283,
        initial_entry_price: 155.691,
        is_closed_position: true,
        miner_hotkey: "5Cfx8PtVZxXcdVLBW6suwyvU8QmnZCHom5fVPfexJhkQh16U",
        net_leverage: 0.0,
        open_ms: 1715323243381,
        orders: [
          {
            leverage: -1.0,
            order_type: "SHORT",
            order_uuid: "4590a13b-a5ba-41a7-a001-8555f879653a",
            price: 155.691,
            price_sources: [],
            processed_ms: 1715323242944,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "cbdab0d0-489e-41ba-ad7b-780a8a994f38",
            price: 155.666,
            price_sources: [],
            processed_ms: 1715323475135,
          },
        ],
        position_type: "FLAT",
        position_uuid: "61a9edb1-77bf-4ce6-8947-bf472dce62b2",
        return_at_close: 1.0000905632310153,
        trade_pair: ["USDJPY", "USD/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 1.36875,
        close_ms: 1718390381188,
        current_return: 1.000408401826484,
        initial_entry_price: 1.36875,
        is_closed_position: true,
        miner_hotkey: "5Cfx8PtVZxXcdVLBW6suwyvU8QmnZCHom5fVPfexJhkQh16U",
        net_leverage: 0.0,
        open_ms: 1715273431289,
        orders: [
          {
            leverage: 0.1,
            order_type: "LONG",
            order_uuid: "7d6174d7-be49-4440-aee7-56b80f94821a",
            price: 1.36875,
            price_sources: [],
            processed_ms: 1715273431060,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "f0496363-3478-4ae6-b0c3-36f07ec9418a",
            price: 1.37434,
            price_sources: [],
            processed_ms: 1718390381188,
          },
        ],
        position_type: "FLAT",
        position_uuid: "d60d8c73-4237-431c-a484-ae821ee1e564",
        return_at_close: 1.0004013989676712,
        trade_pair: ["USDCAD", "USD/CAD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 2893.8,
        close_ms: 0,
        current_return: 0.9999159755339001,
        initial_entry_price: 2893.8,
        is_closed_position: false,
        miner_hotkey: "5Cfx8PtVZxXcdVLBW6suwyvU8QmnZCHom5fVPfexJhkQh16U",
        net_leverage: -0.001,
        open_ms: 1715365192544,
        orders: [
          {
            leverage: -0.001,
            order_type: "SHORT",
            order_uuid: "07925863-aff5-4bac-938c-154f6b856e9a",
            price: 2893.8,
            price_sources: [],
            processed_ms: 1715365192339,
          },
        ],
        position_type: "SHORT",
        position_uuid: "3a3b1dc1-ed3e-43ab-8d69-e8b1c7141019",
        return_at_close: 0.9999154755759124,
        trade_pair: ["ETHUSD", "ETH/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 125.77200000001187,
        close_ms: 0,
        current_return: 1.0002936475287665,
        initial_entry_price: 167.73,
        is_closed_position: false,
        miner_hotkey: "5Cfx8PtVZxXcdVLBW6suwyvU8QmnZCHom5fVPfexJhkQh16U",
        net_leverage: 0.0010000000000000009,
        open_ms: 1715323226537,
        orders: [
          {
            leverage: 1.0,
            order_type: "LONG",
            order_uuid: "f9ea5844-197e-42a0-8416-c6086537d530",
            price: 167.73,
            price_sources: [],
            processed_ms: 1715323226221,
          },
          {
            leverage: -0.999,
            order_type: "SHORT",
            order_uuid: "84ebfdd2-42b6-425c-8262-f86e03a57eaa",
            price: 167.772,
            price_sources: [],
            processed_ms: 1715324011488,
          },
        ],
        position_type: "LONG",
        position_uuid: "e1c7c9f6-81a0-4c95-aa91-42418a639616",
        return_at_close: 1.000223661983717,
        trade_pair: ["EURJPY", "EUR/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 70665.23,
        close_ms: 0,
        current_return: 0.9468130366235276,
        initial_entry_price: 70665.23,
        is_closed_position: false,
        miner_hotkey: "5Cfx8PtVZxXcdVLBW6suwyvU8QmnZCHom5fVPfexJhkQh16U",
        net_leverage: 0.3,
        open_ms: 1716301209880,
        orders: [
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "eb35e1b7-0ada-4eb8-89eb-b4873b9a643c",
            price: 70665.23,
            price_sources: [],
            processed_ms: 1716301207361,
          },
        ],
        position_type: "LONG",
        position_uuid: "777e808c-9237-4c60-a3af-22be763df89d",
        return_at_close: 0.946671014668034,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
    ],
    thirty_day_returns: 1.0,
  },
  "5ChX6tTbNGwCaLeCuQFFNRp57BckJzfT6xL1UmaPQyai5W5j": {
    all_time_returns: 1.0,
    n_positions: 0,
    percentage_profitable: 0.0,
    positions: [
      {
        average_entry_price: 64106.5,
        close_ms: 0,
        current_return: 0.9906881517474827,
        initial_entry_price: 64106.5,
        is_closed_position: false,
        miner_hotkey: "5ChX6tTbNGwCaLeCuQFFNRp57BckJzfT6xL1UmaPQyai5W5j",
        net_leverage: 0.1,
        open_ms: 1718980759245,
        orders: [
          {
            leverage: 0.1,
            order_type: "LONG",
            order_uuid: "4af412a7-7b7f-45f1-b49d-c4a02699ac45",
            price: 64106.5,
            price_sources: [],
            processed_ms: 1718980758952,
          },
        ],
        position_type: "LONG",
        position_uuid: "4af412a7-7b7f-45f1-b49d-c4a02699ac45",
        return_at_close: 0.9906386173398953,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
    ],
    thirty_day_returns: 1.0,
  },
  "5ChrUp14CPTaX6pF2NGTfwFiSGym86656g2WYYtPj7Crb8vP": {
    all_time_returns: 1.0,
    n_positions: 0,
    percentage_profitable: 0.0,
    positions: [
      {
        average_entry_price: 64295.71,
        close_ms: 1711186985898,
        current_return: 1.0078278939605771,
        initial_entry_price: 64295.71,
        is_closed_position: true,
        miner_hotkey: "5ChrUp14CPTaX6pF2NGTfwFiSGym86656g2WYYtPj7Crb8vP",
        net_leverage: 0.0,
        open_ms: 1711185252110,
        orders: [
          {
            leverage: 10.0,
            order_type: "LONG",
            order_uuid: "10257fb2-6d3c-46c6-8b1f-dc9ebab03040",
            price: 64295.71,
            price_sources: [],
            processed_ms: 1711185252109,
          },
          {
            leverage: 10.0,
            order_type: "FLAT",
            order_uuid: "232c4215-cd28-42db-ae64-b4cb3c6bcdf8",
            price: 64346.04,
            price_sources: [],
            processed_ms: 1711186985898,
          },
        ],
        position_type: "FLAT",
        position_uuid: "1dd8dced-c92d-4308-9363-4b1fec06a2a5",
        return_at_close: 0.9876713360813656,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 69973.01,
        close_ms: 1711386396502,
        current_return: 0.8715348960977952,
        initial_entry_price: 69973.01,
        is_closed_position: true,
        miner_hotkey: "5ChrUp14CPTaX6pF2NGTfwFiSGym86656g2WYYtPj7Crb8vP",
        net_leverage: -19.0,
        open_ms: 1711382114940,
        orders: [
          {
            leverage: -19.0,
            order_type: "SHORT",
            order_uuid: "91719d45-b95d-4880-97bc-b4df6344800e",
            price: 69973.01,
            price_sources: [],
            processed_ms: 1711382114939,
          },
        ],
        position_type: "FLAT",
        position_uuid: "cf41b9f0-13d1-4e15-87a9-8210449d1eee",
        return_at_close: 0.838416570046079,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
    ],
    thirty_day_returns: 1.0,
  },
  "5Ck3US6cjMBoF49aLexLNKSS2WgZFMzxHhSzvw8wA18V33QA": {
    all_time_returns: 1.0,
    n_positions: 0,
    percentage_profitable: 0.0,
    positions: [
      {
        average_entry_price: 64377.81999999999,
        close_ms: 1711195908253,
        current_return: 1.0029321589329991,
        initial_entry_price: 64377.82,
        is_closed_position: true,
        miner_hotkey: "5Ck3US6cjMBoF49aLexLNKSS2WgZFMzxHhSzvw8wA18V33QA",
        net_leverage: 0.0,
        open_ms: 1711192335197,
        orders: [
          {
            leverage: 0.6,
            order_type: "LONG",
            order_uuid: "4bfd49b4-d85c-4d37-8ad9-17ab29cc1a06",
            price: 64377.82,
            price_sources: [],
            processed_ms: 1711192335197,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "f9b92661-dd30-4d13-9c88-ddd9a6b8bdf5",
            price: 64692.43,
            price_sources: [],
            processed_ms: 1711195908253,
          },
        ],
        position_type: "FLAT",
        position_uuid: "b31e4834-fedc-461e-ad73-6e0bd0007867",
        return_at_close: 1.0017286403422796,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 64663.83000000001,
        close_ms: 1711199575177,
        current_return: 1.000278733876419,
        initial_entry_price: 64663.83,
        is_closed_position: true,
        miner_hotkey: "5Ck3US6cjMBoF49aLexLNKSS2WgZFMzxHhSzvw8wA18V33QA",
        net_leverage: 0.0,
        open_ms: 1711195995115,
        orders: [
          {
            leverage: 0.6,
            order_type: "LONG",
            order_uuid: "321f8890-e073-429b-a145-4513a98d6a08",
            price: 64663.83,
            price_sources: [],
            processed_ms: 1711195995115,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "2a6a5b35-04da-429f-bad6-b0dcdb0e7727",
            price: 64693.87,
            price_sources: [],
            processed_ms: 1711199575177,
          },
        ],
        position_type: "FLAT",
        position_uuid: "3802fcd2-435d-422f-a77f-15322da338b9",
        return_at_close: 0.9990783993957673,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 64676.74999999999,
        close_ms: 1711203258292,
        current_return: 0.9985988164216663,
        initial_entry_price: 64676.75,
        is_closed_position: true,
        miner_hotkey: "5Ck3US6cjMBoF49aLexLNKSS2WgZFMzxHhSzvw8wA18V33QA",
        net_leverage: 0.0,
        open_ms: 1711199662321,
        orders: [
          {
            leverage: 0.6,
            order_type: "LONG",
            order_uuid: "2df818f9-f629-41bb-a6fd-f323d9712aaf",
            price: 64676.75,
            price_sources: [],
            processed_ms: 1711199662320,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "ee2d49eb-6a39-44af-8d3d-9c873b096fbb",
            price: 64525.71,
            price_sources: [],
            processed_ms: 1711203258292,
          },
        ],
        position_type: "FLAT",
        position_uuid: "c757931c-2a55-4120-81b1-e08536559642",
        return_at_close: 0.9974004978419603,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 64562.31,
        close_ms: 1711206924265,
        current_return: 1.0048113520101745,
        initial_entry_price: 64562.31,
        is_closed_position: true,
        miner_hotkey: "5Ck3US6cjMBoF49aLexLNKSS2WgZFMzxHhSzvw8wA18V33QA",
        net_leverage: 0.0,
        open_ms: 1711203345613,
        orders: [
          {
            leverage: 0.6,
            order_type: "LONG",
            order_uuid: "4e9c1cb3-08af-4773-aafa-603ef64158c1",
            price: 64562.31,
            price_sources: [],
            processed_ms: 1711203345612,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "c02618b2-627e-4e97-a496-2f345cca0176",
            price: 65080.03,
            price_sources: [],
            processed_ms: 1711206924265,
          },
        ],
        position_type: "FLAT",
        position_uuid: "216d557a-6bbd-45be-9384-3dc16ce3da33",
        return_at_close: 1.0036055783877622,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 65027.32,
        close_ms: 1711210563271,
        current_return: 1.0017851266206266,
        initial_entry_price: 65027.32,
        is_closed_position: true,
        miner_hotkey: "5Ck3US6cjMBoF49aLexLNKSS2WgZFMzxHhSzvw8wA18V33QA",
        net_leverage: 0.0,
        open_ms: 1711207011404,
        orders: [
          {
            leverage: 0.6,
            order_type: "LONG",
            order_uuid: "511d37f8-89e7-41ae-97ca-bc063b3cbffe",
            price: 65027.32,
            price_sources: [],
            processed_ms: 1711207011403,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "4ff74fde-d9c9-4423-aff9-335b5382e909",
            price: 65220.79,
            price_sources: [],
            processed_ms: 1711210563271,
          },
        ],
        position_type: "FLAT",
        position_uuid: "01f813ec-b62c-40db-a86a-6de42ba6924a",
        return_at_close: 1.0005829844686818,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 65151.0,
        close_ms: 1711214229830,
        current_return: 1.0031963899249436,
        initial_entry_price: 65151.0,
        is_closed_position: true,
        miner_hotkey: "5Ck3US6cjMBoF49aLexLNKSS2WgZFMzxHhSzvw8wA18V33QA",
        net_leverage: 0.0,
        open_ms: 1711210689909,
        orders: [
          {
            leverage: 0.6,
            order_type: "LONG",
            order_uuid: "920b10be-513e-455b-8e7a-7665f0f16d24",
            price: 65151.0,
            price_sources: [],
            processed_ms: 1711210689908,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "c740d888-31f3-4df1-b728-b717d1e333af",
            price: 65498.08,
            price_sources: [],
            processed_ms: 1711214229830,
          },
        ],
        position_type: "FLAT",
        position_uuid: "b31641a8-4908-4ccd-8e64-660e7257e78b",
        return_at_close: 1.0019925542570338,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 65491.759999999995,
        close_ms: 1711217892325,
        current_return: 1.000359953679669,
        initial_entry_price: 65491.76,
        is_closed_position: true,
        miner_hotkey: "5Ck3US6cjMBoF49aLexLNKSS2WgZFMzxHhSzvw8wA18V33QA",
        net_leverage: 0.0,
        open_ms: 1711214315338,
        orders: [
          {
            leverage: 0.6,
            order_type: "LONG",
            order_uuid: "cf50cdaf-3140-49c7-947d-3bb23ee0ff3a",
            price: 65491.76,
            price_sources: [],
            processed_ms: 1711214315337,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "2cc50f9a-4dfd-46d8-952a-5160c4d99986",
            price: 65531.05,
            price_sources: [],
            processed_ms: 1711217892325,
          },
        ],
        position_type: "FLAT",
        position_uuid: "256a9803-780d-4a01-838e-4c9470d9eb12",
        return_at_close: 0.9991595217352535,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 65525.0,
        close_ms: 1711221549124,
        current_return: 0.9977982449446776,
        initial_entry_price: 65525.0,
        is_closed_position: true,
        miner_hotkey: "5Ck3US6cjMBoF49aLexLNKSS2WgZFMzxHhSzvw8wA18V33QA",
        net_leverage: 0.0,
        open_ms: 1711217979428,
        orders: [
          {
            leverage: 0.6,
            order_type: "LONG",
            order_uuid: "9c526215-6a7d-42e4-a0c1-c107963f3224",
            price: 65525.0,
            price_sources: [],
            processed_ms: 1711217979427,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "b2717b8b-b845-42da-af22-c71409e4e0e5",
            price: 65284.55,
            price_sources: [],
            processed_ms: 1711221549124,
          },
        ],
        position_type: "FLAT",
        position_uuid: "6e2a9c17-7170-4294-97b7-8bd5db55e2ea",
        return_at_close: 0.996600887050744,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 65310.7,
        close_ms: 1711225214786,
        current_return: 0.9961151541784119,
        initial_entry_price: 65310.7,
        is_closed_position: true,
        miner_hotkey: "5Ck3US6cjMBoF49aLexLNKSS2WgZFMzxHhSzvw8wA18V33QA",
        net_leverage: 0.0,
        open_ms: 1711221636411,
        orders: [
          {
            leverage: 0.6,
            order_type: "LONG",
            order_uuid: "42cf3a39-8434-4e75-ae7c-61aca39b4a6e",
            price: 65310.7,
            price_sources: [],
            processed_ms: 1711221636410,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "0e7b0b01-64df-4ab0-b8f3-3b6eec15c955",
            price: 64887.83,
            price_sources: [],
            processed_ms: 1711225214786,
          },
        ],
        position_type: "FLAT",
        position_uuid: "373b5b30-f695-44db-ba15-330762e778c9",
        return_at_close: 0.9949198159933978,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 64932.920000000006,
        close_ms: 1711228874382,
        current_return: 0.9999044552439655,
        initial_entry_price: 64932.92,
        is_closed_position: true,
        miner_hotkey: "5Ck3US6cjMBoF49aLexLNKSS2WgZFMzxHhSzvw8wA18V33QA",
        net_leverage: 0.0,
        open_ms: 1711225342697,
        orders: [
          {
            leverage: 0.6,
            order_type: "LONG",
            order_uuid: "4c9323fa-5346-449d-bb8c-bac5ec485bab",
            price: 64932.92,
            price_sources: [],
            processed_ms: 1711225342695,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "5454ca17-9a99-4c81-8c82-60b84483d00f",
            price: 64922.58,
            price_sources: [],
            processed_ms: 1711228874382,
          },
        ],
        position_type: "FLAT",
        position_uuid: "c57ac919-2d39-4a31-aa72-162a0edd5ed8",
        return_at_close: 0.9987045698976728,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 64922.58000000001,
        close_ms: 1711232537358,
        current_return: 0.9993457746133932,
        initial_entry_price: 64922.58,
        is_closed_position: true,
        miner_hotkey: "5Ck3US6cjMBoF49aLexLNKSS2WgZFMzxHhSzvw8wA18V33QA",
        net_leverage: 0.0,
        open_ms: 1711228961325,
        orders: [
          {
            leverage: 0.6,
            order_type: "LONG",
            order_uuid: "261f8590-8ed9-4cd6-9f04-a017c94071a8",
            price: 64922.58,
            price_sources: [],
            processed_ms: 1711228961323,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "39aae02b-8138-4c3a-ac67-7943d25afcb3",
            price: 64851.79,
            price_sources: [],
            processed_ms: 1711232537358,
          },
        ],
        position_type: "FLAT",
        position_uuid: "0d1c68e9-1194-449d-81b2-51e7ff2ff491",
        return_at_close: 0.9981465596838572,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 64857.17,
        close_ms: 1711236218001,
        current_return: 0.9986078023447523,
        initial_entry_price: 64857.17,
        is_closed_position: true,
        miner_hotkey: "5Ck3US6cjMBoF49aLexLNKSS2WgZFMzxHhSzvw8wA18V33QA",
        net_leverage: 0.0,
        open_ms: 1711232624324,
        orders: [
          {
            leverage: 0.6,
            order_type: "LONG",
            order_uuid: "ebd4ad6d-9090-4d29-96ba-1952d6832277",
            price: 64857.17,
            price_sources: [],
            processed_ms: 1711232624322,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "703432e6-9216-4a14-aeea-5641edd50869",
            price: 64706.68,
            price_sources: [],
            processed_ms: 1711236218001,
          },
        ],
        position_type: "FLAT",
        position_uuid: "1c2c5b47-e3c0-4f2e-8585-e905edea3508",
        return_at_close: 0.9974094729819386,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 64697.24,
        close_ms: 1711239861581,
        current_return: 0.9970455926713412,
        initial_entry_price: 64697.24,
        is_closed_position: true,
        miner_hotkey: "5Ck3US6cjMBoF49aLexLNKSS2WgZFMzxHhSzvw8wA18V33QA",
        net_leverage: 0.0,
        open_ms: 1711236323462,
        orders: [
          {
            leverage: 0.6,
            order_type: "LONG",
            order_uuid: "fe730865-70b7-4242-913c-b58dd69b850e",
            price: 64697.24,
            price_sources: [],
            processed_ms: 1711236323461,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "3e65df18-5c68-44ba-bdfb-5b52c002063a",
            price: 64378.67,
            price_sources: [],
            processed_ms: 1711239861581,
          },
        ],
        position_type: "FLAT",
        position_uuid: "49186823-c393-4127-ab29-cbeaa236f1e7",
        return_at_close: 0.9958491379601356,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 64366.91999999999,
        close_ms: 1711243525973,
        current_return: 1.0017419196071524,
        initial_entry_price: 64366.92,
        is_closed_position: true,
        miner_hotkey: "5Ck3US6cjMBoF49aLexLNKSS2WgZFMzxHhSzvw8wA18V33QA",
        net_leverage: 0.0,
        open_ms: 1711239975262,
        orders: [
          {
            leverage: 0.6,
            order_type: "LONG",
            order_uuid: "f254edd9-0a40-41a3-b1d0-4a3d64678a9c",
            price: 64366.92,
            price_sources: [],
            processed_ms: 1711239975260,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "a5e03767-93de-497c-b0dc-26bc6bd2ee66",
            price: 64553.79,
            price_sources: [],
            processed_ms: 1711243525973,
          },
        ],
        position_type: "FLAT",
        position_uuid: "a19edb1b-af26-4775-a9cb-2aae8daa9274",
        return_at_close: 1.0005398293036238,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 64574.58999999999,
        close_ms: 1711247185822,
        current_return: 0.9981061900664023,
        initial_entry_price: 64574.59,
        is_closed_position: true,
        miner_hotkey: "5Ck3US6cjMBoF49aLexLNKSS2WgZFMzxHhSzvw8wA18V33QA",
        net_leverage: 0.0,
        open_ms: 1711243612553,
        orders: [
          {
            leverage: 0.6,
            order_type: "LONG",
            order_uuid: "79ad7e41-bb50-48cb-b0c4-f3e212b0b799",
            price: 64574.59,
            price_sources: [],
            processed_ms: 1711243612552,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "00552c9c-fcb7-497b-899f-25b2bc63543b",
            price: 64370.77,
            price_sources: [],
            processed_ms: 1711247185822,
          },
        ],
        position_type: "FLAT",
        position_uuid: "23dd75ee-1dee-4d59-88db-f00b550448c3",
        return_at_close: 0.9969084626383227,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 64327.36,
        close_ms: 1711250858689,
        current_return: 0.9987731814270009,
        initial_entry_price: 64327.36,
        is_closed_position: true,
        miner_hotkey: "5Ck3US6cjMBoF49aLexLNKSS2WgZFMzxHhSzvw8wA18V33QA",
        net_leverage: 0.0,
        open_ms: 1711247342935,
        orders: [
          {
            leverage: 0.6,
            order_type: "LONG",
            order_uuid: "eaa223b2-cffa-45ef-b000-14577afdd92c",
            price: 64327.36,
            price_sources: [],
            processed_ms: 1711247342934,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "5880d305-d1c9-4f3c-bbeb-e39054e88c62",
            price: 64195.83,
            price_sources: [],
            processed_ms: 1711250858689,
          },
        ],
        position_type: "FLAT",
        position_uuid: "23588aac-c70d-42c9-8411-9f474381de28",
        return_at_close: 0.9975746536092885,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 64170.04,
        close_ms: 1711254514640,
        current_return: 0.9974761119051819,
        initial_entry_price: 64170.04,
        is_closed_position: true,
        miner_hotkey: "5Ck3US6cjMBoF49aLexLNKSS2WgZFMzxHhSzvw8wA18V33QA",
        net_leverage: 0.0,
        open_ms: 1711250947849,
        orders: [
          {
            leverage: 0.6,
            order_type: "LONG",
            order_uuid: "7fff1bba-df5c-42b2-b0a1-71d143707229",
            price: 64170.04,
            price_sources: [],
            processed_ms: 1711250947847,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "4aef0bc9-5a45-4505-ba6d-a2abd0b58095",
            price: 63900.11,
            price_sources: [],
            processed_ms: 1711254514640,
          },
        ],
        position_type: "FLAT",
        position_uuid: "50d59e2e-765c-4414-818e-b1b68fee50bc",
        return_at_close: 0.9962791405708957,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 63939.28,
        close_ms: 1711260583382,
        current_return: 1.0030486423994764,
        initial_entry_price: 63939.28,
        is_closed_position: true,
        miner_hotkey: "5Ck3US6cjMBoF49aLexLNKSS2WgZFMzxHhSzvw8wA18V33QA",
        net_leverage: 0.0,
        open_ms: 1711254645215,
        orders: [
          {
            leverage: 0.6,
            order_type: "LONG",
            order_uuid: "14237a76-e873-46ed-a920-fabe490da680",
            price: 63939.28,
            price_sources: [],
            processed_ms: 1711254645213,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "9799456f-1adb-41e6-aa93-b31d10b3a6da",
            price: 64264.16,
            price_sources: [],
            processed_ms: 1711260583382,
          },
        ],
        position_type: "FLAT",
        position_uuid: "545cf7c8-93fb-451b-8aa2-be4502e3dadd",
        return_at_close: 1.001844984028597,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 64324.59,
        close_ms: 1711269764126,
        current_return: 1.0049165179288355,
        initial_entry_price: 64324.59,
        is_closed_position: true,
        miner_hotkey: "5Ck3US6cjMBoF49aLexLNKSS2WgZFMzxHhSzvw8wA18V33QA",
        net_leverage: 0.0,
        open_ms: 1711266248607,
        orders: [
          {
            leverage: 0.7,
            order_type: "LONG",
            order_uuid: "9c01d834-e1c2-4535-8fbb-d10c184c7612",
            price: 64324.59,
            price_sources: [],
            processed_ms: 1711266248605,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "034e127e-19e7-4c8a-ba1d-63c35e033b49",
            price: 64776.38,
            price_sources: [],
            processed_ms: 1711269764126,
          },
        ],
        position_type: "FLAT",
        position_uuid: "5a0db3d0-1f30-41b8-869b-40645c72658e",
        return_at_close: 1.0035096348037351,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 64779.52,
        close_ms: 1711273432757,
        current_return: 1.001792155915944,
        initial_entry_price: 64779.52,
        is_closed_position: true,
        miner_hotkey: "5Ck3US6cjMBoF49aLexLNKSS2WgZFMzxHhSzvw8wA18V33QA",
        net_leverage: 0.0,
        open_ms: 1711269850522,
        orders: [
          {
            leverage: 0.7,
            order_type: "LONG",
            order_uuid: "17b4afdb-eec1-4bd4-9c85-e324f6c0125f",
            price: 64779.52,
            price_sources: [],
            processed_ms: 1711269850521,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "989450f6-54a7-4f90-8ed4-d5da74603558",
            price: 64945.37,
            price_sources: [],
            processed_ms: 1711273432757,
          },
        ],
        position_type: "FLAT",
        position_uuid: "1cd77483-6bfd-4bb7-bbb5-c5e7a6a195a7",
        return_at_close: 1.0003896468976616,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 65182.240000000005,
        close_ms: 1711282242605,
        current_return: 1.0034068789289843,
        initial_entry_price: 65182.24,
        is_closed_position: true,
        miner_hotkey: "5Ck3US6cjMBoF49aLexLNKSS2WgZFMzxHhSzvw8wA18V33QA",
        net_leverage: 0.0,
        open_ms: 1711277176524,
        orders: [
          {
            leverage: 0.7,
            order_type: "LONG",
            order_uuid: "e1c01183-2136-400e-9e0c-a5e5ffbfde26",
            price: 65182.24,
            price_sources: [],
            processed_ms: 1711277176523,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "9e825d64-0963-496c-9e46-2558fac68a82",
            price: 65499.48,
            price_sources: [],
            processed_ms: 1711282242605,
          },
        ],
        position_type: "FLAT",
        position_uuid: "3fdb41fd-10a1-432f-9d88-3c538f14d45d",
        return_at_close: 1.0020021092984837,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 65632.2,
        close_ms: 1711293231651,
        current_return: 1.0035162618348918,
        initial_entry_price: 65632.2,
        is_closed_position: true,
        miner_hotkey: "5Ck3US6cjMBoF49aLexLNKSS2WgZFMzxHhSzvw8wA18V33QA",
        net_leverage: 0.0,
        open_ms: 1711291407504,
        orders: [
          {
            leverage: -1.1,
            order_type: "SHORT",
            order_uuid: "82a27b98-c079-43e2-8f6a-fc1335948fe7",
            price: 65632.2,
            price_sources: [],
            processed_ms: 1711291407502,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "735f86ec-812f-4071-99f9-8ed4fdce486b",
            price: 65422.4,
            price_sources: [],
            processed_ms: 1711293231651,
          },
        ],
        position_type: "FLAT",
        position_uuid: "0b49c9a7-4079-4556-9907-8fb990d6feee",
        return_at_close: 1.001308526058855,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 65324.164,
        close_ms: 1711302521397,
        current_return: 0.9636165747994889,
        initial_entry_price: 65620.1,
        is_closed_position: true,
        miner_hotkey: "5Ck3US6cjMBoF49aLexLNKSS2WgZFMzxHhSzvw8wA18V33QA",
        net_leverage: 0.0,
        open_ms: 1711296899476,
        orders: [
          {
            leverage: -1.1,
            order_type: "SHORT",
            order_uuid: "416b8c8a-88b8-4368-b71a-199603c42461",
            price: 65620.1,
            price_sources: [],
            processed_ms: 1711296899474,
          },
          {
            leverage: -2.2,
            order_type: "SHORT",
            order_uuid: "7eb983ed-d174-4006-b69e-6a8b86e2e31c",
            price: 65626.34,
            price_sources: [],
            processed_ms: 1711298732579,
          },
          {
            leverage: -4.4,
            order_type: "SHORT",
            order_uuid: "38129851-f62e-4039-aa88-bf9f62474b7f",
            price: 64856.16,
            price_sources: [],
            processed_ms: 1711300567764,
          },
          {
            leverage: -8.8,
            order_type: "SHORT",
            order_uuid: "47a0da43-fc41-4f92-a802-c862c2e30196",
            price: 65445.63,
            price_sources: [],
            processed_ms: 1711302404341,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "8579d666-1089-4802-8448-d1d1d2f002b1",
            price: 65468.86,
            price_sources: [],
            processed_ms: 1711302521397,
          },
        ],
        position_type: "FLAT",
        position_uuid: "9b649ce8-5ef2-450b-8f20-256a5de023b6",
        return_at_close: 0.9318172278311058,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 66388.87999999992,
        close_ms: 1711314597258,
        current_return: 0.9999992665906586,
        initial_entry_price: 65993.16,
        is_closed_position: true,
        miner_hotkey: "5Ck3US6cjMBoF49aLexLNKSS2WgZFMzxHhSzvw8wA18V33QA",
        net_leverage: 0.0,
        open_ms: 1711305371354,
        orders: [
          {
            leverage: -1.1,
            order_type: "SHORT",
            order_uuid: "e514f7a8-77d4-48ea-9324-aad9d768a341",
            price: 65993.16,
            price_sources: [],
            processed_ms: 1711305371352,
          },
          {
            leverage: 0.10999999999999999,
            order_type: "LONG",
            order_uuid: "2681a34e-a7e6-481e-bda5-dab206978ba3",
            price: 65710.02,
            price_sources: [],
            processed_ms: 1711306213324,
          },
          {
            leverage: 0.10999999999999999,
            order_type: "LONG",
            order_uuid: "1cc0e953-cdbf-4e18-b1c8-3332f81fc7a7",
            price: 65870.21,
            price_sources: [],
            processed_ms: 1711307145877,
          },
          {
            leverage: 0.10999999999999999,
            order_type: "LONG",
            order_uuid: "3985ad9a-9989-422b-893e-5e899cfeec37",
            price: 65859.69,
            price_sources: [],
            processed_ms: 1711308077573,
          },
          {
            leverage: 0.10999999999999999,
            order_type: "LONG",
            order_uuid: "c3e42385-3728-4ace-8879-ef0b4107faa0",
            price: 65741.47,
            price_sources: [],
            processed_ms: 1711309018232,
          },
          {
            leverage: 0.10999999999999999,
            order_type: "LONG",
            order_uuid: "f4c742b7-10f5-463b-85f8-f943c5567622",
            price: 65909.49,
            price_sources: [],
            processed_ms: 1711309936656,
          },
          {
            leverage: 0.10999999999999999,
            order_type: "LONG",
            order_uuid: "5098a4e8-e84b-4bc7-9855-c4c94a981173",
            price: 65773.9,
            price_sources: [],
            processed_ms: 1711310869360,
          },
          {
            leverage: 0.10999999999999999,
            order_type: "LONG",
            order_uuid: "64cd1c75-84b5-4fb9-99eb-400f5b3ec23c",
            price: 66265.71,
            price_sources: [],
            processed_ms: 1711311809269,
          },
          {
            leverage: 0.11000000000000001,
            order_type: "LONG",
            order_uuid: "67303176-eb32-4690-b5f7-fd9b01af0689",
            price: 66241.67,
            price_sources: [],
            processed_ms: 1711312735579,
          },
          {
            leverage: 0.11000000000000001,
            order_type: "LONG",
            order_uuid: "eb3aba1b-7c03-441c-a722-94d754ef47b8",
            price: 66170.56,
            price_sources: [],
            processed_ms: 1711313672373,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "624ca63e-cfe9-48c3-ae9a-2a893dcd215c",
            price: 66389.32,
            price_sources: [],
            processed_ms: 1711314597258,
          },
        ],
        position_type: "FLAT",
        position_uuid: "969fbc97-ebc9-4f02-9951-cf533bfd2c80",
        return_at_close: 0.9977992682041592,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: -287265.6700000066,
        close_ms: 1711325774828,
        current_return: 0.9908249511914166,
        initial_entry_price: 66339.51,
        is_closed_position: true,
        miner_hotkey: "5Ck3US6cjMBoF49aLexLNKSS2WgZFMzxHhSzvw8wA18V33QA",
        net_leverage: 0.0,
        open_ms: 1711317393426,
        orders: [
          {
            leverage: -1.1,
            order_type: "SHORT",
            order_uuid: "df23b07a-6eef-4128-9c8f-718bfe223373",
            price: 66339.51,
            price_sources: [],
            processed_ms: 1711317393424,
          },
          {
            leverage: 0.55,
            order_type: "LONG",
            order_uuid: "74534b72-cb51-4bd2-aa67-d385ac092ff3",
            price: 66826.33,
            price_sources: [],
            processed_ms: 1711318325059,
          },
          {
            leverage: 0.275,
            order_type: "LONG",
            order_uuid: "4538bd3c-4077-4a71-a6a6-8374762c9685",
            price: 66876.08,
            price_sources: [],
            processed_ms: 1711319249472,
          },
          {
            leverage: 0.1375,
            order_type: "LONG",
            order_uuid: "f5579721-61e9-4702-bef2-9cdc85a57934",
            price: 66918.21,
            price_sources: [],
            processed_ms: 1711320187602,
          },
          {
            leverage: 0.06875,
            order_type: "LONG",
            order_uuid: "38292f74-3f7b-412d-83ae-5610f371ebb4",
            price: 66966.3,
            price_sources: [],
            processed_ms: 1711321119376,
          },
          {
            leverage: 0.05500000000000001,
            order_type: "LONG",
            order_uuid: "006a6768-2fbe-4132-991b-42008f539548",
            price: 67395.5,
            price_sources: [],
            processed_ms: 1711322048278,
          },
          {
            leverage: 0.006874999999999999,
            order_type: "LONG",
            order_uuid: "02081bf4-83fc-4493-bc7a-fb76e2f37caa",
            price: 67367.4,
            price_sources: [],
            processed_ms: 1711322980790,
          },
          {
            leverage: 0.0034374999999999996,
            order_type: "LONG",
            order_uuid: "1e89e486-7b94-4afa-8559-329af7f988f3",
            price: 67257.17,
            price_sources: [],
            processed_ms: 1711323914473,
          },
          {
            leverage: 0.0017187499999999998,
            order_type: "LONG",
            order_uuid: "981fc816-3a1c-4399-be1d-cb2a1ae79c57",
            price: 67204.93,
            price_sources: [],
            processed_ms: 1711324845767,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "07ba8192-e9bf-4e9f-8909-a273e755f200",
            price: 66868.58,
            price_sources: [],
            processed_ms: 1711325774828,
          },
        ],
        position_type: "FLAT",
        position_uuid: "ed43752b-6074-4a74-ac69-c7f9e861a426",
        return_at_close: 0.9886451362987955,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
    ],
    thirty_day_returns: 1.0,
  },
  "5CkVBq7FcvFePCPJemktexNT21H6iT26dETYufUckjubM1Xs": {
    all_time_returns: 1.0,
    n_positions: 0,
    percentage_profitable: 0.0,
    positions: [
      {
        average_entry_price: 3491.7300000000005,
        close_ms: 1711353003263,
        current_return: 0.8912859814475904,
        initial_entry_price: 3491.73,
        is_closed_position: true,
        miner_hotkey: "5CkVBq7FcvFePCPJemktexNT21H6iT26dETYufUckjubM1Xs",
        net_leverage: 10.0,
        open_ms: 1711343454488,
        orders: [
          {
            leverage: 10.0,
            order_type: "LONG",
            order_uuid: "36326f58-8290-4e0e-926d-c39f3fc0ee80",
            price: 3491.73,
            price_sources: [],
            processed_ms: 1711343454488,
          },
        ],
        position_type: "FLAT",
        position_uuid: "a112a052-6654-4020-9f9c-268dcb3ccf8d",
        return_at_close: 0.8734602618186386,
        trade_pair: ["ETHUSD", "ETH/USD", 0.003, 0.001, 20],
      },
    ],
    thirty_day_returns: 1.0,
  },
  "5CnuyazUFWumVNTxoqMPc3bWk3FBj6eUbRjHDm8jsrEqaDkS": {
    all_time_returns: 1.0665579017634685,
    n_positions: 32,
    percentage_profitable: 0.96875,
    positions: [
      {
        average_entry_price: 1.24528,
        close_ms: 1713444561875,
        current_return: 1.000007387896698,
        initial_entry_price: 1.24528,
        is_closed_position: true,
        miner_hotkey: "5CnuyazUFWumVNTxoqMPc3bWk3FBj6eUbRjHDm8jsrEqaDkS",
        net_leverage: 0.0,
        open_ms: 1713444186988,
        orders: [
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "709fe535-8c43-431f-9a33-9b38a5c94744",
            price: 1.24528,
            price_sources: [],
            processed_ms: 1713444186986,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "555652ce-35f0-42df-8619-56efa01a6bba",
            price: 1.2462,
            price_sources: [],
            processed_ms: 1713444561875,
          },
        ],
        position_type: "FLAT",
        position_uuid: "d113393e-822c-4071-a795-dff54d5afa03",
        return_at_close: 1.0000066878915266,
        trade_pair: ["GBPUSD", "GBP/USD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 1.37634,
        close_ms: 1713445149613,
        current_return: 1.000005812517256,
        initial_entry_price: 1.37634,
        is_closed_position: true,
        miner_hotkey: "5CnuyazUFWumVNTxoqMPc3bWk3FBj6eUbRjHDm8jsrEqaDkS",
        net_leverage: 0.0,
        open_ms: 1713443930857,
        orders: [
          {
            leverage: -0.01,
            order_type: "SHORT",
            order_uuid: "3400919c-1e43-4cfd-84a7-fa3ca90a7eba",
            price: 1.37634,
            price_sources: [],
            processed_ms: 1713443930856,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "6ecfb28a-a83e-4dd3-b175-a3155e0c7414",
            price: 1.37554,
            price_sources: [],
            processed_ms: 1713445149613,
          },
        ],
        position_type: "FLAT",
        position_uuid: "bf16e5ac-be91-42da-b4e9-33bc7423e1b6",
        return_at_close: 1.0000051125131872,
        trade_pair: ["USDCAD", "USD/CAD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.90977,
        close_ms: 1713446119453,
        current_return: 1.0000051661408929,
        initial_entry_price: 0.90977,
        is_closed_position: true,
        miner_hotkey: "5CnuyazUFWumVNTxoqMPc3bWk3FBj6eUbRjHDm8jsrEqaDkS",
        net_leverage: 0.0,
        open_ms: 1713443913933,
        orders: [
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "b267fc99-001f-49f9-9c9e-90fd32a7723b",
            price: 0.90977,
            price_sources: [],
            processed_ms: 1713443913933,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "5c632f0f-7428-4756-9cfe-3193a4d8c6a8",
            price: 0.91024,
            price_sources: [],
            processed_ms: 1713446119453,
          },
        ],
        position_type: "FLAT",
        position_uuid: "6c171c8c-38ea-416b-9ac6-b47a36c4426a",
        return_at_close: 1.0000044661372767,
        trade_pair: ["USDCHF", "USD/CHF", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 154.448,
        close_ms: 1713448407675,
        current_return: 1.0000069278980628,
        initial_entry_price: 154.448,
        is_closed_position: true,
        miner_hotkey: "5CnuyazUFWumVNTxoqMPc3bWk3FBj6eUbRjHDm8jsrEqaDkS",
        net_leverage: 0.0,
        open_ms: 1713443897966,
        orders: [
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "086bc951-d3a6-42ad-82d3-b86dcdb09be8",
            price: 154.448,
            price_sources: [],
            processed_ms: 1713443897965,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "fc087b6a-dc70-4275-a44e-808410e4516d",
            price: 154.555,
            price_sources: [],
            processed_ms: 1713448407675,
          },
        ],
        position_type: "FLAT",
        position_uuid: "79f3c3e6-78a4-4f9c-b71f-f97c4a3c8d0b",
        return_at_close: 1.0000062278932134,
        trade_pair: ["USDJPY", "USD/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 164.70924242424243,
        close_ms: 1713456489273,
        current_return: 1.0000145806765046,
        initial_entry_price: 164.67,
        is_closed_position: true,
        miner_hotkey: "5CnuyazUFWumVNTxoqMPc3bWk3FBj6eUbRjHDm8jsrEqaDkS",
        net_leverage: 0.0,
        open_ms: 1713444205062,
        orders: [
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "3939f07d-a15a-43b2-b94d-571a2b735cb5",
            price: 164.67,
            price_sources: [],
            processed_ms: 1713444203990,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "ac38c65c-c6a9-4fd0-9b75-e799f9d1463e",
            price: 164.623,
            price_sources: [],
            processed_ms: 1713447385867,
          },
          {
            leverage: -0.017,
            order_type: "SHORT",
            order_uuid: "210f909f-1658-45ad-948f-b44ec4f6cd27",
            price: 164.785,
            price_sources: [],
            processed_ms: 1713451881106,
          },
          {
            leverage: 0.03,
            order_type: "LONG",
            order_uuid: "918131df-1aa4-4499-b592-df09ec1cf766",
            price: 164.794,
            price_sources: [],
            processed_ms: 1713456392505,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "c739ec41-d0b4-40ab-a660-00d6c9a4884d",
            price: 164.782,
            price_sources: [],
            processed_ms: 1713456489273,
          },
        ],
        position_type: "FLAT",
        position_uuid: "b4aa6bba-1a66-4a57-b425-0e6b178056fe",
        return_at_close: 1.0000110806254723,
        trade_pair: ["EURJPY", "EUR/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 192.346,
        close_ms: 1713477025619,
        current_return: 1.0000106078175455,
        initial_entry_price: 192.311,
        is_closed_position: true,
        miner_hotkey: "5CnuyazUFWumVNTxoqMPc3bWk3FBj6eUbRjHDm8jsrEqaDkS",
        net_leverage: 0.0,
        open_ms: 1713444169771,
        orders: [
          {
            leverage: -0.01,
            order_type: "SHORT",
            order_uuid: "df642e96-c82d-4733-bba2-58b135e894b5",
            price: 192.311,
            price_sources: [],
            processed_ms: 1713444169770,
          },
          {
            leverage: -0.01,
            order_type: "SHORT",
            order_uuid: "6f461fb4-ec40-43c2-8ec3-067311138fcc",
            price: 192.381,
            price_sources: [],
            processed_ms: 1713447365559,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "71897855-c487-4a47-91b5-c595ff9b9beb",
            price: 192.244,
            price_sources: [],
            processed_ms: 1713477025619,
          },
        ],
        position_type: "FLAT",
        position_uuid: "0f4641f5-9e3d-4829-9005-75483faa6559",
        return_at_close: 1.0000092078026945,
        trade_pair: ["GBPJPY", "GBP/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.9701250000000001,
        close_ms: 1713523415747,
        current_return: 1.0000481577346272,
        initial_entry_price: 0.96973,
        is_closed_position: true,
        miner_hotkey: "5CnuyazUFWumVNTxoqMPc3bWk3FBj6eUbRjHDm8jsrEqaDkS",
        net_leverage: 0.0,
        open_ms: 1713444219548,
        orders: [
          {
            leverage: -0.01,
            order_type: "SHORT",
            order_uuid: "2ce9ac19-994f-40a9-9ef1-8c17ecbfa829",
            price: 0.96973,
            price_sources: [],
            processed_ms: 1713444219547,
          },
          {
            leverage: -0.01,
            order_type: "SHORT",
            order_uuid: "37c842e8-b9f9-4f37-bfcc-5defc4b5de40",
            price: 0.97052,
            price_sources: [],
            processed_ms: 1713447348806,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "69982d35-d54b-42aa-871f-a3c2a2d24b18",
            price: 0.96779,
            price_sources: [],
            processed_ms: 1713523415747,
          },
        ],
        position_type: "FLAT",
        position_uuid: "45e032f4-0869-4d98-8824-1fac5fb95e5e",
        return_at_close: 1.0000467576672063,
        trade_pair: ["EURCHF", "EUR/CHF", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 154.5364,
        close_ms: 1713525913101,
        current_return: 1.0000147460192215,
        initial_entry_price: 154.618,
        is_closed_position: true,
        miner_hotkey: "5CnuyazUFWumVNTxoqMPc3bWk3FBj6eUbRjHDm8jsrEqaDkS",
        net_leverage: 0.0,
        open_ms: 1713452498464,
        orders: [
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "9bdf7590-e1ad-41f6-8ae4-b7c9b5e21829",
            price: 154.618,
            price_sources: [],
            processed_ms: 1713452498461,
          },
          {
            leverage: 0.04,
            order_type: "LONG",
            order_uuid: "b3844c47-6ecb-4aec-a26a-b5e602b16b1f",
            price: 154.516,
            price_sources: [],
            processed_ms: 1713523466932,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "f17fa6de-65b1-4026-b02b-11ec2108df48",
            price: 154.582,
            price_sources: [],
            processed_ms: 1713525913101,
          },
        ],
        position_type: "FLAT",
        position_uuid: "a8292512-282f-464e-aa00-56a0ef0b46af",
        return_at_close: 1.0000112459676103,
        trade_pair: ["USDJPY", "USD/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 1.3770193333333334,
        close_ms: 1713527136553,
        current_return: 1.0000566298342541,
        initial_entry_price: 1.3756,
        is_closed_position: true,
        miner_hotkey: "5CnuyazUFWumVNTxoqMPc3bWk3FBj6eUbRjHDm8jsrEqaDkS",
        net_leverage: 0.0,
        open_ms: 1713452531772,
        orders: [
          {
            leverage: -0.01,
            order_type: "SHORT",
            order_uuid: "76045f2d-af4b-4d8a-a089-6ef185be594f",
            price: 1.3756,
            price_sources: [],
            processed_ms: 1713452531770,
          },
          {
            leverage: -0.05,
            order_type: "SHORT",
            order_uuid: "98044d9f-0de9-446b-b220-e8f9089bb10c",
            price: 1.37725,
            price_sources: [],
            processed_ms: 1713523433369,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "06bccaac-118f-4805-b05e-f29c493d22ff",
            price: 1.37696,
            price_sources: [],
            processed_ms: 1713523649224,
          },
          {
            leverage: -0.1,
            order_type: "SHORT",
            order_uuid: "39e50639-2ae8-4616-8255-1caa1192ac05",
            price: 1.37704,
            price_sources: [],
            processed_ms: 1713523666231,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "c242b0cd-2e5e-41a2-9d21-30f9becfeff0",
            price: 1.3765,
            price_sources: [],
            processed_ms: 1713527136553,
          },
        ],
        position_type: "FLAT",
        position_uuid: "53a70dba-272f-437d-adb0-08c4e11c1b15",
        return_at_close: 1.0000454292,
        trade_pair: ["USDCAD", "USD/CAD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 1.2436933333333335,
        close_ms: 1713527557945,
        current_return: 1.0000088438655732,
        initial_entry_price: 1.2438,
        is_closed_position: true,
        miner_hotkey: "5CnuyazUFWumVNTxoqMPc3bWk3FBj6eUbRjHDm8jsrEqaDkS",
        net_leverage: 0.0,
        open_ms: 1713523632944,
        orders: [
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "190f9308-963d-4623-8fea-3ff173c5d261",
            price: 1.2438,
            price_sources: [],
            processed_ms: 1713523632941,
          },
          {
            leverage: 0.02,
            order_type: "LONG",
            order_uuid: "dfdb8f0b-fe75-453c-afc2-ddbb0143bb87",
            price: 1.24364,
            price_sources: [],
            processed_ms: 1713526107764,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "230fde17-ec1b-435d-b288-aab53f381e26",
            price: 1.24406,
            price_sources: [],
            processed_ms: 1713527557945,
          },
        ],
        position_type: "FLAT",
        position_uuid: "a43beec0-136d-4ff0-aba4-1e15055ccd51",
        return_at_close: 1.000006743847001,
        trade_pair: ["GBPUSD", "GBP/USD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 154.578,
        close_ms: 1713530854873,
        current_return: 1.000002522998098,
        initial_entry_price: 154.578,
        is_closed_position: true,
        miner_hotkey: "5CnuyazUFWumVNTxoqMPc3bWk3FBj6eUbRjHDm8jsrEqaDkS",
        net_leverage: 0.0,
        open_ms: 1713526461374,
        orders: [
          {
            leverage: -0.01,
            order_type: "SHORT",
            order_uuid: "bbda9bf3-195a-4b89-bf1e-4f585c37cca7",
            price: 154.578,
            price_sources: [],
            processed_ms: 1713526461372,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "7b70d582-cc21-4043-bef3-65733e4868ef",
            price: 154.539,
            price_sources: [],
            processed_ms: 1713530854873,
          },
        ],
        position_type: "FLAT",
        position_uuid: "bcb68605-d568-435f-9005-1ee6ef790740",
        return_at_close: 1.000001822996332,
        trade_pair: ["USDJPY", "USD/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 1.4616280066445182,
        close_ms: 1713955440360,
        current_return: 1.0001706542552828,
        initial_entry_price: 1.46747,
        is_closed_position: true,
        miner_hotkey: "5CnuyazUFWumVNTxoqMPc3bWk3FBj6eUbRjHDm8jsrEqaDkS",
        net_leverage: 0.0,
        open_ms: 1713444252289,
        orders: [
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "ae5a486f-a44f-4694-81fb-13672a2fa353",
            price: 1.46747,
            price_sources: [],
            processed_ms: 1713444252287,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "b7bf2146-45ff-47b3-aefa-06d7eab2989c",
            price: 1.46672,
            price_sources: [],
            processed_ms: 1713447332314,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "5127f771-33e1-410d-a44e-7ebd63919242",
            price: 1.46713,
            price_sources: [],
            processed_ms: 1713523503246,
          },
          {
            leverage: 0.05,
            order_type: "LONG",
            order_uuid: "3dedfe68-ef75-436b-a130-ecd42d6bb2d8",
            price: 1.4664,
            price_sources: [],
            processed_ms: 1713525988947,
          },
          {
            leverage: -0.079,
            order_type: "SHORT",
            order_uuid: "037440a1-425a-427c-9c45-d3a455f62d55",
            price: 1.46723,
            price_sources: [],
            processed_ms: 1713530830888,
          },
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "6fada5e2-10bd-42dd-85f8-fca67b128766",
            price: 1.46176,
            price_sources: [],
            processed_ms: 1713943547521,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "7e131040-e87f-43ba-a614-2607eefb62dc",
            price: 1.46246,
            price_sources: [],
            processed_ms: 1713955440360,
          },
        ],
        position_type: "FLAT",
        position_uuid: "c74f6a43-9008-4736-affe-38811293952f",
        return_at_close: 1.0001440497158796,
        trade_pair: ["EURCAD", "EUR/CAD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 1.0282181818181857,
        close_ms: 1714157139346,
        current_return: 1.0004325079722378,
        initial_entry_price: 1.0662,
        is_closed_position: true,
        miner_hotkey: "5CnuyazUFWumVNTxoqMPc3bWk3FBj6eUbRjHDm8jsrEqaDkS",
        net_leverage: 0.0,
        open_ms: 1713444235596,
        orders: [
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "899755b7-d14c-4574-8f50-7f971e90744a",
            price: 1.0662,
            price_sources: [],
            processed_ms: 1713444235595,
          },
          {
            leverage: 0.04,
            order_type: "LONG",
            order_uuid: "88c4cb0e-4dbb-4e1c-a1fa-c4da525b2cc2",
            price: 1.0652,
            price_sources: [],
            processed_ms: 1713523449714,
          },
          {
            leverage: 0.05,
            order_type: "LONG",
            order_uuid: "a024cfab-2d6a-401b-95e5-97a66a4171ac",
            price: 1.0652,
            price_sources: [],
            processed_ms: 1713525953510,
          },
          {
            leverage: -0.099,
            order_type: "SHORT",
            order_uuid: "74b498b4-d111-4a77-b6a7-2e5de09cdbd3",
            price: 1.0661,
            price_sources: [],
            processed_ms: 1713529363458,
          },
          {
            leverage: 0.45,
            order_type: "LONG",
            order_uuid: "639c2e35-9587-4e7e-8e76-b1ed7c30fef5",
            price: 1.06895,
            price_sources: [],
            processed_ms: 1713943531042,
          },
          {
            leverage: -0.44,
            order_type: "SHORT",
            order_uuid: "d32bbd05-6cf3-4f07-8e30-fc9e1dce1322",
            price: 1.06978,
            price_sources: [],
            processed_ms: 1714052913161,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "58b25ba4-2a1e-4040-b72a-4116055f905e",
            price: 1.07014,
            price_sources: [],
            processed_ms: 1714157139346,
          },
        ],
        position_type: "FLAT",
        position_uuid: "e40b1ddf-307b-4dc9-9c5d-c568b7cf1f03",
        return_at_close: 1.0003939913206807,
        trade_pair: ["EURUSD", "EUR/USD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 102.745,
        close_ms: 1714409072338,
        current_return: 1.000452576767726,
        initial_entry_price: 102.745,
        is_closed_position: true,
        miner_hotkey: "5CnuyazUFWumVNTxoqMPc3bWk3FBj6eUbRjHDm8jsrEqaDkS",
        net_leverage: 0.0,
        open_ms: 1714403529628,
        orders: [
          {
            leverage: -0.1,
            order_type: "SHORT",
            order_uuid: "1868395d-fd1a-4771-b3ad-18a87acfa960",
            price: 102.745,
            price_sources: [],
            processed_ms: 1714403529624,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "e38ece9f-9049-48b8-92e6-73a3684b04c8",
            price: 102.28,
            price_sources: [],
            processed_ms: 1714409072338,
          },
        ],
        position_type: "FLAT",
        position_uuid: "eeaf8b35-58ae-4593-b398-512df71820bc",
        return_at_close: 1.0004455735996887,
        trade_pair: ["AUDJPY", "AUD/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.656,
        close_ms: 1714409089186,
        current_return: 1.0001600609756098,
        initial_entry_price: 0.656,
        is_closed_position: true,
        miner_hotkey: "5CnuyazUFWumVNTxoqMPc3bWk3FBj6eUbRjHDm8jsrEqaDkS",
        net_leverage: 0.0,
        open_ms: 1714403497672,
        orders: [
          {
            leverage: 0.1,
            order_type: "LONG",
            order_uuid: "0c39c218-0eff-414f-b8ca-77fdc2eccccf",
            price: 0.656,
            price_sources: [],
            processed_ms: 1714403497667,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "0eea9b18-fee2-48e4-8b65-78fe69616a24",
            price: 0.65705,
            price_sources: [],
            processed_ms: 1714409089186,
          },
        ],
        position_type: "FLAT",
        position_uuid: "77965fad-0b89-41d6-93b4-e9a3f8bb6afc",
        return_at_close: 1.000153059855183,
        trade_pair: ["AUDUSD", "AUD/USD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 1.25504,
        close_ms: 1714409192516,
        current_return: 1.0001250956144825,
        initial_entry_price: 1.25504,
        is_closed_position: true,
        miner_hotkey: "5CnuyazUFWumVNTxoqMPc3bWk3FBj6eUbRjHDm8jsrEqaDkS",
        net_leverage: 0.0,
        open_ms: 1714403596473,
        orders: [
          {
            leverage: 0.1,
            order_type: "LONG",
            order_uuid: "203f68f3-4fb3-4ad5-b50b-db81d27b4989",
            price: 1.25504,
            price_sources: [],
            processed_ms: 1714403596468,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "6c4237ac-3c93-45ef-9f6f-9d15e5274da4",
            price: 1.25661,
            price_sources: [],
            processed_ms: 1714409192516,
          },
        ],
        position_type: "FLAT",
        position_uuid: "61e90b53-01bf-4e21-a4d6-18205fc926e6",
        return_at_close: 1.0001180947388133,
        trade_pair: ["GBPUSD", "GBP/USD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.8955860838302931,
        close_ms: 1714409210242,
        current_return: 1.0001200426116856,
        initial_entry_price: 0.895586083830293,
        is_closed_position: true,
        miner_hotkey: "5CnuyazUFWumVNTxoqMPc3bWk3FBj6eUbRjHDm8jsrEqaDkS",
        net_leverage: 0.0,
        open_ms: 1714403546164,
        orders: [
          {
            leverage: 0.1,
            order_type: "LONG",
            order_uuid: "f6fb0fd9-7973-447b-93ed-b4fd0e1cfd08",
            price: 0.895586083830293,
            price_sources: [],
            processed_ms: 1714403546159,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "afc4140a-4ea1-471d-b61c-2090b75110cf",
            price: 0.896661168755216,
            price_sources: [],
            processed_ms: 1714409210242,
          },
        ],
        position_type: "FLAT",
        position_uuid: "5387066f-8716-4f0c-8135-9f037b9dc7b2",
        return_at_close: 1.0001130417713873,
        trade_pair: ["AUDCAD", "AUD/CAD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 114.43350000000002,
        close_ms: 1714462744382,
        current_return: 1.0002972117873672,
        initial_entry_price: 114.733,
        is_closed_position: true,
        miner_hotkey: "5CnuyazUFWumVNTxoqMPc3bWk3FBj6eUbRjHDm8jsrEqaDkS",
        net_leverage: 0.0,
        open_ms: 1714403480911,
        orders: [
          {
            leverage: 0.1,
            order_type: "LONG",
            order_uuid: "c8e57423-5137-4bed-8841-a0bb3a9b43ce",
            price: 114.733,
            price_sources: [],
            processed_ms: 1714403480905,
          },
          {
            leverage: 0.1,
            order_type: "LONG",
            order_uuid: "0fb7e850-acb0-4bd5-b17e-122a9e0711fe",
            price: 114.134,
            price_sources: [],
            processed_ms: 1714410137905,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "861cae30-615b-4579-b163-02e22969ec3e",
            price: 114.604,
            price_sources: [],
            processed_ms: 1714462744382,
          },
        ],
        position_type: "FLAT",
        position_uuid: "85643eec-5971-4027-bb19-ba15e64fa795",
        return_at_close: 1.000283207626402,
        trade_pair: ["CADJPY", "CAD/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 196.163,
        close_ms: 1714462759802,
        current_return: 1.000511803011803,
        initial_entry_price: 196.56,
        is_closed_position: true,
        miner_hotkey: "5CnuyazUFWumVNTxoqMPc3bWk3FBj6eUbRjHDm8jsrEqaDkS",
        net_leverage: 0.0,
        open_ms: 1714403579621,
        orders: [
          {
            leverage: 0.1,
            order_type: "LONG",
            order_uuid: "36b3460a-83de-4b41-aec9-b9e5515c266f",
            price: 196.56,
            price_sources: [],
            processed_ms: 1714403579616,
          },
          {
            leverage: 0.1,
            order_type: "LONG",
            order_uuid: "4ee61fd7-94ce-4ec8-8b4a-ab783c23d4a9",
            price: 195.766,
            price_sources: [],
            processed_ms: 1714410156469,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "6df86297-dbd8-43bc-9208-84ae72aa2327",
            price: 196.666,
            price_sources: [],
            processed_ms: 1714462759802,
          },
        ],
        position_type: "FLAT",
        position_uuid: "0ca40deb-3339-4b88-8933-53a72288ea70",
        return_at_close: 1.0004977958465608,
        trade_pair: ["GBPJPY", "GBP/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 1.79468,
        close_ms: 1714462778772,
        current_return: 1.0004708360264782,
        initial_entry_price: 1.79468,
        is_closed_position: true,
        miner_hotkey: "5CnuyazUFWumVNTxoqMPc3bWk3FBj6eUbRjHDm8jsrEqaDkS",
        net_leverage: 0.0,
        open_ms: 1714403563660,
        orders: [
          {
            leverage: 0.1,
            order_type: "LONG",
            order_uuid: "eeb5e394-2a75-4a6f-9fe8-584ec74b8d7e",
            price: 1.79468,
            price_sources: [],
            processed_ms: 1714403563647,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "eb509b13-3720-4e2b-a9b2-662724eb4ade",
            price: 1.80313,
            price_sources: [],
            processed_ms: 1714462778772,
          },
        ],
        position_type: "FLAT",
        position_uuid: "924c579c-bc74-423f-a982-de33fb503a85",
        return_at_close: 1.0004638327306261,
        trade_pair: ["EURNZD", "EUR/NZD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.85425,
        close_ms: 1714631772435,
        current_return: 1.0001767632426104,
        initial_entry_price: 0.85425,
        is_closed_position: true,
        miner_hotkey: "5CnuyazUFWumVNTxoqMPc3bWk3FBj6eUbRjHDm8jsrEqaDkS",
        net_leverage: 0.0,
        open_ms: 1714403447909,
        orders: [
          {
            leverage: 0.1,
            order_type: "LONG",
            order_uuid: "1292dc91-83d5-45d9-b93b-1ac26f00141e",
            price: 0.85425,
            price_sources: [],
            processed_ms: 1714403447905,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "f8d32874-90bb-4e24-b46c-1749753c76af",
            price: 0.85576,
            price_sources: [],
            processed_ms: 1714631772435,
          },
        ],
        position_type: "FLAT",
        position_uuid: "ef4ff6c7-6838-4193-a0ed-3e88a128264e",
        return_at_close: 1.0001697620052676,
        trade_pair: ["EURGBP", "EUR/GBP", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 165.483,
        close_ms: 1714654236995,
        current_return: 1.0002368823383672,
        initial_entry_price: 165.483,
        is_closed_position: true,
        miner_hotkey: "5CnuyazUFWumVNTxoqMPc3bWk3FBj6eUbRjHDm8jsrEqaDkS",
        net_leverage: 0.0,
        open_ms: 1713948001028,
        orders: [
          {
            leverage: -0.35,
            order_type: "SHORT",
            order_uuid: "f48bcea8-bdaf-440e-897f-a1986fa5ebb7",
            price: 165.483,
            price_sources: [],
            processed_ms: 1713948001023,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "22c4e572-925f-44d4-8ea0-948ff4e409f0",
            price: 165.371,
            price_sources: [],
            processed_ms: 1714654236995,
          },
        ],
        position_type: "FLAT",
        position_uuid: "7d5e76a7-a732-4ebb-9153-76d511b55383",
        return_at_close: 1.00021237653475,
        trade_pair: ["EURJPY", "EUR/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 153.8548339483395,
        close_ms: 1715040452774,
        current_return: 1.0640778767919405,
        initial_entry_price: 154.86,
        is_closed_position: true,
        miner_hotkey: "5CnuyazUFWumVNTxoqMPc3bWk3FBj6eUbRjHDm8jsrEqaDkS",
        net_leverage: 0.0,
        open_ms: 1714650662096,
        orders: [
          {
            leverage: 0.1,
            order_type: "LONG",
            order_uuid: "7bbb2f33-a6ca-4e54-a924-d621493ca7ee",
            price: 154.86,
            price_sources: [],
            processed_ms: 1714650661878,
          },
          {
            leverage: 7.0,
            order_type: "LONG",
            order_uuid: "95dcfdad-d995-45c4-ba97-7181d65f5aa5",
            price: 153.78,
            price_sources: [],
            processed_ms: 1714994959551,
          },
          {
            leverage: 20.0,
            order_type: "LONG",
            order_uuid: "084101b0-4a78-4a2a-aedf-19f78da4b392",
            price: 153.876,
            price_sources: [],
            processed_ms: 1715015614913,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "cfbd54fd-52e5-46fe-94bd-60f39d169f36",
            price: 154.221,
            price_sources: [],
            processed_ms: 1715040452774,
          },
        ],
        position_type: "FLAT",
        position_uuid: "71ef95b5-a805-4e88-8426-dc8f490b8c38",
        return_at_close: 1.0620593210596663,
        trade_pair: ["USDJPY", "USD/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 166.072,
        close_ms: 1715041664462,
        current_return: 1.0001969025482924,
        initial_entry_price: 166.072,
        is_closed_position: true,
        miner_hotkey: "5CnuyazUFWumVNTxoqMPc3bWk3FBj6eUbRjHDm8jsrEqaDkS",
        net_leverage: 0.0,
        open_ms: 1715041016923,
        orders: [
          {
            leverage: -0.3,
            order_type: "SHORT",
            order_uuid: "64d26245-306b-43e4-832f-42e8eee8fce2",
            price: 166.072,
            price_sources: [],
            processed_ms: 1715041016681,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "1d3e14f7-713c-4851-91f4-4ba6b2613873",
            price: 165.963,
            price_sources: [],
            processed_ms: 1715041664462,
          },
        ],
        position_type: "FLAT",
        position_uuid: "633c8f9d-097d-4269-a410-432ccd43f07d",
        return_at_close: 1.0001758984133389,
        trade_pair: ["EURJPY", "EUR/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.66293,
        close_ms: 1715042339183,
        current_return: 1.0002126921394416,
        initial_entry_price: 0.66293,
        is_closed_position: true,
        miner_hotkey: "5CnuyazUFWumVNTxoqMPc3bWk3FBj6eUbRjHDm8jsrEqaDkS",
        net_leverage: 0.0,
        open_ms: 1715040983650,
        orders: [
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "2183d473-bf2a-4244-8dfd-4c0f619817e6",
            price: 0.66293,
            price_sources: [],
            processed_ms: 1715040983469,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "53125144-b626-4d7d-a77a-f23b17559a4e",
            price: 0.6634,
            price_sources: [],
            processed_ms: 1715042339183,
          },
        ],
        position_type: "FLAT",
        position_uuid: "c2b0fc7a-6c8f-436b-9264-30a7edf4e873",
        return_at_close: 1.0001916876729067,
        trade_pair: ["AUDUSD", "AUD/USD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.90605,
        close_ms: 1715042503234,
        current_return: 1.0001710722366315,
        initial_entry_price: 0.90605,
        is_closed_position: true,
        miner_hotkey: "5CnuyazUFWumVNTxoqMPc3bWk3FBj6eUbRjHDm8jsrEqaDkS",
        net_leverage: 0.0,
        open_ms: 1715040930599,
        orders: [
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "8da962e1-78e5-46d1-acc6-bd3fa998b84c",
            price: 0.90605,
            price_sources: [],
            processed_ms: 1715040929972,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "62c8d729-bb04-48e7-8425-e6dbb2cb3272",
            price: 0.90667,
            price_sources: [],
            processed_ms: 1715042503234,
          },
        ],
        position_type: "FLAT",
        position_uuid: "6db3ba88-8d11-430c-bb84-c3d6287de22a",
        return_at_close: 1.0001535692428674,
        trade_pair: ["AUDCAD", "AUD/CAD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 102.22,
        close_ms: 1715042622609,
        current_return: 1.0001296223830953,
        initial_entry_price: 102.22,
        is_closed_position: true,
        miner_hotkey: "5CnuyazUFWumVNTxoqMPc3bWk3FBj6eUbRjHDm8jsrEqaDkS",
        net_leverage: 0.0,
        open_ms: 1715040949927,
        orders: [
          {
            leverage: -0.25,
            order_type: "SHORT",
            order_uuid: "73ce641a-5f79-4de0-928a-f2226d8f6ce6",
            price: 102.22,
            price_sources: [],
            processed_ms: 1715040949739,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "75c27fd1-a741-4100-95ee-cd5dd9f99c0a",
            price: 102.167,
            price_sources: [],
            processed_ms: 1715042622609,
          },
        ],
        position_type: "FLAT",
        position_uuid: "22bf1534-49d9-4fe9-a424-46e7875efdc6",
        return_at_close: 1.0001121201147036,
        trade_pair: ["AUDJPY", "AUD/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.9761733333333332,
        close_ms: 1715043488755,
        current_return: 1.0000758336578468,
        initial_entry_price: 0.97582,
        is_closed_position: true,
        miner_hotkey: "5CnuyazUFWumVNTxoqMPc3bWk3FBj6eUbRjHDm8jsrEqaDkS",
        net_leverage: 0.0,
        open_ms: 1714650631296,
        orders: [
          {
            leverage: 0.1,
            order_type: "LONG",
            order_uuid: "3c357828-b0f0-49b5-acb4-ebeb0fe6c56f",
            price: 0.97582,
            price_sources: [],
            processed_ms: 1714650630789,
          },
          {
            leverage: 0.2,
            order_type: "LONG",
            order_uuid: "640902be-c0de-4c8e-94f0-a457ac1b6d5b",
            price: 0.97635,
            price_sources: [],
            processed_ms: 1715042829186,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "b96f51e2-d1fc-4285-b76f-72bbe6fe5f34",
            price: 0.97642,
            price_sources: [],
            processed_ms: 1715043488755,
          },
        ],
        position_type: "FLAT",
        position_uuid: "30e3a3c8-18fc-4018-bd39-abcd67711ce2",
        return_at_close: 1.00005483206534,
        trade_pair: ["EURCHF", "EUR/CHF", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 193.72559440559445,
        close_ms: 1715045392629,
        current_return: 1.0004724303336034,
        initial_entry_price: 193.673,
        is_closed_position: true,
        miner_hotkey: "5CnuyazUFWumVNTxoqMPc3bWk3FBj6eUbRjHDm8jsrEqaDkS",
        net_leverage: 0.0,
        open_ms: 1715041090703,
        orders: [
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "b4339358-5be5-4c36-9615-150574f58281",
            price: 193.673,
            price_sources: [],
            processed_ms: 1715041090505,
          },
          {
            leverage: -0.299,
            order_type: "SHORT",
            order_uuid: "dee8390e-d108-4f65-9dbf-8e98c8527dbe",
            price: 193.42,
            price_sources: [],
            processed_ms: 1715043721988,
          },
          {
            leverage: 1.0,
            order_type: "LONG",
            order_uuid: "b754fb5d-ac10-4c6c-aa63-cf149f4e1483",
            price: 193.65,
            price_sources: [],
            processed_ms: 1715044335913,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "cde7baa8-9f40-48af-82c1-a7e3ac025bfc",
            price: 193.817,
            price_sources: [],
            processed_ms: 1715045392629,
          },
        ],
        position_type: "FLAT",
        position_uuid: "01d1bc1e-9f8e-466e-941c-f6581dd70017",
        return_at_close: 1.0003813873424432,
        trade_pair: ["GBPJPY", "GBP/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 1.79075,
        close_ms: 1715049118923,
        current_return: 1.000135418120899,
        initial_entry_price: 1.79075,
        is_closed_position: true,
        miner_hotkey: "5CnuyazUFWumVNTxoqMPc3bWk3FBj6eUbRjHDm8jsrEqaDkS",
        net_leverage: 0.0,
        open_ms: 1715041034642,
        orders: [
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "48ce0dc9-6b42-44bd-8c72-7a6c10d3af82",
            price: 1.79075,
            price_sources: [],
            processed_ms: 1715041034451,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "b1ca5116-c697-4ebb-a5f3-24ff9ea197bb",
            price: 1.79172,
            price_sources: [],
            processed_ms: 1715049118923,
          },
        ],
        position_type: "FLAT",
        position_uuid: "422d6a24-0a14-4f0c-bbc3-1aa88bf894a3",
        return_at_close: 1.000117915751082,
        trade_pair: ["EURNZD", "EUR/NZD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 68691.99585365852,
        close_ms: 1716667355963,
        current_return: 1.0003049353790483,
        initial_entry_price: 64068.23,
        is_closed_position: true,
        miner_hotkey: "5CnuyazUFWumVNTxoqMPc3bWk3FBj6eUbRjHDm8jsrEqaDkS",
        net_leverage: 0.0,
        open_ms: 1714852915553,
        orders: [
          {
            leverage: 0.001,
            order_type: "LONG",
            order_uuid: "95d5cd6f-84e6-4b0b-9303-b73c6800e5dd",
            price: 64068.23,
            price_sources: [],
            processed_ms: 1714852915362,
          },
          {
            leverage: 0.04,
            order_type: "LONG",
            order_uuid: "232701b8-58fd-45cf-987a-badf05359f7c",
            price: 68807.59,
            price_sources: [],
            processed_ms: 1716577691054,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "e90c380e-410b-4506-972a-d8b1a9121bce",
            price: 69168.5,
            price_sources: [],
            processed_ms: 1716667355963,
          },
        ],
        position_type: "FLAT",
        position_uuid: "4951bd66-d150-4a62-969b-05faca0f6e95",
        return_at_close: 1.0002639228766979,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 0.6974173875587402,
        close_ms: 1718392283681,
        current_return: 0.9996546190041641,
        initial_entry_price: 1.098370873875588,
        is_closed_position: true,
        miner_hotkey: "5CnuyazUFWumVNTxoqMPc3bWk3FBj6eUbRjHDm8jsrEqaDkS",
        net_leverage: 0.0,
        open_ms: 1714403513824,
        orders: [
          {
            leverage: -0.1,
            order_type: "SHORT",
            order_uuid: "2a46d0d8-f6fb-47f9-bed5-517ef209d884",
            price: 1.098370873875588,
            price_sources: [],
            processed_ms: 1714403513821,
          },
          {
            leverage: -0.3,
            order_type: "SHORT",
            order_uuid: "c6103962-07f3-4fc0-a54e-10856bdfab9c",
            price: 1.1023,
            price_sources: [],
            processed_ms: 1715040966725,
          },
          {
            leverage: 0.399,
            order_type: "LONG",
            order_uuid: "9c30dd8b-25a4-4d4d-8af9-bf0ecc96692c",
            price: 1.10233,
            price_sources: [],
            processed_ms: 1715045454854,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "7042ba28-9ea0-417c-a04e-6d430de499c3",
            price: 1.0767738137750884,
            price_sources: [],
            processed_ms: 1718392283681,
          },
        ],
        position_type: "FLAT",
        position_uuid: "4fb5d235-724e-4520-8642-1cef9390e5f0",
        return_at_close: 0.999626628674832,
        trade_pair: ["AUDNZD", "AUD/NZD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 155.73529925187032,
        close_ms: 0,
        current_return: 0.9689175399047613,
        initial_entry_price: 153.929,
        is_closed_position: false,
        miner_hotkey: "5CnuyazUFWumVNTxoqMPc3bWk3FBj6eUbRjHDm8jsrEqaDkS",
        net_leverage: -0.802,
        open_ms: 1715042748215,
        orders: [
          {
            leverage: -0.5,
            order_type: "SHORT",
            order_uuid: "cb485493-3b54-47d2-b277-5b27842ddb78",
            price: 153.929,
            price_sources: [],
            processed_ms: 1715042748022,
          },
          {
            leverage: 0.498,
            order_type: "LONG",
            order_uuid: "f6b7e03b-fb13-474c-9c8f-f546e375af9f",
            price: 154.255,
            price_sources: [],
            processed_ms: 1715045269451,
          },
          {
            leverage: -0.3,
            order_type: "SHORT",
            order_uuid: "3e3cd9e1-70ee-4375-9a76-ea7f1e202e2e",
            price: 155.729,
            price_sources: [],
            processed_ms: 1716160867292,
          },
          {
            leverage: -0.5,
            order_type: "SHORT",
            order_uuid: "c98c3e7c-e16f-415c-be2d-0f9a70bb60a8",
            price: 156.071,
            price_sources: [],
            processed_ms: 1716301147736,
          },
        ],
        position_type: "SHORT",
        position_uuid: "60f91e82-033a-4dfe-b244-795439b324bd",
        return_at_close: 0.9688565659239751,
        trade_pair: ["USDJPY", "USD/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 3776.0509090909086,
        close_ms: 0,
        current_return: 0.9532393390021711,
        initial_entry_price: 3758.56,
        is_closed_position: false,
        miner_hotkey: "5CnuyazUFWumVNTxoqMPc3bWk3FBj6eUbRjHDm8jsrEqaDkS",
        net_leverage: 0.275,
        open_ms: 1716667378377,
        orders: [
          {
            leverage: 0.075,
            order_type: "LONG",
            order_uuid: "6c89b4e0-b07d-4176-887c-09256534dcdb",
            price: 3758.56,
            price_sources: [],
            processed_ms: 1716667378176,
          },
          {
            leverage: 0.2,
            order_type: "LONG",
            order_uuid: "a1a04aa7-b732-4006-92a1-9a5cbfcc2468",
            price: 3782.61,
            price_sources: [],
            processed_ms: 1717226559839,
          },
        ],
        position_type: "LONG",
        position_uuid: "4f816cf2-c9ba-44f6-9c79-2e51f40d0d75",
        return_at_close: 0.9531082685930582,
        trade_pair: ["ETHUSD", "ETH/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 183.93599999998838,
        close_ms: 0,
        current_return: 0.9994225495670364,
        initial_entry_price: 112.827,
        is_closed_position: false,
        miner_hotkey: "5CnuyazUFWumVNTxoqMPc3bWk3FBj6eUbRjHDm8jsrEqaDkS",
        net_leverage: 0.0010000000000000009,
        open_ms: 1715041000254,
        orders: [
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "bbebc8ce-81ba-4776-9f60-f00865ad88e5",
            price: 112.827,
            price_sources: [],
            processed_ms: 1715041000054,
          },
          {
            leverage: 0.5,
            order_type: "LONG",
            order_uuid: "4dadee30-2e88-4fc5-a44f-1dc3d9dab829",
            price: 112.664,
            price_sources: [],
            processed_ms: 1715042766928,
          },
          {
            leverage: -0.799,
            order_type: "SHORT",
            order_uuid: "93319ec9-b937-47a1-9578-eb8af9b44a79",
            price: 112.636,
            price_sources: [],
            processed_ms: 1715043663363,
          },
        ],
        position_type: "LONG",
        position_uuid: "ff631c44-5310-4651-8eec-8fd8841abed1",
        return_at_close: 0.9993666168840498,
        trade_pair: ["CADJPY", "CAD/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.9349700000000342,
        close_ms: 0,
        current_return: 0.9998631349243199,
        initial_entry_price: 1.0769,
        is_closed_position: false,
        miner_hotkey: "5CnuyazUFWumVNTxoqMPc3bWk3FBj6eUbRjHDm8jsrEqaDkS",
        net_leverage: -0.0010000000000000009,
        open_ms: 1715041053832,
        orders: [
          {
            leverage: -0.25,
            order_type: "SHORT",
            order_uuid: "5eb867f4-64a8-4cf3-896d-56e3eeec2982",
            price: 1.0769,
            price_sources: [],
            processed_ms: 1715041053654,
          },
          {
            leverage: 0.249,
            order_type: "LONG",
            order_uuid: "fd299eff-1765-4222-98d6-604ce63b1c11",
            price: 1.07747,
            price_sources: [],
            processed_ms: 1715043889620,
          },
        ],
        position_type: "SHORT",
        position_uuid: "2325dc3f-c8e3-4588-84c4-61d20b671bc4",
        return_at_close: 0.9998456723146685,
        trade_pair: ["EURUSD", "EUR/USD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.21417999999995255,
        close_ms: 0,
        current_return: 0.9991394807697564,
        initial_entry_price: 1.46332,
        is_closed_position: false,
        miner_hotkey: "5CnuyazUFWumVNTxoqMPc3bWk3FBj6eUbRjHDm8jsrEqaDkS",
        net_leverage: -0.0010000000000000009,
        open_ms: 1714403464163,
        orders: [
          {
            leverage: -0.1,
            order_type: "SHORT",
            order_uuid: "572c9b0e-1562-4e78-87a5-960650bfe9b6",
            price: 1.46332,
            price_sources: [],
            processed_ms: 1714403464159,
          },
          {
            leverage: -0.1,
            order_type: "SHORT",
            order_uuid: "79a66dfa-aab1-4501-8e19-b20b4c2caf7c",
            price: 1.46846,
            price_sources: [],
            processed_ms: 1714650612668,
          },
          {
            leverage: 0.199,
            order_type: "LONG",
            order_uuid: "9ad1765e-6e4e-4862-9a90-348f5f26d42f",
            price: 1.47218,
            price_sources: [],
            processed_ms: 1715043584044,
          },
        ],
        position_type: "SHORT",
        position_uuid: "bd4d8976-de27-4d99-b1b6-f85534617b2f",
        return_at_close: 0.9991255277869076,
        trade_pair: ["EURCAD", "EUR/CAD", 7e-5, 0.001, 500],
      },
    ],
    thirty_day_returns: 1.0,
  },
  "5CoU9wjAD292DrdEM77f7PGNHuhWo8oMFL2xYoXyNMvy2z4y": {
    all_time_returns: 1.0,
    n_positions: 0,
    percentage_profitable: 0.0,
    positions: [
      {
        average_entry_price: 3442.460588235294,
        close_ms: 0,
        current_return: 0.9923403828569743,
        initial_entry_price: 3390.3,
        is_closed_position: false,
        miner_hotkey: "5CoU9wjAD292DrdEM77f7PGNHuhWo8oMFL2xYoXyNMvy2z4y",
        net_leverage: 0.085,
        open_ms: 1719337508369,
        orders: [
          {
            leverage: 0.02,
            order_type: "LONG",
            order_uuid: "822d2edd-608a-4997-ad21-14a3d2d72f88",
            price: 3390.3,
            price_sources: [],
            processed_ms: 1719337507559,
          },
          {
            leverage: 0.065,
            order_type: "LONG",
            order_uuid: "0c220bdb-e35f-4a6b-83f8-c313ad4eb6d4",
            price: 3458.51,
            price_sources: [],
            processed_ms: 1719842684600,
          },
        ],
        position_type: "LONG",
        position_uuid: "bd967b54-df1e-43ea-bdd6-03b557ef872d",
        return_at_close: 0.9922982083907029,
        trade_pair: ["ETHUSD", "ETH/USD", 0.003, 0.001, 20],
      },
    ],
    thirty_day_returns: 1.0,
  },
  "5CqW5oS1RUCWCkNy1tXwrbMfVJfr9rPiQ2GtjtsxFy7XHsNm": {
    all_time_returns: 1.0,
    n_positions: 0,
    percentage_profitable: 0.0,
    positions: [
      {
        average_entry_price: 70534.18833333334,
        close_ms: 1711463622521,
        current_return: 0.9017457218737048,
        initial_entry_price: 70551.33,
        is_closed_position: true,
        miner_hotkey: "5CqW5oS1RUCWCkNy1tXwrbMfVJfr9rPiQ2GtjtsxFy7XHsNm",
        net_leverage: 6.0,
        open_ms: 1711462196960,
        orders: [
          {
            leverage: 1.0,
            order_type: "LONG",
            order_uuid: "b0c82ff2-34a7-4d13-bfce-99ec25f4eab7",
            price: 70551.33,
            price_sources: [],
            processed_ms: 1711462196958,
          },
          {
            leverage: 1.0,
            order_type: "LONG",
            order_uuid: "35cd76b1-63ca-4421-84c4-05ee86c84a38",
            price: 70551.33,
            price_sources: [],
            processed_ms: 1711462200385,
          },
          {
            leverage: 1.0,
            order_type: "LONG",
            order_uuid: "91dbbc25-3d97-422a-aa08-c273b1738e38",
            price: 70551.33,
            price_sources: [],
            processed_ms: 1711462201236,
          },
          {
            leverage: 1.0,
            order_type: "LONG",
            order_uuid: "05432d4a-7199-4f3e-8c8e-aaf2f6a00335",
            price: 70551.33,
            price_sources: [],
            processed_ms: 1711462201552,
          },
          {
            leverage: 1.0,
            order_type: "LONG",
            order_uuid: "99fe477c-2962-41ae-8a23-e46915c6d0c3",
            price: 70551.33,
            price_sources: [],
            processed_ms: 1711462201621,
          },
          {
            leverage: 1.0,
            order_type: "LONG",
            order_uuid: "382ef2f4-bc31-40b3-92f4-d5ae570a2a3f",
            price: 70448.48,
            price_sources: [],
            processed_ms: 1711462243187,
          },
        ],
        position_type: "FLAT",
        position_uuid: "0aca4c35-4b18-47c7-b908-80a084f68b29",
        return_at_close: 0.8909247732112203,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
    ],
    thirty_day_returns: 1.0,
  },
  "5CqqEFtxwURz8uQ9wvKyfw9hNJWwYQxPSFfnyj7omQqVUzfd": {
    all_time_returns: 1.0,
    n_positions: 0,
    percentage_profitable: 0.0,
    positions: [
      {
        average_entry_price: 3433.35234042553,
        close_ms: 0,
        current_return: 0.9551853269681141,
        initial_entry_price: 3108.56,
        is_closed_position: false,
        miner_hotkey: "5CqqEFtxwURz8uQ9wvKyfw9hNJWwYQxPSFfnyj7omQqVUzfd",
        net_leverage: 0.4700000000000001,
        open_ms: 1716112596651,
        orders: [
          {
            leverage: 0.06,
            order_type: "LONG",
            order_uuid: "a1399693-4118-4d47-8c52-14a72c8bfc50",
            price: 3108.56,
            price_sources: [],
            processed_ms: 1716112594877,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "d91e5b04-d77b-42bc-9c69-0fc432132bc4",
            price: 3095.2,
            price_sources: [],
            processed_ms: 1716206535471,
          },
          {
            leverage: 1.0,
            order_type: "LONG",
            order_uuid: "306947f8-4ed0-4938-9835-8d680ce79af0",
            price: 3502.99,
            price_sources: [],
            processed_ms: 1718276370268,
          },
          {
            leverage: -0.6,
            order_type: "SHORT",
            order_uuid: "6bc1f52b-4e7d-459e-8253-ae4fceda32a6",
            price: 3511.3,
            price_sources: [],
            processed_ms: 1719131967676,
          },
        ],
        position_type: "LONG",
        position_uuid: "0581b0f1-1959-4690-a033-e61d599377b2",
        return_at_close: 0.9543877472200957,
        trade_pair: ["ETHUSD", "ETH/USD", 0.003, 0.001, 20],
      },
    ],
    thirty_day_returns: 1.0,
  },
  "5CrGaMAv5guzzoyef6XBUPiBGhsrnox7nxPayV8DPzZh1zQL": {
    all_time_returns: 1.045290423886098,
    n_positions: 12,
    percentage_profitable: 1.0,
    positions: [
      {
        average_entry_price: 1.071008,
        close_ms: 1714485650063,
        current_return: 1.0000361582763313,
        initial_entry_price: 1.07306,
        is_closed_position: true,
        miner_hotkey: "5CrGaMAv5guzzoyef6XBUPiBGhsrnox7nxPayV8DPzZh1zQL",
        net_leverage: 0.0,
        open_ms: 1714470836601,
        orders: [
          {
            leverage: -0.01,
            order_type: "SHORT",
            order_uuid: "5ea5c9cb-7d67-4e68-85ea-0eaac80c583f",
            price: 1.07306,
            price_sources: [],
            processed_ms: 1714470836600,
          },
          {
            leverage: -0.09,
            order_type: "SHORT",
            order_uuid: "e9010e73-f8d2-4071-a11a-a26a51d8f351",
            price: 1.07078,
            price_sources: [],
            processed_ms: 1714482796482,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "fdb2d635-e82d-4fce-abc3-dcb921aacc1a",
            price: 1.07062,
            price_sources: [],
            processed_ms: 1714485650063,
          },
        ],
        position_type: "FLAT",
        position_uuid: "d9ddab6c-610a-47c4-ab8a-e313fa934ca6",
        return_at_close: 1.0000291580232235,
        trade_pair: ["EURUSD", "EUR/USD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 1.1552600000000064,
        close_ms: 1715014729634,
        current_return: 1.0002735565312844,
        initial_entry_price: 0.911,
        is_closed_position: true,
        miner_hotkey: "5CrGaMAv5guzzoyef6XBUPiBGhsrnox7nxPayV8DPzZh1zQL",
        net_leverage: 0.0,
        open_ms: 1714470855672,
        orders: [
          {
            leverage: -0.01,
            order_type: "SHORT",
            order_uuid: "2ac82268-a20a-4c22-bdc9-bc0cf75f7568",
            price: 0.911,
            price_sources: [],
            processed_ms: 1714470855672,
          },
          {
            leverage: -0.09,
            order_type: "SHORT",
            order_uuid: "d91423b6-f579-4d45-bc62-570ffca60fec",
            price: 0.91499,
            price_sources: [],
            processed_ms: 1714482821858,
          },
          {
            leverage: 0.099,
            order_type: "LONG",
            order_uuid: "c4a6c7df-b178-4e89-8827-34bb15070417",
            price: 0.91216,
            price_sources: [],
            processed_ms: 1714650214043,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "0561ccba-d508-4fb0-8a96-7e5e60fc5747",
            price: 0.90605,
            price_sources: [],
            processed_ms: 1715014729634,
          },
        ],
        position_type: "FLAT",
        position_uuid: "1127dd22-65b4-4d36-9b3b-4f30813968e9",
        return_at_close: 1.0002665546163887,
        trade_pair: ["USDCHF", "USD/CHF", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.8587000000000011,
        close_ms: 1715272549985,
        current_return: 1.000121042563856,
        initial_entry_price: 0.97759,
        is_closed_position: true,
        miner_hotkey: "5CrGaMAv5guzzoyef6XBUPiBGhsrnox7nxPayV8DPzZh1zQL",
        net_leverage: 0.0,
        open_ms: 1714470817083,
        orders: [
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "4d9bb238-435f-4c0b-93e0-ae013263cecd",
            price: 0.97759,
            price_sources: [],
            processed_ms: 1714470817083,
          },
          {
            leverage: 0.09,
            order_type: "LONG",
            order_uuid: "b1e50677-9044-4723-80c2-259a8ea92d84",
            price: 0.9798,
            price_sources: [],
            processed_ms: 1714482774210,
          },
          {
            leverage: -0.099,
            order_type: "SHORT",
            order_uuid: "1257c4bc-7c21-457b-9b4d-82913fa2ad61",
            price: 0.9808,
            price_sources: [],
            processed_ms: 1714526077157,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "eeed5dea-47ee-49eb-971a-a3856de21cab",
            price: 0.97703,
            price_sources: [],
            processed_ms: 1715272549985,
          },
        ],
        position_type: "FLAT",
        position_uuid: "a3dfab9b-704f-4ac7-89d8-d69dc7f2668b",
        return_at_close: 1.0001140417165582,
        trade_pair: ["EURCHF", "EUR/CHF", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 113.68327272727272,
        close_ms: 1715304063476,
        current_return: 1.0002155437861806,
        initial_entry_price: 113.666,
        is_closed_position: true,
        miner_hotkey: "5CrGaMAv5guzzoyef6XBUPiBGhsrnox7nxPayV8DPzZh1zQL",
        net_leverage: 0.0,
        open_ms: 1715272483098,
        orders: [
          {
            leverage: -0.1,
            order_type: "SHORT",
            order_uuid: "64717ab3-8d48-43ac-888f-2fa09a80aa40",
            price: 113.666,
            price_sources: [],
            processed_ms: 1715272482557,
          },
          {
            leverage: -1.0,
            order_type: "SHORT",
            order_uuid: "8a179f62-0ae1-4ad6-a9d0-52496c3d40e6",
            price: 113.685,
            price_sources: [],
            processed_ms: 1715303878809,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "9cf10dc6-c23e-4bf8-b929-d55c47a7b0ee",
            price: 113.661,
            price_sources: [],
            processed_ms: 1715304063476,
          },
        ],
        position_type: "FLAT",
        position_uuid: "8e481417-46f7-4ebf-a053-3310f20b9164",
        return_at_close: 1.000138527189309,
        trade_pair: ["CADJPY", "CAD/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 1.0968643478260869,
        close_ms: 1715304375742,
        current_return: 1.0002783954217394,
        initial_entry_price: 1.09736,
        is_closed_position: true,
        miner_hotkey: "5CrGaMAv5guzzoyef6XBUPiBGhsrnox7nxPayV8DPzZh1zQL",
        net_leverage: 0.0,
        open_ms: 1715272499793,
        orders: [
          {
            leverage: 0.15,
            order_type: "LONG",
            order_uuid: "89677239-ede9-4ca7-a329-96783d01962a",
            price: 1.09736,
            price_sources: [],
            processed_ms: 1715272498842,
          },
          {
            leverage: 1.0,
            order_type: "LONG",
            order_uuid: "5d7a8d15-b178-4ca4-b9c8-d57724e34d55",
            price: 1.09679,
            price_sources: [],
            processed_ms: 1715303753823,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "9ed24964-b9d3-4223-ad6f-1c295a2cd0fc",
            price: 1.09713,
            price_sources: [],
            processed_ms: 1715304375742,
          },
        ],
        position_type: "FLAT",
        position_uuid: "ad632d68-3bdf-473e-8d2f-5646814e719c",
        return_at_close: 1.0001978730109078,
        trade_pair: ["AUDNZD", "AUD/NZD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 102.788,
        close_ms: 1715304392477,
        current_return: 1.0001070163832353,
        initial_entry_price: 102.788,
        is_closed_position: true,
        miner_hotkey: "5CrGaMAv5guzzoyef6XBUPiBGhsrnox7nxPayV8DPzZh1zQL",
        net_leverage: 0.0,
        open_ms: 1715272463783,
        orders: [
          {
            leverage: 0.1,
            order_type: "LONG",
            order_uuid: "a6cee74a-2b9f-4d70-ba0c-85b3d33b073c",
            price: 102.788,
            price_sources: [],
            processed_ms: 1715272462955,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "72ad8217-dd8d-450a-809d-5de5832a2642",
            price: 102.898,
            price_sources: [],
            processed_ms: 1715304392477,
          },
        ],
        position_type: "FLAT",
        position_uuid: "7dfe334c-78e0-45ad-ba23-7bcc12f7a1ec",
        return_at_close: 1.0001000156341207,
        trade_pair: ["AUDJPY", "AUD/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 194.716,
        close_ms: 1715305030519,
        current_return: 1.000297869717948,
        initial_entry_price: 194.716,
        is_closed_position: true,
        miner_hotkey: "5CrGaMAv5guzzoyef6XBUPiBGhsrnox7nxPayV8DPzZh1zQL",
        net_leverage: 0.0,
        open_ms: 1715303788098,
        orders: [
          {
            leverage: 1.0,
            order_type: "LONG",
            order_uuid: "b2c25591-58f9-4f50-b9aa-9143d3abdeda",
            price: 194.716,
            price_sources: [],
            processed_ms: 1715303787917,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "cc99a7ff-1ede-48ff-a5d5-5da4687f6f49",
            price: 194.774,
            price_sources: [],
            processed_ms: 1715305030519,
          },
        ],
        position_type: "FLAT",
        position_uuid: "83455833-ea62-4057-9940-5832e65f4577",
        return_at_close: 1.0002278488670677,
        trade_pair: ["GBPJPY", "GBP/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.9046118181818181,
        close_ms: 1715305046773,
        current_return: 1.000172522477688,
        initial_entry_price: 0.90423,
        is_closed_position: true,
        miner_hotkey: "5CrGaMAv5guzzoyef6XBUPiBGhsrnox7nxPayV8DPzZh1zQL",
        net_leverage: 0.0,
        open_ms: 1715272445971,
        orders: [
          {
            leverage: -0.1,
            order_type: "SHORT",
            order_uuid: "0c76e348-31f0-4725-a3ae-48b7fe8f3042",
            price: 0.90423,
            price_sources: [],
            processed_ms: 1715272445645,
          },
          {
            leverage: -1.0,
            order_type: "SHORT",
            order_uuid: "898dbc5d-bb8b-4dd4-be3b-3ba14b2001c1",
            price: 0.90465,
            price_sources: [],
            processed_ms: 1715303770483,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "5783006a-a920-4002-b5e3-2fb65d07f32d",
            price: 0.90447,
            price_sources: [],
            processed_ms: 1715305046773,
          },
        ],
        position_type: "FLAT",
        position_uuid: "2fdf6044-0e26-4b3d-9884-93fc94c1243c",
        return_at_close: 1.0000955091934574,
        trade_pair: ["AUDCAD", "AUD/CAD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.6613747826086956,
        close_ms: 1715305310685,
        current_return: 1.0001824264995307,
        initial_entry_price: 0.66054,
        is_closed_position: true,
        miner_hotkey: "5CrGaMAv5guzzoyef6XBUPiBGhsrnox7nxPayV8DPzZh1zQL",
        net_leverage: 0.0,
        open_ms: 1715272516262,
        orders: [
          {
            leverage: -0.15,
            order_type: "SHORT",
            order_uuid: "054f36d9-ffc1-4c5a-8b4a-ac10c8f28a2b",
            price: 0.66054,
            price_sources: [],
            processed_ms: 1715272515895,
          },
          {
            leverage: -1.0,
            order_type: "SHORT",
            order_uuid: "13c8e0d0-e6f9-4cf9-abe8-4acae1182374",
            price: 0.6615,
            price_sources: [],
            processed_ms: 1715303737235,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "1127edd6-e477-4d8e-8af5-41deb74ffa57",
            price: 0.66127,
            price_sources: [],
            processed_ms: 1715305310685,
          },
        ],
        position_type: "FLAT",
        position_uuid: "2bc7456c-d9d3-4cba-98a0-c274edb340af",
        return_at_close: 1.0001019118141974,
        trade_pair: ["AUDUSD", "AUD/USD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 155.512,
        close_ms: 1715305547410,
        current_return: 1.0244354133443077,
        initial_entry_price: 155.512,
        is_closed_position: true,
        miner_hotkey: "5CrGaMAv5guzzoyef6XBUPiBGhsrnox7nxPayV8DPzZh1zQL",
        net_leverage: 0.0,
        open_ms: 1715303714830,
        orders: [
          {
            leverage: 40.0,
            order_type: "LONG",
            order_uuid: "f152f2d6-4345-4373-9906-bfe229145ddd",
            price: 155.512,
            price_sources: [],
            processed_ms: 1715303711371,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "e420e92b-3853-4542-8123-bc04feea2091",
            price: 155.607,
            price_sources: [],
            processed_ms: 1715305547410,
          },
        ],
        position_type: "FLAT",
        position_uuid: "4d4f9c84-a660-4abb-b439-5ef9373818b4",
        return_at_close: 1.0215669941869436,
        trade_pair: ["USDJPY", "USD/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.8608218181818181,
        close_ms: 1715306215475,
        current_return: 1.0001893059555886,
        initial_entry_price: 0.86104,
        is_closed_position: true,
        miner_hotkey: "5CrGaMAv5guzzoyef6XBUPiBGhsrnox7nxPayV8DPzZh1zQL",
        net_leverage: 0.0,
        open_ms: 1715272570166,
        orders: [
          {
            leverage: 0.1,
            order_type: "LONG",
            order_uuid: "ec37edb7-1415-48a5-98c1-c951340e9f78",
            price: 0.86104,
            price_sources: [],
            processed_ms: 1715272569877,
          },
          {
            leverage: 1.0,
            order_type: "LONG",
            order_uuid: "9e5a3b07-e2c4-4c3a-96fb-f057b93268d2",
            price: 0.8608,
            price_sources: [],
            processed_ms: 1715303862839,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "f166568a-1b69-484d-8bbf-6653dfd9b5af",
            price: 0.86097,
            price_sources: [],
            processed_ms: 1715306215475,
          },
        ],
        position_type: "FLAT",
        position_uuid: "bc1bb948-6d31-447d-9e94-88177ddb001a",
        return_at_close: 1.0001122913790301,
        trade_pair: ["EURGBP", "EUR/GBP", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 155.611,
        close_ms: 1715306873773,
        current_return: 1.024676918726826,
        initial_entry_price: 155.611,
        is_closed_position: true,
        miner_hotkey: "5CrGaMAv5guzzoyef6XBUPiBGhsrnox7nxPayV8DPzZh1zQL",
        net_leverage: 0.0,
        open_ms: 1715305665190,
        orders: [
          {
            leverage: 40.0,
            order_type: "LONG",
            order_uuid: "2ab7b79c-dfe7-4f88-97d3-393136605d4c",
            price: 155.611,
            price_sources: [],
            processed_ms: 1715305665012,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "177536e5-689c-4f3f-be55-c90917fd55bc",
            price: 155.707,
            price_sources: [],
            processed_ms: 1715306873773,
          },
        ],
        position_type: "FLAT",
        position_uuid: "a54ecbad-35e7-4990-b875-62ae12f83871",
        return_at_close: 1.021807823354391,
        trade_pair: ["USDJPY", "USD/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 167.688,
        close_ms: 0,
        current_return: 0.9956243142025667,
        initial_entry_price: 167.688,
        is_closed_position: false,
        miner_hotkey: "5CrGaMAv5guzzoyef6XBUPiBGhsrnox7nxPayV8DPzZh1zQL",
        net_leverage: -0.1,
        open_ms: 1715272588886,
        orders: [
          {
            leverage: -0.1,
            order_type: "SHORT",
            order_uuid: "5a75e7c3-c93e-4964-8788-43463bf452b5",
            price: 167.688,
            price_sources: [],
            processed_ms: 1715272588470,
          },
        ],
        position_type: "SHORT",
        position_uuid: "050f2412-ee1b-45e3-a4a0-0c1d757efdc3",
        return_at_close: 0.9956208295174669,
        trade_pair: ["EURJPY", "EUR/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 70504.96583850932,
        close_ms: 0,
        current_return: 0.9376771688661661,
        initial_entry_price: 63900.9,
        is_closed_position: false,
        miner_hotkey: "5CrGaMAv5guzzoyef6XBUPiBGhsrnox7nxPayV8DPzZh1zQL",
        net_leverage: 0.322,
        open_ms: 1714856311137,
        orders: [
          {
            leverage: 0.002,
            order_type: "LONG",
            order_uuid: "4d7da386-deb5-4e85-bb15-18ae31355d6c",
            price: 63900.9,
            price_sources: [],
            processed_ms: 1714856310961,
          },
          {
            leverage: 0.02,
            order_type: "LONG",
            order_uuid: "41127a39-faa1-4156-9764-77df8df39cbd",
            price: 66350.86,
            price_sources: [],
            processed_ms: 1716156801343,
          },
          {
            leverage: 0.1,
            order_type: "LONG",
            order_uuid: "a860dfa1-53c5-4661-938f-abec653abbba",
            price: 70615.8,
            price_sources: [],
            processed_ms: 1716302096243,
          },
          {
            leverage: 0.2,
            order_type: "LONG",
            order_uuid: "04d1eb6d-0ee6-4833-b9a4-5423db930644",
            price: 70931.0,
            price_sources: [],
            processed_ms: 1717587317579,
          },
        ],
        position_type: "LONG",
        position_uuid: "700c1582-f836-4c5d-9bed-b43db742def9",
        return_at_close: 0.9375262028419787,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
    ],
    thirty_day_returns: 1.0,
  },
  "5Cvn18M5pxDM7BarjkHA431kStF9yTgX86gwiXzbkcU5da3x": {
    all_time_returns: 1.0,
    n_positions: 0,
    percentage_profitable: 0.0,
    positions: [
      {
        average_entry_price: 70510.18999999999,
        close_ms: 1711544078105,
        current_return: 0.97045555342472,
        initial_entry_price: 70551.33,
        is_closed_position: true,
        miner_hotkey: "5Cvn18M5pxDM7BarjkHA431kStF9yTgX86gwiXzbkcU5da3x",
        net_leverage: 0.0,
        open_ms: 1711462199863,
        orders: [
          {
            leverage: 1.0,
            order_type: "LONG",
            order_uuid: "70cb8971-885b-4c5d-ab31-ae13cc939339",
            price: 70551.33,
            price_sources: [],
            processed_ms: 1711462199863,
          },
          {
            leverage: 1.0,
            order_type: "LONG",
            order_uuid: "0b5a304d-4e15-4fa5-8ed8-7721a7112cd6",
            price: 70551.33,
            price_sources: [],
            processed_ms: 1711462202038,
          },
          {
            leverage: 1.0,
            order_type: "LONG",
            order_uuid: "9e0b999d-6c10-4b4c-ae7e-cf2168e6adc8",
            price: 70551.33,
            price_sources: [],
            processed_ms: 1711462203114,
          },
          {
            leverage: 1.0,
            order_type: "LONG",
            order_uuid: "032302d9-a232-49d1-be48-430db5137b1f",
            price: 70448.48,
            price_sources: [],
            processed_ms: 1711462243913,
          },
          {
            leverage: 1.0,
            order_type: "LONG",
            order_uuid: "8ba498bd-d017-4787-9af4-322e46101dc1",
            price: 70448.48,
            price_sources: [],
            processed_ms: 1711462245394,
          },
          {
            leverage: 6.0,
            order_type: "FLAT",
            order_uuid: "8aff045d-0535-46a2-8d87-48470d0d273c",
            price: 70093.31,
            price_sources: [],
            processed_ms: 1711544078105,
          },
        ],
        position_type: "FLAT",
        position_uuid: "a3f7d6af-d1d7-400f-af03-3729a725be04",
        return_at_close: 0.9607509978904728,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 70245.43,
        close_ms: 1712048648256,
        current_return: 0.9385407705526182,
        initial_entry_price: 70245.43,
        is_closed_position: true,
        miner_hotkey: "5Cvn18M5pxDM7BarjkHA431kStF9yTgX86gwiXzbkcU5da3x",
        net_leverage: 1.0,
        open_ms: 1711544536523,
        orders: [
          {
            leverage: 1.0,
            order_type: "LONG",
            order_uuid: "4f6786a3-0814-4c7a-96b9-c2dd750c5e8d",
            price: 70245.43,
            price_sources: [],
            processed_ms: 1711544536522,
          },
        ],
        position_type: "FLAT",
        position_uuid: "511ba937-010b-4484-9079-4dfa8f8f459e",
        return_at_close: 0.936663689011513,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
    ],
    thirty_day_returns: 1.0,
  },
  "5D2e2RSGCp1huC61raoNfwh3tusSyj3HTffz3AnQrkaBRfh9": {
    all_time_returns: 1.0,
    n_positions: 0,
    percentage_profitable: 0.0,
    positions: [
      {
        average_entry_price: 69637.5,
        close_ms: 1711980369738,
        current_return: 1.0010344989598257,
        initial_entry_price: 69598.91,
        is_closed_position: true,
        miner_hotkey: "5D2e2RSGCp1huC61raoNfwh3tusSyj3HTffz3AnQrkaBRfh9",
        net_leverage: 0.0,
        open_ms: 1711381158218,
        orders: [
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "7c3fd75d-9bed-40de-bb2a-db4aa1fe7b76",
            price: 69598.91,
            price_sources: [],
            processed_ms: 1711381158217,
          },
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "1c24a4a8-261e-4e04-b020-ca07ca975896",
            price: 69676.09,
            price_sources: [],
            processed_ms: 1711383595244,
          },
          {
            leverage: 19.4,
            order_type: "FLAT",
            order_uuid: "01bf1cd6-19bf-47be-a9f8-4874e52c9627",
            price: 69757.5,
            price_sources: [],
            processed_ms: 1711980369738,
          },
        ],
        position_type: "FLAT",
        position_uuid: "c7bbe87b-2974-46d6-93d2-919458425b9b",
        return_at_close: 0.9998332575610739,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 5225.12988,
        close_ms: 1711980474960,
        current_return: 1.0134198080450392,
        initial_entry_price: 5225.12988,
        is_closed_position: true,
        miner_hotkey: "5D2e2RSGCp1huC61raoNfwh3tusSyj3HTffz3AnQrkaBRfh9",
        net_leverage: 0.0,
        open_ms: 1711381081751,
        orders: [
          {
            leverage: 1.0,
            order_type: "LONG",
            order_uuid: "56cbab19-3c59-4bc2-bd28-0acbe171ec41",
            price: 5225.12988,
            price_sources: [],
            processed_ms: 1711381081750,
          },
          {
            leverage: 1.0,
            order_type: "LONG",
            order_uuid: "deb01849-4190-4256-8819-87bd8cce8beb",
            price: 5225.12988,
            price_sources: [],
            processed_ms: 1711381083087,
          },
          {
            leverage: -1.0,
            order_type: "SHORT",
            order_uuid: "14cbac5a-ed65-4397-ab79-17a88dc5905b",
            price: 5225.12988,
            price_sources: [],
            processed_ms: 1711381085206,
          },
          {
            leverage: 1.0,
            order_type: "LONG",
            order_uuid: "250053c3-6916-4a4e-b3e2-ed2699f128c6",
            price: 5225.12988,
            price_sources: [],
            processed_ms: 1711381085513,
          },
          {
            leverage: 19.9,
            order_type: "FLAT",
            order_uuid: "c16d98bf-e8a6-40a8-8b27-d1b476dc8f3e",
            price: 5260.19,
            price_sources: [],
            processed_ms: 1711980474960,
          },
        ],
        position_type: "FLAT",
        position_uuid: "2aff5abd-55a7-4f08-b02a-a39bb83cc3e6",
        return_at_close: 1.0132373924795912,
        trade_pair: ["SPX", "SPX", 9e-5, 0.001, 500],
      },
      {
        average_entry_price: 69758.1,
        close_ms: 1711981374263,
        current_return: 0.9315633596671903,
        initial_entry_price: 69758.1,
        is_closed_position: true,
        miner_hotkey: "5D2e2RSGCp1huC61raoNfwh3tusSyj3HTffz3AnQrkaBRfh9",
        net_leverage: 19.9,
        open_ms: 1711980613948,
        orders: [
          {
            leverage: 19.9,
            order_type: "LONG",
            order_uuid: "59b090ec-4a42-42c7-96df-0b0cacc5e019",
            price: 69758.1,
            price_sources: [],
            processed_ms: 1711980613948,
          },
        ],
        position_type: "FLAT",
        position_uuid: "75aec6dc-491f-47c1-a20d-05d1584eb782",
        return_at_close: 0.8944871379524362,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
    ],
    thirty_day_returns: 1.0,
  },
  "5D2muvq5yU8FrimKgrmySenHLiQ27eq3qG7ptDs8RuUJWHBY": {
    all_time_returns: 1.0,
    n_positions: 0,
    percentage_profitable: 0.0,
    positions: [
      {
        average_entry_price: 3868.9,
        close_ms: 1719235695798,
        current_return: 1.0145224740882421,
        initial_entry_price: 3868.9,
        is_closed_position: true,
        miner_hotkey: "5D2muvq5yU8FrimKgrmySenHLiQ27eq3qG7ptDs8RuUJWHBY",
        net_leverage: 0.0,
        open_ms: 1717637607899,
        orders: [
          {
            leverage: -0.1,
            order_type: "SHORT",
            order_uuid: "20b63062-8ce8-469c-b733-c5866881de57",
            price: 3868.9,
            price_sources: [],
            processed_ms: 1717637607672,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "802e630f-90d6-480e-99ea-ac702580ec93",
            price: 3307.04,
            price_sources: [],
            processed_ms: 1719235695798,
          },
        ],
        position_type: "FLAT",
        position_uuid: "61a62668-5045-45f7-9fd6-ce2101bf29d9",
        return_at_close: 1.0144210218408334,
        trade_pair: ["ETHUSD", "ETH/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 64583.80666666666,
        close_ms: 0,
        current_return: 0.965511966160289,
        initial_entry_price: 71159.0,
        is_closed_position: false,
        miner_hotkey: "5D2muvq5yU8FrimKgrmySenHLiQ27eq3qG7ptDs8RuUJWHBY",
        net_leverage: 0.30000000000000004,
        open_ms: 1717637492148,
        orders: [
          {
            leverage: 0.1,
            order_type: "LONG",
            order_uuid: "73c567c7-1586-4dcb-b85b-89653893bcc7",
            price: 71159.0,
            price_sources: [],
            processed_ms: 1717637491661,
          },
          {
            leverage: 0.2,
            order_type: "LONG",
            order_uuid: "4274ddfb-893e-4025-8c8c-17ccd4cdd062",
            price: 61296.21,
            price_sources: [],
            processed_ms: 1719235845408,
          },
        ],
        position_type: "LONG",
        position_uuid: "db71c965-9cc0-4a87-88b4-cdbb3c1db84f",
        return_at_close: 0.965367139365365,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
    ],
    thirty_day_returns: 1.0,
  },
  "5D5GDbEa5Aedt9fXhWTzngDTANvnf8Q5poSFKYkU9hEZ7P8C": {
    all_time_returns: 0.9942132617813371,
    n_positions: 375,
    percentage_profitable: 0.704,
    positions: [
      {
        average_entry_price: 171.03733333333335,
        close_ms: 1719410659428,
        current_return: 1.0014330381929224,
        initial_entry_price: 171.105,
        is_closed_position: true,
        miner_hotkey: "5D5GDbEa5Aedt9fXhWTzngDTANvnf8Q5poSFKYkU9hEZ7P8C",
        net_leverage: 0.0,
        open_ms: 1719394771188,
        orders: [
          {
            leverage: 0.1,
            order_type: "LONG",
            order_uuid: "358de3a7-c477-4f12-bede-bc7c2e3832b9",
            price: 171.105,
            price_sources: [],
            processed_ms: 1719394771186,
          },
          {
            leverage: 0.05,
            order_type: "LONG",
            order_uuid: "6b771d4d-85bc-464b-bbe9-f4c603acc0fd",
            price: 171.053,
            price_sources: [],
            processed_ms: 1719394846919,
          },
          {
            leverage: 0.05,
            order_type: "LONG",
            order_uuid: "18c0d381-753e-4328-badd-93f88e4dd028",
            price: 171.006,
            price_sources: [],
            processed_ms: 1719395025319,
          },
          {
            leverage: 0.1,
            order_type: "LONG",
            order_uuid: "5a8f849a-e1cf-43a9-a66f-7202b9a14677",
            price: 171.0485,
            price_sources: [],
            processed_ms: 1719397131188,
          },
          {
            leverage: 0.1,
            order_type: "LONG",
            order_uuid: "d992daf4-e91e-4148-9e6f-0719594aed28",
            price: 171.024,
            price_sources: [],
            processed_ms: 1719397953715,
          },
          {
            leverage: 0.1,
            order_type: "LONG",
            order_uuid: "250f9fc9-2d8f-440c-84c9-58ce98a736c3",
            price: 171.03,
            price_sources: [],
            processed_ms: 1719398019590,
          },
          {
            leverage: 0.1,
            order_type: "LONG",
            order_uuid: "8fe4aeda-6ac9-4dfa-a66e-cac3e1345c27",
            price: 170.987,
            price_sources: [],
            processed_ms: 1719407134483,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "4ffaa750-a84e-46f5-b8d4-05240721136e",
            price: 171.446,
            price_sources: [],
            processed_ms: 1719410659428,
          },
        ],
        position_type: "FLAT",
        position_uuid: "9b8d5feb-b509-4939-9a0c-09a9d2f44451",
        return_at_close: 1.0013909780053183,
        trade_pair: ["EURJPY", "EUR/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 171.39982213438734,
        close_ms: 1719411716982,
        current_return: 1.0003472730506897,
        initial_entry_price: 171.4645,
        is_closed_position: true,
        miner_hotkey: "5D5GDbEa5Aedt9fXhWTzngDTANvnf8Q5poSFKYkU9hEZ7P8C",
        net_leverage: 0.0,
        open_ms: 1719410687998,
        orders: [
          {
            leverage: 0.1,
            order_type: "LONG",
            order_uuid: "817743c8-db58-4866-8494-34d4d579d842",
            price: 171.4645,
            price_sources: [],
            processed_ms: 1719410687988,
          },
          {
            leverage: 0.12,
            order_type: "LONG",
            order_uuid: "0e64cfc0-c775-435d-bf05-66b69bbdd8af",
            price: 171.41699999999997,
            price_sources: [],
            processed_ms: 1719410802080,
          },
          {
            leverage: 0.28600000000000003,
            order_type: "LONG",
            order_uuid: "8e22b9a7-393e-407c-97b5-f7400e7abfc1",
            price: 171.37,
            price_sources: [],
            processed_ms: 1719411092371,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "45ca750f-4af6-479d-a07e-825171aab29f",
            price: 171.51749999999998,
            price_sources: [],
            processed_ms: 1719411716982,
          },
        ],
        position_type: "FLAT",
        position_uuid: "e88b99c2-7472-4f67-a4c4-641888958e5e",
        return_at_close: 1.0003118407502782,
        trade_pair: ["EURJPY", "EUR/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 171.53540123456787,
        close_ms: 1719416116200,
        current_return: 1.0004070947570247,
        initial_entry_price: 171.5915,
        is_closed_position: true,
        miner_hotkey: "5D5GDbEa5Aedt9fXhWTzngDTANvnf8Q5poSFKYkU9hEZ7P8C",
        net_leverage: 0.0,
        open_ms: 1719411745797,
        orders: [
          {
            leverage: 0.1,
            order_type: "LONG",
            order_uuid: "fab6f292-db8d-4392-aa61-4323bde288e1",
            price: 171.5915,
            price_sources: [],
            processed_ms: 1719411745792,
          },
          {
            leverage: 0.08000000000000002,
            order_type: "LONG",
            order_uuid: "74414ff3-d78d-47e1-b415-f1ddc6b3060b",
            price: 171.529,
            price_sources: [],
            processed_ms: 1719412126540,
          },
          {
            leverage: 0.14400000000000002,
            order_type: "LONG",
            order_uuid: "bef5b8c2-a52f-4236-9b94-b7762138a2bf",
            price: 171.5,
            price_sources: [],
            processed_ms: 1719414074020,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "682a923b-9dea-48cb-99ee-9f3d6ed34c3c",
            price: 171.751,
            price_sources: [],
            processed_ms: 1719416116200,
          },
        ],
        position_type: "FLAT",
        position_uuid: "6b703f9a-2c43-4631-b367-2e0ee813052e",
        return_at_close: 1.0003844055241156,
        trade_pair: ["EURJPY", "EUR/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 171.6206092498484,
        close_ms: 1719428006935,
        current_return: 1.000193655462307,
        initial_entry_price: 171.7635,
        is_closed_position: true,
        miner_hotkey: "5D5GDbEa5Aedt9fXhWTzngDTANvnf8Q5poSFKYkU9hEZ7P8C",
        net_leverage: 0.0,
        open_ms: 1719416144792,
        orders: [
          {
            leverage: 0.1,
            order_type: "LONG",
            order_uuid: "45fcc405-dd64-43d6-8748-e45267f6a0e4",
            price: 171.7635,
            price_sources: [],
            processed_ms: 1719416144789,
          },
          {
            leverage: 0.12,
            order_type: "LONG",
            order_uuid: "144fa37d-ac34-4c1d-92ae-d7b7927c2db1",
            price: 171.74,
            price_sources: [],
            processed_ms: 1719416299061,
          },
          {
            leverage: 0.28600000000000003,
            order_type: "LONG",
            order_uuid: "005c8466-41b8-4cea-b8ba-da4386f0f1dc",
            price: 171.6765,
            price_sources: [],
            processed_ms: 1719416847734,
          },
          {
            leverage: 0.40480000000000005,
            order_type: "LONG",
            order_uuid: "707bc6d7-123a-4477-a011-435d686072d6",
            price: 171.58,
            price_sources: [],
            processed_ms: 1719417632306,
          },
          {
            leverage: 1.00188,
            order_type: "LONG",
            order_uuid: "5e139cc5-51eb-4a96-9b9f-bd1edaa75a9b",
            price: 171.5925,
            price_sources: [],
            processed_ms: 1719427375872,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "ede48fb7-21b3-4e57-9526-e502447f6015",
            price: 171.638,
            price_sources: [],
            processed_ms: 1719428006935,
          },
        ],
        position_type: "FLAT",
        position_uuid: "69d2133b-7c7f-4655-a635-471cb39ed41e",
        return_at_close: 1.0000597419342419,
        trade_pair: ["EURJPY", "EUR/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 171.50992391304348,
        close_ms: 1719448716916,
        current_return: 1.0001987437947188,
        initial_entry_price: 171.628,
        is_closed_position: true,
        miner_hotkey: "5D5GDbEa5Aedt9fXhWTzngDTANvnf8Q5poSFKYkU9hEZ7P8C",
        net_leverage: 0.0,
        open_ms: 1719428035877,
        orders: [
          {
            leverage: 0.1,
            order_type: "LONG",
            order_uuid: "6c692dfd-4af6-4dc4-8fe7-c809d4d6027c",
            price: 171.628,
            price_sources: [],
            processed_ms: 1719428035870,
          },
          {
            leverage: 0.13,
            order_type: "LONG",
            order_uuid: "aa7344d9-b957-44aa-bc36-da67ae0bf751",
            price: 171.7,
            price_sources: [],
            processed_ms: 1719435696320,
          },
          {
            leverage: 0.23,
            order_type: "LONG",
            order_uuid: "dd2defc3-f48d-4370-85b2-95e9dab02479",
            price: 171.511,
            price_sources: [],
            processed_ms: 1719443965712,
          },
          {
            leverage: 0.46,
            order_type: "LONG",
            order_uuid: "72665286-de65-48c1-be2a-adbb4c15f75d",
            price: 171.43,
            price_sources: [],
            processed_ms: 1719446812124,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "10a5a976-2907-4c57-b216-ea59b5446db7",
            price: 171.547,
            price_sources: [],
            processed_ms: 1719448716916,
          },
        ],
        position_type: "FLAT",
        position_uuid: "21c99945-8500-4dca-99f9-470b3ef39c37",
        return_at_close: 1.0001343309956185,
        trade_pair: ["EURJPY", "EUR/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 171.44739297739298,
        close_ms: 1719465889655,
        current_return: 1.0003810994059315,
        initial_entry_price: 171.529,
        is_closed_position: true,
        miner_hotkey: "5D5GDbEa5Aedt9fXhWTzngDTANvnf8Q5poSFKYkU9hEZ7P8C",
        net_leverage: 0.0,
        open_ms: 1719448746936,
        orders: [
          {
            leverage: 0.1,
            order_type: "LONG",
            order_uuid: "46c390e1-f817-41ad-9a7c-ac7d6e7d92b1",
            price: 171.529,
            price_sources: [],
            processed_ms: 1719448746863,
          },
          {
            leverage: 0.11000000000000001,
            order_type: "LONG",
            order_uuid: "e58ea931-207c-42df-b3cb-fdb836342009",
            price: 171.528,
            price_sources: [],
            processed_ms: 1719449051465,
          },
          {
            leverage: 0.16800000000000004,
            order_type: "LONG",
            order_uuid: "1ea7d4b3-dfb5-40fd-9b09-cc5114aec22a",
            price: 171.447,
            price_sources: [],
            processed_ms: 1719453167812,
          },
          {
            leverage: 0.45360000000000006,
            order_type: "LONG",
            order_uuid: "a9c6255b-6df7-4c45-aec6-e12abb90d4b9",
            price: 171.41,
            price_sources: [],
            processed_ms: 1719461680184,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "5f96d2f6-4d9f-41e1-82ef-027ab2acff4b",
            price: 171.526,
            price_sources: [],
            processed_ms: 1719465889655,
          },
        ],
        position_type: "FLAT",
        position_uuid: "15c93a02-1d4c-441f-bdfd-6f6f29f4b7ee",
        return_at_close: 1.000322865221373,
        trade_pair: ["EURJPY", "EUR/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 171.60992956713133,
        close_ms: 1719482795172,
        current_return: 1.0001150871793079,
        initial_entry_price: 171.543,
        is_closed_position: true,
        miner_hotkey: "5D5GDbEa5Aedt9fXhWTzngDTANvnf8Q5poSFKYkU9hEZ7P8C",
        net_leverage: 0.0,
        open_ms: 1719465922154,
        orders: [
          {
            leverage: 0.1,
            order_type: "LONG",
            order_uuid: "f01a623a-0de9-4257-9f85-09f36b1b8166",
            price: 171.543,
            price_sources: [],
            processed_ms: 1719465919112,
          },
          {
            leverage: 0.11000000000000001,
            order_type: "LONG",
            order_uuid: "2039abfa-070f-49d8-bfc6-919bc8c77689",
            price: 171.516,
            price_sources: [],
            processed_ms: 1719467730055,
          },
          {
            leverage: 0.1,
            order_type: "LONG",
            order_uuid: "34c424b1-92e8-4f10-b7b2-85117b53399f",
            price: 171.724,
            price_sources: [],
            processed_ms: 1719481703573,
          },
          {
            leverage: 0.08000000000000002,
            order_type: "LONG",
            order_uuid: "5f3309a3-54d0-463b-9418-b3e7bd44d037",
            price: 171.703,
            price_sources: [],
            processed_ms: 1719481809859,
          },
          {
            leverage: 0.21600000000000003,
            order_type: "LONG",
            order_uuid: "06f1f911-0034-4186-b795-7cb095cda0d2",
            price: 171.673,
            price_sources: [],
            processed_ms: 1719482361180,
          },
          {
            leverage: 0.396,
            order_type: "LONG",
            order_uuid: "8be37d6b-81a7-4587-aec6-31336f5aecba",
            price: 171.622,
            price_sources: [],
            processed_ms: 1719482631385,
          },
          {
            leverage: 0.6336,
            order_type: "LONG",
            order_uuid: "66975434-bc11-4f9c-b749-37a879547b70",
            price: 171.578,
            price_sources: [],
            processed_ms: 1719482751076,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "4fa2b449-e467-4f5e-8f0b-f293bd1d45ce",
            price: 171.622,
            price_sources: [],
            processed_ms: 1719482795172,
          },
        ],
        position_type: "FLAT",
        position_uuid: "b5b88bd5-7428-484d-96b2-0bb405e70edb",
        return_at_close: 1.0000005820027464,
        trade_pair: ["EURJPY", "EUR/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 171.5226002415459,
        close_ms: 1719483375200,
        current_return: 1.000030881753016,
        initial_entry_price: 171.59,
        is_closed_position: true,
        miner_hotkey: "5D5GDbEa5Aedt9fXhWTzngDTANvnf8Q5poSFKYkU9hEZ7P8C",
        net_leverage: 0.0,
        open_ms: 1719482826794,
        orders: [
          {
            leverage: 0.1,
            order_type: "LONG",
            order_uuid: "d40c43aa-e207-4b01-b98d-e61691d48265",
            price: 171.59,
            price_sources: [],
            processed_ms: 1719482825596,
          },
          {
            leverage: 0.08000000000000002,
            order_type: "LONG",
            order_uuid: "0441d2e2-f373-4411-90a2-eb1d7aa8c794",
            price: 171.588,
            price_sources: [],
            processed_ms: 1719482957663,
          },
          {
            leverage: 0.23400000000000004,
            order_type: "LONG",
            order_uuid: "c353ab93-7ecb-4021-b847-a10f6b2b0afd",
            price: 171.4955,
            price_sources: [],
            processed_ms: 1719483220039,
          },
          {
            leverage: 0.41400000000000003,
            order_type: "LONG",
            order_uuid: "4687711d-7a28-4e58-b9fb-265e07ed77a4",
            price: 171.509,
            price_sources: [],
            processed_ms: 1719483345700,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "026c4f43-9c37-4da4-8edd-82bf22fbe181",
            price: 171.529,
            price_sources: [],
            processed_ms: 1719483375200,
          },
        ],
        position_type: "FLAT",
        position_uuid: "ff141435-bf58-4e9c-a722-bb237178ce94",
        return_at_close: 0.9999729199631094,
        trade_pair: ["EURJPY", "EUR/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 171.55149999999998,
        close_ms: 1719485511037,
        current_return: 1.0000836483504953,
        initial_entry_price: 171.55149999999998,
        is_closed_position: true,
        miner_hotkey: "5D5GDbEa5Aedt9fXhWTzngDTANvnf8Q5poSFKYkU9hEZ7P8C",
        net_leverage: 0.0,
        open_ms: 1719483406437,
        orders: [
          {
            leverage: 0.1,
            order_type: "LONG",
            order_uuid: "c17295c1-3795-42bd-b55a-f79ba3e44ced",
            price: 171.55149999999998,
            price_sources: [],
            processed_ms: 1719483404899,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "ef4fafe4-1380-431a-b9a3-4d3273bd9150",
            price: 171.695,
            price_sources: [],
            processed_ms: 1719485511037,
          },
        ],
        position_type: "FLAT",
        position_uuid: "2ef38f22-bbe8-4031-a015-56e8e5457e8d",
        return_at_close: 1.000076647764957,
        trade_pair: ["EURJPY", "EUR/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 171.69290476190474,
        close_ms: 1719487848998,
        current_return: 1.0000906258190396,
        initial_entry_price: 171.695,
        is_closed_position: true,
        miner_hotkey: "5D5GDbEa5Aedt9fXhWTzngDTANvnf8Q5poSFKYkU9hEZ7P8C",
        net_leverage: 0.0,
        open_ms: 1719485542322,
        orders: [
          {
            leverage: 0.1,
            order_type: "LONG",
            order_uuid: "a37900f5-01f8-44e3-b5ad-861a31f3df1f",
            price: 171.695,
            price_sources: [],
            processed_ms: 1719485541295,
          },
          {
            leverage: 0.11000000000000001,
            order_type: "LONG",
            order_uuid: "0291f39a-bf81-4b0d-bd5e-d62596188fe2",
            price: 171.691,
            price_sources: [],
            processed_ms: 1719486888973,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "871cd6c5-5c7a-4762-ab14-341e20d94805",
            price: 171.767,
            price_sources: [],
            processed_ms: 1719487848998,
          },
        ],
        position_type: "FLAT",
        position_uuid: "e3c2e581-6bfb-4437-8b7e-69fccd2967c2",
        return_at_close: 1.00007592448684,
        trade_pair: ["EURJPY", "EUR/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 171.75930303030304,
        close_ms: 1719488146360,
        current_return: 1.0000341503172847,
        initial_entry_price: 171.77,
        is_closed_position: true,
        miner_hotkey: "5D5GDbEa5Aedt9fXhWTzngDTANvnf8Q5poSFKYkU9hEZ7P8C",
        net_leverage: 0.0,
        open_ms: 1719487879449,
        orders: [
          {
            leverage: 0.1,
            order_type: "LONG",
            order_uuid: "36ddaed1-4042-43a5-bb52-34065936fe4e",
            price: 171.77,
            price_sources: [],
            processed_ms: 1719487878281,
          },
          {
            leverage: 0.12,
            order_type: "LONG",
            order_uuid: "357e3786-ccec-46df-b712-4a5df4797f7f",
            price: 171.751,
            price_sources: [],
            processed_ms: 1719487940488,
          },
          {
            leverage: 0.24200000000000002,
            order_type: "LONG",
            order_uuid: "44828cd5-d49f-46ea-946d-f55f7c16d885",
            price: 171.759,
            price_sources: [],
            processed_ms: 1719488059004,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "a234a81a-18df-47e8-b3c8-68c947e67f5e",
            price: 171.772,
            price_sources: [],
            processed_ms: 1719488146360,
          },
        ],
        position_type: "FLAT",
        position_uuid: "32eb0542-b840-45d3-99df-5bf0c27b2c8c",
        return_at_close: 1.0000018092128635,
        trade_pair: ["EURJPY", "EUR/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 171.91988712522044,
        close_ms: 1719500078504,
        current_return: 1.0003803007107392,
        initial_entry_price: 171.793,
        is_closed_position: true,
        miner_hotkey: "5D5GDbEa5Aedt9fXhWTzngDTANvnf8Q5poSFKYkU9hEZ7P8C",
        net_leverage: 0.0,
        open_ms: 1719488179662,
        orders: [
          {
            leverage: 0.1,
            order_type: "LONG",
            order_uuid: "bf7ee4e2-9a09-4b4d-901f-e81920d7bfe0",
            price: 171.793,
            price_sources: [],
            processed_ms: 1719488177342,
          },
          {
            leverage: 0.11000000000000001,
            order_type: "LONG",
            order_uuid: "a6019bfc-5344-4588-9ebe-8146e6743798",
            price: 171.732,
            price_sources: [],
            processed_ms: 1719488567479,
          },
          {
            leverage: 0.1,
            order_type: "LONG",
            order_uuid: "b3bdc50b-6b6a-4d97-9d84-c6e90662288b",
            price: 172.029,
            price_sources: [],
            processed_ms: 1719498523844,
          },
          {
            leverage: 0.12,
            order_type: "LONG",
            order_uuid: "99a075e3-aea9-4c22-aafe-822a837ed655",
            price: 172.03,
            price_sources: [],
            processed_ms: 1719499666367,
          },
          {
            leverage: 0.22,
            order_type: "LONG",
            order_uuid: "37160e06-9429-4101-bf72-5e406ea6c5b8",
            price: 171.944,
            price_sources: [],
            processed_ms: 1719499962908,
          },
          {
            leverage: 0.48400000000000004,
            order_type: "LONG",
            order_uuid: "16499515-9452-4a3f-91b3-9da5e2d1be4c",
            price: 171.928,
            price_sources: [],
            processed_ms: 1719500048475,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "42f1e4ef-9a79-4e06-bdc5-173a50e2699e",
            price: 171.97750000000002,
            price_sources: [],
            processed_ms: 1719500078504,
          },
        ],
        position_type: "FLAT",
        position_uuid: "b13bf693-c76d-4536-88cd-d76cb703d5c7",
        return_at_close: 1.0003008905224688,
        trade_pair: ["EURJPY", "EUR/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 171.97630434782607,
        close_ms: 1719500405109,
        current_return: 1.0000651196864951,
        initial_entry_price: 171.991,
        is_closed_position: true,
        miner_hotkey: "5D5GDbEa5Aedt9fXhWTzngDTANvnf8Q5poSFKYkU9hEZ7P8C",
        net_leverage: 0.0,
        open_ms: 1719500109124,
        orders: [
          {
            leverage: 0.1,
            order_type: "LONG",
            order_uuid: "70bf51e2-13e9-416d-83d7-7f2a1ba7b866",
            price: 171.991,
            price_sources: [],
            processed_ms: 1719500108017,
          },
          {
            leverage: 0.13,
            order_type: "LONG",
            order_uuid: "ee417b25-d9e9-48f2-8202-febd6870ca3a",
            price: 171.96499999999997,
            price_sources: [],
            processed_ms: 1719500228885,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "ef18fad1-20d1-4dd7-a1fe-36e8ff26d6d1",
            price: 172.025,
            price_sources: [],
            processed_ms: 1719500405109,
          },
        ],
        position_type: "FLAT",
        position_uuid: "6807d0d1-738c-4de1-8fcb-fdeb3e97c0fa",
        return_at_close: 1.0000490186380682,
        trade_pair: ["EURJPY", "EUR/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 172.0107738095238,
        close_ms: 1719500732616,
        current_return: 1.0000542609524585,
        initial_entry_price: 172.039,
        is_closed_position: true,
        miner_hotkey: "5D5GDbEa5Aedt9fXhWTzngDTANvnf8Q5poSFKYkU9hEZ7P8C",
        net_leverage: 0.0,
        open_ms: 1719500443441,
        orders: [
          {
            leverage: 0.1,
            order_type: "LONG",
            order_uuid: "f540ce52-af26-489b-9f48-2a42cf3ba7f1",
            price: 172.039,
            price_sources: [],
            processed_ms: 1719500434864,
          },
          {
            leverage: 0.11000000000000001,
            order_type: "LONG",
            order_uuid: "74f8d23a-60ea-4dbc-80ee-477ee51e3ed6",
            price: 172.0095,
            price_sources: [],
            processed_ms: 1719500472412,
          },
          {
            leverage: 0.21000000000000002,
            order_type: "LONG",
            order_uuid: "f19f4e28-d6d0-42a2-98df-4dd9b0655f20",
            price: 171.998,
            price_sources: [],
            processed_ms: 1719500554377,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "44900282-c4b3-4297-9ac3-fea46feb91fc",
            price: 172.03300000000002,
            price_sources: [],
            processed_ms: 1719500732616,
          },
        ],
        position_type: "FLAT",
        position_uuid: "9edeb07e-f58a-46a2-b9b5-31b3a120a424",
        return_at_close: 1.0000248593571865,
        trade_pair: ["EURJPY", "EUR/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 172.00967391304346,
        close_ms: 1719501014503,
        current_return: 1.0000532499316936,
        initial_entry_price: 172.019,
        is_closed_position: true,
        miner_hotkey: "5D5GDbEa5Aedt9fXhWTzngDTANvnf8Q5poSFKYkU9hEZ7P8C",
        net_leverage: 0.0,
        open_ms: 1719500763757,
        orders: [
          {
            leverage: 0.1,
            order_type: "LONG",
            order_uuid: "e7c7e552-c548-4070-8ea1-d91007d9dd9d",
            price: 172.019,
            price_sources: [],
            processed_ms: 1719500762163,
          },
          {
            leverage: 0.13,
            order_type: "LONG",
            order_uuid: "e548dd6c-478a-4206-a6f7-8299759b561d",
            price: 172.0025,
            price_sources: [],
            processed_ms: 1719500834740,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "181596a7-0d32-40c8-85d4-92547e7fa4c8",
            price: 172.0495,
            price_sources: [],
            processed_ms: 1719501014503,
          },
        ],
        position_type: "FLAT",
        position_uuid: "2e1f736a-6b0e-4515-a0a2-ecfc4232442e",
        return_at_close: 1.0000371490743698,
        trade_pair: ["EURJPY", "EUR/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 172.0076086956522,
        close_ms: 1719502410131,
        current_return: 0.9998247368145695,
        initial_entry_price: 172.027,
        is_closed_position: true,
        miner_hotkey: "5D5GDbEa5Aedt9fXhWTzngDTANvnf8Q5poSFKYkU9hEZ7P8C",
        net_leverage: 0.0,
        open_ms: 1719501045291,
        orders: [
          {
            leverage: 0.1,
            order_type: "LONG",
            order_uuid: "8525911a-6e8d-4c4c-b306-784733c984b6",
            price: 172.027,
            price_sources: [],
            processed_ms: 1719501044037,
          },
          {
            leverage: 0.5,
            order_type: "LONG",
            order_uuid: "05cd6e99-6d80-4d3b-b834-10582e5f5829",
            price: 172.051,
            price_sources: [],
            processed_ms: 1719501137012,
          },
          {
            leverage: 0.6,
            order_type: "LONG",
            order_uuid: "eec8cef3-c48d-46a8-9fb1-d958947876e2",
            price: 172.0335,
            price_sources: [],
            processed_ms: 1719501170376,
          },
          {
            leverage: 1.1,
            order_type: "LONG",
            order_uuid: "fe602e78-693e-4dff-af33-633aae7a35de",
            price: 171.972,
            price_sources: [],
            processed_ms: 1719501728153,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "2c967f31-f1ae-4525-ab9d-3c081279e868",
            price: 171.99450000000002,
            price_sources: [],
            processed_ms: 1719502410131,
          },
        ],
        position_type: "FLAT",
        position_uuid: "3df4c01a-1f68-466f-be88-c65434e919f2",
        return_at_close: 0.9996637650319424,
        trade_pair: ["EURJPY", "EUR/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 171.9896263157895,
        close_ms: 1719502570041,
        current_return: 1.0001097610667844,
        initial_entry_price: 172.00999,
        is_closed_position: true,
        miner_hotkey: "5D5GDbEa5Aedt9fXhWTzngDTANvnf8Q5poSFKYkU9hEZ7P8C",
        net_leverage: 0.0,
        open_ms: 1719502440599,
        orders: [
          {
            leverage: 0.5,
            order_type: "LONG",
            order_uuid: "9c804c9c-84da-4b13-ad69-21aa4ee9cc7d",
            price: 172.00999,
            price_sources: [],
            processed_ms: 1719502439964,
          },
          {
            leverage: 0.45,
            order_type: "LONG",
            order_uuid: "35db3561-a926-4745-99b7-35845680e725",
            price: 171.967,
            price_sources: [],
            processed_ms: 1719502483852,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "1fa73f7e-6406-4df9-ba78-7bd53ab8d4f3",
            price: 172.0095,
            price_sources: [],
            processed_ms: 1719502570041,
          },
        ],
        position_type: "FLAT",
        position_uuid: "f211ded0-8035-4148-9f43-d84eda9197d8",
        return_at_close: 1.0000432537676736,
        trade_pair: ["EURJPY", "EUR/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 172.0125,
        close_ms: 1719502739746,
        current_return: 1.000167168083715,
        initial_entry_price: 172.0125,
        is_closed_position: true,
        miner_hotkey: "5D5GDbEa5Aedt9fXhWTzngDTANvnf8Q5poSFKYkU9hEZ7P8C",
        net_leverage: 0.0,
        open_ms: 1719502600311,
        orders: [
          {
            leverage: 0.5,
            order_type: "LONG",
            order_uuid: "baf146f5-4cda-487a-837b-650c516ead64",
            price: 172.0125,
            price_sources: [],
            processed_ms: 1719502599816,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "a3229ba7-d75d-4054-8c92-7e0bbd274d5b",
            price: 172.07001,
            price_sources: [],
            processed_ms: 1719502739746,
          },
        ],
        position_type: "FLAT",
        position_uuid: "22d473fb-8dbb-4e14-81c8-ab9b68329502",
        return_at_close: 1.000132162232832,
        trade_pair: ["EURJPY", "EUR/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 172.0412857142857,
        close_ms: 1719503370460,
        current_return: 1.0002162036498898,
        initial_entry_price: 172.06,
        is_closed_position: true,
        miner_hotkey: "5D5GDbEa5Aedt9fXhWTzngDTANvnf8Q5poSFKYkU9hEZ7P8C",
        net_leverage: 0.0,
        open_ms: 1719502768563,
        orders: [
          {
            leverage: 0.5,
            order_type: "LONG",
            order_uuid: "f4d417d5-5129-4f4a-8af0-c18b545150ee",
            price: 172.06,
            price_sources: [],
            processed_ms: 1719502768035,
          },
          {
            leverage: 0.5,
            order_type: "LONG",
            order_uuid: "af51ff62-8d67-4163-a745-d2406e37ba4d",
            price: 172.076,
            price_sources: [],
            processed_ms: 1719502803845,
          },
          {
            leverage: 1.1,
            order_type: "LONG",
            order_uuid: "420ba134-bdfb-43f3-8363-a75bb777216e",
            price: 172.017,
            price_sources: [],
            processed_ms: 1719503110406,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "c09e4fab-3f6e-4a9a-967c-d30e0afe88cf",
            price: 172.059,
            price_sources: [],
            processed_ms: 1719503370460,
          },
        ],
        position_type: "FLAT",
        position_uuid: "76753d34-1c5b-4688-9ee3-44a9d1a62a82",
        return_at_close: 1.0000691718679533,
        trade_pair: ["EURJPY", "EUR/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 172.0730434782609,
        close_ms: 1719503754457,
        current_return: 1.000103288976698,
        initial_entry_price: 172.09,
        is_closed_position: true,
        miner_hotkey: "5D5GDbEa5Aedt9fXhWTzngDTANvnf8Q5poSFKYkU9hEZ7P8C",
        net_leverage: 0.0,
        open_ms: 1719503400377,
        orders: [
          {
            leverage: 0.5,
            order_type: "LONG",
            order_uuid: "e5bcce71-4ca8-46e7-9036-f6915a71bf52",
            price: 172.09,
            price_sources: [],
            processed_ms: 1719503400007,
          },
          {
            leverage: 0.65,
            order_type: "LONG",
            order_uuid: "59514772-2a62-428f-ace7-c5367a5eac73",
            price: 172.06,
            price_sources: [],
            processed_ms: 1719503442032,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "230be498-50ed-4033-8bfb-b9d3affb074a",
            price: 172.0885,
            price_sources: [],
            processed_ms: 1719503754457,
          },
        ],
        position_type: "FLAT",
        position_uuid: "96501611-a38e-4682-9495-a27225eddf5c",
        return_at_close: 1.0000227806619355,
        trade_pair: ["EURJPY", "EUR/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 172.03021595238096,
        close_ms: 1719504768407,
        current_return: 1.000330737516779,
        initial_entry_price: 172.091,
        is_closed_position: true,
        miner_hotkey: "5D5GDbEa5Aedt9fXhWTzngDTANvnf8Q5poSFKYkU9hEZ7P8C",
        net_leverage: 0.0,
        open_ms: 1719503784500,
        orders: [
          {
            leverage: 0.5,
            order_type: "LONG",
            order_uuid: "7a52cf4a-3104-46d4-8559-45b5bef131b6",
            price: 172.091,
            price_sources: [],
            processed_ms: 1719503783890,
          },
          {
            leverage: 0.5,
            order_type: "LONG",
            order_uuid: "ef150ce4-f656-4308-9088-f05c7df62490",
            price: 172.1000061,
            price_sources: [],
            processed_ms: 1719503820219,
          },
          {
            leverage: 1.1,
            order_type: "LONG",
            order_uuid: "bef8f6e1-be64-48fe-bd7c-78993668bc1b",
            price: 172.026,
            price_sources: [],
            processed_ms: 1719504661109,
          },
          {
            leverage: 2.7300000000000004,
            order_type: "LONG",
            order_uuid: "1f2e2db8-95fd-4180-abd7-7e777d2716c3",
            price: 172.00799999999998,
            price_sources: [],
            processed_ms: 1719504731576,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "a2a35da9-a359-48d0-8138-f8b439310695",
            price: 172.042,
            price_sources: [],
            processed_ms: 1719504768407,
          },
        ],
        position_type: "FLAT",
        position_uuid: "7616182f-a858-4f42-a1b0-a104d727f88d",
        return_at_close: 0.9999925256944245,
        trade_pair: ["EURJPY", "EUR/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 172.0375,
        close_ms: 1719504972930,
        current_return: 1.0001281697304367,
        initial_entry_price: 172.0375,
        is_closed_position: true,
        miner_hotkey: "5D5GDbEa5Aedt9fXhWTzngDTANvnf8Q5poSFKYkU9hEZ7P8C",
        net_leverage: 0.0,
        open_ms: 1719504798003,
        orders: [
          {
            leverage: 0.5,
            order_type: "LONG",
            order_uuid: "220e7e5f-322f-4491-b232-dd6af21124c4",
            price: 172.0375,
            price_sources: [],
            processed_ms: 1719504796933,
          },
          {
            leverage: 0.4,
            order_type: "LONG",
            order_uuid: "d43a9f79-f87e-46d7-a8bd-8dc611a2438d",
            price: 172.03750000000002,
            price_sources: [],
            processed_ms: 1719504883106,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "8714750c-6cd6-4a4c-8ac7-e929a2fbecb6",
            price: 172.062,
            price_sources: [],
            processed_ms: 1719504972930,
          },
        ],
        position_type: "FLAT",
        position_uuid: "9b38e54e-14b8-4341-a8ae-e570683e366e",
        return_at_close: 1.0000651616557437,
        trade_pair: ["EURJPY", "EUR/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 172.05105263157895,
        close_ms: 1719506771708,
        current_return: 1.0001432631450473,
        initial_entry_price: 172.061,
        is_closed_position: true,
        miner_hotkey: "5D5GDbEa5Aedt9fXhWTzngDTANvnf8Q5poSFKYkU9hEZ7P8C",
        net_leverage: 0.0,
        open_ms: 1719505003463,
        orders: [
          {
            leverage: 0.5,
            order_type: "LONG",
            order_uuid: "e905d2e1-f571-4374-b69b-b8df16d4a8c5",
            price: 172.061,
            price_sources: [],
            processed_ms: 1719505003036,
          },
          {
            leverage: 0.45,
            order_type: "LONG",
            order_uuid: "ebab1d6e-5479-45b9-a352-dbc0daebdaab",
            price: 172.04,
            price_sources: [],
            processed_ms: 1719505692289,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "5e7dab05-fd0b-4b9f-93db-9f4e0eb406b6",
            price: 172.077,
            price_sources: [],
            processed_ms: 1719506771708,
          },
        ],
        position_type: "FLAT",
        position_uuid: "05dfa71d-0a2c-4b4d-89af-b4028758a74c",
        return_at_close: 1.0000767536180482,
        trade_pair: ["EURJPY", "EUR/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 172.04925,
        close_ms: 1719507109627,
        current_return: 1.000074100119432,
        initial_entry_price: 172.0645,
        is_closed_position: true,
        miner_hotkey: "5D5GDbEa5Aedt9fXhWTzngDTANvnf8Q5poSFKYkU9hEZ7P8C",
        net_leverage: 0.0,
        open_ms: 1719506801696,
        orders: [
          {
            leverage: 0.5,
            order_type: "LONG",
            order_uuid: "4c7550a0-9189-430b-b1eb-a06a2c06d4e1",
            price: 172.0645,
            price_sources: [],
            processed_ms: 1719506800781,
          },
          {
            leverage: 0.5,
            order_type: "LONG",
            order_uuid: "3197288d-f806-4cbc-9f15-e1b3a8840058",
            price: 172.034,
            price_sources: [],
            processed_ms: 1719506940174,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "183cab4a-41bb-47e6-b303-0b1453977c94",
            price: 172.062,
            price_sources: [],
            processed_ms: 1719507109627,
          },
        ],
        position_type: "FLAT",
        position_uuid: "38028958-988f-4799-8ef0-658a5e6ffd47",
        return_at_close: 1.0000040949324236,
        trade_pair: ["EURJPY", "EUR/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 172.0325346320346,
        close_ms: 1719509435080,
        current_return: 1.0001136373541764,
        initial_entry_price: 172.08249999999998,
        is_closed_position: true,
        miner_hotkey: "5D5GDbEa5Aedt9fXhWTzngDTANvnf8Q5poSFKYkU9hEZ7P8C",
        net_leverage: 0.0,
        open_ms: 1719507139544,
        orders: [
          {
            leverage: 0.5,
            order_type: "LONG",
            order_uuid: "d98f1eea-fda9-4b9d-aa01-dbab6f26e45d",
            price: 172.08249999999998,
            price_sources: [],
            processed_ms: 1719507138914,
          },
          {
            leverage: 0.55,
            order_type: "LONG",
            order_uuid: "58b3f074-dd31-49a7-ab81-4dd635f8727c",
            price: 172.0605,
            price_sources: [],
            processed_ms: 1719507651564,
          },
          {
            leverage: 1.26,
            order_type: "LONG",
            order_uuid: "3805db64-71a3-4f62-9284-a5aae8fd54b3",
            price: 172.0005,
            price_sources: [],
            processed_ms: 1719508808761,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "0a37af17-0614-4f87-a6a5-3020b693a226",
            price: 172.041,
            price_sources: [],
            processed_ms: 1719509435080,
          },
        ],
        position_type: "FLAT",
        position_uuid: "7d304941-bf3a-474e-a1bf-642a0bf9f98e",
        return_at_close: 0.9999519189790161,
        trade_pair: ["EURJPY", "EUR/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 172.04333333333332,
        close_ms: 1719510622458,
        current_return: 1.000004068395541,
        initial_entry_price: 172.058,
        is_closed_position: true,
        miner_hotkey: "5D5GDbEa5Aedt9fXhWTzngDTANvnf8Q5poSFKYkU9hEZ7P8C",
        net_leverage: 0.0,
        open_ms: 1719509465380,
        orders: [
          {
            leverage: 0.5,
            order_type: "LONG",
            order_uuid: "c32d6b43-4b22-41b2-bc0b-1085a308a661",
            price: 172.058,
            price_sources: [],
            processed_ms: 1719509464967,
          },
          {
            leverage: 0.55,
            order_type: "LONG",
            order_uuid: "5848ca5c-a2a3-4f38-83a5-60729515fa96",
            price: 172.03,
            price_sources: [],
            processed_ms: 1719509611255,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "b81c48a3-f06f-4f02-89be-49ae78b36ab8",
            price: 172.04399999999998,
            price_sources: [],
            processed_ms: 1719510622458,
          },
        ],
        position_type: "FLAT",
        position_uuid: "4b2c844b-8c72-4ecd-9db1-67c6e6a253cc",
        return_at_close: 0.9999305680965139,
        trade_pair: ["EURJPY", "EUR/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 172.02184297520662,
        close_ms: 1719511659129,
        current_return: 1.00007253663158,
        initial_entry_price: 172.051,
        is_closed_position: true,
        miner_hotkey: "5D5GDbEa5Aedt9fXhWTzngDTANvnf8Q5poSFKYkU9hEZ7P8C",
        net_leverage: 0.0,
        open_ms: 1719510653573,
        orders: [
          {
            leverage: 0.5,
            order_type: "LONG",
            order_uuid: "af70e4ef-13de-4f61-b147-813cbf3d5d11",
            price: 172.051,
            price_sources: [],
            processed_ms: 1719510653527,
          },
          {
            leverage: 0.6,
            order_type: "LONG",
            order_uuid: "1ab594ff-a6fe-45c6-850f-8f1da9c8d879",
            price: 172.05,
            price_sources: [],
            processed_ms: 1719510685753,
          },
          {
            leverage: 1.32,
            order_type: "LONG",
            order_uuid: "8d364fc2-0f17-4f73-926e-3157f01b6283",
            price: 171.998,
            price_sources: [],
            processed_ms: 1719511419224,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "f0d6ff85-8c95-4534-a3e4-2593c226aee3",
            price: 172.027,
            price_sources: [],
            processed_ms: 1719511659129,
          },
        ],
        position_type: "FLAT",
        position_uuid: "c051b500-2db6-42a1-84ac-d75f0c1b0d3c",
        return_at_close: 0.9999031243438745,
        trade_pair: ["EURJPY", "EUR/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 172.014,
        close_ms: 1719513133556,
        current_return: 1.0002180055111793,
        initial_entry_price: 172.014,
        is_closed_position: true,
        miner_hotkey: "5D5GDbEa5Aedt9fXhWTzngDTANvnf8Q5poSFKYkU9hEZ7P8C",
        net_leverage: 0.0,
        open_ms: 1719511689069,
        orders: [
          {
            leverage: 0.5,
            order_type: "LONG",
            order_uuid: "1699ba34-e641-4595-8011-a0215659ae38",
            price: 172.014,
            price_sources: [],
            processed_ms: 1719511689031,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "30133fdf-54da-4acf-84b6-392bd39f3fe5",
            price: 172.089,
            price_sources: [],
            processed_ms: 1719513133556,
          },
        ],
        position_type: "FLAT",
        position_uuid: "00533b52-ee32-4e6c-a8b4-e2cff997bdf8",
        return_at_close: 1.0001829978809864,
        trade_pair: ["EURJPY", "EUR/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 172.09392857142856,
        close_ms: 1719515292974,
        current_return: 1.0001590635731346,
        initial_entry_price: 172.101,
        is_closed_position: true,
        miner_hotkey: "5D5GDbEa5Aedt9fXhWTzngDTANvnf8Q5poSFKYkU9hEZ7P8C",
        net_leverage: 0.0,
        open_ms: 1719513162896,
        orders: [
          {
            leverage: 0.5,
            order_type: "LONG",
            order_uuid: "0f809c9d-9e4d-4fe0-b9c2-c7e97355b7b1",
            price: 172.101,
            price_sources: [],
            processed_ms: 1719513162805,
          },
          {
            leverage: 0.55,
            order_type: "LONG",
            order_uuid: "a7bd2ffa-ddc5-48a3-a2ef-4c66dd9934ae",
            price: 172.0875,
            price_sources: [],
            processed_ms: 1719515183002,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "33482e13-3324-47b9-af58-d172fda1dffe",
            price: 172.12,
            price_sources: [],
            processed_ms: 1719515292974,
          },
        ],
        position_type: "FLAT",
        position_uuid: "de798bc1-ddc7-4e82-8e46-9a282ff69d69",
        return_at_close: 1.000085551881962,
        trade_pair: ["EURJPY", "EUR/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 172.10593478260873,
        close_ms: 1719515931986,
        current_return: 1.0001474322102337,
        initial_entry_price: 172.113,
        is_closed_position: true,
        miner_hotkey: "5D5GDbEa5Aedt9fXhWTzngDTANvnf8Q5poSFKYkU9hEZ7P8C",
        net_leverage: 0.0,
        open_ms: 1719515323098,
        orders: [
          {
            leverage: 0.5,
            order_type: "LONG",
            order_uuid: "5d85653e-f660-4ad8-9684-a22b4d74c1c2",
            price: 172.113,
            price_sources: [],
            processed_ms: 1719515323067,
          },
          {
            leverage: 0.65,
            order_type: "LONG",
            order_uuid: "b9a310ed-eb43-4408-9eaa-1d5458d8285f",
            price: 172.1005,
            price_sources: [],
            processed_ms: 1719515446990,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "b9781d0c-810c-4816-acd3-789a98d5f6ae",
            price: 172.128,
            price_sources: [],
            processed_ms: 1719515931986,
          },
        ],
        position_type: "FLAT",
        position_uuid: "f542de95-d09f-4dcb-9845-1d9563e6de91",
        return_at_close: 1.0000669203419408,
        trade_pair: ["EURJPY", "EUR/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 172.08782608695654,
        close_ms: 1719518873419,
        current_return: 1.0001815551579094,
        initial_entry_price: 172.124,
        is_closed_position: true,
        miner_hotkey: "5D5GDbEa5Aedt9fXhWTzngDTANvnf8Q5poSFKYkU9hEZ7P8C",
        net_leverage: 0.0,
        open_ms: 1719515960940,
        orders: [
          {
            leverage: 0.5,
            order_type: "LONG",
            order_uuid: "0b62ab6d-bf9f-469e-a7ff-9bdc5a324910",
            price: 172.124,
            price_sources: [],
            processed_ms: 1719515960836,
          },
          {
            leverage: 0.65,
            order_type: "LONG",
            order_uuid: "8ab70517-b060-41ed-87fe-d7c338851e7e",
            price: 172.06,
            price_sources: [],
            processed_ms: 1719516732430,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "6cc921ef-0677-4374-9fee-8d4b68f1932c",
            price: 172.115,
            price_sources: [],
            processed_ms: 1719518873419,
          },
        ],
        position_type: "FLAT",
        position_uuid: "da96f090-4a2c-4e31-aba4-e7a298de48db",
        return_at_close: 1.000101040542719,
        trade_pair: ["EURJPY", "EUR/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 172.0966937992002,
        close_ms: 1719521427877,
        current_return: 0.9999080461662145,
        initial_entry_price: 172.11200000000002,
        is_closed_position: true,
        miner_hotkey: "5D5GDbEa5Aedt9fXhWTzngDTANvnf8Q5poSFKYkU9hEZ7P8C",
        net_leverage: 0.0,
        open_ms: 1719518902944,
        orders: [
          {
            leverage: 0.5,
            order_type: "LONG",
            order_uuid: "f6830144-18ae-4e6a-b2cc-6bf919490aa2",
            price: 172.11200000000002,
            price_sources: [],
            processed_ms: 1719518902828,
          },
          {
            leverage: 0.5,
            order_type: "LONG",
            order_uuid: "f247f2e1-2aea-4331-ba2c-91e792baec6a",
            price: 172.10945271648086,
            price_sources: [],
            processed_ms: 1719519096313,
          },
          {
            leverage: 1.2,
            order_type: "LONG",
            order_uuid: "5382530a-aae2-47e7-a6eb-e0b2fd740495",
            price: 172.085,
            price_sources: [],
            processed_ms: 1719520791264,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "054c4991-8088-4ccd-99b3-cc900aa9f7e0",
            price: 172.0895,
            price_sources: [],
            processed_ms: 1719521427877,
          },
        ],
        position_type: "FLAT",
        position_uuid: "74a46706-e11d-4f34-bbfa-d076e309a3b9",
        return_at_close: 0.9997540603271049,
        trade_pair: ["EURJPY", "EUR/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 172.08157894736846,
        close_ms: 1719521978347,
        current_return: 0.9998808714399445,
        initial_entry_price: 172.083,
        is_closed_position: true,
        miner_hotkey: "5D5GDbEa5Aedt9fXhWTzngDTANvnf8Q5poSFKYkU9hEZ7P8C",
        net_leverage: 0.0,
        open_ms: 1719521459825,
        orders: [
          {
            leverage: 0.5,
            order_type: "LONG",
            order_uuid: "effac245-fb71-46c0-83ce-0f2ba6125c2e",
            price: 172.083,
            price_sources: [],
            processed_ms: 1719521459737,
          },
          {
            leverage: 0.45,
            order_type: "LONG",
            order_uuid: "291fc85a-6194-4faa-be48-0cb135d6d6b6",
            price: 172.08,
            price_sources: [],
            processed_ms: 1719521660154,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "d24ed72a-45d1-4bdc-8226-94ff0846863c",
            price: 172.06,
            price_sources: [],
            processed_ms: 1719521978347,
          },
        ],
        position_type: "FLAT",
        position_uuid: "02385233-fa6e-411b-9adc-fc8cbd892f8e",
        return_at_close: 0.9998143793619938,
        trade_pair: ["EURJPY", "EUR/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 172.131,
        close_ms: 1719522063869,
        current_return: 0.9997937617279863,
        initial_entry_price: 172.131,
        is_closed_position: true,
        miner_hotkey: "5D5GDbEa5Aedt9fXhWTzngDTANvnf8Q5poSFKYkU9hEZ7P8C",
        net_leverage: 0.0,
        open_ms: 1719522008361,
        orders: [
          {
            leverage: 0.5,
            order_type: "LONG",
            order_uuid: "4062612d-77b9-41b6-81c7-92e0a63b9843",
            price: 172.131,
            price_sources: [],
            processed_ms: 1719522007983,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "10974fd5-43cc-48ae-891a-d885dbcf675b",
            price: 172.06,
            price_sources: [],
            processed_ms: 1719522063869,
          },
        ],
        position_type: "FLAT",
        position_uuid: "b11a3791-51a8-441d-bb1b-9f476dd1eaa2",
        return_at_close: 0.9997587689463258,
        trade_pair: ["EURJPY", "EUR/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 172.0781818181818,
        close_ms: 1719522402032,
        current_return: 0.9999096095420321,
        initial_entry_price: 172.1,
        is_closed_position: true,
        miner_hotkey: "5D5GDbEa5Aedt9fXhWTzngDTANvnf8Q5poSFKYkU9hEZ7P8C",
        net_leverage: 0.0,
        open_ms: 1719522096101,
        orders: [
          {
            leverage: 0.5,
            order_type: "LONG",
            order_uuid: "a0de5c53-8be1-4525-bb5c-3d2b598e1222",
            price: 172.1,
            price_sources: [],
            processed_ms: 1719522096052,
          },
          {
            leverage: 0.6,
            order_type: "LONG",
            order_uuid: "dee2d7b0-aba4-46ce-8116-fd39a78abb9f",
            price: 172.06,
            price_sources: [],
            processed_ms: 1719522367623,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "75f6dde9-3f5c-48d8-b019-8f79cbe981c5",
            price: 172.064039820167,
            price_sources: [],
            processed_ms: 1719522402032,
          },
        ],
        position_type: "FLAT",
        position_uuid: "91a436c4-f64e-4c96-b08d-dd6cfdd88f73",
        return_at_close: 0.9998326165020974,
        trade_pair: ["EURJPY", "EUR/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 172.0481818181818,
        close_ms: 1719522576066,
        current_return: 1.0000116252034412,
        initial_entry_price: 172.04,
        is_closed_position: true,
        miner_hotkey: "5D5GDbEa5Aedt9fXhWTzngDTANvnf8Q5poSFKYkU9hEZ7P8C",
        net_leverage: 0.0,
        open_ms: 1719522432816,
        orders: [
          {
            leverage: 0.5,
            order_type: "LONG",
            order_uuid: "51bf7e79-d8ce-41ee-b9fa-6a58c0d6e151",
            price: 172.04,
            price_sources: [],
            processed_ms: 1719522432016,
          },
          {
            leverage: 0.6,
            order_type: "LONG",
            order_uuid: "3e0f1798-7448-48f3-be32-0a632005e4a6",
            price: 172.055,
            price_sources: [],
            processed_ms: 1719522481914,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "28f3536a-d1e7-4fa8-b825-196e9b2c70a4",
            price: 172.05,
            price_sources: [],
            processed_ms: 1719522576066,
          },
        ],
        position_type: "FLAT",
        position_uuid: "47ea0539-2a3f-4d8c-b1f0-aeb4f6886b0e",
        return_at_close: 0.9999346243083006,
        trade_pair: ["EURJPY", "EUR/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 172.00234210526315,
        close_ms: 1719522848249,
        current_return: 1.000272304562627,
        initial_entry_price: 172.05,
        is_closed_position: true,
        miner_hotkey: "5D5GDbEa5Aedt9fXhWTzngDTANvnf8Q5poSFKYkU9hEZ7P8C",
        net_leverage: 0.0,
        open_ms: 1719522618601,
        orders: [
          {
            leverage: 0.5,
            order_type: "LONG",
            order_uuid: "f23efe84-7979-47c9-9c72-5fd1a257da51",
            price: 172.05,
            price_sources: [],
            processed_ms: 1719522617844,
          },
          {
            leverage: 0.45,
            order_type: "LONG",
            order_uuid: "8ef8b72d-5ec4-46b6-868b-f625417908d7",
            price: 171.891,
            price_sources: [],
            processed_ms: 1719522693948,
          },
          {
            leverage: 0.95,
            order_type: "LONG",
            order_uuid: "c55ba15e-491c-4484-a190-12de72d362d1",
            price: 172.03,
            price_sources: [],
            processed_ms: 1719522818160,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "8ab05c05-f458-485b-91ce-bbc623dbcffc",
            price: 172.027,
            price_sources: [],
            processed_ms: 1719522848249,
          },
        ],
        position_type: "FLAT",
        position_uuid: "3b0c5c18-b6f6-4976-8741-f8e8b7f71711",
        return_at_close: 1.00013926834612,
        trade_pair: ["EURJPY", "EUR/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 172.016,
        close_ms: 1719522930007,
        current_return: 1.0000116268254116,
        initial_entry_price: 172.016,
        is_closed_position: true,
        miner_hotkey: "5D5GDbEa5Aedt9fXhWTzngDTANvnf8Q5poSFKYkU9hEZ7P8C",
        net_leverage: 0.0,
        open_ms: 1719522878587,
        orders: [
          {
            leverage: 0.5,
            order_type: "LONG",
            order_uuid: "9266d270-5339-4531-8cfd-435416d223cc",
            price: 172.016,
            price_sources: [],
            processed_ms: 1719522878044,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "5fc96937-96f0-4e33-8a64-2697e447718b",
            price: 172.02,
            price_sources: [],
            processed_ms: 1719522930007,
          },
        ],
        position_type: "FLAT",
        position_uuid: "f64af9d9-94db-4ecc-b4c0-8af5c03dcb8c",
        return_at_close: 0.9999766264184726,
        trade_pair: ["EURJPY", "EUR/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 172.05333333333334,
        close_ms: 1719523035804,
        current_return: 0.9998779353638688,
        initial_entry_price: 172.04,
        is_closed_position: true,
        miner_hotkey: "5D5GDbEa5Aedt9fXhWTzngDTANvnf8Q5poSFKYkU9hEZ7P8C",
        net_leverage: 0.0,
        open_ms: 1719522960172,
        orders: [
          {
            leverage: 0.5,
            order_type: "LONG",
            order_uuid: "e508370b-2e6b-4933-b8d4-e2cbb964eba0",
            price: 172.04,
            price_sources: [],
            processed_ms: 1719522959772,
          },
          {
            leverage: 0.4,
            order_type: "LONG",
            order_uuid: "63309e7f-3230-4850-8a0e-790be49d6b52",
            price: 172.07,
            price_sources: [],
            processed_ms: 1719522999943,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "194f15a1-d026-424e-a38c-688216e585fb",
            price: 172.03,
            price_sources: [],
            processed_ms: 1719523035804,
          },
        ],
        position_type: "FLAT",
        position_uuid: "6db0db3c-087c-43ee-817c-6d58e85a5ecc",
        return_at_close: 0.9998149430539408,
        trade_pair: ["EURJPY", "EUR/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 172.0604210526316,
        close_ms: 1719523211900,
        current_return: 0.9999424585876199,
        initial_entry_price: 172.05,
        is_closed_position: true,
        miner_hotkey: "5D5GDbEa5Aedt9fXhWTzngDTANvnf8Q5poSFKYkU9hEZ7P8C",
        net_leverage: 0.0,
        open_ms: 1719523066315,
        orders: [
          {
            leverage: 0.5,
            order_type: "LONG",
            order_uuid: "b23de621-2a42-4438-86f6-c236c7ccc4c6",
            price: 172.05,
            price_sources: [],
            processed_ms: 1719523065831,
          },
          {
            leverage: 0.45,
            order_type: "LONG",
            order_uuid: "c76b4259-f634-4f89-a6b4-844656e8d929",
            price: 172.072,
            price_sources: [],
            processed_ms: 1719523148066,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "71e86cad-550d-43dc-b877-960f7f73dcf2",
            price: 172.05,
            price_sources: [],
            processed_ms: 1719523211900,
          },
        ],
        position_type: "FLAT",
        position_uuid: "ff3649d9-285a-43a7-a722-4dfe45144edb",
        return_at_close: 0.9998759624141238,
        trade_pair: ["EURJPY", "EUR/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 172.05,
        close_ms: 1719523328160,
        current_return: 1.000023249055507,
        initial_entry_price: 172.05,
        is_closed_position: true,
        miner_hotkey: "5D5GDbEa5Aedt9fXhWTzngDTANvnf8Q5poSFKYkU9hEZ7P8C",
        net_leverage: 0.0,
        open_ms: 1719523251988,
        orders: [
          {
            leverage: 0.5,
            order_type: "LONG",
            order_uuid: "1a06639f-fc31-42df-aeeb-43e9de96de17",
            price: 172.05,
            price_sources: [],
            processed_ms: 1719523251926,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "7c5fd218-e5fd-4620-a220-70185dbbfc40",
            price: 172.058,
            price_sources: [],
            processed_ms: 1719523328160,
          },
        ],
        position_type: "FLAT",
        position_uuid: "f8acb707-471b-4a52-8dba-7d0b8ea63d50",
        return_at_close: 0.99998824824179,
        trade_pair: ["EURJPY", "EUR/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 172.1,
        close_ms: 1719523398083,
        current_return: 0.9999128413712958,
        initial_entry_price: 172.1,
        is_closed_position: true,
        miner_hotkey: "5D5GDbEa5Aedt9fXhWTzngDTANvnf8Q5poSFKYkU9hEZ7P8C",
        net_leverage: 0.0,
        open_ms: 1719523360369,
        orders: [
          {
            leverage: 0.5,
            order_type: "LONG",
            order_uuid: "6b5e6ae8-b983-4c42-9c1d-e547ca2511c1",
            price: 172.1,
            price_sources: [],
            processed_ms: 1719523360273,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "af9ae386-ca63-4b53-a931-78f9b3ba7bcb",
            price: 172.07,
            price_sources: [],
            processed_ms: 1719523398083,
          },
        ],
        position_type: "FLAT",
        position_uuid: "2e27128b-a8f0-41de-b9c1-2da9e6aa6624",
        return_at_close: 0.9998778444218478,
        trade_pair: ["EURJPY", "EUR/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 172.06434782608696,
        close_ms: 1719523518382,
        current_return: 1.0000912419364212,
        initial_entry_price: 172.07,
        is_closed_position: true,
        miner_hotkey: "5D5GDbEa5Aedt9fXhWTzngDTANvnf8Q5poSFKYkU9hEZ7P8C",
        net_leverage: 0.0,
        open_ms: 1719523429237,
        orders: [
          {
            leverage: 0.5,
            order_type: "LONG",
            order_uuid: "a7d8d4dd-7a59-4061-9405-6939a3f22062",
            price: 172.07,
            price_sources: [],
            processed_ms: 1719523429096,
          },
          {
            leverage: 0.65,
            order_type: "LONG",
            order_uuid: "8cc4c4b4-f0d7-4be0-a0cb-e4c0c1794c99",
            price: 172.06,
            price_sources: [],
            processed_ms: 1719523481562,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "9be4e52b-5510-4d09-8a7e-c6d57a132fd5",
            price: 172.078,
            price_sources: [],
            processed_ms: 1719523518382,
          },
        ],
        position_type: "FLAT",
        position_uuid: "5c25fa14-9c68-4f44-979d-96f00659086c",
        return_at_close: 1.0000107345914453,
        trade_pair: ["EURJPY", "EUR/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 172.0913043478261,
        close_ms: 1719523683974,
        current_return: 0.9999244537424453,
        initial_entry_price: 172.08,
        is_closed_position: true,
        miner_hotkey: "5D5GDbEa5Aedt9fXhWTzngDTANvnf8Q5poSFKYkU9hEZ7P8C",
        net_leverage: 0.0,
        open_ms: 1719523558379,
        orders: [
          {
            leverage: 0.5,
            order_type: "LONG",
            order_uuid: "a8d739d0-6c66-4498-ab6c-692ec58ba7b8",
            price: 172.08,
            price_sources: [],
            processed_ms: 1719523558007,
          },
          {
            leverage: 0.65,
            order_type: "LONG",
            order_uuid: "bfe51b29-f576-48c8-b510-6557b28e3126",
            price: 172.1,
            price_sources: [],
            processed_ms: 1719523615945,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "ef8a07d4-ee57-45bb-b088-a7637e1f0f92",
            price: 172.08,
            price_sources: [],
            processed_ms: 1719523683974,
          },
        ],
        position_type: "FLAT",
        position_uuid: "bbddbef2-a1dc-4235-892f-8f5ab14fcfce",
        return_at_close: 0.999843959823919,
        trade_pair: ["EURJPY", "EUR/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 172.074347826087,
        close_ms: 1719524001892,
        current_return: 0.999947549501066,
        initial_entry_price: 172.067,
        is_closed_position: true,
        miner_hotkey: "5D5GDbEa5Aedt9fXhWTzngDTANvnf8Q5poSFKYkU9hEZ7P8C",
        net_leverage: 0.0,
        open_ms: 1719523718029,
        orders: [
          {
            leverage: 0.5,
            order_type: "LONG",
            order_uuid: "51065c49-0ddc-48f4-bbde-695701ecaea3",
            price: 172.067,
            price_sources: [],
            processed_ms: 1719523717996,
          },
          {
            leverage: 0.65,
            order_type: "LONG",
            order_uuid: "9747050e-d96e-41de-ab1f-1ec852042513",
            price: 172.08,
            price_sources: [],
            processed_ms: 1719523840459,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "6b6e04d5-2f2a-42d6-8c4f-a5f996c04520",
            price: 172.0665,
            price_sources: [],
            processed_ms: 1719524001892,
          },
        ],
        position_type: "FLAT",
        position_uuid: "c279ead7-48b3-425a-9032-e07a5eb59c0c",
        return_at_close: 0.9998670537233312,
        trade_pair: ["EURJPY", "EUR/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 172.06400000000002,
        close_ms: 1719524768669,
        current_return: 0.9998721391126453,
        initial_entry_price: 172.062,
        is_closed_position: true,
        miner_hotkey: "5D5GDbEa5Aedt9fXhWTzngDTANvnf8Q5poSFKYkU9hEZ7P8C",
        net_leverage: 0.0,
        open_ms: 1719524032483,
        orders: [
          {
            leverage: 0.5,
            order_type: "LONG",
            order_uuid: "7fe9c7a5-6f3a-461f-8fe4-260ed28d8565",
            price: 172.062,
            price_sources: [],
            processed_ms: 1719524031960,
          },
          {
            leverage: 0.5,
            order_type: "LONG",
            order_uuid: "5adf558f-747f-4767-9917-6a1bd1af1932",
            price: 172.066,
            price_sources: [],
            processed_ms: 1719524156107,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "879ed429-5834-4a52-892f-ba706bc0b973",
            price: 172.042,
            price_sources: [],
            processed_ms: 1719524768669,
          },
        ],
        position_type: "FLAT",
        position_uuid: "83af67a7-a1b6-4c8f-9cb8-0347076c6afe",
        return_at_close: 0.9998021480629073,
        trade_pair: ["EURJPY", "EUR/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 172.0799276304873,
        close_ms: 1719525274543,
        current_return: 0.9999130414833984,
        initial_entry_price: 172.0799276304873,
        is_closed_position: true,
        miner_hotkey: "5D5GDbEa5Aedt9fXhWTzngDTANvnf8Q5poSFKYkU9hEZ7P8C",
        net_leverage: 0.0,
        open_ms: 1719524800073,
        orders: [
          {
            leverage: 0.5,
            order_type: "LONG",
            order_uuid: "f5181d2d-8bb1-4e5b-8a2f-cc375d99bee9",
            price: 172.0799276304873,
            price_sources: [],
            processed_ms: 1719524799700,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "c9a97b98-e0d9-457e-8500-c3e5f3dbbd7e",
            price: 172.05,
            price_sources: [],
            processed_ms: 1719525274543,
          },
        ],
        position_type: "FLAT",
        position_uuid: "f450adba-8ef0-4f09-b612-67fc3b479ac1",
        return_at_close: 0.9998780445269465,
        trade_pair: ["EURJPY", "EUR/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 172.05000000000004,
        close_ms: 1719525489940,
        current_return: 1.000050130775937,
        initial_entry_price: 172.05,
        is_closed_position: true,
        miner_hotkey: "5D5GDbEa5Aedt9fXhWTzngDTANvnf8Q5poSFKYkU9hEZ7P8C",
        net_leverage: 0.0,
        open_ms: 1719525304412,
        orders: [
          {
            leverage: 0.5,
            order_type: "LONG",
            order_uuid: "ef2d053c-45b6-4406-b190-b2a981a35c86",
            price: 172.05,
            price_sources: [],
            processed_ms: 1719525303891,
          },
          {
            leverage: 0.65,
            order_type: "LONG",
            order_uuid: "8ba1f5ec-c38f-4f18-8cb4-00afe3946041",
            price: 172.05,
            price_sources: [],
            processed_ms: 1719525419912,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "58634f2d-8731-4f73-9bd3-a585d4055853",
            price: 172.0575,
            price_sources: [],
            processed_ms: 1719525489940,
          },
        ],
        position_type: "FLAT",
        position_uuid: "563476be-036c-4ce0-ab86-5ba758a7833f",
        return_at_close: 0.9999696267404096,
        trade_pair: ["EURJPY", "EUR/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 172.03151927437642,
        close_ms: 1719526681240,
        current_return: 0.9998780246856185,
        initial_entry_price: 172.084,
        is_closed_position: true,
        miner_hotkey: "5D5GDbEa5Aedt9fXhWTzngDTANvnf8Q5poSFKYkU9hEZ7P8C",
        net_leverage: 0.0,
        open_ms: 1719525530371,
        orders: [
          {
            leverage: 0.5,
            order_type: "LONG",
            order_uuid: "b18b19b1-93f2-43db-b6e9-9841c28fe55e",
            price: 172.084,
            price_sources: [],
            processed_ms: 1719525529779,
          },
          {
            leverage: 0.55,
            order_type: "LONG",
            order_uuid: "c1c14a32-2d07-46e1-8683-810b8913a52f",
            price: 172.05,
            price_sources: [],
            processed_ms: 1719525700088,
          },
          {
            leverage: 1.1550000000000002,
            order_type: "LONG",
            order_uuid: "71db9170-aaa4-47f3-81cd-54adb0c836c8",
            price: 172.0,
            price_sources: [],
            processed_ms: 1719525949132,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "e15a459c-2130-4fcd-89e4-298011dcdc60",
            price: 172.022,
            price_sources: [],
            processed_ms: 1719526681240,
          },
        ],
        position_type: "FLAT",
        position_uuid: "ef1721a3-3880-464a-82db-0f8eac9b0292",
        return_at_close: 0.9997236935125083,
        trade_pair: ["EURJPY", "EUR/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 172.026,
        close_ms: 1719527023615,
        current_return: 1.0000552242102938,
        initial_entry_price: 172.026,
        is_closed_position: true,
        miner_hotkey: "5D5GDbEa5Aedt9fXhWTzngDTANvnf8Q5poSFKYkU9hEZ7P8C",
        net_leverage: 0.0,
        open_ms: 1719526715063,
        orders: [
          {
            leverage: 0.5,
            order_type: "LONG",
            order_uuid: "f1d7f1d3-b038-4316-a8c3-c8e626e90eb9",
            price: 172.026,
            price_sources: [],
            processed_ms: 1719526715005,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "7d8c32a8-c342-4aac-aa7b-59079fa3a36d",
            price: 172.04500000000002,
            price_sources: [],
            processed_ms: 1719527023615,
          },
        ],
        position_type: "FLAT",
        position_uuid: "fb3b1ea2-ed09-4910-aea7-23c5559795b9",
        return_at_close: 1.0000202222774464,
        trade_pair: ["EURJPY", "EUR/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 172.0118181818182,
        close_ms: 1719531071934,
        current_return: 1.0001737988118902,
        initial_entry_price: 172.038,
        is_closed_position: true,
        miner_hotkey: "5D5GDbEa5Aedt9fXhWTzngDTANvnf8Q5poSFKYkU9hEZ7P8C",
        net_leverage: 0.0,
        open_ms: 1719527053054,
        orders: [
          {
            leverage: 0.5,
            order_type: "LONG",
            order_uuid: "e433e360-9d96-4b18-9551-54fdddd5df74",
            price: 172.038,
            price_sources: [],
            processed_ms: 1719527053010,
          },
          {
            leverage: 0.6,
            order_type: "LONG",
            order_uuid: "9ddb6eac-545b-4425-abca-135488725a28",
            price: 171.99,
            price_sources: [],
            processed_ms: 1719531022293,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "ac0b4180-82b2-4ad4-ba3c-dbabe97d99d7",
            price: 172.039,
            price_sources: [],
            processed_ms: 1719531071934,
          },
        ],
        position_type: "FLAT",
        position_uuid: "224b0bad-6fa1-4c30-a508-48fd3521f314",
        return_at_close: 1.0000967854293816,
        trade_pair: ["EURJPY", "EUR/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 172.071,
        close_ms: 1719533233883,
        current_return: 1.0001423830860516,
        initial_entry_price: 172.071,
        is_closed_position: true,
        miner_hotkey: "5D5GDbEa5Aedt9fXhWTzngDTANvnf8Q5poSFKYkU9hEZ7P8C",
        net_leverage: 0.0,
        open_ms: 1719531105842,
        orders: [
          {
            leverage: 0.5,
            order_type: "LONG",
            order_uuid: "29d52101-45ec-4cdf-a9cb-2baa79e14cb5",
            price: 172.071,
            price_sources: [],
            processed_ms: 1719531105805,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "e7371e25-b5b8-4abc-af32-122831bdfa6c",
            price: 172.12,
            price_sources: [],
            processed_ms: 1719533233883,
          },
        ],
        position_type: "FLAT",
        position_uuid: "4a8132a8-506d-40ee-a837-1130d63eb550",
        return_at_close: 1.0001073781026437,
        trade_pair: ["EURJPY", "EUR/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 172.0623609782031,
        close_ms: 1719534718475,
        current_return: 0.9999046755797263,
        initial_entry_price: 172.107,
        is_closed_position: true,
        miner_hotkey: "5D5GDbEa5Aedt9fXhWTzngDTANvnf8Q5poSFKYkU9hEZ7P8C",
        net_leverage: 0.0,
        open_ms: 1719533265640,
        orders: [
          {
            leverage: 0.5,
            order_type: "LONG",
            order_uuid: "ac5ddf51-28e2-415b-b003-3c47943571af",
            price: 172.107,
            price_sources: [],
            processed_ms: 1719533265492,
          },
          {
            leverage: 0.6,
            order_type: "LONG",
            order_uuid: "d8944158-fb95-451e-8d4d-cb0d8e9264cf",
            price: 172.095,
            price_sources: [],
            processed_ms: 1719533312226,
          },
          {
            leverage: 0.8800000000000001,
            order_type: "LONG",
            order_uuid: "7708d9b2-e1a5-4473-aea1-e8616c1a7d3d",
            price: 172.058,
            price_sources: [],
            processed_ms: 1719534372398,
          },
          {
            leverage: 1.7820000000000003,
            order_type: "LONG",
            order_uuid: "03a21e99-d911-4b5a-83fa-b04d6cbd3bd9",
            price: 172.041,
            price_sources: [],
            processed_ms: 1719534427898,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "80e00509-6960-4dfc-a20f-8f1870b2bce2",
            price: 172.058,
            price_sources: [],
            processed_ms: 1719534718475,
          },
        ],
        position_type: "FLAT",
        position_uuid: "fee57d56-874f-461e-9c57-54711d068cb3",
        return_at_close: 0.9996413606824591,
        trade_pair: ["EURJPY", "EUR/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 172.06,
        close_ms: 1719535969862,
        current_return: 1.0002324770428919,
        initial_entry_price: 172.06,
        is_closed_position: true,
        miner_hotkey: "5D5GDbEa5Aedt9fXhWTzngDTANvnf8Q5poSFKYkU9hEZ7P8C",
        net_leverage: 0.0,
        open_ms: 1719534749211,
        orders: [
          {
            leverage: 0.5,
            order_type: "LONG",
            order_uuid: "62348d5d-871e-452a-83c4-594dab13f2a7",
            price: 172.06,
            price_sources: [],
            processed_ms: 1719534749053,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "65ecef55-2367-4dcd-a815-e6d979104d51",
            price: 172.14,
            price_sources: [],
            processed_ms: 1719535969862,
          },
        ],
        position_type: "FLAT",
        position_uuid: "14606ed6-04a4-4b5e-aafc-9478de4a2981",
        return_at_close: 1.0001974689061954,
        trade_pair: ["EURJPY", "EUR/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 172.17849999999999,
        close_ms: 1719536105917,
        current_return: 1.0002381249691454,
        initial_entry_price: 172.17849999999999,
        is_closed_position: true,
        miner_hotkey: "5D5GDbEa5Aedt9fXhWTzngDTANvnf8Q5poSFKYkU9hEZ7P8C",
        net_leverage: 0.0,
        open_ms: 1719535999989,
        orders: [
          {
            leverage: 0.5,
            order_type: "LONG",
            order_uuid: "286ce7cc-3e38-4269-a778-dc951d5f8113",
            price: 172.17849999999999,
            price_sources: [],
            processed_ms: 1719535999960,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "e4b30167-9b62-4cdc-93dd-74d537859f3d",
            price: 172.26049999999998,
            price_sources: [],
            processed_ms: 1719536105917,
          },
        ],
        position_type: "FLAT",
        position_uuid: "f1620828-0224-42df-82ae-dc2979957199",
        return_at_close: 1.0002031166347716,
        trade_pair: ["EURJPY", "EUR/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 172.19599999999997,
        close_ms: 1719536292201,
        current_return: 1.0003577069590266,
        initial_entry_price: 172.208,
        is_closed_position: true,
        miner_hotkey: "5D5GDbEa5Aedt9fXhWTzngDTANvnf8Q5poSFKYkU9hEZ7P8C",
        net_leverage: 0.0,
        open_ms: 1719536136069,
        orders: [
          {
            leverage: 0.5,
            order_type: "LONG",
            order_uuid: "b09a5f11-1b73-4f5e-b3d8-c4ac110c4b8f",
            price: 172.208,
            price_sources: [],
            processed_ms: 1719536136017,
          },
          {
            leverage: 0.6,
            order_type: "LONG",
            order_uuid: "e1753113-a904-44df-9619-91d1a25e17d4",
            price: 172.186,
            price_sources: [],
            processed_ms: 1719536184173,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "ab50c1fa-841c-4911-a099-b0d5bfaf8bcc",
            price: 172.252,
            price_sources: [],
            processed_ms: 1719536292201,
          },
        ],
        position_type: "FLAT",
        position_uuid: "d485d33b-df81-420c-bf43-e4455cd29613",
        return_at_close: 1.0002806794155907,
        trade_pair: ["EURJPY", "EUR/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 172.249,
        close_ms: 1719536372102,
        current_return: 1.0002409302811628,
        initial_entry_price: 172.249,
        is_closed_position: true,
        miner_hotkey: "5D5GDbEa5Aedt9fXhWTzngDTANvnf8Q5poSFKYkU9hEZ7P8C",
        net_leverage: 0.0,
        open_ms: 1719536322127,
        orders: [
          {
            leverage: 0.5,
            order_type: "LONG",
            order_uuid: "343b8850-482f-4733-a168-11ef32b9b68b",
            price: 172.249,
            price_sources: [],
            processed_ms: 1719536322055,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "93c0df3f-d013-4dee-9110-2cefbe4b3db9",
            price: 172.332,
            price_sources: [],
            processed_ms: 1719536372102,
          },
        ],
        position_type: "FLAT",
        position_uuid: "d7dfed55-bb8e-46d2-9ea1-1f5ad4b50429",
        return_at_close: 1.000205921848603,
        trade_pair: ["EURJPY", "EUR/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 172.22200894528814,
        close_ms: 1719536950287,
        current_return: 1.0011708754819215,
        initial_entry_price: 172.35649999999998,
        is_closed_position: true,
        miner_hotkey: "5D5GDbEa5Aedt9fXhWTzngDTANvnf8Q5poSFKYkU9hEZ7P8C",
        net_leverage: 0.0,
        open_ms: 1719536402505,
        orders: [
          {
            leverage: 0.5,
            order_type: "LONG",
            order_uuid: "23dadcaa-a42e-4e93-a024-9c88b58de856",
            price: 172.35649999999998,
            price_sources: [],
            processed_ms: 1719536402424,
          },
          {
            leverage: 0.5,
            order_type: "LONG",
            order_uuid: "c5f639ab-7d05-42c0-ba33-a85836c8d1cd",
            price: 172.333,
            price_sources: [],
            processed_ms: 1719536445825,
          },
          {
            leverage: 1.3,
            order_type: "LONG",
            order_uuid: "ef47af75-cda1-4c41-a099-abc36f8c28cf",
            price: 172.258,
            price_sources: [],
            processed_ms: 1719536614256,
          },
          {
            leverage: 2.76,
            order_type: "LONG",
            order_uuid: "97ae64dc-8f00-4eeb-a464-d52c54e18ebb",
            price: 172.22,
            price_sources: [],
            processed_ms: 1719536651879,
          },
          {
            leverage: 4.553999999999999,
            order_type: "LONG",
            order_uuid: "847245d6-c2a3-4306-a3b3-158772a20b9f",
            price: 172.186,
            price_sources: [],
            processed_ms: 1719536834158,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "baf9332c-3c1d-4371-9185-422c4427f53e",
            price: 172.243,
            price_sources: [],
            processed_ms: 1719536950287,
          },
        ],
        position_type: "FLAT",
        position_uuid: "d3a42741-275a-476e-960f-95633163dd9e",
        return_at_close: 1.0004971075061397,
        trade_pair: ["EURJPY", "EUR/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 172.17557268170427,
        close_ms: 1719537291900,
        current_return: 1.0004884275856865,
        initial_entry_price: 172.2415,
        is_closed_position: true,
        miner_hotkey: "5D5GDbEa5Aedt9fXhWTzngDTANvnf8Q5poSFKYkU9hEZ7P8C",
        net_leverage: 0.0,
        open_ms: 1719536980004,
        orders: [
          {
            leverage: 0.5,
            order_type: "LONG",
            order_uuid: "6bf27037-d718-4ecc-a99f-da72aa2c99dd",
            price: 172.2415,
            price_sources: [],
            processed_ms: 1719536979839,
          },
          {
            leverage: 0.45,
            order_type: "LONG",
            order_uuid: "bd93712e-63c2-449b-b975-0f4b4c88ebfe",
            price: 172.179,
            price_sources: [],
            processed_ms: 1719537020123,
          },
          {
            leverage: 1.045,
            order_type: "LONG",
            order_uuid: "0c10b41f-afd2-476d-97a4-c32902e3d87a",
            price: 172.1755,
            price_sources: [],
            processed_ms: 1719537078974,
          },
          {
            leverage: 1.596,
            order_type: "LONG",
            order_uuid: "eab08050-18d9-4fde-8e09-00f99b21371b",
            price: 172.154,
            price_sources: [],
            processed_ms: 1719537220230,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "e649e271-7da2-43fe-bff1-f33ac2a68408",
            price: 172.199,
            price_sources: [],
            processed_ms: 1719537291900,
          },
        ],
        position_type: "FLAT",
        position_uuid: "9d1a4ca3-252f-404c-aceb-40f1b28e13d0",
        return_at_close: 1.0002369348096443,
        trade_pair: ["EURJPY", "EUR/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 172.16066666666666,
        close_ms: 1719537548222,
        current_return: 1.0003085437524681,
        initial_entry_price: 172.196,
        is_closed_position: true,
        miner_hotkey: "5D5GDbEa5Aedt9fXhWTzngDTANvnf8Q5poSFKYkU9hEZ7P8C",
        net_leverage: 0.0,
        open_ms: 1719537321926,
        orders: [
          {
            leverage: 0.5,
            order_type: "LONG",
            order_uuid: "d271332d-df3a-4120-97b2-bf9b35d9a65b",
            price: 172.196,
            price_sources: [],
            processed_ms: 1719537321837,
          },
          {
            leverage: 0.6,
            order_type: "LONG",
            order_uuid: "577555cb-abce-458c-994d-10d33fce4089",
            price: 172.163,
            price_sources: [],
            processed_ms: 1719537352160,
          },
          {
            leverage: 0.8800000000000001,
            order_type: "LONG",
            order_uuid: "22deecc5-1ae9-49f2-91bc-0088fe3451c7",
            price: 172.139,
            price_sources: [],
            processed_ms: 1719537393989,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "aa68d0a4-e86f-4c16-8d8c-8c67c5a67cf8",
            price: 172.1875,
            price_sources: [],
            processed_ms: 1719537548222,
          },
        ],
        position_type: "FLAT",
        position_uuid: "47e8049e-0054-4e74-b36d-555d95b5b37e",
        return_at_close: 1.000169900988304,
        trade_pair: ["EURJPY", "EUR/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 172.186,
        close_ms: 1719537843783,
        current_return: 1.0001495475822657,
        initial_entry_price: 172.186,
        is_closed_position: true,
        miner_hotkey: "5D5GDbEa5Aedt9fXhWTzngDTANvnf8Q5poSFKYkU9hEZ7P8C",
        net_leverage: 0.0,
        open_ms: 1719537578193,
        orders: [
          {
            leverage: 0.5,
            order_type: "LONG",
            order_uuid: "13d148e2-04da-454d-8743-c9f5d3f36b64",
            price: 172.186,
            price_sources: [],
            processed_ms: 1719537578065,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "cd5be18e-a01e-40f5-99c9-947efee6e014",
            price: 172.2375,
            price_sources: [],
            processed_ms: 1719537843783,
          },
        ],
        position_type: "FLAT",
        position_uuid: "ff7f9fc6-ec09-4a07-9b61-a4f88acf7451",
        return_at_close: 1.0001145423481004,
        trade_pair: ["EURJPY", "EUR/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 172.22725000000003,
        close_ms: 1719538184197,
        current_return: 1.0001901576711925,
        initial_entry_price: 172.2255,
        is_closed_position: true,
        miner_hotkey: "5D5GDbEa5Aedt9fXhWTzngDTANvnf8Q5poSFKYkU9hEZ7P8C",
        net_leverage: 0.0,
        open_ms: 1719537872085,
        orders: [
          {
            leverage: 0.5,
            order_type: "LONG",
            order_uuid: "786066d0-7684-423b-b60a-157569a8b754",
            price: 172.2255,
            price_sources: [],
            processed_ms: 1719537872040,
          },
          {
            leverage: 0.5,
            order_type: "LONG",
            order_uuid: "6f2c2860-4c75-4b9d-a9e6-ff4e08057b28",
            price: 172.229,
            price_sources: [],
            processed_ms: 1719538054811,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "a4d891f8-9bd2-4a0c-8640-33bccc179633",
            price: 172.26,
            price_sources: [],
            processed_ms: 1719538184197,
          },
        ],
        position_type: "FLAT",
        position_uuid: "23b80f04-b225-42f2-9c50-cb93c049d4cf",
        return_at_close: 1.0001201443601555,
        trade_pair: ["EURJPY", "EUR/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 172.34300000000002,
        close_ms: 1719538288636,
        current_return: 1.0000507708465096,
        initial_entry_price: 172.34300000000002,
        is_closed_position: true,
        miner_hotkey: "5D5GDbEa5Aedt9fXhWTzngDTANvnf8Q5poSFKYkU9hEZ7P8C",
        net_leverage: 0.0,
        open_ms: 1719538214116,
        orders: [
          {
            leverage: 0.5,
            order_type: "LONG",
            order_uuid: "9fa0a4f8-be4e-4435-874c-832d0d6eb5cb",
            price: 172.34300000000002,
            price_sources: [],
            processed_ms: 1719538213837,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "acf726ef-457e-4832-91f4-3f930214ea6e",
            price: 172.3605,
            price_sources: [],
            processed_ms: 1719538288636,
          },
        ],
        position_type: "FLAT",
        position_uuid: "2c4af118-3bc1-40de-b548-1876563465fc",
        return_at_close: 1.00001576906953,
        trade_pair: ["EURJPY", "EUR/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 172.2804642857143,
        close_ms: 1719538790407,
        current_return: 1.0004147854547987,
        initial_entry_price: 172.31799999999998,
        is_closed_position: true,
        miner_hotkey: "5D5GDbEa5Aedt9fXhWTzngDTANvnf8Q5poSFKYkU9hEZ7P8C",
        net_leverage: 0.0,
        open_ms: 1719538318923,
        orders: [
          {
            leverage: 0.5,
            order_type: "LONG",
            order_uuid: "e924b670-336d-4baf-9ecf-457fa8f2fea3",
            price: 172.31799999999998,
            price_sources: [],
            processed_ms: 1719538318716,
          },
          {
            leverage: 0.55,
            order_type: "LONG",
            order_uuid: "e06e68b3-7dbc-4ab5-a3b2-18ecd07f49ef",
            price: 172.2835,
            price_sources: [],
            processed_ms: 1719538347972,
          },
          {
            leverage: 1.05,
            order_type: "LONG",
            order_uuid: "f0e1f378-8618-40d1-bebd-5e726e43d796",
            price: 172.261,
            price_sources: [],
            processed_ms: 1719538521989,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "2366a107-47ad-4be3-8a4d-e2534cc4ff48",
            price: 172.3145,
            price_sources: [],
            processed_ms: 1719538790407,
          },
        ],
        position_type: "FLAT",
        position_uuid: "5d7ddf64-3f84-4c34-9566-f4929f17874b",
        return_at_close: 1.0002677244813367,
        trade_pair: ["EURJPY", "EUR/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 172.1758636363636,
        close_ms: 1719538952800,
        current_return: 1.000206075452641,
        initial_entry_price: 172.267,
        is_closed_position: true,
        miner_hotkey: "5D5GDbEa5Aedt9fXhWTzngDTANvnf8Q5poSFKYkU9hEZ7P8C",
        net_leverage: 0.0,
        open_ms: 1719538820051,
        orders: [
          {
            leverage: 0.5,
            order_type: "LONG",
            order_uuid: "145de77f-e8b7-4f10-8548-7a35d20a6265",
            price: 172.267,
            price_sources: [],
            processed_ms: 1719538819900,
          },
          {
            leverage: 0.6,
            order_type: "LONG",
            order_uuid: "1fb2cc11-6d2b-4db0-a2a9-528bfda56c6c",
            price: 172.195,
            price_sources: [],
            processed_ms: 1719538851835,
          },
          {
            leverage: 1.1,
            order_type: "LONG",
            order_uuid: "17202b7a-c86a-48a8-a935-e3243a211321",
            price: 172.124,
            price_sources: [],
            processed_ms: 1719538883153,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "d81443eb-2ae7-4cbb-8226-fa45309eb189",
            price: 172.192,
            price_sources: [],
            processed_ms: 1719538952800,
          },
        ],
        position_type: "FLAT",
        position_uuid: "f818f395-2ca7-4c5f-9fad-7290cfdfffe5",
        return_at_close: 1.0000520437170213,
        trade_pair: ["EURJPY", "EUR/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 172.11229545454546,
        close_ms: 1719539321826,
        current_return: 1.0001815013794106,
        initial_entry_price: 172.175,
        is_closed_position: true,
        miner_hotkey: "5D5GDbEa5Aedt9fXhWTzngDTANvnf8Q5poSFKYkU9hEZ7P8C",
        net_leverage: 0.0,
        open_ms: 1719538982040,
        orders: [
          {
            leverage: 0.5,
            order_type: "LONG",
            order_uuid: "fe128639-e8b1-45f4-879d-482a3bc73684",
            price: 172.175,
            price_sources: [],
            processed_ms: 1719538981888,
          },
          {
            leverage: 0.5,
            order_type: "LONG",
            order_uuid: "9fa56322-fa6b-4998-a08f-eaf6b7bdf60b",
            price: 172.1295,
            price_sources: [],
            processed_ms: 1719539234142,
          },
          {
            leverage: 1.2,
            order_type: "LONG",
            order_uuid: "84023d24-9ed3-422a-a6d3-4c9b4c4d6221",
            price: 172.079,
            price_sources: [],
            processed_ms: 1719539278060,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "ddf7c26a-e680-4639-b960-23ba683f44a4",
            price: 172.12650000000002,
            price_sources: [],
            processed_ms: 1719539321826,
          },
        ],
        position_type: "FLAT",
        position_uuid: "c0833baf-c7c7-4cd3-8fc4-59e991743136",
        return_at_close: 1.0000274734281982,
        trade_pair: ["EURJPY", "EUR/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 172.1095,
        close_ms: 1719539477882,
        current_return: 1.0002265426684402,
        initial_entry_price: 172.153,
        is_closed_position: true,
        miner_hotkey: "5D5GDbEa5Aedt9fXhWTzngDTANvnf8Q5poSFKYkU9hEZ7P8C",
        net_leverage: 0.0,
        open_ms: 1719539352264,
        orders: [
          {
            leverage: 0.5,
            order_type: "LONG",
            order_uuid: "ae4da95a-ffac-42d2-ac24-babb79b29ee7",
            price: 172.153,
            price_sources: [],
            processed_ms: 1719539351945,
          },
          {
            leverage: 0.5,
            order_type: "LONG",
            order_uuid: "79887f8f-c48d-4b09-b43e-6842094eef59",
            price: 172.116,
            price_sources: [],
            processed_ms: 1719539383848,
          },
          {
            leverage: 1.0,
            order_type: "LONG",
            order_uuid: "a5192d99-67db-417f-89d6-c145ab0b9af1",
            price: 172.0845,
            price_sources: [],
            processed_ms: 1719539413815,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "39424ca2-dcc5-4fc0-b000-18e795123248",
            price: 172.129,
            price_sources: [],
            processed_ms: 1719539477882,
          },
        ],
        position_type: "FLAT",
        position_uuid: "cd3329a3-c9b0-4073-a132-305f9f30b297",
        return_at_close: 1.0000865109524666,
        trade_pair: ["EURJPY", "EUR/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 172.179,
        close_ms: 1719539773532,
        current_return: 1.0001001864338857,
        initial_entry_price: 172.179,
        is_closed_position: true,
        miner_hotkey: "5D5GDbEa5Aedt9fXhWTzngDTANvnf8Q5poSFKYkU9hEZ7P8C",
        net_leverage: 0.0,
        open_ms: 1719539509349,
        orders: [
          {
            leverage: 0.5,
            order_type: "LONG",
            order_uuid: "562dfa53-73ff-4178-acbc-f273e96b299e",
            price: 172.179,
            price_sources: [],
            processed_ms: 1719539509091,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "c5bbc1bf-df0b-4284-88ef-8a007680b31c",
            price: 172.2135,
            price_sources: [],
            processed_ms: 1719539773532,
          },
        ],
        position_type: "FLAT",
        position_uuid: "a19cb4b4-7754-4e9a-8323-e0a94724fc53",
        return_at_close: 1.0000651829273604,
        trade_pair: ["EURJPY", "EUR/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 172.08888198757765,
        close_ms: 1719540904761,
        current_return: 1.0004100436148646,
        initial_entry_price: 172.189,
        is_closed_position: true,
        miner_hotkey: "5D5GDbEa5Aedt9fXhWTzngDTANvnf8Q5poSFKYkU9hEZ7P8C",
        net_leverage: 0.0,
        open_ms: 1719539802372,
        orders: [
          {
            leverage: 0.5,
            order_type: "LONG",
            order_uuid: "b801efec-7a45-498d-a0ce-f92de30976ee",
            price: 172.189,
            price_sources: [],
            processed_ms: 1719539802047,
          },
          {
            leverage: 0.5,
            order_type: "LONG",
            order_uuid: "5934b2f7-a00a-471d-9e94-717e058c9168",
            price: 172.132,
            price_sources: [],
            processed_ms: 1719540066408,
          },
          {
            leverage: 1.3,
            order_type: "LONG",
            order_uuid: "c5b00a41-f750-45d1-bd65-f5ed27fb97d5",
            price: 172.09,
            price_sources: [],
            processed_ms: 1719540842331,
          },
          {
            leverage: 2.53,
            order_type: "LONG",
            order_uuid: "910a3ad3-0e25-4e4e-af01-e8cd994ecfcf",
            price: 172.06,
            price_sources: [],
            processed_ms: 1719540875295,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "96c7a583-1b75-4447-83eb-1cd3ebd01da3",
            price: 172.1035,
            price_sources: [],
            processed_ms: 1719540904761,
          },
        ],
        position_type: "FLAT",
        position_uuid: "a07299d3-6513-4005-abad-fb3533e6cbc9",
        return_at_close: 1.0000718049791184,
        trade_pair: ["EURJPY", "EUR/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 172.09550000000002,
        close_ms: 1719541805116,
        current_return: 1.0002077334968085,
        initial_entry_price: 172.09550000000002,
        is_closed_position: true,
        miner_hotkey: "5D5GDbEa5Aedt9fXhWTzngDTANvnf8Q5poSFKYkU9hEZ7P8C",
        net_leverage: 0.0,
        open_ms: 1719540934961,
        orders: [
          {
            leverage: 0.5,
            order_type: "LONG",
            order_uuid: "708ccf6c-8f1f-429f-abc4-db5992973db7",
            price: 172.09550000000002,
            price_sources: [],
            processed_ms: 1719540934865,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "3cbad258-2c4b-4476-a601-850816ed4c00",
            price: 172.167,
            price_sources: [],
            processed_ms: 1719541805116,
          },
        ],
        position_type: "FLAT",
        position_uuid: "0036c106-9398-48c0-8067-89d2f5430ca1",
        return_at_close: 1.0001727262261362,
        trade_pair: ["EURJPY", "EUR/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 172.08866439909298,
        close_ms: 1719542418416,
        current_return: 1.00031597778862,
        initial_entry_price: 172.164,
        is_closed_position: true,
        miner_hotkey: "5D5GDbEa5Aedt9fXhWTzngDTANvnf8Q5poSFKYkU9hEZ7P8C",
        net_leverage: 0.0,
        open_ms: 1719541833952,
        orders: [
          {
            leverage: 0.5,
            order_type: "LONG",
            order_uuid: "fe2a6024-d2fb-4e53-86c2-8685b1e1cad4",
            price: 172.164,
            price_sources: [],
            processed_ms: 1719541833901,
          },
          {
            leverage: 0.55,
            order_type: "LONG",
            order_uuid: "282c69ec-4ee8-4c26-9ae6-f32352f870ae",
            price: 172.13,
            price_sources: [],
            processed_ms: 1719541940237,
          },
          {
            leverage: 1.1550000000000002,
            order_type: "LONG",
            order_uuid: "42b88cfb-f6d3-4c72-a06f-82e01c72f260",
            price: 172.114,
            price_sources: [],
            processed_ms: 1719542046032,
          },
          {
            leverage: 2.205,
            order_type: "LONG",
            order_uuid: "1efb646b-509a-4d84-a10a-bcd51c3fa294",
            price: 172.048,
            price_sources: [],
            processed_ms: 1719542198299,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "e300bdb5-531c-4c02-b3e0-16c001a866ab",
            price: 172.101,
            price_sources: [],
            processed_ms: 1719542418416,
          },
        ],
        position_type: "FLAT",
        position_uuid: "983fd57f-fa42-4194-89d3-ccc581b93d66",
        return_at_close: 1.0000071802462764,
        trade_pair: ["EURJPY", "EUR/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 172.08083333333332,
        close_ms: 1719542674183,
        current_return: 1.0000296343642412,
        initial_entry_price: 172.09750000000003,
        is_closed_position: true,
        miner_hotkey: "5D5GDbEa5Aedt9fXhWTzngDTANvnf8Q5poSFKYkU9hEZ7P8C",
        net_leverage: 0.0,
        open_ms: 1719542448470,
        orders: [
          {
            leverage: 0.5,
            order_type: "LONG",
            order_uuid: "7a4e027c-6ec4-424a-944a-95ce529e55eb",
            price: 172.09750000000003,
            price_sources: [],
            processed_ms: 1719542448428,
          },
          {
            leverage: 0.4,
            order_type: "LONG",
            order_uuid: "be59b398-f62a-4f2b-be17-a43fe634dba3",
            price: 172.06,
            price_sources: [],
            processed_ms: 1719542634712,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "e0363b25-bcf1-4313-b67c-5445a94b7671",
            price: 172.0865,
            price_sources: [],
            processed_ms: 1719542674183,
          },
        ],
        position_type: "FLAT",
        position_uuid: "b9d87de1-990f-4b4e-a1ca-4e733c55cf3a",
        return_at_close: 0.9999666324972762,
        trade_pair: ["EURJPY", "EUR/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 172.04891322314052,
        close_ms: 1719543348756,
        current_return: 1.00040900282682,
        initial_entry_price: 172.1015,
        is_closed_position: true,
        miner_hotkey: "5D5GDbEa5Aedt9fXhWTzngDTANvnf8Q5poSFKYkU9hEZ7P8C",
        net_leverage: 0.0,
        open_ms: 1719542704117,
        orders: [
          {
            leverage: 0.5,
            order_type: "LONG",
            order_uuid: "25e0e326-fded-4a7f-894f-15976203b82a",
            price: 172.1015,
            price_sources: [],
            processed_ms: 1719542704017,
          },
          {
            leverage: 0.6,
            order_type: "LONG",
            order_uuid: "3cd68870-0ce3-4741-9b8f-71267c12ab26",
            price: 172.0555,
            price_sources: [],
            processed_ms: 1719542838004,
          },
          {
            leverage: 1.32,
            order_type: "LONG",
            order_uuid: "fe579f7a-3c12-4051-b8dc-c68140b8a175",
            price: 172.026,
            price_sources: [],
            processed_ms: 1719542948030,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "0185b7c3-ac17-4e1f-a37d-d3a15627b1b4",
            price: 172.078,
            price_sources: [],
            processed_ms: 1719543348756,
          },
        ],
        position_type: "FLAT",
        position_uuid: "5891f6e6-13d6-44b5-8869-29d06edef760",
        return_at_close: 1.000239533541741,
        trade_pair: ["EURJPY", "EUR/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 172.0473896103896,
        close_ms: 1719544470171,
        current_return: 1.000209545171919,
        initial_entry_price: 172.087,
        is_closed_position: true,
        miner_hotkey: "5D5GDbEa5Aedt9fXhWTzngDTANvnf8Q5poSFKYkU9hEZ7P8C",
        net_leverage: 0.0,
        open_ms: 1719543378199,
        orders: [
          {
            leverage: 0.5,
            order_type: "LONG",
            order_uuid: "e3da0c9f-1297-4f84-9318-f4a5ef624d58",
            price: 172.087,
            price_sources: [],
            processed_ms: 1719543378063,
          },
          {
            leverage: 0.55,
            order_type: "LONG",
            order_uuid: "e6ae5969-9a3d-4baa-9c91-6776ad4030be",
            price: 172.081,
            price_sources: [],
            processed_ms: 1719543437956,
          },
          {
            leverage: 1.26,
            order_type: "LONG",
            order_uuid: "61146353-f540-4dc5-84ea-063b1042b69e",
            price: 172.017,
            price_sources: [],
            processed_ms: 1719543906992,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "1b9a9365-da3c-4db6-b626-f3bbac0466c3",
            price: 172.063,
            price_sources: [],
            processed_ms: 1719544470171,
          },
        ],
        position_type: "FLAT",
        position_uuid: "5425745e-dbd3-4969-b35d-449d17077c6d",
        return_at_close: 1.0000478112884648,
        trade_pair: ["EURJPY", "EUR/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 172.063,
        close_ms: 1719545435256,
        current_return: 1.0001743547421584,
        initial_entry_price: 172.063,
        is_closed_position: true,
        miner_hotkey: "5D5GDbEa5Aedt9fXhWTzngDTANvnf8Q5poSFKYkU9hEZ7P8C",
        net_leverage: 0.0,
        open_ms: 1719544499012,
        orders: [
          {
            leverage: 0.5,
            order_type: "LONG",
            order_uuid: "205061a0-9947-4cd3-81cf-35cb3905b19c",
            price: 172.063,
            price_sources: [],
            processed_ms: 1719544498944,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "1108a601-2e5c-4e2b-937f-2b86826a6910",
            price: 172.123,
            price_sources: [],
            processed_ms: 1719545435256,
          },
        ],
        position_type: "FLAT",
        position_uuid: "6a00aa6e-ad6e-4eec-b562-03b0d5a1c9e6",
        return_at_close: 1.0001393486397425,
        trade_pair: ["EURJPY", "EUR/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 172.09855263157897,
        close_ms: 1719547089587,
        current_return: 1.000195646111479,
        initial_entry_price: 172.122,
        is_closed_position: true,
        miner_hotkey: "5D5GDbEa5Aedt9fXhWTzngDTANvnf8Q5poSFKYkU9hEZ7P8C",
        net_leverage: 0.0,
        open_ms: 1719545465356,
        orders: [
          {
            leverage: 0.5,
            order_type: "LONG",
            order_uuid: "21f2e258-6d66-4526-821f-e2e0f6b41e11",
            price: 172.122,
            price_sources: [],
            processed_ms: 1719545465253,
          },
          {
            leverage: 0.45,
            order_type: "LONG",
            order_uuid: "dda6901c-fcc0-451a-98f4-0809b02b224f",
            price: 172.0725,
            price_sources: [],
            processed_ms: 1719546146314,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "8caecac7-7ce6-423d-aef2-bc2872917f7e",
            price: 172.13400000000001,
            price_sources: [],
            processed_ms: 1719547089587,
          },
        ],
        position_type: "FLAT",
        position_uuid: "a35fd02c-db92-4973-8272-dd6eb65d3ae7",
        return_at_close: 1.0001291331010125,
        trade_pair: ["EURJPY", "EUR/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 172.11381818181817,
        close_ms: 1719547447981,
        current_return: 1.0002056824124106,
        initial_entry_price: 172.11,
        is_closed_position: true,
        miner_hotkey: "5D5GDbEa5Aedt9fXhWTzngDTANvnf8Q5poSFKYkU9hEZ7P8C",
        net_leverage: 0.0,
        open_ms: 1719547120961,
        orders: [
          {
            leverage: 0.5,
            order_type: "LONG",
            order_uuid: "df682b76-832e-428a-9bb5-ec4ce0647ca2",
            price: 172.11,
            price_sources: [],
            processed_ms: 1719547120872,
          },
          {
            leverage: 0.6,
            order_type: "LONG",
            order_uuid: "ce22a25c-e335-49d1-94d0-29b74f9c19f2",
            price: 172.117,
            price_sources: [],
            processed_ms: 1719547327414,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "981d9d53-73a0-45ee-9fff-a93f95fd664c",
            price: 172.146,
            price_sources: [],
            processed_ms: 1719547447981,
          },
        ],
        position_type: "FLAT",
        position_uuid: "db3f10d0-3f5f-431b-8471-8ecfb3562988",
        return_at_close: 1.0001286665748648,
        trade_pair: ["EURJPY", "EUR/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 172.12890909090905,
        close_ms: 1719547815143,
        current_return: 1.0001890814880223,
        initial_entry_price: 172.148,
        is_closed_position: true,
        miner_hotkey: "5D5GDbEa5Aedt9fXhWTzngDTANvnf8Q5poSFKYkU9hEZ7P8C",
        net_leverage: 0.0,
        open_ms: 1719547476898,
        orders: [
          {
            leverage: 0.5,
            order_type: "LONG",
            order_uuid: "596acf50-a8c8-4fdd-9589-1f3dbe014e18",
            price: 172.148,
            price_sources: [],
            processed_ms: 1719547476844,
          },
          {
            leverage: 0.6,
            order_type: "LONG",
            order_uuid: "99d30660-5d40-420d-b6d7-89a5aa28d929",
            price: 172.113,
            price_sources: [],
            processed_ms: 1719547605224,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "444532bf-873d-4ac6-a3a9-c732078e8f91",
            price: 172.1585,
            price_sources: [],
            processed_ms: 1719547815143,
          },
        ],
        position_type: "FLAT",
        position_uuid: "dffcb789-7ca7-4f82-9f56-48b7139683fd",
        return_at_close: 1.0001120669287478,
        trade_pair: ["EURJPY", "EUR/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 172.05645955165693,
        close_ms: 1719553043606,
        current_return: 0.9998556833065662,
        initial_entry_price: 172.16649999999998,
        is_closed_position: true,
        miner_hotkey: "5D5GDbEa5Aedt9fXhWTzngDTANvnf8Q5poSFKYkU9hEZ7P8C",
        net_leverage: 0.0,
        open_ms: 1719547845204,
        orders: [
          {
            leverage: 0.5,
            order_type: "LONG",
            order_uuid: "2c350e85-2d00-429a-99a7-51ccd1b6d801",
            price: 172.16649999999998,
            price_sources: [],
            processed_ms: 1719547844938,
          },
          {
            leverage: 0.4,
            order_type: "LONG",
            order_uuid: "80966290-517b-449e-88bd-62fe6fd24b59",
            price: 172.131,
            price_sources: [],
            processed_ms: 1719549463505,
          },
          {
            leverage: 0.81,
            order_type: "LONG",
            order_uuid: "946bb95a-cca7-47c3-ab81-2c77cfb50ee3",
            price: 172.074,
            price_sources: [],
            processed_ms: 1719552051376,
          },
          {
            leverage: 1.881,
            order_type: "LONG",
            order_uuid: "28860e6a-29ae-4cc4-8e1d-0c4f56f2b88c",
            price: 172.0505,
            price_sources: [],
            processed_ms: 1719552400430,
          },
          {
            leverage: 3.591,
            order_type: "LONG",
            order_uuid: "42618e0f-0ba4-495b-bfa6-28ed3442093d",
            price: 172.03199999999998,
            price_sources: [],
            processed_ms: 1719552649375,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "3e31e932-e273-40fb-8427-94b2352182cd",
            price: 172.053,
            price_sources: [],
            processed_ms: 1719553043606,
          },
        ],
        position_type: "FLAT",
        position_uuid: "4d58282b-aaf8-4c41-9420-0be68c3a736e",
        return_at_close: 0.9993530158603406,
        trade_pair: ["EURJPY", "EUR/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 172.04388888888886,
        close_ms: 1719553585701,
        current_return: 1.0000319665223332,
        initial_entry_price: 172.055,
        is_closed_position: true,
        miner_hotkey: "5D5GDbEa5Aedt9fXhWTzngDTANvnf8Q5poSFKYkU9hEZ7P8C",
        net_leverage: 0.0,
        open_ms: 1719553074720,
        orders: [
          {
            leverage: 0.5,
            order_type: "LONG",
            order_uuid: "ab4b3224-ff98-41d5-ad5a-f61653e0c80d",
            price: 172.055,
            price_sources: [],
            processed_ms: 1719553074018,
          },
          {
            leverage: 0.4,
            order_type: "LONG",
            order_uuid: "40329ec4-c401-40a4-a366-fbc1e602b25f",
            price: 172.03,
            price_sources: [],
            processed_ms: 1719553160840,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "010cb22b-2448-48dd-98b4-f75b03df05a2",
            price: 172.05,
            price_sources: [],
            processed_ms: 1719553585701,
          },
        ],
        position_type: "FLAT",
        position_uuid: "2d29437c-082f-4f7a-b4a6-6372d24b2a06",
        return_at_close: 0.9999689645084422,
        trade_pair: ["EURJPY", "EUR/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 172.05942857142855,
        close_ms: 1719554408114,
        current_return: 1.0001987667164554,
        initial_entry_price: 172.061,
        is_closed_position: true,
        miner_hotkey: "5D5GDbEa5Aedt9fXhWTzngDTANvnf8Q5poSFKYkU9hEZ7P8C",
        net_leverage: 0.0,
        open_ms: 1719553615002,
        orders: [
          {
            leverage: 0.5,
            order_type: "LONG",
            order_uuid: "3497f954-54ab-45af-bbd5-401ca5f65400",
            price: 172.061,
            price_sources: [],
            processed_ms: 1719553614804,
          },
          {
            leverage: 0.55,
            order_type: "LONG",
            order_uuid: "0dbbc429-c17b-4665-9dd8-d99bcf085a29",
            price: 172.058,
            price_sources: [],
            processed_ms: 1719553734949,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "be6992f2-d9e9-4c5c-81e4-1f390bb6c923",
            price: 172.092,
            price_sources: [],
            processed_ms: 1719554408114,
          },
        ],
        position_type: "FLAT",
        position_uuid: "149b4f71-1a12-4c24-bac8-2a49f4ed8a25",
        return_at_close: 1.0001252521071018,
        trade_pair: ["EURJPY", "EUR/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 172.08974999999998,
        close_ms: 1719556180523,
        current_return: 1.0001263800116211,
        initial_entry_price: 172.1,
        is_closed_position: true,
        miner_hotkey: "5D5GDbEa5Aedt9fXhWTzngDTANvnf8Q5poSFKYkU9hEZ7P8C",
        net_leverage: 0.0,
        open_ms: 1719554437894,
        orders: [
          {
            leverage: 0.5,
            order_type: "LONG",
            order_uuid: "3f243a15-ec36-45cd-ae26-afd9d170a087",
            price: 172.1,
            price_sources: [],
            processed_ms: 1719554437791,
          },
          {
            leverage: 0.5,
            order_type: "LONG",
            order_uuid: "8b8ca224-1dce-4a9d-bd88-3ea624c189b2",
            price: 172.0795,
            price_sources: [],
            processed_ms: 1719555458760,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "c24a1c78-342a-4097-a6d1-2c4936093f85",
            price: 172.11149999999998,
            price_sources: [],
            processed_ms: 1719556180523,
          },
        ],
        position_type: "FLAT",
        position_uuid: "efc82229-3ece-41c7-8232-145f6bc1b8e8",
        return_at_close: 1.0000563711650203,
        trade_pair: ["EURJPY", "EUR/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 172.0957222222222,
        close_ms: 1719556408052,
        current_return: 1.0002210930304853,
        initial_entry_price: 172.09949999999998,
        is_closed_position: true,
        miner_hotkey: "5D5GDbEa5Aedt9fXhWTzngDTANvnf8Q5poSFKYkU9hEZ7P8C",
        net_leverage: 0.0,
        open_ms: 1719556211108,
        orders: [
          {
            leverage: 0.5,
            order_type: "LONG",
            order_uuid: "8148d207-c517-4cea-923e-a6424e3c1208",
            price: 172.09949999999998,
            price_sources: [],
            processed_ms: 1719556211050,
          },
          {
            leverage: 0.4,
            order_type: "LONG",
            order_uuid: "a8e5876a-0feb-4a01-bbbe-edcf935a0ad3",
            price: 172.091,
            price_sources: [],
            processed_ms: 1719556241990,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "82224334-3fa4-4348-b362-78915e0b2395",
            price: 172.138,
            price_sources: [],
            processed_ms: 1719556408052,
          },
        ],
        position_type: "FLAT",
        position_uuid: "b4e97b92-de37-4460-858b-40ea2043932c",
        return_at_close: 1.0001580791016245,
        trade_pair: ["EURJPY", "EUR/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 172.1317391304348,
        close_ms: 1719556846393,
        current_return: 1.0001553904511842,
        initial_entry_price: 172.147,
        is_closed_position: true,
        miner_hotkey: "5D5GDbEa5Aedt9fXhWTzngDTANvnf8Q5poSFKYkU9hEZ7P8C",
        net_leverage: 0.0,
        open_ms: 1719556440492,
        orders: [
          {
            leverage: 0.5,
            order_type: "LONG",
            order_uuid: "de43bce1-39bd-4de2-998d-b30c08411b06",
            price: 172.147,
            price_sources: [],
            processed_ms: 1719556438061,
          },
          {
            leverage: 0.65,
            order_type: "LONG",
            order_uuid: "3d90b7de-0501-4e25-9da0-51a68b887cc7",
            price: 172.12,
            price_sources: [],
            processed_ms: 1719556500079,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "3977a96f-2434-496d-973b-8bb281472c28",
            price: 172.155,
            price_sources: [],
            processed_ms: 1719556846393,
          },
        ],
        position_type: "FLAT",
        position_uuid: "7dda1baa-83cc-4fa4-9e73-db1801e63f44",
        return_at_close: 1.0000748779422528,
        trade_pair: ["EURJPY", "EUR/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 172.13666666666668,
        close_ms: 1719557114357,
        current_return: 1.000209404315878,
        initial_entry_price: 172.155,
        is_closed_position: true,
        miner_hotkey: "5D5GDbEa5Aedt9fXhWTzngDTANvnf8Q5poSFKYkU9hEZ7P8C",
        net_leverage: 0.0,
        open_ms: 1719556876207,
        orders: [
          {
            leverage: 0.5,
            order_type: "LONG",
            order_uuid: "47231056-798e-46f1-aa3f-17e6cc5dbb42",
            price: 172.155,
            price_sources: [],
            processed_ms: 1719556876029,
          },
          {
            leverage: 0.55,
            order_type: "LONG",
            order_uuid: "2fd5d060-b1b6-46d4-aa73-c26b14fa8e3b",
            price: 172.12,
            price_sources: [],
            processed_ms: 1719556969820,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "b98d1ecb-ca10-4cb4-96e9-ad630c3b5190",
            price: 172.171,
            price_sources: [],
            processed_ms: 1719557114357,
          },
        ],
        position_type: "FLAT",
        position_uuid: "0cf352f2-422f-4ee5-a3e4-4563d89b4a46",
        return_at_close: 1.0001358889246608,
        trade_pair: ["EURJPY", "EUR/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 172.15825,
        close_ms: 1719558057330,
        current_return: 1.000196037999425,
        initial_entry_price: 172.1605,
        is_closed_position: true,
        miner_hotkey: "5D5GDbEa5Aedt9fXhWTzngDTANvnf8Q5poSFKYkU9hEZ7P8C",
        net_leverage: 0.0,
        open_ms: 1719557143810,
        orders: [
          {
            leverage: 0.5,
            order_type: "LONG",
            order_uuid: "da73e50d-3bfe-4ec9-be0f-cf61bff18f47",
            price: 172.1605,
            price_sources: [],
            processed_ms: 1719557143740,
          },
          {
            leverage: 0.5,
            order_type: "LONG",
            order_uuid: "eb1dfd86-39b9-4fbb-a916-153a9d9f0bd3",
            price: 172.156,
            price_sources: [],
            processed_ms: 1719557175895,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "c470f333-57e3-4a27-9c1c-703a56b999c7",
            price: 172.192,
            price_sources: [],
            processed_ms: 1719558057330,
          },
        ],
        position_type: "FLAT",
        position_uuid: "96aaafa6-acdd-4416-9129-25f4fad9d00c",
        return_at_close: 1.0001260242767651,
        trade_pair: ["EURJPY", "EUR/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 172.19376190476189,
        close_ms: 1719558191198,
        current_return: 1.0003276732152917,
        initial_entry_price: 172.199,
        is_closed_position: true,
        miner_hotkey: "5D5GDbEa5Aedt9fXhWTzngDTANvnf8Q5poSFKYkU9hEZ7P8C",
        net_leverage: 0.0,
        open_ms: 1719558086930,
        orders: [
          {
            leverage: 0.5,
            order_type: "LONG",
            order_uuid: "263b19a4-25df-404b-8f0a-78413709109d",
            price: 172.199,
            price_sources: [],
            processed_ms: 1719558086792,
          },
          {
            leverage: 0.55,
            order_type: "LONG",
            order_uuid: "c4a516fe-9032-4510-9440-8cd870ab502e",
            price: 172.189,
            price_sources: [],
            processed_ms: 1719558116847,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "d1b3d273-6897-4784-bf06-4b021ff0ac42",
            price: 172.2475,
            price_sources: [],
            processed_ms: 1719558191198,
          },
        ],
        position_type: "FLAT",
        position_uuid: "8651c41c-12ab-45c4-a08c-033418e90f0c",
        return_at_close: 1.0002541491313104,
        trade_pair: ["EURJPY", "EUR/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 172.25438095238093,
        close_ms: 1719558431285,
        current_return: 1.0001591996191983,
        initial_entry_price: 172.268,
        is_closed_position: true,
        miner_hotkey: "5D5GDbEa5Aedt9fXhWTzngDTANvnf8Q5poSFKYkU9hEZ7P8C",
        net_leverage: 0.0,
        open_ms: 1719558220979,
        orders: [
          {
            leverage: 0.5,
            order_type: "LONG",
            order_uuid: "a93ea447-1348-43ea-904d-426d141a0cf4",
            price: 172.268,
            price_sources: [],
            processed_ms: 1719558220824,
          },
          {
            leverage: 0.55,
            order_type: "LONG",
            order_uuid: "9482fa12-833b-469e-b0bc-beb02c6505cd",
            price: 172.242,
            price_sources: [],
            processed_ms: 1719558298830,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "2a5fca04-c5c7-4410-a0b7-a33e241c00cf",
            price: 172.28050000000002,
            price_sources: [],
            processed_ms: 1719558431285,
          },
        ],
        position_type: "FLAT",
        position_uuid: "03996883-bdf2-431f-bff0-d2fc79c033a0",
        return_at_close: 1.0000856879180264,
        trade_pair: ["EURJPY", "EUR/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 172.09657354572366,
        close_ms: 1719559401330,
        current_return: 1.0016221877793645,
        initial_entry_price: 172.26600000000002,
        is_closed_position: true,
        miner_hotkey: "5D5GDbEa5Aedt9fXhWTzngDTANvnf8Q5poSFKYkU9hEZ7P8C",
        net_leverage: 0.0,
        open_ms: 1719558460832,
        orders: [
          {
            leverage: 0.5,
            order_type: "LONG",
            order_uuid: "5d3a39bb-4337-4ca3-b33e-094bf1635c2e",
            price: 172.26600000000002,
            price_sources: [],
            processed_ms: 1719558460748,
          },
          {
            leverage: 0.6,
            order_type: "LONG",
            order_uuid: "664ba332-0715-4c5e-8149-e920f6eca4ff",
            price: 172.2375,
            price_sources: [],
            processed_ms: 1719558611000,
          },
          {
            leverage: 0.8800000000000001,
            order_type: "LONG",
            order_uuid: "a0c90f57-b74d-499c-bbc0-3d1d97201881",
            price: 172.192,
            price_sources: [],
            processed_ms: 1719558894394,
          },
          {
            leverage: 1.5840000000000003,
            order_type: "LONG",
            order_uuid: "51690e26-dedd-4a0e-b990-68cc38f1408e",
            price: 172.111,
            price_sources: [],
            processed_ms: 1719558981125,
          },
          {
            leverage: 2.8512000000000004,
            order_type: "LONG",
            order_uuid: "2e9480d8-cbc4-45bf-8ed2-fdaea2dc9b98",
            price: 172.09,
            price_sources: [],
            processed_ms: 1719559010820,
          },
          {
            leverage: 5.773680000000001,
            order_type: "LONG",
            order_uuid: "faef636b-d256-4d9c-9966-fc0cd697d7f7",
            price: 172.052,
            price_sources: [],
            processed_ms: 1719559050952,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "2b7469cd-0e8b-4389-8e96-ff080a31995d",
            price: 172.11950000000002,
            price_sources: [],
            processed_ms: 1719559401330,
          },
        ],
        position_type: "FLAT",
        position_uuid: "bae2e65e-12bb-41fa-876f-e6f584c6a6a3",
        return_at_close: 1.0007675820937119,
        trade_pair: ["EURJPY", "EUR/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 172.1295,
        close_ms: 1719559885827,
        current_return: 1.0002396451508893,
        initial_entry_price: 172.1295,
        is_closed_position: true,
        miner_hotkey: "5D5GDbEa5Aedt9fXhWTzngDTANvnf8Q5poSFKYkU9hEZ7P8C",
        net_leverage: 0.0,
        open_ms: 1719559430924,
        orders: [
          {
            leverage: 0.5,
            order_type: "LONG",
            order_uuid: "8937b1ae-655d-4164-b388-a11466afa096",
            price: 172.1295,
            price_sources: [],
            processed_ms: 1719559430819,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "bc566dea-ee0a-4319-baef-e2cad4dfe188",
            price: 172.212,
            price_sources: [],
            processed_ms: 1719559885827,
          },
        ],
        position_type: "FLAT",
        position_uuid: "570c5d17-7cac-4735-8040-b70a79abef4e",
        return_at_close: 1.000204636763309,
        trade_pair: ["EURJPY", "EUR/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 172.1806052631579,
        close_ms: 1719560554061,
        current_return: 1.0002249898391684,
        initial_entry_price: 172.23,
        is_closed_position: true,
        miner_hotkey: "5D5GDbEa5Aedt9fXhWTzngDTANvnf8Q5poSFKYkU9hEZ7P8C",
        net_leverage: 0.0,
        open_ms: 1719559914761,
        orders: [
          {
            leverage: 0.5,
            order_type: "LONG",
            order_uuid: "e97a2e9b-c050-47d0-8aa6-c6e48b50f523",
            price: 172.23,
            price_sources: [],
            processed_ms: 1719559914720,
          },
          {
            leverage: 0.45,
            order_type: "LONG",
            order_uuid: "8324e310-c267-4319-a7a0-9e50a22f52dd",
            price: 172.165,
            price_sources: [],
            processed_ms: 1719560291647,
          },
          {
            leverage: 0.95,
            order_type: "LONG",
            order_uuid: "1fef1411-1dc1-4fad-9a1c-cf422cf2b873",
            price: 172.162,
            price_sources: [],
            processed_ms: 1719560513152,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "60801f9c-098c-41e3-acc6-13387a6607b2",
            price: 172.201,
            price_sources: [],
            processed_ms: 1719560554061,
          },
        ],
        position_type: "FLAT",
        position_uuid: "d6e15fe8-4b1f-4953-bf95-59022b320a99",
        return_at_close: 1.0000919599155198,
        trade_pair: ["EURJPY", "EUR/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 172.1705909090909,
        close_ms: 1719560712860,
        current_return: 1.0000984340564414,
        initial_entry_price: 172.19650000000001,
        is_closed_position: true,
        miner_hotkey: "5D5GDbEa5Aedt9fXhWTzngDTANvnf8Q5poSFKYkU9hEZ7P8C",
        net_leverage: 0.0,
        open_ms: 1719560583125,
        orders: [
          {
            leverage: 0.5,
            order_type: "LONG",
            order_uuid: "4b58e2aa-08d1-479f-9d70-11f79a493829",
            price: 172.19650000000001,
            price_sources: [],
            processed_ms: 1719560582826,
          },
          {
            leverage: 0.6,
            order_type: "LONG",
            order_uuid: "7f3ea1b6-c618-4c97-82b7-2a6404acfaa0",
            price: 172.149,
            price_sources: [],
            processed_ms: 1719560648847,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "4201904c-661e-4cd7-9db6-a6095551a4ac",
            price: 172.186,
            price_sources: [],
            processed_ms: 1719560712860,
          },
        ],
        position_type: "FLAT",
        position_uuid: "2f7988b2-9243-463b-8c5b-bfbb5bb840ff",
        return_at_close: 1.000021426477019,
        trade_pair: ["EURJPY", "EUR/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 172.1496099773243,
        close_ms: 1719560999304,
        current_return: 1.000197101129403,
        initial_entry_price: 172.1705,
        is_closed_position: true,
        miner_hotkey: "5D5GDbEa5Aedt9fXhWTzngDTANvnf8Q5poSFKYkU9hEZ7P8C",
        net_leverage: 0.0,
        open_ms: 1719560744779,
        orders: [
          {
            leverage: 0.5,
            order_type: "LONG",
            order_uuid: "891c94ac-add0-489b-8fb2-d3acf7bc0893",
            price: 172.1705,
            price_sources: [],
            processed_ms: 1719560744664,
          },
          {
            leverage: 0.55,
            order_type: "LONG",
            order_uuid: "cf7a3317-2e5a-4d40-ac05-ea4e2cd4cae2",
            price: 172.155,
            price_sources: [],
            processed_ms: 1719560772976,
          },
          {
            leverage: 1.1550000000000002,
            order_type: "LONG",
            order_uuid: "6791f176-095f-49c9-a33c-3e16e3d58a11",
            price: 172.138,
            price_sources: [],
            processed_ms: 1719560828884,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "ee190d73-c7c4-461b-8ffd-b5e2093dc24c",
            price: 172.165,
            price_sources: [],
            processed_ms: 1719560999304,
          },
        ],
        position_type: "FLAT",
        position_uuid: "b2cc3a32-eb72-480e-a0fe-c0623f8eeb10",
        return_at_close: 1.0000427207068439,
        trade_pair: ["EURJPY", "EUR/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 172.13048872180454,
        close_ms: 1719561262987,
        current_return: 1.0003594702909906,
        initial_entry_price: 172.17,
        is_closed_position: true,
        miner_hotkey: "5D5GDbEa5Aedt9fXhWTzngDTANvnf8Q5poSFKYkU9hEZ7P8C",
        net_leverage: 0.0,
        open_ms: 1719561028938,
        orders: [
          {
            leverage: 0.5,
            order_type: "LONG",
            order_uuid: "a4ddd979-f5e7-4bd1-abfc-9e0ce6184b88",
            price: 172.17,
            price_sources: [],
            processed_ms: 1719561028763,
          },
          {
            leverage: 0.5,
            order_type: "LONG",
            order_uuid: "05c02ff8-532c-4f86-bfa8-b91980839928",
            price: 172.1585,
            price_sources: [],
            processed_ms: 1719561058922,
          },
          {
            leverage: 1.1,
            order_type: "LONG",
            order_uuid: "6a0b47ff-7f85-4721-8c00-e093c30534f7",
            price: 172.135,
            price_sources: [],
            processed_ms: 1719561091936,
          },
          {
            leverage: 1.8900000000000001,
            order_type: "LONG",
            order_uuid: "d1800803-c451-4baa-8a06-a57c3a26571f",
            price: 172.11,
            price_sources: [],
            processed_ms: 1719561231127,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "ec0701f4-c68a-41de-8eb8-520d1e04ae20",
            price: 172.146,
            price_sources: [],
            processed_ms: 1719561262987,
          },
        ],
        position_type: "FLAT",
        position_uuid: "f609387c-d1f8-481a-ab13-118c0fb27f92",
        return_at_close: 1.0000800698909382,
        trade_pair: ["EURJPY", "EUR/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 172.154,
        close_ms: 1719561503344,
        current_return: 1.0001161750525691,
        initial_entry_price: 172.154,
        is_closed_position: true,
        miner_hotkey: "5D5GDbEa5Aedt9fXhWTzngDTANvnf8Q5poSFKYkU9hEZ7P8C",
        net_leverage: 0.0,
        open_ms: 1719561293323,
        orders: [
          {
            leverage: 0.5,
            order_type: "LONG",
            order_uuid: "8022b2ce-295f-4ef6-85bc-925ec24d9630",
            price: 172.154,
            price_sources: [],
            processed_ms: 1719561293194,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "e625d800-3ad5-40e4-bb18-92958915333b",
            price: 172.194,
            price_sources: [],
            processed_ms: 1719561503344,
          },
        ],
        position_type: "FLAT",
        position_uuid: "2e0ddd4a-5a70-489f-ac64-f63c054644d7",
        return_at_close: 1.0000811709864423,
        trade_pair: ["EURJPY", "EUR/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 172.11406578947367,
        close_ms: 1719561869045,
        current_return: 1.0000765320941043,
        initial_entry_price: 172.15,
        is_closed_position: true,
        miner_hotkey: "5D5GDbEa5Aedt9fXhWTzngDTANvnf8Q5poSFKYkU9hEZ7P8C",
        net_leverage: 0.0,
        open_ms: 1719561533266,
        orders: [
          {
            leverage: 0.5,
            order_type: "LONG",
            order_uuid: "f108ad4d-3af6-4ca6-9a3e-6a9f78dfec80",
            price: 172.15,
            price_sources: [],
            processed_ms: 1719561533012,
          },
          {
            leverage: 0.45,
            order_type: "LONG",
            order_uuid: "812fe005-9d6d-4aba-a16b-17b2ef92fc27",
            price: 172.1165,
            price_sources: [],
            processed_ms: 1719561630154,
          },
          {
            leverage: 0.95,
            order_type: "LONG",
            order_uuid: "7f741264-0a2f-4370-9332-001807a6c983",
            price: 172.094,
            price_sources: [],
            processed_ms: 1719561755014,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "9e907821-eec7-4ad0-bd6a-45ba2673ac5e",
            price: 172.121,
            price_sources: [],
            processed_ms: 1719561869045,
          },
        ],
        position_type: "FLAT",
        position_uuid: "c49bc65a-8d4e-4b2f-ab2f-fcba3e82693f",
        return_at_close: 0.9999435219153358,
        trade_pair: ["EURJPY", "EUR/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 172.083,
        close_ms: 1719562406833,
        current_return: 1.0001946678443587,
        initial_entry_price: 172.088,
        is_closed_position: true,
        miner_hotkey: "5D5GDbEa5Aedt9fXhWTzngDTANvnf8Q5poSFKYkU9hEZ7P8C",
        net_leverage: 0.0,
        open_ms: 1719561899217,
        orders: [
          {
            leverage: 0.5,
            order_type: "LONG",
            order_uuid: "4d46f4e1-2d12-495e-afa4-622596ad9054",
            price: 172.088,
            price_sources: [],
            processed_ms: 1719561899030,
          },
          {
            leverage: 0.5,
            order_type: "LONG",
            order_uuid: "373744d8-0c79-40cc-b991-467dfd25d278",
            price: 172.078,
            price_sources: [],
            processed_ms: 1719562313552,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "1ceefd36-16c0-4a4d-8f95-ad35dfaec8a3",
            price: 172.1165,
            price_sources: [],
            processed_ms: 1719562406833,
          },
        ],
        position_type: "FLAT",
        position_uuid: "203843d2-5c0e-4822-916b-1b4cd091dbac",
        return_at_close: 1.0001246542176097,
        trade_pair: ["EURJPY", "EUR/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 172.08023391812867,
        close_ms: 1719563071359,
        current_return: 1.0000076119199177,
        initial_entry_price: 172.0985,
        is_closed_position: true,
        miner_hotkey: "5D5GDbEa5Aedt9fXhWTzngDTANvnf8Q5poSFKYkU9hEZ7P8C",
        net_leverage: 0.0,
        open_ms: 1719562437068,
        orders: [
          {
            leverage: 0.5,
            order_type: "LONG",
            order_uuid: "c9ac1fa1-90c2-4de5-a375-10efc6772414",
            price: 172.0985,
            price_sources: [],
            processed_ms: 1719562437008,
          },
          {
            leverage: 0.45,
            order_type: "LONG",
            order_uuid: "e73f2179-286e-4b0d-9bf3-f5252f0d3031",
            price: 172.111,
            price_sources: [],
            processed_ms: 1719562509062,
          },
          {
            leverage: 0.76,
            order_type: "LONG",
            order_uuid: "77b55965-634c-44ae-bb86-85178baed835",
            price: 172.05,
            price_sources: [],
            processed_ms: 1719562829247,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "f76448dd-c6cc-4edf-8083-910d3432e666",
            price: 172.081,
            price_sources: [],
            processed_ms: 1719563071359,
          },
        ],
        position_type: "FLAT",
        position_uuid: "522ab91d-fe69-43ae-8b1f-370a67ceab90",
        return_at_close: 0.999887911008771,
        trade_pair: ["EURJPY", "EUR/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 172.07,
        close_ms: 1719563471454,
        current_return: 1.0001772534433662,
        initial_entry_price: 172.07,
        is_closed_position: true,
        miner_hotkey: "5D5GDbEa5Aedt9fXhWTzngDTANvnf8Q5poSFKYkU9hEZ7P8C",
        net_leverage: 0.0,
        open_ms: 1719563100965,
        orders: [
          {
            leverage: 0.5,
            order_type: "LONG",
            order_uuid: "0583d5e4-f378-40dc-b18f-53cf620b2934",
            price: 172.07,
            price_sources: [],
            processed_ms: 1719563100891,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "ae248613-0625-4530-b52d-6c3d09e5de21",
            price: 172.131,
            price_sources: [],
            processed_ms: 1719563471454,
          },
        ],
        position_type: "FLAT",
        position_uuid: "a0a83b81-0740-4a1a-b811-f3cb33c1b138",
        return_at_close: 1.0001422472394956,
        trade_pair: ["EURJPY", "EUR/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 172.11577777777777,
        close_ms: 1719564125565,
        current_return: 1.0000325343062642,
        initial_entry_price: 172.126,
        is_closed_position: true,
        miner_hotkey: "5D5GDbEa5Aedt9fXhWTzngDTANvnf8Q5poSFKYkU9hEZ7P8C",
        net_leverage: 0.0,
        open_ms: 1719563502197,
        orders: [
          {
            leverage: 0.5,
            order_type: "LONG",
            order_uuid: "0a6998e3-9dd0-4578-9dce-24b6b81c12aa",
            price: 172.126,
            price_sources: [],
            processed_ms: 1719563502084,
          },
          {
            leverage: 0.4,
            order_type: "LONG",
            order_uuid: "459839c1-12f0-42dc-b132-5909e4632fa7",
            price: 172.103,
            price_sources: [],
            processed_ms: 1719563619255,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "8df2102a-1f21-447a-bd8a-48af9eebb81c",
            price: 172.122,
            price_sources: [],
            processed_ms: 1719564125565,
          },
        ],
        position_type: "FLAT",
        position_uuid: "0232c547-baf4-466f-b57a-b6e182178ebe",
        return_at_close: 0.9999695322566028,
        trade_pair: ["EURJPY", "EUR/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 172.06722846889954,
        close_ms: 1719564473146,
        current_return: 0.9996909372376909,
        initial_entry_price: 172.1495,
        is_closed_position: true,
        miner_hotkey: "5D5GDbEa5Aedt9fXhWTzngDTANvnf8Q5poSFKYkU9hEZ7P8C",
        net_leverage: 0.0,
        open_ms: 1719564155164,
        orders: [
          {
            leverage: 0.5,
            order_type: "LONG",
            order_uuid: "cd1b23a5-bbe3-40ba-9301-438c1c591386",
            price: 172.1495,
            price_sources: [],
            processed_ms: 1719564155060,
          },
          {
            leverage: 0.45,
            order_type: "LONG",
            order_uuid: "68f01af3-dd01-4720-a388-e66c160ce0e4",
            price: 172.0885,
            price_sources: [],
            processed_ms: 1719564214152,
          },
          {
            leverage: 0.95,
            order_type: "LONG",
            order_uuid: "c8b2f6c4-4e2d-4c45-ad3b-c7f523a40d9f",
            price: 172.072,
            price_sources: [],
            processed_ms: 1719564290968,
          },
          {
            leverage: 2.28,
            order_type: "LONG",
            order_uuid: "7187d9f3-f747-40fd-a908-452196418f8e",
            price: 172.043,
            price_sources: [],
            processed_ms: 1719564443264,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "1a26e445-05de-4d5d-b2ea-c75cfa75811b",
            price: 172.0545,
            price_sources: [],
            processed_ms: 1719564473146,
          },
        ],
        position_type: "FLAT",
        position_uuid: "480667b7-2907-4d20-8067-31cdcaf7d93d",
        return_at_close: 0.9993984276694552,
        trade_pair: ["EURJPY", "EUR/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 172.05399999999997,
        close_ms: 1719565077630,
        current_return: 1.0002891534053264,
        initial_entry_price: 172.05399999999997,
        is_closed_position: true,
        miner_hotkey: "5D5GDbEa5Aedt9fXhWTzngDTANvnf8Q5poSFKYkU9hEZ7P8C",
        net_leverage: 0.0,
        open_ms: 1719564504554,
        orders: [
          {
            leverage: 0.5,
            order_type: "LONG",
            order_uuid: "9f309e28-6061-4e00-af7e-2f1bf8b82fc4",
            price: 172.05399999999997,
            price_sources: [],
            processed_ms: 1719564504439,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "e788026b-7f9d-4235-bbf8-7293d49d1d40",
            price: 172.1535,
            price_sources: [],
            processed_ms: 1719565077630,
          },
        ],
        position_type: "FLAT",
        position_uuid: "d048907b-da94-464b-93a1-363252d2b92e",
        return_at_close: 1.0002541432849572,
        trade_pair: ["EURJPY", "EUR/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 171.9392117870313,
        close_ms: 1719565985013,
        current_return: 1.0019955854475917,
        initial_entry_price: 172.164,
        is_closed_position: true,
        miner_hotkey: "5D5GDbEa5Aedt9fXhWTzngDTANvnf8Q5poSFKYkU9hEZ7P8C",
        net_leverage: 0.0,
        open_ms: 1719565107112,
        orders: [
          {
            leverage: 0.5,
            order_type: "LONG",
            order_uuid: "b5df3093-8cd4-4809-bb11-0e9d536d4c52",
            price: 172.164,
            price_sources: [],
            processed_ms: 1719565106975,
          },
          {
            leverage: 0.45,
            order_type: "LONG",
            order_uuid: "bcd14573-361c-4104-9930-136df0c76d5d",
            price: 172.136,
            price_sources: [],
            processed_ms: 1719565259169,
          },
          {
            leverage: 1.2349999999999999,
            order_type: "LONG",
            order_uuid: "cfd25a5c-1abe-4a00-8904-b78911e57b7c",
            price: 172.086,
            price_sources: [],
            processed_ms: 1719565567286,
          },
          {
            leverage: 1.9664999999999997,
            order_type: "LONG",
            order_uuid: "fa0a0e57-a86e-4fdb-b606-0c48298e3b21",
            price: 172.04,
            price_sources: [],
            processed_ms: 1719565645887,
          },
          {
            leverage: 5.3969499999999995,
            order_type: "LONG",
            order_uuid: "8288c06d-aad8-4eb1-867a-b7fbbdaa99e3",
            price: 172.0,
            price_sources: [],
            processed_ms: 1719565746928,
          },
          {
            leverage: 11.458139999999998,
            order_type: "LONG",
            order_uuid: "34fd2c0e-ade8-4fba-b7e6-8b7e850a451c",
            price: 171.939,
            price_sources: [],
            processed_ms: 1719565806943,
          },
          {
            leverage: 23.107248999999996,
            order_type: "LONG",
            order_uuid: "ea61edf8-6f53-4629-b608-dbe91eec45ed",
            price: 171.9,
            price_sources: [],
            processed_ms: 1719565836846,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "534ae9c6-6e01-4262-a65e-c5869dc26a19",
            price: 171.947,
            price_sources: [],
            processed_ms: 1719565985013,
          },
        ],
        position_type: "FLAT",
        position_uuid: "2b15ceb4-948e-449f-9f72-e642eddc6d29",
        return_at_close: 0.9989014544121315,
        trade_pair: ["EURJPY", "EUR/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 171.959,
        close_ms: 1719566291275,
        current_return: 1.0002195290737907,
        initial_entry_price: 171.959,
        is_closed_position: true,
        miner_hotkey: "5D5GDbEa5Aedt9fXhWTzngDTANvnf8Q5poSFKYkU9hEZ7P8C",
        net_leverage: 0.0,
        open_ms: 1719566015150,
        orders: [
          {
            leverage: 0.5,
            order_type: "LONG",
            order_uuid: "cd038cae-d4e4-43ea-b77c-28f42027814d",
            price: 171.959,
            price_sources: [],
            processed_ms: 1719566014907,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "4b7941a9-28d5-43a6-87f7-6b58cb8548dc",
            price: 172.03449999999998,
            price_sources: [],
            processed_ms: 1719566291275,
          },
        ],
        position_type: "FLAT",
        position_uuid: "e5f00beb-86fe-4c3d-a9e6-5e7f7da29686",
        return_at_close: 1.0001845213902731,
        trade_pair: ["EURJPY", "EUR/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 171.96799521531102,
        close_ms: 1719566558198,
        current_return: 0.9999308532593842,
        initial_entry_price: 172.011,
        is_closed_position: true,
        miner_hotkey: "5D5GDbEa5Aedt9fXhWTzngDTANvnf8Q5poSFKYkU9hEZ7P8C",
        net_leverage: 0.0,
        open_ms: 1719566320912,
        orders: [
          {
            leverage: 0.5,
            order_type: "LONG",
            order_uuid: "fc36f3bf-3fae-4076-8280-f4b2ea876971",
            price: 172.011,
            price_sources: [],
            processed_ms: 1719566320840,
          },
          {
            leverage: 0.6,
            order_type: "LONG",
            order_uuid: "e5ab7aa8-155c-4f54-90be-950fac63be2c",
            price: 171.9785,
            price_sources: [],
            processed_ms: 1719566363016,
          },
          {
            leverage: 0.9900000000000001,
            order_type: "LONG",
            order_uuid: "5b247ee2-a1e6-455a-ba45-17aee8a77ffd",
            price: 171.976,
            price_sources: [],
            processed_ms: 1719566405123,
          },
          {
            leverage: 1.8810000000000002,
            order_type: "LONG",
            order_uuid: "f37f789e-4a1c-4e76-9993-36911e055066",
            price: 171.949,
            price_sources: [],
            processed_ms: 1719566512960,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "d68d836e-2d98-451e-aaad-0c254b690e9b",
            price: 171.965,
            price_sources: [],
            processed_ms: 1719566558198,
          },
        ],
        position_type: "FLAT",
        position_uuid: "c015dbd9-0fe0-46d3-9b4c-e752631b4fec",
        return_at_close: 0.9996529024801037,
        trade_pair: ["EURJPY", "EUR/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 171.92248803827752,
        close_ms: 1719566747116,
        current_return: 1.0000973801285218,
        initial_entry_price: 171.95499999999998,
        is_closed_position: true,
        miner_hotkey: "5D5GDbEa5Aedt9fXhWTzngDTANvnf8Q5poSFKYkU9hEZ7P8C",
        net_leverage: 0.0,
        open_ms: 1719566587598,
        orders: [
          {
            leverage: 0.5,
            order_type: "LONG",
            order_uuid: "0528d7fc-42c8-4c0b-8224-4bdae6191e5f",
            price: 171.95499999999998,
            price_sources: [],
            processed_ms: 1719566587460,
          },
          {
            leverage: 0.45,
            order_type: "LONG",
            order_uuid: "7eb620c8-b7d0-4dd7-9ce2-685a30519c76",
            price: 171.937,
            price_sources: [],
            processed_ms: 1719566620926,
          },
          {
            leverage: 1.14,
            order_type: "LONG",
            order_uuid: "61339e17-9e41-4b6a-beea-4740c7937369",
            price: 171.9025,
            price_sources: [],
            processed_ms: 1719566675791,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "b77459b7-334b-450c-aa88-bc20717d98b6",
            price: 171.9305,
            price_sources: [],
            processed_ms: 1719566747116,
          },
        ],
        position_type: "FLAT",
        position_uuid: "a11132be-19c0-43c7-b23a-9933b8f659dc",
        return_at_close: 0.9999510658818089,
        trade_pair: ["EURJPY", "EUR/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 171.9057826086957,
        close_ms: 1719566939094,
        current_return: 1.000315859276839,
        initial_entry_price: 171.912,
        is_closed_position: true,
        miner_hotkey: "5D5GDbEa5Aedt9fXhWTzngDTANvnf8Q5poSFKYkU9hEZ7P8C",
        net_leverage: 0.0,
        open_ms: 1719566779295,
        orders: [
          {
            leverage: 0.5,
            order_type: "LONG",
            order_uuid: "3865b771-4819-4f71-85f9-cb8dc1a4a441",
            price: 171.912,
            price_sources: [],
            processed_ms: 1719566779148,
          },
          {
            leverage: 0.65,
            order_type: "LONG",
            order_uuid: "3a75376e-e8eb-4572-b5b6-6881a83f654f",
            price: 171.901,
            price_sources: [],
            processed_ms: 1719566821212,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "26af401d-0742-4584-991d-fba1c65a4a80",
            price: 171.953,
            price_sources: [],
            processed_ms: 1719566939094,
          },
        ],
        position_type: "FLAT",
        position_uuid: "f326c4e9-7e13-4744-914c-a2d806899ffe",
        return_at_close: 1.0002353338501673,
        trade_pair: ["EURJPY", "EUR/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 171.92655555555555,
        close_ms: 1719567087299,
        current_return: 1.0001384179640929,
        initial_entry_price: 171.943,
        is_closed_position: true,
        miner_hotkey: "5D5GDbEa5Aedt9fXhWTzngDTANvnf8Q5poSFKYkU9hEZ7P8C",
        net_leverage: 0.0,
        open_ms: 1719566970507,
        orders: [
          {
            leverage: 0.5,
            order_type: "LONG",
            order_uuid: "3969db62-b9ed-4db2-a86b-443937518824",
            price: 171.943,
            price_sources: [],
            processed_ms: 1719566969160,
          },
          {
            leverage: 0.4,
            order_type: "LONG",
            order_uuid: "89f39896-56c0-4b42-a473-a5cbbfb3a05d",
            price: 171.906,
            price_sources: [],
            processed_ms: 1719567001060,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "9642d8f8-59ef-459d-9149-11a8452aa043",
            price: 171.953,
            price_sources: [],
            processed_ms: 1719567087299,
          },
        ],
        position_type: "FLAT",
        position_uuid: "570f59ba-3760-43b9-8ae9-151d1ff1c44e",
        return_at_close: 1.0000754092437611,
        trade_pair: ["EURJPY", "EUR/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 171.89438842975207,
        close_ms: 1719567417102,
        current_return: 1.000050834050671,
        initial_entry_price: 171.932,
        is_closed_position: true,
        miner_hotkey: "5D5GDbEa5Aedt9fXhWTzngDTANvnf8Q5poSFKYkU9hEZ7P8C",
        net_leverage: 0.0,
        open_ms: 1719567116887,
        orders: [
          {
            leverage: 0.5,
            order_type: "LONG",
            order_uuid: "d6b5634a-71f4-4618-bfb5-b3e6f8162458",
            price: 171.932,
            price_sources: [],
            processed_ms: 1719567116820,
          },
          {
            leverage: 0.6,
            order_type: "LONG",
            order_uuid: "f72dff77-a9b6-4648-88b0-c7af6033c3eb",
            price: 171.91449999999998,
            price_sources: [],
            processed_ms: 1719567282246,
          },
          {
            leverage: 1.32,
            order_type: "LONG",
            order_uuid: "1264ada8-7296-442c-951d-ed265f1e6f3e",
            price: 171.871,
            price_sources: [],
            processed_ms: 1719567359247,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "c3fee374-df38-4cdb-8ac0-cf2cb44be44c",
            price: 171.898,
            price_sources: [],
            processed_ms: 1719567417102,
          },
        ],
        position_type: "FLAT",
        position_uuid: "8281801f-e70b-47d9-a915-5498d65859f8",
        return_at_close: 0.9998814254393829,
        trade_pair: ["EURJPY", "EUR/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 171.919,
        close_ms: 1719567555128,
        current_return: 1.0002239426706763,
        initial_entry_price: 171.919,
        is_closed_position: true,
        miner_hotkey: "5D5GDbEa5Aedt9fXhWTzngDTANvnf8Q5poSFKYkU9hEZ7P8C",
        net_leverage: 0.0,
        open_ms: 1719567447008,
        orders: [
          {
            leverage: 0.5,
            order_type: "LONG",
            order_uuid: "15e4627c-c2e3-4c69-b559-5ad294f53af1",
            price: 171.919,
            price_sources: [],
            processed_ms: 1719567446944,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "32f8b770-3a5d-4964-b6fd-7d4e9ddcbda7",
            price: 171.996,
            price_sources: [],
            processed_ms: 1719567555128,
          },
        ],
        position_type: "FLAT",
        position_uuid: "2c5365d9-e07e-405f-8f4f-6861772d52ab",
        return_at_close: 1.000188934832683,
        trade_pair: ["EURJPY", "EUR/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 171.9925,
        close_ms: 1719568358488,
        current_return: 1.000145355175371,
        initial_entry_price: 171.9925,
        is_closed_position: true,
        miner_hotkey: "5D5GDbEa5Aedt9fXhWTzngDTANvnf8Q5poSFKYkU9hEZ7P8C",
        net_leverage: 0.0,
        open_ms: 1719567585321,
        orders: [
          {
            leverage: 0.5,
            order_type: "LONG",
            order_uuid: "72436090-9f3a-4004-ab7f-e3498633233b",
            price: 171.9925,
            price_sources: [],
            processed_ms: 1719567585093,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "86fe6145-92d2-41a2-9da6-05f3bd38b5bc",
            price: 172.04250000000002,
            price_sources: [],
            processed_ms: 1719568358488,
          },
        ],
        position_type: "FLAT",
        position_uuid: "8395f30f-b2c4-431b-b89e-182c91a8e735",
        return_at_close: 1.0001103500879398,
        trade_pair: ["EURJPY", "EUR/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 172.0376956521739,
        close_ms: 1719568556251,
        current_return: 1.0002493475695877,
        initial_entry_price: 172.04899999999998,
        is_closed_position: true,
        miner_hotkey: "5D5GDbEa5Aedt9fXhWTzngDTANvnf8Q5poSFKYkU9hEZ7P8C",
        net_leverage: 0.0,
        open_ms: 1719568387844,
        orders: [
          {
            leverage: 0.5,
            order_type: "LONG",
            order_uuid: "dc21e9cd-e65e-46fa-919a-bd911bafd13c",
            price: 172.04899999999998,
            price_sources: [],
            processed_ms: 1719568387788,
          },
          {
            leverage: 0.65,
            order_type: "LONG",
            order_uuid: "b0d4206b-08c7-4689-a41f-1b43d9cfc881",
            price: 172.029,
            price_sources: [],
            processed_ms: 1719568446147,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "e7d1e3e3-a946-4543-9ab5-932e908be2b0",
            price: 172.075,
            price_sources: [],
            processed_ms: 1719568556251,
          },
        ],
        position_type: "FLAT",
        position_uuid: "1d94b5da-b512-48d0-abb9-e7e6d4e72aee",
        return_at_close: 1.0001688274971083,
        trade_pair: ["EURJPY", "EUR/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 172.076,
        close_ms: 1719569096947,
        current_return: 1.0000929821706688,
        initial_entry_price: 172.076,
        is_closed_position: true,
        miner_hotkey: "5D5GDbEa5Aedt9fXhWTzngDTANvnf8Q5poSFKYkU9hEZ7P8C",
        net_leverage: 0.0,
        open_ms: 1719568586758,
        orders: [
          {
            leverage: 0.5,
            order_type: "LONG",
            order_uuid: "390eecd8-4d80-4fd5-a3ef-9c682e8cff0b",
            price: 172.076,
            price_sources: [],
            processed_ms: 1719568586465,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "a420d1c0-18f7-4fc4-8e5e-294f9995cbfc",
            price: 172.108,
            price_sources: [],
            processed_ms: 1719569096947,
          },
        ],
        position_type: "FLAT",
        position_uuid: "dd6b6cea-99f5-42f3-b196-26060ea1870d",
        return_at_close: 1.000057978916293,
        trade_pair: ["EURJPY", "EUR/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 172.05154037267084,
        close_ms: 1719570038535,
        current_return: 1.0005740942997225,
        initial_entry_price: 172.132,
        is_closed_position: true,
        miner_hotkey: "5D5GDbEa5Aedt9fXhWTzngDTANvnf8Q5poSFKYkU9hEZ7P8C",
        net_leverage: 0.0,
        open_ms: 1719569126672,
        orders: [
          {
            leverage: 0.5,
            order_type: "LONG",
            order_uuid: "8cd22573-b0c8-4b12-b787-a0f819f4f383",
            price: 172.132,
            price_sources: [],
            processed_ms: 1719569126228,
          },
          {
            leverage: 0.55,
            order_type: "LONG",
            order_uuid: "d328ad32-5201-4858-a0f2-7e50696caa75",
            price: 172.123,
            price_sources: [],
            processed_ms: 1719569177943,
          },
          {
            leverage: 1.05,
            order_type: "LONG",
            order_uuid: "f289a3c9-25ae-4fad-84b2-a193b6d32988",
            price: 172.05,
            price_sources: [],
            processed_ms: 1719569534497,
          },
          {
            leverage: 2.7300000000000004,
            order_type: "LONG",
            order_uuid: "f6b1fb4f-e883-4a95-b8f7-32b7aa23d3f8",
            price: 172.023,
            price_sources: [],
            processed_ms: 1719569664215,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "8852753a-1bdd-4db5-bc33-8bdd8bf1488a",
            price: 172.072,
            price_sources: [],
            processed_ms: 1719570038535,
          },
        ],
        position_type: "FLAT",
        position_uuid: "e5f0f781-1680-4cfc-8c01-f6be09fd6922",
        return_at_close: 1.0002358001984397,
        trade_pair: ["EURJPY", "EUR/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 171.9034765372338,
        close_ms: 1719571873493,
        current_return: 1.000711412701735,
        initial_entry_price: 172.071,
        is_closed_position: true,
        miner_hotkey: "5D5GDbEa5Aedt9fXhWTzngDTANvnf8Q5poSFKYkU9hEZ7P8C",
        net_leverage: 0.0,
        open_ms: 1719570068184,
        orders: [
          {
            leverage: 0.5,
            order_type: "LONG",
            order_uuid: "c0918822-0e6d-46bf-8c0e-ebc65053f6e0",
            price: 172.071,
            price_sources: [],
            processed_ms: 1719570067926,
          },
          {
            leverage: 0.65,
            order_type: "LONG",
            order_uuid: "8d55754d-b6c6-41bb-bebd-8819b3a4b404",
            price: 172.047,
            price_sources: [],
            processed_ms: 1719570278215,
          },
          {
            leverage: 1.265,
            order_type: "LONG",
            order_uuid: "21477041-b8c2-4b09-a2f4-f7724e7cce23",
            price: 171.96699999999998,
            price_sources: [],
            processed_ms: 1719570426344,
          },
          {
            leverage: 3.1395,
            order_type: "LONG",
            order_uuid: "3ffa1f46-6226-4258-9afa-b3f38f610066",
            price: 171.934,
            price_sources: [],
            processed_ms: 1719570728606,
          },
          {
            leverage: 6.10995,
            order_type: "LONG",
            order_uuid: "cfc9a5a0-a2bd-47e0-9a53-5d3f9a880a29",
            price: 171.886,
            price_sources: [],
            processed_ms: 1719571080818,
          },
          {
            leverage: 10.498005000000001,
            order_type: "LONG",
            order_uuid: "7460d3eb-9e47-4e39-a2c2-47907541ee97",
            price: 171.88,
            price_sources: [],
            processed_ms: 1719571162057,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "c9053828-1834-47dd-bf05-ee5dc03ee5e1",
            price: 171.909,
            price_sources: [],
            processed_ms: 1719571873493,
          },
        ],
        position_type: "FLAT",
        position_uuid: "2543e1de-d0da-44e5-9f48-dc66e560f05f",
        return_at_close: 0.9991589371860957,
        trade_pair: ["EURJPY", "EUR/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 172.183343605547,
        close_ms: 1719594159308,
        current_return: 1.0015528415321209,
        initial_entry_price: 171.90550000000002,
        is_closed_position: true,
        miner_hotkey: "5D5GDbEa5Aedt9fXhWTzngDTANvnf8Q5poSFKYkU9hEZ7P8C",
        net_leverage: 0.0,
        open_ms: 1719571903787,
        orders: [
          {
            leverage: 0.5,
            order_type: "LONG",
            order_uuid: "75f70d43-656c-4358-9457-c86289dc3433",
            price: 171.90550000000002,
            price_sources: [],
            processed_ms: 1719571903446,
          },
          {
            leverage: 0.2,
            order_type: "LONG",
            order_uuid: "095d1fa2-26b7-4dd0-85d1-01367a4e8551",
            price: 172.3725,
            price_sources: [],
            processed_ms: 1719593512515,
          },
          {
            leverage: 0.22000000000000003,
            order_type: "LONG",
            order_uuid: "35b03aa6-0c28-4459-966c-5b4462ab5e4d",
            price: 172.3565,
            price_sources: [],
            processed_ms: 1719593552088,
          },
          {
            leverage: 0.37800000000000006,
            order_type: "LONG",
            order_uuid: "46ec7b6e-8db3-4180-8af5-d5b3464c9515",
            price: 172.35,
            price_sources: [],
            processed_ms: 1719593588261,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "cd409eea-ab6a-4801-835d-463f2ede9c8b",
            price: 172.389,
            price_sources: [],
            processed_ms: 1719594159308,
          },
        ],
        position_type: "FLAT",
        position_uuid: "f312836c-eef7-45ef-9e36-a9559af80a44",
        return_at_close: 1.0014618404409392,
        trade_pair: ["EURJPY", "EUR/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 172.262984238923,
        close_ms: 1719601187008,
        current_return: 0.9996671585238115,
        initial_entry_price: 172.383,
        is_closed_position: true,
        miner_hotkey: "5D5GDbEa5Aedt9fXhWTzngDTANvnf8Q5poSFKYkU9hEZ7P8C",
        net_leverage: 0.0,
        open_ms: 1719594190328,
        orders: [
          {
            leverage: 0.2,
            order_type: "LONG",
            order_uuid: "064f07d7-e4c5-48fc-a9f2-b9036ccadfa9",
            price: 172.383,
            price_sources: [],
            processed_ms: 1719594190031,
          },
          {
            leverage: 0.18000000000000002,
            order_type: "LONG",
            order_uuid: "cf774116-6516-4917-a18e-5e8b32355a75",
            price: 172.36700000000002,
            price_sources: [],
            processed_ms: 1719595748264,
          },
          {
            leverage: 0.41800000000000004,
            order_type: "LONG",
            order_uuid: "0646340f-d97f-42a7-abb9-5150bf7fcb53",
            price: 172.308,
            price_sources: [],
            processed_ms: 1719597094657,
          },
          {
            leverage: 0.7182000000000001,
            order_type: "LONG",
            order_uuid: "bedf244c-0b3d-4278-a9c0-59075c814f51",
            price: 172.3051702801305,
            price_sources: [],
            processed_ms: 1719597130389,
          },
          {
            leverage: 1.66782,
            order_type: "LONG",
            order_uuid: "11439701-ed6b-4882-862d-2f68b6c8b1c7",
            price: 172.256,
            price_sources: [],
            processed_ms: 1719598993804,
          },
          {
            leverage: 2.8656180000000004,
            order_type: "LONG",
            order_uuid: "5a42aa54-b701-47cf-8699-371e5770cbe6",
            price: 172.235,
            price_sources: [],
            processed_ms: 1719599119144,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "ea2ac7e9-b0c1-4664-88ab-9ea077962020",
            price: 172.25349999999997,
            price_sources: [],
            processed_ms: 1719601187008,
          },
        ],
        position_type: "FLAT",
        position_uuid: "7209ae87-1d4e-409e-acdc-2caeb3462778",
        return_at_close: 0.9992438248137424,
        trade_pair: ["EURJPY", "EUR/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 172.22259523809524,
        close_ms: 1719604703012,
        current_return: 1.0001472970383831,
        initial_entry_price: 172.237,
        is_closed_position: true,
        miner_hotkey: "5D5GDbEa5Aedt9fXhWTzngDTANvnf8Q5poSFKYkU9hEZ7P8C",
        net_leverage: 0.0,
        open_ms: 1719601220292,
        orders: [
          {
            leverage: 0.2,
            order_type: "LONG",
            order_uuid: "6d1aeafc-5343-413f-9902-e36ce575a1d0",
            price: 172.237,
            price_sources: [],
            processed_ms: 1719601218152,
          },
          {
            leverage: 0.22000000000000003,
            order_type: "LONG",
            order_uuid: "4d655db0-1841-40c7-8c39-97944bca16c4",
            price: 172.2095,
            price_sources: [],
            processed_ms: 1719601295775,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "d7fb3282-0c20-471b-ba43-b9db9f6595a8",
            price: 172.283,
            price_sources: [],
            processed_ms: 1719604703012,
          },
        ],
        position_type: "FLAT",
        position_uuid: "91d0f19d-3ded-4462-becb-c3c7d10888ab",
        return_at_close: 1.0001178927078502,
        trade_pair: ["EURJPY", "EUR/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 172.30499999999998,
        close_ms: 1719604981141,
        current_return: 1.0000824124802676,
        initial_entry_price: 172.304,
        is_closed_position: true,
        miner_hotkey: "5D5GDbEa5Aedt9fXhWTzngDTANvnf8Q5poSFKYkU9hEZ7P8C",
        net_leverage: 0.0,
        open_ms: 1719604732112,
        orders: [
          {
            leverage: 0.2,
            order_type: "LONG",
            order_uuid: "e0fb956e-ebea-4924-b5d9-1fe26ba12546",
            price: 172.304,
            price_sources: [],
            processed_ms: 1719604731326,
          },
          {
            leverage: 0.2,
            order_type: "LONG",
            order_uuid: "fb160d84-073c-4d29-bbdc-5dd1c2a82b25",
            price: 172.306,
            price_sources: [],
            processed_ms: 1719604809229,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "4f9e139c-e36d-429e-bd4d-1dc3396d2d9b",
            price: 172.34050000000002,
            price_sources: [],
            processed_ms: 1719604981141,
          },
        ],
        position_type: "FLAT",
        position_uuid: "458d7127-8b20-476f-be37-ba85fc202b8b",
        return_at_close: 1.0000544101727182,
        trade_pair: ["EURJPY", "EUR/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 172.31742105263163,
        close_ms: 1719606369414,
        current_return: 1.0000929563244534,
        initial_entry_price: 172.339,
        is_closed_position: true,
        miner_hotkey: "5D5GDbEa5Aedt9fXhWTzngDTANvnf8Q5poSFKYkU9hEZ7P8C",
        net_leverage: 0.0,
        open_ms: 1719605010996,
        orders: [
          {
            leverage: 0.2,
            order_type: "LONG",
            order_uuid: "21ada822-2782-4149-8388-0e4b03bec693",
            price: 172.339,
            price_sources: [],
            processed_ms: 1719605010869,
          },
          {
            leverage: 0.2,
            order_type: "LONG",
            order_uuid: "2039c9b5-6c2d-49fc-b44c-7a3b98d475c4",
            price: 172.32,
            price_sources: [],
            processed_ms: 1719605135681,
          },
          {
            leverage: 0.36000000000000004,
            order_type: "LONG",
            order_uuid: "e9a211ad-d319-49e3-8796-20c3e330edab",
            price: 172.304,
            price_sources: [],
            processed_ms: 1719605282046,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "9f5cf90f-f7db-4841-9292-a0e2d1c0864d",
            price: 172.3385,
            price_sources: [],
            processed_ms: 1719606369414,
          },
        ],
        position_type: "FLAT",
        position_uuid: "ea474af7-8de9-48a9-b327-2d850cf925fd",
        return_at_close: 1.000039751379177,
        trade_pair: ["EURJPY", "EUR/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 172.32740476190475,
        close_ms: 1719607966169,
        current_return: 1.000207602016838,
        initial_entry_price: 172.349,
        is_closed_position: true,
        miner_hotkey: "5D5GDbEa5Aedt9fXhWTzngDTANvnf8Q5poSFKYkU9hEZ7P8C",
        net_leverage: 0.0,
        open_ms: 1719606399826,
        orders: [
          {
            leverage: 0.2,
            order_type: "LONG",
            order_uuid: "e79d4451-f873-4bd2-ba75-5b5ac2300d41",
            price: 172.349,
            price_sources: [],
            processed_ms: 1719606398391,
          },
          {
            leverage: 0.2,
            order_type: "LONG",
            order_uuid: "e5d17fcd-2304-41a0-ae4d-abf83cdc4e55",
            price: 172.332,
            price_sources: [],
            processed_ms: 1719606446244,
          },
          {
            leverage: 0.44000000000000006,
            order_type: "LONG",
            order_uuid: "96e2387f-67fd-4882-b05b-dec747241726",
            price: 172.3155,
            price_sources: [],
            processed_ms: 1719607239786,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "74a51587-0e02-477c-8319-89e67f29ef6d",
            price: 172.37,
            price_sources: [],
            processed_ms: 1719607966169,
          },
        ],
        position_type: "FLAT",
        position_uuid: "f52f0f28-0c60-440d-8022-be8500e8d381",
        return_at_close: 1.0001487898098393,
        trade_pair: ["EURJPY", "EUR/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 172.3568671679198,
        close_ms: 1719608364514,
        current_return: 0.999921899478943,
        initial_entry_price: 172.342,
        is_closed_position: true,
        miner_hotkey: "5D5GDbEa5Aedt9fXhWTzngDTANvnf8Q5poSFKYkU9hEZ7P8C",
        net_leverage: 0.0,
        open_ms: 1719607996402,
        orders: [
          {
            leverage: 0.2,
            order_type: "LONG",
            order_uuid: "94c9a2ac-ab13-4e67-a493-d25db2b6ec75",
            price: 172.342,
            price_sources: [],
            processed_ms: 1719607996070,
          },
          {
            leverage: 0.22000000000000003,
            order_type: "LONG",
            order_uuid: "c04c9782-1b2a-4384-940b-c868f2d040d7",
            price: 172.365,
            price_sources: [],
            processed_ms: 1719608240336,
          },
          {
            leverage: 0.37800000000000006,
            order_type: "LONG",
            order_uuid: "2361aadb-4a58-4a44-989f-755075464ed0",
            price: 172.36,
            price_sources: [],
            processed_ms: 1719608300643,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "12b1a34a-d3b2-4f0a-aefc-7f25deec5652",
            price: 172.34,
            price_sources: [],
            processed_ms: 1719608364514,
          },
        ],
        position_type: "FLAT",
        position_uuid: "d2c947bf-8e89-45f4-8be4-eda8277ff0d8",
        return_at_close: 0.9998660438416381,
        trade_pair: ["EURJPY", "EUR/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 172.37,
        close_ms: 1719781206122,
        current_return: 1.0004084237396298,
        initial_entry_price: 172.37,
        is_closed_position: true,
        miner_hotkey: "5D5GDbEa5Aedt9fXhWTzngDTANvnf8Q5poSFKYkU9hEZ7P8C",
        net_leverage: 0.0,
        open_ms: 1719608399778,
        orders: [
          {
            leverage: 0.2,
            order_type: "LONG",
            order_uuid: "98c60929-4ce4-49e7-9644-132c05d2be62",
            price: 172.37,
            price_sources: [],
            processed_ms: 1719608398356,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "31d26a99-de59-43bf-8d5f-44603e640950",
            price: 172.722,
            price_sources: [],
            processed_ms: 1719781206122,
          },
        ],
        position_type: "FLAT",
        position_uuid: "530187d1-7300-446f-92bc-d2d643b8b7ba",
        return_at_close: 1.0003944180216975,
        trade_pair: ["EURJPY", "EUR/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 172.682,
        close_ms: 1719783029488,
        current_return: 1.0000498025271887,
        initial_entry_price: 172.682,
        is_closed_position: true,
        miner_hotkey: "5D5GDbEa5Aedt9fXhWTzngDTANvnf8Q5poSFKYkU9hEZ7P8C",
        net_leverage: 0.0,
        open_ms: 1719782234844,
        orders: [
          {
            leverage: 0.2,
            order_type: "LONG",
            order_uuid: "ccc21f4a-3e45-4511-a4c1-be71c68df249",
            price: 172.682,
            price_sources: [],
            processed_ms: 1719782234410,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "e64b1680-2376-4e21-bbe2-2aaad823f9d9",
            price: 172.725,
            price_sources: [],
            processed_ms: 1719783029488,
          },
        ],
        position_type: "FLAT",
        position_uuid: "6c59d25f-1341-4413-81a7-4835d67d5af1",
        return_at_close: 1.0000358018299533,
        trade_pair: ["EURJPY", "EUR/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 172.69125000000003,
        close_ms: 1719784568528,
        current_return: 1.0000220025824083,
        initial_entry_price: 172.707,
        is_closed_position: true,
        miner_hotkey: "5D5GDbEa5Aedt9fXhWTzngDTANvnf8Q5poSFKYkU9hEZ7P8C",
        net_leverage: 0.0,
        open_ms: 1719783087792,
        orders: [
          {
            leverage: 0.2,
            order_type: "LONG",
            order_uuid: "c9ec7296-8ea6-44d6-ac83-c0cf947c17c4",
            price: 172.707,
            price_sources: [],
            processed_ms: 1719783086974,
          },
          {
            leverage: 0.2,
            order_type: "LONG",
            order_uuid: "76681185-6f49-4b34-a6cf-92c440925857",
            price: 172.676,
            price_sources: [],
            processed_ms: 1719783599744,
          },
          {
            leverage: 0.4,
            order_type: "LONG",
            order_uuid: "3da46634-df85-4615-a07b-38c87430dd0b",
            price: 172.691,
            price_sources: [],
            processed_ms: 1719783739194,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "ec75e21a-d86e-4a1b-96ec-8f91a7ff6112",
            price: 172.696,
            price_sources: [],
            processed_ms: 1719784568528,
          },
        ],
        position_type: "FLAT",
        position_uuid: "b9d66715-3f01-4a50-b381-b8dc738da764",
        return_at_close: 0.9999660013502637,
        trade_pair: ["EURJPY", "EUR/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 172.7055,
        close_ms: 1719784988260,
        current_return: 1.0001285443796575,
        initial_entry_price: 172.703,
        is_closed_position: true,
        miner_hotkey: "5D5GDbEa5Aedt9fXhWTzngDTANvnf8Q5poSFKYkU9hEZ7P8C",
        net_leverage: 0.0,
        open_ms: 1719784626791,
        orders: [
          {
            leverage: 0.2,
            order_type: "LONG",
            order_uuid: "3bd8a23c-7563-4c5b-893c-9f988685d691",
            price: 172.703,
            price_sources: [],
            processed_ms: 1719784626214,
          },
          {
            leverage: 0.2,
            order_type: "LONG",
            order_uuid: "34a4e93f-569e-4c52-bfdb-36febce71d41",
            price: 172.708,
            price_sources: [],
            processed_ms: 1719784854097,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "dd3a0a06-6d4e-4b16-aa76-ab1b2415a165",
            price: 172.761,
            price_sources: [],
            processed_ms: 1719784988260,
          },
        ],
        position_type: "FLAT",
        position_uuid: "7330e763-faed-44c7-86bf-5626c99aacde",
        return_at_close: 1.0001005407804149,
        trade_pair: ["EURJPY", "EUR/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 172.64066422726515,
        close_ms: 1719785707289,
        current_return: 1.0004447517554367,
        initial_entry_price: 172.744,
        is_closed_position: true,
        miner_hotkey: "5D5GDbEa5Aedt9fXhWTzngDTANvnf8Q5poSFKYkU9hEZ7P8C",
        net_leverage: 0.0,
        open_ms: 1719785018838,
        orders: [
          {
            leverage: 0.2,
            order_type: "LONG",
            order_uuid: "7baeac27-528e-452a-8cb4-aed1d6cb13d5",
            price: 172.744,
            price_sources: [],
            processed_ms: 1719785018109,
          },
          {
            leverage: 0.22000000000000003,
            order_type: "LONG",
            order_uuid: "4fab9ed7-ade6-4445-963a-99f7bc4cffe1",
            price: 172.704,
            price_sources: [],
            processed_ms: 1719785124135,
          },
          {
            leverage: 0.42000000000000004,
            order_type: "LONG",
            order_uuid: "ec36c7f7-aaa5-4591-8f1b-b37dbf2e9cac",
            price: 172.662,
            price_sources: [],
            processed_ms: 1719785250068,
          },
          {
            leverage: 0.7560000000000001,
            order_type: "LONG",
            order_uuid: "1e64086e-3626-454b-9030-589032c0e7e2",
            price: 172.6451045010037,
            price_sources: [],
            processed_ms: 1719785305802,
          },
          {
            leverage: 1.4364000000000001,
            order_type: "LONG",
            order_uuid: "3d38944d-c5b8-4b4a-bd11-8901940b550e",
            price: 172.608,
            price_sources: [],
            processed_ms: 1719785450245,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "941a34e4-02c7-45ff-b299-fb1efa88b359",
            price: 172.666,
            price_sources: [],
            processed_ms: 1719785707289,
          },
        ],
        position_type: "FLAT",
        position_uuid: "69fa6267-f665-4fc9-8a1f-b7bfad79b823",
        return_at_close: 1.0002323893488712,
        trade_pair: ["EURJPY", "EUR/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 172.6539736842105,
        close_ms: 1719786421072,
        current_return: 1.0002377602130925,
        initial_entry_price: 172.695,
        is_closed_position: true,
        miner_hotkey: "5D5GDbEa5Aedt9fXhWTzngDTANvnf8Q5poSFKYkU9hEZ7P8C",
        net_leverage: 0.0,
        open_ms: 1719785738071,
        orders: [
          {
            leverage: 0.2,
            order_type: "LONG",
            order_uuid: "28aab5a5-bf32-41ab-b232-07265b60c380",
            price: 172.695,
            price_sources: [],
            processed_ms: 1719785737839,
          },
          {
            leverage: 0.18000000000000002,
            order_type: "LONG",
            order_uuid: "c5a83cb7-08b0-4bd3-9eae-1da069d9b796",
            price: 172.659,
            price_sources: [],
            processed_ms: 1719785768015,
          },
          {
            leverage: 0.38,
            order_type: "LONG",
            order_uuid: "9535d139-2a4b-4bb0-b8bd-d93a0af98620",
            price: 172.63,
            price_sources: [],
            processed_ms: 1719785890145,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "cb278249-5c05-4ae6-9eab-c8ba890ba214",
            price: 172.708,
            price_sources: [],
            processed_ms: 1719786421072,
          },
        ],
        position_type: "FLAT",
        position_uuid: "0c1dbd92-fe19-426c-8c6b-14d8f84728ca",
        return_at_close: 1.0001845475642492,
        trade_pair: ["EURJPY", "EUR/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 172.65659210526314,
        close_ms: 1719790667312,
        current_return: 0.9999683864953594,
        initial_entry_price: 172.711,
        is_closed_position: true,
        miner_hotkey: "5D5GDbEa5Aedt9fXhWTzngDTANvnf8Q5poSFKYkU9hEZ7P8C",
        net_leverage: 0.0,
        open_ms: 1719786452117,
        orders: [
          {
            leverage: 0.2,
            order_type: "LONG",
            order_uuid: "ab4c21fe-1a74-481e-883d-be1aa079466c",
            price: 172.711,
            price_sources: [],
            processed_ms: 1719786450008,
          },
          {
            leverage: 0.2,
            order_type: "LONG",
            order_uuid: "3d7836b5-f7a7-4536-ad63-2b0165818d51",
            price: 172.68849999999998,
            price_sources: [],
            processed_ms: 1719786824491,
          },
          {
            leverage: 0.4,
            order_type: "LONG",
            order_uuid: "b546e691-5374-4473-b67e-80d05ef863a6",
            price: 172.655,
            price_sources: [],
            processed_ms: 1719787328823,
          },
          {
            leverage: 0.7200000000000001,
            order_type: "LONG",
            order_uuid: "05102296-f141-4b29-8d31-e9ac73a173e5",
            price: 172.6335,
            price_sources: [],
            processed_ms: 1719790429313,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "f1efa6f9-80bd-4ba9-b6a0-fe2a98367ee2",
            price: 172.653,
            price_sources: [],
            processed_ms: 1719790667312,
          },
        ],
        position_type: "FLAT",
        position_uuid: "d34be1c3-af7b-4e73-9ced-fd911bba3b0f",
        return_at_close: 0.9998619898590363,
        trade_pair: ["EURJPY", "EUR/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 172.66942793224672,
        close_ms: 1719791009212,
        current_return: 1.0000597708176364,
        initial_entry_price: 172.664,
        is_closed_position: true,
        miner_hotkey: "5D5GDbEa5Aedt9fXhWTzngDTANvnf8Q5poSFKYkU9hEZ7P8C",
        net_leverage: 0.0,
        open_ms: 1719790699105,
        orders: [
          {
            leverage: 0.2,
            order_type: "LONG",
            order_uuid: "414b7778-2258-4d4c-ad91-4bf916c59bf0",
            price: 172.664,
            price_sources: [],
            processed_ms: 1719790698423,
          },
          {
            leverage: 0.22000000000000003,
            order_type: "LONG",
            order_uuid: "d3dda18b-9871-41e4-af36-dee2a0f3e54b",
            price: 172.6743624161074,
            price_sources: [],
            processed_ms: 1719790778926,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "a1710148-d9bc-4b43-b1c1-cf5f644d2384",
            price: 172.694,
            price_sources: [],
            processed_ms: 1719791009212,
          },
        ],
        position_type: "FLAT",
        position_uuid: "d9b27821-2e10-4ba2-a666-06a2f241b625",
        return_at_close: 1.0000303690603745,
        trade_pair: ["EURJPY", "EUR/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 172.68919047619045,
        close_ms: 1719792929280,
        current_return: 1.0001673460879235,
        initial_entry_price: 172.696,
        is_closed_position: true,
        miner_hotkey: "5D5GDbEa5Aedt9fXhWTzngDTANvnf8Q5poSFKYkU9hEZ7P8C",
        net_leverage: 0.0,
        open_ms: 1719791046847,
        orders: [
          {
            leverage: 0.2,
            order_type: "LONG",
            order_uuid: "8dd3cd36-37ad-4535-aae0-88119d08033d",
            price: 172.696,
            price_sources: [],
            processed_ms: 1719791046764,
          },
          {
            leverage: 0.22000000000000003,
            order_type: "LONG",
            order_uuid: "eea9fa99-bc51-4457-864b-35fcaf9b6234",
            price: 172.683,
            price_sources: [],
            processed_ms: 1719792393003,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "8827ceb7-049a-4e37-aa9b-562462d25829",
            price: 172.758,
            price_sources: [],
            processed_ms: 1719792929280,
          },
        ],
        position_type: "FLAT",
        position_uuid: "21554aae-6c6f-4828-a78b-57975811d4a8",
        return_at_close: 1.0001379411679485,
        trade_pair: ["EURJPY", "EUR/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 172.82549999999995,
        close_ms: 1719792989011,
        current_return: 1.0000514970302417,
        initial_entry_price: 172.82549999999998,
        is_closed_position: true,
        miner_hotkey: "5D5GDbEa5Aedt9fXhWTzngDTANvnf8Q5poSFKYkU9hEZ7P8C",
        net_leverage: 0.0,
        open_ms: 1719792957296,
        orders: [
          {
            leverage: 0.2,
            order_type: "LONG",
            order_uuid: "84cb7a37-85ea-4996-a386-67469ea85090",
            price: 172.82549999999998,
            price_sources: [],
            processed_ms: 1719792957132,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "fe416800-52f5-414b-885a-34978757e284",
            price: 172.87,
            price_sources: [],
            processed_ms: 1719792989011,
          },
        ],
        position_type: "FLAT",
        position_uuid: "0543bab4-7cc9-48a8-9530-ea8d3fc7666a",
        return_at_close: 1.0000374963092833,
        trade_pair: ["EURJPY", "EUR/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 172.84366666666665,
        close_ms: 1719793559078,
        current_return: 1.0001254966370372,
        initial_entry_price: 172.913,
        is_closed_position: true,
        miner_hotkey: "5D5GDbEa5Aedt9fXhWTzngDTANvnf8Q5poSFKYkU9hEZ7P8C",
        net_leverage: 0.0,
        open_ms: 1719793019013,
        orders: [
          {
            leverage: 0.2,
            order_type: "LONG",
            order_uuid: "bb357951-a486-4112-a44e-74550d031d33",
            price: 172.913,
            price_sources: [],
            processed_ms: 1719793018760,
          },
          {
            leverage: 0.2,
            order_type: "LONG",
            order_uuid: "0bb11a76-8e9b-44bf-a0be-c7d33ed73681",
            price: 172.86599999999999,
            price_sources: [],
            processed_ms: 1719793353367,
          },
          {
            leverage: 0.44000000000000006,
            order_type: "LONG",
            order_uuid: "b284c4ea-75de-4d5d-9587-39b9d6d816e5",
            price: 172.802,
            price_sources: [],
            processed_ms: 1719793383004,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "b385cfcd-1f70-4d99-9320-5975a878caea",
            price: 172.86950000000002,
            price_sources: [],
            processed_ms: 1719793559078,
          },
        ],
        position_type: "FLAT",
        position_uuid: "06d0dfe8-a506-4f43-a452-3a04dcdbfcd1",
        return_at_close: 1.000066689257835,
        trade_pair: ["EURJPY", "EUR/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 172.85080952380952,
        close_ms: 1719793907240,
        current_return: 1.0001753903911288,
        initial_entry_price: 172.87150000000003,
        is_closed_position: true,
        miner_hotkey: "5D5GDbEa5Aedt9fXhWTzngDTANvnf8Q5poSFKYkU9hEZ7P8C",
        net_leverage: 0.0,
        open_ms: 1719793588927,
        orders: [
          {
            leverage: 0.2,
            order_type: "LONG",
            order_uuid: "93ada0e3-c716-4e95-af6e-601c67b69124",
            price: 172.87150000000003,
            price_sources: [],
            processed_ms: 1719793588796,
          },
          {
            leverage: 0.22000000000000003,
            order_type: "LONG",
            order_uuid: "755632e5-4cce-4b0f-bd9c-737b5b5bb65f",
            price: 172.832,
            price_sources: [],
            processed_ms: 1719793675084,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "dbd99571-058c-403d-86de-02cb487afb71",
            price: 172.923,
            price_sources: [],
            processed_ms: 1719793907240,
          },
        ],
        position_type: "FLAT",
        position_uuid: "c43617ee-8805-4c88-bf6f-a7dfc3d81555",
        return_at_close: 1.0001459852346513,
        trade_pair: ["EURJPY", "EUR/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 172.92649999999998,
        close_ms: 1719794712060,
        current_return: 1.000170592708463,
        initial_entry_price: 172.92649999999998,
        is_closed_position: true,
        miner_hotkey: "5D5GDbEa5Aedt9fXhWTzngDTANvnf8Q5poSFKYkU9hEZ7P8C",
        net_leverage: 0.0,
        open_ms: 1719793937082,
        orders: [
          {
            leverage: 0.2,
            order_type: "LONG",
            order_uuid: "01ef5853-6171-4822-a6a7-dd1f1aaf537f",
            price: 172.92649999999998,
            price_sources: [],
            processed_ms: 1719793936830,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "8aa5c28f-0f50-4452-95af-f7a37d2a01c9",
            price: 173.074,
            price_sources: [],
            processed_ms: 1719794712060,
          },
        ],
        position_type: "FLAT",
        position_uuid: "d011f342-902b-4fce-8cb8-448fe6011a4d",
        return_at_close: 1.000156590320165,
        trade_pair: ["EURJPY", "EUR/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 173.071,
        close_ms: 1719795140413,
        current_return: 1.0001155595102589,
        initial_entry_price: 173.071,
        is_closed_position: true,
        miner_hotkey: "5D5GDbEa5Aedt9fXhWTzngDTANvnf8Q5poSFKYkU9hEZ7P8C",
        net_leverage: 0.0,
        open_ms: 1719794742344,
        orders: [
          {
            leverage: 0.2,
            order_type: "LONG",
            order_uuid: "7a214c1c-6c14-4ca6-9d2a-7463d1d05737",
            price: 173.071,
            price_sources: [],
            processed_ms: 1719794742121,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "f8a57690-4511-48f9-a07e-bc616c4128fe",
            price: 173.171,
            price_sources: [],
            processed_ms: 1719795140413,
          },
        ],
        position_type: "FLAT",
        position_uuid: "1c6a256c-aafc-471c-926d-65106989b666",
        return_at_close: 1.0001015578924257,
        trade_pair: ["EURJPY", "EUR/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 172.9695941828255,
        close_ms: 1719795964153,
        current_return: 0.9990763559938551,
        initial_entry_price: 173.194,
        is_closed_position: true,
        miner_hotkey: "5D5GDbEa5Aedt9fXhWTzngDTANvnf8Q5poSFKYkU9hEZ7P8C",
        net_leverage: 0.0,
        open_ms: 1719795169805,
        orders: [
          {
            leverage: 0.2,
            order_type: "LONG",
            order_uuid: "40f17983-4867-44a1-b033-37ac2480cdd8",
            price: 173.194,
            price_sources: [],
            processed_ms: 1719795169736,
          },
          {
            leverage: 0.2,
            order_type: "LONG",
            order_uuid: "eb6b3e12-c72e-4a63-885b-aa6c3a2756e8",
            price: 173.167,
            price_sources: [],
            processed_ms: 1719795254117,
          },
          {
            leverage: 0.44000000000000006,
            order_type: "LONG",
            order_uuid: "90edf295-ac83-4b4a-933b-16d32a2956ba",
            price: 173.128,
            price_sources: [],
            processed_ms: 1719795337865,
          },
          {
            leverage: 0.8400000000000001,
            order_type: "LONG",
            order_uuid: "bcca81ac-769f-4cd9-a53e-404b642da4b7",
            price: 173.084,
            price_sources: [],
            processed_ms: 1719795368296,
          },
          {
            leverage: 1.6800000000000002,
            order_type: "LONG",
            order_uuid: "62b564ff-9927-4379-9cc2-0812737e7c9e",
            price: 173.0315,
            price_sources: [],
            processed_ms: 1719795544052,
          },
          {
            leverage: 3.0240000000000005,
            order_type: "LONG",
            order_uuid: "ac32b9f8-4c79-4b71-b5be-6afb739d3f64",
            price: 172.993,
            price_sources: [],
            processed_ms: 1719795602276,
          },
          {
            leverage: 5.7456000000000005,
            order_type: "LONG",
            order_uuid: "ce8c807d-7c55-4a4a-83d1-4ecd7642b77f",
            price: 172.956,
            price_sources: [],
            processed_ms: 1719795740088,
          },
          {
            leverage: 12.1296,
            order_type: "LONG",
            order_uuid: "b690a485-e3d3-4010-89b2-3ccc956edfed",
            price: 172.941,
            price_sources: [],
            processed_ms: 1719795786012,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "e6d8bfb0-e334-460c-8fc5-e6ecdea4abaa",
            price: 172.963,
            price_sources: [],
            processed_ms: 1719795964153,
          },
        ],
        position_type: "FLAT",
        position_uuid: "fa50b8b5-69d7-4e98-a7e5-64531a9045d6",
        return_at_close: 0.9973797804743822,
        trade_pair: ["EURJPY", "EUR/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 172.9322380952381,
        close_ms: 1719796341863,
        current_return: 1.0001785301327413,
        initial_entry_price: 172.968,
        is_closed_position: true,
        miner_hotkey: "5D5GDbEa5Aedt9fXhWTzngDTANvnf8Q5poSFKYkU9hEZ7P8C",
        net_leverage: 0.0,
        open_ms: 1719795995376,
        orders: [
          {
            leverage: 0.2,
            order_type: "LONG",
            order_uuid: "0f131e7a-f10a-4a14-8b9b-cafd770b198d",
            price: 172.968,
            price_sources: [],
            processed_ms: 1719795995087,
          },
          {
            leverage: 0.2,
            order_type: "LONG",
            order_uuid: "75abb6fd-e3c6-43ec-9157-d26f400c5b6a",
            price: 172.93,
            price_sources: [],
            processed_ms: 1719796044148,
          },
          {
            leverage: 0.44000000000000006,
            order_type: "LONG",
            order_uuid: "9297188c-ef05-41c5-8e3e-3cecf4cd3169",
            price: 172.917,
            price_sources: [],
            processed_ms: 1719796262498,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "e9c21421-45cf-4ef1-95d4-bc7b5b669ab0",
            price: 172.969,
            price_sources: [],
            processed_ms: 1719796341863,
          },
        ],
        position_type: "FLAT",
        position_uuid: "1dcd6653-7258-4dc3-b149-533924aed173",
        return_at_close: 1.0001197196351694,
        trade_pair: ["EURJPY", "EUR/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 172.965,
        close_ms: 1719797028339,
        current_return: 1.0002115949402215,
        initial_entry_price: 172.972,
        is_closed_position: true,
        miner_hotkey: "5D5GDbEa5Aedt9fXhWTzngDTANvnf8Q5poSFKYkU9hEZ7P8C",
        net_leverage: 0.0,
        open_ms: 1719796372423,
        orders: [
          {
            leverage: 0.2,
            order_type: "LONG",
            order_uuid: "42d831a2-1987-482a-89a0-56c5089487e1",
            price: 172.972,
            price_sources: [],
            processed_ms: 1719796372099,
          },
          {
            leverage: 0.2,
            order_type: "LONG",
            order_uuid: "7c64e3a2-9ca3-4e33-a680-d42ff38ab2dc",
            price: 172.958,
            price_sources: [],
            processed_ms: 1719796744453,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "64dfe7d2-3d05-40c2-8b37-c3744bdeddf5",
            price: 173.0565,
            price_sources: [],
            processed_ms: 1719797028339,
          },
        ],
        position_type: "FLAT",
        position_uuid: "2eb25bb3-f825-4d5a-9656-4cebfc1f904a",
        return_at_close: 1.000183589015563,
        trade_pair: ["EURJPY", "EUR/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 172.90950934876872,
        close_ms: 1719798621071,
        current_return: 1.0002839414514657,
        initial_entry_price: 173.053,
        is_closed_position: true,
        miner_hotkey: "5D5GDbEa5Aedt9fXhWTzngDTANvnf8Q5poSFKYkU9hEZ7P8C",
        net_leverage: 0.0,
        open_ms: 1719797058243,
        orders: [
          {
            leverage: 0.2,
            order_type: "LONG",
            order_uuid: "841c461d-3d6c-4862-a25c-ebd0a07c470b",
            price: 173.053,
            price_sources: [],
            processed_ms: 1719797058024,
          },
          {
            leverage: 0.18000000000000002,
            order_type: "LONG",
            order_uuid: "f3ea8705-bafe-4d3d-9d9f-45945ad00a96",
            price: 173.014,
            price_sources: [],
            processed_ms: 1719797985791,
          },
          {
            leverage: 0.38,
            order_type: "LONG",
            order_uuid: "cee42946-bc0b-4b45-80cc-4b97ecaa023b",
            price: 172.993,
            price_sources: [],
            processed_ms: 1719798059033,
          },
          {
            leverage: 0.8360000000000001,
            order_type: "LONG",
            order_uuid: "5b39fcd9-9983-465d-8319-5e1d094d480c",
            price: 172.9655,
            price_sources: [],
            processed_ms: 1719798108997,
          },
          {
            leverage: 1.7556000000000003,
            order_type: "LONG",
            order_uuid: "270ed428-979f-45f4-9769-85037bb09418",
            price: 172.917,
            price_sources: [],
            processed_ms: 1719798493664,
          },
          {
            leverage: 3.6867600000000005,
            order_type: "LONG",
            order_uuid: "1e85f1e6-78a0-4a03-918a-4cf499e5d5b9",
            price: 172.909,
            price_sources: [],
            processed_ms: 1719798524750,
          },
          {
            leverage: 7.038360000000001,
            order_type: "LONG",
            order_uuid: "1bffbb0c-da36-4127-a9e5-e7d860302222",
            price: 172.89,
            price_sources: [],
            processed_ms: 1719798588416,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "eb3a23ea-93a7-47fe-ab77-ca51d479a8a3",
            price: 172.913,
            price_sources: [],
            processed_ms: 1719798621071,
          },
        ],
        position_type: "FLAT",
        position_uuid: "e7624bb3-fba1-4e40-a59a-e4cdda74c359",
        return_at_close: 0.9992982912639641,
        trade_pair: ["EURJPY", "EUR/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 172.90711904761903,
        close_ms: 1719801151324,
        current_return: 1.0001636585492797,
        initial_entry_price: 172.921,
        is_closed_position: true,
        miner_hotkey: "5D5GDbEa5Aedt9fXhWTzngDTANvnf8Q5poSFKYkU9hEZ7P8C",
        net_leverage: 0.0,
        open_ms: 1719798651938,
        orders: [
          {
            leverage: 0.2,
            order_type: "LONG",
            order_uuid: "806a5d73-7737-4850-a75b-b63482a23528",
            price: 172.921,
            price_sources: [],
            processed_ms: 1719798651700,
          },
          {
            leverage: 0.22000000000000003,
            order_type: "LONG",
            order_uuid: "ffcd1cab-019a-42d6-b06e-bb3266783597",
            price: 172.8945,
            price_sources: [],
            processed_ms: 1719799320048,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "6e88faa1-c34b-4822-bc05-a39ab1afbbe2",
            price: 172.97449999999998,
            price_sources: [],
            processed_ms: 1719801151324,
          },
        ],
        position_type: "FLAT",
        position_uuid: "524952e9-cbf1-43fa-978d-e3d0dc996c31",
        return_at_close: 1.0001342537377185,
        trade_pair: ["EURJPY", "EUR/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 172.94486842105266,
        close_ms: 1719802344326,
        current_return: 1.0001214079857548,
        initial_entry_price: 172.97050000000002,
        is_closed_position: true,
        miner_hotkey: "5D5GDbEa5Aedt9fXhWTzngDTANvnf8Q5poSFKYkU9hEZ7P8C",
        net_leverage: 0.0,
        open_ms: 1719801181036,
        orders: [
          {
            leverage: 0.2,
            order_type: "LONG",
            order_uuid: "59c26750-6736-42f3-a690-8478bb28fbfd",
            price: 172.97050000000002,
            price_sources: [],
            processed_ms: 1719801180820,
          },
          {
            leverage: 0.2,
            order_type: "LONG",
            order_uuid: "f59955b2-7802-4cdc-a3eb-d5fe57ee9b08",
            price: 172.964,
            price_sources: [],
            processed_ms: 1719801411476,
          },
          {
            leverage: 0.36000000000000004,
            order_type: "LONG",
            order_uuid: "0585aaa5-45a2-420c-a334-021fbf8e62ce",
            price: 172.92,
            price_sources: [],
            processed_ms: 1719802020188,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "43045a11-031a-451e-9005-af121d8f8732",
            price: 172.97250000000003,
            price_sources: [],
            processed_ms: 1719802344326,
          },
        ],
        position_type: "FLAT",
        position_uuid: "254b8d75-8f3e-4f1e-806b-1954bddbadc7",
        return_at_close: 1.00006820152685,
        trade_pair: ["EURJPY", "EUR/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 172.97599999999997,
        close_ms: 1719803651030,
        current_return: 1.0001491536398113,
        initial_entry_price: 172.976,
        is_closed_position: true,
        miner_hotkey: "5D5GDbEa5Aedt9fXhWTzngDTANvnf8Q5poSFKYkU9hEZ7P8C",
        net_leverage: 0.0,
        open_ms: 1719802374129,
        orders: [
          {
            leverage: 0.2,
            order_type: "LONG",
            order_uuid: "c9241525-86be-40db-b7db-fcb6ad2974a5",
            price: 172.976,
            price_sources: [],
            processed_ms: 1719802373908,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "46aac470-c836-49d6-9167-88f5c52dae5c",
            price: 173.105,
            price_sources: [],
            processed_ms: 1719803651030,
          },
        ],
        position_type: "FLAT",
        position_uuid: "3e0f240e-51f5-47a9-8f52-aac1c98f903e",
        return_at_close: 1.0001351515516603,
        trade_pair: ["EURJPY", "EUR/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 173.09590476190476,
        close_ms: 1719804661739,
        current_return: 1.000149443414265,
        initial_entry_price: 173.109,
        is_closed_position: true,
        miner_hotkey: "5D5GDbEa5Aedt9fXhWTzngDTANvnf8Q5poSFKYkU9hEZ7P8C",
        net_leverage: 0.0,
        open_ms: 1719803682241,
        orders: [
          {
            leverage: 0.2,
            order_type: "LONG",
            order_uuid: "b6964f82-bf56-49d7-8705-9a2b0c6a8a65",
            price: 173.109,
            price_sources: [],
            processed_ms: 1719803682124,
          },
          {
            leverage: 0.22000000000000003,
            order_type: "LONG",
            order_uuid: "df9a99d8-67ac-451d-b98e-aac4d3323942",
            price: 173.084,
            price_sources: [],
            processed_ms: 1719803760049,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "b928a930-ea66-4bc4-b03a-616f29d1aa9e",
            price: 173.1575,
            price_sources: [],
            processed_ms: 1719804661739,
          },
        ],
        position_type: "FLAT",
        position_uuid: "c79e28a6-3791-4d4a-9985-db9368fb54fb",
        return_at_close: 1.0001200390206286,
        trade_pair: ["EURJPY", "EUR/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 173.14867120181404,
        close_ms: 1719805551241,
        current_return: 1.0001060803787967,
        initial_entry_price: 173.18,
        is_closed_position: true,
        miner_hotkey: "5D5GDbEa5Aedt9fXhWTzngDTANvnf8Q5poSFKYkU9hEZ7P8C",
        net_leverage: 0.0,
        open_ms: 1719804692853,
        orders: [
          {
            leverage: 0.2,
            order_type: "LONG",
            order_uuid: "a163e51e-76c7-4c4c-b9e8-ee8933b668d1",
            price: 173.18,
            price_sources: [],
            processed_ms: 1719804690884,
          },
          {
            leverage: 0.22000000000000003,
            order_type: "LONG",
            order_uuid: "9ab7d804-8a0f-44dd-8240-09db212273ee",
            price: 173.151,
            price_sources: [],
            processed_ms: 1719804891202,
          },
          {
            leverage: 0.4620000000000001,
            order_type: "LONG",
            order_uuid: "088505c5-a0ee-411b-842c-6c36e4441313",
            price: 173.134,
            price_sources: [],
            processed_ms: 1719805301844,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "20a70db0-1414-4c01-8cad-821a06562201",
            price: 173.1695,
            price_sources: [],
            processed_ms: 1719805551241,
          },
        ],
        position_type: "FLAT",
        position_uuid: "07910e74-3da8-4844-8169-d3632191953f",
        return_at_close: 1.000044333829394,
        trade_pair: ["EURJPY", "EUR/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 173.16261904761905,
        close_ms: 1719807247377,
        current_return: 1.0001440194951812,
        initial_entry_price: 173.171,
        is_closed_position: true,
        miner_hotkey: "5D5GDbEa5Aedt9fXhWTzngDTANvnf8Q5poSFKYkU9hEZ7P8C",
        net_leverage: 0.0,
        open_ms: 1719805583184,
        orders: [
          {
            leverage: 0.2,
            order_type: "LONG",
            order_uuid: "af9d6469-6833-47a0-a819-86b4bc6c62bf",
            price: 173.171,
            price_sources: [],
            processed_ms: 1719805582949,
          },
          {
            leverage: 0.22000000000000003,
            order_type: "LONG",
            order_uuid: "251454e4-6f79-499d-9412-60842e68986c",
            price: 173.155,
            price_sources: [],
            processed_ms: 1719805743020,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "5a4c879f-c263-4a69-8637-3917d8d4f290",
            price: 173.222,
            price_sources: [],
            processed_ms: 1719807247377,
          },
        ],
        position_type: "FLAT",
        position_uuid: "68e669b6-0cfc-4023-b457-11a0141a49c1",
        return_at_close: 1.000114615261008,
        trade_pair: ["EURJPY", "EUR/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 173.20509523809523,
        close_ms: 1719808610592,
        current_return: 1.0000819790433852,
        initial_entry_price: 173.21499999999997,
        is_closed_position: true,
        miner_hotkey: "5D5GDbEa5Aedt9fXhWTzngDTANvnf8Q5poSFKYkU9hEZ7P8C",
        net_leverage: 0.0,
        open_ms: 1719807277067,
        orders: [
          {
            leverage: 0.2,
            order_type: "LONG",
            order_uuid: "7bf7a7d3-ea8f-4c50-b300-35dafb876f9b",
            price: 173.21499999999997,
            price_sources: [],
            processed_ms: 1719807276916,
          },
          {
            leverage: 0.2,
            order_type: "LONG",
            order_uuid: "29e7dae5-5cdc-4ed9-b2c6-5cd1de01d5a6",
            price: 173.2185,
            price_sources: [],
            processed_ms: 1719807589268,
          },
          {
            leverage: 0.44000000000000006,
            order_type: "LONG",
            order_uuid: "65b65db2-cb46-48e9-898a-f40586a5c3c1",
            price: 173.1945,
            price_sources: [],
            processed_ms: 1719808200068,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "ad779196-8cc4-4492-8768-d93c4cd4114f",
            price: 173.22199999999998,
            price_sources: [],
            processed_ms: 1719808610592,
          },
        ],
        position_type: "FLAT",
        position_uuid: "f77b0930-64bc-4a6b-aab9-bbcb8b13e6bc",
        return_at_close: 1.0000231742230175,
        trade_pair: ["EURJPY", "EUR/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 173.1865,
        close_ms: 1719809953312,
        current_return: 1.000159338629226,
        initial_entry_price: 173.216,
        is_closed_position: true,
        miner_hotkey: "5D5GDbEa5Aedt9fXhWTzngDTANvnf8Q5poSFKYkU9hEZ7P8C",
        net_leverage: 0.0,
        open_ms: 1719808640198,
        orders: [
          {
            leverage: 0.2,
            order_type: "LONG",
            order_uuid: "a29b97c1-28b2-41a6-9e89-68f6eb161f5b",
            price: 173.216,
            price_sources: [],
            processed_ms: 1719808640048,
          },
          {
            leverage: 0.2,
            order_type: "LONG",
            order_uuid: "2cad1b66-c868-4414-a59b-a56f1043cba9",
            price: 173.18,
            price_sources: [],
            processed_ms: 1719808727938,
          },
          {
            leverage: 0.4,
            order_type: "LONG",
            order_uuid: "13bff8d2-688d-4339-84f5-c7310e2a5d0e",
            price: 173.175,
            price_sources: [],
            processed_ms: 1719809693477,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "75e27356-9490-49d0-9e05-c6e9663d1e97",
            price: 173.221,
            price_sources: [],
            processed_ms: 1719809953312,
          },
        ],
        position_type: "FLAT",
        position_uuid: "df4eb925-f430-4664-a5f9-f846fce60253",
        return_at_close: 1.000103329706263,
        trade_pair: ["EURJPY", "EUR/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 173.1703157894737,
        close_ms: 1719811481967,
        current_return: 1.0001025291682781,
        initial_entry_price: 173.219,
        is_closed_position: true,
        miner_hotkey: "5D5GDbEa5Aedt9fXhWTzngDTANvnf8Q5poSFKYkU9hEZ7P8C",
        net_leverage: 0.0,
        open_ms: 1719809983288,
        orders: [
          {
            leverage: 0.2,
            order_type: "LONG",
            order_uuid: "1f73a5da-7010-4aca-9635-d928e108d79a",
            price: 173.219,
            price_sources: [],
            processed_ms: 1719809983146,
          },
          {
            leverage: 0.2,
            order_type: "LONG",
            order_uuid: "34be8832-c1d2-49b8-88ff-cc7e01ad4931",
            price: 173.1985,
            price_sources: [],
            processed_ms: 1719811086631,
          },
          {
            leverage: 0.36000000000000004,
            order_type: "LONG",
            order_uuid: "95970957-a226-452b-859d-354ab21f9d41",
            price: 173.18,
            price_sources: [],
            processed_ms: 1719811282577,
          },
          {
            leverage: 0.76,
            order_type: "LONG",
            order_uuid: "288a877d-91b0-4223-9e00-14ddf4c58219",
            price: 173.1455,
            price_sources: [],
            processed_ms: 1719811349940,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "6ea45b01-4835-422d-9657-f19fd8074723",
            price: 173.182,
            price_sources: [],
            processed_ms: 1719811481967,
          },
        ],
        position_type: "FLAT",
        position_uuid: "050d5b66-6f93-42f0-94a1-485c804f4942",
        return_at_close: 0.9999961182591747,
        trade_pair: ["EURJPY", "EUR/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 173.18999999999997,
        close_ms: 1719813040717,
        current_return: 1.000125873318321,
        initial_entry_price: 173.19,
        is_closed_position: true,
        miner_hotkey: "5D5GDbEa5Aedt9fXhWTzngDTANvnf8Q5poSFKYkU9hEZ7P8C",
        net_leverage: 0.0,
        open_ms: 1719811512057,
        orders: [
          {
            leverage: 0.2,
            order_type: "LONG",
            order_uuid: "abcc3e0c-8548-424f-9c38-51d8d10e64d9",
            price: 173.19,
            price_sources: [],
            processed_ms: 1719811511954,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "36328b78-be0b-4b8c-8f35-03f80d06fa46",
            price: 173.299,
            price_sources: [],
            processed_ms: 1719813040717,
          },
        ],
        position_type: "FLAT",
        position_uuid: "d022ce52-e73e-4fbd-b023-99253d12de50",
        return_at_close: 1.0001118715560946,
        trade_pair: ["EURJPY", "EUR/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 173.20267326210265,
        close_ms: 1719813520162,
        current_return: 1.0003906638046434,
        initial_entry_price: 173.304,
        is_closed_position: true,
        miner_hotkey: "5D5GDbEa5Aedt9fXhWTzngDTANvnf8Q5poSFKYkU9hEZ7P8C",
        net_leverage: 0.0,
        open_ms: 1719813070298,
        orders: [
          {
            leverage: 0.2,
            order_type: "LONG",
            order_uuid: "f3c86cc2-9906-41da-a639-4ac0230fa793",
            price: 173.304,
            price_sources: [],
            processed_ms: 1719813069998,
          },
          {
            leverage: 0.22000000000000003,
            order_type: "LONG",
            order_uuid: "0960365a-baef-400f-be30-b313e18dcaa3",
            price: 173.299,
            price_sources: [],
            processed_ms: 1719813124117,
          },
          {
            leverage: 0.37800000000000006,
            order_type: "LONG",
            order_uuid: "237ac4c9-38fd-4221-acb1-d396cc1ed261",
            price: 173.238,
            price_sources: [],
            processed_ms: 1719813254190,
          },
          {
            leverage: 0.798,
            order_type: "LONG",
            order_uuid: "1cfc3d5e-f6d8-40ff-81cf-95e1f2fda79b",
            price: 173.2135,
            price_sources: [],
            processed_ms: 1719813303925,
          },
          {
            leverage: 1.4364000000000001,
            order_type: "LONG",
            order_uuid: "962deb54-18c4-4cd7-8f9c-58c09e0bfc14",
            price: 173.1585,
            price_sources: [],
            processed_ms: 1719813335844,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "91bcd64b-da3e-4ccb-a72b-a251a0b8eccf",
            price: 173.225,
            price_sources: [],
            processed_ms: 1719813520162,
          },
        ],
        position_type: "FLAT",
        position_uuid: "3c9baa61-6008-41ff-baf5-90d50a7e8e11",
        return_at_close: 1.000178312879219,
        trade_pair: ["EURJPY", "EUR/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 173.22938095238095,
        close_ms: 1719813669992,
        current_return: 1.0000475692900976,
        initial_entry_price: 173.221,
        is_closed_position: true,
        miner_hotkey: "5D5GDbEa5Aedt9fXhWTzngDTANvnf8Q5poSFKYkU9hEZ7P8C",
        net_leverage: 0.0,
        open_ms: 1719813550277,
        orders: [
          {
            leverage: 0.2,
            order_type: "LONG",
            order_uuid: "65917506-35c5-47c4-8209-9a50a6ecb170",
            price: 173.221,
            price_sources: [],
            processed_ms: 1719813549989,
          },
          {
            leverage: 0.22000000000000003,
            order_type: "LONG",
            order_uuid: "2d3a0aed-fef9-458f-a02d-29844de7801d",
            price: 173.237,
            price_sources: [],
            processed_ms: 1719813609948,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "0757e7a8-9adf-4356-a5db-b6ed9dbcd5ba",
            price: 173.249,
            price_sources: [],
            processed_ms: 1719813669992,
          },
        ],
        position_type: "FLAT",
        position_uuid: "d4607e9e-2afd-424f-a5c3-49ac5915d2cc",
        return_at_close: 1.0000181678915605,
        trade_pair: ["EURJPY", "EUR/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 173.25549999999998,
        close_ms: 1719815187822,
        current_return: 1.0001621882133611,
        initial_entry_price: 173.25549999999998,
        is_closed_position: true,
        miner_hotkey: "5D5GDbEa5Aedt9fXhWTzngDTANvnf8Q5poSFKYkU9hEZ7P8C",
        net_leverage: 0.0,
        open_ms: 1719813700096,
        orders: [
          {
            leverage: 0.2,
            order_type: "LONG",
            order_uuid: "8d59de1d-1c38-4918-a3b7-5ec76f627db6",
            price: 173.25549999999998,
            price_sources: [],
            processed_ms: 1719813699795,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "973960ce-360f-4055-b21f-9f4398dab49d",
            price: 173.396,
            price_sources: [],
            processed_ms: 1719815187822,
          },
        ],
        position_type: "FLAT",
        position_uuid: "c621ffae-85ce-4083-b716-00a2fb108a19",
        return_at_close: 1.0001481859427261,
        trade_pair: ["EURJPY", "EUR/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 173.40000000000003,
        close_ms: 1719815438251,
        current_return: 1.0000945686458806,
        initial_entry_price: 173.419,
        is_closed_position: true,
        miner_hotkey: "5D5GDbEa5Aedt9fXhWTzngDTANvnf8Q5poSFKYkU9hEZ7P8C",
        net_leverage: 0.0,
        open_ms: 1719815216129,
        orders: [
          {
            leverage: 0.2,
            order_type: "LONG",
            order_uuid: "b9c3466a-bb63-431e-8d64-40728e403597",
            price: 173.419,
            price_sources: [],
            processed_ms: 1719815216008,
          },
          {
            leverage: 0.2,
            order_type: "LONG",
            order_uuid: "ebdc8547-0757-4156-af47-8732b09d7148",
            price: 173.381,
            price_sources: [],
            processed_ms: 1719815245959,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "e4cb82fb-3a3b-40b9-b7dc-a6da5f0793da",
            price: 173.441,
            price_sources: [],
            processed_ms: 1719815438251,
          },
        ],
        position_type: "FLAT",
        position_uuid: "4edb765a-30f3-4f08-b7d7-638f15290f03",
        return_at_close: 1.0000665659979586,
        trade_pair: ["EURJPY", "EUR/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 173.27199868091282,
        close_ms: 1719816525126,
        current_return: 1.0006121081405273,
        initial_entry_price: 173.404,
        is_closed_position: true,
        miner_hotkey: "5D5GDbEa5Aedt9fXhWTzngDTANvnf8Q5poSFKYkU9hEZ7P8C",
        net_leverage: 0.0,
        open_ms: 1719815468021,
        orders: [
          {
            leverage: 0.2,
            order_type: "LONG",
            order_uuid: "626ae483-18c6-4cc8-8e9e-e4be41f04962",
            price: 173.404,
            price_sources: [],
            processed_ms: 1719815467902,
          },
          {
            leverage: 0.2,
            order_type: "LONG",
            order_uuid: "0759580f-b8d8-4456-b548-a1065c336ecc",
            price: 173.387,
            price_sources: [],
            processed_ms: 1719815507961,
          },
          {
            leverage: 0.4,
            order_type: "LONG",
            order_uuid: "05226207-07e0-46a3-b8c2-f2970a4f85f8",
            price: 173.309,
            price_sources: [],
            processed_ms: 1719816131879,
          },
          {
            leverage: 0.8800000000000001,
            order_type: "LONG",
            order_uuid: "8e30e24a-deb7-4b43-a184-1ed617596682",
            price: 173.309,
            price_sources: [],
            processed_ms: 1719816192972,
          },
          {
            leverage: 1.5120000000000002,
            order_type: "LONG",
            order_uuid: "abd130c4-5600-4176-ba44-6d6edd6f760e",
            price: 173.284,
            price_sources: [],
            processed_ms: 1719816291164,
          },
          {
            leverage: 2.8728000000000002,
            order_type: "LONG",
            order_uuid: "fb95d097-c212-4109-b768-1b20a903582e",
            price: 173.232,
            price_sources: [],
            processed_ms: 1719816395016,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "ae9c2e8b-e6a5-4607-a9da-839ad5a5a7c2",
            price: 173.2895,
            price_sources: [],
            processed_ms: 1719816525126,
          },
        ],
        position_type: "FLAT",
        position_uuid: "31efb993-d1ce-4afd-afdc-b48d10748f40",
        return_at_close: 1.0001873122785856,
        trade_pair: ["EURJPY", "EUR/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 173.28984210526318,
        close_ms: 1719817350066,
        current_return: 1.0001022972158193,
        initial_entry_price: 173.3185,
        is_closed_position: true,
        miner_hotkey: "5D5GDbEa5Aedt9fXhWTzngDTANvnf8Q5poSFKYkU9hEZ7P8C",
        net_leverage: 0.0,
        open_ms: 1719816555017,
        orders: [
          {
            leverage: 0.2,
            order_type: "LONG",
            order_uuid: "760f62a3-5bac-48a1-acfc-cf7fb7b410fb",
            price: 173.3185,
            price_sources: [],
            processed_ms: 1719816554906,
          },
          {
            leverage: 0.18000000000000002,
            order_type: "LONG",
            order_uuid: "77942c62-8425-4e26-8936-bbeef6fac3f2",
            price: 173.258,
            price_sources: [],
            processed_ms: 1719817206556,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "c7e8bc82-e1ec-4845-8886-8abd92a992c7",
            price: 173.3365,
            price_sources: [],
            processed_ms: 1719817350066,
          },
        ],
        position_type: "FLAT",
        position_uuid: "ec08090c-7e26-4f99-811f-fee3e405be1f",
        return_at_close: 1.0000756944947133,
        trade_pair: ["EURJPY", "EUR/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 173.26535734072024,
        close_ms: 1719817567966,
        current_return: 1.0002677759091683,
        initial_entry_price: 173.3315,
        is_closed_position: true,
        miner_hotkey: "5D5GDbEa5Aedt9fXhWTzngDTANvnf8Q5poSFKYkU9hEZ7P8C",
        net_leverage: 0.0,
        open_ms: 1719817380037,
        orders: [
          {
            leverage: 0.2,
            order_type: "LONG",
            order_uuid: "fadef001-d058-4013-8f43-5130f5892370",
            price: 173.3315,
            price_sources: [],
            processed_ms: 1719817379816,
          },
          {
            leverage: 0.2,
            order_type: "LONG",
            order_uuid: "6732bc48-c86a-4c95-b2c3-5e5ed0a4f769",
            price: 173.299,
            price_sources: [],
            processed_ms: 1719817456005,
          },
          {
            leverage: 0.36000000000000004,
            order_type: "LONG",
            order_uuid: "ec360620-8f22-4105-bdff-be2fee6dc5cd",
            price: 173.2695,
            price_sources: [],
            processed_ms: 1719817485716,
          },
          {
            leverage: 0.684,
            order_type: "LONG",
            order_uuid: "a5265194-0d1e-4ccb-85f2-5e5accd473e0",
            price: 173.234,
            price_sources: [],
            processed_ms: 1719817538080,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "5e96c09c-e63e-4e54-89f9-61f3d3f17a2d",
            price: 173.2975,
            price_sources: [],
            processed_ms: 1719817567966,
          },
        ],
        position_type: "FLAT",
        position_uuid: "0a2bdcac-d0d4-4b06-a549-29e002b25afc",
        return_at_close: 1.0001666688423794,
        trade_pair: ["EURJPY", "EUR/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 173.28099999999998,
        close_ms: 1719817844163,
        current_return: 1.0001269533504145,
        initial_entry_price: 173.292,
        is_closed_position: true,
        miner_hotkey: "5D5GDbEa5Aedt9fXhWTzngDTANvnf8Q5poSFKYkU9hEZ7P8C",
        net_leverage: 0.0,
        open_ms: 1719817597819,
        orders: [
          {
            leverage: 0.2,
            order_type: "LONG",
            order_uuid: "40b09714-18bc-4652-9a92-b6511d298793",
            price: 173.292,
            price_sources: [],
            processed_ms: 1719817597737,
          },
          {
            leverage: 0.2,
            order_type: "LONG",
            order_uuid: "bffa2d9f-c803-4516-ba81-e92182bbb9b5",
            price: 173.27,
            price_sources: [],
            processed_ms: 1719817627753,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "578254bf-560c-4d1a-b223-d7a8bec98764",
            price: 173.336,
            price_sources: [],
            processed_ms: 1719817844163,
          },
        ],
        position_type: "FLAT",
        position_uuid: "6d6dd830-d4c8-46b5-bd3f-b028e1495c5b",
        return_at_close: 1.0000989497957207,
        trade_pair: ["EURJPY", "EUR/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 173.30068988260126,
        close_ms: 1719818596558,
        current_return: 0.9999677290779199,
        initial_entry_price: 173.3635,
        is_closed_position: true,
        miner_hotkey: "5D5GDbEa5Aedt9fXhWTzngDTANvnf8Q5poSFKYkU9hEZ7P8C",
        net_leverage: 0.0,
        open_ms: 1719817875181,
        orders: [
          {
            leverage: 0.2,
            order_type: "LONG",
            order_uuid: "41a50ade-964a-44a9-a3d5-cae1209d75a4",
            price: 173.3635,
            price_sources: [],
            processed_ms: 1719817875000,
          },
          {
            leverage: 0.18000000000000002,
            order_type: "LONG",
            order_uuid: "5a348fb3-a28a-4235-9c71-200b58cf6189",
            price: 173.352,
            price_sources: [],
            processed_ms: 1719817990048,
          },
          {
            leverage: 0.342,
            order_type: "LONG",
            order_uuid: "349d64be-1f79-4932-b9a5-b9e7db8b219c",
            price: 173.285,
            price_sources: [],
            processed_ms: 1719818119980,
          },
          {
            leverage: 0.7942,
            order_type: "LONG",
            order_uuid: "690624c7-7a31-406a-8421-0d0373b85983",
            price: 173.28,
            price_sources: [],
            processed_ms: 1719818284106,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "65d67f58-52d1-4bf8-a23c-54ed29c40ade",
            price: 173.297,
            price_sources: [],
            processed_ms: 1719818596558,
          },
        ],
        position_type: "FLAT",
        position_uuid: "f07f16a6-dd07-487d-9f03-bbd1e6332bdb",
        return_at_close: 0.9998615985029619,
        trade_pair: ["EURJPY", "EUR/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 173.24040789473685,
        close_ms: 1719818962201,
        current_return: 0.9999920363327891,
        initial_entry_price: 173.287,
        is_closed_position: true,
        miner_hotkey: "5D5GDbEa5Aedt9fXhWTzngDTANvnf8Q5poSFKYkU9hEZ7P8C",
        net_leverage: 0.0,
        open_ms: 1719818626019,
        orders: [
          {
            leverage: 0.2,
            order_type: "LONG",
            order_uuid: "9f67975a-2e53-435b-bbf9-6bd71306cf88",
            price: 173.287,
            price_sources: [],
            processed_ms: 1719818625847,
          },
          {
            leverage: 0.2,
            order_type: "LONG",
            order_uuid: "a3fda072-27ce-455a-914b-07b24641e47d",
            price: 173.247,
            price_sources: [],
            processed_ms: 1719818713843,
          },
          {
            leverage: 0.36000000000000004,
            order_type: "LONG",
            order_uuid: "b79ab541-469c-48c9-85c9-462bfd5ec8e2",
            price: 173.236,
            price_sources: [],
            processed_ms: 1719818839986,
          },
          {
            leverage: 0.76,
            order_type: "LONG",
            order_uuid: "edad7fad-d7af-4630-8c39-97db898b549b",
            price: 173.2285,
            price_sources: [],
            processed_ms: 1719818877949,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "3f74ce65-3649-41a2-b53e-e9680c377018",
            price: 173.23950000000002,
            price_sources: [],
            processed_ms: 1719818962201,
          },
        ],
        position_type: "FLAT",
        position_uuid: "c405d2d8-afb2-4c1f-8ed4-b84ca3308e5a",
        return_at_close: 0.9998856371801234,
        trade_pair: ["EURJPY", "EUR/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 173.21712406015038,
        close_ms: 1719819466687,
        current_return: 0.9999988571395586,
        initial_entry_price: 173.2495,
        is_closed_position: true,
        miner_hotkey: "5D5GDbEa5Aedt9fXhWTzngDTANvnf8Q5poSFKYkU9hEZ7P8C",
        net_leverage: 0.0,
        open_ms: 1719818992105,
        orders: [
          {
            leverage: 0.2,
            order_type: "LONG",
            order_uuid: "8df378dc-40d4-49a7-8fb0-c5947bada685",
            price: 173.2495,
            price_sources: [],
            processed_ms: 1719818991874,
          },
          {
            leverage: 0.18000000000000002,
            order_type: "LONG",
            order_uuid: "4292f4d9-1ac8-4c4f-bd94-6bf2e8fcfed0",
            price: 173.262,
            price_sources: [],
            processed_ms: 1719819039912,
          },
          {
            leverage: 0.41800000000000004,
            order_type: "LONG",
            order_uuid: "4c9d2d0f-f9ad-4da7-b3a1-0b0183674e31",
            price: 173.194,
            price_sources: [],
            processed_ms: 1719819362372,
          },
          {
            leverage: 0.798,
            order_type: "LONG",
            order_uuid: "e2714ca9-d37c-4322-a59f-5483e75e5671",
            price: 173.211,
            price_sources: [],
            processed_ms: 1719819436373,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "21d1af5f-8c1b-4faa-9b0c-11f7997524be",
            price: 173.21699999999998,
            price_sources: [],
            processed_ms: 1719819466687,
          },
        ],
        position_type: "FLAT",
        position_uuid: "96ccacb9-faa0-4d52-a65b-025153fa7250",
        return_at_close: 0.999887137267239,
        trade_pair: ["EURJPY", "EUR/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 173.1237583432265,
        close_ms: 1719820655820,
        current_return: 1.0003235420094085,
        initial_entry_price: 173.235,
        is_closed_position: true,
        miner_hotkey: "5D5GDbEa5Aedt9fXhWTzngDTANvnf8Q5poSFKYkU9hEZ7P8C",
        net_leverage: 0.0,
        open_ms: 1719819496567,
        orders: [
          {
            leverage: 0.2,
            order_type: "LONG",
            order_uuid: "e2903c30-f2c2-4a1d-a8ae-b343a15e40de",
            price: 173.235,
            price_sources: [],
            processed_ms: 1719819496114,
          },
          {
            leverage: 0.2,
            order_type: "LONG",
            order_uuid: "21f52a6c-246a-4415-9f8c-9ed5c438e8c2",
            price: 173.209,
            price_sources: [],
            processed_ms: 1719819694360,
          },
          {
            leverage: 0.36000000000000004,
            order_type: "LONG",
            order_uuid: "eda23f19-69df-47e3-b101-f0edbaf6eda3",
            price: 173.179,
            price_sources: [],
            processed_ms: 1719819793932,
          },
          {
            leverage: 0.684,
            order_type: "LONG",
            order_uuid: "a8acc489-d0ab-48b7-a318-314bdc6bcb8f",
            price: 173.161,
            price_sources: [],
            processed_ms: 1719820336933,
          },
          {
            leverage: 1.444,
            order_type: "LONG",
            order_uuid: "e23ed2aa-d87a-415a-98e9-5a184611506b",
            price: 173.102,
            price_sources: [],
            processed_ms: 1719820466093,
          },
          {
            leverage: 3.1768,
            order_type: "LONG",
            order_uuid: "859b5076-9470-403e-aaea-4c41c10ce28e",
            price: 173.107,
            price_sources: [],
            processed_ms: 1719820592108,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "5bfc1461-62e6-4beb-ae4c-c610957c7eed",
            price: 173.133,
            price_sources: [],
            processed_ms: 1719820655820,
          },
        ],
        position_type: "FLAT",
        position_uuid: "726d0138-cc07-4451-ae46-066604fb3a1b",
        return_at_close: 0.999898868654178,
        trade_pair: ["EURJPY", "EUR/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 173.12289473684208,
        close_ms: 1719821621680,
        current_return: 1.0001341118985325,
        initial_entry_price: 173.139,
        is_closed_position: true,
        miner_hotkey: "5D5GDbEa5Aedt9fXhWTzngDTANvnf8Q5poSFKYkU9hEZ7P8C",
        net_leverage: 0.0,
        open_ms: 1719820687376,
        orders: [
          {
            leverage: 0.2,
            order_type: "LONG",
            order_uuid: "ef79a1b7-fa75-491d-9c92-b6a35fcc2d16",
            price: 173.139,
            price_sources: [],
            processed_ms: 1719820685745,
          },
          {
            leverage: 0.18000000000000002,
            order_type: "LONG",
            order_uuid: "026d41b9-ccd5-498e-9d0a-fcf7eb6db904",
            price: 173.105,
            price_sources: [],
            processed_ms: 1719821543526,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "449f50bd-b754-4938-a6f0-4eba1e1dbdf1",
            price: 173.184,
            price_sources: [],
            processed_ms: 1719821621680,
          },
        ],
        position_type: "FLAT",
        position_uuid: "20b23bef-b329-4d09-85b2-23d3d39cf2a6",
        return_at_close: 1.0001075083311561,
        trade_pair: ["EURJPY", "EUR/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 173.1865,
        close_ms: 1719822454342,
        current_return: 1.0001564787093682,
        initial_entry_price: 173.18650000000002,
        is_closed_position: true,
        miner_hotkey: "5D5GDbEa5Aedt9fXhWTzngDTANvnf8Q5poSFKYkU9hEZ7P8C",
        net_leverage: 0.0,
        open_ms: 1719821652266,
        orders: [
          {
            leverage: 0.2,
            order_type: "LONG",
            order_uuid: "5451cd7e-f919-4a77-83b5-ef6bd8880c43",
            price: 173.18650000000002,
            price_sources: [],
            processed_ms: 1719821652098,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "6baa61f7-5391-4bdd-a29d-6c8159e34a05",
            price: 173.322,
            price_sources: [],
            processed_ms: 1719822454342,
          },
        ],
        position_type: "FLAT",
        position_uuid: "e5300887-c634-4dc3-a908-117ea49f3cdd",
        return_at_close: 1.0001424765186664,
        trade_pair: ["EURJPY", "EUR/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 173.27890977443607,
        close_ms: 1719823184271,
        current_return: 1.0001937929482632,
        initial_entry_price: 173.319,
        is_closed_position: true,
        miner_hotkey: "5D5GDbEa5Aedt9fXhWTzngDTANvnf8Q5poSFKYkU9hEZ7P8C",
        net_leverage: 0.0,
        open_ms: 1719822484248,
        orders: [
          {
            leverage: 0.2,
            order_type: "LONG",
            order_uuid: "e7d352ee-f833-4580-a415-f22ff1eb7687",
            price: 173.319,
            price_sources: [],
            processed_ms: 1719822483940,
          },
          {
            leverage: 0.18000000000000002,
            order_type: "LONG",
            order_uuid: "ff079d06-1211-4f53-bd22-141fc42b9d2f",
            price: 173.3015,
            price_sources: [],
            processed_ms: 1719822563143,
          },
          {
            leverage: 0.41800000000000004,
            order_type: "LONG",
            order_uuid: "b9f4d70a-7255-4d72-afd6-ae00cd78be40",
            price: 173.25,
            price_sources: [],
            processed_ms: 1719822954663,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "caaf1d75-ccaf-4e5b-9028-43551b9d5a59",
            price: 173.321,
            price_sources: [],
            processed_ms: 1719823184271,
          },
        ],
        position_type: "FLAT",
        position_uuid: "12fb8b1e-877d-45a4-a23e-2a6d0cca314e",
        return_at_close: 1.0001379221229891,
        trade_pair: ["EURJPY", "EUR/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 173.30599999999998,
        close_ms: 1719823484219,
        current_return: 1.0000946319452058,
        initial_entry_price: 173.303,
        is_closed_position: true,
        miner_hotkey: "5D5GDbEa5Aedt9fXhWTzngDTANvnf8Q5poSFKYkU9hEZ7P8C",
        net_leverage: 0.0,
        open_ms: 1719823214147,
        orders: [
          {
            leverage: 0.2,
            order_type: "LONG",
            order_uuid: "d7e537c6-4ef6-4f50-9efe-0ce646362f17",
            price: 173.303,
            price_sources: [],
            processed_ms: 1719823213925,
          },
          {
            leverage: 0.2,
            order_type: "LONG",
            order_uuid: "c92fca53-8579-429e-8ae8-904c079f6e5d",
            price: 173.309,
            price_sources: [],
            processed_ms: 1719823342104,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "b4d7f25e-e51b-4874-be1e-2fc47e561abd",
            price: 173.347,
            price_sources: [],
            processed_ms: 1719823484219,
          },
        ],
        position_type: "FLAT",
        position_uuid: "89ccc8d2-8922-45b0-a677-2391d5bec15a",
        return_at_close: 1.0000666292955112,
        trade_pair: ["EURJPY", "EUR/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 173.26048468393208,
        close_ms: 1719825047126,
        current_return: 1.000193169964897,
        initial_entry_price: 173.3505,
        is_closed_position: true,
        miner_hotkey: "5D5GDbEa5Aedt9fXhWTzngDTANvnf8Q5poSFKYkU9hEZ7P8C",
        net_leverage: 0.0,
        open_ms: 1719823514091,
        orders: [
          {
            leverage: 0.2,
            order_type: "LONG",
            order_uuid: "b1e969ff-0c0b-4dbf-95b0-a6f67d9a2fa1",
            price: 173.3505,
            price_sources: [],
            processed_ms: 1719823513860,
          },
          {
            leverage: 0.22000000000000003,
            order_type: "LONG",
            order_uuid: "d624dea0-b11f-441e-a929-22fb9ca2f252",
            price: 173.3365,
            price_sources: [],
            processed_ms: 1719824575654,
          },
          {
            leverage: 0.37800000000000006,
            order_type: "LONG",
            order_uuid: "a31d001f-8860-492a-b4ad-4fb865033670",
            price: 173.309,
            price_sources: [],
            processed_ms: 1719824642867,
          },
          {
            leverage: 0.8778000000000001,
            order_type: "LONG",
            order_uuid: "e4048dc4-5b69-43d4-a21c-6f251d7bbfb7",
            price: 173.263,
            price_sources: [],
            processed_ms: 1719824933350,
          },
          {
            leverage: 1.8433800000000002,
            order_type: "LONG",
            order_uuid: "06240d85-56f4-463d-9f99-944bbe82575a",
            price: 173.2305,
            price_sources: [],
            processed_ms: 1719825007271,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "561a4340-fb54-4142-88be-e9e82ae6fdd3",
            price: 173.26999999999998,
            price_sources: [],
            processed_ms: 1719825047126,
          },
        ],
        position_type: "FLAT",
        position_uuid: "a4642cb4-de2d-4fb8-ad8a-dd1b67eac500",
        return_at_close: 0.9999467797789056,
        trade_pair: ["EURJPY", "EUR/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 173.2700714285714,
        close_ms: 1719825373252,
        current_return: 1.0002177726485864,
        initial_entry_price: 173.3,
        is_closed_position: true,
        miner_hotkey: "5D5GDbEa5Aedt9fXhWTzngDTANvnf8Q5poSFKYkU9hEZ7P8C",
        net_leverage: 0.0,
        open_ms: 1719825077080,
        orders: [
          {
            leverage: 0.2,
            order_type: "LONG",
            order_uuid: "b1e9c7ee-a857-40c9-8641-32011972a7d5",
            price: 173.3,
            price_sources: [],
            processed_ms: 1719825076838,
          },
          {
            leverage: 0.2,
            order_type: "LONG",
            order_uuid: "2fd124a9-bbd7-42b9-ad17-da77eec0563a",
            price: 173.2755,
            price_sources: [],
            processed_ms: 1719825195052,
          },
          {
            leverage: 0.44000000000000006,
            order_type: "LONG",
            order_uuid: "630cd127-6ae2-49e8-9a27-ab86d636e908",
            price: 173.254,
            price_sources: [],
            processed_ms: 1719825224735,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "49afe6cd-81a2-4b2d-8e46-69ee3627b776",
            price: 173.315,
            price_sources: [],
            processed_ms: 1719825373252,
          },
        ],
        position_type: "FLAT",
        position_uuid: "69e9427c-a04c-4619-b574-cb3e07ad8757",
        return_at_close: 1.0001589598435545,
        trade_pair: ["EURJPY", "EUR/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 173.31074999999998,
        close_ms: 1719826348757,
        current_return: 1.0000905854590145,
        initial_entry_price: 173.317,
        is_closed_position: true,
        miner_hotkey: "5D5GDbEa5Aedt9fXhWTzngDTANvnf8Q5poSFKYkU9hEZ7P8C",
        net_leverage: 0.0,
        open_ms: 1719825403084,
        orders: [
          {
            leverage: 0.2,
            order_type: "LONG",
            order_uuid: "a86458f8-d0a5-46e2-b467-e932b74af615",
            price: 173.317,
            price_sources: [],
            processed_ms: 1719825402790,
          },
          {
            leverage: 0.2,
            order_type: "LONG",
            order_uuid: "1fd8f9da-ab4b-4d11-92c2-793082539f99",
            price: 173.3045,
            price_sources: [],
            processed_ms: 1719826278351,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "71b8b3fc-9e0f-4708-99fa-b0063ca70041",
            price: 173.35,
            price_sources: [],
            processed_ms: 1719826348757,
          },
        ],
        position_type: "FLAT",
        position_uuid: "0f5be5c9-6e57-4cfd-8afe-e2e733f3f1c6",
        return_at_close: 1.0000625829226217,
        trade_pair: ["EURJPY", "EUR/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 173.32965789473687,
        close_ms: 1719826583996,
        current_return: 1.0000555565170737,
        initial_entry_price: 173.337,
        is_closed_position: true,
        miner_hotkey: "5D5GDbEa5Aedt9fXhWTzngDTANvnf8Q5poSFKYkU9hEZ7P8C",
        net_leverage: 0.0,
        open_ms: 1719826378220,
        orders: [
          {
            leverage: 0.2,
            order_type: "LONG",
            order_uuid: "3f344352-6d0e-4afd-9e39-ec0bee0446b5",
            price: 173.337,
            price_sources: [],
            processed_ms: 1719826378048,
          },
          {
            leverage: 0.18000000000000002,
            order_type: "LONG",
            order_uuid: "b4d44598-57e3-42da-ac7f-b924ec85f8df",
            price: 173.32150000000001,
            price_sources: [],
            processed_ms: 1719826535943,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "c19eaac6-b921-45e9-bdbf-7f9680a56f65",
            price: 173.355,
            price_sources: [],
            processed_ms: 1719826583996,
          },
        ],
        position_type: "FLAT",
        position_uuid: "3de69787-f175-43a4-b5ef-6838dad29bf2",
        return_at_close: 1.0000289550392703,
        trade_pair: ["EURJPY", "EUR/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 173.25518416969743,
        close_ms: 1719827530244,
        current_return: 1.000354766640324,
        initial_entry_price: 173.35899999999998,
        is_closed_position: true,
        miner_hotkey: "5D5GDbEa5Aedt9fXhWTzngDTANvnf8Q5poSFKYkU9hEZ7P8C",
        net_leverage: 0.0,
        open_ms: 1719826614394,
        orders: [
          {
            leverage: 0.2,
            order_type: "LONG",
            order_uuid: "bf97664f-8363-43db-aeb4-dcc079b79e0e",
            price: 173.35899999999998,
            price_sources: [],
            processed_ms: 1719826614070,
          },
          {
            leverage: 0.18000000000000002,
            order_type: "LONG",
            order_uuid: "4372ba9d-19ad-4e85-a25c-f61769101a69",
            price: 173.349,
            price_sources: [],
            processed_ms: 1719826654180,
          },
          {
            leverage: 0.41800000000000004,
            order_type: "LONG",
            order_uuid: "49dbdbd8-a5de-47ca-b433-d1059a79648c",
            price: 173.283,
            price_sources: [],
            processed_ms: 1719826932220,
          },
          {
            leverage: 0.8778000000000001,
            order_type: "LONG",
            order_uuid: "4118364d-8f41-47cc-996b-90ba25d23e09",
            price: 173.2595,
            price_sources: [],
            processed_ms: 1719827033826,
          },
          {
            leverage: 1.5082200000000001,
            order_type: "LONG",
            order_uuid: "17882ac9-d7b2-44b6-9a23-a7ea82a1d70e",
            price: 173.22,
            price_sources: [],
            processed_ms: 1719827258108,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "80cc4ece-e431-44d7-94c2-12f2882b6ce7",
            price: 173.2745,
            price_sources: [],
            processed_ms: 1719827530244,
          },
        ],
        position_type: "FLAT",
        position_uuid: "589ab7c0-b2ae-43be-bbc1-fb12e27eee61",
        return_at_close: 1.0001318061694386,
        trade_pair: ["EURJPY", "EUR/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 173.18080725623582,
        close_ms: 1719828469812,
        current_return: 0.9998817581605802,
        initial_entry_price: 173.272,
        is_closed_position: true,
        miner_hotkey: "5D5GDbEa5Aedt9fXhWTzngDTANvnf8Q5poSFKYkU9hEZ7P8C",
        net_leverage: 0.0,
        open_ms: 1719827560336,
        orders: [
          {
            leverage: 0.2,
            order_type: "LONG",
            order_uuid: "f7230b42-6095-411f-901b-cdf1535827f4",
            price: 173.272,
            price_sources: [],
            processed_ms: 1719827560081,
          },
          {
            leverage: 0.2,
            order_type: "LONG",
            order_uuid: "ffe477a4-43dd-400f-98a0-21773c9c0fa1",
            price: 173.25349999999997,
            price_sources: [],
            processed_ms: 1719827635951,
          },
          {
            leverage: 0.44000000000000006,
            order_type: "LONG",
            order_uuid: "122d75bc-059f-49c5-8e06-f8eb017d83cf",
            price: 173.213,
            price_sources: [],
            processed_ms: 1719828100548,
          },
          {
            leverage: 0.9240000000000002,
            order_type: "LONG",
            order_uuid: "9995ed75-2532-49d5-b699-00112947f270",
            price: 173.185,
            price_sources: [],
            processed_ms: 1719828154153,
          },
          {
            leverage: 1.7640000000000002,
            order_type: "LONG",
            order_uuid: "92a191a6-9f93-4b52-b3fe-930c27bdd044",
            price: 173.152,
            price_sources: [],
            processed_ms: 1719828408364,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "60b5680c-4f15-4f2a-9538-72819a282f9c",
            price: 173.175,
            price_sources: [],
            processed_ms: 1719828469812,
          },
        ],
        position_type: "FLAT",
        position_uuid: "91737391-2dbc-42fd-ab97-b600c3b16218",
        return_at_close: 0.9996348273615848,
        trade_pair: ["EURJPY", "EUR/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 173.14684903047092,
        close_ms: 1719828968035,
        current_return: 1.0000512832356592,
        initial_entry_price: 173.195,
        is_closed_position: true,
        miner_hotkey: "5D5GDbEa5Aedt9fXhWTzngDTANvnf8Q5poSFKYkU9hEZ7P8C",
        net_leverage: 0.0,
        open_ms: 1719828500921,
        orders: [
          {
            leverage: 0.2,
            order_type: "LONG",
            order_uuid: "21b4e515-1178-4e30-8ec3-7a16c196093e",
            price: 173.195,
            price_sources: [],
            processed_ms: 1719828500664,
          },
          {
            leverage: 0.18000000000000002,
            order_type: "LONG",
            order_uuid: "26cbdb60-9435-4e5d-8160-f4c22a374279",
            price: 173.175,
            price_sources: [],
            processed_ms: 1719828556006,
          },
          {
            leverage: 0.38,
            order_type: "LONG",
            order_uuid: "2ab6f63d-393f-4f39-86fa-b4f4edf7adef",
            price: 173.14749999999998,
            price_sources: [],
            processed_ms: 1719828828311,
          },
          {
            leverage: 0.684,
            order_type: "LONG",
            order_uuid: "a2773076-e1c0-45aa-a4af-42e7f769bf81",
            price: 173.125,
            price_sources: [],
            processed_ms: 1719828895960,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "ebc5d7c7-168a-49db-a1c0-b3c41bc37bbb",
            price: 173.153,
            price_sources: [],
            processed_ms: 1719828968035,
          },
        ],
        position_type: "FLAT",
        position_uuid: "d67b89e6-5207-404b-8a80-76e08793fd44",
        return_at_close: 0.9999501980519497,
        trade_pair: ["EURJPY", "EUR/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 173.15314285714285,
        close_ms: 1719830930193,
        current_return: 1.000094247549968,
        initial_entry_price: 173.161,
        is_closed_position: true,
        miner_hotkey: "5D5GDbEa5Aedt9fXhWTzngDTANvnf8Q5poSFKYkU9hEZ7P8C",
        net_leverage: 0.0,
        open_ms: 1719828998298,
        orders: [
          {
            leverage: 0.2,
            order_type: "LONG",
            order_uuid: "78ced5d6-c189-4201-852f-03c6987ac040",
            price: 173.161,
            price_sources: [],
            processed_ms: 1719828997832,
          },
          {
            leverage: 0.22000000000000003,
            order_type: "LONG",
            order_uuid: "d6f18376-bb86-4f15-b845-80a659ad200c",
            price: 173.146,
            price_sources: [],
            processed_ms: 1719830642853,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "9146c4d9-5c73-4d82-b6de-6d2732698d25",
            price: 173.192,
            price_sources: [],
            processed_ms: 1719830930193,
          },
        ],
        position_type: "FLAT",
        position_uuid: "409668a2-19b8-40fe-b324-bfe0dc6c78c7",
        return_at_close: 1.00006484477909,
        trade_pair: ["EURJPY", "EUR/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 173.1742894736842,
        close_ms: 1719831998180,
        current_return: 1.0000250568686997,
        initial_entry_price: 173.206,
        is_closed_position: true,
        miner_hotkey: "5D5GDbEa5Aedt9fXhWTzngDTANvnf8Q5poSFKYkU9hEZ7P8C",
        net_leverage: 0.0,
        open_ms: 1719830960657,
        orders: [
          {
            leverage: 0.2,
            order_type: "LONG",
            order_uuid: "f9348048-cf40-4eb4-a820-14573396eb4a",
            price: 173.206,
            price_sources: [],
            processed_ms: 1719830960448,
          },
          {
            leverage: 0.2,
            order_type: "LONG",
            order_uuid: "d00c7ca2-0f05-4fea-b330-55b53ef4babd",
            price: 173.1935,
            price_sources: [],
            processed_ms: 1719831336406,
          },
          {
            leverage: 0.36000000000000004,
            order_type: "LONG",
            order_uuid: "01278282-61a5-4f4a-b435-2bb1e4e264c6",
            price: 173.146,
            price_sources: [],
            processed_ms: 1719831898694,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "798e4ede-e9b3-4277-875c-1b5b47467f58",
            price: 173.18,
            price_sources: [],
            processed_ms: 1719831998180,
          },
        ],
        position_type: "FLAT",
        position_uuid: "42235c96-a765-4636-8854-e72f420ae582",
        return_at_close: 0.9999718555356742,
        trade_pair: ["EURJPY", "EUR/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 173.1543074792244,
        close_ms: 1719832802876,
        current_return: 1.000119630874421,
        initial_entry_price: 173.166,
        is_closed_position: true,
        miner_hotkey: "5D5GDbEa5Aedt9fXhWTzngDTANvnf8Q5poSFKYkU9hEZ7P8C",
        net_leverage: 0.0,
        open_ms: 1719832028085,
        orders: [
          {
            leverage: 0.2,
            order_type: "LONG",
            order_uuid: "2dc88f2a-c940-4cef-8053-6d0a1bc893e7",
            price: 173.166,
            price_sources: [],
            processed_ms: 1719832027962,
          },
          {
            leverage: 0.18000000000000002,
            order_type: "LONG",
            order_uuid: "e76f2953-fb91-4957-bef6-2d21eb47f95d",
            price: 173.159,
            price_sources: [],
            processed_ms: 1719832659036,
          },
          {
            leverage: 0.342,
            order_type: "LONG",
            order_uuid: "d2edb96b-22a3-4e73-be8c-372f574581b5",
            price: 173.145,
            price_sources: [],
            processed_ms: 1719832733055,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "89034561-afae-4fb8-b5b0-6dc9ca62c458",
            price: 173.183,
            price_sources: [],
            processed_ms: 1719832802876,
          },
        ],
        position_type: "FLAT",
        position_uuid: "549b3f7e-e2e7-409a-90b8-80d0ddf09799",
        return_at_close: 1.0000690848282765,
        trade_pair: ["EURJPY", "EUR/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 173.16118421052633,
        close_ms: 1719833886583,
        current_return: 1.0001154884439376,
        initial_entry_price: 173.1775,
        is_closed_position: true,
        miner_hotkey: "5D5GDbEa5Aedt9fXhWTzngDTANvnf8Q5poSFKYkU9hEZ7P8C",
        net_leverage: 0.0,
        open_ms: 1719832833265,
        orders: [
          {
            leverage: 0.2,
            order_type: "LONG",
            order_uuid: "4c4cdccd-95e9-42b5-ada0-2d1896bbb92d",
            price: 173.1775,
            price_sources: [],
            processed_ms: 1719832832880,
          },
          {
            leverage: 0.2,
            order_type: "LONG",
            order_uuid: "c7039fba-d4a1-4aa3-b24f-75aa60f25b51",
            price: 173.165,
            price_sources: [],
            processed_ms: 1719832863006,
          },
          {
            leverage: 0.36000000000000004,
            order_type: "LONG",
            order_uuid: "a4ef9927-03e1-49c5-aa54-87cf2bb6d210",
            price: 173.15,
            price_sources: [],
            processed_ms: 1719832936924,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "4f20a38b-58b4-4129-b5aa-910872b91101",
            price: 173.1875,
            price_sources: [],
            processed_ms: 1719833886583,
          },
        ],
        position_type: "FLAT",
        position_uuid: "8c47b100-b7d3-4542-b78c-4ef3f1b83ec1",
        return_at_close: 1.0000622822999523,
        trade_pair: ["EURJPY", "EUR/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 173.1028949158611,
        close_ms: 1719834459934,
        current_return: 0.9999246214964598,
        initial_entry_price: 173.182,
        is_closed_position: true,
        miner_hotkey: "5D5GDbEa5Aedt9fXhWTzngDTANvnf8Q5poSFKYkU9hEZ7P8C",
        net_leverage: 0.0,
        open_ms: 1719833916701,
        orders: [
          {
            leverage: 0.2,
            order_type: "LONG",
            order_uuid: "4359ab45-95d7-47e5-914c-f20a5b003d68",
            price: 173.182,
            price_sources: [],
            processed_ms: 1719833916453,
          },
          {
            leverage: 0.22000000000000003,
            order_type: "LONG",
            order_uuid: "6a7b863b-80d9-435a-8016-a8601d7f8106",
            price: 173.185,
            price_sources: [],
            processed_ms: 1719833945951,
          },
          {
            leverage: 0.42000000000000004,
            order_type: "LONG",
            order_uuid: "68d6a889-c8d7-4c92-b27f-d311d839a60d",
            price: 173.151,
            price_sources: [],
            processed_ms: 1719834134194,
          },
          {
            leverage: 0.7560000000000001,
            order_type: "LONG",
            order_uuid: "27dfb815-dc9b-472f-a3e0-2b0d9a6a40f4",
            price: 173.088,
            price_sources: [],
            processed_ms: 1719834324055,
          },
          {
            leverage: 1.7556000000000003,
            order_type: "LONG",
            order_uuid: "c17ac3af-3fb9-4b3e-b371-56d9d6972d39",
            price: 173.07850000000002,
            price_sources: [],
            processed_ms: 1719834358031,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "1153de01-746f-44dd-a48b-4f3d09d03d43",
            price: 173.099,
            price_sources: [],
            processed_ms: 1719834459934,
          },
        ],
        position_type: "FLAT",
        position_uuid: "1b009b36-bb08-4d3d-9138-863e09b5a23e",
        return_at_close: 0.9996900271811613,
        trade_pair: ["EURJPY", "EUR/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 173.105,
        close_ms: 1719834945368,
        current_return: 1.0001409512476207,
        initial_entry_price: 173.10950000000003,
        is_closed_position: true,
        miner_hotkey: "5D5GDbEa5Aedt9fXhWTzngDTANvnf8Q5poSFKYkU9hEZ7P8C",
        net_leverage: 0.0,
        open_ms: 1719834490294,
        orders: [
          {
            leverage: 0.2,
            order_type: "LONG",
            order_uuid: "b58941dc-3629-4fd4-9c44-27cc7487b235",
            price: 173.10950000000003,
            price_sources: [],
            processed_ms: 1719834490095,
          },
          {
            leverage: 0.2,
            order_type: "LONG",
            order_uuid: "6c17aba9-9224-494e-b4a5-f62cd1cd2cb8",
            price: 173.1005,
            price_sources: [],
            processed_ms: 1719834535851,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "53498e6c-4cfe-4035-9eff-590d59aa73e4",
            price: 173.166,
            price_sources: [],
            processed_ms: 1719834945368,
          },
        ],
        position_type: "FLAT",
        position_uuid: "cd55a177-738e-4fb8-b15d-0c2a52a803e2",
        return_at_close: 1.0001129473009858,
        trade_pair: ["EURJPY", "EUR/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 173.14568421052633,
        close_ms: 1719836305632,
        current_return: 1.0001901033094838,
        initial_entry_price: 173.169,
        is_closed_position: true,
        miner_hotkey: "5D5GDbEa5Aedt9fXhWTzngDTANvnf8Q5poSFKYkU9hEZ7P8C",
        net_leverage: 0.0,
        open_ms: 1719834974415,
        orders: [
          {
            leverage: 0.2,
            order_type: "LONG",
            order_uuid: "968665b5-893f-4bcd-bca1-5383ac4307ae",
            price: 173.169,
            price_sources: [],
            processed_ms: 1719834974112,
          },
          {
            leverage: 0.18000000000000002,
            order_type: "LONG",
            order_uuid: "50890a5f-e18a-4308-8b71-1894ba2011fb",
            price: 173.174,
            price_sources: [],
            processed_ms: 1719835042330,
          },
          {
            leverage: 0.38,
            order_type: "LONG",
            order_uuid: "6a5dd75b-91b1-479c-8608-cecfbbc6cf14",
            price: 173.12,
            price_sources: [],
            processed_ms: 1719835931335,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "4942cf15-8a85-4619-b315-2035c77b77d0",
            price: 173.189,
            price_sources: [],
            processed_ms: 1719836305632,
          },
        ],
        position_type: "FLAT",
        position_uuid: "97bac4da-a9ff-487e-812b-f09739e3cb06",
        return_at_close: 1.0001368931959878,
        trade_pair: ["EURJPY", "EUR/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 173.19161904761904,
        close_ms: 1719836414811,
        current_return: 1.0000724595842956,
        initial_entry_price: 173.2,
        is_closed_position: true,
        miner_hotkey: "5D5GDbEa5Aedt9fXhWTzngDTANvnf8Q5poSFKYkU9hEZ7P8C",
        net_leverage: 0.0,
        open_ms: 1719836335395,
        orders: [
          {
            leverage: 0.2,
            order_type: "LONG",
            order_uuid: "1cb9868c-434b-44c1-85f0-ac349da891ab",
            price: 173.2,
            price_sources: [],
            processed_ms: 1719836334964,
          },
          {
            leverage: 0.22000000000000003,
            order_type: "LONG",
            order_uuid: "6143be1a-fa17-4ad8-b7e9-a63f4cb90d7f",
            price: 173.184,
            price_sources: [],
            processed_ms: 1719836384974,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "a7dc1af8-1309-4835-aa23-1b8a51b1daff",
            price: 173.2215,
            price_sources: [],
            processed_ms: 1719836414811,
          },
        ],
        position_type: "FLAT",
        position_uuid: "e2fc2a73-7169-44a5-858f-68ddf348cfe9",
        return_at_close: 1.0000430574539838,
        trade_pair: ["EURJPY", "EUR/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 173.21305137844612,
        close_ms: 1719836650782,
        current_return: 1.000119541623369,
        initial_entry_price: 173.22,
        is_closed_position: true,
        miner_hotkey: "5D5GDbEa5Aedt9fXhWTzngDTANvnf8Q5poSFKYkU9hEZ7P8C",
        net_leverage: 0.0,
        open_ms: 1719836445227,
        orders: [
          {
            leverage: 0.2,
            order_type: "LONG",
            order_uuid: "527cc184-442e-4a26-b36f-01d27b164f16",
            price: 173.22,
            price_sources: [],
            processed_ms: 1719836445059,
          },
          {
            leverage: 0.18000000000000002,
            order_type: "LONG",
            order_uuid: "e7bb8aeb-353d-4d42-a05b-cc995ef4c07a",
            price: 173.195,
            price_sources: [],
            processed_ms: 1719836475071,
          },
          {
            leverage: 0.41800000000000004,
            order_type: "LONG",
            order_uuid: "f75daad5-bb1e-4224-a971-a4e2e0d52023",
            price: 173.2175,
            price_sources: [],
            processed_ms: 1719836621189,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "8b7f7544-97cd-4df6-85cd-6307d65762a0",
            price: 173.239,
            price_sources: [],
            processed_ms: 1719836650782,
          },
        ],
        position_type: "FLAT",
        position_uuid: "880767a7-7671-4d02-be86-c894ff763dad",
        return_at_close: 1.0000636749457739,
        trade_pair: ["EURJPY", "EUR/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 173.210253968254,
        close_ms: 1719837098884,
        current_return: 0.9999210482264996,
        initial_entry_price: 173.245,
        is_closed_position: true,
        miner_hotkey: "5D5GDbEa5Aedt9fXhWTzngDTANvnf8Q5poSFKYkU9hEZ7P8C",
        net_leverage: 0.0,
        open_ms: 1719836682683,
        orders: [
          {
            leverage: 0.2,
            order_type: "LONG",
            order_uuid: "c5c1cee1-d7d0-4f3b-8476-395bcf10dfb0",
            price: 173.245,
            price_sources: [],
            processed_ms: 1719836682392,
          },
          {
            leverage: 0.22000000000000003,
            order_type: "LONG",
            order_uuid: "7a8bd784-7937-4443-ac4c-d3aa295728c6",
            price: 173.252,
            price_sources: [],
            processed_ms: 1719836727191,
          },
          {
            leverage: 0.42000000000000004,
            order_type: "LONG",
            order_uuid: "93aecc76-cc93-49c3-b2e0-8fef34759bdf",
            price: 173.223,
            price_sources: [],
            processed_ms: 1719836923200,
          },
          {
            leverage: 0.9240000000000002,
            order_type: "LONG",
            order_uuid: "9baa2584-82f7-4d2d-968a-b22fda303953",
            price: 173.187,
            price_sources: [],
            processed_ms: 1719837056866,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "87147094-9758-4a92-96c1-2acf22ba1e5a",
            price: 173.2025,
            price_sources: [],
            processed_ms: 1719837098884,
          },
        ],
        position_type: "FLAT",
        position_uuid: "443289d8-97ff-44c4-9941-a4fba003dfd5",
        return_at_close: 0.9997975779754646,
        trade_pair: ["EURJPY", "EUR/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 173.19474999999997,
        close_ms: 1719837603746,
        current_return: 1.0001934230392824,
        initial_entry_price: 173.19549999999998,
        is_closed_position: true,
        miner_hotkey: "5D5GDbEa5Aedt9fXhWTzngDTANvnf8Q5poSFKYkU9hEZ7P8C",
        net_leverage: 0.0,
        open_ms: 1719837129006,
        orders: [
          {
            leverage: 0.2,
            order_type: "LONG",
            order_uuid: "1bba00c7-7e3d-4237-9180-e5acfd14b45f",
            price: 173.19549999999998,
            price_sources: [],
            processed_ms: 1719837128732,
          },
          {
            leverage: 0.2,
            order_type: "LONG",
            order_uuid: "693c4663-ac51-4b2c-b188-71b2dda78f0f",
            price: 173.194,
            price_sources: [],
            processed_ms: 1719837158889,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "9e4a1ab1-4db5-4fa2-a4d6-8e765f42161c",
            price: 173.2785,
            price_sources: [],
            processed_ms: 1719837603746,
          },
        ],
        position_type: "FLAT",
        position_uuid: "77e4179e-8790-4c89-a926-cb49aba129bb",
        return_at_close: 1.0001654176234374,
        trade_pair: ["EURJPY", "EUR/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 173.29699999999997,
        close_ms: 1719838772777,
        current_return: 1.0001615723295845,
        initial_entry_price: 173.297,
        is_closed_position: true,
        miner_hotkey: "5D5GDbEa5Aedt9fXhWTzngDTANvnf8Q5poSFKYkU9hEZ7P8C",
        net_leverage: 0.0,
        open_ms: 1719837633083,
        orders: [
          {
            leverage: 0.2,
            order_type: "LONG",
            order_uuid: "e0051e42-fb5f-40b3-a89a-a799b14fb36c",
            price: 173.297,
            price_sources: [],
            processed_ms: 1719837632785,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "1e123179-0d79-46cf-b55c-233473c88d0c",
            price: 173.437,
            price_sources: [],
            processed_ms: 1719838772777,
          },
        ],
        position_type: "FLAT",
        position_uuid: "26b1aaea-c85e-4d2f-8020-ddda6392a898",
        return_at_close: 1.000147570067572,
        trade_pair: ["EURJPY", "EUR/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 173.42776190476192,
        close_ms: 1719838944206,
        current_return: 1.0001047156646792,
        initial_entry_price: 173.422,
        is_closed_position: true,
        miner_hotkey: "5D5GDbEa5Aedt9fXhWTzngDTANvnf8Q5poSFKYkU9hEZ7P8C",
        net_leverage: 0.0,
        open_ms: 1719838803785,
        orders: [
          {
            leverage: 0.2,
            order_type: "LONG",
            order_uuid: "038afed3-41a0-487e-85fd-184a78d9351b",
            price: 173.422,
            price_sources: [],
            processed_ms: 1719838803440,
          },
          {
            leverage: 0.22000000000000003,
            order_type: "LONG",
            order_uuid: "8f926402-a27e-4276-b7f5-ff32d8132db2",
            price: 173.433,
            price_sources: [],
            processed_ms: 1719838838128,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "30dd5419-6a1f-4a72-8b7c-e677c3990cfd",
            price: 173.471,
            price_sources: [],
            processed_ms: 1719838944206,
          },
        ],
        position_type: "FLAT",
        position_uuid: "15eb494c-c725-4e97-9253-a159f27bce95",
        return_at_close: 1.0000753125860387,
        trade_pair: ["EURJPY", "EUR/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 173.46274999999997,
        close_ms: 1719839408316,
        current_return: 1.0001896565141826,
        initial_entry_price: 173.4715,
        is_closed_position: true,
        miner_hotkey: "5D5GDbEa5Aedt9fXhWTzngDTANvnf8Q5poSFKYkU9hEZ7P8C",
        net_leverage: 0.0,
        open_ms: 1719838974339,
        orders: [
          {
            leverage: 0.2,
            order_type: "LONG",
            order_uuid: "f5450e39-f788-42ac-aa6c-514e5b90e84e",
            price: 173.4715,
            price_sources: [],
            processed_ms: 1719838974094,
          },
          {
            leverage: 0.2,
            order_type: "LONG",
            order_uuid: "c9472216-377b-4cf5-9a49-f61bd2851923",
            price: 173.454,
            price_sources: [],
            processed_ms: 1719839134153,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "ced36c94-be56-4f7e-bbcd-e5b56b949a9e",
            price: 173.545,
            price_sources: [],
            processed_ms: 1719839408316,
          },
        ],
        position_type: "FLAT",
        position_uuid: "d935e955-2e3d-4b34-a8d1-5884d32b35ae",
        return_at_close: 1.0001616512038,
        trade_pair: ["EURJPY", "EUR/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 173.5407857142857,
        close_ms: 1719839786819,
        current_return: 1.0000985363604933,
        initial_entry_price: 173.54,
        is_closed_position: true,
        miner_hotkey: "5D5GDbEa5Aedt9fXhWTzngDTANvnf8Q5poSFKYkU9hEZ7P8C",
        net_leverage: 0.0,
        open_ms: 1719839438147,
        orders: [
          {
            leverage: 0.2,
            order_type: "LONG",
            order_uuid: "b8539669-ff80-477d-9ea7-b12f062b4f22",
            price: 173.54,
            price_sources: [],
            processed_ms: 1719839437843,
          },
          {
            leverage: 0.22000000000000003,
            order_type: "LONG",
            order_uuid: "549bacae-c840-4b4a-88ba-0418261e3963",
            price: 173.54149999999998,
            price_sources: [],
            processed_ms: 1719839467857,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "469d8451-ae1d-4545-8183-c379ec1502d7",
            price: 173.5815,
            price_sources: [],
            processed_ms: 1719839786819,
          },
        ],
        position_type: "FLAT",
        position_uuid: "ccd13477-5dce-437d-a1a6-b1f0282daa2c",
        return_at_close: 1.0000691334635243,
        trade_pair: ["EURJPY", "EUR/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 173.49070498614958,
        close_ms: 1719841523071,
        current_return: 0.9998634905089728,
        initial_entry_price: 173.585,
        is_closed_position: true,
        miner_hotkey: "5D5GDbEa5Aedt9fXhWTzngDTANvnf8Q5poSFKYkU9hEZ7P8C",
        net_leverage: 0.0,
        open_ms: 1719839815929,
        orders: [
          {
            leverage: 0.2,
            order_type: "LONG",
            order_uuid: "519e97ac-5201-4a0f-8915-054ab8e7f52d",
            price: 173.585,
            price_sources: [],
            processed_ms: 1719839815767,
          },
          {
            leverage: 0.18000000000000002,
            order_type: "LONG",
            order_uuid: "8b52f33e-a5f1-4962-a437-fc44f954f1c5",
            price: 173.5505,
            price_sources: [],
            processed_ms: 1719840332707,
          },
          {
            leverage: 0.38,
            order_type: "LONG",
            order_uuid: "ea889a53-f6e5-4cfc-b20e-97e3c73507b7",
            price: 173.51549999999997,
            price_sources: [],
            processed_ms: 1719841331372,
          },
          {
            leverage: 0.76,
            order_type: "LONG",
            order_uuid: "72a6a3aa-bf9a-4275-9948-05c61f74bc4c",
            price: 173.473,
            price_sources: [],
            processed_ms: 1719841412911,
          },
          {
            leverage: 1.368,
            order_type: "LONG",
            order_uuid: "f20dd52b-5d6d-4708-aa7d-d672cc5bdad2",
            price: 173.472,
            price_sources: [],
            processed_ms: 1719841491157,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "e7826289-311b-4a96-b090-ac470d96e86b",
            price: 173.48250000000002,
            price_sources: [],
            processed_ms: 1719841523071,
          },
        ],
        position_type: "FLAT",
        position_uuid: "36b4768d-2c9e-4956-9045-fff88b8d1c7f",
        return_at_close: 0.9996613581057315,
        trade_pair: ["EURJPY", "EUR/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 173.47478947368424,
        close_ms: 1719841888824,
        current_return: 1.0000674694905776,
        initial_entry_price: 173.471,
        is_closed_position: true,
        miner_hotkey: "5D5GDbEa5Aedt9fXhWTzngDTANvnf8Q5poSFKYkU9hEZ7P8C",
        net_leverage: 0.0,
        open_ms: 1719841553356,
        orders: [
          {
            leverage: 0.2,
            order_type: "LONG",
            order_uuid: "4789e7b3-b44c-4fa7-9fb1-2c1ca4909747",
            price: 173.471,
            price_sources: [],
            processed_ms: 1719841553152,
          },
          {
            leverage: 0.18000000000000002,
            order_type: "LONG",
            order_uuid: "dd704244-0348-48d4-ad2e-393d7a841151",
            price: 173.50900000000001,
            price_sources: [],
            processed_ms: 1719841703567,
          },
          {
            leverage: 0.342,
            order_type: "LONG",
            order_uuid: "9f1cc204-911b-4c78-bacf-d6a830c1dc1c",
            price: 173.459,
            price_sources: [],
            processed_ms: 1719841844988,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "6cec8f5d-5843-41e6-95e0-2022963a52bc",
            price: 173.491,
            price_sources: [],
            processed_ms: 1719841888824,
          },
        ],
        position_type: "FLAT",
        position_uuid: "843001a9-7849-4b43-8d2d-af29600fd580",
        return_at_close: 1.0000169260806695,
        trade_pair: ["EURJPY", "EUR/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 173.46886904761905,
        close_ms: 1719842065036,
        current_return: 1.0001240972258247,
        initial_entry_price: 173.493,
        is_closed_position: true,
        miner_hotkey: "5D5GDbEa5Aedt9fXhWTzngDTANvnf8Q5poSFKYkU9hEZ7P8C",
        net_leverage: 0.0,
        open_ms: 1719841919226,
        orders: [
          {
            leverage: 0.2,
            order_type: "LONG",
            order_uuid: "ca3f4534-312d-4b5d-9208-9ad9d150d4df",
            price: 173.493,
            price_sources: [],
            processed_ms: 1719841918898,
          },
          {
            leverage: 0.22000000000000003,
            order_type: "LONG",
            order_uuid: "9e83122f-9156-4a9a-bdf8-7e6645cad915",
            price: 173.4715,
            price_sources: [],
            processed_ms: 1719841962985,
          },
          {
            leverage: 0.42000000000000004,
            order_type: "LONG",
            order_uuid: "ed2db629-2465-4d21-b9d2-ac1d33d15481",
            price: 173.456,
            price_sources: [],
            processed_ms: 1719841995111,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "0d23f828-a814-4ec1-8f1a-3d7f60fe5c55",
            price: 173.49450000000002,
            price_sources: [],
            processed_ms: 1719842065036,
          },
        ],
        position_type: "FLAT",
        position_uuid: "f1388c0d-2cbc-4790-ad8c-09a59d1bc895",
        return_at_close: 1.0000652899289078,
        trade_pair: ["EURJPY", "EUR/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 173.45809523809527,
        close_ms: 1719842348304,
        current_return: 1.000018907787068,
        initial_entry_price: 173.4735,
        is_closed_position: true,
        miner_hotkey: "5D5GDbEa5Aedt9fXhWTzngDTANvnf8Q5poSFKYkU9hEZ7P8C",
        net_leverage: 0.0,
        open_ms: 1719842095429,
        orders: [
          {
            leverage: 0.2,
            order_type: "LONG",
            order_uuid: "931e42ee-0a1d-4e9c-81f1-bfb765b3544d",
            price: 173.4735,
            price_sources: [],
            processed_ms: 1719842095161,
          },
          {
            leverage: 0.2,
            order_type: "LONG",
            order_uuid: "f0b67a52-7ed3-48dd-8f33-b9e256d8994e",
            price: 173.46050000000002,
            price_sources: [],
            processed_ms: 1719842139234,
          },
          {
            leverage: 0.44000000000000006,
            order_type: "LONG",
            order_uuid: "7766c905-76f8-45bf-b7c3-9cced39688c7",
            price: 173.45,
            price_sources: [],
            processed_ms: 1719842281060,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "7ce0aed1-67ee-4afd-bccc-e919fde256d0",
            price: 173.462,
            price_sources: [],
            processed_ms: 1719842348304,
          },
        ],
        position_type: "FLAT",
        position_uuid: "6b378136-1b0c-4249-a312-b7662125413c",
        return_at_close: 0.9999601066752901,
        trade_pair: ["EURJPY", "EUR/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 173.47225,
        close_ms: 1719842436773,
        current_return: 1.0000317029377095,
        initial_entry_price: 173.4855,
        is_closed_position: true,
        miner_hotkey: "5D5GDbEa5Aedt9fXhWTzngDTANvnf8Q5poSFKYkU9hEZ7P8C",
        net_leverage: 0.0,
        open_ms: 1719842375523,
        orders: [
          {
            leverage: 0.2,
            order_type: "LONG",
            order_uuid: "a8e2e433-8474-4e85-be96-8798bb6f2c8f",
            price: 173.4855,
            price_sources: [],
            processed_ms: 1719842374969,
          },
          {
            leverage: 0.2,
            order_type: "LONG",
            order_uuid: "5498440c-65e9-46e3-b0db-30fc11a72705",
            price: 173.459,
            price_sources: [],
            processed_ms: 1719842406929,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "5bf35abb-e9fc-4778-b884-e58af451734f",
            price: 173.486,
            price_sources: [],
            processed_ms: 1719842436773,
          },
        ],
        position_type: "FLAT",
        position_uuid: "851ac0d5-b8ce-4cb1-a3bb-49f3b1caae79",
        return_at_close: 1.0000037020500272,
        trade_pair: ["EURJPY", "EUR/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 173.41030576441102,
        close_ms: 1719843623090,
        current_return: 1.0000661939352011,
        initial_entry_price: 173.46,
        is_closed_position: true,
        miner_hotkey: "5D5GDbEa5Aedt9fXhWTzngDTANvnf8Q5poSFKYkU9hEZ7P8C",
        net_leverage: 0.0,
        open_ms: 1719842468449,
        orders: [
          {
            leverage: 0.2,
            order_type: "LONG",
            order_uuid: "630787c4-3402-40fc-b41f-7f687703c239",
            price: 173.46,
            price_sources: [],
            processed_ms: 1719842468036,
          },
          {
            leverage: 0.18000000000000002,
            order_type: "LONG",
            order_uuid: "6fd4949a-1e71-4f12-8585-0654163fe927",
            price: 173.453,
            price_sources: [],
            processed_ms: 1719842997960,
          },
          {
            leverage: 0.41800000000000004,
            order_type: "LONG",
            order_uuid: "7c33d4f2-ad93-4d6b-8d7d-aeb742df71ec",
            price: 173.405,
            price_sources: [],
            processed_ms: 1719843291417,
          },
          {
            leverage: 0.798,
            order_type: "LONG",
            order_uuid: "22a776a5-48a8-4b2c-8331-c8be10c75161",
            price: 173.391,
            price_sources: [],
            processed_ms: 1719843505286,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "3c5be43e-9755-4a4c-a0cd-c2810d96a28c",
            price: 173.4175,
            price_sources: [],
            processed_ms: 1719843623090,
          },
        ],
        position_type: "FLAT",
        position_uuid: "dce4a1a6-316c-4507-820f-4e152aeff001",
        return_at_close: 0.9999544665400146,
        trade_pair: ["EURJPY", "EUR/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 173.40435714285715,
        close_ms: 1719843835553,
        current_return: 1.0001153892730072,
        initial_entry_price: 173.413,
        is_closed_position: true,
        miner_hotkey: "5D5GDbEa5Aedt9fXhWTzngDTANvnf8Q5poSFKYkU9hEZ7P8C",
        net_leverage: 0.0,
        open_ms: 1719843653986,
        orders: [
          {
            leverage: 0.2,
            order_type: "LONG",
            order_uuid: "24248ecf-a20e-49e4-85d5-0fbd75667f26",
            price: 173.413,
            price_sources: [],
            processed_ms: 1719843653806,
          },
          {
            leverage: 0.22000000000000003,
            order_type: "LONG",
            order_uuid: "9500b8d6-9edb-4c2d-b62d-c75fc1298433",
            price: 173.3965,
            price_sources: [],
            processed_ms: 1719843700824,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "9d919d81-3217-4b11-92ca-b15fbba59e25",
            price: 173.452,
            price_sources: [],
            processed_ms: 1719843835553,
          },
        ],
        position_type: "FLAT",
        position_uuid: "4b156b58-18a5-42a5-b234-97fbe8a2eff7",
        return_at_close: 1.0000859858805626,
        trade_pair: ["EURJPY", "EUR/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 173.45399999999998,
        close_ms: 1719844329165,
        current_return: 1.0000922392224234,
        initial_entry_price: 173.462,
        is_closed_position: true,
        miner_hotkey: "5D5GDbEa5Aedt9fXhWTzngDTANvnf8Q5poSFKYkU9hEZ7P8C",
        net_leverage: 0.0,
        open_ms: 1719843865246,
        orders: [
          {
            leverage: 0.2,
            order_type: "LONG",
            order_uuid: "145bdf17-a3ef-4c78-bd2d-fbc64e227b14",
            price: 173.462,
            price_sources: [],
            processed_ms: 1719843864888,
          },
          {
            leverage: 0.2,
            order_type: "LONG",
            order_uuid: "967cf3de-e778-4418-854f-46daf8d83170",
            price: 173.446,
            price_sources: [],
            processed_ms: 1719844290088,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "61f0f161-14ca-4c07-9661-da40672041e0",
            price: 173.494,
            price_sources: [],
            processed_ms: 1719844329165,
          },
        ],
        position_type: "FLAT",
        position_uuid: "36b0af31-67e0-4b3f-95c4-27d180d677ba",
        return_at_close: 1.0000642366397252,
        trade_pair: ["EURJPY", "EUR/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 173.45949289891396,
        close_ms: 1719845919282,
        current_return: 1.0000048973617177,
        initial_entry_price: 173.522,
        is_closed_position: true,
        miner_hotkey: "5D5GDbEa5Aedt9fXhWTzngDTANvnf8Q5poSFKYkU9hEZ7P8C",
        net_leverage: 0.0,
        open_ms: 1719844359747,
        orders: [
          {
            leverage: 0.2,
            order_type: "LONG",
            order_uuid: "15fff8a8-45ab-4c79-8b10-ff0a2e684bbe",
            price: 173.522,
            price_sources: [],
            processed_ms: 1719844359029,
          },
          {
            leverage: 0.22000000000000003,
            order_type: "LONG",
            order_uuid: "d84f59c3-9a1e-4362-afd4-d09f7ce72665",
            price: 173.50799999999998,
            price_sources: [],
            processed_ms: 1719844433754,
          },
          {
            leverage: 0.37800000000000006,
            order_type: "LONG",
            order_uuid: "d98d39d2-de8b-4478-a456-1c8be9c1480e",
            price: 173.469,
            price_sources: [],
            processed_ms: 1719844679556,
          },
          {
            leverage: 0.8778000000000001,
            order_type: "LONG",
            order_uuid: "31033558-ad4c-41eb-9d8e-2d506a3b82be",
            price: 173.429,
            price_sources: [],
            processed_ms: 1719845855219,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "49c3423f-1813-4bff-ad6a-bbebc7a53a45",
            price: 173.46,
            price_sources: [],
            processed_ms: 1719845919282,
          },
        ],
        position_type: "FLAT",
        position_uuid: "6989e5ca-8ea3-42d7-ae67-e221ce46e5b2",
        return_at_close: 0.9998875907872278,
        trade_pair: ["EURJPY", "EUR/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 173.30471096369732,
        close_ms: 1719848627563,
        current_return: 0.9993855928050505,
        initial_entry_price: 173.455,
        is_closed_position: true,
        miner_hotkey: "5D5GDbEa5Aedt9fXhWTzngDTANvnf8Q5poSFKYkU9hEZ7P8C",
        net_leverage: 0.0,
        open_ms: 1719845949737,
        orders: [
          {
            leverage: 0.2,
            order_type: "LONG",
            order_uuid: "1ed3f0ad-1538-4026-9a49-39c03efc4386",
            price: 173.455,
            price_sources: [],
            processed_ms: 1719845949548,
          },
          {
            leverage: 0.2,
            order_type: "LONG",
            order_uuid: "f44f5097-7172-47ba-a495-0caac9b0401c",
            price: 173.451,
            price_sources: [],
            processed_ms: 1719846102156,
          },
          {
            leverage: 0.36000000000000004,
            order_type: "LONG",
            order_uuid: "f41f9ada-fdbc-4276-95f9-8084ed5f10fa",
            price: 173.395,
            price_sources: [],
            processed_ms: 1719846484061,
          },
          {
            leverage: 0.684,
            order_type: "LONG",
            order_uuid: "48b6409a-9e05-4d52-bcc0-d553d84cdec1",
            price: 173.348,
            price_sources: [],
            processed_ms: 1719846531313,
          },
          {
            leverage: 1.444,
            order_type: "LONG",
            order_uuid: "37b8bb46-9e4f-444f-899d-fabdb602d8cb",
            price: 173.353,
            price_sources: [],
            processed_ms: 1719846631196,
          },
          {
            leverage: 2.888,
            order_type: "LONG",
            order_uuid: "daf12e44-0262-44a4-a4c6-747b94806add",
            price: 173.31,
            price_sources: [],
            processed_ms: 1719848078032,
          },
          {
            leverage: 5.1984,
            order_type: "LONG",
            order_uuid: "208bd432-112b-4dda-bd2b-c595e9046b72",
            price: 173.265,
            price_sources: [],
            processed_ms: 1719848363527,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "decb8f5f-9c04-44d6-abf8-38373e35a832",
            price: 173.295,
            price_sources: [],
            processed_ms: 1719848627563,
          },
        ],
        position_type: "FLAT",
        position_uuid: "ac3cf2b8-eea5-42a4-83ac-8d19c34337c4",
        return_at_close: 0.9986178567975729,
        trade_pair: ["EURJPY", "EUR/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 173.27586842105262,
        close_ms: 1719849063582,
        current_return: 1.000020311539782,
        initial_entry_price: 173.3005,
        is_closed_position: true,
        miner_hotkey: "5D5GDbEa5Aedt9fXhWTzngDTANvnf8Q5poSFKYkU9hEZ7P8C",
        net_leverage: 0.0,
        open_ms: 1719848658947,
        orders: [
          {
            leverage: 0.2,
            order_type: "LONG",
            order_uuid: "cfda8226-ab87-4b45-98c6-b16a2bb386a3",
            price: 173.3005,
            price_sources: [],
            processed_ms: 1719848658845,
          },
          {
            leverage: 0.18000000000000002,
            order_type: "LONG",
            order_uuid: "aa5bcf74-e2a9-44c2-a66f-e126627a82e5",
            price: 173.282,
            price_sources: [],
            processed_ms: 1719848719665,
          },
          {
            leverage: 0.38,
            order_type: "LONG",
            order_uuid: "8c9d0971-cdc3-43ea-b6a2-b9c1239b0289",
            price: 173.26,
            price_sources: [],
            processed_ms: 1719848807588,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "e6a22aed-f0ed-462d-98f2-ec83150a2485",
            price: 173.28050000000002,
            price_sources: [],
            processed_ms: 1719849063582,
          },
        ],
        position_type: "FLAT",
        position_uuid: "d5feb17d-6443-467b-b01d-b9b83c3058b4",
        return_at_close: 0.9999671104592082,
        trade_pair: ["EURJPY", "EUR/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 173.2846842105263,
        close_ms: 1719850520243,
        current_return: 1.0002132533248709,
        initial_entry_price: 173.315,
        is_closed_position: true,
        miner_hotkey: "5D5GDbEa5Aedt9fXhWTzngDTANvnf8Q5poSFKYkU9hEZ7P8C",
        net_leverage: 0.0,
        open_ms: 1719849096034,
        orders: [
          {
            leverage: 0.2,
            order_type: "LONG",
            order_uuid: "3880add4-01df-4cb5-a1ac-a40b0272c529",
            price: 173.315,
            price_sources: [],
            processed_ms: 1719849094863,
          },
          {
            leverage: 0.18000000000000002,
            order_type: "LONG",
            order_uuid: "7bc09f04-377f-43c1-9076-d9fa696f7bce",
            price: 173.306,
            price_sources: [],
            processed_ms: 1719849301420,
          },
          {
            leverage: 0.41800000000000004,
            order_type: "LONG",
            order_uuid: "d26a0f86-16b6-4cb7-b593-7ac32073b994",
            price: 173.261,
            price_sources: [],
            processed_ms: 1719849812592,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "a28fadd4-c63a-4fc5-bd38-606bdacbd614",
            price: 173.331,
            price_sources: [],
            processed_ms: 1719850520243,
          },
        ],
        position_type: "FLAT",
        position_uuid: "e4bd17f8-b7d4-45b7-a590-358b97d69e53",
        return_at_close: 1.0001573814125402,
        trade_pair: ["EURJPY", "EUR/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 173.2890238095238,
        close_ms: 1719850977622,
        current_return: 1.00011136210861,
        initial_entry_price: 173.3085,
        is_closed_position: true,
        miner_hotkey: "5D5GDbEa5Aedt9fXhWTzngDTANvnf8Q5poSFKYkU9hEZ7P8C",
        net_leverage: 0.0,
        open_ms: 1719850550220,
        orders: [
          {
            leverage: 0.2,
            order_type: "LONG",
            order_uuid: "272aba3d-6a8d-4fdc-8b88-a52bce550ad9",
            price: 173.3085,
            price_sources: [],
            processed_ms: 1719850549633,
          },
          {
            leverage: 0.22000000000000003,
            order_type: "LONG",
            order_uuid: "92e5e88a-176f-4bc1-b917-584024ac8582",
            price: 173.3,
            price_sources: [],
            processed_ms: 1719850637161,
          },
          {
            leverage: 0.42000000000000004,
            order_type: "LONG",
            order_uuid: "d857de93-d220-45ce-95b6-a8caaca909da",
            price: 173.274,
            price_sources: [],
            processed_ms: 1719850783119,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "31f7d145-bb49-41f8-a3e0-ec8e956e5dbe",
            price: 173.312,
            price_sources: [],
            processed_ms: 1719850977622,
          },
        ],
        position_type: "FLAT",
        position_uuid: "098dde67-e238-4fd2-b6ec-36ad1605b2f1",
        return_at_close: 1.000052555560518,
        trade_pair: ["EURJPY", "EUR/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 173.27697368421053,
        close_ms: 1719851509394,
        current_return: 1.0000790500150023,
        initial_entry_price: 173.308,
        is_closed_position: true,
        miner_hotkey: "5D5GDbEa5Aedt9fXhWTzngDTANvnf8Q5poSFKYkU9hEZ7P8C",
        net_leverage: 0.0,
        open_ms: 1719851008370,
        orders: [
          {
            leverage: 0.2,
            order_type: "LONG",
            order_uuid: "0f4cf73f-9c62-43ba-8425-df3a36e100f9",
            price: 173.308,
            price_sources: [],
            processed_ms: 1719851007571,
          },
          {
            leverage: 0.2,
            order_type: "LONG",
            order_uuid: "1869c7f8-c2c0-46ae-8ffe-0867f69049cd",
            price: 173.2945,
            price_sources: [],
            processed_ms: 1719851111264,
          },
          {
            leverage: 0.36000000000000004,
            order_type: "LONG",
            order_uuid: "ce3c7fe6-14bf-47ab-afaf-a038ab189883",
            price: 173.25,
            price_sources: [],
            processed_ms: 1719851275221,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "3612f9c4-1c0d-4b4f-b681-a2bb8e6ea803",
            price: 173.29500000000002,
            price_sources: [],
            processed_ms: 1719851509394,
          },
        ],
        position_type: "FLAT",
        position_uuid: "f27bb32e-e749-4059-8b39-89876d1b036e",
        return_at_close: 1.0000258458095415,
        trade_pair: ["EURJPY", "EUR/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 173.30125,
        close_ms: 1719852949032,
        current_return: 1.0000403867900578,
        initial_entry_price: 173.324,
        is_closed_position: true,
        miner_hotkey: "5D5GDbEa5Aedt9fXhWTzngDTANvnf8Q5poSFKYkU9hEZ7P8C",
        net_leverage: 0.0,
        open_ms: 1719851540317,
        orders: [
          {
            leverage: 0.2,
            order_type: "LONG",
            order_uuid: "66031450-17c9-4a3b-9bb9-f1eb851f488b",
            price: 173.324,
            price_sources: [],
            processed_ms: 1719851539025,
          },
          {
            leverage: 0.2,
            order_type: "LONG",
            order_uuid: "e16395d7-f2fa-4c1b-8ef0-c108c2c6b247",
            price: 173.321,
            price_sources: [],
            processed_ms: 1719851714103,
          },
          {
            leverage: 0.4,
            order_type: "LONG",
            order_uuid: "f037d481-3a91-4ad9-9f40-cce6c47127d4",
            price: 173.28,
            price_sources: [],
            processed_ms: 1719852778525,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "71ad07a6-0dff-47e0-b6f7-1541299ed6cb",
            price: 173.31,
            price_sources: [],
            processed_ms: 1719852949032,
          },
        ],
        position_type: "FLAT",
        position_uuid: "786faca9-fb4a-48e6-abcc-2991296a0d0e",
        return_at_close: 0.9999843845283977,
        trade_pair: ["EURJPY", "EUR/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 173.24596610574054,
        close_ms: 1719853821384,
        current_return: 1.0000680231987764,
        initial_entry_price: 173.285,
        is_closed_position: true,
        miner_hotkey: "5D5GDbEa5Aedt9fXhWTzngDTANvnf8Q5poSFKYkU9hEZ7P8C",
        net_leverage: 0.0,
        open_ms: 1719852979892,
        orders: [
          {
            leverage: 0.2,
            order_type: "LONG",
            order_uuid: "bda61165-629c-4a59-b0c7-3c7173a1f65b",
            price: 173.285,
            price_sources: [],
            processed_ms: 1719852979281,
          },
          {
            leverage: 0.22000000000000003,
            order_type: "LONG",
            order_uuid: "3e813f30-8527-4108-abbe-2eecf6440c90",
            price: 173.29149999999998,
            price_sources: [],
            processed_ms: 1719853384353,
          },
          {
            leverage: 0.37800000000000006,
            order_type: "LONG",
            order_uuid: "addac778-ac80-4872-962c-5fea5809ecdb",
            price: 173.2475,
            price_sources: [],
            processed_ms: 1719853509634,
          },
          {
            leverage: 0.8778000000000001,
            order_type: "LONG",
            order_uuid: "0a321be0-dae4-49b8-8e27-72428778bafe",
            price: 173.22500000000002,
            price_sources: [],
            processed_ms: 1719853761524,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "a41fbe92-02e6-43ab-aa5c-063dd12b7e85",
            price: 173.253,
            price_sources: [],
            processed_ms: 1719853821384,
          },
        ],
        position_type: "FLAT",
        position_uuid: "c03a9479-4b18-4ee7-a466-7c41fb379abf",
        return_at_close: 0.999950709219247,
        trade_pair: ["EURJPY", "EUR/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 173.2386052631579,
        close_ms: 1719856273740,
        current_return: 1.0001039541239658,
        initial_entry_price: 173.2495,
        is_closed_position: true,
        miner_hotkey: "5D5GDbEa5Aedt9fXhWTzngDTANvnf8Q5poSFKYkU9hEZ7P8C",
        net_leverage: 0.0,
        open_ms: 1719853851129,
        orders: [
          {
            leverage: 0.2,
            order_type: "LONG",
            order_uuid: "9c3b6c19-ee1d-4298-bc6e-9c9c3ed4c336",
            price: 173.2495,
            price_sources: [],
            processed_ms: 1719853850962,
          },
          {
            leverage: 0.18000000000000002,
            order_type: "LONG",
            order_uuid: "3c482297-597f-4604-b91c-c43b4f6b897b",
            price: 173.2265,
            price_sources: [],
            processed_ms: 1719853941884,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "f44dfd68-ba25-4350-bf51-6f59141cee89",
            price: 173.286,
            price_sources: [],
            processed_ms: 1719856273740,
          },
        ],
        position_type: "FLAT",
        position_uuid: "e100c8df-2289-4dca-b0fb-9328843b3b16",
        return_at_close: 1.0000773513587862,
        trade_pair: ["EURJPY", "EUR/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 173.24252631578943,
        close_ms: 1719857078219,
        current_return: 1.0000678707569959,
        initial_entry_price: 173.2705,
        is_closed_position: true,
        miner_hotkey: "5D5GDbEa5Aedt9fXhWTzngDTANvnf8Q5poSFKYkU9hEZ7P8C",
        net_leverage: 0.0,
        open_ms: 1719856304178,
        orders: [
          {
            leverage: 0.2,
            order_type: "LONG",
            order_uuid: "04b04f22-f58f-4e50-9a56-35384a582fad",
            price: 173.2705,
            price_sources: [],
            processed_ms: 1719856302951,
          },
          {
            leverage: 0.18000000000000002,
            order_type: "LONG",
            order_uuid: "ea25005f-64c3-42cc-a203-f7c27d586cb9",
            price: 173.24,
            price_sources: [],
            processed_ms: 1719856371344,
          },
          {
            leverage: 0.38,
            order_type: "LONG",
            order_uuid: "28feabce-21a2-487d-85ba-c33ef0c2fb13",
            price: 173.22899999999998,
            price_sources: [],
            processed_ms: 1719857044851,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "825d876b-ac9a-4063-99df-96ae71b091ca",
            price: 173.258,
            price_sources: [],
            processed_ms: 1719857078219,
          },
        ],
        position_type: "FLAT",
        position_uuid: "1460e096-0c69-4198-9652-27c944edc356",
        return_at_close: 1.0000146671462717,
        trade_pair: ["EURJPY", "EUR/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 173.24904761904762,
        close_ms: 1719860356816,
        current_return: 1.0000774464597966,
        initial_entry_price: 173.281,
        is_closed_position: true,
        miner_hotkey: "5D5GDbEa5Aedt9fXhWTzngDTANvnf8Q5poSFKYkU9hEZ7P8C",
        net_leverage: 0.0,
        open_ms: 1719857108345,
        orders: [
          {
            leverage: 0.2,
            order_type: "LONG",
            order_uuid: "ec611edf-5af6-41bb-bd1b-b1ada2243832",
            price: 173.281,
            price_sources: [],
            processed_ms: 1719857107947,
          },
          {
            leverage: 0.22000000000000003,
            order_type: "LONG",
            order_uuid: "629cf552-bfcd-4f1b-b437-f10b252acde7",
            price: 173.22,
            price_sources: [],
            processed_ms: 1719858729361,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "58ee258f-1740-4c66-b6ae-bebb052b0ba5",
            price: 173.281,
            price_sources: [],
            processed_ms: 1719860356816,
          },
        ],
        position_type: "FLAT",
        position_uuid: "d2210c42-8c57-46a2-8438-8d7e5963709a",
        return_at_close: 1.0000480441828707,
        trade_pair: ["EURJPY", "EUR/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 173.275,
        close_ms: 1719861923569,
        current_return: 1.000100418410042,
        initial_entry_price: 173.275,
        is_closed_position: true,
        miner_hotkey: "5D5GDbEa5Aedt9fXhWTzngDTANvnf8Q5poSFKYkU9hEZ7P8C",
        net_leverage: 0.0,
        open_ms: 1719860388983,
        orders: [
          {
            leverage: 0.2,
            order_type: "LONG",
            order_uuid: "3884204c-7437-434e-be6d-66d5a1d586d3",
            price: 173.275,
            price_sources: [],
            processed_ms: 1719860387743,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "083fb7c0-6b1e-41c7-8987-4516ef55e269",
            price: 173.362,
            price_sources: [],
            processed_ms: 1719861923569,
          },
        ],
        position_type: "FLAT",
        position_uuid: "e3dcf24a-0dfc-4a1f-8c7f-fcf7ee7b1fb8",
        return_at_close: 1.0000864170041843,
        trade_pair: ["EURJPY", "EUR/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 173.33165789473688,
        close_ms: 1719862902828,
        current_return: 1.000126810130964,
        initial_entry_price: 173.33,
        is_closed_position: true,
        miner_hotkey: "5D5GDbEa5Aedt9fXhWTzngDTANvnf8Q5poSFKYkU9hEZ7P8C",
        net_leverage: 0.0,
        open_ms: 1719861954389,
        orders: [
          {
            leverage: 0.2,
            order_type: "LONG",
            order_uuid: "88a4162f-92ea-4474-b679-444772e5f64e",
            price: 173.33,
            price_sources: [],
            processed_ms: 1719861953256,
          },
          {
            leverage: 0.18000000000000002,
            order_type: "LONG",
            order_uuid: "37e9ce32-35eb-420a-9f68-719ec533f267",
            price: 173.33350000000002,
            price_sources: [],
            processed_ms: 1719862023707,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "678f9c23-eb31-451d-8095-b261740badd8",
            price: 173.3895,
            price_sources: [],
            processed_ms: 1719862902828,
          },
        ],
        position_type: "FLAT",
        position_uuid: "ce8f2025-1021-44a8-9b55-5c9c5392a162",
        return_at_close: 1.0001002067578144,
        trade_pair: ["EURJPY", "EUR/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 173.38636842105262,
        close_ms: 1719864653066,
        current_return: 1.000062747631104,
        initial_entry_price: 173.39299999999997,
        is_closed_position: true,
        miner_hotkey: "5D5GDbEa5Aedt9fXhWTzngDTANvnf8Q5poSFKYkU9hEZ7P8C",
        net_leverage: 0.0,
        open_ms: 1719862936137,
        orders: [
          {
            leverage: 0.2,
            order_type: "LONG",
            order_uuid: "870b6af3-7b62-4377-b299-664dde3ba249",
            price: 173.39299999999997,
            price_sources: [],
            processed_ms: 1719862934108,
          },
          {
            leverage: 0.18000000000000002,
            order_type: "LONG",
            order_uuid: "620407e1-7fd0-4336-b5ec-72e4dadbe311",
            price: 173.379,
            price_sources: [],
            processed_ms: 1719862974184,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "6a836c26-1ab1-4643-baf4-879cf4984c85",
            price: 173.415,
            price_sources: [],
            processed_ms: 1719864653066,
          },
        ],
        position_type: "FLAT",
        position_uuid: "f3d06e4d-fa9d-48e1-a1d3-3fc35acc9ee2",
        return_at_close: 1.0000361459620168,
        trade_pair: ["EURJPY", "EUR/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 173.39625,
        close_ms: 1719866075141,
        current_return: 1.0000870816404797,
        initial_entry_price: 173.4005,
        is_closed_position: true,
        miner_hotkey: "5D5GDbEa5Aedt9fXhWTzngDTANvnf8Q5poSFKYkU9hEZ7P8C",
        net_leverage: 0.0,
        open_ms: 1719864683794,
        orders: [
          {
            leverage: 0.2,
            order_type: "LONG",
            order_uuid: "b5d37a09-6ef7-4626-b5db-2ad44de6a905",
            price: 173.4005,
            price_sources: [],
            processed_ms: 1719864683324,
          },
          {
            leverage: 0.2,
            order_type: "LONG",
            order_uuid: "4b7e6818-9b97-478f-858c-a18398a16970",
            price: 173.392,
            price_sources: [],
            processed_ms: 1719864817590,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "ed86d92e-ffa6-42c2-b2b1-3c76296e7608",
            price: 173.434,
            price_sources: [],
            processed_ms: 1719866075141,
          },
        ],
        position_type: "FLAT",
        position_uuid: "a8af7215-6183-4cea-a567-02a90eef7738",
        return_at_close: 1.0000590792021937,
        trade_pair: ["EURJPY", "EUR/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 173.40844897959184,
        close_ms: 1719867616233,
        current_return: 0.9999112610275038,
        initial_entry_price: 173.43,
        is_closed_position: true,
        miner_hotkey: "5D5GDbEa5Aedt9fXhWTzngDTANvnf8Q5poSFKYkU9hEZ7P8C",
        net_leverage: 0.0,
        open_ms: 1719866104373,
        orders: [
          {
            leverage: 0.2,
            order_type: "LONG",
            order_uuid: "87dd4667-4bf3-49a5-b1ee-c4b6a40eef53",
            price: 173.43,
            price_sources: [],
            processed_ms: 1719866103589,
          },
          {
            leverage: 0.22000000000000003,
            order_type: "LONG",
            order_uuid: "6ba595a5-e412-46de-9f29-595b73fda1cd",
            price: 173.394,
            price_sources: [],
            processed_ms: 1719867333383,
          },
          {
            leverage: 0.4620000000000001,
            order_type: "LONG",
            order_uuid: "540a26c6-c6a5-4ea0-a4e9-6664f9a65661",
            price: 173.406,
            price_sources: [],
            processed_ms: 1719867531594,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "612cd1b9-710f-42cb-a376-3687dafc2b64",
            price: 173.391,
            price_sources: [],
            processed_ms: 1719867616233,
          },
        ],
        position_type: "FLAT",
        position_uuid: "00f91e11-219b-416e-b6e3-861dbb863438",
        return_at_close: 0.9998495265062479,
        trade_pair: ["EURJPY", "EUR/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 173.38,
        close_ms: 1719867789627,
        current_return: 1.0000922775246555,
        initial_entry_price: 173.39,
        is_closed_position: true,
        miner_hotkey: "5D5GDbEa5Aedt9fXhWTzngDTANvnf8Q5poSFKYkU9hEZ7P8C",
        net_leverage: 0.0,
        open_ms: 1719867660740,
        orders: [
          {
            leverage: 0.2,
            order_type: "LONG",
            order_uuid: "132c3e8d-f261-4933-b448-f3b485eab573",
            price: 173.39,
            price_sources: [],
            processed_ms: 1719867659098,
          },
          {
            leverage: 0.2,
            order_type: "LONG",
            order_uuid: "07a3e336-c40a-4c9b-9632-2eb240bd1434",
            price: 173.37,
            price_sources: [],
            processed_ms: 1719867715127,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "0452d8e8-1c65-4e1a-a8e1-5c66f723a61c",
            price: 173.42,
            price_sources: [],
            processed_ms: 1719867789627,
          },
        ],
        position_type: "FLAT",
        position_uuid: "ce060e6c-7770-4594-8c98-c5f35d8e6b24",
        return_at_close: 1.0000642749408848,
        trade_pair: ["EURJPY", "EUR/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 173.3891904761905,
        close_ms: 1719867943401,
        current_return: 1.0001230396678966,
        initial_entry_price: 173.44,
        is_closed_position: true,
        miner_hotkey: "5D5GDbEa5Aedt9fXhWTzngDTANvnf8Q5poSFKYkU9hEZ7P8C",
        net_leverage: 0.0,
        open_ms: 1719867819743,
        orders: [
          {
            leverage: 0.2,
            order_type: "LONG",
            order_uuid: "5d38b5a2-4804-43c5-82e0-69bcbc3de015",
            price: 173.44,
            price_sources: [],
            processed_ms: 1719867819340,
          },
          {
            leverage: 0.22000000000000003,
            order_type: "LONG",
            order_uuid: "aaa6880f-4e8e-4bd8-afba-341b0692946a",
            price: 173.343,
            price_sources: [],
            processed_ms: 1719867907531,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "8baa96b1-9c16-458b-97b5-53734246c6dd",
            price: 173.44,
            price_sources: [],
            processed_ms: 1719867943401,
          },
        ],
        position_type: "FLAT",
        position_uuid: "4d49bfff-61f3-462d-96ba-ca34162d7a0a",
        return_at_close: 1.0000936360505304,
        trade_pair: ["EURJPY", "EUR/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 173.44208595668832,
        close_ms: 1719868273657,
        current_return: 0.9999243233063594,
        initial_entry_price: 173.43,
        is_closed_position: true,
        miner_hotkey: "5D5GDbEa5Aedt9fXhWTzngDTANvnf8Q5poSFKYkU9hEZ7P8C",
        net_leverage: 0.0,
        open_ms: 1719867975442,
        orders: [
          {
            leverage: 0.2,
            order_type: "LONG",
            order_uuid: "a14cc45f-f0cc-41bd-a3e8-c224799cea49",
            price: 173.43,
            price_sources: [],
            processed_ms: 1719867975037,
          },
          {
            leverage: 0.22000000000000003,
            order_type: "LONG",
            order_uuid: "08527ae5-21f5-4778-9a57-3a8af12e111f",
            price: 173.38,
            price_sources: [],
            processed_ms: 1719868004276,
          },
          {
            leverage: 0.4620000000000001,
            order_type: "LONG",
            order_uuid: "c7efecf5-0e57-4f79-9c51-23571d2b9ce6",
            price: 173.379,
            price_sources: [],
            processed_ms: 1719868208027,
          },
          {
            leverage: 0.9702000000000002,
            order_type: "LONG",
            order_uuid: "a3011d69-fbd4-46db-a3fa-f414ff7fa5f8",
            price: 173.4886967728078,
            price_sources: [],
            processed_ms: 1719868239263,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "0f6f2aa5-80ad-4250-b9ae-2cb5e4313356",
            price: 173.435,
            price_sources: [],
            processed_ms: 1719868273657,
          },
        ],
        position_type: "FLAT",
        position_uuid: "9194d635-28ed-4667-be24-1c0fa2121d4c",
        return_at_close: 0.9997946791181455,
        trade_pair: ["EURJPY", "EUR/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 173.41861904761905,
        close_ms: 1719868419101,
        current_return: 1.0001903724201546,
        initial_entry_price: 173.46,
        is_closed_position: true,
        miner_hotkey: "5D5GDbEa5Aedt9fXhWTzngDTANvnf8Q5poSFKYkU9hEZ7P8C",
        net_leverage: 0.0,
        open_ms: 1719868314615,
        orders: [
          {
            leverage: 0.2,
            order_type: "LONG",
            order_uuid: "bc4b514c-3773-4b6a-91bf-8428cb9d7946",
            price: 173.46,
            price_sources: [],
            processed_ms: 1719868313104,
          },
          {
            leverage: 0.18000000000000002,
            order_type: "LONG",
            order_uuid: "74bd8423-0fb4-46e5-bc71-075fcccb745e",
            price: 173.46,
            price_sources: [],
            processed_ms: 1719868359329,
          },
          {
            leverage: 0.41800000000000004,
            order_type: "LONG",
            order_uuid: "e0a10d59-3eaf-4385-9c1f-728d9d2092e9",
            price: 173.381,
            price_sources: [],
            processed_ms: 1719868389068,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "5f808c09-f9a1-4c70-9a35-f47ba3af4e41",
            price: 173.46,
            price_sources: [],
            processed_ms: 1719868419101,
          },
        ],
        position_type: "FLAT",
        position_uuid: "487cc4e1-631e-4685-9881-9e152a65a822",
        return_at_close: 1.0001345017859513,
        trade_pair: ["EURJPY", "EUR/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 173.45999999999998,
        close_ms: 1719868519150,
        current_return: 1.0,
        initial_entry_price: 173.46,
        is_closed_position: true,
        miner_hotkey: "5D5GDbEa5Aedt9fXhWTzngDTANvnf8Q5poSFKYkU9hEZ7P8C",
        net_leverage: 0.0,
        open_ms: 1719868450156,
        orders: [
          {
            leverage: 0.2,
            order_type: "LONG",
            order_uuid: "301bf819-8d43-4a91-93d5-24cef9bcc6aa",
            price: 173.46,
            price_sources: [],
            processed_ms: 1719868449354,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "c64bffe5-82ef-4ed8-85c6-33efcd5410a9",
            price: 173.45999999999998,
            price_sources: [],
            processed_ms: 1719868519150,
          },
        ],
        position_type: "FLAT",
        position_uuid: "869e1926-bbd8-49e3-a514-a1d48fbb5da8",
        return_at_close: 0.999986,
        trade_pair: ["EURJPY", "EUR/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 173.39476190476188,
        close_ms: 1719868659332,
        current_return: 1.000085351787774,
        initial_entry_price: 173.4,
        is_closed_position: true,
        miner_hotkey: "5D5GDbEa5Aedt9fXhWTzngDTANvnf8Q5poSFKYkU9hEZ7P8C",
        net_leverage: 0.0,
        open_ms: 1719868551666,
        orders: [
          {
            leverage: 0.2,
            order_type: "LONG",
            order_uuid: "3e587f3a-c354-444d-988d-e5b7b581c1ca",
            price: 173.4,
            price_sources: [],
            processed_ms: 1719868549340,
          },
          {
            leverage: 0.22000000000000003,
            order_type: "LONG",
            order_uuid: "3005c6a6-4e2b-4010-8312-7e9bec70f068",
            price: 173.39,
            price_sources: [],
            processed_ms: 1719868599274,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "4fedbbc5-8e84-4e73-ac86-513a9ac6ec16",
            price: 173.43,
            price_sources: [],
            processed_ms: 1719868659332,
          },
        ],
        position_type: "FLAT",
        position_uuid: "1b82aba6-2fae-4897-aceb-2883227873bd",
        return_at_close: 1.0000559492784313,
        trade_pair: ["EURJPY", "EUR/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 173.40576842929912,
        close_ms: 1719868863880,
        current_return: 0.9999957161131293,
        initial_entry_price: 173.38,
        is_closed_position: true,
        miner_hotkey: "5D5GDbEa5Aedt9fXhWTzngDTANvnf8Q5poSFKYkU9hEZ7P8C",
        net_leverage: 0.0,
        open_ms: 1719868701685,
        orders: [
          {
            leverage: 0.2,
            order_type: "LONG",
            order_uuid: "8bb89044-3b29-402a-886e-b40fd743f8e5",
            price: 173.38,
            price_sources: [],
            processed_ms: 1719868700900,
          },
          {
            leverage: 0.22000000000000003,
            order_type: "LONG",
            order_uuid: "6bad8167-38d1-48ca-8084-e71276e3738e",
            price: 173.4291942741165,
            price_sources: [],
            processed_ms: 1719868809578,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "fe09167d-efde-4f8b-bb8e-382324124f9a",
            price: 173.404,
            price_sources: [],
            processed_ms: 1719868863880,
          },
        ],
        position_type: "FLAT",
        position_uuid: "3e368e4d-85ea-48f5-bca5-432931e82ef8",
        return_at_close: 0.9999663162390756,
        trade_pair: ["EURJPY", "EUR/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 173.40400000000002,
        close_ms: 1719869271353,
        current_return: 1.0000341730850195,
        initial_entry_price: 173.44,
        is_closed_position: true,
        miner_hotkey: "5D5GDbEa5Aedt9fXhWTzngDTANvnf8Q5poSFKYkU9hEZ7P8C",
        net_leverage: 0.0,
        open_ms: 1719868901577,
        orders: [
          {
            leverage: 0.2,
            order_type: "LONG",
            order_uuid: "8f2cdc49-e0e8-43ec-a211-db9097a9aa23",
            price: 173.44,
            price_sources: [],
            processed_ms: 1719868899220,
          },
          {
            leverage: 0.18000000000000002,
            order_type: "LONG",
            order_uuid: "5b0491ac-d500-4913-9d31-af01f99e7950",
            price: 173.364,
            price_sources: [],
            processed_ms: 1719868947380,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "28ce6ee0-7371-4753-8d79-09e6d39b4a40",
            price: 173.4195973154363,
            price_sources: [],
            processed_ms: 1719869271353,
          },
        ],
        position_type: "FLAT",
        position_uuid: "7f03bc0f-c449-49e6-8f5a-23e864c074d7",
        return_at_close: 1.0000075721760155,
        trade_pair: ["EURJPY", "EUR/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 173.41736842105263,
        close_ms: 1719869411397,
        current_return: 0.999915109573241,
        initial_entry_price: 173.4,
        is_closed_position: true,
        miner_hotkey: "5D5GDbEa5Aedt9fXhWTzngDTANvnf8Q5poSFKYkU9hEZ7P8C",
        net_leverage: 0.0,
        open_ms: 1719869308309,
        orders: [
          {
            leverage: 0.2,
            order_type: "LONG",
            order_uuid: "f3e9b619-0055-4e4a-966b-608621b0a938",
            price: 173.4,
            price_sources: [],
            processed_ms: 1719869307568,
          },
          {
            leverage: 0.2,
            order_type: "LONG",
            order_uuid: "2f2eef52-60f5-42ee-ad07-f02add4df5dd",
            price: 173.43,
            price_sources: [],
            processed_ms: 1719869336852,
          },
          {
            leverage: 0.36000000000000004,
            order_type: "LONG",
            order_uuid: "516114fb-c64e-4c13-acc6-cf4b173d6930",
            price: 173.42,
            price_sources: [],
            processed_ms: 1719869366988,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "df104aa3-b360-4e80-8715-a9da71aa4972",
            price: 173.398,
            price_sources: [],
            processed_ms: 1719869411397,
          },
        ],
        position_type: "FLAT",
        position_uuid: "d36890e0-fea4-4ee8-a472-f74c1bb2a99f",
        return_at_close: 0.9998619140894117,
        trade_pair: ["EURJPY", "EUR/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 173.39989974937345,
        close_ms: 1719869597347,
        current_return: 1.0000372758203102,
        initial_entry_price: 173.41,
        is_closed_position: true,
        miner_hotkey: "5D5GDbEa5Aedt9fXhWTzngDTANvnf8Q5poSFKYkU9hEZ7P8C",
        net_leverage: 0.0,
        open_ms: 1719869453638,
        orders: [
          {
            leverage: 0.2,
            order_type: "LONG",
            order_uuid: "91cb0c76-13e6-44c7-aed7-cb3502258c10",
            price: 173.41,
            price_sources: [],
            processed_ms: 1719869450987,
          },
          {
            leverage: 0.18000000000000002,
            order_type: "LONG",
            order_uuid: "317e6a55-02e4-4fcf-8c31-2a03380eee24",
            price: 173.342,
            price_sources: [],
            processed_ms: 1719869481210,
          },
          {
            leverage: 0.41800000000000004,
            order_type: "LONG",
            order_uuid: "0441f1a8-002c-4436-9e22-7e6e9445abbb",
            price: 173.42,
            price_sources: [],
            processed_ms: 1719869517314,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "48c484ca-415f-4cd0-acb3-956a7beee38d",
            price: 173.408,
            price_sources: [],
            processed_ms: 1719869597347,
          },
        ],
        position_type: "FLAT",
        position_uuid: "640c41ac-effe-4c04-913e-2b6df4c0c447",
        return_at_close: 0.9999814137380829,
        trade_pair: ["EURJPY", "EUR/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 173.38263157894738,
        close_ms: 1719869898518,
        current_return: 1.000076124567474,
        initial_entry_price: 173.4,
        is_closed_position: true,
        miner_hotkey: "5D5GDbEa5Aedt9fXhWTzngDTANvnf8Q5poSFKYkU9hEZ7P8C",
        net_leverage: 0.0,
        open_ms: 1719869630116,
        orders: [
          {
            leverage: 0.2,
            order_type: "LONG",
            order_uuid: "6bf31c81-5ed9-4f63-b2dc-87beafe37834",
            price: 173.4,
            price_sources: [],
            processed_ms: 1719869627116,
          },
          {
            leverage: 0.18000000000000002,
            order_type: "LONG",
            order_uuid: "4f8314ac-72e4-4c2e-a9f0-5949c2e05cf7",
            price: 173.39,
            price_sources: [],
            processed_ms: 1719869697252,
          },
          {
            leverage: 0.38,
            order_type: "LONG",
            order_uuid: "fddaeef2-4cbc-498e-9ec9-d06721401f1b",
            price: 173.37,
            price_sources: [],
            processed_ms: 1719869803517,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "b7575716-b4a3-416f-9ec2-f07c00c9ff4f",
            price: 173.4,
            price_sources: [],
            processed_ms: 1719869898518,
          },
        ],
        position_type: "FLAT",
        position_uuid: "b23ed2af-1579-422d-b823-1f2acbfe461f",
        return_at_close: 1.0000229205176472,
        trade_pair: ["EURJPY", "EUR/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 173.39,
        close_ms: 1719870582263,
        current_return: 1.0000461361014994,
        initial_entry_price: 173.4,
        is_closed_position: true,
        miner_hotkey: "5D5GDbEa5Aedt9fXhWTzngDTANvnf8Q5poSFKYkU9hEZ7P8C",
        net_leverage: 0.0,
        open_ms: 1719869940726,
        orders: [
          {
            leverage: 0.2,
            order_type: "LONG",
            order_uuid: "dac83355-6b0f-40f7-805d-b5310203060c",
            price: 173.4,
            price_sources: [],
            processed_ms: 1719869940112,
          },
          {
            leverage: 0.2,
            order_type: "LONG",
            order_uuid: "91183348-36a8-4798-920d-8755f99759cf",
            price: 173.38,
            price_sources: [],
            processed_ms: 1719870269918,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "e0b044ab-e459-4575-b3af-5b4a89883b78",
            price: 173.41,
            price_sources: [],
            processed_ms: 1719870582263,
          },
        ],
        position_type: "FLAT",
        position_uuid: "10f81360-3b68-40ce-8178-c0e04e62ef8b",
        return_at_close: 1.0000181348096886,
        trade_pair: ["EURJPY", "EUR/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 173.40290476190475,
        close_ms: 1719870964008,
        current_return: 0.9999762412778963,
        initial_entry_price: 173.41,
        is_closed_position: true,
        miner_hotkey: "5D5GDbEa5Aedt9fXhWTzngDTANvnf8Q5poSFKYkU9hEZ7P8C",
        net_leverage: 0.0,
        open_ms: 1719870626186,
        orders: [
          {
            leverage: 0.2,
            order_type: "LONG",
            order_uuid: "371bafdf-c38b-45c1-bb86-e93fa3ec61d0",
            price: 173.41,
            price_sources: [],
            processed_ms: 1719870623128,
          },
          {
            leverage: 0.22000000000000003,
            order_type: "LONG",
            order_uuid: "11ff83b6-5f6a-4f1a-9892-3635d41eb417",
            price: 173.402,
            price_sources: [],
            processed_ms: 1719870661566,
          },
          {
            leverage: 0.42000000000000004,
            order_type: "LONG",
            order_uuid: "e5723eab-0830-428f-acef-10faaabbbd82",
            price: 173.4,
            price_sources: [],
            processed_ms: 1719870917569,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "014c31bb-7473-4981-9dd9-fdcf078d6d4b",
            price: 173.398,
            price_sources: [],
            processed_ms: 1719870964008,
          },
        ],
        position_type: "FLAT",
        position_uuid: "15272464-c1d4-4363-aca2-380e2cd07ba9",
        return_at_close: 0.9999174426749091,
        trade_pair: ["EURJPY", "EUR/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 173.39100000000002,
        close_ms: 1719871263084,
        current_return: 1.0000131472725176,
        initial_entry_price: 173.42,
        is_closed_position: true,
        miner_hotkey: "5D5GDbEa5Aedt9fXhWTzngDTANvnf8Q5poSFKYkU9hEZ7P8C",
        net_leverage: 0.0,
        open_ms: 1719871010605,
        orders: [
          {
            leverage: 0.2,
            order_type: "LONG",
            order_uuid: "f5cd1d77-29ef-4d6f-a386-8b477a3f2f65",
            price: 173.42,
            price_sources: [],
            processed_ms: 1719871008633,
          },
          {
            leverage: 0.2,
            order_type: "LONG",
            order_uuid: "d92f8b18-1d98-41ce-9553-53a3773287af",
            price: 173.362,
            price_sources: [],
            processed_ms: 1719871187532,
          },
          {
            leverage: 0.36000000000000004,
            order_type: "LONG",
            order_uuid: "7a68b92e-7eb2-4f31-a4ee-ca1bdf84b5ef",
            price: 173.391,
            price_sources: [],
            processed_ms: 1719871234076,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "dffe0949-c467-4318-bbc4-236e2d542a94",
            price: 173.394,
            price_sources: [],
            processed_ms: 1719871263084,
          },
        ],
        position_type: "FLAT",
        position_uuid: "364a1918-0357-4da7-bcfb-94e765d33ae8",
        return_at_close: 0.9999599465730827,
        trade_pair: ["EURJPY", "EUR/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 173.39,
        close_ms: 1719871323801,
        current_return: 1.0000346040717458,
        initial_entry_price: 173.39,
        is_closed_position: true,
        miner_hotkey: "5D5GDbEa5Aedt9fXhWTzngDTANvnf8Q5poSFKYkU9hEZ7P8C",
        net_leverage: 0.0,
        open_ms: 1719871293774,
        orders: [
          {
            leverage: 0.2,
            order_type: "LONG",
            order_uuid: "14c24dd5-ca19-4b13-bddb-9f3e771a4f52",
            price: 173.39,
            price_sources: [],
            processed_ms: 1719871293192,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "c9bcbb1f-777d-432f-a3d5-eec4e0ee577a",
            price: 173.42,
            price_sources: [],
            processed_ms: 1719871323801,
          },
        ],
        position_type: "FLAT",
        position_uuid: "5efd25b2-1f5d-4742-b5fb-0e724d77b718",
        return_at_close: 1.000020603587289,
        trade_pair: ["EURJPY", "EUR/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 173.38956892230576,
        close_ms: 1719871477040,
        current_return: 1.0000157910824794,
        initial_entry_price: 173.389,
        is_closed_position: true,
        miner_hotkey: "5D5GDbEa5Aedt9fXhWTzngDTANvnf8Q5poSFKYkU9hEZ7P8C",
        net_leverage: 0.0,
        open_ms: 1719871364880,
        orders: [
          {
            leverage: 0.2,
            order_type: "LONG",
            order_uuid: "b9fab387-dd9a-4b27-842a-3cca741fac89",
            price: 173.389,
            price_sources: [],
            processed_ms: 1719871360871,
          },
          {
            leverage: 0.22000000000000003,
            order_type: "LONG",
            order_uuid: "d7801be6-d60f-44d6-964b-8665161c9460",
            price: 173.3945,
            price_sources: [],
            processed_ms: 1719871414506,
          },
          {
            leverage: 0.37800000000000006,
            order_type: "LONG",
            order_uuid: "ea7f66b8-b111-4d56-b915-9f487ea3cd78",
            price: 173.387,
            price_sources: [],
            processed_ms: 1719871443289,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "09ed5b0c-39fc-4095-9651-cb4fe614654a",
            price: 173.393,
            price_sources: [],
            processed_ms: 1719871477040,
          },
        ],
        position_type: "FLAT",
        position_uuid: "9776fa8e-91ef-4200-8e99-144cf22dcea4",
        return_at_close: 0.9999599302003895,
        trade_pair: ["EURJPY", "EUR/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 173.40047619047618,
        close_ms: 1719871879779,
        current_return: 0.9999746236807198,
        initial_entry_price: 173.39,
        is_closed_position: true,
        miner_hotkey: "5D5GDbEa5Aedt9fXhWTzngDTANvnf8Q5poSFKYkU9hEZ7P8C",
        net_leverage: 0.0,
        open_ms: 1719871508961,
        orders: [
          {
            leverage: 0.2,
            order_type: "LONG",
            order_uuid: "ae2b89b4-80f7-435d-9138-38df57139ff1",
            price: 173.39,
            price_sources: [],
            processed_ms: 1719871508753,
          },
          {
            leverage: 0.22000000000000003,
            order_type: "LONG",
            order_uuid: "0fa89e65-7799-441b-a8f3-05451e03f0c9",
            price: 173.41,
            price_sources: [],
            processed_ms: 1719871627312,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "3a98ee05-decd-4346-a224-d39a20ec5a53",
            price: 173.39,
            price_sources: [],
            processed_ms: 1719871879779,
          },
        ],
        position_type: "FLAT",
        position_uuid: "aaeaa156-de09-4776-824c-f960e2a77fcf",
        return_at_close: 0.9999452244267836,
        trade_pair: ["EURJPY", "EUR/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 173.369,
        close_ms: 1719872007242,
        current_return: 0.9999792373174614,
        initial_entry_price: 173.388,
        is_closed_position: true,
        miner_hotkey: "5D5GDbEa5Aedt9fXhWTzngDTANvnf8Q5poSFKYkU9hEZ7P8C",
        net_leverage: 0.0,
        open_ms: 1719871911639,
        orders: [
          {
            leverage: 0.2,
            order_type: "LONG",
            order_uuid: "2d6a6601-cc38-4c5c-a2b7-11eeaf19af5a",
            price: 173.388,
            price_sources: [],
            processed_ms: 1719871910890,
          },
          {
            leverage: 0.2,
            order_type: "LONG",
            order_uuid: "db47bb3a-d931-4612-972c-379e761d16e7",
            price: 173.35,
            price_sources: [],
            processed_ms: 1719871955450,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "26932382-b139-4552-9618-65beeb810760",
            price: 173.36,
            price_sources: [],
            processed_ms: 1719872007242,
          },
        ],
        position_type: "FLAT",
        position_uuid: "9a4fe3e0-7bd5-42be-b723-41e6493b4317",
        return_at_close: 0.9999512378988165,
        trade_pair: ["EURJPY", "EUR/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 173.3706509695291,
        close_ms: 1719877935528,
        current_return: 0.9999827142692351,
        initial_entry_price: 173.38,
        is_closed_position: true,
        miner_hotkey: "5D5GDbEa5Aedt9fXhWTzngDTANvnf8Q5poSFKYkU9hEZ7P8C",
        net_leverage: 0.0,
        open_ms: 1719872039459,
        orders: [
          {
            leverage: 0.2,
            order_type: "LONG",
            order_uuid: "f5f22210-489f-4685-91cd-36c8cc885e00",
            price: 173.38,
            price_sources: [],
            processed_ms: 1719872039062,
          },
          {
            leverage: 0.18000000000000002,
            order_type: "LONG",
            order_uuid: "43037164-9d33-438b-8875-2083898b309e",
            price: 173.39,
            price_sources: [],
            processed_ms: 1719872105426,
          },
          {
            leverage: 0.342,
            order_type: "LONG",
            order_uuid: "7c284e0a-2298-4713-a0be-d3325bc6eed9",
            price: 173.355,
            price_sources: [],
            processed_ms: 1719872405809,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "0516ebdc-6190-408d-9dd0-7e015fb33b49",
            price: 173.3665,
            price_sources: [],
            processed_ms: 1719877935528,
          },
        ],
        position_type: "FLAT",
        position_uuid: "81c143bd-c0d0-4a82-a7b1-501e3980c13b",
        return_at_close: 0.9999321751428559,
        trade_pair: ["EURJPY", "EUR/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 173.382,
        close_ms: 1719878824834,
        current_return: 1.0000992029161042,
        initial_entry_price: 173.382,
        is_closed_position: true,
        miner_hotkey: "5D5GDbEa5Aedt9fXhWTzngDTANvnf8Q5poSFKYkU9hEZ7P8C",
        net_leverage: 0.0,
        open_ms: 1719877965843,
        orders: [
          {
            leverage: 0.2,
            order_type: "LONG",
            order_uuid: "4c492517-e348-4505-973d-135524b596f8",
            price: 173.382,
            price_sources: [],
            processed_ms: 1719877965353,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "b019d1d5-fda6-459d-825f-3104eb1e3f3a",
            price: 173.468,
            price_sources: [],
            processed_ms: 1719878824834,
          },
        ],
        position_type: "FLAT",
        position_uuid: "b10365f9-4e83-4272-8f87-8ae5df1affe2",
        return_at_close: 1.0000852015272634,
        trade_pair: ["EURJPY", "EUR/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 173.43977423822713,
        close_ms: 1719880665536,
        current_return: 1.0001528634513612,
        initial_entry_price: 173.462,
        is_closed_position: true,
        miner_hotkey: "5D5GDbEa5Aedt9fXhWTzngDTANvnf8Q5poSFKYkU9hEZ7P8C",
        net_leverage: 0.0,
        open_ms: 1719878855698,
        orders: [
          {
            leverage: 0.2,
            order_type: "LONG",
            order_uuid: "323c7924-feec-4f8e-b4bf-cc3845b8a2ef",
            price: 173.462,
            price_sources: [],
            processed_ms: 1719878854386,
          },
          {
            leverage: 0.18000000000000002,
            order_type: "LONG",
            order_uuid: "ebb3b093-3a64-4468-bb66-1eee769f916c",
            price: 173.446,
            price_sources: [],
            processed_ms: 1719879294710,
          },
          {
            leverage: 0.342,
            order_type: "LONG",
            order_uuid: "c098262e-37fc-426d-b056-95cbdf28f7a9",
            price: 173.4235,
            price_sources: [],
            processed_ms: 1719879792881,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "18f6d55a-01a0-41d2-821a-9a682f1e78a2",
            price: 173.4765,
            price_sources: [],
            processed_ms: 1719880665536,
          },
        ],
        position_type: "FLAT",
        position_uuid: "53795061-466e-4357-9cbd-3361389de001",
        return_at_close: 1.0001023157256423,
        trade_pair: ["EURJPY", "EUR/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 173.45865476190474,
        close_ms: 1719881429310,
        current_return: 1.0000597759985244,
        initial_entry_price: 173.481,
        is_closed_position: true,
        miner_hotkey: "5D5GDbEa5Aedt9fXhWTzngDTANvnf8Q5poSFKYkU9hEZ7P8C",
        net_leverage: 0.0,
        open_ms: 1719880693811,
        orders: [
          {
            leverage: 0.2,
            order_type: "LONG",
            order_uuid: "afd07e22-de86-43f0-bf8f-f78993eb8eee",
            price: 173.481,
            price_sources: [],
            processed_ms: 1719880693200,
          },
          {
            leverage: 0.22000000000000003,
            order_type: "LONG",
            order_uuid: "4b3493c2-c083-404a-bfb6-df8a7b759260",
            price: 173.46249999999998,
            price_sources: [],
            processed_ms: 1719881170375,
          },
          {
            leverage: 0.42000000000000004,
            order_type: "LONG",
            order_uuid: "01dc1628-f0ab-4493-9333-eabff4c81ae3",
            price: 173.446,
            price_sources: [],
            processed_ms: 1719881275535,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "3cd4c29e-b2f6-4c8f-be31-891d147e57f7",
            price: 173.471,
            price_sources: [],
            processed_ms: 1719881429310,
          },
        ],
        position_type: "FLAT",
        position_uuid: "23e64f6b-3b42-4511-8d50-34f2bf2daa8a",
        return_at_close: 1.0000009724836956,
        trade_pair: ["EURJPY", "EUR/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 173.34445679675375,
        close_ms: 1719882191558,
        current_return: 1.0003301174815842,
        initial_entry_price: 173.474,
        is_closed_position: true,
        miner_hotkey: "5D5GDbEa5Aedt9fXhWTzngDTANvnf8Q5poSFKYkU9hEZ7P8C",
        net_leverage: 0.0,
        open_ms: 1719881461971,
        orders: [
          {
            leverage: 0.2,
            order_type: "LONG",
            order_uuid: "6f923d0a-2100-4880-b4cc-d67db847933b",
            price: 173.474,
            price_sources: [],
            processed_ms: 1719881460951,
          },
          {
            leverage: 0.2,
            order_type: "LONG",
            order_uuid: "4b0cd2b7-9ba2-47c3-9601-0b95c43f0ef5",
            price: 173.466,
            price_sources: [],
            processed_ms: 1719881527499,
          },
          {
            leverage: 0.36000000000000004,
            order_type: "LONG",
            order_uuid: "98df3583-43d4-4657-925d-7c192d12539f",
            price: 173.429,
            price_sources: [],
            processed_ms: 1719881711393,
          },
          {
            leverage: 0.8360000000000001,
            order_type: "LONG",
            order_uuid: "857c48cc-1970-4cda-a58c-f7a4350657b4",
            price: 173.393,
            price_sources: [],
            processed_ms: 1719881933593,
          },
          {
            leverage: 1.7556000000000003,
            order_type: "LONG",
            order_uuid: "79496520-080c-40d1-978f-03df13a10d3e",
            price: 173.345,
            price_sources: [],
            processed_ms: 1719882061269,
          },
          {
            leverage: 3.3516000000000004,
            order_type: "LONG",
            order_uuid: "f2763cc3-bf25-46d1-8ba8-013177b007ac",
            price: 173.308,
            price_sources: [],
            processed_ms: 1719882119051,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "902d92ff-01e3-40e4-8db7-d584a13d6438",
            price: 173.353,
            price_sources: [],
            processed_ms: 1719882191558,
          },
        ],
        position_type: "FLAT",
        position_uuid: "c3eebc99-4762-40f7-858a-73e07c38f482",
        return_at_close: 0.999860738582539,
        trade_pair: ["EURJPY", "EUR/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 173.3435,
        close_ms: 1719883742114,
        current_return: 1.0001407609745967,
        initial_entry_price: 173.3435,
        is_closed_position: true,
        miner_hotkey: "5D5GDbEa5Aedt9fXhWTzngDTANvnf8Q5poSFKYkU9hEZ7P8C",
        net_leverage: 0.0,
        open_ms: 1719882221878,
        orders: [
          {
            leverage: 0.2,
            order_type: "LONG",
            order_uuid: "ab4aaa76-0026-4cad-a463-af335f5d4185",
            price: 173.3435,
            price_sources: [],
            processed_ms: 1719882221046,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "427944f3-6693-488b-89ab-deeac06c5ccc",
            price: 173.4655,
            price_sources: [],
            processed_ms: 1719883742114,
          },
        ],
        position_type: "FLAT",
        position_uuid: "aeeda629-e399-4907-ac8e-9cda41ed3899",
        return_at_close: 1.000126759003943,
        trade_pair: ["EURJPY", "EUR/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 173.43937499999998,
        close_ms: 1719888153454,
        current_return: 1.0000882017225279,
        initial_entry_price: 173.466,
        is_closed_position: true,
        miner_hotkey: "5D5GDbEa5Aedt9fXhWTzngDTANvnf8Q5poSFKYkU9hEZ7P8C",
        net_leverage: 0.0,
        open_ms: 1719883774377,
        orders: [
          {
            leverage: 0.2,
            order_type: "LONG",
            order_uuid: "3e65e5d9-5962-4018-9808-9a4a47722679",
            price: 173.466,
            price_sources: [],
            processed_ms: 1719883772029,
          },
          {
            leverage: 0.2,
            order_type: "LONG",
            order_uuid: "4da7a78e-f8cb-4e7c-aa0b-c8b77983463d",
            price: 173.43349999999998,
            price_sources: [],
            processed_ms: 1719886025425,
          },
          {
            leverage: 0.4,
            order_type: "LONG",
            order_uuid: "d3642ebf-5379-49bf-9a1d-39c609b8a88d",
            price: 173.429,
            price_sources: [],
            processed_ms: 1719886897769,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "d105d70b-b54a-4cea-b209-47a77bb85063",
            price: 173.45850000000002,
            price_sources: [],
            processed_ms: 1719888153454,
          },
        ],
        position_type: "FLAT",
        position_uuid: "b6ea90f7-ee27-4e02-bf66-54bc12a2896d",
        return_at_close: 1.0000321967832315,
        trade_pair: ["EURJPY", "EUR/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 173.46488095238095,
        close_ms: 1719895709952,
        current_return: 1.0000947121454058,
        initial_entry_price: 173.473,
        is_closed_position: true,
        miner_hotkey: "5D5GDbEa5Aedt9fXhWTzngDTANvnf8Q5poSFKYkU9hEZ7P8C",
        net_leverage: 0.0,
        open_ms: 1719888183584,
        orders: [
          {
            leverage: 0.2,
            order_type: "LONG",
            order_uuid: "8f53a7e0-625f-4b93-8ec2-553d0b9edf74",
            price: 173.473,
            price_sources: [],
            processed_ms: 1719888183133,
          },
          {
            leverage: 0.22000000000000003,
            order_type: "LONG",
            order_uuid: "7d64aa84-a11c-4240-a108-f6b001f5c5b0",
            price: 173.45749999999998,
            price_sources: [],
            processed_ms: 1719893143261,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "abc87946-ba67-4569-8f59-4d365f058e59",
            price: 173.504,
            price_sources: [],
            processed_ms: 1719895709952,
          },
        ],
        position_type: "FLAT",
        position_uuid: "8911acc8-3d3b-497b-8240-a8c1db3225e7",
        return_at_close: 1.0000653093608687,
        trade_pair: ["EURJPY", "EUR/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 173.42054874027176,
        close_ms: 1719898283607,
        current_return: 0.9998331243228287,
        initial_entry_price: 173.516,
        is_closed_position: true,
        miner_hotkey: "5D5GDbEa5Aedt9fXhWTzngDTANvnf8Q5poSFKYkU9hEZ7P8C",
        net_leverage: 0.0,
        open_ms: 1719895739747,
        orders: [
          {
            leverage: 0.2,
            order_type: "LONG",
            order_uuid: "dfaca1e1-8b02-46c0-9b1a-38097a1741eb",
            price: 173.516,
            price_sources: [],
            processed_ms: 1719895739219,
          },
          {
            leverage: 0.2,
            order_type: "LONG",
            order_uuid: "94996387-fd54-4c84-8d27-c4a2042fd121",
            price: 173.514,
            price_sources: [],
            processed_ms: 1719895768761,
          },
          {
            leverage: 0.44000000000000006,
            order_type: "LONG",
            order_uuid: "b79f7f56-6e3f-4131-bd0d-aa0cb77787f0",
            price: 173.468,
            price_sources: [],
            processed_ms: 1719896131631,
          },
          {
            leverage: 0.7560000000000001,
            order_type: "LONG",
            order_uuid: "54f59d95-d838-44be-9ae6-060568b66f2f",
            price: 173.42,
            price_sources: [],
            processed_ms: 1719897517687,
          },
          {
            leverage: 1.4364000000000001,
            order_type: "LONG",
            order_uuid: "b62887cc-38a8-4bbe-84e3-04ea57a17088",
            price: 173.38,
            price_sources: [],
            processed_ms: 1719897833703,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "f884b147-e395-4341-8fda-eb81f56cf69e",
            price: 173.411,
            price_sources: [],
            processed_ms: 1719898283607,
          },
        ],
        position_type: "FLAT",
        position_uuid: "93cc9c9d-6535-4121-b693-702f5cdbf7ad",
        return_at_close: 0.999620891745195,
        trade_pair: ["EURJPY", "EUR/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 173.414,
        close_ms: 1719899804796,
        current_return: 1.0001580033907298,
        initial_entry_price: 173.414,
        is_closed_position: true,
        miner_hotkey: "5D5GDbEa5Aedt9fXhWTzngDTANvnf8Q5poSFKYkU9hEZ7P8C",
        net_leverage: 0.0,
        open_ms: 1719898313035,
        orders: [
          {
            leverage: 0.2,
            order_type: "LONG",
            order_uuid: "462f3c28-632d-479e-8a20-d06200f9bbf6",
            price: 173.414,
            price_sources: [],
            processed_ms: 1719898312890,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "c4ac1514-91fa-46c1-abfc-17f02e10ab27",
            price: 173.551,
            price_sources: [],
            processed_ms: 1719899804796,
          },
        ],
        position_type: "FLAT",
        position_uuid: "b40162f1-650b-4080-9db7-c1ed3ba3997b",
        return_at_close: 1.0001440011786824,
        trade_pair: ["EURJPY", "EUR/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 173.51771052631582,
        close_ms: 1719901784753,
        current_return: 1.000202727427269,
        initial_entry_price: 173.5335,
        is_closed_position: true,
        miner_hotkey: "5D5GDbEa5Aedt9fXhWTzngDTANvnf8Q5poSFKYkU9hEZ7P8C",
        net_leverage: 0.0,
        open_ms: 1719899834025,
        orders: [
          {
            leverage: 0.2,
            order_type: "LONG",
            order_uuid: "dd51abb8-0a2d-4a73-b5d5-f2f39791d3dd",
            price: 173.5335,
            price_sources: [],
            processed_ms: 1719899833236,
          },
          {
            leverage: 0.18000000000000002,
            order_type: "LONG",
            order_uuid: "b92be3ec-65aa-4e4b-94e4-908238133341",
            price: 173.546,
            price_sources: [],
            processed_ms: 1719899927718,
          },
          {
            leverage: 0.38,
            order_type: "LONG",
            order_uuid: "aa1c5fd9-6a03-4584-a3cf-9c37176bcf33",
            price: 173.496,
            price_sources: [],
            processed_ms: 1719900744796,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "1e15d317-6944-47a3-a670-2a9c9070b992",
            price: 173.564,
            price_sources: [],
            processed_ms: 1719901784753,
          },
        ],
        position_type: "FLAT",
        position_uuid: "d41d7116-861c-40bb-b65e-1bdcb9db34e4",
        return_at_close: 1.00014951664217,
        trade_pair: ["EURJPY", "EUR/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 173.37813605442176,
        close_ms: 1719903519536,
        current_return: 1.0019403772802173,
        initial_entry_price: 173.558,
        is_closed_position: true,
        miner_hotkey: "5D5GDbEa5Aedt9fXhWTzngDTANvnf8Q5poSFKYkU9hEZ7P8C",
        net_leverage: 0.0,
        open_ms: 1719901815832,
        orders: [
          {
            leverage: 0.2,
            order_type: "LONG",
            order_uuid: "baad1c8c-73ca-4a32-9158-23c93cd442a2",
            price: 173.558,
            price_sources: [],
            processed_ms: 1719901814692,
          },
          {
            leverage: 0.2,
            order_type: "LONG",
            order_uuid: "deb2e200-a71c-4c30-94aa-ecd20b789d11",
            price: 173.548,
            price_sources: [],
            processed_ms: 1719901887262,
          },
          {
            leverage: 0.4,
            order_type: "LONG",
            order_uuid: "2b0b12b6-e6cf-44c0-93e6-c1a25a3e7c02",
            price: 173.50900000000001,
            price_sources: [],
            processed_ms: 1719902091482,
          },
          {
            leverage: 0.8,
            order_type: "LONG",
            order_uuid: "5fe80de1-14e0-44bb-9b2f-9fd682160b09",
            price: 173.4645,
            price_sources: [],
            processed_ms: 1719902516722,
          },
          {
            leverage: 1.7600000000000002,
            order_type: "LONG",
            order_uuid: "e87f3873-a304-4225-af46-9bbce3edfd87",
            price: 173.443,
            price_sources: [],
            processed_ms: 1719903198003,
          },
          {
            leverage: 3.6960000000000006,
            order_type: "LONG",
            order_uuid: "931c1aa7-5171-4059-9af8-096a5b18f80d",
            price: 173.374,
            price_sources: [],
            processed_ms: 1719903297407,
          },
          {
            leverage: 7.056000000000001,
            order_type: "LONG",
            order_uuid: "6e09310c-d48f-4e1e-8817-4bb46f4ec3bf",
            price: 173.337,
            price_sources: [],
            processed_ms: 1719903341890,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "73c859ef-aee6-4a79-a84f-2acec4b3abb0",
            price: 173.402,
            price_sources: [],
            processed_ms: 1719903519536,
          },
        ],
        position_type: "FLAT",
        position_uuid: "4c76575b-17c5-42d3-a193-47ecf10e3b59",
        return_at_close: 1.0009506204979248,
        trade_pair: ["EURJPY", "EUR/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 173.39128571428571,
        close_ms: 1719904189579,
        current_return: 1.000071275549776,
        initial_entry_price: 173.4115,
        is_closed_position: true,
        miner_hotkey: "5D5GDbEa5Aedt9fXhWTzngDTANvnf8Q5poSFKYkU9hEZ7P8C",
        net_leverage: 0.0,
        open_ms: 1719903551104,
        orders: [
          {
            leverage: 0.2,
            order_type: "LONG",
            order_uuid: "4ec07edd-b864-4c4d-baf7-85ae40f7e5a3",
            price: 173.4115,
            price_sources: [],
            processed_ms: 1719903549076,
          },
          {
            leverage: 0.22000000000000003,
            order_type: "LONG",
            order_uuid: "fd4b41ed-8a27-4257-bb31-fbc2621affbd",
            price: 173.404,
            price_sources: [],
            processed_ms: 1719903619462,
          },
          {
            leverage: 0.42000000000000004,
            order_type: "LONG",
            order_uuid: "05c3719a-6d8d-4893-bbf6-367b6da2be7a",
            price: 173.375,
            price_sources: [],
            processed_ms: 1719903965875,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "87d59726-ff94-4232-909d-9ef7ae2e1c19",
            price: 173.406,
            price_sources: [],
            processed_ms: 1719904189579,
          },
        ],
        position_type: "FLAT",
        position_uuid: "5e7f8871-99b7-4503-9cb4-b31334a1000e",
        return_at_close: 1.0000124713587737,
        trade_pair: ["EURJPY", "EUR/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 173.2531204541967,
        close_ms: 1719905117095,
        current_return: 1.0001931635415793,
        initial_entry_price: 173.4085,
        is_closed_position: true,
        miner_hotkey: "5D5GDbEa5Aedt9fXhWTzngDTANvnf8Q5poSFKYkU9hEZ7P8C",
        net_leverage: 0.0,
        open_ms: 1719904221839,
        orders: [
          {
            leverage: 0.2,
            order_type: "LONG",
            order_uuid: "17c17ef4-66a9-4ef5-862f-d4087604fd00",
            price: 173.4085,
            price_sources: [],
            processed_ms: 1719904219161,
          },
          {
            leverage: 0.22000000000000003,
            order_type: "LONG",
            order_uuid: "6ed758f7-86f1-4620-95f0-d4be86df286c",
            price: 173.40249999999997,
            price_sources: [],
            processed_ms: 1719904285881,
          },
          {
            leverage: 0.4620000000000001,
            order_type: "LONG",
            order_uuid: "e94389c6-8c30-45b5-86bf-a367f046fc28",
            price: 173.361,
            price_sources: [],
            processed_ms: 1719904381466,
          },
          {
            leverage: 0.7938000000000002,
            order_type: "LONG",
            order_uuid: "9b9d5ec6-7ec3-4088-a0d8-b9918b9dce9a",
            price: 173.308,
            price_sources: [],
            processed_ms: 1719904609509,
          },
          {
            leverage: 1.6758000000000002,
            order_type: "LONG",
            order_uuid: "9f636d94-3562-49f7-8639-f8f5d4253c1a",
            price: 173.292,
            price_sources: [],
            processed_ms: 1719904817175,
          },
          {
            leverage: 3.3516000000000004,
            order_type: "LONG",
            order_uuid: "b58e50e0-f0cb-40ba-a483-ff5288cb0c37",
            price: 173.253,
            price_sources: [],
            processed_ms: 1719904967527,
          },
          {
            leverage: 7.373520000000001,
            order_type: "LONG",
            order_uuid: "1ba6f7bc-1f6a-4f03-9d2e-0c57bdebcca0",
            price: 173.223,
            price_sources: [],
            processed_ms: 1719905077087,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "7e6dbc0c-fc05-45ee-9278-a8bed149e4bf",
            price: 173.25549999999998,
            price_sources: [],
            processed_ms: 1719905117095,
          },
        ],
        position_type: "FLAT",
        position_uuid: "c186bfa5-f90a-4f05-b26d-d2d332529e15",
        return_at_close: 0.999207602803943,
        trade_pair: ["EURJPY", "EUR/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 173.15710187518658,
        close_ms: 1719906494416,
        current_return: 1.0003059235551142,
        initial_entry_price: 173.249,
        is_closed_position: true,
        miner_hotkey: "5D5GDbEa5Aedt9fXhWTzngDTANvnf8Q5poSFKYkU9hEZ7P8C",
        net_leverage: 0.0,
        open_ms: 1719905147512,
        orders: [
          {
            leverage: 0.2,
            order_type: "LONG",
            order_uuid: "acd1ed88-a1a8-4394-9a37-357a9a69c816",
            price: 173.249,
            price_sources: [],
            processed_ms: 1719905147129,
          },
          {
            leverage: 0.18000000000000002,
            order_type: "LONG",
            order_uuid: "2e500a6a-a012-457e-96ad-e3cfb92ea755",
            price: 173.21,
            price_sources: [],
            processed_ms: 1719905956526,
          },
          {
            leverage: 0.342,
            order_type: "LONG",
            order_uuid: "b1edb003-c89b-4e98-8452-cb2a0ab23241",
            price: 173.2095,
            price_sources: [],
            processed_ms: 1719906016186,
          },
          {
            leverage: 0.7942,
            order_type: "LONG",
            order_uuid: "cf9d7dbd-0a8f-4d1f-b332-e9abfd184a39",
            price: 173.158,
            price_sources: [],
            processed_ms: 1719906070231,
          },
          {
            leverage: 1.3645800000000001,
            order_type: "LONG",
            order_uuid: "5a65b90c-e55e-4b32-82e5-cb3680ebc4ce",
            price: 173.123,
            price_sources: [],
            processed_ms: 1719906366768,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "81ba7cc2-07c8-461b-a6b2-30bf398af56e",
            price: 173.1755,
            price_sources: [],
            processed_ms: 1719906494416,
          },
        ],
        position_type: "FLAT",
        position_uuid: "a16439a7-d4bc-431a-9fcd-9c26f1d491b4",
        return_at_close: 1.0001042072642221,
        trade_pair: ["EURJPY", "EUR/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 173.1627142857143,
        close_ms: 1719906718139,
        current_return: 1.0000698206262488,
        initial_entry_price: 173.158,
        is_closed_position: true,
        miner_hotkey: "5D5GDbEa5Aedt9fXhWTzngDTANvnf8Q5poSFKYkU9hEZ7P8C",
        net_leverage: 0.0,
        open_ms: 1719906524931,
        orders: [
          {
            leverage: 0.2,
            order_type: "LONG",
            order_uuid: "56e7493c-ca0a-4fa9-ac55-4b0659105438",
            price: 173.158,
            price_sources: [],
            processed_ms: 1719906524606,
          },
          {
            leverage: 0.22000000000000003,
            order_type: "LONG",
            order_uuid: "8f9fa586-b5fc-40d8-af55-7c47f023974b",
            price: 173.167,
            price_sources: [],
            processed_ms: 1719906578085,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "5ac6f8e0-1d77-4f82-b53e-2804010779f1",
            price: 173.19150000000002,
            price_sources: [],
            processed_ms: 1719906718139,
          },
        ],
        position_type: "FLAT",
        position_uuid: "6ad6c5cf-3455-4646-a2ab-9f6785d9e3f5",
        return_at_close: 1.0000404185735225,
        trade_pair: ["EURJPY", "EUR/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 173.1956904761905,
        close_ms: 1719906924472,
        current_return: 1.0000783500868953,
        initial_entry_price: 173.197,
        is_closed_position: true,
        miner_hotkey: "5D5GDbEa5Aedt9fXhWTzngDTANvnf8Q5poSFKYkU9hEZ7P8C",
        net_leverage: 0.0,
        open_ms: 1719906749189,
        orders: [
          {
            leverage: 0.2,
            order_type: "LONG",
            order_uuid: "b78448df-5506-428b-9e79-69135fc7c505",
            price: 173.197,
            price_sources: [],
            processed_ms: 1719906748145,
          },
          {
            leverage: 0.22000000000000003,
            order_type: "LONG",
            order_uuid: "b2f27a67-faff-4c92-a327-54c875fe4174",
            price: 173.1945,
            price_sources: [],
            processed_ms: 1719906824476,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "a4c00079-15b8-429f-ac75-70826ddf2cce",
            price: 173.228,
            price_sources: [],
            processed_ms: 1719906924472,
          },
        ],
        position_type: "FLAT",
        position_uuid: "51dd7343-a532-4d27-9975-37e68bbd4d3e",
        return_at_close: 1.0000489477834027,
        trade_pair: ["EURJPY", "EUR/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 173.22433333333333,
        close_ms: 1719907729286,
        current_return: 1.0000888973553037,
        initial_entry_price: 173.2335,
        is_closed_position: true,
        miner_hotkey: "5D5GDbEa5Aedt9fXhWTzngDTANvnf8Q5poSFKYkU9hEZ7P8C",
        net_leverage: 0.0,
        open_ms: 1719906956038,
        orders: [
          {
            leverage: 0.2,
            order_type: "LONG",
            order_uuid: "b5c2ed42-5f4f-4281-8448-eeafee115724",
            price: 173.2335,
            price_sources: [],
            processed_ms: 1719906955043,
          },
          {
            leverage: 0.22000000000000003,
            order_type: "LONG",
            order_uuid: "eaa9793a-8d25-4c2a-b0e2-c664a118a524",
            price: 173.216,
            price_sources: [],
            processed_ms: 1719907609858,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "a18c6f44-d274-489f-a27c-a204ed754748",
            price: 173.261,
            price_sources: [],
            processed_ms: 1719907729286,
          },
        ],
        position_type: "FLAT",
        position_uuid: "5dd181ca-d60a-4856-827b-e1d055ac53ce",
        return_at_close: 1.0000594947417214,
        trade_pair: ["EURJPY", "EUR/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 173.26149999999998,
        close_ms: 1719908624006,
        current_return: 1.00014659879258,
        initial_entry_price: 173.262,
        is_closed_position: true,
        miner_hotkey: "5D5GDbEa5Aedt9fXhWTzngDTANvnf8Q5poSFKYkU9hEZ7P8C",
        net_leverage: 0.0,
        open_ms: 1719907762658,
        orders: [
          {
            leverage: 0.2,
            order_type: "LONG",
            order_uuid: "dd8650bb-a934-4de7-af8f-09b8bd08a45c",
            price: 173.262,
            price_sources: [],
            processed_ms: 1719907759041,
          },
          {
            leverage: 0.2,
            order_type: "LONG",
            order_uuid: "808cce35-31cb-4675-940f-abb2c689547a",
            price: 173.261,
            price_sources: [],
            processed_ms: 1719908235929,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "f3df1a4a-5997-4bb3-a599-83a70bad2a12",
            price: 173.325,
            price_sources: [],
            processed_ms: 1719908624006,
          },
        ],
        position_type: "FLAT",
        position_uuid: "ac0b33fd-e5d3-421e-a0d2-74f83bb227b9",
        return_at_close: 1.0001185946878137,
        trade_pair: ["EURJPY", "EUR/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 173.25769172932328,
        close_ms: 1719909077374,
        current_return: 1.0002082561661625,
        initial_entry_price: 173.325,
        is_closed_position: true,
        miner_hotkey: "5D5GDbEa5Aedt9fXhWTzngDTANvnf8Q5poSFKYkU9hEZ7P8C",
        net_leverage: 0.0,
        open_ms: 1719908654942,
        orders: [
          {
            leverage: 0.2,
            order_type: "LONG",
            order_uuid: "a242691f-17ec-4817-9e1c-8f1c53cefe02",
            price: 173.325,
            price_sources: [],
            processed_ms: 1719908653424,
          },
          {
            leverage: 0.2,
            order_type: "LONG",
            order_uuid: "4640c2cc-7c8f-4c47-a1d2-4427f973dbc0",
            price: 173.297,
            price_sources: [],
            processed_ms: 1719908723408,
          },
          {
            leverage: 0.4,
            order_type: "LONG",
            order_uuid: "a989b251-351a-486b-ab62-4ac456906c8e",
            price: 173.27,
            price_sources: [],
            processed_ms: 1719908775393,
          },
          {
            leverage: 0.7200000000000001,
            order_type: "LONG",
            order_uuid: "9368f362-6389-4e94-a3f7-2617a3a29331",
            price: 173.26,
            price_sources: [],
            processed_ms: 1719908929552,
          },
          {
            leverage: 1.6720000000000002,
            order_type: "LONG",
            order_uuid: "ff08fe07-e657-4b3c-8e5f-13d939218145",
            price: 173.24099999999999,
            price_sources: [],
            processed_ms: 1719909042592,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "a5169eed-3ca1-40f6-82f6-db2017bf5489",
            price: 173.269,
            price_sources: [],
            processed_ms: 1719909077374,
          },
        ],
        position_type: "FLAT",
        position_uuid: "2b6a0ca1-2536-4e01-93b3-8ad9632c1cbf",
        return_at_close: 0.9999847696334048,
        trade_pair: ["EURJPY", "EUR/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 173.20987218045113,
        close_ms: 1719910385332,
        current_return: 1.0001807950689106,
        initial_entry_price: 173.268,
        is_closed_position: true,
        miner_hotkey: "5D5GDbEa5Aedt9fXhWTzngDTANvnf8Q5poSFKYkU9hEZ7P8C",
        net_leverage: 0.0,
        open_ms: 1719909108313,
        orders: [
          {
            leverage: 0.2,
            order_type: "LONG",
            order_uuid: "108bc4b4-6344-427f-a621-8dbe2caa0566",
            price: 173.268,
            price_sources: [],
            processed_ms: 1719909107462,
          },
          {
            leverage: 0.18000000000000002,
            order_type: "LONG",
            order_uuid: "a268adfa-8bf1-43d8-917b-61644fff14ce",
            price: 173.25799999999998,
            price_sources: [],
            processed_ms: 1719909399840,
          },
          {
            leverage: 0.41800000000000004,
            order_type: "LONG",
            order_uuid: "3e6b60e6-ba0e-4591-91f3-986004435f3d",
            price: 173.205,
            price_sources: [],
            processed_ms: 1719910194509,
          },
          {
            leverage: 0.798,
            order_type: "LONG",
            order_uuid: "1399bed7-9143-4951-9c69-40f6c09df9cf",
            price: 173.187,
            price_sources: [],
            processed_ms: 1719910226240,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "1e6f5767-4df3-4c2f-aa19-5c880434619c",
            price: 173.2295,
            price_sources: [],
            processed_ms: 1719910385332,
          },
        ],
        position_type: "FLAT",
        position_uuid: "df93b9f9-5d3f-44da-a366-25c05d3a915c",
        return_at_close: 1.0000690548704854,
        trade_pair: ["EURJPY", "EUR/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 173.22234210526315,
        close_ms: 1719910676615,
        current_return: 1.0000694476515957,
        initial_entry_price: 173.224,
        is_closed_position: true,
        miner_hotkey: "5D5GDbEa5Aedt9fXhWTzngDTANvnf8Q5poSFKYkU9hEZ7P8C",
        net_leverage: 0.0,
        open_ms: 1719910416135,
        orders: [
          {
            leverage: 0.2,
            order_type: "LONG",
            order_uuid: "34280da6-e2ee-4656-a951-7546e98b0f5f",
            price: 173.224,
            price_sources: [],
            processed_ms: 1719910414046,
          },
          {
            leverage: 0.18000000000000002,
            order_type: "LONG",
            order_uuid: "33708ad6-f2a3-4e78-991b-476fdcc3fa85",
            price: 173.22050000000002,
            price_sources: [],
            processed_ms: 1719910456415,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "70214c41-d56e-4dbc-ae62-b746947c97eb",
            price: 173.25400000000002,
            price_sources: [],
            processed_ms: 1719910676615,
          },
        ],
        position_type: "FLAT",
        position_uuid: "93c99eb3-d9be-431c-b25c-7a0f5630d765",
        return_at_close: 1.000042845804288,
        trade_pair: ["EURJPY", "EUR/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 173.20921052631581,
        close_ms: 1719911738091,
        current_return: 1.0000814931954334,
        initial_entry_price: 173.266,
        is_closed_position: true,
        miner_hotkey: "5D5GDbEa5Aedt9fXhWTzngDTANvnf8Q5poSFKYkU9hEZ7P8C",
        net_leverage: 0.0,
        open_ms: 1719910706839,
        orders: [
          {
            leverage: 0.2,
            order_type: "LONG",
            order_uuid: "3187ef72-238a-449a-b9b7-7eca3a45acdc",
            price: 173.266,
            price_sources: [],
            processed_ms: 1719910706198,
          },
          {
            leverage: 0.2,
            order_type: "LONG",
            order_uuid: "1083ebf7-c97e-4e42-b19f-e155018ab6e6",
            price: 173.251,
            price_sources: [],
            processed_ms: 1719910737250,
          },
          {
            leverage: 0.36000000000000004,
            order_type: "LONG",
            order_uuid: "090f8967-2d9d-4ec1-bc87-af3d7daca815",
            price: 173.2045,
            price_sources: [],
            processed_ms: 1719911162536,
          },
          {
            leverage: 0.76,
            order_type: "LONG",
            order_uuid: "97feda3b-f853-446f-a7a2-cba18199e6ca",
            price: 173.1855,
            price_sources: [],
            processed_ms: 1719911634792,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "6d3515ad-243a-4619-968b-379f9eda7903",
            price: 173.2185,
            price_sources: [],
            processed_ms: 1719911738091,
          },
        ],
        position_type: "FLAT",
        position_uuid: "989e6d1c-a1b0-4287-acb3-5984617315b9",
        return_at_close: 0.9999750845245574,
        trade_pair: ["EURJPY", "EUR/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 173.1509642857143,
        close_ms: 1719912240049,
        current_return: 1.000301004502944,
        initial_entry_price: 173.22,
        is_closed_position: true,
        miner_hotkey: "5D5GDbEa5Aedt9fXhWTzngDTANvnf8Q5poSFKYkU9hEZ7P8C",
        net_leverage: 0.0,
        open_ms: 1719911769306,
        orders: [
          {
            leverage: 0.2,
            order_type: "LONG",
            order_uuid: "9cfda1fb-4553-45ba-99d9-6d63ecd6630b",
            price: 173.22,
            price_sources: [],
            processed_ms: 1719911768719,
          },
          {
            leverage: 0.2,
            order_type: "LONG",
            order_uuid: "39f14013-f003-4109-b2b6-fb4c8cfe0f72",
            price: 173.20350000000002,
            price_sources: [],
            processed_ms: 1719911826355,
          },
          {
            leverage: 0.4,
            order_type: "LONG",
            order_uuid: "74aef34a-55b0-4027-a8be-89311a9eff62",
            price: 173.155,
            price_sources: [],
            processed_ms: 1719911940436,
          },
          {
            leverage: 0.8800000000000001,
            order_type: "LONG",
            order_uuid: "6d7068bb-605e-4655-8f60-7b96964ce65a",
            price: 173.12150000000003,
            price_sources: [],
            processed_ms: 1719912151405,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "b64d4ea0-a7c8-42f6-be57-cf1302946736",
            price: 173.182,
            price_sources: [],
            processed_ms: 1719912240049,
          },
        ],
        position_type: "FLAT",
        position_uuid: "274a71bd-45c1-4695-a4e2-7fcf793d6510",
        return_at_close: 1.0001833691048143,
        trade_pair: ["EURJPY", "EUR/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 173.15730952380954,
        close_ms: 1719912806327,
        current_return: 1.0000276035711397,
        initial_entry_price: 173.166,
        is_closed_position: true,
        miner_hotkey: "5D5GDbEa5Aedt9fXhWTzngDTANvnf8Q5poSFKYkU9hEZ7P8C",
        net_leverage: 0.0,
        open_ms: 1719912271005,
        orders: [
          {
            leverage: 0.2,
            order_type: "LONG",
            order_uuid: "53a01af6-4485-4222-ba2c-6819fc0ec2c3",
            price: 173.166,
            price_sources: [],
            processed_ms: 1719912270544,
          },
          {
            leverage: 0.2,
            order_type: "LONG",
            order_uuid: "adb0cd0d-7ef7-4380-b9ad-64896cc578df",
            price: 173.179,
            price_sources: [],
            processed_ms: 1719912312215,
          },
          {
            leverage: 0.44000000000000006,
            order_type: "LONG",
            order_uuid: "bf25df70-4d50-4d07-ab24-8736721dfb5d",
            price: 173.14350000000002,
            price_sources: [],
            processed_ms: 1719912738836,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "d954e6f3-3f88-42f2-b663-31958fc9ebb1",
            price: 173.163,
            price_sources: [],
            processed_ms: 1719912806327,
          },
        ],
        position_type: "FLAT",
        position_uuid: "d43c1345-0466-4a30-a75c-c9d7190edb9c",
        return_at_close: 0.9999688019480497,
        trade_pair: ["EURJPY", "EUR/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 173.1314880952381,
        close_ms: 1719915427382,
        current_return: 1.0000849532785843,
        initial_entry_price: 173.154,
        is_closed_position: true,
        miner_hotkey: "5D5GDbEa5Aedt9fXhWTzngDTANvnf8Q5poSFKYkU9hEZ7P8C",
        net_leverage: 0.0,
        open_ms: 1719912844852,
        orders: [
          {
            leverage: 0.2,
            order_type: "LONG",
            order_uuid: "fde4a57d-cfe8-431e-9bf6-7bcb4480e860",
            price: 173.154,
            price_sources: [],
            processed_ms: 1719912844257,
          },
          {
            leverage: 0.22000000000000003,
            order_type: "LONG",
            order_uuid: "f33bf277-c16b-497d-88f7-e3b8776ba76a",
            price: 173.14249999999998,
            price_sources: [],
            processed_ms: 1719913863663,
          },
          {
            leverage: 0.42000000000000004,
            order_type: "LONG",
            order_uuid: "9d8c42c6-887a-4d10-adce-483fc0784785",
            price: 173.115,
            price_sources: [],
            processed_ms: 1719915154992,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "feb499e9-0022-4922-bfa6-e4e4a3ff7ced",
            price: 173.149,
            price_sources: [],
            processed_ms: 1719915427382,
          },
        ],
        position_type: "FLAT",
        position_uuid: "cc5dfd50-fc28-4617-a468-f395bc62a982",
        return_at_close: 1.0000261482833315,
        trade_pair: ["EURJPY", "EUR/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 173.16875000000002,
        close_ms: 1719915981065,
        current_return: 1.0001172261859044,
        initial_entry_price: 173.1695,
        is_closed_position: true,
        miner_hotkey: "5D5GDbEa5Aedt9fXhWTzngDTANvnf8Q5poSFKYkU9hEZ7P8C",
        net_leverage: 0.0,
        open_ms: 1719915460374,
        orders: [
          {
            leverage: 0.2,
            order_type: "LONG",
            order_uuid: "177509b7-7942-446e-8aa8-19d4fe908015",
            price: 173.1695,
            price_sources: [],
            processed_ms: 1719915459829,
          },
          {
            leverage: 0.2,
            order_type: "LONG",
            order_uuid: "89b8da2f-41c4-47ca-a587-5c8624c2b530",
            price: 173.168,
            price_sources: [],
            processed_ms: 1719915875769,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "7125f566-4d72-401e-a248-4ef88c5213fb",
            price: 173.21949999999998,
            price_sources: [],
            processed_ms: 1719915981065,
          },
        ],
        position_type: "FLAT",
        position_uuid: "c784a3a1-24e9-40de-b31e-ae9bd08674ae",
        return_at_close: 1.0000892229035712,
        trade_pair: ["EURJPY", "EUR/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 173.20322619047622,
        close_ms: 1719917045928,
        current_return: 1.0000425448394898,
        initial_entry_price: 173.229,
        is_closed_position: true,
        miner_hotkey: "5D5GDbEa5Aedt9fXhWTzngDTANvnf8Q5poSFKYkU9hEZ7P8C",
        net_leverage: 0.0,
        open_ms: 1719916012050,
        orders: [
          {
            leverage: 0.2,
            order_type: "LONG",
            order_uuid: "dd09c100-763d-4e6d-9dd7-7cd28eb2ebd2",
            price: 173.229,
            price_sources: [],
            processed_ms: 1719916011086,
          },
          {
            leverage: 0.22000000000000003,
            order_type: "LONG",
            order_uuid: "ad9dce33-edbc-41ca-909d-1c3d54b7b961",
            price: 173.2165,
            price_sources: [],
            processed_ms: 1719916316357,
          },
          {
            leverage: 0.42000000000000004,
            order_type: "LONG",
            order_uuid: "fcac5f66-d4b3-42df-bb85-7f1be814a714",
            price: 173.184,
            price_sources: [],
            processed_ms: 1719916838203,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "30ef437e-9f4d-4200-bec6-2f1623c913d7",
            price: 173.212,
            price_sources: [],
            processed_ms: 1719917045928,
          },
        ],
        position_type: "FLAT",
        position_uuid: "ad4268e5-83d0-4e1b-9d40-06849120fd61",
        return_at_close: 0.9999837423378533,
        trade_pair: ["EURJPY", "EUR/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 173.1975595238095,
        close_ms: 1719917445666,
        current_return: 1.0001282110488945,
        initial_entry_price: 173.23,
        is_closed_position: true,
        miner_hotkey: "5D5GDbEa5Aedt9fXhWTzngDTANvnf8Q5poSFKYkU9hEZ7P8C",
        net_leverage: 0.0,
        open_ms: 1719917076533,
        orders: [
          {
            leverage: 0.2,
            order_type: "LONG",
            order_uuid: "d54a7079-9ece-432e-88d7-dfb9fdd32de7",
            price: 173.23,
            price_sources: [],
            processed_ms: 1719917075318,
          },
          {
            leverage: 0.22000000000000003,
            order_type: "LONG",
            order_uuid: "18d8b1da-cadf-4f0d-8ed2-9fba096f6ced",
            price: 173.214,
            price_sources: [],
            processed_ms: 1719917120163,
          },
          {
            leverage: 0.42000000000000004,
            order_type: "LONG",
            order_uuid: "730cd6ab-26b9-4e5f-a672-393a0bb8f65d",
            price: 173.1735,
            price_sources: [],
            processed_ms: 1719917305039,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "8527f366-5b20-415c-bdfe-8fc0fa4a727f",
            price: 173.224,
            price_sources: [],
            processed_ms: 1719917445666,
          },
        ],
        position_type: "FLAT",
        position_uuid: "4f2aee71-9bff-458c-ba0d-905502dfc171",
        return_at_close: 1.0000694035100848,
        trade_pair: ["EURJPY", "EUR/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 173.20325,
        close_ms: 1719919093256,
        current_return: 1.0001241314407618,
        initial_entry_price: 173.20350000000002,
        is_closed_position: true,
        miner_hotkey: "5D5GDbEa5Aedt9fXhWTzngDTANvnf8Q5poSFKYkU9hEZ7P8C",
        net_leverage: 0.0,
        open_ms: 1719917475893,
        orders: [
          {
            leverage: 0.2,
            order_type: "LONG",
            order_uuid: "ee13d23f-fe3c-4c9c-8ac0-02f467d940f3",
            price: 173.20350000000002,
            price_sources: [],
            processed_ms: 1719917475044,
          },
          {
            leverage: 0.2,
            order_type: "LONG",
            order_uuid: "77416639-268d-4aaf-aee5-7e58200797c3",
            price: 173.203,
            price_sources: [],
            processed_ms: 1719917942118,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "2f34dcc1-d7b8-486b-8f1f-3cb364e799f8",
            price: 173.257,
            price_sources: [],
            processed_ms: 1719919093256,
          },
        ],
        position_type: "FLAT",
        position_uuid: "f8cc3c17-3120-4e5c-8057-b66f8a976b84",
        return_at_close: 1.0000961279650815,
        trade_pair: ["EURJPY", "EUR/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 173.24371428571428,
        close_ms: 1719920494125,
        current_return: 1.0001328138528138,
        initial_entry_price: 173.25,
        is_closed_position: true,
        miner_hotkey: "5D5GDbEa5Aedt9fXhWTzngDTANvnf8Q5poSFKYkU9hEZ7P8C",
        net_leverage: 0.0,
        open_ms: 1719919122708,
        orders: [
          {
            leverage: 0.2,
            order_type: "LONG",
            order_uuid: "35f70db2-1ed8-4e7c-9ce5-a28656bb5773",
            price: 173.25,
            price_sources: [],
            processed_ms: 1719919121963,
          },
          {
            leverage: 0.22000000000000003,
            order_type: "LONG",
            order_uuid: "d31960ce-9fc3-4ee3-9c0f-f2b0bfa72869",
            price: 173.238,
            price_sources: [],
            processed_ms: 1719919274629,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "569cf346-4b89-4fb0-b76d-52d5212bed47",
            price: 173.2985,
            price_sources: [],
            processed_ms: 1719920494125,
          },
        ],
        position_type: "FLAT",
        position_uuid: "fc4bc6e3-4a63-4e74-954f-ca83c1b69d0e",
        return_at_close: 1.0001034099480866,
        trade_pair: ["EURJPY", "EUR/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 173.2761904761905,
        close_ms: 1719921377918,
        current_return: 1.000115412802742,
        initial_entry_price: 173.291,
        is_closed_position: true,
        miner_hotkey: "5D5GDbEa5Aedt9fXhWTzngDTANvnf8Q5poSFKYkU9hEZ7P8C",
        net_leverage: 0.0,
        open_ms: 1719920525032,
        orders: [
          {
            leverage: 0.2,
            order_type: "LONG",
            order_uuid: "bd2f86a2-e89f-4d00-98d7-8c5b4de7bb2d",
            price: 173.291,
            price_sources: [],
            processed_ms: 1719920523761,
          },
          {
            leverage: 0.22000000000000003,
            order_type: "LONG",
            order_uuid: "9c0846a0-660e-495c-b7fb-55a208272ad2",
            price: 173.265,
            price_sources: [],
            processed_ms: 1719920564061,
          },
          {
            leverage: 0.42000000000000004,
            order_type: "LONG",
            order_uuid: "3a8a7983-c97e-4e4b-a52e-e494a3f47a47",
            price: 173.275,
            price_sources: [],
            processed_ms: 1719921321198,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "6cc9f755-29df-41b9-8f8f-512dde3967ef",
            price: 173.3,
            price_sources: [],
            processed_ms: 1719921377918,
          },
        ],
        position_type: "FLAT",
        position_uuid: "cbc566ff-f837-4912-9176-67a2546ddc6c",
        return_at_close: 1.0000566060164693,
        trade_pair: ["EURJPY", "EUR/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 173.2772230576441,
        close_ms: 1719924409022,
        current_return: 1.0001048786820923,
        initial_entry_price: 173.305,
        is_closed_position: true,
        miner_hotkey: "5D5GDbEa5Aedt9fXhWTzngDTANvnf8Q5poSFKYkU9hEZ7P8C",
        net_leverage: 0.0,
        open_ms: 1719921408116,
        orders: [
          {
            leverage: 0.2,
            order_type: "LONG",
            order_uuid: "9a09d129-210a-4e67-8ef6-602132aeae28",
            price: 173.305,
            price_sources: [],
            processed_ms: 1719921407148,
          },
          {
            leverage: 0.18000000000000002,
            order_type: "LONG",
            order_uuid: "886876a6-6dce-4480-bf67-4bf2af133174",
            price: 173.291,
            price_sources: [],
            processed_ms: 1719923708900,
          },
          {
            leverage: 0.41800000000000004,
            order_type: "LONG",
            order_uuid: "08ed9dae-fee8-480e-a170-f473e420a0b0",
            price: 173.258,
            price_sources: [],
            processed_ms: 1719924239797,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "43373dab-ffa9-4544-a356-4594712718b1",
            price: 173.3,
            price_sources: [],
            processed_ms: 1719924409022,
          },
        ],
        position_type: "FLAT",
        position_uuid: "4d9cc999-7ff5-4fa9-a906-cefd70e908f3",
        return_at_close: 1.000049012823569,
        trade_pair: ["EURJPY", "EUR/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 173.29375,
        close_ms: 1719925243567,
        current_return: 1.0001367629874345,
        initial_entry_price: 173.29250000000002,
        is_closed_position: true,
        miner_hotkey: "5D5GDbEa5Aedt9fXhWTzngDTANvnf8Q5poSFKYkU9hEZ7P8C",
        net_leverage: 0.0,
        open_ms: 1719924439140,
        orders: [
          {
            leverage: 0.2,
            order_type: "LONG",
            order_uuid: "a66e716b-7f68-44ff-9465-514cfa942495",
            price: 173.29250000000002,
            price_sources: [],
            processed_ms: 1719924438939,
          },
          {
            leverage: 0.2,
            order_type: "LONG",
            order_uuid: "640846e5-f8ed-4f2c-aa4a-ab24773415f7",
            price: 173.295,
            price_sources: [],
            processed_ms: 1719924845761,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "c39cd2e4-1d4a-421c-87e1-5ca22956162c",
            price: 173.353,
            price_sources: [],
            processed_ms: 1719925243567,
          },
        ],
        position_type: "FLAT",
        position_uuid: "dfb45339-c869-410a-9cda-c75b8853eeea",
        return_at_close: 1.0001087591580708,
        trade_pair: ["EURJPY", "EUR/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 173.2958843537415,
        close_ms: 1719927325199,
        current_return: 1.0001634195450375,
        initial_entry_price: 173.333,
        is_closed_position: true,
        miner_hotkey: "5D5GDbEa5Aedt9fXhWTzngDTANvnf8Q5poSFKYkU9hEZ7P8C",
        net_leverage: 0.0,
        open_ms: 1719925273005,
        orders: [
          {
            leverage: 0.2,
            order_type: "LONG",
            order_uuid: "9af6bec7-dad0-48ea-a9ef-445b1690690f",
            price: 173.333,
            price_sources: [],
            processed_ms: 1719925272777,
          },
          {
            leverage: 0.22000000000000003,
            order_type: "LONG",
            order_uuid: "9170d25a-f46a-4e77-8d89-0834755900dd",
            price: 173.31650000000002,
            price_sources: [],
            processed_ms: 1719926212116,
          },
          {
            leverage: 0.4620000000000001,
            order_type: "LONG",
            order_uuid: "fe6dca6c-0320-43b8-a098-846ea4683f3d",
            price: 173.27,
            price_sources: [],
            processed_ms: 1719927079540,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "ca934a3f-2276-492c-b688-76a3ba7f57b7",
            price: 173.328,
            price_sources: [],
            processed_ms: 1719927325199,
          },
        ],
        position_type: "FLAT",
        position_uuid: "5e0e7bb6-559e-4b44-8437-36c22e6b639a",
        return_at_close: 1.0001016694555147,
        trade_pair: ["EURJPY", "EUR/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 173.2680620300752,
        close_ms: 1719927585249,
        current_return: 1.0002388389508785,
        initial_entry_price: 173.326,
        is_closed_position: true,
        miner_hotkey: "5D5GDbEa5Aedt9fXhWTzngDTANvnf8Q5poSFKYkU9hEZ7P8C",
        net_leverage: 0.0,
        open_ms: 1719927355339,
        orders: [
          {
            leverage: 0.2,
            order_type: "LONG",
            order_uuid: "2cfe3702-d1a6-4b39-93b8-af0cc919695f",
            price: 173.326,
            price_sources: [],
            processed_ms: 1719927354979,
          },
          {
            leverage: 0.18000000000000002,
            order_type: "LONG",
            order_uuid: "771fb23d-be72-4d0b-b7fa-4f4cb2bf8d12",
            price: 173.299,
            price_sources: [],
            processed_ms: 1719927397220,
          },
          {
            leverage: 0.41800000000000004,
            order_type: "LONG",
            order_uuid: "d807add9-a719-4a3e-bc68-52ff61486581",
            price: 173.2825,
            price_sources: [],
            processed_ms: 1719927449196,
          },
          {
            leverage: 0.798,
            order_type: "LONG",
            order_uuid: "b51546c2-07c4-4ca1-896f-b03eacdbc599",
            price: 173.239,
            price_sources: [],
            processed_ms: 1719927537163,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "f985c2c2-cf5c-4896-8163-9cdddc4c522d",
            price: 173.294,
            price_sources: [],
            processed_ms: 1719927585249,
          },
        ],
        position_type: "FLAT",
        position_uuid: "97920279-81c9-4a83-924d-8c7898f07688",
        return_at_close: 1.000127092267791,
        trade_pair: ["EURJPY", "EUR/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 173.27883458646616,
        close_ms: 1719928010974,
        current_return: 1.0002494099997115,
        initial_entry_price: 173.305,
        is_closed_position: true,
        miner_hotkey: "5D5GDbEa5Aedt9fXhWTzngDTANvnf8Q5poSFKYkU9hEZ7P8C",
        net_leverage: 0.0,
        open_ms: 1719927615188,
        orders: [
          {
            leverage: 0.2,
            order_type: "LONG",
            order_uuid: "ee8c76eb-fadf-4db5-84b1-28b1b41ba459",
            price: 173.305,
            price_sources: [],
            processed_ms: 1719927614834,
          },
          {
            leverage: 0.18000000000000002,
            order_type: "LONG",
            order_uuid: "367aa6f0-24d4-4fb2-b2f3-aa4462ef9e74",
            price: 173.2935,
            price_sources: [],
            processed_ms: 1719927835103,
          },
          {
            leverage: 0.41800000000000004,
            order_type: "LONG",
            order_uuid: "48b67aee-4df1-44b2-adc6-64d98a353e7b",
            price: 173.26,
            price_sources: [],
            processed_ms: 1719927888874,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "af32d004-2b88-4b1d-aaac-bd48b268ffbd",
            price: 173.333,
            price_sources: [],
            processed_ms: 1719928010974,
          },
        ],
        position_type: "FLAT",
        position_uuid: "7f1b9277-1035-451d-8d3f-d34ea42cfa1a",
        return_at_close: 1.0001935360676688,
        trade_pair: ["EURJPY", "EUR/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 173.34399999999997,
        close_ms: 1719928827338,
        current_return: 1.000084802473694,
        initial_entry_price: 173.344,
        is_closed_position: true,
        miner_hotkey: "5D5GDbEa5Aedt9fXhWTzngDTANvnf8Q5poSFKYkU9hEZ7P8C",
        net_leverage: 0.0,
        open_ms: 1719928041381,
        orders: [
          {
            leverage: 0.2,
            order_type: "LONG",
            order_uuid: "80bf3745-e449-444e-8496-7287fdd446bc",
            price: 173.344,
            price_sources: [],
            processed_ms: 1719928040954,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "27e0297c-57bc-4219-9abb-a6b5996a2bae",
            price: 173.41750000000002,
            price_sources: [],
            processed_ms: 1719928827338,
          },
        ],
        position_type: "FLAT",
        position_uuid: "46d0968f-c2ff-4664-a813-85376548ccb7",
        return_at_close: 1.0000708012864594,
        trade_pair: ["EURJPY", "EUR/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 173.3327968134622,
        close_ms: 1719929397617,
        current_return: 1.0005256693803193,
        initial_entry_price: 173.444,
        is_closed_position: true,
        miner_hotkey: "5D5GDbEa5Aedt9fXhWTzngDTANvnf8Q5poSFKYkU9hEZ7P8C",
        net_leverage: 0.0,
        open_ms: 1719928856826,
        orders: [
          {
            leverage: 0.2,
            order_type: "LONG",
            order_uuid: "27af0b4d-123d-46b5-89e1-2129a768ba55",
            price: 173.444,
            price_sources: [],
            processed_ms: 1719928856405,
          },
          {
            leverage: 0.22000000000000003,
            order_type: "LONG",
            order_uuid: "ce2d0bb6-ebdd-4f10-9695-ad380f712e76",
            price: 173.42,
            price_sources: [],
            processed_ms: 1719928895992,
          },
          {
            leverage: 0.37800000000000006,
            order_type: "LONG",
            order_uuid: "e927bc63-1daa-4364-9ad0-c4ee0aa9e5bf",
            price: 173.358,
            price_sources: [],
            processed_ms: 1719929132259,
          },
          {
            leverage: 0.8778000000000001,
            order_type: "LONG",
            order_uuid: "9dd0a064-2548-405e-8f60-a572816d5115",
            price: 173.324,
            price_sources: [],
            processed_ms: 1719929193827,
          },
          {
            leverage: 1.6758000000000002,
            order_type: "LONG",
            order_uuid: "3bf74450-8189-4332-9668-0d564c0f8e64",
            price: 173.307,
            price_sources: [],
            processed_ms: 1719929322066,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "e20ff536-7891-4d30-a265-47b5cf08913b",
            price: 173.36,
            price_sources: [],
            processed_ms: 1719929397617,
          },
        ],
        position_type: "FLAT",
        position_uuid: "4daa0b87-1341-4e4f-8336-6fdb4f1fa00f",
        return_at_close: 1.0002909340519746,
        trade_pair: ["EURJPY", "EUR/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 173.29716666666664,
        close_ms: 1719930138286,
        current_return: 1.0000864247389374,
        initial_entry_price: 173.33,
        is_closed_position: true,
        miner_hotkey: "5D5GDbEa5Aedt9fXhWTzngDTANvnf8Q5poSFKYkU9hEZ7P8C",
        net_leverage: 0.0,
        open_ms: 1719929426214,
        orders: [
          {
            leverage: 0.2,
            order_type: "LONG",
            order_uuid: "476fed1a-9788-4582-8f80-08eaf05006b4",
            price: 173.33,
            price_sources: [],
            processed_ms: 1719929425925,
          },
          {
            leverage: 0.22000000000000003,
            order_type: "LONG",
            order_uuid: "d8abd3ef-fbc2-410e-bc88-5ed195e990b8",
            price: 173.302,
            price_sources: [],
            processed_ms: 1719929828386,
          },
          {
            leverage: 0.42000000000000004,
            order_type: "LONG",
            order_uuid: "8139aac6-24fc-481a-8369-76dc7c18c3eb",
            price: 173.279,
            price_sources: [],
            processed_ms: 1719929869902,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "8a11103b-c9c3-47d7-9cd9-bb33302866bd",
            price: 173.315,
            price_sources: [],
            processed_ms: 1719930138286,
          },
        ],
        position_type: "FLAT",
        position_uuid: "e1ec7713-b31b-4ffa-ba71-a72a82c681a5",
        return_at_close: 1.0000276196571627,
        trade_pair: ["EURJPY", "EUR/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 173.26287656641605,
        close_ms: 1719931241240,
        current_return: 1.0000471805346396,
        initial_entry_price: 173.313,
        is_closed_position: true,
        miner_hotkey: "5D5GDbEa5Aedt9fXhWTzngDTANvnf8Q5poSFKYkU9hEZ7P8C",
        net_leverage: 0.0,
        open_ms: 1719930170309,
        orders: [
          {
            leverage: 0.2,
            order_type: "LONG",
            order_uuid: "ca232fd8-f3f0-4b2e-a42b-cb6c30e4217d",
            price: 173.313,
            price_sources: [],
            processed_ms: 1719930169916,
          },
          {
            leverage: 0.22000000000000003,
            order_type: "LONG",
            order_uuid: "1f00f0fc-9eb9-4721-8efa-131ee4958b2d",
            price: 173.308,
            price_sources: [],
            processed_ms: 1719930203922,
          },
          {
            leverage: 0.37800000000000006,
            order_type: "LONG",
            order_uuid: "a8e0e819-32c5-4043-9577-e73bb57ec022",
            price: 173.27949999999998,
            price_sources: [],
            processed_ms: 1719930259875,
          },
          {
            leverage: 0.798,
            order_type: "LONG",
            order_uuid: "f194fa12-db7a-4026-a025-da7e94be83ba",
            price: 173.23,
            price_sources: [],
            processed_ms: 1719930973283,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "ff51d8c2-8cc2-471a-b9df-aa415e25ceaa",
            price: 173.268,
            price_sources: [],
            processed_ms: 1719931241240,
          },
        ],
        position_type: "FLAT",
        position_uuid: "f4f2e507-13cb-480d-89d0-28bf44a663a7",
        return_at_close: 0.9999354552636303,
        trade_pair: ["EURJPY", "EUR/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 173.2620476190476,
        close_ms: 1719931627324,
        current_return: 1.0001235091607437,
        initial_entry_price: 173.2665,
        is_closed_position: true,
        miner_hotkey: "5D5GDbEa5Aedt9fXhWTzngDTANvnf8Q5poSFKYkU9hEZ7P8C",
        net_leverage: 0.0,
        open_ms: 1719931271123,
        orders: [
          {
            leverage: 0.2,
            order_type: "LONG",
            order_uuid: "87cd5d42-c27d-45cd-852f-fbed803ced5e",
            price: 173.2665,
            price_sources: [],
            processed_ms: 1719931270756,
          },
          {
            leverage: 0.22000000000000003,
            order_type: "LONG",
            order_uuid: "80427eb6-2cbc-4f13-8388-a8c8a35e0c80",
            price: 173.258,
            price_sources: [],
            processed_ms: 1719931361108,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "dc348eb1-ed90-4d0b-977d-0ae74d55b900",
            price: 173.313,
            price_sources: [],
            processed_ms: 1719931627324,
          },
        ],
        position_type: "FLAT",
        position_uuid: "4fab1ad3-823d-4f50-a765-e872d99386be",
        return_at_close: 1.0000941055295745,
        trade_pair: ["EURJPY", "EUR/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 173.296,
        close_ms: 1719932267960,
        current_return: 1.0001379143200073,
        initial_entry_price: 173.296,
        is_closed_position: true,
        miner_hotkey: "5D5GDbEa5Aedt9fXhWTzngDTANvnf8Q5poSFKYkU9hEZ7P8C",
        net_leverage: 0.0,
        open_ms: 1719931657274,
        orders: [
          {
            leverage: 0.2,
            order_type: "LONG",
            order_uuid: "41c95cd1-6308-4841-8fcb-db4adb660e16",
            price: 173.296,
            price_sources: [],
            processed_ms: 1719931656922,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "f50cfef1-bd76-4f6b-860b-0baafafdecb6",
            price: 173.4155,
            price_sources: [],
            processed_ms: 1719932267960,
          },
        ],
        position_type: "FLAT",
        position_uuid: "06264cf3-275d-48ef-a755-f4a43372c562",
        return_at_close: 1.000123912389207,
        trade_pair: ["EURJPY", "EUR/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 173.369984962406,
        close_ms: 1719933288221,
        current_return: 1.0001611915511963,
        initial_entry_price: 173.421,
        is_closed_position: true,
        miner_hotkey: "5D5GDbEa5Aedt9fXhWTzngDTANvnf8Q5poSFKYkU9hEZ7P8C",
        net_leverage: 0.0,
        open_ms: 1719932298695,
        orders: [
          {
            leverage: 0.2,
            order_type: "LONG",
            order_uuid: "74f1998b-21ab-40cd-b4be-ce597b0255f2",
            price: 173.421,
            price_sources: [],
            processed_ms: 1719932298125,
          },
          {
            leverage: 0.18000000000000002,
            order_type: "LONG",
            order_uuid: "d9c6641b-44c4-4013-9124-09f198a826f3",
            price: 173.412,
            price_sources: [],
            processed_ms: 1719932331370,
          },
          {
            leverage: 0.38,
            order_type: "LONG",
            order_uuid: "6906764c-fd2c-48bf-9b68-82a081753b1c",
            price: 173.387,
            price_sources: [],
            processed_ms: 1719932820530,
          },
          {
            leverage: 0.8360000000000001,
            order_type: "LONG",
            order_uuid: "54757b7c-7ab4-4ace-8354-4c10f6868250",
            price: 173.341,
            price_sources: [],
            processed_ms: 1719933117080,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "3c7a6e59-3cff-470e-8f4a-09888004dc4a",
            price: 173.3875,
            price_sources: [],
            processed_ms: 1719933288221,
          },
        ],
        position_type: "FLAT",
        position_uuid: "4c40b516-7873-4bf3-be2a-d764ef8b61ef",
        return_at_close: 1.000049453542876,
        trade_pair: ["EURJPY", "EUR/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 173.37717857142857,
        close_ms: 1719933784202,
        current_return: 1.0000814925889614,
        initial_entry_price: 173.39,
        is_closed_position: true,
        miner_hotkey: "5D5GDbEa5Aedt9fXhWTzngDTANvnf8Q5poSFKYkU9hEZ7P8C",
        net_leverage: 0.0,
        open_ms: 1719933318045,
        orders: [
          {
            leverage: 0.2,
            order_type: "LONG",
            order_uuid: "c9ef8b6e-6370-4df9-9ef2-e00d29ed438b",
            price: 173.39,
            price_sources: [],
            processed_ms: 1719933317754,
          },
          {
            leverage: 0.22000000000000003,
            order_type: "LONG",
            order_uuid: "bd3017f1-e77b-4efe-ba53-218bb82e1b35",
            price: 173.384,
            price_sources: [],
            processed_ms: 1719933532232,
          },
          {
            leverage: 0.42000000000000004,
            order_type: "LONG",
            order_uuid: "3d44350b-82b8-4267-8b0b-068724d789db",
            price: 173.3675,
            price_sources: [],
            processed_ms: 1719933607874,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "65961f12-3244-4907-ad88-99bccd01351b",
            price: 173.394,
            price_sources: [],
            processed_ms: 1719933784202,
          },
        ],
        position_type: "FLAT",
        position_uuid: "a21c9c22-50d7-4289-9dcd-4a1a70e5fe1d",
        return_at_close: 1.0000226877971972,
        trade_pair: ["EURJPY", "EUR/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 173.40049999999997,
        close_ms: 1719942004108,
        current_return: 1.0001476359505075,
        initial_entry_price: 173.3995,
        is_closed_position: true,
        miner_hotkey: "5D5GDbEa5Aedt9fXhWTzngDTANvnf8Q5poSFKYkU9hEZ7P8C",
        net_leverage: 0.0,
        open_ms: 1719933813779,
        orders: [
          {
            leverage: 0.2,
            order_type: "LONG",
            order_uuid: "a4ad37eb-9612-47ae-ad2b-32e6746754e5",
            price: 173.3995,
            price_sources: [],
            processed_ms: 1719933813711,
          },
          {
            leverage: 0.2,
            order_type: "LONG",
            order_uuid: "cd7ada06-28df-4ebf-a3c0-adec2f88c5d7",
            price: 173.4015,
            price_sources: [],
            processed_ms: 1719934537099,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "afa8107d-7e3b-451a-943a-bfe3f8d029e6",
            price: 173.4645,
            price_sources: [],
            processed_ms: 1719942004108,
          },
        ],
        position_type: "FLAT",
        position_uuid: "5adb59e8-a70b-4a4e-ada5-ab458f7added",
        return_at_close: 1.000119631816701,
        trade_pair: ["EURJPY", "EUR/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 173.43544671201815,
        close_ms: 1719946349168,
        current_return: 1.0000256969068002,
        initial_entry_price: 173.445,
        is_closed_position: true,
        miner_hotkey: "5D5GDbEa5Aedt9fXhWTzngDTANvnf8Q5poSFKYkU9hEZ7P8C",
        net_leverage: 0.0,
        open_ms: 1719942034115,
        orders: [
          {
            leverage: 0.2,
            order_type: "LONG",
            order_uuid: "4cd53d75-6474-436c-8aca-d0b2cc17bfc4",
            price: 173.445,
            price_sources: [],
            processed_ms: 1719942033721,
          },
          {
            leverage: 0.22000000000000003,
            order_type: "LONG",
            order_uuid: "5eadfdf1-0b99-42db-a243-d69804f9b4db",
            price: 173.455,
            price_sources: [],
            processed_ms: 1719942099930,
          },
          {
            leverage: 0.4620000000000001,
            order_type: "LONG",
            order_uuid: "e735ef02-57c0-494d-a1d0-4000a61a3b63",
            price: 173.422,
            price_sources: [],
            processed_ms: 1719945031936,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "46f98387-755a-44e0-a4af-bcdb39e0096a",
            price: 173.4405,
            price_sources: [],
            processed_ms: 1719946349168,
          },
        ],
        position_type: "FLAT",
        position_uuid: "c26fcc33-80af-450d-b0e3-fc6e56889c2b",
        return_at_close: 0.9999639553202732,
        trade_pair: ["EURJPY", "EUR/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 173.4412105263158,
        close_ms: 1719951445930,
        current_return: 1.0001550964163501,
        initial_entry_price: 173.4405,
        is_closed_position: true,
        miner_hotkey: "5D5GDbEa5Aedt9fXhWTzngDTANvnf8Q5poSFKYkU9hEZ7P8C",
        net_leverage: 0.0,
        open_ms: 1719946378985,
        orders: [
          {
            leverage: 0.2,
            order_type: "LONG",
            order_uuid: "12b326e4-2646-4de6-90e0-7f7febe4a9cc",
            price: 173.4405,
            price_sources: [],
            processed_ms: 1719946378729,
          },
          {
            leverage: 0.18000000000000002,
            order_type: "LONG",
            order_uuid: "9e9aa116-d2e4-46e9-a233-2c91f74d8444",
            price: 173.442,
            price_sources: [],
            processed_ms: 1719946467024,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "6c244943-249d-4a88-a472-3c2a9b7a3270",
            price: 173.512,
            price_sources: [],
            processed_ms: 1719951445930,
          },
        ],
        position_type: "FLAT",
        position_uuid: "d9d82f84-6df3-4cde-8a55-a494bd9a6acf",
        return_at_close: 1.0001284922907854,
        trade_pair: ["EURJPY", "EUR/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 173.4790701754386,
        close_ms: 1719953772797,
        current_return: 0.9998981736028262,
        initial_entry_price: 173.511,
        is_closed_position: true,
        miner_hotkey: "5D5GDbEa5Aedt9fXhWTzngDTANvnf8Q5poSFKYkU9hEZ7P8C",
        net_leverage: 0.0,
        open_ms: 1719951476693,
        orders: [
          {
            leverage: 0.2,
            order_type: "LONG",
            order_uuid: "a5454724-cbfb-4c47-b59d-0bc5773f7b7f",
            price: 173.511,
            price_sources: [],
            processed_ms: 1719951476154,
          },
          {
            leverage: 0.2,
            order_type: "LONG",
            order_uuid: "223ce16e-232c-492b-8bee-e53a4744e3d4",
            price: 173.508,
            price_sources: [],
            processed_ms: 1719951548340,
          },
          {
            leverage: 0.36000000000000004,
            order_type: "LONG",
            order_uuid: "63d8ef6e-63c7-4765-94ad-e86e396a32ab",
            price: 173.464,
            price_sources: [],
            processed_ms: 1719953425126,
          },
          {
            leverage: 0.8360000000000001,
            order_type: "LONG",
            order_uuid: "255723a3-c525-42b1-bd09-1853171c2f02",
            price: 173.471,
            price_sources: [],
            processed_ms: 1719953738467,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "f69e1936-88d6-46a0-a522-b6111074066c",
            price: 173.468,
            price_sources: [],
            processed_ms: 1719953772797,
          },
        ],
        position_type: "FLAT",
        position_uuid: "ccdc9fa5-db19-455c-9de3-f2376efad0e3",
        return_at_close: 0.9997864649788712,
        trade_pair: ["EURJPY", "EUR/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 173.44036904761901,
        close_ms: 1719954059160,
        current_return: 1.0000950596644955,
        initial_entry_price: 173.47,
        is_closed_position: true,
        miner_hotkey: "5D5GDbEa5Aedt9fXhWTzngDTANvnf8Q5poSFKYkU9hEZ7P8C",
        net_leverage: 0.0,
        open_ms: 1719953802917,
        orders: [
          {
            leverage: 0.2,
            order_type: "LONG",
            order_uuid: "67a17ec3-89c1-4ba1-bbb4-8709fb8f14e9",
            price: 173.47,
            price_sources: [],
            processed_ms: 1719953802733,
          },
          {
            leverage: 0.22000000000000003,
            order_type: "LONG",
            order_uuid: "a0671e8b-970f-4df2-87cb-2613ef6d16b8",
            price: 173.4905,
            price_sources: [],
            processed_ms: 1719953947169,
          },
          {
            leverage: 0.42000000000000004,
            order_type: "LONG",
            order_uuid: "91dddb7c-fbdd-426c-8d03-456162757114",
            price: 173.4,
            price_sources: [],
            processed_ms: 1719954010776,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "d2fa807b-a503-4bbe-8728-8fdbe0824751",
            price: 173.46,
            price_sources: [],
            processed_ms: 1719954059160,
          },
        ],
        position_type: "FLAT",
        position_uuid: "9933bcda-fce9-4bea-a80e-0533fd2a2208",
        return_at_close: 1.0000362540749872,
        trade_pair: ["EURJPY", "EUR/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 173.455,
        close_ms: 1719954299079,
        current_return: 0.9999884686346863,
        initial_entry_price: 173.44,
        is_closed_position: true,
        miner_hotkey: "5D5GDbEa5Aedt9fXhWTzngDTANvnf8Q5poSFKYkU9hEZ7P8C",
        net_leverage: 0.0,
        open_ms: 1719954089490,
        orders: [
          {
            leverage: 0.2,
            order_type: "LONG",
            order_uuid: "8e4c8a6a-7299-4f25-84f6-f4bbc44fc43a",
            price: 173.44,
            price_sources: [],
            processed_ms: 1719954088925,
          },
          {
            leverage: 0.2,
            order_type: "LONG",
            order_uuid: "7b3e2a39-0f77-462f-b136-6e91c09fa79c",
            price: 173.47,
            price_sources: [],
            processed_ms: 1719954159000,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "e4af86a8-071d-446b-bbab-c85d2508d17b",
            price: 173.45,
            price_sources: [],
            processed_ms: 1719954299079,
          },
        ],
        position_type: "FLAT",
        position_uuid: "c833a1e7-c6cf-40cd-baf3-da48bc9a3f39",
        return_at_close: 0.9999604689575645,
        trade_pair: ["EURJPY", "EUR/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 173.4213157894737,
        close_ms: 1719954584752,
        current_return: 1.0001256846353415,
        initial_entry_price: 173.45,
        is_closed_position: true,
        miner_hotkey: "5D5GDbEa5Aedt9fXhWTzngDTANvnf8Q5poSFKYkU9hEZ7P8C",
        net_leverage: 0.0,
        open_ms: 1719954341889,
        orders: [
          {
            leverage: 0.2,
            order_type: "LONG",
            order_uuid: "d163a2a3-6bcc-4631-ac1e-e3a72c053920",
            price: 173.45,
            price_sources: [],
            processed_ms: 1719954340615,
          },
          {
            leverage: 0.2,
            order_type: "LONG",
            order_uuid: "e4aa8754-653d-4c43-acd0-8d084e87dd63",
            price: 173.44,
            price_sources: [],
            processed_ms: 1719954399035,
          },
          {
            leverage: 0.36000000000000004,
            order_type: "LONG",
            order_uuid: "94491449-1b23-4b1a-bb5a-951d6d35885a",
            price: 173.395,
            price_sources: [],
            processed_ms: 1719954533111,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "495dba01-ed16-4d18-af8c-0baeaca0dee2",
            price: 173.45,
            price_sources: [],
            processed_ms: 1719954584752,
          },
        ],
        position_type: "FLAT",
        position_uuid: "1bdadbe8-d0db-4f56-a585-85343a7df089",
        return_at_close: 1.000072477948919,
        trade_pair: ["EURJPY", "EUR/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 173.44350931193978,
        close_ms: 1719955076951,
        current_return: 0.9999516520758218,
        initial_entry_price: 173.46,
        is_closed_position: true,
        miner_hotkey: "5D5GDbEa5Aedt9fXhWTzngDTANvnf8Q5poSFKYkU9hEZ7P8C",
        net_leverage: 0.0,
        open_ms: 1719954615341,
        orders: [
          {
            leverage: 0.2,
            order_type: "LONG",
            order_uuid: "dc81cab5-a6b4-4f8d-93d2-52e6de287d7c",
            price: 173.46,
            price_sources: [],
            processed_ms: 1719954614949,
          },
          {
            leverage: 0.18000000000000002,
            order_type: "LONG",
            order_uuid: "e96b818e-15f9-4cb8-b8d3-dd948573766f",
            price: 173.4565579495997,
            price_sources: [],
            processed_ms: 1719954654824,
          },
          {
            leverage: 0.41800000000000004,
            order_type: "LONG",
            order_uuid: "adefd6de-0e64-4fb6-93e7-2b6e4496a906",
            price: 173.43,
            price_sources: [],
            processed_ms: 1719955045564,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "7fa0badf-c0ab-4268-8b83-c81f80a43bfb",
            price: 173.433,
            price_sources: [],
            processed_ms: 1719955076951,
          },
        ],
        position_type: "FLAT",
        position_uuid: "6b0563b6-1362-4604-bb79-92eb1c2462e3",
        return_at_close: 0.9998957947765369,
        trade_pair: ["EURJPY", "EUR/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 173.39249999999998,
        close_ms: 1719955443252,
        current_return: 1.0001095542870322,
        initial_entry_price: 173.43,
        is_closed_position: true,
        miner_hotkey: "5D5GDbEa5Aedt9fXhWTzngDTANvnf8Q5poSFKYkU9hEZ7P8C",
        net_leverage: 0.0,
        open_ms: 1719955122094,
        orders: [
          {
            leverage: 0.2,
            order_type: "LONG",
            order_uuid: "dbc6191b-43c3-4bad-ae66-546b9b5c396c",
            price: 173.43,
            price_sources: [],
            processed_ms: 1719955120975,
          },
          {
            leverage: 0.2,
            order_type: "LONG",
            order_uuid: "496998c8-3b31-45f4-b98a-5141a4c244ee",
            price: 173.355,
            price_sources: [],
            processed_ms: 1719955248905,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "bcece00e-e112-46ab-84d9-4f9bc66651c0",
            price: 173.44,
            price_sources: [],
            processed_ms: 1719955443252,
          },
        ],
        position_type: "FLAT",
        position_uuid: "07b4129e-8fa9-49b0-af6a-082a8d454841",
        return_at_close: 1.000081551219512,
        trade_pair: ["EURJPY", "EUR/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 173.4347619047619,
        close_ms: 1719955589016,
        current_return: 0.9999769399285139,
        initial_entry_price: 173.46,
        is_closed_position: true,
        miner_hotkey: "5D5GDbEa5Aedt9fXhWTzngDTANvnf8Q5poSFKYkU9hEZ7P8C",
        net_leverage: 0.0,
        open_ms: 1719955474081,
        orders: [
          {
            leverage: 0.2,
            order_type: "LONG",
            order_uuid: "30aea4bc-d8b6-42e2-81a4-d02b95644a94",
            price: 173.46,
            price_sources: [],
            processed_ms: 1719955472885,
          },
          {
            leverage: 0.2,
            order_type: "LONG",
            order_uuid: "ee5dc232-07f4-40ca-aca9-125484f5ca9e",
            price: 173.42,
            price_sources: [],
            processed_ms: 1719955526788,
          },
          {
            leverage: 0.44000000000000006,
            order_type: "LONG",
            order_uuid: "d32f1f60-e614-4238-8b55-7f15f592092c",
            price: 173.43,
            price_sources: [],
            processed_ms: 1719955556925,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "c045b0ca-8fe4-4664-b7f6-77f1610454cb",
            price: 173.43,
            price_sources: [],
            processed_ms: 1719955589016,
          },
        ],
        position_type: "FLAT",
        position_uuid: "a2355354-737c-4253-8c57-0f882ed39bae",
        return_at_close: 0.9999181412844461,
        trade_pair: ["EURJPY", "EUR/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 173.41937950138504,
        close_ms: 1719955960988,
        current_return: 0.9998818482190738,
        initial_entry_price: 173.421,
        is_closed_position: true,
        miner_hotkey: "5D5GDbEa5Aedt9fXhWTzngDTANvnf8Q5poSFKYkU9hEZ7P8C",
        net_leverage: 0.0,
        open_ms: 1719955629384,
        orders: [
          {
            leverage: 0.2,
            order_type: "LONG",
            order_uuid: "a933f618-9777-4a69-ad7d-110f5ba1e95f",
            price: 173.421,
            price_sources: [],
            processed_ms: 1719955628870,
          },
          {
            leverage: 0.18000000000000002,
            order_type: "LONG",
            order_uuid: "935de3b8-3722-4aa8-a783-c2f7172f7606",
            price: 173.424,
            price_sources: [],
            processed_ms: 1719955662780,
          },
          {
            leverage: 0.342,
            order_type: "LONG",
            order_uuid: "8dc80958-e9e5-4e0e-8922-8b1e6d165b64",
            price: 173.416,
            price_sources: [],
            processed_ms: 1719955827019,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "74593cc9-7595-4ecb-b191-d2b82e1b354d",
            price: 173.391,
            price_sources: [],
            processed_ms: 1719955960988,
          },
        ],
        position_type: "FLAT",
        position_uuid: "e971de61-d5cd-441b-86dd-2342868c93a0",
        return_at_close: 0.9998313141904648,
        trade_pair: ["EURJPY", "EUR/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 173.4187368421053,
        close_ms: 1719956140857,
        current_return: 1.0000181069579157,
        initial_entry_price: 173.414,
        is_closed_position: true,
        miner_hotkey: "5D5GDbEa5Aedt9fXhWTzngDTANvnf8Q5poSFKYkU9hEZ7P8C",
        net_leverage: 0.0,
        open_ms: 1719955990819,
        orders: [
          {
            leverage: 0.2,
            order_type: "LONG",
            order_uuid: "59255108-59fb-453f-8d94-39a0da684e8e",
            price: 173.414,
            price_sources: [],
            processed_ms: 1719955990712,
          },
          {
            leverage: 0.18000000000000002,
            order_type: "LONG",
            order_uuid: "25ae6174-dfb6-4a62-b7b0-a84886abdf77",
            price: 173.424,
            price_sources: [],
            processed_ms: 1719956039000,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "98a8de0d-e77b-451e-ad04-b2360b594141",
            price: 173.427,
            price_sources: [],
            processed_ms: 1719956140857,
          },
        ],
        position_type: "FLAT",
        position_uuid: "cb47698c-810c-4937-9eae-e0a30dc550b6",
        return_at_close: 0.9999915064762707,
        trade_pair: ["EURJPY", "EUR/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 173.43057142857145,
        close_ms: 1719956412880,
        current_return: 1.000095479704797,
        initial_entry_price: 173.44,
        is_closed_position: true,
        miner_hotkey: "5D5GDbEa5Aedt9fXhWTzngDTANvnf8Q5poSFKYkU9hEZ7P8C",
        net_leverage: 0.0,
        open_ms: 1719956183808,
        orders: [
          {
            leverage: 0.2,
            order_type: "LONG",
            order_uuid: "9b774e7b-a95a-49c1-b50f-3044a7bcf046",
            price: 173.44,
            price_sources: [],
            processed_ms: 1719956180784,
          },
          {
            leverage: 0.22000000000000003,
            order_type: "LONG",
            order_uuid: "dc164078-8b78-4148-a7bd-c47710a92c7e",
            price: 173.422,
            price_sources: [],
            processed_ms: 1719956339157,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "40d934ce-56e1-4f23-9c3f-d08b2f362f96",
            price: 173.47,
            price_sources: [],
            processed_ms: 1719956412880,
          },
        ],
        position_type: "FLAT",
        position_uuid: "e775555f-df94-4aea-ad13-1bd67330ad14",
        return_at_close: 1.0000660768976937,
        trade_pair: ["EURJPY", "EUR/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 173.46115789473683,
        close_ms: 1719956652967,
        current_return: 1.0000149887008256,
        initial_entry_price: 173.464,
        is_closed_position: true,
        miner_hotkey: "5D5GDbEa5Aedt9fXhWTzngDTANvnf8Q5poSFKYkU9hEZ7P8C",
        net_leverage: 0.0,
        open_ms: 1719956443549,
        orders: [
          {
            leverage: 0.2,
            order_type: "LONG",
            order_uuid: "617f6ba1-14a7-4b93-934d-6501c42bd995",
            price: 173.464,
            price_sources: [],
            processed_ms: 1719956442951,
          },
          {
            leverage: 0.18000000000000002,
            order_type: "LONG",
            order_uuid: "7eb1e6c9-d098-4196-b3cb-c4c24a967296",
            price: 173.458,
            price_sources: [],
            processed_ms: 1719956504794,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "92405a59-0b18-4730-9ad0-d4ad690438f1",
            price: 173.468,
            price_sources: [],
            processed_ms: 1719956652967,
          },
        ],
        position_type: "FLAT",
        position_uuid: "c1965881-e2bb-4439-81a5-d084ceaad0fa",
        return_at_close: 0.9999883883021262,
        trade_pair: ["EURJPY", "EUR/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 173.44960150375937,
        close_ms: 1719957100935,
        current_return: 1.0001168117111408,
        initial_entry_price: 173.51,
        is_closed_position: true,
        miner_hotkey: "5D5GDbEa5Aedt9fXhWTzngDTANvnf8Q5poSFKYkU9hEZ7P8C",
        net_leverage: 0.0,
        open_ms: 1719956685942,
        orders: [
          {
            leverage: 0.2,
            order_type: "LONG",
            order_uuid: "b6bb87cd-9ac5-4cfb-a9a2-8fbbed1716b0",
            price: 173.51,
            price_sources: [],
            processed_ms: 1719956684967,
          },
          {
            leverage: 0.18000000000000002,
            order_type: "LONG",
            order_uuid: "9ba9827e-543b-4ed2-b942-122c13ef8c16",
            price: 173.5,
            price_sources: [],
            processed_ms: 1719956761167,
          },
          {
            leverage: 0.41800000000000004,
            order_type: "LONG",
            order_uuid: "c74eaf76-4a84-4734-8d6b-eab45b57793d",
            price: 173.399,
            price_sources: [],
            processed_ms: 1719956988033,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "ab8e7d57-56d3-43a4-970e-f98bc20cdb3d",
            price: 173.475,
            price_sources: [],
            processed_ms: 1719957100935,
          },
        ],
        position_type: "FLAT",
        position_uuid: "02e8ddf6-6f36-4aa6-bb2c-dba6f3a8fe18",
        return_at_close: 1.0000609451860385,
        trade_pair: ["EURJPY", "EUR/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 173.47,
        close_ms: 1719957624851,
        current_return: 0.9999538851740835,
        initial_entry_price: 173.48,
        is_closed_position: true,
        miner_hotkey: "5D5GDbEa5Aedt9fXhWTzngDTANvnf8Q5poSFKYkU9hEZ7P8C",
        net_leverage: 0.0,
        open_ms: 1719957137257,
        orders: [
          {
            leverage: 0.2,
            order_type: "LONG",
            order_uuid: "a4aa3b9c-b452-445c-8a84-1487c5762214",
            price: 173.48,
            price_sources: [],
            processed_ms: 1719957136785,
          },
          {
            leverage: 0.2,
            order_type: "LONG",
            order_uuid: "dbb657fb-69f5-4b20-8a54-9c097b20406b",
            price: 173.48,
            price_sources: [],
            processed_ms: 1719957244234,
          },
          {
            leverage: 0.4,
            order_type: "LONG",
            order_uuid: "f51e86f4-23f4-4166-b91b-003af3969bbe",
            price: 173.46,
            price_sources: [],
            processed_ms: 1719957573368,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "0164977c-faa6-4f11-8793-8523922b17ff",
            price: 173.46,
            price_sources: [],
            processed_ms: 1719957624851,
          },
        ],
        position_type: "FLAT",
        position_uuid: "691ff9c9-c486-4d4b-9441-308f72eb636d",
        return_at_close: 0.9998978877565138,
        trade_pair: ["EURJPY", "EUR/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 173.45999999999998,
        close_ms: 1719963192068,
        current_return: 1.0001429724432147,
        initial_entry_price: 173.46,
        is_closed_position: true,
        miner_hotkey: "5D5GDbEa5Aedt9fXhWTzngDTANvnf8Q5poSFKYkU9hEZ7P8C",
        net_leverage: 0.0,
        open_ms: 1719957655107,
        orders: [
          {
            leverage: 0.2,
            order_type: "LONG",
            order_uuid: "919e9380-90ea-4494-b097-83395f14bbeb",
            price: 173.46,
            price_sources: [],
            processed_ms: 1719957654902,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "117ce1a0-10d3-4fd1-ad0d-c9c81222417f",
            price: 173.584,
            price_sources: [],
            processed_ms: 1719963192068,
          },
        ],
        position_type: "FLAT",
        position_uuid: "f52d28ff-4f73-4155-a8ec-a2085b6fc420",
        return_at_close: 1.0001289704416005,
        trade_pair: ["EURJPY", "EUR/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 173.5627619047619,
        close_ms: 1719964365312,
        current_return: 1.0000688999625542,
        initial_entry_price: 173.585,
        is_closed_position: true,
        miner_hotkey: "5D5GDbEa5Aedt9fXhWTzngDTANvnf8Q5poSFKYkU9hEZ7P8C",
        net_leverage: 0.0,
        open_ms: 1719963222025,
        orders: [
          {
            leverage: 0.2,
            order_type: "LONG",
            order_uuid: "f1840d51-5774-445f-831f-807b02da1fb8",
            price: 173.585,
            price_sources: [],
            processed_ms: 1719963221795,
          },
          {
            leverage: 0.22000000000000003,
            order_type: "LONG",
            order_uuid: "f5216817-6ed1-47f9-abe0-cdaa24908c1f",
            price: 173.565,
            price_sources: [],
            processed_ms: 1719963319946,
          },
          {
            leverage: 0.42000000000000004,
            order_type: "LONG",
            order_uuid: "9b5a1589-db54-4ce8-bfa7-725df7157e3a",
            price: 173.551,
            price_sources: [],
            processed_ms: 1719964010973,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "fa9d2fd0-7809-4b0d-b91f-9823185430cf",
            price: 173.577,
            price_sources: [],
            processed_ms: 1719964365312,
          },
        ],
        position_type: "FLAT",
        position_uuid: "70d6fae1-e3f4-462d-a48a-4a219f2afe44",
        return_at_close: 1.0000100959112364,
        trade_pair: ["EURJPY", "EUR/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 173.5695,
        close_ms: 1719965052842,
        current_return: 1.0001094615069968,
        initial_entry_price: 173.577,
        is_closed_position: true,
        miner_hotkey: "5D5GDbEa5Aedt9fXhWTzngDTANvnf8Q5poSFKYkU9hEZ7P8C",
        net_leverage: 0.0,
        open_ms: 1719964399037,
        orders: [
          {
            leverage: 0.2,
            order_type: "LONG",
            order_uuid: "7de8b98a-9234-441e-8f58-42a4d063911d",
            price: 173.577,
            price_sources: [],
            processed_ms: 1719964398246,
          },
          {
            leverage: 0.2,
            order_type: "LONG",
            order_uuid: "d9ae6280-6054-4e35-b18b-ec5dd33a243e",
            price: 173.562,
            price_sources: [],
            processed_ms: 1719964573014,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "3fd5eb8c-8706-49ee-86cd-00a7c00f246d",
            price: 173.617,
            price_sources: [],
            processed_ms: 1719965052842,
          },
        ],
        position_type: "FLAT",
        position_uuid: "abcef0e9-00b5-47be-8476-f1a25b95dea0",
        return_at_close: 1.0000814584420745,
        trade_pair: ["EURJPY", "EUR/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 173.61025,
        close_ms: 1719966272678,
        current_return: 1.0001630062264923,
        initial_entry_price: 173.613,
        is_closed_position: true,
        miner_hotkey: "5D5GDbEa5Aedt9fXhWTzngDTANvnf8Q5poSFKYkU9hEZ7P8C",
        net_leverage: 0.0,
        open_ms: 1719965083459,
        orders: [
          {
            leverage: 0.2,
            order_type: "LONG",
            order_uuid: "06cfb09b-a785-41d0-9150-8dfb54e6ca48",
            price: 173.613,
            price_sources: [],
            processed_ms: 1719965082963,
          },
          {
            leverage: 0.2,
            order_type: "LONG",
            order_uuid: "e995e287-1ef6-4268-b49f-0e0f59bd902b",
            price: 173.60750000000002,
            price_sources: [],
            processed_ms: 1719965257596,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "bc59f63a-81ef-49ae-9de6-59c558fb74e1",
            price: 173.681,
            price_sources: [],
            processed_ms: 1719966272678,
          },
        ],
        position_type: "FLAT",
        position_uuid: "48c6cfbd-a7d4-48c9-b03b-9f69635f1326",
        return_at_close: 1.000135001662318,
        trade_pair: ["EURJPY", "EUR/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 173.6314962406015,
        close_ms: 1719967946910,
        current_return: 0.9998943586554738,
        initial_entry_price: 173.682,
        is_closed_position: true,
        miner_hotkey: "5D5GDbEa5Aedt9fXhWTzngDTANvnf8Q5poSFKYkU9hEZ7P8C",
        net_leverage: 0.0,
        open_ms: 1719966304182,
        orders: [
          {
            leverage: 0.2,
            order_type: "LONG",
            order_uuid: "c2a99eaf-ff6e-4a25-a4e6-749ef81f95d0",
            price: 173.682,
            price_sources: [],
            processed_ms: 1719966301784,
          },
          {
            leverage: 0.2,
            order_type: "LONG",
            order_uuid: "4802b7a5-ddef-4d6d-af4a-e18a262f5065",
            price: 173.6455,
            price_sources: [],
            processed_ms: 1719966542280,
          },
          {
            leverage: 0.36000000000000004,
            order_type: "LONG",
            order_uuid: "29a20d71-cec3-47df-aadb-a386d7dd77d0",
            price: 173.627,
            price_sources: [],
            processed_ms: 1719966956603,
          },
          {
            leverage: 0.8360000000000001,
            order_type: "LONG",
            order_uuid: "8678a39a-6a82-4dbd-92c4-a098baa9cc6f",
            price: 173.618,
            price_sources: [],
            processed_ms: 1719967885444,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "2661d85a-59a4-4210-9f34-0eabd9d95f11",
            price: 173.62,
            price_sources: [],
            processed_ms: 1719967946910,
          },
        ],
        position_type: "FLAT",
        position_uuid: "782a640d-a384-42b1-978b-ef01c8dbd5d4",
        return_at_close: 0.9997826504577249,
        trade_pair: ["EURJPY", "EUR/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 173.59714285714287,
        close_ms: 1719969522608,
        current_return: 1.0001444450843748,
        initial_entry_price: 173.63,
        is_closed_position: true,
        miner_hotkey: "5D5GDbEa5Aedt9fXhWTzngDTANvnf8Q5poSFKYkU9hEZ7P8C",
        net_leverage: 0.0,
        open_ms: 1719967979081,
        orders: [
          {
            leverage: 0.2,
            order_type: "LONG",
            order_uuid: "9e1e87aa-1738-4158-bebb-efe2cc9c91d5",
            price: 173.63,
            price_sources: [],
            processed_ms: 1719967978563,
          },
          {
            leverage: 0.22000000000000003,
            order_type: "LONG",
            order_uuid: "00e277ba-d025-4647-88e5-8c58dc7954bf",
            price: 173.6,
            price_sources: [],
            processed_ms: 1719968704140,
          },
          {
            leverage: 0.42000000000000004,
            order_type: "LONG",
            order_uuid: "3d015910-7982-4dd7-a664-442403eef51c",
            price: 173.58,
            price_sources: [],
            processed_ms: 1719969062439,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "dadbb553-5e53-4e15-9254-e72260491641",
            price: 173.627,
            price_sources: [],
            processed_ms: 1719969522608,
          },
        ],
        position_type: "FLAT",
        position_uuid: "1f1f61fe-89fd-420e-934d-af31e0266cb1",
        return_at_close: 1.0000856365910038,
        trade_pair: ["EURJPY", "EUR/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 173.60046240601505,
        close_ms: 1719971891095,
        current_return: 1.0000852011092904,
        initial_entry_price: 173.6245,
        is_closed_position: true,
        miner_hotkey: "5D5GDbEa5Aedt9fXhWTzngDTANvnf8Q5poSFKYkU9hEZ7P8C",
        net_leverage: 0.0,
        open_ms: 1719969552469,
        orders: [
          {
            leverage: 0.2,
            order_type: "LONG",
            order_uuid: "94055c6b-00e4-4833-96a5-5edc59ee0a61",
            price: 173.6245,
            price_sources: [],
            processed_ms: 1719969551885,
          },
          {
            leverage: 0.22000000000000003,
            order_type: "LONG",
            order_uuid: "866b24eb-1ae1-436f-8cf3-b8636647144e",
            price: 173.6215,
            price_sources: [],
            processed_ms: 1719969589730,
          },
          {
            leverage: 0.37800000000000006,
            order_type: "LONG",
            order_uuid: "1165d2fb-237f-40c1-bef9-dcedc935fcfa",
            price: 173.5755,
            price_sources: [],
            processed_ms: 1719971637919,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "f2e6cea2-6b97-4f2f-b0c6-cf58cf00083d",
            price: 173.619,
            price_sources: [],
            processed_ms: 1719971891095,
          },
        ],
        position_type: "FLAT",
        position_uuid: "597bca79-bb00-4329-9c7f-b1ac01a5e867",
        return_at_close: 1.0000293363499564,
        trade_pair: ["EURJPY", "EUR/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 173.6125,
        close_ms: 1719975352298,
        current_return: 1.0000840916945053,
        initial_entry_price: 173.62,
        is_closed_position: true,
        miner_hotkey: "5D5GDbEa5Aedt9fXhWTzngDTANvnf8Q5poSFKYkU9hEZ7P8C",
        net_leverage: 0.0,
        open_ms: 1719971920903,
        orders: [
          {
            leverage: 0.2,
            order_type: "LONG",
            order_uuid: "abd539f5-b98a-4cd4-9ca4-563ba9c80d15",
            price: 173.62,
            price_sources: [],
            processed_ms: 1719971920884,
          },
          {
            leverage: 0.2,
            order_type: "LONG",
            order_uuid: "6cab44ad-63ab-4414-a8e6-5e6af5f4def6",
            price: 173.60500000000002,
            price_sources: [],
            processed_ms: 1719974467323,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "59b09181-477b-4a5d-90d7-fb397583059b",
            price: 173.649,
            price_sources: [],
            processed_ms: 1719975352298,
          },
        ],
        position_type: "FLAT",
        position_uuid: "25b5fec5-b64b-4c62-9092-57a704252bb8",
        return_at_close: 1.0000560893399377,
        trade_pair: ["EURJPY", "EUR/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 173.63500000000002,
        close_ms: 1719977582217,
        current_return: 1.0001117318435753,
        initial_entry_price: 173.63,
        is_closed_position: true,
        miner_hotkey: "5D5GDbEa5Aedt9fXhWTzngDTANvnf8Q5poSFKYkU9hEZ7P8C",
        net_leverage: 0.0,
        open_ms: 1719975381732,
        orders: [
          {
            leverage: 0.2,
            order_type: "LONG",
            order_uuid: "54e04d6c-a0ad-4049-b7ce-1ed3772de116",
            price: 173.63,
            price_sources: [],
            processed_ms: 1719975381710,
          },
          {
            leverage: 0.2,
            order_type: "LONG",
            order_uuid: "e74e930d-3021-4f68-bf00-69b870fb10c4",
            price: 173.64,
            price_sources: [],
            processed_ms: 1719976954927,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "5d9d7dbe-e355-43b6-9637-e6f13b154d82",
            price: 173.68349999999998,
            price_sources: [],
            processed_ms: 1719977582217,
          },
        ],
        position_type: "FLAT",
        position_uuid: "d38832bc-fe69-4e62-949c-6d4f259f0787",
        return_at_close: 1.0000837287150837,
        trade_pair: ["EURJPY", "EUR/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 173.65095290858727,
        close_ms: 1719980422722,
        current_return: 0.999975450020583,
        initial_entry_price: 173.68650000000002,
        is_closed_position: true,
        miner_hotkey: "5D5GDbEa5Aedt9fXhWTzngDTANvnf8Q5poSFKYkU9hEZ7P8C",
        net_leverage: 0.0,
        open_ms: 1719977614635,
        orders: [
          {
            leverage: 0.2,
            order_type: "LONG",
            order_uuid: "20dd8736-a4ee-4bd2-bfd8-46f38b200372",
            price: 173.68650000000002,
            price_sources: [],
            processed_ms: 1719977614065,
          },
          {
            leverage: 0.2,
            order_type: "LONG",
            order_uuid: "91d9469d-fec2-4ec4-b2fa-a143a365339b",
            price: 173.6715,
            price_sources: [],
            processed_ms: 1719978022549,
          },
          {
            leverage: 0.36000000000000004,
            order_type: "LONG",
            order_uuid: "e1dc72ea-120c-49e7-a1ca-6ae3684caecf",
            price: 173.652,
            price_sources: [],
            processed_ms: 1719978847433,
          },
          {
            leverage: 0.684,
            order_type: "LONG",
            order_uuid: "1ddc0f3f-255c-485e-a809-0d130faf280f",
            price: 173.634,
            price_sources: [],
            processed_ms: 1719979368409,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "4b4b75fa-c70d-4a9f-9122-ab151ff74fbf",
            price: 173.648,
            price_sources: [],
            processed_ms: 1719980422722,
          },
        ],
        position_type: "FLAT",
        position_uuid: "bc362579-f291-4e40-88b9-f4743d3f00bd",
        return_at_close: 0.9998743725020949,
        trade_pair: ["EURJPY", "EUR/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 173.65699999999998,
        close_ms: 1719984140241,
        current_return: 1.0001266865142207,
        initial_entry_price: 173.65699999999998,
        is_closed_position: true,
        miner_hotkey: "5D5GDbEa5Aedt9fXhWTzngDTANvnf8Q5poSFKYkU9hEZ7P8C",
        net_leverage: 0.0,
        open_ms: 1719980452413,
        orders: [
          {
            leverage: 0.2,
            order_type: "LONG",
            order_uuid: "8a6d549e-2aff-4605-854b-7cf5aef654ff",
            price: 173.65699999999998,
            price_sources: [],
            processed_ms: 1719980451788,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "8ad4a203-d939-402c-9645-8d641af1e1d0",
            price: 173.767,
            price_sources: [],
            processed_ms: 1719984140241,
          },
        ],
        position_type: "FLAT",
        position_uuid: "5cf867d9-abb6-49f3-88e4-1ba612597aa2",
        return_at_close: 1.0001126847406094,
        trade_pair: ["EURJPY", "EUR/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 173.77219047619047,
        close_ms: 1719985902626,
        current_return: 1.0001131357773192,
        initial_entry_price: 173.7735,
        is_closed_position: true,
        miner_hotkey: "5D5GDbEa5Aedt9fXhWTzngDTANvnf8Q5poSFKYkU9hEZ7P8C",
        net_leverage: 0.0,
        open_ms: 1719984168220,
        orders: [
          {
            leverage: 0.2,
            order_type: "LONG",
            order_uuid: "1a273637-4fef-44db-8de4-befed7a3205e",
            price: 173.7735,
            price_sources: [],
            processed_ms: 1719984167780,
          },
          {
            leverage: 0.22000000000000003,
            order_type: "LONG",
            order_uuid: "690028f1-c9e7-424b-a33d-2f5981b9e781",
            price: 173.771,
            price_sources: [],
            processed_ms: 1719984250049,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "cd445b02-f380-432f-a2ae-7cda513cd33f",
            price: 173.819,
            price_sources: [],
            processed_ms: 1719985902626,
          },
        ],
        position_type: "FLAT",
        position_uuid: "01d6dd49-ca7b-4ce2-899c-1e7cdc6bc270",
        return_at_close: 1.0000837324511274,
        trade_pair: ["EURJPY", "EUR/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 173.8162380952381,
        close_ms: 1719986579211,
        current_return: 1.0000658719839837,
        initial_entry_price: 173.822,
        is_closed_position: true,
        miner_hotkey: "5D5GDbEa5Aedt9fXhWTzngDTANvnf8Q5poSFKYkU9hEZ7P8C",
        net_leverage: 0.0,
        open_ms: 1719985930872,
        orders: [
          {
            leverage: 0.2,
            order_type: "LONG",
            order_uuid: "c741d059-0591-4cc7-abb0-a1351995f33b",
            price: 173.822,
            price_sources: [],
            processed_ms: 1719985930257,
          },
          {
            leverage: 0.22000000000000003,
            order_type: "LONG",
            order_uuid: "5abce338-b80b-4836-8e17-19189c62dbcc",
            price: 173.811,
            price_sources: [],
            processed_ms: 1719985959732,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "8fcb6001-800a-4bc4-be80-d6dea39171f0",
            price: 173.8435,
            price_sources: [],
            processed_ms: 1719986579211,
          },
        ],
        position_type: "FLAT",
        position_uuid: "7fc6ec09-7063-450a-b6ad-a9c1728892fb",
        return_at_close: 1.0000364700473474,
        trade_pair: ["EURJPY", "EUR/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 173.8415263157895,
        close_ms: 1719988328361,
        current_return: 1.000132188999206,
        initial_entry_price: 173.842,
        is_closed_position: true,
        miner_hotkey: "5D5GDbEa5Aedt9fXhWTzngDTANvnf8Q5poSFKYkU9hEZ7P8C",
        net_leverage: 0.0,
        open_ms: 1719986609482,
        orders: [
          {
            leverage: 0.2,
            order_type: "LONG",
            order_uuid: "13b0ce79-1307-4c37-8afc-c3a951a9fd64",
            price: 173.842,
            price_sources: [],
            processed_ms: 1719986609020,
          },
          {
            leverage: 0.18000000000000002,
            order_type: "LONG",
            order_uuid: "da8cfcfd-7b66-4974-9cc6-f04bce1fb22e",
            price: 173.841,
            price_sources: [],
            processed_ms: 1719986663111,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "4533e390-4a27-4fb5-882b-92611953b674",
            price: 173.902,
            price_sources: [],
            processed_ms: 1719988328361,
          },
        ],
        position_type: "FLAT",
        position_uuid: "f67aaec3-5ebb-47b5-8d08-81aed915ac7e",
        return_at_close: 1.0001055854829788,
        trade_pair: ["EURJPY", "EUR/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 173.8362716911326,
        close_ms: 1719988986020,
        current_return: 0.9999369483904169,
        initial_entry_price: 173.9115,
        is_closed_position: true,
        miner_hotkey: "5D5GDbEa5Aedt9fXhWTzngDTANvnf8Q5poSFKYkU9hEZ7P8C",
        net_leverage: 0.0,
        open_ms: 1719988358216,
        orders: [
          {
            leverage: 0.2,
            order_type: "LONG",
            order_uuid: "017c7fd3-a36d-4d89-b8fa-6b482e612402",
            price: 173.9115,
            price_sources: [],
            processed_ms: 1719988357838,
          },
          {
            leverage: 0.22000000000000003,
            order_type: "LONG",
            order_uuid: "13c25049-798e-45ad-857e-2ac76b87064c",
            price: 173.89,
            price_sources: [],
            processed_ms: 1719988397955,
          },
          {
            leverage: 0.37800000000000006,
            order_type: "LONG",
            order_uuid: "17a399c3-99ab-4493-8033-ae8d77569d5e",
            price: 173.8785,
            price_sources: [],
            processed_ms: 1719988560143,
          },
          {
            leverage: 0.798,
            order_type: "LONG",
            order_uuid: "98d5bafc-a838-4ac1-b00e-7c51614e35a4",
            price: 173.814,
            price_sources: [],
            processed_ms: 1719988820376,
          },
          {
            leverage: 1.7556000000000003,
            order_type: "LONG",
            order_uuid: "7942658e-c1e7-426a-9e8e-e9f6d85dde16",
            price: 173.822,
            price_sources: [],
            processed_ms: 1719988885813,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "ef4897ed-c2bf-46b6-9d0b-9f131f2682d8",
            price: 173.833,
            price_sources: [],
            processed_ms: 1719988986020,
          },
        ],
        position_type: "FLAT",
        position_uuid: "da93408b-90d9-47d5-aa40-be3fea563313",
        return_at_close: 0.9997023511830812,
        trade_pair: ["EURJPY", "EUR/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 173.73971428571429,
        close_ms: 1719989310234,
        current_return: 1.0002033155274035,
        initial_entry_price: 173.8185,
        is_closed_position: true,
        miner_hotkey: "5D5GDbEa5Aedt9fXhWTzngDTANvnf8Q5poSFKYkU9hEZ7P8C",
        net_leverage: 0.0,
        open_ms: 1719989016329,
        orders: [
          {
            leverage: 0.2,
            order_type: "LONG",
            order_uuid: "8c8718de-2903-457e-a4db-dd94ff0b25b3",
            price: 173.8185,
            price_sources: [],
            processed_ms: 1719989016014,
          },
          {
            leverage: 0.22000000000000003,
            order_type: "LONG",
            order_uuid: "89924a9c-68f8-4e32-a470-acbf57f00f6a",
            price: 173.781,
            price_sources: [],
            processed_ms: 1719989045884,
          },
          {
            leverage: 0.37800000000000006,
            order_type: "LONG",
            order_uuid: "fca016dc-95a1-4d30-9072-2969d072cef5",
            price: 173.674,
            price_sources: [],
            processed_ms: 1719989111851,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "7dadba18-eafa-403a-9103-22cfb7086276",
            price: 173.784,
            price_sources: [],
            processed_ms: 1719989310234,
          },
        ],
        position_type: "FLAT",
        position_uuid: "37d1ffcd-bbd3-46ba-84b9-6094af6d0ab6",
        return_at_close: 1.000147444170198,
        trade_pair: ["EURJPY", "EUR/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 173.7625,
        close_ms: 1719989965010,
        current_return: 1.0001605639882023,
        initial_entry_price: 173.7625,
        is_closed_position: true,
        miner_hotkey: "5D5GDbEa5Aedt9fXhWTzngDTANvnf8Q5poSFKYkU9hEZ7P8C",
        net_leverage: 0.0,
        open_ms: 1719989340216,
        orders: [
          {
            leverage: 0.2,
            order_type: "LONG",
            order_uuid: "8649153e-2521-4439-8d68-fcec2a2bb38e",
            price: 173.7625,
            price_sources: [],
            processed_ms: 1719989339966,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "b621b7e8-1d18-4fc0-b12f-6e84cee412d2",
            price: 173.902,
            price_sources: [],
            processed_ms: 1719989965010,
          },
        ],
        position_type: "FLAT",
        position_uuid: "15401f91-c3dd-4f6a-a5b0-ceb6668d6ba1",
        return_at_close: 1.0001465617403065,
        trade_pair: ["EURJPY", "EUR/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 173.898,
        close_ms: 1719990225356,
        current_return: 1.0001771153204753,
        initial_entry_price: 173.898,
        is_closed_position: true,
        miner_hotkey: "5D5GDbEa5Aedt9fXhWTzngDTANvnf8Q5poSFKYkU9hEZ7P8C",
        net_leverage: 0.0,
        open_ms: 1719989995476,
        orders: [
          {
            leverage: 0.2,
            order_type: "LONG",
            order_uuid: "54b0f06e-1847-4baf-a676-2ddc2fc185a2",
            price: 173.898,
            price_sources: [],
            processed_ms: 1719989994786,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "68e98435-2019-462a-a244-1f7047674122",
            price: 174.052,
            price_sources: [],
            processed_ms: 1719990225356,
          },
        ],
        position_type: "FLAT",
        position_uuid: "85a5a3f7-9a8f-436b-81c3-767b4142924c",
        return_at_close: 1.0001631128408608,
        trade_pair: ["EURJPY", "EUR/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 174.02900000000002,
        close_ms: 1719990396960,
        current_return: 1.0001436467897815,
        initial_entry_price: 174.038,
        is_closed_position: true,
        miner_hotkey: "5D5GDbEa5Aedt9fXhWTzngDTANvnf8Q5poSFKYkU9hEZ7P8C",
        net_leverage: 0.0,
        open_ms: 1719990255107,
        orders: [
          {
            leverage: 0.2,
            order_type: "LONG",
            order_uuid: "e71c5bf5-6578-4f03-b8c3-75274dce4c7d",
            price: 174.038,
            price_sources: [],
            processed_ms: 1719990254775,
          },
          {
            leverage: 0.2,
            order_type: "LONG",
            order_uuid: "4f418ca8-4e34-44de-9a9b-bc78aefe3fdc",
            price: 174.02,
            price_sources: [],
            processed_ms: 1719990291003,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "f82aac97-c02f-46e1-b328-546ab7b97c49",
            price: 174.0915,
            price_sources: [],
            processed_ms: 1719990396960,
          },
        ],
        position_type: "FLAT",
        position_uuid: "b3a4096e-e67b-48d9-87b4-221387191bb0",
        return_at_close: 1.0001156427676714,
        trade_pair: ["EURJPY", "EUR/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 174.0433125,
        close_ms: 1719990837253,
        current_return: 1.0002085098439046,
        initial_entry_price: 174.0925,
        is_closed_position: true,
        miner_hotkey: "5D5GDbEa5Aedt9fXhWTzngDTANvnf8Q5poSFKYkU9hEZ7P8C",
        net_leverage: 0.0,
        open_ms: 1719990427329,
        orders: [
          {
            leverage: 0.2,
            order_type: "LONG",
            order_uuid: "8ea3ad60-3161-4cbb-ae92-01c8543d7298",
            price: 174.0925,
            price_sources: [],
            processed_ms: 1719990426939,
          },
          {
            leverage: 0.2,
            order_type: "LONG",
            order_uuid: "40b26fe3-b9d6-4a3c-9f1e-d93132f601df",
            price: 174.068,
            price_sources: [],
            processed_ms: 1719990558256,
          },
          {
            leverage: 0.4,
            order_type: "LONG",
            order_uuid: "c8a0196a-01c9-4f5a-a4bc-cc95c89d635b",
            price: 174.041,
            price_sources: [],
            processed_ms: 1719990635030,
          },
          {
            leverage: 0.8,
            order_type: "LONG",
            order_uuid: "4e14315c-144b-454a-b8ff-ca87e0ddd477",
            price: 174.026,
            price_sources: [],
            processed_ms: 1719990699057,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "1a6693dc-78a6-4b1d-ab1b-5f8e281a1808",
            price: 174.066,
            price_sources: [],
            processed_ms: 1719990837253,
          },
        ],
        position_type: "FLAT",
        position_uuid: "d2dd3e95-7b36-4c2c-a0d8-28bc2411a4b2",
        return_at_close: 1.0000964864908022,
        trade_pair: ["EURJPY", "EUR/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 174.05339473684214,
        close_ms: 1719991489708,
        current_return: 1.0001061152732453,
        initial_entry_price: 174.056,
        is_closed_position: true,
        miner_hotkey: "5D5GDbEa5Aedt9fXhWTzngDTANvnf8Q5poSFKYkU9hEZ7P8C",
        net_leverage: 0.0,
        open_ms: 1719990867430,
        orders: [
          {
            leverage: 0.2,
            order_type: "LONG",
            order_uuid: "0c5cf161-4df8-4d5a-8156-bfa3711b88d9",
            price: 174.056,
            price_sources: [],
            processed_ms: 1719990866999,
          },
          {
            leverage: 0.18000000000000002,
            order_type: "LONG",
            order_uuid: "fec58354-4930-4a32-86cb-09620fe3f2df",
            price: 174.0505,
            price_sources: [],
            processed_ms: 1719990900945,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "ae7c2bcb-0c5f-476a-b359-0592802318cc",
            price: 174.102,
            price_sources: [],
            processed_ms: 1719991489708,
          },
        ],
        position_type: "FLAT",
        position_uuid: "1e441431-a832-41c4-bf91-ca9f26700773",
        return_at_close: 1.000079512450579,
        trade_pair: ["EURJPY", "EUR/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 174.08686394557824,
        close_ms: 1719992054200,
        current_return: 1.000213431652941,
        initial_entry_price: 174.126,
        is_closed_position: true,
        miner_hotkey: "5D5GDbEa5Aedt9fXhWTzngDTANvnf8Q5poSFKYkU9hEZ7P8C",
        net_leverage: 0.0,
        open_ms: 1719991518178,
        orders: [
          {
            leverage: 0.2,
            order_type: "LONG",
            order_uuid: "483230bb-011b-44a3-bdc0-6384425b852b",
            price: 174.126,
            price_sources: [],
            processed_ms: 1719991517948,
          },
          {
            leverage: 0.22000000000000003,
            order_type: "LONG",
            order_uuid: "e16910d5-be65-471a-b2c5-29222437a7d7",
            price: 174.072,
            price_sources: [],
            processed_ms: 1719991806436,
          },
          {
            leverage: 0.4620000000000001,
            order_type: "LONG",
            order_uuid: "01b527ad-046f-4d81-8944-425c096b1c97",
            price: 174.077,
            price_sources: [],
            processed_ms: 1719991845953,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "9b4da2ae-7c82-4219-ae3e-1c27d03a9994",
            price: 174.12900000000002,
            price_sources: [],
            processed_ms: 1719992054200,
          },
        ],
        position_type: "FLAT",
        position_uuid: "c4b7f554-c7ee-46a8-8e46-6f991bde71c1",
        return_at_close: 1.0001516784756708,
        trade_pair: ["EURJPY", "EUR/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 174.09745578231292,
        close_ms: 1719993073572,
        current_return: 1.0001243266215643,
        initial_entry_price: 174.122,
        is_closed_position: true,
        miner_hotkey: "5D5GDbEa5Aedt9fXhWTzngDTANvnf8Q5poSFKYkU9hEZ7P8C",
        net_leverage: 0.0,
        open_ms: 1719992084323,
        orders: [
          {
            leverage: 0.2,
            order_type: "LONG",
            order_uuid: "a7a1fbd7-cab0-4e74-bb48-d4bd9fa97097",
            price: 174.122,
            price_sources: [],
            processed_ms: 1719992083846,
          },
          {
            leverage: 0.22000000000000003,
            order_type: "LONG",
            order_uuid: "8761a4cd-a59f-4e99-b68a-c688d5209b2a",
            price: 174.116,
            price_sources: [],
            processed_ms: 1719992201013,
          },
          {
            leverage: 0.4620000000000001,
            order_type: "LONG",
            order_uuid: "cb046d58-984b-4f6d-a187-1b540ad26896",
            price: 174.078,
            price_sources: [],
            processed_ms: 1719992510713,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "91863dc0-2930-4c35-87bc-6c737b74fb77",
            price: 174.122,
            price_sources: [],
            processed_ms: 1719993073572,
          },
        ],
        position_type: "FLAT",
        position_uuid: "b702c1f1-6fa4-4fc1-ba3b-5d5484164f07",
        return_at_close: 1.0000625789456385,
        trade_pair: ["EURJPY", "EUR/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 174.03958084268845,
        close_ms: 1719994005294,
        current_return: 0.9999120619350093,
        initial_entry_price: 174.118,
        is_closed_position: true,
        miner_hotkey: "5D5GDbEa5Aedt9fXhWTzngDTANvnf8Q5poSFKYkU9hEZ7P8C",
        net_leverage: 0.0,
        open_ms: 1719993100947,
        orders: [
          {
            leverage: 0.2,
            order_type: "LONG",
            order_uuid: "787b7232-e20e-447e-985e-f8c9432ab15f",
            price: 174.118,
            price_sources: [],
            processed_ms: 1719993100868,
          },
          {
            leverage: 0.18000000000000002,
            order_type: "LONG",
            order_uuid: "2bccc84b-5936-47df-832c-fe84b4014379",
            price: 174.081,
            price_sources: [],
            processed_ms: 1719993239336,
          },
          {
            leverage: 0.38,
            order_type: "LONG",
            order_uuid: "644124b8-a62b-4ee9-9128-dc473b6143d4",
            price: 174.062,
            price_sources: [],
            processed_ms: 1719993268768,
          },
          {
            leverage: 0.684,
            order_type: "LONG",
            order_uuid: "c32b7cd1-3321-4ced-a40c-207caed749b0",
            price: 174.059,
            price_sources: [],
            processed_ms: 1719993360944,
          },
          {
            leverage: 1.2996,
            order_type: "LONG",
            order_uuid: "294a099c-b243-4749-babb-d8395db50918",
            price: 174.005,
            price_sources: [],
            processed_ms: 1719993906329,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "8e37976e-49a8-46ca-9639-8a7acec79e02",
            price: 174.034,
            price_sources: [],
            processed_ms: 1719994005294,
          },
        ],
        position_type: "FLAT",
        position_uuid: "54e08d3b-94f0-4a87-ad9d-e17aaa045eff",
        return_at_close: 0.9997200268236904,
        trade_pair: ["EURJPY", "EUR/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 174.0442380952381,
        close_ms: 1719994333392,
        current_return: 1.000147831083022,
        initial_entry_price: 174.05,
        is_closed_position: true,
        miner_hotkey: "5D5GDbEa5Aedt9fXhWTzngDTANvnf8Q5poSFKYkU9hEZ7P8C",
        net_leverage: 0.0,
        open_ms: 1719994035683,
        orders: [
          {
            leverage: 0.2,
            order_type: "LONG",
            order_uuid: "a6d3f2ad-a22e-4c39-9022-6e97db88946c",
            price: 174.05,
            price_sources: [],
            processed_ms: 1719994034903,
          },
          {
            leverage: 0.22000000000000003,
            order_type: "LONG",
            order_uuid: "c20d3a4d-3be8-4e17-b77c-1b992864f8ac",
            price: 174.039,
            price_sources: [],
            processed_ms: 1719994109031,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "2e49a35d-0044-4e36-895e-1c92b674dd17",
            price: 174.1055,
            price_sources: [],
            processed_ms: 1719994333392,
          },
        ],
        position_type: "FLAT",
        position_uuid: "cf0effc7-2290-4e2a-9338-4766519729b9",
        return_at_close: 1.0001184267367882,
        trade_pair: ["EURJPY", "EUR/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 174.111,
        close_ms: 1720004749760,
        current_return: 1.0001768986451172,
        initial_entry_price: 174.111,
        is_closed_position: true,
        miner_hotkey: "5D5GDbEa5Aedt9fXhWTzngDTANvnf8Q5poSFKYkU9hEZ7P8C",
        net_leverage: 0.0,
        open_ms: 1719994363110,
        orders: [
          {
            leverage: 0.2,
            order_type: "LONG",
            order_uuid: "575e7902-e01f-4acd-855f-d406fcd9b9ef",
            price: 174.111,
            price_sources: [],
            processed_ms: 1719994362801,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "2051ec11-8dbc-4f64-b1e0-6f0f5d722423",
            price: 174.265,
            price_sources: [],
            processed_ms: 1720004749760,
          },
        ],
        position_type: "FLAT",
        position_uuid: "d4b0245b-4230-4b08-8b7e-93edf1ef6dd9",
        return_at_close: 1.0001628961685363,
        trade_pair: ["EURJPY", "EUR/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 174.19919298245617,
        close_ms: 1720008007276,
        current_return: 0.9998132985209514,
        initial_entry_price: 174.26749999999998,
        is_closed_position: true,
        miner_hotkey: "5D5GDbEa5Aedt9fXhWTzngDTANvnf8Q5poSFKYkU9hEZ7P8C",
        net_leverage: 0.0,
        open_ms: 1720004779719,
        orders: [
          {
            leverage: 0.2,
            order_type: "LONG",
            order_uuid: "c3681b21-9b7d-4a4c-ab52-edea15a489af",
            price: 174.26749999999998,
            price_sources: [],
            processed_ms: 1720004779048,
          },
          {
            leverage: 0.2,
            order_type: "LONG",
            order_uuid: "dbbc4455-261f-4c16-9560-b73f82eec164",
            price: 174.268,
            price_sources: [],
            processed_ms: 1720004828180,
          },
          {
            leverage: 0.4,
            order_type: "LONG",
            order_uuid: "0fb52486-ff6c-4413-a859-69d9ad9f25e6",
            price: 174.2115,
            price_sources: [],
            processed_ms: 1720005738571,
          },
          {
            leverage: 0.7200000000000001,
            order_type: "LONG",
            order_uuid: "1a380dfc-df6f-4814-b2ed-4ba23bb0320b",
            price: 174.2,
            price_sources: [],
            processed_ms: 1720005816206,
          },
          {
            leverage: 1.6720000000000002,
            order_type: "LONG",
            order_uuid: "dfa06ed5-87c7-44c8-b530-a9b55f3a68cf",
            price: 174.17950000000002,
            price_sources: [],
            processed_ms: 1720007957668,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "6e83f365-3c3a-4f35-81c1-92a2b2e81992",
            price: 174.189,
            price_sources: [],
            processed_ms: 1720008007276,
          },
        ],
        position_type: "FLAT",
        position_uuid: "dfe8a52e-72fa-406d-8195-cb3da7072d33",
        return_at_close: 0.9995899002375299,
        trade_pair: ["EURJPY", "EUR/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 174.14399999999998,
        close_ms: 1720008916393,
        current_return: 1.000214606812532,
        initial_entry_price: 174.179,
        is_closed_position: true,
        miner_hotkey: "5D5GDbEa5Aedt9fXhWTzngDTANvnf8Q5poSFKYkU9hEZ7P8C",
        net_leverage: 0.0,
        open_ms: 1720008035541,
        orders: [
          {
            leverage: 0.2,
            order_type: "LONG",
            order_uuid: "a7e43e08-8e7c-423a-9ac8-ad41ab3683e8",
            price: 174.179,
            price_sources: [],
            processed_ms: 1720008035059,
          },
          {
            leverage: 0.2,
            order_type: "LONG",
            order_uuid: "75329479-40ea-4efe-8a25-50960a6e337d",
            price: 174.175,
            price_sources: [],
            processed_ms: 1720008174944,
          },
          {
            leverage: 0.44000000000000006,
            order_type: "LONG",
            order_uuid: "188205e3-8912-4407-a859-8639fd164d90",
            price: 174.114,
            price_sources: [],
            processed_ms: 1720008859988,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "a3287caf-f4a5-4e6c-8198-a31492527d91",
            price: 174.18849999999998,
            price_sources: [],
            processed_ms: 1720008916393,
          },
        ],
        position_type: "FLAT",
        position_uuid: "29b69738-ef1c-4aa5-8f72-bb4127fc6bbb",
        return_at_close: 1.0001557941936514,
        trade_pair: ["EURJPY", "EUR/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 174.237,
        close_ms: 1720010234085,
        current_return: 1.0001526656221125,
        initial_entry_price: 174.237,
        is_closed_position: true,
        miner_hotkey: "5D5GDbEa5Aedt9fXhWTzngDTANvnf8Q5poSFKYkU9hEZ7P8C",
        net_leverage: 0.0,
        open_ms: 1720008947076,
        orders: [
          {
            leverage: 0.2,
            order_type: "LONG",
            order_uuid: "ca73e39e-87b8-41a7-8262-da3c5e2ae38b",
            price: 174.237,
            price_sources: [],
            processed_ms: 1720008946553,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "66f23b83-3f74-4b55-bc76-1f1d6a8d3fb4",
            price: 174.37,
            price_sources: [],
            processed_ms: 1720010234085,
          },
        ],
        position_type: "FLAT",
        position_uuid: "fc6d8147-35ec-40ab-935f-003c30393e18",
        return_at_close: 1.0001386634847937,
        trade_pair: ["EURJPY", "EUR/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 174.34602255639098,
        close_ms: 1720010582175,
        current_return: 1.000118876948309,
        initial_entry_price: 174.382,
        is_closed_position: true,
        miner_hotkey: "5D5GDbEa5Aedt9fXhWTzngDTANvnf8Q5poSFKYkU9hEZ7P8C",
        net_leverage: 0.0,
        open_ms: 1720010265826,
        orders: [
          {
            leverage: 0.2,
            order_type: "LONG",
            order_uuid: "481e4555-2d35-4986-81cf-d307d84580a8",
            price: 174.382,
            price_sources: [],
            processed_ms: 1720010264963,
          },
          {
            leverage: 0.22000000000000003,
            order_type: "LONG",
            order_uuid: "86478a1c-eb8b-4632-961c-1d555ef048eb",
            price: 174.346,
            price_sources: [],
            processed_ms: 1720010297941,
          },
          {
            leverage: 0.37800000000000006,
            order_type: "LONG",
            order_uuid: "51be7201-9246-424a-8d4b-2136a1d80982",
            price: 174.327,
            price_sources: [],
            processed_ms: 1720010340412,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "4b1a23ce-fe5a-46d4-9bc3-5f5070d02216",
            price: 174.372,
            price_sources: [],
            processed_ms: 1720010582175,
          },
        ],
        position_type: "FLAT",
        position_uuid: "60b6ba70-0b58-4d58-b290-1a604f33eed3",
        return_at_close: 1.0000630103078425,
        trade_pair: ["EURJPY", "EUR/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 174.33879365079366,
        close_ms: 1720011935171,
        current_return: 1.0000830110128938,
        initial_entry_price: 174.3865,
        is_closed_position: true,
        miner_hotkey: "5D5GDbEa5Aedt9fXhWTzngDTANvnf8Q5poSFKYkU9hEZ7P8C",
        net_leverage: 0.0,
        open_ms: 1720010612444,
        orders: [
          {
            leverage: 0.2,
            order_type: "LONG",
            order_uuid: "3e5a61d3-6e08-4f97-bf01-d22aefedd8ef",
            price: 174.3865,
            price_sources: [],
            processed_ms: 1720010611956,
          },
          {
            leverage: 0.22000000000000003,
            order_type: "LONG",
            order_uuid: "2b2cb238-e0cd-4b0c-abb5-180609533267",
            price: 174.369,
            price_sources: [],
            processed_ms: 1720010646551,
          },
          {
            leverage: 0.42000000000000004,
            order_type: "LONG",
            order_uuid: "d8fb013c-2dd2-4baa-993e-3e7362dcd3c4",
            price: 174.346,
            price_sources: [],
            processed_ms: 1720011225206,
          },
          {
            leverage: 0.9240000000000002,
            order_type: "LONG",
            order_uuid: "80f672ce-a490-4f15-acb1-25975c7d00dd",
            price: 174.318,
            price_sources: [],
            processed_ms: 1720011783968,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "b575aee9-8a12-4341-a345-0d331e0ad178",
            price: 174.347,
            price_sources: [],
            processed_ms: 1720011935171,
          },
        ],
        position_type: "FLAT",
        position_uuid: "9e62ebf3-1921-4490-b36b-bd46a5092533",
        return_at_close: 0.9999595207626939,
        trade_pair: ["EURJPY", "EUR/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 174.28867451523547,
        close_ms: 1720012606900,
        current_return: 0.9999861295947868,
        initial_entry_price: 174.328,
        is_closed_position: true,
        miner_hotkey: "5D5GDbEa5Aedt9fXhWTzngDTANvnf8Q5poSFKYkU9hEZ7P8C",
        net_leverage: 0.0,
        open_ms: 1720011965680,
        orders: [
          {
            leverage: 0.2,
            order_type: "LONG",
            order_uuid: "c9b715d4-f3ca-4173-9a8b-c9f2c290a332",
            price: 174.328,
            price_sources: [],
            processed_ms: 1720011964992,
          },
          {
            leverage: 0.18000000000000002,
            order_type: "LONG",
            order_uuid: "314c4e2a-1ad4-4ed3-84d9-d03defcbd065",
            price: 174.3275,
            price_sources: [],
            processed_ms: 1720012015057,
          },
          {
            leverage: 0.38,
            order_type: "LONG",
            order_uuid: "8c43dbb3-7952-4b80-8ec1-99745dac287c",
            price: 174.303,
            price_sources: [],
            processed_ms: 1720012243312,
          },
          {
            leverage: 0.684,
            order_type: "LONG",
            order_uuid: "3e683e54-cea6-483a-97c6-1cb52c5779f5",
            price: 174.259,
            price_sources: [],
            processed_ms: 1720012541501,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "19890324-e33d-47b9-adb9-23fbd0674870",
            price: 174.287,
            price_sources: [],
            processed_ms: 1720012606900,
          },
        ],
        position_type: "FLAT",
        position_uuid: "b4bb7911-e925-4709-96f6-64a6b3cb19fa",
        return_at_close: 0.9998850509968075,
        trade_pair: ["EURJPY", "EUR/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 174.281552631579,
        close_ms: 1720013599988,
        current_return: 1.00008164693867,
        initial_entry_price: 174.287,
        is_closed_position: true,
        miner_hotkey: "5D5GDbEa5Aedt9fXhWTzngDTANvnf8Q5poSFKYkU9hEZ7P8C",
        net_leverage: 0.0,
        open_ms: 1720012637637,
        orders: [
          {
            leverage: 0.2,
            order_type: "LONG",
            order_uuid: "18f93f10-b34c-4681-bf02-05618b35d09a",
            price: 174.287,
            price_sources: [],
            processed_ms: 1720012637049,
          },
          {
            leverage: 0.18000000000000002,
            order_type: "LONG",
            order_uuid: "7acad9ed-5f19-4b88-bd0e-192216e0575c",
            price: 174.27550000000002,
            price_sources: [],
            processed_ms: 1720013065686,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "b26d0239-0993-4352-80ba-3deacfe0b05d",
            price: 174.31900000000002,
            price_sources: [],
            processed_ms: 1720013599988,
          },
        ],
        position_type: "FLAT",
        position_uuid: "94325d34-2726-48f4-ba9a-97921856c488",
        return_at_close: 1.0000550447668615,
        trade_pair: ["EURJPY", "EUR/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 174.30255263157895,
        close_ms: 1720014064758,
        current_return: 1.0001274214229776,
        initial_entry_price: 174.30349999999999,
        is_closed_position: true,
        miner_hotkey: "5D5GDbEa5Aedt9fXhWTzngDTANvnf8Q5poSFKYkU9hEZ7P8C",
        net_leverage: 0.0,
        open_ms: 1720013630457,
        orders: [
          {
            leverage: 0.2,
            order_type: "LONG",
            order_uuid: "6da74e3a-c8f4-46ac-a77a-8fb5c3619acd",
            price: 174.30349999999999,
            price_sources: [],
            processed_ms: 1720013629924,
          },
          {
            leverage: 0.18000000000000002,
            order_type: "LONG",
            order_uuid: "e97c1795-bb5e-4a38-a042-de6a4662d329",
            price: 174.30149999999998,
            price_sources: [],
            processed_ms: 1720013685964,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "eb33b691-85f9-4a7e-9abe-c709f2e78f95",
            price: 174.361,
            price_sources: [],
            processed_ms: 1720014064758,
          },
        ],
        position_type: "FLAT",
        position_uuid: "7cf8e6d9-5861-47c6-9df5-18e85c6a62a4",
        return_at_close: 1.0001008180335678,
        trade_pair: ["EURJPY", "EUR/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 174.3595,
        close_ms: 1720015041671,
        current_return: 1.0001760729986036,
        initial_entry_price: 174.35950000000003,
        is_closed_position: true,
        miner_hotkey: "5D5GDbEa5Aedt9fXhWTzngDTANvnf8Q5poSFKYkU9hEZ7P8C",
        net_leverage: 0.0,
        open_ms: 1720014094454,
        orders: [
          {
            leverage: 0.2,
            order_type: "LONG",
            order_uuid: "0578e6d7-6c76-4a61-9f08-012f6388bf4a",
            price: 174.35950000000003,
            price_sources: [],
            processed_ms: 1720014093852,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "395ee9ac-f61d-4c1c-9800-d2fc743ac40a",
            price: 174.513,
            price_sources: [],
            processed_ms: 1720015041671,
          },
        ],
        position_type: "FLAT",
        position_uuid: "3b4abb51-5c1a-4eeb-b2a1-1facfddffed0",
        return_at_close: 1.0001620705335816,
        trade_pair: ["EURJPY", "EUR/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 174.30439583333333,
        close_ms: 1720015379004,
        current_return: 0.9998864626318201,
        initial_entry_price: 174.48,
        is_closed_position: true,
        miner_hotkey: "5D5GDbEa5Aedt9fXhWTzngDTANvnf8Q5poSFKYkU9hEZ7P8C",
        net_leverage: 0.0,
        open_ms: 1720015071501,
        orders: [
          {
            leverage: 0.2,
            order_type: "LONG",
            order_uuid: "fb602fb1-2783-4ae6-9a2d-bde5aabfb689",
            price: 174.48,
            price_sources: [],
            processed_ms: 1720015071137,
          },
          {
            leverage: 0.22000000000000003,
            order_type: "LONG",
            order_uuid: "ea08679e-b564-4488-b1cf-81cfbc82d17d",
            price: 174.4555,
            price_sources: [],
            processed_ms: 1720015114740,
          },
          {
            leverage: 0.42000000000000004,
            order_type: "LONG",
            order_uuid: "efc07e61-2c23-41e9-a526-e88fdc30af9b",
            price: 174.41,
            price_sources: [],
            processed_ms: 1720015207235,
          },
          {
            leverage: 0.8400000000000001,
            order_type: "LONG",
            order_uuid: "dfbe8c04-7783-4252-a2ef-e0762356d441",
            price: 174.293,
            price_sources: [],
            processed_ms: 1720015275248,
          },
          {
            leverage: 1.6800000000000002,
            order_type: "LONG",
            order_uuid: "798716fb-a16b-4478-aaa6-cdebd08f27aa",
            price: 174.243,
            price_sources: [],
            processed_ms: 1720015340880,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "86b80c10-10df-4b60-bb1e-e9c01ff4aaa6",
            price: 174.2985,
            price_sources: [],
            processed_ms: 1720015379004,
          },
        ],
        position_type: "FLAT",
        position_uuid: "c311f7b9-5361-47f1-82e7-12468d6aca55",
        return_at_close: 0.9996512893358092,
        trade_pair: ["EURJPY", "EUR/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 173.95608335831156,
        close_ms: 1720016046961,
        current_return: 1.001720573774579,
        initial_entry_price: 174.3105,
        is_closed_position: true,
        miner_hotkey: "5D5GDbEa5Aedt9fXhWTzngDTANvnf8Q5poSFKYkU9hEZ7P8C",
        net_leverage: 0.0,
        open_ms: 1720015409841,
        orders: [
          {
            leverage: 0.2,
            order_type: "LONG",
            order_uuid: "688c9410-5ed0-4fdf-9c3c-975393536bc7",
            price: 174.3105,
            price_sources: [],
            processed_ms: 1720015408960,
          },
          {
            leverage: 0.18000000000000002,
            order_type: "LONG",
            order_uuid: "3067262c-6dfc-426c-a6bb-16f66646a0a8",
            price: 174.304,
            price_sources: [],
            processed_ms: 1720015529076,
          },
          {
            leverage: 0.41800000000000004,
            order_type: "LONG",
            order_uuid: "f7f376d0-73c8-4931-b613-3e1f788862db",
            price: 174.271,
            price_sources: [],
            processed_ms: 1720015667180,
          },
          {
            leverage: 0.8778000000000001,
            order_type: "LONG",
            order_uuid: "3f960165-35ee-47c9-89d0-b8237d6dffc5",
            price: 174.2165,
            price_sources: [],
            processed_ms: 1720015825147,
          },
          {
            leverage: 1.5082200000000001,
            order_type: "LONG",
            order_uuid: "09da3a9b-e9f7-4e4b-b03b-4bd72fe85815",
            price: 174.14249999999998,
            price_sources: [],
            processed_ms: 1720015865188,
          },
          {
            leverage: 3.5024220000000006,
            order_type: "LONG",
            order_uuid: "b5edb5d0-de26-47bf-a988-9bcb8f930e39",
            price: 174.117,
            price_sources: [],
            processed_ms: 1720015894966,
          },
          {
            leverage: 7.355086200000002,
            order_type: "LONG",
            order_uuid: "c1cf419a-36a0-4005-8a7b-f894a73fa2f1",
            price: 174.038,
            price_sources: [],
            processed_ms: 1720015946834,
          },
          {
            leverage: 12.637375380000003,
            order_type: "LONG",
            order_uuid: "5ea286ee-9414-4fcf-b23d-5d9fa1acbe67",
            price: 173.9765,
            price_sources: [],
            processed_ms: 1720015976915,
          },
          {
            leverage: 24.011013222000006,
            order_type: "LONG",
            order_uuid: "1d8b0484-69e6-4eff-af0f-8174abcfb9cf",
            price: 173.86450000000002,
            price_sources: [],
            processed_ms: 1720016007022,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "ad938cbe-a565-4ba5-8830-31f3c20ff0de",
            price: 173.962,
            price_sources: [],
            processed_ms: 1720016046961,
          },
        ],
        position_type: "FLAT",
        position_uuid: "a04350c2-b184-45d5-af95-ac92996095bf",
        return_at_close: 0.998166174496535,
        trade_pair: ["EURJPY", "EUR/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 174.00894736842105,
        close_ms: 1720016407237,
        current_return: 1.0001387867224496,
        initial_entry_price: 174.00799999999998,
        is_closed_position: true,
        miner_hotkey: "5D5GDbEa5Aedt9fXhWTzngDTANvnf8Q5poSFKYkU9hEZ7P8C",
        net_leverage: 0.0,
        open_ms: 1720016077217,
        orders: [
          {
            leverage: 0.2,
            order_type: "LONG",
            order_uuid: "c8fffe8a-c9d6-419d-bfcb-5dfd56e74df8",
            price: 174.00799999999998,
            price_sources: [],
            processed_ms: 1720016076922,
          },
          {
            leverage: 0.18000000000000002,
            order_type: "LONG",
            order_uuid: "ce5b025f-dd7f-4690-84fa-f6ef1353bcc6",
            price: 174.01,
            price_sources: [],
            processed_ms: 1720016230959,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "a7b631cd-c8d6-4e9a-90e5-dbe93e556657",
            price: 174.0725,
            price_sources: [],
            processed_ms: 1720016407237,
          },
        ],
        position_type: "FLAT",
        position_uuid: "70cd5196-d890-4421-90d8-80dcbb362d38",
        return_at_close: 1.0001121830307227,
        trade_pair: ["EURJPY", "EUR/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 174.0952368421053,
        close_ms: 1720016631187,
        current_return: 1.0001516974090288,
        initial_entry_price: 174.16250000000002,
        is_closed_position: true,
        miner_hotkey: "5D5GDbEa5Aedt9fXhWTzngDTANvnf8Q5poSFKYkU9hEZ7P8C",
        net_leverage: 0.0,
        open_ms: 1720016437957,
        orders: [
          {
            leverage: 0.2,
            order_type: "LONG",
            order_uuid: "e5cbe4e6-880a-4462-a67c-51c9d1d66a97",
            price: 174.16250000000002,
            price_sources: [],
            processed_ms: 1720016437003,
          },
          {
            leverage: 0.2,
            order_type: "LONG",
            order_uuid: "bcc42020-d9bf-43fd-a5b9-83d4be840fd6",
            price: 174.095,
            price_sources: [],
            processed_ms: 1720016489100,
          },
          {
            leverage: 0.36000000000000004,
            order_type: "LONG",
            order_uuid: "aaffd094-4cdc-44c7-b4e2-a6b3566b798d",
            price: 174.058,
            price_sources: [],
            processed_ms: 1720016520903,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "1145d66d-d5ef-4147-a8f6-392bfe6b30a9",
            price: 174.13,
            price_sources: [],
            processed_ms: 1720016631187,
          },
        ],
        position_type: "FLAT",
        position_uuid: "a530758b-3041-45ca-bb24-9c17d121e86c",
        return_at_close: 1.0000984893387266,
        trade_pair: ["EURJPY", "EUR/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 174.08499999999998,
        close_ms: 1720016773294,
        current_return: 1.000128784332197,
        initial_entry_price: 174.0895,
        is_closed_position: true,
        miner_hotkey: "5D5GDbEa5Aedt9fXhWTzngDTANvnf8Q5poSFKYkU9hEZ7P8C",
        net_leverage: 0.0,
        open_ms: 1720016661887,
        orders: [
          {
            leverage: 0.2,
            order_type: "LONG",
            order_uuid: "34776f9e-6651-4255-a65a-84a617ad3e79",
            price: 174.0895,
            price_sources: [],
            processed_ms: 1720016661120,
          },
          {
            leverage: 0.18000000000000002,
            order_type: "LONG",
            order_uuid: "eb5ad81a-d8a6-4387-b880-92a0adb6b523",
            price: 174.07999999999998,
            price_sources: [],
            processed_ms: 1720016705787,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "abcffaed-7da1-48bd-94e7-70d3522a326a",
            price: 174.144,
            price_sources: [],
            processed_ms: 1720016773294,
          },
        ],
        position_type: "FLAT",
        position_uuid: "c777cc83-8d11-4b00-952d-3953d1bff870",
        return_at_close: 1.0001021809065338,
        trade_pair: ["EURJPY", "EUR/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 174.1382619047619,
        close_ms: 1720017225013,
        current_return: 1.0001054910473304,
        initial_entry_price: 174.138,
        is_closed_position: true,
        miner_hotkey: "5D5GDbEa5Aedt9fXhWTzngDTANvnf8Q5poSFKYkU9hEZ7P8C",
        net_leverage: 0.0,
        open_ms: 1720016803769,
        orders: [
          {
            leverage: 0.2,
            order_type: "LONG",
            order_uuid: "87e5c396-76f4-4321-9b3a-6c8a59579f24",
            price: 174.138,
            price_sources: [],
            processed_ms: 1720016802935,
          },
          {
            leverage: 0.22000000000000003,
            order_type: "LONG",
            order_uuid: "36a0708c-79c6-4e56-a5ef-ba464f12385f",
            price: 174.1385,
            price_sources: [],
            processed_ms: 1720017139446,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "549f08e1-88ba-4c31-a83a-255e3100587e",
            price: 174.182,
            price_sources: [],
            processed_ms: 1720017225013,
          },
        ],
        position_type: "FLAT",
        position_uuid: "e35f1c13-2361-4dfc-b522-408b8cfd201c",
        return_at_close: 1.0000760879458936,
        trade_pair: ["EURJPY", "EUR/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 174.1595476190476,
        close_ms: 1720017843745,
        current_return: 1.000173498181484,
        initial_entry_price: 174.1805,
        is_closed_position: true,
        miner_hotkey: "5D5GDbEa5Aedt9fXhWTzngDTANvnf8Q5poSFKYkU9hEZ7P8C",
        net_leverage: 0.0,
        open_ms: 1720017257530,
        orders: [
          {
            leverage: 0.2,
            order_type: "LONG",
            order_uuid: "5ebb9453-f687-483c-9598-3df17fd5e3df",
            price: 174.1805,
            price_sources: [],
            processed_ms: 1720017256962,
          },
          {
            leverage: 0.22000000000000003,
            order_type: "LONG",
            order_uuid: "61b20c19-a434-4a05-ac1e-3af1b8cb3a7d",
            price: 174.14049999999997,
            price_sources: [],
            processed_ms: 1720017307078,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "9f126f16-09fe-4bf0-b89d-a12380626805",
            price: 174.23149999999998,
            price_sources: [],
            processed_ms: 1720017843745,
          },
        ],
        position_type: "FLAT",
        position_uuid: "d8483a71-8ca3-4c85-a4d8-6f905d23302f",
        return_at_close: 1.0001440930806376,
        trade_pair: ["EURJPY", "EUR/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 174.21650969529088,
        close_ms: 1720018119211,
        current_return: 1.0002485660094746,
        initial_entry_price: 174.25150000000002,
        is_closed_position: true,
        miner_hotkey: "5D5GDbEa5Aedt9fXhWTzngDTANvnf8Q5poSFKYkU9hEZ7P8C",
        net_leverage: 0.0,
        open_ms: 1720017876339,
        orders: [
          {
            leverage: 0.2,
            order_type: "LONG",
            order_uuid: "21db4b58-ac27-4c56-9929-4a183092c7dc",
            price: 174.25150000000002,
            price_sources: [],
            processed_ms: 1720017873134,
          },
          {
            leverage: 0.18000000000000002,
            order_type: "LONG",
            order_uuid: "6e14a016-ce6e-4632-8a1e-49080bbaa2cd",
            price: 174.228,
            price_sources: [],
            processed_ms: 1720017902977,
          },
          {
            leverage: 0.342,
            order_type: "LONG",
            order_uuid: "c5579f94-131e-4ab1-ac19-7838be6da341",
            price: 174.19,
            price_sources: [],
            processed_ms: 1720017934034,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "95c29654-71bd-4ac6-a689-5326735d3adb",
            price: 174.2765,
            price_sources: [],
            processed_ms: 1720018119211,
          },
        ],
        position_type: "FLAT",
        position_uuid: "e0960ddc-e885-46e4-8175-071cbd15e744",
        return_at_close: 1.0001980134469486,
        trade_pair: ["EURJPY", "EUR/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 174.17587297190346,
        close_ms: 1720018415131,
        current_return: 1.000454622972267,
        initial_entry_price: 174.271,
        is_closed_position: true,
        miner_hotkey: "5D5GDbEa5Aedt9fXhWTzngDTANvnf8Q5poSFKYkU9hEZ7P8C",
        net_leverage: 0.0,
        open_ms: 1720018149900,
        orders: [
          {
            leverage: 0.2,
            order_type: "LONG",
            order_uuid: "28f534f6-8848-4900-bb1c-44797e0a7cbf",
            price: 174.271,
            price_sources: [],
            processed_ms: 1720018149204,
          },
          {
            leverage: 0.18000000000000002,
            order_type: "LONG",
            order_uuid: "99e01219-5116-4a0f-8485-118e4cddfdc6",
            price: 174.27,
            price_sources: [],
            processed_ms: 1720018214870,
          },
          {
            leverage: 0.342,
            order_type: "LONG",
            order_uuid: "a7ef47bf-b90d-4174-83a2-8713fd95da35",
            price: 174.221,
            price_sources: [],
            processed_ms: 1720018242724,
          },
          {
            leverage: 0.722,
            order_type: "LONG",
            order_uuid: "b6f3885b-3078-4149-9e20-6cfca8f1bb10",
            price: 174.188,
            price_sources: [],
            processed_ms: 1720018276803,
          },
          {
            leverage: 1.5884,
            order_type: "LONG",
            order_uuid: "23a89fe9-7390-4dd3-8001-ea5e030bc688",
            price: 174.138,
            price_sources: [],
            processed_ms: 1720018307175,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "46baf53a-7c17-4426-a32b-222329ad8eb4",
            price: 174.202,
            price_sources: [],
            processed_ms: 1720018415131,
          },
        ],
        position_type: "FLAT",
        position_uuid: "3e7ed529-bde6-48fd-b4af-cedf1de9c68a",
        return_at_close: 1.000242258470358,
        trade_pair: ["EURJPY", "EUR/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 174.19978571428572,
        close_ms: 1720018556740,
        current_return: 1.0001842587888858,
        initial_entry_price: 174.2115,
        is_closed_position: true,
        miner_hotkey: "5D5GDbEa5Aedt9fXhWTzngDTANvnf8Q5poSFKYkU9hEZ7P8C",
        net_leverage: 0.0,
        open_ms: 1720018445573,
        orders: [
          {
            leverage: 0.2,
            order_type: "LONG",
            order_uuid: "a178f59c-21aa-4ca8-af8e-060f20bf83d8",
            price: 174.2115,
            price_sources: [],
            processed_ms: 1720018444993,
          },
          {
            leverage: 0.2,
            order_type: "LONG",
            order_uuid: "80c99cdf-cab5-42ce-8a98-7424b869c2ea",
            price: 174.203,
            price_sources: [],
            processed_ms: 1720018492800,
          },
          {
            leverage: 0.44000000000000006,
            order_type: "LONG",
            order_uuid: "e31fe189-ad65-4dc4-bb4a-f6bd7bfef970",
            price: 174.193,
            price_sources: [],
            processed_ms: 1720018526977,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "28101ee0-18bf-4583-a140-12be0ce040fa",
            price: 174.238,
            price_sources: [],
            processed_ms: 1720018556740,
          },
        ],
        position_type: "FLAT",
        position_uuid: "b68c6856-0596-4f72-9e20-8e4c3f4dc7fb",
        return_at_close: 1.000125447954469,
        trade_pair: ["EURJPY", "EUR/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 174.22938095238095,
        close_ms: 1720019666735,
        current_return: 1.0000918889756187,
        initial_entry_price: 174.232,
        is_closed_position: true,
        miner_hotkey: "5D5GDbEa5Aedt9fXhWTzngDTANvnf8Q5poSFKYkU9hEZ7P8C",
        net_leverage: 0.0,
        open_ms: 1720018587081,
        orders: [
          {
            leverage: 0.2,
            order_type: "LONG",
            order_uuid: "f1e41b24-f64a-4cbe-9d49-0bb0d2e128ab",
            price: 174.232,
            price_sources: [],
            processed_ms: 1720018586719,
          },
          {
            leverage: 0.22000000000000003,
            order_type: "LONG",
            order_uuid: "6d0d3cf7-d334-46f1-9ef8-37704cccb490",
            price: 174.227,
            price_sources: [],
            processed_ms: 1720019280026,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "0514f86d-fc42-499a-bc1e-82771eccd522",
            price: 174.26749999999998,
            price_sources: [],
            processed_ms: 1720019666735,
          },
        ],
        position_type: "FLAT",
        position_uuid: "3294e278-3a35-4a9c-8c8a-16088d310f88",
        return_at_close: 1.000062486274083,
        trade_pair: ["EURJPY", "EUR/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 174.259,
        close_ms: 1720020033692,
        current_return: 1.0001438703972003,
        initial_entry_price: 174.259,
        is_closed_position: true,
        miner_hotkey: "5D5GDbEa5Aedt9fXhWTzngDTANvnf8Q5poSFKYkU9hEZ7P8C",
        net_leverage: 0.0,
        open_ms: 1720019696722,
        orders: [
          {
            leverage: 0.2,
            order_type: "LONG",
            order_uuid: "70c7cd9c-2a5a-4a0b-858c-fae8a065d50f",
            price: 174.259,
            price_sources: [],
            processed_ms: 1720019695928,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "8f7e4d98-5c2c-4a2b-9293-55697e5f6369",
            price: 174.3843535577287,
            price_sources: [],
            processed_ms: 1720020033692,
          },
        ],
        position_type: "FLAT",
        position_uuid: "ee226b2f-e53f-49a3-b5a5-e2ec0952f388",
        return_at_close: 1.0001298683830149,
        trade_pair: ["EURJPY", "EUR/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 174.3590357142857,
        close_ms: 1720020384244,
        current_return: 1.0000672707461145,
        initial_entry_price: 174.37,
        is_closed_position: true,
        miner_hotkey: "5D5GDbEa5Aedt9fXhWTzngDTANvnf8Q5poSFKYkU9hEZ7P8C",
        net_leverage: 0.0,
        open_ms: 1720020064636,
        orders: [
          {
            leverage: 0.2,
            order_type: "LONG",
            order_uuid: "88581e92-58a5-41d1-a8de-17888bfa6ac3",
            price: 174.37,
            price_sources: [],
            processed_ms: 1720020063776,
          },
          {
            leverage: 0.22000000000000003,
            order_type: "LONG",
            order_uuid: "dc663553-b1ef-44c3-a60e-d3e57205dcd0",
            price: 174.373,
            price_sources: [],
            processed_ms: 1720020143936,
          },
          {
            leverage: 0.42000000000000004,
            order_type: "LONG",
            order_uuid: "9ce96ec0-1e0a-4273-9ca6-0007a0a5064c",
            price: 174.3465,
            price_sources: [],
            processed_ms: 1720020174200,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "6f6831f8-93b0-48d7-a4fd-bb79ee167405",
            price: 174.373,
            price_sources: [],
            processed_ms: 1720020384244,
          },
        ],
        position_type: "FLAT",
        position_uuid: "bacc3919-c350-4fd4-9c24-f83fdb695de7",
        return_at_close: 1.0000084667905946,
        trade_pair: ["EURJPY", "EUR/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 174.3270476190476,
        close_ms: 1720023065337,
        current_return: 1.000062400885542,
        initial_entry_price: 174.35649999999998,
        is_closed_position: true,
        miner_hotkey: "5D5GDbEa5Aedt9fXhWTzngDTANvnf8Q5poSFKYkU9hEZ7P8C",
        net_leverage: 0.0,
        open_ms: 1720020417740,
        orders: [
          {
            leverage: 0.2,
            order_type: "LONG",
            order_uuid: "ac3be52a-684a-47ec-9484-e5a691db06dc",
            price: 174.35649999999998,
            price_sources: [],
            processed_ms: 1720020416082,
          },
          {
            leverage: 0.2,
            order_type: "LONG",
            order_uuid: "9aa78c1a-8f1f-4538-b699-d316d252cf3a",
            price: 174.3395,
            price_sources: [],
            processed_ms: 1720022491561,
          },
          {
            leverage: 0.44000000000000006,
            order_type: "LONG",
            order_uuid: "2ac9ade9-5037-43b0-84d5-5b5f91d84812",
            price: 174.308,
            price_sources: [],
            processed_ms: 1720022821933,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "3e5ec50b-973a-47cb-ba4c-9fb48b348bb8",
            price: 174.34,
            price_sources: [],
            processed_ms: 1720023065337,
          },
        ],
        position_type: "FLAT",
        position_uuid: "c958ed81-8663-46d5-89ab-6a1e5e8bb3cf",
        return_at_close: 1.00000359721637,
        trade_pair: ["EURJPY", "EUR/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 174.29524826285422,
        close_ms: 1720024550163,
        current_return: 1.0000120324268866,
        initial_entry_price: 174.334,
        is_closed_position: true,
        miner_hotkey: "5D5GDbEa5Aedt9fXhWTzngDTANvnf8Q5poSFKYkU9hEZ7P8C",
        net_leverage: 0.0,
        open_ms: 1720023097063,
        orders: [
          {
            leverage: 0.2,
            order_type: "LONG",
            order_uuid: "62f3830c-5a68-48f6-bf83-c8d3449f82d9",
            price: 174.334,
            price_sources: [],
            processed_ms: 1720023096277,
          },
          {
            leverage: 0.22000000000000003,
            order_type: "LONG",
            order_uuid: "904bf1ec-9e6b-4eae-ae32-404b3d03c274",
            price: 174.325,
            price_sources: [],
            processed_ms: 1720023219087,
          },
          {
            leverage: 0.37800000000000006,
            order_type: "LONG",
            order_uuid: "7bf07d03-d69b-496f-a2aa-4a21ab63cb7f",
            price: 174.319,
            price_sources: [],
            processed_ms: 1720023603684,
          },
          {
            leverage: 0.8778000000000001,
            order_type: "LONG",
            order_uuid: "6e753af5-7a73-4840-9324-7b95c6b73c62",
            price: 174.2687343801448,
            price_sources: [
              {
                close: 174.2687343801448,
                high: 174.2687343801448,
                lag_ms: 384,
                low: 174.2687343801448,
                open: 174.2687343801448,
                source: "Polygon_ws",
                start_ms: 1720024422000,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 174.2687343801448,
                websocket: true,
              },
              {
                close: 174.264,
                high: 174.264,
                lag_ms: 384,
                low: 174.264,
                open: 174.264,
                source: "TwelveData_ws",
                start_ms: 1720024422000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1720024422384,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "0da6e08e-ddc6-43e9-bce6-76c8beafe470",
            price: 174.29649999999998,
            price_sources: [
              {
                close: 174.29649999999998,
                high: 174.29649999999998,
                lag_ms: 163,
                low: 174.29649999999998,
                open: 174.29649999999998,
                source: "Polygon_ws",
                start_ms: 1720024550000,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 174.28,
                websocket: true,
              },
              {
                close: 174.298,
                high: 174.298,
                lag_ms: 837,
                low: 174.298,
                open: 174.298,
                source: "TwelveData_ws",
                start_ms: 1720024551000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1720024550163,
          },
        ],
        position_type: "FLAT",
        position_uuid: "8cd355ce-21a5-4d63-a101-1cc94e313039",
        return_at_close: 0.9998947250154108,
        trade_pair: ["EURJPY", "EUR/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 174.28692857142858,
        close_ms: 1720025200002,
        current_return: 1.0000822691129394,
        initial_entry_price: 174.306,
        is_closed_position: true,
        miner_hotkey: "5D5GDbEa5Aedt9fXhWTzngDTANvnf8Q5poSFKYkU9hEZ7P8C",
        net_leverage: 0.0,
        open_ms: 1720024580623,
        orders: [
          {
            leverage: 0.2,
            order_type: "LONG",
            order_uuid: "2bb7c690-cb5b-41b6-8ffc-675657040491",
            price: 174.306,
            price_sources: [
              {
                close: 174.306,
                high: 174.306,
                lag_ms: 206,
                low: 174.306,
                open: 174.306,
                source: "Polygon_ws",
                start_ms: 1720024579999,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 174.306,
                websocket: true,
              },
              {
                close: 174.309,
                high: 174.309,
                lag_ms: 207,
                low: 174.309,
                open: 174.309,
                source: "TwelveData_ws",
                start_ms: 1720024580000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1720024579793,
          },
          {
            leverage: 0.2,
            order_type: "LONG",
            order_uuid: "b22891ab-0c19-4a74-9098-40c63e19a3bf",
            price: 174.2985,
            price_sources: [
              {
                close: 174.2985,
                high: 174.2985,
                lag_ms: 50,
                low: 174.2985,
                open: 174.2985,
                source: "Polygon_ws",
                start_ms: 1720024632000,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 174.298,
                websocket: true,
              },
              {
                close: 174.296,
                high: 174.296,
                lag_ms: 950,
                low: 174.296,
                open: 174.296,
                source: "TwelveData_ws",
                start_ms: 1720024633000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1720024632050,
          },
          {
            leverage: 0.44000000000000006,
            order_type: "LONG",
            order_uuid: "eb30fa24-4044-4328-abd8-7c39865f2b79",
            price: 174.273,
            price_sources: [
              {
                close: 174.273,
                high: 174.273,
                lag_ms: 235,
                low: 174.273,
                open: 174.273,
                source: "Polygon_ws",
                start_ms: 1720025170999,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 174.275,
                websocket: true,
              },
              {
                close: 174.279,
                high: 174.279,
                lag_ms: 764,
                low: 174.279,
                open: 174.279,
                source: "TwelveData_ws",
                start_ms: 1720025170000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1720025170764,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "05e6d01b-52ff-4521-80be-bf93117a664c",
            price: 174.304,
            price_sources: [
              {
                close: 174.304,
                high: 174.304,
                lag_ms: 2,
                low: 174.304,
                open: 174.304,
                source: "Polygon_ws",
                start_ms: 1720025200000,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 174.304,
                websocket: true,
              },
              {
                close: 174.308,
                high: 174.308,
                lag_ms: 2,
                low: 174.308,
                open: 174.308,
                source: "TwelveData_ws",
                start_ms: 1720025200000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1720025200002,
          },
        ],
        position_type: "FLAT",
        position_uuid: "a57da636-15e7-48b3-8128-bd192ac3d435",
        return_at_close: 1.0000234642755155,
        trade_pair: ["EURJPY", "EUR/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 174.3042380952381,
        close_ms: 1720025482368,
        current_return: 1.0001343583271183,
        initial_entry_price: 174.31,
        is_closed_position: true,
        miner_hotkey: "5D5GDbEa5Aedt9fXhWTzngDTANvnf8Q5poSFKYkU9hEZ7P8C",
        net_leverage: 0.0,
        open_ms: 1720025230156,
        orders: [
          {
            leverage: 0.2,
            order_type: "LONG",
            order_uuid: "77693ece-20e6-4e2a-8dcc-720431040dd6",
            price: 174.31,
            price_sources: [
              {
                close: 174.31,
                high: 174.31,
                lag_ms: 220,
                low: 174.31,
                open: 174.31,
                source: "Polygon_ws",
                start_ms: 1720025229999,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 174.313,
                websocket: true,
              },
              {
                close: 174.31,
                high: 174.31,
                lag_ms: 221,
                low: 174.31,
                open: 174.31,
                source: "TwelveData_ws",
                start_ms: 1720025230000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1720025229779,
          },
          {
            leverage: 0.22000000000000003,
            order_type: "LONG",
            order_uuid: "2f06d89e-3e83-49db-8414-99d43be3f6ef",
            price: 174.299,
            price_sources: [
              {
                close: 174.299,
                high: 174.299,
                lag_ms: 358,
                low: 174.299,
                open: 174.299,
                source: "Polygon_ws",
                start_ms: 1720025265999,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 174.299,
                websocket: true,
              },
              {
                close: 174.301,
                high: 174.301,
                lag_ms: 359,
                low: 174.301,
                open: 174.301,
                source: "TwelveData_ws",
                start_ms: 1720025266000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1720025265641,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "9bde85a8-63f1-43f5-b706-5691a2991396",
            price: 174.36,
            price_sources: [
              {
                close: 174.36,
                high: 174.36,
                lag_ms: 368,
                low: 174.36,
                open: 174.36,
                source: "Polygon_ws",
                start_ms: 1720025482000,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 174.36,
                websocket: true,
              },
              {
                close: 174.358,
                high: 174.358,
                lag_ms: 368,
                low: 174.358,
                open: 174.358,
                source: "TwelveData_ws",
                start_ms: 1720025482000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1720025482368,
          },
        ],
        position_type: "FLAT",
        position_uuid: "3edb7a6f-3b5b-4265-9819-f962029c8b22",
        return_at_close: 1.0001049543769835,
        trade_pair: ["EURJPY", "EUR/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 174.15353806388396,
        close_ms: 1720029802974,
        current_return: 0.9991079476420447,
        initial_entry_price: 174.346,
        is_closed_position: true,
        miner_hotkey: "5D5GDbEa5Aedt9fXhWTzngDTANvnf8Q5poSFKYkU9hEZ7P8C",
        net_leverage: 0.0,
        open_ms: 1720025514328,
        orders: [
          {
            leverage: 0.2,
            order_type: "LONG",
            order_uuid: "8cf41712-333b-4608-8f44-acc935eb661d",
            price: 174.346,
            price_sources: [
              {
                close: 174.346,
                high: 174.346,
                lag_ms: 262,
                low: 174.346,
                open: 174.346,
                source: "Polygon_ws",
                start_ms: 1720025513999,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 174.346,
                websocket: true,
              },
              {
                close: 174.351,
                high: 174.351,
                lag_ms: 263,
                low: 174.351,
                open: 174.351,
                source: "TwelveData_ws",
                start_ms: 1720025514000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1720025513737,
          },
          {
            leverage: 0.18000000000000002,
            order_type: "LONG",
            order_uuid: "575b4a25-4626-42a9-8031-a8b3304fdaa5",
            price: 174.33100000000002,
            price_sources: [
              {
                close: 174.33100000000002,
                high: 174.33100000000002,
                lag_ms: 151,
                low: 174.33100000000002,
                open: 174.33100000000002,
                source: "Polygon_ws",
                start_ms: 1720026301000,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 174.334,
                websocket: true,
              },
              {
                close: 174.332,
                high: 174.332,
                lag_ms: 1151,
                low: 174.332,
                open: 174.332,
                source: "TwelveData_ws",
                start_ms: 1720026300000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1720026301151,
          },
          {
            leverage: 0.41800000000000004,
            order_type: "LONG",
            order_uuid: "665c4dfa-074b-491a-bd2f-3adb89d40c7a",
            price: 174.293,
            price_sources: [
              {
                close: 174.293,
                high: 174.293,
                lag_ms: 181,
                low: 174.293,
                open: 174.293,
                source: "Polygon_ws",
                start_ms: 1720026496999,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 174.293,
                websocket: true,
              },
              {
                close: 174.295,
                high: 174.295,
                lag_ms: 182,
                low: 174.295,
                open: 174.295,
                source: "TwelveData_ws",
                start_ms: 1720026497000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1720026496818,
          },
          {
            leverage: 0.798,
            order_type: "LONG",
            order_uuid: "4d4efa49-3b2d-4e44-90eb-3412a1181e49",
            price: 174.26999999999998,
            price_sources: [
              {
                close: 174.26999999999998,
                high: 174.26999999999998,
                lag_ms: 396,
                low: 174.26999999999998,
                open: 174.26999999999998,
                source: "Polygon_ws",
                start_ms: 1720026707000,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 174.24,
                websocket: true,
              },
              {
                close: 174.274,
                high: 174.274,
                lag_ms: 396,
                low: 174.274,
                open: 174.274,
                source: "TwelveData_ws",
                start_ms: 1720026707000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1720026707396,
          },
          {
            leverage: 1.7556000000000003,
            order_type: "LONG",
            order_uuid: "d43843ce-dbc4-4df1-bcdf-12b68962994a",
            price: 174.203,
            price_sources: [
              {
                close: 174.203,
                high: 174.203,
                lag_ms: 96,
                low: 174.203,
                open: 174.203,
                source: "Polygon_ws",
                start_ms: 1720027780999,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 174.19,
                websocket: true,
              },
              {
                close: 174.211,
                high: 174.211,
                lag_ms: 97,
                low: 174.211,
                open: 174.211,
                source: "TwelveData_ws",
                start_ms: 1720027781000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1720027780903,
          },
          {
            leverage: 3.0164400000000002,
            order_type: "LONG",
            order_uuid: "db7b0fc5-c647-4fef-8fed-8b084479dd7e",
            price: 174.1745,
            price_sources: [
              {
                close: 174.1745,
                high: 174.1745,
                lag_ms: 353,
                low: 174.1745,
                open: 174.1745,
                source: "Polygon_ws",
                start_ms: 1720028071000,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 174.17,
                websocket: true,
              },
              {
                close: 174.179,
                high: 174.179,
                lag_ms: 353,
                low: 174.179,
                open: 174.179,
                source: "TwelveData_ws",
                start_ms: 1720028071000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1720028071353,
          },
          {
            leverage: 7.004844000000001,
            order_type: "LONG",
            order_uuid: "70b780eb-b051-43a8-b5d8-335f5d54918b",
            price: 174.152,
            price_sources: [
              {
                close: 174.152,
                high: 174.152,
                lag_ms: 393,
                low: 174.152,
                open: 174.152,
                source: "Polygon_ws",
                start_ms: 1720029409000,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 174.14,
                websocket: true,
              },
              {
                close: 174.164,
                high: 174.164,
                lag_ms: 607,
                low: 174.164,
                open: 174.164,
                source: "TwelveData_ws",
                start_ms: 1720029410000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1720029409393,
          },
          {
            leverage: 14.710172400000005,
            order_type: "LONG",
            order_uuid: "f54eac1f-cc48-4afe-81bf-71ee879a1ff7",
            price: 174.129,
            price_sources: [
              {
                close: 174.129,
                high: 174.129,
                lag_ms: 236,
                low: 174.129,
                open: 174.129,
                source: "Polygon_ws",
                start_ms: 1720029665000,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 174.129,
                websocket: true,
              },
              {
                close: 174.13,
                high: 174.13,
                lag_ms: 764,
                low: 174.13,
                open: 174.13,
                source: "TwelveData_ws",
                start_ms: 1720029666000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1720029665236,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "f7b7e3cf-2544-41cd-87a6-5d71fb5af3f3",
            price: 174.148,
            price_sources: [
              {
                close: 174.148,
                high: 174.148,
                lag_ms: 25,
                low: 174.148,
                open: 174.148,
                source: "Polygon_ws",
                start_ms: 1720029802999,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 174.133,
                websocket: true,
              },
              {
                close: 174.148,
                high: 174.148,
                lag_ms: 26,
                low: 174.148,
                open: 174.148,
                source: "TwelveData_ws",
                start_ms: 1720029803000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1720029802974,
          },
        ],
        position_type: "FLAT",
        position_uuid: "e43e08f1-2889-4a09-9f94-66dcf53e04c1",
        return_at_close: 0.9971438873030123,
        trade_pair: ["EURJPY", "EUR/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 174.147,
        close_ms: 1720031471671,
        current_return: 1.0001079547738405,
        initial_entry_price: 174.147,
        is_closed_position: true,
        miner_hotkey: "5D5GDbEa5Aedt9fXhWTzngDTANvnf8Q5poSFKYkU9hEZ7P8C",
        net_leverage: 0.0,
        open_ms: 1720029833279,
        orders: [
          {
            leverage: 0.2,
            order_type: "LONG",
            order_uuid: "5823abfe-2857-4924-985e-f34ca51881f3",
            price: 174.147,
            price_sources: [
              {
                close: 174.147,
                high: 174.147,
                lag_ms: 183,
                low: 174.147,
                open: 174.147,
                source: "Polygon_ws",
                start_ms: 1720029832999,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 174.148,
                websocket: true,
              },
              {
                close: 174.144,
                high: 174.144,
                lag_ms: 816,
                low: 174.144,
                open: 174.144,
                source: "TwelveData_ws",
                start_ms: 1720029832000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1720029832816,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "11a133cf-9adc-4d1a-8397-cfc472eeee8a",
            price: 174.241,
            price_sources: [
              {
                close: 174.241,
                high: 174.241,
                lag_ms: 328,
                low: 174.241,
                open: 174.241,
                source: "Polygon_ws",
                start_ms: 1720031471999,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 174.238,
                websocket: true,
              },
              {
                close: 174.241,
                high: 174.241,
                lag_ms: 671,
                low: 174.241,
                open: 174.241,
                source: "TwelveData_ws",
                start_ms: 1720031471000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1720031471671,
          },
        ],
        position_type: "FLAT",
        position_uuid: "2f808e8a-ea2f-4beb-a170-24efce2923e4",
        return_at_close: 1.0000939532624737,
        trade_pair: ["EURJPY", "EUR/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 174.2305476190476,
        close_ms: 1720032606833,
        current_return: 1.000163801761988,
        initial_entry_price: 174.235,
        is_closed_position: true,
        miner_hotkey: "5D5GDbEa5Aedt9fXhWTzngDTANvnf8Q5poSFKYkU9hEZ7P8C",
        net_leverage: 0.0,
        open_ms: 1720031501547,
        orders: [
          {
            leverage: 0.2,
            order_type: "LONG",
            order_uuid: "ab19a43d-a2f6-4975-b1de-718ec3b1bdea",
            price: 174.235,
            price_sources: [
              {
                close: 174.235,
                high: 174.235,
                lag_ms: 207,
                low: 174.235,
                open: 174.235,
                source: "Polygon_ws",
                start_ms: 1720031500999,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 174.21,
                websocket: true,
              },
              {
                close: 174.24,
                high: 174.24,
                lag_ms: 792,
                low: 174.24,
                open: 174.24,
                source: "TwelveData_ws",
                start_ms: 1720031500000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1720031500792,
          },
          {
            leverage: 0.22000000000000003,
            order_type: "LONG",
            order_uuid: "fd04ed32-da24-4b21-846f-476376b14383",
            price: 174.2265,
            price_sources: [
              {
                close: 174.2265,
                high: 174.2265,
                lag_ms: 99,
                low: 174.2265,
                open: 174.2265,
                source: "Polygon_ws",
                start_ms: 1720031533000,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 174.225,
                websocket: true,
              },
              {
                close: 174.234,
                high: 174.234,
                lag_ms: 99,
                low: 174.234,
                open: 174.234,
                source: "TwelveData_ws",
                start_ms: 1720031533000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1720031533099,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "d6f7be9a-b132-410d-b8c3-4bb026a8ea83",
            price: 174.2985,
            price_sources: [
              {
                close: 174.2985,
                high: 174.2985,
                lag_ms: 166,
                low: 174.2985,
                open: 174.2985,
                source: "Polygon_ws",
                start_ms: 1720032606999,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 174.29,
                websocket: true,
              },
              {
                close: 174.308,
                high: 174.308,
                lag_ms: 833,
                low: 174.308,
                open: 174.308,
                source: "TwelveData_ws",
                start_ms: 1720032606000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1720032606833,
          },
        ],
        position_type: "FLAT",
        position_uuid: "9f88698c-fe38-4513-95c4-4e51422971ad",
        return_at_close: 1.0001343969462162,
        trade_pair: ["EURJPY", "EUR/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 174.27190476190472,
        close_ms: 1720033577385,
        current_return: 1.0001026479616721,
        initial_entry_price: 174.285,
        is_closed_position: true,
        miner_hotkey: "5D5GDbEa5Aedt9fXhWTzngDTANvnf8Q5poSFKYkU9hEZ7P8C",
        net_leverage: 0.0,
        open_ms: 1720032636464,
        orders: [
          {
            leverage: 0.2,
            order_type: "LONG",
            order_uuid: "dfcd9b3e-1f10-4885-87cc-eeac84f3e619",
            price: 174.285,
            price_sources: [
              {
                close: 174.285,
                high: 174.285,
                lag_ms: 89,
                low: 174.285,
                open: 174.285,
                source: "Polygon_ws",
                start_ms: 1720032635999,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 174.29,
                websocket: true,
              },
              {
                close: 174.287,
                high: 174.287,
                lag_ms: 90,
                low: 174.287,
                open: 174.287,
                source: "TwelveData_ws",
                start_ms: 1720032636000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1720032635910,
          },
          {
            leverage: 0.22000000000000003,
            order_type: "LONG",
            order_uuid: "3fbee458-7dd6-46c2-b581-d207a5e1e6bf",
            price: 174.26,
            price_sources: [
              {
                close: 174.26,
                high: 174.26,
                lag_ms: 266,
                low: 174.26,
                open: 174.26,
                source: "Polygon_ws",
                start_ms: 1720033208999,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 174.26,
                websocket: true,
              },
              {
                close: 174.267,
                high: 174.267,
                lag_ms: 733,
                low: 174.267,
                open: 174.267,
                source: "TwelveData_ws",
                start_ms: 1720033208000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1720033208733,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "0efed540-7e51-4a39-be13-fb66e392a7a9",
            price: 174.3145,
            price_sources: [
              {
                close: 174.3145,
                high: 174.3145,
                lag_ms: 385,
                low: 174.3145,
                open: 174.3145,
                source: "Polygon_ws",
                start_ms: 1720033577000,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 174.31,
                websocket: true,
              },
              {
                close: 174.328,
                high: 174.328,
                lag_ms: 615,
                low: 174.328,
                open: 174.328,
                source: "TwelveData_ws",
                start_ms: 1720033578000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1720033577385,
          },
        ],
        position_type: "FLAT",
        position_uuid: "f4de05a3-62ac-4f07-b450-d2acab86352a",
        return_at_close: 1.000073244943822,
        trade_pair: ["EURJPY", "EUR/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 174.326,
        close_ms: 1720034729081,
        current_return: 1.0000848984087285,
        initial_entry_price: 174.326,
        is_closed_position: true,
        miner_hotkey: "5D5GDbEa5Aedt9fXhWTzngDTANvnf8Q5poSFKYkU9hEZ7P8C",
        net_leverage: 0.0,
        open_ms: 1720033607962,
        orders: [
          {
            leverage: 0.2,
            order_type: "LONG",
            order_uuid: "642a2984-6ab7-48e7-bcb8-357b4c380d6e",
            price: 174.326,
            price_sources: [
              {
                close: 174.326,
                high: 174.326,
                lag_ms: 485,
                low: 174.326,
                open: 174.326,
                source: "Polygon_ws",
                start_ms: 1720033607999,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 174.326,
                websocket: true,
              },
              {
                close: 174.33,
                high: 174.33,
                lag_ms: 486,
                low: 174.33,
                open: 174.33,
                source: "TwelveData_ws",
                start_ms: 1720033608000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1720033607514,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "4d248a59-a2d0-433a-9649-8435f8c4a989",
            price: 174.4,
            price_sources: [
              {
                close: 174.4,
                high: 174.4,
                lag_ms: 81,
                low: 174.4,
                open: 174.4,
                source: "Polygon_ws",
                start_ms: 1720034729000,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 174.4,
                websocket: true,
              },
              {
                close: 174.404,
                high: 174.404,
                lag_ms: 81,
                low: 174.404,
                open: 174.404,
                source: "TwelveData_ws",
                start_ms: 1720034729000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1720034729081,
          },
        ],
        position_type: "FLAT",
        position_uuid: "369342a6-b523-4b2f-bcec-0ee69be9cb1b",
        return_at_close: 1.0000708972201509,
        trade_pair: ["EURJPY", "EUR/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 174.33597713433414,
        close_ms: 1720040763035,
        current_return: 0.9996543095895948,
        initial_entry_price: 174.406,
        is_closed_position: true,
        miner_hotkey: "5D5GDbEa5Aedt9fXhWTzngDTANvnf8Q5poSFKYkU9hEZ7P8C",
        net_leverage: 0.0,
        open_ms: 1720034758572,
        orders: [
          {
            leverage: 0.2,
            order_type: "LONG",
            order_uuid: "900b9db4-686b-4fbb-8378-3e4866c64102",
            price: 174.406,
            price_sources: [
              {
                close: 174.406,
                high: 174.406,
                lag_ms: 256,
                low: 174.406,
                open: 174.406,
                source: "Polygon_ws",
                start_ms: 1720034757999,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 174.415,
                websocket: true,
              },
              {
                close: 174.415,
                high: 174.415,
                lag_ms: 743,
                low: 174.415,
                open: 174.415,
                source: "TwelveData_ws",
                start_ms: 1720034757000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1720034757743,
          },
          {
            leverage: 0.22000000000000003,
            order_type: "LONG",
            order_uuid: "63c77b47-f987-45bf-bfe3-f9baa56726cb",
            price: 174.38070218962355,
            price_sources: [
              {
                close: 174.38070218962355,
                high: 174.38070218962355,
                lag_ms: 1268,
                low: 174.38070218962355,
                open: 174.38070218962355,
                source: "Polygon_ws",
                start_ms: 1720040439000,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 174.3814043792471,
                websocket: true,
              },
              {
                close: 174.41,
                high: 174.41,
                lag_ms: 2733,
                low: 174.41,
                open: 174.41,
                source: "Polygon_rest",
                start_ms: 1720040434000,
                timespan_ms: 1000,
                volume: 1.0,
                vwap: null,
                websocket: false,
              },
              {
                close: 174.41,
                high: 174.41,
                lag_ms: 4732,
                low: 174.41,
                open: 174.41,
                source: "TwelveData_ws",
                start_ms: 1720040433000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
              {
                close: 174.46001,
                high: 174.46001,
                lag_ms: 1777733,
                low: 174.44,
                open: 174.44,
                source: "TwelveData_rest",
                start_ms: 1720038600000,
                timespan_ms: 60000,
                volume: null,
                vwap: null,
                websocket: false,
              },
            ],
            processed_ms: 1720040437732,
          },
          {
            leverage: 0.37800000000000006,
            order_type: "LONG",
            order_uuid: "3d04002a-e266-4e5c-9cc2-966bb1d2e714",
            price: 174.31,
            price_sources: [
              {
                close: 174.31,
                high: 174.31,
                lag_ms: 44,
                low: 174.31,
                open: 174.31,
                source: "Polygon_ws",
                start_ms: 1720040597999,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 174.31,
                websocket: true,
              },
              {
                close: 174.303,
                high: 174.303,
                lag_ms: 3045,
                low: 174.303,
                open: 174.303,
                source: "TwelveData_ws",
                start_ms: 1720040601000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1720040597955,
          },
          {
            leverage: 0.8778000000000001,
            order_type: "LONG",
            order_uuid: "c0b100c0-88ee-4df2-8dc9-e7289fdd2aaa",
            price: 174.32,
            price_sources: [
              {
                close: 174.32,
                high: 174.32,
                lag_ms: 52,
                low: 174.32,
                open: 174.32,
                source: "Polygon_ws",
                start_ms: 1720040722999,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 174.32,
                websocket: true,
              },
              {
                close: 174.32,
                high: 174.32,
                lag_ms: 52,
                low: 174.32,
                open: 174.32,
                source: "Polygon_rest",
                start_ms: 1720040722000,
                timespan_ms: 1000,
                volume: 1.0,
                vwap: null,
                websocket: false,
              },
              {
                close: 174.251,
                high: 174.251,
                lag_ms: 947,
                low: 174.251,
                open: 174.251,
                source: "TwelveData_ws",
                start_ms: 1720040722000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
              {
                close: 174.28999,
                high: 174.36,
                lag_ms: 82948,
                low: 174.28,
                open: 174.34,
                source: "TwelveData_rest",
                start_ms: 1720040580000,
                timespan_ms: 60000,
                volume: null,
                vwap: null,
                websocket: false,
              },
            ],
            processed_ms: 1720040722947,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "43bf84ab-5b8d-4154-8dbe-ca584f2d3bf9",
            price: 174.3,
            price_sources: [
              {
                close: 174.3,
                high: 174.3,
                lag_ms: 36,
                low: 174.3,
                open: 174.3,
                source: "Polygon_ws",
                start_ms: 1720040762999,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 174.3,
                websocket: true,
              },
              {
                close: 174.3,
                high: 174.3,
                lag_ms: 36,
                low: 174.3,
                open: 174.3,
                source: "Polygon_rest",
                start_ms: 1720040762000,
                timespan_ms: 1000,
                volume: 1.0,
                vwap: null,
                websocket: false,
              },
              {
                close: 174.265,
                high: 174.265,
                lag_ms: 2035,
                low: 174.265,
                open: 174.265,
                source: "TwelveData_ws",
                start_ms: 1720040761000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
              {
                close: 174.28999,
                high: 174.36,
                lag_ms: 123036,
                low: 174.28,
                open: 174.34,
                source: "TwelveData_rest",
                start_ms: 1720040580000,
                timespan_ms: 60000,
                volume: null,
                vwap: null,
                websocket: false,
              },
            ],
            processed_ms: 1720040763035,
          },
        ],
        position_type: "FLAT",
        position_uuid: "ceac86f8-1cf3-4c84-a83b-9dc10fcb6b04",
        return_at_close: 0.9995370441411541,
        trade_pair: ["EURJPY", "EUR/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 174.33,
        close_ms: 1720040958501,
        current_return: 1.0000229449893878,
        initial_entry_price: 174.33,
        is_closed_position: true,
        miner_hotkey: "5D5GDbEa5Aedt9fXhWTzngDTANvnf8Q5poSFKYkU9hEZ7P8C",
        net_leverage: 0.0,
        open_ms: 1720040799326,
        orders: [
          {
            leverage: 0.2,
            order_type: "LONG",
            order_uuid: "0c467174-b460-4e5e-abe5-f6c1de5529a3",
            price: 174.33,
            price_sources: [
              {
                close: 174.33,
                high: 174.33,
                lag_ms: 187,
                low: 174.33,
                open: 174.33,
                source: "Polygon_ws",
                start_ms: 1720040798999,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 174.33,
                websocket: true,
              },
              {
                close: 174.275,
                high: 174.275,
                lag_ms: 188,
                low: 174.275,
                open: 174.275,
                source: "TwelveData_ws",
                start_ms: 1720040799000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1720040798812,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "ac96f15c-b5c2-4602-a407-5169023d15d7",
            price: 174.35,
            price_sources: [
              {
                close: 174.35,
                high: 174.35,
                lag_ms: 499,
                low: 174.35,
                open: 174.35,
                source: "Polygon_ws",
                start_ms: 1720040959000,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 174.35,
                websocket: true,
              },
              {
                close: 174.289,
                high: 174.289,
                lag_ms: 499,
                low: 174.289,
                open: 174.289,
                source: "TwelveData_ws",
                start_ms: 1720040959000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
              {
                close: 174.3797195253506,
                high: 174.3797195253506,
                lag_ms: 5502,
                low: 174.3797195253506,
                open: 174.3797195253506,
                source: "Polygon_rest",
                start_ms: 1720040952000,
                timespan_ms: 1000,
                volume: 1.0,
                vwap: null,
                websocket: false,
              },
              {
                close: 174.28999,
                high: 174.36,
                lag_ms: 318502,
                low: 174.28,
                open: 174.34,
                source: "TwelveData_rest",
                start_ms: 1720040580000,
                timespan_ms: 60000,
                volume: null,
                vwap: null,
                websocket: false,
              },
            ],
            processed_ms: 1720040958501,
          },
        ],
        position_type: "FLAT",
        position_uuid: "e470d368-01b2-4e06-8b49-6722aa71c2cd",
        return_at_close: 1.000008944668158,
        trade_pair: ["EURJPY", "EUR/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 174.33226190476188,
        close_ms: 1720041104812,
        current_return: 1.0000372813306568,
        initial_entry_price: 174.35,
        is_closed_position: true,
        miner_hotkey: "5D5GDbEa5Aedt9fXhWTzngDTANvnf8Q5poSFKYkU9hEZ7P8C",
        net_leverage: 0.0,
        open_ms: 1720041001954,
        orders: [
          {
            leverage: 0.2,
            order_type: "LONG",
            order_uuid: "c18683f5-0a10-4a6b-a386-440e8cd466da",
            price: 174.35,
            price_sources: [
              {
                close: 174.35,
                high: 174.35,
                lag_ms: 395,
                low: 174.35,
                open: 174.35,
                source: "Polygon_ws",
                start_ms: 1720040995000,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 174.35,
                websocket: true,
              },
              {
                close: 174.35,
                high: 174.35,
                lag_ms: 395,
                low: 174.35,
                open: 174.35,
                source: "Polygon_rest",
                start_ms: 1720040995000,
                timespan_ms: 1000,
                volume: 1.0,
                vwap: null,
                websocket: false,
              },
              {
                close: 174.313,
                high: 174.313,
                lag_ms: 4605,
                low: 174.313,
                open: 174.313,
                source: "TwelveData_ws",
                start_ms: 1720041000000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
              {
                close: 174.28999,
                high: 174.36,
                lag_ms: 355396,
                low: 174.28,
                open: 174.34,
                source: "TwelveData_rest",
                start_ms: 1720040580000,
                timespan_ms: 60000,
                volume: null,
                vwap: null,
                websocket: false,
              },
            ],
            processed_ms: 1720040995395,
          },
          {
            leverage: 0.22000000000000003,
            order_type: "LONG",
            order_uuid: "fd0c3640-95ae-4ef1-8dc7-057ad5a13a80",
            price: 174.33,
            price_sources: [
              {
                close: 174.33,
                high: 174.33,
                lag_ms: 396,
                low: 174.33,
                open: 174.33,
                source: "Polygon_ws",
                start_ms: 1720041025999,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 174.33,
                websocket: true,
              },
              {
                close: 174.27,
                high: 174.27,
                lag_ms: 2397,
                low: 174.27,
                open: 174.27,
                source: "TwelveData_ws",
                start_ms: 1720041028000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1720041025603,
          },
          {
            leverage: 0.42000000000000004,
            order_type: "LONG",
            order_uuid: "9097dded-56e2-4653-9d4a-c21bb5309c3e",
            price: 174.325,
            price_sources: [
              {
                close: 174.325,
                high: 174.325,
                lag_ms: 545,
                low: 174.325,
                open: 174.325,
                source: "TwelveData_ws",
                start_ms: 1720041056000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
              {
                close: 174.282,
                high: 174.282,
                lag_ms: 4456,
                low: 174.282,
                open: 174.282,
                source: "Polygon_ws",
                start_ms: 1720041050999,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 174.282,
                websocket: true,
              },
              {
                close: 174.282,
                high: 174.282,
                lag_ms: 4456,
                low: 174.282,
                open: 174.282,
                source: "Polygon_rest",
                start_ms: 1720041050000,
                timespan_ms: 1000,
                volume: 1.0,
                vwap: null,
                websocket: false,
              },
              {
                close: 174.28999,
                high: 174.36,
                lag_ms: 415456,
                low: 174.28,
                open: 174.34,
                source: "TwelveData_rest",
                start_ms: 1720040580000,
                timespan_ms: 60000,
                volume: null,
                vwap: null,
                websocket: false,
              },
            ],
            processed_ms: 1720041055455,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "ad13e717-7346-447d-9c04-9f38180f6134",
            price: 174.34,
            price_sources: [
              {
                close: 174.34,
                high: 174.34,
                lag_ms: 188,
                low: 174.34,
                open: 174.34,
                source: "Polygon_ws",
                start_ms: 1720041105000,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 174.34,
                websocket: true,
              },
              {
                close: 174.287,
                high: 174.287,
                lag_ms: 1812,
                low: 174.287,
                open: 174.287,
                source: "TwelveData_ws",
                start_ms: 1720041103000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
              {
                close: 174.35,
                high: 174.35,
                lag_ms: 3813,
                low: 174.35,
                open: 174.35,
                source: "Polygon_rest",
                start_ms: 1720041100000,
                timespan_ms: 1000,
                volume: 1.0,
                vwap: null,
                websocket: false,
              },
              {
                close: 174.28999,
                high: 174.36,
                lag_ms: 464813,
                low: 174.28,
                open: 174.34,
                source: "TwelveData_rest",
                start_ms: 1720040580000,
                timespan_ms: 60000,
                volume: null,
                vwap: null,
                websocket: false,
              },
            ],
            processed_ms: 1720041104812,
          },
        ],
        position_type: "FLAT",
        position_uuid: "7fddf9c9-b9e6-4bc8-84ef-d1b6bdd34d0b",
        return_at_close: 0.9999784791385146,
        trade_pair: ["EURJPY", "EUR/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 174.33,
        close_ms: 1720041298985,
        current_return: 1.0000229449893878,
        initial_entry_price: 174.33,
        is_closed_position: true,
        miner_hotkey: "5D5GDbEa5Aedt9fXhWTzngDTANvnf8Q5poSFKYkU9hEZ7P8C",
        net_leverage: 0.0,
        open_ms: 1720041147818,
        orders: [
          {
            leverage: 0.2,
            order_type: "LONG",
            order_uuid: "66c44e1c-0df7-43d5-b608-79f5cb2808ec",
            price: 174.33,
            price_sources: [
              {
                close: 174.33,
                high: 174.33,
                lag_ms: 1004,
                low: 174.33,
                open: 174.33,
                source: "Polygon_ws",
                start_ms: 1720041146000,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 174.33,
                websocket: true,
              },
              {
                close: 174.314,
                high: 174.314,
                lag_ms: 2004,
                low: 174.314,
                open: 174.314,
                source: "TwelveData_ws",
                start_ms: 1720041147000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
              {
                close: 174.271,
                high: 174.271,
                lag_ms: 3997,
                low: 174.271,
                open: 174.271,
                source: "Polygon_rest",
                start_ms: 1720041140000,
                timespan_ms: 1000,
                volume: 1.0,
                vwap: null,
                websocket: false,
              },
              {
                close: 174.28999,
                high: 174.36,
                lag_ms: 504997,
                low: 174.28,
                open: 174.34,
                source: "TwelveData_rest",
                start_ms: 1720040580000,
                timespan_ms: 60000,
                volume: null,
                vwap: null,
                websocket: false,
              },
            ],
            processed_ms: 1720041144996,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "4208ed98-146d-405d-863c-19e8fc0e5714",
            price: 174.35,
            price_sources: [
              {
                close: 174.35,
                high: 174.35,
                lag_ms: 1986,
                low: 174.35,
                open: 174.35,
                source: "Polygon_ws",
                start_ms: 1720041296999,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 174.35,
                websocket: true,
              },
              {
                close: 174.338,
                high: 174.338,
                lag_ms: 3015,
                low: 174.338,
                open: 174.338,
                source: "TwelveData_ws",
                start_ms: 1720041302000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1720041298985,
          },
        ],
        position_type: "FLAT",
        position_uuid: "5d3698d9-65df-452d-b453-a48ff0870e4e",
        return_at_close: 1.000008944668158,
        trade_pair: ["EURJPY", "EUR/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 174.293,
        close_ms: 1720041526976,
        current_return: 1.0000206528598474,
        initial_entry_price: 174.31,
        is_closed_position: true,
        miner_hotkey: "5D5GDbEa5Aedt9fXhWTzngDTANvnf8Q5poSFKYkU9hEZ7P8C",
        net_leverage: 0.0,
        open_ms: 1720041332605,
        orders: [
          {
            leverage: 0.2,
            order_type: "LONG",
            order_uuid: "5f7b07d7-d311-4d0b-983b-e6d1c230154d",
            price: 174.31,
            price_sources: [
              {
                close: 174.31,
                high: 174.31,
                lag_ms: 95,
                low: 174.31,
                open: 174.31,
                source: "Polygon_ws",
                start_ms: 1720041331999,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 174.31,
                websocket: true,
              },
              {
                close: 174.268,
                high: 174.268,
                lag_ms: 96,
                low: 174.268,
                open: 174.268,
                source: "TwelveData_ws",
                start_ms: 1720041332000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
              {
                close: 174.32,
                high: 174.32,
                lag_ms: 4905,
                low: 174.32,
                open: 174.32,
                source: "Polygon_rest",
                start_ms: 1720041326000,
                timespan_ms: 1000,
                volume: 1.0,
                vwap: null,
                websocket: false,
              },
              {
                close: 174.28999,
                high: 174.36,
                lag_ms: 691905,
                low: 174.28,
                open: 174.34,
                source: "TwelveData_rest",
                start_ms: 1720040580000,
                timespan_ms: 60000,
                volume: null,
                vwap: null,
                websocket: false,
              },
            ],
            processed_ms: 1720041331904,
          },
          {
            leverage: 0.2,
            order_type: "LONG",
            order_uuid: "20420fe2-f827-41d9-9df2-c607ffc6af82",
            price: 174.276,
            price_sources: [
              {
                close: 174.276,
                high: 174.276,
                lag_ms: 1741,
                low: 174.276,
                open: 174.276,
                source: "TwelveData_ws",
                start_ms: 1720041369000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
              {
                close: 174.243,
                high: 174.243,
                lag_ms: 3742,
                low: 174.243,
                open: 174.243,
                source: "Polygon_ws",
                start_ms: 1720041366999,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 174.243,
                websocket: true,
              },
              {
                close: 174.243,
                high: 174.243,
                lag_ms: 3742,
                low: 174.243,
                open: 174.243,
                source: "Polygon_rest",
                start_ms: 1720041366000,
                timespan_ms: 1000,
                volume: 1.0,
                vwap: null,
                websocket: false,
              },
              {
                close: 174.28999,
                high: 174.36,
                lag_ms: 730742,
                low: 174.28,
                open: 174.34,
                source: "TwelveData_rest",
                start_ms: 1720040580000,
                timespan_ms: 60000,
                volume: null,
                vwap: null,
                websocket: false,
              },
            ],
            processed_ms: 1720041370741,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "4211aea8-8e44-4038-8b33-a13d5b81cc52",
            price: 174.302,
            price_sources: [
              {
                close: 174.302,
                high: 174.302,
                lag_ms: 2024,
                low: 174.302,
                open: 174.302,
                source: "TwelveData_ws",
                start_ms: 1720041529000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
              {
                close: 174.35608414239482,
                high: 174.35608414239482,
                lag_ms: 3977,
                low: 174.35608414239482,
                open: 174.35608414239482,
                source: "Polygon_ws",
                start_ms: 1720041522999,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 174.3721682847896,
                websocket: true,
              },
              {
                close: 174.35608414239482,
                high: 174.35608414239482,
                lag_ms: 3977,
                low: 174.35608414239482,
                open: 174.35608414239482,
                source: "Polygon_rest",
                start_ms: 1720041522000,
                timespan_ms: 1000,
                volume: 2.0,
                vwap: null,
                websocket: false,
              },
              {
                close: 174.34,
                high: 174.35001,
                lag_ms: 133024,
                low: 174.28999,
                open: 174.35001,
                source: "TwelveData_rest",
                start_ms: 1720041660000,
                timespan_ms: 60000,
                volume: null,
                vwap: null,
                websocket: false,
              },
            ],
            processed_ms: 1720041526976,
          },
        ],
        position_type: "FLAT",
        position_uuid: "3c4f1a48-2667-46d3-9c67-48c8e17d966f",
        return_at_close: 0.9999926522815673,
        trade_pair: ["EURJPY", "EUR/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 174.31428571428572,
        close_ms: 1720041686923,
        current_return: 0.9999125451729478,
        initial_entry_price: 174.33,
        is_closed_position: true,
        miner_hotkey: "5D5GDbEa5Aedt9fXhWTzngDTANvnf8Q5poSFKYkU9hEZ7P8C",
        net_leverage: 0.0,
        open_ms: 1720041563776,
        orders: [
          {
            leverage: 0.2,
            order_type: "LONG",
            order_uuid: "1295d743-0fd7-4363-83da-7b9072a589f4",
            price: 174.33,
            price_sources: [
              {
                close: 174.33,
                high: 174.33,
                lag_ms: 172,
                low: 174.33,
                open: 174.33,
                source: "Polygon_ws",
                start_ms: 1720041563000,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 174.33,
                websocket: true,
              },
              {
                close: 174.305,
                high: 174.305,
                lag_ms: 1828,
                low: 174.305,
                open: 174.305,
                source: "TwelveData_ws",
                start_ms: 1720041561000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
              {
                close: 174.31,
                high: 174.31,
                lag_ms: 3829,
                low: 174.31,
                open: 174.31,
                source: "Polygon_rest",
                start_ms: 1720041558000,
                timespan_ms: 1000,
                volume: 1.0,
                vwap: null,
                websocket: false,
              },
              {
                close: 174.33,
                high: 174.37,
                lag_ms: 202829,
                low: 174.31,
                open: 174.34,
                source: "TwelveData_rest",
                start_ms: 1720041300000,
                timespan_ms: 60000,
                volume: null,
                vwap: null,
                websocket: false,
              },
            ],
            processed_ms: 1720041562828,
          },
          {
            leverage: 0.22000000000000003,
            order_type: "LONG",
            order_uuid: "b41ca949-16cd-4ce6-abcc-411e7744c399",
            price: 174.33,
            price_sources: [
              {
                close: 174.33,
                high: 174.33,
                lag_ms: 1182,
                low: 174.33,
                open: 174.33,
                source: "Polygon_ws",
                start_ms: 1720041598000,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 174.33,
                websocket: true,
              },
              {
                close: 174.32,
                high: 174.32,
                lag_ms: 2819,
                low: 174.32,
                open: 174.32,
                source: "Polygon_rest",
                start_ms: 1720041593000,
                timespan_ms: 1000,
                volume: 1.0,
                vwap: null,
                websocket: false,
              },
              {
                close: 174.307,
                high: 174.307,
                lag_ms: 7182,
                low: 174.307,
                open: 174.307,
                source: "TwelveData_ws",
                start_ms: 1720041604000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
              {
                close: 174.33,
                high: 174.37,
                lag_ms: 236819,
                low: 174.31,
                open: 174.34,
                source: "TwelveData_rest",
                start_ms: 1720041300000,
                timespan_ms: 60000,
                volume: null,
                vwap: null,
                websocket: false,
              },
            ],
            processed_ms: 1720041596818,
          },
          {
            leverage: 0.4620000000000001,
            order_type: "LONG",
            order_uuid: "3b118444-4ab4-4866-ac33-af1510072cc5",
            price: 174.3,
            price_sources: [
              {
                close: 174.3,
                high: 174.3,
                lag_ms: 1199,
                low: 174.3,
                open: 174.3,
                source: "Polygon_ws",
                start_ms: 1720041628000,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 174.3,
                websocket: true,
              },
              {
                close: 174.307,
                high: 174.307,
                lag_ms: 1199,
                low: 174.307,
                open: 174.307,
                source: "TwelveData_ws",
                start_ms: 1720041628000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
              {
                close: 174.214,
                high: 174.214,
                lag_ms: 3802,
                low: 174.214,
                open: 174.214,
                source: "Polygon_rest",
                start_ms: 1720041622000,
                timespan_ms: 1000,
                volume: 1.0,
                vwap: null,
                websocket: false,
              },
              {
                close: 174.33,
                high: 174.37,
                lag_ms: 266802,
                low: 174.31,
                open: 174.34,
                source: "TwelveData_rest",
                start_ms: 1720041300000,
                timespan_ms: 60000,
                volume: null,
                vwap: null,
                websocket: false,
              },
            ],
            processed_ms: 1720041626801,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "edf98a6e-e19a-4825-ab23-21c210a405dc",
            price: 174.297,
            price_sources: [
              {
                close: 174.297,
                high: 174.297,
                lag_ms: 2077,
                low: 174.297,
                open: 174.297,
                source: "TwelveData_ws",
                start_ms: 1720041689000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
              {
                close: 174.16,
                high: 174.16,
                lag_ms: 2924,
                low: 174.16,
                open: 174.16,
                source: "Polygon_ws",
                start_ms: 1720041683999,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 174.16,
                websocket: true,
              },
              {
                close: 174.16,
                high: 174.16,
                lag_ms: 2924,
                low: 174.16,
                open: 174.16,
                source: "Polygon_rest",
                start_ms: 1720041683000,
                timespan_ms: 1000,
                volume: 1.0,
                vwap: null,
                websocket: false,
              },
              {
                close: 174.34,
                high: 174.35001,
                lag_ms: 26923,
                low: 174.28999,
                open: 174.35001,
                source: "TwelveData_rest",
                start_ms: 1720041660000,
                timespan_ms: 60000,
                volume: null,
                vwap: null,
                websocket: false,
              },
            ],
            processed_ms: 1720041686923,
          },
        ],
        position_type: "FLAT",
        position_uuid: "5bda6ee5-493f-474b-9b36-d15135e6d6eb",
        return_at_close: 0.9998508105724088,
        trade_pair: ["EURJPY", "EUR/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 174.224,
        close_ms: 1720041918952,
        current_return: 0.9997397888939881,
        initial_entry_price: 174.32,
        is_closed_position: true,
        miner_hotkey: "5D5GDbEa5Aedt9fXhWTzngDTANvnf8Q5poSFKYkU9hEZ7P8C",
        net_leverage: 0.0,
        open_ms: 1720041717779,
        orders: [
          {
            leverage: 0.2,
            order_type: "LONG",
            order_uuid: "78ec0bab-837c-40b5-aed1-6a78a5d8792d",
            price: 174.32,
            price_sources: [
              {
                close: 174.32,
                high: 174.32,
                lag_ms: 1555,
                low: 174.32,
                open: 174.32,
                source: "Polygon_ws",
                start_ms: 1720041719000,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 174.32,
                websocket: true,
              },
              {
                close: 174.3,
                high: 174.3,
                lag_ms: 3445,
                low: 174.3,
                open: 174.3,
                source: "TwelveData_ws",
                start_ms: 1720041714000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
              {
                close: 174.31,
                high: 174.31,
                lag_ms: 3446,
                low: 174.31,
                open: 174.31,
                source: "Polygon_rest",
                start_ms: 1720041713000,
                timespan_ms: 1000,
                volume: 1.0,
                vwap: null,
                websocket: false,
              },
              {
                close: 174.33,
                high: 174.37,
                lag_ms: 357446,
                low: 174.31,
                open: 174.34,
                source: "TwelveData_rest",
                start_ms: 1720041300000,
                timespan_ms: 60000,
                volume: null,
                vwap: null,
                websocket: false,
              },
            ],
            processed_ms: 1720041717445,
          },
          {
            leverage: 0.22000000000000003,
            order_type: "LONG",
            order_uuid: "24642a2a-3fb9-4b88-8c33-88de9fc95440",
            price: 174.278,
            price_sources: [
              {
                close: 174.278,
                high: 174.278,
                lag_ms: 996,
                low: 174.278,
                open: 174.278,
                source: "TwelveData_ws",
                start_ms: 1720041850000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
              {
                close: 174.21,
                high: 174.21,
                lag_ms: 4005,
                low: 174.21,
                open: 174.21,
                source: "Polygon_ws",
                start_ms: 1720041844999,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 174.21,
                websocket: true,
              },
              {
                close: 174.21,
                high: 174.21,
                lag_ms: 4005,
                low: 174.21,
                open: 174.21,
                source: "Polygon_rest",
                start_ms: 1720041844000,
                timespan_ms: 1000,
                volume: 1.0,
                vwap: null,
                websocket: false,
              },
              {
                close: 174.34,
                high: 174.35001,
                lag_ms: 129005,
                low: 174.28999,
                open: 174.35001,
                source: "TwelveData_rest",
                start_ms: 1720041660000,
                timespan_ms: 60000,
                volume: null,
                vwap: null,
                websocket: false,
              },
            ],
            processed_ms: 1720041849004,
          },
          {
            leverage: 0.42000000000000004,
            order_type: "LONG",
            order_uuid: "a01e13fd-779a-4e2d-85f8-8c68407ba04e",
            price: 174.15,
            price_sources: [
              {
                close: 174.15,
                high: 174.15,
                lag_ms: 45,
                low: 174.15,
                open: 174.15,
                source: "Polygon_ws",
                start_ms: 1720041890000,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 174.15,
                websocket: true,
              },
              {
                close: 174.282,
                high: 174.282,
                lag_ms: 955,
                low: 174.282,
                open: 174.282,
                source: "TwelveData_ws",
                start_ms: 1720041889000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
              {
                close: 174.08,
                high: 174.08,
                lag_ms: 4956,
                low: 174.08,
                open: 174.08,
                source: "Polygon_rest",
                start_ms: 1720041884000,
                timespan_ms: 1000,
                volume: 1.0,
                vwap: null,
                websocket: false,
              },
              {
                close: 174.34,
                high: 174.35001,
                lag_ms: 169956,
                low: 174.28999,
                open: 174.35001,
                source: "TwelveData_rest",
                start_ms: 1720041660000,
                timespan_ms: 60000,
                volume: null,
                vwap: null,
                websocket: false,
              },
            ],
            processed_ms: 1720041889955,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "7f477e13-248c-4164-ae7a-3b342d85fb13",
            price: 174.17,
            price_sources: [
              {
                close: 174.17,
                high: 174.17,
                lag_ms: 1048,
                low: 174.17,
                open: 174.17,
                source: "Polygon_ws",
                start_ms: 1720041920000,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 174.17,
                websocket: true,
              },
              {
                close: 174.23,
                high: 174.23,
                lag_ms: 3953,
                low: 174.23,
                open: 174.23,
                source: "Polygon_rest",
                start_ms: 1720041914000,
                timespan_ms: 1000,
                volume: 1.0,
                vwap: null,
                websocket: false,
              },
              {
                close: 174.28,
                high: 174.28,
                lag_ms: 4952,
                low: 174.28,
                open: 174.28,
                source: "TwelveData_ws",
                start_ms: 1720041914000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
              {
                close: 174.34,
                high: 174.35001,
                lag_ms: 198953,
                low: 174.28999,
                open: 174.35001,
                source: "TwelveData_rest",
                start_ms: 1720041660000,
                timespan_ms: 60000,
                volume: null,
                vwap: null,
                websocket: false,
              },
            ],
            processed_ms: 1720041918952,
          },
        ],
        position_type: "FLAT",
        position_uuid: "283b3b14-4c3c-403d-af11-3ff0154d6223",
        return_at_close: 0.9996810041944011,
        trade_pair: ["EURJPY", "EUR/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 174.284,
        close_ms: 1720041984868,
        current_return: 1.0000527874044662,
        initial_entry_price: 174.284,
        is_closed_position: true,
        miner_hotkey: "5D5GDbEa5Aedt9fXhWTzngDTANvnf8Q5poSFKYkU9hEZ7P8C",
        net_leverage: 0.0,
        open_ms: 1720041955486,
        orders: [
          {
            leverage: 0.2,
            order_type: "LONG",
            order_uuid: "dfa7c96f-1213-446c-bc67-3f20b813263c",
            price: 174.284,
            price_sources: [
              {
                close: 174.284,
                high: 174.284,
                lag_ms: 825,
                low: 174.284,
                open: 174.284,
                source: "TwelveData_ws",
                start_ms: 1720041956000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
              {
                close: 174.247,
                high: 174.247,
                lag_ms: 1176,
                low: 174.247,
                open: 174.247,
                source: "Polygon_ws",
                start_ms: 1720041953999,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 174.247,
                websocket: true,
              },
            ],
            processed_ms: 1720041955175,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "6755c950-39eb-4faa-a86a-e41c21bcb415",
            price: 174.33,
            price_sources: [
              {
                close: 174.33,
                high: 174.33,
                lag_ms: 132,
                low: 174.33,
                open: 174.33,
                source: "Polygon_ws",
                start_ms: 1720041985000,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 174.33,
                websocket: true,
              },
              {
                close: 174.299,
                high: 174.299,
                lag_ms: 1132,
                low: 174.299,
                open: 174.299,
                source: "TwelveData_ws",
                start_ms: 1720041986000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1720041984868,
          },
        ],
        position_type: "FLAT",
        position_uuid: "431d20c5-8859-4738-8ee3-654982bebe5d",
        return_at_close: 1.0000387866654425,
        trade_pair: ["EURJPY", "EUR/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 174.2863157894737,
        close_ms: 1720042106826,
        current_return: 1.0000298319086685,
        initial_entry_price: 174.31,
        is_closed_position: true,
        miner_hotkey: "5D5GDbEa5Aedt9fXhWTzngDTANvnf8Q5poSFKYkU9hEZ7P8C",
        net_leverage: 0.0,
        open_ms: 1720042015936,
        orders: [
          {
            leverage: 0.2,
            order_type: "LONG",
            order_uuid: "b8b7aa5a-dbd3-4263-83e4-a24668693f60",
            price: 174.31,
            price_sources: [
              {
                close: 174.31,
                high: 174.31,
                lag_ms: 460,
                low: 174.31,
                open: 174.31,
                source: "Polygon_ws",
                start_ms: 1720042015000,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 174.31,
                websocket: true,
              },
              {
                close: 174.297,
                high: 174.297,
                lag_ms: 540,
                low: 174.297,
                open: 174.297,
                source: "TwelveData_ws",
                start_ms: 1720042016000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1720042015460,
          },
          {
            leverage: 0.18000000000000002,
            order_type: "LONG",
            order_uuid: "aee2320c-c865-4997-af95-444579c21bf7",
            price: 174.26,
            price_sources: [
              {
                close: 174.26,
                high: 174.26,
                lag_ms: 181,
                low: 174.26,
                open: 174.26,
                source: "Polygon_ws",
                start_ms: 1720042062999,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 174.26,
                websocket: true,
              },
              {
                close: 174.276,
                high: 174.276,
                lag_ms: 818,
                low: 174.276,
                open: 174.276,
                source: "TwelveData_ws",
                start_ms: 1720042062000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1720042062818,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "451f7371-1a25-4d0a-851b-8519c82abd05",
            price: 174.3,
            price_sources: [
              {
                close: 174.3,
                high: 174.3,
                lag_ms: 174,
                low: 174.3,
                open: 174.3,
                source: "Polygon_ws",
                start_ms: 1720042107000,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 174.3,
                websocket: true,
              },
              {
                close: 174.287,
                high: 174.287,
                lag_ms: 826,
                low: 174.287,
                open: 174.287,
                source: "TwelveData_ws",
                start_ms: 1720042106000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
              {
                close: 174.226,
                high: 174.226,
                lag_ms: 2827,
                low: 174.226,
                open: 174.226,
                source: "Polygon_rest",
                start_ms: 1720042103000,
                timespan_ms: 1000,
                volume: 1.0,
                vwap: null,
                websocket: false,
              },
              {
                close: 174.34,
                high: 174.35001,
                lag_ms: 386827,
                low: 174.28999,
                open: 174.35001,
                source: "TwelveData_rest",
                start_ms: 1720041660000,
                timespan_ms: 60000,
                volume: null,
                vwap: null,
                websocket: false,
              },
            ],
            processed_ms: 1720042106826,
          },
        ],
        position_type: "FLAT",
        position_uuid: "c248abb2-b9c9-466e-8e95-3894d8e40c5e",
        return_at_close: 1.0000032311151397,
        trade_pair: ["EURJPY", "EUR/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 174.319,
        close_ms: 1720042210792,
        current_return: 0.9999975201263234,
        initial_entry_price: 174.319,
        is_closed_position: true,
        miner_hotkey: "5D5GDbEa5Aedt9fXhWTzngDTANvnf8Q5poSFKYkU9hEZ7P8C",
        net_leverage: 0.0,
        open_ms: 1720042137827,
        orders: [
          {
            leverage: 0.2,
            order_type: "LONG",
            order_uuid: "f1046c61-3ee8-471a-9e67-d432fa81a779",
            price: 174.319,
            price_sources: [
              {
                close: 174.319,
                high: 174.319,
                lag_ms: 966,
                low: 174.319,
                open: 174.319,
                source: "TwelveData_ws",
                start_ms: 1720042136000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
              {
                close: 174.32,
                high: 174.32,
                lag_ms: 1034,
                low: 174.32,
                open: 174.32,
                source: "Polygon_ws",
                start_ms: 1720042138000,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 174.32,
                websocket: true,
              },
              {
                close: 174.283,
                high: 174.283,
                lag_ms: 2967,
                low: 174.283,
                open: 174.283,
                source: "Polygon_rest",
                start_ms: 1720042133000,
                timespan_ms: 1000,
                volume: 1.0,
                vwap: null,
                websocket: false,
              },
              {
                close: 174.34,
                high: 174.35001,
                lag_ms: 416967,
                low: 174.28999,
                open: 174.35001,
                source: "TwelveData_rest",
                start_ms: 1720041660000,
                timespan_ms: 60000,
                volume: null,
                vwap: null,
                websocket: false,
              },
            ],
            processed_ms: 1720042136966,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "81ad3368-fce1-4864-a2e7-26b1dd513cf8",
            price: 174.3168385545028,
            price_sources: [
              {
                close: 174.3168385545028,
                high: 174.3168385545028,
                lag_ms: 793,
                low: 174.3168385545028,
                open: 174.3168385545028,
                source: "Polygon_ws",
                start_ms: 1720042209999,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 174.3168385545028,
                websocket: true,
              },
              {
                close: 174.31,
                high: 174.31,
                lag_ms: 7792,
                low: 174.31,
                open: 174.31,
                source: "TwelveData_ws",
                start_ms: 1720042203000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1720042210792,
          },
        ],
        position_type: "FLAT",
        position_uuid: "21e22916-d3dd-4d3a-9cf3-2fa5de1072ad",
        return_at_close: 0.9999835201610416,
        trade_pair: ["EURJPY", "EUR/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 174.35000000000002,
        close_ms: 1720042495156,
        current_return: 1.0000229436732821,
        initial_entry_price: 174.34,
        is_closed_position: true,
        miner_hotkey: "5D5GDbEa5Aedt9fXhWTzngDTANvnf8Q5poSFKYkU9hEZ7P8C",
        net_leverage: 0.0,
        open_ms: 1720042243863,
        orders: [
          {
            leverage: 0.2,
            order_type: "LONG",
            order_uuid: "663e6ee9-58e3-4be8-b24f-01202e07bfc3",
            price: 174.34,
            price_sources: [
              {
                close: 174.34,
                high: 174.34,
                lag_ms: 116,
                low: 174.34,
                open: 174.34,
                source: "Polygon_ws",
                start_ms: 1720042243000,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 174.34,
                websocket: true,
              },
              {
                close: 174.313,
                high: 174.313,
                lag_ms: 884,
                low: 174.313,
                open: 174.313,
                source: "TwelveData_ws",
                start_ms: 1720042242000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
              {
                close: 174.282,
                high: 174.282,
                lag_ms: 2885,
                low: 174.282,
                open: 174.282,
                source: "Polygon_rest",
                start_ms: 1720042239000,
                timespan_ms: 1000,
                volume: 1.0,
                vwap: null,
                websocket: false,
              },
              {
                close: 174.34,
                high: 174.35001,
                lag_ms: 522885,
                low: 174.28999,
                open: 174.35001,
                source: "TwelveData_rest",
                start_ms: 1720041660000,
                timespan_ms: 60000,
                volume: null,
                vwap: null,
                websocket: false,
              },
            ],
            processed_ms: 1720042242884,
          },
          {
            leverage: 0.2,
            order_type: "LONG",
            order_uuid: "67f4c249-6cdb-47c4-9346-a463b7264c0a",
            price: 174.36,
            price_sources: [
              {
                close: 174.36,
                high: 174.36,
                lag_ms: 64,
                low: 174.36,
                open: 174.36,
                source: "Polygon_ws",
                start_ms: 1720042319000,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 174.36,
                websocket: true,
              },
              {
                close: 174.314,
                high: 174.314,
                lag_ms: 2064,
                low: 174.314,
                open: 174.314,
                source: "TwelveData_ws",
                start_ms: 1720042321000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
              {
                close: 174.314,
                high: 174.314,
                lag_ms: 3937,
                low: 174.314,
                open: 174.314,
                source: "Polygon_rest",
                start_ms: 1720042314000,
                timespan_ms: 1000,
                volume: 2.0,
                vwap: null,
                websocket: false,
              },
              {
                close: 174.34,
                high: 174.36,
                lag_ms: 58937,
                low: 174.34,
                open: 174.35001,
                source: "TwelveData_rest",
                start_ms: 1720042200000,
                timespan_ms: 60000,
                volume: null,
                vwap: null,
                websocket: false,
              },
            ],
            processed_ms: 1720042318936,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "524c88fd-96c5-4af7-9533-83dadccf38e0",
            price: 174.36,
            price_sources: [
              {
                close: 174.36,
                high: 174.36,
                lag_ms: 156,
                low: 174.36,
                open: 174.36,
                source: "Polygon_ws",
                start_ms: 1720042495000,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 174.36,
                websocket: true,
              },
              {
                close: 174.335,
                high: 174.335,
                lag_ms: 844,
                low: 174.335,
                open: 174.335,
                source: "TwelveData_ws",
                start_ms: 1720042496000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1720042495156,
          },
        ],
        position_type: "FLAT",
        position_uuid: "793281bd-cfc8-4d77-999a-cd28a9c0ecd0",
        return_at_close: 0.9999949430308592,
        trade_pair: ["EURJPY", "EUR/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 174.29839598997495,
        close_ms: 1720042735011,
        current_return: 1.0000741798577655,
        initial_entry_price: 174.36,
        is_closed_position: true,
        miner_hotkey: "5D5GDbEa5Aedt9fXhWTzngDTANvnf8Q5poSFKYkU9hEZ7P8C",
        net_leverage: 0.0,
        open_ms: 1720042545496,
        orders: [
          {
            leverage: 0.2,
            order_type: "LONG",
            order_uuid: "aeafce9b-ab90-42f9-bdc0-15b4ffa4ee47",
            price: 174.36,
            price_sources: [
              {
                close: 174.36,
                high: 174.36,
                lag_ms: 128,
                low: 174.36,
                open: 174.36,
                source: "Polygon_ws",
                start_ms: 1720042544999,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 174.36,
                websocket: true,
              },
              {
                close: 174.346,
                high: 174.346,
                lag_ms: 871,
                low: 174.346,
                open: 174.346,
                source: "TwelveData_ws",
                start_ms: 1720042544000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1720042544871,
          },
          {
            leverage: 0.18000000000000002,
            order_type: "LONG",
            order_uuid: "e6d12132-c0ac-44d9-8df3-d1a45e9e60a4",
            price: 174.35,
            price_sources: [
              {
                close: 174.35,
                high: 174.35,
                lag_ms: 1,
                low: 174.35,
                open: 174.35,
                source: "Polygon_ws",
                start_ms: 1720042604999,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 174.35,
                websocket: true,
              },
              {
                close: 174.316,
                high: 174.316,
                lag_ms: 1002,
                low: 174.316,
                open: 174.316,
                source: "TwelveData_ws",
                start_ms: 1720042606000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1720042604998,
          },
          {
            leverage: 0.41800000000000004,
            order_type: "LONG",
            order_uuid: "6fbc3682-d635-4a56-b4cc-a478d6d27382",
            price: 174.32,
            price_sources: [
              {
                close: 174.32,
                high: 174.32,
                lag_ms: 187,
                low: 174.32,
                open: 174.32,
                source: "Polygon_ws",
                start_ms: 1720042664999,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 174.32,
                websocket: true,
              },
              {
                close: 174.306,
                high: 174.306,
                lag_ms: 188,
                low: 174.306,
                open: 174.306,
                source: "TwelveData_ws",
                start_ms: 1720042665000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
              {
                close: 174.303,
                high: 174.303,
                lag_ms: 3813,
                low: 174.303,
                open: 174.303,
                source: "Polygon_rest",
                start_ms: 1720042660000,
                timespan_ms: 1000,
                volume: 2.0,
                vwap: null,
                websocket: false,
              },
              {
                close: 174.34,
                high: 174.36,
                lag_ms: 404813,
                low: 174.34,
                open: 174.35001,
                source: "TwelveData_rest",
                start_ms: 1720042200000,
                timespan_ms: 60000,
                volume: null,
                vwap: null,
                websocket: false,
              },
            ],
            processed_ms: 1720042664812,
          },
          {
            leverage: 0.798,
            order_type: "LONG",
            order_uuid: "01e71d60-12fd-4e67-9f22-65d5b9f14cda",
            price: 174.26,
            price_sources: [
              {
                close: 174.26,
                high: 174.26,
                lag_ms: 250,
                low: 174.26,
                open: 174.26,
                source: "Polygon_ws",
                start_ms: 1720042695000,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 174.26,
                websocket: true,
              },
              {
                close: 174.306,
                high: 174.306,
                lag_ms: 2750,
                low: 174.306,
                open: 174.306,
                source: "TwelveData_ws",
                start_ms: 1720042692000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
              {
                close: 174.2525,
                high: 174.2525,
                lag_ms: 3751,
                low: 174.2525,
                open: 174.2525,
                source: "Polygon_rest",
                start_ms: 1720042690000,
                timespan_ms: 1000,
                volume: 2.0,
                vwap: null,
                websocket: false,
              },
              {
                close: 174.34,
                high: 174.36,
                lag_ms: 434751,
                low: 174.34,
                open: 174.35001,
                source: "TwelveData_rest",
                start_ms: 1720042200000,
                timespan_ms: 60000,
                volume: null,
                vwap: null,
                websocket: false,
              },
            ],
            processed_ms: 1720042694750,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "1932b9b8-3f14-4195-9db9-4f32e77c962b",
            price: 174.3065,
            price_sources: [
              {
                close: 174.3065,
                high: 174.3065,
                lag_ms: 11,
                low: 174.3065,
                open: 174.3065,
                source: "Polygon_ws",
                start_ms: 1720042735000,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 174.32,
                websocket: true,
              },
              {
                close: 174.31,
                high: 174.31,
                lag_ms: 1011,
                low: 174.31,
                open: 174.31,
                source: "TwelveData_ws",
                start_ms: 1720042734000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
              {
                close: 174.34,
                high: 174.34,
                lag_ms: 4012,
                low: 174.34,
                open: 174.34,
                source: "Polygon_rest",
                start_ms: 1720042730000,
                timespan_ms: 1000,
                volume: 1.0,
                vwap: null,
                websocket: false,
              },
              {
                close: 174.34,
                high: 174.36,
                lag_ms: 475012,
                low: 174.34,
                open: 174.35001,
                source: "TwelveData_rest",
                start_ms: 1720042200000,
                timespan_ms: 60000,
                volume: null,
                vwap: null,
                websocket: false,
              },
            ],
            processed_ms: 1720042735011,
          },
        ],
        position_type: "FLAT",
        position_uuid: "9608ca57-17f1-4e20-bcec-0f3d09aaafd3",
        return_at_close: 0.9999624515703918,
        trade_pair: ["EURJPY", "EUR/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 174.2769035087719,
        close_ms: 1720043233943,
        current_return: 1.0001526747800726,
        initial_entry_price: 174.288,
        is_closed_position: true,
        miner_hotkey: "5D5GDbEa5Aedt9fXhWTzngDTANvnf8Q5poSFKYkU9hEZ7P8C",
        net_leverage: 0.0,
        open_ms: 1720042766430,
        orders: [
          {
            leverage: 0.2,
            order_type: "LONG",
            order_uuid: "33384d50-e9a0-44c8-a281-63949ad49051",
            price: 174.288,
            price_sources: [
              {
                close: 174.288,
                high: 174.288,
                lag_ms: 80,
                low: 174.288,
                open: 174.288,
                source: "Polygon_ws",
                start_ms: 1720042765000,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 174.288,
                websocket: true,
              },
              {
                close: 174.31,
                high: 174.31,
                lag_ms: 2920,
                low: 174.31,
                open: 174.31,
                source: "TwelveData_ws",
                start_ms: 1720042768000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
              {
                close: 174.34,
                high: 174.34,
                lag_ms: 4081,
                low: 174.34,
                open: 174.34,
                source: "Polygon_rest",
                start_ms: 1720042760000,
                timespan_ms: 1000,
                volume: 1.0,
                vwap: null,
                websocket: false,
              },
              {
                close: 174.34,
                high: 174.36,
                lag_ms: 505081,
                low: 174.34,
                open: 174.35001,
                source: "TwelveData_rest",
                start_ms: 1720042200000,
                timespan_ms: 60000,
                volume: null,
                vwap: null,
                websocket: false,
              },
            ],
            processed_ms: 1720042765080,
          },
          {
            leverage: 0.22000000000000003,
            order_type: "LONG",
            order_uuid: "8bb25e88-9df8-478b-9d2e-db7696e4369b",
            price: 174.33,
            price_sources: [
              {
                close: 174.33,
                high: 174.33,
                lag_ms: 187,
                low: 174.33,
                open: 174.33,
                source: "Polygon_ws",
                start_ms: 1720042800999,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 174.33,
                websocket: true,
              },
              {
                close: 174.32,
                high: 174.32,
                lag_ms: 1188,
                low: 174.32,
                open: 174.32,
                source: "TwelveData_ws",
                start_ms: 1720042802000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1720042800812,
          },
          {
            leverage: 0.4620000000000001,
            order_type: "LONG",
            order_uuid: "e79155e9-6d88-4851-82fd-4c16e9a12da4",
            price: 174.275,
            price_sources: [
              {
                close: 174.275,
                high: 174.275,
                lag_ms: 574,
                low: 174.275,
                open: 174.275,
                source: "TwelveData_ws",
                start_ms: 1720043132000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
              {
                close: 174.3,
                high: 174.3,
                lag_ms: 1574,
                low: 174.3,
                open: 174.3,
                source: "Polygon_ws",
                start_ms: 1720043133000,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 174.3,
                websocket: true,
              },
              {
                close: 174.245,
                high: 174.245,
                lag_ms: 3427,
                low: 174.245,
                open: 174.245,
                source: "Polygon_rest",
                start_ms: 1720043127000,
                timespan_ms: 1000,
                volume: 1.0,
                vwap: null,
                websocket: false,
              },
              {
                close: 174.34,
                high: 174.36,
                lag_ms: 871427,
                low: 174.34,
                open: 174.35001,
                source: "TwelveData_rest",
                start_ms: 1720042200000,
                timespan_ms: 60000,
                volume: null,
                vwap: null,
                websocket: false,
              },
            ],
            processed_ms: 1720043131426,
          },
          {
            leverage: 0.7938000000000002,
            order_type: "LONG",
            order_uuid: "f8b89f05-2e0c-4533-bf46-70c811952880",
            price: 174.26049999999998,
            price_sources: [
              {
                close: 174.26049999999998,
                high: 174.26049999999998,
                lag_ms: 162,
                low: 174.26049999999998,
                open: 174.26049999999998,
                source: "Polygon_ws",
                start_ms: 1720043202999,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 174.29,
                websocket: true,
              },
              {
                close: 174.256,
                high: 174.256,
                lag_ms: 1163,
                low: 174.256,
                open: 174.256,
                source: "TwelveData_ws",
                start_ms: 1720043204000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1720043202837,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "9a44569d-11f6-4e62-9653-107aa8c21356",
            price: 174.2927821232064,
            price_sources: [
              {
                close: 174.2927821232064,
                high: 174.2927821232064,
                lag_ms: 944,
                low: 174.2927821232064,
                open: 174.2927821232064,
                source: "Polygon_ws",
                start_ms: 1720043232999,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 174.3305642464128,
                websocket: true,
              },
              {
                close: 174.253,
                high: 174.253,
                lag_ms: 1943,
                low: 174.253,
                open: 174.253,
                source: "TwelveData_ws",
                start_ms: 1720043232000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1720043233943,
          },
        ],
        position_type: "FLAT",
        position_uuid: "6cf5ecf6-853b-47f6-8e27-71776cef0758",
        return_at_close: 1.0000353508704047,
        trade_pair: ["EURJPY", "EUR/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 174.23024060150374,
        close_ms: 1720043530824,
        current_return: 1.0000904766549803,
        initial_entry_price: 174.277,
        is_closed_position: true,
        miner_hotkey: "5D5GDbEa5Aedt9fXhWTzngDTANvnf8Q5poSFKYkU9hEZ7P8C",
        net_leverage: 0.0,
        open_ms: 1720043268306,
        orders: [
          {
            leverage: 0.2,
            order_type: "LONG",
            order_uuid: "8fc0e021-12a6-4ff8-b3e8-3c96a87a30c6",
            price: 174.277,
            price_sources: [
              {
                close: 174.277,
                high: 174.277,
                lag_ms: 1079,
                low: 174.277,
                open: 174.277,
                source: "TwelveData_ws",
                start_ms: 1720043266000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
              {
                close: 174.3,
                high: 174.3,
                lag_ms: 2080,
                low: 174.3,
                open: 174.3,
                source: "Polygon_ws",
                start_ms: 1720043264999,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 174.3,
                websocket: true,
              },
              {
                close: 174.3,
                high: 174.3,
                lag_ms: 2080,
                low: 174.3,
                open: 174.3,
                source: "Polygon_rest",
                start_ms: 1720043264000,
                timespan_ms: 1000,
                volume: 1.0,
                vwap: null,
                websocket: false,
              },
              {
                close: 174.34,
                high: 174.36,
                lag_ms: 1007080,
                low: 174.34,
                open: 174.35001,
                source: "TwelveData_rest",
                start_ms: 1720042200000,
                timespan_ms: 60000,
                volume: null,
                vwap: null,
                websocket: false,
              },
            ],
            processed_ms: 1720043267079,
          },
          {
            leverage: 0.22000000000000003,
            order_type: "LONG",
            order_uuid: "e6748989-8d75-4af2-a27e-2ed4c2351b90",
            price: 174.25,
            price_sources: [
              {
                close: 174.25,
                high: 174.25,
                lag_ms: 13,
                low: 174.25,
                open: 174.25,
                source: "Polygon_ws",
                start_ms: 1720043446999,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 174.25,
                websocket: true,
              },
              {
                close: 174.216,
                high: 174.216,
                lag_ms: 1988,
                low: 174.216,
                open: 174.216,
                source: "TwelveData_ws",
                start_ms: 1720043449000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1720043447012,
          },
          {
            leverage: 0.37800000000000006,
            order_type: "LONG",
            order_uuid: "912f13de-d169-41df-982d-affc8732d03a",
            price: 174.194,
            price_sources: [
              {
                close: 174.194,
                high: 174.194,
                lag_ms: 217,
                low: 174.194,
                open: 174.194,
                source: "TwelveData_ws",
                start_ms: 1720043501000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
              {
                close: 174.154,
                high: 174.154,
                lag_ms: 1783,
                low: 174.154,
                open: 174.154,
                source: "Polygon_ws",
                start_ms: 1720043503000,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 174.154,
                websocket: true,
              },
              {
                close: 174.17,
                high: 174.17,
                lag_ms: 4218,
                low: 174.17,
                open: 174.17,
                source: "Polygon_rest",
                start_ms: 1720043496000,
                timespan_ms: 1000,
                volume: 1.0,
                vwap: null,
                websocket: false,
              },
              {
                close: 174.3,
                high: 174.31,
                lag_ms: 101218,
                low: 174.3,
                open: 174.31,
                source: "TwelveData_rest",
                start_ms: 1720043340000,
                timespan_ms: 60000,
                volume: null,
                vwap: null,
                websocket: false,
              },
            ],
            processed_ms: 1720043501217,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "89cc0771-0454-43cb-9b4f-118fb27cf02a",
            price: 174.25,
            price_sources: [
              {
                close: 174.25,
                high: 174.25,
                lag_ms: 175,
                low: 174.25,
                open: 174.25,
                source: "Polygon_ws",
                start_ms: 1720043530999,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 174.25,
                websocket: true,
              },
              {
                close: 174.223,
                high: 174.223,
                lag_ms: 1176,
                low: 174.223,
                open: 174.223,
                source: "TwelveData_ws",
                start_ms: 1720043532000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1720043530824,
          },
        ],
        position_type: "FLAT",
        position_uuid: "622e8ccf-dfee-4d73-bb14-91ab7ca1aa2e",
        return_at_close: 1.0000346116009544,
        trade_pair: ["EURJPY", "EUR/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 174.26999999999998,
        close_ms: 1720043736938,
        current_return: 0.9997878909612626,
        initial_entry_price: 174.25,
        is_closed_position: true,
        miner_hotkey: "5D5GDbEa5Aedt9fXhWTzngDTANvnf8Q5poSFKYkU9hEZ7P8C",
        net_leverage: 0.0,
        open_ms: 1720043561888,
        orders: [
          {
            leverage: 0.2,
            order_type: "LONG",
            order_uuid: "0a1e55ee-4348-4eee-9cd1-e1febbb316ab",
            price: 174.25,
            price_sources: [
              {
                close: 174.25,
                high: 174.25,
                lag_ms: 24,
                low: 174.25,
                open: 174.25,
                source: "Polygon_ws",
                start_ms: 1720043561000,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 174.25,
                websocket: true,
              },
              {
                close: 174.219,
                high: 174.219,
                lag_ms: 1976,
                low: 174.219,
                open: 174.219,
                source: "TwelveData_ws",
                start_ms: 1720043559000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1720043560976,
          },
          {
            leverage: 0.2,
            order_type: "LONG",
            order_uuid: "07d818b5-3f03-439f-ab77-4860042d3ec7",
            price: 174.29,
            price_sources: [
              {
                close: 174.29,
                high: 174.29,
                lag_ms: 147,
                low: 174.29,
                open: 174.29,
                source: "Polygon_ws",
                start_ms: 1720043672999,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 174.29,
                websocket: true,
              },
              {
                close: 174.232,
                high: 174.232,
                lag_ms: 2852,
                low: 174.232,
                open: 174.232,
                source: "TwelveData_ws",
                start_ms: 1720043670000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1720043672852,
          },
          {
            leverage: 0.44000000000000006,
            order_type: "LONG",
            order_uuid: "0f814139-cc2b-4a06-bfc4-7f24cbba2c1a",
            price: 174.27,
            price_sources: [
              {
                close: 174.27,
                high: 174.27,
                lag_ms: 36,
                low: 174.27,
                open: 174.27,
                source: "Polygon_ws",
                start_ms: 1720043702999,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 174.27,
                websocket: true,
              },
              {
                close: 174.222,
                high: 174.222,
                lag_ms: 37,
                low: 174.222,
                open: 174.222,
                source: "TwelveData_ws",
                start_ms: 1720043703000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1720043702963,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "543e3ae6-b820-40bc-b52e-7a4b97267eed",
            price: 174.226,
            price_sources: [
              {
                close: 174.226,
                high: 174.226,
                lag_ms: 62,
                low: 174.226,
                open: 174.226,
                source: "TwelveData_ws",
                start_ms: 1720043737000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
              {
                close: 174.25,
                high: 174.25,
                lag_ms: 3939,
                low: 174.25,
                open: 174.25,
                source: "Polygon_ws",
                start_ms: 1720043732999,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 174.25,
                websocket: true,
              },
              {
                close: 174.25,
                high: 174.25,
                lag_ms: 3939,
                low: 174.25,
                open: 174.25,
                source: "Polygon_rest",
                start_ms: 1720043732000,
                timespan_ms: 1000,
                volume: 1.0,
                vwap: null,
                websocket: false,
              },
              {
                close: 174.28,
                high: 174.304,
                lag_ms: 36939,
                low: 174.255,
                open: 174.28999,
                source: "TwelveData_rest",
                start_ms: 1720043640000,
                timespan_ms: 60000,
                volume: null,
                vwap: null,
                websocket: false,
              },
            ],
            processed_ms: 1720043736938,
          },
        ],
        position_type: "FLAT",
        position_uuid: "2fbeb910-a5ac-4976-904b-84e3c01cebb6",
        return_at_close: 0.999729103433274,
        trade_pair: ["EURJPY", "EUR/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 174.25,
        close_ms: 1720044027518,
        current_return: 1.0001330272596844,
        initial_entry_price: 174.25,
        is_closed_position: true,
        miner_hotkey: "5D5GDbEa5Aedt9fXhWTzngDTANvnf8Q5poSFKYkU9hEZ7P8C",
        net_leverage: 0.0,
        open_ms: 1720043778986,
        orders: [
          {
            leverage: 0.2,
            order_type: "LONG",
            order_uuid: "2bac5de0-8103-432a-858c-e3c96b6cc3d5",
            price: 174.25,
            price_sources: [
              {
                close: 174.25,
                high: 174.25,
                lag_ms: 124,
                low: 174.25,
                open: 174.25,
                source: "Polygon_ws",
                start_ms: 1720043777999,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 174.25,
                websocket: true,
              },
              {
                close: 174.225,
                high: 174.225,
                lag_ms: 2876,
                low: 174.225,
                open: 174.225,
                source: "Polygon_rest",
                start_ms: 1720043774000,
                timespan_ms: 1000,
                volume: 1.0,
                vwap: null,
                websocket: false,
              },
              {
                close: 174.233,
                high: 174.233,
                lag_ms: 4125,
                low: 174.233,
                open: 174.233,
                source: "TwelveData_ws",
                start_ms: 1720043782000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
              {
                close: 174.28,
                high: 174.304,
                lag_ms: 77876,
                low: 174.255,
                open: 174.28999,
                source: "TwelveData_rest",
                start_ms: 1720043640000,
                timespan_ms: 60000,
                volume: null,
                vwap: null,
                websocket: false,
              },
            ],
            processed_ms: 1720043777875,
          },
          {
            leverage: 0.18000000000000002,
            order_type: "LONG",
            order_uuid: "ca972e21-3b8b-4cef-a3bb-a50fef95167e",
            price: 174.25,
            price_sources: [
              {
                close: 174.25,
                high: 174.25,
                lag_ms: 10,
                low: 174.25,
                open: 174.25,
                source: "Polygon_ws",
                start_ms: 1720043838999,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 174.25,
                websocket: true,
              },
              {
                close: 174.23,
                high: 174.23,
                lag_ms: 2991,
                low: 174.23,
                open: 174.23,
                source: "TwelveData_ws",
                start_ms: 1720043842000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1720043839009,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "aa3ffd30-266a-4fcf-aea6-e1931fe495c0",
            price: 174.311,
            price_sources: [
              {
                close: 174.311,
                high: 174.311,
                lag_ms: 482,
                low: 174.311,
                open: 174.311,
                source: "Polygon_ws",
                start_ms: 1720044028000,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 174.306,
                websocket: true,
              },
              {
                close: 174.322,
                high: 174.322,
                lag_ms: 518,
                low: 174.322,
                open: 174.322,
                source: "TwelveData_ws",
                start_ms: 1720044027000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1720044027518,
          },
        ],
        position_type: "FLAT",
        position_uuid: "33a81688-5e3c-4709-bfa1-505a1db2b3b6",
        return_at_close: 1.0001064237211594,
        trade_pair: ["EURJPY", "EUR/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 174.279,
        close_ms: 1720044233081,
        current_return: 1.000149396304679,
        initial_entry_price: 174.30149999999998,
        is_closed_position: true,
        miner_hotkey: "5D5GDbEa5Aedt9fXhWTzngDTANvnf8Q5poSFKYkU9hEZ7P8C",
        net_leverage: 0.0,
        open_ms: 1720044057028,
        orders: [
          {
            leverage: 0.2,
            order_type: "LONG",
            order_uuid: "8019c16b-12d6-45c8-90bd-75e376babf9c",
            price: 174.30149999999998,
            price_sources: [
              {
                close: 174.30149999999998,
                high: 174.30149999999998,
                lag_ms: 268,
                low: 174.30149999999998,
                open: 174.30149999999998,
                source: "Polygon_ws",
                start_ms: 1720044056999,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 174.301,
                websocket: true,
              },
              {
                close: 174.325,
                high: 174.325,
                lag_ms: 3269,
                low: 174.325,
                open: 174.325,
                source: "TwelveData_ws",
                start_ms: 1720044060000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1720044056731,
          },
          {
            leverage: 0.22000000000000003,
            order_type: "LONG",
            order_uuid: "c06411b0-779c-4c98-83a0-302915733d7f",
            price: 174.291,
            price_sources: [
              {
                close: 174.291,
                high: 174.291,
                lag_ms: 231,
                low: 174.291,
                open: 174.291,
                source: "Polygon_ws",
                start_ms: 1720044097000,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 174.291,
                websocket: true,
              },
              {
                close: 174.298,
                high: 174.298,
                lag_ms: 769,
                low: 174.298,
                open: 174.298,
                source: "TwelveData_ws",
                start_ms: 1720044098000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1720044097231,
          },
          {
            leverage: 0.42000000000000004,
            order_type: "LONG",
            order_uuid: "8a8cee74-ee0e-498d-b82f-37014371aef3",
            price: 174.262,
            price_sources: [
              {
                close: 174.262,
                high: 174.262,
                lag_ms: 112,
                low: 174.262,
                open: 174.262,
                source: "Polygon_ws",
                start_ms: 1720044148999,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 174.258,
                websocket: true,
              },
              {
                close: 174.269,
                high: 174.269,
                lag_ms: 113,
                low: 174.269,
                open: 174.269,
                source: "TwelveData_ws",
                start_ms: 1720044149000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1720044148887,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "cb2e57a9-b744-482e-8387-392d201dceab",
            price: 174.31,
            price_sources: [
              {
                close: 174.31,
                high: 174.31,
                lag_ms: 81,
                low: 174.31,
                open: 174.31,
                source: "Polygon_ws",
                start_ms: 1720044233000,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 174.31,
                websocket: true,
              },
              {
                close: 174.313,
                high: 174.313,
                lag_ms: 919,
                low: 174.313,
                open: 174.313,
                source: "TwelveData_ws",
                start_ms: 1720044234000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1720044233081,
          },
        ],
        position_type: "FLAT",
        position_uuid: "283c3daf-43cf-4114-9484-62abab5c2d94",
        return_at_close: 1.0000905875201762,
        trade_pair: ["EURJPY", "EUR/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 174.315,
        close_ms: 1720048766148,
        current_return: 1.0001078538236474,
        initial_entry_price: 174.31,
        is_closed_position: true,
        miner_hotkey: "5D5GDbEa5Aedt9fXhWTzngDTANvnf8Q5poSFKYkU9hEZ7P8C",
        net_leverage: 0.0,
        open_ms: 1720044263297,
        orders: [
          {
            leverage: 0.2,
            order_type: "LONG",
            order_uuid: "cc5dbc38-1ba7-493c-9303-2da6ab3ca1c2",
            price: 174.31,
            price_sources: [
              {
                close: 174.31,
                high: 174.31,
                lag_ms: 91,
                low: 174.31,
                open: 174.31,
                source: "Polygon_ws",
                start_ms: 1720044262999,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 174.31,
                websocket: true,
              },
              {
                close: 174.328,
                high: 174.328,
                lag_ms: 2908,
                low: 174.328,
                open: 174.328,
                source: "TwelveData_ws",
                start_ms: 1720044260000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1720044262908,
          },
          {
            leverage: 0.2,
            order_type: "LONG",
            order_uuid: "0af4644a-0625-46d2-a54f-22a5ac247488",
            price: 174.32,
            price_sources: [
              {
                close: 174.32,
                high: 174.32,
                lag_ms: 48,
                low: 174.32,
                open: 174.32,
                source: "Polygon_ws",
                start_ms: 1720044323000,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 174.32,
                websocket: true,
              },
              {
                close: 174.317,
                high: 174.317,
                lag_ms: 952,
                low: 174.317,
                open: 174.317,
                source: "TwelveData_ws",
                start_ms: 1720044322000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1720044322952,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "2267c8ad-f8c4-423b-b3bc-d910fdc6086f",
            price: 174.362,
            price_sources: [
              {
                close: 174.362,
                high: 174.362,
                lag_ms: 148,
                low: 174.362,
                open: 174.362,
                source: "Polygon_ws",
                start_ms: 1720048766000,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 174.361,
                websocket: true,
              },
              {
                close: 174.381,
                high: 174.381,
                lag_ms: 852,
                low: 174.381,
                open: 174.381,
                source: "TwelveData_ws",
                start_ms: 1720048767000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1720048766148,
          },
        ],
        position_type: "FLAT",
        position_uuid: "71dd530a-9276-4841-ae1e-77f9ed3d7134",
        return_at_close: 1.0000798508037403,
        trade_pair: ["EURJPY", "EUR/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 174.369,
        close_ms: 1720050500557,
        current_return: 1.0001101056325912,
        initial_entry_price: 174.378,
        is_closed_position: true,
        miner_hotkey: "5D5GDbEa5Aedt9fXhWTzngDTANvnf8Q5poSFKYkU9hEZ7P8C",
        net_leverage: 0.0,
        open_ms: 1720048798624,
        orders: [
          {
            leverage: 0.2,
            order_type: "LONG",
            order_uuid: "6a822f30-de7b-453f-b3d9-69fb65c69bff",
            price: 174.378,
            price_sources: [
              {
                close: 174.378,
                high: 174.378,
                lag_ms: 206,
                low: 174.378,
                open: 174.378,
                source: "Polygon_ws",
                start_ms: 1720048797999,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 174.378,
                websocket: true,
              },
              {
                close: 174.38,
                high: 174.38,
                lag_ms: 1207,
                low: 174.38,
                open: 174.38,
                source: "TwelveData_ws",
                start_ms: 1720048799000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
              {
                close: 174.381,
                high: 174.381,
                lag_ms: 3794,
                low: 174.381,
                open: 174.381,
                source: "Polygon_rest",
                start_ms: 1720048793000,
                timespan_ms: 1000,
                volume: 1.0,
                vwap: null,
                websocket: false,
              },
              {
                close: 174.39799,
                high: 174.39999,
                lag_ms: 297794,
                low: 174.38,
                open: 174.38,
                source: "TwelveData_rest",
                start_ms: 1720048440000,
                timespan_ms: 60000,
                volume: null,
                vwap: null,
                websocket: false,
              },
            ],
            processed_ms: 1720048797793,
          },
          {
            leverage: 0.2,
            order_type: "LONG",
            order_uuid: "4cfc1c06-bc34-4dab-b4fe-625cbc554664",
            price: 174.36,
            price_sources: [
              {
                close: 174.36,
                high: 174.36,
                lag_ms: 64,
                low: 174.36,
                open: 174.36,
                source: "Polygon_ws",
                start_ms: 1720048916000,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 174.36,
                websocket: true,
              },
              {
                close: 174.371,
                high: 174.371,
                lag_ms: 1064,
                low: 174.371,
                open: 174.371,
                source: "TwelveData_ws",
                start_ms: 1720048915000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1720048916064,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "a9548412-0e4c-4b53-b422-3ac411d7e1f1",
            price: 174.417,
            price_sources: [
              {
                close: 174.417,
                high: 174.417,
                lag_ms: 442,
                low: 174.417,
                open: 174.417,
                source: "Polygon_ws",
                start_ms: 1720050500999,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 174.418,
                websocket: true,
              },
              {
                close: 174.423,
                high: 174.423,
                lag_ms: 557,
                low: 174.423,
                open: 174.423,
                source: "TwelveData_ws",
                start_ms: 1720050500000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1720050500557,
          },
        ],
        position_type: "FLAT",
        position_uuid: "a6ac4a06-8a00-48b7-b50a-826fd9092b46",
        return_at_close: 1.0000821025496334,
        trade_pair: ["EURJPY", "EUR/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 174.30166834850286,
        close_ms: 1720051800578,
        current_return: 1.0002549481675145,
        initial_entry_price: 174.408,
        is_closed_position: true,
        miner_hotkey: "5D5GDbEa5Aedt9fXhWTzngDTANvnf8Q5poSFKYkU9hEZ7P8C",
        net_leverage: 0.0,
        open_ms: 1720050530585,
        orders: [
          {
            leverage: 0.2,
            order_type: "LONG",
            order_uuid: "169223e9-9691-437a-9848-14edff0fa678",
            price: 174.408,
            price_sources: [
              {
                close: 174.408,
                high: 174.408,
                lag_ms: 207,
                low: 174.408,
                open: 174.408,
                source: "Polygon_ws",
                start_ms: 1720050529999,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 174.408,
                websocket: true,
              },
              {
                close: 174.415,
                high: 174.415,
                lag_ms: 1792,
                low: 174.415,
                open: 174.415,
                source: "TwelveData_ws",
                start_ms: 1720050528000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1720050529792,
          },
          {
            leverage: 0.2,
            order_type: "LONG",
            order_uuid: "92c19c76-f7d2-40c9-9890-62604af15890",
            price: 174.4,
            price_sources: [
              {
                close: 174.4,
                high: 174.4,
                lag_ms: 340,
                low: 174.4,
                open: 174.4,
                source: "Polygon_ws",
                start_ms: 1720050975000,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 174.4,
                websocket: true,
              },
              {
                close: 174.402,
                high: 174.402,
                lag_ms: 660,
                low: 174.402,
                open: 174.402,
                source: "TwelveData_ws",
                start_ms: 1720050974000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1720050974660,
          },
          {
            leverage: 0.44000000000000006,
            order_type: "LONG",
            order_uuid: "d2434d2b-ce53-49f7-8775-9be88f737d4c",
            price: 174.349,
            price_sources: [
              {
                close: 174.349,
                high: 174.349,
                lag_ms: 234,
                low: 174.349,
                open: 174.349,
                source: "Polygon_ws",
                start_ms: 1720051164000,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 174.35,
                websocket: true,
              },
              {
                close: 174.361,
                high: 174.361,
                lag_ms: 766,
                low: 174.361,
                open: 174.361,
                source: "TwelveData_ws",
                start_ms: 1720051165000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1720051164234,
          },
          {
            leverage: 0.7560000000000001,
            order_type: "LONG",
            order_uuid: "b57d5f92-b30d-4160-9e20-524fd9c1a90d",
            price: 174.32600000000002,
            price_sources: [
              {
                close: 174.32600000000002,
                high: 174.32600000000002,
                lag_ms: 132,
                low: 174.32600000000002,
                open: 174.32600000000002,
                source: "Polygon_ws",
                start_ms: 1720051306000,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 174.334,
                websocket: true,
              },
              {
                close: 174.337,
                high: 174.337,
                lag_ms: 1132,
                low: 174.337,
                open: 174.337,
                source: "TwelveData_ws",
                start_ms: 1720051305000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1720051306132,
          },
          {
            leverage: 1.4364000000000001,
            order_type: "LONG",
            order_uuid: "2499080c-d80c-482f-99e8-1e22026e1db3",
            price: 174.30849999999998,
            price_sources: [
              {
                close: 174.30849999999998,
                high: 174.30849999999998,
                lag_ms: 28,
                low: 174.30849999999998,
                open: 174.30849999999998,
                source: "Polygon_ws",
                start_ms: 1720051372000,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 174.32,
                websocket: true,
              },
              {
                close: 174.32,
                high: 174.32,
                lag_ms: 972,
                low: 174.32,
                open: 174.32,
                source: "TwelveData_ws",
                start_ms: 1720051373000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1720051372028,
          },
          {
            leverage: 3.0324,
            order_type: "LONG",
            order_uuid: "61e3d99b-7056-4a37-b21e-70e17c50b9db",
            price: 174.272,
            price_sources: [
              {
                close: 174.272,
                high: 174.272,
                lag_ms: 12,
                low: 174.272,
                open: 174.272,
                source: "Polygon_ws",
                start_ms: 1720051431999,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 174.269,
                websocket: true,
              },
              {
                close: 174.285,
                high: 174.285,
                lag_ms: 987,
                low: 174.285,
                open: 174.285,
                source: "TwelveData_ws",
                start_ms: 1720051431000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1720051431987,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "03a2cf4a-84ba-452d-8e4a-95dcace82a33",
            price: 174.309,
            price_sources: [
              {
                close: 174.309,
                high: 174.309,
                lag_ms: 421,
                low: 174.309,
                open: 174.309,
                source: "Polygon_ws",
                start_ms: 1720051800999,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 174.31,
                websocket: true,
              },
              {
                close: 174.311,
                high: 174.311,
                lag_ms: 578,
                low: 174.311,
                open: 174.311,
                source: "TwelveData_ws",
                start_ms: 1720051800000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1720051800578,
          },
        ],
        position_type: "FLAT",
        position_uuid: "b1d3528b-f26b-4b66-a86f-05df179e3088",
        return_at_close: 0.9998303039328392,
        trade_pair: ["EURJPY", "EUR/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 173.98703581243208,
        close_ms: 1720055320140,
        current_return: 1.004049626085656,
        initial_entry_price: 174.318,
        is_closed_position: true,
        miner_hotkey: "5D5GDbEa5Aedt9fXhWTzngDTANvnf8Q5poSFKYkU9hEZ7P8C",
        net_leverage: 0.0,
        open_ms: 1720051830115,
        orders: [
          {
            leverage: 0.2,
            order_type: "LONG",
            order_uuid: "00a8ac3a-5fe5-49fe-8f2d-aab492affdcd",
            price: 174.318,
            price_sources: [
              {
                close: 174.318,
                high: 174.318,
                lag_ms: 266,
                low: 174.318,
                open: 174.318,
                source: "Polygon_ws",
                start_ms: 1720051829999,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 174.318,
                websocket: true,
              },
              {
                close: 174.314,
                high: 174.314,
                lag_ms: 267,
                low: 174.314,
                open: 174.314,
                source: "TwelveData_ws",
                start_ms: 1720051830000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1720051829733,
          },
          {
            leverage: 0.2,
            order_type: "LONG",
            order_uuid: "647a7d71-1cbd-4b88-a5ba-3e6af7fa8053",
            price: 174.2985,
            price_sources: [
              {
                close: 174.2985,
                high: 174.2985,
                lag_ms: 168,
                low: 174.2985,
                open: 174.2985,
                source: "Polygon_ws",
                start_ms: 1720051879999,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 174.298,
                websocket: true,
              },
              {
                close: 174.301,
                high: 174.301,
                lag_ms: 1169,
                low: 174.301,
                open: 174.301,
                source: "TwelveData_ws",
                start_ms: 1720051881000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1720051879831,
          },
          {
            leverage: 0.36000000000000004,
            order_type: "LONG",
            order_uuid: "c07bc256-2db5-4264-a1db-0229efc6d680",
            price: 174.255,
            price_sources: [
              {
                close: 174.255,
                high: 174.255,
                lag_ms: 70,
                low: 174.255,
                open: 174.255,
                source: "Polygon_ws",
                start_ms: 1720054231999,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 174.254,
                websocket: true,
              },
              {
                close: 174.272,
                high: 174.272,
                lag_ms: 71,
                low: 174.272,
                open: 174.272,
                source: "TwelveData_ws",
                start_ms: 1720054232000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1720054231929,
          },
          {
            leverage: 0.76,
            order_type: "LONG",
            order_uuid: "6749aab1-1d30-4677-95b6-5251d4edd76a",
            price: 174.165,
            price_sources: [
              {
                close: 174.165,
                high: 174.165,
                lag_ms: 367,
                low: 174.165,
                open: 174.165,
                source: "Polygon_ws",
                start_ms: 1720054495000,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 174.187,
                websocket: true,
              },
              {
                close: 174.196,
                high: 174.196,
                lag_ms: 367,
                low: 174.196,
                open: 174.196,
                source: "TwelveData_ws",
                start_ms: 1720054495000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1720054495367,
          },
          {
            leverage: 1.52,
            order_type: "LONG",
            order_uuid: "67bcf55b-0b96-4cb8-bc6b-93e386bed658",
            price: 174.158,
            price_sources: [
              {
                close: 174.158,
                high: 174.158,
                lag_ms: 480,
                low: 174.158,
                open: 174.158,
                source: "Polygon_ws",
                start_ms: 1720054838999,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 174.165,
                websocket: true,
              },
              {
                close: 174.16,
                high: 174.16,
                lag_ms: 481,
                low: 174.16,
                open: 174.16,
                source: "TwelveData_ws",
                start_ms: 1720054839000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1720054838519,
          },
          {
            leverage: 3.04,
            order_type: "LONG",
            order_uuid: "0bda4184-e60b-4bb4-8375-fd72be659b8d",
            price: 174.107,
            price_sources: [
              {
                close: 174.107,
                high: 174.107,
                lag_ms: 295,
                low: 174.107,
                open: 174.107,
                source: "Polygon_ws",
                start_ms: 1720054868000,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 174.105,
                websocket: true,
              },
              {
                close: 174.103,
                high: 174.103,
                lag_ms: 295,
                low: 174.103,
                open: 174.103,
                source: "TwelveData_ws",
                start_ms: 1720054868000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1720054868295,
          },
          {
            leverage: 6.688000000000001,
            order_type: "LONG",
            order_uuid: "412e7d3d-9049-4ee6-87cf-544d96546f90",
            price: 174.107,
            price_sources: [
              {
                close: 174.107,
                high: 174.107,
                lag_ms: 91,
                low: 174.107,
                open: 174.107,
                source: "Polygon_ws",
                start_ms: 1720054897999,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 174.107,
                websocket: true,
              },
              {
                close: 174.105,
                high: 174.105,
                lag_ms: 908,
                low: 174.105,
                open: 174.105,
                source: "TwelveData_ws",
                start_ms: 1720054897000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1720054897908,
          },
          {
            leverage: 11.491200000000001,
            order_type: "LONG",
            order_uuid: "108f6cf8-c076-4428-ba16-db5603bd7a4e",
            price: 174.0795,
            price_sources: [
              {
                close: 174.0795,
                high: 174.0795,
                lag_ms: 17,
                low: 174.0795,
                open: 174.0795,
                source: "Polygon_ws",
                start_ms: 1720055059000,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 174.077,
                websocket: true,
              },
              {
                close: 174.077,
                high: 174.077,
                lag_ms: 17,
                low: 174.077,
                open: 174.077,
                source: "TwelveData_ws",
                start_ms: 1720055059000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1720055059017,
          },
          {
            leverage: 24.2592,
            order_type: "LONG",
            order_uuid: "9dfb695f-8d94-47c7-ae7d-2eec7ea053c7",
            price: 174.037,
            price_sources: [
              {
                close: 174.037,
                high: 174.037,
                lag_ms: 20,
                low: 174.037,
                open: 174.037,
                source: "Polygon_ws",
                start_ms: 1720055164000,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 174.044,
                websocket: true,
              },
              {
                close: 174.03,
                high: 174.03,
                lag_ms: 980,
                low: 174.03,
                open: 174.03,
                source: "TwelveData_ws",
                start_ms: 1720055165000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1720055164020,
          },
          {
            leverage: 48.5184,
            order_type: "LONG",
            order_uuid: "cddd662c-0c9e-4057-8738-4256c3024b6a",
            price: 173.987,
            price_sources: [
              {
                close: 173.987,
                high: 173.987,
                lag_ms: 185,
                low: 173.987,
                open: 173.987,
                source: "Polygon_ws",
                start_ms: 1720055193999,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 173.986,
                websocket: true,
              },
              {
                close: 173.98,
                high: 173.98,
                lag_ms: 186,
                low: 173.98,
                open: 173.98,
                source: "TwelveData_ws",
                start_ms: 1720055194000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1720055193814,
          },
          {
            leverage: 106.74048,
            order_type: "LONG",
            order_uuid: "a53f9c27-4fff-4508-95ec-eb9111b53486",
            price: 173.949,
            price_sources: [
              {
                close: 173.949,
                high: 173.949,
                lag_ms: 116,
                low: 173.949,
                open: 173.949,
                source: "Polygon_ws",
                start_ms: 1720055259999,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 173.9589911321967,
                websocket: true,
              },
              {
                close: 173.945,
                high: 173.945,
                lag_ms: 117,
                low: 173.945,
                open: 173.945,
                source: "TwelveData_ws",
                start_ms: 1720055260000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1720055259883,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "4a278176-9ff5-4f23-8ebd-efe996b4b24d",
            price: 173.9905,
            price_sources: [
              {
                close: 173.9905,
                high: 173.9905,
                lag_ms: 140,
                low: 173.9905,
                open: 173.9905,
                source: "Polygon_ws",
                start_ms: 1720055320000,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 173.991,
                websocket: true,
              },
              {
                close: 173.995,
                high: 173.995,
                lag_ms: 140,
                low: 173.995,
                open: 173.995,
                source: "TwelveData_ws",
                start_ms: 1720055320000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1720055320140,
          },
        ],
        position_type: "FLAT",
        position_uuid: "c76aabc6-fa09-4fdd-a404-0a3282290380",
        return_at_close: 0.9897274509604433,
        trade_pair: ["EURJPY", "EUR/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 174.021,
        close_ms: 1720055664588,
        current_return: 1.000148832612156,
        initial_entry_price: 174.021,
        is_closed_position: true,
        miner_hotkey: "5D5GDbEa5Aedt9fXhWTzngDTANvnf8Q5poSFKYkU9hEZ7P8C",
        net_leverage: 0.0,
        open_ms: 1720055350583,
        orders: [
          {
            leverage: 0.2,
            order_type: "LONG",
            order_uuid: "3beeb3e8-7522-43e3-a9b1-ae94b25004d6",
            price: 174.021,
            price_sources: [
              {
                close: 174.021,
                high: 174.021,
                lag_ms: 160,
                low: 174.021,
                open: 174.021,
                source: "Polygon_ws",
                start_ms: 1720055350000,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 174.02,
                websocket: true,
              },
              {
                close: 174.032,
                high: 174.032,
                lag_ms: 160,
                low: 174.032,
                open: 174.032,
                source: "TwelveData_ws",
                start_ms: 1720055350000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1720055349840,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "6d43eb3f-0d8a-4047-90ee-fd9014e97aa2",
            price: 174.1505,
            price_sources: [
              {
                close: 174.1505,
                high: 174.1505,
                lag_ms: 411,
                low: 174.1505,
                open: 174.1505,
                source: "Polygon_ws",
                start_ms: 1720055664999,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 174.149,
                websocket: true,
              },
              {
                close: 174.135,
                high: 174.135,
                lag_ms: 5412,
                low: 174.135,
                open: 174.135,
                source: "TwelveData_ws",
                start_ms: 1720055670000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1720055664588,
          },
        ],
        position_type: "FLAT",
        position_uuid: "00958147-6f82-4a52-a69a-1c04a020cc62",
        return_at_close: 1.0001348305284994,
        trade_pair: ["EURJPY", "EUR/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 174.08914473684212,
        close_ms: 1720059257767,
        current_return: 1.0000866633739562,
        initial_entry_price: 174.122,
        is_closed_position: true,
        miner_hotkey: "5D5GDbEa5Aedt9fXhWTzngDTANvnf8Q5poSFKYkU9hEZ7P8C",
        net_leverage: 0.0,
        open_ms: 1720055694474,
        orders: [
          {
            leverage: 0.2,
            order_type: "LONG",
            order_uuid: "8a1907f7-9462-44f6-8cb8-488bb41d506b",
            price: 174.122,
            price_sources: [
              {
                close: 174.122,
                high: 174.122,
                lag_ms: 39,
                low: 174.122,
                open: 174.122,
                source: "Polygon_ws",
                start_ms: 1720055693999,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 174.116,
                websocket: true,
              },
              {
                close: 174.121,
                high: 174.121,
                lag_ms: 40,
                low: 174.121,
                open: 174.121,
                source: "TwelveData_ws",
                start_ms: 1720055694000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1720055693960,
          },
          {
            leverage: 0.18000000000000002,
            order_type: "LONG",
            order_uuid: "0091c69c-3141-4201-bbb1-864e2095ac0e",
            price: 174.1015,
            price_sources: [
              {
                close: 174.1015,
                high: 174.1015,
                lag_ms: 207,
                low: 174.1015,
                open: 174.1015,
                source: "Polygon_ws",
                start_ms: 1720057581000,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 174.101,
                websocket: true,
              },
              {
                close: 174.101,
                high: 174.101,
                lag_ms: 207,
                low: 174.101,
                open: 174.101,
                source: "TwelveData_ws",
                start_ms: 1720057581000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1720057581207,
          },
          {
            leverage: 0.38,
            order_type: "LONG",
            order_uuid: "0f856854-f70a-4147-8275-3c52e456f86c",
            price: 174.066,
            price_sources: [
              {
                close: 174.066,
                high: 174.066,
                lag_ms: 215,
                low: 174.066,
                open: 174.066,
                source: "Polygon_ws",
                start_ms: 1720058776999,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 174.066,
                websocket: true,
              },
              {
                close: 174.067,
                high: 174.067,
                lag_ms: 786,
                low: 174.067,
                open: 174.067,
                source: "TwelveData_ws",
                start_ms: 1720058778000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1720058777214,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "02d62231-80ba-4075-bf4d-4d5822896034",
            price: 174.109,
            price_sources: [
              {
                close: 174.109,
                high: 174.109,
                lag_ms: 232,
                low: 174.109,
                open: 174.109,
                source: "Polygon_ws",
                start_ms: 1720059257999,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 174.109,
                websocket: true,
              },
              {
                close: 174.11,
                high: 174.11,
                lag_ms: 767,
                low: 174.11,
                open: 174.11,
                source: "TwelveData_ws",
                start_ms: 1720059257000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1720059257767,
          },
        ],
        position_type: "FLAT",
        position_uuid: "84c86fbd-6293-4314-81fa-265b49184389",
        return_at_close: 1.0000334587634647,
        trade_pair: ["EURJPY", "EUR/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 174.08700000000002,
        close_ms: 1720061926947,
        current_return: 1.0001711856617646,
        initial_entry_price: 174.08,
        is_closed_position: true,
        miner_hotkey: "5D5GDbEa5Aedt9fXhWTzngDTANvnf8Q5poSFKYkU9hEZ7P8C",
        net_leverage: 0.0,
        open_ms: 1720059288096,
        orders: [
          {
            leverage: 0.2,
            order_type: "LONG",
            order_uuid: "3c7d4875-7b93-4d21-9c45-b71322d3b819",
            price: 174.08,
            price_sources: [
              {
                close: 174.08,
                high: 174.08,
                lag_ms: 363,
                low: 174.08,
                open: 174.08,
                source: "Polygon_ws",
                start_ms: 1720059287999,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 174.08,
                websocket: true,
              },
              {
                close: 174.107,
                high: 174.107,
                lag_ms: 364,
                low: 174.107,
                open: 174.107,
                source: "TwelveData_ws",
                start_ms: 1720059288000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1720059287636,
          },
          {
            leverage: 0.2,
            order_type: "LONG",
            order_uuid: "ebe24628-98f4-436f-a509-39dbb84fcd46",
            price: 174.094,
            price_sources: [
              {
                close: 174.094,
                high: 174.094,
                lag_ms: 892,
                low: 174.094,
                open: 174.094,
                source: "Polygon_ws",
                start_ms: 1720060638000,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 174.09,
                websocket: true,
              },
              {
                close: 174.098,
                high: 174.098,
                lag_ms: 892,
                low: 174.098,
                open: 174.098,
                source: "TwelveData_ws",
                start_ms: 1720060638000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
              {
                close: 174.096,
                high: 174.096,
                lag_ms: 3109,
                low: 174.096,
                open: 174.096,
                source: "Polygon_rest",
                start_ms: 1720060633000,
                timespan_ms: 1000,
                volume: 3.0,
                vwap: null,
                websocket: false,
              },
              {
                close: 174.12,
                high: 174.13,
                lag_ms: 77109,
                low: 174.11,
                open: 174.13,
                source: "TwelveData_rest",
                start_ms: 1720060500000,
                timespan_ms: 60000,
                volume: null,
                vwap: null,
                websocket: false,
              },
            ],
            processed_ms: 1720060637108,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "8f7f5c49-0254-4927-a812-371575fe0e72",
            price: 174.1615,
            price_sources: [
              {
                close: 174.1615,
                high: 174.1615,
                lag_ms: 52,
                low: 174.1615,
                open: 174.1615,
                source: "Polygon_ws",
                start_ms: 1720061926999,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 174.161,
                websocket: true,
              },
              {
                close: 174.158,
                high: 174.158,
                lag_ms: 53,
                low: 174.158,
                open: 174.158,
                source: "TwelveData_ws",
                start_ms: 1720061927000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1720061926947,
          },
        ],
        position_type: "FLAT",
        position_uuid: "5a82ef08-ec00-454a-85c6-0917960ced82",
        return_at_close: 1.000143180868566,
        trade_pair: ["EURJPY", "EUR/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 174.13459523809524,
        close_ms: 1720063928252,
        current_return: 1.0000598345076286,
        initial_entry_price: 174.147,
        is_closed_position: true,
        miner_hotkey: "5D5GDbEa5Aedt9fXhWTzngDTANvnf8Q5poSFKYkU9hEZ7P8C",
        net_leverage: 0.0,
        open_ms: 1720061957992,
        orders: [
          {
            leverage: 0.2,
            order_type: "LONG",
            order_uuid: "d43f6cea-e120-4f53-8137-9b684de9262f",
            price: 174.147,
            price_sources: [
              {
                close: 174.147,
                high: 174.147,
                lag_ms: 333,
                low: 174.147,
                open: 174.147,
                source: "Polygon_ws",
                start_ms: 1720061957000,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 174.145,
                websocket: true,
              },
              {
                close: 174.147,
                high: 174.147,
                lag_ms: 333,
                low: 174.147,
                open: 174.147,
                source: "TwelveData_ws",
                start_ms: 1720061957000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1720061957333,
          },
          {
            leverage: 0.2,
            order_type: "LONG",
            order_uuid: "21d3a2d9-c3c6-4f1d-bd09-5160d512a153",
            price: 174.14,
            price_sources: [
              {
                close: 174.14,
                high: 174.14,
                lag_ms: 76,
                low: 174.14,
                open: 174.14,
                source: "Polygon_ws",
                start_ms: 1720061993000,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 174.14,
                websocket: true,
              },
              {
                close: 174.139,
                high: 174.139,
                lag_ms: 76,
                low: 174.139,
                open: 174.139,
                source: "TwelveData_ws",
                start_ms: 1720061993000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1720061993076,
          },
          {
            leverage: 0.44000000000000006,
            order_type: "LONG",
            order_uuid: "5a37e776-4bfa-4506-afda-271223175c2a",
            price: 174.12650000000002,
            price_sources: [
              {
                close: 174.12650000000002,
                high: 174.12650000000002,
                lag_ms: 183,
                low: 174.12650000000002,
                open: 174.12650000000002,
                source: "Polygon_ws",
                start_ms: 1720062193000,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 174.129,
                websocket: true,
              },
              {
                close: 174.126,
                high: 174.126,
                lag_ms: 183,
                low: 174.126,
                open: 174.126,
                source: "TwelveData_ws",
                start_ms: 1720062193000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1720062193183,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "3e2f7445-d4e9-4b79-920e-9fd0322ec346",
            price: 174.147,
            price_sources: [
              {
                close: 174.147,
                high: 174.147,
                lag_ms: 252,
                low: 174.147,
                open: 174.147,
                source: "Polygon_ws",
                start_ms: 1720063928000,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 174.149,
                websocket: true,
              },
              {
                close: 174.143,
                high: 174.143,
                lag_ms: 252,
                low: 174.143,
                open: 174.143,
                source: "TwelveData_ws",
                start_ms: 1720063928000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1720063928252,
          },
        ],
        position_type: "FLAT",
        position_uuid: "99cf075a-3bbb-475c-b55f-e15814a632d5",
        return_at_close: 1.0000010309893594,
        trade_pair: ["EURJPY", "EUR/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 174.11029072681706,
        close_ms: 1720073376044,
        current_return: 1.0001361441147587,
        initial_entry_price: 174.139,
        is_closed_position: true,
        miner_hotkey: "5D5GDbEa5Aedt9fXhWTzngDTANvnf8Q5poSFKYkU9hEZ7P8C",
        net_leverage: 0.0,
        open_ms: 1720063960561,
        orders: [
          {
            leverage: 0.2,
            order_type: "LONG",
            order_uuid: "151c48fd-d86b-4087-8e8a-9e2efffc228b",
            price: 174.139,
            price_sources: [
              {
                close: 174.139,
                high: 174.139,
                lag_ms: 59,
                low: 174.139,
                open: 174.139,
                source: "Polygon_ws",
                start_ms: 1720063959999,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 174.138,
                websocket: true,
              },
              {
                close: 174.136,
                high: 174.136,
                lag_ms: 60,
                low: 174.136,
                open: 174.136,
                source: "TwelveData_ws",
                start_ms: 1720063960000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1720063959940,
          },
          {
            leverage: 0.18000000000000002,
            order_type: "LONG",
            order_uuid: "07720d67-af8a-473f-9702-fadc1b07c942",
            price: 174.093,
            price_sources: [
              {
                close: 174.093,
                high: 174.093,
                lag_ms: 475,
                low: 174.093,
                open: 174.093,
                source: "Polygon_ws",
                start_ms: 1720073071999,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 174.094,
                websocket: true,
              },
              {
                close: 174.094,
                high: 174.094,
                lag_ms: 476,
                low: 174.094,
                open: 174.094,
                source: "TwelveData_ws",
                start_ms: 1720073072000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1720073071524,
          },
          {
            leverage: 0.41800000000000004,
            order_type: "LONG",
            order_uuid: "eeaac851-5efc-4e19-82ac-eaad8880ea08",
            price: 174.104,
            price_sources: [
              {
                close: 174.104,
                high: 174.104,
                lag_ms: 456,
                low: 174.104,
                open: 174.104,
                source: "Polygon_ws",
                start_ms: 1720073231000,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 174.104,
                websocket: true,
              },
              {
                close: 174.105,
                high: 174.105,
                lag_ms: 456,
                low: 174.105,
                open: 174.105,
                source: "TwelveData_ws",
                start_ms: 1720073231000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1720073231456,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "2393462c-1d73-4663-93a5-898edefdd052",
            price: 174.14,
            price_sources: [
              {
                close: 174.14,
                high: 174.14,
                lag_ms: 44,
                low: 174.14,
                open: 174.14,
                source: "Polygon_ws",
                start_ms: 1720073376000,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 174.143,
                websocket: true,
              },
              {
                close: 174.139,
                high: 174.139,
                lag_ms: 44,
                low: 174.139,
                open: 174.139,
                source: "TwelveData_ws",
                start_ms: 1720073376000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1720073376044,
          },
        ],
        position_type: "FLAT",
        position_uuid: "8f9f7e0e-1de0-4e59-889b-615f05ef67d3",
        return_at_close: 1.0000802765097485,
        trade_pair: ["EURJPY", "EUR/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 174.0767806122449,
        close_ms: 1720074613414,
        current_return: 1.0003365002799431,
        initial_entry_price: 174.14249999999998,
        is_closed_position: true,
        miner_hotkey: "5D5GDbEa5Aedt9fXhWTzngDTANvnf8Q5poSFKYkU9hEZ7P8C",
        net_leverage: 0.0,
        open_ms: 1720073407221,
        orders: [
          {
            leverage: 0.2,
            order_type: "LONG",
            order_uuid: "7a921c52-f509-4387-9a20-f73d55367329",
            price: 174.14249999999998,
            price_sources: [
              {
                close: 174.14249999999998,
                high: 174.14249999999998,
                lag_ms: 358,
                low: 174.14249999999998,
                open: 174.14249999999998,
                source: "Polygon_ws",
                start_ms: 1720073405999,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 174.143,
                websocket: true,
              },
              {
                close: 174.144,
                high: 174.144,
                lag_ms: 641,
                low: 174.144,
                open: 174.144,
                source: "TwelveData_ws",
                start_ms: 1720073405000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1720073405641,
          },
          {
            leverage: 0.22000000000000003,
            order_type: "LONG",
            order_uuid: "a65c9347-c35d-42a6-9883-13cc32011d3a",
            price: 174.108,
            price_sources: [
              {
                close: 174.108,
                high: 174.108,
                lag_ms: 297,
                low: 174.108,
                open: 174.108,
                source: "Polygon_ws",
                start_ms: 1720073473999,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 174.108,
                websocket: true,
              },
              {
                close: 174.108,
                high: 174.108,
                lag_ms: 298,
                low: 174.108,
                open: 174.108,
                source: "TwelveData_ws",
                start_ms: 1720073474000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1720073473702,
          },
          {
            leverage: 0.4620000000000001,
            order_type: "LONG",
            order_uuid: "e5b27792-e9a8-44c5-8094-97b9f8239e0b",
            price: 174.0865,
            price_sources: [
              {
                close: 174.0865,
                high: 174.0865,
                lag_ms: 228,
                low: 174.0865,
                open: 174.0865,
                source: "Polygon_ws",
                start_ms: 1720073577000,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 174.08,
                websocket: true,
              },
              {
                close: 174.088,
                high: 174.088,
                lag_ms: 772,
                low: 174.088,
                open: 174.088,
                source: "TwelveData_ws",
                start_ms: 1720073578000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1720073577228,
          },
          {
            leverage: 0.8820000000000001,
            order_type: "LONG",
            order_uuid: "118feacb-a317-48d4-bdc4-41b41ea03bf7",
            price: 174.049,
            price_sources: [
              {
                close: 174.049,
                high: 174.049,
                lag_ms: 292,
                low: 174.049,
                open: 174.049,
                source: "Polygon_ws",
                start_ms: 1720074406999,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 174.02,
                websocket: true,
              },
              {
                close: 174.052,
                high: 174.052,
                lag_ms: 707,
                low: 174.052,
                open: 174.052,
                source: "TwelveData_ws",
                start_ms: 1720074406000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1720074406707,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "4032291f-0c3e-47cf-8029-05dca92aadf2",
            price: 174.11,
            price_sources: [
              {
                close: 174.11,
                high: 174.11,
                lag_ms: 414,
                low: 174.11,
                open: 174.11,
                source: "Polygon_ws",
                start_ms: 1720074613000,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 174.11,
                websocket: true,
              },
              {
                close: 174.108,
                high: 174.108,
                lag_ms: 586,
                low: 174.108,
                open: 174.108,
                source: "TwelveData_ws",
                start_ms: 1720074614000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1720074613414,
          },
        ],
        position_type: "FLAT",
        position_uuid: "22c6e23c-06b2-4b36-a56e-a4772cc911f3",
        return_at_close: 1.0002129787288887,
        trade_pair: ["EURJPY", "EUR/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 174.10678947368424,
        close_ms: 1720075340822,
        current_return: 1.0000855770997978,
        initial_entry_price: 174.112,
        is_closed_position: true,
        miner_hotkey: "5D5GDbEa5Aedt9fXhWTzngDTANvnf8Q5poSFKYkU9hEZ7P8C",
        net_leverage: 0.0,
        open_ms: 1720074643874,
        orders: [
          {
            leverage: 0.2,
            order_type: "LONG",
            order_uuid: "6feb255b-fc54-4e40-9500-383dd43e7767",
            price: 174.112,
            price_sources: [
              {
                close: 174.112,
                high: 174.112,
                lag_ms: 395,
                low: 174.112,
                open: 174.112,
                source: "Polygon_ws",
                start_ms: 1720074642000,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 174.112,
                websocket: true,
              },
              {
                close: 174.11,
                high: 174.11,
                lag_ms: 395,
                low: 174.11,
                open: 174.11,
                source: "TwelveData_ws",
                start_ms: 1720074642000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1720074642395,
          },
          {
            leverage: 0.18000000000000002,
            order_type: "LONG",
            order_uuid: "a4cf70d5-286a-4cdb-be8c-98b279beaa7b",
            price: 174.101,
            price_sources: [
              {
                close: 174.101,
                high: 174.101,
                lag_ms: 82,
                low: 174.101,
                open: 174.101,
                source: "Polygon_ws",
                start_ms: 1720075134000,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 174.101,
                websocket: true,
              },
              {
                close: 174.103,
                high: 174.103,
                lag_ms: 918,
                low: 174.103,
                open: 174.103,
                source: "TwelveData_ws",
                start_ms: 1720075135000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1720075134082,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "51159247-5bb2-41da-b4a5-da7ffc02e04d",
            price: 174.14600000000002,
            price_sources: [
              {
                close: 174.14600000000002,
                high: 174.14600000000002,
                lag_ms: 177,
                low: 174.14600000000002,
                open: 174.14600000000002,
                source: "Polygon_ws",
                start_ms: 1720075340999,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 174.15,
                websocket: true,
              },
              {
                close: 174.148,
                high: 174.148,
                lag_ms: 178,
                low: 174.148,
                open: 174.148,
                source: "TwelveData_ws",
                start_ms: 1720075341000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1720075340822,
          },
        ],
        position_type: "FLAT",
        position_uuid: "df6d9ce8-2ed1-4d85-8bb4-b2832db94fb0",
        return_at_close: 1.000058974823447,
        trade_pair: ["EURJPY", "EUR/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 174.13825000000003,
        close_ms: 1720077828911,
        current_return: 1.0001624993899094,
        initial_entry_price: 174.15449999999998,
        is_closed_position: true,
        miner_hotkey: "5D5GDbEa5Aedt9fXhWTzngDTANvnf8Q5poSFKYkU9hEZ7P8C",
        net_leverage: 0.0,
        open_ms: 1720075374292,
        orders: [
          {
            leverage: 0.2,
            order_type: "LONG",
            order_uuid: "e03e044d-dc4a-4406-b815-85c0ca00b494",
            price: 174.15449999999998,
            price_sources: [
              {
                close: 174.15449999999998,
                high: 174.15449999999998,
                lag_ms: 450,
                low: 174.15449999999998,
                open: 174.15449999999998,
                source: "Polygon_ws",
                start_ms: 1720075372999,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 174.153,
                websocket: true,
              },
              {
                close: 174.154,
                high: 174.154,
                lag_ms: 549,
                low: 174.154,
                open: 174.154,
                source: "TwelveData_ws",
                start_ms: 1720075372000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1720075372549,
          },
          {
            leverage: 0.2,
            order_type: "LONG",
            order_uuid: "6a0738b5-7fc0-45a4-bfad-c97c66731467",
            price: 174.122,
            price_sources: [
              {
                close: 174.122,
                high: 174.122,
                lag_ms: 364,
                low: 174.122,
                open: 174.122,
                source: "Polygon_ws",
                start_ms: 1720076007000,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 174.122,
                websocket: true,
              },
              {
                close: 174.124,
                high: 174.124,
                lag_ms: 364,
                low: 174.124,
                open: 174.124,
                source: "TwelveData_ws",
                start_ms: 1720076007000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1720076007364,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "cf14905c-7778-4d10-b823-b5aa8c456edd",
            price: 174.209,
            price_sources: [
              {
                close: 174.209,
                high: 174.209,
                lag_ms: 88,
                low: 174.209,
                open: 174.209,
                source: "Polygon_ws",
                start_ms: 1720077828999,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 174.212,
                websocket: true,
              },
              {
                close: 174.208,
                high: 174.208,
                lag_ms: 911,
                low: 174.208,
                open: 174.208,
                source: "TwelveData_ws",
                start_ms: 1720077828000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1720077828911,
          },
        ],
        position_type: "FLAT",
        position_uuid: "7de4f13a-7536-4890-9faf-a6a2d048847f",
        return_at_close: 1.0001344948399264,
        trade_pair: ["EURJPY", "EUR/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 174.122637702289,
        close_ms: 1720079228128,
        current_return: 0.999958459211466,
        initial_entry_price: 174.2095,
        is_closed_position: true,
        miner_hotkey: "5D5GDbEa5Aedt9fXhWTzngDTANvnf8Q5poSFKYkU9hEZ7P8C",
        net_leverage: 0.0,
        open_ms: 1720077861456,
        orders: [
          {
            leverage: 0.2,
            order_type: "LONG",
            order_uuid: "3345073f-72f8-46de-87a5-20bb3f6b5ba6",
            price: 174.2095,
            price_sources: [
              {
                close: 174.2095,
                high: 174.2095,
                lag_ms: 336,
                low: 174.2095,
                open: 174.2095,
                source: "Polygon_ws",
                start_ms: 1720077858999,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 174.211,
                websocket: true,
              },
              {
                close: 174.206,
                high: 174.206,
                lag_ms: 663,
                low: 174.206,
                open: 174.206,
                source: "TwelveData_ws",
                start_ms: 1720077858000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1720077858663,
          },
          {
            leverage: 0.2,
            order_type: "LONG",
            order_uuid: "05ed807d-1056-46cd-a72e-c2b32e30952f",
            price: 174.192,
            price_sources: [
              {
                close: 174.192,
                high: 174.192,
                lag_ms: 245,
                low: 174.192,
                open: 174.192,
                source: "Polygon_ws",
                start_ms: 1720077926999,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 174.19,
                websocket: true,
              },
              {
                close: 174.196,
                high: 174.196,
                lag_ms: 246,
                low: 174.196,
                open: 174.196,
                source: "TwelveData_ws",
                start_ms: 1720077927000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1720077926754,
          },
          {
            leverage: 0.36000000000000004,
            order_type: "LONG",
            order_uuid: "ddd0f87a-8e48-4c34-a8a7-4ab7e93e1714",
            price: 174.15449999999998,
            price_sources: [
              {
                close: 174.15449999999998,
                high: 174.15449999999998,
                lag_ms: 123,
                low: 174.15449999999998,
                open: 174.15449999999998,
                source: "Polygon_ws",
                start_ms: 1720078883999,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 174.15,
                websocket: true,
              },
              {
                close: 174.157,
                high: 174.157,
                lag_ms: 124,
                low: 174.157,
                open: 174.157,
                source: "TwelveData_ws",
                start_ms: 1720078884000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1720078883876,
          },
          {
            leverage: 0.684,
            order_type: "LONG",
            order_uuid: "17b0370a-adf6-44c9-8a35-aafe355cd6d6",
            price: 174.126,
            price_sources: [
              {
                close: 174.126,
                high: 174.126,
                lag_ms: 131,
                low: 174.126,
                open: 174.126,
                source: "Polygon_ws",
                start_ms: 1720078929000,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 174.126,
                websocket: true,
              },
              {
                close: 174.124,
                high: 174.124,
                lag_ms: 131,
                low: 174.124,
                open: 174.124,
                source: "TwelveData_ws",
                start_ms: 1720078929000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1720078929131,
          },
          {
            leverage: 1.2996,
            order_type: "LONG",
            order_uuid: "0b4a0803-5ab1-4109-8d64-2a6cb7173f1d",
            price: 174.088,
            price_sources: [
              {
                close: 174.088,
                high: 174.088,
                lag_ms: 0,
                low: 174.088,
                open: 174.088,
                source: "Polygon_ws",
                start_ms: 1720079144000,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 174.086,
                websocket: true,
              },
              {
                close: 174.082,
                high: 174.082,
                lag_ms: 1000,
                low: 174.082,
                open: 174.082,
                source: "TwelveData_ws",
                start_ms: 1720079143000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1720079144000,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "b55e9caf-8613-4fed-af31-ad11763591a0",
            price: 174.12,
            price_sources: [
              {
                close: 174.12,
                high: 174.12,
                lag_ms: 128,
                low: 174.12,
                open: 174.12,
                source: "Polygon_ws",
                start_ms: 1720079228000,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 174.09,
                websocket: true,
              },
              {
                close: 174.117,
                high: 174.117,
                lag_ms: 128,
                low: 174.117,
                open: 174.117,
                source: "TwelveData_ws",
                start_ms: 1720079228000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1720079228128,
          },
        ],
        position_type: "FLAT",
        position_uuid: "789d61d3-8148-4944-b1e2-ba3688b77209",
        return_at_close: 0.9997664151894574,
        trade_pair: ["EURJPY", "EUR/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 174.08468421052632,
        close_ms: 1720081126689,
        current_return: 1.0001174014787164,
        initial_entry_price: 174.1375,
        is_closed_position: true,
        miner_hotkey: "5D5GDbEa5Aedt9fXhWTzngDTANvnf8Q5poSFKYkU9hEZ7P8C",
        net_leverage: 0.0,
        open_ms: 1720079259277,
        orders: [
          {
            leverage: 0.2,
            order_type: "LONG",
            order_uuid: "86fbcfdf-027f-426d-83a8-4648f131e276",
            price: 174.1375,
            price_sources: [
              {
                close: 174.1375,
                high: 174.1375,
                lag_ms: 440,
                low: 174.1375,
                open: 174.1375,
                source: "Polygon_ws",
                start_ms: 1720079257999,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 174.138,
                websocket: true,
              },
              {
                close: 174.136,
                high: 174.136,
                lag_ms: 441,
                low: 174.136,
                open: 174.136,
                source: "TwelveData_ws",
                start_ms: 1720079258000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1720079257559,
          },
          {
            leverage: 0.18000000000000002,
            order_type: "LONG",
            order_uuid: "fe71d393-786a-4879-afed-564b18c05e2c",
            price: 174.09,
            price_sources: [
              {
                close: 174.09,
                high: 174.09,
                lag_ms: 450,
                low: 174.09,
                open: 174.09,
                source: "Polygon_ws",
                start_ms: 1720079287999,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 174.092,
                websocket: true,
              },
              {
                close: 174.087,
                high: 174.087,
                lag_ms: 451,
                low: 174.087,
                open: 174.087,
                source: "TwelveData_ws",
                start_ms: 1720079288000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1720079287549,
          },
          {
            leverage: 0.342,
            order_type: "LONG",
            order_uuid: "21b6de62-da0f-41f4-9004-2abe1c5e267b",
            price: 174.051,
            price_sources: [
              {
                close: 174.051,
                high: 174.051,
                lag_ms: 110,
                low: 174.051,
                open: 174.051,
                source: "Polygon_ws",
                start_ms: 1720080364999,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 174.048,
                websocket: true,
              },
              {
                close: 174.048,
                high: 174.048,
                lag_ms: 111,
                low: 174.048,
                open: 174.048,
                source: "TwelveData_ws",
                start_ms: 1720080365000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1720080364889,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "0d332eb9-2bfd-4b0d-9d66-77e9b90228e7",
            price: 174.113,
            price_sources: [
              {
                close: 174.113,
                high: 174.113,
                lag_ms: 310,
                low: 174.113,
                open: 174.113,
                source: "Polygon_ws",
                start_ms: 1720081126999,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 174.113,
                websocket: true,
              },
              {
                close: 174.109,
                high: 174.109,
                lag_ms: 311,
                low: 174.109,
                open: 174.109,
                source: "TwelveData_ws",
                start_ms: 1720081127000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1720081126689,
          },
        ],
        position_type: "FLAT",
        position_uuid: "7861db2c-1b23-410c-aeb3-3412b0112343",
        return_at_close: 1.0000668555452457,
        trade_pair: ["EURJPY", "EUR/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 173.9512136610488,
        close_ms: 1720088946927,
        current_return: 1.0005410737960554,
        initial_entry_price: 174.115,
        is_closed_position: true,
        miner_hotkey: "5D5GDbEa5Aedt9fXhWTzngDTANvnf8Q5poSFKYkU9hEZ7P8C",
        net_leverage: 0.0,
        open_ms: 1720081158255,
        orders: [
          {
            leverage: 0.2,
            order_type: "LONG",
            order_uuid: "9bf4c08c-e740-49ca-9b02-e54aceaa3ee4",
            price: 174.115,
            price_sources: [
              {
                close: 174.115,
                high: 174.115,
                lag_ms: 59,
                low: 174.115,
                open: 174.115,
                source: "Polygon_ws",
                start_ms: 1720081156000,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 174.115,
                websocket: true,
              },
              {
                close: 174.117,
                high: 174.117,
                lag_ms: 941,
                low: 174.117,
                open: 174.117,
                source: "TwelveData_ws",
                start_ms: 1720081157000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1720081156059,
          },
          {
            leverage: 0.2,
            order_type: "LONG",
            order_uuid: "4026ec22-4e4d-419b-9ac6-bc9a47bbc73d",
            price: 174.0855,
            price_sources: [
              {
                close: 174.0855,
                high: 174.0855,
                lag_ms: 317,
                low: 174.0855,
                open: 174.0855,
                source: "Polygon_ws",
                start_ms: 1720087436000,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 174.089,
                websocket: true,
              },
              {
                close: 174.087,
                high: 174.087,
                lag_ms: 683,
                low: 174.087,
                open: 174.087,
                source: "TwelveData_ws",
                start_ms: 1720087437000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1720087436317,
          },
          {
            leverage: 0.44000000000000006,
            order_type: "LONG",
            order_uuid: "57094a20-80d2-4c37-b06d-41a08e42698b",
            price: 174.048,
            price_sources: [
              {
                close: 174.048,
                high: 174.048,
                lag_ms: 329,
                low: 174.048,
                open: 174.048,
                source: "Polygon_ws",
                start_ms: 1720087686000,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 174.046,
                websocket: true,
              },
              {
                close: 174.047,
                high: 174.047,
                lag_ms: 329,
                low: 174.047,
                open: 174.047,
                source: "TwelveData_ws",
                start_ms: 1720087686000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1720087686329,
          },
          {
            leverage: 0.7560000000000001,
            order_type: "LONG",
            order_uuid: "bae542ae-1586-4b0e-8a63-d0bc7f0be221",
            price: 174.02100000000002,
            price_sources: [
              {
                close: 174.02100000000002,
                high: 174.02100000000002,
                lag_ms: 21,
                low: 174.02100000000002,
                open: 174.02100000000002,
                source: "Polygon_ws",
                start_ms: 1720088319000,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 174.02,
                websocket: true,
              },
              {
                close: 174.021,
                high: 174.021,
                lag_ms: 1021,
                low: 174.021,
                open: 174.021,
                source: "TwelveData_ws",
                start_ms: 1720088318000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1720088319021,
          },
          {
            leverage: 1.4364000000000001,
            order_type: "LONG",
            order_uuid: "ed88a6f8-e850-4ce9-8c97-63ec4c25f4a3",
            price: 173.9765,
            price_sources: [
              {
                close: 173.9765,
                high: 173.9765,
                lag_ms: 16,
                low: 173.9765,
                open: 173.9765,
                source: "Polygon_ws",
                start_ms: 1720088481000,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 173.97,
                websocket: true,
              },
              {
                close: 173.981,
                high: 173.981,
                lag_ms: 984,
                low: 173.981,
                open: 173.981,
                source: "TwelveData_ws",
                start_ms: 1720088482000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1720088481016,
          },
          {
            leverage: 3.33564,
            order_type: "LONG",
            order_uuid: "605b6281-9baa-458b-8805-926c386ed32d",
            price: 173.9475,
            price_sources: [
              {
                close: 173.9475,
                high: 173.9475,
                lag_ms: 6,
                low: 173.9475,
                open: 173.9475,
                source: "Polygon_ws",
                start_ms: 1720088670999,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 173.96,
                websocket: true,
              },
              {
                close: 173.946,
                high: 173.946,
                lag_ms: 7,
                low: 173.946,
                open: 173.946,
                source: "TwelveData_ws",
                start_ms: 1720088671000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1720088670993,
          },
          {
            leverage: 5.731236000000001,
            order_type: "LONG",
            order_uuid: "bda4b09c-761e-43b5-87f3-7483400818a1",
            price: 173.92000000000002,
            price_sources: [
              {
                close: 173.92000000000002,
                high: 173.92000000000002,
                lag_ms: 21,
                low: 173.92000000000002,
                open: 173.92000000000002,
                source: "Polygon_ws",
                start_ms: 1720088791000,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 173.922,
                websocket: true,
              },
              {
                close: 173.918,
                high: 173.918,
                lag_ms: 1021,
                low: 173.918,
                open: 173.918,
                source: "TwelveData_ws",
                start_ms: 1720088790000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1720088791021,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "a8b44696-312f-47b5-814c-361d53ddacad",
            price: 173.959,
            price_sources: [
              {
                close: 173.959,
                high: 173.959,
                lag_ms: 72,
                low: 173.959,
                open: 173.959,
                source: "Polygon_ws",
                start_ms: 1720088946999,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 173.959,
                websocket: true,
              },
              {
                close: 173.962,
                high: 173.962,
                lag_ms: 73,
                low: 173.962,
                open: 173.962,
                source: "TwelveData_ws",
                start_ms: 1720088947000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1720088946927,
          },
        ],
        position_type: "FLAT",
        position_uuid: "1a181331-80fd-44e5-a995-873718974d73",
        return_at_close: 0.9996936662139718,
        trade_pair: ["EURJPY", "EUR/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 173.88929673180442,
        close_ms: 1720091261724,
        current_return: 0.999774942432273,
        initial_entry_price: 173.969,
        is_closed_position: true,
        miner_hotkey: "5D5GDbEa5Aedt9fXhWTzngDTANvnf8Q5poSFKYkU9hEZ7P8C",
        net_leverage: 0.0,
        open_ms: 1720088976727,
        orders: [
          {
            leverage: 0.2,
            order_type: "LONG",
            order_uuid: "91999493-ca2e-4277-ab2e-c01c0dffdb67",
            price: 173.969,
            price_sources: [
              {
                close: 173.969,
                high: 173.969,
                lag_ms: 297,
                low: 173.969,
                open: 173.969,
                source: "Polygon_ws",
                start_ms: 1720088976999,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 173.96,
                websocket: true,
              },
              {
                close: 173.969,
                high: 173.969,
                lag_ms: 298,
                low: 173.969,
                open: 173.969,
                source: "TwelveData_ws",
                start_ms: 1720088977000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1720088976702,
          },
          {
            leverage: 0.18000000000000002,
            order_type: "LONG",
            order_uuid: "abfc1c03-73c3-4ffc-9a6c-4ec54235d693",
            price: 173.95850000000002,
            price_sources: [
              {
                close: 173.95850000000002,
                high: 173.95850000000002,
                lag_ms: 286,
                low: 173.95850000000002,
                open: 173.95850000000002,
                source: "Polygon_ws",
                start_ms: 1720089020999,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 173.959,
                websocket: true,
              },
              {
                close: 173.959,
                high: 173.959,
                lag_ms: 713,
                low: 173.959,
                open: 173.959,
                source: "TwelveData_ws",
                start_ms: 1720089020000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1720089020713,
          },
          {
            leverage: 0.342,
            order_type: "LONG",
            order_uuid: "6a933aec-f732-41af-ac5c-bf33da1d8884",
            price: 173.892,
            price_sources: [
              {
                close: 173.892,
                high: 173.892,
                lag_ms: 399,
                low: 173.892,
                open: 173.892,
                source: "Polygon_ws",
                start_ms: 1720090936000,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 173.892,
                websocket: true,
              },
              {
                close: 173.894,
                high: 173.894,
                lag_ms: 399,
                low: 173.894,
                open: 173.894,
                source: "TwelveData_ws",
                start_ms: 1720090936000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1720090936399,
          },
          {
            leverage: 0.7942,
            order_type: "LONG",
            order_uuid: "5d942e34-79c9-45fc-8af6-a84e9c6e0928",
            price: 173.9055,
            price_sources: [
              {
                close: 173.9055,
                high: 173.9055,
                lag_ms: 8,
                low: 173.9055,
                open: 173.9055,
                source: "Polygon_ws",
                start_ms: 1720091044000,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 173.88,
                websocket: true,
              },
              {
                close: 173.906,
                high: 173.906,
                lag_ms: 8,
                low: 173.906,
                open: 173.906,
                source: "TwelveData_ws",
                start_ms: 1720091044000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1720091044008,
          },
          {
            leverage: 1.66782,
            order_type: "LONG",
            order_uuid: "abb194f6-758d-4ff2-9ab4-59bd471e9538",
            price: 173.864,
            price_sources: [
              {
                close: 173.864,
                high: 173.864,
                lag_ms: 145,
                low: 173.864,
                open: 173.864,
                source: "Polygon_ws",
                start_ms: 1720091222000,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 173.855,
                websocket: true,
              },
              {
                close: 173.864,
                high: 173.864,
                lag_ms: 1855,
                low: 173.864,
                open: 173.864,
                source: "TwelveData_ws",
                start_ms: 1720091224000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1720091222145,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "0c4164b9-e8a2-4d0a-a926-a5c60b814fbb",
            price: 173.877,
            price_sources: [
              {
                close: 173.877,
                high: 173.877,
                lag_ms: 275,
                low: 173.877,
                open: 173.877,
                source: "Polygon_ws",
                start_ms: 1720091261999,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 173.877,
                websocket: true,
              },
              {
                close: 173.878,
                high: 173.878,
                lag_ms: 276,
                low: 173.878,
                open: 173.878,
                source: "TwelveData_ws",
                start_ms: 1720091262000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1720091261724,
          },
        ],
        position_type: "FLAT",
        position_uuid: "f82b4e3c-40bc-4a96-acb1-0fe3e8f1a690",
        return_at_close: 0.9995521111934188,
        trade_pair: ["EURJPY", "EUR/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 173.83592481203007,
        close_ms: 1720091956053,
        current_return: 1.0002275085112255,
        initial_entry_price: 173.888,
        is_closed_position: true,
        miner_hotkey: "5D5GDbEa5Aedt9fXhWTzngDTANvnf8Q5poSFKYkU9hEZ7P8C",
        net_leverage: 0.0,
        open_ms: 1720091291747,
        orders: [
          {
            leverage: 0.2,
            order_type: "LONG",
            order_uuid: "3c3eb17d-0508-4362-bd71-7406017e5579",
            price: 173.888,
            price_sources: [
              {
                close: 173.888,
                high: 173.888,
                lag_ms: 281,
                low: 173.888,
                open: 173.888,
                source: "Polygon_ws",
                start_ms: 1720091291999,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 173.894,
                websocket: true,
              },
              {
                close: 173.891,
                high: 173.891,
                lag_ms: 282,
                low: 173.891,
                open: 173.891,
                source: "TwelveData_ws",
                start_ms: 1720091292000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1720091291718,
          },
          {
            leverage: 0.22000000000000003,
            order_type: "LONG",
            order_uuid: "e91bdac1-a4df-4630-bd97-fabfc065370f",
            price: 173.84,
            price_sources: [
              {
                close: 173.84,
                high: 173.84,
                lag_ms: 215,
                low: 173.84,
                open: 173.84,
                source: "Polygon_ws",
                start_ms: 1720091604000,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 173.84,
                websocket: true,
              },
              {
                close: 173.873,
                high: 173.873,
                lag_ms: 1785,
                low: 173.873,
                open: 173.873,
                source: "TwelveData_ws",
                start_ms: 1720091606000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1720091604215,
          },
          {
            leverage: 0.37800000000000006,
            order_type: "LONG",
            order_uuid: "8a143f13-6d41-43c5-8648-1eae68f62046",
            price: 173.806,
            price_sources: [
              {
                close: 173.806,
                high: 173.806,
                lag_ms: 99,
                low: 173.806,
                open: 173.806,
                source: "Polygon_ws",
                start_ms: 1720091724000,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 173.78,
                websocket: true,
              },
              {
                close: 173.807,
                high: 173.807,
                lag_ms: 1099,
                low: 173.807,
                open: 173.807,
                source: "TwelveData_ws",
                start_ms: 1720091723000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1720091724099,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "bb2a2ed0-e145-4e95-8209-89e0d6ee6201",
            price: 173.88549999999998,
            price_sources: [
              {
                close: 173.88549999999998,
                high: 173.88549999999998,
                lag_ms: 53,
                low: 173.88549999999998,
                open: 173.88549999999998,
                source: "Polygon_ws",
                start_ms: 1720091956000,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 173.887,
                websocket: true,
              },
              {
                close: 173.879,
                high: 173.879,
                lag_ms: 53,
                low: 173.879,
                open: 173.879,
                source: "TwelveData_ws",
                start_ms: 1720091956000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1720091956053,
          },
        ],
        position_type: "FLAT",
        position_uuid: "becf55dc-41f4-44a7-a7d4-2022a4b00d5a",
        return_at_close: 1.0001716358026,
        trade_pair: ["EURJPY", "EUR/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 3495.9,
        close_ms: 0,
        current_return: 0.9948661288938471,
        initial_entry_price: 3495.9,
        is_closed_position: false,
        miner_hotkey: "5D5GDbEa5Aedt9fXhWTzngDTANvnf8Q5poSFKYkU9hEZ7P8C",
        net_leverage: 0.05,
        open_ms: 1719065106752,
        orders: [
          {
            leverage: 0.05,
            order_type: "LONG",
            order_uuid: "a27b9aeb-f9aa-4956-ba58-6683c38d6755",
            price: 3495.9,
            price_sources: [],
            processed_ms: 1719065105036,
          },
        ],
        position_type: "LONG",
        position_uuid: "50800cf5-8eb5-45dd-bb4c-e9c4db50176e",
        return_at_close: 0.9948412572406247,
        trade_pair: ["ETHUSD", "ETH/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 173.8857894736842,
        close_ms: 0,
        current_return: 1.0024907120921085,
        initial_entry_price: 173.882,
        is_closed_position: false,
        miner_hotkey: "5D5GDbEa5Aedt9fXhWTzngDTANvnf8Q5poSFKYkU9hEZ7P8C",
        net_leverage: 0.38,
        open_ms: 1720091985911,
        orders: [
          {
            leverage: 0.2,
            order_type: "LONG",
            order_uuid: "05e47762-261b-4626-9442-3bc491db7ec6",
            price: 173.882,
            price_sources: [
              {
                close: 173.882,
                high: 173.882,
                lag_ms: 112,
                low: 173.882,
                open: 173.882,
                source: "Polygon_ws",
                start_ms: 1720091985999,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 173.878,
                websocket: true,
              },
              {
                close: 173.887,
                high: 173.887,
                lag_ms: 113,
                low: 173.887,
                open: 173.887,
                source: "TwelveData_ws",
                start_ms: 1720091986000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1720091985887,
          },
          {
            leverage: 0.18000000000000002,
            order_type: "LONG",
            order_uuid: "b2388f6c-d44b-40ca-b18f-b8c1f60190fd",
            price: 173.89,
            price_sources: [
              {
                close: 173.89,
                high: 173.89,
                lag_ms: 238,
                low: 173.89,
                open: 173.89,
                source: "Polygon_ws",
                start_ms: 1720093181999,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 173.89,
                websocket: true,
              },
              {
                close: 173.893,
                high: 173.893,
                lag_ms: 761,
                low: 173.893,
                open: 173.893,
                source: "TwelveData_ws",
                start_ms: 1720093181000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1720093181761,
          },
        ],
        position_type: "LONG",
        position_uuid: "6c0bdd93-881e-4b3f-8614-1f1f4baff270",
        return_at_close: 1.0024773789656376,
        trade_pair: ["EURJPY", "EUR/JPY", 7e-5, 0.001, 500],
      },
    ],
    thirty_day_returns: 0.9942132617813371,
  },
  "5D7irf6EGydsUU3sP2zxC4CHR3iZCVNeMCFVBFEGYDLXPVsR": {
    all_time_returns: 1.0,
    n_positions: 0,
    percentage_profitable: 0.0,
    positions: [
      {
        average_entry_price: 64238.11000000001,
        close_ms: 1711250607214,
        current_return: 0.9998209474095673,
        initial_entry_price: 64238.11,
        is_closed_position: true,
        miner_hotkey: "5D7irf6EGydsUU3sP2zxC4CHR3iZCVNeMCFVBFEGYDLXPVsR",
        net_leverage: 0.0,
        open_ms: 1711250586364,
        orders: [
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "6e0bb036-1865-425f-bc56-636ff9dcdfca",
            price: 64238.11,
            price_sources: [],
            processed_ms: 1711250586363,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "d51e6bef-8244-4086-9320-d67ffc8d58d7",
            price: 64199.77,
            price_sources: [],
            processed_ms: 1711250607214,
          },
        ],
        position_type: "FLAT",
        position_uuid: "38132e91-3706-4f28-96f4-fe7459130430",
        return_at_close: 0.9992210548411214,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 63923.69999999999,
        close_ms: 1711259570546,
        current_return: 1.0020747234593743,
        initial_entry_price: 63923.7,
        is_closed_position: true,
        miner_hotkey: "5D7irf6EGydsUU3sP2zxC4CHR3iZCVNeMCFVBFEGYDLXPVsR",
        net_leverage: 0.0,
        open_ms: 1711254293693,
        orders: [
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "3198e0d6-d079-4a85-8123-094c3b712e33",
            price: 63923.7,
            price_sources: [],
            processed_ms: 1711254293692,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "c26a8cd8-5130-4e4d-af73-a09caee3677b",
            price: 64365.78,
            price_sources: [],
            processed_ms: 1711259570546,
          },
        ],
        position_type: "FLAT",
        position_uuid: "34ded87e-59ed-4411-8e00-259c23752929",
        return_at_close: 1.0014734786252986,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 64365.66000000001,
        close_ms: 1711263229112,
        current_return: 0.9961037065416557,
        initial_entry_price: 64365.66,
        is_closed_position: true,
        miner_hotkey: "5D7irf6EGydsUU3sP2zxC4CHR3iZCVNeMCFVBFEGYDLXPVsR",
        net_leverage: 0.0,
        open_ms: 1711259657701,
        orders: [
          {
            leverage: 1.25,
            order_type: "LONG",
            order_uuid: "5e4f0a07-a6e8-48cc-bab5-c687663a650a",
            price: 64365.66,
            price_sources: [],
            processed_ms: 1711259657700,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "0ad05696-9136-40a4-a3f4-587af095386d",
            price: 64165.03,
            price_sources: [],
            processed_ms: 1711263229112,
          },
        ],
        position_type: "FLAT",
        position_uuid: "55136dc7-1294-495e-9c1e-6c83eb3a1abd",
        return_at_close: 0.9936134472753017,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 64194.91000000001,
        close_ms: 1711266887672,
        current_return: 1.0035067032573142,
        initial_entry_price: 64194.91,
        is_closed_position: true,
        miner_hotkey: "5D7irf6EGydsUU3sP2zxC4CHR3iZCVNeMCFVBFEGYDLXPVsR",
        net_leverage: 0.0,
        open_ms: 1711263365497,
        orders: [
          {
            leverage: 1.25,
            order_type: "LONG",
            order_uuid: "a4096c6b-6374-420b-bd83-90fa9b00df6b",
            price: 64194.91,
            price_sources: [],
            processed_ms: 1711263365497,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "add82de3-1fca-4e53-8736-d296fdc5962c",
            price: 64375.0,
            price_sources: [],
            processed_ms: 1711266887672,
          },
        ],
        position_type: "FLAT",
        position_uuid: "c5814d54-a565-44e9-9f7f-1f2d2f89d628",
        return_at_close: 1.000997936499171,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 64328.04,
        close_ms: 1711270571523,
        current_return: 1.0142954767469987,
        initial_entry_price: 64328.04,
        is_closed_position: true,
        miner_hotkey: "5D7irf6EGydsUU3sP2zxC4CHR3iZCVNeMCFVBFEGYDLXPVsR",
        net_leverage: 0.0,
        open_ms: 1711267001546,
        orders: [
          {
            leverage: 1.25,
            order_type: "LONG",
            order_uuid: "e7bfd0b3-5902-4ab0-81ba-1cdd1a45d44b",
            price: 64328.04,
            price_sources: [],
            processed_ms: 1711267001545,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "7b97839d-5e18-4740-b2dc-fc9409702bfd",
            price: 65063.72,
            price_sources: [],
            processed_ms: 1711270571523,
          },
        ],
        position_type: "FLAT",
        position_uuid: "3dc5ecbe-aa59-42e4-a341-7833ef28e2b0",
        return_at_close: 1.0117597380551313,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 64888.39,
        close_ms: 1711277875750,
        current_return: 1.0052322534123592,
        initial_entry_price: 64888.39,
        is_closed_position: true,
        miner_hotkey: "5D7irf6EGydsUU3sP2zxC4CHR3iZCVNeMCFVBFEGYDLXPVsR",
        net_leverage: 0.0,
        open_ms: 1711274364673,
        orders: [
          {
            leverage: 1.25,
            order_type: "LONG",
            order_uuid: "3d06f06d-c4e5-48de-a4b5-912ad472799c",
            price: 64888.39,
            price_sources: [],
            processed_ms: 1711274364672,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "5223ceaa-24ce-4180-8732-0ddb833f0ea5",
            price: 65160.0,
            price_sources: [],
            processed_ms: 1711277875750,
          },
        ],
        position_type: "FLAT",
        position_uuid: "6c71c551-b613-4d2b-b1a6-24b916f21dc5",
        return_at_close: 1.0027191727788283,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 65106.45,
        close_ms: 1711283426695,
        current_return: 1.0074746818479583,
        initial_entry_price: 65106.45,
        is_closed_position: true,
        miner_hotkey: "5D7irf6EGydsUU3sP2zxC4CHR3iZCVNeMCFVBFEGYDLXPVsR",
        net_leverage: 0.0,
        open_ms: 1711277964216,
        orders: [
          {
            leverage: 1.25,
            order_type: "LONG",
            order_uuid: "5a5c3f5f-1aa4-46c4-8e0f-3929f6fc04b4",
            price: 65106.45,
            price_sources: [],
            processed_ms: 1711277964214,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "e5ad3e80-ae31-4b68-9202-c257529885c2",
            price: 65495.77,
            price_sources: [],
            processed_ms: 1711283426695,
          },
        ],
        position_type: "FLAT",
        position_uuid: "8405de05-90cd-4333-8e2e-a0839e8c9981",
        return_at_close: 1.0049559951433384,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 65706.06,
        close_ms: 1711309529774,
        current_return: 1.002701805586882,
        initial_entry_price: 65706.06,
        is_closed_position: true,
        miner_hotkey: "5D7irf6EGydsUU3sP2zxC4CHR3iZCVNeMCFVBFEGYDLXPVsR",
        net_leverage: 0.0,
        open_ms: 1711308637293,
        orders: [
          {
            leverage: 1.25,
            order_type: "LONG",
            order_uuid: "c804b545-1ca6-41c9-9352-f637e17ad6e1",
            price: 65706.06,
            price_sources: [],
            processed_ms: 1711308637292,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "2403364c-7b70-4269-a9ea-44cd93054538",
            price: 65848.08,
            price_sources: [],
            processed_ms: 1711309529774,
          },
        ],
        position_type: "FLAT",
        position_uuid: "08c3bbb7-8da6-4483-ac8e-59201d7f3fc8",
        return_at_close: 1.0001950510729147,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 66899.51,
        close_ms: 1711326359119,
        current_return: 1.0010371899584916,
        initial_entry_price: 66899.51,
        is_closed_position: true,
        miner_hotkey: "5D7irf6EGydsUU3sP2zxC4CHR3iZCVNeMCFVBFEGYDLXPVsR",
        net_leverage: 0.0,
        open_ms: 1711325391778,
        orders: [
          {
            leverage: 1.25,
            order_type: "LONG",
            order_uuid: "30f6cc3b-39a9-47d3-8a39-c2504c2fd760",
            price: 66899.51,
            price_sources: [],
            processed_ms: 1711325391777,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "f1519edc-751d-49cd-aa54-dff50b15815b",
            price: 66955.02,
            price_sources: [],
            processed_ms: 1711326359119,
          },
        ],
        position_type: "FLAT",
        position_uuid: "b96d0971-ae55-4a19-b416-e5d90e36de18",
        return_at_close: 0.9985345969835954,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 66631.13,
        close_ms: 1711328195861,
        current_return: 0.9986080079986634,
        initial_entry_price: 66631.13,
        is_closed_position: true,
        miner_hotkey: "5D7irf6EGydsUU3sP2zxC4CHR3iZCVNeMCFVBFEGYDLXPVsR",
        net_leverage: 0.0,
        open_ms: 1711327267133,
        orders: [
          {
            leverage: 1.25,
            order_type: "LONG",
            order_uuid: "846e7ceb-ac0b-4360-9ae4-393298a828dd",
            price: 66631.13,
            price_sources: [],
            processed_ms: 1711327267132,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "0e61b927-4ddb-4a08-9db2-cfc5601f528d",
            price: 66556.93,
            price_sources: [],
            processed_ms: 1711328195861,
          },
        ],
        position_type: "FLAT",
        position_uuid: "61232d76-e3f5-44bb-bb64-76ea96a85407",
        return_at_close: 0.9961114879786669,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 67100.02,
        close_ms: 1711342115921,
        current_return: 1.0052611757790832,
        initial_entry_price: 67100.02,
        is_closed_position: true,
        miner_hotkey: "5D7irf6EGydsUU3sP2zxC4CHR3iZCVNeMCFVBFEGYDLXPVsR",
        net_leverage: 0.0,
        open_ms: 1711340317123,
        orders: [
          {
            leverage: 1.25,
            order_type: "LONG",
            order_uuid: "13c1655e-9f31-461d-9246-09aea556941f",
            price: 67100.02,
            price_sources: [],
            processed_ms: 1711340316758,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "e9c1957a-1d6f-4b85-b0fb-a51a19d3f97e",
            price: 67382.44,
            price_sources: [],
            processed_ms: 1711342115921,
          },
        ],
        position_type: "FLAT",
        position_uuid: "d84aeddd-edca-4da6-9ec9-b08779526db9",
        return_at_close: 1.0027480228396355,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 66933.19,
        close_ms: 1711354932462,
        current_return: 1.001164220620592,
        initial_entry_price: 66933.19,
        is_closed_position: true,
        miner_hotkey: "5D7irf6EGydsUU3sP2zxC4CHR3iZCVNeMCFVBFEGYDLXPVsR",
        net_leverage: 0.0,
        open_ms: 1711353098045,
        orders: [
          {
            leverage: 1.25,
            order_type: "LONG",
            order_uuid: "58a4f366-533c-49cf-a04d-2602aa649d36",
            price: 66933.19,
            price_sources: [],
            processed_ms: 1711353098044,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "8cccde18-f6fc-4a52-b19e-bf6aff72b593",
            price: 66995.53,
            price_sources: [],
            processed_ms: 1711354932462,
          },
        ],
        position_type: "FLAT",
        position_uuid: "cdd1705e-8576-4fbf-9ea9-ed0a5ab51004",
        return_at_close: 0.9986613100690406,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 67220.6,
        close_ms: 1711364087017,
        current_return: 0.9961092656120295,
        initial_entry_price: 67220.6,
        is_closed_position: true,
        miner_hotkey: "5D7irf6EGydsUU3sP2zxC4CHR3iZCVNeMCFVBFEGYDLXPVsR",
        net_leverage: 0.0,
        open_ms: 1711362260125,
        orders: [
          {
            leverage: 1.25,
            order_type: "LONG",
            order_uuid: "3b0152d0-56f8-4cda-818c-530298f33bba",
            price: 67220.6,
            price_sources: [],
            processed_ms: 1711362260124,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "f7daf634-961b-4a16-bdc7-27411f56ceb8",
            price: 67011.37,
            price_sources: [],
            processed_ms: 1711364087017,
          },
        ],
        position_type: "FLAT",
        position_uuid: "6aca1c55-7fa5-4169-b319-8b491601ae8b",
        return_at_close: 0.9936189924479994,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: -1321313.5868965345,
        close_ms: 1711382488080,
        current_return: 1.0482945072306904,
        initial_entry_price: 66828.33,
        is_closed_position: true,
        miner_hotkey: "5D7irf6EGydsUU3sP2zxC4CHR3iZCVNeMCFVBFEGYDLXPVsR",
        net_leverage: 0.0,
        open_ms: 1711368048404,
        orders: [
          {
            leverage: 1.25,
            order_type: "LONG",
            order_uuid: "b8dd11ec-c4a0-48b3-a451-4293fe7d436f",
            price: 66828.33,
            price_sources: [],
            processed_ms: 1711368048403,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "ca9812b9-bc91-4f6d-ae9f-fd49eda4ccba",
            price: 67159.91,
            price_sources: [],
            processed_ms: 1711369882270,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "fdd248f7-bcb4-430c-a792-b95ee59a922c",
            price: 67127.19,
            price_sources: [],
            processed_ms: 1711371713134,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "97e3ff1e-aa98-46df-a00e-e2759cec184e",
            price: 67188.75,
            price_sources: [],
            processed_ms: 1711373544348,
          },
          {
            leverage: -1.0,
            order_type: "SHORT",
            order_uuid: "773c5ea3-6be1-4f57-abb2-375fdccd6994",
            price: 68187.31,
            price_sources: [],
            processed_ms: 1711375371353,
          },
          {
            leverage: -0.8,
            order_type: "SHORT",
            order_uuid: "2f0a1034-ac83-463a-9e77-1467cf14ccff",
            price: 68868.54,
            price_sources: [],
            processed_ms: 1711376452447,
          },
          {
            leverage: -0.15999999999999998,
            order_type: "SHORT",
            order_uuid: "4f6836a6-d177-4aea-8314-f99eded135ef",
            price: 69210.38,
            price_sources: [],
            processed_ms: 1711376767796,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "529d820c-3f54-4ed9-ab3b-4ada21a5e59b",
            price: 69095.56,
            price_sources: [],
            processed_ms: 1711377940480,
          },
          {
            leverage: -0.23199999999999998,
            order_type: "SHORT",
            order_uuid: "d58eab8e-415c-4786-bf16-408054fee8a0",
            price: 69061.84,
            price_sources: [],
            processed_ms: 1711378216989,
          },
          {
            leverage: -0.0464,
            order_type: "SHORT",
            order_uuid: "8b2c0169-12a6-432c-b5da-d02de56deb98",
            price: 69404.06,
            price_sources: [],
            processed_ms: 1711378859227,
          },
          {
            leverage: -0.00928,
            order_type: "SHORT",
            order_uuid: "bc699246-6203-43d5-a7ee-f4ffbcd66d6e",
            price: 69527.42,
            price_sources: [],
            processed_ms: 1711380670091,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "9f4e2799-9e9c-46e2-894a-5fce1e8a5051",
            price: 69824.89,
            price_sources: [],
            processed_ms: 1711382488080,
          },
        ],
        position_type: "FLAT",
        position_uuid: "4f8c2a23-01ad-40b2-9fe4-f93e894fe013",
        return_at_close: 1.0441013292017676,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 67433.86419354832,
        close_ms: 1711404288821,
        current_return: 0.9954114914156774,
        initial_entry_price: 70265.01,
        is_closed_position: true,
        miner_hotkey: "5D7irf6EGydsUU3sP2zxC4CHR3iZCVNeMCFVBFEGYDLXPVsR",
        net_leverage: 0.0,
        open_ms: 1711386111249,
        orders: [
          {
            leverage: -1.25,
            order_type: "SHORT",
            order_uuid: "b38f4abc-4a83-4585-8d07-bd4f0de23ebc",
            price: 70265.01,
            price_sources: [],
            processed_ms: 1711386111247,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "2e6628ed-f515-45fb-ae2f-6ce558caee7a",
            price: 70725.62,
            price_sources: [],
            processed_ms: 1711387928773,
          },
          {
            leverage: 0.5,
            order_type: "LONG",
            order_uuid: "f905cc96-3eb3-4dcf-85cc-8aebb77e9eb1",
            price: 70606.1,
            price_sources: [],
            processed_ms: 1711389747018,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "53967fe3-71f3-4956-a748-c7848bb61e86",
            price: 70572.58,
            price_sources: [],
            processed_ms: 1711391597527,
          },
          {
            leverage: 0.125,
            order_type: "LONG",
            order_uuid: "933f2573-fb2f-4603-99cb-65fb9e0db096",
            price: 70834.49,
            price_sources: [],
            processed_ms: 1711393387955,
          },
          {
            leverage: -0.0625,
            order_type: "SHORT",
            order_uuid: "8d9e1445-db4d-493e-b503-5426e4e1741f",
            price: 70688.24,
            price_sources: [],
            processed_ms: 1711395203994,
          },
          {
            leverage: -0.03125,
            order_type: "SHORT",
            order_uuid: "e87ed527-46b7-4100-b395-db0da9152176",
            price: 70970.07,
            price_sources: [],
            processed_ms: 1711397013544,
          },
          {
            leverage: -0.015625,
            order_type: "SHORT",
            order_uuid: "ef6ffb7a-3c27-4c86-b4cd-ffd84dadc200",
            price: 70782.84,
            price_sources: [],
            processed_ms: 1711398836272,
          },
          {
            leverage: -0.0078125,
            order_type: "SHORT",
            order_uuid: "72a6f5e3-b2e3-4b1b-98bd-536aad978edc",
            price: 70788.4,
            price_sources: [],
            processed_ms: 1711400678634,
          },
          {
            leverage: 0.12109375,
            order_type: "LONG",
            order_uuid: "83d12dbc-f37f-4ff2-9241-d72943d62fd8",
            price: 70014.99,
            price_sources: [],
            processed_ms: 1711402535474,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "e37af61a-6cd5-4eec-8adb-72648a5c9e70",
            price: 70096.36,
            price_sources: [],
            processed_ms: 1711404288821,
          },
        ],
        position_type: "FLAT",
        position_uuid: "80e2036c-35e9-4d64-98da-b16ed8ec6d7f",
        return_at_close: 0.9929229626871383,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 72196.21451612898,
        close_ms: 1711411549992,
        current_return: 0.99792196322575,
        initial_entry_price: 70415.95,
        is_closed_position: true,
        miner_hotkey: "5D7irf6EGydsUU3sP2zxC4CHR3iZCVNeMCFVBFEGYDLXPVsR",
        net_leverage: 0.0,
        open_ms: 1711406105599,
        orders: [
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "e0f8cb59-908e-408d-82f9-40b3662562e7",
            price: 70415.95,
            price_sources: [],
            processed_ms: 1711406105596,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "49fb030c-3e29-4abf-83e2-4daafa03b4e6",
            price: 70309.85,
            price_sources: [],
            processed_ms: 1711407924009,
          },
          {
            leverage: -0.439453125,
            order_type: "SHORT",
            order_uuid: "acd8764d-74a5-49d0-a99f-6fc9e1d8e55b",
            price: 70110.31,
            price_sources: [],
            processed_ms: 1711409753753,
          },
          {
            leverage: -0.2197265625,
            order_type: "SHORT",
            order_uuid: "6d29ac09-4398-4390-9349-c74a27b90bc0",
            price: 69779.46,
            price_sources: [],
            processed_ms: 1711411549992,
          },
        ],
        position_type: "FLAT",
        position_uuid: "42e042e3-be91-43c3-8d15-0b59efa2c6f6",
        return_at_close: 0.9969240412625242,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 70156.8780952381,
        close_ms: 1711424265917,
        current_return: 0.9991741079365262,
        initial_entry_price: 70184.55,
        is_closed_position: true,
        miner_hotkey: "5D7irf6EGydsUU3sP2zxC4CHR3iZCVNeMCFVBFEGYDLXPVsR",
        net_leverage: 0.0,
        open_ms: 1711413370957,
        orders: [
          {
            leverage: -0.10986328125,
            order_type: "SHORT",
            order_uuid: "ab785820-984c-4997-aeb4-3e4916224a8d",
            price: 70184.55,
            price_sources: [],
            processed_ms: 1711413370955,
          },
          {
            leverage: -0.054931640625,
            order_type: "SHORT",
            order_uuid: "8a0b0a32-2342-4264-b991-551f2a9b2366",
            price: 70042.6,
            price_sources: [],
            processed_ms: 1711415181397,
          },
          {
            leverage: -0.0274658203125,
            order_type: "SHORT",
            order_uuid: "e6edd56a-4c29-47e3-aa72-72834ecfe192",
            price: 70248.52,
            price_sources: [],
            processed_ms: 1711417038954,
          },
          {
            leverage: -0.01373291015625,
            order_type: "SHORT",
            order_uuid: "461129c3-e699-4e53-abaf-5e86c17c263d",
            price: 70104.99,
            price_sources: [],
            processed_ms: 1711418820067,
          },
          {
            leverage: -0.006866455078125,
            order_type: "SHORT",
            order_uuid: "d77b6986-9dff-4cfe-9ffa-f4e5de9e8d8f",
            price: 70245.35,
            price_sources: [],
            processed_ms: 1711420638972,
          },
          {
            leverage: -0.0034332275390625,
            order_type: "SHORT",
            order_uuid: "e549b349-35c2-4062-bfeb-a90cc2450f04",
            price: 70397.3,
            price_sources: [],
            processed_ms: 1711422457619,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "159c04b6-5a72-4923-95ea-72660e64ae9d",
            price: 70424.87,
            price_sources: [],
            processed_ms: 1711424265917,
          },
        ],
        position_type: "FLAT",
        position_uuid: "5acb523e-5652-47c5-bc46-b91184cc9935",
        return_at_close: 0.9987418785365018,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 70624.45285714285,
        close_ms: 1711431534938,
        current_return: 1.0009435598746868,
        initial_entry_price: 70675.45,
        is_closed_position: true,
        miner_hotkey: "5D7irf6EGydsUU3sP2zxC4CHR3iZCVNeMCFVBFEGYDLXPVsR",
        net_leverage: 0.0,
        open_ms: 1711426091966,
        orders: [
          {
            leverage: -0.12671661376953125,
            order_type: "SHORT",
            order_uuid: "fbcbf4bc-c95b-4b80-859b-8ceffa4e8b3d",
            price: 70675.45,
            price_sources: [],
            processed_ms: 1711426091965,
          },
          {
            leverage: -0.06335830688476562,
            order_type: "SHORT",
            order_uuid: "efd52d30-1eec-4398-9e53-7216d049d00d",
            price: 70595.47,
            price_sources: [],
            processed_ms: 1711427903307,
          },
          {
            leverage: -0.03167915344238281,
            order_type: "SHORT",
            order_uuid: "f48e372d-b6fd-400e-862e-8dd491c92c73",
            price: 70478.43,
            price_sources: [],
            processed_ms: 1711429715354,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "3ba46a27-4809-45f0-ba0a-7b450ff7f51c",
            price: 70323.73,
            price_sources: [],
            processed_ms: 1711431534938,
          },
        ],
        position_type: "FLAT",
        position_uuid: "a98ebe5d-484e-49a3-857a-59c43bd3f08b",
        return_at_close: 1.0004996332500007,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 69110.56920952622,
        close_ms: 1711438803966,
        current_return: 1.0032166872180974,
        initial_entry_price: 70408.05,
        is_closed_position: true,
        miner_hotkey: "5D7irf6EGydsUU3sP2zxC4CHR3iZCVNeMCFVBFEGYDLXPVsR",
        net_leverage: 0.0,
        open_ms: 1711433353252,
        orders: [
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "9d33a023-2dba-4881-8bbf-808d21bf81cd",
            price: 70408.05,
            price_sources: [],
            processed_ms: 1711433353250,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "031631f3-4925-45fb-ac74-8669b4cc90f1",
            price: 70463.92,
            price_sources: [],
            processed_ms: 1711435166192,
          },
          {
            leverage: -0.3908395767211914,
            order_type: "SHORT",
            order_uuid: "61d6f97f-20bd-489b-87ea-790169b363f3",
            price: 70806.17,
            price_sources: [],
            processed_ms: 1711436981123,
          },
          {
            leverage: -0.1954197883605957,
            order_type: "SHORT",
            order_uuid: "c5960224-c954-40aa-bad3-f25252eed2ff",
            price: 71185.32,
            price_sources: [],
            processed_ms: 1711438803966,
          },
        ],
        position_type: "FLAT",
        position_uuid: "1d15f2a0-b581-4061-9b7b-4b38774f61f3",
        return_at_close: 1.0022134705308794,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 70703.13992125986,
        close_ms: 1711453376183,
        current_return: 1.0000164479321365,
        initial_entry_price: 70728.84,
        is_closed_position: true,
        miner_hotkey: "5D7irf6EGydsUU3sP2zxC4CHR3iZCVNeMCFVBFEGYDLXPVsR",
        net_leverage: 0.0,
        open_ms: 1711440627418,
        orders: [
          {
            leverage: -0.09770989418029785,
            order_type: "SHORT",
            order_uuid: "aecc3da8-cd55-4f34-8249-79c3757c10f0",
            price: 70728.84,
            price_sources: [],
            processed_ms: 1711440627416,
          },
          {
            leverage: -0.048854947090148926,
            order_type: "SHORT",
            order_uuid: "0e0ee385-3a7a-478b-bae0-77a1eaa4c14e",
            price: 70526.82,
            price_sources: [],
            processed_ms: 1711442496982,
          },
          {
            leverage: -0.024427473545074463,
            order_type: "SHORT",
            order_uuid: "48fa271f-3337-49a2-9d8b-e722697c5f07",
            price: 70674.64,
            price_sources: [],
            processed_ms: 1711444275152,
          },
          {
            leverage: -0.012213736772537231,
            order_type: "SHORT",
            order_uuid: "bbf4efcc-4615-4c65-a249-3af34e8f3f52",
            price: 70989.82,
            price_sources: [],
            processed_ms: 1711446078989,
          },
          {
            leverage: -0.006106868386268616,
            order_type: "SHORT",
            order_uuid: "eafd96bc-9b9c-4217-ab5f-274ec79d10bb",
            price: 71096.18,
            price_sources: [],
            processed_ms: 1711447897906,
          },
          {
            leverage: -0.003053434193134308,
            order_type: "SHORT",
            order_uuid: "aafa026d-6cd9-4568-b582-6677082ea4ea",
            price: 70953.63,
            price_sources: [],
            processed_ms: 1711449713107,
          },
          {
            leverage: -0.001526717096567154,
            order_type: "SHORT",
            order_uuid: "a8dbac73-e277-4692-a55e-4c5abdf8c018",
            price: 70789.99,
            price_sources: [],
            processed_ms: 1711451529389,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "aafb71d1-9210-4854-8582-27a2a9dcce37",
            price: 70697.14,
            price_sources: [],
            processed_ms: 1711453376183,
          },
        ],
        position_type: "FLAT",
        position_uuid: "27316acf-6a4f-4a93-b50a-8626d8a2ec3c",
        return_at_close: 0.9996286554113283,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 25055.359844886934,
        close_ms: 1711556331775,
        current_return: 1.0283994091310094,
        initial_entry_price: 70909.08,
        is_closed_position: true,
        miner_hotkey: "5D7irf6EGydsUU3sP2zxC4CHR3iZCVNeMCFVBFEGYDLXPVsR",
        net_leverage: 0.0,
        open_ms: 1711458800028,
        orders: [
          {
            leverage: 1.25,
            order_type: "LONG",
            order_uuid: "c1fce3b6-b75e-44dc-9729-8e7258504b01",
            price: 70909.08,
            price_sources: [],
            processed_ms: 1711458800026,
          },
          {
            leverage: -0.625,
            order_type: "SHORT",
            order_uuid: "2ad1af70-60ea-485d-bb17-332ccb56c000",
            price: 70353.17,
            price_sources: [],
            processed_ms: 1711460610027,
          },
          {
            leverage: -0.3125,
            order_type: "SHORT",
            order_uuid: "879edee5-ac8d-4dbf-bfc7-a6e8960c2ba7",
            price: 70406.67,
            price_sources: [],
            processed_ms: 1711462427101,
          },
          {
            leverage: -0.15625,
            order_type: "SHORT",
            order_uuid: "16371692-d00f-4ae5-95b9-f690bf437839",
            price: 69871.12,
            price_sources: [],
            processed_ms: 1711464241060,
          },
          {
            leverage: -0.078125,
            order_type: "SHORT",
            order_uuid: "a4de7f46-d674-4f3b-b42a-8549f111ad71",
            price: 70077.4,
            price_sources: [],
            processed_ms: 1711466062528,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "89d651fc-e6b7-466a-bf9b-dd1023f9aec8",
            price: 70252.89,
            price_sources: [],
            processed_ms: 1711467877113,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "cb98bdf3-c65d-4da7-b762-c7f2f7fdef18",
            price: 70073.59,
            price_sources: [],
            processed_ms: 1711469692330,
          },
          {
            leverage: -0.2890625,
            order_type: "SHORT",
            order_uuid: "0764ec45-e80e-43a6-9c83-5e12bff37da7",
            price: 69459.44,
            price_sources: [],
            processed_ms: 1711471510043,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "ad8c0950-c765-47b2-9d9b-2474a194fd0f",
            price: 69665.33,
            price_sources: [],
            processed_ms: 1711473328221,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "227c3fb9-c880-4085-9798-cb27e4c656bd",
            price: 70141.65,
            price_sources: [],
            processed_ms: 1711475143261,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "a7fa38ed-3c58-412c-ac37-98c7e1bd80b1",
            price: 70237.2,
            price_sources: [],
            processed_ms: 1711476957165,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "23833e21-c2de-4e99-bf4a-c2cd18d7b0b9",
            price: 70301.92,
            price_sources: [],
            processed_ms: 1711478773797,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "ee3a388a-d14e-4bcc-ab3b-d2488a9b4608",
            price: 69806.83,
            price_sources: [],
            processed_ms: 1711480612782,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "7a758380-2f7a-4cb5-bc7c-cc65f9e76f80",
            price: 69787.63,
            price_sources: [],
            processed_ms: 1711482432621,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "df7fcb55-e060-46fa-97f6-d6fd67205929",
            price: 69621.42,
            price_sources: [],
            processed_ms: 1711484228797,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "330ddcc1-0500-4a49-8571-29ca4667a9e7",
            price: 69748.81,
            price_sources: [],
            processed_ms: 1711486036908,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "b220ea3a-a209-4915-bec6-764f9f823557",
            price: 69871.69,
            price_sources: [],
            processed_ms: 1711487879690,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "d94908db-08b5-494f-b611-2bc4959a4e51",
            price: 70089.01,
            price_sources: [],
            processed_ms: 1711489678855,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "4b9bcdbf-773a-4f65-9e1b-e27ed2b7441b",
            price: 69851.9,
            price_sources: [],
            processed_ms: 1711491490884,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "06f05870-4c66-42c6-a6cc-0d9fb331d00f",
            price: 70124.75,
            price_sources: [],
            processed_ms: 1711493309065,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "5aa1c18e-6bac-43a0-b57b-d0845aafee62",
            price: 70274.78,
            price_sources: [],
            processed_ms: 1711495135845,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "4603640b-6141-498d-8b0e-0b9d35f12801",
            price: 69959.4,
            price_sources: [],
            processed_ms: 1711496951356,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "d58bfada-6080-4ed9-a327-fb3f278134c1",
            price: 69924.56,
            price_sources: [],
            processed_ms: 1711498802670,
          },
          {
            leverage: -2.01953125,
            order_type: "SHORT",
            order_uuid: "d32f69ae-3f28-43cb-ad2a-7b65bf4b1393",
            price: 70363.24,
            price_sources: [],
            processed_ms: 1711500590624,
          },
          {
            leverage: -1.009765625,
            order_type: "SHORT",
            order_uuid: "ba9dcb68-a159-4931-90f3-73b7e7ffe8a1",
            price: 70670.08,
            price_sources: [],
            processed_ms: 1711502406696,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "576a35f0-2438-485f-8774-3a96eba437e4",
            price: 70398.77,
            price_sources: [],
            processed_ms: 1711504222878,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "0903cca2-1aa1-428d-af0d-7393f5d09ac9",
            price: 70208.88,
            price_sources: [],
            processed_ms: 1711506074948,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "6268f944-a687-4ecd-a52d-0d3a59f3ac93",
            price: 70456.35,
            price_sources: [],
            processed_ms: 1711507851809,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "0027614a-6a4b-4ce7-a6fe-ad29ca3fefca",
            price: 70531.52,
            price_sources: [],
            processed_ms: 1711509669792,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "1105f8a7-a9ee-409d-9111-572a285e05ba",
            price: 70503.36,
            price_sources: [],
            processed_ms: 1711511486945,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "af8ac9fe-f03f-4b60-b930-2912942a3597",
            price: 70287.33,
            price_sources: [],
            processed_ms: 1711513348053,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "04b408a5-8924-41c5-9cbd-ac369f59bf58",
            price: 70177.02,
            price_sources: [],
            processed_ms: 1711515137761,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "dc94319f-c26c-403f-af52-30638e7bfcb7",
            price: 70424.0,
            price_sources: [],
            processed_ms: 1711516937800,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "c9b9443e-0de0-4c14-b11a-624e7f1ef685",
            price: 70410.0,
            price_sources: [],
            processed_ms: 1711518750927,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "6d587f2a-f8dd-4860-8e74-5e021f552c20",
            price: 70336.87,
            price_sources: [],
            processed_ms: 1711520579748,
          },
          {
            leverage: -1.7548828125,
            order_type: "SHORT",
            order_uuid: "af8a90b6-0344-4eae-9550-c94d49e9cc6b",
            price: 70228.46,
            price_sources: [],
            processed_ms: 1711522433358,
          },
          {
            leverage: -0.87744140625,
            order_type: "SHORT",
            order_uuid: "60e8d1a9-d533-49ac-9d6d-5af6d33979cf",
            price: 69803.36,
            price_sources: [],
            processed_ms: 1711526557913,
          },
          {
            leverage: -0.438720703125,
            order_type: "SHORT",
            order_uuid: "ef8c6e4e-d5dd-4a12-83a7-229f7f254539",
            price: 69866.14,
            price_sources: [],
            processed_ms: 1711528411805,
          },
          {
            leverage: 0.375,
            order_type: "LONG",
            order_uuid: "3bab228e-011e-48da-8633-68a21ce37b02",
            price: 69535.47,
            price_sources: [],
            processed_ms: 1711530188612,
          },
          {
            leverage: 0.375,
            order_type: "LONG",
            order_uuid: "1a8f15fe-986c-40b5-9392-4a9ee1cb32ab",
            price: 69771.18,
            price_sources: [],
            processed_ms: 1711532009658,
          },
          {
            leverage: 0.375,
            order_type: "LONG",
            order_uuid: "7ce9da73-8906-4c6c-84b7-c9d53071477f",
            price: 70014.01,
            price_sources: [],
            processed_ms: 1711533858720,
          },
          {
            leverage: 0.375,
            order_type: "LONG",
            order_uuid: "fc467809-fc8c-4ea6-adc2-adf13009bd43",
            price: 70158.46,
            price_sources: [],
            processed_ms: 1711538248846,
          },
          {
            leverage: 0.375,
            order_type: "LONG",
            order_uuid: "fbb2d300-482f-417c-9dff-3a4814c534fc",
            price: 70156.89,
            price_sources: [],
            processed_ms: 1711541860402,
          },
          {
            leverage: -1.8509765625,
            order_type: "SHORT",
            order_uuid: "c8dbba02-ab7e-4928-a9e0-96b24b313734",
            price: 71347.41,
            price_sources: [],
            processed_ms: 1711545477891,
          },
          {
            leverage: -0.37019531249999993,
            order_type: "SHORT",
            order_uuid: "a0dbf3d1-39c7-44fc-816f-07b76325a94c",
            price: 69261.6,
            price_sources: [],
            processed_ms: 1711549091998,
          },
          {
            leverage: -0.04627441406249999,
            order_type: "SHORT",
            order_uuid: "a4d354db-06f7-4400-a7b7-6cd0cab14274",
            price: 69319.55,
            price_sources: [],
            processed_ms: 1711552714100,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "0725bf53-52bd-4837-a547-ef9af30acc1f",
            price: 68573.49,
            price_sources: [],
            processed_ms: 1711556331775,
          },
        ],
        position_type: "FLAT",
        position_uuid: "6103f305-a3cf-4af9-b9c6-9cdf2920291e",
        return_at_close: 1.0200918701541228,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 68232.6326086956,
        close_ms: 1711618790323,
        current_return: 1.0099910107074583,
        initial_entry_price: 69265.74,
        is_closed_position: true,
        miner_hotkey: "5D7irf6EGydsUU3sP2zxC4CHR3iZCVNeMCFVBFEGYDLXPVsR",
        net_leverage: 0.0,
        open_ms: 1711559940380,
        orders: [
          {
            leverage: 1.25,
            order_type: "LONG",
            order_uuid: "c6428db2-59a0-4bc6-b870-7dced6817f7e",
            price: 69265.74,
            price_sources: [],
            processed_ms: 1711559939760,
          },
          {
            leverage: -0.625,
            order_type: "SHORT",
            order_uuid: "2b6b3c38-bc1e-49ac-9e26-dfeef6408af2",
            price: 68674.94,
            price_sources: [],
            processed_ms: 1711563562096,
          },
          {
            leverage: 0.375,
            order_type: "LONG",
            order_uuid: "32c032e3-6f09-4435-a0da-fb27a2ae4b1b",
            price: 68783.77,
            price_sources: [],
            processed_ms: 1711567176587,
          },
          {
            leverage: -0.5,
            order_type: "SHORT",
            order_uuid: "4992b31e-d339-4b94-adb4-6e7c25f3e565",
            price: 68886.93,
            price_sources: [],
            processed_ms: 1711570820826,
          },
          {
            leverage: -0.25,
            order_type: "SHORT",
            order_uuid: "98897495-d271-46bc-a6af-bed2014a41d2",
            price: 69089.82,
            price_sources: [],
            processed_ms: 1711574408887,
          },
          {
            leverage: 0.375,
            order_type: "LONG",
            order_uuid: "7cc7d87b-d1b0-43f9-b449-e019506f293c",
            price: 69008.67,
            price_sources: [],
            processed_ms: 1711578029090,
          },
          {
            leverage: 0.375,
            order_type: "LONG",
            order_uuid: "eed9fb0c-e20e-439d-9644-66cf45c811cb",
            price: 69270.18,
            price_sources: [],
            processed_ms: 1711581668900,
          },
          {
            leverage: 0.375,
            order_type: "LONG",
            order_uuid: "639efa54-200f-4e7b-a73c-d1482ca195af",
            price: 69795.18,
            price_sources: [],
            processed_ms: 1711585273892,
          },
          {
            leverage: 0.375,
            order_type: "LONG",
            order_uuid: "e35566ce-cb49-4ccf-a201-cf2ea90044fa",
            price: 69465.22,
            price_sources: [],
            processed_ms: 1711588890343,
          },
          {
            leverage: 0.375,
            order_type: "LONG",
            order_uuid: "857b467a-06d5-4fb7-a8c7-df503e276d72",
            price: 69401.75,
            price_sources: [],
            processed_ms: 1711592505260,
          },
          {
            leverage: 0.375,
            order_type: "LONG",
            order_uuid: "533627ee-e2e0-4a39-b3b0-5461527af5c6",
            price: 69216.13,
            price_sources: [],
            processed_ms: 1711596128056,
          },
          {
            leverage: 0.375,
            order_type: "LONG",
            order_uuid: "caa01f2d-e422-4b0c-ab42-0e685aa5886f",
            price: 69570.01,
            price_sources: [],
            processed_ms: 1711599745903,
          },
          {
            leverage: -2.3,
            order_type: "SHORT",
            order_uuid: "980057c1-2b9f-49b3-9ed5-ed398a399f3b",
            price: 69676.63,
            price_sources: [],
            processed_ms: 1711601130080,
          },
          {
            leverage: -0.2875000000000001,
            order_type: "SHORT",
            order_uuid: "d464abc9-4f16-4677-be29-f7d147b66c21",
            price: 69610.01,
            price_sources: [],
            processed_ms: 1711602969845,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "60fc3631-8588-4b17-a379-3358f652aeae",
            price: 70639.71,
            price_sources: [],
            processed_ms: 1711618790323,
          },
        ],
        position_type: "FLAT",
        position_uuid: "894515cd-35d9-46e4-b7bf-21a684cfb0ec",
        return_at_close: 1.0041835623958904,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 70856.15,
        close_ms: 1711624331676,
        current_return: 0.9977233451154199,
        initial_entry_price: 70856.15,
        is_closed_position: true,
        miner_hotkey: "5D7irf6EGydsUU3sP2zxC4CHR3iZCVNeMCFVBFEGYDLXPVsR",
        net_leverage: 0.0,
        open_ms: 1711624213852,
        orders: [
          {
            leverage: -3.5,
            order_type: "SHORT",
            order_uuid: "9986d34c-086a-48bd-b6ef-546162037de3",
            price: 70856.15,
            price_sources: [],
            processed_ms: 1711624213849,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "36b4446a-0c4f-4b51-8232-ec7ea110581a",
            price: 70902.24,
            price_sources: [],
            processed_ms: 1711624331676,
          },
        ],
        position_type: "FLAT",
        position_uuid: "307ca6af-adab-4fd4-bfde-d8073e4ac3a7",
        return_at_close: 0.990739281699612,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 70800.17,
        close_ms: 1711633485242,
        current_return: 0.9912579305953646,
        initial_entry_price: 70800.17,
        is_closed_position: true,
        miner_hotkey: "5D7irf6EGydsUU3sP2zxC4CHR3iZCVNeMCFVBFEGYDLXPVsR",
        net_leverage: 0.0,
        open_ms: 1711633293982,
        orders: [
          {
            leverage: -3.5,
            order_type: "SHORT",
            order_uuid: "bf4c213d-2f1a-4719-bdd1-4c77ce7d9e11",
            price: 70800.17,
            price_sources: [],
            processed_ms: 1711633293979,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "d11b58f2-1851-4414-b141-3134bf4f8653",
            price: 70977.01,
            price_sources: [],
            processed_ms: 1711633485242,
          },
        ],
        position_type: "FLAT",
        position_uuid: "5af1e248-2c65-4cff-9523-c4638a4232de",
        return_at_close: 0.984319125081197,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 71313.66,
        close_ms: 1711637062169,
        current_return: 1.0030782321367324,
        initial_entry_price: 71313.66,
        is_closed_position: true,
        miner_hotkey: "5D7irf6EGydsUU3sP2zxC4CHR3iZCVNeMCFVBFEGYDLXPVsR",
        net_leverage: 0.0,
        open_ms: 1711636975061,
        orders: [
          {
            leverage: -3.5,
            order_type: "SHORT",
            order_uuid: "66533859-c9e4-4aa4-83bb-b371f7d5b247",
            price: 71313.66,
            price_sources: [],
            processed_ms: 1711636975058,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "eb5c3660-51f5-4477-88c3-6bcc44b8ebfc",
            price: 71250.94,
            price_sources: [],
            processed_ms: 1711637062169,
          },
        ],
        position_type: "FLAT",
        position_uuid: "b2e5557f-c312-4fa2-b3b5-79276131b317",
        return_at_close: 0.9960566845117753,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 71061.12571428569,
        close_ms: 1711677021565,
        current_return: 1.0026658904126222,
        initial_entry_price: 70818.74,
        is_closed_position: true,
        miner_hotkey: "5D7irf6EGydsUU3sP2zxC4CHR3iZCVNeMCFVBFEGYDLXPVsR",
        net_leverage: 0.0,
        open_ms: 1711662503151,
        orders: [
          {
            leverage: -5.0,
            order_type: "SHORT",
            order_uuid: "8bcd84fc-4902-4586-8e55-fd27c6f69394",
            price: 70818.74,
            price_sources: [],
            processed_ms: 1711662503136,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "87cd7788-1162-4589-be71-77677ebafcae",
            price: 70821.84,
            price_sources: [],
            processed_ms: 1711666138322,
          },
          {
            leverage: 1.75,
            order_type: "LONG",
            order_uuid: "32d9815c-d03b-461c-98cf-853922916370",
            price: 70764.34,
            price_sources: [],
            processed_ms: 1711669750147,
          },
          {
            leverage: 0.875,
            order_type: "LONG",
            order_uuid: "944bb48e-5aaa-40ad-b734-98b3bd84711c",
            price: 70679.84,
            price_sources: [],
            processed_ms: 1711673371247,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "ee45e79b-87ce-4f19-949f-96e65f187496",
            price: 70845.36,
            price_sources: [],
            processed_ms: 1711677021565,
          },
        ],
        position_type: "FLAT",
        position_uuid: "c0f179ed-4774-404a-80fe-804b7b861028",
        return_at_close: 0.992639231508496,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 70386.043125,
        close_ms: 1711697751884,
        current_return: 0.966134401159407,
        initial_entry_price: 70408.47,
        is_closed_position: true,
        miner_hotkey: "5D7irf6EGydsUU3sP2zxC4CHR3iZCVNeMCFVBFEGYDLXPVsR",
        net_leverage: 0.0,
        open_ms: 1711687846874,
        orders: [
          {
            leverage: 5.0,
            order_type: "LONG",
            order_uuid: "568213dd-6300-4402-9f66-121801f9754e",
            price: 70408.47,
            price_sources: [],
            processed_ms: 1711687846871,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "f40b05a0-d39a-430f-bfa4-f403a07f7bd0",
            price: 70254.69,
            price_sources: [],
            processed_ms: 1711691458539,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "1b845891-4b52-44cd-af57-e9f89efd499b",
            price: 70442.64,
            price_sources: [],
            processed_ms: 1711695076258,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "594fee27-e7a5-419d-9f64-420ebe479c3c",
            price: 70087.99,
            price_sources: [],
            processed_ms: 1711697751884,
          },
        ],
        position_type: "FLAT",
        position_uuid: "810ed943-0e35-4786-a1b2-6d8f0c873de1",
        return_at_close: 0.9506762507408565,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 58917.397008439926,
        close_ms: 1711789125858,
        current_return: 1.061873035710688,
        initial_entry_price: 69868.97,
        is_closed_position: true,
        miner_hotkey: "5D7irf6EGydsUU3sP2zxC4CHR3iZCVNeMCFVBFEGYDLXPVsR",
        net_leverage: 0.0,
        open_ms: 1711698711154,
        orders: [
          {
            leverage: 5.0,
            order_type: "LONG",
            order_uuid: "193acc99-67f2-4e4a-86ed-e5a812116a9d",
            price: 69868.97,
            price_sources: [],
            processed_ms: 1711698711151,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "ee2ef61c-def7-46e3-af59-d9ecb074883a",
            price: 70049.93,
            price_sources: [],
            processed_ms: 1711702307997,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "cc052cf5-29a2-459f-8c4c-6573014bcaa2",
            price: 69744.6,
            price_sources: [],
            processed_ms: 1711705930945,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "8ecc3dfb-7887-4087-9e03-cee80539f345",
            price: 69962.87,
            price_sources: [],
            processed_ms: 1711709544309,
          },
          {
            leverage: -4.75,
            order_type: "SHORT",
            order_uuid: "8fb2c63a-ce16-4edb-ace4-c22cfe95dbca",
            price: 70153.53,
            price_sources: [],
            processed_ms: 1711713165088,
          },
          {
            leverage: -2.375,
            order_type: "SHORT",
            order_uuid: "8ba87bce-3e7d-4ccd-801f-1083e959e2aa",
            price: 70135.68,
            price_sources: [],
            processed_ms: 1711716809566,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "dd901bb5-1b36-4c38-a9f4-5cfbf62c1f3d",
            price: 70338.6,
            price_sources: [],
            processed_ms: 1711720410471,
          },
          {
            leverage: -1.9375,
            order_type: "SHORT",
            order_uuid: "bf20838d-2a65-46df-aeb1-f4b6efbb6dce",
            price: 70352.78,
            price_sources: [],
            processed_ms: 1711724015216,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "043ee964-db09-49db-aff6-98576227ee40",
            price: 69346.48,
            price_sources: [],
            processed_ms: 1711727623118,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "07164c8c-fd11-4f30-9500-a2f7f79e4881",
            price: 69261.68,
            price_sources: [],
            processed_ms: 1711731248813,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "3fa6d6ca-3604-4564-a447-5d60d2a47609",
            price: 69277.45,
            price_sources: [],
            processed_ms: 1711734861149,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "bbe8aba2-9f1b-4755-ba2f-2c11219f6c4d",
            price: 69480.68,
            price_sources: [],
            processed_ms: 1711738471214,
          },
          {
            leverage: -3.96875,
            order_type: "SHORT",
            order_uuid: "0af19ab3-259c-4182-b63c-b41167d14384",
            price: 69638.77,
            price_sources: [],
            processed_ms: 1711742092284,
          },
          {
            leverage: -1.984375,
            order_type: "SHORT",
            order_uuid: "44fb2483-6c65-440e-a140-6faa063ad63c",
            price: 69610.77,
            price_sources: [],
            processed_ms: 1711745708684,
          },
          {
            leverage: -0.9921875,
            order_type: "SHORT",
            order_uuid: "e7ff9eb9-97c9-40af-9b60-67edb88b74f1",
            price: 69544.0,
            price_sources: [],
            processed_ms: 1711749323148,
          },
          {
            leverage: -0.49609375,
            order_type: "SHORT",
            order_uuid: "92793237-2e82-489c-9816-0a317606662a",
            price: 69811.06,
            price_sources: [],
            processed_ms: 1711752941055,
          },
          {
            leverage: -0.248046875,
            order_type: "SHORT",
            order_uuid: "c0d4abf3-c7d7-4f49-a1f0-a911c3002943",
            price: 69907.85,
            price_sources: [],
            processed_ms: 1711756558385,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "03e37c57-8360-4692-8e5c-1024565ba856",
            price: 69962.38,
            price_sources: [],
            processed_ms: 1711760174972,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "b60d040a-a34a-4a8e-9e73-6ab59e4e1292",
            price: 69999.23,
            price_sources: [],
            processed_ms: 1711763796756,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "d114e68e-fec8-48ff-b80a-21becb26f3d1",
            price: 69961.3,
            price_sources: [],
            processed_ms: 1711767409113,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "d1fd590a-2591-4155-8cf5-2e139ccfd386",
            price: 69809.65,
            price_sources: [],
            processed_ms: 1711771106443,
          },
          {
            leverage: -3.1240234375,
            order_type: "SHORT",
            order_uuid: "16194295-fc96-445e-b932-6b3bc260debf",
            price: 70133.17,
            price_sources: [],
            processed_ms: 1711774671587,
          },
          {
            leverage: -1.56201171875,
            order_type: "SHORT",
            order_uuid: "b024104b-00b9-46b7-ae64-f87a98a7e99b",
            price: 70056.88,
            price_sources: [],
            processed_ms: 1711778280753,
          },
          {
            leverage: -0.781005859375,
            order_type: "SHORT",
            order_uuid: "01b88aa4-e20a-4181-bec6-2141635cad69",
            price: 70047.64,
            price_sources: [],
            processed_ms: 1711781895772,
          },
          {
            leverage: -0.3905029296875,
            order_type: "SHORT",
            order_uuid: "acaa27c5-78d2-4c36-9682-0d6bc5929ff4",
            price: 69904.87,
            price_sources: [],
            processed_ms: 1711785513810,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "18bbf484-0d62-461a-956d-9488439c7d48",
            price: 69987.75,
            price_sources: [],
            processed_ms: 1711789125858,
          },
        ],
        position_type: "FLAT",
        position_uuid: "120d878c-f888-4382-8650-a454cff081ba",
        return_at_close: 1.041697448032185,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 69513.11142857143,
        close_ms: 1711807228729,
        current_return: 0.9914173482988115,
        initial_entry_price: 70053.67,
        is_closed_position: true,
        miner_hotkey: "5D7irf6EGydsUU3sP2zxC4CHR3iZCVNeMCFVBFEGYDLXPVsR",
        net_leverage: 0.0,
        open_ms: 1711792768045,
        orders: [
          {
            leverage: -5.0,
            order_type: "SHORT",
            order_uuid: "54be1dc1-9793-4838-b71d-aad58447807f",
            price: 70053.67,
            price_sources: [],
            processed_ms: 1711792768042,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "bd55709b-71d8-420c-a01d-ca1283f12ae0",
            price: 70054.55,
            price_sources: [],
            processed_ms: 1711796363931,
          },
          {
            leverage: 1.75,
            order_type: "LONG",
            order_uuid: "68672848-6fce-4b52-b257-e5dce664da13",
            price: 70220.43,
            price_sources: [],
            processed_ms: 1711799980768,
          },
          {
            leverage: 0.875,
            order_type: "LONG",
            order_uuid: "c8470d65-8f61-496e-9438-5dbc8fe7127d",
            price: 70259.2,
            price_sources: [],
            processed_ms: 1711803609421,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "06b3acff-275f-48d9-8e0d-85745427fd8f",
            price: 70200.25,
            price_sources: [],
            processed_ms: 1711807228729,
          },
        ],
        position_type: "FLAT",
        position_uuid: "97db2de8-6537-4bf5-8ec6-edcb9feaa84f",
        return_at_close: 0.9815031748158233,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 71194.51058823541,
        close_ms: 1711839800269,
        current_return: 0.9944195708839697,
        initial_entry_price: 70090.6,
        is_closed_position: true,
        miner_hotkey: "5D7irf6EGydsUU3sP2zxC4CHR3iZCVNeMCFVBFEGYDLXPVsR",
        net_leverage: 0.0,
        open_ms: 1711810849823,
        orders: [
          {
            leverage: 5.0,
            order_type: "LONG",
            order_uuid: "77e5c892-c6a4-4945-b251-4032560fd2a4",
            price: 70090.6,
            price_sources: [],
            processed_ms: 1711810849820,
          },
          {
            leverage: -2.5,
            order_type: "SHORT",
            order_uuid: "fdf4447b-9369-43a0-8ac7-6df2b7f0f54e",
            price: 69983.67,
            price_sources: [],
            processed_ms: 1711814523060,
          },
          {
            leverage: -1.25,
            order_type: "SHORT",
            order_uuid: "7a22a752-3029-49ca-b94f-eb798ddee465",
            price: 70207.23,
            price_sources: [],
            processed_ms: 1711818087988,
          },
          {
            leverage: -0.625,
            order_type: "SHORT",
            order_uuid: "47e8ab07-6b7a-4cee-b6d8-318685ede05d",
            price: 70064.54,
            price_sources: [],
            processed_ms: 1711821719939,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "75db372e-279a-4741-8cbf-f713f69b77da",
            price: 69929.1,
            price_sources: [],
            processed_ms: 1711825328216,
          },
          {
            leverage: -1.0625,
            order_type: "SHORT",
            order_uuid: "0356acfb-5664-4c2b-858f-63e48e1584c3",
            price: 69902.0,
            price_sources: [],
            processed_ms: 1711828949049,
          },
          {
            leverage: -0.53125,
            order_type: "SHORT",
            order_uuid: "9f63196a-aad2-459a-afc2-4ac9168f1846",
            price: 69884.78,
            price_sources: [],
            processed_ms: 1711832567979,
          },
          {
            leverage: -0.265625,
            order_type: "SHORT",
            order_uuid: "7435fb47-df50-4cf3-b871-37a7e2f22613",
            price: 69759.6,
            price_sources: [],
            processed_ms: 1711836190475,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "3396a1ab-51aa-4774-8471-e5241058bc78",
            price: 69722.0,
            price_sources: [],
            processed_ms: 1711839800269,
          },
        ],
        position_type: "FLAT",
        position_uuid: "94d61e6f-914f-4386-9117-364f21f76d92",
        return_at_close: 0.98447537517513,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 69413.18000000005,
        close_ms: 1711890454805,
        current_return: 0.9919528762917974,
        initial_entry_price: 70256.78,
        is_closed_position: true,
        miner_hotkey: "5D7irf6EGydsUU3sP2zxC4CHR3iZCVNeMCFVBFEGYDLXPVsR",
        net_leverage: 0.0,
        open_ms: 1711875967447,
        orders: [
          {
            leverage: -5.0,
            order_type: "SHORT",
            order_uuid: "2c5f6b5e-838a-475a-b45c-41ed930edabd",
            price: 70256.78,
            price_sources: [],
            processed_ms: 1711875967444,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "e487ad18-b33e-4181-a0ad-d17f8018183c",
            price: 70262.71,
            price_sources: [],
            processed_ms: 1711879582768,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "165853e4-e4ce-4428-9447-66e1530e3a63",
            price: 70343.62,
            price_sources: [],
            processed_ms: 1711883205470,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "674838fd-ac8d-49e3-941b-d3f097b376ca",
            price: 70445.21,
            price_sources: [],
            processed_ms: 1711886817418,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "27c4a61a-567d-4c08-9e8b-3d9928dfbd23",
            price: 70543.91,
            price_sources: [],
            processed_ms: 1711890454805,
          },
        ],
        position_type: "FLAT",
        position_uuid: "4fd76e7f-f4d6-485c-a175-d03a245d2801",
        return_at_close: 0.9820333475288794,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 70815.46000000002,
        close_ms: 1711904924617,
        current_return: 1.0045907873268305,
        initial_entry_price: 70549.99,
        is_closed_position: true,
        miner_hotkey: "5D7irf6EGydsUU3sP2zxC4CHR3iZCVNeMCFVBFEGYDLXPVsR",
        net_leverage: 0.0,
        open_ms: 1711894064939,
        orders: [
          {
            leverage: -5.0,
            order_type: "SHORT",
            order_uuid: "25c81482-0468-4954-8920-b5d1aac36b33",
            price: 70549.99,
            price_sources: [],
            processed_ms: 1711894064934,
          },
          {
            leverage: 2.5,
            order_type: "LONG",
            order_uuid: "1effe48d-68ee-4594-ae54-3ee039ffaa23",
            price: 70454.32,
            price_sources: [],
            processed_ms: 1711897681240,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "88b804c4-7d45-4e27-9de7-d36bca866bc0",
            price: 70532.46,
            price_sources: [],
            processed_ms: 1711901429048,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "016ac5b1-fc0a-43cc-91e1-bf4a644f9ebb",
            price: 70491.58,
            price_sources: [],
            processed_ms: 1711904924617,
          },
        ],
        position_type: "FLAT",
        position_uuid: "d5092d8f-07c6-475b-9cee-cf1432fcb0a7",
        return_at_close: 0.9945448794535622,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 70472.76,
        close_ms: 1711910459593,
        current_return: 0.9648176117978063,
        initial_entry_price: 70472.76,
        is_closed_position: true,
        miner_hotkey: "5D7irf6EGydsUU3sP2zxC4CHR3iZCVNeMCFVBFEGYDLXPVsR",
        net_leverage: 0.0,
        open_ms: 1711908542360,
        orders: [
          {
            leverage: -5.0,
            order_type: "SHORT",
            order_uuid: "043133cc-9074-4732-9f42-c1a5c2a55306",
            price: 70472.76,
            price_sources: [],
            processed_ms: 1711908542354,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "c50cf87a-3777-4856-9d24-6f88079644e1",
            price: 70968.64,
            price_sources: [],
            processed_ms: 1711910459593,
          },
        ],
        position_type: "FLAT",
        position_uuid: "6724733a-b286-4cb3-9c28-014a921c8817",
        return_at_close: 0.9551694356798283,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 71492.16000000015,
        close_ms: 1711930289968,
        current_return: 1.0018513599140257,
        initial_entry_price: 71034.27,
        is_closed_position: true,
        miner_hotkey: "5D7irf6EGydsUU3sP2zxC4CHR3iZCVNeMCFVBFEGYDLXPVsR",
        net_leverage: 0.0,
        open_ms: 1711915785783,
        orders: [
          {
            leverage: -5.0,
            order_type: "SHORT",
            order_uuid: "a2cb8329-0919-4722-b616-323f8eac0a75",
            price: 71034.27,
            price_sources: [],
            processed_ms: 1711915785780,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "0d6a6feb-05f0-4293-b5aa-cb90ac547095",
            price: 70853.04,
            price_sources: [],
            processed_ms: 1711919411210,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "cc84fb58-c740-446d-b242-ebfa34c7b384",
            price: 71007.14,
            price_sources: [],
            processed_ms: 1711923104068,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "46b50af6-52cf-422e-b020-ac50ede538f0",
            price: 71090.0,
            price_sources: [],
            processed_ms: 1711926658657,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "985b45d6-93aa-4937-9767-80a03b12b057",
            price: 71229.14,
            price_sources: [],
            processed_ms: 1711930289968,
          },
        ],
        position_type: "FLAT",
        position_uuid: "9897aa14-b71f-43ac-a5f3-6acb2c08d276",
        return_at_close: 0.9918328463148854,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 74027.32,
        close_ms: 1711948454593,
        current_return: 1.024806013558584,
        initial_entry_price: 71090.02,
        is_closed_position: true,
        miner_hotkey: "5D7irf6EGydsUU3sP2zxC4CHR3iZCVNeMCFVBFEGYDLXPVsR",
        net_leverage: 0.0,
        open_ms: 1711933896797,
        orders: [
          {
            leverage: -5.0,
            order_type: "SHORT",
            order_uuid: "335911ab-d9c5-48ef-870c-202897cdf381",
            price: 71090.02,
            price_sources: [],
            processed_ms: 1711933896791,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "e2163ac7-70b4-4511-960d-ad9455e80f2a",
            price: 70884.85,
            price_sources: [],
            processed_ms: 1711937517083,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "14bf0ba7-0b73-41fd-a7bf-083274732115",
            price: 70754.59,
            price_sources: [],
            processed_ms: 1711941134601,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "ee4b6309-0043-41cb-b27a-764e4cfb6654",
            price: 70651.52,
            price_sources: [],
            processed_ms: 1711944753001,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "075d7eb6-72e0-4ce6-8e34-87114ed16b82",
            price: 70500.4,
            price_sources: [],
            processed_ms: 1711948454593,
          },
        ],
        position_type: "FLAT",
        position_uuid: "feac9da3-f0e5-4be0-a504-abfe39a37e6a",
        return_at_close: 1.014557953422998,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 66504.54285714266,
        close_ms: 1711988276713,
        current_return: 0.9945387018227849,
        initial_entry_price: 69509.0,
        is_closed_position: true,
        miner_hotkey: "5D7irf6EGydsUU3sP2zxC4CHR3iZCVNeMCFVBFEGYDLXPVsR",
        net_leverage: 0.0,
        open_ms: 1711966454648,
        orders: [
          {
            leverage: -5.0,
            order_type: "SHORT",
            order_uuid: "a2e0b7c4-55d3-41e5-973c-1d01306e8642",
            price: 69509.0,
            price_sources: [],
            processed_ms: 1711966454645,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "cc76c5ed-e963-4755-8936-4ee1c0523165",
            price: 69771.1,
            price_sources: [],
            processed_ms: 1711970074584,
          },
          {
            leverage: 1.75,
            order_type: "LONG",
            order_uuid: "aaeb5ab8-f783-4a69-9ea8-f5803276a936",
            price: 69639.5,
            price_sources: [],
            processed_ms: 1711973704938,
          },
          {
            leverage: 0.875,
            order_type: "LONG",
            order_uuid: "7c81a748-32e9-462e-afeb-9c421fcfcc01",
            price: 69700.0,
            price_sources: [],
            processed_ms: 1711977441793,
          },
          {
            leverage: 0.4375,
            order_type: "LONG",
            order_uuid: "41cd9c2d-6770-4622-8d0f-5b4cc99af397",
            price: 69731.6,
            price_sources: [],
            processed_ms: 1711980945751,
          },
          {
            leverage: 0.21875,
            order_type: "LONG",
            order_uuid: "4456400f-a767-445a-8231-cfabbdbe0727",
            price: 68463.0,
            price_sources: [],
            processed_ms: 1711984562643,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "f0baee41-9343-49bd-86cb-5fc1424ffd62",
            price: 68239.9,
            price_sources: [],
            processed_ms: 1711988276713,
          },
        ],
        position_type: "FLAT",
        position_uuid: "9febf9f5-8cbc-4398-aff8-88b2b92936a2",
        return_at_close: 0.984593314804557,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 68887.09032258065,
        close_ms: 1712031045663,
        current_return: 0.7558404292273961,
        initial_entry_price: 68513.8,
        is_closed_position: true,
        miner_hotkey: "5D7irf6EGydsUU3sP2zxC4CHR3iZCVNeMCFVBFEGYDLXPVsR",
        net_leverage: 7.75,
        open_ms: 1711991803545,
        orders: [
          {
            leverage: 5.0,
            order_type: "LONG",
            order_uuid: "365886c4-8b0f-4d34-9fc2-d6c352e25e45",
            price: 68513.8,
            price_sources: [],
            processed_ms: 1711991803541,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "95be41a4-5acc-47d5-b074-627473849a16",
            price: 68607.0,
            price_sources: [],
            processed_ms: 1711995416796,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "33782be3-ae0c-4bb6-80c6-9c8761adf0ce",
            price: 69222.8,
            price_sources: [],
            processed_ms: 1711999042947,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "3bdeb2c0-ff78-41da-ae1b-832b0147a172",
            price: 69580.6,
            price_sources: [],
            processed_ms: 1712002679460,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "5136df8c-8b39-4dec-85f9-c02ae7c02778",
            price: 69785.6,
            price_sources: [],
            processed_ms: 1712006297443,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "4ec58374-af20-4869-af31-a5531907eea7",
            price: 69716.0,
            price_sources: [],
            processed_ms: 1712009904592,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "dd4c6908-816e-4b84-ba22-c175e030a5d6",
            price: 69797.9,
            price_sources: [],
            processed_ms: 1712013513491,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "fc7c7d7c-467d-4cef-91d2-f00392b71064",
            price: 69509.8,
            price_sources: [],
            processed_ms: 1712017147756,
          },
          {
            leverage: -7.75,
            order_type: "SHORT",
            order_uuid: "bb033b25-1e36-4cfc-8832-815484a61c3d",
            price: 69422.4,
            price_sources: [],
            processed_ms: 1712020785549,
          },
        ],
        position_type: "FLAT",
        position_uuid: "19e2af39-b4e1-4c98-bedd-d19f4bc7768c",
        return_at_close: 0.7324093759213468,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
    ],
    thirty_day_returns: 1.0,
  },
  "5DA1UaQ2JUvoVmCGjJFZ9JERxZ2gWzrLhFyN4hJWSMiRinkK": {
    all_time_returns: 1.0,
    n_positions: 0,
    percentage_profitable: 0.0,
    positions: [
      {
        average_entry_price: 64245.48999999999,
        close_ms: 1711241727157,
        current_return: 1.0013534335250616,
        initial_entry_price: 64245.49,
        is_closed_position: true,
        miner_hotkey: "5DA1UaQ2JUvoVmCGjJFZ9JERxZ2gWzrLhFyN4hJWSMiRinkK",
        net_leverage: 0.0,
        open_ms: 1711238148763,
        orders: [
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "ff3bd544-79ab-4120-aa02-c6108b976d47",
            price: 64245.49,
            price_sources: [],
            processed_ms: 1711238148763,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "d7a9be59-48aa-471b-b253-c95c5250ccda",
            price: 64535.33,
            price_sources: [],
            processed_ms: 1711241727157,
          },
        ],
        position_type: "FLAT",
        position_uuid: "b76ae363-1641-424b-8d57-dad47f9ad559",
        return_at_close: 1.0007526214649465,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 63991.549999998846,
        close_ms: 1711343070974,
        current_return: 0.999475785974849,
        initial_entry_price: 66680.37,
        is_closed_position: true,
        miner_hotkey: "5DA1UaQ2JUvoVmCGjJFZ9JERxZ2gWzrLhFyN4hJWSMiRinkK",
        net_leverage: 0.0,
        open_ms: 1711328649405,
        orders: [
          {
            leverage: -1.25,
            order_type: "SHORT",
            order_uuid: "215c85e4-d40e-495b-ad01-8d8a90ac291f",
            price: 66680.37,
            price_sources: [],
            processed_ms: 1711328649404,
          },
          {
            leverage: 0.625,
            order_type: "LONG",
            order_uuid: "69188f9c-a3cc-427e-bc49-86427f242a93",
            price: 66737.53,
            price_sources: [],
            processed_ms: 1711330250142,
          },
          {
            leverage: 0.3125,
            order_type: "LONG",
            order_uuid: "dc142583-a164-415a-a869-d07239cf64a6",
            price: 66583.38,
            price_sources: [],
            processed_ms: 1711332078152,
          },
          {
            leverage: 0.15625,
            order_type: "LONG",
            order_uuid: "35c94b5b-63ae-4eeb-9f2e-14ccf8944fff",
            price: 66682.76,
            price_sources: [],
            processed_ms: 1711333909133,
          },
          {
            leverage: 0.078125,
            order_type: "LONG",
            order_uuid: "077ba152-bd08-4969-a2cf-bf2586abd0df",
            price: 66679.69,
            price_sources: [],
            processed_ms: 1711335750191,
          },
          {
            leverage: 0.0390625,
            order_type: "LONG",
            order_uuid: "64582e2a-2908-4498-8b18-5ce7beacd9b3",
            price: 66796.32,
            price_sources: [],
            processed_ms: 1711337576992,
          },
          {
            leverage: 0.01953125,
            order_type: "LONG",
            order_uuid: "f94bae57-d47e-44e7-ad2d-7b131dca652f",
            price: 67225.6,
            price_sources: [],
            processed_ms: 1711339409463,
          },
          {
            leverage: 0.009765625,
            order_type: "LONG",
            order_uuid: "8455aca9-7a1d-40bb-82a0-e954c3e15f41",
            price: 67227.57,
            price_sources: [],
            processed_ms: 1711341248541,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "e8005e64-6384-4e17-86d5-96b0b75020fd",
            price: 67570.92,
            price_sources: [],
            processed_ms: 1711343070974,
          },
        ],
        position_type: "FLAT",
        position_uuid: "2bbb8fb7-92cf-4013-b749-cb038364d368",
        return_at_close: 0.996977096509912,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: -137696.97666667093,
        close_ms: 1711395267289,
        current_return: 1.0145275860375458,
        initial_entry_price: 67244.48,
        is_closed_position: true,
        miner_hotkey: "5DA1UaQ2JUvoVmCGjJFZ9JERxZ2gWzrLhFyN4hJWSMiRinkK",
        net_leverage: 0.0,
        open_ms: 1711370237896,
        orders: [
          {
            leverage: 1.25,
            order_type: "LONG",
            order_uuid: "d14a7d21-ef03-4642-9428-9a673d9216c1",
            price: 67244.48,
            price_sources: [],
            processed_ms: 1711370237895,
          },
          {
            leverage: -0.625,
            order_type: "SHORT",
            order_uuid: "9b2e8fc6-a350-4e48-bce6-bef1369edc42",
            price: 67197.62,
            price_sources: [],
            processed_ms: 1711372070150,
          },
          {
            leverage: -0.3125,
            order_type: "SHORT",
            order_uuid: "1bbe6740-f3dd-4d51-a458-27342afb4b2b",
            price: 67182.73,
            price_sources: [],
            processed_ms: 1711373895485,
          },
          {
            leverage: -0.15625,
            order_type: "SHORT",
            order_uuid: "ee59955a-46bb-4fd9-a9f6-36b2191085a6",
            price: 68234.56,
            price_sources: [],
            processed_ms: 1711375734433,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "b33341fa-72cf-434e-a056-5111103fd5d6",
            price: 68578.47,
            price_sources: [],
            processed_ms: 1711375929521,
          },
          {
            leverage: -0.15625,
            order_type: "SHORT",
            order_uuid: "1005ded8-fc28-408c-86a5-3f661f1af4bd",
            price: 68838.73,
            price_sources: [],
            processed_ms: 1711375949092,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "8d208bea-d9cc-42da-80f3-c2cd5ec1fafa",
            price: 68868.54,
            price_sources: [],
            processed_ms: 1711376437795,
          },
          {
            leverage: -0.4,
            order_type: "SHORT",
            order_uuid: "3428b650-21f1-4292-a817-25cfc072feaa",
            price: 69210.38,
            price_sources: [],
            processed_ms: 1711376764478,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "0ee7f787-5b55-4602-8be3-564008664ae0",
            price: 69095.56,
            price_sources: [],
            processed_ms: 1711377926100,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "004fc763-6b73-465e-8998-7f6f9cab49d6",
            price: 69061.84,
            price_sources: [],
            processed_ms: 1711378195476,
          },
          {
            leverage: -0.3,
            order_type: "SHORT",
            order_uuid: "6f9349ee-f8ed-4f3c-a4b1-2d96503f7eb3",
            price: 69482.27,
            price_sources: [],
            processed_ms: 1711380809363,
          },
          {
            leverage: -0.15,
            order_type: "SHORT",
            order_uuid: "90fc70c9-47a9-403c-9dd1-1aba6f31e989",
            price: 69870.9,
            price_sources: [],
            processed_ms: 1711382625243,
          },
          {
            leverage: -0.075,
            order_type: "SHORT",
            order_uuid: "c0a1c013-b2d3-4fb6-a33b-19c01ac28704",
            price: 69716.65,
            price_sources: [],
            processed_ms: 1711384481275,
          },
          {
            leverage: -0.0375,
            order_type: "SHORT",
            order_uuid: "6a6290b0-a2ca-41e2-be5b-5cebd5fc2b15",
            price: 70284.82,
            price_sources: [],
            processed_ms: 1711386259078,
          },
          {
            leverage: -0.01875,
            order_type: "SHORT",
            order_uuid: "731ea37b-a1ca-41ef-8f1b-cd8d7321ef79",
            price: 70673.03,
            price_sources: [],
            processed_ms: 1711388075132,
          },
          {
            leverage: -0.009375,
            order_type: "SHORT",
            order_uuid: "71818683-74e4-4f49-bcd3-5ea38ea58704",
            price: 70588.7,
            price_sources: [],
            processed_ms: 1711389917911,
          },
          {
            leverage: -0.0046875,
            order_type: "SHORT",
            order_uuid: "113a58da-fe86-44ea-a476-dddaddc0e4a0",
            price: 70518.19,
            price_sources: [],
            processed_ms: 1711391707319,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "4a94abec-b583-47a1-826e-2bcb2a223bd8",
            price: 70708.35,
            price_sources: [],
            processed_ms: 1711395267289,
          },
        ],
        position_type: "FLAT",
        position_uuid: "ce8980d6-2ffe-485a-a6c7-00c68a473b5c",
        return_at_close: 1.011991267072452,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 68744.58000000009,
        close_ms: 1711409811284,
        current_return: 1.007044527179108,
        initial_entry_price: 70014.99,
        is_closed_position: true,
        miner_hotkey: "5DA1UaQ2JUvoVmCGjJFZ9JERxZ2gWzrLhFyN4hJWSMiRinkK",
        net_leverage: 0.0,
        open_ms: 1711402536274,
        orders: [
          {
            leverage: 3.0,
            order_type: "LONG",
            order_uuid: "495caa50-e37c-4f8d-98fa-cabc18b324b7",
            price: 70014.99,
            price_sources: [],
            processed_ms: 1711402536273,
          },
          {
            leverage: -1.5,
            order_type: "SHORT",
            order_uuid: "f9fd7ba2-79d0-4448-ba57-962d4c33e285",
            price: 70063.81,
            price_sources: [],
            processed_ms: 1711404350483,
          },
          {
            leverage: -0.75,
            order_type: "SHORT",
            order_uuid: "aaf01734-ad0b-4fbb-bb38-95f146689b96",
            price: 70398.77,
            price_sources: [],
            processed_ms: 1711406166026,
          },
          {
            leverage: -0.375,
            order_type: "SHORT",
            order_uuid: "97266f8e-95e6-4d71-8f51-9381e3bffea9",
            price: 70322.56,
            price_sources: [],
            processed_ms: 1711408008421,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "5ba75c1b-1e2f-4343-a685-4fb505cdd489",
            price: 70059.84,
            price_sources: [],
            processed_ms: 1711409811284,
          },
        ],
        position_type: "FLAT",
        position_uuid: "1cbfce8d-1dcc-4100-982e-fbb586d36588",
        return_at_close: 1.0010022600160333,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 72271.51414012734,
        close_ms: 1711486096521,
        current_return: 0.9870397077104995,
        initial_entry_price: 69867.46,
        is_closed_position: true,
        miner_hotkey: "5DA1UaQ2JUvoVmCGjJFZ9JERxZ2gWzrLhFyN4hJWSMiRinkK",
        net_leverage: 0.0,
        open_ms: 1711464309357,
        orders: [
          {
            leverage: 3.0,
            order_type: "LONG",
            order_uuid: "2bedf79e-b8aa-4102-915e-7db08b63f481",
            price: 69867.46,
            price_sources: [],
            processed_ms: 1711464309356,
          },
          {
            leverage: 0.8999999999999999,
            order_type: "LONG",
            order_uuid: "1d51ecd4-291f-4c99-9570-88bcde181227",
            price: 70066.1,
            price_sources: [],
            processed_ms: 1711466126205,
          },
          {
            leverage: -1.95,
            order_type: "SHORT",
            order_uuid: "b2895180-8d02-45f1-8cd9-c0db4ce0a3f9",
            price: 70252.89,
            price_sources: [],
            processed_ms: 1711467937250,
          },
          {
            leverage: -0.975,
            order_type: "SHORT",
            order_uuid: "b6edede4-50dc-4909-97bb-1e34de49e3ac",
            price: 70030.97,
            price_sources: [],
            processed_ms: 1711469760278,
          },
          {
            leverage: -0.4875,
            order_type: "SHORT",
            order_uuid: "b31cc348-3436-4265-90f9-1164df73ef79",
            price: 69550.21,
            price_sources: [],
            processed_ms: 1711471573634,
          },
          {
            leverage: -0.24375,
            order_type: "SHORT",
            order_uuid: "28059a17-fcd5-4f7a-bfd3-b5485846f441",
            price: 69728.02,
            price_sources: [],
            processed_ms: 1711473391412,
          },
          {
            leverage: 0.8999999999999998,
            order_type: "LONG",
            order_uuid: "4061a98a-ba8b-422f-b3b4-f53598824aaa",
            price: 70113.27,
            price_sources: [],
            processed_ms: 1711475202235,
          },
          {
            leverage: 0.8999999999999999,
            order_type: "LONG",
            order_uuid: "5c67cfca-c67d-4ef7-9207-7abbb00ec7ce",
            price: 70264.0,
            price_sources: [],
            processed_ms: 1711477020185,
          },
          {
            leverage: 0.8999999999999999,
            order_type: "LONG",
            order_uuid: "4974d828-558b-4b94-800e-6dea5ee2c8dc",
            price: 70385.38,
            price_sources: [],
            processed_ms: 1711478835160,
          },
          {
            leverage: -1.4718749999999998,
            order_type: "SHORT",
            order_uuid: "4f112fe4-31c9-4d6b-925e-fda204ef6dd6",
            price: 69727.33,
            price_sources: [],
            processed_ms: 1711480655472,
          },
          {
            leverage: -0.7359374999999999,
            order_type: "SHORT",
            order_uuid: "b3f7c103-35b0-41aa-b409-636b1952353c",
            price: 69748.83,
            price_sources: [],
            processed_ms: 1711482470188,
          },
          {
            leverage: -0.36796874999999996,
            order_type: "SHORT",
            order_uuid: "78b185dd-b8d5-4676-9e0d-de4cd29aa2a5",
            price: 69621.78,
            price_sources: [],
            processed_ms: 1711484289523,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "215e6e8f-fd4d-4740-b563-d18803ef1233",
            price: 69810.7,
            price_sources: [],
            processed_ms: 1711486096521,
          },
        ],
        position_type: "FLAT",
        position_uuid: "741ff927-9a63-4d0d-90df-a13512390eba",
        return_at_close: 0.9793407979903576,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 67586.24999999994,
        close_ms: 1711578083240,
        current_return: 0.9927729832059576,
        initial_entry_price: 68886.93,
        is_closed_position: true,
        miner_hotkey: "5DA1UaQ2JUvoVmCGjJFZ9JERxZ2gWzrLhFyN4hJWSMiRinkK",
        net_leverage: 0.0,
        open_ms: 1711570809167,
        orders: [
          {
            leverage: -3.5,
            order_type: "SHORT",
            order_uuid: "47fbe8bb-d4e4-47a4-aaa7-166ecb8f2671",
            price: 68886.93,
            price_sources: [],
            processed_ms: 1711570809165,
          },
          {
            leverage: 1.0499999999999998,
            order_type: "LONG",
            order_uuid: "c61e9db9-9bf3-46a7-840e-fa4978492dba",
            price: 68889.44,
            price_sources: [],
            processed_ms: 1711572621647,
          },
          {
            leverage: 1.05,
            order_type: "LONG",
            order_uuid: "1163e424-3b40-47d0-9984-114fee60a835",
            price: 69113.12,
            price_sources: [],
            processed_ms: 1711574448536,
          },
          {
            leverage: 1.05,
            order_type: "LONG",
            order_uuid: "8f8057a1-2235-4e23-8c1e-bbaa57ba55a4",
            price: 69091.79,
            price_sources: [],
            processed_ms: 1711576270056,
          },
          {
            leverage: 1.05,
            order_type: "LONG",
            order_uuid: "2c847850-19d0-40ce-b400-159985a5e45c",
            price: 69008.67,
            price_sources: [],
            processed_ms: 1711578083240,
          },
        ],
        position_type: "FLAT",
        position_uuid: "74c5887a-be2d-4123-b6bf-346087f4653c",
        return_at_close: 0.9858235723235159,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 69094.78000000001,
        close_ms: 1711587177347,
        current_return: 0.9945830979142096,
        initial_entry_price: 69326.71,
        is_closed_position: true,
        miner_hotkey: "5DA1UaQ2JUvoVmCGjJFZ9JERxZ2gWzrLhFyN4hJWSMiRinkK",
        net_leverage: 0.0,
        open_ms: 1711579894409,
        orders: [
          {
            leverage: -3.5,
            order_type: "SHORT",
            order_uuid: "19047ffb-d7d9-4b76-b654-4336f4dd1a8f",
            price: 69326.71,
            price_sources: [],
            processed_ms: 1711579894408,
          },
          {
            leverage: 1.0499999999999998,
            order_type: "LONG",
            order_uuid: "3e0e1a64-f382-4242-92ca-289d5a52b184",
            price: 69216.24,
            price_sources: [],
            processed_ms: 1711581712158,
          },
          {
            leverage: 1.05,
            order_type: "LONG",
            order_uuid: "91cdce61-282e-413a-8d66-df372f734211",
            price: 69252.14,
            price_sources: [],
            processed_ms: 1711583533265,
          },
          {
            leverage: 0.7000000000000001,
            order_type: "LONG",
            order_uuid: "6f00f6c6-afba-4f6f-8fed-71f7a897c274",
            price: 69836.2,
            price_sources: [],
            processed_ms: 1711585359146,
          },
          {
            leverage: 1.05,
            order_type: "LONG",
            order_uuid: "62292c73-64a8-4341-8a4a-3f08989d1983",
            price: 69631.26,
            price_sources: [],
            processed_ms: 1711587177347,
          },
        ],
        position_type: "FLAT",
        position_uuid: "ba1b082a-98d8-48bd-b6bf-5524b1791047",
        return_at_close: 0.9876210162288102,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 71342.81428571428,
        close_ms: 1711598127401,
        current_return: 1.0092401804218731,
        initial_entry_price: 69491.02,
        is_closed_position: true,
        miner_hotkey: "5DA1UaQ2JUvoVmCGjJFZ9JERxZ2gWzrLhFyN4hJWSMiRinkK",
        net_leverage: 0.0,
        open_ms: 1711588993359,
        orders: [
          {
            leverage: -3.5,
            order_type: "SHORT",
            order_uuid: "2195d76c-f20a-4834-b2a0-157eea8aa792",
            price: 69491.02,
            price_sources: [],
            processed_ms: 1711588993357,
          },
          {
            leverage: 1.0499999999999998,
            order_type: "LONG",
            order_uuid: "38c401bc-91da-4906-b179-67ab3351d542",
            price: 69400.85,
            price_sources: [],
            processed_ms: 1711590817249,
          },
          {
            leverage: 1.225,
            order_type: "LONG",
            order_uuid: "9953c075-705b-45b1-ab0b-3dfe19dffbcb",
            price: 69413.89,
            price_sources: [],
            processed_ms: 1711592629501,
          },
          {
            leverage: 0.6125,
            order_type: "LONG",
            order_uuid: "df87c86f-669d-4f0a-9149-2423134e60bd",
            price: 69038.56,
            price_sources: [],
            processed_ms: 1711594441017,
          },
          {
            leverage: 0.30625,
            order_type: "LONG",
            order_uuid: "fc676149-26d0-49af-b340-57762c78a253",
            price: 69161.82,
            price_sources: [],
            processed_ms: 1711596259243,
          },
          {
            leverage: 1.05,
            order_type: "LONG",
            order_uuid: "c9461d05-6d16-4ba4-a15c-5cb6d7ea8877",
            price: 69246.13,
            price_sources: [],
            processed_ms: 1711598127401,
          },
        ],
        position_type: "FLAT",
        position_uuid: "4dba450c-da87-4030-a543-b68d4bc575d8",
        return_at_close: 1.00217549915892,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 70893.06,
        close_ms: 1711624451534,
        current_return: 1.0012737495038302,
        initial_entry_price: 70893.06,
        is_closed_position: true,
        miner_hotkey: "5DA1UaQ2JUvoVmCGjJFZ9JERxZ2gWzrLhFyN4hJWSMiRinkK",
        net_leverage: 0.0,
        open_ms: 1711624341961,
        orders: [
          {
            leverage: -3.5,
            order_type: "SHORT",
            order_uuid: "d649c026-a6fe-4ffd-97a8-da7dcb7dfca7",
            price: 70893.06,
            price_sources: [],
            processed_ms: 1711624341960,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "97ca49c1-6ed6-4f37-919a-066395c01d52",
            price: 70867.26,
            price_sources: [],
            processed_ms: 1711624451534,
          },
        ],
        position_type: "FLAT",
        position_uuid: "c360fab3-cb9e-4899-bc85-a6c4350f4bdc",
        return_at_close: 0.9942648332573034,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 70941.54,
        close_ms: 1711633512878,
        current_return: 0.9982500379890258,
        initial_entry_price: 70941.54,
        is_closed_position: true,
        miner_hotkey: "5DA1UaQ2JUvoVmCGjJFZ9JERxZ2gWzrLhFyN4hJWSMiRinkK",
        net_leverage: 0.0,
        open_ms: 1711633426257,
        orders: [
          {
            leverage: -3.5,
            order_type: "SHORT",
            order_uuid: "74e552c2-8115-4c76-9d00-3f2a49a18aa5",
            price: 70941.54,
            price_sources: [],
            processed_ms: 1711633426256,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "58bc946e-da81-4b3d-a6a0-585ffe683000",
            price: 70977.01,
            price_sources: [],
            processed_ms: 1711633512878,
          },
        ],
        position_type: "FLAT",
        position_uuid: "ffae2ccd-b043-4de4-9a40-8f357ddcd684",
        return_at_close: 0.9912622877231027,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 70769.41,
        close_ms: 1711635329132,
        current_return: 1.0016736044570669,
        initial_entry_price: 70769.41,
        is_closed_position: true,
        miner_hotkey: "5DA1UaQ2JUvoVmCGjJFZ9JERxZ2gWzrLhFyN4hJWSMiRinkK",
        net_leverage: 0.0,
        open_ms: 1711635242587,
        orders: [
          {
            leverage: -3.5,
            order_type: "SHORT",
            order_uuid: "bfbbb6e5-9f08-415c-93df-c8edb1c958c6",
            price: 70769.41,
            price_sources: [],
            processed_ms: 1711635242586,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "06d6884e-e0a4-4da2-922a-84c2b3a0e5d4",
            price: 70735.57,
            price_sources: [],
            processed_ms: 1711635329132,
          },
        ],
        position_type: "FLAT",
        position_uuid: "e27895ef-ac32-4632-91f9-8b9ee95db709",
        return_at_close: 0.9946618892258674,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 71250.94,
        close_ms: 1711637147292,
        current_return: 0.9977217142679098,
        initial_entry_price: 71250.94,
        is_closed_position: true,
        miner_hotkey: "5DA1UaQ2JUvoVmCGjJFZ9JERxZ2gWzrLhFyN4hJWSMiRinkK",
        net_leverage: 0.0,
        open_ms: 1711637060291,
        orders: [
          {
            leverage: -3.5,
            order_type: "SHORT",
            order_uuid: "ad2179f9-0d52-4156-8db5-55fe88080cd1",
            price: 71250.94,
            price_sources: [],
            processed_ms: 1711637060290,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "761686d7-1b86-4e92-9e9c-4a80af066851",
            price: 71297.32,
            price_sources: [],
            processed_ms: 1711637147292,
          },
        ],
        position_type: "FLAT",
        position_uuid: "12d930a6-b6a2-4399-9c41-c43e2767e8c4",
        return_at_close: 0.9907376622680344,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 71361.46,
        close_ms: 1711638959914,
        current_return: 1.001502281483591,
        initial_entry_price: 71361.46,
        is_closed_position: true,
        miner_hotkey: "5DA1UaQ2JUvoVmCGjJFZ9JERxZ2gWzrLhFyN4hJWSMiRinkK",
        net_leverage: 0.0,
        open_ms: 1711638872888,
        orders: [
          {
            leverage: -3.5,
            order_type: "SHORT",
            order_uuid: "ab59f793-fb6a-4bcf-86f9-8a7851250c53",
            price: 71361.46,
            price_sources: [],
            processed_ms: 1711638872887,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "3f9d3f95-09bb-4927-b76c-57d91a8b0a78",
            price: 71330.83,
            price_sources: [],
            processed_ms: 1711638959914,
          },
        ],
        position_type: "FLAT",
        position_uuid: "918c20d4-3a56-450f-951b-fbac62cb1e17",
        return_at_close: 0.9944917655132058,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 71802.42000000023,
        close_ms: 1711682890350,
        current_return: 1.0049177544736139,
        initial_entry_price: 70889.57,
        is_closed_position: true,
        miner_hotkey: "5DA1UaQ2JUvoVmCGjJFZ9JERxZ2gWzrLhFyN4hJWSMiRinkK",
        net_leverage: 0.0,
        open_ms: 1711661197551,
        orders: [
          {
            leverage: -5.0,
            order_type: "SHORT",
            order_uuid: "d0b459b3-5c2c-4a2e-8f15-57bb3304a4d4",
            price: 70889.57,
            price_sources: [],
            processed_ms: 1711661197549,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "cfd5bd04-7ba7-4e4c-83d3-1e35ee1fd405",
            price: 70751.23,
            price_sources: [],
            processed_ms: 1711664849847,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "61016266-cc8b-4925-b3af-061e1cf5bd49",
            price: 71005.8,
            price_sources: [],
            processed_ms: 1711668429557,
          },
          {
            leverage: 1.0,
            order_type: "LONG",
            order_uuid: "26d8b869-11e5-4ef6-a181-8143b8f305d0",
            price: 70739.18,
            price_sources: [],
            processed_ms: 1711672037259,
          },
          {
            leverage: 0.5,
            order_type: "LONG",
            order_uuid: "496ac47e-aee1-487a-a378-86596840d284",
            price: 70876.0,
            price_sources: [],
            processed_ms: 1711675677083,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "db43f429-c33b-4613-b355-038048752195",
            price: 70738.08,
            price_sources: [],
            processed_ms: 1711679276561,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "4cb98ebf-d156-4d87-9d1b-db815cc33c55",
            price: 70407.95,
            price_sources: [],
            processed_ms: 1711682890350,
          },
        ],
        position_type: "FLAT",
        position_uuid: "e3f5bfd0-23ef-4a3f-900e-482d93917213",
        return_at_close: 0.9948685769288778,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 70432.64307692308,
        close_ms: 1711692533071,
        current_return: 0.9710175653999978,
        initial_entry_price: 70467.51,
        is_closed_position: true,
        miner_hotkey: "5DA1UaQ2JUvoVmCGjJFZ9JERxZ2gWzrLhFyN4hJWSMiRinkK",
        net_leverage: 0.0,
        open_ms: 1711686506336,
        orders: [
          {
            leverage: 5.0,
            order_type: "LONG",
            order_uuid: "162b7ad9-009f-4250-8d17-c6ea758802f7",
            price: 70467.51,
            price_sources: [],
            processed_ms: 1711686506334,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "7cac76d9-e1ae-46ab-89b1-ce55d39f5188",
            price: 70316.42,
            price_sources: [],
            processed_ms: 1711690124933,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "0c01e259-924f-477e-8d50-f5bd7a63d687",
            price: 70118.44,
            price_sources: [],
            processed_ms: 1711692533071,
          },
        ],
        position_type: "FLAT",
        position_uuid: "a28674cb-61a1-457a-868d-2152766dc41a",
        return_at_close: 0.9583943370497978,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 70313.40076923076,
        close_ms: 1711698076021,
        current_return: 0.9673780381168122,
        initial_entry_price: 70332.22,
        is_closed_position: true,
        miner_hotkey: "5DA1UaQ2JUvoVmCGjJFZ9JERxZ2gWzrLhFyN4hJWSMiRinkK",
        net_leverage: 0.0,
        open_ms: 1711693753678,
        orders: [
          {
            leverage: 5.0,
            order_type: "LONG",
            order_uuid: "fc4c7852-5d04-49d0-8480-990ef73f0f58",
            price: 70332.22,
            price_sources: [],
            processed_ms: 1711693753676,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "81e567f3-0625-4548-b727-575953ca0b15",
            price: 70250.67,
            price_sources: [],
            processed_ms: 1711697364073,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "21a989d0-f12b-4ca5-af68-7665d15cee7a",
            price: 69960.42,
            price_sources: [],
            processed_ms: 1711698076021,
          },
        ],
        position_type: "FLAT",
        position_uuid: "a8809430-2bf1-4564-9777-1eff09f71d3e",
        return_at_close: 0.9548021236212937,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
    ],
    thirty_day_returns: 1.0,
  },
  "5DAb4ygWMtbfiJ3ZJJU2MqcEEvegeM6UAXV7625A3q2oQwjA": {
    all_time_returns: 1.0,
    n_positions: 0,
    percentage_profitable: 0.0,
    positions: [
      {
        average_entry_price: 100.195,
        close_ms: 1712603451124,
        current_return: 1.0289435600578907,
        initial_entry_price: 100.195,
        is_closed_position: true,
        miner_hotkey: "5DAb4ygWMtbfiJ3ZJJU2MqcEEvegeM6UAXV7625A3q2oQwjA",
        net_leverage: 0.0,
        open_ms: 1712588642596,
        orders: [
          {
            leverage: 50.0,
            order_type: "LONG",
            order_uuid: "3d5c4f50-b016-45af-9061-2fb932a96477",
            price: 100.195,
            price_sources: [],
            processed_ms: 1712588642596,
          },
          {
            leverage: 50.0,
            order_type: "FLAT",
            order_uuid: "40952194-6b88-4863-8075-1ca8d10bfae0",
            price: 100.253,
            price_sources: [],
            processed_ms: 1712603451124,
          },
        ],
        position_type: "FLAT",
        position_uuid: "895099c8-8c44-4651-b330-7f890d377387",
        return_at_close: 1.025342257597688,
        trade_pair: ["AUDJPY", "AUD/JPY", 7e-5, 0.001, 500],
      },
    ],
    thirty_day_returns: 1.0,
  },
  "5DCPidFwEmBbBdgVZthUo6cZYR3JT1TonGgBXGx5DsqpLrmG": {
    all_time_returns: 1.0,
    n_positions: 0,
    percentage_profitable: 0.0,
    positions: [
      {
        average_entry_price: 64577.9,
        close_ms: 1711197009546,
        current_return: 1.0012196432525677,
        initial_entry_price: 64577.9,
        is_closed_position: true,
        miner_hotkey: "5DCPidFwEmBbBdgVZthUo6cZYR3JT1TonGgBXGx5DsqpLrmG",
        net_leverage: 0.0,
        open_ms: 1711193439518,
        orders: [
          {
            leverage: 0.6,
            order_type: "LONG",
            order_uuid: "3a348863-a53c-459b-999b-13c45d23f147",
            price: 64577.9,
            price_sources: [],
            processed_ms: 1711193439518,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "a7f00dec-c2d8-4180-b5c7-90cdc7cf36be",
            price: 64709.17,
            price_sources: [],
            processed_ms: 1711197009546,
          },
        ],
        position_type: "FLAT",
        position_uuid: "fd8c4c2f-b8f6-4801-a60a-4d243804394b",
        return_at_close: 1.0000181796806646,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 64673.64000000001,
        close_ms: 1711200681504,
        current_return: 0.998645414113076,
        initial_entry_price: 64673.64,
        is_closed_position: true,
        miner_hotkey: "5DCPidFwEmBbBdgVZthUo6cZYR3JT1TonGgBXGx5DsqpLrmG",
        net_leverage: 0.0,
        open_ms: 1711197096532,
        orders: [
          {
            leverage: 0.6,
            order_type: "LONG",
            order_uuid: "4db6adfe-819d-43ea-986c-52c340a8c237",
            price: 64673.64,
            price_sources: [],
            processed_ms: 1711197096532,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "6f858621-a944-406c-be67-2e0e04e10a0f",
            price: 64527.63,
            price_sources: [],
            processed_ms: 1711200681504,
          },
        ],
        position_type: "FLAT",
        position_uuid: "f5b5d451-2257-4952-8b33-294ed5dd2ab4",
        return_at_close: 0.9974470396161402,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 64527.62999999999,
        close_ms: 1711204330884,
        current_return: 1.0064535145642264,
        initial_entry_price: 64527.63,
        is_closed_position: true,
        miner_hotkey: "5DCPidFwEmBbBdgVZthUo6cZYR3JT1TonGgBXGx5DsqpLrmG",
        net_leverage: 0.0,
        open_ms: 1711200701390,
        orders: [
          {
            leverage: 0.6,
            order_type: "LONG",
            order_uuid: "27c8ff70-1ad7-4d0f-8d50-bfe31c8fe7de",
            price: 64527.63,
            price_sources: [],
            processed_ms: 1711200701390,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "7306dc00-8381-4af1-8eeb-775106fbeff1",
            price: 65221.68,
            price_sources: [],
            processed_ms: 1711204330884,
          },
        ],
        position_type: "FLAT",
        position_uuid: "8b007fa0-bce0-4abb-a611-70f0753664ed",
        return_at_close: 1.0052457703467492,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 65372.40000000001,
        close_ms: 1711208029443,
        current_return: 0.9960516364704369,
        initial_entry_price: 65372.4,
        is_closed_position: true,
        miner_hotkey: "5DCPidFwEmBbBdgVZthUo6cZYR3JT1TonGgBXGx5DsqpLrmG",
        net_leverage: 0.0,
        open_ms: 1711204432607,
        orders: [
          {
            leverage: 0.6,
            order_type: "LONG",
            order_uuid: "b5ff8c41-afa8-4fa7-8980-8411f80cc781",
            price: 65372.4,
            price_sources: [],
            processed_ms: 1711204432607,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "e4ec1f67-bab6-40b3-acfa-db0310142a25",
            price: 64942.21,
            price_sources: [],
            processed_ms: 1711208029443,
          },
        ],
        position_type: "FLAT",
        position_uuid: "1b73ce9d-9ffa-445b-b738-c9cfc24bf269",
        return_at_close: 0.9948563745066724,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 64942.21000000001,
        close_ms: 1711211630981,
        current_return: 1.0042603724141816,
        initial_entry_price: 64942.21,
        is_closed_position: true,
        miner_hotkey: "5DCPidFwEmBbBdgVZthUo6cZYR3JT1TonGgBXGx5DsqpLrmG",
        net_leverage: 0.0,
        open_ms: 1711208049622,
        orders: [
          {
            leverage: 0.6,
            order_type: "LONG",
            order_uuid: "a125b816-6eab-4c20-9935-0fae4a07e328",
            price: 64942.21,
            price_sources: [],
            processed_ms: 1711208049622,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "26383310-1f7b-468f-b5c3-f03c03a48ed0",
            price: 65403.34,
            price_sources: [],
            processed_ms: 1711211630981,
          },
        ],
        position_type: "FLAT",
        position_uuid: "06f3c728-b75b-4e6a-bde6-3ada56365592",
        return_at_close: 1.0030552599672846,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 65490.62000000001,
        close_ms: 1711215292858,
        current_return: 1.0040592377961912,
        initial_entry_price: 65490.62,
        is_closed_position: true,
        miner_hotkey: "5DCPidFwEmBbBdgVZthUo6cZYR3JT1TonGgBXGx5DsqpLrmG",
        net_leverage: 0.0,
        open_ms: 1711211757228,
        orders: [
          {
            leverage: 0.6,
            order_type: "LONG",
            order_uuid: "4c0b84eb-8c0e-4bc7-88b9-97770436ba15",
            price: 65490.62,
            price_sources: [],
            processed_ms: 1711211757227,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "da31b8b1-1be1-4df3-b3e5-b7fb3ad72e41",
            price: 65933.69,
            price_sources: [],
            processed_ms: 1711215292858,
          },
        ],
        position_type: "FLAT",
        position_uuid: "ecd9ac91-b228-4f2f-a755-3505758f71b4",
        return_at_close: 1.0028543667108358,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 65931.17,
        close_ms: 1711218953739,
        current_return: 0.9958850722655157,
        initial_entry_price: 65931.17,
        is_closed_position: true,
        miner_hotkey: "5DCPidFwEmBbBdgVZthUo6cZYR3JT1TonGgBXGx5DsqpLrmG",
        net_leverage: 0.0,
        open_ms: 1711215379639,
        orders: [
          {
            leverage: 0.6,
            order_type: "LONG",
            order_uuid: "98e280c6-e7fd-4000-b0d4-b6b6a2bab1bc",
            price: 65931.17,
            price_sources: [],
            processed_ms: 1711215379638,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "704799d4-0808-4815-848d-fb6de79fc28d",
            price: 65479.0,
            price_sources: [],
            processed_ms: 1711218953739,
          },
        ],
        position_type: "FLAT",
        position_uuid: "6a9e39cd-9e3d-4298-815b-cde0f5160930",
        return_at_close: 0.9946900101787971,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 65428.090000000004,
        close_ms: 1711222643683,
        current_return: 0.997042279546904,
        initial_entry_price: 65428.09,
        is_closed_position: true,
        miner_hotkey: "5DCPidFwEmBbBdgVZthUo6cZYR3JT1TonGgBXGx5DsqpLrmG",
        net_leverage: 0.0,
        open_ms: 1711219040736,
        orders: [
          {
            leverage: 0.6,
            order_type: "LONG",
            order_uuid: "013f0889-2560-4081-836b-0014726a47df",
            price: 65428.09,
            price_sources: [],
            processed_ms: 1711219040735,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "88f4c76b-a78e-4c19-9bad-585d748163f9",
            price: 65105.56,
            price_sources: [],
            processed_ms: 1711222643683,
          },
        ],
        position_type: "FLAT",
        position_uuid: "15f472f2-a19d-483c-879e-a0d730ac5813",
        return_at_close: 0.9958458288114478,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 65051.18,
        close_ms: 1711226278870,
        current_return: 0.9993211499007397,
        initial_entry_price: 65051.18,
        is_closed_position: true,
        miner_hotkey: "5DCPidFwEmBbBdgVZthUo6cZYR3JT1TonGgBXGx5DsqpLrmG",
        net_leverage: 0.0,
        open_ms: 1711222748501,
        orders: [
          {
            leverage: 0.6,
            order_type: "LONG",
            order_uuid: "89f841da-7d21-40d0-bcc8-96d2c89da71d",
            price: 65051.18,
            price_sources: [],
            processed_ms: 1711222748500,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "c4801735-2a3b-4c91-b50f-7353a1bf1652",
            price: 64977.58,
            price_sources: [],
            processed_ms: 1711226278870,
          },
        ],
        position_type: "FLAT",
        position_uuid: "00342016-47c0-4f4d-9236-dab8f9f0e833",
        return_at_close: 0.9981219645208589,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 64985.37,
        close_ms: 1711229946833,
        current_return: 0.9987994528614671,
        initial_entry_price: 64985.37,
        is_closed_position: true,
        miner_hotkey: "5DCPidFwEmBbBdgVZthUo6cZYR3JT1TonGgBXGx5DsqpLrmG",
        net_leverage: 0.0,
        open_ms: 1711226418537,
        orders: [
          {
            leverage: 0.6,
            order_type: "LONG",
            order_uuid: "e131a06e-5499-49e4-a688-96884021016a",
            price: 64985.37,
            price_sources: [],
            processed_ms: 1711226418536,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "7de576c1-8821-4b39-ab48-61a124a366bc",
            price: 64855.34,
            price_sources: [],
            processed_ms: 1711229946833,
          },
        ],
        position_type: "FLAT",
        position_uuid: "c7823ac7-28eb-456a-8e6b-537e23789e0f",
        return_at_close: 0.9976008935180334,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 64895.03,
        close_ms: 1711233599651,
        current_return: 1.0011726321722942,
        initial_entry_price: 64895.03,
        is_closed_position: true,
        miner_hotkey: "5DCPidFwEmBbBdgVZthUo6cZYR3JT1TonGgBXGx5DsqpLrmG",
        net_leverage: 0.0,
        open_ms: 1711230032978,
        orders: [
          {
            leverage: 0.6,
            order_type: "LONG",
            order_uuid: "278e6960-dcfc-4064-8429-cbf5d8736839",
            price: 64895.03,
            price_sources: [],
            processed_ms: 1711230032977,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "8e06dc5c-86d9-4668-8ad9-6ed49580aba1",
            price: 65021.86,
            price_sources: [],
            processed_ms: 1711233599651,
          },
        ],
        position_type: "FLAT",
        position_uuid: "54db2b83-6ee9-4401-81bb-a203c3495d1c",
        return_at_close: 0.9999712250136875,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 64965.0,
        close_ms: 1711237269655,
        current_return: 0.9949275456014777,
        initial_entry_price: 64965.0,
        is_closed_position: true,
        miner_hotkey: "5DCPidFwEmBbBdgVZthUo6cZYR3JT1TonGgBXGx5DsqpLrmG",
        net_leverage: 0.0,
        open_ms: 1711233686660,
        orders: [
          {
            leverage: 0.6,
            order_type: "LONG",
            order_uuid: "81533130-e2a0-45ea-8c5f-1903e294ebb3",
            price: 64965.0,
            price_sources: [],
            processed_ms: 1711233686658,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "30b8fa4e-8382-4c43-a9e3-d6ab209780f9",
            price: 64415.78,
            price_sources: [],
            processed_ms: 1711237269655,
          },
        ],
        position_type: "FLAT",
        position_uuid: "66c2cd0c-349c-4d4c-905f-ecf4a425e80a",
        return_at_close: 0.993733632546756,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 64371.74999999999,
        close_ms: 1711240923489,
        current_return: 1.0009141898425942,
        initial_entry_price: 64371.75,
        is_closed_position: true,
        miner_hotkey: "5DCPidFwEmBbBdgVZthUo6cZYR3JT1TonGgBXGx5DsqpLrmG",
        net_leverage: 0.0,
        open_ms: 1711237357937,
        orders: [
          {
            leverage: 0.6,
            order_type: "LONG",
            order_uuid: "44d5720b-ba6c-4777-bd56-034ba16b76e9",
            price: 64371.75,
            price_sources: [],
            processed_ms: 1711237357936,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "feaf4234-1ff2-41ba-bba4-a8ac5995c4d2",
            price: 64469.83,
            price_sources: [],
            processed_ms: 1711240923489,
          },
        ],
        position_type: "FLAT",
        position_uuid: "d788ad21-2679-46b8-a846-20de22520963",
        return_at_close: 0.999713092814783,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 64507.55999999999,
        close_ms: 1711244640823,
        current_return: 0.9998710987252633,
        initial_entry_price: 64514.49,
        is_closed_position: true,
        miner_hotkey: "5DCPidFwEmBbBdgVZthUo6cZYR3JT1TonGgBXGx5DsqpLrmG",
        net_leverage: 0.0,
        open_ms: 1711241009615,
        orders: [
          {
            leverage: 0.6,
            order_type: "LONG",
            order_uuid: "82c2fb7c-4c59-4b13-83e8-c0b2f035ac92",
            price: 64514.49,
            price_sources: [],
            processed_ms: 1711241009614,
          },
          {
            leverage: 0.6,
            order_type: "LONG",
            order_uuid: "a5e83de1-f409-4ea9-ae54-46a0f0c86df8",
            price: 64500.63,
            price_sources: [],
            processed_ms: 1711244621576,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "bd476a03-ef6e-4b0e-b842-ae1bc03b8bac",
            price: 64500.63,
            price_sources: [],
            processed_ms: 1711244640823,
          },
        ],
        position_type: "FLAT",
        position_uuid: "6f97b3e2-0a02-4eef-9d26-cf12bf512343",
        return_at_close: 0.9974714080883227,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 64375.13999999999,
        close_ms: 1711251909298,
        current_return: 0.9972459244360479,
        initial_entry_price: 64375.14,
        is_closed_position: true,
        miner_hotkey: "5DCPidFwEmBbBdgVZthUo6cZYR3JT1TonGgBXGx5DsqpLrmG",
        net_leverage: 0.0,
        open_ms: 1711248360640,
        orders: [
          {
            leverage: 0.6,
            order_type: "LONG",
            order_uuid: "368da89d-9189-4ae4-90ef-fadacdcde95f",
            price: 64375.14,
            price_sources: [],
            processed_ms: 1711248360638,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "49bc0695-4495-4362-a7f8-d1611e877d3a",
            price: 64079.65,
            price_sources: [],
            processed_ms: 1711251909298,
          },
        ],
        position_type: "FLAT",
        position_uuid: "4bc68fd1-f3dd-4a85-be80-127051ebfa1c",
        return_at_close: 0.9960492293267247,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 64118.21,
        close_ms: 1711255579189,
        current_return: 0.9994299279409079,
        initial_entry_price: 64118.21,
        is_closed_position: true,
        miner_hotkey: "5DCPidFwEmBbBdgVZthUo6cZYR3JT1TonGgBXGx5DsqpLrmG",
        net_leverage: 0.0,
        open_ms: 1711251996737,
        orders: [
          {
            leverage: 0.6,
            order_type: "LONG",
            order_uuid: "84c18ecf-21bf-4d5b-b89f-ae07a26be888",
            price: 64118.21,
            price_sources: [],
            processed_ms: 1711251996736,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "f03cf5f7-8453-478b-8135-ab40606ebea4",
            price: 64057.29,
            price_sources: [],
            processed_ms: 1711255579189,
          },
        ],
        position_type: "FLAT",
        position_uuid: "66aa8b0b-168a-4c2c-bb55-d80e719fe53d",
        return_at_close: 0.9982306120273788,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 64094.47,
        close_ms: 1711256087850,
        current_return: 1.000556428659134,
        initial_entry_price: 64094.47,
        is_closed_position: true,
        miner_hotkey: "5DCPidFwEmBbBdgVZthUo6cZYR3JT1TonGgBXGx5DsqpLrmG",
        net_leverage: 0.0,
        open_ms: 1711255681765,
        orders: [
          {
            leverage: 0.6,
            order_type: "LONG",
            order_uuid: "cf65b508-c942-4605-9960-296dc4084d82",
            price: 64094.47,
            price_sources: [],
            processed_ms: 1711255681764,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "3523090d-42c1-4467-92dc-04384c443911",
            price: 64153.91,
            price_sources: [],
            processed_ms: 1711256087850,
          },
        ],
        position_type: "FLAT",
        position_uuid: "1b415ea6-9189-45ec-b2e5-a32a3af87817",
        return_at_close: 0.999355760944743,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 64135.84999999999,
        close_ms: 1711257821959,
        current_return: 1.001603752035718,
        initial_entry_price: 64135.85,
        is_closed_position: true,
        miner_hotkey: "5DCPidFwEmBbBdgVZthUo6cZYR3JT1TonGgBXGx5DsqpLrmG",
        net_leverage: 0.0,
        open_ms: 1711256174757,
        orders: [
          {
            leverage: 0.6,
            order_type: "LONG",
            order_uuid: "e78ed4b6-f9cd-4135-bf2c-7c1b537f8a54",
            price: 64135.85,
            price_sources: [],
            processed_ms: 1711256174755,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "13eb4ede-5c9e-4960-a7c6-5ebd8c631512",
            price: 64307.28,
            price_sources: [],
            processed_ms: 1711257821959,
          },
        ],
        position_type: "FLAT",
        position_uuid: "a07d37ed-a91d-44c0-b8bd-5caef67b76cb",
        return_at_close: 1.0004018275332751,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 64291.37000000001,
        close_ms: 1711261487575,
        current_return: 1.0000062216748529,
        initial_entry_price: 64291.37,
        is_closed_position: true,
        miner_hotkey: "5DCPidFwEmBbBdgVZthUo6cZYR3JT1TonGgBXGx5DsqpLrmG",
        net_leverage: 0.0,
        open_ms: 1711257946780,
        orders: [
          {
            leverage: 1.25,
            order_type: "LONG",
            order_uuid: "066f8898-0dd2-4e23-9daa-232e729f6f93",
            price: 64291.37,
            price_sources: [],
            processed_ms: 1711257946778,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "a12635fd-6e0d-4b15-97e0-4e01bdcbbf5d",
            price: 64291.69,
            price_sources: [],
            processed_ms: 1711261487575,
          },
        ],
        position_type: "FLAT",
        position_uuid: "33298c8c-2551-41db-807a-7569cbae13db",
        return_at_close: 0.9975062061206658,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 64268.46,
        close_ms: 1711266280719,
        current_return: 1.001593900024989,
        initial_entry_price: 64268.46,
        is_closed_position: true,
        miner_hotkey: "5DCPidFwEmBbBdgVZthUo6cZYR3JT1TonGgBXGx5DsqpLrmG",
        net_leverage: 0.0,
        open_ms: 1711261574554,
        orders: [
          {
            leverage: 1.25,
            order_type: "LONG",
            order_uuid: "53e06422-81be-4f52-949e-f4e738bcc5b4",
            price: 64268.46,
            price_sources: [],
            processed_ms: 1711261574552,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "66b6cafe-d967-4222-bdf7-e1da84668712",
            price: 64350.41,
            price_sources: [],
            processed_ms: 1711266280719,
          },
        ],
        position_type: "FLAT",
        position_uuid: "fb1b1c09-95a6-421a-b869-4cba5d4c13a1",
        return_at_close: 0.9990899152749265,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 64845.67,
        close_ms: 1711273602619,
        current_return: 1.00086402068172,
        initial_entry_price: 64845.67,
        is_closed_position: true,
        miner_hotkey: "5DCPidFwEmBbBdgVZthUo6cZYR3JT1TonGgBXGx5DsqpLrmG",
        net_leverage: 0.0,
        open_ms: 1711270044652,
        orders: [
          {
            leverage: 0.7,
            order_type: "LONG",
            order_uuid: "78afd04b-93df-438b-a4ad-f38ac941366f",
            price: 64845.67,
            price_sources: [],
            processed_ms: 1711270044650,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "ca50597f-c524-443a-8775-429725067752",
            price: 64925.71,
            price_sources: [],
            processed_ms: 1711273602619,
          },
        ],
        position_type: "FLAT",
        position_uuid: "036af3df-4017-40bf-9a34-3c7ae3ae0d17",
        return_at_close: 0.9994628110527657,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 65188.883636363644,
        close_ms: 1711282121441,
        current_return: 1.0074328584752004,
        initial_entry_price: 65277.04,
        is_closed_position: true,
        miner_hotkey: "5DCPidFwEmBbBdgVZthUo6cZYR3JT1TonGgBXGx5DsqpLrmG",
        net_leverage: 0.0,
        open_ms: 1711277376500,
        orders: [
          {
            leverage: 0.7,
            order_type: "LONG",
            order_uuid: "29c192f0-ac26-4755-bcb2-8349f8097af8",
            price: 65277.04,
            price_sources: [],
            processed_ms: 1711277376497,
          },
          {
            leverage: 0.40000000000000013,
            order_type: "LONG",
            order_uuid: "48a48b48-f7c0-45ed-8106-478e447b9287",
            price: 65034.61,
            price_sources: [],
            processed_ms: 1711280263984,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "402a7c26-d2a9-4445-a911-4afbe26a46a5",
            price: 65629.97,
            price_sources: [],
            processed_ms: 1711282121441,
          },
        ],
        position_type: "FLAT",
        position_uuid: "95b3c06c-12c7-4279-963d-5939eca73045",
        return_at_close: 1.005216506186555,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 65542.53,
        close_ms: 1711293083729,
        current_return: 0.9998172331766869,
        initial_entry_price: 65542.53,
        is_closed_position: true,
        miner_hotkey: "5DCPidFwEmBbBdgVZthUo6cZYR3JT1TonGgBXGx5DsqpLrmG",
        net_leverage: 0.0,
        open_ms: 1711291281685,
        orders: [
          {
            leverage: -1.1,
            order_type: "SHORT",
            order_uuid: "35ff14bd-342f-47da-9090-f65c451a2c70",
            price: 65542.53,
            price_sources: [],
            processed_ms: 1711291281683,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "99c9c9a9-416e-4d6b-9db2-b0e43a539f13",
            price: 65553.42,
            price_sources: [],
            processed_ms: 1711293083729,
          },
        ],
        position_type: "FLAT",
        position_uuid: "2bf0b3fa-ed8a-4774-9efa-7dd0f26da45e",
        return_at_close: 0.9976176352636982,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 65596.74,
        close_ms: 1711296764933,
        current_return: 0.9978213551466125,
        initial_entry_price: 65596.74,
        is_closed_position: true,
        miner_hotkey: "5DCPidFwEmBbBdgVZthUo6cZYR3JT1TonGgBXGx5DsqpLrmG",
        net_leverage: 0.0,
        open_ms: 1711294921071,
        orders: [
          {
            leverage: -1.1,
            order_type: "SHORT",
            order_uuid: "e1819098-23bf-4979-8565-59406b68c38c",
            price: 65596.74,
            price_sources: [],
            processed_ms: 1711294921069,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "1091bd7a-3c4b-481f-9bd5-caeefdd6cb8e",
            price: 65726.66,
            price_sources: [],
            processed_ms: 1711296764933,
          },
        ],
        position_type: "FLAT",
        position_uuid: "591dd17d-4021-414d-8086-cd804fa925d2",
        return_at_close: 0.99562614816529,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 64690.83769230767,
        close_ms: 1711311308056,
        current_return: 0.9667669028824196,
        initial_entry_price: 64853.14,
        is_closed_position: true,
        miner_hotkey: "5DCPidFwEmBbBdgVZthUo6cZYR3JT1TonGgBXGx5DsqpLrmG",
        net_leverage: 0.0,
        open_ms: 1711300415918,
        orders: [
          {
            leverage: -1.1,
            order_type: "SHORT",
            order_uuid: "0ab0a9da-dd05-4da4-9f2b-7ef73e095327",
            price: 64853.14,
            price_sources: [],
            processed_ms: 1711300415917,
          },
          {
            leverage: -1.1,
            order_type: "SHORT",
            order_uuid: "71970f81-4daa-4c58-b0ff-7aecc9897d48",
            price: 65327.8,
            price_sources: [],
            processed_ms: 1711302255249,
          },
          {
            leverage: 0.10999999999999988,
            order_type: "LONG",
            order_uuid: "d63f81c9-c74b-42b7-889c-db4107be11f6",
            price: 65993.16,
            price_sources: [],
            processed_ms: 1711305338660,
          },
          {
            leverage: 0.1100000000000001,
            order_type: "LONG",
            order_uuid: "aa3e9726-c2b8-4730-a4a1-2f2ffe49e6ae",
            price: 65701.59,
            price_sources: [],
            processed_ms: 1711306282531,
          },
          {
            leverage: 0.1100000000000001,
            order_type: "LONG",
            order_uuid: "a3f24eb8-03c5-4c1c-b849-5a9b2a114703",
            price: 65777.0,
            price_sources: [],
            processed_ms: 1711307204891,
          },
          {
            leverage: 0.1100000000000001,
            order_type: "LONG",
            order_uuid: "57f27e24-96b3-44de-8143-fcbd2f22245e",
            price: 65864.72,
            price_sources: [],
            processed_ms: 1711308136635,
          },
          {
            leverage: 0.1100000000000001,
            order_type: "LONG",
            order_uuid: "cb90a679-e9fa-439f-88c9-752f671bf0f6",
            price: 65846.34,
            price_sources: [],
            processed_ms: 1711309064623,
          },
          {
            leverage: 0.1100000000000001,
            order_type: "LONG",
            order_uuid: "d992af41-47a9-4bd2-ad8b-1cef0f6d99d3",
            price: 65888.87,
            price_sources: [],
            processed_ms: 1711310021537,
          },
          {
            leverage: 0.1100000000000001,
            order_type: "LONG",
            order_uuid: "9219d410-ee21-442f-b5d5-d16d931a8971",
            price: 65756.83,
            price_sources: [],
            processed_ms: 1711310936808,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "43a2c1c3-1c90-4b18-9c58-6d613d00a01a",
            price: 66198.02,
            price_sources: [],
            processed_ms: 1711311308056,
          },
        ],
        position_type: "FLAT",
        position_uuid: "157e4424-04cd-4683-97ce-8195ab91a421",
        return_at_close: 0.962513128509737,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 119896.20666665043,
        close_ms: 1711324027145,
        current_return: 1.00102188058953,
        initial_entry_price: 66413.23,
        is_closed_position: true,
        miner_hotkey: "5DCPidFwEmBbBdgVZthUo6cZYR3JT1TonGgBXGx5DsqpLrmG",
        net_leverage: 0.0,
        open_ms: 1711311868619,
        orders: [
          {
            leverage: -1.1,
            order_type: "SHORT",
            order_uuid: "56de6a39-b451-4d6b-a0bc-d5614836ed61",
            price: 66413.23,
            price_sources: [],
            processed_ms: 1711311868617,
          },
          {
            leverage: 0.10999999999999999,
            order_type: "LONG",
            order_uuid: "ca8b187f-517d-4c58-8e0b-2bf8ce16528f",
            price: 66211.26,
            price_sources: [],
            processed_ms: 1711312809074,
          },
          {
            leverage: 0.10999999999999999,
            order_type: "LONG",
            order_uuid: "79849ea6-60fe-4a51-8815-6fd6034c609c",
            price: 66229.38,
            price_sources: [],
            processed_ms: 1711313733628,
          },
          {
            leverage: 0.10999999999999999,
            order_type: "LONG",
            order_uuid: "7e9c95ec-05cb-4816-9907-d0446b59a21e",
            price: 66353.0,
            price_sources: [],
            processed_ms: 1711314669587,
          },
          {
            leverage: 0.38500000000000006,
            order_type: "LONG",
            order_uuid: "52546608-a2b9-4b6a-a5f7-da9cd8dfca0e",
            price: 66259.9,
            price_sources: [],
            processed_ms: 1711315603684,
          },
          {
            leverage: 0.19250000000000003,
            order_type: "LONG",
            order_uuid: "f5076ef0-b705-4fc8-9a13-ed380d0b87a7",
            price: 66368.69,
            price_sources: [],
            processed_ms: 1711316535738,
          },
          {
            leverage: 0.11000000000000001,
            order_type: "LONG",
            order_uuid: "f37fec82-adbb-4df1-abbb-7ce7ff8eec5f",
            price: 66479.99,
            price_sources: [],
            processed_ms: 1711317466906,
          },
          {
            leverage: 0.04125000000000001,
            order_type: "LONG",
            order_uuid: "52eb23be-d4f2-481c-8835-670766e8f72e",
            price: 66821.21,
            price_sources: [],
            processed_ms: 1711318397851,
          },
          {
            leverage: 0.020625000000000004,
            order_type: "LONG",
            order_uuid: "6310787e-f230-4e9f-872c-172ca4d48a5e",
            price: 66962.22,
            price_sources: [],
            processed_ms: 1711319332081,
          },
          {
            leverage: 0.010312500000000002,
            order_type: "LONG",
            order_uuid: "5fcf19b5-ed91-4968-9efc-aa02789fd812",
            price: 66931.54,
            price_sources: [],
            processed_ms: 1711320275246,
          },
          {
            leverage: 0.005156250000000001,
            order_type: "LONG",
            order_uuid: "f6593ce1-f683-4a27-9431-ce3567477db2",
            price: 66953.69,
            price_sources: [],
            processed_ms: 1711321199844,
          },
          {
            leverage: 0.0025781250000000006,
            order_type: "LONG",
            order_uuid: "d5c26fcd-e285-409a-92d5-101a24de0e4e",
            price: 67537.85,
            price_sources: [],
            processed_ms: 1711322127964,
          },
          {
            leverage: 0.0012890625000000003,
            order_type: "LONG",
            order_uuid: "5ad30535-9d2c-43f9-b095-5da8d994040a",
            price: 67345.44,
            price_sources: [],
            processed_ms: 1711323068177,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "03f5c829-658b-42ba-bd29-c9c6ee58f835",
            price: 67248.34,
            price_sources: [],
            processed_ms: 1711324027145,
          },
        ],
        position_type: "FLAT",
        position_uuid: "05e0c4ec-b1bd-49f0-a05a-b63eb9f3c3cd",
        return_at_close: 0.9988196324522329,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
    ],
    thirty_day_returns: 1.0,
  },
  "5DCm4HsUpL27Lbr3M45L7DzMXXD4NTVrKH7cEEKre9gw42nE": {
    all_time_returns: 1.0,
    n_positions: 0,
    percentage_profitable: 0.0,
    positions: [
      {
        average_entry_price: 3204.065525606469,
        close_ms: 0,
        current_return: 0.9926294922609233,
        initial_entry_price: 3378.31,
        is_closed_position: false,
        miner_hotkey: "5DCm4HsUpL27Lbr3M45L7DzMXXD4NTVrKH7cEEKre9gw42nE",
        net_leverage: 0.371,
        open_ms: 1719600511096,
        orders: [
          {
            leverage: 0.001,
            order_type: "LONG",
            order_uuid: "4396ed42-480a-45b0-b9f6-00be5c4bfcf7",
            price: 3378.31,
            price_sources: [],
            processed_ms: 1719600509781,
          },
          {
            leverage: 0.07,
            order_type: "LONG",
            order_uuid: "dd9d590c-49c6-436b-a725-8d9048754b22",
            price: 3379.2,
            price_sources: [],
            processed_ms: 1719600658280,
          },
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "b1c0063e-bd29-4f25-8827-0b25042a91ae",
            price: 3162.62,
            price_sources: [
              {
                close: 3162.62,
                high: 3162.86,
                lag_ms: 201,
                low: 3162.62,
                open: 3162.62,
                source: "Polygon_ws",
                start_ms: 1720083706000,
                timespan_ms: 0,
                volume: 2.56461566,
                vwap: 3162.8367,
                websocket: true,
              },
              {
                close: 3162.92,
                high: 3163.14,
                lag_ms: 3202,
                low: 3162.92,
                open: 3163.14,
                source: "Polygon_rest",
                start_ms: 1720083702000,
                timespan_ms: 1000,
                volume: 0.00024026,
                vwap: 3163.03,
                websocket: false,
              },
              {
                close: 3166.29,
                high: 3166.29,
                lag_ms: 3799,
                low: 3166.29,
                open: 3166.29,
                source: "TwelveData_ws",
                start_ms: 1720083710000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
              {
                close: 3162.96997,
                high: 3162.96997,
                lag_ms: 646202,
                low: 3159.32007,
                open: 3159.40991,
                source: "TwelveData_rest",
                start_ms: 1720083000000,
                timespan_ms: 60000,
                volume: null,
                vwap: null,
                websocket: false,
              },
            ],
            processed_ms: 1720083706201,
          },
        ],
        position_type: "LONG",
        position_uuid: "5f49d2ac-8370-4caa-8e82-da2979ee135c",
        return_at_close: 0.992445359490109,
        trade_pair: ["ETHUSD", "ETH/USD", 0.003, 0.001, 20],
      },
    ],
    thirty_day_returns: 1.0,
  },
  "5DCuhMEFomeuriLiMw1ieygqiNASbHxGPfqBicq79qatZp5Y": {
    all_time_returns: 1.0072116019399342,
    n_positions: 1,
    percentage_profitable: 1.0,
    positions: [
      {
        average_entry_price: 160.624,
        close_ms: 1719587779488,
        current_return: 1.0075642494272337,
        initial_entry_price: 160.624,
        is_closed_position: true,
        miner_hotkey: "5DCuhMEFomeuriLiMw1ieygqiNASbHxGPfqBicq79qatZp5Y",
        net_leverage: 0.0,
        open_ms: 1719577073168,
        orders: [
          {
            leverage: 5.0,
            order_type: "LONG",
            order_uuid: "b601a6ea-eaf8-4bea-8af7-1ffc4dad1306",
            price: 160.624,
            price_sources: [],
            processed_ms: 1719577073158,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "4e46eef5-0768-4784-860c-0327da96e98b",
            price: 160.867,
            price_sources: [],
            processed_ms: 1719587779488,
          },
        ],
        position_type: "FLAT",
        position_uuid: "eecd8e87-ebf4-42ef-b528-7938d3d68beb",
        return_at_close: 1.0072116019399342,
        trade_pair: ["USDJPY", "USD/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 64233.96000000001,
        close_ms: 0,
        current_return: 0.9905081984669791,
        initial_entry_price: 64233.96,
        is_closed_position: false,
        miner_hotkey: "5DCuhMEFomeuriLiMw1ieygqiNASbHxGPfqBicq79qatZp5Y",
        net_leverage: 0.1,
        open_ms: 1719065264254,
        orders: [
          {
            leverage: 0.1,
            order_type: "LONG",
            order_uuid: "08dbc13c-c9e0-4cf1-ac4e-7ac4df9a860f",
            price: 64233.96,
            price_sources: [],
            processed_ms: 1719065261493,
          },
        ],
        position_type: "LONG",
        position_uuid: "20a3af4e-d64f-41bc-9a6a-8457ebe2e908",
        return_at_close: 0.9904586730570558,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
    ],
    thirty_day_returns: 1.0072116019399342,
  },
  "5DCzvCF22vTVhXLtGrd7dBy19iFKKJNxmdSp5uo4C4v6Xx6h": {
    all_time_returns: 1.0018874390499648,
    n_positions: 2,
    percentage_profitable: 0.5,
    positions: [
      {
        average_entry_price: 61649.0,
        close_ms: 1719572651816,
        current_return: 0.9997242453243361,
        initial_entry_price: 61649.0,
        is_closed_position: true,
        miner_hotkey: "5DCzvCF22vTVhXLtGrd7dBy19iFKKJNxmdSp5uo4C4v6Xx6h",
        net_leverage: 0.0,
        open_ms: 1719572401590,
        orders: [
          {
            leverage: 1.0,
            order_type: "LONG",
            order_uuid: "6f9b87ff-0313-4ec6-b24b-567148075d11",
            price: 61649.0,
            price_sources: [],
            processed_ms: 1719572400797,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "1b20df3b-a2c2-40d0-87c7-bb6f7dcffbbb",
            price: 61632.0,
            price_sources: [],
            processed_ms: 1719572651816,
          },
        ],
        position_type: "FLAT",
        position_uuid: "66608717-e4e9-4fe5-b37f-e6fa34595b66",
        return_at_close: 0.9987245210790118,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 160.594,
        close_ms: 1719578148600,
        current_return: 1.0035181887243607,
        initial_entry_price: 160.594,
        is_closed_position: true,
        miner_hotkey: "5DCzvCF22vTVhXLtGrd7dBy19iFKKJNxmdSp5uo4C4v6Xx6h",
        net_leverage: 0.0,
        open_ms: 1719577190812,
        orders: [
          {
            leverage: -5.0,
            order_type: "SHORT",
            order_uuid: "e82dc2ef-71db-4b30-aa06-83bf48dfd703",
            price: 160.594,
            price_sources: [],
            processed_ms: 1719577190804,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "348a8b93-9023-4bb3-a4f8-ba619b182cea",
            price: 160.481,
            price_sources: [],
            processed_ms: 1719578148600,
          },
        ],
        position_type: "FLAT",
        position_uuid: "72ca70b5-ba38-4f85-b0e5-af2eabdb6a3a",
        return_at_close: 1.0031669573583073,
        trade_pair: ["USDJPY", "USD/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 3377.07,
        close_ms: 0,
        current_return: 1.009527809610106,
        initial_entry_price: 3377.07,
        is_closed_position: false,
        miner_hotkey: "5DCzvCF22vTVhXLtGrd7dBy19iFKKJNxmdSp5uo4C4v6Xx6h",
        net_leverage: -0.134,
        open_ms: 1718389522607,
        orders: [
          {
            leverage: -0.134,
            order_type: "SHORT",
            order_uuid: "8fc50f96-4b0a-460a-9b16-8c7943d8c70d",
            price: 3377.07,
            price_sources: [],
            processed_ms: 1718389522323,
          },
        ],
        position_type: "SHORT",
        position_uuid: "4c7996f2-5b6b-49c8-9ec1-dc8bd4a770f9",
        return_at_close: 1.009460171246862,
        trade_pair: ["ETHUSD", "ETH/USD", 0.003, 0.001, 20],
      },
    ],
    thirty_day_returns: 1.0018874390499648,
  },
  "5DDBXwjobdGeiM3svUPUogVB6T3YVNC7nmin9p7EQGBrK2hA": {
    all_time_returns: 1.0471466129677047,
    n_positions: 13,
    percentage_profitable: 0.6923076923076923,
    positions: [
      {
        average_entry_price: 0.8957200128603021,
        close_ms: 1714410432859,
        current_return: 1.000118339115402,
        initial_entry_price: 0.895720012860302,
        is_closed_position: true,
        miner_hotkey: "5DDBXwjobdGeiM3svUPUogVB6T3YVNC7nmin9p7EQGBrK2hA",
        net_leverage: 0.0,
        open_ms: 1714406267399,
        orders: [
          {
            leverage: 0.1,
            order_type: "LONG",
            order_uuid: "c07a2562-7a4b-4469-82a8-62c1fc2ad43f",
            price: 0.895720012860302,
            price_sources: [],
            processed_ms: 1714406267399,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "22d899c7-18b8-411a-bc76-b38b6919cb08",
            price: 0.89678,
            price_sources: [],
            processed_ms: 1714410432859,
          },
        ],
        position_type: "FLAT",
        position_uuid: "ba7580a7-087b-4a0d-9fce-81f4f39ac95a",
        return_at_close: 1.0001113382870284,
        trade_pair: ["AUDCAD", "AUD/CAD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.9529999999999946,
        close_ms: 1714962973929,
        current_return: 1.000444512195122,
        initial_entry_price: 0.656,
        is_closed_position: true,
        miner_hotkey: "5DDBXwjobdGeiM3svUPUogVB6T3YVNC7nmin9p7EQGBrK2hA",
        net_leverage: 0.0,
        open_ms: 1714406285703,
        orders: [
          {
            leverage: -0.1,
            order_type: "SHORT",
            order_uuid: "09a6d540-466f-4aee-90c0-55b31e29372b",
            price: 0.656,
            price_sources: [],
            processed_ms: 1714406285702,
          },
          {
            leverage: 0.099,
            order_type: "LONG",
            order_uuid: "8a935eb1-0073-4448-bccc-42826360794f",
            price: 0.653,
            price_sources: [],
            processed_ms: 1714476422624,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "0fa9c4e8-9738-49e2-82e8-14cb57970f49",
            price: 0.6614,
            price_sources: [],
            processed_ms: 1714962973929,
          },
        ],
        position_type: "FLAT",
        position_uuid: "48f39184-5baa-4e69-b9a2-5079cd15e5b5",
        return_at_close: 1.0004375090835367,
        trade_pair: ["AUDUSD", "AUD/USD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.825099529496924,
        close_ms: 1715304348865,
        current_return: 1.0003024235113505,
        initial_entry_price: 0.825099529496924,
        is_closed_position: true,
        miner_hotkey: "5DDBXwjobdGeiM3svUPUogVB6T3YVNC7nmin9p7EQGBrK2hA",
        net_leverage: 0.0,
        open_ms: 1715304236369,
        orders: [
          {
            leverage: -1.0,
            order_type: "SHORT",
            order_uuid: "9a4fad26-bcca-4df4-943f-ed711cfe1e58",
            price: 0.825099529496924,
            price_sources: [],
            processed_ms: 1715304236166,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "f59ad29e-82f0-453b-bf0d-fb85c5b1eee5",
            price: 0.82485,
            price_sources: [],
            processed_ms: 1715304348865,
          },
        ],
        position_type: "FLAT",
        position_uuid: "3cabe182-0e51-42d9-a2dd-2f02b5c62427",
        return_at_close: 1.0002324023417046,
        trade_pair: ["NZDCAD", "NZD/CAD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 155.469,
        close_ms: 1715304872603,
        current_return: 1.000469546983643,
        initial_entry_price: 155.469,
        is_closed_position: true,
        miner_hotkey: "5DDBXwjobdGeiM3svUPUogVB6T3YVNC7nmin9p7EQGBrK2hA",
        net_leverage: 0.0,
        open_ms: 1715304198612,
        orders: [
          {
            leverage: 1.0,
            order_type: "LONG",
            order_uuid: "4777e054-e031-473c-b179-e939d8b47a3f",
            price: 155.469,
            price_sources: [],
            processed_ms: 1715304198403,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "1aedb7de-10d6-4454-9232-75bf3f19da58",
            price: 155.542,
            price_sources: [],
            processed_ms: 1715304872603,
          },
        ],
        position_type: "FLAT",
        position_uuid: "b7fcb9f8-f262-446b-8e04-1f1de383187a",
        return_at_close: 1.0003995141153543,
        trade_pair: ["USDJPY", "USD/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 113.694,
        close_ms: 1715306053418,
        current_return: 1.0267824159586267,
        initial_entry_price: 113.694,
        is_closed_position: true,
        miner_hotkey: "5DDBXwjobdGeiM3svUPUogVB6T3YVNC7nmin9p7EQGBrK2hA",
        net_leverage: 0.0,
        open_ms: 1715304766156,
        orders: [
          {
            leverage: 35.0,
            order_type: "LONG",
            order_uuid: "80f55b52-3415-480d-9638-e27c87bb9008",
            price: 113.694,
            price_sources: [],
            processed_ms: 1715304766152,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "c7b456fc-aa8f-4ae2-8d76-14a65ebb46c2",
            price: 113.781,
            price_sources: [],
            processed_ms: 1715306053418,
          },
        ],
        position_type: "FLAT",
        position_uuid: "e8ecbadd-a959-4925-a666-9c2c9d949f6f",
        return_at_close: 1.0242667990395282,
        trade_pair: ["CADJPY", "CAD/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 93.781,
        close_ms: 1715306069787,
        current_return: 1.0004371887695802,
        initial_entry_price: 93.781,
        is_closed_position: true,
        miner_hotkey: "5DDBXwjobdGeiM3svUPUogVB6T3YVNC7nmin9p7EQGBrK2hA",
        net_leverage: 0.0,
        open_ms: 1715305513511,
        orders: [
          {
            leverage: 1.0,
            order_type: "LONG",
            order_uuid: "51312b98-05cf-4452-8d16-b4564692dcac",
            price: 93.781,
            price_sources: [],
            processed_ms: 1715305513230,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "82b53aef-eedc-4031-bdc0-3df8a1adc54f",
            price: 93.822,
            price_sources: [],
            processed_ms: 1715306069787,
          },
        ],
        position_type: "FLAT",
        position_uuid: "5e15ba6f-31f2-41b5-884a-3d9fd2e27c56",
        return_at_close: 1.0003671581663662,
        trade_pair: ["NZDJPY", "NZD/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 155.61,
        close_ms: 1715306085969,
        current_return: 1.000186363344258,
        initial_entry_price: 155.61,
        is_closed_position: true,
        miner_hotkey: "5DDBXwjobdGeiM3svUPUogVB6T3YVNC7nmin9p7EQGBrK2hA",
        net_leverage: 0.0,
        open_ms: 1715305532541,
        orders: [
          {
            leverage: 1.0,
            order_type: "LONG",
            order_uuid: "bc0f20fe-77c0-4d9b-a19b-ba0dad29b9a3",
            price: 155.61,
            price_sources: [],
            processed_ms: 1715305532345,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "773e0b53-a6ca-4504-8558-533cffa75554",
            price: 155.639,
            price_sources: [],
            processed_ms: 1715306085969,
          },
        ],
        position_type: "FLAT",
        position_uuid: "212b5f7b-6afa-478d-af1b-975da558a9e4",
        return_at_close: 1.000116350298824,
        trade_pair: ["USDJPY", "USD/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.86084,
        close_ms: 1715306103901,
        current_return: 0.999953533757725,
        initial_entry_price: 0.86084,
        is_closed_position: true,
        miner_hotkey: "5DDBXwjobdGeiM3svUPUogVB6T3YVNC7nmin9p7EQGBrK2hA",
        net_leverage: 0.0,
        open_ms: 1715304314201,
        orders: [
          {
            leverage: 1.0,
            order_type: "LONG",
            order_uuid: "1be863aa-059d-4074-bd2f-4dc7c5ee2b02",
            price: 0.86084,
            price_sources: [],
            processed_ms: 1715304312355,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "73529436-ec3e-4e04-a5da-6e7d63127fbf",
            price: 0.8608,
            price_sources: [],
            processed_ms: 1715306103901,
          },
        ],
        position_type: "FLAT",
        position_uuid: "e68fd70c-5bd0-4e77-8236-a0a1ceb951af",
        return_at_close: 0.9998835370103619,
        trade_pair: ["EURGBP", "EUR/GBP", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 3126.35,
        close_ms: 1715306123755,
        current_return: 1.0000290018711917,
        initial_entry_price: 3126.35,
        is_closed_position: true,
        miner_hotkey: "5DDBXwjobdGeiM3svUPUogVB6T3YVNC7nmin9p7EQGBrK2hA",
        net_leverage: 0.0,
        open_ms: 1714841050539,
        orders: [
          {
            leverage: -0.001,
            order_type: "SHORT",
            order_uuid: "b94ec9a2-d334-45b1-9467-1daef0a987b1",
            price: 3126.35,
            price_sources: [],
            processed_ms: 1714841050322,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "5e1e4012-7cba-4fc7-86d1-9d5e1f7e7238",
            price: 3035.68,
            price_sources: [],
            processed_ms: 1715306123755,
          },
        ],
        position_type: "FLAT",
        position_uuid: "64e25870-c3c8-4219-93c2-4df782488165",
        return_at_close: 1.0000280018421899,
        trade_pair: ["ETHUSD", "ETH/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 1.47453,
        close_ms: 1715306165373,
        current_return: 0.9998847090259269,
        initial_entry_price: 1.47453,
        is_closed_position: true,
        miner_hotkey: "5DDBXwjobdGeiM3svUPUogVB6T3YVNC7nmin9p7EQGBrK2hA",
        net_leverage: 0.0,
        open_ms: 1715304219603,
        orders: [
          {
            leverage: 1.0,
            order_type: "LONG",
            order_uuid: "18739216-6b06-4123-bb9b-90d426fcf776",
            price: 1.47453,
            price_sources: [],
            processed_ms: 1715304219326,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "6a43a1c4-10d5-4936-ae13-e173c7c44f98",
            price: 1.47436,
            price_sources: [],
            processed_ms: 1715306165373,
          },
        ],
        position_type: "FLAT",
        position_uuid: "972eb639-784b-499b-9ff0-ac760683cf16",
        return_at_close: 0.999814717096295,
        trade_pair: ["EURCAD", "EUR/CAD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 1.0533000000000061,
        close_ms: 1718389770398,
        current_return: 0.9997647804270593,
        initial_entry_price: 1.36515,
        is_closed_position: true,
        miner_hotkey: "5DDBXwjobdGeiM3svUPUogVB6T3YVNC7nmin9p7EQGBrK2hA",
        net_leverage: 0.0,
        open_ms: 1714406305427,
        orders: [
          {
            leverage: -0.1,
            order_type: "SHORT",
            order_uuid: "25b2905a-3301-4a8c-917d-de73f878c9a2",
            price: 1.36515,
            price_sources: [],
            processed_ms: 1714406305426,
          },
          {
            leverage: 0.099,
            order_type: "LONG",
            order_uuid: "d1fffd0e-f6a0-4f25-bdc2-768c10f72261",
            price: 1.3683,
            price_sources: [],
            processed_ms: 1715318959568,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "4328605e-3f6e-4ece-b954-bab01c6b9d90",
            price: 1.3744100000000001,
            price_sources: [],
            processed_ms: 1718389770398,
          },
        ],
        position_type: "FLAT",
        position_uuid: "6033f4e9-333e-4e7d-8630-d85675648d1f",
        return_at_close: 0.9997577820735963,
        trade_pair: ["USDCAD", "USD/CAD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 113.65399999999671,
        close_ms: 1718389912747,
        current_return: 0.9996742303102625,
        initial_entry_price: 167.6,
        is_closed_position: true,
        miner_hotkey: "5DDBXwjobdGeiM3svUPUogVB6T3YVNC7nmin9p7EQGBrK2hA",
        net_leverage: 0.0,
        open_ms: 1715304329581,
        orders: [
          {
            leverage: -1.0,
            order_type: "SHORT",
            order_uuid: "83d9babc-abcc-491e-91a4-2750f00b1854",
            price: 167.6,
            price_sources: [],
            processed_ms: 1715304329067,
          },
          {
            leverage: 0.999,
            order_type: "LONG",
            order_uuid: "7e4def55-be8c-48e7-a2e2-2b6852a300ab",
            price: 167.654,
            price_sources: [],
            processed_ms: 1715318935052,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "f7ebe197-136b-4820-b470-9e8d371cd79b",
            price: 168.253,
            price_sources: [],
            processed_ms: 1718389912747,
          },
        ],
        position_type: "FLAT",
        position_uuid: "c5c8a027-7ce8-4d66-b3da-e31f76af5b1e",
        return_at_close: 0.9996042531141407,
        trade_pair: ["EURJPY", "EUR/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 2490.5906578947365,
        close_ms: 1718392185372,
        current_return: 1.0217233677086277,
        initial_entry_price: 3114.64,
        is_closed_position: true,
        miner_hotkey: "5DDBXwjobdGeiM3svUPUogVB6T3YVNC7nmin9p7EQGBrK2hA",
        net_leverage: 0.0,
        open_ms: 1716050734415,
        orders: [
          {
            leverage: 0.001,
            order_type: "LONG",
            order_uuid: "5ad9df5d-18b2-4eae-8b4c-1544f884ef20",
            price: 3114.64,
            price_sources: [],
            processed_ms: 1716050733570,
          },
          {
            leverage: 0.15,
            order_type: "LONG",
            order_uuid: "6bd737f3-9c42-4909-a2c7-0968d66f6016",
            price: 3145.74,
            price_sources: [],
            processed_ms: 1716229561740,
          },
          {
            leverage: -0.075,
            order_type: "SHORT",
            order_uuid: "aad4e141-804d-4e91-8680-5e00e0c1a640",
            price: 3809.21,
            price_sources: [],
            processed_ms: 1717158002811,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "ef3527ee-c8cf-4ecb-aa55-72746e5765bd",
            price: 3380.86,
            price_sources: [],
            processed_ms: 1718392185372,
          },
        ],
        position_type: "FLAT",
        position_uuid: "1b566b3b-f063-45a6-b0a4-4f0f90e5a18d",
        return_at_close: 1.0215690874801038,
        trade_pair: ["ETHUSD", "ETH/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 118.76399999997024,
        close_ms: 0,
        current_return: 0.9995435620069032,
        initial_entry_price: 194.688,
        is_closed_position: false,
        miner_hotkey: "5DDBXwjobdGeiM3svUPUogVB6T3YVNC7nmin9p7EQGBrK2hA",
        net_leverage: -0.0010000000000000009,
        open_ms: 1715304181284,
        orders: [
          {
            leverage: -1.0,
            order_type: "SHORT",
            order_uuid: "f05f4025-1e5f-4b0a-8575-575a7696b5d5",
            price: 194.688,
            price_sources: [],
            processed_ms: 1715304181099,
          },
          {
            leverage: 0.999,
            order_type: "LONG",
            order_uuid: "de8bec0d-723e-4c1c-b3f1-454e08034bad",
            price: 194.764,
            price_sources: [],
            processed_ms: 1715318916937,
          },
        ],
        position_type: "SHORT",
        position_uuid: "ba6e4a2e-167f-4574-90b1-8015d32b8961",
        return_at_close: 0.9994736289415874,
        trade_pair: ["GBPJPY", "GBP/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.9779400000000651,
        close_ms: 0,
        current_return: 0.999551593896359,
        initial_entry_price: 1.78713,
        is_closed_position: false,
        miner_hotkey: "5DDBXwjobdGeiM3svUPUogVB6T3YVNC7nmin9p7EQGBrK2hA",
        net_leverage: -0.0010000000000000009,
        open_ms: 1715304253372,
        orders: [
          {
            leverage: -1.0,
            order_type: "SHORT",
            order_uuid: "e3ef95e7-0c84-4390-9749-d84183a3060a",
            price: 1.78713,
            price_sources: [],
            processed_ms: 1715304253063,
          },
          {
            leverage: 0.999,
            order_type: "LONG",
            order_uuid: "01ebba6c-edb9-465d-a58b-68cc8dd9dd40",
            price: 1.78794,
            price_sources: [],
            processed_ms: 1715318900669,
          },
        ],
        position_type: "SHORT",
        position_uuid: "061a1540-fa6b-4567-8f68-4bb2de12f39d",
        return_at_close: 0.999481660269092,
        trade_pair: ["EURNZD", "EUR/NZD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 70994.2775,
        close_ms: 0,
        current_return: 0.9637067861227817,
        initial_entry_price: 70852.24,
        is_closed_position: false,
        miner_hotkey: "5DDBXwjobdGeiM3svUPUogVB6T3YVNC7nmin9p7EQGBrK2hA",
        net_leverage: 0.2,
        open_ms: 1717582547636,
        orders: [
          {
            leverage: 0.15,
            order_type: "LONG",
            order_uuid: "afee9886-ed88-4a6f-bc94-75f86ce45401",
            price: 70852.24,
            price_sources: [],
            processed_ms: 1717582547436,
          },
          {
            leverage: 0.05,
            order_type: "LONG",
            order_uuid: "b718e32c-5580-4234-adf9-2c1a891f3a2b",
            price: 71420.39,
            price_sources: [],
            processed_ms: 1717606032161,
          },
        ],
        position_type: "LONG",
        position_uuid: "5a1c8d69-f8db-4d87-915f-70943f172aa1",
        return_at_close: 0.9636104154441695,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 1.71182000000014,
        close_ms: 0,
        current_return: 0.9996583990800778,
        initial_entry_price: 1.25228,
        is_closed_position: false,
        miner_hotkey: "5DDBXwjobdGeiM3svUPUogVB6T3YVNC7nmin9p7EQGBrK2hA",
        net_leverage: 0.0010000000000000009,
        open_ms: 1715304163798,
        orders: [
          {
            leverage: 1.0,
            order_type: "LONG",
            order_uuid: "eb943236-cfec-4a30-9261-8c32fcd2672b",
            price: 1.25228,
            price_sources: [],
            processed_ms: 1715304162449,
          },
          {
            leverage: -0.999,
            order_type: "SHORT",
            order_uuid: "c3ebe768-3b25-49a1-8d86-e2efe1f52cf1",
            price: 1.25182,
            price_sources: [],
            processed_ms: 1715318998166,
          },
        ],
        position_type: "LONG",
        position_uuid: "726b88bb-efc6-410f-ba88-36f72ed91a7e",
        return_at_close: 0.9995884579801861,
        trade_pair: ["GBPUSD", "GBP/USD", 7e-5, 0.001, 500],
      },
    ],
    thirty_day_returns: 1.0,
  },
  "5DFyZirdqUvC7Y1CwrLR71SBShxFTpd5Bb2fsGmppFQMN8Tt": {
    all_time_returns: 1.0,
    n_positions: 0,
    percentage_profitable: 0.0,
    positions: [
      {
        average_entry_price: 63766.07,
        close_ms: 1711140116244,
        current_return: 1.002695163744606,
        initial_entry_price: 63766.07,
        is_closed_position: true,
        miner_hotkey: "5DFyZirdqUvC7Y1CwrLR71SBShxFTpd5Bb2fsGmppFQMN8Tt",
        net_leverage: 0.0,
        open_ms: 1711133947049,
        orders: [
          {
            leverage: -1.0,
            order_type: "SHORT",
            order_uuid: "2fdd8acb-8ea9-4efa-9eef-80d0d6aee41e",
            price: 63766.07,
            price_sources: [],
            processed_ms: 1711133947049,
          },
          {
            leverage: 2.0,
            order_type: "FLAT",
            order_uuid: "667e7e56-c8f0-4280-a1f9-7e937e23f295",
            price: 63594.21,
            price_sources: [],
            processed_ms: 1711140116244,
          },
        ],
        position_type: "FLAT",
        position_uuid: "3e49a181-4bd5-482b-b3f4-bfe1d615d77e",
        return_at_close: 1.0006897734171167,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 64109.86,
        close_ms: 1711164069071,
        current_return: 1.016730343819188,
        initial_entry_price: 64109.86,
        is_closed_position: true,
        miner_hotkey: "5DFyZirdqUvC7Y1CwrLR71SBShxFTpd5Bb2fsGmppFQMN8Tt",
        net_leverage: 0.0,
        open_ms: 1711157747352,
        orders: [
          {
            leverage: -2.0,
            order_type: "SHORT",
            order_uuid: "b640db37-35e1-4ae8-a90e-c7d4cdd991c7",
            price: 64109.86,
            price_sources: [],
            processed_ms: 1711157747351,
          },
          {
            leverage: 2.0,
            order_type: "FLAT",
            order_uuid: "a6cac9dc-0473-40ca-9504-052384abf8a7",
            price: 63573.57,
            price_sources: [],
            processed_ms: 1711164069071,
          },
        ],
        position_type: "FLAT",
        position_uuid: "bda4e978-6122-42e7-b3e8-b5b0b435a654",
        return_at_close: 1.012663422443911,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 3358.14,
        close_ms: 1711251245059,
        current_return: 1.0046275616859333,
        initial_entry_price: 3358.14,
        is_closed_position: true,
        miner_hotkey: "5DFyZirdqUvC7Y1CwrLR71SBShxFTpd5Bb2fsGmppFQMN8Tt",
        net_leverage: 0.0,
        open_ms: 1711241487805,
        orders: [
          {
            leverage: -0.4,
            order_type: "SHORT",
            order_uuid: "fa55ab5c-89de-427c-8cdb-6c8218e744ae",
            price: 3358.14,
            price_sources: [],
            processed_ms: 1711241487804,
          },
          {
            leverage: 0.4,
            order_type: "FLAT",
            order_uuid: "b5c4f8e7-2fd5-4d30-9e03-85882876935f",
            price: 3319.29,
            price_sources: [],
            processed_ms: 1711251245059,
          },
        ],
        position_type: "FLAT",
        position_uuid: "8af8a931-d798-4a12-b46a-ed02670ef1d3",
        return_at_close: 1.0038238596365845,
        trade_pair: ["ETHUSD", "ETH/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 64562.670000000006,
        close_ms: 1711251379247,
        current_return: 1.0216241056945137,
        initial_entry_price: 64562.67,
        is_closed_position: true,
        miner_hotkey: "5DFyZirdqUvC7Y1CwrLR71SBShxFTpd5Bb2fsGmppFQMN8Tt",
        net_leverage: 0.0,
        open_ms: 1711194093557,
        orders: [
          {
            leverage: -3.0,
            order_type: "SHORT",
            order_uuid: "bcac6a0f-5ac7-4792-9b2f-4720ca30d1a9",
            price: 64562.67,
            price_sources: [],
            processed_ms: 1711194093556,
          },
          {
            leverage: 3.0,
            order_type: "FLAT",
            order_uuid: "217d8dc9-7143-4115-93f7-06eb5f373f9b",
            price: 64097.3,
            price_sources: [],
            processed_ms: 1711251379247,
          },
        ],
        position_type: "FLAT",
        position_uuid: "71fef66f-ccd2-41f2-81c2-13d689715398",
        return_at_close: 1.0154943610603466,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 3397.8,
        close_ms: 1711300288273,
        current_return: 1.0175643063158515,
        initial_entry_price: 3397.8,
        is_closed_position: true,
        miner_hotkey: "5DFyZirdqUvC7Y1CwrLR71SBShxFTpd5Bb2fsGmppFQMN8Tt",
        net_leverage: 0.0,
        open_ms: 1711289783610,
        orders: [
          {
            leverage: -1.0,
            order_type: "SHORT",
            order_uuid: "adab7445-948b-49b0-a537-e2f38dc42fa3",
            price: 3397.8,
            price_sources: [],
            processed_ms: 1711289783609,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "86fbbf52-7253-4eff-b6b7-eef88a985c13",
            price: 3338.12,
            price_sources: [],
            processed_ms: 1711300288273,
          },
        ],
        position_type: "FLAT",
        position_uuid: "2d8bdd05-0a71-4bac-829e-12661f8669ae",
        return_at_close: 1.0155291777032198,
        trade_pair: ["ETHUSD", "ETH/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 3541.96,
        close_ms: 1711386106352,
        current_return: 0.8957917608975212,
        initial_entry_price: 3382.65,
        is_closed_position: true,
        miner_hotkey: "5DFyZirdqUvC7Y1CwrLR71SBShxFTpd5Bb2fsGmppFQMN8Tt",
        net_leverage: -5.0,
        open_ms: 1711303932188,
        orders: [
          {
            leverage: -1.0,
            order_type: "SHORT",
            order_uuid: "d0f72067-f5b6-429d-9a74-c843b054e34a",
            price: 3382.65,
            price_sources: [],
            processed_ms: 1711303932187,
          },
          {
            leverage: -1.0,
            order_type: "SHORT",
            order_uuid: "7a7e6f31-9950-447d-8bf2-f0f0dd421269",
            price: 3557.98,
            price_sources: [],
            processed_ms: 1711378677204,
          },
          {
            leverage: -1.0,
            order_type: "SHORT",
            order_uuid: "71b04d24-52bf-4399-b6a8-4b8d880722a5",
            price: 3575.97,
            price_sources: [],
            processed_ms: 1711379333856,
          },
          {
            leverage: -1.0,
            order_type: "SHORT",
            order_uuid: "2930e857-7a05-4347-887b-f2e8b10fb1dc",
            price: 3596.83,
            price_sources: [],
            processed_ms: 1711384782059,
          },
          {
            leverage: -1.0,
            order_type: "SHORT",
            order_uuid: "d88c44c4-9214-41e9-b8ce-f4c01327fe0a",
            price: 3596.37,
            price_sources: [],
            processed_ms: 1711385170403,
          },
        ],
        position_type: "FLAT",
        position_uuid: "dddb446d-5f09-4b7f-a474-ca4e908983be",
        return_at_close: 0.886833843288546,
        trade_pair: ["ETHUSD", "ETH/USD", 0.003, 0.001, 20],
      },
    ],
    thirty_day_returns: 1.0,
  },
  "5DLzS15wTh52PnQUputdvcLWss7cpwNXLkiaoFT11piTzxTn": {
    all_time_returns: 1.0,
    n_positions: 0,
    percentage_profitable: 0.0,
    positions: [
      {
        average_entry_price: 64512.61,
        close_ms: 1711196317464,
        current_return: 1.0003080328016491,
        initial_entry_price: 64512.61,
        is_closed_position: true,
        miner_hotkey: "5DLzS15wTh52PnQUputdvcLWss7cpwNXLkiaoFT11piTzxTn",
        net_leverage: 0.0,
        open_ms: 1711192785222,
        orders: [
          {
            leverage: 0.6,
            order_type: "LONG",
            order_uuid: "6db9c5a2-456c-4c5a-a8d3-b0578c2f2aec",
            price: 64512.61,
            price_sources: [],
            processed_ms: 1711192785222,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "d17bbcd8-001a-4a74-a382-270e0d96a655",
            price: 64545.73,
            price_sources: [],
            processed_ms: 1711196317464,
          },
        ],
        position_type: "FLAT",
        position_uuid: "5b3cefff-8de5-4d58-9ced-279b91df71f4",
        return_at_close: 0.9991076631622872,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 65180.54,
        close_ms: 1711214626531,
        current_return: 0.9963773850293355,
        initial_entry_price: 65180.54,
        is_closed_position: true,
        miner_hotkey: "5DLzS15wTh52PnQUputdvcLWss7cpwNXLkiaoFT11piTzxTn",
        net_leverage: 0.0,
        open_ms: 1711211079002,
        orders: [
          {
            leverage: -0.6,
            order_type: "SHORT",
            order_uuid: "c9074643-ce7f-4d52-bce1-e831f2886764",
            price: 65180.54,
            price_sources: [],
            processed_ms: 1711211079001,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "cd306b49-33cd-4105-bfb5-6e8ac2de172a",
            price: 65574.08,
            price_sources: [],
            processed_ms: 1711214626531,
          },
        ],
        position_type: "FLAT",
        position_uuid: "be84df8c-ca53-46ea-a416-eff4442237fd",
        return_at_close: 0.9951817321673003,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 65516.35,
        close_ms: 1711218288436,
        current_return: 0.9999414802564551,
        initial_entry_price: 65516.35,
        is_closed_position: true,
        miner_hotkey: "5DLzS15wTh52PnQUputdvcLWss7cpwNXLkiaoFT11piTzxTn",
        net_leverage: 0.0,
        open_ms: 1711214741258,
        orders: [
          {
            leverage: -0.6,
            order_type: "SHORT",
            order_uuid: "65d03473-0a48-45b6-bb1f-25e90d15fab5",
            price: 65516.35,
            price_sources: [],
            processed_ms: 1711214741258,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "e679a0fe-3e01-426c-a003-bcc61d985c6c",
            price: 65522.74,
            price_sources: [],
            processed_ms: 1711218288436,
          },
        ],
        position_type: "FLAT",
        position_uuid: "2fa1af27-6869-4aa9-8205-30484291d31e",
        return_at_close: 0.9987415504801475,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 65514.86,
        close_ms: 1711221954124,
        current_return: 1.0019536331146857,
        initial_entry_price: 65514.86,
        is_closed_position: true,
        miner_hotkey: "5DLzS15wTh52PnQUputdvcLWss7cpwNXLkiaoFT11piTzxTn",
        net_leverage: 0.0,
        open_ms: 1711218411857,
        orders: [
          {
            leverage: -0.6,
            order_type: "SHORT",
            order_uuid: "00bccdb3-3170-4fa5-8c3e-f96830e49d91",
            price: 65514.86,
            price_sources: [],
            processed_ms: 1711218411856,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "25a3908a-82f6-45a2-869c-180b452a281a",
            price: 65301.54,
            price_sources: [],
            processed_ms: 1711221954124,
          },
        ],
        position_type: "FLAT",
        position_uuid: "b40862dc-12f5-4dec-a7c4-bf790c5866a0",
        return_at_close: 1.0007512887549481,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 65159.530000000006,
        close_ms: 1711225606381,
        current_return: 1.0019387800986288,
        initial_entry_price: 65159.53,
        is_closed_position: true,
        miner_hotkey: "5DLzS15wTh52PnQUputdvcLWss7cpwNXLkiaoFT11piTzxTn",
        net_leverage: 0.0,
        open_ms: 1711222059273,
        orders: [
          {
            leverage: -0.6,
            order_type: "SHORT",
            order_uuid: "39c84ada-e6f9-4346-982c-cb34b2db2a31",
            price: 65159.53,
            price_sources: [],
            processed_ms: 1711222059272,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "3d883d89-f8bf-4807-90f1-d07b5f479c90",
            price: 64948.98,
            price_sources: [],
            processed_ms: 1711225606381,
          },
        ],
        position_type: "FLAT",
        position_uuid: "6908c22c-44fa-47c7-ba83-dae54aa3db9b",
        return_at_close: 1.0007364535625105,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 64922.59,
        close_ms: 1711229272330,
        current_return: 1.0005887935154774,
        initial_entry_price: 64922.59,
        is_closed_position: true,
        miner_hotkey: "5DLzS15wTh52PnQUputdvcLWss7cpwNXLkiaoFT11piTzxTn",
        net_leverage: 0.0,
        open_ms: 1711225716361,
        orders: [
          {
            leverage: -0.6,
            order_type: "SHORT",
            order_uuid: "92ac6620-fcba-493f-b2ad-5b3a9e97c7d6",
            price: 64922.59,
            price_sources: [],
            processed_ms: 1711225716360,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "18482db6-69a8-407b-a80c-e976852a4bef",
            price: 64858.88,
            price_sources: [],
            processed_ms: 1711229272330,
          },
        ],
        position_type: "FLAT",
        position_uuid: "5a387e6f-a96f-4843-bdc9-3e0fad67435a",
        return_at_close: 0.9993880869632588,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 64901.42,
        close_ms: 1711232929155,
        current_return: 0.9996362175126523,
        initial_entry_price: 64901.42,
        is_closed_position: true,
        miner_hotkey: "5DLzS15wTh52PnQUputdvcLWss7cpwNXLkiaoFT11piTzxTn",
        net_leverage: 0.0,
        open_ms: 1711229400327,
        orders: [
          {
            leverage: -0.6,
            order_type: "SHORT",
            order_uuid: "96a6ef6b-0212-4ced-806a-506ff3978d60",
            price: 64901.42,
            price_sources: [],
            processed_ms: 1711229400326,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "904e8280-9a42-41cf-8127-df5248a67c34",
            price: 64940.77,
            price_sources: [],
            processed_ms: 1711232929155,
          },
        ],
        position_type: "FLAT",
        position_uuid: "5127b8b8-00c2-4afc-aaa7-6bbcab1189b8",
        return_at_close: 0.9984366540516372,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 64948.6,
        close_ms: 1711236598408,
        current_return: 1.0042453570977665,
        initial_entry_price: 64948.6,
        is_closed_position: true,
        miner_hotkey: "5DLzS15wTh52PnQUputdvcLWss7cpwNXLkiaoFT11piTzxTn",
        net_leverage: 0.0,
        open_ms: 1711233015082,
        orders: [
          {
            leverage: -0.6,
            order_type: "SHORT",
            order_uuid: "aafac8a3-3a4c-4963-a9a3-3c8e1a52bc20",
            price: 64948.6,
            price_sources: [],
            processed_ms: 1711233015081,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "06e10185-bcab-4919-a983-6cf402426189",
            price: 64489.05,
            price_sources: [],
            processed_ms: 1711236598408,
          },
        ],
        position_type: "FLAT",
        position_uuid: "57867f0d-39f9-4332-ab27-c51efb95f133",
        return_at_close: 1.0030402626692492,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 64418.26,
        close_ms: 1711240253535,
        current_return: 1.000329068186567,
        initial_entry_price: 64418.26,
        is_closed_position: true,
        miner_hotkey: "5DLzS15wTh52PnQUputdvcLWss7cpwNXLkiaoFT11piTzxTn",
        net_leverage: 0.0,
        open_ms: 1711236686117,
        orders: [
          {
            leverage: -0.6,
            order_type: "SHORT",
            order_uuid: "0c4e72c1-951e-40dc-8a20-fd1935fa6da1",
            price: 64418.26,
            price_sources: [],
            processed_ms: 1711236686116,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "58190e73-2d44-48ac-af6e-5cda1deea5e7",
            price: 64382.93,
            price_sources: [],
            processed_ms: 1711240253535,
          },
        ],
        position_type: "FLAT",
        position_uuid: "d2f74acb-ebaf-451f-9e9e-e38edb7aa074",
        return_at_close: 0.9991286733047432,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 64365.46,
        close_ms: 1711243921155,
        current_return: 1.0019956976925202,
        initial_entry_price: 64365.46,
        is_closed_position: true,
        miner_hotkey: "5DLzS15wTh52PnQUputdvcLWss7cpwNXLkiaoFT11piTzxTn",
        net_leverage: 0.0,
        open_ms: 1711240355375,
        orders: [
          {
            leverage: 0.6,
            order_type: "LONG",
            order_uuid: "d147dd91-ce05-4fb4-92a5-96939190be23",
            price: 64365.46,
            price_sources: [],
            processed_ms: 1711240355374,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "ba3cf7c3-e8d7-4f8e-b026-d8898f76116e",
            price: 64579.55,
            price_sources: [],
            processed_ms: 1711243921155,
          },
        ],
        position_type: "FLAT",
        position_uuid: "a1d0debe-35aa-4c31-9820-a59a6804ca8e",
        return_at_close: 1.0007933028552891,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 64313.32,
        close_ms: 1711251238434,
        current_return: 0.9983208144129396,
        initial_entry_price: 64313.32,
        is_closed_position: true,
        miner_hotkey: "5DLzS15wTh52PnQUputdvcLWss7cpwNXLkiaoFT11piTzxTn",
        net_leverage: 0.0,
        open_ms: 1711247663399,
        orders: [
          {
            leverage: 0.6,
            order_type: "LONG",
            order_uuid: "05f7208d-c57d-4286-a49d-7fba05b16067",
            price: 64313.32,
            price_sources: [],
            processed_ms: 1711247663398,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "219fa818-5a15-41ff-9902-bee8ac353ffc",
            price: 64133.33,
            price_sources: [],
            processed_ms: 1711251238434,
          },
        ],
        position_type: "FLAT",
        position_uuid: "e6a75dbe-d43e-42ef-8546-9777919b548d",
        return_at_close: 0.9971228294356441,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 64097.299999999996,
        close_ms: 1711254905401,
        current_return: 0.9985571311116069,
        initial_entry_price: 64097.3,
        is_closed_position: true,
        miner_hotkey: "5DLzS15wTh52PnQUputdvcLWss7cpwNXLkiaoFT11piTzxTn",
        net_leverage: 0.0,
        open_ms: 1711251325189,
        orders: [
          {
            leverage: 0.6,
            order_type: "LONG",
            order_uuid: "0bb2fecf-3f7a-41c7-a0fa-4e4e9746d17e",
            price: 64097.3,
            price_sources: [],
            processed_ms: 1711251325188,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "ca2e93e7-0bcb-44b5-9377-cc600c77d037",
            price: 63943.16,
            price_sources: [],
            processed_ms: 1711254905401,
          },
        ],
        position_type: "FLAT",
        position_uuid: "a46268fe-dadc-4dfc-b784-4625e0993068",
        return_at_close: 0.997358862554273,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 64004.880000000005,
        close_ms: 1711256018311,
        current_return: 1.0013283362143637,
        initial_entry_price: 64004.88,
        is_closed_position: true,
        miner_hotkey: "5DLzS15wTh52PnQUputdvcLWss7cpwNXLkiaoFT11piTzxTn",
        net_leverage: 0.0,
        open_ms: 1711254992183,
        orders: [
          {
            leverage: 0.6,
            order_type: "LONG",
            order_uuid: "17947208-5e82-4911-8060-b09427b6dfb3",
            price: 64004.88,
            price_sources: [],
            processed_ms: 1711254992182,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "482cf197-2704-4f3c-954b-7eaa7d680ce9",
            price: 64146.58,
            price_sources: [],
            processed_ms: 1711256018311,
          },
        ],
        position_type: "FLAT",
        position_uuid: "aef8929d-4c21-4a16-9319-0e79aeca21d6",
        return_at_close: 1.0001267422109064,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 64153.909999999996,
        close_ms: 1711257462580,
        current_return: 1.0006563590590192,
        initial_entry_price: 64153.91,
        is_closed_position: true,
        miner_hotkey: "5DLzS15wTh52PnQUputdvcLWss7cpwNXLkiaoFT11piTzxTn",
        net_leverage: 0.0,
        open_ms: 1711256105460,
        orders: [
          {
            leverage: 0.6,
            order_type: "LONG",
            order_uuid: "effe3861-bad8-4a1c-9bdf-dedb568a870f",
            price: 64153.91,
            price_sources: [],
            processed_ms: 1711256105459,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "47b87eff-7e44-45fd-8f33-3da301a1398a",
            price: 64224.09,
            price_sources: [],
            processed_ms: 1711257462580,
          },
        ],
        position_type: "FLAT",
        position_uuid: "dc71abdb-7dc3-4996-acb1-2cb589b8105b",
        return_at_close: 0.9994555714281483,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 64231.3,
        close_ms: 1711261123475,
        current_return: 1.0021118987160464,
        initial_entry_price: 64231.3,
        is_closed_position: true,
        miner_hotkey: "5DLzS15wTh52PnQUputdvcLWss7cpwNXLkiaoFT11piTzxTn",
        net_leverage: 0.0,
        open_ms: 1711257549100,
        orders: [
          {
            leverage: 1.25,
            order_type: "LONG",
            order_uuid: "49815d4d-4e8a-4f41-93f9-ae715b029154",
            price: 64231.3,
            price_sources: [],
            processed_ms: 1711257549098,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "3b07d6e6-3ec9-4071-84cf-b2b157e7a07e",
            price: 64339.82,
            price_sources: [],
            processed_ms: 1711261123475,
          },
        ],
        position_type: "FLAT",
        position_uuid: "e3f7b125-b472-4326-9ff5-c9afd1e0c930",
        return_at_close: 0.9996066189692564,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 64373.55,
        close_ms: 1711264781333,
        current_return: 0.9949903725986837,
        initial_entry_price: 64373.55,
        is_closed_position: true,
        miner_hotkey: "5DLzS15wTh52PnQUputdvcLWss7cpwNXLkiaoFT11piTzxTn",
        net_leverage: 0.0,
        open_ms: 1711261226181,
        orders: [
          {
            leverage: 1.25,
            order_type: "LONG",
            order_uuid: "2968f51b-2e12-4e91-a87f-c06b201f5132",
            price: 64373.55,
            price_sources: [],
            processed_ms: 1711261226179,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "2f5d29aa-7922-4fd8-b925-65e0cebe86ff",
            price: 64115.56,
            price_sources: [],
            processed_ms: 1711264781333,
          },
        ],
        position_type: "FLAT",
        position_uuid: "12654acc-fa85-41bd-a14a-b9d71c10bc5a",
        return_at_close: 0.992502896667187,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 64105.31,
        close_ms: 1711268441376,
        current_return: 1.0122602948180113,
        initial_entry_price: 64105.31,
        is_closed_position: true,
        miner_hotkey: "5DLzS15wTh52PnQUputdvcLWss7cpwNXLkiaoFT11piTzxTn",
        net_leverage: 0.0,
        open_ms: 1711264868153,
        orders: [
          {
            leverage: 1.25,
            order_type: "LONG",
            order_uuid: "553f0ab7-37dc-4440-a2bd-f2deb270058e",
            price: 64105.31,
            price_sources: [],
            processed_ms: 1711264868152,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "df71dbea-f2a6-4cc1-ae75-78c6bfe1a75d",
            price: 64734.07,
            price_sources: [],
            processed_ms: 1711268441376,
          },
        ],
        position_type: "FLAT",
        position_uuid: "88643bb1-38a7-4457-b271-978ca4ca955e",
        return_at_close: 1.0097296440809662,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 64828.1,
        close_ms: 1711272099547,
        current_return: 1.0007739699297065,
        initial_entry_price: 64828.1,
        is_closed_position: true,
        miner_hotkey: "5DLzS15wTh52PnQUputdvcLWss7cpwNXLkiaoFT11piTzxTn",
        net_leverage: 0.0,
        open_ms: 1711268528372,
        orders: [
          {
            leverage: 1.25,
            order_type: "LONG",
            order_uuid: "baea4f3f-876d-44af-9344-e630bd0b225d",
            price: 64828.1,
            price_sources: [],
            processed_ms: 1711268528370,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "d5f01767-3641-4e92-88eb-5ec05eb1159d",
            price: 64868.24,
            price_sources: [],
            processed_ms: 1711272099547,
          },
        ],
        position_type: "FLAT",
        position_uuid: "7e5cf517-5435-4b94-9cbb-acbf6f60931b",
        return_at_close: 0.9982720350048823,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 65470.68500000001,
        close_ms: 1711286332087,
        current_return: 1.0081663532079768,
        initial_entry_price: 65287.71,
        is_closed_position: true,
        miner_hotkey: "5DLzS15wTh52PnQUputdvcLWss7cpwNXLkiaoFT11piTzxTn",
        net_leverage: 0.0,
        open_ms: 1711282706425,
        orders: [
          {
            leverage: 1.25,
            order_type: "LONG",
            order_uuid: "8c69d5f2-23dc-4e14-a1ce-7267cb131fdf",
            price: 65287.71,
            price_sources: [],
            processed_ms: 1711282706423,
          },
          {
            leverage: 1.25,
            order_type: "LONG",
            order_uuid: "a16727bc-9741-4ee4-a532-4eb93bdba329",
            price: 65653.66,
            price_sources: [],
            processed_ms: 1711284476508,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "d8d665fc-cd56-43d4-806f-9d67abdd95ce",
            price: 65683.95,
            price_sources: [],
            processed_ms: 1711286332087,
          },
        ],
        position_type: "FLAT",
        position_uuid: "9f5de8a7-a2fb-4a8d-975e-9abdf8510fd7",
        return_at_close: 1.0031255214419368,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 65664.06,
        close_ms: 1711297300078,
        current_return: 1.0001540035751673,
        initial_entry_price: 65664.06,
        is_closed_position: true,
        miner_hotkey: "5DLzS15wTh52PnQUputdvcLWss7cpwNXLkiaoFT11piTzxTn",
        net_leverage: 0.0,
        open_ms: 1711295464556,
        orders: [
          {
            leverage: -1.25,
            order_type: "SHORT",
            order_uuid: "96c735f1-9a56-4a91-bd36-9ab07ff6fd03",
            price: 65664.06,
            price_sources: [],
            processed_ms: 1711295464553,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "488f4cd2-1479-4959-9bd5-260691b43017",
            price: 65655.97,
            price_sources: [],
            processed_ms: 1711297300078,
          },
        ],
        position_type: "FLAT",
        position_uuid: "fe897e07-c4be-4bce-8084-b1341a1f1004",
        return_at_close: 0.9976536185662294,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: -146994.7100000012,
        close_ms: 1711314375368,
        current_return: 0.9920378412670725,
        initial_entry_price: 65403.39,
        is_closed_position: true,
        miner_hotkey: "5DLzS15wTh52PnQUputdvcLWss7cpwNXLkiaoFT11piTzxTn",
        net_leverage: 0.0,
        open_ms: 1711299122859,
        orders: [
          {
            leverage: -1.25,
            order_type: "SHORT",
            order_uuid: "26ecb6e2-3ef1-423f-99eb-2d23c5a33b0e",
            price: 65403.39,
            price_sources: [],
            processed_ms: 1711299122853,
          },
          {
            leverage: 0.625,
            order_type: "LONG",
            order_uuid: "d3b5c33c-5fd8-4128-ae51-7610b183c426",
            price: 65821.02,
            price_sources: [],
            processed_ms: 1711306000429,
          },
          {
            leverage: 0.3125,
            order_type: "LONG",
            order_uuid: "1d3b79a6-520e-42ce-a098-357749141173",
            price: 65802.11,
            price_sources: [],
            processed_ms: 1711306931203,
          },
          {
            leverage: 0.15625,
            order_type: "LONG",
            order_uuid: "72b07c46-d978-465d-acd6-4100ce43d13e",
            price: 65856.71,
            price_sources: [],
            processed_ms: 1711307870302,
          },
          {
            leverage: 0.078125,
            order_type: "LONG",
            order_uuid: "7ca9189d-fb4e-46f7-8095-ae89e9f4238b",
            price: 65700.07,
            price_sources: [],
            processed_ms: 1711308787435,
          },
          {
            leverage: 0.0390625,
            order_type: "LONG",
            order_uuid: "5bfe84df-3ca8-4c06-863c-5360cc848e9e",
            price: 65865.64,
            price_sources: [],
            processed_ms: 1711309722176,
          },
          {
            leverage: 0.01953125,
            order_type: "LONG",
            order_uuid: "6f2a3d93-ecaf-4bd6-a125-dcc770e9dca8",
            price: 65799.21,
            price_sources: [],
            processed_ms: 1711310654354,
          },
          {
            leverage: 0.009765625,
            order_type: "LONG",
            order_uuid: "ed6477aa-3b61-4e8f-85df-cd14c4d68db9",
            price: 66142.73,
            price_sources: [],
            processed_ms: 1711311597841,
          },
          {
            leverage: 0.0048828125,
            order_type: "LONG",
            order_uuid: "fdee6bda-ce9e-4165-a2bd-c4b9fe1ed205",
            price: 66169.64,
            price_sources: [],
            processed_ms: 1711312516193,
          },
          {
            leverage: 0.00244140625,
            order_type: "LONG",
            order_uuid: "ffa530d7-eea8-4190-9c24-e9772416b8e5",
            price: 66293.39,
            price_sources: [],
            processed_ms: 1711313443375,
          },
          {
            leverage: 0.125,
            order_type: "LONG",
            order_uuid: "3653a604-ae46-41f5-9a1e-73e3cfa10830",
            price: 66305.38,
            price_sources: [],
            processed_ms: 1711314375368,
          },
        ],
        position_type: "FLAT",
        position_uuid: "60fa1c3b-114f-4b48-9982-22a64ef7f613",
        return_at_close: 0.9895577466639048,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 63987.19400000006,
        close_ms: 1711320883290,
        current_return: 0.9931865919271705,
        initial_entry_price: 66390.6,
        is_closed_position: true,
        miner_hotkey: "5DLzS15wTh52PnQUputdvcLWss7cpwNXLkiaoFT11piTzxTn",
        net_leverage: 0.0,
        open_ms: 1711316235299,
        orders: [
          {
            leverage: -1.25,
            order_type: "SHORT",
            order_uuid: "46fdd62b-df72-4d77-bd23-93bf6af9936c",
            price: 66390.6,
            price_sources: [],
            processed_ms: 1711316235298,
          },
          {
            leverage: 0.125,
            order_type: "LONG",
            order_uuid: "0f71739b-f134-4655-a808-2f76caf8a914",
            price: 66381.58,
            price_sources: [],
            processed_ms: 1711317173496,
          },
          {
            leverage: 0.5625,
            order_type: "LONG",
            order_uuid: "22f5110d-47d1-42f1-be5c-0254ce2bfed9",
            price: 66782.45,
            price_sources: [],
            processed_ms: 1711318100157,
          },
          {
            leverage: 0.28125,
            order_type: "LONG",
            order_uuid: "df584128-dd2b-49ff-8427-a19c673e3712",
            price: 66715.65,
            price_sources: [],
            processed_ms: 1711319054937,
          },
          {
            leverage: 0.125,
            order_type: "LONG",
            order_uuid: "70404f60-0944-4fd7-a921-ae8b10e44d28",
            price: 66909.19,
            price_sources: [],
            processed_ms: 1711319961574,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "5ec519e0-2e6c-4aed-8205-0803a018d234",
            price: 66882.21,
            price_sources: [],
            processed_ms: 1711320883290,
          },
        ],
        position_type: "FLAT",
        position_uuid: "85fb3996-e17c-4c9f-89ad-d9416b242948",
        return_at_close: 0.9907036254473527,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 70634.68999999996,
        close_ms: 1711327398150,
        current_return: 1.0047486221839352,
        initial_entry_price: 67368.39,
        is_closed_position: true,
        miner_hotkey: "5DLzS15wTh52PnQUputdvcLWss7cpwNXLkiaoFT11piTzxTn",
        net_leverage: 0.0,
        open_ms: 1711322743496,
        orders: [
          {
            leverage: -1.25,
            order_type: "SHORT",
            order_uuid: "47171153-c549-434c-ab80-cb18f5f1515a",
            price: 67368.39,
            price_sources: [],
            processed_ms: 1711322743494,
          },
          {
            leverage: 0.625,
            order_type: "LONG",
            order_uuid: "444bd8d9-aa44-4821-b542-bf5795b48d03",
            price: 67215.6,
            price_sources: [],
            processed_ms: 1711323684128,
          },
          {
            leverage: 0.3125,
            order_type: "LONG",
            order_uuid: "0273dbe8-b9e2-4c68-b805-8c28dc10432b",
            price: 67236.77,
            price_sources: [],
            processed_ms: 1711324614021,
          },
          {
            leverage: 0.15625,
            order_type: "LONG",
            order_uuid: "10a35aca-f7f3-4971-9058-7e9ed4d9ef65",
            price: 66851.08,
            price_sources: [],
            processed_ms: 1711325542327,
          },
          {
            leverage: 0.078125,
            order_type: "LONG",
            order_uuid: "2c691592-e1cf-467b-9ee4-6c0cee785d09",
            price: 66885.51,
            price_sources: [],
            processed_ms: 1711326466426,
          },
          {
            leverage: 0.125,
            order_type: "LONG",
            order_uuid: "119154da-672b-4cc3-8136-cd0e8a44d8b0",
            price: 66539.88,
            price_sources: [],
            processed_ms: 1711327398150,
          },
        ],
        position_type: "FLAT",
        position_uuid: "07ca4ade-a3e0-4bc7-a851-a7116b81a112",
        return_at_close: 1.0022367506284755,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 61276.81999999968,
        close_ms: 1711339273398,
        current_return: 0.9980267791814007,
        initial_entry_price: 66599.87,
        is_closed_position: true,
        miner_hotkey: "5DLzS15wTh52PnQUputdvcLWss7cpwNXLkiaoFT11piTzxTn",
        net_leverage: 0.0,
        open_ms: 1711328349339,
        orders: [
          {
            leverage: -1.25,
            order_type: "SHORT",
            order_uuid: "7c479b3c-3bec-4d9a-a014-24653492f582",
            price: 66599.87,
            price_sources: [],
            processed_ms: 1711328349337,
          },
          {
            leverage: 0.125,
            order_type: "LONG",
            order_uuid: "379fbe7d-944a-4d3b-a330-76df8267a736",
            price: 66732.11,
            price_sources: [],
            processed_ms: 1711329263311,
          },
          {
            leverage: 0.5625,
            order_type: "LONG",
            order_uuid: "aa19e9f9-ed9b-4b46-ab4f-74a695ecf351",
            price: 66739.05,
            price_sources: [],
            processed_ms: 1711330116073,
          },
          {
            leverage: 0.125,
            order_type: "LONG",
            order_uuid: "b4c8c4c3-2a1f-4f82-ac3e-dfdaa0de90c1",
            price: 66543.21,
            price_sources: [],
            processed_ms: 1711331964184,
          },
          {
            leverage: 0.21875,
            order_type: "LONG",
            order_uuid: "65b1b0c0-3ff3-4a96-9961-3b3e5e72005f",
            price: 66629.28,
            price_sources: [],
            processed_ms: 1711333778318,
          },
          {
            leverage: 0.175,
            order_type: "LONG",
            order_uuid: "5fdbc336-459e-4d04-9330-d37b98a6c700",
            price: 66704.54,
            price_sources: [],
            processed_ms: 1711335618330,
          },
          {
            leverage: 0.021875000000000006,
            order_type: "LONG",
            order_uuid: "730ae400-d8eb-4afa-8f71-a4d8114e0e82",
            price: 66780.66,
            price_sources: [],
            processed_ms: 1711337446255,
          },
          {
            leverage: 0.125,
            order_type: "LONG",
            order_uuid: "296ed624-7224-49ac-8796-418dfa3679b4",
            price: 67284.42,
            price_sources: [],
            processed_ms: 1711339273398,
          },
        ],
        position_type: "FLAT",
        position_uuid: "462633ac-e95c-42c5-a159-27e60ad59830",
        return_at_close: 0.9955317122334473,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 43916.06000000014,
        close_ms: 1711352098487,
        current_return: 0.9946027930215506,
        initial_entry_price: 67182.26,
        is_closed_position: true,
        miner_hotkey: "5DLzS15wTh52PnQUputdvcLWss7cpwNXLkiaoFT11piTzxTn",
        net_leverage: 0.0,
        open_ms: 1711341102546,
        orders: [
          {
            leverage: -1.25,
            order_type: "SHORT",
            order_uuid: "15ee6589-86bf-4c2c-9eef-1efe220bf0ad",
            price: 67182.26,
            price_sources: [],
            processed_ms: 1711341102544,
          },
          {
            leverage: 1.0,
            order_type: "LONG",
            order_uuid: "83e89d98-0782-457a-a5b5-40e327b94bc7",
            price: 67489.12,
            price_sources: [],
            processed_ms: 1711342935325,
          },
          {
            leverage: 0.125,
            order_type: "LONG",
            order_uuid: "801c5b8b-2729-4d86-9dbb-bc463d318eb0",
            price: 67599.0,
            price_sources: [],
            processed_ms: 1711344770641,
          },
          {
            leverage: 0.0625,
            order_type: "LONG",
            order_uuid: "c5074ed8-be29-4b08-8d39-85616e1d3759",
            price: 67264.93,
            price_sources: [],
            processed_ms: 1711346604680,
          },
          {
            leverage: 0.03125,
            order_type: "LONG",
            order_uuid: "1349680c-6ade-4be4-9155-67e073712f01",
            price: 67142.51,
            price_sources: [],
            processed_ms: 1711348431420,
          },
          {
            leverage: 0.015625,
            order_type: "LONG",
            order_uuid: "389ef1c7-fb61-4de1-aff4-d31c9fdda0f1",
            price: 67224.32,
            price_sources: [],
            processed_ms: 1711350259227,
          },
          {
            leverage: 0.125,
            order_type: "LONG",
            order_uuid: "63ae447d-ea58-4fe4-81f3-ed87ae231dde",
            price: 67122.24,
            price_sources: [],
            processed_ms: 1711352098487,
          },
        ],
        position_type: "FLAT",
        position_uuid: "54be4b82-d4fe-4199-8fca-29f7926d7b75",
        return_at_close: 0.9921162860389968,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 66906.92,
        close_ms: 1711359423046,
        current_return: 0.9982135629617983,
        initial_entry_price: 66906.92,
        is_closed_position: true,
        miner_hotkey: "5DLzS15wTh52PnQUputdvcLWss7cpwNXLkiaoFT11piTzxTn",
        net_leverage: 0.0,
        open_ms: 1711357610487,
        orders: [
          {
            leverage: 1.25,
            order_type: "LONG",
            order_uuid: "a54aa0b4-cd19-4d4c-9701-cb0540b0c30a",
            price: 66906.92,
            price_sources: [],
            processed_ms: 1711357610485,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "45918c86-422b-440a-8714-a99817fe6f6c",
            price: 66811.3,
            price_sources: [],
            processed_ms: 1711359423046,
          },
        ],
        position_type: "FLAT",
        position_uuid: "e27fa6d8-f590-4f56-953a-095c4a004bcf",
        return_at_close: 0.9957180290543939,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 65573.67499999999,
        close_ms: 1711376220458,
        current_return: 1.050099879623614,
        initial_entry_price: 66815.43,
        is_closed_position: true,
        miner_hotkey: "5DLzS15wTh52PnQUputdvcLWss7cpwNXLkiaoFT11piTzxTn",
        net_leverage: 0.0,
        open_ms: 1711367981391,
        orders: [
          {
            leverage: 1.25,
            order_type: "LONG",
            order_uuid: "6b03b3eb-1ffb-4494-a699-02ac76ce0124",
            price: 66815.43,
            price_sources: [],
            processed_ms: 1711367981389,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "1ea6f8f7-b40b-4f3e-b986-75c2f0d73e23",
            price: 67131.99,
            price_sources: [],
            processed_ms: 1711369816960,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "ea0b1452-13e3-4885-b1a7-42b0a00dbf1f",
            price: 67104.24,
            price_sources: [],
            processed_ms: 1711371642577,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "027782f9-8293-4f3d-ae7f-e040413ea658",
            price: 66938.32,
            price_sources: [],
            processed_ms: 1711373485384,
          },
          {
            leverage: -1.0,
            order_type: "SHORT",
            order_uuid: "1751c239-543c-4c84-bd1f-a5c6852d370d",
            price: 68239.25,
            price_sources: [],
            processed_ms: 1711375305679,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "f302edad-49bf-4616-8781-09e1ae57e715",
            price: 68921.12,
            price_sources: [],
            processed_ms: 1711376220458,
          },
        ],
        position_type: "FLAT",
        position_uuid: "694ef4c8-7451-4a07-8ca5-2872f15367d8",
        return_at_close: 1.0458994801051196,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 69235.28,
        close_ms: 1711376617719,
        current_return: 0.9975877543934248,
        initial_entry_price: 69235.28,
        is_closed_position: true,
        miner_hotkey: "5DLzS15wTh52PnQUputdvcLWss7cpwNXLkiaoFT11piTzxTn",
        net_leverage: 0.0,
        open_ms: 1711376602318,
        orders: [
          {
            leverage: -1.25,
            order_type: "SHORT",
            order_uuid: "dabe008f-ebca-4efd-bb84-ecef9fce4746",
            price: 69235.28,
            price_sources: [],
            processed_ms: 1711376602316,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "0e6366b1-b84c-42d9-a97f-c4b359f56ce0",
            price: 69368.89,
            price_sources: [],
            processed_ms: 1711376617719,
          },
        ],
        position_type: "FLAT",
        position_uuid: "7a55456e-a557-4261-ae05-3b6d8b70a100",
        return_at_close: 0.9950937850074413,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 62944.94000000011,
        close_ms: 1711381855283,
        current_return: 1.005059789039751,
        initial_entry_price: 69144.78,
        is_closed_position: true,
        miner_hotkey: "5DLzS15wTh52PnQUputdvcLWss7cpwNXLkiaoFT11piTzxTn",
        net_leverage: 0.0,
        open_ms: 1711378036661,
        orders: [
          {
            leverage: 1.25,
            order_type: "LONG",
            order_uuid: "83acdede-c554-490f-a043-54ab1a1f1d1b",
            price: 69144.78,
            price_sources: [],
            processed_ms: 1711378036659,
          },
          {
            leverage: -1.0,
            order_type: "SHORT",
            order_uuid: "8309acbe-8541-44ea-b7e2-e492a2c5b987",
            price: 69295.36,
            price_sources: [],
            processed_ms: 1711378305311,
          },
          {
            leverage: -0.2,
            order_type: "SHORT",
            order_uuid: "cf6737dc-9020-4f64-af2f-7f0d6e4ada66",
            price: 69941.84,
            price_sources: [],
            processed_ms: 1711381768499,
          },
          {
            leverage: -0.2,
            order_type: "SHORT",
            order_uuid: "fe576810-a6bc-4cde-af8b-d1f9d808a9aa",
            price: 69942.1,
            price_sources: [],
            processed_ms: 1711381855283,
          },
        ],
        position_type: "FLAT",
        position_uuid: "1ee63560-d418-4eb8-8b79-f0c564c560fd",
        return_at_close: 1.0025471395671517,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 67523.6457142857,
        close_ms: 1711391236097,
        current_return: 0.97940403199748,
        initial_entry_price: 69973.01,
        is_closed_position: true,
        miner_hotkey: "5DLzS15wTh52PnQUputdvcLWss7cpwNXLkiaoFT11piTzxTn",
        net_leverage: 0.0,
        open_ms: 1711382128326,
        orders: [
          {
            leverage: -5.0,
            order_type: "SHORT",
            order_uuid: "60ef4f14-a29e-46bb-9f0d-c26d0dd720e1",
            price: 69973.01,
            price_sources: [],
            processed_ms: 1711382128324,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "76b8a14f-914e-4511-b144-4dac53efb361",
            price: 69795.64,
            price_sources: [],
            processed_ms: 1711383945522,
          },
          {
            leverage: 1.75,
            order_type: "LONG",
            order_uuid: "46bbeba5-90de-4049-86e9-cf08138eae6d",
            price: 70225.75,
            price_sources: [],
            processed_ms: 1711385751834,
          },
          {
            leverage: 0.875,
            order_type: "LONG",
            order_uuid: "ee6787ef-421e-42da-839f-6f121643c943",
            price: 70699.99,
            price_sources: [],
            processed_ms: 1711387569283,
          },
          {
            leverage: 0.4375,
            order_type: "LONG",
            order_uuid: "6da4b1d4-3da3-4386-b8a4-a037561bf797",
            price: 70565.58,
            price_sources: [],
            processed_ms: 1711389388367,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "c5f5db66-97ab-4ef7-846c-9258ad9631f3",
            price: 70817.73,
            price_sources: [],
            processed_ms: 1711391236097,
          },
        ],
        position_type: "FLAT",
        position_uuid: "9c7055be-6022-4bf7-b1d3-feea03db66d1",
        return_at_close: 0.9696099916775053,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 70899.25954545454,
        close_ms: 1711401020828,
        current_return: 0.9681744975792684,
        initial_entry_price: 70920.64,
        is_closed_position: true,
        miner_hotkey: "5DLzS15wTh52PnQUputdvcLWss7cpwNXLkiaoFT11piTzxTn",
        net_leverage: 0.0,
        open_ms: 1711393015577,
        orders: [
          {
            leverage: 5.0,
            order_type: "LONG",
            order_uuid: "f98db7c5-6201-4f38-9f96-32162f9c7934",
            price: 70920.64,
            price_sources: [],
            processed_ms: 1711393015575,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "5373ef91-90f1-4859-a777-a9d0f4fc2e08",
            price: 70667.05,
            price_sources: [],
            processed_ms: 1711394835879,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "3c7503dc-16dc-454b-9763-e5ef0b242aef",
            price: 71081.85,
            price_sources: [],
            processed_ms: 1711396650063,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "595e74af-3813-4b97-83ed-fabeff20d7fb",
            price: 70882.64,
            price_sources: [],
            processed_ms: 1711398465813,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "77cb4887-6ff1-479f-a224-a8e73183778d",
            price: 70894.23,
            price_sources: [],
            processed_ms: 1711400280758,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "fe3be02b-69fa-42ef-af7b-a9ca452c477b",
            price: 70694.07,
            price_sources: [],
            processed_ms: 1711401020828,
          },
        ],
        position_type: "FLAT",
        position_uuid: "88d9cee4-d4f5-4e07-bc6e-20c7b055fe93",
        return_at_close: 0.9468746586325245,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 69750.16374999999,
        close_ms: 1711406682609,
        current_return: 1.055227699034608,
        initial_entry_price: 69630.73,
        is_closed_position: true,
        miner_hotkey: "5DLzS15wTh52PnQUputdvcLWss7cpwNXLkiaoFT11piTzxTn",
        net_leverage: 0.0,
        open_ms: 1711402097188,
        orders: [
          {
            leverage: 5.0,
            order_type: "LONG",
            order_uuid: "d3e7f5f7-8535-4d56-9688-b2454d1e8498",
            price: 69630.73,
            price_sources: [],
            processed_ms: 1711402097185,
          },
          {
            leverage: -2.5,
            order_type: "SHORT",
            order_uuid: "ea69b7a5-9830-4723-be9a-79c027421f11",
            price: 69870.55,
            price_sources: [],
            processed_ms: 1711403914870,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "108191b6-8b72-48a2-bc41-7bc66c90f287",
            price: 70348.92,
            price_sources: [],
            processed_ms: 1711405723540,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "aadd7fc9-f1bb-4b15-af90-e8dd45944a50",
            price: 70711.55,
            price_sources: [],
            processed_ms: 1711406682609,
          },
        ],
        position_type: "FLAT",
        position_uuid: "9ea84116-646d-4ab6-b691-9a39d010cfd3",
        return_at_close: 1.0446754220442618,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 70312.64846153845,
        close_ms: 1711410001867,
        current_return: 0.9691393048098332,
        initial_entry_price: 70358.59,
        is_closed_position: true,
        miner_hotkey: "5DLzS15wTh52PnQUputdvcLWss7cpwNXLkiaoFT11piTzxTn",
        net_leverage: 0.0,
        open_ms: 1711407540152,
        orders: [
          {
            leverage: 5.0,
            order_type: "LONG",
            order_uuid: "0bdba820-cd07-4aa6-af54-d4531cf31806",
            price: 70358.59,
            price_sources: [],
            processed_ms: 1711407540149,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "2679319d-436b-4bf6-a2ca-8093c94d1b38",
            price: 70159.51,
            price_sources: [],
            processed_ms: 1711409358317,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "d6df492b-7d70-4efd-9af5-fbad6efce849",
            price: 69978.6,
            price_sources: [],
            processed_ms: 1711410001867,
          },
        ],
        position_type: "FLAT",
        position_uuid: "30ab180f-a352-4b3b-b171-4f51f6b5ebf8",
        return_at_close: 0.9565404938473054,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 57492.959999999846,
        close_ms: 1711425744120,
        current_return: 1.0465153022709315,
        initial_entry_price: 69890.28,
        is_closed_position: true,
        miner_hotkey: "5DLzS15wTh52PnQUputdvcLWss7cpwNXLkiaoFT11piTzxTn",
        net_leverage: 0.0,
        open_ms: 1711411228953,
        orders: [
          {
            leverage: 5.0,
            order_type: "LONG",
            order_uuid: "0803c764-031b-43ce-9e38-279ec4dee08f",
            price: 69890.28,
            price_sources: [],
            processed_ms: 1711411228950,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "d11b6faf-8dd0-423a-b58f-f6962687d97d",
            price: 69935.8,
            price_sources: [],
            processed_ms: 1711413006676,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "0fb06021-589a-44d5-95b1-73dd69a06223",
            price: 69901.51,
            price_sources: [],
            processed_ms: 1711414817737,
          },
          {
            leverage: -4.0,
            order_type: "SHORT",
            order_uuid: "7cfbba01-94ba-4ed2-9871-9e6801bd57e6",
            price: 70283.8,
            price_sources: [],
            processed_ms: 1711416634346,
          },
          {
            leverage: -2.0,
            order_type: "SHORT",
            order_uuid: "9afd1c1d-382f-480f-9b89-a861713976e0",
            price: 70293.96,
            price_sources: [],
            processed_ms: 1711418449502,
          },
          {
            leverage: -1.0,
            order_type: "SHORT",
            order_uuid: "11ddefe6-c240-41aa-b556-304670553c59",
            price: 70252.49,
            price_sources: [],
            processed_ms: 1711420290278,
          },
          {
            leverage: -0.5,
            order_type: "SHORT",
            order_uuid: "322752e4-0a3c-400f-82f9-f84253620001",
            price: 70470.97,
            price_sources: [],
            processed_ms: 1711422089414,
          },
          {
            leverage: -0.25,
            order_type: "SHORT",
            order_uuid: "c5bd3503-23b1-4495-b85b-594f0cd179fa",
            price: 70492.12,
            price_sources: [],
            processed_ms: 1711423900612,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "661cc6b4-eb6a-429e-8cd6-c70fcfd7f838",
            price: 70496.83,
            price_sources: [],
            processed_ms: 1711425744120,
          },
        ],
        position_type: "FLAT",
        position_uuid: "88d089d0-1aca-4354-a3df-3f47708d965e",
        return_at_close: 1.0297710574345966,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 70686.63999999988,
        close_ms: 1711438433534,
        current_return: 1.0049100834547728,
        initial_entry_price: 70584.34,
        is_closed_position: true,
        miner_hotkey: "5DLzS15wTh52PnQUputdvcLWss7cpwNXLkiaoFT11piTzxTn",
        net_leverage: 0.0,
        open_ms: 1711427545582,
        orders: [
          {
            leverage: 5.0,
            order_type: "LONG",
            order_uuid: "58f2238b-59a0-4864-ae48-191bbd192774",
            price: 70584.34,
            price_sources: [],
            processed_ms: 1711427545579,
          },
          {
            leverage: -2.5,
            order_type: "SHORT",
            order_uuid: "15071315-b7e3-4181-bcd8-abb14ecd48f3",
            price: 70586.99,
            price_sources: [],
            processed_ms: 1711429353926,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "7ebdd33d-66e7-4dee-99e3-00c584cdb57f",
            price: 70369.47,
            price_sources: [],
            processed_ms: 1711431180638,
          },
          {
            leverage: -2.0,
            order_type: "SHORT",
            order_uuid: "5bbcbf33-e82a-47a8-852d-5d5ca1988681",
            price: 70319.58,
            price_sources: [],
            processed_ms: 1711432988355,
          },
          {
            leverage: -1.0,
            order_type: "SHORT",
            order_uuid: "26fa2587-40a8-4b4a-bfb8-aa8ea00c6b8f",
            price: 70590.68,
            price_sources: [],
            processed_ms: 1711434807901,
          },
          {
            leverage: -0.5,
            order_type: "SHORT",
            order_uuid: "86986a44-ffc8-4311-abc0-4724099d3392",
            price: 70870.54,
            price_sources: [],
            processed_ms: 1711436637253,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "1bad9d1d-e8e6-4b94-affd-de0496f99527",
            price: 71379.79,
            price_sources: [],
            processed_ms: 1711438433534,
          },
        ],
        position_type: "FLAT",
        position_uuid: "0634a3e2-fd41-49cf-9291-3c3c1723dc79",
        return_at_close: 0.9948609826202252,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 68933.26000000013,
        close_ms: 1711447539207,
        current_return: 0.9844252793021999,
        initial_entry_price: 70571.41,
        is_closed_position: true,
        miner_hotkey: "5DLzS15wTh52PnQUputdvcLWss7cpwNXLkiaoFT11piTzxTn",
        net_leverage: 0.0,
        open_ms: 1711440249968,
        orders: [
          {
            leverage: -5.0,
            order_type: "SHORT",
            order_uuid: "ffa31e74-7ac8-4a47-be1d-458c9d5a5994",
            price: 70571.41,
            price_sources: [],
            processed_ms: 1711440249965,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "6cff69d2-4c09-4c11-84f2-1398a0447330",
            price: 70529.93,
            price_sources: [],
            processed_ms: 1711442092626,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "5ec44275-d526-4e78-a1dc-1fb1f39f6ac3",
            price: 70689.56,
            price_sources: [],
            processed_ms: 1711443884607,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "52a812d8-ea04-4b57-9ed8-221a13f8d618",
            price: 71040.79,
            price_sources: [],
            processed_ms: 1711445698387,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "e3609da4-af2b-4eb0-957a-23a7ace1ea88",
            price: 71131.52,
            price_sources: [],
            processed_ms: 1711447539207,
          },
        ],
        position_type: "FLAT",
        position_uuid: "f0f4ab99-2e40-4192-aabc-f344f2695cf3",
        return_at_close: 0.9745810265091779,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 74495.70999999996,
        close_ms: 1711456611316,
        current_return: 1.012252563220898,
        initial_entry_price: 70917.61,
        is_closed_position: true,
        miner_hotkey: "5DLzS15wTh52PnQUputdvcLWss7cpwNXLkiaoFT11piTzxTn",
        net_leverage: 0.0,
        open_ms: 1711449344579,
        orders: [
          {
            leverage: -5.0,
            order_type: "SHORT",
            order_uuid: "21a88e07-9759-46af-9340-e7f89f5cceb8",
            price: 70917.61,
            price_sources: [],
            processed_ms: 1711449344575,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "49c721df-d869-4a17-a6b2-f8408e87ae69",
            price: 70899.12,
            price_sources: [],
            processed_ms: 1711451157572,
          },
          {
            leverage: 1.75,
            order_type: "LONG",
            order_uuid: "6266b932-7872-4a76-b64a-6ebf10a90dca",
            price: 70682.59,
            price_sources: [],
            processed_ms: 1711453007360,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "b943e420-2ba8-49c9-bb12-8ee5749ada5e",
            price: 70613.94,
            price_sources: [],
            processed_ms: 1711454789517,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "32d00e4c-9986-4a2c-9240-a2f60501b598",
            price: 71020.02,
            price_sources: [],
            processed_ms: 1711456611316,
          },
        ],
        position_type: "FLAT",
        position_uuid: "875289ad-3c10-4793-a921-607ff9abb81c",
        return_at_close: 1.002130037588689,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 71735.56000000001,
        close_ms: 1711463151063,
        current_return: 1.0526484686082227,
        initial_entry_price: 70987.06,
        is_closed_position: true,
        miner_hotkey: "5DLzS15wTh52PnQUputdvcLWss7cpwNXLkiaoFT11piTzxTn",
        net_leverage: 0.0,
        open_ms: 1711458424090,
        orders: [
          {
            leverage: -5.0,
            order_type: "SHORT",
            order_uuid: "efcd46ae-e48a-4b91-bf3f-354bac741d3d",
            price: 70987.06,
            price_sources: [],
            processed_ms: 1711458424085,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "77e27644-4f44-4877-b152-f30b364f4ba5",
            price: 70605.0,
            price_sources: [],
            processed_ms: 1711460239505,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "cde1e5da-b75b-4c11-b399-38900240285e",
            price: 70371.12,
            price_sources: [],
            processed_ms: 1711462051567,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "fc6c1f4f-560c-4850-ada5-f5fc3bc01407",
            price: 69866.88,
            price_sources: [],
            processed_ms: 1711463151063,
          },
        ],
        position_type: "FLAT",
        position_uuid: "3b6be187-6c9f-4d5a-b9a1-a0a15a005f1c",
        return_at_close: 1.0421219839221405,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 69681.32981366459,
        close_ms: 1711488573481,
        current_return: 1.0591353036481221,
        initial_entry_price: 69992.2,
        is_closed_position: true,
        miner_hotkey: "5DLzS15wTh52PnQUputdvcLWss7cpwNXLkiaoFT11piTzxTn",
        net_leverage: 0.0,
        open_ms: 1711465687502,
        orders: [
          {
            leverage: 5.0,
            order_type: "LONG",
            order_uuid: "7470d840-f65e-4049-99c2-8e4b85017578",
            price: 69992.2,
            price_sources: [],
            processed_ms: 1711465687497,
          },
          {
            leverage: -2.5,
            order_type: "SHORT",
            order_uuid: "65560ef5-ac1e-4960-8853-a3b034620d60",
            price: 70266.06,
            price_sources: [],
            processed_ms: 1711467498750,
          },
          {
            leverage: -1.25,
            order_type: "SHORT",
            order_uuid: "6f977a18-2126-411b-bf35-c0cc7facf563",
            price: 70154.98,
            price_sources: [],
            processed_ms: 1711469348667,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "deb58eef-1934-46e8-9d00-97822db3e31b",
            price: 69667.99,
            price_sources: [],
            processed_ms: 1711471145564,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "a694bc08-82ec-4361-b0cc-e386c139654a",
            price: 69854.57,
            price_sources: [],
            processed_ms: 1711472954671,
          },
          {
            leverage: -2.125,
            order_type: "SHORT",
            order_uuid: "69d18d04-8230-4ff4-bfab-e6fe33a9cefc",
            price: 70200.04,
            price_sources: [],
            processed_ms: 1711474790920,
          },
          {
            leverage: -1.0625,
            order_type: "SHORT",
            order_uuid: "c3b78f8d-fde7-41e1-9958-14a2535306c7",
            price: 70197.34,
            price_sources: [],
            processed_ms: 1711476579449,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "7a52f3d6-9766-4b93-81a5-373e3e9a2a1b",
            price: 70365.67,
            price_sources: [],
            processed_ms: 1711478399080,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "c6f9afec-75e3-413a-919e-29679e14dfa9",
            price: 69786.81,
            price_sources: [],
            processed_ms: 1711480217380,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "e55dca12-10e8-4b11-8150-7f99f618c773",
            price: 69837.36,
            price_sources: [],
            processed_ms: 1711482059565,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "83a0f0f3-245f-4504-8a3a-e9722b738c0f",
            price: 69742.04,
            price_sources: [],
            processed_ms: 1711483850784,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "a650ad6e-db36-4549-a8d1-0105661a9d8d",
            price: 69855.83,
            price_sources: [],
            processed_ms: 1711485666920,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "778b7a5c-19d4-48a1-ac46-7daba08d86ba",
            price: 69773.74,
            price_sources: [],
            processed_ms: 1711487478306,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "1419eccd-9c18-4d5e-83a1-414061222abe",
            price: 70092.66,
            price_sources: [],
            processed_ms: 1711488573481,
          },
        ],
        position_type: "FLAT",
        position_uuid: "d2aff6d7-7472-4bdd-ab47-ccc7c794c18f",
        return_at_close: 1.0378202056622037,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 70064.29272727274,
        close_ms: 1711498336335,
        current_return: 0.969900770642535,
        initial_entry_price: 70092.16,
        is_closed_position: true,
        miner_hotkey: "5DLzS15wTh52PnQUputdvcLWss7cpwNXLkiaoFT11piTzxTn",
        net_leverage: 0.0,
        open_ms: 1711489313555,
        orders: [
          {
            leverage: 5.0,
            order_type: "LONG",
            order_uuid: "78104e5f-7931-4556-a94d-b5572cb0f867",
            price: 70092.16,
            price_sources: [],
            processed_ms: 1711489313552,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "ebcbbbd2-8462-4e1a-8c8e-2e68b590b474",
            price: 69932.97,
            price_sources: [],
            processed_ms: 1711491146152,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "0b94d4fb-785a-4e07-a753-a52451167a54",
            price: 69926.55,
            price_sources: [],
            processed_ms: 1711492939932,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "2d9952ed-e33b-44d2-9e2e-508bbae91afc",
            price: 70242.16,
            price_sources: [],
            processed_ms: 1711494753708,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "35527155-fd86-4af3-a9d6-19dba06035ff",
            price: 70062.6,
            price_sources: [],
            processed_ms: 1711496586572,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "9cfac69f-623c-4b7d-804b-8757e1d78025",
            price: 69872.5,
            price_sources: [],
            processed_ms: 1711498336335,
          },
        ],
        position_type: "FLAT",
        position_uuid: "28daad51-697f-468d-8da7-d55a5dd73180",
        return_at_close: 0.9485629536883992,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 69945.98461538462,
        close_ms: 1711501262692,
        current_return: 1.0621725493812644,
        initial_entry_price: 69831.14,
        is_closed_position: true,
        miner_hotkey: "5DLzS15wTh52PnQUputdvcLWss7cpwNXLkiaoFT11piTzxTn",
        net_leverage: 0.0,
        open_ms: 1711498423590,
        orders: [
          {
            leverage: 5.0,
            order_type: "LONG",
            order_uuid: "c9688e95-2c34-4514-9b45-4f6f73498582",
            price: 69831.14,
            price_sources: [],
            processed_ms: 1711498423587,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "0d72208b-188e-474f-9236-38e48a621cef",
            price: 70328.8,
            price_sources: [],
            processed_ms: 1711500219605,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "ae19b722-cb64-44a7-923b-acbe992a3ba2",
            price: 70613.92,
            price_sources: [],
            processed_ms: 1711501262692,
          },
        ],
        position_type: "FLAT",
        position_uuid: "b190586b-08b7-4681-8b94-54e5317e2f36",
        return_at_close: 1.048364306239308,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 70611.25076923077,
        close_ms: 1711505176730,
        current_return: 0.969774397713063,
        initial_entry_price: 70648.22,
        is_closed_position: true,
        miner_hotkey: "5DLzS15wTh52PnQUputdvcLWss7cpwNXLkiaoFT11piTzxTn",
        net_leverage: 0.0,
        open_ms: 1711502029324,
        orders: [
          {
            leverage: 5.0,
            order_type: "LONG",
            order_uuid: "6b47ba44-a0c0-4fc1-8b4d-329a54240667",
            price: 70648.22,
            price_sources: [],
            processed_ms: 1711502029321,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "f6e77bbb-934b-41ec-97ef-1831a6035716",
            price: 70488.02,
            price_sources: [],
            processed_ms: 1711503848674,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "e138e307-acfc-4ca4-a0e8-77763cc77592",
            price: 70282.73,
            price_sources: [],
            processed_ms: 1711505176730,
          },
        ],
        position_type: "FLAT",
        position_uuid: "f715aeb2-c6b7-4aa3-858a-25c2e93c195a",
        return_at_close: 0.9571673305427931,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 70320.53959999999,
        close_ms: 1711515322394,
        current_return: 0.959684277620157,
        initial_entry_price: 70223.72,
        is_closed_position: true,
        miner_hotkey: "5DLzS15wTh52PnQUputdvcLWss7cpwNXLkiaoFT11piTzxTn",
        net_leverage: 0.0,
        open_ms: 1711505666139,
        orders: [
          {
            leverage: 5.0,
            order_type: "LONG",
            order_uuid: "71cfd73f-8853-4d56-8f85-9fe4f95e7152",
            price: 70223.72,
            price_sources: [],
            processed_ms: 1711505666135,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "059614b7-940a-4fb6-a150-72cd4d927c90",
            price: 70368.4,
            price_sources: [],
            processed_ms: 1711507479665,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "0b8d0fd8-e069-48f1-a4ab-720441eaf12a",
            price: 70529.73,
            price_sources: [],
            processed_ms: 1711509300519,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "6b23e40c-19f4-4571-a6a6-0733edee196a",
            price: 70382.5,
            price_sources: [],
            processed_ms: 1711511149642,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "ef8afb4f-c859-4a10-929a-67c097d7878e",
            price: 70299.64,
            price_sources: [],
            processed_ms: 1711512924234,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "cb626465-8c9a-42d2-b0b8-0beccca09319",
            price: 70345.16,
            price_sources: [],
            processed_ms: 1711514752272,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "f9da0134-0903-4ada-b6d8-b934408adae2",
            price: 70094.05,
            price_sources: [],
            processed_ms: 1711515322394,
          },
        ],
        position_type: "FLAT",
        position_uuid: "e9760c5e-5fcc-47d9-8b37-9a167829bc32",
        return_at_close: 0.935692170679653,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 70295.9936,
        close_ms: 1711523968396,
        current_return: 0.9413400825202822,
        initial_entry_price: 70351.19,
        is_closed_position: true,
        miner_hotkey: "5DLzS15wTh52PnQUputdvcLWss7cpwNXLkiaoFT11piTzxTn",
        net_leverage: 0.0,
        open_ms: 1711516569823,
        orders: [
          {
            leverage: 5.0,
            order_type: "LONG",
            order_uuid: "9aa40a27-5512-466d-9919-73147d70078b",
            price: 70351.19,
            price_sources: [],
            processed_ms: 1711516569819,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "77780cc5-541f-4501-9a71-48f4f759d723",
            price: 70392.35,
            price_sources: [],
            processed_ms: 1711518419110,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "0f9dbaf8-4435-438f-93a6-acd9d805bfd1",
            price: 70387.72,
            price_sources: [],
            processed_ms: 1711520217249,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "1aaba5e7-5559-47c8-806a-2338ee2008d0",
            price: 70250.2,
            price_sources: [],
            processed_ms: 1711522033709,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "35ca2547-6cee-40da-a32d-0b86e39f72e3",
            price: 70190.89,
            price_sources: [],
            processed_ms: 1711523546203,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "85fda4b1-e310-4421-9f1f-9077fadd0cd5",
            price: 70074.82,
            price_sources: [],
            processed_ms: 1711523631306,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "0edb96e0-6af2-4d63-8f18-6da19e352d11",
            price: 69965.85,
            price_sources: [],
            processed_ms: 1711523968396,
          },
        ],
        position_type: "FLAT",
        position_uuid: "5a178839-2679-4820-b988-0f68c4fb7b96",
        return_at_close: 0.9178065804572751,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
    ],
    thirty_day_returns: 1.0,
  },
  "5DPdXPrYCTnsUDh2nYZMCAUb3d6h8eouDCF3zhdw8ru3czSm": {
    all_time_returns: 1.0,
    n_positions: 0,
    percentage_profitable: 0.0,
    positions: [
      {
        average_entry_price: 61330.295000000006,
        close_ms: 1719731733620,
        current_return: 1.0018889809014162,
        initial_entry_price: 61549.59,
        is_closed_position: true,
        miner_hotkey: "5DPdXPrYCTnsUDh2nYZMCAUb3d6h8eouDCF3zhdw8ru3czSm",
        net_leverage: 0.0,
        open_ms: 1719554079952,
        orders: [
          {
            leverage: 0.2,
            order_type: "LONG",
            order_uuid: "f6052648-2856-4b1f-aa68-6194bce0ec24",
            price: 61549.59,
            price_sources: [],
            processed_ms: 1719554079947,
          },
          {
            leverage: 0.2,
            order_type: "LONG",
            order_uuid: "3e755ec4-a275-4185-9457-757aaddbca58",
            price: 61111.0,
            price_sources: [],
            processed_ms: 1719702410665,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "2c2d3f07-e0fa-4c54-8e90-e684f53c9d64",
            price: 61620.96,
            price_sources: [],
            processed_ms: 1719731733620,
          },
        ],
        position_type: "FLAT",
        position_uuid: "f6052648-2856-4b1f-aa68-6194bce0ec24",
        return_at_close: 1.0014882253090558,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 3378.885,
        close_ms: 1719731774486,
        current_return: 1.0007056656897728,
        initial_entry_price: 3388.29,
        is_closed_position: true,
        miner_hotkey: "5DPdXPrYCTnsUDh2nYZMCAUb3d6h8eouDCF3zhdw8ru3czSm",
        net_leverage: 0.0,
        open_ms: 1719650356892,
        orders: [
          {
            leverage: 0.1,
            order_type: "LONG",
            order_uuid: "5690a792-4de6-4268-9aa1-9f82ef3d1c76",
            price: 3388.29,
            price_sources: [],
            processed_ms: 1719650356634,
          },
          {
            leverage: 0.1,
            order_type: "LONG",
            order_uuid: "5dcf49c4-9d07-437c-81ef-bfe99e6844f4",
            price: 3369.48,
            price_sources: [],
            processed_ms: 1719701746028,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "c6500420-75d3-4795-9bd6-9f38df4098b7",
            price: 3390.84,
            price_sources: [],
            processed_ms: 1719731774486,
          },
        ],
        position_type: "FLAT",
        position_uuid: "5690a792-4de6-4268-9aa1-9f82ef3d1c76",
        return_at_close: 1.0005055245566348,
        trade_pair: ["ETHUSD", "ETH/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 3433.710508474576,
        close_ms: 1719796360527,
        current_return: 0.9630697505663578,
        initial_entry_price: 3390.08,
        is_closed_position: true,
        miner_hotkey: "5DPdXPrYCTnsUDh2nYZMCAUb3d6h8eouDCF3zhdw8ru3czSm",
        net_leverage: 0.0,
        open_ms: 1719758282043,
        orders: [
          {
            leverage: -0.2,
            order_type: "SHORT",
            order_uuid: "af368515-31c6-43ca-ace4-9b88e2c67272",
            price: 3390.08,
            price_sources: [],
            processed_ms: 1719758281441,
          },
          {
            leverage: -0.25,
            order_type: "SHORT",
            order_uuid: "9824c05c-9b35-434e-9abe-2f263623caa1",
            price: 3407.52,
            price_sources: [],
            processed_ms: 1719769093530,
          },
          {
            leverage: -0.5,
            order_type: "SHORT",
            order_uuid: "c534bcd9-7f87-4c56-930c-ea9c69a0fcbd",
            price: 3420.94,
            price_sources: [],
            processed_ms: 1719780521978,
          },
          {
            leverage: -2.0,
            order_type: "SHORT",
            order_uuid: "d5e375ce-bf81-4cd0-a2c6-4ca048bfb73f",
            price: 3444.54,
            price_sources: [],
            processed_ms: 1719787361125,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "d4b9ead7-097e-4c04-9357-eba33634921e",
            price: 3476.15,
            price_sources: [],
            processed_ms: 1719796360527,
          },
        ],
        position_type: "FLAT",
        position_uuid: "af368515-31c6-43ca-ace4-9b88e2c67272",
        return_at_close: 0.9602286948021871,
        trade_pair: ["ETHUSD", "ETH/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 62508.89493670886,
        close_ms: 1719796382227,
        current_return: 0.9334140728586984,
        initial_entry_price: 61617.9,
        is_closed_position: true,
        miner_hotkey: "5DPdXPrYCTnsUDh2nYZMCAUb3d6h8eouDCF3zhdw8ru3czSm",
        net_leverage: 0.0,
        open_ms: 1719758263776,
        orders: [
          {
            leverage: -0.25,
            order_type: "SHORT",
            order_uuid: "384b8353-29b2-4c64-8174-69bf99fe72f4",
            price: 61617.9,
            price_sources: [],
            processed_ms: 1719758262901,
          },
          {
            leverage: -0.2,
            order_type: "SHORT",
            order_uuid: "5fa41e9e-6922-479c-9470-aad0814d1055",
            price: 61841.0,
            price_sources: [],
            processed_ms: 1719769107663,
          },
          {
            leverage: -0.5,
            order_type: "SHORT",
            order_uuid: "24cf382d-12ba-4e24-87e3-55413250b9ea",
            price: 61944.66,
            price_sources: [],
            processed_ms: 1719780594559,
          },
          {
            leverage: -3.0,
            order_type: "SHORT",
            order_uuid: "a61858ac-7e90-4074-b229-64367ff65c5c",
            price: 62721.71,
            price_sources: [],
            processed_ms: 1719787464580,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "8a97e6d5-b0d2-42f5-af89-20416fe28ece",
            price: 63547.6,
            price_sources: [],
            processed_ms: 1719796382227,
          },
        ],
        position_type: "FLAT",
        position_uuid: "384b8353-29b2-4c64-8174-69bf99fe72f4",
        return_at_close: 0.9297270872709065,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 63439.48,
        close_ms: 1719808752678,
        current_return: 1.0190559569529893,
        initial_entry_price: 63439.48,
        is_closed_position: true,
        miner_hotkey: "5DPdXPrYCTnsUDh2nYZMCAUb3d6h8eouDCF3zhdw8ru3czSm",
        net_leverage: 0.0,
        open_ms: 1719796840930,
        orders: [
          {
            leverage: -5.0,
            order_type: "SHORT",
            order_uuid: "d2dd703b-b36e-47d9-94c2-b8b90b47c092",
            price: 63439.48,
            price_sources: [],
            processed_ms: 1719796840096,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "e6792091-4689-4c34-8719-e62709ea5cd3",
            price: 63197.7,
            price_sources: [],
            processed_ms: 1719808752678,
          },
        ],
        position_type: "FLAT",
        position_uuid: "d2dd703b-b36e-47d9-94c2-b8b90b47c092",
        return_at_close: 1.0139606771682244,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 3490.022,
        close_ms: 1719809393534,
        current_return: 1.0031458365026105,
        initial_entry_price: 3483.97,
        is_closed_position: true,
        miner_hotkey: "5DPdXPrYCTnsUDh2nYZMCAUb3d6h8eouDCF3zhdw8ru3czSm",
        net_leverage: 0.0,
        open_ms: 1719796655160,
        orders: [
          {
            leverage: -3.0,
            order_type: "SHORT",
            order_uuid: "8154f751-7321-4852-8a0a-dc4d091b8737",
            price: 3483.97,
            price_sources: [],
            processed_ms: 1719796654521,
          },
          {
            leverage: -2.0,
            order_type: "SHORT",
            order_uuid: "45f7a48a-3f99-4d2c-88b6-a76bc6c7ad73",
            price: 3499.1,
            price_sources: [],
            processed_ms: 1719797682308,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "668fe9f2-45d0-463c-91bd-bac9623c50b7",
            price: 3487.83,
            price_sources: [],
            processed_ms: 1719809393534,
          },
        ],
        position_type: "FLAT",
        position_uuid: "8154f751-7321-4852-8a0a-dc4d091b8737",
        return_at_close: 0.9981301073200974,
        trade_pair: ["ETHUSD", "ETH/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 3368.7419999999997,
        close_ms: 1720058575278,
        current_return: 0.0,
        initial_entry_price: 3469.37,
        is_closed_position: true,
        miner_hotkey: "5DPdXPrYCTnsUDh2nYZMCAUb3d6h8eouDCF3zhdw8ru3czSm",
        net_leverage: 20.0,
        open_ms: 1719836593883,
        orders: [
          {
            leverage: 1.0,
            order_type: "LONG",
            order_uuid: "8928810c-b051-4e93-b8fb-127638c5bb13",
            price: 3469.37,
            price_sources: [],
            processed_ms: 1719836591745,
          },
          {
            leverage: 2.5,
            order_type: "LONG",
            order_uuid: "a8a84612-03ef-4dda-99b7-f238951f1fc4",
            price: 3430.4,
            price_sources: [],
            processed_ms: 1719873082968,
          },
          {
            leverage: 3.0,
            order_type: "LONG",
            order_uuid: "aafead4d-9f5c-40a2-8e9a-d233aa0272dc",
            price: 3422.63,
            price_sources: [],
            processed_ms: 1719929506299,
          },
          {
            leverage: 5.0,
            order_type: "LONG",
            order_uuid: "95827822-70d5-4552-bd8e-48853d23e771",
            price: 3392.85,
            price_sources: [],
            processed_ms: 1719972135516,
          },
          {
            leverage: 5.0,
            order_type: "LONG",
            order_uuid: "a56deb6e-8ade-461d-a411-009dddeafa5b",
            price: 3305.7,
            price_sources: [],
            processed_ms: 1720017654497,
          },
          {
            leverage: 3.5,
            order_type: "LONG",
            order_uuid: "66cb4c32-94a4-4f26-b854-6e29e58c30af",
            price: 3305.38,
            price_sources: [],
            processed_ms: 1720019831476,
          },
        ],
        position_type: "FLAT",
        position_uuid: "8928810c-b051-4e93-b8fb-127638c5bb13",
        return_at_close: 0.0,
        trade_pair: ["ETHUSD", "ETH/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 60861.63885714287,
        close_ms: 1720061785858,
        current_return: 0.47787122728513054,
        initial_entry_price: 62613.73,
        is_closed_position: true,
        miner_hotkey: "5DPdXPrYCTnsUDh2nYZMCAUb3d6h8eouDCF3zhdw8ru3czSm",
        net_leverage: 17.5,
        open_ms: 1719836472288,
        orders: [
          {
            leverage: 1.0,
            order_type: "LONG",
            order_uuid: "c6dae5ac-cc61-4f75-8b47-4094e7b5a0ea",
            price: 62613.73,
            price_sources: [],
            processed_ms: 1719836469300,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "7bec558b-3832-42c6-9962-cb0f44dcc094",
            price: 62255.8,
            price_sources: [],
            processed_ms: 1719929814200,
          },
          {
            leverage: 5.0,
            order_type: "LONG",
            order_uuid: "d5a31eed-3109-4402-a8bb-4c5e041d736e",
            price: 61480.55,
            price_sources: [],
            processed_ms: 1719972167896,
          },
          {
            leverage: 5.0,
            order_type: "LONG",
            order_uuid: "468d26eb-6883-43f8-9ce1-27245247701c",
            price: 60255.2,
            price_sources: [],
            processed_ms: 1720017636388,
          },
          {
            leverage: 5.0,
            order_type: "LONG",
            order_uuid: "90049929-77cc-49c1-8d18-1bf0812d86ee",
            price: 60080.5,
            price_sources: [],
            processed_ms: 1720019890916,
          },
        ],
        position_type: "FLAT",
        position_uuid: "c6dae5ac-cc61-4f75-8b47-4094e7b5a0ea",
        return_at_close: 0.47368985404638564,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
    ],
    thirty_day_returns: 1.0,
  },
  "5DQ1XPp8KuDEwGP1eC9eRacpLoA1RBLGX22kk5vAMBtp3kGj": {
    all_time_returns: 1.0,
    n_positions: 0,
    percentage_profitable: 0.0,
    positions: [
      {
        average_entry_price: 1.3701750000000001,
        close_ms: 1717595734207,
        current_return: 0.9998649807506341,
        initial_entry_price: 1.3701750000000001,
        is_closed_position: true,
        miner_hotkey: "5DQ1XPp8KuDEwGP1eC9eRacpLoA1RBLGX22kk5vAMBtp3kGj",
        net_leverage: 0.0,
        open_ms: 1717595691309,
        orders: [
          {
            leverage: -0.5,
            order_type: "SHORT",
            order_uuid: "a386ed65-15b3-4f4f-804b-ff2334c97d62",
            price: 1.3701750000000001,
            price_sources: [],
            processed_ms: 1717595690385,
          },
          {
            leverage: 0.5,
            order_type: "FLAT",
            order_uuid: "972f6c23-044b-42b9-aba3-372028f5d85e",
            price: 1.370545,
            price_sources: [],
            processed_ms: 1717595734207,
          },
        ],
        position_type: "FLAT",
        position_uuid: "0184e13c-371f-472d-9b4d-79311e0166fc",
        return_at_close: 0.9998299854763079,
        trade_pair: ["USDCAD", "USD/CAD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 1.0888,
        close_ms: 1717596067961,
        current_return: 0.9995775165319618,
        initial_entry_price: 1.0888,
        is_closed_position: true,
        miner_hotkey: "5DQ1XPp8KuDEwGP1eC9eRacpLoA1RBLGX22kk5vAMBtp3kGj",
        net_leverage: 0.0,
        open_ms: 1717595090025,
        orders: [
          {
            leverage: 0.5,
            order_type: "LONG",
            order_uuid: "bb3f0f67-4405-45a5-9198-27d46ae019f4",
            price: 1.0888,
            price_sources: [],
            processed_ms: 1717595090020,
          },
          {
            leverage: 0.5,
            order_type: "FLAT",
            order_uuid: "7a336d25-db56-4bbf-86f0-9beeac8dadf1",
            price: 1.08788,
            price_sources: [],
            processed_ms: 1717596067961,
          },
        ],
        position_type: "FLAT",
        position_uuid: "9052242b-b876-4c11-8531-73c0f21e0bcf",
        return_at_close: 0.9995425313188832,
        trade_pair: ["EURUSD", "EUR/USD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 1.08795,
        close_ms: 1717608054729,
        current_return: 1.0008042649018796,
        initial_entry_price: 1.08795,
        is_closed_position: true,
        miner_hotkey: "5DQ1XPp8KuDEwGP1eC9eRacpLoA1RBLGX22kk5vAMBtp3kGj",
        net_leverage: 0.0,
        open_ms: 1717596145845,
        orders: [
          {
            leverage: -0.5,
            order_type: "SHORT",
            order_uuid: "6455e3be-4af7-42d9-92a0-6c58e6d7d322",
            price: 1.08795,
            price_sources: [],
            processed_ms: 1717596145842,
          },
          {
            leverage: 0.01,
            order_type: "FLAT",
            order_uuid: "47ff9e6a-ac59-4054-a760-a016a6858ee7",
            price: 1.0862,
            price_sources: [],
            processed_ms: 1717608054729,
          },
        ],
        position_type: "FLAT",
        position_uuid: "698d4dc1-a7fb-49d8-9c69-f90220968808",
        return_at_close: 1.000769236752608,
        trade_pair: ["EURUSD", "EUR/USD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.851825,
        close_ms: 1717744041249,
        current_return: 1.0003433803891644,
        initial_entry_price: 0.851825,
        is_closed_position: true,
        miner_hotkey: "5DQ1XPp8KuDEwGP1eC9eRacpLoA1RBLGX22kk5vAMBtp3kGj",
        net_leverage: 0.0,
        open_ms: 1717677584113,
        orders: [
          {
            leverage: -0.5,
            order_type: "SHORT",
            order_uuid: "4b002fa5-ef86-4851-8365-5b7a15128bd5",
            price: 0.851825,
            price_sources: [],
            processed_ms: 1717677584111,
          },
          {
            leverage: 0.5,
            order_type: "FLAT",
            order_uuid: "c45a84cc-4da3-409d-988e-105ad13a8717",
            price: 0.85124,
            price_sources: [],
            processed_ms: 1717744041249,
          },
        ],
        position_type: "FLAT",
        position_uuid: "cec7ca38-3284-4d9b-82cf-0dab59733f5b",
        return_at_close: 1.0003083683708507,
        trade_pair: ["EURGBP", "EUR/GBP", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 69331.27,
        close_ms: 1717963096247,
        current_return: 1.001109311858848,
        initial_entry_price: 69331.27,
        is_closed_position: true,
        miner_hotkey: "5DQ1XPp8KuDEwGP1eC9eRacpLoA1RBLGX22kk5vAMBtp3kGj",
        net_leverage: 0.0,
        open_ms: 1717820598259,
        orders: [
          {
            leverage: 0.2,
            order_type: "LONG",
            order_uuid: "105a1a8a-0708-4f75-8002-d064776a30f0",
            price: 69331.27,
            price_sources: [],
            processed_ms: 1717820598026,
          },
          {
            leverage: 0.2,
            order_type: "FLAT",
            order_uuid: "be8c5b7d-4051-4952-8b64-15ef3e5bd93b",
            price: 69715.82,
            price_sources: [],
            processed_ms: 1717963096247,
          },
        ],
        position_type: "FLAT",
        position_uuid: "c3dcc992-a260-41ce-bc1b-81b7fbc8b70f",
        return_at_close: 1.0009090899964763,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 1.0991024999999999,
        close_ms: 1717968249725,
        current_return: 1.0040247151782433,
        initial_entry_price: 1.0884,
        is_closed_position: true,
        miner_hotkey: "5DQ1XPp8KuDEwGP1eC9eRacpLoA1RBLGX22kk5vAMBtp3kGj",
        net_leverage: 0.0,
        open_ms: 1717651390444,
        orders: [
          {
            leverage: -0.5,
            order_type: "SHORT",
            order_uuid: "d15e6f4b-f2d5-4a58-a0fd-e2e3223a36e4",
            price: 1.0884,
            price_sources: [],
            processed_ms: 1717651390441,
          },
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "7af80f2a-9715-4105-ac57-712f02a057a5",
            price: 1.0812650000000001,
            price_sources: [],
            processed_ms: 1717772063364,
          },
          {
            leverage: 0.01,
            order_type: "FLAT",
            order_uuid: "40b46f80-a393-429b-aca6-13f2da51991d",
            price: 1.0772,
            price_sources: [],
            processed_ms: 1717968249725,
          },
        ],
        position_type: "FLAT",
        position_uuid: "354f24fa-4487-44e9-b83d-47de331361f8",
        return_at_close: 1.0039895743132121,
        trade_pair: ["EURUSD", "EUR/USD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 1.3713800000000003,
        close_ms: 1718006095713,
        current_return: 1.0018357664233575,
        initial_entry_price: 1.37,
        is_closed_position: true,
        miner_hotkey: "5DQ1XPp8KuDEwGP1eC9eRacpLoA1RBLGX22kk5vAMBtp3kGj",
        net_leverage: 0.0,
        open_ms: 1717608364676,
        orders: [
          {
            leverage: 0.5,
            order_type: "LONG",
            order_uuid: "75482d88-8ae3-4d80-b209-01b0dc47e58d",
            price: 1.37,
            price_sources: [],
            processed_ms: 1717608364653,
          },
          {
            leverage: -0.3,
            order_type: "SHORT",
            order_uuid: "ca666cc1-c7d8-4cd2-b0a9-abc1e559a4e4",
            price: 1.3739,
            price_sources: [],
            processed_ms: 1717772106197,
          },
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "148f4560-bca5-492b-8650-ca725eae7c0b",
            price: 1.3762,
            price_sources: [],
            processed_ms: 1717968219739,
          },
          {
            leverage: 0.5,
            order_type: "FLAT",
            order_uuid: "f7319779-21fc-4266-b976-3f00c02eeb8f",
            price: 1.37641,
            price_sources: [],
            processed_ms: 1718006095713,
          },
        ],
        position_type: "FLAT",
        position_uuid: "675a1702-1c5a-40c7-afc3-a9abc3ae62d2",
        return_at_close: 1.001779663620438,
        trade_pair: ["USDCAD", "USD/CAD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 102.797,
        close_ms: 1718194628555,
        current_return: 0.9941437979707579,
        initial_entry_price: 102.797,
        is_closed_position: true,
        miner_hotkey: "5DQ1XPp8KuDEwGP1eC9eRacpLoA1RBLGX22kk5vAMBtp3kGj",
        net_leverage: 0.0,
        open_ms: 1717524540100,
        orders: [
          {
            leverage: -0.5,
            order_type: "SHORT",
            order_uuid: "77bf8e04-d6c6-4231-bf2c-4ba8d5424ca5",
            price: 102.797,
            price_sources: [],
            processed_ms: 1717524540099,
          },
          {
            leverage: 0.5,
            order_type: "FLAT",
            order_uuid: "d436fb5a-d318-4589-a0c1-677d2920d914",
            price: 104.001,
            price_sources: [],
            processed_ms: 1718194628555,
          },
        ],
        position_type: "FLAT",
        position_uuid: "4eb0ed76-7100-46fc-af56-e3d4b91556d0",
        return_at_close: 0.9941090029378289,
        trade_pair: ["AUDJPY", "AUD/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 156.0695,
        close_ms: 1718197462832,
        current_return: 0.9998286098643108,
        initial_entry_price: 154.618,
        is_closed_position: true,
        miner_hotkey: "5DQ1XPp8KuDEwGP1eC9eRacpLoA1RBLGX22kk5vAMBtp3kGj",
        net_leverage: 0.0,
        open_ms: 1717524153509,
        orders: [
          {
            leverage: -0.5,
            order_type: "SHORT",
            order_uuid: "2d1b39df-1b4e-4fbf-8f0d-93029156c570",
            price: 154.618,
            price_sources: [],
            processed_ms: 1717524153507,
          },
          {
            leverage: -0.5,
            order_type: "SHORT",
            order_uuid: "c950aa56-6ec3-47e0-8cf3-bdeb540addf7",
            price: 157.168,
            price_sources: [],
            processed_ms: 1718100995522,
          },
          {
            leverage: 0.5,
            order_type: "LONG",
            order_uuid: "c69cd7e7-eb16-4ef3-9073-ffacdd2d0317",
            price: 156.898,
            price_sources: [],
            processed_ms: 1718110210154,
          },
          {
            leverage: -0.5,
            order_type: "SHORT",
            order_uuid: "fb2d7938-efe9-4528-b46f-822fb8745825",
            price: 157.251,
            price_sources: [],
            processed_ms: 1718194677958,
          },
          {
            leverage: 0.5,
            order_type: "FLAT",
            order_uuid: "5fa4c571-11a1-4661-8e4d-4d7a744aadf1",
            price: 156.096,
            price_sources: [],
            processed_ms: 1718197462832,
          },
        ],
        position_type: "FLAT",
        position_uuid: "a846d4c4-4414-43a4-8177-91fdf69162de",
        return_at_close: 0.999723627860275,
        trade_pair: ["USDJPY", "USD/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 113.03399999999999,
        close_ms: 1718263498923,
        current_return: 0.9939310296017126,
        initial_entry_price: 113.03399999999999,
        is_closed_position: true,
        miner_hotkey: "5DQ1XPp8KuDEwGP1eC9eRacpLoA1RBLGX22kk5vAMBtp3kGj",
        net_leverage: 0.0,
        open_ms: 1717524191058,
        orders: [
          {
            leverage: -0.5,
            order_type: "SHORT",
            order_uuid: "b430d061-d8fa-4d7b-a8ca-c5da64b548f5",
            price: 113.03399999999999,
            price_sources: [],
            processed_ms: 1717524191056,
          },
          {
            leverage: 0.01,
            order_type: "FLAT",
            order_uuid: "1b3db821-5d71-4a91-8316-41a17d58ee31",
            price: 114.406,
            price_sources: [],
            processed_ms: 1718263498923,
          },
        ],
        position_type: "FLAT",
        position_uuid: "24385fe6-f5f8-4f32-b0cb-7d765fdaaf69",
        return_at_close: 0.9938962420156766,
        trade_pair: ["CADJPY", "CAD/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.6509,
        close_ms: 1718263534631,
        current_return: 0.9993163312336765,
        initial_entry_price: 0.6509,
        is_closed_position: true,
        miner_hotkey: "5DQ1XPp8KuDEwGP1eC9eRacpLoA1RBLGX22kk5vAMBtp3kGj",
        net_leverage: 0.0,
        open_ms: 1718048302854,
        orders: [
          {
            leverage: -0.5,
            order_type: "SHORT",
            order_uuid: "12d4889d-f300-4b9c-9a9e-383ca1f28055",
            price: 0.6509,
            price_sources: [],
            processed_ms: 1718048302840,
          },
          {
            leverage: 0.01,
            order_type: "FLAT",
            order_uuid: "1b862704-96cf-4f16-ab26-b39f5709d2d6",
            price: 0.65179,
            price_sources: [],
            processed_ms: 1718263534631,
          },
        ],
        position_type: "FLAT",
        position_uuid: "363afda3-a106-44f2-9a72-17f69c811b7f",
        return_at_close: 0.9992813551620833,
        trade_pair: ["CADCHF", "CAD/CHF", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 1.4776275595238093,
        close_ms: 1718296823666,
        current_return: 0.9907133647894342,
        initial_entry_price: 1.48143,
        is_closed_position: true,
        miner_hotkey: "5DQ1XPp8KuDEwGP1eC9eRacpLoA1RBLGX22kk5vAMBtp3kGj",
        net_leverage: 0.0,
        open_ms: 1718006142285,
        orders: [
          {
            leverage: 0.5,
            order_type: "LONG",
            order_uuid: "45fc9924-3726-489f-860f-c24e24a9422f",
            price: 1.48143,
            price_sources: [],
            processed_ms: 1718006142282,
          },
          {
            leverage: 0.5,
            order_type: "LONG",
            order_uuid: "60381602-c14f-4820-848b-e95405a9b662",
            price: 1.4781849999999999,
            price_sources: [],
            processed_ms: 1718110164453,
          },
          {
            leverage: 1.0,
            order_type: "LONG",
            order_uuid: "9c0a7849-35d0-47c4-837a-009592b4f257",
            price: 1.47745,
            price_sources: [],
            processed_ms: 1718131038611,
          },
          {
            leverage: 20.0,
            order_type: "LONG",
            order_uuid: "0c63c730-c68a-4a2a-97aa-3bae8d4ab36b",
            price: 1.47782,
            price_sources: [],
            processed_ms: 1718295742362,
          },
          {
            leverage: 20.0,
            order_type: "LONG",
            order_uuid: "ee60266f-ef3c-403d-8639-6d02a3e3077a",
            price: 1.477335,
            price_sources: [],
            processed_ms: 1718296470578,
          },
          {
            leverage: 20.0,
            order_type: "FLAT",
            order_uuid: "c4f7ce22-160b-4b38-8258-4ca034dff227",
            price: 1.4773,
            price_sources: [],
            processed_ms: 1718296823666,
          },
        ],
        position_type: "FLAT",
        position_uuid: "b9b8fb3c-4747-4728-a022-31f6b1a178e8",
        return_at_close: 0.9878006674969532,
        trade_pair: ["EURCAD", "EUR/CAD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 1.0742414146341464,
        close_ms: 1718296857358,
        current_return: 0.9813596328917168,
        initial_entry_price: 1.08088,
        is_closed_position: true,
        miner_hotkey: "5DQ1XPp8KuDEwGP1eC9eRacpLoA1RBLGX22kk5vAMBtp3kGj",
        net_leverage: 0.0,
        open_ms: 1718263422075,
        orders: [
          {
            leverage: 0.1,
            order_type: "LONG",
            order_uuid: "4e84866f-cc65-40d4-b096-9c1883da1453",
            price: 1.08088,
            price_sources: [],
            processed_ms: 1718263421933,
          },
          {
            leverage: 0.9,
            order_type: "LONG",
            order_uuid: "42d59056-2b30-4d61-bb32-bbf515615c9e",
            price: 1.0809,
            price_sources: [],
            processed_ms: 1718263468189,
          },
          {
            leverage: 20.0,
            order_type: "LONG",
            order_uuid: "39774eff-72b3-43b8-a2f2-8c0b4df9de50",
            price: 1.07416,
            price_sources: [],
            processed_ms: 1718296102857,
          },
          {
            leverage: 20.0,
            order_type: "LONG",
            order_uuid: "a693b6b2-0e96-44fd-86d1-284c4b8d93c0",
            price: 1.07399,
            price_sources: [],
            processed_ms: 1718296440987,
          },
          {
            leverage: 20.0,
            order_type: "FLAT",
            order_uuid: "9fdba866-efca-4be0-8c7e-37d7754fafc3",
            price: 1.07375,
            price_sources: [],
            processed_ms: 1718296857358,
          },
        ],
        position_type: "FLAT",
        position_uuid: "62796282-dad4-4280-851b-f4a8a29ff6a2",
        return_at_close: 0.9785431307453175,
        trade_pair: ["EURUSD", "EUR/USD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.84272,
        close_ms: 1718296861376,
        current_return: 0.9999406683121321,
        initial_entry_price: 0.84272,
        is_closed_position: true,
        miner_hotkey: "5DQ1XPp8KuDEwGP1eC9eRacpLoA1RBLGX22kk5vAMBtp3kGj",
        net_leverage: 0.0,
        open_ms: 1718199021337,
        orders: [
          {
            leverage: 0.5,
            order_type: "LONG",
            order_uuid: "1fc39805-12ef-4420-9b76-b84ffae2b0be",
            price: 0.84272,
            price_sources: [],
            processed_ms: 1718199021158,
          },
          {
            leverage: 20.0,
            order_type: "FLAT",
            order_uuid: "07b63a9b-4fae-4fbc-8523-fe457bc7cc1d",
            price: 0.84262,
            price_sources: [],
            processed_ms: 1718296861376,
          },
        ],
        position_type: "FLAT",
        position_uuid: "0c9b4053-461b-4a95-8202-64af914b95c4",
        return_at_close: 0.9999056703887412,
        trade_pair: ["EURGBP", "EUR/GBP", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 1.2741125,
        close_ms: 1718297584403,
        current_return: 0.9548681561483313,
        initial_entry_price: 1.274045,
        is_closed_position: true,
        miner_hotkey: "5DQ1XPp8KuDEwGP1eC9eRacpLoA1RBLGX22kk5vAMBtp3kGj",
        net_leverage: 0.0,
        open_ms: 1718296948578,
        orders: [
          {
            leverage: -100.0,
            order_type: "SHORT",
            order_uuid: "9e34a441-1a60-4fc2-9a93-08facc99bcdf",
            price: 1.274045,
            price_sources: [],
            processed_ms: 1718296948449,
          },
          {
            leverage: -100.0,
            order_type: "SHORT",
            order_uuid: "1ebc9813-9180-492b-9756-bf0db09a8129",
            price: 1.2741799999999999,
            price_sources: [],
            processed_ms: 1718297018600,
          },
          {
            leverage: 200.0,
            order_type: "FLAT",
            order_uuid: "9d0c3b6a-1412-4cdc-9448-0538e3692044",
            price: 1.2744,
            price_sources: [],
            processed_ms: 1718297584403,
          },
        ],
        position_type: "FLAT",
        position_uuid: "67394338-5893-4831-9765-6864578b06a0",
        return_at_close: 0.9415000019622547,
        trade_pair: ["GBPUSD", "GBP/USD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 1.27442,
        close_ms: 1718297768356,
        current_return: 0.9921532932628359,
        initial_entry_price: 1.27442,
        is_closed_position: true,
        miner_hotkey: "5DQ1XPp8KuDEwGP1eC9eRacpLoA1RBLGX22kk5vAMBtp3kGj",
        net_leverage: 0.0,
        open_ms: 1718297629519,
        orders: [
          {
            leverage: -200.0,
            order_type: "SHORT",
            order_uuid: "42d0d0e8-be54-42b4-8924-63c92db0c3d6",
            price: 1.27442,
            price_sources: [],
            processed_ms: 1718297629484,
          },
          {
            leverage: 200.0,
            order_type: "FLAT",
            order_uuid: "a80a8b49-c2ba-4678-971e-b40c854107d3",
            price: 1.27447,
            price_sources: [],
            processed_ms: 1718297768356,
          },
        ],
        position_type: "FLAT",
        position_uuid: "8f0a82de-8fa4-4681-9928-af8fce0d5902",
        return_at_close: 0.9782631471571561,
        trade_pair: ["GBPUSD", "GBP/USD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 1.4765000000000001,
        close_ms: 1718297800201,
        current_return: 1.0270910938029394,
        initial_entry_price: 1.4765,
        is_closed_position: true,
        miner_hotkey: "5DQ1XPp8KuDEwGP1eC9eRacpLoA1RBLGX22kk5vAMBtp3kGj",
        net_leverage: 0.0,
        open_ms: 1718297553757,
        orders: [
          {
            leverage: -200.0,
            order_type: "SHORT",
            order_uuid: "8ed6c041-6aff-42cc-8733-c78bcf76f414",
            price: 1.4765,
            price_sources: [],
            processed_ms: 1718297553669,
          },
          {
            leverage: 200.0,
            order_type: "FLAT",
            order_uuid: "8601e26b-e5ff-4bf0-9155-201add0de7ac",
            price: 1.4763,
            price_sources: [],
            processed_ms: 1718297800201,
          },
        ],
        position_type: "FLAT",
        position_uuid: "5058409d-9699-4b99-afaf-bdfd04044668",
        return_at_close: 1.0127118184896982,
        trade_pair: ["EURCAD", "EUR/CAD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 1.0175249999999778,
        close_ms: 1718298639142,
        current_return: 1.201967815081892,
        initial_entry_price: 1.27451,
        is_closed_position: true,
        miner_hotkey: "5DQ1XPp8KuDEwGP1eC9eRacpLoA1RBLGX22kk5vAMBtp3kGj",
        net_leverage: 0.0,
        open_ms: 1718297829503,
        orders: [
          {
            leverage: 500.0,
            order_type: "LONG",
            order_uuid: "57843037-531b-408b-91b9-6152f525a19e",
            price: 1.27451,
            price_sources: [],
            processed_ms: 1718297829493,
          },
          {
            leverage: -499.0,
            order_type: "SHORT",
            order_uuid: "8ee92515-a663-4930-b6b6-f2f2ce98a9cd",
            price: 1.275025,
            price_sources: [],
            processed_ms: 1718298576621,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "23a2b155-4c0d-458f-bdd6-9bb6eed337bd",
            price: 1.274935,
            price_sources: [],
            processed_ms: 1718298639142,
          },
        ],
        position_type: "FLAT",
        position_uuid: "1415528c-54af-44b1-b546-2d90e87c72a3",
        return_at_close: 1.1598989415540257,
        trade_pair: ["GBPUSD", "GBP/USD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 1.5209400000001096,
        close_ms: 1718298677449,
        current_return: 0.9693840911091849,
        initial_entry_price: 1.4760300000000002,
        is_closed_position: true,
        miner_hotkey: "5DQ1XPp8KuDEwGP1eC9eRacpLoA1RBLGX22kk5vAMBtp3kGj",
        net_leverage: 0.0,
        open_ms: 1718297948548,
        orders: [
          {
            leverage: 500.0,
            order_type: "LONG",
            order_uuid: "fa60c47a-a0fa-4c08-833c-2185c0b264e0",
            price: 1.4760300000000002,
            price_sources: [],
            processed_ms: 1718297948517,
          },
          {
            leverage: -499.0,
            order_type: "SHORT",
            order_uuid: "a123887c-fd3e-42a4-a47d-2e1837611a2d",
            price: 1.47594,
            price_sources: [],
            processed_ms: 1718298607967,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "9c377a00-499e-4270-b595-4ed085249da1",
            price: 1.47575,
            price_sources: [],
            processed_ms: 1718298677449,
          },
        ],
        position_type: "FLAT",
        position_uuid: "ee2b25b3-fc53-4730-84c4-d05ccba1cdfd",
        return_at_close: 0.9354556479203634,
        trade_pair: ["EURCAD", "EUR/CAD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 1.27521,
        close_ms: 1718307740974,
        current_return: 1.4891351228425018,
        initial_entry_price: 1.27521,
        is_closed_position: true,
        miner_hotkey: "5DQ1XPp8KuDEwGP1eC9eRacpLoA1RBLGX22kk5vAMBtp3kGj",
        net_leverage: 0.0,
        open_ms: 1718298713681,
        orders: [
          {
            leverage: 499.0,
            order_type: "LONG",
            order_uuid: "acf0a6d1-f706-4eff-9771-bc38a9f5b06d",
            price: 1.27521,
            price_sources: [],
            processed_ms: 1718298713650,
          },
          {
            leverage: 0.5,
            order_type: "FLAT",
            order_uuid: "c6b2c465-9cbd-49bd-a688-f904d8cf24ca",
            price: 1.27646,
            price_sources: [],
            processed_ms: 1718307740974,
          },
        ],
        position_type: "FLAT",
        position_uuid: "edbbb2d6-99e4-4862-b18c-2d55a2f2d1b5",
        return_at_close: 1.4371196330016132,
        trade_pair: ["GBPUSD", "GBP/USD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.61684,
        close_ms: 1718307774008,
        current_return: 0.9676415277867519,
        initial_entry_price: 0.61684,
        is_closed_position: true,
        miner_hotkey: "5DQ1XPp8KuDEwGP1eC9eRacpLoA1RBLGX22kk5vAMBtp3kGj",
        net_leverage: 0.0,
        open_ms: 1718298797645,
        orders: [
          {
            leverage: 499.0,
            order_type: "LONG",
            order_uuid: "7110e7ef-aeec-495f-8535-b09eb178e4c8",
            price: 0.61684,
            price_sources: [],
            processed_ms: 1718298797613,
          },
          {
            leverage: 0.5,
            order_type: "FLAT",
            order_uuid: "ca866e0d-00eb-4b6c-bf4f-0dbf9b0f6ec3",
            price: 0.6168,
            price_sources: [],
            processed_ms: 1718307774008,
          },
        ],
        position_type: "FLAT",
        position_uuid: "4f6cf26f-d3b3-4bce-95a4-023e9eb3749f",
        return_at_close: 0.9338418092211607,
        trade_pair: ["NZDUSD", "NZD/USD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 1.07412,
        close_ms: 1720078337380,
        current_return: 1.0004776002681264,
        initial_entry_price: 1.07412,
        is_closed_position: true,
        miner_hotkey: "5DQ1XPp8KuDEwGP1eC9eRacpLoA1RBLGX22kk5vAMBtp3kGj",
        net_leverage: 0.0,
        open_ms: 1718307839473,
        orders: [
          {
            leverage: 0.1,
            order_type: "LONG",
            order_uuid: "70c5900c-b3c3-4303-b3de-234750357b12",
            price: 1.07412,
            price_sources: [],
            processed_ms: 1718307839110,
          },
          {
            leverage: 0.01,
            order_type: "FLAT",
            order_uuid: "076ffc8c-ee39-47cd-8c4b-d30f26322d97",
            price: 1.07925,
            price_sources: [
              {
                close: 1.07925,
                high: 1.07925,
                lag_ms: 380,
                low: 1.07925,
                open: 1.07925,
                source: "Polygon_ws",
                start_ms: 1720078337000,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 1.0793,
                websocket: true,
              },
              {
                close: 1.0793,
                high: 1.0793,
                lag_ms: 620,
                low: 1.0793,
                open: 1.0793,
                source: "TwelveData_ws",
                start_ms: 1720078338000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1720078337380,
          },
        ],
        position_type: "FLAT",
        position_uuid: "2f56c208-7af2-4776-b8f1-f84c623ce3d7",
        return_at_close: 1.0004705969249246,
        trade_pair: ["EURUSD", "EUR/USD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 3130.507,
        close_ms: 0,
        current_return: 0.9841508640704809,
        initial_entry_price: 3187.24,
        is_closed_position: false,
        miner_hotkey: "5DQ1XPp8KuDEwGP1eC9eRacpLoA1RBLGX22kk5vAMBtp3kGj",
        net_leverage: -5.0,
        open_ms: 1720078388544,
        orders: [
          {
            leverage: -0.1,
            order_type: "SHORT",
            order_uuid: "0d8f6fc5-f661-4350-afd5-90102945f180",
            price: 3187.24,
            price_sources: [
              {
                close: 3187.24,
                high: 3187.24,
                lag_ms: 164,
                low: 3187.24,
                open: 3187.24,
                source: "Polygon_ws",
                start_ms: 1720078387000,
                timespan_ms: 0,
                volume: 0.4,
                vwap: 3187.24,
                websocket: true,
              },
              {
                close: 3188.76,
                high: 3188.76,
                lag_ms: 836,
                low: 3188.76,
                open: 3188.76,
                source: "TwelveData_ws",
                start_ms: 1720078388000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
              {
                close: 3186.39,
                high: 3186.74,
                lag_ms: 2165,
                low: 3186.18,
                open: 3186.74,
                source: "Polygon_rest",
                start_ms: 1720078384000,
                timespan_ms: 1000,
                volume: 10.99355366,
                vwap: 3186.2886,
                websocket: false,
              },
              {
                close: 3193.67993,
                high: 3196.51001,
                lag_ms: 67165,
                low: 3192.86011,
                open: 3196.51001,
                source: "TwelveData_rest",
                start_ms: 1720078260000,
                timespan_ms: 60000,
                volume: null,
                vwap: null,
                websocket: false,
              },
            ],
            processed_ms: 1720078387164,
          },
          {
            leverage: -0.4,
            order_type: "SHORT",
            order_uuid: "e2ee1589-df83-4b9e-b2f6-0c6a9de1571c",
            price: 3191.44,
            price_sources: [
              {
                close: 3191.44,
                high: 3191.44,
                lag_ms: 64,
                low: 3191.44,
                open: 3191.44,
                source: "Polygon_ws",
                start_ms: 1720078419999,
                timespan_ms: 0,
                volume: 0.32304542,
                vwap: 3191.44,
                websocket: true,
              },
              {
                close: 3193.9,
                high: 3193.9,
                lag_ms: 1937,
                low: 3193.9,
                open: 3193.9,
                source: "TwelveData_ws",
                start_ms: 1720078422000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
              {
                close: 3191.26,
                high: 3191.3,
                lag_ms: 2064,
                low: 3191.26,
                open: 3191.3,
                source: "Polygon_rest",
                start_ms: 1720078417000,
                timespan_ms: 1000,
                volume: 0.4001316,
                vwap: 3191.3,
                websocket: false,
              },
              {
                close: 3190.1001,
                high: 3193.69995,
                lag_ms: 40064,
                low: 3190.1001,
                open: 3193.40991,
                source: "TwelveData_rest",
                start_ms: 1720078320000,
                timespan_ms: 60000,
                volume: null,
                vwap: null,
                websocket: false,
              },
            ],
            processed_ms: 1720078420063,
          },
          {
            leverage: -4.5,
            order_type: "SHORT",
            order_uuid: "2116eb99-4252-48ee-a75a-813e528255b6",
            price: 3123.83,
            price_sources: [
              {
                close: 3123.83,
                high: 3124.0,
                lag_ms: 116,
                low: 3123.11,
                open: 3123.83,
                source: "Polygon_ws",
                start_ms: 1720111649999,
                timespan_ms: 0,
                volume: 10.3973459,
                vwap: 3123.6771,
                websocket: true,
              },
              {
                close: 3122.94,
                high: 3122.94,
                lag_ms: 2884,
                low: 3122.94,
                open: 3122.94,
                source: "Polygon_rest",
                start_ms: 1720111646000,
                timespan_ms: 1000,
                volume: 0.0582211,
                vwap: 3122.94,
                websocket: false,
              },
              {
                close: 3126.0,
                high: 3126.0,
                lag_ms: 3883,
                low: 3126.0,
                open: 3126.0,
                source: "TwelveData_ws",
                start_ms: 1720111646000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
              {
                close: 3131.12012,
                high: 3133.29004,
                lag_ms: 209884,
                low: 3130.72998,
                open: 3133.29004,
                source: "TwelveData_rest",
                start_ms: 1720111380000,
                timespan_ms: 60000,
                volume: null,
                vwap: null,
                websocket: false,
              },
            ],
            processed_ms: 1720111649883,
          },
        ],
        position_type: "SHORT",
        position_uuid: "1cd0b2e4-5eee-4f1b-ac5f-1770abab1adf",
        return_at_close: 0.9816904869103048,
        trade_pair: ["ETHUSD", "ETH/USD", 0.003, 0.001, 20],
      },
    ],
    thirty_day_returns: 1.0,
  },
  "5DRNu7apWdLWq3tSY5MPSQRAf2n4vqKtQhHJmH7pfYNXXaD6": {
    all_time_returns: 1.0,
    n_positions: 0,
    percentage_profitable: 0.0,
    positions: [
      {
        average_entry_price: 64390.9,
        close_ms: 1711237087295,
        current_return: 1.0,
        initial_entry_price: 64390.9,
        is_closed_position: true,
        miner_hotkey: "5DRNu7apWdLWq3tSY5MPSQRAf2n4vqKtQhHJmH7pfYNXXaD6",
        net_leverage: 0.0,
        open_ms: 1711237068512,
        orders: [
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "d12608fd-531e-49c6-88ab-ae00518c75b0",
            price: 64390.9,
            price_sources: [],
            processed_ms: 1711237068511,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "e04c1c7f-836a-4290-98c3-bbcca899953f",
            price: 64390.9,
            price_sources: [],
            processed_ms: 1711237087295,
          },
        ],
        position_type: "FLAT",
        position_uuid: "9206e8a5-f220-48b6-81ba-86ff01bcc337",
        return_at_close: 0.9994,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 64119.86,
        close_ms: 1711255346805,
        current_return: 0.9994016830354902,
        initial_entry_price: 64119.86,
        is_closed_position: true,
        miner_hotkey: "5DRNu7apWdLWq3tSY5MPSQRAf2n4vqKtQhHJmH7pfYNXXaD6",
        net_leverage: 0.0,
        open_ms: 1711251788475,
        orders: [
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "516a1aa0-b7a1-4dd0-90c5-f31c2489b98e",
            price: 64119.86,
            price_sources: [],
            processed_ms: 1711251788474,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "9dd8c6c9-3d6a-4ac5-876d-dbb23fccc569",
            price: 63991.98,
            price_sources: [],
            processed_ms: 1711255346805,
          },
        ],
        position_type: "FLAT",
        position_uuid: "fcd11716-0406-4996-a139-2f2159e5f2c9",
        return_at_close: 0.9988020420256688,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 64022.9,
        close_ms: 1711258479862,
        current_return: 1.0014138534805515,
        initial_entry_price: 64022.9,
        is_closed_position: true,
        miner_hotkey: "5DRNu7apWdLWq3tSY5MPSQRAf2n4vqKtQhHJmH7pfYNXXaD6",
        net_leverage: 0.0,
        open_ms: 1711255433690,
        orders: [
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "48b8df23-588f-40f7-bec0-97fde59169bf",
            price: 64022.9,
            price_sources: [],
            processed_ms: 1711255433690,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "6d87286f-fe69-4065-a8ad-0354708d5c4b",
            price: 64324.63,
            price_sources: [],
            processed_ms: 1711258479862,
          },
        ],
        position_type: "FLAT",
        position_uuid: "aa9c35f6-8b8f-49c8-898d-21293b4861bf",
        return_at_close: 1.0008130051684632,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 64337.89,
        close_ms: 1711262145787,
        current_return: 0.9981377614342031,
        initial_entry_price: 64337.89,
        is_closed_position: true,
        miner_hotkey: "5DRNu7apWdLWq3tSY5MPSQRAf2n4vqKtQhHJmH7pfYNXXaD6",
        net_leverage: 0.0,
        open_ms: 1711258568244,
        orders: [
          {
            leverage: 1.25,
            order_type: "LONG",
            order_uuid: "cb3db13c-c8f5-4598-ae81-9a1e0ce957f1",
            price: 64337.89,
            price_sources: [],
            processed_ms: 1711258568243,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "c2da92ec-c807-4a12-a817-54d6b0fb1d5a",
            price: 64242.04,
            price_sources: [],
            processed_ms: 1711262145787,
          },
        ],
        position_type: "FLAT",
        position_uuid: "bd573d20-10e1-41ce-ac07-cfc4a073f886",
        return_at_close: 0.9956424170306176,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 64227.27,
        close_ms: 1711265802203,
        current_return: 0.9981322186043406,
        initial_entry_price: 64227.27,
        is_closed_position: true,
        miner_hotkey: "5DRNu7apWdLWq3tSY5MPSQRAf2n4vqKtQhHJmH7pfYNXXaD6",
        net_leverage: 0.0,
        open_ms: 1711262232662,
        orders: [
          {
            leverage: 1.25,
            order_type: "LONG",
            order_uuid: "35e239e0-c8f2-450e-878a-25431951001c",
            price: 64227.27,
            price_sources: [],
            processed_ms: 1711262232662,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "9ffdaa83-c73c-4bb9-b967-8e9503bd10e7",
            price: 64131.3,
            price_sources: [],
            processed_ms: 1711265802203,
          },
        ],
        position_type: "FLAT",
        position_uuid: "76fdf690-313e-40e5-9c59-fc10c4139a73",
        return_at_close: 0.9956368880578299,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 64118.4,
        close_ms: 1711269469431,
        current_return: 1.0135945765957979,
        initial_entry_price: 64118.4,
        is_closed_position: true,
        miner_hotkey: "5DRNu7apWdLWq3tSY5MPSQRAf2n4vqKtQhHJmH7pfYNXXaD6",
        net_leverage: 0.0,
        open_ms: 1711265888829,
        orders: [
          {
            leverage: 1.25,
            order_type: "LONG",
            order_uuid: "3c01ab4c-7887-49ee-b0a1-11dcdc5936ff",
            price: 64118.4,
            price_sources: [],
            processed_ms: 1711265888828,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "3b72df07-c152-40e7-86d1-a64da503d81a",
            price: 64815.73,
            price_sources: [],
            processed_ms: 1711269469431,
          },
        ],
        position_type: "FLAT",
        position_uuid: "1c859c0a-c22a-46bd-a19d-6a9085462c9c",
        return_at_close: 1.0110605901543084,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 64822.48,
        close_ms: 1711273126940,
        current_return: 1.0047603084608918,
        initial_entry_price: 64822.48,
        is_closed_position: true,
        miner_hotkey: "5DRNu7apWdLWq3tSY5MPSQRAf2n4vqKtQhHJmH7pfYNXXaD6",
        net_leverage: 0.0,
        open_ms: 1711269572590,
        orders: [
          {
            leverage: 1.25,
            order_type: "LONG",
            order_uuid: "ac155d69-43fa-42a4-bb86-6bf5df74c1f7",
            price: 64822.48,
            price_sources: [],
            processed_ms: 1711269572590,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "5cc8387d-0c6c-4628-a994-3e86ece6fe96",
            price: 65069.34,
            price_sources: [],
            processed_ms: 1711273126940,
          },
        ],
        position_type: "FLAT",
        position_uuid: "f3f61a8d-acae-477a-bc0b-732fb119209c",
        return_at_close: 1.0022484076897396,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 65045.240000000005,
        close_ms: 1711282220914,
        current_return: 1.0087293090163092,
        initial_entry_price: 65045.24,
        is_closed_position: true,
        miner_hotkey: "5DRNu7apWdLWq3tSY5MPSQRAf2n4vqKtQhHJmH7pfYNXXaD6",
        net_leverage: 0.0,
        open_ms: 1711276881168,
        orders: [
          {
            leverage: 1.25,
            order_type: "LONG",
            order_uuid: "6bca2811-4fa1-4db4-8f1e-21b435fa957c",
            price: 65045.24,
            price_sources: [],
            processed_ms: 1711276881167,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "3045b446-a24b-4de6-9b64-eff493b0dfb8",
            price: 65499.48,
            price_sources: [],
            processed_ms: 1711282220914,
          },
        ],
        position_type: "FLAT",
        position_uuid: "4e767474-ed15-428f-b367-6f7eb3fccdff",
        return_at_close: 1.0062074857437684,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 65399.61,
        close_ms: 1711303684151,
        current_return: 1.0053480823509497,
        initial_entry_price: 65399.61,
        is_closed_position: true,
        miner_hotkey: "5DRNu7apWdLWq3tSY5MPSQRAf2n4vqKtQhHJmH7pfYNXXaD6",
        net_leverage: 0.0,
        open_ms: 1711302369058,
        orders: [
          {
            leverage: 1.25,
            order_type: "LONG",
            order_uuid: "915eb262-88e8-4fb1-98b7-6542792d8c46",
            price: 65399.61,
            price_sources: [],
            processed_ms: 1711302369057,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "7774af63-28d9-4c54-9fda-3b47cae82b7f",
            price: 65679.42,
            price_sources: [],
            processed_ms: 1711303684151,
          },
        ],
        position_type: "FLAT",
        position_uuid: "ed1b2c35-819b-48fe-8eb0-d2fce15a9804",
        return_at_close: 1.0028347121450725,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 65725.97,
        close_ms: 1711306456127,
        current_return: 1.0006000291817678,
        initial_entry_price: 65725.97,
        is_closed_position: true,
        miner_hotkey: "5DRNu7apWdLWq3tSY5MPSQRAf2n4vqKtQhHJmH7pfYNXXaD6",
        net_leverage: 0.0,
        open_ms: 1711305523880,
        orders: [
          {
            leverage: 1.25,
            order_type: "LONG",
            order_uuid: "b21754b2-3638-49cc-b214-e3fe7bc7f719",
            price: 65725.97,
            price_sources: [],
            processed_ms: 1711305523879,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "456b5715-6ea6-4254-a892-c59fb8d58bf5",
            price: 65757.52,
            price_sources: [],
            processed_ms: 1711306456127,
          },
        ],
        position_type: "FLAT",
        position_uuid: "237d29a0-9f25-4df1-a1ab-c8736600acbc",
        return_at_close: 0.9980985291088135,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 65666.01,
        close_ms: 1711310190849,
        current_return: 1.0041006755245216,
        initial_entry_price: 65666.01,
        is_closed_position: true,
        miner_hotkey: "5DRNu7apWdLWq3tSY5MPSQRAf2n4vqKtQhHJmH7pfYNXXaD6",
        net_leverage: 0.0,
        open_ms: 1711307387102,
        orders: [
          {
            leverage: 1.25,
            order_type: "LONG",
            order_uuid: "48d0ddee-3d9e-4f5d-bed1-d70105a7459a",
            price: 65666.01,
            price_sources: [],
            processed_ms: 1711307387101,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "f359fc71-2e11-4786-beb2-8286f9e0fd74",
            price: 65881.43,
            price_sources: [],
            processed_ms: 1711310190849,
          },
        ],
        position_type: "FLAT",
        position_uuid: "b9302c16-a024-4fdd-8810-c6400395e64e",
        return_at_close: 1.0015904238357103,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 66868.275,
        close_ms: 1711326961225,
        current_return: 0.995647118970153,
        initial_entry_price: 66895.35,
        is_closed_position: true,
        miner_hotkey: "5DRNu7apWdLWq3tSY5MPSQRAf2n4vqKtQhHJmH7pfYNXXaD6",
        net_leverage: 0.0,
        open_ms: 1711325106652,
        orders: [
          {
            leverage: 1.25,
            order_type: "LONG",
            order_uuid: "22d5034f-3225-4630-9d09-af39c2757f4c",
            price: 66895.35,
            price_sources: [],
            processed_ms: 1711325106651,
          },
          {
            leverage: 1.25,
            order_type: "LONG",
            order_uuid: "a8720296-1845-47de-9c9c-9747ac3c9816",
            price: 66841.2,
            price_sources: [],
            processed_ms: 1711326032146,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "1c0f48c9-412e-4bb9-98e4-920436bfff75",
            price: 66751.8,
            price_sources: [],
            processed_ms: 1711326961225,
          },
        ],
        position_type: "FLAT",
        position_uuid: "1237c098-8530-4b8f-976a-40b9a8b3d137",
        return_at_close: 0.9906688833753022,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 67044.93,
        close_ms: 1711342383868,
        current_return: 1.0080494528072443,
        initial_entry_price: 67044.93,
        is_closed_position: true,
        miner_hotkey: "5DRNu7apWdLWq3tSY5MPSQRAf2n4vqKtQhHJmH7pfYNXXaD6",
        net_leverage: 0.0,
        open_ms: 1711340549799,
        orders: [
          {
            leverage: 1.25,
            order_type: "LONG",
            order_uuid: "8edbdf96-b3cc-4261-b064-322f62e3f765",
            price: 67044.93,
            price_sources: [],
            processed_ms: 1711340549798,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "8e404ad7-dcb3-4fb2-85ce-384d139ff32a",
            price: 67476.67,
            price_sources: [],
            processed_ms: 1711342383868,
          },
        ],
        position_type: "FLAT",
        position_uuid: "7647c236-ea21-4e4e-ab1c-cd3970d5e306",
        return_at_close: 1.0055293291752263,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 66829.47,
        close_ms: 1711355221713,
        current_return: 1.004076233134873,
        initial_entry_price: 66829.47,
        is_closed_position: true,
        miner_hotkey: "5DRNu7apWdLWq3tSY5MPSQRAf2n4vqKtQhHJmH7pfYNXXaD6",
        net_leverage: 0.0,
        open_ms: 1711353373111,
        orders: [
          {
            leverage: 1.25,
            order_type: "LONG",
            order_uuid: "cf0f1a00-8269-4243-bdea-1ebb2e7d7e78",
            price: 66829.47,
            price_sources: [],
            processed_ms: 1711353373110,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "b9892af0-b4b7-43be-832e-8cad3fbdc74d",
            price: 67047.4,
            price_sources: [],
            processed_ms: 1711355221713,
          },
        ],
        position_type: "FLAT",
        position_uuid: "09dc57de-b948-47fc-b233-b82255079dd1",
        return_at_close: 1.0015660425520359,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 67220.6,
        close_ms: 1711364360067,
        current_return: 0.9959683117972762,
        initial_entry_price: 67220.6,
        is_closed_position: true,
        miner_hotkey: "5DRNu7apWdLWq3tSY5MPSQRAf2n4vqKtQhHJmH7pfYNXXaD6",
        net_leverage: 0.0,
        open_ms: 1711362523042,
        orders: [
          {
            leverage: 1.25,
            order_type: "LONG",
            order_uuid: "94882ed5-1ee3-4d45-9ae6-a2ee0432b3f7",
            price: 67220.6,
            price_sources: [],
            processed_ms: 1711362523041,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "d9f85b31-7a7a-431d-8383-9ea14082f355",
            price: 67003.79,
            price_sources: [],
            processed_ms: 1711364360067,
          },
        ],
        position_type: "FLAT",
        position_uuid: "583468bd-cbd9-4c88-9734-468a119ecb86",
        return_at_close: 0.993478391017783,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: -1091167.1455172512,
        close_ms: 1711382578407,
        current_return: 1.0402203335007336,
        initial_entry_price: 66965.37,
        is_closed_position: true,
        miner_hotkey: "5DRNu7apWdLWq3tSY5MPSQRAf2n4vqKtQhHJmH7pfYNXXaD6",
        net_leverage: 0.0,
        open_ms: 1711368288119,
        orders: [
          {
            leverage: 1.25,
            order_type: "LONG",
            order_uuid: "256deeaf-6cbb-4422-b774-c7dc76db4da4",
            price: 66965.37,
            price_sources: [],
            processed_ms: 1711368288117,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "ada39843-2df6-4b32-af62-84c4b56d35fc",
            price: 67248.93,
            price_sources: [],
            processed_ms: 1711370112216,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "cfe003e9-3466-425e-95c7-28cb6c75b7d3",
            price: 67185.79,
            price_sources: [],
            processed_ms: 1711371952227,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "a7be1c16-4839-4797-997d-8779421e69df",
            price: 67108.33,
            price_sources: [],
            processed_ms: 1711373778042,
          },
          {
            leverage: -1.0,
            order_type: "SHORT",
            order_uuid: "81ef5868-6565-42af-b8ab-1cde76e729e2",
            price: 67827.85,
            price_sources: [],
            processed_ms: 1711375613196,
          },
          {
            leverage: -0.8,
            order_type: "SHORT",
            order_uuid: "78e582d3-32f3-4dc1-ba22-84334dae6d57",
            price: 68868.54,
            price_sources: [],
            processed_ms: 1711376427818,
          },
          {
            leverage: -0.15999999999999998,
            order_type: "SHORT",
            order_uuid: "6b133139-b499-4d88-8e77-2b379c815ed0",
            price: 69210.38,
            price_sources: [],
            processed_ms: 1711376756095,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "4f336e34-1626-445b-942c-99247b23251a",
            price: 69095.56,
            price_sources: [],
            processed_ms: 1711377871373,
          },
          {
            leverage: -0.23199999999999998,
            order_type: "SHORT",
            order_uuid: "a0aaa644-eae5-423b-a253-b33de8a8b3fd",
            price: 69074.0,
            price_sources: [],
            processed_ms: 1711378177573,
          },
          {
            leverage: -0.0464,
            order_type: "SHORT",
            order_uuid: "41e97552-0b95-4370-bed5-bdb3864d71a6",
            price: 69645.83,
            price_sources: [],
            processed_ms: 1711378943166,
          },
          {
            leverage: -0.00928,
            order_type: "SHORT",
            order_uuid: "f6518eb5-9431-402a-a324-e06950440061",
            price: 69482.27,
            price_sources: [],
            processed_ms: 1711380766263,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "73729b19-6479-4c18-b6b1-2fb4c35e2b88",
            price: 69767.99,
            price_sources: [],
            processed_ms: 1711382578407,
          },
        ],
        position_type: "FLAT",
        position_uuid: "ec099265-540b-45be-8190-283af799f548",
        return_at_close: 1.0360594521667308,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: -45148.31000000298,
        close_ms: 1711404375377,
        current_return: 0.9935949734724461,
        initial_entry_price: 70289.18,
        is_closed_position: true,
        miner_hotkey: "5DRNu7apWdLWq3tSY5MPSQRAf2n4vqKtQhHJmH7pfYNXXaD6",
        net_leverage: 0.0,
        open_ms: 1711386208425,
        orders: [
          {
            leverage: -1.25,
            order_type: "SHORT",
            order_uuid: "ca2cc1a8-1849-498d-a8ef-27694b046c8c",
            price: 70289.18,
            price_sources: [],
            processed_ms: 1711386208423,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "142b7d31-5ba1-4864-950d-ac10573df11c",
            price: 70750.51,
            price_sources: [],
            processed_ms: 1711388059859,
          },
          {
            leverage: 0.5,
            order_type: "LONG",
            order_uuid: "55b650bc-abcc-4f65-8c5d-fedb2396f282",
            price: 70648.8,
            price_sources: [],
            processed_ms: 1711389848497,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "7d18e505-75fa-4c8c-a3a6-5b8ec21893e4",
            price: 70414.53,
            price_sources: [],
            processed_ms: 1711391656351,
          },
          {
            leverage: 0.125,
            order_type: "LONG",
            order_uuid: "00b9699e-78a0-49c0-a9f0-0adbe9eae685",
            price: 70818.65,
            price_sources: [],
            processed_ms: 1711393476680,
          },
          {
            leverage: 0.0625,
            order_type: "LONG",
            order_uuid: "43de0dc7-5490-43ce-9911-0658241331c0",
            price: 70708.35,
            price_sources: [],
            processed_ms: 1711395291607,
          },
          {
            leverage: 0.03125,
            order_type: "LONG",
            order_uuid: "207daa37-8298-466d-be91-50245176c7b8",
            price: 70993.43,
            price_sources: [],
            processed_ms: 1711397108584,
          },
          {
            leverage: 0.015625,
            order_type: "LONG",
            order_uuid: "c0ca1b0d-63b9-4da9-8929-2737bb8a17d2",
            price: 70746.98,
            price_sources: [],
            processed_ms: 1711398996001,
          },
          {
            leverage: 0.0078125,
            order_type: "LONG",
            order_uuid: "b40cf749-8bb8-456a-b6ec-0152d4182eda",
            price: 70798.1,
            price_sources: [],
            processed_ms: 1711400745962,
          },
          {
            leverage: 0.00390625,
            order_type: "LONG",
            order_uuid: "7bef9eed-d126-4047-a9d9-ae4422dfd3f6",
            price: 70014.99,
            price_sources: [],
            processed_ms: 1711402555450,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "46d42b4c-4974-4406-8757-036fb820b58e",
            price: 70103.93,
            price_sources: [],
            processed_ms: 1711404375377,
          },
        ],
        position_type: "FLAT",
        position_uuid: "66816ef6-dca4-414d-9a72-6af8e6fa2191",
        return_at_close: 0.991110986038765,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 398916.53666665516,
        close_ms: 1711427979972,
        current_return: 0.9931701930536705,
        initial_entry_price: 70414.47,
        is_closed_position: true,
        miner_hotkey: "5DRNu7apWdLWq3tSY5MPSQRAf2n4vqKtQhHJmH7pfYNXXaD6",
        net_leverage: 0.0,
        open_ms: 1711406187468,
        orders: [
          {
            leverage: 1.25,
            order_type: "LONG",
            order_uuid: "5d9a3533-4352-4629-98f7-10887369aeaf",
            price: 70414.47,
            price_sources: [],
            processed_ms: 1711406187467,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "6470eb28-1c5c-460a-8f56-e6e1adeac9f6",
            price: 70322.56,
            price_sources: [],
            processed_ms: 1711408002336,
          },
          {
            leverage: -0.75,
            order_type: "SHORT",
            order_uuid: "38f3950b-a9c5-48ba-ac44-6c585a92eede",
            price: 70122.0,
            price_sources: [],
            processed_ms: 1711409844356,
          },
          {
            leverage: -0.375,
            order_type: "SHORT",
            order_uuid: "f14b3b92-048b-46e8-b013-e286b50329bd",
            price: 69889.53,
            price_sources: [],
            processed_ms: 1711411637741,
          },
          {
            leverage: -0.1875,
            order_type: "SHORT",
            order_uuid: "86afa97c-ac73-4762-ab31-c65e6cba8bee",
            price: 70221.75,
            price_sources: [],
            processed_ms: 1711413451423,
          },
          {
            leverage: -0.09375,
            order_type: "SHORT",
            order_uuid: "4855d226-9cf4-436f-ae56-b55ab7a3a3e3",
            price: 70040.77,
            price_sources: [],
            processed_ms: 1711415291082,
          },
          {
            leverage: -0.046875,
            order_type: "SHORT",
            order_uuid: "af35fa19-7e47-4b54-85aa-4a0ab0b8108d",
            price: 70238.5,
            price_sources: [],
            processed_ms: 1711417088109,
          },
          {
            leverage: -0.0234375,
            order_type: "SHORT",
            order_uuid: "70323d35-7a85-425d-a18a-26ba3dfbb1b4",
            price: 70118.14,
            price_sources: [],
            processed_ms: 1711418904291,
          },
          {
            leverage: -0.01171875,
            order_type: "SHORT",
            order_uuid: "fe201af8-32d6-4669-b781-deee78ceb937",
            price: 70234.1,
            price_sources: [],
            processed_ms: 1711420721323,
          },
          {
            leverage: -0.005859375,
            order_type: "SHORT",
            order_uuid: "cc4e5be8-f97d-4bea-a240-e67a7405f2a0",
            price: 70422.37,
            price_sources: [],
            processed_ms: 1711422536868,
          },
          {
            leverage: -0.0029296875,
            order_type: "SHORT",
            order_uuid: "488deff2-07a5-4ca0-9683-bf640b79b306",
            price: 70456.37,
            price_sources: [],
            processed_ms: 1711424352454,
          },
          {
            leverage: -0.00146484375,
            order_type: "SHORT",
            order_uuid: "8a3e2eb9-bca8-4d6b-bf52-95b9509c8cd9",
            price: 70640.55,
            price_sources: [],
            processed_ms: 1711426197536,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "068b2e87-3c1a-4831-9811-6b3abd6c1395",
            price: 70610.37,
            price_sources: [],
            processed_ms: 1711427979972,
          },
        ],
        position_type: "FLAT",
        position_uuid: "13cacec9-283e-4f19-936d-bb2cf3832edd",
        return_at_close: 0.9901906824745095,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: -436585.8914285792,
        close_ms: 1711455239540,
        current_return: 1.0123252614248301,
        initial_entry_price: 70339.86,
        is_closed_position: true,
        miner_hotkey: "5DRNu7apWdLWq3tSY5MPSQRAf2n4vqKtQhHJmH7pfYNXXaD6",
        net_leverage: 0.0,
        open_ms: 1711431619428,
        orders: [
          {
            leverage: 1.25,
            order_type: "LONG",
            order_uuid: "8306d2ec-4cdb-4cbe-a005-997703589fea",
            price: 70339.86,
            price_sources: [],
            processed_ms: 1711431619426,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "960c3c5a-212d-4026-81c2-84fe8f5b55ec",
            price: 70424.92,
            price_sources: [],
            processed_ms: 1711433435046,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "d2df889d-07b1-4ad5-8074-d4d41c99c040",
            price: 70463.92,
            price_sources: [],
            processed_ms: 1711435245345,
          },
          {
            leverage: -0.875,
            order_type: "SHORT",
            order_uuid: "b99e91cf-807b-4075-be31-29ff1fa43eda",
            price: 70778.74,
            price_sources: [],
            processed_ms: 1711437068470,
          },
          {
            leverage: -0.4375,
            order_type: "SHORT",
            order_uuid: "f6a05495-f677-4c87-9ea9-75929bf38dd0",
            price: 71164.26,
            price_sources: [],
            processed_ms: 1711438930513,
          },
          {
            leverage: -0.21875,
            order_type: "SHORT",
            order_uuid: "a734c917-2799-4074-af92-f3b9da79425d",
            price: 70744.16,
            price_sources: [],
            processed_ms: 1711440719501,
          },
          {
            leverage: -0.109375,
            order_type: "SHORT",
            order_uuid: "daa13eee-4f23-4ddb-909e-33ac3d021ca6",
            price: 70612.9,
            price_sources: [],
            processed_ms: 1711442535337,
          },
          {
            leverage: -0.0546875,
            order_type: "SHORT",
            order_uuid: "48a2c146-02be-43ab-bc34-d7bf6081e4f8",
            price: 70684.05,
            price_sources: [],
            processed_ms: 1711444340211,
          },
          {
            leverage: -0.02734375,
            order_type: "SHORT",
            order_uuid: "2af3f04a-7d7d-430e-92e3-6fbb0114e9c3",
            price: 71030.55,
            price_sources: [],
            processed_ms: 1711446160233,
          },
          {
            leverage: -0.013671875,
            order_type: "SHORT",
            order_uuid: "825acc1c-2d38-483d-a7b4-e402496cdb52",
            price: 71165.33,
            price_sources: [],
            processed_ms: 1711447975123,
          },
          {
            leverage: -0.0068359375,
            order_type: "SHORT",
            order_uuid: "ac795043-7008-4ca8-8a07-b9608d8849e5",
            price: 70997.14,
            price_sources: [],
            processed_ms: 1711449791343,
          },
          {
            leverage: -0.00341796875,
            order_type: "SHORT",
            order_uuid: "82e5f667-7fc5-4063-babc-2d043902275e",
            price: 70782.71,
            price_sources: [],
            processed_ms: 1711451608331,
          },
          {
            leverage: -0.001708984375,
            order_type: "SHORT",
            order_uuid: "b0206966-35c9-4e9c-b067-9c13e406ae8c",
            price: 70695.26,
            price_sources: [],
            processed_ms: 1711453420244,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "199f73ba-4582-43c2-8485-1421ec066ed6",
            price: 70707.9,
            price_sources: [],
            processed_ms: 1711455239540,
          },
        ],
        position_type: "FLAT",
        position_uuid: "85bd7559-057e-4dbd-b2ca-75fd7ea64a16",
        return_at_close: 1.0087821230098433,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 69963.18361489555,
        close_ms: 1711546341973,
        current_return: 1.0634277389577527,
        initial_entry_price: 70854.15,
        is_closed_position: true,
        miner_hotkey: "5DRNu7apWdLWq3tSY5MPSQRAf2n4vqKtQhHJmH7pfYNXXaD6",
        net_leverage: 0.0,
        open_ms: 1711458876218,
        orders: [
          {
            leverage: 1.25,
            order_type: "LONG",
            order_uuid: "9430c690-3419-447f-8ecf-9adf21b31a88",
            price: 70854.15,
            price_sources: [],
            processed_ms: 1711458876216,
          },
          {
            leverage: -0.625,
            order_type: "SHORT",
            order_uuid: "b2e7a5dc-b003-457d-9874-da7653127c13",
            price: 70583.17,
            price_sources: [],
            processed_ms: 1711460689833,
          },
          {
            leverage: -0.3125,
            order_type: "SHORT",
            order_uuid: "462081a1-0e46-4a40-a0b0-5d73d3caa241",
            price: 70399.74,
            price_sources: [],
            processed_ms: 1711462508635,
          },
          {
            leverage: -0.25,
            order_type: "SHORT",
            order_uuid: "cb0b9da2-e63f-4c8f-8527-5f93295e62f8",
            price: 69867.46,
            price_sources: [],
            processed_ms: 1711464321332,
          },
          {
            leverage: -0.03125,
            order_type: "SHORT",
            order_uuid: "fb2caae4-4603-4c27-81c3-959a30abf945",
            price: 70066.1,
            price_sources: [],
            processed_ms: 1711466138026,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "fbc92391-0d3a-4d15-807d-f1e0d45f718c",
            price: 70291.12,
            price_sources: [],
            processed_ms: 1711467953719,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "02d4e652-8aca-4991-a812-d2f2e5e0b97a",
            price: 70030.97,
            price_sources: [],
            processed_ms: 1711469774557,
          },
          {
            leverage: -0.265625,
            order_type: "SHORT",
            order_uuid: "1744750a-f25d-413e-ad8b-c1d3f2e900c8",
            price: 69592.0,
            price_sources: [],
            processed_ms: 1711471588510,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "16083097-9a0c-4bef-8109-e05629c3df22",
            price: 69728.02,
            price_sources: [],
            processed_ms: 1711473407510,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "7bdde88a-bf9e-4798-a626-c87f0260da1f",
            price: 70113.27,
            price_sources: [],
            processed_ms: 1711475227601,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "d62e966a-0a44-40ae-9e15-5c4dd2e68975",
            price: 70264.0,
            price_sources: [],
            processed_ms: 1711477038230,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "41734ae4-31a7-4b56-83dd-53e69cfb195c",
            price: 70390.32,
            price_sources: [],
            processed_ms: 1711478852532,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "aa40b2ed-f4e2-49cf-a6bf-630ee0411180",
            price: 69727.33,
            price_sources: [],
            processed_ms: 1711480667375,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "90a2d492-9743-4c97-9a09-5c24d4636c06",
            price: 69748.83,
            price_sources: [],
            processed_ms: 1711482489457,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "a77d4516-6a26-4b56-be29-f2a030812445",
            price: 69650.37,
            price_sources: [],
            processed_ms: 1711484297409,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "e4e74c44-becf-4e68-95b5-aaf69b3a1ba2",
            price: 69810.7,
            price_sources: [],
            processed_ms: 1711486149119,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "34cf6fa4-88d3-4379-8aa0-230263e17ffb",
            price: 69872.06,
            price_sources: [],
            processed_ms: 1711487937417,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "5c5e4a38-7c96-4b0a-8b29-b9c12809f31b",
            price: 70076.02,
            price_sources: [],
            processed_ms: 1711489750540,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "c2578ec9-3d28-4670-9ed6-746422a897c8",
            price: 69852.83,
            price_sources: [],
            processed_ms: 1711491564341,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "6fd26500-95c1-4ce8-a20b-0aa7e2463831",
            price: 70139.74,
            price_sources: [],
            processed_ms: 1711493381377,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "475644f0-cfbd-45ac-95c0-7101634fa20c",
            price: 70243.07,
            price_sources: [],
            processed_ms: 1711495204267,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "dc5b11c2-3c23-478a-b634-dd2ad2d1ad22",
            price: 69991.24,
            price_sources: [],
            processed_ms: 1711497015422,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "03bd39a0-2d83-4769-91f8-79960a7d0dbd",
            price: 69979.88,
            price_sources: [],
            processed_ms: 1711498833154,
          },
          {
            leverage: -2.0078125,
            order_type: "SHORT",
            order_uuid: "b5dc0f20-a280-4de8-827d-b9d37b55337b",
            price: 70394.49,
            price_sources: [],
            processed_ms: 1711500651406,
          },
          {
            leverage: -1.00390625,
            order_type: "SHORT",
            order_uuid: "d0043c0c-171b-452a-8848-59cfb7e28e84",
            price: 70562.98,
            price_sources: [],
            processed_ms: 1711502482107,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "68ea73a0-8054-4a68-89ac-301b26ce9f42",
            price: 70366.71,
            price_sources: [],
            processed_ms: 1711504296561,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "4fd92af1-9eab-4f66-976b-7a56b8be3c04",
            price: 70218.95,
            price_sources: [],
            processed_ms: 1711506100540,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "2d481d3b-4628-4397-9b71-b30d58e9e5f6",
            price: 70551.93,
            price_sources: [],
            processed_ms: 1711507910382,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "e0f34b33-c2a4-4631-971b-72bd80744a3b",
            price: 70493.48,
            price_sources: [],
            processed_ms: 1711509728511,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "d808a0ee-b902-4dfb-865a-887e4d36255c",
            price: 70385.58,
            price_sources: [],
            processed_ms: 1711511548200,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "8024ccf5-5050-4549-92ec-2de7cf99806b",
            price: 70287.33,
            price_sources: [],
            processed_ms: 1711513363304,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "047d5695-0cf9-4e06-9eda-72775759e24c",
            price: 70100.01,
            price_sources: [],
            processed_ms: 1711515176285,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "5611341f-c63f-4831-99d4-8cf6919fc66b",
            price: 70417.68,
            price_sources: [],
            processed_ms: 1711517000159,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "9280b5b4-e631-4393-89f9-d18b0f708c0c",
            price: 70410.0,
            price_sources: [],
            processed_ms: 1711518817485,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "79f8528e-d6f0-44b8-a4e6-4a9d4ff62c40",
            price: 70313.78,
            price_sources: [],
            processed_ms: 1711520631145,
          },
          {
            leverage: -1.751953125,
            order_type: "SHORT",
            order_uuid: "f0919a7e-128b-45fd-b9cb-1c12aa57a296",
            price: 70228.46,
            price_sources: [],
            processed_ms: 1711522449319,
          },
          {
            leverage: -0.8759765625,
            order_type: "SHORT",
            order_uuid: "c5bc36e8-2121-4dcd-a3eb-5496d8bec3be",
            price: 69835.98,
            price_sources: [],
            processed_ms: 1711526672411,
          },
          {
            leverage: -0.43798828125,
            order_type: "SHORT",
            order_uuid: "bb473e54-4858-4449-b224-67d1301faac8",
            price: 69850.0,
            price_sources: [],
            processed_ms: 1711528488225,
          },
          {
            leverage: 0.375,
            order_type: "LONG",
            order_uuid: "a423baf2-7622-4433-ac79-a0e33d07d060",
            price: 69561.53,
            price_sources: [],
            processed_ms: 1711530298013,
          },
          {
            leverage: 0.375,
            order_type: "LONG",
            order_uuid: "e254eaa3-0f29-4f0b-bb42-27fff988a50b",
            price: 69747.82,
            price_sources: [],
            processed_ms: 1711532115094,
          },
          {
            leverage: 0.375,
            order_type: "LONG",
            order_uuid: "3232ebb9-4caf-4a54-876c-11e379d10a0d",
            price: 70020.75,
            price_sources: [],
            processed_ms: 1711533960863,
          },
          {
            leverage: 0.375,
            order_type: "LONG",
            order_uuid: "c52f7ebc-809d-4f11-b882-79faa14fa3a1",
            price: 70039.33,
            price_sources: [],
            processed_ms: 1711536432073,
          },
          {
            leverage: 0.375,
            order_type: "LONG",
            order_uuid: "94f7fada-f869-461e-8f09-bdc29f2ae233",
            price: 70278.78,
            price_sources: [],
            processed_ms: 1711540009203,
          },
          {
            leverage: 0.375,
            order_type: "LONG",
            order_uuid: "3b21ff36-0136-40ea-ac71-1fc84d6c4e5f",
            price: 69998.77,
            price_sources: [],
            processed_ms: 1711543633149,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "84a5d830-d9f3-4f3e-9623-aeedeae50207",
            price: 71635.11,
            price_sources: [],
            processed_ms: 1711546341973,
          },
        ],
        position_type: "FLAT",
        position_uuid: "dcb30614-8d91-421e-a5fe-42e88160d626",
        return_at_close: 1.0548870849292482,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 65659.99270758129,
        close_ms: 1711618557001,
        current_return: 1.0193523681102947,
        initial_entry_price: 69971.7,
        is_closed_position: true,
        miner_hotkey: "5DRNu7apWdLWq3tSY5MPSQRAf2n4vqKtQhHJmH7pfYNXXaD6",
        net_leverage: 0.0,
        open_ms: 1711547283905,
        orders: [
          {
            leverage: 1.25,
            order_type: "LONG",
            order_uuid: "00c5d6ff-74ec-4c11-b115-abe4101d9f78",
            price: 69971.7,
            price_sources: [],
            processed_ms: 1711547283903,
          },
          {
            leverage: -1.0,
            order_type: "SHORT",
            order_uuid: "b14a63f4-ecbc-4403-9bfc-7609022cb801",
            price: 69760.0,
            price_sources: [],
            processed_ms: 1711550880970,
          },
          {
            leverage: -0.125,
            order_type: "SHORT",
            order_uuid: "0f245071-9357-4b78-be79-5ad2b85519ed",
            price: 69059.99,
            price_sources: [],
            processed_ms: 1711554545850,
          },
          {
            leverage: -0.0625,
            order_type: "SHORT",
            order_uuid: "6a2f2f67-c83a-4d1c-94f0-dd8387c452ff",
            price: 69064.3,
            price_sources: [],
            processed_ms: 1711558107250,
          },
          {
            leverage: -0.03125,
            order_type: "SHORT",
            order_uuid: "748f26c1-0799-401e-b0d9-0c1ec7af46e2",
            price: 69090.01,
            price_sources: [],
            processed_ms: 1711561808824,
          },
          {
            leverage: 0.375,
            order_type: "LONG",
            order_uuid: "e5059fdb-016c-4f40-aadc-e91e2eabaf05",
            price: 68743.09,
            price_sources: [],
            processed_ms: 1711565444903,
          },
          {
            leverage: 0.375,
            order_type: "LONG",
            order_uuid: "22988aae-f282-4c23-bba0-492d4f66d866",
            price: 68642.68,
            price_sources: [],
            processed_ms: 1711568973592,
          },
          {
            leverage: 0.375,
            order_type: "LONG",
            order_uuid: "af718699-5710-4be4-a579-cede2089a417",
            price: 68889.44,
            price_sources: [],
            processed_ms: 1711572589759,
          },
          {
            leverage: -0.578125,
            order_type: "SHORT",
            order_uuid: "b03461cf-ce5c-41be-90d8-cbb31586442f",
            price: 69099.39,
            price_sources: [],
            processed_ms: 1711576198445,
          },
          {
            leverage: -0.2890625,
            order_type: "SHORT",
            order_uuid: "e8e3186e-2ba7-4553-a07b-fded5f49a425",
            price: 69374.86,
            price_sources: [],
            processed_ms: 1711579869541,
          },
          {
            leverage: 0.375,
            order_type: "LONG",
            order_uuid: "33a2b67b-6dc6-4fff-a24d-8b091d004a2b",
            price: 69243.81,
            price_sources: [],
            processed_ms: 1711583435362,
          },
          {
            leverage: 0.375,
            order_type: "LONG",
            order_uuid: "6c7cae13-dbb6-4ad5-9e5c-da754cc15e35",
            price: 69686.4,
            price_sources: [],
            processed_ms: 1711587049243,
          },
          {
            leverage: 0.375,
            order_type: "LONG",
            order_uuid: "0c6e1dcb-3346-4d84-8965-1a69270d242c",
            price: 69426.94,
            price_sources: [],
            processed_ms: 1711590678451,
          },
          {
            leverage: 0.375,
            order_type: "LONG",
            order_uuid: "8b4b9e1b-fafe-4610-ba94-b846a9f5d8d2",
            price: 69015.04,
            price_sources: [],
            processed_ms: 1711594290555,
          },
          {
            leverage: 0.375,
            order_type: "LONG",
            order_uuid: "56922413-a265-42b5-a583-72537b916407",
            price: 69160.56,
            price_sources: [],
            processed_ms: 1711597937120,
          },
          {
            leverage: -1.08203125,
            order_type: "SHORT",
            order_uuid: "8be6dd05-4d39-4491-91e1-b3d6ea186a6b",
            price: 69652.79,
            price_sources: [],
            processed_ms: 1711601551862,
          },
          {
            leverage: -0.541015625,
            order_type: "SHORT",
            order_uuid: "f1a19673-5d68-4cde-87ea-ced0501d17b9",
            price: 69611.08,
            price_sources: [],
            processed_ms: 1711603384417,
          },
          {
            leverage: -0.2705078125,
            order_type: "SHORT",
            order_uuid: "bde075c1-83bf-4442-9f6a-eef90d4a0857",
            price: 70641.42,
            price_sources: [],
            processed_ms: 1711618454631,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "cc30391d-2531-4716-88ea-52c00baa37d4",
            price: 70665.83,
            price_sources: [],
            processed_ms: 1711618557001,
          },
        ],
        position_type: "FLAT",
        position_uuid: "4849ee49-dd66-4233-82b7-25516e920f81",
        return_at_close: 1.0149404836420672,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 71262.57,
        close_ms: 1711633134977,
        current_return: 1.0206878589980688,
        initial_entry_price: 71262.57,
        is_closed_position: true,
        miner_hotkey: "5DRNu7apWdLWq3tSY5MPSQRAf2n4vqKtQhHJmH7pfYNXXaD6",
        net_leverage: 0.0,
        open_ms: 1711632992087,
        orders: [
          {
            leverage: -3.5,
            order_type: "SHORT",
            order_uuid: "eacc2d93-8b75-47ea-af85-4e4ec277550f",
            price: 71262.57,
            price_sources: [],
            processed_ms: 1711632992084,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "4e899328-1b14-4aa9-9211-8da8815da3c7",
            price: 70841.35,
            price_sources: [],
            processed_ms: 1711633134977,
          },
        ],
        position_type: "FLAT",
        position_uuid: "d0cac5e1-2e74-4de0-9dca-710179613814",
        return_at_close: 1.0135430439850823,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 71412.96,
        close_ms: 1711636794447,
        current_return: 0.9978709746802267,
        initial_entry_price: 71412.96,
        is_closed_position: true,
        miner_hotkey: "5DRNu7apWdLWq3tSY5MPSQRAf2n4vqKtQhHJmH7pfYNXXaD6",
        net_leverage: 0.0,
        open_ms: 1711636639339,
        orders: [
          {
            leverage: -3.5,
            order_type: "SHORT",
            order_uuid: "23ac4d8f-1df3-4628-ac95-4d240d63c29c",
            price: 71412.96,
            price_sources: [],
            processed_ms: 1711636639335,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "3f85cef0-b805-4349-82d7-fc5b30abb9e3",
            price: 71456.4,
            price_sources: [],
            processed_ms: 1711636794447,
          },
        ],
        position_type: "FLAT",
        position_uuid: "40bd5267-d6e3-46dc-93a0-d53e7fbed1d8",
        return_at_close: 0.9908858778574651,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 71354.47,
        close_ms: 1711638582483,
        current_return: 1.0039471248262373,
        initial_entry_price: 71354.47,
        is_closed_position: true,
        miner_hotkey: "5DRNu7apWdLWq3tSY5MPSQRAf2n4vqKtQhHJmH7pfYNXXaD6",
        net_leverage: 0.0,
        open_ms: 1711638455564,
        orders: [
          {
            leverage: -3.5,
            order_type: "SHORT",
            order_uuid: "d3a1a542-5097-4787-8679-e056e4fb422e",
            price: 71354.47,
            price_sources: [],
            processed_ms: 1711638455561,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "bc06f906-9e60-493f-8bb8-426914bf939a",
            price: 71274.0,
            price_sources: [],
            processed_ms: 1711638582483,
          },
        ],
        position_type: "FLAT",
        position_uuid: "8c9f5ec9-b575-4a63-8c04-647f46742e5f",
        return_at_close: 0.9969194949524536,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 71531.66000000025,
        close_ms: 1711678080376,
        current_return: 1.002581460949166,
        initial_entry_price: 70784.53,
        is_closed_position: true,
        miner_hotkey: "5DRNu7apWdLWq3tSY5MPSQRAf2n4vqKtQhHJmH7pfYNXXaD6",
        net_leverage: 0.0,
        open_ms: 1711659983213,
        orders: [
          {
            leverage: -5.0,
            order_type: "SHORT",
            order_uuid: "c6452aa2-2ac6-47cf-bf31-acd1880c5410",
            price: 70784.53,
            price_sources: [],
            processed_ms: 1711659983209,
          },
          {
            leverage: 2.5,
            order_type: "LONG",
            order_uuid: "d2ae9740-9a2b-47ce-a5f7-4d7fb77fcb5e",
            price: 70687.01,
            price_sources: [],
            processed_ms: 1711663611701,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "5fcc1797-3330-4989-9708-986a811c3a34",
            price: 70821.4,
            price_sources: [],
            processed_ms: 1711667228238,
          },
          {
            leverage: 0.5,
            order_type: "LONG",
            order_uuid: "327a1673-f42b-44cf-a514-1f6399be96b2",
            price: 70826.35,
            price_sources: [],
            processed_ms: 1711670844972,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "e94de210-f861-4f52-a5bc-50890ef09afc",
            price: 70707.74,
            price_sources: [],
            processed_ms: 1711674464243,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "2776ab2f-c6bc-4352-a3eb-8197d4b99a65",
            price: 70800.75,
            price_sources: [],
            processed_ms: 1711678080376,
          },
        ],
        position_type: "FLAT",
        position_uuid: "f6496b25-5731-43d5-8b8b-e2d0516aa6cf",
        return_at_close: 0.9925556463396742,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 70197.92210526315,
        close_ms: 1711700262679,
        current_return: 0.9731490188075212,
        initial_entry_price: 70412.79,
        is_closed_position: true,
        miner_hotkey: "5DRNu7apWdLWq3tSY5MPSQRAf2n4vqKtQhHJmH7pfYNXXaD6",
        net_leverage: 0.0,
        open_ms: 1711688928479,
        orders: [
          {
            leverage: 5.0,
            order_type: "LONG",
            order_uuid: "413f1dd2-26e9-4f44-8b90-14df1beaafc4",
            price: 70412.79,
            price_sources: [],
            processed_ms: 1711688928476,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "7521b610-0cb4-4e76-b170-b41603b9fc8d",
            price: 70111.87,
            price_sources: [],
            processed_ms: 1711692579711,
          },
          {
            leverage: -3.25,
            order_type: "SHORT",
            order_uuid: "a4711d9f-48f4-49be-9709-f925b664ca99",
            price: 70405.02,
            price_sources: [],
            processed_ms: 1711696159122,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "53e153ac-5a0a-45ff-b9b0-ca8fd2380a23",
            price: 70016.46,
            price_sources: [],
            processed_ms: 1711699775324,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "2a309ff2-ac3a-403d-9985-6c813d747311",
            price: 69799.89,
            price_sources: [],
            processed_ms: 1711700262679,
          },
        ],
        position_type: "FLAT",
        position_uuid: "e7eba03d-8891-48be-8814-4fa444cc9b52",
        return_at_close: 0.9604980815630234,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 69671.61000000002,
        close_ms: 1711731608576,
        current_return: 0.9692277862364967,
        initial_entry_price: 69960.68,
        is_closed_position: true,
        miner_hotkey: "5DRNu7apWdLWq3tSY5MPSQRAf2n4vqKtQhHJmH7pfYNXXaD6",
        net_leverage: 0.0,
        open_ms: 1711703399368,
        orders: [
          {
            leverage: 5.0,
            order_type: "LONG",
            order_uuid: "6ed06954-589a-429e-9fad-ed7d33961eb6",
            price: 69960.68,
            price_sources: [],
            processed_ms: 1711703399365,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "81f7e8d3-9ff8-49aa-bfe9-b8a2d0574cc0",
            price: 69699.84,
            price_sources: [],
            processed_ms: 1711707025679,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "26c2aa68-e824-46ab-946e-62702458b938",
            price: 70175.92,
            price_sources: [],
            processed_ms: 1711710648412,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "07070888-e7c5-44bb-a45b-8119511cd70e",
            price: 70017.83,
            price_sources: [],
            processed_ms: 1711714251050,
          },
          {
            leverage: -4.75,
            order_type: "SHORT",
            order_uuid: "78635e51-f040-4233-8981-a3cdddadc5a1",
            price: 70117.91,
            price_sources: [],
            processed_ms: 1711717874651,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "9a34ac35-8531-4e6c-8332-b83c820c9e07",
            price: 70441.51,
            price_sources: [],
            processed_ms: 1711721488462,
          },
          {
            leverage: -3.125,
            order_type: "SHORT",
            order_uuid: "5da73fc5-bf8e-4003-986f-2b95cdb80090",
            price: 70104.25,
            price_sources: [],
            processed_ms: 1711725109898,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "49a4e631-5c68-4cfc-b8c8-8c7926e1d6da",
            price: 69374.0,
            price_sources: [],
            processed_ms: 1711728732252,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "49b5e26a-9d3d-499f-835d-68e099ad5734",
            price: 69206.13,
            price_sources: [],
            processed_ms: 1711731608576,
          },
        ],
        position_type: "FLAT",
        position_uuid: "a3acc9df-fee6-45ec-94c8-be589998f92a",
        return_at_close: 0.9508124582980033,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 58458.455384614994,
        close_ms: 1711783031118,
        current_return: 1.03403705436557,
        initial_entry_price: 69324.76,
        is_closed_position: true,
        miner_hotkey: "5DRNu7apWdLWq3tSY5MPSQRAf2n4vqKtQhHJmH7pfYNXXaD6",
        net_leverage: 0.0,
        open_ms: 1711732354912,
        orders: [
          {
            leverage: 5.0,
            order_type: "LONG",
            order_uuid: "602f86ae-4555-4c79-88af-7c6069870762",
            price: 69324.76,
            price_sources: [],
            processed_ms: 1711732354909,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "2e121756-af19-426c-adfb-d96d08ef7c89",
            price: 69480.15,
            price_sources: [],
            processed_ms: 1711735966364,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "6611b157-9199-4abb-9e87-c610b3b3b98a",
            price: 69481.68,
            price_sources: [],
            processed_ms: 1711739589370,
          },
          {
            leverage: -4.0,
            order_type: "SHORT",
            order_uuid: "6c094eee-6268-40f7-bb25-b62afc15884d",
            price: 69673.93,
            price_sources: [],
            processed_ms: 1711743211138,
          },
          {
            leverage: -2.0,
            order_type: "SHORT",
            order_uuid: "b7a331f9-3ed1-4288-a07e-c39c38f4b23a",
            price: 69577.18,
            price_sources: [],
            processed_ms: 1711746826046,
          },
          {
            leverage: -1.0,
            order_type: "SHORT",
            order_uuid: "bf2172b3-b786-4a84-9943-553a29b19897",
            price: 69570.62,
            price_sources: [],
            processed_ms: 1711750466411,
          },
          {
            leverage: -0.5,
            order_type: "SHORT",
            order_uuid: "a72591d5-c3a3-4ee0-bfb3-47e56ebc17ad",
            price: 69844.8,
            price_sources: [],
            processed_ms: 1711754120973,
          },
          {
            leverage: -0.25,
            order_type: "SHORT",
            order_uuid: "8ad6a713-ff81-43b7-a29c-85fbd1911c22",
            price: 69904.26,
            price_sources: [],
            processed_ms: 1711757710587,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "6b8f81dc-8393-4ba6-ad72-cd1cd44b23bb",
            price: 70006.64,
            price_sources: [],
            processed_ms: 1711761337747,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "0202fde9-9f12-47fb-bca5-79de2dddb740",
            price: 69990.18,
            price_sources: [],
            processed_ms: 1711764933030,
          },
          {
            leverage: -1.625,
            order_type: "SHORT",
            order_uuid: "461b3427-8e69-4b4e-936c-8a3bde0ce519",
            price: 70053.41,
            price_sources: [],
            processed_ms: 1711768556328,
          },
          {
            leverage: -0.8125,
            order_type: "SHORT",
            order_uuid: "af0b32d2-7a17-4db3-8ed3-5181241d9001",
            price: 69888.15,
            price_sources: [],
            processed_ms: 1711772179944,
          },
          {
            leverage: -0.40625,
            order_type: "SHORT",
            order_uuid: "445b114d-4cf0-4b72-9be4-42b9c8c8e0df",
            price: 70203.28,
            price_sources: [],
            processed_ms: 1711775795776,
          },
          {
            leverage: -0.203125,
            order_type: "SHORT",
            order_uuid: "a0d0addc-86ed-4af4-b0a9-5807d50b0a6e",
            price: 70041.8,
            price_sources: [],
            processed_ms: 1711779410192,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "e378a0ec-f5ef-44a4-a77f-6103cde75e79",
            price: 70075.0,
            price_sources: [],
            processed_ms: 1711783031118,
          },
        ],
        position_type: "FLAT",
        position_uuid: "b1c0b6c0-e1c2-4f4b-9d58-c2a8c9e097b4",
        return_at_close: 1.0174924614957208,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 70394.01999999979,
        close_ms: 1711808375975,
        current_return: 1.0006604673536745,
        initial_entry_price: 70098.09,
        is_closed_position: true,
        miner_hotkey: "5DRNu7apWdLWq3tSY5MPSQRAf2n4vqKtQhHJmH7pfYNXXaD6",
        net_leverage: 0.0,
        open_ms: 1711790325086,
        orders: [
          {
            leverage: -5.0,
            order_type: "SHORT",
            order_uuid: "49077e7d-24ea-463b-989f-cd29d9d880a3",
            price: 70098.09,
            price_sources: [],
            processed_ms: 1711790325082,
          },
          {
            leverage: 2.5,
            order_type: "LONG",
            order_uuid: "365d54f1-7864-43db-9a13-e5a97a43a7fc",
            price: 70002.8,
            price_sources: [],
            processed_ms: 1711793883681,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "a5521fef-b1ac-4f94-b610-eb84cfef436b",
            price: 70131.85,
            price_sources: [],
            processed_ms: 1711797499747,
          },
          {
            leverage: 0.5,
            order_type: "LONG",
            order_uuid: "b667155c-d43b-41ca-a607-82b06c4d6fd8",
            price: 70232.79,
            price_sources: [],
            processed_ms: 1711801122710,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "a173707e-1c34-423a-8eab-6c7a553c0546",
            price: 70283.1,
            price_sources: [],
            processed_ms: 1711804745442,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "6f2ea9cf-10cf-42fb-99b0-0ca81d4b0821",
            price: 70208.83,
            price_sources: [],
            processed_ms: 1711808375975,
          },
        ],
        position_type: "FLAT",
        position_uuid: "ec3b31e4-f76f-4f6a-8661-9210da2d7b74",
        return_at_close: 0.9906538626801378,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 71024.12999999992,
        close_ms: 1711840948167,
        current_return: 0.9938866694235419,
        initial_entry_price: 69934.26,
        is_closed_position: true,
        miner_hotkey: "5DRNu7apWdLWq3tSY5MPSQRAf2n4vqKtQhHJmH7pfYNXXaD6",
        net_leverage: 0.0,
        open_ms: 1711822842374,
        orders: [
          {
            leverage: 5.0,
            order_type: "LONG",
            order_uuid: "bdc04bc9-7173-4702-b7d1-7e7c0f3c1f28",
            price: 69934.26,
            price_sources: [],
            processed_ms: 1711822842371,
          },
          {
            leverage: -2.5,
            order_type: "SHORT",
            order_uuid: "51befdb5-2e00-47f8-9a5e-7304d5fa5578",
            price: 69847.91,
            price_sources: [],
            processed_ms: 1711826462024,
          },
          {
            leverage: -1.25,
            order_type: "SHORT",
            order_uuid: "7fe6dab6-1de1-4cb8-9efd-e3cffcfd479f",
            price: 69924.09,
            price_sources: [],
            processed_ms: 1711830081033,
          },
          {
            leverage: -0.625,
            order_type: "SHORT",
            order_uuid: "5dce2473-7df3-434e-a3d1-995c24de9365",
            price: 69809.45,
            price_sources: [],
            processed_ms: 1711833701858,
          },
          {
            leverage: -0.3125,
            order_type: "SHORT",
            order_uuid: "9e6b7425-c156-4471-83bb-16d1f10cf35f",
            price: 69825.49,
            price_sources: [],
            processed_ms: 1711837329603,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "63238144-eb4e-4116-a274-845035097833",
            price: 69656.03,
            price_sources: [],
            processed_ms: 1711840948167,
          },
        ],
        position_type: "FLAT",
        position_uuid: "2a5ee40a-9c3c-4841-83a0-dc52752216fa",
        return_at_close: 0.9839478027293065,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 70117.19999999995,
        close_ms: 1711877111600,
        current_return: 1.001779749601362,
        initial_entry_price: 70276.74,
        is_closed_position: true,
        miner_hotkey: "5DRNu7apWdLWq3tSY5MPSQRAf2n4vqKtQhHJmH7pfYNXXaD6",
        net_leverage: 0.0,
        open_ms: 1711862646966,
        orders: [
          {
            leverage: 5.0,
            order_type: "LONG",
            order_uuid: "9ccd73bf-697e-4788-a79d-d8421a9679e3",
            price: 70276.74,
            price_sources: [],
            processed_ms: 1711862646963,
          },
          {
            leverage: -2.5,
            order_type: "SHORT",
            order_uuid: "d771d44b-e167-493f-aad8-5e788b7e0727",
            price: 70283.52,
            price_sources: [],
            processed_ms: 1711866263102,
          },
          {
            leverage: -1.25,
            order_type: "SHORT",
            order_uuid: "2c875428-8721-4ad3-be36-ea36037c81b2",
            price: 70264.26,
            price_sources: [],
            processed_ms: 1711869881480,
          },
          {
            leverage: -0.625,
            order_type: "SHORT",
            order_uuid: "346b7a2c-33ca-4151-acde-5c95586f45f0",
            price: 70434.12,
            price_sources: [],
            processed_ms: 1711873504001,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "c770e28d-9680-4089-937f-7103b53db398",
            price: 70317.32,
            price_sources: [],
            processed_ms: 1711877111600,
          },
        ],
        position_type: "FLAT",
        position_uuid: "fd8010fc-2004-4ce6-99fd-63c69329471f",
        return_at_close: 0.9917619521053485,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 69424.98500000002,
        close_ms: 1711895285704,
        current_return: 0.9826423016494287,
        initial_entry_price: 70273.43,
        is_closed_position: true,
        miner_hotkey: "5DRNu7apWdLWq3tSY5MPSQRAf2n4vqKtQhHJmH7pfYNXXaD6",
        net_leverage: 0.0,
        open_ms: 1711880736246,
        orders: [
          {
            leverage: -5.0,
            order_type: "SHORT",
            order_uuid: "00d1d737-c201-4094-a2f6-565912ddbd2e",
            price: 70273.43,
            price_sources: [],
            processed_ms: 1711880736243,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "fd158da0-8faa-4ef6-ae27-45bd270813bf",
            price: 70328.16,
            price_sources: [],
            processed_ms: 1711884343935,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "56d0bcff-fe1e-4158-baff-75f5b3d8eb2b",
            price: 70493.43,
            price_sources: [],
            processed_ms: 1711888039388,
          },
          {
            leverage: 1.0,
            order_type: "LONG",
            order_uuid: "10af10f8-ae80-42e3-88d0-47399848003a",
            price: 70709.78,
            price_sources: [],
            processed_ms: 1711891593782,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "ea62486f-380d-4e71-9281-00d21295f927",
            price: 70644.77,
            price_sources: [],
            processed_ms: 1711895285704,
          },
        ],
        position_type: "FLAT",
        position_uuid: "61d82af1-47cf-42e5-8fda-af290bfde270",
        return_at_close: 0.9728158786329344,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 67645.20000000008,
        close_ms: 1711916937813,
        current_return: 0.9885458100119776,
        initial_entry_price: 70391.49,
        is_closed_position: true,
        miner_hotkey: "5DRNu7apWdLWq3tSY5MPSQRAf2n4vqKtQhHJmH7pfYNXXaD6",
        net_leverage: 0.0,
        open_ms: 1711898828523,
        orders: [
          {
            leverage: -5.0,
            order_type: "SHORT",
            order_uuid: "6e16fc6a-dfe9-4e84-83bd-6fec55a6afdd",
            price: 70391.49,
            price_sources: [],
            processed_ms: 1711898828519,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "058e60b6-ca10-4769-a203-d942d4525be2",
            price: 70563.08,
            price_sources: [],
            processed_ms: 1711902458769,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "b23b7d95-e9d1-4a9f-847a-ace360ef1cba",
            price: 70486.61,
            price_sources: [],
            processed_ms: 1711906070702,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "865cdde4-e4cf-4f56-866c-84acb5173997",
            price: 70484.99,
            price_sources: [],
            processed_ms: 1711909690502,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "81e9a0a8-dd47-48bd-b2a5-661a4eb98302",
            price: 70976.52,
            price_sources: [],
            processed_ms: 1711913315849,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "0b306d35-c771-4f1a-ba5a-52bf741eab91",
            price: 70870.31,
            price_sources: [],
            processed_ms: 1711916937813,
          },
        ],
        position_type: "FLAT",
        position_uuid: "d9a5beeb-5e4a-4433-a45e-ba3ab4a43296",
        return_at_close: 0.9786603519118579,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 68376.91000000003,
        close_ms: 1711935035441,
        current_return: 0.9815946903084938,
        initial_entry_price: 70785.28,
        is_closed_position: true,
        miner_hotkey: "5DRNu7apWdLWq3tSY5MPSQRAf2n4vqKtQhHJmH7pfYNXXaD6",
        net_leverage: 0.0,
        open_ms: 1711920555083,
        orders: [
          {
            leverage: -5.0,
            order_type: "SHORT",
            order_uuid: "54f6b3bc-7a95-4d28-94fc-3f52c8050a0d",
            price: 70785.28,
            price_sources: [],
            processed_ms: 1711920555077,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "938487f2-c9a4-4c85-a1f6-d86a84f6f6c4",
            price: 70945.75,
            price_sources: [],
            processed_ms: 1711924174675,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "60cf73de-9b33-446d-978b-d162426304f7",
            price: 71106.09,
            price_sources: [],
            processed_ms: 1711927795124,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "028042e0-49d5-44b1-9e9a-fc40b896761f",
            price: 71106.79,
            price_sources: [],
            processed_ms: 1711931431631,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "da888289-efe1-4ccb-9484-2f777850ce9b",
            price: 70982.56,
            price_sources: [],
            processed_ms: 1711935035441,
          },
        ],
        position_type: "FLAT",
        position_uuid: "0a38f98b-39fe-4983-ac22-a6bc2e379553",
        return_at_close: 0.9717787434054088,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
    ],
    thirty_day_returns: 1.0,
  },
  "5DX8tSyGrx1QuoR1wL99TWDusvmmWgQW5su3ik2Sc8y8Mqu3": {
    all_time_returns: 1.13837246177453,
    n_positions: 19,
    percentage_profitable: 0.8947368421052632,
    positions: [
      {
        average_entry_price: 151.83,
        close_ms: 1712610897049,
        current_return: 1.000200223934664,
        initial_entry_price: 151.83,
        is_closed_position: true,
        miner_hotkey: "5DX8tSyGrx1QuoR1wL99TWDusvmmWgQW5su3ik2Sc8y8Mqu3",
        net_leverage: 0.0,
        open_ms: 1712606100290,
        orders: [
          {
            leverage: -0.8,
            order_type: "SHORT",
            order_uuid: "9ea335cf-7aaf-4e63-927f-b693bb575db7",
            price: 151.83,
            price_sources: [],
            processed_ms: 1712606100289,
          },
          {
            leverage: 0.8,
            order_type: "FLAT",
            order_uuid: "57a234ef-afe7-4682-bc69-150616652f9d",
            price: 151.792,
            price_sources: [],
            processed_ms: 1712610897049,
          },
        ],
        position_type: "FLAT",
        position_uuid: "9b4aebfc-e6a3-4715-919f-6019fbd5799e",
        return_at_close: 1.0001442127221236,
        trade_pair: ["USDJPY", "USD/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 1.08563,
        close_ms: 1712623093393,
        current_return: 1.000405294621556,
        initial_entry_price: 1.08563,
        is_closed_position: true,
        miner_hotkey: "5DX8tSyGrx1QuoR1wL99TWDusvmmWgQW5su3ik2Sc8y8Mqu3",
        net_leverage: 0.0,
        open_ms: 1712606252221,
        orders: [
          {
            leverage: 0.8,
            order_type: "LONG",
            order_uuid: "09c8fcc7-fb25-4f85-b64e-19b2603552bf",
            price: 1.08563,
            price_sources: [],
            processed_ms: 1712606252221,
          },
          {
            leverage: 0.8,
            order_type: "FLAT",
            order_uuid: "5fd3c473-8ffa-47b1-a351-9ff4e45c9151",
            price: 1.08618,
            price_sources: [],
            processed_ms: 1712623093393,
          },
        ],
        position_type: "FLAT",
        position_uuid: "7d6c3ddc-bee5-4f37-b44c-18216859c08d",
        return_at_close: 1.0003492719250573,
        trade_pair: ["EURUSD", "EUR/USD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 1.0858,
        close_ms: 1712625215752,
        current_return: 1.0001989316632895,
        initial_entry_price: 1.0858,
        is_closed_position: true,
        miner_hotkey: "5DX8tSyGrx1QuoR1wL99TWDusvmmWgQW5su3ik2Sc8y8Mqu3",
        net_leverage: 0.0,
        open_ms: 1712624444497,
        orders: [
          {
            leverage: 0.9,
            order_type: "LONG",
            order_uuid: "6671d707-b4fd-47fc-b901-f1103b77effa",
            price: 1.0858,
            price_sources: [],
            processed_ms: 1712624444496,
          },
          {
            leverage: 0.9,
            order_type: "FLAT",
            order_uuid: "e5cbc72c-9b2d-4304-83f3-4ce47ff9d875",
            price: 1.08604,
            price_sources: [],
            processed_ms: 1712625215752,
          },
        ],
        position_type: "FLAT",
        position_uuid: "ff8bb2d0-e9e9-4c9b-816c-78fbcd943845",
        return_at_close: 1.0001359191305947,
        trade_pair: ["EURUSD", "EUR/USD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 1.08565,
        close_ms: 1712626279322,
        current_return: 1.000356468475107,
        initial_entry_price: 1.08565,
        is_closed_position: true,
        miner_hotkey: "5DX8tSyGrx1QuoR1wL99TWDusvmmWgQW5su3ik2Sc8y8Mqu3",
        net_leverage: 0.0,
        open_ms: 1712625615625,
        orders: [
          {
            leverage: 0.9,
            order_type: "LONG",
            order_uuid: "7119282e-d56e-4f75-acda-72e25954d88e",
            price: 1.08565,
            price_sources: [],
            processed_ms: 1712625615624,
          },
          {
            leverage: 0.3,
            order_type: "FLAT",
            order_uuid: "9be36e9f-5f57-4aa1-8b87-8a0252a8d8cc",
            price: 1.08608,
            price_sources: [],
            processed_ms: 1712626279322,
          },
        ],
        position_type: "FLAT",
        position_uuid: "f0740012-b8c3-4ecb-8f7a-7b03fb5a3617",
        return_at_close: 1.000293446017593,
        trade_pair: ["EURUSD", "EUR/USD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 100.321,
        close_ms: 1712626381878,
        current_return: 1.0003498768951666,
        initial_entry_price: 100.321,
        is_closed_position: true,
        miner_hotkey: "5DX8tSyGrx1QuoR1wL99TWDusvmmWgQW5su3ik2Sc8y8Mqu3",
        net_leverage: 0.0,
        open_ms: 1712624548290,
        orders: [
          {
            leverage: 0.9,
            order_type: "LONG",
            order_uuid: "8aad9661-5135-4d48-99ee-98df8bed3bfb",
            price: 100.321,
            price_sources: [],
            processed_ms: 1712624548290,
          },
          {
            leverage: 0.3,
            order_type: "FLAT",
            order_uuid: "3694b52f-926e-413b-b345-8e1f5f40be3f",
            price: 100.36,
            price_sources: [],
            processed_ms: 1712626381878,
          },
        ],
        position_type: "FLAT",
        position_uuid: "ba6e7107-1784-4eea-910c-2eac49f59fb2",
        return_at_close: 1.0002868548529222,
        trade_pair: ["AUDJPY", "AUD/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 151.88,
        close_ms: 1712628958628,
        current_return: 1.0002370292336054,
        initial_entry_price: 151.88,
        is_closed_position: true,
        miner_hotkey: "5DX8tSyGrx1QuoR1wL99TWDusvmmWgQW5su3ik2Sc8y8Mqu3",
        net_leverage: 0.0,
        open_ms: 1712624037941,
        orders: [
          {
            leverage: -0.9,
            order_type: "SHORT",
            order_uuid: "ad4a6c39-c2cc-45b2-890e-fcf536a7ea2f",
            price: 151.88,
            price_sources: [],
            processed_ms: 1712624037940,
          },
          {
            leverage: 0.3,
            order_type: "FLAT",
            order_uuid: "8a251d46-dbcd-472f-a893-0f4da3f84aae",
            price: 151.84,
            price_sources: [],
            processed_ms: 1712628958628,
          },
        ],
        position_type: "FLAT",
        position_uuid: "3b37a818-5630-4b3b-9879-0109b1c9732d",
        return_at_close: 1.0001740143007636,
        trade_pair: ["USDJPY", "USD/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 151.779,
        close_ms: 1712671669520,
        current_return: 1.000337991421738,
        initial_entry_price: 151.779,
        is_closed_position: true,
        miner_hotkey: "5DX8tSyGrx1QuoR1wL99TWDusvmmWgQW5su3ik2Sc8y8Mqu3",
        net_leverage: 0.0,
        open_ms: 1712670913113,
        orders: [
          {
            leverage: -0.9,
            order_type: "SHORT",
            order_uuid: "93b23740-e805-4c73-ace2-fc95ba8f838e",
            price: 151.779,
            price_sources: [],
            processed_ms: 1712670913112,
          },
          {
            leverage: 0.9,
            order_type: "FLAT",
            order_uuid: "29250d6c-81e6-409d-887a-dcc5bb24b516",
            price: 151.722,
            price_sources: [],
            processed_ms: 1712671669520,
          },
        ],
        position_type: "FLAT",
        position_uuid: "bd728050-fc1a-4ff0-bdbf-eae65533f6c0",
        return_at_close: 1.0002749701282785,
        trade_pair: ["USDJPY", "USD/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 1.79208,
        close_ms: 1712673896657,
        current_return: 1.000336480514263,
        initial_entry_price: 1.79208,
        is_closed_position: true,
        miner_hotkey: "5DX8tSyGrx1QuoR1wL99TWDusvmmWgQW5su3ik2Sc8y8Mqu3",
        net_leverage: 0.0,
        open_ms: 1712669912434,
        orders: [
          {
            leverage: 0.9,
            order_type: "LONG",
            order_uuid: "da513237-e517-4765-8c44-1c14be8271df",
            price: 1.79208,
            price_sources: [],
            processed_ms: 1712669912433,
          },
          {
            leverage: 0.9,
            order_type: "FLAT",
            order_uuid: "1f6999ec-f775-4825-afb0-aec8a136300d",
            price: 1.79275,
            price_sources: [],
            processed_ms: 1712673896657,
          },
        ],
        position_type: "FLAT",
        position_uuid: "c7929024-2c26-440e-b82f-18969f2dee13",
        return_at_close: 1.0002734593159905,
        trade_pair: ["EURNZD", "EUR/NZD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 1.79242,
        close_ms: 1712675289843,
        current_return: 1.000336416688053,
        initial_entry_price: 1.79242,
        is_closed_position: true,
        miner_hotkey: "5DX8tSyGrx1QuoR1wL99TWDusvmmWgQW5su3ik2Sc8y8Mqu3",
        net_leverage: 0.0,
        open_ms: 1712674227276,
        orders: [
          {
            leverage: 0.9,
            order_type: "LONG",
            order_uuid: "2a3bc275-ad74-4a26-8118-fa93c44c812f",
            price: 1.79242,
            price_sources: [],
            processed_ms: 1712674226330,
          },
          {
            leverage: 0.9,
            order_type: "FLAT",
            order_uuid: "c60308ff-2361-4abb-a45d-d43abd8c2181",
            price: 1.79309,
            price_sources: [],
            processed_ms: 1712675289843,
          },
        ],
        position_type: "FLAT",
        position_uuid: "9d78e291-b515-46e3-be98-fee975e0612e",
        return_at_close: 1.0002733954938017,
        trade_pair: ["EURNZD", "EUR/NZD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 151.703,
        close_ms: 1712676946666,
        current_return: 1.0002195078541625,
        initial_entry_price: 151.703,
        is_closed_position: true,
        miner_hotkey: "5DX8tSyGrx1QuoR1wL99TWDusvmmWgQW5su3ik2Sc8y8Mqu3",
        net_leverage: 0.0,
        open_ms: 1712675668789,
        orders: [
          {
            leverage: -0.9,
            order_type: "SHORT",
            order_uuid: "0b91ef32-2136-4ce1-b091-6e20c43063de",
            price: 151.703,
            price_sources: [],
            processed_ms: 1712675668787,
          },
          {
            leverage: 0.9,
            order_type: "FLAT",
            order_uuid: "dddc9fff-5f4f-4eb9-bebc-d6a5bec88fcf",
            price: 151.666,
            price_sources: [],
            processed_ms: 1712676946666,
          },
        ],
        position_type: "FLAT",
        position_uuid: "0fdeceb5-162b-4c2d-acf9-c3c3790461f7",
        return_at_close: 1.0001564940251677,
        trade_pair: ["USDJPY", "USD/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 6333.720000000009,
        close_ms: 1712687241366,
        current_return: 1.0382135408071895,
        initial_entry_price: 3696.53,
        is_closed_position: true,
        miner_hotkey: "5DX8tSyGrx1QuoR1wL99TWDusvmmWgQW5su3ik2Sc8y8Mqu3",
        net_leverage: 0.0,
        open_ms: 1712623674169,
        orders: [
          {
            leverage: -0.3,
            order_type: "SHORT",
            order_uuid: "e93dbf9a-ff25-4589-9550-49ca218ae86a",
            price: 3696.53,
            price_sources: [],
            processed_ms: 1712623674169,
          },
          {
            leverage: -0.3,
            order_type: "SHORT",
            order_uuid: "c9842206-8902-455c-9ac6-7ba531cccede",
            price: 3695.01,
            price_sources: [],
            processed_ms: 1712623776975,
          },
          {
            leverage: -0.3,
            order_type: "SHORT",
            order_uuid: "eb53f90d-1fbb-41d5-871a-d191f1e6e0a8",
            price: 3694.85,
            price_sources: [],
            processed_ms: 1712624176816,
          },
          {
            leverage: -0.3,
            order_type: "SHORT",
            order_uuid: "eea1c694-4e23-4e1e-980a-7624a0f33b03",
            price: 3693.44,
            price_sources: [],
            processed_ms: 1712624330681,
          },
          {
            leverage: -0.3,
            order_type: "SHORT",
            order_uuid: "59afc61d-42e9-445c-84a9-9058b5e5b054",
            price: 3682.69,
            price_sources: [],
            processed_ms: 1712625911725,
          },
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "750449a8-bdb3-4a55-8a77-e5762fd4d55e",
            price: 3617.71,
            price_sources: [],
            processed_ms: 1712652165216,
          },
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "6589e6b3-68bb-4c6c-aab4-387c4f4c755c",
            price: 3617.69,
            price_sources: [],
            processed_ms: 1712652349473,
          },
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "5da6043f-2535-4fca-971a-8bfa748ee5a0",
            price: 3620.16,
            price_sources: [],
            processed_ms: 1712652559454,
          },
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "15a9383a-c249-48e0-94e8-ea8aaf26a936",
            price: 3621.69,
            price_sources: [],
            processed_ms: 1712658958539,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "4d779b48-252c-49b7-ab08-095802e5b303",
            price: 3515.58,
            price_sources: [],
            processed_ms: 1712677074367,
          },
          {
            leverage: 0.25,
            order_type: "FLAT",
            order_uuid: "3b3722ee-1f46-4c4d-8880-47388a53e385",
            price: 3508.57,
            price_sources: [],
            processed_ms: 1712687241366,
          },
        ],
        position_type: "FLAT",
        position_uuid: "efeda751-4e06-4cb1-87f7-0ffec5dd5472",
        return_at_close: 1.0366562204959788,
        trade_pair: ["ETHUSD", "ETH/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 70795.975625,
        close_ms: 1712843111867,
        current_return: 1.0199948302528479,
        initial_entry_price: 70216.2,
        is_closed_position: true,
        miner_hotkey: "5DX8tSyGrx1QuoR1wL99TWDusvmmWgQW5su3ik2Sc8y8Mqu3",
        net_leverage: 0.0,
        open_ms: 1712837736398,
        orders: [
          {
            leverage: -0.3,
            order_type: "SHORT",
            order_uuid: "ce591697-69c7-4625-aca1-775019b1558f",
            price: 70216.2,
            price_sources: [],
            processed_ms: 1712837736397,
          },
          {
            leverage: -1.3,
            order_type: "SHORT",
            order_uuid: "7f95a8b5-1051-4bfd-8e2f-c27dc1d2a476",
            price: 70929.77,
            price_sources: [],
            processed_ms: 1712839341490,
          },
          {
            leverage: 1.3,
            order_type: "FLAT",
            order_uuid: "1d8e476d-bf93-4d93-a7cb-fec41c76de69",
            price: 69918.5,
            price_sources: [],
            processed_ms: 1712843111867,
          },
        ],
        position_type: "FLAT",
        position_uuid: "33466849-34f3-490c-b56f-708237c97c18",
        return_at_close: 1.0183628385244432,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 67644.34,
        close_ms: 1713027978224,
        current_return: 1.000332991644238,
        initial_entry_price: 67644.34,
        is_closed_position: true,
        miner_hotkey: "5DX8tSyGrx1QuoR1wL99TWDusvmmWgQW5su3ik2Sc8y8Mqu3",
        net_leverage: 0.0,
        open_ms: 1713021549678,
        orders: [
          {
            leverage: -0.05,
            order_type: "SHORT",
            order_uuid: "e67e0f53-c1d1-4447-a810-3f57189cfde7",
            price: 67644.34,
            price_sources: [],
            processed_ms: 1713021549677,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "1bd80a64-4b7e-4678-82f8-d5b880b40701",
            price: 67193.84,
            price_sources: [],
            processed_ms: 1713027978224,
          },
        ],
        position_type: "FLAT",
        position_uuid: "2a8c67a3-3472-4f61-8235-ee33bb28c570",
        return_at_close: 1.0002829749946558,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: -0.27690000000065673,
        close_ms: 1713797757417,
        current_return: 1.012329108339388,
        initial_entry_price: 1.08797,
        is_closed_position: true,
        miner_hotkey: "5DX8tSyGrx1QuoR1wL99TWDusvmmWgQW5su3ik2Sc8y8Mqu3",
        net_leverage: 0.0,
        open_ms: 1712670581395,
        orders: [
          {
            leverage: 0.9,
            order_type: "LONG",
            order_uuid: "d174d73d-ce72-47c6-ad70-f394b85498bb",
            price: 1.08797,
            price_sources: [],
            processed_ms: 1712670581393,
          },
          {
            leverage: -0.89,
            order_type: "SHORT",
            order_uuid: "4b3cfb07-322d-482c-85e9-c9bd11e8dfd0",
            price: 1.0638,
            price_sources: [],
            processed_ms: 1712925989432,
          },
          {
            leverage: 15.0,
            order_type: "LONG",
            order_uuid: "60ad3312-f3c4-48e9-85a9-3a2357ef28e8",
            price: 1.06123,
            price_sources: [],
            processed_ms: 1713231495760,
          },
          {
            leverage: 15.0,
            order_type: "LONG",
            order_uuid: "1ab903fb-0a1c-4cb3-b935-fdf7c5e13257",
            price: 1.06118,
            price_sources: [],
            processed_ms: 1713231871289,
          },
          {
            leverage: -30.0,
            order_type: "SHORT",
            order_uuid: "84c8cb78-8f80-4908-bf1d-dc7bcfdbcfeb",
            price: 1.06146,
            price_sources: [],
            processed_ms: 1713232060804,
          },
          {
            leverage: 6.0,
            order_type: "LONG",
            order_uuid: "369f353f-1cf1-4633-8254-2f1c0d82c0f7",
            price: 1.06278,
            price_sources: [],
            processed_ms: 1713293200579,
          },
          {
            leverage: -6.0,
            order_type: "SHORT",
            order_uuid: "77bc73aa-21d1-4f6a-acf8-2462e87b3ee5",
            price: 1.062,
            price_sources: [],
            processed_ms: 1713298696450,
          },
          {
            leverage: 7.0,
            order_type: "LONG",
            order_uuid: "d5802b27-535b-43d5-a7d1-91a97bc77538",
            price: 1.06528,
            price_sources: [],
            processed_ms: 1713371955446,
          },
          {
            leverage: -7.0,
            order_type: "SHORT",
            order_uuid: "8309b63e-0826-4cb8-9384-3517266ec4ee",
            price: 1.0671,
            price_sources: [],
            processed_ms: 1713376944060,
          },
          {
            leverage: 5.0,
            order_type: "LONG",
            order_uuid: "4eedba37-8f4f-4371-b50c-94235d316d8b",
            price: 1.0669,
            price_sources: [],
            processed_ms: 1713535910656,
          },
          {
            leverage: -5.0,
            order_type: "SHORT",
            order_uuid: "0da3e556-d705-4763-bcd3-c60bdfafdd52",
            price: 1.06512,
            price_sources: [],
            processed_ms: 1713543076613,
          },
          {
            leverage: 15.0,
            order_type: "LONG",
            order_uuid: "8ee5b0bb-4fdd-4086-a05c-44b6979e065e",
            price: 1.06393,
            price_sources: [],
            processed_ms: 1713790808928,
          },
          {
            leverage: 15.0,
            order_type: "LONG",
            order_uuid: "bdf58fc2-d8e6-4a4c-a7f0-d88c534ed23e",
            price: 1.06342,
            price_sources: [],
            processed_ms: 1713791741384,
          },
          {
            leverage: -15.0,
            order_type: "SHORT",
            order_uuid: "4f4ed2aa-2f5f-46af-805d-bcfd24856a99",
            price: 1.06465,
            price_sources: [],
            processed_ms: 1713797236940,
          },
          {
            leverage: -15.0,
            order_type: "SHORT",
            order_uuid: "e3f2b8bd-508d-4247-877a-3faef54e7b2d",
            price: 1.06459,
            price_sources: [],
            processed_ms: 1713797443391,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "aeda251f-ffcd-4a52-b088-abf242764b70",
            price: 1.06447,
            price_sources: [],
            processed_ms: 1713797757417,
          },
        ],
        position_type: "FLAT",
        position_uuid: "4734c70a-cdec-426b-ab25-8f18bf017171",
        return_at_close: 1.0067380146740295,
        trade_pair: ["EURUSD", "EUR/USD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 64709.251000000004,
        close_ms: 1714180777418,
        current_return: 1.0315110962790965,
        initial_entry_price: 63945.76,
        is_closed_position: true,
        miner_hotkey: "5DX8tSyGrx1QuoR1wL99TWDusvmmWgQW5su3ik2Sc8y8Mqu3",
        net_leverage: 0.0,
        open_ms: 1714045809450,
        orders: [
          {
            leverage: -0.2,
            order_type: "SHORT",
            order_uuid: "86f71e9b-a468-4ef3-b0fb-13fd8df30396",
            price: 63945.76,
            price_sources: [],
            processed_ms: 1714045809448,
          },
          {
            leverage: -0.8,
            order_type: "SHORT",
            order_uuid: "3d543dd7-8f06-4bf1-9a20-b061fa0fbcdf",
            price: 64291.98,
            price_sources: [],
            processed_ms: 1714133278508,
          },
          {
            leverage: -0.5,
            order_type: "SHORT",
            order_uuid: "b8e3cb0c-9eb2-475d-9398-41ffb1a1edbf",
            price: 64032.03,
            price_sources: [],
            processed_ms: 1714156957419,
          },
          {
            leverage: 0.5,
            order_type: "LONG",
            order_uuid: "3fb23239-9daf-4a53-8b19-8bc181681371",
            price: 63059.0,
            price_sources: [],
            processed_ms: 1714179036924,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "0b52f289-5211-408a-b2b4-806b0704440a",
            price: 62694.25,
            price_sources: [],
            processed_ms: 1714180777418,
          },
        ],
        position_type: "FLAT",
        position_uuid: "d977c88a-0450-4409-9e82-9b2d17dd4a75",
        return_at_close: 1.029963829634678,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 141.02300000002612,
        close_ms: 1717706114232,
        current_return: 0.9962990918564804,
        initial_entry_price: 100.755,
        is_closed_position: true,
        miner_hotkey: "5DX8tSyGrx1QuoR1wL99TWDusvmmWgQW5su3ik2Sc8y8Mqu3",
        net_leverage: 0.0,
        open_ms: 1712672278090,
        orders: [
          {
            leverage: 0.9,
            order_type: "LONG",
            order_uuid: "16712df2-d710-44af-ae3e-27796a4db054",
            price: 100.755,
            price_sources: [],
            processed_ms: 1712672278089,
          },
          {
            leverage: -0.89,
            order_type: "SHORT",
            order_uuid: "32050e1f-2e09-4a4c-a2e9-fe458e796dc0",
            price: 99.043,
            price_sources: [],
            processed_ms: 1712926149552,
          },
          {
            leverage: 25.0,
            order_type: "LONG",
            order_uuid: "fe1b0210-2610-4ca0-8d03-61f447241a52",
            price: 98.927,
            price_sources: [],
            processed_ms: 1713230655557,
          },
          {
            leverage: -24.0,
            order_type: "SHORT",
            order_uuid: "bab6d092-fa0f-4f56-8149-766eef62c9c8",
            price: 98.97,
            price_sources: [],
            processed_ms: 1713231015650,
          },
          {
            leverage: -1.0,
            order_type: "SHORT",
            order_uuid: "9806ee9e-5b0b-450e-9331-a756380ebe78",
            price: 99.016,
            price_sources: [],
            processed_ms: 1713232281642,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "8ac0d9a2-e7fd-4903-8268-fde5459c17cf",
            price: 103.7345,
            price_sources: [],
            processed_ms: 1717706114232,
          },
        ],
        position_type: "FLAT",
        position_uuid: "55f98c2d-2cf0-423e-a361-93f6b9cb8e5a",
        return_at_close: 0.9944928016029446,
        trade_pair: ["AUDJPY", "AUD/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 3869.0319999999983,
        close_ms: 1717706373781,
        current_return: 1.0098741948849295,
        initial_entry_price: 3716.86,
        is_closed_position: true,
        miner_hotkey: "5DX8tSyGrx1QuoR1wL99TWDusvmmWgQW5su3ik2Sc8y8Mqu3",
        net_leverage: 0.0,
        open_ms: 1716598122601,
        orders: [
          {
            leverage: -0.4,
            order_type: "SHORT",
            order_uuid: "78acf9d9-359f-4fd1-ad27-ae6d32704ce5",
            price: 3716.86,
            price_sources: [],
            processed_ms: 1716598121285,
          },
          {
            leverage: -0.4,
            order_type: "SHORT",
            order_uuid: "38374542-e5d0-4a29-9734-14ca05a8d71a",
            price: 3805.18,
            price_sources: [],
            processed_ms: 1716724687159,
          },
          {
            leverage: -1.6,
            order_type: "SHORT",
            order_uuid: "2c0cfd55-adcd-4804-860c-dfc91bab75b6",
            price: 3866.62,
            price_sources: [],
            processed_ms: 1716737760305,
          },
          {
            leverage: 1.6,
            order_type: "LONG",
            order_uuid: "041c6d18-286a-4636-9b1e-9fdb2e1f51bb",
            price: 3837.07,
            price_sources: [],
            processed_ms: 1716738482806,
          },
          {
            leverage: 0.4,
            order_type: "LONG",
            order_uuid: "7b462aee-fcd4-4729-bfb5-1da5e306a9e0",
            price: 3764.11,
            price_sources: [],
            processed_ms: 1717004029595,
          },
          {
            leverage: 0.1,
            order_type: "LONG",
            order_uuid: "a8d9bb76-1095-4a74-be64-57ff799bddcc",
            price: 3760.76,
            price_sources: [],
            processed_ms: 1717033132098,
          },
          {
            leverage: -0.2,
            order_type: "SHORT",
            order_uuid: "f889f9fb-b6de-4774-9435-e7e9e50f0a43",
            price: 3800.7,
            price_sources: [],
            processed_ms: 1717255004130,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "e2e7a6d1-7ce6-4c64-b4e6-3b07cadaab5f",
            price: 3795.63,
            price_sources: [],
            processed_ms: 1717706373781,
          },
        ],
        position_type: "FLAT",
        position_uuid: "3d28a41d-be8e-4808-ae50-51a5581b1ebf",
        return_at_close: 1.0072485219782286,
        trade_pair: ["ETHUSD", "ETH/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 5057.039999999998,
        close_ms: 1719859489926,
        current_return: 1.04169962505171,
        initial_entry_price: 3795.19,
        is_closed_position: true,
        miner_hotkey: "5DX8tSyGrx1QuoR1wL99TWDusvmmWgQW5su3ik2Sc8y8Mqu3",
        net_leverage: 0.0,
        open_ms: 1717706491382,
        orders: [
          {
            leverage: -0.5,
            order_type: "SHORT",
            order_uuid: "10d5f8e9-0a66-42f3-81cb-d21c4ef63386",
            price: 3795.19,
            price_sources: [],
            processed_ms: 1717706490008,
          },
          {
            leverage: 0.1,
            order_type: "LONG",
            order_uuid: "470acf28-627d-4d3f-a30e-3735b8791d8d",
            price: 3659.44,
            price_sources: [],
            processed_ms: 1717783463177,
          },
          {
            leverage: 0.1,
            order_type: "LONG",
            order_uuid: "5d38b24c-cf33-4547-93e6-1e4903c12417",
            price: 3637.38,
            price_sources: [],
            processed_ms: 1717784048120,
          },
          {
            leverage: 0.1,
            order_type: "LONG",
            order_uuid: "25a9424a-8754-473d-9c7f-5f5c7fcb009e",
            price: 3321.81,
            price_sources: [],
            processed_ms: 1719236089401,
          },
          {
            leverage: 0.1,
            order_type: "LONG",
            order_uuid: "8d909602-a09a-41a0-9515-0bbb1ac2e7d6",
            price: 3300.28,
            price_sources: [],
            processed_ms: 1719254851006,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "2a20cfa7-c43d-469e-b689-6fa322411c1e",
            price: 3474.46,
            price_sources: [],
            processed_ms: 1719859489926,
          },
        ],
        position_type: "FLAT",
        position_uuid: "0bb45f86-c0b3-4510-aeb2-a77bc5e1b8a6",
        return_at_close: 1.0411787752391843,
        trade_pair: ["ETHUSD", "ETH/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 60109.38,
        close_ms: 1720100249029,
        current_return: 0.9945804465126741,
        initial_entry_price: 60109.38,
        is_closed_position: true,
        miner_hotkey: "5DX8tSyGrx1QuoR1wL99TWDusvmmWgQW5su3ik2Sc8y8Mqu3",
        net_leverage: 0.0,
        open_ms: 1720019966259,
        orders: [
          {
            leverage: 0.1,
            order_type: "LONG",
            order_uuid: "5aebc907-3a7a-4c12-b08d-68e090de5392",
            price: 60109.38,
            price_sources: [],
            processed_ms: 1720019965663,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "5ab2a209-1645-44ab-9e7d-2944dfd458e2",
            price: 56851.72,
            price_sources: [
              {
                close: 56851.72,
                high: 56928.0,
                lag_ms: 29,
                low: 56821.54,
                open: 56851.72,
                source: "Polygon_ws",
                start_ms: 1720100249000,
                timespan_ms: 0,
                volume: 10.73649666,
                vwap: 56831.4658,
                websocket: true,
              },
              {
                close: 56851.5,
                high: 56853.2,
                lag_ms: 2030,
                low: 56849.61,
                open: 56849.61,
                source: "Polygon_rest",
                start_ms: 1720100246000,
                timespan_ms: 1000,
                volume: 0.29610695,
                vwap: 56851.4011,
                websocket: false,
              },
              {
                close: 56864.2,
                high: 56864.2,
                lag_ms: 5971,
                low: 56864.2,
                open: 56864.2,
                source: "TwelveData_ws",
                start_ms: 1720100255000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
              {
                close: 56888.01,
                high: 56943.36,
                lag_ms: 29030,
                low: 56856.1,
                open: 56936.05,
                source: "TwelveData_rest",
                start_ms: 1720100160000,
                timespan_ms: 60000,
                volume: null,
                vwap: null,
                websocket: false,
              },
            ],
            processed_ms: 1720100249029,
          },
        ],
        position_type: "FLAT",
        position_uuid: "5aebc907-3a7a-4c12-b08d-68e090de5392",
        return_at_close: 0.9944809884680228,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 55556.0,
        close_ms: 0,
        current_return: 1.0092915256677946,
        initial_entry_price: 55556.0,
        is_closed_position: false,
        miner_hotkey: "5DX8tSyGrx1QuoR1wL99TWDusvmmWgQW5su3ik2Sc8y8Mqu3",
        net_leverage: 0.2,
        open_ms: 1720182281443,
        orders: [
          {
            leverage: 0.2,
            order_type: "LONG",
            order_uuid: "ec89894f-0921-496c-9fdb-4c55a3dbb1fd",
            price: 55556.0,
            price_sources: [
              {
                close: 55556.0,
                high: 55558.0,
                lag_ms: 224,
                low: 55495.88,
                open: 55556.0,
                source: "Polygon_ws",
                start_ms: 1720182281000,
                timespan_ms: 0,
                volume: 1.96676493,
                vwap: 55504.2744,
                websocket: true,
              },
              {
                close: 55459.0,
                high: 55459.0,
                lag_ms: 224,
                low: 55459.0,
                open: 55459.0,
                source: "TwelveData_ws",
                start_ms: 1720182281000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
              {
                close: 55498.64,
                high: 55498.64,
                lag_ms: 2225,
                low: 55498.64,
                open: 55498.64,
                source: "Polygon_rest",
                start_ms: 1720182278000,
                timespan_ms: 1000,
                volume: 0.04379046,
                vwap: 55498.64,
                websocket: false,
              },
              {
                close: 55435.39,
                high: 55559.74,
                lag_ms: 161225,
                low: 55429.21,
                open: 55551.78,
                source: "TwelveData_rest",
                start_ms: 1720182060000,
                timespan_ms: 60000,
                volume: null,
                vwap: null,
                websocket: false,
              },
            ],
            processed_ms: 1720182281224,
          },
        ],
        position_type: "LONG",
        position_uuid: "ec89894f-0921-496c-9fdb-4c55a3dbb1fd",
        return_at_close: 1.0091905965152277,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 5541.9400000000005,
        close_ms: 0,
        current_return: 0.9964407770564099,
        initial_entry_price: 5541.9400000000005,
        is_closed_position: false,
        miner_hotkey: "5DX8tSyGrx1QuoR1wL99TWDusvmmWgQW5su3ik2Sc8y8Mqu3",
        net_leverage: -0.3,
        open_ms: 1720186756527,
        orders: [
          {
            leverage: -0.3,
            order_type: "SHORT",
            order_uuid: "6f0417fe-ac3e-431d-aaff-f7c429800462",
            price: 5541.9400000000005,
            price_sources: [
              {
                close: 5541.9400000000005,
                high: 5541.9400000000005,
                lag_ms: 370,
                low: 5541.9400000000005,
                open: 5541.9400000000005,
                source: "Polygon_ws",
                start_ms: 1720186755000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
              {
                close: 5541.25,
                high: 5541.25,
                lag_ms: 370,
                low: 5541.25,
                open: 5541.25,
                source: "TwelveData_ws",
                start_ms: 1720186755000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1720186755370,
          },
        ],
        position_type: "SHORT",
        position_uuid: "6f0417fe-ac3e-431d-aaff-f7c429800462",
        return_at_close: 0.9964273251059196,
        trade_pair: ["SPX", "SPX", 9e-5, 0.001, 500],
      },
    ],
    thirty_day_returns: 0.9944809884680228,
  },
  "5DXRG8rCuuF7Lkd46mMbkdDNq52kDdph5PbxrCLAhuKAwkdq": {
    all_time_returns: 1.0,
    n_positions: 0,
    percentage_profitable: 0.0,
    positions: [
      {
        average_entry_price: 3303.22,
        close_ms: 0,
        current_return: 0.9937080333734961,
        initial_entry_price: 3303.22,
        is_closed_position: false,
        miner_hotkey: "5DXRG8rCuuF7Lkd46mMbkdDNq52kDdph5PbxrCLAhuKAwkdq",
        net_leverage: 0.125,
        open_ms: 1711141853371,
        orders: [
          {
            leverage: 0.125,
            order_type: "LONG",
            order_uuid: "79472907-0c2d-4ad4-8796-8400fd8f4a4c",
            price: 3303.22,
            price_sources: [],
            processed_ms: 1711141853371,
          },
        ],
        position_type: "LONG",
        position_uuid: "c0c7202e-3b76-46bb-8bb1-05b9d0b02d2c",
        return_at_close: 0.9936459266214103,
        trade_pair: ["ETHUSD", "ETH/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 63179.35,
        close_ms: 0,
        current_return: 1.0099762620223223,
        initial_entry_price: 63179.35,
        is_closed_position: false,
        miner_hotkey: "5DXRG8rCuuF7Lkd46mMbkdDNq52kDdph5PbxrCLAhuKAwkdq",
        net_leverage: -0.125,
        open_ms: 1711141831090,
        orders: [
          {
            leverage: -0.125,
            order_type: "SHORT",
            order_uuid: "bd3280fc-88a3-450d-8fcc-869b520ec461",
            price: 63179.35,
            price_sources: [],
            processed_ms: 1711141831089,
          },
        ],
        position_type: "SHORT",
        position_uuid: "283786aa-0d80-406e-89e9-c796ff1963ba",
        return_at_close: 1.0099131385059459,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
    ],
    thirty_day_returns: 1.0,
  },
  "5DXuzYrxUu3nst74kYujMXK7tyLgjezjrtC9NxUQ3j73XJSr": {
    all_time_returns: 1.0,
    n_positions: 0,
    percentage_profitable: 0.0,
    positions: [
      {
        average_entry_price: 64569.93,
        close_ms: 1711198600836,
        current_return: 1.0021526428788137,
        initial_entry_price: 64569.93,
        is_closed_position: true,
        miner_hotkey: "5DXuzYrxUu3nst74kYujMXK7tyLgjezjrtC9NxUQ3j73XJSr",
        net_leverage: 0.0,
        open_ms: 1711195025432,
        orders: [
          {
            leverage: 0.6,
            order_type: "LONG",
            order_uuid: "a23629eb-887b-4394-9198-1abb1a5f48e5",
            price: 64569.93,
            price_sources: [],
            processed_ms: 1711195025432,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "63259673-9321-40ce-b310-433809b04f4d",
            price: 64801.59,
            price_sources: [],
            processed_ms: 1711198600836,
          },
        ],
        position_type: "FLAT",
        position_uuid: "d90427ee-9009-4605-8e40-f29823ae1ea6",
        return_at_close: 1.000950059707359,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 64717.94000000001,
        close_ms: 1711202270661,
        current_return: 0.9988996250498702,
        initial_entry_price: 64717.94,
        is_closed_position: true,
        miner_hotkey: "5DXuzYrxUu3nst74kYujMXK7tyLgjezjrtC9NxUQ3j73XJSr",
        net_leverage: 0.0,
        open_ms: 1711198704802,
        orders: [
          {
            leverage: 0.6,
            order_type: "LONG",
            order_uuid: "527bed80-dc93-48f1-b4f3-05869ff34156",
            price: 64717.94,
            price_sources: [],
            processed_ms: 1711198704801,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "690a7b9b-149c-438f-a716-f304672bbc2c",
            price: 64599.25,
            price_sources: [],
            processed_ms: 1711202270661,
          },
        ],
        position_type: "FLAT",
        position_uuid: "a5525c06-665a-4065-9b91-19c395aa88c0",
        return_at_close: 0.9977009454998104,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 64611.43,
        close_ms: 1711205950180,
        current_return: 1.006306314532893,
        initial_entry_price: 64611.43,
        is_closed_position: true,
        miner_hotkey: "5DXuzYrxUu3nst74kYujMXK7tyLgjezjrtC9NxUQ3j73XJSr",
        net_leverage: 0.0,
        open_ms: 1711202357647,
        orders: [
          {
            leverage: 0.6,
            order_type: "LONG",
            order_uuid: "f9bbf0b3-9946-4a34-a652-1339a48061e5",
            price: 64611.43,
            price_sources: [],
            processed_ms: 1711202357646,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "ccd5aaa6-6775-4f95-aaae-9a4be0758177",
            price: 65290.53,
            price_sources: [],
            processed_ms: 1711205950180,
          },
        ],
        position_type: "FLAT",
        position_uuid: "f9e63e68-3fe3-441b-867e-998602936d59",
        return_at_close: 1.0050987469554535,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 65255.37,
        close_ms: 1711209594023,
        current_return: 0.9986864529310001,
        initial_entry_price: 65255.37,
        is_closed_position: true,
        miner_hotkey: "5DXuzYrxUu3nst74kYujMXK7tyLgjezjrtC9NxUQ3j73XJSr",
        net_leverage: 0.0,
        open_ms: 1711206052822,
        orders: [
          {
            leverage: 0.6,
            order_type: "LONG",
            order_uuid: "a0d7ce35-f4c2-4e67-bb57-7c43738dde55",
            price: 65255.37,
            price_sources: [],
            processed_ms: 1711206052821,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "f0369e49-1321-40d8-8a43-74491e17a77e",
            price: 65112.51,
            price_sources: [],
            processed_ms: 1711209594023,
          },
        ],
        position_type: "FLAT",
        position_uuid: "9a95748e-9485-427e-a60e-9f4e8a64f2f2",
        return_at_close: 0.9974880291874829,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 65111.06,
        close_ms: 1711213258760,
        current_return: 1.0051046627101448,
        initial_entry_price: 65111.06,
        is_closed_position: true,
        miner_hotkey: "5DXuzYrxUu3nst74kYujMXK7tyLgjezjrtC9NxUQ3j73XJSr",
        net_leverage: 0.0,
        open_ms: 1711209720962,
        orders: [
          {
            leverage: 0.6,
            order_type: "LONG",
            order_uuid: "727ac08d-9a71-40a8-bc20-2c39399a40fb",
            price: 65111.06,
            price_sources: [],
            processed_ms: 1711209720961,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "0ffbe695-cb4a-4ccc-a020-fa91ffee4016",
            price: 65665.01,
            price_sources: [],
            processed_ms: 1711213258760,
          },
        ],
        position_type: "FLAT",
        position_uuid: "9f979250-26d4-4f9f-97fd-ff05bbc20c9d",
        return_at_close: 1.0038985371148925,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 65724.05,
        close_ms: 1711216913975,
        current_return: 0.9991288120558608,
        initial_entry_price: 65724.05,
        is_closed_position: true,
        miner_hotkey: "5DXuzYrxUu3nst74kYujMXK7tyLgjezjrtC9NxUQ3j73XJSr",
        net_leverage: 0.0,
        open_ms: 1711213345776,
        orders: [
          {
            leverage: 0.6,
            order_type: "LONG",
            order_uuid: "6cd4553c-9923-4b64-bcc8-b0b39b29d391",
            price: 65724.05,
            price_sources: [],
            processed_ms: 1711213345775,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "293555ee-b8a0-48d4-8f4a-59c0a4148e71",
            price: 65628.62,
            price_sources: [],
            processed_ms: 1711216913975,
          },
        ],
        position_type: "FLAT",
        position_uuid: "a76b85fd-20a1-4f3f-8012-d67cf88fccc5",
        return_at_close: 0.9979298574813937,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 65579.0,
        close_ms: 1711220603724,
        current_return: 0.9988486558196984,
        initial_entry_price: 65579.0,
        is_closed_position: true,
        miner_hotkey: "5DXuzYrxUu3nst74kYujMXK7tyLgjezjrtC9NxUQ3j73XJSr",
        net_leverage: 0.0,
        open_ms: 1711217000718,
        orders: [
          {
            leverage: 0.6,
            order_type: "LONG",
            order_uuid: "7710b78e-c331-4576-841b-61b9d5603133",
            price: 65579.0,
            price_sources: [],
            processed_ms: 1711217000717,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "940b3352-8b63-43fa-87c4-d8634a5c180e",
            price: 65453.16,
            price_sources: [],
            processed_ms: 1711220603724,
          },
        ],
        position_type: "FLAT",
        position_uuid: "22ac3006-3e78-44dc-8eb3-74185610e608",
        return_at_close: 0.9976500374327147,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 65475.05,
        close_ms: 1711224235954,
        current_return: 0.9950464184448885,
        initial_entry_price: 65475.05,
        is_closed_position: true,
        miner_hotkey: "5DXuzYrxUu3nst74kYujMXK7tyLgjezjrtC9NxUQ3j73XJSr",
        net_leverage: 0.0,
        open_ms: 1711220708681,
        orders: [
          {
            leverage: 0.6,
            order_type: "LONG",
            order_uuid: "47a1a76d-fa6b-4fe1-b749-a39f8aa6c72a",
            price: 65475.05,
            price_sources: [],
            processed_ms: 1711220708680,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "fb1d876d-7dbf-472a-a5bc-e6ba61c38334",
            price: 64934.49,
            price_sources: [],
            processed_ms: 1711224235954,
          },
        ],
        position_type: "FLAT",
        position_uuid: "e4d6dbbc-560c-49a8-88ce-bda01b52b525",
        return_at_close: 0.9938523627427547,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 64945.21,
        close_ms: 1711227908416,
        current_return: 0.999758966057697,
        initial_entry_price: 64945.21,
        is_closed_position: true,
        miner_hotkey: "5DXuzYrxUu3nst74kYujMXK7tyLgjezjrtC9NxUQ3j73XJSr",
        net_leverage: 0.0,
        open_ms: 1711224363686,
        orders: [
          {
            leverage: 0.6,
            order_type: "LONG",
            order_uuid: "2f45caf9-b31f-4aee-87ef-7e2552ce48da",
            price: 64945.21,
            price_sources: [],
            processed_ms: 1711224363685,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "305edc30-91c1-45fa-8d18-4b3c3f64eb1d",
            price: 64919.12,
            price_sources: [],
            processed_ms: 1711227908416,
          },
        ],
        position_type: "FLAT",
        position_uuid: "f4aa2925-5585-49ca-aefe-b7528c080773",
        return_at_close: 0.9985592552984278,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 64889.72000000001,
        close_ms: 1711231566517,
        current_return: 1.0006596422360892,
        initial_entry_price: 64889.72,
        is_closed_position: true,
        miner_hotkey: "5DXuzYrxUu3nst74kYujMXK7tyLgjezjrtC9NxUQ3j73XJSr",
        net_leverage: 0.0,
        open_ms: 1711227994658,
        orders: [
          {
            leverage: 0.6,
            order_type: "LONG",
            order_uuid: "3cd33c81-3661-450c-b97c-c07e16937be9",
            price: 64889.72,
            price_sources: [],
            processed_ms: 1711227994657,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "430fdf1f-631b-4bb4-a070-543466d42e27",
            price: 64961.06,
            price_sources: [],
            processed_ms: 1711231566517,
          },
        ],
        position_type: "FLAT",
        position_uuid: "337e5635-223f-45da-84f7-f60a8c05ce29",
        return_at_close: 0.9994588506654059,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 64964.60999999999,
        close_ms: 1711235223876,
        current_return: 0.9983496552969379,
        initial_entry_price: 64964.61,
        is_closed_position: true,
        miner_hotkey: "5DXuzYrxUu3nst74kYujMXK7tyLgjezjrtC9NxUQ3j73XJSr",
        net_leverage: 0.0,
        open_ms: 1711231652798,
        orders: [
          {
            leverage: 0.6,
            order_type: "LONG",
            order_uuid: "96ec2c5c-2f5d-4b5f-b5ea-46add76dcf40",
            price: 64964.61,
            price_sources: [],
            processed_ms: 1711231652797,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "a17fbea5-bcd0-4914-b242-13a9012b71bb",
            price: 64785.92,
            price_sources: [],
            processed_ms: 1711235223876,
          },
        ],
        position_type: "FLAT",
        position_uuid: "70fd7763-ae1a-4ed1-a02e-1c86eac2d46b",
        return_at_close: 0.9971516357105815,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 64777.61,
        close_ms: 1711238888102,
        current_return: 0.9947867172005882,
        initial_entry_price: 64777.61,
        is_closed_position: true,
        miner_hotkey: "5DXuzYrxUu3nst74kYujMXK7tyLgjezjrtC9NxUQ3j73XJSr",
        net_leverage: 0.0,
        open_ms: 1711235356696,
        orders: [
          {
            leverage: 0.6,
            order_type: "LONG",
            order_uuid: "340ec4d2-4620-4e53-aa7c-01f8fbbe3210",
            price: 64777.61,
            price_sources: [],
            processed_ms: 1711235356695,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "55142a2b-3829-49fc-8212-2a48417fcfcc",
            price: 64214.77,
            price_sources: [],
            processed_ms: 1711238888102,
          },
        ],
        position_type: "FLAT",
        position_uuid: "873419cc-8184-4260-9f0c-4940dbdfd7b6",
        return_at_close: 0.9935929731399475,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 64272.10999999999,
        close_ms: 1711242572085,
        current_return: 1.0022958325158455,
        initial_entry_price: 64272.11,
        is_closed_position: true,
        miner_hotkey: "5DXuzYrxUu3nst74kYujMXK7tyLgjezjrtC9NxUQ3j73XJSr",
        net_leverage: 0.0,
        open_ms: 1711238975064,
        orders: [
          {
            leverage: 0.6,
            order_type: "LONG",
            order_uuid: "efff9e30-8607-4405-814d-0f5aee0cc972",
            price: 64272.11,
            price_sources: [],
            processed_ms: 1711238975063,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "fa2778bf-8469-4e19-afbc-42dda4c484e6",
            price: 64518.04,
            price_sources: [],
            processed_ms: 1711242572085,
          },
        ],
        position_type: "FLAT",
        position_uuid: "c5aae10f-00df-49d8-b3eb-0e58000cf9cc",
        return_at_close: 1.0010930775168265,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 64502.69,
        close_ms: 1711246219982,
        current_return: 0.9998642847298306,
        initial_entry_price: 64502.69,
        is_closed_position: true,
        miner_hotkey: "5DXuzYrxUu3nst74kYujMXK7tyLgjezjrtC9NxUQ3j73XJSr",
        net_leverage: 0.0,
        open_ms: 1711242674808,
        orders: [
          {
            leverage: 0.6,
            order_type: "LONG",
            order_uuid: "1f32127b-66cf-474c-9fec-2afde11cc584",
            price: 64502.69,
            price_sources: [],
            processed_ms: 1711242674807,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "c62c5ff4-8f63-4908-8742-31ff6bceea66",
            price: 64488.1,
            price_sources: [],
            processed_ms: 1711246219982,
          },
        ],
        position_type: "FLAT",
        position_uuid: "8dc8bb42-7ac9-4784-9545-1aa9df44924d",
        return_at_close: 0.9986644475881548,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 64484.340000000004,
        close_ms: 1711249875844,
        current_return: 0.995559573068438,
        initial_entry_price: 64484.34,
        is_closed_position: true,
        miner_hotkey: "5DXuzYrxUu3nst74kYujMXK7tyLgjezjrtC9NxUQ3j73XJSr",
        net_leverage: 0.0,
        open_ms: 1711246338849,
        orders: [
          {
            leverage: 0.6,
            order_type: "LONG",
            order_uuid: "997e0814-64bd-4406-8abc-b5b125b9dddf",
            price: 64484.34,
            price_sources: [],
            processed_ms: 1711246338847,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "d2b2a05d-55ae-48f1-8315-f1e3658cf0de",
            price: 64007.11,
            price_sources: [],
            processed_ms: 1711249875844,
          },
        ],
        position_type: "FLAT",
        position_uuid: "3a93ba8f-f9ba-42fe-9cd3-4254c0661fdc",
        return_at_close: 0.9943649015807559,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 63960.56,
        close_ms: 1711253541614,
        current_return: 1.001413027027906,
        initial_entry_price: 63960.56,
        is_closed_position: true,
        miner_hotkey: "5DXuzYrxUu3nst74kYujMXK7tyLgjezjrtC9NxUQ3j73XJSr",
        net_leverage: 0.0,
        open_ms: 1711249962806,
        orders: [
          {
            leverage: 0.6,
            order_type: "LONG",
            order_uuid: "fd6313a4-71da-492b-bd38-8774837161ce",
            price: 63960.56,
            price_sources: [],
            processed_ms: 1711249962804,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "9d31c618-57fc-4384-a9b4-376585cdc1b4",
            price: 64111.19,
            price_sources: [],
            processed_ms: 1711253541614,
          },
        ],
        position_type: "FLAT",
        position_uuid: "9ba396ad-1b1d-45ee-b51c-1cba0511b3bc",
        return_at_close: 1.0002113313954726,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 64115.39,
        close_ms: 1711260221949,
        current_return: 1.0013417683336248,
        initial_entry_price: 64115.39,
        is_closed_position: true,
        miner_hotkey: "5DXuzYrxUu3nst74kYujMXK7tyLgjezjrtC9NxUQ3j73XJSr",
        net_leverage: 0.0,
        open_ms: 1711253647135,
        orders: [
          {
            leverage: 0.6,
            order_type: "LONG",
            order_uuid: "480ca49f-79ac-4494-9ead-55fa6f2dc16b",
            price: 64115.39,
            price_sources: [],
            processed_ms: 1711253647133,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "1ffb54c4-a389-4fce-b64f-3b5932b93567",
            price: 64258.77,
            price_sources: [],
            processed_ms: 1711260221949,
          },
        ],
        position_type: "FLAT",
        position_uuid: "2724cdb3-dd02-4e73-9d6b-46d1ebeba31d",
        return_at_close: 1.0001401582116245,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 64120.81,
        close_ms: 1711264160634,
        current_return: 1.000216154474655,
        initial_entry_price: 64120.81,
        is_closed_position: true,
        miner_hotkey: "5DXuzYrxUu3nst74kYujMXK7tyLgjezjrtC9NxUQ3j73XJSr",
        net_leverage: 0.0,
        open_ms: 1711264123454,
        orders: [
          {
            leverage: 0.7,
            order_type: "LONG",
            order_uuid: "50e1f123-e3ca-42e0-9e32-c6e9eb8f789b",
            price: 64120.81,
            price_sources: [],
            processed_ms: 1711264123453,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "250556ee-a079-4cb5-bde3-e5c0a5129c89",
            price: 64140.61,
            price_sources: [],
            processed_ms: 1711264160634,
          },
        ],
        position_type: "FLAT",
        position_uuid: "8d9708a2-89d0-4efd-b05e-e30fdaf50c5f",
        return_at_close: 0.9988158518583906,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 64963.62,
        close_ms: 1711278734845,
        current_return: 1.0005175358146605,
        initial_entry_price: 64963.62,
        is_closed_position: true,
        miner_hotkey: "5DXuzYrxUu3nst74kYujMXK7tyLgjezjrtC9NxUQ3j73XJSr",
        net_leverage: 0.0,
        open_ms: 1711275133442,
        orders: [
          {
            leverage: 0.7,
            order_type: "LONG",
            order_uuid: "3e9ca969-a848-44d5-bcdf-184cef63d138",
            price: 64963.62,
            price_sources: [],
            processed_ms: 1711275133440,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "7de14a9d-ad48-4e5a-a1cf-7f298f9f99e4",
            price: 65011.65,
            price_sources: [],
            processed_ms: 1711278734845,
          },
        ],
        position_type: "FLAT",
        position_uuid: "0a7b91d8-f9c2-4fc6-9c1c-417139077aed",
        return_at_close: 0.99911681126452,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 65022.33000000001,
        close_ms: 1711282795049,
        current_return: 1.0029071243678902,
        initial_entry_price: 65022.33,
        is_closed_position: true,
        miner_hotkey: "5DXuzYrxUu3nst74kYujMXK7tyLgjezjrtC9NxUQ3j73XJSr",
        net_leverage: 0.0,
        open_ms: 1711278821775,
        orders: [
          {
            leverage: 0.7,
            order_type: "LONG",
            order_uuid: "94687843-af1f-421b-b8a9-c86046741d70",
            price: 65022.33,
            price_sources: [],
            processed_ms: 1711278821773,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "76eec7f1-4e69-47da-9c9a-06f359c9fccc",
            price: 65292.37,
            price_sources: [],
            processed_ms: 1711282795049,
          },
        ],
        position_type: "FLAT",
        position_uuid: "9ce08446-ef6a-489a-9dbb-b97902d6384c",
        return_at_close: 1.0015030543937753,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 65629.81,
        close_ms: 1711297461208,
        current_return: 1.0025278451971749,
        initial_entry_price: 65629.81,
        is_closed_position: true,
        miner_hotkey: "5DXuzYrxUu3nst74kYujMXK7tyLgjezjrtC9NxUQ3j73XJSr",
        net_leverage: 0.0,
        open_ms: 1711295612977,
        orders: [
          {
            leverage: -1.1,
            order_type: "SHORT",
            order_uuid: "1ab99136-649e-4757-9ce0-f5b74f3252d9",
            price: 65629.81,
            price_sources: [],
            processed_ms: 1711295612976,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "dc2b74a4-d8b6-4864-99d4-bcb3a2a521aa",
            price: 65478.99,
            price_sources: [],
            processed_ms: 1711297461208,
          },
        ],
        position_type: "FLAT",
        position_uuid: "f02f26d2-be1c-4a1c-973e-38a1eaa1a6e6",
        return_at_close: 1.000322283937741,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 65274.54285714286,
        close_ms: 1711303654925,
        current_return: 0.9522991050594971,
        initial_entry_price: 65356.3,
        is_closed_position: true,
        miner_hotkey: "5DXuzYrxUu3nst74kYujMXK7tyLgjezjrtC9NxUQ3j73XJSr",
        net_leverage: 0.0,
        open_ms: 1711299268924,
        orders: [
          {
            leverage: -1.1,
            order_type: "SHORT",
            order_uuid: "87ecba9e-8bfa-488c-9ee6-f2bb5d9704ce",
            price: 65356.3,
            price_sources: [],
            processed_ms: 1711299268923,
          },
          {
            leverage: -2.2,
            order_type: "SHORT",
            order_uuid: "9db5258f-9a0d-44f6-8ed4-171e98da0403",
            price: 65062.05,
            price_sources: [],
            processed_ms: 1711301101005,
          },
          {
            leverage: -4.4,
            order_type: "SHORT",
            order_uuid: "58655e11-7b7d-4816-97a9-763251d0f29e",
            price: 65360.35,
            price_sources: [],
            processed_ms: 1711302947497,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "fbc92e77-e8b7-4aba-a65e-33427df033c0",
            price: 65679.42,
            price_sources: [],
            processed_ms: 1711303654925,
          },
        ],
        position_type: "FLAT",
        position_uuid: "749a1064-cc1b-4655-89e1-b05f9fa69114",
        return_at_close: 0.9376336988415809,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
    ],
    thirty_day_returns: 1.0,
  },
  "5DZuPEvsJCdXC8wtBdMUjjn6EPvUuFCfDC5ATjGz7n3o6Con": {
    all_time_returns: 1.0,
    n_positions: 0,
    percentage_profitable: 0.0,
    positions: [
      {
        average_entry_price: 64569.62999999999,
        close_ms: 1711284522698,
        current_return: 1.0829561823414513,
        initial_entry_price: 64569.63,
        is_closed_position: true,
        miner_hotkey: "5DZuPEvsJCdXC8wtBdMUjjn6EPvUuFCfDC5ATjGz7n3o6Con",
        net_leverage: 0.0,
        open_ms: 1711189184827,
        orders: [
          {
            leverage: 5.0,
            order_type: "LONG",
            order_uuid: "93772304-624e-4a5e-888d-9947d73f9827",
            price: 64569.63,
            price_sources: [],
            processed_ms: 1711189184826,
          },
          {
            leverage: 5.0,
            order_type: "FLAT",
            order_uuid: "64e175e0-776b-480b-964a-79d000e98af2",
            price: 65640.92,
            price_sources: [],
            processed_ms: 1711284522698,
          },
        ],
        position_type: "FLAT",
        position_uuid: "a2ac6db2-3c49-4b37-a3c9-710f0c5b0ef4",
        return_at_close: 1.0721266205180369,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 151.45,
        close_ms: 1711285058746,
        current_return: 1.0,
        initial_entry_price: 151.45,
        is_closed_position: true,
        miner_hotkey: "5DZuPEvsJCdXC8wtBdMUjjn6EPvUuFCfDC5ATjGz7n3o6Con",
        net_leverage: 0.0,
        open_ms: 1711284670341,
        orders: [
          {
            leverage: 0.1,
            order_type: "LONG",
            order_uuid: "dd2ef76c-aee3-45d8-b958-c0ecec71bfe6",
            price: 151.45,
            price_sources: [],
            processed_ms: 1711284670340,
          },
          {
            leverage: 0.1,
            order_type: "FLAT",
            order_uuid: "780a941b-1e0b-4f13-ac4c-3336ac6ba759",
            price: 151.45,
            price_sources: [],
            processed_ms: 1711285058746,
          },
        ],
        position_type: "FLAT",
        position_uuid: "aeff3c61-bf78-472f-bc2c-399b882c3ec9",
        return_at_close: 0.999993,
        trade_pair: ["USDJPY", "USD/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 190.83,
        close_ms: 1711285182152,
        current_return: 1.0,
        initial_entry_price: 190.83,
        is_closed_position: true,
        miner_hotkey: "5DZuPEvsJCdXC8wtBdMUjjn6EPvUuFCfDC5ATjGz7n3o6Con",
        net_leverage: 0.0,
        open_ms: 1711284715987,
        orders: [
          {
            leverage: 0.1,
            order_type: "LONG",
            order_uuid: "cdf3e0d5-c2c8-4f95-a680-5c0a7bece90c",
            price: 190.83,
            price_sources: [],
            processed_ms: 1711284715986,
          },
          {
            leverage: 0.1,
            order_type: "FLAT",
            order_uuid: "df61d755-5543-49fe-8001-9dba46962f04",
            price: 190.83,
            price_sources: [],
            processed_ms: 1711285182152,
          },
        ],
        position_type: "FLAT",
        position_uuid: "f0c6f272-839c-484e-9268-ef81805f28cf",
        return_at_close: 0.999993,
        trade_pair: ["GBPJPY", "GBP/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 3360.06,
        close_ms: 1711378450158,
        current_return: 0.7086063939334415,
        initial_entry_price: 3360.06,
        is_closed_position: true,
        miner_hotkey: "5DZuPEvsJCdXC8wtBdMUjjn6EPvUuFCfDC5ATjGz7n3o6Con",
        net_leverage: -5.0,
        open_ms: 1711188792885,
        orders: [
          {
            leverage: -5.0,
            order_type: "SHORT",
            order_uuid: "a2c948e3-f083-4f05-81e4-9bf3e1e0a210",
            price: 3360.06,
            price_sources: [],
            processed_ms: 1711188792884,
          },
        ],
        position_type: "FLAT",
        position_uuid: "addafcac-2a6b-4c63-b1da-3818a5f5e15f",
        return_at_close: 0.7015203299941071,
        trade_pair: ["ETHUSD", "ETH/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 65631.8,
        close_ms: 1711378450158,
        current_return: 1.2680834595424773,
        initial_entry_price: 65631.8,
        is_closed_position: true,
        miner_hotkey: "5DZuPEvsJCdXC8wtBdMUjjn6EPvUuFCfDC5ATjGz7n3o6Con",
        net_leverage: 5.0,
        open_ms: 1711285227843,
        orders: [
          {
            leverage: 5.0,
            order_type: "LONG",
            order_uuid: "cfbb095f-9b71-44a2-8eb9-db494c26d2e4",
            price: 65631.8,
            price_sources: [],
            processed_ms: 1711285227842,
          },
        ],
        position_type: "FLAT",
        position_uuid: "80c8daba-2c3f-4244-a79b-06fb03cb0f62",
        return_at_close: 1.2554026249470525,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
    ],
    thirty_day_returns: 1.0,
  },
  "5Da5hqCMSVgeGWmzeEnNrime3JKfgTpQmh7dXsdMP58dgeBd": {
    all_time_returns: 1.3283271888404518,
    n_positions: 102,
    percentage_profitable: 0.7745098039215687,
    positions: [
      {
        average_entry_price: -2821.7449999940095,
        close_ms: 1714724059309,
        current_return: 1.0019427208494411,
        initial_entry_price: 153.136,
        is_closed_position: true,
        miner_hotkey: "5Da5hqCMSVgeGWmzeEnNrime3JKfgTpQmh7dXsdMP58dgeBd",
        net_leverage: 0.0,
        open_ms: 1714717691574,
        orders: [
          {
            leverage: 2.5,
            order_type: "LONG",
            order_uuid: "d071f3c2-c151-4533-850f-b3e4bf581ce3",
            price: 153.136,
            price_sources: [],
            processed_ms: 1714717691324,
          },
          {
            leverage: -2.4999,
            order_type: "SHORT",
            order_uuid: "f2091549-32ee-43b2-b2e9-e92a9fff6b0e",
            price: 153.255,
            price_sources: [],
            processed_ms: 1714723878512,
          },
          {
            leverage: 0.001,
            order_type: "FLAT",
            order_uuid: "20d08921-66a2-4063-b49f-4ddc2349b6cf",
            price: 153.26,
            price_sources: [],
            processed_ms: 1714724059309,
          },
        ],
        position_type: "FLAT",
        position_uuid: "fbc8444e-0fdc-49a5-9630-b48fd3350342",
        return_at_close: 1.0017673808732925,
        trade_pair: ["USDJPY", "USD/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: -3712.8339999914124,
        close_ms: 1714724096563,
        current_return: 1.0034147491808982,
        initial_entry_price: 112.013,
        is_closed_position: true,
        miner_hotkey: "5Da5hqCMSVgeGWmzeEnNrime3JKfgTpQmh7dXsdMP58dgeBd",
        net_leverage: 0.0,
        open_ms: 1714717931282,
        orders: [
          {
            leverage: 2.5,
            order_type: "LONG",
            order_uuid: "ffad2af7-b713-4032-b8bc-1a18c30dd8ce",
            price: 112.013,
            price_sources: [],
            processed_ms: 1714717931112,
          },
          {
            leverage: -2.4999,
            order_type: "SHORT",
            order_uuid: "d2a66f1d-952e-49a0-aee1-0ad94ff92d00",
            price: 112.166,
            price_sources: [],
            processed_ms: 1714723927325,
          },
          {
            leverage: 0.001,
            order_type: "FLAT",
            order_uuid: "6e3de134-60d5-4f77-a7f2-d59599c2e5fb",
            price: 112.129,
            price_sources: [],
            processed_ms: 1714724096563,
          },
        ],
        position_type: "FLAT",
        position_uuid: "66b9de76-8dc6-45ba-8b14-12bc168c56e0",
        return_at_close: 1.0032391515997916,
        trade_pair: ["CADJPY", "CAD/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: -7660.325999984003,
        close_ms: 1714724132818,
        current_return: 1.0047608173471811,
        initial_entry_price: 164.361,
        is_closed_position: true,
        miner_hotkey: "5Da5hqCMSVgeGWmzeEnNrime3JKfgTpQmh7dXsdMP58dgeBd",
        net_leverage: 0.0,
        open_ms: 1714717738536,
        orders: [
          {
            leverage: 2.5,
            order_type: "LONG",
            order_uuid: "b82154be-c490-4792-85ee-fd1101f8d136",
            price: 164.361,
            price_sources: [],
            processed_ms: 1714717738362,
          },
          {
            leverage: -2.4999,
            order_type: "SHORT",
            order_uuid: "25b1c74f-fa0b-4389-af9e-4333f6b71c79",
            price: 164.674,
            price_sources: [],
            processed_ms: 1714723931037,
          },
          {
            leverage: 0.001,
            order_type: "FLAT",
            order_uuid: "6c69624a-7257-4031-bc19-402528fec538",
            price: 164.601,
            price_sources: [],
            processed_ms: 1714724132818,
          },
        ],
        position_type: "FLAT",
        position_uuid: "df4af5b9-7577-42ea-94b9-766433ac6353",
        return_at_close: 1.0045849842041454,
        trade_pair: ["EURJPY", "EUR/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 191.75812346268654,
        close_ms: 1714744625839,
        current_return: 1.0449268985908706,
        initial_entry_price: 192.103,
        is_closed_position: true,
        miner_hotkey: "5Da5hqCMSVgeGWmzeEnNrime3JKfgTpQmh7dXsdMP58dgeBd",
        net_leverage: 0.0,
        open_ms: 1714717811879,
        orders: [
          {
            leverage: 2.5,
            order_type: "LONG",
            order_uuid: "1c4bafeb-b26c-4742-be0d-a153d027b3fd",
            price: 192.103,
            price_sources: [],
            processed_ms: 1714717811688,
          },
          {
            leverage: 12.5,
            order_type: "LONG",
            order_uuid: "63413639-8042-4a8f-b384-eb1657ba6906",
            price: 191.744,
            price_sources: [],
            processed_ms: 1714743237176,
          },
          {
            leverage: -14.499,
            order_type: "SHORT",
            order_uuid: "e8aabec8-2399-4861-9aa3-66398952e21f",
            price: 191.658,
            price_sources: [],
            processed_ms: 1714743465029,
          },
          {
            leverage: 50.0,
            order_type: "LONG",
            order_uuid: "67c1aa72-d3f3-44ab-8f5a-e4e3a0214597",
            price: 191.771,
            price_sources: [],
            processed_ms: 1714743562553,
          },
          {
            leverage: 49.999,
            order_type: "LONG",
            order_uuid: "24196b6a-4ade-45be-bccc-e90fd50b7e68",
            price: 191.75,
            price_sources: [],
            processed_ms: 1714743662106,
          },
          {
            leverage: 25.0,
            order_type: "LONG",
            order_uuid: "e1855abd-6ce8-4989-ab38-1157d1b504ee",
            price: 191.675,
            price_sources: [],
            processed_ms: 1714743948603,
          },
          {
            leverage: -25.0,
            order_type: "SHORT",
            order_uuid: "f3aff828-fee9-461c-9a42-1190ae626ef2",
            price: 191.755,
            price_sources: [],
            processed_ms: 1714744005101,
          },
          {
            leverage: 25.0,
            order_type: "LONG",
            order_uuid: "9765c178-6ab6-4068-af5d-d9ff16c829b0",
            price: 191.843,
            price_sources: [],
            processed_ms: 1714744400282,
          },
          {
            leverage: -25.0,
            order_type: "SHORT",
            order_uuid: "2f4cafa2-025d-4dd5-a00a-7c53dc789d3e",
            price: 191.858,
            price_sources: [],
            processed_ms: 1714744528952,
          },
          {
            leverage: 25.0,
            order_type: "FLAT",
            order_uuid: "d844d157-e326-42d7-afdb-8c18bb2f527a",
            price: 191.844,
            price_sources: [],
            processed_ms: 1714744625839,
          },
        ],
        position_type: "FLAT",
        position_uuid: "ac4930c4-fe25-4234-be88-f8af21431693",
        return_at_close: 1.032858066057029,
        trade_pair: ["GBPJPY", "GBP/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: -4277.635000010342,
        close_ms: 1714747483956,
        current_return: 1.0289103117067857,
        initial_entry_price: 153.253,
        is_closed_position: true,
        miner_hotkey: "5Da5hqCMSVgeGWmzeEnNrime3JKfgTpQmh7dXsdMP58dgeBd",
        net_leverage: 0.0,
        open_ms: 1714724185640,
        orders: [
          {
            leverage: 2.5,
            order_type: "LONG",
            order_uuid: "dcc0dbda-cc0c-4ce8-81aa-37e9a1d2023e",
            price: 153.253,
            price_sources: [],
            processed_ms: 1714724185468,
          },
          {
            leverage: 50.0,
            order_type: "LONG",
            order_uuid: "7d999057-bb9c-4bd5-8be9-e7f5dbe6110e",
            price: 152.232,
            price_sources: [],
            processed_ms: 1714740271806,
          },
          {
            leverage: -52.499,
            order_type: "SHORT",
            order_uuid: "e6cf0712-d6fc-4318-b5da-081714c76213",
            price: 152.365,
            price_sources: [],
            processed_ms: 1714740556819,
          },
          {
            leverage: 0.001,
            order_type: "FLAT",
            order_uuid: "71ca6ab4-2320-43b1-a0ff-6fa31d61fb88",
            price: 152.957,
            price_sources: [],
            processed_ms: 1714747483956,
          },
        ],
        position_type: "FLAT",
        position_uuid: "cf6cabec-d50b-4bf0-9741-25e8aacc1f0e",
        return_at_close: 1.0251290663112633,
        trade_pair: ["USDJPY", "USD/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: -809.0190000000671,
        close_ms: 1714747522885,
        current_return: 1.0090458272458924,
        initial_entry_price: 100.617,
        is_closed_position: true,
        miner_hotkey: "5Da5hqCMSVgeGWmzeEnNrime3JKfgTpQmh7dXsdMP58dgeBd",
        net_leverage: 0.0,
        open_ms: 1714717772758,
        orders: [
          {
            leverage: 2.5,
            order_type: "LONG",
            order_uuid: "bb791f33-94b1-4f13-9be9-e3800a15191a",
            price: 100.617,
            price_sources: [],
            processed_ms: 1714717772507,
          },
          {
            leverage: -2.499,
            order_type: "SHORT",
            order_uuid: "3bb74fc2-14ff-4696-a939-c4d4aec892cd",
            price: 100.981,
            price_sources: [],
            processed_ms: 1714740711543,
          },
          {
            leverage: 0.001,
            order_type: "FLAT",
            order_uuid: "e3dc1c3e-f240-497c-9b06-338981076e6d",
            price: 101.145,
            price_sources: [],
            processed_ms: 1714747522885,
          },
        ],
        position_type: "FLAT",
        position_uuid: "a7f45035-8088-4605-b20c-8cd32695f081",
        return_at_close: 1.0088692442261245,
        trade_pair: ["AUDJPY", "AUD/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: -1078.1320000012445,
        close_ms: 1714747587255,
        current_return: 1.0075491784857311,
        initial_entry_price: 164.635,
        is_closed_position: true,
        miner_hotkey: "5Da5hqCMSVgeGWmzeEnNrime3JKfgTpQmh7dXsdMP58dgeBd",
        net_leverage: 0.0,
        open_ms: 1714724289136,
        orders: [
          {
            leverage: 2.5,
            order_type: "LONG",
            order_uuid: "0d812710-008e-48dc-bcaf-4b012cbad4e9",
            price: 164.635,
            price_sources: [],
            processed_ms: 1714724288959,
          },
          {
            leverage: 22.5,
            order_type: "LONG",
            order_uuid: "ec1d7a2b-9a01-493a-a089-a10da3c03144",
            price: 164.352,
            price_sources: [],
            processed_ms: 1714740788039,
          },
          {
            leverage: 25.0,
            order_type: "LONG",
            order_uuid: "72779809-4f46-405e-9b16-5b49c38a7727",
            price: 164.306,
            price_sources: [],
            processed_ms: 1714740918217,
          },
          {
            leverage: -49.999,
            order_type: "SHORT",
            order_uuid: "36e288b5-106c-4f7d-b4da-9ba50627e55d",
            price: 164.368,
            price_sources: [],
            processed_ms: 1714741477142,
          },
          {
            leverage: 0.001,
            order_type: "FLAT",
            order_uuid: "4486d5e0-81d6-4859-8bad-0a15f8c97d38",
            price: 164.727,
            price_sources: [],
            processed_ms: 1714747587255,
          },
        ],
        position_type: "FLAT",
        position_uuid: "2c152323-fd21-4410-b9d3-366409b4f43d",
        return_at_close: 1.004022756361031,
        trade_pair: ["EURJPY", "EUR/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: -223.74200000009714,
        close_ms: 1714747636189,
        current_return: 1.002329511687357,
        initial_entry_price: 168.601,
        is_closed_position: true,
        miner_hotkey: "5Da5hqCMSVgeGWmzeEnNrime3JKfgTpQmh7dXsdMP58dgeBd",
        net_leverage: 0.0,
        open_ms: 1714717845389,
        orders: [
          {
            leverage: 2.5,
            order_type: "LONG",
            order_uuid: "34553fe4-6207-4373-b226-77142f00ee02",
            price: 168.601,
            price_sources: [],
            processed_ms: 1714717845113,
          },
          {
            leverage: -2.499,
            order_type: "SHORT",
            order_uuid: "7ed090d7-ec79-47ae-8e52-a751f8c33758",
            price: 168.758,
            price_sources: [],
            processed_ms: 1714742253161,
          },
          {
            leverage: 0.001,
            order_type: "FLAT",
            order_uuid: "963cd554-c994-4eae-8847-b54e0152436e",
            price: 169.016,
            price_sources: [],
            processed_ms: 1714747636189,
          },
        ],
        position_type: "FLAT",
        position_uuid: "0efc7235-3610-472b-b039-74592dac71ec",
        return_at_close: 1.0021541040228117,
        trade_pair: ["CHFJPY", "CHF/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: -1680.6629999994202,
        close_ms: 1714747670682,
        current_return: 1.0194004027447934,
        initial_entry_price: 91.373,
        is_closed_position: true,
        miner_hotkey: "5Da5hqCMSVgeGWmzeEnNrime3JKfgTpQmh7dXsdMP58dgeBd",
        net_leverage: 0.0,
        open_ms: 1714717997622,
        orders: [
          {
            leverage: 2.5,
            order_type: "LONG",
            order_uuid: "b155332b-7c9a-4555-a129-85a9895d29d3",
            price: 91.373,
            price_sources: [],
            processed_ms: 1714717997416,
          },
          {
            leverage: 2.5,
            order_type: "LONG",
            order_uuid: "08e7268d-92ea-4e66-a34c-6c684350e1f0",
            price: 91.592,
            price_sources: [],
            processed_ms: 1714724216346,
          },
          {
            leverage: -4.999,
            order_type: "SHORT",
            order_uuid: "d8c44a53-c924-441b-a3b1-959b81ec34ba",
            price: 91.837,
            price_sources: [],
            processed_ms: 1714740614602,
          },
          {
            leverage: 0.001,
            order_type: "FLAT",
            order_uuid: "a31598bb-5353-4052-80fd-666cce4f3f2e",
            price: 92.01,
            price_sources: [],
            processed_ms: 1714747670682,
          },
        ],
        position_type: "FLAT",
        position_uuid: "7b7207d1-6de6-4927-878c-8079f86248a5",
        return_at_close: 1.0190436126038327,
        trade_pair: ["NZDJPY", "NZD/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: -1129.047333328562,
        close_ms: 1714747705056,
        current_return: 1.0332017802037377,
        initial_entry_price: 112.122,
        is_closed_position: true,
        miner_hotkey: "5Da5hqCMSVgeGWmzeEnNrime3JKfgTpQmh7dXsdMP58dgeBd",
        net_leverage: 0.0,
        open_ms: 1714724250359,
        orders: [
          {
            leverage: 2.5,
            order_type: "LONG",
            order_uuid: "af19e8ee-f894-4c75-820c-194702ed1510",
            price: 112.122,
            price_sources: [],
            processed_ms: 1714724250185,
          },
          {
            leverage: 25.0,
            order_type: "LONG",
            order_uuid: "3698fdce-eb85-4a4a-b678-b2a3ad4c8f71",
            price: 111.776,
            price_sources: [],
            processed_ms: 1714741641573,
          },
          {
            leverage: -27.499,
            order_type: "SHORT",
            order_uuid: "64c790be-f35a-4e43-9f3e-c9fe0d89463d",
            price: 111.806,
            price_sources: [],
            processed_ms: 1714741815215,
          },
          {
            leverage: 2.5,
            order_type: "LONG",
            order_uuid: "f631d1d0-5850-4454-b9e9-26604abb72fb",
            price: 111.79,
            price_sources: [],
            processed_ms: 1714742041066,
          },
          {
            leverage: 12.5,
            order_type: "LONG",
            order_uuid: "a0c3bd4a-7c85-40b6-8471-3a256031cbbd",
            price: 111.867,
            price_sources: [],
            processed_ms: 1714742314807,
          },
          {
            leverage: 10.0,
            order_type: "LONG",
            order_uuid: "003a3d43-199f-4421-b403-b36af14938cd",
            price: 111.824,
            price_sources: [],
            processed_ms: 1714742372772,
          },
          {
            leverage: 25.0,
            order_type: "LONG",
            order_uuid: "4c3d3683-bc43-493a-9e79-cc670feb5ea3",
            price: 111.818,
            price_sources: [],
            processed_ms: 1714742533748,
          },
          {
            leverage: 25.0,
            order_type: "LONG",
            order_uuid: "06f96cac-ce33-47ca-9594-fb4aa2435d87",
            price: 111.771,
            price_sources: [],
            processed_ms: 1714742840053,
          },
          {
            leverage: -74.999,
            order_type: "SHORT",
            order_uuid: "8b63f5ee-73e8-418e-a444-e4752e770b11",
            price: 111.818,
            price_sources: [],
            processed_ms: 1714742995619,
          },
          {
            leverage: 10.0,
            order_type: "LONG",
            order_uuid: "d0662bce-601a-4a53-baf1-802b0c98749e",
            price: 111.783,
            price_sources: [],
            processed_ms: 1714743180837,
          },
          {
            leverage: -9.999,
            order_type: "SHORT",
            order_uuid: "19d3be7c-a6c1-4786-9162-9e6233b05ae0",
            price: 111.734,
            price_sources: [],
            processed_ms: 1714743510835,
          },
          {
            leverage: 25.0,
            order_type: "LONG",
            order_uuid: "2a63b5d3-0036-460e-a2be-9ed7e333a187",
            price: 111.845,
            price_sources: [],
            processed_ms: 1714744364095,
          },
          {
            leverage: -25.0,
            order_type: "SHORT",
            order_uuid: "26ed0d25-656f-4cdf-b891-7e85d79f351d",
            price: 111.852,
            price_sources: [],
            processed_ms: 1714744575042,
          },
          {
            leverage: 25.0,
            order_type: "LONG",
            order_uuid: "cf2430b8-25ba-4e09-849f-ed3a9371ba99",
            price: 111.748,
            price_sources: [],
            processed_ms: 1714744782442,
          },
          {
            leverage: -25.0,
            order_type: "SHORT",
            order_uuid: "d6440ded-d7fc-478b-ae79-d608cc11556a",
            price: 111.637,
            price_sources: [],
            processed_ms: 1714744813673,
          },
          {
            leverage: 50.0,
            order_type: "LONG",
            order_uuid: "3aec5c83-334f-4f61-a6c8-6dd9eeff2806",
            price: 111.6,
            price_sources: [],
            processed_ms: 1714745119041,
          },
          {
            leverage: -50.0,
            order_type: "SHORT",
            order_uuid: "8e500150-1816-41a1-9930-6aaf02297e48",
            price: 111.634,
            price_sources: [],
            processed_ms: 1714745229655,
          },
          {
            leverage: 50.0,
            order_type: "LONG",
            order_uuid: "8f256ab3-8539-4cc7-bf3b-42961aa84f36",
            price: 111.72,
            price_sources: [],
            processed_ms: 1714745432471,
          },
          {
            leverage: -50.0,
            order_type: "SHORT",
            order_uuid: "76691df9-8127-436b-946f-77306566b431",
            price: 111.668,
            price_sources: [],
            processed_ms: 1714745470539,
          },
          {
            leverage: 50.0,
            order_type: "LONG",
            order_uuid: "103332b3-ddba-42c6-aad0-599b813aa08c",
            price: 111.672,
            price_sources: [],
            processed_ms: 1714745549089,
          },
          {
            leverage: 50.0,
            order_type: "LONG",
            order_uuid: "fa00d54c-4f2f-4ff4-9fdd-411cf5ab4d18",
            price: 111.655,
            price_sources: [],
            processed_ms: 1714745603706,
          },
          {
            leverage: -100.0,
            order_type: "SHORT",
            order_uuid: "32fea1f1-979e-4e4d-9146-a3dc884a319e",
            price: 111.718,
            price_sources: [],
            processed_ms: 1714745883543,
          },
          {
            leverage: 10.0,
            order_type: "LONG",
            order_uuid: "399aed2d-05f8-42aa-8440-22deeb8c1619",
            price: 111.701,
            price_sources: [],
            processed_ms: 1714745940560,
          },
          {
            leverage: 10.0,
            order_type: "LONG",
            order_uuid: "34a751c0-93d7-40cb-afea-9f1f72605fea",
            price: 111.701,
            price_sources: [],
            processed_ms: 1714746368983,
          },
          {
            leverage: -20.0,
            order_type: "SHORT",
            order_uuid: "567b44e9-78f6-43e8-9be0-5475f3270632",
            price: 111.747,
            price_sources: [],
            processed_ms: 1714746533454,
          },
          {
            leverage: 10.0,
            order_type: "LONG",
            order_uuid: "b0e17a73-d677-4a7f-bc0d-b8920ff1585c",
            price: 111.748,
            price_sources: [],
            processed_ms: 1714746833122,
          },
          {
            leverage: 10.0,
            order_type: "LONG",
            order_uuid: "0df8c26b-a3c7-4abd-bbc6-580967640be6",
            price: 111.733,
            price_sources: [],
            processed_ms: 1714746898497,
          },
          {
            leverage: -20.0,
            order_type: "SHORT",
            order_uuid: "fb68cf92-bf4b-40c5-bf91-4b3a8d16bb71",
            price: 111.781,
            price_sources: [],
            processed_ms: 1714747007829,
          },
          {
            leverage: 0.001,
            order_type: "FLAT",
            order_uuid: "11335d88-c6b7-4a47-8b2f-185c13f4152f",
            price: 111.836,
            price_sources: [],
            processed_ms: 1714747705056,
          },
        ],
        position_type: "FLAT",
        position_uuid: "5adb9501-cdf4-4d56-a9f1-d5e379ddfdf4",
        return_at_close: 1.0040913200464974,
        trade_pair: ["CADJPY", "CAD/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 190.44799999999998,
        close_ms: 1714971691353,
        current_return: 1.006836884758597,
        initial_entry_price: 191.754,
        is_closed_position: true,
        miner_hotkey: "5Da5hqCMSVgeGWmzeEnNrime3JKfgTpQmh7dXsdMP58dgeBd",
        net_leverage: 0.0,
        open_ms: 1714942855234,
        orders: [
          {
            leverage: 1.0,
            order_type: "LONG",
            order_uuid: "eeb768ea-1b62-4916-8a07-dc0e25df4bf5",
            price: 191.754,
            price_sources: [],
            processed_ms: 1714942854024,
          },
          {
            leverage: -0.5,
            order_type: "SHORT",
            order_uuid: "9c4abd77-2e72-4510-88ce-c7b402e50a3c",
            price: 193.06,
            price_sources: [],
            processed_ms: 1714971445643,
          },
          {
            leverage: 0.5,
            order_type: "FLAT",
            order_uuid: "e863e758-d119-4758-a126-f9d233903b7f",
            price: 193.07,
            price_sources: [],
            processed_ms: 1714971691353,
          },
        ],
        position_type: "FLAT",
        position_uuid: "dd75a027-05aa-4fe3-ad34-b82bed7a70f5",
        return_at_close: 1.0067664061766637,
        trade_pair: ["GBPJPY", "GBP/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 92.326,
        close_ms: 1715017639348,
        current_return: 1.0005198968871174,
        initial_entry_price: 92.326,
        is_closed_position: true,
        miner_hotkey: "5Da5hqCMSVgeGWmzeEnNrime3JKfgTpQmh7dXsdMP58dgeBd",
        net_leverage: 0.0,
        open_ms: 1714972987908,
        orders: [
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "6a2ab43b-6690-4219-ae60-8eec88d5a76a",
            price: 92.326,
            price_sources: [],
            processed_ms: 1714972987714,
          },
          {
            leverage: 0.01,
            order_type: "FLAT",
            order_uuid: "2341a029-0929-4bb8-9666-25b3e84e6605",
            price: 92.518,
            price_sources: [],
            processed_ms: 1715017639348,
          },
        ],
        position_type: "FLAT",
        position_uuid: "b8a831f4-dd65-4943-b0b9-6d559ea9a1ed",
        return_at_close: 1.0005023877889219,
        trade_pair: ["NZDJPY", "NZD/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 153.858,
        close_ms: 1715065348672,
        current_return: 1.0008286861911633,
        initial_entry_price: 153.858,
        is_closed_position: true,
        miner_hotkey: "5Da5hqCMSVgeGWmzeEnNrime3JKfgTpQmh7dXsdMP58dgeBd",
        net_leverage: 0.0,
        open_ms: 1714971315774,
        orders: [
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "fc5ede9a-3ea1-4ba3-8ee7-eb0f33c5a5b7",
            price: 153.858,
            price_sources: [],
            processed_ms: 1714971315542,
          },
          {
            leverage: 0.001,
            order_type: "FLAT",
            order_uuid: "b9efda20-1539-4ad5-bbcb-a6740462da45",
            price: 154.368,
            price_sources: [],
            processed_ms: 1715065348672,
          },
        ],
        position_type: "FLAT",
        position_uuid: "61ce4cea-d42e-4cc4-8fa1-f180679ad03c",
        return_at_close: 1.000811171689155,
        trade_pair: ["USDJPY", "USD/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 87.70880392156799,
        close_ms: 1715085636695,
        current_return: 1.072405150046511,
        initial_entry_price: 102.135,
        is_closed_position: true,
        miner_hotkey: "5Da5hqCMSVgeGWmzeEnNrime3JKfgTpQmh7dXsdMP58dgeBd",
        net_leverage: 0.0,
        open_ms: 1715084669547,
        orders: [
          {
            leverage: 100.0,
            order_type: "LONG",
            order_uuid: "9bc8cc39-fc64-492a-87c0-0b49baf0bff2",
            price: 102.135,
            price_sources: [],
            processed_ms: 1715084668087,
          },
          {
            leverage: -99.99,
            order_type: "SHORT",
            order_uuid: "457cb3a2-bc74-4db9-a70b-29169e25c1e6",
            price: 102.149,
            price_sources: [],
            processed_ms: 1715084746590,
          },
          {
            leverage: 100.0,
            order_type: "LONG",
            order_uuid: "48e78766-e728-4036-9c90-bc60f59bef86",
            price: 102.16,
            price_sources: [],
            processed_ms: 1715084933901,
          },
          {
            leverage: -99.5,
            order_type: "SHORT",
            order_uuid: "bacd85ff-ec9b-48aa-a4d4-3e76df645eeb",
            price: 102.22,
            price_sources: [],
            processed_ms: 1715085588653,
          },
          {
            leverage: 0.05,
            order_type: "FLAT",
            order_uuid: "5704c9f2-13bc-456f-9dd6-217e5d4d7616",
            price: 102.209,
            price_sources: [],
            processed_ms: 1715085636695,
          },
        ],
        position_type: "FLAT",
        position_uuid: "524dba56-4397-43ae-95d3-de17d1bd716f",
        return_at_close: 1.05739147794586,
        trade_pair: ["AUDJPY", "AUD/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.6569000000000011,
        close_ms: 1715094758410,
        current_return: 1.0037956534862653,
        initial_entry_price: 0.6626,
        is_closed_position: true,
        miner_hotkey: "5Da5hqCMSVgeGWmzeEnNrime3JKfgTpQmh7dXsdMP58dgeBd",
        net_leverage: 0.0,
        open_ms: 1715020354770,
        orders: [
          {
            leverage: 0.5,
            order_type: "LONG",
            order_uuid: "8b385061-7b15-4fec-ab1a-ee2db5dd7a4a",
            price: 0.6626,
            price_sources: [],
            processed_ms: 1715020352712,
          },
          {
            leverage: 5.0,
            order_type: "LONG",
            order_uuid: "16948ce0-276c-441e-adcc-32f4c90cf11e",
            price: 0.65916,
            price_sources: [],
            processed_ms: 1715064318588,
          },
          {
            leverage: 5.0,
            order_type: "LONG",
            order_uuid: "1513715f-d304-4689-a4fb-01387010ada8",
            price: 0.65921,
            price_sources: [],
            processed_ms: 1715064561094,
          },
          {
            leverage: -7.5,
            order_type: "SHORT",
            order_uuid: "c62f780f-7b19-4cb2-b336-8a8601a4d38e",
            price: 0.65936,
            price_sources: [],
            processed_ms: 1715064963303,
          },
          {
            leverage: -2.5,
            order_type: "SHORT",
            order_uuid: "fbcf14d0-8d79-44b1-901b-46d97e71bf6c",
            price: 0.6598,
            price_sources: [],
            processed_ms: 1715067914736,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "839094b0-0f23-46f6-babf-265157977a33",
            price: 0.66193,
            price_sources: [],
            processed_ms: 1715094758410,
          },
        ],
        position_type: "FLAT",
        position_uuid: "848d733f-1bac-449c-bd42-618a5bf81c72",
        return_at_close: 1.0030578636809528,
        trade_pair: ["AUDUSD", "AUD/USD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 165.638,
        close_ms: 1715143082953,
        current_return: 1.001544029751627,
        initial_entry_price: 165.638,
        is_closed_position: true,
        miner_hotkey: "5Da5hqCMSVgeGWmzeEnNrime3JKfgTpQmh7dXsdMP58dgeBd",
        net_leverage: 0.0,
        open_ms: 1714971357236,
        orders: [
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "2930cbd0-9867-4329-abcf-f51ae632b6fd",
            price: 165.638,
            price_sources: [],
            processed_ms: 1714971357011,
          },
          {
            leverage: 0.01,
            order_type: "FLAT",
            order_uuid: "0e0696c3-4ef9-4d49-a818-0e5581ed9f45",
            price: 166.661,
            price_sources: [],
            processed_ms: 1715143082953,
          },
        ],
        position_type: "FLAT",
        position_uuid: "467fe1e9-7a99-44a1-ab15-de91506d834a",
        return_at_close: 1.0015265027311062,
        trade_pair: ["EURJPY", "EUR/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 193.5675,
        close_ms: 1715184423982,
        current_return: 1.0038356746229073,
        initial_entry_price: 193.056,
        is_closed_position: true,
        miner_hotkey: "5Da5hqCMSVgeGWmzeEnNrime3JKfgTpQmh7dXsdMP58dgeBd",
        net_leverage: 0.0,
        open_ms: 1714972937414,
        orders: [
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "03d6e6af-786c-4c73-97c1-f8f730cfb374",
            price: 193.056,
            price_sources: [],
            processed_ms: 1714972937246,
          },
          {
            leverage: 0.75,
            order_type: "LONG",
            order_uuid: "e971979e-0446-48c6-ae91-3fd0e6a1c8c6",
            price: 193.738,
            price_sources: [],
            processed_ms: 1715095681962,
          },
          {
            leverage: 0.01,
            order_type: "FLAT",
            order_uuid: "6b331b10-dca1-48ca-acf1-cb5fb2b33535",
            price: 194.308,
            price_sources: [],
            processed_ms: 1715184423982,
          },
        ],
        position_type: "FLAT",
        position_uuid: "1b322857-4ff6-4a96-8694-ce01726ce189",
        return_at_close: 1.0037654061256838,
        trade_pair: ["GBPJPY", "GBP/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 155.58175,
        close_ms: 1715244276199,
        current_return: 1.0016967888311654,
        initial_entry_price: 155.146,
        is_closed_position: true,
        miner_hotkey: "5Da5hqCMSVgeGWmzeEnNrime3JKfgTpQmh7dXsdMP58dgeBd",
        net_leverage: 0.0,
        open_ms: 1715143160419,
        orders: [
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "7fdfd7ac-d7e0-4ae1-8715-9fac7518f1cc",
            price: 155.146,
            price_sources: [],
            processed_ms: 1715143160030,
          },
          {
            leverage: 0.75,
            order_type: "LONG",
            order_uuid: "98f9a619-9bff-48e7-9926-212509c0896e",
            price: 155.727,
            price_sources: [],
            processed_ms: 1715236534791,
          },
          {
            leverage: 0.001,
            order_type: "FLAT",
            order_uuid: "bf51180e-ca4e-4743-bdc6-adc6d8d81d71",
            price: 155.845,
            price_sources: [],
            processed_ms: 1715244276199,
          },
        ],
        position_type: "FLAT",
        position_uuid: "fdcc1667-00c5-4d8a-9d10-58c91fd6cce9",
        return_at_close: 1.0016266700559473,
        trade_pair: ["USDJPY", "USD/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 113.39,
        close_ms: 1715268922762,
        current_return: 1.0029808625099215,
        initial_entry_price: 113.39,
        is_closed_position: true,
        miner_hotkey: "5Da5hqCMSVgeGWmzeEnNrime3JKfgTpQmh7dXsdMP58dgeBd",
        net_leverage: 0.0,
        open_ms: 1715236504251,
        orders: [
          {
            leverage: 1.0,
            order_type: "LONG",
            order_uuid: "910d7c27-d91b-4a05-a1d0-9e055bd7e67d",
            price: 113.39,
            price_sources: [],
            processed_ms: 1715236502436,
          },
          {
            leverage: 0.01,
            order_type: "FLAT",
            order_uuid: "d402afb5-72a5-4d18-bd38-e447216f2ec9",
            price: 113.728,
            price_sources: [],
            processed_ms: 1715268922762,
          },
        ],
        position_type: "FLAT",
        position_uuid: "048d096d-5176-4606-ba90-51112a0c1ecd",
        return_at_close: 1.0029106538495458,
        trade_pair: ["CADJPY", "CAD/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 165.97000000000003,
        close_ms: 1715323144728,
        current_return: 1.0026155474116543,
        initial_entry_price: 167.269,
        is_closed_position: true,
        miner_hotkey: "5Da5hqCMSVgeGWmzeEnNrime3JKfgTpQmh7dXsdMP58dgeBd",
        net_leverage: 0.0,
        open_ms: 1715236065878,
        orders: [
          {
            leverage: 1.0,
            order_type: "LONG",
            order_uuid: "52ef466c-e6dc-44cb-adb6-48a60b42d409",
            price: 167.269,
            price_sources: [],
            processed_ms: 1715236064537,
          },
          {
            leverage: -0.75,
            order_type: "SHORT",
            order_uuid: "3f1ff835-6e0d-4306-959f-10f569f99092",
            price: 167.702,
            price_sources: [],
            processed_ms: 1715320781787,
          },
          {
            leverage: 0.001,
            order_type: "FLAT",
            order_uuid: "b13a840e-f047-4c68-9035-54ca27b72123",
            price: 167.72,
            price_sources: [],
            processed_ms: 1715323144728,
          },
        ],
        position_type: "FLAT",
        position_uuid: "133a70c0-e146-443d-aa52-eb35ecb19308",
        return_at_close: 1.0025453643233355,
        trade_pair: ["EURJPY", "EUR/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 1.10028999999999,
        close_ms: 1715368594567,
        current_return: 1.0303770170291493,
        initial_entry_price: 1.25432,
        is_closed_position: true,
        miner_hotkey: "5Da5hqCMSVgeGWmzeEnNrime3JKfgTpQmh7dXsdMP58dgeBd",
        net_leverage: 0.0,
        open_ms: 1715095894452,
        orders: [
          {
            leverage: 1.0,
            order_type: "LONG",
            order_uuid: "73c2a699-2108-4643-bce6-7ce72f9c5e24",
            price: 1.25432,
            price_sources: [],
            processed_ms: 1715095892587,
          },
          {
            leverage: 25.0,
            order_type: "LONG",
            order_uuid: "778ffd5f-f88d-43d7-af02-11e32e7415aa",
            price: 1.25079,
            price_sources: [],
            processed_ms: 1715108464629,
          },
          {
            leverage: 25.0,
            order_type: "LONG",
            order_uuid: "d98ed543-58ca-4524-a9ad-a0afc34bf7e0",
            price: 1.25074,
            price_sources: [],
            processed_ms: 1715108638402,
          },
          {
            leverage: -25.0,
            order_type: "SHORT",
            order_uuid: "58fe41f0-d71d-431f-89b1-f5e051dd584a",
            price: 1.2507,
            price_sources: [],
            processed_ms: 1715108896307,
          },
          {
            leverage: 50.0,
            order_type: "LONG",
            order_uuid: "3b2ad271-3287-4e91-87bd-e36a7e967bdb",
            price: 1.25063,
            price_sources: [],
            processed_ms: 1715109420761,
          },
          {
            leverage: 75.0,
            order_type: "LONG",
            order_uuid: "c80cb21f-3956-4624-b701-1f0945346e4d",
            price: 1.25056,
            price_sources: [],
            processed_ms: 1715109537502,
          },
          {
            leverage: -150.0,
            order_type: "SHORT",
            order_uuid: "b2f8771a-277c-4e5c-b40f-53c2e65d86fb",
            price: 1.25085,
            price_sources: [],
            processed_ms: 1715109802409,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "cab1bdc6-77e3-49d3-be8f-daebf343ae55",
            price: 1.24595,
            price_sources: [],
            processed_ms: 1715254129680,
          },
          {
            leverage: -1.5,
            order_type: "SHORT",
            order_uuid: "e5320cbf-a357-498e-aefb-e4b4420a2d01",
            price: 1.2506,
            price_sources: [],
            processed_ms: 1715258792695,
          },
          {
            leverage: -0.75,
            order_type: "SHORT",
            order_uuid: "3fb3e599-6abb-4f9f-99b1-4a0cc06e60c1",
            price: 1.25203,
            price_sources: [],
            processed_ms: 1715320745936,
          },
          {
            leverage: 0.01,
            order_type: "FLAT",
            order_uuid: "b3aac7b7-6f12-4ac0-97be-d5755ee06066",
            price: 1.2527,
            price_sources: [],
            processed_ms: 1715368594567,
          },
        ],
        position_type: "FLAT",
        position_uuid: "442fe4f0-59f5-4898-8e81-31f284764cbd",
        return_at_close: 1.0175745825925622,
        trade_pair: ["GBPUSD", "GBP/USD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 92.47800000000001,
        close_ms: 1715581379800,
        current_return: 1.0030039195527853,
        initial_entry_price: 93.378,
        is_closed_position: true,
        miner_hotkey: "5Da5hqCMSVgeGWmzeEnNrime3JKfgTpQmh7dXsdMP58dgeBd",
        net_leverage: 0.0,
        open_ms: 1715236099667,
        orders: [
          {
            leverage: 1.0,
            order_type: "LONG",
            order_uuid: "bcaa18a8-bb24-4451-9959-0f6931489515",
            price: 93.378,
            price_sources: [],
            processed_ms: 1715236099270,
          },
          {
            leverage: -0.75,
            order_type: "SHORT",
            order_uuid: "5450f347-8227-481b-b3b3-eed24f9552cc",
            price: 93.678,
            price_sources: [],
            processed_ms: 1715259216749,
          },
          {
            leverage: 0.001,
            order_type: "FLAT",
            order_uuid: "fb1583ca-87c3-4c6a-91e9-3260c90dbade",
            price: 93.6,
            price_sources: [],
            processed_ms: 1715581379800,
          },
        ],
        position_type: "FLAT",
        position_uuid: "8f2ea678-8381-422a-9422-e84f7658e656",
        return_at_close: 1.0029337092784165,
        trade_pair: ["NZDJPY", "NZD/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 54472.20000000004,
        close_ms: 1715590187981,
        current_return: 1.0001309502377198,
        initial_entry_price: 63877.7,
        is_closed_position: true,
        miner_hotkey: "5Da5hqCMSVgeGWmzeEnNrime3JKfgTpQmh7dXsdMP58dgeBd",
        net_leverage: 0.0,
        open_ms: 1714938561597,
        orders: [
          {
            leverage: 0.001,
            order_type: "LONG",
            order_uuid: "1310d57d-d4e4-4921-8d83-35a26e5f56f1",
            price: 63877.7,
            price_sources: [],
            processed_ms: 1714938561138,
          },
          {
            leverage: 0.005,
            order_type: "LONG",
            order_uuid: "c1162304-4efe-42de-9de1-7132bef1c2c9",
            price: 60958.9,
            price_sources: [],
            processed_ms: 1715414303231,
          },
          {
            leverage: -0.005,
            order_type: "SHORT",
            order_uuid: "45fb7a64-4716-4292-83c5-a072ad85c700",
            price: 62840.0,
            price_sources: [],
            processed_ms: 1715590128113,
          },
          {
            leverage: 0.001,
            order_type: "FLAT",
            order_uuid: "52832d7f-4bd0-4096-bcfc-24c80a0de79b",
            price: 62837.0,
            price_sources: [],
            processed_ms: 1715590187981,
          },
        ],
        position_type: "FLAT",
        position_uuid: "ea1cc880-b792-468e-89da-31e76fd9eb1a",
        return_at_close: 1.0001249494520184,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 171.82508829161878,
        close_ms: 1715607603886,
        current_return: 1.0027420217752798,
        initial_entry_price: 171.9210184303974,
        is_closed_position: true,
        miner_hotkey: "5Da5hqCMSVgeGWmzeEnNrime3JKfgTpQmh7dXsdMP58dgeBd",
        net_leverage: 0.0,
        open_ms: 1715586462247,
        orders: [
          {
            leverage: 1.0,
            order_type: "LONG",
            order_uuid: "3faf4bc7-e88a-4a4f-bf77-d3d9e88c69c3",
            price: 171.9210184303974,
            price_sources: [],
            processed_ms: 1715586461790,
          },
          {
            leverage: 1.0,
            order_type: "LONG",
            order_uuid: "a7690e70-6f11-42e0-88f2-fc77b147a2b4",
            price: 171.7291581528402,
            price_sources: [],
            processed_ms: 1715592178031,
          },
          {
            leverage: 0.001,
            order_type: "FLAT",
            order_uuid: "507b2ece-b48d-4bea-ab36-8b99c27d3b72",
            price: 172.060793879701,
            price_sources: [],
            processed_ms: 1715607603886,
          },
        ],
        position_type: "FLAT",
        position_uuid: "45255ac4-fd51-4972-8ad9-50b5f31db6d1",
        return_at_close: 1.0026016378922313,
        trade_pair: ["CHFJPY", "CHF/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 92.89499999999998,
        close_ms: 1715668454729,
        current_return: 1.003352858331733,
        initial_entry_price: 93.726,
        is_closed_position: true,
        miner_hotkey: "5Da5hqCMSVgeGWmzeEnNrime3JKfgTpQmh7dXsdMP58dgeBd",
        net_leverage: 0.0,
        open_ms: 1715590513420,
        orders: [
          {
            leverage: 0.5,
            order_type: "LONG",
            order_uuid: "dd78e8ec-f74a-40eb-8938-7b64fdda89b0",
            price: 93.726,
            price_sources: [],
            processed_ms: 1715590512919,
          },
          {
            leverage: 0.5,
            order_type: "LONG",
            order_uuid: "dd6c7ab0-c54a-44f0-9781-f2043736fe75",
            price: 93.717,
            price_sources: [],
            processed_ms: 1715590563891,
          },
          {
            leverage: -0.75,
            order_type: "SHORT",
            order_uuid: "df0f7e65-10c9-4657-8a39-f82720265b9e",
            price: 93.997,
            price_sources: [],
            processed_ms: 1715625298247,
          },
          {
            leverage: 0.01,
            order_type: "FLAT",
            order_uuid: "6622c1d1-7e2c-4e6c-b79f-37286e0da6ce",
            price: 94.152,
            price_sources: [],
            processed_ms: 1715668454729,
          },
        ],
        position_type: "FLAT",
        position_uuid: "4457c767-d516-448e-86c1-6fb65755e1bb",
        return_at_close: 1.0032826236316499,
        trade_pair: ["NZDJPY", "NZD/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 1.07721,
        close_ms: 1715753989895,
        current_return: 1.0024043594099572,
        initial_entry_price: 1.07721,
        is_closed_position: true,
        miner_hotkey: "5Da5hqCMSVgeGWmzeEnNrime3JKfgTpQmh7dXsdMP58dgeBd",
        net_leverage: 0.0,
        open_ms: 1715368539226,
        orders: [
          {
            leverage: 0.5,
            order_type: "LONG",
            order_uuid: "a2550898-3ac2-41aa-8e72-0dcf044ca14d",
            price: 1.07721,
            price_sources: [],
            processed_ms: 1715368538816,
          },
          {
            leverage: 0.01,
            order_type: "FLAT",
            order_uuid: "bf2e7ab4-8ab7-461f-94d8-31631fc60561",
            price: 1.08239,
            price_sources: [],
            processed_ms: 1715753989895,
          },
        ],
        position_type: "FLAT",
        position_uuid: "26d6b142-15f7-48a3-b962-45f1e104173d",
        return_at_close: 1.0023692752573778,
        trade_pair: ["EURUSD", "EUR/USD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 156.11799999999917,
        close_ms: 1715783805939,
        current_return: 0.996318989506006,
        initial_entry_price: 114.637,
        is_closed_position: true,
        miner_hotkey: "5Da5hqCMSVgeGWmzeEnNrime3JKfgTpQmh7dXsdMP58dgeBd",
        net_leverage: 0.0,
        open_ms: 1715754043815,
        orders: [
          {
            leverage: 1.0,
            order_type: "LONG",
            order_uuid: "a4b81070-dffa-40b0-8194-557d83a621bd",
            price: 114.637,
            price_sources: [],
            processed_ms: 1715754042757,
          },
          {
            leverage: -0.99,
            order_type: "SHORT",
            order_uuid: "02a5e08e-c4fe-45f0-be37-1c7b614f4767",
            price: 114.218,
            price_sources: [],
            processed_ms: 1715763212853,
          },
          {
            leverage: 0.01,
            order_type: "FLAT",
            order_uuid: "33b8f60f-1927-4281-9d7f-04fa32f1ddbb",
            price: 113.92,
            price_sources: [],
            processed_ms: 1715783805939,
          },
        ],
        position_type: "FLAT",
        position_uuid: "4a95c14a-1e3a-44ce-9b6e-3fe4514fbcf7",
        return_at_close: 0.9962492471767405,
        trade_pair: ["CADJPY", "CAD/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.7231302341368255,
        close_ms: 1715838504774,
        current_return: 1.002061833029927,
        initial_entry_price: 0.90299,
        is_closed_position: true,
        miner_hotkey: "5Da5hqCMSVgeGWmzeEnNrime3JKfgTpQmh7dXsdMP58dgeBd",
        net_leverage: 0.0,
        open_ms: 1715586720921,
        orders: [
          {
            leverage: 0.5,
            order_type: "LONG",
            order_uuid: "6cfb2772-04ce-401a-bdef-3fa411339be2",
            price: 0.90299,
            price_sources: [],
            processed_ms: 1715586720287,
          },
          {
            leverage: -0.25,
            order_type: "SHORT",
            order_uuid: "61dac40f-c47a-489a-bf41-1c4e9dc799da",
            price: 0.905969990634527,
            price_sources: [],
            processed_ms: 1715754086945,
          },
          {
            leverage: -0.24,
            order_type: "SHORT",
            order_uuid: "e2208225-f9b5-4cc8-8150-92c6bec40255",
            price: 0.90738,
            price_sources: [],
            processed_ms: 1715777599368,
          },
          {
            leverage: 0.01,
            order_type: "FLAT",
            order_uuid: "395fd44d-ea55-45ab-89c1-7540ce4fddb1",
            price: 0.909311694906195,
            price_sources: [],
            processed_ms: 1715838504774,
          },
        ],
        position_type: "FLAT",
        position_uuid: "40f9e0fa-ff5d-44f6-aefb-135197f974b2",
        return_at_close: 1.002026760865771,
        trade_pair: ["AUDCAD", "AUD/CAD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 77.37100000000218,
        close_ms: 1715865035591,
        current_return: 0.9945541740886589,
        initial_entry_price: 167.857,
        is_closed_position: true,
        miner_hotkey: "5Da5hqCMSVgeGWmzeEnNrime3JKfgTpQmh7dXsdMP58dgeBd",
        net_leverage: 0.0,
        open_ms: 1715845461433,
        orders: [
          {
            leverage: -1.0,
            order_type: "SHORT",
            order_uuid: "70493540-60d1-4585-a10f-63d6edc32705",
            price: 167.857,
            price_sources: [],
            processed_ms: 1715845459357,
          },
          {
            leverage: 0.99,
            order_type: "LONG",
            order_uuid: "e1c48d75-0d81-46ad-9a2c-cf14b17b61ba",
            price: 168.771,
            price_sources: [],
            processed_ms: 1715864980552,
          },
          {
            leverage: 0.01,
            order_type: "FLAT",
            order_uuid: "1c2e179c-8470-4dce-b132-0a8d0755c5ca",
            price: 168.783,
            price_sources: [],
            processed_ms: 1715865035591,
          },
        ],
        position_type: "FLAT",
        position_uuid: "2aad641a-ea18-441d-89d8-c95fcdaedb93",
        return_at_close: 0.9944845552964726,
        trade_pair: ["EURJPY", "EUR/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 83.22800000000107,
        close_ms: 1715922526619,
        current_return: 1.0012766025131221,
        initial_entry_price: 94.305,
        is_closed_position: true,
        miner_hotkey: "5Da5hqCMSVgeGWmzeEnNrime3JKfgTpQmh7dXsdMP58dgeBd",
        net_leverage: 0.0,
        open_ms: 1715691539664,
        orders: [
          {
            leverage: 1.0,
            order_type: "LONG",
            order_uuid: "6f7f0c1c-7973-4196-972c-f2632dea0f6e",
            price: 94.305,
            price_sources: [],
            processed_ms: 1715691539020,
          },
          {
            leverage: -0.5,
            order_type: "SHORT",
            order_uuid: "e1517922-b715-4a8d-b7ef-53c91de1197c",
            price: 94.455,
            price_sources: [],
            processed_ms: 1715701766676,
          },
          {
            leverage: -0.49,
            order_type: "SHORT",
            order_uuid: "65546d86-25e0-4441-90de-38dfbbf33294",
            price: 94.378,
            price_sources: [],
            processed_ms: 1715763521791,
          },
          {
            leverage: 0.01,
            order_type: "FLAT",
            order_uuid: "a592042b-665a-4b61-b822-490620de65da",
            price: 95.267,
            price_sources: [],
            processed_ms: 1715922526619,
          },
        ],
        position_type: "FLAT",
        position_uuid: "7d7b0182-3f24-4950-9089-b2fae2db0895",
        return_at_close: 1.0012065131509462,
        trade_pair: ["NZDJPY", "NZD/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 114.005,
        close_ms: 1715961407628,
        current_return: 1.002338024920872,
        initial_entry_price: 114.005,
        is_closed_position: true,
        miner_hotkey: "5Da5hqCMSVgeGWmzeEnNrime3JKfgTpQmh7dXsdMP58dgeBd",
        net_leverage: 0.0,
        open_ms: 1715865173705,
        orders: [
          {
            leverage: 1.0,
            order_type: "LONG",
            order_uuid: "79228737-3c0d-4ca8-80f1-1b083df1a6e8",
            price: 114.005,
            price_sources: [],
            processed_ms: 1715865173043,
          },
          {
            leverage: 0.01,
            order_type: "FLAT",
            order_uuid: "8b9ed80d-f219-4b82-836c-819f05d462a7",
            price: 114.271546531104,
            price_sources: [],
            processed_ms: 1715961407628,
          },
        ],
        position_type: "FLAT",
        position_uuid: "620de51e-c239-47b2-83f2-1c9bf63d4d91",
        return_at_close: 1.0022678612591276,
        trade_pair: ["CADJPY", "CAD/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.8469385148514832,
        close_ms: 1716185166935,
        current_return: 1.015382542865276,
        initial_entry_price: 0.83278,
        is_closed_position: true,
        miner_hotkey: "5Da5hqCMSVgeGWmzeEnNrime3JKfgTpQmh7dXsdMP58dgeBd",
        net_leverage: 0.0,
        open_ms: 1715877955376,
        orders: [
          {
            leverage: -1.0,
            order_type: "SHORT",
            order_uuid: "0b2302be-723e-4fdd-963e-0229cd130524",
            price: 0.83278,
            price_sources: [],
            processed_ms: 1715877954757,
          },
          {
            leverage: -24.01,
            order_type: "SHORT",
            order_uuid: "0975f2d8-9684-4980-b173-4be0d03432e2",
            price: 0.83379,
            price_sources: [],
            processed_ms: 1715930573137,
          },
          {
            leverage: 25.0,
            order_type: "LONG",
            order_uuid: "0ca11f61-4187-4d3d-8193-b877f962adda",
            price: 0.8332,
            price_sources: [],
            processed_ms: 1715930629994,
          },
          {
            leverage: -1.0,
            order_type: "SHORT",
            order_uuid: "d60ac9d3-1376-456a-8975-d98a8efb700e",
            price: 0.83333,
            price_sources: [],
            processed_ms: 1715931269376,
          },
          {
            leverage: 0.01,
            order_type: "FLAT",
            order_uuid: "999d9247-3073-4c28-98b6-23727e8c1e59",
            price: 0.834255075200647,
            price_sources: [],
            processed_ms: 1716185166935,
          },
        ],
        position_type: "FLAT",
        position_uuid: "d67b792f-0b5d-43e2-baf2-5ac119592e37",
        return_at_close: 1.0135338358694812,
        trade_pair: ["NZDCAD", "NZD/CAD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 194.9320000000027,
        close_ms: 1716211601448,
        current_return: 0.9985052481859306,
        initial_entry_price: 169.4395,
        is_closed_position: true,
        miner_hotkey: "5Da5hqCMSVgeGWmzeEnNrime3JKfgTpQmh7dXsdMP58dgeBd",
        net_leverage: 0.0,
        open_ms: 1716187134585,
        orders: [
          {
            leverage: 1.0,
            order_type: "LONG",
            order_uuid: "d067e853-d928-4272-b1fd-ec5264c3d0b9",
            price: 169.4395,
            price_sources: [],
            processed_ms: 1716187134488,
          },
          {
            leverage: -0.99,
            order_type: "SHORT",
            order_uuid: "c0536c8a-ca5f-4e8a-b4f1-9e3556d9d60a",
            price: 169.182,
            price_sources: [],
            processed_ms: 1716207300000,
          },
          {
            leverage: -1.0,
            order_type: "SHORT",
            order_uuid: "a6914bfa-6f05-42d1-b898-67727b740052",
            price: 169.60500000000002,
            price_sources: [],
            processed_ms: 1716211601448,
          },
        ],
        position_type: "FLAT",
        position_uuid: "e45261f6-5cb7-4fb8-b5de-cceb79e567c7",
        return_at_close: 0.9984353528185576,
        trade_pair: ["EURJPY", "EUR/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 1.234859999999992,
        close_ms: 1716211656895,
        current_return: 1.0013656136478595,
        initial_entry_price: 1.08735,
        is_closed_position: true,
        miner_hotkey: "5Da5hqCMSVgeGWmzeEnNrime3JKfgTpQmh7dXsdMP58dgeBd",
        net_leverage: 0.0,
        open_ms: 1715877881435,
        orders: [
          {
            leverage: -1.0,
            order_type: "SHORT",
            order_uuid: "94b40940-035c-4a10-ab5f-f2e5aa4cabe2",
            price: 1.08735,
            price_sources: [],
            processed_ms: 1715877880256,
          },
          {
            leverage: 0.99,
            order_type: "LONG",
            order_uuid: "cf68087f-7b73-4944-8376-e36197ea4b9f",
            price: 1.08586,
            price_sources: [],
            processed_ms: 1715922387175,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "1af5084d-ddf8-4c4a-9c55-a6dcf18896bb",
            price: 1.08637,
            price_sources: [],
            processed_ms: 1716211656895,
          },
        ],
        position_type: "FLAT",
        position_uuid: "2b7de8df-679c-4539-9df2-34d358724a4d",
        return_at_close: 1.0012955180549041,
        trade_pair: ["EURUSD", "EUR/USD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 171.37477428660898,
        close_ms: 1716211726606,
        current_return: 0.995531338772906,
        initial_entry_price: 171.475,
        is_closed_position: true,
        miner_hotkey: "5Da5hqCMSVgeGWmzeEnNrime3JKfgTpQmh7dXsdMP58dgeBd",
        net_leverage: 0.0,
        open_ms: 1715786690281,
        orders: [
          {
            leverage: -0.5,
            order_type: "SHORT",
            order_uuid: "58e15f74-ffa5-4856-9357-8912e2a3aa8c",
            price: 171.475,
            price_sources: [],
            processed_ms: 1715786689564,
          },
          {
            leverage: 0.49,
            order_type: "LONG",
            order_uuid: "84304729-0374-470d-bcd6-132198b4295f",
            price: 171.7330687426856,
            price_sources: [],
            processed_ms: 1715865139753,
          },
          {
            leverage: -1.0,
            order_type: "SHORT",
            order_uuid: "2b734798-b5f2-4697-a615-d72dce09c8b1",
            price: 171.214,
            price_sources: [],
            processed_ms: 1715958628646,
          },
          {
            leverage: 1.0,
            order_type: "LONG",
            order_uuid: "cc68a86b-e9f3-4458-816e-a0d3d357c43f",
            price: 171.37,
            price_sources: [],
            processed_ms: 1715969280172,
          },
          {
            leverage: -1.0,
            order_type: "SHORT",
            order_uuid: "729fe75b-8b67-48d0-af49-415debe038be",
            price: 171.263,
            price_sources: [],
            processed_ms: 1716207332057,
          },
          {
            leverage: -1.0,
            order_type: "SHORT",
            order_uuid: "0120d952-646f-47e8-b43e-4b9e2eb16037",
            price: 171.768,
            price_sources: [],
            processed_ms: 1716211570757,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "6fa70920-0377-4e9f-a6e6-cfcb416d1045",
            price: 171.756,
            price_sources: [],
            processed_ms: 1716211726606,
          },
        ],
        position_type: "FLAT",
        position_uuid: "a431fed1-b9e3-45e8-8a2e-e8d2921bafd5",
        return_at_close: 0.9952874335949066,
        trade_pair: ["CHFJPY", "CHF/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.66709,
        close_ms: 1716279074498,
        current_return: 1.0013641337750527,
        initial_entry_price: 0.66709,
        is_closed_position: true,
        miner_hotkey: "5Da5hqCMSVgeGWmzeEnNrime3JKfgTpQmh7dXsdMP58dgeBd",
        net_leverage: 0.0,
        open_ms: 1716211804881,
        orders: [
          {
            leverage: -1.0,
            order_type: "SHORT",
            order_uuid: "9022c5e6-357e-400f-8423-d063a1a5288b",
            price: 0.66709,
            price_sources: [],
            processed_ms: 1716211804443,
          },
          {
            leverage: 0.01,
            order_type: "FLAT",
            order_uuid: "00a2904d-ae2e-487f-ba73-b539a7772a02",
            price: 0.66618,
            price_sources: [],
            processed_ms: 1716279074498,
          },
        ],
        position_type: "FLAT",
        position_uuid: "f766893f-778f-4a0e-97cd-df9d41fc90fe",
        return_at_close: 1.0012940382856885,
        trade_pair: ["AUDUSD", "AUD/USD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 201.22599999999966,
        close_ms: 1716362847407,
        current_return: 1.0099631702782617,
        initial_entry_price: 198.481,
        is_closed_position: true,
        miner_hotkey: "5Da5hqCMSVgeGWmzeEnNrime3JKfgTpQmh7dXsdMP58dgeBd",
        net_leverage: 0.0,
        open_ms: 1716279030318,
        orders: [
          {
            leverage: -1.0,
            order_type: "SHORT",
            order_uuid: "8a1bc2cd-c54e-467d-a663-0b1d9ee09e78",
            price: 198.481,
            price_sources: [],
            processed_ms: 1716279029953,
          },
          {
            leverage: -15.0,
            order_type: "SHORT",
            order_uuid: "411fe6b5-4d66-413c-8ad7-d67681290a90",
            price: 199.289,
            price_sources: [],
            processed_ms: 1716361693217,
          },
          {
            leverage: -15.0,
            order_type: "SHORT",
            order_uuid: "e184397e-2cf9-4456-9a21-2928b6687383",
            price: 199.35500000000002,
            price_sources: [],
            processed_ms: 1716362042536,
          },
          {
            leverage: 30.0,
            order_type: "LONG",
            order_uuid: "5f898493-b01b-468d-9bd6-450632e89a33",
            price: 199.2305,
            price_sources: [],
            processed_ms: 1716362796018,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "70703fde-4274-4bdd-a988-0b2159ff3c1c",
            price: 199.2485,
            price_sources: [],
            processed_ms: 1716362847407,
          },
        ],
        position_type: "FLAT",
        position_uuid: "bf938ad8-4dd0-4252-9e82-887bf9247ae0",
        return_at_close: 1.0077715501987579,
        trade_pair: ["GBPJPY", "GBP/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 199.302,
        close_ms: 1716403539475,
        current_return: 1.0011690800895123,
        initial_entry_price: 199.302,
        is_closed_position: true,
        miner_hotkey: "5Da5hqCMSVgeGWmzeEnNrime3JKfgTpQmh7dXsdMP58dgeBd",
        net_leverage: 0.0,
        open_ms: 1716386745623,
        orders: [
          {
            leverage: -1.0,
            order_type: "SHORT",
            order_uuid: "40c64e1b-1c51-4eeb-9562-fb2f8a28380f",
            price: 199.302,
            price_sources: [],
            processed_ms: 1716386745510,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "9fec0107-a5b6-475b-93ae-a33688d5e05b",
            price: 199.069,
            price_sources: [],
            processed_ms: 1716403539475,
          },
        ],
        position_type: "FLAT",
        position_uuid: "c3c58c34-abd5-446f-92c4-bed67b8041b5",
        return_at_close: 1.001098998253906,
        trade_pair: ["GBPJPY", "GBP/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 104.038,
        close_ms: 1716403612929,
        current_return: 1.0048011303562159,
        initial_entry_price: 104.038,
        is_closed_position: true,
        miner_hotkey: "5Da5hqCMSVgeGWmzeEnNrime3JKfgTpQmh7dXsdMP58dgeBd",
        net_leverage: 0.0,
        open_ms: 1716278977216,
        orders: [
          {
            leverage: -1.0,
            order_type: "SHORT",
            order_uuid: "374cc724-3636-4457-a70a-a4de78d1bca2",
            price: 104.038,
            price_sources: [],
            processed_ms: 1716278976538,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "3f42c83c-e9aa-45ea-97e3-7e164ccdb5b6",
            price: 103.5385,
            price_sources: [],
            processed_ms: 1716403612929,
          },
        ],
        position_type: "FLAT",
        position_uuid: "8e35d33f-d82d-4b24-8aaa-2a14e2100baf",
        return_at_close: 1.0047307942770909,
        trade_pair: ["AUDJPY", "AUD/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 1.2737349999999998,
        close_ms: 1716403648910,
        current_return: 1.001841042289016,
        initial_entry_price: 1.2737349999999998,
        is_closed_position: true,
        miner_hotkey: "5Da5hqCMSVgeGWmzeEnNrime3JKfgTpQmh7dXsdMP58dgeBd",
        net_leverage: 0.0,
        open_ms: 1716386811898,
        orders: [
          {
            leverage: -1.0,
            order_type: "SHORT",
            order_uuid: "6dacfa8f-0366-43d1-bbfe-de44c48e51e2",
            price: 1.2737349999999998,
            price_sources: [],
            processed_ms: 1716386811782,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "6c0a204d-f16b-446a-8381-b1b433919cbb",
            price: 1.27139,
            price_sources: [],
            processed_ms: 1716403648910,
          },
        ],
        position_type: "FLAT",
        position_uuid: "e2a02ac2-1f83-44fb-b4e2-dc4d2f589d35",
        return_at_close: 1.0017709134160557,
        trade_pair: ["GBPUSD", "GBP/USD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 1.36958,
        close_ms: 1716477955939,
        current_return: 1.0004307890010076,
        initial_entry_price: 1.36958,
        is_closed_position: true,
        miner_hotkey: "5Da5hqCMSVgeGWmzeEnNrime3JKfgTpQmh7dXsdMP58dgeBd",
        net_leverage: 0.0,
        open_ms: 1716405957821,
        orders: [
          {
            leverage: 1.0,
            order_type: "LONG",
            order_uuid: "2506f182-12fa-4d5c-a331-e2d19fb822e2",
            price: 1.36958,
            price_sources: [],
            processed_ms: 1716405957784,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "1811f481-8dba-4cde-93ae-6a8a7d02a976",
            price: 1.37017,
            price_sources: [],
            processed_ms: 1716477955939,
          },
        ],
        position_type: "FLAT",
        position_uuid: "50c83e6c-6440-48b2-870c-12f092a14484",
        return_at_close: 1.0003607588457775,
        trade_pair: ["USDCAD", "USD/CAD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.6615,
        close_ms: 1716489991816,
        current_return: 1.0024489795918368,
        initial_entry_price: 0.6615,
        is_closed_position: true,
        miner_hotkey: "5Da5hqCMSVgeGWmzeEnNrime3JKfgTpQmh7dXsdMP58dgeBd",
        net_leverage: 0.0,
        open_ms: 1716403758857,
        orders: [
          {
            leverage: -1.0,
            order_type: "SHORT",
            order_uuid: "946604e2-ecee-4bca-bb3b-b4bf75f716c4",
            price: 0.6615,
            price_sources: [],
            processed_ms: 1716403758844,
          },
          {
            leverage: 0.01,
            order_type: "FLAT",
            order_uuid: "c1bdb7e2-2acd-40e8-9610-1bd6f9c03a13",
            price: 0.65988,
            price_sources: [],
            processed_ms: 1716489991816,
          },
        ],
        position_type: "FLAT",
        position_uuid: "c18bb809-617d-42b8-a27b-477d01e886c9",
        return_at_close: 1.0023788081632654,
        trade_pair: ["AUDUSD", "AUD/USD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 1.2731199999999998,
        close_ms: 1716526103329,
        current_return: 1.0015263571990558,
        initial_entry_price: 1.271,
        is_closed_position: true,
        miner_hotkey: "5Da5hqCMSVgeGWmzeEnNrime3JKfgTpQmh7dXsdMP58dgeBd",
        net_leverage: 0.0,
        open_ms: 1716405883119,
        orders: [
          {
            leverage: -1.0,
            order_type: "SHORT",
            order_uuid: "5b668ad9-e9b9-458d-ba42-a0178c95ce89",
            price: 1.271,
            price_sources: [],
            processed_ms: 1716405883078,
          },
          {
            leverage: 0.5,
            order_type: "LONG",
            order_uuid: "23624b2b-377e-434b-9e51-dddc23f5490c",
            price: 1.26888,
            price_sources: [],
            processed_ms: 1716490037022,
          },
          {
            leverage: 0.01,
            order_type: "FLAT",
            order_uuid: "aa8d82a9-780a-4217-87ec-126f81d93715",
            price: 1.26924,
            price_sources: [],
            processed_ms: 1716526103329,
          },
        ],
        position_type: "FLAT",
        position_uuid: "62a959c6-1564-4b41-a2f1-dcd51fff3314",
        return_at_close: 1.0014562503540518,
        trade_pair: ["GBPUSD", "GBP/USD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.85153,
        close_ms: 1716554637613,
        current_return: 1.0015736380397637,
        initial_entry_price: 0.85153,
        is_closed_position: true,
        miner_hotkey: "5Da5hqCMSVgeGWmzeEnNrime3JKfgTpQmh7dXsdMP58dgeBd",
        net_leverage: 0.0,
        open_ms: 1716526462179,
        orders: [
          {
            leverage: 1.0,
            order_type: "LONG",
            order_uuid: "24aff8ba-be7a-4619-9700-e35faf4cdbb6",
            price: 0.85153,
            price_sources: [],
            processed_ms: 1716526461667,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "24dc929e-a42d-4e0f-b1d1-02d1d4897966",
            price: 0.85287,
            price_sources: [],
            processed_ms: 1716554637613,
          },
        ],
        position_type: "FLAT",
        position_uuid: "ac6b5be6-4d03-43f7-977f-6740207942fa",
        return_at_close: 1.001503527885101,
        trade_pair: ["EURGBP", "EUR/GBP", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 1.0876227884615384,
        close_ms: 1716885707644,
        current_return: 1.0356994315818584,
        initial_entry_price: 1.08195,
        is_closed_position: true,
        miner_hotkey: "5Da5hqCMSVgeGWmzeEnNrime3JKfgTpQmh7dXsdMP58dgeBd",
        net_leverage: 0.0,
        open_ms: 1716536157634,
        orders: [
          {
            leverage: -1.0,
            order_type: "SHORT",
            order_uuid: "73e15d3f-1ca3-4689-90bc-83cd16514f83",
            price: 1.08195,
            price_sources: [],
            processed_ms: 1716536153679,
          },
          {
            leverage: -1.0,
            order_type: "SHORT",
            order_uuid: "7498f805-c677-43d9-ba6a-03958e20e078",
            price: 1.08506,
            price_sources: [],
            processed_ms: 1716554586569,
          },
          {
            leverage: -25.0,
            order_type: "SHORT",
            order_uuid: "15cb2622-943d-4c79-8e9b-5690bee699ba",
            price: 1.08755,
            price_sources: [],
            processed_ms: 1716872310928,
          },
          {
            leverage: -25.0,
            order_type: "SHORT",
            order_uuid: "23567a57-b3c6-448f-9191-d789855512f8",
            price: 1.087765,
            price_sources: [],
            processed_ms: 1716876293728,
          },
          {
            leverage: -25.0,
            order_type: "SHORT",
            order_uuid: "6a0ed687-1cef-4239-a99e-655b0ac409cb",
            price: 1.08794,
            price_sources: [],
            processed_ms: 1716877144109,
          },
          {
            leverage: 25.0,
            order_type: "LONG",
            order_uuid: "3db5aba7-0455-4678-9313-c0a913c4ad02",
            price: 1.08768,
            price_sources: [],
            processed_ms: 1716877447504,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "d0fcb7c6-62f5-46eb-91aa-7e2f9959db13",
            price: 1.08688,
            price_sources: [],
            processed_ms: 1716885707644,
          },
        ],
        position_type: "FLAT",
        position_uuid: "839d4f9e-e9c4-41c4-bd3d-754522ef31c1",
        return_at_close: 1.0301170116456322,
        trade_pair: ["EURUSD", "EUR/USD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 1.363521875,
        close_ms: 1716959801224,
        current_return: 1.0320714806710443,
        initial_entry_price: 1.371,
        is_closed_position: true,
        miner_hotkey: "5Da5hqCMSVgeGWmzeEnNrime3JKfgTpQmh7dXsdMP58dgeBd",
        net_leverage: 0.0,
        open_ms: 1716550461999,
        orders: [
          {
            leverage: 1.0,
            order_type: "LONG",
            order_uuid: "4f10280f-fa4d-46ce-a2dd-4a330eb3647a",
            price: 1.371,
            price_sources: [],
            processed_ms: 1716550458863,
          },
          {
            leverage: 10.0,
            order_type: "LONG",
            order_uuid: "f42350b1-1f64-4634-8334-ea2acdbe9a1c",
            price: 1.36387,
            price_sources: [],
            processed_ms: 1716906813207,
          },
          {
            leverage: 15.0,
            order_type: "LONG",
            order_uuid: "db59ae79-2883-441b-b2ba-a9207637aa14",
            price: 1.36351,
            price_sources: [],
            processed_ms: 1716910934825,
          },
          {
            leverage: -10.0,
            order_type: "SHORT",
            order_uuid: "291ff77b-faf6-497b-9d2a-f2ed138e1f4a",
            price: 1.3646,
            price_sources: [],
            processed_ms: 1716919188213,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "d94c56db-b4bd-4145-a1c2-b9a5c3d7eed2",
            price: 1.36627,
            price_sources: [],
            processed_ms: 1716959801224,
          },
        ],
        position_type: "FLAT",
        position_uuid: "9be2df9c-5054-4cc4-a497-cb64abb94214",
        return_at_close: 1.030193110576223,
        trade_pair: ["USDCAD", "USD/CAD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 169.445,
        close_ms: 1717051055555,
        current_return: 1.0015314703886218,
        initial_entry_price: 169.445,
        is_closed_position: true,
        miner_hotkey: "5Da5hqCMSVgeGWmzeEnNrime3JKfgTpQmh7dXsdMP58dgeBd",
        net_leverage: 0.0,
        open_ms: 1716403571700,
        orders: [
          {
            leverage: -1.0,
            order_type: "SHORT",
            order_uuid: "10354c19-3b23-480f-9043-b00d2a259019",
            price: 169.445,
            price_sources: [],
            processed_ms: 1716403571655,
          },
          {
            leverage: 0.01,
            order_type: "FLAT",
            order_uuid: "b480dbec-61bd-4310-9fc8-08e8987ea657",
            price: 169.1855,
            price_sources: [],
            processed_ms: 1717051055555,
          },
        ],
        position_type: "FLAT",
        position_uuid: "07574b34-dd4d-49a2-95b6-7a7cf309d9fb",
        return_at_close: 1.0014613631856946,
        trade_pair: ["EURJPY", "EUR/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.90582,
        close_ms: 1717136537466,
        current_return: 1.0016007595327991,
        initial_entry_price: 0.90582,
        is_closed_position: true,
        miner_hotkey: "5Da5hqCMSVgeGWmzeEnNrime3JKfgTpQmh7dXsdMP58dgeBd",
        net_leverage: 0.0,
        open_ms: 1717051211125,
        orders: [
          {
            leverage: 1.0,
            order_type: "LONG",
            order_uuid: "a844272c-7d86-48db-a740-9fb76e41ef6d",
            price: 0.90582,
            price_sources: [],
            processed_ms: 1717051210753,
          },
          {
            leverage: 0.01,
            order_type: "FLAT",
            order_uuid: "8e2a82f6-41bb-4841-930f-0ef23d4c03b4",
            price: 0.90727,
            price_sources: [],
            processed_ms: 1717136537466,
          },
        ],
        position_type: "FLAT",
        position_uuid: "6a750e77-9d89-4046-9289-d0474f1c4e79",
        return_at_close: 1.001530647479632,
        trade_pair: ["AUDCAD", "AUD/CAD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 1.090610000000002,
        close_ms: 1717413851421,
        current_return: 1.00638279020874,
        initial_entry_price: 0.6659,
        is_closed_position: true,
        miner_hotkey: "5Da5hqCMSVgeGWmzeEnNrime3JKfgTpQmh7dXsdMP58dgeBd",
        net_leverage: 0.0,
        open_ms: 1716884946397,
        orders: [
          {
            leverage: -1.0,
            order_type: "SHORT",
            order_uuid: "c88f3b26-e827-4eb8-a2cb-9c423c6433bd",
            price: 0.6659,
            price_sources: [],
            processed_ms: 1716884946375,
          },
          {
            leverage: 0.99,
            order_type: "LONG",
            order_uuid: "8fcd47a4-83c5-4a1b-9b79-229106f60ac2",
            price: 0.66161,
            price_sources: [],
            processed_ms: 1717004998445,
          },
          {
            leverage: 0.01,
            order_type: "FLAT",
            order_uuid: "b6c25706-c43a-460b-91fa-2bf693cf01b3",
            price: 0.66558,
            price_sources: [],
            processed_ms: 1717413851421,
          },
        ],
        position_type: "FLAT",
        position_uuid: "b40ddeac-0e10-4a36-ad0f-0ff0efbb026b",
        return_at_close: 1.0063123434134256,
        trade_pair: ["AUDUSD", "AUD/USD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 95.45,
        close_ms: 1717484571946,
        current_return: 0.9902304871660557,
        initial_entry_price: 95.45,
        is_closed_position: true,
        miner_hotkey: "5Da5hqCMSVgeGWmzeEnNrime3JKfgTpQmh7dXsdMP58dgeBd",
        net_leverage: 0.0,
        open_ms: 1717051646791,
        orders: [
          {
            leverage: -1.0,
            order_type: "SHORT",
            order_uuid: "001d3683-8b8a-4598-9d16-e2191d4be475",
            price: 95.45,
            price_sources: [],
            processed_ms: 1717051646714,
          },
          {
            leverage: 0.01,
            order_type: "FLAT",
            order_uuid: "f66225bb-bf4f-438a-a019-2c0fd47f7110",
            price: 96.3825,
            price_sources: [],
            processed_ms: 1717484571946,
          },
        ],
        position_type: "FLAT",
        position_uuid: "aebe4765-027c-4545-9433-c2df3d4c43bd",
        return_at_close: 0.990161171031954,
        trade_pair: ["NZDJPY", "NZD/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 1.3166538095238098,
        close_ms: 1717571031849,
        current_return: 1.006459761112199,
        initial_entry_price: 1.27675,
        is_closed_position: true,
        miner_hotkey: "5Da5hqCMSVgeGWmzeEnNrime3JKfgTpQmh7dXsdMP58dgeBd",
        net_leverage: 0.0,
        open_ms: 1716884977467,
        orders: [
          {
            leverage: -1.0,
            order_type: "SHORT",
            order_uuid: "7ed0ca4a-bfd7-4801-892c-25bc1a4b44e9",
            price: 1.27675,
            price_sources: [],
            processed_ms: 1716884977456,
          },
          {
            leverage: 0.99,
            order_type: "LONG",
            order_uuid: "d33e0b1c-5f63-4b37-8690-294404ffb16c",
            price: 1.26893,
            price_sources: [],
            processed_ms: 1717051119628,
          },
          {
            leverage: -0.2,
            order_type: "SHORT",
            order_uuid: "b40d5fc9-ce83-4089-872a-d372d00bff8c",
            price: 1.27994,
            price_sources: [],
            processed_ms: 1717484692574,
          },
          {
            leverage: 0.01,
            order_type: "FLAT",
            order_uuid: "68fdc4c0-8321-4e45-b3a1-3168ad2d3966",
            price: 1.27738,
            price_sources: [],
            processed_ms: 1717571031849,
          },
        ],
        position_type: "FLAT",
        position_uuid: "cc3043fb-117d-419a-b313-1a61353fcea1",
        return_at_close: 1.0063752184922656,
        trade_pair: ["GBPUSD", "GBP/USD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 1.3608849999999997,
        close_ms: 1717621625582,
        current_return: 1.001231717268559,
        initial_entry_price: 1.366385,
        is_closed_position: true,
        miner_hotkey: "5Da5hqCMSVgeGWmzeEnNrime3JKfgTpQmh7dXsdMP58dgeBd",
        net_leverage: 0.0,
        open_ms: 1717137328974,
        orders: [
          {
            leverage: 1.0,
            order_type: "LONG",
            order_uuid: "ecb463eb-28a8-41d7-bdce-4a61842353dd",
            price: 1.366385,
            price_sources: [],
            processed_ms: 1717137328955,
          },
          {
            leverage: -0.8,
            order_type: "SHORT",
            order_uuid: "ad19a62e-5bc3-4bc6-9f3d-a37fb144335b",
            price: 1.36776,
            price_sources: [],
            processed_ms: 1717508443421,
          },
          {
            leverage: 0.01,
            order_type: "FLAT",
            order_uuid: "1f2275c3-bc39-4e5d-8ebf-0b9cf682af0e",
            price: 1.3693,
            price_sources: [],
            processed_ms: 1717621625582,
          },
        ],
        position_type: "FLAT",
        position_uuid: "6365b68d-e4e1-4179-9db8-2e70ca6f6887",
        return_at_close: 1.0011616310483502,
        trade_pair: ["USDCAD", "USD/CAD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 113.875,
        close_ms: 1717650298434,
        current_return: 1.0002634467618001,
        initial_entry_price: 113.875,
        is_closed_position: true,
        miner_hotkey: "5Da5hqCMSVgeGWmzeEnNrime3JKfgTpQmh7dXsdMP58dgeBd",
        net_leverage: 0.0,
        open_ms: 1717485663768,
        orders: [
          {
            leverage: -1.0,
            order_type: "SHORT",
            order_uuid: "8a3c44ea-9d06-4860-b515-8cdd6150b508",
            price: 113.875,
            price_sources: [],
            processed_ms: 1717485662967,
          },
          {
            leverage: 0.01,
            order_type: "FLAT",
            order_uuid: "9d46364c-2d17-40de-ad87-62d9fd1118ef",
            price: 113.845,
            price_sources: [],
            processed_ms: 1717650298434,
          },
        ],
        position_type: "FLAT",
        position_uuid: "2f5e5251-9c62-4edb-8326-9330d2323105",
        return_at_close: 1.0001934283205267,
        trade_pair: ["CADJPY", "CAD/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 1.4878149999999999,
        close_ms: 1717650395649,
        current_return: 1.000411677527112,
        initial_entry_price: 1.4878149999999999,
        is_closed_position: true,
        miner_hotkey: "5Da5hqCMSVgeGWmzeEnNrime3JKfgTpQmh7dXsdMP58dgeBd",
        net_leverage: 0.0,
        open_ms: 1717572353095,
        orders: [
          {
            leverage: 0.5,
            order_type: "LONG",
            order_uuid: "2b8480d1-454d-471a-8d3e-45c1aea955af",
            price: 1.4878149999999999,
            price_sources: [],
            processed_ms: 1717572353073,
          },
          {
            leverage: 0.01,
            order_type: "FLAT",
            order_uuid: "a38c41c9-72d2-4e02-a21d-ab4cb1cefe38",
            price: 1.4890400000000001,
            price_sources: [],
            processed_ms: 1717650395649,
          },
        ],
        position_type: "FLAT",
        position_uuid: "5f71630a-d309-4465-a389-5b623605a5cb",
        return_at_close: 1.0003766631183983,
        trade_pair: ["EURCAD", "EUR/CAD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 168.33,
        close_ms: 1717676863239,
        current_return: 0.9896928652052517,
        initial_entry_price: 168.33,
        is_closed_position: true,
        miner_hotkey: "5Da5hqCMSVgeGWmzeEnNrime3JKfgTpQmh7dXsdMP58dgeBd",
        net_leverage: 0.0,
        open_ms: 1717508479647,
        orders: [
          {
            leverage: -1.0,
            order_type: "SHORT",
            order_uuid: "e664d75a-7f51-4c80-aca1-8cfce9b9c7e3",
            price: 168.33,
            price_sources: [],
            processed_ms: 1717508479636,
          },
          {
            leverage: 0.01,
            order_type: "FLAT",
            order_uuid: "8d4d5322-d432-426b-b38f-00496390c9fe",
            price: 170.065,
            price_sources: [],
            processed_ms: 1717676863239,
          },
        ],
        position_type: "FLAT",
        position_uuid: "e4179149-aa93-4973-8305-517c518ed275",
        return_at_close: 0.9896235867046873,
        trade_pair: ["EURJPY", "EUR/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 1.08834,
        close_ms: 1717771252587,
        current_return: 1.0027036587831009,
        initial_entry_price: 1.08834,
        is_closed_position: true,
        miner_hotkey: "5Da5hqCMSVgeGWmzeEnNrime3JKfgTpQmh7dXsdMP58dgeBd",
        net_leverage: 0.0,
        open_ms: 1717651960655,
        orders: [
          {
            leverage: -0.5,
            order_type: "SHORT",
            order_uuid: "6c752606-9bf5-4469-91fa-95b842d9da43",
            price: 1.08834,
            price_sources: [],
            processed_ms: 1717651960399,
          },
          {
            leverage: 0.5,
            order_type: "FLAT",
            order_uuid: "c37dc7b5-4249-437a-bb92-7bc3c3856ba4",
            price: 1.082455,
            price_sources: [],
            processed_ms: 1717771252587,
          },
        ],
        position_type: "FLAT",
        position_uuid: "61e8519c-99f5-423d-a6e4-21cdf19b676f",
        return_at_close: 1.0026685641550435,
        trade_pair: ["EURUSD", "EUR/USD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.85154,
        close_ms: 1717967689487,
        current_return: 1.0031237522606102,
        initial_entry_price: 0.85154,
        is_closed_position: true,
        miner_hotkey: "5Da5hqCMSVgeGWmzeEnNrime3JKfgTpQmh7dXsdMP58dgeBd",
        net_leverage: 0.0,
        open_ms: 1717751424228,
        orders: [
          {
            leverage: -0.5,
            order_type: "SHORT",
            order_uuid: "e561546e-72dd-44d1-9220-69aa647ae293",
            price: 0.85154,
            price_sources: [],
            processed_ms: 1717751424214,
          },
          {
            leverage: 0.01,
            order_type: "FLAT",
            order_uuid: "124b2961-6c9c-4301-bd98-5a8d20fa7444",
            price: 0.84622,
            price_sources: [],
            processed_ms: 1717967689487,
          },
        ],
        position_type: "FLAT",
        position_uuid: "3e4bc890-1848-46b9-a521-be26e4ccd171",
        return_at_close: 1.0030886429292811,
        trade_pair: ["EURGBP", "EUR/GBP", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.9697650000000002,
        close_ms: 1717999612043,
        current_return: 1.0027603804221759,
        initial_entry_price: 0.969975,
        is_closed_position: true,
        miner_hotkey: "5Da5hqCMSVgeGWmzeEnNrime3JKfgTpQmh7dXsdMP58dgeBd",
        net_leverage: 0.0,
        open_ms: 1717650644093,
        orders: [
          {
            leverage: -0.5,
            order_type: "SHORT",
            order_uuid: "8a4cee9d-5732-4b18-81aa-44a0e32a286d",
            price: 0.969975,
            price_sources: [],
            processed_ms: 1717650644079,
          },
          {
            leverage: -0.5,
            order_type: "SHORT",
            order_uuid: "cf238993-4ddc-4494-b8a3-f5297f39a4a4",
            price: 0.9684,
            price_sources: [],
            processed_ms: 1717751373793,
          },
          {
            leverage: 0.5,
            order_type: "LONG",
            order_uuid: "ab296a14-ec34-4717-a0a6-acb0f2a30865",
            price: 0.96861,
            price_sources: [],
            processed_ms: 1717775517518,
          },
          {
            leverage: 0.5,
            order_type: "FLAT",
            order_uuid: "e859fedf-677d-460a-8601-73d2b9c42e48",
            price: 0.96441,
            price_sources: [],
            processed_ms: 1717999612043,
          },
        ],
        position_type: "FLAT",
        position_uuid: "7578b8c0-ec83-4a0c-b3ce-b385e6935e73",
        return_at_close: 1.0026901871955463,
        trade_pair: ["EURCHF", "EUR/CHF", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.6525,
        close_ms: 1718087594216,
        current_return: 1.0007816091954023,
        initial_entry_price: 0.6525,
        is_closed_position: true,
        miner_hotkey: "5Da5hqCMSVgeGWmzeEnNrime3JKfgTpQmh7dXsdMP58dgeBd",
        net_leverage: 0.0,
        open_ms: 1718004552778,
        orders: [
          {
            leverage: -0.5,
            order_type: "SHORT",
            order_uuid: "a23892b8-39b3-4ae4-89b2-3fe88eff45ab",
            price: 0.6525,
            price_sources: [],
            processed_ms: 1718004552763,
          },
          {
            leverage: 0.5,
            order_type: "FLAT",
            order_uuid: "580923e3-8f26-45f8-8d34-ca18d3bb4f74",
            price: 0.65148,
            price_sources: [],
            processed_ms: 1718087594216,
          },
        ],
        position_type: "FLAT",
        position_uuid: "bf75821e-a618-4cfb-b2db-59f5af984c3b",
        return_at_close: 1.0007465818390804,
        trade_pair: ["CADCHF", "CAD/CHF", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 1.07582,
        close_ms: 1718197883089,
        current_return: 1.0059141382255727,
        initial_entry_price: 1.07708,
        is_closed_position: true,
        miner_hotkey: "5Da5hqCMSVgeGWmzeEnNrime3JKfgTpQmh7dXsdMP58dgeBd",
        net_leverage: 0.0,
        open_ms: 1718088485447,
        orders: [
          {
            leverage: 0.5,
            order_type: "LONG",
            order_uuid: "e024b5fb-7fca-43a0-b245-617262b87ca6",
            price: 1.07708,
            price_sources: [],
            processed_ms: 1718088485286,
          },
          {
            leverage: 0.5,
            order_type: "LONG",
            order_uuid: "0d3f4ebf-dd21-4307-b626-b58d6a76316a",
            price: 1.07456,
            price_sources: [],
            processed_ms: 1718095992312,
          },
          {
            leverage: 0.01,
            order_type: "FLAT",
            order_uuid: "79b4a02b-bb41-4dcf-b5bc-91fc61e51ff6",
            price: 1.08219,
            price_sources: [],
            processed_ms: 1718197883089,
          },
        ],
        position_type: "FLAT",
        position_uuid: "847bf164-556a-48f1-a015-92c0581da1e1",
        return_at_close: 1.0058437242358969,
        trade_pair: ["EURUSD", "EUR/USD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 1.4839724999999997,
        close_ms: 1718204475361,
        current_return: 1.0006952169076753,
        initial_entry_price: 1.49234,
        is_closed_position: true,
        miner_hotkey: "5Da5hqCMSVgeGWmzeEnNrime3JKfgTpQmh7dXsdMP58dgeBd",
        net_leverage: 0.0,
        open_ms: 1717676727618,
        orders: [
          {
            leverage: 0.5,
            order_type: "LONG",
            order_uuid: "70241b87-30bd-409e-81cd-70929506ac62",
            price: 1.49234,
            price_sources: [],
            processed_ms: 1717676725426,
          },
          {
            leverage: 0.5,
            order_type: "LONG",
            order_uuid: "65977764-d905-43d1-82ac-34abc80ae3b6",
            price: 1.4812,
            price_sources: [],
            processed_ms: 1718087556821,
          },
          {
            leverage: 0.5,
            order_type: "LONG",
            order_uuid: "389fa52d-a691-452b-9134-7d41eb8b895c",
            price: 1.478985,
            price_sources: [],
            processed_ms: 1718189344452,
          },
          {
            leverage: -0.5,
            order_type: "SHORT",
            order_uuid: "3f1aa8b5-566f-4662-9f2d-2475904112d7",
            price: 1.48458,
            price_sources: [],
            processed_ms: 1718203809714,
          },
          {
            leverage: 0.5,
            order_type: "FLAT",
            order_uuid: "7c6d9c95-39d6-49d5-a6a4-e6528f892875",
            price: 1.48501,
            price_sources: [],
            processed_ms: 1718204475361,
          },
        ],
        position_type: "FLAT",
        position_uuid: "5cbf1dfa-eeca-4557-a80c-d50c022c7851",
        return_at_close: 1.0005901439098999,
        trade_pair: ["EURCAD", "EUR/CAD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.8442875000000001,
        close_ms: 1718262708740,
        current_return: 1.0013292282187156,
        initial_entry_price: 0.8444750000000001,
        is_closed_position: true,
        miner_hotkey: "5Da5hqCMSVgeGWmzeEnNrime3JKfgTpQmh7dXsdMP58dgeBd",
        net_leverage: 0.0,
        open_ms: 1718203770282,
        orders: [
          {
            leverage: 0.5,
            order_type: "LONG",
            order_uuid: "12e6f39f-0427-4bbc-aad4-f4e860b8312c",
            price: 0.8444750000000001,
            price_sources: [],
            processed_ms: 1718203770204,
          },
          {
            leverage: 0.5,
            order_type: "LONG",
            order_uuid: "b4378114-fbb0-4c52-a8d1-e8c5d56c0324",
            price: 0.8441,
            price_sources: [],
            processed_ms: 1718209794556,
          },
          {
            leverage: 0.01,
            order_type: "FLAT",
            order_uuid: "bfcb8226-5a8f-47e3-bf01-9226134bdf38",
            price: 0.84541,
            price_sources: [],
            processed_ms: 1718262708740,
          },
        ],
        position_type: "FLAT",
        position_uuid: "2bff16d2-5eb4-40ac-8810-132994e22b46",
        return_at_close: 1.0012591351727402,
        trade_pair: ["EURGBP", "EUR/GBP", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.61721,
        close_ms: 1718282140685,
        current_return: 1.0034662606498428,
        initial_entry_price: 0.61738,
        is_closed_position: true,
        miner_hotkey: "5Da5hqCMSVgeGWmzeEnNrime3JKfgTpQmh7dXsdMP58dgeBd",
        net_leverage: 0.0,
        open_ms: 1718265557067,
        orders: [
          {
            leverage: 0.5,
            order_type: "LONG",
            order_uuid: "e819fb02-91ed-49db-b8be-197d15721443",
            price: 0.61738,
            price_sources: [],
            processed_ms: 1718265556821,
          },
          {
            leverage: 0.5,
            order_type: "LONG",
            order_uuid: "cfc29651-7bf6-46d0-a4a3-fa0a80a34de5",
            price: 0.61704,
            price_sources: [],
            processed_ms: 1718280950218,
          },
          {
            leverage: 0.01,
            order_type: "FLAT",
            order_uuid: "ae07dfb8-f929-439e-84c1-9078b3cb6a60",
            price: 0.61935,
            price_sources: [],
            processed_ms: 1718282140685,
          },
        ],
        position_type: "FLAT",
        position_uuid: "ac08aa70-6228-485e-abd6-f0ec626d7932",
        return_at_close: 1.0033960180115973,
        trade_pair: ["NZDUSD", "NZD/USD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 1.0823183333333333,
        close_ms: 1718349447360,
        current_return: 0.9845813453427958,
        initial_entry_price: 1.08359,
        is_closed_position: true,
        miner_hotkey: "5Da5hqCMSVgeGWmzeEnNrime3JKfgTpQmh7dXsdMP58dgeBd",
        net_leverage: 0.0,
        open_ms: 1718199152458,
        orders: [
          {
            leverage: 0.5,
            order_type: "LONG",
            order_uuid: "6ef8dfa8-c4aa-4706-8b2f-4b87e6d8521f",
            price: 1.08359,
            price_sources: [],
            processed_ms: 1718199152394,
          },
          {
            leverage: 0.5,
            order_type: "LONG",
            order_uuid: "923b05d2-5227-4e52-932f-bc8a2d24c53a",
            price: 1.08264,
            price_sources: [],
            processed_ms: 1718215781046,
          },
          {
            leverage: 0.5,
            order_type: "LONG",
            order_uuid: "a79a025c-cb4e-431e-be69-452a496977a4",
            price: 1.080725,
            price_sources: [],
            processed_ms: 1718262739404,
          },
          {
            leverage: 0.5,
            order_type: "FLAT",
            order_uuid: "79a556a9-5f8f-4eb6-aaaa-3ccb30f4ead1",
            price: 1.07118,
            price_sources: [],
            processed_ms: 1718349447360,
          },
        ],
        position_type: "FLAT",
        position_uuid: "44b2a0a9-cd9b-447b-90b3-056912e60137",
        return_at_close: 0.9844779643015348,
        trade_pair: ["EURUSD", "EUR/USD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.84777,
        close_ms: 1718358094422,
        current_return: 0.9972637857302684,
        initial_entry_price: 0.84777,
        is_closed_position: true,
        miner_hotkey: "5Da5hqCMSVgeGWmzeEnNrime3JKfgTpQmh7dXsdMP58dgeBd",
        net_leverage: 0.0,
        open_ms: 1718308368192,
        orders: [
          {
            leverage: 0.5,
            order_type: "LONG",
            order_uuid: "1e666698-cafb-41c3-a49c-765bde07d69b",
            price: 0.84777,
            price_sources: [],
            processed_ms: 1718308365308,
          },
          {
            leverage: 0.01,
            order_type: "FLAT",
            order_uuid: "8f3e6f3d-d398-4058-a265-530058acdbf9",
            price: 0.8431306392570994,
            price_sources: [],
            processed_ms: 1718358094422,
          },
        ],
        position_type: "FLAT",
        position_uuid: "63b3d49b-9652-46f6-a28a-7b347086771f",
        return_at_close: 0.9972288814977679,
        trade_pair: ["NZDCAD", "NZD/CAD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 169.14049999999997,
        close_ms: 1718358850780,
        current_return: 1.0038207880430765,
        initial_entry_price: 169.14049999999997,
        is_closed_position: true,
        miner_hotkey: "5Da5hqCMSVgeGWmzeEnNrime3JKfgTpQmh7dXsdMP58dgeBd",
        net_leverage: 0.0,
        open_ms: 1718349478124,
        orders: [
          {
            leverage: -0.5,
            order_type: "SHORT",
            order_uuid: "7855bece-99ee-4515-8018-e5336e4d16c2",
            price: 169.14049999999997,
            price_sources: [],
            processed_ms: 1718349477966,
          },
          {
            leverage: 0.01,
            order_type: "FLAT",
            order_uuid: "24b8a3ef-d473-4eda-aa29-41c9df2b3ed1",
            price: 167.848,
            price_sources: [],
            processed_ms: 1718358850780,
          },
        ],
        position_type: "FLAT",
        position_uuid: "6ac12430-b0ef-406f-a771-a02291933752",
        return_at_close: 1.003785654315495,
        trade_pair: ["EURJPY", "EUR/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 158.1765,
        close_ms: 1718359000068,
        current_return: 1.003298530439098,
        initial_entry_price: 158.1765,
        is_closed_position: true,
        miner_hotkey: "5Da5hqCMSVgeGWmzeEnNrime3JKfgTpQmh7dXsdMP58dgeBd",
        net_leverage: 0.0,
        open_ms: 1718345876331,
        orders: [
          {
            leverage: -0.5,
            order_type: "SHORT",
            order_uuid: "2263678c-5a98-4ac6-9ade-00dd8a6dfec4",
            price: 158.1765,
            price_sources: [],
            processed_ms: 1718345876185,
          },
          {
            leverage: 0.01,
            order_type: "FLAT",
            order_uuid: "1cd8191e-6790-436f-bd65-6acb9e2a4640",
            price: 157.133,
            price_sources: [],
            processed_ms: 1718359000068,
          },
        ],
        position_type: "FLAT",
        position_uuid: "232e3e66-93e3-43bf-85a1-08691b77d04c",
        return_at_close: 1.0032634149905326,
        trade_pair: ["USDJPY", "USD/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 199.6295,
        close_ms: 1718373633053,
        current_return: 1.0013337207176294,
        initial_entry_price: 199.6295,
        is_closed_position: true,
        miner_hotkey: "5Da5hqCMSVgeGWmzeEnNrime3JKfgTpQmh7dXsdMP58dgeBd",
        net_leverage: 0.0,
        open_ms: 1718359136421,
        orders: [
          {
            leverage: -0.5,
            order_type: "SHORT",
            order_uuid: "9b32c67c-979a-44b5-b9e6-90c486fe6da9",
            price: 199.6295,
            price_sources: [],
            processed_ms: 1718359136080,
          },
          {
            leverage: 0.01,
            order_type: "FLAT",
            order_uuid: "08e28772-8f1f-4a60-b639-1ab82589aabb",
            price: 199.097,
            price_sources: [],
            processed_ms: 1718373633053,
          },
        ],
        position_type: "FLAT",
        position_uuid: "2bcbe342-17e6-4282-bf6c-fc33f571d1aa",
        return_at_close: 1.0012986740374044,
        trade_pair: ["GBPJPY", "GBP/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.64803,
        close_ms: 1718379886730,
        current_return: 1.000616237867817,
        initial_entry_price: 0.6491,
        is_closed_position: true,
        miner_hotkey: "5Da5hqCMSVgeGWmzeEnNrime3JKfgTpQmh7dXsdMP58dgeBd",
        net_leverage: 0.0,
        open_ms: 1718359041858,
        orders: [
          {
            leverage: -0.5,
            order_type: "SHORT",
            order_uuid: "f4a0b48b-13ae-4f3e-9abf-6f095d7ec120",
            price: 0.6491,
            price_sources: [],
            processed_ms: 1718359041752,
          },
          {
            leverage: -0.5,
            order_type: "SHORT",
            order_uuid: "958b0466-d7b0-455a-9b8c-c9c910414856",
            price: 0.64696,
            price_sources: [],
            processed_ms: 1718375243541,
          },
          {
            leverage: 0.5,
            order_type: "FLAT",
            order_uuid: "96f93f82-0fce-4287-8a04-93a452b3caea",
            price: 0.64763,
            price_sources: [],
            processed_ms: 1718379886730,
          },
        ],
        position_type: "FLAT",
        position_uuid: "a4f0ed4a-6e64-4580-ae76-945419996bb3",
        return_at_close: 1.0005461947311662,
        trade_pair: ["CADCHF", "CAD/CHF", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 114.02,
        close_ms: 1718603902181,
        current_return: 0.9977416242764428,
        initial_entry_price: 114.02,
        is_closed_position: true,
        miner_hotkey: "5Da5hqCMSVgeGWmzeEnNrime3JKfgTpQmh7dXsdMP58dgeBd",
        net_leverage: 0.0,
        open_ms: 1718360346658,
        orders: [
          {
            leverage: -0.5,
            order_type: "SHORT",
            order_uuid: "2059f31a-8f78-4ff6-b18d-58574ad083a4",
            price: 114.02,
            price_sources: [],
            processed_ms: 1718360346573,
          },
          {
            leverage: 0.01,
            order_type: "FLAT",
            order_uuid: "a57d32cb-3297-449a-bc6d-7cafe06ee29d",
            price: 114.535,
            price_sources: [],
            processed_ms: 1718603902181,
          },
        ],
        position_type: "FLAT",
        position_uuid: "564792a9-5514-4c90-bc8f-d5c84a653489",
        return_at_close: 0.9977067033195931,
        trade_pair: ["CADJPY", "CAD/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 103.696,
        close_ms: 1718603936416,
        current_return: 0.9989102761919457,
        initial_entry_price: 103.696,
        is_closed_position: true,
        miner_hotkey: "5Da5hqCMSVgeGWmzeEnNrime3JKfgTpQmh7dXsdMP58dgeBd",
        net_leverage: 0.0,
        open_ms: 1718358126886,
        orders: [
          {
            leverage: -0.5,
            order_type: "SHORT",
            order_uuid: "c642cbe0-18b6-415d-8e6a-6a59dad6500a",
            price: 103.696,
            price_sources: [],
            processed_ms: 1718358126705,
          },
          {
            leverage: 0.01,
            order_type: "FLAT",
            order_uuid: "4e6b1ef3-c3ef-4c80-8402-b7412fca8bf3",
            price: 103.922,
            price_sources: [],
            processed_ms: 1718603936416,
          },
        ],
        position_type: "FLAT",
        position_uuid: "e3af14fa-b32e-48dc-b955-864aed36d926",
        return_at_close: 0.998875314332279,
        trade_pair: ["AUDJPY", "AUD/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 1.75028,
        close_ms: 1718629697430,
        current_return: 1.001445483008433,
        initial_entry_price: 1.75028,
        is_closed_position: true,
        miner_hotkey: "5Da5hqCMSVgeGWmzeEnNrime3JKfgTpQmh7dXsdMP58dgeBd",
        net_leverage: 0.0,
        open_ms: 1718623674405,
        orders: [
          {
            leverage: 1.0,
            order_type: "LONG",
            order_uuid: "4f4d45dd-79c1-42e2-9f69-0d355940addd",
            price: 1.75028,
            price_sources: [],
            processed_ms: 1718623674175,
          },
          {
            leverage: 0.01,
            order_type: "FLAT",
            order_uuid: "d72c8779-02f2-4267-b64c-a6acdc1317b6",
            price: 1.75281,
            price_sources: [],
            processed_ms: 1718629697430,
          },
        ],
        position_type: "FLAT",
        position_uuid: "dd5d3f66-3e1f-46e1-be6a-5490ffc883a4",
        return_at_close: 1.0013753818246223,
        trade_pair: ["EURNZD", "EUR/NZD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.6117,
        close_ms: 1718629727368,
        current_return: 1.0004740886055257,
        initial_entry_price: 0.6117,
        is_closed_position: true,
        miner_hotkey: "5Da5hqCMSVgeGWmzeEnNrime3JKfgTpQmh7dXsdMP58dgeBd",
        net_leverage: 0.0,
        open_ms: 1718603967516,
        orders: [
          {
            leverage: -1.0,
            order_type: "SHORT",
            order_uuid: "6174c8a9-bacd-473d-a367-c520f83b7809",
            price: 0.6117,
            price_sources: [],
            processed_ms: 1718603967462,
          },
          {
            leverage: 0.01,
            order_type: "FLAT",
            order_uuid: "460db228-bac9-43e4-8340-bd7e83cf9ee5",
            price: 0.61141,
            price_sources: [],
            processed_ms: 1718629727368,
          },
        ],
        position_type: "FLAT",
        position_uuid: "d21fd7f1-fc4b-45bd-ae04-de3f1fee9b6b",
        return_at_close: 1.0004040554193232,
        trade_pair: ["NZDUSD", "NZD/USD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.64923,
        close_ms: 1718644236222,
        current_return: 1.000739337368883,
        initial_entry_price: 0.64923,
        is_closed_position: true,
        miner_hotkey: "5Da5hqCMSVgeGWmzeEnNrime3JKfgTpQmh7dXsdMP58dgeBd",
        net_leverage: 0.0,
        open_ms: 1718629983876,
        orders: [
          {
            leverage: -0.5,
            order_type: "SHORT",
            order_uuid: "3ed43cbe-f8a3-4ed5-8783-7ff43666b6ab",
            price: 0.64923,
            price_sources: [],
            processed_ms: 1718629981924,
          },
          {
            leverage: 0.01,
            order_type: "FLAT",
            order_uuid: "818212ec-a3d4-49b7-9285-433e61fd11e2",
            price: 0.64827,
            price_sources: [],
            processed_ms: 1718644236222,
          },
        ],
        position_type: "FLAT",
        position_uuid: "f124a4f9-9cee-4aaf-a35a-4c3d3c5d9f0c",
        return_at_close: 1.0007043114920753,
        trade_pair: ["CADCHF", "CAD/CHF", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 157.833,
        close_ms: 1718644268000,
        current_return: 1.0002692719519999,
        initial_entry_price: 157.833,
        is_closed_position: true,
        miner_hotkey: "5Da5hqCMSVgeGWmzeEnNrime3JKfgTpQmh7dXsdMP58dgeBd",
        net_leverage: 0.0,
        open_ms: 1718629758645,
        orders: [
          {
            leverage: -0.5,
            order_type: "SHORT",
            order_uuid: "bc927d83-86b7-421b-b523-77a91a6afdf7",
            price: 157.833,
            price_sources: [],
            processed_ms: 1718629758471,
          },
          {
            leverage: 0.01,
            order_type: "FLAT",
            order_uuid: "df8b2597-4d0c-49d3-9993-95add29f9efc",
            price: 157.748,
            price_sources: [],
            processed_ms: 1718644268000,
          },
        ],
        position_type: "FLAT",
        position_uuid: "a21b1c67-7640-4d9d-b563-8181b3b145df",
        return_at_close: 1.0002342625274816,
        trade_pair: ["USDJPY", "USD/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 1.078749392901156,
        close_ms: 1718689552082,
        current_return: 1.0015715453103178,
        initial_entry_price: 1.078749392901156,
        is_closed_position: true,
        miner_hotkey: "5Da5hqCMSVgeGWmzeEnNrime3JKfgTpQmh7dXsdMP58dgeBd",
        net_leverage: 0.0,
        open_ms: 1718644380362,
        orders: [
          {
            leverage: 0.5,
            order_type: "LONG",
            order_uuid: "3cb5fee8-8bda-45ae-830d-e790817fce1b",
            price: 1.078749392901156,
            price_sources: [],
            processed_ms: 1718644380285,
          },
          {
            leverage: 0.01,
            order_type: "FLAT",
            order_uuid: "fd96bd83-38ac-4707-8783-ce82fb58c2f2",
            price: 1.08214,
            price_sources: [],
            processed_ms: 1718689552082,
          },
        ],
        position_type: "FLAT",
        position_uuid: "07021c43-dae2-45c5-aeb1-62aa4c1b6894",
        return_at_close: 1.0015364903062318,
        trade_pair: ["AUDNZD", "AUD/NZD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 1.751,
        close_ms: 1718689634760,
        current_return: 1.0004940034266134,
        initial_entry_price: 1.751,
        is_closed_position: true,
        miner_hotkey: "5Da5hqCMSVgeGWmzeEnNrime3JKfgTpQmh7dXsdMP58dgeBd",
        net_leverage: 0.0,
        open_ms: 1718644338204,
        orders: [
          {
            leverage: 0.5,
            order_type: "LONG",
            order_uuid: "42e453a7-3006-4e60-9afe-459d7d1563f3",
            price: 1.751,
            price_sources: [],
            processed_ms: 1718644338091,
          },
          {
            leverage: 0.01,
            order_type: "FLAT",
            order_uuid: "55ada171-6d54-4444-8836-4349cf0615b1",
            price: 1.7527300000000001,
            price_sources: [],
            processed_ms: 1718689634760,
          },
        ],
        position_type: "FLAT",
        position_uuid: "56b89763-9509-43fd-a040-7b267d95055b",
        return_at_close: 1.0004589861364934,
        trade_pair: ["EURNZD", "EUR/NZD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.95431,
        close_ms: 1718708877664,
        current_return: 0.9983967473881653,
        initial_entry_price: 0.95431,
        is_closed_position: true,
        miner_hotkey: "5Da5hqCMSVgeGWmzeEnNrime3JKfgTpQmh7dXsdMP58dgeBd",
        net_leverage: 0.0,
        open_ms: 1718691256051,
        orders: [
          {
            leverage: 0.5,
            order_type: "LONG",
            order_uuid: "04a28f80-33e8-4f48-b371-64f9c2f59593",
            price: 0.95431,
            price_sources: [],
            processed_ms: 1718691255542,
          },
          {
            leverage: 0.01,
            order_type: "FLAT",
            order_uuid: "f7d33d54-fe28-4418-b7a1-6eaac5d048b8",
            price: 0.95125,
            price_sources: [],
            processed_ms: 1718708877664,
          },
        ],
        position_type: "FLAT",
        position_uuid: "38fb3996-9b69-49d6-bbb2-46cb39943feb",
        return_at_close: 0.9983618035020068,
        trade_pair: ["EURCHF", "EUR/CHF", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 158.122,
        close_ms: 1718718810771,
        current_return: 1.0013027915154122,
        initial_entry_price: 158.122,
        is_closed_position: true,
        miner_hotkey: "5Da5hqCMSVgeGWmzeEnNrime3JKfgTpQmh7dXsdMP58dgeBd",
        net_leverage: 0.0,
        open_ms: 1718696930422,
        orders: [
          {
            leverage: -0.5,
            order_type: "SHORT",
            order_uuid: "b224c693-4bff-4a2f-830f-d102492dc99a",
            price: 158.122,
            price_sources: [],
            processed_ms: 1718696929909,
          },
          {
            leverage: 0.01,
            order_type: "FLAT",
            order_uuid: "74055af9-6654-4c36-8621-25ba70dfb4eb",
            price: 157.71,
            price_sources: [],
            processed_ms: 1718718810771,
          },
        ],
        position_type: "FLAT",
        position_uuid: "f4e02a82-61eb-4328-99c3-2b9bcd4ea275",
        return_at_close: 1.0012677459177093,
        trade_pair: ["USDJPY", "USD/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 169.4,
        close_ms: 1718780061335,
        current_return: 1.0004958677685951,
        initial_entry_price: 169.4,
        is_closed_position: true,
        miner_hotkey: "5Da5hqCMSVgeGWmzeEnNrime3JKfgTpQmh7dXsdMP58dgeBd",
        net_leverage: 0.0,
        open_ms: 1718708936251,
        orders: [
          {
            leverage: -0.5,
            order_type: "SHORT",
            order_uuid: "ab3c1b93-22ff-4c22-bbe7-fbb328ca066b",
            price: 169.4,
            price_sources: [],
            processed_ms: 1718708935378,
          },
          {
            leverage: 0.01,
            order_type: "FLAT",
            order_uuid: "5f30e5fb-9b44-48bb-95e1-6e658ba327e5",
            price: 169.232,
            price_sources: [],
            processed_ms: 1718780061335,
          },
        ],
        position_type: "FLAT",
        position_uuid: "426cb328-b93c-489f-b805-c99b84c3b2d7",
        return_at_close: 1.0004608504132233,
        trade_pair: ["EURJPY", "EUR/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.88676,
        close_ms: 1718870752498,
        current_return: 1.0034444394497888,
        initial_entry_price: 0.88984,
        is_closed_position: true,
        miner_hotkey: "5Da5hqCMSVgeGWmzeEnNrime3JKfgTpQmh7dXsdMP58dgeBd",
        net_leverage: 0.0,
        open_ms: 1718691162217,
        orders: [
          {
            leverage: 0.5,
            order_type: "LONG",
            order_uuid: "30770910-d0bb-41b4-bd39-2d76db5798ab",
            price: 0.88984,
            price_sources: [],
            processed_ms: 1718691162094,
          },
          {
            leverage: 0.5,
            order_type: "LONG",
            order_uuid: "78c27ac8-5f21-4584-b752-2224f89bb746",
            price: 0.88368,
            price_sources: [],
            processed_ms: 1718864114357,
          },
          {
            leverage: 0.01,
            order_type: "FLAT",
            order_uuid: "0c783d63-c326-4c4c-a7a4-0f58aad2774c",
            price: 0.8898250000000001,
            price_sources: [],
            processed_ms: 1718870752498,
          },
        ],
        position_type: "FLAT",
        position_uuid: "27ffa76e-4f5d-457e-aee9-352e68977046",
        return_at_close: 1.0033741983390272,
        trade_pair: ["USDCHF", "USD/CHF", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 1.07326,
        close_ms: 1718870879917,
        current_return: 1.0009690103050521,
        initial_entry_price: 1.07326,
        is_closed_position: true,
        miner_hotkey: "5Da5hqCMSVgeGWmzeEnNrime3JKfgTpQmh7dXsdMP58dgeBd",
        net_leverage: 0.0,
        open_ms: 1718864072385,
        orders: [
          {
            leverage: -1.0,
            order_type: "SHORT",
            order_uuid: "c9f156d6-a2da-41f6-9012-ab1c23b5da78",
            price: 1.07326,
            price_sources: [],
            processed_ms: 1718864072242,
          },
          {
            leverage: 0.01,
            order_type: "FLAT",
            order_uuid: "5dbd5bcb-e012-4231-ad60-4504258a37c8",
            price: 1.07222,
            price_sources: [],
            processed_ms: 1718870879917,
          },
        ],
        position_type: "FLAT",
        position_uuid: "d5b0ee0d-07a2-4124-8661-aeab3ae1cde6",
        return_at_close: 1.0008989424743309,
        trade_pair: ["EURUSD", "EUR/USD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.8453200000000001,
        close_ms: 1718907798489,
        current_return: 0.9998314247858798,
        initial_entry_price: 0.8453200000000001,
        is_closed_position: true,
        miner_hotkey: "5Da5hqCMSVgeGWmzeEnNrime3JKfgTpQmh7dXsdMP58dgeBd",
        net_leverage: 0.0,
        open_ms: 1718884585729,
        orders: [
          {
            leverage: 0.5,
            order_type: "LONG",
            order_uuid: "8e651f00-c0e0-4413-bd2e-92c1abf57479",
            price: 0.8453200000000001,
            price_sources: [],
            processed_ms: 1718884585649,
          },
          {
            leverage: 0.01,
            order_type: "FLAT",
            order_uuid: "8a4bb851-1c47-472f-9d07-2b6bea888b51",
            price: 0.845035,
            price_sources: [],
            processed_ms: 1718907798489,
          },
        ],
        position_type: "FLAT",
        position_uuid: "29ca70d6-406c-4369-9d8d-2968c5401405",
        return_at_close: 0.9997964306860123,
        trade_pair: ["EURGBP", "EUR/GBP", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 170.109,
        close_ms: 1718958939008,
        current_return: 1.002604212593102,
        initial_entry_price: 170.109,
        is_closed_position: true,
        miner_hotkey: "5Da5hqCMSVgeGWmzeEnNrime3JKfgTpQmh7dXsdMP58dgeBd",
        net_leverage: 0.0,
        open_ms: 1718907829779,
        orders: [
          {
            leverage: -1.0,
            order_type: "SHORT",
            order_uuid: "c4c0697c-373d-4464-be51-efe7d3a92429",
            price: 170.109,
            price_sources: [],
            processed_ms: 1718907829525,
          },
          {
            leverage: 0.01,
            order_type: "FLAT",
            order_uuid: "5316a375-2e63-4795-8e9c-882e6d9cc729",
            price: 169.666,
            price_sources: [],
            processed_ms: 1718958939008,
          },
        ],
        position_type: "FLAT",
        position_uuid: "00559df7-6396-44ce-8c25-d9c6737065ac",
        return_at_close: 1.0025340302982206,
        trade_pair: ["EURJPY", "EUR/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 158.395125,
        close_ms: 1718980700680,
        current_return: 0.9871663569893018,
        initial_entry_price: 157.691,
        is_closed_position: true,
        miner_hotkey: "5Da5hqCMSVgeGWmzeEnNrime3JKfgTpQmh7dXsdMP58dgeBd",
        net_leverage: 0.0,
        open_ms: 1718780107900,
        orders: [
          {
            leverage: -0.5,
            order_type: "SHORT",
            order_uuid: "b0b3e80b-2da8-4264-a78c-d6665c01b9b7",
            price: 157.691,
            price_sources: [],
            processed_ms: 1718780107466,
          },
          {
            leverage: -0.5,
            order_type: "SHORT",
            order_uuid: "96be2438-e970-471e-aa7f-61a88dd3dc82",
            price: 158.3615,
            price_sources: [],
            processed_ms: 1718870841835,
          },
          {
            leverage: -1.0,
            order_type: "SHORT",
            order_uuid: "acbe0639-e1c2-4f86-9ec6-63fb6e1e9eee",
            price: 158.764,
            price_sources: [],
            processed_ms: 1718907750969,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "5ca02b90-df85-493a-8059-06a5f11dc7a1",
            price: 159.407,
            price_sources: [],
            processed_ms: 1718980700680,
          },
        ],
        position_type: "FLAT",
        position_uuid: "c356a9ff-bf0a-4094-a35c-1486b99d62d5",
        return_at_close: 0.9870281536993233,
        trade_pair: ["USDJPY", "USD/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 64423.68,
        close_ms: 1719068469646,
        current_return: 1.0006534476142934,
        initial_entry_price: 64423.68,
        is_closed_position: true,
        miner_hotkey: "5Da5hqCMSVgeGWmzeEnNrime3JKfgTpQmh7dXsdMP58dgeBd",
        net_leverage: 0.0,
        open_ms: 1719038434633,
        orders: [
          {
            leverage: -0.25,
            order_type: "SHORT",
            order_uuid: "4b40cacc-8375-45c6-ae6e-7949471c613a",
            price: 64423.68,
            price_sources: [],
            processed_ms: 1719038434327,
          },
          {
            leverage: 0.01,
            order_type: "FLAT",
            order_uuid: "28ad9db3-26bb-4fca-9316-7d03a8df7e57",
            price: 64255.29,
            price_sources: [],
            processed_ms: 1719068469646,
          },
        ],
        position_type: "FLAT",
        position_uuid: "37054e9f-e80c-4731-bdaf-a5482964384b",
        return_at_close: 1.0004032842523898,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 64359.82,
        close_ms: 1719159857873,
        current_return: 1.0011905331618391,
        initial_entry_price: 64359.82,
        is_closed_position: true,
        miner_hotkey: "5Da5hqCMSVgeGWmzeEnNrime3JKfgTpQmh7dXsdMP58dgeBd",
        net_leverage: 0.0,
        open_ms: 1719123222507,
        orders: [
          {
            leverage: -0.25,
            order_type: "SHORT",
            order_uuid: "b1d1be65-6a92-47d9-a0f1-29c19d58c5dc",
            price: 64359.82,
            price_sources: [],
            processed_ms: 1719123221799,
          },
          {
            leverage: 0.25,
            order_type: "FLAT",
            order_uuid: "8a69de55-377c-42bf-8254-acd053a262a1",
            price: 64053.33,
            price_sources: [],
            processed_ms: 1719159857873,
          },
        ],
        position_type: "FLAT",
        position_uuid: "b1ec4961-9ec7-49b6-a549-fb0e0d984c18",
        return_at_close: 1.0009402355285486,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 62233.25,
        close_ms: 1719214535452,
        current_return: 0.9993101758304443,
        initial_entry_price: 62233.25,
        is_closed_position: true,
        miner_hotkey: "5Da5hqCMSVgeGWmzeEnNrime3JKfgTpQmh7dXsdMP58dgeBd",
        net_leverage: 0.0,
        open_ms: 1719212700101,
        orders: [
          {
            leverage: -0.25,
            order_type: "SHORT",
            order_uuid: "8b5238f4-52c3-4ba7-bd36-ae467b0d1d84",
            price: 62233.25,
            price_sources: [],
            processed_ms: 1719212699779,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "840a407d-93ac-4d0d-9e13-eab2733117b6",
            price: 62404.97,
            price_sources: [],
            processed_ms: 1719214535452,
          },
        ],
        position_type: "FLAT",
        position_uuid: "a51b3f65-0aa6-43c3-a506-2e10fe863442",
        return_at_close: 0.9990603482864867,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 3379.65,
        close_ms: 1719224601828,
        current_return: 0.9945955942183362,
        initial_entry_price: 3379.65,
        is_closed_position: true,
        miner_hotkey: "5Da5hqCMSVgeGWmzeEnNrime3JKfgTpQmh7dXsdMP58dgeBd",
        net_leverage: 0.0,
        open_ms: 1719214766094,
        orders: [
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "7eea7353-6a9f-44b6-b68e-197f787355ff",
            price: 3379.65,
            price_sources: [],
            processed_ms: 1719214763467,
          },
          {
            leverage: 0.01,
            order_type: "FLAT",
            order_uuid: "269ab58f-3fcc-4324-91af-1cb333f84968",
            price: 3306.59,
            price_sources: [],
            processed_ms: 1719224601828,
          },
        ],
        position_type: "FLAT",
        position_uuid: "79338382-b2e1-4789-b11f-05c74cd78c28",
        return_at_close: 0.9943469453197816,
        trade_pair: ["ETHUSD", "ETH/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 97.459,
        close_ms: 1719239028805,
        current_return: 0.9959624047035165,
        initial_entry_price: 97.459,
        is_closed_position: true,
        miner_hotkey: "5Da5hqCMSVgeGWmzeEnNrime3JKfgTpQmh7dXsdMP58dgeBd",
        net_leverage: 0.0,
        open_ms: 1718981601046,
        orders: [
          {
            leverage: -1.0,
            order_type: "SHORT",
            order_uuid: "f1806392-ada8-4a16-95a8-9abf5896c53a",
            price: 97.459,
            price_sources: [],
            processed_ms: 1718981601015,
          },
          {
            leverage: 1.0,
            order_type: "LONG",
            order_uuid: "32c6a79d-1979-4b4b-9e90-fbbce222e634",
            price: 97.85249999999999,
            price_sources: [],
            processed_ms: 1719239028805,
          },
        ],
        position_type: "FLAT",
        position_uuid: "4274c975-c479-4293-9f17-22e19d284b57",
        return_at_close: 0.9958926873351872,
        trade_pair: ["NZDJPY", "NZD/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.8928400000000001,
        close_ms: 1719295270562,
        current_return: 1.0004228081179158,
        initial_entry_price: 0.8928400000000001,
        is_closed_position: true,
        miner_hotkey: "5Da5hqCMSVgeGWmzeEnNrime3JKfgTpQmh7dXsdMP58dgeBd",
        net_leverage: 0.0,
        open_ms: 1719239076881,
        orders: [
          {
            leverage: -0.5,
            order_type: "SHORT",
            order_uuid: "df888996-ceeb-4ed1-9108-fa1abd884c05",
            price: 0.8928400000000001,
            price_sources: [],
            processed_ms: 1719239076764,
          },
          {
            leverage: 0.01,
            order_type: "FLAT",
            order_uuid: "415bd71c-f45b-46c3-b39e-e044dacd024c",
            price: 0.892085,
            price_sources: [],
            processed_ms: 1719295270562,
          },
        ],
        position_type: "FLAT",
        position_uuid: "608ea61e-c50d-4a44-b69a-2c7f09eb6b66",
        return_at_close: 1.0003877933196317,
        trade_pair: ["USDCHF", "USD/CHF", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.83672,
        close_ms: 1719318889530,
        current_return: 0.9936103748172965,
        initial_entry_price: 0.83621,
        is_closed_position: true,
        miner_hotkey: "5Da5hqCMSVgeGWmzeEnNrime3JKfgTpQmh7dXsdMP58dgeBd",
        net_leverage: 0.0,
        open_ms: 1719300894358,
        orders: [
          {
            leverage: 0.5,
            order_type: "LONG",
            order_uuid: "d1fa9667-4a83-4443-a8b5-dd07ccae8ac9",
            price: 0.83621,
            price_sources: [],
            processed_ms: 1719300893684,
          },
          {
            leverage: 3.0,
            order_type: "LONG",
            order_uuid: "a4257ed1-6e3b-406e-bc9a-988c3c10e018",
            price: 0.836805,
            price_sources: [],
            processed_ms: 1719316384544,
          },
          {
            leverage: 3.0,
            order_type: "FLAT",
            order_uuid: "396a9e8d-5439-4a7b-a65e-d833abd0aafa",
            price: 0.8351934090074205,
            price_sources: [],
            processed_ms: 1719318889530,
          },
        ],
        position_type: "FLAT",
        position_uuid: "f8226dca-d839-4281-8429-9900f09e1eaa",
        return_at_close: 0.9933669402754662,
        trade_pair: ["NZDCAD", "NZD/CAD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.909305540009328,
        close_ms: 1719318941847,
        current_return: 0.9991116627259791,
        initial_entry_price: 0.909305540009328,
        is_closed_position: true,
        miner_hotkey: "5Da5hqCMSVgeGWmzeEnNrime3JKfgTpQmh7dXsdMP58dgeBd",
        net_leverage: 0.0,
        open_ms: 1719295317402,
        orders: [
          {
            leverage: 0.5,
            order_type: "LONG",
            order_uuid: "fc739662-3dd9-4e80-9597-e14174b45952",
            price: 0.909305540009328,
            price_sources: [],
            processed_ms: 1719295317321,
          },
          {
            leverage: 3.0,
            order_type: "FLAT",
            order_uuid: "adccf802-ed10-48f3-ac9e-34af02bbde0a",
            price: 0.90769,
            price_sources: [],
            processed_ms: 1719318941847,
          },
        ],
        position_type: "FLAT",
        position_uuid: "c6e1f457-3ea9-4523-9730-4ea22096e5ea",
        return_at_close: 0.9990766938177836,
        trade_pair: ["AUDCAD", "AUD/CAD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 60100.0,
        close_ms: 1719322048117,
        current_return: 0.9953481697171381,
        initial_entry_price: 60100.0,
        is_closed_position: true,
        miner_hotkey: "5Da5hqCMSVgeGWmzeEnNrime3JKfgTpQmh7dXsdMP58dgeBd",
        net_leverage: 0.0,
        open_ms: 1719252520531,
        orders: [
          {
            leverage: -0.25,
            order_type: "SHORT",
            order_uuid: "cc235671-3e5d-447e-9df8-9ad84ae60347",
            price: 60100.0,
            price_sources: [],
            processed_ms: 1719252518512,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "a6da5674-a2b7-4c65-88c2-9cebbb7eedfe",
            price: 61218.3,
            price_sources: [],
            processed_ms: 1719322048117,
          },
        ],
        position_type: "FLAT",
        position_uuid: "ea4794d9-7243-43d1-9ca6-364b32c2bdf1",
        return_at_close: 0.9950993326747088,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 1.070885,
        close_ms: 1719380949539,
        current_return: 1.0003315015151022,
        initial_entry_price: 1.070885,
        is_closed_position: true,
        miner_hotkey: "5Da5hqCMSVgeGWmzeEnNrime3JKfgTpQmh7dXsdMP58dgeBd",
        net_leverage: 0.0,
        open_ms: 1719319110652,
        orders: [
          {
            leverage: -1.0,
            order_type: "SHORT",
            order_uuid: "1dcf9e04-62b8-4cd8-96e8-e7cb7926dddc",
            price: 1.070885,
            price_sources: [],
            processed_ms: 1719319110268,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "8d10aadb-13e5-49fb-bac4-b056141313ec",
            price: 1.07053,
            price_sources: [],
            processed_ms: 1719380949539,
          },
        ],
        position_type: "FLAT",
        position_uuid: "7e28f671-94b2-4f44-891e-14e9c1ccbdda",
        return_at_close: 1.0002614783099961,
        trade_pair: ["EURUSD", "EUR/USD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 177.146,
        close_ms: 1719956797315,
        current_return: 0.9957276301446877,
        initial_entry_price: 177.146,
        is_closed_position: true,
        miner_hotkey: "5Da5hqCMSVgeGWmzeEnNrime3JKfgTpQmh7dXsdMP58dgeBd",
        net_leverage: 0.0,
        open_ms: 1718645219372,
        orders: [
          {
            leverage: -0.5,
            order_type: "SHORT",
            order_uuid: "14e3c6e7-ae51-411f-b060-e7f2c6ce71c1",
            price: 177.146,
            price_sources: [],
            processed_ms: 1718645219148,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "3da2d89a-fa25-4611-8eeb-5c8c5b131a5d",
            price: 178.6596664607783,
            price_sources: [],
            processed_ms: 1719956797315,
          },
        ],
        position_type: "FLAT",
        position_uuid: "9b5dee87-d83e-430e-b010-0705e271197f",
        return_at_close: 0.9956927796776326,
        trade_pair: ["CHFJPY", "CHF/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 1.46105,
        close_ms: 1719956827964,
        current_return: 0.9942027993566271,
        initial_entry_price: 1.46105,
        is_closed_position: true,
        miner_hotkey: "5Da5hqCMSVgeGWmzeEnNrime3JKfgTpQmh7dXsdMP58dgeBd",
        net_leverage: 0.0,
        open_ms: 1719322000628,
        orders: [
          {
            leverage: -1.0,
            order_type: "SHORT",
            order_uuid: "38d6723f-b1ac-45fa-9f62-0cf989406615",
            price: 1.46105,
            price_sources: [],
            processed_ms: 1719322000390,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "e22098ae-f222-40b6-b39a-41caebb49f9a",
            price: 1.46952,
            price_sources: [],
            processed_ms: 1719956827964,
          },
        ],
        position_type: "FLAT",
        position_uuid: "6b9be960-940a-4f91-a295-00b4f91534f9",
        return_at_close: 0.9941332051606722,
        trade_pair: ["EURCAD", "EUR/CAD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 201.39249999999998,
        close_ms: 1719985800335,
        current_return: 0.9810122025398165,
        initial_entry_price: 201.39249999999998,
        is_closed_position: true,
        miner_hotkey: "5Da5hqCMSVgeGWmzeEnNrime3JKfgTpQmh7dXsdMP58dgeBd",
        net_leverage: 0.0,
        open_ms: 1718981640995,
        orders: [
          {
            leverage: -1.0,
            order_type: "SHORT",
            order_uuid: "ef98ec0c-6b23-4cb4-a4dd-79b22efdc70b",
            price: 201.39249999999998,
            price_sources: [],
            processed_ms: 1718981640882,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "5c649e06-79eb-4272-8684-d1e9cc501361",
            price: 205.2165,
            price_sources: [],
            processed_ms: 1719985800335,
          },
        ],
        position_type: "FLAT",
        position_uuid: "0be30904-f31f-436d-bf29-8c569a69482c",
        return_at_close: 0.9809435316856386,
        trade_pair: ["GBPJPY", "GBP/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 60904.1,
        close_ms: 1720077929882,
        current_return: 1.035516492321535,
        initial_entry_price: 60904.1,
        is_closed_position: true,
        miner_hotkey: "5Da5hqCMSVgeGWmzeEnNrime3JKfgTpQmh7dXsdMP58dgeBd",
        net_leverage: 0.0,
        open_ms: 1719985851399,
        orders: [
          {
            leverage: -1.0,
            order_type: "SHORT",
            order_uuid: "909e01d2-08fb-4c94-bb58-e60e974297f2",
            price: 60904.1,
            price_sources: [],
            processed_ms: 1719985850210,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "c57abe6b-c7c4-4fb2-9e2f-1b53332120ec",
            price: 58741.0,
            price_sources: [
              {
                close: 58741.0,
                high: 58741.0,
                lag_ms: 117,
                low: 58609.97,
                open: 58741.0,
                source: "Polygon_ws",
                start_ms: 1720077929999,
                timespan_ms: 0,
                volume: 0.13054017,
                vwap: 58612.6127,
                websocket: true,
              },
              {
                close: 58568.6,
                high: 58568.6,
                lag_ms: 2118,
                low: 58568.6,
                open: 58568.6,
                source: "TwelveData_ws",
                start_ms: 1720077932000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1720077929882,
          },
        ],
        position_type: "FLAT",
        position_uuid: "5016dc7b-7802-4e13-9ee4-7c720d52b1bb",
        return_at_close: 1.0344809758292135,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 58446.37,
        close_ms: 1720111196919,
        current_return: 1.0076374289797638,
        initial_entry_price: 58446.37,
        is_closed_position: true,
        miner_hotkey: "5Da5hqCMSVgeGWmzeEnNrime3JKfgTpQmh7dXsdMP58dgeBd",
        net_leverage: 0.0,
        open_ms: 1720079109898,
        orders: [
          {
            leverage: -1.0,
            order_type: "SHORT",
            order_uuid: "7a204bea-1ab5-4c90-9872-c8fa3084e731",
            price: 58446.37,
            price_sources: [
              {
                close: 58446.37,
                high: 58446.37,
                lag_ms: 32,
                low: 58442.19,
                open: 58446.37,
                source: "Polygon_ws",
                start_ms: 1720079108000,
                timespan_ms: 0,
                volume: 0.4847559,
                vwap: 58443.7905,
                websocket: true,
              },
              {
                close: 58466.3,
                high: 58466.3,
                lag_ms: 2032,
                low: 58466.3,
                open: 58466.3,
                source: "TwelveData_ws",
                start_ms: 1720079106000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
              {
                close: 58581.0,
                high: 58581.0,
                lag_ms: 2033,
                low: 58444.0,
                open: 58444.0,
                source: "Polygon_rest",
                start_ms: 1720079105000,
                timespan_ms: 1000,
                volume: 0.01842596,
                vwap: 58567.7211,
                websocket: false,
              },
              {
                close: 58471.16,
                high: 58508.43,
                lag_ms: 68033,
                low: 58466.1,
                open: 58504.69,
                source: "TwelveData_rest",
                start_ms: 1720078980000,
                timespan_ms: 60000,
                volume: null,
                vwap: null,
                websocket: false,
              },
            ],
            processed_ms: 1720079108032,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "5ac80d94-9100-44fb-8345-5d3699228696",
            price: 57999.99,
            price_sources: [
              {
                close: 57999.99,
                high: 58000.0,
                lag_ms: 80,
                low: 57998.82,
                open: 57999.99,
                source: "Polygon_ws",
                start_ms: 1720111196999,
                timespan_ms: 0,
                volume: 0.4311802,
                vwap: 57999.1061,
                websocket: true,
              },
              {
                close: 58075.0,
                high: 58079.0,
                lag_ms: 2920,
                low: 57978.71,
                open: 57978.71,
                source: "Polygon_rest",
                start_ms: 1720111193000,
                timespan_ms: 1000,
                volume: 0.53200571,
                vwap: 58073.6525,
                websocket: false,
              },
              {
                close: 57960.8,
                high: 57960.8,
                lag_ms: 9919,
                low: 57960.8,
                open: 57960.8,
                source: "TwelveData_ws",
                start_ms: 1720111187000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
              {
                close: 57929.93,
                high: 57933.0,
                lag_ms: 56920,
                low: 57847.82,
                open: 57870.11,
                source: "TwelveData_rest",
                start_ms: 1720111080000,
                timespan_ms: 60000,
                volume: null,
                vwap: null,
                websocket: false,
              },
            ],
            processed_ms: 1720111196919,
          },
        ],
        position_type: "FLAT",
        position_uuid: "5561d4df-40a9-4382-a474-146a36d7aabf",
        return_at_close: 1.006629791550784,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 161.287,
        close_ms: 1720111227735,
        current_return: 1.0016740344851107,
        initial_entry_price: 161.287,
        is_closed_position: true,
        miner_hotkey: "5Da5hqCMSVgeGWmzeEnNrime3JKfgTpQmh7dXsdMP58dgeBd",
        net_leverage: 0.0,
        open_ms: 1720079400022,
        orders: [
          {
            leverage: -2.0,
            order_type: "SHORT",
            order_uuid: "54b98c8d-29d4-4534-979f-684d1205873f",
            price: 161.287,
            price_sources: [
              {
                close: 161.287,
                high: 161.287,
                lag_ms: 452,
                low: 161.287,
                open: 161.287,
                source: "Polygon_ws",
                start_ms: 1720079400000,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 161.28,
                websocket: true,
              },
              {
                close: 161.288,
                high: 161.288,
                lag_ms: 452,
                low: 161.288,
                open: 161.288,
                source: "TwelveData_ws",
                start_ms: 1720079400000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1720079399548,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "0ed016b8-97c4-4542-889a-fa794fe2fb71",
            price: 161.152,
            price_sources: [
              {
                close: 161.152,
                high: 161.152,
                lag_ms: 735,
                low: 161.152,
                open: 161.152,
                source: "TwelveData_ws",
                start_ms: 1720111227000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
              {
                close: 161.152,
                high: 161.152,
                lag_ms: 736,
                low: 161.152,
                open: 161.152,
                source: "Polygon_ws",
                start_ms: 1720111226999,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 161.152,
                websocket: true,
              },
            ],
            processed_ms: 1720111227735,
          },
        ],
        position_type: "FLAT",
        position_uuid: "01927bae-ae82-4f0f-be50-38ad04389bd7",
        return_at_close: 1.0015338001202827,
        trade_pair: ["USDJPY", "USD/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 3131.55,
        close_ms: 1720619216125,
        current_return: 0.9918570675863391,
        initial_entry_price: 3131.55,
        is_closed_position: true,
        miner_hotkey: "5Da5hqCMSVgeGWmzeEnNrime3JKfgTpQmh7dXsdMP58dgeBd",
        net_leverage: 0.0,
        open_ms: 1720111302053,
        orders: [
          {
            leverage: 1.0,
            order_type: "LONG",
            order_uuid: "1d89c3c5-a3eb-43a7-ba70-b10d9d5ebf79",
            price: 3131.55,
            price_sources: [
              {
                close: 3131.55,
                high: 3131.55,
                lag_ms: 103,
                low: 3131.06,
                open: 3131.55,
                source: "Polygon_ws",
                start_ms: 1720111301000,
                timespan_ms: 0,
                volume: 13.07630932,
                vwap: 3131.2182,
                websocket: true,
              },
              {
                close: 3133.95,
                high: 3133.95,
                lag_ms: 103,
                low: 3133.95,
                open: 3133.95,
                source: "TwelveData_ws",
                start_ms: 1720111301000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
              {
                close: 3131.43,
                high: 3131.43,
                lag_ms: 3104,
                low: 3131.43,
                open: 3131.43,
                source: "Polygon_rest",
                start_ms: 1720111297000,
                timespan_ms: 1000,
                volume: 0.0288895,
                vwap: 3131.43,
                websocket: false,
              },
              {
                close: 3116.21997,
                high: 3116.21997,
                lag_ms: 401104,
                low: 3115.06006,
                open: 3115.8501,
                source: "TwelveData_rest",
                start_ms: 1720110840000,
                timespan_ms: 60000,
                volume: null,
                vwap: null,
                websocket: false,
              },
            ],
            processed_ms: 1720111301103,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "cc2c0ca5-beec-4644-998c-eaa129d2904e",
            price: 3106.05,
            price_sources: [
              {
                close: 3106.05,
                high: 3112.2,
                lag_ms: 125,
                low: 3103.47,
                open: 3106.05,
                source: "Polygon_ws",
                start_ms: 1720619216000,
                timespan_ms: 0,
                volume: 55.87920716,
                vwap: 3105.9436,
                websocket: true,
              },
              {
                close: 3106.23,
                high: 3106.23,
                lag_ms: 875,
                low: 3106.23,
                open: 3106.23,
                source: "TwelveData_ws",
                start_ms: 1720619217000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
              {
                close: 3105.87,
                high: 3106.17,
                lag_ms: 2126,
                low: 3105.31,
                open: 3106.17,
                source: "Polygon_rest",
                start_ms: 1720619213000,
                timespan_ms: 1000,
                volume: 23.04352229,
                vwap: 3105.4667,
                websocket: false,
              },
              {
                close: 3098.43994,
                high: 3103.43994,
                lag_ms: 176126,
                low: 3096.94995,
                open: 3103.43994,
                source: "TwelveData_rest",
                start_ms: 1720618980000,
                timespan_ms: 60000,
                volume: null,
                vwap: null,
                websocket: false,
              },
            ],
            processed_ms: 1720619216125,
          },
        ],
        position_type: "FLAT",
        position_uuid: "a2c54e61-4ed7-4e39-843c-f4779dcdd10a",
        return_at_close: 0.9908652105187528,
        trade_pair: ["ETHUSD", "ETH/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 3103.71,
        close_ms: 0,
        current_return: 0.9892902365233865,
        initial_entry_price: 3103.71,
        is_closed_position: false,
        miner_hotkey: "5Da5hqCMSVgeGWmzeEnNrime3JKfgTpQmh7dXsdMP58dgeBd",
        net_leverage: -1.0,
        open_ms: 1720619239073,
        orders: [
          {
            leverage: -1.0,
            order_type: "SHORT",
            order_uuid: "cc6fb896-c3a7-4432-90ed-7c55f4ad50c8",
            price: 3103.71,
            price_sources: [
              {
                close: 3103.71,
                high: 3104.05,
                lag_ms: 197,
                low: 3103.57,
                open: 3103.71,
                source: "Polygon_ws",
                start_ms: 1720619239000,
                timespan_ms: 0,
                volume: 0.17686258,
                vwap: 3103.9771,
                websocket: true,
              },
              {
                close: 3103.75,
                high: 3103.75,
                lag_ms: 2804,
                low: 3103.75,
                open: 3103.75,
                source: "Polygon_rest",
                start_ms: 1720619235000,
                timespan_ms: 1000,
                volume: 0.012,
                vwap: 3103.75,
                websocket: false,
              },
              {
                close: 3103.83,
                high: 3103.83,
                lag_ms: 3197,
                low: 3103.83,
                open: 3103.83,
                source: "TwelveData_ws",
                start_ms: 1720619242000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
              {
                close: 3098.43994,
                high: 3103.43994,
                lag_ms: 198804,
                low: 3096.94995,
                open: 3103.43994,
                source: "TwelveData_rest",
                start_ms: 1720618980000,
                timespan_ms: 60000,
                volume: null,
                vwap: null,
                websocket: false,
              },
            ],
            processed_ms: 1720619238803,
          },
        ],
        position_type: "SHORT",
        position_uuid: "3eea71fc-890d-4370-bc00-eb1d99f9edfe",
        return_at_close: 0.9887955914051249,
        trade_pair: ["ETHUSD", "ETH/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 1.77669,
        close_ms: 0,
        current_return: 1.0014690238589736,
        initial_entry_price: 1.77669,
        is_closed_position: false,
        miner_hotkey: "5Da5hqCMSVgeGWmzeEnNrime3JKfgTpQmh7dXsdMP58dgeBd",
        net_leverage: 1.0,
        open_ms: 1720591686360,
        orders: [
          {
            leverage: 1.0,
            order_type: "LONG",
            order_uuid: "151a7d70-03ab-4e32-b237-42db531fb135",
            price: 1.77669,
            price_sources: [
              {
                close: 1.77669,
                high: 1.77669,
                lag_ms: 355,
                low: 1.77669,
                open: 1.77669,
                source: "Polygon_ws",
                start_ms: 1720591686000,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 1.77669,
                websocket: true,
              },
              {
                close: 1.77673,
                high: 1.77673,
                lag_ms: 645,
                low: 1.77673,
                open: 1.77673,
                source: "TwelveData_ws",
                start_ms: 1720591687000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1720591686355,
          },
        ],
        position_type: "LONG",
        position_uuid: "835dd583-880c-4897-96ed-e2453215361a",
        return_at_close: 1.0014339724431385,
        trade_pair: ["EURNZD", "EUR/NZD", 7e-5, 0.001, 500],
      },
    ],
    thirty_day_returns: 0.9782898062653924,
  },
  "5DaHdgTLPrGCdiNMosKq9GEpDmA6pPaMvNopXtnG28AtYghm": {
    all_time_returns: 1.070705295053582,
    n_positions: 12,
    percentage_profitable: 1.0,
    positions: [
      {
        average_entry_price: 114.6901,
        close_ms: 1714485640393,
        current_return: 1.000191065028385,
        initial_entry_price: 114.673,
        is_closed_position: true,
        miner_hotkey: "5DaHdgTLPrGCdiNMosKq9GEpDmA6pPaMvNopXtnG28AtYghm",
        net_leverage: 0.0,
        open_ms: 1714470731949,
        orders: [
          {
            leverage: -0.01,
            order_type: "SHORT",
            order_uuid: "702dff05-0d9e-4bc8-b7e8-32c8e2df45ba",
            price: 114.673,
            price_sources: [],
            processed_ms: 1714470731948,
          },
          {
            leverage: -0.09,
            order_type: "SHORT",
            order_uuid: "bbac1eaf-3462-45cb-a796-a54c346f799f",
            price: 114.692,
            price_sources: [],
            processed_ms: 1714482552470,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "d90ed40e-12ca-4326-8d68-de18ac444d76",
            price: 114.471,
            price_sources: [],
            processed_ms: 1714485640393,
          },
        ],
        position_type: "FLAT",
        position_uuid: "964515da-d74f-48e4-ab1f-7588f6fbdfe6",
        return_at_close: 1.0001840636909298,
        trade_pair: ["CADJPY", "CAD/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.5634200000000162,
        close_ms: 1715014765728,
        current_return: 1.0001496507698084,
        initial_entry_price: 0.66575,
        is_closed_position: true,
        miner_hotkey: "5DaHdgTLPrGCdiNMosKq9GEpDmA6pPaMvNopXtnG28AtYghm",
        net_leverage: 0.0,
        open_ms: 1714470709599,
        orders: [
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "ab3c7fcc-58ef-4ecb-96ce-57179f897a51",
            price: 0.66575,
            price_sources: [],
            processed_ms: 1714470709599,
          },
          {
            leverage: 0.09,
            order_type: "LONG",
            order_uuid: "b073ec5b-1cbe-4708-9374-071d11207586",
            price: 0.6659,
            price_sources: [],
            processed_ms: 1714482533008,
          },
          {
            leverage: -0.099,
            order_type: "SHORT",
            order_uuid: "c3a7ffb0-d3fc-45aa-8eca-9ed2d0a0ca11",
            price: 0.66692,
            price_sources: [],
            processed_ms: 1714491457183,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "3a74fe4d-9b33-4727-8b54-c98ba451bde9",
            price: 0.66305,
            price_sources: [],
            processed_ms: 1715014765728,
          },
        ],
        position_type: "FLAT",
        position_uuid: "b79b21a2-79b7-4c6a-a1ec-fd5e509e84c6",
        return_at_close: 1.000142649722253,
        trade_pair: ["CADCHF", "CAD/CHF", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 122.16658286797599,
        close_ms: 1715273608695,
        current_return: 1.0005747706307337,
        initial_entry_price: 172.171,
        is_closed_position: true,
        miner_hotkey: "5DaHdgTLPrGCdiNMosKq9GEpDmA6pPaMvNopXtnG28AtYghm",
        net_leverage: 0.0,
        open_ms: 1714470751972,
        orders: [
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "8aeb9152-0f45-4031-abf1-ea9dfba68083",
            price: 172.171,
            price_sources: [],
            processed_ms: 1714470751971,
          },
          {
            leverage: 0.09,
            order_type: "LONG",
            order_uuid: "65463c70-b300-4445-9bcd-95e870ddb8b0",
            price: 172.004,
            price_sources: [],
            processed_ms: 1714482572418,
          },
          {
            leverage: 0.1,
            order_type: "LONG",
            order_uuid: "769028be-afeb-47df-a1c8-7602edeb7521",
            price: 169.7443516573595,
            price_sources: [],
            processed_ms: 1714650240044,
          },
          {
            leverage: 0.15,
            order_type: "LONG",
            order_uuid: "a0c8ee67-d3b8-4d59-a4ff-51858f8780a8",
            price: 168.682,
            price_sources: [],
            processed_ms: 1714670422703,
          },
          {
            leverage: -0.348,
            order_type: "SHORT",
            order_uuid: "448eaf99-b3ea-4916-9b34-df1c40ddae29",
            price: 170.214,
            price_sources: [],
            processed_ms: 1715063444183,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "32dc40f1-a758-42a5-8893-299dec4d35e6",
            price: 171.646,
            price_sources: [],
            processed_ms: 1715273608695,
          },
        ],
        position_type: "FLAT",
        position_uuid: "84785c19-e02f-4800-a380-c0a9c48af802",
        return_at_close: 1.0005502565488533,
        trade_pair: ["CHFJPY", "CHF/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 1.25221,
        close_ms: 1715305436626,
        current_return: 1.0002555481907986,
        initial_entry_price: 1.25221,
        is_closed_position: true,
        miner_hotkey: "5DaHdgTLPrGCdiNMosKq9GEpDmA6pPaMvNopXtnG28AtYghm",
        net_leverage: 0.0,
        open_ms: 1715305232567,
        orders: [
          {
            leverage: -1.0,
            order_type: "SHORT",
            order_uuid: "282ec111-8940-45f3-8360-f0c7bffbe7b0",
            price: 1.25221,
            price_sources: [],
            processed_ms: 1715305232363,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "72fd6328-744f-48e8-901c-d3d9b340a3bd",
            price: 1.25189,
            price_sources: [],
            processed_ms: 1715305436626,
          },
        ],
        position_type: "FLAT",
        position_uuid: "987737d0-52e1-4df2-a4ac-c240d007a865",
        return_at_close: 1.0001855303024252,
        trade_pair: ["GBPUSD", "GBP/USD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 171.612,
        close_ms: 1715305683734,
        current_return: 1.0003787613919772,
        initial_entry_price: 171.612,
        is_closed_position: true,
        miner_hotkey: "5DaHdgTLPrGCdiNMosKq9GEpDmA6pPaMvNopXtnG28AtYghm",
        net_leverage: 0.0,
        open_ms: 1715305290915,
        orders: [
          {
            leverage: 1.0,
            order_type: "LONG",
            order_uuid: "d8f569f1-5941-4aa2-87e6-437e9155deed",
            price: 171.612,
            price_sources: [],
            processed_ms: 1715305290740,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "c04f0e9f-c4e1-41c9-8b0d-5478b7b38390",
            price: 171.677,
            price_sources: [],
            processed_ms: 1715305683734,
          },
        ],
        position_type: "FLAT",
        position_uuid: "c8e02fb0-2625-452d-9f15-c12bddb14d24",
        return_at_close: 1.0003087348786799,
        trade_pair: ["CHFJPY", "CHF/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 155.584,
        close_ms: 1715306182445,
        current_return: 1.0002956602221307,
        initial_entry_price: 155.584,
        is_closed_position: true,
        miner_hotkey: "5DaHdgTLPrGCdiNMosKq9GEpDmA6pPaMvNopXtnG28AtYghm",
        net_leverage: 0.0,
        open_ms: 1715305268719,
        orders: [
          {
            leverage: 1.0,
            order_type: "LONG",
            order_uuid: "83bd7720-1e23-484d-96ad-4d25e5195fb9",
            price: 155.584,
            price_sources: [],
            processed_ms: 1715305266471,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "112d6a32-9c77-422f-bbbc-d63bd07b7029",
            price: 155.63,
            price_sources: [],
            processed_ms: 1715306182445,
          },
        ],
        position_type: "FLAT",
        position_uuid: "a6706855-ef6b-4f66-bc61-b9e2d25869b2",
        return_at_close: 1.000225639525915,
        trade_pair: ["USDJPY", "USD/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.9769,
        close_ms: 1715306240567,
        current_return: 1.0001023646227865,
        initial_entry_price: 0.9769,
        is_closed_position: true,
        miner_hotkey: "5DaHdgTLPrGCdiNMosKq9GEpDmA6pPaMvNopXtnG28AtYghm",
        net_leverage: 0.0,
        open_ms: 1715305179660,
        orders: [
          {
            leverage: 1.0,
            order_type: "LONG",
            order_uuid: "49f69157-1a2a-4ff4-852c-3b5758e468d7",
            price: 0.9769,
            price_sources: [],
            processed_ms: 1715305179442,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "4075eebf-6c87-47ee-8c5a-8835f2831326",
            price: 0.977,
            price_sources: [],
            processed_ms: 1715306240567,
          },
        ],
        position_type: "FLAT",
        position_uuid: "9b59513f-2984-4ccf-a9f8-1f790e634922",
        return_at_close: 1.000032357457263,
        trade_pair: ["EURCHF", "EUR/CHF", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 194.798,
        close_ms: 1715306351367,
        current_return: 1.000333678990544,
        initial_entry_price: 194.798,
        is_closed_position: true,
        miner_hotkey: "5DaHdgTLPrGCdiNMosKq9GEpDmA6pPaMvNopXtnG28AtYghm",
        net_leverage: 0.0,
        open_ms: 1715305249210,
        orders: [
          {
            leverage: 1.0,
            order_type: "LONG",
            order_uuid: "3c6b5012-9fed-42d8-9d1c-d9c33c07eef9",
            price: 194.798,
            price_sources: [],
            processed_ms: 1715305249008,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "7e9580b5-d111-48a6-a446-6fd29b05ee64",
            price: 194.863,
            price_sources: [],
            processed_ms: 1715306351367,
          },
        ],
        position_type: "FLAT",
        position_uuid: "78b97c53-b4c0-4dd2-8a0d-fd5b65c30a06",
        return_at_close: 1.0002636556330147,
        trade_pair: ["GBPJPY", "GBP/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 1.0779,
        close_ms: 1715306368004,
        current_return: 1.000278318953521,
        initial_entry_price: 1.0779,
        is_closed_position: true,
        miner_hotkey: "5DaHdgTLPrGCdiNMosKq9GEpDmA6pPaMvNopXtnG28AtYghm",
        net_leverage: 0.0,
        open_ms: 1715305199362,
        orders: [
          {
            leverage: -1.0,
            order_type: "SHORT",
            order_uuid: "722b79f0-1f94-4389-80d5-7d14f77828ad",
            price: 1.0779,
            price_sources: [],
            processed_ms: 1715305199177,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "ffcad5c3-a251-4c31-96df-6e59b9a4442b",
            price: 1.0776,
            price_sources: [],
            processed_ms: 1715306368004,
          },
        ],
        position_type: "FLAT",
        position_uuid: "84619a25-2ae5-49e9-975c-0457fbd2b361",
        return_at_close: 1.0002082994711943,
        trade_pair: ["EURUSD", "EUR/USD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 1.25186,
        close_ms: 1715321147854,
        current_return: 1.0011662645982777,
        initial_entry_price: 1.25186,
        is_closed_position: true,
        miner_hotkey: "5DaHdgTLPrGCdiNMosKq9GEpDmA6pPaMvNopXtnG28AtYghm",
        net_leverage: 0.0,
        open_ms: 1715305905725,
        orders: [
          {
            leverage: 1.0,
            order_type: "LONG",
            order_uuid: "258ee5ba-d3eb-4413-987b-debc12556638",
            price: 1.25186,
            price_sources: [],
            processed_ms: 1715305905492,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "07f890b0-6eed-402c-8c02-016dd832ec27",
            price: 1.25332,
            price_sources: [],
            processed_ms: 1715321147854,
          },
        ],
        position_type: "FLAT",
        position_uuid: "23a8954f-8894-4cda-8363-64472e9aa809",
        return_at_close: 1.0010961829597558,
        trade_pair: ["GBPUSD", "GBP/USD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 167.767,
        close_ms: 1715324573777,
        current_return: 1.0351678220388978,
        initial_entry_price: 167.767,
        is_closed_position: true,
        miner_hotkey: "5DaHdgTLPrGCdiNMosKq9GEpDmA6pPaMvNopXtnG28AtYghm",
        net_leverage: 0.0,
        open_ms: 1715306455097,
        orders: [
          {
            leverage: 50.0,
            order_type: "LONG",
            order_uuid: "f57a3aec-a839-471e-8178-2d8c39158dd2",
            price: 167.767,
            price_sources: [],
            processed_ms: 1715306454889,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "6a3ba477-1119-4e7d-8f8e-7052236a7b95",
            price: 167.885,
            price_sources: [],
            processed_ms: 1715324573777,
          },
        ],
        position_type: "FLAT",
        position_uuid: "181c1f05-ac12-475d-863f-72849c866036",
        return_at_close: 1.0315447346617617,
        trade_pair: ["EURJPY", "EUR/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.9045722851432378,
        close_ms: 1715776379647,
        current_return: 1.035810640833259,
        initial_entry_price: 0.90632,
        is_closed_position: true,
        miner_hotkey: "5DaHdgTLPrGCdiNMosKq9GEpDmA6pPaMvNopXtnG28AtYghm",
        net_leverage: 0.0,
        open_ms: 1715305215801,
        orders: [
          {
            leverage: -1.0,
            order_type: "SHORT",
            order_uuid: "cd0b8ddf-7c43-4114-9e6a-d862e7221d15",
            price: 0.90632,
            price_sources: [],
            processed_ms: 1715305215606,
          },
          {
            leverage: 0.99,
            order_type: "LONG",
            order_uuid: "4a5c69dc-b7d4-46c1-b8be-0f43860755ea",
            price: 0.906,
            price_sources: [],
            processed_ms: 1715365228502,
          },
          {
            leverage: -15.0,
            order_type: "SHORT",
            order_uuid: "a4878dfb-38f5-494c-8b99-f480cda66cb2",
            price: 0.90455,
            price_sources: [],
            processed_ms: 1715776195514,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "2e527a02-8a10-4528-bece-9b315cd8d6df",
            price: 0.90241,
            price_sources: [],
            processed_ms: 1715776379647,
          },
        ],
        position_type: "FLAT",
        position_uuid: "fa2a758a-d26d-42c1-b411-edcd4824d5fc",
        return_at_close: 1.0346505329155258,
        trade_pair: ["USDCHF", "USD/CHF", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 4208.8966666666665,
        close_ms: 0,
        current_return: 0.9576918826470201,
        initial_entry_price: 3800.5,
        is_closed_position: false,
        miner_hotkey: "5DaHdgTLPrGCdiNMosKq9GEpDmA6pPaMvNopXtnG28AtYghm",
        net_leverage: 0.15,
        open_ms: 1717587167179,
        orders: [
          {
            leverage: 0.35,
            order_type: "LONG",
            order_uuid: "075cdfe1-c4e9-4dbb-8e08-2ae7fe0a83a7",
            price: 3800.5,
            price_sources: [],
            processed_ms: 1717587166999,
          },
          {
            leverage: -0.05,
            order_type: "SHORT",
            order_uuid: "bf08492b-da29-4c5b-a482-428fcc2034be",
            price: 3497.3,
            price_sources: [],
            processed_ms: 1719082880004,
          },
          {
            leverage: -0.15,
            order_type: "SHORT",
            order_uuid: "a5b24730-f43f-48da-b80f-411bc67049bd",
            price: 3493.17,
            price_sources: [],
            processed_ms: 1719083154123,
          },
        ],
        position_type: "LONG",
        position_uuid: "e89c2568-371e-4d7a-a495-50c746a5f501",
        return_at_close: 0.9574285173792921,
        trade_pair: ["ETHUSD", "ETH/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 67085.51870967742,
        close_ms: 0,
        current_return: 1.008682419360947,
        initial_entry_price: 63900.18,
        is_closed_position: false,
        miner_hotkey: "5DaHdgTLPrGCdiNMosKq9GEpDmA6pPaMvNopXtnG28AtYghm",
        net_leverage: -0.062,
        open_ms: 1714856286730,
        orders: [
          {
            leverage: -0.002,
            order_type: "SHORT",
            order_uuid: "d37a0c55-1e57-45d0-a4f5-d2b59413cbf2",
            price: 63900.18,
            price_sources: [],
            processed_ms: 1714856286507,
          },
          {
            leverage: -0.01,
            order_type: "SHORT",
            order_uuid: "007eadcb-efbc-43f1-8be2-6b211a38e007",
            price: 66380.18,
            price_sources: [],
            processed_ms: 1716157392096,
          },
          {
            leverage: -0.05,
            order_type: "SHORT",
            order_uuid: "33e4dffc-e4cb-4e91-ba92-1ff484b8edf8",
            price: 67354.0,
            price_sources: [],
            processed_ms: 1717197240401,
          },
        ],
        position_type: "SHORT",
        position_uuid: "9da20418-88a9-4458-816f-e2e0eadb76e1",
        return_at_close: 1.0086511502059468,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 0.8324700000000002,
        close_ms: 0,
        current_return: 1.004054200151357,
        initial_entry_price: 0.83247,
        is_closed_position: false,
        miner_hotkey: "5DaHdgTLPrGCdiNMosKq9GEpDmA6pPaMvNopXtnG28AtYghm",
        net_leverage: -0.75,
        open_ms: 1716302784857,
        orders: [
          {
            leverage: -0.75,
            order_type: "SHORT",
            order_uuid: "cbbcb0a9-9ee1-4a2d-b537-69996e66ae37",
            price: 0.83247,
            price_sources: [],
            processed_ms: 1716302784833,
          },
        ],
        position_type: "SHORT",
        position_uuid: "741e28e6-45e7-444e-8dcf-67111deb0442",
        return_at_close: 1.004027843728603,
        trade_pair: ["NZDCAD", "NZD/CAD", 7e-5, 0.001, 500],
      },
    ],
    thirty_day_returns: 1.0,
  },
  "5DciY4ZrExtzdGYCzWLix9Lh2NG9PXpQ9asv3Hb2qSBnuyU4": {
    all_time_returns: 1.036931782226528,
    n_positions: 1,
    percentage_profitable: 1.0,
    positions: [
      {
        average_entry_price: 3403.512073170731,
        close_ms: 1719796813137,
        current_return: 1.0386351438624624,
        initial_entry_price: 3592.32,
        is_closed_position: true,
        miner_hotkey: "5DciY4ZrExtzdGYCzWLix9Lh2NG9PXpQ9asv3Hb2qSBnuyU4",
        net_leverage: 0.0,
        open_ms: 1718591188032,
        orders: [
          {
            leverage: 0.14,
            order_type: "LONG",
            order_uuid: "2dab0851-00e7-4a73-b3b3-9c0136a5dc36",
            price: 3592.32,
            price_sources: [],
            processed_ms: 1718591187446,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "c356ea19-8b7e-4ed1-b0c2-73124c89e6b5",
            price: 3385.89,
            price_sources: [],
            processed_ms: 1719737268183,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "591b43b8-eef6-4677-bd9d-c00854750aa5",
            price: 3488.14,
            price_sources: [],
            processed_ms: 1719796813137,
          },
        ],
        position_type: "FLAT",
        position_uuid: "9ff1db55-a032-48b4-a0dd-9c33315397d2",
        return_at_close: 1.036931782226528,
        trade_pair: ["ETHUSD", "ETH/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 3524.095,
        close_ms: 0,
        current_return: 0.977897005509406,
        initial_entry_price: 3503.1,
        is_closed_position: false,
        miner_hotkey: "5DciY4ZrExtzdGYCzWLix9Lh2NG9PXpQ9asv3Hb2qSBnuyU4",
        net_leverage: 0.2,
        open_ms: 1719803945919,
        orders: [
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "2ff8719e-9ed7-4593-b109-004809bb5498",
            price: 3503.1,
            price_sources: [],
            processed_ms: 1719803945158,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "631ddb14-f3bc-4fd5-ba0a-9b5fe226c687",
            price: 3493.1,
            price_sources: [],
            processed_ms: 1719805068652,
          },
          {
            leverage: -0.3,
            order_type: "SHORT",
            order_uuid: "7ab33e98-280f-4f02-98b7-6ed94c8fedc6",
            price: 3480.77,
            price_sources: [],
            processed_ms: 1719825569755,
          },
        ],
        position_type: "LONG",
        position_uuid: "ed67859f-97aa-491e-aaec-b2e12294bfeb",
        return_at_close: 0.9775058467072022,
        trade_pair: ["ETHUSD", "ETH/USD", 0.003, 0.001, 20],
      },
    ],
    thirty_day_returns: 1.036931782226528,
  },
  "5Df8YED2EoxY65B2voeCHzY9rn1R76DXB8Cq9f62CsGVRoU5": {
    all_time_returns: 1.062723719306954,
    n_positions: 39,
    percentage_profitable: 0.8717948717948718,
    positions: [
      {
        average_entry_price: 164.883,
        close_ms: 1713531471391,
        current_return: 1.0000624685382968,
        initial_entry_price: 164.883,
        is_closed_position: true,
        miner_hotkey: "5Df8YED2EoxY65B2voeCHzY9rn1R76DXB8Cq9f62CsGVRoU5",
        net_leverage: 0.0,
        open_ms: 1713530937751,
        orders: [
          {
            leverage: -0.1,
            order_type: "SHORT",
            order_uuid: "1081db69-aea2-4882-ae7a-610d76365088",
            price: 164.883,
            price_sources: [],
            processed_ms: 1713530937006,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "402ad311-c907-474e-badd-4feefd7590bc",
            price: 164.78,
            price_sources: [],
            processed_ms: 1713531471391,
          },
        ],
        position_type: "FLAT",
        position_uuid: "fdea7bbc-3d1a-4348-bbaf-e309b2a888fa",
        return_at_close: 1.000055468101017,
        trade_pair: ["EURJPY", "EUR/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 91.123,
        close_ms: 1713531515509,
        current_return: 1.0000691373198862,
        initial_entry_price: 91.123,
        is_closed_position: true,
        miner_hotkey: "5Df8YED2EoxY65B2voeCHzY9rn1R76DXB8Cq9f62CsGVRoU5",
        net_leverage: 0.0,
        open_ms: 1713531144856,
        orders: [
          {
            leverage: -0.1,
            order_type: "SHORT",
            order_uuid: "8ce344fd-bb77-4009-9534-e5dc84ebba2c",
            price: 91.123,
            price_sources: [],
            processed_ms: 1713531144853,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "e97f0376-57d7-44f6-afc1-94072940c5de",
            price: 91.06,
            price_sources: [],
            processed_ms: 1713531515509,
          },
        ],
        position_type: "FLAT",
        position_uuid: "0246c630-57f5-47b7-9f6f-cd7735cbb69a",
        return_at_close: 1.000062136835925,
        trade_pair: ["NZDJPY", "NZD/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.64235,
        close_ms: 1713531849470,
        current_return: 1.000068498482136,
        initial_entry_price: 0.64235,
        is_closed_position: true,
        miner_hotkey: "5Df8YED2EoxY65B2voeCHzY9rn1R76DXB8Cq9f62CsGVRoU5",
        net_leverage: 0.0,
        open_ms: 1713529984512,
        orders: [
          {
            leverage: -0.1,
            order_type: "SHORT",
            order_uuid: "31ec80ab-5128-4719-bfaf-e753e8ce3153",
            price: 0.64235,
            price_sources: [],
            processed_ms: 1713529984511,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "8ad689e6-e92b-4dd9-98a6-e595838807e0",
            price: 0.64191,
            price_sources: [],
            processed_ms: 1713531849470,
          },
        ],
        position_type: "FLAT",
        position_uuid: "20adfa66-0dd8-4142-be7a-ed52d42e2b75",
        return_at_close: 1.0000614980026465,
        trade_pair: ["AUDUSD", "AUD/USD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 1.08915,
        close_ms: 1713532533442,
        current_return: 1.0000339714456228,
        initial_entry_price: 1.08915,
        is_closed_position: true,
        miner_hotkey: "5Df8YED2EoxY65B2voeCHzY9rn1R76DXB8Cq9f62CsGVRoU5",
        net_leverage: 0.0,
        open_ms: 1713530079842,
        orders: [
          {
            leverage: -0.1,
            order_type: "SHORT",
            order_uuid: "12dc9b61-1444-4706-b8cd-11623cc07743",
            price: 1.08915,
            price_sources: [],
            processed_ms: 1713530079841,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "be62d447-aa6c-47b6-ba8f-a17b31e9f24d",
            price: 1.08878,
            price_sources: [],
            processed_ms: 1713532533442,
          },
        ],
        position_type: "FLAT",
        position_uuid: "4247be53-55f5-47b9-94cc-91f785b80f4a",
        return_at_close: 1.0000269712078227,
        trade_pair: ["AUDNZD", "AUD/NZD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 1.37562,
        close_ms: 1713532559925,
        current_return: 1.000049432256001,
        initial_entry_price: 1.37562,
        is_closed_position: true,
        miner_hotkey: "5Df8YED2EoxY65B2voeCHzY9rn1R76DXB8Cq9f62CsGVRoU5",
        net_leverage: 0.0,
        open_ms: 1713531380835,
        orders: [
          {
            leverage: 0.1,
            order_type: "LONG",
            order_uuid: "a8227a01-134f-47e1-b7c8-d2f15b6adc09",
            price: 1.37562,
            price_sources: [],
            processed_ms: 1713531380833,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "1eb2b898-3d7d-41e7-b60c-8e3a51b82a6e",
            price: 1.3763,
            price_sources: [],
            processed_ms: 1713532559925,
          },
        ],
        position_type: "FLAT",
        position_uuid: "a7d4015e-097a-4f58-a084-c41e2e6e6ec7",
        return_at_close: 1.0000424319099752,
        trade_pair: ["USDCAD", "USD/CAD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.58955,
        close_ms: 1713532575362,
        current_return: 1.0000763294037827,
        initial_entry_price: 0.58955,
        is_closed_position: true,
        miner_hotkey: "5Df8YED2EoxY65B2voeCHzY9rn1R76DXB8Cq9f62CsGVRoU5",
        net_leverage: 0.0,
        open_ms: 1713531218313,
        orders: [
          {
            leverage: -0.1,
            order_type: "SHORT",
            order_uuid: "c625ada4-d5e1-49a4-af9b-8deee71ad79a",
            price: 0.58955,
            price_sources: [],
            processed_ms: 1713531218311,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "863a606e-d7b4-4622-b528-6401642a72ba",
            price: 0.5891,
            price_sources: [],
            processed_ms: 1713532575362,
          },
        ],
        position_type: "FLAT",
        position_uuid: "846f7964-3333-4be3-940e-8f98e8123d55",
        return_at_close: 1.0000693288694769,
        trade_pair: ["NZDUSD", "NZD/USD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 1.24512,
        close_ms: 1713532592587,
        current_return: 1.00006344769982,
        initial_entry_price: 1.24512,
        is_closed_position: true,
        miner_hotkey: "5Df8YED2EoxY65B2voeCHzY9rn1R76DXB8Cq9f62CsGVRoU5",
        net_leverage: 0.0,
        open_ms: 1713531360531,
        orders: [
          {
            leverage: -0.1,
            order_type: "SHORT",
            order_uuid: "dcfa137c-e259-4cae-a7ba-b1358f24bc9f",
            price: 1.24512,
            price_sources: [],
            processed_ms: 1713531360529,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "5a7c67d6-6123-4b02-9cb2-8d3c7c9b7579",
            price: 1.24433,
            price_sources: [],
            processed_ms: 1713532592587,
          },
        ],
        position_type: "FLAT",
        position_uuid: "61be1fc6-c573-466a-899e-63bc09c7a6d9",
        return_at_close: 1.000056447255686,
        trade_pair: ["GBPUSD", "GBP/USD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.8108286602157,
        close_ms: 1713532801168,
        current_return: 1.0000254899995538,
        initial_entry_price: 0.8108286602157,
        is_closed_position: true,
        miner_hotkey: "5Df8YED2EoxY65B2voeCHzY9rn1R76DXB8Cq9f62CsGVRoU5",
        net_leverage: 0.0,
        open_ms: 1713531110351,
        orders: [
          {
            leverage: 0.1,
            order_type: "LONG",
            order_uuid: "02cb685c-f0a0-488d-8c61-bfa099d9de11",
            price: 0.8108286602157,
            price_sources: [],
            processed_ms: 1713531110350,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "b42e99bf-9166-4728-9ff3-a383d4588d76",
            price: 0.811035340437571,
            price_sources: [],
            processed_ms: 1713532801168,
          },
        ],
        position_type: "FLAT",
        position_uuid: "be0d6189-9705-4719-ba53-9bda76802327",
        return_at_close: 1.0000184898211237,
        trade_pair: ["NZDCAD", "NZD/CAD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 112.394,
        close_ms: 1713533958903,
        current_return: 1.0000311404523372,
        initial_entry_price: 112.394,
        is_closed_position: true,
        miner_hotkey: "5Df8YED2EoxY65B2voeCHzY9rn1R76DXB8Cq9f62CsGVRoU5",
        net_leverage: 0.0,
        open_ms: 1713530177774,
        orders: [
          {
            leverage: 0.1,
            order_type: "LONG",
            order_uuid: "aad6540c-c85b-4dec-a338-a5e031ff8a58",
            price: 112.394,
            price_sources: [],
            processed_ms: 1713530177773,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "3c3fe79e-fa67-4036-a319-f14f473374c7",
            price: 112.429,
            price_sources: [],
            processed_ms: 1713533958903,
          },
        ],
        position_type: "FLAT",
        position_uuid: "16cdb32e-80d8-4d63-8382-4a2ae849aa01",
        return_at_close: 1.0000241402343542,
        trade_pair: ["CADJPY", "CAD/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 1.4667999999999999,
        close_ms: 1713535322106,
        current_return: 1.0000613580583584,
        initial_entry_price: 1.4668,
        is_closed_position: true,
        miner_hotkey: "5Df8YED2EoxY65B2voeCHzY9rn1R76DXB8Cq9f62CsGVRoU5",
        net_leverage: 0.0,
        open_ms: 1713530258909,
        orders: [
          {
            leverage: -0.1,
            order_type: "SHORT",
            order_uuid: "aa36432a-7e80-4586-8fa0-488819b4e4f2",
            price: 1.4668,
            price_sources: [],
            processed_ms: 1713530257627,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "349ae395-be54-4a09-ae8b-f28cb1e53a13",
            price: 1.4659,
            price_sources: [],
            processed_ms: 1713535322106,
          },
        ],
        position_type: "FLAT",
        position_uuid: "678566b3-6097-4e0e-8f90-2d804b32cd56",
        return_at_close: 1.000054357628852,
        trade_pair: ["EURCAD", "EUR/CAD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 154.575,
        close_ms: 1713767342803,
        current_return: 1.000088630114831,
        initial_entry_price: 154.575,
        is_closed_position: true,
        miner_hotkey: "5Df8YED2EoxY65B2voeCHzY9rn1R76DXB8Cq9f62CsGVRoU5",
        net_leverage: 0.0,
        open_ms: 1713534061054,
        orders: [
          {
            leverage: 0.1,
            order_type: "LONG",
            order_uuid: "55094b25-4fce-4094-ae68-fe442811db3e",
            price: 154.575,
            price_sources: [],
            processed_ms: 1713534061051,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "a41257ff-8f95-45de-8e94-c41b4c03aff2",
            price: 154.712,
            price_sources: [],
            processed_ms: 1713767342803,
          },
        ],
        position_type: "FLAT",
        position_uuid: "22fa2f8c-54ae-4739-8f89-83fe08fa4398",
        return_at_close: 1.0000816294944204,
        trade_pair: ["USDJPY", "USD/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 1.065498,
        close_ms: 1713767366741,
        current_return: 1.000446072964792,
        initial_entry_price: 1.06709,
        is_closed_position: true,
        miner_hotkey: "5Df8YED2EoxY65B2voeCHzY9rn1R76DXB8Cq9f62CsGVRoU5",
        net_leverage: 0.0,
        open_ms: 1713530563461,
        orders: [
          {
            leverage: 0.1,
            order_type: "LONG",
            order_uuid: "e4d27289-e9a0-425c-af98-87c9bed998d8",
            price: 1.06709,
            price_sources: [],
            processed_ms: 1713530563459,
          },
          {
            leverage: 0.2,
            order_type: "LONG",
            order_uuid: "f9b94fe3-0345-4fa2-a834-6a418a127c8a",
            price: 1.0654,
            price_sources: [],
            processed_ms: 1713551108399,
          },
          {
            leverage: 0.2,
            order_type: "LONG",
            order_uuid: "f9bc95d3-8c6f-46bc-ad7b-f13cf98f71ff",
            price: 1.0648,
            price_sources: [],
            processed_ms: 1713552552033,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "5891e4e8-c7cd-4aae-976a-ecc5f21a24cd",
            price: 1.06645,
            price_sources: [],
            processed_ms: 1713767366741,
          },
        ],
        position_type: "FLAT",
        position_uuid: "44864e64-7eab-453a-b8c8-209bc2840ec6",
        return_at_close: 1.0004110573522382,
        trade_pair: ["EURUSD", "EUR/USD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 1.80896,
        close_ms: 1713767472646,
        current_return: 1.0002415752697682,
        initial_entry_price: 1.80896,
        is_closed_position: true,
        miner_hotkey: "5Df8YED2EoxY65B2voeCHzY9rn1R76DXB8Cq9f62CsGVRoU5",
        net_leverage: 0.0,
        open_ms: 1713531084089,
        orders: [
          {
            leverage: -0.1,
            order_type: "SHORT",
            order_uuid: "65608897-eb8f-4052-8109-e6657b359d30",
            price: 1.80896,
            price_sources: [],
            processed_ms: 1713531084086,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "40b56c80-579b-4556-be41-2ed9136685fb",
            price: 1.80459,
            price_sources: [],
            processed_ms: 1713767472646,
          },
        ],
        position_type: "FLAT",
        position_uuid: "0211ee53-9060-4474-93f4-72d048bcf5e5",
        return_at_close: 1.0002345735787412,
        trade_pair: ["EURNZD", "EUR/NZD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 99.21373333333334,
        close_ms: 1713793324462,
        current_return: 1.0008681723853081,
        initial_entry_price: 99.266,
        is_closed_position: true,
        miner_hotkey: "5Df8YED2EoxY65B2voeCHzY9rn1R76DXB8Cq9f62CsGVRoU5",
        net_leverage: 0.0,
        open_ms: 1713530031922,
        orders: [
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "f19153d9-25c3-4a3e-933c-c7ed4f9283a2",
            price: 99.266,
            price_sources: [],
            processed_ms: 1713530031921,
          },
          {
            leverage: 0.09,
            order_type: "LONG",
            order_uuid: "1c8a67bc-3483-430e-967e-a697ee92cf6e",
            price: 99.294,
            price_sources: [],
            processed_ms: 1713530499859,
          },
          {
            leverage: 0.2,
            order_type: "LONG",
            order_uuid: "3c5ca50f-232b-4171-892a-48a4cf185ed4",
            price: 99.175,
            price_sources: [],
            processed_ms: 1713552568461,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "4decb88f-d445-4247-b065-d86acf797b60",
            price: 99.501,
            price_sources: [],
            processed_ms: 1713793324462,
          },
        ],
        position_type: "FLAT",
        position_uuid: "a90d6280-5e10-43fc-9d62-5f2a4e2e4c66",
        return_at_close: 1.000847154153688,
        trade_pair: ["AUDJPY", "AUD/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.8825808402111851,
        close_ms: 1713793356887,
        current_return: 1.0002379430910953,
        initial_entry_price: 0.88307,
        is_closed_position: true,
        miner_hotkey: "5Df8YED2EoxY65B2voeCHzY9rn1R76DXB8Cq9f62CsGVRoU5",
        net_leverage: 0.0,
        open_ms: 1713529928658,
        orders: [
          {
            leverage: 0.1,
            order_type: "LONG",
            order_uuid: "83649a42-51d0-44e1-9cd0-c0e0f9239202",
            price: 0.88307,
            price_sources: [],
            processed_ms: 1713529928658,
          },
          {
            leverage: 0.1,
            order_type: "LONG",
            order_uuid: "d9e94fbd-039f-4a62-954a-a74e737a9823",
            price: 0.88209168042237,
            price_sources: [],
            processed_ms: 1713535806115,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "4144e065-875d-42fa-80fc-6fdf748464bc",
            price: 0.883631442238453,
            price_sources: [],
            processed_ms: 1713793356887,
          },
        ],
        position_type: "FLAT",
        position_uuid: "6fc3bc28-935f-4503-b736-ad6f6080e52d",
        return_at_close: 1.000223939759892,
        trade_pair: ["AUDCAD", "AUD/CAD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 64835.560000000005,
        close_ms: 1713793414367,
        current_return: 1.0008117690353873,
        initial_entry_price: 64835.56,
        is_closed_position: true,
        miner_hotkey: "5Df8YED2EoxY65B2voeCHzY9rn1R76DXB8Cq9f62CsGVRoU5",
        net_leverage: 0.0,
        open_ms: 1713646083218,
        orders: [
          {
            leverage: 0.05,
            order_type: "LONG",
            order_uuid: "422e60bb-0c0b-4529-be1a-51e4d638053c",
            price: 64835.56,
            price_sources: [],
            processed_ms: 1713646083214,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "48b2b7eb-a034-4d40-a81a-8cf39afeecc0",
            price: 65888.19,
            price_sources: [],
            processed_ms: 1713793414367,
          },
        ],
        position_type: "FLAT",
        position_uuid: "867f7765-737d-463b-83f6-f504991d9f8a",
        return_at_close: 1.0007617284469355,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 194.57,
        close_ms: 1714102718243,
        current_return: 1.0004851724315156,
        initial_entry_price: 194.57,
        is_closed_position: true,
        miner_hotkey: "5Df8YED2EoxY65B2voeCHzY9rn1R76DXB8Cq9f62CsGVRoU5",
        net_leverage: 0.0,
        open_ms: 1714098532961,
        orders: [
          {
            leverage: 0.2,
            order_type: "LONG",
            order_uuid: "ab20246d-f35c-490e-8836-c3cd89baa9c2",
            price: 194.57,
            price_sources: [],
            processed_ms: 1714098532958,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "dfa6003b-d3b9-48f5-a32d-0f97db7aa133",
            price: 195.042,
            price_sources: [],
            processed_ms: 1714102718243,
          },
        ],
        position_type: "FLAT",
        position_uuid: "cca55f09-d671-4ee5-b585-6f1a12a8d759",
        return_at_close: 1.0004711656391017,
        trade_pair: ["GBPJPY", "GBP/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.891554583442152,
        close_ms: 1714102758945,
        current_return: 1.0003716822713942,
        initial_entry_price: 0.891554583442152,
        is_closed_position: true,
        miner_hotkey: "5Df8YED2EoxY65B2voeCHzY9rn1R76DXB8Cq9f62CsGVRoU5",
        net_leverage: 0.0,
        open_ms: 1714098569883,
        orders: [
          {
            leverage: -0.3,
            order_type: "SHORT",
            order_uuid: "092983e2-eb03-48f9-ba0b-b119027e746c",
            price: 0.891554583442152,
            price_sources: [],
            processed_ms: 1714098569879,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "fbc97991-c8d5-4461-856c-df1087a2283e",
            price: 0.89045,
            price_sources: [],
            processed_ms: 1714102758945,
          },
        ],
        position_type: "FLAT",
        position_uuid: "d93b65e0-4bbe-4056-a5ee-73710f2f81fe",
        return_at_close: 1.0003506744660664,
        trade_pair: ["AUDCAD", "AUD/CAD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 64477.24,
        close_ms: 1714102817162,
        current_return: 1.0005064577826221,
        initial_entry_price: 64477.24,
        is_closed_position: true,
        miner_hotkey: "5Df8YED2EoxY65B2voeCHzY9rn1R76DXB8Cq9f62CsGVRoU5",
        net_leverage: 0.0,
        open_ms: 1714098425748,
        orders: [
          {
            leverage: -0.3,
            order_type: "SHORT",
            order_uuid: "b5084423-fd27-4208-a5c7-0f9798f108b0",
            price: 64477.24,
            price_sources: [],
            processed_ms: 1714098425742,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "e4fa702b-8e78-4a1c-bd72-be20f19377db",
            price: 64368.39,
            price_sources: [],
            processed_ms: 1714102817162,
          },
        ],
        position_type: "FLAT",
        position_uuid: "ec59abeb-f9f9-4597-9edb-999f6704506b",
        return_at_close: 1.0002063058452875,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 3156.57,
        close_ms: 1714102833089,
        current_return: 1.0005640616238511,
        initial_entry_price: 3156.57,
        is_closed_position: true,
        miner_hotkey: "5Df8YED2EoxY65B2voeCHzY9rn1R76DXB8Cq9f62CsGVRoU5",
        net_leverage: 0.0,
        open_ms: 1714098453581,
        orders: [
          {
            leverage: -0.15,
            order_type: "SHORT",
            order_uuid: "ec07744e-1cf8-4b91-b16c-462c863ab39e",
            price: 3156.57,
            price_sources: [],
            processed_ms: 1714098453578,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "a3a55743-ea15-40ea-90d1-d3be0e99c5f6",
            price: 3144.7,
            price_sources: [],
            processed_ms: 1714102833089,
          },
        ],
        position_type: "FLAT",
        position_uuid: "51d69155-8b4b-4d55-bbff-d65a3ac78c4b",
        return_at_close: 1.0004139770146077,
        trade_pair: ["ETHUSD", "ETH/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 1.09436,
        close_ms: 1714109223657,
        current_return: 1.0005416871819528,
        initial_entry_price: 1.09436,
        is_closed_position: true,
        miner_hotkey: "5Df8YED2EoxY65B2voeCHzY9rn1R76DXB8Cq9f62CsGVRoU5",
        net_leverage: 0.0,
        open_ms: 1714098586276,
        orders: [
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "f8357c1b-c2b6-414a-9c97-190963d7833f",
            price: 1.09436,
            price_sources: [],
            processed_ms: 1714098586272,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "efc2ecb5-8782-4837-9f5d-6fbdc3fbaba8",
            price: 1.096336002614806,
            price_sources: [],
            processed_ms: 1714109223657,
          },
        ],
        position_type: "FLAT",
        position_uuid: "5505f7ce-af42-46ec-8573-2f00ef36ef33",
        return_at_close: 1.000520675806522,
        trade_pair: ["AUDNZD", "AUD/NZD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 1.07273,
        close_ms: 1714118993914,
        current_return: 1.0002405078631156,
        initial_entry_price: 1.07273,
        is_closed_position: true,
        miner_hotkey: "5Df8YED2EoxY65B2voeCHzY9rn1R76DXB8Cq9f62CsGVRoU5",
        net_leverage: 0.0,
        open_ms: 1714098111695,
        orders: [
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "9914d466-8ee7-4b9b-97c6-a216afe6af57",
            price: 1.07273,
            price_sources: [],
            processed_ms: 1714098111692,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "79d59141-8937-4f1c-8368-2ec9867f6372",
            price: 1.07359,
            price_sources: [],
            processed_ms: 1714118993914,
          },
        ],
        position_type: "FLAT",
        position_uuid: "6d66a477-8c92-45ca-bb5d-46d24c49767a",
        return_at_close: 1.0002195028124503,
        trade_pair: ["EURUSD", "EUR/USD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 1.25041,
        close_ms: 1714159940796,
        current_return: 1.0001727433401844,
        initial_entry_price: 1.25041,
        is_closed_position: true,
        miner_hotkey: "5Df8YED2EoxY65B2voeCHzY9rn1R76DXB8Cq9f62CsGVRoU5",
        net_leverage: 0.0,
        open_ms: 1714098551068,
        orders: [
          {
            leverage: -0.2,
            order_type: "SHORT",
            order_uuid: "feb7219c-c325-4237-8bcc-86acedc9b1e7",
            price: 1.25041,
            price_sources: [],
            processed_ms: 1714098550097,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "071f4983-c8a7-447c-b83a-531abdc6646a",
            price: 1.24933,
            price_sources: [],
            processed_ms: 1714159940796,
          },
        ],
        position_type: "FLAT",
        position_uuid: "9e171866-168b-4ac4-85c2-bf73f6a6e9ef",
        return_at_close: 1.0001587409217776,
        trade_pair: ["GBPUSD", "GBP/USD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.6784200000000012,
        close_ms: 1714164340232,
        current_return: 1.0010353824243556,
        initial_entry_price: 0.85664,
        is_closed_position: true,
        miner_hotkey: "5Df8YED2EoxY65B2voeCHzY9rn1R76DXB8Cq9f62CsGVRoU5",
        net_leverage: 0.0,
        open_ms: 1713530827090,
        orders: [
          {
            leverage: 0.1,
            order_type: "LONG",
            order_uuid: "7e3abeb9-a349-4d76-8e80-86e0cfb14cd9",
            price: 0.85664,
            price_sources: [],
            processed_ms: 1713530827088,
          },
          {
            leverage: 0.1,
            order_type: "LONG",
            order_uuid: "6b02b972-dd6a-4635-b958-8fc26968fc93",
            price: 0.85588,
            price_sources: [],
            processed_ms: 1713535824199,
          },
          {
            leverage: -0.195,
            order_type: "SHORT",
            order_uuid: "61d7ca9b-8550-46fe-864c-a0b2ca80d169",
            price: 0.86082,
            price_sources: [],
            processed_ms: 1713552316759,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "2cee09df-ce6e-4a03-83ef-4fb3ded5208c",
            price: 0.85581,
            price_sources: [],
            processed_ms: 1714164340232,
          },
        ],
        position_type: "FLAT",
        position_uuid: "f11d6135-1369-4150-8985-d7097bd73ed1",
        return_at_close: 1.0010213679290016,
        trade_pair: ["EURGBP", "EUR/GBP", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.9770138461538462,
        close_ms: 1714401157332,
        current_return: 1.0003944162226903,
        initial_entry_price: 0.96852,
        is_closed_position: true,
        miner_hotkey: "5Df8YED2EoxY65B2voeCHzY9rn1R76DXB8Cq9f62CsGVRoU5",
        net_leverage: 0.0,
        open_ms: 1713530687558,
        orders: [
          {
            leverage: -0.1,
            order_type: "SHORT",
            order_uuid: "16298a46-c2a1-4224-bf16-600d1f3da981",
            price: 0.96852,
            price_sources: [],
            processed_ms: 1713530687557,
          },
          {
            leverage: -0.2,
            order_type: "SHORT",
            order_uuid: "c0af9caf-e809-4d5a-8fd8-4c7b8fb8392b",
            price: 0.96948,
            price_sources: [],
            processed_ms: 1713551125609,
          },
          {
            leverage: -1.0,
            order_type: "SHORT",
            order_uuid: "c9d70a13-e888-492f-8987-1fc92380e697",
            price: 0.97937,
            price_sources: [],
            processed_ms: 1714098086753,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "d37a5470-9330-4c95-b4e0-5eb6c533ae66",
            price: 0.97672,
            price_sources: [],
            processed_ms: 1714401157332,
          },
        ],
        position_type: "FLAT",
        position_uuid: "b1e8150d-1237-40ed-ae49-c785e75cd6f5",
        return_at_close: 1.0003033803308141,
        trade_pair: ["EURCHF", "EUR/CHF", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 2985.1499999999996,
        close_ms: 1714546159385,
        current_return: 1.0041194023852342,
        initial_entry_price: 2985.87,
        is_closed_position: true,
        miner_hotkey: "5Df8YED2EoxY65B2voeCHzY9rn1R76DXB8Cq9f62CsGVRoU5",
        net_leverage: 0.0,
        open_ms: 1714541306888,
        orders: [
          {
            leverage: -1.0,
            order_type: "SHORT",
            order_uuid: "a228da09-ece6-4334-afc1-df5ee032fb34",
            price: 2985.87,
            price_sources: [],
            processed_ms: 1714541306884,
          },
          {
            leverage: -1.0,
            order_type: "SHORT",
            order_uuid: "7c4e6894-1a11-4e73-84e4-96d324dd8e4c",
            price: 2984.43,
            price_sources: [],
            processed_ms: 1714541349981,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "4941fdb4-2fcd-4e48-87ba-9ba4fc375925",
            price: 2979.0,
            price_sources: [],
            processed_ms: 1714546159385,
          },
        ],
        position_type: "FLAT",
        position_uuid: "9ccc7132-bdb4-4805-8741-7b98d774990a",
        return_at_close: 1.0021111635804638,
        trade_pair: ["ETHUSD", "ETH/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 2954.92,
        close_ms: 1714547571415,
        current_return: 1.0544177168925046,
        initial_entry_price: 2954.92,
        is_closed_position: true,
        miner_hotkey: "5Df8YED2EoxY65B2voeCHzY9rn1R76DXB8Cq9f62CsGVRoU5",
        net_leverage: 0.0,
        open_ms: 1714546548245,
        orders: [
          {
            leverage: -3.0,
            order_type: "SHORT",
            order_uuid: "59725e7e-afb3-4db8-9757-e81042d87bb8",
            price: 2954.92,
            price_sources: [],
            processed_ms: 1714546548237,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "193d94af-85fe-4b22-9358-ea4252532b98",
            price: 2901.32,
            price_sources: [],
            processed_ms: 1714547571415,
          },
        ],
        position_type: "FLAT",
        position_uuid: "38accba8-5678-45ae-8be9-71b8d67ca794",
        return_at_close: 1.051254463741827,
        trade_pair: ["ETHUSD", "ETH/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 1.19259000000002,
        close_ms: 1714568745322,
        current_return: 1.0001890630959516,
        initial_entry_price: 1.46824,
        is_closed_position: true,
        miner_hotkey: "5Df8YED2EoxY65B2voeCHzY9rn1R76DXB8Cq9f62CsGVRoU5",
        net_leverage: 0.0,
        open_ms: 1714541004860,
        orders: [
          {
            leverage: 0.15,
            order_type: "LONG",
            order_uuid: "c7b570bb-d1da-4089-8a24-6d12e6a12047",
            price: 1.46824,
            price_sources: [],
            processed_ms: 1714541004853,
          },
          {
            leverage: -0.149,
            order_type: "SHORT",
            order_uuid: "5b6e112b-7df5-43b9-bf77-08412572e93e",
            price: 1.47009,
            price_sources: [],
            processed_ms: 1714567333467,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "2c75f7bf-9682-4be5-9c95-d98a4221e4fd",
            price: 1.47018,
            price_sources: [],
            processed_ms: 1714568745322,
          },
        ],
        position_type: "FLAT",
        position_uuid: "de14985b-1258-4827-8fa9-0ec63d238b73",
        return_at_close: 1.000178561110789,
        trade_pair: ["EURCAD", "EUR/CAD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 1.8506000000000002,
        close_ms: 1714965651473,
        current_return: 1.0003172192088023,
        initial_entry_price: 1.81042,
        is_closed_position: true,
        miner_hotkey: "5Df8YED2EoxY65B2voeCHzY9rn1R76DXB8Cq9f62CsGVRoU5",
        net_leverage: 0.0,
        open_ms: 1714541021852,
        orders: [
          {
            leverage: -0.15,
            order_type: "SHORT",
            order_uuid: "a0f713d6-91e3-4e9a-acff-e91a0ee08320",
            price: 1.81042,
            price_sources: [],
            processed_ms: 1714541021848,
          },
          {
            leverage: 0.14,
            order_type: "LONG",
            order_uuid: "5071e3ca-3a08-4675-b3d9-d3e4707956d3",
            price: 1.80755,
            price_sources: [],
            processed_ms: 1714594686395,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "6f12308d-6af9-4988-a839-e2e46346aba5",
            price: 1.79317,
            price_sources: [],
            processed_ms: 1714965651473,
          },
        ],
        position_type: "FLAT",
        position_uuid: "3e3228f4-4070-4007-9eea-50641f9186f2",
        return_at_close: 1.0003067158780006,
        trade_pair: ["EURNZD", "EUR/NZD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 3109.87,
        close_ms: 1716300776938,
        current_return: 0.9977780743246503,
        initial_entry_price: 3109.87,
        is_closed_position: true,
        miner_hotkey: "5Df8YED2EoxY65B2voeCHzY9rn1R76DXB8Cq9f62CsGVRoU5",
        net_leverage: 0.0,
        open_ms: 1714814364581,
        orders: [
          {
            leverage: -0.01,
            order_type: "SHORT",
            order_uuid: "d5821b22-3002-48db-b497-80be0ccfb854",
            price: 3109.87,
            price_sources: [],
            processed_ms: 1714814361812,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "687c6596-4770-44f7-abec-c768d70db9d2",
            price: 3800.86,
            price_sources: [],
            processed_ms: 1716300776938,
          },
        ],
        position_type: "FLAT",
        position_uuid: "465e34b6-50b4-442a-bd3d-875e0df808cc",
        return_at_close: 0.9977680965439071,
        trade_pair: ["ETHUSD", "ETH/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 157.314,
        close_ms: 1718591122070,
        current_return: 1.0123955909836373,
        initial_entry_price: 157.314,
        is_closed_position: true,
        miner_hotkey: "5Df8YED2EoxY65B2voeCHzY9rn1R76DXB8Cq9f62CsGVRoU5",
        net_leverage: 0.0,
        open_ms: 1718390694065,
        orders: [
          {
            leverage: 10.0,
            order_type: "LONG",
            order_uuid: "8ed64b01-2a37-4ef8-b73b-55ad288be0f2",
            price: 157.314,
            price_sources: [],
            processed_ms: 1718390693887,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "9d3777dd-8531-44a8-ab9f-9c992d75abdb",
            price: 157.509,
            price_sources: [],
            processed_ms: 1718591122070,
          },
        ],
        position_type: "FLAT",
        position_uuid: "79e6ecfe-f007-4ebf-82cc-58dbc68fd3da",
        return_at_close: 1.0116869140699487,
        trade_pair: ["USDJPY", "USD/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 3691.49,
        close_ms: 1719233768387,
        current_return: 0.9897903014771813,
        initial_entry_price: 3691.49,
        is_closed_position: true,
        miner_hotkey: "5Df8YED2EoxY65B2voeCHzY9rn1R76DXB8Cq9f62CsGVRoU5",
        net_leverage: 0.0,
        open_ms: 1716571752585,
        orders: [
          {
            leverage: 0.1,
            order_type: "LONG",
            order_uuid: "ca43f568-9608-420e-a7e5-a5503e8c45b1",
            price: 3691.49,
            price_sources: [],
            processed_ms: 1716571752278,
          },
          {
            leverage: -0.2,
            order_type: "SHORT",
            order_uuid: "54e7220d-f548-4630-9420-fbc1e3fa78c0",
            price: 3314.6,
            price_sources: [],
            processed_ms: 1719233768387,
          },
        ],
        position_type: "FLAT",
        position_uuid: "cc076c8c-1167-48fb-a950-fd5010de2d90",
        return_at_close: 0.9896913224470336,
        trade_pair: ["ETHUSD", "ETH/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 61193.22311827956,
        close_ms: 1719248229116,
        current_return: 1.0154168878204766,
        initial_entry_price: 61100.01,
        is_closed_position: true,
        miner_hotkey: "5Df8YED2EoxY65B2voeCHzY9rn1R76DXB8Cq9f62CsGVRoU5",
        net_leverage: 0.0,
        open_ms: 1719233526327,
        orders: [
          {
            leverage: -0.2,
            order_type: "SHORT",
            order_uuid: "1c229567-e8a5-45e0-b444-171a7a22c791",
            price: 61100.01,
            price_sources: [],
            processed_ms: 1719233525553,
          },
          {
            leverage: -0.08,
            order_type: "SHORT",
            order_uuid: "efc8461a-a72b-4658-bc70-4ad0686e189b",
            price: 61470.0,
            price_sources: [],
            processed_ms: 1719239117611,
          },
          {
            leverage: -0.5,
            order_type: "SHORT",
            order_uuid: "bfaf7f0b-7420-47ca-b6c3-13a69c5f91ba",
            price: 61206.83,
            price_sources: [],
            processed_ms: 1719240158679,
          },
          {
            leverage: -0.05,
            order_type: "SHORT",
            order_uuid: "fd5eb3e6-e20e-4c5b-a76f-9838faae1e50",
            price: 61294.21,
            price_sources: [],
            processed_ms: 1719241178363,
          },
          {
            leverage: -0.05,
            order_type: "SHORT",
            order_uuid: "4be05f7f-769e-4d11-8923-69f6e7e610f2",
            price: 61229.4,
            price_sources: [],
            processed_ms: 1719241262653,
          },
          {
            leverage: -0.05,
            order_type: "SHORT",
            order_uuid: "0dd7558c-4f37-48af-9f74-6061e39ab119",
            price: 60850.0,
            price_sources: [],
            processed_ms: 1719245634836,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "e7772640-b47e-4d52-ae8e-d854c42d5a42",
            price: 60180.35,
            price_sources: [],
            processed_ms: 1719248229116,
          },
        ],
        position_type: "FLAT",
        position_uuid: "76587338-1246-40cf-9acc-82842ab35a28",
        return_at_close: 1.0144725501148035,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 61454.19999999999,
        close_ms: 1719421724120,
        current_return: 0.9895014010433788,
        initial_entry_price: 61454.2,
        is_closed_position: true,
        miner_hotkey: "5Df8YED2EoxY65B2voeCHzY9rn1R76DXB8Cq9f62CsGVRoU5",
        net_leverage: 0.0,
        open_ms: 1719420008029,
        orders: [
          {
            leverage: 1.1,
            order_type: "LONG",
            order_uuid: "ea526304-c534-4356-9b31-ff5a3e6658f2",
            price: 61454.2,
            price_sources: [],
            processed_ms: 1719420006343,
          },
          {
            leverage: -1.2,
            order_type: "SHORT",
            order_uuid: "9bead8dc-0a81-4e39-bf56-9b4a37b978c9",
            price: 60867.67,
            price_sources: [],
            processed_ms: 1719421724120,
          },
        ],
        position_type: "FLAT",
        position_uuid: "5be1f8c2-bf6d-4541-aa00-6cb165474423",
        return_at_close: 0.9884129495022311,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 3318.59,
        close_ms: 1719476232525,
        current_return: 0.9986883586101326,
        initial_entry_price: 3318.59,
        is_closed_position: true,
        miner_hotkey: "5Df8YED2EoxY65B2voeCHzY9rn1R76DXB8Cq9f62CsGVRoU5",
        net_leverage: 0.0,
        open_ms: 1719238891167,
        orders: [
          {
            leverage: -0.08,
            order_type: "SHORT",
            order_uuid: "14f8f49b-6ef8-4ed9-a18e-41f4f570aef3",
            price: 3318.59,
            price_sources: [],
            processed_ms: 1719238890916,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "12db3a74-d38d-4edd-a4ab-f5f9218d01e8",
            price: 3373.0,
            price_sources: [],
            processed_ms: 1719476232525,
          },
        ],
        position_type: "FLAT",
        position_uuid: "2bce795f-9fd5-4fe0-8a55-2d7810ecd87b",
        return_at_close: 0.9986084635414438,
        trade_pair: ["ETHUSD", "ETH/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 114.63447222222223,
        close_ms: 1719476488810,
        current_return: 0.9914936991013761,
        initial_entry_price: 113.952,
        is_closed_position: true,
        miner_hotkey: "5Df8YED2EoxY65B2voeCHzY9rn1R76DXB8Cq9f62CsGVRoU5",
        net_leverage: 0.0,
        open_ms: 1714098619040,
        orders: [
          {
            leverage: -0.23,
            order_type: "SHORT",
            order_uuid: "f0761935-28f5-4bd2-a4f3-780d87b07f22",
            price: 113.952,
            price_sources: [],
            processed_ms: 1714098619033,
          },
          {
            leverage: 0.22,
            order_type: "LONG",
            order_uuid: "f72e5d70-b2d3-499b-ba35-9236475e4631",
            price: 113.505,
            price_sources: [],
            processed_ms: 1714594667092,
          },
          {
            leverage: -0.35,
            order_type: "SHORT",
            order_uuid: "f3954984-e10c-4da5-8792-ce2c0b02cc55",
            price: 114.373,
            price_sources: [],
            processed_ms: 1716158954880,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "1f1687dc-e0d1-4033-9559-6de7ddd12f66",
            price: 117.327,
            price_sources: [],
            processed_ms: 1719476488810,
          },
        ],
        position_type: "FLAT",
        position_uuid: "658e3c39-f1e0-4a9e-b5dd-a3cfc7bf568f",
        return_at_close: 0.9914534444571926,
        trade_pair: ["CADJPY", "CAD/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 60759.99999999999,
        close_ms: 1719479700799,
        current_return: 1.0038199473337723,
        initial_entry_price: 60760.0,
        is_closed_position: true,
        miner_hotkey: "5Df8YED2EoxY65B2voeCHzY9rn1R76DXB8Cq9f62CsGVRoU5",
        net_leverage: 0.0,
        open_ms: 1719476273634,
        orders: [
          {
            leverage: 1.1,
            order_type: "LONG",
            order_uuid: "da26f12e-ad3b-40f5-9e45-6e7f37692c56",
            price: 60760.0,
            price_sources: [],
            processed_ms: 1719476271288,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "0ab75b1e-46f7-40b6-b855-a6df51868c08",
            price: 60971.0,
            price_sources: [],
            processed_ms: 1719479700799,
          },
        ],
        position_type: "FLAT",
        position_uuid: "ea5ad8ac-6ca9-4fd4-a893-d439a3be0805",
        return_at_close: 1.0027157453917053,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 3383.04,
        close_ms: 1719488519447,
        current_return: 1.006025054388952,
        initial_entry_price: 3383.04,
        is_closed_position: true,
        miner_hotkey: "5Df8YED2EoxY65B2voeCHzY9rn1R76DXB8Cq9f62CsGVRoU5",
        net_leverage: 0.0,
        open_ms: 1719480692674,
        orders: [
          {
            leverage: 1.1,
            order_type: "LONG",
            order_uuid: "10915db6-c62b-472a-bfa4-0dfc5a382919",
            price: 3383.04,
            price_sources: [],
            processed_ms: 1719480690000,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "49910760-5194-4cea-b25a-9e5f9b327336",
            price: 3401.57,
            price_sources: [],
            processed_ms: 1719488519447,
          },
        ],
        position_type: "FLAT",
        position_uuid: "11de8ec6-b6d1-4a73-b100-fadfe0719a90",
        return_at_close: 1.004918426829124,
        trade_pair: ["ETHUSD", "ETH/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 61532.0,
        close_ms: 1719568459332,
        current_return: 1.004192940258727,
        initial_entry_price: 61532.0,
        is_closed_position: true,
        miner_hotkey: "5Df8YED2EoxY65B2voeCHzY9rn1R76DXB8Cq9f62CsGVRoU5",
        net_leverage: 0.0,
        open_ms: 1719565091845,
        orders: [
          {
            leverage: 2.0,
            order_type: "LONG",
            order_uuid: "4ecb5ac1-5214-43da-b394-e94d3e53e6fc",
            price: 61532.0,
            price_sources: [],
            processed_ms: 1719565091332,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "06c67f0b-b4bd-47fb-962a-8b2d9373d574",
            price: 61661.0,
            price_sources: [],
            processed_ms: 1719568459332,
          },
        ],
        position_type: "FLAT",
        position_uuid: "c537fa98-7b59-45a3-b711-215903cfbacb",
        return_at_close: 1.0021845543782095,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 3356.054615384617,
        close_ms: 0,
        current_return: 0.9915647886281187,
        initial_entry_price: 3376.75,
        is_closed_position: false,
        miner_hotkey: "5Df8YED2EoxY65B2voeCHzY9rn1R76DXB8Cq9f62CsGVRoU5",
        net_leverage: 0.13,
        open_ms: 1719604146991,
        orders: [
          {
            leverage: 0.7,
            order_type: "LONG",
            order_uuid: "e2b8673f-d324-4165-81f3-d9b7ac6f737d",
            price: 3376.75,
            price_sources: [],
            processed_ms: 1719604145875,
          },
          {
            leverage: -0.57,
            order_type: "SHORT",
            order_uuid: "418e2c0f-cd71-490a-92aa-e16dcd8c364f",
            price: 3381.47,
            price_sources: [],
            processed_ms: 1719605499697,
          },
        ],
        position_type: "LONG",
        position_uuid: "5e1d6308-a6ca-42be-af82-1cab86b71af9",
        return_at_close: 0.9909351449873398,
        trade_pair: ["ETHUSD", "ETH/USD", 0.003, 0.001, 20],
      },
    ],
    thirty_day_returns: 1.0230019262483763,
  },
  "5DfTfq3oGN7SJGV32x2XKtJ2E6PrRnbArHeoAtKBNQmmZ5Ff": {
    all_time_returns: 1.0,
    n_positions: 0,
    percentage_profitable: 0.0,
    positions: [
      {
        average_entry_price: 64177.99999999999,
        close_ms: 1711254329863,
        current_return: 0.9988148742559756,
        initial_entry_price: 64178.0,
        is_closed_position: true,
        miner_hotkey: "5DfTfq3oGN7SJGV32x2XKtJ2E6PrRnbArHeoAtKBNQmmZ5Ff",
        net_leverage: 0.0,
        open_ms: 1711250754667,
        orders: [
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "da1f9f0e-4950-4ab4-9b40-e574e761e202",
            price: 64178.0,
            price_sources: [],
            processed_ms: 1711250754667,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "4a5f27e3-34ba-4a4c-8fa8-e1c6d4c10cef",
            price: 63924.47,
            price_sources: [],
            processed_ms: 1711254329863,
          },
        ],
        position_type: "FLAT",
        position_uuid: "c956630d-57ff-40a9-a608-6fbd1bda33a5",
        return_at_close: 0.998215585331422,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 63954.92,
        close_ms: 1711257347103,
        current_return: 1.0010115719009578,
        initial_entry_price: 63954.92,
        is_closed_position: true,
        miner_hotkey: "5DfTfq3oGN7SJGV32x2XKtJ2E6PrRnbArHeoAtKBNQmmZ5Ff",
        net_leverage: 0.0,
        open_ms: 1711254457101,
        orders: [
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "a6489e6d-d497-4ec1-92f5-43c4a3eb7408",
            price: 63954.92,
            price_sources: [],
            processed_ms: 1711254457100,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "a1b9dce5-0b5a-4662-8cf5-160c15528bba",
            price: 64170.57,
            price_sources: [],
            processed_ms: 1711257347103,
          },
        ],
        position_type: "FLAT",
        position_uuid: "d4d522ed-ea89-4654-a5c0-09a4db591c26",
        return_at_close: 1.0004109649578172,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 64224.08999999999,
        close_ms: 1711261003509,
        current_return: 1.00344010323852,
        initial_entry_price: 64224.09,
        is_closed_position: true,
        miner_hotkey: "5DfTfq3oGN7SJGV32x2XKtJ2E6PrRnbArHeoAtKBNQmmZ5Ff",
        net_leverage: 0.0,
        open_ms: 1711257433516,
        orders: [
          {
            leverage: 1.25,
            order_type: "LONG",
            order_uuid: "bf2058f2-b11b-4a59-8af3-f977857b1d8e",
            price: 64224.09,
            price_sources: [],
            processed_ms: 1711257433515,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "c1aa0245-2113-4ce1-b4c8-e09ca370ddda",
            price: 64400.84,
            price_sources: [],
            processed_ms: 1711261003509,
          },
        ],
        position_type: "FLAT",
        position_uuid: "30427e66-2c14-4ca1-944d-0e3346d40c6b",
        return_at_close: 1.0009315029804238,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 64339.81999999999,
        close_ms: 1711264688204,
        current_return: 0.996037640453455,
        initial_entry_price: 64339.82,
        is_closed_position: true,
        miner_hotkey: "5DfTfq3oGN7SJGV32x2XKtJ2E6PrRnbArHeoAtKBNQmmZ5Ff",
        net_leverage: 0.0,
        open_ms: 1711261107740,
        orders: [
          {
            leverage: 1.25,
            order_type: "LONG",
            order_uuid: "708fd80f-930f-4bd2-9b7b-36484d1527ff",
            price: 64339.82,
            price_sources: [],
            processed_ms: 1711261107739,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "16cad566-f753-46ad-ad8d-f9cfa59bed3c",
            price: 64135.87,
            price_sources: [],
            processed_ms: 1711264688204,
          },
        ],
        position_type: "FLAT",
        position_uuid: "125a3111-ab5d-4f6c-94bb-5451fae793d7",
        return_at_close: 0.9935475463523215,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 64438.43000000001,
        close_ms: 1711268387296,
        current_return: 1.0125893776799264,
        initial_entry_price: 64115.56,
        is_closed_position: true,
        miner_hotkey: "5DfTfq3oGN7SJGV32x2XKtJ2E6PrRnbArHeoAtKBNQmmZ5Ff",
        net_leverage: 0.0,
        open_ms: 1711264776670,
        orders: [
          {
            leverage: 1.25,
            order_type: "LONG",
            order_uuid: "d197bcac-0337-46d8-b0e2-4922978383ed",
            price: 64115.56,
            price_sources: [],
            processed_ms: 1711264776670,
          },
          {
            leverage: 1.25,
            order_type: "LONG",
            order_uuid: "4c34bb1d-929d-4af5-8d7e-dcaf18b7ab9d",
            price: 64761.3,
            price_sources: [],
            processed_ms: 1711268367213,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "d56edb73-6ebb-4b85-9f4c-865344072ec1",
            price: 64761.3,
            price_sources: [],
            processed_ms: 1711268387296,
          },
        ],
        position_type: "FLAT",
        position_uuid: "28b83c47-5f32-444d-9564-fcbf973e7107",
        return_at_close: 1.0075264307915268,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 64868.240000000005,
        close_ms: 1711275653608,
        current_return: 1.002886427934533,
        initial_entry_price: 64868.24,
        is_closed_position: true,
        miner_hotkey: "5DfTfq3oGN7SJGV32x2XKtJ2E6PrRnbArHeoAtKBNQmmZ5Ff",
        net_leverage: 0.0,
        open_ms: 1711272126825,
        orders: [
          {
            leverage: 1.25,
            order_type: "LONG",
            order_uuid: "7beb4299-0dc9-453f-b1d6-eb2f7cb895a7",
            price: 64868.24,
            price_sources: [],
            processed_ms: 1711272126824,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "893eb2f2-a1f8-4c94-9eaa-429f0664a6f5",
            price: 65018.03,
            price_sources: [],
            processed_ms: 1711275653608,
          },
        ],
        position_type: "FLAT",
        position_uuid: "e18393e9-bd3f-44d1-8732-21bb2ddb8115",
        return_at_close: 1.0003792118646968,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 66225.0,
        close_ms: 1711312258576,
        current_return: 1.0035128350320877,
        initial_entry_price: 66225.0,
        is_closed_position: true,
        miner_hotkey: "5DfTfq3oGN7SJGV32x2XKtJ2E6PrRnbArHeoAtKBNQmmZ5Ff",
        net_leverage: 0.0,
        open_ms: 1711311320820,
        orders: [
          {
            leverage: 1.25,
            order_type: "LONG",
            order_uuid: "c0c41a78-32bc-464f-b1d1-25d3953973ea",
            price: 66225.0,
            price_sources: [],
            processed_ms: 1711311320819,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "2b0c00fe-71a7-4a2d-a14d-0089ec3266c1",
            price: 66411.11,
            price_sources: [],
            processed_ms: 1711312258576,
          },
        ],
        position_type: "FLAT",
        position_uuid: "43496e18-9794-4889-99fd-e6fbc881602b",
        return_at_close: 1.0010040529445074,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 251567.2499999933,
        close_ms: 1711331395213,
        current_return: 1.0042884494901083,
        initial_entry_price: 67396.7,
        is_closed_position: true,
        miner_hotkey: "5DfTfq3oGN7SJGV32x2XKtJ2E6PrRnbArHeoAtKBNQmmZ5Ff",
        net_leverage: 0.0,
        open_ms: 1711322514847,
        orders: [
          {
            leverage: -1.25,
            order_type: "SHORT",
            order_uuid: "cbb72aee-e1e7-4102-aff0-8d47cc784ef7",
            price: 67396.7,
            price_sources: [],
            processed_ms: 1711322514846,
          },
          {
            leverage: 0.625,
            order_type: "LONG",
            order_uuid: "91a4e59a-c5e0-4670-aeea-b9ce26bab95b",
            price: 67244.52,
            price_sources: [],
            processed_ms: 1711323451200,
          },
          {
            leverage: 0.3125,
            order_type: "LONG",
            order_uuid: "7af71a54-6d87-43ab-af09-169724a0b924",
            price: 67327.32,
            price_sources: [],
            processed_ms: 1711324378899,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "1e70213c-f976-4c12-b6e5-cb0f63f6140d",
            price: 66855.72,
            price_sources: [],
            processed_ms: 1711325319178,
          },
          {
            leverage: 0.03125,
            order_type: "LONG",
            order_uuid: "cb3116f3-d7c5-448f-bae7-fdb168b19544",
            price: 66931.06,
            price_sources: [],
            processed_ms: 1711326316326,
          },
          {
            leverage: 0.025,
            order_type: "LONG",
            order_uuid: "6c628791-4fb0-45fa-a5a8-75e94c33f1b7",
            price: 66694.32,
            price_sources: [],
            processed_ms: 1711327178907,
          },
          {
            leverage: 0.0031249999999999993,
            order_type: "LONG",
            order_uuid: "e8f07e43-6c01-4db0-a90f-c30164af811b",
            price: 66587.11,
            price_sources: [],
            processed_ms: 1711328120346,
          },
          {
            leverage: 0.0015624999999999997,
            order_type: "LONG",
            order_uuid: "cfea0527-daa6-43b2-b31f-54d97e9f2bce",
            price: 66701.01,
            price_sources: [],
            processed_ms: 1711329047018,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "3c3f1ae5-41a9-4fc5-9467-deb48f01b8eb",
            price: 66589.75,
            price_sources: [],
            processed_ms: 1711331395213,
          },
        ],
        position_type: "FLAT",
        position_uuid: "ec1507ed-d219-4809-8657-6bac34518ad7",
        return_at_close: 1.0017777283663831,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 67044.93,
        close_ms: 1711342376933,
        current_return: 1.0080494528072443,
        initial_entry_price: 67044.93,
        is_closed_position: true,
        miner_hotkey: "5DfTfq3oGN7SJGV32x2XKtJ2E6PrRnbArHeoAtKBNQmmZ5Ff",
        net_leverage: 0.0,
        open_ms: 1711340533168,
        orders: [
          {
            leverage: 1.25,
            order_type: "LONG",
            order_uuid: "f062c17d-0d79-4943-828e-dbc32e240e0d",
            price: 67044.93,
            price_sources: [],
            processed_ms: 1711340533167,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "998c5503-b36b-4259-98d9-53dd7a416365",
            price: 67476.67,
            price_sources: [],
            processed_ms: 1711342376933,
          },
        ],
        position_type: "FLAT",
        position_uuid: "1a7a8f7f-d3cf-41b7-a515-495659aea2f3",
        return_at_close: 1.0055293291752263,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 66829.47,
        close_ms: 1711355205068,
        current_return: 1.004076233134873,
        initial_entry_price: 66829.47,
        is_closed_position: true,
        miner_hotkey: "5DfTfq3oGN7SJGV32x2XKtJ2E6PrRnbArHeoAtKBNQmmZ5Ff",
        net_leverage: 0.0,
        open_ms: 1711353366140,
        orders: [
          {
            leverage: 1.25,
            order_type: "LONG",
            order_uuid: "01e566d3-38cf-4087-995e-867deb57fc31",
            price: 66829.47,
            price_sources: [],
            processed_ms: 1711353366139,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "4e7fdcb9-30b6-48a6-958c-5c145020133d",
            price: 67047.4,
            price_sources: [],
            processed_ms: 1711355205068,
          },
        ],
        position_type: "FLAT",
        position_uuid: "1e6fb74c-a1c0-4ce8-bed6-d2dc9bb883a6",
        return_at_close: 1.0015660425520359,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 67220.6,
        close_ms: 1711364349261,
        current_return: 0.9959683117972762,
        initial_entry_price: 67220.6,
        is_closed_position: true,
        miner_hotkey: "5DfTfq3oGN7SJGV32x2XKtJ2E6PrRnbArHeoAtKBNQmmZ5Ff",
        net_leverage: 0.0,
        open_ms: 1711362516124,
        orders: [
          {
            leverage: 1.25,
            order_type: "LONG",
            order_uuid: "a907c9a8-320d-41a9-b4ce-308841f333d9",
            price: 67220.6,
            price_sources: [],
            processed_ms: 1711362516114,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "54ce2fc4-0e0a-42d6-ae36-7a94ca949972",
            price: 67003.79,
            price_sources: [],
            processed_ms: 1711364349261,
          },
        ],
        position_type: "FLAT",
        position_uuid: "0e4c2a67-5300-4e86-a47b-043c067e4e5b",
        return_at_close: 0.993478391017783,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 67166.96125,
        close_ms: 1711376072096,
        current_return: 1.0500370510519716,
        initial_entry_price: 67150.59,
        is_closed_position: true,
        miner_hotkey: "5DfTfq3oGN7SJGV32x2XKtJ2E6PrRnbArHeoAtKBNQmmZ5Ff",
        net_leverage: 0.0,
        open_ms: 1711369484137,
        orders: [
          {
            leverage: 1.25,
            order_type: "LONG",
            order_uuid: "621f60ad-06e8-46a8-bfc9-f94cf5244621",
            price: 67150.59,
            price_sources: [],
            processed_ms: 1711369484136,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "8463c061-44c7-4456-b2ba-712a1a4b704a",
            price: 67017.66,
            price_sources: [],
            processed_ms: 1711371347702,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "51bab536-4225-4731-907f-79b6be3997de",
            price: 66934.32,
            price_sources: [],
            processed_ms: 1711373147915,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "76029941-7ae5-4ba8-987e-2e7ff538e4ce",
            price: 67630.76,
            price_sources: [],
            processed_ms: 1711374984996,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "8b9f8840-31d6-4bd8-8443-c4a216326616",
            price: 68846.97,
            price_sources: [],
            processed_ms: 1711376072096,
          },
        ],
        position_type: "FLAT",
        position_uuid: "24a484c9-4d59-4335-9b0c-b355f1f24764",
        return_at_close: 1.0458369028477636,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 69141.81,
        close_ms: 1711376522429,
        current_return: 1.0,
        initial_entry_price: 69141.81,
        is_closed_position: true,
        miner_hotkey: "5DfTfq3oGN7SJGV32x2XKtJ2E6PrRnbArHeoAtKBNQmmZ5Ff",
        net_leverage: 0.0,
        open_ms: 1711376506389,
        orders: [
          {
            leverage: -1.25,
            order_type: "SHORT",
            order_uuid: "3a4d8dfc-f698-4dbb-a347-3d59212b6e34",
            price: 69141.81,
            price_sources: [],
            processed_ms: 1711376506387,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "fd234641-4079-4491-a965-1c4a09e0ad63",
            price: 69141.81,
            price_sources: [],
            processed_ms: 1711376522429,
          },
        ],
        position_type: "FLAT",
        position_uuid: "6c59f6b6-2c7a-4814-a727-d1057b29eaed",
        return_at_close: 0.9975,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 69669.66000000003,
        close_ms: 1711378166277,
        current_return: 1.0021516281228338,
        initial_entry_price: 69210.38,
        is_closed_position: true,
        miner_hotkey: "5DfTfq3oGN7SJGV32x2XKtJ2E6PrRnbArHeoAtKBNQmmZ5Ff",
        net_leverage: 0.0,
        open_ms: 1711376746209,
        orders: [
          {
            leverage: -1.25,
            order_type: "SHORT",
            order_uuid: "b44c1f0e-211f-4cad-8693-6b5545cab8e0",
            price: 69210.38,
            price_sources: [],
            processed_ms: 1711376746208,
          },
          {
            leverage: 1.0,
            order_type: "LONG",
            order_uuid: "8ca482b5-ac98-46fc-9ff3-710ddd8866cc",
            price: 69095.56,
            price_sources: [],
            processed_ms: 1711377878127,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "85d30ecd-42a1-42bd-bb95-b9ad8c388b92",
            price: 69074.0,
            price_sources: [],
            processed_ms: 1711378166277,
          },
        ],
        position_type: "FLAT",
        position_uuid: "5f2db27b-a92c-4d74-844c-383f6d54d8a5",
        return_at_close: 0.9996462490525267,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 64628.01999999992,
        close_ms: 1711391224390,
        current_return: 0.9888897365074873,
        initial_entry_price: 69639.55,
        is_closed_position: true,
        miner_hotkey: "5DfTfq3oGN7SJGV32x2XKtJ2E6PrRnbArHeoAtKBNQmmZ5Ff",
        net_leverage: 0.0,
        open_ms: 1711380326206,
        orders: [
          {
            leverage: -1.25,
            order_type: "SHORT",
            order_uuid: "363a497c-b584-4e69-b6c2-56e65a10383d",
            price: 69639.55,
            price_sources: [],
            processed_ms: 1711380326204,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "2ab03867-86aa-44aa-9c4d-b6e0cc84e18e",
            price: 69988.71,
            price_sources: [],
            processed_ms: 1711382167344,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "15d45b64-4ea0-45aa-a135-337ffa17ca0c",
            price: 69795.64,
            price_sources: [],
            processed_ms: 1711383971079,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "760f3035-8ced-4143-8ddc-47c1a34cce2c",
            price: 70225.75,
            price_sources: [],
            processed_ms: 1711385778031,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "71e4a93e-f59a-4491-9e51-4d030fd5a089",
            price: 70590.85,
            price_sources: [],
            processed_ms: 1711387596139,
          },
          {
            leverage: 0.125,
            order_type: "LONG",
            order_uuid: "d9193bcf-7e59-4d73-9517-66704f43aa23",
            price: 70565.58,
            price_sources: [],
            processed_ms: 1711389417085,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "4275da4d-a3a1-47b8-9e5b-8aaa53e6205c",
            price: 70817.73,
            price_sources: [],
            processed_ms: 1711391224390,
          },
        ],
        position_type: "FLAT",
        position_uuid: "ec951418-ce8c-42f7-b8d3-194e8b2ad13f",
        return_at_close: 0.9864175121662186,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: -540262.4938626544,
        close_ms: 1711456635406,
        current_return: 1.0155522706507507,
        initial_entry_price: 69870.55,
        is_closed_position: true,
        miner_hotkey: "5DfTfq3oGN7SJGV32x2XKtJ2E6PrRnbArHeoAtKBNQmmZ5Ff",
        net_leverage: 0.0,
        open_ms: 1711403945296,
        orders: [
          {
            leverage: 1.25,
            order_type: "LONG",
            order_uuid: "3ba484de-1726-41a3-a270-47b179b13534",
            price: 69870.55,
            price_sources: [],
            processed_ms: 1711403945294,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "a897323e-8338-42cb-b773-2373aab7645b",
            price: 70348.01,
            price_sources: [],
            processed_ms: 1711405761352,
          },
          {
            leverage: -0.75,
            order_type: "SHORT",
            order_uuid: "350ce576-1ca5-46aa-a174-58bcdc6da8fb",
            price: 70349.83,
            price_sources: [],
            processed_ms: 1711407570183,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "b94b6274-b4d4-41e5-bd19-a2773bc4d125",
            price: 70159.51,
            price_sources: [],
            processed_ms: 1711409388568,
          },
          {
            leverage: -0.5,
            order_type: "SHORT",
            order_uuid: "2c74a3fe-6bff-4f62-b4cd-bf29fc301349",
            price: 69944.77,
            price_sources: [],
            processed_ms: 1711411206497,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "ff2502f2-e119-4548-abeb-2fcea0521777",
            price: 69952.18,
            price_sources: [],
            processed_ms: 1711413048628,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "9ef6c615-0b42-4b12-981b-d8496fe09a54",
            price: 69901.51,
            price_sources: [],
            processed_ms: 1711414837018,
          },
          {
            leverage: -0.5,
            order_type: "SHORT",
            order_uuid: "5d80c2e3-c6b2-47ee-a28c-b712d095e088",
            price: 70283.8,
            price_sources: [],
            processed_ms: 1711416652677,
          },
          {
            leverage: -0.25,
            order_type: "SHORT",
            order_uuid: "a8faa047-ba5c-48bb-bb45-38d66b4e25e4",
            price: 70293.96,
            price_sources: [],
            processed_ms: 1711418475284,
          },
          {
            leverage: -0.125,
            order_type: "SHORT",
            order_uuid: "a7288e75-219e-435a-88b7-db4f29e54fee",
            price: 70252.49,
            price_sources: [],
            processed_ms: 1711420291162,
          },
          {
            leverage: -0.0625,
            order_type: "SHORT",
            order_uuid: "bdb95976-0093-4515-9267-89917d61178b",
            price: 70470.97,
            price_sources: [],
            processed_ms: 1711422112030,
          },
          {
            leverage: -0.03125,
            order_type: "SHORT",
            order_uuid: "7042d93e-b7f5-45f8-9f18-076047d09a16",
            price: 70492.12,
            price_sources: [],
            processed_ms: 1711423921526,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "4bb19584-a912-46d8-ba3b-e129102c79d7",
            price: 70429.14,
            price_sources: [],
            processed_ms: 1711425739498,
          },
          {
            leverage: -0.140625,
            order_type: "SHORT",
            order_uuid: "1a402535-ba6d-429a-91c5-b850efca777b",
            price: 70584.34,
            price_sources: [],
            processed_ms: 1711427556124,
          },
          {
            leverage: -0.0703125,
            order_type: "SHORT",
            order_uuid: "2140e4e3-fc99-4f6c-a816-6e5dbb3248ce",
            price: 70586.99,
            price_sources: [],
            processed_ms: 1711429374214,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "f3e2b682-3c45-4190-8ccf-ca65c50d0024",
            price: 70369.47,
            price_sources: [],
            processed_ms: 1711431193277,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "73e41187-2b91-46b9-bf9d-6806df3f8bfb",
            price: 70302.18,
            price_sources: [],
            processed_ms: 1711433012438,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "c39253c3-f87b-4ef6-a3ce-79ba6cb277cd",
            price: 70590.68,
            price_sources: [],
            processed_ms: 1711434822194,
          },
          {
            leverage: -0.41015625,
            order_type: "SHORT",
            order_uuid: "dd9ca254-5242-4f6c-9768-05c8975e283f",
            price: 70870.53,
            price_sources: [],
            processed_ms: 1711436655368,
          },
          {
            leverage: -0.205078125,
            order_type: "SHORT",
            order_uuid: "94eefdee-77ed-4c1a-8a26-a83a3b3c0828",
            price: 71430.83,
            price_sources: [],
            processed_ms: 1711438536439,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "d5717c4a-7ea3-43d3-827d-e4cef51d63a4",
            price: 70710.67,
            price_sources: [],
            processed_ms: 1711440278260,
          },
          {
            leverage: -0.2275390625,
            order_type: "SHORT",
            order_uuid: "5ec674ba-ca33-4ba7-b301-555506f67d3e",
            price: 70529.93,
            price_sources: [],
            processed_ms: 1711442091617,
          },
          {
            leverage: -0.11376953125,
            order_type: "SHORT",
            order_uuid: "4a5ecb55-e28e-4023-96b9-3c89c65938c3",
            price: 70689.56,
            price_sources: [],
            processed_ms: 1711443909919,
          },
          {
            leverage: -0.056884765625,
            order_type: "SHORT",
            order_uuid: "43b68b98-bac4-4a63-ac9b-a0707a73ded5",
            price: 71060.37,
            price_sources: [],
            processed_ms: 1711445760170,
          },
          {
            leverage: -0.0284423828125,
            order_type: "SHORT",
            order_uuid: "faf74059-3a7f-4f1b-953f-12eb64241805",
            price: 71098.74,
            price_sources: [],
            processed_ms: 1711447544039,
          },
          {
            leverage: -0.01422119140625,
            order_type: "SHORT",
            order_uuid: "e174c304-1c3e-4cdd-b41f-665c280602b4",
            price: 70917.61,
            price_sources: [],
            processed_ms: 1711449360289,
          },
          {
            leverage: -0.007110595703125,
            order_type: "SHORT",
            order_uuid: "2b0c5e50-3c6a-48c2-849d-461ae9c0b41b",
            price: 70880.84,
            price_sources: [],
            processed_ms: 1711451193111,
          },
          {
            leverage: -0.0035552978515625,
            order_type: "SHORT",
            order_uuid: "bc6e5d34-e5b0-45fe-a7a1-1e45c7dcbec9",
            price: 70657.18,
            price_sources: [],
            processed_ms: 1711453078468,
          },
          {
            leverage: -0.00177764892578125,
            order_type: "SHORT",
            order_uuid: "2ef9114b-2ed7-4930-a343-88ba75c2c23d",
            price: 70649.11,
            price_sources: [],
            processed_ms: 1711454807285,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "f16c8651-b358-44d2-8cfc-aa09ff649d41",
            price: 71020.02,
            price_sources: [],
            processed_ms: 1711456635406,
          },
        ],
        position_type: "FLAT",
        position_uuid: "06bf129f-a900-40cd-a833-be5c9dd02e36",
        return_at_close: 1.0125056138387984,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: -90262.93000000078,
        close_ms: 1711474809088,
        current_return: 0.9942463574085436,
        initial_entry_price: 69722.34,
        is_closed_position: true,
        miner_hotkey: "5DfTfq3oGN7SJGV32x2XKtJ2E6PrRnbArHeoAtKBNQmmZ5Ff",
        net_leverage: 0.0,
        open_ms: 1711463904985,
        orders: [
          {
            leverage: -1.25,
            order_type: "SHORT",
            order_uuid: "2f6495d4-3d03-4f82-b1d0-6a9188ecfe2f",
            price: 69722.34,
            price_sources: [],
            processed_ms: 1711463904983,
          },
          {
            leverage: 0.625,
            order_type: "LONG",
            order_uuid: "0cbe6ad0-2b08-4f9e-8726-77e94b161131",
            price: 69889.09,
            price_sources: [],
            processed_ms: 1711465725166,
          },
          {
            leverage: 0.3125,
            order_type: "LONG",
            order_uuid: "606bb322-2502-4dbd-b733-5f96e5abf18c",
            price: 70325.59,
            price_sources: [],
            processed_ms: 1711467564656,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "27891d0d-e948-464f-a7ea-8e054be1d398",
            price: 70154.98,
            price_sources: [],
            processed_ms: 1711469363503,
          },
          {
            leverage: 0.05,
            order_type: "LONG",
            order_uuid: "7fc8bb90-1c0d-42e5-94b9-9bbea5b2876e",
            price: 69715.09,
            price_sources: [],
            processed_ms: 1711471193440,
          },
          {
            leverage: 0.009999999999999998,
            order_type: "LONG",
            order_uuid: "62f5c28f-e433-4ddc-9454-16ad89678775",
            price: 69665.47,
            price_sources: [],
            processed_ms: 1711472989704,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "ccf566d0-ae6a-4c01-bb0d-2a264f3c6362",
            price: 70200.04,
            price_sources: [],
            processed_ms: 1711474809088,
          },
        ],
        position_type: "FLAT",
        position_uuid: "7d921ea1-3487-4a26-bfc0-0cbc3e52dbb6",
        return_at_close: 0.9917607415150224,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: -279196.61000001547,
        close_ms: 1711550391030,
        current_return: 1.031089615539565,
        initial_entry_price: 70197.34,
        is_closed_position: true,
        miner_hotkey: "5DfTfq3oGN7SJGV32x2XKtJ2E6PrRnbArHeoAtKBNQmmZ5Ff",
        net_leverage: 0.0,
        open_ms: 1711476623394,
        orders: [
          {
            leverage: 1.25,
            order_type: "LONG",
            order_uuid: "57c25183-46fc-4873-9aa8-7bcdfad4e98b",
            price: 70197.34,
            price_sources: [],
            processed_ms: 1711476623392,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "e6280038-3f6a-4181-a68b-056040489580",
            price: 70303.61,
            price_sources: [],
            processed_ms: 1711478456548,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "79e26a2f-2136-4a4a-bec1-c7f1db56bd67",
            price: 69786.81,
            price_sources: [],
            processed_ms: 1711480275745,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "a4177697-80cb-4664-85b3-0b73f5d61a75",
            price: 69903.69,
            price_sources: [],
            processed_ms: 1711482099224,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "a6413ba6-2d83-4e17-b252-77402a574ba1",
            price: 69770.56,
            price_sources: [],
            processed_ms: 1711483927669,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "40bae8ed-5596-43cd-99f3-25d239ce3b3a",
            price: 69792.43,
            price_sources: [],
            processed_ms: 1711485745446,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "53b26791-5538-49b9-aa81-0c2ab9820652",
            price: 69850.21,
            price_sources: [],
            processed_ms: 1711487539559,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "b0746a19-1ff9-481c-b65a-800cfe2adca2",
            price: 70074.94,
            price_sources: [],
            processed_ms: 1711489351182,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "d299ed37-e3d2-4a2d-bea3-14fe74a86d1e",
            price: 69932.97,
            price_sources: [],
            processed_ms: 1711491167761,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "aee7c18e-f850-44dc-9b3e-865181f95793",
            price: 69926.55,
            price_sources: [],
            processed_ms: 1711492997754,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "8aae1aff-c7c9-4a74-9168-8660b07c829e",
            price: 70242.16,
            price_sources: [],
            processed_ms: 1711494811476,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "9d8132aa-b135-4f47-bc87-3486a3ddcc56",
            price: 70031.97,
            price_sources: [],
            processed_ms: 1711496622617,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "8f88506f-a3ab-4ce7-8f60-97a260f18765",
            price: 69831.14,
            price_sources: [],
            processed_ms: 1711498442548,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "333e667a-5df5-490f-a7bc-59070e726423",
            price: 70328.8,
            price_sources: [],
            processed_ms: 1711500256131,
          },
          {
            leverage: -2.25,
            order_type: "SHORT",
            order_uuid: "ac2cfc5f-5806-43ad-9d4d-0697823f96f4",
            price: 70694.78,
            price_sources: [],
            processed_ms: 1711502072274,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "6ec78f39-5a5d-4523-9ce6-e30470130e25",
            price: 70507.01,
            price_sources: [],
            processed_ms: 1711503893162,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "35b84ab0-f43b-4706-8d87-18a3ec3d79f8",
            price: 70223.72,
            price_sources: [],
            processed_ms: 1711505701345,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "6ad7c3d1-564c-424e-80d3-a5a1c839a549",
            price: 70368.4,
            price_sources: [],
            processed_ms: 1711507521047,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "7b390c7e-fdfd-4ac8-b387-95fcb46ea196",
            price: 70528.79,
            price_sources: [],
            processed_ms: 1711509337417,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "624ea34b-db0d-40c6-ada0-8c1d0df316b0",
            price: 70382.5,
            price_sources: [],
            processed_ms: 1711511158977,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "ca258a2c-9190-4a0e-a262-6d89d8839890",
            price: 70266.95,
            price_sources: [],
            processed_ms: 1711513031305,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "b95a95d5-72ff-45da-8e02-0b9d4ed58f34",
            price: 70334.8,
            price_sources: [],
            processed_ms: 1711514792139,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "6e1ea673-2e18-4d02-9891-6678401ede24",
            price: 70402.94,
            price_sources: [],
            processed_ms: 1711516605324,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "ba62e601-d49c-4902-b994-d3f2f471012d",
            price: 70419.91,
            price_sources: [],
            processed_ms: 1711518494101,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "03869437-3732-491c-b204-53a68f0c538b",
            price: 70337.69,
            price_sources: [],
            processed_ms: 1711520310618,
          },
          {
            leverage: -2.5,
            order_type: "SHORT",
            order_uuid: "12c1d921-5a52-4410-8838-b8224b4f7b44",
            price: 70215.66,
            price_sources: [],
            processed_ms: 1711522064512,
          },
          {
            leverage: -1.25,
            order_type: "SHORT",
            order_uuid: "6c651ae4-25b5-472f-9c66-7ae15591bd8a",
            price: 70061.98,
            price_sources: [],
            processed_ms: 1711523760362,
          },
          {
            leverage: -0.625,
            order_type: "SHORT",
            order_uuid: "34426878-c693-41e7-b7e3-18d796707b7a",
            price: 69711.19,
            price_sources: [],
            processed_ms: 1711526363129,
          },
          {
            leverage: -0.3125,
            order_type: "SHORT",
            order_uuid: "1dca649c-c63b-471c-b959-ddf1737e74e2",
            price: 69867.58,
            price_sources: [],
            processed_ms: 1711528210064,
          },
          {
            leverage: 0.375,
            order_type: "LONG",
            order_uuid: "80faf796-00a9-4321-8c7b-64946c78907c",
            price: 69524.89,
            price_sources: [],
            processed_ms: 1711530008171,
          },
          {
            leverage: 0.375,
            order_type: "LONG",
            order_uuid: "71ba6256-7585-4829-8a0e-0448f84c0251",
            price: 69844.71,
            price_sources: [],
            processed_ms: 1711531822310,
          },
          {
            leverage: 0.375,
            order_type: "LONG",
            order_uuid: "3963a552-d154-4d39-af01-830a158f550a",
            price: 70023.9,
            price_sources: [],
            processed_ms: 1711533639497,
          },
          {
            leverage: 0.375,
            order_type: "LONG",
            order_uuid: "64972ae6-772d-4767-862f-28dc9f978fa4",
            price: 70189.2,
            price_sources: [],
            processed_ms: 1711535961921,
          },
          {
            leverage: -0.90625,
            order_type: "SHORT",
            order_uuid: "105b0cd6-976b-4a57-8476-f1fb5936fcb8",
            price: 70227.45,
            price_sources: [],
            processed_ms: 1711539541362,
          },
          {
            leverage: 0.375,
            order_type: "LONG",
            order_uuid: "b5204f34-bd23-49e6-95c7-bdf81407f379",
            price: 70196.5,
            price_sources: [],
            processed_ms: 1711543152589,
          },
          {
            leverage: -1.025,
            order_type: "SHORT",
            order_uuid: "dba5190a-e13c-46bb-803f-ae7397ffa874",
            price: 71043.29,
            price_sources: [],
            processed_ms: 1711546769186,
          },
          {
            leverage: -0.12812500000000004,
            order_type: "SHORT",
            order_uuid: "209f7a2b-9985-4b90-8191-d6323451b0a9",
            price: 69988.72,
            price_sources: [],
            processed_ms: 1711550391030,
          },
        ],
        position_type: "FLAT",
        position_uuid: "4c573a88-900f-4faf-9d59-ec32df769cdf",
        return_at_close: 1.0212942641919391,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 69211.93666666666,
        close_ms: 1711561240341,
        current_return: 1.0002940890862062,
        initial_entry_price: 69247.14,
        is_closed_position: true,
        miner_hotkey: "5DfTfq3oGN7SJGV32x2XKtJ2E6PrRnbArHeoAtKBNQmmZ5Ff",
        net_leverage: 0.0,
        open_ms: 1711554009025,
        orders: [
          {
            leverage: -0.06406250000000002,
            order_type: "SHORT",
            order_uuid: "355275b4-c0de-4478-a5de-bb65dc46867c",
            price: 69247.14,
            price_sources: [],
            processed_ms: 1711554009023,
          },
          {
            leverage: -0.03203125000000001,
            order_type: "SHORT",
            order_uuid: "161b54ba-b315-4858-ae88-4bb4529d4270",
            price: 69141.53,
            price_sources: [],
            processed_ms: 1711557621537,
          },
          {
            leverage: 0.375,
            order_type: "LONG",
            order_uuid: "f1bfebbd-b2b3-4a65-b581-cdf186d53dc9",
            price: 69000.01,
            price_sources: [],
            processed_ms: 1711561240341,
          },
        ],
        position_type: "FLAT",
        position_uuid: "7b9291a1-cf27-4f50-a3ee-01577537bf2e",
        return_at_close: 1.00010184506596,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 65124.295493948775,
        close_ms: 1711618499773,
        current_return: 1.0280067375775481,
        initial_entry_price: 68653.39,
        is_closed_position: true,
        miner_hotkey: "5DfTfq3oGN7SJGV32x2XKtJ2E6PrRnbArHeoAtKBNQmmZ5Ff",
        net_leverage: 0.0,
        open_ms: 1711564849065,
        orders: [
          {
            leverage: 0.37499999999999994,
            order_type: "LONG",
            order_uuid: "0ab000d4-d0fa-4f65-be16-119ed6fb15e8",
            price: 68653.39,
            price_sources: [],
            processed_ms: 1711564847651,
          },
          {
            leverage: 0.375,
            order_type: "LONG",
            order_uuid: "6dbc8f78-44a9-4b48-b2d8-5fe7ea71193c",
            price: 68525.23,
            price_sources: [],
            processed_ms: 1711568476313,
          },
          {
            leverage: 0.375,
            order_type: "LONG",
            order_uuid: "ffa1cda1-cf44-4ff2-be62-95a950cb99ab",
            price: 68911.61,
            price_sources: [],
            processed_ms: 1711572102020,
          },
          {
            leverage: -0.766015625,
            order_type: "SHORT",
            order_uuid: "f3fc4b7e-9f4c-4d22-a183-0cf8c24953bd",
            price: 69176.48,
            price_sources: [],
            processed_ms: 1711575714665,
          },
          {
            leverage: 0.3750000000000001,
            order_type: "LONG",
            order_uuid: "15bbf286-7c84-4187-988c-c4726e3fa38c",
            price: 69308.71,
            price_sources: [],
            processed_ms: 1711579362257,
          },
          {
            leverage: 0.375,
            order_type: "LONG",
            order_uuid: "021b8a04-09c2-4fda-9abf-2512ad61514e",
            price: 69120.58,
            price_sources: [],
            processed_ms: 1711582955301,
          },
          {
            leverage: 0.375,
            order_type: "LONG",
            order_uuid: "0d0775ed-bedc-48d9-8891-a8f7ad00a159",
            price: 69506.93,
            price_sources: [],
            processed_ms: 1711586570089,
          },
          {
            leverage: 0.375,
            order_type: "LONG",
            order_uuid: "dd68af84-f81f-4f72-b2d4-1658764fdc08",
            price: 69490.57,
            price_sources: [],
            processed_ms: 1711590194029,
          },
          {
            leverage: 0.375,
            order_type: "LONG",
            order_uuid: "ddc7b2df-67a8-41be-8497-691a7dc3ad19",
            price: 69058.39,
            price_sources: [],
            processed_ms: 1711593814928,
          },
          {
            leverage: 0.375,
            order_type: "LONG",
            order_uuid: "b129ef20-bea0-436e-9320-4e8e08f00c83",
            price: 69080.04,
            price_sources: [],
            processed_ms: 1711597425068,
          },
          {
            leverage: -1.5080078125,
            order_type: "SHORT",
            order_uuid: "a9e5a668-0a18-4766-81fd-0d432525aa16",
            price: 69657.73,
            price_sources: [],
            processed_ms: 1711601490000,
          },
          {
            leverage: -0.75400390625,
            order_type: "SHORT",
            order_uuid: "928f87c3-336b-4277-b14d-7eedd8a9c969",
            price: 69614.73,
            price_sources: [],
            processed_ms: 1711603299924,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "58517b37-7570-4f78-9f93-28a5e496f238",
            price: 70665.82,
            price_sources: [],
            processed_ms: 1711618499773,
          },
        ],
        position_type: "FLAT",
        position_uuid: "7f72de6d-1f6e-4486-91dd-7c7427086818",
        return_at_close: 1.022642630546079,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 70841.35,
        close_ms: 1711633321418,
        current_return: 1.0020345462078295,
        initial_entry_price: 70841.35,
        is_closed_position: true,
        miner_hotkey: "5DfTfq3oGN7SJGV32x2XKtJ2E6PrRnbArHeoAtKBNQmmZ5Ff",
        net_leverage: 0.0,
        open_ms: 1711633166226,
        orders: [
          {
            leverage: -3.5,
            order_type: "SHORT",
            order_uuid: "92c405a8-265b-4711-8125-e06c2bfd874b",
            price: 70841.35,
            price_sources: [],
            processed_ms: 1711633166223,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "ab68df54-c9e4-467f-a0b4-f52f46807cec",
            price: 70800.17,
            price_sources: [],
            processed_ms: 1711633321418,
          },
        ],
        position_type: "FLAT",
        position_uuid: "0b0db78b-b35a-427a-b43f-c81f9ce07aa0",
        return_at_close: 0.9950203043843747,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 71111.44,
        close_ms: 1711635150226,
        current_return: 1.0168853984675323,
        initial_entry_price: 71111.44,
        is_closed_position: true,
        miner_hotkey: "5DfTfq3oGN7SJGV32x2XKtJ2E6PrRnbArHeoAtKBNQmmZ5Ff",
        net_leverage: 0.0,
        open_ms: 1711634998477,
        orders: [
          {
            leverage: -3.5,
            order_type: "SHORT",
            order_uuid: "d9506d64-4198-4adf-a190-77c09b1c2529",
            price: 71111.44,
            price_sources: [],
            processed_ms: 1711634998474,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "fc5360fa-e13c-46f9-93af-5f1272ac0f08",
            price: 70768.37,
            price_sources: [],
            processed_ms: 1711635150226,
          },
        ],
        position_type: "FLAT",
        position_uuid: "331e7751-0cc2-49c8-a159-79bf623588fa",
        return_at_close: 1.0097672006782596,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 71456.4,
        close_ms: 1711636960623,
        current_return: 1.0069915360975359,
        initial_entry_price: 71456.4,
        is_closed_position: true,
        miner_hotkey: "5DfTfq3oGN7SJGV32x2XKtJ2E6PrRnbArHeoAtKBNQmmZ5Ff",
        net_leverage: 0.0,
        open_ms: 1711636815295,
        orders: [
          {
            leverage: -3.5,
            order_type: "SHORT",
            order_uuid: "256e90ba-1622-4453-9c0a-d9c6275fe19d",
            price: 71456.4,
            price_sources: [],
            processed_ms: 1711636815292,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "37fe0643-9889-4923-ab6c-1fa182afa4ef",
            price: 71313.66,
            price_sources: [],
            processed_ms: 1711636960623,
          },
        ],
        position_type: "FLAT",
        position_uuid: "5382a5f6-e33b-4b84-9acd-46dcdc4488f7",
        return_at_close: 0.9999425953448531,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 71137.37285714282,
        close_ms: 1711684017554,
        current_return: 1.0020189350418953,
        initial_entry_price: 70787.01,
        is_closed_position: true,
        miner_hotkey: "5DfTfq3oGN7SJGV32x2XKtJ2E6PrRnbArHeoAtKBNQmmZ5Ff",
        net_leverage: 0.0,
        open_ms: 1711662268119,
        orders: [
          {
            leverage: -5.0,
            order_type: "SHORT",
            order_uuid: "d6ffca1e-895b-45c2-bb1f-108b21fb9fa9",
            price: 70787.01,
            price_sources: [],
            processed_ms: 1711662268116,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "5fa6752f-cb0f-4f0b-bff4-257cc2305cb4",
            price: 70840.93,
            price_sources: [],
            processed_ms: 1711665897308,
          },
          {
            leverage: 1.75,
            order_type: "LONG",
            order_uuid: "1a8e4f76-a54c-4fc7-980b-093673e7ec4b",
            price: 70722.04,
            price_sources: [],
            processed_ms: 1711669504296,
          },
          {
            leverage: 0.875,
            order_type: "LONG",
            order_uuid: "6993a627-cb76-441a-b3e0-6fc5afb00bfb",
            price: 70706.84,
            price_sources: [],
            processed_ms: 1711673122449,
          },
          {
            leverage: 0.4375,
            order_type: "LONG",
            order_uuid: "bdaf2419-1b8d-48e5-9ec9-f46470e65639",
            price: 70908.11,
            price_sources: [],
            processed_ms: 1711676738051,
          },
          {
            leverage: 0.21875,
            order_type: "LONG",
            order_uuid: "58822ceb-fa00-42cd-abb7-5c30ef394a04",
            price: 70665.15,
            price_sources: [],
            processed_ms: 1711680367259,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "5d43e47f-3a6e-4c00-a3f0-7125293180c1",
            price: 70484.05,
            price_sources: [],
            processed_ms: 1711684017554,
          },
        ],
        position_type: "FLAT",
        position_uuid: "f145c035-5076-45b4-8269-e73009e847d1",
        return_at_close: 0.9919987456914764,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 70432.515,
        close_ms: 1711697709094,
        current_return: 0.9624494624992801,
        initial_entry_price: 70457.58,
        is_closed_position: true,
        miner_hotkey: "5DfTfq3oGN7SJGV32x2XKtJ2E6PrRnbArHeoAtKBNQmmZ5Ff",
        net_leverage: 0.0,
        open_ms: 1711687594121,
        orders: [
          {
            leverage: 5.0,
            order_type: "LONG",
            order_uuid: "f564f4cf-763a-4171-a22e-565e7cfc41fc",
            price: 70457.58,
            price_sources: [],
            processed_ms: 1711687594117,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "612415fb-b7d6-41da-8523-d7e0ac32008b",
            price: 70308.54,
            price_sources: [],
            processed_ms: 1711691215730,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "b24e1826-d3ba-403b-85e2-c44f121f38d0",
            price: 70472.94,
            price_sources: [],
            processed_ms: 1711694832014,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "7df7c8fc-65d7-4c82-8fdd-6e51d21fdb0e",
            price: 70101.8,
            price_sources: [],
            processed_ms: 1711697709094,
          },
        ],
        position_type: "FLAT",
        position_uuid: "f7c235b3-16e6-4936-9d58-32d03a126356",
        return_at_close: 0.9470502710992916,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 69983.73076923077,
        close_ms: 1711705527136,
        current_return: 0.9658030538794428,
        initial_entry_price: 69991.63,
        is_closed_position: true,
        miner_hotkey: "5DfTfq3oGN7SJGV32x2XKtJ2E6PrRnbArHeoAtKBNQmmZ5Ff",
        net_leverage: 0.0,
        open_ms: 1711698450627,
        orders: [
          {
            leverage: 5.0,
            order_type: "LONG",
            order_uuid: "56687dee-cd0d-4d1d-a214-ec24de1b06b5",
            price: 69991.63,
            price_sources: [],
            processed_ms: 1711698450622,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "38a8699b-c97c-4eaf-9f85-57c1f4771985",
            price: 69957.4,
            price_sources: [],
            processed_ms: 1711702069496,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "6ecb65d3-137f-4a1a-861f-5f96c73ca57d",
            price: 69615.5,
            price_sources: [],
            processed_ms: 1711705527136,
          },
        ],
        position_type: "FLAT",
        position_uuid: "bb5ceb90-1272-4ae3-8ec7-4994170f16a1",
        return_at_close: 0.95324761417901,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 60352.29132231354,
        close_ms: 1711759993941,
        current_return: 1.0325194289934676,
        initial_entry_price: 69763.65,
        is_closed_position: true,
        miner_hotkey: "5DfTfq3oGN7SJGV32x2XKtJ2E6PrRnbArHeoAtKBNQmmZ5Ff",
        net_leverage: 0.0,
        open_ms: 1711705693956,
        orders: [
          {
            leverage: 5.0,
            order_type: "LONG",
            order_uuid: "0b3a9910-3995-4abc-ab14-e8d61368b6d8",
            price: 69763.65,
            price_sources: [],
            processed_ms: 1711705693953,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "aac624d5-5347-4693-b69e-bd6ec37861f7",
            price: 69927.07,
            price_sources: [],
            processed_ms: 1711709310260,
          },
          {
            leverage: -3.25,
            order_type: "SHORT",
            order_uuid: "a8a4a913-bbcc-4b1d-9dd7-94a3bb906a8c",
            price: 70159.13,
            price_sources: [],
            processed_ms: 1711712927638,
          },
          {
            leverage: -1.625,
            order_type: "SHORT",
            order_uuid: "91ccaf97-d4ea-47b2-9362-5559899ab65d",
            price: 70114.12,
            price_sources: [],
            processed_ms: 1711716558052,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "adb35744-754d-46cf-abed-945ba7f51939",
            price: 70453.72,
            price_sources: [],
            processed_ms: 1711720215139,
          },
          {
            leverage: -1.5625,
            order_type: "SHORT",
            order_uuid: "935a13b6-a5a6-44c7-8aaf-e63324ada939",
            price: 70374.53,
            price_sources: [],
            processed_ms: 1711723796013,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "2503a16f-c872-4651-a3ce-09179b57ff0c",
            price: 69463.82,
            price_sources: [],
            processed_ms: 1711727428008,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "d6953b7b-f090-45dc-9c35-2858822641f3",
            price: 69473.17,
            price_sources: [],
            processed_ms: 1711731038845,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "ad7b39fe-c72f-4f58-b4f6-143e1458e9b9",
            price: 69460.21,
            price_sources: [],
            processed_ms: 1711734656609,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "7caa7230-9f2e-4bd4-8dd5-3a3aa04814dc",
            price: 69449.46,
            price_sources: [],
            processed_ms: 1711738273765,
          },
          {
            leverage: -3.78125,
            order_type: "SHORT",
            order_uuid: "e299cc0e-bb1d-4207-8fae-b466b56cd1d4",
            price: 69595.32,
            price_sources: [],
            processed_ms: 1711741895098,
          },
          {
            leverage: -1.890625,
            order_type: "SHORT",
            order_uuid: "22ce96bc-5039-4c3d-b27c-11e97dfbd32a",
            price: 69613.6,
            price_sources: [],
            processed_ms: 1711745508773,
          },
          {
            leverage: -0.9453125,
            order_type: "SHORT",
            order_uuid: "b72d63ce-fb97-454f-9c7f-481dff373c9e",
            price: 69508.8,
            price_sources: [],
            processed_ms: 1711749135423,
          },
          {
            leverage: -0.47265625,
            order_type: "SHORT",
            order_uuid: "f7493a68-2850-49f3-9ac8-ffd39d595326",
            price: 69796.85,
            price_sources: [],
            processed_ms: 1711752747988,
          },
          {
            leverage: -0.236328125,
            order_type: "SHORT",
            order_uuid: "402311de-bac4-4a22-af8b-127c8a9117a9",
            price: 69884.57,
            price_sources: [],
            processed_ms: 1711756356444,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "ccbef18d-6f62-43e5-9ff2-b771d138f94f",
            price: 69951.97,
            price_sources: [],
            processed_ms: 1711759993941,
          },
        ],
        position_type: "FLAT",
        position_uuid: "faf5b9ba-4cc2-4a55-a071-2067b4bf4d20",
        return_at_close: 1.0169025726299414,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 68625.42000000007,
        close_ms: 1711788934683,
        current_return: 1.009621780677843,
        initial_entry_price: 70055.12,
        is_closed_position: true,
        miner_hotkey: "5DfTfq3oGN7SJGV32x2XKtJ2E6PrRnbArHeoAtKBNQmmZ5Ff",
        net_leverage: 0.0,
        open_ms: 1711763615128,
        orders: [
          {
            leverage: 5.0,
            order_type: "LONG",
            order_uuid: "579a73a2-d2f1-4e94-99c3-4ba8dcbc0b9a",
            price: 70055.12,
            price_sources: [],
            processed_ms: 1711763615125,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "915363f6-8ae3-4cb2-acb9-5cb4c43ada17",
            price: 69914.85,
            price_sources: [],
            processed_ms: 1711767225577,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "995f7275-8cfc-45a7-aab3-a45a6d698550",
            price: 69834.93,
            price_sources: [],
            processed_ms: 1711770850846,
          },
          {
            leverage: -4.0,
            order_type: "SHORT",
            order_uuid: "82652d70-7b96-4f9d-822f-7a22277412d5",
            price: 70108.04,
            price_sources: [],
            processed_ms: 1711774463426,
          },
          {
            leverage: -2.0,
            order_type: "SHORT",
            order_uuid: "8ef8c8d4-3255-479b-9cd0-2729a715a266",
            price: 70063.19,
            price_sources: [],
            processed_ms: 1711778079898,
          },
          {
            leverage: -1.0,
            order_type: "SHORT",
            order_uuid: "e30dcd41-525b-4081-b560-60c2551b055b",
            price: 70042.96,
            price_sources: [],
            processed_ms: 1711781693702,
          },
          {
            leverage: -0.5,
            order_type: "SHORT",
            order_uuid: "ba2ed36a-536d-4d3f-8f59-f298afe8ebcb",
            price: 69972.12,
            price_sources: [],
            processed_ms: 1711785319867,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "7fd84ffc-9b98-4b2f-bfce-a1ef79438025",
            price: 69973.53,
            price_sources: [],
            processed_ms: 1711788934683,
          },
        ],
        position_type: "FLAT",
        position_uuid: "5c2315b9-ab96-4578-867c-a73366cdec62",
        return_at_close: 0.9934678321869974,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 69560.64285714288,
        close_ms: 1711807030952,
        current_return: 0.9922737029522734,
        initial_entry_price: 70049.08,
        is_closed_position: true,
        miner_hotkey: "5DfTfq3oGN7SJGV32x2XKtJ2E6PrRnbArHeoAtKBNQmmZ5Ff",
        net_leverage: 0.0,
        open_ms: 1711792555318,
        orders: [
          {
            leverage: -5.0,
            order_type: "SHORT",
            order_uuid: "00b1e557-9505-48c0-a83d-ececffdd7bc1",
            price: 70049.08,
            price_sources: [],
            processed_ms: 1711792555315,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "7cb348c7-6bb2-4b98-81b8-f8aac41dd6e6",
            price: 70078.98,
            price_sources: [],
            processed_ms: 1711796181109,
          },
          {
            leverage: 1.75,
            order_type: "LONG",
            order_uuid: "3bcc192c-fd52-4eec-8e20-0cc7d4c7df20",
            price: 70185.89,
            price_sources: [],
            processed_ms: 1711799796114,
          },
          {
            leverage: 0.875,
            order_type: "LONG",
            order_uuid: "3077d6ac-2635-4cf3-af9a-04751a9526ad",
            price: 70212.64,
            price_sources: [],
            processed_ms: 1711803407287,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "f60a2768-5d3c-4b24-9107-401150886c98",
            price: 70179.18,
            price_sources: [],
            processed_ms: 1711807030952,
          },
        ],
        position_type: "FLAT",
        position_uuid: "b26717e0-c042-45e3-a957-5c640fbcf511",
        return_at_close: 0.9823509659227506,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 70763.05411764719,
        close_ms: 1711839613383,
        current_return: 0.9961575102299896,
        initial_entry_price: 70097.82,
        is_closed_position: true,
        miner_hotkey: "5DfTfq3oGN7SJGV32x2XKtJ2E6PrRnbArHeoAtKBNQmmZ5Ff",
        net_leverage: 0.0,
        open_ms: 1711810652934,
        orders: [
          {
            leverage: 5.0,
            order_type: "LONG",
            order_uuid: "e1c985ec-5081-4727-85de-01fe91479611",
            price: 70097.82,
            price_sources: [],
            processed_ms: 1711810652930,
          },
          {
            leverage: -2.5,
            order_type: "SHORT",
            order_uuid: "94d83cb7-d4f2-43d7-917a-f786af4a07e1",
            price: 70053.95,
            price_sources: [],
            processed_ms: 1711814269855,
          },
          {
            leverage: -1.25,
            order_type: "SHORT",
            order_uuid: "32f78fc4-6783-4f23-82a7-3aeb3007986f",
            price: 70247.21,
            price_sources: [],
            processed_ms: 1711817892928,
          },
          {
            leverage: -0.625,
            order_type: "SHORT",
            order_uuid: "780333ab-0897-4506-946a-f3ded97a1211",
            price: 70108.35,
            price_sources: [],
            processed_ms: 1711821515933,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "8e5fb5f5-010e-44f5-85a8-87b1c6312ae5",
            price: 70015.97,
            price_sources: [],
            processed_ms: 1711825138672,
          },
          {
            leverage: -1.0625,
            order_type: "SHORT",
            order_uuid: "c2253977-3100-4a13-ba5f-53188284478d",
            price: 69930.0,
            price_sources: [],
            processed_ms: 1711828765409,
          },
          {
            leverage: -0.53125,
            order_type: "SHORT",
            order_uuid: "3ecee0fe-7edb-4330-a12f-29708bdb54c1",
            price: 69864.83,
            price_sources: [],
            processed_ms: 1711832368086,
          },
          {
            leverage: -0.265625,
            order_type: "SHORT",
            order_uuid: "295eb796-c097-4779-a3dc-0c61439f265b",
            price: 69792.74,
            price_sources: [],
            processed_ms: 1711835994261,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "6938f625-39bf-43c1-bfab-958504f74d49",
            price: 69749.03,
            price_sources: [],
            processed_ms: 1711839613383,
          },
        ],
        position_type: "FLAT",
        position_uuid: "b903a3b7-bfb3-4ac4-ad30-cb22b7570997",
        return_at_close: 0.9861959351276897,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 69443.94000000012,
        close_ms: 1711890278460,
        current_return: 0.9919917459962297,
        initial_entry_price: 70254.39,
        is_closed_position: true,
        miner_hotkey: "5DfTfq3oGN7SJGV32x2XKtJ2E6PrRnbArHeoAtKBNQmmZ5Ff",
        net_leverage: 0.0,
        open_ms: 1711875800085,
        orders: [
          {
            leverage: -5.0,
            order_type: "SHORT",
            order_uuid: "0bd8d4b8-64c2-4be3-9053-e9db765e647f",
            price: 70254.39,
            price_sources: [],
            processed_ms: 1711875800081,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "aaf3a700-3451-440c-96e0-3b054d21cd8f",
            price: 70270.06,
            price_sources: [],
            processed_ms: 1711879417839,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "36942929-7dbf-4fe0-b880-45421faf262e",
            price: 70319.83,
            price_sources: [],
            processed_ms: 1711883078397,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "1397d432-0ce5-4adc-9b7d-bbe4dafceec1",
            price: 70443.43,
            price_sources: [],
            processed_ms: 1711886652314,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "7887793f-d289-46ff-bcb5-f6a381aa0b20",
            price: 70569.17,
            price_sources: [],
            processed_ms: 1711890278460,
          },
        ],
        position_type: "FLAT",
        position_uuid: "bc8c04ec-640c-4186-afe6-cb4f78f7bda6",
        return_at_close: 0.9820718285362674,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 70480.52000000002,
        close_ms: 1711908378097,
        current_return: 0.9998906026234655,
        initial_entry_price: 70477.01,
        is_closed_position: true,
        miner_hotkey: "5DfTfq3oGN7SJGV32x2XKtJ2E6PrRnbArHeoAtKBNQmmZ5Ff",
        net_leverage: 0.0,
        open_ms: 1711893900933,
        orders: [
          {
            leverage: -5.0,
            order_type: "SHORT",
            order_uuid: "45371667-44f4-4006-a04e-976728836d2d",
            price: 70477.01,
            price_sources: [],
            processed_ms: 1711893900927,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "6e580bf6-1ff0-49b6-8c6f-6f64ea1b1b57",
            price: 70454.99,
            price_sources: [],
            processed_ms: 1711897521694,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "5cbebcf7-c438-425c-9053-513ee813d6bb",
            price: 70495.2,
            price_sources: [],
            processed_ms: 1711901137567,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "09b95714-305e-45c5-9f77-26e3d606db28",
            price: 70479.67,
            price_sources: [],
            processed_ms: 1711904750917,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "45fe1c53-a04e-48cc-81f0-8e71a92fffe3",
            price: 70495.94,
            price_sources: [],
            processed_ms: 1711908378097,
          },
        ],
        position_type: "FLAT",
        position_uuid: "496e3c62-6959-4a1a-94b0-afc61a1bcca3",
        return_at_close: 0.9898916965972309,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 71616.37000000008,
        close_ms: 1711930088899,
        current_return: 1.0030909453780514,
        initial_entry_price: 71049.46,
        is_closed_position: true,
        miner_hotkey: "5DfTfq3oGN7SJGV32x2XKtJ2E6PrRnbArHeoAtKBNQmmZ5Ff",
        net_leverage: 0.0,
        open_ms: 1711915673739,
        orders: [
          {
            leverage: -5.0,
            order_type: "SHORT",
            order_uuid: "15fb78ab-90a2-42b1-b4b5-60d29e70301a",
            price: 71049.46,
            price_sources: [],
            processed_ms: 1711915673733,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "ad0c6b55-e57e-433e-942c-2f419d37b359",
            price: 70826.34,
            price_sources: [],
            processed_ms: 1711919241388,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "223e2da7-20a9-477b-accf-f3330e21c141",
            price: 71044.54,
            price_sources: [],
            processed_ms: 1711922857021,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "33c278c6-32a3-4e6c-98c1-1cb14e28a8c5",
            price: 71088.53,
            price_sources: [],
            processed_ms: 1711926470360,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "163e2766-1e4c-4e02-845b-6ff24c8d5104",
            price: 71177.15,
            price_sources: [],
            processed_ms: 1711930088899,
          },
        ],
        position_type: "FLAT",
        position_uuid: "59e57f36-816a-4f40-ba55-8a8fba5f3d1a",
        return_at_close: 0.993060035924271,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 73999.61999999988,
        close_ms: 1711948173506,
        current_return: 1.0249894256544376,
        initial_entry_price: 71091.87,
        is_closed_position: true,
        miner_hotkey: "5DfTfq3oGN7SJGV32x2XKtJ2E6PrRnbArHeoAtKBNQmmZ5Ff",
        net_leverage: 0.0,
        open_ms: 1711933711409,
        orders: [
          {
            leverage: -5.0,
            order_type: "SHORT",
            order_uuid: "8d5fa134-076f-4376-99c9-5d904db64a60",
            price: 71091.87,
            price_sources: [],
            processed_ms: 1711933711388,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "4f856529-31c9-48ed-a549-7948a4c02f94",
            price: 70892.13,
            price_sources: [],
            processed_ms: 1711937321989,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "8bffeed0-68ca-48f7-81dd-7f01422d41e2",
            price: 70750.0,
            price_sources: [],
            processed_ms: 1711940996517,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "ba83f121-cd73-4805-9c69-7cc0bd145aff",
            price: 70664.23,
            price_sources: [],
            processed_ms: 1711944559492,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "884792dd-3198-4e84-a8ff-6f5d8973a7c3",
            price: 70446.53,
            price_sources: [],
            processed_ms: 1711948173506,
          },
        ],
        position_type: "FLAT",
        position_uuid: "2ddaba0e-033d-4702-bc2d-567481bd3d3b",
        return_at_close: 1.0147395313978933,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 69273.8,
        close_ms: 1711955248311,
        current_return: 0.9647052709682449,
        initial_entry_price: 69273.8,
        is_closed_position: true,
        miner_hotkey: "5DfTfq3oGN7SJGV32x2XKtJ2E6PrRnbArHeoAtKBNQmmZ5Ff",
        net_leverage: 0.0,
        open_ms: 1711951939010,
        orders: [
          {
            leverage: -5.0,
            order_type: "SHORT",
            order_uuid: "29b113d8-5071-4a0f-850b-0feb3657eacf",
            price: 69273.8,
            price_sources: [],
            processed_ms: 1711951939006,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "ad92cb31-bdfb-4657-b617-ec28620e10da",
            price: 69762.8,
            price_sources: [],
            processed_ms: 1711955248311,
          },
        ],
        position_type: "FLAT",
        position_uuid: "f925cab4-e430-4ba3-b5b5-e00f18f466aa",
        return_at_close: 0.9550582182585624,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
    ],
    thirty_day_returns: 1.0,
  },
  "5Dhyo55Ca2srjW74BtUWGiFF52kD9KVnrLQNFX84UfWwTk2j": {
    all_time_returns: 1.0,
    n_positions: 0,
    percentage_profitable: 0.0,
    positions: [
      {
        average_entry_price: 3307.668,
        close_ms: 1711153069606,
        current_return: 0.9978845334469536,
        initial_entry_price: 3285.8,
        is_closed_position: true,
        miner_hotkey: "5Dhyo55Ca2srjW74BtUWGiFF52kD9KVnrLQNFX84UfWwTk2j",
        net_leverage: 0.0,
        open_ms: 1711148556564,
        orders: [
          {
            leverage: -0.1,
            order_type: "SHORT",
            order_uuid: "46cd49c7-c314-4ab3-bddd-7e847fb487fe",
            price: 3285.8,
            price_sources: [],
            processed_ms: 1711148556563,
          },
          {
            leverage: -0.1,
            order_type: "SHORT",
            order_uuid: "6475b908-fce6-446d-b48e-8eac7427ee42",
            price: 3295.61,
            price_sources: [],
            processed_ms: 1711149478282,
          },
          {
            leverage: -0.1,
            order_type: "SHORT",
            order_uuid: "5eb2be39-5174-492f-aeae-2a5c536ce77b",
            price: 3310.96,
            price_sources: [],
            processed_ms: 1711150358799,
          },
          {
            leverage: -0.1,
            order_type: "SHORT",
            order_uuid: "b23b2c5b-64f5-40c8-954d-e862548e9d6d",
            price: 3317.61,
            price_sources: [],
            processed_ms: 1711151275816,
          },
          {
            leverage: -0.1,
            order_type: "SHORT",
            order_uuid: "a8521e4b-2a51-4c00-bb20-dad307fd0b40",
            price: 3328.36,
            price_sources: [],
            processed_ms: 1711152157795,
          },
          {
            leverage: 0.1,
            order_type: "FLAT",
            order_uuid: "317769a4-bdfe-42a0-abd8-f848460a3fdc",
            price: 3321.57,
            price_sources: [],
            processed_ms: 1711153069606,
          },
        ],
        position_type: "FLAT",
        position_uuid: "71c7904b-a450-4da2-94d8-07a08e21b140",
        return_at_close: 0.9968866489135066,
        trade_pair: ["ETHUSD", "ETH/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 3329.0140000000006,
        close_ms: 1711158461974,
        current_return: 0.9979257007869278,
        initial_entry_price: 3323.05,
        is_closed_position: true,
        miner_hotkey: "5Dhyo55Ca2srjW74BtUWGiFF52kD9KVnrLQNFX84UfWwTk2j",
        net_leverage: 0.0,
        open_ms: 1711153958686,
        orders: [
          {
            leverage: -0.1,
            order_type: "SHORT",
            order_uuid: "caca37f5-dbc8-403d-aa0a-06b6e510e07f",
            price: 3323.05,
            price_sources: [],
            processed_ms: 1711153958685,
          },
          {
            leverage: -0.1,
            order_type: "SHORT",
            order_uuid: "658119e8-a092-4b60-a834-a9d645b3547f",
            price: 3323.55,
            price_sources: [],
            processed_ms: 1711154874486,
          },
          {
            leverage: -0.1,
            order_type: "SHORT",
            order_uuid: "745f52b0-6e95-4f72-a971-421ff448205c",
            price: 3323.18,
            price_sources: [],
            processed_ms: 1711155782643,
          },
          {
            leverage: -0.1,
            order_type: "SHORT",
            order_uuid: "a3cc26c4-089e-4fde-a84a-04bee54a4e56",
            price: 3336.48,
            price_sources: [],
            processed_ms: 1711156660493,
          },
          {
            leverage: -0.1,
            order_type: "SHORT",
            order_uuid: "4aec4d5c-75e3-41e7-bff4-c8b30e6c6e80",
            price: 3338.81,
            price_sources: [],
            processed_ms: 1711157577401,
          },
          {
            leverage: 0.1,
            order_type: "FLAT",
            order_uuid: "bff2c6f1-8ab4-480d-8204-40bf9f47abd1",
            price: 3342.8,
            price_sources: [],
            processed_ms: 1711158461974,
          },
        ],
        position_type: "FLAT",
        position_uuid: "f0566652-1dbb-4169-b42a-94903c4c2e8c",
        return_at_close: 0.9969277750861408,
        trade_pair: ["ETHUSD", "ETH/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 3339.5080000000007,
        close_ms: 1711163865451,
        current_return: 1.0013708379935047,
        initial_entry_price: 3343.94,
        is_closed_position: true,
        miner_hotkey: "5Dhyo55Ca2srjW74BtUWGiFF52kD9KVnrLQNFX84UfWwTk2j",
        net_leverage: 0.0,
        open_ms: 1711159375254,
        orders: [
          {
            leverage: -0.1,
            order_type: "SHORT",
            order_uuid: "2e02e4c9-153d-4fa4-ad01-293864ec876c",
            price: 3343.94,
            price_sources: [],
            processed_ms: 1711159375253,
          },
          {
            leverage: -0.1,
            order_type: "SHORT",
            order_uuid: "5a205da4-d774-474c-9788-742e62c3377e",
            price: 3343.74,
            price_sources: [],
            processed_ms: 1711160269055,
          },
          {
            leverage: -0.1,
            order_type: "SHORT",
            order_uuid: "8f52085e-cd37-43e6-b02d-159edbe8873a",
            price: 3335.69,
            price_sources: [],
            processed_ms: 1711161180651,
          },
          {
            leverage: -0.1,
            order_type: "SHORT",
            order_uuid: "f5526a2c-ffaa-4f5c-b3db-d880467f6cf4",
            price: 3337.98,
            price_sources: [],
            processed_ms: 1711162063557,
          },
          {
            leverage: -0.1,
            order_type: "SHORT",
            order_uuid: "3d83d602-0e1c-4a33-b06a-640d2e1addbe",
            price: 3336.19,
            price_sources: [],
            processed_ms: 1711162985731,
          },
          {
            leverage: 0.1,
            order_type: "FLAT",
            order_uuid: "4929730b-4f80-4c2c-af20-c33004a782c9",
            price: 3330.34,
            price_sources: [],
            processed_ms: 1711163865451,
          },
        ],
        position_type: "FLAT",
        position_uuid: "3641f147-8be5-4919-9473-b0f49cc7ea43",
        return_at_close: 1.0003694671555112,
        trade_pair: ["ETHUSD", "ETH/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 3305.9020000000005,
        close_ms: 1711169265108,
        current_return: 0.9956107373197901,
        initial_entry_price: 3301.01,
        is_closed_position: true,
        miner_hotkey: "5Dhyo55Ca2srjW74BtUWGiFF52kD9KVnrLQNFX84UfWwTk2j",
        net_leverage: 0.0,
        open_ms: 1711164761902,
        orders: [
          {
            leverage: -0.1,
            order_type: "SHORT",
            order_uuid: "7b06f104-5e3f-4de0-9c29-3de4058f8e52",
            price: 3301.01,
            price_sources: [],
            processed_ms: 1711164761901,
          },
          {
            leverage: -0.1,
            order_type: "SHORT",
            order_uuid: "ddc6860f-953b-4909-880d-ebf2ef8633fd",
            price: 3285.66,
            price_sources: [],
            processed_ms: 1711165682669,
          },
          {
            leverage: -0.1,
            order_type: "SHORT",
            order_uuid: "c2097fd3-7d8f-4ec1-8697-4b47031b8adf",
            price: 3305.7,
            price_sources: [],
            processed_ms: 1711166560568,
          },
          {
            leverage: -0.1,
            order_type: "SHORT",
            order_uuid: "2a7b838d-84c9-457a-97d9-3dcb228f9058",
            price: 3312.53,
            price_sources: [],
            processed_ms: 1711167480699,
          },
          {
            leverage: -0.1,
            order_type: "SHORT",
            order_uuid: "f875ad26-f85c-4779-bc35-4c95d7507fe2",
            price: 3324.61,
            price_sources: [],
            processed_ms: 1711168372864,
          },
          {
            leverage: 0.1,
            order_type: "FLAT",
            order_uuid: "b1e68cab-e3e3-4031-8b2a-7fa0ec2492b3",
            price: 3334.88,
            price_sources: [],
            processed_ms: 1711169265108,
          },
        ],
        position_type: "FLAT",
        position_uuid: "546dda51-44f4-43fa-9329-e01f784f6323",
        return_at_close: 0.9946151265824703,
        trade_pair: ["ETHUSD", "ETH/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 3326.5880000000006,
        close_ms: 1711174662887,
        current_return: 0.9992703759398497,
        initial_entry_price: 3325.0,
        is_closed_position: true,
        miner_hotkey: "5Dhyo55Ca2srjW74BtUWGiFF52kD9KVnrLQNFX84UfWwTk2j",
        net_leverage: 0.0,
        open_ms: 1711170167199,
        orders: [
          {
            leverage: -0.1,
            order_type: "SHORT",
            order_uuid: "579e9874-028a-489c-8bd2-9811dae91997",
            price: 3325.0,
            price_sources: [],
            processed_ms: 1711170167198,
          },
          {
            leverage: -0.1,
            order_type: "SHORT",
            order_uuid: "6f2cd3f6-f59f-426f-8ee7-18238090069d",
            price: 3322.38,
            price_sources: [],
            processed_ms: 1711171066433,
          },
          {
            leverage: -0.1,
            order_type: "SHORT",
            order_uuid: "df11ceaf-c0e1-46ad-8195-b611c0943332",
            price: 3328.04,
            price_sources: [],
            processed_ms: 1711171978666,
          },
          {
            leverage: -0.1,
            order_type: "SHORT",
            order_uuid: "8508718f-9aeb-4a3c-a3d0-64df9d2864eb",
            price: 3326.96,
            price_sources: [],
            processed_ms: 1711172869927,
          },
          {
            leverage: -0.1,
            order_type: "SHORT",
            order_uuid: "297731fb-02df-45c9-8bf4-7103be7b8b03",
            price: 3330.56,
            price_sources: [],
            processed_ms: 1711173771899,
          },
          {
            leverage: 0.1,
            order_type: "FLAT",
            order_uuid: "a69e6878-e982-48e5-be37-b5539f64ef3d",
            price: 3331.44,
            price_sources: [],
            processed_ms: 1711174662887,
          },
        ],
        position_type: "FLAT",
        position_uuid: "3bef275a-d1ae-4637-8120-8c0dc41e7e01",
        return_at_close: 0.9982711055639099,
        trade_pair: ["ETHUSD", "ETH/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 3357.156250000001,
        close_ms: 1711182418853,
        current_return: 0.9999248640218406,
        initial_entry_price: 3340.61,
        is_closed_position: true,
        miner_hotkey: "5Dhyo55Ca2srjW74BtUWGiFF52kD9KVnrLQNFX84UfWwTk2j",
        net_leverage: 0.0,
        open_ms: 1711175563977,
        orders: [
          {
            leverage: -0.1,
            order_type: "SHORT",
            order_uuid: "95f1699e-c5e3-46e3-a6ae-b6f9853380de",
            price: 3340.61,
            price_sources: [],
            processed_ms: 1711175563976,
          },
          {
            leverage: -0.1,
            order_type: "SHORT",
            order_uuid: "ba04e84c-45f9-40f9-be3c-2303e8e6c90f",
            price: 3345.08,
            price_sources: [],
            processed_ms: 1711176464578,
          },
          {
            leverage: -0.1,
            order_type: "SHORT",
            order_uuid: "57b54c3e-dffb-44dc-971e-a644c461ac31",
            price: 3342.58,
            price_sources: [],
            processed_ms: 1711177379675,
          },
          {
            leverage: -0.1,
            order_type: "SHORT",
            order_uuid: "28da4305-63a1-438c-a5b2-31ba643c4ade",
            price: 3359.69,
            price_sources: [],
            processed_ms: 1711177914959,
          },
          {
            leverage: -0.1,
            order_type: "SHORT",
            order_uuid: "ff995425-1e7f-4590-a753-21ac44e9f342",
            price: 3374.45,
            price_sources: [],
            processed_ms: 1711178811100,
          },
          {
            leverage: -0.1,
            order_type: "SHORT",
            order_uuid: "cb8d1383-b41c-4f2f-98f1-bd4c9fa2b62c",
            price: 3366.94,
            price_sources: [],
            processed_ms: 1711179716848,
          },
          {
            leverage: -0.1,
            order_type: "SHORT",
            order_uuid: "4044fa4a-8e28-4c0b-b4e1-93a205611cfb",
            price: 3360.9,
            price_sources: [],
            processed_ms: 1711180631215,
          },
          {
            leverage: -0.1,
            order_type: "SHORT",
            order_uuid: "aa5c7990-ff2c-4daf-836c-2d8c1d0f1618",
            price: 3367.0,
            price_sources: [],
            processed_ms: 1711181520711,
          },
          {
            leverage: 0.1,
            order_type: "FLAT",
            order_uuid: "0242ae6d-dca6-485d-93e7-a99103f4fe32",
            price: 3357.47,
            price_sources: [],
            processed_ms: 1711182418853,
          },
        ],
        position_type: "FLAT",
        position_uuid: "336742b9-5bd0-4d93-9535-01e9d6705d7e",
        return_at_close: 0.9983249842394055,
        trade_pair: ["ETHUSD", "ETH/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 3341.042,
        close_ms: 1711187828878,
        current_return: 0.9993086428605573,
        initial_entry_price: 3347.04,
        is_closed_position: true,
        miner_hotkey: "5Dhyo55Ca2srjW74BtUWGiFF52kD9KVnrLQNFX84UfWwTk2j",
        net_leverage: 0.0,
        open_ms: 1711183322027,
        orders: [
          {
            leverage: -0.1,
            order_type: "SHORT",
            order_uuid: "5682e705-ddc2-432a-8cc0-21a6a499ab59",
            price: 3347.04,
            price_sources: [],
            processed_ms: 1711183322026,
          },
          {
            leverage: -0.1,
            order_type: "SHORT",
            order_uuid: "a115564d-07f6-4056-adc1-4ba5f7626fb8",
            price: 3345.3,
            price_sources: [],
            processed_ms: 1711184213014,
          },
          {
            leverage: -0.1,
            order_type: "SHORT",
            order_uuid: "d9c1be7b-15aa-4736-88ef-2e4709dc9972",
            price: 3333.3,
            price_sources: [],
            processed_ms: 1711185117907,
          },
          {
            leverage: -0.1,
            order_type: "SHORT",
            order_uuid: "fbec8ffc-d37b-476f-9df5-9e969cfa5ee8",
            price: 3340.41,
            price_sources: [],
            processed_ms: 1711186028194,
          },
          {
            leverage: -0.1,
            order_type: "SHORT",
            order_uuid: "c6515d3c-db22-4590-8f8c-f9f4b1f86e26",
            price: 3339.16,
            price_sources: [],
            processed_ms: 1711186916936,
          },
          {
            leverage: 0.1,
            order_type: "FLAT",
            order_uuid: "dea6e274-6d17-4b06-bbdc-20bb2ecdcb17",
            price: 3345.67,
            price_sources: [],
            processed_ms: 1711187828878,
          },
        ],
        position_type: "FLAT",
        position_uuid: "147bf060-fcc3-4393-8216-05d19ca55088",
        return_at_close: 0.9983093342176967,
        trade_pair: ["ETHUSD", "ETH/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 3358.2260000000006,
        close_ms: 1711193229388,
        current_return: 0.999783634816045,
        initial_entry_price: 3360.06,
        is_closed_position: true,
        miner_hotkey: "5Dhyo55Ca2srjW74BtUWGiFF52kD9KVnrLQNFX84UfWwTk2j",
        net_leverage: 0.0,
        open_ms: 1711188742541,
        orders: [
          {
            leverage: -0.1,
            order_type: "SHORT",
            order_uuid: "20328b7e-60c2-47a4-9c0f-d8146462f10a",
            price: 3360.06,
            price_sources: [],
            processed_ms: 1711188742540,
          },
          {
            leverage: -0.1,
            order_type: "SHORT",
            order_uuid: "9aaf91dd-a485-4614-af44-4cd6bf6b0331",
            price: 3363.64,
            price_sources: [],
            processed_ms: 1711189635077,
          },
          {
            leverage: -0.1,
            order_type: "SHORT",
            order_uuid: "f69b7f34-604c-4ae3-a10d-1b6b887bb5ae",
            price: 3366.38,
            price_sources: [],
            processed_ms: 1711190533175,
          },
          {
            leverage: -0.1,
            order_type: "SHORT",
            order_uuid: "3b676dab-ce58-463f-a7e4-21fd9767b6d4",
            price: 3345.11,
            price_sources: [],
            processed_ms: 1711191439300,
          },
          {
            leverage: -0.1,
            order_type: "SHORT",
            order_uuid: "f4f90eda-15b8-4790-987e-fc97e05aa068",
            price: 3355.94,
            price_sources: [],
            processed_ms: 1711192329535,
          },
          {
            leverage: 0.1,
            order_type: "FLAT",
            order_uuid: "c8263caf-6877-403a-bf38-26b7471b9674",
            price: 3359.68,
            price_sources: [],
            processed_ms: 1711193229388,
          },
        ],
        position_type: "FLAT",
        position_uuid: "0a9d07db-2fd4-481a-b800-19dd479edf90",
        return_at_close: 0.998783851181229,
        trade_pair: ["ETHUSD", "ETH/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 3365.3740000000003,
        close_ms: 1711198621106,
        current_return: 0.998247824299934,
        initial_entry_price: 3357.54,
        is_closed_position: true,
        miner_hotkey: "5Dhyo55Ca2srjW74BtUWGiFF52kD9KVnrLQNFX84UfWwTk2j",
        net_leverage: 0.0,
        open_ms: 1711194138794,
        orders: [
          {
            leverage: -0.1,
            order_type: "SHORT",
            order_uuid: "33933bdb-e76d-4f5e-b5a4-5ccad395139f",
            price: 3357.54,
            price_sources: [],
            processed_ms: 1711194138792,
          },
          {
            leverage: -0.1,
            order_type: "SHORT",
            order_uuid: "36c055c1-3800-4044-8733-f327e5e48588",
            price: 3359.41,
            price_sources: [],
            processed_ms: 1711195030438,
          },
          {
            leverage: -0.1,
            order_type: "SHORT",
            order_uuid: "909c1808-15b9-4b8d-87e1-cf4dbe8faee0",
            price: 3368.12,
            price_sources: [],
            processed_ms: 1711195920094,
          },
          {
            leverage: -0.1,
            order_type: "SHORT",
            order_uuid: "e9abd3d2-4ce2-4e1c-a190-8271e978fd78",
            price: 3372.19,
            price_sources: [],
            processed_ms: 1711196824913,
          },
          {
            leverage: -0.1,
            order_type: "SHORT",
            order_uuid: "662e603c-50d1-48d4-aa9f-b9417555e2be",
            price: 3369.61,
            price_sources: [],
            processed_ms: 1711197720304,
          },
          {
            leverage: 0.1,
            order_type: "FLAT",
            order_uuid: "0b36f849-f76e-45e5-a5ba-1831531fe489",
            price: 3377.14,
            price_sources: [],
            processed_ms: 1711198621106,
          },
        ],
        position_type: "FLAT",
        position_uuid: "d1e4557d-47da-481b-ba80-e64b28fdcc04",
        return_at_close: 0.9972495764756341,
        trade_pair: ["ETHUSD", "ETH/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 3361.51,
        close_ms: 1711204039298,
        current_return: 0.9992616428669056,
        initial_entry_price: 3365.58,
        is_closed_position: true,
        miner_hotkey: "5Dhyo55Ca2srjW74BtUWGiFF52kD9KVnrLQNFX84UfWwTk2j",
        net_leverage: 0.0,
        open_ms: 1711199522414,
        orders: [
          {
            leverage: -0.1,
            order_type: "SHORT",
            order_uuid: "1126ec42-316e-4a61-8003-9daf9c1bbc24",
            price: 3365.58,
            price_sources: [],
            processed_ms: 1711199522412,
          },
          {
            leverage: -0.1,
            order_type: "SHORT",
            order_uuid: "74d435c4-7ee5-4f92-b219-f0c23e259acc",
            price: 3361.18,
            price_sources: [],
            processed_ms: 1711200422362,
          },
          {
            leverage: -0.1,
            order_type: "SHORT",
            order_uuid: "c856268a-3d62-42fc-bd8e-9ea14b2cd5c9",
            price: 3350.82,
            price_sources: [],
            processed_ms: 1711201324233,
          },
          {
            leverage: -0.1,
            order_type: "SHORT",
            order_uuid: "9e984187-289e-4d9e-8588-e8989e7caa7d",
            price: 3363.77,
            price_sources: [],
            processed_ms: 1711202219898,
          },
          {
            leverage: -0.1,
            order_type: "SHORT",
            order_uuid: "ac4176de-e786-4987-8445-649c6260f411",
            price: 3366.2,
            price_sources: [],
            processed_ms: 1711203117268,
          },
          {
            leverage: 0.1,
            order_type: "FLAT",
            order_uuid: "0d55849e-199a-4dfd-b856-955fb5348623",
            price: 3366.48,
            price_sources: [],
            processed_ms: 1711204039298,
          },
        ],
        position_type: "FLAT",
        position_uuid: "5a05d15e-e887-40fd-a82b-5891b64c128d",
        return_at_close: 0.9982623812240387,
        trade_pair: ["ETHUSD", "ETH/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 3401.766,
        close_ms: 1711209441262,
        current_return: 0.9993651446189986,
        initial_entry_price: 3405.5,
        is_closed_position: true,
        miner_hotkey: "5Dhyo55Ca2srjW74BtUWGiFF52kD9KVnrLQNFX84UfWwTk2j",
        net_leverage: 0.0,
        open_ms: 1711204962232,
        orders: [
          {
            leverage: -0.1,
            order_type: "SHORT",
            order_uuid: "ca6db3ca-cc27-40ff-8d62-bb031e92b617",
            price: 3405.5,
            price_sources: [],
            processed_ms: 1711204962230,
          },
          {
            leverage: -0.1,
            order_type: "SHORT",
            order_uuid: "7c42f6bf-b50c-4dd6-9a96-d5f22b1ed57a",
            price: 3414.88,
            price_sources: [],
            processed_ms: 1711205826420,
          },
          {
            leverage: -0.1,
            order_type: "SHORT",
            order_uuid: "934d450c-cc1d-4d26-b276-3b5ea71f8fb9",
            price: 3411.2,
            price_sources: [],
            processed_ms: 1711206729407,
          },
          {
            leverage: -0.1,
            order_type: "SHORT",
            order_uuid: "4d5b5f87-3625-406b-8174-3c580d4b3210",
            price: 3384.09,
            price_sources: [],
            processed_ms: 1711207630312,
          },
          {
            leverage: -0.1,
            order_type: "SHORT",
            order_uuid: "ab672939-ce37-45a0-ab9c-ea5e5d22f5de",
            price: 3393.16,
            price_sources: [],
            processed_ms: 1711208533480,
          },
          {
            leverage: 0.1,
            order_type: "FLAT",
            order_uuid: "ca37af1c-4d84-4c78-9a1a-b2f542d73d66",
            price: 3406.09,
            price_sources: [],
            processed_ms: 1711209441262,
          },
        ],
        position_type: "FLAT",
        position_uuid: "01fe8b49-dc88-444e-933d-4b07508a364f",
        return_at_close: 0.9983657794743797,
        trade_pair: ["ETHUSD", "ETH/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 3405.4440000000004,
        close_ms: 1711214834710,
        current_return: 1.0008029522210982,
        initial_entry_price: 3389.99,
        is_closed_position: true,
        miner_hotkey: "5Dhyo55Ca2srjW74BtUWGiFF52kD9KVnrLQNFX84UfWwTk2j",
        net_leverage: 0.0,
        open_ms: 1711210325061,
        orders: [
          {
            leverage: -0.1,
            order_type: "SHORT",
            order_uuid: "ba1e683b-ab9d-4856-896d-92e5b8298c25",
            price: 3389.99,
            price_sources: [],
            processed_ms: 1711210325059,
          },
          {
            leverage: -0.1,
            order_type: "SHORT",
            order_uuid: "96ca030a-e0c6-4789-a4a3-7ce4bf627dbf",
            price: 3401.46,
            price_sources: [],
            processed_ms: 1711211235896,
          },
          {
            leverage: -0.1,
            order_type: "SHORT",
            order_uuid: "0284d389-395c-421a-aed3-10d29a540ad5",
            price: 3415.33,
            price_sources: [],
            processed_ms: 1711212139817,
          },
          {
            leverage: -0.1,
            order_type: "SHORT",
            order_uuid: "235a320f-a8f2-4d02-88cb-0da68fcc7b88",
            price: 3414.06,
            price_sources: [],
            processed_ms: 1711213055986,
          },
          {
            leverage: -0.1,
            order_type: "SHORT",
            order_uuid: "2cb09c93-3388-4a82-8bd6-baf82ccaa6e1",
            price: 3406.38,
            price_sources: [],
            processed_ms: 1711213929840,
          },
          {
            leverage: 0.1,
            order_type: "FLAT",
            order_uuid: "a841dd37-2f81-43eb-800b-8563c5abd697",
            price: 3400.0,
            price_sources: [],
            processed_ms: 1711214834710,
          },
        ],
        position_type: "FLAT",
        position_uuid: "f1fcecf1-d8bc-48ee-b73b-61bb4345a51d",
        return_at_close: 0.9998021492688771,
        trade_pair: ["ETHUSD", "ETH/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 3405.09,
        close_ms: 1711220245925,
        current_return: 0.9995147566746612,
        initial_entry_price: 3410.66,
        is_closed_position: true,
        miner_hotkey: "5Dhyo55Ca2srjW74BtUWGiFF52kD9KVnrLQNFX84UfWwTk2j",
        net_leverage: 0.0,
        open_ms: 1711215721146,
        orders: [
          {
            leverage: -0.1,
            order_type: "SHORT",
            order_uuid: "e5988176-59a3-41c2-b121-deebeb882e2d",
            price: 3410.66,
            price_sources: [],
            processed_ms: 1711215721144,
          },
          {
            leverage: -0.1,
            order_type: "SHORT",
            order_uuid: "a2e6805c-b31a-4bcd-98bb-46e6ef9a7096",
            price: 3401.19,
            price_sources: [],
            processed_ms: 1711216630769,
          },
          {
            leverage: -0.1,
            order_type: "SHORT",
            order_uuid: "0bc0aa48-eb3e-4814-a329-8ae0dbac5692",
            price: 3408.38,
            price_sources: [],
            processed_ms: 1711217526788,
          },
          {
            leverage: -0.1,
            order_type: "SHORT",
            order_uuid: "9385a939-dd7d-4685-8110-48cb275ee249",
            price: 3405.21,
            price_sources: [],
            processed_ms: 1711218422391,
          },
          {
            leverage: -0.1,
            order_type: "SHORT",
            order_uuid: "1676ea9d-77cb-4266-af98-919dcec111db",
            price: 3400.01,
            price_sources: [],
            processed_ms: 1711219329531,
          },
          {
            leverage: 0.1,
            order_type: "FLAT",
            order_uuid: "1c6e6cc3-8d1f-45d4-8d5e-50c9264e1893",
            price: 3408.4,
            price_sources: [],
            processed_ms: 1711220245925,
          },
        ],
        position_type: "FLAT",
        position_uuid: "50836c32-abd5-4dd0-8cba-4087afbc3c3b",
        return_at_close: 0.9985152419179865,
        trade_pair: ["ETHUSD", "ETH/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 3393.606,
        close_ms: 1711225635604,
        current_return: 1.0009066672930345,
        initial_entry_price: 3405.88,
        is_closed_position: true,
        miner_hotkey: "5Dhyo55Ca2srjW74BtUWGiFF52kD9KVnrLQNFX84UfWwTk2j",
        net_leverage: 0.0,
        open_ms: 1711221127428,
        orders: [
          {
            leverage: -0.1,
            order_type: "SHORT",
            order_uuid: "99da03f0-b0ce-4a2b-bc49-16acbfdfca85",
            price: 3405.88,
            price_sources: [],
            processed_ms: 1711221127426,
          },
          {
            leverage: -0.1,
            order_type: "SHORT",
            order_uuid: "230f6096-9649-4f85-8ce6-4c4ff80d4c3b",
            price: 3400.1,
            price_sources: [],
            processed_ms: 1711222029435,
          },
          {
            leverage: -0.1,
            order_type: "SHORT",
            order_uuid: "e4306a3c-c7be-40f8-88f1-99e1f3d44e30",
            price: 3391.92,
            price_sources: [],
            processed_ms: 1711222925768,
          },
          {
            leverage: -0.1,
            order_type: "SHORT",
            order_uuid: "c6183fb3-1795-4122-a502-d8ccfdaf5ce5",
            price: 3389.07,
            price_sources: [],
            processed_ms: 1711223842128,
          },
          {
            leverage: -0.1,
            order_type: "SHORT",
            order_uuid: "2716d504-2a4e-43ff-bc29-d6bb8672d3ac",
            price: 3381.06,
            price_sources: [],
            processed_ms: 1711224738411,
          },
          {
            leverage: 0.1,
            order_type: "FLAT",
            order_uuid: "990b7d95-e025-4e4b-9e18-232b57eb122d",
            price: 3387.43,
            price_sources: [],
            processed_ms: 1711225635604,
          },
        ],
        position_type: "FLAT",
        position_uuid: "9c913d56-d2df-4c1e-b135-21d910cf54b6",
        return_at_close: 0.9999057606257414,
        trade_pair: ["ETHUSD", "ETH/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 3381.846,
        close_ms: 1711231052543,
        current_return: 0.9988812707254039,
        initial_entry_price: 3389.56,
        is_closed_position: true,
        miner_hotkey: "5Dhyo55Ca2srjW74BtUWGiFF52kD9KVnrLQNFX84UfWwTk2j",
        net_leverage: 0.0,
        open_ms: 1711226525303,
        orders: [
          {
            leverage: -0.1,
            order_type: "SHORT",
            order_uuid: "b04aa473-f3e2-4f83-a665-06607b59a288",
            price: 3389.56,
            price_sources: [],
            processed_ms: 1711226524848,
          },
          {
            leverage: -0.1,
            order_type: "SHORT",
            order_uuid: "fe1b34e8-ebc9-4634-a8c7-6fdc5348b53b",
            price: 3385.49,
            price_sources: [],
            processed_ms: 1711227444332,
          },
          {
            leverage: -0.1,
            order_type: "SHORT",
            order_uuid: "083dd8d0-bbbf-4291-86cd-dc01c079c2d8",
            price: 3377.71,
            price_sources: [],
            processed_ms: 1711228339477,
          },
          {
            leverage: -0.1,
            order_type: "SHORT",
            order_uuid: "a664a157-e91c-4da7-98ca-be92d4fb5e7c",
            price: 3378.66,
            price_sources: [],
            processed_ms: 1711229232589,
          },
          {
            leverage: -0.1,
            order_type: "SHORT",
            order_uuid: "184b56fa-829b-4363-a2c5-2f8a7d692baa",
            price: 3377.81,
            price_sources: [],
            processed_ms: 1711230138678,
          },
          {
            leverage: 0.1,
            order_type: "FLAT",
            order_uuid: "285fd722-e4a1-4e59-ad34-b8c8a2799b2f",
            price: 3389.43,
            price_sources: [],
            processed_ms: 1711231052543,
          },
        ],
        position_type: "FLAT",
        position_uuid: "f8c5323d-4f5b-4c5e-b90a-46a0382928f5",
        return_at_close: 0.9978823894546784,
        trade_pair: ["ETHUSD", "ETH/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 3382.878,
        close_ms: 1711236444483,
        current_return: 1.0019793619628279,
        initial_entry_price: 3386.95,
        is_closed_position: true,
        miner_hotkey: "5Dhyo55Ca2srjW74BtUWGiFF52kD9KVnrLQNFX84UfWwTk2j",
        net_leverage: 0.0,
        open_ms: 1711231927443,
        orders: [
          {
            leverage: -0.1,
            order_type: "SHORT",
            order_uuid: "70397a70-d6ac-474b-b831-f8bcea17795a",
            price: 3386.95,
            price_sources: [],
            processed_ms: 1711231927441,
          },
          {
            leverage: -0.1,
            order_type: "SHORT",
            order_uuid: "5ac91e2f-647c-47e4-9e57-8a196b33ac93",
            price: 3376.83,
            price_sources: [],
            processed_ms: 1711232830693,
          },
          {
            leverage: -0.1,
            order_type: "SHORT",
            order_uuid: "142d9b99-a69b-4c16-be51-a67a70ba93a1",
            price: 3386.31,
            price_sources: [],
            processed_ms: 1711233728838,
          },
          {
            leverage: -0.1,
            order_type: "SHORT",
            order_uuid: "aaccadfc-1298-43e0-8932-1bfc8238698a",
            price: 3390.79,
            price_sources: [],
            processed_ms: 1711234647540,
          },
          {
            leverage: -0.1,
            order_type: "SHORT",
            order_uuid: "3608d6b5-8429-410f-8c59-7b6dc14ee76b",
            price: 3373.51,
            price_sources: [],
            processed_ms: 1711235549583,
          },
          {
            leverage: 0.1,
            order_type: "FLAT",
            order_uuid: "7c35ca73-eaa4-4676-8ecb-d7c66b04598b",
            price: 3369.47,
            price_sources: [],
            processed_ms: 1711236444483,
          },
        ],
        position_type: "FLAT",
        position_uuid: "66c080e7-6d0a-478f-8c44-bbc0ea3da0f5",
        return_at_close: 1.000977382600865,
        trade_pair: ["ETHUSD", "ETH/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 3350.1060000000007,
        close_ms: 1711241830667,
        current_return: 0.9989832777910023,
        initial_entry_price: 3360.8,
        is_closed_position: true,
        miner_hotkey: "5Dhyo55Ca2srjW74BtUWGiFF52kD9KVnrLQNFX84UfWwTk2j",
        net_leverage: 0.0,
        open_ms: 1711237338934,
        orders: [
          {
            leverage: -0.1,
            order_type: "SHORT",
            order_uuid: "564b0727-fb02-4c89-8f28-6d202caf5d78",
            price: 3360.8,
            price_sources: [],
            processed_ms: 1711237338932,
          },
          {
            leverage: -0.1,
            order_type: "SHORT",
            order_uuid: "baaeb242-8219-45db-b3e7-e128c405222a",
            price: 3334.38,
            price_sources: [],
            processed_ms: 1711238229890,
          },
          {
            leverage: -0.1,
            order_type: "SHORT",
            order_uuid: "803dd1eb-2ecf-49cf-be02-fc38107c7c71",
            price: 3348.03,
            price_sources: [],
            processed_ms: 1711239134557,
          },
          {
            leverage: -0.1,
            order_type: "SHORT",
            order_uuid: "7f84c188-1edf-4c15-9f22-19bf3ae709e4",
            price: 3351.8,
            price_sources: [],
            processed_ms: 1711240032343,
          },
          {
            leverage: -0.1,
            order_type: "SHORT",
            order_uuid: "6411bb22-c95a-4d84-a9d5-f9ff72d9aa22",
            price: 3355.52,
            price_sources: [],
            processed_ms: 1711240947360,
          },
          {
            leverage: 0.1,
            order_type: "FLAT",
            order_uuid: "042bf7e4-0ec9-44b3-8030-12ef647bfc10",
            price: 3356.94,
            price_sources: [],
            processed_ms: 1711241830667,
          },
        ],
        position_type: "FLAT",
        position_uuid: "a419ed19-50c0-428e-893c-7a87d95930c0",
        return_at_close: 0.9979842945132112,
        trade_pair: ["ETHUSD", "ETH/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 3352.2660000000005,
        close_ms: 1711247250694,
        current_return: 1.0012815734310565,
        initial_entry_price: 3353.69,
        is_closed_position: true,
        miner_hotkey: "5Dhyo55Ca2srjW74BtUWGiFF52kD9KVnrLQNFX84UfWwTk2j",
        net_leverage: 0.0,
        open_ms: 1711242735037,
        orders: [
          {
            leverage: -0.1,
            order_type: "SHORT",
            order_uuid: "261958de-97cf-4176-97ab-1da83954f5d3",
            price: 3353.69,
            price_sources: [],
            processed_ms: 1711242735035,
          },
          {
            leverage: -0.1,
            order_type: "SHORT",
            order_uuid: "8882aa6b-33ec-4b21-9763-765c321fdfbe",
            price: 3349.82,
            price_sources: [],
            processed_ms: 1711243634689,
          },
          {
            leverage: -0.1,
            order_type: "SHORT",
            order_uuid: "dd6239cb-8c3f-49c8-ba27-4a7b82816f56",
            price: 3351.61,
            price_sources: [],
            processed_ms: 1711244561405,
          },
          {
            leverage: -0.1,
            order_type: "SHORT",
            order_uuid: "757d8e88-0577-4ed3-85df-1054eaa61539",
            price: 3354.15,
            price_sources: [],
            processed_ms: 1711245433106,
          },
          {
            leverage: -0.1,
            order_type: "SHORT",
            order_uuid: "39ad5908-ab04-4889-98ca-ab6fa1de5d07",
            price: 3352.06,
            price_sources: [],
            processed_ms: 1711246338493,
          },
          {
            leverage: 0.1,
            order_type: "FLAT",
            order_uuid: "0efc9288-0871-469c-ba36-18c0641b6e05",
            price: 3343.67,
            price_sources: [],
            processed_ms: 1711247250694,
          },
        ],
        position_type: "FLAT",
        position_uuid: "112b0a3a-443e-4ec8-a705-44fd31514588",
        return_at_close: 1.0002802918576255,
        trade_pair: ["ETHUSD", "ETH/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 3331.1620000000003,
        close_ms: 1711252632347,
        current_return: 1.0019441651103769,
        initial_entry_price: 3341.28,
        is_closed_position: true,
        miner_hotkey: "5Dhyo55Ca2srjW74BtUWGiFF52kD9KVnrLQNFX84UfWwTk2j",
        net_leverage: 0.0,
        open_ms: 1711248129697,
        orders: [
          {
            leverage: -0.1,
            order_type: "SHORT",
            order_uuid: "c8bb99b2-8267-443a-bcbb-218ca0935299",
            price: 3341.28,
            price_sources: [],
            processed_ms: 1711248129695,
          },
          {
            leverage: -0.1,
            order_type: "SHORT",
            order_uuid: "3e5a8f90-b97d-4e22-8793-53846e91e0fe",
            price: 3344.78,
            price_sources: [],
            processed_ms: 1711249036733,
          },
          {
            leverage: -0.1,
            order_type: "SHORT",
            order_uuid: "afc4f5bc-71d8-4350-b4a3-359efa267020",
            price: 3329.38,
            price_sources: [],
            processed_ms: 1711249939537,
          },
          {
            leverage: -0.1,
            order_type: "SHORT",
            order_uuid: "fb52ac5a-49bc-40a5-b978-ee8d98a7019c",
            price: 3324.19,
            price_sources: [],
            processed_ms: 1711250842676,
          },
          {
            leverage: -0.1,
            order_type: "SHORT",
            order_uuid: "7ee1915d-4bd8-4493-ae1a-2dfcfc150e7d",
            price: 3316.18,
            price_sources: [],
            processed_ms: 1711251736211,
          },
          {
            leverage: 0.1,
            order_type: "FLAT",
            order_uuid: "ebb8d70f-7e7d-43a2-8a3c-dbba86bc10f0",
            price: 3318.17,
            price_sources: [],
            processed_ms: 1711252632347,
          },
        ],
        position_type: "FLAT",
        position_uuid: "5ffa110f-eac0-4983-ae72-41d91a0fe8a2",
        return_at_close: 1.0009422209452665,
        trade_pair: ["ETHUSD", "ETH/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 3313.8540000000003,
        close_ms: 1711258038623,
        current_return: 0.9979704207958071,
        initial_entry_price: 3319.9,
        is_closed_position: true,
        miner_hotkey: "5Dhyo55Ca2srjW74BtUWGiFF52kD9KVnrLQNFX84UfWwTk2j",
        net_leverage: 0.0,
        open_ms: 1711253540816,
        orders: [
          {
            leverage: -0.1,
            order_type: "SHORT",
            order_uuid: "d00ca85b-ef78-42c1-afee-3ac5a834356b",
            price: 3319.9,
            price_sources: [],
            processed_ms: 1711253540814,
          },
          {
            leverage: -0.1,
            order_type: "SHORT",
            order_uuid: "e3e2d28f-ed98-48f1-a6e7-4e2a0f223ce5",
            price: 3310.33,
            price_sources: [],
            processed_ms: 1711254435248,
          },
          {
            leverage: -0.1,
            order_type: "SHORT",
            order_uuid: "2b67b011-d669-46d3-a747-3403ef6aefcf",
            price: 3301.87,
            price_sources: [],
            processed_ms: 1711255358766,
          },
          {
            leverage: -0.1,
            order_type: "SHORT",
            order_uuid: "dea43052-87eb-4f9b-90bf-981f6ed7c3cb",
            price: 3317.48,
            price_sources: [],
            processed_ms: 1711256241154,
          },
          {
            leverage: -0.1,
            order_type: "SHORT",
            order_uuid: "cd967a9a-6ab6-49c5-a21c-12939ab44931",
            price: 3319.69,
            price_sources: [],
            processed_ms: 1711257135148,
          },
          {
            leverage: 0.1,
            order_type: "FLAT",
            order_uuid: "23634d41-6bcc-4c98-8156-6e82267dba56",
            price: 3327.33,
            price_sources: [],
            processed_ms: 1711258038623,
          },
        ],
        position_type: "FLAT",
        position_uuid: "c00cd096-402b-44bc-8ba8-1d43f4376f14",
        return_at_close: 0.9969724503750113,
        trade_pair: ["ETHUSD", "ETH/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 3327.408000000001,
        close_ms: 1711263433767,
        current_return: 1.0004140880004566,
        initial_entry_price: 3330.21,
        is_closed_position: true,
        miner_hotkey: "5Dhyo55Ca2srjW74BtUWGiFF52kD9KVnrLQNFX84UfWwTk2j",
        net_leverage: 0.0,
        open_ms: 1711258935520,
        orders: [
          {
            leverage: -0.1,
            order_type: "SHORT",
            order_uuid: "ce10a6d8-7f03-4bdb-961c-1bf3c44e1a38",
            price: 3330.21,
            price_sources: [],
            processed_ms: 1711258935518,
          },
          {
            leverage: -0.1,
            order_type: "SHORT",
            order_uuid: "03778f07-a577-45f4-918d-90be90ddb725",
            price: 3332.51,
            price_sources: [],
            processed_ms: 1711259833091,
          },
          {
            leverage: -0.1,
            order_type: "SHORT",
            order_uuid: "acb30b29-136f-427a-861f-f487851e8343",
            price: 3326.37,
            price_sources: [],
            processed_ms: 1711260737445,
          },
          {
            leverage: -0.1,
            order_type: "SHORT",
            order_uuid: "6fe18c79-df9f-47eb-aeee-e119621da966",
            price: 3327.61,
            price_sources: [],
            processed_ms: 1711261663412,
          },
          {
            leverage: -0.1,
            order_type: "SHORT",
            order_uuid: "bb4a7c7d-378d-42e2-b786-2055d86f356a",
            price: 3320.34,
            price_sources: [],
            processed_ms: 1711262536093,
          },
          {
            leverage: 0.1,
            order_type: "FLAT",
            order_uuid: "0d18e1af-bd6a-4884-aaa0-503d37df70df",
            price: 3324.65,
            price_sources: [],
            processed_ms: 1711263433767,
          },
        ],
        position_type: "FLAT",
        position_uuid: "1c5b093d-b7a7-4c85-94b6-8ad7a568f233",
        return_at_close: 0.9994136739124562,
        trade_pair: ["ETHUSD", "ETH/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 64509.0,
        close_ms: 1711268025886,
        current_return: 1.1233679021531882,
        initial_entry_price: 64509.0,
        is_closed_position: true,
        miner_hotkey: "5Dhyo55Ca2srjW74BtUWGiFF52kD9KVnrLQNFX84UfWwTk2j",
        net_leverage: 0.0,
        open_ms: 1711267454084,
        orders: [
          {
            leverage: 19.0,
            order_type: "LONG",
            order_uuid: "a34756fd-be05-4cd6-bb50-9e5720c9fecf",
            price: 64509.0,
            price_sources: [],
            processed_ms: 1711267454081,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "9455c1da-c3cd-4d39-9030-cf15e68bba66",
            price: 64927.86,
            price_sources: [],
            processed_ms: 1711268025886,
          },
        ],
        position_type: "FLAT",
        position_uuid: "a58dcf02-e7f2-45e2-aa7f-d71b0d534312",
        return_at_close: 1.080679921871367,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 3325.2700000000004,
        close_ms: 1711268299829,
        current_return: 0.9970554865038116,
        initial_entry_price: 3322.79,
        is_closed_position: true,
        miner_hotkey: "5Dhyo55Ca2srjW74BtUWGiFF52kD9KVnrLQNFX84UfWwTk2j",
        net_leverage: 0.0,
        open_ms: 1711264339972,
        orders: [
          {
            leverage: -0.1,
            order_type: "SHORT",
            order_uuid: "3fc7203f-f3ed-4a38-aac8-d21454a46f59",
            price: 3322.79,
            price_sources: [],
            processed_ms: 1711264339970,
          },
          {
            leverage: -0.1,
            order_type: "SHORT",
            order_uuid: "9ca3bf0f-c5cb-4a54-865a-4cf8a8cf30a4",
            price: 3326.33,
            price_sources: [],
            processed_ms: 1711265234715,
          },
          {
            leverage: -0.1,
            order_type: "SHORT",
            order_uuid: "f1ef3fd2-2177-4386-8c31-36b49084a302",
            price: 3319.41,
            price_sources: [],
            processed_ms: 1711266135216,
          },
          {
            leverage: -0.1,
            order_type: "SHORT",
            order_uuid: "f6a04ecc-60b2-4f64-a48d-03862c5919e0",
            price: 3332.55,
            price_sources: [],
            processed_ms: 1711267041997,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "bd7ea88b-bef4-47b4-900b-412102bca597",
            price: 3349.73,
            price_sources: [],
            processed_ms: 1711268299829,
          },
        ],
        position_type: "FLAT",
        position_uuid: "e33dd2b2-f7d5-4f48-8ecd-1e5ef3fec94a",
        return_at_close: 0.9962578421146085,
        trade_pair: ["ETHUSD", "ETH/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 64925.71,
        close_ms: 1711274762637,
        current_return: 1.001776645954276,
        initial_entry_price: 64925.71,
        is_closed_position: true,
        miner_hotkey: "5Dhyo55Ca2srjW74BtUWGiFF52kD9KVnrLQNFX84UfWwTk2j",
        net_leverage: 0.0,
        open_ms: 1711273618150,
        orders: [
          {
            leverage: -5.0,
            order_type: "SHORT",
            order_uuid: "72efc549-d6d0-4fe4-be12-202dfbdc8d3e",
            price: 64925.71,
            price_sources: [],
            processed_ms: 1711273618147,
          },
          {
            leverage: 5.0,
            order_type: "FLAT",
            order_uuid: "d85b07e7-223f-4f48-9307-c3581411f54a",
            price: 64902.64,
            price_sources: [],
            processed_ms: 1711274762637,
          },
        ],
        position_type: "FLAT",
        position_uuid: "d1f7bdf3-225d-4ede-861b-ef306e62864d",
        return_at_close: 0.9917588794947333,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 64988.3,
        close_ms: 1711275205096,
        current_return: 0.9961046834584067,
        initial_entry_price: 64988.3,
        is_closed_position: true,
        miner_hotkey: "5Dhyo55Ca2srjW74BtUWGiFF52kD9KVnrLQNFX84UfWwTk2j",
        net_leverage: 0.0,
        open_ms: 1711274897512,
        orders: [
          {
            leverage: 5.0,
            order_type: "LONG",
            order_uuid: "d3d9f3fe-b8d9-4334-956b-410237e0ed8a",
            price: 64988.3,
            price_sources: [],
            processed_ms: 1711274897509,
          },
          {
            leverage: 5.0,
            order_type: "FLAT",
            order_uuid: "d9cb8644-0583-4565-b3ec-1b7a55f95f4e",
            price: 64937.67,
            price_sources: [],
            processed_ms: 1711275205096,
          },
        ],
        position_type: "FLAT",
        position_uuid: "55383bb6-1649-4a79-8cac-94b552af475a",
        return_at_close: 0.9861436366238227,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 64909.52,
        close_ms: 1711275488342,
        current_return: 0.9941356830246161,
        initial_entry_price: 64909.52,
        is_closed_position: true,
        miner_hotkey: "5Dhyo55Ca2srjW74BtUWGiFF52kD9KVnrLQNFX84UfWwTk2j",
        net_leverage: 0.0,
        open_ms: 1711275384495,
        orders: [
          {
            leverage: -5.0,
            order_type: "SHORT",
            order_uuid: "b6163681-9ba3-497c-959b-4128314486e9",
            price: 64909.52,
            price_sources: [],
            processed_ms: 1711275384492,
          },
          {
            leverage: 5.0,
            order_type: "FLAT",
            order_uuid: "44c652f8-c7b6-4670-9d42-f48779ebe3c0",
            price: 64985.65,
            price_sources: [],
            processed_ms: 1711275488342,
          },
        ],
        position_type: "FLAT",
        position_uuid: "3d83d34e-42ab-4de5-98dd-2fe20db2006b",
        return_at_close: 0.9841943261943699,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 65021.3,
        close_ms: 1711275968515,
        current_return: 1.005683522168889,
        initial_entry_price: 65021.3,
        is_closed_position: true,
        miner_hotkey: "5Dhyo55Ca2srjW74BtUWGiFF52kD9KVnrLQNFX84UfWwTk2j",
        net_leverage: 0.0,
        open_ms: 1711275593126,
        orders: [
          {
            leverage: 5.0,
            order_type: "LONG",
            order_uuid: "2b733697-ae1f-4dd4-b7fe-5376530323bd",
            price: 65021.3,
            price_sources: [],
            processed_ms: 1711275593123,
          },
          {
            leverage: 5.0,
            order_type: "FLAT",
            order_uuid: "511da67f-2a3d-41cc-9b4a-f0dc1aba805d",
            price: 65095.21,
            price_sources: [],
            processed_ms: 1711275968515,
          },
        ],
        position_type: "FLAT",
        position_uuid: "b62815ac-e341-4029-a412-7d841e7152b8",
        return_at_close: 0.9956266869472,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 65167.009999999995,
        close_ms: 1711276491124,
        current_return: 0.9936125656217776,
        initial_entry_price: 65167.01,
        is_closed_position: true,
        miner_hotkey: "5Dhyo55Ca2srjW74BtUWGiFF52kD9KVnrLQNFX84UfWwTk2j",
        net_leverage: 0.0,
        open_ms: 1711276233219,
        orders: [
          {
            leverage: 5.0,
            order_type: "LONG",
            order_uuid: "0b36cea9-c645-4063-9d54-581060ed175f",
            price: 65167.01,
            price_sources: [],
            processed_ms: 1711276233214,
          },
          {
            leverage: 5.0,
            order_type: "FLAT",
            order_uuid: "b90e7bb9-f0ed-47b5-b133-eacec8907c7d",
            price: 65083.76,
            price_sources: [],
            processed_ms: 1711276491124,
          },
        ],
        position_type: "FLAT",
        position_uuid: "3e7f767e-2b87-4fa5-b9ac-ebcc12b9a2d5",
        return_at_close: 0.9836764399655598,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 65077.19,
        close_ms: 1711277016415,
        current_return: 0.9866573833320101,
        initial_entry_price: 65077.19,
        is_closed_position: true,
        miner_hotkey: "5Dhyo55Ca2srjW74BtUWGiFF52kD9KVnrLQNFX84UfWwTk2j",
        net_leverage: 0.0,
        open_ms: 1711276594685,
        orders: [
          {
            leverage: -10.0,
            order_type: "SHORT",
            order_uuid: "70f34950-66ce-4c26-8287-f954386e037e",
            price: 65077.19,
            price_sources: [],
            processed_ms: 1711276594682,
          },
          {
            leverage: 10.0,
            order_type: "FLAT",
            order_uuid: "62839841-75ac-45c8-a1a7-182179477c55",
            price: 65164.02,
            price_sources: [],
            processed_ms: 1711277016415,
          },
        ],
        position_type: "FLAT",
        position_uuid: "ec77f07c-80e9-4075-b86b-d8a752cf1a52",
        return_at_close: 0.9669242356653699,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 65165.86,
        close_ms: 1711277360379,
        current_return: 1.0255916211341338,
        initial_entry_price: 65165.86,
        is_closed_position: true,
        miner_hotkey: "5Dhyo55Ca2srjW74BtUWGiFF52kD9KVnrLQNFX84UfWwTk2j",
        net_leverage: 0.0,
        open_ms: 1711277136953,
        orders: [
          {
            leverage: 15.0,
            order_type: "LONG",
            order_uuid: "863b3a3f-498f-4c5c-adbf-a7546403730f",
            price: 65165.86,
            price_sources: [],
            processed_ms: 1711277136950,
          },
          {
            leverage: 5.0,
            order_type: "FLAT",
            order_uuid: "4ba07cd8-6da6-434a-b2ab-7d99412e4727",
            price: 65277.04,
            price_sources: [],
            processed_ms: 1711277360379,
          },
        ],
        position_type: "FLAT",
        position_uuid: "0fcd8a6c-e3eb-4863-83c4-d676c10f1d60",
        return_at_close: 0.9948238725001097,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 65905.48,
        close_ms: 1711310742555,
        current_return: 1.0159880483383166,
        initial_entry_price: 65905.48,
        is_closed_position: true,
        miner_hotkey: "5Dhyo55Ca2srjW74BtUWGiFF52kD9KVnrLQNFX84UfWwTk2j",
        net_leverage: 0.0,
        open_ms: 1711309587568,
        orders: [
          {
            leverage: -10.0,
            order_type: "SHORT",
            order_uuid: "17911da2-e720-4704-9b56-7fd3fd62d2f8",
            price: 65905.48,
            price_sources: [],
            processed_ms: 1711309587565,
          },
          {
            leverage: 10.0,
            order_type: "FLAT",
            order_uuid: "dedfb791-d0f2-4c86-8b0e-1676adf7c9e8",
            price: 65800.11,
            price_sources: [],
            processed_ms: 1711310742555,
          },
        ],
        position_type: "FLAT",
        position_uuid: "16350417-21d3-43a9-8657-0edfbade643b",
        return_at_close: 0.9956682873715502,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 66279.34,
        close_ms: 1711343147700,
        current_return: 1.0060581774049047,
        initial_entry_price: 66279.34,
        is_closed_position: true,
        miner_hotkey: "5Dhyo55Ca2srjW74BtUWGiFF52kD9KVnrLQNFX84UfWwTk2j",
        net_leverage: 0.0,
        open_ms: 1711313517769,
        orders: [
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "057e1b03-dad3-483d-9039-95de87b2b43e",
            price: 66279.34,
            price_sources: [],
            processed_ms: 1711313517766,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "1178c39d-4a38-4300-8b96-892be383508b",
            price: 67617.78,
            price_sources: [],
            processed_ms: 1711343147700,
          },
        ],
        position_type: "FLAT",
        position_uuid: "87f0f832-126c-4c91-a15c-9d8e8034197e",
        return_at_close: 1.0054545424984618,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 70710.16,
        close_ms: 1711396239830,
        current_return: 1.04246065911886,
        initial_entry_price: 70710.16,
        is_closed_position: true,
        miner_hotkey: "5Dhyo55Ca2srjW74BtUWGiFF52kD9KVnrLQNFX84UfWwTk2j",
        net_leverage: 0.0,
        open_ms: 1711395388025,
        orders: [
          {
            leverage: 15.0,
            order_type: "LONG",
            order_uuid: "d306b35b-ab8c-48e1-87c0-5e980f8d1fe9",
            price: 70710.16,
            price_sources: [],
            processed_ms: 1711395388022,
          },
          {
            leverage: 5.0,
            order_type: "FLAT",
            order_uuid: "2c89f39d-a3bf-47ee-8069-9aee81600a37",
            price: 70910.32,
            price_sources: [],
            processed_ms: 1711396239830,
          },
        ],
        position_type: "FLAT",
        position_uuid: "b4877559-9173-4445-8f0d-784e5f97a691",
        return_at_close: 1.0111868393452943,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 69643.53,
        close_ms: 1711472082431,
        current_return: 1.0280654642290536,
        initial_entry_price: 69643.53,
        is_closed_position: true,
        miner_hotkey: "5Dhyo55Ca2srjW74BtUWGiFF52kD9KVnrLQNFX84UfWwTk2j",
        net_leverage: 0.0,
        open_ms: 1711471725055,
        orders: [
          {
            leverage: 19.9,
            order_type: "LONG",
            order_uuid: "d5e9a232-2ebe-4b6d-bd3c-1d1d9bf571e4",
            price: 69643.53,
            price_sources: [],
            processed_ms: 1711471725052,
          },
          {
            leverage: 0.10000000000000142,
            order_type: "FLAT",
            order_uuid: "3a3ba4d7-1a74-4339-8409-c05101ce9581",
            price: 69741.75,
            price_sources: [],
            processed_ms: 1711472082431,
          },
        ],
        position_type: "FLAT",
        position_uuid: "0a72070f-2044-4f3a-81bf-f290486a2d9a",
        return_at_close: 0.9871484587527374,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 69649.23,
        close_ms: 1711472234147,
        current_return: 0.9975914019437133,
        initial_entry_price: 69649.23,
        is_closed_position: true,
        miner_hotkey: "5Dhyo55Ca2srjW74BtUWGiFF52kD9KVnrLQNFX84UfWwTk2j",
        net_leverage: 0.0,
        open_ms: 1711472150710,
        orders: [
          {
            leverage: 19.9,
            order_type: "LONG",
            order_uuid: "dcff1165-174d-432b-89a8-c1f3b1df6594",
            price: 69649.23,
            price_sources: [],
            processed_ms: 1711472150707,
          },
          {
            leverage: 0.10000000000000142,
            order_type: "FLAT",
            order_uuid: "f23d4e9a-0b4c-46b6-90eb-242f5faad5e6",
            price: 69640.8,
            price_sources: [],
            processed_ms: 1711472234147,
          },
        ],
        position_type: "FLAT",
        position_uuid: "1f0b189d-010f-48ba-856f-3a0d615811b5",
        return_at_close: 0.9578872641463536,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 0.6528,
        close_ms: 1711644765410,
        current_return: 1.2675398284314279,
        initial_entry_price: 0.6528,
        is_closed_position: true,
        miner_hotkey: "5Dhyo55Ca2srjW74BtUWGiFF52kD9KVnrLQNFX84UfWwTk2j",
        net_leverage: 0.0,
        open_ms: 1711644597369,
        orders: [
          {
            leverage: -499.0,
            order_type: "SHORT",
            order_uuid: "77be8f49-94b4-4d34-bc33-3fde6074953e",
            price: 0.6528,
            price_sources: [],
            processed_ms: 1711644597366,
          },
          {
            leverage: 499.0,
            order_type: "FLAT",
            order_uuid: "e5fb70e7-937f-4d12-ada4-c0980c0716b0",
            price: 0.65245,
            price_sources: [],
            processed_ms: 1711644765410,
          },
        ],
        position_type: "FLAT",
        position_uuid: "e73d143d-1082-4eaf-b8cc-526e0fa2c60d",
        return_at_close: 1.2232646622243182,
        trade_pair: ["AUDUSD", "AUD/USD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 1.07875,
        close_ms: 1711653425922,
        current_return: 1.1156431054461569,
        initial_entry_price: 1.07875,
        is_closed_position: true,
        miner_hotkey: "5Dhyo55Ca2srjW74BtUWGiFF52kD9KVnrLQNFX84UfWwTk2j",
        net_leverage: 0.0,
        open_ms: 1711653299727,
        orders: [
          {
            leverage: -499.0,
            order_type: "SHORT",
            order_uuid: "9ebf4448-11e1-4452-a5ba-3cf44ec5eac9",
            price: 1.07875,
            price_sources: [],
            processed_ms: 1711653299723,
          },
          {
            leverage: 499.0,
            order_type: "FLAT",
            order_uuid: "282fb82c-b5c4-4fb6-bca7-5994d855b79b",
            price: 1.0785,
            price_sources: [],
            processed_ms: 1711653425922,
          },
        ],
        position_type: "FLAT",
        position_uuid: "1c7e3b76-c651-4a53-bbd2-5ca0ede8dc40",
        return_at_close: 1.0766736917729225,
        trade_pair: ["EURUSD", "EUR/USD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 1.0786,
        close_ms: 1711653565172,
        current_return: 1.0,
        initial_entry_price: 1.0786,
        is_closed_position: true,
        miner_hotkey: "5Dhyo55Ca2srjW74BtUWGiFF52kD9KVnrLQNFX84UfWwTk2j",
        net_leverage: 0.0,
        open_ms: 1711653497273,
        orders: [
          {
            leverage: 499.0,
            order_type: "LONG",
            order_uuid: "ae9d1c94-0e99-429c-9657-2efcb0b81867",
            price: 1.0786,
            price_sources: [],
            processed_ms: 1711653497270,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "7d54c18f-0103-4f6c-843f-9047a6ac5416",
            price: 1.0786,
            price_sources: [],
            processed_ms: 1711653565172,
          },
        ],
        position_type: "FLAT",
        position_uuid: "3e3401f7-274a-4195-bc54-13b221b69724",
        return_at_close: 0.96507,
        trade_pair: ["EURUSD", "EUR/USD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 1.0786,
        close_ms: 1711653828066,
        current_return: 1.0462636751344285,
        initial_entry_price: 1.0786,
        is_closed_position: true,
        miner_hotkey: "5Dhyo55Ca2srjW74BtUWGiFF52kD9KVnrLQNFX84UfWwTk2j",
        net_leverage: 0.0,
        open_ms: 1711653699940,
        orders: [
          {
            leverage: -499.0,
            order_type: "SHORT",
            order_uuid: "630eb935-9367-4eda-80f6-28ba84e65932",
            price: 1.0786,
            price_sources: [],
            processed_ms: 1711653699937,
          },
          {
            leverage: 499.0,
            order_type: "FLAT",
            order_uuid: "d6ea7b33-d1b5-4e61-9279-13636fb00b8c",
            price: 1.0785,
            price_sources: [],
            processed_ms: 1711653828066,
          },
        ],
        position_type: "FLAT",
        position_uuid: "341488fe-0b90-4439-bb62-6af579f2d7bb",
        return_at_close: 1.0097176849619829,
        trade_pair: ["EURUSD", "EUR/USD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 1.07855,
        close_ms: 1711656534455,
        current_return: 1.0,
        initial_entry_price: 1.07855,
        is_closed_position: true,
        miner_hotkey: "5Dhyo55Ca2srjW74BtUWGiFF52kD9KVnrLQNFX84UfWwTk2j",
        net_leverage: 0.0,
        open_ms: 1711656477473,
        orders: [
          {
            leverage: -499.0,
            order_type: "SHORT",
            order_uuid: "ffdf9cc2-af81-4eb0-a6f7-9790d6a1f937",
            price: 1.07855,
            price_sources: [],
            processed_ms: 1711656477470,
          },
          {
            leverage: 499.0,
            order_type: "FLAT",
            order_uuid: "f6cf7a5d-92bc-4799-999b-3ca1bccc0851",
            price: 1.07855,
            price_sources: [],
            processed_ms: 1711656534455,
          },
        ],
        position_type: "FLAT",
        position_uuid: "1086a1f0-cea8-4564-a26f-0ca9fe7cec33",
        return_at_close: 0.96507,
        trade_pair: ["EURUSD", "EUR/USD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.8087,
        close_ms: 1711667334140,
        current_return: 1.1234079386669829,
        initial_entry_price: 0.8087,
        is_closed_position: true,
        miner_hotkey: "5Dhyo55Ca2srjW74BtUWGiFF52kD9KVnrLQNFX84UfWwTk2j",
        net_leverage: 0.0,
        open_ms: 1711667066206,
        orders: [
          {
            leverage: 499.0,
            order_type: "LONG",
            order_uuid: "ed1ce026-8439-4940-b43d-46f59ce8e782",
            price: 0.8087,
            price_sources: [],
            processed_ms: 1711667066203,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "4ca5f2de-275a-4f47-9d85-2a9d9b25bcbb",
            price: 0.8089,
            price_sources: [],
            processed_ms: 1711667334140,
          },
        ],
        position_type: "FLAT",
        position_uuid: "82e5da61-2e8b-48d0-aa9c-f42f8dd760dc",
        return_at_close: 1.0841672993693452,
        trade_pair: ["NZDCAD", "NZD/CAD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.809,
        close_ms: 1711667779061,
        current_return: 0.814956736711942,
        initial_entry_price: 0.809,
        is_closed_position: true,
        miner_hotkey: "5Dhyo55Ca2srjW74BtUWGiFF52kD9KVnrLQNFX84UfWwTk2j",
        net_leverage: 499.0,
        open_ms: 1711667710829,
        orders: [
          {
            leverage: 499.0,
            order_type: "LONG",
            order_uuid: "449d3bbf-0896-4909-9eee-200781197a8c",
            price: 0.809,
            price_sources: [],
            processed_ms: 1711667710826,
          },
        ],
        position_type: "FLAT",
        position_uuid: "594004a5-1dd5-40a4-8d37-205d4f502074",
        return_at_close: 0.7864902978985939,
        trade_pair: ["NZDCAD", "NZD/CAD", 7e-5, 0.001, 500],
      },
    ],
    thirty_day_returns: 1.0,
  },
  "5DjqgrgQcKdrwGDg7RhSkxjnAVWwVgYTBodAdss233s3zJ6T": {
    all_time_returns: 1.0,
    n_positions: 0,
    percentage_profitable: 0.0,
    positions: [
      {
        average_entry_price: 1.357205,
        close_ms: 1717607898632,
        current_return: 1.0022474765015774,
        initial_entry_price: 1.367645,
        is_closed_position: true,
        miner_hotkey: "5DjqgrgQcKdrwGDg7RhSkxjnAVWwVgYTBodAdss233s3zJ6T",
        net_leverage: 0.0,
        open_ms: 1717588200967,
        orders: [
          {
            leverage: 1.0,
            order_type: "LONG",
            order_uuid: "17ad7bd4-e23a-4ec4-b983-0f133b5927da",
            price: 1.367645,
            price_sources: [],
            processed_ms: 1717588200875,
          },
          {
            leverage: -0.5,
            order_type: "SHORT",
            order_uuid: "bec005a0-80db-4361-9ac5-84bcdb954ca5",
            price: 1.37028,
            price_sources: [],
            processed_ms: 1717595702312,
          },
          {
            leverage: -0.25,
            order_type: "SHORT",
            order_uuid: "5e514736-c57b-4b94-879f-cb623f3edf47",
            price: 1.3728150000000001,
            price_sources: [],
            processed_ms: 1717596461349,
          },
          {
            leverage: 0.01,
            order_type: "FLAT",
            order_uuid: "4b90ae41-63a7-48c5-830a-f5583c4178cc",
            price: 1.3695,
            price_sources: [],
            processed_ms: 1717607898632,
          },
        ],
        position_type: "FLAT",
        position_uuid: "1bd42e3e-4ed6-430c-a937-20d042fadb02",
        return_at_close: 1.0021773191782224,
        trade_pair: ["USDCAD", "USD/CAD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.84551,
        close_ms: 1717651687398,
        current_return: 1.000900854546624,
        initial_entry_price: 0.84551,
        is_closed_position: true,
        miner_hotkey: "5DjqgrgQcKdrwGDg7RhSkxjnAVWwVgYTBodAdss233s3zJ6T",
        net_leverage: 0.0,
        open_ms: 1717588344320,
        orders: [
          {
            leverage: 0.5,
            order_type: "LONG",
            order_uuid: "cf4ec576-8095-46d4-afb5-f03a0e2b2baf",
            price: 0.84551,
            price_sources: [],
            processed_ms: 1717588344124,
          },
          {
            leverage: 0.5,
            order_type: "FLAT",
            order_uuid: "6eef131d-2aa5-457e-a3f5-cbce47a50e27",
            price: 0.847033363055432,
            price_sources: [],
            processed_ms: 1717651687398,
          },
        ],
        position_type: "FLAT",
        position_uuid: "d9cacc4c-8a21-46fb-93a0-0cc47183e62b",
        return_at_close: 1.000865823016715,
        trade_pair: ["NZDCAD", "NZD/CAD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.96981,
        close_ms: 1717750938498,
        current_return: 1.0007166352172074,
        initial_entry_price: 0.96981,
        is_closed_position: true,
        miner_hotkey: "5DjqgrgQcKdrwGDg7RhSkxjnAVWwVgYTBodAdss233s3zJ6T",
        net_leverage: 0.0,
        open_ms: 1717677510368,
        orders: [
          {
            leverage: -0.5,
            order_type: "SHORT",
            order_uuid: "95cc14b6-462e-40ba-b93a-a3c287e7867b",
            price: 0.96981,
            price_sources: [],
            processed_ms: 1717677510366,
          },
          {
            leverage: 0.01,
            order_type: "FLAT",
            order_uuid: "6ff099a0-f2e7-49c2-91df-143459fa514b",
            price: 0.9684200000000001,
            price_sources: [],
            processed_ms: 1717750938498,
          },
        ],
        position_type: "FLAT",
        position_uuid: "cd2b4935-dc10-44b0-9860-da57ffd9cbae",
        return_at_close: 1.000681610134975,
        trade_pair: ["EURCHF", "EUR/CHF", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 3686.25,
        close_ms: 1717962973552,
        current_return: 1.000816547982367,
        initial_entry_price: 3686.25,
        is_closed_position: true,
        miner_hotkey: "5DjqgrgQcKdrwGDg7RhSkxjnAVWwVgYTBodAdss233s3zJ6T",
        net_leverage: 0.0,
        open_ms: 1717820646462,
        orders: [
          {
            leverage: 0.2,
            order_type: "LONG",
            order_uuid: "04c4736b-e22a-449d-8a36-3e2999cdc088",
            price: 3686.25,
            price_sources: [],
            processed_ms: 1717820646243,
          },
          {
            leverage: 0.2,
            order_type: "FLAT",
            order_uuid: "6e35e64e-78a0-463e-9f37-4e69cb51fb50",
            price: 3701.3,
            price_sources: [],
            processed_ms: 1717962973552,
          },
        ],
        position_type: "FLAT",
        position_uuid: "ab34d87f-b96b-42e3-94fc-0a916efc88bd",
        return_at_close: 1.0006163846727705,
        trade_pair: ["ETHUSD", "ETH/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 1.2853225000000001,
        close_ms: 1717968309304,
        current_return: 1.002082754355544,
        initial_entry_price: 1.277395,
        is_closed_position: true,
        miner_hotkey: "5DjqgrgQcKdrwGDg7RhSkxjnAVWwVgYTBodAdss233s3zJ6T",
        net_leverage: 0.0,
        open_ms: 1717673065113,
        orders: [
          {
            leverage: -0.5,
            order_type: "SHORT",
            order_uuid: "f6164cda-c5c1-4dbb-92b8-8d77521b7dee",
            price: 1.277395,
            price_sources: [],
            processed_ms: 1717673065109,
          },
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "3102a75d-1224-4e47-85ad-02490aeb2a84",
            price: 1.27211,
            price_sources: [],
            processed_ms: 1717772194213,
          },
          {
            leverage: 0.01,
            order_type: "FLAT",
            order_uuid: "55920a74-2c0a-42cd-8da3-5955cdd5bf58",
            price: 1.27202,
            price_sources: [],
            processed_ms: 1717968309304,
          },
        ],
        position_type: "FLAT",
        position_uuid: "be0d66c3-b064-4632-b9eb-25fff3bc1019",
        return_at_close: 1.0020476814591415,
        trade_pair: ["GBPUSD", "GBP/USD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 1.1353399999999993,
        close_ms: 1717968359469,
        current_return: 1.0053526546920888,
        initial_entry_price: 1.08619,
        is_closed_position: true,
        miner_hotkey: "5DjqgrgQcKdrwGDg7RhSkxjnAVWwVgYTBodAdss233s3zJ6T",
        net_leverage: 0.0,
        open_ms: 1717608284835,
        orders: [
          {
            leverage: -0.5,
            order_type: "SHORT",
            order_uuid: "474854ef-7960-4739-8ecf-6ca2faf8dfa3",
            price: 1.08619,
            price_sources: [],
            processed_ms: 1717608284567,
          },
          {
            leverage: -0.5,
            order_type: "SHORT",
            order_uuid: "7a389dce-02d5-4845-9b13-0067c45343e3",
            price: 1.08693,
            price_sources: [],
            processed_ms: 1717672942352,
          },
          {
            leverage: 0.9,
            order_type: "LONG",
            order_uuid: "43fd3340-23f3-4957-91fb-6ab3a88f406f",
            price: 1.08114,
            price_sources: [],
            processed_ms: 1717772295425,
          },
          {
            leverage: 0.01,
            order_type: "FLAT",
            order_uuid: "7a677972-e607-4191-963b-505c71aeefb1",
            price: 1.0772,
            price_sources: [],
            processed_ms: 1717968359469,
          },
        ],
        position_type: "FLAT",
        position_uuid: "072e9936-2607-4b43-a205-526aa981fd7b",
        return_at_close: 1.0052822800062604,
        trade_pair: ["EURUSD", "EUR/USD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 1.3762,
        close_ms: 1718008073834,
        current_return: 1.0003669524778376,
        initial_entry_price: 1.3762,
        is_closed_position: true,
        miner_hotkey: "5DjqgrgQcKdrwGDg7RhSkxjnAVWwVgYTBodAdss233s3zJ6T",
        net_leverage: 0.0,
        open_ms: 1717968560593,
        orders: [
          {
            leverage: 0.5,
            order_type: "LONG",
            order_uuid: "3362ff7b-b8df-49e6-a48a-552a0ec64c2f",
            price: 1.3762,
            price_sources: [],
            processed_ms: 1717968560328,
          },
          {
            leverage: 0.01,
            order_type: "FLAT",
            order_uuid: "68d2616f-01bd-4417-b47b-9e8c85c08d6a",
            price: 1.37721,
            price_sources: [],
            processed_ms: 1718008073834,
          },
        ],
        position_type: "FLAT",
        position_uuid: "f9d911c6-8268-45d8-a707-fb16c99af857",
        return_at_close: 1.000331939634501,
        trade_pair: ["USDCAD", "USD/CAD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 1.2721224999999998,
        close_ms: 1718100538082,
        current_return: 1.0004146686581243,
        initial_entry_price: 1.2721,
        is_closed_position: true,
        miner_hotkey: "5DjqgrgQcKdrwGDg7RhSkxjnAVWwVgYTBodAdss233s3zJ6T",
        net_leverage: 0.0,
        open_ms: 1718006325765,
        orders: [
          {
            leverage: 0.5,
            order_type: "LONG",
            order_uuid: "6ab4e36e-ac34-4d46-bb3b-c78910114278",
            price: 1.2721,
            price_sources: [],
            processed_ms: 1718006325762,
          },
          {
            leverage: -0.3,
            order_type: "SHORT",
            order_uuid: "a63c24b0-68d7-4e7d-9e87-527c5a75a138",
            price: 1.2720850000000001,
            price_sources: [],
            processed_ms: 1718088204892,
          },
          {
            leverage: 0.01,
            order_type: "FLAT",
            order_uuid: "6425ed2f-64f8-4563-86fa-3545bf6f96ca",
            price: 1.27476,
            price_sources: [],
            processed_ms: 1718100538082,
          },
        ],
        position_type: "FLAT",
        position_uuid: "d7cfab6e-3dab-42bc-bf90-de64cbdab03a",
        return_at_close: 1.0003796541447214,
        trade_pair: ["GBPUSD", "GBP/USD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.6159864999999999,
        close_ms: 1718195520201,
        current_return: 1.0061203554119549,
        initial_entry_price: 0.619,
        is_closed_position: true,
        miner_hotkey: "5DjqgrgQcKdrwGDg7RhSkxjnAVWwVgYTBodAdss233s3zJ6T",
        net_leverage: 0.0,
        open_ms: 1717651657496,
        orders: [
          {
            leverage: 0.5,
            order_type: "LONG",
            order_uuid: "26046a65-9335-4718-b441-d1fb8f8cc723",
            price: 0.619,
            price_sources: [],
            processed_ms: 1717651656981,
          },
          {
            leverage: -0.4,
            order_type: "SHORT",
            order_uuid: "b25991c1-76db-4b48-bb80-5449710738f5",
            price: 0.618,
            price_sources: [],
            processed_ms: 1717673006915,
          },
          {
            leverage: 0.4,
            order_type: "LONG",
            order_uuid: "b832d7f4-3879-4ab4-bd6b-f4df0b003725",
            price: 0.61817,
            price_sources: [],
            processed_ms: 1717677641145,
          },
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "4caa46a3-9eac-40ab-bcfa-779641a7d008",
            price: 0.612885,
            price_sources: [],
            processed_ms: 1718088246684,
          },
          {
            leverage: 0.2,
            order_type: "LONG",
            order_uuid: "c870f8f1-26d6-4b7d-b809-865fabcde1f3",
            price: 0.612765,
            price_sources: [],
            processed_ms: 1718100600349,
          },
          {
            leverage: 0.5,
            order_type: "FLAT",
            order_uuid: "79cf97fd-b023-4816-9e34-f68ced82b727",
            price: 0.619775,
            price_sources: [],
            processed_ms: 1718195520201,
          },
        ],
        position_type: "FLAT",
        position_uuid: "7c3067df-f83a-4d14-b8ed-d4872cc6e58c",
        return_at_close: 1.0060217556171245,
        trade_pair: ["NZDUSD", "NZD/USD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 157.98450000000003,
        close_ms: 1718354010969,
        current_return: 1.0009605372678967,
        initial_entry_price: 157.98450000000003,
        is_closed_position: true,
        miner_hotkey: "5DjqgrgQcKdrwGDg7RhSkxjnAVWwVgYTBodAdss233s3zJ6T",
        net_leverage: 0.0,
        open_ms: 1718349666173,
        orders: [
          {
            leverage: -0.5,
            order_type: "SHORT",
            order_uuid: "ab2dcad0-f802-4f6c-846b-d375e458ed8e",
            price: 157.98450000000003,
            price_sources: [],
            processed_ms: 1718349666150,
          },
          {
            leverage: 0.5,
            order_type: "FLAT",
            order_uuid: "55d732db-1da7-482c-bfe7-b1504a4d1ac4",
            price: 157.681,
            price_sources: [],
            processed_ms: 1718354010969,
          },
        ],
        position_type: "FLAT",
        position_uuid: "72614097-834b-4a51-a558-9bde7ebb05f0",
        return_at_close: 1.0009255036490923,
        trade_pair: ["USDJPY", "USD/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.8441,
        close_ms: 1718354882969,
        current_return: 0.9978083165501718,
        initial_entry_price: 0.8441,
        is_closed_position: true,
        miner_hotkey: "5DjqgrgQcKdrwGDg7RhSkxjnAVWwVgYTBodAdss233s3zJ6T",
        net_leverage: 0.0,
        open_ms: 1718208641830,
        orders: [
          {
            leverage: 0.5,
            order_type: "LONG",
            order_uuid: "8e6f3582-4a20-4f69-a25a-59d7d7c3a425",
            price: 0.8441,
            price_sources: [],
            processed_ms: 1718208641811,
          },
          {
            leverage: 0.5,
            order_type: "FLAT",
            order_uuid: "1590453b-8699-4dec-b92d-131e3cdc1447",
            price: 0.8404,
            price_sources: [],
            processed_ms: 1718354882969,
          },
        ],
        position_type: "FLAT",
        position_uuid: "b59110b0-2588-4595-9143-571bf72535da",
        return_at_close: 0.9977733932590925,
        trade_pair: ["EURGBP", "EUR/GBP", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 1.4879499999999999,
        close_ms: 1718357983432,
        current_return: 0.974656556107569,
        initial_entry_price: 1.49151,
        is_closed_position: true,
        miner_hotkey: "5DjqgrgQcKdrwGDg7RhSkxjnAVWwVgYTBodAdss233s3zJ6T",
        net_leverage: 0.0,
        open_ms: 1717595651467,
        orders: [
          {
            leverage: 0.5,
            order_type: "LONG",
            order_uuid: "8d44bff6-1719-466a-994b-20e90ed054b6",
            price: 1.49151,
            price_sources: [],
            processed_ms: 1717595651465,
          },
          {
            leverage: -0.4,
            order_type: "SHORT",
            order_uuid: "ea153218-f22b-4764-ad0c-a497ea1f9943",
            price: 1.4880499999999999,
            price_sources: [],
            processed_ms: 1717672875978,
          },
          {
            leverage: 1.0,
            order_type: "LONG",
            order_uuid: "3f2ec343-04c9-48a1-9fb0-eefe3828cda2",
            price: 1.489975,
            price_sources: [],
            processed_ms: 1717682260033,
          },
          {
            leverage: 0.4,
            order_type: "LONG",
            order_uuid: "e9c7015e-8c1e-4719-a7f5-b82c36d1a89d",
            price: 1.48515,
            price_sources: [],
            processed_ms: 1717772264557,
          },
          {
            leverage: -0.5,
            order_type: "SHORT",
            order_uuid: "f119785c-9127-467d-91c4-a104483e1a32",
            price: 1.47963,
            price_sources: [],
            processed_ms: 1717999040626,
          },
          {
            leverage: 0.5,
            order_type: "LONG",
            order_uuid: "363c861f-9675-40cf-a624-9e97ca512671",
            price: 1.48239,
            price_sources: [],
            processed_ms: 1718008104373,
          },
          {
            leverage: 0.5,
            order_type: "LONG",
            order_uuid: "c9182428-b5eb-4cfa-b08b-2f1f8b898c06",
            price: 1.4799,
            price_sources: [],
            processed_ms: 1718101108265,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "7dba9b71-2fb1-440b-b18d-4886a6de5c57",
            price: 1.46905,
            price_sources: [],
            processed_ms: 1718357983432,
          },
        ],
        position_type: "FLAT",
        position_uuid: "983047af-9135-4da3-ae15-e5737e4cbbe4",
        return_at_close: 0.9744587008266792,
        trade_pair: ["EURCAD", "EUR/CAD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 156.899,
        close_ms: 1718368901167,
        current_return: 0.9969343335521578,
        initial_entry_price: 156.899,
        is_closed_position: true,
        miner_hotkey: "5DjqgrgQcKdrwGDg7RhSkxjnAVWwVgYTBodAdss233s3zJ6T",
        net_leverage: 0.0,
        open_ms: 1718360244721,
        orders: [
          {
            leverage: -1.0,
            order_type: "SHORT",
            order_uuid: "dc0775dd-2f22-48c1-ac71-1d99f431f568",
            price: 156.899,
            price_sources: [],
            processed_ms: 1718360244696,
          },
          {
            leverage: 0.01,
            order_type: "FLAT",
            order_uuid: "ca07f054-5261-4a3b-a84e-b1c9ab38c2d6",
            price: 157.38,
            price_sources: [],
            processed_ms: 1718368901167,
          },
        ],
        position_type: "FLAT",
        position_uuid: "458d4f01-81f2-4373-94b4-32dd0705e9a6",
        return_at_close: 0.9968645481488091,
        trade_pair: ["USDJPY", "USD/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 114.22866666666668,
        close_ms: 1718368997376,
        current_return: 0.999471504944268,
        initial_entry_price: 114.476,
        is_closed_position: true,
        miner_hotkey: "5DjqgrgQcKdrwGDg7RhSkxjnAVWwVgYTBodAdss233s3zJ6T",
        net_leverage: 0.0,
        open_ms: 1718354940134,
        orders: [
          {
            leverage: -0.5,
            order_type: "SHORT",
            order_uuid: "c3d941ed-4880-414c-b5de-69ca1cda2b0d",
            price: 114.476,
            price_sources: [],
            processed_ms: 1718354940122,
          },
          {
            leverage: -1.0,
            order_type: "SHORT",
            order_uuid: "f59ed451-d365-4fd6-a235-4fe20b187cde",
            price: 114.105,
            price_sources: [],
            processed_ms: 1718358040546,
          },
          {
            leverage: 0.01,
            order_type: "FLAT",
            order_uuid: "edc0555f-b080-4d80-85e2-babb67730d86",
            price: 114.269,
            price_sources: [],
            processed_ms: 1718368997376,
          },
        ],
        position_type: "FLAT",
        position_uuid: "2a114c70-8873-41ce-a334-95805960368b",
        return_at_close: 0.9993665604362488,
        trade_pair: ["CADJPY", "CAD/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 167.97799999999998,
        close_ms: 1718373439148,
        current_return: 1.0018886526423318,
        initial_entry_price: 168.374,
        is_closed_position: true,
        miner_hotkey: "5DjqgrgQcKdrwGDg7RhSkxjnAVWwVgYTBodAdss233s3zJ6T",
        net_leverage: 0.0,
        open_ms: 1718354986596,
        orders: [
          {
            leverage: -0.5,
            order_type: "SHORT",
            order_uuid: "e7dfe657-7b91-4998-a08d-3458e3d3b30d",
            price: 168.374,
            price_sources: [],
            processed_ms: 1718354986501,
          },
          {
            leverage: -1.0,
            order_type: "SHORT",
            order_uuid: "0adef248-cd6d-4e64-8bce-15719442e4a0",
            price: 167.78,
            price_sources: [],
            processed_ms: 1718360153988,
          },
          {
            leverage: 0.01,
            order_type: "FLAT",
            order_uuid: "87010a1e-0a29-4ade-96b5-c3b69dacb92a",
            price: 167.766,
            price_sources: [],
            processed_ms: 1718373439148,
          },
        ],
        position_type: "FLAT",
        position_uuid: "5a12b7b0-bf16-4c4f-8d38-63197b89a897",
        return_at_close: 1.0017834543338044,
        trade_pair: ["EURJPY", "EUR/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.612645,
        close_ms: 1718623876529,
        current_return: 1.000987521321483,
        initial_entry_price: 0.612645,
        is_closed_position: true,
        miner_hotkey: "5DjqgrgQcKdrwGDg7RhSkxjnAVWwVgYTBodAdss233s3zJ6T",
        net_leverage: 0.0,
        open_ms: 1718368966704,
        orders: [
          {
            leverage: -1.0,
            order_type: "SHORT",
            order_uuid: "fbfd0a5e-7f81-4023-944f-9bcb4d411a3e",
            price: 0.612645,
            price_sources: [],
            processed_ms: 1718368966511,
          },
          {
            leverage: 0.01,
            order_type: "FLAT",
            order_uuid: "942057a6-211c-44f3-8baf-14ea945decb3",
            price: 0.61204,
            price_sources: [],
            processed_ms: 1718623876529,
          },
        ],
        position_type: "FLAT",
        position_uuid: "92f4e350-e337-44f7-9beb-04331514d7b7",
        return_at_close: 1.0009174521949904,
        trade_pair: ["NZDUSD", "NZD/USD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.65971,
        close_ms: 1718638840040,
        current_return: 1.0003941125646119,
        initial_entry_price: 0.65971,
        is_closed_position: true,
        miner_hotkey: "5DjqgrgQcKdrwGDg7RhSkxjnAVWwVgYTBodAdss233s3zJ6T",
        net_leverage: 0.0,
        open_ms: 1718373527479,
        orders: [
          {
            leverage: -1.0,
            order_type: "SHORT",
            order_uuid: "b0a3ec6d-7de6-46a7-b626-48ee49677eea",
            price: 0.65971,
            price_sources: [],
            processed_ms: 1718373527456,
          },
          {
            leverage: 0.01,
            order_type: "FLAT",
            order_uuid: "c84fb4d6-de10-4c66-a9c2-fea5b5696e2d",
            price: 0.65945,
            price_sources: [],
            processed_ms: 1718638840040,
          },
        ],
        position_type: "FLAT",
        position_uuid: "35e80118-938a-48fc-9250-ca7d5b6b8ad7",
        return_at_close: 1.0003240849767323,
        trade_pair: ["AUDUSD", "AUD/USD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 1.2659500000000001,
        close_ms: 1718645584940,
        current_return: 0.9968758639756705,
        initial_entry_price: 1.2659500000000001,
        is_closed_position: true,
        miner_hotkey: "5DjqgrgQcKdrwGDg7RhSkxjnAVWwVgYTBodAdss233s3zJ6T",
        net_leverage: 0.0,
        open_ms: 1718373497325,
        orders: [
          {
            leverage: -1.0,
            order_type: "SHORT",
            order_uuid: "5ecd1d88-bad4-4340-8241-6397877604f6",
            price: 1.2659500000000001,
            price_sources: [],
            processed_ms: 1718373497285,
          },
          {
            leverage: 0.01,
            order_type: "FLAT",
            order_uuid: "876820d6-8f80-4a72-a9b3-27ac1ac7e49a",
            price: 1.269905,
            price_sources: [],
            processed_ms: 1718645584940,
          },
        ],
        position_type: "FLAT",
        position_uuid: "1371a3a7-7d9e-4755-99d4-68c1225f07de",
        return_at_close: 0.9968060826651922,
        trade_pair: ["GBPUSD", "GBP/USD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.840906865744707,
        close_ms: 1718645631401,
        current_return: 0.9997543519549414,
        initial_entry_price: 0.840906865744707,
        is_closed_position: true,
        miner_hotkey: "5DjqgrgQcKdrwGDg7RhSkxjnAVWwVgYTBodAdss233s3zJ6T",
        net_leverage: 0.0,
        open_ms: 1718623923779,
        orders: [
          {
            leverage: -0.5,
            order_type: "SHORT",
            order_uuid: "53a582c9-baa6-4013-b106-7c175dcc52b5",
            price: 0.840906865744707,
            price_sources: [],
            processed_ms: 1718623923622,
          },
          {
            leverage: 0.01,
            order_type: "FLAT",
            order_uuid: "18abbb82-80b9-447f-9bc1-96416132b20d",
            price: 0.84132,
            price_sources: [],
            processed_ms: 1718645631401,
          },
        ],
        position_type: "FLAT",
        position_uuid: "71e5ffe2-9332-4763-800a-cd843144305c",
        return_at_close: 0.999719360552623,
        trade_pair: ["NZDCAD", "NZD/CAD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 1.06851,
        close_ms: 1718645663396,
        current_return: 0.9963594163835622,
        initial_entry_price: 1.06851,
        is_closed_position: true,
        miner_hotkey: "5DjqgrgQcKdrwGDg7RhSkxjnAVWwVgYTBodAdss233s3zJ6T",
        net_leverage: 0.0,
        open_ms: 1718368931488,
        orders: [
          {
            leverage: -1.0,
            order_type: "SHORT",
            order_uuid: "c6d6dfee-419c-4015-b00f-a45be2fcec63",
            price: 1.06851,
            price_sources: [],
            processed_ms: 1718368931465,
          },
          {
            leverage: 0.01,
            order_type: "FLAT",
            order_uuid: "28b8eb71-5895-4ae0-b94c-5f6c6ce0d4c3",
            price: 1.0724,
            price_sources: [],
            processed_ms: 1718645663396,
          },
        ],
        position_type: "FLAT",
        position_uuid: "dcdc9c3e-2603-412f-b31f-db79cfa18be1",
        return_at_close: 0.9962896712244154,
        trade_pair: ["EURUSD", "EUR/USD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 96.651,
        close_ms: 1718699442208,
        current_return: 1.0034143464630474,
        initial_entry_price: 96.651,
        is_closed_position: true,
        miner_hotkey: "5DjqgrgQcKdrwGDg7RhSkxjnAVWwVgYTBodAdss233s3zJ6T",
        net_leverage: 0.0,
        open_ms: 1718645715306,
        orders: [
          {
            leverage: -2.0,
            order_type: "SHORT",
            order_uuid: "9054d9b7-26a0-47f6-9c41-f53db3b2961d",
            price: 96.651,
            price_sources: [],
            processed_ms: 1718645715242,
          },
          {
            leverage: 2.0,
            order_type: "FLAT",
            order_uuid: "4bff8ef0-4ebf-4d05-b79c-d4096a0e544a",
            price: 96.486,
            price_sources: [],
            processed_ms: 1718699442208,
          },
        ],
        position_type: "FLAT",
        position_uuid: "528e9844-40c5-4467-8ff7-8434c2b1f3dd",
        return_at_close: 1.0032738684545426,
        trade_pair: ["NZDJPY", "NZD/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 158.127,
        close_ms: 1718714751086,
        current_return: 1.0042750447425173,
        initial_entry_price: 158.127,
        is_closed_position: true,
        miner_hotkey: "5DjqgrgQcKdrwGDg7RhSkxjnAVWwVgYTBodAdss233s3zJ6T",
        net_leverage: 0.0,
        open_ms: 1718708758740,
        orders: [
          {
            leverage: -2.0,
            order_type: "SHORT",
            order_uuid: "80147d29-7023-4846-9eb6-a5d2fac7d66c",
            price: 158.127,
            price_sources: [],
            processed_ms: 1718708758634,
          },
          {
            leverage: 0.01,
            order_type: "FLAT",
            order_uuid: "13ad9b3c-b0fc-41ec-bae6-e117e7eb2987",
            price: 157.789,
            price_sources: [],
            processed_ms: 1718714751086,
          },
        ],
        position_type: "FLAT",
        position_uuid: "b05cdda6-c457-4f8e-8508-7704ba0acdec",
        return_at_close: 1.0041344462362534,
        trade_pair: ["USDJPY", "USD/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 200.36,
        close_ms: 1718715021661,
        current_return: 1.0015621880614893,
        initial_entry_price: 200.36,
        is_closed_position: true,
        miner_hotkey: "5DjqgrgQcKdrwGDg7RhSkxjnAVWwVgYTBodAdss233s3zJ6T",
        net_leverage: 0.0,
        open_ms: 1718645752303,
        orders: [
          {
            leverage: -2.0,
            order_type: "SHORT",
            order_uuid: "5d86d660-366b-47fa-9534-43ec0ba0b6b7",
            price: 200.36,
            price_sources: [],
            processed_ms: 1718645752284,
          },
          {
            leverage: 0.01,
            order_type: "FLAT",
            order_uuid: "b8354472-2b3a-4354-a6cc-9d9a72dc85ee",
            price: 200.20350000000002,
            price_sources: [],
            processed_ms: 1718715021661,
          },
        ],
        position_type: "FLAT",
        position_uuid: "37a3debb-8e00-4420-9d17-919b7b16b0d7",
        return_at_close: 1.0014219693551607,
        trade_pair: ["GBPJPY", "GBP/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.8832699999999999,
        close_ms: 1718871517801,
        current_return: 1.0410406783882622,
        initial_entry_price: 0.88327,
        is_closed_position: true,
        miner_hotkey: "5DjqgrgQcKdrwGDg7RhSkxjnAVWwVgYTBodAdss233s3zJ6T",
        net_leverage: 0.0,
        open_ms: 1718863286944,
        orders: [
          {
            leverage: 5.0,
            order_type: "LONG",
            order_uuid: "441bea47-e33c-42ca-bee6-36f15685172e",
            price: 0.88327,
            price_sources: [],
            processed_ms: 1718863286876,
          },
          {
            leverage: 0.01,
            order_type: "FLAT",
            order_uuid: "37e11366-5747-4d92-8897-58aeee9a513a",
            price: 0.89052,
            price_sources: [],
            processed_ms: 1718871517801,
          },
        ],
        position_type: "FLAT",
        position_uuid: "d6f14e2f-ddac-4a24-9cab-fbaeeeeb7fd2",
        return_at_close: 1.0406763141508264,
        trade_pair: ["USDCHF", "USD/CHF", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 169.129,
        close_ms: 0,
        current_return: 0.9419614613697235,
        initial_entry_price: 169.129,
        is_closed_position: false,
        miner_hotkey: "5DjqgrgQcKdrwGDg7RhSkxjnAVWwVgYTBodAdss233s3zJ6T",
        net_leverage: -2.0,
        open_ms: 1718780489117,
        orders: [
          {
            leverage: -2.0,
            order_type: "SHORT",
            order_uuid: "9c1418ba-26de-41a8-aa09-3d7ab9db944c",
            price: 169.129,
            price_sources: [],
            processed_ms: 1718780488929,
          },
        ],
        position_type: "SHORT",
        position_uuid: "5a3e5b57-fbb9-4bf0-93be-af6da553345d",
        return_at_close: 0.9418955240674276,
        trade_pair: ["EURJPY", "EUR/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 177.1522274154716,
        close_ms: 0,
        current_return: 0.9801869375080371,
        initial_entry_price: 177.1522274154716,
        is_closed_position: false,
        miner_hotkey: "5DjqgrgQcKdrwGDg7RhSkxjnAVWwVgYTBodAdss233s3zJ6T",
        net_leverage: -2.0,
        open_ms: 1718645784471,
        orders: [
          {
            leverage: -2.0,
            order_type: "SHORT",
            order_uuid: "3da7dd52-2cfc-4d8d-b6b4-358e3f9fd421",
            price: 177.1522274154716,
            price_sources: [],
            processed_ms: 1718645784350,
          },
        ],
        position_type: "SHORT",
        position_uuid: "13bcf091-c523-4656-af32-d807b2ba50e5",
        return_at_close: 0.9801183244224115,
        trade_pair: ["CHFJPY", "CHF/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 96.4965,
        close_ms: 0,
        current_return: 0.959490758732182,
        initial_entry_price: 96.4965,
        is_closed_position: false,
        miner_hotkey: "5DjqgrgQcKdrwGDg7RhSkxjnAVWwVgYTBodAdss233s3zJ6T",
        net_leverage: -2.0,
        open_ms: 1718714851414,
        orders: [
          {
            leverage: -2.0,
            order_type: "SHORT",
            order_uuid: "fc234131-a460-4845-87f4-359c69bfb4e5",
            price: 96.4965,
            price_sources: [],
            processed_ms: 1718714851178,
          },
        ],
        position_type: "SHORT",
        position_uuid: "89719e9b-6786-4b43-b37e-a6308b086658",
        return_at_close: 0.9594235943790708,
        trade_pair: ["NZDJPY", "NZD/JPY", 7e-5, 0.001, 500],
      },
    ],
    thirty_day_returns: 1.0,
  },
  "5Dk2u35LRYEi9SC5cWamtzRkdXJJDLES7gABuey6cJ6t1ajK": {
    all_time_returns: 1.0,
    n_positions: 0,
    percentage_profitable: 0.0,
    positions: [
      {
        average_entry_price: 62844.27199999999,
        close_ms: 1711147103574,
        current_return: 1.000200217076504,
        initial_entry_price: 62641.51,
        is_closed_position: true,
        miner_hotkey: "5Dk2u35LRYEi9SC5cWamtzRkdXJJDLES7gABuey6cJ6t1ajK",
        net_leverage: 0.0,
        open_ms: 1711146196812,
        orders: [
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "e30a460b-5110-42a8-ac8f-dd561f2ac797",
            price: 62641.51,
            price_sources: [],
            processed_ms: 1711146196811,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "f3c40787-1a92-45ee-88d1-93cd96268de7",
            price: 62609.97,
            price_sources: [],
            processed_ms: 1711146389463,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "e408c00d-301f-42fa-9e78-11e65a6cbde9",
            price: 62812.81,
            price_sources: [],
            processed_ms: 1711146563733,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "c9e62917-b55b-4e13-829b-35fe4b2c4afd",
            price: 62992.15,
            price_sources: [],
            processed_ms: 1711146745696,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "43417c4f-d0b2-4724-a2e8-de665dd914ac",
            price: 63164.92,
            price_sources: [],
            processed_ms: 1711146921663,
          },
          {
            leverage: 0.01,
            order_type: "FLAT",
            order_uuid: "8c0a7123-8810-429c-b473-8f5af8271bf5",
            price: 63095.11,
            price_sources: [],
            processed_ms: 1711147103574,
          },
        ],
        position_type: "FLAT",
        position_uuid: "72f67a21-ab87-442b-aedd-df643f0671cf",
        return_at_close: 1.0001001970547965,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 63106.836,
        close_ms: 1711148187634,
        current_return: 0.9998807561783233,
        initial_entry_price: 63121.09,
        is_closed_position: true,
        miner_hotkey: "5Dk2u35LRYEi9SC5cWamtzRkdXJJDLES7gABuey6cJ6t1ajK",
        net_leverage: 0.0,
        open_ms: 1711147278601,
        orders: [
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "3fd2e026-5150-470b-90e6-01f4f8b093b9",
            price: 63121.09,
            price_sources: [],
            processed_ms: 1711147278600,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "1242a124-d897-4e59-b567-0d67ba16e4e1",
            price: 63143.32,
            price_sources: [],
            processed_ms: 1711147460226,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "58288c65-0f43-4fea-937b-20b09bf082f8",
            price: 63135.68,
            price_sources: [],
            processed_ms: 1711147648785,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "39b18c81-38ae-4063-8878-b043fc3fd1ee",
            price: 63160.88,
            price_sources: [],
            processed_ms: 1711147829717,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "1b85ca34-2927-4010-bf1f-a028e8310619",
            price: 62973.21,
            price_sources: [],
            processed_ms: 1711148004687,
          },
          {
            leverage: 0.01,
            order_type: "FLAT",
            order_uuid: "a77be017-614f-4055-8f75-5f246f7b3f5a",
            price: 62956.3,
            price_sources: [],
            processed_ms: 1711148187634,
          },
        ],
        position_type: "FLAT",
        position_uuid: "f20eab32-761b-4305-9db5-f94cb8e2a214",
        return_at_close: 0.9997807681027054,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 63110.969999999994,
        close_ms: 1711149254684,
        current_return: 1.0001715279201315,
        initial_entry_price: 63027.64,
        is_closed_position: true,
        miner_hotkey: "5Dk2u35LRYEi9SC5cWamtzRkdXJJDLES7gABuey6cJ6t1ajK",
        net_leverage: 0.0,
        open_ms: 1711148361627,
        orders: [
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "c8c1a654-4cf0-4877-b0e7-748e376d60e0",
            price: 63027.64,
            price_sources: [],
            processed_ms: 1711148361626,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "9493a813-1546-45d4-8607-ec64c13a4ff7",
            price: 63030.02,
            price_sources: [],
            processed_ms: 1711148543532,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "a7f84a33-eace-4d92-89ad-bc02f0259ba3",
            price: 63136.62,
            price_sources: [],
            processed_ms: 1711148719156,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "d535bdb8-0361-49f1-a041-6dca9f2c1db5",
            price: 63132.68,
            price_sources: [],
            processed_ms: 1711148899845,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "3ab89a83-7001-4c97-b7b7-0c7f811383d5",
            price: 63227.89,
            price_sources: [],
            processed_ms: 1711149074555,
          },
          {
            leverage: 0.01,
            order_type: "FLAT",
            order_uuid: "6701618a-b3cd-4ac3-a161-d198512172b4",
            price: 63327.19,
            price_sources: [],
            processed_ms: 1711149254684,
          },
        ],
        position_type: "FLAT",
        position_uuid: "2bc5c2e0-475a-408a-9343-e4c81c5af0b7",
        return_at_close: 1.0000715107673395,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 63366.93599999999,
        close_ms: 1711150336932,
        current_return: 1.0000130060365884,
        initial_entry_price: 63332.13,
        is_closed_position: true,
        miner_hotkey: "5Dk2u35LRYEi9SC5cWamtzRkdXJJDLES7gABuey6cJ6t1ajK",
        net_leverage: 0.0,
        open_ms: 1711149430464,
        orders: [
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "a2babdc6-c40d-4e26-a459-15b1f2d22cd7",
            price: 63332.13,
            price_sources: [],
            processed_ms: 1711149430463,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "91e04056-7b1b-4022-8866-ef133b93cb9a",
            price: 63287.24,
            price_sources: [],
            processed_ms: 1711149611751,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "376d2661-14ee-4779-8158-5da92c16c3c8",
            price: 63371.6,
            price_sources: [],
            processed_ms: 1711149796451,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "c995b6bf-ebf0-4cd9-852b-9e664814b53f",
            price: 63466.56,
            price_sources: [],
            processed_ms: 1711149979736,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "1d0e0899-f574-469f-84c6-42605997daf5",
            price: 63377.15,
            price_sources: [],
            processed_ms: 1711150154834,
          },
          {
            leverage: 0.01,
            order_type: "FLAT",
            order_uuid: "fa70c316-21e2-4001-8cfd-ab2b2cada95a",
            price: 63383.41,
            price_sources: [],
            processed_ms: 1711150336932,
          },
        ],
        position_type: "FLAT",
        position_uuid: "b438769e-f97b-4e0b-aaee-73b935368bef",
        return_at_close: 0.9999130047359848,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 63461.86,
        close_ms: 1711151419816,
        current_return: 0.9999920127997053,
        initial_entry_price: 63413.96,
        is_closed_position: true,
        miner_hotkey: "5Dk2u35LRYEi9SC5cWamtzRkdXJJDLES7gABuey6cJ6t1ajK",
        net_leverage: 0.0,
        open_ms: 1711150512123,
        orders: [
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "8d851a0f-a424-4ef5-9c29-bac07fb53ed6",
            price: 63413.96,
            price_sources: [],
            processed_ms: 1711150512122,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "9d801f7f-c4ae-4543-a4b8-f90ea01ebeef",
            price: 63484.24,
            price_sources: [],
            processed_ms: 1711150693552,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "d633ec8e-8e23-4472-b865-f18380e5792f",
            price: 63425.88,
            price_sources: [],
            processed_ms: 1711150878109,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "722ab907-ac7b-4bb0-ac2f-7e97ee93ec88",
            price: 63479.5,
            price_sources: [],
            processed_ms: 1711151061803,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "718542ff-6ecb-4338-a204-2fb9c94f1636",
            price: 63505.72,
            price_sources: [],
            processed_ms: 1711151237911,
          },
          {
            leverage: 0.01,
            order_type: "FLAT",
            order_uuid: "947dd943-782a-49d4-95a3-7a242e3fdb34",
            price: 63451.73,
            price_sources: [],
            processed_ms: 1711151419816,
          },
        ],
        position_type: "FLAT",
        position_uuid: "769b4ccf-eb64-44c0-a9d0-b697f12a580a",
        return_at_close: 0.9998920135984253,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 63631.954000000005,
        close_ms: 1711152500702,
        current_return: 1.0000605780738652,
        initial_entry_price: 63493.27,
        is_closed_position: true,
        miner_hotkey: "5Dk2u35LRYEi9SC5cWamtzRkdXJJDLES7gABuey6cJ6t1ajK",
        net_leverage: 0.0,
        open_ms: 1711151595540,
        orders: [
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "3f726e38-8f9d-4fe1-aaa6-7754b3e7e00a",
            price: 63493.27,
            price_sources: [],
            processed_ms: 1711151595539,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "8cbf0b35-6cd5-47eb-ac28-4e63f178d245",
            price: 63588.57,
            price_sources: [],
            processed_ms: 1711151776504,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "62454123-f640-4eb6-8080-1d4121d21a45",
            price: 63600.16,
            price_sources: [],
            processed_ms: 1711151950929,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "9737134d-078e-4136-a262-5bd21e3c6340",
            price: 63712.44,
            price_sources: [],
            processed_ms: 1711152133818,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "77b68e79-e879-4c7b-94fa-58c6fa323f0c",
            price: 63765.33,
            price_sources: [],
            processed_ms: 1711152317869,
          },
          {
            leverage: 0.01,
            order_type: "FLAT",
            order_uuid: "46d179d2-60b6-43f5-b57b-4cf8bddd8e6d",
            price: 63708.88,
            price_sources: [],
            processed_ms: 1711152500702,
          },
        ],
        position_type: "FLAT",
        position_uuid: "dba3ce7a-dd7b-4968-a0fa-9c0d0cc67f80",
        return_at_close: 0.9999605720160578,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 63704.28400000001,
        close_ms: 1711153573555,
        current_return: 0.99992796006131,
        initial_entry_price: 63793.78,
        is_closed_position: true,
        miner_hotkey: "5Dk2u35LRYEi9SC5cWamtzRkdXJJDLES7gABuey6cJ6t1ajK",
        net_leverage: 0.0,
        open_ms: 1711152675893,
        orders: [
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "61029a38-e89a-46d9-9280-2d892b434812",
            price: 63793.78,
            price_sources: [],
            processed_ms: 1711152675892,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "c03e827d-74d7-4058-b286-829863e52bca",
            price: 63685.15,
            price_sources: [],
            processed_ms: 1711152860235,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "ca34f9e5-70c2-4a75-b0d3-3b7c8c30a4ff",
            price: 63665.09,
            price_sources: [],
            processed_ms: 1711153035524,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "22e4f28a-1e68-46ae-b966-35478e75917c",
            price: 63701.83,
            price_sources: [],
            processed_ms: 1711153217029,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "e692d459-6c63-4a6a-b4f1-e64de4a6ba45",
            price: 63675.57,
            price_sources: [],
            processed_ms: 1711153391599,
          },
          {
            leverage: 0.01,
            order_type: "FLAT",
            order_uuid: "32f3dd4a-690d-4b26-8bec-e868538b4e39",
            price: 63612.37,
            price_sources: [],
            processed_ms: 1711153573555,
          },
        ],
        position_type: "FLAT",
        position_uuid: "d1807b1c-be72-4699-9524-c09c15a8b5c7",
        return_at_close: 0.9998279672653039,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 63615.07599999999,
        close_ms: 1711154658513,
        current_return: 1.0000939148427022,
        initial_entry_price: 63602.3,
        is_closed_position: true,
        miner_hotkey: "5Dk2u35LRYEi9SC5cWamtzRkdXJJDLES7gABuey6cJ6t1ajK",
        net_leverage: 0.0,
        open_ms: 1711153759223,
        orders: [
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "6fd605ee-5b16-438c-95a4-d32f707f2ce2",
            price: 63602.3,
            price_sources: [],
            processed_ms: 1711153759222,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "4bf0b69e-d495-440b-b145-2b2df65204aa",
            price: 63624.2,
            price_sources: [],
            processed_ms: 1711153941711,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "f0c2809e-fda4-4fd4-822e-fa2743360bfa",
            price: 63632.11,
            price_sources: [],
            processed_ms: 1711154116767,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "891e1941-cf9b-4039-a912-005d0f70363e",
            price: 63605.99,
            price_sources: [],
            processed_ms: 1711154300695,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "0f7a0523-86be-4ac5-a8fe-4f4fb0c77466",
            price: 63610.78,
            price_sources: [],
            processed_ms: 1711154475753,
          },
          {
            leverage: 0.01,
            order_type: "FLAT",
            order_uuid: "35c0d359-d5cd-4526-a257-9c20cace93bc",
            price: 63734.54,
            price_sources: [],
            processed_ms: 1711154658513,
          },
        ],
        position_type: "FLAT",
        position_uuid: "a26817f4-ee49-47c9-a6c0-cef749f637a3",
        return_at_close: 0.9999939054512179,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 63638.072,
        close_ms: 1711155744859,
        current_return: 0.9999638143600186,
        initial_entry_price: 63674.43,
        is_closed_position: true,
        miner_hotkey: "5Dk2u35LRYEi9SC5cWamtzRkdXJJDLES7gABuey6cJ6t1ajK",
        net_leverage: 0.0,
        open_ms: 1711154842898,
        orders: [
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "f7707630-7eff-4b12-8140-59a2354b7505",
            price: 63674.43,
            price_sources: [],
            processed_ms: 1711154842896,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "842ac59f-7877-4094-b84c-2244e36ca9f5",
            price: 63608.41,
            price_sources: [],
            processed_ms: 1711155025622,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "7efc1196-bb89-44cd-b7a6-86c3f5ae3f3f",
            price: 63627.99,
            price_sources: [],
            processed_ms: 1711155200733,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "703a4ff0-eb9f-4330-92a7-25a9e35b9e15",
            price: 63665.99,
            price_sources: [],
            processed_ms: 1711155384863,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "e46d9be9-5273-4ca4-a3a7-df583df15e21",
            price: 63613.54,
            price_sources: [],
            processed_ms: 1711155559815,
          },
          {
            leverage: 0.01,
            order_type: "FLAT",
            order_uuid: "f9bfd7b6-45c6-471f-8f29-bfc9df1f2d2d",
            price: 63591.99,
            price_sources: [],
            processed_ms: 1711155744859,
          },
        ],
        position_type: "FLAT",
        position_uuid: "d0a6fbba-6e8b-42ea-9958-eed4fd642724",
        return_at_close: 0.9998638179785826,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 63783.865999999995,
        close_ms: 1711156818587,
        current_return: 0.9999525241703267,
        initial_entry_price: 63712.42,
        is_closed_position: true,
        miner_hotkey: "5Dk2u35LRYEi9SC5cWamtzRkdXJJDLES7gABuey6cJ6t1ajK",
        net_leverage: 0.0,
        open_ms: 1711155919464,
        orders: [
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "11d58a22-de90-487d-9252-04047b1f2554",
            price: 63712.42,
            price_sources: [],
            processed_ms: 1711155919463,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "e2807c75-4a08-481e-8b70-26d67df57e9c",
            price: 63801.71,
            price_sources: [],
            processed_ms: 1711156100559,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "a307e442-535c-468c-899c-f00620258a76",
            price: 63750.64,
            price_sources: [],
            processed_ms: 1711156275891,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "271777f6-18a7-4873-9bb5-f80ab2ff2623",
            price: 63812.0,
            price_sources: [],
            processed_ms: 1711156461837,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "701600bd-02da-40b1-abf0-5e020334ede3",
            price: 63842.56,
            price_sources: [],
            processed_ms: 1711156637464,
          },
          {
            leverage: 0.01,
            order_type: "FLAT",
            order_uuid: "016ede05-2d29-426a-b2a1-2b61809a3508",
            price: 63723.37,
            price_sources: [],
            processed_ms: 1711156818587,
          },
        ],
        position_type: "FLAT",
        position_uuid: "0d919caf-e34e-400c-ba56-532f980b253a",
        return_at_close: 0.9998525289179097,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 63920.002,
        close_ms: 1711157902689,
        current_return: 1.000058143329016,
        initial_entry_price: 63745.92,
        is_closed_position: true,
        miner_hotkey: "5Dk2u35LRYEi9SC5cWamtzRkdXJJDLES7gABuey6cJ6t1ajK",
        net_leverage: 0.0,
        open_ms: 1711157003990,
        orders: [
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "f740f686-859d-4e84-8245-4c670e4bc1a5",
            price: 63745.92,
            price_sources: [],
            processed_ms: 1711157003988,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "ccf062be-b8ea-4b8c-8287-e8f43e991b35",
            price: 63910.67,
            price_sources: [],
            processed_ms: 1711157185937,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "64ff73eb-2b67-4713-89c4-3fe1e5d359a6",
            price: 63979.35,
            price_sources: [],
            processed_ms: 1711157360838,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "40347a20-cc09-4b58-98ed-267962c7e284",
            price: 63903.1,
            price_sources: [],
            processed_ms: 1711157544083,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "2157c074-4b80-4ba7-aa94-5f0e9b6f7e99",
            price: 64060.97,
            price_sources: [],
            processed_ms: 1711157718949,
          },
          {
            leverage: 0.01,
            order_type: "FLAT",
            order_uuid: "5ac4dcaa-3816-4eb9-bdfc-f3b7909f59ef",
            price: 63994.13,
            price_sources: [],
            processed_ms: 1711157902689,
          },
        ],
        position_type: "FLAT",
        position_uuid: "bb484bf7-d5b9-4785-b9d1-128c5131abe9",
        return_at_close: 0.9999581375146832,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 64146.844,
        close_ms: 1711158987899,
        current_return: 1.0000826114414496,
        initial_entry_price: 64050.45,
        is_closed_position: true,
        miner_hotkey: "5Dk2u35LRYEi9SC5cWamtzRkdXJJDLES7gABuey6cJ6t1ajK",
        net_leverage: 0.0,
        open_ms: 1711158078514,
        orders: [
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "6e9a0635-2700-4d71-a431-ccf9b432bc92",
            price: 64050.45,
            price_sources: [],
            processed_ms: 1711158078512,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "3fd039f4-e5a0-4903-8c23-a31c226b9fa2",
            price: 64067.15,
            price_sources: [],
            processed_ms: 1711158263227,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "e7eb83a1-93a3-49e5-a052-4e24cc27f4c9",
            price: 64063.17,
            price_sources: [],
            processed_ms: 1711158438694,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "8af555c4-5466-48c7-9c4f-0cc042fde230",
            price: 64304.91,
            price_sources: [],
            processed_ms: 1711158620597,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "f2b5da23-2254-4aac-a029-94723b38f2f5",
            price: 64248.54,
            price_sources: [],
            processed_ms: 1711158805067,
          },
          {
            leverage: 0.01,
            order_type: "FLAT",
            order_uuid: "7371fb7a-d93d-46ba-8144-d5b8e6724266",
            price: 64252.67,
            price_sources: [],
            processed_ms: 1711158987899,
          },
        ],
        position_type: "FLAT",
        position_uuid: "fb7a0825-31c1-4eea-b968-593ece9814bf",
        return_at_close: 0.9999826031803055,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 64154.17600000001,
        close_ms: 1711160073568,
        current_return: 0.999893261294816,
        initial_entry_price: 64136.06,
        is_closed_position: true,
        miner_hotkey: "5Dk2u35LRYEi9SC5cWamtzRkdXJJDLES7gABuey6cJ6t1ajK",
        net_leverage: 0.0,
        open_ms: 1711159162654,
        orders: [
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "4340b1a7-840d-469f-9cac-64325e6233da",
            price: 64136.06,
            price_sources: [],
            processed_ms: 1711159162652,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "5dbf07b3-9ba2-4b7e-af7d-747a5bea6ad9",
            price: 64066.93,
            price_sources: [],
            processed_ms: 1711159344592,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "95ac293f-754f-47ab-aa29-cde81700b46f",
            price: 64154.54,
            price_sources: [],
            processed_ms: 1711159521721,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "0392b01a-da12-4afd-9a38-95bbabf64b4a",
            price: 64250.66,
            price_sources: [],
            processed_ms: 1711159704746,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "3164b470-340e-48e4-a446-4e8c1f226c54",
            price: 64162.69,
            price_sources: [],
            processed_ms: 1711159890298,
          },
          {
            leverage: 0.01,
            order_type: "FLAT",
            order_uuid: "4d2197d8-fcb1-4134-8c4a-d0d97da97535",
            price: 64017.26,
            price_sources: [],
            processed_ms: 1711160073568,
          },
        ],
        position_type: "FLAT",
        position_uuid: "c2b06975-04f1-4f5e-b9ff-2a3f94e20fd0",
        return_at_close: 0.9997932719686866,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 63939.682,
        close_ms: 1711161148994,
        current_return: 0.9999244083670229,
        initial_entry_price: 63877.44,
        is_closed_position: true,
        miner_hotkey: "5Dk2u35LRYEi9SC5cWamtzRkdXJJDLES7gABuey6cJ6t1ajK",
        net_leverage: 0.0,
        open_ms: 1711160248829,
        orders: [
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "cbe04fe4-ba9b-4951-8049-bc28137fb7a6",
            price: 63877.44,
            price_sources: [],
            processed_ms: 1711160248828,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "4326328c-5b3a-4225-be7e-a5df40c90464",
            price: 63964.88,
            price_sources: [],
            processed_ms: 1711160430775,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "1ba22440-fb6c-46d7-ae01-c81c16a3c0c7",
            price: 63958.66,
            price_sources: [],
            processed_ms: 1711160605854,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "a83a3d94-6e77-414f-8a27-deecf356acd3",
            price: 63994.24,
            price_sources: [],
            processed_ms: 1711160789711,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "bd7de38c-4253-44af-b96e-b2ce4538149c",
            price: 63903.19,
            price_sources: [],
            processed_ms: 1711160964659,
          },
          {
            leverage: 0.01,
            order_type: "FLAT",
            order_uuid: "8a71b55a-2cf5-49bf-928a-ccab1435e06d",
            price: 63843.11,
            price_sources: [],
            processed_ms: 1711161148994,
          },
        ],
        position_type: "FLAT",
        position_uuid: "345a09f3-463d-468f-8610-1fab4161bcc3",
        return_at_close: 0.9998244159261862,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 63832.536,
        close_ms: 1711162225606,
        current_return: 0.9999814857073359,
        initial_entry_price: 63804.76,
        is_closed_position: true,
        miner_hotkey: "5Dk2u35LRYEi9SC5cWamtzRkdXJJDLES7gABuey6cJ6t1ajK",
        net_leverage: 0.0,
        open_ms: 1711161331675,
        orders: [
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "345c79a4-6e26-4864-88d2-8d6b7d7b27a3",
            price: 63804.76,
            price_sources: [],
            processed_ms: 1711161331673,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "c16140e0-0ffa-45ac-873c-e5a37a198d5c",
            price: 63837.5,
            price_sources: [],
            processed_ms: 1711161512500,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "28fc6936-adc3-4442-a134-846ba5c75b1d",
            price: 63789.16,
            price_sources: [],
            processed_ms: 1711161686875,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "ed7acdaf-b646-41bb-8b66-9cac17e8c700",
            price: 63843.39,
            price_sources: [],
            processed_ms: 1711161870572,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "854fe42b-21e1-4512-aa77-6cf2798b4065",
            price: 63887.87,
            price_sources: [],
            processed_ms: 1711162045608,
          },
          {
            leverage: 0.01,
            order_type: "FLAT",
            order_uuid: "8ba61524-510d-4daf-a64d-2480aab9107e",
            price: 63808.91,
            price_sources: [],
            processed_ms: 1711162225606,
          },
        ],
        position_type: "FLAT",
        position_uuid: "c24a2cf6-c735-4b8c-9027-7deb7a747884",
        return_at_close: 0.9998814875587652,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 63788.195999999996,
        close_ms: 1711163314570,
        current_return: 0.9999021823466047,
        initial_entry_price: 63785.01,
        is_closed_position: true,
        miner_hotkey: "5Dk2u35LRYEi9SC5cWamtzRkdXJJDLES7gABuey6cJ6t1ajK",
        net_leverage: 0.0,
        open_ms: 1711162410666,
        orders: [
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "bb52eb08-343f-4544-9f45-065eaf4ff137",
            price: 63785.01,
            price_sources: [],
            processed_ms: 1711162410664,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "fa9a745f-28ae-4117-a430-3a90d3f43ba4",
            price: 63872.76,
            price_sources: [],
            processed_ms: 1711162594373,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "22bba158-bc4a-477c-9af0-7ead991170d7",
            price: 63853.11,
            price_sources: [],
            processed_ms: 1711162768467,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "3ae3c922-c0a4-4b90-a022-804bf671f477",
            price: 63771.97,
            price_sources: [],
            processed_ms: 1711162950969,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "661700bf-9424-45de-8e31-ce03cc27c209",
            price: 63658.13,
            price_sources: [],
            processed_ms: 1711163133599,
          },
          {
            leverage: 0.01,
            order_type: "FLAT",
            order_uuid: "a1a4ab24-fac0-4e29-9b8b-9501e5ca2de8",
            price: 63663.41,
            price_sources: [],
            processed_ms: 1711163314570,
          },
        ],
        position_type: "FLAT",
        position_uuid: "593fad89-0b2a-422f-b050-ec34b799f871",
        return_at_close: 0.99980219212837,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 63676.476,
        close_ms: 1711164395417,
        current_return: 0.9998127706113521,
        initial_entry_price: 63712.22,
        is_closed_position: true,
        miner_hotkey: "5Dk2u35LRYEi9SC5cWamtzRkdXJJDLES7gABuey6cJ6t1ajK",
        net_leverage: 0.0,
        open_ms: 1711163489280,
        orders: [
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "29595b19-2f61-4104-94eb-99f31fc38d22",
            price: 63712.22,
            price_sources: [],
            processed_ms: 1711163489277,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "1be2c1d1-abbd-4376-a92f-98148ade5874",
            price: 63771.37,
            price_sources: [],
            processed_ms: 1711163672367,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "a51ea4e2-9cb7-4721-8a04-95051da9154d",
            price: 63749.62,
            price_sources: [],
            processed_ms: 1711163846905,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "822a4c1c-963b-4d1f-a8a9-9b10bd352c51",
            price: 63601.42,
            price_sources: [],
            processed_ms: 1711164029370,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "ff215295-4dd1-45cb-b729-ee3663f434e9",
            price: 63547.75,
            price_sources: [],
            processed_ms: 1711164213602,
          },
          {
            leverage: 0.01,
            order_type: "FLAT",
            order_uuid: "297b5325-a5cd-4124-a607-6f797674e889",
            price: 63437.9,
            price_sources: [],
            processed_ms: 1711164395417,
          },
        ],
        position_type: "FLAT",
        position_uuid: "8f252c5b-bece-46df-94de-d0af0f3f8f3c",
        return_at_close: 0.999712789334291,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 63273.95999999999,
        close_ms: 1711165474328,
        current_return: 1.000000425271591,
        initial_entry_price: 63488.84,
        is_closed_position: true,
        miner_hotkey: "5Dk2u35LRYEi9SC5cWamtzRkdXJJDLES7gABuey6cJ6t1ajK",
        net_leverage: 0.0,
        open_ms: 1711164569319,
        orders: [
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "e22d3640-0062-4699-a421-d448f774580d",
            price: 63488.84,
            price_sources: [],
            processed_ms: 1711164569317,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "98e448a2-02b9-415b-ad33-593e63aaeaac",
            price: 63353.95,
            price_sources: [],
            processed_ms: 1711164750393,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "ceed4dd1-4464-46a1-870e-be61396c1366",
            price: 63170.85,
            price_sources: [],
            processed_ms: 1711164934804,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "88867a77-671e-4528-944b-0aaf3c265b1c",
            price: 63147.97,
            price_sources: [],
            processed_ms: 1711165118495,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "49f18001-4a10-4f46-92c4-71f1016c7281",
            price: 63208.19,
            price_sources: [],
            processed_ms: 1711165292352,
          },
          {
            leverage: 0.01,
            order_type: "FLAT",
            order_uuid: "d4274f6f-84b0-4cd9-9195-31f630671be8",
            price: 63274.5,
            price_sources: [],
            processed_ms: 1711165474328,
          },
        ],
        position_type: "FLAT",
        position_uuid: "92584c28-41a3-4e85-b376-1fa8178cc6cf",
        return_at_close: 0.9999004252290639,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 63474.306000000004,
        close_ms: 1711166551449,
        current_return: 1.0000854161487316,
        initial_entry_price: 63432.97,
        is_closed_position: true,
        miner_hotkey: "5Dk2u35LRYEi9SC5cWamtzRkdXJJDLES7gABuey6cJ6t1ajK",
        net_leverage: 0.0,
        open_ms: 1711165648596,
        orders: [
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "b13ff24d-f270-4626-8d70-3c78234b09f2",
            price: 63432.97,
            price_sources: [],
            processed_ms: 1711165648594,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "7c398f32-a6fb-4861-b05e-7a2b30a38d36",
            price: 63432.64,
            price_sources: [],
            processed_ms: 1711165829506,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "284f032d-ce30-47d9-9606-71d01e963a68",
            price: 63449.07,
            price_sources: [],
            processed_ms: 1711166015609,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "3a515780-0f5b-4cf9-ab11-4264fd3222cc",
            price: 63537.98,
            price_sources: [],
            processed_ms: 1711166195438,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "2c355906-21a0-41e3-a2e5-277a78d0cb98",
            price: 63518.87,
            price_sources: [],
            processed_ms: 1711166369571,
          },
          {
            leverage: 0.01,
            order_type: "FLAT",
            order_uuid: "86e74b53-b1c3-43fc-821f-d30f356ceef1",
            price: 63582.67,
            price_sources: [],
            processed_ms: 1711166551449,
          },
        ],
        position_type: "FLAT",
        position_uuid: "ed02b4f3-5e56-4b1f-86d4-8e7dea3c1998",
        return_at_close: 0.9999854076071167,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 63814.09,
        close_ms: 1711167636460,
        current_return: 1.000082208274585,
        initial_entry_price: 63618.9,
        is_closed_position: true,
        miner_hotkey: "5Dk2u35LRYEi9SC5cWamtzRkdXJJDLES7gABuey6cJ6t1ajK",
        net_leverage: 0.0,
        open_ms: 1711166735695,
        orders: [
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "76ca87a3-b169-40d4-9908-2c000edb8112",
            price: 63618.9,
            price_sources: [],
            processed_ms: 1711166735693,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "16868560-01f4-47ad-8b23-273c5d1741c0",
            price: 63761.22,
            price_sources: [],
            processed_ms: 1711166921687,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "a98624d4-204d-464a-a1ee-1a6740c8c0de",
            price: 63935.23,
            price_sources: [],
            processed_ms: 1711167095323,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "37dc66fc-a0d2-447a-95af-f2d84256d632",
            price: 63847.73,
            price_sources: [],
            processed_ms: 1711167278383,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "b7e79edc-bd54-41cc-b484-6ed543dac8e2",
            price: 63907.37,
            price_sources: [],
            processed_ms: 1711167452471,
          },
          {
            leverage: 0.01,
            order_type: "FLAT",
            order_uuid: "6902de15-0991-4863-a3e7-9da088941ceb",
            price: 63918.69,
            price_sources: [],
            processed_ms: 1711167636460,
          },
        ],
        position_type: "FLAT",
        position_uuid: "c229dd46-4e03-4461-9ee7-2e8d0492e610",
        return_at_close: 0.9999822000537576,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 63902.13399999999,
        close_ms: 1711168721438,
        current_return: 1.0000825142530383,
        initial_entry_price: 63865.33,
        is_closed_position: true,
        miner_hotkey: "5Dk2u35LRYEi9SC5cWamtzRkdXJJDLES7gABuey6cJ6t1ajK",
        net_leverage: 0.0,
        open_ms: 1711167820663,
        orders: [
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "08136227-f4f7-4b29-8764-4551781a7c66",
            price: 63865.33,
            price_sources: [],
            processed_ms: 1711167820660,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "55e0d761-bf38-4565-b149-b8699dfded1f",
            price: 63921.85,
            price_sources: [],
            processed_ms: 1711168007572,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "f9e37ee4-0f42-4e56-a658-556bb06c9106",
            price: 63842.33,
            price_sources: [],
            processed_ms: 1711168181449,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "5d7da1ff-c2c8-4ee4-8683-aed0c4d6d805",
            price: 63931.81,
            price_sources: [],
            processed_ms: 1711168361561,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "1b5195da-570a-49d9-b0ca-a848afae37b9",
            price: 63949.35,
            price_sources: [],
            processed_ms: 1711168536493,
          },
          {
            leverage: 0.01,
            order_type: "FLAT",
            order_uuid: "3a109415-b6a3-47ce-8ab3-36c4c540da7e",
            price: 64007.53,
            price_sources: [],
            processed_ms: 1711168721438,
          },
        ],
        position_type: "FLAT",
        position_uuid: "bfe5d897-5cdc-4039-8f13-9250b3c16fa8",
        return_at_close: 0.999982506001613,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 63985.972,
        close_ms: 1711169803231,
        current_return: 0.9999463801187871,
        initial_entry_price: 63980.0,
        is_closed_position: true,
        miner_hotkey: "5Dk2u35LRYEi9SC5cWamtzRkdXJJDLES7gABuey6cJ6t1ajK",
        net_leverage: 0.0,
        open_ms: 1711168897412,
        orders: [
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "c771884b-e3f0-4ea3-84e6-7bf92fb95f7a",
            price: 63980.0,
            price_sources: [],
            processed_ms: 1711168897410,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "b7c9ca4f-e405-4d9e-8ba4-91e0eaa89bbb",
            price: 64003.22,
            price_sources: [],
            processed_ms: 1711169080515,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "51b30434-c4cb-4ce6-b90f-1bc23f78f028",
            price: 63984.31,
            price_sources: [],
            processed_ms: 1711169254341,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "2bd8b95a-41b0-4f89-ac5a-ba51375bd39a",
            price: 63973.18,
            price_sources: [],
            processed_ms: 1711169436306,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "4dac46aa-d785-44e0-8878-5e351fa91dcb",
            price: 63989.15,
            price_sources: [],
            processed_ms: 1711169620893,
          },
          {
            leverage: 0.01,
            order_type: "FLAT",
            order_uuid: "bf61b267-db6b-4608-83a2-794424feea8d",
            price: 63917.36,
            price_sources: [],
            processed_ms: 1711169803231,
          },
        ],
        position_type: "FLAT",
        position_uuid: "29c10abe-6685-498e-b33b-93e5025d7bac",
        return_at_close: 0.9998463854807752,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 63905.439999999995,
        close_ms: 1711170887390,
        current_return: 0.9999699550225357,
        initial_entry_price: 63887.55,
        is_closed_position: true,
        miner_hotkey: "5Dk2u35LRYEi9SC5cWamtzRkdXJJDLES7gABuey6cJ6t1ajK",
        net_leverage: 0.0,
        open_ms: 1711169977445,
        orders: [
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "12616eec-d345-4cb4-a390-396a58a06b0b",
            price: 63887.55,
            price_sources: [],
            processed_ms: 1711169977441,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "eb82426b-e3d0-4e02-9c76-8c85d6249ee3",
            price: 63876.4,
            price_sources: [],
            processed_ms: 1711170162511,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "2c0d5005-a2e3-41c2-942d-73f5b6cb744a",
            price: 64018.19,
            price_sources: [],
            processed_ms: 1711170337531,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "02f1421f-c120-43ca-a9c3-b793bdaed9ad",
            price: 63914.46,
            price_sources: [],
            processed_ms: 1711170519700,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "0bdbb9dd-b397-4cc5-a2fb-543a0f8ee5bc",
            price: 63830.6,
            price_sources: [],
            processed_ms: 1711170704690,
          },
          {
            leverage: 0.01,
            order_type: "FLAT",
            order_uuid: "6efec973-88ef-4044-8eca-30d4dba608ba",
            price: 63867.05,
            price_sources: [],
            processed_ms: 1711170887390,
          },
        ],
        position_type: "FLAT",
        position_uuid: "abfffbb6-b569-4b1c-ab7f-d682e7d167c6",
        return_at_close: 0.9998699580270335,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 63962.636,
        close_ms: 1711171964535,
        current_return: 1.0000152103295066,
        initial_entry_price: 63917.09,
        is_closed_position: true,
        miner_hotkey: "5Dk2u35LRYEi9SC5cWamtzRkdXJJDLES7gABuey6cJ6t1ajK",
        net_leverage: 0.0,
        open_ms: 1711171062004,
        orders: [
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "e6fa4e0f-73e6-46ac-a4d8-a6e15a657b14",
            price: 63917.09,
            price_sources: [],
            processed_ms: 1711171062002,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "8d3eb8a7-b17d-4631-a223-d539ef1030f6",
            price: 63912.12,
            price_sources: [],
            processed_ms: 1711171245330,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "802d597e-4a66-4700-87e2-7cdbb46989af",
            price: 63964.5,
            price_sources: [],
            processed_ms: 1711171419680,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "92a23c67-87f2-4d67-9c0e-b0718a1a1975",
            price: 64004.81,
            price_sources: [],
            processed_ms: 1711171607492,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "b7627807-5bc3-423d-bbba-91788ae49797",
            price: 64014.66,
            price_sources: [],
            processed_ms: 1711171783269,
          },
          {
            leverage: 0.01,
            order_type: "FLAT",
            order_uuid: "9ad94d12-4392-4c1c-9456-2310565cab22",
            price: 63982.08,
            price_sources: [],
            processed_ms: 1711171964535,
          },
        ],
        position_type: "FLAT",
        position_uuid: "b73b364d-26be-4021-b807-38455f5f9179",
        return_at_close: 0.9999152088084736,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 64022.096,
        close_ms: 1711173048309,
        current_return: 1.0000024654512425,
        initial_entry_price: 63963.95,
        is_closed_position: true,
        miner_hotkey: "5Dk2u35LRYEi9SC5cWamtzRkdXJJDLES7gABuey6cJ6t1ajK",
        net_leverage: 0.0,
        open_ms: 1711172138621,
        orders: [
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "966f8ccd-4992-4eec-b36c-1d3ef9fc7e72",
            price: 63963.95,
            price_sources: [],
            processed_ms: 1711172138619,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "5a3792b8-e1f9-4b50-9e4a-1ee16d514aeb",
            price: 63923.26,
            price_sources: [],
            processed_ms: 1711172319390,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "6f6acebc-c1a8-411e-92b5-74a4f003d7cc",
            price: 64012.21,
            price_sources: [],
            processed_ms: 1711172503574,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "fa71dd30-45ce-4404-91d2-a3f1258148fa",
            price: 64082.64,
            price_sources: [],
            processed_ms: 1711172688348,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "8649d474-fe39-495b-88d7-211e2426aa29",
            price: 64128.42,
            price_sources: [],
            processed_ms: 1711172862387,
          },
          {
            leverage: 0.01,
            order_type: "FLAT",
            order_uuid: "af305f5f-df48-49d5-aa8c-9ec70ed661dc",
            price: 64025.25,
            price_sources: [],
            processed_ms: 1711173048309,
          },
        ],
        position_type: "FLAT",
        position_uuid: "fb3d996f-af3c-412f-b767-eb2f81cc7426",
        return_at_close: 0.9999024652046974,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 64086.954000000005,
        close_ms: 1711174128615,
        current_return: 0.9999323155952061,
        initial_entry_price: 64116.69,
        is_closed_position: true,
        miner_hotkey: "5Dk2u35LRYEi9SC5cWamtzRkdXJJDLES7gABuey6cJ6t1ajK",
        net_leverage: 0.0,
        open_ms: 1711173222585,
        orders: [
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "35996728-c293-459d-8f79-574df8119fa5",
            price: 64116.69,
            price_sources: [],
            processed_ms: 1711173222582,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "41d05e72-0563-46aa-b40c-487aa2055b8d",
            price: 64060.84,
            price_sources: [],
            processed_ms: 1711173404758,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "6b666c04-27aa-461a-ad6a-ddca87702933",
            price: 64068.16,
            price_sources: [],
            processed_ms: 1711173580646,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "f0745c2a-a724-41c3-9c21-13756eb9b256",
            price: 64131.36,
            price_sources: [],
            processed_ms: 1711173761548,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "51c8b8d4-035f-4c95-88b5-84c711874297",
            price: 64057.72,
            price_sources: [],
            processed_ms: 1711173946720,
          },
          {
            leverage: 0.01,
            order_type: "FLAT",
            order_uuid: "66ea32c1-4e4f-4f11-a698-fadf65d9ea9e",
            price: 64000.16,
            price_sources: [],
            processed_ms: 1711174128615,
          },
        ],
        position_type: "FLAT",
        position_uuid: "e49653d5-6a88-4234-8146-eb112648c6d2",
        return_at_close: 0.9998323223636466,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 64086.03599999999,
        close_ms: 1711175203358,
        current_return: 1.0000440957338799,
        initial_entry_price: 64069.69,
        is_closed_position: true,
        miner_hotkey: "5Dk2u35LRYEi9SC5cWamtzRkdXJJDLES7gABuey6cJ6t1ajK",
        net_leverage: 0.0,
        open_ms: 1711174306252,
        orders: [
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "c108838d-3189-444d-8cf5-101aaa1ebf56",
            price: 64069.69,
            price_sources: [],
            processed_ms: 1711174304455,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "a64ec478-8056-4a20-982e-dea57d47aadd",
            price: 64048.56,
            price_sources: [],
            processed_ms: 1711174487446,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "dd74fc84-44ed-4b87-8bca-07248c9c89e2",
            price: 64071.83,
            price_sources: [],
            processed_ms: 1711174661431,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "93ef7d59-f2be-4b98-bbb2-31d433dc6be8",
            price: 64081.0,
            price_sources: [],
            processed_ms: 1711174848276,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "049cb9da-aaf2-4655-9b05-e01b562bd466",
            price: 64159.1,
            price_sources: [],
            processed_ms: 1711175022378,
          },
          {
            leverage: 0.01,
            order_type: "FLAT",
            order_uuid: "4eebceee-f672-4704-b45a-bbac55d0e60e",
            price: 64142.54,
            price_sources: [],
            processed_ms: 1711175203358,
          },
        ],
        position_type: "FLAT",
        position_uuid: "8bceab5c-0948-4f8e-b168-57251fa2af80",
        return_at_close: 0.9999440913243065,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 64163.35999999999,
        close_ms: 1711176290703,
        current_return: 1.0000638489064406,
        initial_entry_price: 64221.93,
        is_closed_position: true,
        miner_hotkey: "5Dk2u35LRYEi9SC5cWamtzRkdXJJDLES7gABuey6cJ6t1ajK",
        net_leverage: 0.0,
        open_ms: 1711175389759,
        orders: [
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "1a078bff-598d-4787-9bcc-10bb212c0ffc",
            price: 64221.93,
            price_sources: [],
            processed_ms: 1711175389755,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "35f41e49-80e1-4f58-8f2e-0dbdad6d006e",
            price: 64122.37,
            price_sources: [],
            processed_ms: 1711175571514,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "5bd3055f-2f0b-4e56-acda-04eba4fe85fe",
            price: 64152.64,
            price_sources: [],
            processed_ms: 1711175745480,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "161bb72e-49d2-45a8-9bff-fefee46a60ea",
            price: 64150.22,
            price_sources: [],
            processed_ms: 1711175928291,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "384aa460-f7c1-4fde-879a-319679b51633",
            price: 64169.64,
            price_sources: [],
            processed_ms: 1711176102499,
          },
          {
            leverage: 0.01,
            order_type: "FLAT",
            order_uuid: "54bac9a8-276e-4442-9948-7cc68ed2e686",
            price: 64245.37,
            price_sources: [],
            processed_ms: 1711176290703,
          },
        ],
        position_type: "FLAT",
        position_uuid: "8d0f0a6d-dc4f-4096-bd33-80f0176b996b",
        return_at_close: 0.99996384252155,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 64148.302,
        close_ms: 1711177375413,
        current_return: 1.0000485362649878,
        initial_entry_price: 64207.66,
        is_closed_position: true,
        miner_hotkey: "5Dk2u35LRYEi9SC5cWamtzRkdXJJDLES7gABuey6cJ6t1ajK",
        net_leverage: 0.0,
        open_ms: 1711176465606,
        orders: [
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "9d2c1a3d-af98-4761-a0dd-6942b99afed3",
            price: 64207.66,
            price_sources: [],
            processed_ms: 1711176465603,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "9b40c7f7-9e1c-4a62-99ed-8c48ad3efe9e",
            price: 64086.52,
            price_sources: [],
            processed_ms: 1711176647545,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "8003ae11-47d3-4cd4-8c15-4ac49773444a",
            price: 64113.99,
            price_sources: [],
            processed_ms: 1711176824337,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "471841ac-f32d-488e-82b2-02c3cbfebc87",
            price: 64162.35,
            price_sources: [],
            processed_ms: 1711177005431,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "e6ddabbb-0fd1-4c5a-a065-18b0295425ba",
            price: 64170.99,
            price_sources: [],
            processed_ms: 1711177189528,
          },
          {
            leverage: 0.01,
            order_type: "FLAT",
            order_uuid: "a5a4220c-785b-430f-9b7d-35ad8ab95bb9",
            price: 64210.63,
            price_sources: [],
            processed_ms: 1711177375413,
          },
        ],
        position_type: "FLAT",
        position_uuid: "7f7a220d-f83f-4944-9840-83c3c78246ce",
        return_at_close: 0.9999485314113613,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 64425.324,
        close_ms: 1711178457482,
        current_return: 1.0003109782648645,
        initial_entry_price: 64211.24,
        is_closed_position: true,
        miner_hotkey: "5Dk2u35LRYEi9SC5cWamtzRkdXJJDLES7gABuey6cJ6t1ajK",
        net_leverage: 0.0,
        open_ms: 1711177549458,
        orders: [
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "b5e6a357-90f6-488c-8e63-a63b71792fec",
            price: 64211.24,
            price_sources: [],
            processed_ms: 1711177549455,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "f913b2db-415d-437f-9351-5a0b3afb1228",
            price: 64385.37,
            price_sources: [],
            processed_ms: 1711177736642,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "c688d67d-a81c-46f2-8294-09aa03aefa17",
            price: 64512.32,
            price_sources: [],
            processed_ms: 1711177911606,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "4db88f9d-37f5-47c7-8fe5-6c94e93c0cc1",
            price: 64565.59,
            price_sources: [],
            processed_ms: 1711178095608,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "a04e9bcf-136a-4464-842e-933d66af7af9",
            price: 64452.1,
            price_sources: [],
            processed_ms: 1711178270445,
          },
          {
            leverage: 0.01,
            order_type: "FLAT",
            order_uuid: "5e4772ed-48c2-41c3-b93d-942682e19856",
            price: 64824.69,
            price_sources: [],
            processed_ms: 1711178457482,
          },
        ],
        position_type: "FLAT",
        position_uuid: "071cc017-b4fc-4f0a-ab48-c8df0aa77837",
        return_at_close: 1.000210947167038,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 64582.716,
        close_ms: 1711179541648,
        current_return: 0.9999895180286662,
        initial_entry_price: 64711.11,
        is_closed_position: true,
        miner_hotkey: "5Dk2u35LRYEi9SC5cWamtzRkdXJJDLES7gABuey6cJ6t1ajK",
        net_leverage: 0.0,
        open_ms: 1711178631692,
        orders: [
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "7aea2f04-59a6-4686-8aef-60bac46402fd",
            price: 64711.11,
            price_sources: [],
            processed_ms: 1711178631689,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "605f05e9-da2a-489e-8c92-eeab1064db9d",
            price: 64650.0,
            price_sources: [],
            processed_ms: 1711178815873,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "fc2f7151-7973-4813-b52a-d2e78958626c",
            price: 64580.51,
            price_sources: [],
            processed_ms: 1711178991035,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "2208ab4f-7fbb-4e59-a434-7f118bed4962",
            price: 64497.24,
            price_sources: [],
            processed_ms: 1711179177562,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "f0532506-2fe5-438c-833d-a0249f2342ef",
            price: 64474.72,
            price_sources: [],
            processed_ms: 1711179352943,
          },
          {
            leverage: 0.01,
            order_type: "FLAT",
            order_uuid: "964633bb-bf9a-43c4-b0c6-0c8f50f539ec",
            price: 64569.15,
            price_sources: [],
            processed_ms: 1711179541648,
          },
        ],
        position_type: "FLAT",
        position_uuid: "b4474bc8-7cb6-4928-b2ae-30073a055f7b",
        return_at_close: 0.9998895190768633,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 64606.276,
        close_ms: 1711180629650,
        current_return: 0.9999248818086638,
        initial_entry_price: 64662.1,
        is_closed_position: true,
        miner_hotkey: "5Dk2u35LRYEi9SC5cWamtzRkdXJJDLES7gABuey6cJ6t1ajK",
        net_leverage: 0.0,
        open_ms: 1711179717572,
        orders: [
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "81d98a2f-6145-4398-ba44-e9171ea2aa7e",
            price: 64662.1,
            price_sources: [],
            processed_ms: 1711179717569,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "a0181298-796d-412c-a7b4-47c09119bf4c",
            price: 64652.72,
            price_sources: [],
            processed_ms: 1711179903563,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "8bd901eb-c8c3-4276-a605-7f92b40b1627",
            price: 64588.26,
            price_sources: [],
            processed_ms: 1711180079444,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "fa2f8eb7-5ed0-465b-ab6e-0104aeae90c4",
            price: 64580.58,
            price_sources: [],
            processed_ms: 1711180267769,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "89513b99-368f-419f-aca2-e2da6b32c9e4",
            price: 64547.72,
            price_sources: [],
            processed_ms: 1711180445789,
          },
          {
            leverage: 0.01,
            order_type: "FLAT",
            order_uuid: "cbd0ffe4-7204-4ff6-a177-01ec0d25fb34",
            price: 64509.13,
            price_sources: [],
            processed_ms: 1711180629650,
          },
        ],
        position_type: "FLAT",
        position_uuid: "ccb929d1-c042-4177-83a6-ddc3b60b4167",
        return_at_close: 0.999824889320483,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 64685.972,
        close_ms: 1711181716638,
        current_return: 0.9999305850853788,
        initial_entry_price: 64576.9,
        is_closed_position: true,
        miner_hotkey: "5Dk2u35LRYEi9SC5cWamtzRkdXJJDLES7gABuey6cJ6t1ajK",
        net_leverage: 0.0,
        open_ms: 1711180805990,
        orders: [
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "2d889e3b-d270-4dd9-9a5e-696fe32ddeea",
            price: 64576.9,
            price_sources: [],
            processed_ms: 1711180805987,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "9e20a31c-f567-4fcf-94d4-3cb8e4946472",
            price: 64667.14,
            price_sources: [],
            processed_ms: 1711180993675,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "91bc4397-130e-4cb0-8132-791c993c530f",
            price: 64760.89,
            price_sources: [],
            processed_ms: 1711181168677,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "335876cb-022e-4d51-84ad-130ed823f6a4",
            price: 64771.48,
            price_sources: [],
            processed_ms: 1711181352693,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "3c6dc804-4967-4514-85fc-87c295ff8e3d",
            price: 64653.45,
            price_sources: [],
            processed_ms: 1711181527642,
          },
          {
            leverage: 0.01,
            order_type: "FLAT",
            order_uuid: "75c8240e-7ae4-4981-8e28-05c68f38f2c9",
            price: 64596.32,
            price_sources: [],
            processed_ms: 1711181716638,
          },
        ],
        position_type: "FLAT",
        position_uuid: "a0a687e7-b1b3-401f-b9da-485a94ec5e9d",
        return_at_close: 0.9998305920268703,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 64530.907999999996,
        close_ms: 1711182790702,
        current_return: 0.9998661566929786,
        initial_entry_price: 64552.35,
        is_closed_position: true,
        miner_hotkey: "5Dk2u35LRYEi9SC5cWamtzRkdXJJDLES7gABuey6cJ6t1ajK",
        net_leverage: 0.0,
        open_ms: 1711181892149,
        orders: [
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "3331868a-4668-4fd6-a2d6-0700384cd184",
            price: 64552.35,
            price_sources: [],
            processed_ms: 1711181892145,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "c4e2d13a-a23f-4e38-af5a-7defeea23d2c",
            price: 64588.64,
            price_sources: [],
            processed_ms: 1711182075559,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "1c0341f6-56ba-4df7-952d-fe06e1822ffb",
            price: 64544.29,
            price_sources: [],
            processed_ms: 1711182251039,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "4ac73977-ed4d-496e-9a25-34cb8aae746f",
            price: 64501.68,
            price_sources: [],
            processed_ms: 1711182413164,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "3f778b19-7c94-4a8d-9454-ab7e838acf1f",
            price: 64467.58,
            price_sources: [],
            processed_ms: 1711182598039,
          },
          {
            leverage: 0.01,
            order_type: "FLAT",
            order_uuid: "dbad5cb9-f8a5-4e71-bb5d-b42185740d36",
            price: 64358.11,
            price_sources: [],
            processed_ms: 1711182790702,
          },
        ],
        position_type: "FLAT",
        position_uuid: "32d1e397-fdb8-4977-946e-b0b9d3cee8c2",
        return_at_close: 0.9997661700773094,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 64404.876000000004,
        close_ms: 1711183880958,
        current_return: 1.000075547035897,
        initial_entry_price: 64254.01,
        is_closed_position: true,
        miner_hotkey: "5Dk2u35LRYEi9SC5cWamtzRkdXJJDLES7gABuey6cJ6t1ajK",
        net_leverage: 0.0,
        open_ms: 1711182965938,
        orders: [
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "cc4e2d21-2ee5-45bb-b76a-0ed2032f77cb",
            price: 64254.01,
            price_sources: [],
            processed_ms: 1711182965935,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "341061f9-1366-4f03-a932-88b8f3359143",
            price: 64402.14,
            price_sources: [],
            processed_ms: 1711183155110,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "1b63228d-4ca3-490a-a64d-dbf9fba2ffcc",
            price: 64439.43,
            price_sources: [],
            processed_ms: 1711183332786,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "20b75ca8-4800-4031-a88c-eca0aaddac13",
            price: 64459.14,
            price_sources: [],
            processed_ms: 1711183517457,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "3d8a3a54-f74d-473f-8ced-12ed2f303a32",
            price: 64469.66,
            price_sources: [],
            processed_ms: 1711183691783,
          },
          {
            leverage: 0.01,
            order_type: "FLAT",
            order_uuid: "8f436dbb-6805-4b13-923e-ca08f3538ae3",
            price: 64501.96,
            price_sources: [],
            processed_ms: 1711183880958,
          },
        ],
        position_type: "FLAT",
        position_uuid: "75d9e740-e54a-4f1f-b2e0-66e2875b7343",
        return_at_close: 0.9999755394811934,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 64435.337999999996,
        close_ms: 1711184948929,
        current_return: 0.9999860516704907,
        initial_entry_price: 64373.3,
        is_closed_position: true,
        miner_hotkey: "5Dk2u35LRYEi9SC5cWamtzRkdXJJDLES7gABuey6cJ6t1ajK",
        net_leverage: 0.0,
        open_ms: 1711184056973,
        orders: [
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "28feaf14-cae1-46fe-98b8-d7a9f3d0b4e3",
            price: 64373.3,
            price_sources: [],
            processed_ms: 1711184056969,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "058a604d-da2f-4c7f-838c-8bd69a8372ef",
            price: 64430.79,
            price_sources: [],
            processed_ms: 1711184239749,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "fca42e99-0e14-4354-92fc-d2207afa31f9",
            price: 64479.43,
            price_sources: [],
            processed_ms: 1711184415021,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "228807d2-f126-4675-82dc-7c5a95420eba",
            price: 64496.29,
            price_sources: [],
            processed_ms: 1711184578003,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "7068cff4-c4a6-4888-968b-7eede1a70d62",
            price: 64396.88,
            price_sources: [],
            processed_ms: 1711184764344,
          },
          {
            leverage: 0.01,
            order_type: "FLAT",
            order_uuid: "3f725e5e-718c-426c-95b9-ecb2b130d004",
            price: 64417.38,
            price_sources: [],
            processed_ms: 1711184948929,
          },
        ],
        position_type: "FLAT",
        position_uuid: "8e9db2d8-7600-4f18-94e4-3c678e3cb347",
        return_at_close: 0.9998860530653236,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
    ],
    thirty_day_returns: 1.0,
  },
  "5DoCFr2EoW1CGuYCEXhsuQdWRsgiUMuxGwNt4Xqb5TCptcBW": {
    all_time_returns: 1.0290094393674574,
    n_positions: 152,
    percentage_profitable: 0.7039473684210527,
    positions: [
      {
        average_entry_price: 57272.4,
        close_ms: 1714645848361,
        current_return: 1.0006190765534533,
        initial_entry_price: 57272.4,
        is_closed_position: true,
        miner_hotkey: "5DoCFr2EoW1CGuYCEXhsuQdWRsgiUMuxGwNt4Xqb5TCptcBW",
        net_leverage: 0.0,
        open_ms: 1714625928059,
        orders: [
          {
            leverage: 0.1,
            order_type: "LONG",
            order_uuid: "bfdc18ad-f042-4d42-ae41-b112e6fbf405",
            price: 57272.4,
            price_sources: [],
            processed_ms: 1714625927842,
          },
          {
            leverage: 0.5,
            order_type: "FLAT",
            order_uuid: "7b648c4c-b756-4157-adc2-42190f98da3f",
            price: 57626.96,
            price_sources: [],
            processed_ms: 1714645848361,
          },
        ],
        position_type: "FLAT",
        position_uuid: "7a956adb-a320-445d-82ec-f493647d939b",
        return_at_close: 1.0005190146457978,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 2917.17,
        close_ms: 1714645855375,
        current_return: 1.000851510196526,
        initial_entry_price: 2917.17,
        is_closed_position: true,
        miner_hotkey: "5DoCFr2EoW1CGuYCEXhsuQdWRsgiUMuxGwNt4Xqb5TCptcBW",
        net_leverage: 0.0,
        open_ms: 1714625956776,
        orders: [
          {
            leverage: 0.1,
            order_type: "LONG",
            order_uuid: "e41150a4-7400-47e0-87a4-31470f4060e6",
            price: 2917.17,
            price_sources: [],
            processed_ms: 1714625956604,
          },
          {
            leverage: 0.5,
            order_type: "FLAT",
            order_uuid: "a508cfc3-d9ff-41b3-816d-c8df877db84f",
            price: 2942.01,
            price_sources: [],
            processed_ms: 1714645855375,
          },
        ],
        position_type: "FLAT",
        position_uuid: "ec0290ee-8436-4783-b784-9622203ed014",
        return_at_close: 1.0007514250455065,
        trade_pair: ["ETHUSD", "ETH/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 2985.2150000000006,
        close_ms: 1714654520590,
        current_return: 0.9999097373540531,
        initial_entry_price: 2996.81,
        is_closed_position: true,
        miner_hotkey: "5DoCFr2EoW1CGuYCEXhsuQdWRsgiUMuxGwNt4Xqb5TCptcBW",
        net_leverage: 0.0,
        open_ms: 1714651833746,
        orders: [
          {
            leverage: 0.5,
            order_type: "LONG",
            order_uuid: "aef0d170-85d2-4ae2-b5d4-a3561b40ece6",
            price: 2996.81,
            price_sources: [],
            processed_ms: 1714651833565,
          },
          {
            leverage: -0.25,
            order_type: "SHORT",
            order_uuid: "97b7cbd9-e8d9-43d1-98ac-61c3b7af58e0",
            price: 3007.7,
            price_sources: [],
            processed_ms: 1714652343458,
          },
          {
            leverage: -0.15,
            order_type: "SHORT",
            order_uuid: "ce5fa35e-8073-49e9-9fbd-d217e670db21",
            price: 2986.39,
            price_sources: [],
            processed_ms: 1714654491112,
          },
          {
            leverage: 0.15,
            order_type: "FLAT",
            order_uuid: "be773f64-3001-48e9-b272-79f6f809d388",
            price: 2982.51,
            price_sources: [],
            processed_ms: 1714654520590,
          },
        ],
        position_type: "FLAT",
        position_uuid: "d541ad0d-6675-480e-915b-70bc2bc3e3b1",
        return_at_close: 0.9994097824853762,
        trade_pair: ["ETHUSD", "ETH/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 59048.92,
        close_ms: 1714654525949,
        current_return: 0.9982824323865758,
        initial_entry_price: 58938.0,
        is_closed_position: true,
        miner_hotkey: "5DoCFr2EoW1CGuYCEXhsuQdWRsgiUMuxGwNt4Xqb5TCptcBW",
        net_leverage: 0.0,
        open_ms: 1714652495091,
        orders: [
          {
            leverage: 0.5,
            order_type: "LONG",
            order_uuid: "4f93338e-28ce-436c-b701-c5e2768cf919",
            price: 58938.0,
            price_sources: [],
            processed_ms: 1714652494900,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "57d4bc91-d9b6-446b-8d9d-c41ff8d7f026",
            price: 58824.5,
            price_sources: [],
            processed_ms: 1714652721341,
          },
          {
            leverage: -0.25,
            order_type: "SHORT",
            order_uuid: "ae5f7926-204a-4fa7-b653-cf37b49a6b49",
            price: 58946.19,
            price_sources: [],
            processed_ms: 1714653745047,
          },
          {
            leverage: -0.25,
            order_type: "SHORT",
            order_uuid: "157b9759-6a1c-44d1-a1d6-ab1a235ae219",
            price: 58705.39,
            price_sources: [],
            processed_ms: 1714654466819,
          },
          {
            leverage: 0.15,
            order_type: "FLAT",
            order_uuid: "f9c90649-9679-403f-91ff-9e7b937bd893",
            price: 58644.0,
            price_sources: [],
            processed_ms: 1714654525949,
          },
        ],
        position_type: "FLAT",
        position_uuid: "d2eb471d-2d1f-4a02-bff5-cfae76f8d3e8",
        return_at_close: 0.9975337205622858,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 1.46584,
        close_ms: 1714659878610,
        current_return: 1.0004570758063636,
        initial_entry_price: 1.46584,
        is_closed_position: true,
        miner_hotkey: "5DoCFr2EoW1CGuYCEXhsuQdWRsgiUMuxGwNt4Xqb5TCptcBW",
        net_leverage: 0.0,
        open_ms: 1714657422404,
        orders: [
          {
            leverage: -1.0,
            order_type: "SHORT",
            order_uuid: "74fd7355-f3ab-4610-bd12-950529784615",
            price: 1.46584,
            price_sources: [],
            processed_ms: 1714657421818,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "6ff3fe39-b009-4fe3-9eae-442a54f644b2",
            price: 1.46517,
            price_sources: [],
            processed_ms: 1714659878610,
          },
        ],
        position_type: "FLAT",
        position_uuid: "5d0245ad-b147-4dae-8807-a3d6ad7c6fdc",
        return_at_close: 1.0003870438110571,
        trade_pair: ["EURCAD", "EUR/CAD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 191.18899999999996,
        close_ms: 1714689114119,
        current_return: 1.0032909479389234,
        initial_entry_price: 191.89,
        is_closed_position: true,
        miner_hotkey: "5DoCFr2EoW1CGuYCEXhsuQdWRsgiUMuxGwNt4Xqb5TCptcBW",
        net_leverage: 0.0,
        open_ms: 1714675498494,
        orders: [
          {
            leverage: 1.0,
            order_type: "LONG",
            order_uuid: "d73be699-dd0e-461f-aecb-71f27013ab4c",
            price: 191.89,
            price_sources: [],
            processed_ms: 1714675496634,
          },
          {
            leverage: -0.5,
            order_type: "SHORT",
            order_uuid: "d0cca710-3298-4b9e-8fdb-6c0bd0aa5c01",
            price: 192.591,
            price_sources: [],
            processed_ms: 1714682086683,
          },
          {
            leverage: 0.5,
            order_type: "FLAT",
            order_uuid: "cd03d732-0b82-4707-8c4b-bab7342d0951",
            price: 192.452,
            price_sources: [],
            processed_ms: 1714689114119,
          },
        ],
        position_type: "FLAT",
        position_uuid: "ec4edd7d-f3a6-43e7-b6fb-eb50acd34eec",
        return_at_close: 1.0032207175725676,
        trade_pair: ["GBPJPY", "GBP/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.9641100000000016,
        close_ms: 1714693470846,
        current_return: 1.0012429297483398,
        initial_entry_price: 0.97592,
        is_closed_position: true,
        miner_hotkey: "5DoCFr2EoW1CGuYCEXhsuQdWRsgiUMuxGwNt4Xqb5TCptcBW",
        net_leverage: 0.0,
        open_ms: 1714654591074,
        orders: [
          {
            leverage: 1.0,
            order_type: "LONG",
            order_uuid: "cc719ade-9d34-4bd6-bae2-f3083371125b",
            price: 0.97592,
            price_sources: [],
            processed_ms: 1714654589822,
          },
          {
            leverage: 1.0,
            order_type: "LONG",
            order_uuid: "58ef3bcc-9b55-4ef5-ba7b-502df4ae6c69",
            price: 0.9748,
            price_sources: [],
            processed_ms: 1714659618677,
          },
          {
            leverage: -1.0,
            order_type: "SHORT",
            order_uuid: "370e9edb-9f49-42d4-b967-a0b3792effed",
            price: 0.9752,
            price_sources: [],
            processed_ms: 1714660835090,
          },
          {
            leverage: 1.0,
            order_type: "LONG",
            order_uuid: "488304d6-4441-4e6c-a715-57886c205afc",
            price: 0.97474,
            price_sources: [],
            processed_ms: 1714661043196,
          },
          {
            leverage: -1.9,
            order_type: "SHORT",
            order_uuid: "cd12c8fe-ac28-43b2-bab9-a5fd95599ed9",
            price: 0.97571,
            price_sources: [],
            processed_ms: 1714661552094,
          },
          {
            leverage: 0.5,
            order_type: "FLAT",
            order_uuid: "c084fea5-7a7e-41fe-98d2-9d3f6ce78a35",
            price: 0.97624,
            price_sources: [],
            processed_ms: 1714693470846,
          },
        ],
        position_type: "FLAT",
        position_uuid: "7aef9fe6-34e4-4bfa-8d03-da3a0e134245",
        return_at_close: 1.0010326687330926,
        trade_pair: ["EURCHF", "EUR/CHF", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 3006.5099999999998,
        close_ms: 1714700122317,
        current_return: 1.0010962103393095,
        initial_entry_price: 3005.81,
        is_closed_position: true,
        miner_hotkey: "5DoCFr2EoW1CGuYCEXhsuQdWRsgiUMuxGwNt4Xqb5TCptcBW",
        net_leverage: 0.0,
        open_ms: 1714698796472,
        orders: [
          {
            leverage: 1.0,
            order_type: "LONG",
            order_uuid: "93c6b520-e7b6-4dfb-b4b4-c900a59a9feb",
            price: 3005.81,
            price_sources: [],
            processed_ms: 1714698796244,
          },
          {
            leverage: -0.5,
            order_type: "SHORT",
            order_uuid: "7727970f-668f-422e-85c0-30908655188d",
            price: 3005.11,
            price_sources: [],
            processed_ms: 1714699578482,
          },
          {
            leverage: 0.5,
            order_type: "FLAT",
            order_uuid: "98b2949b-5d8b-425e-8dd1-078cc0c8afcf",
            price: 3013.1,
            price_sources: [],
            processed_ms: 1714700122317,
          },
        ],
        position_type: "FLAT",
        position_uuid: "56d575d7-ecc5-48ee-87a1-610e0102923c",
        return_at_close: 1.0000951141289702,
        trade_pair: ["ETHUSD", "ETH/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 3010.5699999999997,
        close_ms: 1714702321315,
        current_return: 0.9989909031666023,
        initial_entry_price: 3007.64,
        is_closed_position: true,
        miner_hotkey: "5DoCFr2EoW1CGuYCEXhsuQdWRsgiUMuxGwNt4Xqb5TCptcBW",
        net_leverage: 0.0,
        open_ms: 1714700935406,
        orders: [
          {
            leverage: -0.5,
            order_type: "SHORT",
            order_uuid: "0edd28af-432e-4936-869c-1f9facddc9d8",
            price: 3007.64,
            price_sources: [],
            processed_ms: 1714700935403,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "ba0f9947-54d3-47ea-9917-c72035d641ed",
            price: 3004.71,
            price_sources: [],
            processed_ms: 1714701221015,
          },
          {
            leverage: 0.25,
            order_type: "FLAT",
            order_uuid: "71e57e0f-83e3-4e45-9a32-7de27c92f5fb",
            price: 3022.71,
            price_sources: [],
            processed_ms: 1714702321315,
          },
        ],
        position_type: "FLAT",
        position_uuid: "e6a7067c-a2e6-463d-9e3b-86dd7a1e62cb",
        return_at_close: 0.9984914077150191,
        trade_pair: ["ETHUSD", "ETH/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 3026.85,
        close_ms: 1714731324425,
        current_return: 1.0038802715694535,
        initial_entry_price: 3026.85,
        is_closed_position: true,
        miner_hotkey: "5DoCFr2EoW1CGuYCEXhsuQdWRsgiUMuxGwNt4Xqb5TCptcBW",
        net_leverage: 0.0,
        open_ms: 1714702704653,
        orders: [
          {
            leverage: -0.25,
            order_type: "SHORT",
            order_uuid: "1550df94-e65c-42b4-ae11-16c36705a260",
            price: 3026.85,
            price_sources: [],
            processed_ms: 1714702704650,
          },
          {
            leverage: 0.25,
            order_type: "FLAT",
            order_uuid: "0176d688-a4ac-47e6-911b-cfbf692b1867",
            price: 2979.87,
            price_sources: [],
            processed_ms: 1714731324425,
          },
        ],
        position_type: "FLAT",
        position_uuid: "7a841b5a-7623-474d-8483-8e07139b6852",
        return_at_close: 1.0036293015015612,
        trade_pair: ["ETHUSD", "ETH/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 1.07516,
        close_ms: 1714739429656,
        current_return: 0.9965679526768108,
        initial_entry_price: 1.07516,
        is_closed_position: true,
        miner_hotkey: "5DoCFr2EoW1CGuYCEXhsuQdWRsgiUMuxGwNt4Xqb5TCptcBW",
        net_leverage: 0.0,
        open_ms: 1714739337507,
        orders: [
          {
            leverage: -1.0,
            order_type: "SHORT",
            order_uuid: "753a2539-b09c-4af6-80c3-5693c9cb8b82",
            price: 1.07516,
            price_sources: [],
            processed_ms: 1714739337324,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "99761afe-d5ce-4aec-8be6-fffcf772e177",
            price: 1.07885,
            price_sources: [],
            processed_ms: 1714739429656,
          },
        ],
        position_type: "FLAT",
        position_uuid: "e042c681-4597-4624-99ae-aa04b91cd949",
        return_at_close: 0.9964981929201233,
        trade_pair: ["EURUSD", "EUR/USD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 1.0830049999999996,
        close_ms: 1714747186045,
        current_return: 1.0062544558947433,
        initial_entry_price: 1.08003,
        is_closed_position: true,
        miner_hotkey: "5DoCFr2EoW1CGuYCEXhsuQdWRsgiUMuxGwNt4Xqb5TCptcBW",
        net_leverage: 0.0,
        open_ms: 1714739707777,
        orders: [
          {
            leverage: -1.0,
            order_type: "SHORT",
            order_uuid: "01854dac-36ed-427b-a68b-723e32a07dfc",
            price: 1.08003,
            price_sources: [],
            processed_ms: 1714739706049,
          },
          {
            leverage: -0.5,
            order_type: "SHORT",
            order_uuid: "fdfa5fad-f68b-4934-9e73-4d51efd2c26f",
            price: 1.07882,
            price_sources: [],
            processed_ms: 1714741254688,
          },
          {
            leverage: -0.5,
            order_type: "SHORT",
            order_uuid: "df14e9e1-015a-46cc-94c6-61f8ae1350b9",
            price: 1.07825,
            price_sources: [],
            processed_ms: 1714744159034,
          },
          {
            leverage: 0.5,
            order_type: "LONG",
            order_uuid: "f1df936e-bfab-457f-81f3-a557cae3f446",
            price: 1.07591,
            price_sources: [],
            processed_ms: 1714745998655,
          },
          {
            leverage: 0.5,
            order_type: "LONG",
            order_uuid: "4c636417-bd71-446c-be4f-f9da3e7e5125",
            price: 1.07521,
            price_sources: [],
            processed_ms: 1714746359349,
          },
          {
            leverage: 0.5,
            order_type: "FLAT",
            order_uuid: "46c1b318-3b4d-41a2-9329-1eee0f0fe9ea",
            price: 1.07625,
            price_sources: [],
            processed_ms: 1714747186045,
          },
        ],
        position_type: "FLAT",
        position_uuid: "5849c05d-c489-41b0-aa9a-b00458107fbe",
        return_at_close: 1.006113580270918,
        trade_pair: ["EURUSD", "EUR/USD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.9032432516678701,
        close_ms: 1715019454529,
        current_return: 1.002241432762082,
        initial_entry_price: 0.90422,
        is_closed_position: true,
        miner_hotkey: "5DoCFr2EoW1CGuYCEXhsuQdWRsgiUMuxGwNt4Xqb5TCptcBW",
        net_leverage: 0.0,
        open_ms: 1715009315466,
        orders: [
          {
            leverage: 1.0,
            order_type: "LONG",
            order_uuid: "72784374-af89-43bb-a833-953544b12f1f",
            price: 0.90422,
            price_sources: [],
            processed_ms: 1715009315281,
          },
          {
            leverage: 1.0,
            order_type: "LONG",
            order_uuid: "6f4a60ef-667b-4945-9e71-de3010afc2ff",
            price: 0.90443,
            price_sources: [],
            processed_ms: 1715009622011,
          },
          {
            leverage: -1.0,
            order_type: "SHORT",
            order_uuid: "1c213432-5532-4e59-89e5-770d3e239f64",
            price: 0.90540674833213,
            price_sources: [],
            processed_ms: 1715014514526,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "ff69ff24-698e-4434-acab-f1a03b511fc4",
            price: 0.90527,
            price_sources: [],
            processed_ms: 1715019454529,
          },
        ],
        position_type: "FLAT",
        position_uuid: "b3c81816-6ec0-4b28-b149-86d534ae2279",
        return_at_close: 1.0021011189614955,
        trade_pair: ["AUDCAD", "AUD/CAD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 1.2567,
        close_ms: 1715023503603,
        current_return: 0.9993077106708045,
        initial_entry_price: 1.2567,
        is_closed_position: true,
        miner_hotkey: "5DoCFr2EoW1CGuYCEXhsuQdWRsgiUMuxGwNt4Xqb5TCptcBW",
        net_leverage: 0.0,
        open_ms: 1715020597101,
        orders: [
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "e7ce8bd7-01c4-4c47-9f6e-87ce7bfb498f",
            price: 1.2567,
            price_sources: [],
            processed_ms: 1715020593721,
          },
          {
            leverage: 0.5,
            order_type: "FLAT",
            order_uuid: "cb97a77f-2d04-4019-8d7b-351d4be6320f",
            price: 1.25612,
            price_sources: [],
            processed_ms: 1715023503603,
          },
        ],
        position_type: "FLAT",
        position_uuid: "6c299211-7fd0-40db-94b6-2c8ba96a1618",
        return_at_close: 0.9992027833611841,
        trade_pair: ["GBPUSD", "GBP/USD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 1.0776466666666666,
        close_ms: 1715043213282,
        current_return: 1.0004268903819742,
        initial_entry_price: 1.07756,
        is_closed_position: true,
        miner_hotkey: "5DoCFr2EoW1CGuYCEXhsuQdWRsgiUMuxGwNt4Xqb5TCptcBW",
        net_leverage: 0.0,
        open_ms: 1715008633316,
        orders: [
          {
            leverage: -1.0,
            order_type: "SHORT",
            order_uuid: "d0284829-7252-4334-8d1d-c2cf77d355b2",
            price: 1.07756,
            price_sources: [],
            processed_ms: 1715008633079,
          },
          {
            leverage: -0.5,
            order_type: "SHORT",
            order_uuid: "b8f536b9-1282-4253-a563-258c022c2746",
            price: 1.07782,
            price_sources: [],
            processed_ms: 1715009743100,
          },
          {
            leverage: 0.5,
            order_type: "FLAT",
            order_uuid: "412df4da-873f-4c3e-bc57-fdf8e61d58fc",
            price: 1.07734,
            price_sources: [],
            processed_ms: 1715043213282,
          },
        ],
        position_type: "FLAT",
        position_uuid: "3b345d69-0005-43be-9fa1-675147b6d95c",
        return_at_close: 1.0003218455584841,
        trade_pair: ["EURUSD", "EUR/USD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.9051372762800557,
        close_ms: 1715044361726,
        current_return: 1.0027881736395772,
        initial_entry_price: 0.90527,
        is_closed_position: true,
        miner_hotkey: "5DoCFr2EoW1CGuYCEXhsuQdWRsgiUMuxGwNt4Xqb5TCptcBW",
        net_leverage: 0.0,
        open_ms: 1715020082815,
        orders: [
          {
            leverage: 1.0,
            order_type: "LONG",
            order_uuid: "56ef4e5c-1b6e-40f7-8745-4f1a2d6194cf",
            price: 0.90527,
            price_sources: [],
            processed_ms: 1715020081603,
          },
          {
            leverage: 0.5,
            order_type: "LONG",
            order_uuid: "8bab709f-523a-41a2-b6ca-6ade365dcd7c",
            price: 0.904871828840167,
            price_sources: [],
            processed_ms: 1715021342512,
          },
          {
            leverage: 0.5,
            order_type: "FLAT",
            order_uuid: "0d07eea9-a57f-477e-b657-6891501e0dc1",
            price: 0.906819976247189,
            price_sources: [],
            processed_ms: 1715044361726,
          },
        ],
        position_type: "FLAT",
        position_uuid: "d858809c-50cb-40cf-8e20-75b01b35790c",
        return_at_close: 1.002682880881345,
        trade_pair: ["AUDCAD", "AUD/CAD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 169.79754931345425,
        close_ms: 1715044577439,
        current_return: 0.9964578300868352,
        initial_entry_price: 169.755,
        is_closed_position: true,
        miner_hotkey: "5DoCFr2EoW1CGuYCEXhsuQdWRsgiUMuxGwNt4Xqb5TCptcBW",
        net_leverage: 0.0,
        open_ms: 1715000010037,
        orders: [
          {
            leverage: -1.5,
            order_type: "SHORT",
            order_uuid: "8aec8067-5513-49c6-aff5-e838f699257a",
            price: 169.755,
            price_sources: [],
            processed_ms: 1715000009741,
          },
          {
            leverage: -0.5,
            order_type: "SHORT",
            order_uuid: "38613ed0-aaae-4dc9-9c53-1e7c2abe4629",
            price: 169.925197253817,
            price_sources: [],
            processed_ms: 1715012569201,
          },
          {
            leverage: 0.5,
            order_type: "FLAT",
            order_uuid: "57485d2a-51b6-4b94-a113-a49232d5dec8",
            price: 170.0981998402589,
            price_sources: [],
            processed_ms: 1715044577439,
          },
        ],
        position_type: "FLAT",
        position_uuid: "409002b0-7e01-4480-aa11-896d9c6c3c45",
        return_at_close: 0.996318325990623,
        trade_pair: ["CHFJPY", "CHF/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 193.713,
        close_ms: 1715048387128,
        current_return: 1.0004567765511048,
        initial_entry_price: 193.749,
        is_closed_position: true,
        miner_hotkey: "5DoCFr2EoW1CGuYCEXhsuQdWRsgiUMuxGwNt4Xqb5TCptcBW",
        net_leverage: 0.0,
        open_ms: 1715044654523,
        orders: [
          {
            leverage: 0.5,
            order_type: "LONG",
            order_uuid: "29e2e5b8-c6b6-4b67-ae93-3fbde56fe6ed",
            price: 193.749,
            price_sources: [],
            processed_ms: 1715044653486,
          },
          {
            leverage: 0.5,
            order_type: "LONG",
            order_uuid: "5b6f235d-f4a5-4e3d-a150-47149eb47abf",
            price: 193.667,
            price_sources: [],
            processed_ms: 1715044829106,
          },
          {
            leverage: -0.5,
            order_type: "SHORT",
            order_uuid: "ccdd7faf-3157-415a-9798-7648c5c3fb7d",
            price: 193.703,
            price_sources: [],
            processed_ms: 1715045575064,
          },
          {
            leverage: 0.5,
            order_type: "FLAT",
            order_uuid: "184bdeb0-05e1-4d7b-a8d5-b1eb870bf3f4",
            price: 193.89,
            price_sources: [],
            processed_ms: 1715048387128,
          },
        ],
        position_type: "FLAT",
        position_uuid: "637380f5-02a0-4111-bbb3-c8b63a26a766",
        return_at_close: 1.0003867445767463,
        trade_pair: ["GBPJPY", "GBP/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 112.87549999999999,
        close_ms: 1715079097651,
        current_return: 1.0003854981788536,
        initial_entry_price: 112.841,
        is_closed_position: true,
        miner_hotkey: "5DoCFr2EoW1CGuYCEXhsuQdWRsgiUMuxGwNt4Xqb5TCptcBW",
        net_leverage: 0.0,
        open_ms: 1715044639896,
        orders: [
          {
            leverage: 0.5,
            order_type: "LONG",
            order_uuid: "9baa87d7-2de1-49e1-96c3-bd79d6115bd3",
            price: 112.841,
            price_sources: [],
            processed_ms: 1715044638017,
          },
          {
            leverage: 0.5,
            order_type: "LONG",
            order_uuid: "f5e4e2ca-fe95-4506-9bd8-14c986818695",
            price: 112.816,
            price_sources: [],
            processed_ms: 1715045001469,
          },
          {
            leverage: -0.5,
            order_type: "SHORT",
            order_uuid: "1ff2661d-cab5-4ac4-99d7-aa120185f0b5",
            price: 112.806,
            price_sources: [],
            processed_ms: 1715045547839,
          },
          {
            leverage: 0.5,
            order_type: "LONG",
            order_uuid: "d9937e53-d66b-4d9f-b439-3bf13923e7a6",
            price: 112.9,
            price_sources: [],
            processed_ms: 1715048446581,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "936ba80a-e778-4770-9953-742ba6c2785a",
            price: 112.919,
            price_sources: [],
            processed_ms: 1715079097651,
          },
        ],
        position_type: "FLAT",
        position_uuid: "8ac03d2b-30ed-4119-a4d5-d23540e789bd",
        return_at_close: 1.0002804577015447,
        trade_pair: ["CADJPY", "CAD/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 1.2534,
        close_ms: 1715082739886,
        current_return: 1.0005584809318653,
        initial_entry_price: 1.2534,
        is_closed_position: true,
        miner_hotkey: "5DoCFr2EoW1CGuYCEXhsuQdWRsgiUMuxGwNt4Xqb5TCptcBW",
        net_leverage: 0.0,
        open_ms: 1715076828704,
        orders: [
          {
            leverage: 1.0,
            order_type: "LONG",
            order_uuid: "90818d0b-8f01-447f-8703-9a427c98a439",
            price: 1.2534,
            price_sources: [],
            processed_ms: 1715076828499,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "335923cf-b5df-492b-9741-06689cb66f61",
            price: 1.2541,
            price_sources: [],
            processed_ms: 1715082739886,
          },
        ],
        position_type: "FLAT",
        position_uuid: "ba004f2c-d915-4641-bcd6-b5400e2f7bc9",
        return_at_close: 1.0004884418382,
        trade_pair: ["GBPUSD", "GBP/USD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 1.07764,
        close_ms: 1715088789368,
        current_return: 0.9994896254778961,
        initial_entry_price: 1.07764,
        is_closed_position: true,
        miner_hotkey: "5DoCFr2EoW1CGuYCEXhsuQdWRsgiUMuxGwNt4Xqb5TCptcBW",
        net_leverage: 0.0,
        open_ms: 1715087561376,
        orders: [
          {
            leverage: -1.0,
            order_type: "SHORT",
            order_uuid: "312b7b08-9f09-466e-8791-3561695d7995",
            price: 1.07764,
            price_sources: [],
            processed_ms: 1715087560831,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "ce0c8b41-a8ed-4bd4-94ea-c44824d18855",
            price: 1.07819,
            price_sources: [],
            processed_ms: 1715088789368,
          },
        ],
        position_type: "FLAT",
        position_uuid: "85f169d4-53dd-495a-b7d8-4bf5dbbe7690",
        return_at_close: 0.9994196612041126,
        trade_pair: ["EURUSD", "EUR/USD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 170.2455,
        close_ms: 1715088996528,
        current_return: 0.9983452986164615,
        initial_entry_price: 170.239,
        is_closed_position: true,
        miner_hotkey: "5DoCFr2EoW1CGuYCEXhsuQdWRsgiUMuxGwNt4Xqb5TCptcBW",
        net_leverage: 0.0,
        open_ms: 1715087670507,
        orders: [
          {
            leverage: -1.0,
            order_type: "SHORT",
            order_uuid: "d638c27b-8979-4f88-9a30-3ab7b1da4c3e",
            price: 170.239,
            price_sources: [],
            processed_ms: 1715087670306,
          },
          {
            leverage: -1.0,
            order_type: "SHORT",
            order_uuid: "008bbd9e-e109-43d7-8cd4-f860b9b3db91",
            price: 170.252,
            price_sources: [],
            processed_ms: 1715087873557,
          },
          {
            leverage: 2.0,
            order_type: "LONG",
            order_uuid: "bcdaa88b-ef2e-46f7-99df-80f45e6afbc7",
            price: 170.3863473544161,
            price_sources: [],
            processed_ms: 1715088996528,
          },
        ],
        position_type: "FLAT",
        position_uuid: "f5d8ec72-9f98-4da0-9d01-7aef2166dd87",
        return_at_close: 0.9982055302746552,
        trade_pair: ["CHFJPY", "CHF/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 1.107339999999998,
        close_ms: 1715097702090,
        current_return: 0.9971591804935961,
        initial_entry_price: 1.07821,
        is_closed_position: true,
        miner_hotkey: "5DoCFr2EoW1CGuYCEXhsuQdWRsgiUMuxGwNt4Xqb5TCptcBW",
        net_leverage: 0.0,
        open_ms: 1715088808651,
        orders: [
          {
            leverage: 2.0,
            order_type: "LONG",
            order_uuid: "6417486a-312e-4536-98a5-7d97707ff9a1",
            price: 1.07821,
            price_sources: [],
            processed_ms: 1715088808454,
          },
          {
            leverage: 1.0,
            order_type: "LONG",
            order_uuid: "caeb0f7b-db89-4c0a-a759-b1b80c4b939a",
            price: 1.07773,
            price_sources: [],
            processed_ms: 1715092103065,
          },
          {
            leverage: -2.9,
            order_type: "SHORT",
            order_uuid: "3eedce0c-062c-4c57-bd85-d890a779371c",
            price: 1.07704,
            price_sources: [],
            processed_ms: 1715096643880,
          },
          {
            leverage: 1.9,
            order_type: "FLAT",
            order_uuid: "95b4f873-964e-40b1-b2bb-46385cb5ea12",
            price: 1.07671,
            price_sources: [],
            processed_ms: 1715097702090,
          },
        ],
        position_type: "FLAT",
        position_uuid: "07fcd99c-c7b1-4dc8-8e4d-d7b51c68b727",
        return_at_close: 0.9969497770656924,
        trade_pair: ["EURUSD", "EUR/USD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 1.2896000000000007,
        close_ms: 1715104992623,
        current_return: 0.9969126116151438,
        initial_entry_price: 1.25543,
        is_closed_position: true,
        miner_hotkey: "5DoCFr2EoW1CGuYCEXhsuQdWRsgiUMuxGwNt4Xqb5TCptcBW",
        net_leverage: 0.0,
        open_ms: 1715087597662,
        orders: [
          {
            leverage: 1.0,
            order_type: "LONG",
            order_uuid: "999f5840-cefe-4ec8-8eac-b773e3f21b61",
            price: 1.25543,
            price_sources: [],
            processed_ms: 1715087597656,
          },
          {
            leverage: 1.0,
            order_type: "LONG",
            order_uuid: "70d0f7db-a6c1-4188-a9c4-44d50b4b149b",
            price: 1.25556,
            price_sources: [],
            processed_ms: 1715092119158,
          },
          {
            leverage: -1.9,
            order_type: "SHORT",
            order_uuid: "722358e8-6282-4180-ba9a-39d28760f9c8",
            price: 1.2537,
            price_sources: [],
            processed_ms: 1715096668898,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "6db8b5a5-2025-4207-abc6-addf2400c547",
            price: 1.25084,
            price_sources: [],
            processed_ms: 1715104992623,
          },
        ],
        position_type: "FLAT",
        position_uuid: "aecabb81-6c23-4046-8ba1-b2c43b7ee3ee",
        return_at_close: 0.9967730438495176,
        trade_pair: ["GBPUSD", "GBP/USD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 1.07673,
        close_ms: 1715175148069,
        current_return: 1.0015231302183463,
        initial_entry_price: 1.07673,
        is_closed_position: true,
        miner_hotkey: "5DoCFr2EoW1CGuYCEXhsuQdWRsgiUMuxGwNt4Xqb5TCptcBW",
        net_leverage: 0.0,
        open_ms: 1715097713541,
        orders: [
          {
            leverage: -1.0,
            order_type: "SHORT",
            order_uuid: "10ffd242-9ea8-43b7-b717-d67f96cc1131",
            price: 1.07673,
            price_sources: [],
            processed_ms: 1715097713535,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "b1804ed1-6e2e-4b50-b7e6-0e4f9aff5f20",
            price: 1.07509,
            price_sources: [],
            processed_ms: 1715175148069,
          },
        ],
        position_type: "FLAT",
        position_uuid: "8f420851-dac5-45ca-acf5-c264bb41f0fb",
        return_at_close: 1.001453023599231,
        trade_pair: ["EURUSD", "EUR/USD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 1.24705,
        close_ms: 1715189458998,
        current_return: 1.0028266709434266,
        initial_entry_price: 1.24705,
        is_closed_position: true,
        miner_hotkey: "5DoCFr2EoW1CGuYCEXhsuQdWRsgiUMuxGwNt4Xqb5TCptcBW",
        net_leverage: 0.0,
        open_ms: 1715155755873,
        orders: [
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "8400ebf1-53ad-4ec4-b7e0-2d2c12eaac5d",
            price: 1.24705,
            price_sources: [],
            processed_ms: 1715155755690,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "20e6e343-c9de-4ee5-96f9-578a9fb4a855",
            price: 1.2494,
            price_sources: [],
            processed_ms: 1715189458998,
          },
        ],
        position_type: "FLAT",
        position_uuid: "d4e16eb4-38a6-4460-8e10-8a6c05deb85b",
        return_at_close: 1.0027213741429775,
        trade_pair: ["GBPUSD", "GBP/USD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 1.07282,
        close_ms: 1715264913267,
        current_return: 1.0064036837493706,
        initial_entry_price: 1.07282,
        is_closed_position: true,
        miner_hotkey: "5DoCFr2EoW1CGuYCEXhsuQdWRsgiUMuxGwNt4Xqb5TCptcBW",
        net_leverage: 0.0,
        open_ms: 1715244923626,
        orders: [
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "29dd7cbe-b0dd-4312-98d8-7ac323f5ed80",
            price: 1.07282,
            price_sources: [],
            processed_ms: 1715244923437,
          },
          {
            leverage: 0.5,
            order_type: "FLAT",
            order_uuid: "febdfefa-8e86-42af-8e22-dbb83a587472",
            price: 1.0774,
            price_sources: [],
            processed_ms: 1715264913267,
          },
        ],
        position_type: "FLAT",
        position_uuid: "000b0b53-7285-40c2-bfd1-1a5eb87ca4a5",
        return_at_close: 1.006298011362577,
        trade_pair: ["EURUSD", "EUR/USD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 155.71233333333336,
        close_ms: 1715266477169,
        current_return: 1.0008800724614408,
        initial_entry_price: 155.669,
        is_closed_position: true,
        miner_hotkey: "5DoCFr2EoW1CGuYCEXhsuQdWRsgiUMuxGwNt4Xqb5TCptcBW",
        net_leverage: 0.0,
        open_ms: 1715262417574,
        orders: [
          {
            leverage: -1.0,
            order_type: "SHORT",
            order_uuid: "72632b5b-200c-406c-95f5-b19ade695338",
            price: 155.669,
            price_sources: [],
            processed_ms: 1715262417381,
          },
          {
            leverage: -1.0,
            order_type: "SHORT",
            order_uuid: "d0c24781-0e16-47e2-87fd-5476a4a79242",
            price: 155.652,
            price_sources: [],
            processed_ms: 1715262760940,
          },
          {
            leverage: 0.5,
            order_type: "LONG",
            order_uuid: "df0c4450-60c1-4d51-877c-2e721ea59c11",
            price: 155.505,
            price_sources: [],
            processed_ms: 1715264807320,
          },
          {
            leverage: 0.5,
            order_type: "FLAT",
            order_uuid: "c3c93775-9e69-4f84-8460-0fc6bcb03c9a",
            price: 155.621,
            price_sources: [],
            processed_ms: 1715266477169,
          },
        ],
        position_type: "FLAT",
        position_uuid: "d13e6408-0906-4ec7-b814-347f2b824a93",
        return_at_close: 1.0007399492512963,
        trade_pair: ["USDJPY", "USD/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 1.2496,
        close_ms: 1715269254853,
        current_return: 1.001740626625585,
        initial_entry_price: 1.24955,
        is_closed_position: true,
        miner_hotkey: "5DoCFr2EoW1CGuYCEXhsuQdWRsgiUMuxGwNt4Xqb5TCptcBW",
        net_leverage: 0.0,
        open_ms: 1715259673554,
        orders: [
          {
            leverage: 1.0,
            order_type: "LONG",
            order_uuid: "8fe23500-d8f4-4a84-95ec-4552c428ddd6",
            price: 1.24955,
            price_sources: [],
            processed_ms: 1715259673547,
          },
          {
            leverage: 1.0,
            order_type: "LONG",
            order_uuid: "29a56b59-a35c-4c4a-a194-9eebf80a23c0",
            price: 1.25033,
            price_sources: [],
            processed_ms: 1715262707792,
          },
          {
            leverage: -0.5,
            order_type: "SHORT",
            order_uuid: "04838e84-e636-494b-85a2-e2323cdbe847",
            price: 1.25096,
            price_sources: [],
            processed_ms: 1715265852113,
          },
          {
            leverage: 0.5,
            order_type: "FLAT",
            order_uuid: "05c72f23-54cc-456f-adab-3a7732943b78",
            price: 1.25105,
            price_sources: [],
            processed_ms: 1715269254853,
          },
        ],
        position_type: "FLAT",
        position_uuid: "cd497b0e-d878-4629-94fc-e4c715f67b90",
        return_at_close: 1.0016003829378575,
        trade_pair: ["GBPUSD", "GBP/USD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.90439,
        close_ms: 1715275368212,
        current_return: 1.000282074091455,
        initial_entry_price: 0.90439,
        is_closed_position: true,
        miner_hotkey: "5DoCFr2EoW1CGuYCEXhsuQdWRsgiUMuxGwNt4Xqb5TCptcBW",
        net_leverage: 0.0,
        open_ms: 1715273098949,
        orders: [
          {
            leverage: 1.0,
            order_type: "LONG",
            order_uuid: "675094a3-958d-49b2-b1eb-b23e62ab25ae",
            price: 0.90439,
            price_sources: [],
            processed_ms: 1715273098748,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "e511826d-31b9-4927-8cf5-d4aa8991a69a",
            price: 0.904645104987571,
            price_sources: [],
            processed_ms: 1715275368212,
          },
        ],
        position_type: "FLAT",
        position_uuid: "263f1adb-3700-4aea-99c7-d824c6738513",
        return_at_close: 1.0002120543462687,
        trade_pair: ["AUDCAD", "AUD/CAD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 155.537,
        close_ms: 1715281294824,
        current_return: 1.000398618978121,
        initial_entry_price: 155.537,
        is_closed_position: true,
        miner_hotkey: "5DoCFr2EoW1CGuYCEXhsuQdWRsgiUMuxGwNt4Xqb5TCptcBW",
        net_leverage: 0.0,
        open_ms: 1715275497817,
        orders: [
          {
            leverage: -1.0,
            order_type: "SHORT",
            order_uuid: "a5488d62-18fa-462a-94be-451dc1a843fd",
            price: 155.537,
            price_sources: [],
            processed_ms: 1715275496861,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "68392c6e-6c34-4a27-8292-63b43307a5c5",
            price: 155.475,
            price_sources: [],
            processed_ms: 1715281294824,
          },
        ],
        position_type: "FLAT",
        position_uuid: "2f39b50e-7289-4112-8cb2-43079d1701af",
        return_at_close: 1.0003285910747926,
        trade_pair: ["USDJPY", "USD/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 62137.81,
        close_ms: 1715281679570,
        current_return: 1.0004589637130759,
        initial_entry_price: 62137.81,
        is_closed_position: true,
        miner_hotkey: "5DoCFr2EoW1CGuYCEXhsuQdWRsgiUMuxGwNt4Xqb5TCptcBW",
        net_leverage: 0.0,
        open_ms: 1715269366390,
        orders: [
          {
            leverage: 0.1,
            order_type: "LONG",
            order_uuid: "bfde0141-750b-4132-9e15-78a389e594a7",
            price: 62137.81,
            price_sources: [],
            processed_ms: 1715269366186,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "65e53f10-12ab-499e-9ce6-eef9b910ef9e",
            price: 62423.0,
            price_sources: [],
            processed_ms: 1715281679570,
          },
        ],
        position_type: "FLAT",
        position_uuid: "2fe74931-9bdc-4b60-9811-11ac2b0d214e",
        return_at_close: 1.0003589178167045,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 155.46125,
        close_ms: 1715286845173,
        current_return: 1.0002379696685149,
        initial_entry_price: 155.482,
        is_closed_position: true,
        miner_hotkey: "5DoCFr2EoW1CGuYCEXhsuQdWRsgiUMuxGwNt4Xqb5TCptcBW",
        net_leverage: 0.0,
        open_ms: 1715281392157,
        orders: [
          {
            leverage: -1.0,
            order_type: "SHORT",
            order_uuid: "02c0ca2b-b400-4bab-9142-342f32b5e2c3",
            price: 155.482,
            price_sources: [],
            processed_ms: 1715281391973,
          },
          {
            leverage: -2.0,
            order_type: "SHORT",
            order_uuid: "6369921e-758b-4faf-9f85-e8f8b4e35c4a",
            price: 155.451,
            price_sources: [],
            processed_ms: 1715282582949,
          },
          {
            leverage: 1.0,
            order_type: "LONG",
            order_uuid: "8fbcadc5-85e3-4839-8707-eb2f015a58b8",
            price: 155.419,
            price_sources: [],
            processed_ms: 1715285444239,
          },
          {
            leverage: -2.0,
            order_type: "SHORT",
            order_uuid: "f57086ea-2b45-44f9-b7da-8083d120f369",
            price: 155.44,
            price_sources: [],
            processed_ms: 1715286238890,
          },
          {
            leverage: 2.0,
            order_type: "FLAT",
            order_uuid: "a98d8ce3-da16-431d-90f8-6999e74cce55",
            price: 155.452,
            price_sources: [],
            processed_ms: 1715286845173,
          },
        ],
        position_type: "FLAT",
        position_uuid: "48edcc0a-83f6-4606-a493-45eb775744c9",
        return_at_close: 0.999887886379131,
        trade_pair: ["USDJPY", "USD/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 1.2525650000000002,
        close_ms: 1715299396547,
        current_return: 0.999788419775166,
        initial_entry_price: 1.25248,
        is_closed_position: true,
        miner_hotkey: "5DoCFr2EoW1CGuYCEXhsuQdWRsgiUMuxGwNt4Xqb5TCptcBW",
        net_leverage: 0.0,
        open_ms: 1715294016502,
        orders: [
          {
            leverage: 0.5,
            order_type: "LONG",
            order_uuid: "898ba94f-22c3-4143-931e-27054c001343",
            price: 1.25248,
            price_sources: [],
            processed_ms: 1715294016176,
          },
          {
            leverage: 0.5,
            order_type: "LONG",
            order_uuid: "e3c101d4-7c40-4c75-9b2a-160ed72686b8",
            price: 1.25265,
            price_sources: [],
            processed_ms: 1715296581086,
          },
          {
            leverage: 0.5,
            order_type: "FLAT",
            order_uuid: "abf60949-fb73-4333-a56a-697388eca9c7",
            price: 1.2523,
            price_sources: [],
            processed_ms: 1715299396547,
          },
        ],
        position_type: "FLAT",
        position_uuid: "fcfd9145-5514-4cbf-a101-83d278d4ab05",
        return_at_close: 0.9997184345857817,
        trade_pair: ["GBPUSD", "GBP/USD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 1.25273,
        close_ms: 1715340497682,
        current_return: 1.0000039912830379,
        initial_entry_price: 1.25273,
        is_closed_position: true,
        miner_hotkey: "5DoCFr2EoW1CGuYCEXhsuQdWRsgiUMuxGwNt4Xqb5TCptcBW",
        net_leverage: 0.0,
        open_ms: 1715340443959,
        orders: [
          {
            leverage: -0.5,
            order_type: "SHORT",
            order_uuid: "161303dc-b04e-4e7a-9bd6-fbdf2bcaf39f",
            price: 1.25273,
            price_sources: [],
            processed_ms: 1715340443455,
          },
          {
            leverage: 1.0,
            order_type: "LONG",
            order_uuid: "f5cf485f-3130-4447-948d-426c64df6e52",
            price: 1.25272,
            price_sources: [],
            processed_ms: 1715340497682,
          },
        ],
        position_type: "FLAT",
        position_uuid: "b0a12cf3-7e31-44a9-bd4d-1cf4cd5a65fc",
        return_at_close: 0.9999689911433429,
        trade_pair: ["GBPUSD", "GBP/USD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 1.07786,
        close_ms: 1715346372390,
        current_return: 1.0009880689514408,
        initial_entry_price: 1.07786,
        is_closed_position: true,
        miner_hotkey: "5DoCFr2EoW1CGuYCEXhsuQdWRsgiUMuxGwNt4Xqb5TCptcBW",
        net_leverage: 0.0,
        open_ms: 1715336009167,
        orders: [
          {
            leverage: -1.5,
            order_type: "SHORT",
            order_uuid: "73b72f6b-5649-490d-8d4e-9c92bd52cd14",
            price: 1.07786,
            price_sources: [],
            processed_ms: 1715336008982,
          },
          {
            leverage: 0.5,
            order_type: "FLAT",
            order_uuid: "55594782-687b-4bae-95bf-6b0d53ce3402",
            price: 1.07715,
            price_sources: [],
            processed_ms: 1715346372390,
          },
        ],
        position_type: "FLAT",
        position_uuid: "a8230b22-e280-46a2-86b7-4e7cde4d6de2",
        return_at_close: 1.0008829652042008,
        trade_pair: ["EURUSD", "EUR/USD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 1.25166,
        close_ms: 1715361594814,
        current_return: 1.001293278941108,
        initial_entry_price: 1.25263,
        is_closed_position: true,
        miner_hotkey: "5DoCFr2EoW1CGuYCEXhsuQdWRsgiUMuxGwNt4Xqb5TCptcBW",
        net_leverage: 0.0,
        open_ms: 1715346817080,
        orders: [
          {
            leverage: 0.5,
            order_type: "LONG",
            order_uuid: "60bdcd93-b5f8-4cd7-88a6-7142bf99d0d7",
            price: 1.25263,
            price_sources: [],
            processed_ms: 1715346816574,
          },
          {
            leverage: 0.5,
            order_type: "LONG",
            order_uuid: "8897323c-bb16-4b77-918e-adb5d9d47dcf",
            price: 1.25069,
            price_sources: [],
            processed_ms: 1715353255732,
          },
          {
            leverage: 0.5,
            order_type: "FLAT",
            order_uuid: "56451cad-646f-4a8e-bd34-e4315cc76d34",
            price: 1.25328,
            price_sources: [],
            processed_ms: 1715361594814,
          },
        ],
        position_type: "FLAT",
        position_uuid: "6866383d-3ce1-4480-a5b2-b1373c16705e",
        return_at_close: 1.001223188411582,
        trade_pair: ["GBPUSD", "GBP/USD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 171.9433376693878,
        close_ms: 1715363717917,
        current_return: 0.9999079280097695,
        initial_entry_price: 171.9433376693878,
        is_closed_position: true,
        miner_hotkey: "5DoCFr2EoW1CGuYCEXhsuQdWRsgiUMuxGwNt4Xqb5TCptcBW",
        net_leverage: 0.0,
        open_ms: 1715363525879,
        orders: [
          {
            leverage: -0.5,
            order_type: "SHORT",
            order_uuid: "342d8f4a-27ab-4a44-867c-834b17f8f7c2",
            price: 171.9433376693878,
            price_sources: [],
            processed_ms: 1715363525431,
          },
          {
            leverage: 0.5,
            order_type: "FLAT",
            order_uuid: "f2918e22-5bec-4507-ac66-d24c8aecbc36",
            price: 171.975,
            price_sources: [],
            processed_ms: 1715363717917,
          },
        ],
        position_type: "FLAT",
        position_uuid: "6f3902ba-5599-428d-aa93-785d82bb8993",
        return_at_close: 0.9998729312322892,
        trade_pair: ["CHFJPY", "CHF/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 1.07821625,
        close_ms: 1715606140254,
        current_return: 0.9963015350368691,
        initial_entry_price: 1.07815,
        is_closed_position: true,
        miner_hotkey: "5DoCFr2EoW1CGuYCEXhsuQdWRsgiUMuxGwNt4Xqb5TCptcBW",
        net_leverage: 0.0,
        open_ms: 1715593901576,
        orders: [
          {
            leverage: -1.5,
            order_type: "SHORT",
            order_uuid: "a6869bb5-7b7b-4366-b31f-e04ee8362366",
            price: 1.07815,
            price_sources: [],
            processed_ms: 1715593900825,
          },
          {
            leverage: -0.25,
            order_type: "SHORT",
            order_uuid: "21f522a2-b5d5-4fcc-8eea-5338ea33798c",
            price: 1.07788,
            price_sources: [],
            processed_ms: 1715597140019,
          },
          {
            leverage: -0.25,
            order_type: "SHORT",
            order_uuid: "c5f594a3-4906-4492-92af-b26b3d32cbc7",
            price: 1.07895,
            price_sources: [],
            processed_ms: 1715601425492,
          },
          {
            leverage: 0.25,
            order_type: "FLAT",
            order_uuid: "92642965-bfb3-45c6-96ea-341793379c39",
            price: 1.08021,
            price_sources: [],
            processed_ms: 1715606140254,
          },
        ],
        position_type: "FLAT",
        position_uuid: "8811a21c-b1c4-40dc-9a24-ceffa07583a6",
        return_at_close: 0.9961620528219639,
        trade_pair: ["EURUSD", "EUR/USD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.82329,
        close_ms: 1715612906856,
        current_return: 0.9996113155753137,
        initial_entry_price: 0.82329,
        is_closed_position: true,
        miner_hotkey: "5DoCFr2EoW1CGuYCEXhsuQdWRsgiUMuxGwNt4Xqb5TCptcBW",
        net_leverage: 0.0,
        open_ms: 1715611046808,
        orders: [
          {
            leverage: 0.5,
            order_type: "LONG",
            order_uuid: "e82b0985-7eb0-4807-b2b2-b7aba0fe8eb0",
            price: 0.82329,
            price_sources: [],
            processed_ms: 1715611046440,
          },
          {
            leverage: 0.25,
            order_type: "FLAT",
            order_uuid: "16b70649-2636-436b-a1c6-92a868815327",
            price: 0.82265,
            price_sources: [],
            processed_ms: 1715612906856,
          },
        ],
        position_type: "FLAT",
        position_uuid: "e749b804-3589-4e1a-ba80-73eb002b6111",
        return_at_close: 0.9995763291792685,
        trade_pair: ["NZDCAD", "NZD/CAD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 1.2560066666666667,
        close_ms: 1715615996201,
        current_return: 1.0000218947301376,
        initial_entry_price: 1.25601,
        is_closed_position: true,
        miner_hotkey: "5DoCFr2EoW1CGuYCEXhsuQdWRsgiUMuxGwNt4Xqb5TCptcBW",
        net_leverage: 0.0,
        open_ms: 1715607697853,
        orders: [
          {
            leverage: -0.5,
            order_type: "SHORT",
            order_uuid: "40facdcb-dfb7-44ad-b93c-7e2e9bcbefc8",
            price: 1.25601,
            price_sources: [],
            processed_ms: 1715607697036,
          },
          {
            leverage: -0.25,
            order_type: "SHORT",
            order_uuid: "7ace6ce9-6aff-419a-aeb5-bfbf46b5129b",
            price: 1.256,
            price_sources: [],
            processed_ms: 1715612510367,
          },
          {
            leverage: 0.25,
            order_type: "FLAT",
            order_uuid: "e9404e65-e347-40a1-9cb9-4aa0dff89bda",
            price: 1.25597,
            price_sources: [],
            processed_ms: 1715615996201,
          },
        ],
        position_type: "FLAT",
        position_uuid: "5a7f4db5-1089-498d-bf25-1de086328428",
        return_at_close: 0.9999693935806643,
        trade_pair: ["GBPUSD", "GBP/USD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 1.0796733333333333,
        close_ms: 1715621990052,
        current_return: 1.0007965175511715,
        initial_entry_price: 1.0797,
        is_closed_position: true,
        miner_hotkey: "5DoCFr2EoW1CGuYCEXhsuQdWRsgiUMuxGwNt4Xqb5TCptcBW",
        net_leverage: 0.0,
        open_ms: 1715617284079,
        orders: [
          {
            leverage: -0.5,
            order_type: "SHORT",
            order_uuid: "f019cac7-408b-47da-aa86-34f3b58555c1",
            price: 1.0797,
            price_sources: [],
            processed_ms: 1715617283027,
          },
          {
            leverage: -0.5,
            order_type: "SHORT",
            order_uuid: "d1dd0135-28e6-4c01-bb87-372fd8e6ec54",
            price: 1.07972,
            price_sources: [],
            processed_ms: 1715617915602,
          },
          {
            leverage: -0.5,
            order_type: "SHORT",
            order_uuid: "bcecff43-c65f-4325-a137-c4936a85effe",
            price: 1.0796,
            price_sources: [],
            processed_ms: 1715618274062,
          },
          {
            leverage: 0.5,
            order_type: "FLAT",
            order_uuid: "9735f7e2-b18d-463f-81ab-2514f118a458",
            price: 1.0791,
            price_sources: [],
            processed_ms: 1715621990052,
          },
        ],
        position_type: "FLAT",
        position_uuid: "d1115423-c2c8-4dc4-a013-339374f7e010",
        return_at_close: 1.0006914339168287,
        trade_pair: ["EURUSD", "EUR/USD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.6627985714285715,
        close_ms: 1715622058513,
        current_return: 1.0030142753666926,
        initial_entry_price: 0.66268,
        is_closed_position: true,
        miner_hotkey: "5DoCFr2EoW1CGuYCEXhsuQdWRsgiUMuxGwNt4Xqb5TCptcBW",
        net_leverage: 0.0,
        open_ms: 1715582965815,
        orders: [
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "bc66304e-8d28-4189-ae91-a691865a8231",
            price: 0.66268,
            price_sources: [],
            processed_ms: 1715582964802,
          },
          {
            leverage: 0.5,
            order_type: "LONG",
            order_uuid: "d1fa11c7-ed2f-4d7b-9151-b496d4152b34",
            price: 0.66325,
            price_sources: [],
            processed_ms: 1715593507339,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "09773f20-8efd-4056-bf13-b3d1c4ff0553",
            price: 0.66301,
            price_sources: [],
            processed_ms: 1715596827362,
          },
          {
            leverage: -0.5,
            order_type: "SHORT",
            order_uuid: "1e6a5b55-b2bc-45c9-a1f0-f3a75afaa331",
            price: 0.663,
            price_sources: [],
            processed_ms: 1715617327838,
          },
          {
            leverage: 0.5,
            order_type: "FLAT",
            order_uuid: "883a6c8b-da70-453e-91cf-0bd504a7094a",
            price: 0.66394,
            price_sources: [],
            processed_ms: 1715622058513,
          },
        ],
        position_type: "FLAT",
        position_uuid: "ef8ad6c7-3980-4b02-83dd-f8e474f4a2c9",
        return_at_close: 1.0028563006183224,
        trade_pair: ["CADCHF", "CAD/CHF", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 1.25589,
        close_ms: 1715624223610,
        current_return: 1.0002388915432394,
        initial_entry_price: 1.2558,
        is_closed_position: true,
        miner_hotkey: "5DoCFr2EoW1CGuYCEXhsuQdWRsgiUMuxGwNt4Xqb5TCptcBW",
        net_leverage: 0.0,
        open_ms: 1715621770436,
        orders: [
          {
            leverage: -0.5,
            order_type: "SHORT",
            order_uuid: "430322cd-535c-40e9-b704-9cb604875813",
            price: 1.2558,
            price_sources: [],
            processed_ms: 1715621769353,
          },
          {
            leverage: -0.5,
            order_type: "SHORT",
            order_uuid: "3acf5f49-97c8-42d3-b662-d6a9f551516d",
            price: 1.25598,
            price_sources: [],
            processed_ms: 1715622759355,
          },
          {
            leverage: 0.5,
            order_type: "FLAT",
            order_uuid: "8f3a0bc6-ff7b-42ff-b682-91a326161aba",
            price: 1.25559,
            price_sources: [],
            processed_ms: 1715624223610,
          },
        ],
        position_type: "FLAT",
        position_uuid: "2aba3362-14d1-4a65-86c0-53d55e765322",
        return_at_close: 1.0001688748208313,
        trade_pair: ["GBPUSD", "GBP/USD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.6639499999999999,
        close_ms: 1715629980474,
        current_return: 1.000056486962809,
        initial_entry_price: 0.66387,
        is_closed_position: true,
        miner_hotkey: "5DoCFr2EoW1CGuYCEXhsuQdWRsgiUMuxGwNt4Xqb5TCptcBW",
        net_leverage: 0.0,
        open_ms: 1715622499784,
        orders: [
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "c8234ab5-4935-4ded-86e9-f37c83c10733",
            price: 0.66387,
            price_sources: [],
            processed_ms: 1715622498953,
          },
          {
            leverage: 0.5,
            order_type: "LONG",
            order_uuid: "e47068e7-9aef-41d5-86e4-aa46767821ec",
            price: 0.66399,
            price_sources: [],
            processed_ms: 1715623583661,
          },
          {
            leverage: 0.5,
            order_type: "FLAT",
            order_uuid: "8c165a85-cede-40f7-bb38-ae9be7b26b81",
            price: 0.664,
            price_sources: [],
            processed_ms: 1715629980474,
          },
        ],
        position_type: "FLAT",
        position_uuid: "74142aa6-f2a5-4408-be9c-66e5216e9962",
        return_at_close: 1.0000039839972434,
        trade_pair: ["CADCHF", "CAD/CHF", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 1.25324,
        close_ms: 1715675717690,
        current_return: 1.0032514364495584,
        initial_entry_price: 1.25483,
        is_closed_position: true,
        miner_hotkey: "5DoCFr2EoW1CGuYCEXhsuQdWRsgiUMuxGwNt4Xqb5TCptcBW",
        net_leverage: 0.0,
        open_ms: 1715671964522,
        orders: [
          {
            leverage: -1.5,
            order_type: "SHORT",
            order_uuid: "37268949-0c6b-4034-ba8c-bd392a30f286",
            price: 1.25483,
            price_sources: [],
            processed_ms: 1715671961688,
          },
          {
            leverage: -1.5,
            order_type: "SHORT",
            order_uuid: "6eb822ce-e293-47e0-9f02-ac98890e7375",
            price: 1.25165,
            price_sources: [],
            processed_ms: 1715675672624,
          },
          {
            leverage: 3.0,
            order_type: "FLAT",
            order_uuid: "69253a15-738e-43af-ad92-747a0a9f84ab",
            price: 1.25188,
            price_sources: [],
            processed_ms: 1715675717690,
          },
        ],
        position_type: "FLAT",
        position_uuid: "81fc095b-dcea-49af-a9f0-978ade8cf3d5",
        return_at_close: 1.003040753647904,
        trade_pair: ["GBPUSD", "GBP/USD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 1.25489,
        close_ms: 1715682819352,
        current_return: 1.000009961032441,
        initial_entry_price: 1.25489,
        is_closed_position: true,
        miner_hotkey: "5DoCFr2EoW1CGuYCEXhsuQdWRsgiUMuxGwNt4Xqb5TCptcBW",
        net_leverage: 0.0,
        open_ms: 1715682134499,
        orders: [
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "0a11f48c-d9a6-441a-83d9-e51915a00fd7",
            price: 1.25489,
            price_sources: [],
            processed_ms: 1715682132654,
          },
          {
            leverage: 0.25,
            order_type: "FLAT",
            order_uuid: "b2c27960-63a7-41d7-ab30-77443f811bbd",
            price: 1.25494,
            price_sources: [],
            processed_ms: 1715682819352,
          },
        ],
        position_type: "FLAT",
        position_uuid: "31cb4dd3-e858-4ad8-aeb9-575cf67b5445",
        return_at_close: 0.999992460858123,
        trade_pair: ["GBPUSD", "GBP/USD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 1.255395,
        close_ms: 1715693732684,
        current_return: 1.000563574375886,
        initial_entry_price: 1.25538,
        is_closed_position: true,
        miner_hotkey: "5DoCFr2EoW1CGuYCEXhsuQdWRsgiUMuxGwNt4Xqb5TCptcBW",
        net_leverage: 0.0,
        open_ms: 1715690936700,
        orders: [
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "b64c44a1-623d-40de-a3e9-0a8006b54e9a",
            price: 1.25538,
            price_sources: [],
            processed_ms: 1715690935921,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "1368ce30-f354-4e50-b89b-eaa2b993f4a6",
            price: 1.25541,
            price_sources: [],
            processed_ms: 1715691106292,
          },
          {
            leverage: 0.25,
            order_type: "FLAT",
            order_uuid: "dad011da-8a31-4483-975a-60d3be83ccf2",
            price: 1.25681,
            price_sources: [],
            processed_ms: 1715693732684,
          },
        ],
        position_type: "FLAT",
        position_uuid: "562a32c6-8399-484f-a574-6ac4e06934fc",
        return_at_close: 1.000528554650783,
        trade_pair: ["GBPUSD", "GBP/USD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 1.25679,
        close_ms: 1715695575426,
        current_return: 1.0002526277261914,
        initial_entry_price: 1.25679,
        is_closed_position: true,
        miner_hotkey: "5DoCFr2EoW1CGuYCEXhsuQdWRsgiUMuxGwNt4Xqb5TCptcBW",
        net_leverage: 0.0,
        open_ms: 1715693757785,
        orders: [
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "814a5e78-044c-422a-8d56-8eca4fcce763",
            price: 1.25679,
            price_sources: [],
            processed_ms: 1715693756710,
          },
          {
            leverage: 0.25,
            order_type: "FLAT",
            order_uuid: "ee6ebba9-ce43-43b5-9d4d-fb230d36929a",
            price: 1.25806,
            price_sources: [],
            processed_ms: 1715695575426,
          },
        ],
        position_type: "FLAT",
        position_uuid: "eb523b4e-46f3-45f4-9e29-3662501c1324",
        return_at_close: 1.0002351233052063,
        trade_pair: ["GBPUSD", "GBP/USD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 1.2579500000000001,
        close_ms: 1715704298705,
        current_return: 1.0002206075253004,
        initial_entry_price: 1.25789,
        is_closed_position: true,
        miner_hotkey: "5DoCFr2EoW1CGuYCEXhsuQdWRsgiUMuxGwNt4Xqb5TCptcBW",
        net_leverage: 0.0,
        open_ms: 1715695592601,
        orders: [
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "6d81656a-3bfd-4ee0-abf3-d57db5324112",
            price: 1.25789,
            price_sources: [],
            processed_ms: 1715695591874,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "6ca9e542-d95e-4bd0-a843-6828d9d850cb",
            price: 1.25832,
            price_sources: [],
            processed_ms: 1715695985630,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "b0a863e4-1eba-4c36-a0cd-94a1d391deaa",
            price: 1.25764,
            price_sources: [],
            processed_ms: 1715696093005,
          },
          {
            leverage: 0.5,
            order_type: "FLAT",
            order_uuid: "2bb4fdcd-dbdf-4ff0-914e-8a4174dcf869",
            price: 1.25832,
            price_sources: [],
            processed_ms: 1715704298705,
          },
        ],
        position_type: "FLAT",
        position_uuid: "8233abec-cfbe-43cd-a38f-4206424200fa",
        return_at_close: 1.0001680959434054,
        trade_pair: ["GBPUSD", "GBP/USD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 39410.545,
        close_ms: 1715710712200,
        current_return: 1.000772550167869,
        initial_entry_price: 39411.68,
        is_closed_position: true,
        miner_hotkey: "5DoCFr2EoW1CGuYCEXhsuQdWRsgiUMuxGwNt4Xqb5TCptcBW",
        net_leverage: 0.0,
        open_ms: 1715708787833,
        orders: [
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "f90fbd1d-c62b-4c61-85e8-6ac9da7c41c5",
            price: 39411.68,
            price_sources: [],
            processed_ms: 1715708786388,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "15660ca6-6abb-4d55-9b60-6e97a81ee43b",
            price: 39409.41,
            price_sources: [],
            processed_ms: 1715709317197,
          },
          {
            leverage: 0.25,
            order_type: "FLAT",
            order_uuid: "e84203a2-890f-48fa-ae10-1db379fc043b",
            price: 39471.44,
            price_sources: [],
            processed_ms: 1715710712200,
          },
        ],
        position_type: "FLAT",
        position_uuid: "fd55fc9c-e07b-4360-bd2e-73ad6bd557e9",
        return_at_close: 1.0007275154031114,
        trade_pair: ["DJI", "DJI", 9e-5, 0.001, 500],
      },
      {
        average_entry_price: 5225.040000000001,
        close_ms: 1715710715302,
        current_return: 1.000902366439241,
        initial_entry_price: 5225.150000000001,
        is_closed_position: true,
        miner_hotkey: "5DoCFr2EoW1CGuYCEXhsuQdWRsgiUMuxGwNt4Xqb5TCptcBW",
        net_leverage: 0.0,
        open_ms: 1715708863960,
        orders: [
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "c5bec381-8349-4bb7-9dd2-7264ba7e30b7",
            price: 5225.150000000001,
            price_sources: [],
            processed_ms: 1715708863156,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "45f0f871-7b83-4d39-b430-8983b4b8ba39",
            price: 5224.93,
            price_sources: [],
            processed_ms: 1715709313167,
          },
          {
            leverage: 0.25,
            order_type: "FLAT",
            order_uuid: "a6071de5-88e2-4dab-b907-c5dd55bcae13",
            price: 5234.47,
            price_sources: [],
            processed_ms: 1715710715302,
          },
        ],
        position_type: "FLAT",
        position_uuid: "94dd3fef-69b2-44ee-8be3-890609fe3844",
        return_at_close: 1.000857325832751,
        trade_pair: ["SPX", "SPX", 9e-5, 0.001, 500],
      },
      {
        average_entry_price: 1.259,
        close_ms: 1715736400653,
        current_return: 0.9999741858617951,
        initial_entry_price: 1.259,
        is_closed_position: true,
        miner_hotkey: "5DoCFr2EoW1CGuYCEXhsuQdWRsgiUMuxGwNt4Xqb5TCptcBW",
        net_leverage: 0.0,
        open_ms: 1715723610599,
        orders: [
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "c7e58e41-7a0a-437b-a0ea-822f762b4278",
            price: 1.259,
            price_sources: [],
            processed_ms: 1715723609428,
          },
          {
            leverage: 0.25,
            order_type: "FLAT",
            order_uuid: "8513f1bc-51c9-4791-ad0b-8c0392c33be8",
            price: 1.25887,
            price_sources: [],
            processed_ms: 1715736400653,
          },
        ],
        position_type: "FLAT",
        position_uuid: "5cfe8a1c-94af-44b7-929e-f7e5db3b70c2",
        return_at_close: 0.9999566863135425,
        trade_pair: ["GBPUSD", "GBP/USD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 1.0822569999999998,
        close_ms: 1715774835361,
        current_return: 0.9971283221823825,
        initial_entry_price: 1.08212,
        is_closed_position: true,
        miner_hotkey: "5DoCFr2EoW1CGuYCEXhsuQdWRsgiUMuxGwNt4Xqb5TCptcBW",
        net_leverage: 0.0,
        open_ms: 1715749275952,
        orders: [
          {
            leverage: -1.5,
            order_type: "SHORT",
            order_uuid: "19deeb1a-294c-4fce-8a11-2803f47f5900",
            price: 1.08212,
            price_sources: [],
            processed_ms: 1715749274828,
          },
          {
            leverage: -0.5,
            order_type: "SHORT",
            order_uuid: "692f21ca-3fb8-4b52-956a-9e5fc2f55337",
            price: 1.0824,
            price_sources: [],
            processed_ms: 1715771433589,
          },
          {
            leverage: -0.25,
            order_type: "SHORT",
            order_uuid: "25b57276-15ed-40e2-abb9-3dce9955a650",
            price: 1.0825,
            price_sources: [],
            processed_ms: 1715772060236,
          },
          {
            leverage: -0.25,
            order_type: "SHORT",
            order_uuid: "91e46a16-c456-4ac5-967b-0e496d38059a",
            price: 1.08255,
            price_sources: [],
            processed_ms: 1715772647882,
          },
          {
            leverage: 0.25,
            order_type: "FLAT",
            order_uuid: "a030430f-6e74-4214-939f-e33d3b0ce1a8",
            price: 1.0835,
            price_sources: [],
            processed_ms: 1715774835361,
          },
        ],
        position_type: "FLAT",
        position_uuid: "e10043cf-2288-4bf7-aa55-e006c4ed329a",
        return_at_close: 0.9969538247260005,
        trade_pair: ["EURUSD", "EUR/USD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.66376,
        close_ms: 1715774879262,
        current_return: 0.9995103651922382,
        initial_entry_price: 0.66376,
        is_closed_position: true,
        miner_hotkey: "5DoCFr2EoW1CGuYCEXhsuQdWRsgiUMuxGwNt4Xqb5TCptcBW",
        net_leverage: 0.0,
        open_ms: 1715771026906,
        orders: [
          {
            leverage: -0.5,
            order_type: "SHORT",
            order_uuid: "6b58bde9-2fe8-4c07-bd40-9c6a9c35a203",
            price: 0.66376,
            price_sources: [],
            processed_ms: 1715771026026,
          },
          {
            leverage: 0.25,
            order_type: "FLAT",
            order_uuid: "5270407c-07cb-4924-ac0f-66cdc4048cd6",
            price: 0.66441,
            price_sources: [],
            processed_ms: 1715774879262,
          },
        ],
        position_type: "FLAT",
        position_uuid: "e535bec5-5fd7-400b-be60-37632af0946a",
        return_at_close: 0.9994753823294565,
        trade_pair: ["AUDUSD", "AUD/USD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 103.49025,
        close_ms: 1715774970720,
        current_return: 0.9955905222792122,
        initial_entry_price: 103.527,
        is_closed_position: true,
        miner_hotkey: "5DoCFr2EoW1CGuYCEXhsuQdWRsgiUMuxGwNt4Xqb5TCptcBW",
        net_leverage: 0.0,
        open_ms: 1715762075656,
        orders: [
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "904866ce-6854-47ed-9ab0-9c8773313925",
            price: 103.527,
            price_sources: [],
            processed_ms: 1715762074527,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "0a4d590a-4c60-427e-81e6-b6bb92352535",
            price: 103.358,
            price_sources: [],
            processed_ms: 1715772687029,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "007f9382-af6c-4488-af74-d76986f0f0d2",
            price: 103.402,
            price_sources: [],
            processed_ms: 1715773001765,
          },
          {
            leverage: 0.25,
            order_type: "FLAT",
            order_uuid: "083b9937-98cc-46cd-a285-6a720c20b6a4",
            price: 103.262,
            price_sources: [],
            processed_ms: 1715774970720,
          },
        ],
        position_type: "FLAT",
        position_uuid: "d10c67bf-e830-4d0b-93bd-e63fe9b690e8",
        return_at_close: 0.9954511396060931,
        trade_pair: ["AUDJPY", "AUD/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 1.08632,
        close_ms: 1715776768453,
        current_return: 1.001334781648133,
        initial_entry_price: 1.08632,
        is_closed_position: true,
        miner_hotkey: "5DoCFr2EoW1CGuYCEXhsuQdWRsgiUMuxGwNt4Xqb5TCptcBW",
        net_leverage: 0.0,
        open_ms: 1715776263535,
        orders: [
          {
            leverage: -1.0,
            order_type: "SHORT",
            order_uuid: "b48c2e1a-948c-43c4-bc80-dd776cbf903e",
            price: 1.08632,
            price_sources: [],
            processed_ms: 1715776260848,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "4d6b4e17-3a41-49a3-bac7-4a58d2f95442",
            price: 1.08487,
            price_sources: [],
            processed_ms: 1715776768453,
          },
        ],
        position_type: "FLAT",
        position_uuid: "af04fd8e-f46b-4b5c-bf90-f87a5248bec7",
        return_at_close: 1.0012646882134177,
        trade_pair: ["EURUSD", "EUR/USD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.903505,
        close_ms: 1715781486056,
        current_return: 1.0056121319459816,
        initial_entry_price: 0.9034,
        is_closed_position: true,
        miner_hotkey: "5DoCFr2EoW1CGuYCEXhsuQdWRsgiUMuxGwNt4Xqb5TCptcBW",
        net_leverage: 0.0,
        open_ms: 1715776976587,
        orders: [
          {
            leverage: 1.0,
            order_type: "LONG",
            order_uuid: "c0e09c0d-1ec1-4241-b5e1-cc5c8d6db644",
            price: 0.9034,
            price_sources: [],
            processed_ms: 1715776975547,
          },
          {
            leverage: 1.0,
            order_type: "LONG",
            order_uuid: "cb27bdd2-0c29-45b0-a8bf-6d5f1deaa8e2",
            price: 0.90361,
            price_sources: [],
            processed_ms: 1715778003480,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "7be46cee-c1ac-4193-b1cb-c784f84abfb6",
            price: 0.90604,
            price_sources: [],
            processed_ms: 1715781486056,
          },
        ],
        position_type: "FLAT",
        position_uuid: "c79826e6-23d9-47b4-b018-50d2fbac6101",
        return_at_close: 1.0054713462475091,
        trade_pair: ["USDCHF", "USD/CHF", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 1.095326067753206,
        close_ms: 1715781517408,
        current_return: 1.0002182153290589,
        initial_entry_price: 1.095326067753206,
        is_closed_position: true,
        miner_hotkey: "5DoCFr2EoW1CGuYCEXhsuQdWRsgiUMuxGwNt4Xqb5TCptcBW",
        net_leverage: 0.0,
        open_ms: 1715778614394,
        orders: [
          {
            leverage: -0.25,
            order_type: "SHORT",
            order_uuid: "d35ad617-2034-4347-a426-6a9d2d252cd7",
            price: 1.095326067753206,
            price_sources: [],
            processed_ms: 1715778613929,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "5040b310-160f-46d3-aaa8-a3b5e4b8da0e",
            price: 1.09437,
            price_sources: [],
            processed_ms: 1715781517408,
          },
        ],
        position_type: "FLAT",
        position_uuid: "7554e5b5-0d68-41b6-94ae-3f7381930f0d",
        return_at_close: 1.0002007115102907,
        trade_pair: ["AUDNZD", "AUD/NZD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 1.0833,
        close_ms: 1715782124299,
        current_return: 0.9992430536324194,
        initial_entry_price: 1.0833,
        is_closed_position: true,
        miner_hotkey: "5DoCFr2EoW1CGuYCEXhsuQdWRsgiUMuxGwNt4Xqb5TCptcBW",
        net_leverage: 0.0,
        open_ms: 1715781306421,
        orders: [
          {
            leverage: -1.0,
            order_type: "SHORT",
            order_uuid: "f7fd20bb-81ef-480a-8d0b-f34b1976bad2",
            price: 1.0833,
            price_sources: [],
            processed_ms: 1715781305700,
          },
          {
            leverage: 1.5,
            order_type: "FLAT",
            order_uuid: "31d2e1a3-1cd0-4fd5-9f37-4e71ddadc8f9",
            price: 1.08412,
            price_sources: [],
            processed_ms: 1715782124299,
          },
        ],
        position_type: "FLAT",
        position_uuid: "b0e86ca0-e4fe-4e45-86ba-6bd6a4568da8",
        return_at_close: 0.9991731066186651,
        trade_pair: ["EURUSD", "EUR/USD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 103.284,
        close_ms: 1715782316881,
        current_return: 1.0013215986987336,
        initial_entry_price: 103.284,
        is_closed_position: true,
        miner_hotkey: "5DoCFr2EoW1CGuYCEXhsuQdWRsgiUMuxGwNt4Xqb5TCptcBW",
        net_leverage: 0.0,
        open_ms: 1715781714632,
        orders: [
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "8bd6b2ca-f325-4dc8-afe5-693b7feb1f17",
            price: 103.284,
            price_sources: [],
            processed_ms: 1715781714222,
          },
          {
            leverage: 2.3,
            order_type: "FLAT",
            order_uuid: "aa83c887-a47b-4bbb-8a11-30d6dc649346",
            price: 103.375,
            price_sources: [],
            processed_ms: 1715782316881,
          },
        ],
        position_type: "FLAT",
        position_uuid: "b6a8483f-81b3-482f-bacc-9d57132b1f69",
        return_at_close: 1.0012164599308702,
        trade_pair: ["AUDJPY", "AUD/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 64484.19000000001,
        close_ms: 1715783255166,
        current_return: 1.000630642952947,
        initial_entry_price: 64484.19,
        is_closed_position: true,
        miner_hotkey: "5DoCFr2EoW1CGuYCEXhsuQdWRsgiUMuxGwNt4Xqb5TCptcBW",
        net_leverage: 0.0,
        open_ms: 1715780008629,
        orders: [
          {
            leverage: -0.15,
            order_type: "SHORT",
            order_uuid: "4aed4eb8-c8d5-4187-883a-331f1d004785",
            price: 64484.19,
            price_sources: [],
            processed_ms: 1715780008269,
          },
          {
            leverage: 2.3,
            order_type: "FLAT",
            order_uuid: "a7d288f1-e883-4031-8c4c-fd333e5fa24c",
            price: 64213.08,
            price_sources: [],
            processed_ms: 1715783255166,
          },
        ],
        position_type: "FLAT",
        position_uuid: "c9c950cc-9817-4db3-b342-23f5f27ada42",
        return_at_close: 1.000480548356504,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 39742.73,
        close_ms: 1715784642957,
        current_return: 0.9996099915632368,
        initial_entry_price: 39742.73,
        is_closed_position: true,
        miner_hotkey: "5DoCFr2EoW1CGuYCEXhsuQdWRsgiUMuxGwNt4Xqb5TCptcBW",
        net_leverage: 0.0,
        open_ms: 1715780926716,
        orders: [
          {
            leverage: -1.0,
            order_type: "SHORT",
            order_uuid: "1d7aced0-e88b-4a7f-a9c6-bd356585fcf6",
            price: 39742.73,
            price_sources: [],
            processed_ms: 1715780925950,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "42a4c9e0-1553-4972-99fa-ed3601db1f6e",
            price: 39758.23,
            price_sources: [],
            processed_ms: 1715784642957,
          },
        ],
        position_type: "FLAT",
        position_uuid: "3e8326a6-ada4-4453-9e82-92f70a81325a",
        return_at_close: 0.9995200266639961,
        trade_pair: ["DJI", "DJI", 9e-5, 0.001, 500],
      },
      {
        average_entry_price: 1.36476,
        close_ms: 1715785831596,
        current_return: 1.002022333597116,
        initial_entry_price: 1.36476,
        is_closed_position: true,
        miner_hotkey: "5DoCFr2EoW1CGuYCEXhsuQdWRsgiUMuxGwNt4Xqb5TCptcBW",
        net_leverage: 0.0,
        open_ms: 1715783305985,
        orders: [
          {
            leverage: -1.0,
            order_type: "SHORT",
            order_uuid: "dc7147e9-d342-407b-9e29-141406e1bf5c",
            price: 1.36476,
            price_sources: [],
            processed_ms: 1715783304245,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "021c3005-9ecf-4e23-955f-3a43f2c72570",
            price: 1.362,
            price_sources: [],
            processed_ms: 1715785831596,
          },
        ],
        position_type: "FLAT",
        position_uuid: "64006652-d825-4329-aa15-ccefed173d00",
        return_at_close: 1.0019521920337642,
        trade_pair: ["USDCAD", "USD/CAD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 1.78375,
        close_ms: 1715785908728,
        current_return: 1.0006054660126138,
        initial_entry_price: 1.78375,
        is_closed_position: true,
        miner_hotkey: "5DoCFr2EoW1CGuYCEXhsuQdWRsgiUMuxGwNt4Xqb5TCptcBW",
        net_leverage: 0.0,
        open_ms: 1715777125380,
        orders: [
          {
            leverage: -1.0,
            order_type: "SHORT",
            order_uuid: "b3b499d1-f92b-437c-8fba-6fbd31a667df",
            price: 1.78375,
            price_sources: [],
            processed_ms: 1715777124269,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "3946c32a-c0fe-4d2e-a8fe-34fa84b2e2b7",
            price: 1.78267,
            price_sources: [],
            processed_ms: 1715785908728,
          },
        ],
        position_type: "FLAT",
        position_uuid: "b6c66655-2373-4ae4-8250-1a8e5970a6a3",
        return_at_close: 1.0005354236299928,
        trade_pair: ["EURNZD", "EUR/NZD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 196.379,
        close_ms: 1715785920012,
        current_return: 1.0000330992621411,
        initial_entry_price: 196.379,
        is_closed_position: true,
        miner_hotkey: "5DoCFr2EoW1CGuYCEXhsuQdWRsgiUMuxGwNt4Xqb5TCptcBW",
        net_leverage: 0.0,
        open_ms: 1715770445922,
        orders: [
          {
            leverage: 0.5,
            order_type: "LONG",
            order_uuid: "71fab110-afd3-4d0d-a77c-4a8ca4d177c9",
            price: 196.379,
            price_sources: [],
            processed_ms: 1715770445544,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "11933996-7e7b-45ab-b8d9-dc2e63f0ba49",
            price: 196.392,
            price_sources: [],
            processed_ms: 1715785920012,
          },
        ],
        position_type: "FLAT",
        position_uuid: "a238aa66-00e4-428f-8403-eb644ee4932c",
        return_at_close: 0.9999980981036669,
        trade_pair: ["GBPJPY", "GBP/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 17.009961428571415,
        close_ms: 1715785949009,
        current_return: 0.980270556466471,
        initial_entry_price: 16.87559,
        is_closed_position: true,
        miner_hotkey: "5DoCFr2EoW1CGuYCEXhsuQdWRsgiUMuxGwNt4Xqb5TCptcBW",
        net_leverage: 0.0,
        open_ms: 1715776092326,
        orders: [
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "92edc074-7695-4752-8afb-5fb647939da4",
            price: 16.87559,
            price_sources: [],
            processed_ms: 1715776091757,
          },
          {
            leverage: 0.75,
            order_type: "LONG",
            order_uuid: "eec3b575-7596-4e7f-9a47-9adf50e3ce38",
            price: 16.87502,
            price_sources: [],
            processed_ms: 1715776135250,
          },
          {
            leverage: 1.0,
            order_type: "LONG",
            order_uuid: "68a14157-ae83-44da-8e9c-4475cc38c592",
            price: 16.83862,
            price_sources: [],
            processed_ms: 1715776654618,
          },
          {
            leverage: -0.5,
            order_type: "SHORT",
            order_uuid: "ac7a9943-9dd4-4c9b-82ff-80778986b6ce",
            price: 16.82815,
            price_sources: [],
            processed_ms: 1715777671636,
          },
          {
            leverage: 1.0,
            order_type: "LONG",
            order_uuid: "5c0b9a10-7c00-4afe-b3df-88551e15c442",
            price: 16.82725,
            price_sources: [],
            processed_ms: 1715777695930,
          },
          {
            leverage: -2.25,
            order_type: "SHORT",
            order_uuid: "b9e8b3cd-8b33-4607-b034-5d56186430fd",
            price: 16.80197,
            price_sources: [],
            processed_ms: 1715778732496,
          },
          {
            leverage: 2.0,
            order_type: "LONG",
            order_uuid: "bc574843-8777-4114-af04-621e63833eab",
            price: 16.79679,
            price_sources: [],
            processed_ms: 1715778974962,
          },
          {
            leverage: 1.0,
            order_type: "LONG",
            order_uuid: "833990fd-70c4-4ffa-a664-d374d0603a6a",
            price: 16.79399,
            price_sources: [],
            processed_ms: 1715779168168,
          },
          {
            leverage: -3.15,
            order_type: "SHORT",
            order_uuid: "3f46d926-0263-4c5d-bbf5-77db45f8b1e2",
            price: 16.7622,
            price_sources: [],
            processed_ms: 1715779649337,
          },
          {
            leverage: 1.0,
            order_type: "LONG",
            order_uuid: "3f1b1a61-48dd-4656-9303-71e3e2475196",
            price: 16.77655,
            price_sources: [],
            processed_ms: 1715780290677,
          },
          {
            leverage: 1.0,
            order_type: "LONG",
            order_uuid: "31e4a08a-e038-440a-80b5-0aedabe20b3f",
            price: 16.7776,
            price_sources: [],
            processed_ms: 1715780808807,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "99564395-4e60-4803-9bcd-a9de8a426d4b",
            price: 16.78345,
            price_sources: [],
            processed_ms: 1715781618571,
          },
          {
            leverage: -2.3,
            order_type: "SHORT",
            order_uuid: "5572a6da-b1e0-4d89-8d4b-11dd8a2ad6d6",
            price: 16.75816,
            price_sources: [],
            processed_ms: 1715782154222,
          },
          {
            leverage: 1.0,
            order_type: "LONG",
            order_uuid: "354fdca6-8391-431a-9c40-1b2d3fe4b377",
            price: 16.74505,
            price_sources: [],
            processed_ms: 1715784007124,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "f840d180-fd60-4ca0-8c13-c6cb834557c0",
            price: 16.69287,
            price_sources: [],
            processed_ms: 1715785949009,
          },
        ],
        position_type: "FLAT",
        position_uuid: "620c6355-a000-4914-aaab-b7736a6407fa",
        return_at_close: 0.9796358312811589,
        trade_pair: ["USDMXN", "USD/MXN", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 16.696200714285716,
        close_ms: 1715799433848,
        current_return: 0.9978157043685901,
        initial_entry_price: 16.6667,
        is_closed_position: true,
        miner_hotkey: "5DoCFr2EoW1CGuYCEXhsuQdWRsgiUMuxGwNt4Xqb5TCptcBW",
        net_leverage: 0.0,
        open_ms: 1715796574550,
        orders: [
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "6ae14f8e-80c1-47cb-8fb0-f5341cb5bedd",
            price: 16.6667,
            price_sources: [],
            processed_ms: 1715796573171,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "d948b69b-36a8-479e-a7ac-d7a55e42e198",
            price: 16.6752,
            price_sources: [],
            processed_ms: 1715797153351,
          },
          {
            leverage: 0.5,
            order_type: "LONG",
            order_uuid: "eaff0d17-771b-4b67-85e6-4c4bc8235dd6",
            price: 16.69026,
            price_sources: [],
            processed_ms: 1715797608252,
          },
          {
            leverage: 1.0,
            order_type: "LONG",
            order_uuid: "e11c5447-34e5-42b2-892f-76fd38acab22",
            price: 16.6853,
            price_sources: [],
            processed_ms: 1715798009117,
          },
          {
            leverage: 1.0,
            order_type: "LONG",
            order_uuid: "6bb7fe57-dc30-412e-8540-de8f0ce82261",
            price: 16.70104,
            price_sources: [],
            processed_ms: 1715798484584,
          },
          {
            leverage: 2.0,
            order_type: "LONG",
            order_uuid: "8c2aee1c-1243-4651-9760-b9b21ad99487",
            price: 16.70363,
            price_sources: [],
            processed_ms: 1715799021835,
          },
          {
            leverage: 2.0,
            order_type: "LONG",
            order_uuid: "e353287a-e1e2-4b4b-923c-1cf56672cfc3",
            price: 16.6996,
            price_sources: [],
            processed_ms: 1715799141860,
          },
          {
            leverage: 2.0,
            order_type: "FLAT",
            order_uuid: "a0a5204e-acbe-40b3-af04-38fe4c52c227",
            price: 16.691,
            price_sources: [],
            processed_ms: 1715799433848,
          },
        ],
        position_type: "FLAT",
        position_uuid: "96a62200-15c2-42a5-98d8-a9a20702f1c2",
        return_at_close: 0.9973267746734495,
        trade_pair: ["USDMXN", "USD/MXN", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 196.1482,
        close_ms: 1715799859213,
        current_return: 1.0014391111926904,
        initial_entry_price: 196.128,
        is_closed_position: true,
        miner_hotkey: "5DoCFr2EoW1CGuYCEXhsuQdWRsgiUMuxGwNt4Xqb5TCptcBW",
        net_leverage: 0.0,
        open_ms: 1715787025070,
        orders: [
          {
            leverage: 1.0,
            order_type: "LONG",
            order_uuid: "49094e8e-2b98-4555-a2ad-94359313861f",
            price: 196.128,
            price_sources: [],
            processed_ms: 1715787024022,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "7b6a0b09-eff5-4e09-9b23-dd3320c292c6",
            price: 196.229,
            price_sources: [],
            processed_ms: 1715788072778,
          },
          {
            leverage: 2.0,
            order_type: "FLAT",
            order_uuid: "efd03721-06d1-42c5-9627-3112fab001fa",
            price: 196.374,
            price_sources: [],
            processed_ms: 1715799859213,
          },
        ],
        position_type: "FLAT",
        position_uuid: "579af030-7e3e-4cd8-b070-e93e276b93f6",
        return_at_close: 1.001351485270461,
        trade_pair: ["GBPJPY", "GBP/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 1.0882600000000002,
        close_ms: 1715848737793,
        current_return: 1.000985617791665,
        initial_entry_price: 1.08815,
        is_closed_position: true,
        miner_hotkey: "5DoCFr2EoW1CGuYCEXhsuQdWRsgiUMuxGwNt4Xqb5TCptcBW",
        net_leverage: 0.0,
        open_ms: 1715836877919,
        orders: [
          {
            leverage: -1.5,
            order_type: "SHORT",
            order_uuid: "9e1d5d6f-60d7-4e74-985e-09f92f9b7b18",
            price: 1.08815,
            price_sources: [],
            processed_ms: 1715836874851,
          },
          {
            leverage: 0.75,
            order_type: "LONG",
            order_uuid: "65da3488-2daf-4068-a4c4-83cb681988aa",
            price: 1.08804,
            price_sources: [],
            processed_ms: 1715844513372,
          },
          {
            leverage: 1.5,
            order_type: "FLAT",
            order_uuid: "24d70004-d72a-4ad1-b0ca-8a6966f55ff6",
            price: 1.08683,
            price_sources: [],
            processed_ms: 1715848737793,
          },
        ],
        position_type: "FLAT",
        position_uuid: "60cb57bd-fe21-4b36-8657-032e4cf93968",
        return_at_close: 1.0008805143017967,
        trade_pair: ["EURUSD", "EUR/USD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 1.08693,
        close_ms: 1715863094390,
        current_return: 0.9993467840615311,
        initial_entry_price: 1.08693,
        is_closed_position: true,
        miner_hotkey: "5DoCFr2EoW1CGuYCEXhsuQdWRsgiUMuxGwNt4Xqb5TCptcBW",
        net_leverage: 0.0,
        open_ms: 1715862388156,
        orders: [
          {
            leverage: 1.0,
            order_type: "LONG",
            order_uuid: "38b68cfc-f820-4ba4-a658-b551194f581c",
            price: 1.08693,
            price_sources: [],
            processed_ms: 1715862387684,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "25907f9c-9b66-4186-8a78-846af14a0aed",
            price: 1.08622,
            price_sources: [],
            processed_ms: 1715863094390,
          },
        ],
        position_type: "FLAT",
        position_uuid: "a77e2d95-026b-4d0e-955a-97ee3cfb96ba",
        return_at_close: 0.9992768297866468,
        trade_pair: ["EURUSD", "EUR/USD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 171.5898079296592,
        close_ms: 1715863701098,
        current_return: 0.9993514293951725,
        initial_entry_price: 171.5898079296592,
        is_closed_position: true,
        miner_hotkey: "5DoCFr2EoW1CGuYCEXhsuQdWRsgiUMuxGwNt4Xqb5TCptcBW",
        net_leverage: 0.0,
        open_ms: 1715848189963,
        orders: [
          {
            leverage: -1.5,
            order_type: "SHORT",
            order_uuid: "4e27d9d4-2746-4cc2-b104-9122426f2316",
            price: 171.5898079296592,
            price_sources: [],
            processed_ms: 1715848189187,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "078c02dc-af5e-40ae-810b-361885cfec95",
            price: 171.664,
            price_sources: [],
            processed_ms: 1715863701098,
          },
        ],
        position_type: "FLAT",
        position_uuid: "0aeed99d-8ae1-44a9-9c5c-2af8682b31fc",
        return_at_close: 0.999246497495086,
        trade_pair: ["CHFJPY", "CHF/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 1.08612,
        close_ms: 1715873226403,
        current_return: 1.0008286377195892,
        initial_entry_price: 1.08612,
        is_closed_position: true,
        miner_hotkey: "5DoCFr2EoW1CGuYCEXhsuQdWRsgiUMuxGwNt4Xqb5TCptcBW",
        net_leverage: 0.0,
        open_ms: 1715869708235,
        orders: [
          {
            leverage: 1.0,
            order_type: "LONG",
            order_uuid: "5d2ed162-efe2-4fd3-8eb5-fe291323900c",
            price: 1.08612,
            price_sources: [],
            processed_ms: 1715869707505,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "1c7eed7c-d8b7-4230-ab72-ed3e8cc66b21",
            price: 1.08702,
            price_sources: [],
            processed_ms: 1715873226403,
          },
        ],
        position_type: "FLAT",
        position_uuid: "fb1695bc-bd74-4964-832a-24fa930cec60",
        return_at_close: 1.0007585797149487,
        trade_pair: ["EURUSD", "EUR/USD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 1.26602,
        close_ms: 1715892458630,
        current_return: 1.000710889243456,
        initial_entry_price: 1.26602,
        is_closed_position: true,
        miner_hotkey: "5DoCFr2EoW1CGuYCEXhsuQdWRsgiUMuxGwNt4Xqb5TCptcBW",
        net_leverage: 0.0,
        open_ms: 1715863606824,
        orders: [
          {
            leverage: 1.0,
            order_type: "LONG",
            order_uuid: "625681ff-f767-41e7-8734-7d2392dbfd8b",
            price: 1.26602,
            price_sources: [],
            processed_ms: 1715863606034,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "e6011c42-a7fe-4b85-a4d1-0c757a948955",
            price: 1.26692,
            price_sources: [],
            processed_ms: 1715892458630,
          },
        ],
        position_type: "FLAT",
        position_uuid: "0db4dd5a-ad5f-4235-a9f9-a6fbc7100019",
        return_at_close: 1.000640839481209,
        trade_pair: ["GBPUSD", "GBP/USD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 1.08501,
        close_ms: 1715945560597,
        current_return: 1.0011612796195426,
        initial_entry_price: 1.08501,
        is_closed_position: true,
        miner_hotkey: "5DoCFr2EoW1CGuYCEXhsuQdWRsgiUMuxGwNt4Xqb5TCptcBW",
        net_leverage: 0.0,
        open_ms: 1715934544654,
        orders: [
          {
            leverage: -1.5,
            order_type: "SHORT",
            order_uuid: "a30049a3-c6fc-4c20-802f-0c0c30cacfe5",
            price: 1.08501,
            price_sources: [],
            processed_ms: 1715934544223,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "9c02740d-a5f1-420d-a877-5e952178900b",
            price: 1.08417,
            price_sources: [],
            processed_ms: 1715945560597,
          },
        ],
        position_type: "FLAT",
        position_uuid: "9a2bf978-f154-4858-a7c1-e4a8d2dd805f",
        return_at_close: 1.0010561576851826,
        trade_pair: ["EURUSD", "EUR/USD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 39901.54,
        close_ms: 1715973888001,
        current_return: 0.9974313021502428,
        initial_entry_price: 39901.54,
        is_closed_position: true,
        miner_hotkey: "5DoCFr2EoW1CGuYCEXhsuQdWRsgiUMuxGwNt4Xqb5TCptcBW",
        net_leverage: 0.0,
        open_ms: 1715952694527,
        orders: [
          {
            leverage: -1.5,
            order_type: "SHORT",
            order_uuid: "1aa27439-4a20-4aef-bd87-4e674225def4",
            price: 39901.54,
            price_sources: [],
            processed_ms: 1715952692578,
          },
          {
            leverage: 1.5,
            order_type: "FLAT",
            order_uuid: "e10f81ea-a06a-48e6-a9d0-2dc3ce48ed00",
            price: 39969.87,
            price_sources: [],
            processed_ms: 1715973888001,
          },
        ],
        position_type: "FLAT",
        position_uuid: "3c1af73b-c59a-44ce-b652-b0068fe3c823",
        return_at_close: 0.9972966489244525,
        trade_pair: ["DJI", "DJI", 9e-5, 0.001, 500],
      },
      {
        average_entry_price: 1.088045,
        close_ms: 1716240102141,
        current_return: 1.0021568573346944,
        initial_entry_price: 1.08723,
        is_closed_position: true,
        miner_hotkey: "5DoCFr2EoW1CGuYCEXhsuQdWRsgiUMuxGwNt4Xqb5TCptcBW",
        net_leverage: 0.0,
        open_ms: 1716190244851,
        orders: [
          {
            leverage: -1.5,
            order_type: "SHORT",
            order_uuid: "63030fcb-3ce5-487a-a174-02ada8a83e25",
            price: 1.08723,
            price_sources: [],
            processed_ms: 1716190244435,
          },
          {
            leverage: 0.5,
            order_type: "LONG",
            order_uuid: "434053de-208c-4212-a0bf-4f2002c4bfcf",
            price: 1.0856,
            price_sources: [],
            processed_ms: 1716208246007,
          },
          {
            leverage: 0.5,
            order_type: "FLAT",
            order_uuid: "0365f941-ab02-45b0-a3f2-7e085b43b98c",
            price: 1.0857,
            price_sources: [],
            processed_ms: 1716240102141,
          },
        ],
        position_type: "FLAT",
        position_uuid: "112e0405-d797-4ea6-8f56-e22e6f578159",
        return_at_close: 1.0020516308646743,
        trade_pair: ["EURUSD", "EUR/USD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.8325475815378592,
        close_ms: 1716292906594,
        current_return: 0.9963917697380019,
        initial_entry_price: 0.832863442050479,
        is_closed_position: true,
        miner_hotkey: "5DoCFr2EoW1CGuYCEXhsuQdWRsgiUMuxGwNt4Xqb5TCptcBW",
        net_leverage: 0.0,
        open_ms: 1716204647676,
        orders: [
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "12e47d39-e39b-4b60-a27d-451b3b9503de",
            price: 0.832863442050479,
            price_sources: [],
            processed_ms: 1716204647472,
          },
          {
            leverage: 0.5,
            order_type: "LONG",
            order_uuid: "a64f7ce4-5591-473b-b752-4295f1b6bf6d",
            price: 0.8316,
            price_sources: [],
            processed_ms: 1716228755921,
          },
          {
            leverage: 1.5,
            order_type: "FLAT",
            order_uuid: "f653530a-ba17-46e4-a6c2-b3c07f05e1d0",
            price: 0.831045,
            price_sources: [],
            processed_ms: 1716292906594,
          },
        ],
        position_type: "FLAT",
        position_uuid: "dc8c5c05-1563-4c2e-9bf4-836b6d856aa2",
        return_at_close: 0.9962522748902386,
        trade_pair: ["NZDCAD", "NZD/CAD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 1.08667,
        close_ms: 1716305280794,
        current_return: 1.0027316624511382,
        initial_entry_price: 1.08725,
        is_closed_position: true,
        miner_hotkey: "5DoCFr2EoW1CGuYCEXhsuQdWRsgiUMuxGwNt4Xqb5TCptcBW",
        net_leverage: 0.0,
        open_ms: 1716285656213,
        orders: [
          {
            leverage: -1.5,
            order_type: "SHORT",
            order_uuid: "c2b0268c-5b6e-491e-a6a6-addd3057462c",
            price: 1.08725,
            price_sources: [],
            processed_ms: 1716285655855,
          },
          {
            leverage: -0.75,
            order_type: "SHORT",
            order_uuid: "814fdf08-d43d-4131-89ab-5f24b47b246f",
            price: 1.08551,
            price_sources: [],
            processed_ms: 1716297170848,
          },
          {
            leverage: 0.75,
            order_type: "FLAT",
            order_uuid: "0335c7a4-e269-4b15-964e-45828df7ab63",
            price: 1.08535,
            price_sources: [],
            processed_ms: 1716305280794,
          },
        ],
        position_type: "FLAT",
        position_uuid: "362c1241-ffb5-4255-a272-57582233308d",
        return_at_close: 1.002573732214302,
        trade_pair: ["EURUSD", "EUR/USD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 198.745,
        close_ms: 1716325530172,
        current_return: 0.9974716345065284,
        initial_entry_price: 198.745,
        is_closed_position: true,
        miner_hotkey: "5DoCFr2EoW1CGuYCEXhsuQdWRsgiUMuxGwNt4Xqb5TCptcBW",
        net_leverage: 0.0,
        open_ms: 1716291350668,
        orders: [
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "95cbd173-afef-46de-9fa6-111b857163b5",
            price: 198.745,
            price_sources: [],
            processed_ms: 1716291350472,
          },
          {
            leverage: 0.75,
            order_type: "FLAT",
            order_uuid: "cc7ae478-4bed-49f3-b7a0-071737867c6c",
            price: 198.41,
            price_sources: [],
            processed_ms: 1716325530172,
          },
        ],
        position_type: "FLAT",
        position_uuid: "575f4504-8a41-44ac-a633-cc7f63297bb4",
        return_at_close: 0.9973668999849052,
        trade_pair: ["GBPJPY", "GBP/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.8557,
        close_ms: 1716365430242,
        current_return: 0.993356316466051,
        initial_entry_price: 0.8557,
        is_closed_position: true,
        miner_hotkey: "5DoCFr2EoW1CGuYCEXhsuQdWRsgiUMuxGwNt4Xqb5TCptcBW",
        net_leverage: 0.0,
        open_ms: 1715960016530,
        orders: [
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "f4a9c8f1-2d13-4c16-9a75-627b1954c6eb",
            price: 0.8557,
            price_sources: [],
            processed_ms: 1715960014825,
          },
          {
            leverage: -3.0,
            order_type: "SHORT",
            order_uuid: "0bf938bd-4bf8-4335-ab67-967ac71d33ac",
            price: 0.85191,
            price_sources: [],
            processed_ms: 1716365430242,
          },
        ],
        position_type: "FLAT",
        position_uuid: "879d86fc-d1ab-4448-a606-2b0cd13257c1",
        return_at_close: 0.993252014052822,
        trade_pair: ["EURGBP", "EUR/GBP", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.8505400000000005,
        close_ms: 1716464811375,
        current_return: 1.001349939546185,
        initial_entry_price: 0.85189,
        is_closed_position: true,
        miner_hotkey: "5DoCFr2EoW1CGuYCEXhsuQdWRsgiUMuxGwNt4Xqb5TCptcBW",
        net_leverage: 0.0,
        open_ms: 1716365488494,
        orders: [
          {
            leverage: 6.0,
            order_type: "LONG",
            order_uuid: "1935397f-ec6a-4372-8f86-16f9645c9bfd",
            price: 0.85189,
            price_sources: [],
            processed_ms: 1716365488276,
          },
          {
            leverage: -5.0,
            order_type: "SHORT",
            order_uuid: "0352d09a-037e-4eb5-8719-ed215b55fca6",
            price: 0.85216,
            price_sources: [],
            processed_ms: 1716463079954,
          },
          {
            leverage: 5.0,
            order_type: "FLAT",
            order_uuid: "3d0490d3-b222-4d8e-8721-318a64b144ba",
            price: 0.85169,
            price_sources: [],
            processed_ms: 1716464811375,
          },
        ],
        position_type: "FLAT",
        position_uuid: "32e87384-1708-4e44-9748-d9abf2d114ed",
        return_at_close: 1.0009293725715755,
        trade_pair: ["EURGBP", "EUR/GBP", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 1.08312,
        close_ms: 1716476912543,
        current_return: 1.0001061747544133,
        initial_entry_price: 1.08312,
        is_closed_position: true,
        miner_hotkey: "5DoCFr2EoW1CGuYCEXhsuQdWRsgiUMuxGwNt4Xqb5TCptcBW",
        net_leverage: 0.0,
        open_ms: 1716473974499,
        orders: [
          {
            leverage: -0.5,
            order_type: "SHORT",
            order_uuid: "5b9e4f31-586c-434c-a1a1-0c04a46a4bf5",
            price: 1.08312,
            price_sources: [],
            processed_ms: 1716473974466,
          },
          {
            leverage: 0.5,
            order_type: "FLAT",
            order_uuid: "8e7bec0f-ea07-4bd3-ba0f-ad6a738c604a",
            price: 1.08289,
            price_sources: [],
            processed_ms: 1716476912543,
          },
        ],
        position_type: "FLAT",
        position_uuid: "09579946-b0a6-457d-8acc-6fac4c91c541",
        return_at_close: 1.000071171038297,
        trade_pair: ["EURUSD", "EUR/USD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.8511799999999999,
        close_ms: 1716555279581,
        current_return: 1.0033306703634954,
        initial_entry_price: 0.85118,
        is_closed_position: true,
        miner_hotkey: "5DoCFr2EoW1CGuYCEXhsuQdWRsgiUMuxGwNt4Xqb5TCptcBW",
        net_leverage: 0.0,
        open_ms: 1716483241792,
        orders: [
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "f4c37a64-1785-42d5-a254-2bf21519edda",
            price: 0.85118,
            price_sources: [],
            processed_ms: 1716483241738,
          },
          {
            leverage: 1.5,
            order_type: "FLAT",
            order_uuid: "c061a87c-0e62-4333-b822-a2e87cd3a30c",
            price: 0.85307,
            price_sources: [],
            processed_ms: 1716555279581,
          },
        ],
        position_type: "FLAT",
        position_uuid: "4a9f023c-bda6-4b80-b8fe-427bd474847b",
        return_at_close: 1.0032253206431072,
        trade_pair: ["EURGBP", "EUR/GBP", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 39412.32,
        close_ms: 1716561647710,
        current_return: 0.9874458037486755,
        initial_entry_price: 39412.32,
        is_closed_position: true,
        miner_hotkey: "5DoCFr2EoW1CGuYCEXhsuQdWRsgiUMuxGwNt4Xqb5TCptcBW",
        net_leverage: 0.0,
        open_ms: 1716476947616,
        orders: [
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "4a2e65b6-988a-4f03-b08d-b188115719e4",
            price: 39412.32,
            price_sources: [],
            processed_ms: 1716476947329,
          },
          {
            leverage: 0.75,
            order_type: "FLAT",
            order_uuid: "5a1f6887-9cfd-423c-a6dd-883278aa96bd",
            price: 39082.46,
            price_sources: [],
            processed_ms: 1716561647710,
          },
        ],
        position_type: "FLAT",
        position_uuid: "0ce879be-e761-4994-89c8-bba7b83163d4",
        return_at_close: 0.9873124985651694,
        trade_pair: ["DJI", "DJI", 9e-5, 0.001, 500],
      },
      {
        average_entry_price: 1.08772,
        close_ms: 1716896173931,
        current_return: 0.9989243555326739,
        initial_entry_price: 1.08772,
        is_closed_position: true,
        miner_hotkey: "5DoCFr2EoW1CGuYCEXhsuQdWRsgiUMuxGwNt4Xqb5TCptcBW",
        net_leverage: 0.0,
        open_ms: 1716877672839,
        orders: [
          {
            leverage: -1.5,
            order_type: "SHORT",
            order_uuid: "457cd452-51f9-426f-9b3a-74d9ab18ab18",
            price: 1.08772,
            price_sources: [],
            processed_ms: 1716877672770,
          },
          {
            leverage: 0.2,
            order_type: "FLAT",
            order_uuid: "9fa6ce3b-35f6-4098-98aa-2320d9ff8956",
            price: 1.0885,
            price_sources: [],
            processed_ms: 1716896173931,
          },
        ],
        position_type: "FLAT",
        position_uuid: "c7ba2ec5-d38d-4b2b-8a6c-e8a2a33c535a",
        return_at_close: 0.998819468475343,
        trade_pair: ["EURUSD", "EUR/USD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 1.088165,
        close_ms: 1716899990344,
        current_return: 1.0007960649350054,
        initial_entry_price: 1.088165,
        is_closed_position: true,
        miner_hotkey: "5DoCFr2EoW1CGuYCEXhsuQdWRsgiUMuxGwNt4Xqb5TCptcBW",
        net_leverage: 0.0,
        open_ms: 1716898463357,
        orders: [
          {
            leverage: -1.75,
            order_type: "SHORT",
            order_uuid: "6d9e8bf7-dcd2-47f1-a2aa-de10d178fa9d",
            price: 1.088165,
            price_sources: [],
            processed_ms: 1716898463341,
          },
          {
            leverage: 1.75,
            order_type: "FLAT",
            order_uuid: "2e1b8729-e264-47bd-8dd6-323ded19e21f",
            price: 1.08767,
            price_sources: [],
            processed_ms: 1716899990344,
          },
        ],
        position_type: "FLAT",
        position_uuid: "fd86ca1d-2fc8-4ce0-92b9-0572666f4843",
        return_at_close: 1.0006734674170508,
        trade_pair: ["EURUSD", "EUR/USD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.849745,
        close_ms: 1716912157056,
        current_return: 1.0027287055115148,
        initial_entry_price: 0.85022,
        is_closed_position: true,
        miner_hotkey: "5DoCFr2EoW1CGuYCEXhsuQdWRsgiUMuxGwNt4Xqb5TCptcBW",
        net_leverage: 0.0,
        open_ms: 1716900520924,
        orders: [
          {
            leverage: 1.75,
            order_type: "LONG",
            order_uuid: "33af35ce-d6ea-40e9-9823-08631c59922b",
            price: 0.85022,
            price_sources: [],
            processed_ms: 1716900516379,
          },
          {
            leverage: 0.75,
            order_type: "LONG",
            order_uuid: "4c77f10d-f3b5-4a01-9002-7c02cce50f4b",
            price: 0.85027,
            price_sources: [],
            processed_ms: 1716901499244,
          },
          {
            leverage: 0.5,
            order_type: "LONG",
            order_uuid: "e4f6cff3-426c-4a14-9907-f3fa7b4d90d1",
            price: 0.8497049999999999,
            price_sources: [],
            processed_ms: 1716902779874,
          },
          {
            leverage: -1.0,
            order_type: "SHORT",
            order_uuid: "515a4565-9546-4da6-9fa2-4c4d4b4b2b31",
            price: 0.85095,
            price_sources: [],
            processed_ms: 1716908337794,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "74e00885-9181-4370-904d-b6b0239da573",
            price: 0.850905,
            price_sources: [],
            processed_ms: 1716912157056,
          },
        ],
        position_type: "FLAT",
        position_uuid: "c1c8b56d-e33c-4178-8689-b1c4234abfe3",
        return_at_close: 1.0025181324833574,
        trade_pair: ["EURGBP", "EUR/GBP", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.8510774999999999,
        close_ms: 1716919226714,
        current_return: 1.0000146868758077,
        initial_entry_price: 0.8511,
        is_closed_position: true,
        miner_hotkey: "5DoCFr2EoW1CGuYCEXhsuQdWRsgiUMuxGwNt4Xqb5TCptcBW",
        net_leverage: 0.0,
        open_ms: 1716915460008,
        orders: [
          {
            leverage: 0.5,
            order_type: "LONG",
            order_uuid: "13b59d69-ce5b-43eb-96aa-431e89df5c90",
            price: 0.8511,
            price_sources: [],
            processed_ms: 1716915459353,
          },
          {
            leverage: 0.5,
            order_type: "LONG",
            order_uuid: "e582a03d-ffad-40aa-a762-d6e5c6172524",
            price: 0.851055,
            price_sources: [],
            processed_ms: 1716916225924,
          },
          {
            leverage: 0.5,
            order_type: "FLAT",
            order_uuid: "512d2b0c-30a0-4f85-b98e-0b12106eaa57",
            price: 0.8510899999999999,
            price_sources: [],
            processed_ms: 1716919226714,
          },
        ],
        position_type: "FLAT",
        position_uuid: "7c1c994b-8d66-414c-abe0-092e42b7122b",
        return_at_close: 0.9999446858477264,
        trade_pair: ["EURGBP", "EUR/GBP", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 1.3706950000000002,
        close_ms: 1716996610424,
        current_return: 1.0007769412588645,
        initial_entry_price: 1.37076,
        is_closed_position: true,
        miner_hotkey: "5DoCFr2EoW1CGuYCEXhsuQdWRsgiUMuxGwNt4Xqb5TCptcBW",
        net_leverage: 0.0,
        open_ms: 1716994589125,
        orders: [
          {
            leverage: -1.5,
            order_type: "SHORT",
            order_uuid: "8bf013b1-d16f-4fa2-b38e-cf3e8ac50b70",
            price: 1.37076,
            price_sources: [],
            processed_ms: 1716994585376,
          },
          {
            leverage: -1.5,
            order_type: "SHORT",
            order_uuid: "7259045f-95dd-41da-b92a-d4a7cf45aa4e",
            price: 1.37063,
            price_sources: [],
            processed_ms: 1716994776627,
          },
          {
            leverage: 0.5,
            order_type: "FLAT",
            order_uuid: "701ae375-1bd1-40c8-a004-60c2e4ddfb58",
            price: 1.37034,
            price_sources: [],
            processed_ms: 1716996610424,
          },
        ],
        position_type: "FLAT",
        position_uuid: "1bbf37bf-e1d9-4fd2-8d5a-0e938920b0f6",
        return_at_close: 1.0005667781012002,
        trade_pair: ["USDCAD", "USD/CAD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 1.0796,
        close_ms: 1717061682543,
        current_return: 1.0025703964431272,
        initial_entry_price: 1.0796,
        is_closed_position: true,
        miner_hotkey: "5DoCFr2EoW1CGuYCEXhsuQdWRsgiUMuxGwNt4Xqb5TCptcBW",
        net_leverage: 0.0,
        open_ms: 1717052847148,
        orders: [
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "b36039ab-a62a-4936-a3d2-d58a68a640aa",
            price: 1.0796,
            price_sources: [],
            processed_ms: 1717052847130,
          },
          {
            leverage: 1.5,
            order_type: "FLAT",
            order_uuid: "9c407de2-68bf-4377-814f-015dcef699aa",
            price: 1.08145,
            price_sources: [],
            processed_ms: 1717061682543,
          },
        ],
        position_type: "FLAT",
        position_uuid: "bcbc3e02-3b0e-4705-854f-2e9d0dbd520b",
        return_at_close: 1.0024651265515006,
        trade_pair: ["EURUSD", "EUR/USD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.99167375,
        close_ms: 1717063559616,
        current_return: 1.0126210900880015,
        initial_entry_price: 0.99203,
        is_closed_position: true,
        miner_hotkey: "5DoCFr2EoW1CGuYCEXhsuQdWRsgiUMuxGwNt4Xqb5TCptcBW",
        net_leverage: 0.0,
        open_ms: 1716559067694,
        orders: [
          {
            leverage: -0.25,
            order_type: "SHORT",
            order_uuid: "9be76003-32d0-457f-8f9f-d421957e6fde",
            price: 0.99203,
            price_sources: [],
            processed_ms: 1716559067679,
          },
          {
            leverage: -0.75,
            order_type: "SHORT",
            order_uuid: "5c333598-acff-4140-999d-4f3f48402a84",
            price: 0.99142,
            price_sources: [],
            processed_ms: 1716559764262,
          },
          {
            leverage: -0.2,
            order_type: "SHORT",
            order_uuid: "76b50941-311f-4e1b-a237-d1c15bbd1e21",
            price: 0.9921800000000001,
            price_sources: [],
            processed_ms: 1716573612399,
          },
          {
            leverage: 0.5,
            order_type: "FLAT",
            order_uuid: "74cf4fb3-6017-4d68-b90c-102658355f8f",
            price: 0.98124,
            price_sources: [],
            processed_ms: 1717063559616,
          },
        ],
        position_type: "FLAT",
        position_uuid: "b052cb66-4eae-4256-9807-686ac9a2c2f3",
        return_at_close: 1.012536029916434,
        trade_pair: ["EURCHF", "EUR/CHF", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 1.3708583333333333,
        close_ms: 1717072507635,
        current_return: 1.0025788989888673,
        initial_entry_price: 1.37074,
        is_closed_position: true,
        miner_hotkey: "5DoCFr2EoW1CGuYCEXhsuQdWRsgiUMuxGwNt4Xqb5TCptcBW",
        net_leverage: 0.0,
        open_ms: 1716996938805,
        orders: [
          {
            leverage: -0.5,
            order_type: "SHORT",
            order_uuid: "030197aa-43fa-4257-96d4-1682ed0b4b6d",
            price: 1.37074,
            price_sources: [],
            processed_ms: 1716996937818,
          },
          {
            leverage: -1.5,
            order_type: "SHORT",
            order_uuid: "b8413055-6cfc-4cc6-a87a-838a98fee889",
            price: 1.3706,
            price_sources: [],
            processed_ms: 1716996991102,
          },
          {
            leverage: -0.5,
            order_type: "SHORT",
            order_uuid: "91cf4858-030f-4437-b26a-4e6f84fff078",
            price: 1.37134,
            price_sources: [],
            processed_ms: 1717002521727,
          },
          {
            leverage: -0.5,
            order_type: "SHORT",
            order_uuid: "c64a3831-731b-4deb-9e70-0d2453e23f05",
            price: 1.37127,
            price_sources: [],
            processed_ms: 1717008050185,
          },
          {
            leverage: 49.0,
            order_type: "FLAT",
            order_uuid: "50314544-7ed0-47d5-8ab5-e58503186ab6",
            price: 1.36968,
            price_sources: [],
            processed_ms: 1717072507635,
          },
        ],
        position_type: "FLAT",
        position_uuid: "ad4a0d39-75e1-43b9-b35a-ee3617df930f",
        return_at_close: 1.0023683574200797,
        trade_pair: ["USDCAD", "USD/CAD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 162.41050000000178,
        close_ms: 1717072660089,
        current_return: 1.0365401785714399,
        initial_entry_price: 156.8,
        is_closed_position: true,
        miner_hotkey: "5DoCFr2EoW1CGuYCEXhsuQdWRsgiUMuxGwNt4Xqb5TCptcBW",
        net_leverage: 0.0,
        open_ms: 1717072186001,
        orders: [
          {
            leverage: -50.0,
            order_type: "SHORT",
            order_uuid: "00809587-c681-480e-afaf-90718b61f3d8",
            price: 156.8,
            price_sources: [],
            processed_ms: 1717072185981,
          },
          {
            leverage: 49.0,
            order_type: "LONG",
            order_uuid: "667de647-61c8-4cff-9598-601c0d756deb",
            price: 156.6855,
            price_sources: [],
            processed_ms: 1717072405641,
          },
          {
            leverage: 49.0,
            order_type: "FLAT",
            order_uuid: "a3b3ac3b-a7c8-4403-942e-0576664b5fa7",
            price: 156.681,
            price_sources: [],
            processed_ms: 1717072660089,
          },
        ],
        position_type: "FLAT",
        position_uuid: "afa7d247-ef82-4c6e-91e4-ef68506cc06b",
        return_at_close: 1.0329122879464399,
        trade_pair: ["USDJPY", "USD/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 69130.29,
        close_ms: 1717417975020,
        current_return: 1.0006524853287901,
        initial_entry_price: 69130.29,
        is_closed_position: true,
        miner_hotkey: "5DoCFr2EoW1CGuYCEXhsuQdWRsgiUMuxGwNt4Xqb5TCptcBW",
        net_leverage: 0.0,
        open_ms: 1717416684754,
        orders: [
          {
            leverage: 0.15,
            order_type: "LONG",
            order_uuid: "a54b9848-6dd4-48b0-9a8a-2b1e3fad8c7d",
            price: 69130.29,
            price_sources: [],
            processed_ms: 1717416684543,
          },
          {
            leverage: 0.15,
            order_type: "FLAT",
            order_uuid: "69e297d2-4468-420c-b2a5-1a265fbe80e1",
            price: 69431.0,
            price_sources: [],
            processed_ms: 1717417975020,
          },
        ],
        position_type: "FLAT",
        position_uuid: "7841ca60-e317-488e-8ce9-3a9aeb1454c6",
        return_at_close: 1.0005023874559908,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 0.8506100000000001,
        close_ms: 1717436771958,
        current_return: 1.0018570538663156,
        initial_entry_price: 0.85081,
        is_closed_position: true,
        miner_hotkey: "5DoCFr2EoW1CGuYCEXhsuQdWRsgiUMuxGwNt4Xqb5TCptcBW",
        net_leverage: 0.0,
        open_ms: 1716983849167,
        orders: [
          {
            leverage: 0.5,
            order_type: "LONG",
            order_uuid: "f74cf688-26ae-463c-93f1-9bb5d4202704",
            price: 0.85081,
            price_sources: [],
            processed_ms: 1716983848977,
          },
          {
            leverage: 0.5,
            order_type: "LONG",
            order_uuid: "09ede7be-aa3b-4986-a18b-6c4915dd3996",
            price: 0.85061,
            price_sources: [],
            processed_ms: 1716994518207,
          },
          {
            leverage: 1.0,
            order_type: "LONG",
            order_uuid: "88a2806f-ba8e-4579-ad31-20672454a6ef",
            price: 0.8505100000000001,
            price_sources: [],
            processed_ms: 1717064907387,
          },
          {
            leverage: 1.15,
            order_type: "FLAT",
            order_uuid: "405ba9de-2882-4306-8a3f-7a30e4c3e4ee",
            price: 0.8514,
            price_sources: [],
            processed_ms: 1717436771958,
          },
        ],
        position_type: "FLAT",
        position_uuid: "13a4dd63-dab7-4917-978f-74e4e1193bba",
        return_at_close: 1.0017167938787743,
        trade_pair: ["EURGBP", "EUR/GBP", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 1.08864,
        close_ms: 1717496153810,
        current_return: 1.0020070914168135,
        initial_entry_price: 1.08864,
        is_closed_position: true,
        miner_hotkey: "5DoCFr2EoW1CGuYCEXhsuQdWRsgiUMuxGwNt4Xqb5TCptcBW",
        net_leverage: 0.0,
        open_ms: 1717435529322,
        orders: [
          {
            leverage: -1.15,
            order_type: "SHORT",
            order_uuid: "23260544-578d-4d19-a4ed-ddbbf28eccbf",
            price: 1.08864,
            price_sources: [],
            processed_ms: 1717435529292,
          },
          {
            leverage: 0.35,
            order_type: "FLAT",
            order_uuid: "61c8213d-a6b0-4543-a559-938e58e16e4b",
            price: 1.08674,
            price_sources: [],
            processed_ms: 1717496153810,
          },
        ],
        position_type: "FLAT",
        position_uuid: "629df4e6-1aba-4179-8ef0-d051b6d01910",
        return_at_close: 1.0019264298459545,
        trade_pair: ["EURUSD", "EUR/USD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 1.28083,
        close_ms: 1717496184863,
        current_return: 1.001489268677342,
        initial_entry_price: 1.28083,
        is_closed_position: true,
        miner_hotkey: "5DoCFr2EoW1CGuYCEXhsuQdWRsgiUMuxGwNt4Xqb5TCptcBW",
        net_leverage: 0.0,
        open_ms: 1717464114829,
        orders: [
          {
            leverage: -0.35,
            order_type: "SHORT",
            order_uuid: "d9d44f78-7019-4222-9628-cb61ca339710",
            price: 1.28083,
            price_sources: [],
            processed_ms: 1717464114754,
          },
          {
            leverage: 0.35,
            order_type: "FLAT",
            order_uuid: "708d3815-0273-42b6-9a19-df5a055e113b",
            price: 1.27538,
            price_sources: [],
            processed_ms: 1717496184863,
          },
        ],
        position_type: "FLAT",
        position_uuid: "5e7e785f-a64d-4f62-98a6-0995163a87b7",
        return_at_close: 1.0014647321902594,
        trade_pair: ["GBPUSD", "GBP/USD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 168.2815,
        close_ms: 1717501419965,
        current_return: 1.0002665177099086,
        initial_entry_price: 168.2815,
        is_closed_position: true,
        miner_hotkey: "5DoCFr2EoW1CGuYCEXhsuQdWRsgiUMuxGwNt4Xqb5TCptcBW",
        net_leverage: 0.0,
        open_ms: 1717498604813,
        orders: [
          {
            leverage: 0.15,
            order_type: "LONG",
            order_uuid: "cc549bb1-ef6a-405f-8966-dcfcdc7ea35e",
            price: 168.2815,
            price_sources: [],
            processed_ms: 1717498604712,
          },
          {
            leverage: 0.15,
            order_type: "FLAT",
            order_uuid: "ffc9c4f1-2b07-4a67-87af-bdb53197a1b0",
            price: 168.5805,
            price_sources: [],
            processed_ms: 1717501419965,
          },
        ],
        position_type: "FLAT",
        position_uuid: "fcc3a8b9-8af3-437d-a4cb-262d6016f177",
        return_at_close: 1.0002560149114725,
        trade_pair: ["EURJPY", "EUR/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 69132.03,
        close_ms: 1717507715857,
        current_return: 1.0004792568654501,
        initial_entry_price: 69132.03,
        is_closed_position: true,
        miner_hotkey: "5DoCFr2EoW1CGuYCEXhsuQdWRsgiUMuxGwNt4Xqb5TCptcBW",
        net_leverage: 0.0,
        open_ms: 1717427455078,
        orders: [
          {
            leverage: 0.15,
            order_type: "LONG",
            order_uuid: "ebf78f73-2f30-4dd3-8b0d-f6c1592511cb",
            price: 69132.03,
            price_sources: [],
            processed_ms: 1717427454525,
          },
          {
            leverage: 0.05,
            order_type: "FLAT",
            order_uuid: "d5f1bcc8-f0e6-45b4-871a-3d9f0dfe5310",
            price: 69352.91,
            price_sources: [],
            processed_ms: 1717507715857,
          },
        ],
        position_type: "FLAT",
        position_uuid: "5ed538d0-eeae-41ca-bc02-fa47e8315728",
        return_at_close: 1.0003291849769202,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 102.824,
        close_ms: 1717603482494,
        current_return: 1.0013056290360227,
        initial_entry_price: 102.824,
        is_closed_position: true,
        miner_hotkey: "5DoCFr2EoW1CGuYCEXhsuQdWRsgiUMuxGwNt4Xqb5TCptcBW",
        net_leverage: 0.0,
        open_ms: 1717521755607,
        orders: [
          {
            leverage: 0.15,
            order_type: "LONG",
            order_uuid: "988f60bf-46f1-4ed9-b6a6-ad76c55ef017",
            price: 102.824,
            price_sources: [],
            processed_ms: 1717521755579,
          },
          {
            leverage: 0.15,
            order_type: "FLAT",
            order_uuid: "cfc101fc-02ca-475c-b76a-96d756041c3a",
            price: 103.719,
            price_sources: [],
            processed_ms: 1717603482494,
          },
        ],
        position_type: "FLAT",
        position_uuid: "0200c0f9-5fb0-4a7d-865d-ebb3c3470ea9",
        return_at_close: 1.0012951153269178,
        trade_pair: ["AUDJPY", "AUD/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 168.477,
        close_ms: 1717603517770,
        current_return: 1.0010412400505706,
        initial_entry_price: 168.477,
        is_closed_position: true,
        miner_hotkey: "5DoCFr2EoW1CGuYCEXhsuQdWRsgiUMuxGwNt4Xqb5TCptcBW",
        net_leverage: 0.0,
        open_ms: 1717507969385,
        orders: [
          {
            leverage: 0.15,
            order_type: "LONG",
            order_uuid: "221677e1-228b-4b95-8f49-eb6f8ffd17d0",
            price: 168.477,
            price_sources: [],
            processed_ms: 1717507969353,
          },
          {
            leverage: 0.15,
            order_type: "FLAT",
            order_uuid: "8c8dc681-ed00-4c5c-9453-1b27eb71c041",
            price: 169.6465,
            price_sources: [],
            processed_ms: 1717603517770,
          },
        ],
        position_type: "FLAT",
        position_uuid: "91ab5c53-abc9-47a9-9908-8c54c8d22ad5",
        return_at_close: 1.00103072911755,
        trade_pair: ["EURJPY", "EUR/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 113.29750000000003,
        close_ms: 1717603553758,
        current_return: 1.0008678479225048,
        initial_entry_price: 113.29750000000001,
        is_closed_position: true,
        miner_hotkey: "5DoCFr2EoW1CGuYCEXhsuQdWRsgiUMuxGwNt4Xqb5TCptcBW",
        net_leverage: 0.0,
        open_ms: 1717510283882,
        orders: [
          {
            leverage: 0.15,
            order_type: "LONG",
            order_uuid: "ae4ce55b-49dd-40ab-98a5-a246c52fe2d5",
            price: 113.29750000000001,
            price_sources: [],
            processed_ms: 1717510280867,
          },
          {
            leverage: 0.15,
            order_type: "FLAT",
            order_uuid: "5c114158-9c84-40a9-820f-cd8e4a23edde",
            price: 113.953,
            price_sources: [],
            processed_ms: 1717603553758,
          },
        ],
        position_type: "FLAT",
        position_uuid: "25e1f18a-13f1-45aa-9db0-8135fd6b6d1c",
        return_at_close: 1.0008573388101016,
        trade_pair: ["CADJPY", "CAD/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.85155,
        close_ms: 1717748559763,
        current_return: 1.0002466091245377,
        initial_entry_price: 0.85155,
        is_closed_position: true,
        miner_hotkey: "5DoCFr2EoW1CGuYCEXhsuQdWRsgiUMuxGwNt4Xqb5TCptcBW",
        net_leverage: 0.0,
        open_ms: 1717678098587,
        orders: [
          {
            leverage: -0.7,
            order_type: "SHORT",
            order_uuid: "538284ab-297f-4f28-906e-662164e58363",
            price: 0.85155,
            price_sources: [],
            processed_ms: 1717678092677,
          },
          {
            leverage: 0.3,
            order_type: "FLAT",
            order_uuid: "9c416e77-0471-4d0f-9a91-5f49c37f41f0",
            price: 0.85125,
            price_sources: [],
            processed_ms: 1717748559763,
          },
        ],
        position_type: "FLAT",
        position_uuid: "433103b5-653f-45a6-b4b3-c0e431cf592c",
        return_at_close: 1.0001975970406907,
        trade_pair: ["EURGBP", "EUR/GBP", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.911770588196079,
        close_ms: 1717763830601,
        current_return: 1.0011564251398108,
        initial_entry_price: 0.911770588196079,
        is_closed_position: true,
        miner_hotkey: "5DoCFr2EoW1CGuYCEXhsuQdWRsgiUMuxGwNt4Xqb5TCptcBW",
        net_leverage: 0.0,
        open_ms: 1717748383664,
        orders: [
          {
            leverage: -0.3,
            order_type: "SHORT",
            order_uuid: "2f014b21-0332-4f99-b13a-a0b450d5696a",
            price: 0.911770588196079,
            price_sources: [],
            processed_ms: 1717748383637,
          },
          {
            leverage: 0.03,
            order_type: "FLAT",
            order_uuid: "e8b2a4f3-bf70-4294-8f2f-cecf08c9a554",
            price: 0.908255940096312,
            price_sources: [],
            processed_ms: 1717763830601,
          },
        ],
        position_type: "FLAT",
        position_uuid: "ad5fa4f7-2ea6-4186-87b5-81703c797bbc",
        return_at_close: 1.001135400854883,
        trade_pair: ["AUDCAD", "AUD/CAD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 1.4888207692307696,
        close_ms: 1717763878640,
        current_return: 1.0007563584533983,
        initial_entry_price: 1.48739,
        is_closed_position: true,
        miner_hotkey: "5DoCFr2EoW1CGuYCEXhsuQdWRsgiUMuxGwNt4Xqb5TCptcBW",
        net_leverage: 0.0,
        open_ms: 1717463958624,
        orders: [
          {
            leverage: -0.35,
            order_type: "SHORT",
            order_uuid: "fab25741-2b71-437b-a16b-d0bca988d7a8",
            price: 1.48739,
            price_sources: [],
            processed_ms: 1717463958606,
          },
          {
            leverage: -0.3,
            order_type: "SHORT",
            order_uuid: "4042da86-788b-43e4-bb34-1ea896a8e266",
            price: 1.49049,
            price_sources: [],
            processed_ms: 1717679083300,
          },
          {
            leverage: 0.03,
            order_type: "FLAT",
            order_uuid: "a7298a74-4d97-4602-bdfa-cd6aa4b1ddae",
            price: 1.48709,
            price_sources: [],
            processed_ms: 1717763878640,
          },
        ],
        position_type: "FLAT",
        position_uuid: "decb03f2-f676-4253-8715-18d08fc480ce",
        return_at_close: 1.0007108240390885,
        trade_pair: ["EURCAD", "EUR/CAD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.6522445312499999,
        close_ms: 1717771090497,
        current_return: 1.000426886738236,
        initial_entry_price: 0.652515,
        is_closed_position: true,
        miner_hotkey: "5DoCFr2EoW1CGuYCEXhsuQdWRsgiUMuxGwNt4Xqb5TCptcBW",
        net_leverage: 0.0,
        open_ms: 1717496327008,
        orders: [
          {
            leverage: 0.35,
            order_type: "LONG",
            order_uuid: "9b7c758f-3dde-437f-8e2e-2553e9f094e0",
            price: 0.652515,
            price_sources: [],
            processed_ms: 1717496326990,
          },
          {
            leverage: 0.03,
            order_type: "LONG",
            order_uuid: "a8f299b5-43bd-4fd5-8844-272635d97981",
            price: 0.65012,
            price_sources: [],
            processed_ms: 1717759412128,
          },
          {
            leverage: -0.06,
            order_type: "SHORT",
            order_uuid: "cc8a4bbd-034d-4292-acda-9a5d54666f16",
            price: 0.65276,
            price_sources: [],
            processed_ms: 1717766660021,
          },
          {
            leverage: 0.06,
            order_type: "FLAT",
            order_uuid: "53be5de3-ef11-4c71-b4e8-e69476a2a1dc",
            price: 0.653115,
            price_sources: [],
            processed_ms: 1717771090497,
          },
        ],
        position_type: "FLAT",
        position_uuid: "568dd070-1fca-4c25-94bf-bb0a925d8d4a",
        return_at_close: 1.0004002753830488,
        trade_pair: ["CADCHF", "CAD/CHF", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 1.484925,
        close_ms: 1717775236454,
        current_return: 1.0000264659831304,
        initial_entry_price: 1.484925,
        is_closed_position: true,
        miner_hotkey: "5DoCFr2EoW1CGuYCEXhsuQdWRsgiUMuxGwNt4Xqb5TCptcBW",
        net_leverage: 0.0,
        open_ms: 1717765494613,
        orders: [
          {
            leverage: 0.03,
            order_type: "LONG",
            order_uuid: "ee115ec4-ea25-461e-8047-040d7981cac7",
            price: 1.484925,
            price_sources: [],
            processed_ms: 1717765489967,
          },
          {
            leverage: 0.06,
            order_type: "FLAT",
            order_uuid: "2ce2827e-bd22-4f3d-a307-e23b2f894b37",
            price: 1.4862350000000002,
            price_sources: [],
            processed_ms: 1717775236454,
          },
        ],
        position_type: "FLAT",
        position_uuid: "3ac6d47d-a511-4ce7-aed8-9d90c2e8ff8e",
        return_at_close: 1.0000243659275518,
        trade_pair: ["EURCAD", "EUR/CAD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.9058666933052666,
        close_ms: 1718047960886,
        current_return: 1.0008116343469147,
        initial_entry_price: 0.906500571791194,
        is_closed_position: true,
        miner_hotkey: "5DoCFr2EoW1CGuYCEXhsuQdWRsgiUMuxGwNt4Xqb5TCptcBW",
        net_leverage: 0.0,
        open_ms: 1717765757328,
        orders: [
          {
            leverage: 0.09,
            order_type: "LONG",
            order_uuid: "6011dfd5-83e0-4470-b531-d4e4f3dd3e7b",
            price: 0.906500571791194,
            price_sources: [],
            processed_ms: 1717765757242,
          },
          {
            leverage: 0.06,
            order_type: "LONG",
            order_uuid: "c973cdc4-4c66-4e3f-9410-8d2aa4370534",
            price: 0.905321531765606,
            price_sources: [],
            processed_ms: 1717769407213,
          },
          {
            leverage: 0.06,
            order_type: "LONG",
            order_uuid: "03cbbc19-0dcc-4523-aa9d-5dd41f3ab067",
            price: 0.9054610371160355,
            price_sources: [],
            processed_ms: 1717775168335,
          },
          {
            leverage: 0.05,
            order_type: "FLAT",
            order_uuid: "c6da7836-2fae-4e73-bf39-97fafdbd235b",
            price: 0.909370250446045,
            price_sources: [],
            processed_ms: 1718047960886,
          },
        ],
        position_type: "FLAT",
        position_uuid: "f51c60f3-5bc4-4730-af46-8beab92c4d9f",
        return_at_close: 1.0007969224158897,
        trade_pair: ["AUDCAD", "AUD/CAD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.8468537500000001,
        close_ms: 1718195466392,
        current_return: 0.999064097128995,
        initial_entry_price: 0.84918,
        is_closed_position: true,
        miner_hotkey: "5DoCFr2EoW1CGuYCEXhsuQdWRsgiUMuxGwNt4Xqb5TCptcBW",
        net_leverage: 0.0,
        open_ms: 1717780033072,
        orders: [
          {
            leverage: 0.1,
            order_type: "LONG",
            order_uuid: "09ee0afc-bf2c-4d45-98ae-fb19368a26a2",
            price: 0.84918,
            price_sources: [],
            processed_ms: 1717780033063,
          },
          {
            leverage: 0.05,
            order_type: "LONG",
            order_uuid: "6840e53b-4f31-443d-99a6-53364dccc642",
            price: 0.84682,
            price_sources: [],
            processed_ms: 1717978906073,
          },
          {
            leverage: 0.05,
            order_type: "LONG",
            order_uuid: "a565fac8-b005-43ae-92b2-74c090d782b7",
            price: 0.842235,
            price_sources: [],
            processed_ms: 1718104873203,
          },
          {
            leverage: 0.05,
            order_type: "FLAT",
            order_uuid: "34035f6b-827f-4778-9e04-4cf85f11689f",
            price: 0.84288,
            price_sources: [],
            processed_ms: 1718195466392,
          },
        ],
        position_type: "FLAT",
        position_uuid: "c12fa2d1-1edd-4bc3-894c-b584c177a664",
        return_at_close: 0.9990501102316353,
        trade_pair: ["EURGBP", "EUR/GBP", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.9674992647058822,
        close_ms: 1718195528357,
        current_return: 0.9984447386860111,
        initial_entry_price: 0.97048,
        is_closed_position: true,
        miner_hotkey: "5DoCFr2EoW1CGuYCEXhsuQdWRsgiUMuxGwNt4Xqb5TCptcBW",
        net_leverage: 0.0,
        open_ms: 1717507040559,
        orders: [
          {
            leverage: 0.05,
            order_type: "LONG",
            order_uuid: "8003c53d-332f-4484-b086-e6757663febd",
            price: 0.97048,
            price_sources: [],
            processed_ms: 1717507040541,
          },
          {
            leverage: 0.15,
            order_type: "LONG",
            order_uuid: "8a963a6f-de22-4175-815e-3cc0d6887ed2",
            price: 0.969445,
            price_sources: [],
            processed_ms: 1717510380102,
          },
          {
            leverage: -0.06,
            order_type: "SHORT",
            order_uuid: "a3a790ff-e9a5-4827-9964-a38dfa5a388e",
            price: 0.9702,
            price_sources: [],
            processed_ms: 1717766614921,
          },
          {
            leverage: 0.1,
            order_type: "LONG",
            order_uuid: "b1fbf32d-4988-472f-ba42-c94f641d013d",
            price: 0.968195,
            price_sources: [],
            processed_ms: 1717776087606,
          },
          {
            leverage: 0.05,
            order_type: "LONG",
            order_uuid: "be96ab83-16e0-4775-9e07-349944cdc84e",
            price: 0.96579,
            price_sources: [],
            processed_ms: 1717978862926,
          },
          {
            leverage: 0.05,
            order_type: "LONG",
            order_uuid: "e766a08d-c45f-4ba6-bb94-ed679544c41f",
            price: 0.96224,
            price_sources: [],
            processed_ms: 1718104762297,
          },
          {
            leverage: 0.05,
            order_type: "FLAT",
            order_uuid: "9ef1703f-7918-414b-aefc-e0d5cad50496",
            price: 0.96306,
            price_sources: [],
            processed_ms: 1718195528357,
          },
        ],
        position_type: "FLAT",
        position_uuid: "0c17a29e-ebcc-4071-be7d-9717c7627573",
        return_at_close: 0.9984167822333279,
        trade_pair: ["EURCHF", "EUR/CHF", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.6596150000000001,
        close_ms: 1718196042748,
        current_return: 1.0006976797071019,
        initial_entry_price: 0.6596150000000001,
        is_closed_position: true,
        miner_hotkey: "5DoCFr2EoW1CGuYCEXhsuQdWRsgiUMuxGwNt4Xqb5TCptcBW",
        net_leverage: 0.0,
        open_ms: 1717769356131,
        orders: [
          {
            leverage: 0.06,
            order_type: "LONG",
            order_uuid: "8e9da89f-dd31-435c-9e44-8fd797241307",
            price: 0.6596150000000001,
            price_sources: [],
            processed_ms: 1717769356122,
          },
          {
            leverage: 0.05,
            order_type: "FLAT",
            order_uuid: "8b96f3fc-3448-4090-b94c-74d444ccc58b",
            price: 0.667285,
            price_sources: [],
            processed_ms: 1718196042748,
          },
        ],
        position_type: "FLAT",
        position_uuid: "bd270eb3-96a7-4acc-a6d8-febba6c1a9da",
        return_at_close: 1.0006934767768472,
        trade_pair: ["AUDUSD", "AUD/USD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 69406.364,
        close_ms: 1718203437251,
        current_return: 1.001402383771167,
        initial_entry_price: 71290.4,
        is_closed_position: true,
        miner_hotkey: "5DoCFr2EoW1CGuYCEXhsuQdWRsgiUMuxGwNt4Xqb5TCptcBW",
        net_leverage: 0.0,
        open_ms: 1717618992543,
        orders: [
          {
            leverage: 0.1,
            order_type: "LONG",
            order_uuid: "5b28a357-fc5b-4a01-9f63-39c0f4188ad1",
            price: 71290.4,
            price_sources: [],
            processed_ms: 1717618992526,
          },
          {
            leverage: 0.1,
            order_type: "LONG",
            order_uuid: "9e2f3ad7-c1a2-46da-b213-1437a3c13b0a",
            price: 68707.97,
            price_sources: [],
            processed_ms: 1717784303038,
          },
          {
            leverage: 0.05,
            order_type: "LONG",
            order_uuid: "5a77a3aa-ec0c-4a7f-9cac-3370216c5546",
            price: 67035.08,
            price_sources: [],
            processed_ms: 1718102687344,
          },
          {
            leverage: 0.15,
            order_type: "FLAT",
            order_uuid: "fd80d0f2-9618-45fd-b515-9acfc8eeca9c",
            price: 69806.27,
            price_sources: [],
            processed_ms: 1718203437251,
          },
        ],
        position_type: "FLAT",
        position_uuid: "b76afc8f-7af8-4d11-bbe6-238f939a528f",
        return_at_close: 1.0011520331752242,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 1.08288,
        close_ms: 1718203514175,
        current_return: 1.0001302083333332,
        initial_entry_price: 1.08288,
        is_closed_position: true,
        miner_hotkey: "5DoCFr2EoW1CGuYCEXhsuQdWRsgiUMuxGwNt4Xqb5TCptcBW",
        net_leverage: 0.0,
        open_ms: 1717766160009,
        orders: [
          {
            leverage: 0.1,
            order_type: "LONG",
            order_uuid: "30a83327-e4d6-4f6a-9535-65afe2c3e306",
            price: 1.08288,
            price_sources: [],
            processed_ms: 1717766159998,
          },
          {
            leverage: 0.15,
            order_type: "FLAT",
            order_uuid: "5334a3a1-cfdc-4225-96f7-b305994b6523",
            price: 1.08429,
            price_sources: [],
            processed_ms: 1718203514175,
          },
        ],
        position_type: "FLAT",
        position_uuid: "9948725a-1018-46b1-a6bb-fa416730cf4a",
        return_at_close: 1.0001232074218749,
        trade_pair: ["EURUSD", "EUR/USD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.96306,
        close_ms: 1718203540785,
        current_return: 1.0005163229705314,
        initial_entry_price: 0.96306,
        is_closed_position: true,
        miner_hotkey: "5DoCFr2EoW1CGuYCEXhsuQdWRsgiUMuxGwNt4Xqb5TCptcBW",
        net_leverage: 0.0,
        open_ms: 1718196292561,
        orders: [
          {
            leverage: 0.15,
            order_type: "LONG",
            order_uuid: "2d889a14-9eea-4b2d-8144-65222c669603",
            price: 0.96306,
            price_sources: [],
            processed_ms: 1718196292313,
          },
          {
            leverage: 0.15,
            order_type: "FLAT",
            order_uuid: "4fcf001a-860e-42c2-9e25-f56e4e4f0d7e",
            price: 0.966375,
            price_sources: [],
            processed_ms: 1718203540785,
          },
        ],
        position_type: "FLAT",
        position_uuid: "8552122b-6904-47e6-82dc-dfff948c48d3",
        return_at_close: 1.0005058175491401,
        trade_pair: ["EURCHF", "EUR/CHF", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 1.47542,
        close_ms: 1718328102894,
        current_return: 1.000156735031381,
        initial_entry_price: 1.47542,
        is_closed_position: true,
        miner_hotkey: "5DoCFr2EoW1CGuYCEXhsuQdWRsgiUMuxGwNt4Xqb5TCptcBW",
        net_leverage: 0.0,
        open_ms: 1718315275267,
        orders: [
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "1a536838-2f15-4214-b18e-bc339cf4e8a6",
            price: 1.47542,
            price_sources: [],
            processed_ms: 1718315274734,
          },
          {
            leverage: 0.25,
            order_type: "FLAT",
            order_uuid: "7859c9d0-be8d-469b-852f-3467e162d501",
            price: 1.476345,
            price_sources: [],
            processed_ms: 1718328102894,
          },
        ],
        position_type: "FLAT",
        position_uuid: "720d79c0-2345-4508-bf48-6328b563fc28",
        return_at_close: 1.0001392322885179,
        trade_pair: ["EURCAD", "EUR/CAD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.61516,
        close_ms: 1718330631113,
        current_return: 1.000170687300865,
        initial_entry_price: 0.61516,
        is_closed_position: true,
        miner_hotkey: "5DoCFr2EoW1CGuYCEXhsuQdWRsgiUMuxGwNt4Xqb5TCptcBW",
        net_leverage: 0.0,
        open_ms: 1718328188211,
        orders: [
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "343179ee-b651-4649-9e6d-d99cd6aa88de",
            price: 0.61516,
            price_sources: [],
            processed_ms: 1718328187068,
          },
          {
            leverage: 0.25,
            order_type: "FLAT",
            order_uuid: "685a90ac-34af-4fcd-9316-8613a2ce9f2f",
            price: 0.61558,
            price_sources: [],
            processed_ms: 1718330631113,
          },
        ],
        position_type: "FLAT",
        position_uuid: "7a5fb097-02a0-4ffd-9f1d-a77096cc2e15",
        return_at_close: 1.0001531843138372,
        trade_pair: ["NZDUSD", "NZD/USD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.841375,
        close_ms: 1718330689195,
        current_return: 1.0000793344228198,
        initial_entry_price: 0.841375,
        is_closed_position: true,
        miner_hotkey: "5DoCFr2EoW1CGuYCEXhsuQdWRsgiUMuxGwNt4Xqb5TCptcBW",
        net_leverage: 0.0,
        open_ms: 1718315084684,
        orders: [
          {
            leverage: 0.15,
            order_type: "LONG",
            order_uuid: "b2165331-7d0c-46ba-a849-e962a2ad0d48",
            price: 0.841375,
            price_sources: [],
            processed_ms: 1718315083232,
          },
          {
            leverage: 0.25,
            order_type: "FLAT",
            order_uuid: "37f197ff-57a5-40d4-9de2-960f12d3fa1a",
            price: 0.84182,
            price_sources: [],
            processed_ms: 1718330689195,
          },
        ],
        position_type: "FLAT",
        position_uuid: "d4927bf7-7498-45a1-880b-adcb00dc885b",
        return_at_close: 1.0000688335898082,
        trade_pair: ["EURGBP", "EUR/GBP", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 96.4,
        close_ms: 1718368324324,
        current_return: 1.0000007780082987,
        initial_entry_price: 96.4,
        is_closed_position: true,
        miner_hotkey: "5DoCFr2EoW1CGuYCEXhsuQdWRsgiUMuxGwNt4Xqb5TCptcBW",
        net_leverage: 0.0,
        open_ms: 1718363914572,
        orders: [
          {
            leverage: -0.15,
            order_type: "SHORT",
            order_uuid: "8a647a42-706b-4649-9259-a61575b9bd76",
            price: 96.4,
            price_sources: [],
            processed_ms: 1718363914451,
          },
          {
            leverage: 0.15,
            order_type: "FLAT",
            order_uuid: "cffd7e9c-147a-44e5-9b33-7c9a0fbb644d",
            price: 96.3995,
            price_sources: [],
            processed_ms: 1718368324324,
          },
        ],
        position_type: "FLAT",
        position_uuid: "166c9369-2bf0-440f-b8be-ac9530eb059f",
        return_at_close: 0.9999902780001295,
        trade_pair: ["NZDJPY", "NZD/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 1.469195,
        close_ms: 1718623629019,
        current_return: 1.0003108845320057,
        initial_entry_price: 1.469195,
        is_closed_position: true,
        miner_hotkey: "5DoCFr2EoW1CGuYCEXhsuQdWRsgiUMuxGwNt4Xqb5TCptcBW",
        net_leverage: 0.0,
        open_ms: 1718385965944,
        orders: [
          {
            leverage: 0.15,
            order_type: "LONG",
            order_uuid: "020823ed-1975-4ca8-a817-7308739decb4",
            price: 1.469195,
            price_sources: [],
            processed_ms: 1718385965781,
          },
          {
            leverage: 0.15,
            order_type: "FLAT",
            order_uuid: "6e40efde-ac51-43f1-94ee-44c811655d28",
            price: 1.47224,
            price_sources: [],
            processed_ms: 1718623629019,
          },
        ],
        position_type: "FLAT",
        position_uuid: "327157ba-d572-47c2-9cf4-49c492f4f081",
        return_at_close: 1.000300381267718,
        trade_pair: ["EURCAD", "EUR/CAD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 3483.67,
        close_ms: 1718624208759,
        current_return: 1.0000572528396776,
        initial_entry_price: 3483.67,
        is_closed_position: true,
        miner_hotkey: "5DoCFr2EoW1CGuYCEXhsuQdWRsgiUMuxGwNt4Xqb5TCptcBW",
        net_leverage: 0.0,
        open_ms: 1718330956812,
        orders: [
          {
            leverage: 0.005,
            order_type: "LONG",
            order_uuid: "2feb51e7-9b59-481f-8d98-31963d535533",
            price: 3483.67,
            price_sources: [],
            processed_ms: 1718330955390,
          },
          {
            leverage: 0.15,
            order_type: "FLAT",
            order_uuid: "38895179-32ab-452d-b755-7a6e534b037f",
            price: 3523.56,
            price_sources: [],
            processed_ms: 1718624208759,
          },
        ],
        position_type: "FLAT",
        position_uuid: "9a4e7da1-ba88-49c8-9447-55bca2301894",
        return_at_close: 1.0000522525534135,
        trade_pair: ["ETHUSD", "ETH/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 169.43,
        close_ms: 1718715119617,
        current_return: 1.0000392492474768,
        initial_entry_price: 169.43,
        is_closed_position: true,
        miner_hotkey: "5DoCFr2EoW1CGuYCEXhsuQdWRsgiUMuxGwNt4Xqb5TCptcBW",
        net_leverage: 0.0,
        open_ms: 1718706483975,
        orders: [
          {
            leverage: -0.05,
            order_type: "SHORT",
            order_uuid: "1b13484a-80cd-4a36-bc2b-54b0f06d7e4c",
            price: 169.43,
            price_sources: [],
            processed_ms: 1718706483748,
          },
          {
            leverage: 0.05,
            order_type: "FLAT",
            order_uuid: "041311cf-b49f-4497-9d67-b5376bf3b054",
            price: 169.297,
            price_sources: [],
            processed_ms: 1718715119617,
          },
        ],
        position_type: "FLAT",
        position_uuid: "5a1fc158-4fba-45e8-8743-3e7dea40d040",
        return_at_close: 1.0000357491101044,
        trade_pair: ["EURJPY", "EUR/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 3403.11,
        close_ms: 1718741469565,
        current_return: 1.0013306945705547,
        initial_entry_price: 3403.11,
        is_closed_position: true,
        miner_hotkey: "5DoCFr2EoW1CGuYCEXhsuQdWRsgiUMuxGwNt4Xqb5TCptcBW",
        net_leverage: 0.0,
        open_ms: 1718715241867,
        orders: [
          {
            leverage: 0.15,
            order_type: "LONG",
            order_uuid: "89789189-3ec4-4493-83e1-d37529db173e",
            price: 3403.11,
            price_sources: [],
            processed_ms: 1718715241684,
          },
          {
            leverage: 0.05,
            order_type: "FLAT",
            order_uuid: "a8493370-5ea5-4ad6-be30-0d5ada54025c",
            price: 3433.3,
            price_sources: [],
            processed_ms: 1718741469565,
          },
        ],
        position_type: "FLAT",
        position_uuid: "abf990b1-0abb-42c2-acb2-045e4c88baec",
        return_at_close: 1.0011804949663692,
        trade_pair: ["ETHUSD", "ETH/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 0.8404028858230076,
        close_ms: 1718840867863,
        current_return: 1.0001782411846474,
        initial_entry_price: 0.84083,
        is_closed_position: true,
        miner_hotkey: "5DoCFr2EoW1CGuYCEXhsuQdWRsgiUMuxGwNt4Xqb5TCptcBW",
        net_leverage: 0.0,
        open_ms: 1718803879245,
        orders: [
          {
            leverage: 0.05,
            order_type: "LONG",
            order_uuid: "3239b990-2cb6-413f-a112-6cbc6d290b42",
            price: 0.84083,
            price_sources: [],
            processed_ms: 1718803879217,
          },
          {
            leverage: 0.05,
            order_type: "LONG",
            order_uuid: "06ecee59-7b91-4397-bdc8-7bd9c7c95976",
            price: 0.8402,
            price_sources: [],
            processed_ms: 1718804397384,
          },
          {
            leverage: 0.08,
            order_type: "LONG",
            order_uuid: "4df60701-e9cb-41b3-b50f-20c2d74a4e59",
            price: 0.840262743101767,
            price_sources: [],
            processed_ms: 1718808322649,
          },
          {
            leverage: 0.03,
            order_type: "FLAT",
            order_uuid: "20170066-6737-46f9-af23-e6b41ad0d78e",
            price: 0.841235499907936,
            price_sources: [],
            processed_ms: 1718840867863,
          },
        ],
        position_type: "FLAT",
        position_uuid: "8e8a66b8-5dc6-4262-b842-e56633ea045d",
        return_at_close: 1.0001656389388085,
        trade_pair: ["NZDCAD", "NZD/CAD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 65065.983529411766,
        close_ms: 1718882507980,
        current_return: 1.0016463167833858,
        initial_entry_price: 64730.92,
        is_closed_position: true,
        miner_hotkey: "5DoCFr2EoW1CGuYCEXhsuQdWRsgiUMuxGwNt4Xqb5TCptcBW",
        net_leverage: 0.0,
        open_ms: 1718718849416,
        orders: [
          {
            leverage: 0.005,
            order_type: "LONG",
            order_uuid: "58351baf-ddfc-4108-975f-6773d9e0b2c4",
            price: 64730.92,
            price_sources: [],
            processed_ms: 1718718847782,
          },
          {
            leverage: 0.05,
            order_type: "LONG",
            order_uuid: "c97e5500-cbc3-48aa-ae71-a4dafc31833d",
            price: 65139.08,
            price_sources: [],
            processed_ms: 1718758486722,
          },
          {
            leverage: 0.03,
            order_type: "LONG",
            order_uuid: "d9384389-f78b-4dda-b6f2-1255f81eb788",
            price: 65000.0,
            price_sources: [],
            processed_ms: 1718844088368,
          },
          {
            leverage: 0.03,
            order_type: "FLAT",
            order_uuid: "eb1d7eed-773c-4119-bb8d-bdde69c81734",
            price: 66319.72,
            price_sources: [],
            processed_ms: 1718882507980,
          },
        ],
        position_type: "FLAT",
        position_uuid: "ab0b6896-bec0-4277-a460-c06a97a52a1d",
        return_at_close: 1.0015611768464592,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 1.2733,
        close_ms: 1718882521530,
        current_return: 1.0001727793921307,
        initial_entry_price: 1.2733,
        is_closed_position: true,
        miner_hotkey: "5DoCFr2EoW1CGuYCEXhsuQdWRsgiUMuxGwNt4Xqb5TCptcBW",
        net_leverage: 0.0,
        open_ms: 1718795700413,
        orders: [
          {
            leverage: -0.05,
            order_type: "SHORT",
            order_uuid: "dd24a7b7-fd83-4508-831b-0e16f35f8deb",
            price: 1.2733,
            price_sources: [],
            processed_ms: 1718795699913,
          },
          {
            leverage: 0.03,
            order_type: "FLAT",
            order_uuid: "650af27e-9542-4648-b5d8-cc1e89158c0c",
            price: 1.2689,
            price_sources: [],
            processed_ms: 1718882521530,
          },
        ],
        position_type: "FLAT",
        position_uuid: "e7731af1-1836-41d3-9bec-e3bc38af4b20",
        return_at_close: 1.0001692787874028,
        trade_pair: ["GBPUSD", "GBP/USD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.6449675,
        close_ms: 1718882559053,
        current_return: 1.0006971864789953,
        initial_entry_price: 0.64581,
        is_closed_position: true,
        miner_hotkey: "5DoCFr2EoW1CGuYCEXhsuQdWRsgiUMuxGwNt4Xqb5TCptcBW",
        net_leverage: 0.0,
        open_ms: 1718703602007,
        orders: [
          {
            leverage: 0.05,
            order_type: "LONG",
            order_uuid: "fcec0a69-7bda-4959-aa44-9007ad58b17f",
            price: 0.64581,
            price_sources: [],
            processed_ms: 1718703601914,
          },
          {
            leverage: 0.05,
            order_type: "LONG",
            order_uuid: "a3f3bd0f-c044-4642-93d8-bb9b1cbceb49",
            price: 0.6441250000000001,
            price_sources: [],
            processed_ms: 1718716007457,
          },
          {
            leverage: 0.03,
            order_type: "FLAT",
            order_uuid: "55f81154-0e1b-4e71-bac1-552af91fde9c",
            price: 0.64947,
            price_sources: [],
            processed_ms: 1718882559053,
          },
        ],
        position_type: "FLAT",
        position_uuid: "653eaf72-0a89-4d9b-bf93-161d341a4424",
        return_at_close: 1.00069018159869,
        trade_pair: ["CADCHF", "CAD/CHF", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 169.715625,
        close_ms: 1718901862472,
        current_return: 0.9998353102001615,
        initial_entry_price: 169.713,
        is_closed_position: true,
        miner_hotkey: "5DoCFr2EoW1CGuYCEXhsuQdWRsgiUMuxGwNt4Xqb5TCptcBW",
        net_leverage: 0.0,
        open_ms: 1718796043163,
        orders: [
          {
            leverage: -0.05,
            order_type: "SHORT",
            order_uuid: "df67f19e-5c59-4ec7-b642-f93ed1ae8fb6",
            price: 169.713,
            price_sources: [],
            processed_ms: 1718796042850,
          },
          {
            leverage: -0.03,
            order_type: "SHORT",
            order_uuid: "fedce7f8-0d55-4381-982b-cde96129dbe7",
            price: 169.72,
            price_sources: [],
            processed_ms: 1718812926074,
          },
          {
            leverage: 0.1,
            order_type: "FLAT",
            order_uuid: "659d1336-b7e1-4e39-a0e3-5f7dbbed92a5",
            price: 170.065,
            price_sources: [],
            processed_ms: 1718901862472,
          },
        ],
        position_type: "FLAT",
        position_uuid: "1a9195f8-bd61-4eee-819b-3e69693bfdf4",
        return_at_close: 0.9998297111224244,
        trade_pair: ["EURJPY", "EUR/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 177.21110062810786,
        close_ms: 1718913647288,
        current_return: 0.9991196273862679,
        initial_entry_price: 177.21110062810786,
        is_closed_position: true,
        miner_hotkey: "5DoCFr2EoW1CGuYCEXhsuQdWRsgiUMuxGwNt4Xqb5TCptcBW",
        net_leverage: 0.0,
        open_ms: 1718651487861,
        orders: [
          {
            leverage: -0.15,
            order_type: "SHORT",
            order_uuid: "b87ce163-7415-464b-a63c-6b547c7d1eb1",
            price: 177.21110062810786,
            price_sources: [],
            processed_ms: 1718651487831,
          },
          {
            leverage: 0.1,
            order_type: "FLAT",
            order_uuid: "917099e6-b3f4-4a99-8fe7-6f34f1c53538",
            price: 178.25117929372317,
            price_sources: [],
            processed_ms: 1718913647288,
          },
        ],
        position_type: "FLAT",
        position_uuid: "33df689c-7566-45cf-a217-36c2ce62e72a",
        return_at_close: 0.9991091366301803,
        trade_pair: ["CHFJPY", "CHF/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 64846.245,
        close_ms: 1718915311908,
        current_return: 1.000249883789338,
        initial_entry_price: 64796.12,
        is_closed_position: true,
        miner_hotkey: "5DoCFr2EoW1CGuYCEXhsuQdWRsgiUMuxGwNt4Xqb5TCptcBW",
        net_leverage: 0.0,
        open_ms: 1718892772915,
        orders: [
          {
            leverage: 0.05,
            order_type: "LONG",
            order_uuid: "b3ba4bb9-013f-469a-a6d6-e7e598601414",
            price: 64796.12,
            price_sources: [],
            processed_ms: 1718892772230,
          },
          {
            leverage: 0.05,
            order_type: "LONG",
            order_uuid: "37eae53a-e6ff-4da3-9103-316be0960fbd",
            price: 64896.37,
            price_sources: [],
            processed_ms: 1718893605983,
          },
          {
            leverage: 0.1,
            order_type: "FLAT",
            order_uuid: "46698ed9-db10-49be-a8b1-c7e4aa889f97",
            price: 65008.16,
            price_sources: [],
            processed_ms: 1718915311908,
          },
        ],
        position_type: "FLAT",
        position_uuid: "c489ee4a-babf-41e1-8975-0aae690dd5d8",
        return_at_close: 1.0001498588009592,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 0.91476,
        close_ms: 1718967498357,
        current_return: 1.0001200314836678,
        initial_entry_price: 0.91476,
        is_closed_position: true,
        miner_hotkey: "5DoCFr2EoW1CGuYCEXhsuQdWRsgiUMuxGwNt4Xqb5TCptcBW",
        net_leverage: 0.0,
        open_ms: 1718819693939,
        orders: [
          {
            leverage: -0.03,
            order_type: "SHORT",
            order_uuid: "eecfd0f1-4616-4b7d-ae20-92c97d057175",
            price: 0.91476,
            price_sources: [],
            processed_ms: 1718819692850,
          },
          {
            leverage: 0.1,
            order_type: "FLAT",
            order_uuid: "fef5fd38-9ae1-466b-aa08-2630e22492be",
            price: 0.9111,
            price_sources: [],
            processed_ms: 1718967498357,
          },
        ],
        position_type: "FLAT",
        position_uuid: "35060e50-a283-43b3-bb49-d4646687e26a",
        return_at_close: 1.0001179312316018,
        trade_pair: ["AUDCAD", "AUD/CAD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.6653925,
        close_ms: 1718986370137,
        current_return: 1.0004876692565485,
        initial_entry_price: 0.66541,
        is_closed_position: true,
        miner_hotkey: "5DoCFr2EoW1CGuYCEXhsuQdWRsgiUMuxGwNt4Xqb5TCptcBW",
        net_leverage: 0.0,
        open_ms: 1718899990650,
        orders: [
          {
            leverage: -0.1,
            order_type: "SHORT",
            order_uuid: "f322413e-495c-4b17-a1d1-8227ec2af7bc",
            price: 0.66541,
            price_sources: [],
            processed_ms: 1718899990424,
          },
          {
            leverage: -0.1,
            order_type: "SHORT",
            order_uuid: "e586fdd8-375a-44e1-97b2-06e974d1924b",
            price: 0.665375,
            price_sources: [],
            processed_ms: 1718901844360,
          },
          {
            leverage: 0.02,
            order_type: "FLAT",
            order_uuid: "f60d568a-0982-4481-a0b2-51c0a109537b",
            price: 0.66377,
            price_sources: [],
            processed_ms: 1718986370137,
          },
        ],
        position_type: "FLAT",
        position_uuid: "89aca4a1-bb6c-4299-9784-dab590e0ed9c",
        return_at_close: 1.000473662429179,
        trade_pair: ["AUDUSD", "AUD/USD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 63537.18,
        close_ms: 1719075845707,
        current_return: 1.0002434889304184,
        initial_entry_price: 63537.18,
        is_closed_position: true,
        miner_hotkey: "5DoCFr2EoW1CGuYCEXhsuQdWRsgiUMuxGwNt4Xqb5TCptcBW",
        net_leverage: 0.0,
        open_ms: 1718986011987,
        orders: [
          {
            leverage: 0.02,
            order_type: "LONG",
            order_uuid: "806c89c5-5ecc-43f0-93b3-141b1ec99ae7",
            price: 63537.18,
            price_sources: [],
            processed_ms: 1718986011329,
          },
          {
            leverage: 0.06,
            order_type: "FLAT",
            order_uuid: "7aef09e7-dca2-4bb4-8fd9-46dda9a0a0be",
            price: 64310.71,
            price_sources: [],
            processed_ms: 1719075845707,
          },
        ],
        position_type: "FLAT",
        position_uuid: "27141ffe-b121-42c4-968b-9f876eea3a71",
        return_at_close: 1.0002234840606397,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 3498.09,
        close_ms: 1719085866119,
        current_return: 1.0001421918818556,
        initial_entry_price: 3498.09,
        is_closed_position: true,
        miner_hotkey: "5DoCFr2EoW1CGuYCEXhsuQdWRsgiUMuxGwNt4Xqb5TCptcBW",
        net_leverage: 0.0,
        open_ms: 1719070032224,
        orders: [
          {
            leverage: -0.06,
            order_type: "SHORT",
            order_uuid: "32968347-a6f7-4851-bc8d-f3dd08a8f1c9",
            price: 3498.09,
            price_sources: [],
            processed_ms: 1719070029037,
          },
          {
            leverage: 0.06,
            order_type: "FLAT",
            order_uuid: "bf072050-5b95-4780-8e91-b8b76e8d90e0",
            price: 3489.8,
            price_sources: [],
            processed_ms: 1719085866119,
          },
        ],
        position_type: "FLAT",
        position_uuid: "93cffa36-4934-426f-8eb1-df405425a125",
        return_at_close: 1.0000821833503428,
        trade_pair: ["ETHUSD", "ETH/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 1.2662999999999998,
        close_ms: 1719226097708,
        current_return: 1.000022901366185,
        initial_entry_price: 1.2663,
        is_closed_position: true,
        miner_hotkey: "5DoCFr2EoW1CGuYCEXhsuQdWRsgiUMuxGwNt4Xqb5TCptcBW",
        net_leverage: 0.0,
        open_ms: 1718914264767,
        orders: [
          {
            leverage: 0.1,
            order_type: "LONG",
            order_uuid: "3974cddd-ad07-414f-bbd5-e47f70aea4bf",
            price: 1.2663,
            price_sources: [],
            processed_ms: 1718914264678,
          },
          {
            leverage: 0.03,
            order_type: "FLAT",
            order_uuid: "f834d2d9-829e-454c-b157-0c3a505e934a",
            price: 1.26659,
            price_sources: [],
            processed_ms: 1719226097708,
          },
        ],
        position_type: "FLAT",
        position_uuid: "a7215a76-c9f1-4ecb-84ec-9cf90f925e41",
        return_at_close: 1.0000159012058754,
        trade_pair: ["GBPUSD", "GBP/USD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.83603,
        close_ms: 1719312953188,
        current_return: 1.000021530327859,
        initial_entry_price: 0.83603,
        is_closed_position: true,
        miner_hotkey: "5DoCFr2EoW1CGuYCEXhsuQdWRsgiUMuxGwNt4Xqb5TCptcBW",
        net_leverage: 0.0,
        open_ms: 1719279457188,
        orders: [
          {
            leverage: 0.02,
            order_type: "LONG",
            order_uuid: "40321524-cc32-470c-9dc7-1a892e08dcde",
            price: 0.83603,
            price_sources: [],
            processed_ms: 1719279456980,
          },
          {
            leverage: 0.02,
            order_type: "FLAT",
            order_uuid: "6e1da0b2-1976-4ddb-a555-6aba9688f6a5",
            price: 0.83693,
            price_sources: [],
            processed_ms: 1719312953188,
          },
        ],
        position_type: "FLAT",
        position_uuid: "40321524-cc32-470c-9dc7-1a892e08dcde",
        return_at_close: 1.0000201302977165,
        trade_pair: ["NZDCAD", "NZD/CAD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 1.267805,
        close_ms: 1719336460644,
        current_return: 1.0001557810546575,
        initial_entry_price: 1.267805,
        is_closed_position: true,
        miner_hotkey: "5DoCFr2EoW1CGuYCEXhsuQdWRsgiUMuxGwNt4Xqb5TCptcBW",
        net_leverage: 0.0,
        open_ms: 1719334053006,
        orders: [
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "54a9068c-7f58-4578-a01d-e968ea176feb",
            price: 1.267805,
            price_sources: [],
            processed_ms: 1719334052601,
          },
          {
            leverage: 0.25,
            order_type: "FLAT",
            order_uuid: "d970c3bc-e673-41e6-b8d7-5e9220cf10bd",
            price: 1.268595,
            price_sources: [],
            processed_ms: 1719336460644,
          },
        ],
        position_type: "FLAT",
        position_uuid: "54a9068c-7f58-4578-a01d-e968ea176feb",
        return_at_close: 1.0001382783284891,
        trade_pair: ["GBPUSD", "GBP/USD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 159.6364166666667,
        close_ms: 1719346190276,
        current_return: 0.9996923842801324,
        initial_entry_price: 159.696,
        is_closed_position: true,
        miner_hotkey: "5DoCFr2EoW1CGuYCEXhsuQdWRsgiUMuxGwNt4Xqb5TCptcBW",
        net_leverage: 0.0,
        open_ms: 1719339477182,
        orders: [
          {
            leverage: -0.35,
            order_type: "SHORT",
            order_uuid: "4b676bf1-3763-4dd8-9fb0-0098d51939c0",
            price: 159.696,
            price_sources: [],
            processed_ms: 1719339477107,
          },
          {
            leverage: -0.35,
            order_type: "SHORT",
            order_uuid: "cf83d541-30ac-411f-a7fc-d716cbe543cb",
            price: 159.677,
            price_sources: [],
            processed_ms: 1719340731633,
          },
          {
            leverage: -0.75,
            order_type: "SHORT",
            order_uuid: "9cb2f8a1-8a79-40ec-bb43-c71eb265a7bb",
            price: 159.6055,
            price_sources: [],
            processed_ms: 1719344306132,
          },
          {
            leverage: 0.55,
            order_type: "LONG",
            order_uuid: "54193867-a674-4107-9f1d-f56dc7b1e580",
            price: 159.658,
            price_sources: [],
            processed_ms: 1719346102461,
          },
          {
            leverage: 0.55,
            order_type: "FLAT",
            order_uuid: "bc4e72bc-2dad-476e-b74e-bbb65a00a27f",
            price: 159.691,
            price_sources: [],
            processed_ms: 1719346190276,
          },
        ],
        position_type: "FLAT",
        position_uuid: "4b676bf1-3763-4dd8-9fb0-0098d51939c0",
        return_at_close: 0.999590915503128,
        trade_pair: ["USDJPY", "USD/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 1.263175,
        close_ms: 1719427336361,
        current_return: 1.0001840600075207,
        initial_entry_price: 1.263175,
        is_closed_position: true,
        miner_hotkey: "5DoCFr2EoW1CGuYCEXhsuQdWRsgiUMuxGwNt4Xqb5TCptcBW",
        net_leverage: 0.0,
        open_ms: 1719424413627,
        orders: [
          {
            leverage: -0.25,
            order_type: "SHORT",
            order_uuid: "8a6727b7-b1e5-46ba-973a-8865983e3833",
            price: 1.263175,
            price_sources: [],
            processed_ms: 1719424412776,
          },
          {
            leverage: 0.25,
            order_type: "FLAT",
            order_uuid: "251cd7c4-33a3-4224-8b01-ba6f96270096",
            price: 1.262245,
            price_sources: [],
            processed_ms: 1719427336361,
          },
        ],
        position_type: "FLAT",
        position_uuid: "8a6727b7-b1e5-46ba-973a-8865983e3833",
        return_at_close: 1.0001665567864706,
        trade_pair: ["GBPUSD", "GBP/USD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 1.265437916666667,
        close_ms: 1719491434417,
        current_return: 0.9976699413095397,
        initial_entry_price: 1.26426,
        is_closed_position: true,
        miner_hotkey: "5DoCFr2EoW1CGuYCEXhsuQdWRsgiUMuxGwNt4Xqb5TCptcBW",
        net_leverage: 0.0,
        open_ms: 1719489633024,
        orders: [
          {
            leverage: 0.14,
            order_type: "LONG",
            order_uuid: "2d93fe10-39ad-4af5-bd47-244b8bce7be5",
            price: 1.26426,
            price_sources: [],
            processed_ms: 1719489630600,
          },
          {
            leverage: 5.14,
            order_type: "LONG",
            order_uuid: "e6d741fb-477a-49c8-a7f8-788dac523835",
            price: 1.26547,
            price_sources: [],
            processed_ms: 1719491411945,
          },
          {
            leverage: 5.14,
            order_type: "FLAT",
            order_uuid: "19334755-cfbc-4760-811b-c8e9620c49fe",
            price: 1.26488,
            price_sources: [],
            processed_ms: 1719491434417,
          },
        ],
        position_type: "FLAT",
        position_uuid: "2d93fe10-39ad-4af5-bd47-244b8bce7be5",
        return_at_close: 0.9973012024992317,
        trade_pair: ["GBPUSD", "GBP/USD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.61,
        close_ms: 1719509976284,
        current_return: 0.9992087415222306,
        initial_entry_price: 0.61042,
        is_closed_position: true,
        miner_hotkey: "5DoCFr2EoW1CGuYCEXhsuQdWRsgiUMuxGwNt4Xqb5TCptcBW",
        net_leverage: 0.0,
        open_ms: 1719395947604,
        orders: [
          {
            leverage: 0.08,
            order_type: "LONG",
            order_uuid: "4bb59466-ca42-4649-87ea-d3aa05b49845",
            price: 0.61042,
            price_sources: [],
            processed_ms: 1719395947492,
          },
          {
            leverage: 0.12,
            order_type: "LONG",
            order_uuid: "56cd0629-2054-4d9d-95b7-67c073d2c019",
            price: 0.60891,
            price_sources: [],
            processed_ms: 1719406380615,
          },
          {
            leverage: 0.4,
            order_type: "LONG",
            order_uuid: "f5d03621-01a5-4c13-9a0c-ca61433d420f",
            price: 0.60817,
            price_sources: [],
            processed_ms: 1719445801623,
          },
          {
            leverage: -0.34,
            order_type: "SHORT",
            order_uuid: "7d33dca9-df5b-4d90-9e1f-03b2c41985db",
            price: 0.60694,
            price_sources: [],
            processed_ms: 1719449416976,
          },
          {
            leverage: 0.16,
            order_type: "LONG",
            order_uuid: "29b90e82-a8e0-484d-8522-a0a9b8e775c9",
            price: 0.60868,
            price_sources: [],
            processed_ms: 1719503816051,
          },
          {
            leverage: 0.1,
            order_type: "FLAT",
            order_uuid: "1ee33bfb-3c43-4cc0-8b69-781546524903",
            price: 0.60885,
            price_sources: [],
            processed_ms: 1719509976284,
          },
        ],
        position_type: "FLAT",
        position_uuid: "4bb59466-ca42-4649-87ea-d3aa05b49845",
        return_at_close: 0.9991555836171816,
        trade_pair: ["NZDUSD", "NZD/USD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 160.05300967741945,
        close_ms: 1719586888886,
        current_return: 0.9592287506380415,
        initial_entry_price: 159.6695,
        is_closed_position: true,
        miner_hotkey: "5DoCFr2EoW1CGuYCEXhsuQdWRsgiUMuxGwNt4Xqb5TCptcBW",
        net_leverage: 0.0,
        open_ms: 1719346416263,
        orders: [
          {
            leverage: -0.55,
            order_type: "SHORT",
            order_uuid: "e393521b-18c2-456c-a95b-748ab595b1b3",
            price: 159.6695,
            price_sources: [],
            processed_ms: 1719346416034,
          },
          {
            leverage: -0.55,
            order_type: "SHORT",
            order_uuid: "aec0c2b9-17c7-41c6-83ed-68eb62b35944",
            price: 159.676,
            price_sources: [],
            processed_ms: 1719360037159,
          },
          {
            leverage: -0.55,
            order_type: "SHORT",
            order_uuid: "496df369-8e8c-48df-ae5e-46bbe6a0a9c9",
            price: 159.736,
            price_sources: [],
            processed_ms: 1719362107317,
          },
          {
            leverage: -0.2,
            order_type: "SHORT",
            order_uuid: "fd06be53-dde5-42b2-93fa-c765f4cf266f",
            price: 159.816,
            price_sources: [],
            processed_ms: 1719365511939,
          },
          {
            leverage: -0.2,
            order_type: "SHORT",
            order_uuid: "3a48ed00-34b0-4b49-a511-a5ef15eae952",
            price: 159.855,
            price_sources: [],
            processed_ms: 1719367910309,
          },
          {
            leverage: -0.2,
            order_type: "SHORT",
            order_uuid: "a7c384ba-f9c4-48c6-b4d2-06d8d379a8dd",
            price: 160.066,
            price_sources: [],
            processed_ms: 1719398008496,
          },
          {
            leverage: -1.0,
            order_type: "SHORT",
            order_uuid: "4d166e24-f468-47f9-b062-f241e707d9a7",
            price: 160.288,
            price_sources: [],
            processed_ms: 1719401603647,
          },
          {
            leverage: 2.0,
            order_type: "LONG",
            order_uuid: "76e34217-af1c-47ba-b9e8-2bb853caa642",
            price: 160.358,
            price_sources: [],
            processed_ms: 1719403464876,
          },
          {
            leverage: -2.0,
            order_type: "SHORT",
            order_uuid: "67901eb8-2793-4222-872b-040eb49677e1",
            price: 160.3005,
            price_sources: [],
            processed_ms: 1719406233988,
          },
          {
            leverage: 2.0,
            order_type: "LONG",
            order_uuid: "0f41a9f8-c193-4c82-9941-5e36e765f754",
            price: 160.378,
            price_sources: [],
            processed_ms: 1719406869244,
          },
          {
            leverage: -2.0,
            order_type: "SHORT",
            order_uuid: "273bdc6a-60d9-4434-92dc-2344b0a4dce2",
            price: 160.21699999999998,
            price_sources: [],
            processed_ms: 1719407118468,
          },
          {
            leverage: -2.0,
            order_type: "SHORT",
            order_uuid: "a6282b3a-455d-4ddf-957f-c017ba63dbd6",
            price: 160.2365,
            price_sources: [],
            processed_ms: 1719407275706,
          },
          {
            leverage: 4.0,
            order_type: "LONG",
            order_uuid: "416f60ad-dd76-4f1f-8d68-d0d6bac5dbc4",
            price: 160.355,
            price_sources: [],
            processed_ms: 1719407590286,
          },
          {
            leverage: -0.5,
            order_type: "SHORT",
            order_uuid: "d3cfc9ca-3966-4c89-abd3-5f5f3d6aa882",
            price: 160.4595,
            price_sources: [],
            processed_ms: 1719410112044,
          },
          {
            leverage: 0.2,
            order_type: "LONG",
            order_uuid: "e316127a-d041-4f73-9bf8-df39ca47862c",
            price: 160.7665,
            price_sources: [],
            processed_ms: 1719429227103,
          },
          {
            leverage: 0.1,
            order_type: "LONG",
            order_uuid: "e43ad50d-699f-4f32-a7fd-76b9a2851c40",
            price: 160.629,
            price_sources: [],
            processed_ms: 1719499431136,
          },
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "efc3b5cb-88c3-42cc-b3c2-781d0e3fc1c6",
            price: 160.632,
            price_sources: [],
            processed_ms: 1719499466840,
          },
          {
            leverage: 0.1,
            order_type: "LONG",
            order_uuid: "bd857ee0-8274-4016-a642-899f4ab69116",
            price: 160.687,
            price_sources: [],
            processed_ms: 1719504312952,
          },
          {
            leverage: 0.1,
            order_type: "LONG",
            order_uuid: "802c8b23-5a3a-4eb5-ac16-e93b9aad682e",
            price: 160.803,
            price_sources: [],
            processed_ms: 1719518435159,
          },
          {
            leverage: -5.1,
            order_type: "SHORT",
            order_uuid: "932c0f03-501a-4748-b205-5f78767070ac",
            price: 161.004,
            price_sources: [],
            processed_ms: 1719537011285,
          },
          {
            leverage: 5.1,
            order_type: "LONG",
            order_uuid: "217cc0dc-3ec5-4ff7-8d58-f1324ca34903",
            price: 161.03,
            price_sources: [],
            processed_ms: 1719537293430,
          },
          {
            leverage: -4.1,
            order_type: "SHORT",
            order_uuid: "77c2ecf7-221e-43c5-b2a0-9babab1cea0c",
            price: 160.527,
            price_sources: [],
            processed_ms: 1719577956088,
          },
          {
            leverage: 4.1,
            order_type: "LONG",
            order_uuid: "e5e583e6-01d5-4ee2-a729-7a36eddfdfbf",
            price: 160.4625,
            price_sources: [],
            processed_ms: 1719579738746,
          },
          {
            leverage: -4.1,
            order_type: "SHORT",
            order_uuid: "9c933a2d-90f6-45d7-b019-28b272fc447e",
            price: 160.37849999999997,
            price_sources: [],
            processed_ms: 1719580372737,
          },
          {
            leverage: 3.1,
            order_type: "LONG",
            order_uuid: "797ced7b-9d14-4ab9-b1cd-e1858f10a25c",
            price: 160.456,
            price_sources: [],
            processed_ms: 1719583085885,
          },
          {
            leverage: -8.1,
            order_type: "SHORT",
            order_uuid: "5c684267-9ccf-450f-a76d-9424826373b0",
            price: 160.409,
            price_sources: [],
            processed_ms: 1719583249384,
          },
          {
            leverage: 8.1,
            order_type: "LONG",
            order_uuid: "9fb31c4d-0bf1-42f5-9902-e0f5c7c9caf8",
            price: 160.484,
            price_sources: [],
            processed_ms: 1719583521459,
          },
          {
            leverage: -8.1,
            order_type: "SHORT",
            order_uuid: "879632bd-2b5d-4952-a59c-b2c2ab04dd8a",
            price: 160.411,
            price_sources: [],
            processed_ms: 1719584481473,
          },
          {
            leverage: 8.1,
            order_type: "LONG",
            order_uuid: "a7680dda-38bb-4f48-b0a4-b50b56f91e7b",
            price: 160.5075,
            price_sources: [],
            processed_ms: 1719584664925,
          },
          {
            leverage: -1.1,
            order_type: "SHORT",
            order_uuid: "501c715c-1ef7-4bdc-af22-2bdcdb859ed1",
            price: 160.458,
            price_sources: [],
            processed_ms: 1719585192262,
          },
          {
            leverage: -1.1,
            order_type: "SHORT",
            order_uuid: "f19cbe04-cb0b-4b97-b40e-c6a089911079",
            price: 160.4635,
            price_sources: [],
            processed_ms: 1719585312505,
          },
          {
            leverage: -0.1,
            order_type: "SHORT",
            order_uuid: "d963b895-bc14-45b6-9ff4-360a164de412",
            price: 160.525,
            price_sources: [],
            processed_ms: 1719585471444,
          },
          {
            leverage: -0.1,
            order_type: "SHORT",
            order_uuid: "329f17c4-75c8-40e0-8a86-e35ad81d4e90",
            price: 160.51749999999998,
            price_sources: [],
            processed_ms: 1719585582221,
          },
          {
            leverage: -0.1,
            order_type: "SHORT",
            order_uuid: "90ed887a-35e4-4539-867a-a78767dae4de",
            price: 160.536,
            price_sources: [],
            processed_ms: 1719585625210,
          },
          {
            leverage: -0.1,
            order_type: "SHORT",
            order_uuid: "3faad0b3-2d8a-437b-acf7-db442fb72c45",
            price: 160.563,
            price_sources: [],
            processed_ms: 1719585652792,
          },
          {
            leverage: -0.1,
            order_type: "SHORT",
            order_uuid: "7c94801f-b593-4a6b-9bdb-5a31542777da",
            price: 160.588,
            price_sources: [],
            processed_ms: 1719585714451,
          },
          {
            leverage: -0.1,
            order_type: "SHORT",
            order_uuid: "aaf181a1-9215-43af-8f46-71787ea16e43",
            price: 160.61700000000002,
            price_sources: [],
            processed_ms: 1719585775168,
          },
          {
            leverage: -0.1,
            order_type: "SHORT",
            order_uuid: "eb65a7ad-9a61-4418-adf1-3623495af496",
            price: 160.632,
            price_sources: [],
            processed_ms: 1719586257784,
          },
          {
            leverage: -0.1,
            order_type: "SHORT",
            order_uuid: "9b8ca1ed-103b-46ef-adc9-1b16a5eff57f",
            price: 160.656,
            price_sources: [],
            processed_ms: 1719586278495,
          },
          {
            leverage: -0.1,
            order_type: "SHORT",
            order_uuid: "9154fd2d-4fb1-451c-8436-30de442deb94",
            price: 160.647,
            price_sources: [],
            processed_ms: 1719586298127,
          },
          {
            leverage: -0.1,
            order_type: "SHORT",
            order_uuid: "4699df00-2a7b-4262-9bc3-5d336a6c5573",
            price: 160.675,
            price_sources: [],
            processed_ms: 1719586353950,
          },
          {
            leverage: -0.1,
            order_type: "SHORT",
            order_uuid: "e73c7f89-5a2d-431f-8263-5ca06202c42e",
            price: 160.681,
            price_sources: [],
            processed_ms: 1719586378889,
          },
          {
            leverage: -0.1,
            order_type: "SHORT",
            order_uuid: "c0714f85-d8dc-401c-8b70-c190e37a858b",
            price: 160.7005,
            price_sources: [],
            processed_ms: 1719586408412,
          },
          {
            leverage: -0.1,
            order_type: "SHORT",
            order_uuid: "0e1a95d5-e41d-4b4a-a381-4de0d3ab0020",
            price: 160.71,
            price_sources: [],
            processed_ms: 1719586423347,
          },
          {
            leverage: -1.1,
            order_type: "SHORT",
            order_uuid: "d088b747-e2d2-42ad-b028-ced6a313ab64",
            price: 160.722,
            price_sources: [],
            processed_ms: 1719586452899,
          },
          {
            leverage: -1.1,
            order_type: "SHORT",
            order_uuid: "81412f57-ff05-4b43-a713-793e2e3dc690",
            price: 160.741,
            price_sources: [],
            processed_ms: 1719586510860,
          },
          {
            leverage: -0.1,
            order_type: "SHORT",
            order_uuid: "edcd100b-6e71-44fc-9904-7dfb2f1410dc",
            price: 160.8095,
            price_sources: [],
            processed_ms: 1719586808765,
          },
          {
            leverage: 0.1,
            order_type: "FLAT",
            order_uuid: "aacb45bd-7208-4971-9377-262fd23540d5",
            price: 160.893,
            price_sources: [],
            processed_ms: 1719586888886,
          },
        ],
        position_type: "FLAT",
        position_uuid: "e393521b-18c2-456c-a95b-748ab595b1b3",
        return_at_close: 0.9562038227729044,
        trade_pair: ["USDJPY", "USD/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.66758,
        close_ms: 1719837949519,
        current_return: 1.0008312112406004,
        initial_entry_price: 0.66758,
        is_closed_position: true,
        miner_hotkey: "5DoCFr2EoW1CGuYCEXhsuQdWRsgiUMuxGwNt4Xqb5TCptcBW",
        net_leverage: 0.0,
        open_ms: 1719829831998,
        orders: [
          {
            leverage: -0.31,
            order_type: "SHORT",
            order_uuid: "0ebbdd11-f0b1-4d63-b5b1-668da5b76f24",
            price: 0.66758,
            price_sources: [],
            processed_ms: 1719829831812,
          },
          {
            leverage: 0.31,
            order_type: "FLAT",
            order_uuid: "e1ecdeca-858d-49bc-b5c6-cd8b8854548b",
            price: 0.66579,
            price_sources: [],
            processed_ms: 1719837949519,
          },
        ],
        position_type: "FLAT",
        position_uuid: "0ebbdd11-f0b1-4d63-b5b1-668da5b76f24",
        return_at_close: 1.0008094932033165,
        trade_pair: ["AUDUSD", "AUD/USD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 60772.710219780245,
        close_ms: 1719841924517,
        current_return: 1.0256349600020342,
        initial_entry_price: 62516.93,
        is_closed_position: true,
        miner_hotkey: "5DoCFr2EoW1CGuYCEXhsuQdWRsgiUMuxGwNt4Xqb5TCptcBW",
        net_leverage: 0.0,
        open_ms: 1719207741672,
        orders: [
          {
            leverage: 0.03,
            order_type: "LONG",
            order_uuid: "5f7f9272-c30c-4fb4-bb8c-56fb1d352225",
            price: 62516.93,
            price_sources: [],
            processed_ms: 1719207740086,
          },
          {
            leverage: 0.03,
            order_type: "LONG",
            order_uuid: "9fee417f-8ae9-4b2a-a134-e487fabb2133",
            price: 61166.8,
            price_sources: [],
            processed_ms: 1719226030214,
          },
          {
            leverage: -0.03,
            order_type: "SHORT",
            order_uuid: "1a5b0ef7-369d-4d12-97dd-dbd093b662d6",
            price: 60965.44,
            price_sources: [],
            processed_ms: 1719228108308,
          },
          {
            leverage: 0.03,
            order_type: "LONG",
            order_uuid: "5c7a559e-ae30-4e01-a844-198444f378e6",
            price: 61310.2,
            price_sources: [],
            processed_ms: 1719230764666,
          },
          {
            leverage: 0.03,
            order_type: "LONG",
            order_uuid: "3d479548-3b9d-4338-937e-c3289c10f9b0",
            price: 61404.05,
            price_sources: [],
            processed_ms: 1719231936108,
          },
          {
            leverage: 0.1,
            order_type: "LONG",
            order_uuid: "5d9687f3-96c2-45e2-b09e-85140d1c6a72",
            price: 61147.13,
            price_sources: [],
            processed_ms: 1719233480362,
          },
          {
            leverage: 0.1,
            order_type: "LONG",
            order_uuid: "dc6bd2b7-f38b-4073-94ce-22d2f0ecd49f",
            price: 60930.15,
            price_sources: [],
            processed_ms: 1719244188950,
          },
          {
            leverage: 0.1,
            order_type: "LONG",
            order_uuid: "8f020e42-3824-4a24-b69e-7e6f09c9220c",
            price: 60842.32,
            price_sources: [],
            processed_ms: 1719244424372,
          },
          {
            leverage: -0.2,
            order_type: "SHORT",
            order_uuid: "05ef529c-2a0d-434a-8152-d4f7fd2006ff",
            price: 60707.18,
            price_sources: [],
            processed_ms: 1719244545547,
          },
          {
            leverage: 0.2,
            order_type: "LONG",
            order_uuid: "bf60111b-af40-4645-b98e-93ab25a1fdda",
            price: 60804.13,
            price_sources: [],
            processed_ms: 1719244578970,
          },
          {
            leverage: 0.1,
            order_type: "LONG",
            order_uuid: "740a9d3c-a7c6-4e55-b5e1-fe5fb7f67b03",
            price: 60747.2,
            price_sources: [],
            processed_ms: 1719244783780,
          },
          {
            leverage: -0.3,
            order_type: "SHORT",
            order_uuid: "983d393f-8591-47ea-aac5-7b8b15066867",
            price: 60722.0,
            price_sources: [],
            processed_ms: 1719245173576,
          },
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "a50dbe6a-07c7-4703-9e7d-c3feb5b42e4b",
            price: 60885.68,
            price_sources: [],
            processed_ms: 1719245693688,
          },
          {
            leverage: 0.1,
            order_type: "LONG",
            order_uuid: "c3b25c05-2ecb-4038-92f1-5ca7500fc003",
            price: 60450.51,
            price_sources: [],
            processed_ms: 1719247181436,
          },
          {
            leverage: -0.4,
            order_type: "SHORT",
            order_uuid: "d4a50fc2-001d-4229-8c1f-6a59a54c2db0",
            price: 60086.82,
            price_sources: [],
            processed_ms: 1719247998117,
          },
          {
            leverage: 0.1,
            order_type: "LONG",
            order_uuid: "5d4574e8-df3e-4830-a1b4-9ab1bdb5e119",
            price: 60229.0,
            price_sources: [],
            processed_ms: 1719248303721,
          },
          {
            leverage: 0.1,
            order_type: "LONG",
            order_uuid: "86960c46-076a-44ca-8c7b-fcaeb43f33a5",
            price: 60052.84,
            price_sources: [],
            processed_ms: 1719249630643,
          },
          {
            leverage: 0.1,
            order_type: "LONG",
            order_uuid: "037af8af-9bf3-41e9-982e-a8e05d7ea15e",
            price: 60081.0,
            price_sources: [],
            processed_ms: 1719249834532,
          },
          {
            leverage: -0.1,
            order_type: "SHORT",
            order_uuid: "d18365b1-7236-42f9-b00a-afcea3285b72",
            price: 60116.78,
            price_sources: [],
            processed_ms: 1719250714828,
          },
          {
            leverage: 0.1,
            order_type: "LONG",
            order_uuid: "a3e78500-1759-44ab-bd94-a92dd6edfcca",
            price: 60143.41,
            price_sources: [],
            processed_ms: 1719252457637,
          },
          {
            leverage: 0.2,
            order_type: "LONG",
            order_uuid: "2589b041-ad97-414b-a71e-391a4b998c85",
            price: 59954.95,
            price_sources: [],
            processed_ms: 1719253158456,
          },
          {
            leverage: 0.2,
            order_type: "LONG",
            order_uuid: "18f45bfd-69a1-48f3-9e6c-050093f904f3",
            price: 60081.1,
            price_sources: [],
            processed_ms: 1719253265708,
          },
          {
            leverage: -0.2,
            order_type: "SHORT",
            order_uuid: "78eb44f5-71e2-4732-a144-449bb679b7d5",
            price: 60390.19,
            price_sources: [],
            processed_ms: 1719253804352,
          },
          {
            leverage: 0.1,
            order_type: "LONG",
            order_uuid: "0add7a30-6615-4fec-85fa-966f7f0944d7",
            price: 59974.73,
            price_sources: [],
            processed_ms: 1719256926424,
          },
          {
            leverage: 0.1,
            order_type: "LONG",
            order_uuid: "4b5a3c04-8fbb-4e92-9456-ec941ba40647",
            price: 59835.97,
            price_sources: [],
            processed_ms: 1719258204418,
          },
          {
            leverage: 0.1,
            order_type: "LONG",
            order_uuid: "bf85a4ae-8e6f-44b2-b0e1-fb74e88a205d",
            price: 59510.64,
            price_sources: [],
            processed_ms: 1719258986765,
          },
          {
            leverage: 0.1,
            order_type: "LONG",
            order_uuid: "e96bedf6-cc38-4db8-8598-0631a0856199",
            price: 59219.88,
            price_sources: [],
            processed_ms: 1719260864602,
          },
          {
            leverage: -0.4,
            order_type: "SHORT",
            order_uuid: "9224e77e-3a41-40ef-8212-7c5c054908fb",
            price: 58808.9,
            price_sources: [],
            processed_ms: 1719260960964,
          },
          {
            leverage: 0.1,
            order_type: "LONG",
            order_uuid: "35b64be9-d116-47f9-b8a6-fbdc443822e6",
            price: 59300.65,
            price_sources: [],
            processed_ms: 1719262133284,
          },
          {
            leverage: -0.1,
            order_type: "SHORT",
            order_uuid: "c26ccc91-22a3-4cde-ac8a-c73426df8b3a",
            price: 61318.5,
            price_sources: [],
            processed_ms: 1719313018742,
          },
          {
            leverage: -0.1,
            order_type: "SHORT",
            order_uuid: "984fd886-129e-4d43-a464-e88bdac2b712",
            price: 61093.45,
            price_sources: [],
            processed_ms: 1719319788276,
          },
          {
            leverage: -0.08,
            order_type: "SHORT",
            order_uuid: "44352df5-3369-4103-9e95-fa370d2cd38e",
            price: 61317.18,
            price_sources: [],
            processed_ms: 1719323263961,
          },
          {
            leverage: -0.05,
            order_type: "SHORT",
            order_uuid: "099b31e4-7b0c-4324-8374-8cdc0e682948",
            price: 61663.65,
            price_sources: [],
            processed_ms: 1719325393382,
          },
          {
            leverage: -0.05,
            order_type: "SHORT",
            order_uuid: "644a4225-3e96-4054-aea6-8cb9db3a9802",
            price: 62091.3,
            price_sources: [],
            processed_ms: 1719341568573,
          },
          {
            leverage: -0.05,
            order_type: "SHORT",
            order_uuid: "cd169b91-ef6e-4979-954e-7fa8bfc3289d",
            price: 61985.1,
            price_sources: [],
            processed_ms: 1719345950305,
          },
          {
            leverage: -0.05,
            order_type: "SHORT",
            order_uuid: "43c49ff6-3ab1-4c5b-914c-9e7f49c11e86",
            price: 62301.35,
            price_sources: [],
            processed_ms: 1719368081351,
          },
          {
            leverage: 0.1,
            order_type: "LONG",
            order_uuid: "15bb6d61-19ba-4ee4-995e-9d9b06b45d16",
            price: 61485.0,
            price_sources: [],
            processed_ms: 1719407685644,
          },
          {
            leverage: -0.05,
            order_type: "SHORT",
            order_uuid: "d7eb537f-a95d-4210-b12e-b773934d7ee3",
            price: 61804.47,
            price_sources: [],
            processed_ms: 1719410850342,
          },
          {
            leverage: 0.15,
            order_type: "LONG",
            order_uuid: "9df2b3ae-8af4-4c86-b66d-e676892501fa",
            price: 61271.0,
            price_sources: [],
            processed_ms: 1719423094868,
          },
          {
            leverage: -0.2,
            order_type: "SHORT",
            order_uuid: "0bb88d4d-a4e2-4869-ba8b-3b10d8458a12",
            price: 60704.86,
            price_sources: [],
            processed_ms: 1719429161424,
          },
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "3e8e9418-99b8-40d7-9f79-b9821e4463e6",
            price: 60937.47,
            price_sources: [],
            processed_ms: 1719430587891,
          },
          {
            leverage: 0.1,
            order_type: "LONG",
            order_uuid: "d2b09680-b0bc-450a-889d-a6f445176629",
            price: 60771.05,
            price_sources: [],
            processed_ms: 1719432989847,
          },
          {
            leverage: 0.1,
            order_type: "LONG",
            order_uuid: "26615f0f-bef1-4f04-a934-6c37df4502e4",
            price: 60825.26,
            price_sources: [],
            processed_ms: 1719442200814,
          },
          {
            leverage: 0.1,
            order_type: "LONG",
            order_uuid: "4283ca4d-9ac7-4898-9269-371cfe82c888",
            price: 61169.5,
            price_sources: [],
            processed_ms: 1719491988147,
          },
          {
            leverage: -0.1,
            order_type: "SHORT",
            order_uuid: "489b12dd-03e0-4083-b633-07c2edb8af8a",
            price: 62251.9,
            price_sources: [],
            processed_ms: 1719497599301,
          },
          {
            leverage: 0.1,
            order_type: "LONG",
            order_uuid: "ebb2ac22-279e-4117-8f39-cd464f740d37",
            price: 61740.0,
            price_sources: [],
            processed_ms: 1719502419351,
          },
          {
            leverage: 0.09,
            order_type: "FLAT",
            order_uuid: "447b5925-63fb-4b27-9516-c73251bd57bc",
            price: 62533.83,
            price_sources: [],
            processed_ms: 1719841924517,
          },
        ],
        position_type: "FLAT",
        position_uuid: "5f7f9272-c30c-4fb4-bb8c-56fb1d352225",
        return_at_close: 1.0221785701868273,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 0.60931,
        close_ms: 1719843670904,
        current_return: 1.0003446521475112,
        initial_entry_price: 0.60931,
        is_closed_position: true,
        miner_hotkey: "5DoCFr2EoW1CGuYCEXhsuQdWRsgiUMuxGwNt4Xqb5TCptcBW",
        net_leverage: 0.0,
        open_ms: 1719842081402,
        orders: [
          {
            leverage: -0.2,
            order_type: "SHORT",
            order_uuid: "94f59de9-2f75-4fbb-9d1c-0d9e05bb2f87",
            price: 0.60931,
            price_sources: [],
            processed_ms: 1719842081088,
          },
          {
            leverage: 0.1,
            order_type: "FLAT",
            order_uuid: "9501f30b-8679-46e0-97ae-032c0b724282",
            price: 0.60826,
            price_sources: [],
            processed_ms: 1719843670904,
          },
        ],
        position_type: "FLAT",
        position_uuid: "94f59de9-2f75-4fbb-9d1c-0d9e05bb2f87",
        return_at_close: 1.0003306473223812,
        trade_pair: ["NZDUSD", "NZD/USD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 62786.55,
        close_ms: 1719856143333,
        current_return: 1.00113567316567,
        initial_entry_price: 62786.55,
        is_closed_position: true,
        miner_hotkey: "5DoCFr2EoW1CGuYCEXhsuQdWRsgiUMuxGwNt4Xqb5TCptcBW",
        net_leverage: 0.0,
        open_ms: 1719845923283,
        orders: [
          {
            leverage: 0.1,
            order_type: "LONG",
            order_uuid: "e0b413ac-f4ed-43ff-9c62-b6174f2d860f",
            price: 62786.55,
            price_sources: [],
            processed_ms: 1719845919055,
          },
          {
            leverage: 0.1,
            order_type: "FLAT",
            order_uuid: "68668cec-3ca0-482f-ab79-1013b94e9b62",
            price: 63499.6,
            price_sources: [],
            processed_ms: 1719856143333,
          },
        ],
        position_type: "FLAT",
        position_uuid: "e0b413ac-f4ed-43ff-9c62-b6174f2d860f",
        return_at_close: 1.0010355595983533,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 3422.2450000000003,
        close_ms: 1719856172411,
        current_return: 1.0033855142599997,
        initial_entry_price: 3388.85,
        is_closed_position: true,
        miner_hotkey: "5DoCFr2EoW1CGuYCEXhsuQdWRsgiUMuxGwNt4Xqb5TCptcBW",
        net_leverage: 0.0,
        open_ms: 1719595974416,
        orders: [
          {
            leverage: 0.1,
            order_type: "LONG",
            order_uuid: "bae4f6d4-e7f4-4fd7-b6ef-7892abddbdb1",
            price: 3388.85,
            price_sources: [],
            processed_ms: 1719595973105,
          },
          {
            leverage: 0.1,
            order_type: "LONG",
            order_uuid: "54d5dbbb-c287-4c97-9f02-494520a46a7f",
            price: 3455.64,
            price_sources: [],
            processed_ms: 1719843264545,
          },
          {
            leverage: 0.1,
            order_type: "FLAT",
            order_uuid: "40294390-3bb9-4694-8e6e-817371b1160b",
            price: 3479.61,
            price_sources: [],
            processed_ms: 1719856172411,
          },
        ],
        position_type: "FLAT",
        position_uuid: "bae4f6d4-e7f4-4fd7-b6ef-7892abddbdb1",
        return_at_close: 1.0031848371571477,
        trade_pair: ["ETHUSD", "ETH/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 0.60754,
        close_ms: 1719877834268,
        current_return: 1.0003377555387298,
        initial_entry_price: 0.60754,
        is_closed_position: true,
        miner_hotkey: "5DoCFr2EoW1CGuYCEXhsuQdWRsgiUMuxGwNt4Xqb5TCptcBW",
        net_leverage: 0.0,
        open_ms: 1719864190610,
        orders: [
          {
            leverage: -0.18,
            order_type: "SHORT",
            order_uuid: "e1835e05-fd09-4c7b-8161-58d38a0e7039",
            price: 0.60754,
            price_sources: [],
            processed_ms: 1719864190385,
          },
          {
            leverage: 0.18,
            order_type: "FLAT",
            order_uuid: "2447468f-e65c-4622-b6c0-d7c1eb1047ec",
            price: 0.6064,
            price_sources: [],
            processed_ms: 1719877834268,
          },
        ],
        position_type: "FLAT",
        position_uuid: "e1835e05-fd09-4c7b-8161-58d38a0e7039",
        return_at_close: 1.00032515128301,
        trade_pair: ["NZDUSD", "NZD/USD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 1.07138,
        close_ms: 1719925056695,
        current_return: 1.0003323127473676,
        initial_entry_price: 1.07128,
        is_closed_position: true,
        miner_hotkey: "5DoCFr2EoW1CGuYCEXhsuQdWRsgiUMuxGwNt4Xqb5TCptcBW",
        net_leverage: 0.0,
        open_ms: 1719915452660,
        orders: [
          {
            leverage: 0.1,
            order_type: "LONG",
            order_uuid: "04c5c33b-ea99-4e76-a895-b1ad35d4f659",
            price: 1.07128,
            price_sources: [],
            processed_ms: 1719915452358,
          },
          {
            leverage: 0.1,
            order_type: "LONG",
            order_uuid: "f7d69e1d-5ab9-4a69-b424-377e468655b5",
            price: 1.07148,
            price_sources: [],
            processed_ms: 1719916627989,
          },
          {
            leverage: 0.1,
            order_type: "FLAT",
            order_uuid: "09624489-7e7b-4830-8d53-2fef70e2a948",
            price: 1.07316,
            price_sources: [],
            processed_ms: 1719925056695,
          },
        ],
        position_type: "FLAT",
        position_uuid: "04c5c33b-ea99-4e76-a895-b1ad35d4f659",
        return_at_close: 1.0003183080949891,
        trade_pair: ["EURUSD", "EUR/USD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 1.26522,
        close_ms: 1719927435498,
        current_return: 1.0004151056733217,
        initial_entry_price: 1.26522,
        is_closed_position: true,
        miner_hotkey: "5DoCFr2EoW1CGuYCEXhsuQdWRsgiUMuxGwNt4Xqb5TCptcBW",
        net_leverage: 0.0,
        open_ms: 1719503892870,
        orders: [
          {
            leverage: 0.26,
            order_type: "LONG",
            order_uuid: "fbd66251-933f-4b7c-8c73-d38663f2c3c4",
            price: 1.26522,
            price_sources: [],
            processed_ms: 1719503892269,
          },
          {
            leverage: 0.1,
            order_type: "FLAT",
            order_uuid: "06fd60e5-df15-4a99-baf9-55098e22ece5",
            price: 1.2672400000000001,
            price_sources: [],
            processed_ms: 1719927435498,
          },
        ],
        position_type: "FLAT",
        position_uuid: "fbd66251-933f-4b7c-8c73-d38663f2c3c4",
        return_at_close: 1.0003968981183984,
        trade_pair: ["GBPUSD", "GBP/USD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 1.47328,
        close_ms: 1719927546750,
        current_return: 1.0000314604148566,
        initial_entry_price: 1.47328,
        is_closed_position: true,
        miner_hotkey: "5DoCFr2EoW1CGuYCEXhsuQdWRsgiUMuxGwNt4Xqb5TCptcBW",
        net_leverage: 0.0,
        open_ms: 1719839987016,
        orders: [
          {
            leverage: -0.09,
            order_type: "SHORT",
            order_uuid: "b3560475-54da-480f-9d56-090334dcf230",
            price: 1.47328,
            price_sources: [],
            processed_ms: 1719839986984,
          },
          {
            leverage: 0.1,
            order_type: "FLAT",
            order_uuid: "88ce67ef-75e9-47fd-af00-affb79503d05",
            price: 1.4727649999999999,
            price_sources: [],
            processed_ms: 1719927546750,
          },
        ],
        position_type: "FLAT",
        position_uuid: "b3560475-54da-480f-9d56-090334dcf230",
        return_at_close: 1.0000251602166559,
        trade_pair: ["EURCAD", "EUR/CAD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.83,
        close_ms: 1720014436189,
        current_return: 1.0001756732081684,
        initial_entry_price: 0.83,
        is_closed_position: true,
        miner_hotkey: "5DoCFr2EoW1CGuYCEXhsuQdWRsgiUMuxGwNt4Xqb5TCptcBW",
        net_leverage: 0.0,
        open_ms: 1720012057630,
        orders: [
          {
            leverage: 0.1,
            order_type: "LONG",
            order_uuid: "5b91bb97-8c34-4f31-b9a8-b409c7aac164",
            price: 0.83,
            price_sources: [],
            processed_ms: 1720012057383,
          },
          {
            leverage: 0.1,
            order_type: "FLAT",
            order_uuid: "2f004cd0-6457-46f1-88da-e38e68cf16f7",
            price: 0.8314580876277979,
            price_sources: [],
            processed_ms: 1720014436189,
          },
        ],
        position_type: "FLAT",
        position_uuid: "5b91bb97-8c34-4f31-b9a8-b409c7aac164",
        return_at_close: 1.000168671978456,
        trade_pair: ["NZDCAD", "NZD/CAD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.6053541379310344,
        close_ms: 1720016206416,
        current_return: 0.996495134885104,
        initial_entry_price: 0.60533,
        is_closed_position: true,
        miner_hotkey: "5DoCFr2EoW1CGuYCEXhsuQdWRsgiUMuxGwNt4Xqb5TCptcBW",
        net_leverage: 0.0,
        open_ms: 1719902187091,
        orders: [
          {
            leverage: -0.19,
            order_type: "SHORT",
            order_uuid: "369cc4bb-c46b-40c5-b374-c9e8e067ec3f",
            price: 0.60533,
            price_sources: [],
            processed_ms: 1719902186674,
          },
          {
            leverage: -0.1,
            order_type: "SHORT",
            order_uuid: "d4ac5579-1e00-4594-8e05-d6cfcd6c0942",
            price: 0.6054,
            price_sources: [],
            processed_ms: 1719917160420,
          },
          {
            leverage: 0.1,
            order_type: "FLAT",
            order_uuid: "0434a852-087f-4520-87d3-8ecdff6fe1de",
            price: 0.61267,
            price_sources: [],
            processed_ms: 1720016206416,
          },
        ],
        position_type: "FLAT",
        position_uuid: "369cc4bb-c46b-40c5-b374-c9e8e067ec3f",
        return_at_close: 0.9964749060338659,
        trade_pair: ["NZDUSD", "NZD/USD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 75742.71999999999,
        close_ms: 0,
        current_return: 0.9887232203448997,
        initial_entry_price: 62449.46,
        is_closed_position: false,
        miner_hotkey: "5DoCFr2EoW1CGuYCEXhsuQdWRsgiUMuxGwNt4Xqb5TCptcBW",
        net_leverage: 0.04000000000000001,
        open_ms: 1719914951244,
        orders: [
          {
            leverage: 0.1,
            order_type: "LONG",
            order_uuid: "7e726811-46ff-4fe6-a79a-6e2ab58aca58",
            price: 62449.46,
            price_sources: [],
            processed_ms: 1719914948419,
          },
          {
            leverage: 0.03,
            order_type: "LONG",
            order_uuid: "e1e2d544-dedf-447f-ae2b-bc24f3df0a2c",
            price: 62019.66,
            price_sources: [],
            processed_ms: 1719942601970,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "b68b68bf-6620-48e6-b114-4f32f6414f9d",
            price: 60512.0,
            price_sources: [],
            processed_ms: 1720005740439,
          },
          {
            leverage: -0.1,
            order_type: "SHORT",
            order_uuid: "e69fe8ef-4969-4090-92dd-509800a19cc5",
            price: 56809.47,
            price_sources: [
              {
                close: 56809.47,
                high: 56969.0,
                lag_ms: 209,
                low: 56805.69,
                open: 56809.47,
                source: "Polygon_ws",
                start_ms: 1720373471999,
                timespan_ms: 0,
                volume: 0.50484757,
                vwap: 56813.6601,
                websocket: true,
              },
              {
                close: 56821.79,
                high: 56825.0,
                lag_ms: 2791,
                low: 56821.79,
                open: 56821.79,
                source: "Polygon_rest",
                start_ms: 1720373468000,
                timespan_ms: 1000,
                volume: 0.00294007,
                vwap: 56824.3778,
                websocket: false,
              },
              {
                close: 56822.8,
                high: 56822.8,
                lag_ms: 3210,
                low: 56822.8,
                open: 56822.8,
                source: "TwelveData_ws",
                start_ms: 1720373475000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
              {
                close: 56852.97,
                high: 56865.63,
                lag_ms: 71791,
                low: 56844.35,
                open: 56860.12,
                source: "TwelveData_rest",
                start_ms: 1720373340000,
                timespan_ms: 60000,
                volume: null,
                vwap: null,
                websocket: false,
              },
            ],
            processed_ms: 1720373471790,
          },
        ],
        position_type: "LONG",
        position_uuid: "7e726811-46ff-4fe6-a79a-6e2ab58aca58",
        return_at_close: 0.9886045735584583,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 1.07742,
        close_ms: 0,
        current_return: 0.9994956470085946,
        initial_entry_price: 1.07742,
        is_closed_position: false,
        miner_hotkey: "5DoCFr2EoW1CGuYCEXhsuQdWRsgiUMuxGwNt4Xqb5TCptcBW",
        net_leverage: -0.11,
        open_ms: 1720011846296,
        orders: [
          {
            leverage: -0.11,
            order_type: "SHORT",
            order_uuid: "e7e491e0-28e8-4a3c-81da-d32f462b5eac",
            price: 1.07742,
            price_sources: [],
            processed_ms: 1720011846048,
          },
        ],
        position_type: "SHORT",
        position_uuid: "e7e491e0-28e8-4a3c-81da-d32f462b5eac",
        return_at_close: 0.9994917989503536,
        trade_pair: ["EURUSD", "EUR/USD", 7e-5, 0.001, 500],
      },
    ],
    thirty_day_returns: 0.9817576649393254,
  },
  "5DqmvEK7Viv2NpEEJGJVuYaQEGpeSW6HAVxrNvV18CLxKve5": {
    all_time_returns: 1.1088348115269426,
    n_positions: 31,
    percentage_profitable: 0.9354838709677419,
    positions: [
      {
        average_entry_price: 67594.3,
        close_ms: 1713022606220,
        current_return: 1.0003102332593132,
        initial_entry_price: 67594.3,
        is_closed_position: true,
        miner_hotkey: "5DqmvEK7Viv2NpEEJGJVuYaQEGpeSW6HAVxrNvV18CLxKve5",
        net_leverage: 0.0,
        open_ms: 1713020517016,
        orders: [
          {
            leverage: 0.1,
            order_type: "LONG",
            order_uuid: "a5a4b4bc-2c95-411f-b30a-8a322b71d250",
            price: 67594.3,
            price_sources: [],
            processed_ms: 1713020517016,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "51e7b942-6237-4a66-a5b9-234bcefff179",
            price: 67804.0,
            price_sources: [],
            processed_ms: 1713022606220,
          },
        ],
        position_type: "FLAT",
        position_uuid: "61f38abb-3c39-40aa-a945-0233cd590541",
        return_at_close: 1.0002102022359873,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 153.806,
        close_ms: 1713173124524,
        current_return: 1.0000025356618076,
        initial_entry_price: 153.806,
        is_closed_position: true,
        miner_hotkey: "5DqmvEK7Viv2NpEEJGJVuYaQEGpeSW6HAVxrNvV18CLxKve5",
        net_leverage: 0.0,
        open_ms: 1713172831661,
        orders: [
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "9bc6961b-c170-46fb-960f-28ccdb907e07",
            price: 153.806,
            price_sources: [],
            processed_ms: 1713172831324,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "439f0983-a695-460b-8a32-2638dc4c5320",
            price: 153.845,
            price_sources: [],
            processed_ms: 1713173124524,
          },
        ],
        position_type: "FLAT",
        position_uuid: "35c43fec-ae52-48ea-93fe-9e6b256509ad",
        return_at_close: 1.0000018356600326,
        trade_pair: ["USDJPY", "USD/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 1.24884,
        close_ms: 1713179914855,
        current_return: 1.0000044841613016,
        initial_entry_price: 1.24884,
        is_closed_position: true,
        miner_hotkey: "5DqmvEK7Viv2NpEEJGJVuYaQEGpeSW6HAVxrNvV18CLxKve5",
        net_leverage: 0.0,
        open_ms: 1713172798948,
        orders: [
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "c666015f-203c-46a2-bdc0-1b17c654b6be",
            price: 1.24884,
            price_sources: [],
            processed_ms: 1713172798948,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "8231f35c-d189-4cc3-9487-efeca22eab65",
            price: 1.2494,
            price_sources: [],
            processed_ms: 1713179914855,
          },
        ],
        position_type: "FLAT",
        position_uuid: "4bcec7c4-dfa2-44f6-b505-542c69000ff8",
        return_at_close: 1.0000037841581628,
        trade_pair: ["GBPUSD", "GBP/USD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 1.0658,
        close_ms: 1713179934734,
        current_return: 1.00000516044286,
        initial_entry_price: 1.0658,
        is_closed_position: true,
        miner_hotkey: "5DqmvEK7Viv2NpEEJGJVuYaQEGpeSW6HAVxrNvV18CLxKve5",
        net_leverage: 0.0,
        open_ms: 1713173833576,
        orders: [
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "8b5930ea-c3f9-446d-b9ae-52aa2e9f554c",
            price: 1.0658,
            price_sources: [],
            processed_ms: 1713173833575,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "be7fa7a4-cf83-4fae-b49d-9a06dd06c238",
            price: 1.06635,
            price_sources: [],
            processed_ms: 1713179934734,
          },
        ],
        position_type: "FLAT",
        position_uuid: "41602b56-1df8-4716-b5f9-b197ab5efec4",
        return_at_close: 1.0000044604392477,
        trade_pair: ["EURUSD", "EUR/USD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 1.46437,
        close_ms: 1713179951062,
        current_return: 1.0000060776989421,
        initial_entry_price: 1.46437,
        is_closed_position: true,
        miner_hotkey: "5DqmvEK7Viv2NpEEJGJVuYaQEGpeSW6HAVxrNvV18CLxKve5",
        net_leverage: 0.0,
        open_ms: 1713173816704,
        orders: [
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "4283a265-b4f5-4d0e-8629-6cce8260ae78",
            price: 1.46437,
            price_sources: [],
            processed_ms: 1713173816703,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "356e59f1-b2d3-426c-884a-71457bd20765",
            price: 1.46526,
            price_sources: [],
            processed_ms: 1713179951062,
          },
        ],
        position_type: "FLAT",
        position_uuid: "da013c61-7caa-4038-994e-d4b8a6c9eb2f",
        return_at_close: 1.0000053776946878,
        trade_pair: ["EURCAD", "EUR/CAD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 91.453,
        close_ms: 1713179978626,
        current_return: 1.000001858878331,
        initial_entry_price: 91.453,
        is_closed_position: true,
        miner_hotkey: "5DqmvEK7Viv2NpEEJGJVuYaQEGpeSW6HAVxrNvV18CLxKve5",
        net_leverage: 0.0,
        open_ms: 1713173937897,
        orders: [
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "d537b403-ed6f-4555-b2e8-ff41affbd280",
            price: 91.453,
            price_sources: [],
            processed_ms: 1713173937895,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "790ad84a-9d2d-465f-9d56-ba6fdac1587a",
            price: 91.47,
            price_sources: [],
            processed_ms: 1713179978626,
          },
        ],
        position_type: "FLAT",
        position_uuid: "bbdeff6d-9e7f-43da-8b2e-3ea1dc48b929",
        return_at_close: 1.00000115887703,
        trade_pair: ["NZDJPY", "NZD/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.81651,
        close_ms: 1713183985853,
        current_return: 1.0000095528529962,
        initial_entry_price: 0.81651,
        is_closed_position: true,
        miner_hotkey: "5DqmvEK7Viv2NpEEJGJVuYaQEGpeSW6HAVxrNvV18CLxKve5",
        net_leverage: 0.0,
        open_ms: 1713173920979,
        orders: [
          {
            leverage: -0.01,
            order_type: "SHORT",
            order_uuid: "a97a2809-1c2e-4812-9042-33fb2dad5342",
            price: 0.81651,
            price_sources: [],
            processed_ms: 1713173920978,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "faa7c4e3-216c-414d-92f4-2b692c858b7c",
            price: 0.81573,
            price_sources: [],
            processed_ms: 1713183985853,
          },
        ],
        position_type: "FLAT",
        position_uuid: "0bf5717e-0c3b-4a81-afc3-d7b825de44ab",
        return_at_close: 1.0000088528463091,
        trade_pair: ["NZDCAD", "NZD/CAD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 192.5291,
        close_ms: 1713190326495,
        current_return: 1.0001146037812478,
        initial_entry_price: 192.053,
        is_closed_position: true,
        miner_hotkey: "5DqmvEK7Viv2NpEEJGJVuYaQEGpeSW6HAVxrNvV18CLxKve5",
        net_leverage: 0.0,
        open_ms: 1713172814339,
        orders: [
          {
            leverage: -0.01,
            order_type: "SHORT",
            order_uuid: "17c1de78-8608-4957-aa7a-a4f868757e35",
            price: 192.053,
            price_sources: [],
            processed_ms: 1713172814338,
          },
          {
            leverage: -0.02,
            order_type: "SHORT",
            order_uuid: "f4048bfd-3fdc-42fe-8906-bda5128d7caf",
            price: 192.638,
            price_sources: [],
            processed_ms: 1713187058150,
          },
          {
            leverage: -0.07,
            order_type: "SHORT",
            order_uuid: "cabc66e7-aeec-485a-85f8-3c9ca7d6fa3c",
            price: 192.566,
            price_sources: [],
            processed_ms: 1713187741453,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "0bbc9f71-654e-4e28-9e00-eb15063076c2",
            price: 192.309,
            price_sources: [],
            processed_ms: 1713190326495,
          },
        ],
        position_type: "FLAT",
        position_uuid: "6d198a98-db2e-4276-a02c-963749fb4fdc",
        return_at_close: 1.0001076029790215,
        trade_pair: ["GBPJPY", "GBP/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.8586700000000016,
        close_ms: 1713344098803,
        current_return: 1.0000062447277158,
        initial_entry_price: 0.85352,
        is_closed_position: true,
        miner_hotkey: "5DqmvEK7Viv2NpEEJGJVuYaQEGpeSW6HAVxrNvV18CLxKve5",
        net_leverage: 0.0,
        open_ms: 1713173868525,
        orders: [
          {
            leverage: -0.01,
            order_type: "SHORT",
            order_uuid: "c806ce4e-6eec-45f5-9481-3cd15b98f2ea",
            price: 0.85352,
            price_sources: [],
            processed_ms: 1713173868524,
          },
          {
            leverage: -0.01,
            order_type: "SHORT",
            order_uuid: "00484733-8192-48e8-9f80-a735023e8e6d",
            price: 0.85375,
            price_sources: [],
            processed_ms: 1713184058711,
          },
          {
            leverage: 0.019,
            order_type: "LONG",
            order_uuid: "dfe74930-27b3-4cb2-963f-249a1b268995",
            price: 0.85337,
            price_sources: [],
            processed_ms: 1713186865182,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "794ad271-8cef-4793-85c7-1ad9475e02ff",
            price: 0.85334,
            price_sources: [],
            processed_ms: 1713344098803,
          },
        ],
        position_type: "FLAT",
        position_uuid: "34e11fd8-540d-40ed-936f-1178f3cf5a1c",
        return_at_close: 1.000004844718973,
        trade_pair: ["EURGBP", "EUR/GBP", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.9513099999999959,
        close_ms: 1713458483335,
        current_return: 1.0000209515002054,
        initial_entry_price: 0.9732,
        is_closed_position: true,
        miner_hotkey: "5DqmvEK7Viv2NpEEJGJVuYaQEGpeSW6HAVxrNvV18CLxKve5",
        net_leverage: 0.0,
        open_ms: 1713173851601,
        orders: [
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "f90d2e75-1979-41e7-8d53-63a3483c4fc6",
            price: 0.9732,
            price_sources: [],
            processed_ms: 1713173851122,
          },
          {
            leverage: 0.02,
            order_type: "LONG",
            order_uuid: "735a122a-662e-404b-a03f-c80cf5c563d6",
            price: 0.97241,
            price_sources: [],
            processed_ms: 1713184004063,
          },
          {
            leverage: -0.029,
            order_type: "SHORT",
            order_uuid: "b0fed63f-1c3e-4d83-8fa4-2fad2f53be41",
            price: 0.97341,
            price_sources: [],
            processed_ms: 1713186759379,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "7f5645f5-9b8a-41d4-94eb-958ff51d7f10",
            price: 0.9717,
            price_sources: [],
            processed_ms: 1713458483335,
          },
        ],
        position_type: "FLAT",
        position_uuid: "2fbd8be4-d516-4cae-8fa2-5c5a166c57c7",
        return_at_close: 1.0000188514562072,
        trade_pair: ["EURCHF", "EUR/CHF", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 168.38800000000006,
        close_ms: 1713821735323,
        current_return: 1.0000217571588859,
        initial_entry_price: 163.992,
        is_closed_position: true,
        miner_hotkey: "5DqmvEK7Viv2NpEEJGJVuYaQEGpeSW6HAVxrNvV18CLxKve5",
        net_leverage: 0.0,
        open_ms: 1713173884990,
        orders: [
          {
            leverage: -0.01,
            order_type: "SHORT",
            order_uuid: "d8127001-8319-47bc-af75-5ec50744e523",
            price: 163.992,
            price_sources: [],
            processed_ms: 1713173884989,
          },
          {
            leverage: -0.02,
            order_type: "SHORT",
            order_uuid: "87fdbf69-b8b7-4d9d-b170-5365fe099516",
            price: 164.34,
            price_sources: [],
            processed_ms: 1713187074245,
          },
          {
            leverage: -0.01,
            order_type: "SHORT",
            order_uuid: "94d25d6e-ff1b-4ebd-9876-59bef001c16d",
            price: 164.266,
            price_sources: [],
            processed_ms: 1713187759009,
          },
          {
            leverage: 0.039,
            order_type: "LONG",
            order_uuid: "ebc8c0de-43d6-480c-bc65-c3615809a4e6",
            price: 164.128,
            price_sources: [],
            processed_ms: 1713190598049,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "bdbd7c69-3210-42b9-8981-5b935b994934",
            price: 164.82,
            price_sources: [],
            processed_ms: 1713821735323,
          },
        ],
        position_type: "FLAT",
        position_uuid: "3f621a35-3083-40ed-984e-cb71240e31fe",
        return_at_close: 1.0000189570979658,
        trade_pair: ["EURJPY", "EUR/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.813099945215921,
        close_ms: 1714129359973,
        current_return: 1.000332042286271,
        initial_entry_price: 0.813099945215921,
        is_closed_position: true,
        miner_hotkey: "5DqmvEK7Viv2NpEEJGJVuYaQEGpeSW6HAVxrNvV18CLxKve5",
        net_leverage: 0.0,
        open_ms: 1714106280822,
        orders: [
          {
            leverage: -0.3,
            order_type: "SHORT",
            order_uuid: "cc1fc6ad-0b34-4eb8-b967-dd70d36b5702",
            price: 0.813099945215921,
            price_sources: [],
            processed_ms: 1714106280820,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "cdc43e3b-d52d-4277-969e-2c553b328d3e",
            price: 0.8122,
            price_sources: [],
            processed_ms: 1714129359973,
          },
        ],
        position_type: "FLAT",
        position_uuid: "308f88fc-c2b1-4734-ac60-20d1901142b2",
        return_at_close: 1.0003110353133828,
        trade_pair: ["NZDCAD", "NZD/CAD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 155.855,
        close_ms: 1714390643784,
        current_return: 1.0197619582304078,
        initial_entry_price: 155.855,
        is_closed_position: true,
        miner_hotkey: "5DqmvEK7Viv2NpEEJGJVuYaQEGpeSW6HAVxrNvV18CLxKve5",
        net_leverage: 0.0,
        open_ms: 1714387581958,
        orders: [
          {
            leverage: 10.0,
            order_type: "LONG",
            order_uuid: "55e7fb4b-85da-4a39-97fe-deb8eb4b2861",
            price: 155.855,
            price_sources: [],
            processed_ms: 1714387581956,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "b8a605e8-bb86-4083-a9e6-bf622e181a71",
            price: 156.163,
            price_sources: [],
            processed_ms: 1714390643784,
          },
        ],
        position_type: "FLAT",
        position_uuid: "30e865ef-1814-4ee9-8f0a-7961654c3b00",
        return_at_close: 1.0190481248596466,
        trade_pair: ["USDJPY", "USD/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.8738000000000016,
        close_ms: 1714390661663,
        current_return: 1.0002446826421236,
        initial_entry_price: 0.89095,
        is_closed_position: true,
        miner_hotkey: "5DqmvEK7Viv2NpEEJGJVuYaQEGpeSW6HAVxrNvV18CLxKve5",
        net_leverage: 0.0,
        open_ms: 1714106250671,
        orders: [
          {
            leverage: 0.5,
            order_type: "LONG",
            order_uuid: "ae88ece1-b42f-4f46-a5f1-00e4f398ec62",
            price: 0.89095,
            price_sources: [],
            processed_ms: 1714106250668,
          },
          {
            leverage: -0.49,
            order_type: "SHORT",
            order_uuid: "7fa248c7-4a00-4e32-a2b9-a140dd21140b",
            price: 0.8913,
            price_sources: [],
            processed_ms: 1714109377448,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "e55be3d4-1111-4a70-82f3-bc9da9788577",
            price: 0.8956,
            price_sources: [],
            processed_ms: 1714390661663,
          },
        ],
        position_type: "FLAT",
        position_uuid: "81bcef83-f0dc-4fd6-9466-b59f9ed98b86",
        return_at_close: 1.0002096740782311,
        trade_pair: ["AUDCAD", "AUD/CAD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 93.103,
        close_ms: 1714390678654,
        current_return: 1.0002470382264803,
        initial_entry_price: 93.103,
        is_closed_position: true,
        miner_hotkey: "5DqmvEK7Viv2NpEEJGJVuYaQEGpeSW6HAVxrNvV18CLxKve5",
        net_leverage: 0.0,
        open_ms: 1714387800364,
        orders: [
          {
            leverage: 0.1,
            order_type: "LONG",
            order_uuid: "76c7d7a4-8029-49c1-a5a2-a25d8402dd66",
            price: 93.103,
            price_sources: [],
            processed_ms: 1714387800362,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "a8aeccf4-8158-4654-8bc7-9e8fdd472ece",
            price: 93.333,
            price_sources: [],
            processed_ms: 1714390678654,
          },
        ],
        position_type: "FLAT",
        position_uuid: "60917bc4-e7b7-459b-bc43-45c9558c21fc",
        return_at_close: 1.0002400364972128,
        trade_pair: ["NZDJPY", "NZD/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 114.17000000000002,
        close_ms: 1714390713331,
        current_return: 1.0002364894455635,
        initial_entry_price: 114.17,
        is_closed_position: true,
        miner_hotkey: "5DqmvEK7Viv2NpEEJGJVuYaQEGpeSW6HAVxrNvV18CLxKve5",
        net_leverage: 0.0,
        open_ms: 1714387899195,
        orders: [
          {
            leverage: 0.1,
            order_type: "LONG",
            order_uuid: "ddbdf511-5080-4053-ad9d-2d6038df71d7",
            price: 114.17,
            price_sources: [],
            processed_ms: 1714387899192,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "cce2b830-b600-4057-9abe-6d8ca47ec97b",
            price: 114.44,
            price_sources: [],
            processed_ms: 1714390713331,
          },
        ],
        position_type: "FLAT",
        position_uuid: "4be6a5fd-e303-4b0c-8183-302f9d8a16db",
        return_at_close: 1.0002294877901374,
        trade_pair: ["CADJPY", "CAD/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 195.39900000000003,
        close_ms: 1714390735007,
        current_return: 1.0001597733646819,
        initial_entry_price: 195.399,
        is_closed_position: true,
        miner_hotkey: "5DqmvEK7Viv2NpEEJGJVuYaQEGpeSW6HAVxrNvV18CLxKve5",
        net_leverage: 0.0,
        open_ms: 1714387834990,
        orders: [
          {
            leverage: 0.1,
            order_type: "LONG",
            order_uuid: "aa8baf11-8702-47f4-84ec-90935d01ad70",
            price: 195.399,
            price_sources: [],
            processed_ms: 1714387834986,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "c90e33d1-3245-4dd5-a906-2b009c9b1b21",
            price: 195.7111955568547,
            price_sources: [],
            processed_ms: 1714390735007,
          },
        ],
        position_type: "FLAT",
        position_uuid: "f4c017c7-1184-443c-b3d2-697198c1c599",
        return_at_close: 1.0001527722462684,
        trade_pair: ["GBPJPY", "GBP/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 1.9731966666666714,
        close_ms: 1714391694137,
        current_return: 1.0090451622259284,
        initial_entry_price: 1.79287,
        is_closed_position: true,
        miner_hotkey: "5DqmvEK7Viv2NpEEJGJVuYaQEGpeSW6HAVxrNvV18CLxKve5",
        net_leverage: 0.0,
        open_ms: 1713173901742,
        orders: [
          {
            leverage: -0.01,
            order_type: "SHORT",
            order_uuid: "55a7d16f-a862-496d-9e1d-53a3b344583d",
            price: 1.79287,
            price_sources: [],
            processed_ms: 1713173901741,
          },
          {
            leverage: -0.01,
            order_type: "SHORT",
            order_uuid: "0fc20949-477f-47ad-8bf8-657120f02105",
            price: 1.79389,
            price_sources: [],
            processed_ms: 1713184031764,
          },
          {
            leverage: -0.08,
            order_type: "SHORT",
            order_uuid: "1c9756ee-410a-48f1-872e-f8f5bbe86d2e",
            price: 1.79455,
            price_sources: [],
            processed_ms: 1713187722698,
          },
          {
            leverage: -0.1,
            order_type: "SHORT",
            order_uuid: "3c332e59-5830-485d-839e-c6df9b674290",
            price: 1.80005,
            price_sources: [],
            processed_ms: 1713319026476,
          },
          {
            leverage: -0.3,
            order_type: "SHORT",
            order_uuid: "5d42c6eb-ea41-4de5-a468-c9151be978ec",
            price: 1.80226,
            price_sources: [],
            processed_ms: 1713458441003,
          },
          {
            leverage: -1.5,
            order_type: "SHORT",
            order_uuid: "c289f46c-ea9a-4f16-9ea1-cfbd316b037b",
            price: 1.80898,
            price_sources: [],
            processed_ms: 1713524445389,
          },
          {
            leverage: -1.0,
            order_type: "SHORT",
            order_uuid: "3a0437ee-60ac-41f7-80f2-c85e39e6caea",
            price: 1.80881,
            price_sources: [],
            processed_ms: 1713532405369,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "08cb7db6-af94-4f05-89fa-6007a9428da1",
            price: 1.80209,
            price_sources: [],
            processed_ms: 1713793002566,
          },
          {
            leverage: 2.9,
            order_type: "LONG",
            order_uuid: "6572e07b-5512-459c-b38a-e6b7bc96b03a",
            price: 1.80234,
            price_sources: [],
            processed_ms: 1713793851505,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "722f55da-f3c2-495a-befa-348918c596be",
            price: 1.79301,
            price_sources: [],
            processed_ms: 1714391694137,
          },
        ],
        position_type: "FLAT",
        position_uuid: "48f59eb8-6b86-47d4-8490-a1071f3657cd",
        return_at_close: 1.008833262741861,
        trade_pair: ["EURNZD", "EUR/NZD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 114.444,
        close_ms: 1714392302379,
        current_return: 1.0001232043619588,
        initial_entry_price: 114.444,
        is_closed_position: true,
        miner_hotkey: "5DqmvEK7Viv2NpEEJGJVuYaQEGpeSW6HAVxrNvV18CLxKve5",
        net_leverage: 0.0,
        open_ms: 1714392040451,
        orders: [
          {
            leverage: -0.3,
            order_type: "SHORT",
            order_uuid: "96bfa944-8b61-4e9a-a564-8fd5214114a7",
            price: 114.444,
            price_sources: [],
            processed_ms: 1714392040446,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "498bf31b-d601-41f5-b85a-4e95e84f7291",
            price: 114.397,
            price_sources: [],
            processed_ms: 1714392302379,
          },
        ],
        position_type: "FLAT",
        position_uuid: "a95ff3a5-d962-4906-a34a-cdb540282948",
        return_at_close: 1.0001022017746672,
        trade_pair: ["CADJPY", "CAD/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 93.35606588,
        close_ms: 1714392534899,
        current_return: 1.0001701039546849,
        initial_entry_price: 93.35606588,
        is_closed_position: true,
        miner_hotkey: "5DqmvEK7Viv2NpEEJGJVuYaQEGpeSW6HAVxrNvV18CLxKve5",
        net_leverage: 0.0,
        open_ms: 1714392070326,
        orders: [
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "98459b6f-0e54-479f-b02b-78a68593aefb",
            price: 93.35606588,
            price_sources: [],
            processed_ms: 1714392070322,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "b333250a-519c-427a-b4dc-c57e4f66c144",
            price: 93.409,
            price_sources: [],
            processed_ms: 1714392534899,
          },
        ],
        position_type: "FLAT",
        position_uuid: "25bd3306-1b2f-4a5d-9158-beb8d363b815",
        return_at_close: 1.0001491003825018,
        trade_pair: ["NZDJPY", "NZD/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 156.47602702702702,
        close_ms: 1714400060384,
        current_return: 1.0670002815675628,
        initial_entry_price: 156.268,
        is_closed_position: true,
        miner_hotkey: "5DqmvEK7Viv2NpEEJGJVuYaQEGpeSW6HAVxrNvV18CLxKve5",
        net_leverage: 0.0,
        open_ms: 1714391799364,
        orders: [
          {
            leverage: 1.0,
            order_type: "LONG",
            order_uuid: "abf69d0c-95eb-4569-9208-5071149f0b0c",
            price: 156.268,
            price_sources: [],
            processed_ms: 1714391799360,
          },
          {
            leverage: 5.0,
            order_type: "LONG",
            order_uuid: "f597275b-ad75-489a-93d2-aab9e4a73fe7",
            price: 156.439,
            price_sources: [],
            processed_ms: 1714394500904,
          },
          {
            leverage: 6.0,
            order_type: "LONG",
            order_uuid: "81962332-4900-4504-bef5-a215fc293608",
            price: 156.475,
            price_sources: [],
            processed_ms: 1714394686763,
          },
          {
            leverage: 25.0,
            order_type: "LONG",
            order_uuid: "74b758e6-8d3a-40f2-b122-df46e12c5c8c",
            price: 156.492,
            price_sources: [],
            processed_ms: 1714397452045,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "b5ec7b80-c2a4-4b44-b4fc-2794ec174200",
            price: 156.759,
            price_sources: [],
            processed_ms: 1714400060384,
          },
        ],
        position_type: "FLAT",
        position_uuid: "852868e4-5732-4383-b84b-d97f97b2e2e7",
        return_at_close: 1.0642367508383028,
        trade_pair: ["USDJPY", "USD/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 1.25326,
        close_ms: 1714526010711,
        current_return: 1.0003510843719579,
        initial_entry_price: 1.25326,
        is_closed_position: true,
        miner_hotkey: "5DqmvEK7Viv2NpEEJGJVuYaQEGpeSW6HAVxrNvV18CLxKve5",
        net_leverage: 0.0,
        open_ms: 1714387818958,
        orders: [
          {
            leverage: -0.1,
            order_type: "SHORT",
            order_uuid: "11cab15a-0dc3-4806-95fd-c268f6563e09",
            price: 1.25326,
            price_sources: [],
            processed_ms: 1714387818956,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "343e2744-fcc0-44f8-806f-578c663e2198",
            price: 1.24886,
            price_sources: [],
            processed_ms: 1714526010711,
          },
        ],
        position_type: "FLAT",
        position_uuid: "119c8a37-d8a6-494f-b81f-5c84bc0a2bff",
        return_at_close: 1.0003440819143672,
        trade_pair: ["GBPUSD", "GBP/USD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 101.905,
        close_ms: 1714964339476,
        current_return: 1.0002620087336245,
        initial_entry_price: 101.905,
        is_closed_position: true,
        miner_hotkey: "5DqmvEK7Viv2NpEEJGJVuYaQEGpeSW6HAVxrNvV18CLxKve5",
        net_leverage: 0.0,
        open_ms: 1714106313225,
        orders: [
          {
            leverage: -0.15,
            order_type: "SHORT",
            order_uuid: "e515edb8-c707-4335-bbf7-9b64ee93fcc4",
            price: 101.905,
            price_sources: [],
            processed_ms: 1714106313222,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "6404d184-eaf6-48f2-9ded-0f5c92a2f46f",
            price: 101.727,
            price_sources: [],
            processed_ms: 1714964339476,
          },
        ],
        position_type: "FLAT",
        position_uuid: "7885a129-e888-422b-9736-25c4fc9f315a",
        return_at_close: 1.0002515059825328,
        trade_pair: ["AUDJPY", "AUD/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 1.3653699999999998,
        close_ms: 1715699282814,
        current_return: 1.000142086027963,
        initial_entry_price: 1.36537,
        is_closed_position: true,
        miner_hotkey: "5DqmvEK7Viv2NpEEJGJVuYaQEGpeSW6HAVxrNvV18CLxKve5",
        net_leverage: 0.0,
        open_ms: 1714387759976,
        orders: [
          {
            leverage: -0.2,
            order_type: "SHORT",
            order_uuid: "4bb6a2d9-cff1-4fea-b27b-fb0979ca07b5",
            price: 1.36537,
            price_sources: [],
            processed_ms: 1714387759973,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "3520c1f2-9429-4805-a88c-f677339ed727",
            price: 1.3644,
            price_sources: [],
            processed_ms: 1715699282814,
          },
        ],
        position_type: "FLAT",
        position_uuid: "aec5f9a2-f285-479d-a20a-74299b0365aa",
        return_at_close: 1.0001280840387587,
        trade_pair: ["USDCAD", "USD/CAD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.7061100000000091,
        close_ms: 1716580228786,
        current_return: 1.0001571801654,
        initial_entry_price: 0.59734,
        is_closed_position: true,
        miner_hotkey: "5DqmvEK7Viv2NpEEJGJVuYaQEGpeSW6HAVxrNvV18CLxKve5",
        net_leverage: 0.0,
        open_ms: 1714387782934,
        orders: [
          {
            leverage: -0.15,
            order_type: "SHORT",
            order_uuid: "bc2c4f53-37cc-48ec-b5f6-6861fc541e0e",
            price: 0.59734,
            price_sources: [],
            processed_ms: 1714387782932,
          },
          {
            leverage: 0.149,
            order_type: "LONG",
            order_uuid: "c38949bc-07a6-4a47-9582-600f96341ad0",
            price: 0.59661,
            price_sources: [],
            processed_ms: 1714397644996,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "08e0d1da-a36b-41c9-8122-566b5c6db4dd",
            price: 0.61222,
            price_sources: [],
            processed_ms: 1716580228786,
          },
        ],
        position_type: "FLAT",
        position_uuid: "b37f7110-f70e-4fc5-ab0d-2fa65aa02395",
        return_at_close: 1.0001466785150082,
        trade_pair: ["NZDUSD", "NZD/USD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.8549450166481687,
        close_ms: 1718390164189,
        current_return: 1.0120765789473685,
        initial_entry_price: 0.855,
        is_closed_position: true,
        miner_hotkey: "5DqmvEK7Viv2NpEEJGJVuYaQEGpeSW6HAVxrNvV18CLxKve5",
        net_leverage: 0.0,
        open_ms: 1714387866262,
        orders: [
          {
            leverage: -0.1,
            order_type: "SHORT",
            order_uuid: "54ca413d-5fda-40e9-ab3c-0ce41bb055ef",
            price: 0.855,
            price_sources: [],
            processed_ms: 1714387866259,
          },
          {
            leverage: 0.099,
            order_type: "LONG",
            order_uuid: "4adc1ffe-b053-452a-8089-77a553c10880",
            price: 0.85346,
            price_sources: [],
            processed_ms: 1714411430700,
          },
          {
            leverage: -0.5,
            order_type: "SHORT",
            order_uuid: "dccb4359-8597-48ed-9b6a-9e929ee14164",
            price: 0.85586,
            price_sources: [],
            processed_ms: 1716159730904,
          },
          {
            leverage: -0.4,
            order_type: "SHORT",
            order_uuid: "ba55a52d-8cf7-4b19-9c86-586b4093cfe6",
            price: 0.85342,
            price_sources: [],
            processed_ms: 1716301726082,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "40e5d332-37c1-424c-863b-037f56827b60",
            price: 0.843485,
            price_sources: [],
            processed_ms: 1718390164189,
          },
        ],
        position_type: "FLAT",
        position_uuid: "c28c6e1d-212b-45ce-95ea-63220cff30d2",
        return_at_close: 1.0120057335868422,
        trade_pair: ["EURGBP", "EUR/GBP", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 1.1990799999999624,
        close_ms: 1718390189565,
        current_return: 1.0001851998660498,
        initial_entry_price: 1.46323,
        is_closed_position: true,
        miner_hotkey: "5DqmvEK7Viv2NpEEJGJVuYaQEGpeSW6HAVxrNvV18CLxKve5",
        net_leverage: 0.0,
        open_ms: 1714387882722,
        orders: [
          {
            leverage: 0.1,
            order_type: "LONG",
            order_uuid: "94f9e328-ebf5-42e1-abc4-b76ae4e30551",
            price: 1.46323,
            price_sources: [],
            processed_ms: 1714387882719,
          },
          {
            leverage: 0.1,
            order_type: "LONG",
            order_uuid: "daf6906e-02c8-4736-a88c-123c9279c764",
            price: 1.46228,
            price_sources: [],
            processed_ms: 1714391177118,
          },
          {
            leverage: -0.199,
            order_type: "SHORT",
            order_uuid: "cd793400-135d-488f-a8b1-8cf401069f68",
            price: 1.46408,
            price_sources: [],
            processed_ms: 1714411414188,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "a5dee600-c6da-499f-acbc-9769c37a76f6",
            price: 1.47007,
            price_sources: [],
            processed_ms: 1718390189565,
          },
        ],
        position_type: "FLAT",
        position_uuid: "78b74ee4-df35-46bb-b07a-ef03632d2e70",
        return_at_close: 1.0001711972732517,
        trade_pair: ["EURCAD", "EUR/CAD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 3713.5242307692306,
        close_ms: 1718390210495,
        current_return: 0.9972830258054821,
        initial_entry_price: 3127.63,
        is_closed_position: true,
        miner_hotkey: "5DqmvEK7Viv2NpEEJGJVuYaQEGpeSW6HAVxrNvV18CLxKve5",
        net_leverage: 0.0,
        open_ms: 1716034967481,
        orders: [
          {
            leverage: 0.001,
            order_type: "LONG",
            order_uuid: "b4ae0d7b-5f41-49d6-88ad-41452c6fc496",
            price: 3127.63,
            price_sources: [],
            processed_ms: 1716034966490,
          },
          {
            leverage: 0.025,
            order_type: "LONG",
            order_uuid: "40c96ced-b055-443c-b9e8-e534a27f5ea3",
            price: 3736.96,
            price_sources: [],
            processed_ms: 1716580264707,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "d5f72ecc-7c17-42dd-98d6-1ca4eb4e00c0",
            price: 3386.69,
            price_sources: [],
            processed_ms: 1718390210495,
          },
        ],
        position_type: "FLAT",
        position_uuid: "44cceb15-68da-4303-902f-1b86bfe3044d",
        return_at_close: 0.9972570964468112,
        trade_pair: ["ETHUSD", "ETH/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 3376.2,
        close_ms: 1719478828122,
        current_return: 0.9997547538652923,
        initial_entry_price: 3376.2,
        is_closed_position: true,
        miner_hotkey: "5DqmvEK7Viv2NpEEJGJVuYaQEGpeSW6HAVxrNvV18CLxKve5",
        net_leverage: 0.0,
        open_ms: 1719476716356,
        orders: [
          {
            leverage: -0.6,
            order_type: "SHORT",
            order_uuid: "d2cb2d29-03d8-4293-8221-9b8ea6db2c68",
            price: 3376.2,
            price_sources: [],
            processed_ms: 1719476714573,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "4fd8e113-9fba-411a-8d00-f3118c566fc4",
            price: 3377.58,
            price_sources: [],
            processed_ms: 1719478828122,
          },
        ],
        position_type: "FLAT",
        position_uuid: "4678bc91-165f-464b-811c-9ac71d02f75a",
        return_at_close: 0.9991549010129731,
        trade_pair: ["ETHUSD", "ETH/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 60916.0,
        close_ms: 1719479589727,
        current_return: 1.0006270273819686,
        initial_entry_price: 60916.0,
        is_closed_position: true,
        miner_hotkey: "5DqmvEK7Viv2NpEEJGJVuYaQEGpeSW6HAVxrNvV18CLxKve5",
        net_leverage: 0.0,
        open_ms: 1719479056361,
        orders: [
          {
            leverage: -0.6,
            order_type: "SHORT",
            order_uuid: "83172ee9-68be-4d59-ad09-feec706da767",
            price: 60916.0,
            price_sources: [],
            processed_ms: 1719479053878,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "fd605112-f59d-4519-8818-acd3e94a6e2b",
            price: 60852.34,
            price_sources: [],
            processed_ms: 1719479589727,
          },
        ],
        position_type: "FLAT",
        position_uuid: "06dbdbf9-4bd7-4202-8abc-db4725693508",
        return_at_close: 1.0000266511655393,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 60921.27,
        close_ms: 1719482143785,
        current_return: 1.0028022725067944,
        initial_entry_price: 60921.27,
        is_closed_position: true,
        miner_hotkey: "5DqmvEK7Viv2NpEEJGJVuYaQEGpeSW6HAVxrNvV18CLxKve5",
        net_leverage: 0.0,
        open_ms: 1719480483730,
        orders: [
          {
            leverage: 0.6,
            order_type: "LONG",
            order_uuid: "633c8ac3-bc3a-431b-9c3f-f623a7797af3",
            price: 60921.27,
            price_sources: [],
            processed_ms: 1719480482255,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "8c585fe1-fe5d-41f8-8661-dd5cf6d695f6",
            price: 61205.8,
            price_sources: [],
            processed_ms: 1719482143785,
          },
        ],
        position_type: "FLAT",
        position_uuid: "6b8a88f5-f0c6-4fc1-bea4-9f6583879fca",
        return_at_close: 1.0022005911432903,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 60651.61,
        close_ms: 0,
        current_return: 0.9979270047406821,
        initial_entry_price: 60651.61,
        is_closed_position: false,
        miner_hotkey: "5DqmvEK7Viv2NpEEJGJVuYaQEGpeSW6HAVxrNvV18CLxKve5",
        net_leverage: 0.05,
        open_ms: 1719597182592,
        orders: [
          {
            leverage: 0.05,
            order_type: "LONG",
            order_uuid: "a08d41ac-9243-46eb-952f-3fcd82bd4b52",
            price: 60651.61,
            price_sources: [],
            processed_ms: 1719597181442,
          },
        ],
        position_type: "LONG",
        position_uuid: "2e05f9b7-bac1-4ce5-a17b-1a6a545206b4",
        return_at_close: 0.9979020565655635,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
    ],
    thirty_day_returns: 1.0013803196803388,
  },
  "5DqxA5rsR5FGCkoZQ2eDnpQu1dBrdqr6EU7ZFKqsnHQQvpVh": {
    all_time_returns: 1.022172527830826,
    n_positions: 1,
    percentage_profitable: 1.0,
    positions: [
      {
        average_entry_price: 64407.0,
        close_ms: 1716116426082,
        current_return: 1.0226838697657088,
        initial_entry_price: 64407.0,
        is_closed_position: true,
        miner_hotkey: "5DqxA5rsR5FGCkoZQ2eDnpQu1dBrdqr6EU7ZFKqsnHQQvpVh",
        net_leverage: 0.0,
        open_ms: 1711186702926,
        orders: [
          {
            leverage: 0.5,
            order_type: "LONG",
            order_uuid: "a49a7203-6bec-4b6d-949e-15407659350c",
            price: 64407.0,
            price_sources: [],
            processed_ms: 1711186702925,
          },
          {
            leverage: 0.2,
            order_type: "FLAT",
            order_uuid: "73aab324-87da-4e7e-a499-b2222e0423f2",
            price: 67329.0,
            price_sources: [],
            processed_ms: 1716116426082,
          },
        ],
        position_type: "FLAT",
        position_uuid: "fb379700-1598-434f-97bb-11cd618dc9bc",
        return_at_close: 1.022172527830826,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
    ],
    thirty_day_returns: 1.0,
  },
  "5Dvra51qhDpjZBs1ErmCtbQXXYGc9196q9GXxCNpmAfYKrib": {
    all_time_returns: 1.0,
    n_positions: 0,
    percentage_profitable: 0.0,
    positions: [
      {
        average_entry_price: 64455.93,
        close_ms: 1711196204372,
        current_return: 1.0012104084139348,
        initial_entry_price: 64455.93,
        is_closed_position: true,
        miner_hotkey: "5Dvra51qhDpjZBs1ErmCtbQXXYGc9196q9GXxCNpmAfYKrib",
        net_leverage: 0.0,
        open_ms: 1711192644055,
        orders: [
          {
            leverage: 0.6,
            order_type: "LONG",
            order_uuid: "ba59d4b5-5113-4171-843d-b16a4a9c305e",
            price: 64455.93,
            price_sources: [],
            processed_ms: 1711192644054,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "0baab85c-fe64-4ebc-962b-8192ca1e6cfe",
            price: 64585.96,
            price_sources: [],
            processed_ms: 1711196204372,
          },
        ],
        position_type: "FLAT",
        position_uuid: "f0802236-ad21-40fe-a166-394bb49c5633",
        return_at_close: 1.000008955923838,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 64591.670000000006,
        close_ms: 1711199864078,
        current_return: 0.9993090749937259,
        initial_entry_price: 64591.67,
        is_closed_position: true,
        miner_hotkey: "5Dvra51qhDpjZBs1ErmCtbQXXYGc9196q9GXxCNpmAfYKrib",
        net_leverage: 0.0,
        open_ms: 1711196292134,
        orders: [
          {
            leverage: 0.6,
            order_type: "LONG",
            order_uuid: "5e099242-5a1f-4e75-87b6-c2a6d95ac3c3",
            price: 64591.67,
            price_sources: [],
            processed_ms: 1711196292133,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "64b39cda-53be-4472-8b7d-cb94752da95b",
            price: 64517.29,
            price_sources: [],
            processed_ms: 1711199864078,
          },
        ],
        position_type: "FLAT",
        position_uuid: "9dc0de92-8459-45f3-b2fb-fc64cd3abdfc",
        return_at_close: 0.9981099041037335,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 64537.34,
        close_ms: 1711203524126,
        current_return: 0.999391608021031,
        initial_entry_price: 64537.34,
        is_closed_position: true,
        miner_hotkey: "5Dvra51qhDpjZBs1ErmCtbQXXYGc9196q9GXxCNpmAfYKrib",
        net_leverage: 0.0,
        open_ms: 1711199950022,
        orders: [
          {
            leverage: 0.6,
            order_type: "LONG",
            order_uuid: "244142f7-bc3b-42fc-92d0-229d1b61a0de",
            price: 64537.34,
            price_sources: [],
            processed_ms: 1711199950022,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "be76c219-b3e3-407c-abfc-02930bb6a3da",
            price: 64471.9,
            price_sources: [],
            processed_ms: 1711203524126,
          },
        ],
        position_type: "FLAT",
        position_uuid: "058811ed-80a6-4cf6-832d-d6e1fc52a109",
        return_at_close: 0.9981923380914057,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 64585.240000000005,
        close_ms: 1711207181137,
        current_return: 1.0039166843693699,
        initial_entry_price: 64585.24,
        is_closed_position: true,
        miner_hotkey: "5Dvra51qhDpjZBs1ErmCtbQXXYGc9196q9GXxCNpmAfYKrib",
        net_leverage: 0.0,
        open_ms: 1711203611361,
        orders: [
          {
            leverage: 0.6,
            order_type: "LONG",
            order_uuid: "1f15d663-ec96-4c8a-9e1a-6b3f393adfed",
            price: 64585.24,
            price_sources: [],
            processed_ms: 1711203611360,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "237de7ad-3953-4b73-a017-abb8a868a861",
            price: 65006.84,
            price_sources: [],
            processed_ms: 1711207181137,
          },
        ],
        position_type: "FLAT",
        position_uuid: "8f0f6f21-f8df-4c05-98f3-fff0e8ef2864",
        return_at_close: 1.0027119843481267,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 64885.13999999999,
        close_ms: 1711210847134,
        current_return: 1.00269100136025,
        initial_entry_price: 64885.14,
        is_closed_position: true,
        miner_hotkey: "5Dvra51qhDpjZBs1ErmCtbQXXYGc9196q9GXxCNpmAfYKrib",
        net_leverage: 0.0,
        open_ms: 1711207268065,
        orders: [
          {
            leverage: 0.6,
            order_type: "LONG",
            order_uuid: "0006fcb7-a93f-4970-b068-315fddcf8d74",
            price: 64885.14,
            price_sources: [],
            processed_ms: 1711207268064,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "ce274ed0-4a51-4b3a-929e-4d787772a0da",
            price: 65176.15,
            price_sources: [],
            processed_ms: 1711210847134,
          },
        ],
        position_type: "FLAT",
        position_uuid: "58df535b-6d2c-4e4b-830b-44769e7ddc8b",
        return_at_close: 1.0014877721586177,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 65574.08,
        close_ms: 1711218173135,
        current_return: 1.0000308353544571,
        initial_entry_price: 65574.08,
        is_closed_position: true,
        miner_hotkey: "5Dvra51qhDpjZBs1ErmCtbQXXYGc9196q9GXxCNpmAfYKrib",
        net_leverage: 0.0,
        open_ms: 1711214599259,
        orders: [
          {
            leverage: 0.6,
            order_type: "LONG",
            order_uuid: "37e016e1-e8d0-4c1a-a782-a9a4af851b55",
            price: 65574.08,
            price_sources: [],
            processed_ms: 1711214599258,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "00fad808-1e86-4723-816d-3c69b8ae022c",
            price: 65577.45,
            price_sources: [],
            processed_ms: 1711218173135,
          },
        ],
        position_type: "FLAT",
        position_uuid: "50eaa6a3-f837-47f3-b59a-40f08fd6a7b6",
        return_at_close: 0.9988307983520318,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 65522.740000000005,
        close_ms: 1711221830309,
        current_return: 0.9983450325795289,
        initial_entry_price: 65522.74,
        is_closed_position: true,
        miner_hotkey: "5Dvra51qhDpjZBs1ErmCtbQXXYGc9196q9GXxCNpmAfYKrib",
        net_leverage: 0.0,
        open_ms: 1711218260020,
        orders: [
          {
            leverage: 0.6,
            order_type: "LONG",
            order_uuid: "31489df3-b5be-4234-a110-62e4b7aeee4f",
            price: 65522.74,
            price_sources: [],
            processed_ms: 1711218260019,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "fd6094a7-4fdd-480e-a9f3-0f176be5d0d9",
            price: 65342.01,
            price_sources: [],
            processed_ms: 1711221830309,
          },
        ],
        position_type: "FLAT",
        position_uuid: "d15e1614-f08c-4e32-a4d4-28185cacf05e",
        return_at_close: 0.9971470185404335,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 65301.54,
        close_ms: 1711225495431,
        current_return: 0.9968540711291035,
        initial_entry_price: 65301.54,
        is_closed_position: true,
        miner_hotkey: "5Dvra51qhDpjZBs1ErmCtbQXXYGc9196q9GXxCNpmAfYKrib",
        net_leverage: 0.0,
        open_ms: 1711221917074,
        orders: [
          {
            leverage: 0.6,
            order_type: "LONG",
            order_uuid: "ad161a9f-6ced-4b65-899f-6859d596a337",
            price: 65301.54,
            price_sources: [],
            processed_ms: 1711221917073,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "2c733f27-fbd8-4699-bcae-d0a526715cb0",
            price: 64959.15,
            price_sources: [],
            processed_ms: 1711225495431,
          },
        ],
        position_type: "FLAT",
        position_uuid: "4b40cf7c-4724-4eec-97c6-552114985a72",
        return_at_close: 0.9956578462437486,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 64940.770000000004,
        close_ms: 1711236478104,
        current_return: 0.99573639179209,
        initial_entry_price: 64940.77,
        is_closed_position: true,
        miner_hotkey: "5Dvra51qhDpjZBs1ErmCtbQXXYGc9196q9GXxCNpmAfYKrib",
        net_leverage: 0.0,
        open_ms: 1711232901158,
        orders: [
          {
            leverage: 0.6,
            order_type: "LONG",
            order_uuid: "2548cb03-a6c1-4e04-b69f-4e0877360538",
            price: 64940.77,
            price_sources: [],
            processed_ms: 1711232901157,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "5318d4d9-989e-403b-9875-7f9d9f444297",
            price: 64479.3,
            price_sources: [],
            processed_ms: 1711236478104,
          },
        ],
        position_type: "FLAT",
        position_uuid: "a36862e8-8a6c-49b8-961a-e2d47777de63",
        return_at_close: 0.9945415081219394,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 64489.05,
        close_ms: 1711240134149,
        current_return: 0.999243127321615,
        initial_entry_price: 64489.05,
        is_closed_position: true,
        miner_hotkey: "5Dvra51qhDpjZBs1ErmCtbQXXYGc9196q9GXxCNpmAfYKrib",
        net_leverage: 0.0,
        open_ms: 1711236564274,
        orders: [
          {
            leverage: 0.6,
            order_type: "LONG",
            order_uuid: "e1564ed4-be89-4ea3-b396-ef4d980a7874",
            price: 64489.05,
            price_sources: [],
            processed_ms: 1711236564273,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "784f22dc-646f-41b2-bad9-8cd723a58d51",
            price: 64407.7,
            price_sources: [],
            processed_ms: 1711240134149,
          },
        ],
        position_type: "FLAT",
        position_uuid: "168204d6-848f-40a8-bfd9-f965b0c19903",
        return_at_close: 0.9980440355688291,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 64382.93000000001,
        close_ms: 1711243801246,
        current_return: 1.002312942265908,
        initial_entry_price: 64382.93,
        is_closed_position: true,
        miner_hotkey: "5Dvra51qhDpjZBs1ErmCtbQXXYGc9196q9GXxCNpmAfYKrib",
        net_leverage: 0.0,
        open_ms: 1711240221077,
        orders: [
          {
            leverage: 0.6,
            order_type: "LONG",
            order_uuid: "0233ba29-b4b7-4675-bfd7-a423a27668d9",
            price: 64382.93,
            price_sources: [],
            processed_ms: 1711240221075,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "9d39ba29-1a2d-42fc-a2ab-9092e873574c",
            price: 64631.12,
            price_sources: [],
            processed_ms: 1711243801246,
          },
        ],
        position_type: "FLAT",
        position_uuid: "84819db5-fc7a-4b75-adf1-9bc660463b4e",
        return_at_close: 1.001110166735189,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 64290.43000000001,
        close_ms: 1711251114574,
        current_return: 0.9986725240444029,
        initial_entry_price: 64290.43,
        is_closed_position: true,
        miner_hotkey: "5Dvra51qhDpjZBs1ErmCtbQXXYGc9196q9GXxCNpmAfYKrib",
        net_leverage: 0.0,
        open_ms: 1711247550243,
        orders: [
          {
            leverage: 0.6,
            order_type: "LONG",
            order_uuid: "205a794b-36e5-48f1-b040-f45a7584597b",
            price: 64290.43,
            price_sources: [],
            processed_ms: 1711247550242,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "810e3876-4930-42a6-a8ed-48e90b7ecbb3",
            price: 64148.19,
            price_sources: [],
            processed_ms: 1711251114574,
          },
        ],
        position_type: "FLAT",
        position_uuid: "c74a4cf6-efea-4c36-b0f3-807a0d681f2c",
        return_at_close: 0.9974741170155497,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 64133.33,
        close_ms: 1711254776192,
        current_return: 0.99871997914345,
        initial_entry_price: 64133.33,
        is_closed_position: true,
        miner_hotkey: "5Dvra51qhDpjZBs1ErmCtbQXXYGc9196q9GXxCNpmAfYKrib",
        net_leverage: 0.0,
        open_ms: 1711251251103,
        orders: [
          {
            leverage: 0.6,
            order_type: "LONG",
            order_uuid: "e2d5b817-37f2-4229-8ad4-0397ed7117eb",
            price: 64133.33,
            price_sources: [],
            processed_ms: 1711251251102,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "2801d42b-591b-49bc-a8a9-13d330e5ce31",
            price: 63996.51,
            price_sources: [],
            processed_ms: 1711254776192,
          },
        ],
        position_type: "FLAT",
        position_uuid: "b78d2ea3-a43e-44c8-934e-c2535f60c81b",
        return_at_close: 0.9975215151684779,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 64359.11,
        close_ms: 1711262861096,
        current_return: 0.9959828919324708,
        initial_entry_price: 64359.11,
        is_closed_position: true,
        miner_hotkey: "5Dvra51qhDpjZBs1ErmCtbQXXYGc9196q9GXxCNpmAfYKrib",
        net_leverage: 0.0,
        open_ms: 1711259284593,
        orders: [
          {
            leverage: 1.25,
            order_type: "LONG",
            order_uuid: "4e74534c-0e4e-41e3-adda-d7a1f384db47",
            price: 64359.11,
            price_sources: [],
            processed_ms: 1711259284592,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "7eb253a9-4299-4574-8b74-6d3d4b8c6a32",
            price: 64152.28,
            price_sources: [],
            processed_ms: 1711262861096,
          },
        ],
        position_type: "FLAT",
        position_uuid: "e7272ce1-183b-4e2e-b227-a77a2a8f4763",
        return_at_close: 0.9934929347026397,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 64146.02,
        close_ms: 1711266524437,
        current_return: 1.003691382567461,
        initial_entry_price: 64146.02,
        is_closed_position: true,
        miner_hotkey: "5Dvra51qhDpjZBs1ErmCtbQXXYGc9196q9GXxCNpmAfYKrib",
        net_leverage: 0.0,
        open_ms: 1711262947643,
        orders: [
          {
            leverage: 1.25,
            order_type: "LONG",
            order_uuid: "877eeced-04d9-47f8-ae67-60f87d6d59cf",
            price: 64146.02,
            price_sources: [],
            processed_ms: 1711262947642,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "3ea47981-7bb4-4ed0-beac-93b9ed06af07",
            price: 64335.45,
            price_sources: [],
            processed_ms: 1711266524437,
          },
        ],
        position_type: "FLAT",
        position_uuid: "b4459df2-4c6b-491d-ae24-f69272085e99",
        return_at_close: 1.0011821541110424,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 65131.969999999994,
        close_ms: 1711284467212,
        current_return: 1.010012172209746,
        initial_entry_price: 65131.97,
        is_closed_position: true,
        miner_hotkey: "5Dvra51qhDpjZBs1ErmCtbQXXYGc9196q9GXxCNpmAfYKrib",
        net_leverage: 0.0,
        open_ms: 1711277597260,
        orders: [
          {
            leverage: 1.25,
            order_type: "LONG",
            order_uuid: "c7783eeb-637f-4288-be94-e26b163d8899",
            price: 65131.97,
            price_sources: [],
            processed_ms: 1711277597258,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "34ba4443-de62-497b-b609-73b56aad07f0",
            price: 65653.66,
            price_sources: [],
            processed_ms: 1711284467212,
          },
        ],
        position_type: "FLAT",
        position_uuid: "1aba63a6-2acd-43c5-bbee-c39f5d8eb849",
        return_at_close: 1.0074871417792217,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 65641.26,
        close_ms: 1711289954620,
        current_return: 0.9994928875527376,
        initial_entry_price: 65641.26,
        is_closed_position: true,
        miner_hotkey: "5Dvra51qhDpjZBs1ErmCtbQXXYGc9196q9GXxCNpmAfYKrib",
        net_leverage: 0.0,
        open_ms: 1711286290535,
        orders: [
          {
            leverage: 1.25,
            order_type: "LONG",
            order_uuid: "bb980d38-b40b-448c-bb88-e1cfd633df5f",
            price: 65641.26,
            price_sources: [],
            processed_ms: 1711286290534,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "6fa9126f-c984-4c1f-b718-f04c151a99bb",
            price: 65614.63,
            price_sources: [],
            processed_ms: 1711289954620,
          },
        ],
        position_type: "FLAT",
        position_uuid: "381c2e11-5318-4c97-8a7c-3884eb8d6c74",
        return_at_close: 0.9969941553338558,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 65634.08,
        close_ms: 1711295449511,
        current_return: 1.0005709686187418,
        initial_entry_price: 65634.08,
        is_closed_position: true,
        miner_hotkey: "5Dvra51qhDpjZBs1ErmCtbQXXYGc9196q9GXxCNpmAfYKrib",
        net_leverage: 0.0,
        open_ms: 1711291784368,
        orders: [
          {
            leverage: 1.25,
            order_type: "LONG",
            order_uuid: "521e8bb6-2bf5-4a01-b3c8-770636de95df",
            price: 65634.08,
            price_sources: [],
            processed_ms: 1711291784366,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "aefb9013-ee2f-4b5b-84fb-bf4bea671245",
            price: 65664.06,
            price_sources: [],
            processed_ms: 1711295449511,
          },
        ],
        position_type: "FLAT",
        position_uuid: "2c2f7a35-c3e8-476f-8d53-2ad70e3ab5f1",
        return_at_close: 0.998069541197195,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 65655.97,
        close_ms: 1711299106229,
        current_return: 0.9951912217578995,
        initial_entry_price: 65655.97,
        is_closed_position: true,
        miner_hotkey: "5Dvra51qhDpjZBs1ErmCtbQXXYGc9196q9GXxCNpmAfYKrib",
        net_leverage: 0.0,
        open_ms: 1711297297337,
        orders: [
          {
            leverage: 1.25,
            order_type: "LONG",
            order_uuid: "6a3a1214-a318-49ad-86c7-ea394cd90f53",
            price: 65655.97,
            price_sources: [],
            processed_ms: 1711297297336,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "0b6ccbe8-9a52-40be-8e1b-ecbfcce09df3",
            price: 65403.39,
            price_sources: [],
            processed_ms: 1711299106229,
          },
        ],
        position_type: "FLAT",
        position_uuid: "7b2fbeae-ca65-4b61-9380-0dc2812b30e1",
        return_at_close: 0.9927032437035048,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 64989.86,
        close_ms: 1711305695613,
        current_return: 1.0147071020002196,
        initial_entry_price: 64989.86,
        is_closed_position: true,
        miner_hotkey: "5Dvra51qhDpjZBs1ErmCtbQXXYGc9196q9GXxCNpmAfYKrib",
        net_leverage: 0.0,
        open_ms: 1711300968376,
        orders: [
          {
            leverage: 1.25,
            order_type: "LONG",
            order_uuid: "e132b2e8-612a-467a-b30c-b99570384571",
            price: 64989.86,
            price_sources: [],
            processed_ms: 1711300968374,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "197891d9-133f-410e-8db4-af9109318b2e",
            price: 65754.51,
            price_sources: [],
            processed_ms: 1711305695613,
          },
        ],
        position_type: "FLAT",
        position_uuid: "f63f1c96-079c-42d9-880e-e961c47c5bc7",
        return_at_close: 1.012170334245219,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 65786.9,
        close_ms: 1711311291246,
        current_return: 1.0078115855892285,
        initial_entry_price: 65786.9,
        is_closed_position: true,
        miner_hotkey: "5Dvra51qhDpjZBs1ErmCtbQXXYGc9196q9GXxCNpmAfYKrib",
        net_leverage: 0.0,
        open_ms: 1711307559891,
        orders: [
          {
            leverage: 1.25,
            order_type: "LONG",
            order_uuid: "114de62f-7f12-4b3d-9d40-b4661ad8c38c",
            price: 65786.9,
            price_sources: [],
            processed_ms: 1711307559889,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "61f4da13-3eed-4a20-bedf-0c9870a5b872",
            price: 66198.02,
            price_sources: [],
            processed_ms: 1711311291246,
          },
        ],
        position_type: "FLAT",
        position_uuid: "5e2837e5-46ad-4ebc-8781-b75f846adb44",
        return_at_close: 1.0052920566252554,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 66217.17,
        close_ms: 1711315009640,
        current_return: 1.0029827988722562,
        initial_entry_price: 66217.17,
        is_closed_position: true,
        miner_hotkey: "5Dvra51qhDpjZBs1ErmCtbQXXYGc9196q9GXxCNpmAfYKrib",
        net_leverage: 0.0,
        open_ms: 1711314083623,
        orders: [
          {
            leverage: 1.25,
            order_type: "LONG",
            order_uuid: "958c02a0-b6b0-494e-a68f-86a2af1c3185",
            price: 66217.17,
            price_sources: [],
            processed_ms: 1711314083620,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "1fab1b9c-edef-4f85-949b-1388a82b92ec",
            price: 66375.18,
            price_sources: [],
            processed_ms: 1711315009640,
          },
        ],
        position_type: "FLAT",
        position_uuid: "34bed808-d7c3-4bea-a3ed-6326a10f5e01",
        return_at_close: 1.0004753418750756,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 66225.63,
        close_ms: 1711318726672,
        current_return: 1.0104859327121536,
        initial_entry_price: 66225.63,
        is_closed_position: true,
        miner_hotkey: "5Dvra51qhDpjZBs1ErmCtbQXXYGc9196q9GXxCNpmAfYKrib",
        net_leverage: 0.0,
        open_ms: 1711316871513,
        orders: [
          {
            leverage: 1.25,
            order_type: "LONG",
            order_uuid: "bd995b3a-20fb-4c71-8c65-c6cca667d217",
            price: 66225.63,
            price_sources: [],
            processed_ms: 1711316871512,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "66c5c22f-23ff-4f81-891e-33715f2ed2a1",
            price: 66781.18,
            price_sources: [],
            processed_ms: 1711318726672,
          },
        ],
        position_type: "FLAT",
        position_uuid: "9b9b5dcc-cffc-45ae-9b1a-f222546d683e",
        return_at_close: 1.0079597178803732,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 66865.16,
        close_ms: 1711327110385,
        current_return: 0.9950617032846403,
        initial_entry_price: 66865.16,
        is_closed_position: true,
        miner_hotkey: "5Dvra51qhDpjZBs1ErmCtbQXXYGc9196q9GXxCNpmAfYKrib",
        net_leverage: 0.0,
        open_ms: 1711325251267,
        orders: [
          {
            leverage: 1.25,
            order_type: "LONG",
            order_uuid: "1badf4ae-19ad-43d8-9cba-ef9f87d1f0fe",
            price: 66865.16,
            price_sources: [],
            processed_ms: 1711325251266,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "27e6174a-8c70-42a5-8939-93cceea442e0",
            price: 66601.0,
            price_sources: [],
            processed_ms: 1711327110385,
          },
        ],
        position_type: "FLAT",
        position_uuid: "bdb794f7-7b64-47c5-bfe9-ba10e06fda15",
        return_at_close: 0.9925740490264288,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 64028.14399999997,
        close_ms: 1711378865926,
        current_return: 1.0503351987110394,
        initial_entry_price: 66751.45,
        is_closed_position: true,
        miner_hotkey: "5Dvra51qhDpjZBs1ErmCtbQXXYGc9196q9GXxCNpmAfYKrib",
        net_leverage: 0.0,
        open_ms: 1711335278184,
        orders: [
          {
            leverage: 1.25,
            order_type: "LONG",
            order_uuid: "b24d4668-afbd-436a-bb2d-68c7cd3173e1",
            price: 66751.45,
            price_sources: [],
            processed_ms: 1711335278182,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "7d66e706-4d90-4984-b6c3-5ee3c01bd27f",
            price: 67248.93,
            price_sources: [],
            processed_ms: 1711370137170,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "0c4d5da4-685f-44db-8f94-3d68ab2a8d38",
            price: 67185.79,
            price_sources: [],
            processed_ms: 1711371973638,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "476eaf86-a495-407b-84e0-d99742de1d76",
            price: 67212.0,
            price_sources: [],
            processed_ms: 1711373800810,
          },
          {
            leverage: -1.0,
            order_type: "SHORT",
            order_uuid: "50115272-2c56-44ff-a8ae-9ec35010c7bb",
            price: 67827.85,
            price_sources: [],
            processed_ms: 1711375636241,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "1a5bc2dc-027e-4c93-adb3-fd8895ad6e80",
            price: 68838.73,
            price_sources: [],
            processed_ms: 1711375977210,
          },
          {
            leverage: -1.0,
            order_type: "SHORT",
            order_uuid: "e78683a9-0b53-4e5a-80bd-3d7f88d068d6",
            price: 69141.81,
            price_sources: [],
            processed_ms: 1711376485718,
          },
          {
            leverage: -0.125,
            order_type: "SHORT",
            order_uuid: "1f412a84-80eb-465d-9fa6-89ee8aee7b46",
            price: 69296.54,
            price_sources: [],
            processed_ms: 1711376813371,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "56bb3166-7d32-4954-a5f4-b9e17c5046d8",
            price: 69142.29,
            price_sources: [],
            processed_ms: 1711377960350,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "74473af5-e047-4627-8d50-9d19b9dff0ed",
            price: 69212.28,
            price_sources: [],
            processed_ms: 1711378246425,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "7c55ec16-8e46-42dc-a6c3-4790c510fc47",
            price: 69404.06,
            price_sources: [],
            processed_ms: 1711378865926,
          },
        ],
        position_type: "FLAT",
        position_uuid: "51bde954-2e3b-48eb-b470-ea598040f5d7",
        return_at_close: 1.0461338579161952,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 70509.50962962963,
        close_ms: 1711396614455,
        current_return: 1.05541145972207,
        initial_entry_price: 69720.19,
        is_closed_position: true,
        miner_hotkey: "5Dvra51qhDpjZBs1ErmCtbQXXYGc9196q9GXxCNpmAfYKrib",
        net_leverage: 0.0,
        open_ms: 1711381345355,
        orders: [
          {
            leverage: 1.25,
            order_type: "LONG",
            order_uuid: "b5cd8e6c-19e9-4176-ae53-89c2889ce8b1",
            price: 69720.19,
            price_sources: [],
            processed_ms: 1711381345353,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "5a586521-290d-4042-8e47-59d8f8c550a5",
            price: 69704.71,
            price_sources: [],
            processed_ms: 1711383163681,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "cf708cd2-54d4-4d1b-8db6-39215d171c58",
            price: 70239.51,
            price_sources: [],
            processed_ms: 1711384978258,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "98daf07d-2f48-4df6-b946-5fd4e6b8c470",
            price: 70520.0,
            price_sources: [],
            processed_ms: 1711386795357,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "c49d35c0-eef1-4a02-9a30-c35ba2b4096f",
            price: 70450.05,
            price_sources: [],
            processed_ms: 1711388623132,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "2b0aa9de-544f-407b-a6b3-c21f28f01833",
            price: 70765.57,
            price_sources: [],
            processed_ms: 1711392682818,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "3daa12a8-efb0-4126-a489-7904c3389a43",
            price: 70700.0,
            price_sources: [],
            processed_ms: 1711394495960,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "931595db-da85-42fb-8e7a-38f0d940b98c",
            price: 70908.02,
            price_sources: [],
            processed_ms: 1711396312274,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "111a4fc4-e6f4-4b81-a2b7-9a30f09d6b48",
            price: 71081.85,
            price_sources: [],
            processed_ms: 1711396614455,
          },
        ],
        position_type: "FLAT",
        position_uuid: "a81e5166-0a1b-4662-8f5c-b1b102bae6bb",
        return_at_close: 1.0411634050158223,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 70872.47615384616,
        close_ms: 1711401293104,
        current_return: 0.9675413063230989,
        initial_entry_price: 70843.27,
        is_closed_position: true,
        miner_hotkey: "5Dvra51qhDpjZBs1ErmCtbQXXYGc9196q9GXxCNpmAfYKrib",
        net_leverage: 0.0,
        open_ms: 1711398133792,
        orders: [
          {
            leverage: 5.0,
            order_type: "LONG",
            order_uuid: "22ec2600-1d4c-4406-a7ae-bb5ce63e76a3",
            price: 70843.27,
            price_sources: [],
            processed_ms: 1711398133790,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "6e972499-35e6-4d22-b108-20f87f30260f",
            price: 70969.83,
            price_sources: [],
            processed_ms: 1711399953244,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "f68477be-2488-4895-815b-88ebc293fc03",
            price: 70518.71,
            price_sources: [],
            processed_ms: 1711401293104,
          },
        ],
        position_type: "FLAT",
        position_uuid: "7ff03be9-ed70-4130-b8bd-7038c2c7cff0",
        return_at_close: 0.9549632693408986,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 70162.5,
        close_ms: 1711402275779,
        current_return: 0.9884197398895421,
        initial_entry_price: 70162.5,
        is_closed_position: true,
        miner_hotkey: "5Dvra51qhDpjZBs1ErmCtbQXXYGc9196q9GXxCNpmAfYKrib",
        net_leverage: 0.0,
        open_ms: 1711401774406,
        orders: [
          {
            leverage: 5.0,
            order_type: "LONG",
            order_uuid: "ac982ba9-dcaa-4726-8a88-993019ad7ad5",
            price: 70162.5,
            price_sources: [],
            processed_ms: 1711401774404,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "ee8a2ac0-cbbd-4430-8e54-90aee1a10de4",
            price: 70000.0,
            price_sources: [],
            processed_ms: 1711402275779,
          },
        ],
        position_type: "FLAT",
        position_uuid: "599aa871-3c68-4317-a7fe-3f5fcbe1a23f",
        return_at_close: 0.9785355424906467,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 61902.02470588254,
        close_ms: 1711416292559,
        current_return: 1.0253738420391545,
        initial_entry_price: 69933.28,
        is_closed_position: true,
        miner_hotkey: "5Dvra51qhDpjZBs1ErmCtbQXXYGc9196q9GXxCNpmAfYKrib",
        net_leverage: 0.0,
        open_ms: 1711403605356,
        orders: [
          {
            leverage: 5.0,
            order_type: "LONG",
            order_uuid: "3a2e98ac-2b87-4225-82f4-2e83413948be",
            price: 69933.28,
            price_sources: [],
            processed_ms: 1711403605354,
          },
          {
            leverage: -2.5,
            order_type: "SHORT",
            order_uuid: "0ac6b30f-12a0-450f-9b74-20dd3c7e239d",
            price: 70353.59,
            price_sources: [],
            processed_ms: 1711405401758,
          },
          {
            leverage: -1.25,
            order_type: "SHORT",
            order_uuid: "f1adfd02-79cc-423b-b949-5e4620399098",
            price: 70361.41,
            price_sources: [],
            processed_ms: 1711407210682,
          },
          {
            leverage: -0.625,
            order_type: "SHORT",
            order_uuid: "fb92ac97-ff56-4b5c-904a-39d922511e75",
            price: 70329.67,
            price_sources: [],
            processed_ms: 1711409027323,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "efd48f79-0957-4afc-8da5-79da9dd562d6",
            price: 69958.15,
            price_sources: [],
            processed_ms: 1711410850209,
          },
          {
            leverage: -1.0625,
            order_type: "SHORT",
            order_uuid: "deacdb0f-570a-41b7-ab86-03b815369d36",
            price: 69816.64,
            price_sources: [],
            processed_ms: 1711412695006,
          },
          {
            leverage: -0.85,
            order_type: "SHORT",
            order_uuid: "76ff48a3-b38b-40f5-9874-72f23e9ae520",
            price: 69973.51,
            price_sources: [],
            processed_ms: 1711414471268,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "2abf3df3-b49b-4194-bb51-bbad01e7f02f",
            price: 70252.5,
            price_sources: [],
            processed_ms: 1711416292559,
          },
        ],
        position_type: "FLAT",
        position_uuid: "b20a79b9-4990-40b3-a331-8a8f86b8c732",
        return_at_close: 1.015120103618763,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 68873.04000000012,
        close_ms: 1711425389246,
        current_return: 0.9886738513757941,
        initial_entry_price: 70271.46,
        is_closed_position: true,
        miner_hotkey: "5Dvra51qhDpjZBs1ErmCtbQXXYGc9196q9GXxCNpmAfYKrib",
        net_leverage: 0.0,
        open_ms: 1711418123488,
        orders: [
          {
            leverage: -5.0,
            order_type: "SHORT",
            order_uuid: "e49248f9-ee9e-499b-b6e5-d197e6b174ef",
            price: 70271.46,
            price_sources: [],
            processed_ms: 1711418123485,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "f3a99f87-bdff-4734-a2e1-151e46c45181",
            price: 70237.09,
            price_sources: [],
            processed_ms: 1711419936421,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "83c081d2-9e7f-4271-a4e1-4459a0890954",
            price: 70533.15,
            price_sources: [],
            processed_ms: 1711421750517,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "86276730-eefc-4052-a192-fec314162c8b",
            price: 70510.28,
            price_sources: [],
            processed_ms: 1711423573435,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "3e582630-eb92-4442-b4f1-2fcb2d35e181",
            price: 70464.85,
            price_sources: [],
            processed_ms: 1711425389246,
          },
        ],
        position_type: "FLAT",
        position_uuid: "e208820a-b9e6-4a6a-9b34-f581c0223bb6",
        return_at_close: 0.9787871128620361,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 71554.70428571424,
        close_ms: 1711436280554,
        current_return: 1.0062189129186936,
        initial_entry_price: 70573.93,
        is_closed_position: true,
        miner_hotkey: "5Dvra51qhDpjZBs1ErmCtbQXXYGc9196q9GXxCNpmAfYKrib",
        net_leverage: 0.0,
        open_ms: 1711427212539,
        orders: [
          {
            leverage: -5.0,
            order_type: "SHORT",
            order_uuid: "71f913c9-03de-4d4f-91c3-e26227e89103",
            price: 70573.93,
            price_sources: [],
            processed_ms: 1711427212537,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "65975d05-2c17-4bf1-8887-04a7c31d0031",
            price: 70599.4,
            price_sources: [],
            processed_ms: 1711429011351,
          },
          {
            leverage: 1.75,
            order_type: "LONG",
            order_uuid: "dd91919c-6124-45a8-81f1-4b4729ef2961",
            price: 70451.04,
            price_sources: [],
            processed_ms: 1711430832018,
          },
          {
            leverage: 0.875,
            order_type: "LONG",
            order_uuid: "24e2aaeb-e49b-4479-b6ca-6faa7df222d6",
            price: 70378.89,
            price_sources: [],
            processed_ms: 1711432655910,
          },
          {
            leverage: 0.4375,
            order_type: "LONG",
            order_uuid: "52f3a7fe-5b9d-4e64-a620-dd0625c0f47a",
            price: 70387.47,
            price_sources: [],
            processed_ms: 1711434479245,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "aa23c436-b957-4895-9055-b4e1eff811d4",
            price: 70551.52,
            price_sources: [],
            processed_ms: 1711436280554,
          },
        ],
        position_type: "FLAT",
        position_uuid: "8dc39441-10b8-4735-85c1-30a7c4fb49c6",
        return_at_close: 0.9961567237895066,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 71324.21,
        close_ms: 1711439808107,
        current_return: 1.053636766534113,
        initial_entry_price: 71324.21,
        is_closed_position: true,
        miner_hotkey: "5Dvra51qhDpjZBs1ErmCtbQXXYGc9196q9GXxCNpmAfYKrib",
        net_leverage: 0.0,
        open_ms: 1711438103483,
        orders: [
          {
            leverage: -5.0,
            order_type: "SHORT",
            order_uuid: "218d4a4a-909e-4c29-b308-8e6776ecf1e8",
            price: 71324.21,
            price_sources: [],
            processed_ms: 1711438103480,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "9f972385-018d-44e8-a914-5e59035e3334",
            price: 70559.09,
            price_sources: [],
            processed_ms: 1711439808107,
          },
        ],
        position_type: "FLAT",
        position_uuid: "696288e6-16a2-4f7a-bd05-5d513dd51301",
        return_at_close: 1.043100398868772,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 67655.27000000002,
        close_ms: 1711447170911,
        current_return: 0.9749985493883953,
        initial_entry_price: 70487.51,
        is_closed_position: true,
        miner_hotkey: "5Dvra51qhDpjZBs1ErmCtbQXXYGc9196q9GXxCNpmAfYKrib",
        net_leverage: 0.0,
        open_ms: 1711439914383,
        orders: [
          {
            leverage: -5.0,
            order_type: "SHORT",
            order_uuid: "a6eeb4d3-59f6-47b7-bda4-1e4538446a03",
            price: 70487.51,
            price_sources: [],
            processed_ms: 1711439914381,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "cbfb010f-0ff4-4c0f-bd65-bdf2b5219a7c",
            price: 70626.87,
            price_sources: [],
            processed_ms: 1711441730558,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "31ed5b6e-09f2-4afa-a251-8cc3c7738e1e",
            price: 70658.76,
            price_sources: [],
            processed_ms: 1711443565655,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "afe0608a-73d4-48db-80fc-5da3bf0b256d",
            price: 71120.98,
            price_sources: [],
            processed_ms: 1711445359225,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "067f03b2-b86f-4ceb-a1dc-b78b328fd10d",
            price: 71179.85,
            price_sources: [],
            processed_ms: 1711447170911,
          },
        ],
        position_type: "FLAT",
        position_uuid: "5c2614f7-1074-4ff1-b021-7b646ff92853",
        return_at_close: 0.9652485638945113,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 71982.21999999994,
        close_ms: 1711458092607,
        current_return: 1.004024153383293,
        initial_entry_price: 70896.9,
        is_closed_position: true,
        miner_hotkey: "5Dvra51qhDpjZBs1ErmCtbQXXYGc9196q9GXxCNpmAfYKrib",
        net_leverage: 0.0,
        open_ms: 1711449010270,
        orders: [
          {
            leverage: -5.0,
            order_type: "SHORT",
            order_uuid: "9420a6ea-0835-486a-9876-8f21a5638291",
            price: 70896.9,
            price_sources: [],
            processed_ms: 1711449010267,
          },
          {
            leverage: 2.5,
            order_type: "LONG",
            order_uuid: "9ec824d4-0dd3-4112-b2a7-5a96a283e794",
            price: 70900.0,
            price_sources: [],
            processed_ms: 1711450810383,
          },
          {
            leverage: 1.25,
            order_type: "LONG",
            order_uuid: "9d0edbf1-1886-4c5a-a64a-d3b2fca6703e",
            price: 70737.53,
            price_sources: [],
            processed_ms: 1711452631396,
          },
          {
            leverage: 0.625,
            order_type: "LONG",
            order_uuid: "b9290ca2-a199-431b-8b7f-3b1806aff54e",
            price: 70700.82,
            price_sources: [],
            processed_ms: 1711454459686,
          },
          {
            leverage: 0.3125,
            order_type: "LONG",
            order_uuid: "408b975b-0cbb-4a32-ae92-64d565a720b4",
            price: 70816.42,
            price_sources: [],
            processed_ms: 1711456279407,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "9cb2aba4-b8f9-4bd1-ac4c-c939db19ca51",
            price: 71069.26,
            price_sources: [],
            processed_ms: 1711458092607,
          },
        ],
        position_type: "FLAT",
        position_uuid: "fa5a88d9-b288-44b3-b3ef-aa46d6c507bf",
        return_at_close: 0.99398391184946,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 75962.36000000004,
        close_ms: 1711465355372,
        current_return: 1.0416096961176426,
        initial_entry_price: 70730.99,
        is_closed_position: true,
        miner_hotkey: "5Dvra51qhDpjZBs1ErmCtbQXXYGc9196q9GXxCNpmAfYKrib",
        net_leverage: 0.0,
        open_ms: 1711459902118,
        orders: [
          {
            leverage: -5.0,
            order_type: "SHORT",
            order_uuid: "93a1ba66-7f44-4e3f-a80e-fb2d58519bf9",
            price: 70730.99,
            price_sources: [],
            processed_ms: 1711459902115,
          },
          {
            leverage: 2.5,
            order_type: "LONG",
            order_uuid: "47012535-08c4-4393-8196-9645d00098f1",
            price: 70463.86,
            price_sources: [],
            processed_ms: 1711461739754,
          },
          {
            leverage: 2.0,
            order_type: "LONG",
            order_uuid: "773f398d-b9f8-4d7c-ab08-bfb1fe0c167e",
            price: 69757.06,
            price_sources: [],
            processed_ms: 1711463532541,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "530fdba0-c799-4b11-83d4-964dd31003c3",
            price: 70076.17,
            price_sources: [],
            processed_ms: 1711465355372,
          },
        ],
        position_type: "FLAT",
        position_uuid: "c4897d94-71cf-44b3-b4b5-0aeba9d45265",
        return_at_close: 1.0311935991564662,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 68172.64818181821,
        close_ms: 1711478073691,
        current_return: 1.020857924763897,
        initial_entry_price: 70019.31,
        is_closed_position: true,
        miner_hotkey: "5Dvra51qhDpjZBs1ErmCtbQXXYGc9196q9GXxCNpmAfYKrib",
        net_leverage: 0.0,
        open_ms: 1711467214487,
        orders: [
          {
            leverage: 5.0,
            order_type: "LONG",
            order_uuid: "41fa4309-72e0-41e4-9b15-032290dee189",
            price: 70019.31,
            price_sources: [],
            processed_ms: 1711467214485,
          },
          {
            leverage: -2.5,
            order_type: "SHORT",
            order_uuid: "14bf08a4-25f6-4c8b-92dc-e1313048dcdb",
            price: 70474.14,
            price_sources: [],
            processed_ms: 1711468989314,
          },
          {
            leverage: -1.25,
            order_type: "SHORT",
            order_uuid: "4444ac0c-40ee-40d2-8df8-cf406750d41e",
            price: 69867.72,
            price_sources: [],
            processed_ms: 1711470803403,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "494b90c5-6ca9-44ba-ba3b-55d2996f8b4e",
            price: 69866.89,
            price_sources: [],
            processed_ms: 1711472628613,
          },
          {
            leverage: -1.375,
            order_type: "SHORT",
            order_uuid: "88baa4df-6e06-4ff8-b0d0-c161ce5b3331",
            price: 70024.86,
            price_sources: [],
            processed_ms: 1711474485007,
          },
          {
            leverage: -0.6875,
            order_type: "SHORT",
            order_uuid: "3db1a00e-9ceb-49e2-bd67-83ef33a07585",
            price: 70144.01,
            price_sources: [],
            processed_ms: 1711476269467,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "1c8b852f-3de2-404b-8c25-503fa1f7e870",
            price: 70296.95,
            price_sources: [],
            processed_ms: 1711478073691,
          },
        ],
        position_type: "FLAT",
        position_uuid: "5025247a-e5e4-4a0b-94bb-76e0e1686ef8",
        return_at_close: 1.010649345516258,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 62673.18692307683,
        close_ms: 1711494422753,
        current_return: 1.0219864955328808,
        initial_entry_price: 69708.23,
        is_closed_position: true,
        miner_hotkey: "5Dvra51qhDpjZBs1ErmCtbQXXYGc9196q9GXxCNpmAfYKrib",
        net_leverage: 0.0,
        open_ms: 1711481703755,
        orders: [
          {
            leverage: 5.0,
            order_type: "LONG",
            order_uuid: "dee5458b-2b80-4f6f-95b8-1e52073e6419",
            price: 69708.23,
            price_sources: [],
            processed_ms: 1711481703752,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "b0cea8f0-e148-4d23-b362-1b50a84f4336",
            price: 69631.99,
            price_sources: [],
            processed_ms: 1711483522522,
          },
          {
            leverage: -3.25,
            order_type: "SHORT",
            order_uuid: "6e5ee9b2-3196-43b4-a263-070f85b395d8",
            price: 69845.37,
            price_sources: [],
            processed_ms: 1711485341599,
          },
          {
            leverage: -1.625,
            order_type: "SHORT",
            order_uuid: "f8d3c6ae-8342-43bb-8e38-856970751ff1",
            price: 69909.8,
            price_sources: [],
            processed_ms: 1711487167283,
          },
          {
            leverage: -0.8125,
            order_type: "SHORT",
            order_uuid: "8bbddeff-1247-4f88-bac1-bc2c741372ff",
            price: 70176.94,
            price_sources: [],
            processed_ms: 1711488970457,
          },
          {
            leverage: -0.40625,
            order_type: "SHORT",
            order_uuid: "4645515b-e6fc-452a-916d-4ac9e92f287e",
            price: 70001.13,
            price_sources: [],
            processed_ms: 1711490820136,
          },
          {
            leverage: -0.203125,
            order_type: "SHORT",
            order_uuid: "0f35b8f9-11c6-4258-b710-6bc8f9753d9c",
            price: 69912.83,
            price_sources: [],
            processed_ms: 1711492611357,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "f61085a4-cf6e-44cf-8fb7-adf883d8984a",
            price: 70218.49,
            price_sources: [],
            processed_ms: 1711494422753,
          },
        ],
        position_type: "FLAT",
        position_uuid: "96c7e4c9-b530-496b-8621-bdbc78105f52",
        return_at_close: 1.0087006710909534,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 69842.48999999987,
        close_ms: 1711518040324,
        current_return: 1.0030868443983638,
        initial_entry_price: 70474.58,
        is_closed_position: true,
        miner_hotkey: "5Dvra51qhDpjZBs1ErmCtbQXXYGc9196q9GXxCNpmAfYKrib",
        net_leverage: 0.0,
        open_ms: 1711501689442,
        orders: [
          {
            leverage: 5.0,
            order_type: "LONG",
            order_uuid: "271cf7de-fa01-468b-85fb-a8dcfc2936fe",
            price: 70474.58,
            price_sources: [],
            processed_ms: 1711501689439,
          },
          {
            leverage: -2.5,
            order_type: "SHORT",
            order_uuid: "a35eea66-5aea-44a6-a764-e649a8e7c5ed",
            price: 70571.91,
            price_sources: [],
            processed_ms: 1711503506564,
          },
          {
            leverage: -1.25,
            order_type: "SHORT",
            order_uuid: "adb7ef7a-b039-483f-90a6-576e3de25a95",
            price: 70273.24,
            price_sources: [],
            processed_ms: 1711505319125,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "6e67ee50-33b7-4e20-bf00-63eb097d2246",
            price: 70350.01,
            price_sources: [],
            processed_ms: 1711507164057,
          },
          {
            leverage: -1.375,
            order_type: "SHORT",
            order_uuid: "bc6ed80c-b10e-40b9-8e25-b71e12d21413",
            price: 70594.49,
            price_sources: [],
            processed_ms: 1711508960501,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "7fbcbe37-0cb1-44b3-a3c0-ec9ab323c084",
            price: 70420.16,
            price_sources: [],
            processed_ms: 1711510778464,
          },
          {
            leverage: -1.4375,
            order_type: "SHORT",
            order_uuid: "208a3106-bee7-46c9-832f-d47f4f386042",
            price: 70417.8,
            price_sources: [],
            processed_ms: 1711512593270,
          },
          {
            leverage: -0.71875,
            order_type: "SHORT",
            order_uuid: "b6b838a1-6a2a-440e-b923-c02489a036c1",
            price: 70422.17,
            price_sources: [],
            processed_ms: 1711514420114,
          },
          {
            leverage: -0.359375,
            order_type: "SHORT",
            order_uuid: "7df05600-33c5-45c7-aca7-07bd2e106657",
            price: 70255.97,
            price_sources: [],
            processed_ms: 1711516227119,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "1a654b96-7ed2-479e-ae1f-aaf8cd6a653a",
            price: 70447.83,
            price_sources: [],
            processed_ms: 1711518040324,
          },
        ],
        position_type: "FLAT",
        position_uuid: "93d28bab-b332-4b6d-9a54-efb51193941a",
        return_at_close: 0.9930559759543802,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 76052.85428571423,
        close_ms: 1711530612199,
        current_return: 1.0392886859192803,
        initial_entry_price: 70529.99,
        is_closed_position: true,
        miner_hotkey: "5Dvra51qhDpjZBs1ErmCtbQXXYGc9196q9GXxCNpmAfYKrib",
        net_leverage: 0.0,
        open_ms: 1711519894295,
        orders: [
          {
            leverage: -5.0,
            order_type: "SHORT",
            order_uuid: "24f5cad2-33e0-4380-b7dd-d603ae6c97d4",
            price: 70529.99,
            price_sources: [],
            processed_ms: 1711519894292,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "67e80d57-ad89-403d-af8d-9b444470e95f",
            price: 70273.99,
            price_sources: [],
            processed_ms: 1711521670232,
          },
          {
            leverage: 1.75,
            order_type: "LONG",
            order_uuid: "cae76e2e-f8bf-4c2a-a5d6-d2b0b2b81e0d",
            price: 69853.58,
            price_sources: [],
            processed_ms: 1711525168466,
          },
          {
            leverage: 0.875,
            order_type: "LONG",
            order_uuid: "55de2c5e-6a59-45f1-a448-f1050cd8e2cb",
            price: 69917.86,
            price_sources: [],
            processed_ms: 1711526987236,
          },
          {
            leverage: 0.4375,
            order_type: "LONG",
            order_uuid: "55fd55f4-55f2-4271-99b1-2075655a8bb3",
            price: 69814.74,
            price_sources: [],
            processed_ms: 1711528792484,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "8597c05c-37d8-411b-a40e-10d0e643b6ed",
            price: 69719.07,
            price_sources: [],
            processed_ms: 1711530612199,
          },
        ],
        position_type: "FLAT",
        position_uuid: "a26d3fa9-9bf3-4b70-a146-435c59a49561",
        return_at_close: 1.0288957990600875,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 69895.84000000005,
        close_ms: 1711546336644,
        current_return: 0.9689991624121854,
        initial_entry_price: 70129.96,
        is_closed_position: true,
        miner_hotkey: "5Dvra51qhDpjZBs1ErmCtbQXXYGc9196q9GXxCNpmAfYKrib",
        net_leverage: 0.0,
        open_ms: 1711536979486,
        orders: [
          {
            leverage: -5.0,
            order_type: "SHORT",
            order_uuid: "04d6d513-b748-420f-b638-1eba68c457b6",
            price: 70129.96,
            price_sources: [],
            processed_ms: 1711536979482,
          },
          {
            leverage: 2.5,
            order_type: "LONG",
            order_uuid: "47b7779d-35a3-4544-b581-73607f367919",
            price: 70260.72,
            price_sources: [],
            processed_ms: 1711540609602,
          },
          {
            leverage: 1.25,
            order_type: "LONG",
            order_uuid: "b9d678a3-e548-4c55-8f76-1b32b57ffb34",
            price: 70102.56,
            price_sources: [],
            processed_ms: 1711544219278,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "b2f273da-5b6b-41c4-a5fb-1cb7f54ba7ff",
            price: 71635.11,
            price_sources: [],
            processed_ms: 1711546336644,
          },
        ],
        position_type: "FLAT",
        position_uuid: "45bdb7e2-e682-4a95-8315-d95acaa68fdc",
        return_at_close: 0.9593091707880635,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 70003.28,
        close_ms: 1711548301041,
        current_return: 0.9378657685754155,
        initial_entry_price: 70003.28,
        is_closed_position: true,
        miner_hotkey: "5Dvra51qhDpjZBs1ErmCtbQXXYGc9196q9GXxCNpmAfYKrib",
        net_leverage: 0.0,
        open_ms: 1711547834253,
        orders: [
          {
            leverage: 5.0,
            order_type: "LONG",
            order_uuid: "9d2e3e1f-366f-4c6a-a14a-aba41f6a75db",
            price: 70003.28,
            price_sources: [],
            processed_ms: 1711547834251,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "cdcfaecb-bdc6-4bee-9503-3540361e1a47",
            price: 69133.36,
            price_sources: [],
            processed_ms: 1711548301041,
          },
        ],
        position_type: "FLAT",
        position_uuid: "753c9f84-eccb-43d9-8aca-6dcdf0063f1b",
        return_at_close: 0.9284871108896613,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
    ],
    thirty_day_returns: 1.0,
  },
  "5Dxqzduahnqw8q3XSUfTcEZGU7xmAsfJubhHZwvXVLN9fSjR": {
    all_time_returns: 1.1026189252589107,
    n_positions: 461,
    percentage_profitable: 0.7288503253796096,
    positions: [
      {
        average_entry_price: 0.8916069638666667,
        close_ms: 1712812242857,
        current_return: 1.000413933229413,
        initial_entry_price: 0.89171,
        is_closed_position: true,
        miner_hotkey: "5Dxqzduahnqw8q3XSUfTcEZGU7xmAsfJubhHZwvXVLN9fSjR",
        net_leverage: 0.0,
        open_ms: 1712810892501,
        orders: [
          {
            leverage: 1.0,
            order_type: "LONG",
            order_uuid: "98b6914b-5807-4aec-bd3d-c691f0e79373",
            price: 0.89171,
            price_sources: [],
            processed_ms: 1712810892501,
          },
          {
            leverage: 1.0,
            order_type: "LONG",
            order_uuid: "8a756f6a-41f4-43c6-b23a-95a7b74686f7",
            price: 0.8915308916,
            price_sources: [],
            processed_ms: 1712811432694,
          },
          {
            leverage: 1.0,
            order_type: "LONG",
            order_uuid: "261bc342-b59b-4035-b642-9cfe7ee21e2c",
            price: 0.89158,
            price_sources: [],
            processed_ms: 1712811613881,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "2b044857-367b-4af0-8f5b-99fa47acb999",
            price: 0.89173,
            price_sources: [],
            processed_ms: 1712812242857,
          },
        ],
        position_type: "FLAT",
        position_uuid: "4df3d424-0153-4def-b0c8-afa7168fd6f9",
        return_at_close: 1.0002038463034348,
        trade_pair: ["AUDCAD", "AUD/CAD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.89200762815,
        close_ms: 1712813830375,
        current_return: 0.9998793135792778,
        initial_entry_price: 0.8918,
        is_closed_position: true,
        miner_hotkey: "5Dxqzduahnqw8q3XSUfTcEZGU7xmAsfJubhHZwvXVLN9fSjR",
        net_leverage: 0.0,
        open_ms: 1712813233403,
        orders: [
          {
            leverage: 0.5,
            order_type: "LONG",
            order_uuid: "94cc5431-c814-40c0-b3e9-95e61a4e055f",
            price: 0.8918,
            price_sources: [],
            processed_ms: 1712813233357,
          },
          {
            leverage: 0.5,
            order_type: "LONG",
            order_uuid: "7bd6a018-c838-4f3e-a1e7-34718aaff3fe",
            price: 0.8922152563,
            price_sources: [],
            processed_ms: 1712813414169,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "bc17093d-5ba3-44a5-a3d2-b6f999ffb65f",
            price: 0.8919,
            price_sources: [],
            processed_ms: 1712813830375,
          },
        ],
        position_type: "FLAT",
        position_uuid: "c171544a-c37c-4905-aa03-ff0ca380b96f",
        return_at_close: 0.9998093220273273,
        trade_pair: ["AUDCAD", "AUD/CAD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.89211,
        close_ms: 1712816086150,
        current_return: 1.000179364154074,
        initial_entry_price: 0.89204,
        is_closed_position: true,
        miner_hotkey: "5Dxqzduahnqw8q3XSUfTcEZGU7xmAsfJubhHZwvXVLN9fSjR",
        net_leverage: 0.0,
        open_ms: 1712815574491,
        orders: [
          {
            leverage: 0.5,
            order_type: "LONG",
            order_uuid: "574a6136-8049-4ded-b937-e5b621162aa4",
            price: 0.89204,
            price_sources: [],
            processed_ms: 1712815574490,
          },
          {
            leverage: 0.5,
            order_type: "LONG",
            order_uuid: "f8fc4be1-cb49-4bec-9a25-381313cf9f0f",
            price: 0.89218,
            price_sources: [],
            processed_ms: 1712815750304,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "87060c8d-86fb-409d-9397-487553ebe7f2",
            price: 0.89227,
            price_sources: [],
            processed_ms: 1712816086150,
          },
        ],
        position_type: "FLAT",
        position_uuid: "dc2cbccf-ebdf-46ca-8670-b9d53565449a",
        return_at_close: 1.000109351598583,
        trade_pair: ["AUDCAD", "AUD/CAD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.8940825541,
        close_ms: 1712857550692,
        current_return: 1.000332682813948,
        initial_entry_price: 0.8940825541,
        is_closed_position: true,
        miner_hotkey: "5Dxqzduahnqw8q3XSUfTcEZGU7xmAsfJubhHZwvXVLN9fSjR",
        net_leverage: 0.0,
        open_ms: 1712856897016,
        orders: [
          {
            leverage: 1.0,
            order_type: "LONG",
            order_uuid: "9bd35cee-560f-41d1-8795-1b046b635ae5",
            price: 0.8940825541,
            price_sources: [],
            processed_ms: 1712856897016,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "0fb8a1fc-bff8-4175-b9d0-fb104d193762",
            price: 0.89438,
            price_sources: [],
            processed_ms: 1712857550692,
          },
        ],
        position_type: "FLAT",
        position_uuid: "e33304d3-db0a-478f-bde9-c660080a9905",
        return_at_close: 1.000262659526151,
        trade_pair: ["AUDCAD", "AUD/CAD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.89499,
        close_ms: 1712860680922,
        current_return: 0.9999068434284183,
        initial_entry_price: 0.89499,
        is_closed_position: true,
        miner_hotkey: "5Dxqzduahnqw8q3XSUfTcEZGU7xmAsfJubhHZwvXVLN9fSjR",
        net_leverage: 0.0,
        open_ms: 1712859988102,
        orders: [
          {
            leverage: 1.0,
            order_type: "LONG",
            order_uuid: "7b43e41d-9546-4af8-89ee-6b435182d993",
            price: 0.89499,
            price_sources: [],
            processed_ms: 1712859988101,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "55c7ed1e-21e6-4aa6-b675-7b4b3f555b86",
            price: 0.8949066258,
            price_sources: [],
            processed_ms: 1712860680922,
          },
        ],
        position_type: "FLAT",
        position_uuid: "7440a0b4-adbc-47b0-b0fd-381a15d580df",
        return_at_close: 0.9998368499493783,
        trade_pair: ["AUDCAD", "AUD/CAD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.6541,
        close_ms: 1712861068938,
        current_return: 1.0005045100137593,
        initial_entry_price: 0.6541,
        is_closed_position: true,
        miner_hotkey: "5Dxqzduahnqw8q3XSUfTcEZGU7xmAsfJubhHZwvXVLN9fSjR",
        net_leverage: 0.0,
        open_ms: 1712859278721,
        orders: [
          {
            leverage: 1.0,
            order_type: "LONG",
            order_uuid: "3eae0aa6-c6a2-41d5-a969-fcee9610f291",
            price: 0.6541,
            price_sources: [],
            processed_ms: 1712859278720,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "2445f46f-5b8f-46a9-9e50-72df79b7166d",
            price: 0.65443,
            price_sources: [],
            processed_ms: 1712861068938,
          },
        ],
        position_type: "FLAT",
        position_uuid: "5535c459-8e37-40ba-9092-09eee9dcd858",
        return_at_close: 1.0004344746980582,
        trade_pair: ["AUDUSD", "AUD/USD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 91.857,
        close_ms: 1712862674680,
        current_return: 1.0002068432454794,
        initial_entry_price: 91.857,
        is_closed_position: true,
        miner_hotkey: "5Dxqzduahnqw8q3XSUfTcEZGU7xmAsfJubhHZwvXVLN9fSjR",
        net_leverage: 0.0,
        open_ms: 1712862168625,
        orders: [
          {
            leverage: 1.0,
            order_type: "LONG",
            order_uuid: "2d116e2e-79a7-4da9-809a-7fb912d6c051",
            price: 91.857,
            price_sources: [],
            processed_ms: 1712862168622,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "ca4e4111-6ce6-42ea-82d7-5eab97fd56ed",
            price: 91.876,
            price_sources: [],
            processed_ms: 1712862674680,
          },
        ],
        position_type: "FLAT",
        position_uuid: "c1354128-b7cc-4067-ac99-d395014f2f70",
        return_at_close: 1.0001368287664523,
        trade_pair: ["NZDJPY", "NZD/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.8945997018,
        close_ms: 1712862915884,
        current_return: 0.9998661951264246,
        initial_entry_price: 0.8945997018,
        is_closed_position: true,
        miner_hotkey: "5Dxqzduahnqw8q3XSUfTcEZGU7xmAsfJubhHZwvXVLN9fSjR",
        net_leverage: 0.0,
        open_ms: 1712862587204,
        orders: [
          {
            leverage: 1.0,
            order_type: "LONG",
            order_uuid: "34d41762-37f7-408f-970f-22c95a11e947",
            price: 0.8945997018,
            price_sources: [],
            processed_ms: 1712862587203,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "5d3d6f17-04f5-4de1-bd34-ad3037dd3204",
            price: 0.89448,
            price_sources: [],
            processed_ms: 1712862915884,
          },
        ],
        position_type: "FLAT",
        position_uuid: "035e3187-bd46-434d-9f71-0754be74821d",
        return_at_close: 0.9997962044927657,
        trade_pair: ["AUDCAD", "AUD/CAD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.5997966666666668,
        close_ms: 1712864781161,
        current_return: 1.002017170959406,
        initial_entry_price: 0.59985,
        is_closed_position: true,
        miner_hotkey: "5Dxqzduahnqw8q3XSUfTcEZGU7xmAsfJubhHZwvXVLN9fSjR",
        net_leverage: 0.0,
        open_ms: 1712861310972,
        orders: [
          {
            leverage: 1.0,
            order_type: "LONG",
            order_uuid: "42ee585e-8c00-4d5c-8a70-6c4ebd3bd2b1",
            price: 0.59985,
            price_sources: [],
            processed_ms: 1712861310971,
          },
          {
            leverage: 1.0,
            order_type: "LONG",
            order_uuid: "a2f586c1-99af-4841-80c1-15e3ec0f1084",
            price: 0.5998,
            price_sources: [],
            processed_ms: 1712861440398,
          },
          {
            leverage: 1.0,
            order_type: "LONG",
            order_uuid: "05f1a9cc-e832-455f-8d14-3908d4e81fb5",
            price: 0.59974,
            price_sources: [],
            processed_ms: 1712861501521,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "efb3e187-7894-43db-9ba5-f6bab8c3715e",
            price: 0.6002,
            price_sources: [],
            processed_ms: 1712864781161,
          },
        ],
        position_type: "FLAT",
        position_uuid: "4639efa2-c0aa-41ef-a797-47e0ec3c2c97",
        return_at_close: 1.0018067473535044,
        trade_pair: ["NZDUSD", "NZD/USD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.89473466635,
        close_ms: 1712865045915,
        current_return: 1.0001252749058311,
        initial_entry_price: 0.89467,
        is_closed_position: true,
        miner_hotkey: "5Dxqzduahnqw8q3XSUfTcEZGU7xmAsfJubhHZwvXVLN9fSjR",
        net_leverage: 0.0,
        open_ms: 1712864397203,
        orders: [
          {
            leverage: 1.0,
            order_type: "LONG",
            order_uuid: "20c0dc11-fe79-4374-b477-294d0d54fe06",
            price: 0.89467,
            price_sources: [],
            processed_ms: 1712864397202,
          },
          {
            leverage: 1.0,
            order_type: "LONG",
            order_uuid: "9677c8ef-c49b-4791-9140-736f4679b5c0",
            price: 0.8947993327,
            price_sources: [],
            processed_ms: 1712864717202,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "8c527377-e07a-4061-b9f0-95f437546677",
            price: 0.8947907062,
            price_sources: [],
            processed_ms: 1712865045915,
          },
        ],
        position_type: "FLAT",
        position_uuid: "eb42d1ad-124a-4e44-bf5b-6aef95abd72f",
        return_at_close: 0.9999852573673443,
        trade_pair: ["AUDCAD", "AUD/CAD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.8946708858333333,
        close_ms: 1712867692556,
        current_return: 1.0001982102881715,
        initial_entry_price: 0.8947189454,
        is_closed_position: true,
        miner_hotkey: "5Dxqzduahnqw8q3XSUfTcEZGU7xmAsfJubhHZwvXVLN9fSjR",
        net_leverage: 0.0,
        open_ms: 1712866523008,
        orders: [
          {
            leverage: 1.0,
            order_type: "LONG",
            order_uuid: "24261c24-d44f-430e-a36f-da32ba480d4a",
            price: 0.8947189454,
            price_sources: [],
            processed_ms: 1712866521583,
          },
          {
            leverage: 1.0,
            order_type: "LONG",
            order_uuid: "16a975b5-c470-4d3d-976f-6c2a447ebcde",
            price: 0.8946549413,
            price_sources: [],
            processed_ms: 1712866692419,
          },
          {
            leverage: 1.0,
            order_type: "LONG",
            order_uuid: "813ea651-3afa-4f5d-8c38-e27daee7f20b",
            price: 0.8946387708,
            price_sources: [],
            processed_ms: 1712867053151,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "4e65af1d-ae9f-428f-8a02-d8476293063f",
            price: 0.89473,
            price_sources: [],
            processed_ms: 1712867692556,
          },
        ],
        position_type: "FLAT",
        position_uuid: "4c28c088-9314-4a5e-a907-8ab3c085ca0c",
        return_at_close: 0.9999881686640109,
        trade_pair: ["AUDCAD", "AUD/CAD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 100.122,
        close_ms: 1712870024897,
        current_return: 1.0005992688919518,
        initial_entry_price: 100.122,
        is_closed_position: true,
        miner_hotkey: "5Dxqzduahnqw8q3XSUfTcEZGU7xmAsfJubhHZwvXVLN9fSjR",
        net_leverage: 0.0,
        open_ms: 1712869202566,
        orders: [
          {
            leverage: 1.0,
            order_type: "LONG",
            order_uuid: "471a1f1e-cb58-4bec-8be5-5c2a78d5a62a",
            price: 100.122,
            price_sources: [],
            processed_ms: 1712869202565,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "aecd4169-2eeb-46e5-823c-980b5485837b",
            price: 100.182,
            price_sources: [],
            processed_ms: 1712870024897,
          },
        ],
        position_type: "FLAT",
        position_uuid: "e7672b34-5576-4e2d-8cc6-18396865ef95",
        return_at_close: 1.0005292269431294,
        trade_pair: ["AUDJPY", "AUD/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 91.88,
        close_ms: 1712871135551,
        current_return: 1.0002829777971267,
        initial_entry_price: 91.88,
        is_closed_position: true,
        miner_hotkey: "5Dxqzduahnqw8q3XSUfTcEZGU7xmAsfJubhHZwvXVLN9fSjR",
        net_leverage: 0.0,
        open_ms: 1712870122214,
        orders: [
          {
            leverage: 1.0,
            order_type: "LONG",
            order_uuid: "da12d891-0223-4fd6-8ead-c07b573e46e2",
            price: 91.88,
            price_sources: [],
            processed_ms: 1712870122213,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "b6fda210-3698-4995-b2d5-b513d57ff2e6",
            price: 91.906,
            price_sources: [],
            processed_ms: 1712871135551,
          },
        ],
        position_type: "FLAT",
        position_uuid: "d09883db-5583-432b-8d89-d8b0d8a9c7dd",
        return_at_close: 1.000212957988681,
        trade_pair: ["NZDJPY", "NZD/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.894245,
        close_ms: 1712873849628,
        current_return: 1.0001901374581976,
        initial_entry_price: 0.89409,
        is_closed_position: true,
        miner_hotkey: "5Dxqzduahnqw8q3XSUfTcEZGU7xmAsfJubhHZwvXVLN9fSjR",
        net_leverage: 0.0,
        open_ms: 1712872453801,
        orders: [
          {
            leverage: 1.0,
            order_type: "LONG",
            order_uuid: "9cdf344e-58fb-419b-abfc-28cec8bdacd0",
            price: 0.89409,
            price_sources: [],
            processed_ms: 1712872453800,
          },
          {
            leverage: 1.0,
            order_type: "LONG",
            order_uuid: "3bf2c4a5-5630-430b-90f2-edb469723238",
            price: 0.8944,
            price_sources: [],
            processed_ms: 1712873355248,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "c1853397-c001-419e-a170-098c42a921c7",
            price: 0.89433,
            price_sources: [],
            processed_ms: 1712873849628,
          },
        ],
        position_type: "FLAT",
        position_uuid: "9e776d01-5407-43b9-ad30-34646df5ff57",
        return_at_close: 1.0000501108389535,
        trade_pair: ["AUDCAD", "AUD/CAD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.894435,
        close_ms: 1712876303757,
        current_return: 1.0036541212229575,
        initial_entry_price: 0.89488,
        is_closed_position: true,
        miner_hotkey: "5Dxqzduahnqw8q3XSUfTcEZGU7xmAsfJubhHZwvXVLN9fSjR",
        net_leverage: 0.0,
        open_ms: 1712875156204,
        orders: [
          {
            leverage: 3.0,
            order_type: "LONG",
            order_uuid: "d6725065-1ff7-49d9-8a31-01c5f6d06a04",
            price: 0.89488,
            price_sources: [],
            processed_ms: 1712875156203,
          },
          {
            leverage: 3.0,
            order_type: "LONG",
            order_uuid: "cd1701f0-e1f5-40aa-afb7-fcf8656023f2",
            price: 0.89399,
            price_sources: [],
            processed_ms: 1712875690787,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "d6ef05f4-09f3-4f6d-98b8-735c6d1a5b01",
            price: 0.89498,
            price_sources: [],
            processed_ms: 1712876303757,
          },
        ],
        position_type: "FLAT",
        position_uuid: "39e46aea-88a5-4a4e-b53a-b90213a18c06",
        return_at_close: 1.003232586492044,
        trade_pair: ["AUDCAD", "AUD/CAD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.59979,
        close_ms: 1712881888483,
        current_return: 1.00053351117039,
        initial_entry_price: 0.5998,
        is_closed_position: true,
        miner_hotkey: "5Dxqzduahnqw8q3XSUfTcEZGU7xmAsfJubhHZwvXVLN9fSjR",
        net_leverage: 0.0,
        open_ms: 1712880052246,
        orders: [
          {
            leverage: 1.0,
            order_type: "LONG",
            order_uuid: "c505ed9a-0490-497c-a9b0-4f8a2cac05d2",
            price: 0.5998,
            price_sources: [],
            processed_ms: 1712880052245,
          },
          {
            leverage: 1.0,
            order_type: "LONG",
            order_uuid: "058cacc3-6c4a-4369-bd55-c57fa9b19a79",
            price: 0.59978,
            price_sources: [],
            processed_ms: 1712880770539,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "6b45332f-6894-47be-b471-1a770c3d7e4e",
            price: 0.59995,
            price_sources: [],
            processed_ms: 1712881888483,
          },
        ],
        position_type: "FLAT",
        position_uuid: "99ecb00d-83ec-4381-94d0-4f46b9214a21",
        return_at_close: 1.000393436478826,
        trade_pair: ["NZDUSD", "NZD/USD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 91.904,
        close_ms: 1712882118599,
        current_return: 1.0004134569351961,
        initial_entry_price: 91.908,
        is_closed_position: true,
        miner_hotkey: "5Dxqzduahnqw8q3XSUfTcEZGU7xmAsfJubhHZwvXVLN9fSjR",
        net_leverage: 0.0,
        open_ms: 1712879269623,
        orders: [
          {
            leverage: 1.0,
            order_type: "LONG",
            order_uuid: "cd2955a5-e03d-43b8-abfb-069f5949a247",
            price: 91.908,
            price_sources: [],
            processed_ms: 1712879269622,
          },
          {
            leverage: 1.0,
            order_type: "LONG",
            order_uuid: "565ce09c-fc7e-463b-9654-953ec0d90b45",
            price: 91.9,
            price_sources: [],
            processed_ms: 1712880514206,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "f7bc46fc-c687-4f7b-83b4-6b0ee9166b8f",
            price: 91.923,
            price_sources: [],
            processed_ms: 1712882118599,
          },
        ],
        position_type: "FLAT",
        position_uuid: "73bf839a-e9f2-4622-9346-05e3085430b3",
        return_at_close: 1.0002733990512251,
        trade_pair: ["NZDJPY", "NZD/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 1.1802199999999916,
        close_ms: 1712882836235,
        current_return: 0.9968088593981006,
        initial_entry_price: 0.89417,
        is_closed_position: true,
        miner_hotkey: "5Dxqzduahnqw8q3XSUfTcEZGU7xmAsfJubhHZwvXVLN9fSjR",
        net_leverage: 0.0,
        open_ms: 1712879110529,
        orders: [
          {
            leverage: 10.0,
            order_type: "LONG",
            order_uuid: "bc1180d2-a3d9-4da8-93c4-4994eefb383e",
            price: 0.89417,
            price_sources: [],
            processed_ms: 1712879110527,
          },
          {
            leverage: -9.99,
            order_type: "SHORT",
            order_uuid: "6df74a0f-e933-4fd5-8c93-15a8a2629d77",
            price: 0.89522,
            price_sources: [],
            processed_ms: 1712879770136,
          },
          {
            leverage: 15.0,
            order_type: "LONG",
            order_uuid: "47d09a2c-f14d-483a-91c1-2f9c78c5bb76",
            price: 0.89476,
            price_sources: [],
            processed_ms: 1712880371099,
          },
          {
            leverage: -15.0,
            order_type: "SHORT",
            order_uuid: "871bcd19-2cbc-4600-8003-47161b40a1ac",
            price: 0.8944,
            price_sources: [],
            processed_ms: 1712880672461,
          },
          {
            leverage: 15.0,
            order_type: "LONG",
            order_uuid: "1be1f829-564d-4041-853d-9166967742f4",
            price: 0.89475,
            price_sources: [],
            processed_ms: 1712881811352,
          },
          {
            leverage: 15.0,
            order_type: "LONG",
            order_uuid: "eb82c761-93a0-4bcb-a3b3-0318cf875745",
            price: 0.89478,
            price_sources: [],
            processed_ms: 1712881991686,
          },
          {
            leverage: -29.999999999999996,
            order_type: "SHORT",
            order_uuid: "66e5eab0-9ba3-48dd-b4b3-660463a6d336",
            price: 0.8945,
            price_sources: [],
            processed_ms: 1712882292804,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "a2cbd0cf-d617-4c5a-b55c-2745047ee735",
            price: 0.8948777808,
            price_sources: [],
            processed_ms: 1712882836235,
          },
        ],
        position_type: "FLAT",
        position_uuid: "6569052f-77b5-4c3f-a8f5-58c5bd2d3ed9",
        return_at_close: 0.994714863027163,
        trade_pair: ["AUDCAD", "AUD/CAD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.65387,
        close_ms: 1712882958477,
        current_return: 1.0003364017248235,
        initial_entry_price: 0.65398,
        is_closed_position: true,
        miner_hotkey: "5Dxqzduahnqw8q3XSUfTcEZGU7xmAsfJubhHZwvXVLN9fSjR",
        net_leverage: 0.0,
        open_ms: 1712865720867,
        orders: [
          {
            leverage: 1.0,
            order_type: "LONG",
            order_uuid: "546f6e0f-95fa-4bf0-92f3-9b569f005a76",
            price: 0.65398,
            price_sources: [],
            processed_ms: 1712865720866,
          },
          {
            leverage: 1.0,
            order_type: "LONG",
            order_uuid: "3a46d6ab-41fc-4485-8038-dbee971058c4",
            price: 0.65376,
            price_sources: [],
            processed_ms: 1712880030551,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "157236ae-28c4-43c1-811b-4fa7fe9b1046",
            price: 0.65398,
            price_sources: [],
            processed_ms: 1712882958477,
          },
        ],
        position_type: "FLAT",
        position_uuid: "d1e7f224-8b00-49ab-9f69-6ac18016f64f",
        return_at_close: 1.000196354628582,
        trade_pair: ["AUDUSD", "AUD/USD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 1.07229,
        close_ms: 1712885599628,
        current_return: 1.000345055908383,
        initial_entry_price: 1.07229,
        is_closed_position: true,
        miner_hotkey: "5Dxqzduahnqw8q3XSUfTcEZGU7xmAsfJubhHZwvXVLN9fSjR",
        net_leverage: 0.0,
        open_ms: 1712884512772,
        orders: [
          {
            leverage: 1.0,
            order_type: "LONG",
            order_uuid: "4afd6f73-5b79-499a-a2be-711dea39e8bc",
            price: 1.07229,
            price_sources: [],
            processed_ms: 1712884512771,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "8fefdd3b-6c42-463d-8858-952ca3ca0b4b",
            price: 1.07266,
            price_sources: [],
            processed_ms: 1712885599628,
          },
        ],
        position_type: "FLAT",
        position_uuid: "f385555a-7823-43e4-a0c5-67e471dd8466",
        return_at_close: 1.0002750317544695,
        trade_pair: ["EURUSD", "EUR/USD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 100.10333333333334,
        close_ms: 1712885616268,
        current_return: 1.0007089507528855,
        initial_entry_price: 100.148,
        is_closed_position: true,
        miner_hotkey: "5Dxqzduahnqw8q3XSUfTcEZGU7xmAsfJubhHZwvXVLN9fSjR",
        net_leverage: 0.0,
        open_ms: 1712880495081,
        orders: [
          {
            leverage: 1.0,
            order_type: "LONG",
            order_uuid: "e06029a9-1687-4496-90c8-1993ecec4845",
            price: 100.148,
            price_sources: [],
            processed_ms: 1712880494533,
          },
          {
            leverage: 1.0,
            order_type: "LONG",
            order_uuid: "574dcc3c-9f75-42f9-a7b9-09627deb23c2",
            price: 100.107,
            price_sources: [],
            processed_ms: 1712883169005,
          },
          {
            leverage: 1.0,
            order_type: "LONG",
            order_uuid: "7bbfa8de-e516-499d-b37b-8b13c9fc903f",
            price: 100.055,
            price_sources: [],
            processed_ms: 1712883294081,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "cc3f0594-bd1c-4ff8-884e-094156541262",
            price: 100.127,
            price_sources: [],
            processed_ms: 1712885616268,
          },
        ],
        position_type: "FLAT",
        position_uuid: "29512be1-e645-423e-a2e9-5f999aebfd50",
        return_at_close: 1.0004988018732273,
        trade_pair: ["AUDJPY", "AUD/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.65382,
        close_ms: 1712886858224,
        current_return: 1.0003670735064698,
        initial_entry_price: 0.65382,
        is_closed_position: true,
        miner_hotkey: "5Dxqzduahnqw8q3XSUfTcEZGU7xmAsfJubhHZwvXVLN9fSjR",
        net_leverage: 0.0,
        open_ms: 1712886164118,
        orders: [
          {
            leverage: 1.0,
            order_type: "LONG",
            order_uuid: "389ba19a-bb18-4bc1-a4aa-09b0577ca6b2",
            price: 0.65382,
            price_sources: [],
            processed_ms: 1712886163573,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "c297116a-a044-4f4c-b81d-e2b8b7180761",
            price: 0.65406,
            price_sources: [],
            processed_ms: 1712886858224,
          },
        ],
        position_type: "FLAT",
        position_uuid: "df48de6c-30f3-46e1-b051-84011b59e6d6",
        return_at_close: 1.0002970478113244,
        trade_pair: ["AUDUSD", "AUD/USD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 63.98599999999982,
        close_ms: 1712949621074,
        current_return: 1.000353350317441,
        initial_entry_price: 98.916,
        is_closed_position: true,
        miner_hotkey: "5Dxqzduahnqw8q3XSUfTcEZGU7xmAsfJubhHZwvXVLN9fSjR",
        net_leverage: 0.0,
        open_ms: 1712940494098,
        orders: [
          {
            leverage: 0.5,
            order_type: "LONG",
            order_uuid: "69044980-ad18-4189-86dc-36aafb53ed73",
            price: 98.916,
            price_sources: [],
            processed_ms: 1712940494096,
          },
          {
            leverage: -0.499,
            order_type: "SHORT",
            order_uuid: "6f571364-e084-4ebd-b4fe-303c3f722aac",
            price: 98.986,
            price_sources: [],
            processed_ms: 1712946462639,
          },
          {
            leverage: 0.01,
            order_type: "FLAT",
            order_uuid: "3204f31e-a1ee-4b72-8b10-1a2191f4a5f4",
            price: 98.938,
            price_sources: [],
            processed_ms: 1712949621074,
          },
        ],
        position_type: "FLAT",
        position_uuid: "e044ab2a-f76e-4409-a810-10893a1bd681",
        return_at_close: 1.0003183379501799,
        trade_pair: ["AUDJPY", "AUD/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.81735200215,
        close_ms: 1713140706318,
        current_return: 1.0000127253880307,
        initial_entry_price: 0.81759,
        is_closed_position: true,
        miner_hotkey: "5Dxqzduahnqw8q3XSUfTcEZGU7xmAsfJubhHZwvXVLN9fSjR",
        net_leverage: 0.0,
        open_ms: 1713140008531,
        orders: [
          {
            leverage: 0.5,
            order_type: "LONG",
            order_uuid: "882a4dfe-9992-47e1-af56-2bf111416fad",
            price: 0.81759,
            price_sources: [],
            processed_ms: 1713140008527,
          },
          {
            leverage: 0.5,
            order_type: "LONG",
            order_uuid: "a7446be2-3a71-4808-9892-aa14191e93bd",
            price: 0.8171140043,
            price_sources: [],
            processed_ms: 1713140405352,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "3b69f028-ec22-4ff0-80c5-def62111f727",
            price: 0.8173624063,
            price_sources: [],
            processed_ms: 1713140706318,
          },
        ],
        position_type: "FLAT",
        position_uuid: "9621f77e-4288-4d8e-8154-747f0c0312e6",
        return_at_close: 0.9999427244972535,
        trade_pair: ["NZDCAD", "NZD/CAD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.8908585454,
        close_ms: 1713140847479,
        current_return: 0.9997585132719995,
        initial_entry_price: 0.890889954,
        is_closed_position: true,
        miner_hotkey: "5Dxqzduahnqw8q3XSUfTcEZGU7xmAsfJubhHZwvXVLN9fSjR",
        net_leverage: 0.0,
        open_ms: 1713140043436,
        orders: [
          {
            leverage: 0.5,
            order_type: "LONG",
            order_uuid: "b8f7fea8-004b-443c-bc99-3ea2417c24ec",
            price: 0.890889954,
            price_sources: [],
            processed_ms: 1713140040405,
          },
          {
            leverage: 0.5,
            order_type: "LONG",
            order_uuid: "97a91b56-f87d-4210-82a1-ac8c8e59772a",
            price: 0.8908271368,
            price_sources: [],
            processed_ms: 1713140448943,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "4a95d794-5d50-4e59-99f6-54c33e99cc20",
            price: 0.8906434073,
            price_sources: [],
            processed_ms: 1713140847479,
          },
        ],
        position_type: "FLAT",
        position_uuid: "fcc678b1-1bc5-4428-ad20-e83f7053583a",
        return_at_close: 0.9996885301760704,
        trade_pair: ["AUDCAD", "AUD/CAD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 1.79224,
        close_ms: 1713141092780,
        current_return: 1.0001088023925366,
        initial_entry_price: 1.79224,
        is_closed_position: true,
        miner_hotkey: "5Dxqzduahnqw8q3XSUfTcEZGU7xmAsfJubhHZwvXVLN9fSjR",
        net_leverage: 0.0,
        open_ms: 1713140651321,
        orders: [
          {
            leverage: 0.5,
            order_type: "LONG",
            order_uuid: "684be9e7-48f3-4ce4-a9b0-0285fafaf7cf",
            price: 1.79224,
            price_sources: [],
            processed_ms: 1713140650957,
          },
          {
            leverage: 0.5,
            order_type: "FLAT",
            order_uuid: "eba40046-6b49-4360-8c82-a43000c040eb",
            price: 1.79263,
            price_sources: [],
            processed_ms: 1713141092780,
          },
        ],
        position_type: "FLAT",
        position_uuid: "cad2213e-d0c8-44c7-bde0-daba3711df6e",
        return_at_close: 1.0000737985844528,
        trade_pair: ["EURNZD", "EUR/NZD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.89092,
        close_ms: 1713141860403,
        current_return: 1.0001137076841915,
        initial_entry_price: 0.89092,
        is_closed_position: true,
        miner_hotkey: "5Dxqzduahnqw8q3XSUfTcEZGU7xmAsfJubhHZwvXVLN9fSjR",
        net_leverage: 0.0,
        open_ms: 1713141544542,
        orders: [
          {
            leverage: 0.5,
            order_type: "LONG",
            order_uuid: "acd47c4d-4b28-480d-bcd9-2fd8988844ba",
            price: 0.89092,
            price_sources: [],
            processed_ms: 1713141544540,
          },
          {
            leverage: 0.5,
            order_type: "FLAT",
            order_uuid: "cd6ca9b8-b475-42b4-a2d6-fc08bffa8941",
            price: 0.8911226089,
            price_sources: [],
            processed_ms: 1713141860403,
          },
        ],
        position_type: "FLAT",
        position_uuid: "68cedc80-3d76-456e-bc00-b77643c01b55",
        return_at_close: 1.0000787037044225,
        trade_pair: ["AUDCAD", "AUD/CAD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.81710299505,
        close_ms: 1713143741316,
        current_return: 0.9999037014569686,
        initial_entry_price: 0.8172859892,
        is_closed_position: true,
        miner_hotkey: "5Dxqzduahnqw8q3XSUfTcEZGU7xmAsfJubhHZwvXVLN9fSjR",
        net_leverage: 0.0,
        open_ms: 1713141699366,
        orders: [
          {
            leverage: 0.5,
            order_type: "LONG",
            order_uuid: "026c2dee-f8ad-4b0d-9a41-f90c457c252d",
            price: 0.8172859892,
            price_sources: [],
            processed_ms: 1713141699364,
          },
          {
            leverage: 0.5,
            order_type: "LONG",
            order_uuid: "1a462764-9835-41d5-ade3-17032b3cfa4d",
            price: 0.8169200009,
            price_sources: [],
            processed_ms: 1713143192317,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "0d7d909e-5815-4126-861b-66722c96617c",
            price: 0.8170242916,
            price_sources: [],
            processed_ms: 1713143741316,
          },
        ],
        position_type: "FLAT",
        position_uuid: "514527bd-5276-4634-a588-062b71ccf5ca",
        return_at_close: 0.9998337081978667,
        trade_pair: ["NZDCAD", "NZD/CAD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.8906515124500001,
        close_ms: 1713144215549,
        current_return: 0.999942171486881,
        initial_entry_price: 0.8907794308,
        is_closed_position: true,
        miner_hotkey: "5Dxqzduahnqw8q3XSUfTcEZGU7xmAsfJubhHZwvXVLN9fSjR",
        net_leverage: 0.0,
        open_ms: 1713143297318,
        orders: [
          {
            leverage: 0.5,
            order_type: "LONG",
            order_uuid: "caf94b2e-9273-4ddd-b9cb-fe9a11f5063e",
            price: 0.8907794308,
            price_sources: [],
            processed_ms: 1713143297316,
          },
          {
            leverage: 0.5,
            order_type: "LONG",
            order_uuid: "4c4aa60e-399e-4ed2-b812-4b5ce376428e",
            price: 0.8905235941,
            price_sources: [],
            processed_ms: 1713143914351,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "90f32a3c-1b0c-47c8-b521-c549a4c965c7",
            price: 0.8906,
            price_sources: [],
            processed_ms: 1713144215549,
          },
        ],
        position_type: "FLAT",
        position_uuid: "869b8c5a-ac24-40c1-ab40-3adf2b09068f",
        return_at_close: 0.999872175534877,
        trade_pair: ["AUDCAD", "AUD/CAD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 1.79332,
        close_ms: 1713144514290,
        current_return: 0.999963754377356,
        initial_entry_price: 1.79332,
        is_closed_position: true,
        miner_hotkey: "5Dxqzduahnqw8q3XSUfTcEZGU7xmAsfJubhHZwvXVLN9fSjR",
        net_leverage: 0.0,
        open_ms: 1713144193398,
        orders: [
          {
            leverage: 0.5,
            order_type: "LONG",
            order_uuid: "e4f5afaf-f4b5-4c61-b051-962d5b1610c4",
            price: 1.79332,
            price_sources: [],
            processed_ms: 1713144193396,
          },
          {
            leverage: 0.5,
            order_type: "FLAT",
            order_uuid: "58f3d2dd-4ff0-42ad-8059-5ebe4e7073b5",
            price: 1.79319,
            price_sources: [],
            processed_ms: 1713144514290,
          },
        ],
        position_type: "FLAT",
        position_uuid: "d1aa9890-5f35-4f83-a330-b86a73cd9745",
        return_at_close: 0.9999287556459527,
        trade_pair: ["EURNZD", "EUR/NZD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: -0.6963199999932373,
        close_ms: 1713144590301,
        current_return: 1.002152761555139,
        initial_entry_price: 0.5993,
        is_closed_position: true,
        miner_hotkey: "5Dxqzduahnqw8q3XSUfTcEZGU7xmAsfJubhHZwvXVLN9fSjR",
        net_leverage: 0.0,
        open_ms: 1712893976471,
        orders: [
          {
            leverage: 0.5,
            order_type: "LONG",
            order_uuid: "8d990789-f4f3-49f5-b238-55835af889bd",
            price: 0.5993,
            price_sources: [],
            processed_ms: 1712893976469,
          },
          {
            leverage: 0.5,
            order_type: "LONG",
            order_uuid: "ac3cb46b-d24f-41a8-9b45-233de2b9759a",
            price: 0.59418,
            price_sources: [],
            processed_ms: 1713140944366,
          },
          {
            leverage: 15.0,
            order_type: "LONG",
            order_uuid: "023eea85-1e87-486c-8988-7d340ed545ff",
            price: 0.59357,
            price_sources: [],
            processed_ms: 1713142491317,
          },
          {
            leverage: 1.0,
            order_type: "LONG",
            order_uuid: "af4e36cc-0d10-4467-8a81-937748a85fca",
            price: 0.59319,
            price_sources: [],
            processed_ms: 1713143396334,
          },
          {
            leverage: 1.0,
            order_type: "LONG",
            order_uuid: "cf3acab6-b104-4231-8a0e-3cacdb7465dd",
            price: 0.59308,
            price_sources: [],
            processed_ms: 1713143502305,
          },
          {
            leverage: 1.0,
            order_type: "LONG",
            order_uuid: "f609b100-c66e-4c6a-8644-1cc0c21d905e",
            price: 0.59295,
            price_sources: [],
            processed_ms: 1713143650300,
          },
          {
            leverage: 1.0,
            order_type: "LONG",
            order_uuid: "9e46a980-ad0c-4453-96b0-9e0232495fa0",
            price: 0.59275,
            price_sources: [],
            processed_ms: 1713143724288,
          },
          {
            leverage: 1.0,
            order_type: "LONG",
            order_uuid: "9eb86fda-7d7a-400c-86b7-09844704b5c0",
            price: 0.59373,
            price_sources: [],
            processed_ms: 1713144152327,
          },
          {
            leverage: -20.999,
            order_type: "SHORT",
            order_uuid: "bfbb8d88-1f4c-482b-a95f-4560a80425ef",
            price: 0.59368,
            price_sources: [],
            processed_ms: 1713144214297,
          },
          {
            leverage: 0.0010000000000012221,
            order_type: "FLAT",
            order_uuid: "820d599a-46ac-45be-932d-c5d0361c3a0c",
            price: 0.59383,
            price_sources: [],
            processed_ms: 1713144590301,
          },
        ],
        position_type: "FLAT",
        position_uuid: "baf9a983-3495-4090-bb19-6cc281711c6e",
        return_at_close: 1.000679596995653,
        trade_pair: ["NZDUSD", "NZD/USD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.59228,
        close_ms: 1713198179316,
        current_return: 0.9999831160937395,
        initial_entry_price: 0.59228,
        is_closed_position: true,
        miner_hotkey: "5Dxqzduahnqw8q3XSUfTcEZGU7xmAsfJubhHZwvXVLN9fSjR",
        net_leverage: 0.0,
        open_ms: 1713198030288,
        orders: [
          {
            leverage: 0.2,
            order_type: "LONG",
            order_uuid: "d4b096e3-5732-4b6c-86a3-72ca821d769e",
            price: 0.59228,
            price_sources: [],
            processed_ms: 1713198030285,
          },
          {
            leverage: 0.2,
            order_type: "FLAT",
            order_uuid: "15bad64f-acba-403b-aa39-45536dcf4793",
            price: 0.59223,
            price_sources: [],
            processed_ms: 1713198179316,
          },
        ],
        position_type: "FLAT",
        position_uuid: "37f97907-43b3-4d28-a9ef-9269b4ba4c89",
        return_at_close: 0.9999691163301142,
        trade_pair: ["NZDUSD", "NZD/USD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.8147005753666666,
        close_ms: 1713198244940,
        current_return: 1.000292485601351,
        initial_entry_price: 0.8146517261,
        is_closed_position: true,
        miner_hotkey: "5Dxqzduahnqw8q3XSUfTcEZGU7xmAsfJubhHZwvXVLN9fSjR",
        net_leverage: 0.0,
        open_ms: 1713197817364,
        orders: [
          {
            leverage: 1.0,
            order_type: "LONG",
            order_uuid: "7ea50e96-a9d6-4d82-8abb-2c7d183f06d4",
            price: 0.8146517261,
            price_sources: [],
            processed_ms: 1713197817362,
          },
          {
            leverage: 1.0,
            order_type: "LONG",
            order_uuid: "0ee200b9-dce3-4062-adae-e21b5a223717",
            price: 0.81469,
            price_sources: [],
            processed_ms: 1713197990343,
          },
          {
            leverage: 1.0,
            order_type: "LONG",
            order_uuid: "66b4b5da-a009-4e72-976b-e9322433bc1c",
            price: 0.81476,
            price_sources: [],
            processed_ms: 1713198122321,
          },
          {
            leverage: 3.0,
            order_type: "FLAT",
            order_uuid: "0dba5e46-4be5-4be3-a894-99b20f726955",
            price: 0.81478,
            price_sources: [],
            processed_ms: 1713198244940,
          },
        ],
        position_type: "FLAT",
        position_uuid: "dd2e12e2-a543-4913-ae1c-544f93d2e2d6",
        return_at_close: 1.0000824241793747,
        trade_pair: ["NZDCAD", "NZD/CAD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.88942365935,
        close_ms: 1713198363067,
        current_return: 0.9997444442611982,
        initial_entry_price: 0.8895073187,
        is_closed_position: true,
        miner_hotkey: "5Dxqzduahnqw8q3XSUfTcEZGU7xmAsfJubhHZwvXVLN9fSjR",
        net_leverage: 0.0,
        open_ms: 1713197406389,
        orders: [
          {
            leverage: 1.0,
            order_type: "LONG",
            order_uuid: "e0e04469-0f87-43bc-b5de-a7eb1ca923b9",
            price: 0.8895073187,
            price_sources: [],
            processed_ms: 1713197406387,
          },
          {
            leverage: 1.0,
            order_type: "LONG",
            order_uuid: "6732dd51-bcf1-43dd-895f-558d758e8fb0",
            price: 0.88934,
            price_sources: [],
            processed_ms: 1713198197345,
          },
          {
            leverage: 2.0,
            order_type: "FLAT",
            order_uuid: "357ef5b1-c926-43f5-b256-caf7fbc180fd",
            price: 0.88931,
            price_sources: [],
            processed_ms: 1713198363067,
          },
        ],
        position_type: "FLAT",
        position_uuid: "45a870d1-d06b-4624-abc5-e0f347b4bb2e",
        return_at_close: 0.9996044800390016,
        trade_pair: ["AUDCAD", "AUD/CAD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 1.796295,
        close_ms: 1713200685471,
        current_return: 1.0006626093444624,
        initial_entry_price: 1.79593,
        is_closed_position: true,
        miner_hotkey: "5Dxqzduahnqw8q3XSUfTcEZGU7xmAsfJubhHZwvXVLN9fSjR",
        net_leverage: 0.0,
        open_ms: 1713197373401,
        orders: [
          {
            leverage: 1.0,
            order_type: "LONG",
            order_uuid: "1c10d99b-dade-4ca4-9594-3d6e33467e50",
            price: 1.79593,
            price_sources: [],
            processed_ms: 1713197373399,
          },
          {
            leverage: 1.0,
            order_type: "LONG",
            order_uuid: "7ac1381d-212c-457a-8c3f-976b1354a169",
            price: 1.79666,
            price_sources: [],
            processed_ms: 1713200057296,
          },
          {
            leverage: 2.0,
            order_type: "FLAT",
            order_uuid: "7260790d-6442-40a5-865f-41372bd54d2d",
            price: 1.79689,
            price_sources: [],
            processed_ms: 1713200685471,
          },
        ],
        position_type: "FLAT",
        position_uuid: "5da07415-8b25-4055-b428-a370570306ff",
        return_at_close: 1.000522516579154,
        trade_pair: ["EURNZD", "EUR/NZD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.884935,
        close_ms: 1713237733344,
        current_return: 1.0008476491862568,
        initial_entry_price: 0.8848,
        is_closed_position: true,
        miner_hotkey: "5Dxqzduahnqw8q3XSUfTcEZGU7xmAsfJubhHZwvXVLN9fSjR",
        net_leverage: 0.0,
        open_ms: 1713236867400,
        orders: [
          {
            leverage: 1.0,
            order_type: "LONG",
            order_uuid: "719dd652-01ea-4da6-8290-dfe3c7e1a895",
            price: 0.8848,
            price_sources: [],
            processed_ms: 1713236867397,
          },
          {
            leverage: 1.0,
            order_type: "LONG",
            order_uuid: "7e3557b0-b338-487f-aaea-a816b4d5db93",
            price: 0.88507,
            price_sources: [],
            processed_ms: 1713237424328,
          },
          {
            leverage: 2.0,
            order_type: "FLAT",
            order_uuid: "521339cc-25c7-41e7-9e06-320d1863aaeb",
            price: 0.88531,
            price_sources: [],
            processed_ms: 1713237733344,
          },
        ],
        position_type: "FLAT",
        position_uuid: "59b22a65-be5e-45ff-9027-314c47c905e4",
        return_at_close: 1.0007075305153708,
        trade_pair: ["AUDCAD", "AUD/CAD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.8110575935,
        close_ms: 1713237787328,
        current_return: 0.9999289896297606,
        initial_entry_price: 0.8110575935,
        is_closed_position: true,
        miner_hotkey: "5Dxqzduahnqw8q3XSUfTcEZGU7xmAsfJubhHZwvXVLN9fSjR",
        net_leverage: 0.0,
        open_ms: 1713237486311,
        orders: [
          {
            leverage: 1.0,
            order_type: "LONG",
            order_uuid: "cec79676-2247-43ce-80ec-7358432624a2",
            price: 0.8110575935,
            price_sources: [],
            processed_ms: 1713237486309,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "efbd5c6a-35e2-4c41-9c84-1f607329207f",
            price: 0.811,
            price_sources: [],
            processed_ms: 1713237787328,
          },
        ],
        position_type: "FLAT",
        position_uuid: "d5a120aa-cfc6-48e3-8985-6b81059afa1d",
        return_at_close: 0.9998589946004866,
        trade_pair: ["NZDCAD", "NZD/CAD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.8133510213999999,
        close_ms: 1713288535441,
        current_return: 1.0000183523604371,
        initial_entry_price: 0.8134757407,
        is_closed_position: true,
        miner_hotkey: "5Dxqzduahnqw8q3XSUfTcEZGU7xmAsfJubhHZwvXVLN9fSjR",
        net_leverage: 0.0,
        open_ms: 1713287810297,
        orders: [
          {
            leverage: 1.0,
            order_type: "LONG",
            order_uuid: "93f6c36b-9aae-47c8-860b-0f5c0a38730a",
            price: 0.8134757407,
            price_sources: [],
            processed_ms: 1713287810294,
          },
          {
            leverage: 1.0,
            order_type: "LONG",
            order_uuid: "df3084de-2211-42e4-82d2-e22d40caf27c",
            price: 0.81341,
            price_sources: [],
            processed_ms: 1713287952587,
          },
          {
            leverage: 1.0,
            order_type: "LONG",
            order_uuid: "8eb03d94-149f-4e2d-9dd3-fb631014e8e7",
            price: 0.81322,
            price_sources: [],
            processed_ms: 1713288130344,
          },
          {
            leverage: 1.0,
            order_type: "LONG",
            order_uuid: "4860c057-a751-4eb1-a87f-7d81577ad4e8",
            price: 0.8132983449,
            price_sources: [],
            processed_ms: 1713288206696,
          },
          {
            leverage: 4.0,
            order_type: "FLAT",
            order_uuid: "0e672dbc-36f0-474d-9cc3-21937dc68cda",
            price: 0.8133547537,
            price_sources: [],
            processed_ms: 1713288535441,
          },
        ],
        position_type: "FLAT",
        position_uuid: "09a842ca-2071-47f5-87a3-81a4caafa24e",
        return_at_close: 0.9997383472217762,
        trade_pair: ["NZDCAD", "NZD/CAD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.8151,
        close_ms: 1713329067325,
        current_return: 1.000236671291866,
        initial_entry_price: 0.8151,
        is_closed_position: true,
        miner_hotkey: "5Dxqzduahnqw8q3XSUfTcEZGU7xmAsfJubhHZwvXVLN9fSjR",
        net_leverage: 0.0,
        open_ms: 1713328766289,
        orders: [
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "864ade4f-8d1f-4fd8-b3e9-c853b93d26c3",
            price: 0.8151,
            price_sources: [],
            processed_ms: 1713328766287,
          },
          {
            leverage: 0.3,
            order_type: "FLAT",
            order_uuid: "bae7089b-3633-4dd5-a125-8521aadc0750",
            price: 0.8157430359,
            price_sources: [],
            processed_ms: 1713329067325,
          },
        ],
        position_type: "FLAT",
        position_uuid: "a2eb02b5-91e8-4987-93fd-2a7a80fc7b5e",
        return_at_close: 1.0002156663217687,
        trade_pair: ["NZDCAD", "NZD/CAD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.8863988513750001,
        close_ms: 1713395066621,
        current_return: 1.0002046189729472,
        initial_entry_price: 0.88642,
        is_closed_position: true,
        miner_hotkey: "5Dxqzduahnqw8q3XSUfTcEZGU7xmAsfJubhHZwvXVLN9fSjR",
        net_leverage: 0.0,
        open_ms: 1713328820373,
        orders: [
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "a409abe5-c83b-43d6-8c71-4895e4c9def0",
            price: 0.88642,
            price_sources: [],
            processed_ms: 1713328820370,
          },
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "28add94c-64eb-48b3-a3f7-000aae32e429",
            price: 0.8864,
            price_sources: [],
            processed_ms: 1713329039836,
          },
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "99bd6287-7884-4a0a-aa36-9e99d9ccea67",
            price: 0.8863630512,
            price_sources: [],
            processed_ms: 1713393274666,
          },
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "01e91978-0652-404d-b27b-696a26c30d2b",
            price: 0.8864123543,
            price_sources: [],
            processed_ms: 1713394564635,
          },
          {
            leverage: 1.2,
            order_type: "FLAT",
            order_uuid: "8eb8534d-2267-4914-8184-8ffc546b5392",
            price: 0.88655,
            price_sources: [],
            processed_ms: 1713395066621,
          },
        ],
        position_type: "FLAT",
        position_uuid: "1e141021-6d0b-499d-a047-b0458697747f",
        return_at_close: 1.0001206017849535,
        trade_pair: ["AUDCAD", "AUD/CAD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.814643832902525,
        close_ms: 1713396176670,
        current_return: 1.0003610986935432,
        initial_entry_price: 0.814875964588363,
        is_closed_position: true,
        miner_hotkey: "5Dxqzduahnqw8q3XSUfTcEZGU7xmAsfJubhHZwvXVLN9fSjR",
        net_leverage: 0.0,
        open_ms: 1713393336510,
        orders: [
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "8019c0da-6bab-477d-98f8-df576fc81ef2",
            price: 0.814875964588363,
            price_sources: [],
            processed_ms: 1713393336505,
          },
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "4ea6c242-a77d-4d94-b57a-ee7347a51eb5",
            price: 0.8143,
            price_sources: [],
            processed_ms: 1713393488597,
          },
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "b5a7c85f-6158-4327-b371-c432de4d7a63",
            price: 0.8145,
            price_sources: [],
            processed_ms: 1713394010592,
          },
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "4606f261-9e32-4a3e-857b-30ebb4b819e6",
            price: 0.81474,
            price_sources: [],
            processed_ms: 1713394618634,
          },
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "ad35f7d9-8eac-45fb-9069-3ed234ded576",
            price: 0.814803199924262,
            price_sources: [],
            processed_ms: 1713395049644,
          },
          {
            leverage: 1.5,
            order_type: "FLAT",
            order_uuid: "a2a8622e-11f9-4740-b9fa-b4dd27a4cc17",
            price: 0.81484,
            price_sources: [],
            processed_ms: 1713396176670,
          },
        ],
        position_type: "FLAT",
        position_uuid: "92aa6b9c-2b69-44a0-aa81-0f50f57cda76",
        return_at_close: 1.0002560607781803,
        trade_pair: ["NZDCAD", "NZD/CAD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.8834970675794324,
        close_ms: 1713802545245,
        current_return: 1.0001659753010876,
        initial_entry_price: 0.8835,
        is_closed_position: true,
        miner_hotkey: "5Dxqzduahnqw8q3XSUfTcEZGU7xmAsfJubhHZwvXVLN9fSjR",
        net_leverage: 0.0,
        open_ms: 1713800557005,
        orders: [
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "37507da8-376f-4e56-8cce-8f01347c2c9b",
            price: 0.8835,
            price_sources: [],
            processed_ms: 1713800556997,
          },
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "d2475cc8-897f-4d49-b50f-8e3dba73969e",
            price: 0.88343,
            price_sources: [],
            processed_ms: 1713801216566,
          },
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "bbc5344e-372e-4584-b880-917f616ca23e",
            price: 0.883561202738297,
            price_sources: [],
            processed_ms: 1713801782632,
          },
          {
            leverage: 0.8999999999999999,
            order_type: "FLAT",
            order_uuid: "7d9c5b37-25d7-4b12-bba1-68694c75f52a",
            price: 0.88366,
            price_sources: [],
            processed_ms: 1713802545245,
          },
        ],
        position_type: "FLAT",
        position_uuid: "0781e787-9181-4d33-9e4f-526a770859a8",
        return_at_close: 1.0001029648446436,
        trade_pair: ["AUDCAD", "AUD/CAD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.8107158803526145,
        close_ms: 1713802595340,
        current_return: 1.0001186158936237,
        initial_entry_price: 0.81084809244184,
        is_closed_position: true,
        miner_hotkey: "5Dxqzduahnqw8q3XSUfTcEZGU7xmAsfJubhHZwvXVLN9fSjR",
        net_leverage: 0.0,
        open_ms: 1713799868595,
        orders: [
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "c69b7f5f-a534-4e51-8917-0321ea3b205a",
            price: 0.81084809244184,
            price_sources: [],
            processed_ms: 1713799868589,
          },
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "ff7af0c1-2587-471e-8ad8-b5c74aba8bac",
            price: 0.81075,
            price_sources: [],
            processed_ms: 1713800408488,
          },
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "78beeb11-244b-465e-8f68-f0f3ede3fdce",
            price: 0.81064,
            price_sources: [],
            processed_ms: 1713801024150,
          },
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "4277bfdd-b500-4725-8a0a-a60ed0787bff",
            price: 0.810801309321233,
            price_sources: [],
            processed_ms: 1713801557628,
          },
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "0a67aa41-9135-4f16-8729-5ebc873f26eb",
            price: 0.81054,
            price_sources: [],
            processed_ms: 1713802103603,
          },
          {
            leverage: 1.5,
            order_type: "FLAT",
            order_uuid: "3b7bcffa-df40-4c66-ae77-97a5a26f9f26",
            price: 0.81078,
            price_sources: [],
            processed_ms: 1713802595340,
          },
        ],
        position_type: "FLAT",
        position_uuid: "88fb02dc-71bc-4097-9a4d-83169d530fb3",
        return_at_close: 1.0000136034389548,
        trade_pair: ["NZDCAD", "NZD/CAD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.5911299999999999,
        close_ms: 1713809919021,
        current_return: 1.000174577934161,
        initial_entry_price: 0.59114,
        is_closed_position: true,
        miner_hotkey: "5Dxqzduahnqw8q3XSUfTcEZGU7xmAsfJubhHZwvXVLN9fSjR",
        net_leverage: 0.0,
        open_ms: 1713803012545,
        orders: [
          {
            leverage: 0.06,
            order_type: "LONG",
            order_uuid: "e0c493ed-a56f-4a83-875f-0f80169d115e",
            price: 0.59114,
            price_sources: [],
            processed_ms: 1713803012538,
          },
          {
            leverage: 0.06,
            order_type: "LONG",
            order_uuid: "d9e9a038-2395-4cde-a963-24feefe55115",
            price: 0.59112,
            price_sources: [],
            processed_ms: 1713803780586,
          },
          {
            leverage: 0.12,
            order_type: "FLAT",
            order_uuid: "0d27a601-2382-428e-9851-38b6fda560df",
            price: 0.59199,
            price_sources: [],
            processed_ms: 1713809919021,
          },
        ],
        position_type: "FLAT",
        position_uuid: "5c53473e-f28b-413f-8379-8a9b58b53378",
        return_at_close: 1.0001661764677063,
        trade_pair: ["NZDUSD", "NZD/USD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.8834291424454217,
        close_ms: 1713815483884,
        current_return: 1.0007283236193891,
        initial_entry_price: 0.88344,
        is_closed_position: true,
        miner_hotkey: "5Dxqzduahnqw8q3XSUfTcEZGU7xmAsfJubhHZwvXVLN9fSjR",
        net_leverage: 0.0,
        open_ms: 1713812696514,
        orders: [
          {
            leverage: 1.0,
            order_type: "LONG",
            order_uuid: "d3677651-09e5-4d07-9ff1-d6e511cd4fbe",
            price: 0.88344,
            price_sources: [],
            processed_ms: 1713812696503,
          },
          {
            leverage: 1.0,
            order_type: "LONG",
            order_uuid: "9dc932cd-5062-4f9e-b08e-fa9b25dab229",
            price: 0.88335,
            price_sources: [],
            processed_ms: 1713813410551,
          },
          {
            leverage: 1.0,
            order_type: "LONG",
            order_uuid: "55f968e8-adf0-4c39-9d28-22e7d5b74a5e",
            price: 0.88344,
            price_sources: [],
            processed_ms: 1713813966581,
          },
          {
            leverage: 1.0,
            order_type: "LONG",
            order_uuid: "e1988be6-d704-41de-9577-ea5b22ae5c83",
            price: 0.883486569781687,
            price_sources: [],
            processed_ms: 1713815073617,
          },
          {
            leverage: 4.0,
            order_type: "FLAT",
            order_uuid: "cb8c0563-8780-44dd-a570-b873d75016ec",
            price: 0.88359,
            price_sources: [],
            processed_ms: 1713815483884,
          },
        ],
        position_type: "FLAT",
        position_uuid: "f419c6ee-d226-4682-b1e2-4863009538b2",
        return_at_close: 1.0004481196887758,
        trade_pair: ["AUDCAD", "AUD/CAD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.5917145454545455,
        close_ms: 1713815754733,
        current_return: 0.999787018255578,
        initial_entry_price: 0.5916,
        is_closed_position: true,
        miner_hotkey: "5Dxqzduahnqw8q3XSUfTcEZGU7xmAsfJubhHZwvXVLN9fSjR",
        net_leverage: 0.0,
        open_ms: 1713812662605,
        orders: [
          {
            leverage: 0.2,
            order_type: "LONG",
            order_uuid: "190597ff-c46c-437f-8614-38ea07b06d3e",
            price: 0.5916,
            price_sources: [],
            processed_ms: 1713812658494,
          },
          {
            leverage: 0.2,
            order_type: "LONG",
            order_uuid: "ed545189-3816-4ceb-bcea-016f602c6da0",
            price: 0.59192,
            price_sources: [],
            processed_ms: 1713812888873,
          },
          {
            leverage: 0.2,
            order_type: "LONG",
            order_uuid: "b05dcd15-1056-43c6-a807-becca630077f",
            price: 0.59178,
            price_sources: [],
            processed_ms: 1713813476933,
          },
          {
            leverage: 0.2,
            order_type: "LONG",
            order_uuid: "759eac74-7a8c-4cf4-963c-32aa85c8aac0",
            price: 0.59174,
            price_sources: [],
            processed_ms: 1713814139539,
          },
          {
            leverage: 0.2,
            order_type: "LONG",
            order_uuid: "2586374d-fba3-4edc-96b0-680c96655648",
            price: 0.59164,
            price_sources: [],
            processed_ms: 1713814646544,
          },
          {
            leverage: 0.1,
            order_type: "LONG",
            order_uuid: "0a950312-8212-4dbc-ab64-ef27fdf14c6b",
            price: 0.5915,
            price_sources: [],
            processed_ms: 1713815468526,
          },
          {
            leverage: 1.1,
            order_type: "FLAT",
            order_uuid: "4e440354-efde-4144-ab92-bb83098de6d5",
            price: 0.5916,
            price_sources: [],
            processed_ms: 1713815754733,
          },
        ],
        position_type: "FLAT",
        position_uuid: "6102a0a9-d9b5-458f-92dc-8a4b90d8506e",
        return_at_close: 0.9997100346551724,
        trade_pair: ["NZDUSD", "NZD/USD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.8107050785687241,
        close_ms: 1713815850000,
        current_return: 1.0013873248663134,
        initial_entry_price: 0.81063,
        is_closed_position: true,
        miner_hotkey: "5Dxqzduahnqw8q3XSUfTcEZGU7xmAsfJubhHZwvXVLN9fSjR",
        net_leverage: 0.0,
        open_ms: 1713811946518,
        orders: [
          {
            leverage: 1.0,
            order_type: "LONG",
            order_uuid: "5c5321bb-b66a-40c8-8170-8c04fe4d2274",
            price: 0.81063,
            price_sources: [],
            processed_ms: 1713811946510,
          },
          {
            leverage: 1.0,
            order_type: "LONG",
            order_uuid: "6b867252-4659-43fc-874c-ca4a29a13641",
            price: 0.81073,
            price_sources: [],
            processed_ms: 1713812674479,
          },
          {
            leverage: 1.0,
            order_type: "LONG",
            order_uuid: "bea13f3c-23ad-4553-a286-d4bf15ba487c",
            price: 0.81064,
            price_sources: [],
            processed_ms: 1713813229352,
          },
          {
            leverage: 1.0,
            order_type: "LONG",
            order_uuid: "73f41615-bd97-42a2-bfc1-ed0867d0a78f",
            price: 0.81075,
            price_sources: [],
            processed_ms: 1713814358541,
          },
          {
            leverage: 1.0,
            order_type: "LONG",
            order_uuid: "05aafcef-f268-4d34-aa69-c4aa4e4864a9",
            price: 0.81077539284362,
            price_sources: [],
            processed_ms: 1713814962560,
          },
          {
            leverage: 5.0,
            order_type: "FLAT",
            order_uuid: "c8e74f88-0653-4485-b0c1-aaa48a84dd5b",
            price: 0.81093,
            price_sources: [],
            processed_ms: 1713815850000,
          },
        ],
        position_type: "FLAT",
        position_uuid: "232be169-0674-47d2-a6e0-ca734564b089",
        return_at_close: 1.0010368393026103,
        trade_pair: ["NZDCAD", "NZD/CAD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.8102019734846917,
        close_ms: 1713823099990,
        current_return: 1.0014369755779295,
        initial_entry_price: 0.81009,
        is_closed_position: true,
        miner_hotkey: "5Dxqzduahnqw8q3XSUfTcEZGU7xmAsfJubhHZwvXVLN9fSjR",
        net_leverage: 0.0,
        open_ms: 1713820486540,
        orders: [
          {
            leverage: 0.5,
            order_type: "LONG",
            order_uuid: "8a07c29f-c601-4b9c-95a2-e52c7237597c",
            price: 0.81009,
            price_sources: [],
            processed_ms: 1713820486531,
          },
          {
            leverage: 0.5,
            order_type: "LONG",
            order_uuid: "f3400ae5-b0a4-4fae-add4-2a5bc5e69279",
            price: 0.81031,
            price_sources: [],
            processed_ms: 1713820636470,
          },
          {
            leverage: 0.5,
            order_type: "LONG",
            order_uuid: "67805f8e-2eb3-4490-9d8d-c3a5ed4c8703",
            price: 0.81040184090815,
            price_sources: [],
            processed_ms: 1713820876510,
          },
          {
            leverage: 0.5,
            order_type: "LONG",
            order_uuid: "2eb674eb-96f3-49a7-9f3c-7262d92a6999",
            price: 0.81031,
            price_sources: [],
            processed_ms: 1713821386557,
          },
          {
            leverage: 0.5,
            order_type: "LONG",
            order_uuid: "5cad7741-6ca1-495a-a310-5af22edc6dc8",
            price: 0.81,
            price_sources: [],
            processed_ms: 1713821897569,
          },
          {
            leverage: 0.5,
            order_type: "LONG",
            order_uuid: "fb279745-dff1-406d-a9de-e01dc1400f7b",
            price: 0.8101,
            price_sources: [],
            processed_ms: 1713822467482,
          },
          {
            leverage: 3.0,
            order_type: "FLAT",
            order_uuid: "b013bdac-323a-40c8-b706-aa451e22f559",
            price: 0.81059,
            price_sources: [],
            processed_ms: 1713823099990,
          },
        ],
        position_type: "FLAT",
        position_uuid: "8f1e0d32-fa96-4cc3-9b52-3fb1897f125b",
        return_at_close: 1.0012266738130582,
        trade_pair: ["NZDCAD", "NZD/CAD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 1.7996699999999999,
        close_ms: 1713828933604,
        current_return: 1.0003786953334963,
        initial_entry_price: 1.80092,
        is_closed_position: true,
        miner_hotkey: "5Dxqzduahnqw8q3XSUfTcEZGU7xmAsfJubhHZwvXVLN9fSjR",
        net_leverage: 0.0,
        open_ms: 1713804457903,
        orders: [
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "c35d82d1-f2db-48e1-9db9-14ea99a69c76",
            price: 1.80092,
            price_sources: [],
            processed_ms: 1713804457115,
          },
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "76fb2f1b-82b3-4b49-b528-477c157889ab",
            price: 1.80087,
            price_sources: [],
            processed_ms: 1713806192662,
          },
          {
            leverage: 0.5,
            order_type: "LONG",
            order_uuid: "2dc9eeea-f634-4363-8b54-23d0df982c48",
            price: 1.7982,
            price_sources: [],
            processed_ms: 1713819619705,
          },
          {
            leverage: 1.1,
            order_type: "FLAT",
            order_uuid: "c3f0091f-769c-4332-9edd-845d075072fe",
            price: 1.80029,
            price_sources: [],
            processed_ms: 1713828933604,
          },
        ],
        position_type: "FLAT",
        position_uuid: "5f62c67b-656c-4cde-a327-c3b4d69b5881",
        return_at_close: 1.0003016661739557,
        trade_pair: ["EURNZD", "EUR/NZD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.8107656384098997,
        close_ms: 1713829971610,
        current_return: 1.0025594348042124,
        initial_entry_price: 0.81096,
        is_closed_position: true,
        miner_hotkey: "5Dxqzduahnqw8q3XSUfTcEZGU7xmAsfJubhHZwvXVLN9fSjR",
        net_leverage: 0.0,
        open_ms: 1713825891459,
        orders: [
          {
            leverage: 1.0,
            order_type: "LONG",
            order_uuid: "4caf85d3-9aa8-479d-96e3-8039b6666c4b",
            price: 0.81096,
            price_sources: [],
            processed_ms: 1713825891452,
          },
          {
            leverage: 1.0,
            order_type: "LONG",
            order_uuid: "bfa2c1ff-0fa0-453a-9d07-4c312c8100c6",
            price: 0.81093,
            price_sources: [],
            processed_ms: 1713827050585,
          },
          {
            leverage: 1.0,
            order_type: "LONG",
            order_uuid: "06671bd3-2278-4ed6-8592-5bc216064f77",
            price: 0.81076,
            price_sources: [],
            processed_ms: 1713827625582,
          },
          {
            leverage: 1.0,
            order_type: "LONG",
            order_uuid: "dd7a660d-aff0-483a-b30a-a2e20599c2e7",
            price: 0.810562025,
            price_sources: [],
            processed_ms: 1713828394812,
          },
          {
            leverage: 1.0,
            order_type: "LONG",
            order_uuid: "e39d3e4b-be7b-4a68-ae0b-46ec63bc4765",
            price: 0.810731805459398,
            price_sources: [],
            processed_ms: 1713828916905,
          },
          {
            leverage: 1.0,
            order_type: "LONG",
            order_uuid: "a5fcb0f7-d8f6-4588-b642-12ec029022c9",
            price: 0.81065,
            price_sources: [],
            processed_ms: 1713829488632,
          },
          {
            leverage: 6.0,
            order_type: "FLAT",
            order_uuid: "3b41bc0d-3deb-4f83-bbec-abe11b8d7e33",
            price: 0.811111571618037,
            price_sources: [],
            processed_ms: 1713829971610,
          },
        ],
        position_type: "FLAT",
        position_uuid: "d27b72fe-16b5-4c54-9d11-bce0a4993327",
        return_at_close: 1.0021383598415947,
        trade_pair: ["NZDCAD", "NZD/CAD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.8834126202146322,
        close_ms: 1713830255885,
        current_return: 1.0012566710614883,
        initial_entry_price: 0.883490480858529,
        is_closed_position: true,
        miner_hotkey: "5Dxqzduahnqw8q3XSUfTcEZGU7xmAsfJubhHZwvXVLN9fSjR",
        net_leverage: 0.0,
        open_ms: 1713826787569,
        orders: [
          {
            leverage: 1.0,
            order_type: "LONG",
            order_uuid: "1c89b329-1c9f-4c8f-b22e-65f9c92f7835",
            price: 0.883490480858529,
            price_sources: [],
            processed_ms: 1713826787557,
          },
          {
            leverage: 1.0,
            order_type: "LONG",
            order_uuid: "5146f443-fef2-47e8-a91a-b83290940d25",
            price: 0.88339,
            price_sources: [],
            processed_ms: 1713828582924,
          },
          {
            leverage: 1.0,
            order_type: "LONG",
            order_uuid: "16af3532-82ac-4182-8911-1207790bfa42",
            price: 0.88347,
            price_sources: [],
            processed_ms: 1713829698615,
          },
          {
            leverage: 1.0,
            order_type: "LONG",
            order_uuid: "5472b282-9f42-4f50-8985-3a8fd781df31",
            price: 0.8833,
            price_sources: [],
            processed_ms: 1713829953272,
          },
          {
            leverage: 4.0,
            order_type: "FLAT",
            order_uuid: "cbd80f0a-c444-4313-83e2-0ecee6f87d0c",
            price: 0.883690184444731,
            price_sources: [],
            processed_ms: 1713830255885,
          },
        ],
        position_type: "FLAT",
        position_uuid: "06a1a76b-12cd-4085-8ce4-503a0f2604f4",
        return_at_close: 1.0009763191935912,
        trade_pair: ["AUDCAD", "AUD/CAD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.8108949999999999,
        close_ms: 1713833959642,
        current_return: 1.0009804214803164,
        initial_entry_price: 0.81082,
        is_closed_position: true,
        miner_hotkey: "5Dxqzduahnqw8q3XSUfTcEZGU7xmAsfJubhHZwvXVLN9fSjR",
        net_leverage: 0.0,
        open_ms: 1713832589977,
        orders: [
          {
            leverage: 1.0,
            order_type: "LONG",
            order_uuid: "2acda3ca-7957-407e-87d3-1de3af2362b6",
            price: 0.81082,
            price_sources: [],
            processed_ms: 1713832589966,
          },
          {
            leverage: 1.0,
            order_type: "LONG",
            order_uuid: "31fa30ee-e338-4b29-adcd-e09d2ae1c47a",
            price: 0.81097,
            price_sources: [],
            processed_ms: 1713833514644,
          },
          {
            leverage: 2.0,
            order_type: "FLAT",
            order_uuid: "176febda-882e-485c-9957-2d20c09492fc",
            price: 0.811292472672335,
            price_sources: [],
            processed_ms: 1713833959642,
          },
        ],
        position_type: "FLAT",
        position_uuid: "904d2205-f872-4650-bceb-600e55a203e7",
        return_at_close: 1.000840284221309,
        trade_pair: ["NZDCAD", "NZD/CAD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.8842817149627715,
        close_ms: 1713836514587,
        current_return: 1.0021641612073549,
        initial_entry_price: 0.88401,
        is_closed_position: true,
        miner_hotkey: "5Dxqzduahnqw8q3XSUfTcEZGU7xmAsfJubhHZwvXVLN9fSjR",
        net_leverage: 0.0,
        open_ms: 1713832868661,
        orders: [
          {
            leverage: 1.0,
            order_type: "LONG",
            order_uuid: "9f091ec9-c4ca-4777-a404-4db342e7f579",
            price: 0.88401,
            price_sources: [],
            processed_ms: 1713832868650,
          },
          {
            leverage: 1.0,
            order_type: "LONG",
            order_uuid: "f9d9b3cb-6afc-4066-9bfb-64a556ecf46d",
            price: 0.8846,
            price_sources: [],
            processed_ms: 1713834348646,
          },
          {
            leverage: 1.0,
            order_type: "LONG",
            order_uuid: "54d40304-b1a0-45a6-a524-ae1fd289a37f",
            price: 0.884184839446876,
            price_sources: [],
            processed_ms: 1713835747724,
          },
          {
            leverage: 1.0,
            order_type: "LONG",
            order_uuid: "8bb14b4e-7d34-41f8-a29f-2ddff81350c7",
            price: 0.88433202040421,
            price_sources: [],
            processed_ms: 1713836114770,
          },
          {
            leverage: 4.0,
            order_type: "FLAT",
            order_uuid: "7db516e9-70e9-417b-9024-522e3e2031f9",
            price: 0.88476,
            price_sources: [],
            processed_ms: 1713836514587,
          },
        ],
        position_type: "FLAT",
        position_uuid: "024b2748-d1e2-412b-9be2-16f44209d3b9",
        return_at_close: 1.001883555242217,
        trade_pair: ["AUDCAD", "AUD/CAD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 1.7995700000000001,
        close_ms: 1713837587648,
        current_return: 1.0006222395066529,
        initial_entry_price: 1.79995,
        is_closed_position: true,
        miner_hotkey: "5Dxqzduahnqw8q3XSUfTcEZGU7xmAsfJubhHZwvXVLN9fSjR",
        net_leverage: 0.0,
        open_ms: 1713832785648,
        orders: [
          {
            leverage: 1.0,
            order_type: "LONG",
            order_uuid: "9d983d5a-c096-46ad-8267-a6e1eb8b3b57",
            price: 1.79995,
            price_sources: [],
            processed_ms: 1713832785638,
          },
          {
            leverage: 1.0,
            order_type: "LONG",
            order_uuid: "f3b3192c-5484-44c8-9b2a-335ba198bf87",
            price: 1.79919,
            price_sources: [],
            processed_ms: 1713836791563,
          },
          {
            leverage: 2.0,
            order_type: "FLAT",
            order_uuid: "c8a093dc-ba76-410b-a0a6-b6844366b91a",
            price: 1.80013,
            price_sources: [],
            processed_ms: 1713837587648,
          },
        ],
        position_type: "FLAT",
        position_uuid: "b98ebb50-8b13-4c6e-aaaf-b27472ff073a",
        return_at_close: 1.000482152393122,
        trade_pair: ["EURNZD", "EUR/NZD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.8111139781511703,
        close_ms: 1713838867742,
        current_return: 1.0004379635038023,
        initial_entry_price: 0.81106,
        is_closed_position: true,
        miner_hotkey: "5Dxqzduahnqw8q3XSUfTcEZGU7xmAsfJubhHZwvXVLN9fSjR",
        net_leverage: 0.0,
        open_ms: 1713836499663,
        orders: [
          {
            leverage: 1.0,
            order_type: "LONG",
            order_uuid: "cc67b367-b4e2-4ea2-9a4c-d481b1536b51",
            price: 0.81106,
            price_sources: [],
            processed_ms: 1713836499650,
          },
          {
            leverage: 1.0,
            order_type: "LONG",
            order_uuid: "da522aa3-da99-4ad0-81e6-4313daade876",
            price: 0.81125,
            price_sources: [],
            processed_ms: 1713837015688,
          },
          {
            leverage: 1.0,
            order_type: "LONG",
            order_uuid: "f2ad7baa-eaad-42dc-a3df-d71305530f81",
            price: 0.81098250161255,
            price_sources: [],
            processed_ms: 1713837566745,
          },
          {
            leverage: 1.0,
            order_type: "LONG",
            order_uuid: "08b47a87-7e99-48e6-9a87-b510f819e48a",
            price: 0.81099,
            price_sources: [],
            processed_ms: 1713838160279,
          },
          {
            leverage: 1.0,
            order_type: "LONG",
            order_uuid: "3ae15009-b2c4-4b1b-b83d-e3240c7da526",
            price: 0.811287389143301,
            price_sources: [],
            processed_ms: 1713838722853,
          },
          {
            leverage: 5.0,
            order_type: "FLAT",
            order_uuid: "d429b053-09e6-430b-96fc-e9aa9d1c0b15",
            price: 0.811185021087049,
            price_sources: [],
            processed_ms: 1713838867742,
          },
        ],
        position_type: "FLAT",
        position_uuid: "f797c107-22c7-4c2f-bcfa-188fe8eb20d8",
        return_at_close: 1.000087810216576,
        trade_pair: ["NZDCAD", "NZD/CAD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.8847238042454142,
        close_ms: 1713845588543,
        current_return: 1.0020264299959327,
        initial_entry_price: 0.88499,
        is_closed_position: true,
        miner_hotkey: "5Dxqzduahnqw8q3XSUfTcEZGU7xmAsfJubhHZwvXVLN9fSjR",
        net_leverage: 0.0,
        open_ms: 1713839068699,
        orders: [
          {
            leverage: 1.0,
            order_type: "LONG",
            order_uuid: "760ebd48-ac5b-4ad9-9b4f-f6bbc4a72e96",
            price: 0.88499,
            price_sources: [],
            processed_ms: 1713839068692,
          },
          {
            leverage: 1.0,
            order_type: "LONG",
            order_uuid: "842d2ce9-3ed2-44a6-9cd2-64051f53fa75",
            price: 0.88496413106702,
            price_sources: [],
            processed_ms: 1713840253520,
          },
          {
            leverage: 1.0,
            order_type: "LONG",
            order_uuid: "3ab83ea9-8ec6-490f-b309-057b8e7bd541",
            price: 0.88502,
            price_sources: [],
            processed_ms: 1713840928573,
          },
          {
            leverage: 1.0,
            order_type: "LONG",
            order_uuid: "4fa1eaf2-55ae-4449-b1a5-aa63f211eb48",
            price: 0.884820897836818,
            price_sources: [],
            processed_ms: 1713841028579,
          },
          {
            leverage: 1.0,
            order_type: "LONG",
            order_uuid: "f7744931-c6b8-428c-b5aa-7a1e57aa46e6",
            price: 0.884220921301466,
            price_sources: [],
            processed_ms: 1713841676530,
          },
          {
            leverage: 1.0,
            order_type: "LONG",
            order_uuid: "e75222dc-4649-4039-88d0-f1553150c2c7",
            price: 0.88448,
            price_sources: [],
            processed_ms: 1713842878341,
          },
          {
            leverage: 1.0,
            order_type: "LONG",
            order_uuid: "e92dd808-e669-48c4-8c9a-f19a43264774",
            price: 0.884570679512595,
            price_sources: [],
            processed_ms: 1713843690549,
          },
          {
            leverage: 7.0,
            order_type: "FLAT",
            order_uuid: "4a7deb10-fed3-4c21-a575-a3212c00d1e1",
            price: 0.88498,
            price_sources: [],
            processed_ms: 1713845588543,
          },
        ],
        position_type: "FLAT",
        position_uuid: "10f0dd01-6566-4fa5-9b9c-3b6c9f41a814",
        return_at_close: 1.0015354370452347,
        trade_pair: ["AUDCAD", "AUD/CAD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.810654876143524,
        close_ms: 1713845890529,
        current_return: 1.0004819289074307,
        initial_entry_price: 0.81079,
        is_closed_position: true,
        miner_hotkey: "5Dxqzduahnqw8q3XSUfTcEZGU7xmAsfJubhHZwvXVLN9fSjR",
        net_leverage: 0.0,
        open_ms: 1713841444530,
        orders: [
          {
            leverage: 1.0,
            order_type: "LONG",
            order_uuid: "62db1000-16c6-4a20-895f-9b6c136ae22e",
            price: 0.81079,
            price_sources: [],
            processed_ms: 1713841444518,
          },
          {
            leverage: 1.0,
            order_type: "LONG",
            order_uuid: "1f24e4a7-b60c-4492-bbaa-2a646c583d3b",
            price: 0.810662918023084,
            price_sources: [],
            processed_ms: 1713842546707,
          },
          {
            leverage: 1.0,
            order_type: "LONG",
            order_uuid: "2d159666-6586-4fe6-9610-702c039b0da8",
            price: 0.810661199081635,
            price_sources: [],
            processed_ms: 1713843334510,
          },
          {
            leverage: 1.0,
            order_type: "LONG",
            order_uuid: "d7dddf62-dbf6-4d6c-b759-0e94c2b3282d",
            price: 0.8103,
            price_sources: [],
            processed_ms: 1713843847020,
          },
          {
            leverage: 1.0,
            order_type: "LONG",
            order_uuid: "c3377a34-ae4e-491f-9e55-cb1ef9751ab8",
            price: 0.81062,
            price_sources: [],
            processed_ms: 1713845016011,
          },
          {
            leverage: 1.0,
            order_type: "LONG",
            order_uuid: "0720f9e6-2625-4d24-bf64-abcdea821bc3",
            price: 0.810895139756425,
            price_sources: [],
            processed_ms: 1713845570488,
          },
          {
            leverage: 6.0,
            order_type: "FLAT",
            order_uuid: "230b7eed-a2b4-4349-8842-204f20598b57",
            price: 0.81072,
            price_sources: [],
            processed_ms: 1713845890529,
          },
        ],
        position_type: "FLAT",
        position_uuid: "22bdebbb-0a22-40b5-adb7-74cbc33b49eb",
        return_at_close: 1.0000617264972895,
        trade_pair: ["NZDCAD", "NZD/CAD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.8857924309489219,
        close_ms: 1713887009409,
        current_return: 1.0010416227525902,
        initial_entry_price: 0.885836211756929,
        is_closed_position: true,
        miner_hotkey: "5Dxqzduahnqw8q3XSUfTcEZGU7xmAsfJubhHZwvXVLN9fSjR",
        net_leverage: 0.0,
        open_ms: 1713885846654,
        orders: [
          {
            leverage: 1.0,
            order_type: "LONG",
            order_uuid: "7dc381a1-7856-4d11-ba4f-14da8953cbd6",
            price: 0.885836211756929,
            price_sources: [],
            processed_ms: 1713885846638,
          },
          {
            leverage: 1.0,
            order_type: "LONG",
            order_uuid: "e632c80d-8813-4eb6-82ac-27611cb38362",
            price: 0.88565,
            price_sources: [],
            processed_ms: 1713886104393,
          },
          {
            leverage: 1.0,
            order_type: "LONG",
            order_uuid: "5023d9fa-a535-4246-be5f-f89c79687f87",
            price: 0.885891081089837,
            price_sources: [],
            processed_ms: 1713886370662,
          },
          {
            leverage: 3.0,
            order_type: "FLAT",
            order_uuid: "72807568-dc04-401c-a655-8f5ba10ea066",
            price: 0.8861,
            price_sources: [],
            processed_ms: 1713887009409,
          },
        ],
        position_type: "FLAT",
        position_uuid: "de13882b-5669-41f2-9c92-93b72003a636",
        return_at_close: 1.0008314040118123,
        trade_pair: ["AUDCAD", "AUD/CAD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.8114051136781398,
        close_ms: 1713888879160,
        current_return: 1.0017418662790725,
        initial_entry_price: 0.811372092885247,
        is_closed_position: true,
        miner_hotkey: "5Dxqzduahnqw8q3XSUfTcEZGU7xmAsfJubhHZwvXVLN9fSjR",
        net_leverage: 0.0,
        open_ms: 1713885642550,
        orders: [
          {
            leverage: 1.0,
            order_type: "LONG",
            order_uuid: "fa53bd16-1468-4d01-a7a5-288e1295e821",
            price: 0.811372092885247,
            price_sources: [],
            processed_ms: 1713885642538,
          },
          {
            leverage: 1.0,
            order_type: "LONG",
            order_uuid: "719cbff6-b83c-435d-be7e-64a632412376",
            price: 0.811523964979967,
            price_sources: [],
            processed_ms: 1713886568588,
          },
          {
            leverage: 1.0,
            order_type: "LONG",
            order_uuid: "9f61aab0-1866-43bb-af68-7df94af67ef8",
            price: 0.811517874097987,
            price_sources: [],
            processed_ms: 1713886647587,
          },
          {
            leverage: 1.0,
            order_type: "LONG",
            order_uuid: "dc2c0205-34ad-4129-b40e-d8ed2417c998",
            price: 0.811337209302326,
            price_sources: [],
            processed_ms: 1713887253589,
          },
          {
            leverage: 1.0,
            order_type: "LONG",
            order_uuid: "c99fb93e-801f-46cc-8b2a-41be1f4174d3",
            price: 0.81135,
            price_sources: [],
            processed_ms: 1713888048949,
          },
          {
            leverage: 1.0,
            order_type: "LONG",
            order_uuid: "c17b6e9b-a9a6-4e57-aa6d-52c5146d67c8",
            price: 0.811329540803312,
            price_sources: [],
            processed_ms: 1713888252783,
          },
          {
            leverage: 6.0,
            order_type: "FLAT",
            order_uuid: "636c6448-82cd-4d64-9505-07b75437f19a",
            price: 0.811640663959536,
            price_sources: [],
            processed_ms: 1713888879160,
          },
        ],
        position_type: "FLAT",
        position_uuid: "1e3caf21-904d-4c5f-a749-3f5cb0b61803",
        return_at_close: 1.0013211346952353,
        trade_pair: ["NZDCAD", "NZD/CAD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.81092,
        close_ms: 1713898399247,
        current_return: 1.0003330866025166,
        initial_entry_price: 0.8106,
        is_closed_position: true,
        miner_hotkey: "5Dxqzduahnqw8q3XSUfTcEZGU7xmAsfJubhHZwvXVLN9fSjR",
        net_leverage: 0.0,
        open_ms: 1713897833648,
        orders: [
          {
            leverage: 0.5,
            order_type: "LONG",
            order_uuid: "6c8215e5-11b7-4d42-bd87-da693c293247",
            price: 0.8106,
            price_sources: [],
            processed_ms: 1713897833633,
          },
          {
            leverage: 0.5,
            order_type: "LONG",
            order_uuid: "facc40e3-fefb-4524-99f9-c050703de006",
            price: 0.81124,
            price_sources: [],
            processed_ms: 1713898274570,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "5b9bb77f-2fe0-4f75-8ab8-af1bad305d56",
            price: 0.81119,
            price_sources: [],
            processed_ms: 1713898399247,
          },
        ],
        position_type: "FLAT",
        position_uuid: "54d56e97-5174-4f61-995c-2d0e85393b0f",
        return_at_close: 1.0002630632864544,
        trade_pair: ["NZDCAD", "NZD/CAD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.8102865426138667,
        close_ms: 1713906089541,
        current_return: 1.0037523894537281,
        initial_entry_price: 0.8102496278416,
        is_closed_position: true,
        miner_hotkey: "5Dxqzduahnqw8q3XSUfTcEZGU7xmAsfJubhHZwvXVLN9fSjR",
        net_leverage: 0.0,
        open_ms: 1713902793936,
        orders: [
          {
            leverage: 1.0,
            order_type: "LONG",
            order_uuid: "38e3bba4-b9cb-4406-a777-80ebe0170a8f",
            price: 0.8102496278416,
            price_sources: [],
            processed_ms: 1713902789817,
          },
          {
            leverage: 1.0,
            order_type: "LONG",
            order_uuid: "149c3cc9-29d1-4d59-b57a-33e4fa7be1e8",
            price: 0.81029,
            price_sources: [],
            processed_ms: 1713904156565,
          },
          {
            leverage: 1.0,
            order_type: "LONG",
            order_uuid: "a3a5d7e9-3ecf-4710-9914-4b7ad7fd53c9",
            price: 0.81032,
            price_sources: [],
            processed_ms: 1713904304675,
          },
          {
            leverage: 3.0,
            order_type: "FLAT",
            order_uuid: "adbe0d7d-0b31-4db7-8490-6234afac6519",
            price: 0.8113,
            price_sources: [],
            processed_ms: 1713906089541,
          },
        ],
        position_type: "FLAT",
        position_uuid: "b95d3ce7-1213-4584-9fa1-26bab6707c56",
        return_at_close: 1.0035416014519427,
        trade_pair: ["NZDCAD", "NZD/CAD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 169.445,
        close_ms: 1713906195606,
        current_return: 1.0013278644988048,
        initial_entry_price: 169.445,
        is_closed_position: true,
        miner_hotkey: "5Dxqzduahnqw8q3XSUfTcEZGU7xmAsfJubhHZwvXVLN9fSjR",
        net_leverage: 0.0,
        open_ms: 1713905999524,
        orders: [
          {
            leverage: 1.0,
            order_type: "LONG",
            order_uuid: "3b2c6c79-4f88-4db3-9bb3-f40723a81741",
            price: 169.445,
            price_sources: [],
            processed_ms: 1713905999515,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "b5b37e41-add8-435d-8774-5fa403cdcad1",
            price: 169.67,
            price_sources: [],
            processed_ms: 1713906195606,
          },
        ],
        position_type: "FLAT",
        position_uuid: "e88ecc1d-efef-4779-9bbf-d9ee58b4d966",
        return_at_close: 1.00125777154829,
        trade_pair: ["CHFJPY", "CHF/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.59311,
        close_ms: 1713906778501,
        current_return: 0.9999764210526314,
        initial_entry_price: 0.59375,
        is_closed_position: true,
        miner_hotkey: "5Dxqzduahnqw8q3XSUfTcEZGU7xmAsfJubhHZwvXVLN9fSjR",
        net_leverage: 0.0,
        open_ms: 1713898290642,
        orders: [
          {
            leverage: 0.1,
            order_type: "LONG",
            order_uuid: "b64926cd-53de-4d92-9177-fafb6912624d",
            price: 0.59375,
            price_sources: [],
            processed_ms: 1713898290622,
          },
          {
            leverage: 0.1,
            order_type: "LONG",
            order_uuid: "e2bcee00-867c-4043-8830-cdebacada929",
            price: 0.59367,
            price_sources: [],
            processed_ms: 1713899016584,
          },
          {
            leverage: 0.2,
            order_type: "LONG",
            order_uuid: "e54157eb-f69a-411c-bd4f-a973957df980",
            price: 0.59307,
            price_sources: [],
            processed_ms: 1713902416615,
          },
          {
            leverage: 0.2,
            order_type: "LONG",
            order_uuid: "20468b75-8ef5-447d-9e69-73d302c4c2a3",
            price: 0.5931,
            price_sources: [],
            processed_ms: 1713902597073,
          },
          {
            leverage: 0.2,
            order_type: "LONG",
            order_uuid: "8294eefc-b287-4445-83d7-2b2338c5a8d1",
            price: 0.59307,
            price_sources: [],
            processed_ms: 1713903992964,
          },
          {
            leverage: 0.2,
            order_type: "LONG",
            order_uuid: "f89c79ea-45cd-44fe-a6b8-fb651dfd5753",
            price: 0.59319,
            price_sources: [],
            processed_ms: 1713904899678,
          },
          {
            leverage: 0.2,
            order_type: "LONG",
            order_uuid: "33f16c2e-530a-4aec-a814-16b7f36ea4e0",
            price: 0.59257,
            price_sources: [],
            processed_ms: 1713906262537,
          },
          {
            leverage: 0.2,
            order_type: "LONG",
            order_uuid: "9e7d6b79-2cf7-4ef3-8b19-f4612c2a5b6f",
            price: 0.59306,
            price_sources: [],
            processed_ms: 1713906323370,
          },
          {
            leverage: 1.4,
            order_type: "FLAT",
            order_uuid: "56402529-f782-41bd-9a3e-3aed9300cea7",
            price: 0.5931,
            price_sources: [],
            processed_ms: 1713906778501,
          },
        ],
        position_type: "FLAT",
        position_uuid: "7a55c6ac-2aee-45f1-bb58-7c1e9af2c6f2",
        return_at_close: 0.9998784233633682,
        trade_pair: ["NZDUSD", "NZD/USD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.8861726096062059,
        close_ms: 1713909880611,
        current_return: 1.0010954768725293,
        initial_entry_price: 0.88625,
        is_closed_position: true,
        miner_hotkey: "5Dxqzduahnqw8q3XSUfTcEZGU7xmAsfJubhHZwvXVLN9fSjR",
        net_leverage: 0.0,
        open_ms: 1713897857785,
        orders: [
          {
            leverage: 0.5,
            order_type: "LONG",
            order_uuid: "e5f7f408-163d-4dc6-ad8c-b469eb7b7fcc",
            price: 0.88625,
            price_sources: [],
            processed_ms: 1713897857771,
          },
          {
            leverage: 0.5,
            order_type: "LONG",
            order_uuid: "78c40f96-2f71-4934-bfba-f337faa129fd",
            price: 0.88614,
            price_sources: [],
            processed_ms: 1713899473713,
          },
          {
            leverage: 1.0,
            order_type: "LONG",
            order_uuid: "d22d9511-d123-4533-9839-942e29948c40",
            price: 0.886194102460984,
            price_sources: [],
            processed_ms: 1713905279595,
          },
          {
            leverage: 1.0,
            order_type: "LONG",
            order_uuid: "f3e6ee1a-eaba-4de8-8e9e-61f3b3ed4081",
            price: 0.886880031160737,
            price_sources: [],
            processed_ms: 1713906035702,
          },
          {
            leverage: 0.5,
            order_type: "LONG",
            order_uuid: "225cfff1-3340-47ed-9447-3250545d1b19",
            price: 0.88467,
            price_sources: [],
            processed_ms: 1713908691460,
          },
          {
            leverage: 3.5,
            order_type: "FLAT",
            order_uuid: "001256fa-c2af-4ddc-a0ee-06d988fae670",
            price: 0.88645,
            price_sources: [],
            processed_ms: 1713909880611,
          },
        ],
        position_type: "FLAT",
        position_uuid: "54f4d5bb-072b-4273-b517-0ba94b90a0ab",
        return_at_close: 1.0008502084806954,
        trade_pair: ["AUDCAD", "AUD/CAD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.6483699999999999,
        close_ms: 1713916868016,
        current_return: 1.000221975582686,
        initial_entry_price: 0.64872,
        is_closed_position: true,
        miner_hotkey: "5Dxqzduahnqw8q3XSUfTcEZGU7xmAsfJubhHZwvXVLN9fSjR",
        net_leverage: 0.0,
        open_ms: 1713905837779,
        orders: [
          {
            leverage: 0.2,
            order_type: "LONG",
            order_uuid: "e005084b-ea7d-49f0-bec6-c357f25a6317",
            price: 0.64872,
            price_sources: [],
            processed_ms: 1713905837762,
          },
          {
            leverage: 0.1,
            order_type: "LONG",
            order_uuid: "90a7ddff-0c15-403b-9f92-ef93c51b0c2c",
            price: 0.64767,
            price_sources: [],
            processed_ms: 1713908688063,
          },
          {
            leverage: 0.30000000000000004,
            order_type: "FLAT",
            order_uuid: "73106a19-69bd-4331-8d3d-ff1ec32ef244",
            price: 0.64885,
            price_sources: [],
            processed_ms: 1713916868016,
          },
        ],
        position_type: "FLAT",
        position_uuid: "e99a2267-bfd1-4fc9-82a3-0a6fdbe9a986",
        return_at_close: 1.0002009709211988,
        trade_pair: ["AUDUSD", "AUD/USD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 1.8026738461538463,
        close_ms: 1713923734778,
        current_return: 1.0003103696724485,
        initial_entry_price: 1.8043,
        is_closed_position: true,
        miner_hotkey: "5Dxqzduahnqw8q3XSUfTcEZGU7xmAsfJubhHZwvXVLN9fSjR",
        net_leverage: 0.0,
        open_ms: 1713903598582,
        orders: [
          {
            leverage: 1.0,
            order_type: "LONG",
            order_uuid: "990e4df0-4424-4109-9ff0-205f9ecf9a13",
            price: 1.8043,
            price_sources: [],
            processed_ms: 1713903598564,
          },
          {
            leverage: 0.5,
            order_type: "LONG",
            order_uuid: "10a999c1-d836-48f3-aa93-3987bedb412b",
            price: 1.8028,
            price_sources: [],
            processed_ms: 1713908520275,
          },
          {
            leverage: 0.5,
            order_type: "LONG",
            order_uuid: "7996de83-7c5d-416a-a93c-0e4170670ee4",
            price: 1.80346,
            price_sources: [],
            processed_ms: 1713908594588,
          },
          {
            leverage: 0.5,
            order_type: "LONG",
            order_uuid: "124b9404-2bdb-4da7-8b48-f8ab66348897",
            price: 1.80348,
            price_sources: [],
            processed_ms: 1713909226512,
          },
          {
            leverage: 1.0,
            order_type: "LONG",
            order_uuid: "1497750e-6fb4-4260-b7c1-9c4163ce0e0a",
            price: 1.80198,
            price_sources: [],
            processed_ms: 1713919388603,
          },
          {
            leverage: 1.0,
            order_type: "LONG",
            order_uuid: "177049d4-1075-4fd7-9107-cf023e6cd9c9",
            price: 1.80216,
            price_sources: [],
            processed_ms: 1713919511678,
          },
          {
            leverage: 1.0,
            order_type: "LONG",
            order_uuid: "48811795-e367-4e93-bc62-1676924269ab",
            price: 1.80177,
            price_sources: [],
            processed_ms: 1713920694588,
          },
          {
            leverage: 1.0,
            order_type: "LONG",
            order_uuid: "5fb382aa-3976-4873-8ec2-9d051352b5c9",
            price: 1.8023,
            price_sources: [],
            processed_ms: 1713922006327,
          },
          {
            leverage: 6.5,
            order_type: "FLAT",
            order_uuid: "eda4bc95-a3e8-4e57-bf0d-3e989620fdc2",
            price: 1.80276,
            price_sources: [],
            processed_ms: 1713923734778,
          },
        ],
        position_type: "FLAT",
        position_uuid: "b3eca53e-6bc0-4303-9b7e-4a8d91a976c3",
        return_at_close: 0.9998552284542476,
        trade_pair: ["EURNZD", "EUR/NZD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.8114336537742071,
        close_ms: 1713924415230,
        current_return: 1.0011948154955372,
        initial_entry_price: 0.81164,
        is_closed_position: true,
        miner_hotkey: "5Dxqzduahnqw8q3XSUfTcEZGU7xmAsfJubhHZwvXVLN9fSjR",
        net_leverage: 0.0,
        open_ms: 1713923325608,
        orders: [
          {
            leverage: 1.0,
            order_type: "LONG",
            order_uuid: "14df5ca7-d718-4576-9414-b98c60a29952",
            price: 0.81164,
            price_sources: [],
            processed_ms: 1713923325587,
          },
          {
            leverage: 1.0,
            order_type: "LONG",
            order_uuid: "c8d918e4-1563-4914-b35e-85225ef24eca",
            price: 0.811227307548414,
            price_sources: [],
            processed_ms: 1713923528704,
          },
          {
            leverage: 2.0,
            order_type: "FLAT",
            order_uuid: "96727361-1b73-467c-9170-8e65b807ae23",
            price: 0.811918533798606,
            price_sources: [],
            processed_ms: 1713924415230,
          },
        ],
        position_type: "FLAT",
        position_uuid: "2672d873-c41b-44c0-b23a-de8a58872fca",
        return_at_close: 1.0010546482213678,
        trade_pair: ["NZDCAD", "NZD/CAD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.8900758289465133,
        close_ms: 1713987261972,
        current_return: 1.0008493376250849,
        initial_entry_price: 0.89040748683954,
        is_closed_position: true,
        miner_hotkey: "5Dxqzduahnqw8q3XSUfTcEZGU7xmAsfJubhHZwvXVLN9fSjR",
        net_leverage: 0.0,
        open_ms: 1713981542734,
        orders: [
          {
            leverage: 0.5,
            order_type: "LONG",
            order_uuid: "d67499d7-d697-4d50-b45b-e93db019cb08",
            price: 0.89040748683954,
            price_sources: [],
            processed_ms: 1713981539488,
          },
          {
            leverage: 0.5,
            order_type: "LONG",
            order_uuid: "061c009b-4e18-40b5-b52f-074e6be3a4c2",
            price: 0.8899,
            price_sources: [],
            processed_ms: 1713982496859,
          },
          {
            leverage: 0.5,
            order_type: "LONG",
            order_uuid: "c64567bc-da86-4fc9-a1f7-6dc47a3f7462",
            price: 0.88992,
            price_sources: [],
            processed_ms: 1713983284746,
          },
          {
            leverage: 1.5,
            order_type: "FLAT",
            order_uuid: "8427776d-2674-433b-b813-4a94e4375664",
            price: 0.89058,
            price_sources: [],
            processed_ms: 1713987261972,
          },
        ],
        position_type: "FLAT",
        position_uuid: "37485b69-0254-4578-b24a-5e4918ea26b5",
        return_at_close: 1.0007442484446343,
        trade_pair: ["AUDCAD", "AUD/CAD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.8130240821725483,
        close_ms: 1713988714639,
        current_return: 1.0016136469418433,
        initial_entry_price: 0.81323,
        is_closed_position: true,
        miner_hotkey: "5Dxqzduahnqw8q3XSUfTcEZGU7xmAsfJubhHZwvXVLN9fSjR",
        net_leverage: 0.0,
        open_ms: 1713978585494,
        orders: [
          {
            leverage: 0.5,
            order_type: "LONG",
            order_uuid: "9dc49ddc-baae-4679-bb63-0a2d167a03a5",
            price: 0.81323,
            price_sources: [],
            processed_ms: 1713978585482,
          },
          {
            leverage: 0.5,
            order_type: "LONG",
            order_uuid: "87d14d74-eb2e-4587-b8bd-aea73cb76338",
            price: 0.813228821903508,
            price_sources: [],
            processed_ms: 1713979702602,
          },
          {
            leverage: 0.5,
            order_type: "LONG",
            order_uuid: "b5272da2-6c45-41b5-bd4a-914f62f3fce3",
            price: 0.8125,
            price_sources: [],
            processed_ms: 1713980581567,
          },
          {
            leverage: 0.5,
            order_type: "LONG",
            order_uuid: "7ba089b4-4493-44c7-9716-e283f7431236",
            price: 0.81294,
            price_sources: [],
            processed_ms: 1713981187707,
          },
          {
            leverage: 0.5,
            order_type: "LONG",
            order_uuid: "f6184c08-6fea-4be9-9b6e-5ac51f5efa60",
            price: 0.812945671131782,
            price_sources: [],
            processed_ms: 1713981918897,
          },
          {
            leverage: 0.5,
            order_type: "LONG",
            order_uuid: "91ba8752-4886-4b1e-9cdc-3e2bdb41a8aa",
            price: 0.8133,
            price_sources: [],
            processed_ms: 1713988096555,
          },
          {
            leverage: 0.1,
            order_type: "FLAT",
            order_uuid: "001627c5-2399-4d61-ae7d-d8152f6a0ead",
            price: 0.81346150420672,
            price_sources: [],
            processed_ms: 1713988714639,
          },
        ],
        position_type: "FLAT",
        position_uuid: "a331c972-adeb-4cea-af3d-757d430ed6cc",
        return_at_close: 1.0014033080759854,
        trade_pair: ["NZDCAD", "NZD/CAD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.8901394771920713,
        close_ms: 1714008595614,
        current_return: 1.0004856436504204,
        initial_entry_price: 0.89051,
        is_closed_position: true,
        miner_hotkey: "5Dxqzduahnqw8q3XSUfTcEZGU7xmAsfJubhHZwvXVLN9fSjR",
        net_leverage: 0.0,
        open_ms: 1714003443690,
        orders: [
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "c190ed44-46c8-4e17-9831-b53157845d16",
            price: 0.89051,
            price_sources: [],
            processed_ms: 1714003443668,
          },
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "84b7d3bc-dcc9-4a2d-9968-58340c1cded0",
            price: 0.89037,
            price_sources: [],
            processed_ms: 1714005988611,
          },
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "94ebd3d4-422b-44b1-bfa1-0f9646ea422d",
            price: 0.889538431576214,
            price_sources: [],
            processed_ms: 1714007853728,
          },
          {
            leverage: 0.8999999999999999,
            order_type: "FLAT",
            order_uuid: "67ed61da-552b-4ef1-b817-02189907482c",
            price: 0.89062,
            price_sources: [],
            processed_ms: 1714008595614,
          },
        ],
        position_type: "FLAT",
        position_uuid: "cd9ed700-cb0a-46d0-998d-015cad9317ce",
        return_at_close: 1.0004226130548703,
        trade_pair: ["AUDCAD", "AUD/CAD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.5937619999999999,
        close_ms: 1714008705695,
        current_return: 1.0001151902997643,
        initial_entry_price: 0.5938,
        is_closed_position: true,
        miner_hotkey: "5Dxqzduahnqw8q3XSUfTcEZGU7xmAsfJubhHZwvXVLN9fSjR",
        net_leverage: 0.0,
        open_ms: 1714003474489,
        orders: [
          {
            leverage: 0.06,
            order_type: "LONG",
            order_uuid: "6d14e11f-7053-4a92-900a-48372e51052b",
            price: 0.5938,
            price_sources: [],
            processed_ms: 1714003474468,
          },
          {
            leverage: 0.06,
            order_type: "LONG",
            order_uuid: "fb763bd3-84a4-484d-8e27-ec1017fa760f",
            price: 0.59388,
            price_sources: [],
            processed_ms: 1714004772331,
          },
          {
            leverage: 0.06,
            order_type: "LONG",
            order_uuid: "f99cd887-958d-46be-8fe6-031d421c4e99",
            price: 0.59386,
            price_sources: [],
            processed_ms: 1714005424654,
          },
          {
            leverage: 0.06,
            order_type: "LONG",
            order_uuid: "fdc190dd-6749-4e38-a046-8ecbb2236b2a",
            price: 0.59337,
            price_sources: [],
            processed_ms: 1714007661324,
          },
          {
            leverage: 0.06,
            order_type: "LONG",
            order_uuid: "1fe00b7e-facb-41b9-b50c-a3ebe15fb562",
            price: 0.5939,
            price_sources: [],
            processed_ms: 1714008402381,
          },
          {
            leverage: 0.3,
            order_type: "FLAT",
            order_uuid: "8b4d500c-f321-42c6-a24a-5c001b179576",
            price: 0.59399,
            price_sources: [],
            processed_ms: 1714008705695,
          },
        ],
        position_type: "FLAT",
        position_uuid: "cfe4b06c-18e1-4400-8c7e-f74e6e95ea54",
        return_at_close: 1.0000941878807679,
        trade_pair: ["NZDUSD", "NZD/USD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.8134790269698889,
        close_ms: 1714009399558,
        current_return: 1.0004075238767647,
        initial_entry_price: 0.81335,
        is_closed_position: true,
        miner_hotkey: "5Dxqzduahnqw8q3XSUfTcEZGU7xmAsfJubhHZwvXVLN9fSjR",
        net_leverage: 0.0,
        open_ms: 1714003163794,
        orders: [
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "c016808b-2242-43f8-8a2b-c6b5496ad4e9",
            price: 0.81335,
            price_sources: [],
            processed_ms: 1714003163775,
          },
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "d7eb5d96-d46d-48f1-ab31-68000ec01a95",
            price: 0.8137857087697,
            price_sources: [],
            processed_ms: 1714004350585,
          },
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "3b57e5c2-f432-4482-adc5-bfc52546c609",
            price: 0.81369,
            price_sources: [],
            processed_ms: 1714005765643,
          },
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "051bd99c-449f-4c77-8806-017620347870",
            price: 0.813443027902641,
            price_sources: [],
            processed_ms: 1714006598675,
          },
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "504cf51d-79b2-426b-9374-4ff281aaeb8e",
            price: 0.813126398177104,
            price_sources: [],
            processed_ms: 1714007827597,
          },
          {
            leverage: 1.5,
            order_type: "FLAT",
            order_uuid: "8f4e39ff-cfd3-4d98-b0c1-d0c293072905",
            price: 0.8137,
            price_sources: [],
            processed_ms: 1714009399558,
          },
        ],
        position_type: "FLAT",
        position_uuid: "1cc6cb0b-1436-4402-8202-956581299302",
        return_at_close: 1.0003024810867576,
        trade_pair: ["NZDCAD", "NZD/CAD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.8135045021340789,
        close_ms: 1714024438502,
        current_return: 1.0005818584732251,
        initial_entry_price: 0.81385,
        is_closed_position: true,
        miner_hotkey: "5Dxqzduahnqw8q3XSUfTcEZGU7xmAsfJubhHZwvXVLN9fSjR",
        net_leverage: 0.0,
        open_ms: 1714012172729,
        orders: [
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "bac14689-ddc7-47cb-8730-137aba0ea298",
            price: 0.81385,
            price_sources: [],
            processed_ms: 1714012172707,
          },
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "15364c17-b623-48f3-9325-eb3d3c1bf429",
            price: 0.81399,
            price_sources: [],
            processed_ms: 1714013109588,
          },
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "b33ec8bc-7cf7-415d-8a6e-9e1c8392d29f",
            price: 0.813752755840004,
            price_sources: [],
            processed_ms: 1714014016694,
          },
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "1de8485d-28e0-4a65-93fe-b296c12170ad",
            price: 0.81298,
            price_sources: [],
            processed_ms: 1714016068594,
          },
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "47b76c31-62c8-411e-8927-cd60f22ea7a0",
            price: 0.812977484402918,
            price_sources: [],
            processed_ms: 1714016830652,
          },
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "e93d3507-94ae-4644-b7a9-d6161d44fbe7",
            price: 0.813531274695631,
            price_sources: [],
            processed_ms: 1714020173725,
          },
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "fe94a303-4942-4c80-be5b-9c10d366af3c",
            price: 0.81345,
            price_sources: [],
            processed_ms: 1714022417592,
          },
          {
            leverage: 2.1,
            order_type: "FLAT",
            order_uuid: "1ba5e679-0348-4247-abac-c198c1431327",
            price: 0.81373,
            price_sources: [],
            processed_ms: 1714024438502,
          },
        ],
        position_type: "FLAT",
        position_uuid: "9ef98cb1-eed5-4273-afce-466e67de2aa5",
        return_at_close: 1.0004347729400296,
        trade_pair: ["NZDCAD", "NZD/CAD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.59416,
        close_ms: 1714025730171,
        current_return: 1.0000387101117545,
        initial_entry_price: 0.59416,
        is_closed_position: true,
        miner_hotkey: "5Dxqzduahnqw8q3XSUfTcEZGU7xmAsfJubhHZwvXVLN9fSjR",
        net_leverage: 0.0,
        open_ms: 1714023280991,
        orders: [
          {
            leverage: 0.09999999999999999,
            order_type: "LONG",
            order_uuid: "78d9248c-6f56-4382-9364-3828da84b6d4",
            price: 0.59416,
            price_sources: [],
            processed_ms: 1714023280961,
          },
          {
            leverage: 0.09999999999999999,
            order_type: "FLAT",
            order_uuid: "bcd17bfc-714a-4059-accc-3e44c98765f7",
            price: 0.59439,
            price_sources: [],
            processed_ms: 1714025730171,
          },
        ],
        position_type: "FLAT",
        position_uuid: "f0bea30c-30fb-43fb-a77d-609c462ae431",
        return_at_close: 1.0000317098407838,
        trade_pair: ["NZDUSD", "NZD/USD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.8909544011550079,
        close_ms: 1714025731581,
        current_return: 1.0004098245918953,
        initial_entry_price: 0.89072,
        is_closed_position: true,
        miner_hotkey: "5Dxqzduahnqw8q3XSUfTcEZGU7xmAsfJubhHZwvXVLN9fSjR",
        net_leverage: 0.0,
        open_ms: 1714021558776,
        orders: [
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "a6644ca1-3276-4bbe-b71a-2b2e90e79f0d",
            price: 0.89072,
            price_sources: [],
            processed_ms: 1714021554521,
          },
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "065875ad-7831-4f8e-ba91-b6ea270703f0",
            price: 0.891117562439383,
            price_sources: [],
            processed_ms: 1714023384568,
          },
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "6feb65d3-41cd-460b-919f-b744bb1ddb20",
            price: 0.891025641025641,
            price_sources: [],
            processed_ms: 1714025278560,
          },
          {
            leverage: 0.8999999999999999,
            order_type: "FLAT",
            order_uuid: "26035447-623b-4d89-8ac9-24c6b5d420af",
            price: 0.89136,
            price_sources: [],
            processed_ms: 1714025731581,
          },
        ],
        position_type: "FLAT",
        position_uuid: "ad6746b7-822a-480b-bf9a-9b0e2a388800",
        return_at_close: 1.000346798772946,
        trade_pair: ["AUDCAD", "AUD/CAD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 1.8020775000000002,
        close_ms: 1714057830303,
        current_return: 1.000194635862268,
        initial_entry_price: 1.80234,
        is_closed_position: true,
        miner_hotkey: "5Dxqzduahnqw8q3XSUfTcEZGU7xmAsfJubhHZwvXVLN9fSjR",
        net_leverage: 0.0,
        open_ms: 1714008501899,
        orders: [
          {
            leverage: 0.06,
            order_type: "LONG",
            order_uuid: "4c320552-6031-40aa-814f-585aaeb1af0d",
            price: 1.80234,
            price_sources: [],
            processed_ms: 1714008501879,
          },
          {
            leverage: 0.09999999999999999,
            order_type: "LONG",
            order_uuid: "ce130dc9-8aa4-486f-a7a9-55875d8632f3",
            price: 1.80192,
            price_sources: [],
            processed_ms: 1714021066499,
          },
          {
            leverage: 0.15999999999999998,
            order_type: "FLAT",
            order_uuid: "6b89397b-45fe-49f4-a4e8-d1a64f1a71b0",
            price: 1.80427,
            price_sources: [],
            processed_ms: 1714057830303,
          },
        ],
        position_type: "FLAT",
        position_uuid: "97b373ca-01a1-4ab3-80f2-9151e9da5ba4",
        return_at_close: 1.0001834336823463,
        trade_pair: ["EURNZD", "EUR/NZD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.65015,
        close_ms: 1714058872505,
        current_return: 1.0001127944833244,
        initial_entry_price: 0.65015,
        is_closed_position: true,
        miner_hotkey: "5Dxqzduahnqw8q3XSUfTcEZGU7xmAsfJubhHZwvXVLN9fSjR",
        net_leverage: 0.0,
        open_ms: 1714058066646,
        orders: [
          {
            leverage: 0.3333333333333333,
            order_type: "LONG",
            order_uuid: "fa41ccaf-e69b-4790-8908-7bd42ef96c69",
            price: 0.65015,
            price_sources: [],
            processed_ms: 1714058066623,
          },
          {
            leverage: 0.3333333333333333,
            order_type: "FLAT",
            order_uuid: "9fc11e55-31cb-43f5-9073-ed23b0142921",
            price: 0.65037,
            price_sources: [],
            processed_ms: 1714058872505,
          },
        ],
        position_type: "FLAT",
        position_uuid: "a1d358fc-92a2-407e-801e-176874065878",
        return_at_close: 1.0000894585181197,
        trade_pair: ["AUDUSD", "AUD/USD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 170.2172403936461,
        close_ms: 1714063121003,
        current_return: 1.0001785601375524,
        initial_entry_price: 170.2172403936461,
        is_closed_position: true,
        miner_hotkey: "5Dxqzduahnqw8q3XSUfTcEZGU7xmAsfJubhHZwvXVLN9fSjR",
        net_leverage: 0.0,
        open_ms: 1714062161491,
        orders: [
          {
            leverage: 1.0,
            order_type: "LONG",
            order_uuid: "8b984080-b930-4da3-b15b-88cae76620e9",
            price: 170.2172403936461,
            price_sources: [],
            processed_ms: 1714062161478,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "ad3ed422-5e2c-4dc0-9657-e11d7b67dd87",
            price: 170.2476344075046,
            price_sources: [],
            processed_ms: 1714063121003,
          },
        ],
        position_type: "FLAT",
        position_uuid: "166d7863-6c1f-46a8-a0ba-0ab9b5898726",
        return_at_close: 1.0001085476383427,
        trade_pair: ["CHFJPY", "CHF/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.6512,
        close_ms: 1714066191704,
        current_return: 1.0001126126126125,
        initial_entry_price: 0.6512,
        is_closed_position: true,
        miner_hotkey: "5Dxqzduahnqw8q3XSUfTcEZGU7xmAsfJubhHZwvXVLN9fSjR",
        net_leverage: 0.0,
        open_ms: 1714065592659,
        orders: [
          {
            leverage: 0.3333333333333333,
            order_type: "LONG",
            order_uuid: "13f178af-2094-41ed-a318-487b4710bafa",
            price: 0.6512,
            price_sources: [],
            processed_ms: 1714065592636,
          },
          {
            leverage: 0.3333333333333333,
            order_type: "FLAT",
            order_uuid: "c382ac57-2929-46bb-a1d4-0c0784828271",
            price: 0.65142,
            price_sources: [],
            processed_ms: 1714066191704,
          },
        ],
        position_type: "FLAT",
        position_uuid: "3c244b88-4bbe-47ad-ae8e-a2ac9da56876",
        return_at_close: 1.0000892766516516,
        trade_pair: ["AUDUSD", "AUD/USD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 1.80381,
        close_ms: 1714070205590,
        current_return: 1.0000942449592807,
        initial_entry_price: 1.80381,
        is_closed_position: true,
        miner_hotkey: "5Dxqzduahnqw8q3XSUfTcEZGU7xmAsfJubhHZwvXVLN9fSjR",
        net_leverage: 0.0,
        open_ms: 1714068143656,
        orders: [
          {
            leverage: 0.3333333333333333,
            order_type: "LONG",
            order_uuid: "a12e5a9e-4c03-405b-bafe-8176e046fa0c",
            price: 1.80381,
            price_sources: [],
            processed_ms: 1714068143642,
          },
          {
            leverage: 0.3333333333333333,
            order_type: "FLAT",
            order_uuid: "e58dbf11-070f-4393-85c2-ec2bab0b4992",
            price: 1.80432,
            price_sources: [],
            processed_ms: 1714070205590,
          },
        ],
        position_type: "FLAT",
        position_uuid: "0e91a3a9-4d32-4d2d-b7c5-8009c552a806",
        return_at_close: 1.0000709094268982,
        trade_pair: ["EURNZD", "EUR/NZD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 170.4988045854875,
        close_ms: 1714070536735,
        current_return: 1.0002592124596998,
        initial_entry_price: 170.4988045854875,
        is_closed_position: true,
        miner_hotkey: "5Dxqzduahnqw8q3XSUfTcEZGU7xmAsfJubhHZwvXVLN9fSjR",
        net_leverage: 0.0,
        open_ms: 1714069318114,
        orders: [
          {
            leverage: 1.0,
            order_type: "LONG",
            order_uuid: "24bdee31-5bda-44f4-8528-2be51dd0cc52",
            price: 170.4988045854875,
            price_sources: [],
            processed_ms: 1714069316192,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "4700e8cb-fef5-41a1-8511-a77240e624e8",
            price: 170.543,
            price_sources: [],
            processed_ms: 1714070536735,
          },
        ],
        position_type: "FLAT",
        position_uuid: "bb8cc174-06c7-45c5-b063-0cf70d8488e5",
        return_at_close: 1.0001891943148276,
        trade_pair: ["CHFJPY", "CHF/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.651575,
        close_ms: 1714072215213,
        current_return: 1.0001994997160966,
        initial_entry_price: 0.65163,
        is_closed_position: true,
        miner_hotkey: "5Dxqzduahnqw8q3XSUfTcEZGU7xmAsfJubhHZwvXVLN9fSjR",
        net_leverage: 0.0,
        open_ms: 1714068588622,
        orders: [
          {
            leverage: 0.3333333333333333,
            order_type: "LONG",
            order_uuid: "a67852d9-c008-4986-ab3d-231cca03103c",
            price: 0.65163,
            price_sources: [],
            processed_ms: 1714068588596,
          },
          {
            leverage: 0.3333333333333333,
            order_type: "LONG",
            order_uuid: "eccaca28-c8fc-4489-af8f-7bd027e1eff6",
            price: 0.65152,
            price_sources: [],
            processed_ms: 1714069570658,
          },
          {
            leverage: 0.6666666666666666,
            order_type: "FLAT",
            order_uuid: "da9190c4-7fd6-4c21-a478-0afedde66213",
            price: 0.65177,
            price_sources: [],
            processed_ms: 1714072215213,
          },
        ],
        position_type: "FLAT",
        position_uuid: "e4e00940-9768-4f2e-90a1-03f53f1c6dcd",
        return_at_close: 1.0001528237394433,
        trade_pair: ["AUDUSD", "AUD/USD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 1.8031799999999998,
        close_ms: 1714074060692,
        current_return: 1.0000813377847284,
        initial_entry_price: 1.80318,
        is_closed_position: true,
        miner_hotkey: "5Dxqzduahnqw8q3XSUfTcEZGU7xmAsfJubhHZwvXVLN9fSjR",
        net_leverage: 0.0,
        open_ms: 1714073192562,
        orders: [
          {
            leverage: 0.3333333333333333,
            order_type: "LONG",
            order_uuid: "92495f60-281f-4285-acc0-daf134e0d2e2",
            price: 1.80318,
            price_sources: [],
            processed_ms: 1714073192536,
          },
          {
            leverage: 0.3333333333333333,
            order_type: "FLAT",
            order_uuid: "93dd23c2-fa00-45ce-8917-ffd7cfcaaffd",
            price: 1.80362,
            price_sources: [],
            processed_ms: 1714074060692,
          },
        ],
        position_type: "FLAT",
        position_uuid: "26f51520-b724-49f1-813b-99a44ab3f9c3",
        return_at_close: 1.0000580025535133,
        trade_pair: ["EURNZD", "EUR/NZD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.65187,
        close_ms: 1714076099643,
        current_return: 1.0001840377890927,
        initial_entry_price: 0.65204,
        is_closed_position: true,
        miner_hotkey: "5Dxqzduahnqw8q3XSUfTcEZGU7xmAsfJubhHZwvXVLN9fSjR",
        net_leverage: 0.0,
        open_ms: 1714073117607,
        orders: [
          {
            leverage: 0.3333333333333333,
            order_type: "LONG",
            order_uuid: "74906169-a368-4c10-93e7-d27796c9ba88",
            price: 0.65204,
            price_sources: [],
            processed_ms: 1714073117580,
          },
          {
            leverage: 0.3333333333333333,
            order_type: "LONG",
            order_uuid: "2b8693ba-adc3-4250-a689-9f534027c4d4",
            price: 0.6517,
            price_sources: [],
            processed_ms: 1714074344650,
          },
          {
            leverage: 0.6666666666666666,
            order_type: "FLAT",
            order_uuid: "b684ed45-7b22-4357-bbb4-1bdb5313801d",
            price: 0.65205,
            price_sources: [],
            processed_ms: 1714076099643,
          },
        ],
        position_type: "FLAT",
        position_uuid: "dd7003be-1808-4f3d-b8d3-f53dd1bf3bbd",
        return_at_close: 1.000137362533996,
        trade_pair: ["AUDUSD", "AUD/USD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 170.56332564336915,
        close_ms: 1714110392376,
        current_return: 1.004382224663699,
        initial_entry_price: 170.5944245247362,
        is_closed_position: true,
        miner_hotkey: "5Dxqzduahnqw8q3XSUfTcEZGU7xmAsfJubhHZwvXVLN9fSjR",
        net_leverage: 0.0,
        open_ms: 1714073237497,
        orders: [
          {
            leverage: 1.0,
            order_type: "LONG",
            order_uuid: "4e4b4b4f-bd6b-4f8e-9582-73113af26595",
            price: 170.5944245247362,
            price_sources: [],
            processed_ms: 1714073237482,
          },
          {
            leverage: 1.0,
            order_type: "LONG",
            order_uuid: "932a6374-fe60-4f71-bb2a-440d919fdc0f",
            price: 170.5322267620021,
            price_sources: [],
            processed_ms: 1714078851603,
          },
          {
            leverage: 0.01,
            order_type: "FLAT",
            order_uuid: "5e8ab920-132b-4d3d-84f1-78218e3165f9",
            price: 170.9371171906901,
            price_sources: [],
            processed_ms: 1714110392376,
          },
        ],
        position_type: "FLAT",
        position_uuid: "87bb2f6a-caee-434b-9e7e-edee1f29aa5d",
        return_at_close: 1.004241611152246,
        trade_pair: ["CHFJPY", "CHF/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.8904174428897909,
        close_ms: 1714110394470,
        current_return: 1.0391633637147142,
        initial_entry_price: 0.891031725393317,
        is_closed_position: true,
        miner_hotkey: "5Dxqzduahnqw8q3XSUfTcEZGU7xmAsfJubhHZwvXVLN9fSjR",
        net_leverage: 0.0,
        open_ms: 1714057882659,
        orders: [
          {
            leverage: 1.0,
            order_type: "LONG",
            order_uuid: "30d3b556-ddde-4be7-9ecd-b93cdd36fd80",
            price: 0.891031725393317,
            price_sources: [],
            processed_ms: 1714057882635,
          },
          {
            leverage: 1.0,
            order_type: "LONG",
            order_uuid: "911da17b-09ae-46ba-8e1f-2393d4963ee5",
            price: 0.89086,
            price_sources: [],
            processed_ms: 1714059102666,
          },
          {
            leverage: 1.0,
            order_type: "LONG",
            order_uuid: "c32b2417-fd43-48d0-ab21-796c51cca32d",
            price: 0.89096,
            price_sources: [],
            processed_ms: 1714065268711,
          },
          {
            leverage: 1.0,
            order_type: "LONG",
            order_uuid: "64c7ba83-bddf-4275-9985-0536ce5f1c55",
            price: 0.890799356397913,
            price_sources: [],
            processed_ms: 1714066176635,
          },
          {
            leverage: 1.0,
            order_type: "LONG",
            order_uuid: "715e3994-f2f1-4e54-9e1a-cdbf62627e6e",
            price: 0.89083,
            price_sources: [],
            processed_ms: 1714067116738,
          },
          {
            leverage: 1.0,
            order_type: "LONG",
            order_uuid: "221033d0-f13b-4c64-8d60-ec7370eb0dc5",
            price: 0.890624694496041,
            price_sources: [],
            processed_ms: 1714068020687,
          },
          {
            leverage: 1.0,
            order_type: "LONG",
            order_uuid: "f12d7d05-38bd-4235-8f32-8d7d1ac0fed2",
            price: 0.890376282782212,
            price_sources: [],
            processed_ms: 1714069095725,
          },
          {
            leverage: 1.0,
            order_type: "LONG",
            order_uuid: "cb03a65d-0463-441f-aeec-6395467c555c",
            price: 0.889854176160406,
            price_sources: [],
            processed_ms: 1714070078669,
          },
          {
            leverage: 1.0,
            order_type: "LONG",
            order_uuid: "d5f23552-0d7c-4491-b461-be528a716c10",
            price: 0.889557971486231,
            price_sources: [],
            processed_ms: 1714070989048,
          },
          {
            leverage: 1.0,
            order_type: "LONG",
            order_uuid: "48d10006-4222-4831-914a-7e5089ac9f76",
            price: 0.8898,
            price_sources: [],
            processed_ms: 1714071918704,
          },
          {
            leverage: 1.0,
            order_type: "LONG",
            order_uuid: "60392060-e4ad-465b-81fb-3aede0ff7f5a",
            price: 0.89024,
            price_sources: [],
            processed_ms: 1714073064527,
          },
          {
            leverage: 1.0,
            order_type: "LONG",
            order_uuid: "a45708b6-8ed3-4dfc-a4f5-82c6f0f021be",
            price: 0.89026,
            price_sources: [],
            processed_ms: 1714074892555,
          },
          {
            leverage: 1.0,
            order_type: "LONG",
            order_uuid: "c7bbc241-6065-45ed-ab59-f77b733ffc4c",
            price: 0.890319993740954,
            price_sources: [],
            processed_ms: 1714075801742,
          },
          {
            leverage: 1.0,
            order_type: "LONG",
            order_uuid: "945bbeee-1912-47ea-b153-21e58e17979f",
            price: 0.89033,
            price_sources: [],
            processed_ms: 1714077765660,
          },
          {
            leverage: 0.01,
            order_type: "FLAT",
            order_uuid: "260972e5-3e69-4425-aaa2-a6812203fc7c",
            price: 0.89291,
            price_sources: [],
            processed_ms: 1714110394470,
          },
        ],
        position_type: "FLAT",
        position_uuid: "c53f75bb-e59d-4696-8f9d-71e97c32741b",
        return_at_close: 1.0381449836182737,
        trade_pair: ["AUDCAD", "AUD/CAD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.8126903113236075,
        close_ms: 1714110395984,
        current_return: 1.0233765527557752,
        initial_entry_price: 0.81365,
        is_closed_position: true,
        miner_hotkey: "5Dxqzduahnqw8q3XSUfTcEZGU7xmAsfJubhHZwvXVLN9fSjR",
        net_leverage: 0.0,
        open_ms: 1714058822557,
        orders: [
          {
            leverage: 1.0,
            order_type: "LONG",
            order_uuid: "2bf80df3-f4cd-4db1-a79a-5883a58a85c4",
            price: 0.81365,
            price_sources: [],
            processed_ms: 1714058822534,
          },
          {
            leverage: 1.0,
            order_type: "LONG",
            order_uuid: "6f4a283c-0888-4b12-995f-d66d403e2a9f",
            price: 0.81315,
            price_sources: [],
            processed_ms: 1714065319843,
          },
          {
            leverage: 1.0,
            order_type: "LONG",
            order_uuid: "9b1469c2-bc7d-4ef7-83cd-93fd20ceb505",
            price: 0.812972310912129,
            price_sources: [],
            processed_ms: 1714066328654,
          },
          {
            leverage: 1.0,
            order_type: "LONG",
            order_uuid: "16a13727-a93b-4d81-96f2-6f1891e32046",
            price: 0.8130808315187,
            price_sources: [],
            processed_ms: 1714067248641,
          },
          {
            leverage: 1.0,
            order_type: "LONG",
            order_uuid: "2e2862df-f557-406f-b059-234a8716b7cb",
            price: 0.81272,
            price_sources: [],
            processed_ms: 1714068236648,
          },
          {
            leverage: 1.0,
            order_type: "LONG",
            order_uuid: "1814d92f-30e5-4cb1-a040-da2cb298e64c",
            price: 0.8127,
            price_sources: [],
            processed_ms: 1714069181498,
          },
          {
            leverage: 1.0,
            order_type: "LONG",
            order_uuid: "ec23801c-dfcb-4883-98cf-bd7f4a32cc0d",
            price: 0.81242,
            price_sources: [],
            processed_ms: 1714070117620,
          },
          {
            leverage: 1.0,
            order_type: "LONG",
            order_uuid: "9212318e-e8d4-4cb8-a061-21283612ddae",
            price: 0.8123,
            price_sources: [],
            processed_ms: 1714071029569,
          },
          {
            leverage: 1.0,
            order_type: "LONG",
            order_uuid: "b268d083-921a-46b0-9287-ddde1f58facd",
            price: 0.81251,
            price_sources: [],
            processed_ms: 1714071937114,
          },
          {
            leverage: 1.0,
            order_type: "LONG",
            order_uuid: "e5a71545-b3cb-45e9-a610-817adc2887ff",
            price: 0.81246,
            price_sources: [],
            processed_ms: 1714072876687,
          },
          {
            leverage: 1.0,
            order_type: "LONG",
            order_uuid: "95d1c3b1-0841-4986-8941-8123218f7019",
            price: 0.81255,
            price_sources: [],
            processed_ms: 1714073080547,
          },
          {
            leverage: 1.0,
            order_type: "LONG",
            order_uuid: "b78ea06d-2981-4b0f-8314-0f8ff3e7e82b",
            price: 0.81252,
            price_sources: [],
            processed_ms: 1714073982603,
          },
          {
            leverage: 1.0,
            order_type: "LONG",
            order_uuid: "871cd10f-d9a9-41b7-bdfe-8b9910c7dfdc",
            price: 0.812601838746893,
            price_sources: [],
            processed_ms: 1714074953571,
          },
          {
            leverage: 1.0,
            order_type: "LONG",
            order_uuid: "2bfdfd34-8723-4b93-9047-5866158516bd",
            price: 0.81248,
            price_sources: [],
            processed_ms: 1714075859207,
          },
          {
            leverage: 1.0,
            order_type: "LONG",
            order_uuid: "0b50d3b8-acd3-43a7-97eb-8b48e5549bc0",
            price: 0.81265,
            price_sources: [],
            processed_ms: 1714077832549,
          },
          {
            leverage: 1.0,
            order_type: "LONG",
            order_uuid: "45cc9e43-519f-4106-9804-00b5e4ce8477",
            price: 0.81228,
            price_sources: [],
            processed_ms: 1714078751993,
          },
          {
            leverage: 0.01,
            order_type: "FLAT",
            order_uuid: "6a6af028-6d5c-4ab9-b0c2-9cfa22343409",
            price: 0.813879082082966,
            price_sources: [],
            processed_ms: 1714110395984,
          },
        ],
        position_type: "FLAT",
        position_uuid: "23b5e33b-7701-49d9-a626-ef892dc5ccb8",
        return_at_close: 1.0222303710166887,
        trade_pair: ["NZDCAD", "NZD/CAD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.65181,
        close_ms: 1714110511814,
        current_return: 1.001119958270048,
        initial_entry_price: 0.65181,
        is_closed_position: true,
        miner_hotkey: "5Dxqzduahnqw8q3XSUfTcEZGU7xmAsfJubhHZwvXVLN9fSjR",
        net_leverage: 0.0,
        open_ms: 1714078624172,
        orders: [
          {
            leverage: 0.3333333333333333,
            order_type: "LONG",
            order_uuid: "00249f6d-a3fb-4660-963f-311967d8de95",
            price: 0.65181,
            price_sources: [],
            processed_ms: 1714078621818,
          },
          {
            leverage: 0.3333333333333333,
            order_type: "FLAT",
            order_uuid: "7fd8bfb3-79b4-4c44-a358-468174873686",
            price: 0.654,
            price_sources: [],
            processed_ms: 1714110511814,
          },
        ],
        position_type: "FLAT",
        position_uuid: "3c4535f0-adfe-445d-a7f7-7b3781336f76",
        return_at_close: 1.001096598804355,
        trade_pair: ["AUDUSD", "AUD/USD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.8926200000000001,
        close_ms: 1714351319828,
        current_return: 1.0000649772579597,
        initial_entry_price: 0.89262,
        is_closed_position: true,
        miner_hotkey: "5Dxqzduahnqw8q3XSUfTcEZGU7xmAsfJubhHZwvXVLN9fSjR",
        net_leverage: 0.0,
        open_ms: 1714110534260,
        orders: [
          {
            leverage: 0.1,
            order_type: "LONG",
            order_uuid: "81df0ea1-7113-4c9d-8956-7f634f84ddc6",
            price: 0.89262,
            price_sources: [],
            processed_ms: 1714110534244,
          },
          {
            leverage: 0.1,
            order_type: "FLAT",
            order_uuid: "65c929c2-be09-4a19-b42b-2d68308dea43",
            price: 0.8932,
            price_sources: [],
            processed_ms: 1714351319828,
          },
        ],
        position_type: "FLAT",
        position_uuid: "cd28fa32-1f30-4e6a-bd3d-836c1c3da0e1",
        return_at_close: 1.000057976803119,
        trade_pair: ["AUDCAD", "AUD/CAD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.81204,
        close_ms: 1714351894864,
        current_return: 1.0000034481060047,
        initial_entry_price: 0.81204,
        is_closed_position: true,
        miner_hotkey: "5Dxqzduahnqw8q3XSUfTcEZGU7xmAsfJubhHZwvXVLN9fSjR",
        net_leverage: 0.0,
        open_ms: 1714351706473,
        orders: [
          {
            leverage: 0.02,
            order_type: "LONG",
            order_uuid: "25f4e102-f8a6-4dff-9079-cde5d41d80b0",
            price: 0.81204,
            price_sources: [],
            processed_ms: 1714351706456,
          },
          {
            leverage: 0.02,
            order_type: "FLAT",
            order_uuid: "8dd839bc-6bfe-4949-ae47-7159e90131cf",
            price: 0.81218,
            price_sources: [],
            processed_ms: 1714351894864,
          },
        ],
        position_type: "FLAT",
        position_uuid: "efc4c6d4-52f5-42f3-8406-1515c632566d",
        return_at_close: 1.0000020481011773,
        trade_pair: ["NZDCAD", "NZD/CAD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.8939584397218839,
        close_ms: 1714352154378,
        current_return: 1.000007194076678,
        initial_entry_price: 0.893958439721884,
        is_closed_position: true,
        miner_hotkey: "5Dxqzduahnqw8q3XSUfTcEZGU7xmAsfJubhHZwvXVLN9fSjR",
        net_leverage: 0.0,
        open_ms: 1714351651276,
        orders: [
          {
            leverage: 0.02,
            order_type: "LONG",
            order_uuid: "405e1356-a5b9-41e6-a2c4-cf3546405a21",
            price: 0.893958439721884,
            price_sources: [],
            processed_ms: 1714351651245,
          },
          {
            leverage: 0.02,
            order_type: "FLAT",
            order_uuid: "d77e1934-b29b-45f7-bf1c-af90defd4acf",
            price: 0.89428,
            price_sources: [],
            processed_ms: 1714352154378,
          },
        ],
        position_type: "FLAT",
        position_uuid: "33705fd7-5f45-4452-837c-bd514f29246e",
        return_at_close: 1.0000057940666063,
        trade_pair: ["AUDCAD", "AUD/CAD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.89404,
        close_ms: 1714353435677,
        current_return: 1.0000333523917173,
        initial_entry_price: 0.89404,
        is_closed_position: true,
        miner_hotkey: "5Dxqzduahnqw8q3XSUfTcEZGU7xmAsfJubhHZwvXVLN9fSjR",
        net_leverage: 0.0,
        open_ms: 1714352595546,
        orders: [
          {
            leverage: 0.2,
            order_type: "LONG",
            order_uuid: "c19103b7-1193-4718-95fd-9d245ba001a0",
            price: 0.89404,
            price_sources: [],
            processed_ms: 1714352595520,
          },
          {
            leverage: 0.2,
            order_type: "FLAT",
            order_uuid: "0199acdd-3967-4bc9-9039-36c60af1f970",
            price: 0.894189091861455,
            price_sources: [],
            processed_ms: 1714353435677,
          },
        ],
        position_type: "FLAT",
        position_uuid: "68ee5813-f31c-4e4d-9de6-a2e48e328792",
        return_at_close: 1.000019351924784,
        trade_pair: ["AUDCAD", "AUD/CAD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.8125475000000001,
        close_ms: 1714354651431,
        current_return: 1.000783067933558,
        initial_entry_price: 0.81232,
        is_closed_position: true,
        miner_hotkey: "5Dxqzduahnqw8q3XSUfTcEZGU7xmAsfJubhHZwvXVLN9fSjR",
        net_leverage: 0.0,
        open_ms: 1714352622459,
        orders: [
          {
            leverage: 0.2,
            order_type: "LONG",
            order_uuid: "5da7d993-f1ed-4d82-a888-669d3409303e",
            price: 0.81232,
            price_sources: [],
            processed_ms: 1714352622440,
          },
          {
            leverage: 0.2,
            order_type: "LONG",
            order_uuid: "e22a7c8a-9d7f-4081-bca9-41cb6abe5860",
            price: 0.81236,
            price_sources: [],
            processed_ms: 1714352879149,
          },
          {
            leverage: 0.2,
            order_type: "LONG",
            order_uuid: "b6d47e12-f5e3-4ad7-880c-f891aac0eaf6",
            price: 0.81253,
            price_sources: [],
            processed_ms: 1714353142095,
          },
          {
            leverage: 0.2,
            order_type: "LONG",
            order_uuid: "6529e8dd-20b8-4451-94ff-0126eefa9cd1",
            price: 0.81298,
            price_sources: [],
            processed_ms: 1714354344314,
          },
          {
            leverage: 0.8,
            order_type: "FLAT",
            order_uuid: "3dee2116-3b72-45fa-afb1-192b428f9521",
            price: 0.813342627179735,
            price_sources: [],
            processed_ms: 1714354651431,
          },
        ],
        position_type: "FLAT",
        position_uuid: "5f7edfac-aacf-453c-b46b-0590bf942684",
        return_at_close: 1.0007270240817538,
        trade_pair: ["NZDCAD", "NZD/CAD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.8943600000000002,
        close_ms: 1714354721565,
        current_return: 1.0002280960686971,
        initial_entry_price: 0.89436,
        is_closed_position: true,
        miner_hotkey: "5Dxqzduahnqw8q3XSUfTcEZGU7xmAsfJubhHZwvXVLN9fSjR",
        net_leverage: 0.0,
        open_ms: 1714354421167,
        orders: [
          {
            leverage: 0.2,
            order_type: "LONG",
            order_uuid: "7b6b156c-acf8-4d06-b067-3fe2bc32b807",
            price: 0.89436,
            price_sources: [],
            processed_ms: 1714354421150,
          },
          {
            leverage: 0.2,
            order_type: "FLAT",
            order_uuid: "0896b79b-2b6d-42ed-b010-b8a0f471fd4a",
            price: 0.89538,
            price_sources: [],
            processed_ms: 1714354721565,
          },
        ],
        position_type: "FLAT",
        position_uuid: "9575c4f2-4b81-4603-b839-73ca4a1a5096",
        return_at_close: 1.0002140928753522,
        trade_pair: ["AUDCAD", "AUD/CAD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 174.455,
        close_ms: 1714354855075,
        current_return: 0.9999724655036225,
        initial_entry_price: 174.455,
        is_closed_position: true,
        miner_hotkey: "5Dxqzduahnqw8q3XSUfTcEZGU7xmAsfJubhHZwvXVLN9fSjR",
        net_leverage: 0.0,
        open_ms: 1714354547554,
        orders: [
          {
            leverage: 0.2,
            order_type: "LONG",
            order_uuid: "a246057c-4c17-418f-930c-dc39cc6801ba",
            price: 174.455,
            price_sources: [],
            processed_ms: 1714354547538,
          },
          {
            leverage: 0.2,
            order_type: "FLAT",
            order_uuid: "e2f585f2-47dc-42d6-b9f2-a0ce4cc06581",
            price: 174.4309823471723,
            price_sources: [],
            processed_ms: 1714354855075,
          },
        ],
        position_type: "FLAT",
        position_uuid: "b8891c64-a891-4b15-98a1-e46eea8c0fed",
        return_at_close: 0.9999584658891055,
        trade_pair: ["CHFJPY", "CHF/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.65583,
        close_ms: 1714356153845,
        current_return: 0.9999994917382045,
        initial_entry_price: 0.65583,
        is_closed_position: true,
        miner_hotkey: "5Dxqzduahnqw8q3XSUfTcEZGU7xmAsfJubhHZwvXVLN9fSjR",
        net_leverage: 0.0,
        open_ms: 1714355850648,
        orders: [
          {
            leverage: 0.03333333333333333,
            order_type: "LONG",
            order_uuid: "120a874f-9986-4671-adb6-54dac3d3f21b",
            price: 0.65583,
            price_sources: [],
            processed_ms: 1714355850632,
          },
          {
            leverage: 0.03333333333333333,
            order_type: "FLAT",
            order_uuid: "778c4f3c-7c73-454f-8bab-66eef3cb32bf",
            price: 0.65582,
            price_sources: [],
            processed_ms: 1714356153845,
          },
        ],
        position_type: "FLAT",
        position_uuid: "beb50929-83fc-4cc7-a85e-23ef15370ca3",
        return_at_close: 0.9999971584060571,
        trade_pair: ["AUDUSD", "AUD/USD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.8951854356280455,
        close_ms: 1714356736731,
        current_return: 1.0002522572650094,
        initial_entry_price: 0.89522,
        is_closed_position: true,
        miner_hotkey: "5Dxqzduahnqw8q3XSUfTcEZGU7xmAsfJubhHZwvXVLN9fSjR",
        net_leverage: 0.0,
        open_ms: 1714355166912,
        orders: [
          {
            leverage: 0.2,
            order_type: "LONG",
            order_uuid: "49735268-e3f0-4284-8cf5-df691444fed5",
            price: 0.89522,
            price_sources: [],
            processed_ms: 1714355166895,
          },
          {
            leverage: 0.2,
            order_type: "LONG",
            order_uuid: "15e67ae9-f7da-4594-81f6-e7c8307c96bf",
            price: 0.895150871256091,
            price_sources: [],
            processed_ms: 1714356387418,
          },
          {
            leverage: 0.4,
            order_type: "FLAT",
            order_uuid: "c091c806-b209-4274-851e-92dbdc050cda",
            price: 0.89575,
            price_sources: [],
            processed_ms: 1714356736731,
          },
        ],
        position_type: "FLAT",
        position_uuid: "e3f041c5-f5f2-45e3-bbc9-07255a010de1",
        return_at_close: 1.000224250201806,
        trade_pair: ["AUDCAD", "AUD/CAD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.8134465477175166,
        close_ms: 1714356954698,
        current_return: 1.0002820234230403,
        initial_entry_price: 0.813715964903915,
        is_closed_position: true,
        miner_hotkey: "5Dxqzduahnqw8q3XSUfTcEZGU7xmAsfJubhHZwvXVLN9fSjR",
        net_leverage: 0.0,
        open_ms: 1714355142703,
        orders: [
          {
            leverage: 0.2,
            order_type: "LONG",
            order_uuid: "ef4d23ee-98ca-4f8b-a574-e21bab97d665",
            price: 0.813715964903915,
            price_sources: [],
            processed_ms: 1714355142685,
          },
          {
            leverage: 0.2,
            order_type: "LONG",
            order_uuid: "cfd4451c-8b5c-4572-b6b7-d2462cefe16f",
            price: 0.8129,
            price_sources: [],
            processed_ms: 1714356365955,
          },
          {
            leverage: 0.2,
            order_type: "LONG",
            order_uuid: "2c606fde-3f86-4951-8b41-a162f0c55369",
            price: 0.813723678248635,
            price_sources: [],
            processed_ms: 1714356654602,
          },
          {
            leverage: 0.6000000000000001,
            order_type: "FLAT",
            order_uuid: "04cc9b68-caf1-472c-8b1d-f74d1301f625",
            price: 0.813829025987191,
            price_sources: [],
            processed_ms: 1714356954698,
          },
        ],
        position_type: "FLAT",
        position_uuid: "44222d59-8113-4102-92be-7ff828f191a6",
        return_at_close: 1.0002400115780565,
        trade_pair: ["NZDCAD", "NZD/CAD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.89566,
        close_ms: 1714406884977,
        current_return: 1.0002729973508846,
        initial_entry_price: 0.89559,
        is_closed_position: true,
        miner_hotkey: "5Dxqzduahnqw8q3XSUfTcEZGU7xmAsfJubhHZwvXVLN9fSjR",
        net_leverage: 0.0,
        open_ms: 1714404624173,
        orders: [
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "4ea00636-08c7-4a15-9926-877334fdb795",
            price: 0.89559,
            price_sources: [],
            processed_ms: 1714404624134,
          },
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "3d4a143e-1419-41dc-a6f6-0bdc5eb2c7a5",
            price: 0.89573,
            price_sources: [],
            processed_ms: 1714406105335,
          },
          {
            leverage: 0.6,
            order_type: "FLAT",
            order_uuid: "9f39fdd3-5669-47e6-8ee1-ea34fa1093d2",
            price: 0.896067489495798,
            price_sources: [],
            processed_ms: 1714406884977,
          },
        ],
        position_type: "FLAT",
        position_uuid: "5eff4676-6087-470f-a492-4adea4a4f431",
        return_at_close: 1.0002309858849958,
        trade_pair: ["AUDCAD", "AUD/CAD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.8151174029762831,
        close_ms: 1714407733750,
        current_return: 1.0006658554738759,
        initial_entry_price: 0.81529,
        is_closed_position: true,
        miner_hotkey: "5Dxqzduahnqw8q3XSUfTcEZGU7xmAsfJubhHZwvXVLN9fSjR",
        net_leverage: 0.0,
        open_ms: 1714403428295,
        orders: [
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "341162aa-9378-4485-adc2-4cc80829a861",
            price: 0.81529,
            price_sources: [],
            processed_ms: 1714403428263,
          },
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "f0b45175-03d1-4518-8f9a-4a9ac495437e",
            price: 0.81506,
            price_sources: [],
            processed_ms: 1714404352181,
          },
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "0ab2932b-cf9f-4136-8d97-b075d836f4a2",
            price: 0.815002208928849,
            price_sources: [],
            processed_ms: 1714405811025,
          },
          {
            leverage: 0.8999999999999999,
            order_type: "FLAT",
            order_uuid: "2186dfe4-0b60-41bd-a500-4e5cebd4a252",
            price: 0.815720586653279,
            price_sources: [],
            processed_ms: 1714407733750,
          },
        ],
        position_type: "FLAT",
        position_uuid: "c0a09dc1-ed4b-45c4-a1bf-afa93c26b459",
        return_at_close: 1.000602813524981,
        trade_pair: ["NZDCAD", "NZD/CAD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 171.5055802084508,
        close_ms: 1714442365914,
        current_return: 1.0009128852159086,
        initial_entry_price: 171.5055802084508,
        is_closed_position: true,
        miner_hotkey: "5Dxqzduahnqw8q3XSUfTcEZGU7xmAsfJubhHZwvXVLN9fSjR",
        net_leverage: 0.0,
        open_ms: 1714408934850,
        orders: [
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "473186aa-e899-40f3-8d2f-738b5c5c99ee",
            price: 171.5055802084508,
            price_sources: [],
            processed_ms: 1714408934819,
          },
          {
            leverage: 0.3,
            order_type: "FLAT",
            order_uuid: "e2936ece-cb0c-41ee-8213-8cc338651cb7",
            price: 172.0274632371779,
            price_sources: [],
            processed_ms: 1714442365914,
          },
        ],
        position_type: "FLAT",
        position_uuid: "fd7510ad-dfed-4409-84dc-3eee0525e87b",
        return_at_close: 1.000891866045319,
        trade_pair: ["CHFJPY", "CHF/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.894511317545467,
        close_ms: 1714443145659,
        current_return: 1.0001445942882214,
        initial_entry_price: 0.894511317545467,
        is_closed_position: true,
        miner_hotkey: "5Dxqzduahnqw8q3XSUfTcEZGU7xmAsfJubhHZwvXVLN9fSjR",
        net_leverage: 0.0,
        open_ms: 1714442841857,
        orders: [
          {
            leverage: 0.5,
            order_type: "LONG",
            order_uuid: "614e669a-0540-4759-a1fc-bcb2924eea8c",
            price: 0.894511317545467,
            price_sources: [],
            processed_ms: 1714442841840,
          },
          {
            leverage: 0.5,
            order_type: "FLAT",
            order_uuid: "eaa2c3d3-9c3c-4edc-b59b-7b2891ef8b11",
            price: 0.89477,
            price_sources: [],
            processed_ms: 1714443145659,
          },
        ],
        position_type: "FLAT",
        position_uuid: "cdb9bff5-8113-48c4-91c8-3d8333cf426c",
        return_at_close: 1.0001095892274214,
        trade_pair: ["AUDCAD", "AUD/CAD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.89422,
        close_ms: 1714445337707,
        current_return: 1.0001733354208138,
        initial_entry_price: 0.89422,
        is_closed_position: true,
        miner_hotkey: "5Dxqzduahnqw8q3XSUfTcEZGU7xmAsfJubhHZwvXVLN9fSjR",
        net_leverage: 0.0,
        open_ms: 1714444950958,
        orders: [
          {
            leverage: 0.5,
            order_type: "LONG",
            order_uuid: "27647922-af76-4af5-ade4-624d11c08a60",
            price: 0.89422,
            price_sources: [],
            processed_ms: 1714444950928,
          },
          {
            leverage: 0.5,
            order_type: "FLAT",
            order_uuid: "84995fb3-a6db-49fa-86ed-7517d8156f26",
            price: 0.89453,
            price_sources: [],
            processed_ms: 1714445337707,
          },
        ],
        position_type: "FLAT",
        position_uuid: "e56a8744-ea09-4f12-8a28-0964a7d19084",
        return_at_close: 1.000138329354074,
        trade_pair: ["AUDCAD", "AUD/CAD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 63666.115,
        close_ms: 1714450727086,
        current_return: 1.0000326748674178,
        initial_entry_price: 63734.0,
        is_closed_position: true,
        miner_hotkey: "5Dxqzduahnqw8q3XSUfTcEZGU7xmAsfJubhHZwvXVLN9fSjR",
        net_leverage: 0.0,
        open_ms: 1714450385049,
        orders: [
          {
            leverage: -0.01,
            order_type: "SHORT",
            order_uuid: "191ebbc2-05b8-43ab-b678-5a5c51351e73",
            price: 63734.0,
            price_sources: [],
            processed_ms: 1714450385008,
          },
          {
            leverage: -0.01,
            order_type: "SHORT",
            order_uuid: "8565edd3-94cb-4541-9bc7-2e1aa665a2bc",
            price: 63598.23,
            price_sources: [],
            processed_ms: 1714450607115,
          },
          {
            leverage: 0.01,
            order_type: "FLAT",
            order_uuid: "730506da-abd3-4683-8caa-080427c86136",
            price: 63561.99,
            price_sources: [],
            processed_ms: 1714450727086,
          },
        ],
        position_type: "FLAT",
        position_uuid: "503880f9-268e-4e04-a6e1-b6b85e86b6aa",
        return_at_close: 1.0000126742139204,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 63534.054000000004,
        close_ms: 1714452765158,
        current_return: 1.0007381850567305,
        initial_entry_price: 63530.14,
        is_closed_position: true,
        miner_hotkey: "5Dxqzduahnqw8q3XSUfTcEZGU7xmAsfJubhHZwvXVLN9fSjR",
        net_leverage: 0.0,
        open_ms: 1714450929593,
        orders: [
          {
            leverage: -0.1,
            order_type: "SHORT",
            order_uuid: "64fd3c5f-df47-4cc5-8d7a-c65f447877d7",
            price: 63530.14,
            price_sources: [],
            processed_ms: 1714450929563,
          },
          {
            leverage: -0.1,
            order_type: "SHORT",
            order_uuid: "51c77034-dd4e-4914-be52-41fd84ae238c",
            price: 63463.94,
            price_sources: [],
            processed_ms: 1714451160713,
          },
          {
            leverage: -0.1,
            order_type: "SHORT",
            order_uuid: "4ea23e68-9897-415d-9ab7-2767f1cd75fd",
            price: 63506.19,
            price_sources: [],
            processed_ms: 1714451342131,
          },
          {
            leverage: -0.1,
            order_type: "SHORT",
            order_uuid: "69572dc3-a51d-458d-8cde-714f7f5b1c41",
            price: 63482.0,
            price_sources: [],
            processed_ms: 1714451645754,
          },
          {
            leverage: -0.1,
            order_type: "SHORT",
            order_uuid: "a2d77209-dabd-480c-b37a-087bd4946fba",
            price: 63688.0,
            price_sources: [],
            processed_ms: 1714452147978,
          },
          {
            leverage: 0.5,
            order_type: "FLAT",
            order_uuid: "c34d82e0-8d60-4028-9f8f-4230166da266",
            price: 63440.26,
            price_sources: [],
            processed_ms: 1714452765158,
          },
        ],
        position_type: "FLAT",
        position_uuid: "40768c81-2b14-42db-86f7-b877a2bf6c51",
        return_at_close: 1.0002378159642022,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 63511.0,
        close_ms: 1714453089179,
        current_return: 1.0001303160082506,
        initial_entry_price: 63511.0,
        is_closed_position: true,
        miner_hotkey: "5Dxqzduahnqw8q3XSUfTcEZGU7xmAsfJubhHZwvXVLN9fSjR",
        net_leverage: 0.0,
        open_ms: 1714453025996,
        orders: [
          {
            leverage: -0.05,
            order_type: "SHORT",
            order_uuid: "0aa04d84-8e5c-4f59-9cd6-0a8bf1cfce68",
            price: 63511.0,
            price_sources: [],
            processed_ms: 1714453025974,
          },
          {
            leverage: 0.05,
            order_type: "FLAT",
            order_uuid: "28f77f62-e143-4b07-b639-44e8ef2992b4",
            price: 63345.47,
            price_sources: [],
            processed_ms: 1714453089179,
          },
        ],
        position_type: "FLAT",
        position_uuid: "5b05fbfd-ca27-49d1-864e-15ae6c537e9d",
        return_at_close: 1.0000803094924502,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 63411.0,
        close_ms: 1714454978112,
        current_return: 1.000025728974468,
        initial_entry_price: 63411.0,
        is_closed_position: true,
        miner_hotkey: "5Dxqzduahnqw8q3XSUfTcEZGU7xmAsfJubhHZwvXVLN9fSjR",
        net_leverage: 0.0,
        open_ms: 1714454916567,
        orders: [
          {
            leverage: -0.05,
            order_type: "SHORT",
            order_uuid: "210d8a0e-d2ec-4979-b75a-83a88527c2d7",
            price: 63411.0,
            price_sources: [],
            processed_ms: 1714454916549,
          },
          {
            leverage: 0.05,
            order_type: "FLAT",
            order_uuid: "da086dde-d6c5-4e08-a814-a2539875b9a1",
            price: 63378.37,
            price_sources: [],
            processed_ms: 1714454978112,
          },
        ],
        position_type: "FLAT",
        position_uuid: "62d21ac1-91a7-484d-b947-f11c12932bcd",
        return_at_close: 0.9999757276880193,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 63363.371111111126,
        close_ms: 1714463271755,
        current_return: 1.0014627580963582,
        initial_entry_price: 63266.1,
        is_closed_position: true,
        miner_hotkey: "5Dxqzduahnqw8q3XSUfTcEZGU7xmAsfJubhHZwvXVLN9fSjR",
        net_leverage: 0.0,
        open_ms: 1714456863267,
        orders: [
          {
            leverage: -0.05,
            order_type: "SHORT",
            order_uuid: "ea83b404-368c-43f1-85fd-f1cf28a1b38d",
            price: 63266.1,
            price_sources: [],
            processed_ms: 1714456863248,
          },
          {
            leverage: -0.1,
            order_type: "SHORT",
            order_uuid: "80f59892-0221-42e3-a5cb-f38a632ef059",
            price: 63400.08,
            price_sources: [],
            processed_ms: 1714458778036,
          },
          {
            leverage: -0.1,
            order_type: "SHORT",
            order_uuid: "008fcb06-8371-43b7-b0d2-2c24bd9b9613",
            price: 63508.0,
            price_sources: [],
            processed_ms: 1714459691884,
          },
          {
            leverage: -0.1,
            order_type: "SHORT",
            order_uuid: "86ab1399-dfe7-4fd7-a3dc-20b0fb0154d4",
            price: 63389.92,
            price_sources: [],
            processed_ms: 1714461089697,
          },
          {
            leverage: -0.1,
            order_type: "SHORT",
            order_uuid: "9e420b3c-2e68-489b-ba90-bc9c5cda6981",
            price: 63204.12,
            price_sources: [],
            processed_ms: 1714463116859,
          },
          {
            leverage: 0.44999999999999996,
            order_type: "FLAT",
            order_uuid: "e9722e65-f9ac-4c3a-a8a6-65d231a3bbe4",
            price: 63157.72,
            price_sources: [],
            processed_ms: 1714463271755,
          },
        ],
        position_type: "FLAT",
        position_uuid: "975ece13-7f61-4bf2-94be-6fc556b525e1",
        return_at_close: 1.001012099855215,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 62245.85,
        close_ms: 1714466718295,
        current_return: 1.0001536969934541,
        initial_entry_price: 62245.85,
        is_closed_position: true,
        miner_hotkey: "5Dxqzduahnqw8q3XSUfTcEZGU7xmAsfJubhHZwvXVLN9fSjR",
        net_leverage: 0.0,
        open_ms: 1714466344490,
        orders: [
          {
            leverage: -0.1,
            order_type: "SHORT",
            order_uuid: "995420d8-b221-4d40-83cb-1d97f501d915",
            price: 62245.85,
            price_sources: [],
            processed_ms: 1714466344472,
          },
          {
            leverage: 0.1,
            order_type: "FLAT",
            order_uuid: "97114d3d-2f55-4554-b7b9-1dc66b14c9ad",
            price: 62150.18,
            price_sources: [],
            processed_ms: 1714466718295,
          },
        ],
        position_type: "FLAT",
        position_uuid: "c8786864-9af2-452e-b6b3-bee3a4f2c804",
        return_at_close: 1.0000536816237549,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 61458.81,
        close_ms: 1714471378429,
        current_return: 0.9997475870424435,
        initial_entry_price: 61458.81,
        is_closed_position: true,
        miner_hotkey: "5Dxqzduahnqw8q3XSUfTcEZGU7xmAsfJubhHZwvXVLN9fSjR",
        net_leverage: 0.0,
        open_ms: 1714471318371,
        orders: [
          {
            leverage: -0.1,
            order_type: "SHORT",
            order_uuid: "c93ce3e1-46ac-40a0-a4ff-5a6da99b0089",
            price: 61458.81,
            price_sources: [],
            processed_ms: 1714471318351,
          },
          {
            leverage: 0.1,
            order_type: "FLAT",
            order_uuid: "b65de293-81e4-48df-a285-3227e72cfcc3",
            price: 61613.94,
            price_sources: [],
            processed_ms: 1714471378429,
          },
        ],
        position_type: "FLAT",
        position_uuid: "c3c776a0-0a86-4b6b-b29b-4922fd62f807",
        return_at_close: 0.9996476122837392,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 61224.76,
        close_ms: 1714479237245,
        current_return: 1.0006100963074416,
        initial_entry_price: 61224.76,
        is_closed_position: true,
        miner_hotkey: "5Dxqzduahnqw8q3XSUfTcEZGU7xmAsfJubhHZwvXVLN9fSjR",
        net_leverage: 0.0,
        open_ms: 1714478232499,
        orders: [
          {
            leverage: -0.1,
            order_type: "SHORT",
            order_uuid: "56713039-bd00-4b87-ad28-bc1c977211d0",
            price: 61224.76,
            price_sources: [],
            processed_ms: 1714478232479,
          },
          {
            leverage: 0.1,
            order_type: "FLAT",
            order_uuid: "1dbf0462-8e26-4b46-85bb-d1b4f3c85082",
            price: 60851.23,
            price_sources: [],
            processed_ms: 1714479237245,
          },
        ],
        position_type: "FLAT",
        position_uuid: "7858df82-0587-452b-85b8-089649182389",
        return_at_close: 1.000510035297811,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 61335.3,
        close_ms: 1714489884000,
        current_return: 1.000764095064343,
        initial_entry_price: 61335.3,
        is_closed_position: true,
        miner_hotkey: "5Dxqzduahnqw8q3XSUfTcEZGU7xmAsfJubhHZwvXVLN9fSjR",
        net_leverage: 0.0,
        open_ms: 1714489491052,
        orders: [
          {
            leverage: -0.1,
            order_type: "SHORT",
            order_uuid: "bb98e71d-2f62-439d-bfba-580e4366c152",
            price: 61335.3,
            price_sources: [],
            processed_ms: 1714489491015,
          },
          {
            leverage: 0.1,
            order_type: "FLAT",
            order_uuid: "0db77b3d-094a-46a4-9cfb-b53aab8503dd",
            price: 60866.64,
            price_sources: [],
            processed_ms: 1714489884000,
          },
        ],
        position_type: "FLAT",
        position_uuid: "63d7cb38-f98a-4497-baf1-302eec7aae21",
        return_at_close: 1.0006640186548366,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 0.8924049999999999,
        close_ms: 1714491080889,
        current_return: 1.0001311115344533,
        initial_entry_price: 0.89237,
        is_closed_position: true,
        miner_hotkey: "5Dxqzduahnqw8q3XSUfTcEZGU7xmAsfJubhHZwvXVLN9fSjR",
        net_leverage: 0.0,
        open_ms: 1714490633984,
        orders: [
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "e6084852-6f36-4182-9364-55a94ae136b0",
            price: 0.89237,
            price_sources: [],
            processed_ms: 1714490633964,
          },
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "b06abba5-4054-4638-a2c1-0c18f66522bd",
            price: 0.89244,
            price_sources: [],
            processed_ms: 1714490960155,
          },
          {
            leverage: 0.6,
            order_type: "FLAT",
            order_uuid: "2b524c96-746a-40f9-b602-dc941b0cb2d0",
            price: 0.8926,
            price_sources: [],
            processed_ms: 1714491080889,
          },
        ],
        position_type: "FLAT",
        position_uuid: "10032f71-d3fc-44f4-b1cd-37a92e3fc391",
        return_at_close: 1.0000891060277688,
        trade_pair: ["AUDCAD", "AUD/CAD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.8918158236057071,
        close_ms: 1714493465905,
        current_return: 0.9999610378277752,
        initial_entry_price: 0.891815823605707,
        is_closed_position: true,
        miner_hotkey: "5Dxqzduahnqw8q3XSUfTcEZGU7xmAsfJubhHZwvXVLN9fSjR",
        net_leverage: 0.0,
        open_ms: 1714493177190,
        orders: [
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "f9d38126-96e1-4d3d-a83d-cdaddf0e9a77",
            price: 0.891815823605707,
            price_sources: [],
            processed_ms: 1714493177156,
          },
          {
            leverage: 0.3,
            order_type: "FLAT",
            order_uuid: "d05888ae-f066-4465-b63d-bafea4533971",
            price: 0.8917,
            price_sources: [],
            processed_ms: 1714493465905,
          },
        ],
        position_type: "FLAT",
        position_uuid: "906a610f-0aca-4366-b9bc-591ca0a09239",
        return_at_close: 0.9999400386459808,
        trade_pair: ["AUDCAD", "AUD/CAD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.6673200000000002,
        close_ms: 1714501344139,
        current_return: 1.0005257166821433,
        initial_entry_price: 0.66766,
        is_closed_position: true,
        miner_hotkey: "5Dxqzduahnqw8q3XSUfTcEZGU7xmAsfJubhHZwvXVLN9fSjR",
        net_leverage: 0.0,
        open_ms: 1714499941575,
        orders: [
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "4f0762a7-0712-4bdd-8882-d3337f152e73",
            price: 0.66766,
            price_sources: [],
            processed_ms: 1714499941556,
          },
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "60feecda-ebd9-4196-b4ac-4e64e9f27a8a",
            price: 0.6671,
            price_sources: [],
            processed_ms: 1714500367247,
          },
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "8d19ea9b-3e58-458b-b173-a37490b20aac",
            price: 0.6672,
            price_sources: [],
            processed_ms: 1714501015351,
          },
          {
            leverage: 0.8999999999999999,
            order_type: "FLAT",
            order_uuid: "d8705eec-da5c-4724-8fea-8260e3cfa9f5",
            price: 0.66771,
            price_sources: [],
            processed_ms: 1714501344139,
          },
        ],
        position_type: "FLAT",
        position_uuid: "b4d43d85-a777-4753-92b5-90fd44ae5de8",
        return_at_close: 1.0004626835619923,
        trade_pair: ["CADCHF", "CAD/CHF", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 197.086,
        close_ms: 1714502355053,
        current_return: 1.0003318348335244,
        initial_entry_price: 197.086,
        is_closed_position: true,
        miner_hotkey: "5Dxqzduahnqw8q3XSUfTcEZGU7xmAsfJubhHZwvXVLN9fSjR",
        net_leverage: 0.0,
        open_ms: 1714500059061,
        orders: [
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "b7166ab3-3893-483a-8c70-5c52a0faf4ca",
            price: 197.086,
            price_sources: [],
            processed_ms: 1714500059039,
          },
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "c55e1d4c-e705-4b5e-9248-08186e37da1f",
            price: 197.086,
            price_sources: [],
            processed_ms: 1714500435118,
          },
          {
            leverage: 0.6,
            order_type: "FLAT",
            order_uuid: "36fbb1c5-2b02-406d-8307-c9416b11f127",
            price: 197.195,
            price_sources: [],
            processed_ms: 1714502355053,
          },
        ],
        position_type: "FLAT",
        position_uuid: "48e6c1a0-6df3-46a2-9204-f9a2daafb218",
        return_at_close: 1.0002898208964615,
        trade_pair: ["GBPJPY", "GBP/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.8922956711079124,
        close_ms: 1714502449401,
        current_return: 1.0002007956632009,
        initial_entry_price: 0.892256110610315,
        is_closed_position: true,
        miner_hotkey: "5Dxqzduahnqw8q3XSUfTcEZGU7xmAsfJubhHZwvXVLN9fSjR",
        net_leverage: 0.0,
        open_ms: 1714495254501,
        orders: [
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "f11a18a8-511f-4e89-9750-f1488d824b58",
            price: 0.892256110610315,
            price_sources: [],
            processed_ms: 1714495254481,
          },
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "d8b59851-e168-434f-8645-8ef05ba06946",
            price: 0.892244315417302,
            price_sources: [],
            processed_ms: 1714496331009,
          },
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "9e084d43-69bd-4c93-89db-e0d086a4bc6d",
            price: 0.892290580330506,
            price_sources: [],
            processed_ms: 1714498439110,
          },
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "05dd25b4-4884-4622-b98e-e2939efff30e",
            price: 0.892391678073527,
            price_sources: [],
            processed_ms: 1714501134900,
          },
          {
            leverage: 1.2,
            order_type: "FLAT",
            order_uuid: "9ea2225f-6ed8-49db-ac39-a1403470381d",
            price: 0.892444972072475,
            price_sources: [],
            processed_ms: 1714502449401,
          },
        ],
        position_type: "FLAT",
        position_uuid: "6d4f40e1-a925-480e-bdeb-e33a3a09c3a5",
        return_at_close: 1.0001167787963652,
        trade_pair: ["AUDCAD", "AUD/CAD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 1.8613899999999999,
        close_ms: 1714502961848,
        current_return: 0.9990609547576136,
        initial_entry_price: 1.80325,
        is_closed_position: true,
        miner_hotkey: "5Dxqzduahnqw8q3XSUfTcEZGU7xmAsfJubhHZwvXVLN9fSjR",
        net_leverage: 0.0,
        open_ms: 1714076858663,
        orders: [
          {
            leverage: 0.3333333333333333,
            order_type: "LONG",
            order_uuid: "1e93f6a8-2c2a-41a2-812d-da2b62e8119d",
            price: 1.80325,
            price_sources: [],
            processed_ms: 1714076858636,
          },
          {
            leverage: -0.3,
            order_type: "SHORT",
            order_uuid: "5b85ba1c-a99d-4abd-8656-aaf494ab0022",
            price: 1.79679,
            price_sources: [],
            processed_ms: 1714357481291,
          },
          {
            leverage: 0.033333333333333326,
            order_type: "FLAT",
            order_uuid: "e50c58bd-97e3-465b-a690-701017519103",
            price: 1.81059,
            price_sources: [],
            processed_ms: 1714502961848,
          },
        ],
        position_type: "FLAT",
        position_uuid: "ae8774f4-db73-4fec-a0f9-5da0b064391a",
        return_at_close: 0.9990376433353358,
        trade_pair: ["EURNZD", "EUR/NZD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.6673,
        close_ms: 1714503677298,
        current_return: 1.0010040461561516,
        initial_entry_price: 0.6673,
        is_closed_position: true,
        miner_hotkey: "5Dxqzduahnqw8q3XSUfTcEZGU7xmAsfJubhHZwvXVLN9fSjR",
        net_leverage: 0.0,
        open_ms: 1714503538078,
        orders: [
          {
            leverage: 1.0,
            order_type: "LONG",
            order_uuid: "8fd654c1-23c2-4159-a09c-9364872e4af5",
            price: 0.6673,
            price_sources: [],
            processed_ms: 1714503538040,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "378ffc5b-a0c2-4a2d-bc64-9095a9f76602",
            price: 0.66797,
            price_sources: [],
            processed_ms: 1714503677298,
          },
        ],
        position_type: "FLAT",
        position_uuid: "ff2fdfa2-753b-4d77-a1fa-5b1364008a73",
        return_at_close: 1.0009339758729205,
        trade_pair: ["CADCHF", "CAD/CHF", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 1.0992328571428571,
        close_ms: 1714506559195,
        current_return: 1.0013820727967033,
        initial_entry_price: 1.09929,
        is_closed_position: true,
        miner_hotkey: "5Dxqzduahnqw8q3XSUfTcEZGU7xmAsfJubhHZwvXVLN9fSjR",
        net_leverage: 0.0,
        open_ms: 1714499938861,
        orders: [
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "b19302c3-6795-4fe0-970d-74bceee6c960",
            price: 1.09929,
            price_sources: [],
            processed_ms: 1714499938827,
          },
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "cb06f830-2447-437f-85d2-49b750627353",
            price: 1.09929,
            price_sources: [],
            processed_ms: 1714500376486,
          },
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "8febe448-bc93-4ff2-b100-2b179c2e0da8",
            price: 1.09922,
            price_sources: [],
            processed_ms: 1714500883463,
          },
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "fb796d42-99d0-4ac3-85b7-4c8e16aefc00",
            price: 1.09926,
            price_sources: [],
            processed_ms: 1714501016703,
          },
          {
            leverage: 1.0,
            order_type: "LONG",
            order_uuid: "2db3217f-934d-496c-a8ef-ea009083c1b3",
            price: 1.09922,
            price_sources: [],
            processed_ms: 1714503542709,
          },
          {
            leverage: 1.0,
            order_type: "LONG",
            order_uuid: "525a0d13-6d4b-49f9-8ce6-60326c71dd69",
            price: 1.09921,
            price_sources: [],
            processed_ms: 1714504594681,
          },
          {
            leverage: 1.0,
            order_type: "LONG",
            order_uuid: "9f741694-16e6-427f-9736-f181746ad020",
            price: 1.09923,
            price_sources: [],
            processed_ms: 1714505992640,
          },
          {
            leverage: 4.2,
            order_type: "FLAT",
            order_uuid: "9d2c7eca-3cbb-492d-b20b-bc7815b16eb4",
            price: 1.099594594953497,
            price_sources: [],
            processed_ms: 1714506559195,
          },
        ],
        position_type: "FLAT",
        position_uuid: "1b10b500-63f4-4a8b-a6db-eca7210d9681",
        return_at_close: 1.0010876664673012,
        trade_pair: ["AUDNZD", "AUD/NZD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 1.098965,
        close_ms: 1714509461085,
        current_return: 1.0010404863734248,
        initial_entry_price: 1.09953,
        is_closed_position: true,
        miner_hotkey: "5Dxqzduahnqw8q3XSUfTcEZGU7xmAsfJubhHZwvXVLN9fSjR",
        net_leverage: 0.0,
        open_ms: 1714508384004,
        orders: [
          {
            leverage: 1.0,
            order_type: "LONG",
            order_uuid: "c313f8ab-1bcf-4748-9ce7-3808f4bb4dde",
            price: 1.09953,
            price_sources: [],
            processed_ms: 1714508383968,
          },
          {
            leverage: 1.0,
            order_type: "LONG",
            order_uuid: "3a69615e-dc70-488a-b3cb-40d4c3cf860d",
            price: 1.0984,
            price_sources: [],
            processed_ms: 1714509323871,
          },
          {
            leverage: 2.0,
            order_type: "FLAT",
            order_uuid: "d404b24b-0eff-4870-b631-8034933097b5",
            price: 1.099537022991086,
            price_sources: [],
            processed_ms: 1714509461085,
          },
        ],
        position_type: "FLAT",
        position_uuid: "531d6334-4f38-457b-b09c-634e024c0f1f",
        return_at_close: 1.0009003407053325,
        trade_pair: ["AUDNZD", "AUD/NZD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.89174,
        close_ms: 1714510676059,
        current_return: 1.0010427528675705,
        initial_entry_price: 0.89187,
        is_closed_position: true,
        miner_hotkey: "5Dxqzduahnqw8q3XSUfTcEZGU7xmAsfJubhHZwvXVLN9fSjR",
        net_leverage: 0.0,
        open_ms: 1714506852038,
        orders: [
          {
            leverage: 1.0,
            order_type: "LONG",
            order_uuid: "56558d94-d18d-48fc-b3b2-6053e14ce6c4",
            price: 0.89187,
            price_sources: [],
            processed_ms: 1714506851999,
          },
          {
            leverage: 1.0,
            order_type: "LONG",
            order_uuid: "e6a10ac0-6a23-4db9-a7ee-15a65eb0e84b",
            price: 0.89179,
            price_sources: [],
            processed_ms: 1714508101316,
          },
          {
            leverage: 1.0,
            order_type: "LONG",
            order_uuid: "11b69abb-2c3f-4f60-b8cd-a6eb0c6be69a",
            price: 0.89156,
            price_sources: [],
            processed_ms: 1714510440150,
          },
          {
            leverage: 3.0,
            order_type: "FLAT",
            order_uuid: "742151bc-2a46-4319-90f6-d88fe8a069e2",
            price: 0.89205,
            price_sources: [],
            processed_ms: 1714510676059,
          },
        ],
        position_type: "FLAT",
        position_uuid: "07c84ef5-bd5b-41d9-aceb-9995164d04ee",
        return_at_close: 1.0008325338894684,
        trade_pair: ["AUDCAD", "AUD/CAD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 1.09927,
        close_ms: 1714511495291,
        current_return: 0.999581540476862,
        initial_entry_price: 1.09927,
        is_closed_position: true,
        miner_hotkey: "5Dxqzduahnqw8q3XSUfTcEZGU7xmAsfJubhHZwvXVLN9fSjR",
        net_leverage: 0.0,
        open_ms: 1714511373985,
        orders: [
          {
            leverage: 1.0,
            order_type: "LONG",
            order_uuid: "b5ec8981-12cf-4eca-a475-230acf89ae21",
            price: 1.09927,
            price_sources: [],
            processed_ms: 1714511373962,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "c3eaf646-0d37-4691-807c-8ad67b73ed21",
            price: 1.09881,
            price_sources: [],
            processed_ms: 1714511495291,
          },
        ],
        position_type: "FLAT",
        position_uuid: "a5f6e399-7993-4e2e-bf3a-4de9a75cbc73",
        return_at_close: 0.9995115697690286,
        trade_pair: ["AUDNZD", "AUD/NZD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 197.06966666666668,
        close_ms: 1714511933948,
        current_return: 0.9997008174276384,
        initial_entry_price: 197.204,
        is_closed_position: true,
        miner_hotkey: "5Dxqzduahnqw8q3XSUfTcEZGU7xmAsfJubhHZwvXVLN9fSjR",
        net_leverage: 0.0,
        open_ms: 1714504007139,
        orders: [
          {
            leverage: 1.0,
            order_type: "LONG",
            order_uuid: "271ca0c5-c968-4ccb-8e5d-c2457a947fdc",
            price: 197.204,
            price_sources: [],
            processed_ms: 1714504007115,
          },
          {
            leverage: 1.0,
            order_type: "LONG",
            order_uuid: "50937608-c3f0-4c6b-b8c4-8d1a93504fe7",
            price: 197.145,
            price_sources: [],
            processed_ms: 1714506404166,
          },
          {
            leverage: 1.0,
            order_type: "LONG",
            order_uuid: "7251a486-0b00-4c25-a088-b449e68a8779",
            price: 196.86,
            price_sources: [],
            processed_ms: 1714511340966,
          },
          {
            leverage: 3.0,
            order_type: "FLAT",
            order_uuid: "16d8a8b0-c2f2-4003-a91d-0a6c2ca7f4ff",
            price: 197.05,
            price_sources: [],
            processed_ms: 1714511933948,
          },
        ],
        position_type: "FLAT",
        position_uuid: "aea31027-554e-4c6b-8d45-7bdc5368a66b",
        return_at_close: 0.9994908802559785,
        trade_pair: ["GBPJPY", "GBP/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 1.098029819818361,
        close_ms: 1714513558901,
        current_return: 0.9996085536015479,
        initial_entry_price: 1.098029819818361,
        is_closed_position: true,
        miner_hotkey: "5Dxqzduahnqw8q3XSUfTcEZGU7xmAsfJubhHZwvXVLN9fSjR",
        net_leverage: 0.0,
        open_ms: 1714513303283,
        orders: [
          {
            leverage: 1.0,
            order_type: "LONG",
            order_uuid: "ca4e0136-2dd3-4608-91d3-63704df26615",
            price: 1.098029819818361,
            price_sources: [],
            processed_ms: 1714513303246,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "ebb8fe83-0212-4fbc-8543-9f79d5fc5eb5",
            price: 1.0976,
            price_sources: [],
            processed_ms: 1714513558901,
          },
        ],
        position_type: "FLAT",
        position_uuid: "1958c73c-1d1d-4b30-b453-6d7af81c2f40",
        return_at_close: 0.9995385810027958,
        trade_pair: ["AUDNZD", "AUD/NZD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.890727619787117,
        close_ms: 1714513569597,
        current_return: 1.000980971446512,
        initial_entry_price: 0.890727619787117,
        is_closed_position: true,
        miner_hotkey: "5Dxqzduahnqw8q3XSUfTcEZGU7xmAsfJubhHZwvXVLN9fSjR",
        net_leverage: 0.0,
        open_ms: 1714513447916,
        orders: [
          {
            leverage: 1.0,
            order_type: "LONG",
            order_uuid: "417e431e-2dcd-45ca-b051-7a7d51369c6c",
            price: 0.890727619787117,
            price_sources: [],
            processed_ms: 1714513447895,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "19ec3637-e09a-474e-980b-ba615dee83d1",
            price: 0.891601398148748,
            price_sources: [],
            processed_ms: 1714513569597,
          },
        ],
        position_type: "FLAT",
        position_uuid: "a6e1571e-3001-40e3-aa6b-a93d24dd6467",
        return_at_close: 1.0009109027785108,
        trade_pair: ["AUDCAD", "AUD/CAD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 1.099228270125869,
        close_ms: 1714514644051,
        current_return: 1.0000550650533662,
        initial_entry_price: 1.099228270125869,
        is_closed_position: true,
        miner_hotkey: "5Dxqzduahnqw8q3XSUfTcEZGU7xmAsfJubhHZwvXVLN9fSjR",
        net_leverage: 0.0,
        open_ms: 1714514518986,
        orders: [
          {
            leverage: 1.0,
            order_type: "LONG",
            order_uuid: "d64d4ef8-c70d-4eb0-9e36-c0fa5600dfce",
            price: 1.099228270125869,
            price_sources: [],
            processed_ms: 1714514518942,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "b821e572-ef66-4a35-a7c4-26833e2bb2f5",
            price: 1.099288799189225,
            price_sources: [],
            processed_ms: 1714514644051,
          },
        ],
        position_type: "FLAT",
        position_uuid: "e784f74a-8dba-4cde-809f-3888595192db",
        return_at_close: 0.9999850611988124,
        trade_pair: ["AUDNZD", "AUD/NZD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 1.0996,
        close_ms: 1714517102151,
        current_return: 1.0006729719898146,
        initial_entry_price: 1.0996,
        is_closed_position: true,
        miner_hotkey: "5Dxqzduahnqw8q3XSUfTcEZGU7xmAsfJubhHZwvXVLN9fSjR",
        net_leverage: 0.0,
        open_ms: 1714516842040,
        orders: [
          {
            leverage: 1.0,
            order_type: "LONG",
            order_uuid: "b9c50f36-8e38-4cbc-bf9f-aeeb6809297a",
            price: 1.0996,
            price_sources: [],
            processed_ms: 1714516841997,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "2f79e018-85d6-4a5c-b10f-c292dc278931",
            price: 1.10034,
            price_sources: [],
            processed_ms: 1714517102151,
          },
        ],
        position_type: "FLAT",
        position_uuid: "4174e257-bdc4-4c06-8a22-8290013d866a",
        return_at_close: 1.0006029248817754,
        trade_pair: ["AUDNZD", "AUD/NZD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 1.10114,
        close_ms: 1714519261962,
        current_return: 1.0001362224603594,
        initial_entry_price: 1.10114,
        is_closed_position: true,
        miner_hotkey: "5Dxqzduahnqw8q3XSUfTcEZGU7xmAsfJubhHZwvXVLN9fSjR",
        net_leverage: 0.0,
        open_ms: 1714519081789,
        orders: [
          {
            leverage: 1.0,
            order_type: "LONG",
            order_uuid: "756e2e83-bd36-48ec-bc4e-360a5a5ac2ce",
            price: 1.10114,
            price_sources: [],
            processed_ms: 1714519081743,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "97a5b48e-e60f-448a-b96d-618cbfe9c31f",
            price: 1.10129,
            price_sources: [],
            processed_ms: 1714519261962,
          },
        ],
        position_type: "FLAT",
        position_uuid: "8c06c06a-f67b-4d30-b3ad-4048bdb017f2",
        return_at_close: 1.000066212924787,
        trade_pair: ["AUDNZD", "AUD/NZD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 1.100829614854057,
        close_ms: 1714521364058,
        current_return: 1.00040004841803,
        initial_entry_price: 1.100829614854057,
        is_closed_position: true,
        miner_hotkey: "5Dxqzduahnqw8q3XSUfTcEZGU7xmAsfJubhHZwvXVLN9fSjR",
        net_leverage: 0.0,
        open_ms: 1714521085615,
        orders: [
          {
            leverage: 1.0,
            order_type: "LONG",
            order_uuid: "f6363e18-da98-4265-b583-bea57f76e912",
            price: 1.100829614854057,
            price_sources: [],
            processed_ms: 1714521085577,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "8d3e39b6-c8fc-4e9c-8824-87a9fb74bcd5",
            price: 1.10127,
            price_sources: [],
            processed_ms: 1714521364058,
          },
        ],
        position_type: "FLAT",
        position_uuid: "4e545067-0fff-4aaf-9c81-d5591ad0987a",
        return_at_close: 1.0003300204146408,
        trade_pair: ["AUDNZD", "AUD/NZD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.8914,
        close_ms: 1714521976201,
        current_return: 1.0006282252636303,
        initial_entry_price: 0.8914,
        is_closed_position: true,
        miner_hotkey: "5Dxqzduahnqw8q3XSUfTcEZGU7xmAsfJubhHZwvXVLN9fSjR",
        net_leverage: 0.0,
        open_ms: 1714521838981,
        orders: [
          {
            leverage: 1.0,
            order_type: "LONG",
            order_uuid: "65318831-3347-4a18-857e-7d6a31b2c84d",
            price: 0.8914,
            price_sources: [],
            processed_ms: 1714521838938,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "7940a2e3-f5c3-43da-9f99-8a14bae91f83",
            price: 0.89196,
            price_sources: [],
            processed_ms: 1714521976201,
          },
        ],
        position_type: "FLAT",
        position_uuid: "8bec17cd-8049-4162-a07a-04a6eb4b2d3d",
        return_at_close: 1.000558181287862,
        trade_pair: ["AUDCAD", "AUD/CAD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.667558,
        close_ms: 1714525517686,
        current_return: 1.0009880387430952,
        initial_entry_price: 0.66799,
        is_closed_position: true,
        miner_hotkey: "5Dxqzduahnqw8q3XSUfTcEZGU7xmAsfJubhHZwvXVLN9fSjR",
        net_leverage: 0.0,
        open_ms: 1714505533461,
        orders: [
          {
            leverage: 1.0,
            order_type: "LONG",
            order_uuid: "cbcf5d63-9074-449a-abde-c6d86a9692e1",
            price: 0.66799,
            price_sources: [],
            processed_ms: 1714505533423,
          },
          {
            leverage: 1.0,
            order_type: "LONG",
            order_uuid: "3b784d97-47b6-44b9-b214-55fb6c4c5d4e",
            price: 0.66782,
            price_sources: [],
            processed_ms: 1714506462954,
          },
          {
            leverage: 1.0,
            order_type: "LONG",
            order_uuid: "76bb1b85-04c2-4702-a764-4f713a8c52ca",
            price: 0.6677,
            price_sources: [],
            processed_ms: 1714507928030,
          },
          {
            leverage: 1.0,
            order_type: "LONG",
            order_uuid: "54e3db48-52c9-4722-a052-755e3416786e",
            price: 0.66747,
            price_sources: [],
            processed_ms: 1714509963912,
          },
          {
            leverage: 1.0,
            order_type: "LONG",
            order_uuid: "dc48a5b3-8223-498e-b578-2034c7b0371c",
            price: 0.66681,
            price_sources: [],
            processed_ms: 1714513177881,
          },
          {
            leverage: 5.0,
            order_type: "FLAT",
            order_uuid: "1564ca0c-583d-49dc-83f9-aa55c1b0269d",
            price: 0.66769,
            price_sources: [],
            processed_ms: 1714525517686,
          },
        ],
        position_type: "FLAT",
        position_uuid: "f40f86b2-74ad-47e5-80ee-a54c7bfe6c04",
        return_at_close: 1.000637692929535,
        trade_pair: ["CADCHF", "CAD/CHF", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.8912310112541235,
        close_ms: 1714526735282,
        current_return: 1.0003920191877818,
        initial_entry_price: 0.89153,
        is_closed_position: true,
        miner_hotkey: "5Dxqzduahnqw8q3XSUfTcEZGU7xmAsfJubhHZwvXVLN9fSjR",
        net_leverage: 0.0,
        open_ms: 1714524591394,
        orders: [
          {
            leverage: 1.0,
            order_type: "LONG",
            order_uuid: "a229bfb4-0546-4da1-9e2f-5604aada1409",
            price: 0.89153,
            price_sources: [],
            processed_ms: 1714524591370,
          },
          {
            leverage: 1.0,
            order_type: "LONG",
            order_uuid: "77a8eb06-061f-43f5-8bf4-2ed7d0bf9c5d",
            price: 0.890932022508247,
            price_sources: [],
            processed_ms: 1714526530737,
          },
          {
            leverage: 2.0,
            order_type: "FLAT",
            order_uuid: "15d498da-da31-4b37-919f-0547e16a823f",
            price: 0.891405759687365,
            price_sources: [],
            processed_ms: 1714526735282,
          },
        ],
        position_type: "FLAT",
        position_uuid: "8d3ee061-c70f-47c9-8bc6-54375e270122",
        return_at_close: 1.0002519643050953,
        trade_pair: ["AUDCAD", "AUD/CAD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.66764,
        close_ms: 1714528173452,
        current_return: 0.9997903061530166,
        initial_entry_price: 0.66764,
        is_closed_position: true,
        miner_hotkey: "5Dxqzduahnqw8q3XSUfTcEZGU7xmAsfJubhHZwvXVLN9fSjR",
        net_leverage: 0.0,
        open_ms: 1714527495885,
        orders: [
          {
            leverage: 1.0,
            order_type: "LONG",
            order_uuid: "807a3147-f420-4517-abe3-ab41d7093d62",
            price: 0.66764,
            price_sources: [],
            processed_ms: 1714527495842,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "9e8252d9-0889-4e4b-9b84-d274f9bd27ea",
            price: 0.6675,
            price_sources: [],
            processed_ms: 1714528173452,
          },
        ],
        position_type: "FLAT",
        position_uuid: "40bf185b-240d-4c7c-b5d1-41664628e464",
        return_at_close: 0.9997203208315858,
        trade_pair: ["CADCHF", "CAD/CHF", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.891376912378303,
        close_ms: 1714530071611,
        current_return: 0.9998015290997038,
        initial_entry_price: 0.891376912378303,
        is_closed_position: true,
        miner_hotkey: "5Dxqzduahnqw8q3XSUfTcEZGU7xmAsfJubhHZwvXVLN9fSjR",
        net_leverage: 0.0,
        open_ms: 1714529257783,
        orders: [
          {
            leverage: 1.0,
            order_type: "LONG",
            order_uuid: "a46c56ae-1fdd-4b48-bef3-63d6425b5b4f",
            price: 0.891376912378303,
            price_sources: [],
            processed_ms: 1714529257745,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "14e329c7-1a2a-40a8-9073-70d6dd033c2d",
            price: 0.8912,
            price_sources: [],
            processed_ms: 1714530071611,
          },
        ],
        position_type: "FLAT",
        position_uuid: "a748dd60-10f3-402c-b217-5005f4307c50",
        return_at_close: 0.9997315429926668,
        trade_pair: ["AUDCAD", "AUD/CAD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.667,
        close_ms: 1714530434052,
        current_return: 1.0010494752623686,
        initial_entry_price: 0.667,
        is_closed_position: true,
        miner_hotkey: "5Dxqzduahnqw8q3XSUfTcEZGU7xmAsfJubhHZwvXVLN9fSjR",
        net_leverage: 0.0,
        open_ms: 1714530190098,
        orders: [
          {
            leverage: 1.0,
            order_type: "LONG",
            order_uuid: "d014391c-ffa1-4c74-93c5-8e5934efee24",
            price: 0.667,
            price_sources: [],
            processed_ms: 1714530190070,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "d170e75c-6bd1-4cdf-97ae-b6750712046e",
            price: 0.6677,
            price_sources: [],
            processed_ms: 1714530434052,
          },
        ],
        position_type: "FLAT",
        position_uuid: "ae8fa645-fe57-432a-b1ee-25ab70a7841c",
        return_at_close: 1.0009794017991003,
        trade_pair: ["CADCHF", "CAD/CHF", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.66786,
        close_ms: 1714532537284,
        current_return: 1.000044919593927,
        initial_entry_price: 0.66786,
        is_closed_position: true,
        miner_hotkey: "5Dxqzduahnqw8q3XSUfTcEZGU7xmAsfJubhHZwvXVLN9fSjR",
        net_leverage: 0.0,
        open_ms: 1714532364267,
        orders: [
          {
            leverage: 1.0,
            order_type: "LONG",
            order_uuid: "bcd08591-94c1-41b7-ab29-0c5f38d3ab53",
            price: 0.66786,
            price_sources: [],
            processed_ms: 1714532364244,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "926f7c71-49dc-45f5-9110-11caf0f31a72",
            price: 0.66789,
            price_sources: [],
            processed_ms: 1714532537284,
          },
        ],
        position_type: "FLAT",
        position_uuid: "86064d7a-1875-4159-b572-0dae5cc4570e",
        return_at_close: 0.9999749164495554,
        trade_pair: ["CADCHF", "CAD/CHF", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.89132,
        close_ms: 1714534521468,
        current_return: 0.9990575563783238,
        initial_entry_price: 0.8913,
        is_closed_position: true,
        miner_hotkey: "5Dxqzduahnqw8q3XSUfTcEZGU7xmAsfJubhHZwvXVLN9fSjR",
        net_leverage: 0.0,
        open_ms: 1714533316714,
        orders: [
          {
            leverage: 1.0,
            order_type: "LONG",
            order_uuid: "444e0cac-24eb-4cf3-97f0-1d720a12f8e7",
            price: 0.8913,
            price_sources: [],
            processed_ms: 1714533316690,
          },
          {
            leverage: 1.0,
            order_type: "LONG",
            order_uuid: "d1a004a4-77d7-4d42-a3a4-c8033a41e136",
            price: 0.89134,
            price_sources: [],
            processed_ms: 1714534260111,
          },
          {
            leverage: 2.0,
            order_type: "FLAT",
            order_uuid: "0db27f5f-7b47-4b10-861d-13419013734a",
            price: 0.8909,
            price_sources: [],
            processed_ms: 1714534521468,
          },
        ],
        position_type: "FLAT",
        position_uuid: "a96164e5-e960-44eb-b836-d6f18cdd4725",
        return_at_close: 0.9989176883204308,
        trade_pair: ["AUDCAD", "AUD/CAD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.6677599999999999,
        close_ms: 1714539303713,
        current_return: 1.002455751549819,
        initial_entry_price: 0.66782,
        is_closed_position: true,
        miner_hotkey: "5Dxqzduahnqw8q3XSUfTcEZGU7xmAsfJubhHZwvXVLN9fSjR",
        net_leverage: 0.0,
        open_ms: 1714534373221,
        orders: [
          {
            leverage: 1.0,
            order_type: "LONG",
            order_uuid: "7cb2642d-e456-4340-8faf-42556084cc91",
            price: 0.66782,
            price_sources: [],
            processed_ms: 1714534373193,
          },
          {
            leverage: 1.0,
            order_type: "LONG",
            order_uuid: "d7df7bb9-4cb0-452b-89cf-37aeae67c0b0",
            price: 0.6677,
            price_sources: [],
            processed_ms: 1714535606114,
          },
          {
            leverage: 0.01,
            order_type: "FLAT",
            order_uuid: "4a07004b-1b62-4824-bc47-f6fbf94d88da",
            price: 0.66858,
            price_sources: [],
            processed_ms: 1714539303713,
          },
        ],
        position_type: "FLAT",
        position_uuid: "63869efd-b7c1-436a-bfff-23335c6f16da",
        return_at_close: 1.002315407744602,
        trade_pair: ["CADCHF", "CAD/CHF", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 1.099352,
        close_ms: 1714540312238,
        current_return: 1.001036184659013,
        initial_entry_price: 1.10019,
        is_closed_position: true,
        miner_hotkey: "5Dxqzduahnqw8q3XSUfTcEZGU7xmAsfJubhHZwvXVLN9fSjR",
        net_leverage: 0.0,
        open_ms: 1714523263697,
        orders: [
          {
            leverage: 1.0,
            order_type: "LONG",
            order_uuid: "328b6470-dc8d-44c6-bcc3-b7cdaab53fb5",
            price: 1.10019,
            price_sources: [],
            processed_ms: 1714523263647,
          },
          {
            leverage: 1.0,
            order_type: "LONG",
            order_uuid: "66ade420-d52d-4cb0-b569-05befaf13986",
            price: 1.0997,
            price_sources: [],
            processed_ms: 1714524176573,
          },
          {
            leverage: 1.0,
            order_type: "LONG",
            order_uuid: "73fdc7e7-b71b-43ec-ac0a-053d178a74a0",
            price: 1.09895,
            price_sources: [],
            processed_ms: 1714525534539,
          },
          {
            leverage: 1.0,
            order_type: "LONG",
            order_uuid: "d5080ba7-107f-494a-afeb-5f2151678dc0",
            price: 1.09888,
            price_sources: [],
            processed_ms: 1714527668740,
          },
          {
            leverage: 1.0,
            order_type: "LONG",
            order_uuid: "528f01e5-93dd-4d79-b9af-5bd693706256",
            price: 1.09904,
            price_sources: [],
            processed_ms: 1714530754003,
          },
          {
            leverage: 0.01,
            order_type: "FLAT",
            order_uuid: "1e24ba8c-2092-42d0-9dee-2558ea02e699",
            price: 1.09958,
            price_sources: [],
            processed_ms: 1714540312238,
          },
        ],
        position_type: "FLAT",
        position_uuid: "0a2342cc-a2c7-414f-ae50-89b9b03a5e36",
        return_at_close: 1.0006858219943824,
        trade_pair: ["AUDNZD", "AUD/NZD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.8929,
        close_ms: 1714581160365,
        current_return: 1.0002962859541993,
        initial_entry_price: 0.8929,
        is_closed_position: true,
        miner_hotkey: "5Dxqzduahnqw8q3XSUfTcEZGU7xmAsfJubhHZwvXVLN9fSjR",
        net_leverage: 0.0,
        open_ms: 1714581002860,
        orders: [
          {
            leverage: 0.5,
            order_type: "LONG",
            order_uuid: "37955bba-92a1-427c-b471-55cdf8f46d09",
            price: 0.8929,
            price_sources: [],
            processed_ms: 1714581002545,
          },
          {
            leverage: 0.5,
            order_type: "FLAT",
            order_uuid: "e643e09c-097c-4581-aac3-facde4f5773b",
            price: 0.893429107457009,
            price_sources: [],
            processed_ms: 1714581160365,
          },
        ],
        position_type: "FLAT",
        position_uuid: "59494be5-a55d-4e29-b000-b0aeda141190",
        return_at_close: 1.0002612755841909,
        trade_pair: ["AUDCAD", "AUD/CAD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.66834,
        close_ms: 1714582173393,
        current_return: 1.000254342524574,
        initial_entry_price: 0.66839,
        is_closed_position: true,
        miner_hotkey: "5Dxqzduahnqw8q3XSUfTcEZGU7xmAsfJubhHZwvXVLN9fSjR",
        net_leverage: 0.0,
        open_ms: 1714579995632,
        orders: [
          {
            leverage: 0.5,
            order_type: "LONG",
            order_uuid: "efd08217-cf9c-46e5-8cfb-9b961e5293a5",
            price: 0.66839,
            price_sources: [],
            processed_ms: 1714579994871,
          },
          {
            leverage: 0.5,
            order_type: "LONG",
            order_uuid: "97bfc94f-c196-4160-9c1e-a12e819830b1",
            price: 0.66829,
            price_sources: [],
            processed_ms: 1714581588285,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "79f130cb-db1d-481a-a870-c68ccdc2c47d",
            price: 0.66851,
            price_sources: [],
            processed_ms: 1714582173393,
          },
        ],
        position_type: "FLAT",
        position_uuid: "74f85754-808d-49a5-a920-3d68c3e9d8ea",
        return_at_close: 1.0001843247205973,
        trade_pair: ["CADCHF", "CAD/CHF", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.6682600000000001,
        close_ms: 1714585507880,
        current_return: 1.000179562764668,
        initial_entry_price: 0.66829,
        is_closed_position: true,
        miner_hotkey: "5Dxqzduahnqw8q3XSUfTcEZGU7xmAsfJubhHZwvXVLN9fSjR",
        net_leverage: 0.0,
        open_ms: 1714584119137,
        orders: [
          {
            leverage: 0.5,
            order_type: "LONG",
            order_uuid: "a44ff70d-1bff-4b8a-a2d9-b0d6b8987745",
            price: 0.66829,
            price_sources: [],
            processed_ms: 1714584118921,
          },
          {
            leverage: 0.5,
            order_type: "LONG",
            order_uuid: "5cb4b60e-3cc5-42fa-b95a-a41b97c23fd0",
            price: 0.66823,
            price_sources: [],
            processed_ms: 1714585076835,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "c6d3dbc2-677e-41ea-b676-f0105de45810",
            price: 0.66838,
            price_sources: [],
            processed_ms: 1714585507880,
          },
        ],
        position_type: "FLAT",
        position_uuid: "aeb8d561-98af-4af5-b3cd-10d9580d351d",
        return_at_close: 1.0001095501952744,
        trade_pair: ["CADCHF", "CAD/CHF", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.89324958665888,
        close_ms: 1714586019258,
        current_return: 1.0001938508081518,
        initial_entry_price: 0.89316,
        is_closed_position: true,
        miner_hotkey: "5Dxqzduahnqw8q3XSUfTcEZGU7xmAsfJubhHZwvXVLN9fSjR",
        net_leverage: 0.0,
        open_ms: 1714583739956,
        orders: [
          {
            leverage: 0.5,
            order_type: "LONG",
            order_uuid: "b162352a-d11d-4d2a-a417-31b5de866e81",
            price: 0.89316,
            price_sources: [],
            processed_ms: 1714583739678,
          },
          {
            leverage: 0.5,
            order_type: "LONG",
            order_uuid: "71d48534-5844-4498-9f73-f3a979bd1657",
            price: 0.89333917331776,
            price_sources: [],
            processed_ms: 1714584670194,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "5db34c54-b070-4242-8e5a-e601b789a65b",
            price: 0.893422726446689,
            price_sources: [],
            processed_ms: 1714586019258,
          },
        ],
        position_type: "FLAT",
        position_uuid: "ea247067-4a4f-4ff2-ba93-033ef0483752",
        return_at_close: 1.0001238372385952,
        trade_pair: ["AUDCAD", "AUD/CAD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 1.8094100000000002,
        close_ms: 1714586545172,
        current_return: 1.0000165799901626,
        initial_entry_price: 1.80941,
        is_closed_position: true,
        miner_hotkey: "5Dxqzduahnqw8q3XSUfTcEZGU7xmAsfJubhHZwvXVLN9fSjR",
        net_leverage: 0.0,
        open_ms: 1714586397093,
        orders: [
          {
            leverage: 0.1,
            order_type: "LONG",
            order_uuid: "6a86935f-8e10-443f-9823-4b7ccf2609d3",
            price: 1.80941,
            price_sources: [],
            processed_ms: 1714586396848,
          },
          {
            leverage: 0.1,
            order_type: "FLAT",
            order_uuid: "3fb68410-a5c5-4213-b776-83dce7ede7a6",
            price: 1.80971,
            price_sources: [],
            processed_ms: 1714586545172,
          },
        ],
        position_type: "FLAT",
        position_uuid: "b0f529d8-5c80-4def-8d42-543d3acae936",
        return_at_close: 1.0000095798741027,
        trade_pair: ["EURNZD", "EUR/NZD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.66832,
        close_ms: 1714587349169,
        current_return: 1.0001197031362221,
        initial_entry_price: 0.66832,
        is_closed_position: true,
        miner_hotkey: "5Dxqzduahnqw8q3XSUfTcEZGU7xmAsfJubhHZwvXVLN9fSjR",
        net_leverage: 0.0,
        open_ms: 1714586842403,
        orders: [
          {
            leverage: 0.5,
            order_type: "LONG",
            order_uuid: "278a47d3-0dcc-407b-a528-e3e27d420cbc",
            price: 0.66832,
            price_sources: [],
            processed_ms: 1714586842198,
          },
          {
            leverage: 0.5,
            order_type: "FLAT",
            order_uuid: "6c0b6ca9-d620-4641-b480-1deff116fcd8",
            price: 0.66848,
            price_sources: [],
            processed_ms: 1714587349169,
          },
        ],
        position_type: "FLAT",
        position_uuid: "394f89d0-d4d8-44d9-8cf5-e5e1af0266c2",
        return_at_close: 1.0000846989466123,
        trade_pair: ["CADCHF", "CAD/CHF", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 1.1002679779622981,
        close_ms: 1714588577582,
        current_return: 1.000072758218679,
        initial_entry_price: 1.100289365355994,
        is_closed_position: true,
        miner_hotkey: "5Dxqzduahnqw8q3XSUfTcEZGU7xmAsfJubhHZwvXVLN9fSjR",
        net_leverage: 0.0,
        open_ms: 1714579561150,
        orders: [
          {
            leverage: 0.5,
            order_type: "LONG",
            order_uuid: "07beea90-358f-42c6-9fcc-64e5574b6f72",
            price: 1.100289365355994,
            price_sources: [],
            processed_ms: 1714579560742,
          },
          {
            leverage: 0.5,
            order_type: "LONG",
            order_uuid: "d1a09327-d8b2-40d3-9a19-eda417fc8887",
            price: 1.10037,
            price_sources: [],
            processed_ms: 1714580504913,
          },
          {
            leverage: 0.5,
            order_type: "LONG",
            order_uuid: "b68facd3-da6d-416d-962e-d59a0b9b126e",
            price: 1.100440524455497,
            price_sources: [],
            processed_ms: 1714581891907,
          },
          {
            leverage: 0.5,
            order_type: "LONG",
            order_uuid: "3e115f58-75e1-416f-be83-a4471f47f46f",
            price: 1.1001,
            price_sources: [],
            processed_ms: 1714583931883,
          },
          {
            leverage: 0.5,
            order_type: "LONG",
            order_uuid: "b44c9045-9252-4327-b38f-ea05fb4b8a07",
            price: 1.10014,
            price_sources: [],
            processed_ms: 1714587931582,
          },
          {
            leverage: 2.5,
            order_type: "FLAT",
            order_uuid: "1ae50cc4-d392-4bb9-81c5-b81b306902a8",
            price: 1.1003,
            price_sources: [],
            processed_ms: 1714588577582,
          },
        ],
        position_type: "FLAT",
        position_uuid: "67e71b67-1b02-442a-9328-8b8e059734cb",
        return_at_close: 0.9998977454859908,
        trade_pair: ["AUDNZD", "AUD/NZD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.892,
        close_ms: 1714590484162,
        current_return: 1.0018497757847533,
        initial_entry_price: 0.892,
        is_closed_position: true,
        miner_hotkey: "5Dxqzduahnqw8q3XSUfTcEZGU7xmAsfJubhHZwvXVLN9fSjR",
        net_leverage: 0.0,
        open_ms: 1714589861075,
        orders: [
          {
            leverage: 0.5,
            order_type: "LONG",
            order_uuid: "f6f22647-978f-449d-aae0-e72d8485ae15",
            price: 0.892,
            price_sources: [],
            processed_ms: 1714589860843,
          },
          {
            leverage: 0.5,
            order_type: "FLAT",
            order_uuid: "a5544a18-c723-4d44-bc6f-7b671f12bc50",
            price: 0.8953,
            price_sources: [],
            processed_ms: 1714590484162,
          },
        ],
        position_type: "FLAT",
        position_uuid: "271896ca-8f70-4414-9082-04df66562bd5",
        return_at_close: 1.0018147110426008,
        trade_pair: ["AUDCAD", "AUD/CAD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 1.1005433333333332,
        close_ms: 1714594676149,
        current_return: 0.9998047123367306,
        initial_entry_price: 1.10094,
        is_closed_position: true,
        miner_hotkey: "5Dxqzduahnqw8q3XSUfTcEZGU7xmAsfJubhHZwvXVLN9fSjR",
        net_leverage: 0.0,
        open_ms: 1714590775875,
        orders: [
          {
            leverage: 0.5,
            order_type: "LONG",
            order_uuid: "214958a9-ea0d-40ea-b685-0b5c9957dd56",
            price: 1.10094,
            price_sources: [],
            processed_ms: 1714590775664,
          },
          {
            leverage: 0.5,
            order_type: "LONG",
            order_uuid: "c81096a1-713f-4cb8-a726-dfe14f6d10d9",
            price: 1.10038,
            price_sources: [],
            processed_ms: 1714592125304,
          },
          {
            leverage: 0.5,
            order_type: "LONG",
            order_uuid: "d6143996-e6ff-4c39-8f97-51bc72055e7d",
            price: 1.10031,
            price_sources: [],
            processed_ms: 1714593026775,
          },
          {
            leverage: 1.5,
            order_type: "FLAT",
            order_uuid: "bf5a5d11-4ef5-4483-aeda-64ce3f6307c7",
            price: 1.1004,
            price_sources: [],
            processed_ms: 1714594676149,
          },
        ],
        position_type: "FLAT",
        position_uuid: "c1d28e65-8e06-4fa4-984d-d2bbf66bfe11",
        return_at_close: 0.9996997328419353,
        trade_pair: ["AUDNZD", "AUD/NZD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.8134528327559352,
        close_ms: 1714594799139,
        current_return: 1.0007070599763788,
        initial_entry_price: 0.814785795149854,
        is_closed_position: true,
        miner_hotkey: "5Dxqzduahnqw8q3XSUfTcEZGU7xmAsfJubhHZwvXVLN9fSjR",
        net_leverage: 0.0,
        open_ms: 1714443316895,
        orders: [
          {
            leverage: 0.5,
            order_type: "LONG",
            order_uuid: "359a4dfd-539f-449c-8a00-3ed3326e4aad",
            price: 0.814785795149854,
            price_sources: [],
            processed_ms: 1714443316866,
          },
          {
            leverage: 0.5,
            order_type: "LONG",
            order_uuid: "163f73bc-3be4-46f7-834a-415d929f0b38",
            price: 0.81485,
            price_sources: [],
            processed_ms: 1714444620889,
          },
          {
            leverage: 0.5,
            order_type: "LONG",
            order_uuid: "7564d3df-82e6-4f37-8e6d-6ea38cdc0d98",
            price: 0.8142,
            price_sources: [],
            processed_ms: 1714446051176,
          },
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "fdfc9624-47fe-4b70-af86-d8e10fc53b8c",
            price: 0.81423,
            price_sources: [],
            processed_ms: 1714446704182,
          },
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "893da270-7a66-4d49-9059-618fff741964",
            price: 0.81364,
            price_sources: [],
            processed_ms: 1714449000456,
          },
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "e1e86db8-1d73-4d3f-99b5-f6439c04933a",
            price: 0.81304,
            price_sources: [],
            processed_ms: 1714490536868,
          },
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "dc276b47-df64-4f3e-ac00-badb55a8e23e",
            price: 0.81301,
            price_sources: [],
            processed_ms: 1714490812141,
          },
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "e3a7145d-09f6-4e10-b83c-5f7c589c094b",
            price: 0.81238,
            price_sources: [],
            processed_ms: 1714491844065,
          },
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "ab7ee608-056d-4c66-8de8-d04561e20921",
            price: 0.81249,
            price_sources: [],
            processed_ms: 1714493229252,
          },
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "6d4bcd8d-ceb9-457f-98a1-74e3330d662e",
            price: 0.81217,
            price_sources: [],
            processed_ms: 1714495172263,
          },
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "296171a5-7d95-4bfd-9233-6a521415fb2d",
            price: 0.81227,
            price_sources: [],
            processed_ms: 1714496191389,
          },
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "a01c52c4-99f4-4dc7-8ac7-caf9f271dffc",
            price: 0.81205,
            price_sources: [],
            processed_ms: 1714498246682,
          },
          {
            leverage: 4.199999999999999,
            order_type: "FLAT",
            order_uuid: "295b0024-a456-40f0-82ec-de40bbf0651d",
            price: 0.81359,
            price_sources: [],
            processed_ms: 1714594799139,
          },
        ],
        position_type: "FLAT",
        position_uuid: "3a2c95eb-cbbe-4a5e-bc74-22e5c1446397",
        return_at_close: 1.0004128521007456,
        trade_pair: ["NZDCAD", "NZD/CAD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.8950020932507335,
        close_ms: 1714598598324,
        current_return: 0.9998248575433742,
        initial_entry_price: 0.895484186501467,
        is_closed_position: true,
        miner_hotkey: "5Dxqzduahnqw8q3XSUfTcEZGU7xmAsfJubhHZwvXVLN9fSjR",
        net_leverage: 0.0,
        open_ms: 1714597512480,
        orders: [
          {
            leverage: 0.2,
            order_type: "LONG",
            order_uuid: "3637c234-6906-41e7-ae42-a14437a09943",
            price: 0.895484186501467,
            price_sources: [],
            processed_ms: 1714597512265,
          },
          {
            leverage: 0.2,
            order_type: "LONG",
            order_uuid: "04111b81-38c1-4046-bf4e-3c4aed89dde1",
            price: 0.89452,
            price_sources: [],
            processed_ms: 1714598110007,
          },
          {
            leverage: 0.4,
            order_type: "FLAT",
            order_uuid: "4747853e-bff5-4935-bf95-1fbd399656e1",
            price: 0.89461,
            price_sources: [],
            processed_ms: 1714598598324,
          },
        ],
        position_type: "FLAT",
        position_uuid: "19746d48-96ec-40af-99ce-d67e70fb7936",
        return_at_close: 0.999796862447363,
        trade_pair: ["AUDCAD", "AUD/CAD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.8131,
        close_ms: 1714600341034,
        current_return: 0.9999901611117944,
        initial_entry_price: 0.8131,
        is_closed_position: true,
        miner_hotkey: "5Dxqzduahnqw8q3XSUfTcEZGU7xmAsfJubhHZwvXVLN9fSjR",
        net_leverage: 0.0,
        open_ms: 1714599913603,
        orders: [
          {
            leverage: 0.2,
            order_type: "LONG",
            order_uuid: "50a364ab-3e0b-43d0-9828-127b2921e875",
            price: 0.8131,
            price_sources: [],
            processed_ms: 1714599913382,
          },
          {
            leverage: 0.2,
            order_type: "FLAT",
            order_uuid: "b81e0b45-bece-4931-adf0-7c5a45b1e52c",
            price: 0.81306,
            price_sources: [],
            processed_ms: 1714600341034,
          },
        ],
        position_type: "FLAT",
        position_uuid: "4b580b45-2571-4150-8b68-f00fd5c0b379",
        return_at_close: 0.9999761612495389,
        trade_pair: ["NZDCAD", "NZD/CAD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.6674842857142858,
        close_ms: 1714604427893,
        current_return: 1.0005299163211232,
        initial_entry_price: 0.66803,
        is_closed_position: true,
        miner_hotkey: "5Dxqzduahnqw8q3XSUfTcEZGU7xmAsfJubhHZwvXVLN9fSjR",
        net_leverage: 0.0,
        open_ms: 1714593260707,
        orders: [
          {
            leverage: 0.5,
            order_type: "LONG",
            order_uuid: "add3ec19-4b8b-47c9-bb67-35c9d6ffece4",
            price: 0.66803,
            price_sources: [],
            processed_ms: 1714593260342,
          },
          {
            leverage: 0.2,
            order_type: "LONG",
            order_uuid: "89c9267d-1371-4b48-a622-8a620546b79e",
            price: 0.66612,
            price_sources: [],
            processed_ms: 1714599407852,
          },
          {
            leverage: 0.01,
            order_type: "FLAT",
            order_uuid: "bbbfb74d-b0af-4c92-b36a-11e077feaf9f",
            price: 0.66799,
            price_sources: [],
            processed_ms: 1714604427893,
          },
        ],
        position_type: "FLAT",
        position_uuid: "896394d9-c94e-49f7-925d-e2b1c6807d78",
        return_at_close: 1.0004808903552236,
        trade_pair: ["CADCHF", "CAD/CHF", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 195.05375,
        close_ms: 1714607453553,
        current_return: 1.0006678621279892,
        initial_entry_price: 196.298,
        is_closed_position: true,
        miner_hotkey: "5Dxqzduahnqw8q3XSUfTcEZGU7xmAsfJubhHZwvXVLN9fSjR",
        net_leverage: 0.0,
        open_ms: 1714594305886,
        orders: [
          {
            leverage: 0.5,
            order_type: "LONG",
            order_uuid: "d1198a16-85b2-4a5c-9cc2-965e46556d0e",
            price: 196.298,
            price_sources: [],
            processed_ms: 1714594305858,
          },
          {
            leverage: 0.5,
            order_type: "LONG",
            order_uuid: "ca05b17a-0443-4e8e-b574-ba4c5e62f4c9",
            price: 194.753,
            price_sources: [],
            processed_ms: 1714595068130,
          },
          {
            leverage: 0.2,
            order_type: "LONG",
            order_uuid: "fe93896f-eff1-4cf6-843e-36d0ecd0576b",
            price: 192.695,
            price_sources: [],
            processed_ms: 1714596429802,
          },
          {
            leverage: 0.2,
            order_type: "FLAT",
            order_uuid: "568cbfa2-7fc1-4b16-bb39-2295640bcd64",
            price: 195.163,
            price_sources: [],
            processed_ms: 1714607453553,
          },
        ],
        position_type: "FLAT",
        position_uuid: "b4e6247e-affe-436d-9046-8a1fc66de156",
        return_at_close: 1.0005838060275705,
        trade_pair: ["GBPJPY", "GBP/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.81453,
        close_ms: 1714676226175,
        current_return: 1.0003204301867334,
        initial_entry_price: 0.81453,
        is_closed_position: true,
        miner_hotkey: "5Dxqzduahnqw8q3XSUfTcEZGU7xmAsfJubhHZwvXVLN9fSjR",
        net_leverage: 0.0,
        open_ms: 1714633910527,
        orders: [
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "e90fbd86-ce8d-482d-8ccb-cadd8cb09f93",
            price: 0.81453,
            price_sources: [],
            processed_ms: 1714633910173,
          },
          {
            leverage: 0.3,
            order_type: "FLAT",
            order_uuid: "1037ba53-c83c-4776-968f-e691e0456a88",
            price: 0.8154,
            price_sources: [],
            processed_ms: 1714676226175,
          },
        ],
        position_type: "FLAT",
        position_uuid: "5b052743-4a8c-47ce-a073-4855dfcf8306",
        return_at_close: 1.0002994234576994,
        trade_pair: ["NZDCAD", "NZD/CAD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.89774204135215,
        close_ms: 1714677386993,
        current_return: 1.000229896323051,
        initial_entry_price: 0.89774204135215,
        is_closed_position: true,
        miner_hotkey: "5Dxqzduahnqw8q3XSUfTcEZGU7xmAsfJubhHZwvXVLN9fSjR",
        net_leverage: 0.0,
        open_ms: 1714673469822,
        orders: [
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "a677fcfc-3b38-41db-90a6-f41a3ff19fef",
            price: 0.89774204135215,
            price_sources: [],
            processed_ms: 1714673469540,
          },
          {
            leverage: 0.3,
            order_type: "FLAT",
            order_uuid: "497537e2-eb03-4966-a528-24d2c00fb948",
            price: 0.89843,
            price_sources: [],
            processed_ms: 1714677386993,
          },
        ],
        position_type: "FLAT",
        position_uuid: "cb28ee82-6d68-4a49-8c3a-c3ff62343600",
        return_at_close: 1.000208891495228,
        trade_pair: ["AUDCAD", "AUD/CAD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 191.99650000000003,
        close_ms: 1714686001153,
        current_return: 1.0012302942414744,
        initial_entry_price: 192.393,
        is_closed_position: true,
        miner_hotkey: "5Dxqzduahnqw8q3XSUfTcEZGU7xmAsfJubhHZwvXVLN9fSjR",
        net_leverage: 0.0,
        open_ms: 1714684436452,
        orders: [
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "d9856dbd-a493-42da-ad98-d763a23f538d",
            price: 192.393,
            price_sources: [],
            processed_ms: 1714684436235,
          },
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "d132efdf-b687-4713-a798-2fed056461a7",
            price: 191.6,
            price_sources: [],
            processed_ms: 1714684917749,
          },
          {
            leverage: 0.6,
            order_type: "FLAT",
            order_uuid: "aba32323-d733-4d08-9834-2e7036c1d842",
            price: 192.391,
            price_sources: [],
            processed_ms: 1714686001153,
          },
        ],
        position_type: "FLAT",
        position_uuid: "98c2f840-6b1f-4b05-9025-f5fc6c1bca55",
        return_at_close: 1.0011882425691163,
        trade_pair: ["GBPJPY", "GBP/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.81424,
        close_ms: 1714687102097,
        current_return: 1.0004122901737509,
        initial_entry_price: 0.81496,
        is_closed_position: true,
        miner_hotkey: "5Dxqzduahnqw8q3XSUfTcEZGU7xmAsfJubhHZwvXVLN9fSjR",
        net_leverage: 0.0,
        open_ms: 1714681795832,
        orders: [
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "c45c3c8e-b9fd-4a74-a93a-2e012f090415",
            price: 0.81496,
            price_sources: [],
            processed_ms: 1714681795486,
          },
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "8229de5d-f832-4755-a52b-64a0a85ecb2a",
            price: 0.81352,
            price_sources: [],
            processed_ms: 1714685358150,
          },
          {
            leverage: 0.6,
            order_type: "FLAT",
            order_uuid: "a1f353ab-c18c-48eb-a92c-c1fa35e6c545",
            price: 0.8148,
            price_sources: [],
            processed_ms: 1714687102097,
          },
        ],
        position_type: "FLAT",
        position_uuid: "874e5b6d-4d2a-4378-8073-7b7aa37095c1",
        return_at_close: 1.0003702728575636,
        trade_pair: ["NZDCAD", "NZD/CAD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 1.1006546591805901,
        close_ms: 1714690203094,
        current_return: 1.0034134506606758,
        initial_entry_price: 1.10247,
        is_closed_position: true,
        miner_hotkey: "5Dxqzduahnqw8q3XSUfTcEZGU7xmAsfJubhHZwvXVLN9fSjR",
        net_leverage: 0.0,
        open_ms: 1714629369717,
        orders: [
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "95ba69fb-7f6f-42c2-8e2c-c534d6f1f6bb",
            price: 1.10247,
            price_sources: [],
            processed_ms: 1714629369485,
          },
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "0b562858-546c-4ace-a640-5a2c19383e10",
            price: 1.10145,
            price_sources: [],
            processed_ms: 1714677680598,
          },
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "f6327e58-5c59-448a-8cf2-c6a042968e45",
            price: 1.10139,
            price_sources: [],
            processed_ms: 1714678309494,
          },
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "7eaa8609-fe21-43b4-8dc9-2bb02d090011",
            price: 1.10126,
            price_sources: [],
            processed_ms: 1714678823418,
          },
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "32b00fef-56cd-4555-8f67-d52cdcebfb2d",
            price: 1.10119,
            price_sources: [],
            processed_ms: 1714679296623,
          },
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "defb79a7-84ab-44c7-b815-878eeadd4722",
            price: 1.10132,
            price_sources: [],
            processed_ms: 1714679766589,
          },
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "997f9052-9db3-4033-aeb0-10b2c567b722",
            price: 1.10123,
            price_sources: [],
            processed_ms: 1714680268307,
          },
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "13f2f011-04a2-4279-a8c7-873e23cd36a1",
            price: 1.1013,
            price_sources: [],
            processed_ms: 1714681066387,
          },
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "5f3d8ef1-9a0d-4f8b-8283-7b5fcb479c99",
            price: 1.10124,
            price_sources: [],
            processed_ms: 1714682258503,
          },
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "bd554d16-226a-4b59-8802-75223c920c00",
            price: 1.10099,
            price_sources: [],
            processed_ms: 1714683247045,
          },
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "ae0144f9-8e5c-40d4-b27d-9f18a6dacc8c",
            price: 1.09374,
            price_sources: [],
            processed_ms: 1714683612555,
          },
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "df3180c5-70bd-4ff5-991c-5788f993be72",
            price: 1.100275910167075,
            price_sources: [],
            processed_ms: 1714683832593,
          },
          {
            leverage: 0.2,
            order_type: "FLAT",
            order_uuid: "467c85a9-af94-411b-9f65-f95b7909514b",
            price: 1.1017,
            price_sources: [],
            processed_ms: 1714690203094,
          },
        ],
        position_type: "FLAT",
        position_uuid: "45ae251e-5b89-4e5b-90ce-3ce063cb82a6",
        return_at_close: 1.0031605904711092,
        trade_pair: ["AUDNZD", "AUD/NZD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.89756,
        close_ms: 1714690343688,
        current_return: 1.000173804536744,
        initial_entry_price: 0.89756,
        is_closed_position: true,
        miner_hotkey: "5Dxqzduahnqw8q3XSUfTcEZGU7xmAsfJubhHZwvXVLN9fSjR",
        net_leverage: 0.0,
        open_ms: 1714682478733,
        orders: [
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "a784eaca-62cb-4f70-aa3a-a824d67d88b9",
            price: 0.89756,
            price_sources: [],
            processed_ms: 1714682478514,
          },
          {
            leverage: 0.2,
            order_type: "FLAT",
            order_uuid: "028512c5-fb9d-4828-8715-2bc05dd9e401",
            price: 0.89808,
            price_sources: [],
            processed_ms: 1714690343688,
          },
        ],
        position_type: "FLAT",
        position_uuid: "fc227c8d-9fd7-4b30-8e4e-2ba6575c9f6b",
        return_at_close: 1.0001528008868488,
        trade_pair: ["AUDCAD", "AUD/CAD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.89758,
        close_ms: 1714697120318,
        current_return: 1.0002707279573966,
        initial_entry_price: 0.89758,
        is_closed_position: true,
        miner_hotkey: "5Dxqzduahnqw8q3XSUfTcEZGU7xmAsfJubhHZwvXVLN9fSjR",
        net_leverage: 0.0,
        open_ms: 1714695516704,
        orders: [
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "2f174456-b2bd-493d-a800-75e28fa3e643",
            price: 0.89758,
            price_sources: [],
            processed_ms: 1714695516427,
          },
          {
            leverage: 0.3,
            order_type: "FLAT",
            order_uuid: "d2a8f3b8-355f-4786-89d7-f48c0dcee7f5",
            price: 0.89839,
            price_sources: [],
            processed_ms: 1714697120318,
          },
        ],
        position_type: "FLAT",
        position_uuid: "d3f2d835-afd2-4a14-bd16-8514ffbf63d7",
        return_at_close: 1.0002497222721094,
        trade_pair: ["AUDCAD", "AUD/CAD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.81471,
        close_ms: 1714697573452,
        current_return: 1.0002467135545163,
        initial_entry_price: 0.81471,
        is_closed_position: true,
        miner_hotkey: "5Dxqzduahnqw8q3XSUfTcEZGU7xmAsfJubhHZwvXVLN9fSjR",
        net_leverage: 0.0,
        open_ms: 1714694859789,
        orders: [
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "e0c45a62-a132-44f2-bb98-88d03f22a465",
            price: 0.81471,
            price_sources: [],
            processed_ms: 1714694859525,
          },
          {
            leverage: 0.3,
            order_type: "FLAT",
            order_uuid: "1050fbad-727f-4e3d-9ce4-9dea18496ba2",
            price: 0.81538,
            price_sources: [],
            processed_ms: 1714697573452,
          },
        ],
        position_type: "FLAT",
        position_uuid: "bc3972f9-015e-47ba-8d48-ee3232b27c5c",
        return_at_close: 1.0002257083735315,
        trade_pair: ["NZDCAD", "NZD/CAD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 1.1017089470849764,
        close_ms: 1714704668312,
        current_return: 1.0006621885739742,
        initial_entry_price: 1.10161160770311,
        is_closed_position: true,
        miner_hotkey: "5Dxqzduahnqw8q3XSUfTcEZGU7xmAsfJubhHZwvXVLN9fSjR",
        net_leverage: 0.0,
        open_ms: 1714694355622,
        orders: [
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "f6fae69c-2eaf-4082-9e1a-f95bfe0e868a",
            price: 1.10161160770311,
            price_sources: [],
            processed_ms: 1714694355344,
          },
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "71c2edd4-29f8-4bb1-9bd2-b3438781d6fe",
            price: 1.10199,
            price_sources: [],
            processed_ms: 1714695188340,
          },
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "956d01f5-e439-4565-aa37-e090db49cd09",
            price: 1.10206681023163,
            price_sources: [],
            processed_ms: 1714696053183,
          },
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "c2266759-bc15-4ee6-98fb-45cacc252d2f",
            price: 1.10211,
            price_sources: [],
            processed_ms: 1714696798976,
          },
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "9a7846e3-73af-4ecb-ad14-ba405f40298f",
            price: 1.10212,
            price_sources: [],
            processed_ms: 1714697285121,
          },
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "48ca2850-3c5e-4414-afd2-179c6d829b5e",
            price: 1.10186,
            price_sources: [],
            processed_ms: 1714699085428,
          },
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "7dfbfebf-9aa9-4556-aa0e-c8047cf06591",
            price: 1.10163,
            price_sources: [],
            processed_ms: 1714699757725,
          },
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "260169d3-83fd-427e-9fe9-378b65859fa1",
            price: 1.10143,
            price_sources: [],
            processed_ms: 1714700460105,
          },
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "ccf73edb-5f27-490a-a2fd-d70b5fbbefdb",
            price: 1.10135,
            price_sources: [],
            processed_ms: 1714701204205,
          },
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "7dcb1453-50e5-4928-92d7-16368e77735f",
            price: 1.10138,
            price_sources: [],
            processed_ms: 1714702012093,
          },
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "2eea14d4-0c07-4839-818a-5133be1f8574",
            price: 1.10125,
            price_sources: [],
            processed_ms: 1714702915121,
          },
          {
            leverage: 0.2,
            order_type: "FLAT",
            order_uuid: "90a3babe-3b78-4f8e-a8aa-dcfca123f4a7",
            price: 1.10193,
            price_sources: [],
            processed_ms: 1714704668312,
          },
        ],
        position_type: "FLAT",
        position_uuid: "12b383b7-2760-4323-b694-f902d8a54ea0",
        return_at_close: 1.0004310356084136,
        trade_pair: ["AUDNZD", "AUD/NZD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 1.1015850000000003,
        close_ms: 1714710195255,
        current_return: 0.9999618815969793,
        initial_entry_price: 1.10183,
        is_closed_position: true,
        miner_hotkey: "5Dxqzduahnqw8q3XSUfTcEZGU7xmAsfJubhHZwvXVLN9fSjR",
        net_leverage: 0.0,
        open_ms: 1714705943740,
        orders: [
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "f09b0b08-045e-4f36-80f7-b361aed080f1",
            price: 1.10183,
            price_sources: [],
            processed_ms: 1714705943520,
          },
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "de21c7be-49d4-44c8-9b5d-e59f2a046918",
            price: 1.10158,
            price_sources: [],
            processed_ms: 1714706765110,
          },
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "6c5912e8-8047-4a3e-a93f-6bc56b79fbb6",
            price: 1.10142,
            price_sources: [],
            processed_ms: 1714707240126,
          },
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "0b835ddb-61ac-45a7-a411-e167b39bce77",
            price: 1.10151,
            price_sources: [],
            processed_ms: 1714708611432,
          },
          {
            leverage: 0.2,
            order_type: "FLAT",
            order_uuid: "ab0d3d8d-81ba-43aa-91db-cc931714a834",
            price: 1.10155,
            price_sources: [],
            processed_ms: 1714710195255,
          },
        ],
        position_type: "FLAT",
        position_uuid: "d5965618-377c-4fd8-9239-af9b1acce92d",
        return_at_close: 0.9998778847989251,
        trade_pair: ["AUDNZD", "AUD/NZD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.8152769629000264,
        close_ms: 1714710527143,
        current_return: 0.9995670557038224,
        initial_entry_price: 0.81574,
        is_closed_position: true,
        miner_hotkey: "5Dxqzduahnqw8q3XSUfTcEZGU7xmAsfJubhHZwvXVLN9fSjR",
        net_leverage: 0.0,
        open_ms: 1714702612347,
        orders: [
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "b4a14727-9046-4df1-8557-9cc911f08bd8",
            price: 0.81574,
            price_sources: [],
            processed_ms: 1714702612138,
          },
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "d8a733d6-8a49-4c0d-9f27-f00ca7ea7ae8",
            price: 0.8155,
            price_sources: [],
            processed_ms: 1714703063612,
          },
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "18399db5-b848-46dd-b47e-a37b687208f0",
            price: 0.815108656100311,
            price_sources: [],
            processed_ms: 1714707422016,
          },
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "f98a750e-193f-40a9-b600-4721f81528c5",
            price: 0.81543,
            price_sources: [],
            processed_ms: 1714707883419,
          },
          {
            leverage: 5.0,
            order_type: "LONG",
            order_uuid: "d99ae5a4-116f-4b7e-bf0a-d70f6aa800e2",
            price: 0.815236714630014,
            price_sources: [],
            processed_ms: 1714710225602,
          },
          {
            leverage: 5.0,
            order_type: "FLAT",
            order_uuid: "84f4b3d4-b50c-469d-9a33-7237d821bb92",
            price: 0.81522,
            price_sources: [],
            processed_ms: 1714710527143,
          },
        ],
        position_type: "FLAT",
        position_uuid: "803b1054-228a-4b7f-989f-4901479d40bb",
        return_at_close: 0.9991332436016469,
        trade_pair: ["NZDCAD", "NZD/CAD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.6665266666666693,
        close_ms: 1714712792270,
        current_return: 0.9993138576779015,
        initial_entry_price: 0.6675,
        is_closed_position: true,
        miner_hotkey: "5Dxqzduahnqw8q3XSUfTcEZGU7xmAsfJubhHZwvXVLN9fSjR",
        net_leverage: 0.0,
        open_ms: 1714622989503,
        orders: [
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "522cd401-f4cf-427c-96a0-52a64eaf36a4",
            price: 0.6675,
            price_sources: [],
            processed_ms: 1714622989254,
          },
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "1ed90b1e-cd6b-478d-b582-88dd5d9c8e9a",
            price: 0.6677,
            price_sources: [],
            processed_ms: 1714625450286,
          },
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "9e99a701-c050-4a49-9f51-f7d9cf677895",
            price: 0.66783,
            price_sources: [],
            processed_ms: 1714626810473,
          },
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "e92e6caf-6fa3-4ce5-ab2a-b56cf38807bf",
            price: 0.66789,
            price_sources: [],
            processed_ms: 1714628952192,
          },
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "7eb5c2b0-a386-4bc7-a2fd-85bb6a6b018d",
            price: 0.66408,
            price_sources: [],
            processed_ms: 1714633244940,
          },
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "6cd9f4d6-8eb7-4375-8d44-85c5b041d16e",
            price: 0.6651,
            price_sources: [],
            processed_ms: 1714638881955,
          },
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "55e7022e-fcdf-4f84-aeb3-8af849527cf2",
            price: 0.66458,
            price_sources: [],
            processed_ms: 1714646114824,
          },
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "9c686a15-256a-4a48-8f99-0fe335748e72",
            price: 0.66575,
            price_sources: [],
            processed_ms: 1714661364886,
          },
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "af951dfb-1f41-4e02-a63e-1c8b51f6aacd",
            price: 0.6656,
            price_sources: [],
            processed_ms: 1714670533982,
          },
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "21b795ac-b461-4e96-a01f-8039f001ed6d",
            price: 0.666,
            price_sources: [],
            processed_ms: 1714671569375,
          },
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "63ba0a62-b6bb-49f4-978d-38997cc3d250",
            price: 0.66543,
            price_sources: [],
            processed_ms: 1714673064843,
          },
          {
            leverage: 5.0,
            order_type: "LONG",
            order_uuid: "43c0beea-95e3-4422-89f4-45219a8c04c0",
            price: 0.665,
            price_sources: [],
            processed_ms: 1714711280626,
          },
          {
            leverage: -1.0,
            order_type: "SHORT",
            order_uuid: "d3f6da5d-0d70-45c4-a7da-9b39f1f7b612",
            price: 0.66544,
            price_sources: [],
            processed_ms: 1714712024627,
          },
          {
            leverage: -1.0,
            order_type: "SHORT",
            order_uuid: "c8e26735-7849-4ab1-a07e-3b23cb2e5e9f",
            price: 0.66552,
            price_sources: [],
            processed_ms: 1714712124816,
          },
          {
            leverage: -1.0,
            order_type: "SHORT",
            order_uuid: "3e980003-cec4-4b32-b59e-93b623b02213",
            price: 0.6655,
            price_sources: [],
            processed_ms: 1714712269128,
          },
          {
            leverage: -1.0,
            order_type: "SHORT",
            order_uuid: "a7584ee7-4327-40c2-b5e8-e3d2bf19107a",
            price: 0.665,
            price_sources: [],
            processed_ms: 1714712347151,
          },
          {
            leverage: -1.0,
            order_type: "SHORT",
            order_uuid: "a621ce6e-dc95-47cf-9f1a-7b0937393ff5",
            price: 0.66547,
            price_sources: [],
            processed_ms: 1714712439982,
          },
          {
            leverage: -1.0,
            order_type: "SHORT",
            order_uuid: "42bde84b-d42b-48d5-8ed6-bc044cf1b531",
            price: 0.66548,
            price_sources: [],
            processed_ms: 1714712523208,
          },
          {
            leverage: -1.0,
            order_type: "SHORT",
            order_uuid: "a901866c-e4a0-41a0-b7e0-93bbff904f38",
            price: 0.66543,
            price_sources: [],
            processed_ms: 1714712623496,
          },
          {
            leverage: -1.0,
            order_type: "SHORT",
            order_uuid: "b70b3858-0714-4054-8cb6-840487ce1300",
            price: 0.66544,
            price_sources: [],
            processed_ms: 1714712717301,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "7529429c-40d9-4785-a648-a311173be72b",
            price: 0.665,
            price_sources: [],
            processed_ms: 1714712792270,
          },
        ],
        position_type: "FLAT",
        position_uuid: "3f8a1e00-9a16-4b56-bc67-e5aacda3ac35",
        return_at_close: 0.9987332563265906,
        trade_pair: ["CADCHF", "CAD/CHF", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 67458.89677419365,
        close_ms: 1714889382616,
        current_return: 1.0002269961716157,
        initial_entry_price: 63331.91,
        is_closed_position: true,
        miner_hotkey: "5Dxqzduahnqw8q3XSUfTcEZGU7xmAsfJubhHZwvXVLN9fSjR",
        net_leverage: 0.0,
        open_ms: 1714882243481,
        orders: [
          {
            leverage: -0.01,
            order_type: "SHORT",
            order_uuid: "8fa37404-598c-4990-9708-1198b79cc276",
            price: 63331.91,
            price_sources: [],
            processed_ms: 1714882242873,
          },
          {
            leverage: -0.01,
            order_type: "SHORT",
            order_uuid: "83e2b9cd-6f6c-4776-b00d-5182e341033c",
            price: 63367.26,
            price_sources: [],
            processed_ms: 1714882874048,
          },
          {
            leverage: -0.01,
            order_type: "SHORT",
            order_uuid: "f572c6ee-984e-4a2d-b1eb-d712f1ac4357",
            price: 63521.0,
            price_sources: [],
            processed_ms: 1714883001154,
          },
          {
            leverage: -0.01,
            order_type: "SHORT",
            order_uuid: "4f4f4ddf-ef2f-4bd8-acfb-144a808333ad",
            price: 63383.1,
            price_sources: [],
            processed_ms: 1714883614149,
          },
          {
            leverage: -0.01,
            order_type: "SHORT",
            order_uuid: "c44dd591-8e3f-4cfd-895c-32dfd755616c",
            price: 63477.0,
            price_sources: [],
            processed_ms: 1714884060866,
          },
          {
            leverage: -0.01,
            order_type: "SHORT",
            order_uuid: "b99aa5d4-9b7a-4a79-829d-06da05fcc183",
            price: 63357.67,
            price_sources: [],
            processed_ms: 1714884261130,
          },
          {
            leverage: 0.059000000000000004,
            order_type: "LONG",
            order_uuid: "6ebda047-9cd4-4463-a658-69f959748c0a",
            price: 63456.0,
            price_sources: [],
            processed_ms: 1714884474742,
          },
          {
            leverage: -0.02,
            order_type: "SHORT",
            order_uuid: "6e084e7d-7f77-4d6e-8cef-57f5762a2365",
            price: 63398.98,
            price_sources: [],
            processed_ms: 1714885427882,
          },
          {
            leverage: -0.02,
            order_type: "SHORT",
            order_uuid: "af76c625-676e-4e0b-88e8-5f45449387ff",
            price: 63445.77,
            price_sources: [],
            processed_ms: 1714885646329,
          },
          {
            leverage: -0.02,
            order_type: "SHORT",
            order_uuid: "535a43ca-ad59-4b1f-a774-376ea291b45b",
            price: 63376.4,
            price_sources: [],
            processed_ms: 1714886458762,
          },
          {
            leverage: -0.02,
            order_type: "SHORT",
            order_uuid: "cc51e521-52fc-4216-bd7e-f6b109576b14",
            price: 63487.0,
            price_sources: [],
            processed_ms: 1714887309629,
          },
          {
            leverage: -0.02,
            order_type: "SHORT",
            order_uuid: "9aabddf9-0fa2-4591-9dd0-56be53c812b5",
            price: 63409.0,
            price_sources: [],
            processed_ms: 1714887372902,
          },
          {
            leverage: 0.03366666666666667,
            order_type: "LONG",
            order_uuid: "c731010c-166b-429c-8c24-45b909596bb3",
            price: 63260.52,
            price_sources: [],
            processed_ms: 1714887585486,
          },
          {
            leverage: 0.022444444444444444,
            order_type: "LONG",
            order_uuid: "b74d8f2a-eb18-42aa-94f4-498315f020a9",
            price: 63300.2,
            price_sources: [],
            processed_ms: 1714888164212,
          },
          {
            leverage: 0.02244444444444444,
            order_type: "LONG",
            order_uuid: "93a34116-1023-465d-8b55-6348120c2021",
            price: 63211.94,
            price_sources: [],
            processed_ms: 1714888721135,
          },
          {
            leverage: 0.019,
            order_type: "LONG",
            order_uuid: "0f4fa9f5-f00d-4847-843f-e55320e74a9f",
            price: 63220.76,
            price_sources: [],
            processed_ms: 1714889005721,
          },
          {
            leverage: 0.003444444444444441,
            order_type: "FLAT",
            order_uuid: "95f4f245-67d4-4422-8940-06882bfbb815",
            price: 63285.19,
            price_sources: [],
            processed_ms: 1714889382616,
          },
        ],
        position_type: "FLAT",
        position_uuid: "ca75e2ac-0861-4893-bbf2-5bdd85f5bbcb",
        return_at_close: 1.0000669598522283,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 63544.00000000001,
        close_ms: 1714890833207,
        current_return: 1.0000419740652147,
        initial_entry_price: 63544.0,
        is_closed_position: true,
        miner_hotkey: "5Dxqzduahnqw8q3XSUfTcEZGU7xmAsfJubhHZwvXVLN9fSjR",
        net_leverage: 0.0,
        open_ms: 1714890594518,
        orders: [
          {
            leverage: -0.02,
            order_type: "SHORT",
            order_uuid: "e362c744-374b-42b1-949c-8c4aec395704",
            price: 63544.0,
            price_sources: [],
            processed_ms: 1714890594253,
          },
          {
            leverage: 0.02,
            order_type: "FLAT",
            order_uuid: "c28c12f8-25e6-467b-b9a1-3155565f660f",
            price: 63410.64,
            price_sources: [],
            processed_ms: 1714890833207,
          },
        ],
        position_type: "FLAT",
        position_uuid: "f3f8793a-7d26-4ef1-93a0-6812f87cad1d",
        return_at_close: 1.0000219732257334,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 63964.28999999999,
        close_ms: 1714911415089,
        current_return: 1.000079440511246,
        initial_entry_price: 63844.0,
        is_closed_position: true,
        miner_hotkey: "5Dxqzduahnqw8q3XSUfTcEZGU7xmAsfJubhHZwvXVLN9fSjR",
        net_leverage: 0.0,
        open_ms: 1714910521743,
        orders: [
          {
            leverage: -0.02,
            order_type: "SHORT",
            order_uuid: "3bbcbdab-1551-4118-97be-72813793b231",
            price: 63844.0,
            price_sources: [],
            processed_ms: 1714910511842,
          },
          {
            leverage: -0.02,
            order_type: "SHORT",
            order_uuid: "f09714b0-88ce-455f-996a-41fc4b63bd4b",
            price: 63891.0,
            price_sources: [],
            processed_ms: 1714910620907,
          },
          {
            leverage: 0.02,
            order_type: "LONG",
            order_uuid: "521c337e-645a-4c12-b16f-36228dfa6c25",
            price: 63770.71,
            price_sources: [],
            processed_ms: 1714911103898,
          },
          {
            leverage: 0.02,
            order_type: "FLAT",
            order_uuid: "9743b5f9-4f07-48a4-af6c-416e425e39ad",
            price: 63710.7,
            price_sources: [],
            processed_ms: 1714911415089,
          },
        ],
        position_type: "FLAT",
        position_uuid: "3e536ee3-9e85-4f51-9762-421a5b6a4cd6",
        return_at_close: 1.0000394373336257,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 132341.19100437535,
        close_ms: 1714935609118,
        current_return: 1.0009194545997793,
        initial_entry_price: 64091.18,
        is_closed_position: true,
        miner_hotkey: "5Dxqzduahnqw8q3XSUfTcEZGU7xmAsfJubhHZwvXVLN9fSjR",
        net_leverage: 0.0,
        open_ms: 1714922416302,
        orders: [
          {
            leverage: -0.02,
            order_type: "SHORT",
            order_uuid: "1dff68f4-d3b2-43b7-8fad-078003c0f57b",
            price: 64091.18,
            price_sources: [],
            processed_ms: 1714922416020,
          },
          {
            leverage: -0.02,
            order_type: "SHORT",
            order_uuid: "57afcf2c-05a3-49ed-9fcd-33bd06e2e19a",
            price: 64299.0,
            price_sources: [],
            processed_ms: 1714922569702,
          },
          {
            leverage: -0.02,
            order_type: "SHORT",
            order_uuid: "c0d8d3dd-625a-4662-b526-9edf9170fe88",
            price: 64316.79,
            price_sources: [],
            processed_ms: 1714922989371,
          },
          {
            leverage: -0.02,
            order_type: "SHORT",
            order_uuid: "b0e77697-661b-49e8-b037-d16f8f047de8",
            price: 64211.45,
            price_sources: [],
            processed_ms: 1714923201747,
          },
          {
            leverage: -0.02,
            order_type: "SHORT",
            order_uuid: "2ed3097c-77b3-4236-bbbb-ef923b7865a5",
            price: 64322.69,
            price_sources: [],
            processed_ms: 1714923545284,
          },
          {
            leverage: -0.02,
            order_type: "SHORT",
            order_uuid: "21f1a012-4731-49e8-9497-033180414c27",
            price: 64357.7,
            price_sources: [],
            processed_ms: 1714923789586,
          },
          {
            leverage: -0.02,
            order_type: "SHORT",
            order_uuid: "5d853a3f-5930-4840-a4fd-8c6f393a0390",
            price: 64397.14,
            price_sources: [],
            processed_ms: 1714924296157,
          },
          {
            leverage: -0.02,
            order_type: "SHORT",
            order_uuid: "6845dacf-b342-4c79-9c40-bd5568ff6530",
            price: 64475.03,
            price_sources: [],
            processed_ms: 1714924580743,
          },
          {
            leverage: -0.02,
            order_type: "SHORT",
            order_uuid: "57abc12c-7496-4a34-a6b0-3fcffb4b9bda",
            price: 64566.0,
            price_sources: [],
            processed_ms: 1714924865639,
          },
          {
            leverage: -0.02,
            order_type: "SHORT",
            order_uuid: "bda75d57-67cc-4ba3-a319-e12062dbc99d",
            price: 64487.0,
            price_sources: [],
            processed_ms: 1714925143735,
          },
          {
            leverage: -0.02,
            order_type: "SHORT",
            order_uuid: "84e2fde2-7684-4a37-b688-2fe0762ec46c",
            price: 64437.0,
            price_sources: [],
            processed_ms: 1714925295587,
          },
          {
            leverage: -0.02,
            order_type: "SHORT",
            order_uuid: "3bcad561-8b91-4385-ab3a-478d80c53b92",
            price: 64616.0,
            price_sources: [],
            processed_ms: 1714925677980,
          },
          {
            leverage: -0.02,
            order_type: "SHORT",
            order_uuid: "67b6a1b1-c1a1-415e-9653-dad7b8eadc80",
            price: 64570.0,
            price_sources: [],
            processed_ms: 1714925856420,
          },
          {
            leverage: -0.02,
            order_type: "SHORT",
            order_uuid: "0d775e82-a096-4eab-82c2-1caffd77097b",
            price: 64346.35,
            price_sources: [],
            processed_ms: 1714926294738,
          },
          {
            leverage: -0.02,
            order_type: "SHORT",
            order_uuid: "2237ff01-ba1c-4712-960f-01574953efa7",
            price: 64322.65,
            price_sources: [],
            processed_ms: 1714926486357,
          },
          {
            leverage: 0.075,
            order_type: "LONG",
            order_uuid: "d001d9cc-d45c-4acb-a98c-5ffb69767ead",
            price: 64262.8,
            price_sources: [],
            processed_ms: 1714926790888,
          },
          {
            leverage: -0.02,
            order_type: "SHORT",
            order_uuid: "53addae3-1d8d-4bf0-8c71-a0a277958945",
            price: 64341.1,
            price_sources: [],
            processed_ms: 1714927273838,
          },
          {
            leverage: 0.12249999999999998,
            order_type: "LONG",
            order_uuid: "8bca50e2-957b-41e0-8066-60bb2c8a9ecc",
            price: 64283.48,
            price_sources: [],
            processed_ms: 1714927526943,
          },
          {
            leverage: -0.02,
            order_type: "SHORT",
            order_uuid: "87125972-c553-4c99-9147-c5305076c8f3",
            price: 64345.86,
            price_sources: [],
            processed_ms: 1714927784781,
          },
          {
            leverage: 0.07125,
            order_type: "LONG",
            order_uuid: "ea060d60-5834-42f6-ae71-9e8037b42537",
            price: 64280.12,
            price_sources: [],
            processed_ms: 1714928004203,
          },
          {
            leverage: 0.0178125,
            order_type: "LONG",
            order_uuid: "72a70599-1d5e-4037-8776-1eb441fe3c63",
            price: 64280.72,
            price_sources: [],
            processed_ms: 1714928271112,
          },
          {
            leverage: 0.02671875,
            order_type: "LONG",
            order_uuid: "6ddd4c35-936c-4af5-a8e1-b35e9e8d4886",
            price: 64283.29,
            price_sources: [],
            processed_ms: 1714928604133,
          },
          {
            leverage: 0.0066796875,
            order_type: "LONG",
            order_uuid: "68740885-fdf6-4157-95c1-2bb0ec8c891a",
            price: 64267.11,
            price_sources: [],
            processed_ms: 1714928906525,
          },
          {
            leverage: 0.01001953125,
            order_type: "LONG",
            order_uuid: "9d6f1ec5-65ae-4860-be13-064e2782dc81",
            price: 64262.49,
            price_sources: [],
            processed_ms: 1714929167339,
          },
          {
            leverage: 0.005009765625,
            order_type: "LONG",
            order_uuid: "15eb1f25-69fe-458d-8fc8-e34e869e7319",
            price: 64284.77,
            price_sources: [],
            processed_ms: 1714929513288,
          },
          {
            leverage: -0.02,
            order_type: "SHORT",
            order_uuid: "d8ff77c2-9f7c-487d-9977-00abb40fc90e",
            price: 64551.0,
            price_sources: [],
            processed_ms: 1714929991376,
          },
          {
            leverage: -0.02,
            order_type: "SHORT",
            order_uuid: "a3d9ed74-a3bd-496b-8eef-a0af5e54f5bf",
            price: 64532.0,
            price_sources: [],
            processed_ms: 1714930161487,
          },
          {
            leverage: -0.02,
            order_type: "SHORT",
            order_uuid: "8cba02e4-23f5-4287-a6bf-855860c1439c",
            price: 64430.79,
            price_sources: [],
            processed_ms: 1714930366192,
          },
          {
            leverage: -0.02,
            order_type: "SHORT",
            order_uuid: "4ce5d794-894a-4905-9645-b0e8d8d93572",
            price: 64531.0,
            price_sources: [],
            processed_ms: 1714930714785,
          },
          {
            leverage: 0.021252441406250003,
            order_type: "LONG",
            order_uuid: "cc048ac3-00c5-4188-8f76-1561b428a92d",
            price: 64302.55,
            price_sources: [],
            processed_ms: 1714931021175,
          },
          {
            leverage: 0.007969665527343751,
            order_type: "LONG",
            order_uuid: "d6b62020-f01c-4fca-bcba-95f9d6853809",
            price: 64240.8,
            price_sources: [],
            processed_ms: 1714931325943,
          },
          {
            leverage: 0.013946914672851564,
            order_type: "LONG",
            order_uuid: "39d9fb29-eae3-4592-8848-99e3bb5fe95c",
            price: 64257.96,
            price_sources: [],
            processed_ms: 1714931572153,
          },
          {
            leverage: 0.010460186004638673,
            order_type: "LONG",
            order_uuid: "6760bce7-e303-425a-90c1-27acea87bc9d",
            price: 64207.99,
            price_sources: [],
            processed_ms: 1714931882149,
          },
          {
            leverage: 0.01569027900695801,
            order_type: "LONG",
            order_uuid: "cb8920ce-7055-4859-8459-a47d809d63dc",
            price: 64242.6,
            price_sources: [],
            processed_ms: 1714932187196,
          },
          {
            leverage: 0.003922569751739502,
            order_type: "LONG",
            order_uuid: "243ca3ca-0d99-4177-859e-977065fdf5be",
            price: 64227.2,
            price_sources: [],
            processed_ms: 1714932494021,
          },
          {
            leverage: 0.002941927313804627,
            order_type: "LONG",
            order_uuid: "abea4c82-2192-43a8-968f-df0dece5bda3",
            price: 64018.1,
            price_sources: [],
            processed_ms: 1714932796031,
          },
          {
            leverage: 0.00220644548535347,
            order_type: "LONG",
            order_uuid: "f3ad4316-597e-4c14-9238-fe083640df52",
            price: 64105.29,
            price_sources: [],
            processed_ms: 1714933101314,
          },
          {
            leverage: 0.0016548341140151025,
            order_type: "LONG",
            order_uuid: "ef4b0d77-f99f-4ea0-9f81-caef23cc74b0",
            price: 64083.01,
            price_sources: [],
            processed_ms: 1714933403966,
          },
          {
            leverage: 0.001241125585511327,
            order_type: "LONG",
            order_uuid: "4fcd5d84-3a89-40b7-bf73-a1f61c79f07e",
            price: 64024.68,
            price_sources: [],
            processed_ms: 1714933698301,
          },
          {
            leverage: 0.0018616883782669904,
            order_type: "LONG",
            order_uuid: "2833a1ae-4111-4fe6-9eae-cf6b48af95d8",
            price: 64010.66,
            price_sources: [],
            processed_ms: 1714934576981,
          },
          {
            leverage: 0.001,
            order_type: "LONG",
            order_uuid: "c64cea8d-3c8f-4d1c-91f1-235aa3d536dd",
            price: 63967.13,
            price_sources: [],
            processed_ms: 1714935295609,
          },
          {
            leverage: 0.0008616883782669904,
            order_type: "FLAT",
            order_uuid: "1bc99d14-475e-4752-b4f5-344681fd8747",
            price: 63953.44,
            price_sources: [],
            processed_ms: 1714935609118,
          },
        ],
        position_type: "FLAT",
        position_uuid: "dd019dbb-a321-4258-9965-f708f7e2db24",
        return_at_close: 1.0004990684288473,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 64386.801188007594,
        close_ms: 1714942034040,
        current_return: 1.0002250247786246,
        initial_entry_price: 64074.0,
        is_closed_position: true,
        miner_hotkey: "5Dxqzduahnqw8q3XSUfTcEZGU7xmAsfJubhHZwvXVLN9fSjR",
        net_leverage: 0.0,
        open_ms: 1714937861192,
        orders: [
          {
            leverage: -0.05,
            order_type: "SHORT",
            order_uuid: "05ecffa8-0359-463e-9e5b-fb156e4e299d",
            price: 64074.0,
            price_sources: [],
            processed_ms: 1714937860822,
          },
          {
            leverage: 0.025,
            order_type: "LONG",
            order_uuid: "101a5df1-0a2f-419f-959a-61a22cb9b1e0",
            price: 63930.01,
            price_sources: [],
            processed_ms: 1714938085561,
          },
          {
            leverage: 0.001,
            order_type: "LONG",
            order_uuid: "40d95256-4c6d-400e-98e8-656b39a1dae0",
            price: 63868.51,
            price_sources: [],
            processed_ms: 1714938394056,
          },
          {
            leverage: 0.001,
            order_type: "LONG",
            order_uuid: "a57d86c0-391a-4639-9c43-7dbed0bccbf4",
            price: 63886.02,
            price_sources: [],
            processed_ms: 1714938701201,
          },
          {
            leverage: 0.001,
            order_type: "LONG",
            order_uuid: "938c0578-e7c6-4038-89f5-5f911ffb4755",
            price: 63906.27,
            price_sources: [],
            processed_ms: 1714939007283,
          },
          {
            leverage: 0.011,
            order_type: "LONG",
            order_uuid: "da474876-ff20-46d6-a3cb-6b32b7eb2912",
            price: 63861.79,
            price_sources: [],
            processed_ms: 1714939604043,
          },
          {
            leverage: 0.0055,
            order_type: "LONG",
            order_uuid: "1ca3792e-e701-4166-90d6-3c26610bdafb",
            price: 63851.9,
            price_sources: [],
            processed_ms: 1714939917976,
          },
          {
            leverage: -0.05,
            order_type: "SHORT",
            order_uuid: "9994e468-a1e3-4d6f-acc4-99ca9f1b22fd",
            price: 63827.89,
            price_sources: [],
            processed_ms: 1714940226392,
          },
          {
            leverage: 0.02775,
            order_type: "LONG",
            order_uuid: "3a499674-a8d7-4e9a-a6fa-90b268ac3f09",
            price: 63755.28,
            price_sources: [],
            processed_ms: 1714940545138,
          },
          {
            leverage: 0.001734375,
            order_type: "LONG",
            order_uuid: "1c5ab0f4-bc42-4c5c-a712-7b706ab4a9f7",
            price: 63724.6,
            price_sources: [],
            processed_ms: 1714940794036,
          },
          {
            leverage: 0.0016259765625,
            order_type: "LONG",
            order_uuid: "97677af6-2bec-4e08-95cf-98c655097117",
            price: 63642.87,
            price_sources: [],
            processed_ms: 1714941098309,
          },
          {
            leverage: 0.00152435302734375,
            order_type: "LONG",
            order_uuid: "adf109e9-dc0e-4252-aa22-57e84d7cd626",
            price: 63701.78,
            price_sources: [],
            processed_ms: 1714941414913,
          },
          {
            leverage: 0.0014290809631347656,
            order_type: "LONG",
            order_uuid: "159ecd9a-7798-48ea-b8c5-2aadc7069302",
            price: 63671.58,
            price_sources: [],
            processed_ms: 1714941721096,
          },
          {
            leverage: 0.027679526805877687,
            order_type: "LONG",
            order_uuid: "2062a855-9ebb-4024-9fad-ebad1a53e97c",
            price: 63714.19,
            price_sources: [],
            processed_ms: 1714942034040,
          },
        ],
        position_type: "FLAT",
        position_uuid: "76f7fbe1-80f0-4a21-9f30-04f336d0dcc6",
        return_at_close: 1.0001250022761468,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 70749.12679978475,
        close_ms: 1714967416180,
        current_return: 1.00103911134532,
        initial_entry_price: 63741.79,
        is_closed_position: true,
        miner_hotkey: "5Dxqzduahnqw8q3XSUfTcEZGU7xmAsfJubhHZwvXVLN9fSjR",
        net_leverage: 0.0,
        open_ms: 1714942820213,
        orders: [
          {
            leverage: -0.05,
            order_type: "SHORT",
            order_uuid: "e6738a5e-5556-4903-9d77-dfceeb1ab50c",
            price: 63741.79,
            price_sources: [],
            processed_ms: 1714942813021,
          },
          {
            leverage: -0.05,
            order_type: "SHORT",
            order_uuid: "995b4825-cf9f-426c-9761-38d60b94da26",
            price: 63852.0,
            price_sources: [],
            processed_ms: 1714943121849,
          },
          {
            leverage: -0.05,
            order_type: "SHORT",
            order_uuid: "45431368-6810-495e-8d60-e55d32e89591",
            price: 63723.17,
            price_sources: [],
            processed_ms: 1714943330642,
          },
          {
            leverage: 0.018750000000000003,
            order_type: "LONG",
            order_uuid: "903aa9ff-4eef-4711-8b03-d36197fa7dbc",
            price: 63629.29,
            price_sources: [],
            processed_ms: 1714943518864,
          },
          {
            leverage: 0.016406250000000004,
            order_type: "LONG",
            order_uuid: "c2c824c0-3aa8-4253-b18b-72aa2eda6ea9",
            price: 63664.87,
            price_sources: [],
            processed_ms: 1714943831003,
          },
          {
            leverage: 0.05742187500000001,
            order_type: "LONG",
            order_uuid: "66de1999-9eec-4575-b02e-67d5b10e208a",
            price: 63676.61,
            price_sources: [],
            processed_ms: 1714944148221,
          },
          {
            leverage: -0.05,
            order_type: "SHORT",
            order_uuid: "070b280f-c9a3-4c73-90f8-72be0406ba2d",
            price: 63912.0,
            price_sources: [],
            processed_ms: 1714944952472,
          },
          {
            leverage: 0.05371093750000001,
            order_type: "LONG",
            order_uuid: "6b767d73-dfea-4c29-a040-47bb8b4b05de",
            price: 63710.01,
            price_sources: [],
            processed_ms: 1714945774069,
          },
          {
            leverage: -0.05,
            order_type: "SHORT",
            order_uuid: "4410f36e-56c8-4e5d-943c-5bcd81decf44",
            price: 63728.68,
            price_sources: [],
            processed_ms: 1714948203523,
          },
          {
            leverage: -0.05,
            order_type: "SHORT",
            order_uuid: "92356efb-49a2-47e7-bba5-6461a7dfeac6",
            price: 63904.0,
            price_sources: [],
            processed_ms: 1714948645460,
          },
          {
            leverage: -0.05,
            order_type: "SHORT",
            order_uuid: "81527926-6755-4b79-abb5-61ec6559e039",
            price: 63965.0,
            price_sources: [],
            processed_ms: 1714949301830,
          },
          {
            leverage: -0.05,
            order_type: "SHORT",
            order_uuid: "e5f97625-7088-475e-8bd0-4e1723e56fc6",
            price: 63973.0,
            price_sources: [],
            processed_ms: 1714949554479,
          },
          {
            leverage: -0.05,
            order_type: "SHORT",
            order_uuid: "4c9e492a-ac5a-44a4-9536-b2de8e975882",
            price: 63905.1,
            price_sources: [],
            processed_ms: 1714949944305,
          },
          {
            leverage: -0.05,
            order_type: "SHORT",
            order_uuid: "94f2304c-ddae-4b43-a8fe-b3a6b8b1c5c2",
            price: 63855.4,
            price_sources: [],
            processed_ms: 1714950234611,
          },
          {
            leverage: -0.05,
            order_type: "SHORT",
            order_uuid: "4e5fbb08-362f-4199-8600-78af77c8b800",
            price: 63898.23,
            price_sources: [],
            processed_ms: 1714950462519,
          },
          {
            leverage: -0.05,
            order_type: "SHORT",
            order_uuid: "f1da9b4a-238f-4abd-8e27-6deb619e3df5",
            price: 64185.85,
            price_sources: [],
            processed_ms: 1714951588850,
          },
          {
            leverage: -0.05,
            order_type: "SHORT",
            order_uuid: "a6af6ca6-0924-4def-8355-11916e6039a8",
            price: 64098.88,
            price_sources: [],
            processed_ms: 1714952514472,
          },
          {
            leverage: -0.05,
            order_type: "SHORT",
            order_uuid: "911a3903-2a5e-42a1-b5d3-69c085c54f87",
            price: 64036.51,
            price_sources: [],
            processed_ms: 1714952781024,
          },
          {
            leverage: -0.05,
            order_type: "SHORT",
            order_uuid: "4e6d6e70-f6a1-4663-9f10-3112f73dc373",
            price: 64080.01,
            price_sources: [],
            processed_ms: 1714953226486,
          },
          {
            leverage: -0.05,
            order_type: "SHORT",
            order_uuid: "9cc14451-cf94-406a-89f7-06f1e1ca9624",
            price: 64238.0,
            price_sources: [],
            processed_ms: 1714953759896,
          },
          {
            leverage: -0.05,
            order_type: "SHORT",
            order_uuid: "ae734270-ead3-4653-91a7-b93fb423b00f",
            price: 64252.29,
            price_sources: [],
            processed_ms: 1714954862464,
          },
          {
            leverage: -0.05,
            order_type: "SHORT",
            order_uuid: "758b11f2-8d7b-4b54-bdc6-c83b87c2b8dd",
            price: 64448.0,
            price_sources: [],
            processed_ms: 1714957481491,
          },
          {
            leverage: -0.05,
            order_type: "SHORT",
            order_uuid: "cac9434e-8b9c-43bf-beb8-f4279c2f4190",
            price: 64554.0,
            price_sources: [],
            processed_ms: 1714957626545,
          },
          {
            leverage: -0.05,
            order_type: "SHORT",
            order_uuid: "e66c8819-b587-44a6-b4b6-8438611fe166",
            price: 64375.0,
            price_sources: [],
            processed_ms: 1714957827086,
          },
          {
            leverage: -0.05,
            order_type: "SHORT",
            order_uuid: "f35bd940-0012-4db8-8417-63635a31d8f5",
            price: 64446.67,
            price_sources: [],
            processed_ms: 1714958333007,
          },
          {
            leverage: 0.4,
            order_type: "LONG",
            order_uuid: "e5009ff7-6b2c-427c-a558-ce8ce31dcef0",
            price: 64193.35,
            price_sources: [],
            processed_ms: 1714959729136,
          },
          {
            leverage: 0.25185546875000014,
            order_type: "LONG",
            order_uuid: "863f7a60-8be3-421f-a513-b7b9f9760202",
            price: 63975.47,
            price_sources: [],
            processed_ms: 1714963885109,
          },
          {
            leverage: 0.03148193359375002,
            order_type: "LONG",
            order_uuid: "84fb6c34-71fc-4bb0-849c-fcebe29d9649",
            price: 63896.61,
            price_sources: [],
            processed_ms: 1714964137155,
          },
          {
            leverage: 0.027546691894531267,
            order_type: "LONG",
            order_uuid: "5b20a88e-301d-437f-aacb-938d5a962d22",
            price: 63884.84,
            price_sources: [],
            processed_ms: 1714964444143,
          },
          {
            leverage: 0.04820671081542972,
            order_type: "LONG",
            order_uuid: "cc6f2731-ff93-4c6a-9eea-88bc0a42db9c",
            price: 63936.15,
            price_sources: [],
            processed_ms: 1714964696024,
          },
          {
            leverage: 0.03615503311157229,
            order_type: "LONG",
            order_uuid: "c7872399-5d85-4d6f-b0d9-c948d8707f45",
            price: 63883.81,
            price_sources: [],
            processed_ms: 1714965001190,
          },
          {
            leverage: 0.02711627483367922,
            order_type: "LONG",
            order_uuid: "b634c1f7-60e4-4c57-b17c-85ffc3321784",
            price: 63726.93,
            price_sources: [],
            processed_ms: 1714965310840,
          },
          {
            leverage: 0.020337206125259414,
            order_type: "LONG",
            order_uuid: "3d88e1f8-996b-4948-9e6e-5016ddf35873",
            price: 63812.7,
            price_sources: [],
            processed_ms: 1714965615133,
          },
          {
            leverage: 0.015252904593944561,
            order_type: "LONG",
            order_uuid: "e5c76ee3-82c1-44c6-abcf-c042adb69c0f",
            price: 63871.36,
            price_sources: [],
            processed_ms: 1714965921188,
          },
          {
            leverage: 0.011439678445458422,
            order_type: "LONG",
            order_uuid: "46eb0f94-4916-4969-9783-389d2d711cf2",
            price: 63880.32,
            price_sources: [],
            processed_ms: 1714966231270,
          },
          {
            leverage: 0.008579758834093816,
            order_type: "LONG",
            order_uuid: "1f060897-8e73-486f-8757-12a8639cf3ea",
            price: 63896.6,
            price_sources: [],
            processed_ms: 1714966541023,
          },
          {
            leverage: 0.006434819125570363,
            order_type: "LONG",
            order_uuid: "318b2c41-3486-46e5-8d47-f6147af40466",
            price: 63933.46,
            price_sources: [],
            processed_ms: 1714966854014,
          },
          {
            leverage: 0.009652228688355545,
            order_type: "LONG",
            order_uuid: "8bb45d7c-b248-4fc9-ba1f-1d0947c72779",
            price: 63848.08,
            price_sources: [],
            processed_ms: 1714967101069,
          },
          {
            leverage: 0.009652228688355545,
            order_type: "LONG",
            order_uuid: "b62d3dd8-a604-4d77-bdb7-e5fa286de605",
            price: 63887.0,
            price_sources: [],
            processed_ms: 1714967416180,
          },
        ],
        position_type: "FLAT",
        position_uuid: "3362cb83-35d3-444a-9ebf-51dac6071478",
        return_at_close: 0.9999880202784075,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 337163.9308496085,
        close_ms: 1714999002348,
        current_return: 1.0047873439007726,
        initial_entry_price: 63844.51,
        is_closed_position: true,
        miner_hotkey: "5Dxqzduahnqw8q3XSUfTcEZGU7xmAsfJubhHZwvXVLN9fSjR",
        net_leverage: 0.0,
        open_ms: 1714968134988,
        orders: [
          {
            leverage: -0.05,
            order_type: "SHORT",
            order_uuid: "1bf1b2e2-3dc8-41f9-a710-7bfb05aa06b7",
            price: 63844.51,
            price_sources: [],
            processed_ms: 1714968132795,
          },
          {
            leverage: -0.05,
            order_type: "SHORT",
            order_uuid: "c9ed518c-b17e-4bd2-8e30-7beef157cad8",
            price: 63800.01,
            price_sources: [],
            processed_ms: 1714968387726,
          },
          {
            leverage: -0.05,
            order_type: "SHORT",
            order_uuid: "d368be6a-ab3f-4204-bbb5-0eeb238026e4",
            price: 63967.8,
            price_sources: [],
            processed_ms: 1714969106536,
          },
          {
            leverage: -0.05,
            order_type: "SHORT",
            order_uuid: "75516585-6d97-433f-99f0-2af0eb478ff5",
            price: 63986.01,
            price_sources: [],
            processed_ms: 1714969287390,
          },
          {
            leverage: -0.05,
            order_type: "SHORT",
            order_uuid: "4fa12494-1335-43d5-9fe9-c1bda45b151d",
            price: 63886.02,
            price_sources: [],
            processed_ms: 1714969947307,
          },
          {
            leverage: -0.05,
            order_type: "SHORT",
            order_uuid: "ba7c057a-5da2-4951-b4ab-a7bf22c52e46",
            price: 64011.36,
            price_sources: [],
            processed_ms: 1714970553506,
          },
          {
            leverage: -0.05,
            order_type: "SHORT",
            order_uuid: "3c96221d-5d12-465a-a526-e284257fa95b",
            price: 64077.5,
            price_sources: [],
            processed_ms: 1714970899284,
          },
          {
            leverage: -0.05,
            order_type: "SHORT",
            order_uuid: "4a4e7cce-ebd1-4337-8332-e44ee99a052d",
            price: 64310.0,
            price_sources: [],
            processed_ms: 1714971672620,
          },
          {
            leverage: -0.05,
            order_type: "SHORT",
            order_uuid: "e9554363-baa0-4033-a443-3b34bc404dc9",
            price: 64317.0,
            price_sources: [],
            processed_ms: 1714972675187,
          },
          {
            leverage: -0.05,
            order_type: "SHORT",
            order_uuid: "5b597894-f191-4b54-b177-82b50cf41fab",
            price: 64249.39,
            price_sources: [],
            processed_ms: 1714972843823,
          },
          {
            leverage: -0.05,
            order_type: "SHORT",
            order_uuid: "fe025256-1906-4131-a451-934c4ac96676",
            price: 64312.85,
            price_sources: [],
            processed_ms: 1714973293681,
          },
          {
            leverage: -0.05,
            order_type: "SHORT",
            order_uuid: "58a5b89b-076e-4895-abdc-5fce73a3e5d9",
            price: 64314.03,
            price_sources: [],
            processed_ms: 1714975827876,
          },
          {
            leverage: -0.05,
            order_type: "SHORT",
            order_uuid: "1b55e367-460b-4b8e-a10f-ff23e6e3f3cf",
            price: 64317.3,
            price_sources: [],
            processed_ms: 1714976490162,
          },
          {
            leverage: -0.05,
            order_type: "SHORT",
            order_uuid: "2d0cef68-b894-4c77-b02e-7854ea63c55a",
            price: 64438.76,
            price_sources: [],
            processed_ms: 1714980948331,
          },
          {
            leverage: -0.05,
            order_type: "SHORT",
            order_uuid: "f7d18ea5-27f8-4ebe-babd-a22b9fd21a73",
            price: 64490.63,
            price_sources: [],
            processed_ms: 1714981430057,
          },
          {
            leverage: -0.05,
            order_type: "SHORT",
            order_uuid: "dc23b9d5-71f2-4ab7-8fad-2ca05536bd0e",
            price: 64581.11,
            price_sources: [],
            processed_ms: 1714982373187,
          },
          {
            leverage: -0.05,
            order_type: "SHORT",
            order_uuid: "bfa2251e-775f-4319-8825-5fc81dac4eff",
            price: 64753.48,
            price_sources: [],
            processed_ms: 1714983504273,
          },
          {
            leverage: -0.05,
            order_type: "SHORT",
            order_uuid: "242abbf5-8d7f-4697-9137-52fd468f4a58",
            price: 64961.18,
            price_sources: [],
            processed_ms: 1714983687160,
          },
          {
            leverage: -0.05,
            order_type: "SHORT",
            order_uuid: "e199ec1a-487b-47be-93dd-55b08f7a2637",
            price: 65254.09,
            price_sources: [],
            processed_ms: 1714983969396,
          },
          {
            leverage: -0.05,
            order_type: "SHORT",
            order_uuid: "b1226ffe-11ba-4b22-a8a8-0e7ff04e2319",
            price: 65500.0,
            price_sources: [],
            processed_ms: 1714984366780,
          },
          {
            leverage: -0.05,
            order_type: "SHORT",
            order_uuid: "2f849871-cccc-44f8-988b-fa9492fc9d4e",
            price: 65328.4,
            price_sources: [],
            processed_ms: 1714984546546,
          },
          {
            leverage: -0.05,
            order_type: "SHORT",
            order_uuid: "5844976f-bdfc-4cbb-b28e-a923deb57714",
            price: 65465.5,
            price_sources: [],
            processed_ms: 1714984905376,
          },
          {
            leverage: -0.05,
            order_type: "SHORT",
            order_uuid: "cc73f42b-adc3-4692-bc46-a3bb006a4f1c",
            price: 65407.86,
            price_sources: [],
            processed_ms: 1714985152111,
          },
          {
            leverage: -0.05,
            order_type: "SHORT",
            order_uuid: "4e9fb3ee-19e6-40cb-a6a9-201d298560ca",
            price: 65285.36,
            price_sources: [],
            processed_ms: 1714985494746,
          },
          {
            leverage: -0.05,
            order_type: "SHORT",
            order_uuid: "ce075460-d090-4cd5-aca3-51e9b7b861c7",
            price: 65189.2,
            price_sources: [],
            processed_ms: 1714986039173,
          },
          {
            leverage: -0.05,
            order_type: "SHORT",
            order_uuid: "dd3cb7d4-c861-46f3-8ead-03f2c2f02105",
            price: 65264.2,
            price_sources: [],
            processed_ms: 1714986457632,
          },
          {
            leverage: -0.05,
            order_type: "SHORT",
            order_uuid: "ce620cff-7b58-43c0-8dd7-fcbd68e76dfa",
            price: 65462.0,
            price_sources: [],
            processed_ms: 1714987404649,
          },
          {
            leverage: 0.6750000000000003,
            order_type: "LONG",
            order_uuid: "93994715-c00f-45ac-a16c-089bf64acf7b",
            price: 64518.1,
            price_sources: [],
            processed_ms: 1714991956292,
          },
          {
            leverage: 0.16875000000000007,
            order_type: "LONG",
            order_uuid: "03770f2e-5818-4a45-9cc7-15d4a09616f4",
            price: 64462.0,
            price_sources: [],
            processed_ms: 1714992078204,
          },
          {
            leverage: 0.015820312500000006,
            order_type: "LONG",
            order_uuid: "e16f6b7f-06be-4f8a-9af4-8c5395205433",
            price: 64183.89,
            price_sources: [],
            processed_ms: 1714992381108,
          },
          {
            leverage: 0.015325927734375007,
            order_type: "LONG",
            order_uuid: "e2745ee3-5c55-4285-bc6c-e976707ff9cc",
            price: 64015.04,
            price_sources: [],
            processed_ms: 1714992685147,
          },
          {
            leverage: 0.014846992492675787,
            order_type: "LONG",
            order_uuid: "f73b05d8-6b58-4205-8e6a-26505a66a3a0",
            price: 64032.95,
            price_sources: [],
            processed_ms: 1714992992225,
          },
          {
            leverage: 0.014383023977279669,
            order_type: "LONG",
            order_uuid: "5badf735-fce4-4b89-a431-bfa96762c999",
            price: 64099.84,
            price_sources: [],
            processed_ms: 1714993300175,
          },
          {
            leverage: 0.02786710895597936,
            order_type: "LONG",
            order_uuid: "c3239c9a-a238-488e-a679-16d5ebcd61b8",
            price: 64297.11,
            price_sources: [],
            processed_ms: 1714993609131,
          },
          {
            leverage: 0.02612541464623065,
            order_type: "LONG",
            order_uuid: "2900fd92-90c8-48d0-915c-cd6ff61a0087",
            price: 64273.34,
            price_sources: [],
            processed_ms: 1714993913927,
          },
          {
            leverage: 0.04898515246168247,
            order_type: "LONG",
            order_uuid: "b60fd958-fa90-4311-ad25-f2322b28730a",
            price: 64322.37,
            price_sources: [],
            processed_ms: 1714994162365,
          },
          {
            leverage: 0.04286200840397216,
            order_type: "LONG",
            order_uuid: "7b8cbbab-1628-4753-8f80-7b2f632638dc",
            price: 64306.0,
            price_sources: [],
            processed_ms: 1714994480330,
          },
          {
            leverage: 0.037504257353475644,
            order_type: "LONG",
            order_uuid: "6c950410-f9cc-4498-98b9-252b652b7719",
            price: 64185.21,
            price_sources: [],
            processed_ms: 1714994799021,
          },
          {
            leverage: 0.03281622518429119,
            order_type: "LONG",
            order_uuid: "783e3402-e154-420d-a992-0673c179c2ab",
            price: 64273.02,
            price_sources: [],
            processed_ms: 1714995110223,
          },
          {
            leverage: 0.02871419703625479,
            order_type: "LONG",
            order_uuid: "d5cc6d2b-92cd-410e-9173-5cb14af94998",
            price: 64168.1,
            price_sources: [],
            processed_ms: 1714995421884,
          },
          {
            leverage: 0.05024984481344588,
            order_type: "LONG",
            order_uuid: "5710ad78-0a34-4e5a-a348-5825a9fd9399",
            price: 64171.0,
            price_sources: [],
            processed_ms: 1714995670890,
          },
          {
            leverage: 0.03768738361008441,
            order_type: "LONG",
            order_uuid: "3068bad5-bcc0-4d8b-9143-3e57f093005d",
            price: 64142.2,
            price_sources: [],
            processed_ms: 1714995981869,
          },
          {
            leverage: 0.028265537707563307,
            order_type: "LONG",
            order_uuid: "408bcaca-d7de-43f9-878e-cea9413c88fc",
            price: 64118.83,
            price_sources: [],
            processed_ms: 1714996297179,
          },
          {
            leverage: 0.02119915328067248,
            order_type: "LONG",
            order_uuid: "2960dfad-177b-4ae0-accf-7124d6fae58f",
            price: 64188.06,
            price_sources: [],
            processed_ms: 1714996608948,
          },
          {
            leverage: 0.01589936496050436,
            order_type: "LONG",
            order_uuid: "38f48bfc-3760-46c4-bac4-20cc8fff7594",
            price: 64249.62,
            price_sources: [],
            processed_ms: 1714996924009,
          },
          {
            leverage: 0.02384904744075654,
            order_type: "LONG",
            order_uuid: "a287800a-b9ac-4e22-ba2e-a3496deba562",
            price: 64293.95,
            price_sources: [],
            processed_ms: 1714997169063,
          },
          {
            leverage: 0.01192452372037827,
            order_type: "LONG",
            order_uuid: "d03ab00a-4d33-40f5-9c93-2c6249fa7a8b",
            price: 64253.0,
            price_sources: [],
            processed_ms: 1714997481361,
          },
          {
            leverage: 0.005962261860189135,
            order_type: "LONG",
            order_uuid: "18028ace-0da8-4f33-a95b-a85308aaddd9",
            price: 64089.7,
            price_sources: [],
            processed_ms: 1714997790001,
          },
          {
            leverage: 0.0014905654650472838,
            order_type: "LONG",
            order_uuid: "6496532b-334f-4900-9aa5-a628eb28a027",
            price: 64063.31,
            price_sources: [],
            processed_ms: 1714998123863,
          },
          {
            leverage: 0.0022358481975709257,
            order_type: "LONG",
            order_uuid: "6cd52a1a-7547-488a-b61f-2ef1b1fe712b",
            price: 63954.2,
            price_sources: [],
            processed_ms: 1714998377350,
          },
          {
            leverage: 0.0011179240987854628,
            order_type: "LONG",
            order_uuid: "6308d589-5bf0-4b0e-b1f6-aaa2dfcc7fc3",
            price: 63799.11,
            price_sources: [],
            processed_ms: 1714998686944,
          },
          {
            leverage: 0.006264740512348183,
            order_type: "LONG",
            order_uuid: "469c1291-e253-493c-acba-7e438f5518ee",
            price: 63759.3,
            price_sources: [],
            processed_ms: 1714999002348,
          },
        ],
        position_type: "FLAT",
        position_uuid: "35e58247-d743-4df7-8718-79e570f07169",
        return_at_close: 1.0034308809865067,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 74671.79767184945,
        close_ms: 1715003237027,
        current_return: 1.0003530776757223,
        initial_entry_price: 64016.0,
        is_closed_position: true,
        miner_hotkey: "5Dxqzduahnqw8q3XSUfTcEZGU7xmAsfJubhHZwvXVLN9fSjR",
        net_leverage: 0.0,
        open_ms: 1714999306074,
        orders: [
          {
            leverage: -0.05,
            order_type: "SHORT",
            order_uuid: "bb17f2b5-fee3-4195-a30b-171d9657b73b",
            price: 64016.0,
            price_sources: [],
            processed_ms: 1714999305805,
          },
          {
            leverage: 0.003125,
            order_type: "LONG",
            order_uuid: "5e63c2bd-d2a3-4428-891d-36878a4a9b52",
            price: 63645.4,
            price_sources: [],
            processed_ms: 1714999631275,
          },
          {
            leverage: 0.005859375,
            order_type: "LONG",
            order_uuid: "74529527-ae3d-4d8d-8aa3-72d4ec5cf659",
            price: 63541.81,
            price_sources: [],
            processed_ms: 1714999934369,
          },
          {
            leverage: 0.01025390625,
            order_type: "LONG",
            order_uuid: "b1c8fbda-af99-44e9-9555-2af8b00e78df",
            price: 63507.57,
            price_sources: [],
            processed_ms: 1715000176128,
          },
          {
            leverage: 0.0076904296875,
            order_type: "LONG",
            order_uuid: "f533d4d3-4388-403b-9d74-fe561e858e08",
            price: 63469.85,
            price_sources: [],
            processed_ms: 1715000486307,
          },
          {
            leverage: 0.005767822265625,
            order_type: "LONG",
            order_uuid: "a428aee3-d91f-420d-91a1-5bd086911271",
            price: 63607.6,
            price_sources: [],
            processed_ms: 1715000789984,
          },
          {
            leverage: 0.00432586669921875,
            order_type: "LONG",
            order_uuid: "4c76cc21-2448-4beb-bf49-a0e990ec8ce5",
            price: 63652.23,
            price_sources: [],
            processed_ms: 1715001093903,
          },
          {
            leverage: 0.0032444000244140625,
            order_type: "LONG",
            order_uuid: "56a76b8b-3a8b-4a95-b036-0f2f5640a9ce",
            price: 63492.24,
            price_sources: [],
            processed_ms: 1715001398972,
          },
          {
            leverage: 0.002433300018310547,
            order_type: "LONG",
            order_uuid: "7e13e72d-bfd2-405c-a8c3-61db2836201d",
            price: 63619.89,
            price_sources: [],
            processed_ms: 1715001702964,
          },
          {
            leverage: 0.0018249750137329102,
            order_type: "LONG",
            order_uuid: "a2b9a98e-3781-4728-b895-01655fcff5aa",
            price: 63482.05,
            price_sources: [],
            processed_ms: 1715002031960,
          },
          {
            leverage: 0.0013687312602996826,
            order_type: "LONG",
            order_uuid: "4beb6bec-6707-422a-a489-2df88af61b50",
            price: 63608.5,
            price_sources: [],
            processed_ms: 1715002299954,
          },
          {
            leverage: 0.001026548445224762,
            order_type: "LONG",
            order_uuid: "0a4f3a22-7fb9-4ece-ba1f-a106acc7160d",
            price: 63571.07,
            price_sources: [],
            processed_ms: 1715002612298,
          },
          {
            leverage: 0.001,
            order_type: "LONG",
            order_uuid: "f478d8d4-9512-427e-8384-16ab1ba1e052",
            price: 63787.11,
            price_sources: [],
            processed_ms: 1715002925101,
          },
          {
            leverage: 0.025269911333918572,
            order_type: "LONG",
            order_uuid: "3f280d76-4a7b-41e6-a7e8-f664f6d0682a",
            price: 63803.3,
            price_sources: [],
            processed_ms: 1715003237027,
          },
        ],
        position_type: "FLAT",
        position_uuid: "fe93f261-a020-4517-b0f8-8a580bc8887d",
        return_at_close: 1.0003030600218386,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 68829.78872367145,
        close_ms: 1715029237506,
        current_return: 1.0013826098571368,
        initial_entry_price: 63919.34,
        is_closed_position: true,
        miner_hotkey: "5Dxqzduahnqw8q3XSUfTcEZGU7xmAsfJubhHZwvXVLN9fSjR",
        net_leverage: 0.0,
        open_ms: 1715004021906,
        orders: [
          {
            leverage: -0.05,
            order_type: "SHORT",
            order_uuid: "4afd0e00-f74d-4115-9145-069920365b28",
            price: 63919.34,
            price_sources: [],
            processed_ms: 1715004021013,
          },
          {
            leverage: -0.05,
            order_type: "SHORT",
            order_uuid: "84c14a60-03eb-4621-ab93-d54ba39c87f8",
            price: 64012.34,
            price_sources: [],
            processed_ms: 1715004089455,
          },
          {
            leverage: -0.05,
            order_type: "SHORT",
            order_uuid: "2b334bed-2ebc-4f21-8eb8-31f30dae9d8b",
            price: 64148.0,
            price_sources: [],
            processed_ms: 1715004595660,
          },
          {
            leverage: -0.05,
            order_type: "SHORT",
            order_uuid: "28efae6a-a980-489f-aac8-7bbfb100feb0",
            price: 64096.69,
            price_sources: [],
            processed_ms: 1715004872648,
          },
          {
            leverage: -0.05,
            order_type: "SHORT",
            order_uuid: "b90d0263-97f1-4a22-ab33-678bc63e6d4b",
            price: 64172.4,
            price_sources: [],
            processed_ms: 1715005324715,
          },
          {
            leverage: 0.125,
            order_type: "LONG",
            order_uuid: "3d3f947f-06fe-4d34-9af5-3c9bbca4e486",
            price: 63967.65,
            price_sources: [],
            processed_ms: 1715005648054,
          },
          {
            leverage: 0.015625,
            order_type: "LONG",
            order_uuid: "49182b91-9f19-4406-8e57-61b7c5915c1b",
            price: 63901.83,
            price_sources: [],
            processed_ms: 1715005898165,
          },
          {
            leverage: 0.0068359375,
            order_type: "LONG",
            order_uuid: "0dc3f219-d921-4e66-830e-6f0bd8313d8d",
            price: 63900.52,
            price_sources: [],
            processed_ms: 1715006205445,
          },
          {
            leverage: 0.00640869140625,
            order_type: "LONG",
            order_uuid: "f7c95837-5530-4de4-9383-16b174c30337",
            price: 63956.82,
            price_sources: [],
            processed_ms: 1715006522115,
          },
          {
            leverage: 0.006008148193359375,
            order_type: "LONG",
            order_uuid: "ba05d301-de6a-446f-8ed8-443e1fdcb742",
            price: 63727.7,
            price_sources: [],
            processed_ms: 1715006824404,
          },
          {
            leverage: 0.005632638931274414,
            order_type: "LONG",
            order_uuid: "9c4689ec-5933-4fb0-9c54-73358e15d8fe",
            price: 63599.05,
            price_sources: [],
            processed_ms: 1715007130011,
          },
          {
            leverage: 0.005280598998069763,
            order_type: "LONG",
            order_uuid: "be58fd37-769f-481e-bbfd-f49efc5411b3",
            price: 63531.0,
            price_sources: [],
            processed_ms: 1715007434501,
          },
          {
            leverage: 0.004950561560690403,
            order_type: "LONG",
            order_uuid: "69d2caa1-29c8-4f8e-8904-84bf66feec93",
            price: 63028.15,
            price_sources: [],
            processed_ms: 1715007737987,
          },
          {
            leverage: 0.009282302926294506,
            order_type: "LONG",
            order_uuid: "1dce8706-a921-4c91-abd5-aa15028be2a6",
            price: 63044.02,
            price_sources: [],
            processed_ms: 1715008040048,
          },
          {
            leverage: 0.008122015060507692,
            order_type: "LONG",
            order_uuid: "97bd45bd-bf04-43be-8369-3ede4b24b645",
            price: 63095.74,
            price_sources: [],
            processed_ms: 1715008344212,
          },
          {
            leverage: 0.007106763177944231,
            order_type: "LONG",
            order_uuid: "dd2e5fc1-ce10-4f0a-a1b0-1ed5d14b4f3d",
            price: 63301.2,
            price_sources: [],
            processed_ms: 1715008651014,
          },
          {
            leverage: 0.012436835561402404,
            order_type: "LONG",
            order_uuid: "b80f11d6-82da-4138-933a-7348acc978a9",
            price: 63200.53,
            price_sources: [],
            processed_ms: 1715008897235,
          },
          {
            leverage: 0.009327626671051803,
            order_type: "LONG",
            order_uuid: "9ea91bae-27ae-446b-b77f-f0a43709e02f",
            price: 63269.4,
            price_sources: [],
            processed_ms: 1715009202931,
          },
          {
            leverage: 0.006995720003288852,
            order_type: "LONG",
            order_uuid: "fb5c076e-3d60-4a1c-ac5b-eadec0c48158",
            price: 63368.93,
            price_sources: [],
            processed_ms: 1715009511883,
          },
          {
            leverage: 0.005246790002466639,
            order_type: "LONG",
            order_uuid: "73d78490-c1a1-415f-b9c8-9e4ed1ab1f2c",
            price: 63364.07,
            price_sources: [],
            processed_ms: 1715009827285,
          },
          {
            leverage: 0.003935092501849979,
            order_type: "LONG",
            order_uuid: "f37430d3-dd5d-44e3-948d-f137ecb293a1",
            price: 63503.98,
            price_sources: [],
            processed_ms: 1715010132156,
          },
          {
            leverage: 0.0029513193763874845,
            order_type: "LONG",
            order_uuid: "301c2078-b9b5-43a3-a586-103649728e6a",
            price: 63631.13,
            price_sources: [],
            processed_ms: 1715010432964,
          },
          {
            leverage: 0.0022134895322906134,
            order_type: "LONG",
            order_uuid: "c74e43db-3b26-478d-8978-7257656392f3",
            price: 63564.65,
            price_sources: [],
            processed_ms: 1715010748085,
          },
          {
            leverage: 0.00332023429843592,
            order_type: "LONG",
            order_uuid: "3d1240c7-3ca7-40bb-83a6-71aa085f5233",
            price: 63663.0,
            price_sources: [],
            processed_ms: 1715011009063,
          },
          {
            leverage: 0.001,
            order_type: "LONG",
            order_uuid: "46c360c3-99d8-4f49-8301-9d7f9d0a37a7",
            price: 63580.95,
            price_sources: [],
            processed_ms: 1715011346099,
          },
          {
            leverage: -0.05,
            order_type: "SHORT",
            order_uuid: "00ee92bc-f5b9-40ac-bc7c-24d6d0b8558e",
            price: 63631.03,
            price_sources: [],
            processed_ms: 1715011692549,
          },
          {
            leverage: 0.003270014643652245,
            order_type: "LONG",
            order_uuid: "01621640-d4f5-4a01-b4c1-2b40879f1191",
            price: 63611.99,
            price_sources: [],
            processed_ms: 1715011937170,
          },
          {
            leverage: 0.00306563872842398,
            order_type: "LONG",
            order_uuid: "fc5dfb4b-b73c-487d-bc2f-d6bcf757907d",
            price: 63610.11,
            price_sources: [],
            processed_ms: 1715012243850,
          },
          {
            leverage: 0.005748072615794962,
            order_type: "LONG",
            order_uuid: "eb9630ce-5b46-4a42-9dd8-654853f6e39b",
            price: 63489.92,
            price_sources: [],
            processed_ms: 1715012548958,
          },
          {
            leverage: 0.005029563538820591,
            order_type: "LONG",
            order_uuid: "c8bbb317-8ff9-41f1-860b-03ca5195d812",
            price: 63575.45,
            price_sources: [],
            processed_ms: 1715012857239,
          },
          {
            leverage: 0.004400868096468017,
            order_type: "LONG",
            order_uuid: "b399bf79-ac24-4d3a-98ac-8b80137e76b2",
            price: 63500.8,
            price_sources: [],
            processed_ms: 1715013159858,
          },
          {
            leverage: 0.003850759584409515,
            order_type: "LONG",
            order_uuid: "c1f578f2-b1f8-4a93-849a-0decc5a248b0",
            price: 63525.55,
            price_sources: [],
            processed_ms: 1715013463104,
          },
          {
            leverage: 0.006738829272716651,
            order_type: "LONG",
            order_uuid: "091d5911-d843-44f7-b7ab-2e742d1d931c",
            price: 63449.97,
            price_sources: [],
            processed_ms: 1715013711143,
          },
          {
            leverage: 0.005054121954537489,
            order_type: "LONG",
            order_uuid: "58c294d2-3c6f-439b-bacf-26be1970273f",
            price: 63455.64,
            price_sources: [],
            processed_ms: 1715014021210,
          },
          {
            leverage: 0.0037905914659031167,
            order_type: "LONG",
            order_uuid: "be9046d2-0d3c-41d7-8c8f-b85528da0708",
            price: 63286.44,
            price_sources: [],
            processed_ms: 1715014325236,
          },
          {
            leverage: 0.0028429435994273373,
            order_type: "LONG",
            order_uuid: "2aa4da0e-3a92-4850-9909-08aa1f237ae2",
            price: 63198.09,
            price_sources: [],
            processed_ms: 1715014624962,
          },
          {
            leverage: 0.0021322076995705028,
            order_type: "LONG",
            order_uuid: "bac890e5-afcc-4740-a08e-ad6a4334e77d",
            price: 63335.4,
            price_sources: [],
            processed_ms: 1715014963329,
          },
          {
            leverage: 0.001599155774677877,
            order_type: "LONG",
            order_uuid: "599bcb59-f784-4b56-9229-b34116294a2e",
            price: 63385.27,
            price_sources: [],
            processed_ms: 1715015228094,
          },
          {
            leverage: 0.0011993668310084078,
            order_type: "LONG",
            order_uuid: "8efde1e0-9153-4b8b-913b-68302e86b179",
            price: 63398.61,
            price_sources: [],
            processed_ms: 1715015543953,
          },
          {
            leverage: 0.001,
            order_type: "LONG",
            order_uuid: "9b9dcc17-89e4-4c82-9b68-336c2efa31de",
            price: 63444.62,
            price_sources: [],
            processed_ms: 1715015817220,
          },
          {
            leverage: 0.001,
            order_type: "LONG",
            order_uuid: "f0987901-22cb-451d-98a4-bee211cfde56",
            price: 63363.3,
            price_sources: [],
            processed_ms: 1715016152293,
          },
          {
            leverage: -0.05,
            order_type: "SHORT",
            order_uuid: "43ad918f-b122-4481-b4e4-aa2a751479ee",
            price: 63450.0,
            price_sources: [],
            processed_ms: 1715016439889,
          },
          {
            leverage: 0.0032248812808140766,
            order_type: "LONG",
            order_uuid: "55057d07-220a-4e0c-9d06-c33fff2e33aa",
            price: 63134.17,
            price_sources: [],
            processed_ms: 1715016744183,
          },
          {
            leverage: 0.0030233262007631967,
            order_type: "LONG",
            order_uuid: "6d759219-6ac8-41ec-af23-cd9c2765fd41",
            price: 63141.0,
            price_sources: [],
            processed_ms: 1715017051285,
          },
          {
            leverage: 0.002834368313215497,
            order_type: "LONG",
            order_uuid: "fc5fc10f-2b99-4d86-9cf9-478d48396595",
            price: 63003.14,
            price_sources: [],
            processed_ms: 1715017356062,
          },
          {
            leverage: 0.005314440587279056,
            order_type: "LONG",
            order_uuid: "664a9e50-0cdb-4a70-8548-e4d7cf0328bf",
            price: 63078.9,
            price_sources: [],
            processed_ms: 1715017658338,
          },
          {
            leverage: 0.03430027102773835,
            order_type: "LONG",
            order_uuid: "0bdf7245-28ef-4ab7-bd2b-ac4768af7f17",
            price: 63190.61,
            price_sources: [],
            processed_ms: 1715017964181,
          },
          {
            leverage: -0.05,
            order_type: "SHORT",
            order_uuid: "b801fc94-bf98-43f7-b589-b1b822620bcf",
            price: 63230.0,
            price_sources: [],
            processed_ms: 1715018326692,
          },
          {
            leverage: 0.019112601635401884,
            order_type: "LONG",
            order_uuid: "dc565eaa-9692-49c4-b3c0-0c40ff6746e3",
            price: 63202.7,
            price_sources: [],
            processed_ms: 1715018570061,
          },
          {
            leverage: 0.002111763215488323,
            order_type: "LONG",
            order_uuid: "60905127-43f5-48ac-a53b-0eb2682accad",
            price: 63166.08,
            price_sources: [],
            processed_ms: 1715018818051,
          },
          {
            leverage: 0.0019797780145203027,
            order_type: "LONG",
            order_uuid: "cc3fb634-f104-4de1-ba90-7ab095f34478",
            price: 63150.01,
            price_sources: [],
            processed_ms: 1715019123898,
          },
          {
            leverage: 0.0018560418886127837,
            order_type: "LONG",
            order_uuid: "89dcbf78-0640-4481-8de3-5ff309de0cc7",
            price: 63271.08,
            price_sources: [],
            processed_ms: 1715019433188,
          },
          {
            leverage: 0.0017400392705744848,
            order_type: "LONG",
            order_uuid: "28447930-bfa5-4a7c-b265-bacc5d19b809",
            price: 63211.02,
            price_sources: [],
            processed_ms: 1715019741082,
          },
          {
            leverage: 0.0016312868161635794,
            order_type: "LONG",
            order_uuid: "eccb7f9d-e8e0-4627-a818-e4b5524ce615",
            price: 63254.89,
            price_sources: [],
            processed_ms: 1715020046356,
          },
          {
            leverage: 0.0015293313901533557,
            order_type: "LONG",
            order_uuid: "4f62207e-f275-477a-ae52-d9fb0fd16879",
            price: 63001.47,
            price_sources: [],
            processed_ms: 1715020355379,
          },
          {
            leverage: 0.001433748178268771,
            order_type: "LONG",
            order_uuid: "823201ac-b5eb-4003-b90f-c29106cc2802",
            price: 63388.25,
            price_sources: [],
            processed_ms: 1715020661412,
          },
          {
            leverage: 0.0013441389171269728,
            order_type: "LONG",
            order_uuid: "805bd1d9-55bf-4272-ba99-7258be6042fb",
            price: 63339.04,
            price_sources: [],
            processed_ms: 1715020970347,
          },
          {
            leverage: 0.001260130234806537,
            order_type: "LONG",
            order_uuid: "491f46d3-82b8-4d75-8be4-49f5500a3a45",
            price: 63465.95,
            price_sources: [],
            processed_ms: 1715021282877,
          },
          {
            leverage: 0.002362744190262257,
            order_type: "LONG",
            order_uuid: "8d982c51-a0f4-46be-90bb-e8978fb07cf3",
            price: 63420.28,
            price_sources: [],
            processed_ms: 1715021534179,
          },
          {
            leverage: 0.0020674011664794747,
            order_type: "LONG",
            order_uuid: "1eee12f5-747d-431d-abaa-802638eabed9",
            price: 63421.3,
            price_sources: [],
            processed_ms: 1715021852057,
          },
          {
            leverage: 0.0018089760206695404,
            order_type: "LONG",
            order_uuid: "ee2259a2-4c7e-46fe-829c-ce84d8c75f37",
            price: 63254.15,
            price_sources: [],
            processed_ms: 1715022161144,
          },
          {
            leverage: 0.0015828540180858478,
            order_type: "LONG",
            order_uuid: "33f691c4-40a5-478f-8bf7-7ad4eb9dfa24",
            price: 63333.71,
            price_sources: [],
            processed_ms: 1715022474836,
          },
          {
            leverage: 0.002769994531650234,
            order_type: "LONG",
            order_uuid: "b5e143af-d458-46a8-a1eb-0c997a100bc7",
            price: 63242.77,
            price_sources: [],
            processed_ms: 1715022733887,
          },
          {
            leverage: 0.0020774958987376754,
            order_type: "LONG",
            order_uuid: "19483196-0105-4e79-af87-af1a84dd4ec1",
            price: 62860.08,
            price_sources: [],
            processed_ms: 1715023047952,
          },
          {
            leverage: 0.001,
            order_type: "LONG",
            order_uuid: "6dd4269e-021b-4819-926a-f59bac2a6c67",
            price: 63051.69,
            price_sources: [],
            processed_ms: 1715023384949,
          },
          {
            leverage: 0.001,
            order_type: "LONG",
            order_uuid: "3b6a3cf5-86d5-4fbf-90dc-99fb7b97a285",
            price: 63023.71,
            price_sources: [],
            processed_ms: 1715023633093,
          },
          {
            leverage: 0.001,
            order_type: "LONG",
            order_uuid: "c5b074be-9dfd-409c-8d80-978ab4664cc8",
            price: 62895.2,
            price_sources: [],
            processed_ms: 1715023933222,
          },
          {
            leverage: -0.05,
            order_type: "SHORT",
            order_uuid: "b1e6df20-a9cf-45a7-938e-cc6ef1b7fd6f",
            price: 62953.9,
            price_sources: [],
            processed_ms: 1715024235429,
          },
          {
            leverage: 0.003327030481013314,
            order_type: "LONG",
            order_uuid: "e56b6e5d-7e4f-4896-b0c6-6536de0735d6",
            price: 63007.0,
            price_sources: [],
            processed_ms: 1715024537869,
          },
          {
            leverage: 0.003119091075949982,
            order_type: "LONG",
            order_uuid: "41c586b4-9393-4ff8-b021-60619051cc3c",
            price: 63109.57,
            price_sources: [],
            processed_ms: 1715024838887,
          },
          {
            leverage: 0.0029241478837031083,
            order_type: "LONG",
            order_uuid: "1b114757-4805-42ed-be84-8d1186ac53e1",
            price: 63117.09,
            price_sources: [],
            processed_ms: 1715025148598,
          },
          {
            leverage: 0.002741388640971664,
            order_type: "LONG",
            order_uuid: "2b954fa1-cab0-4f3d-95f2-c83336e1473a",
            price: 63202.23,
            price_sources: [],
            processed_ms: 1715025453926,
          },
          {
            leverage: 0.005140103701821871,
            order_type: "LONG",
            order_uuid: "7e759bf8-3377-4de0-ba72-4271b2fad670",
            price: 63182.12,
            price_sources: [],
            processed_ms: 1715025757347,
          },
          {
            leverage: 0.004497590739094137,
            order_type: "LONG",
            order_uuid: "9134f772-0336-4619-a34c-07e2138ffcc5",
            price: 63076.8,
            price_sources: [],
            processed_ms: 1715026064046,
          },
          {
            leverage: 0.00393539189670737,
            order_type: "LONG",
            order_uuid: "b704e3d2-d0b8-49ad-b800-0fef05bd8001",
            price: 63146.88,
            price_sources: [],
            processed_ms: 1715026373202,
          },
          {
            leverage: -0.05,
            order_type: "SHORT",
            order_uuid: "0ac9cc0d-467e-4ad6-9126-9c239339ff3e",
            price: 63127.6,
            price_sources: [],
            processed_ms: 1715026682305,
          },
          {
            leverage: 0.004846733954809474,
            order_type: "LONG",
            order_uuid: "959882a3-5a55-4956-b2b2-7224b274019b",
            price: 63323.0,
            price_sources: [],
            processed_ms: 1715026989085,
          },
          {
            leverage: 0.004543813082633882,
            order_type: "LONG",
            order_uuid: "7fe2f948-5e8c-4add-abcf-1232bea7392c",
            price: 63323.95,
            price_sources: [],
            processed_ms: 1715027292177,
          },
          {
            leverage: 0.004259824764969265,
            order_type: "LONG",
            order_uuid: "aa47917b-f6fd-445d-b1b1-4b224b0aa73b",
            price: 63350.0,
            price_sources: [],
            processed_ms: 1715027593157,
          },
          {
            leverage: 0.007987171434317372,
            order_type: "LONG",
            order_uuid: "78b3df9a-2e02-4c95-900f-d84f63a001ab",
            price: 63351.89,
            price_sources: [],
            processed_ms: 1715027844304,
          },
          {
            leverage: 0.0069887750050277,
            order_type: "LONG",
            order_uuid: "af140e35-d564-46b9-9672-3dbf06547f68",
            price: 63360.93,
            price_sources: [],
            processed_ms: 1715028153995,
          },
          {
            leverage: 0.012230356258798473,
            order_type: "LONG",
            order_uuid: "0d4b70be-b475-4fd2-8db6-4a3a190f97c2",
            price: 63301.67,
            price_sources: [],
            processed_ms: 1715028459980,
          },
          {
            leverage: 0.0045863835970494276,
            order_type: "LONG",
            order_uuid: "284e8260-ed65-4e6a-bac1-fb30039d8fe4",
            price: 63314.69,
            price_sources: [],
            processed_ms: 1715028777006,
          },
          {
            leverage: 0.016052342589672995,
            order_type: "LONG",
            order_uuid: "be059f99-9199-41f9-af4d-3bbcab2d4183",
            price: 63298.38,
            price_sources: [],
            processed_ms: 1715028934362,
          },
          {
            leverage: 0.0010032714118545622,
            order_type: "FLAT",
            order_uuid: "fd02129c-c19b-41d9-add4-a4d5be081aa9",
            price: 63324.33,
            price_sources: [],
            processed_ms: 1715029237506,
          },
        ],
        position_type: "FLAT",
        position_uuid: "370e4f01-8fec-464d-bb87-5fb8e836a7ce",
        return_at_close: 1.0008819185522082,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 69409.24839506148,
        close_ms: 1715050382218,
        current_return: 1.0001741559474053,
        initial_entry_price: 63829.22,
        is_closed_position: true,
        miner_hotkey: "5Dxqzduahnqw8q3XSUfTcEZGU7xmAsfJubhHZwvXVLN9fSjR",
        net_leverage: 0.0,
        open_ms: 1715047503609,
        orders: [
          {
            leverage: -0.05,
            order_type: "SHORT",
            order_uuid: "d4926b65-6c8c-46e0-83e3-759cbc3d67ec",
            price: 63829.22,
            price_sources: [],
            processed_ms: 1715047503328,
          },
          {
            leverage: -0.05,
            order_type: "SHORT",
            order_uuid: "7ff60709-da2b-4ab6-809b-fcff4d6fd753",
            price: 63901.0,
            price_sources: [],
            processed_ms: 1715047778428,
          },
          {
            leverage: 0.025,
            order_type: "LONG",
            order_uuid: "d15a102d-6f2e-46dd-9a89-0b9ceb59ee2a",
            price: 63752.2,
            price_sources: [],
            processed_ms: 1715047963988,
          },
          {
            leverage: 0.018750000000000003,
            order_type: "LONG",
            order_uuid: "fd260e62-b9dd-4af9-b585-de813ab66b49",
            price: 63761.68,
            price_sources: [],
            processed_ms: 1715048296069,
          },
          {
            leverage: 0.014062500000000002,
            order_type: "LONG",
            order_uuid: "49fee14f-e628-48d8-afba-20b08d64aff6",
            price: 63768.45,
            price_sources: [],
            processed_ms: 1715048605139,
          },
          {
            leverage: 0.02109375,
            order_type: "LONG",
            order_uuid: "52b616f0-7eea-46b5-9f35-93c8b3acb72d",
            price: 63761.67,
            price_sources: [],
            processed_ms: 1715049105052,
          },
          {
            leverage: 0.010546875,
            order_type: "LONG",
            order_uuid: "981fdef8-ba9e-4f90-ae2b-440b3bb9b75c",
            price: 63748.4,
            price_sources: [],
            processed_ms: 1715049172035,
          },
          {
            leverage: 0.0052734375,
            order_type: "LONG",
            order_uuid: "6efc0cd6-b397-4b75-be8d-dde3081ebebb",
            price: 63697.33,
            price_sources: [],
            processed_ms: 1715049477359,
          },
          {
            leverage: 0.001318359375,
            order_type: "LONG",
            order_uuid: "e0f092e9-8d3b-4775-8ca5-f4776c0d048e",
            price: 63683.18,
            price_sources: [],
            processed_ms: 1715049816871,
          },
          {
            leverage: 0.0019775390625000003,
            order_type: "LONG",
            order_uuid: "b78d4c1e-6252-4355-8adf-3e8211d263f0",
            price: 63710.92,
            price_sources: [],
            processed_ms: 1715050069243,
          },
          {
            leverage: 0.0019775390625000003,
            order_type: "FLAT",
            order_uuid: "b1785036-89c3-4193-87b9-9b732eb48dc6",
            price: 63788.0,
            price_sources: [],
            processed_ms: 1715050382218,
          },
        ],
        position_type: "FLAT",
        position_uuid: "8d8d3c02-3e09-4967-8aa9-ea0c3cab251d",
        return_at_close: 1.0000741385318106,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 75799.36999999984,
        close_ms: 1715057168435,
        current_return: 1.0003005971615122,
        initial_entry_price: 63828.0,
        is_closed_position: true,
        miner_hotkey: "5Dxqzduahnqw8q3XSUfTcEZGU7xmAsfJubhHZwvXVLN9fSjR",
        net_leverage: 0.0,
        open_ms: 1715051425348,
        orders: [
          {
            leverage: -0.05,
            order_type: "SHORT",
            order_uuid: "3e496011-fb82-4367-8dff-d6fd6f38546c",
            price: 63828.0,
            price_sources: [],
            processed_ms: 1715051425116,
          },
          {
            leverage: -0.05,
            order_type: "SHORT",
            order_uuid: "31595dae-4c60-4ab5-ba22-0ae4c320dd8c",
            price: 63699.9,
            price_sources: [],
            processed_ms: 1715051606579,
          },
          {
            leverage: 0.05,
            order_type: "LONG",
            order_uuid: "74e902b0-75e3-4671-a7bc-ba71f437229e",
            price: 63659.33,
            price_sources: [],
            processed_ms: 1715051990134,
          },
          {
            leverage: 0.025,
            order_type: "LONG",
            order_uuid: "fa8c7163-bb35-4da5-a352-75c963de19e6",
            price: 63584.0,
            price_sources: [],
            processed_ms: 1715052189171,
          },
          {
            leverage: 0.0125,
            order_type: "LONG",
            order_uuid: "971596be-5d92-42fd-95d5-253f879828d1",
            price: 63394.9,
            price_sources: [],
            processed_ms: 1715055984173,
          },
          {
            leverage: 0.00625,
            order_type: "LONG",
            order_uuid: "374eceb4-2a81-4196-8799-44d9711a7405",
            price: 63360.6,
            price_sources: [],
            processed_ms: 1715056270901,
          },
          {
            leverage: 0.003125,
            order_type: "LONG",
            order_uuid: "d6e13f8f-6186-47bd-8383-343982af8fa4",
            price: 63353.64,
            price_sources: [],
            processed_ms: 1715056575193,
          },
          {
            leverage: 0.0015625,
            order_type: "LONG",
            order_uuid: "999242d9-3168-42c2-a6f5-b0b7664829d0",
            price: 63341.99,
            price_sources: [],
            processed_ms: 1715056631273,
          },
          {
            leverage: 0.0015625,
            order_type: "FLAT",
            order_uuid: "37e9e153-9dfd-4a2d-a0af-1100515ef394",
            price: 63520.0,
            price_sources: [],
            processed_ms: 1715057168435,
          },
        ],
        position_type: "FLAT",
        position_uuid: "7d65c9db-1e0a-4445-99e2-3d26f3afbb0a",
        return_at_close: 1.0002005671017962,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 63568.49999999999,
        close_ms: 1715058883949,
        current_return: 1.0001041748751005,
        initial_entry_price: 63451.0,
        is_closed_position: true,
        miner_hotkey: "5Dxqzduahnqw8q3XSUfTcEZGU7xmAsfJubhHZwvXVLN9fSjR",
        net_leverage: 0.0,
        open_ms: 1715058185697,
        orders: [
          {
            leverage: -0.05,
            order_type: "SHORT",
            order_uuid: "6bacd78d-8e4d-43fc-aa99-c277ebee5889",
            price: 63451.0,
            price_sources: [],
            processed_ms: 1715058185481,
          },
          {
            leverage: -0.05,
            order_type: "SHORT",
            order_uuid: "7b01a597-519a-480f-9903-351a727aab9a",
            price: 63529.0,
            price_sources: [],
            processed_ms: 1715058576699,
          },
          {
            leverage: 0.05,
            order_type: "LONG",
            order_uuid: "2168dc9a-c17f-4be7-be3c-4677f53e34f7",
            price: 63411.5,
            price_sources: [],
            processed_ms: 1715058727342,
          },
          {
            leverage: 0.05,
            order_type: "LONG",
            order_uuid: "c20a4573-408a-4e80-ad5b-0474035a21ac",
            price: 63436.3,
            price_sources: [],
            processed_ms: 1715058883949,
          },
        ],
        position_type: "FLAT",
        position_uuid: "0fe00bfe-8fdd-4c4d-a348-cc638f5bd4e3",
        return_at_close: 1.000004164457613,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 63192.6125,
        close_ms: 1715060919176,
        current_return: 1.0005730933766042,
        initial_entry_price: 63383.65,
        is_closed_position: true,
        miner_hotkey: "5Dxqzduahnqw8q3XSUfTcEZGU7xmAsfJubhHZwvXVLN9fSjR",
        net_leverage: 0.0,
        open_ms: 1715058906761,
        orders: [
          {
            leverage: 0.05,
            order_type: "LONG",
            order_uuid: "f9aef93c-568a-4558-8237-28e9c0b67e95",
            price: 63383.65,
            price_sources: [],
            processed_ms: 1715058905882,
          },
          {
            leverage: 0.025,
            order_type: "LONG",
            order_uuid: "cebe997e-7410-4f22-9161-2fe5777baa37",
            price: 63427.55,
            price_sources: [],
            processed_ms: 1715059319094,
          },
          {
            leverage: 0.037500000000000006,
            order_type: "LONG",
            order_uuid: "4853939c-72d1-4439-b874-0b1319f4b578",
            price: 63404.4,
            price_sources: [],
            processed_ms: 1715059603118,
          },
          {
            leverage: 0.037500000000000006,
            order_type: "LONG",
            order_uuid: "e197b9da-9681-4e6a-9147-9aabccb0f815",
            price: 63390.0,
            price_sources: [],
            processed_ms: 1715059632016,
          },
          {
            leverage: -0.05,
            order_type: "SHORT",
            order_uuid: "60905fb7-a0d0-4c80-9a9b-85dc0597147d",
            price: 63808.0,
            price_sources: [],
            processed_ms: 1715060592688,
          },
          {
            leverage: 0.4,
            order_type: "FLAT",
            order_uuid: "ad2619c9-2fec-458d-a518-5c89c6b93f1d",
            price: 63555.86,
            price_sources: [],
            processed_ms: 1715060919176,
          },
        ],
        position_type: "FLAT",
        position_uuid: "6b7b178a-03f8-4cee-8b05-514ac7c3336a",
        return_at_close: 1.0004230074125977,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 64166.08111111104,
        close_ms: 1715062417859,
        current_return: 1.0002935093131091,
        initial_entry_price: 63620.0,
        is_closed_position: true,
        miner_hotkey: "5Dxqzduahnqw8q3XSUfTcEZGU7xmAsfJubhHZwvXVLN9fSjR",
        net_leverage: 0.0,
        open_ms: 1715061486116,
        orders: [
          {
            leverage: -0.05,
            order_type: "SHORT",
            order_uuid: "907dca88-ae38-408c-883c-d690fdb3cb7b",
            price: 63620.0,
            price_sources: [],
            processed_ms: 1715061485359,
          },
          {
            leverage: -0.05,
            order_type: "SHORT",
            order_uuid: "98a6fb24-b89c-4779-be65-299bde6ec175",
            price: 63711.0,
            price_sources: [],
            processed_ms: 1715061849793,
          },
          {
            leverage: -0.05,
            order_type: "SHORT",
            order_uuid: "e3bcb95c-a4ef-4325-acef-870973eb0cfc",
            price: 63593.03,
            price_sources: [],
            processed_ms: 1715061936220,
          },
          {
            leverage: 0.07500000000000001,
            order_type: "LONG",
            order_uuid: "62362205-db60-41b1-bc94-e428dbdb5dbc",
            price: 63549.03,
            price_sources: [],
            processed_ms: 1715062112268,
          },
          {
            leverage: 0.018750000000000003,
            order_type: "LONG",
            order_uuid: "e5905572-7f93-4db2-aa64-dfe9909a177c",
            price: 63455.86,
            price_sources: [],
            processed_ms: 1715062294928,
          },
          {
            leverage: 0.028125000000000004,
            order_type: "LONG",
            order_uuid: "2d3ea26b-aa7d-4314-8357-872e1f260e35",
            price: 63486.43,
            price_sources: [],
            processed_ms: 1715062356921,
          },
          {
            leverage: 0.028125000000000004,
            order_type: "LONG",
            order_uuid: "23ce18ef-12ab-4837-b7f3-f49007911b40",
            price: 63502.15,
            price_sources: [],
            processed_ms: 1715062417859,
          },
        ],
        position_type: "FLAT",
        position_uuid: "b519e02a-96af-435b-8432-3ba4a08653f7",
        return_at_close: 1.0001434652867123,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 64229.76,
        close_ms: 1715077186847,
        current_return: 1.0009132101020621,
        initial_entry_price: 63540.69,
        is_closed_position: true,
        miner_hotkey: "5Dxqzduahnqw8q3XSUfTcEZGU7xmAsfJubhHZwvXVLN9fSjR",
        net_leverage: 0.0,
        open_ms: 1715063191576,
        orders: [
          {
            leverage: -0.05,
            order_type: "SHORT",
            order_uuid: "5d121870-47d0-4fb9-a8bd-4cb2611fb49a",
            price: 63540.69,
            price_sources: [],
            processed_ms: 1715063191313,
          },
          {
            leverage: -0.05,
            order_type: "SHORT",
            order_uuid: "fcfe2b5c-6a61-47a4-ba59-886cbe8530f7",
            price: 63647.0,
            price_sources: [],
            processed_ms: 1715063258415,
          },
          {
            leverage: -0.05,
            order_type: "SHORT",
            order_uuid: "b4aaf12b-5dd8-450d-9231-89641f0ed46c",
            price: 63608.0,
            price_sources: [],
            processed_ms: 1715063352729,
          },
          {
            leverage: 0.037500000000000006,
            order_type: "LONG",
            order_uuid: "8f5c6f69-c7c3-46c7-bfb3-625fc940710d",
            price: 63484.76,
            price_sources: [],
            processed_ms: 1715063513344,
          },
          {
            leverage: 0.037500000000000006,
            order_type: "LONG",
            order_uuid: "e6e468d1-bb37-4ebb-a21d-8178062d9cd1",
            price: 63471.25,
            price_sources: [],
            processed_ms: 1715063575161,
          },
          {
            leverage: 0.018750000000000003,
            order_type: "LONG",
            order_uuid: "0bb44f3f-2217-4073-b392-16ba6dee875e",
            price: 63470.97,
            price_sources: [],
            processed_ms: 1715063698089,
          },
          {
            leverage: -0.05,
            order_type: "SHORT",
            order_uuid: "3ee67442-3a41-4656-b648-ef8f29456c8c",
            price: 63914.0,
            price_sources: [],
            processed_ms: 1715067088226,
          },
          {
            leverage: -0.05,
            order_type: "SHORT",
            order_uuid: "27273c10-528b-4d3d-907b-c998e8961d75",
            price: 63905.0,
            price_sources: [],
            processed_ms: 1715069828152,
          },
          {
            leverage: -0.05,
            order_type: "SHORT",
            order_uuid: "d0da05a1-4a92-4e72-8af8-d9848584a1f9",
            price: 64228.0,
            price_sources: [],
            processed_ms: 1715070069327,
          },
          {
            leverage: -0.05,
            order_type: "SHORT",
            order_uuid: "9e7f4e1e-ba55-4b0c-8cc6-28d35221cd50",
            price: 64254.0,
            price_sources: [],
            processed_ms: 1715070153762,
          },
          {
            leverage: -0.05,
            order_type: "SHORT",
            order_uuid: "322c2397-6822-4d27-b33b-ea4b4d425e01",
            price: 64282.42,
            price_sources: [],
            processed_ms: 1715070241126,
          },
          {
            leverage: -0.05,
            order_type: "SHORT",
            order_uuid: "bf16834e-aea5-45a2-af5e-0e40c53b83cc",
            price: 64148.45,
            price_sources: [],
            processed_ms: 1715070331220,
          },
          {
            leverage: 0.17812499999999998,
            order_type: "LONG",
            order_uuid: "173dbdbb-0a9e-40ad-8969-df4450e6e223",
            price: 63913.1,
            price_sources: [],
            processed_ms: 1715077122194,
          },
          {
            leverage: 0.17812499999999998,
            order_type: "LONG",
            order_uuid: "30c1510c-b4f7-40f6-9c3a-129feb53ef0b",
            price: 63904.0,
            price_sources: [],
            processed_ms: 1715077186847,
          },
        ],
        position_type: "FLAT",
        position_uuid: "b7a9db4b-1a8c-425d-95f6-bcd6bb53d44a",
        return_at_close: 1.0004627991575163,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 62018.34111111106,
        close_ms: 1715094944259,
        current_return: 1.0008672665594052,
        initial_entry_price: 63831.55,
        is_closed_position: true,
        miner_hotkey: "5Dxqzduahnqw8q3XSUfTcEZGU7xmAsfJubhHZwvXVLN9fSjR",
        net_leverage: 0.0,
        open_ms: 1715077261516,
        orders: [
          {
            leverage: 0.17812499999999998,
            order_type: "LONG",
            order_uuid: "96ef7214-8220-4382-a36a-6971c5d5ac79",
            price: 63831.55,
            price_sources: [],
            processed_ms: 1715077261209,
          },
          {
            leverage: -0.05,
            order_type: "SHORT",
            order_uuid: "7a563027-2f55-45a7-a437-e0fcd2832209",
            price: 64202.58,
            price_sources: [],
            processed_ms: 1715094114501,
          },
          {
            leverage: -0.05,
            order_type: "SHORT",
            order_uuid: "77546fe5-2848-4525-9211-1c75fb0e9b73",
            price: 64238.4,
            price_sources: [],
            processed_ms: 1715094185650,
          },
          {
            leverage: -0.05,
            order_type: "SHORT",
            order_uuid: "6b7c081d-b327-4bfa-bde7-aa910dbd4c88",
            price: 64073.6,
            price_sources: [],
            processed_ms: 1715094444044,
          },
          {
            leverage: -0.05,
            order_type: "SHORT",
            order_uuid: "9a28e7ef-2d23-458a-8229-c4044a45a6df",
            price: 63986.66,
            price_sources: [],
            processed_ms: 1715094944259,
          },
        ],
        position_type: "FLAT",
        position_uuid: "9a3e6329-2427-4f88-bbc0-cfc9122b39a4",
        return_at_close: 1.0006889870775493,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 63984.08666666666,
        close_ms: 1715095996892,
        current_return: 1.000290438712662,
        initial_entry_price: 63895.58,
        is_closed_position: true,
        miner_hotkey: "5Dxqzduahnqw8q3XSUfTcEZGU7xmAsfJubhHZwvXVLN9fSjR",
        net_leverage: 0.0,
        open_ms: 1715095325152,
        orders: [
          {
            leverage: -0.05,
            order_type: "SHORT",
            order_uuid: "1dd1c4a7-f9db-4c0a-84a3-88a784eb708b",
            price: 63895.58,
            price_sources: [],
            processed_ms: 1715095324887,
          },
          {
            leverage: -0.05,
            order_type: "SHORT",
            order_uuid: "5dca2089-5556-401d-806d-455dfb097327",
            price: 63895.0,
            price_sources: [],
            processed_ms: 1715095392332,
          },
          {
            leverage: -0.05,
            order_type: "SHORT",
            order_uuid: "1a2b3fec-56c2-4ac5-88d6-761013f9d044",
            price: 63876.1,
            price_sources: [],
            processed_ms: 1715095702718,
          },
          {
            leverage: 0.07500000000000001,
            order_type: "LONG",
            order_uuid: "87fc3975-b754-4334-956a-5eec4301da9a",
            price: 63793.7,
            price_sources: [],
            processed_ms: 1715095933938,
          },
          {
            leverage: 0.07500000000000001,
            order_type: "LONG",
            order_uuid: "959766fb-cb02-4b04-96af-da0a72c1d3de",
            price: 63736.65,
            price_sources: [],
            processed_ms: 1715095996892,
          },
        ],
        position_type: "FLAT",
        position_uuid: "db2cf6ec-feab-49da-af73-3ba3990ad005",
        return_at_close: 1.0001403951468553,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 64112.99999999999,
        close_ms: 1715099499344,
        current_return: 1.0002263789573398,
        initial_entry_price: 63782.43,
        is_closed_position: true,
        miner_hotkey: "5Dxqzduahnqw8q3XSUfTcEZGU7xmAsfJubhHZwvXVLN9fSjR",
        net_leverage: 0.0,
        open_ms: 1715096329205,
        orders: [
          {
            leverage: -0.05,
            order_type: "SHORT",
            order_uuid: "da545b56-4736-4fd6-918c-2f07c217157a",
            price: 63782.43,
            price_sources: [],
            processed_ms: 1715096328885,
          },
          {
            leverage: -0.05,
            order_type: "SHORT",
            order_uuid: "af3c1c7b-e4a0-45f0-912f-4cf74f1f37ab",
            price: 63773.2,
            price_sources: [],
            processed_ms: 1715097430726,
          },
          {
            leverage: 0.05,
            order_type: "LONG",
            order_uuid: "7855e324-47b5-45e6-96c9-43832c6d5f4d",
            price: 63681.54,
            price_sources: [],
            processed_ms: 1715099254151,
          },
          {
            leverage: 0.025,
            order_type: "LONG",
            order_uuid: "c1c980e0-1f26-4dc4-90cb-040485e9a8e5",
            price: 63635.18,
            price_sources: [],
            processed_ms: 1715099439356,
          },
          {
            leverage: 0.025,
            order_type: "LONG",
            order_uuid: "7e3f0758-02fc-49c4-9493-6926606b57b4",
            price: 63535.44,
            price_sources: [],
            processed_ms: 1715099499344,
          },
        ],
        position_type: "FLAT",
        position_uuid: "ee44b2df-c546-4f21-86c5-62b98726e620",
        return_at_close: 1.000126356319444,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 63561.4,
        close_ms: 1715099867537,
        current_return: 1.0000102774639954,
        initial_entry_price: 63561.4,
        is_closed_position: true,
        miner_hotkey: "5Dxqzduahnqw8q3XSUfTcEZGU7xmAsfJubhHZwvXVLN9fSjR",
        net_leverage: 0.0,
        open_ms: 1715099562131,
        orders: [
          {
            leverage: 0.0125,
            order_type: "LONG",
            order_uuid: "44979e63-8c62-42a7-8114-a6db2bd23394",
            price: 63561.4,
            price_sources: [],
            processed_ms: 1715099561895,
          },
          {
            leverage: -0.05,
            order_type: "SHORT",
            order_uuid: "4fcac53a-d169-41fe-870c-27a36f3789d6",
            price: 63613.66,
            price_sources: [],
            processed_ms: 1715099867537,
          },
        ],
        position_type: "FLAT",
        position_uuid: "fe8ac2c7-d847-4df3-9a7a-8c4238fb0da9",
        return_at_close: 0.9999977773355271,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 63737.29999999999,
        close_ms: 1715101487266,
        current_return: 1.0002982292747336,
        initial_entry_price: 63618.0,
        is_closed_position: true,
        miner_hotkey: "5Dxqzduahnqw8q3XSUfTcEZGU7xmAsfJubhHZwvXVLN9fSjR",
        net_leverage: 0.0,
        open_ms: 1715100524640,
        orders: [
          {
            leverage: -0.05,
            order_type: "SHORT",
            order_uuid: "54de9ea6-80ce-4788-b748-451611782a41",
            price: 63618.0,
            price_sources: [],
            processed_ms: 1715100524386,
          },
          {
            leverage: -0.05,
            order_type: "SHORT",
            order_uuid: "ae802423-7614-4068-a7c1-83f1ba5ad56b",
            price: 63581.5,
            price_sources: [],
            processed_ms: 1715101161391,
          },
          {
            leverage: -0.05,
            order_type: "SHORT",
            order_uuid: "9ca09128-d149-40cd-a303-09a5a88f09ac",
            price: 63668.0,
            price_sources: [],
            processed_ms: 1715101365438,
          },
          {
            leverage: 0.07500000000000001,
            order_type: "LONG",
            order_uuid: "5de3ab1b-0bb6-4ad7-a604-9e71d3c84f04",
            price: 63507.7,
            price_sources: [],
            processed_ms: 1715101426005,
          },
          {
            leverage: 0.07500000000000001,
            order_type: "LONG",
            order_uuid: "e0d83832-0ab9-49f3-bc97-4bd248b7920b",
            price: 63484.33,
            price_sources: [],
            processed_ms: 1715101487266,
          },
        ],
        position_type: "FLAT",
        position_uuid: "36375f2d-9b05-4fae-81e5-b487a40a4870",
        return_at_close: 1.0001481845403424,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 63825.80199999998,
        close_ms: 1715105790174,
        current_return: 1.000236270195436,
        initial_entry_price: 63390.56,
        is_closed_position: true,
        miner_hotkey: "5Dxqzduahnqw8q3XSUfTcEZGU7xmAsfJubhHZwvXVLN9fSjR",
        net_leverage: 0.0,
        open_ms: 1715104153771,
        orders: [
          {
            leverage: -0.08,
            order_type: "SHORT",
            order_uuid: "c77ecd4e-1ec8-4336-98d4-b9367b218b3a",
            price: 63390.56,
            price_sources: [],
            processed_ms: 1715104153450,
          },
          {
            leverage: 0.04,
            order_type: "LONG",
            order_uuid: "b37550ac-797f-4bcd-af04-974a53cc095c",
            price: 63289.7,
            price_sources: [],
            processed_ms: 1715105034981,
          },
          {
            leverage: -0.08,
            order_type: "SHORT",
            order_uuid: "b2e4ef92-e421-4cfa-ad30-3812016b354c",
            price: 63315.0,
            price_sources: [],
            processed_ms: 1715105157922,
          },
          {
            leverage: 0.02,
            order_type: "LONG",
            order_uuid: "417b3f78-1e50-4cca-ae93-4e9331436934",
            price: 63280.9,
            price_sources: [],
            processed_ms: 1715105277935,
          },
          {
            leverage: 0.049999999999999996,
            order_type: "LONG",
            order_uuid: "9e1d90c7-7a41-451b-bcf6-a616f4689ee8",
            price: 63255.96,
            price_sources: [],
            processed_ms: 1715105412191,
          },
          {
            leverage: 0.024999999999999998,
            order_type: "LONG",
            order_uuid: "c114ca17-04ff-4fc0-87de-768dda714148",
            price: 63231.83,
            price_sources: [],
            processed_ms: 1715105668990,
          },
          {
            leverage: 0.024999999999999998,
            order_type: "LONG",
            order_uuid: "4b5342f8-cee0-483a-8c16-53d92da77cdf",
            price: 63226.71,
            price_sources: [],
            processed_ms: 1715105790174,
          },
        ],
        position_type: "FLAT",
        position_uuid: "d06419a0-6370-4a6e-9f60-a5e92e7a3c1d",
        return_at_close: 1.0000762323922046,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 63698.82999999999,
        close_ms: 1715107092160,
        current_return: 1.000238018281825,
        initial_entry_price: 63177.5,
        is_closed_position: true,
        miner_hotkey: "5Dxqzduahnqw8q3XSUfTcEZGU7xmAsfJubhHZwvXVLN9fSjR",
        net_leverage: 0.0,
        open_ms: 1715106149977,
        orders: [
          {
            leverage: -0.08,
            order_type: "SHORT",
            order_uuid: "0b693ab3-be49-472e-aa98-07a4cb17f476",
            price: 63177.5,
            price_sources: [],
            processed_ms: 1715106149620,
          },
          {
            leverage: 0.04,
            order_type: "LONG",
            order_uuid: "3b6fd6de-7ac2-4006-8206-5663a9c97e7c",
            price: 62982.02,
            price_sources: [],
            processed_ms: 1715106849089,
          },
          {
            leverage: 0.02,
            order_type: "LONG",
            order_uuid: "c2273f69-017a-488a-9900-601af4d816a3",
            price: 63047.13,
            price_sources: [],
            processed_ms: 1715106971320,
          },
          {
            leverage: 0.02,
            order_type: "LONG",
            order_uuid: "1700335f-cad3-4282-ab02-639883d83082",
            price: 62946.96,
            price_sources: [],
            processed_ms: 1715107092160,
          },
        ],
        position_type: "FLAT",
        position_uuid: "8c7f7b7e-d116-4e81-85ac-643a37b4bc68",
        return_at_close: 1.0001579992403624,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 63492.81666666664,
        close_ms: 1715118573982,
        current_return: 1.0006337200996809,
        initial_entry_price: 63001.0,
        is_closed_position: true,
        miner_hotkey: "5Dxqzduahnqw8q3XSUfTcEZGU7xmAsfJubhHZwvXVLN9fSjR",
        net_leverage: 0.0,
        open_ms: 1715107453142,
        orders: [
          {
            leverage: -0.08,
            order_type: "SHORT",
            order_uuid: "0dbddf76-9405-4d8d-8008-01cc96981b35",
            price: 63001.0,
            price_sources: [],
            processed_ms: 1715107449466,
          },
          {
            leverage: -0.08,
            order_type: "SHORT",
            order_uuid: "eb7a8e6a-2d12-472e-838a-9b0db88cbd00",
            price: 63025.3,
            price_sources: [],
            processed_ms: 1715107687348,
          },
          {
            leverage: 0.08,
            order_type: "LONG",
            order_uuid: "aa300c28-b788-466c-9ceb-4e7cdaa49eb7",
            price: 62972.3,
            price_sources: [],
            processed_ms: 1715115046892,
          },
          {
            leverage: -0.08,
            order_type: "SHORT",
            order_uuid: "653af1f1-bd46-4484-ab5f-a634ce27a3df",
            price: 63000.03,
            price_sources: [],
            processed_ms: 1715116176089,
          },
          {
            leverage: -0.08,
            order_type: "SHORT",
            order_uuid: "313bf71f-f749-4d49-aef2-fb010883cbee",
            price: 63148.34,
            price_sources: [],
            processed_ms: 1715117073452,
          },
          {
            leverage: 0.12,
            order_type: "LONG",
            order_uuid: "83affb1f-cc6e-4579-863e-96995151fdb7",
            price: 62924.58,
            price_sources: [],
            processed_ms: 1715117735330,
          },
          {
            leverage: 0.06,
            order_type: "LONG",
            order_uuid: "e78406a6-a51f-46e1-b4f3-af9655dee28b",
            price: 62927.85,
            price_sources: [],
            processed_ms: 1715118483144,
          },
          {
            leverage: 0.06,
            order_type: "LONG",
            order_uuid: "b7df1bc3-7aeb-4019-9e1f-c194533d10b9",
            price: 62827.4,
            price_sources: [],
            processed_ms: 1715118573982,
          },
        ],
        position_type: "FLAT",
        position_uuid: "aa95cab6-1499-460f-b8b5-2632bede6e3c",
        return_at_close: 1.000313517309249,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 62895.99,
        close_ms: 1715118935303,
        current_return: 1.0000496724830947,
        initial_entry_price: 62895.99,
        is_closed_position: true,
        miner_hotkey: "5Dxqzduahnqw8q3XSUfTcEZGU7xmAsfJubhHZwvXVLN9fSjR",
        net_leverage: 0.0,
        open_ms: 1715118764196,
        orders: [
          {
            leverage: 0.06,
            order_type: "LONG",
            order_uuid: "a8b8e848-7ec5-4a36-8b44-b029510cef1d",
            price: 62895.99,
            price_sources: [],
            processed_ms: 1715118755336,
          },
          {
            leverage: -0.08,
            order_type: "SHORT",
            order_uuid: "0371712a-7260-43ee-adcb-a6b62faf0d46",
            price: 62948.06,
            price_sources: [],
            processed_ms: 1715118935303,
          },
        ],
        position_type: "FLAT",
        position_uuid: "78b98fb4-e25f-4f14-bdfc-a95c7f4dbfb1",
        return_at_close: 0.9999896695027458,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 63264.020000000004,
        close_ms: 1715119505166,
        current_return: 1.0001768583783441,
        initial_entry_price: 63133.0,
        is_closed_position: true,
        miner_hotkey: "5Dxqzduahnqw8q3XSUfTcEZGU7xmAsfJubhHZwvXVLN9fSjR",
        net_leverage: 0.0,
        open_ms: 1715119073203,
        orders: [
          {
            leverage: -0.08,
            order_type: "SHORT",
            order_uuid: "247e03c4-6699-446c-bb94-1f4f5e16a371",
            price: 63133.0,
            price_sources: [],
            processed_ms: 1715119072818,
          },
          {
            leverage: 0.04,
            order_type: "LONG",
            order_uuid: "a734dd1e-d19b-4f51-9f3b-650e27a2662d",
            price: 63001.98,
            price_sources: [],
            processed_ms: 1715119414057,
          },
          {
            leverage: 0.04,
            order_type: "LONG",
            order_uuid: "2ae20ff1-a4d5-40e2-a954-25bf505e171b",
            price: 62984.88,
            price_sources: [],
            processed_ms: 1715119505166,
          },
        ],
        position_type: "FLAT",
        position_uuid: "95b8ca0f-6d3e-477b-bcd5-f1ee9c8598ff",
        return_at_close: 1.0000968442296738,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 63010.4,
        close_ms: 1715119825125,
        current_return: 0.999947799093483,
        initial_entry_price: 63010.4,
        is_closed_position: true,
        miner_hotkey: "5Dxqzduahnqw8q3XSUfTcEZGU7xmAsfJubhHZwvXVLN9fSjR",
        net_leverage: 0.0,
        open_ms: 1715119606350,
        orders: [
          {
            leverage: 0.04,
            order_type: "LONG",
            order_uuid: "3c268fbe-6bd7-4baf-8535-30e3244d0086",
            price: 63010.4,
            price_sources: [],
            processed_ms: 1715119596281,
          },
          {
            leverage: -0.08,
            order_type: "SHORT",
            order_uuid: "0c15b44f-c5b5-4a0c-a81d-e3ea9479600a",
            price: 62928.17,
            price_sources: [],
            processed_ms: 1715119825125,
          },
        ],
        position_type: "FLAT",
        position_uuid: "79727df7-eddc-41bb-b0c0-342872cef26f",
        return_at_close: 0.9999078011815192,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 63239.01999999999,
        close_ms: 1715120229174,
        current_return: 1.000274800875829,
        initial_entry_price: 63026.0,
        is_closed_position: true,
        miner_hotkey: "5Dxqzduahnqw8q3XSUfTcEZGU7xmAsfJubhHZwvXVLN9fSjR",
        net_leverage: 0.0,
        open_ms: 1715119940401,
        orders: [
          {
            leverage: -0.08,
            order_type: "SHORT",
            order_uuid: "859982a5-96b1-4ddc-a31d-7e6f11e2a6d4",
            price: 63026.0,
            price_sources: [],
            processed_ms: 1715119940159,
          },
          {
            leverage: 0.04,
            order_type: "LONG",
            order_uuid: "4527ae3d-799a-4ec1-b3ac-8e5c3fb58ff4",
            price: 62812.98,
            price_sources: [],
            processed_ms: 1715120138318,
          },
          {
            leverage: 0.04,
            order_type: "LONG",
            order_uuid: "effa30c4-dd9d-457b-977f-a51fabe8acc4",
            price: 62806.03,
            price_sources: [],
            processed_ms: 1715120229174,
          },
        ],
        position_type: "FLAT",
        position_uuid: "21524d2f-943a-4589-bce2-e5e48c696896",
        return_at_close: 1.0001947788917591,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 62858.545000000006,
        close_ms: 1715120526476,
        current_return: 1.0001545085015933,
        initial_entry_price: 62885.86,
        is_closed_position: true,
        miner_hotkey: "5Dxqzduahnqw8q3XSUfTcEZGU7xmAsfJubhHZwvXVLN9fSjR",
        net_leverage: 0.0,
        open_ms: 1715120320210,
        orders: [
          {
            leverage: 0.04,
            order_type: "LONG",
            order_uuid: "cf3f59c3-3f95-45d6-ba52-9d91a1d6d593",
            price: 62885.86,
            price_sources: [],
            processed_ms: 1715120319956,
          },
          {
            leverage: 0.04,
            order_type: "LONG",
            order_uuid: "ac49f671-8bfe-4800-ab7a-8450f2422cb0",
            price: 62831.23,
            price_sources: [],
            processed_ms: 1715120411378,
          },
          {
            leverage: -0.08,
            order_type: "SHORT",
            order_uuid: "59adca40-41b5-40f0-ba6e-061ae6265ae1",
            price: 62980.0,
            price_sources: [],
            processed_ms: 1715120526476,
          },
        ],
        position_type: "FLAT",
        position_uuid: "7bc9497f-c3db-4beb-a393-ab58301f96a2",
        return_at_close: 1.0000744961409131,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 63511.91999999998,
        close_ms: 1715123032290,
        current_return: 1.00072976696166,
        initial_entry_price: 62929.13,
        is_closed_position: true,
        miner_hotkey: "5Dxqzduahnqw8q3XSUfTcEZGU7xmAsfJubhHZwvXVLN9fSjR",
        net_leverage: 0.0,
        open_ms: 1715120778256,
        orders: [
          {
            leverage: -0.08,
            order_type: "SHORT",
            order_uuid: "3401d18f-752a-4ff4-97f7-a5f588cb53d9",
            price: 62929.13,
            price_sources: [],
            processed_ms: 1715120777984,
          },
          {
            leverage: -0.08,
            order_type: "SHORT",
            order_uuid: "ee3d1bab-7543-4adf-8928-9eb1d68051b5",
            price: 62969.0,
            price_sources: [],
            processed_ms: 1715120928836,
          },
          {
            leverage: 0.08,
            order_type: "LONG",
            order_uuid: "0022a741-502c-4451-9a4f-beebf000cfdf",
            price: 62831.87,
            price_sources: [],
            processed_ms: 1715122717060,
          },
          {
            leverage: 0.04,
            order_type: "LONG",
            order_uuid: "f0a9b3cc-1141-4f40-ad5e-b648bd275dd7",
            price: 62620.6,
            price_sources: [],
            processed_ms: 1715122941969,
          },
          {
            leverage: 0.04,
            order_type: "LONG",
            order_uuid: "1eae25d5-a340-4ccf-94b5-cf5117482690",
            price: 62363.83,
            price_sources: [],
            processed_ms: 1715123032290,
          },
        ],
        position_type: "FLAT",
        position_uuid: "e5e1e6e2-2465-44a2-bd95-98a24e4f175a",
        return_at_close: 1.000569650198946,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 64042.939999999995,
        close_ms: 1715124241292,
        current_return: 1.000270740681491,
        initial_entry_price: 62510.0,
        is_closed_position: true,
        miner_hotkey: "5Dxqzduahnqw8q3XSUfTcEZGU7xmAsfJubhHZwvXVLN9fSjR",
        net_leverage: 0.0,
        open_ms: 1715123335184,
        orders: [
          {
            leverage: -0.08,
            order_type: "SHORT",
            order_uuid: "cc0dd0bf-e4d4-432c-b0b2-e60f765a8cec",
            price: 62510.0,
            price_sources: [],
            processed_ms: 1715123334910,
          },
          {
            leverage: -0.08,
            order_type: "SHORT",
            order_uuid: "3092e21a-a2f2-4ff3-acee-9d83e79c89bb",
            price: 62434.25,
            price_sources: [],
            processed_ms: 1715123524565,
          },
          {
            leverage: 0.04,
            order_type: "LONG",
            order_uuid: "5cb8b2d5-d40b-4d32-80b8-211834b6cc56",
            price: 62391.89,
            price_sources: [],
            processed_ms: 1715123643240,
          },
          {
            leverage: 0.08,
            order_type: "LONG",
            order_uuid: "dc9982a5-4711-43e0-8b99-511085b22d35",
            price: 62369.8,
            price_sources: [],
            processed_ms: 1715123734083,
          },
          {
            leverage: 0.02,
            order_type: "LONG",
            order_uuid: "a75af42c-977e-4338-a1ac-bbc948742e10",
            price: 62339.75,
            price_sources: [],
            processed_ms: 1715123916283,
          },
          {
            leverage: 0.009999999999999997,
            order_type: "LONG",
            order_uuid: "399206c6-85ca-46b3-9c59-60c23d69895a",
            price: 62305.6,
            price_sources: [],
            processed_ms: 1715124006932,
          },
          {
            leverage: 0.009999999999999997,
            order_type: "FLAT",
            order_uuid: "b3fae9ad-1c4f-4b01-a1d6-de914a105e1b",
            price: 62350.54,
            price_sources: [],
            processed_ms: 1715124241292,
          },
        ],
        position_type: "FLAT",
        position_uuid: "ac3d87a7-217e-46b1-ae92-c4f84e39ae4c",
        return_at_close: 1.0001106973629819,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 66843.60078189307,
        close_ms: 1715144403063,
        current_return: 1.00264630421141,
        initial_entry_price: 62321.29,
        is_closed_position: true,
        miner_hotkey: "5Dxqzduahnqw8q3XSUfTcEZGU7xmAsfJubhHZwvXVLN9fSjR",
        net_leverage: 0.0,
        open_ms: 1715124477466,
        orders: [
          {
            leverage: -0.08,
            order_type: "SHORT",
            order_uuid: "af5c764d-1ad5-4fb7-8407-0156c881afc6",
            price: 62321.29,
            price_sources: [],
            processed_ms: 1715124477233,
          },
          {
            leverage: -0.08,
            order_type: "SHORT",
            order_uuid: "c2cb88f6-f0fc-48e2-92b6-56133bd33944",
            price: 62515.0,
            price_sources: [],
            processed_ms: 1715124838852,
          },
          {
            leverage: -0.08,
            order_type: "SHORT",
            order_uuid: "6b27754e-10eb-4b33-9dd3-cd5c444cb833",
            price: 62469.98,
            price_sources: [],
            processed_ms: 1715124928882,
          },
          {
            leverage: 0.12,
            order_type: "LONG",
            order_uuid: "2a2feb8f-a79f-489d-ac31-3310524f8790",
            price: 62329.87,
            price_sources: [],
            processed_ms: 1715125848283,
          },
          {
            leverage: 0.06,
            order_type: "LONG",
            order_uuid: "9d561c58-4418-4831-a4f5-325dc9bba9b9",
            price: 62320.94,
            price_sources: [],
            processed_ms: 1715126132015,
          },
          {
            leverage: -0.08,
            order_type: "SHORT",
            order_uuid: "566b81a9-f985-4c18-81e7-f9b2e8617bf5",
            price: 62468.0,
            price_sources: [],
            processed_ms: 1715126333073,
          },
          {
            leverage: 0.03,
            order_type: "LONG",
            order_uuid: "843c6299-8146-457c-b705-5da01eb0b41c",
            price: 62302.5,
            price_sources: [],
            processed_ms: 1715126425129,
          },
          {
            leverage: -0.08,
            order_type: "SHORT",
            order_uuid: "475e3541-18b9-4098-a0be-290d4bb9754e",
            price: 62350.1,
            price_sources: [],
            processed_ms: 1715126524910,
          },
          {
            leverage: 0.095,
            order_type: "LONG",
            order_uuid: "0843b056-4cbc-4be7-b299-8cdd247a2c5a",
            price: 62448.0,
            price_sources: [],
            processed_ms: 1715128279908,
          },
          {
            leverage: 0.0475,
            order_type: "LONG",
            order_uuid: "711810d8-43b8-416d-81c2-789d68e64ec7",
            price: 62156.5,
            price_sources: [],
            processed_ms: 1715128369997,
          },
          {
            leverage: -0.08,
            order_type: "SHORT",
            order_uuid: "4dac9640-d27c-4484-b6e0-f88970198433",
            price: 62700.0,
            price_sources: [],
            processed_ms: 1715128740346,
          },
          {
            leverage: -0.08,
            order_type: "SHORT",
            order_uuid: "fd464e4f-de1c-42b8-881d-56d5ab770eeb",
            price: 62573.44,
            price_sources: [],
            processed_ms: 1715128837102,
          },
          {
            leverage: -0.08,
            order_type: "SHORT",
            order_uuid: "6c64ecad-6f73-429d-a0d7-58392e1b0b1f",
            price: 62640.1,
            price_sources: [],
            processed_ms: 1715129401748,
          },
          {
            leverage: -0.08,
            order_type: "SHORT",
            order_uuid: "21b09d90-ab4e-44a9-b967-7530a2555abc",
            price: 62703.2,
            price_sources: [],
            processed_ms: 1715134207202,
          },
          {
            leverage: -0.08,
            order_type: "SHORT",
            order_uuid: "af243da6-49ff-4e61-bddd-2aa0c735ea2f",
            price: 62875.0,
            price_sources: [],
            processed_ms: 1715135161727,
          },
          {
            leverage: -0.08,
            order_type: "SHORT",
            order_uuid: "6723d40f-11d9-49ec-a1af-142c034c13b7",
            price: 62885.63,
            price_sources: [],
            processed_ms: 1715139855719,
          },
          {
            leverage: -0.08,
            order_type: "SHORT",
            order_uuid: "4740793f-0ca1-4319-a8f8-c565b91b6673",
            price: 62956.0,
            price_sources: [],
            processed_ms: 1715142097295,
          },
          {
            leverage: 0.30375,
            order_type: "LONG",
            order_uuid: "0afe8255-50a5-4f70-a14a-eca8d796fc5a",
            price: 62510.53,
            price_sources: [],
            processed_ms: 1715143241981,
          },
          {
            leverage: 0.151875,
            order_type: "LONG",
            order_uuid: "3ad690d2-c2de-4b5f-a4f6-aaa14517a28b",
            price: 62533.35,
            price_sources: [],
            processed_ms: 1715143433382,
          },
          {
            leverage: 0.0759375,
            order_type: "LONG",
            order_uuid: "c9515a17-4f83-46cd-add7-3f22af1d673d",
            price: 62498.45,
            price_sources: [],
            processed_ms: 1715144178993,
          },
          {
            leverage: 0.03796875,
            order_type: "LONG",
            order_uuid: "652f338e-4435-477a-b13e-de877a1cfc3c",
            price: 62549.85,
            price_sources: [],
            processed_ms: 1715144306930,
          },
          {
            leverage: 0.03796875,
            order_type: "LONG",
            order_uuid: "b451ab7c-7c1d-4b14-b760-959097ed4624",
            price: 62500.0,
            price_sources: [],
            processed_ms: 1715144403063,
          },
        ],
        position_type: "FLAT",
        position_uuid: "3c53686b-204a-480a-a537-4edcff1e9ca1",
        return_at_close: 1.0016837637593672,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 62406.08000000001,
        close_ms: 1715144697223,
        current_return: 1.000006625631469,
        initial_entry_price: 62406.08,
        is_closed_position: true,
        miner_hotkey: "5Dxqzduahnqw8q3XSUfTcEZGU7xmAsfJubhHZwvXVLN9fSjR",
        net_leverage: 0.0,
        open_ms: 1715144584350,
        orders: [
          {
            leverage: 0.018984375,
            order_type: "LONG",
            order_uuid: "3c5172c4-e536-42ef-8480-8e679341de11",
            price: 62406.08,
            price_sources: [],
            processed_ms: 1715144584042,
          },
          {
            leverage: -0.08,
            order_type: "SHORT",
            order_uuid: "19c757fb-a52f-408b-8e94-cfa76f50b30e",
            price: 62427.86,
            price_sources: [],
            processed_ms: 1715144697223,
          },
        ],
        position_type: "FLAT",
        position_uuid: "1e98c1bc-3a07-4564-a907-35bb6a801c64",
        return_at_close: 0.9999876411306855,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 63285.65666666666,
        close_ms: 1715149276094,
        current_return: 1.0006892724193575,
        initial_entry_price: 62591.0,
        is_closed_position: true,
        miner_hotkey: "5Dxqzduahnqw8q3XSUfTcEZGU7xmAsfJubhHZwvXVLN9fSjR",
        net_leverage: 0.0,
        open_ms: 1715144917211,
        orders: [
          {
            leverage: -0.08,
            order_type: "SHORT",
            order_uuid: "2a157f16-1021-44f3-9bda-aef777178269",
            price: 62591.0,
            price_sources: [],
            processed_ms: 1715144916764,
          },
          {
            leverage: -0.08,
            order_type: "SHORT",
            order_uuid: "91d28086-7288-472e-bba7-8b943a9d5f79",
            price: 62508.44,
            price_sources: [],
            processed_ms: 1715145085371,
          },
          {
            leverage: -0.08,
            order_type: "SHORT",
            order_uuid: "dee3b067-6eb2-4808-aaf9-5413c49076c0",
            price: 62810.0,
            price_sources: [],
            processed_ms: 1715146381612,
          },
          {
            leverage: 0.12,
            order_type: "LONG",
            order_uuid: "8c0b523a-41a0-425e-96f6-7e7c1d134d2c",
            price: 62526.54,
            price_sources: [],
            processed_ms: 1715148810103,
          },
          {
            leverage: 0.03,
            order_type: "LONG",
            order_uuid: "4cb6e241-0e08-41d3-9907-2cb3c9b8808e",
            price: 62461.23,
            price_sources: [],
            processed_ms: 1715149091175,
          },
          {
            leverage: 0.045,
            order_type: "LONG",
            order_uuid: "48c267ea-ea29-49fd-be95-3ea55e4eb5d9",
            price: 62397.31,
            price_sources: [],
            processed_ms: 1715149185348,
          },
          {
            leverage: 0.045,
            order_type: "LONG",
            order_uuid: "ec2bcd1b-0ab2-45d6-99dc-c3648ced50e1",
            price: 62326.94,
            price_sources: [],
            processed_ms: 1715149276094,
          },
        ],
        position_type: "FLAT",
        position_uuid: "6677544e-2a20-4298-9281-30a9ddaa7941",
        return_at_close: 1.0004491069939767,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 62506.98500000001,
        close_ms: 1715152962985,
        current_return: 1.0006059258106963,
        initial_entry_price: 62226.76,
        is_closed_position: true,
        miner_hotkey: "5Dxqzduahnqw8q3XSUfTcEZGU7xmAsfJubhHZwvXVLN9fSjR",
        net_leverage: 0.0,
        open_ms: 1715149522049,
        orders: [
          {
            leverage: -0.08,
            order_type: "SHORT",
            order_uuid: "676c0ded-afed-4e92-b7e6-69939daddb5c",
            price: 62226.76,
            price_sources: [],
            processed_ms: 1715149521707,
          },
          {
            leverage: -0.08,
            order_type: "SHORT",
            order_uuid: "7a6f33bb-9cdb-4995-bc39-4f4245ae98d0",
            price: 62298.2,
            price_sources: [],
            processed_ms: 1715150006664,
          },
          {
            leverage: -0.08,
            order_type: "SHORT",
            order_uuid: "11b7fb1b-1b1e-4177-a3f9-59a202084c4d",
            price: 62471.66,
            price_sources: [],
            processed_ms: 1715151184131,
          },
          {
            leverage: -0.08,
            order_type: "SHORT",
            order_uuid: "70750e00-5a3a-4b90-bc44-ca293dbb87d2",
            price: 62536.55,
            price_sources: [],
            processed_ms: 1715151331011,
          },
          {
            leverage: 0.16,
            order_type: "LONG",
            order_uuid: "e04de0f9-4f31-4da7-9b40-6dd07cae95ba",
            price: 62259.6,
            price_sources: [],
            processed_ms: 1715152871237,
          },
          {
            leverage: 0.16,
            order_type: "LONG",
            order_uuid: "7d32ff3d-9e81-4c74-bc70-2754f28621b8",
            price: 62271.33,
            price_sources: [],
            processed_ms: 1715152962985,
          },
        ],
        position_type: "FLAT",
        position_uuid: "68e25f55-e63a-41dd-970c-db832e5b9c9a",
        return_at_close: 1.000285731914437,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 62348.116666666676,
        close_ms: 1715155922560,
        current_return: 1.0004706009525715,
        initial_entry_price: 62166.47,
        is_closed_position: true,
        miner_hotkey: "5Dxqzduahnqw8q3XSUfTcEZGU7xmAsfJubhHZwvXVLN9fSjR",
        net_leverage: 0.0,
        open_ms: 1715153247977,
        orders: [
          {
            leverage: -0.08,
            order_type: "SHORT",
            order_uuid: "2b08a93d-30dc-4493-a612-e3b50543eec3",
            price: 62166.47,
            price_sources: [],
            processed_ms: 1715153244109,
          },
          {
            leverage: -0.08,
            order_type: "SHORT",
            order_uuid: "3ec508ae-329c-479d-8fb3-518d5ae8eddd",
            price: 62362.0,
            price_sources: [],
            processed_ms: 1715153393995,
          },
          {
            leverage: 0.08,
            order_type: "LONG",
            order_uuid: "698b963d-4207-4acd-9996-5a007b8e3cb8",
            price: 62144.34,
            price_sources: [],
            processed_ms: 1715154641980,
          },
          {
            leverage: -0.08,
            order_type: "SHORT",
            order_uuid: "058c9559-23ea-4f84-9a68-8adae29dfa88",
            price: 62165.36,
            price_sources: [],
            processed_ms: 1715154800133,
          },
          {
            leverage: 0.08,
            order_type: "LONG",
            order_uuid: "caf23960-7b6f-4e98-8018-f45786a16dce",
            price: 62144.85,
            price_sources: [],
            processed_ms: 1715154983248,
          },
          {
            leverage: -0.08,
            order_type: "SHORT",
            order_uuid: "4abbf9ee-accb-497d-8eea-9c4493b9d5f5",
            price: 62128.27,
            price_sources: [],
            processed_ms: 1715155330058,
          },
          {
            leverage: -0.08,
            order_type: "SHORT",
            order_uuid: "693d163f-2694-43bb-8a97-1d4ac066620c",
            price: 62171.68,
            price_sources: [],
            processed_ms: 1715155598076,
          },
          {
            leverage: 0.12,
            order_type: "LONG",
            order_uuid: "d33d8974-8b28-45e1-95fa-3fcb466b1d7f",
            price: 62121.61,
            price_sources: [],
            processed_ms: 1715155829954,
          },
          {
            leverage: 0.12,
            order_type: "LONG",
            order_uuid: "bd81c793-e304-4bf4-b944-0efc5b81e910",
            price: 62104.32,
            price_sources: [],
            processed_ms: 1715155922560,
          },
        ],
        position_type: "FLAT",
        position_uuid: "8618706b-c6cf-4a5f-942d-2c0a9b177032",
        return_at_close: 1.0000704127121904,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 64421.49000000003,
        close_ms: 1715158582130,
        current_return: 1.0003578944290872,
        initial_entry_price: 62250.48,
        is_closed_position: true,
        miner_hotkey: "5Dxqzduahnqw8q3XSUfTcEZGU7xmAsfJubhHZwvXVLN9fSjR",
        net_leverage: 0.0,
        open_ms: 1715156257099,
        orders: [
          {
            leverage: -0.08,
            order_type: "SHORT",
            order_uuid: "fc0ee3a4-665c-4894-b1a3-919d945df306",
            price: 62250.48,
            price_sources: [],
            processed_ms: 1715156255832,
          },
          {
            leverage: -0.08,
            order_type: "SHORT",
            order_uuid: "8b90ae1f-dc66-4ee1-a965-fb740f669aa5",
            price: 62375.42,
            price_sources: [],
            processed_ms: 1715156534510,
          },
          {
            leverage: 0.08,
            order_type: "LONG",
            order_uuid: "7a24ce91-c0f3-41dc-b8cb-315c5c473421",
            price: 62189.64,
            price_sources: [],
            processed_ms: 1715157921974,
          },
          {
            leverage: 0.04,
            order_type: "LONG",
            order_uuid: "1a40d552-695e-446c-a88f-49477f8752e1",
            price: 62160.98,
            price_sources: [],
            processed_ms: 1715158014950,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "7cd01666-9199-470e-aca6-820fc4a8afa7",
            price: 62129.17,
            price_sources: [],
            processed_ms: 1715158199094,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "fae5d73e-4008-403b-8c9d-0124b81b85b7",
            price: 62150.0,
            price_sources: [],
            processed_ms: 1715158290990,
          },
          {
            leverage: 0.009999999999999998,
            order_type: "LONG",
            order_uuid: "cb5c8336-9267-49b7-ba1f-b82d5c9d5425",
            price: 62145.5,
            price_sources: [],
            processed_ms: 1715158486039,
          },
          {
            leverage: 0.009999999999999998,
            order_type: "LONG",
            order_uuid: "57c80b26-57c0-40a8-8bcf-ac748508b0c5",
            price: 62193.58,
            price_sources: [],
            processed_ms: 1715158582130,
          },
        ],
        position_type: "FLAT",
        position_uuid: "913dc637-a0de-4f35-9600-0e280418dbd9",
        return_at_close: 1.0001978371659785,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 62760.5,
        close_ms: 1715159047247,
        current_return: 1.0005474148963398,
        initial_entry_price: 62512.0,
        is_closed_position: true,
        miner_hotkey: "5Dxqzduahnqw8q3XSUfTcEZGU7xmAsfJubhHZwvXVLN9fSjR",
        net_leverage: 0.0,
        open_ms: 1715158745615,
        orders: [
          {
            leverage: -0.08,
            order_type: "SHORT",
            order_uuid: "938b86a9-7fd0-45e1-bc7a-c86357da4ed1",
            price: 62512.0,
            price_sources: [],
            processed_ms: 1715158745399,
          },
          {
            leverage: -0.08,
            order_type: "SHORT",
            order_uuid: "cbcaa445-971d-4800-a123-28fab6560069",
            price: 62580.0,
            price_sources: [],
            processed_ms: 1715158862420,
          },
          {
            leverage: 0.08,
            order_type: "LONG",
            order_uuid: "f1346904-189d-414f-a8d1-28f489b5d752",
            price: 62331.5,
            price_sources: [],
            processed_ms: 1715158955012,
          },
          {
            leverage: 0.08,
            order_type: "LONG",
            order_uuid: "3f575a14-3db2-4da6-a662-24fcdf1584f5",
            price: 62332.75,
            price_sources: [],
            processed_ms: 1715159047247,
          },
        ],
        position_type: "FLAT",
        position_uuid: "b97df678-e630-4277-a36d-a78e1f4b015c",
        return_at_close: 1.0003873273099564,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 62139.54000000001,
        close_ms: 1715160113937,
        current_return: 0.9999962103578948,
        initial_entry_price: 62275.01,
        is_closed_position: true,
        miner_hotkey: "5Dxqzduahnqw8q3XSUfTcEZGU7xmAsfJubhHZwvXVLN9fSjR",
        net_leverage: 0.0,
        open_ms: 1715159347136,
        orders: [
          {
            leverage: -0.08,
            order_type: "SHORT",
            order_uuid: "e87ce8ee-6482-4239-bc95-69e6f0382aa4",
            price: 62275.01,
            price_sources: [],
            processed_ms: 1715159346738,
          },
          {
            leverage: -0.08,
            order_type: "SHORT",
            order_uuid: "07f3fc5f-40f4-419d-9ec8-9f879c52eb25",
            price: 62241.53,
            price_sources: [],
            processed_ms: 1715159704297,
          },
          {
            leverage: 0.08,
            order_type: "LONG",
            order_uuid: "c2326cb8-dfc0-48dd-a137-95761b42715f",
            price: 62377.0,
            price_sources: [],
            processed_ms: 1715160021314,
          },
          {
            leverage: 0.08,
            order_type: "LONG",
            order_uuid: "892dac32-db00-4f0d-b7bb-428fb7afd625",
            price: 62142.49,
            price_sources: [],
            processed_ms: 1715160113937,
          },
        ],
        position_type: "FLAT",
        position_uuid: "94cfb9a0-1649-4dc9-b5ce-59edf8094bad",
        return_at_close: 0.9998362109642375,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 62020.130000000005,
        close_ms: 1715160977432,
        current_return: 1.0000943440880228,
        initial_entry_price: 62113.06,
        is_closed_position: true,
        miner_hotkey: "5Dxqzduahnqw8q3XSUfTcEZGU7xmAsfJubhHZwvXVLN9fSjR",
        net_leverage: 0.0,
        open_ms: 1715160209394,
        orders: [
          {
            leverage: 0.08,
            order_type: "LONG",
            order_uuid: "d8fb4087-7cfd-4253-9161-d1a1a33b03d9",
            price: 62113.06,
            price_sources: [],
            processed_ms: 1715160209169,
          },
          {
            leverage: 0.04,
            order_type: "LONG",
            order_uuid: "d0befa70-92e6-449c-897c-5e9bf3019d41",
            price: 62144.01,
            price_sources: [],
            processed_ms: 1715160304998,
          },
          {
            leverage: -0.08,
            order_type: "SHORT",
            order_uuid: "858ac651-e997-4ed5-ab99-74d814577d1e",
            price: 62175.0,
            price_sources: [],
            processed_ms: 1715160566150,
          },
          {
            leverage: -0.08,
            order_type: "SHORT",
            order_uuid: "16a193af-da5d-4f56-bd79-c56577251e12",
            price: 62166.63,
            price_sources: [],
            processed_ms: 1715160977432,
          },
        ],
        position_type: "FLAT",
        position_uuid: "a7ed3b42-8ef4-4dc3-9b7a-fc3db36a2ab6",
        return_at_close: 0.9999743327667322,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 62302.79199999999,
        close_ms: 1715166647055,
        current_return: 1.0003373831414664,
        initial_entry_price: 62126.4,
        is_closed_position: true,
        miner_hotkey: "5Dxqzduahnqw8q3XSUfTcEZGU7xmAsfJubhHZwvXVLN9fSjR",
        net_leverage: 0.0,
        open_ms: 1715161276884,
        orders: [
          {
            leverage: -0.08,
            order_type: "SHORT",
            order_uuid: "85332bf6-5867-455a-abdb-18da8ee5fe1d",
            price: 62126.4,
            price_sources: [],
            processed_ms: 1715161274563,
          },
          {
            leverage: -0.08,
            order_type: "SHORT",
            order_uuid: "bb31549d-82b1-4f8d-9407-147d5bc7f461",
            price: 62186.23,
            price_sources: [],
            processed_ms: 1715161526579,
          },
          {
            leverage: -0.08,
            order_type: "SHORT",
            order_uuid: "90053dfc-ff49-4722-a4a7-e279421021dd",
            price: 62489.0,
            price_sources: [],
            processed_ms: 1715162451809,
          },
          {
            leverage: -0.08,
            order_type: "SHORT",
            order_uuid: "83c68a0e-05c1-41f9-a9eb-563da4389e0c",
            price: 62447.85,
            price_sources: [],
            processed_ms: 1715163741829,
          },
          {
            leverage: -0.08,
            order_type: "SHORT",
            order_uuid: "9f2c70a5-5f90-48c2-a0ac-81ffbfdc30d7",
            price: 62660.0,
            price_sources: [],
            processed_ms: 1715163912844,
          },
          {
            leverage: 0.2,
            order_type: "LONG",
            order_uuid: "34ac3f59-5061-468a-b33f-248d910ede9c",
            price: 62461.0,
            price_sources: [],
            processed_ms: 1715166555525,
          },
          {
            leverage: 0.2,
            order_type: "LONG",
            order_uuid: "be0487e1-908e-4c8c-a63a-7d76bdc75e53",
            price: 62197.99,
            price_sources: [],
            processed_ms: 1715166647055,
          },
        ],
        position_type: "FLAT",
        position_uuid: "d5147136-9b24-466c-867c-e53096d5fe59",
        return_at_close: 0.9999372481882098,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 62471.529999999984,
        close_ms: 1715172107130,
        current_return: 1.0003221949224053,
        initial_entry_price: 62287.76,
        is_closed_position: true,
        miner_hotkey: "5Dxqzduahnqw8q3XSUfTcEZGU7xmAsfJubhHZwvXVLN9fSjR",
        net_leverage: 0.0,
        open_ms: 1715166992968,
        orders: [
          {
            leverage: -0.08,
            order_type: "SHORT",
            order_uuid: "a86df53f-8cd1-4a58-b1cd-1b2a3c35d0e8",
            price: 62287.76,
            price_sources: [],
            processed_ms: 1715166989201,
          },
          {
            leverage: -0.08,
            order_type: "SHORT",
            order_uuid: "59fe8614-97c1-4508-a9ae-be7e8adb79f4",
            price: 62170.45,
            price_sources: [],
            processed_ms: 1715167337634,
          },
          {
            leverage: -0.08,
            order_type: "SHORT",
            order_uuid: "a5c65287-4857-4a4d-b554-90817ceeb241",
            price: 62311.2,
            price_sources: [],
            processed_ms: 1715168423907,
          },
          {
            leverage: -0.08,
            order_type: "SHORT",
            order_uuid: "5366c6f2-afd6-45de-ab6d-189320fef5f7",
            price: 62568.0,
            price_sources: [],
            processed_ms: 1715170163646,
          },
          {
            leverage: 0.16,
            order_type: "LONG",
            order_uuid: "70cdbde6-3258-476a-8f3c-8448d5ba1e2e",
            price: 62355.0,
            price_sources: [],
            processed_ms: 1715171828473,
          },
          {
            leverage: 0.08,
            order_type: "LONG",
            order_uuid: "70b2c3fc-4469-4080-93cf-2cd3cad49f80",
            price: 62155.88,
            price_sources: [],
            processed_ms: 1715172011242,
          },
          {
            leverage: 0.08,
            order_type: "LONG",
            order_uuid: "3a043a81-0b31-4279-873c-35997f2e17bd",
            price: 62220.67,
            price_sources: [],
            processed_ms: 1715172107130,
          },
        ],
        position_type: "FLAT",
        position_uuid: "28517768-1f54-4709-913f-4c8762e68e43",
        return_at_close: 1.00000209182003,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 62142.65,
        close_ms: 1715172370325,
        current_return: 1.000202566192462,
        initial_entry_price: 62142.65,
        is_closed_position: true,
        miner_hotkey: "5Dxqzduahnqw8q3XSUfTcEZGU7xmAsfJubhHZwvXVLN9fSjR",
        net_leverage: 0.0,
        open_ms: 1715172199493,
        orders: [
          {
            leverage: 0.08,
            order_type: "LONG",
            order_uuid: "07056272-7494-4c38-acdc-a4c97648bbc0",
            price: 62142.65,
            price_sources: [],
            processed_ms: 1715172199137,
          },
          {
            leverage: -0.08,
            order_type: "SHORT",
            order_uuid: "09c69a88-026c-4761-8ce4-39147e46fe45",
            price: 62300.0,
            price_sources: [],
            processed_ms: 1715172370325,
          },
        ],
        position_type: "FLAT",
        position_uuid: "fba38dff-06cc-4d8c-8b0d-66f850dc0dcf",
        return_at_close: 1.0001225499871667,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 62219.19999999999,
        close_ms: 1715173548141,
        current_return: 1.0000775075475064,
        initial_entry_price: 62239.1,
        is_closed_position: true,
        miner_hotkey: "5Dxqzduahnqw8q3XSUfTcEZGU7xmAsfJubhHZwvXVLN9fSjR",
        net_leverage: 0.0,
        open_ms: 1715173218803,
        orders: [
          {
            leverage: -0.08,
            order_type: "SHORT",
            order_uuid: "68ec79d1-c7de-4cce-b305-1d60bd412429",
            price: 62239.1,
            price_sources: [],
            processed_ms: 1715173218556,
          },
          {
            leverage: 0.04,
            order_type: "LONG",
            order_uuid: "19975c6f-7a43-4cd7-b4be-5da1351081eb",
            price: 62259.0,
            price_sources: [],
            processed_ms: 1715173456506,
          },
          {
            leverage: 0.04,
            order_type: "LONG",
            order_uuid: "24db8559-41dd-4717-8a5b-8e0b4279a000",
            price: 62098.6,
            price_sources: [],
            processed_ms: 1715173548141,
          },
        ],
        position_type: "FLAT",
        position_uuid: "ce9747f6-7402-441a-a3fb-3227191c249f",
        return_at_close: 0.9999975013469026,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 62347.18,
        close_ms: 1715174148020,
        current_return: 1.0004245851908908,
        initial_entry_price: 62095.43,
        is_closed_position: true,
        miner_hotkey: "5Dxqzduahnqw8q3XSUfTcEZGU7xmAsfJubhHZwvXVLN9fSjR",
        net_leverage: 0.0,
        open_ms: 1715173660549,
        orders: [
          {
            leverage: -0.08,
            order_type: "SHORT",
            order_uuid: "c9ed882f-1db7-4b4b-b308-e96e5c09393c",
            price: 62095.43,
            price_sources: [],
            processed_ms: 1715173660220,
          },
          {
            leverage: -0.08,
            order_type: "SHORT",
            order_uuid: "48d9f248-57c1-4570-a648-dbb26be32ea1",
            price: 62156.0,
            price_sources: [],
            processed_ms: 1715173765863,
          },
          {
            leverage: 0.08,
            order_type: "LONG",
            order_uuid: "9c74e216-a105-4efc-9fc1-392bf1d9e67e",
            price: 61904.25,
            price_sources: [],
            processed_ms: 1715174056091,
          },
          {
            leverage: 0.08,
            order_type: "LONG",
            order_uuid: "1ede0e9b-aafe-4081-ad28-253ba473c226",
            price: 62017.62,
            price_sources: [],
            processed_ms: 1715174148020,
          },
        ],
        position_type: "FLAT",
        position_uuid: "c637415b-107e-46ef-95b9-d668c056c4cc",
        return_at_close: 1.0002645172572602,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 63071.19066666664,
        close_ms: 1715175807021,
        current_return: 1.0003602441643473,
        initial_entry_price: 62161.0,
        is_closed_position: true,
        miner_hotkey: "5Dxqzduahnqw8q3XSUfTcEZGU7xmAsfJubhHZwvXVLN9fSjR",
        net_leverage: 0.0,
        open_ms: 1715174271413,
        orders: [
          {
            leverage: -0.08,
            order_type: "SHORT",
            order_uuid: "4035e43d-dd1c-423f-b246-7f484dd30440",
            price: 62161.0,
            price_sources: [],
            processed_ms: 1715174262766,
          },
          {
            leverage: -0.08,
            order_type: "SHORT",
            order_uuid: "d4997909-7808-41ef-b523-9e76e84ee4cb",
            price: 62103.36,
            price_sources: [],
            processed_ms: 1715174360315,
          },
          {
            leverage: 0.08,
            order_type: "LONG",
            order_uuid: "ef5fa51b-59e2-4142-9db6-90574d29a053",
            price: 62015.07,
            price_sources: [],
            processed_ms: 1715174725220,
          },
          {
            leverage: 0.04,
            order_type: "LONG",
            order_uuid: "51a8b68d-8fcb-4a2d-a3bd-a4b5adc8435d",
            price: 62168.0,
            price_sources: [],
            processed_ms: 1715174958194,
          },
          {
            leverage: -0.08,
            order_type: "SHORT",
            order_uuid: "58e119e1-086c-4304-9410-4a6e5ba2c62b",
            price: 61920.0,
            price_sources: [],
            processed_ms: 1715175158224,
          },
          {
            leverage: 0.02,
            order_type: "LONG",
            order_uuid: "293366a3-7959-4254-b1b0-d8fc08cd9bc9",
            price: 61896.0,
            price_sources: [],
            processed_ms: 1715175248146,
          },
          {
            leverage: 0.049999999999999996,
            order_type: "LONG",
            order_uuid: "23041276-5ae4-43b3-babf-b5b741a012a7",
            price: 61890.32,
            price_sources: [],
            processed_ms: 1715175338367,
          },
          {
            leverage: 0.012499999999999999,
            order_type: "LONG",
            order_uuid: "8304f55e-d261-4421-b8a4-c2e592081150",
            price: 61871.19,
            price_sources: [],
            processed_ms: 1715175625441,
          },
          {
            leverage: 0.01875,
            order_type: "LONG",
            order_uuid: "078e2507-e883-4ce2-b5d3-6b9cd2998877",
            price: 61782.0,
            price_sources: [],
            processed_ms: 1715175716243,
          },
          {
            leverage: 0.01875,
            order_type: "LONG",
            order_uuid: "6160487a-4947-4d13-9c67-8a61ee9bf1ce",
            price: 61876.89,
            price_sources: [],
            processed_ms: 1715175807021,
          },
        ],
        position_type: "FLAT",
        position_uuid: "1b0c5555-a0a0-42bf-a103-3de6bb9de438",
        return_at_close: 1.000120157705748,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 61865.53,
        close_ms: 1715176177902,
        current_return: 1.0000279436707322,
        initial_entry_price: 61865.53,
        is_closed_position: true,
        miner_hotkey: "5Dxqzduahnqw8q3XSUfTcEZGU7xmAsfJubhHZwvXVLN9fSjR",
        net_leverage: 0.0,
        open_ms: 1715175898149,
        orders: [
          {
            leverage: 0.009375,
            order_type: "LONG",
            order_uuid: "4d8817f7-1bfa-442a-b783-e45966895866",
            price: 61865.53,
            price_sources: [],
            processed_ms: 1715175897917,
          },
          {
            leverage: 0.009375,
            order_type: "FLAT",
            order_uuid: "1ce7e23f-8898-44d2-8587-bd6bae7bc7f3",
            price: 62049.93,
            price_sources: [],
            processed_ms: 1715176177902,
          },
        ],
        position_type: "FLAT",
        position_uuid: "a1c71c51-f2ee-4d01-8ef6-71f4f16645e9",
        return_at_close: 1.0000185684087604,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 62830.17333333332,
        close_ms: 1715181010473,
        current_return: 1.001919031520654,
        initial_entry_price: 62118.0,
        is_closed_position: true,
        miner_hotkey: "5Dxqzduahnqw8q3XSUfTcEZGU7xmAsfJubhHZwvXVLN9fSjR",
        net_leverage: 0.0,
        open_ms: 1715176398766,
        orders: [
          {
            leverage: -0.08,
            order_type: "SHORT",
            order_uuid: "460d5ac1-4bf8-48c6-8ec4-915a63ad0cc9",
            price: 62118.0,
            price_sources: [],
            processed_ms: 1715176395674,
          },
          {
            leverage: -0.08,
            order_type: "SHORT",
            order_uuid: "c393f8b3-a35b-44cd-a501-fa057e13e703",
            price: 62161.0,
            price_sources: [],
            processed_ms: 1715176504237,
          },
          {
            leverage: -0.08,
            order_type: "SHORT",
            order_uuid: "4585caa8-dccf-4325-8f81-0a9056a9bd2e",
            price: 62437.18,
            price_sources: [],
            processed_ms: 1715176788697,
          },
          {
            leverage: -0.08,
            order_type: "SHORT",
            order_uuid: "0a71cefd-20fb-46d0-9b8c-cb028ff0a266",
            price: 62353.3,
            price_sources: [],
            processed_ms: 1715176893665,
          },
          {
            leverage: 0.16,
            order_type: "LONG",
            order_uuid: "57a1c9c7-a83c-459d-bb41-84d879538e04",
            price: 62152.37,
            price_sources: [],
            processed_ms: 1715177715344,
          },
          {
            leverage: -0.08,
            order_type: "SHORT",
            order_uuid: "0b8c71dc-67fd-48cd-874d-a211871e5afc",
            price: 62644.67,
            price_sources: [],
            processed_ms: 1715178291510,
          },
          {
            leverage: -0.08,
            order_type: "SHORT",
            order_uuid: "0f558645-d6d0-4fe6-9cd9-c3d61349802a",
            price: 62668.0,
            price_sources: [],
            processed_ms: 1715178661767,
          },
          {
            leverage: -0.08,
            order_type: "SHORT",
            order_uuid: "d68a7b97-d871-4d6d-a684-bb180a932b35",
            price: 62715.83,
            price_sources: [],
            processed_ms: 1715179464837,
          },
          {
            leverage: -0.08,
            order_type: "SHORT",
            order_uuid: "8a96d279-cba8-477b-bd81-98ba093fc31b",
            price: 62790.0,
            price_sources: [],
            processed_ms: 1715179749778,
          },
          {
            leverage: 0.24000000000000002,
            order_type: "LONG",
            order_uuid: "22457b77-9667-4d8e-848a-7ec9d0ef4e81",
            price: 62364.24,
            price_sources: [],
            processed_ms: 1715180919953,
          },
          {
            leverage: 0.24000000000000002,
            order_type: "LONG",
            order_uuid: "d122e722-c91a-411e-8e20-254c78668d74",
            price: 62333.48,
            price_sources: [],
            processed_ms: 1715181010473,
          },
        ],
        position_type: "FLAT",
        position_uuid: "acb03046-93ce-4469-b433-55a011161eec",
        return_at_close: 1.001277803340481,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 62444.94000000001,
        close_ms: 1715181546090,
        current_return: 1.0002417235673087,
        initial_entry_price: 62243.0,
        is_closed_position: true,
        miner_hotkey: "5Dxqzduahnqw8q3XSUfTcEZGU7xmAsfJubhHZwvXVLN9fSjR",
        net_leverage: 0.0,
        open_ms: 1715181336875,
        orders: [
          {
            leverage: -0.08,
            order_type: "SHORT",
            order_uuid: "1c541d6b-c83a-468d-8693-7923e1753831",
            price: 62243.0,
            price_sources: [],
            processed_ms: 1715181336430,
          },
          {
            leverage: 0.04,
            order_type: "LONG",
            order_uuid: "48360fab-98e1-40c7-891d-f7c4b6ea0010",
            price: 62041.06,
            price_sources: [],
            processed_ms: 1715181454047,
          },
          {
            leverage: 0.04,
            order_type: "LONG",
            order_uuid: "a78b6161-8989-4ab2-a8bb-270c511efb9c",
            price: 62068.8,
            price_sources: [],
            processed_ms: 1715181546090,
          },
        ],
        position_type: "FLAT",
        position_uuid: "7c61fba4-7e15-4e7d-a3f8-c5437eba5a78",
        return_at_close: 1.0001617042294233,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 61914.98,
        close_ms: 1715181959746,
        current_return: 1.0001019074866857,
        initial_entry_price: 61914.98,
        is_closed_position: true,
        miner_hotkey: "5Dxqzduahnqw8q3XSUfTcEZGU7xmAsfJubhHZwvXVLN9fSjR",
        net_leverage: 0.0,
        open_ms: 1715181636514,
        orders: [
          {
            leverage: 0.04,
            order_type: "LONG",
            order_uuid: "ff8d19ae-04fb-4812-8931-ee8a7e2c4802",
            price: 61914.98,
            price_sources: [],
            processed_ms: 1715181636253,
          },
          {
            leverage: -0.08,
            order_type: "SHORT",
            order_uuid: "054e09df-c83f-412c-b0a1-57ac1b1ed16b",
            price: 62072.72,
            price_sources: [],
            processed_ms: 1715181959746,
          },
        ],
        position_type: "FLAT",
        position_uuid: "b4e7ff9a-0377-4b86-b4f8-0c0e4785c368",
        return_at_close: 1.0000619034103861,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 62994.19000000004,
        close_ms: 1715184403939,
        current_return: 1.0004019835108167,
        initial_entry_price: 62218.97,
        is_closed_position: true,
        miner_hotkey: "5Dxqzduahnqw8q3XSUfTcEZGU7xmAsfJubhHZwvXVLN9fSjR",
        net_leverage: 0.0,
        open_ms: 1715182509306,
        orders: [
          {
            leverage: -0.08,
            order_type: "SHORT",
            order_uuid: "70a2c4bc-16cf-4dc5-86db-2e187ea66505",
            price: 62218.97,
            price_sources: [],
            processed_ms: 1715182509055,
          },
          {
            leverage: -0.08,
            order_type: "SHORT",
            order_uuid: "fd4c53b5-9f1d-4adf-ad8b-3488a756127a",
            price: 62240.25,
            price_sources: [],
            processed_ms: 1715182690131,
          },
          {
            leverage: -0.08,
            order_type: "SHORT",
            order_uuid: "37676d6d-11c7-4d62-a3f9-91f780da0e74",
            price: 62433.34,
            price_sources: [],
            processed_ms: 1715183402643,
          },
          {
            leverage: 0.12,
            order_type: "LONG",
            order_uuid: "44afa247-0ce8-4dff-a58f-99aa9244211b",
            price: 62227.5,
            price_sources: [],
            processed_ms: 1715183827010,
          },
          {
            leverage: 0.06,
            order_type: "LONG",
            order_uuid: "5860d96e-791c-453b-9252-4201db123029",
            price: 62178.32,
            price_sources: [],
            processed_ms: 1715184127490,
          },
          {
            leverage: 0.03,
            order_type: "LONG",
            order_uuid: "df294cbd-cf9c-4296-b4a8-e6223a5b3722",
            price: 62119.33,
            price_sources: [],
            processed_ms: 1715184313260,
          },
          {
            leverage: 0.03,
            order_type: "LONG",
            order_uuid: "dec92ba5-18d8-4b09-8874-d74e0fb83e2b",
            price: 62160.49,
            price_sources: [],
            processed_ms: 1715184403939,
          },
        ],
        position_type: "FLAT",
        position_uuid: "10834521-c156-4bd7-94aa-3396503cbe33",
        return_at_close: 1.0001618870347742,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 62082.03,
        close_ms: 1715184784472,
        current_return: 1.0000089518657815,
        initial_entry_price: 62082.03,
        is_closed_position: true,
        miner_hotkey: "5Dxqzduahnqw8q3XSUfTcEZGU7xmAsfJubhHZwvXVLN9fSjR",
        net_leverage: 0.0,
        open_ms: 1715184587147,
        orders: [
          {
            leverage: 0.015,
            order_type: "LONG",
            order_uuid: "e416f8b6-9573-4eb4-95ec-d9aa39cddfc9",
            price: 62082.03,
            price_sources: [],
            processed_ms: 1715184586910,
          },
          {
            leverage: -0.08,
            order_type: "SHORT",
            order_uuid: "5e751901-b293-41f3-8dea-91ed43aef370",
            price: 62119.08,
            price_sources: [],
            processed_ms: 1715184784472,
          },
        ],
        position_type: "FLAT",
        position_uuid: "a1297ea1-84a7-43fe-9c0f-a58414a5a778",
        return_at_close: 0.9999939517315035,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 62571.869999999995,
        close_ms: 1715195660394,
        current_return: 1.001043742895869,
        initial_entry_price: 62261.31,
        is_closed_position: true,
        miner_hotkey: "5Dxqzduahnqw8q3XSUfTcEZGU7xmAsfJubhHZwvXVLN9fSjR",
        net_leverage: 0.0,
        open_ms: 1715184897290,
        orders: [
          {
            leverage: -0.08,
            order_type: "SHORT",
            order_uuid: "dfc1b6f3-3c17-4c50-83db-408f39ec4341",
            price: 62261.31,
            price_sources: [],
            processed_ms: 1715184896184,
          },
          {
            leverage: -0.08,
            order_type: "SHORT",
            order_uuid: "1be13d30-1d76-4f6d-95df-2a037f847c10",
            price: 62242.26,
            price_sources: [],
            processed_ms: 1715185329411,
          },
          {
            leverage: -0.08,
            order_type: "SHORT",
            order_uuid: "939ca57c-4dde-4a68-b771-ed9867cd57de",
            price: 62415.01,
            price_sources: [],
            processed_ms: 1715187354772,
          },
          {
            leverage: -0.08,
            order_type: "SHORT",
            order_uuid: "e4f6c584-cac6-4507-a2a6-c7567007f4a6",
            price: 62475.88,
            price_sources: [],
            processed_ms: 1715187805984,
          },
          {
            leverage: -0.08,
            order_type: "SHORT",
            order_uuid: "d1bc89f3-faff-436b-b61a-825db01ad02e",
            price: 62583.8,
            price_sources: [],
            processed_ms: 1715188412479,
          },
          {
            leverage: -0.08,
            order_type: "SHORT",
            order_uuid: "f53cb9c0-17d4-4cc9-8cee-30c9321db0e8",
            price: 62658.2,
            price_sources: [],
            processed_ms: 1715192257783,
          },
          {
            leverage: 0.24000000000000002,
            order_type: "LONG",
            order_uuid: "86c6dd03-dc33-4f0e-aea6-f2b58039c118",
            price: 62306.95,
            price_sources: [],
            processed_ms: 1715195570157,
          },
          {
            leverage: 0.24000000000000002,
            order_type: "LONG",
            order_uuid: "c20dbd4c-01fc-4a6f-84fa-6872993b68a3",
            price: 62301.1,
            price_sources: [],
            processed_ms: 1715195660394,
          },
        ],
        position_type: "FLAT",
        position_uuid: "f9f32ac7-165d-4583-93d2-656571078c71",
        return_at_close: 1.000563241899279,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 2902.0699999999997,
        close_ms: 1715224182007,
        current_return: 0.9983847238812518,
        initial_entry_price: 2963.58,
        is_closed_position: true,
        miner_hotkey: "5Dxqzduahnqw8q3XSUfTcEZGU7xmAsfJubhHZwvXVLN9fSjR",
        net_leverage: 0.0,
        open_ms: 1715203492077,
        orders: [
          {
            leverage: -0.1,
            order_type: "SHORT",
            order_uuid: "373f7eda-3c25-4788-be9e-71800aea9988",
            price: 2963.58,
            price_sources: [],
            processed_ms: 1715203492016,
          },
          {
            leverage: -0.1,
            order_type: "SHORT",
            order_uuid: "af947b10-2978-4561-8311-d9e330915969",
            price: 2966.6,
            price_sources: [],
            processed_ms: 1715206329224,
          },
          {
            leverage: 0.05,
            order_type: "LONG",
            order_uuid: "f1a95dca-5517-4bc9-a1de-04d26b5993b9",
            price: 2978.65,
            price_sources: [],
            processed_ms: 1715214558455,
          },
          {
            leverage: 0.05,
            order_type: "LONG",
            order_uuid: "f26aec82-17da-406b-86d0-fd4d05266967",
            price: 2987.51,
            price_sources: [],
            processed_ms: 1715215403897,
          },
          {
            leverage: 0.05,
            order_type: "LONG",
            order_uuid: "f22eb808-6b80-4d7c-afad-67dbc0a90b5a",
            price: 2992.13,
            price_sources: [],
            processed_ms: 1715216866813,
          },
          {
            leverage: 0.05,
            order_type: "FLAT",
            order_uuid: "a7f43532-179a-4687-ac9e-bd6fc58c8e59",
            price: 2997.81,
            price_sources: [],
            processed_ms: 1715224182007,
          },
        ],
        position_type: "FLAT",
        position_uuid: "1820a563-6e7c-4f77-82a1-efeafce38a82",
        return_at_close: 0.9981850469364756,
        trade_pair: ["ETHUSD", "ETH/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 55657.08200000029,
        close_ms: 1715283556303,
        current_return: 1.0053761656640576,
        initial_entry_price: 62302.47,
        is_closed_position: true,
        miner_hotkey: "5Dxqzduahnqw8q3XSUfTcEZGU7xmAsfJubhHZwvXVLN9fSjR",
        net_leverage: 0.0,
        open_ms: 1715195758001,
        orders: [
          {
            leverage: 0.12000000000000001,
            order_type: "LONG",
            order_uuid: "fa3f9162-fd70-4650-8e2e-9bd1cdff3a91",
            price: 62302.47,
            price_sources: [],
            processed_ms: 1715195751798,
          },
          {
            leverage: 0.24000000000000002,
            order_type: "LONG",
            order_uuid: "f96a2067-cb10-4602-b27b-03df43c709f0",
            price: 62313.0,
            price_sources: [],
            processed_ms: 1715196028243,
          },
          {
            leverage: 0.24000000000000002,
            order_type: "LONG",
            order_uuid: "7c5f775c-3444-4b4c-aa39-04a51aefdc95",
            price: 62162.48,
            price_sources: [],
            processed_ms: 1715196121196,
          },
          {
            leverage: 0.1,
            order_type: "LONG",
            order_uuid: "038b03c9-5eeb-4575-a75b-5cd3cd53ef47",
            price: 61667.54,
            price_sources: [],
            processed_ms: 1715200466874,
          },
          {
            leverage: 0.05,
            order_type: "LONG",
            order_uuid: "38f7d884-1cc6-4045-9241-08362a428b24",
            price: 61459.82,
            price_sources: [],
            processed_ms: 1715201179047,
          },
          {
            leverage: 0.05,
            order_type: "LONG",
            order_uuid: "1b11b2bc-2efc-4e7b-bf3d-d9bbe2be7c30",
            price: 61504.67,
            price_sources: [],
            processed_ms: 1715202651698,
          },
          {
            leverage: 0.05,
            order_type: "LONG",
            order_uuid: "576a510d-3660-4511-a666-381ec3655c32",
            price: 61238.63,
            price_sources: [],
            processed_ms: 1715206446504,
          },
          {
            leverage: 0.05,
            order_type: "LONG",
            order_uuid: "4b74571f-f929-4006-b9ad-e098bc334d96",
            price: 61047.85,
            price_sources: [],
            processed_ms: 1715209554263,
          },
          {
            leverage: 0.05,
            order_type: "LONG",
            order_uuid: "ea651236-2ab8-4080-ad5a-7b7efd0ccb8a",
            price: 61304.55,
            price_sources: [],
            processed_ms: 1715214779850,
          },
          {
            leverage: 0.05,
            order_type: "LONG",
            order_uuid: "11ef0d66-101f-40a6-82af-98bdc02eecfd",
            price: 61517.0,
            price_sources: [],
            processed_ms: 1715224422866,
          },
          {
            leverage: -0.05,
            order_type: "SHORT",
            order_uuid: "894e606a-e97b-4174-afef-11f87e2bc3bd",
            price: 62308.0,
            price_sources: [],
            processed_ms: 1715278701478,
          },
          {
            leverage: -0.05,
            order_type: "SHORT",
            order_uuid: "ea60cb3e-076a-45ae-a825-31b4704f2633",
            price: 62251.0,
            price_sources: [],
            processed_ms: 1715279009976,
          },
          {
            leverage: -0.05,
            order_type: "SHORT",
            order_uuid: "39fd2e49-9389-4d79-abec-e942f4da54f6",
            price: 62170.06,
            price_sources: [],
            processed_ms: 1715279160057,
          },
          {
            leverage: -0.05,
            order_type: "SHORT",
            order_uuid: "a4c1575a-1a67-4bdb-92ec-064126adeb87",
            price: 62088.08,
            price_sources: [],
            processed_ms: 1715279639184,
          },
          {
            leverage: -0.05,
            order_type: "SHORT",
            order_uuid: "8378d2b3-9d64-4fd2-b269-c0e95391779e",
            price: 62070.67,
            price_sources: [],
            processed_ms: 1715279807101,
          },
          {
            leverage: -0.05,
            order_type: "SHORT",
            order_uuid: "76448d35-b186-426f-a200-4d98552d28df",
            price: 61981.86,
            price_sources: [],
            processed_ms: 1715280008315,
          },
          {
            leverage: -0.05,
            order_type: "SHORT",
            order_uuid: "84bbfa88-5c2c-4819-9287-6fe0937881ff",
            price: 62158.67,
            price_sources: [],
            processed_ms: 1715280498147,
          },
          {
            leverage: -0.05,
            order_type: "SHORT",
            order_uuid: "1862ea91-27a8-40a1-9439-98af7e84cbc5",
            price: 62348.0,
            price_sources: [],
            processed_ms: 1715280748194,
          },
          {
            leverage: -0.05,
            order_type: "SHORT",
            order_uuid: "f803d30a-6715-4e39-bd18-c2e3f0f9ff84",
            price: 62249.99,
            price_sources: [],
            processed_ms: 1715281164116,
          },
          {
            leverage: -0.05,
            order_type: "SHORT",
            order_uuid: "963eb2c3-f6f4-493a-9b92-a5ca2132eb2e",
            price: 62270.0,
            price_sources: [],
            processed_ms: 1715281385425,
          },
          {
            leverage: -0.05,
            order_type: "SHORT",
            order_uuid: "2b47cbca-4b8e-42e1-a859-92710e380f23",
            price: 62344.81,
            price_sources: [],
            processed_ms: 1715281512968,
          },
          {
            leverage: -0.05,
            order_type: "SHORT",
            order_uuid: "7d7e50e5-9233-4104-8129-743e56655371",
            price: 62224.51,
            price_sources: [],
            processed_ms: 1715281859212,
          },
          {
            leverage: -0.05,
            order_type: "SHORT",
            order_uuid: "127ebf7d-8279-4869-89eb-ab665e354f8f",
            price: 62333.0,
            price_sources: [],
            processed_ms: 1715282035218,
          },
          {
            leverage: -0.05,
            order_type: "SHORT",
            order_uuid: "5b3e9470-9985-473f-8329-d466a4ddc815",
            price: 62216.0,
            price_sources: [],
            processed_ms: 1715282279967,
          },
          {
            leverage: -0.05,
            order_type: "SHORT",
            order_uuid: "3e6185ff-a458-40aa-b73f-7d43ef2b471b",
            price: 62281.68,
            price_sources: [],
            processed_ms: 1715282437359,
          },
          {
            leverage: -0.05,
            order_type: "SHORT",
            order_uuid: "3a87c253-a4ad-4989-a0e8-4a847fc584e0",
            price: 62256.07,
            price_sources: [],
            processed_ms: 1715282603397,
          },
          {
            leverage: -0.05,
            order_type: "SHORT",
            order_uuid: "b28eb6d6-dfc0-45c3-ad3b-49631f28608d",
            price: 62416.0,
            price_sources: [],
            processed_ms: 1715282888354,
          },
          {
            leverage: -0.05,
            order_type: "SHORT",
            order_uuid: "9316319e-1354-4961-bcce-41a8eee80ded",
            price: 62358.35,
            price_sources: [],
            processed_ms: 1715283139189,
          },
          {
            leverage: -0.05,
            order_type: "SHORT",
            order_uuid: "46feb494-a116-47e8-bad3-470899d3a116",
            price: 62432.0,
            price_sources: [],
            processed_ms: 1715283362980,
          },
          {
            leverage: -0.05,
            order_type: "SHORT",
            order_uuid: "19044203-51bb-4ac7-b6aa-16968aef6870",
            price: 62356.05,
            price_sources: [],
            processed_ms: 1715283556303,
          },
        ],
        position_type: "FLAT",
        position_uuid: "5b83ccf8-baed-4c14-a433-19cf2fde6390",
        return_at_close: 1.0043707894983935,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 105.1859999999996,
        close_ms: 1715289368213,
        current_return: 1.0004637702218349,
        initial_entry_price: 113.553,
        is_closed_position: true,
        miner_hotkey: "5Dxqzduahnqw8q3XSUfTcEZGU7xmAsfJubhHZwvXVLN9fSjR",
        net_leverage: 0.0,
        open_ms: 1715288400481,
        orders: [
          {
            leverage: 0.05,
            order_type: "LONG",
            order_uuid: "72b24aa9-684c-4d5c-aafc-095af6a3a7cb",
            price: 113.553,
            price_sources: [],
            processed_ms: 1715288400104,
          },
          {
            leverage: 0.05,
            order_type: "LONG",
            order_uuid: "ee57c7e2-c188-4c56-b6bd-1745c833f47d",
            price: 112.735,
            price_sources: [],
            processed_ms: 1715288496119,
          },
          {
            leverage: -0.05,
            order_type: "SHORT",
            order_uuid: "c6add1ac-2536-4e70-aff7-501f204dfbf0",
            price: 113.68,
            price_sources: [],
            processed_ms: 1715288746131,
          },
          {
            leverage: -0.025,
            order_type: "SHORT",
            order_uuid: "15a98b68-d798-4d85-aa2d-99f67a4ce9c2",
            price: 113.68,
            price_sources: [],
            processed_ms: 1715288917291,
          },
          {
            leverage: -0.0125,
            order_type: "SHORT",
            order_uuid: "c20b0a16-0764-493c-b8f2-ce1c9cca644c",
            price: 113.66,
            price_sources: [],
            processed_ms: 1715289047215,
          },
          {
            leverage: -0.00625,
            order_type: "SHORT",
            order_uuid: "de33118e-b51d-40a4-8474-49b05f0c7bec",
            price: 113.638,
            price_sources: [],
            processed_ms: 1715289213138,
          },
          {
            leverage: 0.00625,
            order_type: "FLAT",
            order_uuid: "92ae03ee-80eb-46cd-8126-752cbe97bcc4",
            price: 113.612,
            price_sources: [],
            processed_ms: 1715289368213,
          },
        ],
        position_type: "FLAT",
        position_uuid: "bdf84df6-412f-4f5e-885a-65029c181abe",
        return_at_close: 1.0004567669754434,
        trade_pair: ["CADJPY", "CAD/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.6605600000000004,
        close_ms: 1715290165222,
        current_return: 1.000055532049926,
        initial_entry_price: 0.66178,
        is_closed_position: true,
        miner_hotkey: "5Dxqzduahnqw8q3XSUfTcEZGU7xmAsfJubhHZwvXVLN9fSjR",
        net_leverage: 0.0,
        open_ms: 1715288765511,
        orders: [
          {
            leverage: 0.05,
            order_type: "LONG",
            order_uuid: "19d6da86-41b6-47e0-a165-073d9fb1b519",
            price: 0.66178,
            price_sources: [],
            processed_ms: 1715288765235,
          },
          {
            leverage: 0.05,
            order_type: "LONG",
            order_uuid: "ca51ab4c-3936-4b77-a1ce-9bb1fa71dc5e",
            price: 0.66134,
            price_sources: [],
            processed_ms: 1715289275211,
          },
          {
            leverage: -0.05,
            order_type: "SHORT",
            order_uuid: "9195d763-f7f9-432e-8eb6-55def7afad1f",
            price: 0.66199,
            price_sources: [],
            processed_ms: 1715289781150,
          },
          {
            leverage: -0.025,
            order_type: "SHORT",
            order_uuid: "8e44216f-2f7a-452c-9466-200445f8e219",
            price: 0.6617,
            price_sources: [],
            processed_ms: 1715290019676,
          },
          {
            leverage: -0.025,
            order_type: "SHORT",
            order_uuid: "7c329d0b-f3d4-45d6-ab49-3822c1b2110b",
            price: 0.66203,
            price_sources: [],
            processed_ms: 1715290165222,
          },
        ],
        position_type: "FLAT",
        position_uuid: "5ec43308-f5c1-46e0-bd36-0d5dcee38bdf",
        return_at_close: 1.0000485316612018,
        trade_pair: ["CADCHF", "CAD/CHF", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.66191,
        close_ms: 1715291376152,
        current_return: 0.9999967895937514,
        initial_entry_price: 0.66191,
        is_closed_position: true,
        miner_hotkey: "5Dxqzduahnqw8q3XSUfTcEZGU7xmAsfJubhHZwvXVLN9fSjR",
        net_leverage: 0.0,
        open_ms: 1715290286621,
        orders: [
          {
            leverage: -0.0125,
            order_type: "SHORT",
            order_uuid: "f7c02eeb-39dc-4a8a-801e-28e85b2edd3b",
            price: 0.66191,
            price_sources: [],
            processed_ms: 1715290286156,
          },
          {
            leverage: 0.05,
            order_type: "LONG",
            order_uuid: "d0484c14-de55-46d0-84db-05205e18a1bc",
            price: 0.66208,
            price_sources: [],
            processed_ms: 1715291376152,
          },
        ],
        position_type: "FLAT",
        position_uuid: "41eacfad-19c8-4178-84be-951b634b68fa",
        return_at_close: 0.9999959145965605,
        trade_pair: ["CADCHF", "CAD/CHF", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 16.38785999999998,
        close_ms: 1715293256163,
        current_return: 1.0001521821730839,
        initial_entry_price: 16.778,
        is_closed_position: true,
        miner_hotkey: "5Dxqzduahnqw8q3XSUfTcEZGU7xmAsfJubhHZwvXVLN9fSjR",
        net_leverage: 0.0,
        open_ms: 1715283682264,
        orders: [
          {
            leverage: 0.1,
            order_type: "LONG",
            order_uuid: "fa88ef07-552d-46e0-b4a6-444807dd0fdb",
            price: 16.778,
            price_sources: [],
            processed_ms: 1715283681919,
          },
          {
            leverage: 0.1,
            order_type: "LONG",
            order_uuid: "8af6c6fb-fd95-430e-b7e8-eea5dcad3da7",
            price: 16.7763,
            price_sources: [],
            processed_ms: 1715286510920,
          },
          {
            leverage: -0.1,
            order_type: "SHORT",
            order_uuid: "1d240ce2-d12e-4883-a550-276bd66b03f8",
            price: 16.78785,
            price_sources: [],
            processed_ms: 1715288012126,
          },
          {
            leverage: -0.05,
            order_type: "SHORT",
            order_uuid: "c756e483-ec20-43c6-b0cb-483a767fb50f",
            price: 16.79274,
            price_sources: [],
            processed_ms: 1715291676138,
          },
          {
            leverage: -0.025,
            order_type: "SHORT",
            order_uuid: "ec829702-557d-4836-80d6-c42b47fcbcfb",
            price: 16.79193,
            price_sources: [],
            processed_ms: 1715292838105,
          },
          {
            leverage: -0.0125,
            order_type: "SHORT",
            order_uuid: "07885d31-e273-44f0-b848-c8d43d59924c",
            price: 16.791,
            price_sources: [],
            processed_ms: 1715292972158,
          },
          {
            leverage: -0.00625,
            order_type: "SHORT",
            order_uuid: "f1cbf87f-f121-49e1-b5c8-8a7b3cee0906",
            price: 16.7837,
            price_sources: [],
            processed_ms: 1715293093083,
          },
          {
            leverage: 0.00625,
            order_type: "FLAT",
            order_uuid: "3b5630a1-edb0-4f10-a4ab-2c9856a11b22",
            price: 16.79639,
            price_sources: [],
            processed_ms: 1715293256163,
          },
        ],
        position_type: "FLAT",
        position_uuid: "f31b88bf-6885-405a-8921-ab047079685e",
        return_at_close: 1.0001381800425335,
        trade_pair: ["USDMXN", "USD/MXN", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 62424.592500000006,
        close_ms: 1715296175252,
        current_return: 0.9976960856219358,
        initial_entry_price: 62445.68,
        is_closed_position: true,
        miner_hotkey: "5Dxqzduahnqw8q3XSUfTcEZGU7xmAsfJubhHZwvXVLN9fSjR",
        net_leverage: 0.0,
        open_ms: 1715285766010,
        orders: [
          {
            leverage: -0.05,
            order_type: "SHORT",
            order_uuid: "9a7906f2-1d45-4aa3-b218-1e9858a1db6e",
            price: 62445.68,
            price_sources: [],
            processed_ms: 1715285765047,
          },
          {
            leverage: -0.05,
            order_type: "SHORT",
            order_uuid: "796cfd2a-a151-49a7-9c38-2e84b35efe04",
            price: 62400.79,
            price_sources: [],
            processed_ms: 1715285968420,
          },
          {
            leverage: -0.05,
            order_type: "SHORT",
            order_uuid: "20792c4d-1c72-4c6b-a1f3-1f94bee23c5e",
            price: 62404.49,
            price_sources: [],
            processed_ms: 1715286333245,
          },
          {
            leverage: -0.05,
            order_type: "SHORT",
            order_uuid: "39617849-76d5-4483-895b-017ae072a2b3",
            price: 62447.41,
            price_sources: [],
            processed_ms: 1715286951184,
          },
          {
            leverage: 0.1,
            order_type: "FLAT",
            order_uuid: "c45fe055-49aa-4c48-82d7-912b66b8c0b0",
            price: 63143.94,
            price_sources: [],
            processed_ms: 1715296175252,
          },
        ],
        position_type: "FLAT",
        position_uuid: "02d8b21b-a293-42dc-88ec-961f3d38aab5",
        return_at_close: 0.9974965464048114,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 0.6502229411764687,
        close_ms: 1715300766649,
        current_return: 1.0001228949151455,
        initial_entry_price: 0.66231,
        is_closed_position: true,
        miner_hotkey: "5Dxqzduahnqw8q3XSUfTcEZGU7xmAsfJubhHZwvXVLN9fSjR",
        net_leverage: 0.0,
        open_ms: 1715293506399,
        orders: [
          {
            leverage: 0.05,
            order_type: "LONG",
            order_uuid: "3bb3dd64-4927-4023-b82e-d94b28a5af26",
            price: 0.66231,
            price_sources: [],
            processed_ms: 1715293506169,
          },
          {
            leverage: -0.025,
            order_type: "SHORT",
            order_uuid: "815c2980-463c-4953-9e3f-d79b8dfad644",
            price: 0.66234,
            price_sources: [],
            processed_ms: 1715293634116,
          },
          {
            leverage: -0.0125,
            order_type: "SHORT",
            order_uuid: "43b6c578-e446-4af7-b29b-e5cd8b867785",
            price: 0.66234,
            price_sources: [],
            processed_ms: 1715293757231,
          },
          {
            leverage: 0.05,
            order_type: "LONG",
            order_uuid: "cf3df7d9-8805-42c8-97c3-891b479a17dd",
            price: 0.66233,
            price_sources: [],
            processed_ms: 1715294068352,
          },
          {
            leverage: 0.05,
            order_type: "LONG",
            order_uuid: "7e2ca9ca-f110-4ba6-afaa-bbed26c67df3",
            price: 0.6623,
            price_sources: [],
            processed_ms: 1715294695177,
          },
          {
            leverage: 0.05,
            order_type: "LONG",
            order_uuid: "cb6665fb-81c1-4407-9ac6-e10ad5379b9c",
            price: 0.6617,
            price_sources: [],
            processed_ms: 1715294996206,
          },
          {
            leverage: 0.05,
            order_type: "LONG",
            order_uuid: "07e990e7-26c7-4c86-bbef-795d3facab02",
            price: 0.6618,
            price_sources: [],
            processed_ms: 1715295326196,
          },
          {
            leverage: -0.10625000000000001,
            order_type: "SHORT",
            order_uuid: "6e9affc3-fe50-4138-883b-6403db04c02f",
            price: 0.66237,
            price_sources: [],
            processed_ms: 1715299856172,
          },
          {
            leverage: -0.053125000000000006,
            order_type: "SHORT",
            order_uuid: "057bacd1-1654-4ab8-bb04-412efcd5483f",
            price: 0.66249,
            price_sources: [],
            processed_ms: 1715300109154,
          },
          {
            leverage: -0.026562500000000003,
            order_type: "SHORT",
            order_uuid: "f8119df2-98d1-458d-b3cb-316842ce6bd1",
            price: 0.66248,
            price_sources: [],
            processed_ms: 1715300232084,
          },
          {
            leverage: -0.013281250000000001,
            order_type: "SHORT",
            order_uuid: "8cac325c-8bd8-4426-8c7d-82bfb7d3c8b8",
            price: 0.66247,
            price_sources: [],
            processed_ms: 1715300500201,
          },
          {
            leverage: -0.006640625000000001,
            order_type: "SHORT",
            order_uuid: "8f473ae9-c7b6-4868-833c-904d81fb7905",
            price: 0.66247,
            price_sources: [],
            processed_ms: 1715300635122,
          },
          {
            leverage: 0.006640625000000001,
            order_type: "FLAT",
            order_uuid: "ec9c2133-7efd-43f7-827d-2d7a729688e3",
            price: 0.66248,
            price_sources: [],
            processed_ms: 1715300766649,
          },
        ],
        position_type: "FLAT",
        position_uuid: "85df7d22-17c0-4388-8ac4-32d2e5765570",
        return_at_close: 1.0001053927644845,
        trade_pair: ["CADCHF", "CAD/CHF", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 16.770450000000004,
        close_ms: 1715305812157,
        current_return: 1.0001859032201386,
        initial_entry_price: 16.78965,
        is_closed_position: true,
        miner_hotkey: "5Dxqzduahnqw8q3XSUfTcEZGU7xmAsfJubhHZwvXVLN9fSjR",
        net_leverage: 0.0,
        open_ms: 1715295224394,
        orders: [
          {
            leverage: 0.05,
            order_type: "LONG",
            order_uuid: "e26775bf-0a15-44c8-8cbd-a530ddb608df",
            price: 16.78965,
            price_sources: [],
            processed_ms: 1715295224157,
          },
          {
            leverage: 0.05,
            order_type: "LONG",
            order_uuid: "7f9bb9be-b658-4825-9a08-47b1c97dc58c",
            price: 16.784,
            price_sources: [],
            processed_ms: 1715295741175,
          },
          {
            leverage: 0.05,
            order_type: "LONG",
            order_uuid: "d3f056f8-16a6-44e9-a9ca-ca27095070b6",
            price: 16.78425,
            price_sources: [],
            processed_ms: 1715296444524,
          },
          {
            leverage: 0.05,
            order_type: "LONG",
            order_uuid: "df90b0ab-4ede-494a-8158-2e43f1d095b5",
            price: 16.787,
            price_sources: [],
            processed_ms: 1715297328317,
          },
          {
            leverage: 0.05,
            order_type: "LONG",
            order_uuid: "9cee2d59-265d-42c7-a8fc-d75df2611f74",
            price: 16.7686,
            price_sources: [],
            processed_ms: 1715303377151,
          },
          {
            leverage: -0.125,
            order_type: "SHORT",
            order_uuid: "61b6617b-865e-401a-aece-1fb2daa00944",
            price: 16.79495,
            price_sources: [],
            processed_ms: 1715305687703,
          },
          {
            leverage: -0.125,
            order_type: "SHORT",
            order_uuid: "a1c08cd8-aa01-4a10-9d7e-c23248ac9c8c",
            price: 16.79542,
            price_sources: [],
            processed_ms: 1715305812157,
          },
        ],
        position_type: "FLAT",
        position_uuid: "063d5b49-ce01-4634-9a2f-3321cd91e26a",
        return_at_close: 1.0001683999668323,
        trade_pair: ["USDMXN", "USD/MXN", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 1.0958491141363709,
        close_ms: 1715307069116,
        current_return: 1.0002209924010246,
        initial_entry_price: 1.09691,
        is_closed_position: true,
        miner_hotkey: "5Dxqzduahnqw8q3XSUfTcEZGU7xmAsfJubhHZwvXVLN9fSjR",
        net_leverage: 0.0,
        open_ms: 1715287958514,
        orders: [
          {
            leverage: 0.05,
            order_type: "LONG",
            order_uuid: "b7beba14-f7d4-4f8d-a969-4403c2c57dfd",
            price: 1.09691,
            price_sources: [],
            processed_ms: 1715287958172,
          },
          {
            leverage: 0.05,
            order_type: "LONG",
            order_uuid: "6e37c1b2-e534-4e74-a577-e49ca6bf2a57",
            price: 1.09682,
            price_sources: [],
            processed_ms: 1715288122156,
          },
          {
            leverage: 0.05,
            order_type: "LONG",
            order_uuid: "2d723362-3223-4292-925f-121beb45cb39",
            price: 1.0964,
            price_sources: [],
            processed_ms: 1715291765091,
          },
          {
            leverage: 0.05,
            order_type: "LONG",
            order_uuid: "6d630685-bb0c-4fe8-8e0d-b4aa37e4d2a0",
            price: 1.09668,
            price_sources: [],
            processed_ms: 1715292324133,
          },
          {
            leverage: 0.05,
            order_type: "LONG",
            order_uuid: "805fd3fa-6cf7-4d36-82a7-064ae4ab2d62",
            price: 1.096438363422032,
            price_sources: [],
            processed_ms: 1715292712154,
          },
          {
            leverage: 0.05,
            order_type: "LONG",
            order_uuid: "9a598480-e89d-4578-b101-bf57c6b9d1db",
            price: 1.09649,
            price_sources: [],
            processed_ms: 1715293480186,
          },
          {
            leverage: 0.05,
            order_type: "LONG",
            order_uuid: "294ad3a1-88fb-4b15-b797-a40b2dcdda82",
            price: 1.09609,
            price_sources: [],
            processed_ms: 1715298840345,
          },
          {
            leverage: -0.175,
            order_type: "SHORT",
            order_uuid: "98cc9564-892f-4a49-ad7a-e0d29079fea8",
            price: 1.09724470398421,
            price_sources: [],
            processed_ms: 1715306948760,
          },
          {
            leverage: -0.175,
            order_type: "SHORT",
            order_uuid: "9ad11371-45b7-44ff-afc0-0e8a6ef57fd8",
            price: 1.09723430713413,
            price_sources: [],
            processed_ms: 1715307069116,
          },
        ],
        position_type: "FLAT",
        position_uuid: "e0566b3d-61be-4c52-a5a0-5843a9766fec",
        return_at_close: 1.0001964869867108,
        trade_pair: ["AUDNZD", "AUD/NZD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 16.77299,
        close_ms: 1715307706155,
        current_return: 0.9999791542505087,
        initial_entry_price: 16.79599,
        is_closed_position: true,
        miner_hotkey: "5Dxqzduahnqw8q3XSUfTcEZGU7xmAsfJubhHZwvXVLN9fSjR",
        net_leverage: 0.0,
        open_ms: 1715305935402,
        orders: [
          {
            leverage: -0.0625,
            order_type: "SHORT",
            order_uuid: "45ca7eab-7ea4-4fba-af83-63f2d594fe01",
            price: 16.79599,
            price_sources: [],
            processed_ms: 1715305935100,
          },
          {
            leverage: 0.05,
            order_type: "LONG",
            order_uuid: "192d4140-6ee4-45e6-8800-7af2d4754459",
            price: 16.80174,
            price_sources: [],
            processed_ms: 1715307401120,
          },
          {
            leverage: 0.05,
            order_type: "LONG",
            order_uuid: "adb9956e-8d32-406d-93c1-2d1b46ae6852",
            price: 16.801,
            price_sources: [],
            processed_ms: 1715307706155,
          },
        ],
        position_type: "FLAT",
        position_uuid: "f3f2e987-4b1f-4e89-910f-831dc3755ba7",
        return_at_close: 0.9999747793417089,
        trade_pair: ["USDMXN", "USD/MXN", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 16.670826666666656,
        close_ms: 1715316076133,
        current_return: 1.0000696238319147,
        initial_entry_price: 16.801,
        is_closed_position: true,
        miner_hotkey: "5Dxqzduahnqw8q3XSUfTcEZGU7xmAsfJubhHZwvXVLN9fSjR",
        net_leverage: 0.0,
        open_ms: 1715310958065,
        orders: [
          {
            leverage: 0.05,
            order_type: "LONG",
            order_uuid: "564e109d-ce1a-48f6-ba49-52d614edeade",
            price: 16.801,
            price_sources: [],
            processed_ms: 1715310955627,
          },
          {
            leverage: 0.05,
            order_type: "LONG",
            order_uuid: "2e79bffb-3a63-47f2-9a8f-f89e93302b58",
            price: 16.791,
            price_sources: [],
            processed_ms: 1715311718161,
          },
          {
            leverage: 0.05,
            order_type: "LONG",
            order_uuid: "0873c326-d89e-42f7-99e3-cfbd183c9825",
            price: 16.795,
            price_sources: [],
            processed_ms: 1715314492276,
          },
          {
            leverage: -0.07500000000000001,
            order_type: "SHORT",
            order_uuid: "390cc2e5-a853-47c3-8bb7-e64bb890690b",
            price: 16.801,
            price_sources: [],
            processed_ms: 1715315476238,
          },
          {
            leverage: -0.037500000000000006,
            order_type: "SHORT",
            order_uuid: "98c95ec7-7809-4834-9cfd-b72114234e16",
            price: 16.80643,
            price_sources: [],
            processed_ms: 1715315641164,
          },
          {
            leverage: -0.018750000000000003,
            order_type: "SHORT",
            order_uuid: "0541db19-d829-4ab8-9464-f7ba55640c82",
            price: 16.80885,
            price_sources: [],
            processed_ms: 1715315781166,
          },
          {
            leverage: -0.009375000000000001,
            order_type: "SHORT",
            order_uuid: "929888da-5ace-4870-9fdb-9460a57dd6fe",
            price: 16.80842,
            price_sources: [],
            processed_ms: 1715315923133,
          },
          {
            leverage: 0.009375000000000001,
            order_type: "FLAT",
            order_uuid: "f4794a93-b5ee-4fed-8e9a-22b4fbd814b1",
            price: 16.7956,
            price_sources: [],
            processed_ms: 1715316076133,
          },
        ],
        position_type: "FLAT",
        position_uuid: "e700c025-fa27-426e-9c72-75182cedb6df",
        return_at_close: 1.0000591231008644,
        trade_pair: ["USDMXN", "USD/MXN", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 16.766885999999996,
        close_ms: 1715324899117,
        current_return: 1.000160627120662,
        initial_entry_price: 16.799,
        is_closed_position: true,
        miner_hotkey: "5Dxqzduahnqw8q3XSUfTcEZGU7xmAsfJubhHZwvXVLN9fSjR",
        net_leverage: 0.0,
        open_ms: 1715319883145,
        orders: [
          {
            leverage: 0.05,
            order_type: "LONG",
            order_uuid: "47965af7-9b67-492f-8320-81d76c6197b5",
            price: 16.799,
            price_sources: [],
            processed_ms: 1715319878222,
          },
          {
            leverage: 0.05,
            order_type: "LONG",
            order_uuid: "c142ebdd-8f5a-49dc-b92d-d398abb4f852",
            price: 16.804,
            price_sources: [],
            processed_ms: 1715320666325,
          },
          {
            leverage: 0.05,
            order_type: "LONG",
            order_uuid: "1fbe33b2-e879-45da-b839-cfc8b59dbe36",
            price: 16.7977,
            price_sources: [],
            processed_ms: 1715321018636,
          },
          {
            leverage: 0.05,
            order_type: "LONG",
            order_uuid: "db1f328d-7a4b-47bd-b502-d01389961a9e",
            price: 16.79734,
            price_sources: [],
            processed_ms: 1715321410721,
          },
          {
            leverage: 0.05,
            order_type: "LONG",
            order_uuid: "a6030ec6-3069-451f-b6b6-95391a65cd50",
            price: 16.79663,
            price_sources: [],
            processed_ms: 1715321884339,
          },
          {
            leverage: -0.125,
            order_type: "SHORT",
            order_uuid: "8f8c2f46-2f02-41d0-b636-570aaafd1e15",
            price: 16.80975,
            price_sources: [],
            processed_ms: 1715323904177,
          },
          {
            leverage: -0.0625,
            order_type: "SHORT",
            order_uuid: "831f98ab-7ccf-472b-a06a-94d32cf2402f",
            price: 16.80935,
            price_sources: [],
            processed_ms: 1715324779508,
          },
          {
            leverage: -0.0625,
            order_type: "SHORT",
            order_uuid: "77b9294d-7793-4814-9bdc-6f7a1c9b22f1",
            price: 16.81006,
            price_sources: [],
            processed_ms: 1715324899117,
          },
        ],
        position_type: "FLAT",
        position_uuid: "73fe361e-9b3f-4a35-b89a-7081d17f9dd7",
        return_at_close: 1.0001431243096874,
        trade_pair: ["USDMXN", "USD/MXN", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.6462935802469126,
        close_ms: 1715325239153,
        current_return: 1.0001899433374974,
        initial_entry_price: 0.66242,
        is_closed_position: true,
        miner_hotkey: "5Dxqzduahnqw8q3XSUfTcEZGU7xmAsfJubhHZwvXVLN9fSjR",
        net_leverage: 0.0,
        open_ms: 1715302606063,
        orders: [
          {
            leverage: 0.05,
            order_type: "LONG",
            order_uuid: "fe9bbea2-ea88-4b43-b8c2-af5c67fd0e56",
            price: 0.66242,
            price_sources: [],
            processed_ms: 1715302605714,
          },
          {
            leverage: 0.05,
            order_type: "LONG",
            order_uuid: "74241a8b-b328-4d26-b814-51fc72372d20",
            price: 0.6619,
            price_sources: [],
            processed_ms: 1715302768128,
          },
          {
            leverage: 0.05,
            order_type: "LONG",
            order_uuid: "06101240-bf8b-491d-a4b7-42c40b8b05ff",
            price: 0.66237,
            price_sources: [],
            processed_ms: 1715304064160,
          },
          {
            leverage: -0.07500000000000001,
            order_type: "SHORT",
            order_uuid: "7076fe1a-8a5f-4b18-9cad-8fe89da067cd",
            price: 0.66266,
            price_sources: [],
            processed_ms: 1715305447705,
          },
          {
            leverage: -0.037500000000000006,
            order_type: "SHORT",
            order_uuid: "df009540-2777-44e9-9196-90f89bd8eafa",
            price: 0.66266,
            price_sources: [],
            processed_ms: 1715305751171,
          },
          {
            leverage: 0.05,
            order_type: "LONG",
            order_uuid: "de159f69-3a37-4475-b975-eef11a6b43d5",
            price: 0.66246,
            price_sources: [],
            processed_ms: 1715306928159,
          },
          {
            leverage: 0.05,
            order_type: "LONG",
            order_uuid: "e5d01979-a89f-4341-afa7-1580a97b0818",
            price: 0.66245,
            price_sources: [],
            processed_ms: 1715307229183,
          },
          {
            leverage: 0.05,
            order_type: "LONG",
            order_uuid: "762bb23f-0c46-466d-85dd-72d5570ff13e",
            price: 0.66242,
            price_sources: [],
            processed_ms: 1715308362230,
          },
          {
            leverage: 0.05,
            order_type: "LONG",
            order_uuid: "4fdb5289-7ec3-4087-ae21-38801bc63185",
            price: 0.66241,
            price_sources: [],
            processed_ms: 1715308762092,
          },
          {
            leverage: 0.05,
            order_type: "LONG",
            order_uuid: "99a2abd3-973c-40a5-b459-b9a511b73eda",
            price: 0.66228,
            price_sources: [],
            processed_ms: 1715313244180,
          },
          {
            leverage: 0.05,
            order_type: "LONG",
            order_uuid: "40a21bd3-005d-4e92-9a34-3738699d9cb8",
            price: 0.6617,
            price_sources: [],
            processed_ms: 1715314056195,
          },
          {
            leverage: -0.16874999999999998,
            order_type: "SHORT",
            order_uuid: "f398e3b8-9eee-43e2-a2fe-d006e607f914",
            price: 0.6624,
            price_sources: [],
            processed_ms: 1715323607147,
          },
          {
            leverage: -0.042187499999999996,
            order_type: "SHORT",
            order_uuid: "91e35a6c-9db6-453b-b59c-11325b5c6ba5",
            price: 0.66269,
            price_sources: [],
            processed_ms: 1715324014183,
          },
          {
            leverage: -0.06328125,
            order_type: "SHORT",
            order_uuid: "ea3b05fe-63f1-4d75-82f8-ce8df8747d01",
            price: 0.66268,
            price_sources: [],
            processed_ms: 1715324181148,
          },
          {
            leverage: -0.031640625,
            order_type: "SHORT",
            order_uuid: "693fbad6-77c7-4d54-812b-d20d351d886d",
            price: 0.6627,
            price_sources: [],
            processed_ms: 1715324320726,
          },
          {
            leverage: -0.0158203125,
            order_type: "SHORT",
            order_uuid: "d7debc2c-96f6-4b53-8393-bb4aecbfc005",
            price: 0.6623,
            price_sources: [],
            processed_ms: 1715324561248,
          },
          {
            leverage: -0.00791015625,
            order_type: "SHORT",
            order_uuid: "4bd7afe4-ba87-4fe6-b772-312a2126d75c",
            price: 0.6625,
            price_sources: [],
            processed_ms: 1715325109161,
          },
          {
            leverage: 0.00791015625,
            order_type: "FLAT",
            order_uuid: "ad8ff26c-c72c-40c9-a6e0-d69e0e5ec011",
            price: 0.6622,
            price_sources: [],
            processed_ms: 1715325239153,
          },
        ],
        position_type: "FLAT",
        position_uuid: "1268e01f-d2bc-437a-af3c-cf1b8d9390ab",
        return_at_close: 1.0001584373542824,
        trade_pair: ["CADCHF", "CAD/CHF", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 1.0490071329457096,
        close_ms: 1715325398112,
        current_return: 1.0002433896908338,
        initial_entry_price: 1.097231114383618,
        is_closed_position: true,
        miner_hotkey: "5Dxqzduahnqw8q3XSUfTcEZGU7xmAsfJubhHZwvXVLN9fSjR",
        net_leverage: 0.0,
        open_ms: 1715307807918,
        orders: [
          {
            leverage: 0.05,
            order_type: "LONG",
            order_uuid: "309f4546-47e7-4c0c-825a-32325e082e61",
            price: 1.097231114383618,
            price_sources: [],
            processed_ms: 1715307807676,
          },
          {
            leverage: 0.05,
            order_type: "LONG",
            order_uuid: "1995d537-521c-4627-82c1-adf0b0ff876a",
            price: 1.0971,
            price_sources: [],
            processed_ms: 1715308743160,
          },
          {
            leverage: 0.05,
            order_type: "LONG",
            order_uuid: "99293d89-be99-4a27-a778-2d5316dfe04a",
            price: 1.097175454581468,
            price_sources: [],
            processed_ms: 1715309058641,
          },
          {
            leverage: 0.05,
            order_type: "LONG",
            order_uuid: "8424e384-6b85-4716-bc4e-1bc2b6cd9ca3",
            price: 1.09719,
            price_sources: [],
            processed_ms: 1715309436147,
          },
          {
            leverage: 0.05,
            order_type: "LONG",
            order_uuid: "2d25bfe2-3143-468f-b398-eb6af82bf877",
            price: 1.09708,
            price_sources: [],
            processed_ms: 1715312560694,
          },
          {
            leverage: 0.05,
            order_type: "LONG",
            order_uuid: "e3270604-e116-4150-920e-d0a3f8031fbd",
            price: 1.09691,
            price_sources: [],
            processed_ms: 1715312890637,
          },
          {
            leverage: 0.05,
            order_type: "LONG",
            order_uuid: "7f4b56dd-7a1b-4b99-8801-e1e03b460cbd",
            price: 1.097,
            price_sources: [],
            processed_ms: 1715313193211,
          },
          {
            leverage: -0.175,
            order_type: "SHORT",
            order_uuid: "73c4b41b-1432-43b9-bfd4-da13474c648a",
            price: 1.0977,
            price_sources: [],
            processed_ms: 1715324550238,
          },
          {
            leverage: -0.0875,
            order_type: "SHORT",
            order_uuid: "08921aea-54d4-4522-8d5e-73afec99fcfd",
            price: 1.09799,
            price_sources: [],
            processed_ms: 1715324672121,
          },
          {
            leverage: -0.04375,
            order_type: "SHORT",
            order_uuid: "ab8b73cb-b863-4227-9511-8e1fb13b51e3",
            price: 1.09818,
            price_sources: [],
            processed_ms: 1715324792324,
          },
          {
            leverage: -0.021875,
            order_type: "SHORT",
            order_uuid: "ce0ef9ce-e294-44e7-9023-fb2dcbd46faf",
            price: 1.098027822717906,
            price_sources: [],
            processed_ms: 1715325034106,
          },
          {
            leverage: -0.0109375,
            order_type: "SHORT",
            order_uuid: "b5883739-81fc-4860-976a-e281a61b95c6",
            price: 1.0978,
            price_sources: [],
            processed_ms: 1715325155119,
          },
          {
            leverage: -0.00546875,
            order_type: "SHORT",
            order_uuid: "a4667268-0130-4c3f-b37c-bce73c576987",
            price: 1.097878778149164,
            price_sources: [],
            processed_ms: 1715325277260,
          },
          {
            leverage: 0.00546875,
            order_type: "FLAT",
            order_uuid: "00ee3f10-5657-4641-b8fb-f60e39f9f824",
            price: 1.09784,
            price_sources: [],
            processed_ms: 1715325398112,
          },
        ],
        position_type: "FLAT",
        position_uuid: "a4dc2cca-0d50-409c-a885-517c5acf09c0",
        return_at_close: 1.0002188837277863,
        trade_pair: ["AUDNZD", "AUD/NZD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 1.0747721779942214,
        close_ms: 1715330598078,
        current_return: 1.0001182406354154,
        initial_entry_price: 1.09818,
        is_closed_position: true,
        miner_hotkey: "5Dxqzduahnqw8q3XSUfTcEZGU7xmAsfJubhHZwvXVLN9fSjR",
        net_leverage: 0.0,
        open_ms: 1715327254477,
        orders: [
          {
            leverage: 0.05,
            order_type: "LONG",
            order_uuid: "fa664839-b6d4-4bbc-ba4b-41f3ee16d4bc",
            price: 1.09818,
            price_sources: [],
            processed_ms: 1715327254081,
          },
          {
            leverage: 0.05,
            order_type: "LONG",
            order_uuid: "a9c6fd6f-2376-4277-a403-d479837d8726",
            price: 1.09826,
            price_sources: [],
            processed_ms: 1715327500149,
          },
          {
            leverage: 0.05,
            order_type: "LONG",
            order_uuid: "ba2af5e7-3239-4f3c-aca7-ca6b8eda42d9",
            price: 1.0973,
            price_sources: [],
            processed_ms: 1715327814098,
          },
          {
            leverage: -0.07500000000000001,
            order_type: "SHORT",
            order_uuid: "88aa339e-a33c-487a-ae7d-4c5fadb9e342",
            price: 1.09845,
            price_sources: [],
            processed_ms: 1715328279231,
          },
          {
            leverage: 0.05,
            order_type: "LONG",
            order_uuid: "bbffe167-0a35-4a53-97f9-b8e9d76a1d02",
            price: 1.098250158784497,
            price_sources: [],
            processed_ms: 1715328594107,
          },
          {
            leverage: 0.05,
            order_type: "LONG",
            order_uuid: "b1c07691-8e86-48cd-a184-67765d8667cb",
            price: 1.09824,
            price_sources: [],
            processed_ms: 1715328912156,
          },
          {
            leverage: -0.0875,
            order_type: "SHORT",
            order_uuid: "351509ca-856c-4c97-882c-ea5f4d30dafa",
            price: 1.09855,
            price_sources: [],
            processed_ms: 1715329218168,
          },
          {
            leverage: -0.04375,
            order_type: "SHORT",
            order_uuid: "7a2428e7-de5c-42f9-ae9e-2b63967ff574",
            price: 1.09862,
            price_sources: [],
            processed_ms: 1715329348118,
          },
          {
            leverage: -0.021875,
            order_type: "SHORT",
            order_uuid: "02f860fb-2c78-4782-8bf0-473c11e1eec5",
            price: 1.09874,
            price_sources: [],
            processed_ms: 1715329534154,
          },
          {
            leverage: -0.0109375,
            order_type: "SHORT",
            order_uuid: "56a91a93-6cc2-4766-9b4f-92e61a8eaaff",
            price: 1.098812791428288,
            price_sources: [],
            processed_ms: 1715329928254,
          },
          {
            leverage: -0.00546875,
            order_type: "SHORT",
            order_uuid: "4b15091a-17b9-4284-a359-df017ad2cb10",
            price: 1.09881511946461,
            price_sources: [],
            processed_ms: 1715330473111,
          },
          {
            leverage: 0.00546875,
            order_type: "FLAT",
            order_uuid: "f176f478-995c-424d-bc17-96f4cc1ebaa3",
            price: 1.098516086748593,
            price_sources: [],
            processed_ms: 1715330598078,
          },
        ],
        position_type: "FLAT",
        position_uuid: "048a3a58-52e4-4ccb-a33b-93c80b4cc110",
        return_at_close: 1.0001007385662044,
        trade_pair: ["AUDNZD", "AUD/NZD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.6459885714285849,
        close_ms: 1715339653093,
        current_return: 1.0001382511733545,
        initial_entry_price: 0.66263,
        is_closed_position: true,
        miner_hotkey: "5Dxqzduahnqw8q3XSUfTcEZGU7xmAsfJubhHZwvXVLN9fSjR",
        net_leverage: 0.0,
        open_ms: 1715327042415,
        orders: [
          {
            leverage: 0.05,
            order_type: "LONG",
            order_uuid: "66be25d2-40a8-4341-8b7d-ded1f85dcaad",
            price: 0.66263,
            price_sources: [],
            processed_ms: 1715327042170,
          },
          {
            leverage: 0.05,
            order_type: "LONG",
            order_uuid: "10db53f7-80ec-48dc-9fc8-91a19b0aebe8",
            price: 0.66258,
            price_sources: [],
            processed_ms: 1715327154145,
          },
          {
            leverage: 0.05,
            order_type: "LONG",
            order_uuid: "db095fe0-a4d6-47c1-8672-4c47982744e3",
            price: 0.66235,
            price_sources: [],
            processed_ms: 1715327549181,
          },
          {
            leverage: 0.05,
            order_type: "LONG",
            order_uuid: "83f066a6-0305-4be0-b86c-e8e6a1fc2857",
            price: 0.66243,
            price_sources: [],
            processed_ms: 1715328173155,
          },
          {
            leverage: 0.05,
            order_type: "LONG",
            order_uuid: "c2f8013a-6c17-4dd1-8074-eccd9be05b93",
            price: 0.662,
            price_sources: [],
            processed_ms: 1715328489123,
          },
          {
            leverage: 0.05,
            order_type: "LONG",
            order_uuid: "f0da9d36-642e-459f-93f9-4f1c8fa0b3a8",
            price: 0.66215,
            price_sources: [],
            processed_ms: 1715329705106,
          },
          {
            leverage: 0.05,
            order_type: "LONG",
            order_uuid: "b0b6edaa-7cf0-4d75-80b8-80d5fe16d481",
            price: 0.66195,
            price_sources: [],
            processed_ms: 1715330228119,
          },
          {
            leverage: -0.175,
            order_type: "SHORT",
            order_uuid: "e08211e6-fa68-44e4-b19e-89fe8fe555b3",
            price: 0.6627,
            price_sources: [],
            processed_ms: 1715338686188,
          },
          {
            leverage: -0.0875,
            order_type: "SHORT",
            order_uuid: "cf6ec43f-550d-407f-8850-a3c26a102ffa",
            price: 0.6621,
            price_sources: [],
            processed_ms: 1715339034143,
          },
          {
            leverage: -0.04375,
            order_type: "SHORT",
            order_uuid: "75aa5519-3cf8-4ad9-b585-4d84847b2ac5",
            price: 0.66276,
            price_sources: [],
            processed_ms: 1715339158150,
          },
          {
            leverage: -0.021875,
            order_type: "SHORT",
            order_uuid: "1b45fc0f-677a-4d27-abc1-8789c3ad8296",
            price: 0.66272,
            price_sources: [],
            processed_ms: 1715339280215,
          },
          {
            leverage: -0.0109375,
            order_type: "SHORT",
            order_uuid: "cc257d7c-7b39-470b-9fe9-8939366513da",
            price: 0.66273,
            price_sources: [],
            processed_ms: 1715339403490,
          },
          {
            leverage: -0.00546875,
            order_type: "SHORT",
            order_uuid: "2c71f54b-c1e5-4613-a93b-f2c4155f7d99",
            price: 0.6627,
            price_sources: [],
            processed_ms: 1715339531178,
          },
          {
            leverage: 0.00546875,
            order_type: "FLAT",
            order_uuid: "99293376-b1e3-49a9-9939-e010b0c9192e",
            price: 0.66274,
            price_sources: [],
            processed_ms: 1715339653093,
          },
        ],
        position_type: "FLAT",
        position_uuid: "2308c304-266b-4aa6-bda9-ab928ce77159",
        return_at_close: 1.0001137477862008,
        trade_pair: ["CADCHF", "CAD/CHF", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.6051200000000032,
        close_ms: 1715344933653,
        current_return: 1.0005626197263828,
        initial_entry_price: 0.66297,
        is_closed_position: true,
        miner_hotkey: "5Dxqzduahnqw8q3XSUfTcEZGU7xmAsfJubhHZwvXVLN9fSjR",
        net_leverage: 0.0,
        open_ms: 1715341473422,
        orders: [
          {
            leverage: 0.05,
            order_type: "LONG",
            order_uuid: "803a1be5-ec0a-49e6-8a6d-36d56e6f0015",
            price: 0.66297,
            price_sources: [],
            processed_ms: 1715341473174,
          },
          {
            leverage: 0.05,
            order_type: "LONG",
            order_uuid: "199360d7-ed05-4390-ad7a-bc891449f6f4",
            price: 0.66296,
            price_sources: [],
            processed_ms: 1715341574791,
          },
          {
            leverage: 0.05,
            order_type: "LONG",
            order_uuid: "33dda4b4-1be3-49d1-818d-71f4f1b3efa4",
            price: 0.6625,
            price_sources: [],
            processed_ms: 1715341657192,
          },
          {
            leverage: 0.05,
            order_type: "LONG",
            order_uuid: "fd9c01af-c97d-4cef-ab82-99c4575732e5",
            price: 0.66285,
            price_sources: [],
            processed_ms: 1715342045111,
          },
          {
            leverage: -0.1,
            order_type: "SHORT",
            order_uuid: "9a47700b-ef37-4e6a-acce-8d82eab03c4b",
            price: 0.66477,
            price_sources: [],
            processed_ms: 1715344326164,
          },
          {
            leverage: -0.05,
            order_type: "SHORT",
            order_uuid: "58b25842-fda4-437f-bf3c-d2f722ad70cc",
            price: 0.6644,
            price_sources: [],
            processed_ms: 1715344447248,
          },
          {
            leverage: -0.025,
            order_type: "SHORT",
            order_uuid: "d1c890f5-af2a-447c-b4eb-5c3509e0360e",
            price: 0.66473,
            price_sources: [],
            processed_ms: 1715344568152,
          },
          {
            leverage: -0.0125,
            order_type: "SHORT",
            order_uuid: "ca8e4c73-68fe-4860-bc05-809970911f75",
            price: 0.66492,
            price_sources: [],
            processed_ms: 1715344689140,
          },
          {
            leverage: -0.00625,
            order_type: "SHORT",
            order_uuid: "e6225696-8924-402a-87cd-53657154b9e2",
            price: 0.66484,
            price_sources: [],
            processed_ms: 1715344810155,
          },
          {
            leverage: 0.00625,
            order_type: "FLAT",
            order_uuid: "71cd4a1b-61d8-45de-a784-7f038b6480d1",
            price: 0.6648,
            price_sources: [],
            processed_ms: 1715344933653,
          },
        ],
        position_type: "FLAT",
        position_uuid: "912de617-b42f-4344-8a4d-45ca9d44b9fb",
        return_at_close: 1.0005486118497067,
        trade_pair: ["CADCHF", "CAD/CHF", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 15.72058271186483,
        close_ms: 1715360783136,
        current_return: 1.0003633487564765,
        initial_entry_price: 16.80105,
        is_closed_position: true,
        miner_hotkey: "5Dxqzduahnqw8q3XSUfTcEZGU7xmAsfJubhHZwvXVLN9fSjR",
        net_leverage: 0.0,
        open_ms: 1715325963482,
        orders: [
          {
            leverage: 0.05,
            order_type: "LONG",
            order_uuid: "ea2fff44-dbb9-43a4-9fc5-88ab27cbe340",
            price: 16.80105,
            price_sources: [],
            processed_ms: 1715325963235,
          },
          {
            leverage: 0.05,
            order_type: "LONG",
            order_uuid: "987dca8c-95e4-4bd0-8e86-630d8b2733c6",
            price: 16.79565,
            price_sources: [],
            processed_ms: 1715326244321,
          },
          {
            leverage: 0.05,
            order_type: "LONG",
            order_uuid: "5448a929-fbcc-4ce6-bac2-acee082781d4",
            price: 16.792,
            price_sources: [],
            processed_ms: 1715326550311,
          },
          {
            leverage: 0.05,
            order_type: "LONG",
            order_uuid: "84a3be11-690c-4bc3-94c9-f45c79d015a2",
            price: 16.781,
            price_sources: [],
            processed_ms: 1715327400692,
          },
          {
            leverage: 0.05,
            order_type: "LONG",
            order_uuid: "00a0fa9d-0133-42df-96d7-da216f0de61f",
            price: 16.76598,
            price_sources: [],
            processed_ms: 1715329024139,
          },
          {
            leverage: 0.05,
            order_type: "LONG",
            order_uuid: "1c9b279c-8650-4e57-a026-6db125df19f1",
            price: 16.7721,
            price_sources: [],
            processed_ms: 1715329349167,
          },
          {
            leverage: 0.05,
            order_type: "LONG",
            order_uuid: "c32a1917-b224-48a7-a711-655f061e1345",
            price: 16.77327,
            price_sources: [],
            processed_ms: 1715332338131,
          },
          {
            leverage: 0.05,
            order_type: "LONG",
            order_uuid: "b96412b6-9353-4951-9866-782c3a0dd876",
            price: 16.76491,
            price_sources: [],
            processed_ms: 1715332811137,
          },
          {
            leverage: 0.05,
            order_type: "LONG",
            order_uuid: "6fe1aaa1-f13e-478a-8bc2-00a334d3392e",
            price: 16.746,
            price_sources: [],
            processed_ms: 1715333117212,
          },
          {
            leverage: 0.05,
            order_type: "LONG",
            order_uuid: "f4a4c37c-27ed-4b8d-8d3f-d4ff4c94c565",
            price: 16.75577,
            price_sources: [],
            processed_ms: 1715333861513,
          },
          {
            leverage: 0.05,
            order_type: "LONG",
            order_uuid: "31ee8e8a-8232-488e-b8e0-5f0ff19af871",
            price: 16.7375,
            price_sources: [],
            processed_ms: 1715334559169,
          },
          {
            leverage: -0.27499999999999997,
            order_type: "SHORT",
            order_uuid: "216afb2a-9db2-4070-b79b-db3ed125856d",
            price: 16.78144,
            price_sources: [],
            processed_ms: 1715357562224,
          },
          {
            leverage: -0.13749999999999998,
            order_type: "SHORT",
            order_uuid: "6538fb75-3b5e-4b2e-82b7-21604772dbaa",
            price: 16.78205,
            price_sources: [],
            processed_ms: 1715357809142,
          },
          {
            leverage: -0.06874999999999999,
            order_type: "SHORT",
            order_uuid: "db879a10-dee1-4709-b3d2-c71ca9c1f59c",
            price: 16.77969,
            price_sources: [],
            processed_ms: 1715357953124,
          },
          {
            leverage: -0.034374999999999996,
            order_type: "SHORT",
            order_uuid: "c07a93e4-83ef-4780-98b4-30e7b9014f35",
            price: 16.7729,
            price_sources: [],
            processed_ms: 1715358073782,
          },
          {
            leverage: 0.05,
            order_type: "LONG",
            order_uuid: "55046f66-a1c6-4e6a-8439-e0af86dd628a",
            price: 16.77232,
            price_sources: [],
            processed_ms: 1715358481721,
          },
          {
            leverage: 0.05,
            order_type: "LONG",
            order_uuid: "58ba085d-a3a9-4a33-bd07-d1708fd48925",
            price: 16.7566,
            price_sources: [],
            processed_ms: 1715358871227,
          },
          {
            leverage: 0.05,
            order_type: "LONG",
            order_uuid: "ba1dc284-f53c-470d-a351-82d24bb49b75",
            price: 16.76743,
            price_sources: [],
            processed_ms: 1715359359534,
          },
          {
            leverage: -0.0921875,
            order_type: "SHORT",
            order_uuid: "76a843a5-3db6-4589-9daa-8eb01de1c5b4",
            price: 16.77206,
            price_sources: [],
            processed_ms: 1715359561102,
          },
          {
            leverage: -0.04609375,
            order_type: "SHORT",
            order_uuid: "b4360c1d-563a-491a-b9ad-e261eb9a6999",
            price: 16.77162,
            price_sources: [],
            processed_ms: 1715359881151,
          },
          {
            leverage: -0.023046875,
            order_type: "SHORT",
            order_uuid: "ae5b4c12-9daf-40d1-9f58-6f30a363cec7",
            price: 16.7774,
            price_sources: [],
            processed_ms: 1715360154153,
          },
          {
            leverage: -0.0115234375,
            order_type: "SHORT",
            order_uuid: "9ca146b9-edde-4b09-8b18-3eb3ea16892c",
            price: 16.77685,
            price_sources: [],
            processed_ms: 1715360506673,
          },
          {
            leverage: -0.00576171875,
            order_type: "SHORT",
            order_uuid: "63f824ad-6120-438d-92f0-6e4b910f4d95",
            price: 16.7774,
            price_sources: [],
            processed_ms: 1715360655152,
          },
          {
            leverage: 0.00576171875,
            order_type: "FLAT",
            order_uuid: "32efc001-a407-419a-abfe-8054d5c65d30",
            price: 16.7801,
            price_sources: [],
            processed_ms: 1715360783136,
          },
        ],
        position_type: "FLAT",
        position_uuid: "304d1e4c-5f01-445c-88bf-0e7b781118b8",
        return_at_close: 1.0003143309523874,
        trade_pair: ["USDMXN", "USD/MXN", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 16.599956666666635,
        close_ms: 1715365726332,
        current_return: 1.00010425608811,
        initial_entry_price: 16.7745,
        is_closed_position: true,
        miner_hotkey: "5Dxqzduahnqw8q3XSUfTcEZGU7xmAsfJubhHZwvXVLN9fSjR",
        net_leverage: 0.0,
        open_ms: 1715362686254,
        orders: [
          {
            leverage: 0.05,
            order_type: "LONG",
            order_uuid: "ca76d04b-6274-4ea9-b55f-9e5baf87fba0",
            price: 16.7745,
            price_sources: [],
            processed_ms: 1715362682347,
          },
          {
            leverage: 0.05,
            order_type: "LONG",
            order_uuid: "fe2236e2-a25b-4a0b-ab5d-a148c368d290",
            price: 16.7668,
            price_sources: [],
            processed_ms: 1715362969153,
          },
          {
            leverage: 0.05,
            order_type: "LONG",
            order_uuid: "50764f4d-0b1d-470f-b43a-adad31566882",
            price: 16.777,
            price_sources: [],
            processed_ms: 1715363648126,
          },
          {
            leverage: -0.07500000000000001,
            order_type: "SHORT",
            order_uuid: "407585ee-405a-46dc-a093-4951fca33155",
            price: 16.78481,
            price_sources: [],
            processed_ms: 1715364687704,
          },
          {
            leverage: -0.037500000000000006,
            order_type: "SHORT",
            order_uuid: "ad4c525e-188e-456b-85c7-458d20c7bce7",
            price: 16.78363,
            price_sources: [],
            processed_ms: 1715364818464,
          },
          {
            leverage: -0.018750000000000003,
            order_type: "SHORT",
            order_uuid: "a353ff90-0abb-4832-ab45-c6d61e435ce2",
            price: 16.784,
            price_sources: [],
            processed_ms: 1715364950301,
          },
          {
            leverage: -0.009375000000000001,
            order_type: "SHORT",
            order_uuid: "9dabbd99-e1c8-46b5-832c-c61d09866748",
            price: 16.78331,
            price_sources: [],
            processed_ms: 1715365231157,
          },
          {
            leverage: 0.009375000000000001,
            order_type: "FLAT",
            order_uuid: "bdaad099-c8fb-4ea8-98b1-6afb0fd80641",
            price: 16.7865,
            price_sources: [],
            processed_ms: 1715365726332,
          },
        ],
        position_type: "FLAT",
        position_uuid: "48f11b73-bcd0-4d0a-9c38-08cb01778312",
        return_at_close: 1.000093754993421,
        trade_pair: ["USDMXN", "USD/MXN", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.9995264951000015,
        close_ms: 1715570410653,
        current_return: 1.000506435072895,
        initial_entry_price: 1.0982,
        is_closed_position: true,
        miner_hotkey: "5Dxqzduahnqw8q3XSUfTcEZGU7xmAsfJubhHZwvXVLN9fSjR",
        net_leverage: 0.0,
        open_ms: 1715332475607,
        orders: [
          {
            leverage: 0.05,
            order_type: "LONG",
            order_uuid: "08568d71-8e25-439d-8144-dd4414a41d10",
            price: 1.0982,
            price_sources: [],
            processed_ms: 1715332475138,
          },
          {
            leverage: 0.05,
            order_type: "LONG",
            order_uuid: "e48b35cb-74ed-484e-83f0-6c8cfaf27831",
            price: 1.09824,
            price_sources: [],
            processed_ms: 1715332600708,
          },
          {
            leverage: 0.05,
            order_type: "LONG",
            order_uuid: "9af66fa9-d4ef-49b5-b7cf-5272ffbca566",
            price: 1.09836,
            price_sources: [],
            processed_ms: 1715333184882,
          },
          {
            leverage: 0.05,
            order_type: "LONG",
            order_uuid: "0ae116c1-17f0-439e-8dc2-61da91931217",
            price: 1.098284694450871,
            price_sources: [],
            processed_ms: 1715333537135,
          },
          {
            leverage: 0.05,
            order_type: "LONG",
            order_uuid: "7c202658-4371-470f-848a-ba9eda5175e2",
            price: 1.098120054497807,
            price_sources: [],
            processed_ms: 1715334347096,
          },
          {
            leverage: 0.05,
            order_type: "LONG",
            order_uuid: "0650a651-4ca6-485f-b4a3-6d83c641cdb6",
            price: 1.09812,
            price_sources: [],
            processed_ms: 1715335462234,
          },
          {
            leverage: -0.15,
            order_type: "SHORT",
            order_uuid: "691513cc-968b-4603-8c67-b0b1e3072475",
            price: 1.09878,
            price_sources: [],
            processed_ms: 1715336720236,
          },
          {
            leverage: -0.075,
            order_type: "SHORT",
            order_uuid: "9f080aad-348c-44b8-b050-475773e1f724",
            price: 1.098863986248582,
            price_sources: [],
            processed_ms: 1715336844699,
          },
          {
            leverage: -0.0375,
            order_type: "SHORT",
            order_uuid: "606df983-1e73-4701-b868-5fea5f206273",
            price: 1.09893,
            price_sources: [],
            processed_ms: 1715336967151,
          },
          {
            leverage: -0.009375,
            order_type: "SHORT",
            order_uuid: "4f4502c6-845d-41d2-b6e9-e5fbd9a3c50f",
            price: 1.09892,
            price_sources: [],
            processed_ms: 1715337222138,
          },
          {
            leverage: -0.014062499999999999,
            order_type: "SHORT",
            order_uuid: "7a0f75ee-3201-4ed5-b9b5-1483276b91e8",
            price: 1.098827431433066,
            price_sources: [],
            processed_ms: 1715337346105,
          },
          {
            leverage: -0.007031249999999999,
            order_type: "SHORT",
            order_uuid: "2afdf3f3-2b58-452a-a728-f7ff4a15e129",
            price: 1.098971462216416,
            price_sources: [],
            processed_ms: 1715337473435,
          },
          {
            leverage: 0.05,
            order_type: "LONG",
            order_uuid: "9681c29f-936c-40eb-a7f6-b8ced4dff3b7",
            price: 1.09875,
            price_sources: [],
            processed_ms: 1715337786124,
          },
          {
            leverage: 0.05,
            order_type: "LONG",
            order_uuid: "72f09659-1b2d-4fac-a745-131a711fc6ef",
            price: 1.09859,
            price_sources: [],
            processed_ms: 1715338175131,
          },
          {
            leverage: 0.05,
            order_type: "LONG",
            order_uuid: "3a0ae2ef-ba48-4d37-91da-f58753ddc5c2",
            price: 1.098618638466623,
            price_sources: [],
            processed_ms: 1715338478143,
          },
          {
            leverage: 0.05,
            order_type: "LONG",
            order_uuid: "df13116f-284f-430a-9124-1a7836b5259a",
            price: 1.098284725067065,
            price_sources: [],
            processed_ms: 1715339151717,
          },
          {
            leverage: 0.05,
            order_type: "LONG",
            order_uuid: "b3b953eb-0e72-4694-b095-bf90954ff25c",
            price: 1.09837875859392,
            price_sources: [],
            processed_ms: 1715340097795,
          },
          {
            leverage: 0.05,
            order_type: "LONG",
            order_uuid: "98cd9da6-0194-4605-a002-9eaeb39d49d2",
            price: 1.098301849193716,
            price_sources: [],
            processed_ms: 1715340644107,
          },
          {
            leverage: 0.05,
            order_type: "LONG",
            order_uuid: "7f950080-45c7-418e-9774-c452681df6b8",
            price: 1.09801,
            price_sources: [],
            processed_ms: 1715344700202,
          },
          {
            leverage: 0.05,
            order_type: "LONG",
            order_uuid: "dd2efe45-bb7e-49e6-b33e-6c4a52948e90",
            price: 1.09772,
            price_sources: [],
            processed_ms: 1715348058181,
          },
          {
            leverage: 0.05,
            order_type: "LONG",
            order_uuid: "c25d084b-5f2a-49db-a107-60e87a01f572",
            price: 1.09725,
            price_sources: [],
            processed_ms: 1715349448118,
          },
          {
            leverage: 0.05,
            order_type: "LONG",
            order_uuid: "b425c1c0-31fe-4057-ac9c-fd60ffb7f59d",
            price: 1.096891178424452,
            price_sources: [],
            processed_ms: 1715349750126,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "20da9795-03da-4fbb-a1f7-103126ef70d7",
            price: 1.09733,
            price_sources: [],
            processed_ms: 1715369698106,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "df50df81-bfc2-402d-8e42-1b363000f9bc",
            price: 1.09723,
            price_sources: [],
            processed_ms: 1715370009713,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "26da8b64-a20f-4753-9076-949025e41aa5",
            price: 1.096991373671327,
            price_sources: [],
            processed_ms: 1715370313453,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "2c973760-ff36-4e36-98b2-9a913b610368",
            price: 1.0962,
            price_sources: [],
            processed_ms: 1715370991186,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "e0ec7ba3-1ed9-4321-adad-f01f697f7eac",
            price: 1.097,
            price_sources: [],
            processed_ms: 1715371677219,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "2b8d0a34-9560-43ae-b6a7-5df146ac63ed",
            price: 1.09707,
            price_sources: [],
            processed_ms: 1715372012255,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "e4e11896-cd8f-4d41-80c0-24a8e1614307",
            price: 1.09698,
            price_sources: [],
            processed_ms: 1715372358673,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "cd84b55e-56c8-4cdf-98ca-84f86def72c7",
            price: 1.09687139072061,
            price_sources: [],
            processed_ms: 1715372743739,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "4131a996-fd1b-4c15-95d7-ee1422ea20da",
            price: 1.09686,
            price_sources: [],
            processed_ms: 1715373068296,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "c4ef6e32-4021-4606-b834-512351481f13",
            price: 1.09678,
            price_sources: [],
            processed_ms: 1715373408133,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "6d01dd6c-02a3-4b1e-972b-ed2879cea80f",
            price: 1.09696,
            price_sources: [],
            processed_ms: 1715374040655,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "17770d8d-b94d-4f8b-9531-0f558eb10f05",
            price: 1.09686,
            price_sources: [],
            processed_ms: 1715374352085,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "3940731a-681a-4b1d-b9de-ee57e3222635",
            price: 1.09683,
            price_sources: [],
            processed_ms: 1715374682158,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "16789c65-0a2a-4608-a1b1-19d60b238c84",
            price: 1.0952,
            price_sources: [],
            processed_ms: 1715566840975,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "1f3cde20-22d6-470c-903d-23e6aa56ac38",
            price: 1.09613,
            price_sources: [],
            processed_ms: 1715567181730,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "a2ad7c38-087f-4c33-98e9-eee355327cef",
            price: 1.09642,
            price_sources: [],
            processed_ms: 1715567512833,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "8c65ae84-fcd5-4a74-a16c-c2fe3cd60962",
            price: 1.096627215732589,
            price_sources: [],
            processed_ms: 1715567816308,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "3cbb5e17-55f7-47d8-be58-ca185c9f60fe",
            price: 1.09655,
            price_sources: [],
            processed_ms: 1715568159200,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "5a31e298-ad5f-4e8f-a17e-8a8471bc4059",
            price: 1.09671,
            price_sources: [],
            processed_ms: 1715568490929,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "eade998d-2a58-4107-afe0-90f7c51d9481",
            price: 1.09664,
            price_sources: [],
            processed_ms: 1715568862929,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "a6647800-3a64-4b38-9ec8-1c70d2691dd7",
            price: 1.09668,
            price_sources: [],
            processed_ms: 1715569170447,
          },
          {
            leverage: -0.35851562500000006,
            order_type: "SHORT",
            order_uuid: "fea058cf-3ed9-4303-a4fe-558ca3875d08",
            price: 1.097947330682406,
            price_sources: [],
            processed_ms: 1715569311283,
          },
          {
            leverage: -0.08962890625000001,
            order_type: "SHORT",
            order_uuid: "3d9976d0-63d1-4ff0-96f7-b643b97f67cc",
            price: 1.09822,
            price_sources: [],
            processed_ms: 1715569553397,
          },
          {
            leverage: -0.04481445312500001,
            order_type: "SHORT",
            order_uuid: "6d6afacc-cb0d-4e3a-9288-96934866b2f3",
            price: 1.09835,
            price_sources: [],
            processed_ms: 1715569674724,
          },
          {
            leverage: -0.13444335937500002,
            order_type: "SHORT",
            order_uuid: "38ac544d-64ff-47a3-9a0a-93c9fb1b554a",
            price: 1.09858,
            price_sources: [],
            processed_ms: 1715569806192,
          },
          {
            leverage: -0.06722167968750001,
            order_type: "SHORT",
            order_uuid: "9e8e7102-b77c-46be-af73-34b232716015",
            price: 1.09852,
            price_sources: [],
            processed_ms: 1715569926204,
          },
          {
            leverage: -0.011203613281249997,
            order_type: "SHORT",
            order_uuid: "fed24cf5-085e-4d6f-8394-c92afb5a3837",
            price: 1.098729419481691,
            price_sources: [],
            processed_ms: 1715570049061,
          },
          {
            leverage: -0.005601806640624998,
            order_type: "SHORT",
            order_uuid: "2803eca8-da8f-45ea-b53f-9f84a1230a2f",
            price: 1.09897,
            price_sources: [],
            processed_ms: 1715570169556,
          },
          {
            leverage: 0.005601806640624998,
            order_type: "FLAT",
            order_uuid: "a0e0d867-3127-431e-8bb6-3f6221d0d6f0",
            price: 1.09881,
            price_sources: [],
            processed_ms: 1715570410653,
          },
        ],
        position_type: "FLAT",
        position_uuid: "145b6b4c-5c90-493a-8d64-354f38ccaaf6",
        return_at_close: 1.0004356992679353,
        trade_pair: ["AUDNZD", "AUD/NZD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 1.0935087426737067,
        close_ms: 1715582484409,
        current_return: 1.0000824866341063,
        initial_entry_price: 1.098190947532606,
        is_closed_position: true,
        miner_hotkey: "5Dxqzduahnqw8q3XSUfTcEZGU7xmAsfJubhHZwvXVLN9fSjR",
        net_leverage: 0.0,
        open_ms: 1715572214373,
        orders: [
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "35d32cec-efb0-4d13-b4ce-5ea847931d94",
            price: 1.098190947532606,
            price_sources: [],
            processed_ms: 1715572213088,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "588dd698-c7c2-4512-b509-14b9cdded823",
            price: 1.09798,
            price_sources: [],
            processed_ms: 1715572751195,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "8c64aeee-3c47-410d-8c5a-164202732438",
            price: 1.0981,
            price_sources: [],
            processed_ms: 1715573082204,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "36d4d06d-6123-4c5b-bd53-5688f586f551",
            price: 1.09793,
            price_sources: [],
            processed_ms: 1715573404362,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "8228f23f-c037-48ed-89a4-863869d85aef",
            price: 1.09793,
            price_sources: [],
            processed_ms: 1715573729344,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "87a2620c-1388-47f2-a793-59ae9f0d9f57",
            price: 1.09797,
            price_sources: [],
            processed_ms: 1715574035674,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "61991e4c-943c-494e-b426-933c60e33353",
            price: 1.09796,
            price_sources: [],
            processed_ms: 1715574348588,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "9117e3a3-4aed-41fa-bdd7-e155a504f6b3",
            price: 1.09787,
            price_sources: [],
            processed_ms: 1715574958070,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "63845755-a1ce-4271-95c9-98b1b2120624",
            price: 1.09784,
            price_sources: [],
            processed_ms: 1715575266973,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "fe83a2be-8784-409e-92f1-7bfca4f41c18",
            price: 1.09797,
            price_sources: [],
            processed_ms: 1715575588488,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "371c2218-5b3c-4d9f-b83a-7de432b569c7",
            price: 1.097868242619318,
            price_sources: [],
            processed_ms: 1715575903314,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "d7f1bb88-1eba-477f-999b-e2c5260b8887",
            price: 1.09771,
            price_sources: [],
            processed_ms: 1715576217917,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "e8c00114-d8c2-4041-bffb-43c52ec56301",
            price: 1.097767713182327,
            price_sources: [],
            processed_ms: 1715576521438,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "783f7a98-0de8-44b8-8988-4e04feeefb08",
            price: 1.097,
            price_sources: [],
            processed_ms: 1715577230040,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "f1bf8ac9-5cfd-4497-9ecd-561b99471383",
            price: 1.09766,
            price_sources: [],
            processed_ms: 1715577866424,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "2ae2d90e-6980-451b-abe1-1d94ac76661f",
            price: 1.09771,
            price_sources: [],
            processed_ms: 1715578174849,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "4b63f2db-fe25-4a30-8f12-5ca4da57e86d",
            price: 1.097696343032501,
            price_sources: [],
            processed_ms: 1715578481220,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "31166c38-3c0a-43a2-93bd-3fb27d9d63cd",
            price: 1.09762,
            price_sources: [],
            processed_ms: 1715578809084,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "140edcdd-facd-49b2-a5bd-4ef36664b83b",
            price: 1.09766,
            price_sources: [],
            processed_ms: 1715579143368,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "ac780400-698b-46dc-aa73-100bc3a9a40d",
            price: 1.09792,
            price_sources: [],
            processed_ms: 1715579491965,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "98aae862-1664-436e-ab59-1c4a05c736ad",
            price: 1.097563296182071,
            price_sources: [],
            processed_ms: 1715579832948,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "2280131b-cb31-46d1-b728-e9670d02d813",
            price: 1.097946099964383,
            price_sources: [],
            processed_ms: 1715580857366,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "c63e46ef-1ea6-4a88-a39b-10530d2f205b",
            price: 1.09812,
            price_sources: [],
            processed_ms: 1715581180901,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "f10fcb13-6a0b-41c6-b150-acfbab40feb5",
            price: 1.09807,
            price_sources: [],
            processed_ms: 1715581492006,
          },
          {
            leverage: -0.06000000000000002,
            order_type: "SHORT",
            order_uuid: "62b78cb4-d997-4a5c-a138-264e2707b654",
            price: 1.09841,
            price_sources: [],
            processed_ms: 1715581756746,
          },
          {
            leverage: -0.03000000000000001,
            order_type: "SHORT",
            order_uuid: "bef96d53-77ac-4c5c-81e5-cfacc32472de",
            price: 1.09854,
            price_sources: [],
            processed_ms: 1715581878362,
          },
          {
            leverage: -0.07500000000000002,
            order_type: "SHORT",
            order_uuid: "ff2f6f0b-68ec-408a-a4ed-fdc47ed43d4a",
            price: 1.0978,
            price_sources: [],
            processed_ms: 1715582000417,
          },
          {
            leverage: -0.03750000000000001,
            order_type: "SHORT",
            order_uuid: "5aa5b7de-4b32-4978-92b3-aec24877d113",
            price: 1.09836,
            price_sources: [],
            processed_ms: 1715582123031,
          },
          {
            leverage: -0.018750000000000006,
            order_type: "SHORT",
            order_uuid: "b53e1108-2106-4763-8a66-6013e1e33e85",
            price: 1.09829,
            price_sources: [],
            processed_ms: 1715582243903,
          },
          {
            leverage: 0.009375000000000003,
            order_type: "FLAT",
            order_uuid: "b71471f3-d524-4920-ad80-3d9620d7d641",
            price: 1.09834,
            price_sources: [],
            processed_ms: 1715582484409,
          },
        ],
        position_type: "FLAT",
        position_uuid: "e563ee7f-b095-4194-8970-9b3b62ec26e7",
        return_at_close: 1.0000656852483307,
        trade_pair: ["AUDNZD", "AUD/NZD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 1.0971083838008786,
        close_ms: 1715584772304,
        current_return: 1.0000076112399412,
        initial_entry_price: 1.098123441149689,
        is_closed_position: true,
        miner_hotkey: "5Dxqzduahnqw8q3XSUfTcEZGU7xmAsfJubhHZwvXVLN9fSjR",
        net_leverage: 0.0,
        open_ms: 1715584297704,
        orders: [
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "f15ec95a-9fb0-4259-a38b-11a9c0b7b7e9",
            price: 1.098123441149689,
            price_sources: [],
            processed_ms: 1715584295165,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "76d206c2-0dc7-4221-8291-c2658cc482c5",
            price: 1.09825,
            price_sources: [],
            processed_ms: 1715584360559,
          },
          {
            leverage: -0.01,
            order_type: "SHORT",
            order_uuid: "81f99dea-d967-430a-9097-c5e6040ab253",
            price: 1.09857,
            price_sources: [],
            processed_ms: 1715584530551,
          },
          {
            leverage: -0.005,
            order_type: "SHORT",
            order_uuid: "c78f305d-3eb0-466d-aa5e-1a2ca58b4915",
            price: 1.098498498498499,
            price_sources: [],
            processed_ms: 1715584651745,
          },
          {
            leverage: 0.005,
            order_type: "FLAT",
            order_uuid: "3b100898-a02d-4f18-b90f-320028cb7e7b",
            price: 1.09878,
            price_sources: [],
            processed_ms: 1715584772304,
          },
        ],
        position_type: "FLAT",
        position_uuid: "e732536e-70ab-4850-9121-6717a77c71b6",
        return_at_close: 1.0000062112292853,
        trade_pair: ["AUDNZD", "AUD/NZD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 1.097093984940103,
        close_ms: 1715588763436,
        current_return: 1.0000140928342849,
        initial_entry_price: 1.098882740587156,
        is_closed_position: true,
        miner_hotkey: "5Dxqzduahnqw8q3XSUfTcEZGU7xmAsfJubhHZwvXVLN9fSjR",
        net_leverage: 0.0,
        open_ms: 1715587144504,
        orders: [
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "77225eaa-29dd-488f-a130-45e6ccd42b87",
            price: 1.098882740587156,
            price_sources: [],
            processed_ms: 1715587142164,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "2202b6ef-967c-4d75-a08a-7462e37a9cc3",
            price: 1.09876,
            price_sources: [],
            processed_ms: 1715587508085,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "4df4cd97-d9d6-417c-b0f8-248678fd653a",
            price: 1.098,
            price_sources: [],
            processed_ms: 1715587844978,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "e77f1423-e398-474f-add5-40c7ef865a3c",
            price: 1.099,
            price_sources: [],
            processed_ms: 1715588147266,
          },
          {
            leverage: -0.0075,
            order_type: "SHORT",
            order_uuid: "adccd325-d346-4e6b-8b78-004b714fbd53",
            price: 1.09911,
            price_sources: [],
            processed_ms: 1715588391271,
          },
          {
            leverage: -0.01625,
            order_type: "SHORT",
            order_uuid: "5801d592-c9ce-451f-905f-17f5e66ceeb8",
            price: 1.099049970621051,
            price_sources: [],
            processed_ms: 1715588513239,
          },
          {
            leverage: -0.008125,
            order_type: "SHORT",
            order_uuid: "a917468a-186c-4eef-b446-fb0096509541",
            price: 1.099034062232756,
            price_sources: [],
            processed_ms: 1715588640239,
          },
          {
            leverage: 0.008125,
            order_type: "FLAT",
            order_uuid: "3262614a-40e9-423d-845d-fdd564eb835c",
            price: 1.099,
            price_sources: [],
            processed_ms: 1715588763436,
          },
        ],
        position_type: "FLAT",
        position_uuid: "02ecd9c1-d815-4d65-9937-f86e8702f211",
        return_at_close: 1.000011292794825,
        trade_pair: ["AUDNZD", "AUD/NZD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 1.0972431176256736,
        close_ms: 1715592234965,
        current_return: 1.000011243062197,
        initial_entry_price: 1.098945698516046,
        is_closed_position: true,
        miner_hotkey: "5Dxqzduahnqw8q3XSUfTcEZGU7xmAsfJubhHZwvXVLN9fSjR",
        net_leverage: 0.0,
        open_ms: 1715590618086,
        orders: [
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "d30216f8-3541-4b78-a377-6d4d8247bcb8",
            price: 1.098945698516046,
            price_sources: [],
            processed_ms: 1715590614661,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "c823d8d0-3b6c-4e5a-9a51-6fd4ffee62d0",
            price: 1.09914,
            price_sources: [],
            processed_ms: 1715590977158,
          },
          {
            leverage: -0.005,
            order_type: "SHORT",
            order_uuid: "eaf94227-4d1f-44fd-bb65-6d4b96521880",
            price: 1.09913,
            price_sources: [],
            processed_ms: 1715591114396,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "85c96e8f-9129-44ba-be19-60b4d7ffb2f7",
            price: 1.0982,
            price_sources: [],
            processed_ms: 1715591418413,
          },
          {
            leverage: -0.0125,
            order_type: "SHORT",
            order_uuid: "6c90f5de-0f4f-47ff-81a0-84e7850bc7ca",
            price: 1.09914,
            price_sources: [],
            processed_ms: 1715591658712,
          },
          {
            leverage: -0.00625,
            order_type: "SHORT",
            order_uuid: "384cd31e-29ed-42e2-a09d-4c60c7831467",
            price: 1.09923,
            price_sources: [],
            processed_ms: 1715591779268,
          },
          {
            leverage: 0.00625,
            order_type: "FLAT",
            order_uuid: "7e3eadb5-8747-42a4-9571-3778bd5d5920",
            price: 1.09922,
            price_sources: [],
            processed_ms: 1715592234965,
          },
        ],
        position_type: "FLAT",
        position_uuid: "24f9e981-34a6-41c2-a3c1-66d3335340f5",
        return_at_close: 1.0000091430385867,
        trade_pair: ["AUDNZD", "AUD/NZD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.6259431999999646,
        close_ms: 1715592756161,
        current_return: 1.0003865922865536,
        initial_entry_price: 0.6641,
        is_closed_position: true,
        miner_hotkey: "5Dxqzduahnqw8q3XSUfTcEZGU7xmAsfJubhHZwvXVLN9fSjR",
        net_leverage: 0.0,
        open_ms: 1715346752868,
        orders: [
          {
            leverage: 0.05,
            order_type: "LONG",
            order_uuid: "0bf891ec-d53d-4c85-8212-8e65414f1523",
            price: 0.6641,
            price_sources: [],
            processed_ms: 1715346752251,
          },
          {
            leverage: 0.05,
            order_type: "LONG",
            order_uuid: "79c84953-0cd6-4342-a64a-09e9accc3d6c",
            price: 0.66442,
            price_sources: [],
            processed_ms: 1715346957121,
          },
          {
            leverage: 0.05,
            order_type: "LONG",
            order_uuid: "29ba77ab-d41f-4129-9592-290daab6078b",
            price: 0.66443,
            price_sources: [],
            processed_ms: 1715347024104,
          },
          {
            leverage: 0.05,
            order_type: "LONG",
            order_uuid: "b64f99d2-e9d3-495d-8cbd-c45fd4861b11",
            price: 0.6639,
            price_sources: [],
            processed_ms: 1715348559139,
          },
          {
            leverage: 0.05,
            order_type: "LONG",
            order_uuid: "7de4d99f-5562-4b84-aa6d-fb0c580da929",
            price: 0.66416,
            price_sources: [],
            processed_ms: 1715350729151,
          },
          {
            leverage: -0.125,
            order_type: "SHORT",
            order_uuid: "96521cc4-c97a-4550-ae60-f6f0648358fb",
            price: 0.66464,
            price_sources: [],
            processed_ms: 1715351477179,
          },
          {
            leverage: 0.05,
            order_type: "LONG",
            order_uuid: "fded622c-c48b-450f-89e9-ce1c0f9036fa",
            price: 0.66423,
            price_sources: [],
            processed_ms: 1715351789144,
          },
          {
            leverage: 0.05,
            order_type: "LONG",
            order_uuid: "6dbdcb4e-b6d1-4ca2-9f7b-ac88b57aa76f",
            price: 0.6636,
            price_sources: [],
            processed_ms: 1715352178292,
          },
          {
            leverage: 0.05,
            order_type: "LONG",
            order_uuid: "7a31a91f-76d5-4a53-ae55-8a831caa62b8",
            price: 0.664,
            price_sources: [],
            processed_ms: 1715353276746,
          },
          {
            leverage: 0.05,
            order_type: "LONG",
            order_uuid: "ac9f2ff3-8bc2-4288-b2b7-bad3f1bf6294",
            price: 0.6637,
            price_sources: [],
            processed_ms: 1715354034247,
          },
          {
            leverage: 0.05,
            order_type: "LONG",
            order_uuid: "4d369337-580c-410c-8bdd-24ccb4fc3ce0",
            price: 0.6631,
            price_sources: [],
            processed_ms: 1715354344174,
          },
          {
            leverage: 0.05,
            order_type: "LONG",
            order_uuid: "a069706d-a6c5-4da0-8365-3fd7b0ec7d1b",
            price: 0.66333,
            price_sources: [],
            processed_ms: 1715355851119,
          },
          {
            leverage: 0.05,
            order_type: "LONG",
            order_uuid: "47f1a13a-0e41-43dd-bbac-6ae1b24272b2",
            price: 0.6627,
            price_sources: [],
            processed_ms: 1715358320257,
          },
          {
            leverage: 0.05,
            order_type: "LONG",
            order_uuid: "39db662b-3593-47e1-bfe8-343c75d64b59",
            price: 0.6629,
            price_sources: [],
            processed_ms: 1715359831129,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "923f03d7-865e-4534-b39b-ffa7ada6aa39",
            price: 0.66283,
            price_sources: [],
            processed_ms: 1715370044130,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "84aa0c4a-0999-4fd6-9743-91a5e09a8550",
            price: 0.66286,
            price_sources: [],
            processed_ms: 1715370352447,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "b5958595-c5a2-482e-9961-946c45158774",
            price: 0.66287,
            price_sources: [],
            processed_ms: 1715370659156,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "b857b546-a89e-40de-a997-a1340ef09067",
            price: 0.6629,
            price_sources: [],
            processed_ms: 1715370969283,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "e30bfae3-98b0-4974-84c2-ca7bf513c52a",
            price: 0.66283,
            price_sources: [],
            processed_ms: 1715371270424,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "4af4302d-7bfb-477c-a1b8-1d3d9d1de251",
            price: 0.66279,
            price_sources: [],
            processed_ms: 1715372438215,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "5a06c91b-486d-4a1c-a8ac-fa07f88baf7d",
            price: 0.6622,
            price_sources: [],
            processed_ms: 1715372802662,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "d6d807c8-5a49-47bc-85f6-6fcdb0c13ed5",
            price: 0.66268,
            price_sources: [],
            processed_ms: 1715373268134,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "ce6b719e-d38c-455c-a932-43e8d276e4ff",
            price: 0.66283,
            price_sources: [],
            processed_ms: 1715373571270,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "eec98a67-0217-42ad-8099-8db6e02b6e5c",
            price: 0.66283,
            price_sources: [],
            processed_ms: 1715373876246,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "271a973b-e3cf-4780-aac0-664240bf4771",
            price: 0.6623,
            price_sources: [],
            processed_ms: 1715374475469,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "60be3713-98de-4735-a227-cfc75704ca22",
            price: 0.66254,
            price_sources: [],
            processed_ms: 1715566938312,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "e65d68b7-7823-4eef-9ea7-3711dabc65e0",
            price: 0.66247,
            price_sources: [],
            processed_ms: 1715567679326,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "ce967110-072d-4ea0-8d09-72516499555c",
            price: 0.66246,
            price_sources: [],
            processed_ms: 1715568011771,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "4ca8451b-0722-48aa-bf5c-6bcd162f5109",
            price: 0.6625,
            price_sources: [],
            processed_ms: 1715568361301,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "8ac0229b-416a-4496-bae6-6ea4e98d350c",
            price: 0.66256,
            price_sources: [],
            processed_ms: 1715568798360,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "b5c3e096-fa4a-4643-b1c6-f350c42788bd",
            price: 0.6625,
            price_sources: [],
            processed_ms: 1715569514299,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "e1624aa2-400a-4ced-b85e-2e2e90adbcce",
            price: 0.6625,
            price_sources: [],
            processed_ms: 1715569837179,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "b29d00bc-46df-41df-8eda-089c8c30f705",
            price: 0.66252,
            price_sources: [],
            processed_ms: 1715570550332,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "f7fa2cad-2d28-479e-ba49-2974fd55f7d4",
            price: 0.66254,
            price_sources: [],
            processed_ms: 1715570894220,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "516c862a-4192-4751-99a7-df530fd8ac02",
            price: 0.66257,
            price_sources: [],
            processed_ms: 1715571249268,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "9466eb8d-78b2-4c75-8d91-0262fec94385",
            price: 0.66258,
            price_sources: [],
            processed_ms: 1715571585600,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "ec8dbd45-272d-4b86-8e3f-d8237688fb30",
            price: 0.66259,
            price_sources: [],
            processed_ms: 1715571927604,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "69290b30-0a41-4afd-ab3d-2d2c32f50de2",
            price: 0.66257,
            price_sources: [],
            processed_ms: 1715572266382,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "f901e745-58ba-498b-9bed-0b67e0ee67c5",
            price: 0.6621,
            price_sources: [],
            processed_ms: 1715572841398,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "71b3e8b9-629b-4080-99d1-9095961ce13f",
            price: 0.6626,
            price_sources: [],
            processed_ms: 1715573566164,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "82cc4d0d-1268-426c-b750-25821fac94d6",
            price: 0.66265,
            price_sources: [],
            processed_ms: 1715574208396,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "b9b073ea-b42c-41da-869a-2019290f5f1c",
            price: 0.6622,
            price_sources: [],
            processed_ms: 1715574618796,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "2985c257-6737-43d8-a009-0f884e1c4915",
            price: 0.66262,
            price_sources: [],
            processed_ms: 1715575552427,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "3ea8c2de-8e00-41d1-b104-5a485e80647d",
            price: 0.6626,
            price_sources: [],
            processed_ms: 1715577376833,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "3e11dade-98b5-42b2-8559-fa3364db0f99",
            price: 0.66261,
            price_sources: [],
            processed_ms: 1715577692649,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "f903b7a0-d548-4c2c-827a-ea06e64da85b",
            price: 0.66245,
            price_sources: [],
            processed_ms: 1715579923080,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "8449d1b0-9173-4423-bfde-5e2ad355a06c",
            price: 0.6621,
            price_sources: [],
            processed_ms: 1715580225236,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "f589cde7-3a1d-47bc-afbb-043c5d0e9dae",
            price: 0.66256,
            price_sources: [],
            processed_ms: 1715583182424,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "0dd0fbd6-8c4a-48f4-9640-ede008bcb475",
            price: 0.6621,
            price_sources: [],
            processed_ms: 1715584024348,
          },
          {
            leverage: -0.4375000000000001,
            order_type: "SHORT",
            order_uuid: "bb32f97a-62c4-4dde-b174-6d576451a0cf",
            price: 0.66341,
            price_sources: [],
            processed_ms: 1715591899280,
          },
          {
            leverage: -0.21875000000000006,
            order_type: "SHORT",
            order_uuid: "3dc62333-84b5-456f-aeae-073a867e073f",
            price: 0.66349,
            price_sources: [],
            processed_ms: 1715592020050,
          },
          {
            leverage: -0.10937500000000003,
            order_type: "SHORT",
            order_uuid: "1006fc4a-818a-4a9d-953b-2c5470297460",
            price: 0.66349,
            price_sources: [],
            processed_ms: 1715592143342,
          },
          {
            leverage: -0.054687500000000014,
            order_type: "SHORT",
            order_uuid: "9d119c8c-b19c-41d4-a0be-dab9cbf95bac",
            price: 0.6631,
            price_sources: [],
            processed_ms: 1715592264252,
          },
          {
            leverage: -0.027343750000000007,
            order_type: "SHORT",
            order_uuid: "76a0eff5-fc58-4100-8bb1-f2066c9579c7",
            price: 0.66353,
            price_sources: [],
            processed_ms: 1715592384849,
          },
          {
            leverage: -0.013671875000000003,
            order_type: "SHORT",
            order_uuid: "cdddce18-1ae9-4dd6-ba7f-9aec7dd82fa0",
            price: 0.66353,
            price_sources: [],
            processed_ms: 1715592512289,
          },
          {
            leverage: -0.006835937500000002,
            order_type: "SHORT",
            order_uuid: "dafa7884-b418-4c8d-a0be-83e176cf84b6",
            price: 0.66352,
            price_sources: [],
            processed_ms: 1715592635653,
          },
          {
            leverage: 0.006835937500000002,
            order_type: "FLAT",
            order_uuid: "3e082dab-3a5b-48d2-a07f-7d9eda31fbbf",
            price: 0.6635,
            price_sources: [],
            processed_ms: 1715592756161,
          },
        ],
        position_type: "FLAT",
        position_uuid: "3b61a3be-262e-4c48-8a56-2350d4f751c0",
        return_at_close: 1.0003165652250936,
        trade_pair: ["CADCHF", "CAD/CHF", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.8761204235367688,
        close_ms: 1715597435674,
        current_return: 1.0001823816209237,
        initial_entry_price: 0.905560301158148,
        is_closed_position: true,
        miner_hotkey: "5Dxqzduahnqw8q3XSUfTcEZGU7xmAsfJubhHZwvXVLN9fSjR",
        net_leverage: 0.0,
        open_ms: 1715294212621,
        orders: [
          {
            leverage: 0.05,
            order_type: "LONG",
            order_uuid: "a2d29315-4131-47f0-89b5-a8b9f6044435",
            price: 0.905560301158148,
            price_sources: [],
            processed_ms: 1715294208240,
          },
          {
            leverage: 0.05,
            order_type: "LONG",
            order_uuid: "77229c24-a48a-49b3-ae6f-894f86f246e7",
            price: 0.905170131073746,
            price_sources: [],
            processed_ms: 1715296432154,
          },
          {
            leverage: 0.05,
            order_type: "LONG",
            order_uuid: "a02dda1e-eeae-472b-b7e7-2ed77b84e56e",
            price: 0.90523009921436,
            price_sources: [],
            processed_ms: 1715298567192,
          },
          {
            leverage: 0.05,
            order_type: "LONG",
            order_uuid: "cdfd1173-89fe-4161-87b6-a0bdf2f082db",
            price: 0.9051,
            price_sources: [],
            processed_ms: 1715299495107,
          },
          {
            leverage: 0.05,
            order_type: "LONG",
            order_uuid: "d3150b3a-844c-4ec3-9567-dca4cd19eb93",
            price: 0.90492,
            price_sources: [],
            processed_ms: 1715300492152,
          },
          {
            leverage: 0.05,
            order_type: "LONG",
            order_uuid: "a004b839-fc63-4ab3-a182-23be2f40db63",
            price: 0.9046345221641,
            price_sources: [],
            processed_ms: 1715301224215,
          },
          {
            leverage: 0.05,
            order_type: "LONG",
            order_uuid: "ad7fd467-233f-4af4-a1d9-8497a7da91d9",
            price: 0.903515661092119,
            price_sources: [],
            processed_ms: 1715307954104,
          },
          {
            leverage: 0.05,
            order_type: "LONG",
            order_uuid: "f216d230-17c9-4da7-b86f-fb13862d03b6",
            price: 0.903542527491386,
            price_sources: [],
            processed_ms: 1715308260564,
          },
          {
            leverage: 0.05,
            order_type: "LONG",
            order_uuid: "11ebd310-ac99-4c9e-a31c-7f3442588786",
            price: 0.903429978154267,
            price_sources: [],
            processed_ms: 1715308652739,
          },
          {
            leverage: 0.05,
            order_type: "LONG",
            order_uuid: "0174f00e-aeb8-495f-ba43-0b9b4805cb88",
            price: 0.901384151661775,
            price_sources: [],
            processed_ms: 1715344202516,
          },
          {
            leverage: 0.05,
            order_type: "LONG",
            order_uuid: "46a76d41-6360-4c08-aa9f-68f3188a3b51",
            price: 0.901761651870641,
            price_sources: [],
            processed_ms: 1715344855173,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "a5600d19-6dfc-43dd-b99e-9f47b658f123",
            price: 0.90311,
            price_sources: [],
            processed_ms: 1715370641111,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "b7ba7341-d478-4412-9768-e477d52cab7d",
            price: 0.90298,
            price_sources: [],
            processed_ms: 1715371550137,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "a4c5a92d-d743-4609-ae1d-1cf911208904",
            price: 0.90266,
            price_sources: [],
            processed_ms: 1715568962384,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "c2a91122-c4b6-4603-9069-4f23bd92f02d",
            price: 0.9025,
            price_sources: [],
            processed_ms: 1715570586642,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "9bdf7197-8d1e-4b3c-a3dc-12e02646b94f",
            price: 0.90236,
            price_sources: [],
            processed_ms: 1715575925372,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "fea191a4-551e-42a1-ab94-ea466af7ca8c",
            price: 0.902381674757282,
            price_sources: [],
            processed_ms: 1715576732973,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "7c8d26f2-bdbd-49d3-9dfd-3f37ab30bfa7",
            price: 0.902359722478698,
            price_sources: [],
            processed_ms: 1715577466544,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "4357d4e4-bbf0-4273-a0eb-7617a49af37d",
            price: 0.90236,
            price_sources: [],
            processed_ms: 1715579914772,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "3075fadf-524f-4d17-9291-780b2f80e7d9",
            price: 0.902387784044062,
            price_sources: [],
            processed_ms: 1715580587068,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "5148a141-b141-4b94-acc7-b097b35ab0c1",
            price: 0.902842397724498,
            price_sources: [],
            processed_ms: 1715583258631,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "b05501fb-94ec-45f9-9075-90e06de8348c",
            price: 0.90291,
            price_sources: [],
            processed_ms: 1715586251371,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "a742531f-9b15-44e3-abc2-1501a456eb38",
            price: 0.90282288943463,
            price_sources: [],
            processed_ms: 1715588187235,
          },
          {
            leverage: -0.335,
            order_type: "SHORT",
            order_uuid: "ba5ab071-ef4a-4ed6-ba69-474508683f11",
            price: 0.90405,
            price_sources: [],
            processed_ms: 1715594541527,
          },
          {
            leverage: -0.1675,
            order_type: "SHORT",
            order_uuid: "5d57ab26-9f46-43dc-b6ec-55bccd2d42f0",
            price: 0.90397,
            price_sources: [],
            processed_ms: 1715594745940,
          },
          {
            leverage: -0.041875,
            order_type: "SHORT",
            order_uuid: "e52ab930-52c6-4227-9fa1-6c3059255fd2",
            price: 0.90395,
            price_sources: [],
            processed_ms: 1715595677129,
          },
          {
            leverage: -0.06281250000000001,
            order_type: "SHORT",
            order_uuid: "2dc2ae92-7beb-4f41-a926-0faf49db1019",
            price: 0.90403,
            price_sources: [],
            processed_ms: 1715596027124,
          },
          {
            leverage: -0.031406250000000004,
            order_type: "SHORT",
            order_uuid: "103d4d19-8502-4358-90c7-9e5300305ffe",
            price: 0.90405,
            price_sources: [],
            processed_ms: 1715596219334,
          },
          {
            leverage: -0.007851562500000001,
            order_type: "SHORT",
            order_uuid: "d2cd7e18-e014-4729-ba17-711b63abfe26",
            price: 0.90402,
            price_sources: [],
            processed_ms: 1715596535294,
          },
          {
            leverage: -0.011777343750000002,
            order_type: "SHORT",
            order_uuid: "e21dea55-988c-4580-a6f0-ededfdd3edcc",
            price: 0.90402,
            price_sources: [],
            processed_ms: 1715596659752,
          },
          {
            leverage: -0.005888671875000001,
            order_type: "SHORT",
            order_uuid: "dc5d84b5-bc35-435c-8bb9-a2d05a480570",
            price: 0.903981496778457,
            price_sources: [],
            processed_ms: 1715597019393,
          },
          {
            leverage: 0.005888671875000001,
            order_type: "FLAT",
            order_uuid: "cf0b82b8-a37e-4ab7-b4df-4aefcb52f663",
            price: 0.904167079739599,
            price_sources: [],
            processed_ms: 1715597435674,
          },
        ],
        position_type: "FLAT",
        position_uuid: "217b766f-8d2b-4db7-b096-01b4b7262fa7",
        return_at_close: 1.0001354730672256,
        trade_pair: ["AUDCAD", "AUD/CAD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.9042397870581623,
        close_ms: 1715601416089,
        current_return: 1.0000027241092198,
        initial_entry_price: 0.904539893529081,
        is_closed_position: true,
        miner_hotkey: "5Dxqzduahnqw8q3XSUfTcEZGU7xmAsfJubhHZwvXVLN9fSjR",
        net_leverage: 0.0,
        open_ms: 1715600308633,
        orders: [
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "558dd13b-717f-49ae-ba39-64fa0deae4ff",
            price: 0.904539893529081,
            price_sources: [],
            processed_ms: 1715600307713,
          },
          {
            leverage: -0.005,
            order_type: "SHORT",
            order_uuid: "59cc8951-362d-4c24-a9db-d0637648cc86",
            price: 0.90484,
            price_sources: [],
            processed_ms: 1715601008205,
          },
          {
            leverage: 0.005,
            order_type: "FLAT",
            order_uuid: "d1f840d0-063d-4ade-8458-3dd9802cbb95",
            price: 0.904732600150872,
            price_sources: [],
            processed_ms: 1715601416089,
          },
        ],
        position_type: "FLAT",
        position_uuid: "39034c0b-b3b0-4b0d-b8fc-b2e595e1a93c",
        return_at_close: 1.0000020241073129,
        trade_pair: ["AUDCAD", "AUD/CAD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.6608832653061226,
        close_ms: 1715602196305,
        current_return: 1.0000263233197182,
        initial_entry_price: 0.66317,
        is_closed_position: true,
        miner_hotkey: "5Dxqzduahnqw8q3XSUfTcEZGU7xmAsfJubhHZwvXVLN9fSjR",
        net_leverage: 0.0,
        open_ms: 1715594575876,
        orders: [
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "2ddfe8e8-1f98-4348-a021-a53a21229502",
            price: 0.66317,
            price_sources: [],
            processed_ms: 1715594572283,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "eded6fae-e371-4a05-ada1-da6abd55a9a2",
            price: 0.66307,
            price_sources: [],
            processed_ms: 1715595200277,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "d2bbffd1-2289-4b3a-97b5-671e055d7c74",
            price: 0.6626,
            price_sources: [],
            processed_ms: 1715595848100,
          },
          {
            leverage: -0.0075,
            order_type: "SHORT",
            order_uuid: "27c5e3ae-01a8-4ae0-a8b9-e8dc80388098",
            price: 0.6628,
            price_sources: [],
            processed_ms: 1715596245293,
          },
          {
            leverage: -0.01125,
            order_type: "SHORT",
            order_uuid: "2acaca39-1620-4c0b-ae47-7a9cece6f0c3",
            price: 0.6628,
            price_sources: [],
            processed_ms: 1715596371195,
          },
          {
            leverage: -0.005625,
            order_type: "SHORT",
            order_uuid: "d927c3e1-f718-4ca5-93e7-190602fba62b",
            price: 0.66317,
            price_sources: [],
            processed_ms: 1715596513340,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "e58fe2f1-a5a4-47c2-a413-f87133c0fd01",
            price: 0.66301,
            price_sources: [],
            processed_ms: 1715596814210,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "78925442-706e-4548-95d7-3357bac67331",
            price: 0.6625,
            price_sources: [],
            processed_ms: 1715597196273,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "1ae3a6b8-c5c4-4d8a-bde6-cd5bb68524ee",
            price: 0.6629,
            price_sources: [],
            processed_ms: 1715597620180,
          },
          {
            leverage: -0.017812500000000002,
            order_type: "SHORT",
            order_uuid: "20992f89-0be8-49ca-9b41-f45c3956f5de",
            price: 0.66292,
            price_sources: [],
            processed_ms: 1715597743225,
          },
          {
            leverage: -0.008906250000000001,
            order_type: "SHORT",
            order_uuid: "c06a6e21-30a0-4282-b16b-7f1c256236e5",
            price: 0.66292,
            price_sources: [],
            processed_ms: 1715597867191,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "490447a9-a6f9-4b65-a928-dc69cb1a086b",
            price: 0.66269,
            price_sources: [],
            processed_ms: 1715598534183,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "8fcea177-5f00-4766-9688-3a522e138848",
            price: 0.66259,
            price_sources: [],
            processed_ms: 1715599223034,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "91d102c4-20ed-4509-a0c0-97ce7560131b",
            price: 0.66256,
            price_sources: [],
            processed_ms: 1715599670949,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "dec0b071-2fe9-4734-81a6-89eae30c023e",
            price: 0.6621,
            price_sources: [],
            processed_ms: 1715599975817,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "98f7177d-c67b-411e-af0c-87d5ffd62938",
            price: 0.66251,
            price_sources: [],
            processed_ms: 1715600288582,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "0b7e859b-5f27-47b1-9a48-5c4b417a774f",
            price: 0.66264,
            price_sources: [],
            processed_ms: 1715601350290,
          },
          {
            leverage: -0.034453125,
            order_type: "SHORT",
            order_uuid: "bd8d3a84-d9f1-47ef-8610-7ac0d385d735",
            price: 0.66272,
            price_sources: [],
            processed_ms: 1715601478876,
          },
          {
            leverage: -0.0172265625,
            order_type: "SHORT",
            order_uuid: "069d7c33-a908-4f81-9669-4c104413ebf9",
            price: 0.66285,
            price_sources: [],
            processed_ms: 1715601952428,
          },
          {
            leverage: -0.00861328125,
            order_type: "SHORT",
            order_uuid: "09f57455-a44f-46b0-8691-128fdf36591e",
            price: 0.66286,
            price_sources: [],
            processed_ms: 1715602074071,
          },
          {
            leverage: 0.00861328125,
            order_type: "FLAT",
            order_uuid: "a5a1eb82-61e2-481f-b5bf-b7aba3539e83",
            price: 0.66291,
            price_sources: [],
            processed_ms: 1715602196305,
          },
        ],
        position_type: "FLAT",
        position_uuid: "f33374e6-fd5c-41b9-9a87-cb820161421b",
        return_at_close: 1.0000179230986024,
        trade_pair: ["CADCHF", "CAD/CHF", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.6623,
        close_ms: 1715604293320,
        current_return: 1.0000033217575117,
        initial_entry_price: 0.6623,
        is_closed_position: true,
        miner_hotkey: "5Dxqzduahnqw8q3XSUfTcEZGU7xmAsfJubhHZwvXVLN9fSjR",
        net_leverage: 0.0,
        open_ms: 1715604048248,
        orders: [
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "1ccd5a9f-a21b-4d6e-b242-160b81e39426",
            price: 0.6623,
            price_sources: [],
            processed_ms: 1715604046448,
          },
          {
            leverage: 0.005,
            order_type: "FLAT",
            order_uuid: "59130bb6-8e1a-4602-9bbd-69db5e4f9752",
            price: 0.66252,
            price_sources: [],
            processed_ms: 1715604293320,
          },
        ],
        position_type: "FLAT",
        position_uuid: "61c897c3-cac4-49dc-8968-3db3b180c032",
        return_at_close: 1.0000026217551865,
        trade_pair: ["CADCHF", "CAD/CHF", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.6610060000000006,
        close_ms: 1715607962498,
        current_return: 1.0000132494828549,
        initial_entry_price: 0.66229,
        is_closed_position: true,
        miner_hotkey: "5Dxqzduahnqw8q3XSUfTcEZGU7xmAsfJubhHZwvXVLN9fSjR",
        net_leverage: 0.0,
        open_ms: 1715606106788,
        orders: [
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "a12c61ed-644e-4168-8df4-46f584934c6c",
            price: 0.66229,
            price_sources: [],
            processed_ms: 1715606104340,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "879648f4-a503-4812-8656-5cb21ae68e23",
            price: 0.66227,
            price_sources: [],
            processed_ms: 1715606192409,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "9a6b1b7b-72b2-4fbb-a9ad-ccf8f8588f4b",
            price: 0.66178,
            price_sources: [],
            processed_ms: 1715606428445,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "f8e7f82f-0910-493c-b518-45b7c4371d47",
            price: 0.66187,
            price_sources: [],
            processed_ms: 1715606750462,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "b6ad4a4d-517a-4e8e-bb46-5ff99972af99",
            price: 0.6613,
            price_sources: [],
            processed_ms: 1715607065231,
          },
          {
            leverage: -0.025,
            order_type: "SHORT",
            order_uuid: "718b6d4a-338f-4f36-bff9-5c12fe04b4ef",
            price: 0.66214,
            price_sources: [],
            processed_ms: 1715607351791,
          },
          {
            leverage: -0.0125,
            order_type: "SHORT",
            order_uuid: "246b95ed-f001-4149-bf6b-b85cb2a7919c",
            price: 0.6617,
            price_sources: [],
            processed_ms: 1715607600110,
          },
          {
            leverage: -0.00625,
            order_type: "SHORT",
            order_uuid: "ad86fdfb-c54c-4b5d-800d-1698828b8255",
            price: 0.66225,
            price_sources: [],
            processed_ms: 1715607720140,
          },
          {
            leverage: 0.00625,
            order_type: "FLAT",
            order_uuid: "27b576b1-3673-4481-b9c9-2618ff29f995",
            price: 0.66241,
            price_sources: [],
            processed_ms: 1715607962498,
          },
        ],
        position_type: "FLAT",
        position_uuid: "c7ae8a66-bd4c-4c30-a5fa-9343d2c7ccc2",
        return_at_close: 1.0000097494364817,
        trade_pair: ["CADCHF", "CAD/CHF", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.904501703436642,
        close_ms: 1715609966300,
        current_return: 1.0000047753155241,
        initial_entry_price: 0.904501703436642,
        is_closed_position: true,
        miner_hotkey: "5Dxqzduahnqw8q3XSUfTcEZGU7xmAsfJubhHZwvXVLN9fSjR",
        net_leverage: 0.0,
        open_ms: 1715609716040,
        orders: [
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "907fba83-18e3-4f9e-8fca-2fd0507eab0b",
            price: 0.904501703436642,
            price_sources: [],
            processed_ms: 1715609714498,
          },
          {
            leverage: 0.005,
            order_type: "FLAT",
            order_uuid: "81868a41-7a6a-4d38-993d-3d9d78e4b414",
            price: 0.904933631539245,
            price_sources: [],
            processed_ms: 1715609966300,
          },
        ],
        position_type: "FLAT",
        position_uuid: "6c6df005-f97a-4e35-a781-8b8ba1b8315a",
        return_at_close: 1.0000040753121815,
        trade_pair: ["AUDCAD", "AUD/CAD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.6624,
        close_ms: 1715610330178,
        current_return: 1.0000032457729469,
        initial_entry_price: 0.6624,
        is_closed_position: true,
        miner_hotkey: "5Dxqzduahnqw8q3XSUfTcEZGU7xmAsfJubhHZwvXVLN9fSjR",
        net_leverage: 0.0,
        open_ms: 1715610016215,
        orders: [
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "e2d9e40d-37b0-492b-bcc2-3d9847c4feb7",
            price: 0.6624,
            price_sources: [],
            processed_ms: 1715610013216,
          },
          {
            leverage: -0.005,
            order_type: "SHORT",
            order_uuid: "4a832abf-18ea-4521-913b-405bcbb4df4c",
            price: 0.6624,
            price_sources: [],
            processed_ms: 1715610200225,
          },
          {
            leverage: 0.005,
            order_type: "FLAT",
            order_uuid: "d6a848f6-3f1c-4c27-94c3-543d2160b5f5",
            price: 0.66283,
            price_sources: [],
            processed_ms: 1715610330178,
          },
        ],
        position_type: "FLAT",
        position_uuid: "97420e63-1432-4ef7-ab21-e14826e4a439",
        return_at_close: 1.000002545770675,
        trade_pair: ["CADCHF", "CAD/CHF", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 15.41845151515085,
        close_ms: 1715610407222,
        current_return: 1.0006333137842636,
        initial_entry_price: 16.78735,
        is_closed_position: true,
        miner_hotkey: "5Dxqzduahnqw8q3XSUfTcEZGU7xmAsfJubhHZwvXVLN9fSjR",
        net_leverage: 0.0,
        open_ms: 1715367554863,
        orders: [
          {
            leverage: 0.05,
            order_type: "LONG",
            order_uuid: "d0b60cc5-e7c8-45af-91c4-c6f23f01467b",
            price: 16.78735,
            price_sources: [],
            processed_ms: 1715367548314,
          },
          {
            leverage: 0.05,
            order_type: "LONG",
            order_uuid: "de299e11-4a42-4055-a99a-9e9525181255",
            price: 16.781,
            price_sources: [],
            processed_ms: 1715368341179,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "e75feb06-b923-471a-83b2-525b5c20140d",
            price: 16.7667,
            price_sources: [],
            processed_ms: 1715370613693,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "d4d5d729-f648-4f88-aedd-10b0c9576c00",
            price: 16.78239,
            price_sources: [],
            processed_ms: 1715370916378,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "6ba0312c-4d2d-48b2-adda-4d39f5d7c6fd",
            price: 16.77845,
            price_sources: [],
            processed_ms: 1715371448161,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "19bc6dd3-44f0-4fc7-8158-95b62dd466a6",
            price: 16.7649,
            price_sources: [],
            processed_ms: 1715372038265,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "469923ed-d62e-402b-97c7-1451c21e08ad",
            price: 16.7596,
            price_sources: [],
            processed_ms: 1715569360225,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "8a5cab03-77d6-442b-8dc1-5b339847ae7a",
            price: 16.7637,
            price_sources: [],
            processed_ms: 1715577748375,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "16f3c887-19eb-4967-b546-ed642fe698d2",
            price: 16.7648,
            price_sources: [],
            processed_ms: 1715579636310,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "79d38b09-fdb0-47b7-828b-60b40b1be15a",
            price: 16.762,
            price_sources: [],
            processed_ms: 1715580455202,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "e8026f59-cbac-4b90-9668-2d01105ba252",
            price: 16.7665,
            price_sources: [],
            processed_ms: 1715580855224,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "d4e7dbab-83c8-4360-bd51-eba061e89fd3",
            price: 16.76595,
            price_sources: [],
            processed_ms: 1715581378249,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "c91f878b-d0ab-4133-bde6-c9789170a88f",
            price: 16.7638,
            price_sources: [],
            processed_ms: 1715581727042,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "c5d44578-e8a7-4b79-9218-798dc4db3068",
            price: 16.7479,
            price_sources: [],
            processed_ms: 1715581812338,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "50367acc-0c6c-4036-8344-c8c9356a5069",
            price: 16.76445,
            price_sources: [],
            processed_ms: 1715582436168,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "69f24956-2727-4ba7-afb9-7bde74237cf7",
            price: 16.7469,
            price_sources: [],
            processed_ms: 1715582742376,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "be98f96a-85f1-40ba-bedf-9ac7c4784f09",
            price: 16.7433,
            price_sources: [],
            processed_ms: 1715583377340,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "8aa3a45a-5631-4768-82ae-9e107deeb0e6",
            price: 16.75232,
            price_sources: [],
            processed_ms: 1715583608349,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "5a3e20a7-71d1-4b6f-891f-cf8f226fe738",
            price: 16.7449,
            price_sources: [],
            processed_ms: 1715584109797,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "b88f4650-85a8-449c-88bd-e66c73e3c93f",
            price: 16.75785,
            price_sources: [],
            processed_ms: 1715584521468,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "04cc7617-c50d-4010-b5cd-5dcc31717849",
            price: 16.76055,
            price_sources: [],
            processed_ms: 1715584827185,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "bcd28e22-8a6b-4212-826c-326c222ecbe8",
            price: 16.758,
            price_sources: [],
            processed_ms: 1715585163184,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "e05dd372-ed3e-475c-9168-7faaba53891c",
            price: 16.76169,
            price_sources: [],
            processed_ms: 1715585774788,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "a84c2c5a-533a-4b4d-aa49-9d80bd7e7b4b",
            price: 16.7532,
            price_sources: [],
            processed_ms: 1715586132263,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "2883e3fe-6eb5-4e33-b47f-c44f1a21c05c",
            price: 16.7615,
            price_sources: [],
            processed_ms: 1715586438584,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "60ad8322-1a81-441a-b897-0b1a3585eb85",
            price: 16.7454,
            price_sources: [],
            processed_ms: 1715586859649,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "074fe846-45db-41bd-873d-d8293b784203",
            price: 16.75413,
            price_sources: [],
            processed_ms: 1715587317763,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "bee51941-bc1c-4d3e-86a3-cc857c5adb40",
            price: 16.75415,
            price_sources: [],
            processed_ms: 1715587938314,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "3928dc2c-768a-4daf-b511-1ece4f35e082",
            price: 16.7417,
            price_sources: [],
            processed_ms: 1715588434740,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "eba42f1b-7e28-4599-a526-2010d299556e",
            price: 16.7387,
            price_sources: [],
            processed_ms: 1715589001836,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "2c2f8855-0a7c-482e-96a7-bc3d42f5302d",
            price: 16.7437,
            price_sources: [],
            processed_ms: 1715589577177,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "5206abf7-f5f0-495a-b686-c993b142c5b3",
            price: 16.74587,
            price_sources: [],
            processed_ms: 1715590128764,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "8b16bffb-271c-4d8e-ba4c-5f59a523f1eb",
            price: 16.74359,
            price_sources: [],
            processed_ms: 1715591146208,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "d1959d10-b1e3-487d-8990-c80b87f17bd3",
            price: 16.74319,
            price_sources: [],
            processed_ms: 1715593112268,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "14a095b0-8c49-4e47-af58-e834ffc9966f",
            price: 16.73845,
            price_sources: [],
            processed_ms: 1715594055335,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "4e801275-8af2-4211-aa6a-628d2e754588",
            price: 16.7393,
            price_sources: [],
            processed_ms: 1715594376588,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "87142a01-24b4-47ea-a626-89841da1d29c",
            price: 16.74061,
            price_sources: [],
            processed_ms: 1715594914318,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "4bad5e85-5cb0-4c57-8b24-06c2b1124f80",
            price: 16.7249,
            price_sources: [],
            processed_ms: 1715595470639,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "a88d7ac0-2f53-4e85-89b1-02a20bd5487c",
            price: 16.73835,
            price_sources: [],
            processed_ms: 1715596276693,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "36ab78a4-f2e4-48a6-bfcb-1a8fb00fee1d",
            price: 16.7337,
            price_sources: [],
            processed_ms: 1715597760242,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "186d1567-9547-4e57-b959-5a402d8136f8",
            price: 16.73915,
            price_sources: [],
            processed_ms: 1715599111407,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "1902737d-4726-470c-8fd4-c51f277abf0e",
            price: 16.7247,
            price_sources: [],
            processed_ms: 1715599415799,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "96c92dba-a2b2-46d7-bca2-7cc21f9ccefb",
            price: 16.74371,
            price_sources: [],
            processed_ms: 1715600417156,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "be07cc58-d7a2-46d2-96db-269c1d7a4bbd",
            price: 16.74072,
            price_sources: [],
            processed_ms: 1715600735272,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "12b5f36e-e41c-4117-ac1b-26f21c1098af",
            price: 16.73635,
            price_sources: [],
            processed_ms: 1715601528180,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "9bbdffea-9e7d-416a-865c-f0e3faa276e0",
            price: 16.7351,
            price_sources: [],
            processed_ms: 1715602125370,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "33dd821b-7a72-4c99-9723-6ef993c16e5b",
            price: 16.73545,
            price_sources: [],
            processed_ms: 1715602482264,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "34a4a6e3-1dc4-47ea-9a04-796a77fbb74b",
            price: 16.7303,
            price_sources: [],
            processed_ms: 1715602818686,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "44e78bc2-78f8-409a-b288-fa3f4344e859",
            price: 16.742,
            price_sources: [],
            processed_ms: 1715603246119,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "8d62123f-0afb-478d-a480-7c06288bbb3d",
            price: 16.7408,
            price_sources: [],
            processed_ms: 1715604098364,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "4f406e4b-31f9-4a19-aaa6-73230562a83b",
            price: 16.7455,
            price_sources: [],
            processed_ms: 1715604405929,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "66607bbf-7f62-4f1c-8dd9-54ed40e26577",
            price: 16.7314,
            price_sources: [],
            processed_ms: 1715605526172,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "3906d6ea-670a-49e9-a92c-a8d47faccf5e",
            price: 16.74267,
            price_sources: [],
            processed_ms: 1715605924668,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "1e32c387-368e-4663-a2d1-abe72937baf8",
            price: 16.7258,
            price_sources: [],
            processed_ms: 1715606317154,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "9dcd2f0b-4901-4370-a34c-5e0e7b4fbd9a",
            price: 16.73195,
            price_sources: [],
            processed_ms: 1715606674527,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "ff30fd71-d283-47f9-8ac6-e4837faa9590",
            price: 16.73888,
            price_sources: [],
            processed_ms: 1715607072164,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "ba2dfeb8-304b-4e93-94ae-fed4d034925f",
            price: 16.74245,
            price_sources: [],
            processed_ms: 1715608119300,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "3943afd1-f93a-47a3-abd3-0ac42fd8dce5",
            price: 16.74115,
            price_sources: [],
            processed_ms: 1715608616852,
          },
          {
            leverage: -0.33000000000000024,
            order_type: "SHORT",
            order_uuid: "26ed282a-1637-40fc-ab8e-493b2fa86214",
            price: 16.75973,
            price_sources: [],
            processed_ms: 1715609190736,
          },
          {
            leverage: -0.08250000000000006,
            order_type: "SHORT",
            order_uuid: "4513b59f-422c-4380-9297-f5590d5fd78f",
            price: 16.7763,
            price_sources: [],
            processed_ms: 1715609432648,
          },
          {
            leverage: -0.12375000000000008,
            order_type: "SHORT",
            order_uuid: "8162bb19-16d4-469d-979e-17c6902ec4b9",
            price: 16.77755,
            price_sources: [],
            processed_ms: 1715609553254,
          },
          {
            leverage: -0.06187500000000004,
            order_type: "SHORT",
            order_uuid: "2d572916-d5cd-4842-8716-7430ad4ce0df",
            price: 16.78,
            price_sources: [],
            processed_ms: 1715609794635,
          },
          {
            leverage: -0.03093750000000002,
            order_type: "SHORT",
            order_uuid: "838c7a85-7454-499d-878c-53781fedb68e",
            price: 16.78545,
            price_sources: [],
            processed_ms: 1715609919306,
          },
          {
            leverage: -0.01546875000000001,
            order_type: "SHORT",
            order_uuid: "f2c1a719-f6d9-4ae8-9c81-400600eaa78d",
            price: 16.78623,
            price_sources: [],
            processed_ms: 1715610043802,
          },
          {
            leverage: -0.007734375000000005,
            order_type: "SHORT",
            order_uuid: "5111f093-1fc0-42d3-a99e-a7cd0474200d",
            price: 16.7842,
            price_sources: [],
            processed_ms: 1715610163528,
          },
          {
            leverage: 0.007734375000000005,
            order_type: "FLAT",
            order_uuid: "5b9e1f48-3021-4bd4-b306-4c95283bfcf2",
            price: 16.79305,
            price_sources: [],
            processed_ms: 1715610407222,
          },
        ],
        position_type: "FLAT",
        position_uuid: "19860f22-7532-4727-8514-24c335dbaad7",
        return_at_close: 1.0005870845251668,
        trade_pair: ["USDMXN", "USD/MXN", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 61363.16,
        close_ms: 1715612129738,
        current_return: 1.0024332840746792,
        initial_entry_price: 61363.16,
        is_closed_position: true,
        miner_hotkey: "5Dxqzduahnqw8q3XSUfTcEZGU7xmAsfJubhHZwvXVLN9fSjR",
        net_leverage: 0.0,
        open_ms: 1715529671676,
        orders: [
          {
            leverage: 0.1,
            order_type: "LONG",
            order_uuid: "daac6275-b1d3-4d10-8530-9697b6b83b54",
            price: 61363.16,
            price_sources: [],
            processed_ms: 1715529666375,
          },
          {
            leverage: 0.1,
            order_type: "FLAT",
            order_uuid: "142062b7-3aca-4ecf-a1ed-8cecc29621ac",
            price: 62856.3,
            price_sources: [],
            processed_ms: 1715612129738,
          },
        ],
        position_type: "FLAT",
        position_uuid: "b1ec2916-2632-41fb-88a0-d691c20f716f",
        return_at_close: 1.0023330407462718,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 0.66267,
        close_ms: 1715612851151,
        current_return: 1.0000041485012596,
        initial_entry_price: 0.66289,
        is_closed_position: true,
        miner_hotkey: "5Dxqzduahnqw8q3XSUfTcEZGU7xmAsfJubhHZwvXVLN9fSjR",
        net_leverage: 0.0,
        open_ms: 1715612529624,
        orders: [
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "722b571d-701c-4d8f-ad03-ce681bf7228c",
            price: 0.66289,
            price_sources: [],
            processed_ms: 1715612528182,
          },
          {
            leverage: -0.005,
            order_type: "SHORT",
            order_uuid: "30f7a61f-9a93-4a39-8b27-5e067bc65ef6",
            price: 0.66311,
            price_sources: [],
            processed_ms: 1715612730240,
          },
          {
            leverage: 0.005,
            order_type: "FLAT",
            order_uuid: "ea698e3f-48d8-4b01-a0b0-6faef20f9e52",
            price: 0.66322,
            price_sources: [],
            processed_ms: 1715612851151,
          },
        ],
        position_type: "FLAT",
        position_uuid: "bf2288fc-d3cd-45f4-a1f0-1b556cd8a033",
        return_at_close: 1.0000034484983558,
        trade_pair: ["CADCHF", "CAD/CHF", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 16.75644999999999,
        close_ms: 1715613003431,
        current_return: 1.0000143381663227,
        initial_entry_price: 16.79085,
        is_closed_position: true,
        miner_hotkey: "5Dxqzduahnqw8q3XSUfTcEZGU7xmAsfJubhHZwvXVLN9fSjR",
        net_leverage: 0.0,
        open_ms: 1715612364332,
        orders: [
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "bf60a745-d9ef-479c-abc3-7cc823578c4e",
            price: 16.79085,
            price_sources: [],
            processed_ms: 1715612362224,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "cec9615d-c965-4728-9eb0-caff09b4f5cd",
            price: 16.78825,
            price_sources: [],
            processed_ms: 1715612468176,
          },
          {
            leverage: -0.01,
            order_type: "SHORT",
            order_uuid: "1bb2f4ba-3839-4d03-a441-e45871acdff1",
            price: 16.79685,
            price_sources: [],
            processed_ms: 1715612619253,
          },
          {
            leverage: -0.005,
            order_type: "SHORT",
            order_uuid: "b3ad920a-a29f-464d-a2f8-aef7945fb641",
            price: 16.80805,
            price_sources: [],
            processed_ms: 1715612751611,
          },
          {
            leverage: 0.005,
            order_type: "FLAT",
            order_uuid: "2ae6de7a-8638-445b-90b1-0892515d8f1c",
            price: 16.8046,
            price_sources: [],
            processed_ms: 1715613003431,
          },
        ],
        position_type: "FLAT",
        position_uuid: "c1e6b658-dea7-417f-a8b9-f8fac130fe26",
        return_at_close: 1.0000129381462493,
        trade_pair: ["USDMXN", "USD/MXN", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.6622400000000004,
        close_ms: 1715616037176,
        current_return: 1.0000064856711917,
        initial_entry_price: 0.663,
        is_closed_position: true,
        miner_hotkey: "5Dxqzduahnqw8q3XSUfTcEZGU7xmAsfJubhHZwvXVLN9fSjR",
        net_leverage: 0.0,
        open_ms: 1715614654537,
        orders: [
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "66b0caee-2517-4fa7-927d-9f435ad1dec6",
            price: 0.663,
            price_sources: [],
            processed_ms: 1715614653315,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "51436452-e596-4708-8b31-4e4d93c9aa10",
            price: 0.6629,
            price_sources: [],
            processed_ms: 1715615346204,
          },
          {
            leverage: -0.01,
            order_type: "SHORT",
            order_uuid: "fa7f2797-4f02-41e4-af44-11bce998b010",
            price: 0.66313,
            price_sources: [],
            processed_ms: 1715615666257,
          },
          {
            leverage: -0.005,
            order_type: "SHORT",
            order_uuid: "6d54490a-10b9-41c6-9b9b-540a3d7ee32d",
            price: 0.6633,
            price_sources: [],
            processed_ms: 1715615787166,
          },
          {
            leverage: 0.005,
            order_type: "FLAT",
            order_uuid: "e6648621-e94f-4fd6-9970-9240142af299",
            price: 0.6631,
            price_sources: [],
            processed_ms: 1715616037176,
          },
        ],
        position_type: "FLAT",
        position_uuid: "8547f310-c919-48af-bd81-6e11067278c7",
        return_at_close: 1.0000050856621117,
        trade_pair: ["CADCHF", "CAD/CHF", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 16.750919999999994,
        close_ms: 1715617452220,
        current_return: 1.0000177348431205,
        initial_entry_price: 16.8059,
        is_closed_position: true,
        miner_hotkey: "5Dxqzduahnqw8q3XSUfTcEZGU7xmAsfJubhHZwvXVLN9fSjR",
        net_leverage: 0.0,
        open_ms: 1715614879651,
        orders: [
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "0958b0bb-5cc3-4d87-9436-4fdf36c43f13",
            price: 16.8059,
            price_sources: [],
            processed_ms: 1715614878373,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "35be1169-e3b1-4f85-ace0-e13c3c42e467",
            price: 16.8059,
            price_sources: [],
            processed_ms: 1715615680810,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "dbb003d9-7adf-437e-8e30-f5acfddd29b4",
            price: 16.80813,
            price_sources: [],
            processed_ms: 1715616007284,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "d3db8b11-7142-4171-8f47-972eb0a385dc",
            price: 16.7928,
            price_sources: [],
            processed_ms: 1715616766233,
          },
          {
            leverage: -0.02,
            order_type: "SHORT",
            order_uuid: "1285996a-106e-423d-abad-d66747951e32",
            price: 16.8104,
            price_sources: [],
            processed_ms: 1715617014289,
          },
          {
            leverage: -0.01,
            order_type: "SHORT",
            order_uuid: "436b527d-37ef-4cea-a722-c0fa32eeb140",
            price: 16.81107,
            price_sources: [],
            processed_ms: 1715617136175,
          },
          {
            leverage: -0.005,
            order_type: "SHORT",
            order_uuid: "bd1e7606-3a3a-43aa-a98b-201822740b29",
            price: 16.8108,
            price_sources: [],
            processed_ms: 1715617264805,
          },
          {
            leverage: 0.005,
            order_type: "FLAT",
            order_uuid: "9660d2be-cc50-484d-ab5b-66bd37743948",
            price: 16.81053,
            price_sources: [],
            processed_ms: 1715617452220,
          },
        ],
        position_type: "FLAT",
        position_uuid: "fa612d10-ab0c-494c-9513-c75447ad8380",
        return_at_close: 1.000014934793463,
        trade_pair: ["USDMXN", "USD/MXN", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 16.805233333333334,
        close_ms: 1715620053492,
        current_return: 1.0000100800694809,
        initial_entry_price: 16.8104,
        is_closed_position: true,
        miner_hotkey: "5Dxqzduahnqw8q3XSUfTcEZGU7xmAsfJubhHZwvXVLN9fSjR",
        net_leverage: 0.0,
        open_ms: 1715619592330,
        orders: [
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "a098ea24-c86c-478c-a2f2-646c2f504e5f",
            price: 16.8104,
            price_sources: [],
            processed_ms: 1715619590344,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "98d435d5-f326-4705-a29c-146f22936a91",
            price: 16.806,
            price_sources: [],
            processed_ms: 1715619770219,
          },
          {
            leverage: -0.005,
            order_type: "SHORT",
            order_uuid: "a7eba73f-24c9-4ab3-bbb0-34d0669effcf",
            price: 16.8171,
            price_sources: [],
            processed_ms: 1715619929198,
          },
          {
            leverage: 0.005,
            order_type: "FLAT",
            order_uuid: "654b31f7-5013-49a2-ae9e-07eab2deceee",
            price: 16.81653,
            price_sources: [],
            processed_ms: 1715620053492,
          },
        ],
        position_type: "FLAT",
        position_uuid: "95db2b27-4998-4b91-bc67-73a889d6f222",
        return_at_close: 1.0000086800553687,
        trade_pair: ["USDMXN", "USD/MXN", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.6586799999999993,
        close_ms: 1715620847160,
        current_return: 1.0000048130538308,
        initial_entry_price: 0.66356,
        is_closed_position: true,
        miner_hotkey: "5Dxqzduahnqw8q3XSUfTcEZGU7xmAsfJubhHZwvXVLN9fSjR",
        net_leverage: 0.0,
        open_ms: 1715617858636,
        orders: [
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "12ae2f25-95c4-482b-b777-fad69c353ef5",
            price: 0.66356,
            price_sources: [],
            processed_ms: 1715617856222,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "9924d484-c9df-4a52-9774-b3a06a071632",
            price: 0.66356,
            price_sources: [],
            processed_ms: 1715618170184,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "496ca695-4f0d-41e2-923c-667d2c31379e",
            price: 0.66354,
            price_sources: [],
            processed_ms: 1715618472424,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "913811db-c997-4336-9d77-3a88929ff502",
            price: 0.6631,
            price_sources: [],
            processed_ms: 1715618775315,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "4014a8d6-f578-4119-a826-503e19294e2d",
            price: 0.6629,
            price_sources: [],
            processed_ms: 1715619137236,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "f08fecff-dcd6-48f2-99f0-1627ccd0528e",
            price: 0.66342,
            price_sources: [],
            processed_ms: 1715619439376,
          },
          {
            leverage: -0.015000000000000001,
            order_type: "SHORT",
            order_uuid: "d7cf343b-482a-4d03-9515-d29891cb64e4",
            price: 0.66354,
            price_sources: [],
            processed_ms: 1715619930282,
          },
          {
            leverage: -0.027500000000000004,
            order_type: "SHORT",
            order_uuid: "92a44947-feb9-45bf-a39d-43c4258299f3",
            price: 0.6632,
            price_sources: [],
            processed_ms: 1715620356350,
          },
          {
            leverage: -0.011250000000000001,
            order_type: "SHORT",
            order_uuid: "67691d1a-12ca-456e-8ab1-1c3b821669aa",
            price: 0.6635,
            price_sources: [],
            processed_ms: 1715620600208,
          },
          {
            leverage: -0.005625000000000001,
            order_type: "SHORT",
            order_uuid: "96d707b0-b918-4a96-bc9b-31b6fe4adb30",
            price: 0.66376,
            price_sources: [],
            processed_ms: 1715620721538,
          },
          {
            leverage: 0.00625,
            order_type: "FLAT",
            order_uuid: "8cd00304-7063-444e-a1c8-a11387c78ca4",
            price: 0.66379,
            price_sources: [],
            processed_ms: 1715620847160,
          },
        ],
        position_type: "FLAT",
        position_uuid: "3dc264c7-6411-4161-8fe3-fa971bad3573",
        return_at_close: 1.000000613033616,
        trade_pair: ["CADCHF", "CAD/CHF", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.6570813333333281,
        close_ms: 1715633771759,
        current_return: 1.0002095289740462,
        initial_entry_price: 0.66387,
        is_closed_position: true,
        miner_hotkey: "5Dxqzduahnqw8q3XSUfTcEZGU7xmAsfJubhHZwvXVLN9fSjR",
        net_leverage: 0.0,
        open_ms: 1715622724672,
        orders: [
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "805d830e-fa40-4b8f-8bfb-b9364a8be009",
            price: 0.66387,
            price_sources: [],
            processed_ms: 1715622718567,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "f4b31dd5-75e1-4b94-ae93-3dfcc1841aeb",
            price: 0.66392,
            price_sources: [],
            processed_ms: 1715623211319,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "7716d8bb-e8ac-4188-a2c5-bccc3ec41ecd",
            price: 0.6635,
            price_sources: [],
            processed_ms: 1715623575458,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "310a1a3d-fb34-43be-942d-6c34e586afea",
            price: 0.6637,
            price_sources: [],
            processed_ms: 1715623650526,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "d5b92c6d-06cf-43c5-a982-e125f095314e",
            price: 0.6635,
            price_sources: [],
            processed_ms: 1715623828305,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "c12c658f-9c10-4a6e-b1c1-b5f1f1f8fd24",
            price: 0.66396,
            price_sources: [],
            processed_ms: 1715624182759,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "c32204e2-f745-434a-86e8-9ea0ac61cb99",
            price: 0.6636,
            price_sources: [],
            processed_ms: 1715624345407,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "618d7fd0-2282-4a5c-97ab-ab70a3d93f23",
            price: 0.6635,
            price_sources: [],
            processed_ms: 1715624422316,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "739c7555-4f59-406b-a4ce-f51dd2f4b2be",
            price: 0.6635,
            price_sources: [],
            processed_ms: 1715625072524,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "c9de8257-dab3-4a4d-ae4f-908690b42b5c",
            price: 0.6635,
            price_sources: [],
            processed_ms: 1715625404928,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "c7f44b7c-e79c-4ed4-9b3d-b791118fc8b8",
            price: 0.66396,
            price_sources: [],
            processed_ms: 1715625728484,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "853a3288-abbf-45db-a362-9020eea15000",
            price: 0.6635,
            price_sources: [],
            processed_ms: 1715625976656,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "32f2ca2e-c6c5-45c4-8153-a414532e2997",
            price: 0.6639,
            price_sources: [],
            processed_ms: 1715627382671,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "87480bd0-e106-4082-832a-1a4ab3112cd6",
            price: 0.6643,
            price_sources: [],
            processed_ms: 1715627457610,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "a9df714e-48ba-4ab0-ad5b-952cfc37aa73",
            price: 0.6639,
            price_sources: [],
            processed_ms: 1715627530152,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "f7b5e7ff-8fcd-48b3-9727-755a16f8525f",
            price: 0.6641,
            price_sources: [],
            processed_ms: 1715627887084,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "13098285-2132-483a-838d-ce417691be07",
            price: 0.6641,
            price_sources: [],
            processed_ms: 1715628166360,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "239ed2e3-4cd8-4a12-b678-344621bdc8b4",
            price: 0.66453,
            price_sources: [],
            processed_ms: 1715628516684,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "ce80ed9b-ef40-4191-b339-4b96c1e2e8f4",
            price: 0.66461,
            price_sources: [],
            processed_ms: 1715628820502,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "a6b3b25d-37f3-4b51-888a-fd3aecb9a0b9",
            price: 0.66473,
            price_sources: [],
            processed_ms: 1715629113679,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "b1792167-ed4e-4a3b-aa2a-9ce353f96c17",
            price: 0.66467,
            price_sources: [],
            processed_ms: 1715629430231,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "18952d2a-2389-4553-a912-186c4f7bd729",
            price: 0.66444,
            price_sources: [],
            processed_ms: 1715629748939,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "0f941817-5eca-413b-8c6a-1ee39e641036",
            price: 0.66434,
            price_sources: [],
            processed_ms: 1715630376663,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "1e1e93d2-b7c1-45cc-9ea6-f617881710d8",
            price: 0.66417,
            price_sources: [],
            processed_ms: 1715631100812,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "042bd10b-e678-4a6d-960d-d2007fac065b",
            price: 0.66416,
            price_sources: [],
            processed_ms: 1715631574685,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "07f9e33a-4015-4baa-b284-e7264f99d98c",
            price: 0.66424,
            price_sources: [],
            processed_ms: 1715631954732,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "8166de76-6fbb-4a24-9fa5-5461aa574644",
            price: 0.66436,
            price_sources: [],
            processed_ms: 1715632319406,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "1de0dd96-df86-470e-9e30-48047a4fb490",
            price: 0.66445,
            price_sources: [],
            processed_ms: 1715632419531,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "a066931d-c8ab-4ec3-b3cc-fa4f62cbaf16",
            price: 0.66457,
            price_sources: [],
            processed_ms: 1715632728193,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "ec4ea1e0-cd8d-4030-822d-7d35743119d0",
            price: 0.66456,
            price_sources: [],
            processed_ms: 1715632900461,
          },
          {
            leverage: -0.15000000000000005,
            order_type: "SHORT",
            order_uuid: "c35108e3-bb09-48b6-ac77-d1fb301674a6",
            price: 0.66456,
            price_sources: [],
            processed_ms: 1715633021351,
          },
          {
            leverage: -0.07500000000000002,
            order_type: "SHORT",
            order_uuid: "0e300dd4-7f59-4d90-b3d8-42db99bf80e2",
            price: 0.66458,
            price_sources: [],
            processed_ms: 1715633270837,
          },
          {
            leverage: -0.03750000000000001,
            order_type: "SHORT",
            order_uuid: "41082cb1-352a-40bf-8aeb-9f11c650eccc",
            price: 0.66458,
            price_sources: [],
            processed_ms: 1715633391243,
          },
          {
            leverage: -0.018750000000000006,
            order_type: "SHORT",
            order_uuid: "dd2969e4-88f7-4b41-8c80-e1b3ef7db450",
            price: 0.6641,
            price_sources: [],
            processed_ms: 1715633513251,
          },
          {
            leverage: 0.009375000000000003,
            order_type: "FLAT",
            order_uuid: "f13e32c4-14ab-4919-a87c-6d7ceb3ef891",
            price: 0.6645,
            price_sources: [],
            processed_ms: 1715633771759,
          },
        ],
        position_type: "FLAT",
        position_uuid: "7f0723ad-befb-4e2e-a592-89d2b7aa80fc",
        return_at_close: 1.0001885245739377,
        trade_pair: ["CADCHF", "CAD/CHF", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 114.04266666666669,
        close_ms: 1715635347365,
        current_return: 1.0000134122754045,
        initial_entry_price: 114.261,
        is_closed_position: true,
        miner_hotkey: "5Dxqzduahnqw8q3XSUfTcEZGU7xmAsfJubhHZwvXVLN9fSjR",
        net_leverage: 0.0,
        open_ms: 1715634088629,
        orders: [
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "4a22f36f-67f4-445c-94eb-959f862ddef7",
            price: 114.261,
            price_sources: [],
            processed_ms: 1715634084126,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "8a85e2f7-98f5-4e9d-aa64-a4706da7ae51",
            price: 114.124,
            price_sources: [],
            processed_ms: 1715634157168,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "e4a5babe-368b-408c-944a-4a62d98cfd85",
            price: 114.25,
            price_sources: [],
            processed_ms: 1715634489195,
          },
          {
            leverage: -0.015,
            order_type: "SHORT",
            order_uuid: "f8ca8a66-f260-435b-8bd3-307ee85388c4",
            price: 114.26,
            price_sources: [],
            processed_ms: 1715634820196,
          },
          {
            leverage: -0.0075,
            order_type: "SHORT",
            order_uuid: "0e21589d-41e9-4a5e-a10a-9c1bf5bce22f",
            price: 114.284,
            price_sources: [],
            processed_ms: 1715635221327,
          },
          {
            leverage: 0.0075,
            order_type: "FLAT",
            order_uuid: "13d3d3a6-ee1f-477c-b7a1-c1634a05a241",
            price: 114.247,
            price_sources: [],
            processed_ms: 1715635347365,
          },
        ],
        position_type: "FLAT",
        position_uuid: "e21a14d2-6b17-4dae-89f5-ddd890b4edd8",
        return_at_close: 1.0000113122472387,
        trade_pair: ["CADJPY", "CAD/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.6634000000000002,
        close_ms: 1715635987183,
        current_return: 1.0000073053170657,
        initial_entry_price: 0.6639,
        is_closed_position: true,
        miner_hotkey: "5Dxqzduahnqw8q3XSUfTcEZGU7xmAsfJubhHZwvXVLN9fSjR",
        net_leverage: 0.0,
        open_ms: 1715635731132,
        orders: [
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "96e46da9-f583-4bbd-8af4-00e227a4ad94",
            price: 0.6639,
            price_sources: [],
            processed_ms: 1715635728317,
          },
          {
            leverage: -0.005,
            order_type: "SHORT",
            order_uuid: "59b824fc-4791-420e-a6c6-396366383c9a",
            price: 0.6644,
            price_sources: [],
            processed_ms: 1715635853383,
          },
          {
            leverage: 0.005,
            order_type: "FLAT",
            order_uuid: "cf5ede36-962d-4523-9d9f-f1738f05a9b1",
            price: 0.66437,
            price_sources: [],
            processed_ms: 1715635987183,
          },
        ],
        position_type: "FLAT",
        position_uuid: "19df44b1-903b-45b5-9218-bdf68cf39685",
        return_at_close: 1.000006605311952,
        trade_pair: ["CADCHF", "CAD/CHF", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.6630900000000003,
        close_ms: 1715638508661,
        current_return: 1.0000110619469027,
        initial_entry_price: 0.66444,
        is_closed_position: true,
        miner_hotkey: "5Dxqzduahnqw8q3XSUfTcEZGU7xmAsfJubhHZwvXVLN9fSjR",
        net_leverage: 0.0,
        open_ms: 1715638034300,
        orders: [
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "cef2d9b1-20bf-4de7-95c6-9f9f0e94fd3e",
            price: 0.66444,
            price_sources: [],
            processed_ms: 1715638032707,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "c258d159-ccb8-4389-8fae-617ca0038b45",
            price: 0.6639,
            price_sources: [],
            processed_ms: 1715638163812,
          },
          {
            leverage: -0.01,
            order_type: "SHORT",
            order_uuid: "2883f6dc-6083-45ad-a543-39b94c012e3b",
            price: 0.66452,
            price_sources: [],
            processed_ms: 1715638266233,
          },
          {
            leverage: -0.005,
            order_type: "SHORT",
            order_uuid: "31ee62b5-06ea-4d0e-9169-5b647077b09c",
            price: 0.66455,
            price_sources: [],
            processed_ms: 1715638389136,
          },
          {
            leverage: 0.005,
            order_type: "FLAT",
            order_uuid: "83fef9eb-38d2-423d-9feb-7dd9b564c356",
            price: 0.66456,
            price_sources: [],
            processed_ms: 1715638508661,
          },
        ],
        position_type: "FLAT",
        position_uuid: "774308f9-ef4a-4638-819d-e978d3286a21",
        return_at_close: 1.0000096619314158,
        trade_pair: ["CADCHF", "CAD/CHF", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 16.81235454545435,
        close_ms: 1715640597742,
        current_return: 0.9999941338511831,
        initial_entry_price: 16.8099,
        is_closed_position: true,
        miner_hotkey: "5Dxqzduahnqw8q3XSUfTcEZGU7xmAsfJubhHZwvXVLN9fSjR",
        net_leverage: 0.0,
        open_ms: 1715622003368,
        orders: [
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "ea791b0e-f455-4a35-b203-f9ceedbb1418",
            price: 16.8099,
            price_sources: [],
            processed_ms: 1715622000225,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "907ec64e-e113-4ff5-bea8-f0c42c14525a",
            price: 16.80883,
            price_sources: [],
            processed_ms: 1715622065386,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "b140b094-b088-431f-bbb3-ddf4b41176de",
            price: 16.80855,
            price_sources: [],
            processed_ms: 1715622171219,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "2779f593-43ec-4c6a-b3b7-fb04c3997809",
            price: 16.8113,
            price_sources: [],
            processed_ms: 1715622467306,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "6bffd276-eaf0-4ad0-bc52-9fac743decf3",
            price: 16.8113,
            price_sources: [],
            processed_ms: 1715622864615,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "2cb500f5-b3c0-4aed-a3f1-2c5134c8cab7",
            price: 16.8043,
            price_sources: [],
            processed_ms: 1715623638272,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "7a9e87fe-fc07-424c-b4ff-c3e4bd8d5c87",
            price: 16.7994,
            price_sources: [],
            processed_ms: 1715624000812,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "e909d9f6-ef47-48ad-9e49-13431d36cf20",
            price: 16.8058,
            price_sources: [],
            processed_ms: 1715624774007,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "f7b65b45-3070-421a-a19d-683c2b4b97ab",
            price: 16.8062,
            price_sources: [],
            processed_ms: 1715626249648,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "fad2b2e7-d28e-4a8c-8657-2eaf5dc2c82e",
            price: 16.7936,
            price_sources: [],
            processed_ms: 1715626675660,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "1b6eb19d-c1eb-4aa5-a339-b2ee8c5dcfd7",
            price: 16.80265,
            price_sources: [],
            processed_ms: 1715626969771,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "f0e6e394-993d-47de-be5e-5137d0897f8f",
            price: 16.80345,
            price_sources: [],
            processed_ms: 1715627285936,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "2fb8b086-6861-4188-9ed0-8b8f10929466",
            price: 16.796,
            price_sources: [],
            processed_ms: 1715627955468,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "b69417af-27a1-4b6a-aee5-6f6ca41cf634",
            price: 16.80122,
            price_sources: [],
            processed_ms: 1715628400780,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "8045f645-d7ec-4057-b9eb-3de7f152a800",
            price: 16.801,
            price_sources: [],
            processed_ms: 1715628737252,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "37e743e0-607b-4f29-af00-017ebf1f7f8e",
            price: 16.80911,
            price_sources: [],
            processed_ms: 1715629573648,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "2c6beed6-ac56-42ea-a395-9264897e29ca",
            price: 16.80658,
            price_sources: [],
            processed_ms: 1715629914546,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "45c30507-73b5-4f89-b0d1-4361ba027ea5",
            price: 16.809,
            price_sources: [],
            processed_ms: 1715630298275,
          },
          {
            leverage: -0.015,
            order_type: "SHORT",
            order_uuid: "dfeaea13-fb6b-4ad6-9758-899343171e84",
            price: 16.8024,
            price_sources: [],
            processed_ms: 1715631379513,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "3294b465-8057-48cf-9edd-65c6d0c9908d",
            price: 16.7877,
            price_sources: [],
            processed_ms: 1715634303268,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "3b95304f-1911-46e8-a71d-f61c6bbad791",
            price: 16.77259,
            price_sources: [],
            processed_ms: 1715634371304,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "e9a07189-6024-4fc4-b3d0-7f7648720531",
            price: 16.7879,
            price_sources: [],
            processed_ms: 1715634583357,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "c0d58e76-adb8-4230-870b-ce43c9a0c651",
            price: 16.7876,
            price_sources: [],
            processed_ms: 1715634671275,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "89a6deab-ad83-4342-a8ab-f7c26ab07a8b",
            price: 16.7632,
            price_sources: [],
            processed_ms: 1715634971135,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "2cbee65c-f514-435d-86ca-c562d847bb0d",
            price: 16.76324,
            price_sources: [],
            processed_ms: 1715635061125,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "a3d32758-5c15-4c91-ab81-63c4ef321f38",
            price: 16.77856,
            price_sources: [],
            processed_ms: 1715635507408,
          },
          {
            leverage: -0.11750000000000005,
            order_type: "SHORT",
            order_uuid: "6c362091-81b7-4e86-a492-501aa11f63e8",
            price: 16.80306,
            price_sources: [],
            processed_ms: 1715637640299,
          },
          {
            leverage: -0.058750000000000024,
            order_type: "SHORT",
            order_uuid: "c0de4f4b-6fc8-4b12-addf-3121403fb577",
            price: 16.7893,
            price_sources: [],
            processed_ms: 1715638140380,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "1a8ced2d-128a-48ae-863d-d1d535f69d03",
            price: 16.7864,
            price_sources: [],
            processed_ms: 1715639758806,
          },
          {
            leverage: -0.03437500000000001,
            order_type: "SHORT",
            order_uuid: "59dbdb0c-601a-4568-aab6-85c94bb1d353",
            price: 16.7877,
            price_sources: [],
            processed_ms: 1715639880420,
          },
          {
            leverage: -0.017187500000000005,
            order_type: "SHORT",
            order_uuid: "acfad283-54e4-4ab2-a508-fa4e4da4f93c",
            price: 16.7885,
            price_sources: [],
            processed_ms: 1715640291999,
          },
          {
            leverage: -0.008593750000000002,
            order_type: "SHORT",
            order_uuid: "0db1ac8c-19fa-424b-b932-36405627206a",
            price: 16.7885,
            price_sources: [],
            processed_ms: 1715640439375,
          },
          {
            leverage: 0.008593750000000002,
            order_type: "FLAT",
            order_uuid: "2630214c-0997-4a18-b950-e461997ab5da",
            price: 16.80088,
            price_sources: [],
            processed_ms: 1715640597742,
          },
        ],
        position_type: "FLAT",
        position_uuid: "13c3e247-5b28-43ed-bc4a-322fad8b625d",
        return_at_close: 0.999975933957947,
        trade_pair: ["USDMXN", "USD/MXN", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.6639,
        close_ms: 1715640636354,
        current_return: 1.000008585630366,
        initial_entry_price: 0.6639,
        is_closed_position: true,
        miner_hotkey: "5Dxqzduahnqw8q3XSUfTcEZGU7xmAsfJubhHZwvXVLN9fSjR",
        net_leverage: 0.0,
        open_ms: 1715640380280,
        orders: [
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "6826bc44-273d-4991-83fc-d2dcf622167e",
            price: 0.6639,
            price_sources: [],
            processed_ms: 1715640377360,
          },
          {
            leverage: 0.005,
            order_type: "FLAT",
            order_uuid: "01cddc22-a209-40f2-8dae-bbf0a11a09cb",
            price: 0.66447,
            price_sources: [],
            processed_ms: 1715640636354,
          },
        ],
        position_type: "FLAT",
        position_uuid: "fc7f8d67-6a60-4890-9b6b-7ee881e25a6a",
        return_at_close: 1.0000078856243562,
        trade_pair: ["CADCHF", "CAD/CHF", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.66445,
        close_ms: 1715640894556,
        current_return: 1.000000150500414,
        initial_entry_price: 0.66445,
        is_closed_position: true,
        miner_hotkey: "5Dxqzduahnqw8q3XSUfTcEZGU7xmAsfJubhHZwvXVLN9fSjR",
        net_leverage: 0.0,
        open_ms: 1715640778946,
        orders: [
          {
            leverage: -0.005,
            order_type: "SHORT",
            order_uuid: "01b610ac-2f4a-451d-9d44-b2527cee058f",
            price: 0.66445,
            price_sources: [],
            processed_ms: 1715640775126,
          },
          {
            leverage: 0.005,
            order_type: "FLAT",
            order_uuid: "11e00a1d-9228-428e-9a06-07f96aa98825",
            price: 0.66443,
            price_sources: [],
            processed_ms: 1715640894556,
          },
        ],
        position_type: "FLAT",
        position_uuid: "a7267eef-cc94-4e1e-aeca-ba28d530d1ba",
        return_at_close: 0.9999998005003613,
        trade_pair: ["CADCHF", "CAD/CHF", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.66444,
        close_ms: 1715641209544,
        current_return: 1.0000006020107157,
        initial_entry_price: 0.66444,
        is_closed_position: true,
        miner_hotkey: "5Dxqzduahnqw8q3XSUfTcEZGU7xmAsfJubhHZwvXVLN9fSjR",
        net_leverage: 0.0,
        open_ms: 1715641089288,
        orders: [
          {
            leverage: -0.005,
            order_type: "SHORT",
            order_uuid: "d67edde7-a6ac-48b7-9602-3a8490061adf",
            price: 0.66444,
            price_sources: [],
            processed_ms: 1715641084792,
          },
          {
            leverage: 0.005,
            order_type: "FLAT",
            order_uuid: "2c4640ac-7450-4170-a35f-9eb6fc5de1a6",
            price: 0.66436,
            price_sources: [],
            processed_ms: 1715641209544,
          },
        ],
        position_type: "FLAT",
        position_uuid: "6cdbdbdc-f053-4c6f-9be0-219d59586f38",
        return_at_close: 1.0000002520105051,
        trade_pair: ["CADCHF", "CAD/CHF", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.66434,
        close_ms: 1715641523557,
        current_return: 0.9999997742119999,
        initial_entry_price: 0.66434,
        is_closed_position: true,
        miner_hotkey: "5Dxqzduahnqw8q3XSUfTcEZGU7xmAsfJubhHZwvXVLN9fSjR",
        net_leverage: 0.0,
        open_ms: 1715641406991,
        orders: [
          {
            leverage: -0.005,
            order_type: "SHORT",
            order_uuid: "bdee8c1d-e89c-4bae-9a4d-248b5dbfa54c",
            price: 0.66434,
            price_sources: [],
            processed_ms: 1715641400140,
          },
          {
            leverage: 0.005,
            order_type: "FLAT",
            order_uuid: "7eee2dc5-a0cc-4c69-910a-4c659a858ea9",
            price: 0.66437,
            price_sources: [],
            processed_ms: 1715641523557,
          },
        ],
        position_type: "FLAT",
        position_uuid: "3ef6db68-3037-49ec-882a-ce9f133fd18c",
        return_at_close: 0.999999424212079,
        trade_pair: ["CADCHF", "CAD/CHF", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.6644,
        close_ms: 1715641837810,
        current_return: 1.00000007525587,
        initial_entry_price: 0.6644,
        is_closed_position: true,
        miner_hotkey: "5Dxqzduahnqw8q3XSUfTcEZGU7xmAsfJubhHZwvXVLN9fSjR",
        net_leverage: 0.0,
        open_ms: 1715641719409,
        orders: [
          {
            leverage: -0.005,
            order_type: "SHORT",
            order_uuid: "f115f005-fcfa-47fc-bc12-36ddc95b27fd",
            price: 0.6644,
            price_sources: [],
            processed_ms: 1715641716748,
          },
          {
            leverage: 0.005,
            order_type: "FLAT",
            order_uuid: "d33e91a5-b431-4387-bcdc-5d2fda51c020",
            price: 0.66439,
            price_sources: [],
            processed_ms: 1715641837810,
          },
        ],
        position_type: "FLAT",
        position_uuid: "a2e8f9d9-b3cf-45a5-8f0e-47d79fab76db",
        return_at_close: 0.9999997252558437,
        trade_pair: ["CADCHF", "CAD/CHF", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.66439,
        close_ms: 1715642171708,
        current_return: 1.000002935023104,
        initial_entry_price: 0.66439,
        is_closed_position: true,
        miner_hotkey: "5Dxqzduahnqw8q3XSUfTcEZGU7xmAsfJubhHZwvXVLN9fSjR",
        net_leverage: 0.0,
        open_ms: 1715642053481,
        orders: [
          {
            leverage: -0.005,
            order_type: "SHORT",
            order_uuid: "1543c92b-28a6-423a-99cc-637359b30ee3",
            price: 0.66439,
            price_sources: [],
            processed_ms: 1715642050480,
          },
          {
            leverage: 0.005,
            order_type: "FLAT",
            order_uuid: "e10f08b6-6036-401e-89dd-629dd1704151",
            price: 0.664,
            price_sources: [],
            processed_ms: 1715642171708,
          },
        ],
        position_type: "FLAT",
        position_uuid: "cc1dda2a-cad5-4c8e-afb3-e49e2e210135",
        return_at_close: 1.0000025850220766,
        trade_pair: ["CADCHF", "CAD/CHF", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.66439,
        close_ms: 1715642464086,
        current_return: 1.0000000752570026,
        initial_entry_price: 0.66439,
        is_closed_position: true,
        miner_hotkey: "5Dxqzduahnqw8q3XSUfTcEZGU7xmAsfJubhHZwvXVLN9fSjR",
        net_leverage: 0.0,
        open_ms: 1715642347039,
        orders: [
          {
            leverage: -0.005,
            order_type: "SHORT",
            order_uuid: "2a20825c-0617-43d4-8d92-f16fa69d092f",
            price: 0.66439,
            price_sources: [],
            processed_ms: 1715642342705,
          },
          {
            leverage: 0.005,
            order_type: "FLAT",
            order_uuid: "f07be84a-e4fe-43f6-990a-a3ef6f2065de",
            price: 0.66438,
            price_sources: [],
            processed_ms: 1715642464086,
          },
        ],
        position_type: "FLAT",
        position_uuid: "167cfd99-dc97-442d-b89d-0b03caaca190",
        return_at_close: 0.9999997252569763,
        trade_pair: ["CADCHF", "CAD/CHF", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.66443,
        close_ms: 1715642774177,
        current_return: 1.0000003762623602,
        initial_entry_price: 0.66443,
        is_closed_position: true,
        miner_hotkey: "5Dxqzduahnqw8q3XSUfTcEZGU7xmAsfJubhHZwvXVLN9fSjR",
        net_leverage: 0.0,
        open_ms: 1715642660235,
        orders: [
          {
            leverage: -0.005,
            order_type: "SHORT",
            order_uuid: "7a3f2cdd-1bde-4227-bf89-816ea7491533",
            price: 0.66443,
            price_sources: [],
            processed_ms: 1715642654091,
          },
          {
            leverage: 0.005,
            order_type: "FLAT",
            order_uuid: "ff15316c-42e6-4813-a77a-e961e83c5d00",
            price: 0.66438,
            price_sources: [],
            processed_ms: 1715642774177,
          },
        ],
        position_type: "FLAT",
        position_uuid: "9d1449b2-e60e-4e98-af0b-49262a4b6dfd",
        return_at_close: 1.0000000262622284,
        trade_pair: ["CADCHF", "CAD/CHF", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.66435,
        close_ms: 1715643101516,
        current_return: 1.000002634153684,
        initial_entry_price: 0.66435,
        is_closed_position: true,
        miner_hotkey: "5Dxqzduahnqw8q3XSUfTcEZGU7xmAsfJubhHZwvXVLN9fSjR",
        net_leverage: 0.0,
        open_ms: 1715642979077,
        orders: [
          {
            leverage: -0.005,
            order_type: "SHORT",
            order_uuid: "94dcb3aa-912c-4b76-89b1-ac5136782bfd",
            price: 0.66435,
            price_sources: [],
            processed_ms: 1715642974880,
          },
          {
            leverage: 0.005,
            order_type: "FLAT",
            order_uuid: "98dbd4c4-a6ee-45f9-96d7-7eeada046a1d",
            price: 0.664,
            price_sources: [],
            processed_ms: 1715643101516,
          },
        ],
        position_type: "FLAT",
        position_uuid: "44364c3a-a73d-49bc-bb94-7266de1e9dfc",
        return_at_close: 1.000002284152762,
        trade_pair: ["CADCHF", "CAD/CHF", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.66431,
        close_ms: 1715643708481,
        current_return: 1.000000150532131,
        initial_entry_price: 0.66431,
        is_closed_position: true,
        miner_hotkey: "5Dxqzduahnqw8q3XSUfTcEZGU7xmAsfJubhHZwvXVLN9fSjR",
        net_leverage: 0.0,
        open_ms: 1715643597111,
        orders: [
          {
            leverage: -0.005,
            order_type: "SHORT",
            order_uuid: "efa78614-7f65-4320-b88c-f702db67f4e3",
            price: 0.66431,
            price_sources: [],
            processed_ms: 1715643593085,
          },
          {
            leverage: 0.005,
            order_type: "FLAT",
            order_uuid: "36f6cec5-240d-418a-b598-94f69a9eb294",
            price: 0.66429,
            price_sources: [],
            processed_ms: 1715643708481,
          },
        ],
        position_type: "FLAT",
        position_uuid: "99d9c394-473f-4e28-9ad9-4e783c37b66e",
        return_at_close: 0.9999998005320783,
        trade_pair: ["CADCHF", "CAD/CHF", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.66433,
        close_ms: 1715644023633,
        current_return: 0.9999997742086012,
        initial_entry_price: 0.66433,
        is_closed_position: true,
        miner_hotkey: "5Dxqzduahnqw8q3XSUfTcEZGU7xmAsfJubhHZwvXVLN9fSjR",
        net_leverage: 0.0,
        open_ms: 1715643908743,
        orders: [
          {
            leverage: -0.005,
            order_type: "SHORT",
            order_uuid: "a5f6de4d-e0ff-4091-88e0-1bde5d3fe249",
            price: 0.66433,
            price_sources: [],
            processed_ms: 1715643902599,
          },
          {
            leverage: 0.005,
            order_type: "FLAT",
            order_uuid: "1afe53fa-096d-4f22-919f-c0ffb2d3e5dd",
            price: 0.66436,
            price_sources: [],
            processed_ms: 1715644023633,
          },
        ],
        position_type: "FLAT",
        position_uuid: "5b9418bf-1b19-4877-9ef7-5e74d232bc93",
        return_at_close: 0.9999994242086803,
        trade_pair: ["CADCHF", "CAD/CHF", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.664,
        close_ms: 1715644653476,
        current_return: 0.9999975150602409,
        initial_entry_price: 0.664,
        is_closed_position: true,
        miner_hotkey: "5Dxqzduahnqw8q3XSUfTcEZGU7xmAsfJubhHZwvXVLN9fSjR",
        net_leverage: 0.0,
        open_ms: 1715644538345,
        orders: [
          {
            leverage: -0.005,
            order_type: "SHORT",
            order_uuid: "d0bc9c21-0896-4676-a454-5a2adfa93f54",
            price: 0.664,
            price_sources: [],
            processed_ms: 1715644531652,
          },
          {
            leverage: 0.005,
            order_type: "FLAT",
            order_uuid: "fc418e2f-1391-47bf-8ec2-67bbeadae5a4",
            price: 0.66433,
            price_sources: [],
            processed_ms: 1715644653476,
          },
        ],
        position_type: "FLAT",
        position_uuid: "69bc2e07-97f4-4b19-a1b6-ad06d4f91564",
        return_at_close: 0.9999971650611107,
        trade_pair: ["CADCHF", "CAD/CHF", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.66433,
        close_ms: 1715644964304,
        current_return: 0.9999999247362004,
        initial_entry_price: 0.66433,
        is_closed_position: true,
        miner_hotkey: "5Dxqzduahnqw8q3XSUfTcEZGU7xmAsfJubhHZwvXVLN9fSjR",
        net_leverage: 0.0,
        open_ms: 1715644845571,
        orders: [
          {
            leverage: -0.005,
            order_type: "SHORT",
            order_uuid: "70ed046c-8371-4931-8497-08fc5d1acada",
            price: 0.66433,
            price_sources: [],
            processed_ms: 1715644840617,
          },
          {
            leverage: 0.005,
            order_type: "FLAT",
            order_uuid: "de10889e-f8ef-401b-9356-9951616f9187",
            price: 0.66434,
            price_sources: [],
            processed_ms: 1715644964304,
          },
        ],
        position_type: "FLAT",
        position_uuid: "a4d44e82-056c-46a6-baf8-b77ea4521be6",
        return_at_close: 0.9999995747362267,
        trade_pair: ["CADCHF", "CAD/CHF", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.66442,
        close_ms: 1715645276401,
        current_return: 1.0000031606513953,
        initial_entry_price: 0.66442,
        is_closed_position: true,
        miner_hotkey: "5Dxqzduahnqw8q3XSUfTcEZGU7xmAsfJubhHZwvXVLN9fSjR",
        net_leverage: 0.0,
        open_ms: 1715645160407,
        orders: [
          {
            leverage: -0.005,
            order_type: "SHORT",
            order_uuid: "656b4535-2678-4927-9ce7-73410d6e42bc",
            price: 0.66442,
            price_sources: [],
            processed_ms: 1715645153001,
          },
          {
            leverage: 0.005,
            order_type: "FLAT",
            order_uuid: "fcee4b93-1b2c-4aca-8abf-1a1f223c77d2",
            price: 0.664,
            price_sources: [],
            processed_ms: 1715645276401,
          },
        ],
        position_type: "FLAT",
        position_uuid: "15042150-bed6-48db-b8ee-b25f17b11503",
        return_at_close: 1.000002810650289,
        trade_pair: ["CADCHF", "CAD/CHF", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 16.792131094049914,
        close_ms: 1715657031121,
        current_return: 0.9999998088347624,
        initial_entry_price: 16.79666,
        is_closed_position: true,
        miner_hotkey: "5Dxqzduahnqw8q3XSUfTcEZGU7xmAsfJubhHZwvXVLN9fSjR",
        net_leverage: 0.0,
        open_ms: 1715644045240,
        orders: [
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "b459068a-af3f-4e6c-b419-d1e9bf9c3964",
            price: 16.79666,
            price_sources: [],
            processed_ms: 1715644039596,
          },
          {
            leverage: -0.009296875000000001,
            order_type: "SHORT",
            order_uuid: "6fbd0bd2-27c6-4023-9ca0-5707e60501c8",
            price: 16.7928,
            price_sources: [],
            processed_ms: 1715644180534,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "d5503d08-96f6-4fd5-af5c-d1bc6b122ef1",
            price: 16.79569,
            price_sources: [],
            processed_ms: 1715645365136,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "059ba7f1-ccd8-45c9-9e9f-825f5201c0c1",
            price: 16.796,
            price_sources: [],
            processed_ms: 1715645854524,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "4f070ee6-59f7-4ab9-8c85-33395019bb85",
            price: 16.797,
            price_sources: [],
            processed_ms: 1715646885771,
          },
          {
            leverage: -0.0103515625,
            order_type: "SHORT",
            order_uuid: "98e2064e-74f7-440f-a8be-1d2fd6301c80",
            price: 16.79859,
            price_sources: [],
            processed_ms: 1715647006319,
          },
          {
            leverage: -0.010175781249999998,
            order_type: "SHORT",
            order_uuid: "9632a05f-2b9b-4924-a40c-049f381de864",
            price: 16.802,
            price_sources: [],
            processed_ms: 1715656754046,
          },
          {
            leverage: -0.005087890624999999,
            order_type: "SHORT",
            order_uuid: "ea4c91ff-6bd7-46c1-8d78-39d0f583b0d6",
            price: 16.7911,
            price_sources: [],
            processed_ms: 1715656882963,
          },
          {
            leverage: 0.005087890624999999,
            order_type: "FLAT",
            order_uuid: "1a8524b7-318b-4278-a5cc-48580e8d82bb",
            price: 16.7915,
            price_sources: [],
            processed_ms: 1715657031121,
          },
        ],
        position_type: "FLAT",
        position_uuid: "bcffab74-c0d1-4f34-a603-18e10933ad99",
        return_at_close: 0.9999970088352976,
        trade_pair: ["USDMXN", "USD/MXN", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 16.797,
        close_ms: 1715661956335,
        current_return: 0.9999937488837293,
        initial_entry_price: 16.797,
        is_closed_position: true,
        miner_hotkey: "5Dxqzduahnqw8q3XSUfTcEZGU7xmAsfJubhHZwvXVLN9fSjR",
        net_leverage: 0.0,
        open_ms: 1715661693011,
        orders: [
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "64b7dcb4-9aa2-4a89-b543-6e93aff3001e",
            price: 16.797,
            price_sources: [],
            processed_ms: 1715661689589,
          },
          {
            leverage: 0.005,
            order_type: "FLAT",
            order_uuid: "a336a9a0-ad1f-4caa-a9d8-41113b7d02f1",
            price: 16.7865,
            price_sources: [],
            processed_ms: 1715661956335,
          },
        ],
        position_type: "FLAT",
        position_uuid: "843f45dd-17e4-4a2e-a557-44ed08ac30d9",
        return_at_close: 0.9999930488881051,
        trade_pair: ["USDMXN", "USD/MXN", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.6603800000000075,
        close_ms: 1715663472552,
        current_return: 1.0000291235833294,
        initial_entry_price: 0.66441,
        is_closed_position: true,
        miner_hotkey: "5Dxqzduahnqw8q3XSUfTcEZGU7xmAsfJubhHZwvXVLN9fSjR",
        net_leverage: 0.0,
        open_ms: 1715647106206,
        orders: [
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "13a08761-50ba-4ef7-9184-9a048b10439d",
            price: 0.66441,
            price_sources: [],
            processed_ms: 1715647098536,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "fda7c04f-f053-451b-b246-7532b9c63318",
            price: 0.66439,
            price_sources: [],
            processed_ms: 1715647428958,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "cb1d0198-b752-4105-9685-baafdd9c1da7",
            price: 0.66431,
            price_sources: [],
            processed_ms: 1715647763295,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "6a3d5b5b-3ae2-4af4-9f4a-a6bb51811495",
            price: 0.66436,
            price_sources: [],
            processed_ms: 1715648176867,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "9730cb29-ccae-4e2a-8ca2-07f7409b577a",
            price: 0.66414,
            price_sources: [],
            processed_ms: 1715657687478,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "90e0003c-12b8-424b-8e2e-7675b342dec5",
            price: 0.66419,
            price_sources: [],
            processed_ms: 1715658006321,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "e3243071-f96e-4375-bb44-635ed97b2db5",
            price: 0.66427,
            price_sources: [],
            processed_ms: 1715658596871,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "748ffa2c-7503-4dc0-8647-33db8c773059",
            price: 0.6642,
            price_sources: [],
            processed_ms: 1715659277764,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "94adf764-4798-482b-9ac5-487b1ea3e3b8",
            price: 0.66416,
            price_sources: [],
            processed_ms: 1715659954456,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "c8a42119-74ca-4c1b-9a84-02681d2e1490",
            price: 0.66416,
            price_sources: [],
            processed_ms: 1715660659877,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "9e6dd55c-e9ca-4837-94ae-4b7edb548a88",
            price: 0.6637,
            price_sources: [],
            processed_ms: 1715661022379,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "28aab1d7-5eae-46f7-bb85-576f699464ae",
            price: 0.6635,
            price_sources: [],
            processed_ms: 1715661397203,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "df6b67ce-6689-4554-bbb9-59c51a00b85c",
            price: 0.6636,
            price_sources: [],
            processed_ms: 1715661699440,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "8963eb4d-be0a-42fb-8351-77ea0a9d670a",
            price: 0.6636,
            price_sources: [],
            processed_ms: 1715662038064,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "8955b3dc-9025-412f-8844-75986665a716",
            price: 0.66407,
            price_sources: [],
            processed_ms: 1715662442735,
          },
          {
            leverage: -0.075,
            order_type: "SHORT",
            order_uuid: "3c7d5436-e39d-4bd4-9e3f-44aa2b0580f4",
            price: 0.66416,
            price_sources: [],
            processed_ms: 1715662670636,
          },
          {
            leverage: -0.037,
            order_type: "SHORT",
            order_uuid: "82424c92-d840-4f24-af39-9d8516d6a272",
            price: 0.66423,
            price_sources: [],
            processed_ms: 1715662792473,
          },
          {
            leverage: -0.019,
            order_type: "SHORT",
            order_uuid: "4cb184cb-59ba-41f1-88d8-036fe057ed90",
            price: 0.66423,
            price_sources: [],
            processed_ms: 1715662913060,
          },
          {
            leverage: -0.009,
            order_type: "SHORT",
            order_uuid: "8b63e801-7808-41e0-9253-f049a6aa8bbd",
            price: 0.66428,
            price_sources: [],
            processed_ms: 1715663033716,
          },
          {
            leverage: -0.005,
            order_type: "SHORT",
            order_uuid: "fb8ef3d9-9dd2-4072-913b-7cdef8b0e55a",
            price: 0.66426,
            price_sources: [],
            processed_ms: 1715663346502,
          },
          {
            leverage: 0.005,
            order_type: "FLAT",
            order_uuid: "fd852fce-09ee-4554-8d26-c22ec5f9b4ad",
            price: 0.66425,
            price_sources: [],
            processed_ms: 1715663472552,
          },
        ],
        position_type: "FLAT",
        position_uuid: "f3c8893f-1742-406a-bf78-c9549db02330",
        return_at_close: 1.0000186232775317,
        trade_pair: ["CADCHF", "CAD/CHF", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.9002163605149721,
        close_ms: 1715698871280,
        current_return: 1.000140598031426,
        initial_entry_price: 0.905015262980155,
        is_closed_position: true,
        miner_hotkey: "5Dxqzduahnqw8q3XSUfTcEZGU7xmAsfJubhHZwvXVLN9fSjR",
        net_leverage: 0.0,
        open_ms: 1715612162751,
        orders: [
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "bbeda00e-99dc-4047-bdcc-4f04b2697522",
            price: 0.905015262980155,
            price_sources: [],
            processed_ms: 1715612158180,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "57e9e49c-8316-412d-a11c-790b0ef9d905",
            price: 0.9033,
            price_sources: [],
            processed_ms: 1715615467867,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "45dc5b61-23c9-4b31-b6bd-114ef9bd5f92",
            price: 0.903363065741658,
            price_sources: [],
            processed_ms: 1715618125359,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "d0e23f1f-c2e1-464c-977e-dc0bf033d4af",
            price: 0.903419114003502,
            price_sources: [],
            processed_ms: 1715619388300,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "44c2a2de-64ac-4a32-b24f-3ccdb6d708d0",
            price: 0.90307,
            price_sources: [],
            processed_ms: 1715620436504,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "4279b623-5e18-491f-90cc-be03bc5e8e3d",
            price: 0.90323,
            price_sources: [],
            processed_ms: 1715624078994,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "cf6c2333-2ab1-42ec-bc04-51046528b9c2",
            price: 0.90341,
            price_sources: [],
            processed_ms: 1715626295012,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "7a56c828-5e09-48a4-9403-edae92a58749",
            price: 0.90341,
            price_sources: [],
            processed_ms: 1715626656408,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "57d32ead-1aa5-4454-9874-36cf43068414",
            price: 0.90327,
            price_sources: [],
            processed_ms: 1715627102556,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "f6aa17cd-07c8-47df-8699-68e08bf54f44",
            price: 0.9032,
            price_sources: [],
            processed_ms: 1715628000496,
          },
          {
            leverage: -0.049999999999999996,
            order_type: "SHORT",
            order_uuid: "cc0e96c3-b5e6-40e0-94b2-a7e3aeefcb07",
            price: 0.90409646514701,
            price_sources: [],
            processed_ms: 1715634021452,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "07faa00f-9fb9-4119-9286-cd9a1e424b64",
            price: 0.90339,
            price_sources: [],
            processed_ms: 1715644958692,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "b22559ff-37e9-4cf6-a075-1ea287a16ebe",
            price: 0.90294,
            price_sources: [],
            processed_ms: 1715645446177,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "f4445583-bcd0-4d5d-97a5-d978acbb3db8",
            price: 0.90322,
            price_sources: [],
            processed_ms: 1715657663230,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "5c9fff1a-8359-46fc-84fb-2e2b24a18e69",
            price: 0.90306,
            price_sources: [],
            processed_ms: 1715659506679,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "510f08f9-fb1b-460c-a713-91600ed080c6",
            price: 0.90282,
            price_sources: [],
            processed_ms: 1715660885520,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "7d74172c-d4bc-43e5-80b0-502d0ccae0ec",
            price: 0.902911435850725,
            price_sources: [],
            processed_ms: 1715661252940,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "68761368-0a9c-4700-b0d1-dc5be47325e8",
            price: 0.90265,
            price_sources: [],
            processed_ms: 1715662880383,
          },
          {
            leverage: -0.085,
            order_type: "SHORT",
            order_uuid: "1819b3d5-7ed0-4f10-aa6b-19b1770e437b",
            price: 0.904051681298658,
            price_sources: [],
            processed_ms: 1715698631428,
          },
          {
            leverage: -0.043,
            order_type: "SHORT",
            order_uuid: "a6527dcd-502a-4f5d-a452-c884d59e1f00",
            price: 0.903851885211701,
            price_sources: [],
            processed_ms: 1715698871280,
          },
        ],
        position_type: "FLAT",
        position_uuid: "6c2110e5-4a92-47fc-b599-166e9a3af19f",
        return_at_close: 1.0001286963583094,
        trade_pair: ["AUDCAD", "AUD/CAD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.9041336995644422,
        close_ms: 1715699476259,
        current_return: 1.0000001514124108,
        initial_entry_price: 0.904046660130892,
        is_closed_position: true,
        miner_hotkey: "5Dxqzduahnqw8q3XSUfTcEZGU7xmAsfJubhHZwvXVLN9fSjR",
        net_leverage: 0.0,
        open_ms: 1715699116050,
        orders: [
          {
            leverage: -0.021,
            order_type: "SHORT",
            order_uuid: "8cb002db-1221-434d-968e-c08e91d003c3",
            price: 0.904046660130892,
            price_sources: [],
            processed_ms: 1715699113308,
          },
          {
            leverage: -0.011,
            order_type: "SHORT",
            order_uuid: "42015725-13b0-41b7-9900-e593e48aa5a3",
            price: 0.904247001921421,
            price_sources: [],
            processed_ms: 1715699233338,
          },
          {
            leverage: -0.005,
            order_type: "SHORT",
            order_uuid: "d4a3a3bb-9b96-492e-8ffa-112c1eec9ab5",
            price: 0.90425,
            price_sources: [],
            processed_ms: 1715699354313,
          },
          {
            leverage: 0.005,
            order_type: "FLAT",
            order_uuid: "fcffff80-60d1-419b-abca-5b7b4bc94e4e",
            price: 0.90413,
            price_sources: [],
            processed_ms: 1715699476259,
          },
        ],
        position_type: "FLAT",
        position_uuid: "36860992-092d-4deb-968f-53268a880bef",
        return_at_close: 0.9999975614120187,
        trade_pair: ["AUDCAD", "AUD/CAD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.6628383333333339,
        close_ms: 1715699968262,
        current_return: 1.0000102272898435,
        initial_entry_price: 0.66391,
        is_closed_position: true,
        miner_hotkey: "5Dxqzduahnqw8q3XSUfTcEZGU7xmAsfJubhHZwvXVLN9fSjR",
        net_leverage: 0.0,
        open_ms: 1715699100010,
        orders: [
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "73028377-6222-461f-87e0-938c31e25072",
            price: 0.66391,
            price_sources: [],
            processed_ms: 1715699096526,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "6f324f2a-f4ec-4ae4-900a-09bfc9ceb6a2",
            price: 0.66382,
            price_sources: [],
            processed_ms: 1715699275205,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "f7bf1e08-b67b-4133-b61a-236cc99b2e0e",
            price: 0.6635,
            price_sources: [],
            processed_ms: 1715699358196,
          },
          {
            leverage: -0.007,
            order_type: "SHORT",
            order_uuid: "450ab06b-6b73-49cb-98e3-a9963c0af29b",
            price: 0.66416,
            price_sources: [],
            processed_ms: 1715699601702,
          },
          {
            leverage: -0.011,
            order_type: "SHORT",
            order_uuid: "77ff2dbd-317c-4c39-9fa9-ac747f18ec0f",
            price: 0.66405,
            price_sources: [],
            processed_ms: 1715699722472,
          },
          {
            leverage: -0.006,
            order_type: "SHORT",
            order_uuid: "849a2939-a740-4c73-836d-da364e428e00",
            price: 0.6636,
            price_sources: [],
            processed_ms: 1715699845350,
          },
          {
            leverage: 0.006,
            order_type: "FLAT",
            order_uuid: "f0b31875-6379-40e0-982d-727237b5bab6",
            price: 0.66397,
            price_sources: [],
            processed_ms: 1715699968262,
          },
        ],
        position_type: "FLAT",
        position_uuid: "99aacf84-7d44-43fd-be5c-72fb35f96bba",
        return_at_close: 1.000008127268366,
        trade_pair: ["CADCHF", "CAD/CHF", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 16.846643750000005,
        close_ms: 1715700675877,
        current_return: 1.0000009762756124,
        initial_entry_price: 16.84975,
        is_closed_position: true,
        miner_hotkey: "5Dxqzduahnqw8q3XSUfTcEZGU7xmAsfJubhHZwvXVLN9fSjR",
        net_leverage: 0.0,
        open_ms: 1715699286547,
        orders: [
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "8a75f89c-891a-4cc6-bf00-facf127b58af",
            price: 16.84975,
            price_sources: [],
            processed_ms: 1715699284331,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "5a6f07a0-65e2-46aa-8112-d7bb14959b82",
            price: 16.85049,
            price_sources: [],
            processed_ms: 1715699621253,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "8404ee07-9a11-4590-aa2a-03e75aebda73",
            price: 16.85146,
            price_sources: [],
            processed_ms: 1715700019564,
          },
          {
            leverage: -0.01,
            order_type: "SHORT",
            order_uuid: "03384289-4ed0-46c3-910e-c615af42d2bc",
            price: 16.85596,
            price_sources: [],
            processed_ms: 1715700169280,
          },
          {
            leverage: -0.005,
            order_type: "SHORT",
            order_uuid: "922083d1-c62b-4153-8b46-dea61d4d7166",
            price: 16.85595,
            price_sources: [],
            processed_ms: 1715700299304,
          },
          {
            leverage: -0.007,
            order_type: "SHORT",
            order_uuid: "9904f9f0-fd18-4ce9-a8d8-2b8bf59905c3",
            price: 16.8435,
            price_sources: [],
            processed_ms: 1715700424260,
          },
          {
            leverage: 0.008,
            order_type: "FLAT",
            order_uuid: "9f77f3d2-3101-479f-8b7d-ce220bb24543",
            price: 16.8487,
            price_sources: [],
            processed_ms: 1715700675877,
          },
        ],
        position_type: "FLAT",
        position_uuid: "aecbbcc8-25bc-411c-b5f4-6e84d3ab9ce9",
        return_at_close: 0.9999988762735622,
        trade_pair: ["USDMXN", "USD/MXN", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.6641614285714286,
        close_ms: 1715702137016,
        current_return: 1.0000035991265719,
        initial_entry_price: 0.66405,
        is_closed_position: true,
        miner_hotkey: "5Dxqzduahnqw8q3XSUfTcEZGU7xmAsfJubhHZwvXVLN9fSjR",
        net_leverage: 0.0,
        open_ms: 1715700094416,
        orders: [
          {
            leverage: -0.011,
            order_type: "SHORT",
            order_uuid: "c82cfcf5-ba9e-4cf7-a6db-c6a3cbffd54e",
            price: 0.66405,
            price_sources: [],
            processed_ms: 1715700091279,
          },
          {
            leverage: -0.006,
            order_type: "SHORT",
            order_uuid: "ccd3ab8c-9cd2-48c3-883d-7358325f88d4",
            price: 0.66408,
            price_sources: [],
            processed_ms: 1715700212255,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "0e057c7f-49f3-4aaf-a9c2-75f78f631bc0",
            price: 0.66399,
            price_sources: [],
            processed_ms: 1715701827720,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "17207297-1b2a-4eb9-b77a-66efa2a3758b",
            price: 0.66382,
            price_sources: [],
            processed_ms: 1715702137016,
          },
        ],
        position_type: "FLAT",
        position_uuid: "5a33d987-88b6-4699-96cc-98202fc250a4",
        return_at_close: 1.000002409122289,
        trade_pair: ["CADCHF", "CAD/CHF", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.9034534003599961,
        close_ms: 1715705639843,
        current_return: 1.0000049122112495,
        initial_entry_price: 0.9042,
        is_closed_position: true,
        miner_hotkey: "5Dxqzduahnqw8q3XSUfTcEZGU7xmAsfJubhHZwvXVLN9fSjR",
        net_leverage: 0.0,
        open_ms: 1715701412721,
        orders: [
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "5a50f923-d7d9-41a9-9cc7-69e9943cc723",
            price: 0.9042,
            price_sources: [],
            processed_ms: 1715701411195,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "d34e54fa-cdd1-4b8d-aecb-719d9636572f",
            price: 0.904238057077006,
            price_sources: [],
            processed_ms: 1715701514370,
          },
          {
            leverage: -0.01,
            order_type: "SHORT",
            order_uuid: "8dfd4c23-2ff5-44b1-95f4-83350e50101e",
            price: 0.90439,
            price_sources: [],
            processed_ms: 1715701788432,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "033fc133-9252-445d-a93d-a49d599b842e",
            price: 0.904123643102992,
            price_sources: [],
            processed_ms: 1715704949275,
          },
          {
            leverage: -0.01,
            order_type: "SHORT",
            order_uuid: "e38acbdc-e480-4239-8a51-76d3000c6c21",
            price: 0.90431,
            price_sources: [],
            processed_ms: 1715705379295,
          },
          {
            leverage: -0.005,
            order_type: "SHORT",
            order_uuid: "5d6d73c7-8399-4cc9-8b8f-e69e0765d775",
            price: 0.90427,
            price_sources: [],
            processed_ms: 1715705519377,
          },
          {
            leverage: 0.005,
            order_type: "FLAT",
            order_uuid: "a8fac812-4a80-4f5e-ba2e-d17b9a697c17",
            price: 0.904341724642382,
            price_sources: [],
            processed_ms: 1715705639843,
          },
        ],
        position_type: "FLAT",
        position_uuid: "5cfec370-8f49-48ea-80e6-d7dca0634c8c",
        return_at_close: 1.000002812200934,
        trade_pair: ["AUDCAD", "AUD/CAD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 16.398050000000175,
        close_ms: 1715706366292,
        current_return: 1.0000269075413541,
        initial_entry_price: 16.84472,
        is_closed_position: true,
        miner_hotkey: "5Dxqzduahnqw8q3XSUfTcEZGU7xmAsfJubhHZwvXVLN9fSjR",
        net_leverage: 0.0,
        open_ms: 1715702564153,
        orders: [
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "3b739177-9792-47a4-8e36-4cc48ae81e1f",
            price: 16.84472,
            price_sources: [],
            processed_ms: 1715702561925,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "40dab262-6cbb-4a12-a96e-c09becec7f24",
            price: 16.842,
            price_sources: [],
            processed_ms: 1715702632431,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "b0663296-bed3-4a90-bb09-e9b1afc6d04a",
            price: 16.845,
            price_sources: [],
            processed_ms: 1715702727715,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "e22d5e73-691f-4fb3-923f-46e9eec99984",
            price: 16.83926,
            price_sources: [],
            processed_ms: 1715703181606,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "366f267d-9481-4963-9722-b549e004206c",
            price: 16.8377,
            price_sources: [],
            processed_ms: 1715703571928,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "13de8c5a-ee31-4512-977f-0c190bbdb03b",
            price: 16.83919,
            price_sources: [],
            processed_ms: 1715704115540,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "a194a8fd-c1d9-4fbb-9f33-33abdf1dca81",
            price: 16.8283,
            price_sources: [],
            processed_ms: 1715704522768,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "4b1acac6-4fd6-49e2-a42b-3c2f28a175da",
            price: 16.827,
            price_sources: [],
            processed_ms: 1715704854647,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "e036e4f9-ff0b-4fa9-9bde-e6346fbc2e67",
            price: 16.83744,
            price_sources: [],
            processed_ms: 1715705231752,
          },
          {
            leverage: -0.045,
            order_type: "SHORT",
            order_uuid: "ae7c8139-1578-4642-8279-4379945df629",
            price: 16.8422,
            price_sources: [],
            processed_ms: 1715705522430,
          },
          {
            leverage: -0.011,
            order_type: "SHORT",
            order_uuid: "3b2c6c7d-212e-462a-aa2e-5d7eb7f80369",
            price: 16.8398,
            price_sources: [],
            processed_ms: 1715705992413,
          },
          {
            leverage: -0.022,
            order_type: "SHORT",
            order_uuid: "6ddc3c8b-f9dd-4ffc-84d1-19e7549de3dc",
            price: 16.8431,
            price_sources: [],
            processed_ms: 1715706117114,
          },
          {
            leverage: -0.011,
            order_type: "SHORT",
            order_uuid: "b769844e-8668-441e-b04f-011ef6373295",
            price: 16.84755,
            price_sources: [],
            processed_ms: 1715706237741,
          },
          {
            leverage: 0.001,
            order_type: "FLAT",
            order_uuid: "ac814542-d38d-49cf-a07b-cc6c8ca717da",
            price: 16.8513,
            price_sources: [],
            processed_ms: 1715706366292,
          },
        ],
        position_type: "FLAT",
        position_uuid: "c17c13a8-31c8-4df8-931c-2fda82dcad32",
        return_at_close: 1.0000206073718367,
        trade_pair: ["USDMXN", "USD/MXN", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.9037705894026591,
        close_ms: 1715708673404,
        current_return: 0.9999992189455655,
        initial_entry_price: 0.903770589402659,
        is_closed_position: true,
        miner_hotkey: "5Dxqzduahnqw8q3XSUfTcEZGU7xmAsfJubhHZwvXVLN9fSjR",
        net_leverage: 0.0,
        open_ms: 1715708345625,
        orders: [
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "83b978e3-92fd-4049-aa05-28f160692af2",
            price: 0.903770589402659,
            price_sources: [],
            processed_ms: 1715708344362,
          },
          {
            leverage: 0.005,
            order_type: "FLAT",
            order_uuid: "b5be75a0-3eaa-4260-ac64-aabb90717dbd",
            price: 0.9037,
            price_sources: [],
            processed_ms: 1715708673404,
          },
        ],
        position_type: "FLAT",
        position_uuid: "606011ac-cb13-45d6-a7df-57ca49b45ed7",
        return_at_close: 0.9999985189461124,
        trade_pair: ["AUDCAD", "AUD/CAD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.6639342105263153,
        close_ms: 1715708704200,
        current_return: 0.9999924401005978,
        initial_entry_price: 0.66403,
        is_closed_position: true,
        miner_hotkey: "5Dxqzduahnqw8q3XSUfTcEZGU7xmAsfJubhHZwvXVLN9fSjR",
        net_leverage: 0.0,
        open_ms: 1715703990614,
        orders: [
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "6ca5423e-1e25-421d-aa30-518a615f7dfa",
            price: 0.66403,
            price_sources: [],
            processed_ms: 1715703988266,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "64c14b16-781f-412a-b978-0b25acc511b2",
            price: 0.6639,
            price_sources: [],
            processed_ms: 1715704306473,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "7c9cd738-b0a0-4e32-94e4-3f4646e7dd11",
            price: 0.66376,
            price_sources: [],
            processed_ms: 1715704609111,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "efa56e8e-74c9-4f68-bc15-c7c8ae9dd7ae",
            price: 0.6632,
            price_sources: [],
            processed_ms: 1715705699202,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "5567cd82-9334-49f3-be26-240168639229",
            price: 0.66354,
            price_sources: [],
            processed_ms: 1715706014814,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "97cf16de-ad70-4372-a942-bd66340eae80",
            price: 0.66343,
            price_sources: [],
            processed_ms: 1715706410041,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "5a6ec676-a554-4887-99ad-6bfeed15eeee",
            price: 0.66336,
            price_sources: [],
            processed_ms: 1715706730423,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "8e30927d-7265-4c79-ad08-daf0cd5c4f4e",
            price: 0.6632,
            price_sources: [],
            processed_ms: 1715707102264,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "f1a830af-84b2-4c96-9734-cf85c66b3813",
            price: 0.663,
            price_sources: [],
            processed_ms: 1715707427326,
          },
          {
            leverage: -0.045,
            order_type: "SHORT",
            order_uuid: "e1e8b35a-acb5-441f-8467-840cffd5e02a",
            price: 0.6632,
            price_sources: [],
            processed_ms: 1715707549618,
          },
          {
            leverage: -0.011,
            order_type: "SHORT",
            order_uuid: "5d85de93-df5e-4d5b-a201-fe7ca4f16b90",
            price: 0.6637,
            price_sources: [],
            processed_ms: 1715707834598,
          },
          {
            leverage: -0.009,
            order_type: "SHORT",
            order_uuid: "4155462c-a7be-48b1-a0a0-bb6d995b3758",
            price: 0.66363,
            price_sources: [],
            processed_ms: 1715708111419,
          },
          {
            leverage: -0.006,
            order_type: "SHORT",
            order_uuid: "58ea80ba-336d-41f4-8243-c4749abb050a",
            price: 0.66368,
            price_sources: [],
            processed_ms: 1715708383587,
          },
          {
            leverage: 0.01,
            order_type: "FLAT",
            order_uuid: "f670802c-92d2-4c1c-a8be-aee43ec938cc",
            price: 0.66367,
            price_sources: [],
            processed_ms: 1715708704200,
          },
        ],
        position_type: "FLAT",
        position_uuid: "bcf36174-71d3-4072-828c-baae3c9dafff",
        return_at_close: 0.9999861401482252,
        trade_pair: ["CADCHF", "CAD/CHF", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 16.825139999999987,
        close_ms: 1715710394379,
        current_return: 1.0000041153167576,
        initial_entry_price: 16.83953,
        is_closed_position: true,
        miner_hotkey: "5Dxqzduahnqw8q3XSUfTcEZGU7xmAsfJubhHZwvXVLN9fSjR",
        net_leverage: 0.0,
        open_ms: 1715709097999,
        orders: [
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "6aead7c2-041d-408e-889a-1f5ec67a3aaa",
            price: 16.83953,
            price_sources: [],
            processed_ms: 1715709096235,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "d8c8ea35-6770-4b1e-9306-0a4bce0ef2a6",
            price: 16.8375,
            price_sources: [],
            processed_ms: 1715709656697,
          },
          {
            leverage: -0.01,
            order_type: "SHORT",
            order_uuid: "1cd1b15b-f8d2-4e30-bb57-b99e46b4bb42",
            price: 16.83996,
            price_sources: [],
            processed_ms: 1715709783397,
          },
          {
            leverage: -0.005,
            order_type: "SHORT",
            order_uuid: "b726afe9-df91-423b-aa62-7b766f6bdb10",
            price: 16.849,
            price_sources: [],
            processed_ms: 1715709909164,
          },
          {
            leverage: 0.005,
            order_type: "FLAT",
            order_uuid: "a67a119b-8017-4fc6-9382-11452ee2153d",
            price: 16.839,
            price_sources: [],
            processed_ms: 1715710394379,
          },
        ],
        position_type: "FLAT",
        position_uuid: "a8af76ff-06fe-43dd-ab1c-78a7bc2710eb",
        return_at_close: 1.000002715310996,
        trade_pair: ["USDMXN", "USD/MXN", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 16.83082,
        close_ms: 1715712575794,
        current_return: 1.0000075340577437,
        initial_entry_price: 16.83024,
        is_closed_position: true,
        miner_hotkey: "5Dxqzduahnqw8q3XSUfTcEZGU7xmAsfJubhHZwvXVLN9fSjR",
        net_leverage: 0.0,
        open_ms: 1715712202887,
        orders: [
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "7712edfc-9712-4189-8713-ecf31f19024a",
            price: 16.83024,
            price_sources: [],
            processed_ms: 1715712198920,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "33d1811b-6bb5-4a1d-a2ca-2c960b4a2513",
            price: 16.8314,
            price_sources: [],
            processed_ms: 1715712264856,
          },
          {
            leverage: 0.005,
            order_type: "FLAT",
            order_uuid: "2888e8a1-6d78-4633-920b-4d246f973c32",
            price: 16.83716,
            price_sources: [],
            processed_ms: 1715712575794,
          },
        ],
        position_type: "FLAT",
        position_uuid: "d7e7fae1-c8c4-498e-a40a-ea74a5dfa230",
        return_at_close: 1.000006134047196,
        trade_pair: ["USDMXN", "USD/MXN", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.6590599999999995,
        close_ms: 1715714161424,
        current_return: 1.0000383571966842,
        initial_entry_price: 0.6635,
        is_closed_position: true,
        miner_hotkey: "5Dxqzduahnqw8q3XSUfTcEZGU7xmAsfJubhHZwvXVLN9fSjR",
        net_leverage: 0.0,
        open_ms: 1715710523626,
        orders: [
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "02a74cf6-1d7f-4730-8215-882ebdab7faf",
            price: 0.6635,
            price_sources: [],
            processed_ms: 1715710519133,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "6a569705-91a4-41e7-afd2-256b7315aa66",
            price: 0.66391,
            price_sources: [],
            processed_ms: 1715710632257,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "2be96aab-1855-4a7b-ad89-8b2f77423784",
            price: 0.66386,
            price_sources: [],
            processed_ms: 1715710708395,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "5ee3b534-1044-4e33-914d-36214ec8626b",
            price: 0.6633,
            price_sources: [],
            processed_ms: 1715711014732,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "8993604a-35b4-4d34-96d9-9fb480c9a4e2",
            price: 0.6635,
            price_sources: [],
            processed_ms: 1715711336301,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "c3f912a3-97cb-444a-9e54-47261e376a4d",
            price: 0.66395,
            price_sources: [],
            processed_ms: 1715711696568,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "c113aa2e-7db4-4bd4-be2f-81f2d540b66d",
            price: 0.66397,
            price_sources: [],
            processed_ms: 1715712015142,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "220e79fc-257a-4e46-b355-32f74f2c310a",
            price: 0.66389,
            price_sources: [],
            processed_ms: 1715712904673,
          },
          {
            leverage: -0.04,
            order_type: "SHORT",
            order_uuid: "bf95fcfd-d86c-441c-8dc2-c8107d12f641",
            price: 0.66401,
            price_sources: [],
            processed_ms: 1715713180195,
          },
          {
            leverage: -0.02,
            order_type: "SHORT",
            order_uuid: "807f408b-1141-4cba-aca1-0e9beb9fe9c5",
            price: 0.6641,
            price_sources: [],
            processed_ms: 1715713545780,
          },
          {
            leverage: -0.01,
            order_type: "SHORT",
            order_uuid: "f52d8bd5-3426-4c61-aa4e-d0ee231dc22b",
            price: 0.66405,
            price_sources: [],
            processed_ms: 1715713680054,
          },
          {
            leverage: -0.005,
            order_type: "SHORT",
            order_uuid: "fd57af75-e838-4e43-b20f-d5a628add4a3",
            price: 0.66412,
            price_sources: [],
            processed_ms: 1715713801880,
          },
          {
            leverage: 0.005,
            order_type: "FLAT",
            order_uuid: "c208653a-2a8a-40cd-b11a-26923655d963",
            price: 0.66415,
            price_sources: [],
            processed_ms: 1715714161424,
          },
        ],
        position_type: "FLAT",
        position_uuid: "76d08bc9-4df8-4615-bf95-7903939f34df",
        return_at_close: 1.0000327569818839,
        trade_pair: ["CADCHF", "CAD/CHF", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 16.83806,
        close_ms: 1715714391044,
        current_return: 0.999997303727389,
        initial_entry_price: 16.83806,
        is_closed_position: true,
        miner_hotkey: "5Dxqzduahnqw8q3XSUfTcEZGU7xmAsfJubhHZwvXVLN9fSjR",
        net_leverage: 0.0,
        open_ms: 1715712706932,
        orders: [
          {
            leverage: -0.01,
            order_type: "SHORT",
            order_uuid: "6bead76c-ff14-4631-b585-dde21b1f28cb",
            price: 16.83806,
            price_sources: [],
            processed_ms: 1715712703583,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "f0cf687d-43e9-41f5-b44c-c513bc740c84",
            price: 16.8426,
            price_sources: [],
            processed_ms: 1715714391044,
          },
        ],
        position_type: "FLAT",
        position_uuid: "990fac70-6809-4b6c-bdd1-b10a03d7ba91",
        return_at_close: 0.9999966037292765,
        trade_pair: ["USDMXN", "USD/MXN", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.9040775296361538,
        close_ms: 1715714902368,
        current_return: 1.0000006012346414,
        initial_entry_price: 0.904243764818077,
        is_closed_position: true,
        miner_hotkey: "5Dxqzduahnqw8q3XSUfTcEZGU7xmAsfJubhHZwvXVLN9fSjR",
        net_leverage: 0.0,
        open_ms: 1715711835563,
        orders: [
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "ec1122c3-df0f-43b9-a11d-c6f90b5d1f3f",
            price: 0.904243764818077,
            price_sources: [],
            processed_ms: 1715711832516,
          },
          {
            leverage: -0.005,
            order_type: "SHORT",
            order_uuid: "77ba67e0-7743-40cd-8dc0-f33a8415cf1b",
            price: 0.90441,
            price_sources: [],
            processed_ms: 1715712412592,
          },
          {
            leverage: 0.005,
            order_type: "FLAT",
            order_uuid: "74af7ba4-f13e-4fea-afcf-4c8e82b67b1a",
            price: 0.904186262171292,
            price_sources: [],
            processed_ms: 1715714902368,
          },
        ],
        position_type: "FLAT",
        position_uuid: "d64d03a8-7f50-4574-b74a-e40e8cd89384",
        return_at_close: 0.9999999012342206,
        trade_pair: ["AUDCAD", "AUD/CAD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.6641266666666668,
        close_ms: 1715716649644,
        current_return: 1.0000018818499337,
        initial_entry_price: 0.66424,
        is_closed_position: true,
        miner_hotkey: "5Dxqzduahnqw8q3XSUfTcEZGU7xmAsfJubhHZwvXVLN9fSjR",
        net_leverage: 0.0,
        open_ms: 1715716002366,
        orders: [
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "5f6d1144-7ab6-4d5b-9fb3-5fb10b78ca4f",
            price: 0.66424,
            price_sources: [],
            processed_ms: 1715715997231,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "e1525f8f-ffc9-4456-9308-3fb16ec2eb39",
            price: 0.6638,
            price_sources: [],
            processed_ms: 1715716286078,
          },
          {
            leverage: -0.005,
            order_type: "SHORT",
            order_uuid: "19e46d46-76c0-43a0-9b40-e0ce15a83fa0",
            price: 0.6637,
            price_sources: [],
            processed_ms: 1715716528169,
          },
          {
            leverage: 0.005,
            order_type: "FLAT",
            order_uuid: "84756570-cfe5-4138-a5ec-5100afa0bd6b",
            price: 0.66421,
            price_sources: [],
            processed_ms: 1715716649644,
          },
        ],
        position_type: "FLAT",
        position_uuid: "d0a27054-dae0-4060-b8c2-27711ed9826f",
        return_at_close: 1.000000481847299,
        trade_pair: ["CADCHF", "CAD/CHF", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 16.8465,
        close_ms: 1715717103275,
        current_return: 0.9999942700215245,
        initial_entry_price: 16.84125,
        is_closed_position: true,
        miner_hotkey: "5Dxqzduahnqw8q3XSUfTcEZGU7xmAsfJubhHZwvXVLN9fSjR",
        net_leverage: 0.0,
        open_ms: 1715716765086,
        orders: [
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "da1d1526-9fc3-40ee-9521-7d2c0bc1d4c2",
            price: 16.84125,
            price_sources: [],
            processed_ms: 1715716760189,
          },
          {
            leverage: -0.005,
            order_type: "SHORT",
            order_uuid: "520f7c71-38a6-40a7-a2d3-25253175cacc",
            price: 16.836,
            price_sources: [],
            processed_ms: 1715716976336,
          },
          {
            leverage: 0.005,
            order_type: "FLAT",
            order_uuid: "715655f3-e607-40e5-a6e2-d45df499bbef",
            price: 16.8272,
            price_sources: [],
            processed_ms: 1715717103275,
          },
        ],
        position_type: "FLAT",
        position_uuid: "e273e891-24d1-43ea-b692-3f10ace7cd42",
        return_at_close: 0.9999935700255356,
        trade_pair: ["USDMXN", "USD/MXN", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.9043576463184387,
        close_ms: 1715718044831,
        current_return: 0.9999997365714688,
        initial_entry_price: 0.90435,
        is_closed_position: true,
        miner_hotkey: "5Dxqzduahnqw8q3XSUfTcEZGU7xmAsfJubhHZwvXVLN9fSjR",
        net_leverage: 0.0,
        open_ms: 1715717392046,
        orders: [
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "5f0951bf-b5f3-4166-845d-6cf4b49da504",
            price: 0.90435,
            price_sources: [],
            processed_ms: 1715717385947,
          },
          {
            leverage: -0.005,
            order_type: "SHORT",
            order_uuid: "6804fddb-fe10-450f-be89-c8f30f683fa8",
            price: 0.904342353681561,
            price_sources: [],
            processed_ms: 1715717923216,
          },
          {
            leverage: 0.005,
            order_type: "FLAT",
            order_uuid: "09c84d8d-c8d9-4b76-a1c3-7ecc12312fb7",
            price: 0.90431,
            price_sources: [],
            processed_ms: 1715718044831,
          },
        ],
        position_type: "FLAT",
        position_uuid: "18d06dda-f507-4a8f-a6a4-16fc17dcda06",
        return_at_close: 0.9999990365716532,
        trade_pair: ["AUDCAD", "AUD/CAD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.9039,
        close_ms: 1715718580622,
        current_return: 0.9999980639451267,
        initial_entry_price: 0.9039,
        is_closed_position: true,
        miner_hotkey: "5Dxqzduahnqw8q3XSUfTcEZGU7xmAsfJubhHZwvXVLN9fSjR",
        net_leverage: 0.0,
        open_ms: 1715718234556,
        orders: [
          {
            leverage: -0.005,
            order_type: "SHORT",
            order_uuid: "adc78f8e-8682-4aa6-8f0e-6f1ad687986d",
            price: 0.9039,
            price_sources: [],
            processed_ms: 1715718229592,
          },
          {
            leverage: 0.005,
            order_type: "FLAT",
            order_uuid: "1f41c7f3-94ef-4f47-b0d2-015d9b81bebf",
            price: 0.90425,
            price_sources: [],
            processed_ms: 1715718580622,
          },
        ],
        position_type: "FLAT",
        position_uuid: "06890be2-5264-47ed-b527-e36068b0761b",
        return_at_close: 0.9999977139458043,
        trade_pair: ["AUDCAD", "AUD/CAD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 16.796129999999998,
        close_ms: 1715719384640,
        current_return: 1.0000109033582463,
        initial_entry_price: 16.81592,
        is_closed_position: true,
        miner_hotkey: "5Dxqzduahnqw8q3XSUfTcEZGU7xmAsfJubhHZwvXVLN9fSjR",
        net_leverage: 0.0,
        open_ms: 1715719149061,
        orders: [
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "ff4c3f79-c302-4834-b6a9-46be3a877cff",
            price: 16.81592,
            price_sources: [],
            processed_ms: 1715719141324,
          },
          {
            leverage: -0.005,
            order_type: "SHORT",
            order_uuid: "bd5fb70e-e5ad-4eb9-8a57-2cf36f8e4dbb",
            price: 16.83571,
            price_sources: [],
            processed_ms: 1715719261303,
          },
          {
            leverage: 0.005,
            order_type: "FLAT",
            order_uuid: "78d1b0a9-bcca-470f-bf76-f8fe3f53aa90",
            price: 16.8328,
            price_sources: [],
            processed_ms: 1715719384640,
          },
        ],
        position_type: "FLAT",
        position_uuid: "bcd023dc-aa5f-42f3-8a28-995c2612cd9a",
        return_at_close: 1.000010203350614,
        trade_pair: ["USDMXN", "USD/MXN", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 114.39000000000003,
        close_ms: 1715721071138,
        current_return: 1.0000075062624922,
        initial_entry_price: 114.571,
        is_closed_position: true,
        miner_hotkey: "5Dxqzduahnqw8q3XSUfTcEZGU7xmAsfJubhHZwvXVLN9fSjR",
        net_leverage: 0.0,
        open_ms: 1715720409794,
        orders: [
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "405ae8f6-e6f2-4ac9-8383-2226d40098e1",
            price: 114.571,
            price_sources: [],
            processed_ms: 1715720404404,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "dcff65dd-d190-4587-a8e6-9e7d51b6584b",
            price: 114.379,
            price_sources: [],
            processed_ms: 1715720475860,
          },
          {
            leverage: -0.01,
            order_type: "SHORT",
            order_uuid: "1d7ccd5a-ddd6-4707-b22d-5c70b9b7df9b",
            price: 114.56,
            price_sources: [],
            processed_ms: 1715720797039,
          },
          {
            leverage: -0.01,
            order_type: "SHORT",
            order_uuid: "357d7323-2bd7-4dbc-b3dc-53178566ee48",
            price: 114.476,
            price_sources: [],
            processed_ms: 1715721071138,
          },
        ],
        position_type: "FLAT",
        position_uuid: "19269ebe-a867-4d4b-a240-51cb7298e6d2",
        return_at_close: 1.0000061062519834,
        trade_pair: ["CADJPY", "CAD/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.6641835294117646,
        close_ms: 1715721312653,
        current_return: 0.999985061365861,
        initial_entry_price: 0.66405,
        is_closed_position: true,
        miner_hotkey: "5Dxqzduahnqw8q3XSUfTcEZGU7xmAsfJubhHZwvXVLN9fSjR",
        net_leverage: 0.0,
        open_ms: 1715718488737,
        orders: [
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "72e760e4-38d1-48a4-b53e-0a36184db989",
            price: 0.66405,
            price_sources: [],
            processed_ms: 1715718484428,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "5d35e449-d46a-4c3d-8e70-b9570b3fe467",
            price: 0.66406,
            price_sources: [],
            processed_ms: 1715719251062,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "237b394d-674a-4a8b-934a-079ba88e9d51",
            price: 0.66406,
            price_sources: [],
            processed_ms: 1715719629382,
          },
          {
            leverage: -0.015,
            order_type: "SHORT",
            order_uuid: "b7570719-bbaa-4e11-9581-45cc51fbc5f5",
            price: 0.6637,
            price_sources: [],
            processed_ms: 1715719749774,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "9e4a1f8f-4956-4e12-bc9d-75350b6c40b7",
            price: 0.66404,
            price_sources: [],
            processed_ms: 1715720084695,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "99e8fd28-1d4e-477c-bc1f-5a2fef19938d",
            price: 0.6634,
            price_sources: [],
            processed_ms: 1715720430363,
          },
          {
            leverage: -0.018,
            order_type: "SHORT",
            order_uuid: "1cfbf5a8-0618-42a8-a9f6-d1f584cacce4",
            price: 0.66386,
            price_sources: [],
            processed_ms: 1715720723460,
          },
          {
            leverage: -0.018,
            order_type: "SHORT",
            order_uuid: "bd6ad2b7-e42e-48da-8819-8fd33b38e72a",
            price: 0.6636,
            price_sources: [],
            processed_ms: 1715721312653,
          },
        ],
        position_type: "FLAT",
        position_uuid: "c08e7d90-70e5-46e1-808f-93cfb151f05d",
        return_at_close: 0.9999815614181461,
        trade_pair: ["CADCHF", "CAD/CHF", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 114.558,
        close_ms: 1715721342548,
        current_return: 1.0000053684596448,
        initial_entry_price: 114.558,
        is_closed_position: true,
        miner_hotkey: "5Dxqzduahnqw8q3XSUfTcEZGU7xmAsfJubhHZwvXVLN9fSjR",
        net_leverage: 0.0,
        open_ms: 1715721208705,
        orders: [
          {
            leverage: -0.005,
            order_type: "SHORT",
            order_uuid: "5297b45b-39c5-4b3b-a78d-8b1f0cac9f14",
            price: 114.558,
            price_sources: [],
            processed_ms: 1715721202360,
          },
          {
            leverage: 0.005,
            order_type: "FLAT",
            order_uuid: "cecf8a16-42ff-4780-85ff-0453b0c83230",
            price: 114.435,
            price_sources: [],
            processed_ms: 1715721342548,
          },
        ],
        position_type: "FLAT",
        position_uuid: "4b30305e-4c88-4d64-9140-f83c0655be93",
        return_at_close: 1.0000050184577658,
        trade_pair: ["CADJPY", "CAD/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 16.78803,
        close_ms: 1715721774774,
        current_return: 1.0000331688273687,
        initial_entry_price: 16.81398,
        is_closed_position: true,
        miner_hotkey: "5Dxqzduahnqw8q3XSUfTcEZGU7xmAsfJubhHZwvXVLN9fSjR",
        net_leverage: 0.0,
        open_ms: 1715721308533,
        orders: [
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "6bb18aeb-cbfb-474e-ba29-465acff986fd",
            price: 16.81398,
            price_sources: [],
            processed_ms: 1715721304156,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "614cae9b-d8e8-4083-8520-77cb963adcdf",
            price: 16.81965,
            price_sources: [],
            processed_ms: 1715721432161,
          },
          {
            leverage: -0.01,
            order_type: "SHORT",
            order_uuid: "9460a5ac-85e4-4d56-9c84-70cccf711107",
            price: 16.8456,
            price_sources: [],
            processed_ms: 1715721654496,
          },
          {
            leverage: -0.01,
            order_type: "SHORT",
            order_uuid: "273accf9-ef28-4544-8c35-b7d6cea50b9c",
            price: 16.8438,
            price_sources: [],
            processed_ms: 1715721774774,
          },
        ],
        position_type: "FLAT",
        position_uuid: "ab916c76-5cf0-438c-b546-098f583910b5",
        return_at_close: 1.0000317687809324,
        trade_pair: ["USDMXN", "USD/MXN", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 16.8457,
        close_ms: 1715722200314,
        current_return: 1.0000069157114277,
        initial_entry_price: 16.8457,
        is_closed_position: true,
        miner_hotkey: "5Dxqzduahnqw8q3XSUfTcEZGU7xmAsfJubhHZwvXVLN9fSjR",
        net_leverage: 0.0,
        open_ms: 1715721904141,
        orders: [
          {
            leverage: -0.005,
            order_type: "SHORT",
            order_uuid: "137c7e4c-7c2c-442f-91c0-85550f4e26e3",
            price: 16.8457,
            price_sources: [],
            processed_ms: 1715721900719,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "557cf557-84d1-469b-b2c0-c549dfdc882d",
            price: 16.8224,
            price_sources: [],
            processed_ms: 1715722200314,
          },
        ],
        position_type: "FLAT",
        position_uuid: "35e75d99-09f9-4fb6-829b-6da051838df0",
        return_at_close: 1.0000065657090074,
        trade_pair: ["USDMXN", "USD/MXN", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.66355,
        close_ms: 1715722392009,
        current_return: 1.0000021094185538,
        initial_entry_price: 0.66369,
        is_closed_position: true,
        miner_hotkey: "5Dxqzduahnqw8q3XSUfTcEZGU7xmAsfJubhHZwvXVLN9fSjR",
        net_leverage: 0.0,
        open_ms: 1715721651740,
        orders: [
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "17c95188-170a-462f-a1b9-f7f5a2167974",
            price: 0.66369,
            price_sources: [],
            processed_ms: 1715721647409,
          },
          {
            leverage: -0.005,
            order_type: "SHORT",
            order_uuid: "16b9ecee-8f41-469a-83fe-121019481654",
            price: 0.66383,
            price_sources: [],
            processed_ms: 1715722104103,
          },
          {
            leverage: 0.005,
            order_type: "FLAT",
            order_uuid: "b0575580-bad8-44f8-b9ce-34102a621901",
            price: 0.66383,
            price_sources: [],
            processed_ms: 1715722392009,
          },
        ],
        position_type: "FLAT",
        position_uuid: "144f7f78-d46e-4354-a831-dcceaf8d3456",
        return_at_close: 1.0000014094170773,
        trade_pair: ["CADCHF", "CAD/CHF", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.6633900000000007,
        close_ms: 1715725557767,
        current_return: 1.0000078303818818,
        initial_entry_price: 0.66408,
        is_closed_position: true,
        miner_hotkey: "5Dxqzduahnqw8q3XSUfTcEZGU7xmAsfJubhHZwvXVLN9fSjR",
        net_leverage: 0.0,
        open_ms: 1715724365635,
        orders: [
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "26dbd5ea-13c7-49ed-93fb-459bf72d379a",
            price: 0.66408,
            price_sources: [],
            processed_ms: 1715724361940,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "6a633c84-f6fa-4876-a425-2723d6814a03",
            price: 0.66402,
            price_sources: [],
            processed_ms: 1715724696430,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "28fabab2-b375-4ea8-be52-35d373566757",
            price: 0.6635,
            price_sources: [],
            processed_ms: 1715725051934,
          },
          {
            leverage: -0.015,
            order_type: "SHORT",
            order_uuid: "23ce49f9-e58f-45d7-b935-e1a157e43f15",
            price: 0.66404,
            price_sources: [],
            processed_ms: 1715725173445,
          },
          {
            leverage: -0.007,
            order_type: "SHORT",
            order_uuid: "2294115d-c44c-4539-9c20-f0c89084f132",
            price: 0.66404,
            price_sources: [],
            processed_ms: 1715725295096,
          },
          {
            leverage: 0.008,
            order_type: "FLAT",
            order_uuid: "18507cb3-9521-4dc8-b174-74ae520726b2",
            price: 0.66404,
            price_sources: [],
            processed_ms: 1715725557767,
          },
        ],
        position_type: "FLAT",
        position_uuid: "df44ba34-e4f0-4441-b661-98d75d8256e8",
        return_at_close: 1.000005730365438,
        trade_pair: ["CADCHF", "CAD/CHF", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 16.809150000000024,
        close_ms: 1715727022569,
        current_return: 1.0000099965025602,
        initial_entry_price: 16.84089,
        is_closed_position: true,
        miner_hotkey: "5Dxqzduahnqw8q3XSUfTcEZGU7xmAsfJubhHZwvXVLN9fSjR",
        net_leverage: 0.0,
        open_ms: 1715724384884,
        orders: [
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "47d9c8a9-ae1d-47d7-873a-66f7ed37938f",
            price: 16.84089,
            price_sources: [],
            processed_ms: 1715724381649,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "dacf7f14-85d2-4e06-9c79-3840141a9819",
            price: 16.8304,
            price_sources: [],
            processed_ms: 1715726440030,
          },
          {
            leverage: -0.005,
            order_type: "SHORT",
            order_uuid: "10a72d21-bcda-4863-928e-e5558496fc57",
            price: 16.84323,
            price_sources: [],
            processed_ms: 1715726690502,
          },
          {
            leverage: -0.01,
            order_type: "SHORT",
            order_uuid: "2af2f339-85d4-41db-8b7b-fd657dc0d920",
            price: 16.8451,
            price_sources: [],
            processed_ms: 1715726882249,
          },
          {
            leverage: 0.005,
            order_type: "FLAT",
            order_uuid: "f0bacf84-2df3-4e30-95f2-9e18b27df38f",
            price: 16.84282,
            price_sources: [],
            processed_ms: 1715727022569,
          },
        ],
        position_type: "FLAT",
        position_uuid: "37341c2a-e639-4388-8434-a4758af0ad84",
        return_at_close: 1.000008596488565,
        trade_pair: ["USDMXN", "USD/MXN", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.9041572933078039,
        close_ms: 1715727121304,
        current_return: 1.000001784151908,
        initial_entry_price: 0.90437,
        is_closed_position: true,
        miner_hotkey: "5Dxqzduahnqw8q3XSUfTcEZGU7xmAsfJubhHZwvXVLN9fSjR",
        net_leverage: 0.0,
        open_ms: 1715725919274,
        orders: [
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "a390cbaa-2656-49f7-ae65-1747f5e1ca58",
            price: 0.90437,
            price_sources: [],
            processed_ms: 1715725916194,
          },
          {
            leverage: -0.005,
            order_type: "SHORT",
            order_uuid: "8212eaac-daba-46b7-a6c0-e9d88dd475c6",
            price: 0.904582706692196,
            price_sources: [],
            processed_ms: 1715726194356,
          },
          {
            leverage: 0.005,
            order_type: "FLAT",
            order_uuid: "b386db07-323a-4548-a47c-c1523a2daf69",
            price: 0.90448,
            price_sources: [],
            processed_ms: 1715727121304,
          },
        ],
        position_type: "FLAT",
        position_uuid: "a2a32f9e-f835-4706-b9b5-ec0cf4e6c360",
        return_at_close: 1.000001084150659,
        trade_pair: ["AUDCAD", "AUD/CAD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.6638,
        close_ms: 1715727976078,
        current_return: 1.0000022593083504,
        initial_entry_price: 0.66392,
        is_closed_position: true,
        miner_hotkey: "5Dxqzduahnqw8q3XSUfTcEZGU7xmAsfJubhHZwvXVLN9fSjR",
        net_leverage: 0.0,
        open_ms: 1715727377499,
        orders: [
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "850e4f42-3b28-4360-8641-0e007c256472",
            price: 0.66392,
            price_sources: [],
            processed_ms: 1715727372231,
          },
          {
            leverage: -0.005,
            order_type: "SHORT",
            order_uuid: "58ef8706-7b96-4f3d-a392-1466ae7deb22",
            price: 0.66404,
            price_sources: [],
            processed_ms: 1715727673169,
          },
          {
            leverage: -0.005,
            order_type: "SHORT",
            order_uuid: "01eb8539-e73a-4444-9996-5ef3c85b915b",
            price: 0.6641,
            price_sources: [],
            processed_ms: 1715727976078,
          },
        ],
        position_type: "FLAT",
        position_uuid: "d5b56d5b-793e-4dc0-a1a4-cd34664d8a91",
        return_at_close: 1.0000015593067688,
        trade_pair: ["CADCHF", "CAD/CHF", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 16.832933333333337,
        close_ms: 1715731006740,
        current_return: 1.0000094424807675,
        initial_entry_price: 16.8335,
        is_closed_position: true,
        miner_hotkey: "5Dxqzduahnqw8q3XSUfTcEZGU7xmAsfJubhHZwvXVLN9fSjR",
        net_leverage: 0.0,
        open_ms: 1715730279845,
        orders: [
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "696a05c0-c456-4076-9d02-7c27d218f461",
            price: 16.8335,
            price_sources: [],
            processed_ms: 1715730276773,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "f7a4cd42-003a-4113-8dd5-65e9d32d3589",
            price: 16.8322,
            price_sources: [],
            processed_ms: 1715730387593,
          },
          {
            leverage: -0.005,
            order_type: "SHORT",
            order_uuid: "6934e7e4-d4d7-4c04-b245-2e78af32e8b5",
            price: 16.8326,
            price_sources: [],
            processed_ms: 1715730637092,
          },
          {
            leverage: 0.007,
            order_type: "FLAT",
            order_uuid: "6bdf6ea9-f46e-4c8a-97bd-f7062494ea5c",
            price: 16.84353,
            price_sources: [],
            processed_ms: 1715731006740,
          },
        ],
        position_type: "FLAT",
        position_uuid: "c60ca4a6-c5eb-4611-9927-6783d1262546",
        return_at_close: 1.000008042467548,
        trade_pair: ["USDMXN", "USD/MXN", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.6647599999999997,
        close_ms: 1715731100363,
        current_return: 0.9999954830161407,
        initial_entry_price: 0.66416,
        is_closed_position: true,
        miner_hotkey: "5Dxqzduahnqw8q3XSUfTcEZGU7xmAsfJubhHZwvXVLN9fSjR",
        net_leverage: 0.0,
        open_ms: 1715730158392,
        orders: [
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "dac47d65-8365-4927-a420-247fd313fd44",
            price: 0.66416,
            price_sources: [],
            processed_ms: 1715730154021,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "e83d0921-2ae7-4f81-9662-000b3311c599",
            price: 0.66411,
            price_sources: [],
            processed_ms: 1715730599716,
          },
          {
            leverage: -0.01,
            order_type: "SHORT",
            order_uuid: "c7ea9d0f-ca6a-45e6-9258-deba4ff7114c",
            price: 0.66409,
            price_sources: [],
            processed_ms: 1715730852682,
          },
          {
            leverage: -0.005,
            order_type: "SHORT",
            order_uuid: "9e1f7dec-91ef-47e2-9aa1-99ae55a7a3f9",
            price: 0.6636,
            price_sources: [],
            processed_ms: 1715730981412,
          },
          {
            leverage: 0.005,
            order_type: "FLAT",
            order_uuid: "bb9edb34-bf7a-48d5-ae5a-35f41c7fb271",
            price: 0.66416,
            price_sources: [],
            processed_ms: 1715731100363,
          },
        ],
        position_type: "FLAT",
        position_uuid: "e7d8dd0f-2be8-470c-bb9b-cf009b77499a",
        return_at_close: 0.9999940830224645,
        trade_pair: ["CADCHF", "CAD/CHF", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.9046599999999998,
        close_ms: 1715731779589,
        current_return: 0.9999993918485592,
        initial_entry_price: 0.90438,
        is_closed_position: true,
        miner_hotkey: "5Dxqzduahnqw8q3XSUfTcEZGU7xmAsfJubhHZwvXVLN9fSjR",
        net_leverage: 0.0,
        open_ms: 1715731362202,
        orders: [
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "082fea74-0e69-4086-8e1b-5f5acea5567c",
            price: 0.90438,
            price_sources: [],
            processed_ms: 1715731358414,
          },
          {
            leverage: -0.005,
            order_type: "SHORT",
            order_uuid: "e7f383e5-e90f-4591-b656-6b9ffb3eaba0",
            price: 0.9041,
            price_sources: [],
            processed_ms: 1715731650808,
          },
          {
            leverage: 0.005,
            order_type: "FLAT",
            order_uuid: "5c19dbad-09a0-462b-a820-50540a31ec1b",
            price: 0.90455,
            price_sources: [],
            processed_ms: 1715731779589,
          },
        ],
        position_type: "FLAT",
        position_uuid: "66c80a65-282a-4be9-aab0-5719266376e3",
        return_at_close: 0.9999986918489849,
        trade_pair: ["AUDCAD", "AUD/CAD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.9041342669308713,
        close_ms: 1715735011548,
        current_return: 1.0000054059591679,
        initial_entry_price: 0.904442456768038,
        is_closed_position: true,
        miner_hotkey: "5Dxqzduahnqw8q3XSUfTcEZGU7xmAsfJubhHZwvXVLN9fSjR",
        net_leverage: 0.0,
        open_ms: 1715734325203,
        orders: [
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "a76f278c-2894-4718-b5fe-662c5cce1fd9",
            price: 0.904442456768038,
            price_sources: [],
            processed_ms: 1715734318961,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "2aea1103-db03-437e-883a-e319f74f82a1",
            price: 0.904384436509146,
            price_sources: [],
            processed_ms: 1715734521620,
          },
          {
            leverage: -0.01,
            order_type: "SHORT",
            order_uuid: "6a8cbe2d-a0f3-4cac-ab09-fa8cc532a507",
            price: 0.904692626346313,
            price_sources: [],
            processed_ms: 1715734850276,
          },
          {
            leverage: -0.01,
            order_type: "SHORT",
            order_uuid: "b582f19b-304b-4ef9-9418-5f6bac0c4c51",
            price: 0.904623204829977,
            price_sources: [],
            processed_ms: 1715735011548,
          },
        ],
        position_type: "FLAT",
        position_uuid: "cae3ccf9-4f91-4a61-b53a-235bca8e1fa1",
        return_at_close: 1.0000040059515996,
        trade_pair: ["AUDCAD", "AUD/CAD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.6644900000000008,
        close_ms: 1715735151568,
        current_return: 0.9999972898504901,
        initial_entry_price: 0.66417,
        is_closed_position: true,
        miner_hotkey: "5Dxqzduahnqw8q3XSUfTcEZGU7xmAsfJubhHZwvXVLN9fSjR",
        net_leverage: 0.0,
        open_ms: 1715733071379,
        orders: [
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "2f5681d0-c41b-49b9-b8fd-92ac58bab347",
            price: 0.66417,
            price_sources: [],
            processed_ms: 1715733066598,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "53ce1c30-9bfd-47f5-b2da-45b60df00719",
            price: 0.66415,
            price_sources: [],
            processed_ms: 1715733447704,
          },
          {
            leverage: -0.01,
            order_type: "SHORT",
            order_uuid: "049a77ea-0424-4ef9-bf07-83ee763909f0",
            price: 0.66412,
            price_sources: [],
            processed_ms: 1715733569604,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "bf540be6-8fac-4773-bdc4-c0012a787d1d",
            price: 0.66418,
            price_sources: [],
            processed_ms: 1715734328694,
          },
          {
            leverage: -0.01,
            order_type: "SHORT",
            order_uuid: "3ec812b8-ae96-4d8e-8ee8-d030f70bb881",
            price: 0.66415,
            price_sources: [],
            processed_ms: 1715734459473,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "9108522e-5350-4124-ad6c-9143e1efc9cb",
            price: 0.66408,
            price_sources: [],
            processed_ms: 1715734785273,
          },
          {
            leverage: -0.01,
            order_type: "SHORT",
            order_uuid: "dc7c62b2-5f73-4f97-be0d-15b8d65a8114",
            price: 0.66403,
            price_sources: [],
            processed_ms: 1715734905584,
          },
          {
            leverage: -0.005,
            order_type: "SHORT",
            order_uuid: "db46853f-d516-4f6f-986d-acdef216bfec",
            price: 0.66407,
            price_sources: [],
            processed_ms: 1715735029496,
          },
          {
            leverage: 0.005,
            order_type: "FLAT",
            order_uuid: "b4e0d414-1662-4ac7-877b-a48d59ffea79",
            price: 0.66413,
            price_sources: [],
            processed_ms: 1715735151568,
          },
        ],
        position_type: "FLAT",
        position_uuid: "cb17459f-2445-4e7c-9d03-97a3e1fe3bbc",
        return_at_close: 0.9999944898580785,
        trade_pair: ["CADCHF", "CAD/CHF", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.904719138359569,
        close_ms: 1715736032391,
        current_return: 1.0000007136930904,
        initial_entry_price: 0.904719138359569,
        is_closed_position: true,
        miner_hotkey: "5Dxqzduahnqw8q3XSUfTcEZGU7xmAsfJubhHZwvXVLN9fSjR",
        net_leverage: 0.0,
        open_ms: 1715735138212,
        orders: [
          {
            leverage: -0.005,
            order_type: "SHORT",
            order_uuid: "6f2fb7c1-8093-4cb7-b769-ac1440b3b52e",
            price: 0.904719138359569,
            price_sources: [],
            processed_ms: 1715735132664,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "88313b7e-6830-4d18-a4ec-45c18cccb87d",
            price: 0.90459,
            price_sources: [],
            processed_ms: 1715736032391,
          },
        ],
        position_type: "FLAT",
        position_uuid: "6486377f-aeeb-4451-88d2-cadf09f3f9de",
        return_at_close: 1.0000003636928407,
        trade_pair: ["AUDCAD", "AUD/CAD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 16.8293,
        close_ms: 1715737951968,
        current_return: 1.0000069521608148,
        initial_entry_price: 16.8293,
        is_closed_position: true,
        miner_hotkey: "5Dxqzduahnqw8q3XSUfTcEZGU7xmAsfJubhHZwvXVLN9fSjR",
        net_leverage: 0.0,
        open_ms: 1715737399000,
        orders: [
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "1e5806f9-8d1a-4313-88ec-9a089acb1ddc",
            price: 16.8293,
            price_sources: [],
            processed_ms: 1715737394160,
          },
          {
            leverage: 0.005,
            order_type: "FLAT",
            order_uuid: "17d65601-09dd-4e7e-8768-8385e0c66101",
            price: 16.841,
            price_sources: [],
            processed_ms: 1715737951968,
          },
        ],
        position_type: "FLAT",
        position_uuid: "4cd50eb6-e735-416f-8c73-060ae41b6e94",
        return_at_close: 1.0000062521559483,
        trade_pair: ["USDMXN", "USD/MXN", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.66411,
        close_ms: 1715737965503,
        current_return: 0.9999996235450014,
        initial_entry_price: 0.66409,
        is_closed_position: true,
        miner_hotkey: "5Dxqzduahnqw8q3XSUfTcEZGU7xmAsfJubhHZwvXVLN9fSjR",
        net_leverage: 0.0,
        open_ms: 1715737328008,
        orders: [
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "5e00feb2-3764-4f5e-8c5a-13c0c0b633f5",
            price: 0.66409,
            price_sources: [],
            processed_ms: 1715737323968,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "ad0e86bd-10d9-4417-86f0-223c4c9b7b53",
            price: 0.66408,
            price_sources: [],
            processed_ms: 1715737578626,
          },
          {
            leverage: -0.01,
            order_type: "SHORT",
            order_uuid: "41d9fd24-b49c-4999-8292-1708dd63fbc9",
            price: 0.66408,
            price_sources: [],
            processed_ms: 1715737710538,
          },
          {
            leverage: -0.005,
            order_type: "SHORT",
            order_uuid: "c4a9d7f0-0c4a-4b81-a405-9847d4693a6f",
            price: 0.66407,
            price_sources: [],
            processed_ms: 1715737845008,
          },
          {
            leverage: 0.005,
            order_type: "FLAT",
            order_uuid: "c2740033-5572-424a-bd43-02c2847d9552",
            price: 0.66406,
            price_sources: [],
            processed_ms: 1715737965503,
          },
        ],
        position_type: "FLAT",
        position_uuid: "f822ade7-8eb9-4ede-8259-76b7a5d339e6",
        return_at_close: 0.9999982235455284,
        trade_pair: ["CADCHF", "CAD/CHF", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.905,
        close_ms: 1715739220268,
        current_return: 1.000000994475138,
        initial_entry_price: 0.905,
        is_closed_position: true,
        miner_hotkey: "5Dxqzduahnqw8q3XSUfTcEZGU7xmAsfJubhHZwvXVLN9fSjR",
        net_leverage: 0.0,
        open_ms: 1715738519534,
        orders: [
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "6ff3ac70-4de9-4283-98e7-61eebe6a9cc6",
            price: 0.905,
            price_sources: [],
            processed_ms: 1715738515032,
          },
          {
            leverage: 0.005,
            order_type: "FLAT",
            order_uuid: "842849a8-fc13-4509-afd7-d62802bc16cf",
            price: 0.90509,
            price_sources: [],
            processed_ms: 1715739220268,
          },
        ],
        position_type: "FLAT",
        position_uuid: "0da323a3-3441-4096-8d25-7bc3824f19f7",
        return_at_close: 1.000000294474442,
        trade_pair: ["AUDCAD", "AUD/CAD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.66409,
        close_ms: 1715740770923,
        current_return: 0.99999412765573,
        initial_entry_price: 0.66413,
        is_closed_position: true,
        miner_hotkey: "5Dxqzduahnqw8q3XSUfTcEZGU7xmAsfJubhHZwvXVLN9fSjR",
        net_leverage: 0.0,
        open_ms: 1715739956063,
        orders: [
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "8cfc19c5-1c6b-4008-ae16-d4a452080b09",
            price: 0.66413,
            price_sources: [],
            processed_ms: 1715739951304,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "008381c7-435a-48ce-94f0-796c9b4fe52e",
            price: 0.66413,
            price_sources: [],
            processed_ms: 1715740081336,
          },
          {
            leverage: -0.01,
            order_type: "SHORT",
            order_uuid: "6226693e-a23a-419c-a50a-48f4b1ffa151",
            price: 0.66417,
            price_sources: [],
            processed_ms: 1715740517288,
          },
          {
            leverage: 0.005,
            order_type: "FLAT",
            order_uuid: "249db0c4-53ce-45c2-ba41-dd3e4f01875b",
            price: 0.6637,
            price_sources: [],
            processed_ms: 1715740770923,
          },
        ],
        position_type: "FLAT",
        position_uuid: "5c72329e-1e7b-4ae8-aa86-3eff3ec5033b",
        return_at_close: 0.9999927276639513,
        trade_pair: ["CADCHF", "CAD/CHF", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 1.092756426707065,
        close_ms: 1715740847840,
        current_return: 1.000213028850239,
        initial_entry_price: 1.099281455933469,
        is_closed_position: true,
        miner_hotkey: "5Dxqzduahnqw8q3XSUfTcEZGU7xmAsfJubhHZwvXVLN9fSjR",
        net_leverage: 0.0,
        open_ms: 1715594071847,
        orders: [
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "f2b4a6e4-5137-4746-b858-fc14403be125",
            price: 1.099281455933469,
            price_sources: [],
            processed_ms: 1715594068236,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "87190023-a28b-4fd0-9da6-ae6945157e62",
            price: 1.09931,
            price_sources: [],
            processed_ms: 1715594352273,
          },
          {
            leverage: -0.01,
            order_type: "SHORT",
            order_uuid: "10404467-38bd-466b-8908-0b8a67f2a526",
            price: 1.099419764998216,
            price_sources: [],
            processed_ms: 1715594849249,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "f2b2ba8c-0986-4486-977d-fb532f6f87f0",
            price: 1.09948,
            price_sources: [],
            processed_ms: 1715595151186,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "07af9627-f598-415f-8b55-da16f3920b91",
            price: 1.09948,
            price_sources: [],
            processed_ms: 1715595457240,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "13350067-2f88-45f7-9546-ea64178b1c1c",
            price: 1.09952,
            price_sources: [],
            processed_ms: 1715595761347,
          },
          {
            leverage: -0.02,
            order_type: "SHORT",
            order_uuid: "ffe19755-1a2a-4c89-8bbc-80504842f538",
            price: 1.09966,
            price_sources: [],
            processed_ms: 1715595902538,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "08c68e62-24e8-4f87-9338-0b81149a1381",
            price: 1.09952,
            price_sources: [],
            processed_ms: 1715596584456,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "9e4868e8-9697-497a-b1ba-7048a198dad7",
            price: 1.09951,
            price_sources: [],
            processed_ms: 1715596915735,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "cc534c67-d1d3-4f44-9f03-3a488e485c5d",
            price: 1.09957,
            price_sources: [],
            processed_ms: 1715597618576,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "79b89661-0cc6-4590-9674-a76b15669fbb",
            price: 1.09953,
            price_sources: [],
            processed_ms: 1715598107260,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "69e37f0b-91ea-4bf1-900a-212f235baa04",
            price: 1.0994,
            price_sources: [],
            processed_ms: 1715598488436,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "ed1f96c8-15b4-4847-8971-c3e52fdab4b0",
            price: 1.099217177593852,
            price_sources: [],
            processed_ms: 1715598792240,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "2bcf3b71-c203-4694-ba6d-5ed70f0c1eea",
            price: 1.09927,
            price_sources: [],
            processed_ms: 1715599095428,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "b9f16972-258d-45cf-b806-cd0206c2a59a",
            price: 1.09919,
            price_sources: [],
            processed_ms: 1715600039328,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "a2212b6b-8058-4715-9b32-e81d64e16635",
            price: 1.099035313595113,
            price_sources: [],
            processed_ms: 1715600381299,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "37630d1b-a75d-4dd3-86f4-30cbbe9409f7",
            price: 1.098967462842553,
            price_sources: [],
            processed_ms: 1715600691274,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "f3e09383-94db-45ad-97d7-e828d42c6fd8",
            price: 1.09908,
            price_sources: [],
            processed_ms: 1715600994030,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "1bee9bc5-2fe1-419c-8be6-fa31a7843725",
            price: 1.09923,
            price_sources: [],
            processed_ms: 1715601436484,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "ec995508-6b4e-4079-810e-e3112e112aba",
            price: 1.0992,
            price_sources: [],
            processed_ms: 1715601749720,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "e466b5ee-ce8a-418f-8a71-e8a9ff290cfa",
            price: 1.09913,
            price_sources: [],
            processed_ms: 1715602136719,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "5c31b68c-db36-413c-9080-436f38167403",
            price: 1.09912,
            price_sources: [],
            processed_ms: 1715602449431,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "6525c361-9159-4b51-85a9-e2f6580f3912",
            price: 1.098973299635391,
            price_sources: [],
            processed_ms: 1715602790342,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "428a62e1-b3f1-4925-ab7e-6a28c88c54da",
            price: 1.099080864635829,
            price_sources: [],
            processed_ms: 1715603128703,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "7305a49b-d3e4-4b4e-a497-41497d4a411c",
            price: 1.09918,
            price_sources: [],
            processed_ms: 1715603430151,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "6f4872c1-522f-4636-bd9e-3ca5e58a5742",
            price: 1.099149650649397,
            price_sources: [],
            processed_ms: 1715603774206,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "7319bdb2-1b96-4c6d-86d7-ae475327218a",
            price: 1.09892,
            price_sources: [],
            processed_ms: 1715604812164,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "ca403282-bb82-42bb-ac8e-204cb088a539",
            price: 1.09887,
            price_sources: [],
            processed_ms: 1715605166419,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "3ada0abd-bc54-4cca-b187-c96ae14b0c6b",
            price: 1.09917,
            price_sources: [],
            processed_ms: 1715605860279,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "f979bb8b-8ab7-4cce-8190-ac73732b6946",
            price: 1.09917,
            price_sources: [],
            processed_ms: 1715606171286,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "eb0d4cee-5266-4afb-86a9-407635d976ee",
            price: 1.09892,
            price_sources: [],
            processed_ms: 1715606498308,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "273736eb-0f9b-4ffb-a316-acd6a3b009dd",
            price: 1.09907,
            price_sources: [],
            processed_ms: 1715607155325,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "325f941c-2472-4ed3-af1f-e7ee69d7354c",
            price: 1.09914,
            price_sources: [],
            processed_ms: 1715607514879,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "c3e3bff7-87a0-49b1-ac30-25de83c5611f",
            price: 1.09907,
            price_sources: [],
            processed_ms: 1715608533072,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "c5836727-c3e9-4da0-8980-3b328d92b29d",
            price: 1.09899,
            price_sources: [],
            processed_ms: 1715608919145,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "44027a3a-8270-4f85-a569-cd454adc2e23",
            price: 1.09899,
            price_sources: [],
            processed_ms: 1715610450449,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "d8b7b713-baf0-4eaf-907a-6f7cf1955e38",
            price: 1.09889,
            price_sources: [],
            processed_ms: 1715610762280,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "db295aa3-bda0-449e-abb9-bbb354465adb",
            price: 1.098935504749097,
            price_sources: [],
            processed_ms: 1715611070661,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "320edd20-35c8-4284-8155-309152922725",
            price: 1.098938923636055,
            price_sources: [],
            processed_ms: 1715611376384,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "da40eacc-b707-40c1-ace3-cfa84df93b84",
            price: 1.098913289979344,
            price_sources: [],
            processed_ms: 1715611704436,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "f729a3d7-6273-4642-91e7-afa018c84c7e",
            price: 1.09891,
            price_sources: [],
            processed_ms: 1715612021667,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "9b7e1a04-5eb2-4213-9196-45a44534ddaf",
            price: 1.09906,
            price_sources: [],
            processed_ms: 1715612347619,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "b8c9cf93-cb75-40ea-9144-7af74c37b635",
            price: 1.09855,
            price_sources: [],
            processed_ms: 1715612741372,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "738933be-6f86-4366-92b6-801d786fb06d",
            price: 1.09834,
            price_sources: [],
            processed_ms: 1715613067468,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "77cdb847-a5f4-4920-b976-b126b04d93c8",
            price: 1.09813,
            price_sources: [],
            processed_ms: 1715613371502,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "d1a7fb68-5560-4923-a802-69efbdcea6a0",
            price: 1.09817,
            price_sources: [],
            processed_ms: 1715613723414,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "d9e95a64-cd9c-4864-9adc-9d6a72b66192",
            price: 1.09813,
            price_sources: [],
            processed_ms: 1715614059780,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "d452d7b7-6a44-454f-9940-0f6148fa970a",
            price: 1.09817,
            price_sources: [],
            processed_ms: 1715614383571,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "a5a23ab9-4a5c-4536-962f-74faa07c3ef7",
            price: 1.098139608630736,
            price_sources: [],
            processed_ms: 1715614695262,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "cce66200-1865-460b-82e3-cf490682a6df",
            price: 1.09828,
            price_sources: [],
            processed_ms: 1715614997743,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "750167c7-52e8-4e87-b751-448701bc02e8",
            price: 1.09839,
            price_sources: [],
            processed_ms: 1715615310914,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "b30670a5-b53f-4cf6-8c09-b3eede672567",
            price: 1.09843,
            price_sources: [],
            processed_ms: 1715615973344,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "5de09967-5897-4ab5-ace2-bdba3c8156aa",
            price: 1.09845,
            price_sources: [],
            processed_ms: 1715616416450,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "e81a3b47-35ea-45a4-aa37-a0d37bdd8095",
            price: 1.09841,
            price_sources: [],
            processed_ms: 1715616829083,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "47ed839a-3b89-4630-ab4d-0ce3f332088e",
            price: 1.09829,
            price_sources: [],
            processed_ms: 1715617538744,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "8472f5c9-a002-4b42-96cf-0c58b52a7be8",
            price: 1.098218976994766,
            price_sources: [],
            processed_ms: 1715617866290,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "2623ddfa-3881-4d10-bfca-67fb84dd9912",
            price: 1.09812215749452,
            price_sources: [],
            processed_ms: 1715618548346,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "1da9325b-ce2b-4583-80f6-ac6857a6dba3",
            price: 1.098017328993855,
            price_sources: [],
            processed_ms: 1715619190734,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "503005d5-20cb-4df3-90d9-e5d700ba0ecb",
            price: 1.09809,
            price_sources: [],
            processed_ms: 1715619838488,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "88efe726-6be0-48dc-889e-6f0c624fbe2e",
            price: 1.09809,
            price_sources: [],
            processed_ms: 1715620150632,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "04179a7a-a73d-4fc3-a373-0b075e01921e",
            price: 1.09796,
            price_sources: [],
            processed_ms: 1715620759512,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "ae89880c-a79c-46aa-bfca-5a2ff18df972",
            price: 1.09797,
            price_sources: [],
            processed_ms: 1715621121332,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "622024be-7572-4cf3-918d-4de4d7c861ee",
            price: 1.098054951894216,
            price_sources: [],
            processed_ms: 1715621438260,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "020d2576-cdac-4c3c-8a92-1d45a7a239e0",
            price: 1.09802,
            price_sources: [],
            processed_ms: 1715621740259,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "a10d762a-3dc4-4030-8eb8-6fb8e6299a08",
            price: 1.09801,
            price_sources: [],
            processed_ms: 1715622356431,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "3a72b47c-2237-4fe8-a074-c7fe88fd891e",
            price: 1.09804,
            price_sources: [],
            processed_ms: 1715622673366,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "29877fca-6db5-45fd-b4ad-617f276ee8d5",
            price: 1.09787,
            price_sources: [],
            processed_ms: 1715622992370,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "4fa57468-7da9-4772-8068-50c3ce71b6d1",
            price: 1.09785,
            price_sources: [],
            processed_ms: 1715623348420,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "dec32e3a-7f28-4e47-b3e4-1d8606f6a2c7",
            price: 1.09784,
            price_sources: [],
            processed_ms: 1715623669436,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "6d139b97-0151-4df1-a15b-47240c2a1fe2",
            price: 1.09791,
            price_sources: [],
            processed_ms: 1715624321141,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "d41e1838-2a69-4f3d-a848-3ecdbda5afd3",
            price: 1.09796,
            price_sources: [],
            processed_ms: 1715624717577,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "b28c6763-b4f2-4332-a0cd-9a78d1dc2ed8",
            price: 1.0981,
            price_sources: [],
            processed_ms: 1715625029846,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "fb62892c-88ab-49a1-86b1-47c54072d363",
            price: 1.098016226594556,
            price_sources: [],
            processed_ms: 1715625381940,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "010d73e1-3e2f-4d07-991b-81c77474b1ba",
            price: 1.09805,
            price_sources: [],
            processed_ms: 1715625688551,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "167599b3-3286-463a-a2e6-6c66e89392d3",
            price: 1.097965091303227,
            price_sources: [],
            processed_ms: 1715626093730,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "9ba2a514-cf40-4cc2-9883-577fd79ba4a7",
            price: 1.09796,
            price_sources: [],
            processed_ms: 1715626400220,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "5a3415e5-38d1-4722-ba3d-6a78705f99fb",
            price: 1.09809,
            price_sources: [],
            processed_ms: 1715626701927,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "e5a2bceb-72dd-4512-b221-aa6d3a53e1d7",
            price: 1.09824,
            price_sources: [],
            processed_ms: 1715627978478,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "4296e26a-68f4-4131-a286-e9c2c07bf534",
            price: 1.09811,
            price_sources: [],
            processed_ms: 1715628580254,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "93e35790-3c7f-4226-9aec-8b15eaa7b645",
            price: 1.09806,
            price_sources: [],
            processed_ms: 1715629224855,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "14080e02-f107-49fe-9bee-bdad9116dda6",
            price: 1.098,
            price_sources: [],
            processed_ms: 1715629856086,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "4e4f8888-a410-45af-997f-3d6a2528ef32",
            price: 1.09792,
            price_sources: [],
            processed_ms: 1715630205209,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "09c13673-eea6-4732-9616-337a8fe50a92",
            price: 1.09807,
            price_sources: [],
            processed_ms: 1715630527452,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "377a92ce-8fde-4b6b-a2e0-e1565865a7d2",
            price: 1.09788,
            price_sources: [],
            processed_ms: 1715630907998,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "63dce173-1f42-4606-80ef-62905b758d68",
            price: 1.09776,
            price_sources: [],
            processed_ms: 1715631541938,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "63aad304-7f37-45de-80a2-40b8039c7f50",
            price: 1.09797,
            price_sources: [],
            processed_ms: 1715631873369,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "f7b72998-b89a-4608-9dbe-6addd2e85553",
            price: 1.09787,
            price_sources: [],
            processed_ms: 1715632184138,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "231582d8-9f91-4084-bec2-233dc1fcf81d",
            price: 1.097639741969923,
            price_sources: [],
            processed_ms: 1715632541942,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "bd406650-b8e8-4309-9b78-148eaec557f6",
            price: 1.09798,
            price_sources: [],
            processed_ms: 1715632866348,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "bc9cb308-8a28-4e16-bb9f-d37c984de97e",
            price: 1.0938,
            price_sources: [],
            processed_ms: 1715634002491,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "7ed930d1-525c-4557-a607-74a867616f23",
            price: 1.09721,
            price_sources: [],
            processed_ms: 1715634462984,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "8fb684c1-d9a8-4096-90d5-d3afd06302a5",
            price: 1.09746,
            price_sources: [],
            processed_ms: 1715643716157,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "38a9a2b8-5476-4631-bad3-d2413093ac9b",
            price: 1.097307807784897,
            price_sources: [],
            processed_ms: 1715644047119,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "6b564507-398a-42ac-b5ec-f3c74becce8e",
            price: 1.097117256934533,
            price_sources: [],
            processed_ms: 1715645182930,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "854462bb-16cf-4fba-8aa9-e0405206c0bc",
            price: 1.09733,
            price_sources: [],
            processed_ms: 1715645993963,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "7d979ef3-241d-4fba-81f7-8b3ac00fd113",
            price: 1.09807,
            price_sources: [],
            processed_ms: 1715648048614,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "da2c5159-f669-4bd8-9c67-a8c79cd3b094",
            price: 1.097735868433803,
            price_sources: [],
            processed_ms: 1715648349983,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "ece47583-a118-41c8-85a9-4710796bc580",
            price: 1.09743,
            price_sources: [],
            processed_ms: 1715657172436,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "19d8b76b-1699-4d72-833d-15e0f0b8a9f9",
            price: 1.09719,
            price_sources: [],
            processed_ms: 1715658538862,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "875a9ea5-1d3d-446c-90d2-9c58302001c3",
            price: 1.097157493101673,
            price_sources: [],
            processed_ms: 1715659284456,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "d7afa100-0319-4755-8fec-54ab986fd186",
            price: 1.09705,
            price_sources: [],
            processed_ms: 1715659586240,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "df98907e-68fb-4352-a66e-5431a66229d0",
            price: 1.097133153414862,
            price_sources: [],
            processed_ms: 1715659936608,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "2dad9474-8214-4ffe-8dd4-cd2d99a8bf4a",
            price: 1.09708,
            price_sources: [],
            processed_ms: 1715661369522,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "5d2c3c56-f897-4d45-a6f3-717d9ba544b7",
            price: 1.09711,
            price_sources: [],
            processed_ms: 1715661711251,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "7666e195-26d6-4653-bdf6-f6789eb17833",
            price: 1.09705,
            price_sources: [],
            processed_ms: 1715662031281,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "3d322360-06aa-4666-b68c-ff04602e345d",
            price: 1.09699,
            price_sources: [],
            processed_ms: 1715706908817,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "47899ecd-a5d2-4886-8d96-ffceb3e2a11c",
            price: 1.0969,
            price_sources: [],
            processed_ms: 1715707218330,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "a2ef0065-ba37-483d-bfed-c928cf169332",
            price: 1.096761195798102,
            price_sources: [],
            processed_ms: 1715707562403,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "bf148b99-b680-4f13-bc98-1f801ec871e7",
            price: 1.09675,
            price_sources: [],
            processed_ms: 1715707886417,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "bd65f623-1a33-43be-b463-6fbd1d7055ea",
            price: 1.09675,
            price_sources: [],
            processed_ms: 1715708190455,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "37ff885b-34f8-45b2-b9a3-db24cf23da14",
            price: 1.0959,
            price_sources: [],
            processed_ms: 1715708506347,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "09c408fc-90e1-4b13-adc5-a0134403c58f",
            price: 1.09687,
            price_sources: [],
            processed_ms: 1715718411660,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "c3ae488b-5ef9-4d4f-acb8-165a30721cb8",
            price: 1.09672,
            price_sources: [],
            processed_ms: 1715718741323,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "594ccd93-53c5-43db-8d1c-0f0fe126af92",
            price: 1.09668,
            price_sources: [],
            processed_ms: 1715719066082,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "fb631add-48e7-4e4e-9cb0-b8579c6ba3bb",
            price: 1.096708916080893,
            price_sources: [],
            processed_ms: 1715719380444,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "38edff3a-b80d-4a1c-b9a0-38280e0dde24",
            price: 1.09697,
            price_sources: [],
            processed_ms: 1715719779262,
          },
          {
            leverage: -0.53,
            order_type: "SHORT",
            order_uuid: "4b67625d-cb77-4c06-9838-ff0de9a9aa0d",
            price: 1.098523701450338,
            price_sources: [],
            processed_ms: 1715720433385,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "a9fd89c9-3691-4eed-871e-4eac4884daed",
            price: 1.0961,
            price_sources: [],
            processed_ms: 1715721212593,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "ee84be7d-ef31-4dc8-abee-75113cbd18e1",
            price: 1.0963,
            price_sources: [],
            processed_ms: 1715723027412,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "2d161e55-0ddb-4551-b6bc-ef235abb615f",
            price: 1.09663,
            price_sources: [],
            processed_ms: 1715723499381,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "589af0cb-f74e-4a34-9932-24e6fc388ad3",
            price: 1.09697,
            price_sources: [],
            processed_ms: 1715724087027,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "868256c6-01d2-435d-888d-6e3227f6307c",
            price: 1.0959,
            price_sources: [],
            processed_ms: 1715724397214,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "e483ce3c-2482-4860-99b7-af9ca71374f8",
            price: 1.09662,
            price_sources: [],
            processed_ms: 1715724707316,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "f82f38f6-bd7b-4374-86a2-a1e6c6c81978",
            price: 1.09674,
            price_sources: [],
            processed_ms: 1715725007633,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "f2ccafb8-869d-47dc-b343-6e4bba99302c",
            price: 1.0958,
            price_sources: [],
            processed_ms: 1715725661765,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "f24053a2-8e36-41e6-ad30-7cbc3c006ff3",
            price: 1.0969,
            price_sources: [],
            processed_ms: 1715725987716,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "ec03cab9-f9e0-4120-8eb5-da55f2656354",
            price: 1.09696,
            price_sources: [],
            processed_ms: 1715726298460,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "1d61ef86-efc3-4b4f-8815-2344a1c5f5b4",
            price: 1.0967,
            price_sources: [],
            processed_ms: 1715727278941,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "55ab8a7d-834b-44bc-b137-9a2ffdf28e26",
            price: 1.09672,
            price_sources: [],
            processed_ms: 1715727584545,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "b5f1dcc2-b271-4e0d-b9c2-c01f8a063cb1",
            price: 1.096406927840514,
            price_sources: [],
            processed_ms: 1715728192611,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "ad8d0a43-cfbf-40f0-a609-d1a2aa26044a",
            price: 1.09638,
            price_sources: [],
            processed_ms: 1715728495787,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "7a6f1965-11c0-4448-8986-0d5882da8b25",
            price: 1.09639439775163,
            price_sources: [],
            processed_ms: 1715728846472,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "ce731f7b-cd3d-43f8-bcf9-7489379bb644",
            price: 1.096338197751495,
            price_sources: [],
            processed_ms: 1715729163052,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "16d952f7-8a88-4fe0-9be4-4a339140ac1d",
            price: 1.0953,
            price_sources: [],
            processed_ms: 1715729466842,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "a32e95d3-0187-48f2-840e-acc3cdb225f4",
            price: 1.0952,
            price_sources: [],
            processed_ms: 1715729783358,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "060150fe-4ad6-4fb4-83d6-97c037c17ef4",
            price: 1.0961,
            price_sources: [],
            processed_ms: 1715730102383,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "0ccd14e9-403e-4d75-b16c-0f4b27748bb7",
            price: 1.09615,
            price_sources: [],
            processed_ms: 1715730763977,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "f119eecb-33bb-41a1-bd7b-2b8a1c54a00e",
            price: 1.09611,
            price_sources: [],
            processed_ms: 1715731069956,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "021b1e55-a3eb-4347-a5b2-fb4ac2b22a99",
            price: 1.09599,
            price_sources: [],
            processed_ms: 1715731684293,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "b9eefe10-248b-4027-8458-c1aa9a6f657d",
            price: 1.09609,
            price_sources: [],
            processed_ms: 1715731988660,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "ead2d4c8-cf9e-4194-8085-7bb12b7f5ceb",
            price: 1.09619,
            price_sources: [],
            processed_ms: 1715732366921,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "de6b0c2b-54e8-4c3c-bbbe-3eb80fcc09c5",
            price: 1.09593,
            price_sources: [],
            processed_ms: 1715732668476,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "cabd8159-db9f-4474-ae29-b91da373653e",
            price: 1.09593,
            price_sources: [],
            processed_ms: 1715732982139,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "a84c178a-5089-4b6a-9ed2-02b218d419f0",
            price: 1.09591,
            price_sources: [],
            processed_ms: 1715733287701,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "b38241d3-9ba2-4c28-b3b8-f8efac399284",
            price: 1.09596873033224,
            price_sources: [],
            processed_ms: 1715733611660,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "419a3652-4ec1-4a24-9623-a4b57731ca5e",
            price: 1.09623,
            price_sources: [],
            processed_ms: 1715733921190,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "28fc3a7d-7164-4a91-b699-a53a55fb9171",
            price: 1.09648,
            price_sources: [],
            processed_ms: 1715734217669,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "c3ee8856-cc5e-4d69-b749-8dacb5f61c5e",
            price: 1.09635,
            price_sources: [],
            processed_ms: 1715734532790,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "22406e51-a6b6-4b9e-87fc-390d5239ac1f",
            price: 1.09638,
            price_sources: [],
            processed_ms: 1715735496218,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "67c49ced-5064-40c7-8bcf-4e591add7db4",
            price: 1.09634,
            price_sources: [],
            processed_ms: 1715735818385,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "37cbd332-f8eb-4bf5-94f0-8266ab7fa5d0",
            price: 1.09653,
            price_sources: [],
            processed_ms: 1715736133796,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "ff69f406-10c0-4ad4-b44b-1aa27156cd66",
            price: 1.0965,
            price_sources: [],
            processed_ms: 1715736442623,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "b24c1845-587f-4593-ad75-35cfd4accc92",
            price: 1.09671,
            price_sources: [],
            processed_ms: 1715736774978,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "38ec68ea-224d-49e8-a589-e401a27481c7",
            price: 1.09659,
            price_sources: [],
            processed_ms: 1715737142223,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "4023b66b-4a86-481c-94c0-316956a6c57c",
            price: 1.09671,
            price_sources: [],
            processed_ms: 1715738088443,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "53b65245-0dd6-44f9-abbd-d1932ca13cfc",
            price: 1.09684,
            price_sources: [],
            processed_ms: 1715738516908,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "4b0894ee-1fa9-4e83-a033-5eb97770bac8",
            price: 1.09676,
            price_sources: [],
            processed_ms: 1715738922684,
          },
          {
            leverage: -0.465,
            order_type: "SHORT",
            order_uuid: "797cf6bf-c449-4735-941c-7bccd3757ae1",
            price: 1.09733,
            price_sources: [],
            processed_ms: 1715740502565,
          },
          {
            leverage: -0.465,
            order_type: "SHORT",
            order_uuid: "2667e5bb-21a8-49b0-b77a-ce26eca01cfc",
            price: 1.097553202084924,
            price_sources: [],
            processed_ms: 1715740726853,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "b518677d-a782-4c19-9db8-cab89c067807",
            price: 1.09744,
            price_sources: [],
            processed_ms: 1715740847840,
          },
        ],
        position_type: "FLAT",
        position_uuid: "9e572ecf-4b5d-4fb7-905c-abe40e6fb50c",
        return_at_close: 1.0001052058857292,
        trade_pair: ["AUDNZD", "AUD/NZD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 16.839699999999997,
        close_ms: 1715741665213,
        current_return: 0.9999966436972794,
        initial_entry_price: 16.834,
        is_closed_position: true,
        miner_hotkey: "5Dxqzduahnqw8q3XSUfTcEZGU7xmAsfJubhHZwvXVLN9fSjR",
        net_leverage: 0.0,
        open_ms: 1715741269289,
        orders: [
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "299bd1d1-4994-4a0d-ac11-a4190280451e",
            price: 16.834,
            price_sources: [],
            processed_ms: 1715741260922,
          },
          {
            leverage: -0.005,
            order_type: "SHORT",
            order_uuid: "effa11e2-a50c-425c-9e93-aaff7decdb16",
            price: 16.8283,
            price_sources: [],
            processed_ms: 1715741461229,
          },
          {
            leverage: 0.005,
            order_type: "FLAT",
            order_uuid: "892b6d92-a041-4abe-957f-3f1cb98ca28b",
            price: 16.8284,
            price_sources: [],
            processed_ms: 1715741665213,
          },
        ],
        position_type: "FLAT",
        position_uuid: "31968152-7048-4ff0-9bcf-954e0105b7d3",
        return_at_close: 0.9999959436996289,
        trade_pair: ["USDMXN", "USD/MXN", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 16.834155,
        close_ms: 1715742235498,
        current_return: 0.9999959857137763,
        initial_entry_price: 16.8274,
        is_closed_position: true,
        miner_hotkey: "5Dxqzduahnqw8q3XSUfTcEZGU7xmAsfJubhHZwvXVLN9fSjR",
        net_leverage: 0.0,
        open_ms: 1715741791835,
        orders: [
          {
            leverage: -0.005,
            order_type: "SHORT",
            order_uuid: "008fd853-3205-4917-91d6-b60b8f91b52d",
            price: 16.8274,
            price_sources: [],
            processed_ms: 1715741787509,
          },
          {
            leverage: -0.005,
            order_type: "SHORT",
            order_uuid: "c611f34b-79cb-45f1-ba17-b84b1fac49a8",
            price: 16.84091,
            price_sources: [],
            processed_ms: 1715742085555,
          },
          {
            leverage: 0.005,
            order_type: "FLAT",
            order_uuid: "84bb5830-332c-4a6f-9159-5b1e5604ee99",
            price: 16.84091,
            price_sources: [],
            processed_ms: 1715742235498,
          },
        ],
        position_type: "FLAT",
        position_uuid: "96c9884f-605f-417c-a539-711add9df430",
        return_at_close: 0.9999952857165864,
        trade_pair: ["USDMXN", "USD/MXN", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.905240622040393,
        close_ms: 1715742514762,
        current_return: 1.0000061796970243,
        initial_entry_price: 0.90569,
        is_closed_position: true,
        miner_hotkey: "5Dxqzduahnqw8q3XSUfTcEZGU7xmAsfJubhHZwvXVLN9fSjR",
        net_leverage: 0.0,
        open_ms: 1715741441093,
        orders: [
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "1fc43f7b-17b1-4fcc-9683-f9780fed0d9d",
            price: 0.90569,
            price_sources: [],
            processed_ms: 1715741436552,
          },
          {
            leverage: -0.005,
            order_type: "SHORT",
            order_uuid: "ded57ea3-7854-4aa4-b760-9e6aea36ac2b",
            price: 0.906139377959607,
            price_sources: [],
            processed_ms: 1715742397607,
          },
          {
            leverage: 0.005,
            order_type: "FLAT",
            order_uuid: "e33820d8-13d6-4a46-89d1-f836919849a8",
            price: 0.90636,
            price_sources: [],
            processed_ms: 1715742514762,
          },
        ],
        position_type: "FLAT",
        position_uuid: "ea54f36b-2f4e-4eb1-a770-06bcf5e99a00",
        return_at_close: 1.0000054796926985,
        trade_pair: ["AUDCAD", "AUD/CAD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 1.09669,
        close_ms: 1715743040423,
        current_return: 1.0000019418663326,
        initial_entry_price: 1.0968,
        is_closed_position: true,
        miner_hotkey: "5Dxqzduahnqw8q3XSUfTcEZGU7xmAsfJubhHZwvXVLN9fSjR",
        net_leverage: 0.0,
        open_ms: 1715742671112,
        orders: [
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "719605c7-d870-4aa7-ae25-5b2ed5b6b741",
            price: 1.0968,
            price_sources: [],
            processed_ms: 1715742667355,
          },
          {
            leverage: -0.005,
            order_type: "SHORT",
            order_uuid: "b6183ca1-8275-423e-b138-181895347ca0",
            price: 1.09691,
            price_sources: [],
            processed_ms: 1715742834761,
          },
          {
            leverage: 0.005,
            order_type: "FLAT",
            order_uuid: "26c2c2b8-a8ca-4e30-b67d-4b887b133ff3",
            price: 1.097115967798705,
            price_sources: [],
            processed_ms: 1715743040423,
          },
        ],
        position_type: "FLAT",
        position_uuid: "76c20b8e-757d-4281-925d-ef318d0d07e4",
        return_at_close: 1.0000012418649733,
        trade_pair: ["AUDNZD", "AUD/NZD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.6637033333333333,
        close_ms: 1715743758354,
        current_return: 1.0000085091642947,
        initial_entry_price: 0.66399,
        is_closed_position: true,
        miner_hotkey: "5Dxqzduahnqw8q3XSUfTcEZGU7xmAsfJubhHZwvXVLN9fSjR",
        net_leverage: 0.0,
        open_ms: 1715742861340,
        orders: [
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "4ba3879f-98ec-4397-bfd1-dfd472d2f637",
            price: 0.66399,
            price_sources: [],
            processed_ms: 1715742856760,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "90ccfef7-9c0f-480a-bda9-fc0bbaf032c5",
            price: 0.66407,
            price_sources: [],
            processed_ms: 1715742938565,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "cdd61c7a-b637-413d-87bb-5621ac43a3db",
            price: 0.6636,
            price_sources: [],
            processed_ms: 1715743510474,
          },
          {
            leverage: -0.015,
            order_type: "SHORT",
            order_uuid: "4f878367-3b7c-4dd3-bd10-63eb779215c5",
            price: 0.66407,
            price_sources: [],
            processed_ms: 1715743634064,
          },
          {
            leverage: -0.015,
            order_type: "SHORT",
            order_uuid: "3ac03314-62d9-4aea-bf03-c3bd52103300",
            price: 0.66408,
            price_sources: [],
            processed_ms: 1715743758354,
          },
        ],
        position_type: "FLAT",
        position_uuid: "b0bc1c86-e6a2-460b-9815-8aae1599915d",
        return_at_close: 1.0000064091464256,
        trade_pair: ["CADCHF", "CAD/CHF", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.66411,
        close_ms: 1715744275166,
        current_return: 0.9999994729788739,
        initial_entry_price: 0.66411,
        is_closed_position: true,
        miner_hotkey: "5Dxqzduahnqw8q3XSUfTcEZGU7xmAsfJubhHZwvXVLN9fSjR",
        net_leverage: 0.0,
        open_ms: 1715743890250,
        orders: [
          {
            leverage: -0.007,
            order_type: "SHORT",
            order_uuid: "d491f575-6208-4a13-aec5-e566f0773113",
            price: 0.66411,
            price_sources: [],
            processed_ms: 1715743886207,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "048e36fc-9717-447f-b9c9-8a05ce10c082",
            price: 0.66416,
            price_sources: [],
            processed_ms: 1715744275166,
          },
        ],
        position_type: "FLAT",
        position_uuid: "6ac1c883-4b7a-447c-8d8b-46dff368dada",
        return_at_close: 0.9999989829791321,
        trade_pair: ["CADCHF", "CAD/CHF", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.9069863376264163,
        close_ms: 1715745038799,
        current_return: 0.9999985866796931,
        initial_entry_price: 0.906863168813208,
        is_closed_position: true,
        miner_hotkey: "5Dxqzduahnqw8q3XSUfTcEZGU7xmAsfJubhHZwvXVLN9fSjR",
        net_leverage: 0.0,
        open_ms: 1715744587479,
        orders: [
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "00504f1f-c1b8-4bd2-82cc-1373a3295b5f",
            price: 0.906863168813208,
            price_sources: [],
            processed_ms: 1715744586629,
          },
          {
            leverage: -0.005,
            order_type: "SHORT",
            order_uuid: "2825cdd1-6a05-4850-b7b1-083323af2dd4",
            price: 0.90674,
            price_sources: [],
            processed_ms: 1715744917077,
          },
          {
            leverage: 0.005,
            order_type: "FLAT",
            order_uuid: "6709e15c-80f2-4673-b151-f8cf0dd7ff0c",
            price: 0.90673,
            price_sources: [],
            processed_ms: 1715745038799,
          },
        ],
        position_type: "FLAT",
        position_uuid: "80e8b89f-3e1e-4b67-8e03-3f7f15d114dc",
        return_at_close: 0.9999978866806825,
        trade_pair: ["AUDCAD", "AUD/CAD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 1.09695,
        close_ms: 1715745130259,
        current_return: 1.000001952125361,
        initial_entry_price: 1.09695,
        is_closed_position: true,
        miner_hotkey: "5Dxqzduahnqw8q3XSUfTcEZGU7xmAsfJubhHZwvXVLN9fSjR",
        net_leverage: 0.0,
        open_ms: 1715744853955,
        orders: [
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "e6f6dbba-edb2-437d-8ff2-4891cca83ad1",
            price: 1.09695,
            price_sources: [],
            processed_ms: 1715744852804,
          },
          {
            leverage: 0.005,
            order_type: "FLAT",
            order_uuid: "d6439c6f-e573-4f72-9688-cbeb1d80c004",
            price: 1.097164138391462,
            price_sources: [],
            processed_ms: 1715745130259,
          },
        ],
        position_type: "FLAT",
        position_uuid: "0d40b28e-4cc3-4a76-9217-771dd7df10d2",
        return_at_close: 1.0000012521239945,
        trade_pair: ["AUDNZD", "AUD/NZD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.906125024911978,
        close_ms: 1715747233180,
        current_return: 1.0000045646629094,
        initial_entry_price: 0.906125024911978,
        is_closed_position: true,
        miner_hotkey: "5Dxqzduahnqw8q3XSUfTcEZGU7xmAsfJubhHZwvXVLN9fSjR",
        net_leverage: 0.0,
        open_ms: 1715746984178,
        orders: [
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "7a1b1b9d-1526-40e6-b23a-8b1123a99738",
            price: 0.906125024911978,
            price_sources: [],
            processed_ms: 1715746976570,
          },
          {
            leverage: 0.005,
            order_type: "FLAT",
            order_uuid: "82e85e78-49ca-4d23-b522-7a173e56d3b5",
            price: 0.906538640441226,
            price_sources: [],
            processed_ms: 1715747233180,
          },
        ],
        position_type: "FLAT",
        position_uuid: "8334fbdd-2e95-48ac-89a5-830fd890babc",
        return_at_close: 1.0000038646597142,
        trade_pair: ["AUDCAD", "AUD/CAD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 1.0978099999999997,
        close_ms: 1715747768136,
        current_return: 0.9999966408669988,
        initial_entry_price: 1.09731,
        is_closed_position: true,
        miner_hotkey: "5Dxqzduahnqw8q3XSUfTcEZGU7xmAsfJubhHZwvXVLN9fSjR",
        net_leverage: 0.0,
        open_ms: 1715746950449,
        orders: [
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "3c86a2fe-8cf0-4561-ac85-a18098ca26fe",
            price: 1.09731,
            price_sources: [],
            processed_ms: 1715746946404,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "2581203b-f613-4e59-aa1b-db6336bd3617",
            price: 1.0963,
            price_sources: [],
            processed_ms: 1715747257968,
          },
          {
            leverage: -0.01,
            order_type: "SHORT",
            order_uuid: "e8f218c4-c094-4682-90e1-54966fabd380",
            price: 1.0962,
            price_sources: [],
            processed_ms: 1715747499460,
          },
          {
            leverage: -0.005,
            order_type: "SHORT",
            order_uuid: "417e2e87-4212-434f-aa55-7b0b0075ee98",
            price: 1.09701,
            price_sources: [],
            processed_ms: 1715747625344,
          },
          {
            leverage: 0.005,
            order_type: "FLAT",
            order_uuid: "69dd55e9-2fd4-4901-9ff3-3406c2091b40",
            price: 1.097072797953284,
            price_sources: [],
            processed_ms: 1715747768136,
          },
        ],
        position_type: "FLAT",
        position_uuid: "bbde22cb-2675-4177-a00b-86afc43e8857",
        return_at_close: 0.9999952408717016,
        trade_pair: ["AUDNZD", "AUD/NZD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.6643699999999998,
        close_ms: 1715748830142,
        current_return: 0.9999966273187184,
        initial_entry_price: 0.66416,
        is_closed_position: true,
        miner_hotkey: "5Dxqzduahnqw8q3XSUfTcEZGU7xmAsfJubhHZwvXVLN9fSjR",
        net_leverage: 0.0,
        open_ms: 1715746192589,
        orders: [
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "5eecbbe1-2863-4292-b3e0-f22fec2f9fa4",
            price: 0.66416,
            price_sources: [],
            processed_ms: 1715746184626,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "cec0ad49-1d16-4712-af68-302bfbe62cc5",
            price: 0.6641,
            price_sources: [],
            processed_ms: 1715746498691,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "21b8d353-734a-4eb9-9630-cf60c9dea261",
            price: 0.66409,
            price_sources: [],
            processed_ms: 1715746895820,
          },
          {
            leverage: -0.015,
            order_type: "SHORT",
            order_uuid: "94961c9c-62f7-4aad-adb0-c9bf8b4b3932",
            price: 0.6636,
            price_sources: [],
            processed_ms: 1715747016474,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "35e8c2d1-12b2-403b-b013-58c7feb37963",
            price: 0.6636,
            price_sources: [],
            processed_ms: 1715747690696,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "5c874fac-4919-4865-bf09-cef3b6be267a",
            price: 0.6638,
            price_sources: [],
            processed_ms: 1715748308426,
          },
          {
            leverage: -0.018,
            order_type: "SHORT",
            order_uuid: "76410edc-578f-4bea-b22b-dd0e977d3d2f",
            price: 0.66413,
            price_sources: [],
            processed_ms: 1715748429708,
          },
          {
            leverage: -0.009,
            order_type: "SHORT",
            order_uuid: "ab8c2884-ad84-49aa-b9ea-6b7199d98409",
            price: 0.6638,
            price_sources: [],
            processed_ms: 1715748689202,
          },
          {
            leverage: 0.009,
            order_type: "FLAT",
            order_uuid: "9b8236d0-4217-4715-94de-682d74d91523",
            price: 0.66409,
            price_sources: [],
            processed_ms: 1715748830142,
          },
        ],
        position_type: "FLAT",
        position_uuid: "4b751770-548e-400a-b457-e78976c495ed",
        return_at_close: 0.9999931273305227,
        trade_pair: ["CADCHF", "CAD/CHF", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 1.09605,
        close_ms: 1715749835281,
        current_return: 1.0000020979276123,
        initial_entry_price: 1.09632,
        is_closed_position: true,
        miner_hotkey: "5Dxqzduahnqw8q3XSUfTcEZGU7xmAsfJubhHZwvXVLN9fSjR",
        net_leverage: 0.0,
        open_ms: 1715749590620,
        orders: [
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "3aed79bd-a019-46b0-8c3e-7b0ff63d8db0",
            price: 1.09632,
            price_sources: [],
            processed_ms: 1715749586348,
          },
          {
            leverage: -0.005,
            order_type: "SHORT",
            order_uuid: "bfa40e5d-a134-44e3-89d6-7c3c79579ce5",
            price: 1.09659,
            price_sources: [],
            processed_ms: 1715749702302,
          },
          {
            leverage: 0.005,
            order_type: "FLAT",
            order_uuid: "3925d6f6-ab59-4f7c-a483-2ef8240bd471",
            price: 1.09651,
            price_sources: [],
            processed_ms: 1715749835281,
          },
        ],
        position_type: "FLAT",
        position_uuid: "a33e94a7-f74f-45e1-9c95-b8020e60ac75",
        return_at_close: 1.0000013979261437,
        trade_pair: ["AUDNZD", "AUD/NZD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.66397,
        close_ms: 1715751239283,
        current_return: 0.9999979666837365,
        initial_entry_price: 0.66394,
        is_closed_position: true,
        miner_hotkey: "5Dxqzduahnqw8q3XSUfTcEZGU7xmAsfJubhHZwvXVLN9fSjR",
        net_leverage: 0.0,
        open_ms: 1715750645527,
        orders: [
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "aab6be45-df85-4cee-9693-b375c461f017",
            price: 0.66394,
            price_sources: [],
            processed_ms: 1715750640217,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "9b4a2126-91e9-4de3-a9d5-adbf554a4dab",
            price: 0.66394,
            price_sources: [],
            processed_ms: 1715750723126,
          },
          {
            leverage: -0.01,
            order_type: "SHORT",
            order_uuid: "7a49d303-c6d2-4e69-98f6-30c8e9f176f4",
            price: 0.66391,
            price_sources: [],
            processed_ms: 1715750992241,
          },
          {
            leverage: -0.005,
            order_type: "SHORT",
            order_uuid: "9a770423-4535-4f06-a77e-5b03fcb65962",
            price: 0.66397,
            price_sources: [],
            processed_ms: 1715751114155,
          },
          {
            leverage: -0.007,
            order_type: "SHORT",
            order_uuid: "97042cba-048d-42f1-9636-83fae793ee9e",
            price: 0.6637,
            price_sources: [],
            processed_ms: 1715751239283,
          },
        ],
        position_type: "FLAT",
        position_uuid: "9edb413f-0eca-43eb-abe7-c1e0aa23fe65",
        return_at_close: 0.9999965666865831,
        trade_pair: ["CADCHF", "CAD/CHF", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 1.0965099999999999,
        close_ms: 1715752015316,
        current_return: 0.999999688236514,
        initial_entry_price: 1.09649,
        is_closed_position: true,
        miner_hotkey: "5Dxqzduahnqw8q3XSUfTcEZGU7xmAsfJubhHZwvXVLN9fSjR",
        net_leverage: 0.0,
        open_ms: 1715751692572,
        orders: [
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "8c399774-a9ad-4202-9840-984cde43808b",
            price: 1.09649,
            price_sources: [],
            processed_ms: 1715751690817,
          },
          {
            leverage: -0.005,
            order_type: "SHORT",
            order_uuid: "105118cf-b64a-4d37-a5db-1197a9809ffa",
            price: 1.09647,
            price_sources: [],
            processed_ms: 1715751894329,
          },
          {
            leverage: 0.005,
            order_type: "FLAT",
            order_uuid: "f2fbba51-1b58-4f77-9938-a44a9d189923",
            price: 1.09644163089104,
            price_sources: [],
            processed_ms: 1715752015316,
          },
        ],
        position_type: "FLAT",
        position_uuid: "13191c89-638c-49ad-98aa-d51f808f867e",
        return_at_close: 0.9999989882367323,
        trade_pair: ["AUDNZD", "AUD/NZD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 16.834559999999996,
        close_ms: 1715752907181,
        current_return: 1.0000001306723687,
        initial_entry_price: 16.836,
        is_closed_position: true,
        miner_hotkey: "5Dxqzduahnqw8q3XSUfTcEZGU7xmAsfJubhHZwvXVLN9fSjR",
        net_leverage: 0.0,
        open_ms: 1715752654536,
        orders: [
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "d59e71c3-5bea-4982-8f63-71a0f27114f3",
            price: 16.836,
            price_sources: [],
            processed_ms: 1715752652469,
          },
          {
            leverage: -0.005,
            order_type: "SHORT",
            order_uuid: "3446845f-70a0-4b9a-b0b3-e6844f545a34",
            price: 16.83744,
            price_sources: [],
            processed_ms: 1715752779371,
          },
          {
            leverage: 0.005,
            order_type: "FLAT",
            order_uuid: "e45a1ca5-5d6c-4cf1-a428-ab74c5f63188",
            price: 16.835,
            price_sources: [],
            processed_ms: 1715752907181,
          },
        ],
        position_type: "FLAT",
        position_uuid: "6fcebaa1-752f-46ec-b8ee-4d46b909e73b",
        return_at_close: 0.9999994306722773,
        trade_pair: ["USDMXN", "USD/MXN", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.66387625,
        close_ms: 1715753894400,
        current_return: 1.0000020935942042,
        initial_entry_price: 0.66393,
        is_closed_position: true,
        miner_hotkey: "5Dxqzduahnqw8q3XSUfTcEZGU7xmAsfJubhHZwvXVLN9fSjR",
        net_leverage: 0.0,
        open_ms: 1715753208324,
        orders: [
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "27cacf42-d946-4c89-9ed7-79378f2e3251",
            price: 0.66393,
            price_sources: [],
            processed_ms: 1715753206204,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "ff889273-f42e-4dd9-aae6-e95c287d51b8",
            price: 0.66397,
            price_sources: [],
            processed_ms: 1715753299020,
          },
          {
            leverage: -0.005,
            order_type: "SHORT",
            order_uuid: "f7690189-a201-4083-bdba-6727432c2eb3",
            price: 0.66397,
            price_sources: [],
            processed_ms: 1715753527246,
          },
          {
            leverage: -0.007,
            order_type: "SHORT",
            order_uuid: "b6961466-0ffe-4b32-9701-906e9d0beeb9",
            price: 0.66402,
            price_sources: [],
            processed_ms: 1715753773935,
          },
          {
            leverage: 0.007,
            order_type: "FLAT",
            order_uuid: "9029789b-a2c0-47e9-8d6a-876da9b6ddd9",
            price: 0.66405,
            price_sources: [],
            processed_ms: 1715753894400,
          },
        ],
        position_type: "FLAT",
        position_uuid: "38891c66-c949-4972-82a4-01e9c685cf6c",
        return_at_close: 1.000000693591273,
        trade_pair: ["CADCHF", "CAD/CHF", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 1.096700240507552,
        close_ms: 1715755087379,
        current_return: 0.9999993976842027,
        initial_entry_price: 1.09617,
        is_closed_position: true,
        miner_hotkey: "5Dxqzduahnqw8q3XSUfTcEZGU7xmAsfJubhHZwvXVLN9fSjR",
        net_leverage: 0.0,
        open_ms: 1715753820735,
        orders: [
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "96f81f37-b541-458b-bd8b-a71c7f3b6fb5",
            price: 1.09617,
            price_sources: [],
            processed_ms: 1715753818309,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "de8f33f9-4465-4528-84e4-dc87246ad938",
            price: 1.096052755090733,
            price_sources: [],
            processed_ms: 1715753889873,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "5e5d31c8-265b-4e3a-a9dd-438e75a4d9f1",
            price: 1.0962,
            price_sources: [],
            processed_ms: 1715753993718,
          },
          {
            leverage: -0.015,
            order_type: "SHORT",
            order_uuid: "892c8fb8-7ead-4645-8f1f-b9a6eccaacfd",
            price: 1.0962,
            price_sources: [],
            processed_ms: 1715754337285,
          },
          {
            leverage: -0.007,
            order_type: "SHORT",
            order_uuid: "00014503-c7e0-48d6-943b-8c57901b007c",
            price: 1.09604,
            price_sources: [],
            processed_ms: 1715754700294,
          },
          {
            leverage: -0.007,
            order_type: "SHORT",
            order_uuid: "16b3530f-fa44-4cc0-aa00-9996ff7ae02f",
            price: 1.096035330057112,
            price_sources: [],
            processed_ms: 1715754965218,
          },
          {
            leverage: 0.007,
            order_type: "FLAT",
            order_uuid: "815a28b0-95f6-4485-9cfd-3ad17152dd3a",
            price: 1.09604,
            price_sources: [],
            processed_ms: 1715755087379,
          },
        ],
        position_type: "FLAT",
        position_uuid: "42c92f6c-3978-4ec4-a014-c0feb8bee0fa",
        return_at_close: 0.9999972976854675,
        trade_pair: ["AUDNZD", "AUD/NZD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 1.095942356222606,
        close_ms: 1715755405177,
        current_return: 0.9999995040738788,
        initial_entry_price: 1.095942356222606,
        is_closed_position: true,
        miner_hotkey: "5Dxqzduahnqw8q3XSUfTcEZGU7xmAsfJubhHZwvXVLN9fSjR",
        net_leverage: 0.0,
        open_ms: 1715755284668,
        orders: [
          {
            leverage: -0.007,
            order_type: "SHORT",
            order_uuid: "1b685ea6-14ba-42a0-9e20-5586dcc2be7e",
            price: 1.095942356222606,
            price_sources: [],
            processed_ms: 1715755282172,
          },
          {
            leverage: 0.007,
            order_type: "FLAT",
            order_uuid: "9689f6ac-1708-4b62-b121-a10a7bea06eb",
            price: 1.09602,
            price_sources: [],
            processed_ms: 1715755405177,
          },
        ],
        position_type: "FLAT",
        position_uuid: "570722ca-4454-4ce6-b479-2fb7e7a58d5a",
        return_at_close: 0.9999990140741218,
        trade_pair: ["AUDNZD", "AUD/NZD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.9053667082923718,
        close_ms: 1715755831236,
        current_return: 1.000005222241064,
        initial_entry_price: 0.9063,
        is_closed_position: true,
        miner_hotkey: "5Dxqzduahnqw8q3XSUfTcEZGU7xmAsfJubhHZwvXVLN9fSjR",
        net_leverage: 0.0,
        open_ms: 1715753428559,
        orders: [
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "7c14516e-0026-44e5-834f-6daf327f3dba",
            price: 0.9063,
            price_sources: [],
            processed_ms: 1715753425669,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "48e76e76-3c15-4702-b9c2-1d99dd846a2f",
            price: 0.9051,
            price_sources: [],
            processed_ms: 1715754620414,
          },
          {
            leverage: -0.01,
            order_type: "SHORT",
            order_uuid: "a23a54db-d2d2-49a9-b0c2-c554ed0ce4c5",
            price: 0.906033291707628,
            price_sources: [],
            processed_ms: 1715755590376,
          },
          {
            leverage: 0.005,
            order_type: "FLAT",
            order_uuid: "b9a87f38-d56d-44f2-96f4-43bd48eb7855",
            price: 0.90584,
            price_sources: [],
            processed_ms: 1715755831236,
          },
        ],
        position_type: "FLAT",
        position_uuid: "45cd16ee-2414-4ca3-ac32-31005e992044",
        return_at_close: 1.0000038222337528,
        trade_pair: ["AUDCAD", "AUD/CAD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.6633666666666668,
        close_ms: 1715756188472,
        current_return: 1.0000107024419655,
        initial_entry_price: 0.6634,
        is_closed_position: true,
        miner_hotkey: "5Dxqzduahnqw8q3XSUfTcEZGU7xmAsfJubhHZwvXVLN9fSjR",
        net_leverage: 0.0,
        open_ms: 1715755729651,
        orders: [
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "c66e610f-42a1-47cb-b3dc-450c99318088",
            price: 0.6634,
            price_sources: [],
            processed_ms: 1715755726377,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "bfb03589-1049-452a-9e6d-5428223e813f",
            price: 0.6636,
            price_sources: [],
            processed_ms: 1715755836244,
          },
          {
            leverage: -0.005,
            order_type: "SHORT",
            order_uuid: "5e64b3cd-90bc-417a-8803-b9dbc74322ce",
            price: 0.6639,
            price_sources: [],
            processed_ms: 1715756068513,
          },
          {
            leverage: 0.005,
            order_type: "FLAT",
            order_uuid: "84a36b8d-069d-4cbc-a20f-8e6016b2abc7",
            price: 0.66384,
            price_sources: [],
            processed_ms: 1715756188472,
          },
        ],
        position_type: "FLAT",
        position_uuid: "8e549ee8-0b3f-4fd8-8211-5d9afa4791db",
        return_at_close: 1.000009302426982,
        trade_pair: ["CADCHF", "CAD/CHF", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 16.814686666666685,
        close_ms: 1715756262248,
        current_return: 1.0000045786121712,
        initial_entry_price: 16.8479,
        is_closed_position: true,
        miner_hotkey: "5Dxqzduahnqw8q3XSUfTcEZGU7xmAsfJubhHZwvXVLN9fSjR",
        net_leverage: 0.0,
        open_ms: 1715754749734,
        orders: [
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "421d5c13-2d71-41cd-b415-7bf7ab969243",
            price: 16.8479,
            price_sources: [],
            processed_ms: 1715754747423,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "57e6d74b-2471-44b1-8fff-2f9138d77b0d",
            price: 16.8342,
            price_sources: [],
            processed_ms: 1715754867744,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "1af01c51-ddb1-43fd-920f-0cdb21b67999",
            price: 16.8316,
            price_sources: [],
            processed_ms: 1715755500812,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "8d535170-22cb-402f-a9a5-f255337d38ea",
            price: 16.8314,
            price_sources: [],
            processed_ms: 1715755566220,
          },
          {
            leverage: -0.01,
            order_type: "SHORT",
            order_uuid: "ff7954f7-6930-4897-bbb7-0818561cb867",
            price: 16.84066,
            price_sources: [],
            processed_ms: 1715755767675,
          },
          {
            leverage: -0.005,
            order_type: "SHORT",
            order_uuid: "e2967a3c-8df2-4c88-841b-0785e0139d2b",
            price: 16.8278,
            price_sources: [],
            processed_ms: 1715755893868,
          },
          {
            leverage: -0.015,
            order_type: "SHORT",
            order_uuid: "8b371541-7d0d-4eb1-b5f8-88c80a82843f",
            price: 16.83821,
            price_sources: [],
            processed_ms: 1715756012128,
          },
          {
            leverage: -0.007,
            order_type: "SHORT",
            order_uuid: "9878c573-1469-45ef-bc71-b3820454acba",
            price: 16.84117,
            price_sources: [],
            processed_ms: 1715756140709,
          },
          {
            leverage: 0.003,
            order_type: "FLAT",
            order_uuid: "5e84d603-2a2d-4b55-8836-1859271a25b6",
            price: 16.8404,
            price_sources: [],
            processed_ms: 1715756262248,
          },
        ],
        position_type: "FLAT",
        position_uuid: "d7a5af88-4b1c-430b-9ddf-0f6e9e6128e3",
        return_at_close: 1.0000017785993511,
        trade_pair: ["USDMXN", "USD/MXN", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 1.0959599999999994,
        close_ms: 1715758396481,
        current_return: 0.9999997718582601,
        initial_entry_price: 1.09581,
        is_closed_position: true,
        miner_hotkey: "5Dxqzduahnqw8q3XSUfTcEZGU7xmAsfJubhHZwvXVLN9fSjR",
        net_leverage: 0.0,
        open_ms: 1715757247124,
        orders: [
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "882a56c6-7e0b-4cd0-8db7-6fe9d23f5dec",
            price: 1.09581,
            price_sources: [],
            processed_ms: 1715757244246,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "a23e4bc6-6db8-478d-8c8c-78c51ccafaf2",
            price: 1.0958,
            price_sources: [],
            processed_ms: 1715757596036,
          },
          {
            leverage: -0.01,
            order_type: "SHORT",
            order_uuid: "d5e34f97-80c8-40c6-a75b-a881562bdca0",
            price: 1.09593,
            price_sources: [],
            processed_ms: 1715757720321,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "abafe634-5f69-4455-bd9e-eb0e040c3549",
            price: 1.09596,
            price_sources: [],
            processed_ms: 1715758034684,
          },
          {
            leverage: -0.01,
            order_type: "SHORT",
            order_uuid: "ddc157af-c4c8-4e5a-8376-5271a65df9c8",
            price: 1.09578,
            price_sources: [],
            processed_ms: 1715758156496,
          },
          {
            leverage: -0.005,
            order_type: "SHORT",
            order_uuid: "f1bef1eb-d2dd-4a27-af13-5e1a3f467c65",
            price: 1.09576,
            price_sources: [],
            processed_ms: 1715758276283,
          },
          {
            leverage: 0.005,
            order_type: "FLAT",
            order_uuid: "4f837bf1-402b-483e-9152-f4da951854db",
            price: 1.09591,
            price_sources: [],
            processed_ms: 1715758396481,
          },
        ],
        position_type: "FLAT",
        position_uuid: "8bccebb3-ec08-4813-8557-0121db94f02a",
        return_at_close: 0.9999976718587391,
        trade_pair: ["AUDNZD", "AUD/NZD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.6634900000000001,
        close_ms: 1715758593324,
        current_return: 1.0000045212044488,
        initial_entry_price: 0.66354,
        is_closed_position: true,
        miner_hotkey: "5Dxqzduahnqw8q3XSUfTcEZGU7xmAsfJubhHZwvXVLN9fSjR",
        net_leverage: 0.0,
        open_ms: 1715758029167,
        orders: [
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "f774ba62-2511-4b27-b5b8-abb667a45033",
            price: 0.66354,
            price_sources: [],
            processed_ms: 1715758026279,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "1cc68c98-3c82-4c28-839a-6390b220cca8",
            price: 0.66355,
            price_sources: [],
            processed_ms: 1715758096148,
          },
          {
            leverage: -0.005,
            order_type: "SHORT",
            order_uuid: "cbe26c1e-9572-42f6-a46b-102295627883",
            price: 0.66371,
            price_sources: [],
            processed_ms: 1715758351716,
          },
          {
            leverage: 0.007,
            order_type: "FLAT",
            order_uuid: "3eda0a4e-206b-4883-9a72-bf7c24339ad3",
            price: 0.66369,
            price_sources: [],
            processed_ms: 1715758593324,
          },
        ],
        position_type: "FLAT",
        position_uuid: "915b5986-6c9e-4a14-ba6a-9bd60af4db3c",
        return_at_close: 1.000003121198119,
        trade_pair: ["CADCHF", "CAD/CHF", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.9056218607369619,
        close_ms: 1715758613385,
        current_return: 1.0000017434602193,
        initial_entry_price: 0.905780930368481,
        is_closed_position: true,
        miner_hotkey: "5Dxqzduahnqw8q3XSUfTcEZGU7xmAsfJubhHZwvXVLN9fSjR",
        net_leverage: 0.0,
        open_ms: 1715757912833,
        orders: [
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "7c815a3b-6338-497b-86e8-6bf16e2b700b",
            price: 0.905780930368481,
            price_sources: [],
            processed_ms: 1715757911037,
          },
          {
            leverage: -0.005,
            order_type: "SHORT",
            order_uuid: "2ed8eb99-5c5c-4630-aaf5-db06a0f14aed",
            price: 0.90594,
            price_sources: [],
            processed_ms: 1715758104302,
          },
          {
            leverage: 0.005,
            order_type: "FLAT",
            order_uuid: "6477d12c-a6c2-4379-a2f3-ea3d6feb75a0",
            price: 0.905937699340846,
            price_sources: [],
            processed_ms: 1715758613385,
          },
        ],
        position_type: "FLAT",
        position_uuid: "05a80db1-25e2-41d5-86cc-078cb443b341",
        return_at_close: 1.000001043458999,
        trade_pair: ["AUDCAD", "AUD/CAD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 16.853900000000003,
        close_ms: 1715759182922,
        current_return: 1.0000010975743607,
        initial_entry_price: 16.85535,
        is_closed_position: true,
        miner_hotkey: "5Dxqzduahnqw8q3XSUfTcEZGU7xmAsfJubhHZwvXVLN9fSjR",
        net_leverage: 0.0,
        open_ms: 1715758258002,
        orders: [
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "7c6a7693-79f5-47e0-ae6f-9de0a21a56b0",
            price: 16.85535,
            price_sources: [],
            processed_ms: 1715758255493,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "fbbb5756-a600-4daf-ab05-120e61bcdcfd",
            price: 16.8517,
            price_sources: [],
            processed_ms: 1715758802237,
          },
          {
            leverage: -0.01,
            order_type: "SHORT",
            order_uuid: "738884a3-cde7-4fbb-b8b1-a9bfd8d2bd82",
            price: 16.85715,
            price_sources: [],
            processed_ms: 1715758926887,
          },
          {
            leverage: -0.005,
            order_type: "SHORT",
            order_uuid: "ae75b308-e9bd-4636-9a61-2c1158ab3cb1",
            price: 16.8459,
            price_sources: [],
            processed_ms: 1715759059300,
          },
          {
            leverage: 0.005,
            order_type: "FLAT",
            order_uuid: "deadb0a9-c9af-47a1-903e-a1971ffbb852",
            price: 16.8576,
            price_sources: [],
            processed_ms: 1715759182922,
          },
        ],
        position_type: "FLAT",
        position_uuid: "01c72aed-8093-4d32-9dc5-e8e154c4e3ea",
        return_at_close: 0.9999996975728241,
        trade_pair: ["USDMXN", "USD/MXN", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 1.09626,
        close_ms: 1715760756356,
        current_return: 0.999999836898888,
        initial_entry_price: 1.09626,
        is_closed_position: true,
        miner_hotkey: "5Dxqzduahnqw8q3XSUfTcEZGU7xmAsfJubhHZwvXVLN9fSjR",
        net_leverage: 0.0,
        open_ms: 1715760543304,
        orders: [
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "ca87c024-b3d1-4026-9d97-86dff43bcd82",
            price: 1.09626,
            price_sources: [],
            processed_ms: 1715760540347,
          },
          {
            leverage: 0.005,
            order_type: "FLAT",
            order_uuid: "54be2ef3-bb24-4512-abc7-ec1cb253afcb",
            price: 1.096242119877505,
            price_sources: [],
            processed_ms: 1715760756356,
          },
        ],
        position_type: "FLAT",
        position_uuid: "e49abf5b-cf8f-49eb-916c-8fb4ce76f5d6",
        return_at_close: 0.9999991368990022,
        trade_pair: ["AUDNZD", "AUD/NZD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.9053700000000001,
        close_ms: 1715761582211,
        current_return: 1.0000018116842015,
        initial_entry_price: 0.90558,
        is_closed_position: true,
        miner_hotkey: "5Dxqzduahnqw8q3XSUfTcEZGU7xmAsfJubhHZwvXVLN9fSjR",
        net_leverage: 0.0,
        open_ms: 1715760755052,
        orders: [
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "5d1c4895-e7f2-4d69-88d8-7a331d95ef3e",
            price: 0.90558,
            price_sources: [],
            processed_ms: 1715760750331,
          },
          {
            leverage: -0.005,
            order_type: "SHORT",
            order_uuid: "a9b11f6d-3ab4-4755-9a6d-94eb23c7fb0f",
            price: 0.90579,
            price_sources: [],
            processed_ms: 1715761111288,
          },
          {
            leverage: 0.005,
            order_type: "FLAT",
            order_uuid: "403c0f61-faad-4198-91e0-eb730e1c12cd",
            price: 0.905698124995846,
            price_sources: [],
            processed_ms: 1715761582211,
          },
        ],
        position_type: "FLAT",
        position_uuid: "8bb461d6-13ad-40ee-ba21-40e6c051bde0",
        return_at_close: 1.0000011116829335,
        trade_pair: ["AUDCAD", "AUD/CAD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 16.83043,
        close_ms: 1715762454260,
        current_return: 1.0000092956154,
        initial_entry_price: 16.85741,
        is_closed_position: true,
        miner_hotkey: "5Dxqzduahnqw8q3XSUfTcEZGU7xmAsfJubhHZwvXVLN9fSjR",
        net_leverage: 0.0,
        open_ms: 1715761027288,
        orders: [
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "66319ac7-3762-47b3-bee6-2036a8363c84",
            price: 16.85741,
            price_sources: [],
            processed_ms: 1715761021732,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "00fdee85-730e-4b67-b0c7-8e7a05f96cd2",
            price: 16.8444,
            price_sources: [],
            processed_ms: 1715761191908,
          },
          {
            leverage: -0.01,
            order_type: "SHORT",
            order_uuid: "e33cd79a-06d4-4f1b-bd8d-0b8d7bba136b",
            price: 16.85755,
            price_sources: [],
            processed_ms: 1715761285200,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "395af6bd-bd7b-4a7d-9d52-9a31ee0c36f9",
            price: 16.8306,
            price_sources: [],
            processed_ms: 1715761839448,
          },
          {
            leverage: -0.01,
            order_type: "SHORT",
            order_uuid: "0bfad66a-84b3-4e12-9118-47fca10f249a",
            price: 16.84443,
            price_sources: [],
            processed_ms: 1715762200616,
          },
          {
            leverage: 0.005,
            order_type: "FLAT",
            order_uuid: "000cb8dd-61db-456e-9840-5a08bc5cf83f",
            price: 16.8461,
            price_sources: [],
            processed_ms: 1715762454260,
          },
        ],
        position_type: "FLAT",
        position_uuid: "fa73efe1-fd9e-4f96-a6aa-1ed0a6fc632c",
        return_at_close: 1.0000071955958794,
        trade_pair: ["USDMXN", "USD/MXN", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.6614442857142856,
        close_ms: 1715763237613,
        current_return: 1.0000292885137172,
        initial_entry_price: 0.6634,
        is_closed_position: true,
        miner_hotkey: "5Dxqzduahnqw8q3XSUfTcEZGU7xmAsfJubhHZwvXVLN9fSjR",
        net_leverage: 0.0,
        open_ms: 1715760678308,
        orders: [
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "bc5143c0-6295-4c36-abc7-f281a827d15e",
            price: 0.6634,
            price_sources: [],
            processed_ms: 1715760675976,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "d2345c6f-23c2-46f0-b2f6-497bb08bab2b",
            price: 0.66392,
            price_sources: [],
            processed_ms: 1715760749251,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "e1477c5b-630d-44ab-9e44-0ccdbfcf8f69",
            price: 0.664,
            price_sources: [],
            processed_ms: 1715760832260,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "08595463-e1e3-44d3-bb4f-1af68c657e3a",
            price: 0.66388,
            price_sources: [],
            processed_ms: 1715761105470,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "489c16f8-afe7-4e75-bace-a0cbec8538f0",
            price: 0.6634,
            price_sources: [],
            processed_ms: 1715761680552,
          },
          {
            leverage: -0.01,
            order_type: "SHORT",
            order_uuid: "28a00f8d-17cf-4f53-b43a-0cb999dd9911",
            price: 0.664,
            price_sources: [],
            processed_ms: 1715762138437,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "e3790233-511f-4428-8d58-c7c92f8fe351",
            price: 0.6639,
            price_sources: [],
            processed_ms: 1715762442319,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "a3e6d1d3-00fe-4e2a-a27d-2be1ee85d1c7",
            price: 0.6634,
            price_sources: [],
            processed_ms: 1715762752275,
          },
          {
            leverage: -0.03,
            order_type: "SHORT",
            order_uuid: "037ac679-8588-4fbe-b942-b198c2e74fcd",
            price: 0.66392,
            price_sources: [],
            processed_ms: 1715762872482,
          },
          {
            leverage: -0.015,
            order_type: "SHORT",
            order_uuid: "f1a975d5-e5ed-44ee-a19f-e5aeb435bc66",
            price: 0.66391,
            price_sources: [],
            processed_ms: 1715762993215,
          },
          {
            leverage: -0.008,
            order_type: "SHORT",
            order_uuid: "2ff75e1b-18c3-4603-808a-c5ac440f0c3f",
            price: 0.66408,
            price_sources: [],
            processed_ms: 1715763115854,
          },
          {
            leverage: -0.008,
            order_type: "SHORT",
            order_uuid: "b5d0fe6b-8e17-4df1-b027-f8a7dd3b7615",
            price: 0.66422,
            price_sources: [],
            processed_ms: 1715763237613,
          },
        ],
        position_type: "FLAT",
        position_uuid: "74114207-12e2-44e7-bdc2-08480fbfaeb1",
        return_at_close: 1.0000243883702036,
        trade_pair: ["CADCHF", "CAD/CHF", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 1.0954175000000004,
        close_ms: 1715763718411,
        current_return: 1.000003734647469,
        initial_entry_price: 1.09589,
        is_closed_position: true,
        miner_hotkey: "5Dxqzduahnqw8q3XSUfTcEZGU7xmAsfJubhHZwvXVLN9fSjR",
        net_leverage: 0.0,
        open_ms: 1715762559175,
        orders: [
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "d8d58a62-99b3-40af-9ad9-54bde4e24380",
            price: 1.09589,
            price_sources: [],
            processed_ms: 1715762557239,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "f280be51-5f18-47f6-96e8-5a9b338b07d6",
            price: 1.0957,
            price_sources: [],
            processed_ms: 1715762637677,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "778479da-1527-474e-9fd1-5abc2c06c9c7",
            price: 1.09591,
            price_sources: [],
            processed_ms: 1715762719347,
          },
          {
            leverage: -0.015,
            order_type: "SHORT",
            order_uuid: "cabde41e-7d8a-4375-a175-8b624b77d415",
            price: 1.09594,
            price_sources: [],
            processed_ms: 1715763131374,
          },
          {
            leverage: -0.007,
            order_type: "SHORT",
            order_uuid: "77cd054b-0093-4de7-968b-8f6c31b0f025",
            price: 1.09608,
            price_sources: [],
            processed_ms: 1715763477892,
          },
          {
            leverage: 0.007,
            order_type: "FLAT",
            order_uuid: "62c6e731-b082-4513-9b20-f11e6ae57c7d",
            price: 1.095929095351858,
            price_sources: [],
            processed_ms: 1715763718411,
          },
        ],
        position_type: "FLAT",
        position_uuid: "7243e36c-3937-41d1-b8d9-2df151c310e1",
        return_at_close: 1.0000016346396263,
        trade_pair: ["AUDNZD", "AUD/NZD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 16.85178,
        close_ms: 1715765145289,
        current_return: 0.9999972821862142,
        initial_entry_price: 16.85178,
        is_closed_position: true,
        miner_hotkey: "5Dxqzduahnqw8q3XSUfTcEZGU7xmAsfJubhHZwvXVLN9fSjR",
        net_leverage: 0.0,
        open_ms: 1715764904282,
        orders: [
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "fdde0a42-6d48-4a0c-af52-d74cf771ae61",
            price: 16.85178,
            price_sources: [],
            processed_ms: 1715764899131,
          },
          {
            leverage: 0.005,
            order_type: "FLAT",
            order_uuid: "a60c3ef5-aee2-42fc-92b9-af181440c304",
            price: 16.8472,
            price_sources: [],
            processed_ms: 1715765145289,
          },
        ],
        position_type: "FLAT",
        position_uuid: "bf5991e9-952c-4d33-b748-71987e0f94c5",
        return_at_close: 0.9999965821881167,
        trade_pair: ["USDMXN", "USD/MXN", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 1.095816616627255,
        close_ms: 1715765815099,
        current_return: 1.0000000308753554,
        initial_entry_price: 1.095816616627255,
        is_closed_position: true,
        miner_hotkey: "5Dxqzduahnqw8q3XSUfTcEZGU7xmAsfJubhHZwvXVLN9fSjR",
        net_leverage: 0.0,
        open_ms: 1715765582926,
        orders: [
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "371ad9e9-ff5a-42a5-a06d-e23877faf7eb",
            price: 1.095816616627255,
            price_sources: [],
            processed_ms: 1715765580039,
          },
          {
            leverage: 0.005,
            order_type: "FLAT",
            order_uuid: "074bd27a-9c16-4ea8-9ca0-2b4bbbb71150",
            price: 1.09582,
            price_sources: [],
            processed_ms: 1715765815099,
          },
        ],
        position_type: "FLAT",
        position_uuid: "63c364d4-02d5-4633-a74b-9590850ae746",
        return_at_close: 0.9999993308753338,
        trade_pair: ["AUDNZD", "AUD/NZD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.6637,
        close_ms: 1715766425156,
        current_return: 1.000001505706628,
        initial_entry_price: 0.66414,
        is_closed_position: true,
        miner_hotkey: "5Dxqzduahnqw8q3XSUfTcEZGU7xmAsfJubhHZwvXVLN9fSjR",
        net_leverage: 0.0,
        open_ms: 1715765256506,
        orders: [
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "9a715560-d414-4e8c-9e41-dbade36256e8",
            price: 0.66414,
            price_sources: [],
            processed_ms: 1715765252339,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "915c2866-3e46-4645-a101-38ac63546679",
            price: 0.6637,
            price_sources: [],
            processed_ms: 1715766053324,
          },
          {
            leverage: -0.01,
            order_type: "SHORT",
            order_uuid: "c364019f-dbaf-40f9-ab40-6d8392e6511e",
            price: 0.66414,
            price_sources: [],
            processed_ms: 1715766176628,
          },
          {
            leverage: 0.005,
            order_type: "FLAT",
            order_uuid: "99945657-ae13-44c9-96bb-2d6116f8edb5",
            price: 0.6638,
            price_sources: [],
            processed_ms: 1715766425156,
          },
        ],
        position_type: "FLAT",
        position_uuid: "dc3e5163-f5d1-455f-b2db-428196f20140",
        return_at_close: 1.00000010570452,
        trade_pair: ["CADCHF", "CAD/CHF", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.90528,
        close_ms: 1715766781286,
        current_return: 0.9999990059532357,
        initial_entry_price: 0.90539,
        is_closed_position: true,
        miner_hotkey: "5Dxqzduahnqw8q3XSUfTcEZGU7xmAsfJubhHZwvXVLN9fSjR",
        net_leverage: 0.0,
        open_ms: 1715764263455,
        orders: [
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "700a4c83-2d37-4973-a667-52bae15c7be9",
            price: 0.90539,
            price_sources: [],
            processed_ms: 1715764259441,
          },
          {
            leverage: -0.005,
            order_type: "SHORT",
            order_uuid: "10067c24-15f6-4805-b50c-e66e3ded4b4c",
            price: 0.9055,
            price_sources: [],
            processed_ms: 1715766524134,
          },
          {
            leverage: 0.005,
            order_type: "FLAT",
            order_uuid: "70203eda-2411-49c6-9701-d99bffcd2303",
            price: 0.9051,
            price_sources: [],
            processed_ms: 1715766781286,
          },
        ],
        position_type: "FLAT",
        position_uuid: "0cbd3b95-a88e-470f-9400-26e8b745bbef",
        return_at_close: 0.9999983059539316,
        trade_pair: ["AUDCAD", "AUD/CAD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 16.842,
        close_ms: 1715767331184,
        current_return: 0.9999994656216601,
        initial_entry_price: 16.842,
        is_closed_position: true,
        miner_hotkey: "5Dxqzduahnqw8q3XSUfTcEZGU7xmAsfJubhHZwvXVLN9fSjR",
        net_leverage: 0.0,
        open_ms: 1715767092162,
        orders: [
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "f0d5e2e6-226c-4707-b855-a87efd470ba4",
            price: 16.842,
            price_sources: [],
            processed_ms: 1715767086093,
          },
          {
            leverage: 0.005,
            order_type: "FLAT",
            order_uuid: "65bc54dd-1a75-4df1-8998-f2b6191ea5ef",
            price: 16.8411,
            price_sources: [],
            processed_ms: 1715767331184,
          },
        ],
        position_type: "FLAT",
        position_uuid: "bd14c826-8b37-408d-a78d-6d8aa8b142f0",
        return_at_close: 0.9999987656220342,
        trade_pair: ["USDMXN", "USD/MXN", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 1.0953163432216055,
        close_ms: 1715768134368,
        current_return: 1.0000026178160397,
        initial_entry_price: 1.095678171610803,
        is_closed_position: true,
        miner_hotkey: "5Dxqzduahnqw8q3XSUfTcEZGU7xmAsfJubhHZwvXVLN9fSjR",
        net_leverage: 0.0,
        open_ms: 1715767621258,
        orders: [
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "e38b8707-af8c-4256-942e-d2e701a2da1d",
            price: 1.095678171610803,
            price_sources: [],
            processed_ms: 1715767617164,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "92856c8d-271c-4661-aa5e-f69dc12e7bf3",
            price: 1.09571,
            price_sources: [],
            processed_ms: 1715767772255,
          },
          {
            leverage: -0.01,
            order_type: "SHORT",
            order_uuid: "d9ef2a1f-5407-4d4d-96bd-c57a6d1f299e",
            price: 1.09582,
            price_sources: [],
            processed_ms: 1715767888140,
          },
          {
            leverage: -0.005,
            order_type: "SHORT",
            order_uuid: "4e6938a2-6b0c-4b9e-8110-65145159b79c",
            price: 1.09582,
            price_sources: [],
            processed_ms: 1715768012095,
          },
          {
            leverage: 0.005,
            order_type: "FLAT",
            order_uuid: "8aaa396b-b9c9-490b-bab4-3e60e72b639e",
            price: 1.09589,
            price_sources: [],
            processed_ms: 1715768134368,
          },
        ],
        position_type: "FLAT",
        position_uuid: "1872cf86-a5d9-412e-8df2-bdc64a7a75e9",
        return_at_close: 1.0000012178123747,
        trade_pair: ["AUDNZD", "AUD/NZD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.6638000000000003,
        close_ms: 1715768796468,
        current_return: 1.0000015825169555,
        initial_entry_price: 0.6635,
        is_closed_position: true,
        miner_hotkey: "5Dxqzduahnqw8q3XSUfTcEZGU7xmAsfJubhHZwvXVLN9fSjR",
        net_leverage: 0.0,
        open_ms: 1715768357342,
        orders: [
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "2ee80e6b-d129-4fea-b82c-e5981a201301",
            price: 0.6635,
            price_sources: [],
            processed_ms: 1715768354384,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "6b34fad1-c9b1-4100-bf77-44dc9a1760a6",
            price: 0.66389,
            price_sources: [],
            processed_ms: 1715768420511,
          },
          {
            leverage: -0.01,
            order_type: "SHORT",
            order_uuid: "2d809a2d-3d7e-4a61-b3b6-b1b794772e6c",
            price: 0.6635,
            price_sources: [],
            processed_ms: 1715768548258,
          },
          {
            leverage: -0.005,
            order_type: "SHORT",
            order_uuid: "df41bde4-734e-46a9-b687-d905a87da247",
            price: 0.66398,
            price_sources: [],
            processed_ms: 1715768675254,
          },
          {
            leverage: 0.005,
            order_type: "FLAT",
            order_uuid: "9807e7b4-8162-4669-9f5a-e86c5e937e4b",
            price: 0.66401,
            price_sources: [],
            processed_ms: 1715768796468,
          },
        ],
        position_type: "FLAT",
        position_uuid: "cc42ce2c-cc98-46cd-bb57-44d3145acd27",
        return_at_close: 1.00000018251474,
        trade_pair: ["CADCHF", "CAD/CHF", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 16.820649999999997,
        close_ms: 1715770042873,
        current_return: 1.0000083991683992,
        initial_entry_price: 16.835,
        is_closed_position: true,
        miner_hotkey: "5Dxqzduahnqw8q3XSUfTcEZGU7xmAsfJubhHZwvXVLN9fSjR",
        net_leverage: 0.0,
        open_ms: 1715769141927,
        orders: [
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "3341995d-9b31-495a-a336-b13f81e92b5f",
            price: 16.835,
            price_sources: [],
            processed_ms: 1715769139297,
          },
          {
            leverage: -0.005,
            order_type: "SHORT",
            order_uuid: "427bca82-3870-449e-9641-79717e252839",
            price: 16.84935,
            price_sources: [],
            processed_ms: 1715769718340,
          },
          {
            leverage: 0.005,
            order_type: "FLAT",
            order_uuid: "eaf1552b-7428-41db-90bc-1fb341bd0f83",
            price: 16.84893,
            price_sources: [],
            processed_ms: 1715770042873,
          },
        ],
        position_type: "FLAT",
        position_uuid: "fb62b553-997f-4c9b-aa60-703a65bfb180",
        return_at_close: 1.0000076991625197,
        trade_pair: ["USDMXN", "USD/MXN", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.9046149262906962,
        close_ms: 1715770583434,
        current_return: 1.0000022384287932,
        initial_entry_price: 0.904817054150379,
        is_closed_position: true,
        miner_hotkey: "5Dxqzduahnqw8q3XSUfTcEZGU7xmAsfJubhHZwvXVLN9fSjR",
        net_leverage: 0.0,
        open_ms: 1715769809635,
        orders: [
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "e8c7bd7a-adac-4660-b235-6e5860151594",
            price: 0.904817054150379,
            price_sources: [],
            processed_ms: 1715769808192,
          },
          {
            leverage: -0.005,
            order_type: "SHORT",
            order_uuid: "157e1c18-7a91-44fa-b47d-39fc5bdb0fc3",
            price: 0.905019182010062,
            price_sources: [],
            processed_ms: 1715770401382,
          },
          {
            leverage: 0.005,
            order_type: "FLAT",
            order_uuid: "7f207e2b-cf62-4617-a304-c958b734d87b",
            price: 0.90502,
            price_sources: [],
            processed_ms: 1715770583434,
          },
        ],
        position_type: "FLAT",
        position_uuid: "6399b0cb-efda-48db-b752-a27d567562b5",
        return_at_close: 1.0000015384272263,
        trade_pair: ["AUDCAD", "AUD/CAD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.90499,
        close_ms: 1715771094631,
        current_return: 1.00000072251392,
        initial_entry_price: 0.90499,
        is_closed_position: true,
        miner_hotkey: "5Dxqzduahnqw8q3XSUfTcEZGU7xmAsfJubhHZwvXVLN9fSjR",
        net_leverage: 0.0,
        open_ms: 1715770708323,
        orders: [
          {
            leverage: -0.005,
            order_type: "SHORT",
            order_uuid: "cd0c9f85-d104-4d54-8bbc-3adb507ab0dc",
            price: 0.90499,
            price_sources: [],
            processed_ms: 1715770706596,
          },
          {
            leverage: 0.005,
            order_type: "FLAT",
            order_uuid: "63180f1a-e94c-4b9d-aaea-352cfa77924b",
            price: 0.904859226425486,
            price_sources: [],
            processed_ms: 1715771094631,
          },
        ],
        position_type: "FLAT",
        position_uuid: "bdacbd19-59ca-452e-a611-7f430b3953cf",
        return_at_close: 1.0000003725136672,
        trade_pair: ["AUDCAD", "AUD/CAD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.6636066666666666,
        close_ms: 1715771217249,
        current_return: 0.9999975892722616,
        initial_entry_price: 0.6637,
        is_closed_position: true,
        miner_hotkey: "5Dxqzduahnqw8q3XSUfTcEZGU7xmAsfJubhHZwvXVLN9fSjR",
        net_leverage: 0.0,
        open_ms: 1715770902475,
        orders: [
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "add0838b-b6bf-4870-bf36-d7a0ae376a2c",
            price: 0.6637,
            price_sources: [],
            processed_ms: 1715770900255,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "d2949b83-a4d6-4f15-9330-4f25512e3ca3",
            price: 0.6637,
            price_sources: [],
            processed_ms: 1715770972293,
          },
          {
            leverage: -0.005,
            order_type: "SHORT",
            order_uuid: "3f8dd687-5c6d-4d96-b113-cf34718e7223",
            price: 0.66398,
            price_sources: [],
            processed_ms: 1715771096437,
          },
          {
            leverage: 0.005,
            order_type: "FLAT",
            order_uuid: "e50753a4-bc5d-4958-8926-cc366c5b199f",
            price: 0.6635,
            price_sources: [],
            processed_ms: 1715771217249,
          },
        ],
        position_type: "FLAT",
        position_uuid: "3c658adf-cf0f-4ad3-83e6-9db9145fb0a0",
        return_at_close: 0.9999961892756365,
        trade_pair: ["CADCHF", "CAD/CHF", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 16.8508,
        close_ms: 1715772996313,
        current_return: 0.999998233598442,
        initial_entry_price: 16.84215,
        is_closed_position: true,
        miner_hotkey: "5Dxqzduahnqw8q3XSUfTcEZGU7xmAsfJubhHZwvXVLN9fSjR",
        net_leverage: 0.0,
        open_ms: 1715772185175,
        orders: [
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "842c99b4-0e4f-472d-881c-70d6312239e4",
            price: 16.84215,
            price_sources: [],
            processed_ms: 1715772182957,
          },
          {
            leverage: -0.005,
            order_type: "SHORT",
            order_uuid: "7106b0a7-3458-4398-8039-c6e63ed003c7",
            price: 16.8335,
            price_sources: [],
            processed_ms: 1715772387588,
          },
          {
            leverage: -0.007,
            order_type: "SHORT",
            order_uuid: "d8a030b0-d9a5-4bf8-bc31-c29cc824f0f8",
            price: 16.84485,
            price_sources: [],
            processed_ms: 1715772996313,
          },
        ],
        position_type: "FLAT",
        position_uuid: "c7a2567f-768d-4f31-99ad-d8ae9d6fc9e8",
        return_at_close: 0.9999975335996785,
        trade_pair: ["USDMXN", "USD/MXN", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 1.0976416498820583,
        close_ms: 1715773365346,
        current_return: 0.9999741890630981,
        initial_entry_price: 1.09546,
        is_closed_position: true,
        miner_hotkey: "5Dxqzduahnqw8q3XSUfTcEZGU7xmAsfJubhHZwvXVLN9fSjR",
        net_leverage: 0.0,
        open_ms: 1715770063199,
        orders: [
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "2451c157-57d0-435f-ba14-d2022c77892e",
            price: 1.09546,
            price_sources: [],
            processed_ms: 1715770059333,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "b9b99aa9-6f54-4452-b4ab-2d3c05665443",
            price: 1.095451384890535,
            price_sources: [],
            processed_ms: 1715770295239,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "3a3d3dcc-68dd-48da-a452-dca20d6417dc",
            price: 1.095403100003319,
            price_sources: [],
            processed_ms: 1715770982168,
          },
          {
            leverage: -0.015,
            order_type: "SHORT",
            order_uuid: "e08ff61c-7967-4fc9-b099-deef7b06c244",
            price: 1.09541,
            price_sources: [],
            processed_ms: 1715771103182,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "aed89df4-7ddb-4f47-a497-ac95ae601ec4",
            price: 1.09546,
            price_sources: [],
            processed_ms: 1715771534112,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "b16ad4c3-950f-4836-8873-6c73803f084c",
            price: 1.0946,
            price_sources: [],
            processed_ms: 1715771868838,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "2b74b672-ba11-4207-b91f-6165be368fa8",
            price: 1.0952,
            price_sources: [],
            processed_ms: 1715772222658,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "b8460b4c-3a60-43e3-93c3-2850ee0b0df5",
            price: 1.09516,
            price_sources: [],
            processed_ms: 1715772540016,
          },
          {
            leverage: -0.018,
            order_type: "SHORT",
            order_uuid: "f19cded0-cf52-42b5-ba5b-966cbfc85054",
            price: 1.09524,
            price_sources: [],
            processed_ms: 1715772711482,
          },
          {
            leverage: -0.019,
            order_type: "SHORT",
            order_uuid: "d1f22e6c-4b57-4735-8e63-9de4dbf255c3",
            price: 1.0943,
            price_sources: [],
            processed_ms: 1715772979213,
          },
          {
            leverage: -0.009,
            order_type: "SHORT",
            order_uuid: "316a03e6-66b9-4089-b994-cbbdc01e55b0",
            price: 1.0946,
            price_sources: [],
            processed_ms: 1715773239498,
          },
          {
            leverage: 0.009,
            order_type: "FLAT",
            order_uuid: "03ce57ed-3cec-4a3a-b626-1e21499f4dad",
            price: 1.0945,
            price_sources: [],
            processed_ms: 1715773365346,
          },
        ],
        position_type: "FLAT",
        position_uuid: "fc2917e8-5ce9-46ee-8120-843e514a8c96",
        return_at_close: 0.9999692891895717,
        trade_pair: ["AUDNZD", "AUD/NZD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 1.09543,
        close_ms: 1715773608104,
        current_return: 0.9999997705527884,
        initial_entry_price: 1.09543,
        is_closed_position: true,
        miner_hotkey: "5Dxqzduahnqw8q3XSUfTcEZGU7xmAsfJubhHZwvXVLN9fSjR",
        net_leverage: 0.0,
        open_ms: 1715773488496,
        orders: [
          {
            leverage: -0.009,
            order_type: "SHORT",
            order_uuid: "c5ac718a-33c3-4d83-9d93-e6885d26042b",
            price: 1.09543,
            price_sources: [],
            processed_ms: 1715773486290,
          },
          {
            leverage: 0.009,
            order_type: "FLAT",
            order_uuid: "eb86ec20-ff5b-4c50-aac1-7c653d8f6c3b",
            price: 1.095457927039881,
            price_sources: [],
            processed_ms: 1715773608104,
          },
        ],
        position_type: "FLAT",
        position_uuid: "58197c0b-100b-492c-9ea8-ff9705950ab8",
        return_at_close: 0.999999140552933,
        trade_pair: ["AUDNZD", "AUD/NZD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 114.034,
        close_ms: 1715775197775,
        current_return: 1.0000027617770062,
        initial_entry_price: 114.057,
        is_closed_position: true,
        miner_hotkey: "5Dxqzduahnqw8q3XSUfTcEZGU7xmAsfJubhHZwvXVLN9fSjR",
        net_leverage: 0.0,
        open_ms: 1715774787216,
        orders: [
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "62f6eca4-b0e0-485c-9b8e-5213d6422a4b",
            price: 114.057,
            price_sources: [],
            processed_ms: 1715774785279,
          },
          {
            leverage: -0.005,
            order_type: "SHORT",
            order_uuid: "0ea581ee-6ce4-4eff-adc3-65fe20e2f260",
            price: 114.08,
            price_sources: [],
            processed_ms: 1715775077418,
          },
          {
            leverage: 0.005,
            order_type: "FLAT",
            order_uuid: "bf3162d0-a199-4faa-8b52-872f14468929",
            price: 114.097,
            price_sources: [],
            processed_ms: 1715775197775,
          },
        ],
        position_type: "FLAT",
        position_uuid: "3acbdf30-f577-4d3a-b28d-acc467443ad3",
        return_at_close: 1.000002061775073,
        trade_pair: ["CADJPY", "CAD/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 16.78787,
        close_ms: 1715775931230,
        current_return: 1.000029244306725,
        initial_entry_price: 16.87166,
        is_closed_position: true,
        miner_hotkey: "5Dxqzduahnqw8q3XSUfTcEZGU7xmAsfJubhHZwvXVLN9fSjR",
        net_leverage: 0.0,
        open_ms: 1715775095108,
        orders: [
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "fd0eb3dd-7f22-4f2a-9916-a93881aec826",
            price: 16.87166,
            price_sources: [],
            processed_ms: 1715775093185,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "ab96ac32-8e0c-4643-a2d8-067addfc7b5f",
            price: 16.8593,
            price_sources: [],
            processed_ms: 1715775249371,
          },
          {
            leverage: -0.015,
            order_type: "SHORT",
            order_uuid: "81bceb20-a730-424f-a945-4874dad9f0f0",
            price: 16.89135,
            price_sources: [],
            processed_ms: 1715775808145,
          },
          {
            leverage: -0.007,
            order_type: "SHORT",
            order_uuid: "873f7ad5-0baf-4137-84f9-53482b717cf9",
            price: 16.88655,
            price_sources: [],
            processed_ms: 1715775931230,
          },
        ],
        position_type: "FLAT",
        position_uuid: "8b029a24-15db-4ed9-92cf-03f79111c732",
        return_at_close: 1.0000278442657828,
        trade_pair: ["USDMXN", "USD/MXN", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 1.0944162500000003,
        close_ms: 1715776188864,
        current_return: 1.0000053601921268,
        initial_entry_price: 1.09511,
        is_closed_position: true,
        miner_hotkey: "5Dxqzduahnqw8q3XSUfTcEZGU7xmAsfJubhHZwvXVLN9fSjR",
        net_leverage: 0.0,
        open_ms: 1715775422298,
        orders: [
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "bec5476a-59f8-43e6-bdb2-6541c56eb090",
            price: 1.09511,
            price_sources: [],
            processed_ms: 1715775420226,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "f09d79ea-93ea-4e65-95c6-d8180b3e1a0c",
            price: 1.09471,
            price_sources: [],
            processed_ms: 1715775616280,
          },
          {
            leverage: -0.005,
            order_type: "SHORT",
            order_uuid: "d9e08792-3ae9-47a7-8e40-db54b3450650",
            price: 1.09507,
            price_sources: [],
            processed_ms: 1715775945232,
          },
          {
            leverage: -0.007,
            order_type: "SHORT",
            order_uuid: "48b838c2-710e-499b-8e42-1c316fe2a77e",
            price: 1.09536,
            price_sources: [],
            processed_ms: 1715776065888,
          },
          {
            leverage: 0.007,
            order_type: "FLAT",
            order_uuid: "a7939ff6-a4b2-4fc8-8017-10eacc66d38e",
            price: 1.09515,
            price_sources: [],
            processed_ms: 1715776188864,
          },
        ],
        position_type: "FLAT",
        position_uuid: "a9f8682e-fda1-4787-b5ca-f7ab550f2d67",
        return_at_close: 1.0000039601846225,
        trade_pair: ["AUDNZD", "AUD/NZD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 114.11999999999999,
        close_ms: 1715776193214,
        current_return: 1.0000008762705923,
        initial_entry_price: 114.12,
        is_closed_position: true,
        miner_hotkey: "5Dxqzduahnqw8q3XSUfTcEZGU7xmAsfJubhHZwvXVLN9fSjR",
        net_leverage: 0.0,
        open_ms: 1715775378969,
        orders: [
          {
            leverage: -0.005,
            order_type: "SHORT",
            order_uuid: "4b966d0b-b7f6-4a4c-9a11-cc8c0f8c0860",
            price: 114.12,
            price_sources: [],
            processed_ms: 1715775377332,
          },
          {
            leverage: 0.005,
            order_type: "FLAT",
            order_uuid: "5f188d6d-7ad2-4771-96a3-3a685447bddc",
            price: 114.1,
            price_sources: [],
            processed_ms: 1715776193214,
          },
        ],
        position_type: "FLAT",
        position_uuid: "77bdbec7-a5c3-45ce-bb9d-c536bd96652a",
        return_at_close: 1.0000005262702856,
        trade_pair: ["CADJPY", "CAD/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.8949485586385659,
        close_ms: 1715776873896,
        current_return: 1.000065610746105,
        initial_entry_price: 0.9055,
        is_closed_position: true,
        miner_hotkey: "5Dxqzduahnqw8q3XSUfTcEZGU7xmAsfJubhHZwvXVLN9fSjR",
        net_leverage: 0.0,
        open_ms: 1715773610569,
        orders: [
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "757bffc0-3ad4-4fd1-8bd3-911e1b8bb547",
            price: 0.9055,
            price_sources: [],
            processed_ms: 1715773607993,
          },
          {
            leverage: -0.005,
            order_type: "SHORT",
            order_uuid: "605d3cdd-85ce-4cba-84d0-3eeef3a881f8",
            price: 0.905785069075452,
            price_sources: [],
            processed_ms: 1715773908957,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "c1407783-4630-4ffa-8153-7c954af5bcfc",
            price: 0.90524,
            price_sources: [],
            processed_ms: 1715774508457,
          },
          {
            leverage: -0.007,
            order_type: "SHORT",
            order_uuid: "357c709c-11a3-400a-acc6-8df918fe5634",
            price: 0.90548,
            price_sources: [],
            processed_ms: 1715774666377,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "cf998674-19ba-411b-9652-c70285f367c5",
            price: 0.90479,
            price_sources: [],
            processed_ms: 1715775622709,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "d39cab2c-a86d-4ef8-9856-f8dbafcc4c68",
            price: 0.904859589723163,
            price_sources: [],
            processed_ms: 1715776202134,
          },
          {
            leverage: -0.007,
            order_type: "SHORT",
            order_uuid: "a51ef909-8aa0-48a5-a820-7edb0e51f5f3",
            price: 0.907352598073526,
            price_sources: [],
            processed_ms: 1715776494374,
          },
          {
            leverage: -0.011,
            order_type: "SHORT",
            order_uuid: "931b36c4-8a78-40dd-9060-f37dc02f784e",
            price: 0.906844068473989,
            price_sources: [],
            processed_ms: 1715776632308,
          },
          {
            leverage: -0.005,
            order_type: "SHORT",
            order_uuid: "6a58aa1c-0671-488e-acad-9de72b1d7ce5",
            price: 0.907022963786595,
            price_sources: [],
            processed_ms: 1715776752858,
          },
          {
            leverage: 0.005,
            order_type: "FLAT",
            order_uuid: "4fb26f3e-d85b-462e-b601-d6eeb65006b7",
            price: 0.906830664758164,
            price_sources: [],
            processed_ms: 1715776873896,
          },
        ],
        position_type: "FLAT",
        position_uuid: "4012c0ac-67e5-4a5c-869d-1ddcd7206603",
        return_at_close: 1.000062810562395,
        trade_pair: ["AUDCAD", "AUD/CAD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 1.0947499999999997,
        close_ms: 1715778495808,
        current_return: 1.0000017808381811,
        initial_entry_price: 1.09499,
        is_closed_position: true,
        miner_hotkey: "5Dxqzduahnqw8q3XSUfTcEZGU7xmAsfJubhHZwvXVLN9fSjR",
        net_leverage: 0.0,
        open_ms: 1715778075074,
        orders: [
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "392ec771-e29c-4c15-9a7d-ea2fec35832c",
            price: 1.09499,
            price_sources: [],
            processed_ms: 1715778072748,
          },
          {
            leverage: -0.005,
            order_type: "SHORT",
            order_uuid: "dd78de07-1bda-4ca1-9bc0-9c6c481e29c8",
            price: 1.09523,
            price_sources: [],
            processed_ms: 1715778359260,
          },
          {
            leverage: 0.005,
            order_type: "FLAT",
            order_uuid: "3dd83158-e195-446a-93a7-285a14a92934",
            price: 1.09514,
            price_sources: [],
            processed_ms: 1715778495808,
          },
        ],
        position_type: "FLAT",
        position_uuid: "10069e22-0cb1-4ba3-948c-85cb7b9379ac",
        return_at_close: 1.0000010808369346,
        trade_pair: ["AUDNZD", "AUD/NZD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 16.767818163265325,
        close_ms: 1715781354736,
        current_return: 1.000065432784402,
        initial_entry_price: 16.83575,
        is_closed_position: true,
        miner_hotkey: "5Dxqzduahnqw8q3XSUfTcEZGU7xmAsfJubhHZwvXVLN9fSjR",
        net_leverage: 0.0,
        open_ms: 1715776553909,
        orders: [
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "e7b42e04-45d7-4949-adc9-dec001f0322c",
            price: 16.83575,
            price_sources: [],
            processed_ms: 1715776552026,
          },
          {
            leverage: -0.005,
            order_type: "SHORT",
            order_uuid: "319561c0-ce79-4ce4-9540-8e1c81b90ef7",
            price: 16.84195,
            price_sources: [],
            processed_ms: 1715776696276,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "1b7685c5-e4a7-4142-8abd-8185ae1c8a77",
            price: 16.82008,
            price_sources: [],
            processed_ms: 1715778351630,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "1663bcce-c7d6-41f0-b8f9-2387a6654bdf",
            price: 16.80808,
            price_sources: [],
            processed_ms: 1715778670013,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "b62c27f2-db29-4a95-8340-404ef77ca3d4",
            price: 16.79645,
            price_sources: [],
            processed_ms: 1715778740404,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "74195fe9-7bc2-4a9f-83a5-95b9458aebb9",
            price: 16.79045,
            price_sources: [],
            processed_ms: 1715778845879,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "c85e07a0-9433-4d53-9acd-4dedafaa0a29",
            price: 16.7827,
            price_sources: [],
            processed_ms: 1715778911246,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "2cbaa947-717d-4d16-97d0-2d35d93fb1b6",
            price: 16.78753,
            price_sources: [],
            processed_ms: 1715779514779,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "fe3f4c8a-5a13-4cc2-a736-5f80cf30bce7",
            price: 16.78345,
            price_sources: [],
            processed_ms: 1715779580228,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "d3e975b6-7400-42d1-b419-27daca5e02dc",
            price: 16.7701,
            price_sources: [],
            processed_ms: 1715779763251,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "bca2bb06-9d5b-405e-8df9-b0944e303596",
            price: 16.7736,
            price_sources: [],
            processed_ms: 1715779923216,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "26eb8095-d72c-47bb-be86-0cfcdfe7b30e",
            price: 16.77564,
            price_sources: [],
            processed_ms: 1715779987221,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "cfaa9294-5fba-4cba-8a9b-455d9d40b46f",
            price: 16.7776,
            price_sources: [],
            processed_ms: 1715780143888,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "e728c472-4453-4c1a-9e24-19fde96d094b",
            price: 16.77618,
            price_sources: [],
            processed_ms: 1715780283864,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "dd33543a-962d-42e5-8d5e-160419cda672",
            price: 16.7615,
            price_sources: [],
            processed_ms: 1715780359812,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "aed820f1-1bc6-428e-91da-d1299fe29f14",
            price: 16.77195,
            price_sources: [],
            processed_ms: 1715780485306,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "1bae8bc5-e71a-43b2-8a02-ca87454ae764",
            price: 16.767,
            price_sources: [],
            processed_ms: 1715780555316,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "44b18b25-3a53-4e69-ba80-1992a6213449",
            price: 16.7739,
            price_sources: [],
            processed_ms: 1715780987998,
          },
          {
            leverage: -0.077,
            order_type: "SHORT",
            order_uuid: "2c37af91-e2bd-4de9-a3cc-3c5f0fddefb1",
            price: 16.78926,
            price_sources: [],
            processed_ms: 1715781108235,
          },
          {
            leverage: -0.039,
            order_type: "SHORT",
            order_uuid: "6a6350d6-f1cf-4a98-8a51-539ffdcb1859",
            price: 16.79266,
            price_sources: [],
            processed_ms: 1715781232968,
          },
          {
            leverage: -0.077,
            order_type: "SHORT",
            order_uuid: "a8742d7b-bd10-4e26-987f-1e8114e65dd2",
            price: 16.7903,
            price_sources: [],
            processed_ms: 1715781354736,
          },
        ],
        position_type: "FLAT",
        position_uuid: "55bc4927-9914-466d-bc84-493d34ed0b9a",
        return_at_close: 1.000053532005752,
        trade_pair: ["USDMXN", "USD/MXN", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 1.094742702874721,
        close_ms: 1715781783774,
        current_return: 0.9999959553152977,
        initial_entry_price: 1.09453,
        is_closed_position: true,
        miner_hotkey: "5Dxqzduahnqw8q3XSUfTcEZGU7xmAsfJubhHZwvXVLN9fSjR",
        net_leverage: 0.0,
        open_ms: 1715780511236,
        orders: [
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "454a840b-275a-4b3e-9d1c-047dcd05fce0",
            price: 1.09453,
            price_sources: [],
            processed_ms: 1715780509220,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "c6c2c19c-880c-4d12-851e-4893299c8ec9",
            price: 1.09445,
            price_sources: [],
            processed_ms: 1715780913425,
          },
          {
            leverage: -0.01,
            order_type: "SHORT",
            order_uuid: "1dd92fcf-0d8b-4f9e-8719-95e75de31e9c",
            price: 1.094237297125279,
            price_sources: [],
            processed_ms: 1715781342266,
          },
          {
            leverage: -0.01,
            order_type: "SHORT",
            order_uuid: "9b9bfbdd-8076-466d-a666-6f8e684afd74",
            price: 1.0943,
            price_sources: [],
            processed_ms: 1715781783774,
          },
        ],
        position_type: "FLAT",
        position_uuid: "e5032042-d626-47f2-9692-003a5bd6527d",
        return_at_close: 0.9999945553209603,
        trade_pair: ["AUDNZD", "AUD/NZD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 16.872755555555567,
        close_ms: 1715782648928,
        current_return: 1.000064586299966,
        initial_entry_price: 16.7839,
        is_closed_position: true,
        miner_hotkey: "5Dxqzduahnqw8q3XSUfTcEZGU7xmAsfJubhHZwvXVLN9fSjR",
        net_leverage: 0.0,
        open_ms: 1715781503708,
        orders: [
          {
            leverage: -0.039,
            order_type: "SHORT",
            order_uuid: "101b0d86-43df-4115-9daa-43fc8065d660",
            price: 16.7839,
            price_sources: [],
            processed_ms: 1715781500193,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "fc7f2139-33e0-4ce6-ab98-42dff6d93fae",
            price: 16.77418,
            price_sources: [],
            processed_ms: 1715781863231,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "9e0a9abf-f2d3-4fd8-8be8-6793a94d84e0",
            price: 16.7451,
            price_sources: [],
            processed_ms: 1715782214880,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "5d954d08-6554-42c9-b73b-e3f617449dbb",
            price: 16.75245,
            price_sources: [],
            processed_ms: 1715782516332,
          },
          {
            leverage: 0.009,
            order_type: "FLAT",
            order_uuid: "b9c3951c-7d13-4b6d-81ea-7eec3f68fa5e",
            price: 16.75231,
            price_sources: [],
            processed_ms: 1715782648928,
          },
        ],
        position_type: "FLAT",
        position_uuid: "f04428df-b0fe-43d6-af36-4a99f196534d",
        return_at_close: 1.0000618561236454,
        trade_pair: ["USDMXN", "USD/MXN", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.9025791991522167,
        close_ms: 1715782737808,
        current_return: 1.0000248177651,
        initial_entry_price: 0.906562514554903,
        is_closed_position: true,
        miner_hotkey: "5Dxqzduahnqw8q3XSUfTcEZGU7xmAsfJubhHZwvXVLN9fSjR",
        net_leverage: 0.0,
        open_ms: 1715779848896,
        orders: [
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "1262f47c-3f99-49d0-ae57-c9a0090daf84",
            price: 0.906562514554903,
            price_sources: [],
            processed_ms: 1715779848172,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "e92da376-44f0-4eff-a257-82a8fdfd3c55",
            price: 0.906394288983806,
            price_sources: [],
            processed_ms: 1715780374248,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "65335d1c-9af5-4499-9bde-9ab969694d32",
            price: 0.90592,
            price_sources: [],
            processed_ms: 1715780956341,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "a46f9ad8-9aae-4ef0-b8ba-6fe84b4e665c",
            price: 0.905331564986737,
            price_sources: [],
            processed_ms: 1715781260799,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "21f4cab1-c0c1-4cf2-a8dd-a1e207526cfc",
            price: 0.905678335964342,
            price_sources: [],
            processed_ms: 1715781964358,
          },
          {
            leverage: -0.02,
            order_type: "SHORT",
            order_uuid: "bea1557d-6e8f-49f1-a522-f59182bc297a",
            price: 0.90621,
            price_sources: [],
            processed_ms: 1715782115298,
          },
          {
            leverage: -0.01,
            order_type: "SHORT",
            order_uuid: "021606a6-dd82-46a8-aff0-de53ea59da66",
            price: 0.906557104913679,
            price_sources: [],
            processed_ms: 1715782236705,
          },
          {
            leverage: -0.01,
            order_type: "SHORT",
            order_uuid: "f7201426-ed89-43e7-be88-33f2c3ef815f",
            price: 0.9063,
            price_sources: [],
            processed_ms: 1715782373286,
          },
          {
            leverage: -0.005,
            order_type: "SHORT",
            order_uuid: "793d1488-49a5-4038-8436-410d6c1917c4",
            price: 0.90664,
            price_sources: [],
            processed_ms: 1715782617698,
          },
          {
            leverage: 0.005,
            order_type: "FLAT",
            order_uuid: "2d1edaf3-09d7-4e2e-8c43-17221b3f9260",
            price: 0.907078970259162,
            price_sources: [],
            processed_ms: 1715782737808,
          },
        ],
        position_type: "FLAT",
        position_uuid: "8ef59254-4754-4af6-ae82-68ce70996847",
        return_at_close: 1.000021317678238,
        trade_pair: ["AUDCAD", "AUD/CAD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.9072800000000001,
        close_ms: 1715783039335,
        current_return: 0.999999383992813,
        initial_entry_price: 0.90728,
        is_closed_position: true,
        miner_hotkey: "5Dxqzduahnqw8q3XSUfTcEZGU7xmAsfJubhHZwvXVLN9fSjR",
        net_leverage: 0.0,
        open_ms: 1715782921572,
        orders: [
          {
            leverage: -0.005,
            order_type: "SHORT",
            order_uuid: "4e11596e-bc3c-4997-9e41-e5aa37f9f004",
            price: 0.90728,
            price_sources: [],
            processed_ms: 1715782918308,
          },
          {
            leverage: 0.005,
            order_type: "FLAT",
            order_uuid: "bd2d735b-dc81-4ead-8a6c-ed153fc12862",
            price: 0.907391778200136,
            price_sources: [],
            processed_ms: 1715783039335,
          },
        ],
        position_type: "FLAT",
        position_uuid: "1fad067c-7f50-4e09-9156-b7d8c0be327d",
        return_at_close: 0.9999990339930286,
        trade_pair: ["AUDCAD", "AUD/CAD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.6829500000000303,
        close_ms: 1715784252272,
        current_return: 0.99997024813298,
        initial_entry_price: 0.66416,
        is_closed_position: true,
        miner_hotkey: "5Dxqzduahnqw8q3XSUfTcEZGU7xmAsfJubhHZwvXVLN9fSjR",
        net_leverage: 0.0,
        open_ms: 1715773123404,
        orders: [
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "ea64e89c-ce06-4c10-84c8-f652a007595a",
            price: 0.66416,
            price_sources: [],
            processed_ms: 1715773120921,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "aef6a115-be06-408c-ad1e-975e592d33bd",
            price: 0.66407,
            price_sources: [],
            processed_ms: 1715773386198,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "97e67f49-40ac-4148-881a-d4c9cf6bae12",
            price: 0.66403,
            price_sources: [],
            processed_ms: 1715773694312,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "bdd552ee-27d5-4ee8-b998-28f5e27d97a3",
            price: 0.66387,
            price_sources: [],
            processed_ms: 1715774033328,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "6f4d2347-8b49-432c-bbb7-3faa134c8262",
            price: 0.6634,
            price_sources: [],
            processed_ms: 1715774338939,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "9ac554a6-41ae-477b-9e03-dfad2819d4dc",
            price: 0.66373,
            price_sources: [],
            processed_ms: 1715774692511,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "bed52c11-8abc-4a65-9cdb-5ec9e70ea8b5",
            price: 0.66355,
            price_sources: [],
            processed_ms: 1715775662224,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "f58efc34-382e-4210-836c-00df875437a5",
            price: 0.66333,
            price_sources: [],
            processed_ms: 1715775777683,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "815fdb8a-6905-47f1-8ef3-d0fff8283fe4",
            price: 0.66333,
            price_sources: [],
            processed_ms: 1715776227536,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "bd5d3fb7-409e-4d05-8ed7-215a6928a1e0",
            price: 0.66305,
            price_sources: [],
            processed_ms: 1715776313349,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "477479ad-a072-402e-8dc5-8a5c667c2468",
            price: 0.6628,
            price_sources: [],
            processed_ms: 1715776684314,
          },
          {
            leverage: -0.055,
            order_type: "SHORT",
            order_uuid: "448e070b-b222-4365-bd11-9783d1291d4a",
            price: 0.6633,
            price_sources: [],
            processed_ms: 1715776857699,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "9f012c78-d5fd-42b2-9d5c-3598e15e02e7",
            price: 0.66328,
            price_sources: [],
            processed_ms: 1715777164918,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "cf3795c7-2700-4042-a131-0bf1510b532a",
            price: 0.66339,
            price_sources: [],
            processed_ms: 1715777478360,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "1f7a8c08-8ced-4186-bd96-6704e04c2cf6",
            price: 0.66329,
            price_sources: [],
            processed_ms: 1715777866446,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "2c26df46-5fbc-424d-a097-94507d708908",
            price: 0.66347,
            price_sources: [],
            processed_ms: 1715778185706,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "43a54caf-9407-43bc-a4e9-09fe26909391",
            price: 0.66335,
            price_sources: [],
            processed_ms: 1715778811944,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "34676bc3-5126-48d2-89c0-7ffac98bc5ac",
            price: 0.66337,
            price_sources: [],
            processed_ms: 1715779136480,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "b924dc66-3cb6-4042-b275-f78683a48820",
            price: 0.66324,
            price_sources: [],
            processed_ms: 1715779456264,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "21bb86b0-ef97-475c-96b9-8989779e8937",
            price: 0.66329,
            price_sources: [],
            processed_ms: 1715779766292,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "be664ddc-3a07-4376-88ca-fab4a6ec141b",
            price: 0.66332,
            price_sources: [],
            processed_ms: 1715780723468,
          },
          {
            leverage: -0.034,
            order_type: "SHORT",
            order_uuid: "af2322a7-035f-4725-ba69-625cb37c1f1c",
            price: 0.6631,
            price_sources: [],
            processed_ms: 1715781161339,
          },
          {
            leverage: -0.067,
            order_type: "SHORT",
            order_uuid: "ef1e68af-80c7-4b75-84dc-57c4b6601303",
            price: 0.66358,
            price_sources: [],
            processed_ms: 1715781407631,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "d2d7184e-bd4c-4c60-a266-77374a0e406b",
            price: 0.66341,
            price_sources: [],
            processed_ms: 1715781956838,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "4472f62d-ffc7-401c-b1a6-e0b12058087f",
            price: 0.66296,
            price_sources: [],
            processed_ms: 1715782274947,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "9edd7567-77bd-4150-8e92-ec6a580a1584",
            price: 0.66307,
            price_sources: [],
            processed_ms: 1715782587349,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "97159200-3cec-49c6-a706-edefb908f7c0",
            price: 0.6624,
            price_sources: [],
            processed_ms: 1715782910343,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "e63820fe-741c-41a8-b208-c042d9b3c7c0",
            price: 0.66281,
            price_sources: [],
            processed_ms: 1715783081019,
          },
          {
            leverage: -0.047,
            order_type: "SHORT",
            order_uuid: "1a4bb86f-70bc-4310-b356-902ab19f99a1",
            price: 0.66318,
            price_sources: [],
            processed_ms: 1715783616222,
          },
          {
            leverage: -0.023,
            order_type: "SHORT",
            order_uuid: "72c43438-7a1e-4759-800a-1663920f032f",
            price: 0.66321,
            price_sources: [],
            processed_ms: 1715783753558,
          },
          {
            leverage: -0.023,
            order_type: "SHORT",
            order_uuid: "79b7f27a-03e5-4dce-99a0-adc0ae6641ce",
            price: 0.6629,
            price_sources: [],
            processed_ms: 1715784130428,
          },
          {
            leverage: 0.001,
            order_type: "FLAT",
            order_uuid: "dc7d0af9-0aeb-42a4-b085-8c6ce4236e99",
            price: 0.66319,
            price_sources: [],
            processed_ms: 1715784252272,
          },
        ],
        position_type: "FLAT",
        position_uuid: "87976f8d-03aa-444c-80a7-5f4cb4e4a29f",
        return_at_close: 0.9999527486536376,
        trade_pair: ["CADCHF", "CAD/CHF", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 1.0940063157894742,
        close_ms: 1715785233426,
        current_return: 1.0000092649275891,
        initial_entry_price: 1.09445,
        is_closed_position: true,
        miner_hotkey: "5Dxqzduahnqw8q3XSUfTcEZGU7xmAsfJubhHZwvXVLN9fSjR",
        net_leverage: 0.0,
        open_ms: 1715782259804,
        orders: [
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "908d300e-db45-4316-8035-5430707c9cdd",
            price: 1.09445,
            price_sources: [],
            processed_ms: 1715782257667,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "c8ef58dd-517c-4d4a-bdb5-1fcc68f943b1",
            price: 1.09445,
            price_sources: [],
            processed_ms: 1715782589201,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "9a9a5c16-8b04-46ad-a0b2-654d14759ada",
            price: 1.09442,
            price_sources: [],
            processed_ms: 1715782973042,
          },
          {
            leverage: -0.01,
            order_type: "SHORT",
            order_uuid: "cfdfc75d-f17d-4db5-9dfc-526568fa1d12",
            price: 1.09404,
            price_sources: [],
            processed_ms: 1715783093269,
          },
          {
            leverage: -0.005,
            order_type: "SHORT",
            order_uuid: "7238aab3-d8ae-4b29-87a8-a2fb5e89a59d",
            price: 1.0943,
            price_sources: [],
            processed_ms: 1715783215152,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "47b6d392-25fd-4038-a952-1b3158ba8a9f",
            price: 1.09412,
            price_sources: [],
            processed_ms: 1715783605904,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "f71c7bb4-7355-4d65-8072-dd2903d8fbf9",
            price: 1.09395,
            price_sources: [],
            processed_ms: 1715783956876,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "534451cc-8301-451c-9df7-c0f8e69c2a15",
            price: 1.09398,
            price_sources: [],
            processed_ms: 1715784666351,
          },
          {
            leverage: -0.017,
            order_type: "SHORT",
            order_uuid: "d9f5cad4-d6c6-44c1-b2e0-4062ed0f3854",
            price: 1.09438,
            price_sources: [],
            processed_ms: 1715784864316,
          },
          {
            leverage: -0.009,
            order_type: "SHORT",
            order_uuid: "3053734e-47ff-4bf5-888c-762b44720338",
            price: 1.09458,
            price_sources: [],
            processed_ms: 1715784984233,
          },
          {
            leverage: 0.009,
            order_type: "FLAT",
            order_uuid: "ea9209e6-082d-44e3-b9bb-dcf3f1316a94",
            price: 1.09454,
            price_sources: [],
            processed_ms: 1715785233426,
          },
        ],
        position_type: "FLAT",
        position_uuid: "312dc331-79e5-4a58-bb13-61122b618eaf",
        return_at_close: 1.0000050648886765,
        trade_pair: ["AUDNZD", "AUD/NZD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.9084994766634621,
        close_ms: 1715786781208,
        current_return: 1.0000027682563006,
        initial_entry_price: 0.908649738331731,
        is_closed_position: true,
        miner_hotkey: "5Dxqzduahnqw8q3XSUfTcEZGU7xmAsfJubhHZwvXVLN9fSjR",
        net_leverage: 0.0,
        open_ms: 1715786418206,
        orders: [
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "9fecefaa-c91a-4dee-8560-4522f6508033",
            price: 0.908649738331731,
            price_sources: [],
            processed_ms: 1715786416583,
          },
          {
            leverage: -0.005,
            order_type: "SHORT",
            order_uuid: "3528dec2-085b-4d2d-a27a-ab6b1a64cc94",
            price: 0.9088,
            price_sources: [],
            processed_ms: 1715786537273,
          },
          {
            leverage: 0.005,
            order_type: "FLAT",
            order_uuid: "5a20c07e-af54-44c8-85d4-e9aca036a2de",
            price: 0.909002551736116,
            price_sources: [],
            processed_ms: 1715786781208,
          },
        ],
        position_type: "FLAT",
        position_uuid: "99bb79fa-dd34-4c3c-9157-a9c07fdb2afa",
        return_at_close: 1.0000020682543629,
        trade_pair: ["AUDCAD", "AUD/CAD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.66349,
        close_ms: 1715787276147,
        current_return: 1.0000059496912186,
        initial_entry_price: 0.6639,
        is_closed_position: true,
        miner_hotkey: "5Dxqzduahnqw8q3XSUfTcEZGU7xmAsfJubhHZwvXVLN9fSjR",
        net_leverage: 0.0,
        open_ms: 1715786641304,
        orders: [
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "dd184690-2d91-4a63-a578-7353a60a0ae0",
            price: 0.6639,
            price_sources: [],
            processed_ms: 1715786639493,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "d558a77e-4944-45ac-87dc-a1dd0546b66d",
            price: 0.66387,
            price_sources: [],
            processed_ms: 1715786912548,
          },
          {
            leverage: -0.01,
            order_type: "SHORT",
            order_uuid: "2a524b66-9639-4a62-8e50-08334d25ed2b",
            price: 0.66392,
            price_sources: [],
            processed_ms: 1715787035256,
          },
          {
            leverage: -0.005,
            order_type: "SHORT",
            order_uuid: "ca2e107c-0629-4193-9d85-64bad578aec5",
            price: 0.66421,
            price_sources: [],
            processed_ms: 1715787156681,
          },
          {
            leverage: 0.005,
            order_type: "FLAT",
            order_uuid: "66d4377a-67ed-42e6-899d-2cff9fcbbbc0",
            price: 0.66428,
            price_sources: [],
            processed_ms: 1715787276147,
          },
        ],
        position_type: "FLAT",
        position_uuid: "35fc075a-38d5-4981-a6f4-6bbac0094940",
        return_at_close: 1.0000045496828889,
        trade_pair: ["CADCHF", "CAD/CHF", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.9080377165384176,
        close_ms: 1715789423252,
        current_return: 1.0000002696494987,
        initial_entry_price: 0.9081,
        is_closed_position: true,
        miner_hotkey: "5Dxqzduahnqw8q3XSUfTcEZGU7xmAsfJubhHZwvXVLN9fSjR",
        net_leverage: 0.0,
        open_ms: 1715788591812,
        orders: [
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "64853981-2156-43ab-bb16-4c9b595acea1",
            price: 0.9081,
            price_sources: [],
            processed_ms: 1715788588596,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "4f9ad2ef-48ed-41e7-a67a-d8e773c05de3",
            price: 0.9082,
            price_sources: [],
            processed_ms: 1715788734254,
          },
          {
            leverage: -0.01,
            order_type: "SHORT",
            order_uuid: "2ad76896-002d-4e43-9cdb-f07b2d453b42",
            price: 0.908177761770414,
            price_sources: [],
            processed_ms: 1715788885852,
          },
          {
            leverage: -0.005,
            order_type: "SHORT",
            order_uuid: "b59dc21f-bc4e-4400-82ff-939f8f17ad8f",
            price: 0.908206759920754,
            price_sources: [],
            processed_ms: 1715789002436,
          },
          {
            leverage: 0.005,
            order_type: "FLAT",
            order_uuid: "e0937c7c-a785-4032-8bf3-4bedbe0b8cc7",
            price: 0.908086690280363,
            price_sources: [],
            processed_ms: 1715789423252,
          },
        ],
        position_type: "FLAT",
        position_uuid: "62a4d971-6a98-4e11-ba19-f16079c9d3d9",
        return_at_close: 0.9999988696491212,
        trade_pair: ["AUDCAD", "AUD/CAD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 5779019041841.25,
        close_ms: 1715791364330,
        current_return: 1.0000301830553382,
        initial_entry_price: 0.66428,
        is_closed_position: true,
        miner_hotkey: "5Dxqzduahnqw8q3XSUfTcEZGU7xmAsfJubhHZwvXVLN9fSjR",
        net_leverage: 0.0,
        open_ms: 1715787401019,
        orders: [
          {
            leverage: -0.01,
            order_type: "SHORT",
            order_uuid: "07670c40-bd2b-454e-bfff-86acc0f4ce31",
            price: 0.66428,
            price_sources: [],
            processed_ms: 1715787398439,
          },
          {
            leverage: -0.005,
            order_type: "SHORT",
            order_uuid: "40abecf0-1fc6-47f7-a993-bf41079e20a3",
            price: 0.66431,
            price_sources: [],
            processed_ms: 1715787519542,
          },
          {
            leverage: -0.01,
            order_type: "SHORT",
            order_uuid: "5b025132-871f-400a-9db1-5482c0aebbb8",
            price: 0.66413,
            price_sources: [],
            processed_ms: 1715787640456,
          },
          {
            leverage: -0.005,
            order_type: "SHORT",
            order_uuid: "b0552862-4cb4-48aa-b44d-e52dd6e33f97",
            price: 0.6639,
            price_sources: [],
            processed_ms: 1715787760196,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "3b019626-bf18-42cd-8311-47931c3b80f1",
            price: 0.6635,
            price_sources: [],
            processed_ms: 1715790048641,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "9415fcc8-d8b2-43b4-a258-5ccda0fa29ce",
            price: 0.66371,
            price_sources: [],
            processed_ms: 1715790727469,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "109d68d6-deb9-4c09-897c-f5cecaf80b24",
            price: 0.6633,
            price_sources: [],
            processed_ms: 1715791060556,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "3ecb937d-b977-4e20-8539-4c5e59625f9b",
            price: 0.6639,
            price_sources: [],
            processed_ms: 1715791364330,
          },
        ],
        position_type: "FLAT",
        position_uuid: "4d3f3c4f-bd3c-49fe-bd54-5d90f2a17d5e",
        return_at_close: 1.0000280829919537,
        trade_pair: ["CADCHF", "CAD/CHF", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.6642,
        close_ms: 1715792225158,
        current_return: 0.9999924721469436,
        initial_entry_price: 0.6642,
        is_closed_position: true,
        miner_hotkey: "5Dxqzduahnqw8q3XSUfTcEZGU7xmAsfJubhHZwvXVLN9fSjR",
        net_leverage: 0.0,
        open_ms: 1715791981397,
        orders: [
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "a39916e4-5479-48a6-a3ce-2c70fd9293c9",
            price: 0.6642,
            price_sources: [],
            processed_ms: 1715791980616,
          },
          {
            leverage: 0.005,
            order_type: "FLAT",
            order_uuid: "07e277ce-e8c1-4fb7-88f2-76ca2f88e168",
            price: 0.6637,
            price_sources: [],
            processed_ms: 1715792225158,
          },
        ],
        position_type: "FLAT",
        position_uuid: "9ee1254b-7a06-42f9-b156-33f0a338cbc4",
        return_at_close: 0.9999917721522131,
        trade_pair: ["CADCHF", "CAD/CHF", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.9088524965557832,
        close_ms: 1715792536505,
        current_return: 1.0000013611056104,
        initial_entry_price: 0.9092,
        is_closed_position: true,
        miner_hotkey: "5Dxqzduahnqw8q3XSUfTcEZGU7xmAsfJubhHZwvXVLN9fSjR",
        net_leverage: 0.0,
        open_ms: 1715792003117,
        orders: [
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "40f92f3b-1de5-4889-803f-1d760128e986",
            price: 0.9092,
            price_sources: [],
            processed_ms: 1715792000205,
          },
          {
            leverage: -0.005,
            order_type: "SHORT",
            order_uuid: "8568e190-2db6-48ef-8db4-460f0493b79f",
            price: 0.909547503444217,
            price_sources: [],
            processed_ms: 1715792410679,
          },
          {
            leverage: 0.005,
            order_type: "FLAT",
            order_uuid: "3d63f6cb-2e60-47e8-a8b3-fa224d0585bd",
            price: 0.9091,
            price_sources: [],
            processed_ms: 1715792536505,
          },
        ],
        position_type: "FLAT",
        position_uuid: "25e0df2c-d4e4-4d34-bf6b-34df86394a39",
        return_at_close: 1.0000006611046577,
        trade_pair: ["AUDCAD", "AUD/CAD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.90956,
        close_ms: 1715792777448,
        current_return: 0.9999990654822112,
        initial_entry_price: 0.90956,
        is_closed_position: true,
        miner_hotkey: "5Dxqzduahnqw8q3XSUfTcEZGU7xmAsfJubhHZwvXVLN9fSjR",
        net_leverage: 0.0,
        open_ms: 1715792659052,
        orders: [
          {
            leverage: -0.005,
            order_type: "SHORT",
            order_uuid: "449331ac-a95a-4016-b601-508b16a8bbd4",
            price: 0.90956,
            price_sources: [],
            processed_ms: 1715792656391,
          },
          {
            leverage: 0.005,
            order_type: "FLAT",
            order_uuid: "3e63ca72-350a-443d-bc94-d2db5eeb3a11",
            price: 0.90973,
            price_sources: [],
            processed_ms: 1715792777448,
          },
        ],
        position_type: "FLAT",
        position_uuid: "a87c2e87-eb47-401f-957c-222f0e35e2ed",
        return_at_close: 0.9999987154825383,
        trade_pair: ["AUDCAD", "AUD/CAD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.9100672488206362,
        close_ms: 1715796539212,
        current_return: 0.9999999382416829,
        initial_entry_price: 0.910067248820636,
        is_closed_position: true,
        miner_hotkey: "5Dxqzduahnqw8q3XSUfTcEZGU7xmAsfJubhHZwvXVLN9fSjR",
        net_leverage: 0.0,
        open_ms: 1715796233349,
        orders: [
          {
            leverage: 0.005,
            order_type: "LONG",
            order_uuid: "cc9907ff-6103-44ae-b8e0-c9307289d83f",
            price: 0.910067248820636,
            price_sources: [],
            processed_ms: 1715796230904,
          },
          {
            leverage: 0.005,
            order_type: "FLAT",
            order_uuid: "0dc1e73e-f83e-4db6-b84b-ba590da1f82c",
            price: 0.910056007976285,
            price_sources: [],
            processed_ms: 1715796539212,
          },
        ],
        position_type: "FLAT",
        position_uuid: "576b2efc-eea7-4b63-9fbf-ea773e9e043d",
        return_at_close: 0.9999995882417045,
        trade_pair: ["AUDCAD", "AUD/CAD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 16.638095999999955,
        close_ms: 1715797404227,
        current_return: 1.0002689485674905,
        initial_entry_price: 16.71472,
        is_closed_position: true,
        miner_hotkey: "5Dxqzduahnqw8q3XSUfTcEZGU7xmAsfJubhHZwvXVLN9fSjR",
        net_leverage: 0.0,
        open_ms: 1715784722538,
        orders: [
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "7fea0fa5-9863-4600-a0b4-29b194d8fdf6",
            price: 16.71472,
            price_sources: [],
            processed_ms: 1715784720300,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "ad7e69be-b418-42cb-b4b5-9e9bd624007d",
            price: 16.71642,
            price_sources: [],
            processed_ms: 1715784790680,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "c43b4bed-3e65-47fd-a718-df87434b41fb",
            price: 16.6995,
            price_sources: [],
            processed_ms: 1715785418970,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "fe3913d9-bb04-4d4f-9139-56a96075b339",
            price: 16.69,
            price_sources: [],
            processed_ms: 1715785640244,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "4d2e33b5-b0df-41e6-a290-789432753d38",
            price: 16.69475,
            price_sources: [],
            processed_ms: 1715785942605,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "28610228-9483-45e7-a430-2c2b14286424",
            price: 16.68435,
            price_sources: [],
            processed_ms: 1715786012211,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "9a2d65a2-868f-4ee5-a9bb-62ae09779c49",
            price: 16.68765,
            price_sources: [],
            processed_ms: 1715786193578,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "c9c748d8-bd87-446b-b5d5-7fa3bf5f17df",
            price: 16.6887,
            price_sources: [],
            processed_ms: 1715786535896,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "aad3b49c-5ff8-4c67-bc57-1d7fe213f227",
            price: 16.69067,
            price_sources: [],
            processed_ms: 1715786917478,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "9321c4dd-657f-460f-bbad-359b1b064202",
            price: 16.69515,
            price_sources: [],
            processed_ms: 1715787622706,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "e7530736-63e4-45cd-aa85-4e0cde6bbc69",
            price: 16.68573,
            price_sources: [],
            processed_ms: 1715788305221,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "ae365c2a-f20d-431c-8cac-7bbcd82df00a",
            price: 16.6831,
            price_sources: [],
            processed_ms: 1715788729255,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "39c98651-fccf-4877-83ac-067c32300d46",
            price: 16.6801,
            price_sources: [],
            processed_ms: 1715789036935,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "cfd2d984-a4a9-476f-876d-5a552e4c9617",
            price: 16.675,
            price_sources: [],
            processed_ms: 1715789448211,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "e65917e7-86c1-4158-a957-353481a719e6",
            price: 16.666,
            price_sources: [],
            processed_ms: 1715789513226,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "4c7270ea-8e82-4c34-bf55-2ce65ba57e70",
            price: 16.66516,
            price_sources: [],
            processed_ms: 1715789609258,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "b5541c27-6d27-4026-99c9-74ef9734551b",
            price: 16.67012,
            price_sources: [],
            processed_ms: 1715789845386,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "04281b70-68ec-4474-8757-d8648dc72246",
            price: 16.66265,
            price_sources: [],
            processed_ms: 1715790334241,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "60fcb174-bce5-46eb-911e-c5b110bfd2f7",
            price: 16.6622,
            price_sources: [],
            processed_ms: 1715790450094,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "16484198-9a81-48e7-a994-81c6b6a91baa",
            price: 16.66,
            price_sources: [],
            processed_ms: 1715790515196,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "710b4956-1c20-419b-a026-edd585a7d751",
            price: 16.65783,
            price_sources: [],
            processed_ms: 1715790685679,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "f573e243-1d48-4f9e-a964-a7bc5ce9fa0b",
            price: 16.6523,
            price_sources: [],
            processed_ms: 1715790757554,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "46f72036-fa07-4b03-b9a5-99114e7b51c9",
            price: 16.65705,
            price_sources: [],
            processed_ms: 1715790879461,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "13d7c37e-09ed-4c37-8cae-67a849adb674",
            price: 16.65588,
            price_sources: [],
            processed_ms: 1715790944260,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "b646d92d-586a-42bd-9998-172a0287823e",
            price: 16.65288,
            price_sources: [],
            processed_ms: 1715791080242,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "ceb68119-7a66-4007-8fbb-942ce6c81d14",
            price: 16.6496,
            price_sources: [],
            processed_ms: 1715791256337,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "79e86467-d2c8-4999-a049-6de5b9f2d7b5",
            price: 16.65383,
            price_sources: [],
            processed_ms: 1715791326300,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "a7a1df43-b92c-4ff2-9f51-c343ba199e79",
            price: 16.6512,
            price_sources: [],
            processed_ms: 1715791457235,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "8a3ef23c-6ab4-4a6d-8a30-4eefb628362f",
            price: 16.65171,
            price_sources: [],
            processed_ms: 1715791537192,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "c3b0d642-2ecb-493d-9521-4cc928acd083",
            price: 16.6385,
            price_sources: [],
            processed_ms: 1715791647940,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "418c33df-a948-4e72-8fb9-52443786127a",
            price: 16.65475,
            price_sources: [],
            processed_ms: 1715791834296,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "6409e167-91e2-4f8e-9d60-2b8c99a57d91",
            price: 16.6541,
            price_sources: [],
            processed_ms: 1715792206339,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "72643260-8c9e-45d2-b95b-4f58d269283d",
            price: 16.6465,
            price_sources: [],
            processed_ms: 1715792407116,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "8f40b73b-2819-4fb4-a04a-4a6417333895",
            price: 16.64887,
            price_sources: [],
            processed_ms: 1715792477177,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "9fed1954-90d3-470a-8482-bf06f37d280c",
            price: 16.637,
            price_sources: [],
            processed_ms: 1715792582735,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "f01f9006-21c4-4c12-9d75-452728d6efd3",
            price: 16.65509,
            price_sources: [],
            processed_ms: 1715792744318,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "ec125245-2751-482c-8e27-735251606b27",
            price: 16.6548,
            price_sources: [],
            processed_ms: 1715793091586,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "86b8afea-0ef9-4095-90b5-e6930526ded7",
            price: 16.6459,
            price_sources: [],
            processed_ms: 1715793543272,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "782dc722-74ec-45a2-90ab-7322584a1ffe",
            price: 16.6602,
            price_sources: [],
            processed_ms: 1715793900176,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "5d38de60-2812-4cef-b7df-52f5b208219a",
            price: 16.654,
            price_sources: [],
            processed_ms: 1715794322296,
          },
          {
            leverage: -0.2,
            order_type: "SHORT",
            order_uuid: "789c4972-bf79-4720-b015-d79d542f2663",
            price: 16.67652,
            price_sources: [],
            processed_ms: 1715797035570,
          },
          {
            leverage: -0.1,
            order_type: "SHORT",
            order_uuid: "06efa314-d212-4a42-b464-6bc52f5b9f62",
            price: 16.67926,
            price_sources: [],
            processed_ms: 1715797284189,
          },
          {
            leverage: -0.2,
            order_type: "SHORT",
            order_uuid: "844f9d54-72a4-4e51-be1a-11d089497ed7",
            price: 16.68305,
            price_sources: [],
            processed_ms: 1715797404227,
          },
        ],
        position_type: "FLAT",
        position_uuid: "a3250a65-82f7-493d-b401-4e930d4d7e4a",
        return_at_close: 1.0002409410369306,
        trade_pair: ["USDMXN", "USD/MXN", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.6624587500000001,
        close_ms: 1715797635787,
        current_return: 1.000017491261902,
        initial_entry_price: 0.66376,
        is_closed_position: true,
        miner_hotkey: "5Dxqzduahnqw8q3XSUfTcEZGU7xmAsfJubhHZwvXVLN9fSjR",
        net_leverage: 0.0,
        open_ms: 1715794295212,
        orders: [
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "b9d94a6d-a374-4dfa-92ba-6fc0ecf7574f",
            price: 0.66376,
            price_sources: [],
            processed_ms: 1715794293292,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "1a258090-c500-4a8c-afef-c856c50c1acf",
            price: 0.66368,
            price_sources: [],
            processed_ms: 1715794380545,
          },
          {
            leverage: -0.005,
            order_type: "SHORT",
            order_uuid: "6f040278-ea32-46cc-ba5c-31a533965bc3",
            price: 0.66373,
            price_sources: [],
            processed_ms: 1715794655955,
          },
          {
            leverage: 0.005,
            order_type: "LONG",
            order_uuid: "b433dbac-3137-4bc0-b5b7-f686ae68cea0",
            price: 0.6631,
            price_sources: [],
            processed_ms: 1715795018499,
          },
          {
            leverage: 0.005,
            order_type: "LONG",
            order_uuid: "b01eb9e5-9d47-432d-aa92-837b782a915e",
            price: 0.66348,
            price_sources: [],
            processed_ms: 1715795402512,
          },
          {
            leverage: 0.005,
            order_type: "LONG",
            order_uuid: "8c3db78f-8c43-4106-af5c-d5f0ca9cd8d2",
            price: 0.66335,
            price_sources: [],
            processed_ms: 1715795713848,
          },
          {
            leverage: 0.005,
            order_type: "LONG",
            order_uuid: "294367e3-2695-4146-89cd-16f8fcc2fe82",
            price: 0.66359,
            price_sources: [],
            processed_ms: 1715796675842,
          },
          {
            leverage: -0.018,
            order_type: "SHORT",
            order_uuid: "a1c9b954-dfde-49e6-b693-7a0bb29bbecb",
            price: 0.66383,
            price_sources: [],
            processed_ms: 1715796942172,
          },
          {
            leverage: -0.009,
            order_type: "SHORT",
            order_uuid: "9cb51551-068b-48cd-b18a-6359dac8f799",
            price: 0.66386,
            price_sources: [],
            processed_ms: 1715797504704,
          },
          {
            leverage: 0.009,
            order_type: "FLAT",
            order_uuid: "bdc06849-10a5-4881-978f-a57dfb7d99fc",
            price: 0.66391,
            price_sources: [],
            processed_ms: 1715797635787,
          },
        ],
        position_type: "FLAT",
        position_uuid: "735d75e9-1028-4004-bbcb-acf4705b6d2a",
        return_at_close: 1.0000146912129264,
        trade_pair: ["CADCHF", "CAD/CHF", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.9088999999999999,
        close_ms: 1715799021824,
        current_return: 1.000003245681593,
        initial_entry_price: 0.9089,
        is_closed_position: true,
        miner_hotkey: "5Dxqzduahnqw8q3XSUfTcEZGU7xmAsfJubhHZwvXVLN9fSjR",
        net_leverage: 0.0,
        open_ms: 1715798413756,
        orders: [
          {
            leverage: 0.005,
            order_type: "LONG",
            order_uuid: "e33928bd-22d5-40c9-828d-bea14d58338c",
            price: 0.9089,
            price_sources: [],
            processed_ms: 1715798406735,
          },
          {
            leverage: 0.005,
            order_type: "FLAT",
            order_uuid: "371c18e6-4807-4e9c-8e5e-b3db771fe6c3",
            price: 0.90949,
            price_sources: [],
            processed_ms: 1715799021824,
          },
        ],
        position_type: "FLAT",
        position_uuid: "83641a86-635d-4830-a960-f94d569f0bca",
        return_at_close: 1.000002895680457,
        trade_pair: ["AUDCAD", "AUD/CAD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.90989,
        close_ms: 1715802708982,
        current_return: 1.0000012638890414,
        initial_entry_price: 0.90989,
        is_closed_position: true,
        miner_hotkey: "5Dxqzduahnqw8q3XSUfTcEZGU7xmAsfJubhHZwvXVLN9fSjR",
        net_leverage: 0.0,
        open_ms: 1715802155988,
        orders: [
          {
            leverage: 0.005,
            order_type: "LONG",
            order_uuid: "86604d6b-8b28-42d6-a84d-a5ba2b86db2b",
            price: 0.90989,
            price_sources: [],
            processed_ms: 1715802148344,
          },
          {
            leverage: 0.005,
            order_type: "FLAT",
            order_uuid: "10e97c51-9c8b-4728-a404-b32d614730c5",
            price: 0.91012,
            price_sources: [],
            processed_ms: 1715802708982,
          },
        ],
        position_type: "FLAT",
        position_uuid: "7d7c5437-4524-44f2-88b2-63ad7c26e880",
        return_at_close: 1.000000913888599,
        trade_pair: ["AUDCAD", "AUD/CAD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 113.92641219691401,
        close_ms: 1715807266776,
        current_return: 0.9999962033109291,
        initial_entry_price: 113.799412196914,
        is_closed_position: true,
        miner_hotkey: "5Dxqzduahnqw8q3XSUfTcEZGU7xmAsfJubhHZwvXVLN9fSjR",
        net_leverage: 0.0,
        open_ms: 1715806876963,
        orders: [
          {
            leverage: 0.005,
            order_type: "LONG",
            order_uuid: "e7d046a3-5c26-4b46-b518-aec5c2896979",
            price: 113.799412196914,
            price_sources: [],
            processed_ms: 1715806870412,
          },
          {
            leverage: 0.005,
            order_type: "LONG",
            order_uuid: "c500bece-7262-44c7-a3c6-cc79f7c5f609",
            price: 113.81,
            price_sources: [],
            processed_ms: 1715806963380,
          },
          {
            leverage: -0.005,
            order_type: "SHORT",
            order_uuid: "c5f94e05-4191-439a-a2aa-aae0efa73627",
            price: 113.683,
            price_sources: [],
            processed_ms: 1715807131900,
          },
          {
            leverage: 0.005,
            order_type: "FLAT",
            order_uuid: "c4cee2f6-4fdd-4ed4-8c4f-e10646980643",
            price: 113.84,
            price_sources: [],
            processed_ms: 1715807266776,
          },
        ],
        position_type: "FLAT",
        position_uuid: "d0a8af68-52ad-45be-9332-0cf2ca290442",
        return_at_close: 0.9999955033135868,
        trade_pair: ["CADJPY", "CAD/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 1.0980106208536478,
        close_ms: 1715809932045,
        current_return: 0.9999784814725063,
        initial_entry_price: 1.09455,
        is_closed_position: true,
        miner_hotkey: "5Dxqzduahnqw8q3XSUfTcEZGU7xmAsfJubhHZwvXVLN9fSjR",
        net_leverage: 0.0,
        open_ms: 1715787040732,
        orders: [
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "98ff9b4c-22fb-4b8b-b623-2136af82933f",
            price: 1.09455,
            price_sources: [],
            processed_ms: 1715787036201,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "2d69a891-c8db-4423-8bea-16567294e5ff",
            price: 1.0945,
            price_sources: [],
            processed_ms: 1715787106656,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "2e44c6ca-786b-4adf-b4cc-3ac0781997eb",
            price: 1.09467,
            price_sources: [],
            processed_ms: 1715787237295,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "19ec6099-a1a7-4ea2-8e4a-68bda9e98d32",
            price: 1.09476,
            price_sources: [],
            processed_ms: 1715787616703,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "823f777a-688f-44a1-b876-fe0eb5260c59",
            price: 1.093987439018985,
            price_sources: [],
            processed_ms: 1715788019360,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "c47d6096-189b-4728-b012-5f864ba27827",
            price: 1.093700663878533,
            price_sources: [],
            processed_ms: 1715788180099,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "d4581dee-f8f6-493f-911c-8fef2fdfa6e7",
            price: 1.09351,
            price_sources: [],
            processed_ms: 1715788792358,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "236dec35-257c-4f8b-acba-f0320dca38ba",
            price: 1.09409,
            price_sources: [],
            processed_ms: 1715789411753,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "badada14-5f89-4958-a879-8982d6baa36e",
            price: 1.0932,
            price_sources: [],
            processed_ms: 1715789749163,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "74c77a19-618c-46fb-85d9-12be850e180a",
            price: 1.09378,
            price_sources: [],
            processed_ms: 1715790102355,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "f38c9162-dbe6-4a2b-8add-c66513b77d4f",
            price: 1.093864332618743,
            price_sources: [],
            processed_ms: 1715790446480,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "d929cf92-15cd-458a-8d4c-3561dcf1472a",
            price: 1.09398,
            price_sources: [],
            processed_ms: 1715790830869,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "a0b8553d-5a30-4c01-9b5c-146fa13b4ab5",
            price: 1.09388,
            price_sources: [],
            processed_ms: 1715791173198,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "a1751f39-792e-4dd8-ab01-f4a96c2d55b1",
            price: 1.09383,
            price_sources: [],
            processed_ms: 1715791538460,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "67cb15fc-fb7b-4806-945f-eb264caa2501",
            price: 1.09342,
            price_sources: [],
            processed_ms: 1715791885635,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "d5e6b439-8be6-4043-a0d0-d9958f46f6a6",
            price: 1.09341,
            price_sources: [],
            processed_ms: 1715792192468,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "ca3262dc-868f-46c5-ae13-e2d9e192dbc4",
            price: 1.09332,
            price_sources: [],
            processed_ms: 1715792580240,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "30084b2e-8afe-4ff6-96d5-c81d8df900f4",
            price: 1.09348,
            price_sources: [],
            processed_ms: 1715792902374,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "6a6a8542-f0b2-4721-b1ba-ead55ac67e94",
            price: 1.09313,
            price_sources: [],
            processed_ms: 1715793324335,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "dbc1d412-bd42-4042-b763-dc9a9f4bc0fe",
            price: 1.09335,
            price_sources: [],
            processed_ms: 1715793641396,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "465423a8-644e-4158-87bf-00348d221b75",
            price: 1.09333,
            price_sources: [],
            processed_ms: 1715794003178,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "eea2798f-8c47-46cb-88ab-1f781faa283d",
            price: 1.0932,
            price_sources: [],
            processed_ms: 1715794619919,
          },
          {
            leverage: 0.005,
            order_type: "LONG",
            order_uuid: "f86b995b-0f0f-4510-8079-835f63ff3d61",
            price: 1.09304,
            price_sources: [],
            processed_ms: 1715795477769,
          },
          {
            leverage: 0.005,
            order_type: "LONG",
            order_uuid: "46e62ccc-7ef4-45d7-9422-d6e3905e8479",
            price: 1.09323,
            price_sources: [],
            processed_ms: 1715795849639,
          },
          {
            leverage: 0.005,
            order_type: "LONG",
            order_uuid: "d2626242-44db-4e40-9b6c-b1e0c782fc1f",
            price: 1.09288,
            price_sources: [],
            processed_ms: 1715796160417,
          },
          {
            leverage: 0.005,
            order_type: "LONG",
            order_uuid: "9c8ce783-4558-482b-842f-1a3e653fa018",
            price: 1.093084955118619,
            price_sources: [],
            processed_ms: 1715796532269,
          },
          {
            leverage: 0.005,
            order_type: "LONG",
            order_uuid: "9c88c22a-bd63-48f2-8cdb-bd1575f3da1b",
            price: 1.09335,
            price_sources: [],
            processed_ms: 1715796920826,
          },
          {
            leverage: 0.005,
            order_type: "LONG",
            order_uuid: "9794981d-5660-451c-a3a3-1cb3b1764c16",
            price: 1.09323,
            price_sources: [],
            processed_ms: 1715797258340,
          },
          {
            leverage: 0.005,
            order_type: "LONG",
            order_uuid: "940d25ef-9131-4de4-8c13-8fd85eb45436",
            price: 1.09323,
            price_sources: [],
            processed_ms: 1715797561246,
          },
          {
            leverage: 0.005,
            order_type: "LONG",
            order_uuid: "43ead8d5-e00c-426e-86e2-46702f15e70f",
            price: 1.09326,
            price_sources: [],
            processed_ms: 1715797864328,
          },
          {
            leverage: 0.005,
            order_type: "LONG",
            order_uuid: "a7a0f689-c9d4-49cf-bc5c-2517c932caa5",
            price: 1.09321,
            price_sources: [],
            processed_ms: 1715798240809,
          },
          {
            leverage: 0.005,
            order_type: "LONG",
            order_uuid: "bd3793fe-29d4-4a2a-862d-774da5e20c69",
            price: 1.09312,
            price_sources: [],
            processed_ms: 1715798587026,
          },
          {
            leverage: 0.005,
            order_type: "LONG",
            order_uuid: "7af34506-707f-464f-9ba8-197697849125",
            price: 1.093,
            price_sources: [],
            processed_ms: 1715798888689,
          },
          {
            leverage: 0.005,
            order_type: "LONG",
            order_uuid: "b2a97606-a9d9-4670-8b2e-4c2f072abce8",
            price: 1.09277,
            price_sources: [],
            processed_ms: 1715799203312,
          },
          {
            leverage: 0.005,
            order_type: "LONG",
            order_uuid: "f83c59dd-3941-4558-b2fc-ded4aca9d676",
            price: 1.09277,
            price_sources: [],
            processed_ms: 1715799585395,
          },
          {
            leverage: 0.005,
            order_type: "LONG",
            order_uuid: "50d54ef6-7a3e-4f44-a7b3-2718da35eb35",
            price: 1.09267,
            price_sources: [],
            processed_ms: 1715800217494,
          },
          {
            leverage: 0.005,
            order_type: "LONG",
            order_uuid: "a206a0c8-0b7e-40e4-b1cd-91983a86a6ce",
            price: 1.09292,
            price_sources: [],
            processed_ms: 1715800886397,
          },
          {
            leverage: 0.005,
            order_type: "LONG",
            order_uuid: "177ca406-6c8d-4ddd-bf3e-a58b3aec40e2",
            price: 1.09303,
            price_sources: [],
            processed_ms: 1715801234730,
          },
          {
            leverage: 0.005,
            order_type: "LONG",
            order_uuid: "88a33433-00d0-41d1-a567-abd4e0854971",
            price: 1.09294,
            price_sources: [],
            processed_ms: 1715801564812,
          },
          {
            leverage: -0.153,
            order_type: "SHORT",
            order_uuid: "f5a57a04-385d-4721-b109-9f9e0fb07b9f",
            price: 1.09323,
            price_sources: [],
            processed_ms: 1715802031614,
          },
          {
            leverage: 0.005,
            order_type: "LONG",
            order_uuid: "c1de34d7-a5b6-455d-8394-5faef2b1fe39",
            price: 1.09302,
            price_sources: [],
            processed_ms: 1715803030504,
          },
          {
            leverage: 0.005,
            order_type: "LONG",
            order_uuid: "12a7056c-c557-440a-8bb2-ae25e50b936d",
            price: 1.09306,
            price_sources: [],
            processed_ms: 1715804124706,
          },
          {
            leverage: 0.005,
            order_type: "LONG",
            order_uuid: "9deec402-a45e-4f2c-b41e-462951731e9d",
            price: 1.093,
            price_sources: [],
            processed_ms: 1715804462778,
          },
          {
            leverage: 0.005,
            order_type: "LONG",
            order_uuid: "3ea3c350-aef1-4019-97f7-e9130ee9a404",
            price: 1.09306,
            price_sources: [],
            processed_ms: 1715804852128,
          },
          {
            leverage: 0.005,
            order_type: "LONG",
            order_uuid: "d188000f-24b5-477e-b59f-5806343a2ba4",
            price: 1.09316,
            price_sources: [],
            processed_ms: 1715805155462,
          },
          {
            leverage: 0.005,
            order_type: "LONG",
            order_uuid: "133ce53c-6583-43e8-bea6-21a5357cba15",
            price: 1.092966211445342,
            price_sources: [],
            processed_ms: 1715805571703,
          },
          {
            leverage: 0.005,
            order_type: "LONG",
            order_uuid: "5cadb18e-ebfb-4b03-8e35-e7c8eea08881",
            price: 1.09322,
            price_sources: [],
            processed_ms: 1715806178820,
          },
          {
            leverage: 0.005,
            order_type: "LONG",
            order_uuid: "0a1a8ef2-7d83-47d4-a04a-57a0b1c528dc",
            price: 1.09331,
            price_sources: [],
            processed_ms: 1715806516227,
          },
          {
            leverage: 0.005,
            order_type: "LONG",
            order_uuid: "4645e421-1d53-4487-978c-bec9efd3e390",
            price: 1.093127434391155,
            price_sources: [],
            processed_ms: 1715806812280,
          },
          {
            leverage: 0.005,
            order_type: "LONG",
            order_uuid: "396e2472-a708-4a8d-b0d0-80c8f2b61fc3",
            price: 1.08751,
            price_sources: [],
            processed_ms: 1715806887233,
          },
          {
            leverage: 0.005,
            order_type: "LONG",
            order_uuid: "55bca91e-fd7a-4882-b0c5-f624998605fe",
            price: 1.0902,
            price_sources: [],
            processed_ms: 1715807136873,
          },
          {
            leverage: 0.005,
            order_type: "LONG",
            order_uuid: "9f9dabbb-6b90-490f-bacc-f0d7363d88eb",
            price: 1.09298,
            price_sources: [],
            processed_ms: 1715807525783,
          },
          {
            leverage: -0.106,
            order_type: "SHORT",
            order_uuid: "79e9b5a4-a9f1-4d6f-99ad-fe0c2d602f0f",
            price: 1.093313822761488,
            price_sources: [],
            processed_ms: 1715808238460,
          },
          {
            leverage: -0.053,
            order_type: "SHORT",
            order_uuid: "7c88d1ad-0d85-4877-aa41-d50966df613f",
            price: 1.09367,
            price_sources: [],
            processed_ms: 1715808364690,
          },
          {
            leverage: -0.027,
            order_type: "SHORT",
            order_uuid: "814605e6-5c1c-434c-98a9-5b9f5ea0864c",
            price: 1.093043852635463,
            price_sources: [],
            processed_ms: 1715808496372,
          },
          {
            leverage: -0.013,
            order_type: "SHORT",
            order_uuid: "85f00678-ca7d-41ef-8d2a-697b57a31b3e",
            price: 1.09346,
            price_sources: [],
            processed_ms: 1715808633708,
          },
          {
            leverage: 0.005,
            order_type: "LONG",
            order_uuid: "4d0899fb-7224-47ac-97e0-0ec1f77c0067",
            price: 1.0933,
            price_sources: [],
            processed_ms: 1715808940352,
          },
          {
            leverage: 0.005,
            order_type: "LONG",
            order_uuid: "4da5c00f-948a-4289-ad3b-fe5716f77d1e",
            price: 1.093061926413661,
            price_sources: [],
            processed_ms: 1715809098736,
          },
          {
            leverage: -0.012,
            order_type: "SHORT",
            order_uuid: "dac2b2c6-4b35-41fa-be86-a6efefdc0d48",
            price: 1.093244554488574,
            price_sources: [],
            processed_ms: 1715809240653,
          },
          {
            leverage: -0.006,
            order_type: "SHORT",
            order_uuid: "8cec3556-872a-4cbd-b8a3-8e01619fc089",
            price: 1.09281,
            price_sources: [],
            processed_ms: 1715809650916,
          },
          {
            leverage: 0.005,
            order_type: "FLAT",
            order_uuid: "15fb8df2-4240-435e-bd94-d4e37037d1df",
            price: 1.0933,
            price_sources: [],
            processed_ms: 1715809932045,
          },
        ],
        position_type: "FLAT",
        position_uuid: "1ba4befb-fd6d-4a30-8772-377fe5a1e1ce",
        return_at_close: 0.9999522320373677,
        trade_pair: ["AUDNZD", "AUD/NZD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.910177316828371,
        close_ms: 1715813249492,
        current_return: 1.0000032558665255,
        initial_entry_price: 0.910177316828371,
        is_closed_position: true,
        miner_hotkey: "5Dxqzduahnqw8q3XSUfTcEZGU7xmAsfJubhHZwvXVLN9fSjR",
        net_leverage: 0.0,
        open_ms: 1715806620040,
        orders: [
          {
            leverage: 0.005,
            order_type: "LONG",
            order_uuid: "b8789814-47e8-4a22-ae1d-6e3c2bd25fe4",
            price: 0.910177316828371,
            price_sources: [],
            processed_ms: 1715806615098,
          },
          {
            leverage: 0.005,
            order_type: "FLAT",
            order_uuid: "45fb151c-f979-45ae-ada7-77856d5a088f",
            price: 0.91077,
            price_sources: [],
            processed_ms: 1715813249492,
          },
        ],
        position_type: "FLAT",
        position_uuid: "7d3acae0-c228-42de-b546-f17058edfcb1",
        return_at_close: 1.0000029058653859,
        trade_pair: ["AUDCAD", "AUD/CAD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.9104100000000002,
        close_ms: 1715818775254,
        current_return: 1.000005654379382,
        initial_entry_price: 0.91077,
        is_closed_position: true,
        miner_hotkey: "5Dxqzduahnqw8q3XSUfTcEZGU7xmAsfJubhHZwvXVLN9fSjR",
        net_leverage: 0.0,
        open_ms: 1715815454146,
        orders: [
          {
            leverage: 0.005,
            order_type: "LONG",
            order_uuid: "1bc0ad37-0d2b-47db-9674-e1c6be3edef5",
            price: 0.91077,
            price_sources: [],
            processed_ms: 1715815449397,
          },
          {
            leverage: 0.005,
            order_type: "LONG",
            order_uuid: "78d42093-b554-4283-b90a-28c43ba40b1e",
            price: 0.9109,
            price_sources: [],
            processed_ms: 1715817720777,
          },
          {
            leverage: -0.005,
            order_type: "SHORT",
            order_uuid: "a3d65233-3d27-45c0-84c0-5e7a7dda877c",
            price: 0.91126,
            price_sources: [],
            processed_ms: 1715818654378,
          },
          {
            leverage: 0.005,
            order_type: "FLAT",
            order_uuid: "adef9487-18f9-4d4c-9cd4-d595aacdbbf9",
            price: 0.911439967821948,
            price_sources: [],
            processed_ms: 1715818775254,
          },
        ],
        position_type: "FLAT",
        position_uuid: "d4072979-b8c4-4710-b9b8-43f0a900b684",
        return_at_close: 1.000004954375424,
        trade_pair: ["AUDCAD", "AUD/CAD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 16.716130000000202,
        close_ms: 1715829034262,
        current_return: 1.0000071386186684,
        initial_entry_price: 16.6839,
        is_closed_position: true,
        miner_hotkey: "5Dxqzduahnqw8q3XSUfTcEZGU7xmAsfJubhHZwvXVLN9fSjR",
        net_leverage: 0.0,
        open_ms: 1715797538570,
        orders: [
          {
            leverage: -0.1,
            order_type: "SHORT",
            order_uuid: "e590e7c1-6c8c-48e2-87ed-70e2a7987575",
            price: 16.6839,
            price_sources: [],
            processed_ms: 1715797535274,
          },
          {
            leverage: -0.05,
            order_type: "SHORT",
            order_uuid: "f75a85f4-8b3f-48ed-a9e8-a399259c22ae",
            price: 16.6877,
            price_sources: [],
            processed_ms: 1715797661319,
          },
          {
            leverage: 0.005,
            order_type: "LONG",
            order_uuid: "c57b5b1a-8603-4bcc-b52f-bf902dd62bbe",
            price: 16.6953,
            price_sources: [],
            processed_ms: 1715798050994,
          },
          {
            leverage: 0.005,
            order_type: "LONG",
            order_uuid: "a5119931-c4ec-4129-9b02-43fbb404b575",
            price: 16.701,
            price_sources: [],
            processed_ms: 1715798502334,
          },
          {
            leverage: 0.005,
            order_type: "LONG",
            order_uuid: "82020b6e-5548-4c42-ac94-54141f8905a0",
            price: 16.699,
            price_sources: [],
            processed_ms: 1715798900192,
          },
          {
            leverage: 0.005,
            order_type: "LONG",
            order_uuid: "083c780d-fd7f-48a8-8446-1b8fb145032b",
            price: 16.698,
            price_sources: [],
            processed_ms: 1715799226336,
          },
          {
            leverage: 0.005,
            order_type: "LONG",
            order_uuid: "a6830fec-091f-463c-a1da-e1b36207ee6a",
            price: 16.69081,
            price_sources: [],
            processed_ms: 1715799582563,
          },
          {
            leverage: 0.005,
            order_type: "LONG",
            order_uuid: "bdab0a92-419f-41e5-8795-932956682725",
            price: 16.68471,
            price_sources: [],
            processed_ms: 1715800200059,
          },
          {
            leverage: 0.005,
            order_type: "LONG",
            order_uuid: "e893f5ee-4c88-4aa8-b8f4-7ea9b774acb7",
            price: 16.6835,
            price_sources: [],
            processed_ms: 1715800507165,
          },
          {
            leverage: 0.005,
            order_type: "LONG",
            order_uuid: "49622522-06d7-46cf-8578-e23841abb10e",
            price: 16.68425,
            price_sources: [],
            processed_ms: 1715800802545,
          },
          {
            leverage: 0.005,
            order_type: "LONG",
            order_uuid: "b7f0f79d-e382-43b8-be8c-3f6bece6f5f9",
            price: 16.6789,
            price_sources: [],
            processed_ms: 1715801110494,
          },
          {
            leverage: 0.005,
            order_type: "LONG",
            order_uuid: "64eb7a6b-6826-4e93-8f16-1109faa846f6",
            price: 16.6724,
            price_sources: [],
            processed_ms: 1715801611860,
          },
          {
            leverage: 0.005,
            order_type: "LONG",
            order_uuid: "679e2937-1d72-4bcd-8a1f-9bf4a7e93dd9",
            price: 16.68605,
            price_sources: [],
            processed_ms: 1715802501751,
          },
          {
            leverage: 0.005,
            order_type: "LONG",
            order_uuid: "5510a884-aeb3-4a76-97b8-e53899700ced",
            price: 16.68672,
            price_sources: [],
            processed_ms: 1715803516516,
          },
          {
            leverage: 0.005,
            order_type: "LONG",
            order_uuid: "467988f9-1bab-418f-93ac-67ae6510d6cf",
            price: 16.68849,
            price_sources: [],
            processed_ms: 1715803864186,
          },
          {
            leverage: 0.005,
            order_type: "LONG",
            order_uuid: "9d9366b2-dcec-4e6e-8e53-bd4c6e9d0176",
            price: 16.68405,
            price_sources: [],
            processed_ms: 1715805354064,
          },
          {
            leverage: 0.005,
            order_type: "LONG",
            order_uuid: "9ba8a3f7-cfa3-4115-bcad-b26f6e2a7f51",
            price: 16.67725,
            price_sources: [],
            processed_ms: 1715805967525,
          },
          {
            leverage: 0.005,
            order_type: "LONG",
            order_uuid: "f7c63352-65cb-4871-b998-f8432c16cff6",
            price: 16.6794,
            price_sources: [],
            processed_ms: 1715811370075,
          },
          {
            leverage: 0.005,
            order_type: "LONG",
            order_uuid: "51707238-36cf-4055-b210-87d290c1fd9b",
            price: 16.6695,
            price_sources: [],
            processed_ms: 1715812351431,
          },
          {
            leverage: 0.005,
            order_type: "LONG",
            order_uuid: "b2cea305-2b1d-4274-94e6-09856ef4f590",
            price: 16.68096,
            price_sources: [],
            processed_ms: 1715815369775,
          },
          {
            leverage: 0.005,
            order_type: "LONG",
            order_uuid: "955a2dc5-4bc7-4b33-a16f-d7bdac53c29f",
            price: 16.68146,
            price_sources: [],
            processed_ms: 1715816756477,
          },
          {
            leverage: 0.005,
            order_type: "LONG",
            order_uuid: "3eea1a03-d3fb-4cdf-9879-9e9b0775838e",
            price: 16.6679,
            price_sources: [],
            processed_ms: 1715817301977,
          },
          {
            leverage: 0.005,
            order_type: "LONG",
            order_uuid: "351d4ed2-3406-4339-935e-f394f9b522d2",
            price: 16.668,
            price_sources: [],
            processed_ms: 1715818773000,
          },
          {
            leverage: 0.005,
            order_type: "LONG",
            order_uuid: "1016e6be-d8b0-4a97-a5e7-6d17d9bf730a",
            price: 16.6788,
            price_sources: [],
            processed_ms: 1715819523103,
          },
          {
            leverage: 0.005,
            order_type: "LONG",
            order_uuid: "a0ac44b7-230d-4e00-bea7-66ab553779f6",
            price: 16.6842,
            price_sources: [],
            processed_ms: 1715820999169,
          },
          {
            leverage: 0.005,
            order_type: "LONG",
            order_uuid: "ec98e917-e8f5-4c35-ad13-847cdb16ea79",
            price: 16.68626,
            price_sources: [],
            processed_ms: 1715823041900,
          },
          {
            leverage: 0.005,
            order_type: "LONG",
            order_uuid: "5a2fa5e3-2f21-4af3-b92f-015601badaa6",
            price: 16.67814,
            price_sources: [],
            processed_ms: 1715823459469,
          },
          {
            leverage: 0.005,
            order_type: "LONG",
            order_uuid: "a0fdf9eb-c6d2-4271-aa5b-7270d13dad99",
            price: 16.689,
            price_sources: [],
            processed_ms: 1715824685183,
          },
          {
            leverage: 0.005,
            order_type: "LONG",
            order_uuid: "b9b54566-887d-4c21-85f2-04b83b872bc5",
            price: 16.69018,
            price_sources: [],
            processed_ms: 1715825023652,
          },
          {
            leverage: 0.005,
            order_type: "LONG",
            order_uuid: "1e159340-b45b-4f82-b380-5e970867470e",
            price: 16.69534,
            price_sources: [],
            processed_ms: 1715826220885,
          },
          {
            leverage: 0.005,
            order_type: "LONG",
            order_uuid: "b9db9393-7a50-4773-a3d5-147fa0e7cde5",
            price: 16.6793,
            price_sources: [],
            processed_ms: 1715826918277,
          },
          {
            leverage: 0.005,
            order_type: "LONG",
            order_uuid: "5363c62a-f1ef-4f5f-83f0-a93b4b117e52",
            price: 16.69231,
            price_sources: [],
            processed_ms: 1715829034262,
          },
        ],
        position_type: "FLAT",
        position_uuid: "92928e98-85b6-4cec-8a98-9a1f00cd8a81",
        return_at_close: 0.9999966385437129,
        trade_pair: ["USDMXN", "USD/MXN", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 16.6634,
        close_ms: 1715830344140,
        current_return: 1.0000077895267472,
        initial_entry_price: 16.6634,
        is_closed_position: true,
        miner_hotkey: "5Dxqzduahnqw8q3XSUfTcEZGU7xmAsfJubhHZwvXVLN9fSjR",
        net_leverage: 0.0,
        open_ms: 1715829914217,
        orders: [
          {
            leverage: 0.005,
            order_type: "LONG",
            order_uuid: "aecf44c6-4df8-4428-8fde-236d5d683c48",
            price: 16.6634,
            price_sources: [],
            processed_ms: 1715829907430,
          },
          {
            leverage: -0.005,
            order_type: "SHORT",
            order_uuid: "ea481dc6-b02c-4db8-96b1-b6243f005289",
            price: 16.68936,
            price_sources: [],
            processed_ms: 1715830344140,
          },
        ],
        position_type: "FLAT",
        position_uuid: "66c2d33e-e6ee-4e1a-b0ae-eb3a17671421",
        return_at_close: 1.0000074395240208,
        trade_pair: ["USDMXN", "USD/MXN", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.9067979855907242,
        close_ms: 1715830582793,
        current_return: 1.000038930407836,
        initial_entry_price: 0.912427159698166,
        is_closed_position: true,
        miner_hotkey: "5Dxqzduahnqw8q3XSUfTcEZGU7xmAsfJubhHZwvXVLN9fSjR",
        net_leverage: 0.0,
        open_ms: 1715820871578,
        orders: [
          {
            leverage: 0.005,
            order_type: "LONG",
            order_uuid: "27db084c-19f4-4b08-81e1-7b84a701cc38",
            price: 0.912427159698166,
            price_sources: [],
            processed_ms: 1715820864494,
          },
          {
            leverage: 0.005,
            order_type: "LONG",
            order_uuid: "59c378d5-11c9-474b-854d-04be1cf279b1",
            price: 0.911643422287783,
            price_sources: [],
            processed_ms: 1715822548873,
          },
          {
            leverage: 0.005,
            order_type: "LONG",
            order_uuid: "d4eded0b-27de-4807-86ce-90dd1c0b67fd",
            price: 0.91123,
            price_sources: [],
            processed_ms: 1715822882264,
          },
          {
            leverage: 0.005,
            order_type: "LONG",
            order_uuid: "41ba2a36-146a-44f5-919d-71bc16298848",
            price: 0.910253921936071,
            price_sources: [],
            processed_ms: 1715823002561,
          },
          {
            leverage: 0.005,
            order_type: "LONG",
            order_uuid: "5af5fdd5-b433-4029-bb84-ec927a330cdb",
            price: 0.910470892245073,
            price_sources: [],
            processed_ms: 1715823238131,
          },
          {
            leverage: 0.005,
            order_type: "LONG",
            order_uuid: "88d7d643-b7bc-4a08-83a8-df81bb727041",
            price: 0.91012,
            price_sources: [],
            processed_ms: 1715823927163,
          },
          {
            leverage: 0.005,
            order_type: "LONG",
            order_uuid: "b517a209-eab9-42f2-8628-0e8f442c398e",
            price: 0.90961,
            price_sources: [],
            processed_ms: 1715824428803,
          },
          {
            leverage: 0.005,
            order_type: "LONG",
            order_uuid: "0dd0d59d-fe4c-4f1c-9e69-4a0b3667e4cd",
            price: 0.90925,
            price_sources: [],
            processed_ms: 1715824967649,
          },
          {
            leverage: 0.005,
            order_type: "LONG",
            order_uuid: "4b13cbae-bf90-40c0-9b89-501408585d95",
            price: 0.90909,
            price_sources: [],
            processed_ms: 1715825169302,
          },
          {
            leverage: 0.005,
            order_type: "LONG",
            order_uuid: "bfa6ced3-b4e8-401f-b385-eef6326b84e6",
            price: 0.90909,
            price_sources: [],
            processed_ms: 1715825616200,
          },
          {
            leverage: 0.005,
            order_type: "LONG",
            order_uuid: "44628024-d3b9-4cfb-81af-777d4b816f9e",
            price: 0.9091,
            price_sources: [],
            processed_ms: 1715825677372,
          },
          {
            leverage: 0.005,
            order_type: "LONG",
            order_uuid: "a9bdc0f4-610f-4f0f-a917-2f674c6da9c4",
            price: 0.909021993948826,
            price_sources: [],
            processed_ms: 1715826039111,
          },
          {
            leverage: 0.005,
            order_type: "LONG",
            order_uuid: "6275151d-89ea-4fe1-868f-f79367a7f88c",
            price: 0.9092,
            price_sources: [],
            processed_ms: 1715826260324,
          },
          {
            leverage: -0.032,
            order_type: "SHORT",
            order_uuid: "6c36ba87-30ea-4796-8873-eb268571a3ba",
            price: 0.910548585354097,
            price_sources: [],
            processed_ms: 1715830063952,
          },
          {
            leverage: -0.016,
            order_type: "SHORT",
            order_uuid: "8e9e1254-8f5c-414d-a996-ace94bc6483c",
            price: 0.910487380330722,
            price_sources: [],
            processed_ms: 1715830339625,
          },
          {
            leverage: -0.008,
            order_type: "SHORT",
            order_uuid: "1d355a25-f9c2-4271-a231-a3ca0e9faec3",
            price: 0.910750282955055,
            price_sources: [],
            processed_ms: 1715830460432,
          },
          {
            leverage: 0.008,
            order_type: "FLAT",
            order_uuid: "55fdc2a5-7cbb-4d98-9f91-d0188c1e7d41",
            price: 0.910744781307134,
            price_sources: [],
            processed_ms: 1715830582793,
          },
        ],
        position_type: "FLAT",
        position_uuid: "d58820b3-72cd-403c-b428-d890342a158a",
        return_at_close: 1.0000343802307028,
        trade_pair: ["AUDCAD", "AUD/CAD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 66361.07583333332,
        close_ms: 1715835559928,
        current_return: 1.000097001956959,
        initial_entry_price: 65969.7,
        is_closed_position: true,
        miner_hotkey: "5Dxqzduahnqw8q3XSUfTcEZGU7xmAsfJubhHZwvXVLN9fSjR",
        net_leverage: 0.0,
        open_ms: 1715832293124,
        orders: [
          {
            leverage: -0.05,
            order_type: "SHORT",
            order_uuid: "ebdbe59a-ce82-4a9f-a726-701d29adbc47",
            price: 65969.7,
            price_sources: [],
            processed_ms: 1715832286678,
          },
          {
            leverage: 0.025,
            order_type: "LONG",
            order_uuid: "33e0b90f-a2bc-42a5-91ee-7c017df53ecf",
            price: 65848.8,
            price_sources: [],
            processed_ms: 1715835210510,
          },
          {
            leverage: 0.013,
            order_type: "LONG",
            order_uuid: "96883b40-baf6-490f-8bfb-c3b0db555341",
            price: 65840.93,
            price_sources: [],
            processed_ms: 1715835456148,
          },
          {
            leverage: 0.013,
            order_type: "LONG",
            order_uuid: "6c825dd1-2453-471f-a297-93c2b7fc72dc",
            price: 65827.81,
            price_sources: [],
            processed_ms: 1715835559928,
          },
        ],
        position_type: "FLAT",
        position_uuid: "cdac7ad3-03a0-4987-8ba7-3cdd76ea8a21",
        return_at_close: 1.0000469971068613,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 66376.76000000004,
        close_ms: 1715837771960,
        current_return: 1.0000477269063577,
        initial_entry_price: 65879.82,
        is_closed_position: true,
        miner_hotkey: "5Dxqzduahnqw8q3XSUfTcEZGU7xmAsfJubhHZwvXVLN9fSjR",
        net_leverage: 0.0,
        open_ms: 1715836694032,
        orders: [
          {
            leverage: -0.05,
            order_type: "SHORT",
            order_uuid: "ee13939c-3d06-40d3-b83f-619a19879e5a",
            price: 65879.82,
            price_sources: [],
            processed_ms: 1715836687536,
          },
          {
            leverage: 0.025,
            order_type: "LONG",
            order_uuid: "35e457e9-359a-46b6-b3f8-00a667574b09",
            price: 65807.78,
            price_sources: [],
            processed_ms: 1715837239576,
          },
          {
            leverage: 0.013,
            order_type: "LONG",
            order_uuid: "977a3287-a707-46bc-a869-f8a86a4ae261",
            price: 65797.78,
            price_sources: [],
            processed_ms: 1715837366147,
          },
          {
            leverage: 0.006,
            order_type: "LONG",
            order_uuid: "eaa08d10-eb19-4e51-aab5-9bbaed0b93f8",
            price: 65860.8,
            price_sources: [],
            processed_ms: 1715837645539,
          },
          {
            leverage: 0.006,
            order_type: "FLAT",
            order_uuid: "62825d77-f4c9-4e7f-ba15-20b2368e6aef",
            price: 65852.72,
            price_sources: [],
            processed_ms: 1715837771960,
          },
        ],
        position_type: "FLAT",
        position_uuid: "4ac7be6a-ed45-42e6-abea-19663986bfd2",
        return_at_close: 0.9999977245200123,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 1.0919095603877615,
        close_ms: 1715967467788,
        current_return: 0.9997431900743733,
        initial_entry_price: 1.09329,
        is_closed_position: true,
        miner_hotkey: "5Dxqzduahnqw8q3XSUfTcEZGU7xmAsfJubhHZwvXVLN9fSjR",
        net_leverage: 0.0,
        open_ms: 1715812036103,
        orders: [
          {
            leverage: 0.005,
            order_type: "LONG",
            order_uuid: "d505fd51-11c1-4268-b576-90b4469066c3",
            price: 1.09329,
            price_sources: [],
            processed_ms: 1715812030200,
          },
          {
            leverage: 0.005,
            order_type: "LONG",
            order_uuid: "763b4f4d-cb9c-4158-ae36-8545c839c574",
            price: 1.09333,
            price_sources: [],
            processed_ms: 1715812348723,
          },
          {
            leverage: 0.005,
            order_type: "LONG",
            order_uuid: "0e9f678c-eb6e-42ce-8e77-f430b6ab97db",
            price: 1.09325,
            price_sources: [],
            processed_ms: 1715812771883,
          },
          {
            leverage: 0.005,
            order_type: "LONG",
            order_uuid: "0a20a584-fab2-4601-8fc4-b27c047e2a7d",
            price: 1.09327,
            price_sources: [],
            processed_ms: 1715813086646,
          },
          {
            leverage: 0.005,
            order_type: "LONG",
            order_uuid: "a8cfdb05-351b-415d-b278-06bab56dfb01",
            price: 1.09352,
            price_sources: [],
            processed_ms: 1715813757883,
          },
          {
            leverage: 0.005,
            order_type: "LONG",
            order_uuid: "1f28d39a-7134-464a-8090-0efe0483c999",
            price: 1.09324,
            price_sources: [],
            processed_ms: 1715814060555,
          },
          {
            leverage: 0.005,
            order_type: "LONG",
            order_uuid: "5a4c7736-9b20-442e-b112-0df1bf687c83",
            price: 1.09317,
            price_sources: [],
            processed_ms: 1715814364203,
          },
          {
            leverage: 0.005,
            order_type: "LONG",
            order_uuid: "c063f27b-f60d-4bec-ae37-f761a47604e3",
            price: 1.09326,
            price_sources: [],
            processed_ms: 1715814689339,
          },
          {
            leverage: 0.005,
            order_type: "LONG",
            order_uuid: "21eb9fb0-f9eb-452c-911a-b44c8e6fc8b2",
            price: 1.09319,
            price_sources: [],
            processed_ms: 1715814992252,
          },
          {
            leverage: 0.005,
            order_type: "LONG",
            order_uuid: "054336a9-56bd-47b0-95af-b68f8734db69",
            price: 1.09298,
            price_sources: [],
            processed_ms: 1715815378899,
          },
          {
            leverage: 0.005,
            order_type: "LONG",
            order_uuid: "45a2bb1b-2b16-4489-9fe9-5605f0f773f8",
            price: 1.09303,
            price_sources: [],
            processed_ms: 1715815683821,
          },
          {
            leverage: 0.005,
            order_type: "LONG",
            order_uuid: "bcc9b654-88c0-43bf-b011-9dc697ef0bc2",
            price: 1.09305,
            price_sources: [],
            processed_ms: 1715815985560,
          },
          {
            leverage: 0.005,
            order_type: "LONG",
            order_uuid: "6f2a6c2f-03fd-43cb-8757-2a8b8d07b77e",
            price: 1.0927,
            price_sources: [],
            processed_ms: 1715816303490,
          },
          {
            leverage: 0.005,
            order_type: "LONG",
            order_uuid: "847ac4f9-4f00-42a3-9461-12c79c632219",
            price: 1.093142722564562,
            price_sources: [],
            processed_ms: 1715816616152,
          },
          {
            leverage: 0.005,
            order_type: "LONG",
            order_uuid: "5ac2f6b0-5242-43b9-ac39-e1c52207c206",
            price: 1.09283,
            price_sources: [],
            processed_ms: 1715817020844,
          },
          {
            leverage: 0.005,
            order_type: "LONG",
            order_uuid: "65f6217c-fc92-4dcc-a027-4a3dbb14ea8c",
            price: 1.09295,
            price_sources: [],
            processed_ms: 1715817355996,
          },
          {
            leverage: 0.005,
            order_type: "LONG",
            order_uuid: "99a34af3-3eeb-4c34-97e8-e0d47469a95d",
            price: 1.09338,
            price_sources: [],
            processed_ms: 1715817715820,
          },
          {
            leverage: 0.005,
            order_type: "LONG",
            order_uuid: "6b2cd11b-0d7e-44b1-9ee1-d609a2de7a0b",
            price: 1.09329,
            price_sources: [],
            processed_ms: 1715818030564,
          },
          {
            leverage: -0.045,
            order_type: "SHORT",
            order_uuid: "6be735ba-502b-4451-8401-85e225ca52d3",
            price: 1.09353,
            price_sources: [],
            processed_ms: 1715818255534,
          },
          {
            leverage: -0.023,
            order_type: "SHORT",
            order_uuid: "d6c83e13-b661-4949-9c16-0117075ef3d8",
            price: 1.093558928452134,
            price_sources: [],
            processed_ms: 1715818434860,
          },
          {
            leverage: -0.011,
            order_type: "SHORT",
            order_uuid: "fd43ca50-55e2-4184-b843-9612a927190e",
            price: 1.09356,
            price_sources: [],
            processed_ms: 1715818721496,
          },
          {
            leverage: 0.005,
            order_type: "LONG",
            order_uuid: "4540b709-eefd-4851-9e51-d8b731632c51",
            price: 1.0931,
            price_sources: [],
            processed_ms: 1715819043209,
          },
          {
            leverage: 0.005,
            order_type: "LONG",
            order_uuid: "c227bb74-68c2-4fb9-913e-a9ba2cff1b2c",
            price: 1.093343870852993,
            price_sources: [],
            processed_ms: 1715819359612,
          },
          {
            leverage: 0.005,
            order_type: "LONG",
            order_uuid: "f60469f0-92f9-4798-bc7f-d33dc24ba858",
            price: 1.093187992621164,
            price_sources: [],
            processed_ms: 1715819663709,
          },
          {
            leverage: 0.005,
            order_type: "LONG",
            order_uuid: "82c93061-e85a-4992-b68d-242f6c93d29b",
            price: 1.09306,
            price_sources: [],
            processed_ms: 1715819977808,
          },
          {
            leverage: 0.005,
            order_type: "LONG",
            order_uuid: "8df940a2-39d6-4694-9e1b-136203b2072b",
            price: 1.0932,
            price_sources: [],
            processed_ms: 1715820318722,
          },
          {
            leverage: 0.005,
            order_type: "LONG",
            order_uuid: "2d17d98d-55d2-4a4b-87e0-02a2ff648300",
            price: 1.09348,
            price_sources: [],
            processed_ms: 1715820627463,
          },
          {
            leverage: 0.005,
            order_type: "LONG",
            order_uuid: "19c454ba-3792-4cca-ab8f-eac10ef1cbd0",
            price: 1.09334,
            price_sources: [],
            processed_ms: 1715820964738,
          },
          {
            leverage: 0.005,
            order_type: "LONG",
            order_uuid: "40980417-c310-4855-89c5-68fdada46ee8",
            price: 1.09319,
            price_sources: [],
            processed_ms: 1715821589884,
          },
          {
            leverage: 0.005,
            order_type: "LONG",
            order_uuid: "da3ba70e-15c9-4325-ab12-28873c67a3c1",
            price: 1.09347,
            price_sources: [],
            processed_ms: 1715821892512,
          },
          {
            leverage: -0.028,
            order_type: "SHORT",
            order_uuid: "68ca4c41-0660-43e9-80bf-021f5a608f62",
            price: 1.093744252002744,
            price_sources: [],
            processed_ms: 1715822109326,
          },
          {
            leverage: 0.005,
            order_type: "LONG",
            order_uuid: "0dbf85d5-cd74-4392-885c-0e67f0930367",
            price: 1.093524308603278,
            price_sources: [],
            processed_ms: 1715822422769,
          },
          {
            leverage: 0.005,
            order_type: "LONG",
            order_uuid: "aa38d248-44a8-412a-a1b4-6479a36436ed",
            price: 1.0932,
            price_sources: [],
            processed_ms: 1715822727211,
          },
          {
            leverage: 0.005,
            order_type: "LONG",
            order_uuid: "93891f1c-6b15-4cb7-93b4-ab62fa14de7b",
            price: 1.092296360775386,
            price_sources: [],
            processed_ms: 1715823218453,
          },
          {
            leverage: 0.005,
            order_type: "LONG",
            order_uuid: "d457f251-fc11-463b-a01b-9b62b795e0e7",
            price: 1.092,
            price_sources: [],
            processed_ms: 1715823314094,
          },
          {
            leverage: 0.005,
            order_type: "LONG",
            order_uuid: "8f18e6c4-846f-4963-bf95-a8e2fe9f2ccb",
            price: 1.091780986524968,
            price_sources: [],
            processed_ms: 1715823444636,
          },
          {
            leverage: 0.005,
            order_type: "LONG",
            order_uuid: "2c4b3c06-b36d-4a8c-b70d-a34057965e72",
            price: 1.092495537524913,
            price_sources: [],
            processed_ms: 1715823853204,
          },
          {
            leverage: 0.005,
            order_type: "LONG",
            order_uuid: "54f5439c-b816-49e6-9ad6-02bd0be1d89c",
            price: 1.09199,
            price_sources: [],
            processed_ms: 1715824057108,
          },
          {
            leverage: 0.005,
            order_type: "LONG",
            order_uuid: "925d2218-f486-47c9-bbd7-0fa08f98af8e",
            price: 1.09187,
            price_sources: [],
            processed_ms: 1715824291168,
          },
          {
            leverage: 0.005,
            order_type: "LONG",
            order_uuid: "0a6c9929-4065-4aa1-80be-84545d320416",
            price: 1.091735332838803,
            price_sources: [],
            processed_ms: 1715824474085,
          },
          {
            leverage: 0.005,
            order_type: "LONG",
            order_uuid: "d6386dc5-54d9-4fa0-8994-beb7fa762f50",
            price: 1.09174,
            price_sources: [],
            processed_ms: 1715824685211,
          },
          {
            leverage: 0.005,
            order_type: "LONG",
            order_uuid: "08d15215-8a3e-4abc-99d7-9ae3a1ab393c",
            price: 1.091554306222924,
            price_sources: [],
            processed_ms: 1715824820064,
          },
          {
            leverage: 0.005,
            order_type: "LONG",
            order_uuid: "35106354-ae3f-4d0f-a56a-a67e10d4bb33",
            price: 1.09164,
            price_sources: [],
            processed_ms: 1715824920778,
          },
          {
            leverage: 0.005,
            order_type: "LONG",
            order_uuid: "7832a9d9-5dd2-41e2-b6ee-0ebd17d9a15a",
            price: 1.09173,
            price_sources: [],
            processed_ms: 1715825051096,
          },
          {
            leverage: 0.005,
            order_type: "LONG",
            order_uuid: "181088f3-ea7c-41a1-a87b-0766532132e7",
            price: 1.09155,
            price_sources: [],
            processed_ms: 1715825217056,
          },
          {
            leverage: 0.005,
            order_type: "LONG",
            order_uuid: "3aa5093b-3954-4ff2-8a67-b9ff18954806",
            price: 1.09171,
            price_sources: [],
            processed_ms: 1715825312133,
          },
          {
            leverage: 0.005,
            order_type: "LONG",
            order_uuid: "5f906e67-d3a6-4e95-9a3b-60b7b4c54faf",
            price: 1.09164,
            price_sources: [],
            processed_ms: 1715825382892,
          },
          {
            leverage: 0.005,
            order_type: "LONG",
            order_uuid: "57cf34cd-e45d-493c-baa1-b14b74d3dc15",
            price: 1.09155,
            price_sources: [],
            processed_ms: 1715825458708,
          },
          {
            leverage: 0.005,
            order_type: "LONG",
            order_uuid: "9493b293-b03d-4a41-8f58-0de7e52c7ae6",
            price: 1.09145,
            price_sources: [],
            processed_ms: 1715825533810,
          },
          {
            leverage: 0.005,
            order_type: "LONG",
            order_uuid: "d7c8bad8-246b-411c-aca7-7b4062ba17fc",
            price: 1.09166,
            price_sources: [],
            processed_ms: 1715825919951,
          },
          {
            leverage: 0.005,
            order_type: "LONG",
            order_uuid: "2d6161e6-e2f5-49e4-b3be-1fa319fa11ee",
            price: 1.09162,
            price_sources: [],
            processed_ms: 1715826256099,
          },
          {
            leverage: 0.005,
            order_type: "LONG",
            order_uuid: "86fd5e4c-78e1-411f-98ff-0e0ec0ef5222",
            price: 1.09155,
            price_sources: [],
            processed_ms: 1715826553734,
          },
          {
            leverage: 0.005,
            order_type: "LONG",
            order_uuid: "af1ee5db-8f39-4f37-bfac-a4f1ebaa97e3",
            price: 1.091793176023353,
            price_sources: [],
            processed_ms: 1715826862007,
          },
          {
            leverage: 0.005,
            order_type: "LONG",
            order_uuid: "c2c1a115-4104-482d-95ee-498f763742bd",
            price: 1.09184,
            price_sources: [],
            processed_ms: 1715827162335,
          },
          {
            leverage: 0.005,
            order_type: "LONG",
            order_uuid: "c44766cd-429a-494d-b4b7-b401f4a9a0d6",
            price: 1.09229,
            price_sources: [],
            processed_ms: 1715827464953,
          },
          {
            leverage: 0.005,
            order_type: "LONG",
            order_uuid: "888c1143-6c26-4f64-9e1e-4f379633d5a8",
            price: 1.092143707298311,
            price_sources: [],
            processed_ms: 1715827781575,
          },
          {
            leverage: 0.005,
            order_type: "LONG",
            order_uuid: "26dc3b41-597f-4673-a397-152218b13c11",
            price: 1.09201,
            price_sources: [],
            processed_ms: 1715828110740,
          },
          {
            leverage: 0.005,
            order_type: "LONG",
            order_uuid: "f9d4d1e8-9492-4ec0-bd88-bb0bfd56a2d4",
            price: 1.09225,
            price_sources: [],
            processed_ms: 1715828419868,
          },
          {
            leverage: 0.005,
            order_type: "LONG",
            order_uuid: "34e14da3-4521-4790-be7c-96224534c0f0",
            price: 1.092056053024198,
            price_sources: [],
            processed_ms: 1715828735415,
          },
          {
            leverage: 0.005,
            order_type: "LONG",
            order_uuid: "1e99d3d0-5151-49a4-a1bc-7d1c28317333",
            price: 1.09199,
            price_sources: [],
            processed_ms: 1715831482857,
          },
          {
            leverage: 0.005,
            order_type: "LONG",
            order_uuid: "6d22d840-8b04-4cca-9f28-7b5c6d8b474e",
            price: 1.092016877024641,
            price_sources: [],
            processed_ms: 1715831805789,
          },
          {
            leverage: 0.003,
            order_type: "FLAT",
            order_uuid: "03fa7e75-21b3-4a98-97bd-a17172bc396f",
            price: 1.090332213626029,
            price_sources: [],
            processed_ms: 1715967467788,
          },
        ],
        position_type: "FLAT",
        position_uuid: "57ce2125-1ff4-48c8-98de-a1d48ca611f8",
        return_at_close: 0.9997232451977314,
        trade_pair: ["AUDNZD", "AUD/NZD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 113.6135,
        close_ms: 1715967867004,
        current_return: 1.0002047732067512,
        initial_entry_price: 113.76,
        is_closed_position: true,
        miner_hotkey: "5Dxqzduahnqw8q3XSUfTcEZGU7xmAsfJubhHZwvXVLN9fSjR",
        net_leverage: 0.0,
        open_ms: 1715809090236,
        orders: [
          {
            leverage: 0.005,
            order_type: "LONG",
            order_uuid: "a12acf2a-7ca8-4126-b704-861dfbdd638a",
            price: 113.76,
            price_sources: [],
            processed_ms: 1715809084209,
          },
          {
            leverage: 0.005,
            order_type: "LONG",
            order_uuid: "85c5fe30-af51-4285-bf6a-3f9614c91da2",
            price: 113.784,
            price_sources: [],
            processed_ms: 1715809507970,
          },
          {
            leverage: 0.005,
            order_type: "LONG",
            order_uuid: "de9dc9f7-f9ca-4779-8cf5-6af71fda4ba0",
            price: 113.757,
            price_sources: [],
            processed_ms: 1715809864822,
          },
          {
            leverage: 0.005,
            order_type: "LONG",
            order_uuid: "b27240a5-04bc-4697-9760-ddfadc0fc621",
            price: 113.794,
            price_sources: [],
            processed_ms: 1715810328356,
          },
          {
            leverage: 0.005,
            order_type: "LONG",
            order_uuid: "6c878fa5-e97d-4fe5-9bee-c82cf3215527",
            price: 113.407,
            price_sources: [],
            processed_ms: 1715819045502,
          },
          {
            leverage: 0.005,
            order_type: "LONG",
            order_uuid: "255296c0-e240-42d4-8d72-c300abb6d427",
            price: 113.179,
            price_sources: [],
            processed_ms: 1715825231924,
          },
          {
            leverage: 0.003,
            order_type: "FLAT",
            order_uuid: "37247d0e-e879-467c-8453-5c9ed4687e88",
            price: 114.39,
            price_sources: [],
            processed_ms: 1715967867004,
          },
        ],
        position_type: "FLAT",
        position_uuid: "04823264-e8a7-4576-a22c-dbf3f79764c1",
        return_at_close: 1.0002026727767275,
        trade_pair: ["CADJPY", "CAD/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.6623918333333328,
        close_ms: 1715967960133,
        current_return: 1.0018566586321183,
        initial_entry_price: 0.6638,
        is_closed_position: true,
        miner_hotkey: "5Dxqzduahnqw8q3XSUfTcEZGU7xmAsfJubhHZwvXVLN9fSjR",
        net_leverage: 0.0,
        open_ms: 1715799445043,
        orders: [
          {
            leverage: 0.005,
            order_type: "LONG",
            order_uuid: "9d6550c6-3f3a-4dab-b61a-b2b9812461c1",
            price: 0.6638,
            price_sources: [],
            processed_ms: 1715799438014,
          },
          {
            leverage: 0.005,
            order_type: "LONG",
            order_uuid: "e59c5b30-0777-4bc5-8c21-59af2a22a225",
            price: 0.66377,
            price_sources: [],
            processed_ms: 1715799527619,
          },
          {
            leverage: 0.005,
            order_type: "LONG",
            order_uuid: "08cc1789-88db-48d3-9c89-a3faff1659a9",
            price: 0.66355,
            price_sources: [],
            processed_ms: 1715799859082,
          },
          {
            leverage: 0.005,
            order_type: "LONG",
            order_uuid: "7d1a6370-5d29-4ac8-8782-5477a3739aaa",
            price: 0.6633,
            price_sources: [],
            processed_ms: 1715800164960,
          },
          {
            leverage: 0.005,
            order_type: "LONG",
            order_uuid: "4cc7b45b-d41b-4395-b470-2c00656d7406",
            price: 0.66369,
            price_sources: [],
            processed_ms: 1715800514488,
          },
          {
            leverage: 0.005,
            order_type: "LONG",
            order_uuid: "78af8125-d99a-48c4-8468-bc56a422aa30",
            price: 0.663,
            price_sources: [],
            processed_ms: 1715801140634,
          },
          {
            leverage: 0.005,
            order_type: "LONG",
            order_uuid: "5d92a145-3d85-4d7d-835b-255e8d9a1ea7",
            price: 0.66356,
            price_sources: [],
            processed_ms: 1715801519894,
          },
          {
            leverage: 0.005,
            order_type: "LONG",
            order_uuid: "78f4a59c-858a-4886-b482-a4a24dfe167b",
            price: 0.6632,
            price_sources: [],
            processed_ms: 1715801869808,
          },
          {
            leverage: 0.005,
            order_type: "LONG",
            order_uuid: "4e69d0b3-b62d-4bd7-ade2-c341c279a95f",
            price: 0.66348,
            price_sources: [],
            processed_ms: 1715802234930,
          },
          {
            leverage: 0.005,
            order_type: "LONG",
            order_uuid: "4a97db36-d21d-49d1-afda-cb37edec3685",
            price: 0.66351,
            price_sources: [],
            processed_ms: 1715802619701,
          },
          {
            leverage: 0.005,
            order_type: "LONG",
            order_uuid: "eeaed6dd-e3a6-46c1-b2db-182437c9177e",
            price: 0.66324,
            price_sources: [],
            processed_ms: 1715803336405,
          },
          {
            leverage: 0.005,
            order_type: "LONG",
            order_uuid: "f262cc6d-36bd-47d8-a3cd-e6b47675f251",
            price: 0.66329,
            price_sources: [],
            processed_ms: 1715803696635,
          },
          {
            leverage: 0.005,
            order_type: "LONG",
            order_uuid: "a402ea66-ab69-40ea-ba77-6b6fb7878054",
            price: 0.66309,
            price_sources: [],
            processed_ms: 1715804091309,
          },
          {
            leverage: 0.005,
            order_type: "LONG",
            order_uuid: "8079448f-dedd-477f-b191-1d7956dd7774",
            price: 0.66296,
            price_sources: [],
            processed_ms: 1715804513462,
          },
          {
            leverage: 0.005,
            order_type: "LONG",
            order_uuid: "6e09db4c-6e40-43e8-9232-02b733ffede4",
            price: 0.66314,
            price_sources: [],
            processed_ms: 1715805044628,
          },
          {
            leverage: 0.005,
            order_type: "LONG",
            order_uuid: "9dd3d48c-b8b5-4f56-978f-f5a977062571",
            price: 0.66315,
            price_sources: [],
            processed_ms: 1715805798763,
          },
          {
            leverage: 0.005,
            order_type: "LONG",
            order_uuid: "265bd2a5-e2f3-43b4-b3a4-a0bb5c9e01b0",
            price: 0.66326,
            price_sources: [],
            processed_ms: 1715806680564,
          },
          {
            leverage: 0.005,
            order_type: "LONG",
            order_uuid: "667f554d-7e75-40e9-8534-2e4ae2d19314",
            price: 0.66287,
            price_sources: [],
            processed_ms: 1715807513621,
          },
          {
            leverage: 0.005,
            order_type: "LONG",
            order_uuid: "70559ad2-46f2-4aa9-a1fa-a4d52dc762a4",
            price: 0.66276,
            price_sources: [],
            processed_ms: 1715808105141,
          },
          {
            leverage: 0.005,
            order_type: "LONG",
            order_uuid: "8924bd0d-9165-4502-ab26-8b0d9b9adcba",
            price: 0.6627,
            price_sources: [],
            processed_ms: 1715808683866,
          },
          {
            leverage: 0.005,
            order_type: "LONG",
            order_uuid: "a8d5d490-897a-4c4c-a6db-1e1605a6ee24",
            price: 0.66286,
            price_sources: [],
            processed_ms: 1715810696715,
          },
          {
            leverage: 0.005,
            order_type: "LONG",
            order_uuid: "c867b9d9-ec83-450b-9368-11732c42551b",
            price: 0.66291,
            price_sources: [],
            processed_ms: 1715811102683,
          },
          {
            leverage: 0.005,
            order_type: "LONG",
            order_uuid: "8682b522-e28a-4d51-9641-f4c11c01a573",
            price: 0.66298,
            price_sources: [],
            processed_ms: 1715811915026,
          },
          {
            leverage: 0.005,
            order_type: "LONG",
            order_uuid: "ccf82e90-6311-4d69-9508-b2afc182b32e",
            price: 0.66286,
            price_sources: [],
            processed_ms: 1715812638573,
          },
          {
            leverage: 0.005,
            order_type: "LONG",
            order_uuid: "b9a47582-b19f-4530-b010-dc45b6c71836",
            price: 0.66283,
            price_sources: [],
            processed_ms: 1715812968946,
          },
          {
            leverage: 0.005,
            order_type: "LONG",
            order_uuid: "5fb77664-9c68-467a-bed6-c63ccedcb631",
            price: 0.6622,
            price_sources: [],
            processed_ms: 1715813818910,
          },
          {
            leverage: 0.005,
            order_type: "LONG",
            order_uuid: "b6446f2d-f0c0-44de-b2a6-d00234954d41",
            price: 0.66264,
            price_sources: [],
            processed_ms: 1715814217082,
          },
          {
            leverage: 0.005,
            order_type: "LONG",
            order_uuid: "a01ff9ed-952a-4949-8145-aea60a051cda",
            price: 0.6627,
            price_sources: [],
            processed_ms: 1715814544915,
          },
          {
            leverage: 0.005,
            order_type: "LONG",
            order_uuid: "b699f3a5-af0e-41b9-9a91-cd305e049d95",
            price: 0.66269,
            price_sources: [],
            processed_ms: 1715814967748,
          },
          {
            leverage: 0.005,
            order_type: "LONG",
            order_uuid: "2aaaebf8-a081-45a5-97b5-ae3f815fd066",
            price: 0.66259,
            price_sources: [],
            processed_ms: 1715815324508,
          },
          {
            leverage: 0.005,
            order_type: "LONG",
            order_uuid: "9ebdac23-8aa1-4373-ae7c-d4bcd136a120",
            price: 0.66253,
            price_sources: [],
            processed_ms: 1715816073147,
          },
          {
            leverage: 0.005,
            order_type: "LONG",
            order_uuid: "bdbfebdb-b92c-4729-9046-590fe5d187a4",
            price: 0.66246,
            price_sources: [],
            processed_ms: 1715816806527,
          },
          {
            leverage: 0.005,
            order_type: "LONG",
            order_uuid: "cee245ee-6cd5-474d-afaf-4e133797746b",
            price: 0.66231,
            price_sources: [],
            processed_ms: 1715817461527,
          },
          {
            leverage: 0.005,
            order_type: "LONG",
            order_uuid: "de607dfe-2227-4f87-9f1f-d8a5e0678de1",
            price: 0.66243,
            price_sources: [],
            processed_ms: 1715817778455,
          },
          {
            leverage: 0.005,
            order_type: "LONG",
            order_uuid: "feb938e0-1b4a-4a67-8b54-0149c3c0360d",
            price: 0.66218,
            price_sources: [],
            processed_ms: 1715819060553,
          },
          {
            leverage: 0.005,
            order_type: "LONG",
            order_uuid: "a8b46de6-6d99-44f9-a16e-4d98cb59c4d8",
            price: 0.66203,
            price_sources: [],
            processed_ms: 1715819424413,
          },
          {
            leverage: 0.005,
            order_type: "LONG",
            order_uuid: "123447a3-1282-4d84-acaa-913608eba2d6",
            price: 0.66175,
            price_sources: [],
            processed_ms: 1715819779201,
          },
          {
            leverage: 0.005,
            order_type: "LONG",
            order_uuid: "06fbb5fc-b456-4b41-abde-ab218892197c",
            price: 0.66167,
            price_sources: [],
            processed_ms: 1715820150860,
          },
          {
            leverage: 0.005,
            order_type: "LONG",
            order_uuid: "f28600e0-19d5-431e-8bcd-95000edc2934",
            price: 0.66172,
            price_sources: [],
            processed_ms: 1715820458434,
          },
          {
            leverage: 0.005,
            order_type: "LONG",
            order_uuid: "3e311a21-d97f-428e-808a-012fd5c9d0cb",
            price: 0.66139,
            price_sources: [],
            processed_ms: 1715821234641,
          },
          {
            leverage: 0.005,
            order_type: "LONG",
            order_uuid: "9c16fd31-6356-489e-a814-c51875b8656a",
            price: 0.66153,
            price_sources: [],
            processed_ms: 1715821560396,
          },
          {
            leverage: 0.005,
            order_type: "LONG",
            order_uuid: "1899dc33-cb43-4f3f-9155-d494b86bfbad",
            price: 0.6616,
            price_sources: [],
            processed_ms: 1715821939644,
          },
          {
            leverage: 0.005,
            order_type: "LONG",
            order_uuid: "78e60229-acf8-4f91-aaff-acf6f5f601ad",
            price: 0.6614,
            price_sources: [],
            processed_ms: 1715822284572,
          },
          {
            leverage: 0.005,
            order_type: "LONG",
            order_uuid: "7a26a2fc-eae5-4289-a03f-bbe6ab493e71",
            price: 0.66127,
            price_sources: [],
            processed_ms: 1715822619690,
          },
          {
            leverage: 0.005,
            order_type: "LONG",
            order_uuid: "1a9dac75-cded-477b-a720-76810906e77d",
            price: 0.66111,
            price_sources: [],
            processed_ms: 1715823510830,
          },
          {
            leverage: 0.005,
            order_type: "LONG",
            order_uuid: "26eb380c-1541-4f58-938b-42a2c10e3800",
            price: 0.66138,
            price_sources: [],
            processed_ms: 1715823854192,
          },
          {
            leverage: 0.005,
            order_type: "LONG",
            order_uuid: "353301fa-6130-4a02-900d-88f845c3f3c8",
            price: 0.66124,
            price_sources: [],
            processed_ms: 1715824164828,
          },
          {
            leverage: 0.005,
            order_type: "LONG",
            order_uuid: "26079079-c563-46a9-bfc1-0556309382d4",
            price: 0.66138,
            price_sources: [],
            processed_ms: 1715825259371,
          },
          {
            leverage: 0.005,
            order_type: "LONG",
            order_uuid: "06b7f69d-3d9b-4542-abaa-987929e33b2c",
            price: 0.6614,
            price_sources: [],
            processed_ms: 1715826386634,
          },
          {
            leverage: 0.005,
            order_type: "LONG",
            order_uuid: "fb275b58-68d5-4de6-802f-cc4be391ab2d",
            price: 0.66134,
            price_sources: [],
            processed_ms: 1715826716053,
          },
          {
            leverage: 0.005,
            order_type: "LONG",
            order_uuid: "67bcb573-5bd0-4489-a531-1fcb0f642b85",
            price: 0.66164,
            price_sources: [],
            processed_ms: 1715827066681,
          },
          {
            leverage: 0.005,
            order_type: "LONG",
            order_uuid: "44076703-d833-470e-9fb8-a9ca20881f44",
            price: 0.6615,
            price_sources: [],
            processed_ms: 1715827380664,
          },
          {
            leverage: 0.005,
            order_type: "LONG",
            order_uuid: "a446168b-0318-4aee-ada5-9b5fee352051",
            price: 0.66154,
            price_sources: [],
            processed_ms: 1715827710448,
          },
          {
            leverage: 0.005,
            order_type: "LONG",
            order_uuid: "21c526be-bd2e-42a6-8976-fa1173f83f18",
            price: 0.66157,
            price_sources: [],
            processed_ms: 1715828106338,
          },
          {
            leverage: 0.005,
            order_type: "LONG",
            order_uuid: "a82f3fec-c43f-4656-85e1-41e0f9ae3a1e",
            price: 0.66161,
            price_sources: [],
            processed_ms: 1715828869585,
          },
          {
            leverage: 0.005,
            order_type: "LONG",
            order_uuid: "aa8aea60-cb82-4000-abfc-e3075bd416fc",
            price: 0.66167,
            price_sources: [],
            processed_ms: 1715829216584,
          },
          {
            leverage: 0.005,
            order_type: "LONG",
            order_uuid: "607a35d7-5a67-4106-b4a0-2a90c811b1d3",
            price: 0.66174,
            price_sources: [],
            processed_ms: 1715829526233,
          },
          {
            leverage: 0.005,
            order_type: "LONG",
            order_uuid: "8bb5e50d-6401-45c6-9cac-7afd69ac2331",
            price: 0.66183,
            price_sources: [],
            processed_ms: 1715829870804,
          },
          {
            leverage: 0.005,
            order_type: "LONG",
            order_uuid: "9d431341-9187-497e-a005-e6b6c958666c",
            price: 0.66181,
            price_sources: [],
            processed_ms: 1715830346690,
          },
          {
            leverage: 0.005,
            order_type: "LONG",
            order_uuid: "128dac3d-d2b8-42a7-95f8-66cd16a675f8",
            price: 0.6619,
            price_sources: [],
            processed_ms: 1715831727771,
          },
          {
            leverage: 0.003,
            order_type: "FLAT",
            order_uuid: "a2be111c-eb40-4fc8-83dd-88f668077bb8",
            price: 0.6665,
            price_sources: [],
            processed_ms: 1715967960133,
          },
        ],
        position_type: "FLAT",
        position_uuid: "955d13f8-986a-475a-a126-905d66df6036",
        return_at_close: 1.0018356196422868,
        trade_pair: ["CADCHF", "CAD/CHF", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 65919.63000000005,
        close_ms: 1716221258278,
        current_return: 1.0014275484226354,
        initial_entry_price: 67169.0,
        is_closed_position: true,
        miner_hotkey: "5Dxqzduahnqw8q3XSUfTcEZGU7xmAsfJubhHZwvXVLN9fSjR",
        net_leverage: 0.0,
        open_ms: 1716091271502,
        orders: [
          {
            leverage: 0.1,
            order_type: "LONG",
            order_uuid: "4e3f9f18-94ca-4b76-9425-b822daff8e3e",
            price: 67169.0,
            price_sources: [],
            processed_ms: 1716091263941,
          },
          {
            leverage: 0.1,
            order_type: "LONG",
            order_uuid: "4767c758-27bf-4fb7-be80-28b0162ce918",
            price: 66328.99,
            price_sources: [],
            processed_ms: 1716147170213,
          },
          {
            leverage: -0.15,
            order_type: "SHORT",
            order_uuid: "e15acdfe-2c82-4e72-b4e4-3b69a9923049",
            price: 67025.45,
            price_sources: [],
            processed_ms: 1716180688600,
          },
          {
            leverage: 0.05,
            order_type: "FLAT",
            order_uuid: "0019fe81-d564-47ac-954f-54d1e8d9d006",
            price: 67837.37,
            price_sources: [],
            processed_ms: 1716221258278,
          },
        ],
        position_type: "FLAT",
        position_uuid: "3a70e564-eecc-481d-b8d4-aba9df6aa76c",
        return_at_close: 1.0012272629129508,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 69920.98333333332,
        close_ms: 1716239727100,
        current_return: 1.0033003367503348,
        initial_entry_price: 69766.82,
        is_closed_position: true,
        miner_hotkey: "5Dxqzduahnqw8q3XSUfTcEZGU7xmAsfJubhHZwvXVLN9fSjR",
        net_leverage: 0.0,
        open_ms: 1716233972704,
        orders: [
          {
            leverage: -0.2,
            order_type: "SHORT",
            order_uuid: "bbe86f19-abbc-40f0-a28c-4190f0b9e518",
            price: 69766.82,
            price_sources: [],
            processed_ms: 1716233966548,
          },
          {
            leverage: -0.1,
            order_type: "SHORT",
            order_uuid: "d8a46fd5-973b-4264-b1ed-8db7e08a4611",
            price: 69938.5,
            price_sources: [],
            processed_ms: 1716235014388,
          },
          {
            leverage: -0.1,
            order_type: "SHORT",
            order_uuid: "18e45305-91a3-489c-80f8-83e46cc56e30",
            price: 69624.16,
            price_sources: [],
            processed_ms: 1716236609736,
          },
          {
            leverage: 0.1,
            order_type: "LONG",
            order_uuid: "84cde75b-f1ef-46a8-9f15-341892ab43ee",
            price: 69333.35,
            price_sources: [],
            processed_ms: 1716239155916,
          },
          {
            leverage: 0.1,
            order_type: "FLAT",
            order_uuid: "41e9c701-c4bd-45a2-a86f-89157558662e",
            price: 69153.47,
            price_sources: [],
            processed_ms: 1716239727100,
          },
        ],
        position_type: "FLAT",
        position_uuid: "6093149a-2a9d-4ee3-a4f5-cff6e9ea9257",
        return_at_close: 1.0028990166156346,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 71807.69666666667,
        close_ms: 1716254875979,
        current_return: 1.0019026862954348,
        initial_entry_price: 70944.17,
        is_closed_position: true,
        miner_hotkey: "5Dxqzduahnqw8q3XSUfTcEZGU7xmAsfJubhHZwvXVLN9fSjR",
        net_leverage: 0.0,
        open_ms: 1716247479247,
        orders: [
          {
            leverage: -0.1,
            order_type: "SHORT",
            order_uuid: "6a9a4d34-da7a-49b2-8c15-30bc95ae5d2e",
            price: 70944.17,
            price_sources: [],
            processed_ms: 1716247472709,
          },
          {
            leverage: -0.1,
            order_type: "SHORT",
            order_uuid: "c0da1119-5eeb-49d0-a050-1e2846d4dd03",
            price: 71386.98,
            price_sources: [],
            processed_ms: 1716248357044,
          },
          {
            leverage: -0.1,
            order_type: "SHORT",
            order_uuid: "64c21109-7063-465a-ba37-181915ddb7b2",
            price: 71828.1,
            price_sources: [],
            processed_ms: 1716250778946,
          },
          {
            leverage: 0.1,
            order_type: "LONG",
            order_uuid: "c11db663-7a2c-4a7e-afdd-2fe7761660b6",
            price: 71057.74,
            price_sources: [],
            processed_ms: 1716254268859,
          },
          {
            leverage: 0.05,
            order_type: "LONG",
            order_uuid: "8a2305f9-ddb6-466e-993f-0be35de70dc3",
            price: 70779.93,
            price_sources: [],
            processed_ms: 1716254623439,
          },
          {
            leverage: 0.05,
            order_type: "FLAT",
            order_uuid: "4f4bfb52-3769-4eab-b855-289bdb61bbae",
            price: 70907.8,
            price_sources: [],
            processed_ms: 1716254875979,
          },
        ],
        position_type: "FLAT",
        position_uuid: "d1fada99-7ef1-4d67-bf4a-dbba3d7a518a",
        return_at_close: 1.0016021154895463,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 69663.43,
        close_ms: 1716387594225,
        current_return: 1.0001110051572253,
        initial_entry_price: 69663.43,
        is_closed_position: true,
        miner_hotkey: "5Dxqzduahnqw8q3XSUfTcEZGU7xmAsfJubhHZwvXVLN9fSjR",
        net_leverage: 0.0,
        open_ms: 1716385125409,
        orders: [
          {
            leverage: 0.05,
            order_type: "LONG",
            order_uuid: "7e5ebfca-10a4-40dd-9d7b-4923e5b55e55",
            price: 69663.43,
            price_sources: [],
            processed_ms: 1716385121748,
          },
          {
            leverage: 0.05,
            order_type: "FLAT",
            order_uuid: "1fea1e17-f7a4-41d3-a3b0-2c063ace2836",
            price: 69818.09,
            price_sources: [],
            processed_ms: 1716387594225,
          },
        ],
        position_type: "FLAT",
        position_uuid: "bebb306b-73f2-48b3-ac9e-57c20bea2416",
        return_at_close: 1.0000609996069674,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 70110.56,
        close_ms: 1716390381218,
        current_return: 0.9997586240931466,
        initial_entry_price: 70110.56,
        is_closed_position: true,
        miner_hotkey: "5Dxqzduahnqw8q3XSUfTcEZGU7xmAsfJubhHZwvXVLN9fSjR",
        net_leverage: 0.0,
        open_ms: 1716389852239,
        orders: [
          {
            leverage: -0.1,
            order_type: "SHORT",
            order_uuid: "45e49e27-3576-4f96-abde-e63bece278d0",
            price: 70110.56,
            price_sources: [],
            processed_ms: 1716389849560,
          },
          {
            leverage: 0.1,
            order_type: "FLAT",
            order_uuid: "1d4475c3-8c91-4090-81ea-266a5f061d28",
            price: 70279.79,
            price_sources: [],
            processed_ms: 1716390381218,
          },
        ],
        position_type: "FLAT",
        position_uuid: "5ad6d86a-c7a0-47eb-aa39-78c9913c8408",
        return_at_close: 0.9996586482307374,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 72127.93000000007,
        close_ms: 1716407478426,
        current_return: 1.00361532609143,
        initial_entry_price: 69977.92,
        is_closed_position: true,
        miner_hotkey: "5Dxqzduahnqw8q3XSUfTcEZGU7xmAsfJubhHZwvXVLN9fSjR",
        net_leverage: 0.0,
        open_ms: 1716390634383,
        orders: [
          {
            leverage: -0.2,
            order_type: "SHORT",
            order_uuid: "a4a60286-4246-4601-b13f-21deec85630d",
            price: 69977.92,
            price_sources: [],
            processed_ms: 1716390631486,
          },
          {
            leverage: -0.1,
            order_type: "SHORT",
            order_uuid: "045d2079-cf6c-4386-8403-ccbb1689e3e4",
            price: 70102.0,
            price_sources: [],
            processed_ms: 1716391358087,
          },
          {
            leverage: -0.1,
            order_type: "SHORT",
            order_uuid: "8ad8fc7c-9cae-438d-9398-00c94f811b29",
            price: 70362.09,
            price_sources: [],
            processed_ms: 1716392527717,
          },
          {
            leverage: -0.1,
            order_type: "SHORT",
            order_uuid: "296ee25e-e3a9-41af-9f05-c1219efe9aed",
            price: 70488.0,
            price_sources: [],
            processed_ms: 1716392741347,
          },
          {
            leverage: 0.4,
            order_type: "LONG",
            order_uuid: "1cf33109-c61e-40f6-ac97-394388d2c775",
            price: 69695.0,
            price_sources: [],
            processed_ms: 1716403751798,
          },
          {
            leverage: 0.1,
            order_type: "FLAT",
            order_uuid: "b9783cdd-c271-496f-bf89-834a216cf785",
            price: 69598.0,
            price_sources: [],
            processed_ms: 1716407478426,
          },
        ],
        position_type: "FLAT",
        position_uuid: "562a85c7-bc35-421e-af39-87a4adc91265",
        return_at_close: 1.0031135184283844,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 68023.81499999999,
        close_ms: 1716478659547,
        current_return: 1.0005765256941705,
        initial_entry_price: 67939.73,
        is_closed_position: true,
        miner_hotkey: "5Dxqzduahnqw8q3XSUfTcEZGU7xmAsfJubhHZwvXVLN9fSjR",
        net_leverage: 0.0,
        open_ms: 1716476841165,
        orders: [
          {
            leverage: 0.1,
            order_type: "LONG",
            order_uuid: "4555d101-b2a1-45b6-9b75-47b97c47a7d7",
            price: 67939.73,
            price_sources: [],
            processed_ms: 1716476824871,
          },
          {
            leverage: 0.1,
            order_type: "LONG",
            order_uuid: "f61cc76a-06bd-4db6-abdc-201deb5a05cd",
            price: 68107.9,
            price_sources: [],
            processed_ms: 1716477397937,
          },
          {
            leverage: 0.1,
            order_type: "FLAT",
            order_uuid: "fb96d8bb-e83f-472c-b71f-2aa70a2c5276",
            price: 68219.66,
            price_sources: [],
            processed_ms: 1716478659547,
          },
        ],
        position_type: "FLAT",
        position_uuid: "58cb33a1-d935-4ca6-ae84-b85620bf720e",
        return_at_close: 1.0003764103890318,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 66732.62400000001,
        close_ms: 1716495456605,
        current_return: 1.0030934330569536,
        initial_entry_price: 66739.12,
        is_closed_position: true,
        miner_hotkey: "5Dxqzduahnqw8q3XSUfTcEZGU7xmAsfJubhHZwvXVLN9fSjR",
        net_leverage: 0.0,
        open_ms: 1716494557175,
        orders: [
          {
            leverage: 0.1,
            order_type: "LONG",
            order_uuid: "71105276-dafb-414e-9b8b-91d0c097abab",
            price: 66739.12,
            price_sources: [],
            processed_ms: 1716494556830,
          },
          {
            leverage: 0.4,
            order_type: "LONG",
            order_uuid: "a2a45a68-28c7-46ec-843b-17b8d4d40bbb",
            price: 66731.0,
            price_sources: [],
            processed_ms: 1716495331712,
          },
          {
            leverage: 0.1,
            order_type: "FLAT",
            order_uuid: "f0102436-1a72-4326-bd9a-0f545db774f9",
            price: 67145.53,
            price_sources: [],
            processed_ms: 1716495456605,
          },
        ],
        position_type: "FLAT",
        position_uuid: "0fe21662-19fc-4ead-bd0c-de081b956c5e",
        return_at_close: 1.0025918863404253,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 68217.45,
        close_ms: 1716568496971,
        current_return: 1.0000304565761593,
        initial_entry_price: 68261.12,
        is_closed_position: true,
        miner_hotkey: "5Dxqzduahnqw8q3XSUfTcEZGU7xmAsfJubhHZwvXVLN9fSjR",
        net_leverage: 0.0,
        open_ms: 1716563551258,
        orders: [
          {
            leverage: 0.05,
            order_type: "LONG",
            order_uuid: "ecddf5ad-5997-4385-8abc-35dfc4039ba2",
            price: 68261.12,
            price_sources: [],
            processed_ms: 1716563550757,
          },
          {
            leverage: 0.05,
            order_type: "LONG",
            order_uuid: "f5ee88a3-4639-4463-8478-43cbade0e156",
            price: 68173.78,
            price_sources: [],
            processed_ms: 1716566840351,
          },
          {
            leverage: 0.1,
            order_type: "FLAT",
            order_uuid: "0744b04a-96ad-4861-bcc9-291666ca6a02",
            price: 68238.24,
            price_sources: [],
            processed_ms: 1716568496971,
          },
        ],
        position_type: "FLAT",
        position_uuid: "6c6ad85e-f665-43e5-a44d-b51c7ad3a2bb",
        return_at_close: 0.9999304535305017,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 69112.78400000001,
        close_ms: 1716581935968,
        current_return: 1.0011924016920628,
        initial_entry_price: 68862.7,
        is_closed_position: true,
        miner_hotkey: "5Dxqzduahnqw8q3XSUfTcEZGU7xmAsfJubhHZwvXVLN9fSjR",
        net_leverage: 0.0,
        open_ms: 1716577521479,
        orders: [
          {
            leverage: -0.1,
            order_type: "SHORT",
            order_uuid: "a2b682d9-ec14-4bdf-9c44-6bd0ee74c356",
            price: 68862.7,
            price_sources: [],
            processed_ms: 1716577520535,
          },
          {
            leverage: -0.1,
            order_type: "SHORT",
            order_uuid: "4297ab34-6786-4ef8-8ab2-9cc515aebe67",
            price: 68961.15,
            price_sources: [],
            processed_ms: 1716578417008,
          },
          {
            leverage: -0.3,
            order_type: "SHORT",
            order_uuid: "21e05e7d-5359-4f2f-b8c6-f45d9ae14347",
            price: 69246.69,
            price_sources: [],
            processed_ms: 1716580773575,
          },
          {
            leverage: 0.1,
            order_type: "FLAT",
            order_uuid: "8c02e1cc-c19d-4b43-a00f-2cfebc9da234",
            price: 68948.56,
            price_sources: [],
            processed_ms: 1716581935968,
          },
        ],
        position_type: "FLAT",
        position_uuid: "14d4130d-605a-4f20-af62-508ed9af2914",
        return_at_close: 1.0006918054912168,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 68551.9,
        close_ms: 1716773734537,
        current_return: 1.0002733841075155,
        initial_entry_price: 68551.9,
        is_closed_position: true,
        miner_hotkey: "5Dxqzduahnqw8q3XSUfTcEZGU7xmAsfJubhHZwvXVLN9fSjR",
        net_leverage: 0.0,
        open_ms: 1716769314904,
        orders: [
          {
            leverage: 0.1,
            order_type: "LONG",
            order_uuid: "66d73aaf-f46f-496c-9124-9207dc016aaa",
            price: 68551.9,
            price_sources: [],
            processed_ms: 1716769314443,
          },
          {
            leverage: 0.1,
            order_type: "FLAT",
            order_uuid: "7c3e7764-a244-4ba1-b8e4-0b74e5945084",
            price: 68739.31,
            price_sources: [],
            processed_ms: 1716773734537,
          },
        ],
        position_type: "FLAT",
        position_uuid: "89cdec5e-e43e-4835-a175-b580d7905826",
        return_at_close: 1.000173356769105,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 69976.33,
        close_ms: 1716831103029,
        current_return: 1.0002599879130558,
        initial_entry_price: 69976.33,
        is_closed_position: true,
        miner_hotkey: "5Dxqzduahnqw8q3XSUfTcEZGU7xmAsfJubhHZwvXVLN9fSjR",
        net_leverage: 0.0,
        open_ms: 1716830677802,
        orders: [
          {
            leverage: 0.1,
            order_type: "LONG",
            order_uuid: "3a1a6248-5128-4415-800f-9aebfc18e1aa",
            price: 69976.33,
            price_sources: [],
            processed_ms: 1716830677149,
          },
          {
            leverage: 0.1,
            order_type: "FLAT",
            order_uuid: "b2d90201-2319-4d4d-b204-c4bce4be7b93",
            price: 70158.26,
            price_sources: [],
            processed_ms: 1716831103029,
          },
        ],
        position_type: "FLAT",
        position_uuid: "89c90aea-63ec-4ad1-aac9-a21cd6ee2d63",
        return_at_close: 1.0001599619142645,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 69340.59,
        close_ms: 1716860211853,
        current_return: 1.0002298942077072,
        initial_entry_price: 69340.59,
        is_closed_position: true,
        miner_hotkey: "5Dxqzduahnqw8q3XSUfTcEZGU7xmAsfJubhHZwvXVLN9fSjR",
        net_leverage: 0.0,
        open_ms: 1716855771885,
        orders: [
          {
            leverage: 0.1,
            order_type: "LONG",
            order_uuid: "e38d887c-2211-4db3-adec-783ffea0d7ae",
            price: 69340.59,
            price_sources: [],
            processed_ms: 1716855771499,
          },
          {
            leverage: 0.1,
            order_type: "FLAT",
            order_uuid: "372188f6-2dcc-417e-842d-4112e95ff5a3",
            price: 69500.0,
            price_sources: [],
            processed_ms: 1716860211853,
          },
        ],
        position_type: "FLAT",
        position_uuid: "77fffab3-f6e9-4d8a-9c0f-ec3777ad55e2",
        return_at_close: 1.0001298712182864,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 68519.76,
        close_ms: 1716862369083,
        current_return: 1.0002449267566667,
        initial_entry_price: 68600.1,
        is_closed_position: true,
        miner_hotkey: "5Dxqzduahnqw8q3XSUfTcEZGU7xmAsfJubhHZwvXVLN9fSjR",
        net_leverage: 0.0,
        open_ms: 1716861050841,
        orders: [
          {
            leverage: 0.1,
            order_type: "LONG",
            order_uuid: "6d7cb7d4-7a11-4741-ab83-2ca89c36df9d",
            price: 68600.1,
            price_sources: [],
            processed_ms: 1716861050664,
          },
          {
            leverage: 0.1,
            order_type: "LONG",
            order_uuid: "064b5290-996e-462b-bbaf-7f30b402ba52",
            price: 68439.42,
            price_sources: [],
            processed_ms: 1716862080407,
          },
          {
            leverage: 0.1,
            order_type: "FLAT",
            order_uuid: "360a8685-0af6-4a76-a0a3-758fe08e1b21",
            price: 68603.77,
            price_sources: [],
            processed_ms: 1716862369083,
          },
        ],
        position_type: "FLAT",
        position_uuid: "1b320b64-966e-4ac2-83cb-cc62302fc98d",
        return_at_close: 1.0000448777713153,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 67470.46,
        close_ms: 1716922500157,
        current_return: 1.0003075123542955,
        initial_entry_price: 67470.46,
        is_closed_position: true,
        miner_hotkey: "5Dxqzduahnqw8q3XSUfTcEZGU7xmAsfJubhHZwvXVLN9fSjR",
        net_leverage: 0.0,
        open_ms: 1716921934415,
        orders: [
          {
            leverage: 0.2,
            order_type: "LONG",
            order_uuid: "829ec141-f071-4bbf-94a0-713c692d14f0",
            price: 67470.46,
            price_sources: [],
            processed_ms: 1716921926267,
          },
          {
            leverage: 0.1,
            order_type: "FLAT",
            order_uuid: "fc99ee41-250e-4933-b6eb-8b928651ccc4",
            price: 67574.2,
            price_sources: [],
            processed_ms: 1716922500157,
          },
        ],
        position_type: "FLAT",
        position_uuid: "22cd8d81-3ee7-4213-85fa-4d97d0ed92c9",
        return_at_close: 1.0001074508518246,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 66716.67999999985,
        close_ms: 1716958777236,
        current_return: 0.99698058798851,
        initial_entry_price: 67837.38,
        is_closed_position: true,
        miner_hotkey: "5Dxqzduahnqw8q3XSUfTcEZGU7xmAsfJubhHZwvXVLN9fSjR",
        net_leverage: 0.0,
        open_ms: 1716922845070,
        orders: [
          {
            leverage: -0.1,
            order_type: "SHORT",
            order_uuid: "141e6c7e-4704-4fa9-9838-3646ce20f4f3",
            price: 67837.38,
            price_sources: [],
            processed_ms: 1716922844397,
          },
          {
            leverage: -0.1,
            order_type: "SHORT",
            order_uuid: "1f55677c-8433-40b0-ae4a-b36817112906",
            price: 68103.0,
            price_sources: [],
            processed_ms: 1716923749991,
          },
          {
            leverage: -0.1,
            order_type: "SHORT",
            order_uuid: "3b06be4c-a616-4417-b0f8-4c78d329cf9a",
            price: 68398.41,
            price_sources: [],
            processed_ms: 1716925967002,
          },
          {
            leverage: -0.1,
            order_type: "SHORT",
            order_uuid: "d3714efa-0f6d-4b7c-88ac-8753bfbac2dd",
            price: 68489.6,
            price_sources: [],
            processed_ms: 1716934460442,
          },
          {
            leverage: -0.1,
            order_type: "SHORT",
            order_uuid: "7392b501-b219-4cc8-8e0e-895cc44e1014",
            price: 68949.0,
            price_sources: [],
            processed_ms: 1716949785269,
          },
          {
            leverage: 0.1,
            order_type: "LONG",
            order_uuid: "217fa5fc-bb89-4b67-a664-93d8fb6a0e6b",
            price: 68737.57,
            price_sources: [],
            processed_ms: 1716954678794,
          },
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "d23399ef-a463-4cb1-9037-c26cb3df6295",
            price: 68774.38,
            price_sources: [],
            processed_ms: 1716957199097,
          },
          {
            leverage: 0.1,
            order_type: "FLAT",
            order_uuid: "2651c7d5-47ad-4b30-b83d-d6b36d79e090",
            price: 68764.97,
            price_sources: [],
            processed_ms: 1716958777236,
          },
        ],
        position_type: "FLAT",
        position_uuid: "372407a9-99b0-4eba-a971-493bfad5c1cb",
        return_at_close: 0.9964820976945158,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 67441.03666666667,
        close_ms: 1717010957394,
        current_return: 1.000891480760151,
        initial_entry_price: 67776.0,
        is_closed_position: true,
        miner_hotkey: "5Dxqzduahnqw8q3XSUfTcEZGU7xmAsfJubhHZwvXVLN9fSjR",
        net_leverage: 0.0,
        open_ms: 1716996028641,
        orders: [
          {
            leverage: 0.1,
            order_type: "LONG",
            order_uuid: "41532043-4a87-41e6-9529-7d4fc090f633",
            price: 67776.0,
            price_sources: [],
            processed_ms: 1716996026587,
          },
          {
            leverage: 0.1,
            order_type: "LONG",
            order_uuid: "21d1c6e4-09b8-4812-9ac1-ef3d6f3427aa",
            price: 67366.77,
            price_sources: [],
            processed_ms: 1716997141450,
          },
          {
            leverage: 0.1,
            order_type: "LONG",
            order_uuid: "c92b21c9-68a8-4ccf-811c-c8a669caed83",
            price: 67180.34,
            price_sources: [],
            processed_ms: 1716998671455,
          },
          {
            leverage: 0.1,
            order_type: "FLAT",
            order_uuid: "a22ba36c-9c89-4fe9-b2b1-ad7fcb49aa2f",
            price: 67642.44,
            price_sources: [],
            processed_ms: 1717010957394,
          },
        ],
        position_type: "FLAT",
        position_uuid: "9d31fd9d-37f7-49d4-8605-c60ac5c57ed6",
        return_at_close: 1.000591213315923,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 67414.68,
        close_ms: 1717018893732,
        current_return: 1.0001969600686378,
        initial_entry_price: 67414.68,
        is_closed_position: true,
        miner_hotkey: "5Dxqzduahnqw8q3XSUfTcEZGU7xmAsfJubhHZwvXVLN9fSjR",
        net_leverage: 0.0,
        open_ms: 1717011575338,
        orders: [
          {
            leverage: 0.1,
            order_type: "LONG",
            order_uuid: "95a3121e-243f-43a8-9de9-1890b5db6078",
            price: 67414.68,
            price_sources: [],
            processed_ms: 1717011570233,
          },
          {
            leverage: 0.1,
            order_type: "FLAT",
            order_uuid: "0463d54c-5e26-4531-99e4-523373c6cba6",
            price: 67547.46,
            price_sources: [],
            processed_ms: 1717018893732,
          },
        ],
        position_type: "FLAT",
        position_uuid: "d6602c3d-5dc3-4a95-a8a7-6d8b39cf3a55",
        return_at_close: 1.000096940372631,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 68003.35,
        close_ms: 1717075187331,
        current_return: 1.0005121071241343,
        initial_entry_price: 68003.35,
        is_closed_position: true,
        miner_hotkey: "5Dxqzduahnqw8q3XSUfTcEZGU7xmAsfJubhHZwvXVLN9fSjR",
        net_leverage: 0.0,
        open_ms: 1717040429441,
        orders: [
          {
            leverage: 0.1,
            order_type: "LONG",
            order_uuid: "e27ea2d7-fd78-463e-8a7d-fd1b4c25b7f6",
            price: 68003.35,
            price_sources: [],
            processed_ms: 1717040427575,
          },
          {
            leverage: 0.1,
            order_type: "FLAT",
            order_uuid: "9900e7d3-bcf7-4cff-81e6-88c09c147e0f",
            price: 68351.6,
            price_sources: [],
            processed_ms: 1717075187331,
          },
        ],
        position_type: "FLAT",
        position_uuid: "e0dd327f-b2cd-4b25-a0ee-94f64a0a7750",
        return_at_close: 1.000412055913422,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 68618.055,
        close_ms: 1717090027891,
        current_return: 1.0006437495260607,
        initial_entry_price: 68706.07,
        is_closed_position: true,
        miner_hotkey: "5Dxqzduahnqw8q3XSUfTcEZGU7xmAsfJubhHZwvXVLN9fSjR",
        net_leverage: 0.0,
        open_ms: 1717079117134,
        orders: [
          {
            leverage: 0.05,
            order_type: "LONG",
            order_uuid: "04b24e30-eadf-4539-910b-de72e1c875f1",
            price: 68706.07,
            price_sources: [],
            processed_ms: 1717079116730,
          },
          {
            leverage: 0.05,
            order_type: "LONG",
            order_uuid: "f664c33f-7c1b-4de2-bc8c-f3f88e280054",
            price: 68530.04,
            price_sources: [],
            processed_ms: 1717079993570,
          },
          {
            leverage: 0.1,
            order_type: "FLAT",
            order_uuid: "58332653-0cb2-4817-92ce-0acad8671954",
            price: 69060.35,
            price_sources: [],
            processed_ms: 1717090027891,
          },
        ],
        position_type: "FLAT",
        position_uuid: "897f08d1-cd03-4853-abd9-104fffb0ceee",
        return_at_close: 1.000543685151108,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 68011.87,
        close_ms: 1717380275804,
        current_return: 1.002684745762712,
        initial_entry_price: 68440.0,
        is_closed_position: true,
        miner_hotkey: "5Dxqzduahnqw8q3XSUfTcEZGU7xmAsfJubhHZwvXVLN9fSjR",
        net_leverage: 0.0,
        open_ms: 1717101146130,
        orders: [
          {
            leverage: 0.1,
            order_type: "LONG",
            order_uuid: "56619d6e-98ab-4e72-bc55-108a0b56e525",
            price: 68440.0,
            price_sources: [],
            processed_ms: 1717101145801,
          },
          {
            leverage: 0.1,
            order_type: "LONG",
            order_uuid: "98ffccb6-84ca-4857-94ca-c971033199c4",
            price: 68139.6,
            price_sources: [],
            processed_ms: 1717141825986,
          },
          {
            leverage: 0.1,
            order_type: "LONG",
            order_uuid: "177829db-88b4-4fb0-96e7-7ec673f556d2",
            price: 67456.01,
            price_sources: [],
            processed_ms: 1717167691814,
          },
          {
            leverage: 0.1,
            order_type: "FLAT",
            order_uuid: "9ed99931-6531-4518-a405-fc1690dba43a",
            price: 68624.35,
            price_sources: [],
            processed_ms: 1717380275804,
          },
        ],
        position_type: "FLAT",
        position_uuid: "36a581ad-406b-4d85-ba42-6713ca138b6e",
        return_at_close: 1.0023839403389831,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 70872.85333333333,
        close_ms: 1717528728262,
        current_return: 1.00112933490616,
        initial_entry_price: 70895.71,
        is_closed_position: true,
        miner_hotkey: "5Dxqzduahnqw8q3XSUfTcEZGU7xmAsfJubhHZwvXVLN9fSjR",
        net_leverage: 0.0,
        open_ms: 1717519746866,
        orders: [
          {
            leverage: -0.1,
            order_type: "SHORT",
            order_uuid: "a499adf2-f2db-4c80-9011-bffdbfeb819c",
            price: 70895.71,
            price_sources: [],
            processed_ms: 1717519729452,
          },
          {
            leverage: -0.1,
            order_type: "SHORT",
            order_uuid: "07df1661-a37b-404c-bc03-6de05c1be988",
            price: 70782.58,
            price_sources: [],
            processed_ms: 1717520286228,
          },
          {
            leverage: -0.1,
            order_type: "SHORT",
            order_uuid: "476766ff-8ae8-4941-8bc9-c8589cb2d0b6",
            price: 70940.27,
            price_sources: [],
            processed_ms: 1717525615398,
          },
          {
            leverage: 0.1,
            order_type: "FLAT",
            order_uuid: "0cab689c-8bf2-4cf2-88d2-18883dc20dd4",
            price: 70605.97,
            price_sources: [],
            processed_ms: 1717528728262,
          },
        ],
        position_type: "FLAT",
        position_uuid: "64d2c8e7-05dc-478c-a357-2e3f6c4b6e7c",
        return_at_close: 1.0008289961056882,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 71634.0608000001,
        close_ms: 1717781473434,
        current_return: 1.0022040304404887,
        initial_entry_price: 70104.0,
        is_closed_position: true,
        miner_hotkey: "5Dxqzduahnqw8q3XSUfTcEZGU7xmAsfJubhHZwvXVLN9fSjR",
        net_leverage: 0.0,
        open_ms: 1717529936601,
        orders: [
          {
            leverage: -0.1,
            order_type: "SHORT",
            order_uuid: "a5bb1044-3909-41f2-b27e-0febb1a65a73",
            price: 70104.0,
            price_sources: [],
            processed_ms: 1717529936050,
          },
          {
            leverage: -0.05,
            order_type: "SHORT",
            order_uuid: "2d639acf-1ea6-422c-be50-35eeef772064",
            price: 70290.4,
            price_sources: [],
            processed_ms: 1717530262053,
          },
          {
            leverage: -0.05,
            order_type: "SHORT",
            order_uuid: "4efa682e-0626-4e45-85ef-ff188df54019",
            price: 70496.0,
            price_sources: [],
            processed_ms: 1717534346724,
          },
          {
            leverage: -0.05,
            order_type: "SHORT",
            order_uuid: "e96d8529-3719-44a3-aa95-bb779abaabd5",
            price: 70726.94,
            price_sources: [],
            processed_ms: 1717539394691,
          },
          {
            leverage: -0.05,
            order_type: "SHORT",
            order_uuid: "a4aceaab-1027-4926-a7bb-e366a6647289",
            price: 70831.19,
            price_sources: [],
            processed_ms: 1717548938824,
          },
          {
            leverage: -0.05,
            order_type: "SHORT",
            order_uuid: "2f31734d-02d2-467d-b630-34e8453e50f4",
            price: 71026.22,
            price_sources: [],
            processed_ms: 1717551291834,
          },
          {
            leverage: -0.05,
            order_type: "SHORT",
            order_uuid: "3f20dc3b-4d37-4c02-a395-801af23729ea",
            price: 71109.93,
            price_sources: [],
            processed_ms: 1717551676737,
          },
          {
            leverage: -0.05,
            order_type: "SHORT",
            order_uuid: "10462745-8843-4226-8dc1-cceeea343cb4",
            price: 71450.0,
            price_sources: [],
            processed_ms: 1717602457836,
          },
          {
            leverage: -0.1,
            order_type: "SHORT",
            order_uuid: "cf54ccda-6dfd-4779-a166-8b7b47d45118",
            price: 71175.6,
            price_sources: [],
            processed_ms: 1717632731766,
          },
          {
            leverage: 0.05,
            order_type: "LONG",
            order_uuid: "8b0ce29f-e974-4e21-a595-54a0afd0890e",
            price: 70457.08,
            price_sources: [],
            processed_ms: 1717703934460,
          },
          {
            leverage: 0.05,
            order_type: "LONG",
            order_uuid: "68ed8f70-f289-409f-be0d-5165cefe25e8",
            price: 70222.0,
            price_sources: [],
            processed_ms: 1717704094605,
          },
          {
            leverage: 0.05,
            order_type: "LONG",
            order_uuid: "15d307e7-d584-4d49-9756-9a18bdf52585",
            price: 70715.3,
            price_sources: [],
            processed_ms: 1717779920869,
          },
          {
            leverage: 0.05,
            order_type: "LONG",
            order_uuid: "fbbe3f9b-b747-43bb-a058-3d8d11e93b82",
            price: 70716.57,
            price_sources: [],
            processed_ms: 1717780131625,
          },
          {
            leverage: 0.05,
            order_type: "LONG",
            order_uuid: "1081b32b-5a99-47a2-9395-d7008528cc43",
            price: 70669.57,
            price_sources: [],
            processed_ms: 1717780272829,
          },
          {
            leverage: 0.05,
            order_type: "LONG",
            order_uuid: "deecbbe0-3101-4f12-91d3-d2e541a667af",
            price: 70616.17,
            price_sources: [],
            processed_ms: 1717780415452,
          },
          {
            leverage: 0.03,
            order_type: "LONG",
            order_uuid: "8a0cd7f5-7bee-474a-8a6b-9da52376e39e",
            price: 70507.98,
            price_sources: [],
            processed_ms: 1717780566109,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "cebdc5ae-413d-47a9-acf2-89379ddf6e9d",
            price: 70491.99,
            price_sources: [],
            processed_ms: 1717780649578,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "8a4b557d-8654-49ea-a119-91dd0649790d",
            price: 70451.41,
            price_sources: [],
            processed_ms: 1717780841069,
          },
          {
            leverage: 0.025,
            order_type: "LONG",
            order_uuid: "c5ad9c02-8f93-434e-a96d-db9550fc3eef",
            price: 70404.0,
            price_sources: [],
            processed_ms: 1717780911647,
          },
          {
            leverage: 0.025,
            order_type: "LONG",
            order_uuid: "2d45477f-2082-478d-bf90-68ba10506ee4",
            price: 70293.77,
            price_sources: [],
            processed_ms: 1717781110027,
          },
          {
            leverage: 0.025,
            order_type: "LONG",
            order_uuid: "31f2ff3a-4c45-4f2b-add6-bc4529f52a68",
            price: 70331.37,
            price_sources: [],
            processed_ms: 1717781202032,
          },
          {
            leverage: 0.1,
            order_type: "FLAT",
            order_uuid: "05869730-beb7-4c29-bd93-3f1977865ffe",
            price: 70397.97,
            price_sources: [],
            processed_ms: 1717781473434,
          },
        ],
        position_type: "FLAT",
        position_uuid: "5494be63-4fa3-4ebd-be73-30a685618768",
        return_at_close: 1.0016528182237463,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 70192.77,
        close_ms: 1717782239798,
        current_return: 1.0000580829051198,
        initial_entry_price: 70192.77,
        is_closed_position: true,
        miner_hotkey: "5Dxqzduahnqw8q3XSUfTcEZGU7xmAsfJubhHZwvXVLN9fSjR",
        net_leverage: 0.0,
        open_ms: 1717781920036,
        orders: [
          {
            leverage: -0.1,
            order_type: "SHORT",
            order_uuid: "a0bd53fa-8c2d-4dae-8f53-6746c884b769",
            price: 70192.77,
            price_sources: [],
            processed_ms: 1717781919786,
          },
          {
            leverage: 0.1,
            order_type: "FLAT",
            order_uuid: "657ebb40-1fe9-4ccf-aaf0-42f89c9c85af",
            price: 70152.0,
            price_sources: [],
            processed_ms: 1717782239798,
          },
        ],
        position_type: "FLAT",
        position_uuid: "8e4480d6-4899-4677-90d4-90664b00d04f",
        return_at_close: 0.9999580770968293,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 69024.10800000001,
        close_ms: 1717784681690,
        current_return: 1.0006028241242326,
        initial_entry_price: 69225.0,
        is_closed_position: true,
        miner_hotkey: "5Dxqzduahnqw8q3XSUfTcEZGU7xmAsfJubhHZwvXVLN9fSjR",
        net_leverage: 0.0,
        open_ms: 1717783544382,
        orders: [
          {
            leverage: 0.1,
            order_type: "LONG",
            order_uuid: "dab638ea-be7f-4e26-b8fa-075397337fe8",
            price: 69225.0,
            price_sources: [],
            processed_ms: 1717783534823,
          },
          {
            leverage: 0.05,
            order_type: "LONG",
            order_uuid: "ab6f5448-5fa0-4cbe-ad6e-c3ddadf7c08f",
            price: 69089.84,
            price_sources: [],
            processed_ms: 1717783928037,
          },
          {
            leverage: 0.05,
            order_type: "LONG",
            order_uuid: "573cfa53-71a1-4757-829c-20fac9ee6c5e",
            price: 68954.0,
            price_sources: [],
            processed_ms: 1717784027708,
          },
          {
            leverage: 0.05,
            order_type: "LONG",
            order_uuid: "01d482a6-d9ee-4061-abbf-ceaaa61c1c60",
            price: 68626.7,
            price_sources: [],
            processed_ms: 1717784128327,
          },
          {
            leverage: 0.1,
            order_type: "FLAT",
            order_uuid: "0d743758-0bf8-4bdf-b7af-9b4b97f7b52d",
            price: 69191.03,
            price_sources: [],
            processed_ms: 1717784681690,
          },
        ],
        position_type: "FLAT",
        position_uuid: "663f646a-17e5-449b-b5b4-dd73ad8645a4",
        return_at_close: 1.0003526734182016,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 69422.7,
        close_ms: 1717817956164,
        current_return: 1.0000311857648867,
        initial_entry_price: 69422.7,
        is_closed_position: true,
        miner_hotkey: "5Dxqzduahnqw8q3XSUfTcEZGU7xmAsfJubhHZwvXVLN9fSjR",
        net_leverage: 0.0,
        open_ms: 1717800864273,
        orders: [
          {
            leverage: 0.05,
            order_type: "LONG",
            order_uuid: "20955b0a-3665-4ffa-991a-edc587d91d39",
            price: 69422.7,
            price_sources: [],
            processed_ms: 1717800863711,
          },
          {
            leverage: 0.05,
            order_type: "FLAT",
            order_uuid: "6599e928-2189-487b-bd8d-68d75b731fc7",
            price: 69466.0,
            price_sources: [],
            processed_ms: 1717817956164,
          },
        ],
        position_type: "FLAT",
        position_uuid: "94cf945a-774f-4117-9712-3217a7e503e1",
        return_at_close: 0.9999811842055985,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 66525.57,
        close_ms: 1718130384327,
        current_return: 1.0007036087352201,
        initial_entry_price: 66731.69,
        is_closed_position: true,
        miner_hotkey: "5Dxqzduahnqw8q3XSUfTcEZGU7xmAsfJubhHZwvXVLN9fSjR",
        net_leverage: 0.0,
        open_ms: 1718128765171,
        orders: [
          {
            leverage: 0.2,
            order_type: "LONG",
            order_uuid: "99bb057a-bd43-4299-a87b-498d9582e0bd",
            price: 66731.69,
            price_sources: [],
            processed_ms: 1718128764601,
          },
          {
            leverage: -0.1,
            order_type: "SHORT",
            order_uuid: "bee123c3-11e4-4d19-9b29-744b9fca573d",
            price: 66937.81,
            price_sources: [],
            processed_ms: 1718130064936,
          },
          {
            leverage: 0.1,
            order_type: "FLAT",
            order_uuid: "72ceb610-3f57-4da2-b08e-38fd39a1bcee",
            price: 66995.1,
            price_sources: [],
            processed_ms: 1718130384327,
          },
        ],
        position_type: "FLAT",
        position_uuid: "7d164f00-cdc9-4fcf-9ed8-b1f9e614ec41",
        return_at_close: 1.000503468013473,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 67190.91,
        close_ms: 1718135508198,
        current_return: 1.0004750940268556,
        initial_entry_price: 67190.91,
        is_closed_position: true,
        miner_hotkey: "5Dxqzduahnqw8q3XSUfTcEZGU7xmAsfJubhHZwvXVLN9fSjR",
        net_leverage: 0.0,
        open_ms: 1718131268829,
        orders: [
          {
            leverage: 0.1,
            order_type: "LONG",
            order_uuid: "e6c87d08-0163-43cf-8db3-c9b0ed96fc7b",
            price: 67190.91,
            price_sources: [],
            processed_ms: 1718131268380,
          },
          {
            leverage: 0.05,
            order_type: "FLAT",
            order_uuid: "9104ef33-cc24-48d2-af5b-804636d01b37",
            price: 67510.13,
            price_sources: [],
            processed_ms: 1718135508198,
          },
        ],
        position_type: "FLAT",
        position_uuid: "33bb5173-0a45-419c-98d7-7fe38ad8eccc",
        return_at_close: 1.000375046517453,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 67217.86,
        close_ms: 1718143999970,
        current_return: 1.000226680825602,
        initial_entry_price: 67217.86,
        is_closed_position: true,
        miner_hotkey: "5Dxqzduahnqw8q3XSUfTcEZGU7xmAsfJubhHZwvXVLN9fSjR",
        net_leverage: 0.0,
        open_ms: 1718139173983,
        orders: [
          {
            leverage: 0.05,
            order_type: "LONG",
            order_uuid: "72252937-2105-4eb7-8726-2ba7de9c61b7",
            price: 67217.86,
            price_sources: [],
            processed_ms: 1718139166352,
          },
          {
            leverage: 0.05,
            order_type: "FLAT",
            order_uuid: "035e5c9e-f226-4835-8efd-f5f215cdb788",
            price: 67522.6,
            price_sources: [],
            processed_ms: 1718143999970,
          },
        ],
        position_type: "FLAT",
        position_uuid: "81c867ba-576b-4023-b6db-589bdd5a997a",
        return_at_close: 1.0001766694915606,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 66723.4,
        close_ms: 1718302545097,
        current_return: 1.0003223157093313,
        initial_entry_price: 66723.4,
        is_closed_position: true,
        miner_hotkey: "5Dxqzduahnqw8q3XSUfTcEZGU7xmAsfJubhHZwvXVLN9fSjR",
        net_leverage: 0.0,
        open_ms: 1718299830904,
        orders: [
          {
            leverage: 0.1,
            order_type: "LONG",
            order_uuid: "64bddf68-d2f8-4893-93d0-13680ae461d2",
            price: 66723.4,
            price_sources: [],
            processed_ms: 1718299829284,
          },
          {
            leverage: 0.05,
            order_type: "FLAT",
            order_uuid: "22c8b5ab-1284-4236-8e47-54b5625f6fc3",
            price: 66938.46,
            price_sources: [],
            processed_ms: 1718302545097,
          },
        ],
        position_type: "FLAT",
        position_uuid: "ae78e864-64b0-464c-8555-646b467ad492",
        return_at_close: 1.0002222834777603,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 66673.0,
        close_ms: 1718315523064,
        current_return: 1.0005894306630878,
        initial_entry_price: 66806.84,
        is_closed_position: true,
        miner_hotkey: "5Dxqzduahnqw8q3XSUfTcEZGU7xmAsfJubhHZwvXVLN9fSjR",
        net_leverage: 0.0,
        open_ms: 1718303675426,
        orders: [
          {
            leverage: 0.05,
            order_type: "LONG",
            order_uuid: "45750e7a-11b3-42d3-b761-be9a658017fa",
            price: 66806.84,
            price_sources: [],
            processed_ms: 1718303673749,
          },
          {
            leverage: 0.1,
            order_type: "LONG",
            order_uuid: "1dafad96-451a-4e95-806c-197f75ba7c42",
            price: 66606.08,
            price_sources: [],
            processed_ms: 1718307366028,
          },
          {
            leverage: 0.05,
            order_type: "FLAT",
            order_uuid: "59c00e7f-354e-4a71-a709-e5bbcfc1c7ee",
            price: 66935.52,
            price_sources: [],
            processed_ms: 1718315523064,
          },
        ],
        position_type: "FLAT",
        position_uuid: "a6cde129-dc0c-4375-893b-5d1d19103e9b",
        return_at_close: 1.0004393422484883,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 66707.49333333332,
        close_ms: 1718343612595,
        current_return: 1.0031445023093637,
        initial_entry_price: 66879.9,
        is_closed_position: true,
        miner_hotkey: "5Dxqzduahnqw8q3XSUfTcEZGU7xmAsfJubhHZwvXVLN9fSjR",
        net_leverage: 0.0,
        open_ms: 1718319447430,
        orders: [
          {
            leverage: 0.2,
            order_type: "LONG",
            order_uuid: "0118b467-6b8a-4b01-8db4-40675f0645f9",
            price: 66879.9,
            price_sources: [],
            processed_ms: 1718319445695,
          },
          {
            leverage: 0.2,
            order_type: "LONG",
            order_uuid: "66b4f9ed-2f5b-415f-98c8-d5abc10d57d9",
            price: 66722.26,
            price_sources: [],
            processed_ms: 1718319882972,
          },
          {
            leverage: 0.2,
            order_type: "LONG",
            order_uuid: "f35340e2-fa85-46e4-a510-87b0352e6004",
            price: 66520.32,
            price_sources: [],
            processed_ms: 1718327248284,
          },
          {
            leverage: 0.05,
            order_type: "FLAT",
            order_uuid: "d836e869-28c3-4641-a66e-aaf715ec1d97",
            price: 67058.0,
            price_sources: [],
            processed_ms: 1718343612595,
          },
        ],
        position_type: "FLAT",
        position_uuid: "2af9eb05-9187-4b4f-a224-0ab892708d13",
        return_at_close: 1.002542615607978,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 66695.41666666666,
        close_ms: 1718379593248,
        current_return: 1.0003758268077572,
        initial_entry_price: 66845.95,
        is_closed_position: true,
        miner_hotkey: "5Dxqzduahnqw8q3XSUfTcEZGU7xmAsfJubhHZwvXVLN9fSjR",
        net_leverage: 0.0,
        open_ms: 1718351968789,
        orders: [
          {
            leverage: 0.05,
            order_type: "LONG",
            order_uuid: "b556b0f1-8029-49a1-bcf3-c1f43b35c469",
            price: 66845.95,
            price_sources: [],
            processed_ms: 1718351967403,
          },
          {
            leverage: 0.1,
            order_type: "LONG",
            order_uuid: "f89d24f5-9106-43c8-9b51-0d584635ab4a",
            price: 66620.15,
            price_sources: [],
            processed_ms: 1718375256527,
          },
          {
            leverage: 0.1,
            order_type: "FLAT",
            order_uuid: "d54bc853-8f6a-4168-8869-fe9a3bafb307",
            price: 66862.9,
            price_sources: [],
            processed_ms: 1718379593248,
          },
        ],
        position_type: "FLAT",
        position_uuid: "2ce71ee3-7851-4d02-849f-ff2cd23d7ac1",
        return_at_close: 1.000225770433736,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 66025.23818181817,
        close_ms: 1718400707227,
        current_return: 1.001482366965049,
        initial_entry_price: 66693.0,
        is_closed_position: true,
        miner_hotkey: "5Dxqzduahnqw8q3XSUfTcEZGU7xmAsfJubhHZwvXVLN9fSjR",
        net_leverage: 0.0,
        open_ms: 1718379827899,
        orders: [
          {
            leverage: 0.05,
            order_type: "LONG",
            order_uuid: "1257f288-c542-43af-9ea6-515e7f1913b1",
            price: 66693.0,
            price_sources: [],
            processed_ms: 1718379826169,
          },
          {
            leverage: 0.1,
            order_type: "LONG",
            order_uuid: "d4b54f5b-a06a-4d22-908e-7129b2cc52b2",
            price: 66469.23,
            price_sources: [],
            processed_ms: 1718380113742,
          },
          {
            leverage: 0.15,
            order_type: "LONG",
            order_uuid: "c1a0cd13-3499-435f-a77f-ea75a93ac170",
            price: 66292.18,
            price_sources: [],
            processed_ms: 1718381141018,
          },
          {
            leverage: 0.05,
            order_type: "LONG",
            order_uuid: "41f1ad91-37eb-44a6-a599-f5187831343c",
            price: 66163.0,
            price_sources: [],
            processed_ms: 1718381291802,
          },
          {
            leverage: 0.1,
            order_type: "LONG",
            order_uuid: "724b0c50-d2fd-4b3f-922a-838378f3ff7a",
            price: 65613.0,
            price_sources: [],
            processed_ms: 1718381367928,
          },
          {
            leverage: 0.1,
            order_type: "LONG",
            order_uuid: "2af4eb47-e0ad-42e4-8e53-b1401c8c84a6",
            price: 65190.31,
            price_sources: [],
            processed_ms: 1718384556524,
          },
          {
            leverage: 0.05,
            order_type: "FLAT",
            order_uuid: "f721fdc4-1a0f-4eeb-9417-60206a7335a2",
            price: 66204.99,
            price_sources: [],
            processed_ms: 1718400707227,
          },
        ],
        position_type: "FLAT",
        position_uuid: "27abc7b2-8581-4c7b-8c40-417b64148649",
        return_at_close: 1.0009315516632182,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 3537.975,
        close_ms: 1718508933088,
        current_return: 0.9999017654079128,
        initial_entry_price: 3552.72,
        is_closed_position: true,
        miner_hotkey: "5Dxqzduahnqw8q3XSUfTcEZGU7xmAsfJubhHZwvXVLN9fSjR",
        net_leverage: 0.0,
        open_ms: 1718485219481,
        orders: [
          {
            leverage: -0.1,
            order_type: "SHORT",
            order_uuid: "a842601e-eea2-4318-b767-f40f1b55e7fe",
            price: 3552.72,
            price_sources: [],
            processed_ms: 1718485217864,
          },
          {
            leverage: 0.03,
            order_type: "LONG",
            order_uuid: "8c1b1ce5-1347-4167-bf0f-44983863ed33",
            price: 3566.48,
            price_sources: [],
            processed_ms: 1718492655400,
          },
          {
            leverage: 0.02,
            order_type: "LONG",
            order_uuid: "39b172f3-460f-4dbb-b488-8ffb1dc318a9",
            price: 3559.09,
            price_sources: [],
            processed_ms: 1718507345713,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "2892d8f6-5e2b-43b2-99fb-9a19ba3ab455",
            price: 3557.68,
            price_sources: [],
            processed_ms: 1718507743668,
          },
          {
            leverage: 0.01,
            order_type: "FLAT",
            order_uuid: "e0cfcd88-47c3-4f18-a980-af474db6a112",
            price: 3546.7,
            price_sources: [],
            processed_ms: 1718508933088,
          },
        ],
        position_type: "FLAT",
        position_uuid: "aef0e2b3-dccb-48f8-9fa9-b6c0ccaaf251",
        return_at_close: 0.9998017752313719,
        trade_pair: ["ETHUSD", "ETH/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 66024.88,
        close_ms: 1718516045200,
        current_return: 1.000285074353789,
        initial_entry_price: 66024.88,
        is_closed_position: true,
        miner_hotkey: "5Dxqzduahnqw8q3XSUfTcEZGU7xmAsfJubhHZwvXVLN9fSjR",
        net_leverage: 0.0,
        open_ms: 1718485061500,
        orders: [
          {
            leverage: 0.1,
            order_type: "LONG",
            order_uuid: "7b14cdf3-4a06-4ca9-934a-48c28bbe34a9",
            price: 66024.88,
            price_sources: [],
            processed_ms: 1718485056804,
          },
          {
            leverage: 0.1,
            order_type: "FLAT",
            order_uuid: "11952389-22ae-4a37-93f2-6346531cb810",
            price: 66213.1,
            price_sources: [],
            processed_ms: 1718516045200,
          },
        ],
        position_type: "FLAT",
        position_uuid: "df5d7b66-335e-4bcd-949f-f2bd0c01e7c9",
        return_at_close: 1.0001850458463537,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 3603.5300000000007,
        close_ms: 1718585825950,
        current_return: 0.9998462618598984,
        initial_entry_price: 3603.53,
        is_closed_position: true,
        miner_hotkey: "5Dxqzduahnqw8q3XSUfTcEZGU7xmAsfJubhHZwvXVLN9fSjR",
        net_leverage: 0.0,
        open_ms: 1718558695138,
        orders: [
          {
            leverage: -0.05,
            order_type: "SHORT",
            order_uuid: "a6d3296d-168f-4d9e-a09d-7b2f37944a35",
            price: 3603.53,
            price_sources: [],
            processed_ms: 1718558694615,
          },
          {
            leverage: 0.05,
            order_type: "FLAT",
            order_uuid: "38f95337-68e5-4b17-8c2c-935562aafd42",
            price: 3614.61,
            price_sources: [],
            processed_ms: 1718585825950,
          },
        ],
        position_type: "FLAT",
        position_uuid: "ba491fbc-8c33-4886-b13b-a05dccd710c6",
        return_at_close: 0.9997962695468054,
        trade_pair: ["ETHUSD", "ETH/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 66307.63499999998,
        close_ms: 1718646278260,
        current_return: 1.0002005516465737,
        initial_entry_price: 66578.86,
        is_closed_position: true,
        miner_hotkey: "5Dxqzduahnqw8q3XSUfTcEZGU7xmAsfJubhHZwvXVLN9fSjR",
        net_leverage: 0.0,
        open_ms: 1718558846823,
        orders: [
          {
            leverage: 0.1,
            order_type: "LONG",
            order_uuid: "9fea4c0d-e36d-4960-adf9-d7d76ad672d8",
            price: 66578.86,
            price_sources: [],
            processed_ms: 1718558843458,
          },
          {
            leverage: -0.05,
            order_type: "SHORT",
            order_uuid: "beec4a5d-45fc-4102-9134-6843205319f4",
            price: 66814.71,
            price_sources: [],
            processed_ms: 1718584340954,
          },
          {
            leverage: 0.05,
            order_type: "LONG",
            order_uuid: "a1a71f84-cace-41f0-99da-70d96fb09191",
            price: 66272.26,
            price_sources: [],
            processed_ms: 1718587209815,
          },
          {
            leverage: 0.1,
            order_type: "FLAT",
            order_uuid: "45208cc4-59d5-4b7e-a440-0282977b1225",
            price: 66441.16,
            price_sources: [],
            processed_ms: 1718646278260,
          },
        ],
        position_type: "FLAT",
        position_uuid: "a4ac22b6-4153-4574-a30a-d45c608ff957",
        return_at_close: 1.0000505215638267,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 69371.08,
        close_ms: 1718751556756,
        current_return: 1.0030854665169546,
        initial_entry_price: 66919.54,
        is_closed_position: true,
        miner_hotkey: "5Dxqzduahnqw8q3XSUfTcEZGU7xmAsfJubhHZwvXVLN9fSjR",
        net_leverage: 0.0,
        open_ms: 1718648731313,
        orders: [
          {
            leverage: -0.05,
            order_type: "SHORT",
            order_uuid: "4bc557e0-b614-4bb6-a7b1-42cae0ab71ed",
            price: 66919.54,
            price_sources: [],
            processed_ms: 1718648729997,
          },
          {
            leverage: -0.05,
            order_type: "SHORT",
            order_uuid: "d20d9fe2-ee49-450c-82b5-fd0c514b91dd",
            price: 67011.54,
            price_sources: [],
            processed_ms: 1718649724273,
          },
          {
            leverage: 0.05,
            order_type: "LONG",
            order_uuid: "56b374de-a6f6-40c4-bd26-7821dcd52830",
            price: 64560.0,
            price_sources: [],
            processed_ms: 1718742614307,
          },
          {
            leverage: 0.05,
            order_type: "LONG",
            order_uuid: "eff5fa1f-71c5-4410-a8cb-30a6189cd9f4",
            price: 65241.52,
            price_sources: [],
            processed_ms: 1718751556756,
          },
        ],
        position_type: "FLAT",
        position_uuid: "78ccfe1f-8486-4b20-a7dc-e4c07c1786a1",
        return_at_close: 1.0029851579703029,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 3796.1600000000003,
        close_ms: 1718758901246,
        current_return: 0.9949343824629387,
        initial_entry_price: 3550.88,
        is_closed_position: true,
        miner_hotkey: "5Dxqzduahnqw8q3XSUfTcEZGU7xmAsfJubhHZwvXVLN9fSjR",
        net_leverage: 0.0,
        open_ms: 1718647223606,
        orders: [
          {
            leverage: 0.1,
            order_type: "LONG",
            order_uuid: "9082aafd-3015-4071-b995-48380aa3ccfb",
            price: 3550.88,
            price_sources: [],
            processed_ms: 1718647221864,
          },
          {
            leverage: 0.05,
            order_type: "LONG",
            order_uuid: "9463f8d1-9751-41de-aa03-685290035760",
            price: 3562.38,
            price_sources: [],
            processed_ms: 1718648856898,
          },
          {
            leverage: 0.05,
            order_type: "LONG",
            order_uuid: "4d063908-b0d7-4ef4-9d9d-a6f467ce69ab",
            price: 3569.97,
            price_sources: [],
            processed_ms: 1718649306211,
          },
          {
            leverage: -0.05,
            order_type: "SHORT",
            order_uuid: "80087d19-8fd2-40dc-9f4d-72d9aa559e96",
            price: 3417.83,
            price_sources: [],
            processed_ms: 1718742789327,
          },
          {
            leverage: -0.05,
            order_type: "SHORT",
            order_uuid: "ff7a0116-473c-46cf-9a41-3e585a6141b7",
            price: 3475.78,
            price_sources: [],
            processed_ms: 1718751920245,
          },
          {
            leverage: -0.09,
            order_type: "SHORT",
            order_uuid: "41e4a2b1-c4be-4502-abe8-318a274dfc3c",
            price: 3476.16,
            price_sources: [],
            processed_ms: 1718753282137,
          },
          {
            leverage: 0.05,
            order_type: "LONG",
            order_uuid: "eb0a234e-c8fc-4b98-8521-c6fde0eaff22",
            price: 3471.98,
            price_sources: [],
            processed_ms: 1718756173122,
          },
          {
            leverage: 0.05,
            order_type: "FLAT",
            order_uuid: "5d904b9b-4adf-4027-8df7-35192004f2cc",
            price: 3496.37,
            price_sources: [],
            processed_ms: 1718758901246,
          },
        ],
        position_type: "FLAT",
        position_uuid: "0da3ce2e-a02c-4b24-bd18-1d6fa46d0ad7",
        return_at_close: 0.994685648867323,
        trade_pair: ["ETHUSD", "ETH/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 3530.936666666667,
        close_ms: 1718761294888,
        current_return: 1.0008019898186105,
        initial_entry_price: 3529.97,
        is_closed_position: true,
        miner_hotkey: "5Dxqzduahnqw8q3XSUfTcEZGU7xmAsfJubhHZwvXVLN9fSjR",
        net_leverage: 0.0,
        open_ms: 1718759642989,
        orders: [
          {
            leverage: -0.1,
            order_type: "SHORT",
            order_uuid: "6289ef2e-26f3-4748-bae9-b873f74382ca",
            price: 3529.97,
            price_sources: [],
            processed_ms: 1718759640244,
          },
          {
            leverage: -0.1,
            order_type: "SHORT",
            order_uuid: "a8ec15ab-b377-4337-b8bf-f3507c43e7f9",
            price: 3521.83,
            price_sources: [],
            processed_ms: 1718759740509,
          },
          {
            leverage: -0.1,
            order_type: "SHORT",
            order_uuid: "c6a42f83-629e-42d9-a103-4ff1e3009595",
            price: 3541.01,
            price_sources: [],
            processed_ms: 1718761034631,
          },
          {
            leverage: 0.05,
            order_type: "FLAT",
            order_uuid: "cea35f54-5a5a-4f96-b7e9-d4b55faf40c9",
            price: 3521.5,
            price_sources: [],
            processed_ms: 1718761294888,
          },
        ],
        position_type: "FLAT",
        position_uuid: "e903c15d-994d-46b3-8da4-144a700e6af7",
        return_at_close: 1.000501749221665,
        trade_pair: ["ETHUSD", "ETH/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 3518.6300000000006,
        close_ms: 1718762959990,
        current_return: 1.0004393755524168,
        initial_entry_price: 3518.63,
        is_closed_position: true,
        miner_hotkey: "5Dxqzduahnqw8q3XSUfTcEZGU7xmAsfJubhHZwvXVLN9fSjR",
        net_leverage: 0.0,
        open_ms: 1718762470281,
        orders: [
          {
            leverage: 0.1,
            order_type: "LONG",
            order_uuid: "4199feae-937a-41f9-8a0c-ae9d2d1c8846",
            price: 3518.63,
            price_sources: [],
            processed_ms: 1718762466856,
          },
          {
            leverage: -0.1,
            order_type: "SHORT",
            order_uuid: "579334f0-3a5d-48db-9bba-5f4d3c167719",
            price: 3534.09,
            price_sources: [],
            processed_ms: 1718762959990,
          },
        ],
        position_type: "FLAT",
        position_uuid: "4d27376d-14e9-4fff-9935-0f1dc52343d8",
        return_at_close: 1.0003393316148617,
        trade_pair: ["ETHUSD", "ETH/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 65197.0,
        close_ms: 1718768733004,
        current_return: 1.0000401352822983,
        initial_entry_price: 65197.0,
        is_closed_position: true,
        miner_hotkey: "5Dxqzduahnqw8q3XSUfTcEZGU7xmAsfJubhHZwvXVLN9fSjR",
        net_leverage: 0.0,
        open_ms: 1718753327146,
        orders: [
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "29c120b8-8cfa-4727-a0fd-569fab9608d6",
            price: 65197.0,
            price_sources: [],
            processed_ms: 1718753324288,
          },
          {
            leverage: 0.1,
            order_type: "FLAT",
            order_uuid: "cf235dc6-5f59-419b-8a71-77af08d2ba12",
            price: 65458.67,
            price_sources: [],
            processed_ms: 1718768733004,
          },
        ],
        position_type: "FLAT",
        position_uuid: "af412e5b-e9c6-481f-ae82-46c0ed780d3c",
        return_at_close: 1.0000301348809455,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 3557.11,
        close_ms: 1718781485050,
        current_return: 1.000319360379634,
        initial_entry_price: 3557.11,
        is_closed_position: true,
        miner_hotkey: "5Dxqzduahnqw8q3XSUfTcEZGU7xmAsfJubhHZwvXVLN9fSjR",
        net_leverage: 0.0,
        open_ms: 1718773525759,
        orders: [
          {
            leverage: -0.1,
            order_type: "SHORT",
            order_uuid: "54f2f442-11d0-4860-a242-2c4106126a0b",
            price: 3557.11,
            price_sources: [],
            processed_ms: 1718773521396,
          },
          {
            leverage: 0.1,
            order_type: "FLAT",
            order_uuid: "393dcb5e-2126-432b-8e65-ec9cb156ae2b",
            price: 3545.75,
            price_sources: [],
            processed_ms: 1718781485050,
          },
        ],
        position_type: "FLAT",
        position_uuid: "1c895b16-fb15-461a-a499-fc7a17d57347",
        return_at_close: 1.000219328443596,
        trade_pair: ["ETHUSD", "ETH/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 3557.88,
        close_ms: 1718849432904,
        current_return: 1.0013710926104615,
        initial_entry_price: 3565.04,
        is_closed_position: true,
        miner_hotkey: "5Dxqzduahnqw8q3XSUfTcEZGU7xmAsfJubhHZwvXVLN9fSjR",
        net_leverage: 0.0,
        open_ms: 1718820790449,
        orders: [
          {
            leverage: -0.1,
            order_type: "SHORT",
            order_uuid: "59135843-e3d0-455c-bf7e-27d625073de1",
            price: 3565.04,
            price_sources: [],
            processed_ms: 1718820789102,
          },
          {
            leverage: -0.1,
            order_type: "SHORT",
            order_uuid: "c99f444e-6863-477b-aa59-567f31be82ca",
            price: 3550.72,
            price_sources: [],
            processed_ms: 1718824747779,
          },
          {
            leverage: 0.05,
            order_type: "FLAT",
            order_uuid: "9c1379d9-3ce0-4a15-997b-590323c2d878",
            price: 3533.44,
            price_sources: [],
            processed_ms: 1718849432904,
          },
        ],
        position_type: "FLAT",
        position_uuid: "f34b878d-cf8e-47c6-8676-8ac17318dff5",
        return_at_close: 1.0011708183919394,
        trade_pair: ["ETHUSD", "ETH/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 66128.14999999997,
        close_ms: 1718914390244,
        current_return: 0.9991523705868467,
        initial_entry_price: 65069.12,
        is_closed_position: true,
        miner_hotkey: "5Dxqzduahnqw8q3XSUfTcEZGU7xmAsfJubhHZwvXVLN9fSjR",
        net_leverage: 0.0,
        open_ms: 1718820925188,
        orders: [
          {
            leverage: 0.1,
            order_type: "LONG",
            order_uuid: "d7ed3762-40ed-4329-add3-4bb8bf61c410",
            price: 65069.12,
            price_sources: [],
            processed_ms: 1718820923790,
          },
          {
            leverage: 0.2,
            order_type: "LONG",
            order_uuid: "916ec9df-4d0d-4945-b2e7-1fdb8b230175",
            price: 65110.56,
            price_sources: [],
            processed_ms: 1718821173982,
          },
          {
            leverage: -0.05,
            order_type: "SHORT",
            order_uuid: "8f9577e2-2d4e-497f-9be9-5d30e4ae4e40",
            price: 64814.51,
            price_sources: [],
            processed_ms: 1718825444151,
          },
          {
            leverage: -0.05,
            order_type: "SHORT",
            order_uuid: "480e7ede-10cd-4758-b3da-16998c0b3209",
            price: 64866.56,
            price_sources: [],
            processed_ms: 1718825740402,
          },
          {
            leverage: -0.05,
            order_type: "SHORT",
            order_uuid: "ea2c2697-9f96-4962-919f-fe46701998dc",
            price: 64892.12,
            price_sources: [],
            processed_ms: 1718825906267,
          },
          {
            leverage: 0.05,
            order_type: "LONG",
            order_uuid: "08f67f7b-6b80-4c08-9ce7-09ac73753ad5",
            price: 64761.0,
            price_sources: [],
            processed_ms: 1718830622692,
          },
          {
            leverage: 0.05,
            order_type: "LONG",
            order_uuid: "ca0a281b-9fae-4f32-9469-7ffd17b1a213",
            price: 64910.44,
            price_sources: [],
            processed_ms: 1718832218218,
          },
          {
            leverage: -0.05,
            order_type: "SHORT",
            order_uuid: "66cde12b-bf75-4e8d-ac60-9762f9ddc713",
            price: 64827.8,
            price_sources: [],
            processed_ms: 1718839954107,
          },
          {
            leverage: -0.05,
            order_type: "SHORT",
            order_uuid: "d5790e09-be6a-4e7c-9706-775c8125f546",
            price: 64933.99,
            price_sources: [],
            processed_ms: 1718841341289,
          },
          {
            leverage: -0.05,
            order_type: "SHORT",
            order_uuid: "aec5cc1b-6d74-46ee-aee2-b3ae876e1edd",
            price: 64907.08,
            price_sources: [],
            processed_ms: 1718843159225,
          },
          {
            leverage: -0.05,
            order_type: "SHORT",
            order_uuid: "46ba59cf-7b54-4ca0-84f0-f8838e18e3a7",
            price: 64881.71,
            price_sources: [],
            processed_ms: 1718843295334,
          },
          {
            leverage: 0.1,
            order_type: "FLAT",
            order_uuid: "a6eec0d1-5a66-4272-aac0-5715b5c8cc6a",
            price: 65025.06,
            price_sources: [],
            processed_ms: 1718914390244,
          },
        ],
        position_type: "FLAT",
        position_uuid: "fc5c9918-540b-4f61-a6bf-c5854b3623e7",
        return_at_close: 0.998752709638612,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 3510.0275,
        close_ms: 1718933530445,
        current_return: 1.000534146459238,
        initial_entry_price: 3520.57,
        is_closed_position: true,
        miner_hotkey: "5Dxqzduahnqw8q3XSUfTcEZGU7xmAsfJubhHZwvXVLN9fSjR",
        net_leverage: 0.0,
        open_ms: 1718911220113,
        orders: [
          {
            leverage: 0.05,
            order_type: "LONG",
            order_uuid: "be3a3cb5-34ba-4608-8399-1c263258955d",
            price: 3520.57,
            price_sources: [],
            processed_ms: 1718911218920,
          },
          {
            leverage: 0.05,
            order_type: "LONG",
            order_uuid: "feff2056-8251-4dbf-9e9b-d2069567f879",
            price: 3518.8,
            price_sources: [],
            processed_ms: 1718925789514,
          },
          {
            leverage: 0.1,
            order_type: "LONG",
            order_uuid: "eb9b724c-7a3b-4db1-a07a-4a22d292649b",
            price: 3500.37,
            price_sources: [],
            processed_ms: 1718928810893,
          },
          {
            leverage: 0.05,
            order_type: "FLAT",
            order_uuid: "bc63d47b-a79b-478a-a1c9-53432ca56851",
            price: 3519.43,
            price_sources: [],
            processed_ms: 1718933530445,
          },
        ],
        position_type: "FLAT",
        position_uuid: "5f78d838-753a-4530-83c6-681d966f8c25",
        return_at_close: 1.0003340396299463,
        trade_pair: ["ETHUSD", "ETH/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: -7.192753008068854e18,
        close_ms: 1719291167660,
        current_return: 1.0016419574033124,
        initial_entry_price: 60792.99,
        is_closed_position: true,
        miner_hotkey: "5Dxqzduahnqw8q3XSUfTcEZGU7xmAsfJubhHZwvXVLN9fSjR",
        net_leverage: 0.0,
        open_ms: 1719245553297,
        orders: [
          {
            leverage: 0.05,
            order_type: "LONG",
            order_uuid: "a98d3250-69e2-40cb-b592-a156cb74a4b9",
            price: 60792.99,
            price_sources: [],
            processed_ms: 1719245550679,
          },
          {
            leverage: 0.05,
            order_type: "LONG",
            order_uuid: "48b7134b-2bb6-4e09-9488-bb87e4dacf39",
            price: 60445.09,
            price_sources: [],
            processed_ms: 1719247179369,
          },
          {
            leverage: 0.05,
            order_type: "LONG",
            order_uuid: "1bf99154-0896-4c4e-9340-b233709d9a68",
            price: 59971.87,
            price_sources: [],
            processed_ms: 1719249726080,
          },
          {
            leverage: 0.05,
            order_type: "LONG",
            order_uuid: "f2d709e5-2112-423a-8464-cd5e4e1a9744",
            price: 59307.26,
            price_sources: [],
            processed_ms: 1719258943709,
          },
          {
            leverage: 0.05,
            order_type: "LONG",
            order_uuid: "1358c871-92a6-4a19-9e17-1595edc0c99d",
            price: 58895.22,
            price_sources: [],
            processed_ms: 1719260965149,
          },
          {
            leverage: -0.05,
            order_type: "SHORT",
            order_uuid: "c86e8052-60c7-4102-99e3-9c8efceb47b9",
            price: 60124.37,
            price_sources: [],
            processed_ms: 1719268326884,
          },
          {
            leverage: -0.05,
            order_type: "SHORT",
            order_uuid: "d7aad1d8-894d-487c-8111-dd08f575b1d0",
            price: 60228.53,
            price_sources: [],
            processed_ms: 1719268820602,
          },
          {
            leverage: -0.05,
            order_type: "SHORT",
            order_uuid: "4d5011b0-3297-4516-80c7-a880fdbec4e7",
            price: 60336.14,
            price_sources: [],
            processed_ms: 1719272549320,
          },
          {
            leverage: -0.05,
            order_type: "SHORT",
            order_uuid: "0cd98199-0c8f-44dd-9aab-9638c001fd18",
            price: 60347.98,
            price_sources: [],
            processed_ms: 1719275377904,
          },
          {
            leverage: -0.05,
            order_type: "SHORT",
            order_uuid: "55d0b8bd-7ad6-4014-8d9d-70ecb8de3fdd",
            price: 60371.8,
            price_sources: [],
            processed_ms: 1719275738432,
          },
          {
            leverage: 0.01,
            order_type: "FLAT",
            order_uuid: "88013402-dde1-4b44-8ffb-43cf46652728",
            price: 61397.0,
            price_sources: [],
            processed_ms: 1719291167660,
          },
        ],
        position_type: "FLAT",
        position_uuid: "4a4e3cf9-215c-46ab-bcdb-385ee0d6e9bb",
        return_at_close: 1.0013915469139616,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 1.0764,
        close_ms: 1719336646345,
        current_return: 0.999995039018952,
        initial_entry_price: 1.0764,
        is_closed_position: true,
        miner_hotkey: "5Dxqzduahnqw8q3XSUfTcEZGU7xmAsfJubhHZwvXVLN9fSjR",
        net_leverage: 0.0,
        open_ms: 1714951149553,
        orders: [
          {
            leverage: 0.001,
            order_type: "LONG",
            order_uuid: "57370d55-7f5c-4df7-8acb-37364e5585ad",
            price: 1.0764,
            price_sources: [],
            processed_ms: 1714951149518,
          },
          {
            leverage: 0.01,
            order_type: "FLAT",
            order_uuid: "0e22243d-2c14-46f0-b663-c13c6268b684",
            price: 1.0710600000000001,
            price_sources: [],
            processed_ms: 1719336646345,
          },
        ],
        position_type: "FLAT",
        position_uuid: "9c6473d8-1dc8-42fe-83b0-6382508452a1",
        return_at_close: 0.9999949690192993,
        trade_pair: ["EURUSD", "EUR/USD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 61851.83000000001,
        close_ms: 1719345546869,
        current_return: 1.0001846509634396,
        initial_entry_price: 61851.83,
        is_closed_position: true,
        miner_hotkey: "5Dxqzduahnqw8q3XSUfTcEZGU7xmAsfJubhHZwvXVLN9fSjR",
        net_leverage: 0.0,
        open_ms: 1719344643138,
        orders: [
          {
            leverage: 0.05,
            order_type: "LONG",
            order_uuid: "a502d492-efef-4914-92cb-036711577033",
            price: 61851.83,
            price_sources: [],
            processed_ms: 1719344642783,
          },
          {
            leverage: 0.05,
            order_type: "FLAT",
            order_uuid: "f283370c-6303-4d8c-9532-b82d562dd97a",
            price: 62080.25,
            price_sources: [],
            processed_ms: 1719345546869,
          },
        ],
        position_type: "FLAT",
        position_uuid: "5549fbca-f485-490d-9eba-efc9ad05ef73",
        return_at_close: 1.0001346417308914,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 61855.93000000001,
        close_ms: 1719353697261,
        current_return: 1.0003419558965487,
        initial_entry_price: 61855.93,
        is_closed_position: true,
        miner_hotkey: "5Dxqzduahnqw8q3XSUfTcEZGU7xmAsfJubhHZwvXVLN9fSjR",
        net_leverage: 0.0,
        open_ms: 1719349609590,
        orders: [
          {
            leverage: 0.1,
            order_type: "LONG",
            order_uuid: "14fcb249-dc08-4242-8f7c-a125aa55c92c",
            price: 61855.93,
            price_sources: [],
            processed_ms: 1719349608335,
          },
          {
            leverage: 0.05,
            order_type: "FLAT",
            order_uuid: "b0e502af-4296-4b26-9627-084202d56b42",
            price: 62067.45,
            price_sources: [],
            processed_ms: 1719353697261,
          },
        ],
        position_type: "FLAT",
        position_uuid: "c580d05b-3a11-4852-91f2-338c63722787",
        return_at_close: 1.000241921700959,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 61862.64,
        close_ms: 1719364898682,
        current_return: 1.0002619028221233,
        initial_entry_price: 61862.64,
        is_closed_position: true,
        miner_hotkey: "5Dxqzduahnqw8q3XSUfTcEZGU7xmAsfJubhHZwvXVLN9fSjR",
        net_leverage: 0.0,
        open_ms: 1719356808340,
        orders: [
          {
            leverage: 0.1,
            order_type: "LONG",
            order_uuid: "63c83542-aa56-40ba-83ec-f6602e076a3c",
            price: 61862.64,
            price_sources: [],
            processed_ms: 1719356808041,
          },
          {
            leverage: 0.05,
            order_type: "FLAT",
            order_uuid: "6dc750fd-1035-490f-bbde-77daa4c7ef03",
            price: 62024.66,
            price_sources: [],
            processed_ms: 1719364898682,
          },
        ],
        position_type: "FLAT",
        position_uuid: "fddd36d3-eaf3-4e21-bfa8-6bb80b18c547",
        return_at_close: 1.0001618766318412,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 60959.3,
        close_ms: 1719452583503,
        current_return: 1.00014581860356,
        initial_entry_price: 60959.3,
        is_closed_position: true,
        miner_hotkey: "5Dxqzduahnqw8q3XSUfTcEZGU7xmAsfJubhHZwvXVLN9fSjR",
        net_leverage: 0.0,
        open_ms: 1719425953005,
        orders: [
          {
            leverage: 0.1,
            order_type: "LONG",
            order_uuid: "80473f45-0396-4129-bdcf-d317524fb06c",
            price: 60959.3,
            price_sources: [],
            processed_ms: 1719425950627,
          },
          {
            leverage: 0.01,
            order_type: "FLAT",
            order_uuid: "85e0cf93-423f-4ac6-8e53-b212b4701ee0",
            price: 61048.19,
            price_sources: [],
            processed_ms: 1719452583503,
          },
        ],
        position_type: "FLAT",
        position_uuid: "9eab4ca5-93e3-448e-9447-175917313e9b",
        return_at_close: 1.0000458040216997,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: -4.788414473564914e18,
        close_ms: 1719625874173,
        current_return: 1.0016417240334878,
        initial_entry_price: 60715.99,
        is_closed_position: true,
        miner_hotkey: "5Dxqzduahnqw8q3XSUfTcEZGU7xmAsfJubhHZwvXVLN9fSjR",
        net_leverage: 0.0,
        open_ms: 1719597263220,
        orders: [
          {
            leverage: 0.1,
            order_type: "LONG",
            order_uuid: "3f721786-2573-440b-b450-d7f459e03842",
            price: 60715.99,
            price_sources: [],
            processed_ms: 1719597259931,
          },
          {
            leverage: 0.1,
            order_type: "LONG",
            order_uuid: "ed563954-4bea-4888-b6ad-fd399730a72d",
            price: 60269.84,
            price_sources: [],
            processed_ms: 1719602276396,
          },
          {
            leverage: 0.15,
            order_type: "LONG",
            order_uuid: "fd737807-b766-436b-8ec8-7a9496510279",
            price: 60124.5,
            price_sources: [],
            processed_ms: 1719603244913,
          },
          {
            leverage: -0.05,
            order_type: "SHORT",
            order_uuid: "9b6bcd27-288b-4fc2-b5a0-4fa88bdba9cd",
            price: 60535.1,
            price_sources: [],
            processed_ms: 1719620830426,
          },
          {
            leverage: -0.05,
            order_type: "SHORT",
            order_uuid: "3c174385-ba09-45e0-870d-21d171a9fc68",
            price: 60536.31,
            price_sources: [],
            processed_ms: 1719621070625,
          },
          {
            leverage: -0.05,
            order_type: "SHORT",
            order_uuid: "e3c019bd-bbfd-44ac-8939-df9dd98515e9",
            price: 60544.18,
            price_sources: [],
            processed_ms: 1719621816062,
          },
          {
            leverage: -0.02,
            order_type: "SHORT",
            order_uuid: "13561e6b-c9ef-4692-81cf-b215acb6e995",
            price: 60499.47,
            price_sources: [],
            processed_ms: 1719622064740,
          },
          {
            leverage: -0.02,
            order_type: "SHORT",
            order_uuid: "c04c885c-43a2-4d99-8708-516997049a32",
            price: 60486.95,
            price_sources: [],
            processed_ms: 1719622268660,
          },
          {
            leverage: -0.02,
            order_type: "SHORT",
            order_uuid: "1d132ab0-6d9b-4b74-a344-6702d66447a4",
            price: 60646.2,
            price_sources: [],
            processed_ms: 1719622732248,
          },
          {
            leverage: -0.02,
            order_type: "SHORT",
            order_uuid: "d491e462-c940-4c5b-a44d-94d1b297ec82",
            price: 60869.0,
            price_sources: [],
            processed_ms: 1719622859760,
          },
          {
            leverage: -0.02,
            order_type: "SHORT",
            order_uuid: "49f8dc59-aca7-49e1-a112-d46c583a3404",
            price: 60646.22,
            price_sources: [],
            processed_ms: 1719623153616,
          },
          {
            leverage: -0.02,
            order_type: "SHORT",
            order_uuid: "f4d285fb-261f-43b3-a0fb-5ed63e8b1fc0",
            price: 60682.89,
            price_sources: [],
            processed_ms: 1719623283939,
          },
          {
            leverage: -0.02,
            order_type: "SHORT",
            order_uuid: "1db99f30-a1b2-4f6b-b590-02915c82a03e",
            price: 60710.98,
            price_sources: [],
            processed_ms: 1719623456360,
          },
          {
            leverage: -0.02,
            order_type: "SHORT",
            order_uuid: "c4b323a7-2c13-45df-bc03-de0ac0283076",
            price: 60764.91,
            price_sources: [],
            processed_ms: 1719623766115,
          },
          {
            leverage: -0.02,
            order_type: "SHORT",
            order_uuid: "62ebabe2-980b-4357-aee1-7c65458e61f0",
            price: 60749.84,
            price_sources: [],
            processed_ms: 1719623916598,
          },
          {
            leverage: -0.02,
            order_type: "SHORT",
            order_uuid: "f8a8a4f6-18f8-46ad-84fe-012e7ee0e9a6",
            price: 60751.41,
            price_sources: [],
            processed_ms: 1719624056123,
          },
          {
            leverage: 0.01,
            order_type: "FLAT",
            order_uuid: "2d53d2c9-d762-41c2-a8e2-c05cf997345b",
            price: 60802.41,
            price_sources: [],
            processed_ms: 1719625874173,
          },
        ],
        position_type: "FLAT",
        position_uuid: "25a27fe6-bfe1-4a1b-93e8-b94d5025e44c",
        return_at_close: 1.0012911494300762,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 3384.7800000000007,
        close_ms: 1719629498568,
        current_return: 1.0001282210365223,
        initial_entry_price: 3384.78,
        is_closed_position: true,
        miner_hotkey: "5Dxqzduahnqw8q3XSUfTcEZGU7xmAsfJubhHZwvXVLN9fSjR",
        net_leverage: 0.0,
        open_ms: 1719628366539,
        orders: [
          {
            leverage: -0.1,
            order_type: "SHORT",
            order_uuid: "59ae352f-d413-46b5-94bb-c4c4f90bfa95",
            price: 3384.78,
            price_sources: [],
            processed_ms: 1719628364050,
          },
          {
            leverage: 0.1,
            order_type: "FLAT",
            order_uuid: "b8731c9a-a710-4319-9573-071418520d19",
            price: 3380.44,
            price_sources: [],
            processed_ms: 1719629498568,
          },
        ],
        position_type: "FLAT",
        position_uuid: "fe236709-0f82-48bc-a393-3056fd7708bc",
        return_at_close: 1.0000282082144187,
        trade_pair: ["ETHUSD", "ETH/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 60647.85,
        close_ms: 1719636553576,
        current_return: 1.000046423739671,
        initial_entry_price: 60647.85,
        is_closed_position: true,
        miner_hotkey: "5Dxqzduahnqw8q3XSUfTcEZGU7xmAsfJubhHZwvXVLN9fSjR",
        net_leverage: 0.0,
        open_ms: 1719631134034,
        orders: [
          {
            leverage: 0.05,
            order_type: "LONG",
            order_uuid: "e8a50b2b-56c1-4dd9-a73c-23fd78febd32",
            price: 60647.85,
            price_sources: [],
            processed_ms: 1719631133000,
          },
          {
            leverage: 0.05,
            order_type: "FLAT",
            order_uuid: "a338ca73-d0f7-4e02-95a6-dd2a3cfb12e3",
            price: 60704.16,
            price_sources: [],
            processed_ms: 1719636553576,
          },
        ],
        position_type: "FLAT",
        position_uuid: "da01553d-0823-48a3-a18e-3db6616e8995",
        return_at_close: 0.9999964214184839,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 62175.05566037737,
        close_ms: 1719780818944,
        current_return: 1.0001908789362821,
        initial_entry_price: 61887.08,
        is_closed_position: true,
        miner_hotkey: "5Dxqzduahnqw8q3XSUfTcEZGU7xmAsfJubhHZwvXVLN9fSjR",
        net_leverage: 0.0,
        open_ms: 1719775897851,
        orders: [
          {
            leverage: -0.03,
            order_type: "SHORT",
            order_uuid: "fa819854-47e3-4b6b-bb78-1c99fd843fe2",
            price: 61887.08,
            price_sources: [],
            processed_ms: 1719775897296,
          },
          {
            leverage: -0.03,
            order_type: "SHORT",
            order_uuid: "aa709a50-8760-47bc-b919-ff92a14e3f38",
            price: 62092.0,
            price_sources: [],
            processed_ms: 1719776079039,
          },
          {
            leverage: 0.03,
            order_type: "LONG",
            order_uuid: "dbb66825-9846-4fb6-b8cc-d836419c8113",
            price: 61882.04,
            price_sources: [],
            processed_ms: 1719776458081,
          },
          {
            leverage: 0.015,
            order_type: "LONG",
            order_uuid: "519d46c9-7d7a-4aa3-bb9d-09f540dd1810",
            price: 61893.73,
            price_sources: [],
            processed_ms: 1719777467368,
          },
          {
            leverage: -0.03,
            order_type: "SHORT",
            order_uuid: "ec363769-4203-45c1-aa1c-131df6ed5575",
            price: 62082.84,
            price_sources: [],
            processed_ms: 1719779050516,
          },
          {
            leverage: -0.03,
            order_type: "SHORT",
            order_uuid: "f01de2a2-0c03-4b75-8fd6-893418ce66d6",
            price: 62213.5,
            price_sources: [],
            processed_ms: 1719779301031,
          },
          {
            leverage: -0.03,
            order_type: "SHORT",
            order_uuid: "0ef39e12-91b2-437e-a5ea-5e2e07098f47",
            price: 62252.75,
            price_sources: [],
            processed_ms: 1719779670639,
          },
          {
            leverage: 0.052,
            order_type: "LONG",
            order_uuid: "670782fa-62a3-4f59-a641-75d2589ff0f8",
            price: 62225.0,
            price_sources: [],
            processed_ms: 1719780229216,
          },
          {
            leverage: 0.01,
            order_type: "FLAT",
            order_uuid: "6fafafc8-af57-4665-9a6e-d977740a83db",
            price: 61952.17,
            price_sources: [],
            processed_ms: 1719780818944,
          },
        ],
        position_type: "FLAT",
        position_uuid: "840ca150-e826-4f29-b4ae-147e6cc77b6e",
        return_at_close: 1.0000408503044418,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 62658.979999999996,
        close_ms: 1719931016132,
        current_return: 1.0027026069610732,
        initial_entry_price: 61821.79,
        is_closed_position: true,
        miner_hotkey: "5Dxqzduahnqw8q3XSUfTcEZGU7xmAsfJubhHZwvXVLN9fSjR",
        net_leverage: 0.0,
        open_ms: 1719781947728,
        orders: [
          {
            leverage: -0.1,
            order_type: "SHORT",
            order_uuid: "af5c2182-8671-4b95-8568-8e317ba91a63",
            price: 61821.79,
            price_sources: [],
            processed_ms: 1719781946996,
          },
          {
            leverage: -0.1,
            order_type: "SHORT",
            order_uuid: "10413736-8c81-4537-8878-bd99e39e0bf8",
            price: 62794.6,
            price_sources: [],
            processed_ms: 1719786652601,
          },
          {
            leverage: 0.05,
            order_type: "LONG",
            order_uuid: "1d37400d-48a1-4cb2-9e85-f982867a4239",
            price: 62570.88,
            price_sources: [],
            processed_ms: 1719792990219,
          },
          {
            leverage: -0.1,
            order_type: "SHORT",
            order_uuid: "7d22f1af-d86f-41ee-b356-45d030128167",
            price: 63316.5,
            price_sources: [],
            processed_ms: 1719802396212,
          },
          {
            leverage: 0.05,
            order_type: "FLAT",
            order_uuid: "3052c72c-29d1-4f8c-a48d-b3ea2156b6c9",
            price: 61990.66,
            price_sources: [],
            processed_ms: 1719931016132,
          },
        ],
        position_type: "FLAT",
        position_uuid: "784e7da8-8034-4e05-b9ea-913b07a33942",
        return_at_close: 1.0024017961789848,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 61377.951249999984,
        close_ms: 1720463649125,
        current_return: 0.935368432967119,
        initial_entry_price: 62197.61,
        is_closed_position: true,
        miner_hotkey: "5Dxqzduahnqw8q3XSUfTcEZGU7xmAsfJubhHZwvXVLN9fSjR",
        net_leverage: 0.0,
        open_ms: 1719945148297,
        orders: [
          {
            leverage: 0.1,
            order_type: "LONG",
            order_uuid: "151e2286-0f79-4252-bc4b-69ac5943e979",
            price: 62197.61,
            price_sources: [],
            processed_ms: 1719945147054,
          },
          {
            leverage: 0.1,
            order_type: "LONG",
            order_uuid: "e140224a-7171-4810-9c98-b6830867618a",
            price: 61797.91,
            price_sources: [],
            processed_ms: 1719950071224,
          },
          {
            leverage: 0.15,
            order_type: "LONG",
            order_uuid: "776c56d5-cbb5-4cae-a770-134d8821c736",
            price: 61828.6,
            price_sources: [],
            processed_ms: 1719971713173,
          },
          {
            leverage: 0.2,
            order_type: "LONG",
            order_uuid: "c041542a-4f74-4964-a261-150d80d9ddce",
            price: 61265.2,
            price_sources: [],
            processed_ms: 1719974677802,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "f753f830-f9d8-49d6-bdc6-06852bf07176",
            price: 60959.4,
            price_sources: [],
            processed_ms: 1719976365211,
          },
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "0894e585-5a66-4204-8ded-442fee828ea8",
            price: 60645.1,
            price_sources: [],
            processed_ms: 1719976593064,
          },
          {
            leverage: -0.3,
            order_type: "SHORT",
            order_uuid: "18750f92-a92d-4f74-ada4-ef47d5f61de6",
            price: 60859.67,
            price_sources: [],
            processed_ms: 1719984196457,
          },
          {
            leverage: 0.01,
            order_type: "FLAT",
            order_uuid: "fbb69fdf-6f46-4e5f-a4f2-fed32d75d441",
            price: 56353.04,
            price_sources: [
              {
                close: 56353.04,
                high: 56354.83,
                lag_ms: 125,
                low: 56353.04,
                open: 56353.04,
                source: "Polygon_ws",
                start_ms: 1720463649000,
                timespan_ms: 0,
                volume: 0.00115739,
                vwap: 56354.1255,
                websocket: true,
              },
              {
                close: 56367.5,
                high: 56367.5,
                lag_ms: 2125,
                low: 56367.5,
                open: 56367.5,
                source: "TwelveData_ws",
                start_ms: 1720463647000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
              {
                close: 56349.35,
                high: 56349.39,
                lag_ms: 2126,
                low: 56346.94,
                open: 56349.39,
                source: "Polygon_rest",
                start_ms: 1720463646000,
                timespan_ms: 1000,
                volume: 0.00161506,
                vwap: 56349.2795,
                websocket: false,
              },
              {
                close: 56325.35,
                high: 56329.35,
                lag_ms: 69126,
                low: 56290.56,
                open: 56292.18,
                source: "TwelveData_rest",
                start_ms: 1720463520000,
                timespan_ms: 60000,
                volume: null,
                vwap: null,
                websocket: false,
              },
            ],
            processed_ms: 1720463649125,
          },
        ],
        position_type: "FLAT",
        position_uuid: "f5a2d5cb-f7d3-46a7-8478-a4831465493a",
        return_at_close: 0.9343395276908552,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
    ],
    thirty_day_returns: 0.9437055778422234,
  },
  "5DyM6WygFf4bTkMJ4CL5N92kQJkRzvM7aGWXFfd2J7mMqChs": {
    all_time_returns: 1.0,
    n_positions: 0,
    percentage_profitable: 0.0,
    positions: [
      {
        average_entry_price: 3498.27,
        close_ms: 0,
        current_return: 1.0079529710399713,
        initial_entry_price: 3498.27,
        is_closed_position: false,
        miner_hotkey: "5DyM6WygFf4bTkMJ4CL5N92kQJkRzvM7aGWXFfd2J7mMqChs",
        net_leverage: -0.077,
        open_ms: 1719065207070,
        orders: [
          {
            leverage: -0.077,
            order_type: "SHORT",
            order_uuid: "78970d6b-5b51-44ed-9342-da1f11f33375",
            price: 3498.27,
            price_sources: [],
            processed_ms: 1719065206060,
          },
        ],
        position_type: "SHORT",
        position_uuid: "231ad0ab-46bf-4b56-91a9-ef2190d4db6a",
        return_at_close: 1.0079141648505863,
        trade_pair: ["ETHUSD", "ETH/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 57763.64,
        close_ms: 0,
        current_return: 1.0032317907943475,
        initial_entry_price: 57763.64,
        is_closed_position: false,
        miner_hotkey: "5DyM6WygFf4bTkMJ4CL5N92kQJkRzvM7aGWXFfd2J7mMqChs",
        net_leverage: 0.5,
        open_ms: 1720082762656,
        orders: [
          {
            leverage: 0.5,
            order_type: "LONG",
            order_uuid: "e573c9b3-87f7-4646-8dcc-4469753cfb8f",
            price: 57763.64,
            price_sources: [
              {
                close: 57763.64,
                high: 57763.64,
                lag_ms: 386,
                low: 57756.12,
                open: 57763.64,
                source: "Polygon_ws",
                start_ms: 1720082759999,
                timespan_ms: 0,
                volume: 0.0645937,
                vwap: 57760.7468,
                websocket: true,
              },
              {
                close: 57720.9,
                high: 57720.9,
                lag_ms: 613,
                low: 57720.9,
                open: 57720.9,
                source: "TwelveData_ws",
                start_ms: 1720082759000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
              {
                close: 57700.1,
                high: 57849.0,
                lag_ms: 2614,
                low: 57700.1,
                open: 57745.74,
                source: "Polygon_rest",
                start_ms: 1720082756000,
                timespan_ms: 1000,
                volume: 1.61603239,
                vwap: 57730.1918,
                websocket: false,
              },
              {
                close: 57622.77,
                high: 57707.28,
                lag_ms: 59614,
                low: 57587.85,
                open: 57707.28,
                source: "TwelveData_rest",
                start_ms: 1720082640000,
                timespan_ms: 60000,
                volume: null,
                vwap: null,
                websocket: false,
              },
            ],
            processed_ms: 1720082759613,
          },
        ],
        position_type: "LONG",
        position_uuid: "20e9aeaa-6834-4be6-9e58-507d7bb1b317",
        return_at_close: 1.002980982846649,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
    ],
    thirty_day_returns: 1.0,
  },
  "5E1XnWqKwtCcTAmDDtLmoChrdszV2wifhadUVJzodKjmESqG": {
    all_time_returns: 1.001244377731146,
    n_positions: 1,
    percentage_profitable: 1.0,
    positions: [
      {
        average_entry_price: 157.0768888888889,
        close_ms: 1718388307706,
        current_return: 1.0013074601011323,
        initial_entry_price: 157.022,
        is_closed_position: true,
        miner_hotkey: "5E1XnWqKwtCcTAmDDtLmoChrdszV2wifhadUVJzodKjmESqG",
        net_leverage: 0.0,
        open_ms: 1718112756500,
        orders: [
          {
            leverage: 0.5,
            order_type: "LONG",
            order_uuid: "bfd6ee4d-93cc-4d58-bb8c-89ea617df7fd",
            price: 157.022,
            price_sources: [],
            processed_ms: 1718112756495,
          },
          {
            leverage: 0.4,
            order_type: "LONG",
            order_uuid: "8112725c-8e31-44ee-9769-fe332b7614df",
            price: 157.1455,
            price_sources: [],
            processed_ms: 1718273584212,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "d5916e2d-3f8c-40af-badf-12a6f67f8247",
            price: 157.305,
            price_sources: [],
            processed_ms: 1718388307706,
          },
        ],
        position_type: "FLAT",
        position_uuid: "3786cd84-c0ad-4949-8e8e-c1bba96051ec",
        return_at_close: 1.001244377731146,
        trade_pair: ["USDJPY", "USD/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 65322.66,
        close_ms: 0,
        current_return: 0.9779994874672893,
        initial_entry_price: 65322.66,
        is_closed_position: false,
        miner_hotkey: "5E1XnWqKwtCcTAmDDtLmoChrdszV2wifhadUVJzodKjmESqG",
        net_leverage: 0.2,
        open_ms: 1718388379845,
        orders: [
          {
            leverage: 0.2,
            order_type: "LONG",
            order_uuid: "9bf66bd0-4151-44d0-88f3-fa63df9fd61a",
            price: 65322.66,
            price_sources: [],
            processed_ms: 1718388378959,
          },
        ],
        position_type: "LONG",
        position_uuid: "af08ce42-f065-4619-9100-0dd29243bd18",
        return_at_close: 0.9779016875185426,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
    ],
    thirty_day_returns: 1.001244377731146,
  },
  "5E4HHh8yXiENdVXW8px34hhJaisastccra1U5dyE6LSkoTU4": {
    all_time_returns: 1.0267538865487393,
    n_positions: 7,
    percentage_profitable: 0.5714285714285714,
    positions: [
      {
        average_entry_price: 3553.2238095238095,
        close_ms: 1718195445770,
        current_return: 1.0199621976450155,
        initial_entry_price: 3915.1,
        is_closed_position: true,
        miner_hotkey: "5E4HHh8yXiENdVXW8px34hhJaisastccra1U5dyE6LSkoTU4",
        net_leverage: 0.0,
        open_ms: 1716785393085,
        orders: [
          {
            leverage: 0.1,
            order_type: "LONG",
            order_uuid: "535dcf96-cd02-41cc-9629-224b9e52c45b",
            price: 3915.1,
            price_sources: [],
            processed_ms: 1716785392906,
          },
          {
            leverage: 2.0,
            order_type: "LONG",
            order_uuid: "0fad00a6-e33a-448c-9684-ac84c316eea0",
            price: 3535.13,
            price_sources: [],
            processed_ms: 1718194209268,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "1d4df637-0fa7-48da-a4bd-d3c37b0c72ce",
            price: 3590.44,
            price_sources: [],
            processed_ms: 1718195445770,
          },
        ],
        position_type: "FLAT",
        position_uuid: "9eb0b8f9-af3a-45d1-85ac-dd34555d3f80",
        return_at_close: 1.017820277029961,
        trade_pair: ["ETHUSD", "ETH/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 65281.01,
        close_ms: 1718393463498,
        current_return: 0.9993871648125542,
        initial_entry_price: 65281.01,
        is_closed_position: true,
        miner_hotkey: "5E4HHh8yXiENdVXW8px34hhJaisastccra1U5dyE6LSkoTU4",
        net_leverage: 0.0,
        open_ms: 1718389957191,
        orders: [
          {
            leverage: -0.15,
            order_type: "SHORT",
            order_uuid: "b4b7d632-d3dd-4d41-9121-99d08aa15464",
            price: 65281.01,
            price_sources: [],
            processed_ms: 1718389955951,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "13487943-7772-43e5-949a-0cb73f00f21d",
            price: 65547.72,
            price_sources: [],
            processed_ms: 1718393463498,
          },
        ],
        position_type: "FLAT",
        position_uuid: "6bf13164-3775-4c79-8a56-42c8575cf8ae",
        return_at_close: 0.9992372567378324,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 3407.5433333333335,
        close_ms: 1719487177700,
        current_return: 0.9999872985490807,
        initial_entry_price: 3385.44,
        is_closed_position: true,
        miner_hotkey: "5E4HHh8yXiENdVXW8px34hhJaisastccra1U5dyE6LSkoTU4",
        net_leverage: 0.0,
        open_ms: 1718391152534,
        orders: [
          {
            leverage: 0.4,
            order_type: "LONG",
            order_uuid: "239468a5-a046-44f1-93be-4ba58d36c313",
            price: 3385.44,
            price_sources: [],
            processed_ms: 1718391151760,
          },
          {
            leverage: -0.2,
            order_type: "SHORT",
            order_uuid: "8e04f05f-e629-49a3-8d6a-dbfd69b1101a",
            price: 3391.49,
            price_sources: [],
            processed_ms: 1718393499336,
          },
          {
            leverage: 0.1,
            order_type: "LONG",
            order_uuid: "35f08605-898f-4a18-a5a7-e9c7b1b3a445",
            price: 3463.85,
            price_sources: [],
            processed_ms: 1718404341955,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "59f82b35-88a4-4fb0-9a77-440651e2acc9",
            price: 3407.4,
            price_sources: [],
            processed_ms: 1719487177700,
          },
        ],
        position_type: "FLAT",
        position_uuid: "6bb5eb86-cd61-403f-a4b8-ba0483f05e0c",
        return_at_close: 0.9994873048998063,
        trade_pair: ["ETHUSD", "ETH/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 61051.04,
        close_ms: 1719488282948,
        current_return: 1.0023806310261054,
        initial_entry_price: 61051.04,
        is_closed_position: true,
        miner_hotkey: "5E4HHh8yXiENdVXW8px34hhJaisastccra1U5dyE6LSkoTU4",
        net_leverage: 0.0,
        open_ms: 1719486165838,
        orders: [
          {
            leverage: 1.0,
            order_type: "LONG",
            order_uuid: "0e5a9daf-7438-46b1-b31e-281d14808e31",
            price: 61051.04,
            price_sources: [],
            processed_ms: 1719486163910,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "5ea533d6-8bb8-4ce8-9c18-45baaacf1305",
            price: 61196.38,
            price_sources: [],
            processed_ms: 1719488282948,
          },
        ],
        position_type: "FLAT",
        position_uuid: "3758889c-d5dd-4bf9-be13-280628b4aabe",
        return_at_close: 1.0013782503950792,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 61861.85,
        close_ms: 1719500178650,
        current_return: 1.0005843666169052,
        initial_entry_price: 61861.85,
        is_closed_position: true,
        miner_hotkey: "5E4HHh8yXiENdVXW8px34hhJaisastccra1U5dyE6LSkoTU4",
        net_leverage: 0.0,
        open_ms: 1719499818419,
        orders: [
          {
            leverage: 1.0,
            order_type: "LONG",
            order_uuid: "deb0c054-fb98-42e8-acf2-5979308db5b1",
            price: 61861.85,
            price_sources: [],
            processed_ms: 1719499814520,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "4c4ea5f2-0346-4ab9-ba6f-cf03a2487bd7",
            price: 61898.0,
            price_sources: [],
            processed_ms: 1719500178650,
          },
        ],
        position_type: "FLAT",
        position_uuid: "c3cd21dc-5121-4fe3-b931-d9ca17237d13",
        return_at_close: 0.9995837822502883,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 60277.33333333333,
        close_ms: 1719611017780,
        current_return: 1.002263734149715,
        initial_entry_price: 60298.6,
        is_closed_position: true,
        miner_hotkey: "5E4HHh8yXiENdVXW8px34hhJaisastccra1U5dyE6LSkoTU4",
        net_leverage: 0.0,
        open_ms: 1719602567127,
        orders: [
          {
            leverage: 1.0,
            order_type: "LONG",
            order_uuid: "c926ebbc-61bc-411f-bf27-032f85407552",
            price: 60298.6,
            price_sources: [],
            processed_ms: 1719602566359,
          },
          {
            leverage: -0.1,
            order_type: "SHORT",
            order_uuid: "18efeb7f-a597-40c0-8474-2bc288714678",
            price: 60490.0,
            price_sources: [],
            processed_ms: 1719602988679,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "798f4c53-5ea6-478c-9360-f25f58472c72",
            price: 60429.0,
            price_sources: [],
            processed_ms: 1719611017780,
          },
        ],
        position_type: "FLAT",
        position_uuid: "80b27430-7b36-4990-a1fe-3752d2f78549",
        return_at_close: 1.0012614704155651,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 60233.0,
        close_ms: 1719620858719,
        current_return: 1.0088323676390019,
        initial_entry_price: 60233.0,
        is_closed_position: true,
        miner_hotkey: "5E4HHh8yXiENdVXW8px34hhJaisastccra1U5dyE6LSkoTU4",
        net_leverage: 0.0,
        open_ms: 1719611623008,
        orders: [
          {
            leverage: 1.0,
            order_type: "LONG",
            order_uuid: "c90eac40-4bb4-4776-a8ca-19569ec5f401",
            price: 60233.0,
            price_sources: [],
            processed_ms: 1719611622405,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "05aa4ee8-45ad-4640-9906-084668ccdc04",
            price: 60765.0,
            price_sources: [],
            processed_ms: 1719620858719,
          },
        ],
        position_type: "FLAT",
        position_uuid: "c4887b40-9f96-4f67-b6dc-7f1c937fa3b4",
        return_at_close: 1.007823535271363,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
    ],
    thirty_day_returns: 1.0087771974290465,
  },
  "5E6wyDU9W9eeY8S5DMBgxkFKPgpdjgBQRwADFJY8g35jaES6": {
    all_time_returns: 1.0,
    n_positions: 0,
    percentage_profitable: 0.0,
    positions: [
      {
        average_entry_price: 64525.36,
        close_ms: 1711196267751,
        current_return: 1.000616594777619,
        initial_entry_price: 64525.36,
        is_closed_position: true,
        miner_hotkey: "5E6wyDU9W9eeY8S5DMBgxkFKPgpdjgBQRwADFJY8g35jaES6",
        net_leverage: 0.0,
        open_ms: 1711192687754,
        orders: [
          {
            leverage: 0.6,
            order_type: "LONG",
            order_uuid: "7393eae8-2ef3-40b5-b6dc-5cad4b790ffa",
            price: 64525.36,
            price_sources: [],
            processed_ms: 1711192687754,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "47fded95-f31c-4198-bc1b-17c720dc96a6",
            price: 64591.67,
            price_sources: [],
            processed_ms: 1711196267751,
          },
        ],
        position_type: "FLAT",
        position_uuid: "30c20987-d6b6-492a-9ba1-b8d2b328aa2d",
        return_at_close: 0.999415854863886,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 65137.80999999999,
        close_ms: 1711214578565,
        current_return: 0.9961594656007009,
        initial_entry_price: 65137.81,
        is_closed_position: true,
        miner_hotkey: "5E6wyDU9W9eeY8S5DMBgxkFKPgpdjgBQRwADFJY8g35jaES6",
        net_leverage: 0.0,
        open_ms: 1711211045120,
        orders: [
          {
            leverage: -0.6,
            order_type: "SHORT",
            order_uuid: "470a3448-b3d6-4ab6-a63c-3c2b8f866953",
            price: 65137.81,
            price_sources: [],
            processed_ms: 1711211045120,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "2d4ffe51-8347-4e0d-999d-c0d504738794",
            price: 65554.75,
            price_sources: [],
            processed_ms: 1711214578565,
          },
        ],
        position_type: "FLAT",
        position_uuid: "b61a7b27-8f72-4ef5-9b80-48a40c2e9117",
        return_at_close: 0.9949640742419801,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 65514.86,
        close_ms: 1711221908016,
        current_return: 1.001880275711495,
        initial_entry_price: 65514.86,
        is_closed_position: true,
        miner_hotkey: "5E6wyDU9W9eeY8S5DMBgxkFKPgpdjgBQRwADFJY8g35jaES6",
        net_leverage: 0.0,
        open_ms: 1711218374115,
        orders: [
          {
            leverage: -0.6,
            order_type: "SHORT",
            order_uuid: "0b0ce854-482e-4b97-80e6-60b6b2495351",
            price: 65514.86,
            price_sources: [],
            processed_ms: 1711218374115,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "6d4c6f1f-2578-4813-91d2-0e792864fc8d",
            price: 65309.55,
            price_sources: [],
            processed_ms: 1711221908016,
          },
        ],
        position_type: "FLAT",
        position_uuid: "12f962fa-5901-4424-ae74-3138f739909b",
        return_at_close: 1.0006780193806413,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 65159.59999999999,
        close_ms: 1711225561608,
        current_return: 1.0024369394532808,
        initial_entry_price: 65159.6,
        is_closed_position: true,
        miner_hotkey: "5E6wyDU9W9eeY8S5DMBgxkFKPgpdjgBQRwADFJY8g35jaES6",
        net_leverage: 0.0,
        open_ms: 1711221994620,
        orders: [
          {
            leverage: -0.6,
            order_type: "SHORT",
            order_uuid: "a4100435-ff3c-43a3-89b2-5ee35e4e6e79",
            price: 65159.6,
            price_sources: [],
            processed_ms: 1711221994619,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "5c18bc6b-1979-4599-8b4f-47fd87708731",
            price: 64894.95,
            price_sources: [],
            processed_ms: 1711225561608,
          },
        ],
        position_type: "FLAT",
        position_uuid: "49ca2c4e-373f-429c-aa9f-c6ae9c9a96c2",
        return_at_close: 1.001234015125937,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 64911.64,
        close_ms: 1711229228215,
        current_return: 1.0000055460006865,
        initial_entry_price: 64911.64,
        is_closed_position: true,
        miner_hotkey: "5E6wyDU9W9eeY8S5DMBgxkFKPgpdjgBQRwADFJY8g35jaES6",
        net_leverage: 0.0,
        open_ms: 1711225650277,
        orders: [
          {
            leverage: -0.6,
            order_type: "SHORT",
            order_uuid: "adcf2fcb-9ab9-4d65-b533-db76ebd046bc",
            price: 64911.64,
            price_sources: [],
            processed_ms: 1711225650276,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "45199219-aed0-4893-9acb-08d17a310b8c",
            price: 64911.04,
            price_sources: [],
            processed_ms: 1711229228215,
          },
        ],
        position_type: "FLAT",
        position_uuid: "300be143-9160-4100-b9db-6d6238c4ec65",
        return_at_close: 0.9988055393454857,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 64901.42,
        close_ms: 1711232886291,
        current_return: 1.0001473619529433,
        initial_entry_price: 64901.42,
        is_closed_position: true,
        miner_hotkey: "5E6wyDU9W9eeY8S5DMBgxkFKPgpdjgBQRwADFJY8g35jaES6",
        net_leverage: 0.0,
        open_ms: 1711229315491,
        orders: [
          {
            leverage: -0.6,
            order_type: "SHORT",
            order_uuid: "6a7e9d4a-e891-4cdb-b814-515956498271",
            price: 64901.42,
            price_sources: [],
            processed_ms: 1711229315490,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "c92c0831-fd9f-4544-ac93-6242add29fcb",
            price: 64885.48,
            price_sources: [],
            processed_ms: 1711232886291,
          },
        ],
        position_type: "FLAT",
        position_uuid: "7835b39c-16d6-4af8-a334-524689196949",
        return_at_close: 0.9989471851185998,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 64400.80000000001,
        close_ms: 1711243879465,
        current_return: 1.0020034533732498,
        initial_entry_price: 64400.8,
        is_closed_position: true,
        miner_hotkey: "5E6wyDU9W9eeY8S5DMBgxkFKPgpdjgBQRwADFJY8g35jaES6",
        net_leverage: 0.0,
        open_ms: 1711240303650,
        orders: [
          {
            leverage: 0.6,
            order_type: "LONG",
            order_uuid: "91f151c0-aeb3-46d5-bcda-67d265e06cdb",
            price: 64400.8,
            price_sources: [],
            processed_ms: 1711240303649,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "44c48350-4275-4d4f-994d-fd62b678b9d4",
            price: 64615.84,
            price_sources: [],
            processed_ms: 1711243879465,
          },
        ],
        position_type: "FLAT",
        position_uuid: "4445f22d-253e-4e08-8ee3-40e95c41933c",
        return_at_close: 1.0008010492292019,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 64097.299999999996,
        close_ms: 1711254859472,
        current_return: 0.9989231371680243,
        initial_entry_price: 64097.3,
        is_closed_position: true,
        miner_hotkey: "5E6wyDU9W9eeY8S5DMBgxkFKPgpdjgBQRwADFJY8g35jaES6",
        net_leverage: 0.0,
        open_ms: 1711251335129,
        orders: [
          {
            leverage: 0.6,
            order_type: "LONG",
            order_uuid: "62b1bb3a-385c-4cf8-bcfc-06c84dd05587",
            price: 64097.3,
            price_sources: [],
            processed_ms: 1711251335128,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "b7ad7fe0-7eef-4c96-ba59-96387f2c1518",
            price: 63982.26,
            price_sources: [],
            processed_ms: 1711254859472,
          },
        ],
        position_type: "FLAT",
        position_uuid: "307b44b4-4a8e-47bb-86e5-7548c18187b7",
        return_at_close: 0.9977244294034227,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 63970.45,
        close_ms: 1711256023853,
        current_return: 1.0016519815008336,
        initial_entry_price: 63970.45,
        is_closed_position: true,
        miner_hotkey: "5E6wyDU9W9eeY8S5DMBgxkFKPgpdjgBQRwADFJY8g35jaES6",
        net_leverage: 0.0,
        open_ms: 1711254946552,
        orders: [
          {
            leverage: 0.6,
            order_type: "LONG",
            order_uuid: "e62b0a20-6051-4b73-8c2e-c22268e8ed15",
            price: 63970.45,
            price_sources: [],
            processed_ms: 1711254946551,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "5c14d61c-81d8-4841-8a5f-dc7d35a3c6b4",
            price: 64146.58,
            price_sources: [],
            processed_ms: 1711256023853,
          },
        ],
        position_type: "FLAT",
        position_uuid: "19a0b17b-465f-41fa-bcd5-dd6fb4944086",
        return_at_close: 1.0004499991230327,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 64153.909999999996,
        close_ms: 1711259677795,
        current_return: 1.001980393712558,
        initial_entry_price: 64153.91,
        is_closed_position: true,
        miner_hotkey: "5E6wyDU9W9eeY8S5DMBgxkFKPgpdjgBQRwADFJY8g35jaES6",
        net_leverage: 0.0,
        open_ms: 1711256110449,
        orders: [
          {
            leverage: 0.6,
            order_type: "LONG",
            order_uuid: "9e012594-8d36-4f6b-91a5-ab6374bede22",
            price: 64153.91,
            price_sources: [],
            processed_ms: 1711256110448,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "f664a558-1ee8-43af-88be-a1d63488801f",
            price: 64365.66,
            price_sources: [],
            processed_ms: 1711259677795,
          },
        ],
        position_type: "FLAT",
        position_uuid: "cc3a1133-d6d8-4cba-99ee-45e4fe9d3d34",
        return_at_close: 1.0007780172401028,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 64352.94,
        close_ms: 1711263339433,
        current_return: 0.9969304044228593,
        initial_entry_price: 64352.94,
        is_closed_position: true,
        miner_hotkey: "5E6wyDU9W9eeY8S5DMBgxkFKPgpdjgBQRwADFJY8g35jaES6",
        net_leverage: 0.0,
        open_ms: 1711259766449,
        orders: [
          {
            leverage: 1.25,
            order_type: "LONG",
            order_uuid: "e42513f1-57e1-40a5-bfc6-2c54d38806fc",
            price: 64352.94,
            price_sources: [],
            processed_ms: 1711259766448,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "42031d62-576e-4b8e-a299-a877332c9080",
            price: 64194.91,
            price_sources: [],
            processed_ms: 1711263339433,
          },
        ],
        position_type: "FLAT",
        position_uuid: "9cbe27d9-a835-4886-86e7-0b274e184f70",
        return_at_close: 0.9944380784118022,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 64208.33999999999,
        close_ms: 1711267005465,
        current_return: 1.0023303047548031,
        initial_entry_price: 64208.34,
        is_closed_position: true,
        miner_hotkey: "5E6wyDU9W9eeY8S5DMBgxkFKPgpdjgBQRwADFJY8g35jaES6",
        net_leverage: 0.0,
        open_ms: 1711263428307,
        orders: [
          {
            leverage: 1.25,
            order_type: "LONG",
            order_uuid: "81173e91-feb9-45d5-906e-cd3a0cd456f1",
            price: 64208.34,
            price_sources: [],
            processed_ms: 1711263428305,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "5fb57f1d-89a9-4f03-9f25-9f1f8a8c32aa",
            price: 64328.04,
            price_sources: [],
            processed_ms: 1711267005465,
          },
        ],
        position_type: "FLAT",
        position_uuid: "3ed2cbea-7f54-4e5e-ae7e-82dbb342c466",
        return_at_close: 0.9998244789929162,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 64494.44,
        close_ms: 1711270664982,
        current_return: 1.0124931311908436,
        initial_entry_price: 64494.44,
        is_closed_position: true,
        miner_hotkey: "5E6wyDU9W9eeY8S5DMBgxkFKPgpdjgBQRwADFJY8g35jaES6",
        net_leverage: 0.0,
        open_ms: 1711267092328,
        orders: [
          {
            leverage: 1.25,
            order_type: "LONG",
            order_uuid: "cd7b3204-d93c-4467-bddb-90887e7491e0",
            price: 64494.44,
            price_sources: [],
            processed_ms: 1711267092327,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "202318cd-2ae6-4b4c-9781-7267cb5e9e4c",
            price: 65139.03,
            price_sources: [],
            processed_ms: 1711270664982,
          },
        ],
        position_type: "FLAT",
        position_uuid: "e126b84b-4414-4d5f-bb59-7d0da82e83b5",
        return_at_close: 1.0099618983628667,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 65172.48,
        close_ms: 1711274324496,
        current_return: 0.9939865338866957,
        initial_entry_price: 65172.48,
        is_closed_position: true,
        miner_hotkey: "5E6wyDU9W9eeY8S5DMBgxkFKPgpdjgBQRwADFJY8g35jaES6",
        net_leverage: 0.0,
        open_ms: 1711270751453,
        orders: [
          {
            leverage: 1.25,
            order_type: "LONG",
            order_uuid: "493723d0-2630-46c7-944b-8b80639480fb",
            price: 65172.48,
            price_sources: [],
            processed_ms: 1711270751452,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "392bf913-de40-4e8f-91f7-045beee4c737",
            price: 64858.95,
            price_sources: [],
            processed_ms: 1711274324496,
          },
        ],
        position_type: "FLAT",
        position_uuid: "c8d02481-065e-4dca-90d3-a03e65210633",
        return_at_close: 0.9915015675519789,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 65078.56999999999,
        close_ms: 1711280801603,
        current_return: 1.0020108385909525,
        initial_entry_price: 65078.57,
        is_closed_position: true,
        miner_hotkey: "5E6wyDU9W9eeY8S5DMBgxkFKPgpdjgBQRwADFJY8g35jaES6",
        net_leverage: 0.0,
        open_ms: 1711278080623,
        orders: [
          {
            leverage: 1.25,
            order_type: "LONG",
            order_uuid: "cce2d269-c769-4674-8a8e-72ded29f6d57",
            price: 65078.57,
            price_sources: [],
            processed_ms: 1711278080622,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "b3415188-b44f-46f7-813e-719f9dbf7dbe",
            price: 65183.26,
            price_sources: [],
            processed_ms: 1711280801603,
          },
        ],
        position_type: "FLAT",
        position_uuid: "a16c70fd-3813-4a59-b8b6-68e2239d5d75",
        return_at_close: 0.9995058114944751,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 65653.66,
        close_ms: 1711286288654,
        current_return: 0.9997639126287855,
        initial_entry_price: 65653.66,
        is_closed_position: true,
        miner_hotkey: "5E6wyDU9W9eeY8S5DMBgxkFKPgpdjgBQRwADFJY8g35jaES6",
        net_leverage: 0.0,
        open_ms: 1711284472440,
        orders: [
          {
            leverage: 1.25,
            order_type: "LONG",
            order_uuid: "38813310-9771-4d2e-8262-394d5ae57a5d",
            price: 65653.66,
            price_sources: [],
            processed_ms: 1711284472439,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "54d743e4-354e-42ab-a2c1-136895e27096",
            price: 65641.26,
            price_sources: [],
            processed_ms: 1711286288654,
          },
        ],
        position_type: "FLAT",
        position_uuid: "1ca1d374-d1bf-4bee-973e-0968acf5b276",
        return_at_close: 0.9972645028472136,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 65664.06,
        close_ms: 1711297284359,
        current_return: 1.0001540035751673,
        initial_entry_price: 65664.06,
        is_closed_position: true,
        miner_hotkey: "5E6wyDU9W9eeY8S5DMBgxkFKPgpdjgBQRwADFJY8g35jaES6",
        net_leverage: 0.0,
        open_ms: 1711295456773,
        orders: [
          {
            leverage: -1.25,
            order_type: "SHORT",
            order_uuid: "e81cc2cd-4c25-48d2-b48f-e598cc1dcb5f",
            price: 65664.06,
            price_sources: [],
            processed_ms: 1711295456771,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "0a8abb4b-626d-4d66-b2a2-b038b40bafa8",
            price: 65655.97,
            price_sources: [],
            processed_ms: 1711297284359,
          },
        ],
        position_type: "FLAT",
        position_uuid: "ea21d4f8-e22c-46e8-8c23-39403f18f21c",
        return_at_close: 0.9976536185662294,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: -67281.16999999832,
        close_ms: 1711312658556,
        current_return: 0.9950174996722743,
        initial_entry_price: 65403.39,
        is_closed_position: true,
        miner_hotkey: "5E6wyDU9W9eeY8S5DMBgxkFKPgpdjgBQRwADFJY8g35jaES6",
        net_leverage: 0.0,
        open_ms: 1711299118199,
        orders: [
          {
            leverage: -1.25,
            order_type: "SHORT",
            order_uuid: "f22666fb-f438-4d5e-9ab9-aa4484dd4fd9",
            price: 65403.39,
            price_sources: [],
            processed_ms: 1711299118197,
          },
          {
            leverage: 0.625,
            order_type: "LONG",
            order_uuid: "19c10f99-0bf1-47ec-afb5-b7c5dc871692",
            price: 65614.31,
            price_sources: [],
            processed_ms: 1711304267486,
          },
          {
            leverage: 0.3125,
            order_type: "LONG",
            order_uuid: "d22dda11-06d3-4243-a3d0-c63d5ec70ced",
            price: 65635.65,
            price_sources: [],
            processed_ms: 1711305199806,
          },
          {
            leverage: 0.15625,
            order_type: "LONG",
            order_uuid: "5483004a-5660-4849-b832-aa5c8f3ae4e6",
            price: 65726.05,
            price_sources: [],
            processed_ms: 1711306124925,
          },
          {
            leverage: 0.078125,
            order_type: "LONG",
            order_uuid: "5b77a403-24fb-44ad-a78c-108dfa4f994d",
            price: 65862.19,
            price_sources: [],
            processed_ms: 1711307061774,
          },
          {
            leverage: 0.0390625,
            order_type: "LONG",
            order_uuid: "4c92a335-c938-4f2a-a2a9-2f97b2027d84",
            price: 65867.86,
            price_sources: [],
            processed_ms: 1711307988737,
          },
          {
            leverage: 0.01953125,
            order_type: "LONG",
            order_uuid: "4d2a898b-1b59-482c-b85b-255bfeee3e8b",
            price: 65702.13,
            price_sources: [],
            processed_ms: 1711308930046,
          },
          {
            leverage: 0.009765625,
            order_type: "LONG",
            order_uuid: "ac99e973-fec1-4eb4-adcd-fbb712291ebe",
            price: 65941.07,
            price_sources: [],
            processed_ms: 1711309857694,
          },
          {
            leverage: 0.0048828125,
            order_type: "LONG",
            order_uuid: "681f3555-8f83-4afd-ad93-0f2242ab16a6",
            price: 65800.11,
            price_sources: [],
            processed_ms: 1711310787435,
          },
          {
            leverage: 0.00244140625,
            order_type: "LONG",
            order_uuid: "9bb3258f-af84-47d9-ab7e-5b431743164e",
            price: 66265.71,
            price_sources: [],
            processed_ms: 1711311748601,
          },
          {
            leverage: 0.125,
            order_type: "LONG",
            order_uuid: "c27b0b1f-4478-49a3-8794-7549bca8beb8",
            price: 66196.17,
            price_sources: [],
            processed_ms: 1711312658556,
          },
        ],
        position_type: "FLAT",
        position_uuid: "2512d7ab-a68e-479e-8527-c0cf41082097",
        return_at_close: 0.9925299559230937,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 62237.34136986309,
        close_ms: 1711327556315,
        current_return: 0.9952708948505468,
        initial_entry_price: 66333.06,
        is_closed_position: true,
        miner_hotkey: "5E6wyDU9W9eeY8S5DMBgxkFKPgpdjgBQRwADFJY8g35jaES6",
        net_leverage: 0.0,
        open_ms: 1711316384849,
        orders: [
          {
            leverage: -1.25,
            order_type: "SHORT",
            order_uuid: "b96bd589-e819-45f8-86cc-0e30ca2ea322",
            price: 66333.06,
            price_sources: [],
            processed_ms: 1711316384848,
          },
          {
            leverage: 0.125,
            order_type: "LONG",
            order_uuid: "2ba35577-8594-48f3-8e8b-4ec4433c57c1",
            price: 66393.75,
            price_sources: [],
            processed_ms: 1711317313631,
          },
          {
            leverage: 0.5625,
            order_type: "LONG",
            order_uuid: "44f584f5-992f-4af6-bbf8-7a74b55dde11",
            price: 66946.9,
            price_sources: [],
            processed_ms: 1711318267077,
          },
          {
            leverage: 0.28125,
            order_type: "LONG",
            order_uuid: "815cdcb1-dc28-469c-b8eb-17662db2e558",
            price: 66742.62,
            price_sources: [],
            processed_ms: 1711319179511,
          },
          {
            leverage: 0.140625,
            order_type: "LONG",
            order_uuid: "c4a61777-64e1-4f6c-a762-5a845433707d",
            price: 66871.15,
            price_sources: [],
            processed_ms: 1711320104596,
          },
          {
            leverage: 0.0703125,
            order_type: "LONG",
            order_uuid: "dce3c6e5-4bfe-49c7-ae53-aa753d57a6b4",
            price: 66904.64,
            price_sources: [],
            processed_ms: 1711321045482,
          },
          {
            leverage: 0.03515625,
            order_type: "LONG",
            order_uuid: "d6a338b6-b056-4e6d-939f-05feee7dd92c",
            price: 67395.5,
            price_sources: [],
            processed_ms: 1711321996124,
          },
          {
            leverage: -1.25,
            order_type: "SHORT",
            order_uuid: "14310e92-48fb-4dbd-87c3-a4fa8289adab",
            price: 67400.0,
            price_sources: [],
            processed_ms: 1711322893571,
          },
          {
            leverage: 0.642578125,
            order_type: "LONG",
            order_uuid: "50c692c2-385c-443c-b31b-c56c8d2ebfa7",
            price: 67218.19,
            price_sources: [],
            processed_ms: 1711323821660,
          },
          {
            leverage: 0.3212890625,
            order_type: "LONG",
            order_uuid: "0d2e1516-6996-474d-978f-2f01758fa84f",
            price: 67246.58,
            price_sources: [],
            processed_ms: 1711324753422,
          },
          {
            leverage: 0.125,
            order_type: "LONG",
            order_uuid: "efb72627-1bdf-4f5c-911a-c8db15bd19e6",
            price: 66919.93,
            price_sources: [],
            processed_ms: 1711325710375,
          },
          {
            leverage: 0.125,
            order_type: "LONG",
            order_uuid: "c7529202-b5bf-41ae-9628-3a5e5c6f5853",
            price: 66883.12,
            price_sources: [],
            processed_ms: 1711326624810,
          },
          {
            leverage: 0.125,
            order_type: "LONG",
            order_uuid: "da07d49c-353b-480d-a13d-81b464f30156",
            price: 66637.68,
            price_sources: [],
            processed_ms: 1711327556315,
          },
        ],
        position_type: "FLAT",
        position_uuid: "676a95f7-9924-4422-8d48-6111f5f9861f",
        return_at_close: 0.9927127376286262,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 65977.84666666666,
        close_ms: 1711336326701,
        current_return: 0.9981916527024306,
        initial_entry_price: 66604.67,
        is_closed_position: true,
        miner_hotkey: "5E6wyDU9W9eeY8S5DMBgxkFKPgpdjgBQRwADFJY8g35jaES6",
        net_leverage: 0.0,
        open_ms: 1711328486374,
        orders: [
          {
            leverage: -1.25,
            order_type: "SHORT",
            order_uuid: "68d8fe9b-9dae-44c5-b10a-f478afdfdbb5",
            price: 66604.67,
            price_sources: [],
            processed_ms: 1711328486372,
          },
          {
            leverage: 0.125,
            order_type: "LONG",
            order_uuid: "c900023c-c2c2-4a10-aa67-8d452bf89ee3",
            price: 66948.71,
            price_sources: [],
            processed_ms: 1711329436846,
          },
          {
            leverage: 0.5625,
            order_type: "LONG",
            order_uuid: "89cd4a8a-218b-4166-95cb-da23dace45f5",
            price: 66687.26,
            price_sources: [],
            processed_ms: 1711330844500,
          },
          {
            leverage: 0.28125,
            order_type: "LONG",
            order_uuid: "906061c2-9021-4a4a-8997-c2a648285a97",
            price: 66579.78,
            price_sources: [],
            processed_ms: 1711332674826,
          },
          {
            leverage: 0.140625,
            order_type: "LONG",
            order_uuid: "3a3442c8-d9ea-402a-bc5d-dd74b3764c01",
            price: 66645.1,
            price_sources: [],
            processed_ms: 1711334507582,
          },
          {
            leverage: 1.25,
            order_type: "LONG",
            order_uuid: "0f4d6466-b3c4-488c-b5b3-5ff26e751af1",
            price: 66834.34,
            price_sources: [],
            processed_ms: 1711336326701,
          },
        ],
        position_type: "FLAT",
        position_uuid: "ed8cfde3-a81f-4c6d-8ea7-cc187ff08f7e",
        return_at_close: 0.9956961735706745,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 67066.73,
        close_ms: 1711339991626,
        current_return: 1.004014658236655,
        initial_entry_price: 67066.73,
        is_closed_position: true,
        miner_hotkey: "5E6wyDU9W9eeY8S5DMBgxkFKPgpdjgBQRwADFJY8g35jaES6",
        net_leverage: 0.0,
        open_ms: 1711338185005,
        orders: [
          {
            leverage: 1.25,
            order_type: "LONG",
            order_uuid: "77ba794c-e934-48ec-bcd9-31d36004c3c8",
            price: 67066.73,
            price_sources: [],
            processed_ms: 1711338185003,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "2e9a86ff-8830-4300-b21d-5ecf4610fe03",
            price: 67282.13,
            price_sources: [],
            processed_ms: 1711339991626,
          },
        ],
        position_type: "FLAT",
        position_uuid: "37d63925-dec3-40ed-9d7b-4d66af7b91f4",
        return_at_close: 1.0015046215910632,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 66994.75,
        close_ms: 1711361968791,
        current_return: 1.0039139260912235,
        initial_entry_price: 66994.75,
        is_closed_position: true,
        miner_hotkey: "5E6wyDU9W9eeY8S5DMBgxkFKPgpdjgBQRwADFJY8g35jaES6",
        net_leverage: 0.0,
        open_ms: 1711356473992,
        orders: [
          {
            leverage: 1.25,
            order_type: "LONG",
            order_uuid: "34a68423-a702-4d85-928b-fe2c72c63f24",
            price: 66994.75,
            price_sources: [],
            processed_ms: 1711356473991,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "87566223-072d-49b9-99f8-bfb63b4b6973",
            price: 67204.52,
            price_sources: [],
            processed_ms: 1711361968791,
          },
        ],
        position_type: "FLAT",
        position_uuid: "0a47a346-b612-46db-868a-0d7c1ccc5fc4",
        return_at_close: 1.0014041412759955,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 66952.89928571424,
        close_ms: 1711376099858,
        current_return: 1.09905487485992,
        initial_entry_price: 66925.0,
        is_closed_position: true,
        miner_hotkey: "5E6wyDU9W9eeY8S5DMBgxkFKPgpdjgBQRwADFJY8g35jaES6",
        net_leverage: 0.0,
        open_ms: 1711363808082,
        orders: [
          {
            leverage: 1.25,
            order_type: "LONG",
            order_uuid: "b4d8ef7e-33de-409a-a760-dd4da9b7aa28",
            price: 66925.0,
            price_sources: [],
            processed_ms: 1711363808080,
          },
          {
            leverage: 1.25,
            order_type: "LONG",
            order_uuid: "e4d22b24-6f5c-40a4-9050-f31482d4c19f",
            price: 66874.84,
            price_sources: [],
            processed_ms: 1711367274985,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "056ca513-b1b1-4f8a-a505-302fee27f235",
            price: 67092.12,
            price_sources: [],
            processed_ms: 1711369101816,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "830fd9f4-ad4d-4988-9f9f-77d207acc735",
            price: 67141.08,
            price_sources: [],
            processed_ms: 1711370966474,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "4149a82d-b625-46f9-82b8-7982c2e9d0ff",
            price: 66898.93,
            price_sources: [],
            processed_ms: 1711372799019,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "a75cb866-ff0b-419a-9e11-14362e3a4790",
            price: 67209.26,
            price_sources: [],
            processed_ms: 1711374605192,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "05b0da15-968a-4b52-bc5f-cec329216f0a",
            price: 68846.97,
            price_sources: [],
            processed_ms: 1711376099858,
          },
        ],
        position_type: "FLAT",
        position_uuid: "49610979-101f-4c09-a68d-a1c854e38080",
        return_at_close: 1.0913614907359006,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 69272.14,
        close_ms: 1711376946270,
        current_return: 1.0,
        initial_entry_price: 69272.14,
        is_closed_position: true,
        miner_hotkey: "5E6wyDU9W9eeY8S5DMBgxkFKPgpdjgBQRwADFJY8g35jaES6",
        net_leverage: 0.0,
        open_ms: 1711376927648,
        orders: [
          {
            leverage: 1.25,
            order_type: "LONG",
            order_uuid: "9f828086-906c-4eed-82f7-0d0af7bead78",
            price: 69272.14,
            price_sources: [],
            processed_ms: 1711376927646,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "65ea4c6c-a5fb-4692-b38d-82f8e9dccaad",
            price: 69272.14,
            price_sources: [],
            processed_ms: 1711376946270,
          },
        ],
        position_type: "FLAT",
        position_uuid: "08538420-79b6-4fd6-940b-4cc30707ba7e",
        return_at_close: 0.9975,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: -159694.7899999985,
        close_ms: 1711394208739,
        current_return: 0.98336703181986,
        initial_entry_price: 69238.52,
        is_closed_position: true,
        miner_hotkey: "5E6wyDU9W9eeY8S5DMBgxkFKPgpdjgBQRwADFJY8g35jaES6",
        net_leverage: 0.0,
        open_ms: 1711378344549,
        orders: [
          {
            leverage: -1.25,
            order_type: "SHORT",
            order_uuid: "af3cf683-449f-443e-b257-dc6e52097559",
            price: 69238.52,
            price_sources: [],
            processed_ms: 1711378344547,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "0e34f1a9-bf4e-4a4c-9f28-891a664fea20",
            price: 69772.64,
            price_sources: [],
            processed_ms: 1711381464782,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "91530b51-2c43-4ee2-803a-c7899d601e46",
            price: 69833.53,
            price_sources: [],
            processed_ms: 1711383279861,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "a1e023e4-3217-4d75-bec3-c9add31c85ba",
            price: 70071.83,
            price_sources: [],
            processed_ms: 1711385110416,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "143c6848-7912-4dd7-828d-b174b25c29d5",
            price: 70574.27,
            price_sources: [],
            processed_ms: 1711386928744,
          },
          {
            leverage: 0.125,
            order_type: "LONG",
            order_uuid: "76c89589-5796-401d-9653-f7492936ed7b",
            price: 70477.89,
            price_sources: [],
            processed_ms: 1711388759960,
          },
          {
            leverage: 0.1,
            order_type: "LONG",
            order_uuid: "b405c90a-8642-47ed-a64f-fbdb41b3dc62",
            price: 70593.26,
            price_sources: [],
            processed_ms: 1711390555543,
          },
          {
            leverage: 0.019999999999999997,
            order_type: "LONG",
            order_uuid: "9d6fa224-3a48-42f9-85d7-d74c0c43b670",
            price: 70724.71,
            price_sources: [],
            processed_ms: 1711392370657,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "86853d90-ad96-4881-95f1-b75064546b2d",
            price: 70633.63,
            price_sources: [],
            processed_ms: 1711394208739,
          },
        ],
        position_type: "FLAT",
        position_uuid: "856cb2f4-9f24-4758-b76c-0ae317ec8da5",
        return_at_close: 0.9809086142403104,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 70550.45384615383,
        close_ms: 1711410579787,
        current_return: 0.9653950484176032,
        initial_entry_price: 70832.71,
        is_closed_position: true,
        miner_hotkey: "5E6wyDU9W9eeY8S5DMBgxkFKPgpdjgBQRwADFJY8g35jaES6",
        net_leverage: 0.0,
        open_ms: 1711395999694,
        orders: [
          {
            leverage: 1.25,
            order_type: "LONG",
            order_uuid: "14b28cdd-8549-431d-9c04-0bea6645a206",
            price: 70832.71,
            price_sources: [],
            processed_ms: 1711395999692,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "e45fcd5a-3d1f-4d0a-9f0a-7b09002bad69",
            price: 70881.69,
            price_sources: [],
            processed_ms: 1711397834934,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "aec7e89e-9941-47a2-a1e9-36e2fdcdb32c",
            price: 70949.97,
            price_sources: [],
            processed_ms: 1711399668838,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "1e7c5661-ed19-4eb8-9f70-800c4928c695",
            price: 70123.98,
            price_sources: [],
            processed_ms: 1711401469771,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "d22f1f9f-5fa0-4f9e-b76e-6b15f0734c28",
            price: 70008.49,
            price_sources: [],
            processed_ms: 1711403287376,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "2d872348-23f7-4b6e-956c-c8a63855c446",
            price: 70399.7,
            price_sources: [],
            processed_ms: 1711405096597,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "084bbd53-c391-4cec-9ecc-056573c113ee",
            price: 70451.11,
            price_sources: [],
            processed_ms: 1711406909770,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "1323b46e-d128-4dd0-8450-30b4337b30cf",
            price: 70381.16,
            price_sources: [],
            processed_ms: 1711408732928,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "8b99a741-0bea-46ca-a248-c3e0644c3087",
            price: 69796.25,
            price_sources: [],
            processed_ms: 1711410561045,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "711378ee-3395-421d-ad9e-98a73cbd5008",
            price: 69796.25,
            price_sources: [],
            processed_ms: 1711410579787,
          },
        ],
        position_type: "FLAT",
        position_uuid: "f2ccd99e-61bf-44b1-8045-eef6e4199410",
        return_at_close: 0.9591199806028888,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: -417614.5999999968,
        close_ms: 1711443253027,
        current_return: 1.0142144149077452,
        initial_entry_price: 69763.87,
        is_closed_position: true,
        miner_hotkey: "5E6wyDU9W9eeY8S5DMBgxkFKPgpdjgBQRwADFJY8g35jaES6",
        net_leverage: 0.0,
        open_ms: 1711412365040,
        orders: [
          {
            leverage: 1.25,
            order_type: "LONG",
            order_uuid: "20e7c84d-6f11-465f-8301-d98a47036297",
            price: 69763.87,
            price_sources: [],
            processed_ms: 1711412365038,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "7005133a-7354-49a4-b699-c71ac8ff7758",
            price: 70041.74,
            price_sources: [],
            processed_ms: 1711414183038,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "d0901ae1-c8aa-469c-b529-95e4532add69",
            price: 70229.14,
            price_sources: [],
            processed_ms: 1711416008924,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "b4890ba1-9f8f-4de8-9854-f5b573fdccb1",
            price: 70193.4,
            price_sources: [],
            processed_ms: 1711417817892,
          },
          {
            leverage: -1.0,
            order_type: "SHORT",
            order_uuid: "8415b44e-a25b-4175-adad-9a09a615e288",
            price: 70187.21,
            price_sources: [],
            processed_ms: 1711419636170,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "594d04fe-68e8-4684-9ea0-6aedc3a5e64c",
            price: 70252.78,
            price_sources: [],
            processed_ms: 1711421455881,
          },
          {
            leverage: -0.625,
            order_type: "SHORT",
            order_uuid: "b035cca9-d018-4f61-9f1b-54d4915f851d",
            price: 70502.95,
            price_sources: [],
            processed_ms: 1711423286886,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "4bb81ac3-41b2-478d-925c-afb6b23bcaea",
            price: 70461.21,
            price_sources: [],
            processed_ms: 1711425081150,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "1e215b38-5cef-4011-915f-64a5002b0c90",
            price: 70537.82,
            price_sources: [],
            processed_ms: 1711426931123,
          },
          {
            leverage: -0.5625,
            order_type: "SHORT",
            order_uuid: "e2cd93d8-6006-4469-b74e-d50e40f56c38",
            price: 70643.51,
            price_sources: [],
            processed_ms: 1711428719731,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "5990a4eb-8895-4d9b-94ec-dc0572cdd29b",
            price: 70540.42,
            price_sources: [],
            processed_ms: 1711430530795,
          },
          {
            leverage: -0.40625,
            order_type: "SHORT",
            order_uuid: "5ce27663-0925-477e-93db-f7726641627b",
            price: 70424.67,
            price_sources: [],
            processed_ms: 1711432344952,
          },
          {
            leverage: -0.203125,
            order_type: "SHORT",
            order_uuid: "92de615b-839c-46f9-b93a-1caab4e91653",
            price: 70387.52,
            price_sources: [],
            processed_ms: 1711434165904,
          },
          {
            leverage: -0.1015625,
            order_type: "SHORT",
            order_uuid: "85478158-a87b-4fc4-8a7c-68a1d64214a8",
            price: 70485.97,
            price_sources: [],
            processed_ms: 1711436001294,
          },
          {
            leverage: -0.05078125,
            order_type: "SHORT",
            order_uuid: "23d491bf-c695-412a-891e-b639a2059b3f",
            price: 71112.9,
            price_sources: [],
            processed_ms: 1711437795773,
          },
          {
            leverage: -0.040625,
            order_type: "SHORT",
            order_uuid: "1e92c93c-86e7-4fa0-bb25-54b41e60575d",
            price: 70823.65,
            price_sources: [],
            processed_ms: 1711439619911,
          },
          {
            leverage: -0.008124999999999999,
            order_type: "SHORT",
            order_uuid: "0a0926ce-d038-4ed5-adb9-6f3e3fe96dab",
            price: 70656.65,
            price_sources: [],
            processed_ms: 1711441469537,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "0f397de0-5130-4c7b-b841-ffe361f793a4",
            price: 70583.6,
            price_sources: [],
            processed_ms: 1711443253027,
          },
        ],
        position_type: "FLAT",
        position_uuid: "817c03e5-eb4c-43a1-a8b6-43986eaf3d97",
        return_at_close: 1.0101575572481143,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 70768.51999999996,
        close_ms: 1711454148932,
        current_return: 1.0001819031231105,
        initial_entry_price: 70958.1,
        is_closed_position: true,
        miner_hotkey: "5E6wyDU9W9eeY8S5DMBgxkFKPgpdjgBQRwADFJY8g35jaES6",
        net_leverage: 0.0,
        open_ms: 1711445059913,
        orders: [
          {
            leverage: -1.25,
            order_type: "SHORT",
            order_uuid: "9ff00435-3bd9-4309-bd61-f963480978c1",
            price: 70958.1,
            price_sources: [],
            processed_ms: 1711445059907,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "3076c51b-62af-4a90-98b0-31bcf9743686",
            price: 71191.53,
            price_sources: [],
            processed_ms: 1711446876850,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "3fd81c28-4616-4774-ae88-608061fe48ea",
            price: 71020.94,
            price_sources: [],
            processed_ms: 1711448740893,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "03967f8d-f36f-40bd-a3b9-2283bc611d50",
            price: 70957.91,
            price_sources: [],
            processed_ms: 1711450514970,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "03de16f8-b3bc-4c4d-ac67-2c63be4569f2",
            price: 70851.6,
            price_sources: [],
            processed_ms: 1711452331030,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "3dac802e-2ea5-4b7a-9965-804f13fb85b1",
            price: 70716.89,
            price_sources: [],
            processed_ms: 1711454148932,
          },
        ],
        position_type: "FLAT",
        position_uuid: "3fb2a653-c65c-42cb-b37a-7a5d9cf6db98",
        return_at_close: 0.9976814483653028,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 338950.91000000155,
        close_ms: 1711470498123,
        current_return: 1.0075954644768808,
        initial_entry_price: 70876.94,
        is_closed_position: true,
        miner_hotkey: "5E6wyDU9W9eeY8S5DMBgxkFKPgpdjgBQRwADFJY8g35jaES6",
        net_leverage: 0.0,
        open_ms: 1711457777828,
        orders: [
          {
            leverage: -1.25,
            order_type: "SHORT",
            order_uuid: "28116184-7c3c-40c7-a457-52c5dc385b5c",
            price: 70876.94,
            price_sources: [],
            processed_ms: 1711457777826,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "e8e71b31-ca5f-4500-9f02-775d7402351f",
            price: 70844.58,
            price_sources: [],
            processed_ms: 1711459601379,
          },
          {
            leverage: 0.5,
            order_type: "LONG",
            order_uuid: "3bdcab26-5120-419a-9429-922f29fd45f8",
            price: 70629.17,
            price_sources: [],
            processed_ms: 1711461415055,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "041406c3-5582-49f0-9de6-0dbc00c5fc7d",
            price: 69884.23,
            price_sources: [],
            processed_ms: 1711463235066,
          },
          {
            leverage: 0.2,
            order_type: "LONG",
            order_uuid: "308104bd-8c8a-4803-9746-dbf89c07fa5a",
            price: 70284.62,
            price_sources: [],
            processed_ms: 1711465048017,
          },
          {
            leverage: 0.039999999999999994,
            order_type: "LONG",
            order_uuid: "482ee32b-1e00-40b3-8943-afb9beee4bf4",
            price: 70058.39,
            price_sources: [],
            processed_ms: 1711466869184,
          },
          {
            leverage: 0.007999999999999997,
            order_type: "LONG",
            order_uuid: "9132e6b0-8c68-47e7-8b7d-25de72d0cb79",
            price: 70278.26,
            price_sources: [],
            processed_ms: 1711468679930,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "99adc757-b3b3-410f-a95f-ba4b68382df3",
            price: 69779.27,
            price_sources: [],
            processed_ms: 1711470498123,
          },
        ],
        position_type: "FLAT",
        position_uuid: "0ced3071-b3f1-4da8-a917-8626f6972f9a",
        return_at_close: 1.0050764758156887,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 70102.88672131149,
        close_ms: 1711524643338,
        current_return: 0.9701647508056096,
        initial_entry_price: 69711.04,
        is_closed_position: true,
        miner_hotkey: "5E6wyDU9W9eeY8S5DMBgxkFKPgpdjgBQRwADFJY8g35jaES6",
        net_leverage: 0.0,
        open_ms: 1711472315287,
        orders: [
          {
            leverage: 1.25,
            order_type: "LONG",
            order_uuid: "783b8560-6f59-499b-b792-532a07cd5e43",
            price: 69711.04,
            price_sources: [],
            processed_ms: 1711472315284,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "b8ba8c2a-c116-4ae4-bb43-22a26a58df16",
            price: 70051.08,
            price_sources: [],
            processed_ms: 1711474163811,
          },
          {
            leverage: -0.75,
            order_type: "SHORT",
            order_uuid: "df972280-461b-4ec6-b6f4-0143349cf1da",
            price: 70138.69,
            price_sources: [],
            processed_ms: 1711475958140,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "7694d3aa-321d-43da-ae73-8d63832fe1c1",
            price: 70248.39,
            price_sources: [],
            processed_ms: 1711477778919,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "54d00f00-9960-4725-b836-468b1cb571a5",
            price: 70088.88,
            price_sources: [],
            processed_ms: 1711479596326,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "228770a6-e262-4d93-918c-417972614fa4",
            price: 69959.1,
            price_sources: [],
            processed_ms: 1711481411493,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "f51f72b0-6356-4d5f-838b-797ec9e11438",
            price: 69445.99,
            price_sources: [],
            processed_ms: 1711483225234,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "ca733f02-8fa3-4eba-bff7-60082e16c51c",
            price: 69740.78,
            price_sources: [],
            processed_ms: 1711485043149,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "48a36b9b-98e1-419f-b637-aa6f7cfbd8ab",
            price: 69820.75,
            price_sources: [],
            processed_ms: 1711486864616,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "404bef29-8f5c-405a-bb04-f9c113d5c7b9",
            price: 70121.64,
            price_sources: [],
            processed_ms: 1711488684970,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "b5531dc0-d5e5-49b9-a2e9-5597a30b4de5",
            price: 70007.35,
            price_sources: [],
            processed_ms: 1711490500027,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "1a4ae787-2a0b-48b8-ad9b-1e8a986cf01b",
            price: 69802.16,
            price_sources: [],
            processed_ms: 1711492337891,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "62bb46f5-2bf8-4820-926a-8adabca3c6c7",
            price: 70261.53,
            price_sources: [],
            processed_ms: 1711494135890,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "b4c1a86d-fe4e-40a1-afc4-0570e80b20d4",
            price: 70078.5,
            price_sources: [],
            processed_ms: 1711495951769,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "0b933e0b-59b0-44da-bb29-5fa94e994b01",
            price: 69976.4,
            price_sources: [],
            processed_ms: 1711497764021,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "0394593c-9105-4e0d-baaa-8156f4fffd6c",
            price: 70117.33,
            price_sources: [],
            processed_ms: 1711499576919,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "9fd10866-5af1-4f91-b18a-c81fba63ccea",
            price: 70613.03,
            price_sources: [],
            processed_ms: 1711501397998,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "0cf0162e-a984-4a0f-8670-2f3158d6e74a",
            price: 70504.48,
            price_sources: [],
            processed_ms: 1711503218838,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "b9e8cc6d-5829-418c-a34f-66a08d17e2a7",
            price: 70303.87,
            price_sources: [],
            processed_ms: 1711505032043,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "6bbff550-2bc0-4722-b5ef-192ed1aa1051",
            price: 70436.86,
            price_sources: [],
            processed_ms: 1711506849551,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "b565655c-98ad-4b7f-bc0f-58872ff82985",
            price: 70585.35,
            price_sources: [],
            processed_ms: 1711508661937,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "cbdaed83-3c92-43b7-a9b7-0d3ac5cb49ed",
            price: 70465.51,
            price_sources: [],
            processed_ms: 1711510525981,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "3c3727ca-ba9b-4ebd-af53-15ae42f7dc46",
            price: 70429.17,
            price_sources: [],
            processed_ms: 1711512294543,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "79d47ffb-8dd0-4689-b283-c0d9426d7d57",
            price: 70362.69,
            price_sources: [],
            processed_ms: 1711514119059,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "ab4f7109-ca8e-4a50-a1d7-9bf0e41f869a",
            price: 70224.23,
            price_sources: [],
            processed_ms: 1711515936766,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "15511d62-a443-407c-8395-627afeac8302",
            price: 70421.48,
            price_sources: [],
            processed_ms: 1711517745028,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "81a52ee2-0cb7-4849-ba66-a257b6edd4f6",
            price: 70492.72,
            price_sources: [],
            processed_ms: 1711519588757,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "60361005-f660-4bd8-a461-109b6dfcac28",
            price: 70283.26,
            price_sources: [],
            processed_ms: 1711521376743,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "2a14a2c4-f9e6-4170-a8ba-2f5955a0c9e3",
            price: 70263.17,
            price_sources: [],
            processed_ms: 1711523195031,
          },
          {
            leverage: 0.375,
            order_type: "LONG",
            order_uuid: "00d4b089-c7de-4de6-8582-0cf78c7e6455",
            price: 69928.81,
            price_sources: [],
            processed_ms: 1711524023663,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "a9b2d808-3491-4ebe-8f72-b3a5a3dcbba1",
            price: 69830.12,
            price_sources: [],
            processed_ms: 1711524643338,
          },
        ],
        position_type: "FLAT",
        position_uuid: "8b2482e5-d797-41fd-bb61-9b01e4c357fa",
        return_at_close: 0.9553697383558241,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 69930.4293548387,
        close_ms: 1711545204528,
        current_return: 1.0738864156731367,
        initial_entry_price: 69832.61,
        is_closed_position: true,
        miner_hotkey: "5E6wyDU9W9eeY8S5DMBgxkFKPgpdjgBQRwADFJY8g35jaES6",
        net_leverage: 0.0,
        open_ms: 1711525708924,
        orders: [
          {
            leverage: 1.25,
            order_type: "LONG",
            order_uuid: "9bba20f9-c8b2-4385-be63-703fdfcf546c",
            price: 69832.61,
            price_sources: [],
            processed_ms: 1711525708921,
          },
          {
            leverage: 0.375,
            order_type: "LONG",
            order_uuid: "484781fd-c764-4ec3-9324-224dee995125",
            price: 69898.83,
            price_sources: [],
            processed_ms: 1711527531569,
          },
          {
            leverage: 0.375,
            order_type: "LONG",
            order_uuid: "8844aefa-f440-4123-94a2-80aae26012e4",
            price: 69762.9,
            price_sources: [],
            processed_ms: 1711529351687,
          },
          {
            leverage: 0.375,
            order_type: "LONG",
            order_uuid: "75355403-4c67-46ec-8099-1a8bca0bf049",
            price: 69743.7,
            price_sources: [],
            processed_ms: 1711531160189,
          },
          {
            leverage: 0.375,
            order_type: "LONG",
            order_uuid: "3acad78a-254d-4723-a5bd-2790a3dc86ae",
            price: 69987.03,
            price_sources: [],
            processed_ms: 1711532994084,
          },
          {
            leverage: 0.375,
            order_type: "LONG",
            order_uuid: "e3b5c9be-5012-44f9-b48c-9061e3f36838",
            price: 70001.02,
            price_sources: [],
            processed_ms: 1711534794551,
          },
          {
            leverage: 0.375,
            order_type: "LONG",
            order_uuid: "c6a85746-24f9-4230-b139-c82c6e676bdb",
            price: 70179.6,
            price_sources: [],
            processed_ms: 1711538041637,
          },
          {
            leverage: 0.375,
            order_type: "LONG",
            order_uuid: "4f3933a7-73e7-4d36-af6d-3c66427e038e",
            price: 70265.99,
            price_sources: [],
            processed_ms: 1711541642138,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "ad05bd62-a0e4-4c98-8842-a7b3d729f721",
            price: 71261.96,
            price_sources: [],
            processed_ms: 1711545204528,
          },
        ],
        position_type: "FLAT",
        position_uuid: "66fdb7ce-e541-4656-a37d-f8d2e54f747c",
        return_at_close: 1.06556379595167,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 71219.49,
        close_ms: 1711548301017,
        current_return: 0.9633855493770034,
        initial_entry_price: 71219.49,
        is_closed_position: true,
        miner_hotkey: "5E6wyDU9W9eeY8S5DMBgxkFKPgpdjgBQRwADFJY8g35jaES6",
        net_leverage: 0.0,
        open_ms: 1711545308637,
        orders: [
          {
            leverage: 1.25,
            order_type: "LONG",
            order_uuid: "59c7f20d-f645-4f7c-a912-427a6476759b",
            price: 71219.49,
            price_sources: [],
            processed_ms: 1711545308634,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "67313e93-365f-40a2-9cb9-438b26ab72de",
            price: 69133.36,
            price_sources: [],
            processed_ms: 1711548301017,
          },
        ],
        position_type: "FLAT",
        position_uuid: "18209489-6c9d-4078-9d9c-bf487b21d29d",
        return_at_close: 0.9609770855035609,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 67065.31857142856,
        close_ms: 1711563369178,
        current_return: 0.9994006144025486,
        initial_entry_price: 69000.67,
        is_closed_position: true,
        miner_hotkey: "5E6wyDU9W9eeY8S5DMBgxkFKPgpdjgBQRwADFJY8g35jaES6",
        net_leverage: 0.0,
        open_ms: 1711548910025,
        orders: [
          {
            leverage: -1.25,
            order_type: "SHORT",
            order_uuid: "68ffdedb-1643-4d58-a7ef-2154a906637f",
            price: 69000.67,
            price_sources: [],
            processed_ms: 1711548910021,
          },
          {
            leverage: 0.375,
            order_type: "LONG",
            order_uuid: "4cc3f68f-19b5-420b-917b-b79719d5ab59",
            price: 69190.12,
            price_sources: [],
            processed_ms: 1711552491905,
          },
          {
            leverage: 0.4375,
            order_type: "LONG",
            order_uuid: "4e397941-8e85-4ff7-ba69-8adc2d920d2c",
            price: 69110.73,
            price_sources: [],
            processed_ms: 1711554265505,
          },
          {
            leverage: 0.21875,
            order_type: "LONG",
            order_uuid: "2976ad32-4f16-4d1c-9239-fba0f1852af6",
            price: 68509.98,
            price_sources: [],
            processed_ms: 1711556083512,
          },
          {
            leverage: 0.109375,
            order_type: "LONG",
            order_uuid: "57394c62-4fd2-4452-b6f8-80afcf5f96da",
            price: 69159.07,
            price_sources: [],
            processed_ms: 1711557901351,
          },
          {
            leverage: 0.0546875,
            order_type: "LONG",
            order_uuid: "6bd45f47-17d6-4269-ba84-0f19e174c8a8",
            price: 69388.51,
            price_sources: [],
            processed_ms: 1711559721669,
          },
          {
            leverage: 0.02734375,
            order_type: "LONG",
            order_uuid: "eb8ae9cc-9a19-4c1c-bb22-b0513faab325",
            price: 69093.13,
            price_sources: [],
            processed_ms: 1711561534576,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "395f39c1-14f9-45a7-bbdc-84d62b8b3170",
            price: 68577.84,
            price_sources: [],
            processed_ms: 1711563369178,
          },
        ],
        position_type: "FLAT",
        position_uuid: "c211b020-6974-4804-b90a-9259c1646356",
        return_at_close: 0.9969021128665423,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 67228.18999999996,
        close_ms: 1711577879612,
        current_return: 0.9908094446832985,
        initial_entry_price: 68847.2,
        is_closed_position: true,
        miner_hotkey: "5E6wyDU9W9eeY8S5DMBgxkFKPgpdjgBQRwADFJY8g35jaES6",
        net_leverage: 0.0,
        open_ms: 1711570619920,
        orders: [
          {
            leverage: -3.5,
            order_type: "SHORT",
            order_uuid: "1ea398c4-fe99-4610-aa67-6ded748d3b9d",
            price: 68847.2,
            price_sources: [],
            processed_ms: 1711570619917,
          },
          {
            leverage: 1.0499999999999998,
            order_type: "LONG",
            order_uuid: "0e0325e6-fada-4304-bbf4-3f5efc631d57",
            price: 68936.45,
            price_sources: [],
            processed_ms: 1711572434995,
          },
          {
            leverage: 1.05,
            order_type: "LONG",
            order_uuid: "3a66adac-99dc-43bb-818b-c39826a653cd",
            price: 69069.14,
            price_sources: [],
            processed_ms: 1711574265826,
          },
          {
            leverage: 1.05,
            order_type: "LONG",
            order_uuid: "7e9f7912-b36e-4850-b600-441db9993e82",
            price: 69075.68,
            price_sources: [],
            processed_ms: 1711576081624,
          },
          {
            leverage: 1.05,
            order_type: "LONG",
            order_uuid: "219a6d95-c5f5-4aa7-bef9-cb6b72a0f1b0",
            price: 69036.03,
            price_sources: [],
            processed_ms: 1711577879612,
          },
        ],
        position_type: "FLAT",
        position_uuid: "6c6887e3-a8c4-4e3e-ae4b-ef78cb49933b",
        return_at_close: 0.9838737785705154,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 69934.7,
        close_ms: 1711588785290,
        current_return: 1.001250694431728,
        initial_entry_price: 69355.27,
        is_closed_position: true,
        miner_hotkey: "5E6wyDU9W9eeY8S5DMBgxkFKPgpdjgBQRwADFJY8g35jaES6",
        net_leverage: 0.0,
        open_ms: 1711579705789,
        orders: [
          {
            leverage: -3.5,
            order_type: "SHORT",
            order_uuid: "c56f0427-d4f2-48a8-b90d-0441e32673cc",
            price: 69355.27,
            price_sources: [],
            processed_ms: 1711579705786,
          },
          {
            leverage: 1.0499999999999998,
            order_type: "LONG",
            order_uuid: "3800cb09-1111-4666-854f-2197a1b2e01a",
            price: 69238.73,
            price_sources: [],
            processed_ms: 1711581516422,
          },
          {
            leverage: 1.05,
            order_type: "LONG",
            order_uuid: "f2c4574c-02b4-47a7-868f-6ed34f8bf07d",
            price: 69136.11,
            price_sources: [],
            processed_ms: 1711583331811,
          },
          {
            leverage: 1.05,
            order_type: "LONG",
            order_uuid: "a4bab6d1-d3c0-481a-a7dc-462c2423102e",
            price: 69529.72,
            price_sources: [],
            processed_ms: 1711585162189,
          },
          {
            leverage: 0.17500000000000004,
            order_type: "LONG",
            order_uuid: "9f1e816c-25e9-4450-9224-ff7bf0363ecd",
            price: 69743.34,
            price_sources: [],
            processed_ms: 1711586972073,
          },
          {
            leverage: 1.05,
            order_type: "LONG",
            order_uuid: "8bb91590-7e95-428a-9f42-a6f47d083c7a",
            price: 69439.03,
            price_sources: [],
            processed_ms: 1711588785290,
          },
        ],
        position_type: "FLAT",
        position_uuid: "5d81429a-33a6-44b0-bd64-691921b009d8",
        return_at_close: 0.994241939570706,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 88953.93000000042,
        close_ms: 1711618479572,
        current_return: 1.0072000334586722,
        initial_entry_price: 69453.22,
        is_closed_position: true,
        miner_hotkey: "5E6wyDU9W9eeY8S5DMBgxkFKPgpdjgBQRwADFJY8g35jaES6",
        net_leverage: 0.0,
        open_ms: 1711590621332,
        orders: [
          {
            leverage: -3.5,
            order_type: "SHORT",
            order_uuid: "f9fd3ea5-65fa-4326-86f2-0ed8a4545550",
            price: 69453.22,
            price_sources: [],
            processed_ms: 1711590621329,
          },
          {
            leverage: 1.75,
            order_type: "LONG",
            order_uuid: "31394e40-ab75-4b11-ad62-6cf9da0b403e",
            price: 69420.73,
            price_sources: [],
            processed_ms: 1711592423602,
          },
          {
            leverage: 0.875,
            order_type: "LONG",
            order_uuid: "a6a3983b-87ed-4b03-ac03-374696a47359",
            price: 69046.86,
            price_sources: [],
            processed_ms: 1711594232647,
          },
          {
            leverage: 0.4375,
            order_type: "LONG",
            order_uuid: "93a9ec8d-3dfa-4ea2-baf4-b3743dd91e9d",
            price: 69243.45,
            price_sources: [],
            processed_ms: 1711596051658,
          },
          {
            leverage: 0.21875,
            order_type: "LONG",
            order_uuid: "67d23c84-511b-4389-8e3b-51f72adba5fa",
            price: 69232.33,
            price_sources: [],
            processed_ms: 1711597898508,
          },
          {
            leverage: 0.109375,
            order_type: "LONG",
            order_uuid: "fa7a30a8-3d67-4fad-8f3e-efb64339690f",
            price: 69486.99,
            price_sources: [],
            processed_ms: 1711599697581,
          },
          {
            leverage: 0.0546875,
            order_type: "LONG",
            order_uuid: "02d8d101-84d5-4924-9d7b-f644a3e6bb8a",
            price: 69657.73,
            price_sources: [],
            processed_ms: 1711601501807,
          },
          {
            leverage: 0.02734375,
            order_type: "LONG",
            order_uuid: "93c9dd7c-7f03-4916-bd23-f57df76a593d",
            price: 69614.73,
            price_sources: [],
            processed_ms: 1711603334747,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "569bb181-bfdf-4572-b464-ec699397ffd9",
            price: 70665.82,
            price_sources: [],
            processed_ms: 1711618479572,
          },
        ],
        position_type: "FLAT",
        position_uuid: "4ac925b5-1d8b-4bb6-a6ea-a37a96c970bc",
        return_at_close: 1.0001496332244615,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 70867.26,
        close_ms: 1711624488552,
        current_return: 1.0046563109678572,
        initial_entry_price: 70867.26,
        is_closed_position: true,
        miner_hotkey: "5E6wyDU9W9eeY8S5DMBgxkFKPgpdjgBQRwADFJY8g35jaES6",
        net_leverage: 0.0,
        open_ms: 1711624401700,
        orders: [
          {
            leverage: -3.5,
            order_type: "SHORT",
            order_uuid: "74a41908-87be-45c9-b79e-f85dfe76ec98",
            price: 70867.26,
            price_sources: [],
            processed_ms: 1711624401694,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "bf6fab03-90b0-4b39-b237-f4506f6ad68c",
            price: 70772.98,
            price_sources: [],
            processed_ms: 1711624488552,
          },
        ],
        position_type: "FLAT",
        position_uuid: "f136e79b-a804-42d9-8663-1dc547d8a231",
        return_at_close: 0.9976237167910822,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 70977.01,
        close_ms: 1711633607669,
        current_return: 1.010442254470849,
        initial_entry_price: 70977.01,
        is_closed_position: true,
        miner_hotkey: "5E6wyDU9W9eeY8S5DMBgxkFKPgpdjgBQRwADFJY8g35jaES6",
        net_leverage: 0.0,
        open_ms: 1711633506652,
        orders: [
          {
            leverage: -3.5,
            order_type: "SHORT",
            order_uuid: "007a563e-c914-41c1-97cb-211c4d8d8836",
            price: 70977.01,
            price_sources: [],
            processed_ms: 1711633506649,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "b76849e2-6fca-4b07-ba88-6231ecfac222",
            price: 70765.25,
            price_sources: [],
            processed_ms: 1711633607669,
          },
        ],
        position_type: "FLAT",
        position_uuid: "c469eafb-b1fd-4e47-8f2b-4e530bdcc15c",
        return_at_close: 1.003369158689553,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 70735.57,
        close_ms: 1711635380886,
        current_return: 0.9976689945383921,
        initial_entry_price: 70735.57,
        is_closed_position: true,
        miner_hotkey: "5E6wyDU9W9eeY8S5DMBgxkFKPgpdjgBQRwADFJY8g35jaES6",
        net_leverage: 0.0,
        open_ms: 1711635290748,
        orders: [
          {
            leverage: -3.5,
            order_type: "SHORT",
            order_uuid: "ed03c1fe-3070-4fba-8c23-ae6dc77e3b54",
            price: 70735.57,
            price_sources: [],
            processed_ms: 1711635290744,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "51e3cb9c-fd8d-49bb-91d2-3f9b0cb0d76d",
            price: 70782.68,
            price_sources: [],
            processed_ms: 1711635380886,
          },
        ],
        position_type: "FLAT",
        position_uuid: "d5557e5c-2ea1-405f-8c14-f13ba90a76d0",
        return_at_close: 0.9906853115766233,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 71180.02,
        close_ms: 1711637214579,
        current_return: 0.9916418961388325,
        initial_entry_price: 71180.02,
        is_closed_position: true,
        miner_hotkey: "5E6wyDU9W9eeY8S5DMBgxkFKPgpdjgBQRwADFJY8g35jaES6",
        net_leverage: 0.0,
        open_ms: 1711637112227,
        orders: [
          {
            leverage: -3.5,
            order_type: "SHORT",
            order_uuid: "a95df6c2-7501-4462-af3e-1eb746af144d",
            price: 71180.02,
            price_sources: [],
            processed_ms: 1711637112223,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "c1ff2d95-2dc4-4b2c-bc01-74678190acb4",
            price: 71350.0,
            price_sources: [],
            processed_ms: 1711637214579,
          },
        ],
        position_type: "FLAT",
        position_uuid: "b4a91ffa-8640-4024-b0f6-5ccf5cf2f5b5",
        return_at_close: 0.9847004028658606,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 71361.46,
        close_ms: 1711639045603,
        current_return: 1.008196090718996,
        initial_entry_price: 71361.46,
        is_closed_position: true,
        miner_hotkey: "5E6wyDU9W9eeY8S5DMBgxkFKPgpdjgBQRwADFJY8g35jaES6",
        net_leverage: 0.0,
        open_ms: 1711638928722,
        orders: [
          {
            leverage: -3.5,
            order_type: "SHORT",
            order_uuid: "fd157cdd-b204-4923-9de6-5b448dfef711",
            price: 71361.46,
            price_sources: [],
            processed_ms: 1711638928718,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "8fd851cc-12e1-4dda-a7d6-b5773b9726f2",
            price: 71194.35,
            price_sources: [],
            processed_ms: 1711639045603,
          },
        ],
        position_type: "FLAT",
        position_uuid: "472683f0-4f68-4feb-9c50-2662db480bfe",
        return_at_close: 1.001138718083963,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 71531.05000000015,
        close_ms: 1711678571001,
        current_return: 1.0025802147680494,
        initial_entry_price: 70842.94,
        is_closed_position: true,
        miner_hotkey: "5E6wyDU9W9eeY8S5DMBgxkFKPgpdjgBQRwADFJY8g35jaES6",
        net_leverage: 0.0,
        open_ms: 1711660481183,
        orders: [
          {
            leverage: -5.0,
            order_type: "SHORT",
            order_uuid: "fc0cd376-a38f-418c-8093-6be2d4302fc7",
            price: 70842.94,
            price_sources: [],
            processed_ms: 1711660481180,
          },
          {
            leverage: 2.5,
            order_type: "LONG",
            order_uuid: "b3b9ab80-9694-449a-939f-8ff1e1afd82c",
            price: 70678.29,
            price_sources: [],
            processed_ms: 1711664081847,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "72c2159c-1449-4a17-b462-36aa62d08460",
            price: 71035.34,
            price_sources: [],
            processed_ms: 1711667708167,
          },
          {
            leverage: 0.5,
            order_type: "LONG",
            order_uuid: "e99c9847-d2c8-4369-be39-36ec12839c70",
            price: 70745.35,
            price_sources: [],
            processed_ms: 1711671312708,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "6503941d-e417-44a4-b2c2-d98fcb6e15c4",
            price: 70842.11,
            price_sources: [],
            processed_ms: 1711674928284,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "65609d67-8ab0-4f56-b1d5-77206b577f15",
            price: 70799.89,
            price_sources: [],
            processed_ms: 1711678571001,
          },
        ],
        position_type: "FLAT",
        position_uuid: "38621db1-5cdb-47f9-864d-c61fde4fd07d",
        return_at_close: 0.9925544126203689,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 70338.06684210527,
        close_ms: 1711697789361,
        current_return: 0.9694824806952129,
        initial_entry_price: 70311.58,
        is_closed_position: true,
        miner_hotkey: "5E6wyDU9W9eeY8S5DMBgxkFKPgpdjgBQRwADFJY8g35jaES6",
        net_leverage: 0.0,
        open_ms: 1711685781897,
        orders: [
          {
            leverage: 5.0,
            order_type: "LONG",
            order_uuid: "7c17ebc2-7ba1-48a9-975c-ea1d6a69006f",
            price: 70311.58,
            price_sources: [],
            processed_ms: 1711685781893,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "854c65ed-1d54-447e-9362-290cba1cd112",
            price: 70400.9,
            price_sources: [],
            processed_ms: 1711689408889,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "5ec08598-433e-4be4-9a04-cc92888c3bad",
            price: 70354.58,
            price_sources: [],
            processed_ms: 1711693026980,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "5b8a0459-013f-47b7-bbb4-5c1872a5f2a4",
            price: 70347.01,
            price_sources: [],
            processed_ms: 1711696652334,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "70cd70e5-978a-454a-aef3-581840a4fec2",
            price: 70112.2,
            price_sources: [],
            processed_ms: 1711697789361,
          },
        ],
        position_type: "FLAT",
        position_uuid: "e0ffae89-399e-4aa7-9878-55b04d04df26",
        return_at_close: 0.9510623135620039,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
    ],
    thirty_day_returns: 1.0,
  },
  "5E7DEGmFUewdJTnSh829jGc3SpSd295hhvUgNcNiQST6bw4A": {
    all_time_returns: 0.9859924177750418,
    n_positions: 19,
    percentage_profitable: 0.7894736842105263,
    positions: [
      {
        average_entry_price: 64131.14,
        close_ms: 1714143631419,
        current_return: 1.000642727725645,
        initial_entry_price: 64310.28,
        is_closed_position: true,
        miner_hotkey: "5E7DEGmFUewdJTnSh829jGc3SpSd295hhvUgNcNiQST6bw4A",
        net_leverage: 0.0,
        open_ms: 1714142296056,
        orders: [
          {
            leverage: -0.1,
            order_type: "SHORT",
            order_uuid: "f22b8553-9f50-496f-be33-69f3bb9d0cbb",
            price: 64310.28,
            price_sources: [],
            processed_ms: 1714142296055,
          },
          {
            leverage: -0.1,
            order_type: "SHORT",
            order_uuid: "3f9155b6-e452-4e85-a106-fb52f2a81abc",
            price: 63952.0,
            price_sources: [],
            processed_ms: 1714142749981,
          },
          {
            leverage: 0.1,
            order_type: "FLAT",
            order_uuid: "6b02648e-e0e6-4c85-8859-f883cc4cc068",
            price: 63924.47,
            price_sources: [],
            processed_ms: 1714143631419,
          },
        ],
        position_type: "FLAT",
        position_uuid: "323ffe3f-d6bf-4f59-b1d6-24e7bff1c559",
        return_at_close: 1.0004425991801,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 63865.41999999999,
        close_ms: 1714177844868,
        current_return: 1.0007886912823871,
        initial_entry_price: 63865.42,
        is_closed_position: true,
        miner_hotkey: "5E7DEGmFUewdJTnSh829jGc3SpSd295hhvUgNcNiQST6bw4A",
        net_leverage: 0.0,
        open_ms: 1714159694008,
        orders: [
          {
            leverage: -0.11,
            order_type: "SHORT",
            order_uuid: "26ef7a0e-9b1b-4a01-b1cb-6f3732be6751",
            price: 63865.42,
            price_sources: [],
            processed_ms: 1714159694006,
          },
          {
            leverage: 0.011,
            order_type: "FLAT",
            order_uuid: "99c071c6-486b-4faa-a9ac-35246e576cd5",
            price: 63407.51,
            price_sources: [],
            processed_ms: 1714177844868,
          },
        ],
        position_type: "FLAT",
        position_uuid: "eb1942fb-f7a7-4b45-bf2a-6cfb8ba6a97d",
        return_at_close: 1.000678604526346,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 62795.0,
        close_ms: 1714482359417,
        current_return: 1.003350961063779,
        initial_entry_price: 62795.0,
        is_closed_position: true,
        miner_hotkey: "5E7DEGmFUewdJTnSh829jGc3SpSd295hhvUgNcNiQST6bw4A",
        net_leverage: 0.0,
        open_ms: 1714415555566,
        orders: [
          {
            leverage: -0.12,
            order_type: "SHORT",
            order_uuid: "726be8ee-324d-4597-89f4-a21a72e7df3a",
            price: 62795.0,
            price_sources: [],
            processed_ms: 1714415555514,
          },
          {
            leverage: 0.012,
            order_type: "FLAT",
            order_uuid: "24fd037f-98bf-4380-8188-19076359b9c4",
            price: 61041.47,
            price_sources: [],
            processed_ms: 1714482359417,
          },
        ],
        position_type: "FLAT",
        position_uuid: "35cd0be9-60f7-41b1-b4b6-a5702502bd46",
        return_at_close: 1.0032305589484514,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 38136.47615384616,
        close_ms: 1714505630500,
        current_return: 1.0012822999519033,
        initial_entry_price: 38089.840000000004,
        is_closed_position: true,
        miner_hotkey: "5E7DEGmFUewdJTnSh829jGc3SpSd295hhvUgNcNiQST6bw4A",
        net_leverage: 0.0,
        open_ms: 1714074060444,
        orders: [
          {
            leverage: -0.2,
            order_type: "SHORT",
            order_uuid: "070190a8-5a84-43dc-a22b-c1ed6a76403e",
            price: 38089.840000000004,
            price_sources: [],
            processed_ms: 1714074060443,
          },
          {
            leverage: -0.02,
            order_type: "SHORT",
            order_uuid: "b9fca599-e74c-4a33-8db1-d2671774f613",
            price: 38233.020000000004,
            price_sources: [],
            processed_ms: 1714146940344,
          },
          {
            leverage: -0.02,
            order_type: "SHORT",
            order_uuid: "10517642-7e80-4d46-9297-bcf0ed4a65af",
            price: 38285.12,
            price_sources: [],
            processed_ms: 1714155992004,
          },
          {
            leverage: -0.02,
            order_type: "SHORT",
            order_uuid: "50aee358-3f86-43f1-a527-05915f59ba72",
            price: 38357.65,
            price_sources: [],
            processed_ms: 1714398584822,
          },
          {
            leverage: 0.02,
            order_type: "FLAT",
            order_uuid: "9f9a2e96-28fa-4477-8c6f-4eef9f2921a2",
            price: 37948.62,
            price_sources: [],
            processed_ms: 1714505630500,
          },
        ],
        position_type: "FLAT",
        position_uuid: "db887bb3-9139-432a-a16a-b983144e4b80",
        return_at_close: 1.0012588699460845,
        trade_pair: ["DJI", "DJI", 9e-5, 0.001, 500],
      },
      {
        average_entry_price: 3255.99,
        close_ms: 1714931086237,
        current_return: 0.9996859941216035,
        initial_entry_price: 3255.99,
        is_closed_position: true,
        miner_hotkey: "5E7DEGmFUewdJTnSh829jGc3SpSd295hhvUgNcNiQST6bw4A",
        net_leverage: 0.0,
        open_ms: 1713966732566,
        orders: [
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "b7de92ca-a719-4bb1-8339-7cedf1bab136",
            price: 3255.99,
            price_sources: [],
            processed_ms: 1713966732543,
          },
          {
            leverage: 0.02,
            order_type: "FLAT",
            order_uuid: "103cb0b7-ec76-4a61-9a37-0a95fc49c312",
            price: 3153.75,
            price_sources: [],
            processed_ms: 1714931086237,
          },
        ],
        position_type: "FLAT",
        position_uuid: "9ecf99f7-b361-475d-b090-20dfec2c6e82",
        return_at_close: 0.9996759972616623,
        trade_pair: ["ETHUSD", "ETH/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 3072.56,
        close_ms: 1715106855153,
        current_return: 1.0012455411773895,
        initial_entry_price: 3072.56,
        is_closed_position: true,
        miner_hotkey: "5E7DEGmFUewdJTnSh829jGc3SpSd295hhvUgNcNiQST6bw4A",
        net_leverage: 0.0,
        open_ms: 1715095960497,
        orders: [
          {
            leverage: -0.1,
            order_type: "SHORT",
            order_uuid: "699b0d7f-465a-4bf4-8b52-2c79d3351306",
            price: 3072.56,
            price_sources: [],
            processed_ms: 1715095960287,
          },
          {
            leverage: 0.1,
            order_type: "FLAT",
            order_uuid: "4a93fdc4-3894-442a-b12d-c9bfb0bf1129",
            price: 3034.29,
            price_sources: [],
            processed_ms: 1715106855153,
          },
        ],
        position_type: "FLAT",
        position_uuid: "fc77e8b5-4238-4dd4-b430-f6d4f414311d",
        return_at_close: 1.0011454166232718,
        trade_pair: ["ETHUSD", "ETH/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 3054.9,
        close_ms: 1715122951922,
        current_return: 1.00104422403352,
        initial_entry_price: 3054.9,
        is_closed_position: true,
        miner_hotkey: "5E7DEGmFUewdJTnSh829jGc3SpSd295hhvUgNcNiQST6bw4A",
        net_leverage: 0.0,
        open_ms: 1715112452966,
        orders: [
          {
            leverage: -0.1,
            order_type: "SHORT",
            order_uuid: "f2fc077a-129e-4a99-acfc-bca01e3b66a6",
            price: 3054.9,
            price_sources: [],
            processed_ms: 1715112452769,
          },
          {
            leverage: 0.1,
            order_type: "FLAT",
            order_uuid: "ae78cd26-3cc0-4169-8cbd-f00a2c5a6747",
            price: 3023.0,
            price_sources: [],
            processed_ms: 1715122951922,
          },
        ],
        position_type: "FLAT",
        position_uuid: "55aa3c0e-69d5-4d00-bef9-179b48bfee4b",
        return_at_close: 1.0009441196111166,
        trade_pair: ["ETHUSD", "ETH/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 63107.40205882352,
        close_ms: 1715181380905,
        current_return: 1.0062002659901017,
        initial_entry_price: 61002.27,
        is_closed_position: true,
        miner_hotkey: "5E7DEGmFUewdJTnSh829jGc3SpSd295hhvUgNcNiQST6bw4A",
        net_leverage: 0.0,
        open_ms: 1714742431780,
        orders: [
          {
            leverage: -0.13,
            order_type: "SHORT",
            order_uuid: "659ee8be-13f9-4a25-8880-aad39ba66ab2",
            price: 61002.27,
            price_sources: [],
            processed_ms: 1714742431363,
          },
          {
            leverage: -0.1,
            order_type: "SHORT",
            order_uuid: "97050d24-4246-44ea-b72a-47dbd207b040",
            price: 63786.22,
            price_sources: [],
            processed_ms: 1714828740863,
          },
          {
            leverage: -0.1,
            order_type: "SHORT",
            order_uuid: "65bf5acc-3fd7-4acd-b66f-f4b807c88292",
            price: 63866.27,
            price_sources: [],
            processed_ms: 1714834262641,
          },
          {
            leverage: 0.1,
            order_type: "LONG",
            order_uuid: "a7d0fff2-4da3-4258-99c0-2d176282e138",
            price: 63463.19,
            price_sources: [],
            processed_ms: 1714838820515,
          },
          {
            leverage: -0.01,
            order_type: "SHORT",
            order_uuid: "dba63b19-edde-4fc6-8351-569030cafd4d",
            price: 64119.46,
            price_sources: [],
            processed_ms: 1714853349352,
          },
          {
            leverage: 0.1,
            order_type: "LONG",
            order_uuid: "5fe7f266-d06d-4d5b-bfbd-20d265d34889",
            price: 63261.57,
            price_sources: [],
            processed_ms: 1714871786368,
          },
          {
            leverage: -0.1,
            order_type: "SHORT",
            order_uuid: "269c6bad-6db7-4579-bb3e-5d48d6088550",
            price: 63832.75,
            price_sources: [],
            processed_ms: 1714946293245,
          },
          {
            leverage: 0.1,
            order_type: "LONG",
            order_uuid: "947a8977-b2e7-4694-8ffc-10773887e003",
            price: 63154.5,
            price_sources: [],
            processed_ms: 1715007653020,
          },
          {
            leverage: -0.1,
            order_type: "SHORT",
            order_uuid: "d71ef1c9-4918-4df2-b0d5-75a0e9319415",
            price: 63860.11,
            price_sources: [],
            processed_ms: 1715046218966,
          },
          {
            leverage: 0.1,
            order_type: "LONG",
            order_uuid: "7b06ae87-f2f6-4db4-95d3-990d6b338ec5",
            price: 63473.87,
            price_sources: [],
            processed_ms: 1715052288593,
          },
          {
            leverage: -0.1,
            order_type: "SHORT",
            order_uuid: "009ed078-7a5b-42ff-89ad-22a7721f097c",
            price: 63911.05,
            price_sources: [],
            processed_ms: 1715094605867,
          },
          {
            leverage: -0.1,
            order_type: "SHORT",
            order_uuid: "f6258a0f-57bc-43a1-9c15-9743916e564e",
            price: 62947.0,
            price_sources: [],
            processed_ms: 1715142103387,
          },
          {
            leverage: 0.1,
            order_type: "FLAT",
            order_uuid: "432c0645-c804-43f4-9fbe-0271635d2ccc",
            price: 61994.96,
            price_sources: [],
            processed_ms: 1715181380905,
          },
        ],
        position_type: "FLAT",
        position_uuid: "8b291971-94ae-49b9-84eb-c52da51bbb3f",
        return_at_close: 1.005455677793269,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 62152.055,
        close_ms: 1715212016514,
        current_return: 1.0039013439188866,
        initial_entry_price: 62727.0,
        is_closed_position: true,
        miner_hotkey: "5E7DEGmFUewdJTnSh829jGc3SpSd295hhvUgNcNiQST6bw4A",
        net_leverage: 0.0,
        open_ms: 1715187884841,
        orders: [
          {
            leverage: -0.1,
            order_type: "SHORT",
            order_uuid: "2a8e454d-bb8f-4d6f-b94c-a9d2957f5314",
            price: 62727.0,
            price_sources: [],
            processed_ms: 1715187884642,
          },
          {
            leverage: -0.14,
            order_type: "SHORT",
            order_uuid: "f7aa8e22-4530-493d-a4ca-a76975b5208e",
            price: 61741.38,
            price_sources: [],
            processed_ms: 1715199654366,
          },
          {
            leverage: 0.1,
            order_type: "FLAT",
            order_uuid: "b1cd56d7-3500-4be7-971d-7005a6d5ec5a",
            price: 61132.39,
            price_sources: [],
            processed_ms: 1715212016514,
          },
        ],
        position_type: "FLAT",
        position_uuid: "5595c1c3-302f-48e5-a501-ea499d05f16b",
        return_at_close: 1.003660407596346,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 3008.69,
        close_ms: 1715352372584,
        current_return: 1.0014687455337705,
        initial_entry_price: 3008.69,
        is_closed_position: true,
        miner_hotkey: "5E7DEGmFUewdJTnSh829jGc3SpSd295hhvUgNcNiQST6bw4A",
        net_leverage: 0.0,
        open_ms: 1715349707619,
        orders: [
          {
            leverage: -0.1,
            order_type: "SHORT",
            order_uuid: "9447faf5-7959-46e4-b5de-fc330db6a5cc",
            price: 3008.69,
            price_sources: [],
            processed_ms: 1715349707309,
          },
          {
            leverage: 0.1,
            order_type: "FLAT",
            order_uuid: "1441e636-17e3-4569-8395-7a94268919b5",
            price: 2964.5,
            price_sources: [],
            processed_ms: 1715352372584,
          },
        ],
        position_type: "FLAT",
        position_uuid: "c0743f8a-81d3-4005-9264-a2b2a5b61e75",
        return_at_close: 1.0013685986592171,
        trade_pair: ["ETHUSD", "ETH/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 62132.42000000001,
        close_ms: 1715352856712,
        current_return: 1.0029237163814182,
        initial_entry_price: 61350.0,
        is_closed_position: true,
        miner_hotkey: "5E7DEGmFUewdJTnSh829jGc3SpSd295hhvUgNcNiQST6bw4A",
        net_leverage: 0.0,
        open_ms: 1715215214663,
        orders: [
          {
            leverage: -0.15,
            order_type: "SHORT",
            order_uuid: "8899f281-74eb-413d-9a96-a8b0559cabaa",
            price: 61350.0,
            price_sources: [],
            processed_ms: 1715215214437,
          },
          {
            leverage: -0.1,
            order_type: "SHORT",
            order_uuid: "6afed173-2755-4f16-92d8-7248c5d83856",
            price: 62751.67,
            price_sources: [],
            processed_ms: 1715349694741,
          },
          {
            leverage: 0.1,
            order_type: "LONG",
            order_uuid: "c2743250-637f-4137-834b-c618402f67ee",
            price: 61578.04,
            price_sources: [],
            processed_ms: 1715352581681,
          },
          {
            leverage: 0.1,
            order_type: "FLAT",
            order_uuid: "9613b0ee-aa85-43a1-a40b-66031569a75e",
            price: 60936.62,
            price_sources: [],
            processed_ms: 1715352856712,
          },
        ],
        position_type: "FLAT",
        position_uuid: "127710f8-48e8-40e3-9f44-56fd2a950b6e",
        return_at_close: 1.0026729854523229,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 62614.08,
        close_ms: 1715696312940,
        current_return: 1.002203258436441,
        initial_entry_price: 62614.08,
        is_closed_position: true,
        miner_hotkey: "5E7DEGmFUewdJTnSh829jGc3SpSd295hhvUgNcNiQST6bw4A",
        net_leverage: 0.0,
        open_ms: 1715607581003,
        orders: [
          {
            leverage: -0.1,
            order_type: "SHORT",
            order_uuid: "b3431eb8-7c25-4cbb-a269-1ed6d2652a1a",
            price: 62614.08,
            price_sources: [],
            processed_ms: 1715607580386,
          },
          {
            leverage: 0.1,
            order_type: "FLAT",
            order_uuid: "c9aa30ea-fc6b-4d90-a547-beff750490a2",
            price: 61234.53,
            price_sources: [],
            processed_ms: 1715696312940,
          },
        ],
        position_type: "FLAT",
        position_uuid: "3645e8ee-cc65-405e-b803-a76c4192242e",
        return_at_close: 1.0021030381105975,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: -103988.31999998829,
        close_ms: 1715786627890,
        current_return: 0.978365439553369,
        initial_entry_price: 5051.150000000001,
        is_closed_position: true,
        miner_hotkey: "5E7DEGmFUewdJTnSh829jGc3SpSd295hhvUgNcNiQST6bw4A",
        net_leverage: 0.0,
        open_ms: 1714069315139,
        orders: [
          {
            leverage: -0.2,
            order_type: "SHORT",
            order_uuid: "d9872689-adc3-4e32-8d1c-c1af45022b06",
            price: 5051.150000000001,
            price_sources: [],
            processed_ms: 1714069315074,
          },
          {
            leverage: -0.2,
            order_type: "SHORT",
            order_uuid: "0b7864f8-b1a7-477d-a5cf-4dde06f21f91",
            price: 5107.27,
            price_sources: [],
            processed_ms: 1714143899659,
          },
          {
            leverage: -0.2,
            order_type: "SHORT",
            order_uuid: "a8327011-8c35-4087-83ca-39c03025ab85",
            price: 5170.22,
            price_sources: [],
            processed_ms: 1715020076020,
          },
          {
            leverage: 0.599,
            order_type: "LONG",
            order_uuid: "bb625509-2946-4264-98ab-7d3ad8cf3bc3",
            price: 5291.68,
            price_sources: [],
            processed_ms: 1715786595435,
          },
          {
            leverage: 0.599,
            order_type: "LONG",
            order_uuid: "b56bc258-b68e-4c7b-8f9c-e061d73debf3",
            price: 5291.09,
            price_sources: [],
            processed_ms: 1715786627890,
          },
        ],
        position_type: "FLAT",
        position_uuid: "e1a5c44b-614b-4fe3-9591-2f7749774878",
        return_at_close: 0.9783126078196331,
        trade_pair: ["SPX", "SPX", 9e-5, 0.001, 500],
      },
      {
        average_entry_price: -813822.8100000044,
        close_ms: 1715877003248,
        current_return: 0.9774669146056691,
        initial_entry_price: 37891.04,
        is_closed_position: true,
        miner_hotkey: "5E7DEGmFUewdJTnSh829jGc3SpSd295hhvUgNcNiQST6bw4A",
        net_leverage: 0.0,
        open_ms: 1714576219541,
        orders: [
          {
            leverage: -0.2,
            order_type: "SHORT",
            order_uuid: "b6ef7d71-255d-466c-8147-8895683122b1",
            price: 37891.04,
            price_sources: [],
            processed_ms: 1714576219073,
          },
          {
            leverage: -0.02,
            order_type: "SHORT",
            order_uuid: "9d96b1d3-0d4a-40ef-9f40-40046e047366",
            price: 38257.1,
            price_sources: [],
            processed_ms: 1714591438406,
          },
          {
            leverage: -0.02,
            order_type: "SHORT",
            order_uuid: "0febe5cd-4ec1-4574-b4ee-d5748eae29a2",
            price: 38267.86,
            price_sources: [],
            processed_ms: 1714676226204,
          },
          {
            leverage: -0.2,
            order_type: "SHORT",
            order_uuid: "030f5599-4ca9-4f91-9ecc-4306b942f45c",
            price: 38766.700000000004,
            price_sources: [],
            processed_ms: 1714743032278,
          },
          {
            leverage: -0.2,
            order_type: "SHORT",
            order_uuid: "ad6bdb8b-63e2-4804-afdd-d8952691e09b",
            price: 38850.63,
            price_sources: [],
            processed_ms: 1715020081485,
          },
          {
            leverage: 0.639,
            order_type: "LONG",
            order_uuid: "5329bce7-bcb8-47d4-984d-e1850d4252d1",
            price: 39821.590000000004,
            price_sources: [],
            processed_ms: 1715786408165,
          },
          {
            leverage: 0.01,
            order_type: "FLAT",
            order_uuid: "4670a4bf-9251-495f-a4cd-34a39322f943",
            price: 39979.23,
            price_sources: [],
            processed_ms: 1715877003248,
          },
        ],
        position_type: "FLAT",
        position_uuid: "793c139a-43ac-4c26-b9e0-493ea00260f6",
        return_at_close: 0.9774106125113878,
        trade_pair: ["DJI", "DJI", 9e-5, 0.001, 500],
      },
      {
        average_entry_price: 67355.87,
        close_ms: 1716247334136,
        current_return: 1.0024868136956735,
        initial_entry_price: 67355.87,
        is_closed_position: true,
        miner_hotkey: "5E7DEGmFUewdJTnSh829jGc3SpSd295hhvUgNcNiQST6bw4A",
        net_leverage: 0.0,
        open_ms: 1716218676834,
        orders: [
          {
            leverage: 0.05,
            order_type: "LONG",
            order_uuid: "cf7c1135-f3f9-4ad2-bfe2-d474b2caf906",
            price: 67355.87,
            price_sources: [],
            processed_ms: 1716218675444,
          },
          {
            leverage: 0.05,
            order_type: "FLAT",
            order_uuid: "50737cb4-bd8a-490b-bb50-5315b84ca3af",
            price: 70705.9,
            price_sources: [],
            processed_ms: 1716247334136,
          },
        ],
        position_type: "FLAT",
        position_uuid: "8b23a6c3-4019-4b8d-897b-dfb8a64570f9",
        return_at_close: 1.0024366893549888,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 3752.195,
        close_ms: 1716735584014,
        current_return: 1.003505892199799,
        initial_entry_price: 3751.57,
        is_closed_position: true,
        miner_hotkey: "5E7DEGmFUewdJTnSh829jGc3SpSd295hhvUgNcNiQST6bw4A",
        net_leverage: 0.0,
        open_ms: 1716574791676,
        orders: [
          {
            leverage: 0.06,
            order_type: "LONG",
            order_uuid: "bda16619-6719-4268-b2af-e20f5b57c9a2",
            price: 3751.57,
            price_sources: [],
            processed_ms: 1716574791488,
          },
          {
            leverage: 0.06,
            order_type: "LONG",
            order_uuid: "161e0766-71b0-4237-a02c-33dfb4589da1",
            price: 3752.82,
            price_sources: [],
            processed_ms: 1716686109686,
          },
          {
            leverage: 0.01,
            order_type: "FLAT",
            order_uuid: "8052159d-8d5a-4e8c-8a5e-2b0f4e553ab2",
            price: 3861.8,
            price_sources: [],
            processed_ms: 1716735584014,
          },
        ],
        position_type: "FLAT",
        position_uuid: "163906a7-a56c-4d63-a41b-aedd7eee0b7d",
        return_at_close: 1.003385471492735,
        trade_pair: ["ETHUSD", "ETH/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 69027.17000000001,
        close_ms: 1716824552021,
        current_return: 1.0031940753157422,
        initial_entry_price: 69202.0,
        is_closed_position: true,
        miner_hotkey: "5E7DEGmFUewdJTnSh829jGc3SpSd295hhvUgNcNiQST6bw4A",
        net_leverage: 0.0,
        open_ms: 1716643319257,
        orders: [
          {
            leverage: 0.06,
            order_type: "LONG",
            order_uuid: "95d90ae6-c19d-4cf3-b8c4-10ce3c7a867c",
            price: 69202.0,
            price_sources: [],
            processed_ms: 1716643318788,
          },
          {
            leverage: 0.06,
            order_type: "LONG",
            order_uuid: "344b62c2-762f-48de-8ced-0cbdc61510ac",
            price: 69118.24,
            price_sources: [],
            processed_ms: 1716660265651,
          },
          {
            leverage: 0.06,
            order_type: "LONG",
            order_uuid: "99041585-a2ee-43c9-b998-54a5becd3d93",
            price: 68761.27,
            price_sources: [],
            processed_ms: 1716815589503,
          },
          {
            leverage: 0.01,
            order_type: "FLAT",
            order_uuid: "30ae6660-ddf0-44a5-9cba-3ebdc3ef8ce3",
            price: 70255.15,
            price_sources: [],
            processed_ms: 1716824552021,
          },
        ],
        position_type: "FLAT",
        position_uuid: "8a0f07c8-ee49-4725-ad93-bf2a1dde8c61",
        return_at_close: 1.0030135003821854,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 68950.115,
        close_ms: 1717761865148,
        current_return: 1.0101865468519748,
        initial_entry_price: 69218.0,
        is_closed_position: true,
        miner_hotkey: "5E7DEGmFUewdJTnSh829jGc3SpSd295hhvUgNcNiQST6bw4A",
        net_leverage: 0.0,
        open_ms: 1716839458477,
        orders: [
          {
            leverage: 0.06,
            order_type: "LONG",
            order_uuid: "247c7111-7c04-4b6c-a875-84ecbeb17209",
            price: 69218.0,
            price_sources: [],
            processed_ms: 1716839458294,
          },
          {
            leverage: 0.06,
            order_type: "LONG",
            order_uuid: "10c750a5-b5b6-47b6-9268-743ed329785b",
            price: 69214.24,
            price_sources: [],
            processed_ms: 1716839560332,
          },
          {
            leverage: 0.06,
            order_type: "LONG",
            order_uuid: "c7aeacbc-8863-4ab5-a981-d879bcedfe14",
            price: 67955.02,
            price_sources: [],
            processed_ms: 1716988405404,
          },
          {
            leverage: 0.06,
            order_type: "LONG",
            order_uuid: "478a4661-4068-4804-8a88-566caf12884b",
            price: 68072.0,
            price_sources: [],
            processed_ms: 1717335775445,
          },
          {
            leverage: -0.06,
            order_type: "SHORT",
            order_uuid: "d06219e4-2e6e-4d93-8d60-fc693fa68dc9",
            price: 69009.99,
            price_sources: [],
            processed_ms: 1717396153214,
          },
          {
            leverage: 0.06,
            order_type: "LONG",
            order_uuid: "bd030e03-b934-4ebe-bca3-f82162a6bcf3",
            price: 70926.19,
            price_sources: [],
            processed_ms: 1717615894103,
          },
          {
            leverage: 0.06,
            order_type: "LONG",
            order_uuid: "ade2fa0a-da1f-4f8a-93cb-a5ba4bbd02fe",
            price: 70208.0,
            price_sources: [],
            processed_ms: 1717704093062,
          },
          {
            leverage: -0.06,
            order_type: "SHORT",
            order_uuid: "893b6535-dbfd-4cda-9605-d60f91b252a6",
            price: 70783.0,
            price_sources: [],
            processed_ms: 1717714580231,
          },
          {
            leverage: 0.01,
            order_type: "FLAT",
            order_uuid: "a08a2ede-5d77-4ebc-97fe-6f1c20e05254",
            price: 71888.0,
            price_sources: [],
            processed_ms: 1717761865148,
          },
        ],
        position_type: "FLAT",
        position_uuid: "6deede0e-80d9-43f0-8ab6-88939d98ecd8",
        return_at_close: 1.009822879695108,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 69923.2,
        close_ms: 1718959846763,
        current_return: 0.9896432842893894,
        initial_entry_price: 70902.4,
        is_closed_position: true,
        miner_hotkey: "5E7DEGmFUewdJTnSh829jGc3SpSd295hhvUgNcNiQST6bw4A",
        net_leverage: 0.0,
        open_ms: 1717775068423,
        orders: [
          {
            leverage: 0.06,
            order_type: "LONG",
            order_uuid: "be40c56d-0155-4df8-8f73-89442cf35a8f",
            price: 70902.4,
            price_sources: [],
            processed_ms: 1717775067042,
          },
          {
            leverage: 0.06,
            order_type: "LONG",
            order_uuid: "4b1fc2e2-51f5-4cb0-8cd4-637d4d491708",
            price: 68944.0,
            price_sources: [],
            processed_ms: 1717784980554,
          },
          {
            leverage: -0.149,
            order_type: "SHORT",
            order_uuid: "5c9d54d3-315b-4cec-a0b3-3e181b394d3f",
            price: 63803.9,
            price_sources: [],
            processed_ms: 1718959846763,
          },
        ],
        position_type: "FLAT",
        position_uuid: "be40c56d-0155-4df8-8f73-89442cf35a8f",
        return_at_close: 0.9895245270952747,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 139243.69999999987,
        close_ms: 0,
        current_return: 0.9648858265481977,
        initial_entry_price: 3876.12,
        is_closed_position: false,
        miner_hotkey: "5E7DEGmFUewdJTnSh829jGc3SpSd295hhvUgNcNiQST6bw4A",
        net_leverage: 0.0010000000000000009,
        open_ms: 1716839382640,
        orders: [
          {
            leverage: 0.06,
            order_type: "LONG",
            order_uuid: "0f28250f-0e56-473c-931f-eeee635d7ea4",
            price: 3876.12,
            price_sources: [],
            processed_ms: 1716839382458,
          },
          {
            leverage: 0.06,
            order_type: "LONG",
            order_uuid: "cff266e4-a115-4da4-9aad-02e60ccdc454",
            price: 3865.2,
            price_sources: [],
            processed_ms: 1716839419840,
          },
          {
            leverage: 0.06,
            order_type: "LONG",
            order_uuid: "b42a2c04-2ae2-479a-96cb-0db357cfba51",
            price: 3819.0,
            price_sources: [],
            processed_ms: 1717161322005,
          },
          {
            leverage: 0.06,
            order_type: "LONG",
            order_uuid: "8e09f701-96b9-49a3-b1b7-f50db6f50103",
            price: 3783.52,
            price_sources: [],
            processed_ms: 1717510366544,
          },
          {
            leverage: 0.06,
            order_type: "LONG",
            order_uuid: "c408fffb-5159-4664-9565-8ec549d17d09",
            price: 3772.36,
            price_sources: [],
            processed_ms: 1717511846809,
          },
          {
            leverage: -0.12,
            order_type: "SHORT",
            order_uuid: "c8697b27-85d0-467c-ab22-f998c959ad4e",
            price: 3859.29,
            price_sources: [],
            processed_ms: 1717610552216,
          },
          {
            leverage: 0.06,
            order_type: "LONG",
            order_uuid: "ab2081cd-96f5-4fee-8d71-221ce904bdb4",
            price: 3762.62,
            price_sources: [],
            processed_ms: 1717704145356,
          },
          {
            leverage: 0.12,
            order_type: "LONG",
            order_uuid: "5854e4c5-5aa5-4bca-9e95-caecf19b0d45",
            price: 3590.98,
            price_sources: [],
            processed_ms: 1718072372391,
          },
          {
            leverage: 0.12,
            order_type: "LONG",
            order_uuid: "d77c31ef-b896-4a70-9b25-c0ec7db4a37f",
            price: 3537.98,
            price_sources: [],
            processed_ms: 1718111763523,
          },
          {
            leverage: -0.12,
            order_type: "SHORT",
            order_uuid: "ce6a85fd-37cd-469b-9cfb-20e8345892b6",
            price: 3611.77,
            price_sources: [],
            processed_ms: 1718195722946,
          },
          {
            leverage: 0.12,
            order_type: "LONG",
            order_uuid: "0d501624-2644-4ab1-87e4-052675862c8e",
            price: 3519.0,
            price_sources: [],
            processed_ms: 1718246514920,
          },
          {
            leverage: 0.12,
            order_type: "LONG",
            order_uuid: "58b0daed-b852-4e23-8207-adc67100f6c4",
            price: 3409.1,
            price_sources: [],
            processed_ms: 1718382997577,
          },
          {
            leverage: -0.12,
            order_type: "SHORT",
            order_uuid: "649efe84-8bd2-4432-ac44-5bf687f06226",
            price: 3468.81,
            price_sources: [],
            processed_ms: 1718400475243,
          },
          {
            leverage: -0.06,
            order_type: "SHORT",
            order_uuid: "35be013e-1add-4069-a279-dd1df0db96ea",
            price: 3551.24,
            price_sources: [],
            processed_ms: 1718432245493,
          },
          {
            leverage: -0.419,
            order_type: "SHORT",
            order_uuid: "65a47ba9-4e0e-4b24-8d82-7cdfc809c8a7",
            price: 3328.1,
            price_sources: [],
            processed_ms: 1719221447293,
          },
        ],
        position_type: "LONG",
        position_uuid: "16228ddd-c08a-41db-ab7a-dc052d6e9ba0",
        return_at_close: 0.9640758048968104,
        trade_pair: ["ETHUSD", "ETH/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 57683.315,
        close_ms: 0,
        current_return: 1.0015739632817646,
        initial_entry_price: 57648.74,
        is_closed_position: false,
        miner_hotkey: "5E7DEGmFUewdJTnSh829jGc3SpSd295hhvUgNcNiQST6bw4A",
        net_leverage: 0.2,
        open_ms: 1720619697980,
        orders: [
          {
            leverage: 0.1,
            order_type: "LONG",
            order_uuid: "4eb01694-7dbe-4f9e-ad19-784faf60d2cb",
            price: 57648.74,
            price_sources: [
              {
                close: 57648.74,
                high: 57660.12,
                lag_ms: 269,
                low: 57648.74,
                open: 57648.74,
                source: "Polygon_ws",
                start_ms: 1720619697999,
                timespan_ms: 0,
                volume: 0.11975141,
                vwap: 57656.6915,
                websocket: true,
              },
              {
                close: 57596.3,
                high: 57596.3,
                lag_ms: 730,
                low: 57596.3,
                open: 57596.3,
                source: "TwelveData_ws",
                start_ms: 1720619697000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
              {
                close: 57648.3,
                high: 57687.93,
                lag_ms: 2731,
                low: 57648.3,
                open: 57687.93,
                source: "Polygon_rest",
                start_ms: 1720619694000,
                timespan_ms: 1000,
                volume: 0.0896793,
                vwap: 57678.6716,
                websocket: false,
              },
              {
                close: 57602.29,
                high: 57608.51,
                lag_ms: 57731,
                low: 57590.37,
                open: 57599.17,
                source: "TwelveData_rest",
                start_ms: 1720619580000,
                timespan_ms: 60000,
                volume: null,
                vwap: null,
                websocket: false,
              },
            ],
            processed_ms: 1720619697730,
          },
          {
            leverage: 0.1,
            order_type: "LONG",
            order_uuid: "33e10d6f-62e9-47b0-bd73-1c0a14450a5d",
            price: 57717.89,
            price_sources: [
              {
                close: 57717.89,
                high: 57717.89,
                lag_ms: 243,
                low: 57717.89,
                open: 57717.89,
                source: "Polygon_ws",
                start_ms: 1720621423000,
                timespan_ms: 0,
                volume: 0.03029855,
                vwap: 57717.89,
                websocket: true,
              },
              {
                close: 57733.6,
                high: 57733.6,
                lag_ms: 757,
                low: 57733.6,
                open: 57733.6,
                source: "TwelveData_ws",
                start_ms: 1720621424000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
              {
                close: 57721.32,
                high: 57733.64,
                lag_ms: 2244,
                low: 57721.32,
                open: 57733.64,
                source: "Polygon_rest",
                start_ms: 1720621420000,
                timespan_ms: 1000,
                volume: 0.81307817,
                vwap: 57722.2223,
                websocket: false,
              },
              {
                close: 57651.05,
                high: 57728.09,
                lag_ms: 103244,
                low: 57637.51,
                open: 57637.51,
                source: "TwelveData_rest",
                start_ms: 1720621260000,
                timespan_ms: 60000,
                volume: null,
                vwap: null,
                websocket: false,
              },
            ],
            processed_ms: 1720621423243,
          },
        ],
        position_type: "LONG",
        position_uuid: "4eb01694-7dbe-4f9e-ad19-784faf60d2cb",
        return_at_close: 1.0014738058854364,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
    ],
    thirty_day_returns: 1.0,
  },
  "5E9Ppyn5DzHGaPQmsHVnkNJDjGd7DstqjHWZpQhWPMbqzNex": {
    all_time_returns: 1.0365769650010073,
    n_positions: 3,
    percentage_profitable: 1.0,
    positions: [
      {
        average_entry_price: 5222.41,
        close_ms: 1717185436579,
        current_return: 1.004010283561963,
        initial_entry_price: 5235.54,
        is_closed_position: true,
        miner_hotkey: "5E9Ppyn5DzHGaPQmsHVnkNJDjGd7DstqjHWZpQhWPMbqzNex",
        net_leverage: 0.0,
        open_ms: 1717164126056,
        orders: [
          {
            leverage: 0.2,
            order_type: "LONG",
            order_uuid: "12f15225-27f9-4d03-b59c-f462172d3ce8",
            price: 5235.54,
            price_sources: [],
            processed_ms: 1717164125310,
          },
          {
            leverage: 0.2,
            order_type: "LONG",
            order_uuid: "d2ef9d4e-b560-4b6a-89fb-aac3e828bc3c",
            price: 5209.28,
            price_sources: [],
            processed_ms: 1717168816367,
          },
          {
            leverage: 0.2,
            order_type: "FLAT",
            order_uuid: "d224849b-90b2-4561-b77c-63fa1f5ad545",
            price: 5274.900000000001,
            price_sources: [],
            processed_ms: 1717185436579,
          },
        ],
        position_type: "FLAT",
        position_uuid: "7741b596-03cb-4a93-bfdc-799b338166fd",
        return_at_close: 1.003974139191755,
        trade_pair: ["SPX", "SPX", 9e-5, 0.001, 500],
      },
      {
        average_entry_price: 65552.57333333332,
        close_ms: 1717602132789,
        current_return: 1.0275925247233944,
        initial_entry_price: 66986.15,
        is_closed_position: true,
        miner_hotkey: "5E9Ppyn5DzHGaPQmsHVnkNJDjGd7DstqjHWZpQhWPMbqzNex",
        net_leverage: 0.0,
        open_ms: 1716487978574,
        orders: [
          {
            leverage: 0.1,
            order_type: "LONG",
            order_uuid: "1457b900-839c-4dd3-8120-fb63b6472d68",
            price: 66986.15,
            price_sources: [],
            processed_ms: 1716487978385,
          },
          {
            leverage: 0.1,
            order_type: "LONG",
            order_uuid: "8168e705-6849-49f8-b767-aa0c04b21d3e",
            price: 67028.54,
            price_sources: [],
            processed_ms: 1716488012243,
          },
          {
            leverage: 0.1,
            order_type: "LONG",
            order_uuid: "acd1c8c2-413a-4de6-bbd0-7391cae37a2c",
            price: 67080.67,
            price_sources: [],
            processed_ms: 1716488205240,
          },
          {
            leverage: 0.1,
            order_type: "LONG",
            order_uuid: "ecda4716-78a7-4085-9182-973e6fbfda5e",
            price: 67100.99,
            price_sources: [],
            processed_ms: 1716488238999,
          },
          {
            leverage: -0.1,
            order_type: "SHORT",
            order_uuid: "024bbe5b-7930-4de5-9f66-a3354e91a3ed",
            price: 67520.0,
            price_sources: [],
            processed_ms: 1716492821788,
          },
          {
            leverage: 0.1,
            order_type: "LONG",
            order_uuid: "b063d826-f0b4-40ba-89fb-676dec48d7ac",
            price: 69252.95,
            price_sources: [],
            processed_ms: 1716839931725,
          },
          {
            leverage: 0.1,
            order_type: "LONG",
            order_uuid: "0a43bafa-3869-45a9-9209-bfdf8ca6d1cf",
            price: 68729.46,
            price_sources: [],
            processed_ms: 1717097956597,
          },
          {
            leverage: 0.1,
            order_type: "LONG",
            order_uuid: "e5c5c738-bf3a-4165-9e77-25d1cb70a885",
            price: 67782.38,
            price_sources: [],
            processed_ms: 1717164172870,
          },
          {
            leverage: -0.1,
            order_type: "SHORT",
            order_uuid: "77903fd1-cf63-478d-8cfc-8c456514c5e9",
            price: 68594.0,
            price_sources: [],
            processed_ms: 1717380103301,
          },
          {
            leverage: -0.1,
            order_type: "SHORT",
            order_uuid: "558b42f9-c355-4140-954e-bb84890389b6",
            price: 69824.58,
            price_sources: [],
            processed_ms: 1717421488315,
          },
          {
            leverage: -0.1,
            order_type: "SHORT",
            order_uuid: "cbc237b5-023d-4bdc-8917-ade735b870ed",
            price: 70444.0,
            price_sources: [],
            processed_ms: 1717516375738,
          },
          {
            leverage: 0.1,
            order_type: "LONG",
            order_uuid: "f417bdb6-c40e-4c96-8fd8-03b2304224a6",
            price: 70310.16,
            price_sources: [],
            processed_ms: 1717529819613,
          },
          {
            leverage: -0.1,
            order_type: "SHORT",
            order_uuid: "8041dbbd-d080-4ebc-87b4-d6e7e8f8c07c",
            price: 71231.0,
            price_sources: [],
            processed_ms: 1717551383959,
          },
          {
            leverage: 0.005,
            order_type: "FLAT",
            order_uuid: "9785849a-cc3d-4504-8a19-71cb55a9222f",
            price: 71713.63,
            price_sources: [],
            processed_ms: 1717602132789,
          },
        ],
        position_type: "FLAT",
        position_uuid: "506e21b3-cfc8-4b75-b0f8-1094fa5c603c",
        return_at_close: 1.0267704507036157,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 3768.1999999999994,
        close_ms: 1717610095477,
        current_return: 1.0058563770500506,
        initial_entry_price: 3768.2,
        is_closed_position: true,
        miner_hotkey: "5E9Ppyn5DzHGaPQmsHVnkNJDjGd7DstqjHWZpQhWPMbqzNex",
        net_leverage: 0.0,
        open_ms: 1716695767440,
        orders: [
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "4ae0b70f-8be4-4840-ab50-ee1716137b7e",
            price: 3768.2,
            price_sources: [],
            processed_ms: 1716695766993,
          },
          {
            leverage: 0.005,
            order_type: "FLAT",
            order_uuid: "8f3ba2c9-7992-448e-85ac-a3997b362318",
            price: 3841.76,
            price_sources: [],
            processed_ms: 1717610095477,
          },
        ],
        position_type: "FLAT",
        position_uuid: "cbc68a92-6bd0-4ee2-b990-3862f355cb27",
        return_at_close: 1.0055546201369356,
        trade_pair: ["ETHUSD", "ETH/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 78279.46999999993,
        close_ms: 0,
        current_return: 0.9803888381415694,
        initial_entry_price: 3831.62,
        is_closed_position: false,
        miner_hotkey: "5E9Ppyn5DzHGaPQmsHVnkNJDjGd7DstqjHWZpQhWPMbqzNex",
        net_leverage: 0.0010000000000000009,
        open_ms: 1717679200035,
        orders: [
          {
            leverage: 0.15,
            order_type: "LONG",
            order_uuid: "8bc469c3-3434-471a-9fd5-25ba183deadb",
            price: 3831.62,
            price_sources: [],
            processed_ms: 1717679198568,
          },
          {
            leverage: -0.149,
            order_type: "SHORT",
            order_uuid: "872dbd48-acae-45d2-b1fb-f4b32e2885cc",
            price: 3331.97,
            price_sources: [],
            processed_ms: 1719221492534,
          },
        ],
        position_type: "LONG",
        position_uuid: "8bc469c3-3434-471a-9fd5-25ba183deadb",
        return_at_close: 0.9802422700102672,
        trade_pair: ["ETHUSD", "ETH/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 1935553.5000001148,
        close_ms: 0,
        current_return: 0.9735153624783104,
        initial_entry_price: 70887.0,
        is_closed_position: false,
        miner_hotkey: "5E9Ppyn5DzHGaPQmsHVnkNJDjGd7DstqjHWZpQhWPMbqzNex",
        net_leverage: 0.0009999999999999454,
        open_ms: 1717614783946,
        orders: [
          {
            leverage: 0.15,
            order_type: "LONG",
            order_uuid: "92b01c31-aca0-4600-88fa-a9efc7f934dc",
            price: 70887.0,
            price_sources: [],
            processed_ms: 1717614783509,
          },
          {
            leverage: -0.05,
            order_type: "SHORT",
            order_uuid: "23262be3-63cf-4d1e-9993-3db119d1bebc",
            price: 71514.18,
            price_sources: [],
            processed_ms: 1717760099326,
          },
          {
            leverage: 0.05,
            order_type: "LONG",
            order_uuid: "c9b788b5-26f3-4cd6-bd3a-a68f921e87f2",
            price: 69442.38,
            price_sources: [],
            processed_ms: 1718049341848,
          },
          {
            leverage: 0.1,
            order_type: "LONG",
            order_uuid: "1962202c-b5c7-4126-8dc7-57a5c405352f",
            price: 67659.0,
            price_sources: [],
            processed_ms: 1718079997463,
          },
          {
            leverage: 0.1,
            order_type: "LONG",
            order_uuid: "30c2391e-d0d5-4ab4-965b-9ae8b994e972",
            price: 67017.93,
            price_sources: [],
            processed_ms: 1718109944918,
          },
          {
            leverage: -0.1,
            order_type: "SHORT",
            order_uuid: "37cf2a17-5db4-40ca-b79e-7d8937d424a0",
            price: 67783.51,
            price_sources: [],
            processed_ms: 1718186176706,
          },
          {
            leverage: 0.1,
            order_type: "LONG",
            order_uuid: "2b3a1fc1-e785-4c0e-926a-de940f334e9e",
            price: 67538.6,
            price_sources: [],
            processed_ms: 1718221689069,
          },
          {
            leverage: 0.1,
            order_type: "LONG",
            order_uuid: "56922886-bdcc-4803-9db1-debe57738e7e",
            price: 66964.29,
            price_sources: [],
            processed_ms: 1718292329704,
          },
          {
            leverage: 0.1,
            order_type: "LONG",
            order_uuid: "faafd547-c0f9-4454-a38c-18f8d9bb8026",
            price: 66166.0,
            price_sources: [],
            processed_ms: 1718381290973,
          },
          {
            leverage: -0.1,
            order_type: "SHORT",
            order_uuid: "47555b37-b985-4526-b8e1-027b47fb181e",
            price: 66498.33,
            price_sources: [],
            processed_ms: 1718401024963,
          },
          {
            leverage: -0.449,
            order_type: "SHORT",
            order_uuid: "a3f588d3-74bd-4bb4-91a0-2e346efd7ecc",
            price: 63920.5,
            price_sources: [],
            processed_ms: 1718959801338,
          },
        ],
        position_type: "LONG",
        position_uuid: "92b01c31-aca0-4600-88fa-a9efc7f934dc",
        return_at_close: 0.9728343884822569,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
    ],
    thirty_day_returns: 1.0,
  },
  "5ECBmZSHpU61Zt8bZdsxpTT2xkBomzBG5xZBxkwVBhLrksVE": {
    all_time_returns: 1.0,
    n_positions: 0,
    percentage_profitable: 0.0,
    positions: [
      {
        average_entry_price: 64593.09,
        close_ms: 1711189004094,
        current_return: 1.0253027065278955,
        initial_entry_price: 64593.09,
        is_closed_position: true,
        miner_hotkey: "5ECBmZSHpU61Zt8bZdsxpTT2xkBomzBG5xZBxkwVBhLrksVE",
        net_leverage: 0.0,
        open_ms: 1711188367874,
        orders: [
          {
            leverage: -11.0,
            order_type: "SHORT",
            order_uuid: "7f639e1c-f9d8-4e0c-94bc-8c97fd223e6c",
            price: 64593.09,
            price_sources: [],
            processed_ms: 1711188367873,
          },
          {
            leverage: 19.0,
            order_type: "FLAT",
            order_uuid: "1679b234-dba1-4ded-b029-2a960a228501",
            price: 64444.51,
            price_sources: [],
            processed_ms: 1711189004094,
          },
        ],
        position_type: "FLAT",
        position_uuid: "a23f5e5b-4fea-4db4-921b-ac1009b2ec9a",
        return_at_close: 1.0027460469842817,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 70714.75557788945,
        close_ms: 1711396587419,
        current_return: 0.8967169812067665,
        initial_entry_price: 70729.72,
        is_closed_position: true,
        miner_hotkey: "5ECBmZSHpU61Zt8bZdsxpTT2xkBomzBG5xZBxkwVBhLrksVE",
        net_leverage: -19.9,
        open_ms: 1711387950979,
        orders: [
          {
            leverage: -19.0,
            order_type: "SHORT",
            order_uuid: "f273ea03-9950-4a3c-8faa-884bfcb96bce",
            price: 70729.72,
            price_sources: [],
            processed_ms: 1711387950978,
          },
          {
            leverage: -0.9,
            order_type: "SHORT",
            order_uuid: "81409a06-c285-4061-b822-f481b1683477",
            price: 70398.84,
            price_sources: [],
            processed_ms: 1711389203539,
          },
        ],
        position_type: "FLAT",
        position_uuid: "7472816e-c726-471c-9e99-0f2118ab2a58",
        return_at_close: 0.8610276453547372,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
    ],
    thirty_day_returns: 1.0,
  },
  "5ECSsYFLWLmwsKNUsHyccaKE61okadfTcVPHh2eAwfmAypmQ": {
    all_time_returns: 1.0,
    n_positions: 0,
    percentage_profitable: 0.0,
    positions: [
      {
        average_entry_price: 64845.579999999994,
        close_ms: 1711209475169,
        current_return: 1.0132642810813015,
        initial_entry_price: 64845.58,
        is_closed_position: true,
        miner_hotkey: "5ECSsYFLWLmwsKNUsHyccaKE61okadfTcVPHh2eAwfmAypmQ",
        net_leverage: 0.0,
        open_ms: 1711208616450,
        orders: [
          {
            leverage: 3.0,
            order_type: "LONG",
            order_uuid: "4986ead8-22a5-4dc7-aa41-3a023fee5eab",
            price: 64845.58,
            price_sources: [],
            processed_ms: 1711208616450,
          },
          {
            leverage: 3.0,
            order_type: "FLAT",
            order_uuid: "c696381d-8423-4950-8a1d-c184d1916f08",
            price: 65132.29,
            price_sources: [],
            processed_ms: 1711209475169,
          },
        ],
        position_type: "FLAT",
        position_uuid: "aad30dcf-98dc-433a-b6b4-6cfda9bacdfb",
        return_at_close: 1.0071846953948136,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 3398.126944444445,
        close_ms: 1711211258600,
        current_return: 1.0017614038384184,
        initial_entry_price: 3406.09,
        is_closed_position: true,
        miner_hotkey: "5ECSsYFLWLmwsKNUsHyccaKE61okadfTcVPHh2eAwfmAypmQ",
        net_leverage: 0.0,
        open_ms: 1711209741415,
        orders: [
          {
            leverage: 0.55,
            order_type: "LONG",
            order_uuid: "8a8e6140-d48b-4b37-b113-9d437e46736e",
            price: 3406.09,
            price_sources: [],
            processed_ms: 1711209741414,
          },
          {
            leverage: 0.6,
            order_type: "LONG",
            order_uuid: "e284040b-8059-4cda-a3a9-7e3a14c48951",
            price: 3389.99,
            price_sources: [],
            processed_ms: 1711210250192,
          },
          {
            leverage: 0.65,
            order_type: "LONG",
            order_uuid: "734c3690-c948-4ff4-8d40-6617eb6bc9b4",
            price: 3398.9,
            price_sources: [],
            processed_ms: 1711210746111,
          },
          {
            leverage: 0.7,
            order_type: "FLAT",
            order_uuid: "e99dbd1c-1b8b-4e3e-b357-4e575c1b7ff4",
            price: 3401.46,
            price_sources: [],
            processed_ms: 1711211258600,
          },
        ],
        position_type: "FLAT",
        position_uuid: "d7bdf0f1-a028-4879-8730-54374e8cdb38",
        return_at_close: 0.9981550627846,
        trade_pair: ["ETHUSD", "ETH/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 3420.009583333333,
        close_ms: 1711213240206,
        current_return: 1.0017406534541138,
        initial_entry_price: 3419.98,
        is_closed_position: true,
        miner_hotkey: "5ECSsYFLWLmwsKNUsHyccaKE61okadfTcVPHh2eAwfmAypmQ",
        net_leverage: 0.0,
        open_ms: 1711211752773,
        orders: [
          {
            leverage: 0.75,
            order_type: "LONG",
            order_uuid: "e5d67a2b-9db2-432c-b026-57479f16cd71",
            price: 3419.98,
            price_sources: [],
            processed_ms: 1711211752773,
          },
          {
            leverage: 0.8,
            order_type: "LONG",
            order_uuid: "8d662655-66e9-49c4-accd-bb22ab0b02e4",
            price: 3415.33,
            price_sources: [],
            processed_ms: 1711212240636,
          },
          {
            leverage: 0.85,
            order_type: "LONG",
            order_uuid: "391fe9d9-fc8a-44c4-9d92-10129da47187",
            price: 3424.44,
            price_sources: [],
            processed_ms: 1711212785788,
          },
          {
            leverage: 0.9,
            order_type: "FLAT",
            order_uuid: "f0790801-19cf-44d7-9b0f-b9cc72d0577a",
            price: 3422.49,
            price_sources: [],
            processed_ms: 1711213240206,
          },
        ],
        position_type: "FLAT",
        position_uuid: "ba512399-9b4c-41d5-8858-c00d5c4f8749",
        return_at_close: 0.996932298317534,
        trade_pair: ["ETHUSD", "ETH/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 3403.4551666666666,
        close_ms: 1711215242897,
        current_return: 1.0085229308228552,
        initial_entry_price: 3405.46,
        is_closed_position: true,
        miner_hotkey: "5ECSsYFLWLmwsKNUsHyccaKE61okadfTcVPHh2eAwfmAypmQ",
        net_leverage: 0.0,
        open_ms: 1711213761067,
        orders: [
          {
            leverage: 0.95,
            order_type: "LONG",
            order_uuid: "63c713e5-de87-4f68-8140-e3b8de05e457",
            price: 3405.46,
            price_sources: [],
            processed_ms: 1711213761066,
          },
          {
            leverage: 1.0,
            order_type: "LONG",
            order_uuid: "1a8dbc78-00e9-403d-b88a-2a7a08c3e5e4",
            price: 3402.9,
            price_sources: [],
            processed_ms: 1711214261780,
          },
          {
            leverage: 1.05,
            order_type: "LONG",
            order_uuid: "f56f0dcc-52a6-4629-a986-e7d5cbbe47be",
            price: 3402.17,
            price_sources: [],
            processed_ms: 1711214740739,
          },
          {
            leverage: 1.1,
            order_type: "FLAT",
            order_uuid: "a1b52b2a-7721-404e-a885-e1e5dcfdba63",
            price: 3413.13,
            price_sources: [],
            processed_ms: 1711215242897,
          },
        ],
        position_type: "FLAT",
        position_uuid: "94fc5cc6-9cec-4036-b011-57b79e2cb0d4",
        return_at_close: 1.0024717932379181,
        trade_pair: ["ETHUSD", "ETH/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 3406.4718055555563,
        close_ms: 1711217250054,
        current_return: 1.0000930904868843,
        initial_entry_price: 3410.66,
        is_closed_position: true,
        miner_hotkey: "5ECSsYFLWLmwsKNUsHyccaKE61okadfTcVPHh2eAwfmAypmQ",
        net_leverage: 0.0,
        open_ms: 1711215751585,
        orders: [
          {
            leverage: 1.15,
            order_type: "LONG",
            order_uuid: "8e0afc43-d3d4-4389-ad4f-fd89c8968f1a",
            price: 3410.66,
            price_sources: [],
            processed_ms: 1711215751584,
          },
          {
            leverage: 1.2,
            order_type: "LONG",
            order_uuid: "19d88a81-7612-4461-b2ff-52d7d29de8b4",
            price: 3407.96,
            price_sources: [],
            processed_ms: 1711216263963,
          },
          {
            leverage: 1.25,
            order_type: "LONG",
            order_uuid: "32837353-3e72-4788-9007-38f5c31b61ea",
            price: 3401.19,
            price_sources: [],
            processed_ms: 1711216746731,
          },
          {
            leverage: 1.3,
            order_type: "FLAT",
            order_uuid: "5201ee15-0aea-4acc-9cee-408838bd1fb3",
            price: 3406.56,
            price_sources: [],
            processed_ms: 1711217250054,
          },
        ],
        position_type: "FLAT",
        position_uuid: "a52d6a77-448f-4864-b5c0-a16af781e31b",
        return_at_close: 0.9928924202353788,
        trade_pair: ["ETHUSD", "ETH/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 3407.7190476190476,
        close_ms: 1711219246288,
        current_return: 0.9887999577511899,
        initial_entry_price: 3408.38,
        is_closed_position: true,
        miner_hotkey: "5ECSsYFLWLmwsKNUsHyccaKE61okadfTcVPHh2eAwfmAypmQ",
        net_leverage: 0.0,
        open_ms: 1711217750530,
        orders: [
          {
            leverage: 1.35,
            order_type: "LONG",
            order_uuid: "35446a7b-ae9b-4f2c-a731-01f76e032d60",
            price: 3408.38,
            price_sources: [],
            processed_ms: 1711217750529,
          },
          {
            leverage: 1.4,
            order_type: "LONG",
            order_uuid: "cba7fb25-8b0a-4276-864c-162aa5889d2d",
            price: 3410.25,
            price_sources: [],
            processed_ms: 1711218242754,
          },
          {
            leverage: 1.45,
            order_type: "LONG",
            order_uuid: "9d7b181d-0b49-45a3-8611-d43a1a5b414e",
            price: 3404.66,
            price_sources: [],
            processed_ms: 1711218744688,
          },
          {
            leverage: 1.5,
            order_type: "FLAT",
            order_uuid: "e97af96c-ae5b-43af-bc38-27d3d1b75595",
            price: 3398.63,
            price_sources: [],
            processed_ms: 1711219246288,
          },
        ],
        position_type: "FLAT",
        position_uuid: "7882f7b2-c8e5-4392-b905-3781d19b3d28",
        return_at_close: 0.9804940381060799,
        trade_pair: ["ETHUSD", "ETH/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 3407.4110416666663,
        close_ms: 1711221253689,
        current_return: 1.0022260273972605,
        initial_entry_price: 3404.72,
        is_closed_position: true,
        miner_hotkey: "5ECSsYFLWLmwsKNUsHyccaKE61okadfTcVPHh2eAwfmAypmQ",
        net_leverage: 0.0,
        open_ms: 1711219755839,
        orders: [
          {
            leverage: 1.55,
            order_type: "LONG",
            order_uuid: "8d257048-a0fe-4ba8-91e9-4277bfa803ad",
            price: 3404.72,
            price_sources: [],
            processed_ms: 1711219755838,
          },
          {
            leverage: 1.6,
            order_type: "LONG",
            order_uuid: "35145460-0015-49b2-a7e8-53c124f7f457",
            price: 3408.4,
            price_sources: [],
            processed_ms: 1711220267428,
          },
          {
            leverage: 1.65,
            order_type: "LONG",
            order_uuid: "e75324c5-3e62-40a0-9127-a59aacb3bd0c",
            price: 3408.98,
            price_sources: [],
            processed_ms: 1711220750329,
          },
          {
            leverage: 1.7,
            order_type: "FLAT",
            order_uuid: "f95c7c0d-39bc-4afa-9423-632fd75a4e3a",
            price: 3408.99,
            price_sources: [],
            processed_ms: 1711221253689,
          },
        ],
        position_type: "FLAT",
        position_uuid: "7a8e4615-53c6-4b47-93ee-a24139c9eece",
        return_at_close: 0.9926046575342468,
        trade_pair: ["ETHUSD", "ETH/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 3398.7120370370367,
        close_ms: 1711223251819,
        current_return: 0.9791586823883479,
        initial_entry_price: 3402.52,
        is_closed_position: true,
        miner_hotkey: "5ECSsYFLWLmwsKNUsHyccaKE61okadfTcVPHh2eAwfmAypmQ",
        net_leverage: 0.0,
        open_ms: 1711221763642,
        orders: [
          {
            leverage: 1.75,
            order_type: "LONG",
            order_uuid: "680fa1f6-484b-4b4b-8124-5aa59eb3365f",
            price: 3402.52,
            price_sources: [],
            processed_ms: 1711221763641,
          },
          {
            leverage: 1.8,
            order_type: "LONG",
            order_uuid: "7e5a65b1-d2d8-48ef-852b-2234c5b67843",
            price: 3399.75,
            price_sources: [],
            processed_ms: 1711222250247,
          },
          {
            leverage: 1.85,
            order_type: "LONG",
            order_uuid: "391bc6e1-5972-43bf-a244-7eea795370ca",
            price: 3394.1,
            price_sources: [],
            processed_ms: 1711222762009,
          },
          {
            leverage: 1.9,
            order_type: "FLAT",
            order_uuid: "201f1512-8595-463e-92b8-e5baee294bb1",
            price: 3385.58,
            price_sources: [],
            processed_ms: 1711223251819,
          },
        ],
        position_type: "FLAT",
        position_uuid: "441ce8cf-e1ab-4caa-aeac-0eeb6b4690ef",
        return_at_close: 0.9685837686185538,
        trade_pair: ["ETHUSD", "ETH/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 3387.5955833333333,
        close_ms: 1711225258533,
        current_return: 1.0008925569626226,
        initial_entry_price: 3390.82,
        is_closed_position: true,
        miner_hotkey: "5ECSsYFLWLmwsKNUsHyccaKE61okadfTcVPHh2eAwfmAypmQ",
        net_leverage: 0.0,
        open_ms: 1711223754573,
        orders: [
          {
            leverage: 1.95,
            order_type: "LONG",
            order_uuid: "f2aae35a-9427-49ad-8c65-27a477480fcc",
            price: 3390.82,
            price_sources: [],
            processed_ms: 1711223754572,
          },
          {
            leverage: 2.0,
            order_type: "LONG",
            order_uuid: "dbfeec66-b8a4-413f-bb9d-f9649cbeffbf",
            price: 3387.84,
            price_sources: [],
            processed_ms: 1711224256823,
          },
          {
            leverage: 2.05,
            order_type: "LONG",
            order_uuid: "a5a2f4e6-9416-4dee-b391-5fb72b3a09c8",
            price: 3384.29,
            price_sources: [],
            processed_ms: 1711224757566,
          },
          {
            leverage: 2.1,
            order_type: "FLAT",
            order_uuid: "edc4a6e6-239d-4c1f-8690-075b47c0186b",
            price: 3388.1,
            price_sources: [],
            processed_ms: 1711225258533,
          },
        ],
        position_type: "FLAT",
        position_uuid: "14593115-09ac-4bab-a3d8-b4221932c724",
        return_at_close: 0.988881846279071,
        trade_pair: ["ETHUSD", "ETH/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 3387.531363636364,
        close_ms: 1711227263379,
        current_return: 0.9992953252140612,
        initial_entry_price: 3384.54,
        is_closed_position: true,
        miner_hotkey: "5ECSsYFLWLmwsKNUsHyccaKE61okadfTcVPHh2eAwfmAypmQ",
        net_leverage: 0.0,
        open_ms: 1711225761322,
        orders: [
          {
            leverage: 2.15,
            order_type: "LONG",
            order_uuid: "68f3a92e-aa2e-4632-8fc0-33b5ad82b042",
            price: 3384.54,
            price_sources: [],
            processed_ms: 1711225761321,
          },
          {
            leverage: 2.2,
            order_type: "LONG",
            order_uuid: "73f78bba-37de-461a-be60-fdcef12f51a3",
            price: 3388.38,
            price_sources: [],
            processed_ms: 1711226262483,
          },
          {
            leverage: 2.25,
            order_type: "LONG",
            order_uuid: "0a62cb91-2c75-41e9-a973-468a05ce1cb2",
            price: 3389.56,
            price_sources: [],
            processed_ms: 1711226762970,
          },
          {
            leverage: 2.3,
            order_type: "FLAT",
            order_uuid: "68d54f7b-cfc5-4276-883a-e647f08a0691",
            price: 3387.17,
            price_sources: [],
            processed_ms: 1711227263379,
          },
        ],
        position_type: "FLAT",
        position_uuid: "448e5bf0-b226-46db-8777-2c5ec56df608",
        return_at_close: 0.9861046269212356,
        trade_pair: ["ETHUSD", "ETH/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 3381.816180555556,
        close_ms: 1711229249858,
        current_return: 0.9932937392099853,
        initial_entry_price: 3388.55,
        is_closed_position: true,
        miner_hotkey: "5ECSsYFLWLmwsKNUsHyccaKE61okadfTcVPHh2eAwfmAypmQ",
        net_leverage: 0.0,
        open_ms: 1711227764743,
        orders: [
          {
            leverage: 2.35,
            order_type: "LONG",
            order_uuid: "8e406189-44ea-4690-9534-0b609503879e",
            price: 3388.55,
            price_sources: [],
            processed_ms: 1711227764741,
          },
          {
            leverage: 2.4,
            order_type: "LONG",
            order_uuid: "f6c0f128-c7df-40c4-8e3a-ea19b2698dd4",
            price: 3377.73,
            price_sources: [],
            processed_ms: 1711228266386,
          },
          {
            leverage: 2.45,
            order_type: "LONG",
            order_uuid: "4a666710-ef7e-439a-9622-c99d8fa85d46",
            price: 3379.36,
            price_sources: [],
            processed_ms: 1711228772563,
          },
          {
            leverage: 2.5,
            order_type: "FLAT",
            order_uuid: "a259d0e0-781d-4fe8-af0f-b3e08686e09e",
            price: 3378.66,
            price_sources: [],
            processed_ms: 1711229249858,
          },
        ],
        position_type: "FLAT",
        position_uuid: "7df187da-2780-41c6-a9df-9ac6684a925f",
        return_at_close: 0.9789903093653616,
        trade_pair: ["ETHUSD", "ETH/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 3387.064423076923,
        close_ms: 1711231267876,
        current_return: 1.0047913238549593,
        initial_entry_price: 3378.92,
        is_closed_position: true,
        miner_hotkey: "5ECSsYFLWLmwsKNUsHyccaKE61okadfTcVPHh2eAwfmAypmQ",
        net_leverage: 0.0,
        open_ms: 1711229758922,
        orders: [
          {
            leverage: 2.55,
            order_type: "LONG",
            order_uuid: "46afcffe-1092-4f93-af7c-ac9a2e98b331",
            price: 3378.92,
            price_sources: [],
            processed_ms: 1711229758920,
          },
          {
            leverage: 2.6,
            order_type: "LONG",
            order_uuid: "2d47eae4-cdeb-4d2b-8394-fc9baa627aa7",
            price: 3392.05,
            price_sources: [],
            processed_ms: 1711230270623,
          },
          {
            leverage: 2.65,
            order_type: "LONG",
            order_uuid: "c709a1a8-743b-43b4-833a-4345cb35c961",
            price: 3390.01,
            price_sources: [],
            processed_ms: 1711230773759,
          },
          {
            leverage: 2.7,
            order_type: "FLAT",
            order_uuid: "bdd0ab80-d519-4887-b707-a9ccc69c4a29",
            price: 3389.14,
            price_sources: [],
            processed_ms: 1711231267876,
          },
        ],
        position_type: "FLAT",
        position_uuid: "fc2b07af-c827-4850-8b84-db98956f48f9",
        return_at_close: 0.9891165792028219,
        trade_pair: ["ETHUSD", "ETH/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 3381.910833333333,
        close_ms: 1711233257726,
        current_return: 1.0146691685179785,
        initial_entry_price: 3389.49,
        is_closed_position: true,
        miner_hotkey: "5ECSsYFLWLmwsKNUsHyccaKE61okadfTcVPHh2eAwfmAypmQ",
        net_leverage: 0.0,
        open_ms: 1711231768509,
        orders: [
          {
            leverage: 2.75,
            order_type: "LONG",
            order_uuid: "710cb6a2-18a5-4879-996b-4db76a253536",
            price: 3389.49,
            price_sources: [],
            processed_ms: 1711231768508,
          },
          {
            leverage: 2.8,
            order_type: "LONG",
            order_uuid: "b69ec688-c3a9-4381-98a5-3a8c69237397",
            price: 3379.72,
            price_sources: [],
            processed_ms: 1711232270710,
          },
          {
            leverage: 2.85,
            order_type: "LONG",
            order_uuid: "ff90cf18-d2f4-4a4b-bf5a-10ebef4d20a1",
            price: 3376.75,
            price_sources: [],
            processed_ms: 1711232754242,
          },
          {
            leverage: 2.9,
            order_type: "FLAT",
            order_uuid: "4c672e8c-5eeb-46bf-a84a-3bea9634f357",
            price: 3387.83,
            price_sources: [],
            processed_ms: 1711233257726,
          },
        ],
        position_type: "FLAT",
        position_uuid: "aa6b5abc-71e2-4264-8ecd-f25c3cb52e05",
        return_at_close: 0.9976227264868764,
        trade_pair: ["ETHUSD", "ETH/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 3383.9220555555557,
        close_ms: 1711235254502,
        current_return: 0.9750027325552192,
        initial_entry_price: 3385.11,
        is_closed_position: true,
        miner_hotkey: "5ECSsYFLWLmwsKNUsHyccaKE61okadfTcVPHh2eAwfmAypmQ",
        net_leverage: 0.0,
        open_ms: 1711233765598,
        orders: [
          {
            leverage: 2.95,
            order_type: "LONG",
            order_uuid: "875f0f62-7aca-4347-84cd-f88ebf6a4909",
            price: 3385.11,
            price_sources: [],
            processed_ms: 1711233765596,
          },
          {
            leverage: 3.0,
            order_type: "LONG",
            order_uuid: "c471066a-15c3-4959-ad68-5b8ac1fc8dd7",
            price: 3381.17,
            price_sources: [],
            processed_ms: 1711234259452,
          },
          {
            leverage: 3.05,
            order_type: "LONG",
            order_uuid: "f2f22128-529c-4bc2-85b1-cb24e23c7dc7",
            price: 3385.48,
            price_sources: [],
            processed_ms: 1711234760493,
          },
          {
            leverage: 3.1,
            order_type: "FLAT",
            order_uuid: "11fc0c9e-010b-4219-ae3c-73cc76e5f24e",
            price: 3374.52,
            price_sources: [],
            processed_ms: 1711235254502,
          },
        ],
        position_type: "FLAT",
        position_uuid: "56ce21a0-54cf-4a37-aaae-ad67aad91f5f",
        return_at_close: 0.9574526833692252,
        trade_pair: ["ETHUSD", "ETH/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 3364.8001562500003,
        close_ms: 1711237258520,
        current_return: 0.9885815170527188,
        initial_entry_price: 3363.1,
        is_closed_position: true,
        miner_hotkey: "5ECSsYFLWLmwsKNUsHyccaKE61okadfTcVPHh2eAwfmAypmQ",
        net_leverage: 0.0,
        open_ms: 1711235755592,
        orders: [
          {
            leverage: 3.15,
            order_type: "LONG",
            order_uuid: "2d84b969-6324-4484-9147-7734ad33f929",
            price: 3363.1,
            price_sources: [],
            processed_ms: 1711235755590,
          },
          {
            leverage: 3.2,
            order_type: "LONG",
            order_uuid: "f0671e62-6f60-4a7a-a29f-3a53da60e92a",
            price: 3369.47,
            price_sources: [],
            processed_ms: 1711236252397,
          },
          {
            leverage: 3.25,
            order_type: "LONG",
            order_uuid: "5a967be1-d57c-4968-b6bf-e7781b1cfe3b",
            price: 3361.85,
            price_sources: [],
            processed_ms: 1711236754693,
          },
          {
            leverage: 3.3,
            order_type: "FLAT",
            order_uuid: "3415f816-30df-440c-a74f-67aa880c5caa",
            price: 3360.8,
            price_sources: [],
            processed_ms: 1711237258520,
          },
        ],
        position_type: "FLAT",
        position_uuid: "325f6e98-7253-4035-8e41-65f30cb0c0c7",
        return_at_close: 0.9696007519253066,
        trade_pair: ["ETHUSD", "ETH/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 3343.042941176471,
        close_ms: 1711239255686,
        current_return: 1.0218176941302684,
        initial_entry_price: 3355.35,
        is_closed_position: true,
        miner_hotkey: "5ECSsYFLWLmwsKNUsHyccaKE61okadfTcVPHh2eAwfmAypmQ",
        net_leverage: 0.0,
        open_ms: 1711237755711,
        orders: [
          {
            leverage: 3.35,
            order_type: "LONG",
            order_uuid: "bab726dc-01b9-462d-8173-8e252a351d12",
            price: 3355.35,
            price_sources: [],
            processed_ms: 1711237755710,
          },
          {
            leverage: 3.4,
            order_type: "LONG",
            order_uuid: "bc07bb4b-850c-40b4-a73a-6e4ffbea6d0b",
            price: 3334.38,
            price_sources: [],
            processed_ms: 1711238251687,
          },
          {
            leverage: 3.45,
            order_type: "LONG",
            order_uuid: "403c512e-6366-4031-8e7d-36cb30dba21c",
            price: 3339.63,
            price_sources: [],
            processed_ms: 1711238759695,
          },
          {
            leverage: 3.5,
            order_type: "FLAT",
            order_uuid: "24c1db4b-e221-4632-bc34-ed08efb26115",
            price: 3350.22,
            price_sources: [],
            processed_ms: 1711239255686,
          },
        ],
        position_type: "FLAT",
        position_uuid: "add53a7e-873f-4d59-a90b-8d5717d51fd5",
        return_at_close: 1.000972613170011,
        trade_pair: ["ETHUSD", "ETH/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 3349.705046296296,
        close_ms: 1711241255672,
        current_return: 1.0193552678387257,
        initial_entry_price: 3345.11,
        is_closed_position: true,
        miner_hotkey: "5ECSsYFLWLmwsKNUsHyccaKE61okadfTcVPHh2eAwfmAypmQ",
        net_leverage: 0.0,
        open_ms: 1711239755479,
        orders: [
          {
            leverage: 3.55,
            order_type: "LONG",
            order_uuid: "a973b6d2-89d1-45d5-80fa-e2c7f6201292",
            price: 3345.11,
            price_sources: [],
            processed_ms: 1711239755477,
          },
          {
            leverage: 3.6,
            order_type: "LONG",
            order_uuid: "b9b4acae-562c-4211-a661-5f0bf3e9fdc0",
            price: 3349.76,
            price_sources: [],
            processed_ms: 1711240259146,
          },
          {
            leverage: 3.65,
            order_type: "LONG",
            order_uuid: "24618fe3-c4c8-401d-8952-a61ddd6aab14",
            price: 3354.12,
            price_sources: [],
            processed_ms: 1711240760740,
          },
          {
            leverage: 3.7,
            order_type: "FLAT",
            order_uuid: "de665ab6-2e4b-4498-9149-29113bd78f2a",
            price: 3355.7,
            price_sources: [],
            processed_ms: 1711241255672,
          },
        ],
        position_type: "FLAT",
        position_uuid: "7b80386c-a832-4950-8f36-4e21902f0082",
        return_at_close: 0.9973371940534093,
        trade_pair: ["ETHUSD", "ETH/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 3356.887280701754,
        close_ms: 1711243254555,
        current_return: 0.9768866027895843,
        initial_entry_price: 3357.49,
        is_closed_position: true,
        miner_hotkey: "5ECSsYFLWLmwsKNUsHyccaKE61okadfTcVPHh2eAwfmAypmQ",
        net_leverage: 0.0,
        open_ms: 1711241759419,
        orders: [
          {
            leverage: 3.75,
            order_type: "LONG",
            order_uuid: "db516415-001d-4a0e-9a77-e666eea1e8ec",
            price: 3357.49,
            price_sources: [],
            processed_ms: 1711241759418,
          },
          {
            leverage: 3.8,
            order_type: "LONG",
            order_uuid: "5a1b020a-417c-4fea-a3f9-e5dd680e2217",
            price: 3354.77,
            price_sources: [],
            processed_ms: 1711242260934,
          },
          {
            leverage: 3.85,
            order_type: "LONG",
            order_uuid: "ab32df16-b9e3-4391-a753-ba3998206570",
            price: 3358.39,
            price_sources: [],
            processed_ms: 1711242757749,
          },
          {
            leverage: 3.9,
            order_type: "FLAT",
            order_uuid: "d9b14d4f-5590-4066-8186-b6d5ea14b929",
            price: 3350.08,
            price_sources: [],
            processed_ms: 1711243254555,
          },
        ],
        position_type: "FLAT",
        position_uuid: "2d5176ef-e74e-480a-ae6e-b4810fccdf7a",
        return_at_close: 0.9546135882459817,
        trade_pair: ["ETHUSD", "ETH/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 3357.1899166666662,
        close_ms: 1711245257753,
        current_return: 0.9891707114337047,
        initial_entry_price: 3368.55,
        is_closed_position: true,
        miner_hotkey: "5ECSsYFLWLmwsKNUsHyccaKE61okadfTcVPHh2eAwfmAypmQ",
        net_leverage: 0.0,
        open_ms: 1711243759566,
        orders: [
          {
            leverage: 3.95,
            order_type: "LONG",
            order_uuid: "affebf9b-245f-4e0d-ae67-47a43c5591ba",
            price: 3368.55,
            price_sources: [],
            processed_ms: 1711243759564,
          },
          {
            leverage: 4.0,
            order_type: "LONG",
            order_uuid: "ab1eda52-62f9-47c4-a7b8-3de6b783c17a",
            price: 3353.93,
            price_sources: [],
            processed_ms: 1711244257387,
          },
          {
            leverage: 4.05,
            order_type: "LONG",
            order_uuid: "914f0054-8b6a-4407-8046-2a9d063499dc",
            price: 3349.33,
            price_sources: [],
            processed_ms: 1711244754627,
          },
          {
            leverage: 4.1,
            order_type: "FLAT",
            order_uuid: "9656a52b-9e2f-4900-bd07-3831f3aa2832",
            price: 3354.15,
            price_sources: [],
            processed_ms: 1711245257753,
          },
        ],
        position_type: "FLAT",
        position_uuid: "161db84e-9e6e-47fe-bedb-3d25aea8003e",
        return_at_close: 0.9654306143592958,
        trade_pair: ["ETHUSD", "ETH/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 3348.2428174603174,
        close_ms: 1711247255838,
        current_return: 0.9827923222114713,
        initial_entry_price: 3348.36,
        is_closed_position: true,
        miner_hotkey: "5ECSsYFLWLmwsKNUsHyccaKE61okadfTcVPHh2eAwfmAypmQ",
        net_leverage: 0.0,
        open_ms: 1711245755785,
        orders: [
          {
            leverage: 4.15,
            order_type: "LONG",
            order_uuid: "a2041a13-8868-47cf-be44-762e725ce669",
            price: 3348.36,
            price_sources: [],
            processed_ms: 1711245755783,
          },
          {
            leverage: 4.2,
            order_type: "LONG",
            order_uuid: "ab2f220a-4db6-40a1-a519-3194ea6b30b9",
            price: 3353.24,
            price_sources: [],
            processed_ms: 1711246258926,
          },
          {
            leverage: 4.25,
            order_type: "LONG",
            order_uuid: "2ffd6bfa-dad0-4877-a8ae-c38070a0ef71",
            price: 3343.19,
            price_sources: [],
            processed_ms: 1711246755623,
          },
          {
            leverage: 4.3,
            order_type: "FLAT",
            order_uuid: "8158ff17-35e3-47ac-9103-73e210606206",
            price: 3343.67,
            price_sources: [],
            processed_ms: 1711247255838,
          },
        ],
        position_type: "FLAT",
        position_uuid: "f650f3f1-c83c-4366-8071-2669a6e12912",
        return_at_close: 0.9580259556917422,
        trade_pair: ["ETHUSD", "ETH/USD", 0.003, 0.001, 20],
      },
    ],
    thirty_day_returns: 1.0,
  },
  "5EEzrDW4r3L6kPzQXst3UWyboStLFQjdZC6Ug1q6jQRRUqFC": {
    all_time_returns: 1.0,
    n_positions: 0,
    percentage_profitable: 0.0,
    positions: [
      {
        average_entry_price: 161.296,
        close_ms: 0,
        current_return: 1.0010043646463644,
        initial_entry_price: 161.296,
        is_closed_position: false,
        miner_hotkey: "5EEzrDW4r3L6kPzQXst3UWyboStLFQjdZC6Ug1q6jQRRUqFC",
        net_leverage: 0.4,
        open_ms: 1719842611291,
        orders: [
          {
            leverage: 0.4,
            order_type: "LONG",
            order_uuid: "df80e51b-a40c-49ad-a078-e956ec2a006c",
            price: 161.296,
            price_sources: [],
            processed_ms: 1719842611289,
          },
        ],
        position_type: "LONG",
        position_uuid: "5c65dcd4-107b-48ae-bcb2-a4af6a008842",
        return_at_close: 1.0009903505852595,
        trade_pair: ["USDJPY", "USD/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 3441.27,
        close_ms: 0,
        current_return: 0.9991156753175426,
        initial_entry_price: 3441.27,
        is_closed_position: false,
        miner_hotkey: "5EEzrDW4r3L6kPzQXst3UWyboStLFQjdZC6Ug1q6jQRRUqFC",
        net_leverage: 0.01,
        open_ms: 1719502191457,
        orders: [
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "1788154e-3c50-4916-8554-abdad417e405",
            price: 3441.27,
            price_sources: [],
            processed_ms: 1719502190868,
          },
        ],
        position_type: "LONG",
        position_uuid: "f23a52a6-b2be-44e0-8e2b-07640c51db3f",
        return_at_close: 0.999110679739166,
        trade_pair: ["ETHUSD", "ETH/USD", 0.003, 0.001, 20],
      },
    ],
    thirty_day_returns: 1.0,
  },
  "5EF393sRCV3Q6SFNTpKQed8m3QDGRgfDvke8sUoH3kbLqGZS": {
    all_time_returns: 1.1763275130456712,
    n_positions: 73,
    percentage_profitable: 0.9315068493150684,
    positions: [
      {
        average_entry_price: 0.8853102072338781,
        close_ms: 1713445092171,
        current_return: 1.000006436080387,
        initial_entry_price: 0.885310207233878,
        is_closed_position: true,
        miner_hotkey: "5EF393sRCV3Q6SFNTpKQed8m3QDGRgfDvke8sUoH3kbLqGZS",
        net_leverage: 0.0,
        open_ms: 1713444702831,
        orders: [
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "43df5b4e-0c1a-4103-afff-764483590dbf",
            price: 0.885310207233878,
            price_sources: [],
            processed_ms: 1713444702829,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "8a705724-6fd2-4c0a-9896-e98105e469ad",
            price: 0.88588,
            price_sources: [],
            processed_ms: 1713445092171,
          },
        ],
        position_type: "FLAT",
        position_uuid: "dfd000f0-8ec1-4431-aec2-b6369714e3a4",
        return_at_close: 1.0000057360758818,
        trade_pair: ["AUDCAD", "AUD/CAD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 91.452,
        close_ms: 1713445982776,
        current_return: 1.000007435594629,
        initial_entry_price: 91.452,
        is_closed_position: true,
        miner_hotkey: "5EF393sRCV3Q6SFNTpKQed8m3QDGRgfDvke8sUoH3kbLqGZS",
        net_leverage: 0.0,
        open_ms: 1713444814002,
        orders: [
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "60070f0c-bbcd-45c5-bbec-096fcbfe2d85",
            price: 91.452,
            price_sources: [],
            processed_ms: 1713444812128,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "ecd9ee54-64f9-4905-b6bc-175a3f2978a3",
            price: 91.52,
            price_sources: [],
            processed_ms: 1713445982776,
          },
        ],
        position_type: "FLAT",
        position_uuid: "5fd80430-794f-446f-8fe6-1814c45365ce",
        return_at_close: 1.000006735589424,
        trade_pair: ["NZDJPY", "NZD/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 1.8001199999999997,
        close_ms: 1713445999903,
        current_return: 1.0000061107037308,
        initial_entry_price: 1.80012,
        is_closed_position: true,
        miner_hotkey: "5EF393sRCV3Q6SFNTpKQed8m3QDGRgfDvke8sUoH3kbLqGZS",
        net_leverage: 0.0,
        open_ms: 1713444778762,
        orders: [
          {
            leverage: -0.01,
            order_type: "SHORT",
            order_uuid: "e010d2ad-88cb-43d1-b95d-cb8619ea412f",
            price: 1.80012,
            price_sources: [],
            processed_ms: 1713444778761,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "5a4606c8-eba6-40bc-8b3c-ea8e2d380d84",
            price: 1.79902,
            price_sources: [],
            processed_ms: 1713445999903,
          },
        ],
        position_type: "FLAT",
        position_uuid: "63768217-7879-492c-a240-4488ba283dcd",
        return_at_close: 1.0000054106994534,
        trade_pair: ["EURNZD", "EUR/NZD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 112.24199999999999,
        close_ms: 1713446028429,
        current_return: 1.0000064147110708,
        initial_entry_price: 112.242,
        is_closed_position: true,
        miner_hotkey: "5EF393sRCV3Q6SFNTpKQed8m3QDGRgfDvke8sUoH3kbLqGZS",
        net_leverage: 0.0,
        open_ms: 1713444636033,
        orders: [
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "1d20e260-5dc6-49c9-8607-0d12fd23d4a0",
            price: 112.242,
            price_sources: [],
            processed_ms: 1713444636032,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "9ca52c2f-f246-4c5f-8551-2a53319e57c5",
            price: 112.314,
            price_sources: [],
            processed_ms: 1713446028429,
          },
        ],
        position_type: "FLAT",
        position_uuid: "d9a5ffb2-e579-4c68-9bb8-24d5ed4a7f9f",
        return_at_close: 1.0000057147065804,
        trade_pair: ["CADJPY", "CAD/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.64386,
        close_ms: 1713446045123,
        current_return: 1.0000094741092784,
        initial_entry_price: 0.64386,
        is_closed_position: true,
        miner_hotkey: "5EF393sRCV3Q6SFNTpKQed8m3QDGRgfDvke8sUoH3kbLqGZS",
        net_leverage: 0.0,
        open_ms: 1713444685824,
        orders: [
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "f1cf2135-8f64-4304-b861-e905a2dad760",
            price: 0.64386,
            price_sources: [],
            processed_ms: 1713444685823,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "c0d5bb4c-5887-4082-825d-67a4c8472872",
            price: 0.64447,
            price_sources: [],
            processed_ms: 1713446045123,
          },
        ],
        position_type: "FLAT",
        position_uuid: "7bf6b373-4b2d-45bc-89ca-0e742aa94ef4",
        return_at_close: 1.0000087741026467,
        trade_pair: ["AUDUSD", "AUD/USD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 1.37526,
        close_ms: 1713446347475,
        current_return: 1.0000052353736748,
        initial_entry_price: 1.37526,
        is_closed_position: true,
        miner_hotkey: "5EF393sRCV3Q6SFNTpKQed8m3QDGRgfDvke8sUoH3kbLqGZS",
        net_leverage: 0.0,
        open_ms: 1713444865930,
        orders: [
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "60d5691b-3e29-4908-87e8-ed0362fe1f55",
            price: 1.37526,
            price_sources: [],
            processed_ms: 1713444865929,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "3d3ad40f-b9ad-4631-9f39-0319fbcfa033",
            price: 1.37598,
            price_sources: [],
            processed_ms: 1713446347475,
          },
        ],
        position_type: "FLAT",
        position_uuid: "d441a036-c1a3-4f47-9b34-0925de152435",
        return_at_close: 1.00000453537001,
        trade_pair: ["USDCAD", "USD/CAD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.8146499999999999,
        close_ms: 1713446557432,
        current_return: 1.0000071196219236,
        initial_entry_price: 0.81465,
        is_closed_position: true,
        miner_hotkey: "5EF393sRCV3Q6SFNTpKQed8m3QDGRgfDvke8sUoH3kbLqGZS",
        net_leverage: 0.0,
        open_ms: 1713444795764,
        orders: [
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "d35c5491-c1c3-491d-8f95-5f1d1f2ba920",
            price: 0.81465,
            price_sources: [],
            processed_ms: 1713444795763,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "866e6bbb-9955-43f8-9616-ab979875fb8e",
            price: 0.81523,
            price_sources: [],
            processed_ms: 1713446557432,
          },
        ],
        position_type: "FLAT",
        position_uuid: "65e279d8-3768-4b7b-b253-56afdd34a9b2",
        return_at_close: 1.0000064196169398,
        trade_pair: ["NZDCAD", "NZD/CAD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 192.397,
        close_ms: 1713448214173,
        current_return: 1.0000017152034595,
        initial_entry_price: 192.397,
        is_closed_position: true,
        miner_hotkey: "5EF393sRCV3Q6SFNTpKQed8m3QDGRgfDvke8sUoH3kbLqGZS",
        net_leverage: 0.0,
        open_ms: 1713444847879,
        orders: [
          {
            leverage: -0.01,
            order_type: "SHORT",
            order_uuid: "634f9dc0-c2c1-439b-81b6-b657814bdb13",
            price: 192.397,
            price_sources: [],
            processed_ms: 1713444847877,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "08490ff4-5e79-40b6-82a7-afbac89b98e8",
            price: 192.364,
            price_sources: [],
            processed_ms: 1713448214173,
          },
        ],
        position_type: "FLAT",
        position_uuid: "c8bd6a3f-e55e-4c33-914e-de7b60812b51",
        return_at_close: 1.000001015202259,
        trade_pair: ["GBPJPY", "GBP/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 99.411,
        close_ms: 1713449181368,
        current_return: 1.0000018106648159,
        initial_entry_price: 99.411,
        is_closed_position: true,
        miner_hotkey: "5EF393sRCV3Q6SFNTpKQed8m3QDGRgfDvke8sUoH3kbLqGZS",
        net_leverage: 0.0,
        open_ms: 1713444669418,
        orders: [
          {
            leverage: -0.01,
            order_type: "SHORT",
            order_uuid: "86c1b08c-a945-4db0-be4d-2c55318f678b",
            price: 99.411,
            price_sources: [],
            processed_ms: 1713444669418,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "1c54aad9-33aa-40e0-9524-8fd59dd03d13",
            price: 99.393,
            price_sources: [],
            processed_ms: 1713449181368,
          },
        ],
        position_type: "FLAT",
        position_uuid: "2d575dc5-459a-4a29-984e-5b1995bbc392",
        return_at_close: 1.0000011106635485,
        trade_pair: ["AUDJPY", "AUD/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.8557999999999999,
        close_ms: 1713451330493,
        current_return: 1.0000053750876372,
        initial_entry_price: 0.8558,
        is_closed_position: true,
        miner_hotkey: "5EF393sRCV3Q6SFNTpKQed8m3QDGRgfDvke8sUoH3kbLqGZS",
        net_leverage: 0.0,
        open_ms: 1713444748873,
        orders: [
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "1a0f1ba5-ae63-440f-839f-b6b81c87f276",
            price: 0.8558,
            price_sources: [],
            processed_ms: 1713444748191,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "080fc5a8-c5ea-4a1b-825e-44b16cd984e9",
            price: 0.85626,
            price_sources: [],
            processed_ms: 1713451330493,
          },
        ],
        position_type: "FLAT",
        position_uuid: "208f22d1-5dc1-41da-a64a-8b43a8f73b3e",
        return_at_close: 1.0000046750838747,
        trade_pair: ["EURGBP", "EUR/GBP", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 1.24494,
        close_ms: 1713458807375,
        current_return: 1.0000158902130734,
        initial_entry_price: 1.24605,
        is_closed_position: true,
        miner_hotkey: "5EF393sRCV3Q6SFNTpKQed8m3QDGRgfDvke8sUoH3kbLqGZS",
        net_leverage: 0.0,
        open_ms: 1713444828841,
        orders: [
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "e86050fd-e5ac-4e85-a86b-2bb61f425a73",
            price: 1.24605,
            price_sources: [],
            processed_ms: 1713444828839,
          },
          {
            leverage: 0.02,
            order_type: "LONG",
            order_uuid: "41cef2f3-a17c-4feb-bfe6-dea7fea23689",
            price: 1.245,
            price_sources: [],
            processed_ms: 1713448300741,
          },
          {
            leverage: 0.03,
            order_type: "LONG",
            order_uuid: "d0d1c209-4c15-43ea-8fb2-89fda69f3f45",
            price: 1.24453,
            price_sources: [],
            processed_ms: 1713452684352,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "4f38cfae-d959-479c-93fe-bca6bcc9de7d",
            price: 1.24527,
            price_sources: [],
            processed_ms: 1713458807375,
          },
        ],
        position_type: "FLAT",
        position_uuid: "2710931d-ab97-4d5b-be69-94e6ae39ec91",
        return_at_close: 1.0000116901463345,
        trade_pair: ["GBPUSD", "GBP/USD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 164.73000000000002,
        close_ms: 1713460327705,
        current_return: 1.0000126351597618,
        initial_entry_price: 164.62,
        is_closed_position: true,
        miner_hotkey: "5EF393sRCV3Q6SFNTpKQed8m3QDGRgfDvke8sUoH3kbLqGZS",
        net_leverage: 0.0,
        open_ms: 1713444762944,
        orders: [
          {
            leverage: -0.01,
            order_type: "SHORT",
            order_uuid: "9de6161a-fc1b-449f-820b-73da1b3718b9",
            price: 164.62,
            price_sources: [],
            processed_ms: 1713444762942,
          },
          {
            leverage: -0.02,
            order_type: "SHORT",
            order_uuid: "3a443216-acee-450e-8a8e-19cf097ac82a",
            price: 164.75,
            price_sources: [],
            processed_ms: 1713452701488,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "3acd8d48-1faf-49c9-8583-8f0d281c3f92",
            price: 164.66,
            price_sources: [],
            processed_ms: 1713460225952,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "e0f652da-5d24-422e-82c7-ac6975a703aa",
            price: 164.626,
            price_sources: [],
            processed_ms: 1713460327705,
          },
        ],
        position_type: "FLAT",
        position_uuid: "22a50e4e-c655-444f-a630-662f4760d466",
        return_at_close: 1.000010535133228,
        trade_pair: ["EURJPY", "EUR/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.6621683333333334,
        close_ms: 1713460459689,
        current_return: 1.0000703341249073,
        initial_entry_price: 0.66113,
        is_closed_position: true,
        miner_hotkey: "5EF393sRCV3Q6SFNTpKQed8m3QDGRgfDvke8sUoH3kbLqGZS",
        net_leverage: 0.0,
        open_ms: 1713444652985,
        orders: [
          {
            leverage: -0.01,
            order_type: "SHORT",
            order_uuid: "deb14f0b-b97a-4d6c-bbc8-8d7ba1f604be",
            price: 0.66113,
            price_sources: [],
            processed_ms: 1713444652985,
          },
          {
            leverage: -0.02,
            order_type: "SHORT",
            order_uuid: "9f29ef7b-2a25-483f-b4d4-a89715d2a1cf",
            price: 0.6617,
            price_sources: [],
            processed_ms: 1713448317144,
          },
          {
            leverage: -0.15,
            order_type: "SHORT",
            order_uuid: "b0c143b6-4cb9-45f0-9f23-27a81624297e",
            price: 0.6623,
            price_sources: [],
            processed_ms: 1713452668242,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "3bee4f0c-9ed4-4645-bc32-d96e4e4f17de",
            price: 0.66191,
            price_sources: [],
            processed_ms: 1713460459689,
          },
        ],
        position_type: "FLAT",
        position_uuid: "ec718df7-4c23-4c71-9237-8b0f1858964a",
        return_at_close: 1.0000577332386973,
        trade_pair: ["CADCHF", "CAD/CHF", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 154.8809411764706,
        close_ms: 1713952226933,
        current_return: 1.000141834810241,
        initial_entry_price: 154.828,
        is_closed_position: true,
        miner_hotkey: "5EF393sRCV3Q6SFNTpKQed8m3QDGRgfDvke8sUoH3kbLqGZS",
        net_leverage: 0.0,
        open_ms: 1713822452712,
        orders: [
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "8fa78ad6-b9fe-4004-8091-e6d760fdda9b",
            price: 154.828,
            price_sources: [],
            processed_ms: 1713822452708,
          },
          {
            leverage: 0.5,
            order_type: "LONG",
            order_uuid: "e2fe489c-310a-46a1-994d-91c917f2137c",
            price: 154.882,
            price_sources: [],
            processed_ms: 1713943355985,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "acf98604-e328-458e-add5-88a132bdefec",
            price: 154.924,
            price_sources: [],
            processed_ms: 1713952226933,
          },
        ],
        position_type: "FLAT",
        position_uuid: "4f33d027-d4b5-490a-b585-500b81cb1ba3",
        return_at_close: 1.0001061297467384,
        trade_pair: ["USDJPY", "USD/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.8704599999999999,
        close_ms: 1714157238960,
        current_return: 1.000509323784044,
        initial_entry_price: 0.88966,
        is_closed_position: true,
        miner_hotkey: "5EF393sRCV3Q6SFNTpKQed8m3QDGRgfDvke8sUoH3kbLqGZS",
        net_leverage: 0.0,
        open_ms: 1713947928659,
        orders: [
          {
            leverage: 0.5,
            order_type: "LONG",
            order_uuid: "2a0fbd18-5818-49f9-8b12-3bab843f3689",
            price: 0.88966,
            price_sources: [],
            processed_ms: 1713947928654,
          },
          {
            leverage: -0.48,
            order_type: "SHORT",
            order_uuid: "e017a30b-7a94-47b5-9d35-d0c64f894261",
            price: 0.89046,
            price_sources: [],
            processed_ms: 1714051628602,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "fa2351b6-404e-4da6-a053-09959e0b0ba7",
            price: 0.89311624988562,
            price_sources: [],
            processed_ms: 1714157238960,
          },
        ],
        position_type: "FLAT",
        position_uuid: "2ab23697-49ea-468b-be41-0b155c7986f7",
        return_at_close: 1.0004743059577115,
        trade_pair: ["AUDCAD", "AUD/CAD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.59466,
        close_ms: 1714480938437,
        current_return: 1.0003480980728483,
        initial_entry_price: 0.59466,
        is_closed_position: true,
        miner_hotkey: "5EF393sRCV3Q6SFNTpKQed8m3QDGRgfDvke8sUoH3kbLqGZS",
        net_leverage: 0.0,
        open_ms: 1714479505325,
        orders: [
          {
            leverage: -0.1,
            order_type: "SHORT",
            order_uuid: "c7c28b4b-1c34-4360-90da-8e222b681163",
            price: 0.59466,
            price_sources: [],
            processed_ms: 1714479505315,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "60d1ae19-cb2d-4619-96e0-793adbbe7941",
            price: 0.59259,
            price_sources: [],
            processed_ms: 1714480938437,
          },
        ],
        position_type: "FLAT",
        position_uuid: "fa4002e1-0894-4c05-b1aa-f20bf226d146",
        return_at_close: 1.0003410956361618,
        trade_pair: ["NZDUSD", "NZD/USD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 1.25427,
        close_ms: 1714480954378,
        current_return: 1.0002160619324387,
        initial_entry_price: 1.25427,
        is_closed_position: true,
        miner_hotkey: "5EF393sRCV3Q6SFNTpKQed8m3QDGRgfDvke8sUoH3kbLqGZS",
        net_leverage: 0.0,
        open_ms: 1714479279036,
        orders: [
          {
            leverage: -0.1,
            order_type: "SHORT",
            order_uuid: "dde9c0ea-ca8a-4c39-ae2a-1d33208dacc0",
            price: 1.25427,
            price_sources: [],
            processed_ms: 1714479279029,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "06ca6e4e-88d1-46ce-a59e-bd27ccc32791",
            price: 1.25156,
            price_sources: [],
            processed_ms: 1714480954378,
          },
        ],
        position_type: "FLAT",
        position_uuid: "a939800a-5201-4887-b9f0-b9ca4b775227",
        return_at_close: 1.0002090604200051,
        trade_pair: ["GBPUSD", "GBP/USD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 1.8031600000000003,
        close_ms: 1714480971370,
        current_return: 1.0001636016770559,
        initial_entry_price: 1.80316,
        is_closed_position: true,
        miner_hotkey: "5EF393sRCV3Q6SFNTpKQed8m3QDGRgfDvke8sUoH3kbLqGZS",
        net_leverage: 0.0,
        open_ms: 1714479225830,
        orders: [
          {
            leverage: 0.1,
            order_type: "LONG",
            order_uuid: "bab697b1-b206-48d1-b182-936ca315ea64",
            price: 1.80316,
            price_sources: [],
            processed_ms: 1714479225826,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "74d71eaa-f501-4eb9-abbd-82ab058e902f",
            price: 1.80611,
            price_sources: [],
            processed_ms: 1714480971370,
          },
        ],
        position_type: "FLAT",
        position_uuid: "8b5976f0-0734-40cc-8106-c181f205abd4",
        return_at_close: 1.000156600531844,
        trade_pair: ["EURNZD", "EUR/NZD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 1.46696,
        close_ms: 1714480986614,
        current_return: 1.0001397447783171,
        initial_entry_price: 1.46696,
        is_closed_position: true,
        miner_hotkey: "5EF393sRCV3Q6SFNTpKQed8m3QDGRgfDvke8sUoH3kbLqGZS",
        net_leverage: 0.0,
        open_ms: 1714479183492,
        orders: [
          {
            leverage: 0.1,
            order_type: "LONG",
            order_uuid: "dbadde77-df24-4ede-beb4-5385dc3c70e1",
            price: 1.46696,
            price_sources: [],
            processed_ms: 1714479183488,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "7f12b123-171b-4477-ad47-01b4d5cf883a",
            price: 1.46901,
            price_sources: [],
            processed_ms: 1714480986614,
          },
        ],
        position_type: "FLAT",
        position_uuid: "3a064b55-e1cc-49d0-8eb8-6fb1582c54db",
        return_at_close: 1.0001327438001038,
        trade_pair: ["EURCAD", "EUR/CAD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 114.779,
        close_ms: 1714481002473,
        current_return: 1.0001237160107685,
        initial_entry_price: 114.779,
        is_closed_position: true,
        miner_hotkey: "5EF393sRCV3Q6SFNTpKQed8m3QDGRgfDvke8sUoH3kbLqGZS",
        net_leverage: 0.0,
        open_ms: 1714479149989,
        orders: [
          {
            leverage: -0.1,
            order_type: "SHORT",
            order_uuid: "74641f62-ae26-49ce-b169-c262672eb306",
            price: 114.779,
            price_sources: [],
            processed_ms: 1714479149985,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "3c31a967-06f4-4a39-a2a8-0c9ede0a34b5",
            price: 114.637,
            price_sources: [],
            processed_ms: 1714481002473,
          },
        ],
        position_type: "FLAT",
        position_uuid: "75e86688-9126-4404-94ad-c291f732b6b9",
        return_at_close: 1.0001167151447565,
        trade_pair: ["CADJPY", "CAD/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 93.34,
        close_ms: 1714486504908,
        current_return: 1.000318191557746,
        initial_entry_price: 93.34,
        is_closed_position: true,
        miner_hotkey: "5EF393sRCV3Q6SFNTpKQed8m3QDGRgfDvke8sUoH3kbLqGZS",
        net_leverage: 0.0,
        open_ms: 1714479488828,
        orders: [
          {
            leverage: -0.1,
            order_type: "SHORT",
            order_uuid: "a62a79dd-a562-471f-86e9-f9d3bdae822f",
            price: 93.34,
            price_sources: [],
            processed_ms: 1714479488824,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "755f5963-6c21-4d99-876b-905bba0c9598",
            price: 93.043,
            price_sources: [],
            processed_ms: 1714486504908,
          },
        ],
        position_type: "FLAT",
        position_uuid: "d1141520-e933-496f-ae76-f03ce334bc3b",
        return_at_close: 1.000311189330405,
        trade_pair: ["NZDJPY", "NZD/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 172.001,
        close_ms: 1714491678628,
        current_return: 1.0001853310822977,
        initial_entry_price: 172.001,
        is_closed_position: true,
        miner_hotkey: "5EF393sRCV3Q6SFNTpKQed8m3QDGRgfDvke8sUoH3kbLqGZS",
        net_leverage: 0.0,
        open_ms: 1714479167353,
        orders: [
          {
            leverage: -0.1,
            order_type: "SHORT",
            order_uuid: "f1a9c19d-0077-4342-943b-4c39c5a9170b",
            price: 172.001,
            price_sources: [],
            processed_ms: 1714479167350,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "4ed83069-82a7-46f0-b7cf-439fbc29d21f",
            price: 171.682228685137,
            price_sources: [],
            processed_ms: 1714491678628,
          },
        ],
        position_type: "FLAT",
        position_uuid: "50c0c024-f933-401e-afab-b5f44b0c284c",
        return_at_close: 1.0001783297849802,
        trade_pair: ["CHFJPY", "CHF/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.6670499999999999,
        close_ms: 1714538701532,
        current_return: 1.0002428603552957,
        initial_entry_price: 0.66705,
        is_closed_position: true,
        miner_hotkey: "5EF393sRCV3Q6SFNTpKQed8m3QDGRgfDvke8sUoH3kbLqGZS",
        net_leverage: 0.0,
        open_ms: 1714479133765,
        orders: [
          {
            leverage: 0.1,
            order_type: "LONG",
            order_uuid: "7738dfc7-51e1-4424-b039-7d6bbc7775e5",
            price: 0.66705,
            price_sources: [],
            processed_ms: 1714479133757,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "2aa00aee-962d-4cea-a0cc-c9a7a620586c",
            price: 0.66867,
            price_sources: [],
            processed_ms: 1714538701532,
          },
        ],
        position_type: "FLAT",
        position_uuid: "faddd824-d5db-4b97-8c12-e95062fab2e9",
        return_at_close: 1.0002358586552733,
        trade_pair: ["CADCHF", "CAD/CHF", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.65267,
        close_ms: 1714630556579,
        current_return: 1.0001930531509031,
        initial_entry_price: 0.65267,
        is_closed_position: true,
        miner_hotkey: "5EF393sRCV3Q6SFNTpKQed8m3QDGRgfDvke8sUoH3kbLqGZS",
        net_leverage: 0.0,
        open_ms: 1714479116953,
        orders: [
          {
            leverage: 0.1,
            order_type: "LONG",
            order_uuid: "f44ff84c-d23c-4577-8631-be0d31c561fb",
            price: 0.65267,
            price_sources: [],
            processed_ms: 1714479116947,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "45c9bd09-f584-4d71-b5df-dbfe0ba77823",
            price: 0.65393,
            price_sources: [],
            processed_ms: 1714630556579,
          },
        ],
        position_type: "FLAT",
        position_uuid: "5cce0d09-2388-406f-9db6-b78e32fce998",
        return_at_close: 1.0001860517995311,
        trade_pair: ["AUDUSD", "AUD/USD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.89337805825116,
        close_ms: 1714630577156,
        current_return: 1.0004070107664766,
        initial_entry_price: 0.89337805825116,
        is_closed_position: true,
        miner_hotkey: "5EF393sRCV3Q6SFNTpKQed8m3QDGRgfDvke8sUoH3kbLqGZS",
        net_leverage: 0.0,
        open_ms: 1714479052805,
        orders: [
          {
            leverage: 0.1,
            order_type: "LONG",
            order_uuid: "e387d4a6-8fc9-4288-b9da-c90dbf20c658",
            price: 0.89337805825116,
            price_sources: [],
            processed_ms: 1714479052802,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "445daf21-3c5a-4c08-bad3-2a17485fc9bc",
            price: 0.897014203133583,
            price_sources: [],
            processed_ms: 1714630577156,
          },
        ],
        position_type: "FLAT",
        position_uuid: "05377ddc-2086-4b78-ac00-5d24d57cc23a",
        return_at_close: 1.0004000079174014,
        trade_pair: ["AUDCAD", "AUD/CAD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 196.88400000000001,
        close_ms: 1714630666900,
        current_return: 1.001115885496028,
        initial_entry_price: 196.884,
        is_closed_position: true,
        miner_hotkey: "5EF393sRCV3Q6SFNTpKQed8m3QDGRgfDvke8sUoH3kbLqGZS",
        net_leverage: 0.0,
        open_ms: 1714479262585,
        orders: [
          {
            leverage: -0.1,
            order_type: "SHORT",
            order_uuid: "bf06a598-73b1-4280-ae3e-696921da5b92",
            price: 196.884,
            price_sources: [],
            processed_ms: 1714479262578,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "f77737dd-64ff-4a0c-a624-d70a32ae49e7",
            price: 194.687,
            price_sources: [],
            processed_ms: 1714630666900,
          },
        ],
        position_type: "FLAT",
        position_uuid: "c663285a-255e-49c7-b1b3-7494612edcfb",
        return_at_close: 1.0011088776848296,
        trade_pair: ["GBPJPY", "GBP/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.9788,
        close_ms: 1714631833219,
        current_return: 1.0001532488761748,
        initial_entry_price: 0.9788,
        is_closed_position: true,
        miner_hotkey: "5EF393sRCV3Q6SFNTpKQed8m3QDGRgfDvke8sUoH3kbLqGZS",
        net_leverage: 0.0,
        open_ms: 1714479208913,
        orders: [
          {
            leverage: -0.1,
            order_type: "SHORT",
            order_uuid: "b34c46ba-f6f9-432e-a7e1-4f4db13b9c53",
            price: 0.9788,
            price_sources: [],
            processed_ms: 1714479208880,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "0446018b-5d82-4f47-acc8-867995298487",
            price: 0.9773,
            price_sources: [],
            processed_ms: 1714631833219,
          },
        ],
        position_type: "FLAT",
        position_uuid: "796898c4-59f9-4c52-b671-29d35839f2f0",
        return_at_close: 1.0001462478034326,
        trade_pair: ["EURCHF", "EUR/CHF", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 165.597085106383,
        close_ms: 1714653946917,
        current_return: 1.0278346592247616,
        initial_entry_price: 165.549,
        is_closed_position: true,
        miner_hotkey: "5EF393sRCV3Q6SFNTpKQed8m3QDGRgfDvke8sUoH3kbLqGZS",
        net_leverage: 0.0,
        open_ms: 1713952269789,
        orders: [
          {
            leverage: -0.5,
            order_type: "SHORT",
            order_uuid: "7d774447-cbff-459a-9384-8c8fa926a1cf",
            price: 165.549,
            price_sources: [],
            processed_ms: 1713952269784,
          },
          {
            leverage: -20.0,
            order_type: "SHORT",
            order_uuid: "1863d314-b01c-4555-b0a5-ed4505545281",
            price: 165.601,
            price_sources: [],
            processed_ms: 1714651849441,
          },
          {
            leverage: -3.0,
            order_type: "SHORT",
            order_uuid: "441b0049-bcea-4564-ba3e-f67e3beb74ad",
            price: 165.579,
            price_sources: [],
            processed_ms: 1714653413000,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "5a134c3f-0afd-4446-b2c8-8325bbcdd090",
            price: 165.401,
            price_sources: [],
            processed_ms: 1714653946917,
          },
        ],
        position_type: "FLAT",
        position_uuid: "d08f487a-7855-45af-b22f-32b69af30c0d",
        return_at_close: 1.0261438712103368,
        trade_pair: ["EURJPY", "EUR/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 181.1849999999995,
        close_ms: 1714658840774,
        current_return: 1.0995997871980236,
        initial_entry_price: 165.412,
        is_closed_position: true,
        miner_hotkey: "5EF393sRCV3Q6SFNTpKQed8m3QDGRgfDvke8sUoH3kbLqGZS",
        net_leverage: 0.0,
        open_ms: 1714653990980,
        orders: [
          {
            leverage: -20.0,
            order_type: "SHORT",
            order_uuid: "f601f2ce-5640-4dde-8dbb-5f86e368d20b",
            price: 165.412,
            price_sources: [],
            processed_ms: 1714653990797,
          },
          {
            leverage: -10.0,
            order_type: "SHORT",
            order_uuid: "69ac9d6c-e76d-44ae-93da-472b6a63b014",
            price: 165.35,
            price_sources: [],
            processed_ms: 1714657538931,
          },
          {
            leverage: 10.0,
            order_type: "LONG",
            order_uuid: "ad392a94-e0bc-4b4c-b16f-a2218bee9a4c",
            price: 165.026,
            price_sources: [],
            processed_ms: 1714658390761,
          },
          {
            leverage: 10.0,
            order_type: "LONG",
            order_uuid: "81550669-e0d3-4528-a9dd-49a5fea89c0e",
            price: 164.75,
            price_sources: [],
            processed_ms: 1714658673467,
          },
          {
            leverage: 9.0,
            order_type: "LONG",
            order_uuid: "eaca21bd-9683-4c48-b230-f7de76785c73",
            price: 164.755,
            price_sources: [],
            processed_ms: 1714658715157,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "5189e942-4f28-4bd3-bb92-1b5b24292e08",
            price: 164.71,
            price_sources: [],
            processed_ms: 1714658840774,
          },
        ],
        position_type: "FLAT",
        position_uuid: "ef719001-20f4-4cab-8bb2-9c60a9855f3a",
        return_at_close: 1.0972906276449077,
        trade_pair: ["EURJPY", "EUR/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.8946500000000027,
        close_ms: 1714663450273,
        current_return: 1.000903709045232,
        initial_entry_price: 0.85979,
        is_closed_position: true,
        miner_hotkey: "5EF393sRCV3Q6SFNTpKQed8m3QDGRgfDvke8sUoH3kbLqGZS",
        net_leverage: 0.0,
        open_ms: 1713947969746,
        orders: [
          {
            leverage: -0.3,
            order_type: "SHORT",
            order_uuid: "03fbae28-8bbe-4c82-9463-0d37f540489f",
            price: 0.85979,
            price_sources: [],
            processed_ms: 1713947969743,
          },
          {
            leverage: 0.28,
            order_type: "LONG",
            order_uuid: "7ba5545b-4b51-42f6-a4b3-58b99a9e5356",
            price: 0.8573,
            price_sources: [],
            processed_ms: 1714051608186,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "f052a195-7992-459c-970c-715fe48e24e3",
            price: 0.8558,
            price_sources: [],
            processed_ms: 1714663450273,
          },
        ],
        position_type: "FLAT",
        position_uuid: "068136b1-5fb3-4d20-8872-b44fd3c86fca",
        return_at_close: 1.000882690067342,
        trade_pair: ["EURGBP", "EUR/GBP", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 1.07199,
        close_ms: 1714712566263,
        current_return: 1.0001175384098733,
        initial_entry_price: 1.07199,
        is_closed_position: true,
        miner_hotkey: "5EF393sRCV3Q6SFNTpKQed8m3QDGRgfDvke8sUoH3kbLqGZS",
        net_leverage: 0.0,
        open_ms: 1714479246505,
        orders: [
          {
            leverage: 0.1,
            order_type: "LONG",
            order_uuid: "ed3bf441-3f96-4cf5-9a6b-13cea296b792",
            price: 1.07199,
            price_sources: [],
            processed_ms: 1714479246501,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "56105ebc-2c95-4ef9-9d2b-e5544a4b7165",
            price: 1.07325,
            price_sources: [],
            processed_ms: 1714712566263,
          },
        ],
        position_type: "FLAT",
        position_uuid: "4ae3d099-c36b-4877-8f47-1eca5b9f2b09",
        return_at_close: 1.0001105375871044,
        trade_pair: ["EURUSD", "EUR/USD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 1.09818,
        close_ms: 1715186079903,
        current_return: 1.0001666393487407,
        initial_entry_price: 1.09818,
        is_closed_position: true,
        miner_hotkey: "5EF393sRCV3Q6SFNTpKQed8m3QDGRgfDvke8sUoH3kbLqGZS",
        net_leverage: 0.0,
        open_ms: 1714479099890,
        orders: [
          {
            leverage: -0.1,
            order_type: "SHORT",
            order_uuid: "ad27c0c9-0321-4fb8-9ba6-f7eeae77b421",
            price: 1.09818,
            price_sources: [],
            processed_ms: 1714479099886,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "a594252f-50da-417b-aa82-a9fb4600f728",
            price: 1.09635,
            price_sources: [],
            processed_ms: 1715186079903,
          },
        ],
        position_type: "FLAT",
        position_uuid: "04fe8b97-d3ab-4ecb-b497-8e3e68a15aab",
        return_at_close: 1.0001596381822653,
        trade_pair: ["AUDNZD", "AUD/NZD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 78.54799999999955,
        close_ms: 1716579187717,
        current_return: 1.0002488634811622,
        initial_entry_price: 102.506,
        is_closed_position: true,
        miner_hotkey: "5EF393sRCV3Q6SFNTpKQed8m3QDGRgfDvke8sUoH3kbLqGZS",
        net_leverage: 0.0,
        open_ms: 1714479083486,
        orders: [
          {
            leverage: 0.1,
            order_type: "LONG",
            order_uuid: "1fa7a770-ffc2-4468-9b0b-1ed1295b60fc",
            price: 102.506,
            price_sources: [],
            processed_ms: 1714479083482,
          },
          {
            leverage: -0.099,
            order_type: "SHORT",
            order_uuid: "fd6affef-c69b-4575-b073-9d36b37ff4ce",
            price: 102.748,
            price_sources: [],
            processed_ms: 1715261710495,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "7b59809a-fee6-40ca-8017-969493d78f4c",
            price: 104.058,
            price_sources: [],
            processed_ms: 1716579187717,
          },
        ],
        position_type: "FLAT",
        position_uuid: "87f928a8-7463-4857-a36b-3d766ab8e39d",
        return_at_close: 1.0002418617391178,
        trade_pair: ["AUDJPY", "AUD/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.8036599999999998,
        close_ms: 1716579548934,
        current_return: 1.0004051347471372,
        initial_entry_price: 0.8132,
        is_closed_position: true,
        miner_hotkey: "5EF393sRCV3Q6SFNTpKQed8m3QDGRgfDvke8sUoH3kbLqGZS",
        net_leverage: 0.0,
        open_ms: 1714479472000,
        orders: [
          {
            leverage: 0.1,
            order_type: "LONG",
            order_uuid: "55167fd0-c6da-4f04-893b-1443f0e01939",
            price: 0.8132,
            price_sources: [],
            processed_ms: 1714479471996,
          },
          {
            leverage: -0.09,
            order_type: "SHORT",
            order_uuid: "a48c0623-3d71-4795-a280-a81bd8b7b609",
            price: 0.81426,
            price_sources: [],
            processed_ms: 1714670179228,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "c9a323ed-bd95-4868-b7fb-b66694a7debf",
            price: 0.8366055576372049,
            price_sources: [],
            processed_ms: 1716579548934,
          },
        ],
        position_type: "FLAT",
        position_uuid: "32d16fc5-ce67-4c2a-be80-40425c51c672",
        return_at_close: 1.000398131911194,
        trade_pair: ["NZDCAD", "NZD/CAD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 69208.75687943262,
        close_ms: 1717930482039,
        current_return: 0.999672805967314,
        initial_entry_price: 64012.72,
        is_closed_position: true,
        miner_hotkey: "5EF393sRCV3Q6SFNTpKQed8m3QDGRgfDvke8sUoH3kbLqGZS",
        net_leverage: 0.0,
        open_ms: 1714853092380,
        orders: [
          {
            leverage: -0.001,
            order_type: "SHORT",
            order_uuid: "f8608d8e-47c2-407b-ac8a-f324b95f391a",
            price: 64012.72,
            price_sources: [],
            processed_ms: 1714853092194,
          },
          {
            leverage: -0.04,
            order_type: "SHORT",
            order_uuid: "96589687-dda0-484e-8db1-9291fbacbddb",
            price: 68935.7,
            price_sources: [],
            processed_ms: 1716580162180,
          },
          {
            leverage: -0.05,
            order_type: "SHORT",
            order_uuid: "329516c5-2495-4c3f-aeac-9c477ef4d283",
            price: 67552.51,
            price_sources: [],
            processed_ms: 1717226875726,
          },
          {
            leverage: -0.05,
            order_type: "SHORT",
            order_uuid: "e1aa1786-b5e4-433d-bcda-246ae7642311",
            price: 71187.37,
            price_sources: [],
            processed_ms: 1717632725896,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "c6cd938f-770a-4760-8d26-f649df3bdfbf",
            price: 69357.3,
            price_sources: [],
            processed_ms: 1717930482039,
          },
        ],
        position_type: "FLAT",
        position_uuid: "26c5a576-1b88-40ec-b269-5d66f803d062",
        return_at_close: 0.9995318521016726,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 143.93799999999987,
        close_ms: 1718390925699,
        current_return: 1.0085763563252355,
        initial_entry_price: 155.678,
        is_closed_position: true,
        miner_hotkey: "5EF393sRCV3Q6SFNTpKQed8m3QDGRgfDvke8sUoH3kbLqGZS",
        net_leverage: 0.0,
        open_ms: 1716158715384,
        orders: [
          {
            leverage: 0.5,
            order_type: "LONG",
            order_uuid: "87de0302-660d-4619-9d10-3fb0b7a3aa7a",
            price: 155.678,
            price_sources: [],
            processed_ms: 1716158715268,
          },
          {
            leverage: 0.6,
            order_type: "LONG",
            order_uuid: "b98be680-79bd-46e6-86db-8ab55b79e3ab",
            price: 156.103,
            price_sources: [],
            processed_ms: 1716301391455,
          },
          {
            leverage: -1.0,
            order_type: "SHORT",
            order_uuid: "27517080-a10e-42f6-806c-82c3b48388c5",
            price: 157.107,
            price_sources: [],
            processed_ms: 1717157812150,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "ff2f40fa-1af8-409b-a315-a7888bb58850",
            price: 157.28949999999998,
            price_sources: [],
            processed_ms: 1718390925699,
          },
        ],
        position_type: "FLAT",
        position_uuid: "9ef3eb1c-53ea-4e39-b7c7-5cd22cedd45e",
        return_at_close: 1.0084986959457984,
        trade_pair: ["USDJPY", "USD/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 69270.8,
        close_ms: 1718390950594,
        current_return: 0.9943571605929193,
        initial_entry_price: 69270.8,
        is_closed_position: true,
        miner_hotkey: "5EF393sRCV3Q6SFNTpKQed8m3QDGRgfDvke8sUoH3kbLqGZS",
        net_leverage: 0.0,
        open_ms: 1718025497229,
        orders: [
          {
            leverage: 0.1,
            order_type: "LONG",
            order_uuid: "cfdea4ab-b086-4d35-8e75-892615020057",
            price: 69270.8,
            price_sources: [],
            processed_ms: 1718025496832,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "ab1c530f-c9f7-43ad-afb0-1c06ddfdca44",
            price: 65361.96,
            price_sources: [],
            processed_ms: 1718390950594,
          },
        ],
        position_type: "FLAT",
        position_uuid: "edcc61af-a0a2-4b27-af26-3dc1d2543893",
        return_at_close: 0.99425772487686,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 1.2674167701863355,
        close_ms: 1719332186294,
        current_return: 1.0004749073124557,
        initial_entry_price: 1.2677,
        is_closed_position: true,
        miner_hotkey: "5EF393sRCV3Q6SFNTpKQed8m3QDGRgfDvke8sUoH3kbLqGZS",
        net_leverage: 0.0,
        open_ms: 1719329669432,
        orders: [
          {
            leverage: 0.282,
            order_type: "LONG",
            order_uuid: "465d6f6a-d36e-4c77-835b-4f9018ed7bb6",
            price: 1.2677,
            price_sources: [],
            processed_ms: 1719329669384,
          },
          {
            leverage: 0.684,
            order_type: "LONG",
            order_uuid: "a4cae116-8be0-4c2a-b707-04603a84a888",
            price: 1.2673,
            price_sources: [],
            processed_ms: 1719330750028,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "0f7fede9-0b4d-4d6f-9372-741caab2ed12",
            price: 1.26804,
            price_sources: [],
            processed_ms: 1719332186294,
          },
        ],
        position_type: "FLAT",
        position_uuid: "c761b3dc-984b-48f3-8e9f-ab6cb88eccd8",
        return_at_close: 1.0004072551992231,
        trade_pair: ["GBPUSD", "GBP/USD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 1.2680150000000001,
        close_ms: 1719340165348,
        current_return: 1.0007329053678387,
        initial_entry_price: 1.2680150000000001,
        is_closed_position: true,
        miner_hotkey: "5EF393sRCV3Q6SFNTpKQed8m3QDGRgfDvke8sUoH3kbLqGZS",
        net_leverage: 0.0,
        open_ms: 1719332219136,
        orders: [
          {
            leverage: 0.671,
            order_type: "LONG",
            order_uuid: "71f6a045-4dde-4210-b10a-22a43ce0be44",
            price: 1.2680150000000001,
            price_sources: [],
            processed_ms: 1719332218030,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "084262c5-8ae0-487a-9d6b-597a3b22d2e0",
            price: 1.2694,
            price_sources: [],
            processed_ms: 1719340165348,
          },
        ],
        position_type: "FLAT",
        position_uuid: "e08f676d-ec39-4e9c-9590-1b4d93fbd6e9",
        return_at_close: 1.0006859009432736,
        trade_pair: ["GBPUSD", "GBP/USD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 1.2689483372093024,
        close_ms: 1719365414133,
        current_return: 1.0003341945647892,
        initial_entry_price: 1.2695,
        is_closed_position: true,
        miner_hotkey: "5EF393sRCV3Q6SFNTpKQed8m3QDGRgfDvke8sUoH3kbLqGZS",
        net_leverage: 0.0,
        open_ms: 1719340194712,
        orders: [
          {
            leverage: 0.664,
            order_type: "LONG",
            order_uuid: "06bf5cd6-dc05-45ae-908e-c33ed85ac5b6",
            price: 1.2695,
            price_sources: [],
            processed_ms: 1719340194666,
          },
          {
            leverage: 0.638,
            order_type: "LONG",
            order_uuid: "fa529974-3f2e-44bc-95f0-16b9d3e4c78a",
            price: 1.26874,
            price_sources: [],
            processed_ms: 1719345658473,
          },
          {
            leverage: 0.418,
            order_type: "LONG",
            order_uuid: "634ccf1a-4528-4543-9fc8-800f51cb8dfd",
            price: 1.26839,
            price_sources: [],
            processed_ms: 1719349206361,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "34e4d46e-0d14-4911-b163-cd9621f73408",
            price: 1.269195,
            price_sources: [],
            processed_ms: 1719365414133,
          },
        ],
        position_type: "FLAT",
        position_uuid: "a5c802e6-2597-4201-91fe-8dedea5d8d1c",
        return_at_close: 1.0002137543277636,
        trade_pair: ["GBPUSD", "GBP/USD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 1.2651843440677966,
        close_ms: 1719485055040,
        current_return: 1.0005842159180456,
        initial_entry_price: 1.269,
        is_closed_position: true,
        miner_hotkey: "5EF393sRCV3Q6SFNTpKQed8m3QDGRgfDvke8sUoH3kbLqGZS",
        net_leverage: 0.0,
        open_ms: 1719365443856,
        orders: [
          {
            leverage: 0.489,
            order_type: "LONG",
            order_uuid: "aa2f65a9-f60a-4122-a4ff-ae0a9c001a05",
            price: 1.269,
            price_sources: [],
            processed_ms: 1719365443792,
          },
          {
            leverage: 0.487,
            order_type: "LONG",
            order_uuid: "76482293-c34c-474a-98fe-a80e045cc542",
            price: 1.26854,
            price_sources: [],
            processed_ms: 1719365962560,
          },
          {
            leverage: 0.271,
            order_type: "LONG",
            order_uuid: "18a42e4d-ef0c-4dc7-86b7-c1aa82b985b8",
            price: 1.2680799999999999,
            price_sources: [],
            processed_ms: 1719382639896,
          },
          {
            leverage: 0.253,
            order_type: "LONG",
            order_uuid: "f0c67b0c-f577-458a-84f9-fec87ef901ac",
            price: 1.26743,
            price_sources: [],
            processed_ms: 1719385153803,
          },
          {
            leverage: 0.314,
            order_type: "LONG",
            order_uuid: "d860f25f-c5ad-45b7-8e25-54a05e818e02",
            price: 1.26678,
            price_sources: [],
            processed_ms: 1719387173264,
          },
          {
            leverage: 0.547,
            order_type: "LONG",
            order_uuid: "ee3f4741-3bb3-43f1-b636-9a6b1c0a0d84",
            price: 1.26612,
            price_sources: [],
            processed_ms: 1719396776817,
          },
          {
            leverage: 0.612,
            order_type: "LONG",
            order_uuid: "f3cec4d2-872c-419d-acbe-7a33f2c89649",
            price: 1.265465,
            price_sources: [],
            processed_ms: 1719399957269,
          },
          {
            leverage: 0.464,
            order_type: "LONG",
            order_uuid: "416a2695-1db4-413e-8922-22cfbc1b7a21",
            price: 1.264615,
            price_sources: [],
            processed_ms: 1719401128636,
          },
          {
            leverage: 0.728,
            order_type: "LONG",
            order_uuid: "899517e9-f55b-4d8c-a80f-383d22716d98",
            price: 1.26398,
            price_sources: [],
            processed_ms: 1719401557932,
          },
          {
            leverage: 0.736,
            order_type: "LONG",
            order_uuid: "66d06b09-e28e-4afd-88e5-31c853cfd785",
            price: 1.26314,
            price_sources: [],
            processed_ms: 1719408696927,
          },
          {
            leverage: 0.263,
            order_type: "LONG",
            order_uuid: "2bcf0093-5c15-407a-a083-488f8e99a06d",
            price: 1.26238,
            price_sources: [],
            processed_ms: 1719413953685,
          },
          {
            leverage: 0.438,
            order_type: "LONG",
            order_uuid: "35a783ad-5be4-419f-892f-61128499f4fd",
            price: 1.26176,
            price_sources: [],
            processed_ms: 1719414748068,
          },
          {
            leverage: 0.298,
            order_type: "LONG",
            order_uuid: "34abc3df-76a1-4f65-a224-a60d191531f0",
            price: 1.26131,
            price_sources: [],
            processed_ms: 1719448913076,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "5538556a-b6bd-4c7b-9c66-27ef1818c843",
            price: 1.26531,
            price_sources: [],
            processed_ms: 1719485055040,
          },
        ],
        position_type: "FLAT",
        position_uuid: "e6dec60e-a224-4506-9ab7-4fb15cd0e91c",
        return_at_close: 1.0001709746368714,
        trade_pair: ["GBPUSD", "GBP/USD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 1.2645534934497817,
        close_ms: 1719491730692,
        current_return: 1.0002992245488391,
        initial_entry_price: 1.2650700000000001,
        is_closed_position: true,
        miner_hotkey: "5EF393sRCV3Q6SFNTpKQed8m3QDGRgfDvke8sUoH3kbLqGZS",
        net_leverage: 0.0,
        open_ms: 1719485086608,
        orders: [
          {
            leverage: 0.284,
            order_type: "LONG",
            order_uuid: "b4dc5a1d-6ea5-4d60-94a8-737b438091a1",
            price: 1.2650700000000001,
            price_sources: [],
            processed_ms: 1719485084703,
          },
          {
            leverage: 0.374,
            order_type: "LONG",
            order_uuid: "b0c9e909-0e2e-473e-a666-404a180b4d8a",
            price: 1.26463,
            price_sources: [],
            processed_ms: 1719487506698,
          },
          {
            leverage: -0.2,
            order_type: "SHORT",
            order_uuid: "23ec32a2-58fc-4210-bd95-9d9bd88ee4ea",
            price: 1.26543,
            price_sources: [],
            processed_ms: 1719491502453,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "dc87810a-2e61-481d-b836-e5835751b869",
            price: 1.26538,
            price_sources: [],
            processed_ms: 1719491730692,
          },
        ],
        position_type: "FLAT",
        position_uuid: "7f6d1015-1318-4a67-ba55-c46e8faa0fe6",
        return_at_close: 1.0002531507665564,
        trade_pair: ["GBPUSD", "GBP/USD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 1.26506,
        close_ms: 1719502749683,
        current_return: 1.0001231245948807,
        initial_entry_price: 1.26506,
        is_closed_position: true,
        miner_hotkey: "5EF393sRCV3Q6SFNTpKQed8m3QDGRgfDvke8sUoH3kbLqGZS",
        net_leverage: 0.0,
        open_ms: 1719500202068,
        orders: [
          {
            leverage: 0.708,
            order_type: "LONG",
            order_uuid: "e1223b5b-67fd-4fec-b4f1-01d16ed761a7",
            price: 1.26506,
            price_sources: [],
            processed_ms: 1719500199570,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "1c567ce9-3c53-4144-ade2-7f0f43135559",
            price: 1.26528,
            price_sources: [],
            processed_ms: 1719502749683,
          },
        ],
        position_type: "FLAT",
        position_uuid: "01142766-840c-4d5e-8342-e75e8fdfaf10",
        return_at_close: 1.0000735584928258,
        trade_pair: ["GBPUSD", "GBP/USD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 1.263900513522215,
        close_ms: 1719558789082,
        current_return: 1.0004406525785785,
        initial_entry_price: 1.265135,
        is_closed_position: true,
        miner_hotkey: "5EF393sRCV3Q6SFNTpKQed8m3QDGRgfDvke8sUoH3kbLqGZS",
        net_leverage: 0.0,
        open_ms: 1719502784153,
        orders: [
          {
            leverage: 0.659,
            order_type: "LONG",
            order_uuid: "f6da64e4-bb9d-49a7-a1b7-2b9e85185b31",
            price: 1.265135,
            price_sources: [],
            processed_ms: 1719502783590,
          },
          {
            leverage: 0.487,
            order_type: "LONG",
            order_uuid: "1a127ef8-79c1-4e03-bc42-1aad7063298f",
            price: 1.2646,
            price_sources: [],
            processed_ms: 1719503026017,
          },
          {
            leverage: 0.605,
            order_type: "LONG",
            order_uuid: "c8517b96-f505-4200-ab4b-15016aab82b3",
            price: 1.264095,
            price_sources: [],
            processed_ms: 1719511354241,
          },
          {
            leverage: 0.43,
            order_type: "LONG",
            order_uuid: "359b4eee-9df6-456d-9a16-d1f31e70dbb7",
            price: 1.2637,
            price_sources: [],
            processed_ms: 1719535891031,
          },
          {
            leverage: 0.502,
            order_type: "LONG",
            order_uuid: "e0322991-97b5-48dc-94bf-56ca4fe6b074",
            price: 1.2629,
            price_sources: [],
            processed_ms: 1719536171057,
          },
          {
            leverage: 0.423,
            order_type: "LONG",
            order_uuid: "8fb023c7-5644-411b-bb40-ef72d13b5d99",
            price: 1.2622849999999999,
            price_sources: [],
            processed_ms: 1719538839098,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "69533a3d-17c1-4d48-ba4a-2c64ebb2ac01",
            price: 1.2640799999999999,
            price_sources: [],
            processed_ms: 1719558789082,
          },
        ],
        position_type: "FLAT",
        position_uuid: "1ba30511-fbaf-412b-a082-4a0be292eb84",
        return_at_close: 1.0002231367718948,
        trade_pair: ["GBPUSD", "GBP/USD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 1.2636983389261747,
        close_ms: 1719559881757,
        current_return: 1.0006695026876935,
        initial_entry_price: 1.2640950000000002,
        is_closed_position: true,
        miner_hotkey: "5EF393sRCV3Q6SFNTpKQed8m3QDGRgfDvke8sUoH3kbLqGZS",
        net_leverage: 0.0,
        open_ms: 1719558820323,
        orders: [
          {
            leverage: 0.391,
            order_type: "LONG",
            order_uuid: "c00cec66-588c-47fa-be0d-2a2cbf9103af",
            price: 1.2640950000000002,
            price_sources: [],
            processed_ms: 1719558820175,
          },
          {
            leverage: 0.503,
            order_type: "LONG",
            order_uuid: "175508b0-b572-4eeb-8aea-4251d0749fb8",
            price: 1.26339,
            price_sources: [],
            processed_ms: 1719559141221,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "2b5ebda9-9784-4739-824e-0f1379a7a033",
            price: 1.264645,
            price_sources: [],
            processed_ms: 1719559881757,
          },
        ],
        position_type: "FLAT",
        position_uuid: "e8020eed-b8cb-4813-97ce-9571607cb55d",
        return_at_close: 1.0006068807902153,
        trade_pair: ["GBPUSD", "GBP/USD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 1.2642653411186233,
        close_ms: 1719568004729,
        current_return: 1.0006749584881791,
        initial_entry_price: 1.2647,
        is_closed_position: true,
        miner_hotkey: "5EF393sRCV3Q6SFNTpKQed8m3QDGRgfDvke8sUoH3kbLqGZS",
        net_leverage: 0.0,
        open_ms: 1719559911616,
        orders: [
          {
            leverage: 0.654,
            order_type: "LONG",
            order_uuid: "9c4e2431-3f32-43b8-85d3-d876579ff4ed",
            price: 1.2647,
            price_sources: [],
            processed_ms: 1719559911592,
          },
          {
            leverage: 0.68,
            order_type: "LONG",
            order_uuid: "a864de38-1a09-4c13-840b-32ca4c02ad33",
            price: 1.2641900000000001,
            price_sources: [],
            processed_ms: 1719561824600,
          },
          {
            leverage: 0.293,
            order_type: "LONG",
            order_uuid: "1e49d227-a1f1-4bb6-bc95-50b17f16a6f2",
            price: 1.26347,
            price_sources: [],
            processed_ms: 1719563397309,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "2e0ad13e-f695-47c6-a21f-da9cb978713e",
            price: 1.26479,
            price_sources: [],
            processed_ms: 1719568004729,
          },
        ],
        position_type: "FLAT",
        position_uuid: "122640bc-9713-40de-91cf-e441c55e5f2e",
        return_at_close: 1.000560991617157,
        trade_pair: ["GBPUSD", "GBP/USD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 60959.03,
        close_ms: 1719678081290,
        current_return: 1.0011801368886613,
        initial_entry_price: 60959.03,
        is_closed_position: true,
        miner_hotkey: "5EF393sRCV3Q6SFNTpKQed8m3QDGRgfDvke8sUoH3kbLqGZS",
        net_leverage: 0.0,
        open_ms: 1719676317151,
        orders: [
          {
            leverage: 2.0,
            order_type: "LONG",
            order_uuid: "3e1e4cc0-e04c-44b1-bc6b-f6102b449793",
            price: 60959.03,
            price_sources: [],
            processed_ms: 1719676315478,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "4446518d-2758-4279-a5ca-5b9cba283cd8",
            price: 60995.0,
            price_sources: [],
            processed_ms: 1719678081290,
          },
        ],
        position_type: "FLAT",
        position_uuid: "c636531e-84d1-48e3-a986-9caab379c81e",
        return_at_close: 0.9991777766148839,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 60875.56,
        close_ms: 1719725326434,
        current_return: 1.0069472872200271,
        initial_entry_price: 60875.56,
        is_closed_position: true,
        miner_hotkey: "5EF393sRCV3Q6SFNTpKQed8m3QDGRgfDvke8sUoH3kbLqGZS",
        net_leverage: 0.0,
        open_ms: 1719679523756,
        orders: [
          {
            leverage: -2.0,
            order_type: "SHORT",
            order_uuid: "6d298da2-9761-4686-87f0-d1a381f0b2d0",
            price: 60875.56,
            price_sources: [],
            processed_ms: 1719679523432,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "cf8598c7-097d-47cd-a580-30ea3e4fdd11",
            price: 60664.1,
            price_sources: [],
            processed_ms: 1719725326434,
          },
        ],
        position_type: "FLAT",
        position_uuid: "75b1631e-66c1-4792-b84a-25cc39d9784b",
        return_at_close: 1.004933392645587,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 3380.0,
        close_ms: 1719795298671,
        current_return: 0.9990052071005917,
        initial_entry_price: 3380.0,
        is_closed_position: true,
        miner_hotkey: "5EF393sRCV3Q6SFNTpKQed8m3QDGRgfDvke8sUoH3kbLqGZS",
        net_leverage: 0.0,
        open_ms: 1719605671840,
        orders: [
          {
            leverage: -0.06,
            order_type: "SHORT",
            order_uuid: "47c460b2-8815-4224-8acb-c792000e72df",
            price: 3380.0,
            price_sources: [],
            processed_ms: 1719605670680,
          },
          {
            leverage: 0.85,
            order_type: "LONG",
            order_uuid: "537f8f98-632e-4715-9f29-74f97f30e303",
            price: 3436.04,
            price_sources: [],
            processed_ms: 1719795298671,
          },
        ],
        position_type: "FLAT",
        position_uuid: "83ef8cde-99ca-414b-b7f9-858d7839f485",
        return_at_close: 0.9989452667881658,
        trade_pair: ["ETHUSD", "ETH/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 62580.3,
        close_ms: 1719855237597,
        current_return: 1.0144440343047252,
        initial_entry_price: 62580.3,
        is_closed_position: true,
        miner_hotkey: "5EF393sRCV3Q6SFNTpKQed8m3QDGRgfDvke8sUoH3kbLqGZS",
        net_leverage: 0.0,
        open_ms: 1719835216011,
        orders: [
          {
            leverage: 1.2,
            order_type: "LONG",
            order_uuid: "0f847b49-7de0-4ccf-a601-068a47f1623f",
            price: 62580.3,
            price_sources: [],
            processed_ms: 1719835215274,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "0f97e744-2088-4ee1-bd92-8f40cc0e106c",
            price: 63333.56,
            price_sources: [],
            processed_ms: 1719855237597,
          },
        ],
        position_type: "FLAT",
        position_uuid: "35038d69-1143-4160-92bc-ba2c4aecba92",
        return_at_close: 1.0132267014635596,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 1.2646531226533164,
        close_ms: 1719995382092,
        current_return: 1.0031250805472869,
        initial_entry_price: 1.2647849999999998,
        is_closed_position: true,
        miner_hotkey: "5EF393sRCV3Q6SFNTpKQed8m3QDGRgfDvke8sUoH3kbLqGZS",
        net_leverage: 0.0,
        open_ms: 1719568034668,
        orders: [
          {
            leverage: 0.542,
            order_type: "LONG",
            order_uuid: "8bb0b321-d85a-41f3-a60c-b679e4fb56cd",
            price: 1.2647849999999998,
            price_sources: [],
            processed_ms: 1719568034653,
          },
          {
            leverage: 0.257,
            order_type: "LONG",
            order_uuid: "7dcb1d6c-2ac6-44db-a43b-279ec17ee553",
            price: 1.264375,
            price_sources: [],
            processed_ms: 1719574261454,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "07e84c35-3ca2-4761-833f-91b71ad61a90",
            price: 1.2696,
            price_sources: [],
            processed_ms: 1719995382092,
          },
        ],
        position_type: "FLAT",
        position_uuid: "a855dd3b-72df-463c-8b01-cf43607cb804",
        return_at_close: 1.0030689757615319,
        trade_pair: ["GBPUSD", "GBP/USD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 1.269940072471998,
        close_ms: 1720008909872,
        current_return: 1.000793419219438,
        initial_entry_price: 1.270105,
        is_closed_position: true,
        miner_hotkey: "5EF393sRCV3Q6SFNTpKQed8m3QDGRgfDvke8sUoH3kbLqGZS",
        net_leverage: 0.0,
        open_ms: 1720004752484,
        orders: [
          {
            leverage: 0.486133494989191,
            order_type: "LONG",
            order_uuid: "97c83f29-28a2-4090-96c6-82e5a64edf2a",
            price: 1.270105,
            price_sources: [],
            processed_ms: 1720004750103,
          },
          {
            leverage: 0.364319966416627,
            order_type: "LONG",
            order_uuid: "88cdf150-2a1c-4943-94ef-326cef893dfe",
            price: 1.26972,
            price_sources: [],
            processed_ms: 1720005789670,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "e5166bc9-a247-4ae4-b87a-9580aaffc563",
            price: 1.271125,
            price_sources: [],
            processed_ms: 1720008909872,
          },
        ],
        position_type: "FLAT",
        position_uuid: "97c83f29-28a2-4090-96c6-82e5a64edf2a",
        return_at_close: 1.000733840243511,
        trade_pair: ["GBPUSD", "GBP/USD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 1.27082,
        close_ms: 1720013041325,
        current_return: 1.0007905655787073,
        initial_entry_price: 1.27082,
        is_closed_position: true,
        miner_hotkey: "5EF393sRCV3Q6SFNTpKQed8m3QDGRgfDvke8sUoH3kbLqGZS",
        net_leverage: 0.0,
        open_ms: 1720008927769,
        orders: [
          {
            leverage: 0.3500580309173954,
            order_type: "LONG",
            order_uuid: "a6a19cc4-cd1d-45c9-83c7-50c6a89b0895",
            price: 1.27082,
            price_sources: [],
            processed_ms: 1720008927716,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "4e1db91f-34b9-4768-be8a-98a969857f31",
            price: 1.27369,
            price_sources: [],
            processed_ms: 1720013041325,
          },
        ],
        position_type: "FLAT",
        position_uuid: "a6a19cc4-cd1d-45c9-83c7-50c6a89b0895",
        return_at_close: 1.0007660421444748,
        trade_pair: ["GBPUSD", "GBP/USD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 1.27365,
        close_ms: 1720015203770,
        current_return: 1.0010692830093597,
        initial_entry_price: 1.27365,
        is_closed_position: true,
        miner_hotkey: "5EF393sRCV3Q6SFNTpKQed8m3QDGRgfDvke8sUoH3kbLqGZS",
        net_leverage: 0.0,
        open_ms: 1720013057828,
        orders: [
          {
            leverage: 0.4436131286224769,
            order_type: "LONG",
            order_uuid: "ace6f679-46df-4d9f-875b-de5ae342eaa1",
            price: 1.27365,
            price_sources: [],
            processed_ms: 1720013057746,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "4a0d2dfb-bacc-4a60-ad95-db5391945d5e",
            price: 1.27672,
            price_sources: [],
            processed_ms: 1720015203770,
          },
        ],
        position_type: "FLAT",
        position_uuid: "ace6f679-46df-4d9f-875b-de5ae342eaa1",
        return_at_close: 1.0010381968859974,
        trade_pair: ["GBPUSD", "GBP/USD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 1.2759117196998069,
        close_ms: 1720015843778,
        current_return: 1.0006987426675267,
        initial_entry_price: 1.27601,
        is_closed_position: true,
        miner_hotkey: "5EF393sRCV3Q6SFNTpKQed8m3QDGRgfDvke8sUoH3kbLqGZS",
        net_leverage: 0.0,
        open_ms: 1720015221965,
        orders: [
          {
            leverage: 0.3881497958007453,
            order_type: "LONG",
            order_uuid: "c465154c-6fd3-44b1-b49a-efa1a639ba2b",
            price: 1.27601,
            price_sources: [],
            processed_ms: 1720015221718,
          },
          {
            leverage: 0.3750254721922352,
            order_type: "LONG",
            order_uuid: "09375049-9249-4bdf-b765-19bc119dbaf1",
            price: 1.27581,
            price_sources: [],
            processed_ms: 1720015371817,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "6edd73a1-0df9-4865-98f3-531e34c310dc",
            price: 1.27708,
            price_sources: [],
            processed_ms: 1720015843778,
          },
        ],
        position_type: "FLAT",
        position_uuid: "c465154c-6fd3-44b1-b49a-efa1a639ba2b",
        return_at_close: 1.0006452830703487,
        trade_pair: ["GBPUSD", "GBP/USD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 1.2754981399207186,
        close_ms: 1720080710303,
        current_return: 1.000751080209773,
        initial_entry_price: 1.27703,
        is_closed_position: true,
        miner_hotkey: "5EF393sRCV3Q6SFNTpKQed8m3QDGRgfDvke8sUoH3kbLqGZS",
        net_leverage: 0.0,
        open_ms: 1720015863922,
        orders: [
          {
            leverage: 0.4156678516315683,
            order_type: "LONG",
            order_uuid: "63f4d4f7-6172-4954-ad33-6f9f616307cd",
            price: 1.27703,
            price_sources: [],
            processed_ms: 1720015863859,
          },
          {
            leverage: 0.3033746609347836,
            order_type: "LONG",
            order_uuid: "00b60732-1faf-4dc2-862b-96303b1792c9",
            price: 1.2763550000000001,
            price_sources: [],
            processed_ms: 1720016503586,
          },
          {
            leverage: 0.4652788987413825,
            order_type: "LONG",
            order_uuid: "fb05b458-3458-44d7-a7ad-a701e9f07a8d",
            price: 1.27578,
            price_sources: [
              {
                close: 1.27578,
                high: 1.27578,
                lag_ms: 49,
                low: 1.27578,
                open: 1.27578,
                source: "TwelveData_ws",
                start_ms: 1720024286000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
              {
                close: 1.275795,
                high: 1.275795,
                lag_ms: 50,
                low: 1.275795,
                open: 1.275795,
                source: "Polygon_ws",
                start_ms: 1720024285999,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 1.2758,
                websocket: true,
              },
            ],
            processed_ms: 1720024286049,
          },
          {
            leverage: 0.3114901278453013,
            order_type: "LONG",
            order_uuid: "1044b0b2-c5ed-4312-818a-c974d1ae552e",
            price: 1.2750949999999999,
            price_sources: [
              {
                close: 1.2750949999999999,
                high: 1.2750949999999999,
                lag_ms: 478,
                low: 1.2750949999999999,
                open: 1.2750949999999999,
                source: "Polygon_ws",
                start_ms: 1720026206999,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 1.2751,
                websocket: true,
              },
              {
                close: 1.27508,
                high: 1.27508,
                lag_ms: 479,
                low: 1.27508,
                open: 1.27508,
                source: "TwelveData_ws",
                start_ms: 1720026207000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1720026206521,
          },
          {
            leverage: 0.2601152517279687,
            order_type: "LONG",
            order_uuid: "f41a4e5a-65ce-49e2-9afd-282df306b2a4",
            price: 1.27421,
            price_sources: [
              {
                close: 1.27421,
                high: 1.27421,
                lag_ms: 349,
                low: 1.27421,
                open: 1.27421,
                source: "Polygon_ws",
                start_ms: 1720027723999,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 1.27421,
                websocket: true,
              },
              {
                close: 1.2742,
                high: 1.2742,
                lag_ms: 650,
                low: 1.2742,
                open: 1.2742,
                source: "TwelveData_ws",
                start_ms: 1720027723000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1720027723650,
          },
          {
            leverage: 0.3207886545820106,
            order_type: "LONG",
            order_uuid: "f85fa87d-db1c-4781-b03a-c42ba7a98a5c",
            price: 1.27373,
            price_sources: [
              {
                close: 1.27373,
                high: 1.27373,
                lag_ms: 244,
                low: 1.27373,
                open: 1.27373,
                source: "Polygon_ws",
                start_ms: 1720028746999,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 1.2738,
                websocket: true,
              },
              {
                close: 1.27367,
                high: 1.27367,
                lag_ms: 245,
                low: 1.27367,
                open: 1.27367,
                source: "TwelveData_ws",
                start_ms: 1720028747000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1720028746755,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "7883c32e-d411-4c09-b75c-4df812b6244d",
            price: 1.27596,
            price_sources: [
              {
                close: 1.27596,
                high: 1.27596,
                lag_ms: 303,
                low: 1.27596,
                open: 1.27596,
                source: "Polygon_ws",
                start_ms: 1720080710000,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 1.27596,
                websocket: true,
              },
              {
                close: 1.2759,
                high: 1.2759,
                lag_ms: 5697,
                low: 1.2759,
                open: 1.2759,
                source: "TwelveData_ws",
                start_ms: 1720080716000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1720080710303,
          },
        ],
        position_type: "FLAT",
        position_uuid: "63f4d4f7-6172-4954-ad33-6f9f616307cd",
        return_at_close: 1.0006056009439994,
        trade_pair: ["GBPUSD", "GBP/USD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 1.2753873838625491,
        close_ms: 1720104695647,
        current_return: 1.000757334566561,
        initial_entry_price: 1.27596,
        is_closed_position: true,
        miner_hotkey: "5EF393sRCV3Q6SFNTpKQed8m3QDGRgfDvke8sUoH3kbLqGZS",
        net_leverage: 0.0,
        open_ms: 1720080727083,
        orders: [
          {
            leverage: 0.4506255851545582,
            order_type: "LONG",
            order_uuid: "745e8dc4-996f-4c4e-b5d8-e21671333c4e",
            price: 1.27596,
            price_sources: [
              {
                close: 1.27596,
                high: 1.27596,
                lag_ms: 149,
                low: 1.27596,
                open: 1.27596,
                source: "Polygon_ws",
                start_ms: 1720080726999,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 1.27596,
                websocket: true,
              },
              {
                close: 1.27599,
                high: 1.27599,
                lag_ms: 850,
                low: 1.27599,
                open: 1.27599,
                source: "TwelveData_ws",
                start_ms: 1720080726000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1720080726850,
          },
          {
            leverage: 0.318207772194946,
            order_type: "LONG",
            order_uuid: "df5c571a-f4de-485c-aa7a-91ecdb993677",
            price: 1.2752,
            price_sources: [
              {
                close: 1.2752,
                high: 1.2752,
                lag_ms: 119,
                low: 1.2752,
                open: 1.2752,
                source: "Polygon_ws",
                start_ms: 1720081946999,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 1.27524,
                websocket: true,
              },
              {
                close: 1.27519,
                high: 1.27519,
                lag_ms: 120,
                low: 1.27519,
                open: 1.27519,
                source: "TwelveData_ws",
                start_ms: 1720081947000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1720081946880,
          },
          {
            leverage: 0.3137469064453168,
            order_type: "LONG",
            order_uuid: "3eab8aa8-c4eb-46db-8bf4-a70aea218bc7",
            price: 1.2747549999999999,
            price_sources: [
              {
                close: 1.2747549999999999,
                high: 1.2747549999999999,
                lag_ms: 425,
                low: 1.2747549999999999,
                open: 1.2747549999999999,
                source: "Polygon_ws",
                start_ms: 1720097857000,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 1.27475,
                websocket: true,
              },
              {
                close: 1.27477,
                high: 1.27477,
                lag_ms: 425,
                low: 1.27477,
                open: 1.27477,
                source: "TwelveData_ws",
                start_ms: 1720097857000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1720097857425,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "2be17a03-eb92-40fb-9607-3f62a852e0bc",
            price: 1.27628,
            price_sources: [
              {
                close: 1.27628,
                high: 1.27628,
                lag_ms: 352,
                low: 1.27628,
                open: 1.27628,
                source: "Polygon_ws",
                start_ms: 1720104695999,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 1.27628,
                websocket: true,
              },
              {
                close: 1.27632,
                high: 1.27632,
                lag_ms: 353,
                low: 1.27632,
                open: 1.27632,
                source: "TwelveData_ws",
                start_ms: 1720104696000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1720104695647,
          },
        ],
        position_type: "FLAT",
        position_uuid: "745e8dc4-996f-4c4e-b5d8-e21671333c4e",
        return_at_close: 1.0006814965568134,
        trade_pair: ["GBPUSD", "GBP/USD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 1.2759785937270258,
        close_ms: 1720140177814,
        current_return: 1.0005370542144647,
        initial_entry_price: 1.276345,
        is_closed_position: true,
        miner_hotkey: "5EF393sRCV3Q6SFNTpKQed8m3QDGRgfDvke8sUoH3kbLqGZS",
        net_leverage: 0.0,
        open_ms: 1720104715686,
        orders: [
          {
            leverage: 0.45754046351193856,
            order_type: "LONG",
            order_uuid: "f2e742b1-af35-4eff-be38-e87abb71547c",
            price: 1.276345,
            price_sources: [
              {
                close: 1.276345,
                high: 1.276345,
                lag_ms: 324,
                low: 1.276345,
                open: 1.276345,
                source: "Polygon_ws",
                start_ms: 1720104715999,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 1.27635,
                websocket: true,
              },
              {
                close: 1.27637,
                high: 1.27637,
                lag_ms: 325,
                low: 1.27637,
                open: 1.27637,
                source: "TwelveData_ws",
                start_ms: 1720104716000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1720104715675,
          },
          {
            leverage: 0.39091090856994704,
            order_type: "LONG",
            order_uuid: "0babd9e8-1411-46ab-82ae-e923647f188d",
            price: 1.2758,
            price_sources: [
              {
                close: 1.2758,
                high: 1.2758,
                lag_ms: 232,
                low: 1.2758,
                open: 1.2758,
                source: "Polygon_ws",
                start_ms: 1720108847000,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 1.2758,
                websocket: true,
              },
              {
                close: 1.27589,
                high: 1.27589,
                lag_ms: 232,
                low: 1.27589,
                open: 1.27589,
                source: "TwelveData_ws",
                start_ms: 1720108847000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1720108847232,
          },
          {
            leverage: 0.3511617469573217,
            order_type: "LONG",
            order_uuid: "2703a94a-a578-4d2a-b3c5-9c4d25aaf0b6",
            price: 1.2757,
            price_sources: [
              {
                close: 1.2757,
                high: 1.2757,
                lag_ms: 478,
                low: 1.2757,
                open: 1.2757,
                source: "Polygon_ws",
                start_ms: 1720128674000,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 1.2757,
                websocket: true,
              },
              {
                close: 1.2758,
                high: 1.2758,
                lag_ms: 6478,
                low: 1.2758,
                open: 1.2758,
                source: "TwelveData_ws",
                start_ms: 1720128668000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1720128674478,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "4c262387-62d0-4d59-9d3d-264106721fcb",
            price: 1.2765499999999999,
            price_sources: [
              {
                close: 1.2765499999999999,
                high: 1.2765499999999999,
                lag_ms: 186,
                low: 1.2765499999999999,
                open: 1.2765499999999999,
                source: "Polygon_ws",
                start_ms: 1720140178000,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 1.27656,
                websocket: true,
              },
              {
                close: 1.27653,
                high: 1.27653,
                lag_ms: 186,
                low: 1.27653,
                open: 1.27653,
                source: "TwelveData_ws",
                start_ms: 1720140178000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1720140177814,
          },
        ],
        position_type: "FLAT",
        position_uuid: "f2e742b1-af35-4eff-be38-e87abb71547c",
        return_at_close: 1.0004530361981223,
        trade_pair: ["GBPUSD", "GBP/USD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 1.27653,
        close_ms: 1720182636264,
        current_return: 1.0008667616042193,
        initial_entry_price: 1.27653,
        is_closed_position: true,
        miner_hotkey: "5EF393sRCV3Q6SFNTpKQed8m3QDGRgfDvke8sUoH3kbLqGZS",
        net_leverage: 0.0,
        open_ms: 1720140195920,
        orders: [
          {
            leverage: 0.3388812222462194,
            order_type: "LONG",
            order_uuid: "1d4a77d5-9bcb-4a7b-ae93-cea2d48ecc5c",
            price: 1.27653,
            price_sources: [
              {
                close: 1.27653,
                high: 1.27653,
                lag_ms: 214,
                low: 1.27653,
                open: 1.27653,
                source: "Polygon_ws",
                start_ms: 1720140195999,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 1.27653,
                websocket: true,
              },
              {
                close: 1.2765,
                high: 1.2765,
                lag_ms: 785,
                low: 1.2765,
                open: 1.2765,
                source: "TwelveData_ws",
                start_ms: 1720140195000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1720140195785,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "4caabd6c-d66e-4421-ae43-ff4af4892701",
            price: 1.279795,
            price_sources: [
              {
                close: 1.279795,
                high: 1.279795,
                lag_ms: 264,
                low: 1.279795,
                open: 1.279795,
                source: "Polygon_ws",
                start_ms: 1720182636000,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 1.27984,
                websocket: true,
              },
              {
                close: 1.27986,
                high: 1.27986,
                lag_ms: 264,
                low: 1.27986,
                open: 1.27986,
                source: "TwelveData_ws",
                start_ms: 1720182636000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1720182636264,
          },
        ],
        position_type: "FLAT",
        position_uuid: "1d4a77d5-9bcb-4a7b-ae93-cea2d48ecc5c",
        return_at_close: 1.0008430193576159,
        trade_pair: ["GBPUSD", "GBP/USD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 1.279584158580249,
        close_ms: 1720183493724,
        current_return: 1.0005369854838841,
        initial_entry_price: 1.28033,
        is_closed_position: true,
        miner_hotkey: "5EF393sRCV3Q6SFNTpKQed8m3QDGRgfDvke8sUoH3kbLqGZS",
        net_leverage: 0.0,
        open_ms: 1720182653759,
        orders: [
          {
            leverage: 0.39536446500327704,
            order_type: "LONG",
            order_uuid: "bbc4803a-a0ef-4558-9c58-e72ba41e4af2",
            price: 1.28033,
            price_sources: [
              {
                close: 1.28033,
                high: 1.28033,
                lag_ms: 323,
                low: 1.28033,
                open: 1.28033,
                source: "Polygon_ws",
                start_ms: 1720182653999,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 1.28,
                websocket: true,
              },
              {
                close: 1.28041,
                high: 1.28041,
                lag_ms: 324,
                low: 1.28041,
                open: 1.28041,
                source: "TwelveData_ws",
                start_ms: 1720182654000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1720182653676,
          },
          {
            leverage: 0.48029235405070514,
            order_type: "LONG",
            order_uuid: "da61d76b-5b4f-401b-993a-c4b45c97ddf4",
            price: 1.27982,
            price_sources: [
              {
                close: 1.27982,
                high: 1.27982,
                lag_ms: 223,
                low: 1.27982,
                open: 1.27982,
                source: "Polygon_ws",
                start_ms: 1720182898000,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 1.27993,
                websocket: true,
              },
              {
                close: 1.27981,
                high: 1.27981,
                lag_ms: 777,
                low: 1.27981,
                open: 1.27981,
                source: "TwelveData_ws",
                start_ms: 1720182899000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1720182898223,
          },
          {
            leverage: 0.48350160043600954,
            order_type: "LONG",
            order_uuid: "bc94bab8-392a-41ff-849d-5d08a838514f",
            price: 1.27874,
            price_sources: [
              {
                close: 1.27874,
                high: 1.27874,
                lag_ms: 250,
                low: 1.27874,
                open: 1.27874,
                source: "Polygon_ws",
                start_ms: 1720183169999,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 1.27874,
                websocket: true,
              },
              {
                close: 1.27858,
                high: 1.27858,
                lag_ms: 251,
                low: 1.27858,
                open: 1.27858,
                source: "TwelveData_ws",
                start_ms: 1720183170000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1720183169749,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "66afdf7a-cb00-4759-b33d-ecbace97d200",
            price: 1.28009,
            price_sources: [
              {
                close: 1.28009,
                high: 1.28009,
                lag_ms: 275,
                low: 1.28009,
                open: 1.28009,
                source: "Polygon_ws",
                start_ms: 1720183493999,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 1.28009,
                websocket: true,
              },
              {
                close: 1.28012,
                high: 1.28012,
                lag_ms: 276,
                low: 1.28012,
                open: 1.28012,
                source: "TwelveData_ws",
                start_ms: 1720183494000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1720183493724,
          },
        ],
        position_type: "FLAT",
        position_uuid: "bbc4803a-a0ef-4558-9c58-e72ba41e4af2",
        return_at_close: 1.000441793305136,
        trade_pair: ["GBPUSD", "GBP/USD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 1.2796544693810068,
        close_ms: 1720186448748,
        current_return: 1.000781168048124,
        initial_entry_price: 1.27989,
        is_closed_position: true,
        miner_hotkey: "5EF393sRCV3Q6SFNTpKQed8m3QDGRgfDvke8sUoH3kbLqGZS",
        net_leverage: 0.0,
        open_ms: 1720183511927,
        orders: [
          {
            leverage: 0.38727643226816677,
            order_type: "LONG",
            order_uuid: "be8fab62-dce1-4eeb-bb60-7e10efd534c8",
            price: 1.27989,
            price_sources: [
              {
                close: 1.27989,
                high: 1.27989,
                lag_ms: 293,
                low: 1.27989,
                open: 1.27989,
                source: "Polygon_ws",
                start_ms: 1720183511999,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 1.27989,
                websocket: true,
              },
              {
                close: 1.27991,
                high: 1.27991,
                lag_ms: 294,
                low: 1.27991,
                open: 1.27991,
                source: "TwelveData_ws",
                start_ms: 1720183512000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1720183511706,
          },
          {
            leverage: 0.3448998801534997,
            order_type: "LONG",
            order_uuid: "59ba6806-0b48-40c7-90a8-5654e6936523",
            price: 1.27939,
            price_sources: [
              {
                close: 1.27939,
                high: 1.27939,
                lag_ms: 252,
                low: 1.27939,
                open: 1.27939,
                source: "Polygon_ws",
                start_ms: 1720183807999,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 1.27939,
                websocket: true,
              },
              {
                close: 1.27935,
                high: 1.27935,
                lag_ms: 253,
                low: 1.27935,
                open: 1.27935,
                source: "TwelveData_ws",
                start_ms: 1720183808000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1720183807747,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "041e63d5-f0db-4b7d-9f78-6f9a38d78c9f",
            price: 1.28102,
            price_sources: [
              {
                close: 1.28102,
                high: 1.28102,
                lag_ms: 251,
                low: 1.28102,
                open: 1.28102,
                source: "Polygon_ws",
                start_ms: 1720186448999,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 1.28102,
                websocket: true,
              },
              {
                close: 1.281,
                high: 1.281,
                lag_ms: 252,
                low: 1.281,
                open: 1.281,
                source: "TwelveData_ws",
                start_ms: 1720186449000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1720186448748,
          },
        ],
        position_type: "FLAT",
        position_uuid: "be8fab62-dce1-4eeb-bb60-7e10efd534c8",
        return_at_close: 1.0007298756695626,
        trade_pair: ["GBPUSD", "GBP/USD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 1.279940022882521,
        close_ms: 1720193138261,
        current_return: 1.0007475977763662,
        initial_entry_price: 1.28107,
        is_closed_position: true,
        miner_hotkey: "5EF393sRCV3Q6SFNTpKQed8m3QDGRgfDvke8sUoH3kbLqGZS",
        net_leverage: 0.0,
        open_ms: 1720186467593,
        orders: [
          {
            leverage: 0.25639328856428584,
            order_type: "LONG",
            order_uuid: "cb1ba7b1-c006-4e92-bd00-6cea8c2a9f09",
            price: 1.28107,
            price_sources: [
              {
                close: 1.28107,
                high: 1.28107,
                lag_ms: 128,
                low: 1.28107,
                open: 1.28107,
                source: "Polygon_ws",
                start_ms: 1720186466999,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 1.28106,
                websocket: true,
              },
              {
                close: 1.28106,
                high: 1.28106,
                lag_ms: 871,
                low: 1.28106,
                open: 1.28106,
                source: "TwelveData_ws",
                start_ms: 1720186466000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1720186466871,
          },
          {
            leverage: 0.34416257687897034,
            order_type: "LONG",
            order_uuid: "9cdb8acc-9205-4506-b4ed-9d3c9871c4ec",
            price: 1.28039,
            price_sources: [
              {
                close: 1.28039,
                high: 1.28039,
                lag_ms: 185,
                low: 1.28039,
                open: 1.28039,
                source: "Polygon_ws",
                start_ms: 1720187259999,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 1.28039,
                websocket: true,
              },
              {
                close: 1.28027,
                high: 1.28027,
                lag_ms: 57186,
                low: 1.28027,
                open: 1.28027,
                source: "TwelveData_ws",
                start_ms: 1720187317000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1720187259814,
          },
          {
            leverage: 0.44265750356554273,
            order_type: "LONG",
            order_uuid: "5c7ad9c3-ac4b-41a3-8d1a-857aac03e5e9",
            price: 1.27959,
            price_sources: [
              {
                close: 1.27959,
                high: 1.27959,
                lag_ms: 2,
                low: 1.27959,
                open: 1.27959,
                source: "Polygon_ws",
                start_ms: 1720187486000,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 1.27959,
                websocket: true,
              },
              {
                close: 1.2796,
                high: 1.2796,
                lag_ms: 2,
                low: 1.2796,
                open: 1.2796,
                source: "TwelveData_ws",
                start_ms: 1720187486000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1720187486002,
          },
          {
            leverage: 0.40793555419612015,
            order_type: "LONG",
            order_uuid: "7404b276-6a61-4bf0-bb0d-722d4e6b314a",
            price: 1.27923,
            price_sources: [
              {
                close: 1.27923,
                high: 1.27923,
                lag_ms: 195,
                low: 1.27923,
                open: 1.27923,
                source: "Polygon_ws",
                start_ms: 1720187584999,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 1.27918,
                websocket: true,
              },
              {
                close: 1.27926,
                high: 1.27926,
                lag_ms: 804,
                low: 1.27926,
                open: 1.27926,
                source: "TwelveData_ws",
                start_ms: 1720187584000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1720187584804,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "6f4c40d7-8697-4ded-ac98-c51a48cb6f4f",
            price: 1.2806,
            price_sources: [
              {
                close: 1.2806,
                high: 1.2806,
                lag_ms: 261,
                low: 1.2806,
                open: 1.2806,
                source: "Polygon_ws",
                start_ms: 1720193138000,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 1.2806,
                websocket: true,
              },
              {
                close: 1.28058,
                high: 1.28058,
                lag_ms: 261,
                low: 1.28058,
                open: 1.28058,
                source: "TwelveData_ws",
                start_ms: 1720193138000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1720193138261,
          },
        ],
        position_type: "FLAT",
        position_uuid: "cb1ba7b1-c006-4e92-bd00-6cea8c2a9f09",
        return_at_close: 1.0006459414104423,
        trade_pair: ["GBPUSD", "GBP/USD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 1.2802533822930375,
        close_ms: 1720205623572,
        current_return: 1.000731947273029,
        initial_entry_price: 1.28051,
        is_closed_position: true,
        miner_hotkey: "5EF393sRCV3Q6SFNTpKQed8m3QDGRgfDvke8sUoH3kbLqGZS",
        net_leverage: 0.0,
        open_ms: 1720193155861,
        orders: [
          {
            leverage: 0.3700059811078397,
            order_type: "LONG",
            order_uuid: "6a9cd77c-d109-478c-ab83-7c259006c571",
            price: 1.28051,
            price_sources: [
              {
                close: 1.28051,
                high: 1.28051,
                lag_ms: 248,
                low: 1.28051,
                open: 1.28051,
                source: "Polygon_ws",
                start_ms: 1720193155999,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 1.28051,
                websocket: true,
              },
              {
                close: 1.28049,
                high: 1.28049,
                lag_ms: 249,
                low: 1.28049,
                open: 1.28049,
                source: "TwelveData_ws",
                start_ms: 1720193156000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1720193155751,
          },
          {
            leverage: 0.34731615343229305,
            order_type: "LONG",
            order_uuid: "4fd83ccb-e526-4197-b48a-757a4a6185ac",
            price: 1.27998,
            price_sources: [
              {
                close: 1.27998,
                high: 1.27998,
                lag_ms: 91,
                low: 1.27998,
                open: 1.27998,
                source: "Polygon_ws",
                start_ms: 1720197261000,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 1.27998,
                websocket: true,
              },
              {
                close: 1.28002,
                high: 1.28002,
                lag_ms: 1909,
                low: 1.28002,
                open: 1.28002,
                source: "TwelveData_ws",
                start_ms: 1720197263000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1720197261091,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "058fff04-5d01-4a65-9e43-81b70d061e82",
            price: 1.28156,
            price_sources: [
              {
                close: 1.28156,
                high: 1.28156,
                lag_ms: 427,
                low: 1.28156,
                open: 1.28156,
                source: "Polygon_ws",
                start_ms: 1720205623999,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 1.28156,
                websocket: true,
              },
              {
                close: 1.28155,
                high: 1.28155,
                lag_ms: 428,
                low: 1.28155,
                open: 1.28155,
                source: "TwelveData_ws",
                start_ms: 1720205624000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1720205623572,
          },
        ],
        position_type: "FLAT",
        position_uuid: "6a9cd77c-d109-478c-ab83-7c259006c571",
        return_at_close: 1.0006816979706725,
        trade_pair: ["GBPUSD", "GBP/USD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 1.2815200000000002,
        close_ms: 1720443444523,
        current_return: 1.0007825481890824,
        initial_entry_price: 1.28152,
        is_closed_position: true,
        miner_hotkey: "5EF393sRCV3Q6SFNTpKQed8m3QDGRgfDvke8sUoH3kbLqGZS",
        net_leverage: 0.0,
        open_ms: 1720205641425,
        orders: [
          {
            leverage: 0.447701408603968,
            order_type: "LONG",
            order_uuid: "064327da-7f4b-4684-bc4f-ac297b7e1e56",
            price: 1.28152,
            price_sources: [
              {
                close: 1.28152,
                high: 1.28152,
                lag_ms: 1,
                low: 1.28152,
                open: 1.28152,
                source: "Polygon_ws",
                start_ms: 1720205640999,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 1.28153,
                websocket: true,
              },
              {
                close: 1.28151,
                high: 1.28151,
                lag_ms: 2,
                low: 1.28151,
                open: 1.28151,
                source: "TwelveData_ws",
                start_ms: 1720205641000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1720205640998,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "2156a2bd-65aa-46d7-8c8d-935f6bdf87d5",
            price: 1.28376,
            price_sources: [
              {
                close: 1.28376,
                high: 1.28376,
                lag_ms: 476,
                low: 1.28376,
                open: 1.28376,
                source: "Polygon_ws",
                start_ms: 1720443444999,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 1.2838,
                websocket: true,
              },
              {
                close: 1.28372,
                high: 1.28372,
                lag_ms: 477,
                low: 1.28372,
                open: 1.28372,
                source: "TwelveData_ws",
                start_ms: 1720443445000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1720443444523,
          },
        ],
        position_type: "FLAT",
        position_uuid: "064327da-7f4b-4684-bc4f-ac297b7e1e56",
        return_at_close: 1.0007511845661252,
        trade_pair: ["GBPUSD", "GBP/USD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 1.2820768147095687,
        close_ms: 1720521097664,
        current_return: 1.0000964579260008,
        initial_entry_price: 1.28392,
        is_closed_position: true,
        miner_hotkey: "5EF393sRCV3Q6SFNTpKQed8m3QDGRgfDvke8sUoH3kbLqGZS",
        net_leverage: 0.0,
        open_ms: 1720443462293,
        orders: [
          {
            leverage: 0.4783427711783918,
            order_type: "LONG",
            order_uuid: "3cde7ad4-5281-4a24-b2a3-02ef3c5d9cad",
            price: 1.28392,
            price_sources: [
              {
                close: 1.28392,
                high: 1.28392,
                lag_ms: 166,
                low: 1.28392,
                open: 1.28392,
                source: "Polygon_ws",
                start_ms: 1720443462000,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 1.28392,
                websocket: true,
              },
              {
                close: 1.28391,
                high: 1.28391,
                lag_ms: 166,
                low: 1.28391,
                open: 1.28391,
                source: "TwelveData_ws",
                start_ms: 1720443462000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1720443462166,
          },
          {
            leverage: 0.38157468488611573,
            order_type: "LONG",
            order_uuid: "5b053310-dac2-4353-b2ac-fa3e97823685",
            price: 1.283155,
            price_sources: [
              {
                close: 1.283155,
                high: 1.283155,
                lag_ms: 455,
                low: 1.283155,
                open: 1.283155,
                source: "Polygon_ws",
                start_ms: 1720450574999,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 1.28316,
                websocket: true,
              },
              {
                close: 1.28312,
                high: 1.28312,
                lag_ms: 456,
                low: 1.28312,
                open: 1.28312,
                source: "TwelveData_ws",
                start_ms: 1720450575000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1720450574544,
          },
          {
            leverage: 0.39861913485589406,
            order_type: "LONG",
            order_uuid: "7a6bdd8c-7b7c-4c92-99f6-6c5f5e8e71a7",
            price: 1.28261,
            price_sources: [
              {
                close: 1.28261,
                high: 1.28261,
                lag_ms: 537,
                low: 1.28261,
                open: 1.28261,
                source: "TwelveData_ws",
                start_ms: 1720453667000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
              {
                close: 1.28262,
                high: 1.28262,
                lag_ms: 538,
                low: 1.28262,
                open: 1.28262,
                source: "Polygon_ws",
                start_ms: 1720453666999,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 1.28262,
                websocket: true,
              },
            ],
            processed_ms: 1720453667537,
          },
          {
            leverage: 0.36433025413158693,
            order_type: "LONG",
            order_uuid: "48163f9d-f052-469d-b0c2-d95e2a9480fb",
            price: 1.28201,
            price_sources: [
              {
                close: 1.28201,
                high: 1.28201,
                lag_ms: 326,
                low: 1.28201,
                open: 1.28201,
                source: "Polygon_ws",
                start_ms: 1720455399000,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 1.28201,
                websocket: true,
              },
              {
                close: 1.28202,
                high: 1.28202,
                lag_ms: 326,
                low: 1.28202,
                open: 1.28202,
                source: "TwelveData_ws",
                start_ms: 1720455399000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1720455399326,
          },
          {
            leverage: 0.3933384885391797,
            order_type: "LONG",
            order_uuid: "652c74dd-233f-4b34-bb04-ac2da6c61aa5",
            price: 1.28127,
            price_sources: [
              {
                close: 1.28127,
                high: 1.28127,
                lag_ms: 129,
                low: 1.28127,
                open: 1.28127,
                source: "Polygon_ws",
                start_ms: 1720462316999,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 1.28127,
                websocket: true,
              },
              {
                close: 1.28126,
                high: 1.28126,
                lag_ms: 130,
                low: 1.28126,
                open: 1.28126,
                source: "TwelveData_ws",
                start_ms: 1720462317000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1720462316870,
          },
          {
            leverage: 0.4240665474804084,
            order_type: "LONG",
            order_uuid: "04cf6d04-f034-449e-ba6e-601c42c03d8a",
            price: 1.28072,
            price_sources: [
              {
                close: 1.28072,
                high: 1.28072,
                lag_ms: 156,
                low: 1.28072,
                open: 1.28072,
                source: "Polygon_ws",
                start_ms: 1720471502000,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 1.28072,
                websocket: true,
              },
              {
                close: 1.28075,
                high: 1.28075,
                lag_ms: 2156,
                low: 1.28075,
                open: 1.28075,
                source: "TwelveData_ws",
                start_ms: 1720471500000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1720471502156,
          },
          {
            leverage: 0.4274698688190657,
            order_type: "LONG",
            order_uuid: "9158f35c-b8c2-4aea-a4e7-1e9730e0591c",
            price: 1.2807,
            price_sources: [
              {
                close: 1.2807,
                high: 1.2807,
                lag_ms: 147,
                low: 1.2807,
                open: 1.2807,
                source: "Polygon_ws",
                start_ms: 1720473857000,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 1.2807,
                websocket: true,
              },
              {
                close: 1.28063,
                high: 1.28063,
                lag_ms: 1147,
                low: 1.28063,
                open: 1.28063,
                source: "TwelveData_ws",
                start_ms: 1720473856000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1720473857147,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "c69aa045-c306-4ba4-b1dc-dde0fc7e6baf",
            price: 1.28212,
            price_sources: [
              {
                close: 1.28212,
                high: 1.28212,
                lag_ms: 335,
                low: 1.28212,
                open: 1.28212,
                source: "Polygon_ws",
                start_ms: 1720521097999,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 1.28212,
                websocket: true,
              },
              {
                close: 1.28212,
                high: 1.28212,
                lag_ms: 336,
                low: 1.28212,
                open: 1.28212,
                source: "TwelveData_ws",
                start_ms: 1720521098000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1720521097664,
          },
        ],
        position_type: "FLAT",
        position_uuid: "3cde7ad4-5281-4a24-b2a3-02ef3c5d9cad",
        return_at_close: 0.9998956966403589,
        trade_pair: ["GBPUSD", "GBP/USD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 1.2811350387800065,
        close_ms: 1720532545691,
        current_return: 1.0006705358422943,
        initial_entry_price: 1.28237,
        is_closed_position: true,
        miner_hotkey: "5EF393sRCV3Q6SFNTpKQed8m3QDGRgfDvke8sUoH3kbLqGZS",
        net_leverage: 0.0,
        open_ms: 1720521115894,
        orders: [
          {
            leverage: 0.370857209786889,
            order_type: "LONG",
            order_uuid: "9f8d5ce9-ecbb-489d-b49f-bbc9c101b8dd",
            price: 1.28237,
            price_sources: [
              {
                close: 1.28237,
                high: 1.28237,
                lag_ms: 885,
                low: 1.28237,
                open: 1.28237,
                source: "TwelveData_ws",
                start_ms: 1720521115000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
              {
                close: 1.2824,
                high: 1.2824,
                lag_ms: 886,
                low: 1.2824,
                open: 1.2824,
                source: "Polygon_ws",
                start_ms: 1720521114999,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 1.2824,
                websocket: true,
              },
            ],
            processed_ms: 1720521115885,
          },
          {
            leverage: 0.45212808350195144,
            order_type: "LONG",
            order_uuid: "a1ffba21-97aa-43ce-a4fe-fee291f7e746",
            price: 1.281755,
            price_sources: [
              {
                close: 1.281755,
                high: 1.281755,
                lag_ms: 91,
                low: 1.281755,
                open: 1.281755,
                source: "Polygon_ws",
                start_ms: 1720521816999,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 1.28176,
                websocket: true,
              },
              {
                close: 1.28171,
                high: 1.28171,
                lag_ms: 92,
                low: 1.28171,
                open: 1.28171,
                source: "TwelveData_ws",
                start_ms: 1720521817000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1720521816908,
          },
          {
            leverage: 0.4821418578002665,
            order_type: "LONG",
            order_uuid: "c9c55351-7640-4af2-a047-950dd71e3601",
            price: 1.28105,
            price_sources: [
              {
                close: 1.28105,
                high: 1.28105,
                lag_ms: 287,
                low: 1.28105,
                open: 1.28105,
                source: "Polygon_ws",
                start_ms: 1720523285999,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 1.28105,
                websocket: true,
              },
              {
                close: 1.28104,
                high: 1.28104,
                lag_ms: 288,
                low: 1.28104,
                open: 1.28104,
                source: "TwelveData_ws",
                start_ms: 1720523286000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1720523285712,
          },
          {
            leverage: 0.316841386074346,
            order_type: "LONG",
            order_uuid: "190cdfc4-ed3b-46ac-b646-8947f0af6bdb",
            price: 1.28037,
            price_sources: [
              {
                close: 1.28037,
                high: 1.28037,
                lag_ms: 239,
                low: 1.28037,
                open: 1.28037,
                source: "Polygon_ws",
                start_ms: 1720524157000,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 1.28037,
                websocket: true,
              },
              {
                close: 1.28037,
                high: 1.28037,
                lag_ms: 239,
                low: 1.28037,
                open: 1.28037,
                source: "TwelveData_ws",
                start_ms: 1720524157000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1720524157239,
          },
          {
            leverage: 0.3105033490280305,
            order_type: "LONG",
            order_uuid: "6d2d85d5-ff59-4d8e-9be1-de688450abbe",
            price: 1.27967,
            price_sources: [
              {
                close: 1.27967,
                high: 1.27967,
                lag_ms: 406,
                low: 1.27967,
                open: 1.27967,
                source: "Polygon_ws",
                start_ms: 1720525212999,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 1.27967,
                websocket: true,
              },
              {
                close: 1.27966,
                high: 1.27966,
                lag_ms: 407,
                low: 1.27966,
                open: 1.27966,
                source: "TwelveData_ws",
                start_ms: 1720525213000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1720525212593,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "9a29c06b-63ae-4037-837d-dcac33008eab",
            price: 1.28158,
            price_sources: [
              {
                close: 1.28158,
                high: 1.28158,
                lag_ms: 308,
                low: 1.28158,
                open: 1.28158,
                source: "Polygon_ws",
                start_ms: 1720532545999,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 1.28158,
                websocket: true,
              },
              {
                close: 1.28156,
                high: 1.28156,
                lag_ms: 309,
                low: 1.28156,
                open: 1.28156,
                source: "TwelveData_ws",
                start_ms: 1720532546000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1720532545691,
          },
        ],
        position_type: "FLAT",
        position_uuid: "9f8d5ce9-ecbb-489d-b49f-bbc9c101b8dd",
        return_at_close: 1.0005351721048443,
        trade_pair: ["GBPUSD", "GBP/USD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 1.2796035525743752,
        close_ms: 1720595214322,
        current_return: 1.0005176535197435,
        initial_entry_price: 1.281495,
        is_closed_position: true,
        miner_hotkey: "5EF393sRCV3Q6SFNTpKQed8m3QDGRgfDvke8sUoH3kbLqGZS",
        net_leverage: 0.0,
        open_ms: 1720532563579,
        orders: [
          {
            leverage: 0.40306794767433085,
            order_type: "LONG",
            order_uuid: "b72f3df0-4138-496b-a373-06db8b1d33b1",
            price: 1.281495,
            price_sources: [
              {
                close: 1.281495,
                high: 1.281495,
                lag_ms: 425,
                low: 1.281495,
                open: 1.281495,
                source: "Polygon_ws",
                start_ms: 1720532563999,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 1.28159,
                websocket: true,
              },
              {
                close: 1.28163,
                high: 1.28163,
                lag_ms: 426,
                low: 1.28163,
                open: 1.28163,
                source: "TwelveData_ws",
                start_ms: 1720532564000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1720532563574,
          },
          {
            leverage: 0.35120932585792036,
            order_type: "LONG",
            order_uuid: "925cec37-0797-4b64-82fd-147f2c5e3ce3",
            price: 1.2808,
            price_sources: [
              {
                close: 1.2808,
                high: 1.2808,
                lag_ms: 263,
                low: 1.2808,
                open: 1.2808,
                source: "Polygon_ws",
                start_ms: 1720533803999,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 1.2808,
                websocket: true,
              },
              {
                close: 1.28081,
                high: 1.28081,
                lag_ms: 264,
                low: 1.28081,
                open: 1.28081,
                source: "TwelveData_ws",
                start_ms: 1720533804000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1720533803736,
          },
          {
            leverage: 0.3732429674895118,
            order_type: "LONG",
            order_uuid: "b23038ae-2ec2-4658-ad28-7c0eb8d7da70",
            price: 1.27986,
            price_sources: [
              {
                close: 1.27986,
                high: 1.27986,
                lag_ms: 419,
                low: 1.27986,
                open: 1.27986,
                source: "Polygon_ws",
                start_ms: 1720533929999,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 1.27987,
                websocket: true,
              },
              {
                close: 1.2799,
                high: 1.2799,
                lag_ms: 420,
                low: 1.2799,
                open: 1.2799,
                source: "TwelveData_ws",
                start_ms: 1720533930000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1720533929580,
          },
          {
            leverage: 0.49753077938368445,
            order_type: "LONG",
            order_uuid: "38c6de75-f6e1-499d-82b0-0e2496e2fca8",
            price: 1.2792,
            price_sources: [
              {
                close: 1.2792,
                high: 1.2792,
                lag_ms: 401,
                low: 1.2792,
                open: 1.2792,
                source: "Polygon_ws",
                start_ms: 1720536237000,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 1.2792,
                websocket: true,
              },
              {
                close: 1.27921,
                high: 1.27921,
                lag_ms: 401,
                low: 1.27921,
                open: 1.27921,
                source: "TwelveData_ws",
                start_ms: 1720536237000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1720536237401,
          },
          {
            leverage: 0.3966164745666789,
            order_type: "LONG",
            order_uuid: "08a509aa-8e89-4581-9617-e7b7841f8f6b",
            price: 1.27872,
            price_sources: [
              {
                close: 1.27872,
                high: 1.27872,
                lag_ms: 426,
                low: 1.27872,
                open: 1.27872,
                source: "Polygon_ws",
                start_ms: 1720538539999,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 1.27873,
                websocket: true,
              },
              {
                close: 1.27869,
                high: 1.27869,
                lag_ms: 427,
                low: 1.27869,
                open: 1.27869,
                source: "TwelveData_ws",
                start_ms: 1720538540000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1720538539573,
          },
          {
            leverage: 0.4680183998293783,
            order_type: "LONG",
            order_uuid: "12ecba7d-063f-474a-8c4f-929328690b0b",
            price: 1.27805,
            price_sources: [
              {
                close: 1.27805,
                high: 1.27805,
                lag_ms: 455,
                low: 1.27805,
                open: 1.27805,
                source: "Polygon_ws",
                start_ms: 1720538986000,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 1.27805,
                websocket: true,
              },
              {
                close: 1.27802,
                high: 1.27802,
                lag_ms: 455,
                low: 1.27802,
                open: 1.27802,
                source: "TwelveData_ws",
                start_ms: 1720538986000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1720538986455,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "4d09f9a0-a2bd-4fb4-9cd1-333142dc3ec9",
            price: 1.2798699999999998,
            price_sources: [
              {
                close: 1.2798699999999998,
                high: 1.2798699999999998,
                lag_ms: 322,
                low: 1.2798699999999998,
                open: 1.2798699999999998,
                source: "Polygon_ws",
                start_ms: 1720595214000,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 1.27986,
                websocket: true,
              },
              {
                close: 1.27988,
                high: 1.27988,
                lag_ms: 322,
                low: 1.27988,
                open: 1.27988,
                source: "TwelveData_ws",
                start_ms: 1720595214000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1720595214322,
          },
        ],
        position_type: "FLAT",
        position_uuid: "b72f3df0-4138-496b-a373-06db8b1d33b1",
        return_at_close: 1.0003432852914806,
        trade_pair: ["GBPUSD", "GBP/USD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 58870.0,
        close_ms: 1720600804605,
        current_return: 1.000024915916426,
        initial_entry_price: 58870.0,
        is_closed_position: true,
        miner_hotkey: "5EF393sRCV3Q6SFNTpKQed8m3QDGRgfDvke8sUoH3kbLqGZS",
        net_leverage: 0.0,
        open_ms: 1720599346237,
        orders: [
          {
            leverage: -0.01,
            order_type: "SHORT",
            order_uuid: "16612616-e080-4777-b345-6fd7877bccf1",
            price: 58870.0,
            price_sources: [
              {
                close: 58870.0,
                high: 58871.0,
                lag_ms: 336,
                low: 58733.25,
                open: 58870.0,
                source: "Polygon_ws",
                start_ms: 1720599344999,
                timespan_ms: 0,
                volume: 0.63137836,
                vwap: 58774.5376,
                websocket: true,
              },
              {
                close: 58741.4,
                high: 58878.0,
                lag_ms: 2336,
                low: 58741.4,
                open: 58878.0,
                source: "Polygon_rest",
                start_ms: 1720599342000,
                timespan_ms: 1000,
                volume: 0.91572502,
                vwap: 58877.942,
                websocket: false,
              },
              {
                close: 58748.2,
                high: 58748.2,
                lag_ms: 3665,
                low: 58748.2,
                open: 58748.2,
                source: "TwelveData_ws",
                start_ms: 1720599349000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
              {
                close: 58699.31,
                high: 58725.05,
                lag_ms: 105336,
                low: 58678.06,
                open: 58682.99,
                source: "TwelveData_rest",
                start_ms: 1720599180000,
                timespan_ms: 60000,
                volume: null,
                vwap: null,
                websocket: false,
              },
            ],
            processed_ms: 1720599345335,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "800c1233-47b2-4afc-83c1-a7158617de37",
            price: 58723.32,
            price_sources: [
              {
                close: 58723.32,
                high: 58726.0,
                lag_ms: 394,
                low: 58714.0,
                open: 58723.32,
                source: "Polygon_ws",
                start_ms: 1720600804999,
                timespan_ms: 0,
                volume: 0.28759419,
                vwap: 58720.3155,
                websocket: true,
              },
              {
                close: 58738.9,
                high: 58738.9,
                lag_ms: 395,
                low: 58738.9,
                open: 58738.9,
                source: "TwelveData_ws",
                start_ms: 1720600805000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
              {
                close: 58711.05,
                high: 58711.05,
                lag_ms: 2606,
                low: 58711.05,
                open: 58711.05,
                source: "Polygon_rest",
                start_ms: 1720600801000,
                timespan_ms: 1000,
                volume: 0.00015186,
                vwap: 58711.05,
                websocket: false,
              },
              {
                close: 58764.74,
                high: 58776.34,
                lag_ms: 64606,
                low: 58711.48,
                open: 58722.16,
                source: "TwelveData_rest",
                start_ms: 1720600680000,
                timespan_ms: 60000,
                volume: null,
                vwap: null,
                websocket: false,
              },
            ],
            processed_ms: 1720600804605,
          },
        ],
        position_type: "FLAT",
        position_uuid: "bf6c9082-61cf-48cc-89f1-d760ac381395",
        return_at_close: 1.0000149156672669,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 1.2795489433794192,
        close_ms: 1720604203113,
        current_return: 1.0006690331039036,
        initial_entry_price: 1.27985,
        is_closed_position: true,
        miner_hotkey: "5EF393sRCV3Q6SFNTpKQed8m3QDGRgfDvke8sUoH3kbLqGZS",
        net_leverage: 0.0,
        open_ms: 1720595231580,
        orders: [
          {
            leverage: 0.2862303352126704,
            order_type: "LONG",
            order_uuid: "0d179b30-e4c9-4e0b-9f88-a60da5801b82",
            price: 1.27985,
            price_sources: [
              {
                close: 1.27985,
                high: 1.27985,
                lag_ms: 424,
                low: 1.27985,
                open: 1.27985,
                source: "Polygon_ws",
                start_ms: 1720595231999,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 1.27985,
                websocket: true,
              },
              {
                close: 1.27984,
                high: 1.27984,
                lag_ms: 425,
                low: 1.27984,
                open: 1.27984,
                source: "TwelveData_ws",
                start_ms: 1720595232000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1720595231575,
          },
          {
            leverage: 0.38479162746572926,
            order_type: "LONG",
            order_uuid: "98483e94-9e07-45d2-a717-c5c351dde013",
            price: 1.279325,
            price_sources: [
              {
                close: 1.279325,
                high: 1.279325,
                lag_ms: 367,
                low: 1.279325,
                open: 1.279325,
                source: "Polygon_ws",
                start_ms: 1720598185000,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 1.27932,
                websocket: true,
              },
              {
                close: 1.27932,
                high: 1.27932,
                lag_ms: 367,
                low: 1.27932,
                open: 1.27932,
                source: "TwelveData_ws",
                start_ms: 1720598185000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1720598185367,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "78e65385-dab5-4817-b972-3e92345b495a",
            price: 1.280825,
            price_sources: [
              {
                close: 1.280825,
                high: 1.280825,
                lag_ms: 113,
                low: 1.280825,
                open: 1.280825,
                source: "Polygon_ws",
                start_ms: 1720604203000,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 1.2807,
                websocket: true,
              },
              {
                close: 1.28093,
                high: 1.28093,
                lag_ms: 113,
                low: 1.28093,
                open: 1.28093,
                source: "TwelveData_ws",
                start_ms: 1720604203000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1720604203113,
          },
        ],
        position_type: "FLAT",
        position_uuid: "0d179b30-e4c9-4e0b-9f88-a60da5801b82",
        return_at_close: 1.0006220301410027,
        trade_pair: ["GBPUSD", "GBP/USD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 1.2805734539827487,
        close_ms: 1720618285348,
        current_return: 1.0007265854013323,
        initial_entry_price: 1.280945,
        is_closed_position: true,
        miner_hotkey: "5EF393sRCV3Q6SFNTpKQed8m3QDGRgfDvke8sUoH3kbLqGZS",
        net_leverage: 0.0,
        open_ms: 1720604223048,
        orders: [
          {
            leverage: 0.4531712169511949,
            order_type: "LONG",
            order_uuid: "ea1a3852-448d-416a-9280-db8a41c807db",
            price: 1.280945,
            price_sources: [
              {
                close: 1.280945,
                high: 1.280945,
                lag_ms: 33,
                low: 1.280945,
                open: 1.280945,
                source: "Polygon_ws",
                start_ms: 1720604223000,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 1.28094,
                websocket: true,
              },
              {
                close: 1.28095,
                high: 1.28095,
                lag_ms: 33,
                low: 1.28095,
                open: 1.28095,
                source: "TwelveData_ws",
                start_ms: 1720604223000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1720604223033,
          },
          {
            leverage: 0.49023732216872706,
            order_type: "LONG",
            order_uuid: "33d93313-b6e2-45e1-a53e-c5a68192d2da",
            price: 1.28023,
            price_sources: [
              {
                close: 1.28023,
                high: 1.28023,
                lag_ms: 153,
                low: 1.28023,
                open: 1.28023,
                source: "Polygon_ws",
                start_ms: 1720605967999,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 1.28024,
                websocket: true,
              },
              {
                close: 1.2802,
                high: 1.2802,
                lag_ms: 154,
                low: 1.2802,
                open: 1.2802,
                source: "TwelveData_ws",
                start_ms: 1720605968000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1720605967846,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "fca97c26-de24-41c2-a75a-5a25fc0de37f",
            price: 1.28156,
            price_sources: [
              {
                close: 1.28156,
                high: 1.28156,
                lag_ms: 348,
                low: 1.28156,
                open: 1.28156,
                source: "Polygon_ws",
                start_ms: 1720618285000,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 1.28156,
                websocket: true,
              },
              {
                close: 1.28153,
                high: 1.28153,
                lag_ms: 1348,
                low: 1.28153,
                open: 1.28153,
                source: "TwelveData_ws",
                start_ms: 1720618284000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1720618285348,
          },
        ],
        position_type: "FLAT",
        position_uuid: "ea1a3852-448d-416a-9280-db8a41c807db",
        return_at_close: 1.000660498820913,
        trade_pair: ["GBPUSD", "GBP/USD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 1.28159,
        close_ms: 1720618613304,
        current_return: 1.0005770373605354,
        initial_entry_price: 1.28159,
        is_closed_position: true,
        miner_hotkey: "5EF393sRCV3Q6SFNTpKQed8m3QDGRgfDvke8sUoH3kbLqGZS",
        net_leverage: 0.0,
        open_ms: 1720618302586,
        orders: [
          {
            leverage: 0.4963257120058274,
            order_type: "LONG",
            order_uuid: "4aab7033-d347-4ec3-b7a3-b56996ee3614",
            price: 1.28159,
            price_sources: [
              {
                close: 1.28159,
                high: 1.28159,
                lag_ms: 418,
                low: 1.28159,
                open: 1.28159,
                source: "Polygon_ws",
                start_ms: 1720618302999,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 1.28154,
                websocket: true,
              },
              {
                close: 1.28162,
                high: 1.28162,
                lag_ms: 419,
                low: 1.28162,
                open: 1.28162,
                source: "TwelveData_ws",
                start_ms: 1720618303000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1720618302581,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "ea64ec61-98c1-4a13-8e6b-82d5e9eb8a86",
            price: 1.28308,
            price_sources: [
              {
                close: 1.28308,
                high: 1.28308,
                lag_ms: 304,
                low: 1.28308,
                open: 1.28308,
                source: "Polygon_ws",
                start_ms: 1720618613000,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 1.28308,
                websocket: true,
              },
              {
                close: 1.28307,
                high: 1.28307,
                lag_ms: 304,
                low: 1.28307,
                open: 1.28307,
                source: "TwelveData_ws",
                start_ms: 1720618613000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1720618613304,
          },
        ],
        position_type: "FLAT",
        position_uuid: "4aab7033-d347-4ec3-b7a3-b56996ee3614",
        return_at_close: 1.0005422745128014,
        trade_pair: ["GBPUSD", "GBP/USD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 1.2829016016055503,
        close_ms: 1720622010352,
        current_return: 1.0008116725919314,
        initial_entry_price: 1.28328,
        is_closed_position: true,
        miner_hotkey: "5EF393sRCV3Q6SFNTpKQed8m3QDGRgfDvke8sUoH3kbLqGZS",
        net_leverage: 0.0,
        open_ms: 1720618630629,
        orders: [
          {
            leverage: 0.316264540398235,
            order_type: "LONG",
            order_uuid: "cdb83284-efd8-4665-8d4e-9e11e61543b8",
            price: 1.28328,
            price_sources: [
              {
                close: 1.28328,
                high: 1.28328,
                lag_ms: 414,
                low: 1.28328,
                open: 1.28328,
                source: "Polygon_ws",
                start_ms: 1720618630999,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 1.28328,
                websocket: true,
              },
              {
                close: 1.28329,
                high: 1.28329,
                lag_ms: 415,
                low: 1.28329,
                open: 1.28329,
                source: "TwelveData_ws",
                start_ms: 1720618631000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1720618630585,
          },
          {
            leverage: 0.4104023847266558,
            order_type: "LONG",
            order_uuid: "00593329-8e96-4043-8270-d1eb3badfbf5",
            price: 1.28261,
            price_sources: [
              {
                close: 1.28261,
                high: 1.28261,
                lag_ms: 273,
                low: 1.28261,
                open: 1.28261,
                source: "Polygon_ws",
                start_ms: 1720619009000,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 1.28262,
                websocket: true,
              },
              {
                close: 1.28265,
                high: 1.28265,
                lag_ms: 727,
                low: 1.28265,
                open: 1.28265,
                source: "TwelveData_ws",
                start_ms: 1720619010000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1720619009273,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "5a3d8434-fe0e-4e55-86f1-afdb03f300fb",
            price: 1.284335,
            price_sources: [
              {
                close: 1.284335,
                high: 1.284335,
                lag_ms: 352,
                low: 1.284335,
                open: 1.284335,
                source: "Polygon_ws",
                start_ms: 1720622010000,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 1.28434,
                websocket: true,
              },
              {
                close: 1.28432,
                high: 1.28432,
                lag_ms: 352,
                low: 1.28432,
                open: 1.28432,
                source: "TwelveData_ws",
                start_ms: 1720622010000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1720622010352,
          },
        ],
        position_type: "FLAT",
        position_uuid: "cdb83284-efd8-4665-8d4e-9e11e61543b8",
        return_at_close: 1.0007607646200787,
        trade_pair: ["GBPUSD", "GBP/USD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 1.2835630710863537,
        close_ms: 1720625219623,
        current_return: 1.0007411503968964,
        initial_entry_price: 1.2842,
        is_closed_position: true,
        miner_hotkey: "5EF393sRCV3Q6SFNTpKQed8m3QDGRgfDvke8sUoH3kbLqGZS",
        net_leverage: 0.0,
        open_ms: 1720622028477,
        orders: [
          {
            leverage: 0.37751272681225345,
            order_type: "LONG",
            order_uuid: "0394d57b-7e70-45a6-8e87-878e5f00311b",
            price: 1.2842,
            price_sources: [
              {
                close: 1.2842,
                high: 1.2842,
                lag_ms: 407,
                low: 1.2842,
                open: 1.2842,
                source: "Polygon_ws",
                start_ms: 1720622027999,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 1.2842,
                websocket: true,
              },
              {
                close: 1.28423,
                high: 1.28423,
                lag_ms: 408,
                low: 1.28423,
                open: 1.28423,
                source: "TwelveData_ws",
                start_ms: 1720622028000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1720622027592,
          },
          {
            leverage: 0.41984641940251743,
            order_type: "LONG",
            order_uuid: "a08c0e9e-5bd1-4fbb-95d4-c31c5880a376",
            price: 1.28362,
            price_sources: [
              {
                close: 1.28362,
                high: 1.28362,
                lag_ms: 58,
                low: 1.28362,
                open: 1.28362,
                source: "Polygon_ws",
                start_ms: 1720622298000,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 1.28362,
                websocket: true,
              },
              {
                close: 1.28359,
                high: 1.28359,
                lag_ms: 58,
                low: 1.28359,
                open: 1.28359,
                source: "TwelveData_ws",
                start_ms: 1720622298000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1720622298058,
          },
          {
            leverage: 0.4277018895848157,
            order_type: "LONG",
            order_uuid: "03352e3a-440f-4a9e-bc43-251f79c50d48",
            price: 1.282945,
            price_sources: [
              {
                close: 1.282945,
                high: 1.282945,
                lag_ms: 279,
                low: 1.282945,
                open: 1.282945,
                source: "Polygon_ws",
                start_ms: 1720623145000,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 1.28293,
                websocket: true,
              },
              {
                close: 1.28295,
                high: 1.28295,
                lag_ms: 279,
                low: 1.28295,
                open: 1.28295,
                source: "TwelveData_ws",
                start_ms: 1720623145000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1720623145279,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "7db909fa-b837-47e7-a4ac-087e7723a336",
            price: 1.28434,
            price_sources: [
              {
                close: 1.28434,
                high: 1.28434,
                lag_ms: 376,
                low: 1.28434,
                open: 1.28434,
                source: "Polygon_ws",
                start_ms: 1720625219999,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 1.28434,
                websocket: true,
              },
              {
                close: 1.28434,
                high: 1.28434,
                lag_ms: 377,
                low: 1.28434,
                open: 1.28434,
                source: "TwelveData_ws",
                start_ms: 1720625220000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1720625219623,
          },
        ],
        position_type: "FLAT",
        position_uuid: "0394d57b-7e70-45a6-8e87-878e5f00311b",
        return_at_close: 1.0006553325675773,
        trade_pair: ["GBPUSD", "GBP/USD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 3434.62,
        close_ms: 0,
        current_return: 0.9739997437853387,
        initial_entry_price: 3434.62,
        is_closed_position: false,
        miner_hotkey: "5EF393sRCV3Q6SFNTpKQed8m3QDGRgfDvke8sUoH3kbLqGZS",
        net_leverage: 0.3,
        open_ms: 1719795430505,
        orders: [
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "13ecd8ca-2e0c-458f-ac9c-5141fbd6bd17",
            price: 3434.62,
            price_sources: [],
            processed_ms: 1719795430176,
          },
        ],
        position_type: "LONG",
        position_uuid: "2e4fde59-bff8-49c3-a14f-1ae455782a7e",
        return_at_close: 0.9738536438237709,
        trade_pair: ["ETHUSD", "ETH/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 1.28432,
        close_ms: 0,
        current_return: 0.9999396053745139,
        initial_entry_price: 1.28432,
        is_closed_position: false,
        miner_hotkey: "5EF393sRCV3Q6SFNTpKQed8m3QDGRgfDvke8sUoH3kbLqGZS",
        net_leverage: 0.27702151930117147,
        open_ms: 1720625239434,
        orders: [
          {
            leverage: 0.27702151930117147,
            order_type: "LONG",
            order_uuid: "a34117b5-ddae-480d-a848-afaf7a447d1b",
            price: 1.28432,
            price_sources: [
              {
                close: 1.28432,
                high: 1.28432,
                lag_ms: 289,
                low: 1.28432,
                open: 1.28432,
                source: "Polygon_ws",
                start_ms: 1720625237999,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 1.28433,
                websocket: true,
              },
              {
                close: 1.2843,
                high: 1.2843,
                lag_ms: 290,
                low: 1.2843,
                open: 1.2843,
                source: "TwelveData_ws",
                start_ms: 1720625238000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1720625237710,
          },
        ],
        position_type: "LONG",
        position_uuid: "a34117b5-ddae-480d-a848-afaf7a447d1b",
        return_at_close: 0.9999299102069097,
        trade_pair: ["GBPUSD", "GBP/USD", 7e-5, 0.001, 500],
      },
    ],
    thirty_day_returns: 1.0361220724758913,
  },
  "5EFD5p3NNTw4qGALArY3YJwkVFY5qodjUmx1pMkKgzfpQyx9": {
    all_time_returns: 1.0,
    n_positions: 0,
    percentage_profitable: 0.0,
    positions: [
      {
        average_entry_price: 63664.30722222224,
        close_ms: 1711177256867,
        current_return: 1.0217226235148995,
        initial_entry_price: 62960.42,
        is_closed_position: true,
        miner_hotkey: "5EFD5p3NNTw4qGALArY3YJwkVFY5qodjUmx1pMkKgzfpQyx9",
        net_leverage: 0.0,
        open_ms: 1711144913906,
        orders: [
          {
            leverage: 0.15,
            order_type: "LONG",
            order_uuid: "af0664de-9e63-47aa-aa0e-78e04f481574",
            price: 62960.42,
            price_sources: [],
            processed_ms: 1711144913906,
          },
          {
            leverage: 0.15,
            order_type: "LONG",
            order_uuid: "582cf5bf-3659-43eb-b0d8-4f2594b3daab",
            price: 62992.15,
            price_sources: [],
            processed_ms: 1711146719658,
          },
          {
            leverage: 0.15,
            order_type: "LONG",
            order_uuid: "1c593128-5c7f-42d8-992c-6592078b26a2",
            price: 63030.02,
            price_sources: [],
            processed_ms: 1711148518473,
          },
          {
            leverage: 0.15,
            order_type: "LONG",
            order_uuid: "f4cea09a-21a3-47e1-a1e6-68afc0c1770c",
            price: 63383.41,
            price_sources: [],
            processed_ms: 1711150318836,
          },
          {
            leverage: 0.15,
            order_type: "LONG",
            order_uuid: "05be78a6-b818-4403-bcf6-d2058a20a96c",
            price: 63712.44,
            price_sources: [],
            processed_ms: 1711152125822,
          },
          {
            leverage: 0.15,
            order_type: "LONG",
            order_uuid: "9486a8ca-dbdc-4f85-865f-ddd917f1b457",
            price: 63624.2,
            price_sources: [],
            processed_ms: 1711153922364,
          },
          {
            leverage: 0.15,
            order_type: "LONG",
            order_uuid: "546741c1-74a7-40da-b404-88c02660eee4",
            price: 63591.99,
            price_sources: [],
            processed_ms: 1711155721779,
          },
          {
            leverage: 0.15,
            order_type: "LONG",
            order_uuid: "ded37342-3a7d-4f83-b315-350ed11b2cd9",
            price: 63903.1,
            price_sources: [],
            processed_ms: 1711157532164,
          },
          {
            leverage: 0.15,
            order_type: "LONG",
            order_uuid: "35d33fd2-bb5a-4d34-8270-b69b09a13620",
            price: 64066.93,
            price_sources: [],
            processed_ms: 1711159338537,
          },
          {
            leverage: 0.15,
            order_type: "LONG",
            order_uuid: "ecaa8f4e-8045-472b-8522-15a643d7979e",
            price: 63855.91,
            price_sources: [],
            processed_ms: 1711161156751,
          },
          {
            leverage: 0.15,
            order_type: "LONG",
            order_uuid: "73f53fb0-fc17-45ec-a9a9-241c7b86c8f2",
            price: 63811.64,
            price_sources: [],
            processed_ms: 1711162926286,
          },
          {
            leverage: 0.15,
            order_type: "LONG",
            order_uuid: "0ac5b803-f23b-4c91-a38c-e5c4b1ce8d65",
            price: 63353.95,
            price_sources: [],
            processed_ms: 1711164734905,
          },
          {
            leverage: 0.15,
            order_type: "LONG",
            order_uuid: "a3456456-2559-4db0-b123-184eb045a0d1",
            price: 63553.03,
            price_sources: [],
            processed_ms: 1711166532345,
          },
          {
            leverage: 0.15,
            order_type: "LONG",
            order_uuid: "2199065c-4b16-4c1a-8e17-bfa540e076de",
            price: 63901.96,
            price_sources: [],
            processed_ms: 1711168328691,
          },
          {
            leverage: 0.15,
            order_type: "LONG",
            order_uuid: "b3061fab-ab8f-4e2e-8a66-ff0df907a9c8",
            price: 63885.65,
            price_sources: [],
            processed_ms: 1711170143562,
          },
          {
            leverage: 0.15,
            order_type: "LONG",
            order_uuid: "025df0ca-61c2-4ad3-9c0c-c700b75b5723",
            price: 64018.73,
            price_sources: [],
            processed_ms: 1711171941449,
          },
          {
            leverage: 0.15,
            order_type: "LONG",
            order_uuid: "51432fd8-c33d-42e8-94a3-9baa5e640556",
            price: 64113.09,
            price_sources: [],
            processed_ms: 1711173722764,
          },
          {
            leverage: 0.15,
            order_type: "LONG",
            order_uuid: "f7b447df-c874-463d-8a6a-611d068e797f",
            price: 64198.91,
            price_sources: [],
            processed_ms: 1711175517767,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "cc3ccf9f-6bcb-46c3-a9fe-22807c6ca576",
            price: 64170.85,
            price_sources: [],
            processed_ms: 1711177256867,
          },
        ],
        position_type: "FLAT",
        position_uuid: "68c5d272-0bc7-472d-94bc-660a6f7584a8",
        return_at_close: 1.0162053213479192,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 64510.67399999999,
        close_ms: 1711181316648,
        current_return: 1.002437035736918,
        initial_entry_price: 64210.63,
        is_closed_position: true,
        miner_hotkey: "5EFD5p3NNTw4qGALArY3YJwkVFY5qodjUmx1pMkKgzfpQyx9",
        net_leverage: 0.0,
        open_ms: 1711177379656,
        orders: [
          {
            leverage: 0.12,
            order_type: "LONG",
            order_uuid: "2db79646-3a8a-41ea-9c51-4d69364c6959",
            price: 64210.63,
            price_sources: [],
            processed_ms: 1711177379655,
          },
          {
            leverage: 0.12,
            order_type: "LONG",
            order_uuid: "866bb6a3-2f57-4760-a51a-3c2d4806b93d",
            price: 64565.59,
            price_sources: [],
            processed_ms: 1711178119403,
          },
          {
            leverage: 0.12,
            order_type: "LONG",
            order_uuid: "00b3e9d8-fade-485d-ad59-2198ac81c7ad",
            price: 64605.05,
            price_sources: [],
            processed_ms: 1711178927426,
          },
          {
            leverage: 0.12,
            order_type: "LONG",
            order_uuid: "20b85881-9080-4dd7-9bd4-9f95d868045b",
            price: 64662.1,
            price_sources: [],
            processed_ms: 1711179723635,
          },
          {
            leverage: 0.12,
            order_type: "LONG",
            order_uuid: "7649cbf4-c701-4bca-aa65-5e52db57df96",
            price: 64510.0,
            price_sources: [],
            processed_ms: 1711180532580,
          },
          {
            leverage: 0.12,
            order_type: "FLAT",
            order_uuid: "57af7c7c-4efe-4450-970b-cd290e57f028",
            price: 64771.48,
            price_sources: [],
            processed_ms: 1711181316648,
          },
        ],
        position_type: "FLAT",
        position_uuid: "ebaefbda-6a50-4454-8ba4-8f64fdd7ba71",
        return_at_close: 1.0012341112940335,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 64425.652,
        close_ms: 1711186115194,
        current_return: 0.9990609940620945,
        initial_entry_price: 64563.17,
        is_closed_position: true,
        miner_hotkey: "5EFD5p3NNTw4qGALArY3YJwkVFY5qodjUmx1pMkKgzfpQyx9",
        net_leverage: 0.0,
        open_ms: 1711182114628,
        orders: [
          {
            leverage: 0.12,
            order_type: "LONG",
            order_uuid: "12fac611-c0a1-401b-a66f-d92b8155749d",
            price: 64563.17,
            price_sources: [],
            processed_ms: 1711182114627,
          },
          {
            leverage: 0.12,
            order_type: "LONG",
            order_uuid: "ea98272c-6046-4279-bb76-0b2e6618dbb3",
            price: 64325.09,
            price_sources: [],
            processed_ms: 1711182929469,
          },
          {
            leverage: 0.12,
            order_type: "LONG",
            order_uuid: "ec04100d-1285-4b49-9db8-984f57547759",
            price: 64444.41,
            price_sources: [],
            processed_ms: 1711183712953,
          },
          {
            leverage: 0.12,
            order_type: "LONG",
            order_uuid: "930dae6f-8ff2-4321-83fd-ecc7a94c9c99",
            price: 64538.5,
            price_sources: [],
            processed_ms: 1711184510021,
          },
          {
            leverage: 0.12,
            order_type: "LONG",
            order_uuid: "17370b58-9442-46ba-ac50-29cce91da957",
            price: 64257.09,
            price_sources: [],
            processed_ms: 1711185319588,
          },
          {
            leverage: 0.12,
            order_type: "FLAT",
            order_uuid: "b19e5f93-72d1-4fda-b201-d1809da28819",
            price: 64324.61,
            price_sources: [],
            processed_ms: 1711186115194,
          },
        ],
        position_type: "FLAT",
        position_uuid: "010ecb6c-49aa-4d65-ad31-866ea7ef67ce",
        return_at_close: 0.99786212086922,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 64517.34,
        close_ms: 1711190921610,
        current_return: 1.0001634135439346,
        initial_entry_price: 64364.31,
        is_closed_position: true,
        miner_hotkey: "5EFD5p3NNTw4qGALArY3YJwkVFY5qodjUmx1pMkKgzfpQyx9",
        net_leverage: 0.0,
        open_ms: 1711186915573,
        orders: [
          {
            leverage: 0.12,
            order_type: "LONG",
            order_uuid: "4f218af3-0e6e-42e8-8e7e-da85a4b5b044",
            price: 64364.31,
            price_sources: [],
            processed_ms: 1711186915572,
          },
          {
            leverage: 0.12,
            order_type: "LONG",
            order_uuid: "fb73cd47-c4fc-4731-aec4-fe4ddba109ee",
            price: 64413.84,
            price_sources: [],
            processed_ms: 1711187712202,
          },
          {
            leverage: 0.12,
            order_type: "LONG",
            order_uuid: "50da81f3-84c6-4df1-8224-fb108654cc91",
            price: 64571.05,
            price_sources: [],
            processed_ms: 1711188525232,
          },
          {
            leverage: 0.12,
            order_type: "LONG",
            order_uuid: "844eda02-7099-4e97-80bf-9ea9aa0bcb63",
            price: 64585.66,
            price_sources: [],
            processed_ms: 1711189343320,
          },
          {
            leverage: 0.12,
            order_type: "LONG",
            order_uuid: "02788ba7-f42e-497b-8189-27f612ebbdaa",
            price: 64651.84,
            price_sources: [],
            processed_ms: 1711190124065,
          },
          {
            leverage: 0.12,
            order_type: "FLAT",
            order_uuid: "ea9a95ac-8072-4d48-ada6-201c31658aa9",
            price: 64534.87,
            price_sources: [],
            processed_ms: 1711190921610,
          },
        ],
        position_type: "FLAT",
        position_uuid: "af958d9e-082e-44ce-8f9b-b0a20593dd75",
        return_at_close: 0.9989632174476819,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 64459.115999999995,
        close_ms: 1711195737351,
        current_return: 1.002086646817874,
        initial_entry_price: 64353.2,
        is_closed_position: true,
        miner_hotkey: "5EFD5p3NNTw4qGALArY3YJwkVFY5qodjUmx1pMkKgzfpQyx9",
        net_leverage: 0.0,
        open_ms: 1711191734812,
        orders: [
          {
            leverage: 0.12,
            order_type: "LONG",
            order_uuid: "e24d3ade-36f1-4b12-a66e-613556b45a5d",
            price: 64353.2,
            price_sources: [],
            processed_ms: 1711191734811,
          },
          {
            leverage: 0.12,
            order_type: "LONG",
            order_uuid: "702af38f-4f88-499c-a16a-3fb558e42081",
            price: 64415.49,
            price_sources: [],
            processed_ms: 1711192522171,
          },
          {
            leverage: 0.12,
            order_type: "LONG",
            order_uuid: "e8ce3ad5-e60b-47c4-b604-b330908c25b3",
            price: 64453.56,
            price_sources: [],
            processed_ms: 1711193336306,
          },
          {
            leverage: 0.12,
            order_type: "LONG",
            order_uuid: "7b0c8fce-9854-4faf-8e35-d4ec21890b4a",
            price: 64536.1,
            price_sources: [],
            processed_ms: 1711194149811,
          },
          {
            leverage: 0.12,
            order_type: "LONG",
            order_uuid: "02ecdd8f-ac47-4fe9-af9a-94b63599e464",
            price: 64537.23,
            price_sources: [],
            processed_ms: 1711194922629,
          },
          {
            leverage: 0.12,
            order_type: "FLAT",
            order_uuid: "e0d62687-d6de-4223-b19c-1259033e4a93",
            price: 64682.92,
            price_sources: [],
            processed_ms: 1711195737351,
          },
        ],
        position_type: "FLAT",
        position_uuid: "d89104a5-3bec-43f8-b88b-25460b3106ac",
        return_at_close: 1.0008841428416926,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 64691.399999999994,
        close_ms: 1711200519883,
        current_return: 0.9983894884734674,
        initial_entry_price: 64719.81,
        is_closed_position: true,
        miner_hotkey: "5EFD5p3NNTw4qGALArY3YJwkVFY5qodjUmx1pMkKgzfpQyx9",
        net_leverage: 0.0,
        open_ms: 1711196556445,
        orders: [
          {
            leverage: 0.12,
            order_type: "LONG",
            order_uuid: "1e02ef05-c204-4299-b91f-e18565103dcb",
            price: 64719.81,
            price_sources: [],
            processed_ms: 1711196556444,
          },
          {
            leverage: 0.12,
            order_type: "LONG",
            order_uuid: "cb3964c5-ad85-4b16-bb59-204727f24d87",
            price: 64649.71,
            price_sources: [],
            processed_ms: 1711197317583,
          },
          {
            leverage: 0.12,
            order_type: "LONG",
            order_uuid: "f6a5ab0f-cc7f-4b21-883a-bf21f6918025",
            price: 64818.38,
            price_sources: [],
            processed_ms: 1711198132526,
          },
          {
            leverage: 0.12,
            order_type: "LONG",
            order_uuid: "d45094ae-288a-4f04-97d9-75794e8eb553",
            price: 64644.85,
            price_sources: [],
            processed_ms: 1711198940587,
          },
          {
            leverage: 0.12,
            order_type: "LONG",
            order_uuid: "20943aad-7039-4d55-b619-124539bfe4be",
            price: 64624.25,
            price_sources: [],
            processed_ms: 1711199720757,
          },
          {
            leverage: 0.12,
            order_type: "FLAT",
            order_uuid: "5a6439ec-a09e-4c4f-8032-0ad1024e7ee2",
            price: 64517.68,
            price_sources: [],
            processed_ms: 1711200519883,
          },
        ],
        position_type: "FLAT",
        position_uuid: "65d935e2-a0cc-4cec-af32-602b96ab8719",
        return_at_close: 0.9971914210872993,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 64599.85,
        close_ms: 1711204368359,
        current_return: 1.0046277514632351,
        initial_entry_price: 64497.5,
        is_closed_position: true,
        miner_hotkey: "5EFD5p3NNTw4qGALArY3YJwkVFY5qodjUmx1pMkKgzfpQyx9",
        net_leverage: 0.0,
        open_ms: 1711201316618,
        orders: [
          {
            leverage: 0.12,
            order_type: "LONG",
            order_uuid: "ca57818b-0d30-41fa-968e-413035ca94b0",
            price: 64497.5,
            price_sources: [],
            processed_ms: 1711201316617,
          },
          {
            leverage: 0.12,
            order_type: "LONG",
            order_uuid: "23e2fa2d-b22e-424e-85cb-9db3f666c825",
            price: 64658.39,
            price_sources: [],
            processed_ms: 1711202137546,
          },
          {
            leverage: 0.12,
            order_type: "LONG",
            order_uuid: "99cff922-c051-41ce-9d40-09313ba08777",
            price: 64618.15,
            price_sources: [],
            processed_ms: 1711202927389,
          },
          {
            leverage: 0.12,
            order_type: "LONG",
            order_uuid: "c86c8ca8-80b3-4088-9d9e-546e682e77f9",
            price: 64625.36,
            price_sources: [],
            processed_ms: 1711203722455,
          },
          {
            leverage: -5.0,
            order_type: "SHORT",
            order_uuid: "e16cab12-ff93-49f7-88f2-dd995df5eb75",
            price: 65221.68,
            price_sources: [],
            processed_ms: 1711204368359,
          },
        ],
        position_type: "FLAT",
        position_uuid: "2de6f60d-0436-4583-8183-d82e4e797343",
        return_at_close: 1.0036633088218305,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 65331.41,
        close_ms: 1711204698160,
        current_return: 0.9994712502911238,
        initial_entry_price: 65307.27,
        is_closed_position: true,
        miner_hotkey: "5EFD5p3NNTw4qGALArY3YJwkVFY5qodjUmx1pMkKgzfpQyx9",
        net_leverage: 0.0,
        open_ms: 1711204529273,
        orders: [
          {
            leverage: 0.12,
            order_type: "LONG",
            order_uuid: "1e91b144-baa5-4062-a907-e1d08104169d",
            price: 65307.27,
            price_sources: [],
            processed_ms: 1711204529271,
          },
          {
            leverage: 0.12,
            order_type: "LONG",
            order_uuid: "f49c1dea-1609-4891-9a32-9f352ffcb811",
            price: 65355.55,
            price_sources: [],
            processed_ms: 1711204595884,
          },
          {
            leverage: -5.0,
            order_type: "SHORT",
            order_uuid: "06ad1429-006e-4d36-b541-762d2ba97e7b",
            price: 65187.53,
            price_sources: [],
            processed_ms: 1711204698160,
          },
        ],
        position_type: "FLAT",
        position_uuid: "2a33a20f-c2fb-4c86-b805-17db91d85fa2",
        return_at_close: 0.998991504090984,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 65146.82,
        close_ms: 1711210604249,
        current_return: 1.0,
        initial_entry_price: 65146.82,
        is_closed_position: true,
        miner_hotkey: "5EFD5p3NNTw4qGALArY3YJwkVFY5qodjUmx1pMkKgzfpQyx9",
        net_leverage: 0.0,
        open_ms: 1711210572530,
        orders: [
          {
            leverage: 0.12,
            order_type: "LONG",
            order_uuid: "e92a4be8-3cd3-4c05-ae95-3dc262914737",
            price: 65146.82,
            price_sources: [],
            processed_ms: 1711210571812,
          },
          {
            leverage: 0.12,
            order_type: "LONG",
            order_uuid: "c0b6c0eb-22b2-4aeb-b179-42f6f6a0deb3",
            price: 65146.82,
            price_sources: [],
            processed_ms: 1711210574420,
          },
          {
            leverage: 5.0,
            order_type: "FLAT",
            order_uuid: "c9ef5c85-345c-4b76-83c1-69ec8b21f580",
            price: 65146.82,
            price_sources: [],
            processed_ms: 1711210604249,
          },
        ],
        position_type: "FLAT",
        position_uuid: "4d5809cd-6362-42e7-8d42-4237a3a4ea44",
        return_at_close: 0.99952,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 65420.36,
        close_ms: 1711214942136,
        current_return: 1.0028856665605475,
        initial_entry_price: 65146.82,
        is_closed_position: true,
        miner_hotkey: "5EFD5p3NNTw4qGALArY3YJwkVFY5qodjUmx1pMkKgzfpQyx9",
        net_leverage: 0.0,
        open_ms: 1711210615032,
        orders: [
          {
            leverage: 0.12,
            order_type: "LONG",
            order_uuid: "ac65470f-1d4a-4963-9493-fa788ccef25d",
            price: 65146.82,
            price_sources: [],
            processed_ms: 1711210615030,
          },
          {
            leverage: 0.12,
            order_type: "LONG",
            order_uuid: "fa348f3b-f180-4c7b-a5fd-09eeefc5c1bc",
            price: 65146.82,
            price_sources: [],
            processed_ms: 1711210623604,
          },
          {
            leverage: 0.12,
            order_type: "LONG",
            order_uuid: "2c29352e-9aaf-4f17-a7ae-7ec39ba7661f",
            price: 65155.67,
            price_sources: [],
            processed_ms: 1711210925859,
          },
          {
            leverage: 0.12,
            order_type: "LONG",
            order_uuid: "26b37573-3ff4-4926-967c-f892ce9befe5",
            price: 65490.62,
            price_sources: [],
            processed_ms: 1711211730250,
          },
          {
            leverage: 0.12,
            order_type: "LONG",
            order_uuid: "accea42d-bf0f-4659-8450-c53b137435e0",
            price: 65750.05,
            price_sources: [],
            processed_ms: 1711212527420,
          },
          {
            leverage: 0.12,
            order_type: "LONG",
            order_uuid: "9dfa45fa-ba5a-4820-9126-5226396e1b02",
            price: 65724.05,
            price_sources: [],
            processed_ms: 1711213340761,
          },
          {
            leverage: 0.12,
            order_type: "LONG",
            order_uuid: "3e99b2a2-15cb-4378-9aa1-2759c71ea49d",
            price: 65528.49,
            price_sources: [],
            processed_ms: 1711214126220,
          },
          {
            leverage: 0.12,
            order_type: "FLAT",
            order_uuid: "afebdd26-9504-4fda-95b9-eec959cbf4fd",
            price: 65644.16,
            price_sources: [],
            processed_ms: 1711214942136,
          },
        ],
        position_type: "FLAT",
        position_uuid: "4286cd66-6d9b-4c16-9181-68c1ebea5e59",
        return_at_close: 1.0012008186407257,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 65624.038,
        close_ms: 1711219747420,
        current_return: 0.997483293800381,
        initial_entry_price: 65797.43,
        is_closed_position: true,
        miner_hotkey: "5EFD5p3NNTw4qGALArY3YJwkVFY5qodjUmx1pMkKgzfpQyx9",
        net_leverage: 0.0,
        open_ms: 1711215725805,
        orders: [
          {
            leverage: 0.12,
            order_type: "LONG",
            order_uuid: "9e587b59-a0c5-4e52-9d36-5f921bb262d8",
            price: 65797.43,
            price_sources: [],
            processed_ms: 1711215725803,
          },
          {
            leverage: 0.12,
            order_type: "LONG",
            order_uuid: "d0dfa464-0942-47a1-a2e2-7981a6ba5cf8",
            price: 65649.52,
            price_sources: [],
            processed_ms: 1711216540260,
          },
          {
            leverage: 0.12,
            order_type: "LONG",
            order_uuid: "ebda1b13-ac35-4f23-9efa-7a8c96c06c9d",
            price: 65616.79,
            price_sources: [],
            processed_ms: 1711217323816,
          },
          {
            leverage: 0.12,
            order_type: "LONG",
            order_uuid: "d1dd15e2-16cc-404d-89c0-48bd79aedd3f",
            price: 65577.45,
            price_sources: [],
            processed_ms: 1711218133217,
          },
          {
            leverage: 0.12,
            order_type: "LONG",
            order_uuid: "eb93740f-9c1f-4404-93cb-a15d319158fc",
            price: 65479.0,
            price_sources: [],
            processed_ms: 1711218927869,
          },
          {
            leverage: 0.12,
            order_type: "FLAT",
            order_uuid: "c06a228c-860a-4f3e-886b-af81af30b689",
            price: 65348.05,
            price_sources: [],
            processed_ms: 1711219747420,
          },
        ],
        position_type: "FLAT",
        position_uuid: "f488228c-2834-4867-b0c9-b28cc5c621dc",
        return_at_close: 0.9962863138478206,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 65186.363999999994,
        close_ms: 1711224523634,
        current_return: 0.9967940931335949,
        initial_entry_price: 65426.23,
        is_closed_position: true,
        miner_hotkey: "5EFD5p3NNTw4qGALArY3YJwkVFY5qodjUmx1pMkKgzfpQyx9",
        net_leverage: 0.0,
        open_ms: 1711220532810,
        orders: [
          {
            leverage: 0.12,
            order_type: "LONG",
            order_uuid: "35c0f570-daba-42ab-8745-6e4cfee293bb",
            price: 65426.23,
            price_sources: [],
            processed_ms: 1711220532808,
          },
          {
            leverage: 0.12,
            order_type: "LONG",
            order_uuid: "f540f054-e51b-4764-93c5-6843cc2ea7b1",
            price: 65376.6,
            price_sources: [],
            processed_ms: 1711221339483,
          },
          {
            leverage: 0.12,
            order_type: "LONG",
            order_uuid: "eceb800d-d0f6-4005-b62b-9dfa8fca8727",
            price: 65154.94,
            price_sources: [],
            processed_ms: 1711222145369,
          },
          {
            leverage: 0.12,
            order_type: "LONG",
            order_uuid: "cb7f2074-f1d3-4046-9176-08296211b052",
            price: 64965.34,
            price_sources: [],
            processed_ms: 1711222925564,
          },
          {
            leverage: 0.12,
            order_type: "LONG",
            order_uuid: "99396f06-90c5-4ded-b2d8-7a6750036d2a",
            price: 65008.71,
            price_sources: [],
            processed_ms: 1711223747347,
          },
          {
            leverage: 0.12,
            order_type: "FLAT",
            order_uuid: "a0b9eadf-adf4-4bb0-8851-68f51322ace9",
            price: 64836.78,
            price_sources: [],
            processed_ms: 1711224523634,
          },
        ],
        position_type: "FLAT",
        position_uuid: "f03aeafd-70e8-4233-9873-086db8f8fc1c",
        return_at_close: 0.9955979402218346,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 64961.884000000005,
        close_ms: 1711229334019,
        current_return: 0.999441400600413,
        initial_entry_price: 64945.29,
        is_closed_position: true,
        miner_hotkey: "5EFD5p3NNTw4qGALArY3YJwkVFY5qodjUmx1pMkKgzfpQyx9",
        net_leverage: 0.0,
        open_ms: 1711225328136,
        orders: [
          {
            leverage: 0.12,
            order_type: "LONG",
            order_uuid: "b50e9a1e-782e-4652-8b5b-3200f97c7b9f",
            price: 64945.29,
            price_sources: [],
            processed_ms: 1711225328014,
          },
          {
            leverage: 0.12,
            order_type: "LONG",
            order_uuid: "5c901f46-b163-43d7-b708-7ab69e0201ac",
            price: 64982.71,
            price_sources: [],
            processed_ms: 1711226124738,
          },
          {
            leverage: 0.12,
            order_type: "LONG",
            order_uuid: "c0db9432-2ffc-4e27-a7b3-300c9a3ef68b",
            price: 64969.65,
            price_sources: [],
            processed_ms: 1711226933856,
          },
          {
            leverage: 0.12,
            order_type: "LONG",
            order_uuid: "fd619541-ec24-4aeb-b761-b811a09cfb86",
            price: 64996.93,
            price_sources: [],
            processed_ms: 1711227724504,
          },
          {
            leverage: 0.12,
            order_type: "LONG",
            order_uuid: "c5674e4d-bf4b-4f60-bb06-2e2d677e2690",
            price: 64914.84,
            price_sources: [],
            processed_ms: 1711228555332,
          },
          {
            leverage: 0.12,
            order_type: "FLAT",
            order_uuid: "b9a7bfed-c82d-4f42-a849-397a5a15c7ef",
            price: 64901.42,
            price_sources: [],
            processed_ms: 1711229334019,
          },
        ],
        position_type: "FLAT",
        position_uuid: "bb1ed1b6-5e63-4ce7-b1d8-35550262fb0d",
        return_at_close: 0.9982420709196925,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 64912.18,
        close_ms: 1711234136087,
        current_return: 0.9996606275118334,
        initial_entry_price: 64778.38,
        is_closed_position: true,
        miner_hotkey: "5EFD5p3NNTw4qGALArY3YJwkVFY5qodjUmx1pMkKgzfpQyx9",
        net_leverage: 0.0,
        open_ms: 1711230149348,
        orders: [
          {
            leverage: 0.12,
            order_type: "LONG",
            order_uuid: "7d994e99-ba10-415a-a20e-3e862b6831bf",
            price: 64778.38,
            price_sources: [],
            processed_ms: 1711230149346,
          },
          {
            leverage: 0.12,
            order_type: "LONG",
            order_uuid: "8576bf7e-2048-4f4f-b624-bad9bac11439",
            price: 64957.48,
            price_sources: [],
            processed_ms: 1711230928249,
          },
          {
            leverage: 0.12,
            order_type: "LONG",
            order_uuid: "80f25f9d-112c-4f45-ab18-65796bd5137f",
            price: 64958.41,
            price_sources: [],
            processed_ms: 1711231741859,
          },
          {
            leverage: 0.12,
            order_type: "LONG",
            order_uuid: "a9c462a1-b658-4c9b-bd2a-050e42ed9a12",
            price: 64851.79,
            price_sources: [],
            processed_ms: 1711232542607,
          },
          {
            leverage: 0.12,
            order_type: "LONG",
            order_uuid: "974693df-25dd-464a-b0ab-ad6e8291a723",
            price: 65014.84,
            price_sources: [],
            processed_ms: 1711233332045,
          },
          {
            leverage: 0.12,
            order_type: "FLAT",
            order_uuid: "9619b345-ef4c-40f6-af25-dea9b86c50b5",
            price: 64875.54,
            price_sources: [],
            processed_ms: 1711234136087,
          },
        ],
        position_type: "FLAT",
        position_uuid: "d34c5039-8d0c-4ce9-b270-ff5cb79f0016",
        return_at_close: 0.9984610347588192,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 64507.407999999996,
        close_ms: 1711238950636,
        current_return: 0.9969965039753943,
        initial_entry_price: 64762.13,
        is_closed_position: true,
        miner_hotkey: "5EFD5p3NNTw4qGALArY3YJwkVFY5qodjUmx1pMkKgzfpQyx9",
        net_leverage: 0.0,
        open_ms: 1711234927195,
        orders: [
          {
            leverage: 0.12,
            order_type: "LONG",
            order_uuid: "59c3c741-3402-47b5-91de-f643ba8079cd",
            price: 64762.13,
            price_sources: [],
            processed_ms: 1711234927193,
          },
          {
            leverage: 0.12,
            order_type: "LONG",
            order_uuid: "a0053b7d-922f-4624-a413-318cacfedb5f",
            price: 64630.51,
            price_sources: [],
            processed_ms: 1711235733641,
          },
          {
            leverage: 0.12,
            order_type: "LONG",
            order_uuid: "7e29e01e-8cc5-48fa-a873-d9777475aa74",
            price: 64527.16,
            price_sources: [],
            processed_ms: 1711236549208,
          },
          {
            leverage: 0.12,
            order_type: "LONG",
            order_uuid: "64800f40-a94b-4866-bb38-55653b54b931",
            price: 64371.75,
            price_sources: [],
            processed_ms: 1711237352061,
          },
          {
            leverage: 0.12,
            order_type: "LONG",
            order_uuid: "55d76ee8-a0df-433e-b1ff-7bdf36cca534",
            price: 64245.49,
            price_sources: [],
            processed_ms: 1711238133301,
          },
          {
            leverage: 0.12,
            order_type: "FLAT",
            order_uuid: "00363b0d-3fda-456d-b601-15e3eb047397",
            price: 64183.22,
            price_sources: [],
            processed_ms: 1711238950636,
          },
        ],
        position_type: "FLAT",
        position_uuid: "b295cfd1-58e5-4f24-896d-0d9a308c1e2f",
        return_at_close: 0.9958001081706238,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 64468.416,
        close_ms: 1711243735447,
        current_return: 1.0018726599214975,
        initial_entry_price: 64308.74,
        is_closed_position: true,
        miner_hotkey: "5EFD5p3NNTw4qGALArY3YJwkVFY5qodjUmx1pMkKgzfpQyx9",
        net_leverage: 0.0,
        open_ms: 1711239739721,
        orders: [
          {
            leverage: 0.12,
            order_type: "LONG",
            order_uuid: "ebc724f2-565c-4d7c-be46-3d09bb5c1595",
            price: 64308.74,
            price_sources: [],
            processed_ms: 1711239739717,
          },
          {
            leverage: 0.12,
            order_type: "LONG",
            order_uuid: "3cfb6182-3256-4573-bf3c-8baa2408407e",
            price: 64514.73,
            price_sources: [],
            processed_ms: 1711240529882,
          },
          {
            leverage: 0.12,
            order_type: "LONG",
            order_uuid: "5ae675c7-9a27-48c7-a332-8ba684153045",
            price: 64530.56,
            price_sources: [],
            processed_ms: 1711241343775,
          },
          {
            leverage: 0.12,
            order_type: "LONG",
            order_uuid: "d76aed3f-d8ac-4d31-80c4-fa07878f1776",
            price: 64524.45,
            price_sources: [],
            processed_ms: 1711242152497,
          },
          {
            leverage: 0.12,
            order_type: "LONG",
            order_uuid: "44e3e029-cd42-41b9-8a47-42e082150b2b",
            price: 64463.6,
            price_sources: [],
            processed_ms: 1711242938208,
          },
          {
            leverage: 0.12,
            order_type: "FLAT",
            order_uuid: "2db30bfb-78d0-4a28-9ad5-72125fb08529",
            price: 64669.13,
            price_sources: [],
            processed_ms: 1711243735447,
          },
        ],
        position_type: "FLAT",
        position_uuid: "a740313a-daef-4d2b-b63c-7bc61b976695",
        return_at_close: 1.0006704127295918,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 64424.825999999994,
        close_ms: 1711248555865,
        current_return: 0.9986707275929705,
        initial_entry_price: 64468.05,
        is_closed_position: true,
        miner_hotkey: "5EFD5p3NNTw4qGALArY3YJwkVFY5qodjUmx1pMkKgzfpQyx9",
        net_leverage: 0.0,
        open_ms: 1711244555424,
        orders: [
          {
            leverage: 0.12,
            order_type: "LONG",
            order_uuid: "b3064ab3-49f0-4386-b0cc-2ea5e4e42187",
            price: 64468.05,
            price_sources: [],
            processed_ms: 1711244555421,
          },
          {
            leverage: 0.12,
            order_type: "LONG",
            order_uuid: "ff33f104-b390-4d5b-8ef4-4ed3f297b77d",
            price: 64516.34,
            price_sources: [],
            processed_ms: 1711245342147,
          },
          {
            leverage: 0.12,
            order_type: "LONG",
            order_uuid: "c32ea3a8-7c06-4b6f-9358-5ae532814a5d",
            price: 64465.0,
            price_sources: [],
            processed_ms: 1711246137961,
          },
          {
            leverage: 0.12,
            order_type: "LONG",
            order_uuid: "f0302401-b741-4367-a983-7f22e5f82c5d",
            price: 64362.94,
            price_sources: [],
            processed_ms: 1711246945523,
          },
          {
            leverage: 0.12,
            order_type: "LONG",
            order_uuid: "627fc51b-84f9-43ce-b8b8-729fcd68a120",
            price: 64311.8,
            price_sources: [],
            processed_ms: 1711247737231,
          },
          {
            leverage: 0.12,
            order_type: "FLAT",
            order_uuid: "b44e9804-5ff3-49cf-9a5a-5d9cd61ebb0d",
            price: 64282.0,
            price_sources: [],
            processed_ms: 1711248555865,
          },
        ],
        position_type: "FLAT",
        position_uuid: "74bf981b-399c-4595-a532-31b8d2244561",
        return_at_close: 0.997472322719859,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 64123.29000000001,
        close_ms: 1711253347603,
        current_return: 1.0001280501416085,
        initial_entry_price: 64240.46,
        is_closed_position: true,
        miner_hotkey: "5EFD5p3NNTw4qGALArY3YJwkVFY5qodjUmx1pMkKgzfpQyx9",
        net_leverage: 0.0,
        open_ms: 1711249338451,
        orders: [
          {
            leverage: 0.12,
            order_type: "LONG",
            order_uuid: "959b2dc4-4170-4db1-ac36-edfc3ea9d46d",
            price: 64240.46,
            price_sources: [],
            processed_ms: 1711249338449,
          },
          {
            leverage: 0.12,
            order_type: "LONG",
            order_uuid: "9c004711-6b14-4003-b33b-74e2255860f4",
            price: 64006.04,
            price_sources: [],
            processed_ms: 1711250133296,
          },
          {
            leverage: 0.12,
            order_type: "LONG",
            order_uuid: "10348f02-135e-4f82-80df-88f7082663e6",
            price: 64170.04,
            price_sources: [],
            processed_ms: 1711250952615,
          },
          {
            leverage: 0.12,
            order_type: "LONG",
            order_uuid: "74d5427e-2c45-410f-b089-c40e6f689aef",
            price: 64119.86,
            price_sources: [],
            processed_ms: 1711251757490,
          },
          {
            leverage: 0.12,
            order_type: "LONG",
            order_uuid: "b8d5c1c9-9337-446a-947c-87e9cb57c258",
            price: 64080.05,
            price_sources: [],
            processed_ms: 1711252538971,
          },
          {
            leverage: 0.12,
            order_type: "FLAT",
            order_uuid: "1645f3d0-e12c-4de8-a217-ffbf16194341",
            price: 64137.0,
            price_sources: [],
            processed_ms: 1711253347603,
          },
        ],
        position_type: "FLAT",
        position_uuid: "2c6b7357-904c-46d4-8ec2-ad2e52a1de99",
        return_at_close: 0.9989278964814386,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 64079.236,
        close_ms: 1711258142537,
        current_return: 1.0022156116711336,
        initial_entry_price: 64022.23,
        is_closed_position: true,
        miner_hotkey: "5EFD5p3NNTw4qGALArY3YJwkVFY5qodjUmx1pMkKgzfpQyx9",
        net_leverage: 0.0,
        open_ms: 1711254142084,
        orders: [
          {
            leverage: 0.12,
            order_type: "LONG",
            order_uuid: "842c4264-00f4-41ce-bb48-456972cc8f6f",
            price: 64022.23,
            price_sources: [],
            processed_ms: 1711254140566,
          },
          {
            leverage: 0.12,
            order_type: "LONG",
            order_uuid: "ba0b9e67-c26e-4792-9150-cada681bb857",
            price: 63970.45,
            price_sources: [],
            processed_ms: 1711254958613,
          },
          {
            leverage: 0.12,
            order_type: "LONG",
            order_uuid: "a59c33d2-df2a-4a2b-be02-d583d67615f8",
            price: 64029.24,
            price_sources: [],
            processed_ms: 1711255744331,
          },
          {
            leverage: 0.12,
            order_type: "LONG",
            order_uuid: "4ea50a75-1d42-46ae-a858-f24c4f7a5a88",
            price: 64203.69,
            price_sources: [],
            processed_ms: 1711256538821,
          },
          {
            leverage: 0.12,
            order_type: "LONG",
            order_uuid: "c1ea74c4-b714-44c8-a337-a8300ea81759",
            price: 64170.57,
            price_sources: [],
            processed_ms: 1711257361087,
          },
          {
            leverage: 0.12,
            order_type: "FLAT",
            order_uuid: "802cc074-ab81-4036-9e67-d85d619b1657",
            price: 64315.65,
            price_sources: [],
            processed_ms: 1711258142537,
          },
        ],
        position_type: "FLAT",
        position_uuid: "aaaff1f2-e738-43f0-960b-982672197942",
        return_at_close: 1.0010129529371283,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 64295.11400000001,
        close_ms: 1711262952537,
        current_return: 0.9986579107521624,
        initial_entry_price: 64329.85,
        is_closed_position: true,
        miner_hotkey: "5EFD5p3NNTw4qGALArY3YJwkVFY5qodjUmx1pMkKgzfpQyx9",
        net_leverage: 0.0,
        open_ms: 1711258956584,
        orders: [
          {
            leverage: 0.12,
            order_type: "LONG",
            order_uuid: "ba87c304-8f5b-4f77-9953-dfb989d28212",
            price: 64329.85,
            price_sources: [],
            processed_ms: 1711258956582,
          },
          {
            leverage: 0.12,
            order_type: "LONG",
            order_uuid: "05f0f5d5-af66-4f0d-97fd-ba823b597cf9",
            price: 64352.94,
            price_sources: [],
            processed_ms: 1711259737936,
          },
          {
            leverage: 0.12,
            order_type: "LONG",
            order_uuid: "7d8fa93b-8746-4273-bd6c-6b7c953efb37",
            price: 64257.99,
            price_sources: [],
            processed_ms: 1711260542863,
          },
          {
            leverage: 0.12,
            order_type: "LONG",
            order_uuid: "e1509dd3-a6fe-41cd-92ff-7bd4a345bfb8",
            price: 64292.75,
            price_sources: [],
            processed_ms: 1711261358457,
          },
          {
            leverage: 0.12,
            order_type: "LONG",
            order_uuid: "cbd8b97a-6c21-497e-bf0e-5ed611adb7f9",
            price: 64242.04,
            price_sources: [],
            processed_ms: 1711262142246,
          },
          {
            leverage: 0.12,
            order_type: "FLAT",
            order_uuid: "411ff502-34e5-4b6b-890f-f71624bea5cc",
            price: 64151.22,
            price_sources: [],
            processed_ms: 1711262952537,
          },
        ],
        position_type: "FLAT",
        position_uuid: "07903fed-fc6f-4f48-8f35-a98beeda5fb5",
        return_at_close: 0.9974595212592599,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 64216.96799999999,
        close_ms: 1711267745734,
        current_return: 1.004336549888381,
        initial_entry_price: 64196.01,
        is_closed_position: true,
        miner_hotkey: "5EFD5p3NNTw4qGALArY3YJwkVFY5qodjUmx1pMkKgzfpQyx9",
        net_leverage: 0.0,
        open_ms: 1711263736400,
        orders: [
          {
            leverage: 0.12,
            order_type: "LONG",
            order_uuid: "076dcb6d-2d4e-403a-a49a-cd3878501c40",
            price: 64196.01,
            price_sources: [],
            processed_ms: 1711263736398,
          },
          {
            leverage: 0.12,
            order_type: "LONG",
            order_uuid: "786d426f-c95e-4914-95df-82e764857b5e",
            price: 64158.79,
            price_sources: [],
            processed_ms: 1711264543109,
          },
          {
            leverage: 0.12,
            order_type: "LONG",
            order_uuid: "058e2d29-bb74-424a-a6e8-a165cab2e994",
            price: 64166.24,
            price_sources: [],
            processed_ms: 1711265340706,
          },
          {
            leverage: 0.12,
            order_type: "LONG",
            order_uuid: "9955f84e-5e8c-48c4-9205-da2a456c9efe",
            price: 64233.91,
            price_sources: [],
            processed_ms: 1711266138955,
          },
          {
            leverage: 0.12,
            order_type: "LONG",
            order_uuid: "19404606-c730-4607-b096-e2c3a10ae171",
            price: 64329.89,
            price_sources: [],
            processed_ms: 1711266957383,
          },
          {
            leverage: 0.12,
            order_type: "FLAT",
            order_uuid: "b5505ffb-4ffd-4c0f-ad99-f83b6cd8ca09",
            price: 64680.95,
            price_sources: [],
            processed_ms: 1711267745734,
          },
        ],
        position_type: "FLAT",
        position_uuid: "55d26733-257a-4496-af2e-0aeeb6508f54",
        return_at_close: 1.003131346028515,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 64789.0,
        close_ms: 1711313663824,
        current_return: 1.0025588788220223,
        initial_entry_price: 64789.0,
        is_closed_position: true,
        miner_hotkey: "5EFD5p3NNTw4qGALArY3YJwkVFY5qodjUmx1pMkKgzfpQyx9",
        net_leverage: 0.0,
        open_ms: 1711268561570,
        orders: [
          {
            leverage: 0.12,
            order_type: "LONG",
            order_uuid: "2b210135-09fc-49f0-b898-614d72748df7",
            price: 64789.0,
            price_sources: [],
            processed_ms: 1711268561568,
          },
          {
            leverage: 0.25,
            order_type: "FLAT",
            order_uuid: "2d6ed321-f918-4f87-b8ab-9c178f3dd18e",
            price: 66170.56,
            price_sources: [],
            processed_ms: 1711313663824,
          },
        ],
        position_type: "FLAT",
        position_uuid: "5e00b0d6-3603-4fb1-8f58-c59a0925f3d8",
        return_at_close: 1.002318264691105,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 70091.93,
        close_ms: 1711475014447,
        current_return: 1.00353535706607,
        initial_entry_price: 70091.93,
        is_closed_position: true,
        miner_hotkey: "5EFD5p3NNTw4qGALArY3YJwkVFY5qodjUmx1pMkKgzfpQyx9",
        net_leverage: 0.0,
        open_ms: 1711474887993,
        orders: [
          {
            leverage: -20.0,
            order_type: "SHORT",
            order_uuid: "31307ba6-a355-44e7-ae78-e1b7d9042685",
            price: 70091.93,
            price_sources: [],
            processed_ms: 1711474887990,
          },
          {
            leverage: 20.0,
            order_type: "LONG",
            order_uuid: "86051115-14d9-4cc6-b788-cbf44755daad",
            price: 70079.54,
            price_sources: [],
            processed_ms: 1711475014447,
          },
        ],
        position_type: "FLAT",
        position_uuid: "a53ef4a9-e9cf-4b2e-9541-f1be62e1a36a",
        return_at_close: 0.9633939427834273,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 70136.02,
        close_ms: 1711475553678,
        current_return: 1.0005132883217505,
        initial_entry_price: 70136.02,
        is_closed_position: true,
        miner_hotkey: "5EFD5p3NNTw4qGALArY3YJwkVFY5qodjUmx1pMkKgzfpQyx9",
        net_leverage: 0.0,
        open_ms: 1711475083882,
        orders: [
          {
            leverage: 20.0,
            order_type: "LONG",
            order_uuid: "12d66f83-e9cc-4935-be68-bd87f696c65c",
            price: 70136.02,
            price_sources: [],
            processed_ms: 1711475083880,
          },
          {
            leverage: -20.0,
            order_type: "SHORT",
            order_uuid: "dcebdca4-6fe5-4684-8653-b6b0ce9249f9",
            price: 70137.82,
            price_sources: [],
            processed_ms: 1711475553678,
          },
        ],
        position_type: "FLAT",
        position_uuid: "05d336e4-a486-4785-80f3-06f71c226e35",
        return_at_close: 0.9604927567888804,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 1.0778,
        close_ms: 1711696951446,
        current_return: 0.9999536092039338,
        initial_entry_price: 1.0778,
        is_closed_position: true,
        miner_hotkey: "5EFD5p3NNTw4qGALArY3YJwkVFY5qodjUmx1pMkKgzfpQyx9",
        net_leverage: 0.0,
        open_ms: 1711696883149,
        orders: [
          {
            leverage: 1.0,
            order_type: "LONG",
            order_uuid: "638abdf8-4366-49f5-9213-01c5aba55a9f",
            price: 1.0778,
            price_sources: [],
            processed_ms: 1711696883147,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "5987b471-a6df-4d80-81c4-b2de38975a6d",
            price: 1.07775,
            price_sources: [],
            processed_ms: 1711696951446,
          },
        ],
        position_type: "FLAT",
        position_uuid: "51318b15-ef54-4b58-b808-3d8a9267184e",
        return_at_close: 0.9998836124512895,
        trade_pair: ["EURUSD", "EUR/USD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 1.07774,
        close_ms: 1711697476266,
        current_return: 1.0000463907960664,
        initial_entry_price: 1.0778,
        is_closed_position: true,
        miner_hotkey: "5EFD5p3NNTw4qGALArY3YJwkVFY5qodjUmx1pMkKgzfpQyx9",
        net_leverage: 0.0,
        open_ms: 1711697072080,
        orders: [
          {
            leverage: 2.0,
            order_type: "LONG",
            order_uuid: "188080db-506e-44d7-9935-3c4814cf4a8a",
            price: 1.0778,
            price_sources: [],
            processed_ms: 1711697072078,
          },
          {
            leverage: -1.0,
            order_type: "SHORT",
            order_uuid: "153982d6-8ef2-4aa3-a8c8-15d2648317d3",
            price: 1.0778,
            price_sources: [],
            processed_ms: 1711697138550,
          },
          {
            leverage: 2.0,
            order_type: "LONG",
            order_uuid: "fa2d4701-f10e-4dba-9639-b7c0883c3cb2",
            price: 1.07775,
            price_sources: [],
            processed_ms: 1711697215673,
          },
          {
            leverage: 2.0,
            order_type: "LONG",
            order_uuid: "b0865315-ba01-4571-a7cc-496ee7cafeb5",
            price: 1.0777,
            price_sources: [],
            processed_ms: 1711697278484,
          },
          {
            leverage: 2.0,
            order_type: "FLAT",
            order_uuid: "23ca769b-994a-4a76-b874-3e0f71a7b39b",
            price: 1.07775,
            price_sources: [],
            processed_ms: 1711697476266,
          },
        ],
        position_type: "FLAT",
        position_uuid: "9d90f03f-633e-4407-b2a0-976c27bedaec",
        return_at_close: 0.9996963745592878,
        trade_pair: ["EURUSD", "EUR/USD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 1.077725,
        close_ms: 1711697745514,
        current_return: 0.9999536048993227,
        initial_entry_price: 1.0777,
        is_closed_position: true,
        miner_hotkey: "5EFD5p3NNTw4qGALArY3YJwkVFY5qodjUmx1pMkKgzfpQyx9",
        net_leverage: 0.0,
        open_ms: 1711697585722,
        orders: [
          {
            leverage: -1.0,
            order_type: "SHORT",
            order_uuid: "4702ae1c-03db-4e3b-8a2f-c50fa73584ca",
            price: 1.0777,
            price_sources: [],
            processed_ms: 1711697585719,
          },
          {
            leverage: -1.0,
            order_type: "SHORT",
            order_uuid: "52720d39-8d98-4d55-a487-c69330f281ac",
            price: 1.07775,
            price_sources: [],
            processed_ms: 1711697652471,
          },
          {
            leverage: 3.0,
            order_type: "LONG",
            order_uuid: "6aa09516-a564-4150-900b-4981b2ef0b16",
            price: 1.07775,
            price_sources: [],
            processed_ms: 1711697745514,
          },
        ],
        position_type: "FLAT",
        position_uuid: "7df070de-f901-421b-aa21-fa2ae96b3da2",
        return_at_close: 0.9998136113946368,
        trade_pair: ["EURUSD", "EUR/USD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 1.07775,
        close_ms: 1711698333832,
        current_return: 0.9993041057759219,
        initial_entry_price: 1.07775,
        is_closed_position: true,
        miner_hotkey: "5EFD5p3NNTw4qGALArY3YJwkVFY5qodjUmx1pMkKgzfpQyx9",
        net_leverage: 0.0,
        open_ms: 1711698228578,
        orders: [
          {
            leverage: -3.0,
            order_type: "SHORT",
            order_uuid: "22738359-8718-4df5-8702-556d31d3c837",
            price: 1.07775,
            price_sources: [],
            processed_ms: 1711698228575,
          },
          {
            leverage: 3.0,
            order_type: "FLAT",
            order_uuid: "8c4ff918-bf5e-4966-b552-a36c2fec7981",
            price: 1.078,
            price_sources: [],
            processed_ms: 1711698333832,
          },
        ],
        position_type: "FLAT",
        position_uuid: "12e0f628-392c-4d54-992e-ba162efac5d8",
        return_at_close: 0.9990942519137089,
        trade_pair: ["EURUSD", "EUR/USD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 3591.0870000000004,
        close_ms: 1711870595547,
        current_return: 0.9248383736645927,
        initial_entry_price: 3484.89,
        is_closed_position: true,
        miner_hotkey: "5EFD5p3NNTw4qGALArY3YJwkVFY5qodjUmx1pMkKgzfpQyx9",
        net_leverage: -10.0,
        open_ms: 1711734462666,
        orders: [
          {
            leverage: -1.0,
            order_type: "SHORT",
            order_uuid: "1d0a492e-d8d7-4a3f-847c-1e5ed2978dfe",
            price: 3484.89,
            price_sources: [],
            processed_ms: 1711734462663,
          },
          {
            leverage: -1.0,
            order_type: "SHORT",
            order_uuid: "2f2a77e0-7c79-4f70-8fa7-b079a6dc4f37",
            price: 3504.94,
            price_sources: [],
            processed_ms: 1711839370570,
          },
          {
            leverage: -8.0,
            order_type: "SHORT",
            order_uuid: "fe00369b-19c5-4c6f-aacf-34d1a34f52d6",
            price: 3615.13,
            price_sources: [],
            processed_ms: 1711870197962,
          },
        ],
        position_type: "FLAT",
        position_uuid: "3e03c9c8-ece0-410c-aec9-ad433e0bd091",
        return_at_close: 0.9063416061913008,
        trade_pair: ["ETHUSD", "ETH/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 70061.27,
        close_ms: 1711870595547,
        current_return: 0.9950623218791209,
        initial_entry_price: 70061.27,
        is_closed_position: true,
        miner_hotkey: "5EFD5p3NNTw4qGALArY3YJwkVFY5qodjUmx1pMkKgzfpQyx9",
        net_leverage: -2.0,
        open_ms: 1711776568590,
        orders: [
          {
            leverage: -2.0,
            order_type: "SHORT",
            order_uuid: "409cb83b-6026-47e5-b86c-b255c7ca3f10",
            price: 70061.27,
            price_sources: [],
            processed_ms: 1711776568587,
          },
        ],
        position_type: "FLAT",
        position_uuid: "82231b89-d4f3-4102-9b4c-bafa306e1560",
        return_at_close: 0.9910820725916044,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
    ],
    thirty_day_returns: 1.0,
  },
  "5EFq8jLuMQ8nTsfSeVvSmHByFSNME9FWrPKnDbbamiXBFU4P": {
    all_time_returns: 1.0,
    n_positions: 0,
    percentage_profitable: 0.0,
    positions: [
      {
        average_entry_price: 63847.0132,
        close_ms: 1711178604014,
        current_return: 1.0358216341330735,
        initial_entry_price: 63107.59,
        is_closed_position: true,
        miner_hotkey: "5EFq8jLuMQ8nTsfSeVvSmHByFSNME9FWrPKnDbbamiXBFU4P",
        net_leverage: 0.0,
        open_ms: 1711141982898,
        orders: [
          {
            leverage: 1.0,
            order_type: "LONG",
            order_uuid: "f30ff5e3-b8d3-4dde-a944-e7f8baa1f23e",
            price: 63107.59,
            price_sources: [],
            processed_ms: 1711141982897,
          },
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "cd4c56eb-a056-4172-806f-eceb445e80e7",
            price: 64172.34,
            price_sources: [],
            processed_ms: 1711177106798,
          },
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "b13d6d09-fd6a-4edd-a68a-b53b735e4eae",
            price: 64210.63,
            price_sources: [],
            processed_ms: 1711177409063,
          },
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "43ec7092-64ba-49a2-8199-a30eb137bf1b",
            price: 64288.32,
            price_sources: [],
            processed_ms: 1711177705995,
          },
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "d4022e01-dfbe-4289-96ea-3b293123139d",
            price: 64576.42,
            price_sources: [],
            processed_ms: 1711178008948,
          },
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "d4f9c30c-4f6b-414c-ac0f-a30ce030428d",
            price: 64452.1,
            price_sources: [],
            processed_ms: 1711178310119,
          },
          {
            leverage: 0.3,
            order_type: "FLAT",
            order_uuid: "5821006a-5aea-48af-be77-cb38612b5e83",
            price: 64751.26,
            price_sources: [],
            processed_ms: 1711178604014,
          },
        ],
        position_type: "FLAT",
        position_uuid: "ec154d97-aafd-43e4-806e-57182865db7a",
        return_at_close: 1.0306425259624081,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 64600.149999999994,
        close_ms: 1711180407037,
        current_return: 0.998534400584784,
        initial_entry_price: 64570.85,
        is_closed_position: true,
        miner_hotkey: "5EFq8jLuMQ8nTsfSeVvSmHByFSNME9FWrPKnDbbamiXBFU4P",
        net_leverage: 0.0,
        open_ms: 1711179051793,
        orders: [
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "b1ed322a-5532-41ca-8312-3be3fe5fdbb2",
            price: 64570.85,
            price_sources: [],
            processed_ms: 1711179051792,
          },
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "d266df7f-6eca-43e5-8d22-5b53ba024927",
            price: 64538.81,
            price_sources: [],
            processed_ms: 1711179247484,
          },
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "8e2f3ed3-54ef-447d-8e1f-38b0063d86df",
            price: 64569.15,
            price_sources: [],
            processed_ms: 1711179515164,
          },
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "9fe01d4d-2566-446b-8fac-fe52d2676cfb",
            price: 64726.36,
            price_sources: [],
            processed_ms: 1711179814750,
          },
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "480c3c7c-23e4-4553-a254-9455c0d1be2c",
            price: 64595.58,
            price_sources: [],
            processed_ms: 1711180112988,
          },
          {
            leverage: 0.3,
            order_type: "FLAT",
            order_uuid: "d5fafc12-c1ed-4029-b4d6-64e813e8a3d7",
            price: 64537.06,
            price_sources: [],
            processed_ms: 1711180407037,
          },
        ],
        position_type: "FLAT",
        position_uuid: "17a9e4f3-2bfc-4f73-bade-f201326dc8e7",
        return_at_close: 0.9955387973830296,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 64638.193999999996,
        close_ms: 1711182209685,
        current_return: 0.9983493690569358,
        initial_entry_price: 64515.33,
        is_closed_position: true,
        miner_hotkey: "5EFq8jLuMQ8nTsfSeVvSmHByFSNME9FWrPKnDbbamiXBFU4P",
        net_leverage: 0.0,
        open_ms: 1711180709784,
        orders: [
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "418af160-7a46-4ad9-9659-621d6a757309",
            price: 64515.33,
            price_sources: [],
            processed_ms: 1711180709783,
          },
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "9c2e0bac-bebf-45df-9db8-689d55bf1233",
            price: 64710.02,
            price_sources: [],
            processed_ms: 1711181055555,
          },
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "325c2ac9-2bdd-4aca-8ab7-d995ea89fe1c",
            price: 64771.48,
            price_sources: [],
            processed_ms: 1711181313967,
          },
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "a29a6be6-4e89-44f7-ac56-614a4ec5eba3",
            price: 64628.3,
            price_sources: [],
            processed_ms: 1711181611872,
          },
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "c89ecd4f-1f08-42b5-b4a6-ef7f9bd5dfe7",
            price: 64565.84,
            price_sources: [],
            processed_ms: 1711181918674,
          },
          {
            leverage: 0.3,
            order_type: "FLAT",
            order_uuid: "d1396519-c513-463a-a2c9-22a6da803952",
            price: 64567.2,
            price_sources: [],
            processed_ms: 1711182209685,
          },
        ],
        position_type: "FLAT",
        position_uuid: "cbfd7d09-8f8c-497b-a4b5-8504da8d0680",
        return_at_close: 0.9953543209497651,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 64418.248,
        close_ms: 1711184015375,
        current_return: 0.9987514269052288,
        initial_entry_price: 64475.2,
        is_closed_position: true,
        miner_hotkey: "5EFq8jLuMQ8nTsfSeVvSmHByFSNME9FWrPKnDbbamiXBFU4P",
        net_leverage: 0.0,
        open_ms: 1711182510701,
        orders: [
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "814df73f-c508-4c43-908a-329d8f0e4f7d",
            price: 64475.2,
            price_sources: [],
            processed_ms: 1711182510700,
          },
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "31d0b7d7-b2e7-49ce-ba7e-362e77364f29",
            price: 64358.11,
            price_sources: [],
            processed_ms: 1711182811597,
          },
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "a2ff74cb-6205-4bec-a27f-8d199fa70219",
            price: 64402.14,
            price_sources: [],
            processed_ms: 1711183121173,
          },
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "028899f8-869b-4e17-946c-0dc49b77fa9d",
            price: 64411.38,
            price_sources: [],
            processed_ms: 1711183414381,
          },
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "b922c6b9-f23e-42ff-9d6c-0ba9e810a098",
            price: 64444.41,
            price_sources: [],
            processed_ms: 1711183712761,
          },
          {
            leverage: 0.3,
            order_type: "FLAT",
            order_uuid: "489cba94-4dfc-452c-86dd-acbe7947e45b",
            price: 64364.58,
            price_sources: [],
            processed_ms: 1711184015375,
          },
        ],
        position_type: "FLAT",
        position_uuid: "11fdc593-f81e-444b-8104-fd17eff63712",
        return_at_close: 0.9957551726245131,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 64370.409999999996,
        close_ms: 1711185819488,
        current_return: 0.9991600075080005,
        initial_entry_price: 64411.29,
        is_closed_position: true,
        miner_hotkey: "5EFq8jLuMQ8nTsfSeVvSmHByFSNME9FWrPKnDbbamiXBFU4P",
        net_leverage: 0.0,
        open_ms: 1711184318056,
        orders: [
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "fa87d066-3f2b-4fc4-b862-c986be02a8ee",
            price: 64411.29,
            price_sources: [],
            processed_ms: 1711184318055,
          },
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "6b426ee2-c7cc-456e-a09c-cda81c979379",
            price: 64496.29,
            price_sources: [],
            processed_ms: 1711184621746,
          },
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "a34b87c4-91cd-4167-92c2-7eefab566128",
            price: 64417.38,
            price_sources: [],
            processed_ms: 1711184925629,
          },
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "112c50d1-57a6-4199-93d0-e0a423676263",
            price: 64295.71,
            price_sources: [],
            processed_ms: 1711185232562,
          },
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "e479873b-bc81-48fa-b75e-89e54a91e9c1",
            price: 64231.38,
            price_sources: [],
            processed_ms: 1711185515646,
          },
          {
            leverage: 0.3,
            order_type: "FLAT",
            order_uuid: "f89dab79-802e-4d83-8f6c-db3e2260198c",
            price: 64334.34,
            price_sources: [],
            processed_ms: 1711185819488,
          },
        ],
        position_type: "FLAT",
        position_uuid: "3f739acf-755e-4eb6-b3c7-923bfb7a0424",
        return_at_close: 0.9961625274854765,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 64340.1,
        close_ms: 1711187620108,
        current_return: 1.0014949954613017,
        initial_entry_price: 64324.61,
        is_closed_position: true,
        miner_hotkey: "5EFq8jLuMQ8nTsfSeVvSmHByFSNME9FWrPKnDbbamiXBFU4P",
        net_leverage: 0.0,
        open_ms: 1711186124187,
        orders: [
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "417c3fe1-bc54-4b15-a275-90f088441c8c",
            price: 64324.61,
            price_sources: [],
            processed_ms: 1711186124186,
          },
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "33915915-03f4-4fd9-aae4-ec8baa395267",
            price: 64344.25,
            price_sources: [],
            processed_ms: 1711186430967,
          },
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "59e568e0-38a3-4806-a93f-10669b71d938",
            price: 64330.49,
            price_sources: [],
            processed_ms: 1711186726719,
          },
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "fd1886a4-b7a4-4440-88d5-6c51a1ff4415",
            price: 64336.08,
            price_sources: [],
            processed_ms: 1711187021800,
          },
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "818c9322-e886-451d-8518-5537e3e6123b",
            price: 64365.07,
            price_sources: [],
            processed_ms: 1711187323940,
          },
          {
            leverage: 0.3,
            order_type: "FLAT",
            order_uuid: "3846ec15-b30f-4ef5-806b-2a5662f54602",
            price: 64404.21,
            price_sources: [],
            processed_ms: 1711187620108,
          },
        ],
        position_type: "FLAT",
        position_uuid: "a168fd16-9b25-41d8-88c8-fe1745504367",
        return_at_close: 0.9984905104749178,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 64519.286,
        close_ms: 1711189421049,
        current_return: 1.0023109914161092,
        initial_entry_price: 64423.0,
        is_closed_position: true,
        miner_hotkey: "5EFq8jLuMQ8nTsfSeVvSmHByFSNME9FWrPKnDbbamiXBFU4P",
        net_leverage: 0.0,
        open_ms: 1711187911960,
        orders: [
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "114bc950-1393-4494-91aa-5ea7d06a8f51",
            price: 64423.0,
            price_sources: [],
            processed_ms: 1711187911959,
          },
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "4df018c2-7793-468c-b46b-75facb7e2e9c",
            price: 64560.26,
            price_sources: [],
            processed_ms: 1711188221114,
          },
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "2d109b3f-9292-4ed5-8df7-4e8770f4cecc",
            price: 64571.05,
            price_sources: [],
            processed_ms: 1711188520192,
          },
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "12df44cb-8a2b-4df8-82f8-024e00f11a22",
            price: 64493.29,
            price_sources: [],
            processed_ms: 1711188818083,
          },
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "a33b91bf-f6f1-490f-a348-0bf980904918",
            price: 64548.83,
            price_sources: [],
            processed_ms: 1711189115703,
          },
          {
            leverage: 0.3,
            order_type: "FLAT",
            order_uuid: "2a0ea6da-8100-4713-af0b-5ff63ad5e19d",
            price: 64618.54,
            price_sources: [],
            processed_ms: 1711189421049,
          },
        ],
        position_type: "FLAT",
        position_uuid: "a2405c3a-03c7-4f53-a062-a2a00af05e9c",
        return_at_close: 0.9993040584418609,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 64559.04199999999,
        close_ms: 1711191224710,
        current_return: 0.9959582294805763,
        initial_entry_price: 64598.67,
        is_closed_position: true,
        miner_hotkey: "5EFq8jLuMQ8nTsfSeVvSmHByFSNME9FWrPKnDbbamiXBFU4P",
        net_leverage: 0.0,
        open_ms: 1711189714439,
        orders: [
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "6e478fe4-441c-4bcb-b5c9-d4f25b569cbc",
            price: 64598.67,
            price_sources: [],
            processed_ms: 1711189714438,
          },
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "ac55362b-dd6a-4584-9451-d4b69cfbc593",
            price: 64595.4,
            price_sources: [],
            processed_ms: 1711190019922,
          },
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "1c38e1aa-171b-4db2-ba28-a1f9494b953b",
            price: 64510.23,
            price_sources: [],
            processed_ms: 1711190321977,
          },
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "9534f37e-506d-48a8-8609-ba1ae7c7c58c",
            price: 64556.04,
            price_sources: [],
            processed_ms: 1711190624523,
          },
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "0823c56b-0dec-4b53-8a2b-05f75c458fea",
            price: 64534.87,
            price_sources: [],
            processed_ms: 1711190923744,
          },
          {
            leverage: 0.3,
            order_type: "FLAT",
            order_uuid: "0d6db60f-0168-4e92-b224-cb954710978b",
            price: 64384.98,
            price_sources: [],
            processed_ms: 1711191224710,
          },
        ],
        position_type: "FLAT",
        position_uuid: "7a564ed2-27c3-4a81-a671-3cea17de69c2",
        return_at_close: 0.9929703547921346,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 64378.89199999999,
        close_ms: 1711193020307,
        current_return: 1.002165625317761,
        initial_entry_price: 64317.22,
        is_closed_position: true,
        miner_hotkey: "5EFq8jLuMQ8nTsfSeVvSmHByFSNME9FWrPKnDbbamiXBFU4P",
        net_leverage: 0.0,
        open_ms: 1711191533810,
        orders: [
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "b67d665d-a42c-4e12-b80d-d95e3f1ca918",
            price: 64317.22,
            price_sources: [],
            processed_ms: 1711191533809,
          },
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "9d2a631c-ce53-4d06-9c7c-ec12735ec7dd",
            price: 64333.58,
            price_sources: [],
            processed_ms: 1711191816911,
          },
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "4e1b5f39-023f-4308-bd44-fa01934b354d",
            price: 64272.65,
            price_sources: [],
            processed_ms: 1711192118094,
          },
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "7e13ac4e-2dbd-48c0-a109-705d96e02430",
            price: 64445.74,
            price_sources: [],
            processed_ms: 1711192426477,
          },
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "1c2eb1ed-c359-402e-9c33-a205cef20063",
            price: 64525.27,
            price_sources: [],
            processed_ms: 1711192724115,
          },
          {
            leverage: 0.3,
            order_type: "FLAT",
            order_uuid: "b551db73-3963-4823-8692-7d286609f82b",
            price: 64471.75,
            price_sources: [],
            processed_ms: 1711193020307,
          },
        ],
        position_type: "FLAT",
        position_uuid: "489ae632-353f-416b-b15c-d3d218b9d847",
        return_at_close: 0.9991591284418078,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 64534.27,
        close_ms: 1711194833660,
        current_return: 1.0000767994816735,
        initial_entry_price: 64453.56,
        is_closed_position: true,
        miner_hotkey: "5EFq8jLuMQ8nTsfSeVvSmHByFSNME9FWrPKnDbbamiXBFU4P",
        net_leverage: 0.0,
        open_ms: 1711193326040,
        orders: [
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "07f6bfac-c517-46da-8ed1-e7a21da1c173",
            price: 64453.56,
            price_sources: [],
            processed_ms: 1711193326039,
          },
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "34ccd0a3-fe92-4a1e-821f-dfad788f49f1",
            price: 64520.89,
            price_sources: [],
            processed_ms: 1711193627805,
          },
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "9be4062c-8d75-420c-b7e1-c2fc69709eae",
            price: 64563.06,
            price_sources: [],
            processed_ms: 1711193924224,
          },
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "2b744518-4034-49e4-a647-700a312df5bc",
            price: 64580.15,
            price_sources: [],
            processed_ms: 1711194226521,
          },
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "a1aad8f1-cd07-4113-aee1-2abe3b4b55b2",
            price: 64553.69,
            price_sources: [],
            processed_ms: 1711194527525,
          },
          {
            leverage: 0.3,
            order_type: "FLAT",
            order_uuid: "b87e3bfe-a157-4fd3-9fe9-454897cf1edd",
            price: 64537.57,
            price_sources: [],
            processed_ms: 1711194833660,
          },
        ],
        position_type: "FLAT",
        position_uuid: "97097703-3031-4e8a-9494-45812289b7e4",
        return_at_close: 0.9970765690832285,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 64612.011999999995,
        close_ms: 1711196632234,
        current_return: 1.003943969239665,
        initial_entry_price: 64613.08,
        is_closed_position: true,
        miner_hotkey: "5EFq8jLuMQ8nTsfSeVvSmHByFSNME9FWrPKnDbbamiXBFU4P",
        net_leverage: 0.0,
        open_ms: 1711195138124,
        orders: [
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "bb641faf-b58a-45cd-87d5-44e46c4bac18",
            price: 64613.08,
            price_sources: [],
            processed_ms: 1711195138122,
          },
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "8ff11356-6488-4b38-be66-62ecc125721a",
            price: 64569.16,
            price_sources: [],
            processed_ms: 1711195426854,
          },
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "06a57ad3-05aa-400e-96e6-d5ec864d9e37",
            price: 64682.92,
            price_sources: [],
            processed_ms: 1711195720879,
          },
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "a8f76ae8-8141-408d-8ebc-8e883deac4c1",
            price: 64649.17,
            price_sources: [],
            processed_ms: 1711196021197,
          },
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "78287d97-ee27-4c38-974d-4681f2540ccc",
            price: 64545.73,
            price_sources: [],
            processed_ms: 1711196326364,
          },
          {
            leverage: 0.3,
            order_type: "FLAT",
            order_uuid: "90093c6a-63ab-4133-b8d6-91bfb1c5ab42",
            price: 64781.9,
            price_sources: [],
            processed_ms: 1711196632234,
          },
        ],
        position_type: "FLAT",
        position_uuid: "748a89af-6ca1-448f-ae94-db4d7e1e2954",
        return_at_close: 1.000932137331946,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 64722.897999999994,
        close_ms: 1711198432179,
        current_return: 1.0035914933165881,
        initial_entry_price: 64687.02,
        is_closed_position: true,
        miner_hotkey: "5EFq8jLuMQ8nTsfSeVvSmHByFSNME9FWrPKnDbbamiXBFU4P",
        net_leverage: 0.0,
        open_ms: 1711196925388,
        orders: [
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "b8e89e7d-b500-4dc7-ac8c-b665764c0b02",
            price: 64687.02,
            price_sources: [],
            processed_ms: 1711196925386,
          },
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "77a4105e-6aa3-4980-a270-00af45e99ed0",
            price: 64648.58,
            price_sources: [],
            processed_ms: 1711197228516,
          },
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "88c05b5d-be1c-448f-9cdb-5955523ff581",
            price: 64703.31,
            price_sources: [],
            processed_ms: 1711197542615,
          },
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "b72f24c0-d574-47e1-97de-001f7de620e2",
            price: 64757.2,
            price_sources: [],
            processed_ms: 1711197825264,
          },
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "34580f54-11c1-486a-987d-af7111b35fc6",
            price: 64818.38,
            price_sources: [],
            processed_ms: 1711198127234,
          },
          {
            leverage: 0.3,
            order_type: "FLAT",
            order_uuid: "9c3cbf9e-d64f-4f67-9a2d-5afce5747c8d",
            price: 64877.78,
            price_sources: [],
            processed_ms: 1711198432179,
          },
        ],
        position_type: "FLAT",
        position_uuid: "02287861-4886-49fe-beb2-916f70e98fcc",
        return_at_close: 1.0005807188366385,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 64632.75,
        close_ms: 1711200235307,
        current_return: 0.9991743603841248,
        initial_entry_price: 64749.8,
        is_closed_position: true,
        miner_hotkey: "5EFq8jLuMQ8nTsfSeVvSmHByFSNME9FWrPKnDbbamiXBFU4P",
        net_leverage: 0.0,
        open_ms: 1711198739129,
        orders: [
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "fae30bd9-b146-4c6e-a8eb-9eafdd1d209b",
            price: 64749.8,
            price_sources: [],
            processed_ms: 1711198739128,
          },
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "deb61d51-9011-41d2-81c7-59298112d427",
            price: 64605.56,
            price_sources: [],
            processed_ms: 1711199033456,
          },
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "d674dc3c-7eaf-4d95-addc-8465354f540e",
            price: 64594.3,
            price_sources: [],
            processed_ms: 1711199336940,
          },
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "54d423b8-f831-4759-9524-9a54d5c0868a",
            price: 64676.75,
            price_sources: [],
            processed_ms: 1711199638675,
          },
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "dcf96dcc-669e-4ef8-bc34-b4f04da6029a",
            price: 64537.34,
            price_sources: [],
            processed_ms: 1711199932805,
          },
          {
            leverage: 0.3,
            order_type: "FLAT",
            order_uuid: "82a72d64-0908-4e67-ab9e-cc9cabe72182",
            price: 64597.11,
            price_sources: [],
            processed_ms: 1711200235307,
          },
        ],
        position_type: "FLAT",
        position_uuid: "8ef074b0-132c-47bd-9f35-c06ce0cf74e2",
        return_at_close: 0.9961768373029724,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 64437.866,
        close_ms: 1711202026239,
        current_return: 1.0049492015211954,
        initial_entry_price: 64517.68,
        is_closed_position: true,
        miner_hotkey: "5EFq8jLuMQ8nTsfSeVvSmHByFSNME9FWrPKnDbbamiXBFU4P",
        net_leverage: 0.0,
        open_ms: 1711200540297,
        orders: [
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "4323d622-ab8d-44ab-8c5b-bd8485befb17",
            price: 64517.68,
            price_sources: [],
            processed_ms: 1711200540296,
          },
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "6a09353b-3e3c-4a1e-916a-617c3fc8f5ad",
            price: 64438.19,
            price_sources: [],
            processed_ms: 1711200833046,
          },
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "3ed03490-704f-4177-a911-12a911b44cc2",
            price: 64464.65,
            price_sources: [],
            processed_ms: 1711201125431,
          },
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "763bf191-f6ec-4bfd-a59d-5b38fe627561",
            price: 64462.96,
            price_sources: [],
            processed_ms: 1711201428093,
          },
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "10b80ab1-ea6f-46a1-b9fe-f1f0c5682132",
            price: 64305.85,
            price_sources: [],
            processed_ms: 1711201733731,
          },
          {
            leverage: 0.3,
            order_type: "FLAT",
            order_uuid: "6680edfa-8d68-4f8e-a25c-545d88bea030",
            price: 64650.74,
            price_sources: [],
            processed_ms: 1711202026239,
          },
        ],
        position_type: "FLAT",
        position_uuid: "8382f17d-d749-4f10-8e1f-8f5262d9fc23",
        return_at_close: 1.0019343539166319,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 64573.87399999999,
        close_ms: 1711203835936,
        current_return: 1.0019992437870515,
        initial_entry_price: 64611.43,
        is_closed_position: true,
        miner_hotkey: "5EFq8jLuMQ8nTsfSeVvSmHByFSNME9FWrPKnDbbamiXBFU4P",
        net_leverage: 0.0,
        open_ms: 1711202327474,
        orders: [
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "29232de2-54cf-41db-98c6-10e82333304f",
            price: 64611.43,
            price_sources: [],
            processed_ms: 1711202327472,
          },
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "849eab56-99de-457a-bcba-7dbb8b645ef0",
            price: 64607.19,
            price_sources: [],
            processed_ms: 1711202632205,
          },
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "69fc6d7b-37d2-494d-b036-89b115db0b73",
            price: 64618.15,
            price_sources: [],
            processed_ms: 1711202934869,
          },
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "3929f03e-6b3c-48e0-b709-2bbb8a8ab924",
            price: 64560.7,
            price_sources: [],
            processed_ms: 1711203228169,
          },
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "6d41f003-e560-46a0-afd6-7a4919f00ee2",
            price: 64471.9,
            price_sources: [],
            processed_ms: 1711203535248,
          },
          {
            leverage: 0.3,
            order_type: "FLAT",
            order_uuid: "51fdb67f-82ce-4812-a12e-2ec8e9e278e5",
            price: 64659.99,
            price_sources: [],
            processed_ms: 1711203835936,
          },
        ],
        position_type: "FLAT",
        position_uuid: "f3e91fe7-4222-46e2-8c87-8cf64c479551",
        return_at_close: 0.9989932460556904,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 65119.481999999996,
        close_ms: 1711205630703,
        current_return: 1.0037769698362136,
        initial_entry_price: 64765.41,
        is_closed_position: true,
        miner_hotkey: "5EFq8jLuMQ8nTsfSeVvSmHByFSNME9FWrPKnDbbamiXBFU4P",
        net_leverage: 0.0,
        open_ms: 1711204134344,
        orders: [
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "7b4f751a-8898-4b24-9d19-4c33fc9dfcb2",
            price: 64765.41,
            price_sources: [],
            processed_ms: 1711204134342,
          },
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "b05dda30-9d73-459f-8ac9-9504b00ac059",
            price: 65372.4,
            price_sources: [],
            processed_ms: 1711204427638,
          },
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "5bbf5b09-601a-48db-bcc9-2c630e690f62",
            price: 65187.53,
            price_sources: [],
            processed_ms: 1711204730453,
          },
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "6c658c71-7a3e-4c40-9ffc-9dce2ae8066a",
            price: 65119.36,
            price_sources: [],
            processed_ms: 1711205030212,
          },
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "35b940df-30e0-4039-9b73-404972d65b23",
            price: 65152.71,
            price_sources: [],
            processed_ms: 1711205328387,
          },
          {
            leverage: 0.3,
            order_type: "FLAT",
            order_uuid: "a5e5fb2a-7442-4234-a748-0fd4bd34dc9d",
            price: 65282.56,
            price_sources: [],
            processed_ms: 1711205630703,
          },
        ],
        position_type: "FLAT",
        position_uuid: "90d23879-cf26-45e1-bd52-1b5927736434",
        return_at_close: 1.000765638926705,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 65146.74599999999,
        close_ms: 1711207433949,
        current_return: 0.9920252753347233,
        initial_entry_price: 65290.53,
        is_closed_position: true,
        miner_hotkey: "5EFq8jLuMQ8nTsfSeVvSmHByFSNME9FWrPKnDbbamiXBFU4P",
        net_leverage: 0.0,
        open_ms: 1711205929064,
        orders: [
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "160851cc-9b45-4af1-817c-b25cdc65cd2b",
            price: 65290.53,
            price_sources: [],
            processed_ms: 1711205929062,
          },
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "b1433919-6c9f-4011-8b6e-41fe3d57564b",
            price: 65132.84,
            price_sources: [],
            processed_ms: 1711206263358,
          },
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "e516b855-12ed-4961-9efa-733a5becd8dd",
            price: 65268.35,
            price_sources: [],
            processed_ms: 1711206546186,
          },
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "08d8e319-290c-4fff-8460-c6a0e1f8ff79",
            price: 65098.1,
            price_sources: [],
            processed_ms: 1711206840098,
          },
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "a771136c-afba-40fc-b7a0-583305926439",
            price: 64943.91,
            price_sources: [],
            processed_ms: 1711207134207,
          },
          {
            leverage: 0.3,
            order_type: "FLAT",
            order_uuid: "280b4209-69ad-41dd-a955-32253bd43e1b",
            price: 64799.63,
            price_sources: [],
            processed_ms: 1711207433949,
          },
        ],
        position_type: "FLAT",
        position_uuid: "7a57b1ae-03bf-4d79-867e-7a797a91deb1",
        return_at_close: 0.9890491995087192,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 64879.08799999999,
        close_ms: 1711209233325,
        current_return: 1.0026999245135617,
        initial_entry_price: 64819.59,
        is_closed_position: true,
        miner_hotkey: "5EFq8jLuMQ8nTsfSeVvSmHByFSNME9FWrPKnDbbamiXBFU4P",
        net_leverage: 0.0,
        open_ms: 1711207731562,
        orders: [
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "a2db577e-6076-442e-9832-d8cd51762f9c",
            price: 64819.59,
            price_sources: [],
            processed_ms: 1711207731559,
          },
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "c11abda2-f0e3-4f5b-9c59-651dbbc835c1",
            price: 64942.21,
            price_sources: [],
            processed_ms: 1711208030264,
          },
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "5375f876-c57a-45f1-8324-49d89b3635fe",
            price: 64872.03,
            price_sources: [],
            processed_ms: 1711208355026,
          },
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "496c96fb-be96-4854-928f-9c0062cf4282",
            price: 64845.58,
            price_sources: [],
            processed_ms: 1711208644166,
          },
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "5871e1b0-41f6-40bc-b32d-02e8dbc8986a",
            price: 64916.03,
            price_sources: [],
            processed_ms: 1711208933989,
          },
          {
            leverage: 0.3,
            order_type: "FLAT",
            order_uuid: "99d50283-d896-4f5f-a53a-079777e63bea",
            price: 64995.76,
            price_sources: [],
            processed_ms: 1711209233325,
          },
        ],
        position_type: "FLAT",
        position_uuid: "a756cb17-a7ec-41a8-ba40-31b56d9c103e",
        return_at_close: 0.999691824740021,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 65093.611999999994,
        close_ms: 1711211032645,
        current_return: 1.0010181141921253,
        initial_entry_price: 65117.45,
        is_closed_position: true,
        miner_hotkey: "5EFq8jLuMQ8nTsfSeVvSmHByFSNME9FWrPKnDbbamiXBFU4P",
        net_leverage: 0.0,
        open_ms: 1711209531632,
        orders: [
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "a41df9ab-ec2d-417e-9d5a-d77625dddfce",
            price: 65117.45,
            price_sources: [],
            processed_ms: 1711209531629,
          },
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "1e0dfffa-db8b-41ac-8c53-ce0971f192d6",
            price: 65059.17,
            price_sources: [],
            processed_ms: 1711209864889,
          },
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "d10d6b65-3a64-4b9b-90b4-151da7f50b35",
            price: 64933.68,
            price_sources: [],
            processed_ms: 1711210147775,
          },
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "a35672e2-fbbb-4b2e-b380-5449affc5cba",
            price: 65192.22,
            price_sources: [],
            processed_ms: 1711210475427,
          },
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "ba2f5dd3-6b0f-4048-bab3-2e0035bf82ea",
            price: 65165.54,
            price_sources: [],
            processed_ms: 1711210734574,
          },
          {
            leverage: 0.3,
            order_type: "FLAT",
            order_uuid: "e50edfab-fa0c-419c-9468-78c10a10b8cc",
            price: 65137.81,
            price_sources: [],
            processed_ms: 1711211032645,
          },
        ],
        position_type: "FLAT",
        position_uuid: "a3057d02-0bb9-4d27-a5f4-fd3f45119ee5",
        return_at_close: 0.9980150598495489,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 65503.98,
        close_ms: 1711212836951,
        current_return: 1.0025549516651697,
        initial_entry_price: 65208.67,
        is_closed_position: true,
        miner_hotkey: "5EFq8jLuMQ8nTsfSeVvSmHByFSNME9FWrPKnDbbamiXBFU4P",
        net_leverage: 0.0,
        open_ms: 1711211339573,
        orders: [
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "1a16dce6-f2c2-4ec9-8526-2efb23d30d58",
            price: 65208.67,
            price_sources: [],
            processed_ms: 1711211339571,
          },
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "ed706e57-9c27-4b40-934e-4aa5f19bf5b5",
            price: 65403.34,
            price_sources: [],
            processed_ms: 1711211637572,
          },
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "621868f7-9a25-4dfd-a293-6e1547f8b292",
            price: 65486.5,
            price_sources: [],
            processed_ms: 1711211933484,
          },
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "34a2b9e2-cd6e-4f07-8031-d652a81ce7d3",
            price: 65671.34,
            price_sources: [],
            processed_ms: 1711212242150,
          },
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "cddb60a9-ed97-4c2d-9a8f-47870829570c",
            price: 65750.05,
            price_sources: [],
            processed_ms: 1711212540247,
          },
          {
            leverage: 0.3,
            order_type: "FLAT",
            order_uuid: "4f84e3bf-8e63-4b64-8ac9-28b86cd16d20",
            price: 65615.05,
            price_sources: [],
            processed_ms: 1711212836951,
          },
        ],
        position_type: "FLAT",
        position_uuid: "970c7b8d-ce82-4e53-8119-58711cdb7bfd",
        return_at_close: 0.9995472868101742,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 65621.982,
        close_ms: 1711214643998,
        current_return: 0.9989065031506917,
        initial_entry_price: 65709.38,
        is_closed_position: true,
        miner_hotkey: "5EFq8jLuMQ8nTsfSeVvSmHByFSNME9FWrPKnDbbamiXBFU4P",
        net_leverage: 0.0,
        open_ms: 1711213135006,
        orders: [
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "6c45203e-9b4e-4d1d-b225-edaedafc6d7f",
            price: 65709.38,
            price_sources: [],
            processed_ms: 1711213135003,
          },
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "2d4ffa8e-2f1e-4ed6-aed6-902265d845e1",
            price: 65710.89,
            price_sources: [],
            processed_ms: 1711213444040,
          },
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "202a6fba-1e71-48ba-ac79-6ac457ffbde0",
            price: 65634.07,
            price_sources: [],
            processed_ms: 1711213751736,
          },
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "8db7b550-b103-4d6d-8913-7993d0087ea7",
            price: 65563.81,
            price_sources: [],
            processed_ms: 1711214041525,
          },
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "5aaaadc6-9a5a-480e-97b5-5ce53644900c",
            price: 65491.76,
            price_sources: [],
            processed_ms: 1711214337519,
          },
          {
            leverage: 0.3,
            order_type: "FLAT",
            order_uuid: "126149cf-cc4d-4d53-924a-14fa17a52eea",
            price: 65574.08,
            price_sources: [],
            processed_ms: 1711214643998,
          },
        ],
        position_type: "FLAT",
        position_uuid: "48bb5795-57d3-439e-9b08-f52529770c9c",
        return_at_close: 0.9959097836412396,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 65777.37000000001,
        close_ms: 1711216445620,
        current_return: 0.9983019814710095,
        initial_entry_price: 65644.16,
        is_closed_position: true,
        miner_hotkey: "5EFq8jLuMQ8nTsfSeVvSmHByFSNME9FWrPKnDbbamiXBFU4P",
        net_leverage: 0.0,
        open_ms: 1711214941580,
        orders: [
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "0244e227-a33e-4372-ab25-25ce4e3267c7",
            price: 65644.16,
            price_sources: [],
            processed_ms: 1711214941577,
          },
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "ed0afd24-1813-4503-bc9a-999a00dbe04a",
            price: 65897.21,
            price_sources: [],
            processed_ms: 1711215238044,
          },
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "0665e710-e3a2-44c5-979f-f53d6b40f80c",
            price: 65844.06,
            price_sources: [],
            processed_ms: 1711215546702,
          },
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "7e742da5-cdc3-4512-8fff-8fb81765007f",
            price: 65730.03,
            price_sources: [],
            processed_ms: 1711215841630,
          },
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "4d25d71f-e144-452b-b71e-1930f995a306",
            price: 65771.39,
            price_sources: [],
            processed_ms: 1711216139208,
          },
          {
            leverage: 0.3,
            order_type: "FLAT",
            order_uuid: "b4224dce-2252-498e-90b5-40b1b4d4545b",
            price: 65703.06,
            price_sources: [],
            processed_ms: 1711216445620,
          },
        ],
        position_type: "FLAT",
        position_uuid: "9353da34-00c7-4e81-98b8-4d4e6b9558c4",
        return_at_close: 0.9953070755265965,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 65584.20800000001,
        close_ms: 1711218241509,
        current_return: 0.9994825573292571,
        initial_entry_price: 65653.65,
        is_closed_position: true,
        miner_hotkey: "5EFq8jLuMQ8nTsfSeVvSmHByFSNME9FWrPKnDbbamiXBFU4P",
        net_leverage: 0.0,
        open_ms: 1711216745020,
        orders: [
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "8983e4f4-7872-4de7-ac92-01c01235ac1a",
            price: 65653.65,
            price_sources: [],
            processed_ms: 1711216745018,
          },
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "e0ad71a4-ea30-44a2-97e5-d912efa0cc71",
            price: 65575.34,
            price_sources: [],
            processed_ms: 1711217055706,
          },
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "e7890e34-6f5c-445a-b9ab-ec318cbc79fd",
            price: 65616.79,
            price_sources: [],
            processed_ms: 1711217345525,
          },
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "71dc645a-6d3e-4117-bf85-1c4bb1a77fff",
            price: 65544.21,
            price_sources: [],
            processed_ms: 1711217642439,
          },
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "1120e42b-8318-493e-a921-cc644baa7e4c",
            price: 65531.05,
            price_sources: [],
            processed_ms: 1711217941244,
          },
          {
            leverage: 0.3,
            order_type: "FLAT",
            order_uuid: "9aa4f993-9667-456d-9511-9ddeac4833fc",
            price: 65561.56,
            price_sources: [],
            processed_ms: 1711218241509,
          },
        ],
        position_type: "FLAT",
        position_uuid: "efd165e9-59eb-4e8d-ba29-d98ea96aa089",
        return_at_close: 0.9964841096572693,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 65455.594000000005,
        close_ms: 1711220060736,
        current_return: 0.998182029346122,
        initial_entry_price: 65524.16,
        is_closed_position: true,
        miner_hotkey: "5EFq8jLuMQ8nTsfSeVvSmHByFSNME9FWrPKnDbbamiXBFU4P",
        net_leverage: 0.0,
        open_ms: 1711218548459,
        orders: [
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "c354b7f9-da3b-4641-9d71-e4b415564188",
            price: 65524.16,
            price_sources: [],
            processed_ms: 1711218548457,
          },
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "d9720175-ea41-4d3c-af9e-3f507c415c1a",
            price: 65516.97,
            price_sources: [],
            processed_ms: 1711218846696,
          },
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "6b581c98-1cf6-414f-9fb6-6171fe65ef1d",
            price: 65415.8,
            price_sources: [],
            processed_ms: 1711219144535,
          },
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "7da0ec74-eb21-42a0-b11f-250eea023125",
            price: 65472.99,
            price_sources: [],
            processed_ms: 1711219443514,
          },
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "57fb531f-299d-49ce-85c8-380f5a246845",
            price: 65348.05,
            price_sources: [],
            processed_ms: 1711219741258,
          },
          {
            leverage: 0.3,
            order_type: "FLAT",
            order_uuid: "52f5caa7-1d16-46ab-80fe-19729258fde9",
            price: 65376.18,
            price_sources: [],
            processed_ms: 1711220060736,
          },
        ],
        position_type: "FLAT",
        position_uuid: "a26eda9d-514f-4d84-93f4-34646c43bbb4",
        return_at_close: 0.9951874832580836,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 65375.458000000006,
        close_ms: 1711221850720,
        current_return: 0.9992331608561722,
        initial_entry_price: 65427.02,
        is_closed_position: true,
        miner_hotkey: "5EFq8jLuMQ8nTsfSeVvSmHByFSNME9FWrPKnDbbamiXBFU4P",
        net_leverage: 0.0,
        open_ms: 1711220351641,
        orders: [
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "a47e30ca-41a5-4234-ab82-621c93853ec8",
            price: 65427.02,
            price_sources: [],
            processed_ms: 1711220351637,
          },
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "33998356-b8cc-4b08-ac4c-7c69eef34c00",
            price: 65453.16,
            price_sources: [],
            processed_ms: 1711220649360,
          },
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "27bc1461-271d-4f40-8d99-da43af748438",
            price: 65350.25,
            price_sources: [],
            processed_ms: 1711220946515,
          },
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "cccd4083-8646-4683-88f2-10fecf384250",
            price: 65362.31,
            price_sources: [],
            processed_ms: 1711221244495,
          },
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "2fcf9b6c-4319-4cbc-bbe7-ef908ce37013",
            price: 65284.55,
            price_sources: [],
            processed_ms: 1711221542579,
          },
          {
            leverage: 0.3,
            order_type: "FLAT",
            order_uuid: "69b31dd3-e4b9-4961-9858-3aebf4201f04",
            price: 65342.01,
            price_sources: [],
            processed_ms: 1711221850720,
          },
        ],
        position_type: "FLAT",
        position_uuid: "fcc7ec3c-68ef-474e-86b9-2ff17a907da8",
        return_at_close: 0.9962354613736036,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 65014.688,
        close_ms: 1711223645158,
        current_return: 0.9983733083017189,
        initial_entry_price: 65154.94,
        is_closed_position: true,
        miner_hotkey: "5EFq8jLuMQ8nTsfSeVvSmHByFSNME9FWrPKnDbbamiXBFU4P",
        net_leverage: 0.0,
        open_ms: 1711222146836,
        orders: [
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "eca950d4-e95c-4738-90d9-f6bd8604688c",
            price: 65154.94,
            price_sources: [],
            processed_ms: 1711222146833,
          },
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "162f01ca-a504-41f3-b302-3387033f7a79",
            price: 65042.56,
            price_sources: [],
            processed_ms: 1711222442684,
          },
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "623b10a5-9635-4455-8048-f98d28407025",
            price: 65051.18,
            price_sources: [],
            processed_ms: 1711222751687,
          },
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "77c28a54-c73e-462b-bfb2-4ef30dd5050b",
            price: 64895.05,
            price_sources: [],
            processed_ms: 1711223058139,
          },
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "925bb8cd-af07-40b0-a81c-b612c23aa203",
            price: 64929.71,
            price_sources: [],
            processed_ms: 1711223348409,
          },
          {
            leverage: 0.3,
            order_type: "FLAT",
            order_uuid: "d731933e-431d-4698-8bf7-a8be854081d5",
            price: 64944.03,
            price_sources: [],
            processed_ms: 1711223645158,
          },
        ],
        position_type: "FLAT",
        position_uuid: "c39419cc-e74e-4317-ae4f-f44a0367ccde",
        return_at_close: 0.9953781883768137,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 64866.19,
        close_ms: 1711225453955,
        current_return: 1.002147405595544,
        initial_entry_price: 64934.17,
        is_closed_position: true,
        miner_hotkey: "5EFq8jLuMQ8nTsfSeVvSmHByFSNME9FWrPKnDbbamiXBFU4P",
        net_leverage: 0.0,
        open_ms: 1711223952798,
        orders: [
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "20f98b75-6579-4815-9348-c7c5d202f330",
            price: 64934.17,
            price_sources: [],
            processed_ms: 1711223952795,
          },
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "cf50976f-9ddb-4b57-90ac-32a0efc61b40",
            price: 64949.16,
            price_sources: [],
            processed_ms: 1711224251365,
          },
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "924e52e0-ba65-4126-97fe-5b531bf2075f",
            price: 64797.59,
            price_sources: [],
            processed_ms: 1711224551050,
          },
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "e1ce87cb-04d8-4648-b42a-dedf839cadcb",
            price: 64743.46,
            price_sources: [],
            processed_ms: 1711224847465,
          },
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "809f5ac9-d2cf-4474-972a-9687f5cf258c",
            price: 64906.57,
            price_sources: [],
            processed_ms: 1711225144752,
          },
          {
            leverage: 0.3,
            order_type: "FLAT",
            order_uuid: "167420a4-b72c-4d5e-92a1-3e41e952faa1",
            price: 64959.15,
            price_sources: [],
            processed_ms: 1711225453955,
          },
        ],
        position_type: "FLAT",
        position_uuid: "abbaf4e3-a780-4cad-b147-7298f5a19036",
        return_at_close: 0.9991409633787574,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 64978.798,
        close_ms: 1711227252434,
        current_return: 1.0003613318583333,
        initial_entry_price: 64976.28,
        is_closed_position: true,
        miner_hotkey: "5EFq8jLuMQ8nTsfSeVvSmHByFSNME9FWrPKnDbbamiXBFU4P",
        net_leverage: 0.0,
        open_ms: 1711225762667,
        orders: [
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "1f89554c-24e3-4854-82b3-616906e52ddd",
            price: 64976.28,
            price_sources: [],
            processed_ms: 1711225762664,
          },
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "963b0750-b0b2-4963-b569-5faf30dbdbc2",
            price: 64998.91,
            price_sources: [],
            processed_ms: 1711226052270,
          },
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "1e13d2b0-41dd-4d9c-a352-ac431723fb4b",
            price: 64986.02,
            price_sources: [],
            processed_ms: 1711226351484,
          },
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "54267db4-4e7e-4122-80dd-2b22a1ced90e",
            price: 64970.18,
            price_sources: [],
            processed_ms: 1711226650803,
          },
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "154fbc34-f71d-4c67-85e4-07a9b59dbc8e",
            price: 64962.6,
            price_sources: [],
            processed_ms: 1711226952053,
          },
          {
            leverage: 0.3,
            order_type: "FLAT",
            order_uuid: "bd68387a-0dab-4750-b87f-c4c5f2603cb3",
            price: 64994.45,
            price_sources: [],
            processed_ms: 1711227252434,
          },
        ],
        position_type: "FLAT",
        position_uuid: "f09e2a3a-9a3d-40b1-8af3-8875406d2823",
        return_at_close: 0.9973602478627583,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 64955.458000000006,
        close_ms: 1711229052438,
        current_return: 0.9999947423190585,
        initial_entry_price: 65047.69,
        is_closed_position: true,
        miner_hotkey: "5EFq8jLuMQ8nTsfSeVvSmHByFSNME9FWrPKnDbbamiXBFU4P",
        net_leverage: 0.0,
        open_ms: 1711227552602,
        orders: [
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "73a31480-2261-49b4-86d3-f06e14e4ea4a",
            price: 65047.69,
            price_sources: [],
            processed_ms: 1711227552599,
          },
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "7fea2234-c657-4531-b27a-30423da0c5b4",
            price: 64919.12,
            price_sources: [],
            processed_ms: 1711227852040,
          },
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "f789f42e-4c36-48d0-ac94-9a7b1d7cb27a",
            price: 64909.31,
            price_sources: [],
            processed_ms: 1711228148913,
          },
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "94ee18d0-64b8-4ceb-9d3b-c340d71ea6fc",
            price: 64917.0,
            price_sources: [],
            processed_ms: 1711228454280,
          },
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "b0feb738-0694-4be2-84b5-8145fdd27ad8",
            price: 64984.17,
            price_sources: [],
            processed_ms: 1711228762357,
          },
          {
            leverage: 0.3,
            order_type: "FLAT",
            order_uuid: "b2ed663a-9b84-439c-ae41-60f6a132d076",
            price: 64955.23,
            price_sources: [],
            processed_ms: 1711229052438,
          },
        ],
        position_type: "FLAT",
        position_uuid: "c0db2632-0b7a-46e5-82b3-541f94e908a2",
        return_at_close: 0.9969947580921014,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 64896.62399999999,
        close_ms: 1711230851236,
        current_return: 1.0020171207348008,
        initial_entry_price: 64901.42,
        is_closed_position: true,
        miner_hotkey: "5EFq8jLuMQ8nTsfSeVvSmHByFSNME9FWrPKnDbbamiXBFU4P",
        net_leverage: 0.0,
        open_ms: 1711229349170,
        orders: [
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "a22a2d45-6cc3-4ba7-ae1f-f24f7fbfcbd5",
            price: 64901.42,
            price_sources: [],
            processed_ms: 1711229349167,
          },
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "5fbf5fa3-224c-45bb-bcca-7fb2dfc57bbb",
            price: 64914.6,
            price_sources: [],
            processed_ms: 1711229648793,
          },
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "03f5e73c-d701-43fd-b1c9-71b435a6d077",
            price: 64848.05,
            price_sources: [],
            processed_ms: 1711229954780,
          },
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "487d5cd5-6759-4fc6-9b24-46b8f484c797",
            price: 64869.04,
            price_sources: [],
            processed_ms: 1711230253647,
          },
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "9c609cdd-8a2a-4998-85ec-af94af5aa6c5",
            price: 64950.01,
            price_sources: [],
            processed_ms: 1711230551354,
          },
          {
            leverage: 0.3,
            order_type: "FLAT",
            order_uuid: "b9a16bc7-4031-4169-bac7-ff3ded3f560b",
            price: 64983.9,
            price_sources: [],
            processed_ms: 1711230851236,
          },
        ],
        position_type: "FLAT",
        position_uuid: "fd26b827-328a-483e-9bd3-0f32beb03907",
        return_at_close: 0.9990110693725963,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 64935.816,
        close_ms: 1711232659224,
        current_return: 0.9982951010099221,
        initial_entry_price: 64944.61,
        is_closed_position: true,
        miner_hotkey: "5EFq8jLuMQ8nTsfSeVvSmHByFSNME9FWrPKnDbbamiXBFU4P",
        net_leverage: 0.0,
        open_ms: 1711231150421,
        orders: [
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "45c4a6f5-fb3f-4bad-87f4-e33dce07cc50",
            price: 64944.61,
            price_sources: [],
            processed_ms: 1711231150418,
          },
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "a51bc29c-227d-4a3d-a8ef-9f9b0d52421d",
            price: 64966.55,
            price_sources: [],
            processed_ms: 1711231472850,
          },
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "03d72471-2ad2-4b95-9eca-38fbff26b9c6",
            price: 64958.41,
            price_sources: [],
            processed_ms: 1711231752229,
          },
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "6e0dbcbe-f7ff-4a45-b129-e7e8aaef22d1",
            price: 64960.86,
            price_sources: [],
            processed_ms: 1711232051916,
          },
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "f22a1f58-0ef3-4554-805c-2d6427c9f16e",
            price: 64848.65,
            price_sources: [],
            processed_ms: 1711232358622,
          },
          {
            leverage: 0.3,
            order_type: "FLAT",
            order_uuid: "cd8049c2-6e8f-4251-adc7-96bf90a32dc6",
            price: 64862.0,
            price_sources: [],
            processed_ms: 1711232659224,
          },
        ],
        position_type: "FLAT",
        position_uuid: "c387e291-9144-4447-85bd-fb0cfe6b5fa2",
        return_at_close: 0.9953002157068924,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 64951.268000000004,
        close_ms: 1711234458111,
        current_return: 0.9982489691848629,
        initial_entry_price: 64948.6,
        is_closed_position: true,
        miner_hotkey: "5EFq8jLuMQ8nTsfSeVvSmHByFSNME9FWrPKnDbbamiXBFU4P",
        net_leverage: 0.0,
        open_ms: 1711232957264,
        orders: [
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "635048d8-adda-4d8a-8792-3bb44b22e469",
            price: 64948.6,
            price_sources: [],
            processed_ms: 1711232957261,
          },
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "8e738d62-9e14-45d9-809a-f74d4a8047c2",
            price: 65012.4,
            price_sources: [],
            processed_ms: 1711233254180,
          },
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "e2c67ae7-544e-4fad-ad57-93aec15e9df5",
            price: 65019.55,
            price_sources: [],
            processed_ms: 1711233555218,
          },
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "7f38f976-e3b4-450e-b2fc-631e6f7e0c76",
            price: 64900.25,
            price_sources: [],
            processed_ms: 1711233852349,
          },
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "8fbe12f3-a10d-4c13-8387-f1500fbcb01f",
            price: 64875.54,
            price_sources: [],
            processed_ms: 1711234159748,
          },
          {
            leverage: 0.3,
            order_type: "FLAT",
            order_uuid: "c2589eef-d26d-4242-8835-10f7d3b8edde",
            price: 64875.45,
            price_sources: [],
            processed_ms: 1711234458111,
          },
        ],
        position_type: "FLAT",
        position_uuid: "8a31618f-cdf2-408d-b2e6-6e0dab52521f",
        return_at_close: 0.9952542222773083,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 64712.21799999999,
        close_ms: 1711236253652,
        current_return: 0.9998717459015661,
        initial_entry_price: 64769.86,
        is_closed_position: true,
        miner_hotkey: "5EFq8jLuMQ8nTsfSeVvSmHByFSNME9FWrPKnDbbamiXBFU4P",
        net_leverage: 0.0,
        open_ms: 1711234778164,
        orders: [
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "afdc0eb5-7fc0-4256-a96f-62d8d6f7f67b",
            price: 64769.86,
            price_sources: [],
            processed_ms: 1711234778161,
          },
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "8ac6d467-82f7-40ef-8767-b969489463a2",
            price: 64730.09,
            price_sources: [],
            processed_ms: 1711235058129,
          },
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "17bbc6b6-ca29-4715-86ea-c17af130072f",
            price: 64777.61,
            price_sources: [],
            processed_ms: 1711235358713,
          },
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "62cbd095-ddbb-40fb-aaae-c61cd7e8f515",
            price: 64594.9,
            price_sources: [],
            processed_ms: 1711235657632,
          },
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "0df65e56-6ffd-4721-830e-0d91c18f0a51",
            price: 64688.63,
            price_sources: [],
            processed_ms: 1711235956713,
          },
          {
            leverage: 0.3,
            order_type: "FLAT",
            order_uuid: "ac3509cc-ff5b-445b-868c-800f8490374d",
            price: 64706.68,
            price_sources: [],
            processed_ms: 1711236253652,
          },
        ],
        position_type: "FLAT",
        position_uuid: "64de9118-7b21-4656-95fa-34cc7bcd52e7",
        return_at_close: 0.9968721306638614,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 64409.14400000001,
        close_ms: 1711238057773,
        current_return: 0.9970147490155304,
        initial_entry_price: 64489.05,
        is_closed_position: true,
        miner_hotkey: "5EFq8jLuMQ8nTsfSeVvSmHByFSNME9FWrPKnDbbamiXBFU4P",
        net_leverage: 0.0,
        open_ms: 1711236562866,
        orders: [
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "f862175b-7f55-4428-bcb7-e3525fe1b094",
            price: 64489.05,
            price_sources: [],
            processed_ms: 1711236562862,
          },
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "e5bc7316-6f69-40c0-b9e0-adfcd8a07785",
            price: 64453.25,
            price_sources: [],
            processed_ms: 1711236859424,
          },
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "7c8c7471-93a0-4794-9144-ab1877844bb8",
            price: 64433.11,
            price_sources: [],
            processed_ms: 1711237157231,
          },
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "0ed41c37-1266-4b68-8157-65bb1d23a2fe",
            price: 64346.63,
            price_sources: [],
            processed_ms: 1711237456889,
          },
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "517967f5-ce92-42e3-9016-dd3e2039cd1e",
            price: 64323.68,
            price_sources: [],
            processed_ms: 1711237777829,
          },
          {
            leverage: 0.3,
            order_type: "FLAT",
            order_uuid: "45a36b3a-733d-4e7a-a2be-b935255e3b96",
            price: 64280.8,
            price_sources: [],
            processed_ms: 1711238057773,
          },
        ],
        position_type: "FLAT",
        position_uuid: "5e2aef81-73bb-4d7c-aad1-56a2abf168f8",
        return_at_close: 0.9940237047684839,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 64195.074,
        close_ms: 1711239866105,
        current_return: 1.004296541458394,
        initial_entry_price: 64096.67,
        is_closed_position: true,
        miner_hotkey: "5EFq8jLuMQ8nTsfSeVvSmHByFSNME9FWrPKnDbbamiXBFU4P",
        net_leverage: 0.0,
        open_ms: 1711238355695,
        orders: [
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "95f9d78b-bdad-4961-b4fa-20a005881521",
            price: 64096.67,
            price_sources: [],
            processed_ms: 1711238355691,
          },
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "b35ac05b-daae-4805-938e-5570c078c649",
            price: 64108.76,
            price_sources: [],
            processed_ms: 1711238663938,
          },
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "3cb43a7f-7da5-4f84-a9fb-8b6e83d06c3c",
            price: 64272.11,
            price_sources: [],
            processed_ms: 1711238965916,
          },
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "a9759c9b-a64b-4061-82af-0d5e89b48c2e",
            price: 64275.86,
            price_sources: [],
            processed_ms: 1711239259073,
          },
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "f61ec755-c240-4b47-bcc2-6f73cc536362",
            price: 64221.97,
            price_sources: [],
            processed_ms: 1711239565811,
          },
          {
            leverage: 0.3,
            order_type: "FLAT",
            order_uuid: "0f12d587-0911-4560-8a76-5d2c3267b2d0",
            price: 64378.67,
            price_sources: [],
            processed_ms: 1711239866105,
          },
        ],
        position_type: "FLAT",
        position_uuid: "61e8c2b6-2bf9-43a0-acb8-badf482a6d3a",
        return_at_close: 1.0012836518340187,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 64467.978,
        close_ms: 1711241663272,
        current_return: 1.0023754870204251,
        initial_entry_price: 64383.85,
        is_closed_position: true,
        miner_hotkey: "5EFq8jLuMQ8nTsfSeVvSmHByFSNME9FWrPKnDbbamiXBFU4P",
        net_leverage: 0.0,
        open_ms: 1711240167092,
        orders: [
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "b38019d0-e35d-4c4a-8f3f-60a983f090db",
            price: 64383.85,
            price_sources: [],
            processed_ms: 1711240167087,
          },
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "476e052d-ae6b-4e12-a72d-a5e641a05f64",
            price: 64398.72,
            price_sources: [],
            processed_ms: 1711240457933,
          },
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "18ad4226-a022-48f6-b1b6-bfae9882b4e7",
            price: 64445.41,
            price_sources: [],
            processed_ms: 1711240767323,
          },
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "c852d2d6-0e3d-4cfc-a186-d7b3d2d05bba",
            price: 64532.98,
            price_sources: [],
            processed_ms: 1711241066059,
          },
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "29b59dd7-88aa-4d78-be68-e54cf66611ea",
            price: 64578.93,
            price_sources: [],
            processed_ms: 1711241363599,
          },
          {
            leverage: 0.3,
            order_type: "FLAT",
            order_uuid: "5bed5a7d-f8f9-422a-addb-7b17c9512068",
            price: 64569.94,
            price_sources: [],
            processed_ms: 1711241663272,
          },
        ],
        position_type: "FLAT",
        position_uuid: "accb9665-9ba6-4981-a251-2fb08e742916",
        return_at_close: 0.9993683605593638,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 64469.856,
        close_ms: 1711243462038,
        current_return: 1.0005043871195534,
        initial_entry_price: 64575.4,
        is_closed_position: true,
        miner_hotkey: "5EFq8jLuMQ8nTsfSeVvSmHByFSNME9FWrPKnDbbamiXBFU4P",
        net_leverage: 0.0,
        open_ms: 1711241960489,
        orders: [
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "80a0d787-1b76-467b-996b-ce054737dd81",
            price: 64575.4,
            price_sources: [],
            processed_ms: 1711241960485,
          },
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "ab116ff6-9394-4ba5-bccd-a1412e0cc94f",
            price: 64503.74,
            price_sources: [],
            processed_ms: 1711242264714,
          },
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "da3ed952-e89b-40b7-88d9-2976cdf4687e",
            price: 64466.21,
            price_sources: [],
            processed_ms: 1711242561602,
          },
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "5dfc3278-b4cb-497e-979c-d1c072f56076",
            price: 64402.82,
            price_sources: [],
            processed_ms: 1711242864623,
          },
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "384f14ce-2443-4df2-ae96-21a4ffa569cb",
            price: 64401.11,
            price_sources: [],
            processed_ms: 1711243182870,
          },
          {
            leverage: 0.3,
            order_type: "FLAT",
            order_uuid: "0b76e27f-8e72-46ad-98a3-8aa0610097b4",
            price: 64491.57,
            price_sources: [],
            processed_ms: 1711243462038,
          },
        ],
        position_type: "FLAT",
        position_uuid: "4b0a27d7-8327-4fa0-97b8-289ad5dae7e0",
        return_at_close: 0.9975028739581947,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 64536.119999999995,
        close_ms: 1711245261492,
        current_return: 1.0001619962643384,
        initial_entry_price: 64631.12,
        is_closed_position: true,
        miner_hotkey: "5EFq8jLuMQ8nTsfSeVvSmHByFSNME9FWrPKnDbbamiXBFU4P",
        net_leverage: 0.0,
        open_ms: 1711243764697,
        orders: [
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "f251dd5b-9d12-45db-9169-0625f05b88cd",
            price: 64631.12,
            price_sources: [],
            processed_ms: 1711243764693,
          },
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "c2ae8c6c-974b-4368-b4e9-260ef9fdd0a8",
            price: 64562.75,
            price_sources: [],
            processed_ms: 1711244061616,
          },
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "c9bb572f-3912-4b93-b651-c57af26d896b",
            price: 64513.69,
            price_sources: [],
            processed_ms: 1711244366317,
          },
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "39594f7f-472f-4e1e-9897-f1d5edeb83b0",
            price: 64468.43,
            price_sources: [],
            processed_ms: 1711244664441,
          },
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "e4bd78ff-a5c7-4d43-8416-c4e5b069b049",
            price: 64504.61,
            price_sources: [],
            processed_ms: 1711244962505,
          },
          {
            leverage: 0.3,
            order_type: "FLAT",
            order_uuid: "80746d9e-686b-4347-bdc7-512efc54dd34",
            price: 64543.1,
            price_sources: [],
            processed_ms: 1711245261492,
          },
        ],
        position_type: "FLAT",
        position_uuid: "49d782bc-58f3-471c-9cbe-b9c89228d5ce",
        return_at_close: 0.9971615102755454,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 64426.541999999994,
        close_ms: 1711247068520,
        current_return: 0.9986802409707654,
        initial_entry_price: 64514.05,
        is_closed_position: true,
        miner_hotkey: "5EFq8jLuMQ8nTsfSeVvSmHByFSNME9FWrPKnDbbamiXBFU4P",
        net_leverage: 0.0,
        open_ms: 1711245570794,
        orders: [
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "daeb68d8-28e0-4c69-822b-6edbc2d10db7",
            price: 64514.05,
            price_sources: [],
            processed_ms: 1711245570789,
          },
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "2056303c-b048-4486-aa58-af3062a7e2ff",
            price: 64320.83,
            price_sources: [],
            processed_ms: 1711245863839,
          },
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "055c88b2-ab06-42ba-932e-ac89d72b9851",
            price: 64465.0,
            price_sources: [],
            processed_ms: 1711246163806,
          },
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "c75b5234-9fbc-4380-ac76-e2625411b48c",
            price: 64470.35,
            price_sources: [],
            processed_ms: 1711246463156,
          },
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "5a711557-aff6-4a06-bb89-eeed12d21e54",
            price: 64362.48,
            price_sources: [],
            processed_ms: 1711246771531,
          },
          {
            leverage: 0.3,
            order_type: "FLAT",
            order_uuid: "d41aa06c-638d-4330-8214-1a21c7a12007",
            price: 64369.78,
            price_sources: [],
            processed_ms: 1711247068520,
          },
        ],
        position_type: "FLAT",
        position_uuid: "72dec8d5-b5ce-4741-bace-e953e9b77ec7",
        return_at_close: 0.9956842002478531,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 64315.212,
        close_ms: 1711248891593,
        current_return: 0.9976383126160269,
        initial_entry_price: 64290.05,
        is_closed_position: true,
        miner_hotkey: "5EFq8jLuMQ8nTsfSeVvSmHByFSNME9FWrPKnDbbamiXBFU4P",
        net_leverage: 0.0,
        open_ms: 1711247368791,
        orders: [
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "61f25dcb-3374-4018-9446-f33e1be715b8",
            price: 64290.05,
            price_sources: [],
            processed_ms: 1711247368787,
          },
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "75f70273-a5dd-4712-88b6-e269e4cb644c",
            price: 64313.32,
            price_sources: [],
            processed_ms: 1711247665792,
          },
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "3c14b6f5-a0dd-485e-a854-23fe861eb0f0",
            price: 64369.41,
            price_sources: [],
            processed_ms: 1711247966753,
          },
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "e0afa603-eb40-454e-86bb-742984a4574a",
            price: 64359.65,
            price_sources: [],
            processed_ms: 1711248268355,
          },
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "3dbbbb5e-096f-4874-b64d-e0146cf2d81f",
            price: 64243.63,
            price_sources: [],
            processed_ms: 1711248572596,
          },
          {
            leverage: 0.3,
            order_type: "FLAT",
            order_uuid: "b5fc459b-78e6-4d2b-bdc2-567547b25e82",
            price: 64213.99,
            price_sources: [],
            processed_ms: 1711248891593,
          },
        ],
        position_type: "FLAT",
        position_uuid: "0b104895-f613-48a9-9ee1-f23f4fceed90",
        return_at_close: 0.9946453976781788,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
    ],
    thirty_day_returns: 1.0,
  },
  "5EHpm2UK3CyhH1zZiJmM6erGrzkmVAF9EnT1QLSPhMzQaQHG": {
    all_time_returns: 1.0122739447378977,
    n_positions: 53,
    percentage_profitable: 0.7169811320754716,
    positions: [
      {
        average_entry_price: 157.0855,
        close_ms: 1716565787035,
        current_return: 1.0006095406641606,
        initial_entry_price: 157.0855,
        is_closed_position: true,
        miner_hotkey: "5EHpm2UK3CyhH1zZiJmM6erGrzkmVAF9EnT1QLSPhMzQaQHG",
        net_leverage: 0.0,
        open_ms: 1716472629110,
        orders: [
          {
            leverage: -0.5,
            order_type: "SHORT",
            order_uuid: "8b016801-7c13-4ce3-a4ff-d1a618f6f9c1",
            price: 157.0855,
            price_sources: [],
            processed_ms: 1716472629109,
          },
          {
            leverage: 0.01,
            order_type: "FLAT",
            order_uuid: "18fbea1d-5f8a-46c4-90e0-a28090a0c905",
            price: 156.894,
            price_sources: [],
            processed_ms: 1716565787035,
          },
        ],
        position_type: "FLAT",
        position_uuid: "8ff6140e-19b9-419f-b333-5d2fc8c974b1",
        return_at_close: 1.0005745193302373,
        trade_pair: ["USDJPY", "USD/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 1.27717,
        close_ms: 1716885516023,
        current_return: 1.000638129614695,
        initial_entry_price: 1.27717,
        is_closed_position: true,
        miner_hotkey: "5EHpm2UK3CyhH1zZiJmM6erGrzkmVAF9EnT1QLSPhMzQaQHG",
        net_leverage: 0.0,
        open_ms: 1716884105474,
        orders: [
          {
            leverage: -1.0,
            order_type: "SHORT",
            order_uuid: "29ac288e-a7e9-402a-b5ff-fe5f4225f715",
            price: 1.27717,
            price_sources: [],
            processed_ms: 1716884105465,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "d96a78a6-4115-4ce6-b868-a46a116a4cf9",
            price: 1.2763550000000001,
            price_sources: [],
            processed_ms: 1716885516023,
          },
        ],
        position_type: "FLAT",
        position_uuid: "bf1431a2-464e-4c2e-8f83-d9f9ed179624",
        return_at_close: 1.0005680849456218,
        trade_pair: ["GBPUSD", "GBP/USD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.91153,
        close_ms: 1716921306008,
        current_return: 1.0007076015051617,
        initial_entry_price: 0.91153,
        is_closed_position: true,
        miner_hotkey: "5EHpm2UK3CyhH1zZiJmM6erGrzkmVAF9EnT1QLSPhMzQaQHG",
        net_leverage: 0.0,
        open_ms: 1716883221355,
        orders: [
          {
            leverage: 1.0,
            order_type: "LONG",
            order_uuid: "5820e4d3-d0a7-428a-ac73-fa9408b0202c",
            price: 0.91153,
            price_sources: [],
            processed_ms: 1716883221113,
          },
          {
            leverage: 0.01,
            order_type: "FLAT",
            order_uuid: "110c1ed3-bcab-47b6-a409-ae9d1438565a",
            price: 0.912175,
            price_sources: [],
            processed_ms: 1716921306008,
          },
        ],
        position_type: "FLAT",
        position_uuid: "24816cff-bd5a-4991-bc4d-1ac9bb2a54e2",
        return_at_close: 1.0006375519730564,
        trade_pair: ["USDCHF", "USD/CHF", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 1.08745,
        close_ms: 1716921686338,
        current_return: 1.0030526068987078,
        initial_entry_price: 1.0875949999999999,
        is_closed_position: true,
        miner_hotkey: "5EHpm2UK3CyhH1zZiJmM6erGrzkmVAF9EnT1QLSPhMzQaQHG",
        net_leverage: 0.0,
        open_ms: 1716884070235,
        orders: [
          {
            leverage: -1.0,
            order_type: "SHORT",
            order_uuid: "4a3d7eb3-c61a-4b17-9e1e-9c96f49a0d6a",
            price: 1.0875949999999999,
            price_sources: [],
            processed_ms: 1716884070233,
          },
          {
            leverage: -1.0,
            order_type: "SHORT",
            order_uuid: "2e7e6b7e-4fd4-4904-9749-b8cf0e6538f1",
            price: 1.087305,
            price_sources: [],
            processed_ms: 1716907749530,
          },
          {
            leverage: 0.01,
            order_type: "FLAT",
            order_uuid: "37538b31-4c8f-4710-b5a4-370f226db0fc",
            price: 1.08579,
            price_sources: [],
            processed_ms: 1716921686338,
          },
        ],
        position_type: "FLAT",
        position_uuid: "520d10fb-4f2c-423e-8bce-2ede69e0301f",
        return_at_close: 1.002912179533742,
        trade_pair: ["EURUSD", "EUR/USD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.666255,
        close_ms: 1716960404001,
        current_return: 1.0025065477932624,
        initial_entry_price: 0.666255,
        is_closed_position: true,
        miner_hotkey: "5EHpm2UK3CyhH1zZiJmM6erGrzkmVAF9EnT1QLSPhMzQaQHG",
        net_leverage: 0.0,
        open_ms: 1716883296751,
        orders: [
          {
            leverage: -1.0,
            order_type: "SHORT",
            order_uuid: "798244e6-6452-4390-a9ce-6b71656ba055",
            price: 0.666255,
            price_sources: [],
            processed_ms: 1716883296439,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "60ffac96-e072-44f7-8719-bcae7003b998",
            price: 0.664585,
            price_sources: [],
            processed_ms: 1716960404001,
          },
        ],
        position_type: "FLAT",
        position_uuid: "a234c310-0b4e-4f7a-8013-c0a286e7be40",
        return_at_close: 1.0024363723349168,
        trade_pair: ["AUDUSD", "AUD/USD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 1.3623,
        close_ms: 1717005467457,
        current_return: 1.0059091242751228,
        initial_entry_price: 1.3623,
        is_closed_position: true,
        miner_hotkey: "5EHpm2UK3CyhH1zZiJmM6erGrzkmVAF9EnT1QLSPhMzQaQHG",
        net_leverage: 0.0,
        open_ms: 1716883328250,
        orders: [
          {
            leverage: 1.0,
            order_type: "LONG",
            order_uuid: "8b47b954-7232-4882-88aa-b7dfc28d5fa0",
            price: 1.3623,
            price_sources: [],
            processed_ms: 1716883327439,
          },
          {
            leverage: 0.01,
            order_type: "FLAT",
            order_uuid: "8c1be2cb-79b9-45eb-926f-bc8bc6800fcf",
            price: 1.37035,
            price_sources: [],
            processed_ms: 1717005467457,
          },
        ],
        position_type: "FLAT",
        position_uuid: "5cd84a71-8438-4bc6-ad7e-ba6732336244",
        return_at_close: 1.0058387106364235,
        trade_pair: ["USDCAD", "USD/CAD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.90701,
        close_ms: 1717086877336,
        current_return: 1.0006615142060176,
        initial_entry_price: 0.90701,
        is_closed_position: true,
        miner_hotkey: "5EHpm2UK3CyhH1zZiJmM6erGrzkmVAF9EnT1QLSPhMzQaQHG",
        net_leverage: 0.0,
        open_ms: 1717005653615,
        orders: [
          {
            leverage: 1.0,
            order_type: "LONG",
            order_uuid: "86f29e9f-c6ef-41ec-92af-47cfb13ca13e",
            price: 0.90701,
            price_sources: [],
            processed_ms: 1717005652396,
          },
          {
            leverage: 0.01,
            order_type: "FLAT",
            order_uuid: "508c535c-cf38-43c9-918f-e9880a1c7222",
            price: 0.90761,
            price_sources: [],
            processed_ms: 1717086877336,
          },
        ],
        position_type: "FLAT",
        position_uuid: "4c94bcd9-d45b-4d5c-82c9-28473f0659d7",
        return_at_close: 1.0005914679000232,
        trade_pair: ["AUDCAD", "AUD/CAD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.838556516844308,
        close_ms: 1717160205574,
        current_return: 1.000597652788344,
        initial_entry_price: 0.838556516844308,
        is_closed_position: true,
        miner_hotkey: "5EHpm2UK3CyhH1zZiJmM6erGrzkmVAF9EnT1QLSPhMzQaQHG",
        net_leverage: 0.0,
        open_ms: 1717005557180,
        orders: [
          {
            leverage: 1.0,
            order_type: "LONG",
            order_uuid: "19ecdde5-9365-4206-bde0-f49dfe97f672",
            price: 0.838556516844308,
            price_sources: [],
            processed_ms: 1717005556725,
          },
          {
            leverage: 0.01,
            order_type: "FLAT",
            order_uuid: "f29aac36-69bb-48da-972d-50366c9777b1",
            price: 0.839057682484784,
            price_sources: [],
            processed_ms: 1717160205574,
          },
        ],
        position_type: "FLAT",
        position_uuid: "7e2a9c76-af97-44eb-8123-5c8bfe11500b",
        return_at_close: 1.0005276109526489,
        trade_pair: ["NZDCAD", "NZD/CAD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 199.742,
        close_ms: 1717414106530,
        current_return: 1.0006558460413932,
        initial_entry_price: 199.742,
        is_closed_position: true,
        miner_hotkey: "5EHpm2UK3CyhH1zZiJmM6erGrzkmVAF9EnT1QLSPhMzQaQHG",
        net_leverage: 0.0,
        open_ms: 1716472752433,
        orders: [
          {
            leverage: -0.5,
            order_type: "SHORT",
            order_uuid: "894433bc-b106-47b3-a4b5-ba8535096b4d",
            price: 199.742,
            price_sources: [],
            processed_ms: 1716472752431,
          },
          {
            leverage: 0.01,
            order_type: "FLAT",
            order_uuid: "816cced4-47ea-477f-a06e-d722e6f08b6c",
            price: 199.48000000000002,
            price_sources: [],
            processed_ms: 1717414106530,
          },
        ],
        position_type: "FLAT",
        position_uuid: "5be36bc2-8041-4a53-9416-ac3dfca646e9",
        return_at_close: 1.0006208230867817,
        trade_pair: ["GBPJPY", "GBP/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 170.187,
        close_ms: 1717414212947,
        current_return: 1.0010194668217902,
        initial_entry_price: 170.187,
        is_closed_position: true,
        miner_hotkey: "5EHpm2UK3CyhH1zZiJmM6erGrzkmVAF9EnT1QLSPhMzQaQHG",
        net_leverage: 0.0,
        open_ms: 1716472690362,
        orders: [
          {
            leverage: -0.5,
            order_type: "SHORT",
            order_uuid: "105259a0-31c7-443a-a49a-02640ff11600",
            price: 170.187,
            price_sources: [],
            processed_ms: 1716472690360,
          },
          {
            leverage: 0.01,
            order_type: "FLAT",
            order_uuid: "8e62a007-9f1b-4712-8b70-ca681b345727",
            price: 169.84,
            price_sources: [],
            processed_ms: 1717414212947,
          },
        ],
        position_type: "FLAT",
        position_uuid: "7f400ded-4797-4d3b-9411-661fb8dfbd84",
        return_at_close: 1.0009844311404514,
        trade_pair: ["EURJPY", "EUR/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 95.967,
        close_ms: 1717508830168,
        current_return: 1.0020137130471933,
        initial_entry_price: 95.967,
        is_closed_position: true,
        miner_hotkey: "5EHpm2UK3CyhH1zZiJmM6erGrzkmVAF9EnT1QLSPhMzQaQHG",
        net_leverage: 0.0,
        open_ms: 1716472721308,
        orders: [
          {
            leverage: -0.5,
            order_type: "SHORT",
            order_uuid: "5d3c4f8c-9054-4642-aebf-412bba916ab1",
            price: 95.967,
            price_sources: [],
            processed_ms: 1716472721304,
          },
          {
            leverage: 0.5,
            order_type: "FLAT",
            order_uuid: "833b36cd-d16a-4cee-b4f7-1740a4c25b59",
            price: 95.5805,
            price_sources: [],
            processed_ms: 1717508830168,
          },
        ],
        position_type: "FLAT",
        position_uuid: "a2c72653-cfba-424e-8c31-ab4ab30e282d",
        return_at_close: 1.0019786425672366,
        trade_pair: ["NZDJPY", "NZD/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 1.088165,
        close_ms: 1717508960067,
        current_return: 1.0005307099566703,
        initial_entry_price: 1.088165,
        is_closed_position: true,
        miner_hotkey: "5EHpm2UK3CyhH1zZiJmM6erGrzkmVAF9EnT1QLSPhMzQaQHG",
        net_leverage: 0.0,
        open_ms: 1717160332363,
        orders: [
          {
            leverage: -0.5,
            order_type: "SHORT",
            order_uuid: "4aa8c9f5-6acd-4c70-bba1-c1c4ff90cb1d",
            price: 1.088165,
            price_sources: [],
            processed_ms: 1717160330630,
          },
          {
            leverage: 0.5,
            order_type: "FLAT",
            order_uuid: "d5c9145c-dacb-43d6-8cd5-41bb13436db6",
            price: 1.08701,
            price_sources: [],
            processed_ms: 1717508960067,
          },
        ],
        position_type: "FLAT",
        position_uuid: "45666b34-24e0-4b15-841f-7832ec695567",
        return_at_close: 1.0004956913818217,
        trade_pair: ["EURUSD", "EUR/USD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 114.793,
        close_ms: 1717618869438,
        current_return: 1.003379997038147,
        initial_entry_price: 114.793,
        is_closed_position: true,
        miner_hotkey: "5EHpm2UK3CyhH1zZiJmM6erGrzkmVAF9EnT1QLSPhMzQaQHG",
        net_leverage: 0.0,
        open_ms: 1716472659823,
        orders: [
          {
            leverage: -0.5,
            order_type: "SHORT",
            order_uuid: "d66df519-48d2-4859-a8bb-1da7c02af266",
            price: 114.793,
            price_sources: [],
            processed_ms: 1716472659821,
          },
          {
            leverage: 0.01,
            order_type: "FLAT",
            order_uuid: "9514e28d-ae3f-4357-a49d-816d48dc378b",
            price: 114.017,
            price_sources: [],
            processed_ms: 1717618869438,
          },
        ],
        position_type: "FLAT",
        position_uuid: "fe9df68e-bab2-411d-a997-01cd36931084",
        return_at_close: 1.0033448787382506,
        trade_pair: ["CADJPY", "CAD/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 104.13,
        close_ms: 1717650966919,
        current_return: 1.0015653510035532,
        initial_entry_price: 104.13,
        is_closed_position: true,
        miner_hotkey: "5EHpm2UK3CyhH1zZiJmM6erGrzkmVAF9EnT1QLSPhMzQaQHG",
        net_leverage: 0.0,
        open_ms: 1716472829098,
        orders: [
          {
            leverage: -0.5,
            order_type: "SHORT",
            order_uuid: "1d75405c-735f-46a1-a865-2a588738a327",
            price: 104.13,
            price_sources: [],
            processed_ms: 1716472829096,
          },
          {
            leverage: 0.01,
            order_type: "FLAT",
            order_uuid: "ed9ca919-905b-49db-9813-7e15ac0eb358",
            price: 103.804,
            price_sources: [],
            processed_ms: 1717650966919,
          },
        ],
        position_type: "FLAT",
        position_uuid: "65ab02d0-c200-47e2-954c-622654230ac6",
        return_at_close: 1.001530296216268,
        trade_pair: ["AUDJPY", "AUD/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 1.08715,
        close_ms: 1717771778354,
        current_return: 1.0027043186312836,
        initial_entry_price: 1.08715,
        is_closed_position: true,
        miner_hotkey: "5EHpm2UK3CyhH1zZiJmM6erGrzkmVAF9EnT1QLSPhMzQaQHG",
        net_leverage: 0.0,
        open_ms: 1717573924352,
        orders: [
          {
            leverage: -0.5,
            order_type: "SHORT",
            order_uuid: "ef4c04db-9b29-43ab-b35a-9b1671b8cbf3",
            price: 1.08715,
            price_sources: [],
            processed_ms: 1717573923704,
          },
          {
            leverage: 0.5,
            order_type: "FLAT",
            order_uuid: "33a549ba-ed4b-4f17-bfcf-4c9c5ec6d176",
            price: 1.08127,
            price_sources: [],
            processed_ms: 1717771778354,
          },
        ],
        position_type: "FLAT",
        position_uuid: "276c7ce1-4940-4eac-b803-5b3a25e60062",
        return_at_close: 1.0026692239801316,
        trade_pair: ["EURUSD", "EUR/USD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 68250.77999999996,
        close_ms: 1717967001281,
        current_return: 1.001060991837325,
        initial_entry_price: 69273.86,
        is_closed_position: true,
        miner_hotkey: "5EHpm2UK3CyhH1zZiJmM6erGrzkmVAF9EnT1QLSPhMzQaQHG",
        net_leverage: 0.0,
        open_ms: 1717826258494,
        orders: [
          {
            leverage: 0.1,
            order_type: "LONG",
            order_uuid: "c1dcae97-d132-4506-b02b-925e392f7541",
            price: 69273.86,
            price_sources: [],
            processed_ms: 1717826258045,
          },
          {
            leverage: 0.15,
            order_type: "LONG",
            order_uuid: "978b5c63-b1cb-42be-bb8c-e0dc758feb3c",
            price: 69499.9,
            price_sources: [],
            processed_ms: 1717832539573,
          },
          {
            leverage: -0.2,
            order_type: "SHORT",
            order_uuid: "abcfae5e-d3a9-4888-8bdd-5bb0b552908b",
            price: 69699.16,
            price_sources: [],
            processed_ms: 1717962813828,
          },
          {
            leverage: 0.01,
            order_type: "FLAT",
            order_uuid: "e37e22ea-bd5b-493c-9bf2-d6542d585b8f",
            price: 69720.76,
            price_sources: [],
            processed_ms: 1717967001281,
          },
        ],
        position_type: "FLAT",
        position_uuid: "aebf8fcb-1c51-4aae-82ac-5cd6ef94ea6c",
        return_at_close: 1.0008107265893658,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 0.6730600000000001,
        close_ms: 1717967086715,
        current_return: 1.0045849754806102,
        initial_entry_price: 0.66478,
        is_closed_position: true,
        miner_hotkey: "5EHpm2UK3CyhH1zZiJmM6erGrzkmVAF9EnT1QLSPhMzQaQHG",
        net_leverage: 0.0,
        open_ms: 1717619156888,
        orders: [
          {
            leverage: -0.5,
            order_type: "SHORT",
            order_uuid: "690ff594-b881-4d49-a3ad-af0256dbd19b",
            price: 0.66478,
            price_sources: [],
            processed_ms: 1717619156885,
          },
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "e11bf895-1e7e-476a-86c2-7c2da64dcb64",
            price: 0.65926,
            price_sources: [],
            processed_ms: 1717771809335,
          },
          {
            leverage: 0.01,
            order_type: "FLAT",
            order_uuid: "ade15292-7573-47cf-b67e-052d3a2bb915",
            price: 0.65782,
            price_sources: [],
            processed_ms: 1717967086715,
          },
        ],
        position_type: "FLAT",
        position_uuid: "6d6edf8f-0072-4c7e-8ef9-8333eb54f6f9",
        return_at_close: 1.0045498150064684,
        trade_pair: ["AUDUSD", "AUD/USD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.6537725000000001,
        close_ms: 1718020013328,
        current_return: 1.0009298367994603,
        initial_entry_price: 0.652265,
        is_closed_position: true,
        miner_hotkey: "5EHpm2UK3CyhH1zZiJmM6erGrzkmVAF9EnT1QLSPhMzQaQHG",
        net_leverage: 0.0,
        open_ms: 1718001735928,
        orders: [
          {
            leverage: -0.5,
            order_type: "SHORT",
            order_uuid: "1c1fbcb7-71ee-4fe7-9292-297b030fcf9f",
            price: 0.652265,
            price_sources: [],
            processed_ms: 1718001735924,
          },
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "1562566d-898f-4839-89d1-79d96d77e70f",
            price: 0.65126,
            price_sources: [],
            processed_ms: 1718007291807,
          },
          {
            leverage: 0.01,
            order_type: "FLAT",
            order_uuid: "d5b8bc31-a014-4665-a758-f1715a2f5934",
            price: 0.65074,
            price_sources: [],
            processed_ms: 1718020013328,
          },
        ],
        position_type: "FLAT",
        position_uuid: "a1e4aa6b-d579-4b16-be00-7a6948aa5bc9",
        return_at_close: 1.0008948042551724,
        trade_pair: ["CADCHF", "CAD/CHF", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 21.22900000000028,
        close_ms: 1718129685813,
        current_return: 0.9910475486372262,
        initial_entry_price: 171.782,
        is_closed_position: true,
        miner_hotkey: "5EHpm2UK3CyhH1zZiJmM6erGrzkmVAF9EnT1QLSPhMzQaQHG",
        net_leverage: 0.0,
        open_ms: 1716472783371,
        orders: [
          {
            leverage: -0.5,
            order_type: "SHORT",
            order_uuid: "c9bb9abc-ed91-480d-9e10-ade8b934ff5b",
            price: 171.782,
            price_sources: [],
            processed_ms: 1716472783369,
          },
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "3e241471-1b2c-40b5-85b4-17ae57868d8b",
            price: 174.744,
            price_sources: [],
            processed_ms: 1717967214035,
          },
          {
            leverage: 0.19,
            order_type: "LONG",
            order_uuid: "3b0bb073-1ce4-43fd-adcd-e9eb8f090464",
            price: 175.029,
            price_sources: [],
            processed_ms: 1718006634913,
          },
          {
            leverage: 0.01,
            order_type: "FLAT",
            order_uuid: "1752cb26-bc8b-4ace-b67c-10623b67aa90",
            price: 175.016,
            price_sources: [],
            processed_ms: 1718129685813,
          },
        ],
        position_type: "FLAT",
        position_uuid: "919c3bfb-1c2f-4dd8-adc2-750398136a53",
        return_at_close: 0.991012861973024,
        trade_pair: ["CHFJPY", "CHF/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 199.19025742574257,
        close_ms: 1718189494789,
        current_return: 0.992030747608744,
        initial_entry_price: 197.804,
        is_closed_position: true,
        miner_hotkey: "5EHpm2UK3CyhH1zZiJmM6erGrzkmVAF9EnT1QLSPhMzQaQHG",
        net_leverage: 0.0,
        open_ms: 1717509008926,
        orders: [
          {
            leverage: -0.5,
            order_type: "SHORT",
            order_uuid: "63248095-4fbf-481e-8539-b86f160a5c3d",
            price: 197.804,
            price_sources: [],
            processed_ms: 1717509008896,
          },
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "3588efa9-d34f-4860-822b-9ea49cf43a69",
            price: 199.761,
            price_sources: [],
            processed_ms: 1718001832061,
          },
          {
            leverage: 0.19,
            order_type: "LONG",
            order_uuid: "8bbb9de2-062a-4354-b7cd-2eb864d9aacc",
            price: 199.766,
            price_sources: [],
            processed_ms: 1718006554865,
          },
          {
            leverage: -1.0,
            order_type: "SHORT",
            order_uuid: "5d5d2bdd-c74f-456e-8904-447fab67b999",
            price: 200.164,
            price_sources: [],
            processed_ms: 1718121549192,
          },
          {
            leverage: 0.01,
            order_type: "FLAT",
            order_uuid: "938f1726-7632-4a79-a99e-4c33ebf512b1",
            price: 200.751,
            price_sources: [],
            processed_ms: 1718189494789,
          },
        ],
        position_type: "FLAT",
        position_uuid: "ce3ec7ee-8310-4365-8370-743a89603bf1",
        return_at_close: 0.9919265843802451,
        trade_pair: ["GBPJPY", "GBP/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.6167925000000001,
        close_ms: 1718195933940,
        current_return: 1.0051134070546452,
        initial_entry_price: 0.6194500000000001,
        is_closed_position: true,
        miner_hotkey: "5EHpm2UK3CyhH1zZiJmM6erGrzkmVAF9EnT1QLSPhMzQaQHG",
        net_leverage: 0.0,
        open_ms: 1717743658368,
        orders: [
          {
            leverage: 0.5,
            order_type: "LONG",
            order_uuid: "d523e6bf-9853-426c-9546-fe8742675329",
            price: 0.6194500000000001,
            price_sources: [],
            processed_ms: 1717743658365,
          },
          {
            leverage: -0.3,
            order_type: "SHORT",
            order_uuid: "44a6b612-a74f-457b-b760-d8388ac36a87",
            price: 0.61027,
            price_sources: [],
            processed_ms: 1717967317280,
          },
          {
            leverage: 0.2,
            order_type: "LONG",
            order_uuid: "0e1e713a-648d-4062-9898-c207ec9917cf",
            price: 0.6115,
            price_sources: [],
            processed_ms: 1718021802796,
          },
          {
            leverage: -0.1,
            order_type: "SHORT",
            order_uuid: "c8989ca9-2139-4931-8696-a8fd0e6912da",
            price: 0.6121650000000001,
            price_sources: [],
            processed_ms: 1718042626903,
          },
          {
            leverage: 0.7,
            order_type: "LONG",
            order_uuid: "b46f1879-d7d8-4161-b76c-faa34c5d420a",
            price: 0.61295,
            price_sources: [],
            processed_ms: 1718088917963,
          },
          {
            leverage: 0.5,
            order_type: "FLAT",
            order_uuid: "b0664ab3-84c6-420d-9911-027bddbf29a8",
            price: 0.6199600000000001,
            price_sources: [],
            processed_ms: 1718195933940,
          },
        ],
        position_type: "FLAT",
        position_uuid: "48713681-22d8-49ef-b3b5-2561976457f2",
        return_at_close: 1.0050149059407538,
        trade_pair: ["NZDUSD", "NZD/USD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 155.0783725490196,
        close_ms: 1718196096477,
        current_return: 0.9954938987177459,
        initial_entry_price: 154.84449999999998,
        is_closed_position: true,
        miner_hotkey: "5EHpm2UK3CyhH1zZiJmM6erGrzkmVAF9EnT1QLSPhMzQaQHG",
        net_leverage: 0.0,
        open_ms: 1717508886627,
        orders: [
          {
            leverage: -0.5,
            order_type: "SHORT",
            order_uuid: "c0848fab-6723-425b-9253-69d01a222ac0",
            price: 154.84449999999998,
            price_sources: [],
            processed_ms: 1717508886618,
          },
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "88a4f72e-6719-437a-960f-55b54278988c",
            price: 157.162,
            price_sources: [],
            processed_ms: 1718001782215,
          },
          {
            leverage: -0.3,
            order_type: "SHORT",
            order_uuid: "38fcee2e-df9f-44fd-8f0d-967375f5bc2d",
            price: 156.965,
            price_sources: [],
            processed_ms: 1718003179722,
          },
          {
            leverage: 0.49,
            order_type: "LONG",
            order_uuid: "aa9ef912-6422-42d3-985e-e382cafbde8d",
            price: 157.032,
            price_sources: [],
            processed_ms: 1718006587936,
          },
          {
            leverage: -1.0,
            order_type: "SHORT",
            order_uuid: "89be586e-98e8-476e-bdb5-e26afe258d01",
            price: 157.34050000000002,
            price_sources: [],
            processed_ms: 1718121501398,
          },
          {
            leverage: 0.5,
            order_type: "LONG",
            order_uuid: "4d4da805-cbb3-4fe2-8a0a-f4377c14e41f",
            price: 157.336,
            price_sources: [],
            processed_ms: 1718189593027,
          },
          {
            leverage: 0.1,
            order_type: "FLAT",
            order_uuid: "3b728057-d354-4677-8629-5b4efb49cc27",
            price: 156.44650000000001,
            price_sources: [],
            processed_ms: 1718196096477,
          },
        ],
        position_type: "FLAT",
        position_uuid: "3ec442c6-fba7-4b3b-aaf3-48e904ac1710",
        return_at_close: 0.9953684664865075,
        trade_pair: ["USDJPY", "USD/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 1.0782477777777777,
        close_ms: 1718199263498,
        current_return: 1.0043521100576316,
        initial_entry_price: 1.0758,
        is_closed_position: true,
        miner_hotkey: "5EHpm2UK3CyhH1zZiJmM6erGrzkmVAF9EnT1QLSPhMzQaQHG",
        net_leverage: 0.0,
        open_ms: 1718189624836,
        orders: [
          {
            leverage: 0.5,
            order_type: "LONG",
            order_uuid: "c3bbb227-6607-4c6c-9fea-e4c10efdec88",
            price: 1.0758,
            price_sources: [],
            processed_ms: 1718189624808,
          },
          {
            leverage: -0.3,
            order_type: "SHORT",
            order_uuid: "41e477db-3cab-41d9-94a7-0fc433086621",
            price: 1.08129,
            price_sources: [],
            processed_ms: 1718195981026,
          },
          {
            leverage: 0.7,
            order_type: "LONG",
            order_uuid: "0c4d331b-1f6a-4800-874d-d9e992ee55ee",
            price: 1.0813,
            price_sources: [],
            processed_ms: 1718196200553,
          },
          {
            leverage: 0.5,
            order_type: "FLAT",
            order_uuid: "c6e08a2a-8cd9-40e0-b503-d1e422d8d9c6",
            price: 1.08345,
            price_sources: [],
            processed_ms: 1718199263498,
          },
        ],
        position_type: "FLAT",
        position_uuid: "786a4efc-6471-465e-a39c-613c8a15944c",
        return_at_close: 1.0042677444803867,
        trade_pair: ["EURUSD", "EUR/USD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 1.4792825,
        close_ms: 1718203205244,
        current_return: 1.0006368502406329,
        initial_entry_price: 1.48151,
        is_closed_position: true,
        miner_hotkey: "5EHpm2UK3CyhH1zZiJmM6erGrzkmVAF9EnT1QLSPhMzQaQHG",
        net_leverage: 0.0,
        open_ms: 1718196286623,
        orders: [
          {
            leverage: 0.5,
            order_type: "LONG",
            order_uuid: "7a2cff51-48c0-4756-ac15-e88c60238c6f",
            price: 1.48151,
            price_sources: [],
            processed_ms: 1718196286595,
          },
          {
            leverage: -0.3,
            order_type: "SHORT",
            order_uuid: "02e8268f-5dc3-487a-8e46-a6fc38d79835",
            price: 1.482995,
            price_sources: [],
            processed_ms: 1718199349934,
          },
          {
            leverage: 0.01,
            order_type: "FLAT",
            order_uuid: "8d42c36b-834a-49b2-b415-970fecf6a81d",
            price: 1.484,
            price_sources: [],
            processed_ms: 1718203205244,
          },
        ],
        position_type: "FLAT",
        position_uuid: "9e87a42a-ec48-4761-bdb7-b774f846af60",
        return_at_close: 1.0006018279508744,
        trade_pair: ["EURCAD", "EUR/CAD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.66497,
        close_ms: 1718283001350,
        current_return: 1.000496262989308,
        initial_entry_price: 0.66497,
        is_closed_position: true,
        miner_hotkey: "5EHpm2UK3CyhH1zZiJmM6erGrzkmVAF9EnT1QLSPhMzQaQHG",
        net_leverage: 0.0,
        open_ms: 1718262992975,
        orders: [
          {
            leverage: 0.5,
            order_type: "LONG",
            order_uuid: "e1468858-df3e-4735-81c2-8b392e43e876",
            price: 0.66497,
            price_sources: [],
            processed_ms: 1718262992735,
          },
          {
            leverage: 0.01,
            order_type: "FLAT",
            order_uuid: "c819579e-4f71-47ba-8670-e4370aa17a9e",
            price: 0.66563,
            price_sources: [],
            processed_ms: 1718283001350,
          },
        ],
        position_type: "FLAT",
        position_uuid: "2f4aa104-aaf4-4ef7-b1a1-418898905d22",
        return_at_close: 1.0004612456201034,
        trade_pair: ["AUDUSD", "AUD/USD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.61698,
        close_ms: 1718283036431,
        current_return: 1.0006240072611754,
        initial_entry_price: 0.61698,
        is_closed_position: true,
        miner_hotkey: "5EHpm2UK3CyhH1zZiJmM6erGrzkmVAF9EnT1QLSPhMzQaQHG",
        net_leverage: 0.0,
        open_ms: 1718262943504,
        orders: [
          {
            leverage: 0.5,
            order_type: "LONG",
            order_uuid: "f7e3aabd-dccb-4891-b2c1-b181fc05d89a",
            price: 0.61698,
            price_sources: [],
            processed_ms: 1718262943479,
          },
          {
            leverage: 0.01,
            order_type: "FLAT",
            order_uuid: "f3ff633c-b8c8-4a1b-89ac-e1767c089104",
            price: 0.61775,
            price_sources: [],
            processed_ms: 1718283036431,
          },
        ],
        position_type: "FLAT",
        position_uuid: "7ec4ea90-da5f-4921-9b6b-400f1c53a8b8",
        return_at_close: 1.0005889854209213,
        trade_pair: ["NZDUSD", "NZD/USD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.91205,
        close_ms: 1718352043779,
        current_return: 0.9987556639948709,
        initial_entry_price: 0.91205,
        is_closed_position: true,
        miner_hotkey: "5EHpm2UK3CyhH1zZiJmM6erGrzkmVAF9EnT1QLSPhMzQaQHG",
        net_leverage: 0.0,
        open_ms: 1718300201484,
        orders: [
          {
            leverage: 0.5,
            order_type: "LONG",
            order_uuid: "e6e04063-6e4f-4ca8-9b56-a5df7f6ab357",
            price: 0.91205,
            price_sources: [],
            processed_ms: 1718300201328,
          },
          {
            leverage: 0.5,
            order_type: "FLAT",
            order_uuid: "568128c1-7838-4532-b929-1b69cd684a30",
            price: 0.909780206693044,
            price_sources: [],
            processed_ms: 1718352043779,
          },
        ],
        position_type: "FLAT",
        position_uuid: "bb078410-8a7c-4510-ba21-9bcf6ddf90cf",
        return_at_close: 0.9987207075466311,
        trade_pair: ["AUDCAD", "AUD/CAD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.844263,
        close_ms: 1718356416380,
        current_return: 0.9972970411397092,
        initial_entry_price: 0.8440749999999999,
        is_closed_position: true,
        miner_hotkey: "5EHpm2UK3CyhH1zZiJmM6erGrzkmVAF9EnT1QLSPhMzQaQHG",
        net_leverage: 0.0,
        open_ms: 1718203272941,
        orders: [
          {
            leverage: 0.1,
            order_type: "LONG",
            order_uuid: "efac5779-9006-4759-a324-cee0f93508ac",
            price: 0.8440749999999999,
            price_sources: [],
            processed_ms: 1718203272833,
          },
          {
            leverage: 0.4,
            order_type: "LONG",
            order_uuid: "08e6a8a8-3562-4c33-819b-0a2914005fab",
            price: 0.84431,
            price_sources: [],
            processed_ms: 1718215635594,
          },
          {
            leverage: 0.5,
            order_type: "FLAT",
            order_uuid: "af315784-010a-4a60-8f52-c63911b7b613",
            price: 0.8397,
            price_sources: [],
            processed_ms: 1718356416380,
          },
        ],
        position_type: "FLAT",
        position_uuid: "833a2ffa-eb81-4664-ac91-ac656d4e71b6",
        return_at_close: 0.9972621357432693,
        trade_pair: ["EURGBP", "EUR/GBP", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 168.902,
        close_ms: 1718359771806,
        current_return: 1.003068347325668,
        initial_entry_price: 168.902,
        is_closed_position: true,
        miner_hotkey: "5EHpm2UK3CyhH1zZiJmM6erGrzkmVAF9EnT1QLSPhMzQaQHG",
        net_leverage: 0.0,
        open_ms: 1718352098352,
        orders: [
          {
            leverage: -0.5,
            order_type: "SHORT",
            order_uuid: "1c35b450-885b-4133-bab0-0a82371863fd",
            price: 168.902,
            price_sources: [],
            processed_ms: 1718352098320,
          },
          {
            leverage: 0.01,
            order_type: "FLAT",
            order_uuid: "3a0b7300-4bc8-4901-9e5c-78947dc1ea34",
            price: 167.8655,
            price_sources: [],
            processed_ms: 1718359771806,
          },
        ],
        position_type: "FLAT",
        position_uuid: "c1acd52f-795d-492b-9583-fba1b20e9362",
        return_at_close: 1.0030332399335116,
        trade_pair: ["EURJPY", "EUR/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 200.252,
        close_ms: 1718364471170,
        current_return: 1.0026010389235778,
        initial_entry_price: 201.0735,
        is_closed_position: true,
        miner_hotkey: "5EHpm2UK3CyhH1zZiJmM6erGrzkmVAF9EnT1QLSPhMzQaQHG",
        net_leverage: 0.0,
        open_ms: 1718350264464,
        orders: [
          {
            leverage: -0.5,
            order_type: "SHORT",
            order_uuid: "e64390e8-4358-42e2-9b40-43459c584260",
            price: 201.0735,
            price_sources: [],
            processed_ms: 1718350264404,
          },
          {
            leverage: -0.5,
            order_type: "SHORT",
            order_uuid: "65a8b914-4528-45f9-8e7f-0ed8f014b651",
            price: 199.4305,
            price_sources: [],
            processed_ms: 1718360302032,
          },
          {
            leverage: 0.01,
            order_type: "FLAT",
            order_uuid: "03277e7f-97f4-4a2d-8ab8-282a7a8bd663",
            price: 199.729,
            price_sources: [],
            processed_ms: 1718364471170,
          },
        ],
        position_type: "FLAT",
        position_uuid: "416a2309-6713-4b33-858a-ffa44e7e51e7",
        return_at_close: 1.002530856850853,
        trade_pair: ["GBPJPY", "GBP/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 167.984,
        close_ms: 1718375508990,
        current_return: 1.0001890060958187,
        initial_entry_price: 167.984,
        is_closed_position: true,
        miner_hotkey: "5EHpm2UK3CyhH1zZiJmM6erGrzkmVAF9EnT1QLSPhMzQaQHG",
        net_leverage: 0.0,
        open_ms: 1718366376880,
        orders: [
          {
            leverage: -0.5,
            order_type: "SHORT",
            order_uuid: "cb1368f6-cfe5-4615-8adb-1bae6f87515a",
            price: 167.984,
            price_sources: [],
            processed_ms: 1718366376855,
          },
          {
            leverage: 0.5,
            order_type: "FLAT",
            order_uuid: "bbc72ed5-75f6-49fc-907b-4814a92e059e",
            price: 167.9205,
            price_sources: [],
            processed_ms: 1718375508990,
          },
        ],
        position_type: "FLAT",
        position_uuid: "d6f84059-bb17-4806-9d17-b617532f1683",
        return_at_close: 1.0001539994806055,
        trade_pair: ["EURJPY", "EUR/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 1.26737,
        close_ms: 1718375615613,
        current_return: 1.000457640625863,
        initial_entry_price: 1.26737,
        is_closed_position: true,
        miner_hotkey: "5EHpm2UK3CyhH1zZiJmM6erGrzkmVAF9EnT1QLSPhMzQaQHG",
        net_leverage: 0.0,
        open_ms: 1718368813247,
        orders: [
          {
            leverage: -0.5,
            order_type: "SHORT",
            order_uuid: "1fec6579-8636-4c7e-8f0b-9deb77cd1c08",
            price: 1.26737,
            price_sources: [],
            processed_ms: 1718368813204,
          },
          {
            leverage: 0.5,
            order_type: "FLAT",
            order_uuid: "667ec2fc-3628-4200-8894-1d101712cca4",
            price: 1.26621,
            price_sources: [],
            processed_ms: 1718375615613,
          },
        ],
        position_type: "FLAT",
        position_uuid: "25886a1d-e76b-462f-8e59-0ad736c8822a",
        return_at_close: 1.0004226246084411,
        trade_pair: ["GBPUSD", "GBP/USD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 1.741435,
        close_ms: 1718378886715,
        current_return: 0.9995592715203266,
        initial_entry_price: 1.741435,
        is_closed_position: true,
        miner_hotkey: "5EHpm2UK3CyhH1zZiJmM6erGrzkmVAF9EnT1QLSPhMzQaQHG",
        net_leverage: 0.0,
        open_ms: 1718300070163,
        orders: [
          {
            leverage: -0.5,
            order_type: "SHORT",
            order_uuid: "6abc130a-a282-401f-b0ed-573a5ce9c104",
            price: 1.741435,
            price_sources: [],
            processed_ms: 1718300070068,
          },
          {
            leverage: 0.01,
            order_type: "FLAT",
            order_uuid: "cadc24a0-74dc-45ad-ba72-c490282f3b47",
            price: 1.74297,
            price_sources: [],
            processed_ms: 1718378886715,
          },
        ],
        position_type: "FLAT",
        position_uuid: "a33e5ff0-aeb1-4b9b-a5f0-bb2236841fb7",
        return_at_close: 0.9995242869458234,
        trade_pair: ["EURNZD", "EUR/NZD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 1.07863,
        close_ms: 1718643130017,
        current_return: 0.9998006730760317,
        initial_entry_price: 1.07863,
        is_closed_position: true,
        miner_hotkey: "5EHpm2UK3CyhH1zZiJmM6erGrzkmVAF9EnT1QLSPhMzQaQHG",
        net_leverage: 0.0,
        open_ms: 1718624019778,
        orders: [
          {
            leverage: 0.5,
            order_type: "LONG",
            order_uuid: "dc8f6635-f348-4e1b-ba25-2f067b6a6a83",
            price: 1.07863,
            price_sources: [],
            processed_ms: 1718624019685,
          },
          {
            leverage: 0.01,
            order_type: "FLAT",
            order_uuid: "b1ed0981-b6af-4484-ad21-80def8d376c6",
            price: 1.0782,
            price_sources: [],
            processed_ms: 1718643130017,
          },
        ],
        position_type: "FLAT",
        position_uuid: "2b4d0a05-b8cb-4c81-bcb3-5b730d1e32e2",
        return_at_close: 0.999765680052474,
        trade_pair: ["AUDNZD", "AUD/NZD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.9512,
        close_ms: 1718644457473,
        current_return: 0.9978921362489487,
        initial_entry_price: 0.9512,
        is_closed_position: true,
        miner_hotkey: "5EHpm2UK3CyhH1zZiJmM6erGrzkmVAF9EnT1QLSPhMzQaQHG",
        net_leverage: 0.0,
        open_ms: 1718375410562,
        orders: [
          {
            leverage: -0.5,
            order_type: "SHORT",
            order_uuid: "75169362-0b31-4abe-9d67-4f08ee9d9245",
            price: 0.9512,
            price_sources: [],
            processed_ms: 1718375410462,
          },
          {
            leverage: 0.01,
            order_type: "FLAT",
            order_uuid: "83163b23-be54-4c5d-a305-1bdf73f891ae",
            price: 0.95521,
            price_sources: [],
            processed_ms: 1718644457473,
          },
        ],
        position_type: "FLAT",
        position_uuid: "7c2f1dc9-7a31-4c88-8d0a-7817ede8dc2d",
        return_at_close: 0.9978572100241799,
        trade_pair: ["EURCHF", "EUR/CHF", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.64676,
        close_ms: 1718699936367,
        current_return: 1.0008117385119673,
        initial_entry_price: 0.64676,
        is_closed_position: true,
        miner_hotkey: "5EHpm2UK3CyhH1zZiJmM6erGrzkmVAF9EnT1QLSPhMzQaQHG",
        net_leverage: 0.0,
        open_ms: 1718375564412,
        orders: [
          {
            leverage: -0.5,
            order_type: "SHORT",
            order_uuid: "4835c3d8-9cab-4512-8f19-53a4148e2454",
            price: 0.64676,
            price_sources: [],
            processed_ms: 1718375564303,
          },
          {
            leverage: 0.01,
            order_type: "FLAT",
            order_uuid: "f7a76b37-b8fd-40c1-89bf-b61f3b2873ac",
            price: 0.64571,
            price_sources: [],
            processed_ms: 1718699936367,
          },
        ],
        position_type: "FLAT",
        position_uuid: "6890c06a-5876-44cd-8dcb-b9f486ec87e8",
        return_at_close: 1.0007767101011193,
        trade_pair: ["CADCHF", "CAD/CHF", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.95525,
        close_ms: 1718699968087,
        current_return: 0.9985187123789584,
        initial_entry_price: 0.95525,
        is_closed_position: true,
        miner_hotkey: "5EHpm2UK3CyhH1zZiJmM6erGrzkmVAF9EnT1QLSPhMzQaQHG",
        net_leverage: 0.0,
        open_ms: 1718644678163,
        orders: [
          {
            leverage: 0.5,
            order_type: "LONG",
            order_uuid: "6df51473-f4ab-4ea3-8080-7977802e83f3",
            price: 0.95525,
            price_sources: [],
            processed_ms: 1718644677828,
          },
          {
            leverage: 0.01,
            order_type: "FLAT",
            order_uuid: "66851472-ef1e-40e9-aeb1-0f2cfd1af179",
            price: 0.95242,
            price_sources: [],
            processed_ms: 1718699968087,
          },
        ],
        position_type: "FLAT",
        position_uuid: "6d02e16c-58d7-4699-910a-727550cb551d",
        return_at_close: 0.9984837642240251,
        trade_pair: ["EURCHF", "EUR/CHF", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 158.1705,
        close_ms: 1718718929149,
        current_return: 1.001417773857957,
        initial_entry_price: 158.1705,
        is_closed_position: true,
        miner_hotkey: "5EHpm2UK3CyhH1zZiJmM6erGrzkmVAF9EnT1QLSPhMzQaQHG",
        net_leverage: 0.0,
        open_ms: 1718700018068,
        orders: [
          {
            leverage: -0.5,
            order_type: "SHORT",
            order_uuid: "ba3733fa-4b74-48b0-8beb-7469cf8ecbef",
            price: 158.1705,
            price_sources: [],
            processed_ms: 1718700017308,
          },
          {
            leverage: 0.01,
            order_type: "FLAT",
            order_uuid: "c089f4ed-0acb-4536-aa10-c140b34ed04b",
            price: 157.722,
            price_sources: [],
            processed_ms: 1718718929149,
          },
        ],
        position_type: "FLAT",
        position_uuid: "a80bce55-5988-4845-a2c8-873ed60b626f",
        return_at_close: 1.001382724235872,
        trade_pair: ["USDJPY", "USD/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.65947,
        close_ms: 1718787599989,
        current_return: 0.9942150514807345,
        initial_entry_price: 0.65947,
        is_closed_position: true,
        miner_hotkey: "5EHpm2UK3CyhH1zZiJmM6erGrzkmVAF9EnT1QLSPhMzQaQHG",
        net_leverage: 0.0,
        open_ms: 1718604189271,
        orders: [
          {
            leverage: -0.5,
            order_type: "SHORT",
            order_uuid: "b1eef947-dc47-4a9e-869c-79e260eb0978",
            price: 0.65947,
            price_sources: [],
            processed_ms: 1718604189237,
          },
          {
            leverage: 0.05,
            order_type: "FLAT",
            order_uuid: "0af5cd48-2892-4cf0-8412-41b23052816e",
            price: 0.6671,
            price_sources: [],
            processed_ms: 1718787599989,
          },
        ],
        position_type: "FLAT",
        position_uuid: "efc55cfd-f7be-48e9-abe5-48341c8d8935",
        return_at_close: 0.9941802539539327,
        trade_pair: ["AUDUSD", "AUD/USD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 1.0733,
        close_ms: 1718872202698,
        current_return: 1.0042847295963035,
        initial_entry_price: 1.07358,
        is_closed_position: true,
        miner_hotkey: "5EHpm2UK3CyhH1zZiJmM6erGrzkmVAF9EnT1QLSPhMzQaQHG",
        net_leverage: 0.0,
        open_ms: 1718733556729,
        orders: [
          {
            leverage: -0.5,
            order_type: "SHORT",
            order_uuid: "63d2b024-d0f3-465c-ba47-680eedf9850d",
            price: 1.07358,
            price_sources: [],
            processed_ms: 1718733556501,
          },
          {
            leverage: -2.0,
            order_type: "SHORT",
            order_uuid: "6a847f31-968a-49e6-b596-6c4daba64015",
            price: 1.07323,
            price_sources: [],
            processed_ms: 1718864035020,
          },
          {
            leverage: 0.01,
            order_type: "FLAT",
            order_uuid: "ef319240-a2a3-48b9-a174-3f0ea819b184",
            price: 1.07146,
            price_sources: [],
            processed_ms: 1718872202698,
          },
        ],
        position_type: "FLAT",
        position_uuid: "b2a3690b-ab04-4f94-8739-2bc160cf50ef",
        return_at_close: 1.0041089797686242,
        trade_pair: ["EURUSD", "EUR/USD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.61011,
        close_ms: 1718897089830,
        current_return: 0.9984879775778138,
        initial_entry_price: 0.61011,
        is_closed_position: true,
        miner_hotkey: "5EHpm2UK3CyhH1zZiJmM6erGrzkmVAF9EnT1QLSPhMzQaQHG",
        net_leverage: 0.0,
        open_ms: 1718697197177,
        orders: [
          {
            leverage: -0.5,
            order_type: "SHORT",
            order_uuid: "bfddb6e9-4879-4a5f-8c68-8dd9d95ace18",
            price: 0.61011,
            price_sources: [],
            processed_ms: 1718697197144,
          },
          {
            leverage: 0.01,
            order_type: "FLAT",
            order_uuid: "efc73f8f-de3f-42f8-8e75-b82a1121dd7f",
            price: 0.611955,
            price_sources: [],
            processed_ms: 1718897089830,
          },
        ],
        position_type: "FLAT",
        position_uuid: "a65ddc25-0b03-477b-aabf-0a8533ac0f67",
        return_at_close: 0.9984530304985986,
        trade_pair: ["NZDUSD", "NZD/USD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 156.62400000000002,
        close_ms: 1718897169777,
        current_return: 0.9671755773609804,
        initial_entry_price: 157.657,
        is_closed_position: true,
        miner_hotkey: "5EHpm2UK3CyhH1zZiJmM6erGrzkmVAF9EnT1QLSPhMzQaQHG",
        net_leverage: 0.0,
        open_ms: 1718780242304,
        orders: [
          {
            leverage: -5.0,
            order_type: "SHORT",
            order_uuid: "6a151161-e5b7-4fcf-83cd-2c9a95075041",
            price: 157.657,
            price_sources: [],
            processed_ms: 1718780241298,
          },
          {
            leverage: 2.5,
            order_type: "LONG",
            order_uuid: "e007fc34-af82-4ed1-b6d4-01e3fb5f8cde",
            price: 158.69,
            price_sources: [],
            processed_ms: 1718897120431,
          },
          {
            leverage: 2.5,
            order_type: "FLAT",
            order_uuid: "de26ccbf-66d7-46f0-9309-bab74110f603",
            price: 158.694,
            price_sources: [],
            processed_ms: 1718897169777,
          },
        ],
        position_type: "FLAT",
        position_uuid: "a3e02ca8-e614-404d-b5ca-8d0acf3f6140",
        return_at_close: 0.9668370659089041,
        trade_pair: ["USDJPY", "USD/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 169.44,
        close_ms: 1718897256389,
        current_return: 0.9981792965061379,
        initial_entry_price: 169.44,
        is_closed_position: true,
        miner_hotkey: "5EHpm2UK3CyhH1zZiJmM6erGrzkmVAF9EnT1QLSPhMzQaQHG",
        net_leverage: 0.0,
        open_ms: 1718733506159,
        orders: [
          {
            leverage: -0.5,
            order_type: "SHORT",
            order_uuid: "edca70e7-730b-4860-97bb-076b999d80c4",
            price: 169.44,
            price_sources: [],
            processed_ms: 1718733506092,
          },
          {
            leverage: 2.5,
            order_type: "FLAT",
            order_uuid: "67a09e56-9161-4e72-9630-bdf64ce59698",
            price: 170.057,
            price_sources: [],
            processed_ms: 1718897256389,
          },
        ],
        position_type: "FLAT",
        position_uuid: "91e2ce69-9bf3-403c-9f21-25a1c25b9a87",
        return_at_close: 0.9981443602307601,
        trade_pair: ["EURJPY", "EUR/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 65010.26,
        close_ms: 1719177008362,
        current_return: 1.0107607629934106,
        initial_entry_price: 65010.26,
        is_closed_position: true,
        miner_hotkey: "5EHpm2UK3CyhH1zZiJmM6erGrzkmVAF9EnT1QLSPhMzQaQHG",
        net_leverage: 0.0,
        open_ms: 1718906766461,
        orders: [
          {
            leverage: -0.5,
            order_type: "SHORT",
            order_uuid: "8f20d6f0-987a-4d92-9141-6f545682057a",
            price: 65010.26,
            price_sources: [],
            processed_ms: 1718906763515,
          },
          {
            leverage: 0.01,
            order_type: "FLAT",
            order_uuid: "ba2bcf18-51ec-499f-a791-af7a4f971862",
            price: 63611.14,
            price_sources: [],
            processed_ms: 1719177008362,
          },
        ],
        position_type: "FLAT",
        position_uuid: "e4a795fb-df86-4cd9-ba7e-092c930c1174",
        return_at_close: 1.0102553826119138,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 0.61194,
        close_ms: 1719319191500,
        current_return: 1.0007190247409876,
        initial_entry_price: 0.61194,
        is_closed_position: true,
        miner_hotkey: "5EHpm2UK3CyhH1zZiJmM6erGrzkmVAF9EnT1QLSPhMzQaQHG",
        net_leverage: 0.0,
        open_ms: 1719316674276,
        orders: [
          {
            leverage: 2.0,
            order_type: "LONG",
            order_uuid: "799bad54-4313-4e2f-9b37-cfaafda9f59d",
            price: 0.61194,
            price_sources: [],
            processed_ms: 1719316673244,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "777c365f-c387-4d72-bc8e-fe5752c713b5",
            price: 0.61216,
            price_sources: [],
            processed_ms: 1719319191500,
          },
        ],
        position_type: "FLAT",
        position_uuid: "420cc847-5a5d-4a0d-b526-d514c95e9fb7",
        return_at_close: 1.0005789240775238,
        trade_pair: ["NZDUSD", "NZD/USD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 3379.12,
        close_ms: 1719338555656,
        current_return: 1.0010579677549185,
        initial_entry_price: 3379.12,
        is_closed_position: true,
        miner_hotkey: "5EHpm2UK3CyhH1zZiJmM6erGrzkmVAF9EnT1QLSPhMzQaQHG",
        net_leverage: 0.0,
        open_ms: 1719210224365,
        orders: [
          {
            leverage: 0.5,
            order_type: "LONG",
            order_uuid: "534a3666-6b7f-4219-8432-f0dda6df43ab",
            price: 3379.12,
            price_sources: [],
            processed_ms: 1719210223233,
          },
          {
            leverage: 0.01,
            order_type: "FLAT",
            order_uuid: "02155aeb-ce76-4578-8d16-31dc9f33ba21",
            price: 3386.27,
            price_sources: [],
            processed_ms: 1719338555656,
          },
        ],
        position_type: "FLAT",
        position_uuid: "f30ea643-411d-475d-a3e2-6a5572c80171",
        return_at_close: 1.0005574387710412,
        trade_pair: ["ETHUSD", "ETH/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 0.83524,
        close_ms: 1719338601708,
        current_return: 1.0001142509839733,
        initial_entry_price: 0.83524,
        is_closed_position: true,
        miner_hotkey: "5EHpm2UK3CyhH1zZiJmM6erGrzkmVAF9EnT1QLSPhMzQaQHG",
        net_leverage: 0.0,
        open_ms: 1719319497459,
        orders: [
          {
            leverage: -1.0,
            order_type: "SHORT",
            order_uuid: "fea61d95-1463-48e9-9afc-21fe996db5af",
            price: 0.83524,
            price_sources: [],
            processed_ms: 1719319497149,
          },
          {
            leverage: 0.01,
            order_type: "FLAT",
            order_uuid: "4cca1283-b522-460b-8270-94cb1f9d2dcd",
            price: 0.835144573008146,
            price_sources: [],
            processed_ms: 1719338601708,
          },
        ],
        position_type: "FLAT",
        position_uuid: "b4db8d72-4a2d-4a5b-aeea-411f7efe81fc",
        return_at_close: 1.0000442429864045,
        trade_pair: ["NZDCAD", "NZD/CAD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.907501496309104,
        close_ms: 1719338640791,
        current_return: 1.0000810111865783,
        initial_entry_price: 0.907501496309104,
        is_closed_position: true,
        miner_hotkey: "5EHpm2UK3CyhH1zZiJmM6erGrzkmVAF9EnT1QLSPhMzQaQHG",
        net_leverage: 0.0,
        open_ms: 1719319339499,
        orders: [
          {
            leverage: -1.0,
            order_type: "SHORT",
            order_uuid: "72204f96-10ef-4aac-8732-7bee8423f896",
            price: 0.907501496309104,
            price_sources: [],
            processed_ms: 1719319339312,
          },
          {
            leverage: 0.01,
            order_type: "FLAT",
            order_uuid: "cde12c79-29ea-406b-bf36-e7f511a9c9e8",
            price: 0.9074279785360665,
            price_sources: [],
            processed_ms: 1719338640791,
          },
        ],
        position_type: "FLAT",
        position_uuid: "2a604880-0817-4e4d-9b0d-90851b2447f0",
        return_at_close: 1.0000110055157954,
        trade_pair: ["AUDCAD", "AUD/CAD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 1.46279,
        close_ms: 1719381255015,
        current_return: 1.0004887919660375,
        initial_entry_price: 1.46279,
        is_closed_position: true,
        miner_hotkey: "5EHpm2UK3CyhH1zZiJmM6erGrzkmVAF9EnT1QLSPhMzQaQHG",
        net_leverage: 0.0,
        open_ms: 1719338709848,
        orders: [
          {
            leverage: -1.0,
            order_type: "SHORT",
            order_uuid: "fea4b4da-d591-4e4c-a450-eece737da69b",
            price: 1.46279,
            price_sources: [],
            processed_ms: 1719338709392,
          },
          {
            leverage: 0.01,
            order_type: "FLAT",
            order_uuid: "796802ba-3d78-4d74-8342-4cb9622a7d36",
            price: 1.462075,
            price_sources: [],
            processed_ms: 1719381255015,
          },
        ],
        position_type: "FLAT",
        position_uuid: "9ff05728-0eb6-4cbc-b2d2-76fca6c9c92f",
        return_at_close: 1.0004187577505999,
        trade_pair: ["EURCAD", "EUR/CAD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 158.68866666666668,
        close_ms: 1719957173321,
        current_return: 0.974099949647533,
        initial_entry_price: 158.88,
        is_closed_position: true,
        miner_hotkey: "5EHpm2UK3CyhH1zZiJmM6erGrzkmVAF9EnT1QLSPhMzQaQHG",
        net_leverage: 0.0,
        open_ms: 1718906716663,
        orders: [
          {
            leverage: -2.5,
            order_type: "SHORT",
            order_uuid: "cbd7f295-ceef-4cd4-96d2-8dcc8b4160d3",
            price: 158.88,
            price_sources: [],
            processed_ms: 1718906716556,
          },
          {
            leverage: 2.0,
            order_type: "LONG",
            order_uuid: "52348e18-5711-4a99-a4d7-abc4f5ab71c0",
            price: 159.47899999999998,
            price_sources: [],
            processed_ms: 1719301060745,
          },
          {
            leverage: -1.0,
            order_type: "SHORT",
            order_uuid: "4e8c10e6-ed88-431a-b9ad-f149aec8558a",
            price: 159.791,
            price_sources: [],
            processed_ms: 1719381285516,
          },
          {
            leverage: 0.01,
            order_type: "FLAT",
            order_uuid: "b015a1f8-a123-4e2e-97e9-5187bc0392b9",
            price: 161.432,
            price_sources: [],
            processed_ms: 1719957173321,
          },
        ],
        position_type: "FLAT",
        position_uuid: "411d5996-51cd-47e2-a8cc-fbd1db84b657",
        return_at_close: 0.9738612951598693,
        trade_pair: ["USDJPY", "USD/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 204.731,
        close_ms: 1720078841199,
        current_return: 0.9951643864387903,
        initial_entry_price: 204.731,
        is_closed_position: true,
        miner_hotkey: "5EHpm2UK3CyhH1zZiJmM6erGrzkmVAF9EnT1QLSPhMzQaQHG",
        net_leverage: 0.0,
        open_ms: 1719957221397,
        orders: [
          {
            leverage: -1.0,
            order_type: "SHORT",
            order_uuid: "db88ba68-2b19-4dd5-9ebd-ff0abd3d64e5",
            price: 204.731,
            price_sources: [],
            processed_ms: 1719957220662,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "08e74f96-a404-4bfa-b1ba-b568ea3b72af",
            price: 205.721,
            price_sources: [
              {
                close: 205.721,
                high: 205.721,
                lag_ms: 199,
                low: 205.721,
                open: 205.721,
                source: "Polygon_ws",
                start_ms: 1720078841000,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 205.726,
                websocket: true,
              },
              {
                close: 205.718,
                high: 205.718,
                lag_ms: 199,
                low: 205.718,
                open: 205.718,
                source: "TwelveData_ws",
                start_ms: 1720078841000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1720078841199,
          },
        ],
        position_type: "FLAT",
        position_uuid: "db61e41b-eb07-4cf4-ab11-efc3603d413f",
        return_at_close: 0.9950947249317397,
        trade_pair: ["GBPJPY", "GBP/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 3185.04,
        close_ms: 1720110943933,
        current_return: 1.0528015346746038,
        initial_entry_price: 3185.04,
        is_closed_position: true,
        miner_hotkey: "5EHpm2UK3CyhH1zZiJmM6erGrzkmVAF9EnT1QLSPhMzQaQHG",
        net_leverage: 0.0,
        open_ms: 1720078882530,
        orders: [
          {
            leverage: -2.5,
            order_type: "SHORT",
            order_uuid: "d3c4caca-9b57-4e11-b3bd-a12f23943ef3",
            price: 3185.04,
            price_sources: [
              {
                close: 3185.04,
                high: 3185.04,
                lag_ms: 302,
                low: 3185.04,
                open: 3185.04,
                source: "Polygon_ws",
                start_ms: 1720078879999,
                timespan_ms: 0,
                volume: 0.2498969,
                vwap: 3185.04,
                websocket: true,
              },
              {
                close: 3187.04,
                high: 3187.04,
                lag_ms: 2697,
                low: 3187.04,
                open: 3187.04,
                source: "TwelveData_ws",
                start_ms: 1720078877000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
              {
                close: 3185.29,
                high: 3185.29,
                lag_ms: 2698,
                low: 3185.0,
                open: 3185.0,
                source: "Polygon_rest",
                start_ms: 1720078876000,
                timespan_ms: 1000,
                volume: 4.54109518,
                vwap: 3185.0029,
                websocket: false,
              },
              {
                close: 3187.55005,
                high: 3191.40991,
                lag_ms: 79698,
                low: 3187.55005,
                open: 3191.32007,
                source: "TwelveData_rest",
                start_ms: 1720078740000,
                timespan_ms: 60000,
                volume: null,
                vwap: null,
                websocket: false,
              },
            ],
            processed_ms: 1720078879697,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "9ae3e5bf-e31e-4844-a719-4b50610284bc",
            price: 3117.77,
            price_sources: [
              {
                close: 3117.77,
                high: 3117.77,
                lag_ms: 67,
                low: 3117.66,
                open: 3117.77,
                source: "Polygon_ws",
                start_ms: 1720110944000,
                timespan_ms: 0,
                volume: 0.07375157,
                vwap: 3117.7629,
                websocket: true,
              },
              {
                close: 3120.07,
                high: 3120.07,
                lag_ms: 2067,
                low: 3120.07,
                open: 3120.07,
                source: "TwelveData_ws",
                start_ms: 1720110946000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
              {
                close: 3117.8,
                high: 3117.8,
                lag_ms: 2934,
                low: 3116.85,
                open: 3117.66,
                source: "Polygon_rest",
                start_ms: 1720110940000,
                timespan_ms: 1000,
                volume: 4.63412318,
                vwap: 3117.6616,
                websocket: false,
              },
              {
                close: 3116.21997,
                high: 3116.21997,
                lag_ms: 43934,
                low: 3115.06006,
                open: 3115.8501,
                source: "TwelveData_rest",
                start_ms: 1720110840000,
                timespan_ms: 60000,
                volume: null,
                vwap: null,
                websocket: false,
              },
            ],
            processed_ms: 1720110943933,
          },
        ],
        position_type: "FLAT",
        position_uuid: "ab8059e0-5d27-4b5c-8017-a619ad7609b4",
        return_at_close: 1.0501695308379173,
        trade_pair: ["ETHUSD", "ETH/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 108.37,
        close_ms: 1720467941580,
        current_return: 1.0008258743194611,
        initial_entry_price: 108.37,
        is_closed_position: true,
        miner_hotkey: "5EHpm2UK3CyhH1zZiJmM6erGrzkmVAF9EnT1QLSPhMzQaQHG",
        net_leverage: 0.0,
        open_ms: 1720080409209,
        orders: [
          {
            leverage: -1.0,
            order_type: "SHORT",
            order_uuid: "4efb82c2-0dc3-438d-99c2-db09512ce986",
            price: 108.37,
            price_sources: [
              {
                close: 108.37,
                high: 108.37,
                lag_ms: 42,
                low: 108.37,
                open: 108.37,
                source: "Polygon_ws",
                start_ms: 1720080409000,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 108.373,
                websocket: true,
              },
              {
                close: 108.372,
                high: 108.372,
                lag_ms: 42,
                low: 108.372,
                open: 108.372,
                source: "TwelveData_ws",
                start_ms: 1720080409000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1720080409042,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "019cafcb-a92a-41b2-b0a7-f8ea17d72fe0",
            price: 108.2805,
            price_sources: [
              {
                close: 108.2805,
                high: 108.2805,
                lag_ms: 419,
                low: 108.2805,
                open: 108.2805,
                source: "Polygon_ws",
                start_ms: 1720467941999,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 108.281,
                websocket: true,
              },
              {
                close: 108.281,
                high: 108.281,
                lag_ms: 580,
                low: 108.281,
                open: 108.281,
                source: "TwelveData_ws",
                start_ms: 1720467941000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1720467941580,
          },
        ],
        position_type: "FLAT",
        position_uuid: "4462a453-e90a-42dd-ae86-876b81292dd6",
        return_at_close: 1.0007558165082588,
        trade_pair: ["AUDJPY", "AUD/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 57383.568,
        close_ms: 0,
        current_return: 0.9967384878793263,
        initial_entry_price: 57751.74,
        is_closed_position: false,
        miner_hotkey: "5EHpm2UK3CyhH1zZiJmM6erGrzkmVAF9EnT1QLSPhMzQaQHG",
        net_leverage: -0.25,
        open_ms: 1720429812260,
        orders: [
          {
            leverage: -0.1,
            order_type: "SHORT",
            order_uuid: "fde11d75-f483-4b15-a554-a2667d6eff32",
            price: 57751.74,
            price_sources: [
              {
                close: 57751.74,
                high: 57751.74,
                lag_ms: 243,
                low: 57748.71,
                open: 57751.74,
                source: "Polygon_ws",
                start_ms: 1720429810000,
                timespan_ms: 0,
                volume: 0.13175655,
                vwap: 57751.7395,
                websocket: true,
              },
              {
                close: 57936.0,
                high: 57936.0,
                lag_ms: 2244,
                low: 57736.98,
                open: 57736.98,
                source: "Polygon_rest",
                start_ms: 1720429807000,
                timespan_ms: 1000,
                volume: 0.00284326,
                vwap: 57878.1249,
                websocket: false,
              },
              {
                close: 57735.2,
                high: 57735.2,
                lag_ms: 16243,
                low: 57735.2,
                open: 57735.2,
                source: "TwelveData_ws",
                start_ms: 1720429794000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
              {
                close: 57758.14,
                high: 57813.51,
                lag_ms: 70244,
                low: 57747.71,
                open: 57810.4,
                source: "TwelveData_rest",
                start_ms: 1720429680000,
                timespan_ms: 60000,
                volume: null,
                vwap: null,
                websocket: false,
              },
            ],
            processed_ms: 1720429810243,
          },
          {
            leverage: -0.15,
            order_type: "SHORT",
            order_uuid: "c7e918b4-a340-4296-acd3-232feeb75037",
            price: 57138.12,
            price_sources: [
              {
                close: 57138.12,
                high: 57138.12,
                lag_ms: 137,
                low: 57126.87,
                open: 57138.12,
                source: "Polygon_ws",
                start_ms: 1720533278999,
                timespan_ms: 0,
                volume: 0.00749777,
                vwap: 57130.6443,
                websocket: true,
              },
              {
                close: 57105.1,
                high: 57137.2,
                lag_ms: 2863,
                low: 57105.1,
                open: 57137.2,
                source: "Polygon_rest",
                start_ms: 1720533275000,
                timespan_ms: 1000,
                volume: 0.28662922,
                vwap: 57134.4315,
                websocket: false,
              },
              {
                close: 57037.3,
                high: 57037.3,
                lag_ms: 4138,
                low: 57037.3,
                open: 57037.3,
                source: "TwelveData_ws",
                start_ms: 1720533283000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
              {
                close: 57063.72,
                high: 57081.94,
                lag_ms: 38863,
                low: 57012.52,
                open: 57078.38,
                source: "TwelveData_rest",
                start_ms: 1720533180000,
                timespan_ms: 60000,
                volume: null,
                vwap: null,
                websocket: false,
              },
            ],
            processed_ms: 1720533278862,
          },
        ],
        position_type: "SHORT",
        position_uuid: "e9f239f0-8d75-4a48-95cb-c480e0db0ee0",
        return_at_close: 0.9966138955683413,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 1.082165,
        close_ms: 0,
        current_return: 1.0001801943326571,
        initial_entry_price: 1.082165,
        is_closed_position: false,
        miner_hotkey: "5EHpm2UK3CyhH1zZiJmM6erGrzkmVAF9EnT1QLSPhMzQaQHG",
        net_leverage: 1.0,
        open_ms: 1720418694487,
        orders: [
          {
            leverage: 1.0,
            order_type: "LONG",
            order_uuid: "34869739-6821-46be-b5b1-00fc8f7289cb",
            price: 1.082165,
            price_sources: [
              {
                close: 1.082165,
                high: 1.082165,
                lag_ms: 341,
                low: 1.082165,
                open: 1.082165,
                source: "Polygon_ws",
                start_ms: 1720418694000,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 1.08216,
                websocket: true,
              },
              {
                close: 1.0821,
                high: 1.0821,
                lag_ms: 1341,
                low: 1.0821,
                open: 1.0821,
                source: "TwelveData_ws",
                start_ms: 1720418693000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1720418694341,
          },
        ],
        position_type: "LONG",
        position_uuid: "dbf37236-2e56-47d6-ac31-165efbe67c8c",
        return_at_close: 1.0001451880258554,
        trade_pair: ["EURUSD", "EUR/USD", 7e-5, 0.001, 500],
      },
    ],
    thirty_day_returns: 0.9970607230537346,
  },
  "5ELogcu9Vo93cSFK13BY6FZPkJT1uXiRJRur4LKiM6JH7Lti": {
    all_time_returns: 1.0,
    n_positions: 0,
    percentage_profitable: 0.0,
    positions: [
      {
        average_entry_price: 64455.93,
        close_ms: 1711196201205,
        current_return: 1.0012104084139348,
        initial_entry_price: 64455.93,
        is_closed_position: true,
        miner_hotkey: "5ELogcu9Vo93cSFK13BY6FZPkJT1uXiRJRur4LKiM6JH7Lti",
        net_leverage: 0.0,
        open_ms: 1711192630446,
        orders: [
          {
            leverage: 0.6,
            order_type: "LONG",
            order_uuid: "876fab95-d4d2-4a62-8dfb-9bd163271df2",
            price: 64455.93,
            price_sources: [],
            processed_ms: 1711192630446,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "daa66c0e-6a17-4ce8-9ec2-dcd28ffe4eb3",
            price: 64585.96,
            price_sources: [],
            processed_ms: 1711196201205,
          },
        ],
        position_type: "FLAT",
        position_uuid: "877d7ccf-39a1-40c0-990f-e5cbf4fad892",
        return_at_close: 1.000008955923838,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 64591.670000000006,
        close_ms: 1711199861484,
        current_return: 0.9993090749937259,
        initial_entry_price: 64591.67,
        is_closed_position: true,
        miner_hotkey: "5ELogcu9Vo93cSFK13BY6FZPkJT1uXiRJRur4LKiM6JH7Lti",
        net_leverage: 0.0,
        open_ms: 1711196304788,
        orders: [
          {
            leverage: 0.6,
            order_type: "LONG",
            order_uuid: "f0be8760-b7dc-4492-9cd7-362e67770ef4",
            price: 64591.67,
            price_sources: [],
            processed_ms: 1711196304787,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "a2211c2b-6c8b-456f-b262-797e7751ee64",
            price: 64517.29,
            price_sources: [],
            processed_ms: 1711199861484,
          },
        ],
        position_type: "FLAT",
        position_uuid: "ebc5b508-b206-4807-a862-54630e67a7aa",
        return_at_close: 0.9981099041037335,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 64537.34,
        close_ms: 1711203523478,
        current_return: 0.999391608021031,
        initial_entry_price: 64537.34,
        is_closed_position: true,
        miner_hotkey: "5ELogcu9Vo93cSFK13BY6FZPkJT1uXiRJRur4LKiM6JH7Lti",
        net_leverage: 0.0,
        open_ms: 1711199948819,
        orders: [
          {
            leverage: 0.6,
            order_type: "LONG",
            order_uuid: "a26d1959-d118-4924-ba25-81986277b0cc",
            price: 64537.34,
            price_sources: [],
            processed_ms: 1711199948818,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "5040ffd7-d968-4649-9de6-6e927850fb71",
            price: 64471.9,
            price_sources: [],
            processed_ms: 1711203523478,
          },
        ],
        position_type: "FLAT",
        position_uuid: "be1002c2-8371-40b2-8529-15de1462fb74",
        return_at_close: 0.9981923380914057,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 64585.240000000005,
        close_ms: 1711207193429,
        current_return: 1.0039166843693699,
        initial_entry_price: 64585.24,
        is_closed_position: true,
        miner_hotkey: "5ELogcu9Vo93cSFK13BY6FZPkJT1uXiRJRur4LKiM6JH7Lti",
        net_leverage: 0.0,
        open_ms: 1711203610513,
        orders: [
          {
            leverage: 0.6,
            order_type: "LONG",
            order_uuid: "36d977a3-ab96-451e-97fe-7a412ec4e5fb",
            price: 64585.24,
            price_sources: [],
            processed_ms: 1711203610512,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "c9da9cd1-80fe-403f-bc93-9e07b1cb4420",
            price: 65006.84,
            price_sources: [],
            processed_ms: 1711207193429,
          },
        ],
        position_type: "FLAT",
        position_uuid: "f70d31fa-5979-4b5d-a44a-3c44ae42cdb8",
        return_at_close: 1.0027119843481267,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 64805.25000000001,
        close_ms: 1711210847880,
        current_return: 1.0034339810432025,
        initial_entry_price: 64805.25,
        is_closed_position: true,
        miner_hotkey: "5ELogcu9Vo93cSFK13BY6FZPkJT1uXiRJRur4LKiM6JH7Lti",
        net_leverage: 0.0,
        open_ms: 1711207279445,
        orders: [
          {
            leverage: 0.6,
            order_type: "LONG",
            order_uuid: "c7ec4267-7088-49c4-b72f-526e6c9e3b26",
            price: 64805.25,
            price_sources: [],
            processed_ms: 1711207279444,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "6dc76982-c46b-4515-a743-51d1753ecd9c",
            price: 65176.15,
            price_sources: [],
            processed_ms: 1711210847880,
          },
        ],
        position_type: "FLAT",
        position_uuid: "9319e8ff-5c67-424b-9776-b43af96e26b9",
        return_at_close: 1.0022298602659507,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 65137.80999999999,
        close_ms: 1711214514629,
        current_return: 1.0039011443583996,
        initial_entry_price: 65137.81,
        is_closed_position: true,
        miner_hotkey: "5ELogcu9Vo93cSFK13BY6FZPkJT1uXiRJRur4LKiM6JH7Lti",
        net_leverage: 0.0,
        open_ms: 1711210934504,
        orders: [
          {
            leverage: 0.6,
            order_type: "LONG",
            order_uuid: "86753e7a-bc29-4f68-aec1-2954f2896424",
            price: 65137.81,
            price_sources: [],
            processed_ms: 1711210934503,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "738a4d1e-b2bf-4324-bc63-1997cb769e02",
            price: 65561.33,
            price_sources: [],
            processed_ms: 1711214514629,
          },
        ],
        position_type: "FLAT",
        position_uuid: "51e9d976-d2c8-49d4-9a33-a754c561da69",
        return_at_close: 1.0026964629851696,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 65574.08,
        close_ms: 1711218174701,
        current_return: 1.0000308353544571,
        initial_entry_price: 65574.08,
        is_closed_position: true,
        miner_hotkey: "5ELogcu9Vo93cSFK13BY6FZPkJT1uXiRJRur4LKiM6JH7Lti",
        net_leverage: 0.0,
        open_ms: 1711214601568,
        orders: [
          {
            leverage: 0.6,
            order_type: "LONG",
            order_uuid: "fa6f633b-39bd-4b00-a1fb-7eef07c2d067",
            price: 65574.08,
            price_sources: [],
            processed_ms: 1711214601567,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "de4119fe-bee6-4d68-bc3a-47989ee19b85",
            price: 65577.45,
            price_sources: [],
            processed_ms: 1711218174701,
          },
        ],
        position_type: "FLAT",
        position_uuid: "c6f81139-3715-489e-b4e1-7721c2778228",
        return_at_close: 0.9988307983520318,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 65522.740000000005,
        close_ms: 1711221836781,
        current_return: 0.9983450325795289,
        initial_entry_price: 65522.74,
        is_closed_position: true,
        miner_hotkey: "5ELogcu9Vo93cSFK13BY6FZPkJT1uXiRJRur4LKiM6JH7Lti",
        net_leverage: 0.0,
        open_ms: 1711218261501,
        orders: [
          {
            leverage: 0.6,
            order_type: "LONG",
            order_uuid: "2e509354-6680-4308-ab27-773386d83245",
            price: 65522.74,
            price_sources: [],
            processed_ms: 1711218261500,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "4751a4a8-db70-48eb-b34b-620889a8ad28",
            price: 65342.01,
            price_sources: [],
            processed_ms: 1711221836781,
          },
        ],
        position_type: "FLAT",
        position_uuid: "a748cd3a-8476-47f7-98ca-262dc8968eea",
        return_at_close: 0.9971470185404335,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 65301.54,
        close_ms: 1711225490564,
        current_return: 0.9968540711291035,
        initial_entry_price: 65301.54,
        is_closed_position: true,
        miner_hotkey: "5ELogcu9Vo93cSFK13BY6FZPkJT1uXiRJRur4LKiM6JH7Lti",
        net_leverage: 0.0,
        open_ms: 1711221923612,
        orders: [
          {
            leverage: 0.6,
            order_type: "LONG",
            order_uuid: "3a2766ba-1718-4db9-a556-add16961c417",
            price: 65301.54,
            price_sources: [],
            processed_ms: 1711221923611,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "04dbf23c-a9f4-4c02-b0ef-28595ff2a895",
            price: 64959.15,
            price_sources: [],
            processed_ms: 1711225490564,
          },
        ],
        position_type: "FLAT",
        position_uuid: "31f6b77a-884e-4955-9795-d60b2432eb00",
        return_at_close: 0.9956578462437486,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 64948.98,
        close_ms: 1711229160701,
        current_return: 0.9996645674805055,
        initial_entry_price: 64948.98,
        is_closed_position: true,
        miner_hotkey: "5ELogcu9Vo93cSFK13BY6FZPkJT1uXiRJRur4LKiM6JH7Lti",
        net_leverage: 0.0,
        open_ms: 1711225577660,
        orders: [
          {
            leverage: 0.6,
            order_type: "LONG",
            order_uuid: "e650d92a-c3d5-4779-a807-5ef453426379",
            price: 64948.98,
            price_sources: [],
            processed_ms: 1711225577659,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "876db72b-d412-4624-a23c-b293edbc1321",
            price: 64912.67,
            price_sources: [],
            processed_ms: 1711229160701,
          },
        ],
        position_type: "FLAT",
        position_uuid: "3e3eb8fe-61b1-4831-94a9-647601e98300",
        return_at_close: 0.9984649699995289,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 64858.87999999999,
        close_ms: 1711232818648,
        current_return: 0.9997389409129483,
        initial_entry_price: 64858.88,
        is_closed_position: true,
        miner_hotkey: "5ELogcu9Vo93cSFK13BY6FZPkJT1uXiRJRur4LKiM6JH7Lti",
        net_leverage: 0.0,
        open_ms: 1711229247522,
        orders: [
          {
            leverage: 0.6,
            order_type: "LONG",
            order_uuid: "47db7818-3508-4396-b0c5-f05f63c7d0b7",
            price: 64858.88,
            price_sources: [],
            processed_ms: 1711229247521,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "eff584c9-47ed-4a4d-b069-df21f708a0c2",
            price: 64830.66,
            price_sources: [],
            processed_ms: 1711232818648,
          },
        ],
        position_type: "FLAT",
        position_uuid: "dd477e21-8eaa-44e0-9c6b-36370849ebdf",
        return_at_close: 0.9985392541838528,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 64940.770000000004,
        close_ms: 1711236488111,
        current_return: 0.99573639179209,
        initial_entry_price: 64940.77,
        is_closed_position: true,
        miner_hotkey: "5ELogcu9Vo93cSFK13BY6FZPkJT1uXiRJRur4LKiM6JH7Lti",
        net_leverage: 0.0,
        open_ms: 1711232905645,
        orders: [
          {
            leverage: 0.6,
            order_type: "LONG",
            order_uuid: "b154b73d-857b-42da-b82b-9e37562287b6",
            price: 64940.77,
            price_sources: [],
            processed_ms: 1711232905644,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "31285ba0-8de8-4cda-a0a4-de55b76806a7",
            price: 64479.3,
            price_sources: [],
            processed_ms: 1711236488111,
          },
        ],
        position_type: "FLAT",
        position_uuid: "73454050-ec6a-4cda-9ec9-0a10aa0f1124",
        return_at_close: 0.9945415081219394,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 64489.05,
        close_ms: 1711240170796,
        current_return: 0.9990212291854198,
        initial_entry_price: 64489.05,
        is_closed_position: true,
        miner_hotkey: "5ELogcu9Vo93cSFK13BY6FZPkJT1uXiRJRur4LKiM6JH7Lti",
        net_leverage: 0.0,
        open_ms: 1711236574601,
        orders: [
          {
            leverage: 0.6,
            order_type: "LONG",
            order_uuid: "0aa3fc1c-46de-48af-9bb8-6b527ccde4f1",
            price: 64489.05,
            price_sources: [],
            processed_ms: 1711236574600,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "4189d41d-4fc0-4006-b991-a3c68c3d876f",
            price: 64383.85,
            price_sources: [],
            processed_ms: 1711240170796,
          },
        ],
        position_type: "FLAT",
        position_uuid: "68eeeec8-d0ff-4d85-86d9-c0ef4ef03791",
        return_at_close: 0.9978224037103973,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 64382.93000000001,
        close_ms: 1711243809780,
        current_return: 1.002312942265908,
        initial_entry_price: 64382.93,
        is_closed_position: true,
        miner_hotkey: "5ELogcu9Vo93cSFK13BY6FZPkJT1uXiRJRur4LKiM6JH7Lti",
        net_leverage: 0.0,
        open_ms: 1711240273668,
        orders: [
          {
            leverage: 0.6,
            order_type: "LONG",
            order_uuid: "6940d869-2599-4eda-bdeb-b3e0304ccac3",
            price: 64382.93,
            price_sources: [],
            processed_ms: 1711240273667,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "878d6397-cf8d-4b39-8708-3f8052400d2c",
            price: 64631.12,
            price_sources: [],
            processed_ms: 1711243809780,
          },
        ],
        position_type: "FLAT",
        position_uuid: "2cb5a13b-d177-4111-a247-1791ff48522a",
        return_at_close: 1.001110166735189,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 64579.55000000001,
        close_ms: 1711247472638,
        current_return: 0.9979107627724255,
        initial_entry_price: 64579.55,
        is_closed_position: true,
        miner_hotkey: "5ELogcu9Vo93cSFK13BY6FZPkJT1uXiRJRur4LKiM6JH7Lti",
        net_leverage: 0.0,
        open_ms: 1711243939185,
        orders: [
          {
            leverage: 0.6,
            order_type: "LONG",
            order_uuid: "a9580170-014b-4e3b-b263-6287ad08dc32",
            price: 64579.55,
            price_sources: [],
            processed_ms: 1711243939184,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "dd8634ee-aeb3-4f69-bf71-832afd486539",
            price: 64354.68,
            price_sources: [],
            processed_ms: 1711247472638,
          },
        ],
        position_type: "FLAT",
        position_uuid: "6634dee8-cb51-48cf-82ce-09ad31f812ce",
        return_at_close: 0.9967132698570986,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 64290.43000000001,
        close_ms: 1711251138601,
        current_return: 0.9986725240444029,
        initial_entry_price: 64290.43,
        is_closed_position: true,
        miner_hotkey: "5ELogcu9Vo93cSFK13BY6FZPkJT1uXiRJRur4LKiM6JH7Lti",
        net_leverage: 0.0,
        open_ms: 1711247559764,
        orders: [
          {
            leverage: 0.6,
            order_type: "LONG",
            order_uuid: "bb7c92de-860a-435c-a880-0e6e142feb66",
            price: 64290.43,
            price_sources: [],
            processed_ms: 1711247559763,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "4ad6f430-29ca-4cba-bd0e-593a1e191a0e",
            price: 64148.19,
            price_sources: [],
            processed_ms: 1711251138601,
          },
        ],
        position_type: "FLAT",
        position_uuid: "897fa52a-5acf-4721-9237-23c99ac2e87c",
        return_at_close: 0.9974741170155497,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 64133.33,
        close_ms: 1711254802669,
        current_return: 0.998586663128205,
        initial_entry_price: 64133.33,
        is_closed_position: true,
        miner_hotkey: "5ELogcu9Vo93cSFK13BY6FZPkJT1uXiRJRur4LKiM6JH7Lti",
        net_leverage: 0.0,
        open_ms: 1711251225556,
        orders: [
          {
            leverage: 0.6,
            order_type: "LONG",
            order_uuid: "fbd5b47e-c67a-4d79-8887-75ba06fe3b06",
            price: 64133.33,
            price_sources: [],
            processed_ms: 1711251225554,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "f99bf980-fb79-4300-96ea-6c8df493a559",
            price: 63982.26,
            price_sources: [],
            processed_ms: 1711254802669,
          },
        ],
        position_type: "FLAT",
        position_uuid: "30d48442-1633-46f7-bffe-38148753d81b",
        return_at_close: 0.9973883591324512,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 63943.16,
        close_ms: 1711259078966,
        current_return: 1.0033847560864992,
        initial_entry_price: 63943.16,
        is_closed_position: true,
        miner_hotkey: "5ELogcu9Vo93cSFK13BY6FZPkJT1uXiRJRur4LKiM6JH7Lti",
        net_leverage: 0.0,
        open_ms: 1711254889703,
        orders: [
          {
            leverage: 0.6,
            order_type: "LONG",
            order_uuid: "106e203a-fcdd-463e-8440-71e7b220c457",
            price: 63943.16,
            price_sources: [],
            processed_ms: 1711254889701,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "5ab4c1a1-23be-492a-9a0f-39b226e1db3f",
            price: 64303.88,
            price_sources: [],
            processed_ms: 1711259078966,
          },
        ],
        position_type: "FLAT",
        position_uuid: "92903349-d9bb-44cc-bf37-9bf31646bf5d",
        return_at_close: 1.0021806943791955,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 64335.64,
        close_ms: 1711262739664,
        current_return: 0.9977281254993344,
        initial_entry_price: 64335.64,
        is_closed_position: true,
        miner_hotkey: "5ELogcu9Vo93cSFK13BY6FZPkJT1uXiRJRur4LKiM6JH7Lti",
        net_leverage: 0.0,
        open_ms: 1711259207829,
        orders: [
          {
            leverage: 1.25,
            order_type: "LONG",
            order_uuid: "2e9c4db3-d000-47c7-b89e-7ac6d8af87a9",
            price: 64335.64,
            price_sources: [],
            processed_ms: 1711259207827,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "7f0664e6-fcd6-4d20-a5a1-db77153d913b",
            price: 64218.71,
            price_sources: [],
            processed_ms: 1711262739664,
          },
        ],
        position_type: "FLAT",
        position_uuid: "04ed64c6-29e8-4536-9116-d19fff76f785",
        return_at_close: 0.9952338051855861,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 64112.6,
        close_ms: 1711266401603,
        current_return: 1.0045414239946595,
        initial_entry_price: 64112.6,
        is_closed_position: true,
        miner_hotkey: "5ELogcu9Vo93cSFK13BY6FZPkJT1uXiRJRur4LKiM6JH7Lti",
        net_leverage: 0.0,
        open_ms: 1711262828262,
        orders: [
          {
            leverage: 1.25,
            order_type: "LONG",
            order_uuid: "7de4d6bf-e3e4-49a3-b14f-bc6fd4df6a84",
            price: 64112.6,
            price_sources: [],
            processed_ms: 1711262828260,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "2a8cb297-fdf1-4aba-b130-dc252524adcd",
            price: 64345.53,
            price_sources: [],
            processed_ms: 1711266401603,
          },
        ],
        position_type: "FLAT",
        position_uuid: "afcee945-52a4-4b73-a4a6-63d30bb68d52",
        return_at_close: 1.002030070434673,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 64356.44,
        close_ms: 1711270067744,
        current_return: 1.0095023512798407,
        initial_entry_price: 64356.44,
        is_closed_position: true,
        miner_hotkey: "5ELogcu9Vo93cSFK13BY6FZPkJT1uXiRJRur4LKiM6JH7Lti",
        net_leverage: 0.0,
        open_ms: 1711266488579,
        orders: [
          {
            leverage: 1.25,
            order_type: "LONG",
            order_uuid: "2f3c965c-05cb-4654-a2ff-38b24f3e8871",
            price: 64356.44,
            price_sources: [],
            processed_ms: 1711266488577,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "db3205a8-36e7-4768-8069-94a58f44b993",
            price: 64845.67,
            price_sources: [],
            processed_ms: 1711270067744,
          },
        ],
        position_type: "FLAT",
        position_uuid: "8ac4997a-319f-4fd4-8d7c-ed03162d5604",
        return_at_close: 1.006978595401641,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 64892.44,
        close_ms: 1711273733655,
        current_return: 1.0012566949247093,
        initial_entry_price: 64892.44,
        is_closed_position: true,
        miner_hotkey: "5ELogcu9Vo93cSFK13BY6FZPkJT1uXiRJRur4LKiM6JH7Lti",
        net_leverage: 0.0,
        open_ms: 1711270154621,
        orders: [
          {
            leverage: 1.25,
            order_type: "LONG",
            order_uuid: "6802693a-19d3-459e-8142-02d7a95a0759",
            price: 64892.44,
            price_sources: [],
            processed_ms: 1711270154619,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "ef69ab59-6001-4dc7-aac0-172591f62058",
            price: 64957.68,
            price_sources: [],
            processed_ms: 1711273733655,
          },
        ],
        position_type: "FLAT",
        position_uuid: "c31dfd27-844b-4818-b084-d2d93fb52d77",
        return_at_close: 0.9987535531873976,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 64976.56999999999,
        close_ms: 1711277407909,
        current_return: 1.0057803528256417,
        initial_entry_price: 64976.57,
        is_closed_position: true,
        miner_hotkey: "5ELogcu9Vo93cSFK13BY6FZPkJT1uXiRJRur4LKiM6JH7Lti",
        net_leverage: 0.0,
        open_ms: 1711273820591,
        orders: [
          {
            leverage: 1.25,
            order_type: "LONG",
            order_uuid: "71b0a840-9c39-45d3-b064-74c3963d8f81",
            price: 64976.57,
            price_sources: [],
            processed_ms: 1711273820589,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "2394adf3-ee99-4d54-97c6-e1fcaf2b211c",
            price: 65277.04,
            price_sources: [],
            processed_ms: 1711277407909,
          },
        ],
        position_type: "FLAT",
        position_uuid: "b6969791-3052-4d82-b9e6-d6e084f99b38",
        return_at_close: 1.0032659019435777,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 65231.96,
        close_ms: 1711313517895,
        current_return: 1.0200702998959406,
        initial_entry_price: 65231.96,
        is_closed_position: true,
        miner_hotkey: "5ELogcu9Vo93cSFK13BY6FZPkJT1uXiRJRur4LKiM6JH7Lti",
        net_leverage: 0.0,
        open_ms: 1711277510647,
        orders: [
          {
            leverage: 1.25,
            order_type: "LONG",
            order_uuid: "96f5b3cc-a603-4b58-8d95-fddb4c9c6f0f",
            price: 65231.96,
            price_sources: [],
            processed_ms: 1711277510645,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "538e0603-9fca-482b-8a6e-abec2a2db9c7",
            price: 66279.34,
            price_sources: [],
            processed_ms: 1711313517895,
          },
        ],
        position_type: "FLAT",
        position_uuid: "f9e7083c-f70c-4ac2-9ad0-e6d2e77fda9f",
        return_at_close: 1.0175201241462009,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 66346.99,
        close_ms: 1711319111721,
        current_return: 1.007466050833655,
        initial_entry_price: 66346.99,
        is_closed_position: true,
        miner_hotkey: "5ELogcu9Vo93cSFK13BY6FZPkJT1uXiRJRur4LKiM6JH7Lti",
        net_leverage: 0.0,
        open_ms: 1711317255105,
        orders: [
          {
            leverage: 1.25,
            order_type: "LONG",
            order_uuid: "81003e0b-63c3-44bb-8e3f-6fd79ecc3ba0",
            price: 66346.99,
            price_sources: [],
            processed_ms: 1711317255103,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "b32691c5-e64b-4060-a18c-ef4d109efc65",
            price: 66743.27,
            price_sources: [],
            processed_ms: 1711319111721,
          },
        ],
        position_type: "FLAT",
        position_uuid: "20a5015c-969b-4a15-beaf-fe9ad8aec251",
        return_at_close: 1.004947385706571,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 484641.82000000926,
        close_ms: 1711334679122,
        current_return: 1.0077575745818694,
        initial_entry_price: 67335.4,
        is_closed_position: true,
        miner_hotkey: "5ELogcu9Vo93cSFK13BY6FZPkJT1uXiRJRur4LKiM6JH7Lti",
        net_leverage: 0.0,
        open_ms: 1711322838399,
        orders: [
          {
            leverage: -1.25,
            order_type: "SHORT",
            order_uuid: "77cbe24c-d373-409a-a706-bd242ead303a",
            price: 67335.4,
            price_sources: [],
            processed_ms: 1711322837039,
          },
          {
            leverage: 0.125,
            order_type: "LONG",
            order_uuid: "0bf3b363-a793-490f-a1d2-c65b6ceee421",
            price: 67195.94,
            price_sources: [],
            processed_ms: 1711323767724,
          },
          {
            leverage: 0.125,
            order_type: "LONG",
            order_uuid: "907baf6d-ffe2-4a27-9389-9448d7b3a04f",
            price: 67246.58,
            price_sources: [],
            processed_ms: 1711324711828,
          },
          {
            leverage: 0.5,
            order_type: "LONG",
            order_uuid: "29ae8150-5446-4e45-a138-f4619b6d8a9d",
            price: 66942.16,
            price_sources: [],
            processed_ms: 1711325631941,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "d9860089-82ff-425e-993c-10d3e3ae524b",
            price: 66878.36,
            price_sources: [],
            processed_ms: 1711326560021,
          },
          {
            leverage: 0.2,
            order_type: "LONG",
            order_uuid: "836de567-02bb-469d-b64b-ffd03e94c6f4",
            price: 66604.42,
            price_sources: [],
            processed_ms: 1711327491707,
          },
          {
            leverage: 0.039999999999999994,
            order_type: "LONG",
            order_uuid: "4ba7ffea-f3a4-4e72-af96-c2b406ab854c",
            price: 66574.75,
            price_sources: [],
            processed_ms: 1711328452765,
          },
          {
            leverage: 0.0049999999999999975,
            order_type: "LONG",
            order_uuid: "4069ed3e-a101-4db1-b689-64ac3ba1b959",
            price: 66796.48,
            price_sources: [],
            processed_ms: 1711329354948,
          },
          {
            leverage: 0.0024999999999999988,
            order_type: "LONG",
            order_uuid: "36efa873-7487-49bf-a064-20029744c374",
            price: 66607.16,
            price_sources: [],
            processed_ms: 1711331039723,
          },
          {
            leverage: 0.0012499999999999994,
            order_type: "LONG",
            order_uuid: "fca44c34-6e72-4931-8aea-771cee845410",
            price: 66470.74,
            price_sources: [],
            processed_ms: 1711332853776,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "e240a711-bb15-4578-96ad-0c4ad6948aba",
            price: 66754.31,
            price_sources: [],
            processed_ms: 1711334679122,
          },
        ],
        position_type: "FLAT",
        position_uuid: "16664b51-6344-470a-a0da-684bab3fd419",
        return_at_close: 1.0052381806454147,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 32783.89428571659,
        close_ms: 1711354835976,
        current_return: 0.9992872954695502,
        initial_entry_price: 67251.6,
        is_closed_position: true,
        miner_hotkey: "5ELogcu9Vo93cSFK13BY6FZPkJT1uXiRJRur4LKiM6JH7Lti",
        net_leverage: 0.0,
        open_ms: 1711340179011,
        orders: [
          {
            leverage: -1.25,
            order_type: "SHORT",
            order_uuid: "737552d9-d212-454e-991d-ffab4bd0fa1c",
            price: 67251.6,
            price_sources: [],
            processed_ms: 1711340179009,
          },
          {
            leverage: 0.125,
            order_type: "LONG",
            order_uuid: "d79ffb2f-c805-4107-b94e-19b293f27ac9",
            price: 67356.97,
            price_sources: [],
            processed_ms: 1711342007337,
          },
          {
            leverage: 0.125,
            order_type: "LONG",
            order_uuid: "73db0bac-59eb-46b0-8ac0-97899b92952e",
            price: 67537.17,
            price_sources: [],
            processed_ms: 1711343839804,
          },
          {
            leverage: 0.125,
            order_type: "LONG",
            order_uuid: "a0f2db22-bf00-49f2-a5eb-b7f0fd394bd3",
            price: 67415.64,
            price_sources: [],
            processed_ms: 1711345691489,
          },
          {
            leverage: 0.7,
            order_type: "LONG",
            order_uuid: "3fd765cd-defc-4e2f-9671-b6b3027becc6",
            price: 67266.79,
            price_sources: [],
            processed_ms: 1711347504913,
          },
          {
            leverage: 0.14000000000000004,
            order_type: "LONG",
            order_uuid: "664e2783-45ad-4aff-8763-ed07debdd220",
            price: 67072.41,
            price_sources: [],
            processed_ms: 1711349338691,
          },
          {
            leverage: 0.028000000000000004,
            order_type: "LONG",
            order_uuid: "6d6f0e6d-98d0-4b95-b0a1-86320e5be0d0",
            price: 67087.1,
            price_sources: [],
            processed_ms: 1711351187876,
          },
          {
            leverage: 0.005599999999999999,
            order_type: "LONG",
            order_uuid: "d3f59527-92a5-4707-8189-44d5485a2015",
            price: 66884.08,
            price_sources: [],
            processed_ms: 1711352999863,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "7f8e7616-3f9c-401a-b148-aeb25295974f",
            price: 67019.98,
            price_sources: [],
            processed_ms: 1711354835976,
          },
        ],
        position_type: "FLAT",
        position_uuid: "cd36c1f8-5a56-439c-8caa-793e56442f25",
        return_at_close: 0.9967890772308764,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 66883.0,
        close_ms: 1711362167862,
        current_return: 1.0067705919291898,
        initial_entry_price: 66883.0,
        is_closed_position: true,
        miner_hotkey: "5ELogcu9Vo93cSFK13BY6FZPkJT1uXiRJRur4LKiM6JH7Lti",
        net_leverage: 0.0,
        open_ms: 1711356678297,
        orders: [
          {
            leverage: 1.25,
            order_type: "LONG",
            order_uuid: "ccfd4c7a-44aa-49cf-a129-ead50fba12e2",
            price: 66883.0,
            price_sources: [],
            processed_ms: 1711356678295,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "5699e1ec-d645-4de2-a3b9-23478bccd9a4",
            price: 67245.27,
            price_sources: [],
            processed_ms: 1711362167862,
          },
        ],
        position_type: "FLAT",
        position_uuid: "cee9003f-ec3e-4fe4-a6b9-3f3151eeb1dc",
        return_at_close: 1.004253665449367,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 64270.17574646785,
        close_ms: 1711400453259,
        current_return: 1.0276850626973595,
        initial_entry_price: 66989.19,
        is_closed_position: true,
        miner_hotkey: "5ELogcu9Vo93cSFK13BY6FZPkJT1uXiRJRur4LKiM6JH7Lti",
        net_leverage: 0.0,
        open_ms: 1711364001546,
        orders: [
          {
            leverage: 1.25,
            order_type: "LONG",
            order_uuid: "c08aefc2-989b-4b5a-a0fd-27c082793bb3",
            price: 66989.19,
            price_sources: [],
            processed_ms: 1711364001544,
          },
          {
            leverage: -0.625,
            order_type: "SHORT",
            order_uuid: "603d2420-bf5e-47cf-b705-87cb20db6f41",
            price: 66815.43,
            price_sources: [],
            processed_ms: 1711367979551,
          },
          {
            leverage: -0.3125,
            order_type: "SHORT",
            order_uuid: "1226f048-67da-41fb-9a73-a8a64376f9d2",
            price: 67108.97,
            price_sources: [],
            processed_ms: 1711369820622,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "fd3ec228-b798-4daa-9ed7-b6e16dbbada7",
            price: 67104.24,
            price_sources: [],
            processed_ms: 1711371645963,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "2fc81290-1eb9-4536-80b5-eaa40e427109",
            price: 67055.84,
            price_sources: [],
            processed_ms: 1711373507792,
          },
          {
            leverage: -0.65,
            order_type: "SHORT",
            order_uuid: "15103bfc-502f-44ea-8727-901338ec0a9a",
            price: 68239.25,
            price_sources: [],
            processed_ms: 1711375316033,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "9ca16f7f-fcf7-43a8-b29a-c42edfde2268",
            price: 68434.58,
            price_sources: [],
            processed_ms: 1711375892799,
          },
          {
            leverage: -0.20625,
            order_type: "SHORT",
            order_uuid: "d8edbf93-fe8d-4b1f-b3a2-f4a3ac6575fc",
            price: 68925.28,
            price_sources: [],
            processed_ms: 1711376397366,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "c5154bd8-845b-43b8-85ff-3556ac43d9b3",
            price: 69210.38,
            price_sources: [],
            processed_ms: 1711376726519,
          },
          {
            leverage: -0.365,
            order_type: "SHORT",
            order_uuid: "e0fab03c-c027-4d6b-a6b0-1124d468e500",
            price: 69095.56,
            price_sources: [],
            processed_ms: 1711377889372,
          },
          {
            leverage: -0.045625,
            order_type: "SHORT",
            order_uuid: "25aecb29-7abc-42f4-8fe1-5e4565dc92f5",
            price: 69074.0,
            price_sources: [],
            processed_ms: 1711378167490,
          },
          {
            leverage: -0.0228125,
            order_type: "SHORT",
            order_uuid: "e82b12f2-f487-4192-96c7-6382b3b0daf3",
            price: 69824.77,
            price_sources: [],
            processed_ms: 1711381643062,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "32ccb2f1-f15e-4278-b666-77f99425d716",
            price: 69642.62,
            price_sources: [],
            processed_ms: 1711383462212,
          },
          {
            leverage: -0.13640625,
            order_type: "SHORT",
            order_uuid: "8fc5b32f-7c41-4f79-bb7a-fa3ece7d5df4",
            price: 70082.49,
            price_sources: [],
            processed_ms: 1711385297262,
          },
          {
            leverage: -0.068203125,
            order_type: "SHORT",
            order_uuid: "80120937-fa80-45e4-9de7-020c8c0ea0c8",
            price: 70653.37,
            price_sources: [],
            processed_ms: 1711387105753,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "0a68d5f5-976a-4e91-ab5d-49e54c02e8d1",
            price: 70348.66,
            price_sources: [],
            processed_ms: 1711388917227,
          },
          {
            leverage: -0.15910156250000002,
            order_type: "SHORT",
            order_uuid: "b4fe50d9-f154-40b6-a3d1-e320697e749c",
            price: 70644.4,
            price_sources: [],
            processed_ms: 1711390731121,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "de09c819-074a-4956-b319-f963bcfc3022",
            price: 70788.29,
            price_sources: [],
            processed_ms: 1711392550366,
          },
          {
            leverage: -0.20455078125,
            order_type: "SHORT",
            order_uuid: "f3107775-d1d4-4fc3-8c69-a8ee6a4067d8",
            price: 70892.99,
            price_sources: [],
            processed_ms: 1711393165454,
          },
          {
            leverage: 0.8999999999999999,
            order_type: "LONG",
            order_uuid: "edc49a65-aed7-40d6-952a-dff51d538848",
            price: 70644.9,
            price_sources: [],
            processed_ms: 1711395007852,
          },
          {
            leverage: -0.552275390625,
            order_type: "SHORT",
            order_uuid: "9806d283-13a2-4cf6-930d-8f35e298d1c0",
            price: 71078.77,
            price_sources: [],
            processed_ms: 1711396797874,
          },
          {
            leverage: -0.2761376953125,
            order_type: "SHORT",
            order_uuid: "c1029ce9-ccbe-41bb-9a70-d3696785c515",
            price: 70886.55,
            price_sources: [],
            processed_ms: 1711398618321,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "1925865a-44bf-490c-b7d5-ebb6ca3f9b48",
            price: 70986.39,
            price_sources: [],
            processed_ms: 1711400453259,
          },
        ],
        position_type: "FLAT",
        position_uuid: "465ec8a1-b786-40df-8d8f-5133d389fd35",
        return_at_close: 1.0251158500406161,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 69833.05875,
        close_ms: 1711406740009,
        current_return: 1.0531791733166955,
        initial_entry_price: 69623.76,
        is_closed_position: true,
        miner_hotkey: "5ELogcu9Vo93cSFK13BY6FZPkJT1uXiRJRur4LKiM6JH7Lti",
        net_leverage: 0.0,
        open_ms: 1711402259850,
        orders: [
          {
            leverage: 3.0,
            order_type: "LONG",
            order_uuid: "b6eff323-6fc2-4f43-b04d-b4a0991a138a",
            price: 69623.76,
            price_sources: [],
            processed_ms: 1711402259848,
          },
          {
            leverage: 0.8999999999999999,
            order_type: "LONG",
            order_uuid: "22d55488-5308-46c5-a207-4b251a27667a",
            price: 69871.16,
            price_sources: [],
            processed_ms: 1711404080268,
          },
          {
            leverage: 0.8999999999999999,
            order_type: "LONG",
            order_uuid: "c596eb29-77b1-4d17-8ffd-46be9c1ad90e",
            price: 70492.62,
            price_sources: [],
            processed_ms: 1711405903028,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "1c907eb3-8763-4e2d-81d0-c6bc2f8bffb9",
            price: 70604.42,
            price_sources: [],
            processed_ms: 1711406740009,
          },
        ],
        position_type: "FLAT",
        position_uuid: "0a0a9058-d4a1-4d20-8199-df0bd5c29ea6",
        return_at_close: 1.0430686532528552,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 68010.68473684225,
        close_ms: 1711422249787,
        current_return: 1.0099666515441998,
        initial_entry_price: 70226.64,
        is_closed_position: true,
        miner_hotkey: "5ELogcu9Vo93cSFK13BY6FZPkJT1uXiRJRur4LKiM6JH7Lti",
        net_leverage: 0.0,
        open_ms: 1711409529171,
        orders: [
          {
            leverage: 3.0,
            order_type: "LONG",
            order_uuid: "49e2d9d2-e674-4b9b-bb9a-5b7946484d1c",
            price: 70226.64,
            price_sources: [],
            processed_ms: 1711409529168,
          },
          {
            leverage: 0.8999999999999999,
            order_type: "LONG",
            order_uuid: "32d0287b-9f8a-4c92-82bc-cd05ee2df85b",
            price: 69822.87,
            price_sources: [],
            processed_ms: 1711411362409,
          },
          {
            leverage: 0.8999999999999999,
            order_type: "LONG",
            order_uuid: "96b5d3e2-a3ea-4750-8446-e36e5411af7e",
            price: 70140.44,
            price_sources: [],
            processed_ms: 1711413161475,
          },
          {
            leverage: 0.8999999999999995,
            order_type: "LONG",
            order_uuid: "edc16f47-0124-461c-b399-8b22e5f4649d",
            price: 69843.02,
            price_sources: [],
            processed_ms: 1711414982443,
          },
          {
            leverage: -2.8499999999999996,
            order_type: "SHORT",
            order_uuid: "7b9f24cc-cf82-4e08-a12f-4895bb81eda7",
            price: 70200.56,
            price_sources: [],
            processed_ms: 1711416812773,
          },
          {
            leverage: -1.4249999999999998,
            order_type: "SHORT",
            order_uuid: "b7c0b9a7-f8fc-4969-89c5-8322876e290f",
            price: 70157.49,
            price_sources: [],
            processed_ms: 1711418612057,
          },
          {
            leverage: -1.14,
            order_type: "SHORT",
            order_uuid: "ff23a65d-0388-46e8-9b56-b5c81ed56f28",
            price: 70242.59,
            price_sources: [],
            processed_ms: 1711420426796,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "a4c25320-2961-4028-b642-a379829a43ce",
            price: 70466.56,
            price_sources: [],
            processed_ms: 1711422249787,
          },
        ],
        position_type: "FLAT",
        position_uuid: "322b94bd-6f27-44b2-b1cd-94cd7719e29b",
        return_at_close: 0.998453031716596,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 69264.02000000005,
        close_ms: 1711431337146,
        current_return: 0.9951405793413743,
        initial_entry_price: 70465.19,
        is_closed_position: true,
        miner_hotkey: "5ELogcu9Vo93cSFK13BY6FZPkJT1uXiRJRur4LKiM6JH7Lti",
        net_leverage: 0.0,
        open_ms: 1711424064838,
        orders: [
          {
            leverage: -3.0,
            order_type: "SHORT",
            order_uuid: "3b5757a3-33ba-4c57-82ac-c8ae022b1157",
            price: 70465.19,
            price_sources: [],
            processed_ms: 1711424064836,
          },
          {
            leverage: 0.8999999999999999,
            order_type: "LONG",
            order_uuid: "b205fbe7-3abf-4817-84bb-d0755081538f",
            price: 70645.81,
            price_sources: [],
            processed_ms: 1711425883063,
          },
          {
            leverage: 0.8999999999999999,
            order_type: "LONG",
            order_uuid: "5340366b-3d94-4ac6-af93-b4c9bc10cea9",
            price: 70599.56,
            price_sources: [],
            processed_ms: 1711427713227,
          },
          {
            leverage: 0.8999999999999999,
            order_type: "LONG",
            order_uuid: "ef3a9b9b-718a-44ff-a6d9-20de3dd1ff51",
            price: 70550.59,
            price_sources: [],
            processed_ms: 1711429519053,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "217a6ddc-0797-44bb-afa4-e1571d60e674",
            price: 70405.42,
            price_sources: [],
            processed_ms: 1711431337146,
          },
        ],
        position_type: "FLAT",
        position_uuid: "d89371c2-3787-4a21-b955-1de0faf1b78b",
        return_at_close: 0.9891697358653261,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 65623.94999999998,
        close_ms: 1711440425323,
        current_return: 0.9783224424557063,
        initial_entry_price: 70366.23,
        is_closed_position: true,
        miner_hotkey: "5ELogcu9Vo93cSFK13BY6FZPkJT1uXiRJRur4LKiM6JH7Lti",
        net_leverage: 0.0,
        open_ms: 1711433176454,
        orders: [
          {
            leverage: -3.0,
            order_type: "SHORT",
            order_uuid: "6c6738aa-400c-41d9-ba56-a7bfcb54f27a",
            price: 70366.23,
            price_sources: [],
            processed_ms: 1711433176451,
          },
          {
            leverage: 0.8999999999999999,
            order_type: "LONG",
            order_uuid: "ef0dcf7b-a117-46c9-9117-174a82e5a1fa",
            price: 70583.6,
            price_sources: [],
            processed_ms: 1711434977257,
          },
          {
            leverage: 0.8999999999999999,
            order_type: "LONG",
            order_uuid: "0caf6fe7-8e33-4fe3-8208-3014de66d741",
            price: 70797.69,
            price_sources: [],
            processed_ms: 1711436787055,
          },
          {
            leverage: 0.8999999999999999,
            order_type: "LONG",
            order_uuid: "4a81a0ab-17c4-4d42-acfe-ac62704532c0",
            price: 71298.16,
            price_sources: [],
            processed_ms: 1711438622690,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "4a3ba7f5-90eb-4b69-a023-2be617af91aa",
            price: 70708.51,
            price_sources: [],
            processed_ms: 1711440425323,
          },
        ],
        position_type: "FLAT",
        position_uuid: "94eff0ed-3a3f-4800-9910-b0f35b024ab2",
        return_at_close: 0.972452507800972,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 70482.85662983425,
        close_ms: 1711462720095,
        current_return: 0.9720976801123773,
        initial_entry_price: 70619.8,
        is_closed_position: true,
        miner_hotkey: "5ELogcu9Vo93cSFK13BY6FZPkJT1uXiRJRur4LKiM6JH7Lti",
        net_leverage: 0.0,
        open_ms: 1711442246075,
        orders: [
          {
            leverage: 3.0,
            order_type: "LONG",
            order_uuid: "edfdc829-0541-4a14-bc2b-e516aaa50198",
            price: 70619.8,
            price_sources: [],
            processed_ms: 1711442246073,
          },
          {
            leverage: 0.8999999999999999,
            order_type: "LONG",
            order_uuid: "549513ce-fb92-444b-9f06-358a4853e796",
            price: 70651.6,
            price_sources: [],
            processed_ms: 1711444055182,
          },
          {
            leverage: -1.95,
            order_type: "SHORT",
            order_uuid: "1d6f13f0-3231-4a9c-b2a5-3bbab0bef2ee",
            price: 70977.82,
            price_sources: [],
            processed_ms: 1711445918805,
          },
          {
            leverage: -0.975,
            order_type: "SHORT",
            order_uuid: "5496ebc6-5d6d-4e10-a2ce-c73a6e7dea6f",
            price: 71083.91,
            price_sources: [],
            processed_ms: 1711447699175,
          },
          {
            leverage: -0.4875,
            order_type: "SHORT",
            order_uuid: "dac373b6-b827-41bd-b943-0c33a68d6316",
            price: 70913.33,
            price_sources: [],
            processed_ms: 1711449506061,
          },
          {
            leverage: 0.8999999999999999,
            order_type: "LONG",
            order_uuid: "b8c6f77f-e278-4b01-9491-678a9393c9fa",
            price: 70821.28,
            price_sources: [],
            processed_ms: 1711451324260,
          },
          {
            leverage: 0.8999999999999997,
            order_type: "LONG",
            order_uuid: "f88c9446-058d-439f-ae7f-c7e5ce647186",
            price: 70663.4,
            price_sources: [],
            processed_ms: 1711453151075,
          },
          {
            leverage: 0.8999999999999999,
            order_type: "LONG",
            order_uuid: "6efcca0b-48c1-4dfb-85d8-9eb707174483",
            price: 70587.74,
            price_sources: [],
            processed_ms: 1711454960210,
          },
          {
            leverage: 0.8999999999999999,
            order_type: "LONG",
            order_uuid: "e385e695-7833-4800-9476-c77a3e5f484d",
            price: 70972.67,
            price_sources: [],
            processed_ms: 1711456776059,
          },
          {
            leverage: 0.8999999999999995,
            order_type: "LONG",
            order_uuid: "aff79999-67db-49b5-a9bf-7427bc982bd6",
            price: 70923.07,
            price_sources: [],
            processed_ms: 1711458595329,
          },
          {
            leverage: 0.9000000000000004,
            order_type: "LONG",
            order_uuid: "ece0ba50-a68b-4337-bcac-2a0c59a74a27",
            price: 70294.87,
            price_sources: [],
            processed_ms: 1711460409145,
          },
          {
            leverage: 0.9000000000000004,
            order_type: "LONG",
            order_uuid: "6b3b25ce-8a84-45c3-abb8-5923a16eab13",
            price: 70448.48,
            price_sources: [],
            processed_ms: 1711462255570,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "6db8ae44-131a-41a2-8fe5-6434e287c902",
            price: 70192.55,
            price_sources: [],
            processed_ms: 1711462720095,
          },
        ],
        position_type: "FLAT",
        position_uuid: "9ac50b06-4fc4-43cb-b90f-63866fe5d0b0",
        return_at_close: 0.9589014541048517,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 69869.45181818184,
        close_ms: 1711471532868,
        current_return: 0.9697649670154807,
        initial_entry_price: 69687.24,
        is_closed_position: true,
        miner_hotkey: "5ELogcu9Vo93cSFK13BY6FZPkJT1uXiRJRur4LKiM6JH7Lti",
        net_leverage: 0.0,
        open_ms: 1711464044958,
        orders: [
          {
            leverage: 3.0,
            order_type: "LONG",
            order_uuid: "bfa898c1-838c-434f-8c08-de4c6ed41dcb",
            price: 69687.24,
            price_sources: [],
            processed_ms: 1711464044955,
          },
          {
            leverage: 0.8999999999999999,
            order_type: "LONG",
            order_uuid: "aff3b616-29e2-4cf8-abc1-7a934d5c3ca9",
            price: 69930.15,
            price_sources: [],
            processed_ms: 1711465867148,
          },
          {
            leverage: 0.8999999999999999,
            order_type: "LONG",
            order_uuid: "ccef1b51-f696-4988-98ce-b66d3606a677",
            price: 70334.29,
            price_sources: [],
            processed_ms: 1711467684025,
          },
          {
            leverage: 0.8999999999999995,
            order_type: "LONG",
            order_uuid: "1c0aa8d6-4ee7-4c43-b791-89d2d714d15c",
            price: 70181.79,
            price_sources: [],
            processed_ms: 1711469506171,
          },
          {
            leverage: 0.9000000000000004,
            order_type: "LONG",
            order_uuid: "5ef652e9-779e-4852-8d39-64df7cd9cd88",
            price: 69638.95,
            price_sources: [],
            processed_ms: 1711471322260,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "d39fdb7a-2ebf-485b-b51d-15c387abb215",
            price: 69550.21,
            price_sources: [],
            processed_ms: 1711471532868,
          },
        ],
        position_type: "FLAT",
        position_uuid: "0009cc99-55af-4c12-9fe7-0a554027c582",
        return_at_close: 0.9569640694508763,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 69878.7424,
        close_ms: 1711483273204,
        current_return: 0.9533396311153949,
        initial_entry_price: 69558.88,
        is_closed_position: true,
        miner_hotkey: "5ELogcu9Vo93cSFK13BY6FZPkJT1uXiRJRur4LKiM6JH7Lti",
        net_leverage: 0.0,
        open_ms: 1711473133166,
        orders: [
          {
            leverage: 3.0,
            order_type: "LONG",
            order_uuid: "b65f2ee0-2179-48a9-a932-8c4444342254",
            price: 69558.88,
            price_sources: [],
            processed_ms: 1711473133163,
          },
          {
            leverage: 0.8999999999999999,
            order_type: "LONG",
            order_uuid: "405b642f-2359-4336-a191-594bb5397a29",
            price: 70055.98,
            price_sources: [],
            processed_ms: 1711474955011,
          },
          {
            leverage: 0.8999999999999999,
            order_type: "LONG",
            order_uuid: "cccf2e60-296e-44f9-aefa-d562fac4cfef",
            price: 70254.56,
            price_sources: [],
            processed_ms: 1711476773599,
          },
          {
            leverage: 0.8999999999999995,
            order_type: "LONG",
            order_uuid: "c8468834-8b54-43cb-b69b-7966e9d846f3",
            price: 70306.58,
            price_sources: [],
            processed_ms: 1711478588423,
          },
          {
            leverage: 0.9000000000000004,
            order_type: "LONG",
            order_uuid: "11d659ca-7828-4bb9-8f28-92131961358f",
            price: 69937.05,
            price_sources: [],
            processed_ms: 1711480403167,
          },
          {
            leverage: 0.9000000000000004,
            order_type: "LONG",
            order_uuid: "4565862b-dbff-44b3-982a-3b70e1f949d5",
            price: 69905.75,
            price_sources: [],
            processed_ms: 1711482223331,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "4bb9fb43-f11a-4bbb-8ef4-99fe370c11fb",
            price: 69445.99,
            price_sources: [],
            processed_ms: 1711483273204,
          },
        ],
        position_type: "FLAT",
        position_uuid: "dccdfaba-4e60-48ba-b2b7-4ebb9f9382bc",
        return_at_close: 0.9390395366486639,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
    ],
    thirty_day_returns: 1.0,
  },
  "5ENUBskpNCJHx48CySr14PBHB94TccE9SSmf7mmw6LsZ2dyK": {
    all_time_returns: 1.0,
    n_positions: 0,
    percentage_profitable: 0.0,
    positions: [
      {
        average_entry_price: 64273.37,
        close_ms: 1711386459445,
        current_return: 0.9012917791614165,
        initial_entry_price: 64273.37,
        is_closed_position: true,
        miner_hotkey: "5ENUBskpNCJHx48CySr14PBHB94TccE9SSmf7mmw6LsZ2dyK",
        net_leverage: -1.0,
        open_ms: 1711191472597,
        orders: [
          {
            leverage: -1.0,
            order_type: "SHORT",
            order_uuid: "2410dd7b-6639-4ccd-8b07-170741de9433",
            price: 64273.37,
            price_sources: [],
            processed_ms: 1711191472596,
          },
        ],
        position_type: "FLAT",
        position_uuid: "18dcc6af-014c-41de-91d8-cc593fcf9312",
        return_at_close: 0.8994891956030936,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
    ],
    thirty_day_returns: 1.0,
  },
  "5EPDfdoeYhygXYEJ9xo8DV6kLuQZnrZgvH87sqci7WDM2j4g": {
    all_time_returns: 1.085354553145805,
    n_positions: 31,
    percentage_profitable: 0.967741935483871,
    positions: [
      {
        average_entry_price: 3263.66,
        close_ms: 1713022537145,
        current_return: 1.0006327252226028,
        initial_entry_price: 3263.66,
        is_closed_position: true,
        miner_hotkey: "5EPDfdoeYhygXYEJ9xo8DV6kLuQZnrZgvH87sqci7WDM2j4g",
        net_leverage: 0.0,
        open_ms: 1713020201510,
        orders: [
          {
            leverage: 0.1,
            order_type: "LONG",
            order_uuid: "82c74ba1-c086-4f8a-a79a-e3888eb7e1ab",
            price: 3263.66,
            price_sources: [],
            processed_ms: 1713020201510,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "4bf56b5c-e7c5-4cd6-b11b-22e5e9084baa",
            price: 3284.31,
            price_sources: [],
            processed_ms: 1713022537145,
          },
        ],
        position_type: "FLAT",
        position_uuid: "4a507a32-594a-4ffe-8358-5d7e68f3f968",
        return_at_close: 1.0005326619500805,
        trade_pair: ["ETHUSD", "ETH/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 3070.39,
        close_ms: 1713132101376,
        current_return: 1.0000625327727097,
        initial_entry_price: 3070.39,
        is_closed_position: true,
        miner_hotkey: "5EPDfdoeYhygXYEJ9xo8DV6kLuQZnrZgvH87sqci7WDM2j4g",
        net_leverage: 0.0,
        open_ms: 1713131938553,
        orders: [
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "111a4864-d866-45cd-b622-9c4e297ef5a9",
            price: 3070.39,
            price_sources: [],
            processed_ms: 1713131938553,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "e650a64f-1af0-46f6-b39e-5315ceefc835",
            price: 3089.59,
            price_sources: [],
            processed_ms: 1713132101376,
          },
        ],
        position_type: "FLAT",
        position_uuid: "a4e20cc8-5b53-43a0-8d6b-fcf16970d133",
        return_at_close: 1.000052532147382,
        trade_pair: ["ETHUSD", "ETH/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 0.85344,
        close_ms: 1713183389321,
        current_return: 1.0000003046494188,
        initial_entry_price: 0.85344,
        is_closed_position: true,
        miner_hotkey: "5EPDfdoeYhygXYEJ9xo8DV6kLuQZnrZgvH87sqci7WDM2j4g",
        net_leverage: 0.0,
        open_ms: 1713180735785,
        orders: [
          {
            leverage: 0.001,
            order_type: "LONG",
            order_uuid: "63d93bab-b9a4-4ec8-afe9-737eeda85e41",
            price: 0.85344,
            price_sources: [],
            processed_ms: 1713180735784,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "cbfcbf4e-d90c-4e7a-bf4f-dc5952cf205d",
            price: 0.8537,
            price_sources: [],
            processed_ms: 1713183389321,
          },
        ],
        position_type: "FLAT",
        position_uuid: "c11729bf-a1c8-4ed5-9093-d31b8f12b2f7",
        return_at_close: 1.0000002346493975,
        trade_pair: ["EURGBP", "EUR/GBP", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 91.456,
        close_ms: 1713183519230,
        current_return: 1.0000003280265921,
        initial_entry_price: 91.456,
        is_closed_position: true,
        miner_hotkey: "5EPDfdoeYhygXYEJ9xo8DV6kLuQZnrZgvH87sqci7WDM2j4g",
        net_leverage: 0.0,
        open_ms: 1713180810775,
        orders: [
          {
            leverage: -0.001,
            order_type: "SHORT",
            order_uuid: "83b4948c-d7dc-4afc-9417-352063ae9864",
            price: 91.456,
            price_sources: [],
            processed_ms: 1713180810774,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "eb82411e-dc6c-43a9-8d7c-f813b7a08260",
            price: 91.426,
            price_sources: [],
            processed_ms: 1713183519230,
          },
        ],
        position_type: "FLAT",
        position_uuid: "19b0e6ea-f479-4029-867b-920c1b45bc3e",
        return_at_close: 1.0000002580265692,
        trade_pair: ["NZDJPY", "NZD/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 1.79348,
        close_ms: 1713183692652,
        current_return: 1.0000002230300868,
        initial_entry_price: 1.79348,
        is_closed_position: true,
        miner_hotkey: "5EPDfdoeYhygXYEJ9xo8DV6kLuQZnrZgvH87sqci7WDM2j4g",
        net_leverage: 0.0,
        open_ms: 1713180769298,
        orders: [
          {
            leverage: 0.001,
            order_type: "LONG",
            order_uuid: "ae8f267f-4782-4486-bfa6-5672b5df4b5c",
            price: 1.79348,
            price_sources: [],
            processed_ms: 1713180769297,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "e1b20e60-f117-4a1c-ae20-7ac4d56efe33",
            price: 1.79388,
            price_sources: [],
            processed_ms: 1713183692652,
          },
        ],
        position_type: "FLAT",
        position_uuid: "2fdb1c35-febb-4ff5-ab64-df271da7e01f",
        return_at_close: 1.0000001530300713,
        trade_pair: ["EURNZD", "EUR/NZD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 164.03099999999998,
        close_ms: 1713184694929,
        current_return: 1.0000018773962709,
        initial_entry_price: 164.057,
        is_closed_position: true,
        miner_hotkey: "5EPDfdoeYhygXYEJ9xo8DV6kLuQZnrZgvH87sqci7WDM2j4g",
        net_leverage: 0.0,
        open_ms: 1713180753069,
        orders: [
          {
            leverage: 0.001,
            order_type: "LONG",
            order_uuid: "89d2cc4b-6649-42d4-93bb-021942700e0f",
            price: 164.057,
            price_sources: [],
            processed_ms: 1713180753069,
          },
          {
            leverage: 0.001,
            order_type: "LONG",
            order_uuid: "64325459-87af-4041-81fb-5334b0100121",
            price: 164.005,
            price_sources: [],
            processed_ms: 1713183660607,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "a22c0484-e3e0-4502-926e-139398bc2fa8",
            price: 164.185,
            price_sources: [],
            processed_ms: 1713184694929,
          },
        ],
        position_type: "FLAT",
        position_uuid: "6a575bee-6ba7-4525-90f6-3c83c0657415",
        return_at_close: 1.000001737396008,
        trade_pair: ["EURJPY", "EUR/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 154.153,
        close_ms: 1713203499543,
        current_return: 1.000002789436469,
        initial_entry_price: 154.153,
        is_closed_position: true,
        miner_hotkey: "5EPDfdoeYhygXYEJ9xo8DV6kLuQZnrZgvH87sqci7WDM2j4g",
        net_leverage: 0.0,
        open_ms: 1713202669745,
        orders: [
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "9c926824-747a-42ac-ac88-487a2eb0f8a5",
            price: 154.153,
            price_sources: [],
            processed_ms: 1713202669744,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "57a6806b-557a-4872-a116-381e941e343c",
            price: 154.196,
            price_sources: [],
            processed_ms: 1713203499543,
          },
        ],
        position_type: "FLAT",
        position_uuid: "19f6d294-9719-488e-81c2-a671cb8a3a85",
        return_at_close: 1.0000020894345163,
        trade_pair: ["USDJPY", "USD/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 1.464244,
        close_ms: 1713211444559,
        current_return: 1.0000009763891355,
        initial_entry_price: 1.46458,
        is_closed_position: true,
        miner_hotkey: "5EPDfdoeYhygXYEJ9xo8DV6kLuQZnrZgvH87sqci7WDM2j4g",
        net_leverage: 0.0,
        open_ms: 1713180684983,
        orders: [
          {
            leverage: 0.001,
            order_type: "LONG",
            order_uuid: "e2400874-e995-4958-b4cf-6d9f8e1c7e59",
            price: 1.46458,
            price_sources: [],
            processed_ms: 1713180684983,
          },
          {
            leverage: 0.001,
            order_type: "LONG",
            order_uuid: "b32e45b8-24f0-42c8-a6c9-d435022360e0",
            price: 1.4641,
            price_sources: [],
            processed_ms: 1713183634768,
          },
          {
            leverage: 0.003,
            order_type: "LONG",
            order_uuid: "ffa7eada-77d9-422f-af7f-7a26a2e62b3b",
            price: 1.46418,
            price_sources: [],
            processed_ms: 1713202737548,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "39995400-8c0e-4322-8c4c-c56beef4f4b5",
            price: 1.46453,
            price_sources: [],
            processed_ms: 1713211444559,
          },
        ],
        position_type: "FLAT",
        position_uuid: "59f2c4e3-6603-4cfa-a990-33605500a682",
        return_at_close: 1.0000006263887937,
        trade_pair: ["EURCAD", "EUR/CAD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 1.4689800000000004,
        close_ms: 1713216069996,
        current_return: 1.0000029899039544,
        initial_entry_price: 1.46493,
        is_closed_position: true,
        miner_hotkey: "5EPDfdoeYhygXYEJ9xo8DV6kLuQZnrZgvH87sqci7WDM2j4g",
        net_leverage: 0.0,
        open_ms: 1713213100696,
        orders: [
          {
            leverage: -0.01,
            order_type: "SHORT",
            order_uuid: "6304778a-157f-47f3-9df1-28fe0a246523",
            price: 1.46493,
            price_sources: [],
            processed_ms: 1713213100694,
          },
          {
            leverage: 0.009,
            order_type: "LONG",
            order_uuid: "8b33ac64-d3bd-4497-a943-4e29f9813af8",
            price: 1.46448,
            price_sources: [],
            processed_ms: 1713216039937,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "6ec270a0-f026-4532-843b-037f717cebef",
            price: 1.4646,
            price_sources: [],
            processed_ms: 1713216069996,
          },
        ],
        position_type: "FLAT",
        position_uuid: "745c5017-9f62-45e3-be61-11b336fc58af",
        return_at_close: 1.0000022899018615,
        trade_pair: ["EURCAD", "EUR/CAD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.8053263544500049,
        close_ms: 1713225998582,
        current_return: 1.0001025483884667,
        initial_entry_price: 0.8165555476,
        is_closed_position: true,
        miner_hotkey: "5EPDfdoeYhygXYEJ9xo8DV6kLuQZnrZgvH87sqci7WDM2j4g",
        net_leverage: 0.0,
        open_ms: 1713180795757,
        orders: [
          {
            leverage: 0.001,
            order_type: "LONG",
            order_uuid: "5c003590-768c-4794-a755-a6aab6556b73",
            price: 0.8165555476,
            price_sources: [],
            processed_ms: 1713180794627,
          },
          {
            leverage: 0.001,
            order_type: "LONG",
            order_uuid: "11e24270-dcb4-454d-a008-b895c15f1c00",
            price: 0.81591,
            price_sources: [],
            processed_ms: 1713183577464,
          },
          {
            leverage: 0.003,
            order_type: "LONG",
            order_uuid: "80203143-48a6-4426-bc88-22ce486ab452",
            price: 0.8143176403,
            price_sources: [],
            processed_ms: 1713202704150,
          },
          {
            leverage: 0.005,
            order_type: "LONG",
            order_uuid: "9bcfb877-cfe8-4ed3-a241-74f4e8f62f7f",
            price: 0.81418,
            price_sources: [],
            processed_ms: 1713203551632,
          },
          {
            leverage: 0.09,
            order_type: "LONG",
            order_uuid: "4a82d9bc-37e1-4060-a332-2da819d3b285",
            price: 0.81362,
            price_sources: [],
            processed_ms: 1713213122031,
          },
          {
            leverage: -0.09,
            order_type: "SHORT",
            order_uuid: "d3f1c1c0-ef59-4116-91a0-93c96dfd67db",
            price: 0.8140039436,
            price_sources: [],
            processed_ms: 1713216124145,
          },
          {
            leverage: 0.15,
            order_type: "LONG",
            order_uuid: "6df3e17b-2370-4cd0-a8bf-33faedb4ef5c",
            price: 0.8137,
            price_sources: [],
            processed_ms: 1713216236933,
          },
          {
            leverage: -0.15,
            order_type: "SHORT",
            order_uuid: "c6cbbaca-63ad-445e-a6d5-2d257e373bed",
            price: 0.81409,
            price_sources: [],
            processed_ms: 1713225968668,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "19e953cd-c141-4b40-bedf-3b64363dcf22",
            price: 0.8137,
            price_sources: [],
            processed_ms: 1713225998582,
          },
        ],
        position_type: "FLAT",
        position_uuid: "061be2d3-e403-4c9a-ad33-fd99bb399817",
        return_at_close: 1.00008504659387,
        trade_pair: ["NZDCAD", "NZD/CAD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.9695569999999999,
        close_ms: 1713350741460,
        current_return: 1.0000253436558608,
        initial_entry_price: 0.97263,
        is_closed_position: true,
        miner_hotkey: "5EPDfdoeYhygXYEJ9xo8DV6kLuQZnrZgvH87sqci7WDM2j4g",
        net_leverage: 0.0,
        open_ms: 1713180722709,
        orders: [
          {
            leverage: 0.001,
            order_type: "LONG",
            order_uuid: "66145cfd-9bc5-490c-aa70-d867af27d87c",
            price: 0.97263,
            price_sources: [],
            processed_ms: 1713180722708,
          },
          {
            leverage: 0.001,
            order_type: "LONG",
            order_uuid: "1bf8c659-437e-40a9-8a76-b3d1e74d1efe",
            price: 0.97227,
            price_sources: [],
            processed_ms: 1713183618141,
          },
          {
            leverage: 0.003,
            order_type: "LONG",
            order_uuid: "404517b8-c536-45d8-b15d-d77ea98eb105",
            price: 0.9702,
            price_sources: [],
            processed_ms: 1713202720798,
          },
          {
            leverage: 0.005,
            order_type: "LONG",
            order_uuid: "f17ada78-10ac-4128-9325-0e516ad648fc",
            price: 0.97001,
            price_sources: [],
            processed_ms: 1713203534687,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "d87dfcb5-9830-452f-8cb9-b221e85700e5",
            price: 0.96927,
            price_sources: [],
            processed_ms: 1713317656801,
          },
          {
            leverage: 0.03,
            order_type: "LONG",
            order_uuid: "ba236464-91bb-49bb-b364-f073375dcdce",
            price: 0.96932,
            price_sources: [],
            processed_ms: 1713318609128,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "ef92d83b-10c8-4757-80ab-efabd7d4f8fa",
            price: 0.97005,
            price_sources: [],
            processed_ms: 1713350741460,
          },
        ],
        position_type: "FLAT",
        position_uuid: "2d3a477d-ba04-4d5b-a7df-03064145587b",
        return_at_close: 1.000021843567158,
        trade_pair: ["EURCHF", "EUR/CHF", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 150.28000000000029,
        close_ms: 1713733954190,
        current_return: 1.0000882873535155,
        initial_entry_price: 163.84,
        is_closed_position: true,
        miner_hotkey: "5EPDfdoeYhygXYEJ9xo8DV6kLuQZnrZgvH87sqci7WDM2j4g",
        net_leverage: 0.0,
        open_ms: 1713213076833,
        orders: [
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "02b34685-6f91-4d3b-acc5-f8328c516aaa",
            price: 163.84,
            price_sources: [],
            processed_ms: 1713213076831,
          },
          {
            leverage: -0.009,
            order_type: "SHORT",
            order_uuid: "e0b5cd6c-4879-4f2f-b47f-c1c17bde6181",
            price: 163.84,
            price_sources: [],
            processed_ms: 1713216265427,
          },
          {
            leverage: 0.03,
            order_type: "LONG",
            order_uuid: "d4cd4d42-44c5-41a5-99ba-e49e03915703",
            price: 163.85,
            price_sources: [],
            processed_ms: 1713216350772,
          },
          {
            leverage: -0.03,
            order_type: "SHORT",
            order_uuid: "7f81d101-6d94-4de7-be0c-81c101619827",
            price: 164.302,
            price_sources: [],
            processed_ms: 1713317724256,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "a717c877-dbaf-4b95-a7f3-1d00f3658cf1",
            price: 164.745,
            price_sources: [],
            processed_ms: 1713733954190,
          },
        ],
        position_type: "FLAT",
        position_uuid: "27910bd9-6a72-481a-a798-8fde682e1646",
        return_at_close: 1.000085487106311,
        trade_pair: ["EURJPY", "EUR/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.8624582857142857,
        close_ms: 1713881910591,
        current_return: 1.0011190671510606,
        initial_entry_price: 0.8533,
        is_closed_position: true,
        miner_hotkey: "5EPDfdoeYhygXYEJ9xo8DV6kLuQZnrZgvH87sqci7WDM2j4g",
        net_leverage: 0.0,
        open_ms: 1713213178503,
        orders: [
          {
            leverage: -0.01,
            order_type: "SHORT",
            order_uuid: "664e7798-6fa5-470c-873b-f93c6e14e729",
            price: 0.8533,
            price_sources: [],
            processed_ms: 1713213178502,
          },
          {
            leverage: -0.01,
            order_type: "SHORT",
            order_uuid: "e26ae02e-7c81-4f83-82fa-72d87ecf9a5b",
            price: 0.85476,
            price_sources: [],
            processed_ms: 1713317672932,
          },
          {
            leverage: -0.03,
            order_type: "SHORT",
            order_uuid: "ca6dec94-9fdd-485f-afcf-fb7b2f2cc709",
            price: 0.85486,
            price_sources: [],
            processed_ms: 1713318592071,
          },
          {
            leverage: -0.3,
            order_type: "SHORT",
            order_uuid: "348cd5a0-32bf-4bb5-bd8a-24c7f51ba931",
            price: 0.86378,
            price_sources: [],
            processed_ms: 1713792828926,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "d5f10dde-9b69-4eb9-ad66-e519f65f9768",
            price: 0.85973,
            price_sources: [],
            processed_ms: 1713881910591,
          },
        ],
        position_type: "FLAT",
        position_uuid: "677132cd-eadf-4de9-8642-18f570cc3217",
        return_at_close: 1.0010945397339155,
        trade_pair: ["EURGBP", "EUR/GBP", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.9131,
        close_ms: 1714158062398,
        current_return: 1.0001697513963421,
        initial_entry_price: 0.9131,
        is_closed_position: true,
        miner_hotkey: "5EPDfdoeYhygXYEJ9xo8DV6kLuQZnrZgvH87sqci7WDM2j4g",
        net_leverage: 0.0,
        open_ms: 1714157191496,
        orders: [
          {
            leverage: 0.5,
            order_type: "LONG",
            order_uuid: "d5317792-0185-4464-94dd-573ff6310486",
            price: 0.9131,
            price_sources: [],
            processed_ms: 1714157191492,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "0ce3b107-a534-4f1c-9d97-09462351efa1",
            price: 0.91341,
            price_sources: [],
            processed_ms: 1714158062398,
          },
        ],
        position_type: "FLAT",
        position_uuid: "103d5679-23e7-45ae-89d1-6e696dc1a64b",
        return_at_close: 1.0001347454550433,
        trade_pair: ["USDCHF", "USD/CHF", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 1.070888712871287,
        close_ms: 1714390077242,
        current_return: 1.0001545648992458,
        initial_entry_price: 1.06596,
        is_closed_position: true,
        miner_hotkey: "5EPDfdoeYhygXYEJ9xo8DV6kLuQZnrZgvH87sqci7WDM2j4g",
        net_leverage: 0.0,
        open_ms: 1713180702317,
        orders: [
          {
            leverage: 0.001,
            order_type: "LONG",
            order_uuid: "2e1b093d-aeb5-4bcc-9ce9-e7d3e63a30e3",
            price: 1.06596,
            price_sources: [],
            processed_ms: 1713180702317,
          },
          {
            leverage: 0.001,
            order_type: "LONG",
            order_uuid: "4646c115-3d6e-4bac-a6a4-d262ebcc4b6c",
            price: 1.06531,
            price_sources: [],
            processed_ms: 1713183599603,
          },
          {
            leverage: 0.003,
            order_type: "LONG",
            order_uuid: "7f1818c6-b6ff-477e-be92-bf59daf0fd5e",
            price: 1.06298,
            price_sources: [],
            processed_ms: 1713202686897,
          },
          {
            leverage: 0.005,
            order_type: "LONG",
            order_uuid: "51cc7321-7f1f-46e2-afb1-755fb6531a11",
            price: 1.06229,
            price_sources: [],
            processed_ms: 1713203518387,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "bc361f3c-e3ea-4ac8-ad37-f310f4dd0c28",
            price: 1.06259,
            price_sources: [],
            processed_ms: 1713317640504,
          },
          {
            leverage: 0.03,
            order_type: "LONG",
            order_uuid: "98f37250-e94c-471f-97fe-44d57c0c2d37",
            price: 1.06263,
            price_sources: [],
            processed_ms: 1713318627851,
          },
          {
            leverage: -0.049,
            order_type: "SHORT",
            order_uuid: "a9871ed0-3a4f-46ba-b7c4-263e160f33ce",
            price: 1.0643,
            price_sources: [],
            processed_ms: 1713350762750,
          },
          {
            leverage: 0.1,
            order_type: "LONG",
            order_uuid: "7d40bda7-9c65-45cb-a487-b9e5fbf9e318",
            price: 1.07174,
            price_sources: [],
            processed_ms: 1714386910210,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "ebdb9497-aed4-47d8-b78e-3f252ac21bc5",
            price: 1.07252,
            price_sources: [],
            processed_ms: 1714390077242,
          },
        ],
        position_type: "FLAT",
        position_uuid: "b8c3fdf7-c212-4b14-b78e-1f2ae58f9661",
        return_at_close: 1.0001440632763143,
        trade_pair: ["EURUSD", "EUR/USD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 93.05,
        close_ms: 1714390098043,
        current_return: 1.000166577109081,
        initial_entry_price: 93.05,
        is_closed_position: true,
        miner_hotkey: "5EPDfdoeYhygXYEJ9xo8DV6kLuQZnrZgvH87sqci7WDM2j4g",
        net_leverage: 0.0,
        open_ms: 1714387180150,
        orders: [
          {
            leverage: 0.1,
            order_type: "LONG",
            order_uuid: "c2817b91-e8b7-4729-88a8-d896ea540837",
            price: 93.05,
            price_sources: [],
            processed_ms: 1714387176488,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "1711d31d-9f0c-4c38-ae8a-cf37ce7dc948",
            price: 93.205,
            price_sources: [],
            processed_ms: 1714390098043,
          },
        ],
        position_type: "FLAT",
        position_uuid: "b6c5a7b8-18c0-4665-98f8-2933891960a9",
        return_at_close: 1.0001595759430413,
        trade_pair: ["NZDJPY", "NZD/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.597,
        close_ms: 1714390130609,
        current_return: 1.0001373534338358,
        initial_entry_price: 0.597,
        is_closed_position: true,
        miner_hotkey: "5EPDfdoeYhygXYEJ9xo8DV6kLuQZnrZgvH87sqci7WDM2j4g",
        net_leverage: 0.0,
        open_ms: 1714387201554,
        orders: [
          {
            leverage: 0.1,
            order_type: "LONG",
            order_uuid: "9cab44ac-f07b-4482-b1fb-0a0955603a1f",
            price: 0.597,
            price_sources: [],
            processed_ms: 1714387201550,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "aaa45d6d-8de5-493b-83a6-af644046b96f",
            price: 0.59782,
            price_sources: [],
            processed_ms: 1714390130609,
          },
        ],
        position_type: "FLAT",
        position_uuid: "6f4cd779-f3a5-4f70-967c-b84c76608f18",
        return_at_close: 1.0001303524723617,
        trade_pair: ["NZDUSD", "NZD/USD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 114.112,
        close_ms: 1714390170430,
        current_return: 1.0001594924284913,
        initial_entry_price: 114.112,
        is_closed_position: true,
        miner_hotkey: "5EPDfdoeYhygXYEJ9xo8DV6kLuQZnrZgvH87sqci7WDM2j4g",
        net_leverage: 0.0,
        open_ms: 1714387025085,
        orders: [
          {
            leverage: 0.14,
            order_type: "LONG",
            order_uuid: "f5df8854-8169-468c-9f73-66b241874481",
            price: 114.112,
            price_sources: [],
            processed_ms: 1714387025081,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "4b4df8e8-f1e4-4d24-8498-c94e5d87b387",
            price: 114.242,
            price_sources: [],
            processed_ms: 1714390170430,
          },
        ],
        position_type: "FLAT",
        position_uuid: "5c85f20b-9ff6-4065-8c04-8959ab805b9b",
        return_at_close: 1.0001496908654657,
        trade_pair: ["CADJPY", "CAD/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.65647,
        close_ms: 1714396076165,
        current_return: 1.0001748747086692,
        initial_entry_price: 0.65647,
        is_closed_position: true,
        miner_hotkey: "5EPDfdoeYhygXYEJ9xo8DV6kLuQZnrZgvH87sqci7WDM2j4g",
        net_leverage: 0.0,
        open_ms: 1714387007056,
        orders: [
          {
            leverage: -0.14,
            order_type: "SHORT",
            order_uuid: "0b7460d6-d53e-4514-8fc5-22dc5323beb9",
            price: 0.65647,
            price_sources: [],
            processed_ms: 1714387007052,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "008642f0-4317-4aa7-88aa-96e55e1c994a",
            price: 0.65565,
            price_sources: [],
            processed_ms: 1714396076165,
          },
        ],
        position_type: "FLAT",
        position_uuid: "cac92abd-2311-4065-8bd4-52063c0ad458",
        return_at_close: 1.000165072994897,
        trade_pair: ["AUDUSD", "AUD/USD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 62476.9,
        close_ms: 1714400973238,
        current_return: 1.004970156970016,
        initial_entry_price: 62476.9,
        is_closed_position: true,
        miner_hotkey: "5EPDfdoeYhygXYEJ9xo8DV6kLuQZnrZgvH87sqci7WDM2j4g",
        net_leverage: 0.0,
        open_ms: 1714387260299,
        orders: [
          {
            leverage: 1.0,
            order_type: "LONG",
            order_uuid: "4b220466-e763-4a38-a6cc-699cd0af9d30",
            price: 62476.9,
            price_sources: [],
            processed_ms: 1714387260296,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "ef30709a-471c-4637-9f7c-bf7dc6d05649",
            price: 62787.42,
            price_sources: [],
            processed_ms: 1714400973238,
          },
        ],
        position_type: "FLAT",
        position_uuid: "849305bf-b461-42d6-9af3-7962ce11d7cd",
        return_at_close: 1.003965186813046,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 1.7940799999999997,
        close_ms: 1714400997476,
        current_return: 1.0001435833407653,
        initial_entry_price: 1.79408,
        is_closed_position: true,
        miner_hotkey: "5EPDfdoeYhygXYEJ9xo8DV6kLuQZnrZgvH87sqci7WDM2j4g",
        net_leverage: 0.0,
        open_ms: 1714387074183,
        orders: [
          {
            leverage: 0.14,
            order_type: "LONG",
            order_uuid: "78471123-422a-447f-8978-f4ee62d339cb",
            price: 1.79408,
            price_sources: [],
            processed_ms: 1714387074180,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "baec9ea4-d022-495a-bb06-893881c28ca8",
            price: 1.79592,
            price_sources: [],
            processed_ms: 1714400997476,
          },
        ],
        position_type: "FLAT",
        position_uuid: "cdbc1b5e-ea8a-4498-bd50-09d28b800bf6",
        return_at_close: 1.0001337819336487,
        trade_pair: ["EURNZD", "EUR/NZD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 1.25306,
        close_ms: 1714410226454,
        current_return: 1.0003742837533718,
        initial_entry_price: 1.25306,
        is_closed_position: true,
        miner_hotkey: "5EPDfdoeYhygXYEJ9xo8DV6kLuQZnrZgvH87sqci7WDM2j4g",
        net_leverage: 0.0,
        open_ms: 1714387112975,
        orders: [
          {
            leverage: 0.14,
            order_type: "LONG",
            order_uuid: "2af3cd3e-5cf1-4006-94a0-483e1fc9ec91",
            price: 1.25306,
            price_sources: [],
            processed_ms: 1714387109598,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "6bd40f8c-ad70-4226-b332-360bc1d74255",
            price: 1.25641,
            price_sources: [],
            processed_ms: 1714410226454,
          },
        ],
        position_type: "FLAT",
        position_uuid: "8d2243d7-8608-4104-bb79-2324235d3083",
        return_at_close: 1.000364480085391,
        trade_pair: ["GBPUSD", "GBP/USD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 1.46318,
        close_ms: 1714418078469,
        current_return: 1.0001492639319838,
        initial_entry_price: 1.46318,
        is_closed_position: true,
        miner_hotkey: "5EPDfdoeYhygXYEJ9xo8DV6kLuQZnrZgvH87sqci7WDM2j4g",
        net_leverage: 0.0,
        open_ms: 1714387045839,
        orders: [
          {
            leverage: 0.14,
            order_type: "LONG",
            order_uuid: "a593208e-690e-47b3-9f85-d01e8fe7d1c1",
            price: 1.46318,
            price_sources: [],
            processed_ms: 1714387041750,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "8cb0c705-3bf6-4263-8375-738f0b74c780",
            price: 1.46474,
            price_sources: [],
            processed_ms: 1714418078469,
          },
        ],
        position_type: "FLAT",
        position_uuid: "98b870a3-0892-448c-8312-f54892e1904f",
        return_at_close: 1.0001394624691973,
        trade_pair: ["EURCAD", "EUR/CAD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 155.999,
        close_ms: 1714419748102,
        current_return: 1.0020000128205957,
        initial_entry_price: 155.999,
        is_closed_position: true,
        miner_hotkey: "5EPDfdoeYhygXYEJ9xo8DV6kLuQZnrZgvH87sqci7WDM2j4g",
        net_leverage: 0.0,
        open_ms: 1714419026449,
        orders: [
          {
            leverage: 8.0,
            order_type: "LONG",
            order_uuid: "77be09ea-ae4f-4348-837c-9acd65ce8cba",
            price: 155.999,
            price_sources: [],
            processed_ms: 1714419026443,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "2e52a87b-a883-4236-a399-2f874fe66bd5",
            price: 156.038,
            price_sources: [],
            processed_ms: 1714419748102,
          },
        ],
        position_type: "FLAT",
        position_uuid: "ffd35524-df45-4ad0-a449-7bcf17f6c002",
        return_at_close: 1.0014388928134161,
        trade_pair: ["USDJPY", "USD/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 114.2981094527363,
        close_ms: 1714423311811,
        current_return: 1.01546623709761,
        initial_entry_price: 114.223,
        is_closed_position: true,
        miner_hotkey: "5EPDfdoeYhygXYEJ9xo8DV6kLuQZnrZgvH87sqci7WDM2j4g",
        net_leverage: 0.0,
        open_ms: 1714420350627,
        orders: [
          {
            leverage: 8.0,
            order_type: "LONG",
            order_uuid: "8c89f8d4-d6d3-4294-bbb5-e3c1c53ef8d3",
            price: 114.223,
            price_sources: [],
            processed_ms: 1714420350623,
          },
          {
            leverage: -7.9,
            order_type: "SHORT",
            order_uuid: "4d66bbc4-7982-4168-a957-c4427b9c979b",
            price: 114.18,
            price_sources: [],
            processed_ms: 1714420619744,
          },
          {
            leverage: 10.0,
            order_type: "LONG",
            order_uuid: "12a6326d-6e74-4253-819c-d668110ebe41",
            price: 114.26,
            price_sources: [],
            processed_ms: 1714421347364,
          },
          {
            leverage: 10.0,
            order_type: "LONG",
            order_uuid: "a6cc20b3-6581-4434-910a-800d5ac249d4",
            price: 114.303,
            price_sources: [],
            processed_ms: 1714421855174,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "c4f58713-d6f9-4cd0-b42a-53f1335c1bd4",
            price: 114.386,
            price_sources: [],
            processed_ms: 1714423311811,
          },
        ],
        position_type: "FLAT",
        position_uuid: "ff9ce1f5-a875-4473-8431-154c0cc35f0c",
        return_at_close: 1.0134759232728987,
        trade_pair: ["CADJPY", "CAD/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 156.055,
        close_ms: 1714423421132,
        current_return: 1.0080997084361276,
        initial_entry_price: 156.055,
        is_closed_position: true,
        miner_hotkey: "5EPDfdoeYhygXYEJ9xo8DV6kLuQZnrZgvH87sqci7WDM2j4g",
        net_leverage: 0.0,
        open_ms: 1714420215794,
        orders: [
          {
            leverage: 8.0,
            order_type: "LONG",
            order_uuid: "2b5a6aa8-f167-4d9d-9650-3e875d19341f",
            price: 156.055,
            price_sources: [],
            processed_ms: 1714420215787,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "3cebd75b-d566-4607-b893-3f427f614a51",
            price: 156.213,
            price_sources: [],
            processed_ms: 1714423421132,
          },
        ],
        position_type: "FLAT",
        position_uuid: "7cfb67e4-651e-4045-987e-c5c5c72f7446",
        return_at_close: 1.0075351725994033,
        trade_pair: ["USDJPY", "USD/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 114.3732,
        close_ms: 1714440444513,
        current_return: 1.0539524309199038,
        initial_entry_price: 114.36,
        is_closed_position: true,
        miner_hotkey: "5EPDfdoeYhygXYEJ9xo8DV6kLuQZnrZgvH87sqci7WDM2j4g",
        net_leverage: 0.0,
        open_ms: 1714423610065,
        orders: [
          {
            leverage: 10.0,
            order_type: "LONG",
            order_uuid: "41947ca6-09f4-4474-846a-18694dddd2ab",
            price: 114.36,
            price_sources: [],
            processed_ms: 1714423610060,
          },
          {
            leverage: 15.0,
            order_type: "LONG",
            order_uuid: "2bbf7890-0100-4ebd-a868-90eca70adc5d",
            price: 114.382,
            price_sources: [],
            processed_ms: 1714423653181,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "a83477cf-d9ee-4526-ae61-7f26b3f1b168",
            price: 114.62,
            price_sources: [],
            processed_ms: 1714440444513,
          },
        ],
        position_type: "FLAT",
        position_uuid: "d5e1d8b7-1f6d-4c0e-a548-28bae1496919",
        return_at_close: 1.052108014165794,
        trade_pair: ["CADJPY", "CAD/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 1.09897,
        close_ms: 1714566308362,
        current_return: 1.0001091639147677,
        initial_entry_price: 1.09897,
        is_closed_position: true,
        miner_hotkey: "5EPDfdoeYhygXYEJ9xo8DV6kLuQZnrZgvH87sqci7WDM2j4g",
        net_leverage: 0.0,
        open_ms: 1714386990466,
        orders: [
          {
            leverage: 0.14,
            order_type: "LONG",
            order_uuid: "dddbf96d-5e55-4cfe-a52d-f7b2c78f7038",
            price: 1.09897,
            price_sources: [],
            processed_ms: 1714386990464,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "8d359674-8f2b-45b2-b677-5eb6f2162fbf",
            price: 1.099826913338658,
            price_sources: [],
            processed_ms: 1714566308362,
          },
        ],
        position_type: "FLAT",
        position_uuid: "1eee19cb-6fce-4637-9671-692e160d03a5",
        return_at_close: 1.0000993628449613,
        trade_pair: ["AUDNZD", "AUD/NZD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.9701237971276372,
        close_ms: 1714964968637,
        current_return: 1.0003642668318058,
        initial_entry_price: 0.815412132866715,
        is_closed_position: true,
        miner_hotkey: "5EPDfdoeYhygXYEJ9xo8DV6kLuQZnrZgvH87sqci7WDM2j4g",
        net_leverage: 0.0,
        open_ms: 1714387128507,
        orders: [
          {
            leverage: -0.14,
            order_type: "SHORT",
            order_uuid: "450a8c44-63c8-42f3-93ee-dd7c64460443",
            price: 0.815412132866715,
            price_sources: [],
            processed_ms: 1714387128504,
          },
          {
            leverage: 0.138,
            order_type: "LONG",
            order_uuid: "9132152f-5d9f-47e0-bbef-787ec93a9a4e",
            price: 0.813169934833948,
            price_sources: [],
            processed_ms: 1714486948679,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "65085753-4554-40b9-930c-5b940132ff16",
            price: 0.82161,
            price_sources: [],
            processed_ms: 1714964968637,
          },
        ],
        position_type: "FLAT",
        position_uuid: "3fcf6ba4-18c6-4ac1-a0a1-53f2e047dc46",
        return_at_close: 1.000354463261991,
        trade_pair: ["NZDCAD", "NZD/CAD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: -0.11181000000004539,
        close_ms: 1715308427163,
        current_return: 1.0011361284671534,
        initial_entry_price: 0.85625,
        is_closed_position: true,
        miner_hotkey: "5EPDfdoeYhygXYEJ9xo8DV6kLuQZnrZgvH87sqci7WDM2j4g",
        net_leverage: 0.0,
        open_ms: 1714157167238,
        orders: [
          {
            leverage: 0.5,
            order_type: "LONG",
            order_uuid: "2746d54c-960c-4864-8f0e-d5f930b9a331",
            price: 0.85625,
            price_sources: [],
            processed_ms: 1714157167236,
          },
          {
            leverage: -0.499,
            order_type: "SHORT",
            order_uuid: "74c8500d-c594-4f6f-a999-e5806a0601c9",
            price: 0.85819,
            price_sources: [],
            processed_ms: 1714754373175,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "05e7fa53-66ca-43ad-8659-5d61d2e6f4fa",
            price: 0.861,
            price_sources: [],
            processed_ms: 1715308427163,
          },
        ],
        position_type: "FLAT",
        position_uuid: "df6f9d69-cafc-4a8a-8d6c-319f11a6f914",
        return_at_close: 1.001101088702657,
        trade_pair: ["EURGBP", "EUR/GBP", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 63196.5,
        close_ms: 1717606430150,
        current_return: 0.9998698078216357,
        initial_entry_price: 63196.5,
        is_closed_position: true,
        miner_hotkey: "5EPDfdoeYhygXYEJ9xo8DV6kLuQZnrZgvH87sqci7WDM2j4g",
        net_leverage: 0.0,
        open_ms: 1714888663582,
        orders: [
          {
            leverage: -0.001,
            order_type: "SHORT",
            order_uuid: "61ff4ac6-6564-4c84-b8ad-e54a39219e71",
            price: 63196.5,
            price_sources: [],
            processed_ms: 1714888663328,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "db0b294d-f58b-4605-aaac-20ce7561c825",
            price: 71424.19,
            price_sources: [],
            processed_ms: 1717606430150,
          },
        ],
        position_type: "FLAT",
        position_uuid: "1959b250-c7b5-4eb2-8d51-f6d7f7b96a44",
        return_at_close: 0.9998688079518279,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 370.7979999999985,
        close_ms: 0,
        current_return: 1.0016715685521254,
        initial_entry_price: 195.231,
        is_closed_position: false,
        miner_hotkey: "5EPDfdoeYhygXYEJ9xo8DV6kLuQZnrZgvH87sqci7WDM2j4g",
        net_leverage: -0.0020000000000000018,
        open_ms: 1714387091905,
        orders: [
          {
            leverage: -0.1,
            order_type: "SHORT",
            order_uuid: "d3309b81-dbb9-44e9-98a8-0836c7853305",
            price: 195.231,
            price_sources: [],
            processed_ms: 1714387091900,
          },
          {
            leverage: 0.098,
            order_type: "LONG",
            order_uuid: "5d14b747-ac30-4c40-8d69-6a4c4dacf768",
            price: 191.648,
            price_sources: [],
            processed_ms: 1714754416423,
          },
        ],
        position_type: "SHORT",
        position_uuid: "6b13fe20-f511-48fa-8d58-b0cbc9702078",
        return_at_close: 1.0016646269681553,
        trade_pair: ["GBPJPY", "GBP/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 166.05799999999803,
        close_ms: 0,
        current_return: 1.002772510340438,
        initial_entry_price: 157.14999999999998,
        is_closed_position: false,
        miner_hotkey: "5EPDfdoeYhygXYEJ9xo8DV6kLuQZnrZgvH87sqci7WDM2j4g",
        net_leverage: -0.10000000000000009,
        open_ms: 1717158414385,
        orders: [
          {
            leverage: -3.5,
            order_type: "SHORT",
            order_uuid: "44224c37-aca0-4a46-a963-94d53fa76e7e",
            price: 157.14999999999998,
            price_sources: [],
            processed_ms: 1717158414179,
          },
          {
            leverage: 3.4,
            order_type: "LONG",
            order_uuid: "ad309c3a-d2d8-41e5-9315-4c4abcbb6781",
            price: 156.888,
            price_sources: [],
            processed_ms: 1717162062729,
          },
        ],
        position_type: "SHORT",
        position_uuid: "dc89132f-7108-4058-998a-45e3bb52aa38",
        return_at_close: 1.0025303407791908,
        trade_pair: ["USDJPY", "USD/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 104.48225925925925,
        close_ms: 0,
        current_return: 0.9684788131484534,
        initial_entry_price: 103.13,
        is_closed_position: false,
        miner_hotkey: "5EPDfdoeYhygXYEJ9xo8DV6kLuQZnrZgvH87sqci7WDM2j4g",
        net_leverage: -0.702,
        open_ms: 1714157982953,
        orders: [
          {
            leverage: -0.2,
            order_type: "SHORT",
            order_uuid: "9ad57960-58a4-4645-83ab-ba8dc46ef671",
            price: 103.13,
            price_sources: [],
            processed_ms: 1714157982949,
          },
          {
            leverage: 0.198,
            order_type: "LONG",
            order_uuid: "3b3b85cc-6314-48d5-9ebc-9ea92d1e4f08",
            price: 102.373,
            price_sources: [],
            processed_ms: 1714366387625,
          },
          {
            leverage: -0.7,
            order_type: "SHORT",
            order_uuid: "0ed1e956-3870-427c-97e1-e5354c402276",
            price: 104.272,
            price_sources: [],
            processed_ms: 1716160467584,
          },
        ],
        position_type: "SHORT",
        position_uuid: "9bd4088b-4940-49b8-9cd1-7bf341826194",
        return_at_close: 0.9684415945076641,
        trade_pair: ["AUDJPY", "AUD/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 3763.9340740740745,
        close_ms: 0,
        current_return: 0.9771492457129282,
        initial_entry_price: 3704.16,
        is_closed_position: false,
        miner_hotkey: "5EPDfdoeYhygXYEJ9xo8DV6kLuQZnrZgvH87sqci7WDM2j4g",
        net_leverage: 0.135,
        open_ms: 1716573060996,
        orders: [
          {
            leverage: 0.085,
            order_type: "LONG",
            order_uuid: "ff3acf1e-4c41-4b63-8d28-04ec4046a317",
            price: 3704.16,
            price_sources: [],
            processed_ms: 1716573060480,
          },
          {
            leverage: 0.05,
            order_type: "LONG",
            order_uuid: "8062e55e-5906-44a4-95df-e506ca9a723f",
            price: 3865.55,
            price_sources: [],
            processed_ms: 1717619343634,
          },
        ],
        position_type: "LONG",
        position_uuid: "fe2da811-846f-4aba-8136-7ba32d1bd4e0",
        return_at_close: 0.9770832881388425,
        trade_pair: ["ETHUSD", "ETH/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 338.43000000000035,
        close_ms: 0,
        current_return: 1.0019573503428862,
        initial_entry_price: 166.965,
        is_closed_position: false,
        miner_hotkey: "5EPDfdoeYhygXYEJ9xo8DV6kLuQZnrZgvH87sqci7WDM2j4g",
        net_leverage: -0.0020000000000000018,
        open_ms: 1714387057533,
        orders: [
          {
            leverage: -0.14,
            order_type: "SHORT",
            order_uuid: "94a7c6d0-4908-4154-b873-9589cd35b068",
            price: 166.965,
            price_sources: [],
            processed_ms: 1714387057531,
          },
          {
            leverage: 0.138,
            order_type: "LONG",
            order_uuid: "e4703278-3a28-43cd-bf68-fba48aeeba76",
            price: 164.48,
            price_sources: [],
            processed_ms: 1714754399320,
          },
        ],
        position_type: "SHORT",
        position_uuid: "375bb2b4-df0c-4c7f-82d8-f44f9552e2f8",
        return_at_close: 1.0019476012978674,
        trade_pair: ["EURJPY", "EUR/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 71327.86,
        close_ms: 0,
        current_return: 0.9815067212166466,
        initial_entry_price: 71327.86,
        is_closed_position: false,
        miner_hotkey: "5EPDfdoeYhygXYEJ9xo8DV6kLuQZnrZgvH87sqci7WDM2j4g",
        net_leverage: 0.1,
        open_ms: 1717606654804,
        orders: [
          {
            leverage: 0.1,
            order_type: "LONG",
            order_uuid: "b37bbac9-000e-4095-9179-ce257ebd7339",
            price: 71327.86,
            price_sources: [],
            processed_ms: 1717606654345,
          },
        ],
        position_type: "LONG",
        position_uuid: "873f1c34-38c9-446c-8eeb-e408ecd1d565",
        return_at_close: 0.9814576458805858,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
    ],
    thirty_day_returns: 1.0,
  },
  "5ERw6vNzMyyhZQ5vKcN6soK4zm4LBA7tKE3P6jUAWM3PRucW": {
    all_time_returns: 1.0,
    n_positions: 0,
    percentage_profitable: 0.0,
    positions: [
      {
        average_entry_price: 64558.30000000001,
        close_ms: 1711197704566,
        current_return: 1.0017809948527143,
        initial_entry_price: 64558.3,
        is_closed_position: true,
        miner_hotkey: "5ERw6vNzMyyhZQ5vKcN6soK4zm4LBA7tKE3P6jUAWM3PRucW",
        net_leverage: 0.0,
        open_ms: 1711194179268,
        orders: [
          {
            leverage: 0.6,
            order_type: "LONG",
            order_uuid: "3d2e8f2c-5728-4903-a720-b87dc3a4ebab",
            price: 64558.3,
            price_sources: [],
            processed_ms: 1711194179268,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "23421501-0b15-4110-83a3-051737c81b90",
            price: 64749.93,
            price_sources: [],
            processed_ms: 1711197704566,
          },
        ],
        position_type: "FLAT",
        position_uuid: "25f0c7dd-bdd3-4723-8045-77ed20418bf6",
        return_at_close: 1.000578857658891,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 64793.88999999999,
        close_ms: 1711201392059,
        current_return: 0.9969355443854352,
        initial_entry_price: 64793.89,
        is_closed_position: true,
        miner_hotkey: "5ERw6vNzMyyhZQ5vKcN6soK4zm4LBA7tKE3P6jUAWM3PRucW",
        net_leverage: 0.0,
        open_ms: 1711197792096,
        orders: [
          {
            leverage: 0.6,
            order_type: "LONG",
            order_uuid: "ad585fa7-cf04-4e98-b1aa-69e4ae285927",
            price: 64793.89,
            price_sources: [],
            processed_ms: 1711197792095,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "ee6919c3-6dea-42ad-8826-659707139d92",
            price: 64462.96,
            price_sources: [],
            processed_ms: 1711201392059,
          },
        ],
        position_type: "FLAT",
        position_uuid: "bb08b88d-3829-44f0-b774-c4d6330d8803",
        return_at_close: 0.9957392217321727,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 64458.4,
        close_ms: 1711205032011,
        current_return: 1.006152433197225,
        initial_entry_price: 64458.4,
        is_closed_position: true,
        miner_hotkey: "5ERw6vNzMyyhZQ5vKcN6soK4zm4LBA7tKE3P6jUAWM3PRucW",
        net_leverage: 0.0,
        open_ms: 1711201479009,
        orders: [
          {
            leverage: 0.6,
            order_type: "LONG",
            order_uuid: "6366960a-46e3-40f8-93be-f4efeeea641a",
            price: 64458.4,
            price_sources: [],
            processed_ms: 1711201479008,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "cc8b1053-9aae-4deb-9aeb-5d064bcd03f1",
            price: 65119.36,
            price_sources: [],
            processed_ms: 1711205032011,
          },
        ],
        position_type: "FLAT",
        position_uuid: "3f9e4097-b713-4a61-9878-f06f7438f322",
        return_at_close: 1.0049450502773882,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 65084.72000000001,
        close_ms: 1711208699783,
        current_return: 0.9977942288143822,
        initial_entry_price: 65084.72,
        is_closed_position: true,
        miner_hotkey: "5ERw6vNzMyyhZQ5vKcN6soK4zm4LBA7tKE3P6jUAWM3PRucW",
        net_leverage: 0.0,
        open_ms: 1711205120366,
        orders: [
          {
            leverage: 0.6,
            order_type: "LONG",
            order_uuid: "3a2d9210-1883-4586-9c98-a7589fe2e2b7",
            price: 65084.72,
            price_sources: [],
            processed_ms: 1711205120365,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "5f832eda-a8a6-44af-897d-a0396ca2fc82",
            price: 64845.45,
            price_sources: [],
            processed_ms: 1711208699783,
          },
        ],
        position_type: "FLAT",
        position_uuid: "55313297-ae49-4b71-abf8-8c9b389533e4",
        return_at_close: 0.9965968757398049,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 64891.82,
        close_ms: 1711212361104,
        current_return: 1.0075860100702987,
        initial_entry_price: 64891.82,
        is_closed_position: true,
        miner_hotkey: "5ERw6vNzMyyhZQ5vKcN6soK4zm4LBA7tKE3P6jUAWM3PRucW",
        net_leverage: 0.0,
        open_ms: 1711208802850,
        orders: [
          {
            leverage: 0.6,
            order_type: "LONG",
            order_uuid: "37da2d3b-d343-4678-b28b-676b39e56598",
            price: 64891.82,
            price_sources: [],
            processed_ms: 1711208802849,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "d9befb21-d7af-4e85-b818-c205ea22fffd",
            price: 65712.27,
            price_sources: [],
            processed_ms: 1711212361104,
          },
        ],
        position_type: "FLAT",
        position_uuid: "65071ec1-c9ec-4209-9fce-0e8b2542514a",
        return_at_close: 1.0063769068582145,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 65750.05,
        close_ms: 1711216025293,
        current_return: 0.9996311485694688,
        initial_entry_price: 65750.05,
        is_closed_position: true,
        miner_hotkey: "5ERw6vNzMyyhZQ5vKcN6soK4zm4LBA7tKE3P6jUAWM3PRucW",
        net_leverage: 0.0,
        open_ms: 1711212501863,
        orders: [
          {
            leverage: 0.6,
            order_type: "LONG",
            order_uuid: "60436613-5e56-460d-8120-71f8650d72eb",
            price: 65750.05,
            price_sources: [],
            processed_ms: 1711212501862,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "2e08459c-6248-4dcf-bf59-d8a0e056f463",
            price: 65709.63,
            price_sources: [],
            processed_ms: 1711216025293,
          },
        ],
        position_type: "FLAT",
        position_uuid: "320cd476-deff-4f27-9576-71d9dda0693a",
        return_at_close: 0.9984315911911854,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 65771.39,
        close_ms: 1711219681266,
        current_return: 0.9962036684947665,
        initial_entry_price: 65771.39,
        is_closed_position: true,
        miner_hotkey: "5ERw6vNzMyyhZQ5vKcN6soK4zm4LBA7tKE3P6jUAWM3PRucW",
        net_leverage: 0.0,
        open_ms: 1711216111801,
        orders: [
          {
            leverage: 0.6,
            order_type: "LONG",
            order_uuid: "423c0b78-f233-40e0-af90-e7eb09c15454",
            price: 65771.39,
            price_sources: [],
            processed_ms: 1711216111800,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "9c538120-9d30-480e-9cb9-bd77a43ec3cb",
            price: 65355.24,
            price_sources: [],
            processed_ms: 1711219681266,
          },
        ],
        position_type: "FLAT",
        position_uuid: "18e2c969-d701-414f-b1bc-f18b61b8c843",
        return_at_close: 0.9950082240925728,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 65399.4,
        close_ms: 1711223349008,
        current_return: 0.9956908778979623,
        initial_entry_price: 65399.4,
        is_closed_position: true,
        miner_hotkey: "5ERw6vNzMyyhZQ5vKcN6soK4zm4LBA7tKE3P6jUAWM3PRucW",
        net_leverage: 0.0,
        open_ms: 1711219817363,
        orders: [
          {
            leverage: 0.6,
            order_type: "LONG",
            order_uuid: "00d15d2b-70e4-4f1b-b3e8-207728c59e56",
            price: 65399.4,
            price_sources: [],
            processed_ms: 1711219817362,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "2dd9468e-c3ef-4f6b-be8d-78b4a23091dd",
            price: 64929.71,
            price_sources: [],
            processed_ms: 1711223349008,
          },
        ],
        position_type: "FLAT",
        position_uuid: "b274d859-d600-4b19-b724-e53c1cc98cfc",
        return_at_close: 0.9944960488444847,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 64916.314999999995,
        close_ms: 1711227083388,
        current_return: 0.9995782483668029,
        initial_entry_price: 64886.53,
        is_closed_position: true,
        miner_hotkey: "5ERw6vNzMyyhZQ5vKcN6soK4zm4LBA7tKE3P6jUAWM3PRucW",
        net_leverage: 0.0,
        open_ms: 1711223435853,
        orders: [
          {
            leverage: 0.6,
            order_type: "LONG",
            order_uuid: "3e99cb2d-c9ae-473c-8a9e-c6c5e7fee605",
            price: 64886.53,
            price_sources: [],
            processed_ms: 1711223435852,
          },
          {
            leverage: 0.6,
            order_type: "LONG",
            order_uuid: "dbfb74c4-a9e7-4b33-a7df-8e5b8819e91d",
            price: 64946.1,
            price_sources: [],
            processed_ms: 1711227060727,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "cabb3ca9-5d48-4b89-a623-0ccef376a9fc",
            price: 64893.51,
            price_sources: [],
            processed_ms: 1711227083388,
          },
        ],
        position_type: "FLAT",
        position_uuid: "8d20073f-3c52-4da7-8416-0284a5c40321",
        return_at_close: 0.9971792605707226,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 64959.63,
        close_ms: 1711234330149,
        current_return: 0.9989853698366201,
        initial_entry_price: 64959.63,
        is_closed_position: true,
        miner_hotkey: "5ERw6vNzMyyhZQ5vKcN6soK4zm4LBA7tKE3P6jUAWM3PRucW",
        net_leverage: 0.0,
        open_ms: 1711230753784,
        orders: [
          {
            leverage: 0.6,
            order_type: "LONG",
            order_uuid: "32c10f7c-8d0d-4f22-b2b5-f597610a3ec8",
            price: 64959.63,
            price_sources: [],
            processed_ms: 1711230753783,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "949090f6-1295-413c-90f9-b26b2c02abf6",
            price: 64849.78,
            price_sources: [],
            processed_ms: 1711234330149,
          },
        ],
        position_type: "FLAT",
        position_uuid: "fe745b60-1e6e-4f05-a2b0-992e63991e15",
        return_at_close: 0.9977865873928162,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 64875.45,
        close_ms: 1711238005873,
        current_return: 0.9947937470953959,
        initial_entry_price: 64875.45,
        is_closed_position: true,
        miner_hotkey: "5ERw6vNzMyyhZQ5vKcN6soK4zm4LBA7tKE3P6jUAWM3PRucW",
        net_leverage: 0.0,
        open_ms: 1711234467909,
        orders: [
          {
            leverage: 0.6,
            order_type: "LONG",
            order_uuid: "a7fe98ae-f214-426d-8931-4af4b496bf16",
            price: 64875.45,
            price_sources: [],
            processed_ms: 1711234467908,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "65f89296-2422-4e56-9022-44a17187e2b2",
            price: 64312.52,
            price_sources: [],
            processed_ms: 1711238005873,
          },
        ],
        position_type: "FLAT",
        position_uuid: "259fba84-4a91-4ca1-a0d1-a43891079185",
        return_at_close: 0.9935999945988815,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 64280.80000000001,
        close_ms: 1711241654017,
        current_return: 1.002865459048425,
        initial_entry_price: 64280.8,
        is_closed_position: true,
        miner_hotkey: "5ERw6vNzMyyhZQ5vKcN6soK4zm4LBA7tKE3P6jUAWM3PRucW",
        net_leverage: 0.0,
        open_ms: 1711238109410,
        orders: [
          {
            leverage: 0.6,
            order_type: "LONG",
            order_uuid: "cdf0c093-3cd5-4fee-813b-8b5c77abdd05",
            price: 64280.8,
            price_sources: [],
            processed_ms: 1711238109409,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "9f00a2a0-f6db-4a6c-98d9-14f36eb4bce0",
            price: 64587.79,
            price_sources: [],
            processed_ms: 1711241654017,
          },
        ],
        position_type: "FLAT",
        position_uuid: "2dbcda42-0303-4ea7-9500-5792a6dcd8f0",
        return_at_close: 1.0016620204975668,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 64535.329999999994,
        close_ms: 1711245315226,
        current_return: 1.000072239500441,
        initial_entry_price: 64535.33,
        is_closed_position: true,
        miner_hotkey: "5ERw6vNzMyyhZQ5vKcN6soK4zm4LBA7tKE3P6jUAWM3PRucW",
        net_leverage: 0.0,
        open_ms: 1711241738883,
        orders: [
          {
            leverage: 0.6,
            order_type: "LONG",
            order_uuid: "07329632-083a-4d89-90d1-5aab66ecdc88",
            price: 64535.33,
            price_sources: [],
            processed_ms: 1711241738882,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "7c0433e3-87a5-4a9d-afca-13aa3e8a5ff5",
            price: 64543.1,
            price_sources: [],
            processed_ms: 1711245315226,
          },
        ],
        position_type: "FLAT",
        position_uuid: "53380d48-85dc-49a2-8cda-bc96235a0c70",
        return_at_close: 0.9988721528130406,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 64543.43,
        close_ms: 1711248991830,
        current_return: 0.997401284685366,
        initial_entry_price: 64543.43,
        is_closed_position: true,
        miner_hotkey: "5ERw6vNzMyyhZQ5vKcN6soK4zm4LBA7tKE3P6jUAWM3PRucW",
        net_leverage: 0.0,
        open_ms: 1711245452492,
        orders: [
          {
            leverage: 0.6,
            order_type: "LONG",
            order_uuid: "a1a7a801-ed1f-40ec-8b23-45fc84557155",
            price: 64543.43,
            price_sources: [],
            processed_ms: 1711245452491,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "747ed843-cbe0-4d22-a430-6a82e72efa4a",
            price: 64263.88,
            price_sources: [],
            processed_ms: 1711248991830,
          },
        ],
        position_type: "FLAT",
        position_uuid: "102be97a-a0fe-4477-b535-9acd7ca9cb8b",
        return_at_close: 0.9962044031437436,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 64222.560000000005,
        close_ms: 1711252637004,
        current_return: 0.9988502171199652,
        initial_entry_price: 64222.56,
        is_closed_position: true,
        miner_hotkey: "5ERw6vNzMyyhZQ5vKcN6soK4zm4LBA7tKE3P6jUAWM3PRucW",
        net_leverage: 0.0,
        open_ms: 1711249078899,
        orders: [
          {
            leverage: 0.6,
            order_type: "LONG",
            order_uuid: "14569491-5f39-446a-8233-1e42c54a32e9",
            price: 64222.56,
            price_sources: [],
            processed_ms: 1711249078898,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "bfa9be54-ea8e-4982-84f7-02ab1dc5b807",
            price: 64099.49,
            price_sources: [],
            processed_ms: 1711252637004,
          },
        ],
        position_type: "FLAT",
        position_uuid: "f1333264-1431-4f62-acb2-6a5c635e7bf1",
        return_at_close: 0.9976515968594213,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 64019.47,
        close_ms: 1711256893739,
        current_return: 1.0013336567766025,
        initial_entry_price: 64019.47,
        is_closed_position: true,
        miner_hotkey: "5ERw6vNzMyyhZQ5vKcN6soK4zm4LBA7tKE3P6jUAWM3PRucW",
        net_leverage: 0.0,
        open_ms: 1711252724063,
        orders: [
          {
            leverage: 0.6,
            order_type: "LONG",
            order_uuid: "5e5b0dcc-f7cf-4c50-a7b7-8924aac9716c",
            price: 64019.47,
            price_sources: [],
            processed_ms: 1711252724062,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "576eadee-cb34-4a99-b062-7fd0a2fe6c3e",
            price: 64161.77,
            price_sources: [],
            processed_ms: 1711256893739,
          },
        ],
        position_type: "FLAT",
        position_uuid: "342bfd08-03cb-4bb9-901c-7b0f3134e8ae",
        return_at_close: 1.0001320563884706,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 64133.895000000004,
        close_ms: 1711258125871,
        current_return: 1.0034004724983243,
        initial_entry_price: 64139.91,
        is_closed_position: true,
        miner_hotkey: "5ERw6vNzMyyhZQ5vKcN6soK4zm4LBA7tKE3P6jUAWM3PRucW",
        net_leverage: 0.0,
        open_ms: 1711256993396,
        orders: [
          {
            leverage: 0.6,
            order_type: "LONG",
            order_uuid: "47de4fec-662e-4814-b65a-985d275ffda6",
            price: 64139.91,
            price_sources: [],
            processed_ms: 1711256993395,
          },
          {
            leverage: 0.6,
            order_type: "LONG",
            order_uuid: "73580682-9c39-4143-b33c-21882a78d2dd",
            price: 64127.88,
            price_sources: [],
            processed_ms: 1711257212781,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "f2ed17e0-cc43-4802-bfda-a0119a2eda8b",
            price: 64315.65,
            price_sources: [],
            processed_ms: 1711258125871,
          },
        ],
        position_type: "FLAT",
        position_uuid: "34918dc3-4a42-466a-9176-25d98af784cb",
        return_at_close: 1.0009923113643284,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 64298.45,
        close_ms: 1711261782723,
        current_return: 1.0011153068231038,
        initial_entry_price: 64298.45,
        is_closed_position: true,
        miner_hotkey: "5ERw6vNzMyyhZQ5vKcN6soK4zm4LBA7tKE3P6jUAWM3PRucW",
        net_leverage: 0.0,
        open_ms: 1711258212859,
        orders: [
          {
            leverage: 1.25,
            order_type: "LONG",
            order_uuid: "5cd97948-44e5-48c4-8dd3-6991952e679d",
            price: 64298.45,
            price_sources: [],
            processed_ms: 1711258212857,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "d36ff4fa-7aef-47d6-a6e0-842b9fd59126",
            price: 64355.82,
            price_sources: [],
            processed_ms: 1711261782723,
          },
        ],
        position_type: "FLAT",
        position_uuid: "bbea8789-8ad2-4a66-9e3e-0162c36f18eb",
        return_at_close: 0.998612518556046,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 64316.37000000001,
        close_ms: 1711265458893,
        current_return: 0.9966489868753473,
        initial_entry_price: 64316.37,
        is_closed_position: true,
        miner_hotkey: "5ERw6vNzMyyhZQ5vKcN6soK4zm4LBA7tKE3P6jUAWM3PRucW",
        net_leverage: 0.0,
        open_ms: 1711261869768,
        orders: [
          {
            leverage: 1.25,
            order_type: "LONG",
            order_uuid: "050af045-5cc7-4988-8ab2-ad978566541e",
            price: 64316.37,
            price_sources: [],
            processed_ms: 1711261869766,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "bfb0816c-8337-40d0-adf3-d8ada00a9b40",
            price: 64143.95,
            price_sources: [],
            processed_ms: 1711265458893,
          },
        ],
        position_type: "FLAT",
        position_uuid: "4b9addb8-acb6-4937-bbc1-af80e7caef7b",
        return_at_close: 0.9941573644081589,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 64124.68000000001,
        close_ms: 1711269107015,
        current_return: 1.010565159935301,
        initial_entry_price: 64124.68,
        is_closed_position: true,
        miner_hotkey: "5ERw6vNzMyyhZQ5vKcN6soK4zm4LBA7tKE3P6jUAWM3PRucW",
        net_leverage: 0.0,
        open_ms: 1711265561867,
        orders: [
          {
            leverage: 1.25,
            order_type: "LONG",
            order_uuid: "bc6beabe-033f-4e07-bc0c-ce67e58329f4",
            price: 64124.68,
            price_sources: [],
            processed_ms: 1711265561865,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "83dcc65a-6c1e-4ce2-ab5c-b55a5a08490a",
            price: 64666.67,
            price_sources: [],
            processed_ms: 1711269107015,
          },
        ],
        position_type: "FLAT",
        position_uuid: "bc016508-319f-4922-b5a8-ae9ed35d7ef3",
        return_at_close: 1.0080387470354628,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 64739.16000000001,
        close_ms: 1711272774930,
        current_return: 1.0036778357952125,
        initial_entry_price: 64739.16,
        is_closed_position: true,
        miner_hotkey: "5ERw6vNzMyyhZQ5vKcN6soK4zm4LBA7tKE3P6jUAWM3PRucW",
        net_leverage: 0.0,
        open_ms: 1711269239382,
        orders: [
          {
            leverage: 1.25,
            order_type: "LONG",
            order_uuid: "abec5e92-a754-4c13-9708-8409aa921aec",
            price: 64739.16,
            price_sources: [],
            processed_ms: 1711269239380,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "98261054-69b8-4833-b732-9fc6397bce93",
            price: 64929.64,
            price_sources: [],
            processed_ms: 1711272774930,
          },
        ],
        position_type: "FLAT",
        position_uuid: "0eb2b041-3a4b-4289-982e-bbdc1add98b6",
        return_at_close: 1.0011686412057246,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 64945.55,
        close_ms: 1711276432780,
        current_return: 1.0026254685655906,
        initial_entry_price: 64945.55,
        is_closed_position: true,
        miner_hotkey: "5ERw6vNzMyyhZQ5vKcN6soK4zm4LBA7tKE3P6jUAWM3PRucW",
        net_leverage: 0.0,
        open_ms: 1711272861737,
        orders: [
          {
            leverage: 1.25,
            order_type: "LONG",
            order_uuid: "a1546717-70d7-475c-954c-7bc1c6a57d9e",
            price: 64945.55,
            price_sources: [],
            processed_ms: 1711272861736,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "49388bd3-f3a8-4b80-840a-d053cf78b4fe",
            price: 65081.96,
            price_sources: [],
            processed_ms: 1711276432780,
          },
        ],
        position_type: "FLAT",
        position_uuid: "604b28ae-835f-4230-9de2-533db2495df2",
        return_at_close: 1.0001189048941768,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 65469.24000000003,
        close_ms: 1711315395804,
        current_return: 1.0177843904531632,
        initial_entry_price: 65083.76,
        is_closed_position: true,
        miner_hotkey: "5ERw6vNzMyyhZQ5vKcN6soK4zm4LBA7tKE3P6jUAWM3PRucW",
        net_leverage: 0.0,
        open_ms: 1711276519761,
        orders: [
          {
            leverage: 1.25,
            order_type: "LONG",
            order_uuid: "269b8fb8-65cf-4183-a9e8-d4adef8ac31a",
            price: 65083.76,
            price_sources: [],
            processed_ms: 1711276519759,
          },
          {
            leverage: 1.25,
            order_type: "LONG",
            order_uuid: "cb772307-2e6f-4ca5-89e0-c580099e5fde",
            price: 65696.9,
            price_sources: [],
            processed_ms: 1711294281797,
          },
          {
            leverage: -1.25,
            order_type: "SHORT",
            order_uuid: "cc2fda25-fbad-40b7-b623-6fbbf6b9278f",
            price: 65672.97,
            price_sources: [],
            processed_ms: 1711296110025,
          },
          {
            leverage: 1.25,
            order_type: "LONG",
            order_uuid: "11769bb8-16be-4c5b-ba61-f814916ff6bd",
            price: 65289.03,
            price_sources: [],
            processed_ms: 1711299777896,
          },
          {
            leverage: -1.25,
            order_type: "SHORT",
            order_uuid: "31f4ee89-f0f4-4364-96c9-6c8bd0fb6440",
            price: 64940.12,
            price_sources: [],
            processed_ms: 1711301609065,
          },
          {
            leverage: 1.25,
            order_type: "LONG",
            order_uuid: "169cef3a-c8cf-4f3c-9992-4f3e9badfd18",
            price: 66289.87,
            price_sources: [],
            processed_ms: 1711312601815,
          },
          {
            leverage: -1.25,
            order_type: "SHORT",
            order_uuid: "e8982de6-989c-46b1-801e-7af5e098351b",
            price: 66277.23,
            price_sources: [],
            processed_ms: 1711313565215,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "bd1bb7a5-b050-40c4-b27b-c4cc28ab89e8",
            price: 66395.22,
            price_sources: [],
            processed_ms: 1711315395804,
          },
        ],
        position_type: "FLAT",
        position_uuid: "2a17f6ca-00ab-41fe-b0f6-287aede918e9",
        return_at_close: 1.0126954685008973,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 66335.32,
        close_ms: 1711318189973,
        current_return: 1.0091525525165175,
        initial_entry_price: 66335.32,
        is_closed_position: true,
        miner_hotkey: "5ERw6vNzMyyhZQ5vKcN6soK4zm4LBA7tKE3P6jUAWM3PRucW",
        net_leverage: 0.0,
        open_ms: 1711316332570,
        orders: [
          {
            leverage: 1.25,
            order_type: "LONG",
            order_uuid: "9280c1c0-0670-4da0-b224-5543bfadadad",
            price: 66335.32,
            price_sources: [],
            processed_ms: 1711316332568,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "5b896868-6fe3-4b0e-8709-42eb9f5c3fa9",
            price: 66821.03,
            price_sources: [],
            processed_ms: 1711318189973,
          },
        ],
        position_type: "FLAT",
        position_uuid: "e609647f-19d0-4dd8-8fd3-42db6ddc847a",
        return_at_close: 1.0066296711352263,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 254954.26666667755,
        close_ms: 1711329374346,
        current_return: 1.003144130397391,
        initial_entry_price: 67324.66,
        is_closed_position: true,
        miner_hotkey: "5ERw6vNzMyyhZQ5vKcN6soK4zm4LBA7tKE3P6jUAWM3PRucW",
        net_leverage: 0.0,
        open_ms: 1711321929841,
        orders: [
          {
            leverage: -1.25,
            order_type: "SHORT",
            order_uuid: "e07fe53a-d5dc-4bc4-9828-d3b930deb70f",
            price: 67324.66,
            price_sources: [],
            processed_ms: 1711321929839,
          },
          {
            leverage: 0.125,
            order_type: "LONG",
            order_uuid: "288e4410-0fb7-4dab-aeec-7d39bb1effe6",
            price: 67335.4,
            price_sources: [],
            processed_ms: 1711322848004,
          },
          {
            leverage: 0.5625,
            order_type: "LONG",
            order_uuid: "188381a0-4d5c-44a3-b2a8-becc7fe7bd48",
            price: 67195.94,
            price_sources: [],
            processed_ms: 1711323785178,
          },
          {
            leverage: 0.28125,
            order_type: "LONG",
            order_uuid: "2f03c891-cbef-44fc-bf1d-33f967e2440f",
            price: 67246.58,
            price_sources: [],
            processed_ms: 1711324724274,
          },
          {
            leverage: 0.225,
            order_type: "LONG",
            order_uuid: "6fa17e41-40dc-4313-8e5d-37991bddeacd",
            price: 66942.16,
            price_sources: [],
            processed_ms: 1711325647288,
          },
          {
            leverage: 0.028124999999999997,
            order_type: "LONG",
            order_uuid: "669d689b-4c1e-47c6-9c71-9f22257ab3b0",
            price: 66883.12,
            price_sources: [],
            processed_ms: 1711326584064,
          },
          {
            leverage: 0.0225,
            order_type: "LONG",
            order_uuid: "ba0d9c43-23b9-45e0-8e9b-f22efb6a6966",
            price: 66604.42,
            price_sources: [],
            processed_ms: 1711327522279,
          },
          {
            leverage: 0.004499999999999999,
            order_type: "LONG",
            order_uuid: "25bdac0e-6103-49e7-b16b-bf3518cc2a9b",
            price: 66574.75,
            price_sources: [],
            processed_ms: 1711328442138,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "a0388c4f-a0a2-408b-b22e-080d3b2f967c",
            price: 66796.48,
            price_sources: [],
            processed_ms: 1711329374346,
          },
        ],
        position_type: "FLAT",
        position_uuid: "91994ab8-07d2-4685-b181-b65596393e0a",
        return_at_close: 1.0006362700713975,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 66580.54,
        close_ms: 1711335185251,
        current_return: 1.001779799322745,
        initial_entry_price: 66580.54,
        is_closed_position: true,
        miner_hotkey: "5ERw6vNzMyyhZQ5vKcN6soK4zm4LBA7tKE3P6jUAWM3PRucW",
        net_leverage: 0.0,
        open_ms: 1711331500153,
        orders: [
          {
            leverage: 1.25,
            order_type: "LONG",
            order_uuid: "d342414f-18a3-4918-8cb2-fd060fe33219",
            price: 66580.54,
            price_sources: [],
            processed_ms: 1711331500151,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "b2f05b18-4874-4199-888d-7b58f4044bcd",
            price: 66675.34,
            price_sources: [],
            processed_ms: 1711335185251,
          },
        ],
        position_type: "FLAT",
        position_uuid: "71acd135-016a-4b09-9857-efbfecc34996",
        return_at_close: 0.9992753498244382,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 129489.5699999991,
        close_ms: 1711355321113,
        current_return: 1.0028869570908308,
        initial_entry_price: 67498.86,
        is_closed_position: true,
        miner_hotkey: "5ERw6vNzMyyhZQ5vKcN6soK4zm4LBA7tKE3P6jUAWM3PRucW",
        net_leverage: 0.0,
        open_ms: 1711342484000,
        orders: [
          {
            leverage: -1.25,
            order_type: "SHORT",
            order_uuid: "93c40928-bd75-4b48-822f-1ea6df6f4a01",
            price: 67498.86,
            price_sources: [],
            processed_ms: 1711342483998,
          },
          {
            leverage: 0.625,
            order_type: "LONG",
            order_uuid: "1495db53-5c50-4d8f-ba19-2a6404aaa9c9",
            price: 67405.6,
            price_sources: [],
            processed_ms: 1711344315162,
          },
          {
            leverage: 0.3125,
            order_type: "LONG",
            order_uuid: "5ff5086a-cde6-4d62-a981-171f79307be7",
            price: 67388.07,
            price_sources: [],
            processed_ms: 1711346155379,
          },
          {
            leverage: 0.15625,
            order_type: "LONG",
            order_uuid: "b87f5b82-a622-410a-b665-65a1d8a1f1dc",
            price: 67195.79,
            price_sources: [],
            processed_ms: 1711347984095,
          },
          {
            leverage: 0.125,
            order_type: "LONG",
            order_uuid: "c257b5f1-e439-4a34-a9bd-4bb80fc119a4",
            price: 67177.18,
            price_sources: [],
            processed_ms: 1711349812301,
          },
          {
            leverage: 0.015625,
            order_type: "LONG",
            order_uuid: "b679862a-402e-4542-bf1f-fb7cdcb6e26d",
            price: 67183.85,
            price_sources: [],
            processed_ms: 1711351656969,
          },
          {
            leverage: 0.0125,
            order_type: "LONG",
            order_uuid: "0b10cf88-8ca8-4d12-85ad-32c3fb1e766c",
            price: 66832.87,
            price_sources: [],
            processed_ms: 1711353482019,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "1640d1ee-9119-4449-bbe7-82b78dadbb91",
            price: 67132.35,
            price_sources: [],
            processed_ms: 1711355321113,
          },
        ],
        position_type: "FLAT",
        position_uuid: "84644221-50c6-406f-a507-435596def027",
        return_at_close: 1.0003797396981038,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 66662.75999999998,
        close_ms: 1711362647166,
        current_return: 1.0104234354345687,
        initial_entry_price: 66897.33,
        is_closed_position: true,
        miner_hotkey: "5ERw6vNzMyyhZQ5vKcN6soK4zm4LBA7tKE3P6jUAWM3PRucW",
        net_leverage: 0.0,
        open_ms: 1711357146864,
        orders: [
          {
            leverage: 1.25,
            order_type: "LONG",
            order_uuid: "39926328-2c6f-43c1-8f16-44a2a3cf0d48",
            price: 66897.33,
            price_sources: [],
            processed_ms: 1711357146861,
          },
          {
            leverage: 1.25,
            order_type: "LONG",
            order_uuid: "7162c1b0-d5d1-4b3c-8197-707a46c7ba49",
            price: 66831.85,
            price_sources: [],
            processed_ms: 1711358974307,
          },
          {
            leverage: -1.25,
            order_type: "SHORT",
            order_uuid: "e7c019e9-14fd-4e67-b300-afc487875689",
            price: 67066.42,
            price_sources: [],
            processed_ms: 1711360807494,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "cb471638-6e56-41c3-b242-c4b22cbc16f4",
            price: 67220.6,
            price_sources: [],
            processed_ms: 1711362647166,
          },
        ],
        position_type: "FLAT",
        position_uuid: "78779da1-e62e-4be9-b011-987b4de90b18",
        return_at_close: 1.0053713182573958,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: -690994.2157277012,
        close_ms: 1711385022782,
        current_return: 1.0120930686543845,
        initial_entry_price: 67027.62,
        is_closed_position: true,
        miner_hotkey: "5ERw6vNzMyyhZQ5vKcN6soK4zm4LBA7tKE3P6jUAWM3PRucW",
        net_leverage: 0.0,
        open_ms: 1711364471381,
        orders: [
          {
            leverage: 1.25,
            order_type: "LONG",
            order_uuid: "1e8df3aa-62e3-482c-9d7a-004bcb87ae24",
            price: 67027.62,
            price_sources: [],
            processed_ms: 1711364471378,
          },
          {
            leverage: -0.625,
            order_type: "SHORT",
            order_uuid: "f04c18a2-6eaa-40c5-8205-9d86127da566",
            price: 67021.69,
            price_sources: [],
            processed_ms: 1711368638852,
          },
          {
            leverage: -0.3125,
            order_type: "SHORT",
            order_uuid: "37f3c545-1df0-46c5-b629-57b853d82d85",
            price: 67169.56,
            price_sources: [],
            processed_ms: 1711370470994,
          },
          {
            leverage: -0.15625,
            order_type: "SHORT",
            order_uuid: "b3643126-9241-4fb4-88bd-a1858b0d6841",
            price: 67090.48,
            price_sources: [],
            processed_ms: 1711372330753,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "8d52b353-d6ae-4956-8294-b5c6f464f495",
            price: 67151.61,
            price_sources: [],
            processed_ms: 1711374133157,
          },
          {
            leverage: -0.325,
            order_type: "SHORT",
            order_uuid: "cb49b1ad-4838-41ea-a0a1-94fc552521bc",
            price: 68868.54,
            price_sources: [],
            processed_ms: 1711376425471,
          },
          {
            leverage: -0.06499999999999999,
            order_type: "SHORT",
            order_uuid: "bad9a1b3-3d93-4bef-86b7-682f1d8bacb7",
            price: 69210.38,
            price_sources: [],
            processed_ms: 1711376745090,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "d704ce43-448b-48d7-aa7b-a839f8c3751b",
            price: 69095.56,
            price_sources: [],
            processed_ms: 1711377884211,
          },
          {
            leverage: -0.213,
            order_type: "SHORT",
            order_uuid: "ba5dd05f-af56-4a24-949b-2eee8bf4c1a2",
            price: 69061.84,
            price_sources: [],
            processed_ms: 1711378184296,
          },
          {
            leverage: -0.04259999999999999,
            order_type: "SHORT",
            order_uuid: "bce581b5-9eda-4656-a8a8-dd2a46f48923",
            price: 69488.33,
            price_sources: [],
            processed_ms: 1711379546745,
          },
          {
            leverage: -0.00852,
            order_type: "SHORT",
            order_uuid: "e8e54478-58bd-40ab-8b64-1a69fc47f926",
            price: 69804.53,
            price_sources: [],
            processed_ms: 1711381377099,
          },
          {
            leverage: -0.001065,
            order_type: "SHORT",
            order_uuid: "29226f16-d543-4826-bff4-94330ec96f5b",
            price: 69704.71,
            price_sources: [],
            processed_ms: 1711383198229,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "2373625c-e275-4287-a2f2-79219634a1d9",
            price: 70104.01,
            price_sources: [],
            processed_ms: 1711385022782,
          },
        ],
        position_type: "FLAT",
        position_uuid: "5c46f146-002b-4ec0-8e0c-d0ad2e62a24e",
        return_at_close: 1.0095628359827487,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 70576.26250000001,
        close_ms: 1711391487346,
        current_return: 0.9979583923804363,
        initial_entry_price: 70551.02,
        is_closed_position: true,
        miner_hotkey: "5ERw6vNzMyyhZQ5vKcN6soK4zm4LBA7tKE3P6jUAWM3PRucW",
        net_leverage: 0.0,
        open_ms: 1711386830260,
        orders: [
          {
            leverage: -1.25,
            order_type: "SHORT",
            order_uuid: "cf4f3fec-8ba9-4b56-a88d-7db0e7d4b6c1",
            price: 70551.02,
            price_sources: [],
            processed_ms: 1711386830258,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "9ee5c40e-e9d3-4f20-8c83-e737b3290018",
            price: 70450.05,
            price_sources: [],
            processed_ms: 1711388649467,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "ff2f8f25-39e3-4feb-a050-84f1ea6a824d",
            price: 70720.3,
            price_sources: [],
            processed_ms: 1711391487346,
          },
        ],
        position_type: "FLAT",
        position_uuid: "1a4dd381-0819-429b-9d65-260a732cc22b",
        return_at_close: 0.9954634963994853,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 70964.15999999989,
        close_ms: 1711400566888,
        current_return: 1.0005814133990378,
        initial_entry_price: 70861.8,
        is_closed_position: true,
        miner_hotkey: "5ERw6vNzMyyhZQ5vKcN6soK4zm4LBA7tKE3P6jUAWM3PRucW",
        net_leverage: 0.0,
        open_ms: 1711393304206,
        orders: [
          {
            leverage: -5.0,
            order_type: "SHORT",
            order_uuid: "75877807-af4d-4fd5-993d-fc47b31d66fd",
            price: 70861.8,
            price_sources: [],
            processed_ms: 1711393304204,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "1b343873-44e3-419f-b0c3-549934258487",
            price: 70688.24,
            price_sources: [],
            processed_ms: 1711395148546,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "b1abe039-d5c2-46e2-91da-56b05d27bfbd",
            price: 71038.57,
            price_sources: [],
            processed_ms: 1711396962158,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "468b6f4f-4700-4d04-91fa-532ae89aa80b",
            price: 70824.47,
            price_sources: [],
            processed_ms: 1711398751969,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "184fa72c-c88a-4bef-aeca-2054dc904e55",
            price: 70881.76,
            price_sources: [],
            processed_ms: 1711400566888,
          },
        ],
        position_type: "FLAT",
        position_uuid: "8cae2483-e417-4cc8-bcea-d441168aa43c",
        return_at_close: 0.9905755992650475,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 69345.16846153847,
        close_ms: 1711406258959,
        current_return: 1.0526137909637578,
        initial_entry_price: 69813.54,
        is_closed_position: true,
        miner_hotkey: "5ERw6vNzMyyhZQ5vKcN6soK4zm4LBA7tKE3P6jUAWM3PRucW",
        net_leverage: 0.0,
        open_ms: 1711402380260,
        orders: [
          {
            leverage: 5.0,
            order_type: "LONG",
            order_uuid: "c4cede9c-d114-40fe-b74f-aeff7892cc60",
            price: 69813.54,
            price_sources: [],
            processed_ms: 1711402380250,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "c431a3ed-0fb9-4a4a-9c38-9e1d4e0611c2",
            price: 70021.84,
            price_sources: [],
            processed_ms: 1711404199040,
          },
          {
            leverage: -3.25,
            order_type: "SHORT",
            order_uuid: "ce2ac04f-f69e-4ee0-a467-dd23cf75ba1f",
            price: 70378.05,
            price_sources: [],
            processed_ms: 1711406036112,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "57a0bbee-0bbb-455c-a429-4482fc44737e",
            price: 70475.37,
            price_sources: [],
            processed_ms: 1711406258959,
          },
        ],
        position_type: "FLAT",
        position_uuid: "e679ac51-5c73-4aab-a755-adf2374fd148",
        return_at_close: 1.0389298116812289,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 63418.224210526816,
        close_ms: 1711424193110,
        current_return: 1.0235977277144415,
        initial_entry_price: 70129.39,
        is_closed_position: true,
        miner_hotkey: "5ERw6vNzMyyhZQ5vKcN6soK4zm4LBA7tKE3P6jUAWM3PRucW",
        net_leverage: 0.0,
        open_ms: 1711409652135,
        orders: [
          {
            leverage: 5.0,
            order_type: "LONG",
            order_uuid: "66cd536c-73ac-432e-b5e0-91beddabc842",
            price: 70129.39,
            price_sources: [],
            processed_ms: 1711409652132,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "03f344e6-c5c2-4092-8b89-0fb3da84698d",
            price: 69789.99,
            price_sources: [],
            processed_ms: 1711411485614,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "68698897-a5e0-4dc8-a57a-d40af0724bb9",
            price: 70115.67,
            price_sources: [],
            processed_ms: 1711413289016,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "2426d3be-528a-428b-b1d5-1548ce690876",
            price: 69902.96,
            price_sources: [],
            processed_ms: 1711415098313,
          },
          {
            leverage: -4.75,
            order_type: "SHORT",
            order_uuid: "dba9ab0c-d576-4dc0-9b6e-d132668d8bc9",
            price: 70210.8,
            price_sources: [],
            processed_ms: 1711416930837,
          },
          {
            leverage: -2.375,
            order_type: "SHORT",
            order_uuid: "cd1ae64d-5ed7-4b01-9a53-a34e1185b6e7",
            price: 70105.77,
            price_sources: [],
            processed_ms: 1711418733291,
          },
          {
            leverage: -1.1875,
            order_type: "SHORT",
            order_uuid: "33e5d975-5922-430d-afc4-e50fff7dea10",
            price: 70229.76,
            price_sources: [],
            processed_ms: 1711420555458,
          },
          {
            leverage: -0.95,
            order_type: "SHORT",
            order_uuid: "164720bb-5924-4ff7-be51-aa6177be8fa1",
            price: 70418.64,
            price_sources: [],
            processed_ms: 1711422372144,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "3feaa346-78ba-4316-b825-f82a9a89f506",
            price: 70386.2,
            price_sources: [],
            processed_ms: 1711424193110,
          },
        ],
        position_type: "FLAT",
        position_uuid: "97c0e1f5-7cf3-4259-9c98-8cb6ace4c720",
        return_at_close: 1.004149370887867,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 74155.97000000025,
        close_ms: 1711435089558,
        current_return: 1.0129369965680362,
        initial_entry_price: 70647.58,
        is_closed_position: true,
        miner_hotkey: "5ERw6vNzMyyhZQ5vKcN6soK4zm4LBA7tKE3P6jUAWM3PRucW",
        net_leverage: 0.0,
        open_ms: 1711426007888,
        orders: [
          {
            leverage: -5.0,
            order_type: "SHORT",
            order_uuid: "3476bafd-9411-4592-8f6b-3a6228870f08",
            price: 70647.58,
            price_sources: [],
            processed_ms: 1711426007885,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "2d4e0e9d-a6a7-4df1-b6f2-78e7483a5235",
            price: 70561.23,
            price_sources: [],
            processed_ms: 1711427827317,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "34a1660c-bc60-42bc-8721-cc2b5589b18c",
            price: 70526.62,
            price_sources: [],
            processed_ms: 1711429643223,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "35712ec9-7a02-4c9e-a4d9-afe368b89fd2",
            price: 70310.59,
            price_sources: [],
            processed_ms: 1711431454072,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "3871ef99-52e6-4afc-942c-1d9ee341b927",
            price: 70404.99,
            price_sources: [],
            processed_ms: 1711433273987,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "f64a2c6a-1715-4c38-95be-e770493e92bf",
            price: 70500.1,
            price_sources: [],
            processed_ms: 1711435089558,
          },
        ],
        position_type: "FLAT",
        position_uuid: "b4e5c0c8-3e99-4e37-90e2-8a90cd662626",
        return_at_close: 1.0028076266023558,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 70820.73,
        close_ms: 1711437950029,
        current_return: 0.9580278260334227,
        initial_entry_price: 70820.73,
        is_closed_position: true,
        miner_hotkey: "5ERw6vNzMyyhZQ5vKcN6soK4zm4LBA7tKE3P6jUAWM3PRucW",
        net_leverage: 0.0,
        open_ms: 1711436904956,
        orders: [
          {
            leverage: -5.0,
            order_type: "SHORT",
            order_uuid: "04d387e4-52e0-4483-ace6-17fa7d49d078",
            price: 70820.73,
            price_sources: [],
            processed_ms: 1711436904954,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "f7f0ab1d-12a0-4d3c-a2aa-bb845a231747",
            price: 71415.23,
            price_sources: [],
            processed_ms: 1711437950029,
          },
        ],
        position_type: "FLAT",
        position_uuid: "e6b3a4b2-cf28-49a3-ae0a-f5dd8497e1db",
        return_at_close: 0.9484475477730885,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 71267.99,
        close_ms: 1711440137588,
        current_return: 1.0586918474900167,
        initial_entry_price: 71267.99,
        is_closed_position: true,
        miner_hotkey: "5ERw6vNzMyyhZQ5vKcN6soK4zm4LBA7tKE3P6jUAWM3PRucW",
        net_leverage: 0.0,
        open_ms: 1711438771779,
        orders: [
          {
            leverage: -5.0,
            order_type: "SHORT",
            order_uuid: "91917667-48a0-483b-8aa7-962b30a3ee72",
            price: 71267.99,
            price_sources: [],
            processed_ms: 1711438771776,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "cf7e02c3-134f-402e-b897-984d2da7a72a",
            price: 70431.42,
            price_sources: [],
            processed_ms: 1711440137588,
          },
        ],
        position_type: "FLAT",
        position_uuid: "34f17311-9d34-4163-9b8d-ef949689e0d7",
        return_at_close: 1.0481049290151165,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 64663.32999999983,
        close_ms: 1711453269589,
        current_return: 1.0173027075485428,
        initial_entry_price: 70661.06,
        is_closed_position: true,
        miner_hotkey: "5ERw6vNzMyyhZQ5vKcN6soK4zm4LBA7tKE3P6jUAWM3PRucW",
        net_leverage: 0.0,
        open_ms: 1711440557847,
        orders: [
          {
            leverage: 5.0,
            order_type: "LONG",
            order_uuid: "7b5461a2-04ae-4d76-9856-2bb918b61cbe",
            price: 70661.06,
            price_sources: [],
            processed_ms: 1711440557844,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "2a7d0143-77ec-4d29-970a-c7f72594c3cd",
            price: 70529.5,
            price_sources: [],
            processed_ms: 1711442354441,
          },
          {
            leverage: -3.25,
            order_type: "SHORT",
            order_uuid: "a063629d-3e3e-4dc1-9548-5cd6e3fc77a8",
            price: 70654.1,
            price_sources: [],
            processed_ms: 1711444174273,
          },
          {
            leverage: -1.625,
            order_type: "SHORT",
            order_uuid: "8abf7dc8-6d6f-4cd3-9848-86140242594c",
            price: 71014.31,
            price_sources: [],
            processed_ms: 1711446000390,
          },
          {
            leverage: -0.8125,
            order_type: "SHORT",
            order_uuid: "d2e44f46-c479-4530-a705-75a8a48a2ffa",
            price: 71067.82,
            price_sources: [],
            processed_ms: 1711447813709,
          },
          {
            leverage: -0.40625,
            order_type: "SHORT",
            order_uuid: "ddea3165-3822-4f31-8014-2d3aaab804ba",
            price: 70950.02,
            price_sources: [],
            processed_ms: 1711449635393,
          },
          {
            leverage: -0.203125,
            order_type: "SHORT",
            order_uuid: "af837d1f-9663-4498-96de-49e8eeca6fda",
            price: 70767.67,
            price_sources: [],
            processed_ms: 1711451453538,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "3daf7830-afc7-46d7-8e58-5f966ea55b26",
            price: 70682.42,
            price_sources: [],
            processed_ms: 1711453269589,
          },
        ],
        position_type: "FLAT",
        position_uuid: "c75436bf-6684-4c11-88e6-6c6e26617094",
        return_at_close: 1.0040777723504117,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 70036.79390243902,
        close_ms: 1711470300172,
        current_return: 0.948866399221979,
        initial_entry_price: 70579.08,
        is_closed_position: true,
        miner_hotkey: "5ERw6vNzMyyhZQ5vKcN6soK4zm4LBA7tKE3P6jUAWM3PRucW",
        net_leverage: 0.0,
        open_ms: 1711455084608,
        orders: [
          {
            leverage: 5.0,
            order_type: "LONG",
            order_uuid: "d5d08d2e-f84c-496c-8cf6-0f2827e4e4f1",
            price: 70579.08,
            price_sources: [],
            processed_ms: 1711455084606,
          },
          {
            leverage: -2.5,
            order_type: "SHORT",
            order_uuid: "8551d6a2-90db-4cde-b2e2-534b491466b6",
            price: 71060.06,
            price_sources: [],
            processed_ms: 1711456893206,
          },
          {
            leverage: -1.25,
            order_type: "SHORT",
            order_uuid: "c64eb0fe-b4e1-4f50-a65f-d7fbc304c1d2",
            price: 70947.07,
            price_sources: [],
            processed_ms: 1711458717181,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "0e1787f0-9a7f-4e1a-87f1-d3a3e8ebd14a",
            price: 70129.07,
            price_sources: [],
            processed_ms: 1711460532285,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "7f481125-d0fb-447e-850d-4f385531e0b6",
            price: 70472.71,
            price_sources: [],
            processed_ms: 1711462344205,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "a1e8566e-3dce-4a36-a1d6-0118a9a9accd",
            price: 69762.69,
            price_sources: [],
            processed_ms: 1711464159188,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "b2581142-195f-4b4b-ba91-ef725be3dabe",
            price: 70101.47,
            price_sources: [],
            processed_ms: 1711465981606,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "15cd55fa-31ae-4ac8-af30-ab68d01c7c03",
            price: 70254.3,
            price_sources: [],
            processed_ms: 1711467792463,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "49cac817-a584-45aa-8d8b-9d449414cb27",
            price: 70156.91,
            price_sources: [],
            processed_ms: 1711469607232,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "a30d3cfc-3f89-4736-b165-4118a6faaa12",
            price: 69684.7,
            price_sources: [],
            processed_ms: 1711470300172,
          },
        ],
        position_type: "FLAT",
        position_uuid: "3adaa879-7f10-46dd-909b-5e66bb8306fb",
        return_at_close: 0.9294146380379285,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 69528.96307692307,
        close_ms: 1711474436411,
        current_return: 1.0535095133649717,
        initial_entry_price: 69519.04,
        is_closed_position: true,
        miner_hotkey: "5ERw6vNzMyyhZQ5vKcN6soK4zm4LBA7tKE3P6jUAWM3PRucW",
        net_leverage: 0.0,
        open_ms: 1711471428147,
        orders: [
          {
            leverage: 5.0,
            order_type: "LONG",
            order_uuid: "0ccf00dc-c321-4a0d-be68-780f290e6f55",
            price: 69519.04,
            price_sources: [],
            processed_ms: 1711471428144,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "455446a5-48f3-43e1-8d17-2e4413c3a378",
            price: 69562.04,
            price_sources: [],
            processed_ms: 1711473243072,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "71fe92af-8824-4a88-bb0a-491689a84639",
            price: 70101.26,
            price_sources: [],
            processed_ms: 1711474436411,
          },
        ],
        position_type: "FLAT",
        position_uuid: "f3a6efa7-366d-4ff2-8126-e3ded2eaa68b",
        return_at_close: 1.039813889691227,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 70153.76375,
        close_ms: 1711480117184,
        current_return: 0.9457537820596436,
        initial_entry_price: 70079.54,
        is_closed_position: true,
        miner_hotkey: "5ERw6vNzMyyhZQ5vKcN6soK4zm4LBA7tKE3P6jUAWM3PRucW",
        net_leverage: 0.0,
        open_ms: 1711475063029,
        orders: [
          {
            leverage: 5.0,
            order_type: "LONG",
            order_uuid: "235fff20-8c6e-405e-96d3-a6aa22089f58",
            price: 70079.54,
            price_sources: [],
            processed_ms: 1711475063026,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "f14db884-c734-44dd-a9e2-4d8b744172ab",
            price: 70238.68,
            price_sources: [],
            processed_ms: 1711476879193,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "5b3ea09e-25af-4558-8925-270b98b85c9d",
            price: 70316.26,
            price_sources: [],
            processed_ms: 1711478694799,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "8ad8db38-c681-45ce-92bf-c8fc4d3a354c",
            price: 69678.57,
            price_sources: [],
            processed_ms: 1711480117184,
          },
        ],
        position_type: "FLAT",
        position_uuid: "b3ba51e5-c81c-4a39-ac35-137e2a64927e",
        return_at_close: 0.9306217215466892,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 69805.96363636362,
        close_ms: 1711488755509,
        current_return: 1.0635539978082917,
        initial_entry_price: 69835.89,
        is_closed_position: true,
        miner_hotkey: "5ERw6vNzMyyhZQ5vKcN6soK4zm4LBA7tKE3P6jUAWM3PRucW",
        net_leverage: 0.0,
        open_ms: 1711480527716,
        orders: [
          {
            leverage: 5.0,
            order_type: "LONG",
            order_uuid: "05c941d9-26b2-4992-8133-7ade2b218e43",
            price: 69835.89,
            price_sources: [],
            processed_ms: 1711480527713,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "3cfe5342-627b-444a-8d71-797ffde6a52a",
            price: 69796.0,
            price_sources: [],
            processed_ms: 1711482344293,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "f61ae8ff-8cd0-4d62-9f48-20bc00f14e44",
            price: 69692.3,
            price_sources: [],
            processed_ms: 1711484155631,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "8e61d539-6dc0-429c-b247-658867d045fe",
            price: 69758.0,
            price_sources: [],
            processed_ms: 1711485978608,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "7f66e07c-efc8-4004-86e4-66d1c9eead16",
            price: 69877.8,
            price_sources: [],
            processed_ms: 1711487794346,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "d0474583-0575-4946-865b-123ceeef0d36",
            price: 70209.45,
            price_sources: [],
            processed_ms: 1711488755509,
          },
        ],
        position_type: "FLAT",
        position_uuid: "7ea02c04-79bb-4ec2-a1f4-1a604966a018",
        return_at_close: 1.0401558098565094,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 69542.52857142861,
        close_ms: 1711501104598,
        current_return: 1.050063602682361,
        initial_entry_price: 70130.69,
        is_closed_position: true,
        miner_hotkey: "5ERw6vNzMyyhZQ5vKcN6soK4zm4LBA7tKE3P6jUAWM3PRucW",
        net_leverage: 0.0,
        open_ms: 1711489609358,
        orders: [
          {
            leverage: 5.0,
            order_type: "LONG",
            order_uuid: "a82d8d47-5f12-46b4-a7ab-c32203639a93",
            price: 70130.69,
            price_sources: [],
            processed_ms: 1711489609354,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "1e3d0c55-d2c2-4de0-a791-3a4a13b12078",
            price: 69883.24,
            price_sources: [],
            processed_ms: 1711491431021,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "80ce022e-abb7-481f-b425-c24ddbc12e2f",
            price: 70085.2,
            price_sources: [],
            processed_ms: 1711493243875,
          },
          {
            leverage: -4.0,
            order_type: "SHORT",
            order_uuid: "23c7dd65-41c7-468b-97a5-b070a7d021ba",
            price: 70259.63,
            price_sources: [],
            processed_ms: 1711495052352,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "06b0c1f9-c01b-46ee-b81c-fa0b9447d3aa",
            price: 70051.19,
            price_sources: [],
            processed_ms: 1711496871271,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "26b54e3b-f90d-481f-b4e1-6bbb1d55c23a",
            price: 69939.93,
            price_sources: [],
            processed_ms: 1711498688612,
          },
          {
            leverage: -3.5,
            order_type: "SHORT",
            order_uuid: "b57a0a1b-a912-4400-b8fb-e5e9f02bc442",
            price: 70330.12,
            price_sources: [],
            processed_ms: 1711500502055,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "86273362-22b8-414a-be26-19a509a4e46a",
            price: 70545.67,
            price_sources: [],
            processed_ms: 1711501104598,
          },
        ],
        position_type: "FLAT",
        position_uuid: "80d83e12-881f-4bf7-ae6d-7a91866030fc",
        return_at_close: 1.0332625850394432,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 70370.70571428572,
        close_ms: 1711515204320,
        current_return: 0.9461675636040463,
        initial_entry_price: 70398.82,
        is_closed_position: true,
        miner_hotkey: "5ERw6vNzMyyhZQ5vKcN6soK4zm4LBA7tKE3P6jUAWM3PRucW",
        net_leverage: 0.0,
        open_ms: 1711504134120,
        orders: [
          {
            leverage: 5.0,
            order_type: "LONG",
            order_uuid: "eae070e5-e6ff-492e-896f-a817b4a5aad8",
            price: 70398.82,
            price_sources: [],
            processed_ms: 1711504134117,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "b83a08db-a8d6-4dd7-bc93-85b3701e190b",
            price: 70224.81,
            price_sources: [],
            processed_ms: 1711505955616,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "5cee2644-0cf6-4352-9643-e0703f079e27",
            price: 70364.51,
            price_sources: [],
            processed_ms: 1711507771295,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "d631765b-b47a-4763-9ac8-9f4d76075287",
            price: 70539.53,
            price_sources: [],
            processed_ms: 1711509588130,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "0216be0f-f543-489e-b693-3e42e81f46c6",
            price: 70446.32,
            price_sources: [],
            processed_ms: 1711511399180,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "31ddd90c-8563-4b13-a3ee-33f284d35fb9",
            price: 70300.01,
            price_sources: [],
            processed_ms: 1711513216163,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "4b8dbaae-704b-46eb-bcee-9e40ce376c62",
            price: 70255.34,
            price_sources: [],
            processed_ms: 1711515034097,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "a25fe2a6-d031-4f4a-b5c4-8649b7219463",
            price: 70100.01,
            price_sources: [],
            processed_ms: 1711515204320,
          },
        ],
        position_type: "FLAT",
        position_uuid: "c4786f5c-b320-43d0-b253-dacd465647e3",
        return_at_close: 0.919674871823133,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 70367.81578947368,
        close_ms: 1711524223341,
        current_return: 0.939627196746411,
        initial_entry_price: 70381.36,
        is_closed_position: true,
        miner_hotkey: "5ERw6vNzMyyhZQ5vKcN6soK4zm4LBA7tKE3P6jUAWM3PRucW",
        net_leverage: 0.0,
        open_ms: 1711516850547,
        orders: [
          {
            leverage: 5.0,
            order_type: "LONG",
            order_uuid: "88cf8aa5-1c1b-470d-9dc1-1071c2cb9c86",
            price: 70381.36,
            price_sources: [],
            processed_ms: 1711516850544,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "e34ec4c6-a268-479d-88df-0ce2e8caf5c2",
            price: 70431.7,
            price_sources: [],
            processed_ms: 1711518665370,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "14a2dc6b-7c81-4dec-ac4a-2f77c45ee9ff",
            price: 70371.87,
            price_sources: [],
            processed_ms: 1711520486252,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "b646e9f8-138c-45e0-ba37-77ffc132e871",
            price: 70254.73,
            price_sources: [],
            processed_ms: 1711522380407,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "c328d4e0-f2bf-4818-be2e-e99e9b103cce",
            price: 69920.54,
            price_sources: [],
            processed_ms: 1711524223341,
          },
        ],
        position_type: "FLAT",
        position_uuid: "38892e7c-96cb-4ac7-b1da-7aaed689b62b",
        return_at_close: 0.9217742800082293,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
    ],
    thirty_day_returns: 1.0,
  },
  "5ETimw455Vhhfitx3FfrdSH5bpeimRHbQuUksSVv3CYYSnT5": {
    all_time_returns: 1.0,
    n_positions: 0,
    percentage_profitable: 0.0,
    positions: [
      {
        average_entry_price: 3487.3654063604245,
        close_ms: 0,
        current_return: 0.8394136031376407,
        initial_entry_price: 3087.67,
        is_closed_position: false,
        miner_hotkey: "5ETimw455Vhhfitx3FfrdSH5bpeimRHbQuUksSVv3CYYSnT5",
        net_leverage: 1.415,
        open_ms: 1716175620023,
        orders: [
          {
            leverage: 0.065,
            order_type: "LONG",
            order_uuid: "93113854-1367-4945-a3ac-101ed0b06f08",
            price: 3087.67,
            price_sources: [],
            processed_ms: 1716175618154,
          },
          {
            leverage: 1.35,
            order_type: "LONG",
            order_uuid: "5ee47608-d861-4b26-b808-9248e882d2e2",
            price: 3506.61,
            price_sources: [],
            processed_ms: 1718276539243,
          },
        ],
        position_type: "LONG",
        position_uuid: "c4c1e92f-3b4b-4d09-92a4-70d80621932a",
        return_at_close: 0.8388197180134208,
        trade_pair: ["ETHUSD", "ETH/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 167.743,
        close_ms: 0,
        current_return: 1.0001302438849908,
        initial_entry_price: 167.743,
        is_closed_position: false,
        miner_hotkey: "5ETimw455Vhhfitx3FfrdSH5bpeimRHbQuUksSVv3CYYSnT5",
        net_leverage: 0.003,
        open_ms: 1715840970915,
        orders: [
          {
            leverage: 0.003,
            order_type: "LONG",
            order_uuid: "9006c0d3-7c28-4eb6-9281-740eec0f6906",
            price: 167.743,
            price_sources: [],
            processed_ms: 1715840968859,
          },
        ],
        position_type: "LONG",
        position_uuid: "f9c0cd7c-b28d-4f81-9f95-90a009fd6463",
        return_at_close: 1.0001301388713153,
        trade_pair: ["EURJPY", "EUR/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 66852.6,
        close_ms: 0,
        current_return: 0.9998696296030372,
        initial_entry_price: 66852.6,
        is_closed_position: false,
        miner_hotkey: "5ETimw455Vhhfitx3FfrdSH5bpeimRHbQuUksSVv3CYYSnT5",
        net_leverage: 0.001,
        open_ms: 1716011240258,
        orders: [
          {
            leverage: 0.001,
            order_type: "LONG",
            order_uuid: "44a034f1-a40a-4cd2-b3ae-48b2b32e6ce9",
            price: 66852.6,
            price_sources: [],
            processed_ms: 1716011239257,
          },
        ],
        position_type: "LONG",
        position_uuid: "900a06e6-26f7-4a30-806f-d6a3e82c510e",
        return_at_close: 0.9998691296682224,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 1.07648,
        close_ms: 0,
        current_return: 0.9836132580261596,
        initial_entry_price: 1.07648,
        is_closed_position: false,
        miner_hotkey: "5ETimw455Vhhfitx3FfrdSH5bpeimRHbQuUksSVv3CYYSnT5",
        net_leverage: -3.0,
        open_ms: 1718194206139,
        orders: [
          {
            leverage: -3.0,
            order_type: "SHORT",
            order_uuid: "8be9a8e8-f48c-45b2-b3f6-ced8625b7ed1",
            price: 1.07648,
            price_sources: [],
            processed_ms: 1718194206137,
          },
        ],
        position_type: "SHORT",
        position_uuid: "d923e977-0eb7-49a3-8fbe-021fa8e8ec60",
        return_at_close: 0.9835099786340669,
        trade_pair: ["EURUSD", "EUR/USD", 7e-5, 0.001, 500],
      },
    ],
    thirty_day_returns: 1.0,
  },
  "5EUTaAo7vCGxvLDWRXRrEuqctPjt9fKZmgkaeFZocWECUe9X": {
    all_time_returns: 1.0400149323708288,
    n_positions: 145,
    percentage_profitable: 0.7034482758620689,
    positions: [
      {
        average_entry_price: 2916.16,
        close_ms: 1714645868453,
        current_return: 1.000878209700428,
        initial_entry_price: 2916.16,
        is_closed_position: true,
        miner_hotkey: "5EUTaAo7vCGxvLDWRXRrEuqctPjt9fKZmgkaeFZocWECUe9X",
        net_leverage: 0.0,
        open_ms: 1714625949570,
        orders: [
          {
            leverage: 0.1,
            order_type: "LONG",
            order_uuid: "71d3bf9b-38e8-4d3e-8846-d86f1e139418",
            price: 2916.16,
            price_sources: [],
            processed_ms: 1714625949386,
          },
          {
            leverage: 0.5,
            order_type: "FLAT",
            order_uuid: "435b104d-5936-448f-80ce-f0e9e2c9b313",
            price: 2941.77,
            price_sources: [],
            processed_ms: 1714645868453,
          },
        ],
        position_type: "FLAT",
        position_uuid: "4963a948-8b74-4d31-b450-d1bc9ec0d88e",
        return_at_close: 1.000778121879458,
        trade_pair: ["ETHUSD", "ETH/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 57272.4,
        close_ms: 1714645873492,
        current_return: 1.0006467862356039,
        initial_entry_price: 57272.4,
        is_closed_position: true,
        miner_hotkey: "5EUTaAo7vCGxvLDWRXRrEuqctPjt9fKZmgkaeFZocWECUe9X",
        net_leverage: 0.0,
        open_ms: 1714625935870,
        orders: [
          {
            leverage: 0.1,
            order_type: "LONG",
            order_uuid: "2fef4173-be72-49a3-99b6-e7872cf670e1",
            price: 57272.4,
            price_sources: [],
            processed_ms: 1714625935649,
          },
          {
            leverage: 0.5,
            order_type: "FLAT",
            order_uuid: "2e047b5e-dac3-4342-9068-8943e26107a0",
            price: 57642.83,
            price_sources: [],
            processed_ms: 1714645873492,
          },
        ],
        position_type: "FLAT",
        position_uuid: "be64626b-575e-47e6-8a96-3df9de704357",
        return_at_close: 1.0005467215569803,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 2992.53,
        close_ms: 1714654538909,
        current_return: 0.9990875638204445,
        initial_entry_price: 2994.73,
        is_closed_position: true,
        miner_hotkey: "5EUTaAo7vCGxvLDWRXRrEuqctPjt9fKZmgkaeFZocWECUe9X",
        net_leverage: 0.0,
        open_ms: 1714649010893,
        orders: [
          {
            leverage: 0.5,
            order_type: "LONG",
            order_uuid: "92263192-3220-48b3-b307-c1297c998198",
            price: 2994.73,
            price_sources: [],
            processed_ms: 1714649010588,
          },
          {
            leverage: -0.25,
            order_type: "SHORT",
            order_uuid: "abdc5773-57aa-4981-a90b-9b70dbdd75da",
            price: 2996.93,
            price_sources: [],
            processed_ms: 1714650907759,
          },
          {
            leverage: 0.1,
            order_type: "FLAT",
            order_uuid: "b49ef7ff-fe6f-4439-953f-88280d92e4f0",
            price: 2981.6,
            price_sources: [],
            processed_ms: 1714654538909,
          },
        ],
        position_type: "FLAT",
        position_uuid: "6b9b5f86-4c2e-4d4a-9273-77bed4bdb5e1",
        return_at_close: 0.9985880200385343,
        trade_pair: ["ETHUSD", "ETH/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 57731.30333333332,
        close_ms: 1714654540521,
        current_return: 1.002318928468524,
        initial_entry_price: 58341.17,
        is_closed_position: true,
        miner_hotkey: "5EUTaAo7vCGxvLDWRXRrEuqctPjt9fKZmgkaeFZocWECUe9X",
        net_leverage: 0.0,
        open_ms: 1714649000740,
        orders: [
          {
            leverage: 0.5,
            order_type: "LONG",
            order_uuid: "ba373593-a55a-46fc-a932-45cb662bce09",
            price: 58341.17,
            price_sources: [],
            processed_ms: 1714649000555,
          },
          {
            leverage: -0.25,
            order_type: "SHORT",
            order_uuid: "5a265b4c-7322-49dc-83e4-dfcd007a224b",
            price: 58503.71,
            price_sources: [],
            processed_ms: 1714650318827,
          },
          {
            leverage: -0.1,
            order_type: "SHORT",
            order_uuid: "da319913-64e1-4fc9-ba62-05656c34a325",
            price: 58849.62,
            price_sources: [],
            processed_ms: 1714652085745,
          },
          {
            leverage: 0.1,
            order_type: "FLAT",
            order_uuid: "e8502686-0513-4432-8329-8438b61f6e37",
            price: 58633.23,
            price_sources: [],
            processed_ms: 1714654540521,
          },
        ],
        position_type: "FLAT",
        position_uuid: "ee8a82a3-9501-481c-929d-731482fbb323",
        return_at_close: 1.00181776900429,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 59158.4,
        close_ms: 1714681855896,
        current_return: 0.999428145453562,
        initial_entry_price: 59158.4,
        is_closed_position: true,
        miner_hotkey: "5EUTaAo7vCGxvLDWRXRrEuqctPjt9fKZmgkaeFZocWECUe9X",
        net_leverage: 0.0,
        open_ms: 1714677273059,
        orders: [
          {
            leverage: 0.5,
            order_type: "LONG",
            order_uuid: "95003164-31ad-428e-8b11-576ee41f37b1",
            price: 59158.4,
            price_sources: [],
            processed_ms: 1714677272811,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "823f3816-bc6f-49a6-8f67-6e13a6a8112f",
            price: 59090.74,
            price_sources: [],
            processed_ms: 1714681855896,
          },
        ],
        position_type: "FLAT",
        position_uuid: "2329c3c2-f78c-484a-8f40-858185ad0621",
        return_at_close: 0.9989284313808352,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 1.38489,
        close_ms: 1714683491959,
        current_return: 1.001275813522602,
        initial_entry_price: 1.37089,
        is_closed_position: true,
        miner_hotkey: "5EUTaAo7vCGxvLDWRXRrEuqctPjt9fKZmgkaeFZocWECUe9X",
        net_leverage: 0.0,
        open_ms: 1714661619511,
        orders: [
          {
            leverage: -1.0,
            order_type: "SHORT",
            order_uuid: "5441d082-2050-4fba-af00-4b21da2bbe98",
            price: 1.37089,
            price_sources: [],
            processed_ms: 1714661619283,
          },
          {
            leverage: 0.5,
            order_type: "LONG",
            order_uuid: "260d8679-c8af-4f08-a07e-7d998408100d",
            price: 1.36977,
            price_sources: [],
            processed_ms: 1714665577984,
          },
          {
            leverage: 0.4,
            order_type: "LONG",
            order_uuid: "8ac255c4-6b2a-40cb-833c-af19e3b280ab",
            price: 1.36879,
            price_sources: [],
            processed_ms: 1714669839255,
          },
          {
            leverage: 0.25,
            order_type: "FLAT",
            order_uuid: "69f8fb81-50bb-4675-ae39-53c321d80816",
            price: 1.3674,
            price_sources: [],
            processed_ms: 1714683491959,
          },
        ],
        position_type: "FLAT",
        position_uuid: "a4e4aa42-14ec-477b-8a8c-5d63fabfb5d5",
        return_at_close: 1.0012057242156556,
        trade_pair: ["USDCAD", "USD/CAD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.9270099999999996,
        close_ms: 1714684526934,
        current_return: 1.0005028900549315,
        initial_entry_price: 0.97576,
        is_closed_position: true,
        miner_hotkey: "5EUTaAo7vCGxvLDWRXRrEuqctPjt9fKZmgkaeFZocWECUe9X",
        net_leverage: 0.0,
        open_ms: 1714657524387,
        orders: [
          {
            leverage: 1.0,
            order_type: "LONG",
            order_uuid: "df8fb70e-3e81-4a52-b6f4-e8521428ed9e",
            price: 0.97576,
            price_sources: [],
            processed_ms: 1714657524201,
          },
          {
            leverage: -0.5,
            order_type: "SHORT",
            order_uuid: "24bb631f-c8db-4f75-8fdb-b12b3f91a73e",
            price: 0.976,
            price_sources: [],
            processed_ms: 1714661671866,
          },
          {
            leverage: -0.49,
            order_type: "SHORT",
            order_uuid: "f9424a89-2485-4d2e-90c3-3ebde452e610",
            price: 0.97651,
            price_sources: [],
            processed_ms: 1714663299856,
          },
          {
            leverage: 0.25,
            order_type: "FLAT",
            order_uuid: "b2a369ab-961a-42ad-9160-904e01f32f2f",
            price: 0.97608,
            price_sources: [],
            processed_ms: 1714684526934,
          },
        ],
        position_type: "FLAT",
        position_uuid: "25ffbf0b-cfc5-4b77-9c82-0c464f28db66",
        return_at_close: 1.0004328548526278,
        trade_pair: ["EURCHF", "EUR/CHF", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 58873.57,
        close_ms: 1714684798813,
        current_return: 0.999207798159056,
        initial_entry_price: 58832.86,
        is_closed_position: true,
        miner_hotkey: "5EUTaAo7vCGxvLDWRXRrEuqctPjt9fKZmgkaeFZocWECUe9X",
        net_leverage: 0.0,
        open_ms: 1714682331506,
        orders: [
          {
            leverage: -0.5,
            order_type: "SHORT",
            order_uuid: "0bf6a3ad-b1c1-4414-af26-121d1ab7286e",
            price: 58832.86,
            price_sources: [],
            processed_ms: 1714682331243,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "b1cd3034-748a-4fbc-bb57-2abd81cb474c",
            price: 58792.15,
            price_sources: [],
            processed_ms: 1714683623813,
          },
          {
            leverage: 0.25,
            order_type: "FLAT",
            order_uuid: "05a84225-98b6-42e7-8040-98c4a0a24aa0",
            price: 59060.0,
            price_sources: [],
            processed_ms: 1714684798813,
          },
        ],
        position_type: "FLAT",
        position_uuid: "3f9a3ee3-5b19-4f9a-a433-8bbb3e3e03a5",
        return_at_close: 0.9987081942599766,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 191.44400000000002,
        close_ms: 1714689133601,
        current_return: 1.002609497320187,
        initial_entry_price: 191.991,
        is_closed_position: true,
        miner_hotkey: "5EUTaAo7vCGxvLDWRXRrEuqctPjt9fKZmgkaeFZocWECUe9X",
        net_leverage: 0.0,
        open_ms: 1714679427448,
        orders: [
          {
            leverage: 1.0,
            order_type: "LONG",
            order_uuid: "301af2bc-da93-4878-9aee-d77ce325d533",
            price: 191.991,
            price_sources: [],
            processed_ms: 1714679425771,
          },
          {
            leverage: -0.5,
            order_type: "SHORT",
            order_uuid: "6b629bd2-c6b5-40e2-b66f-ecbedfe673df",
            price: 192.538,
            price_sources: [],
            processed_ms: 1714682079173,
          },
          {
            leverage: 0.25,
            order_type: "FLAT",
            order_uuid: "d57c3280-2a5e-4a9a-a351-20b1b9119b86",
            price: 192.446,
            price_sources: [],
            processed_ms: 1714689133601,
          },
        ],
        position_type: "FLAT",
        position_uuid: "72f33fb8-fef9-4863-87c4-4371828d34f5",
        return_at_close: 1.0025393146553745,
        trade_pair: ["GBPJPY", "GBP/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 153.105,
        close_ms: 1714693544061,
        current_return: 1.0030083929329543,
        initial_entry_price: 153.105,
        is_closed_position: true,
        miner_hotkey: "5EUTaAo7vCGxvLDWRXRrEuqctPjt9fKZmgkaeFZocWECUe9X",
        net_leverage: 0.0,
        open_ms: 1714691634734,
        orders: [
          {
            leverage: -4.9,
            order_type: "SHORT",
            order_uuid: "a70e68ed-f181-42f9-8454-0182f903890b",
            price: 153.105,
            price_sources: [],
            processed_ms: 1714691634501,
          },
          {
            leverage: 4.9,
            order_type: "FLAT",
            order_uuid: "ac79f4c2-10d8-48b2-921d-4e2966f36af6",
            price: 153.011,
            price_sources: [],
            processed_ms: 1714693544061,
          },
        ],
        position_type: "FLAT",
        position_uuid: "011ab7b5-f27f-4374-bedd-960a3da5d51f",
        return_at_close: 1.0026643610541783,
        trade_pair: ["USDJPY", "USD/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 2992.09,
        close_ms: 1714694984136,
        current_return: 0.9983857437443393,
        initial_entry_price: 2992.09,
        is_closed_position: true,
        miner_hotkey: "5EUTaAo7vCGxvLDWRXRrEuqctPjt9fKZmgkaeFZocWECUe9X",
        net_leverage: 0.0,
        open_ms: 1714691240010,
        orders: [
          {
            leverage: 1.0,
            order_type: "LONG",
            order_uuid: "d59b66d5-1adc-4499-b1f3-9436f1a1a70c",
            price: 2992.09,
            price_sources: [],
            processed_ms: 1714691239798,
          },
          {
            leverage: 4.9,
            order_type: "FLAT",
            order_uuid: "5227be7f-7c0c-4e9f-8cb4-e55bc88b4af1",
            price: 2987.26,
            price_sources: [],
            processed_ms: 1714694984136,
          },
        ],
        position_type: "FLAT",
        position_uuid: "525aedda-fc72-4878-b403-605a34da5754",
        return_at_close: 0.997387358000595,
        trade_pair: ["ETHUSD", "ETH/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 3002.7299999999996,
        close_ms: 1714700056310,
        current_return: 1.001593035256517,
        initial_entry_price: 3003.7,
        is_closed_position: true,
        miner_hotkey: "5EUTaAo7vCGxvLDWRXRrEuqctPjt9fKZmgkaeFZocWECUe9X",
        net_leverage: 0.0,
        open_ms: 1714699057423,
        orders: [
          {
            leverage: 1.0,
            order_type: "LONG",
            order_uuid: "8629da09-f763-40c9-88e5-5ebbe5010114",
            price: 3003.7,
            price_sources: [],
            processed_ms: 1714699057194,
          },
          {
            leverage: -0.5,
            order_type: "SHORT",
            order_uuid: "12d191c7-b1f9-49f9-94eb-7bd680392ea1",
            price: 3004.67,
            price_sources: [],
            processed_ms: 1714699570593,
          },
          {
            leverage: 0.5,
            order_type: "FLAT",
            order_uuid: "a57ba8e2-9972-4c21-9b43-7ecb352967d8",
            price: 3012.3,
            price_sources: [],
            processed_ms: 1714700056310,
          },
        ],
        position_type: "FLAT",
        position_uuid: "7090883c-ce7e-429c-b1f2-516fbac2e337",
        return_at_close: 1.0005914422212605,
        trade_pair: ["ETHUSD", "ETH/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 3006.6,
        close_ms: 1714702198692,
        current_return: 0.9974381361005787,
        initial_entry_price: 3006.6,
        is_closed_position: true,
        miner_hotkey: "5EUTaAo7vCGxvLDWRXRrEuqctPjt9fKZmgkaeFZocWECUe9X",
        net_leverage: 0.0,
        open_ms: 1714701499876,
        orders: [
          {
            leverage: -0.75,
            order_type: "SHORT",
            order_uuid: "2eb6e2dd-309d-41c1-8aec-7a9267881212",
            price: 3006.6,
            price_sources: [],
            processed_ms: 1714701499637,
          },
          {
            leverage: 0.75,
            order_type: "FLAT",
            order_uuid: "f6519b59-8e76-41b2-ab8b-b5adf52a6bb1",
            price: 3016.87,
            price_sources: [],
            processed_ms: 1714702198692,
          },
        ],
        position_type: "FLAT",
        position_uuid: "2b56f4a3-ecc6-4ca0-acdc-05f90391fe7a",
        return_at_close: 0.9966900574985033,
        trade_pair: ["ETHUSD", "ETH/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 1.0841049999999997,
        close_ms: 1714747181821,
        current_return: 1.0072428219700473,
        initial_entry_price: 1.08038,
        is_closed_position: true,
        miner_hotkey: "5EUTaAo7vCGxvLDWRXRrEuqctPjt9fKZmgkaeFZocWECUe9X",
        net_leverage: 0.0,
        open_ms: 1714740158523,
        orders: [
          {
            leverage: -1.5,
            order_type: "SHORT",
            order_uuid: "a132eb17-ded9-40a3-a6f6-e975ccc0b21d",
            price: 1.08038,
            price_sources: [],
            processed_ms: 1714740156237,
          },
          {
            leverage: -0.5,
            order_type: "SHORT",
            order_uuid: "7e51c956-9909-4758-920c-c8c1ec4e7c38",
            price: 1.0782,
            price_sources: [],
            processed_ms: 1714744167233,
          },
          {
            leverage: 0.5,
            order_type: "LONG",
            order_uuid: "a33ff192-406b-4344-a0c9-60a114ba2899",
            price: 1.07593,
            price_sources: [],
            processed_ms: 1714745994402,
          },
          {
            leverage: 0.5,
            order_type: "LONG",
            order_uuid: "3a229139-1305-4e98-9596-546990986c09",
            price: 1.0752,
            price_sources: [],
            processed_ms: 1714746362827,
          },
          {
            leverage: 0.5,
            order_type: "FLAT",
            order_uuid: "c5323acd-8c1a-4a79-8624-e1557c00e9d5",
            price: 1.07628,
            price_sources: [],
            processed_ms: 1714747181821,
          },
        ],
        position_type: "FLAT",
        position_uuid: "d3cd0cb5-6ebd-4f7a-b90b-285c8c57efb9",
        return_at_close: 1.0071018079749714,
        trade_pair: ["EURUSD", "EUR/USD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.904140875839926,
        close_ms: 1715019437126,
        current_return: 1.001259896760022,
        initial_entry_price: 0.904140875839926,
        is_closed_position: true,
        miner_hotkey: "5EUTaAo7vCGxvLDWRXRrEuqctPjt9fKZmgkaeFZocWECUe9X",
        net_leverage: 0.0,
        open_ms: 1715008867636,
        orders: [
          {
            leverage: 1.0,
            order_type: "LONG",
            order_uuid: "6a0f8677-26e4-47fe-8a17-e45814a2a3ed",
            price: 0.904140875839926,
            price_sources: [],
            processed_ms: 1715008867411,
          },
          {
            leverage: 1.5,
            order_type: "FLAT",
            order_uuid: "aaa19f5c-07a1-4bf8-887a-e705934def33",
            price: 0.90528,
            price_sources: [],
            processed_ms: 1715019437126,
          },
        ],
        position_type: "FLAT",
        position_uuid: "60713fc2-8cb2-41f4-a245-c63f9f7dbb3d",
        return_at_close: 1.0011898085672486,
        trade_pair: ["AUDCAD", "AUD/CAD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 5162.18,
        close_ms: 1715020465096,
        current_return: 1.0022316153253081,
        initial_entry_price: 5162.18,
        is_closed_position: true,
        miner_hotkey: "5EUTaAo7vCGxvLDWRXRrEuqctPjt9fKZmgkaeFZocWECUe9X",
        net_leverage: 0.0,
        open_ms: 1715017249019,
        orders: [
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "74b23b29-1ffb-4f7c-a6c7-a03fb73d653c",
            price: 5162.18,
            price_sources: [],
            processed_ms: 1715017248762,
          },
          {
            leverage: 1.5,
            order_type: "FLAT",
            order_uuid: "3121f10c-837e-4c4d-8775-2f3784eea095",
            price: 5169.86,
            price_sources: [],
            processed_ms: 1715020465096,
          },
        ],
        position_type: "FLAT",
        position_uuid: "13001207-0599-4bf6-8bcf-e69df702a16b",
        return_at_close: 1.0020963140572392,
        trade_pair: ["SPX", "SPX", 9e-5, 0.001, 500],
      },
      {
        average_entry_price: 1.25664,
        close_ms: 1715023161468,
        current_return: 0.9995941558441558,
        initial_entry_price: 1.25664,
        is_closed_position: true,
        miner_hotkey: "5EUTaAo7vCGxvLDWRXRrEuqctPjt9fKZmgkaeFZocWECUe9X",
        net_leverage: 0.0,
        open_ms: 1715021397230,
        orders: [
          {
            leverage: 1.0,
            order_type: "LONG",
            order_uuid: "0f0a9747-374d-4731-9be9-9d5e42971a97",
            price: 1.25664,
            price_sources: [],
            processed_ms: 1715021397034,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "1f9ae518-372d-49f7-9a6e-0d475aafebdb",
            price: 1.25613,
            price_sources: [],
            processed_ms: 1715023161468,
          },
        ],
        position_type: "FLAT",
        position_uuid: "a86bca2a-5fca-4dfc-ba50-8bb7958f1f3c",
        return_at_close: 0.9995241842532466,
        trade_pair: ["GBPUSD", "GBP/USD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 1.0776,
        close_ms: 1715043204576,
        current_return: 1.0003201559020043,
        initial_entry_price: 1.0776,
        is_closed_position: true,
        miner_hotkey: "5EUTaAo7vCGxvLDWRXRrEuqctPjt9fKZmgkaeFZocWECUe9X",
        net_leverage: 0.0,
        open_ms: 1715009513624,
        orders: [
          {
            leverage: -1.0,
            order_type: "SHORT",
            order_uuid: "3493ff61-75c5-42b1-8941-7fdfd9ff9182",
            price: 1.0776,
            price_sources: [],
            processed_ms: 1715009513430,
          },
          {
            leverage: -0.5,
            order_type: "SHORT",
            order_uuid: "6d787971-4cbd-44d6-86f6-2cdeb2decc18",
            price: 1.0776,
            price_sources: [],
            processed_ms: 1715009948954,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "9be35811-acf8-4524-97f5-3d71a8aad972",
            price: 1.07737,
            price_sources: [],
            processed_ms: 1715043204576,
          },
        ],
        position_type: "FLAT",
        position_uuid: "d652253f-6d7f-4eb3-aa5e-be066e8c1aa7",
        return_at_close: 1.0002151222856346,
        trade_pair: ["EURUSD", "EUR/USD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.9033341856206472,
        close_ms: 1715043900934,
        current_return: 1.0034973989409217,
        initial_entry_price: 0.905191095677069,
        is_closed_position: true,
        miner_hotkey: "5EUTaAo7vCGxvLDWRXRrEuqctPjt9fKZmgkaeFZocWECUe9X",
        net_leverage: 0.0,
        open_ms: 1715020562124,
        orders: [
          {
            leverage: 1.0,
            order_type: "LONG",
            order_uuid: "4c64bea7-566a-45c3-805b-8f1ed8945de5",
            price: 0.905191095677069,
            price_sources: [],
            processed_ms: 1715020560264,
          },
          {
            leverage: 1.0,
            order_type: "LONG",
            order_uuid: "dc7edcc5-93c9-4527-807c-d2ff352730a6",
            price: 0.90501,
            price_sources: [],
            processed_ms: 1715024387915,
          },
          {
            leverage: -1.0,
            order_type: "SHORT",
            order_uuid: "48941ce4-4fb9-4594-b4f4-390a7a1c94ef",
            price: 0.906866910056422,
            price_sources: [],
            processed_ms: 1715043505144,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "24f88e1f-b491-40a0-a5b2-76b0123cf1ec",
            price: 0.9065,
            price_sources: [],
            processed_ms: 1715043900934,
          },
        ],
        position_type: "FLAT",
        position_uuid: "03825a16-4ab3-4e6d-9bbc-349ed4d5ea9c",
        return_at_close: 1.00335690930507,
        trade_pair: ["AUDCAD", "AUD/CAD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 193.504,
        close_ms: 1715044765942,
        current_return: 1.0009922275508516,
        initial_entry_price: 193.504,
        is_closed_position: true,
        miner_hotkey: "5EUTaAo7vCGxvLDWRXRrEuqctPjt9fKZmgkaeFZocWECUe9X",
        net_leverage: 0.0,
        open_ms: 1715044010341,
        orders: [
          {
            leverage: 1.0,
            order_type: "LONG",
            order_uuid: "1047a053-0339-4860-be69-d09b40f805a2",
            price: 193.504,
            price_sources: [],
            processed_ms: 1715044009181,
          },
          {
            leverage: 1.49,
            order_type: "FLAT",
            order_uuid: "68982bac-0d94-4203-b357-7c6dfde55858",
            price: 193.696,
            price_sources: [],
            processed_ms: 1715044765942,
          },
        ],
        position_type: "FLAT",
        position_uuid: "4552fc9f-6c81-41eb-aa5e-fef5d27a0889",
        return_at_close: 1.000922158094923,
        trade_pair: ["GBPJPY", "GBP/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 112.673,
        close_ms: 1715044771822,
        current_return: 1.0010739041296495,
        initial_entry_price: 112.673,
        is_closed_position: true,
        miner_hotkey: "5EUTaAo7vCGxvLDWRXRrEuqctPjt9fKZmgkaeFZocWECUe9X",
        net_leverage: 0.0,
        open_ms: 1715043990782,
        orders: [
          {
            leverage: 1.0,
            order_type: "LONG",
            order_uuid: "35d20fe4-1653-4e9e-a151-cc845afd03a7",
            price: 112.673,
            price_sources: [],
            processed_ms: 1715043988199,
          },
          {
            leverage: 1.49,
            order_type: "FLAT",
            order_uuid: "f4e9e8cf-b40f-4cb7-9129-680c677bc377",
            price: 112.794,
            price_sources: [],
            processed_ms: 1715044771822,
          },
        ],
        position_type: "FLAT",
        position_uuid: "25b0b5b6-81a5-4236-bf89-54aab8fa08a2",
        return_at_close: 1.0010038289563603,
        trade_pair: ["CADJPY", "CAD/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 112.80763,
        close_ms: 1715048337337,
        current_return: 1.0009251511337245,
        initial_entry_price: 112.814,
        is_closed_position: true,
        miner_hotkey: "5EUTaAo7vCGxvLDWRXRrEuqctPjt9fKZmgkaeFZocWECUe9X",
        net_leverage: 0.0,
        open_ms: 1715045022960,
        orders: [
          {
            leverage: 1.49,
            order_type: "LONG",
            order_uuid: "0c6a3b37-c941-4f2f-a7d5-66ee4064a977",
            price: 112.814,
            price_sources: [],
            processed_ms: 1715045022468,
          },
          {
            leverage: -0.49,
            order_type: "SHORT",
            order_uuid: "f4215415-27f4-4c9f-a29a-b41f146979e5",
            price: 112.827,
            price_sources: [],
            processed_ms: 1715045522509,
          },
          {
            leverage: 2.49,
            order_type: "FLAT",
            order_uuid: "68769adf-d468-454b-b616-080f43934c96",
            price: 112.912,
            price_sources: [],
            processed_ms: 1715048337337,
          },
        ],
        position_type: "FLAT",
        position_uuid: "95cb57c7-e610-4c77-b79c-8f1f52941dcd",
        return_at_close: 1.0008207546404613,
        trade_pair: ["CADJPY", "CAD/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 193.8616,
        close_ms: 1715076044938,
        current_return: 0.9989992726672476,
        initial_entry_price: 193.859,
        is_closed_position: true,
        miner_hotkey: "5EUTaAo7vCGxvLDWRXRrEuqctPjt9fKZmgkaeFZocWECUe9X",
        net_leverage: 0.0,
        open_ms: 1715048586232,
        orders: [
          {
            leverage: 1.0,
            order_type: "LONG",
            order_uuid: "1f5acbdd-ec00-489c-995e-b46022955bd4",
            price: 193.859,
            price_sources: [],
            processed_ms: 1715048584980,
          },
          {
            leverage: 0.5,
            order_type: "LONG",
            order_uuid: "ed8b3053-5409-4f38-aadb-23c6370b7444",
            price: 193.968,
            price_sources: [],
            processed_ms: 1715050986624,
          },
          {
            leverage: 1.0,
            order_type: "LONG",
            order_uuid: "fa923502-dd29-4d04-9ef2-f31b971d911f",
            price: 193.811,
            price_sources: [],
            processed_ms: 1715075784748,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "230429e8-c18b-4b89-ba26-d33003d2e908",
            price: 193.784,
            price_sources: [],
            processed_ms: 1715076044938,
          },
        ],
        position_type: "FLAT",
        position_uuid: "e5371c8d-3eb2-493e-890d-5db4e9c81e06",
        return_at_close: 0.9988244477945308,
        trade_pair: ["GBPJPY", "GBP/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 112.974,
        close_ms: 1715079105340,
        current_return: 0.9997875617398694,
        initial_entry_price: 112.974,
        is_closed_position: true,
        miner_hotkey: "5EUTaAo7vCGxvLDWRXRrEuqctPjt9fKZmgkaeFZocWECUe9X",
        net_leverage: 0.0,
        open_ms: 1715051015481,
        orders: [
          {
            leverage: 0.5,
            order_type: "LONG",
            order_uuid: "53bc9f7b-53dc-47d5-8e43-0feee3447afa",
            price: 112.974,
            price_sources: [],
            processed_ms: 1715051015307,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "13a3f7b2-64e8-42ea-ae24-6b38ca6195de",
            price: 112.926,
            price_sources: [],
            processed_ms: 1715079105340,
          },
        ],
        position_type: "FLAT",
        position_uuid: "e8ed3028-1964-4d5f-b1e9-8111fcd5fa61",
        return_at_close: 0.9997525691752085,
        trade_pair: ["CADJPY", "CAD/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 1.253595,
        close_ms: 1715082750431,
        current_return: 1.000693857368446,
        initial_entry_price: 1.25386,
        is_closed_position: true,
        miner_hotkey: "5EUTaAo7vCGxvLDWRXRrEuqctPjt9fKZmgkaeFZocWECUe9X",
        net_leverage: 0.0,
        open_ms: 1715075137620,
        orders: [
          {
            leverage: 1.0,
            order_type: "LONG",
            order_uuid: "5fdaad42-916d-4eee-b9bd-b233fe0115ef",
            price: 1.25386,
            price_sources: [],
            processed_ms: 1715075134330,
          },
          {
            leverage: 1.0,
            order_type: "LONG",
            order_uuid: "aa86a7df-037b-4b1b-a051-14a72b7a9747",
            price: 1.25333,
            price_sources: [],
            processed_ms: 1715076796392,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "3d07a35e-635a-4d90-a14d-53c8dd3cda01",
            price: 1.25403,
            price_sources: [],
            processed_ms: 1715082750431,
          },
        ],
        position_type: "FLAT",
        position_uuid: "ca040240-9ab3-49bb-a291-17c5b269a557",
        return_at_close: 1.0005537602284145,
        trade_pair: ["GBPUSD", "GBP/USD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 1.07651,
        close_ms: 1715088796661,
        current_return: 0.9984022442894168,
        initial_entry_price: 1.07651,
        is_closed_position: true,
        miner_hotkey: "5EUTaAo7vCGxvLDWRXRrEuqctPjt9fKZmgkaeFZocWECUe9X",
        net_leverage: 0.0,
        open_ms: 1715082420899,
        orders: [
          {
            leverage: -1.0,
            order_type: "SHORT",
            order_uuid: "d170f1e4-3f46-47c9-a381-e000190f7b63",
            price: 1.07651,
            price_sources: [],
            processed_ms: 1715082420893,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "cfe52d70-2c77-4d61-a54d-3736e2e67cdd",
            price: 1.07823,
            price_sources: [],
            processed_ms: 1715088796661,
          },
        ],
        position_type: "FLAT",
        position_uuid: "5d8ac5e6-3e4b-47b1-a3f7-6203882f0040",
        return_at_close: 0.9983323561323165,
        trade_pair: ["EURUSD", "EUR/USD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 1.0831100000000007,
        close_ms: 1715099181587,
        current_return: 0.996655443946687,
        initial_entry_price: 1.07817,
        is_closed_position: true,
        miner_hotkey: "5EUTaAo7vCGxvLDWRXRrEuqctPjt9fKZmgkaeFZocWECUe9X",
        net_leverage: 0.0,
        open_ms: 1715089041567,
        orders: [
          {
            leverage: 3.0,
            order_type: "LONG",
            order_uuid: "8f5c2ea2-bd04-45f4-89b1-8b5d947e043b",
            price: 1.07817,
            price_sources: [],
            processed_ms: 1715089041375,
          },
          {
            leverage: -2.9,
            order_type: "SHORT",
            order_uuid: "093c4000-95b4-4036-96f1-ae0b3dad7b6b",
            price: 1.07696,
            price_sources: [],
            processed_ms: 1715096621558,
          },
          {
            leverage: 0.5,
            order_type: "LONG",
            order_uuid: "4f77eaff-7815-4bf8-914e-a2bf4902901f",
            price: 1.07708,
            price_sources: [],
            processed_ms: 1715097897111,
          },
          {
            leverage: 0.5,
            order_type: "FLAT",
            order_uuid: "9dd58772-d813-49bd-b92d-599ffa29c4be",
            price: 1.0771,
            price_sources: [],
            processed_ms: 1715099181587,
          },
        ],
        position_type: "FLAT",
        position_uuid: "0137bc46-91da-40c9-9253-4a79734ca6fc",
        return_at_close: 0.9964112633629201,
        trade_pair: ["EURUSD", "EUR/USD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 1.2577454545454545,
        close_ms: 1715105057026,
        current_return: 0.9939770645854902,
        initial_entry_price: 1.2557,
        is_closed_position: true,
        miner_hotkey: "5EUTaAo7vCGxvLDWRXRrEuqctPjt9fKZmgkaeFZocWECUe9X",
        net_leverage: 0.0,
        open_ms: 1715087420864,
        orders: [
          {
            leverage: 1.0,
            order_type: "LONG",
            order_uuid: "15048e2a-3e49-438d-b28c-d33150ce3d60",
            price: 1.2557,
            price_sources: [],
            processed_ms: 1715087420858,
          },
          {
            leverage: 1.0,
            order_type: "LONG",
            order_uuid: "29c36c99-f72f-4e23-8a6a-1a88a99225a8",
            price: 1.25605,
            price_sources: [],
            processed_ms: 1715088918964,
          },
          {
            leverage: -1.9,
            order_type: "SHORT",
            order_uuid: "351c60d7-acc6-45a8-9efe-7cfcfd7f8439",
            price: 1.2535,
            price_sources: [],
            processed_ms: 1715096600702,
          },
          {
            leverage: 1.0,
            order_type: "LONG",
            order_uuid: "a5592ec6-415b-43f1-8706-dee26da81c5a",
            price: 1.25342,
            price_sources: [],
            processed_ms: 1715099466371,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "5c8bcbbb-d0d5-4a18-b077-ad59318320c0",
            price: 1.25087,
            price_sources: [],
            processed_ms: 1715105057026,
          },
        ],
        position_type: "FLAT",
        position_uuid: "3e9c3145-5596-487e-afbc-917796e09564",
        return_at_close: 0.9937683294019272,
        trade_pair: ["GBPUSD", "GBP/USD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 1.0770849999999998,
        close_ms: 1715116415448,
        current_return: 1.0032581453634084,
        initial_entry_price: 1.0773,
        is_closed_position: true,
        miner_hotkey: "5EUTaAo7vCGxvLDWRXRrEuqctPjt9fKZmgkaeFZocWECUe9X",
        net_leverage: 0.0,
        open_ms: 1715099189157,
        orders: [
          {
            leverage: -1.0,
            order_type: "SHORT",
            order_uuid: "7e3e775c-4c12-4339-912b-df37cf4c38c1",
            price: 1.0773,
            price_sources: [],
            processed_ms: 1715099188966,
          },
          {
            leverage: -1.0,
            order_type: "SHORT",
            order_uuid: "33387e0b-da6f-449f-af35-913e76b835c4",
            price: 1.07687,
            price_sources: [],
            processed_ms: 1715100610679,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "9b2802b2-6de6-4076-b851-beb5149e878f",
            price: 1.07533,
            price_sources: [],
            processed_ms: 1715116415448,
          },
        ],
        position_type: "FLAT",
        position_uuid: "6d40e377-755d-42ac-9565-ecc11485e7d5",
        return_at_close: 1.0031176892230576,
        trade_pair: ["EURUSD", "EUR/USD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 169.93974867084492,
        close_ms: 1715130029803,
        current_return: 0.99171520675803,
        initial_entry_price: 169.76,
        is_closed_position: true,
        miner_hotkey: "5EUTaAo7vCGxvLDWRXRrEuqctPjt9fKZmgkaeFZocWECUe9X",
        net_leverage: 0.0,
        open_ms: 1715000034352,
        orders: [
          {
            leverage: -1.0,
            order_type: "SHORT",
            order_uuid: "74dbebd7-3fc6-476b-b731-87ef092c3b5a",
            price: 169.76,
            price_sources: [],
            processed_ms: 1715000033933,
          },
          {
            leverage: -0.5,
            order_type: "SHORT",
            order_uuid: "ff4e5074-7d77-4ba6-82a1-0e0f2ee1b257",
            price: 169.8115140340094,
            price_sources: [],
            processed_ms: 1715004950795,
          },
          {
            leverage: -1.49,
            order_type: "SHORT",
            order_uuid: "8adc7fa5-dcc1-4b4c-b771-d6542ccc5129",
            price: 170.056,
            price_sources: [],
            processed_ms: 1715044698484,
          },
          {
            leverage: 2.49,
            order_type: "LONG",
            order_uuid: "9e122e4f-8231-47a1-9393-a8f9ce6bcfc0",
            price: 170.1972283632195,
            price_sources: [],
            processed_ms: 1715048193011,
          },
          {
            leverage: 0.49,
            order_type: "LONG",
            order_uuid: "9743c0c0-2bc9-45bd-b1a6-ae5a95bd2477",
            price: 170.233,
            price_sources: [],
            processed_ms: 1715049116104,
          },
          {
            leverage: -1.0,
            order_type: "SHORT",
            order_uuid: "8256a486-abc4-4864-a461-77747376ee6f",
            price: 170.197523283185,
            price_sources: [],
            processed_ms: 1715080075319,
          },
          {
            leverage: -1.0,
            order_type: "SHORT",
            order_uuid: "7b3333cf-c783-41de-9b61-58c4af501b3c",
            price: 170.4044343446751,
            price_sources: [],
            processed_ms: 1715084302568,
          },
          {
            leverage: -1.0,
            order_type: "SHORT",
            order_uuid: "1ffdcabf-71a0-4d9d-b361-05ba98d71a7f",
            price: 170.2700214789813,
            price_sources: [],
            processed_ms: 1715085926669,
          },
          {
            leverage: 3.0,
            order_type: "LONG",
            order_uuid: "3b8b20ff-a20f-4957-8ed0-41c04e2f0756",
            price: 170.3540728612362,
            price_sources: [],
            processed_ms: 1715088979230,
          },
          {
            leverage: -1.0,
            order_type: "SHORT",
            order_uuid: "be00cfd3-fbdd-4053-86f8-0ebbea02ea95",
            price: 170.3370326804071,
            price_sources: [],
            processed_ms: 1715089351134,
          },
          {
            leverage: -1.0,
            order_type: "SHORT",
            order_uuid: "55b64a8f-6a7f-49ca-9806-a7fe97a1c8b1",
            price: 170.280871868831,
            price_sources: [],
            processed_ms: 1715090097129,
          },
          {
            leverage: -1.0,
            order_type: "SHORT",
            order_uuid: "87244041-175d-462f-8f50-e9a76c568429",
            price: 170.2470500342841,
            price_sources: [],
            processed_ms: 1715092226882,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "a18fba89-8f2d-40b1-b1ff-11023a8dd480",
            price: 170.407,
            price_sources: [],
            processed_ms: 1715130029803,
          },
        ],
        position_type: "FLAT",
        position_uuid: "0bbeeb6f-e7a3-4bbf-ae32-e5886d6ae35a",
        return_at_close: 0.9910911203784172,
        trade_pair: ["CHFJPY", "CHF/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.8604,
        close_ms: 1715175172621,
        current_return: 1.00139470013947,
        initial_entry_price: 0.8604,
        is_closed_position: true,
        miner_hotkey: "5EUTaAo7vCGxvLDWRXRrEuqctPjt9fKZmgkaeFZocWECUe9X",
        net_leverage: 0.0,
        open_ms: 1715167720019,
        orders: [
          {
            leverage: 1.0,
            order_type: "LONG",
            order_uuid: "23446e5c-a8f5-4427-a117-7674971cd3e1",
            price: 0.8604,
            price_sources: [],
            processed_ms: 1715167719461,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "3dfb7d20-8e6e-41c4-97a1-b325d52bf06e",
            price: 0.8616,
            price_sources: [],
            processed_ms: 1715175172621,
          },
        ],
        position_type: "FLAT",
        position_uuid: "17cd1331-297f-4cfa-85f8-83d472303d61",
        return_at_close: 1.0013246025104603,
        trade_pair: ["EURGBP", "EUR/GBP", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 1.24735,
        close_ms: 1715189401328,
        current_return: 1.0043698934387455,
        initial_entry_price: 1.24717,
        is_closed_position: true,
        miner_hotkey: "5EUTaAo7vCGxvLDWRXRrEuqctPjt9fKZmgkaeFZocWECUe9X",
        net_leverage: 0.0,
        open_ms: 1715156375448,
        orders: [
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "ec8dbebf-cbd3-43e8-bcd3-718fcdc64e88",
            price: 1.24717,
            price_sources: [],
            processed_ms: 1715156375038,
          },
          {
            leverage: 1.0,
            order_type: "LONG",
            order_uuid: "ca09670a-9a0d-4ea7-a8b9-9e8e01b0df16",
            price: 1.24762,
            price_sources: [],
            processed_ms: 1715174898600,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "e79e14d1-0add-46d2-9cba-26c2c4fc58e5",
            price: 1.24953,
            price_sources: [],
            processed_ms: 1715189401328,
          },
        ],
        position_type: "FLAT",
        position_uuid: "7ddfca47-ccab-4105-bd03-ca200b13a7f0",
        return_at_close: 1.0041941287073937,
        trade_pair: ["GBPUSD", "GBP/USD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.65773,
        close_ms: 1715200559476,
        current_return: 0.9999391847718669,
        initial_entry_price: 0.65773,
        is_closed_position: true,
        miner_hotkey: "5EUTaAo7vCGxvLDWRXRrEuqctPjt9fKZmgkaeFZocWECUe9X",
        net_leverage: 0.0,
        open_ms: 1715188939771,
        orders: [
          {
            leverage: -1.0,
            order_type: "SHORT",
            order_uuid: "772321e9-c7e3-4568-9d76-c6b1e6bc1479",
            price: 0.65773,
            price_sources: [],
            processed_ms: 1715188939274,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "9b6a73f5-d857-4493-95ae-e27e8172067b",
            price: 0.65777,
            price_sources: [],
            processed_ms: 1715200559476,
          },
        ],
        position_type: "FLAT",
        position_uuid: "997624de-ed7c-437b-bdd5-a5bd3c4b6bbc",
        return_at_close: 0.9998691890289328,
        trade_pair: ["AUDUSD", "AUD/USD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 1.07458,
        close_ms: 1715244913211,
        current_return: 1.0016564611289993,
        initial_entry_price: 1.07458,
        is_closed_position: true,
        miner_hotkey: "5EUTaAo7vCGxvLDWRXRrEuqctPjt9fKZmgkaeFZocWECUe9X",
        net_leverage: 0.0,
        open_ms: 1715169348160,
        orders: [
          {
            leverage: -1.0,
            order_type: "SHORT",
            order_uuid: "b6c0a551-f105-4c19-813b-1117444a56e3",
            price: 1.07458,
            price_sources: [],
            processed_ms: 1715169347942,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "daca23a0-73fc-4bb1-8526-69561796b5a5",
            price: 1.0728,
            price_sources: [],
            processed_ms: 1715244913211,
          },
        ],
        position_type: "FLAT",
        position_uuid: "14ef068e-10c7-43fc-8ded-7c667e04d017",
        return_at_close: 1.0015863451767202,
        trade_pair: ["EURUSD", "EUR/USD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 1.07159,
        close_ms: 1715258439969,
        current_return: 1.0017699444796364,
        initial_entry_price: 1.07348,
        is_closed_position: true,
        miner_hotkey: "5EUTaAo7vCGxvLDWRXRrEuqctPjt9fKZmgkaeFZocWECUe9X",
        net_leverage: 0.0,
        open_ms: 1715257391041,
        orders: [
          {
            leverage: 1.0,
            order_type: "LONG",
            order_uuid: "b46baee5-a970-4e8a-b9d7-7e9f86180392",
            price: 1.07348,
            price_sources: [],
            processed_ms: 1715257388442,
          },
          {
            leverage: -0.5,
            order_type: "SHORT",
            order_uuid: "de109b8c-1160-452f-90a8-0dc581851497",
            price: 1.07537,
            price_sources: [],
            processed_ms: 1715258426118,
          },
          {
            leverage: 0.5,
            order_type: "FLAT",
            order_uuid: "bcc42804-b67a-4aac-9f55-2e4de2eb8ef5",
            price: 1.07539,
            price_sources: [],
            processed_ms: 1715258439969,
          },
        ],
        position_type: "FLAT",
        position_uuid: "76eafe32-6b05-49a9-bfbf-147fc181b02a",
        return_at_close: 1.0016998205835228,
        trade_pair: ["EURUSD", "EUR/USD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 1.24753,
        close_ms: 1715258463096,
        current_return: 1.0023165775572531,
        initial_entry_price: 1.24753,
        is_closed_position: true,
        miner_hotkey: "5EUTaAo7vCGxvLDWRXRrEuqctPjt9fKZmgkaeFZocWECUe9X",
        net_leverage: 0.0,
        open_ms: 1715257377433,
        orders: [
          {
            leverage: 1.0,
            order_type: "LONG",
            order_uuid: "89c7f21a-f703-4efa-a21c-185806fff296",
            price: 1.24753,
            price_sources: [],
            processed_ms: 1715257374669,
          },
          {
            leverage: 0.5,
            order_type: "FLAT",
            order_uuid: "93618b44-b87d-4692-a05e-3301c7996855",
            price: 1.25042,
            price_sources: [],
            processed_ms: 1715258463096,
          },
        ],
        position_type: "FLAT",
        position_uuid: "d994fbc9-9263-45f1-960d-fd4002cd7844",
        return_at_close: 1.002246415396824,
        trade_pair: ["GBPUSD", "GBP/USD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 1.25086,
        close_ms: 1715260091304,
        current_return: 0.9993404537678077,
        initial_entry_price: 1.25086,
        is_closed_position: true,
        miner_hotkey: "5EUTaAo7vCGxvLDWRXRrEuqctPjt9fKZmgkaeFZocWECUe9X",
        net_leverage: 0.0,
        open_ms: 1715258707527,
        orders: [
          {
            leverage: 0.5,
            order_type: "LONG",
            order_uuid: "2341ae39-c6f5-4b14-b141-0d027058a117",
            price: 1.25086,
            price_sources: [],
            processed_ms: 1715258707519,
          },
          {
            leverage: 0.5,
            order_type: "FLAT",
            order_uuid: "568bb079-bee7-447c-b7e2-7726837962c1",
            price: 1.24921,
            price_sources: [],
            processed_ms: 1715260091304,
          },
        ],
        position_type: "FLAT",
        position_uuid: "5f186d32-c927-4e5e-88ed-f2b4413d17a8",
        return_at_close: 0.9993054768519258,
        trade_pair: ["GBPUSD", "GBP/USD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 155.72275000000002,
        close_ms: 1715266482318,
        current_return: 1.001332451453817,
        initial_entry_price: 155.728,
        is_closed_position: true,
        miner_hotkey: "5EUTaAo7vCGxvLDWRXRrEuqctPjt9fKZmgkaeFZocWECUe9X",
        net_leverage: 0.0,
        open_ms: 1715262622683,
        orders: [
          {
            leverage: -1.5,
            order_type: "SHORT",
            order_uuid: "52a449e2-b352-44ce-906b-4923bb33ee7b",
            price: 155.728,
            price_sources: [],
            processed_ms: 1715262622445,
          },
          {
            leverage: -1.5,
            order_type: "SHORT",
            order_uuid: "0246ddf9-50a0-42e8-8e00-afb794c06ea8",
            price: 155.573,
            price_sources: [],
            processed_ms: 1715263009931,
          },
          {
            leverage: 1.0,
            order_type: "LONG",
            order_uuid: "b51b4c72-4ae7-4d63-b73d-5e6629fce48f",
            price: 155.506,
            price_sources: [],
            processed_ms: 1715264795773,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "d5167961-5159-41fb-ab5a-89a0a5d696c0",
            price: 155.619,
            price_sources: [],
            processed_ms: 1715266482318,
          },
        ],
        position_type: "FLAT",
        position_uuid: "3763c587-a428-4853-a4c0-44008cd6df59",
        return_at_close: 1.0011221716390117,
        trade_pair: ["USDJPY", "USD/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 155.657,
        close_ms: 1715281300382,
        current_return: 1.0011692374901227,
        initial_entry_price: 155.657,
        is_closed_position: true,
        miner_hotkey: "5EUTaAo7vCGxvLDWRXRrEuqctPjt9fKZmgkaeFZocWECUe9X",
        net_leverage: 0.0,
        open_ms: 1715272800549,
        orders: [
          {
            leverage: -1.0,
            order_type: "SHORT",
            order_uuid: "71a17e73-e3c8-4a68-9bff-4d2e39dc9dc8",
            price: 155.657,
            price_sources: [],
            processed_ms: 1715272798863,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "c9b25ebf-9aa3-472b-9128-f96ea0f070ef",
            price: 155.475,
            price_sources: [],
            processed_ms: 1715281300382,
          },
        ],
        position_type: "FLAT",
        position_uuid: "30efd88c-2798-4c90-b111-56c95c4f0651",
        return_at_close: 1.0010991556434983,
        trade_pair: ["USDJPY", "USD/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 61721.4,
        close_ms: 1715281711222,
        current_return: 1.0017196628721967,
        initial_entry_price: 61721.4,
        is_closed_position: true,
        miner_hotkey: "5EUTaAo7vCGxvLDWRXRrEuqctPjt9fKZmgkaeFZocWECUe9X",
        net_leverage: 0.0,
        open_ms: 1715267930901,
        orders: [
          {
            leverage: 0.2,
            order_type: "LONG",
            order_uuid: "eaf2ae11-5f1f-4e92-9f98-c1531ee8ccf0",
            price: 61721.4,
            price_sources: [],
            processed_ms: 1715267930711,
          },
          {
            leverage: -1.5,
            order_type: "SHORT",
            order_uuid: "1c6b3cc7-4a75-43f8-a5f3-bccaa6b67124",
            price: 62252.1,
            price_sources: [],
            processed_ms: 1715281711222,
          },
        ],
        position_type: "FLAT",
        position_uuid: "77aff08f-a0ef-47fb-8784-ea9a24dec9a5",
        return_at_close: 1.0015193189396223,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 155.613,
        close_ms: 1715286856356,
        current_return: 1.0005145389409502,
        initial_entry_price: 155.479,
        is_closed_position: true,
        miner_hotkey: "5EUTaAo7vCGxvLDWRXRrEuqctPjt9fKZmgkaeFZocWECUe9X",
        net_leverage: 0.0,
        open_ms: 1715281638298,
        orders: [
          {
            leverage: -1.5,
            order_type: "SHORT",
            order_uuid: "d1d4f6fd-ae86-44b3-8b1e-c1981aeb70b5",
            price: 155.479,
            price_sources: [],
            processed_ms: 1715281638099,
          },
          {
            leverage: 1.0,
            order_type: "LONG",
            order_uuid: "3f160922-2813-4f1b-b987-c03d72ce4a6a",
            price: 155.412,
            price_sources: [],
            processed_ms: 1715285433450,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "8b3f903a-4c08-4aee-b4a1-7b7fc73c2c2a",
            price: 155.453,
            price_sources: [],
            processed_ms: 1715286856356,
          },
        ],
        position_type: "FLAT",
        position_uuid: "46884d4f-9736-47ec-9456-ae4e61a868bf",
        return_at_close: 1.0004094849143614,
        trade_pair: ["USDJPY", "USD/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 62453.38,
        close_ms: 1715287073842,
        current_return: 1.0000091268078686,
        initial_entry_price: 62453.38,
        is_closed_position: true,
        miner_hotkey: "5EUTaAo7vCGxvLDWRXRrEuqctPjt9fKZmgkaeFZocWECUe9X",
        net_leverage: 0.0,
        open_ms: 1715286416535,
        orders: [
          {
            leverage: -1.0,
            order_type: "SHORT",
            order_uuid: "88093704-eec8-4753-919b-6ba1b9ffdea1",
            price: 62453.38,
            price_sources: [],
            processed_ms: 1715286416264,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "c1f31e78-5f46-4c53-9f03-3669863c62f3",
            price: 62452.81,
            price_sources: [],
            processed_ms: 1715287073842,
          },
        ],
        position_type: "FLAT",
        position_uuid: "9afc7f51-18f0-49de-8825-a203633394cd",
        return_at_close: 0.9990091176810607,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 1.252755,
        close_ms: 1715299401785,
        current_return: 0.9996687208634012,
        initial_entry_price: 1.25272,
        is_closed_position: true,
        miner_hotkey: "5EUTaAo7vCGxvLDWRXRrEuqctPjt9fKZmgkaeFZocWECUe9X",
        net_leverage: 0.0,
        open_ms: 1715292561087,
        orders: [
          {
            leverage: 0.5,
            order_type: "LONG",
            order_uuid: "927e8daa-4e65-46a0-ad19-206aa384d3b0",
            price: 1.25272,
            price_sources: [],
            processed_ms: 1715292560872,
          },
          {
            leverage: 0.5,
            order_type: "LONG",
            order_uuid: "fed5e3b4-8e6f-4ae8-8c84-12f3809aaf24",
            price: 1.25279,
            price_sources: [],
            processed_ms: 1715292611277,
          },
          {
            leverage: 0.5,
            order_type: "FLAT",
            order_uuid: "acf425f9-14f7-4200-915e-0060b2a72525",
            price: 1.25234,
            price_sources: [],
            processed_ms: 1715299401785,
          },
        ],
        position_type: "FLAT",
        position_uuid: "0388923e-86e6-4a1b-bded-a5cc3ea18e61",
        return_at_close: 0.9995987440529407,
        trade_pair: ["GBPUSD", "GBP/USD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 1.25274,
        close_ms: 1715340491326,
        current_return: 1.0000039912511773,
        initial_entry_price: 1.25274,
        is_closed_position: true,
        miner_hotkey: "5EUTaAo7vCGxvLDWRXRrEuqctPjt9fKZmgkaeFZocWECUe9X",
        net_leverage: 0.0,
        open_ms: 1715340452356,
        orders: [
          {
            leverage: -0.5,
            order_type: "SHORT",
            order_uuid: "1a081bb0-5e52-4fb6-b3f1-f39fd8f9e97a",
            price: 1.25274,
            price_sources: [],
            processed_ms: 1715340452068,
          },
          {
            leverage: 1.0,
            order_type: "LONG",
            order_uuid: "64afbde2-aa0d-4bb9-b1bf-26c54e1af1be",
            price: 1.25273,
            price_sources: [],
            processed_ms: 1715340491326,
          },
        ],
        position_type: "FLAT",
        position_uuid: "650d4d32-03c6-45f2-8a79-d4526d8b1534",
        return_at_close: 0.9999689911114835,
        trade_pair: ["GBPUSD", "GBP/USD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 1.07783,
        close_ms: 1715346113719,
        current_return: 1.0007515099783824,
        initial_entry_price: 1.07783,
        is_closed_position: true,
        miner_hotkey: "5EUTaAo7vCGxvLDWRXRrEuqctPjt9fKZmgkaeFZocWECUe9X",
        net_leverage: 0.0,
        open_ms: 1715336021631,
        orders: [
          {
            leverage: -1.0,
            order_type: "SHORT",
            order_uuid: "867db0c9-309e-4284-8692-a6f553e7e4e2",
            price: 1.07783,
            price_sources: [],
            processed_ms: 1715336021621,
          },
          {
            leverage: 0.5,
            order_type: "FLAT",
            order_uuid: "6210af4d-982c-4f58-a1fc-25474cd59ce3",
            price: 1.07702,
            price_sources: [],
            processed_ms: 1715346113719,
          },
        ],
        position_type: "FLAT",
        position_uuid: "d5411158-a374-4766-867a-b14aa41985cf",
        return_at_close: 1.0006814573726839,
        trade_pair: ["EURUSD", "EUR/USD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 1.25136,
        close_ms: 1715361585281,
        current_return: 1.0015571970453183,
        initial_entry_price: 1.25225,
        is_closed_position: true,
        miner_hotkey: "5EUTaAo7vCGxvLDWRXRrEuqctPjt9fKZmgkaeFZocWECUe9X",
        net_leverage: 0.0,
        open_ms: 1715346497153,
        orders: [
          {
            leverage: 0.5,
            order_type: "LONG",
            order_uuid: "85592cdc-00be-4742-b13c-50b0c9e7c40a",
            price: 1.25225,
            price_sources: [],
            processed_ms: 1715346496648,
          },
          {
            leverage: 0.5,
            order_type: "LONG",
            order_uuid: "e56c0923-1fa3-424f-aef0-1251fda1652f",
            price: 1.25047,
            price_sources: [],
            processed_ms: 1715353721605,
          },
          {
            leverage: 0.5,
            order_type: "FLAT",
            order_uuid: "93b9ddf6-26e4-42ff-b9b1-cd8c6d80d1cd",
            price: 1.25331,
            price_sources: [],
            processed_ms: 1715361585281,
          },
        ],
        position_type: "FLAT",
        position_uuid: "368f4282-5997-46e4-9412-d72d4b020ba9",
        return_at_close: 1.0014870880415252,
        trade_pair: ["GBPUSD", "GBP/USD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 1.0782466666666668,
        close_ms: 1715606123935,
        current_return: 0.9959659096178284,
        initial_entry_price: 1.07831,
        is_closed_position: true,
        miner_hotkey: "5EUTaAo7vCGxvLDWRXRrEuqctPjt9fKZmgkaeFZocWECUe9X",
        net_leverage: 0.0,
        open_ms: 1715593758738,
        orders: [
          {
            leverage: -1.0,
            order_type: "SHORT",
            order_uuid: "33c5d1fa-c1f1-4f4f-a452-b89b21ce1080",
            price: 1.07831,
            price_sources: [],
            processed_ms: 1715593757627,
          },
          {
            leverage: -0.5,
            order_type: "SHORT",
            order_uuid: "23cd09dc-066d-4026-8f55-3440f081820d",
            price: 1.07829,
            price_sources: [],
            processed_ms: 1715595754530,
          },
          {
            leverage: -0.25,
            order_type: "SHORT",
            order_uuid: "3827490c-f7a6-4779-a322-a7fc82a02beb",
            price: 1.07799,
            price_sources: [],
            processed_ms: 1715596687170,
          },
          {
            leverage: -0.25,
            order_type: "SHORT",
            order_uuid: "15c91de0-9f86-4b7c-aa58-f8bd3b039420",
            price: 1.07811,
            price_sources: [],
            processed_ms: 1715597908146,
          },
          {
            leverage: -0.25,
            order_type: "SHORT",
            order_uuid: "70dc9afe-476a-4485-a4ed-9ac02ed2482e",
            price: 1.0783,
            price_sources: [],
            processed_ms: 1715598947706,
          },
          {
            leverage: 0.25,
            order_type: "FLAT",
            order_uuid: "a7a7f515-ef08-4480-8683-709a6e03e1d8",
            price: 1.08018,
            price_sources: [],
            processed_ms: 1715606123935,
          },
        ],
        position_type: "FLAT",
        position_uuid: "bc37333c-8329-4d45-9232-5db4fa5149df",
        return_at_close: 0.9958090449870636,
        trade_pair: ["EURUSD", "EUR/USD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 1.25557,
        close_ms: 1715613080472,
        current_return: 1.000039822550714,
        initial_entry_price: 1.25557,
        is_closed_position: true,
        miner_hotkey: "5EUTaAo7vCGxvLDWRXRrEuqctPjt9fKZmgkaeFZocWECUe9X",
        net_leverage: 0.0,
        open_ms: 1715609613309,
        orders: [
          {
            leverage: -0.5,
            order_type: "SHORT",
            order_uuid: "15543272-2f59-4478-964f-8e9e655b020c",
            price: 1.25557,
            price_sources: [],
            processed_ms: 1715609612454,
          },
          {
            leverage: 0.5,
            order_type: "FLAT",
            order_uuid: "11b8bbbb-410d-40f8-ac8c-30125e1159c4",
            price: 1.25547,
            price_sources: [],
            processed_ms: 1715613080472,
          },
        ],
        position_type: "FLAT",
        position_uuid: "383125ce-5756-4caa-b29e-301a4c6bc0bf",
        return_at_close: 1.0000048211569248,
        trade_pair: ["GBPUSD", "GBP/USD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.82337,
        close_ms: 1715615800889,
        current_return: 0.9996902971932424,
        initial_entry_price: 0.82337,
        is_closed_position: true,
        miner_hotkey: "5EUTaAo7vCGxvLDWRXRrEuqctPjt9fKZmgkaeFZocWECUe9X",
        net_leverage: 0.0,
        open_ms: 1715609850593,
        orders: [
          {
            leverage: 0.5,
            order_type: "LONG",
            order_uuid: "3266f47e-01f5-4002-98ee-500882d53177",
            price: 0.82337,
            price_sources: [],
            processed_ms: 1715609849804,
          },
          {
            leverage: 0.5,
            order_type: "FLAT",
            order_uuid: "549a2094-542b-472a-b175-7ffc98033cf3",
            price: 0.82286,
            price_sources: [],
            processed_ms: 1715615800889,
          },
        ],
        position_type: "FLAT",
        position_uuid: "f4cd9541-9304-499d-88eb-f34c5d8819ca",
        return_at_close: 0.9996553080328406,
        trade_pair: ["NZDCAD", "NZD/CAD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 1.0798,
        close_ms: 1715622067610,
        current_return: 1.0005556584552697,
        initial_entry_price: 1.0798,
        is_closed_position: true,
        miner_hotkey: "5EUTaAo7vCGxvLDWRXRrEuqctPjt9fKZmgkaeFZocWECUe9X",
        net_leverage: 0.0,
        open_ms: 1715616755021,
        orders: [
          {
            leverage: -1.0,
            order_type: "SHORT",
            order_uuid: "259a5203-b720-4eea-b194-1c8ba241f438",
            price: 1.0798,
            price_sources: [],
            processed_ms: 1715616754248,
          },
          {
            leverage: 0.25,
            order_type: "FLAT",
            order_uuid: "1929061c-8016-4a05-887c-0dbc17e101a5",
            price: 1.0792,
            price_sources: [],
            processed_ms: 1715622067610,
          },
        ],
        position_type: "FLAT",
        position_uuid: "b404628d-cd47-4602-a635-b59b3c55b2c5",
        return_at_close: 1.0004856195591778,
        trade_pair: ["EURUSD", "EUR/USD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 1.25569,
        close_ms: 1715624135047,
        current_return: 1.0001194562352174,
        initial_entry_price: 1.25569,
        is_closed_position: true,
        miner_hotkey: "5EUTaAo7vCGxvLDWRXRrEuqctPjt9fKZmgkaeFZocWECUe9X",
        net_leverage: 0.0,
        open_ms: 1715622119731,
        orders: [
          {
            leverage: -1.5,
            order_type: "SHORT",
            order_uuid: "49f6f830-6b21-480d-ad8e-ce8ded4183c1",
            price: 1.25569,
            price_sources: [],
            processed_ms: 1715622118909,
          },
          {
            leverage: 0.25,
            order_type: "FLAT",
            order_uuid: "0febc6bb-4912-4415-b596-b40830911079",
            price: 1.25559,
            price_sources: [],
            processed_ms: 1715624135047,
          },
        ],
        position_type: "FLAT",
        position_uuid: "83286ebc-4f20-4a10-8972-f67de6e4383e",
        return_at_close: 1.0000144436923126,
        trade_pair: ["GBPUSD", "GBP/USD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.6629342857142857,
        close_ms: 1715624461125,
        current_return: 1.0017580925073568,
        initial_entry_price: 0.66265,
        is_closed_position: true,
        miner_hotkey: "5EUTaAo7vCGxvLDWRXRrEuqctPjt9fKZmgkaeFZocWECUe9X",
        net_leverage: 0.0,
        open_ms: 1715582975243,
        orders: [
          {
            leverage: 1.0,
            order_type: "LONG",
            order_uuid: "f2eb00a0-0e27-4483-ae3c-85938f465498",
            price: 0.66265,
            price_sources: [],
            processed_ms: 1715582974532,
          },
          {
            leverage: 0.5,
            order_type: "LONG",
            order_uuid: "087a411e-4bce-4b4b-93ef-d2157392e344",
            price: 0.66313,
            price_sources: [],
            processed_ms: 1715593611978,
          },
          {
            leverage: -0.25,
            order_type: "SHORT",
            order_uuid: "51316ca4-0577-4c24-a318-ba0204369fed",
            price: 0.66352,
            price_sources: [],
            processed_ms: 1715617997118,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "f7c91307-b7cf-4b34-b532-46edf2349d42",
            price: 0.6635,
            price_sources: [],
            processed_ms: 1715622714892,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "5d2d73d3-0311-4864-b287-972d97ec49a1",
            price: 0.6637,
            price_sources: [],
            processed_ms: 1715623806432,
          },
          {
            leverage: 0.25,
            order_type: "FLAT",
            order_uuid: "6e3b4bac-64b2-4072-97d7-e8746b47efef",
            price: 0.6636,
            price_sources: [],
            processed_ms: 1715624461125,
          },
        ],
        position_type: "FLAT",
        position_uuid: "397b95a7-a71e-4c89-a14b-307bf22b71bb",
        return_at_close: 1.0016178463744057,
        trade_pair: ["CADCHF", "CAD/CHF", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 1.2547,
        close_ms: 1715675753993,
        current_return: 1.0022395791822747,
        initial_entry_price: 1.2547,
        is_closed_position: true,
        miner_hotkey: "5EUTaAo7vCGxvLDWRXRrEuqctPjt9fKZmgkaeFZocWECUe9X",
        net_leverage: 0.0,
        open_ms: 1715671944453,
        orders: [
          {
            leverage: -1.0,
            order_type: "SHORT",
            order_uuid: "c89b06b9-e63c-4c91-8d86-bc04a9c10186",
            price: 1.2547,
            price_sources: [],
            processed_ms: 1715671944184,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "538830bf-bace-43d9-a9b4-c339b573a6a7",
            price: 1.25189,
            price_sources: [],
            processed_ms: 1715675753993,
          },
        ],
        position_type: "FLAT",
        position_uuid: "38df36d0-82c9-44c7-ac6f-b1ac437fcbdb",
        return_at_close: 1.002169422411732,
        trade_pair: ["GBPUSD", "GBP/USD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 1.25537,
        close_ms: 1715696641944,
        current_return: 1.0008005607908426,
        initial_entry_price: 1.25537,
        is_closed_position: true,
        miner_hotkey: "5EUTaAo7vCGxvLDWRXRrEuqctPjt9fKZmgkaeFZocWECUe9X",
        net_leverage: 0.0,
        open_ms: 1715691597975,
        orders: [
          {
            leverage: 0.5,
            order_type: "LONG",
            order_uuid: "de904f02-6c02-4919-b34f-e7e8fa04b687",
            price: 1.25537,
            price_sources: [],
            processed_ms: 1715691597869,
          },
          {
            leverage: 0.1,
            order_type: "FLAT",
            order_uuid: "be881071-d459-4cff-b7dd-479ea1d6cbf8",
            price: 1.25738,
            price_sources: [],
            processed_ms: 1715696641944,
          },
        ],
        position_type: "FLAT",
        position_uuid: "c5c6bc36-33b7-4d97-8a5d-51beab8b0fdc",
        return_at_close: 1.0007655327712148,
        trade_pair: ["GBPUSD", "GBP/USD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 5223.7300000000005,
        close_ms: 1715710764203,
        current_return: 1.0004215378666201,
        initial_entry_price: 5223.7300000000005,
        is_closed_position: true,
        miner_hotkey: "5EUTaAo7vCGxvLDWRXRrEuqctPjt9fKZmgkaeFZocWECUe9X",
        net_leverage: 0.0,
        open_ms: 1715709417738,
        orders: [
          {
            leverage: 0.2,
            order_type: "LONG",
            order_uuid: "5b0f3a74-117a-49a7-b831-0c4e99f4d2d1",
            price: 5223.7300000000005,
            price_sources: [],
            processed_ms: 1715709417085,
          },
          {
            leverage: 0.2,
            order_type: "FLAT",
            order_uuid: "31e4bc87-1a50-4d68-b0c3-e277d840992e",
            price: 5234.74,
            price_sources: [],
            processed_ms: 1715710764203,
          },
        ],
        position_type: "FLAT",
        position_uuid: "f7189040-8823-4a8e-8fc9-446b18214107",
        return_at_close: 1.0004035302789385,
        trade_pair: ["SPX", "SPX", 9e-5, 0.001, 500],
      },
      {
        average_entry_price: 1.0822975000000001,
        close_ms: 1715774842666,
        current_return: 0.9979069251667996,
        initial_entry_price: 1.08214,
        is_closed_position: true,
        miner_hotkey: "5EUTaAo7vCGxvLDWRXRrEuqctPjt9fKZmgkaeFZocWECUe9X",
        net_leverage: 0.0,
        open_ms: 1715749251849,
        orders: [
          {
            leverage: -1.0,
            order_type: "SHORT",
            order_uuid: "643d3ea1-5f63-47a9-b0a7-9f09d2c8e399",
            price: 1.08214,
            price_sources: [],
            processed_ms: 1715749250087,
          },
          {
            leverage: -0.25,
            order_type: "SHORT",
            order_uuid: "5be0538d-a3ab-4a51-8967-2545ed18d023",
            price: 1.08235,
            price_sources: [],
            processed_ms: 1715771463732,
          },
          {
            leverage: -0.25,
            order_type: "SHORT",
            order_uuid: "56476123-bf63-42ac-b519-7d00950b6ddd",
            price: 1.08268,
            price_sources: [],
            processed_ms: 1715771784244,
          },
          {
            leverage: -0.25,
            order_type: "SHORT",
            order_uuid: "b080d810-b657-48ea-9c48-93b4d4f4a9bd",
            price: 1.0822,
            price_sources: [],
            processed_ms: 1715772290581,
          },
          {
            leverage: -0.25,
            order_type: "SHORT",
            order_uuid: "4a39f7ed-25dc-4ebe-8da4-94f5d8f2ae05",
            price: 1.08259,
            price_sources: [],
            processed_ms: 1715772784520,
          },
          {
            leverage: 0.25,
            order_type: "FLAT",
            order_uuid: "3c9c999d-d35b-4024-b295-56221e0736ad",
            price: 1.08343,
            price_sources: [],
            processed_ms: 1715774842666,
          },
        ],
        position_type: "FLAT",
        position_uuid: "7ed89d59-20a1-45b7-b615-baaab84a5564",
        return_at_close: 0.9977672181972762,
        trade_pair: ["EURUSD", "EUR/USD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 103.53,
        close_ms: 1715774976167,
        current_return: 0.9974886506326668,
        initial_entry_price: 103.53,
        is_closed_position: true,
        miner_hotkey: "5EUTaAo7vCGxvLDWRXRrEuqctPjt9fKZmgkaeFZocWECUe9X",
        net_leverage: 0.0,
        open_ms: 1715762097841,
        orders: [
          {
            leverage: 1.0,
            order_type: "LONG",
            order_uuid: "be7bf348-3b72-42c9-bde3-3bab085b1929",
            price: 103.53,
            price_sources: [],
            processed_ms: 1715762097190,
          },
          {
            leverage: 0.25,
            order_type: "FLAT",
            order_uuid: "7ab9abbe-872f-4dfd-9fad-4df2b8fa622a",
            price: 103.27,
            price_sources: [],
            processed_ms: 1715774976167,
          },
        ],
        position_type: "FLAT",
        position_uuid: "8af6fc37-34fa-41f3-b5db-04872e7dc521",
        return_at_close: 0.9974188264271224,
        trade_pair: ["AUDJPY", "AUD/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 1.0861666666666665,
        close_ms: 1715776745954,
        current_return: 1.001334437695564,
        initial_entry_price: 1.0866,
        is_closed_position: true,
        miner_hotkey: "5EUTaAo7vCGxvLDWRXRrEuqctPjt9fKZmgkaeFZocWECUe9X",
        net_leverage: 0.0,
        open_ms: 1715776310178,
        orders: [
          {
            leverage: -0.5,
            order_type: "SHORT",
            order_uuid: "86092725-2426-4bb6-90db-17e79caab5e9",
            price: 1.0866,
            price_sources: [],
            processed_ms: 1715776308910,
          },
          {
            leverage: -1.0,
            order_type: "SHORT",
            order_uuid: "2c797911-f729-492d-ae0e-18ba4e5af365",
            price: 1.08595,
            price_sources: [],
            processed_ms: 1715776473296,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "8a582ccf-c52c-48d1-ab0a-5811baccb5c5",
            price: 1.0852,
            price_sources: [],
            processed_ms: 1715776745954,
          },
        ],
        position_type: "FLAT",
        position_uuid: "ccab66c7-99bc-44e2-b8eb-5bbff5cf743a",
        return_at_close: 1.001229297579606,
        trade_pair: ["EURUSD", "EUR/USD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.9030066666666666,
        close_ms: 1715782219588,
        current_return: 1.0037599397523647,
        initial_entry_price: 0.90294,
        is_closed_position: true,
        miner_hotkey: "5EUTaAo7vCGxvLDWRXRrEuqctPjt9fKZmgkaeFZocWECUe9X",
        net_leverage: 0.0,
        open_ms: 1715777204041,
        orders: [
          {
            leverage: 1.0,
            order_type: "LONG",
            order_uuid: "e45ee7a1-7995-46d9-aba1-0ec5debcb765",
            price: 0.90294,
            price_sources: [],
            processed_ms: 1715777203061,
          },
          {
            leverage: 0.5,
            order_type: "LONG",
            order_uuid: "be7052d1-93c6-49a4-8cc5-f8a2aa6c46e4",
            price: 0.90314,
            price_sources: [],
            processed_ms: 1715778490664,
          },
          {
            leverage: 1.45,
            order_type: "FLAT",
            order_uuid: "1abb1241-0298-4821-8c4e-f1f059ea596e",
            price: 0.90527,
            price_sources: [],
            processed_ms: 1715782219588,
          },
        ],
        position_type: "FLAT",
        position_uuid: "afd34b7f-15aa-4486-bd71-e624ce642658",
        return_at_close: 1.0036545449586907,
        trade_pair: ["USDCHF", "USD/CHF", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 1.09497,
        close_ms: 1715782234772,
        current_return: 1.0008356393325843,
        initial_entry_price: 1.09497,
        is_closed_position: true,
        miner_hotkey: "5EUTaAo7vCGxvLDWRXRrEuqctPjt9fKZmgkaeFZocWECUe9X",
        net_leverage: 0.0,
        open_ms: 1715778058763,
        orders: [
          {
            leverage: -1.5,
            order_type: "SHORT",
            order_uuid: "e8d1bd3b-acf1-40f8-b79c-f579230b3756",
            price: 1.09497,
            price_sources: [],
            processed_ms: 1715778058525,
          },
          {
            leverage: 1.45,
            order_type: "FLAT",
            order_uuid: "8993a366-ce47-4cb4-9ebb-a6aad1bf1273",
            price: 1.09436,
            price_sources: [],
            processed_ms: 1715782234772,
          },
        ],
        position_type: "FLAT",
        position_uuid: "fe20d1d9-825c-4f70-97ff-926091848dfa",
        return_at_close: 1.0007305515904543,
        trade_pair: ["AUDNZD", "AUD/NZD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 2975.91,
        close_ms: 1715783258110,
        current_return: 1.0005746141516376,
        initial_entry_price: 2975.91,
        is_closed_position: true,
        miner_hotkey: "5EUTaAo7vCGxvLDWRXRrEuqctPjt9fKZmgkaeFZocWECUe9X",
        net_leverage: 0.0,
        open_ms: 1715780257341,
        orders: [
          {
            leverage: -0.15,
            order_type: "SHORT",
            order_uuid: "c10a8ac8-66c2-4948-98f7-6ef393947c78",
            price: 2975.91,
            price_sources: [],
            processed_ms: 1715780256368,
          },
          {
            leverage: 1.45,
            order_type: "FLAT",
            order_uuid: "7352745c-cb6e-4f41-a8e3-17e294707f85",
            price: 2964.51,
            price_sources: [],
            processed_ms: 1715783258110,
          },
        ],
        position_type: "FLAT",
        position_uuid: "06e0b4f4-27d4-4821-9247-bd97ddbc9196",
        return_at_close: 1.000424527959515,
        trade_pair: ["ETHUSD", "ETH/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 5271.83,
        close_ms: 1715784651184,
        current_return: 0.997811006804089,
        initial_entry_price: 5271.83,
        is_closed_position: true,
        miner_hotkey: "5EUTaAo7vCGxvLDWRXRrEuqctPjt9fKZmgkaeFZocWECUe9X",
        net_leverage: 0.0,
        open_ms: 1715780938205,
        orders: [
          {
            leverage: -1.0,
            order_type: "SHORT",
            order_uuid: "0f7883a3-e3f7-4992-a130-9c9346d5e137",
            price: 5271.83,
            price_sources: [],
            processed_ms: 1715780937033,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "893cd230-da52-4cfd-a919-a97d380f4d97",
            price: 5283.37,
            price_sources: [],
            processed_ms: 1715784651184,
          },
        ],
        position_type: "FLAT",
        position_uuid: "624effb7-06cd-4ab6-8a40-0b0cf3f67e10",
        return_at_close: 0.9977212038134765,
        trade_pair: ["SPX", "SPX", 9e-5, 0.001, 500],
      },
      {
        average_entry_price: 16.823697812500004,
        close_ms: 1715785955746,
        current_return: 0.9874561685126719,
        initial_entry_price: 16.8201,
        is_closed_position: true,
        miner_hotkey: "5EUTaAo7vCGxvLDWRXRrEuqctPjt9fKZmgkaeFZocWECUe9X",
        net_leverage: 0.0,
        open_ms: 1715776411728,
        orders: [
          {
            leverage: 0.5,
            order_type: "LONG",
            order_uuid: "d9f87f26-e38b-4773-985d-d40eb72a2b0c",
            price: 16.8201,
            price_sources: [],
            processed_ms: 1715776411042,
          },
          {
            leverage: 1.0,
            order_type: "LONG",
            order_uuid: "5248b72f-a8b4-48b0-b238-e3022395f924",
            price: 16.84625,
            price_sources: [],
            processed_ms: 1715776534265,
          },
          {
            leverage: -1.45,
            order_type: "SHORT",
            order_uuid: "f879eb1f-6709-42a1-85d7-1fb82a206806",
            price: 16.8001,
            price_sources: [],
            processed_ms: 1715778771272,
          },
          {
            leverage: 1.45,
            order_type: "LONG",
            order_uuid: "ef30a08c-5929-4235-9f4b-d8065f01352f",
            price: 16.78765,
            price_sources: [],
            processed_ms: 1715778927659,
          },
          {
            leverage: -1.45,
            order_type: "SHORT",
            order_uuid: "62be0f14-361b-43b2-8993-4169e23857ed",
            price: 16.77457,
            price_sources: [],
            processed_ms: 1715779656227,
          },
          {
            leverage: 1.0,
            order_type: "LONG",
            order_uuid: "8e6b927f-9fd4-4fe7-919d-48acbd739b9a",
            price: 16.79185,
            price_sources: [],
            processed_ms: 1715780993043,
          },
          {
            leverage: 0.5,
            order_type: "LONG",
            order_uuid: "16412ad3-efd8-4298-8b40-726ec91765f5",
            price: 16.78971,
            price_sources: [],
            processed_ms: 1715781187164,
          },
          {
            leverage: 0.5,
            order_type: "LONG",
            order_uuid: "c0e280e7-02fc-44b8-a8d0-245cf94280d3",
            price: 16.78305,
            price_sources: [],
            processed_ms: 1715781659575,
          },
          {
            leverage: -1.45,
            order_type: "SHORT",
            order_uuid: "56c1787d-8766-446e-903c-8b4214fbd129",
            price: 16.75861,
            price_sources: [],
            processed_ms: 1715782179451,
          },
          {
            leverage: 1.0,
            order_type: "LONG",
            order_uuid: "91f77a5c-5164-4cd4-8cb1-452923806ac5",
            price: 16.72455,
            price_sources: [],
            processed_ms: 1715784565908,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "769fd92b-90aa-4cca-bb8e-5ff89c67ff29",
            price: 16.69183,
            price_sources: [],
            processed_ms: 1715785955746,
          },
        ],
        position_type: "FLAT",
        position_uuid: "04326fb9-9b76-4a0f-bc0a-2bb37eb6e8bb",
        return_at_close: 0.9870448930184864,
        trade_pair: ["USDMXN", "USD/MXN", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 196.2411666666667,
        close_ms: 1715796409966,
        current_return: 1.0006712554897743,
        initial_entry_price: 196.274,
        is_closed_position: true,
        miner_hotkey: "5EUTaAo7vCGxvLDWRXRrEuqctPjt9fKZmgkaeFZocWECUe9X",
        net_leverage: 0.0,
        open_ms: 1715771630250,
        orders: [
          {
            leverage: 0.5,
            order_type: "LONG",
            order_uuid: "66c77eb7-ad2b-4c52-aab1-3ef8baf7a7a7",
            price: 196.274,
            price_sources: [],
            processed_ms: 1715771629824,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "55284452-7b7b-4cd7-8cd3-9ab73b20f609",
            price: 196.268,
            price_sources: [],
            processed_ms: 1715772751316,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "84812afb-5fdb-4bb8-9dc7-5c3e285203d8",
            price: 196.327,
            price_sources: [],
            processed_ms: 1715773023221,
          },
          {
            leverage: 0.5,
            order_type: "LONG",
            order_uuid: "a27a7b69-0ef1-4356-92e2-b0d867d8199d",
            price: 196.152,
            price_sources: [],
            processed_ms: 1715787452744,
          },
          {
            leverage: 0.5,
            order_type: "FLAT",
            order_uuid: "2a617c81-b0ef-479a-8eb4-8255b727677f",
            price: 196.329,
            price_sources: [],
            processed_ms: 1715796409966,
          },
        ],
        position_type: "FLAT",
        position_uuid: "75da363a-af55-4ed1-b0f1-7735b951510c",
        return_at_close: 1.000566185007948,
        trade_pair: ["GBPJPY", "GBP/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 16.695234666666668,
        close_ms: 1715799660260,
        current_return: 0.998295701038069,
        initial_entry_price: 16.68868,
        is_closed_position: true,
        miner_hotkey: "5EUTaAo7vCGxvLDWRXRrEuqctPjt9fKZmgkaeFZocWECUe9X",
        net_leverage: 0.0,
        open_ms: 1715797595038,
        orders: [
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "18c3d37e-1b28-46fd-a32e-ec1cf8693f71",
            price: 16.68868,
            price_sources: [],
            processed_ms: 1715797594180,
          },
          {
            leverage: 0.5,
            order_type: "LONG",
            order_uuid: "ecea0407-ca34-4bac-bbf8-00bea3f02b28",
            price: 16.6945,
            price_sources: [],
            processed_ms: 1715797895535,
          },
          {
            leverage: 0.5,
            order_type: "LONG",
            order_uuid: "04a81d63-e536-4b2a-9117-0050ff8504a8",
            price: 16.7012,
            price_sources: [],
            processed_ms: 1715798490204,
          },
          {
            leverage: 0.5,
            order_type: "LONG",
            order_uuid: "4f85926a-7504-4a06-8a14-e83efe4e2540",
            price: 16.70662,
            price_sources: [],
            processed_ms: 1715798867926,
          },
          {
            leverage: 2.0,
            order_type: "LONG",
            order_uuid: "70ffef9f-8dcb-44c6-a9f9-6082f254421c",
            price: 16.6919,
            price_sources: [],
            processed_ms: 1715799464218,
          },
          {
            leverage: 2.0,
            order_type: "FLAT",
            order_uuid: "42573be4-4355-4b44-b638-05ede73b21c5",
            price: 16.68765,
            price_sources: [],
            processed_ms: 1715799660260,
          },
        ],
        position_type: "FLAT",
        position_uuid: "99a12382-89f3-4153-a57c-928662a1df80",
        return_at_close: 0.9980336484165464,
        trade_pair: ["USDMXN", "USD/MXN", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 1.0882500000000002,
        close_ms: 1715848750348,
        current_return: 1.000638704578455,
        initial_entry_price: 1.08814,
        is_closed_position: true,
        miner_hotkey: "5EUTaAo7vCGxvLDWRXRrEuqctPjt9fKZmgkaeFZocWECUe9X",
        net_leverage: 0.0,
        open_ms: 1715836835768,
        orders: [
          {
            leverage: -1.0,
            order_type: "SHORT",
            order_uuid: "fb340f5b-0993-49ca-a692-a8cec7ed1d80",
            price: 1.08814,
            price_sources: [],
            processed_ms: 1715836834672,
          },
          {
            leverage: 0.5,
            order_type: "LONG",
            order_uuid: "1427936a-3d94-4ab5-90ff-b72ded513f4e",
            price: 1.08803,
            price_sources: [],
            processed_ms: 1715844534188,
          },
          {
            leverage: 1.5,
            order_type: "FLAT",
            order_uuid: "1d619005-204d-4916-bbd2-af4084655513",
            price: 1.08686,
            price_sources: [],
            processed_ms: 1715848750348,
          },
        ],
        position_type: "FLAT",
        position_uuid: "77a1d260-de81-456c-b501-081bf26ee303",
        return_at_close: 1.0005686598691346,
        trade_pair: ["EURUSD", "EUR/USD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 171.590303849567,
        close_ms: 1715863718336,
        current_return: 0.9987796535715713,
        initial_entry_price: 171.601,
        is_closed_position: true,
        miner_hotkey: "5EUTaAo7vCGxvLDWRXRrEuqctPjt9fKZmgkaeFZocWECUe9X",
        net_leverage: 0.0,
        open_ms: 1715848158915,
        orders: [
          {
            leverage: -1.0,
            order_type: "SHORT",
            order_uuid: "be472fba-7e79-42e1-aeb8-12c5492424b4",
            price: 171.601,
            price_sources: [],
            processed_ms: 1715848157249,
          },
          {
            leverage: -1.0,
            order_type: "SHORT",
            order_uuid: "1579e981-29a3-406d-b123-429da54dfacb",
            price: 171.579607699134,
            price_sources: [],
            processed_ms: 1715848180708,
          },
          {
            leverage: 1.5,
            order_type: "FLAT",
            order_uuid: "34092fb0-5d63-4885-9a86-0bbe8b174b7c",
            price: 171.6950101832994,
            price_sources: [],
            processed_ms: 1715863718336,
          },
        ],
        position_type: "FLAT",
        position_uuid: "1f55477f-d8b1-4c2b-b84f-2f82ff75d4a7",
        return_at_close: 0.9986398244200713,
        trade_pair: ["CHFJPY", "CHF/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 1.266,
        close_ms: 1715892429629,
        current_return: 1.001090047393365,
        initial_entry_price: 1.266,
        is_closed_position: true,
        miner_hotkey: "5EUTaAo7vCGxvLDWRXRrEuqctPjt9fKZmgkaeFZocWECUe9X",
        net_leverage: 0.0,
        open_ms: 1715863837757,
        orders: [
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "b759b5fd-2269-4f1b-bfef-ed08b35a2a58",
            price: 1.266,
            price_sources: [],
            processed_ms: 1715863834894,
          },
          {
            leverage: 1.5,
            order_type: "FLAT",
            order_uuid: "7ed58d7a-2877-4fb2-be6a-589bed197b56",
            price: 1.26692,
            price_sources: [],
            processed_ms: 1715892429629,
          },
        ],
        position_type: "FLAT",
        position_uuid: "aeb604fd-83d7-4ee3-b565-c5398a103540",
        return_at_close: 1.0009849329383886,
        trade_pair: ["GBPUSD", "GBP/USD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 1.08507,
        close_ms: 1715945574278,
        current_return: 1.0012718073488347,
        initial_entry_price: 1.08507,
        is_closed_position: true,
        miner_hotkey: "5EUTaAo7vCGxvLDWRXRrEuqctPjt9fKZmgkaeFZocWECUe9X",
        net_leverage: 0.0,
        open_ms: 1715934526376,
        orders: [
          {
            leverage: -1.5,
            order_type: "SHORT",
            order_uuid: "f1834a92-1c03-43e3-9a76-769bf045327c",
            price: 1.08507,
            price_sources: [],
            processed_ms: 1715934523607,
          },
          {
            leverage: 1.5,
            order_type: "FLAT",
            order_uuid: "03dba9fb-fb55-498f-bcbd-4d7c3641fee0",
            price: 1.08415,
            price_sources: [],
            processed_ms: 1715945574278,
          },
        ],
        position_type: "FLAT",
        position_uuid: "3e1585de-ac9d-4ecf-9988-afe371c87a25",
        return_at_close: 1.001166673809063,
        trade_pair: ["EURUSD", "EUR/USD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 5300.54,
        close_ms: 1715971041844,
        current_return: 1.002561059816547,
        initial_entry_price: 5300.54,
        is_closed_position: true,
        miner_hotkey: "5EUTaAo7vCGxvLDWRXRrEuqctPjt9fKZmgkaeFZocWECUe9X",
        net_leverage: 0.0,
        open_ms: 1715952729536,
        orders: [
          {
            leverage: -1.5,
            order_type: "SHORT",
            order_uuid: "b1399ff6-d569-45f6-9da0-ed8786bbed78",
            price: 5300.54,
            price_sources: [],
            processed_ms: 1715952727105,
          },
          {
            leverage: 0.5,
            order_type: "FLAT",
            order_uuid: "38b7cead-7b05-4d3b-a8f1-9a4d591662c8",
            price: 5291.49,
            price_sources: [],
            processed_ms: 1715971041844,
          },
        ],
        position_type: "FLAT",
        position_uuid: "3de20aad-ab5f-4ff0-a729-7a978d0dffc1",
        return_at_close: 1.0024257140734718,
        trade_pair: ["SPX", "SPX", 9e-5, 0.001, 500],
      },
      {
        average_entry_price: 1.08971,
        close_ms: 1716240093021,
        current_return: 1.0015121551890653,
        initial_entry_price: 1.08719,
        is_closed_position: true,
        miner_hotkey: "5EUTaAo7vCGxvLDWRXRrEuqctPjt9fKZmgkaeFZocWECUe9X",
        net_leverage: 0.0,
        open_ms: 1716190268249,
        orders: [
          {
            leverage: -1.0,
            order_type: "SHORT",
            order_uuid: "edb2b390-9a95-4d87-9dac-eb4381589ebd",
            price: 1.08719,
            price_sources: [],
            processed_ms: 1716190267846,
          },
          {
            leverage: 0.6,
            order_type: "LONG",
            order_uuid: "8d0ff07c-6c83-4fca-94ce-1fcade1a59d4",
            price: 1.0855100000000002,
            price_sources: [],
            processed_ms: 1716208272703,
          },
          {
            leverage: 0.6,
            order_type: "FLAT",
            order_uuid: "753051b0-d40e-4b3b-a369-1f63fe577490",
            price: 1.0856,
            price_sources: [],
            processed_ms: 1716240093021,
          },
        ],
        position_type: "FLAT",
        position_uuid: "273dc30e-fff1-4378-8b29-5b773e33d44b",
        return_at_close: 1.0014420493382021,
        trade_pair: ["EURUSD", "EUR/USD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.83241,
        close_ms: 1716292913192,
        current_return: 0.9971708653187733,
        initial_entry_price: 0.83241,
        is_closed_position: true,
        miner_hotkey: "5EUTaAo7vCGxvLDWRXRrEuqctPjt9fKZmgkaeFZocWECUe9X",
        net_leverage: 0.0,
        open_ms: 1716207471652,
        orders: [
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "a71ec415-0715-48b3-a343-af999248a9cb",
            price: 0.83241,
            price_sources: [],
            processed_ms: 1716207471296,
          },
          {
            leverage: 0.6,
            order_type: "FLAT",
            order_uuid: "8715a486-e4b1-41d1-85d8-daae8af9ee81",
            price: 0.83084,
            price_sources: [],
            processed_ms: 1716292913192,
          },
        ],
        position_type: "FLAT",
        position_uuid: "9aff0fc9-1076-412b-b1a7-3cc37ba9755e",
        return_at_close: 0.9970661623779148,
        trade_pair: ["NZDCAD", "NZD/CAD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 1.0866262499999997,
        close_ms: 1716305280842,
        current_return: 1.0018781156301155,
        initial_entry_price: 1.08726,
        is_closed_position: true,
        miner_hotkey: "5EUTaAo7vCGxvLDWRXRrEuqctPjt9fKZmgkaeFZocWECUe9X",
        net_leverage: 0.0,
        open_ms: 1716285638555,
        orders: [
          {
            leverage: -1.0,
            order_type: "SHORT",
            order_uuid: "51ba7211-bf7d-459c-b1ec-200164d6ef80",
            price: 1.08726,
            price_sources: [],
            processed_ms: 1716285637732,
          },
          {
            leverage: -0.6,
            order_type: "SHORT",
            order_uuid: "74f2f72c-d0b2-4fdf-8b41-deda93998bd9",
            price: 1.08557,
            price_sources: [],
            processed_ms: 1716297178868,
          },
          {
            leverage: 0.6,
            order_type: "FLAT",
            order_uuid: "f7cf4796-88da-49bc-914e-7fc9e575c257",
            price: 1.08535,
            price_sources: [],
            processed_ms: 1716305280842,
          },
        ],
        position_type: "FLAT",
        position_uuid: "68e8c9b1-4ce0-4599-adee-df084c839dfd",
        return_at_close: 1.001765905281165,
        trade_pair: ["EURUSD", "EUR/USD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 198.741,
        close_ms: 1716325537138,
        current_return: 0.9980929954060812,
        initial_entry_price: 198.741,
        is_closed_position: true,
        miner_hotkey: "5EUTaAo7vCGxvLDWRXRrEuqctPjt9fKZmgkaeFZocWECUe9X",
        net_leverage: 0.0,
        open_ms: 1716291373057,
        orders: [
          {
            leverage: 1.0,
            order_type: "LONG",
            order_uuid: "f3d89f32-87c0-4e3a-9e3f-400003a4eedc",
            price: 198.741,
            price_sources: [],
            processed_ms: 1716291372204,
          },
          {
            leverage: 0.6,
            order_type: "FLAT",
            order_uuid: "beccb2c5-ec91-43eb-818c-619d7bddddc7",
            price: 198.362,
            price_sources: [],
            processed_ms: 1716325537138,
          },
        ],
        position_type: "FLAT",
        position_uuid: "dafaf80e-2ea0-4bb2-8b55-49f88d7b3506",
        return_at_close: 0.9980231288964028,
        trade_pair: ["GBPJPY", "GBP/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.8557533333333334,
        close_ms: 1716365409597,
        current_return: 0.9931935031549427,
        initial_entry_price: 0.8558,
        is_closed_position: true,
        miner_hotkey: "5EUTaAo7vCGxvLDWRXRrEuqctPjt9fKZmgkaeFZocWECUe9X",
        net_leverage: 0.0,
        open_ms: 1715960124156,
        orders: [
          {
            leverage: 1.0,
            order_type: "LONG",
            order_uuid: "11856608-86fc-4b1f-af90-ea6349ddf179",
            price: 0.8558,
            price_sources: [],
            processed_ms: 1715960122156,
          },
          {
            leverage: 0.5,
            order_type: "LONG",
            order_uuid: "f0cd18f8-92b1-4024-b40a-23c495e69ff7",
            price: 0.85566,
            price_sources: [],
            processed_ms: 1715960943595,
          },
          {
            leverage: -2.0,
            order_type: "SHORT",
            order_uuid: "059bc95c-2e32-4c54-9f8a-4c3e11591b64",
            price: 0.85187,
            price_sources: [],
            processed_ms: 1716365409597,
          },
        ],
        position_type: "FLAT",
        position_uuid: "ec53132a-379f-49c5-a197-21584598e478",
        return_at_close: 0.9930892178371115,
        trade_pair: ["EURGBP", "EUR/GBP", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.8514025000000001,
        close_ms: 1716555284254,
        current_return: 1.0038558148269872,
        initial_entry_price: 0.85196,
        is_closed_position: true,
        miner_hotkey: "5EUTaAo7vCGxvLDWRXRrEuqctPjt9fKZmgkaeFZocWECUe9X",
        net_leverage: 0.0,
        open_ms: 1716365512393,
        orders: [
          {
            leverage: 4.0,
            order_type: "LONG",
            order_uuid: "13268bd2-02b7-46b5-a535-006ec261d1d9",
            price: 0.85196,
            price_sources: [],
            processed_ms: 1716365512066,
          },
          {
            leverage: -3.0,
            order_type: "SHORT",
            order_uuid: "19618ab6-e103-4377-b4e2-7f217e14ca25",
            price: 0.85207,
            price_sources: [],
            processed_ms: 1716463120004,
          },
          {
            leverage: 1.0,
            order_type: "LONG",
            order_uuid: "c6be2d71-ef55-4e76-afed-22d4ab7c4ec0",
            price: 0.851175,
            price_sources: [],
            processed_ms: 1716483252616,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "0fefe88c-4f75-44e5-a4af-586d912535cd",
            price: 0.853045,
            price_sources: [],
            processed_ms: 1716555284254,
          },
        ],
        position_type: "FLAT",
        position_uuid: "b82422c2-851e-4d49-b961-c8c0993e52ed",
        return_at_close: 1.0035044652917977,
        trade_pair: ["EURGBP", "EUR/GBP", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 39418.17,
        close_ms: 1716561079217,
        current_return: 0.9923185170696661,
        initial_entry_price: 39418.17,
        is_closed_position: true,
        miner_hotkey: "5EUTaAo7vCGxvLDWRXRrEuqctPjt9fKZmgkaeFZocWECUe9X",
        net_leverage: 0.0,
        open_ms: 1716476956835,
        orders: [
          {
            leverage: 1.0,
            order_type: "LONG",
            order_uuid: "573ebe20-c09e-47b5-8e0e-f1df61b0ef11",
            price: 39418.17,
            price_sources: [],
            processed_ms: 1716476956617,
          },
          {
            leverage: 1.25,
            order_type: "FLAT",
            order_uuid: "6fa41232-7aec-46c1-8c2f-6b0494e5417d",
            price: 39115.38,
            price_sources: [],
            processed_ms: 1716561079217,
          },
        ],
        position_type: "FLAT",
        position_uuid: "3037e108-64da-4a9c-ac9d-0ff8c929f9db",
        return_at_close: 0.9922292084031298,
        trade_pair: ["DJI", "DJI", 9e-5, 0.001, 500],
      },
      {
        average_entry_price: 1.08772,
        close_ms: 1716896179682,
        current_return: 0.9990990328393337,
        initial_entry_price: 1.08772,
        is_closed_position: true,
        miner_hotkey: "5EUTaAo7vCGxvLDWRXRrEuqctPjt9fKZmgkaeFZocWECUe9X",
        net_leverage: 0.0,
        open_ms: 1716877655957,
        orders: [
          {
            leverage: -1.0,
            order_type: "SHORT",
            order_uuid: "727a9abb-5193-4c9e-96c8-2299ec64ae6f",
            price: 1.08772,
            price_sources: [],
            processed_ms: 1716877655362,
          },
          {
            leverage: 0.15,
            order_type: "FLAT",
            order_uuid: "60086959-5fe5-4120-98ab-0ed22d949a2f",
            price: 1.0887,
            price_sources: [],
            processed_ms: 1716896179682,
          },
        ],
        position_type: "FLAT",
        position_uuid: "0197f0f5-8f80-4d46-84d8-b1614fd9d553",
        return_at_close: 0.9990290959070349,
        trade_pair: ["EURUSD", "EUR/USD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 1.0878299999999999,
        close_ms: 1716903520153,
        current_return: 1.000750369900655,
        initial_entry_price: 1.08813,
        is_closed_position: true,
        miner_hotkey: "5EUTaAo7vCGxvLDWRXRrEuqctPjt9fKZmgkaeFZocWECUe9X",
        net_leverage: 0.0,
        open_ms: 1716898542816,
        orders: [
          {
            leverage: -0.15,
            order_type: "SHORT",
            order_uuid: "42c5a974-c06e-4896-b907-3950220071ef",
            price: 1.08813,
            price_sources: [],
            processed_ms: 1716898542791,
          },
          {
            leverage: -0.5,
            order_type: "SHORT",
            order_uuid: "1a712c6c-c47a-47e7-9b40-5bf0bfbead00",
            price: 1.0876,
            price_sources: [],
            processed_ms: 1716900059278,
          },
          {
            leverage: -0.5,
            order_type: "SHORT",
            order_uuid: "1a24f74b-9adc-4b25-b5f2-8079ebdf97b6",
            price: 1.0879699999999999,
            price_sources: [],
            processed_ms: 1716900210520,
          },
          {
            leverage: 0.75,
            order_type: "FLAT",
            order_uuid: "a83c4f7d-4161-4590-86f0-16b6cfb183a5",
            price: 1.08712,
            price_sources: [],
            processed_ms: 1716903520153,
          },
        ],
        position_type: "FLAT",
        position_uuid: "caa7c9fd-5d60-4058-bb5c-37423c4fca42",
        return_at_close: 1.000669809495878,
        trade_pair: ["EURUSD", "EUR/USD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.8501988461538462,
        close_ms: 1716919200374,
        current_return: 1.00353971893926,
        initial_entry_price: 0.85035,
        is_closed_position: true,
        miner_hotkey: "5EUTaAo7vCGxvLDWRXRrEuqctPjt9fKZmgkaeFZocWECUe9X",
        net_leverage: 0.0,
        open_ms: 1716900604308,
        orders: [
          {
            leverage: 2.5,
            order_type: "LONG",
            order_uuid: "5492ffc4-c1f5-4547-9d8b-3844f8ee1a6b",
            price: 0.85035,
            price_sources: [],
            processed_ms: 1716900604254,
          },
          {
            leverage: 0.75,
            order_type: "LONG",
            order_uuid: "0e1f8132-c1b3-476a-8103-b7b3d1561e1d",
            price: 0.849695,
            price_sources: [],
            processed_ms: 1716902789043,
          },
          {
            leverage: 0.5,
            order_type: "FLAT",
            order_uuid: "3b85f839-06aa-4e9f-b880-215d5a2e403a",
            price: 0.851125,
            price_sources: [],
            processed_ms: 1716919200374,
          },
        ],
        position_type: "FLAT",
        position_uuid: "e1f95546-c45c-40ef-aeb5-5dd7f0b818c3",
        return_at_close: 1.0033114136532013,
        trade_pair: ["EURGBP", "EUR/GBP", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 1.0797050000000001,
        close_ms: 1717061585519,
        current_return: 1.0016532293543143,
        initial_entry_price: 1.0797050000000001,
        is_closed_position: true,
        miner_hotkey: "5EUTaAo7vCGxvLDWRXRrEuqctPjt9fKZmgkaeFZocWECUe9X",
        net_leverage: 0.0,
        open_ms: 1717052866726,
        orders: [
          {
            leverage: 1.0,
            order_type: "LONG",
            order_uuid: "a155fc1f-4b1a-42c8-b4bc-f39fa479befd",
            price: 1.0797050000000001,
            price_sources: [],
            processed_ms: 1717052866711,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "a431bb8c-6abc-40d7-a358-b886a53b54bc",
            price: 1.08149,
            price_sources: [],
            processed_ms: 1717061585519,
          },
        ],
        position_type: "FLAT",
        position_uuid: "9070ea7f-a90f-4762-8343-94000f1797ec",
        return_at_close: 1.0015831136282596,
        trade_pair: ["EURUSD", "EUR/USD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.9916553658536584,
        close_ms: 1717063418641,
        current_return: 1.0219378805597,
        initial_entry_price: 0.99196,
        is_closed_position: true,
        miner_hotkey: "5EUTaAo7vCGxvLDWRXRrEuqctPjt9fKZmgkaeFZocWECUe9X",
        net_leverage: 0.0,
        open_ms: 1716558205230,
        orders: [
          {
            leverage: -0.5,
            order_type: "SHORT",
            order_uuid: "61a6d279-7384-4275-9a02-0710734cc53e",
            price: 0.99196,
            price_sources: [],
            processed_ms: 1716558205216,
          },
          {
            leverage: -1.25,
            order_type: "SHORT",
            order_uuid: "37b149b6-31a4-4757-a773-591985bd6f4c",
            price: 0.991425,
            price_sources: [],
            processed_ms: 1716559783015,
          },
          {
            leverage: -0.15,
            order_type: "SHORT",
            order_uuid: "a88da13f-8baa-477e-8803-c0d853f07e6b",
            price: 0.991995,
            price_sources: [],
            processed_ms: 1716564213778,
          },
          {
            leverage: -0.15,
            order_type: "SHORT",
            order_uuid: "25f62824-16aa-4ac1-824a-955725a584fd",
            price: 0.99222,
            price_sources: [],
            processed_ms: 1716577443109,
          },
          {
            leverage: 0.5,
            order_type: "FLAT",
            order_uuid: "47d4e98f-bd35-4b26-a322-da45cd97f109",
            price: 0.9810399999999999,
            price_sources: [],
            processed_ms: 1717063418641,
          },
        ],
        position_type: "FLAT",
        position_uuid: "e88cf29d-4b74-4695-8bad-bc5006f14885",
        return_at_close: 1.0217912324738396,
        trade_pair: ["EURCHF", "EUR/CHF", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 1.37136125,
        close_ms: 1717071733882,
        current_return: 1.0011980918062922,
        initial_entry_price: 1.37093,
        is_closed_position: true,
        miner_hotkey: "5EUTaAo7vCGxvLDWRXRrEuqctPjt9fKZmgkaeFZocWECUe9X",
        net_leverage: 0.0,
        open_ms: 1716994729061,
        orders: [
          {
            leverage: -2.0,
            order_type: "SHORT",
            order_uuid: "e82871e5-5c9f-4963-bf32-53164c91fa94",
            price: 1.37093,
            price_sources: [],
            processed_ms: 1716994729045,
          },
          {
            leverage: 1.0,
            order_type: "LONG",
            order_uuid: "f7820606-ff57-4fb7-84ca-0cc572de700b",
            price: 1.37014,
            price_sources: [],
            processed_ms: 1716996547207,
          },
          {
            leverage: -1.0,
            order_type: "SHORT",
            order_uuid: "b3209166-a054-4add-b8df-41d2e2a75ccc",
            price: 1.37112,
            price_sources: [],
            processed_ms: 1717001064002,
          },
          {
            leverage: -0.5,
            order_type: "SHORT",
            order_uuid: "793f37be-1edb-43ef-aaed-374a1a751b54",
            price: 1.371385,
            price_sources: [],
            processed_ms: 1717002509417,
          },
          {
            leverage: 0.5,
            order_type: "LONG",
            order_uuid: "7cc091a9-be16-40d1-8c23-228e13030387",
            price: 1.37162,
            price_sources: [],
            processed_ms: 1717066148282,
          },
          {
            leverage: 0.5,
            order_type: "FLAT",
            order_uuid: "cc6831a5-9e17-4717-bb3f-443923ca2ee8",
            price: 1.37054,
            price_sources: [],
            processed_ms: 1717071733882,
          },
        ],
        position_type: "FLAT",
        position_uuid: "f4b4d9a2-3c1d-4670-a46d-d1b466ad043c",
        return_at_close: 1.0009527982737996,
        trade_pair: ["USDCAD", "USD/CAD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 156.60640322580656,
        close_ms: 1717097359652,
        current_return: 1.0420312499999764,
        initial_entry_price: 156.8,
        is_closed_position: true,
        miner_hotkey: "5EUTaAo7vCGxvLDWRXRrEuqctPjt9fKZmgkaeFZocWECUe9X",
        net_leverage: 0.0,
        open_ms: 1717072183844,
        orders: [
          {
            leverage: 50.0,
            order_type: "LONG",
            order_uuid: "4b0c8c69-3336-4ba9-a9c0-2a7db3728965",
            price: 156.8,
            price_sources: [],
            processed_ms: 1717072183811,
          },
          {
            leverage: -49.0,
            order_type: "SHORT",
            order_uuid: "6bb298e2-e8c5-4262-a81d-b68b919db75e",
            price: 156.664,
            price_sources: [],
            processed_ms: 1717072352897,
          },
          {
            leverage: 49.0,
            order_type: "LONG",
            order_uuid: "1cbd5ea4-d575-4229-a73b-db8f43598d21",
            price: 156.6765,
            price_sources: [],
            processed_ms: 1717072672285,
          },
          {
            leverage: -39.0,
            order_type: "SHORT",
            order_uuid: "4dfe3afd-49e8-45ed-8e59-49cc941196f8",
            price: 156.75599999999997,
            price_sources: [],
            processed_ms: 1717072903086,
          },
          {
            leverage: 20.0,
            order_type: "LONG",
            order_uuid: "f71454dc-49c9-4d7e-8149-e4c176f236c0",
            price: 156.619,
            price_sources: [],
            processed_ms: 1717073572987,
          },
          {
            leverage: -20.0,
            order_type: "SHORT",
            order_uuid: "15e901f2-6b43-458b-b608-86400806147c",
            price: 156.81,
            price_sources: [],
            processed_ms: 1717078096700,
          },
          {
            leverage: 10.0,
            order_type: "LONG",
            order_uuid: "ae02d8de-a4b1-480c-9e54-8922bca71f8e",
            price: 156.59300000000002,
            price_sources: [],
            processed_ms: 1717080563945,
          },
          {
            leverage: 10.0,
            order_type: "LONG",
            order_uuid: "002f1ca9-8e7f-4c96-ac50-d20393844721",
            price: 156.556,
            price_sources: [],
            processed_ms: 1717080809398,
          },
          {
            leverage: 10.0,
            order_type: "FLAT",
            order_uuid: "1164ffa8-7639-4bbb-8841-dbafdbfc63a7",
            price: 156.819,
            price_sources: [],
            processed_ms: 1717097359652,
          },
        ],
        position_type: "FLAT",
        position_uuid: "ba7829d8-6ae6-4d53-868c-ac295c99c9c1",
        return_at_close: 1.0318922859374766,
        trade_pair: ["USDJPY", "USD/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 69089.93,
        close_ms: 1717414073027,
        current_return: 1.0001856565783176,
        initial_entry_price: 69089.93,
        is_closed_position: true,
        miner_hotkey: "5EUTaAo7vCGxvLDWRXRrEuqctPjt9fKZmgkaeFZocWECUe9X",
        net_leverage: 0.0,
        open_ms: 1717413496139,
        orders: [
          {
            leverage: 0.1,
            order_type: "LONG",
            order_uuid: "650a5236-b5f1-40ea-8a42-0f3237dcbc63",
            price: 69089.93,
            price_sources: [],
            processed_ms: 1717413494096,
          },
          {
            leverage: 0.1,
            order_type: "FLAT",
            order_uuid: "e0c47bd4-2eb2-4a75-ac5b-e8807e7e88ff",
            price: 69218.2,
            price_sources: [],
            processed_ms: 1717414073027,
          },
        ],
        position_type: "FLAT",
        position_uuid: "479dbca4-ed97-47a7-97a8-e93a8a30ff98",
        return_at_close: 1.0000856380126597,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 69136.84,
        close_ms: 1717418061204,
        current_return: 1.0004254750434067,
        initial_entry_price: 69136.84,
        is_closed_position: true,
        miner_hotkey: "5EUTaAo7vCGxvLDWRXRrEuqctPjt9fKZmgkaeFZocWECUe9X",
        net_leverage: 0.0,
        open_ms: 1717416662730,
        orders: [
          {
            leverage: 0.1,
            order_type: "LONG",
            order_uuid: "83af559c-2128-4ec9-993b-db4673020acd",
            price: 69136.84,
            price_sources: [],
            processed_ms: 1717416662490,
          },
          {
            leverage: 0.1,
            order_type: "FLAT",
            order_uuid: "74781ef8-a68e-42ac-a867-dc940e425341",
            price: 69431.0,
            price_sources: [],
            processed_ms: 1717418061204,
          },
        ],
        position_type: "FLAT",
        position_uuid: "6de10624-83af-4b21-bea3-dd2f72e2dc60",
        return_at_close: 1.0003254324959023,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 0.90386,
        close_ms: 1717436722858,
        current_return: 0.9806164671519926,
        initial_entry_price: 0.90386,
        is_closed_position: true,
        miner_hotkey: "5EUTaAo7vCGxvLDWRXRrEuqctPjt9fKZmgkaeFZocWECUe9X",
        net_leverage: 0.0,
        open_ms: 1717080608411,
        orders: [
          {
            leverage: 2.0,
            order_type: "LONG",
            order_uuid: "a95101d0-16a6-401a-9432-0dbc98d01d4f",
            price: 0.90386,
            price_sources: [],
            processed_ms: 1717080608345,
          },
          {
            leverage: 0.5,
            order_type: "FLAT",
            order_uuid: "a9ecd17b-2870-4a31-8d71-8ac98658b9f1",
            price: 0.8951,
            price_sources: [],
            processed_ms: 1717436722858,
          },
        ],
        position_type: "FLAT",
        position_uuid: "d36d5b84-6aee-4316-abf7-5a0b72e960ec",
        return_at_close: 0.9804791808465912,
        trade_pair: ["USDCHF", "USD/CHF", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.85058,
        close_ms: 1717436764003,
        current_return: 1.0015518822450562,
        initial_entry_price: 0.85058,
        is_closed_position: true,
        miner_hotkey: "5EUTaAo7vCGxvLDWRXRrEuqctPjt9fKZmgkaeFZocWECUe9X",
        net_leverage: 0.0,
        open_ms: 1716994511793,
        orders: [
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "6f518f57-afef-49a6-99ef-d7ae21388ea4",
            price: 0.85058,
            price_sources: [],
            processed_ms: 1716994511779,
          },
          {
            leverage: 0.5,
            order_type: "FLAT",
            order_uuid: "e6495b4f-180e-4365-b982-6f763289b020",
            price: 0.85146,
            price_sources: [],
            processed_ms: 1717436764003,
          },
        ],
        position_type: "FLAT",
        position_uuid: "c33c6d25-7da6-4645-82a1-192935a52b58",
        return_at_close: 1.0014467192974204,
        trade_pair: ["EURGBP", "EUR/GBP", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 1.08863,
        close_ms: 1717496142842,
        current_return: 1.000881842315571,
        initial_entry_price: 1.08863,
        is_closed_position: true,
        miner_hotkey: "5EUTaAo7vCGxvLDWRXRrEuqctPjt9fKZmgkaeFZocWECUe9X",
        net_leverage: 0.0,
        open_ms: 1717435578902,
        orders: [
          {
            leverage: -0.5,
            order_type: "SHORT",
            order_uuid: "c860f8af-9de9-45b8-a74b-c83e093c35ca",
            price: 1.08863,
            price_sources: [],
            processed_ms: 1717435578867,
          },
          {
            leverage: 0.5,
            order_type: "FLAT",
            order_uuid: "96c14267-74de-413c-bf58-b13bd21e866d",
            price: 1.08671,
            price_sources: [],
            processed_ms: 1717496142842,
          },
        ],
        position_type: "FLAT",
        position_uuid: "ab451df6-f337-44f3-818d-39d6419bafaf",
        return_at_close: 1.00084681145109,
        trade_pair: ["EURUSD", "EUR/USD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 1.28085,
        close_ms: 1717496177175,
        current_return: 1.0021079751727369,
        initial_entry_price: 1.28085,
        is_closed_position: true,
        miner_hotkey: "5EUTaAo7vCGxvLDWRXRrEuqctPjt9fKZmgkaeFZocWECUe9X",
        net_leverage: 0.0,
        open_ms: 1717464129566,
        orders: [
          {
            leverage: -0.5,
            order_type: "SHORT",
            order_uuid: "6a5c0b97-d578-49a5-bc1e-bc0b6cf57671",
            price: 1.28085,
            price_sources: [],
            processed_ms: 1717464129550,
          },
          {
            leverage: 0.5,
            order_type: "FLAT",
            order_uuid: "7b1f796b-adb0-4350-88f1-b61c4124ceaa",
            price: 1.27545,
            price_sources: [],
            processed_ms: 1717496177175,
          },
        ],
        position_type: "FLAT",
        position_uuid: "4baf79bb-18a3-431a-b3ca-d48cbb291fde",
        return_at_close: 1.0020729013936058,
        trade_pair: ["GBPUSD", "GBP/USD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 168.298,
        close_ms: 1717501429513,
        current_return: 1.0001735017647269,
        initial_entry_price: 168.298,
        is_closed_position: true,
        miner_hotkey: "5EUTaAo7vCGxvLDWRXRrEuqctPjt9fKZmgkaeFZocWECUe9X",
        net_leverage: 0.0,
        open_ms: 1717498584867,
        orders: [
          {
            leverage: 0.1,
            order_type: "LONG",
            order_uuid: "96732e2c-d124-4269-b8a4-d12ac5c78d9d",
            price: 168.298,
            price_sources: [],
            processed_ms: 1717498583659,
          },
          {
            leverage: 0.1,
            order_type: "FLAT",
            order_uuid: "37e51a6e-e39e-4248-9e24-d4e20718b5b6",
            price: 168.59,
            price_sources: [],
            processed_ms: 1717501429513,
          },
        ],
        position_type: "FLAT",
        position_uuid: "84f1b520-f4c4-4345-8780-aacb116b38ff",
        return_at_close: 1.0001665005502145,
        trade_pair: ["EURJPY", "EUR/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 69117.89,
        close_ms: 1717510406095,
        current_return: 1.0020953475286933,
        initial_entry_price: 69117.89,
        is_closed_position: true,
        miner_hotkey: "5EUTaAo7vCGxvLDWRXRrEuqctPjt9fKZmgkaeFZocWECUe9X",
        net_leverage: 0.0,
        open_ms: 1717427445699,
        orders: [
          {
            leverage: 0.2,
            order_type: "LONG",
            order_uuid: "7de56d0c-0009-47a7-b370-96f3e3a4024f",
            price: 69117.89,
            price_sources: [],
            processed_ms: 1717427444958,
          },
          {
            leverage: 0.1,
            order_type: "FLAT",
            order_uuid: "0bd8cb3a-244c-4380-87c3-7f4f88d4b4d2",
            price: 69842.02,
            price_sources: [],
            processed_ms: 1717510406095,
          },
        ],
        position_type: "FLAT",
        position_uuid: "3e3c7cc8-7293-4d8d-9f0c-84358189ed4e",
        return_at_close: 1.0018949284591876,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 0.910858644665491,
        close_ms: 1717678544320,
        current_return: 0.9998384937327663,
        initial_entry_price: 0.910858644665491,
        is_closed_position: true,
        miner_hotkey: "5EUTaAo7vCGxvLDWRXRrEuqctPjt9fKZmgkaeFZocWECUe9X",
        net_leverage: 0.0,
        open_ms: 1717677023281,
        orders: [
          {
            leverage: 0.1,
            order_type: "LONG",
            order_uuid: "751ae83f-35cd-47ea-b693-7c4c6367a759",
            price: 0.910858644665491,
            price_sources: [],
            processed_ms: 1717677020618,
          },
          {
            leverage: 0.6,
            order_type: "FLAT",
            order_uuid: "c6f731ef-fb5e-48d5-88a6-e4376502b2c5",
            price: 0.909387550868717,
            price_sources: [],
            processed_ms: 1717678544320,
          },
        ],
        position_type: "FLAT",
        position_uuid: "e50eb4a1-ca79-433e-a9b7-3326fd12caf9",
        return_at_close: 0.9998314948633102,
        trade_pair: ["AUDCAD", "AUD/CAD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.85157,
        close_ms: 1717748544019,
        current_return: 1.0002606949516775,
        initial_entry_price: 0.85157,
        is_closed_position: true,
        miner_hotkey: "5EUTaAo7vCGxvLDWRXRrEuqctPjt9fKZmgkaeFZocWECUe9X",
        net_leverage: 0.0,
        open_ms: 1717678069436,
        orders: [
          {
            leverage: -0.6,
            order_type: "SHORT",
            order_uuid: "de09ac3c-82c2-435c-9eb8-32c252804084",
            price: 0.85157,
            price_sources: [],
            processed_ms: 1717678069407,
          },
          {
            leverage: 0.2,
            order_type: "FLAT",
            order_uuid: "bf217092-3908-4698-959d-dcc122612851",
            price: 0.8512,
            price_sources: [],
            processed_ms: 1717748544019,
          },
        ],
        position_type: "FLAT",
        position_uuid: "0c279656-6d7d-440c-9dca-791f11b8af0a",
        return_at_close: 1.0002186840024896,
        trade_pair: ["EURGBP", "EUR/GBP", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.9116980503249461,
        close_ms: 1717763838885,
        current_return: 1.0007738172378295,
        initial_entry_price: 0.911698050324946,
        is_closed_position: true,
        miner_hotkey: "5EUTaAo7vCGxvLDWRXRrEuqctPjt9fKZmgkaeFZocWECUe9X",
        net_leverage: 0.0,
        open_ms: 1717748477681,
        orders: [
          {
            leverage: -0.2,
            order_type: "SHORT",
            order_uuid: "af8071e2-2020-403e-83d5-0966c4e0b7aa",
            price: 0.911698050324946,
            price_sources: [],
            processed_ms: 1717748477650,
          },
          {
            leverage: 0.08,
            order_type: "FLAT",
            order_uuid: "7eb860c4-6667-4ed4-8864-d4cdbf273e5e",
            price: 0.9081706119897606,
            price_sources: [],
            processed_ms: 1717763838885,
          },
        ],
        position_type: "FLAT",
        position_uuid: "8df6d0e6-e781-4ecb-9f8e-ee4189f10f4e",
        return_at_close: 1.0007598064043883,
        trade_pair: ["AUDCAD", "AUD/CAD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 1.4882857142857142,
        close_ms: 1717763869305,
        current_return: 1.0003002084313857,
        initial_entry_price: 1.4872999999999998,
        is_closed_position: true,
        miner_hotkey: "5EUTaAo7vCGxvLDWRXRrEuqctPjt9fKZmgkaeFZocWECUe9X",
        net_leverage: 0.0,
        open_ms: 1717464024322,
        orders: [
          {
            leverage: -0.25,
            order_type: "SHORT",
            order_uuid: "b31449d1-1fa0-4044-abeb-e6f17dd05441",
            price: 1.4872999999999998,
            price_sources: [],
            processed_ms: 1717464024304,
          },
          {
            leverage: -0.1,
            order_type: "SHORT",
            order_uuid: "2edf6a3a-9be0-46ff-9b7a-c4b46c0b23c2",
            price: 1.49075,
            price_sources: [],
            processed_ms: 1717679007105,
          },
          {
            leverage: 0.08,
            order_type: "FLAT",
            order_uuid: "2f299e3c-b822-4366-a3b0-a8e96c64abc1",
            price: 1.48701,
            price_sources: [],
            processed_ms: 1717763869305,
          },
        ],
        position_type: "FLAT",
        position_uuid: "2f9a6a15-6701-4a91-983b-e2e3c04c4bef",
        return_at_close: 1.000275701076279,
        trade_pair: ["EURCAD", "EUR/CAD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 1.0884749999999999,
        close_ms: 1717765977600,
        current_return: 1.0004873791313535,
        initial_entry_price: 1.0884749999999999,
        is_closed_position: true,
        miner_hotkey: "5EUTaAo7vCGxvLDWRXRrEuqctPjt9fKZmgkaeFZocWECUe9X",
        net_leverage: 0.0,
        open_ms: 1717678009308,
        orders: [
          {
            leverage: -0.1,
            order_type: "SHORT",
            order_uuid: "98510b15-ff3d-4d50-9d0d-f3389a0e4f17",
            price: 1.0884749999999999,
            price_sources: [],
            processed_ms: 1717678009278,
          },
          {
            leverage: 0.06,
            order_type: "FLAT",
            order_uuid: "2e30dc0b-611e-4640-99b4-88c946613f8f",
            price: 1.08317,
            price_sources: [],
            processed_ms: 1717765977600,
          },
        ],
        position_type: "FLAT",
        position_uuid: "096415f8-a5de-4f44-9d41-7e25ad9049fc",
        return_at_close: 1.0004803757196996,
        trade_pair: ["EURUSD", "EUR/USD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.6519318181818181,
        close_ms: 1717771082462,
        current_return: 1.000477004183844,
        initial_entry_price: 0.6525099999999999,
        is_closed_position: true,
        miner_hotkey: "5EUTaAo7vCGxvLDWRXRrEuqctPjt9fKZmgkaeFZocWECUe9X",
        net_leverage: 0.0,
        open_ms: 1717496353045,
        orders: [
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "cbd6cc16-6e03-494d-b1a9-91b627702c35",
            price: 0.6525099999999999,
            price_sources: [],
            processed_ms: 1717496350305,
          },
          {
            leverage: 0.08,
            order_type: "LONG",
            order_uuid: "a060769a-2386-4f22-94f9-39c9d30eadeb",
            price: 0.6501250000000001,
            price_sources: [],
            processed_ms: 1717759473265,
          },
          {
            leverage: 0.1,
            order_type: "FLAT",
            order_uuid: "f7a8aae3-da6a-44e7-b170-e4ac820fae8d",
            price: 0.652875,
            price_sources: [],
            processed_ms: 1717771082462,
          },
        ],
        position_type: "FLAT",
        position_uuid: "568b3201-a79b-45aa-8235-03a62777d6b5",
        return_at_close: 1.0004538931650473,
        trade_pair: ["CADCHF", "CAD/CHF", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 1.4846650000000001,
        close_ms: 1717775702485,
        current_return: 1.0000573866831912,
        initial_entry_price: 1.4846650000000001,
        is_closed_position: true,
        miner_hotkey: "5EUTaAo7vCGxvLDWRXRrEuqctPjt9fKZmgkaeFZocWECUe9X",
        net_leverage: 0.0,
        open_ms: 1717765653930,
        orders: [
          {
            leverage: 0.06,
            order_type: "LONG",
            order_uuid: "f64f2f6f-c076-429b-8dad-4f2d25c137e1",
            price: 1.4846650000000001,
            price_sources: [],
            processed_ms: 1717765650931,
          },
          {
            leverage: 0.1,
            order_type: "FLAT",
            order_uuid: "c0c27964-4719-4d0a-b819-0c99d0438216",
            price: 1.486085,
            price_sources: [],
            processed_ms: 1717775702485,
          },
        ],
        position_type: "FLAT",
        position_uuid: "2d99f163-8fc6-46b7-a7fc-b2783d2296d7",
        return_at_close: 1.0000531864421671,
        trade_pair: ["EURCAD", "EUR/CAD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.9053874706441121,
        close_ms: 1717777523507,
        current_return: 1.0001195997974792,
        initial_entry_price: 0.906233694180432,
        is_closed_position: true,
        miner_hotkey: "5EUTaAo7vCGxvLDWRXRrEuqctPjt9fKZmgkaeFZocWECUe9X",
        net_leverage: 0.0,
        open_ms: 1717766123952,
        orders: [
          {
            leverage: 0.06,
            order_type: "LONG",
            order_uuid: "5f60c2ee-80eb-4723-862c-95a76aa0cc81",
            price: 0.906233694180432,
            price_sources: [],
            processed_ms: 1717766123944,
          },
          {
            leverage: 0.1,
            order_type: "LONG",
            order_uuid: "dfa758d7-955f-4705-bd0c-9f2c22a23c1a",
            price: 0.90487973652232,
            price_sources: [],
            processed_ms: 1717769795201,
          },
          {
            leverage: 0.05,
            order_type: "FLAT",
            order_uuid: "fb7d69ee-d4cd-488e-864b-5df00359c073",
            price: 0.906064879183442,
            price_sources: [],
            processed_ms: 1717777523507,
          },
        ],
        position_type: "FLAT",
        position_uuid: "f87dd724-c693-4c52-bb6d-140292027823",
        return_at_close: 1.0001083984579615,
        trade_pair: ["AUDCAD", "AUD/CAD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.8491599999999999,
        close_ms: 1718195461347,
        current_return: 0.9989127490696689,
        initial_entry_price: 0.84916,
        is_closed_position: true,
        miner_hotkey: "5EUTaAo7vCGxvLDWRXRrEuqctPjt9fKZmgkaeFZocWECUe9X",
        net_leverage: 0.0,
        open_ms: 1717781753752,
        orders: [
          {
            leverage: 0.15,
            order_type: "LONG",
            order_uuid: "7e5aef27-ec16-426e-8df0-0927d4f3c5d0",
            price: 0.84916,
            price_sources: [],
            processed_ms: 1717781753743,
          },
          {
            leverage: 0.03,
            order_type: "FLAT",
            order_uuid: "71ffabc0-b3bf-49e3-b884-74c1833383ce",
            price: 0.843005,
            price_sources: [],
            processed_ms: 1718195461347,
          },
        ],
        position_type: "FLAT",
        position_uuid: "712fc988-8ab6-4471-aab6-19e4031b50a7",
        return_at_close: 0.9989022604858037,
        trade_pair: ["EURGBP", "EUR/GBP", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.96872,
        close_ms: 1718195519424,
        current_return: 0.9992365625740514,
        initial_entry_price: 0.97061,
        is_closed_position: true,
        miner_hotkey: "5EUTaAo7vCGxvLDWRXRrEuqctPjt9fKZmgkaeFZocWECUe9X",
        net_leverage: 0.0,
        open_ms: 1717507022066,
        orders: [
          {
            leverage: 0.1,
            order_type: "LONG",
            order_uuid: "311060f4-04c1-4863-8ba2-1793f03ee45c",
            price: 0.97061,
            price_sources: [],
            processed_ms: 1717507022039,
          },
          {
            leverage: 0.03,
            order_type: "LONG",
            order_uuid: "4819a4a9-f68d-4041-b3c3-4ac220a6df85",
            price: 0.96242,
            price_sources: [],
            processed_ms: 1718104826280,
          },
          {
            leverage: 0.03,
            order_type: "FLAT",
            order_uuid: "62cd1c30-1a6d-4f5c-9d99-ffd5b0d9fce3",
            price: 0.96302,
            price_sources: [],
            processed_ms: 1718195519424,
          },
        ],
        position_type: "FLAT",
        position_uuid: "93e76e28-4136-4e78-802d-54d900c70cf3",
        return_at_close: 0.9992274695213319,
        trade_pair: ["EURCHF", "EUR/CHF", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 69079.724375,
        close_ms: 1718196000573,
        current_return: 1.0010728143817953,
        initial_entry_price: 71371.34,
        is_closed_position: true,
        miner_hotkey: "5EUTaAo7vCGxvLDWRXRrEuqctPjt9fKZmgkaeFZocWECUe9X",
        net_leverage: 0.0,
        open_ms: 1717619011675,
        orders: [
          {
            leverage: 0.09,
            order_type: "LONG",
            order_uuid: "cdcfb328-ab57-4ce6-aa3f-0156a7454140",
            price: 71371.34,
            price_sources: [],
            processed_ms: 1717619011398,
          },
          {
            leverage: 0.15,
            order_type: "LONG",
            order_uuid: "81e2097e-c3d2-4afd-9117-9fe5311e3ba0",
            price: 68808.0,
            price_sources: [],
            processed_ms: 1717784320787,
          },
          {
            leverage: 0.08,
            order_type: "LONG",
            order_uuid: "eb0d818b-2e44-4b1f-a561-43729a97c085",
            price: 67011.14,
            price_sources: [],
            processed_ms: 1718102751556,
          },
          {
            leverage: 0.1,
            order_type: "FLAT",
            order_uuid: "deafa990-e112-40ef-9682-7731662cb19f",
            price: 69319.0,
            price_sources: [],
            processed_ms: 1718196000573,
          },
        ],
        position_type: "FLAT",
        position_uuid: "4512d632-b33a-472e-ba0d-bc67dcb90343",
        return_at_close: 1.000752471081193,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 1.0818918181818182,
        close_ms: 1718203490217,
        current_return: 1.0002466730081916,
        initial_entry_price: 1.08281,
        is_closed_position: true,
        miner_hotkey: "5EUTaAo7vCGxvLDWRXRrEuqctPjt9fKZmgkaeFZocWECUe9X",
        net_leverage: 0.0,
        open_ms: 1717766050763,
        orders: [
          {
            leverage: 0.06,
            order_type: "LONG",
            order_uuid: "6d54af10-6226-47b0-9ae2-e5ad94f07fbc",
            price: 1.08281,
            price_sources: [],
            processed_ms: 1717766050755,
          },
          {
            leverage: 0.05,
            order_type: "LONG",
            order_uuid: "9725d4a5-7a78-4ce9-bd28-506a73bee07f",
            price: 1.08079,
            price_sources: [],
            processed_ms: 1717776182219,
          },
          {
            leverage: 0.1,
            order_type: "FLAT",
            order_uuid: "06cd4144-dc18-4109-b560-8a781914c28b",
            price: 1.08432,
            price_sources: [],
            processed_ms: 1718203490217,
          },
        ],
        position_type: "FLAT",
        position_uuid: "003bf7a0-fc45-47a0-b2d6-64092c24dd73",
        return_at_close: 1.0002389711088093,
        trade_pair: ["EURUSD", "EUR/USD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 1.4756799999999999,
        close_ms: 1718328281027,
        current_return: 1.000104358668546,
        initial_entry_price: 1.47568,
        is_closed_position: true,
        miner_hotkey: "5EUTaAo7vCGxvLDWRXRrEuqctPjt9fKZmgkaeFZocWECUe9X",
        net_leverage: 0.0,
        open_ms: 1718315165257,
        orders: [
          {
            leverage: 0.2,
            order_type: "LONG",
            order_uuid: "8bcd136c-731d-4ec1-93a7-9f0e145a2adb",
            price: 1.47568,
            price_sources: [],
            processed_ms: 1718315162503,
          },
          {
            leverage: 0.2,
            order_type: "FLAT",
            order_uuid: "52f8f636-4094-4813-b1c0-306b8bf79049",
            price: 1.47645,
            price_sources: [],
            processed_ms: 1718328281027,
          },
        ],
        position_type: "FLAT",
        position_uuid: "ba81550f-8ecb-4435-ada6-e170a56508a2",
        return_at_close: 1.0000903572075248,
        trade_pair: ["EURCAD", "EUR/CAD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.61486,
        close_ms: 1718330519885,
        current_return: 1.000461623484282,
        initial_entry_price: 0.61522,
        is_closed_position: true,
        miner_hotkey: "5EUTaAo7vCGxvLDWRXRrEuqctPjt9fKZmgkaeFZocWECUe9X",
        net_leverage: 0.0,
        open_ms: 1718328270556,
        orders: [
          {
            leverage: 0.2,
            order_type: "LONG",
            order_uuid: "7e9370e1-459f-4cab-899e-17c710a58cb0",
            price: 0.61522,
            price_sources: [],
            processed_ms: 1718328270337,
          },
          {
            leverage: 0.2,
            order_type: "LONG",
            order_uuid: "55ab7805-5f57-4a7e-81eb-a76227ad032a",
            price: 0.6145,
            price_sources: [],
            processed_ms: 1718328904382,
          },
          {
            leverage: 0.2,
            order_type: "FLAT",
            order_uuid: "d2c5e577-8a64-4db3-a5a3-dfcdc257695d",
            price: 0.61557,
            price_sources: [],
            processed_ms: 1718330519885,
          },
        ],
        position_type: "FLAT",
        position_uuid: "c08e1f55-b729-4add-8665-6dad9a75ce31",
        return_at_close: 1.0004336105588245,
        trade_pair: ["NZDUSD", "NZD/USD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.8414550000000001,
        close_ms: 1718330768254,
        current_return: 1.0000516961691357,
        initial_entry_price: 0.8414550000000001,
        is_closed_position: true,
        miner_hotkey: "5EUTaAo7vCGxvLDWRXRrEuqctPjt9fKZmgkaeFZocWECUe9X",
        net_leverage: 0.0,
        open_ms: 1718315126347,
        orders: [
          {
            leverage: 0.1,
            order_type: "LONG",
            order_uuid: "f35a1741-75aa-42b7-939a-60dd46a08e51",
            price: 0.8414550000000001,
            price_sources: [],
            processed_ms: 1718315126031,
          },
          {
            leverage: 0.2,
            order_type: "FLAT",
            order_uuid: "78406350-5442-41d6-b097-30745b72f3de",
            price: 0.84189,
            price_sources: [],
            processed_ms: 1718330768254,
          },
        ],
        position_type: "FLAT",
        position_uuid: "1efd9a3c-c6e5-4830-b18c-4cc5bcab72e4",
        return_at_close: 1.0000446958072626,
        trade_pair: ["EURGBP", "EUR/GBP", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 3483.6799999999994,
        close_ms: 1718445998839,
        current_return: 1.0001214807330179,
        initial_entry_price: 3483.68,
        is_closed_position: true,
        miner_hotkey: "5EUTaAo7vCGxvLDWRXRrEuqctPjt9fKZmgkaeFZocWECUe9X",
        net_leverage: 0.0,
        open_ms: 1718331015180,
        orders: [
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "cc175c2f-4077-499b-a2ee-e906329fd2ad",
            price: 3483.68,
            price_sources: [],
            processed_ms: 1718331013912,
          },
          {
            leverage: 0.25,
            order_type: "FLAT",
            order_uuid: "9cd57a37-c550-451c-9dc0-accae52035fe",
            price: 3526.0,
            price_sources: [],
            processed_ms: 1718445998839,
          },
        ],
        position_type: "FLAT",
        position_uuid: "0125b47a-cdf5-4abf-86e6-59e43729c648",
        return_at_close: 1.0001114795182107,
        trade_pair: ["ETHUSD", "ETH/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 1.469805,
        close_ms: 1718624872251,
        current_return: 1.0004320300992309,
        initial_entry_price: 1.469805,
        is_closed_position: true,
        miner_hotkey: "5EUTaAo7vCGxvLDWRXRrEuqctPjt9fKZmgkaeFZocWECUe9X",
        net_leverage: 0.0,
        open_ms: 1718386877139,
        orders: [
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "a11693a1-08c6-4a5a-ab4a-dde20b4ba179",
            price: 1.469805,
            price_sources: [],
            processed_ms: 1718386876564,
          },
          {
            leverage: 0.25,
            order_type: "FLAT",
            order_uuid: "46ac4e4c-14c9-40db-9ac0-61d9fc7f6218",
            price: 1.472345,
            price_sources: [],
            processed_ms: 1718624872251,
          },
        ],
        position_type: "FLAT",
        position_uuid: "37013470-f0e0-4714-983b-0f38aecc1dda",
        return_at_close: 1.0004145225387042,
        trade_pair: ["EURCAD", "EUR/CAD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 1.7530000000000001,
        close_ms: 1718629245840,
        current_return: 1.0000114090131202,
        initial_entry_price: 1.7530000000000001,
        is_closed_position: true,
        miner_hotkey: "5EUTaAo7vCGxvLDWRXRrEuqctPjt9fKZmgkaeFZocWECUe9X",
        net_leverage: 0.0,
        open_ms: 1718626676933,
        orders: [
          {
            leverage: 1.0,
            order_type: "LONG",
            order_uuid: "b32de92f-c47f-43a9-bdb9-69a052ef96b6",
            price: 1.7530000000000001,
            price_sources: [],
            processed_ms: 1718626676812,
          },
          {
            leverage: 0.5,
            order_type: "FLAT",
            order_uuid: "794d5ef7-1431-4f4f-bb50-cfffa8a97fde",
            price: 1.75302,
            price_sources: [],
            processed_ms: 1718629245840,
          },
        ],
        position_type: "FLAT",
        position_uuid: "31256fa7-a88b-4dc4-842e-c7043cb77f6f",
        return_at_close: 0.9999414082144893,
        trade_pair: ["EURNZD", "EUR/NZD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 169.458,
        close_ms: 1718715085751,
        current_return: 1.0000430785209313,
        initial_entry_price: 169.458,
        is_closed_position: true,
        miner_hotkey: "5EUTaAo7vCGxvLDWRXRrEuqctPjt9fKZmgkaeFZocWECUe9X",
        net_leverage: 0.0,
        open_ms: 1718706640781,
        orders: [
          {
            leverage: -0.05,
            order_type: "SHORT",
            order_uuid: "fb8c5d0f-6625-4b56-a8a4-d8f191ebc12f",
            price: 169.458,
            price_sources: [],
            processed_ms: 1718706640460,
          },
          {
            leverage: 0.1,
            order_type: "FLAT",
            order_uuid: "de9e4d52-d6d0-4d8f-a10c-af7ec4a19ae4",
            price: 169.312,
            price_sources: [],
            processed_ms: 1718715085751,
          },
        ],
        position_type: "FLAT",
        position_uuid: "8ffb455b-f7e4-4686-bd8d-2c48e92f733b",
        return_at_close: 1.0000395783701566,
        trade_pair: ["EURJPY", "EUR/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 158.0885,
        close_ms: 1718715145808,
        current_return: 1.0001206918909344,
        initial_entry_price: 158.0885,
        is_closed_position: true,
        miner_hotkey: "5EUTaAo7vCGxvLDWRXRrEuqctPjt9fKZmgkaeFZocWECUe9X",
        net_leverage: 0.0,
        open_ms: 1718706457457,
        orders: [
          {
            leverage: -0.08,
            order_type: "SHORT",
            order_uuid: "b200fac0-a6db-45f5-bf25-871c353a54fd",
            price: 158.0885,
            price_sources: [],
            processed_ms: 1718706457281,
          },
          {
            leverage: 0.1,
            order_type: "FLAT",
            order_uuid: "0dadd5bd-9350-4705-af07-35e70b0afc16",
            price: 157.85,
            price_sources: [],
            processed_ms: 1718715145808,
          },
        ],
        position_type: "FLAT",
        position_uuid: "53f3581b-0c48-4b2d-abf6-40839fb20209",
        return_at_close: 1.0001150912150598,
        trade_pair: ["USDJPY", "USD/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 3403.81,
        close_ms: 1718740883252,
        current_return: 1.0002749859716025,
        initial_entry_price: 3403.81,
        is_closed_position: true,
        miner_hotkey: "5EUTaAo7vCGxvLDWRXRrEuqctPjt9fKZmgkaeFZocWECUe9X",
        net_leverage: 0.0,
        open_ms: 1718715219302,
        orders: [
          {
            leverage: 0.1,
            order_type: "LONG",
            order_uuid: "8cac41b7-4183-4f52-af7e-d8ffcdcaa011",
            price: 3403.81,
            price_sources: [],
            processed_ms: 1718715217581,
          },
          {
            leverage: 0.15,
            order_type: "FLAT",
            order_uuid: "7e4237eb-7822-457b-b4aa-d89387ae9b6a",
            price: 3413.17,
            price_sources: [],
            processed_ms: 1718740883252,
          },
        ],
        position_type: "FLAT",
        position_uuid: "3d395332-35c6-4a89-90c5-67765559774e",
        return_at_close: 1.0001749584730053,
        trade_pair: ["ETHUSD", "ETH/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 64506.76006223012,
        close_ms: 1718741332489,
        current_return: 0.9998772817190023,
        initial_entry_price: 64749.68,
        is_closed_position: true,
        miner_hotkey: "5EUTaAo7vCGxvLDWRXRrEuqctPjt9fKZmgkaeFZocWECUe9X",
        net_leverage: 0.0,
        open_ms: 1718717594320,
        orders: [
          {
            leverage: 0.005363108525689771,
            order_type: "LONG",
            order_uuid: "e4e54550-12f0-4ff5-8981-79d9d06ec030",
            price: 64749.68,
            price_sources: [],
            processed_ms: 1718717593126,
          },
          {
            leverage: 0.00853169431162874,
            order_type: "LONG",
            order_uuid: "5318d54c-1810-40aa-927f-a484cde8088e",
            price: 64583.74,
            price_sources: [],
            processed_ms: 1718726534667,
          },
          {
            leverage: 0.004225534692034506,
            order_type: "LONG",
            order_uuid: "d4a39496-d3b8-4a5c-a074-17a6f08f9879",
            price: 64417.87,
            price_sources: [],
            processed_ms: 1718727403244,
          },
          {
            leverage: 0.018487768011867023,
            order_type: "LONG",
            order_uuid: "a7240aac-ef42-4b77-bbb2-81e4866d69bc",
            price: 64752.4,
            price_sources: [],
            processed_ms: 1718730160920,
          },
          {
            leverage: 0.02795409123051299,
            order_type: "LONG",
            order_uuid: "ebdff661-aba9-4ef8-affd-3da3eec865e7",
            price: 64446.0,
            price_sources: [],
            processed_ms: 1718733775111,
          },
          {
            leverage: 0.022126203074422895,
            order_type: "LONG",
            order_uuid: "348af301-6ed4-4d39-9050-7d7166e4b015",
            price: 64624.0,
            price_sources: [],
            processed_ms: 1718737345637,
          },
          {
            leverage: 0.009052336669101246,
            order_type: "LONG",
            order_uuid: "3cf34a98-5753-4178-abdb-ff5c7bc03346",
            price: 64329.4,
            price_sources: [],
            processed_ms: 1718739990855,
          },
          {
            leverage: 0.02509201751942202,
            order_type: "LONG",
            order_uuid: "e9333751-0416-4f54-9f47-6e866f5c322a",
            price: 64290.94,
            price_sources: [],
            processed_ms: 1718740980784,
          },
          {
            leverage: 0.15,
            order_type: "FLAT",
            order_uuid: "868ff927-2a83-4da8-b6f4-b46cbef0c284",
            price: 64441.0,
            price_sources: [],
            processed_ms: 1718741332489,
          },
        ],
        position_type: "FLAT",
        position_uuid: "8d695af4-de8d-4e17-aec8-7a027d62c84f",
        return_at_close: 0.9997564637933555,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 177.45162354553992,
        close_ms: 1718755338450,
        current_return: 0.9959898610230205,
        initial_entry_price: 177.1434106092019,
        is_closed_position: true,
        miner_hotkey: "5EUTaAo7vCGxvLDWRXRrEuqctPjt9fKZmgkaeFZocWECUe9X",
        net_leverage: 0.0,
        open_ms: 1718647020422,
        orders: [
          {
            leverage: -0.5,
            order_type: "SHORT",
            order_uuid: "d6c93f00-12bf-47a9-9082-55022c716378",
            price: 177.1434106092019,
            price_sources: [],
            processed_ms: 1718647020193,
          },
          {
            leverage: -0.15,
            order_type: "SHORT",
            order_uuid: "a64d89c0-f217-4bb3-9755-178fafb0cdb4",
            price: 178.479,
            price_sources: [],
            processed_ms: 1718754576926,
          },
          {
            leverage: 0.15,
            order_type: "FLAT",
            order_uuid: "4b3efc37-0b86-4485-8b92-ce316da60e51",
            price: 178.5445,
            price_sources: [],
            processed_ms: 1718755338450,
          },
        ],
        position_type: "FLAT",
        position_uuid: "f6f6c480-dc75-4941-9bc4-a15801e4a86c",
        return_at_close: 0.9959445434843439,
        trade_pair: ["CHFJPY", "CHF/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 64862.53999999999,
        close_ms: 1718758441608,
        current_return: 0.9997917998584851,
        initial_entry_price: 64862.54,
        is_closed_position: true,
        miner_hotkey: "5EUTaAo7vCGxvLDWRXRrEuqctPjt9fKZmgkaeFZocWECUe9X",
        net_leverage: 0.0,
        open_ms: 1718744576461,
        orders: [
          {
            leverage: -0.06409601787942425,
            order_type: "SHORT",
            order_uuid: "226c4f4c-97e6-4735-a5bc-02de50812f67",
            price: 64862.54,
            price_sources: [],
            processed_ms: 1718744574572,
          },
          {
            leverage: 0.08,
            order_type: "LONG",
            order_uuid: "87b89e68-7c7a-4cda-a0da-cf2e0b3247cd",
            price: 65073.23,
            price_sources: [],
            processed_ms: 1718758441608,
          },
        ],
        position_type: "FLAT",
        position_uuid: "15978f85-cac1-4b0a-b76d-2e19dcc0add3",
        return_at_close: 0.9997277171854058,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 3481.64,
        close_ms: 1718773397345,
        current_return: 0.9997708578559784,
        initial_entry_price: 3481.64,
        is_closed_position: true,
        miner_hotkey: "5EUTaAo7vCGxvLDWRXRrEuqctPjt9fKZmgkaeFZocWECUe9X",
        net_leverage: 0.0,
        open_ms: 1718755414544,
        orders: [
          {
            leverage: -0.010359569592406498,
            order_type: "SHORT",
            order_uuid: "ad8ca9eb-9a99-418f-b11e-38835086f2a9",
            price: 3481.64,
            price_sources: [],
            processed_ms: 1718755411404,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "988f20d7-ca1c-4ce3-baa1-ead73d272cc0",
            price: 3558.65,
            price_sources: [],
            processed_ms: 1718773397345,
          },
        ],
        position_type: "FLAT",
        position_uuid: "be6fad59-0c19-4b60-9411-cd9bbdb6a318",
        return_at_close: 0.9997605006602001,
        trade_pair: ["ETHUSD", "ETH/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 0.840316826309866,
        close_ms: 1718842228388,
        current_return: 0.9995593049615853,
        initial_entry_price: 0.84014,
        is_closed_position: true,
        miner_hotkey: "5EUTaAo7vCGxvLDWRXRrEuqctPjt9fKZmgkaeFZocWECUe9X",
        net_leverage: 0.0,
        open_ms: 1718626719609,
        orders: [
          {
            leverage: -0.5,
            order_type: "SHORT",
            order_uuid: "11983288-1493-477b-8f03-535df24c6513",
            price: 0.84014,
            price_sources: [],
            processed_ms: 1718626719472,
          },
          {
            leverage: 0.1,
            order_type: "LONG",
            order_uuid: "c1ab3304-6a66-45e2-a54f-9eea733530c0",
            price: 0.843035,
            price_sources: [],
            processed_ms: 1718728183501,
          },
          {
            leverage: -0.15,
            order_type: "SHORT",
            order_uuid: "61c72a78-6822-4516-913e-a0a89f499349",
            price: 0.842718363136176,
            price_sources: [],
            processed_ms: 1718734213056,
          },
          {
            leverage: 0.08,
            order_type: "FLAT",
            order_uuid: "1a1a0f16-024c-4691-936c-e6f0481f4f23",
            price: 0.84099,
            price_sources: [],
            processed_ms: 1718842228388,
          },
        ],
        position_type: "FLAT",
        position_uuid: "90fc22f4-9330-4c84-acc7-b8eda4a74f0f",
        return_at_close: 0.9995138250132095,
        trade_pair: ["NZDCAD", "NZD/CAD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.6453656942201722,
        close_ms: 1718842458688,
        current_return: 0.9998723635763939,
        initial_entry_price: 0.64562,
        is_closed_position: true,
        miner_hotkey: "5EUTaAo7vCGxvLDWRXRrEuqctPjt9fKZmgkaeFZocWECUe9X",
        net_leverage: 0.0,
        open_ms: 1718703999490,
        orders: [
          {
            leverage: 0.11,
            order_type: "LONG",
            order_uuid: "64f21a28-51c7-43cc-b755-9cd9584f3bcd",
            price: 0.64562,
            price_sources: [],
            processed_ms: 1718703999203,
          },
          {
            leverage: 0.03,
            order_type: "LONG",
            order_uuid: "72c18f7f-9d07-4f08-a6f1-bec0b63ae919",
            price: 0.64455,
            price_sources: [],
            processed_ms: 1718716231998,
          },
          {
            leverage: 0.0031395781321987147,
            order_type: "LONG",
            order_uuid: "000edfed-0a67-4d5a-b728-91d203f89a7b",
            price: 0.64425,
            price_sources: [],
            processed_ms: 1718730201994,
          },
          {
            leverage: 0.08,
            order_type: "FLAT",
            order_uuid: "caa784c8-4ed3-48bd-a6ff-5df8afb5829b",
            price: 0.64479,
            price_sources: [],
            processed_ms: 1718842458688,
          },
        ],
        position_type: "FLAT",
        position_uuid: "0a0731a1-0b1c-4db3-b160-35e12c98ea47",
        return_at_close: 0.9998623450848123,
        trade_pair: ["CADCHF", "CAD/CHF", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 1.2704194487317269,
        close_ms: 1718861067327,
        current_return: 1.0001118263417457,
        initial_entry_price: 1.27078,
        is_closed_position: true,
        miner_hotkey: "5EUTaAo7vCGxvLDWRXRrEuqctPjt9fKZmgkaeFZocWECUe9X",
        net_leverage: 0.0,
        open_ms: 1718745675532,
        orders: [
          {
            leverage: 0.11244184575122246,
            order_type: "LONG",
            order_uuid: "a6ef354a-2e13-4c2f-b797-3eb1d5dd7270",
            price: 1.27078,
            price_sources: [],
            processed_ms: 1718745648550,
          },
          {
            leverage: 0.11244184575122246,
            order_type: "LONG",
            order_uuid: "62cd9d20-25a1-420f-8504-7906ff78c757",
            price: 1.26985,
            price_sources: [],
            processed_ms: 1718745680832,
          },
          {
            leverage: 0.11244184575122246,
            order_type: "LONG",
            order_uuid: "0ad8530e-e49b-4518-87e5-36af58a140a2",
            price: 1.2708,
            price_sources: [],
            processed_ms: 1718745733786,
          },
          {
            leverage: -0.02191930366747863,
            order_type: "SHORT",
            order_uuid: "779750bb-dc30-4c38-be9a-c295fa7405b9",
            price: 1.2713,
            price_sources: [],
            processed_ms: 1718778448719,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "b41c0aee-ef5f-47f3-ab15-7ce151d9a11a",
            price: 1.27087,
            price_sources: [],
            processed_ms: 1718861067327,
          },
        ],
        position_type: "FLAT",
        position_uuid: "7a718de7-14ac-4df6-94ab-939afeec2454",
        return_at_close: 1.0000882109136062,
        trade_pair: ["GBPUSD", "GBP/USD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.6140266666666666,
        close_ms: 1718901899858,
        current_return: 0.998779167680686,
        initial_entry_price: 0.61413,
        is_closed_position: true,
        miner_hotkey: "5EUTaAo7vCGxvLDWRXRrEuqctPjt9fKZmgkaeFZocWECUe9X",
        net_leverage: 0.0,
        open_ms: 1718745674468,
        orders: [
          {
            leverage: 0.10111257616457452,
            order_type: "LONG",
            order_uuid: "dc6d528c-a947-4d00-8609-7a363d17d2c0",
            price: 0.61413,
            price_sources: [],
            processed_ms: 1718745645600,
          },
          {
            leverage: 0.10111257616457452,
            order_type: "LONG",
            order_uuid: "543ba30f-9f9c-46ae-88cd-613a20c3d718",
            price: 0.614,
            price_sources: [],
            processed_ms: 1718745678578,
          },
          {
            leverage: 0.10111257616457452,
            order_type: "LONG",
            order_uuid: "fda213f7-2536-400a-a93c-1ca79c5a5ab6",
            price: 0.61395,
            price_sources: [],
            processed_ms: 1718745731799,
          },
          {
            leverage: 0.18,
            order_type: "FLAT",
            order_uuid: "56a60285-9b37-4749-9a21-aa12065ca355",
            price: 0.611555,
            price_sources: [],
            processed_ms: 1718901899858,
          },
        ],
        position_type: "FLAT",
        position_uuid: "a776d8f4-c20a-45c0-a852-22508fefe003",
        return_at_close: 0.9987579599624066,
        trade_pair: ["NZDUSD", "NZD/USD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 1.2676349999999998,
        close_ms: 1718910178061,
        current_return: 1.0000598817913797,
        initial_entry_price: 1.2676349999999998,
        is_closed_position: true,
        miner_hotkey: "5EUTaAo7vCGxvLDWRXRrEuqctPjt9fKZmgkaeFZocWECUe9X",
        net_leverage: 0.0,
        open_ms: 1718909004900,
        orders: [
          {
            leverage: -0.08775520764800593,
            order_type: "SHORT",
            order_uuid: "d2981e37-b749-4992-8108-f8c8ab9d5d4d",
            price: 1.2676349999999998,
            price_sources: [],
            processed_ms: 1718909004812,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "075d3cd3-e1d9-4eaa-9ca8-540c43568d31",
            price: 1.26677,
            price_sources: [],
            processed_ms: 1718910178061,
          },
        ],
        position_type: "FLAT",
        position_uuid: "e3d48d89-1797-455e-aa0d-4ac0530464d6",
        return_at_close: 1.0000537385589987,
        trade_pair: ["GBPUSD", "GBP/USD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 64845.19,
        close_ms: 1718915112336,
        current_return: 1.000214726797778,
        initial_entry_price: 64845.19,
        is_closed_position: true,
        miner_hotkey: "5EUTaAo7vCGxvLDWRXRrEuqctPjt9fKZmgkaeFZocWECUe9X",
        net_leverage: 0.0,
        open_ms: 1718893077289,
        orders: [
          {
            leverage: 0.08,
            order_type: "LONG",
            order_uuid: "f38d538f-c2d4-4ac4-b7e0-70fcc49ff7dc",
            price: 64845.19,
            price_sources: [],
            processed_ms: 1718893076643,
          },
          {
            leverage: 0.18,
            order_type: "FLAT",
            order_uuid: "ee718952-8968-425c-984b-ca8c5b35003e",
            price: 65019.24,
            price_sources: [],
            processed_ms: 1718915112336,
          },
        ],
        position_type: "FLAT",
        position_uuid: "26288657-313a-4f8f-b077-b68b6f959ac7",
        return_at_close: 1.000134709619634,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 1.26608,
        close_ms: 1718949786092,
        current_return: 1.0000791895076393,
        initial_entry_price: 1.26608,
        is_closed_position: true,
        miner_hotkey: "5EUTaAo7vCGxvLDWRXRrEuqctPjt9fKZmgkaeFZocWECUe9X",
        net_leverage: 0.0,
        open_ms: 1718912245739,
        orders: [
          {
            leverage: 0.1319213839894037,
            order_type: "LONG",
            order_uuid: "2417354e-b580-4c2e-8b1c-6f43590d7824",
            price: 1.26608,
            price_sources: [],
            processed_ms: 1718912245404,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "db09ce1c-0749-4c98-95dc-8a99cf1be419",
            price: 1.26684,
            price_sources: [],
            processed_ms: 1718949786092,
          },
        ],
        position_type: "FLAT",
        position_uuid: "b1c84f8d-9cd3-4e85-a3a8-1fdeacaecdcb",
        return_at_close: 1.0000699542794849,
        trade_pair: ["GBPUSD", "GBP/USD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 1.2644600000000001,
        close_ms: 1718957600289,
        current_return: 1.0000731184399074,
        initial_entry_price: 1.2644600000000001,
        is_closed_position: true,
        miner_hotkey: "5EUTaAo7vCGxvLDWRXRrEuqctPjt9fKZmgkaeFZocWECUe9X",
        net_leverage: 0.0,
        open_ms: 1718955149801,
        orders: [
          {
            leverage: 0.1038824073317125,
            order_type: "LONG",
            order_uuid: "a79c41bb-aa26-4eea-a013-7dfcb1f834eb",
            price: 1.2644600000000001,
            price_sources: [],
            processed_ms: 1718955149472,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "9592bb5a-2a45-4822-be09-f244fc440ce9",
            price: 1.26535,
            price_sources: [],
            processed_ms: 1718957600289,
          },
        ],
        position_type: "FLAT",
        position_uuid: "6c4a620d-fb18-417b-93b3-faf38948e665",
        return_at_close: 1.0000658461396938,
        trade_pair: ["GBPUSD", "GBP/USD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 1.265475,
        close_ms: 1718964863427,
        current_return: 1.0000389515111208,
        initial_entry_price: 1.265475,
        is_closed_position: true,
        miner_hotkey: "5EUTaAo7vCGxvLDWRXRrEuqctPjt9fKZmgkaeFZocWECUe9X",
        net_leverage: 0.0,
        open_ms: 1718960190808,
        orders: [
          {
            leverage: -0.04501567446182329,
            order_type: "SHORT",
            order_uuid: "f04211e7-a8ab-4639-847b-719d5176c52d",
            price: 1.265475,
            price_sources: [],
            processed_ms: 1718960189813,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "9c0ea0f1-640f-4504-a7f9-1b11231df303",
            price: 1.26438,
            price_sources: [],
            processed_ms: 1718964863427,
          },
        ],
        position_type: "FLAT",
        position_uuid: "48b7b801-6174-4c5c-8614-6256ad15965e",
        return_at_close: 1.0000358002911685,
        trade_pair: ["GBPUSD", "GBP/USD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.9144749999999999,
        close_ms: 1718967623164,
        current_return: 1.0000185485612725,
        initial_entry_price: 0.9144749999999999,
        is_closed_position: true,
        miner_hotkey: "5EUTaAo7vCGxvLDWRXRrEuqctPjt9fKZmgkaeFZocWECUe9X",
        net_leverage: 0.0,
        open_ms: 1718776999021,
        orders: [
          {
            leverage: -0.005163511448082983,
            order_type: "SHORT",
            order_uuid: "961a7de5-24ba-4fbc-9a62-e7b1231949ee",
            price: 0.9144749999999999,
            price_sources: [],
            processed_ms: 1718776996360,
          },
          {
            leverage: 0.18,
            order_type: "FLAT",
            order_uuid: "618bb0b2-c4cf-4503-a047-cbdcb9ee4574",
            price: 0.911189988290398,
            price_sources: [],
            processed_ms: 1718967623164,
          },
        ],
        position_type: "FLAT",
        position_uuid: "4b32a658-b3f5-41c3-a387-de4d489653f8",
        return_at_close: 1.0000181871087668,
        trade_pair: ["AUDCAD", "AUD/CAD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.66545,
        close_ms: 1718986570457,
        current_return: 1.0005396348335713,
        initial_entry_price: 0.66545,
        is_closed_position: true,
        miner_hotkey: "5EUTaAo7vCGxvLDWRXRrEuqctPjt9fKZmgkaeFZocWECUe9X",
        net_leverage: 0.0,
        open_ms: 1718899257142,
        orders: [
          {
            leverage: -0.18,
            order_type: "SHORT",
            order_uuid: "9d47508b-1b13-4177-8391-ab6886d07804",
            price: 0.66545,
            price_sources: [],
            processed_ms: 1718899256475,
          },
          {
            leverage: 0.08,
            order_type: "FLAT",
            order_uuid: "041d9813-59f5-4ce0-9cef-b76f27d1b194",
            price: 0.663455,
            price_sources: [],
            processed_ms: 1718986570457,
          },
        ],
        position_type: "FLAT",
        position_uuid: "d3b0e9ce-f016-4aa8-9f7f-6ab6478d4e0c",
        return_at_close: 1.0005270280341725,
        trade_pair: ["AUDUSD", "AUD/USD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 1.26425,
        close_ms: 1718990585960,
        current_return: 1.0000517483764397,
        initial_entry_price: 1.26425,
        is_closed_position: true,
        miner_hotkey: "5EUTaAo7vCGxvLDWRXRrEuqctPjt9fKZmgkaeFZocWECUe9X",
        net_leverage: 0.0,
        open_ms: 1718989017657,
        orders: [
          {
            leverage: -0.10552078211926434,
            order_type: "SHORT",
            order_uuid: "65c7581c-4891-4eca-ad24-93f207c16440",
            price: 1.26425,
            price_sources: [],
            processed_ms: 1718989015500,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "702a0786-8dac-4a81-9ce7-c804932acee0",
            price: 1.26363,
            price_sources: [],
            processed_ms: 1718990585960,
          },
        ],
        position_type: "FLAT",
        position_uuid: "0a315a1c-d1c5-4ab3-80d2-7f7208cc84a7",
        return_at_close: 1.0000443615394543,
        trade_pair: ["GBPUSD", "GBP/USD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.9558240741182784,
        close_ms: 1719226120887,
        current_return: 1.0005204907993779,
        initial_entry_price: 0.9627049999999999,
        is_closed_position: true,
        miner_hotkey: "5EUTaAo7vCGxvLDWRXRrEuqctPjt9fKZmgkaeFZocWECUe9X",
        net_leverage: 0.0,
        open_ms: 1718195850372,
        orders: [
          {
            leverage: 0.1,
            order_type: "LONG",
            order_uuid: "fafefc06-cd58-4177-9dc9-4629aa55954d",
            price: 0.9627049999999999,
            price_sources: [],
            processed_ms: 1718195850251,
          },
          {
            leverage: 0.05,
            order_type: "LONG",
            order_uuid: "e3cde803-a769-448e-9312-bc2d5ae86a04",
            price: 0.95193,
            price_sources: [],
            processed_ms: 1718704207914,
          },
          {
            leverage: 0.05,
            order_type: "LONG",
            order_uuid: "4e354559-4953-4a31-90f3-ca9e2c1875bc",
            price: 0.95178,
            price_sources: [],
            processed_ms: 1718706590842,
          },
          {
            leverage: 0.028466757775641057,
            order_type: "LONG",
            order_uuid: "255ddb88-5dce-4798-9b22-a01064238dc6",
            price: 0.94931,
            price_sources: [],
            processed_ms: 1718730131984,
          },
          {
            leverage: 0.016448808077363746,
            order_type: "LONG",
            order_uuid: "1d3efa07-3bfb-4dc2-b92b-df75144d2b3f",
            price: 0.949395,
            price_sources: [],
            processed_ms: 1718758935485,
          },
          {
            leverage: 0.08,
            order_type: "FLAT",
            order_uuid: "58e09a2c-3289-4dde-8cf9-31d49b350960",
            price: 0.95787,
            price_sources: [],
            processed_ms: 1719226120887,
          },
        ],
        position_type: "FLAT",
        position_uuid: "65f10eb4-3d3e-488a-ac9e-4aa011895303",
        return_at_close: 1.0005033377864272,
        trade_pair: ["EURCHF", "EUR/CHF", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 61346.68666666667,
        close_ms: 1719368463534,
        current_return: 1.0004483448794197,
        initial_entry_price: 61204.0,
        is_closed_position: true,
        miner_hotkey: "5EUTaAo7vCGxvLDWRXRrEuqctPjt9fKZmgkaeFZocWECUe9X",
        net_leverage: 0.0,
        open_ms: 1719226555451,
        orders: [
          {
            leverage: 0.02,
            order_type: "LONG",
            order_uuid: "54941361-63a2-450f-9de9-cb585b4a3fcf",
            price: 61204.0,
            price_sources: [],
            processed_ms: 1719226554144,
          },
          {
            leverage: -0.01,
            order_type: "SHORT",
            order_uuid: "0d162926-7dc6-4e73-9d75-f3561534989c",
            price: 60969.52,
            price_sources: [],
            processed_ms: 1719228120564,
          },
          {
            leverage: 0.02,
            order_type: "LONG",
            order_uuid: "60bd76b8-bba1-467a-a088-f4b11cb25a59",
            price: 61300.79,
            price_sources: [],
            processed_ms: 1719230782970,
          },
          {
            leverage: 0.02,
            order_type: "FLAT",
            order_uuid: "56cadc77-416a-43a8-8f4f-8ff1bcf61eac",
            price: 62261.37,
            price_sources: [],
            processed_ms: 1719368463534,
          },
        ],
        position_type: "FLAT",
        position_uuid: "54941361-63a2-450f-9de9-cb585b4a3fcf",
        return_at_close: 1.0004083269456245,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 1.2641,
        close_ms: 1719408139245,
        current_return: 1.0000417812890012,
        initial_entry_price: 1.2641,
        is_closed_position: true,
        miner_hotkey: "5EUTaAo7vCGxvLDWRXRrEuqctPjt9fKZmgkaeFZocWECUe9X",
        net_leverage: 0.0,
        open_ms: 1719200821893,
        orders: [
          {
            leverage: -0.08125496527164185,
            order_type: "SHORT",
            order_uuid: "c79a92cd-2106-4f04-a5ae-e455a1be4617",
            price: 1.2641,
            price_sources: [],
            processed_ms: 1719200820846,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "46d3282d-079e-4311-9a3b-e6e2fc71c29c",
            price: 1.26345,
            price_sources: [],
            processed_ms: 1719408139245,
          },
        ],
        position_type: "FLAT",
        position_uuid: "c79a92cd-2106-4f04-a5ae-e455a1be4617",
        return_at_close: 1.0000360932037866,
        trade_pair: ["GBPUSD", "GBP/USD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 1.2632050000000001,
        close_ms: 1719425582456,
        current_return: 1.0000246553468528,
        initial_entry_price: 1.2632050000000001,
        is_closed_position: true,
        miner_hotkey: "5EUTaAo7vCGxvLDWRXRrEuqctPjt9fKZmgkaeFZocWECUe9X",
        net_leverage: 0.0,
        open_ms: 1719424375752,
        orders: [
          {
            leverage: -0.08305268645618728,
            order_type: "SHORT",
            order_uuid: "d8b062f8-7399-403f-8b5d-6298ecf6d8fa",
            price: 1.2632050000000001,
            price_sources: [],
            processed_ms: 1719424375681,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "ed5637ef-b4e7-4f93-8295-3c7c1b151be9",
            price: 1.26283,
            price_sources: [],
            processed_ms: 1719425582456,
          },
        ],
        position_type: "FLAT",
        position_uuid: "d8b062f8-7399-403f-8b5d-6298ecf6d8fa",
        return_at_close: 1.0000188415154623,
        trade_pair: ["GBPUSD", "GBP/USD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.608117461426366,
        close_ms: 1719469957477,
        current_return: 1.0007816383469499,
        initial_entry_price: 0.6105,
        is_closed_position: true,
        miner_hotkey: "5EUTaAo7vCGxvLDWRXRrEuqctPjt9fKZmgkaeFZocWECUe9X",
        net_leverage: 0.0,
        open_ms: 1719391648009,
        orders: [
          {
            leverage: 0.05522406111061841,
            order_type: "LONG",
            order_uuid: "d586bd9b-1378-4936-a8f9-e792b048a239",
            price: 0.6105,
            price_sources: [],
            processed_ms: 1719391647869,
          },
          {
            leverage: 0.05548147088655604,
            order_type: "LONG",
            order_uuid: "1b4fbe57-5c3e-44e6-873f-be3590e712d3",
            price: 0.60886,
            price_sources: [],
            processed_ms: 1719406329151,
          },
          {
            leverage: 0.1097949336624092,
            order_type: "LONG",
            order_uuid: "ae72744e-9309-4eb6-85f0-fbfdbe82511f",
            price: 0.60819,
            price_sources: [],
            processed_ms: 1719439367503,
          },
          {
            leverage: 0.18302988967296055,
            order_type: "LONG",
            order_uuid: "fc8ee10f-a60c-44b1-8a68-98e62e5c231e",
            price: 0.60713,
            price_sources: [],
            processed_ms: 1719448995124,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "06581af7-9631-49ad-9acc-481a4aa570b6",
            price: 0.6093,
            price_sources: [],
            processed_ms: 1719469957477,
          },
        ],
        position_type: "FLAT",
        position_uuid: "d586bd9b-1378-4936-a8f9-e792b048a239",
        return_at_close: 1.0007533691430406,
        trade_pair: ["NZDUSD", "NZD/USD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 1.264327376365832,
        close_ms: 1719509038664,
        current_return: 0.9999504180299454,
        initial_entry_price: 1.26233,
        is_closed_position: true,
        miner_hotkey: "5EUTaAo7vCGxvLDWRXRrEuqctPjt9fKZmgkaeFZocWECUe9X",
        net_leverage: 0.0,
        open_ms: 1719452828824,
        orders: [
          {
            leverage: -0.029319606715502725,
            order_type: "SHORT",
            order_uuid: "1a7615a9-62de-45b1-a8f9-52c76dd25694",
            price: 1.26233,
            price_sources: [],
            processed_ms: 1719452828707,
          },
          {
            leverage: -0.10597138067972706,
            order_type: "SHORT",
            order_uuid: "c7b07136-4ec9-4841-8223-25eceeb3bd7c",
            price: 1.26488,
            price_sources: [],
            processed_ms: 1719508822069,
          },
          {
            leverage: 1.5,
            order_type: "FLAT",
            order_uuid: "5339221d-26fa-4760-9277-2c5c5b4ef22f",
            price: 1.26479,
            price_sources: [],
            processed_ms: 1719509038664,
          },
        ],
        position_type: "FLAT",
        position_uuid: "1a7615a9-62de-45b1-a8f9-52c76dd25694",
        return_at_close: 0.9999409481303874,
        trade_pair: ["GBPUSD", "GBP/USD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 117.396,
        close_ms: 1719509041730,
        current_return: 1.0000208676859694,
        initial_entry_price: 117.396,
        is_closed_position: true,
        miner_hotkey: "5EUTaAo7vCGxvLDWRXRrEuqctPjt9fKZmgkaeFZocWECUe9X",
        net_leverage: 0.0,
        open_ms: 1719508737675,
        orders: [
          {
            leverage: 0.1749844901474464,
            order_type: "LONG",
            order_uuid: "64030d32-23da-41d1-9bdc-96e3b88077a0",
            price: 117.396,
            price_sources: [],
            processed_ms: 1719508736872,
          },
          {
            leverage: 1.5,
            order_type: "FLAT",
            order_uuid: "0f9d49cc-1c49-493e-a077-fb929309462a",
            price: 117.41,
            price_sources: [],
            processed_ms: 1719509041730,
          },
        ],
        position_type: "FLAT",
        position_uuid: "64030d32-23da-41d1-9bdc-96e3b88077a0",
        return_at_close: 1.0000086185160526,
        trade_pair: ["CADJPY", "CAD/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.6087904969587533,
        close_ms: 1719509049787,
        current_return: 1.0000279621539716,
        initial_entry_price: 0.60885,
        is_closed_position: true,
        miner_hotkey: "5EUTaAo7vCGxvLDWRXRrEuqctPjt9fKZmgkaeFZocWECUe9X",
        net_leverage: 0.0,
        open_ms: 1719503001426,
        orders: [
          {
            leverage: 0.04383732230382976,
            order_type: "LONG",
            order_uuid: "ebcde759-e27f-470a-8f61-59bcd52d5335",
            price: 0.60885,
            price_sources: [],
            processed_ms: 1719503000704,
          },
          {
            leverage: 0.12726053794560016,
            order_type: "LONG",
            order_uuid: "f564583a-266e-469f-8d4e-e772fb35ea41",
            price: 0.60877,
            price_sources: [],
            processed_ms: 1719508822533,
          },
          {
            leverage: 1.5,
            order_type: "FLAT",
            order_uuid: "ab240a29-950d-4219-9235-4289c8505bc5",
            price: 0.60889,
            price_sources: [],
            processed_ms: 1719509049787,
          },
        ],
        position_type: "FLAT",
        position_uuid: "ebcde759-e27f-470a-8f61-59bcd52d5335",
        return_at_close: 1.0000159849688557,
        trade_pair: ["NZDUSD", "NZD/USD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 1.46496,
        close_ms: 1719509058856,
        current_return: 0.999994066315952,
        initial_entry_price: 1.46496,
        is_closed_position: true,
        miner_hotkey: "5EUTaAo7vCGxvLDWRXRrEuqctPjt9fKZmgkaeFZocWECUe9X",
        net_leverage: 0.0,
        open_ms: 1719508859527,
        orders: [
          {
            leverage: 0.2483602795137858,
            order_type: "LONG",
            order_uuid: "afca6f1a-bbfb-4f1d-9650-2dc5fd08b467",
            price: 1.46496,
            price_sources: [],
            processed_ms: 1719508858754,
          },
          {
            leverage: 1.5,
            order_type: "FLAT",
            order_uuid: "6f5a8010-15a2-4480-9129-86674fdf0385",
            price: 1.464925,
            price_sources: [],
            processed_ms: 1719509058856,
          },
        ],
        position_type: "FLAT",
        position_uuid: "afca6f1a-bbfb-4f1d-9650-2dc5fd08b467",
        return_at_close: 0.9999766811995444,
        trade_pair: ["EURCAD", "EUR/CAD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 1.27581,
        close_ms: 1720149894524,
        current_return: 1.0002099748263584,
        initial_entry_price: 1.27581,
        is_closed_position: true,
        miner_hotkey: "5EUTaAo7vCGxvLDWRXRrEuqctPjt9fKZmgkaeFZocWECUe9X",
        net_leverage: 0.0,
        open_ms: 1720127449352,
        orders: [
          {
            leverage: 0.24353453019680613,
            order_type: "LONG",
            order_uuid: "5127298d-d7de-4028-88a8-cdc1b22957f9",
            price: 1.27581,
            price_sources: [
              {
                close: 1.27581,
                high: 1.27581,
                lag_ms: 1905,
                low: 1.27581,
                open: 1.27581,
                source: "TwelveData_ws",
                start_ms: 1720127449000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
              {
                close: 1.27584,
                high: 1.27584,
                lag_ms: 15905,
                low: 1.27584,
                open: 1.27584,
                source: "Polygon_ws",
                start_ms: 1720127463000,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 1.27584,
                websocket: true,
              },
              {
                close: 1.27615,
                high: 1.27615,
                lag_ms: 227096,
                low: 1.27615,
                open: 1.27615,
                source: "TwelveData_rest",
                start_ms: 1720127160000,
                timespan_ms: 60000,
                volume: null,
                vwap: null,
                websocket: false,
              },
            ],
            processed_ms: 1720127447095,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "e4fd1756-06b9-460a-8203-91c85366d07c",
            price: 1.27691,
            price_sources: [
              {
                close: 1.27691,
                high: 1.27691,
                lag_ms: 475,
                low: 1.27691,
                open: 1.27691,
                source: "Polygon_ws",
                start_ms: 1720149894999,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 1.27691,
                websocket: true,
              },
              {
                close: 1.27697,
                high: 1.27697,
                lag_ms: 524,
                low: 1.27697,
                open: 1.27697,
                source: "TwelveData_ws",
                start_ms: 1720149894000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1720149894524,
          },
        ],
        position_type: "FLAT",
        position_uuid: "5127298d-d7de-4028-88a8-cdc1b22957f9",
        return_at_close: 1.0001929238297163,
        trade_pair: ["GBPUSD", "GBP/USD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 1.28012,
        close_ms: 1720390499328,
        current_return: 1.000256942998512,
        initial_entry_price: 1.28012,
        is_closed_position: true,
        miner_hotkey: "5EUTaAo7vCGxvLDWRXRrEuqctPjt9fKZmgkaeFZocWECUe9X",
        net_leverage: 0.0,
        open_ms: 1720387209930,
        orders: [
          {
            leverage: 0.2491802054963167,
            order_type: "LONG",
            order_uuid: "40099f04-2456-4e3f-807e-11d56cd358e1",
            price: 1.28012,
            price_sources: [
              {
                close: 1.28012,
                high: 1.28012,
                lag_ms: 634,
                low: 1.28012,
                open: 1.28012,
                source: "TwelveData_ws",
                start_ms: 1720387210000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
              {
                close: 1.28019,
                high: 1.28019,
                lag_ms: 6367,
                low: 1.28019,
                open: 1.28019,
                source: "Polygon_ws",
                start_ms: 1720387202999,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 1.28019,
                websocket: true,
              },
              {
                close: 1.28019,
                high: 1.28019,
                lag_ms: 6367,
                low: 1.28019,
                open: 1.28019,
                source: "Polygon_rest",
                start_ms: 1720387202000,
                timespan_ms: 1000,
                volume: 1.0,
                vwap: null,
                websocket: false,
              },
              {
                close: 1.28145,
                high: 1.2816,
                lag_ms: 174009367,
                low: 1.2814,
                open: 1.28155,
                source: "TwelveData_rest",
                start_ms: 1720213140000,
                timespan_ms: 60000,
                volume: null,
                vwap: null,
                websocket: false,
              },
            ],
            processed_ms: 1720387209366,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "3b1b61c7-3b70-403d-beb9-ce7a2e681d1f",
            price: 1.28144,
            price_sources: [
              {
                close: 1.28144,
                high: 1.28144,
                lag_ms: 329,
                low: 1.28144,
                open: 1.28144,
                source: "Polygon_ws",
                start_ms: 1720390498999,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 1.28144,
                websocket: true,
              },
              {
                close: 1.28143,
                high: 1.28143,
                lag_ms: 1672,
                low: 1.28143,
                open: 1.28143,
                source: "TwelveData_ws",
                start_ms: 1720390501000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1720390499328,
          },
        ],
        position_type: "FLAT",
        position_uuid: "40099f04-2456-4e3f-807e-11d56cd358e1",
        return_at_close: 1.0002394959023695,
        trade_pair: ["GBPUSD", "GBP/USD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 1.280885,
        close_ms: 1720425910512,
        current_return: 1.000164783822786,
        initial_entry_price: 1.280885,
        is_closed_position: true,
        miner_hotkey: "5EUTaAo7vCGxvLDWRXRrEuqctPjt9fKZmgkaeFZocWECUe9X",
        net_leverage: 0.0,
        open_ms: 1720418561129,
        orders: [
          {
            leverage: 0.24542921726685205,
            order_type: "LONG",
            order_uuid: "6ba77e02-8fca-4ef3-b54e-fddca15c9e3f",
            price: 1.280885,
            price_sources: [
              {
                close: 1.280885,
                high: 1.280885,
                lag_ms: 164,
                low: 1.280885,
                open: 1.280885,
                source: "Polygon_ws",
                start_ms: 1720418560999,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 1.28089,
                websocket: true,
              },
              {
                close: 1.28087,
                high: 1.28087,
                lag_ms: 165,
                low: 1.28087,
                open: 1.28087,
                source: "TwelveData_ws",
                start_ms: 1720418561000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1720418560835,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "0817bbab-5d72-487b-91d7-5ea6a548e2ef",
            price: 1.281745,
            price_sources: [
              {
                close: 1.281745,
                high: 1.281745,
                lag_ms: 487,
                low: 1.281745,
                open: 1.281745,
                source: "Polygon_ws",
                start_ms: 1720425910999,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 1.28178,
                websocket: true,
              },
              {
                close: 1.28177,
                high: 1.28177,
                lag_ms: 488,
                low: 1.28177,
                open: 1.28177,
                source: "TwelveData_ws",
                start_ms: 1720425911000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1720425910512,
          },
        ],
        position_type: "FLAT",
        position_uuid: "6ba77e02-8fca-4ef3-b54e-fddca15c9e3f",
        return_at_close: 1.000147600946584,
        trade_pair: ["GBPUSD", "GBP/USD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 1.28156,
        close_ms: 1720488304193,
        current_return: 1.0001928579978965,
        initial_entry_price: 1.28156,
        is_closed_position: true,
        miner_hotkey: "5EUTaAo7vCGxvLDWRXRrEuqctPjt9fKZmgkaeFZocWECUe9X",
        net_leverage: 0.0,
        open_ms: 1720426941369,
        orders: [
          {
            leverage: -0.24965565230728837,
            order_type: "SHORT",
            order_uuid: "961ca87c-124b-4180-b03e-740550288ee4",
            price: 1.28156,
            price_sources: [
              {
                close: 1.28156,
                high: 1.28156,
                lag_ms: 127,
                low: 1.28156,
                open: 1.28156,
                source: "Polygon_ws",
                start_ms: 1720426940999,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 1.28156,
                websocket: true,
              },
              {
                close: 1.28158,
                high: 1.28158,
                lag_ms: 128,
                low: 1.28158,
                open: 1.28158,
                source: "TwelveData_ws",
                start_ms: 1720426941000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1720426940872,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "6000856f-62f1-43ec-901f-4139d0e8a14a",
            price: 1.28057,
            price_sources: [
              {
                close: 1.28057,
                high: 1.28057,
                lag_ms: 193,
                low: 1.28057,
                open: 1.28057,
                source: "Polygon_ws",
                start_ms: 1720488304000,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 1.28057,
                websocket: true,
              },
              {
                close: 1.28057,
                high: 1.28057,
                lag_ms: 193,
                low: 1.28057,
                open: 1.28057,
                source: "TwelveData_ws",
                start_ms: 1720488304000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1720488304193,
          },
        ],
        position_type: "FLAT",
        position_uuid: "961ca87c-124b-4180-b03e-740550288ee4",
        return_at_close: 1.0001753787318688,
        trade_pair: ["GBPUSD", "GBP/USD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 1.28059,
        close_ms: 1720511330739,
        current_return: 1.0001780521833874,
        initial_entry_price: 1.28059,
        is_closed_position: true,
        miner_hotkey: "5EUTaAo7vCGxvLDWRXRrEuqctPjt9fKZmgkaeFZocWECUe9X",
        net_leverage: 0.0,
        open_ms: 1720499610187,
        orders: [
          {
            leverage: -0.23751233908757086,
            order_type: "SHORT",
            order_uuid: "622a5d2c-c692-439c-9547-a6ee36b17f69",
            price: 1.28059,
            price_sources: [
              {
                close: 1.28059,
                high: 1.28059,
                lag_ms: 60,
                low: 1.28059,
                open: 1.28059,
                source: "Polygon_ws",
                start_ms: 1720499610000,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 1.28058,
                websocket: true,
              },
              {
                close: 1.28058,
                high: 1.28058,
                lag_ms: 60,
                low: 1.28058,
                open: 1.28058,
                source: "TwelveData_ws",
                start_ms: 1720499610000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1720499610060,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "0f7c18d7-c488-4e1d-91de-8a4120da4ef6",
            price: 1.27963,
            price_sources: [
              {
                close: 1.27963,
                high: 1.27963,
                lag_ms: 260,
                low: 1.27963,
                open: 1.27963,
                source: "Polygon_ws",
                start_ms: 1720511330999,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 1.27963,
                websocket: true,
              },
              {
                close: 1.2797,
                high: 1.2797,
                lag_ms: 1739,
                low: 1.2797,
                open: 1.2797,
                source: "TwelveData_ws",
                start_ms: 1720511329000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1720511330739,
          },
        ],
        position_type: "FLAT",
        position_uuid: "622a5d2c-c692-439c-9547-a6ee36b17f69",
        return_at_close: 1.00016142335938,
        trade_pair: ["GBPUSD", "GBP/USD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 1.2801399999999998,
        close_ms: 1720516591194,
        current_return: 1.0001247615642292,
        initial_entry_price: 1.2801399999999998,
        is_closed_position: true,
        miner_hotkey: "5EUTaAo7vCGxvLDWRXRrEuqctPjt9fKZmgkaeFZocWECUe9X",
        net_leverage: 0.0,
        open_ms: 1720512814570,
        orders: [
          {
            leverage: 0.24198828610967987,
            order_type: "LONG",
            order_uuid: "b51a08fc-4c05-46a1-864d-b921fb1e58f4",
            price: 1.2801399999999998,
            price_sources: [
              {
                close: 1.2801399999999998,
                high: 1.2801399999999998,
                lag_ms: 498,
                low: 1.2801399999999998,
                open: 1.2801399999999998,
                source: "Polygon_ws",
                start_ms: 1720512814999,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 1.28013,
                websocket: true,
              },
              {
                close: 1.2801,
                high: 1.2801,
                lag_ms: 499,
                low: 1.2801,
                open: 1.2801,
                source: "TwelveData_ws",
                start_ms: 1720512815000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1720512814501,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "298b2900-2cb0-4e20-826d-a130f2a2d65f",
            price: 1.2808,
            price_sources: [
              {
                close: 1.2808,
                high: 1.2808,
                lag_ms: 194,
                low: 1.2808,
                open: 1.2808,
                source: "TwelveData_ws",
                start_ms: 1720516591000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
              {
                close: 1.28065,
                high: 1.28065,
                lag_ms: 151195,
                low: 1.28065,
                open: 1.28065,
                source: "TwelveData_rest",
                start_ms: 1720516380000,
                timespan_ms: 60000,
                volume: null,
                vwap: null,
                websocket: false,
              },
              {
                close: 1.28066,
                high: 1.28066,
                lag_ms: 329195,
                low: 1.28066,
                open: 1.28066,
                source: "Polygon_ws",
                start_ms: 1720516261999,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 1.28067,
                websocket: true,
              },
            ],
            processed_ms: 1720516591194,
          },
        ],
        position_type: "FLAT",
        position_uuid: "b51a08fc-4c05-46a1-864d-b921fb1e58f4",
        return_at_close: 1.000107820270843,
        trade_pair: ["GBPUSD", "GBP/USD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.61482,
        close_ms: 1720583593464,
        current_return: 1.0036168949123174,
        initial_entry_price: 0.61482,
        is_closed_position: true,
        miner_hotkey: "5EUTaAo7vCGxvLDWRXRrEuqctPjt9fKZmgkaeFZocWECUe9X",
        net_leverage: 0.0,
        open_ms: 1720209439169,
        orders: [
          {
            leverage: -0.39995311690485896,
            order_type: "SHORT",
            order_uuid: "fd2df0be-aa15-4bee-a61a-eaf9ae6456da",
            price: 0.61482,
            price_sources: [
              {
                close: 0.61482,
                high: 0.61482,
                lag_ms: 39,
                low: 0.61482,
                open: 0.61482,
                source: "Polygon_ws",
                start_ms: 1720209437999,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 0.61482,
                websocket: true,
              },
              {
                close: 0.6148,
                high: 0.6148,
                lag_ms: 1040,
                low: 0.6148,
                open: 0.6148,
                source: "TwelveData_ws",
                start_ms: 1720209439000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1720209437960,
          },
          {
            leverage: 0.02,
            order_type: "FLAT",
            order_uuid: "8a543ee2-3d6e-4909-b265-bd57f537a45b",
            price: 0.60926,
            price_sources: [
              {
                close: 0.60926,
                high: 0.60926,
                lag_ms: 464,
                low: 0.60926,
                open: 0.60926,
                source: "TwelveData_ws",
                start_ms: 1720583593000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
              {
                close: 0.60931,
                high: 0.60931,
                lag_ms: 1465,
                low: 0.60931,
                open: 0.60931,
                source: "Polygon_ws",
                start_ms: 1720583591999,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 0.6093,
                websocket: true,
              },
            ],
            processed_ms: 1720583593464,
          },
        ],
        position_type: "FLAT",
        position_uuid: "fd2df0be-aa15-4bee-a61a-eaf9ae6456da",
        return_at_close: 1.0035887969329464,
        trade_pair: ["NZDUSD", "NZD/USD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 1.36364,
        close_ms: 1720615482999,
        current_return: 1.0001588944798592,
        initial_entry_price: 1.36364,
        is_closed_position: true,
        miner_hotkey: "5EUTaAo7vCGxvLDWRXRrEuqctPjt9fKZmgkaeFZocWECUe9X",
        net_leverage: 0.0,
        open_ms: 1720487180699,
        orders: [
          {
            leverage: -0.2381042511157166,
            order_type: "SHORT",
            order_uuid: "30708231-b5b1-4176-91ea-6cd892bf3463",
            price: 1.36364,
            price_sources: [
              {
                close: 1.36364,
                high: 1.36364,
                lag_ms: 1238,
                low: 1.36364,
                open: 1.36364,
                source: "Polygon_ws",
                start_ms: 1720487179000,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 1.36364,
                websocket: true,
              },
              {
                close: 1.36362,
                high: 1.36362,
                lag_ms: 2238,
                low: 1.36362,
                open: 1.36362,
                source: "TwelveData_ws",
                start_ms: 1720487180000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
              {
                close: 1.36361,
                high: 1.36361,
                lag_ms: 2763,
                low: 1.36361,
                open: 1.36361,
                source: "Polygon_rest",
                start_ms: 1720487174000,
                timespan_ms: 1000,
                volume: 1.0,
                vwap: null,
                websocket: false,
              },
              {
                close: 1.36375,
                high: 1.36375,
                lag_ms: 497763,
                low: 1.36365,
                open: 1.3637,
                source: "TwelveData_rest",
                start_ms: 1720486620000,
                timespan_ms: 60000,
                volume: null,
                vwap: null,
                websocket: false,
              },
            ],
            processed_ms: 1720487177762,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "399e3076-73bb-4839-8296-c5009e2e85a7",
            price: 1.36273,
            price_sources: [
              {
                close: 1.36273,
                high: 1.36273,
                lag_ms: 0,
                low: 1.36273,
                open: 1.36273,
                source: "Polygon_ws",
                start_ms: 1720615482999,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 1.36273,
                websocket: true,
              },
              {
                close: 1.36277,
                high: 1.36277,
                lag_ms: 999,
                low: 1.36277,
                open: 1.36277,
                source: "TwelveData_ws",
                start_ms: 1720615482000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1720615482999,
          },
        ],
        position_type: "FLAT",
        position_uuid: "30708231-b5b1-4176-91ea-6cd892bf3463",
        return_at_close: 1.0001422245339395,
        trade_pair: ["USDCAD", "USD/CAD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.6079300000000001,
        close_ms: 0,
        current_return: 0.9999726853267145,
        initial_entry_price: 0.6079300000000001,
        is_closed_position: false,
        miner_hotkey: "5EUTaAo7vCGxvLDWRXRrEuqctPjt9fKZmgkaeFZocWECUe9X",
        net_leverage: -0.23722013329311117,
        open_ms: 1720614799250,
        orders: [
          {
            leverage: -0.23722013329311117,
            order_type: "SHORT",
            order_uuid: "93144ccf-8d40-4135-9b90-7ced87748d1b",
            price: 0.6079300000000001,
            price_sources: [
              {
                close: 0.6079300000000001,
                high: 0.6079300000000001,
                lag_ms: 112,
                low: 0.6079300000000001,
                open: 0.6079300000000001,
                source: "Polygon_ws",
                start_ms: 1720614798000,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 0.60806,
                websocket: true,
              },
              {
                close: 0.60801,
                high: 0.60801,
                lag_ms: 888,
                low: 0.60801,
                open: 0.60801,
                source: "TwelveData_ws",
                start_ms: 1720614797000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1720614797888,
          },
        ],
        position_type: "SHORT",
        position_uuid: "93144ccf-8d40-4135-9b90-7ced87748d1b",
        return_at_close: 0.9999643828488349,
        trade_pair: ["NZDUSD", "NZD/USD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 56868.52,
        close_ms: 0,
        current_return: 0.999553890271806,
        initial_entry_price: 56868.52,
        is_closed_position: false,
        miner_hotkey: "5EUTaAo7vCGxvLDWRXRrEuqctPjt9fKZmgkaeFZocWECUe9X",
        net_leverage: -0.02,
        open_ms: 1720373158416,
        orders: [
          {
            leverage: -0.02,
            order_type: "SHORT",
            order_uuid: "596279e9-56a2-4c9f-a97a-ff631e0d3942",
            price: 56868.52,
            price_sources: [
              {
                close: 56868.52,
                high: 56868.75,
                lag_ms: 255,
                low: 56868.52,
                open: 56868.52,
                source: "Polygon_ws",
                start_ms: 1720373157999,
                timespan_ms: 0,
                volume: 0.00015111,
                vwap: 56868.6478,
                websocket: true,
              },
              {
                close: 56865.04,
                high: 56882.2,
                lag_ms: 2745,
                low: 56865.04,
                open: 56882.2,
                source: "Polygon_rest",
                start_ms: 1720373154000,
                timespan_ms: 1000,
                volume: 0.00207706,
                vwap: 56879.3496,
                websocket: false,
              },
              {
                close: 56867.0,
                high: 56867.0,
                lag_ms: 3744,
                low: 56867.0,
                open: 56867.0,
                source: "TwelveData_ws",
                start_ms: 1720373154000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
              {
                close: 56889.13,
                high: 56902.83,
                lag_ms: 57745,
                low: 56869.14,
                open: 56873.29,
                source: "TwelveData_rest",
                start_ms: 1720373040000,
                timespan_ms: 60000,
                volume: null,
                vwap: null,
                websocket: false,
              },
            ],
            processed_ms: 1720373157744,
          },
        ],
        position_type: "SHORT",
        position_uuid: "596279e9-56a2-4c9f-a97a-ff631e0d3942",
        return_at_close: 0.9995438947329033,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 1.08445,
        close_ms: 0,
        current_return: 1.0011161911885937,
        initial_entry_price: 1.08445,
        is_closed_position: false,
        miner_hotkey: "5EUTaAo7vCGxvLDWRXRrEuqctPjt9fKZmgkaeFZocWECUe9X",
        net_leverage: -0.5791643705600918,
        open_ms: 1720443814193,
        orders: [
          {
            leverage: -0.5791643705600918,
            order_type: "SHORT",
            order_uuid: "b0a7efab-f40e-43d8-b4ba-bf63525e8b81",
            price: 1.08445,
            price_sources: [
              {
                close: 1.08445,
                high: 1.08445,
                lag_ms: 5,
                low: 1.08445,
                open: 1.08445,
                source: "Polygon_ws",
                start_ms: 1720443814000,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 1.08445,
                websocket: true,
              },
              {
                close: 1.0845,
                high: 1.0845,
                lag_ms: 995,
                low: 1.0845,
                open: 1.0845,
                source: "TwelveData_ws",
                start_ms: 1720443815000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1720443814005,
          },
        ],
        position_type: "SHORT",
        position_uuid: "b0a7efab-f40e-43d8-b4ba-bf63525e8b81",
        return_at_close: 1.0010958978095883,
        trade_pair: ["EURUSD", "EUR/USD", 7e-5, 0.001, 500],
      },
    ],
    thirty_day_returns: 1.0021303860633755,
  },
  "5EUXGiE1vL3LpkJnrBX2gowcUdc6YeYZkmuHD9DuTaPT9Xx5": {
    all_time_returns: 1.0068782175384374,
    n_positions: 33,
    percentage_profitable: 0.9393939393939394,
    positions: [
      {
        average_entry_price: 0.612955,
        close_ms: 1717152136397,
        current_return: 1.00061046085725,
        initial_entry_price: 0.61429,
        is_closed_position: true,
        miner_hotkey: "5EUXGiE1vL3LpkJnrBX2gowcUdc6YeYZkmuHD9DuTaPT9Xx5",
        net_leverage: 0.0,
        open_ms: 1716926517518,
        orders: [
          {
            leverage: 0.5,
            order_type: "LONG",
            order_uuid: "04f4d348-928e-4137-a318-d76d88b9f669",
            price: 0.61429,
            price_sources: [],
            processed_ms: 1716926517501,
          },
          {
            leverage: 0.5,
            order_type: "LONG",
            order_uuid: "ce80b4e2-54c7-42f1-aa37-0c06b162800a",
            price: 0.61162,
            price_sources: [],
            processed_ms: 1717008211842,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "1225b406-30cc-4716-a52a-2da3c4ec7cdc",
            price: 0.61333,
            price_sources: [],
            processed_ms: 1717152136397,
          },
        ],
        position_type: "FLAT",
        position_uuid: "6e2b4247-2f32-4fa3-a382-6e1b9cd34b01",
        return_at_close: 1.00054041812499,
        trade_pair: ["NZDUSD", "NZD/USD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 1.27977,
        close_ms: 1717489826474,
        current_return: 1.0017406169780507,
        initial_entry_price: 1.27977,
        is_closed_position: true,
        miner_hotkey: "5EUXGiE1vL3LpkJnrBX2gowcUdc6YeYZkmuHD9DuTaPT9Xx5",
        net_leverage: 0.0,
        open_ms: 1717441376338,
        orders: [
          {
            leverage: -1.007959,
            order_type: "SHORT",
            order_uuid: "01e9f18e-fdf7-4220-847e-5dffa31ed872",
            price: 1.27977,
            price_sources: [],
            processed_ms: 1717441375501,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "4622ce4d-5119-4df7-8810-c8dbd9715b6f",
            price: 1.27756,
            price_sources: [],
            processed_ms: 1717489826474,
          },
        ],
        position_type: "FLAT",
        position_uuid: "01e9f18e-fdf7-4220-847e-5dffa31ed872",
        return_at_close: 1.0016699370351123,
        trade_pair: ["GBPUSD", "GBP/USD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.66701,
        close_ms: 1717490399756,
        current_return: 1.0015678272064885,
        initial_entry_price: 0.66701,
        is_closed_position: true,
        miner_hotkey: "5EUXGiE1vL3LpkJnrBX2gowcUdc6YeYZkmuHD9DuTaPT9Xx5",
        net_leverage: 0.0,
        open_ms: 1717434141599,
        orders: [
          {
            leverage: -0.503979,
            order_type: "SHORT",
            order_uuid: "260c9b6e-5814-4015-b4ff-f1bb0e6f9553",
            price: 0.66701,
            price_sources: [],
            processed_ms: 1717434141597,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "89969920-c16c-4c23-9987-61ab46de46a0",
            price: 0.664935,
            price_sources: [],
            processed_ms: 1717490399756,
          },
        ],
        position_type: "FLAT",
        position_uuid: "260c9b6e-5814-4015-b4ff-f1bb0e6f9553",
        return_at_close: 1.0015324933658494,
        trade_pair: ["AUDUSD", "AUD/USD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.65255,
        close_ms: 1717501368373,
        current_return: 1.0012782305263965,
        initial_entry_price: 0.65255,
        is_closed_position: true,
        miner_hotkey: "5EUXGiE1vL3LpkJnrBX2gowcUdc6YeYZkmuHD9DuTaPT9Xx5",
        net_leverage: 0.0,
        open_ms: 1717496208802,
        orders: [
          {
            leverage: 1.004951,
            order_type: "LONG",
            order_uuid: "be75a546-78c8-453f-9c93-ad9d0c68afbf",
            price: 0.65255,
            price_sources: [],
            processed_ms: 1717496208796,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "781657fb-8742-49d7-ad38-f17b45345e73",
            price: 0.65338,
            price_sources: [],
            processed_ms: 1717501368373,
          },
        ],
        position_type: "FLAT",
        position_uuid: "be75a546-78c8-453f-9c93-ad9d0c68afbf",
        return_at_close: 1.0012077940372632,
        trade_pair: ["CADCHF", "CAD/CHF", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 1.48727,
        close_ms: 1717502344405,
        current_return: 1.0010845013716407,
        initial_entry_price: 1.48727,
        is_closed_position: true,
        miner_hotkey: "5EUXGiE1vL3LpkJnrBX2gowcUdc6YeYZkmuHD9DuTaPT9Xx5",
        net_leverage: 0.0,
        open_ms: 1717500029821,
        orders: [
          {
            leverage: -1.004951,
            order_type: "SHORT",
            order_uuid: "2caabb5e-499e-4b89-9e5e-9c7f9608faf0",
            price: 1.48727,
            price_sources: [],
            processed_ms: 1717500029819,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "83b6b6d2-8d8d-42d7-9430-c055f090f31a",
            price: 1.485665,
            price_sources: [],
            processed_ms: 1717502344405,
          },
        ],
        position_type: "FLAT",
        position_uuid: "2caabb5e-499e-4b89-9e5e-9c7f9608faf0",
        return_at_close: 1.0010140785106891,
        trade_pair: ["EURCAD", "EUR/CAD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.616835,
        close_ms: 1717509615360,
        current_return: 1.0015936202712232,
        initial_entry_price: 0.616835,
        is_closed_position: true,
        miner_hotkey: "5EUXGiE1vL3LpkJnrBX2gowcUdc6YeYZkmuHD9DuTaPT9Xx5",
        net_leverage: 0.0,
        open_ms: 1717503321569,
        orders: [
          {
            leverage: 1.003062,
            order_type: "LONG",
            order_uuid: "5da34394-4307-4950-bb06-4be2912ab993",
            price: 0.616835,
            price_sources: [],
            processed_ms: 1717503321560,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "4afdf64c-67bb-4549-a7d1-eec592233222",
            price: 0.617815,
            price_sources: [],
            processed_ms: 1717509615360,
          },
        ],
        position_type: "FLAT",
        position_uuid: "5da34394-4307-4950-bb06-4be2912ab993",
        return_at_close: 1.0015232940362278,
        trade_pair: ["NZDUSD", "NZD/USD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.6514689610665053,
        close_ms: 1717567022749,
        current_return: 1.0002336059492363,
        initial_entry_price: 0.653025,
        is_closed_position: true,
        miner_hotkey: "5EUXGiE1vL3LpkJnrBX2gowcUdc6YeYZkmuHD9DuTaPT9Xx5",
        net_leverage: 0.0,
        open_ms: 1717501911443,
        orders: [
          {
            leverage: 1.004049,
            order_type: "LONG",
            order_uuid: "d52bc5d3-6ab4-4e06-9697-390e646f3541",
            price: 0.653025,
            price_sources: [],
            processed_ms: 1717501911437,
          },
          {
            leverage: 1.002167,
            order_type: "LONG",
            order_uuid: "f9d590e8-0f06-4ca3-b8b2-cc9aceed7c31",
            price: 0.64991,
            price_sources: [],
            processed_ms: 1717519801245,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "4172d182-3785-4cd5-b5ce-128ff5483e67",
            price: 0.651545,
            price_sources: [],
            processed_ms: 1717567022749,
          },
        ],
        position_type: "FLAT",
        position_uuid: "d52bc5d3-6ab4-4e06-9697-390e646f3541",
        return_at_close: 1.000093138022757,
        trade_pair: ["CADCHF", "CAD/CHF", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.9694,
        close_ms: 1717572639071,
        current_return: 1.000583767278729,
        initial_entry_price: 0.9694,
        is_closed_position: true,
        miner_hotkey: "5EUXGiE1vL3LpkJnrBX2gowcUdc6YeYZkmuHD9DuTaPT9Xx5",
        net_leverage: 0.0,
        open_ms: 1717568072610,
        orders: [
          {
            leverage: 0.5008,
            order_type: "LONG",
            order_uuid: "ad50f66b-ef86-499c-872f-68375bf21612",
            price: 0.9694,
            price_sources: [],
            processed_ms: 1717568072608,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "7df97788-50fe-4afd-83bf-5271289c1efb",
            price: 0.97053,
            price_sources: [],
            processed_ms: 1717572639071,
          },
        ],
        position_type: "FLAT",
        position_uuid: "ad50f66b-ef86-499c-872f-68375bf21612",
        return_at_close: 1.0005486908141834,
        trade_pair: ["EURCHF", "EUR/CHF", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.651725,
        close_ms: 1717573248908,
        current_return: 1.0008106854885113,
        initial_entry_price: 0.651725,
        is_closed_position: true,
        miner_hotkey: "5EUXGiE1vL3LpkJnrBX2gowcUdc6YeYZkmuHD9DuTaPT9Xx5",
        net_leverage: 0.0,
        open_ms: 1717567509214,
        orders: [
          {
            leverage: 0.5008,
            order_type: "LONG",
            order_uuid: "d831ca50-ecdf-4004-8bbc-bd380092dcb1",
            price: 0.651725,
            price_sources: [],
            processed_ms: 1717567509206,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "0095f783-35dc-4630-92a3-4324a2528ead",
            price: 0.65278,
            price_sources: [],
            processed_ms: 1717573248908,
          },
        ],
        position_type: "FLAT",
        position_uuid: "d831ca50-ecdf-4004-8bbc-bd380092dcb1",
        return_at_close: 1.000775601069121,
        trade_pair: ["CADCHF", "CAD/CHF", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.651765,
        close_ms: 1717587076823,
        current_return: 1.0003914299785965,
        initial_entry_price: 0.651765,
        is_closed_position: true,
        miner_hotkey: "5EUXGiE1vL3LpkJnrBX2gowcUdc6YeYZkmuHD9DuTaPT9Xx5",
        net_leverage: 0.0,
        open_ms: 1717581964317,
        orders: [
          {
            leverage: 0.500236,
            order_type: "LONG",
            order_uuid: "6510f835-b31d-425f-b9c4-f36a16908f28",
            price: 0.651765,
            price_sources: [],
            processed_ms: 1717581964304,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "b12322cb-e961-4c11-a874-7a556e51d3d9",
            price: 0.6522749999999999,
            price_sources: [],
            processed_ms: 1717587076823,
          },
        ],
        position_type: "FLAT",
        position_uuid: "6510f835-b31d-425f-b9c4-f36a16908f28",
        return_at_close: 1.0003563997520808,
        trade_pair: ["CADCHF", "CAD/CHF", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.96977,
        close_ms: 1717590135688,
        current_return: 1.0003868721449416,
        initial_entry_price: 0.96977,
        is_closed_position: true,
        miner_hotkey: "5EUXGiE1vL3LpkJnrBX2gowcUdc6YeYZkmuHD9DuTaPT9Xx5",
        net_leverage: 0.0,
        open_ms: 1717582193711,
        orders: [
          {
            leverage: 0.500236,
            order_type: "LONG",
            order_uuid: "1f84523e-484e-4fe6-9d30-ed4be7f89471",
            price: 0.96977,
            price_sources: [],
            processed_ms: 1717582191724,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "14bc8fa3-c1e7-4c41-b3d7-bc7fee4fd4db",
            price: 0.97052,
            price_sources: [],
            processed_ms: 1717590135688,
          },
        ],
        position_type: "FLAT",
        position_uuid: "1f84523e-484e-4fe6-9d30-ed4be7f89471",
        return_at_close: 1.0003518420780253,
        trade_pair: ["EURCHF", "EUR/CHF", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.97065,
        close_ms: 1717634247314,
        current_return: 1.0001339024777212,
        initial_entry_price: 0.97065,
        is_closed_position: true,
        miner_hotkey: "5EUXGiE1vL3LpkJnrBX2gowcUdc6YeYZkmuHD9DuTaPT9Xx5",
        net_leverage: 0.0,
        open_ms: 1717628725698,
        orders: [
          {
            leverage: -0.499894,
            order_type: "SHORT",
            order_uuid: "75176d4a-b772-408a-8f07-0a86832705c6",
            price: 0.97065,
            price_sources: [],
            processed_ms: 1717628725684,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "241d3d63-94f8-4c31-9f15-a6ccab5bb2ed",
            price: 0.97039,
            price_sources: [],
            processed_ms: 1717634247314,
          },
        ],
        position_type: "FLAT",
        position_uuid: "75176d4a-b772-408a-8f07-0a86832705c6",
        return_at_close: 1.000098905212128,
        trade_pair: ["EURCHF", "EUR/CHF", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 1.49073,
        close_ms: 1717681510109,
        current_return: 1.0006270100353518,
        initial_entry_price: 1.49073,
        is_closed_position: true,
        miner_hotkey: "5EUXGiE1vL3LpkJnrBX2gowcUdc6YeYZkmuHD9DuTaPT9Xx5",
        net_leverage: 0.0,
        open_ms: 1717678109053,
        orders: [
          {
            leverage: -0.999682,
            order_type: "SHORT",
            order_uuid: "9dccf373-7c03-4d59-a663-0a1d4c3580b9",
            price: 1.49073,
            price_sources: [],
            processed_ms: 1717678108862,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "a5cbd5c7-8784-44bc-aea5-e37c552d0516",
            price: 1.489795,
            price_sources: [],
            processed_ms: 1717681510109,
          },
        ],
        position_type: "FLAT",
        position_uuid: "9dccf373-7c03-4d59-a663-0a1d4c3580b9",
        return_at_close: 1.0005569884186067,
        trade_pair: ["EURCAD", "EUR/CAD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 1.49013,
        close_ms: 1717701050133,
        current_return: 1.000971971841383,
        initial_entry_price: 1.49013,
        is_closed_position: true,
        miner_hotkey: "5EUXGiE1vL3LpkJnrBX2gowcUdc6YeYZkmuHD9DuTaPT9Xx5",
        net_leverage: 0.0,
        open_ms: 1717682642948,
        orders: [
          {
            leverage: -0.998872,
            order_type: "SHORT",
            order_uuid: "0a53555a-4e22-44e0-8868-8341075ed489",
            price: 1.49013,
            price_sources: [],
            processed_ms: 1717682642942,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "dd6abc08-8d36-4123-a818-dda95c7429b3",
            price: 1.48868,
            price_sources: [],
            processed_ms: 1717701050133,
          },
        ],
        position_type: "FLAT",
        position_uuid: "0a53555a-4e22-44e0-8868-8341075ed489",
        return_at_close: 1.000901982840101,
        trade_pair: ["EURCAD", "EUR/CAD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.61787,
        close_ms: 1717763454363,
        current_return: 1.0015706505251913,
        initial_entry_price: 0.61787,
        is_closed_position: true,
        miner_hotkey: "5EUXGiE1vL3LpkJnrBX2gowcUdc6YeYZkmuHD9DuTaPT9Xx5",
        net_leverage: 0.0,
        open_ms: 1717586358323,
        orders: [
          {
            leverage: -0.500236,
            order_type: "SHORT",
            order_uuid: "c27d4bd3-ec64-48d8-a142-1a4598774d1c",
            price: 0.61787,
            price_sources: [],
            processed_ms: 1717586357268,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "e0550017-df71-4e36-b1f7-1954297a177e",
            price: 0.61593,
            price_sources: [],
            processed_ms: 1717763454363,
          },
        ],
        position_type: "FLAT",
        position_uuid: "c27d4bd3-ec64-48d8-a142-1a4598774d1c",
        return_at_close: 1.0015355790064757,
        trade_pair: ["NZDUSD", "NZD/USD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.65161,
        close_ms: 1717763628932,
        current_return: 1.0008284530317213,
        initial_entry_price: 0.65161,
        is_closed_position: true,
        miner_hotkey: "5EUXGiE1vL3LpkJnrBX2gowcUdc6YeYZkmuHD9DuTaPT9Xx5",
        net_leverage: 0.0,
        open_ms: 1717641171526,
        orders: [
          {
            leverage: 0.999682,
            order_type: "LONG",
            order_uuid: "e07a285b-d3dc-49b1-a20f-1a2b549a9264",
            price: 0.65161,
            price_sources: [],
            processed_ms: 1717641170370,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "46e1d14a-d9ae-449c-8816-bf0f590c27d0",
            price: 0.65215,
            price_sources: [],
            processed_ms: 1717763628932,
          },
        ],
        position_type: "FLAT",
        position_uuid: "e07a285b-d3dc-49b1-a20f-1a2b549a9264",
        return_at_close: 1.0007584173184505,
        trade_pair: ["CADCHF", "CAD/CHF", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 1.488865,
        close_ms: 1717763702446,
        current_return: 1.0006938444687732,
        initial_entry_price: 1.488865,
        is_closed_position: true,
        miner_hotkey: "5EUXGiE1vL3LpkJnrBX2gowcUdc6YeYZkmuHD9DuTaPT9Xx5",
        net_leverage: 0.0,
        open_ms: 1717734135115,
        orders: [
          {
            leverage: -0.998107,
            order_type: "SHORT",
            order_uuid: "dee334fd-b1ca-4ef6-a4e2-736c86fbd8dd",
            price: 1.488865,
            price_sources: [],
            processed_ms: 1717734135108,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "06a2f054-d6f5-49c0-a628-75f7ff85a02f",
            price: 1.48783,
            price_sources: [],
            processed_ms: 1717763702446,
          },
        ],
        position_type: "FLAT",
        position_uuid: "dee334fd-b1ca-4ef6-a4e2-736c86fbd8dd",
        return_at_close: 1.0006239285016016,
        trade_pair: ["EURCAD", "EUR/CAD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.6517200000000001,
        close_ms: 1717764541363,
        current_return: 1.0010241957282269,
        initial_entry_price: 0.6517200000000001,
        is_closed_position: true,
        miner_hotkey: "5EUXGiE1vL3LpkJnrBX2gowcUdc6YeYZkmuHD9DuTaPT9Xx5",
        net_leverage: 0.0,
        open_ms: 1717764350463,
        orders: [
          {
            leverage: 0.996252,
            order_type: "LONG",
            order_uuid: "034a0098-0a77-4a3a-86fb-23448f9985d1",
            price: 0.6517200000000001,
            price_sources: [],
            processed_ms: 1717764348275,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "804fd7ef-c9c4-4e16-ba70-cba04b0b7313",
            price: 0.65239,
            price_sources: [],
            processed_ms: 1717764541363,
          },
        ],
        position_type: "FLAT",
        position_uuid: "034a0098-0a77-4a3a-86fb-23448f9985d1",
        return_at_close: 1.000954386663234,
        trade_pair: ["CADCHF", "CAD/CHF", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.65821,
        close_ms: 1718020536600,
        current_return: 1.0006057769632792,
        initial_entry_price: 0.65821,
        is_closed_position: true,
        miner_hotkey: "5EUXGiE1vL3LpkJnrBX2gowcUdc6YeYZkmuHD9DuTaPT9Xx5",
        net_leverage: 0.0,
        open_ms: 1717977625740,
        orders: [
          {
            leverage: 0.298673,
            order_type: "LONG",
            order_uuid: "0e2bbee9-c725-4c83-a858-6afab844a726",
            price: 0.65821,
            price_sources: [],
            processed_ms: 1717977623857,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "0f4cf491-17cb-4e21-8df1-8c2c933fc250",
            price: 0.659545,
            price_sources: [],
            processed_ms: 1718020536600,
          },
        ],
        position_type: "FLAT",
        position_uuid: "0e2bbee9-c725-4c83-a858-6afab844a726",
        return_at_close: 1.0005848571882336,
        trade_pair: ["AUDUSD", "AUD/USD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.61051,
        close_ms: 1718021532810,
        current_return: 1.0004036055510965,
        initial_entry_price: 0.61051,
        is_closed_position: true,
        miner_hotkey: "5EUXGiE1vL3LpkJnrBX2gowcUdc6YeYZkmuHD9DuTaPT9Xx5",
        net_leverage: 0.0,
        open_ms: 1717977647323,
        orders: [
          {
            leverage: 0.298673,
            order_type: "LONG",
            order_uuid: "42828b2a-3004-4a99-9d16-154f2cbcae1d",
            price: 0.61051,
            price_sources: [],
            processed_ms: 1717977647312,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "854cbbc7-b534-4dd2-a8c1-f9fd448626d2",
            price: 0.611335,
            price_sources: [],
            processed_ms: 1718021532810,
          },
        ],
        position_type: "FLAT",
        position_uuid: "42828b2a-3004-4a99-9d16-154f2cbcae1d",
        return_at_close: 1.000382690002871,
        trade_pair: ["NZDUSD", "NZD/USD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.65978,
        close_ms: 1718034084225,
        current_return: 1.0003958533526327,
        initial_entry_price: 0.65978,
        is_closed_position: true,
        miner_hotkey: "5EUXGiE1vL3LpkJnrBX2gowcUdc6YeYZkmuHD9DuTaPT9Xx5",
        net_leverage: 0.0,
        open_ms: 1718022615150,
        orders: [
          {
            leverage: 0.298487,
            order_type: "LONG",
            order_uuid: "7a57402a-d671-4e6f-95d9-923b935bcac4",
            price: 0.65978,
            price_sources: [],
            processed_ms: 1718022615000,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "d8065793-c041-4c06-924c-4c220168cd73",
            price: 0.660655,
            price_sources: [],
            processed_ms: 1718034084225,
          },
        ],
        position_type: "FLAT",
        position_uuid: "7a57402a-d671-4e6f-95d9-923b935bcac4",
        return_at_close: 1.000374950991637,
        trade_pair: ["AUDUSD", "AUD/USD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.61125,
        close_ms: 1718034184844,
        current_return: 1.0003662417177914,
        initial_entry_price: 0.61125,
        is_closed_position: true,
        miner_hotkey: "5EUXGiE1vL3LpkJnrBX2gowcUdc6YeYZkmuHD9DuTaPT9Xx5",
        net_leverage: 0.0,
        open_ms: 1718022633101,
        orders: [
          {
            leverage: 0.298487,
            order_type: "LONG",
            order_uuid: "d7f11351-2a8e-4107-b254-255d0b00f9b7",
            price: 0.61125,
            price_sources: [],
            processed_ms: 1718022633004,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "7b4a5f6e-081b-4078-ba10-40a4379a1e4e",
            price: 0.612,
            price_sources: [],
            processed_ms: 1718034184844,
          },
        ],
        position_type: "FLAT",
        position_uuid: "d7f11351-2a8e-4107-b254-255d0b00f9b7",
        return_at_close: 1.000345339975504,
        trade_pair: ["NZDUSD", "NZD/USD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.6607449999999999,
        close_ms: 1718068257088,
        current_return: 1.0006057605354561,
        initial_entry_price: 0.6607449999999999,
        is_closed_position: true,
        miner_hotkey: "5EUXGiE1vL3LpkJnrBX2gowcUdc6YeYZkmuHD9DuTaPT9Xx5",
        net_leverage: 0.0,
        open_ms: 1718063870199,
        orders: [
          {
            leverage: -0.497209,
            order_type: "SHORT",
            order_uuid: "99246abc-b77a-476f-8a8a-c95fd0723ec7",
            price: 0.6607449999999999,
            price_sources: [],
            processed_ms: 1718063869014,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "a45c6bd4-a8b2-40c7-a1fd-ab13c2dadffe",
            price: 0.65994,
            price_sources: [],
            processed_ms: 1718068257088,
          },
        ],
        position_type: "FLAT",
        position_uuid: "99246abc-b77a-476f-8a8a-c95fd0723ec7",
        return_at_close: 1.0005709348221847,
        trade_pair: ["AUDUSD", "AUD/USD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.61244,
        close_ms: 1718069711175,
        current_return: 1.0004138890993404,
        initial_entry_price: 0.61244,
        is_closed_position: true,
        miner_hotkey: "5EUXGiE1vL3LpkJnrBX2gowcUdc6YeYZkmuHD9DuTaPT9Xx5",
        net_leverage: 0.0,
        open_ms: 1718068542804,
        orders: [
          {
            leverage: -0.497024,
            order_type: "SHORT",
            order_uuid: "4ad83ef8-404c-4c81-b310-a2e6e7aa9b83",
            price: 0.61244,
            price_sources: [],
            processed_ms: 1718068542730,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "61a3897e-cdfc-4d88-9b66-db5890fe61a0",
            price: 0.61193,
            price_sources: [],
            processed_ms: 1718069711175,
          },
        ],
        position_type: "FLAT",
        position_uuid: "4ad83ef8-404c-4c81-b310-a2e6e7aa9b83",
        return_at_close: 1.0003790830194432,
        trade_pair: ["NZDUSD", "NZD/USD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.8444329966530263,
        close_ms: 1718233318586,
        current_return: 1.000493719115389,
        initial_entry_price: 0.8467,
        is_closed_position: true,
        miner_hotkey: "5EUXGiE1vL3LpkJnrBX2gowcUdc6YeYZkmuHD9DuTaPT9Xx5",
        net_leverage: 0.0,
        open_ms: 1717978113110,
        orders: [
          {
            leverage: 0.298673,
            order_type: "LONG",
            order_uuid: "550c7515-8ded-4f73-9a41-126ae9d7763a",
            price: 0.8467,
            price_sources: [],
            processed_ms: 1717978113103,
          },
          {
            leverage: 0.298325,
            order_type: "LONG",
            order_uuid: "04ac6635-ae5c-48b9-827d-e356f272aecb",
            price: 0.84432,
            price_sources: [],
            processed_ms: 1718034208879,
          },
          {
            leverage: 0.298139,
            order_type: "LONG",
            order_uuid: "9933922a-594a-4f28-ba22-85cc16aad7ce",
            price: 0.842275,
            price_sources: [],
            processed_ms: 1718112281932,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "f4562192-1e49-442f-b811-c55377455bff",
            price: 0.8449,
            price_sources: [],
            processed_ms: 1718233318586,
          },
        ],
        position_type: "FLAT",
        position_uuid: "550c7515-8ded-4f73-9a41-126ae9d7763a",
        return_at_close: 1.0004310285891518,
        trade_pair: ["EURGBP", "EUR/GBP", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.60827,
        close_ms: 1719469848980,
        current_return: 1.0011349590971115,
        initial_entry_price: 0.60827,
        is_closed_position: true,
        miner_hotkey: "5EUXGiE1vL3LpkJnrBX2gowcUdc6YeYZkmuHD9DuTaPT9Xx5",
        net_leverage: 0.0,
        open_ms: 1719439237158,
        orders: [
          {
            leverage: 0.496663,
            order_type: "LONG",
            order_uuid: "02856201-749e-47aa-b840-bc76d04e0565",
            price: 0.60827,
            price_sources: [],
            processed_ms: 1719439237112,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "18116af4-fe53-491f-9fa8-56887c120d39",
            price: 0.60966,
            price_sources: [],
            processed_ms: 1719469848980,
          },
        ],
        position_type: "FLAT",
        position_uuid: "02856201-749e-47aa-b840-bc76d04e0565",
        return_at_close: 1.001100153228658,
        trade_pair: ["NZDUSD", "NZD/USD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 2294.0199999999986,
        close_ms: 1719779228580,
        current_return: 1.003235031610264,
        initial_entry_price: 3495.7,
        is_closed_position: true,
        miner_hotkey: "5EUXGiE1vL3LpkJnrBX2gowcUdc6YeYZkmuHD9DuTaPT9Xx5",
        net_leverage: 0.0,
        open_ms: 1718148941920,
        orders: [
          {
            leverage: 0.1,
            order_type: "LONG",
            order_uuid: "a0398026-c8a6-4ac6-bd22-2626c372defb",
            price: 3495.7,
            price_sources: [],
            processed_ms: 1718148941281,
          },
          {
            leverage: -0.09,
            order_type: "SHORT",
            order_uuid: "6a0a7a6b-da0a-4b07-8ad3-f9c1c94d854b",
            price: 3629.22,
            price_sources: [],
            processed_ms: 1718195848237,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "3f27980d-218a-464b-a461-39f67a709f2f",
            price: 3424.89,
            price_sources: [],
            processed_ms: 1719779228580,
          },
        ],
        position_type: "FLAT",
        position_uuid: "a0398026-c8a6-4ac6-bd22-2626c372defb",
        return_at_close: 1.0031347081071031,
        trade_pair: ["ETHUSD", "ETH/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 0.66666,
        close_ms: 1719844465248,
        current_return: 1.000912177196772,
        initial_entry_price: 0.66666,
        is_closed_position: true,
        miner_hotkey: "5EUXGiE1vL3LpkJnrBX2gowcUdc6YeYZkmuHD9DuTaPT9Xx5",
        net_leverage: 0.0,
        open_ms: 1719813289945,
        orders: [
          {
            leverage: -0.496418,
            order_type: "SHORT",
            order_uuid: "4d490d05-fcaa-429d-be7e-36834fee25e5",
            price: 0.66666,
            price_sources: [],
            processed_ms: 1719813289935,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "edca009c-4888-4cee-8496-afad02a04b7d",
            price: 0.665435,
            price_sources: [],
            processed_ms: 1719844465248,
          },
        ],
        position_type: "FLAT",
        position_uuid: "4d490d05-fcaa-429d-be7e-36834fee25e5",
        return_at_close: 1.0008773962392894,
        trade_pair: ["AUDUSD", "AUD/USD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 3307.8,
        close_ms: 1720153287776,
        current_return: 0.9861557530685048,
        initial_entry_price: 3307.8,
        is_closed_position: true,
        miner_hotkey: "5EUXGiE1vL3LpkJnrBX2gowcUdc6YeYZkmuHD9DuTaPT9Xx5",
        net_leverage: 0.0,
        open_ms: 1720014290339,
        orders: [
          {
            leverage: 0.1,
            order_type: "LONG",
            order_uuid: "1cf355af-4684-4f4b-8743-5f9e1340f66a",
            price: 3307.8,
            price_sources: [],
            processed_ms: 1720014289579,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "903b7c06-1983-4907-9621-a2ffc92b15cd",
            price: 2849.86,
            price_sources: [
              {
                close: 2849.86,
                high: 2851.2,
                lag_ms: 223,
                low: 2848.86,
                open: 2849.86,
                source: "Polygon_ws",
                start_ms: 1720153287999,
                timespan_ms: 0,
                volume: 7.72146168,
                vwap: 2850.4715,
                websocket: true,
              },
              {
                close: 2851.7,
                high: 2851.7,
                lag_ms: 224,
                low: 2851.7,
                open: 2851.7,
                source: "TwelveData_ws",
                start_ms: 1720153288000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
              {
                close: 2851.95,
                high: 2855.28,
                lag_ms: 2777,
                low: 2851.95,
                open: 2855.28,
                source: "Polygon_rest",
                start_ms: 1720153284000,
                timespan_ms: 1000,
                volume: 37.27448537,
                vwap: 2852.9736,
                websocket: false,
              },
              {
                close: 2840.55005,
                high: 2869.8501,
                lag_ms: 87777,
                low: 2838.03003,
                open: 2869.06006,
                source: "TwelveData_rest",
                start_ms: 1720153140000,
                timespan_ms: 60000,
                volume: null,
                vwap: null,
                websocket: false,
              },
            ],
            processed_ms: 1720153287776,
          },
        ],
        position_type: "FLAT",
        position_uuid: "1cf355af-4684-4f4b-8743-5f9e1340f66a",
        return_at_close: 0.9860571374931979,
        trade_pair: ["ETHUSD", "ETH/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 0.60766,
        close_ms: 1720153411911,
        current_return: 0.9966196517789553,
        initial_entry_price: 0.60766,
        is_closed_position: true,
        miner_hotkey: "5EUXGiE1vL3LpkJnrBX2gowcUdc6YeYZkmuHD9DuTaPT9Xx5",
        net_leverage: 0.0,
        open_ms: 1719974953210,
        orders: [
          {
            leverage: -0.49616,
            order_type: "SHORT",
            order_uuid: "4ecd48db-1bf6-441a-b36b-a63a829a8fff",
            price: 0.60766,
            price_sources: [],
            processed_ms: 1719974953205,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "c136e499-fa8d-4a09-b788-5abee8454dcd",
            price: 0.6118,
            price_sources: [
              {
                close: 0.6118,
                high: 0.6118,
                lag_ms: 88,
                low: 0.6118,
                open: 0.6118,
                source: "Polygon_ws",
                start_ms: 1720153411999,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 0.6118,
                websocket: true,
              },
              {
                close: 0.61203,
                high: 0.61203,
                lag_ms: 1089,
                low: 0.61203,
                open: 0.61203,
                source: "TwelveData_ws",
                start_ms: 1720153413000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1720153411911,
          },
        ],
        position_type: "FLAT",
        position_uuid: "4ecd48db-1bf6-441a-b36b-a63a829a8fff",
        return_at_close: 0.9965850379825054,
        trade_pair: ["NZDUSD", "NZD/USD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 1.08298,
        close_ms: 1720391615888,
        current_return: 1.0001616400118192,
        initial_entry_price: 1.08298,
        is_closed_position: true,
        miner_hotkey: "5EUXGiE1vL3LpkJnrBX2gowcUdc6YeYZkmuHD9DuTaPT9Xx5",
        net_leverage: 0.0,
        open_ms: 1720183522635,
        orders: [
          {
            leverage: -0.318278,
            order_type: "SHORT",
            order_uuid: "8e50d3cd-dbb7-4acf-9a7c-7cdd2f499dcc",
            price: 1.08298,
            price_sources: [
              {
                close: 1.08298,
                high: 1.08298,
                lag_ms: 376,
                low: 1.08298,
                open: 1.08298,
                source: "Polygon_ws",
                start_ms: 1720183522999,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 1.08298,
                websocket: true,
              },
              {
                close: 1.0829,
                high: 1.0829,
                lag_ms: 377,
                low: 1.0829,
                open: 1.0829,
                source: "TwelveData_ws",
                start_ms: 1720183523000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1720183522623,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "8820dc8f-7933-42bd-9ce9-6bde4cb74e4b",
            price: 1.08243,
            price_sources: [
              {
                close: 1.08243,
                high: 1.08243,
                lag_ms: 112,
                low: 1.08243,
                open: 1.08243,
                source: "Polygon_ws",
                start_ms: 1720391616000,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 1.08243,
                websocket: true,
              },
              {
                close: 1.0824,
                high: 1.0824,
                lag_ms: 1112,
                low: 1.0824,
                open: 1.0824,
                source: "TwelveData_ws",
                start_ms: 1720391617000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
              {
                close: 1.08243,
                high: 1.08243,
                lag_ms: 2889,
                low: 1.08243,
                open: 1.08243,
                source: "Polygon_rest",
                start_ms: 1720391612000,
                timespan_ms: 1000,
                volume: 1.0,
                vwap: null,
                websocket: false,
              },
              {
                close: 1.08385,
                high: 1.08395,
                lag_ms: 178415889,
                low: 1.08375,
                open: 1.08375,
                source: "TwelveData_rest",
                start_ms: 1720213140000,
                timespan_ms: 60000,
                volume: null,
                vwap: null,
                websocket: false,
              },
            ],
            processed_ms: 1720391615888,
          },
        ],
        position_type: "FLAT",
        position_uuid: "8e50d3cd-dbb7-4acf-9a7c-7cdd2f499dcc",
        return_at_close: 1.000139356950567,
        trade_pair: ["EURUSD", "EUR/USD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 1.08438,
        close_ms: 1720533867562,
        current_return: 1.0008851234069238,
        initial_entry_price: 1.08438,
        is_closed_position: true,
        miner_hotkey: "5EUXGiE1vL3LpkJnrBX2gowcUdc6YeYZkmuHD9DuTaPT9Xx5",
        net_leverage: 0.0,
        open_ms: 1720443616926,
        orders: [
          {
            leverage: -0.377878,
            order_type: "SHORT",
            order_uuid: "2a05cb29-6bfc-4c17-800e-b8f7a10c7f13",
            price: 1.08438,
            price_sources: [
              {
                close: 1.08438,
                high: 1.08438,
                lag_ms: 130,
                low: 1.08438,
                open: 1.08438,
                source: "Polygon_ws",
                start_ms: 1720443616999,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 1.08433,
                websocket: true,
              },
              {
                close: 1.0844,
                high: 1.0844,
                lag_ms: 131,
                low: 1.0844,
                open: 1.0844,
                source: "TwelveData_ws",
                start_ms: 1720443617000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1720443616869,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "0a748763-c121-4b29-8ecf-4a7bc02eda07",
            price: 1.08184,
            price_sources: [
              {
                close: 1.08184,
                high: 1.08184,
                lag_ms: 437,
                low: 1.08184,
                open: 1.08184,
                source: "Polygon_ws",
                start_ms: 1720533867999,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 1.08184,
                websocket: true,
              },
              {
                close: 1.0819,
                high: 1.0819,
                lag_ms: 562,
                low: 1.0819,
                open: 1.0819,
                source: "TwelveData_ws",
                start_ms: 1720533867000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1720533867562,
          },
        ],
        position_type: "FLAT",
        position_uuid: "2a05cb29-6bfc-4c17-800e-b8f7a10c7f13",
        return_at_close: 1.0008586485341173,
        trade_pair: ["EURUSD", "EUR/USD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 1.36366,
        close_ms: 1720615379971,
        current_return: 1.0001192280920463,
        initial_entry_price: 1.36366,
        is_closed_position: true,
        miner_hotkey: "5EUXGiE1vL3LpkJnrBX2gowcUdc6YeYZkmuHD9DuTaPT9Xx5",
        net_leverage: 0.0,
        open_ms: 1720487121099,
        orders: [
          {
            leverage: -0.149162,
            order_type: "SHORT",
            order_uuid: "982798b1-7564-4b64-8890-bec16e361591",
            price: 1.36366,
            price_sources: [
              {
                close: 1.36366,
                high: 1.36366,
                lag_ms: 55,
                low: 1.36366,
                open: 1.36366,
                source: "Polygon_ws",
                start_ms: 1720487120999,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 1.36366,
                websocket: true,
              },
              {
                close: 1.3637,
                high: 1.3637,
                lag_ms: 946,
                low: 1.3637,
                open: 1.3637,
                source: "TwelveData_ws",
                start_ms: 1720487122000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1720487121054,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "ea2f051e-ea04-4652-903b-bbc25c288ed5",
            price: 1.36257,
            price_sources: [
              {
                close: 1.36257,
                high: 1.36257,
                lag_ms: 28,
                low: 1.36257,
                open: 1.36257,
                source: "Polygon_ws",
                start_ms: 1720615379999,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 1.36257,
                websocket: true,
              },
              {
                close: 1.36256,
                high: 1.36256,
                lag_ms: 5029,
                low: 1.36256,
                open: 1.36256,
                source: "TwelveData_ws",
                start_ms: 1720615385000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1720615379971,
          },
        ],
        position_type: "FLAT",
        position_uuid: "982798b1-7564-4b64-8890-bec16e361591",
        return_at_close: 1.0001087855071453,
        trade_pair: ["USDCAD", "USD/CAD", 7e-5, 0.001, 500],
      },
    ],
    thirty_day_returns: 0.9897536270817556,
  },
  "5EXWvBCADJo1JVv6jHZPTRuV19YuuJBnjG3stBm3bF5cR9oy": {
    all_time_returns: 1.170019546937052,
    n_positions: 24,
    percentage_profitable: 0.4583333333333333,
    positions: [
      {
        average_entry_price: 0.89657,
        close_ms: 1718282343866,
        current_return: 1.0012129560435883,
        initial_entry_price: 0.89657,
        is_closed_position: true,
        miner_hotkey: "5EXWvBCADJo1JVv6jHZPTRuV19YuuJBnjG3stBm3bF5cR9oy",
        net_leverage: 0.0,
        open_ms: 1718185279321,
        orders: [
          {
            leverage: -0.5,
            order_type: "SHORT",
            order_uuid: "14ff46ad-391d-48ad-a6e8-3d1d0f373afe",
            price: 0.89657,
            price_sources: [],
            processed_ms: 1718185279314,
          },
          {
            leverage: 0.01,
            order_type: "FLAT",
            order_uuid: "5c92eb2c-10fd-472a-b923-57f38e29ac77",
            price: 0.894395,
            price_sources: [],
            processed_ms: 1718282343866,
          },
        ],
        position_type: "FLAT",
        position_uuid: "d0740e59-19fd-4af7-a0f1-5b38ca361842",
        return_at_close: 1.0011779135901266,
        trade_pair: ["USDCHF", "USD/CHF", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 1.07503,
        close_ms: 1718282389655,
        current_return: 1.002260402035292,
        initial_entry_price: 1.07503,
        is_closed_position: true,
        miner_hotkey: "5EXWvBCADJo1JVv6jHZPTRuV19YuuJBnjG3stBm3bF5cR9oy",
        net_leverage: 0.0,
        open_ms: 1718185313534,
        orders: [
          {
            leverage: 0.5,
            order_type: "LONG",
            order_uuid: "8fa19ed9-3d60-4824-bd5c-d7f27ba60010",
            price: 1.07503,
            price_sources: [],
            processed_ms: 1718185313528,
          },
          {
            leverage: 0.01,
            order_type: "FLAT",
            order_uuid: "a57b3e08-bf90-44f3-b708-c266c383aa57",
            price: 1.07989,
            price_sources: [],
            processed_ms: 1718282389655,
          },
        ],
        position_type: "FLAT",
        position_uuid: "c1dc420c-e7e0-467e-9c2f-f8106b26d280",
        return_at_close: 1.0022253229212208,
        trade_pair: ["EURUSD", "EUR/USD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.66501,
        close_ms: 1718282501177,
        current_return: 1.00069171892152,
        initial_entry_price: 0.66501,
        is_closed_position: true,
        miner_hotkey: "5EXWvBCADJo1JVv6jHZPTRuV19YuuJBnjG3stBm3bF5cR9oy",
        net_leverage: 0.0,
        open_ms: 1718263772146,
        orders: [
          {
            leverage: 0.5,
            order_type: "LONG",
            order_uuid: "012b6e9a-ee8f-4ffc-97a4-95ac4b98a274",
            price: 0.66501,
            price_sources: [],
            processed_ms: 1718263772051,
          },
          {
            leverage: 0.01,
            order_type: "FLAT",
            order_uuid: "3de43b70-62b4-492e-a49c-6ade9b8e41b2",
            price: 0.66593,
            price_sources: [],
            processed_ms: 1718282501177,
          },
        ],
        position_type: "FLAT",
        position_uuid: "7cc8b339-6897-43bc-9d84-afffda7149e5",
        return_at_close: 1.0006566947113578,
        trade_pair: ["AUDUSD", "AUD/USD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 1.0739299999999998,
        close_ms: 1718357545595,
        current_return: 0.9970947827139572,
        initial_entry_price: 1.0739299999999998,
        is_closed_position: true,
        miner_hotkey: "5EXWvBCADJo1JVv6jHZPTRuV19YuuJBnjG3stBm3bF5cR9oy",
        net_leverage: 0.0,
        open_ms: 1718299765777,
        orders: [
          {
            leverage: 0.5,
            order_type: "LONG",
            order_uuid: "2f563ee5-d479-48a1-83d7-42bd17411c76",
            price: 1.0739299999999998,
            price_sources: [],
            processed_ms: 1718299765683,
          },
          {
            leverage: 0.01,
            order_type: "FLAT",
            order_uuid: "1957f2df-13d7-4f08-ab1e-70c1c8cde354",
            price: 1.06769,
            price_sources: [],
            processed_ms: 1718357545595,
          },
        ],
        position_type: "FLAT",
        position_uuid: "5907adc0-1493-4022-a686-863bbcdea4c2",
        return_at_close: 0.9970598843965622,
        trade_pair: ["EURUSD", "EUR/USD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.84492,
        close_ms: 1718357576603,
        current_return: 0.9972660133503763,
        initial_entry_price: 0.84492,
        is_closed_position: true,
        miner_hotkey: "5EXWvBCADJo1JVv6jHZPTRuV19YuuJBnjG3stBm3bF5cR9oy",
        net_leverage: 0.0,
        open_ms: 1718205760446,
        orders: [
          {
            leverage: 0.5,
            order_type: "LONG",
            order_uuid: "71c26114-93ce-458f-9668-036365f8deb8",
            price: 0.84492,
            price_sources: [],
            processed_ms: 1718205760431,
          },
          {
            leverage: 0.01,
            order_type: "FLAT",
            order_uuid: "f3d07df7-c7d7-4550-ad81-9b526375fbd0",
            price: 0.8403,
            price_sources: [],
            processed_ms: 1718357576603,
          },
        ],
        position_type: "FLAT",
        position_uuid: "5d10805a-8200-473b-b56e-a89584f3c638",
        return_at_close: 0.9972311090399091,
        trade_pair: ["EURGBP", "EUR/GBP", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.84767,
        close_ms: 1718357720992,
        current_return: 0.9973243669796766,
        initial_entry_price: 0.84767,
        is_closed_position: true,
        miner_hotkey: "5EXWvBCADJo1JVv6jHZPTRuV19YuuJBnjG3stBm3bF5cR9oy",
        net_leverage: 0.0,
        open_ms: 1718309723917,
        orders: [
          {
            leverage: 0.5,
            order_type: "LONG",
            order_uuid: "da9b3e1f-17b5-461c-a4ed-7e93de61d818",
            price: 0.84767,
            price_sources: [],
            processed_ms: 1718309723880,
          },
          {
            leverage: 0.01,
            order_type: "FLAT",
            order_uuid: "add2f151-238f-46d0-a847-244a8702e1bb",
            price: 0.843133892315325,
            price_sources: [],
            processed_ms: 1718357720992,
          },
        ],
        position_type: "FLAT",
        position_uuid: "a361b288-0b54-4aac-8560-793d1d10eb5b",
        return_at_close: 0.9972894606268323,
        trade_pair: ["NZDCAD", "NZD/CAD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.61681,
        close_ms: 1718357838134,
        current_return: 0.9965467485935702,
        initial_entry_price: 0.61681,
        is_closed_position: true,
        miner_hotkey: "5EXWvBCADJo1JVv6jHZPTRuV19YuuJBnjG3stBm3bF5cR9oy",
        net_leverage: 0.0,
        open_ms: 1718299685922,
        orders: [
          {
            leverage: 0.5,
            order_type: "LONG",
            order_uuid: "5a745304-f799-4d66-8e1d-5bb078850126",
            price: 0.61681,
            price_sources: [],
            processed_ms: 1718299685911,
          },
          {
            leverage: 0.01,
            order_type: "FLAT",
            order_uuid: "97500971-df9b-4719-9030-542b497e8c6e",
            price: 0.61255,
            price_sources: [],
            processed_ms: 1718357838134,
          },
        ],
        position_type: "FLAT",
        position_uuid: "89d5fa2f-f112-46e8-b816-c6200c8594e0",
        return_at_close: 0.9965118694573694,
        trade_pair: ["NZDUSD", "NZD/USD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 96.77949999999998,
        close_ms: 1718358406382,
        current_return: 1.0054891764171772,
        initial_entry_price: 96.918,
        is_closed_position: true,
        miner_hotkey: "5EXWvBCADJo1JVv6jHZPTRuV19YuuJBnjG3stBm3bF5cR9oy",
        net_leverage: 0.0,
        open_ms: 1718349842744,
        orders: [
          {
            leverage: -0.5,
            order_type: "SHORT",
            order_uuid: "46b6e8d1-a7f2-4b34-a5f8-9db06bc788b1",
            price: 96.918,
            price_sources: [],
            processed_ms: 1718349842726,
          },
          {
            leverage: -0.5,
            order_type: "SHORT",
            order_uuid: "bcea1133-7b75-45cc-939a-ff03ab92e7a7",
            price: 96.335,
            price_sources: [],
            processed_ms: 1718357751512,
          },
          {
            leverage: -1.0,
            order_type: "SHORT",
            order_uuid: "4b7fad42-f8a2-41f1-95ae-03c5f5ca177c",
            price: 96.339,
            price_sources: [],
            processed_ms: 1718357868495,
          },
          {
            leverage: -1.0,
            order_type: "SHORT",
            order_uuid: "6bf33bd0-a5f5-49b3-b713-d18b4b601e94",
            price: 96.252,
            price_sources: [],
            processed_ms: 1718357956314,
          },
          {
            leverage: 2.0,
            order_type: "LONG",
            order_uuid: "d9376a46-e0f3-4c0a-97b9-0f44edf70ea2",
            price: 96.219,
            price_sources: [],
            processed_ms: 1718358339467,
          },
          {
            leverage: 2.0,
            order_type: "FLAT",
            order_uuid: "d8b68947-a794-4467-9f30-5dd00a204707",
            price: 96.2475,
            price_sources: [],
            processed_ms: 1718358406382,
          },
        ],
        position_type: "FLAT",
        position_uuid: "5c86917a-4044-4718-bf52-52bef84fa01e",
        return_at_close: 1.0052780236901295,
        trade_pair: ["NZDJPY", "NZD/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 168.37,
        close_ms: 1718358417531,
        current_return: 1.0035782485321152,
        initial_entry_price: 168.099,
        is_closed_position: true,
        miner_hotkey: "5EXWvBCADJo1JVv6jHZPTRuV19YuuJBnjG3stBm3bF5cR9oy",
        net_leverage: 0.0,
        open_ms: 1718357617056,
        orders: [
          {
            leverage: -1.0,
            order_type: "SHORT",
            order_uuid: "d77cb005-7634-47cf-93ab-47a1dc86b97c",
            price: 168.099,
            price_sources: [],
            processed_ms: 1718357617044,
          },
          {
            leverage: -1.0,
            order_type: "SHORT",
            order_uuid: "3fd52798-0f33-441c-ac34-6dfb3407f0bc",
            price: 167.933,
            price_sources: [],
            processed_ms: 1718357789105,
          },
          {
            leverage: -1.0,
            order_type: "SHORT",
            order_uuid: "e1f151b2-3683-445f-abec-ee70dba4fc9d",
            price: 167.79,
            price_sources: [],
            processed_ms: 1718357927914,
          },
          {
            leverage: 2.0,
            order_type: "LONG",
            order_uuid: "851a2431-5b64-4ca5-b738-6fc25b5cbea1",
            price: 167.726,
            price_sources: [],
            processed_ms: 1718358369422,
          },
          {
            leverage: 2.0,
            order_type: "FLAT",
            order_uuid: "dc314bab-e8d3-4faf-8524-c119db2dc56c",
            price: 167.7685,
            price_sources: [],
            processed_ms: 1718358417531,
          },
        ],
        position_type: "FLAT",
        position_uuid: "a1af35b5-a75c-4a76-89b3-e5fc5fd762ca",
        return_at_close: 1.0033674970999233,
        trade_pair: ["EURJPY", "EUR/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.6491,
        close_ms: 1718360548736,
        current_return: 1.0000154059466955,
        initial_entry_price: 0.6491,
        is_closed_position: true,
        miner_hotkey: "5EXWvBCADJo1JVv6jHZPTRuV19YuuJBnjG3stBm3bF5cR9oy",
        net_leverage: 0.0,
        open_ms: 1718358729064,
        orders: [
          {
            leverage: -1.0,
            order_type: "SHORT",
            order_uuid: "47ac37f4-e2e1-466a-b161-d17d9ddb0df5",
            price: 0.6491,
            price_sources: [],
            processed_ms: 1718358729040,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "1a746ed3-fc8c-4d9f-85e0-244f1892588e",
            price: 0.64909,
            price_sources: [],
            processed_ms: 1718360548736,
          },
        ],
        position_type: "FLAT",
        position_uuid: "9ad17c97-68b6-4e29-8e62-2d25c4a8198e",
        return_at_close: 0.9999454048682792,
        trade_pair: ["CADCHF", "CAD/CHF", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 114.13100000000001,
        close_ms: 1718368496347,
        current_return: 0.9959398733345601,
        initial_entry_price: 114.159,
        is_closed_position: true,
        miner_hotkey: "5EXWvBCADJo1JVv6jHZPTRuV19YuuJBnjG3stBm3bF5cR9oy",
        net_leverage: 0.0,
        open_ms: 1718358523528,
        orders: [
          {
            leverage: -1.0,
            order_type: "SHORT",
            order_uuid: "d967bd23-6c33-417f-bbf8-e4db57140013",
            price: 114.159,
            price_sources: [],
            processed_ms: 1718358523495,
          },
          {
            leverage: -2.0,
            order_type: "SHORT",
            order_uuid: "ad98f4bf-64ac-4e6b-a358-1a6b376c1944",
            price: 114.117,
            price_sources: [],
            processed_ms: 1718359355028,
          },
          {
            leverage: 0.01,
            order_type: "FLAT",
            order_uuid: "a2b93ac5-c474-40a9-b5ba-61ec9841f281",
            price: 114.2855,
            price_sources: [],
            processed_ms: 1718368496347,
          },
        ],
        position_type: "FLAT",
        position_uuid: "b09c142b-4b76-40ee-84b3-da2dd15a5788",
        return_at_close: 0.9957307259611599,
        trade_pair: ["CADJPY", "CAD/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 96.205,
        close_ms: 1718368701708,
        current_return: 0.9945273114702978,
        initial_entry_price: 96.205,
        is_closed_position: true,
        miner_hotkey: "5EXWvBCADJo1JVv6jHZPTRuV19YuuJBnjG3stBm3bF5cR9oy",
        net_leverage: 0.0,
        open_ms: 1718360459951,
        orders: [
          {
            leverage: -3.0,
            order_type: "SHORT",
            order_uuid: "146f4596-d1e5-476e-9d0d-8869065fdbce",
            price: 96.205,
            price_sources: [],
            processed_ms: 1718360459908,
          },
          {
            leverage: 3.0,
            order_type: "FLAT",
            order_uuid: "b1548ef0-9e35-45fa-9456-3bffd42f234a",
            price: 96.3805,
            price_sources: [],
            processed_ms: 1718368701708,
          },
        ],
        position_type: "FLAT",
        position_uuid: "6d99f706-b353-43b6-b9ac-e1e3e5a81e72",
        return_at_close: 0.994318460734889,
        trade_pair: ["NZDJPY", "NZD/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 167.76049999999998,
        close_ms: 1718373242746,
        current_return: 1.0005454204058761,
        initial_entry_price: 167.76049999999998,
        is_closed_position: true,
        miner_hotkey: "5EXWvBCADJo1JVv6jHZPTRuV19YuuJBnjG3stBm3bF5cR9oy",
        net_leverage: 0.0,
        open_ms: 1718367180291,
        orders: [
          {
            leverage: -3.0,
            order_type: "SHORT",
            order_uuid: "2fe563f4-cacd-48a3-b886-e61ccd3e4fa1",
            price: 167.76049999999998,
            price_sources: [],
            processed_ms: 1718367179929,
          },
          {
            leverage: 3.0,
            order_type: "FLAT",
            order_uuid: "6f8ea8bf-c74c-4806-8da8-e17612add7e4",
            price: 167.73,
            price_sources: [],
            processed_ms: 1718373242746,
          },
        ],
        position_type: "FLAT",
        position_uuid: "332bedc8-f1f4-4276-805b-7becbb389387",
        return_at_close: 1.0003353058675908,
        trade_pair: ["EURJPY", "EUR/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.64815,
        close_ms: 1718378628088,
        current_return: 1.0001388567461236,
        initial_entry_price: 0.64815,
        is_closed_position: true,
        miner_hotkey: "5EXWvBCADJo1JVv6jHZPTRuV19YuuJBnjG3stBm3bF5cR9oy",
        net_leverage: 0.0,
        open_ms: 1718368732589,
        orders: [
          {
            leverage: -3.0,
            order_type: "SHORT",
            order_uuid: "5cc72235-1e35-4bda-b598-c004e42a5ab7",
            price: 0.64815,
            price_sources: [],
            processed_ms: 1718368732579,
          },
          {
            leverage: 0.01,
            order_type: "FLAT",
            order_uuid: "cca7dafc-ca14-4a34-883a-56dd8dc2ed88",
            price: 0.64812,
            price_sources: [],
            processed_ms: 1718378628088,
          },
        ],
        position_type: "FLAT",
        position_uuid: "761147b5-04dd-4f62-b782-abc4fef725fd",
        return_at_close: 0.9999288275862068,
        trade_pair: ["CADCHF", "CAD/CHF", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 1.068065,
        close_ms: 1718382361076,
        current_return: 0.9953514065155211,
        initial_entry_price: 1.068065,
        is_closed_position: true,
        miner_hotkey: "5EXWvBCADJo1JVv6jHZPTRuV19YuuJBnjG3stBm3bF5cR9oy",
        net_leverage: 0.0,
        open_ms: 1718368535992,
        orders: [
          {
            leverage: -3.0,
            order_type: "SHORT",
            order_uuid: "76b47c4e-9521-4958-a3be-d184a38d7660",
            price: 1.068065,
            price_sources: [],
            processed_ms: 1718368535960,
          },
          {
            leverage: 0.01,
            order_type: "FLAT",
            order_uuid: "630c19d0-33bc-400a-bb73-bc0f11024149",
            price: 1.06972,
            price_sources: [],
            processed_ms: 1718382361076,
          },
        ],
        position_type: "FLAT",
        position_uuid: "bc4c14ef-5611-478e-a814-bec015e77655",
        return_at_close: 0.9951423827201528,
        trade_pair: ["EURUSD", "EUR/USD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.612805,
        close_ms: 1718382475368,
        current_return: 0.9898477157360406,
        initial_entry_price: 0.611685,
        is_closed_position: true,
        miner_hotkey: "5EXWvBCADJo1JVv6jHZPTRuV19YuuJBnjG3stBm3bF5cR9oy",
        net_leverage: 0.0,
        open_ms: 1718373298883,
        orders: [
          {
            leverage: -3.0,
            order_type: "SHORT",
            order_uuid: "d923910a-30c8-4a78-aa24-cd1a59356464",
            price: 0.611685,
            price_sources: [],
            processed_ms: 1718373298848,
          },
          {
            leverage: -3.0,
            order_type: "SHORT",
            order_uuid: "f5ba6f1d-4d37-4b81-b74e-73edd9aadada",
            price: 0.613925,
            price_sources: [],
            processed_ms: 1718378674290,
          },
          {
            leverage: 0.01,
            order_type: "FLAT",
            order_uuid: "d94a0f6c-a80c-45f9-832e-7da02cab611c",
            price: 0.61384,
            price_sources: [],
            processed_ms: 1718382475368,
          },
        ],
        position_type: "FLAT",
        position_uuid: "2a34320c-d128-4a6f-88bd-c9cdf51e6c7a",
        return_at_close: 0.9894319796954315,
        trade_pair: ["NZDUSD", "NZD/USD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 3400.94,
        close_ms: 1718382966463,
        current_return: 0.9893999894146912,
        initial_entry_price: 3400.94,
        is_closed_position: true,
        miner_hotkey: "5EXWvBCADJo1JVv6jHZPTRuV19YuuJBnjG3stBm3bF5cR9oy",
        net_leverage: 0.0,
        open_ms: 1718382553860,
        orders: [
          {
            leverage: -5.0,
            order_type: "SHORT",
            order_uuid: "8006a0fc-87f9-439d-ae15-af5246401c42",
            price: 3400.94,
            price_sources: [],
            processed_ms: 1718382552166,
          },
          {
            leverage: 5.0,
            order_type: "FLAT",
            order_uuid: "6ffd0cc8-63f9-426d-a511-0efa4555c15c",
            price: 3408.15,
            price_sources: [],
            processed_ms: 1718382966463,
          },
        ],
        position_type: "FLAT",
        position_uuid: "f5200690-6eea-4ec9-bc90-472e5ada9d1c",
        return_at_close: 0.9844529894676177,
        trade_pair: ["ETHUSD", "ETH/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 65514.92,
        close_ms: 1718382997716,
        current_return: 1.00738152469697,
        initial_entry_price: 65514.92,
        is_closed_position: true,
        miner_hotkey: "5EXWvBCADJo1JVv6jHZPTRuV19YuuJBnjG3stBm3bF5cR9oy",
        net_leverage: 0.0,
        open_ms: 1718382505784,
        orders: [
          {
            leverage: -5.0,
            order_type: "SHORT",
            order_uuid: "941b55c6-dada-4b41-af05-5682147d7b80",
            price: 65514.92,
            price_sources: [],
            processed_ms: 1718382505341,
          },
          {
            leverage: 5.0,
            order_type: "FLAT",
            order_uuid: "4a6c659c-97da-4ea9-9ad9-931eaea030c9",
            price: 65418.2,
            price_sources: [],
            processed_ms: 1718382997716,
          },
        ],
        position_type: "FLAT",
        position_uuid: "6227772b-d899-4f71-8cf9-f1c023169456",
        return_at_close: 1.002344617073485,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 65294.009999999995,
        close_ms: 1718383558524,
        current_return: 1.030988483448527,
        initial_entry_price: 65279.09,
        is_closed_position: true,
        miner_hotkey: "5EXWvBCADJo1JVv6jHZPTRuV19YuuJBnjG3stBm3bF5cR9oy",
        net_leverage: 0.0,
        open_ms: 1718383069501,
        orders: [
          {
            leverage: -5.0,
            order_type: "SHORT",
            order_uuid: "09dc1c29-3863-48f3-9c85-a4ec8c953596",
            price: 65279.09,
            price_sources: [],
            processed_ms: 1718383068419,
          },
          {
            leverage: -5.0,
            order_type: "SHORT",
            order_uuid: "f1575363-88f6-453b-b3a2-88841e7f9f9c",
            price: 65263.52,
            price_sources: [],
            processed_ms: 1718383241063,
          },
          {
            leverage: -5.0,
            order_type: "SHORT",
            order_uuid: "ca0d2677-528c-4de0-9bf0-43af243285dc",
            price: 65339.42,
            price_sources: [],
            processed_ms: 1718383429803,
          },
          {
            leverage: 5.0,
            order_type: "FLAT",
            order_uuid: "c56e13bb-4ee8-40b7-8a71-92acfa6790c4",
            price: 65159.15,
            price_sources: [],
            processed_ms: 1718383558524,
          },
        ],
        position_type: "FLAT",
        position_uuid: "280c6ebd-a1ec-4878-9fd7-23a6fef8798b",
        return_at_close: 1.015523656196799,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 65324.35992996498,
        close_ms: 1718384253621,
        current_return: 1.0013348990757782,
        initial_entry_price: 65289.58,
        is_closed_position: true,
        miner_hotkey: "5EXWvBCADJo1JVv6jHZPTRuV19YuuJBnjG3stBm3bF5cR9oy",
        net_leverage: 0.0,
        open_ms: 1718383612904,
        orders: [
          {
            leverage: -5.0,
            order_type: "SHORT",
            order_uuid: "5ae934f1-2059-410d-a123-c15a80707ea1",
            price: 65289.58,
            price_sources: [],
            processed_ms: 1718383612495,
          },
          {
            leverage: -5.0,
            order_type: "SHORT",
            order_uuid: "e3d0eb72-af53-4005-a545-81be8953cb07",
            price: 65242.41,
            price_sources: [],
            processed_ms: 1718383708360,
          },
          {
            leverage: -5.0,
            order_type: "SHORT",
            order_uuid: "db71dcc6-1eb1-490b-9c36-dcfa69f93ab8",
            price: 65326.18,
            price_sources: [],
            processed_ms: 1718383776487,
          },
          {
            leverage: -4.99,
            order_type: "SHORT",
            order_uuid: "9feafd9f-4a87-415b-914e-43c1c5e803b7",
            price: 65439.5,
            price_sources: [],
            processed_ms: 1718383815685,
          },
          {
            leverage: 5.0,
            order_type: "FLAT",
            order_uuid: "2bd8e094-f109-440c-af34-5bc7dd124cec",
            price: 65320.0,
            price_sources: [],
            processed_ms: 1718384253621,
          },
        ],
        position_type: "FLAT",
        position_uuid: "47017edc-6885-4dbe-bdcb-b9cd0a93528a",
        return_at_close: 0.9813182144432535,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 65231.35333333334,
        close_ms: 1718384676917,
        current_return: 1.0440050095395388,
        initial_entry_price: 65291.43,
        is_closed_position: true,
        miner_hotkey: "5EXWvBCADJo1JVv6jHZPTRuV19YuuJBnjG3stBm3bF5cR9oy",
        net_leverage: 0.0,
        open_ms: 1718384297161,
        orders: [
          {
            leverage: -5.0,
            order_type: "SHORT",
            order_uuid: "10dfe6a1-b58c-4572-a4f3-674511aaaa20",
            price: 65291.43,
            price_sources: [],
            processed_ms: 1718384296340,
          },
          {
            leverage: -5.0,
            order_type: "SHORT",
            order_uuid: "d5984dfc-949b-4b98-999c-d2898f7ba161",
            price: 65201.39,
            price_sources: [],
            processed_ms: 1718384351702,
          },
          {
            leverage: -5.0,
            order_type: "SHORT",
            order_uuid: "b51cc224-ef33-4465-8a26-6ff3c411bfdd",
            price: 65201.24,
            price_sources: [],
            processed_ms: 1718384506364,
          },
          {
            leverage: 5.0,
            order_type: "FLAT",
            order_uuid: "bfa47585-bf78-4dc2-ae54-5153fae7cf21",
            price: 65039.81,
            price_sources: [],
            processed_ms: 1718384676917,
          },
        ],
        position_type: "FLAT",
        position_uuid: "79e5ebd4-6691-4149-a941-82e485b63df8",
        return_at_close: 1.0283449343964457,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 65089.4152076038,
        close_ms: 1718384986597,
        current_return: 1.0474202142457734,
        initial_entry_price: 65059.86,
        is_closed_position: true,
        miner_hotkey: "5EXWvBCADJo1JVv6jHZPTRuV19YuuJBnjG3stBm3bF5cR9oy",
        net_leverage: 0.0,
        open_ms: 1718384836601,
        orders: [
          {
            leverage: 10.0,
            order_type: "LONG",
            order_uuid: "6b20f939-468a-430a-81a0-0bf0b8f45416",
            price: 65059.86,
            price_sources: [],
            processed_ms: 1718384836187,
          },
          {
            leverage: 9.99,
            order_type: "LONG",
            order_uuid: "a2cff27d-88bd-433b-8791-f17454469bf5",
            price: 65119.0,
            price_sources: [],
            processed_ms: 1718384944478,
          },
          {
            leverage: 0.00999999999999801,
            order_type: "FLAT",
            order_uuid: "9c39d526-782d-4972-a3e4-4f7dfeb31c56",
            price: 65243.75,
            price_sources: [],
            processed_ms: 1718384986597,
          },
        ],
        position_type: "FLAT",
        position_uuid: "73054e9f-d778-45d2-b301-3a1cbc9da2b3",
        return_at_close: 1.0264822841630004,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 65361.75886943472,
        close_ms: 1718385696561,
        current_return: 0.9610015898543298,
        initial_entry_price: 65314.16,
        is_closed_position: true,
        miner_hotkey: "5EXWvBCADJo1JVv6jHZPTRuV19YuuJBnjG3stBm3bF5cR9oy",
        net_leverage: 0.0,
        open_ms: 1718385072696,
        orders: [
          {
            leverage: -5.0,
            order_type: "SHORT",
            order_uuid: "2690f996-44a5-4fef-8508-eb9d9384ca58",
            price: 65314.16,
            price_sources: [],
            processed_ms: 1718385072014,
          },
          {
            leverage: -5.0,
            order_type: "SHORT",
            order_uuid: "31ac012b-49d0-477b-bad9-3293de259c71",
            price: 65305.34,
            price_sources: [],
            processed_ms: 1718385212124,
          },
          {
            leverage: -5.0,
            order_type: "SHORT",
            order_uuid: "d6c49197-acf2-4c6a-96e8-e5fb26327945",
            price: 65413.62,
            price_sources: [],
            processed_ms: 1718385358044,
          },
          {
            leverage: -4.99,
            order_type: "SHORT",
            order_uuid: "4f6f7366-9f4e-48fa-85a5-68f6f41236cc",
            price: 65414.02,
            price_sources: [],
            processed_ms: 1718385391761,
          },
          {
            leverage: 4.99,
            order_type: "FLAT",
            order_uuid: "39b6883e-b840-458d-9cbb-994c990ef41d",
            price: 65489.18,
            price_sources: [],
            processed_ms: 1718385696561,
          },
        ],
        position_type: "FLAT",
        position_uuid: "36b13cbc-b4bc-4d91-91b2-fb7e8bc05547",
        return_at_close: 0.9417911680731418,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 65467.28,
        close_ms: 1718433054751,
        current_return: 1.2515479763326067,
        initial_entry_price: 65467.28,
        is_closed_position: true,
        miner_hotkey: "5EXWvBCADJo1JVv6jHZPTRuV19YuuJBnjG3stBm3bF5cR9oy",
        net_leverage: 0.0,
        open_ms: 1718385727720,
        orders: [
          {
            leverage: 19.99,
            order_type: "LONG",
            order_uuid: "89609031-4f99-4adb-90b8-b9de7ff0577f",
            price: 65467.28,
            price_sources: [],
            processed_ms: 1718385727400,
          },
          {
            leverage: 0.01,
            order_type: "FLAT",
            order_uuid: "c4409f92-b6f4-474d-b344-0fde021adcd3",
            price: 66291.1,
            price_sources: [],
            processed_ms: 1718433054751,
          },
        ],
        position_type: "FLAT",
        position_uuid: "121f3c5b-d852-446f-bc41-e7dd3966b120",
        return_at_close: 1.226529532285718,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 65252.50090909091,
        close_ms: 0,
        current_return: 0.9704590134408915,
        initial_entry_price: 66238.91,
        is_closed_position: false,
        miner_hotkey: "5EXWvBCADJo1JVv6jHZPTRuV19YuuJBnjG3stBm3bF5cR9oy",
        net_leverage: 0.275,
        open_ms: 1718433152581,
        orders: [
          {
            leverage: 0.025,
            order_type: "LONG",
            order_uuid: "d99a2f8b-229d-4554-9cc7-61f8c18153cb",
            price: 66238.91,
            price_sources: [],
            processed_ms: 1718433150718,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "299c0205-74be-48b2-af62-d3fab5c390b2",
            price: 65153.86,
            price_sources: [],
            processed_ms: 1718787342568,
          },
        ],
        position_type: "LONG",
        position_uuid: "d4f7448a-a737-488e-91af-d42e20c30d04",
        return_at_close: 0.9703255753265433,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
    ],
    thirty_day_returns: 1.170019546937052,
  },
  "5EcoYYwH1z2ew73FamyrRSSbWKh9qMoevy8V8ENa2rXiM3uH": {
    all_time_returns: 1.0,
    n_positions: 0,
    percentage_profitable: 0.0,
    positions: [
      {
        average_entry_price: 63471.731999999996,
        close_ms: 1711155007344,
        current_return: 0.9992430502744888,
        initial_entry_price: 63197.46,
        is_closed_position: true,
        miner_hotkey: "5EcoYYwH1z2ew73FamyrRSSbWKh9qMoevy8V8ENa2rXiM3uH",
        net_leverage: 0.0,
        open_ms: 1711149012424,
        orders: [
          {
            leverage: -0.07,
            order_type: "SHORT",
            order_uuid: "12c26860-0d5b-43b5-b92a-ab2df05df981",
            price: 63197.46,
            price_sources: [],
            processed_ms: 1711149012423,
          },
          {
            leverage: -0.07,
            order_type: "SHORT",
            order_uuid: "b79c4766-eb2a-4575-8f12-ffbbbe40f2d8",
            price: 63375.33,
            price_sources: [],
            processed_ms: 1711150230573,
          },
          {
            leverage: -0.07,
            order_type: "SHORT",
            order_uuid: "77a74093-9e11-40f8-8a83-edcab4f30d25",
            price: 63451.73,
            price_sources: [],
            processed_ms: 1711151405092,
          },
          {
            leverage: -0.07,
            order_type: "SHORT",
            order_uuid: "c4773571-326c-4509-bb6e-3d64ba6931f1",
            price: 63699.49,
            price_sources: [],
            processed_ms: 1711152618922,
          },
          {
            leverage: -0.07,
            order_type: "SHORT",
            order_uuid: "428b2966-2145-48e9-9a15-a343982df68d",
            price: 63634.65,
            price_sources: [],
            processed_ms: 1711153800917,
          },
          {
            leverage: 0.07,
            order_type: "FLAT",
            order_uuid: "5105b648-c8cb-46c8-a4bb-64c717c615a4",
            price: 63608.41,
            price_sources: [],
            processed_ms: 1711155007344,
          },
        ],
        position_type: "FLAT",
        position_uuid: "b9e0849f-05e5-4a81-b9c1-8e8a2e6ed7b8",
        return_at_close: 0.9985435801392966,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 64011.484000000004,
        close_ms: 1711162202764,
        current_return: 1.0011123384854816,
        initial_entry_price: 63740.4,
        is_closed_position: true,
        miner_hotkey: "5EcoYYwH1z2ew73FamyrRSSbWKh9qMoevy8V8ENa2rXiM3uH",
        net_leverage: 0.0,
        open_ms: 1711156226714,
        orders: [
          {
            leverage: -0.07,
            order_type: "SHORT",
            order_uuid: "ffa5408e-9d6e-46bb-b7e8-e075c4d68216",
            price: 63740.4,
            price_sources: [],
            processed_ms: 1711156226713,
          },
          {
            leverage: -0.07,
            order_type: "SHORT",
            order_uuid: "dea12e77-0e2c-441c-9601-8888356aa135",
            price: 63956.54,
            price_sources: [],
            processed_ms: 1711157405930,
          },
          {
            leverage: -0.07,
            order_type: "SHORT",
            order_uuid: "3b95ca22-32e9-4b8a-8aab-b4cbc39036d3",
            price: 64304.91,
            price_sources: [],
            processed_ms: 1711158621803,
          },
          {
            leverage: -0.07,
            order_type: "SHORT",
            order_uuid: "4d9a39ac-afde-4aa6-8236-809989fc3d5e",
            price: 64151.3,
            price_sources: [],
            processed_ms: 1711159805184,
          },
          {
            leverage: -0.07,
            order_type: "SHORT",
            order_uuid: "31fdd8f2-c953-4769-813c-5a25d526b432",
            price: 63904.27,
            price_sources: [],
            processed_ms: 1711161022623,
          },
          {
            leverage: 0.07,
            order_type: "FLAT",
            order_uuid: "14acb2d4-7c2f-465f-b4fe-76fee1a21073",
            price: 63808.91,
            price_sources: [],
            processed_ms: 1711162202764,
          },
        ],
        position_type: "FLAT",
        position_uuid: "c01d1fdc-64f9-45cd-83ab-9955f9e807f5",
        return_at_close: 1.0004115598485417,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 63655.350000000006,
        close_ms: 1711169409002,
        current_return: 0.9982852742715175,
        initial_entry_price: 63712.23,
        is_closed_position: true,
        miner_hotkey: "5EcoYYwH1z2ew73FamyrRSSbWKh9qMoevy8V8ENa2rXiM3uH",
        net_leverage: 0.0,
        open_ms: 1711163422553,
        orders: [
          {
            leverage: -0.07,
            order_type: "SHORT",
            order_uuid: "d178fc5a-964b-455e-8bf9-7a0c8069247e",
            price: 63712.23,
            price_sources: [],
            processed_ms: 1711163422552,
          },
          {
            leverage: -0.07,
            order_type: "SHORT",
            order_uuid: "42f5407c-d372-47cf-92e4-ebc0651ee358",
            price: 63522.99,
            price_sources: [],
            processed_ms: 1711164605084,
          },
          {
            leverage: -0.07,
            order_type: "SHORT",
            order_uuid: "894756dc-39b6-4dd1-8bbf-e68b1165c106",
            price: 63432.64,
            price_sources: [],
            processed_ms: 1711165803690,
          },
          {
            leverage: -0.07,
            order_type: "SHORT",
            order_uuid: "dfa2bc98-21f4-40e7-a13c-6e3677b074c6",
            price: 63760.23,
            price_sources: [],
            processed_ms: 1711167013316,
          },
          {
            leverage: -0.07,
            order_type: "SHORT",
            order_uuid: "92816dc3-49aa-4ac0-ae85-d8ae999685c2",
            price: 63848.66,
            price_sources: [],
            processed_ms: 1711168222441,
          },
          {
            leverage: 0.07,
            order_type: "FLAT",
            order_uuid: "e61d7b54-91ea-4541-b882-cbf3b2f01593",
            price: 63967.49,
            price_sources: [],
            processed_ms: 1711169409002,
          },
        ],
        position_type: "FLAT",
        position_uuid: "6821b4dc-68e1-4499-a9ac-75175a12ba38",
        return_at_close: 0.9975864745795274,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 64038.698,
        close_ms: 1711176606703,
        current_return: 0.9996819960378325,
        initial_entry_price: 63848.89,
        is_closed_position: true,
        miner_hotkey: "5EcoYYwH1z2ew73FamyrRSSbWKh9qMoevy8V8ENa2rXiM3uH",
        net_leverage: 0.0,
        open_ms: 1711170625501,
        orders: [
          {
            leverage: -0.07,
            order_type: "SHORT",
            order_uuid: "2cd88ed3-79c5-4188-9d4c-009bd20f0ea9",
            price: 63848.89,
            price_sources: [],
            processed_ms: 1711170625501,
          },
          {
            leverage: -0.07,
            order_type: "SHORT",
            order_uuid: "b4972802-c139-4ee3-b65d-312bc31b7e9b",
            price: 64014.66,
            price_sources: [],
            processed_ms: 1711171801403,
          },
          {
            leverage: -0.07,
            order_type: "SHORT",
            order_uuid: "90a4dae7-2426-4ede-adbb-aea784f9f4f9",
            price: 64047.12,
            price_sources: [],
            processed_ms: 1711173016423,
          },
          {
            leverage: -0.07,
            order_type: "SHORT",
            order_uuid: "cff9949a-9bd9-4231-bce5-53a16b25343f",
            price: 64060.89,
            price_sources: [],
            processed_ms: 1711174238881,
          },
          {
            leverage: -0.07,
            order_type: "SHORT",
            order_uuid: "0cac5144-0adb-48d7-a215-002ac4da1bbd",
            price: 64221.93,
            price_sources: [],
            processed_ms: 1711175413521,
          },
          {
            leverage: 0.07,
            order_type: "FLAT",
            order_uuid: "7fab1ed8-7027-4730-b97e-b981eeae914a",
            price: 64096.71,
            price_sources: [],
            processed_ms: 1711176606703,
          },
        ],
        position_type: "FLAT",
        position_uuid: "dee72b47-294a-42e5-b3fe-86158b3cfa28",
        return_at_close: 0.998982218640606,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 64577.53800000001,
        close_ms: 1711183810218,
        current_return: 1.0005506953239456,
        initial_entry_price: 64469.95,
        is_closed_position: true,
        miner_hotkey: "5EcoYYwH1z2ew73FamyrRSSbWKh9qMoevy8V8ENa2rXiM3uH",
        net_leverage: 0.0,
        open_ms: 1711177820613,
        orders: [
          {
            leverage: -0.07,
            order_type: "SHORT",
            order_uuid: "06c300c8-6960-4c57-a277-4fd35ac79859",
            price: 64469.95,
            price_sources: [],
            processed_ms: 1711177820612,
          },
          {
            leverage: -0.07,
            order_type: "SHORT",
            order_uuid: "e9c08996-4940-4a5b-9649-8442468b2014",
            price: 64580.51,
            price_sources: [],
            processed_ms: 1711179005974,
          },
          {
            leverage: -0.07,
            order_type: "SHORT",
            order_uuid: "7f1d8c7d-1cb5-49b7-9ffd-5e23db725da6",
            price: 64557.56,
            price_sources: [],
            processed_ms: 1711180214532,
          },
          {
            leverage: -0.07,
            order_type: "SHORT",
            order_uuid: "7e730191-8213-430d-8ed5-07e88aaa0e23",
            price: 64812.09,
            price_sources: [],
            processed_ms: 1711181409607,
          },
          {
            leverage: -0.07,
            order_type: "SHORT",
            order_uuid: "16608df1-2cdc-4eb4-bb7d-b5b9ddc807d0",
            price: 64467.58,
            price_sources: [],
            processed_ms: 1711182608776,
          },
          {
            leverage: 0.07,
            order_type: "FLAT",
            order_uuid: "7b33c3a7-a316-4d7a-9748-220b79672f94",
            price: 64476.1,
            price_sources: [],
            processed_ms: 1711183810218,
          },
        ],
        position_type: "FLAT",
        position_uuid: "406aa872-57f0-4615-8a4d-46c1cbb138b7",
        return_at_close: 0.9998503098372188,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 64438.367999999995,
        close_ms: 1711191011002,
        current_return: 0.9995097023619754,
        initial_entry_price: 64426.58,
        is_closed_position: true,
        miner_hotkey: "5EcoYYwH1z2ew73FamyrRSSbWKh9qMoevy8V8ENa2rXiM3uH",
        net_leverage: 0.0,
        open_ms: 1711185023493,
        orders: [
          {
            leverage: -0.07,
            order_type: "SHORT",
            order_uuid: "1003f8ac-69b6-42ce-ac09-cf0c0a63981b",
            price: 64426.58,
            price_sources: [],
            processed_ms: 1711185023492,
          },
          {
            leverage: -0.07,
            order_type: "SHORT",
            order_uuid: "601b32dc-662e-4609-8f6f-2c9e12bd67f1",
            price: 64340.61,
            price_sources: [],
            processed_ms: 1711186206856,
          },
          {
            leverage: -0.07,
            order_type: "SHORT",
            order_uuid: "cf608cc2-7614-4f8a-be2b-3dfd725458b1",
            price: 64297.55,
            price_sources: [],
            processed_ms: 1711187429733,
          },
          {
            leverage: -0.07,
            order_type: "SHORT",
            order_uuid: "4065d328-ea73-4765-9f7a-fae31425c9ca",
            price: 64546.98,
            price_sources: [],
            processed_ms: 1711188618746,
          },
          {
            leverage: -0.07,
            order_type: "SHORT",
            order_uuid: "74fa35b2-5c7a-4d26-b5ca-9951e6fa379f",
            price: 64580.12,
            price_sources: [],
            processed_ms: 1711189806719,
          },
          {
            leverage: 0.07,
            order_type: "FLAT",
            order_uuid: "e45203e0-767b-45ac-9938-6c1a130c0f85",
            price: 64528.62,
            price_sources: [],
            processed_ms: 1711191011002,
          },
        ],
        position_type: "FLAT",
        position_uuid: "0fa2610f-5caf-4239-9275-a84afb4f1b23",
        return_at_close: 0.998810045570322,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 64547.448,
        close_ms: 1711198224410,
        current_return: 0.9986244758433576,
        initial_entry_price: 64319.99,
        is_closed_position: true,
        miner_hotkey: "5EcoYYwH1z2ew73FamyrRSSbWKh9qMoevy8V8ENa2rXiM3uH",
        net_leverage: 0.0,
        open_ms: 1711192225945,
        orders: [
          {
            leverage: -0.07,
            order_type: "SHORT",
            order_uuid: "29321313-979e-4383-8c88-86ee53d51c71",
            price: 64319.99,
            price_sources: [],
            processed_ms: 1711192225944,
          },
          {
            leverage: -0.07,
            order_type: "SHORT",
            order_uuid: "a2e46c27-e66f-4fcf-a7a2-2e6b34a29d02",
            price: 64433.37,
            price_sources: [],
            processed_ms: 1711193422005,
          },
          {
            leverage: -0.07,
            order_type: "SHORT",
            order_uuid: "d0e4b4ac-5e08-4053-a093-acd30686ed00",
            price: 64549.72,
            price_sources: [],
            processed_ms: 1711194610238,
          },
          {
            leverage: -0.07,
            order_type: "SHORT",
            order_uuid: "40e64c55-975c-4ad4-aa21-0a3f1a5bcbda",
            price: 64724.99,
            price_sources: [],
            processed_ms: 1711195818931,
          },
          {
            leverage: -0.07,
            order_type: "SHORT",
            order_uuid: "82da365a-255e-4206-97d9-dd7231f000c9",
            price: 64709.17,
            price_sources: [],
            processed_ms: 1711197009447,
          },
          {
            leverage: 0.07,
            order_type: "FLAT",
            order_uuid: "c92c7c00-039f-4942-a505-cb0b4bfc8395",
            price: 64800.23,
            price_sources: [],
            processed_ms: 1711198224410,
          },
        ],
        position_type: "FLAT",
        position_uuid: "6a262461-369e-4933-8e69-7f80884eff7c",
        return_at_close: 0.9979254387102672,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 64610.828,
        close_ms: 1711205436043,
        current_return: 0.9963511232574955,
        initial_entry_price: 64571.57,
        is_closed_position: true,
        miner_hotkey: "5EcoYYwH1z2ew73FamyrRSSbWKh9qMoevy8V8ENa2rXiM3uH",
        net_leverage: 0.0,
        open_ms: 1711199411034,
        orders: [
          {
            leverage: -0.07,
            order_type: "SHORT",
            order_uuid: "0d9a2d3e-b909-4b51-9b01-2f97f2f86668",
            price: 64571.57,
            price_sources: [],
            processed_ms: 1711199411033,
          },
          {
            leverage: -0.07,
            order_type: "SHORT",
            order_uuid: "a47bdc96-0a72-4e7e-9427-2f0ca74a24ff",
            price: 64542.83,
            price_sources: [],
            processed_ms: 1711200624161,
          },
          {
            leverage: -0.07,
            order_type: "SHORT",
            order_uuid: "31b625b8-d39a-4a8f-832d-08eeacc267fb",
            price: 64526.68,
            price_sources: [],
            processed_ms: 1711201813348,
          },
          {
            leverage: -0.07,
            order_type: "SHORT",
            order_uuid: "0f33e8d3-56d0-46e6-9340-fc1f869ae24f",
            price: 64597.63,
            price_sources: [],
            processed_ms: 1711203012216,
          },
          {
            leverage: -0.07,
            order_type: "SHORT",
            order_uuid: "13406d7d-6724-4f23-bca9-6d6087d73f07",
            price: 64815.43,
            price_sources: [],
            processed_ms: 1711204219077,
          },
          {
            leverage: 0.07,
            order_type: "FLAT",
            order_uuid: "06b5ba73-b906-4aa8-a46d-f1a5b14731d0",
            price: 65284.01,
            price_sources: [],
            processed_ms: 1711205436043,
          },
        ],
        position_type: "FLAT",
        position_uuid: "e5819f7f-4af5-4cde-b4ba-d6c722f30e2f",
        return_at_close: 0.9956536774712152,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 65073.484,
        close_ms: 1711212618392,
        current_return: 0.9968716781306731,
        initial_entry_price: 65241.88,
        is_closed_position: true,
        miner_hotkey: "5EcoYYwH1z2ew73FamyrRSSbWKh9qMoevy8V8ENa2rXiM3uH",
        net_leverage: 0.0,
        open_ms: 1711206619595,
        orders: [
          {
            leverage: -0.07,
            order_type: "SHORT",
            order_uuid: "74d1b255-96bc-4d77-85da-d39781f15fec",
            price: 65241.88,
            price_sources: [],
            processed_ms: 1711206619593,
          },
          {
            leverage: -0.07,
            order_type: "SHORT",
            order_uuid: "a54c1fa0-60ac-4c16-9641-a8c96b9c4857",
            price: 64823.21,
            price_sources: [],
            processed_ms: 1711207820531,
          },
          {
            leverage: -0.07,
            order_type: "SHORT",
            order_uuid: "5fca616e-d0aa-4bb5-8f99-487a431a17c8",
            price: 64927.1,
            price_sources: [],
            processed_ms: 1711209016365,
          },
          {
            leverage: -0.07,
            order_type: "SHORT",
            order_uuid: "2bd54a68-f6db-4360-9e64-3f489a454f7b",
            price: 65101.27,
            price_sources: [],
            processed_ms: 1711210277692,
          },
          {
            leverage: -0.07,
            order_type: "SHORT",
            order_uuid: "8b68b643-b250-427d-9701-75e16218ac33",
            price: 65273.96,
            price_sources: [],
            processed_ms: 1711211431188,
          },
          {
            leverage: 0.07,
            order_type: "FLAT",
            order_uuid: "0d3ab3ad-1432-4bef-8905-65de641a55fd",
            price: 65656.62,
            price_sources: [],
            processed_ms: 1711212618392,
          },
        ],
        position_type: "FLAT",
        position_uuid: "cbdcdd08-5cd1-46c8-ae84-86f94a6ffe37",
        return_at_close: 0.9961738679559816,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 65651.42,
        close_ms: 1711219816237,
        current_return: 1.0013438414819475,
        initial_entry_price: 65637.95,
        is_closed_position: true,
        miner_hotkey: "5EcoYYwH1z2ew73FamyrRSSbWKh9qMoevy8V8ENa2rXiM3uH",
        net_leverage: 0.0,
        open_ms: 1711213836647,
        orders: [
          {
            leverage: -0.07,
            order_type: "SHORT",
            order_uuid: "3f7ca4b9-f5f4-446e-a36e-dc69e2f5b521",
            price: 65637.95,
            price_sources: [],
            processed_ms: 1711213836645,
          },
          {
            leverage: -0.07,
            order_type: "SHORT",
            order_uuid: "f34b497b-345e-40cd-817e-1afb52e6df38",
            price: 65673.99,
            price_sources: [],
            processed_ms: 1711215020239,
          },
          {
            leverage: -0.07,
            order_type: "SHORT",
            order_uuid: "a5295ed1-4522-4a90-9cbc-5d1918777f03",
            price: 65775.2,
            price_sources: [],
            processed_ms: 1711216233359,
          },
          {
            leverage: -0.07,
            order_type: "SHORT",
            order_uuid: "563b4c1b-799b-41a8-a405-f5994f8f1f33",
            price: 65656.23,
            price_sources: [],
            processed_ms: 1711217421830,
          },
          {
            leverage: -0.07,
            order_type: "SHORT",
            order_uuid: "16b31a45-b26d-42f2-aac9-540c4270b756",
            price: 65513.73,
            price_sources: [],
            processed_ms: 1711218629296,
          },
          {
            leverage: 0.07,
            order_type: "FLAT",
            order_uuid: "bbc0b3ef-2bc7-4b66-bfe7-1ab8660b5437",
            price: 65399.4,
            price_sources: [],
            processed_ms: 1711219816237,
          },
        ],
        position_type: "FLAT",
        position_uuid: "44ca105d-d6b9-4ac6-8ac4-1e3f92e0699f",
        return_at_close: 1.00064290079291,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 65019.766,
        close_ms: 1711227028641,
        current_return: 1.0003945741407185,
        initial_entry_price: 65344.12,
        is_closed_position: true,
        miner_hotkey: "5EcoYYwH1z2ew73FamyrRSSbWKh9qMoevy8V8ENa2rXiM3uH",
        net_leverage: 0.0,
        open_ms: 1711221030996,
        orders: [
          {
            leverage: -0.07,
            order_type: "SHORT",
            order_uuid: "6508c982-cf44-4ece-b8c6-1d8da3184775",
            price: 65344.12,
            price_sources: [],
            processed_ms: 1711221030528,
          },
          {
            leverage: -0.07,
            order_type: "SHORT",
            order_uuid: "a8f9ee5a-9f83-418d-9164-8baf4b2058a0",
            price: 65136.07,
            price_sources: [],
            processed_ms: 1711222223027,
          },
          {
            leverage: -0.07,
            order_type: "SHORT",
            order_uuid: "a76ac8da-31e2-489d-af1f-6cd220399035",
            price: 64886.53,
            price_sources: [],
            processed_ms: 1711223423672,
          },
          {
            leverage: -0.07,
            order_type: "SHORT",
            order_uuid: "5f2c639a-2ee8-41c4-b2be-072b1715bc0b",
            price: 64720.14,
            price_sources: [],
            processed_ms: 1711224634460,
          },
          {
            leverage: -0.07,
            order_type: "SHORT",
            order_uuid: "74965a70-b71e-491f-9764-4a64be423184",
            price: 65011.97,
            price_sources: [],
            processed_ms: 1711225824068,
          },
          {
            leverage: 0.07,
            order_type: "FLAT",
            order_uuid: "efb34643-8b32-4b69-90f6-75b37408155d",
            price: 64946.1,
            price_sources: [],
            processed_ms: 1711227028641,
          },
        ],
        position_type: "FLAT",
        position_uuid: "d69a2328-5718-4012-9937-f5799c2fb5b2",
        return_at_close: 0.99969429793882,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 64933.31399999999,
        close_ms: 1711234225421,
        current_return: 1.0005762416292279,
        initial_entry_price: 64901.42,
        is_closed_position: true,
        miner_hotkey: "5EcoYYwH1z2ew73FamyrRSSbWKh9qMoevy8V8ENa2rXiM3uH",
        net_leverage: 0.0,
        open_ms: 1711228241594,
        orders: [
          {
            leverage: -0.07,
            order_type: "SHORT",
            order_uuid: "161013bd-8a86-4240-8cdf-31bb99cead67",
            price: 64901.42,
            price_sources: [],
            processed_ms: 1711228241593,
          },
          {
            leverage: -0.07,
            order_type: "SHORT",
            order_uuid: "2b6f4d60-1f1c-4725-be7d-763507b038b1",
            price: 64858.54,
            price_sources: [],
            processed_ms: 1711229424989,
          },
          {
            leverage: -0.07,
            order_type: "SHORT",
            order_uuid: "486ba2b5-9ab0-4783-9f11-4ef81e3be764",
            price: 64962.85,
            price_sources: [],
            processed_ms: 1711230638798,
          },
          {
            leverage: -0.07,
            order_type: "SHORT",
            order_uuid: "1cf17011-aace-4528-b2b0-e738cc17a5bd",
            price: 64969.87,
            price_sources: [],
            processed_ms: 1711231817819,
          },
          {
            leverage: -0.07,
            order_type: "SHORT",
            order_uuid: "0df000d2-d30b-4019-9dab-dc7e5e531cdd",
            price: 64973.89,
            price_sources: [],
            processed_ms: 1711233028636,
          },
          {
            leverage: 0.07,
            order_type: "FLAT",
            order_uuid: "e63eeb25-8d70-4419-959d-e23316913ee7",
            price: 64826.46,
            price_sources: [],
            processed_ms: 1711234225421,
          },
        ],
        position_type: "FLAT",
        position_uuid: "12393466-724b-4bc4-b6b1-9932bc027118",
        return_at_close: 0.9998758382600874,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 64406.020000000004,
        close_ms: 1711241424649,
        current_return: 0.9990266675412084,
        initial_entry_price: 64643.38,
        is_closed_position: true,
        miner_hotkey: "5EcoYYwH1z2ew73FamyrRSSbWKh9qMoevy8V8ENa2rXiM3uH",
        net_leverage: 0.0,
        open_ms: 1711235441085,
        orders: [
          {
            leverage: -0.07,
            order_type: "SHORT",
            order_uuid: "244a3142-dfef-4222-bd4f-ade4da5de21d",
            price: 64643.38,
            price_sources: [],
            processed_ms: 1711235441083,
          },
          {
            leverage: -0.07,
            order_type: "SHORT",
            order_uuid: "143d70ec-76ea-47b1-9be4-4e4bac7e8d3c",
            price: 64407.78,
            price_sources: [],
            processed_ms: 1711236621367,
          },
          {
            leverage: -0.07,
            order_type: "SHORT",
            order_uuid: "5a76c9e6-8b35-4a89-aff4-ea9536c9abbf",
            price: 64306.58,
            price_sources: [],
            processed_ms: 1711237832004,
          },
          {
            leverage: -0.07,
            order_type: "SHORT",
            order_uuid: "113afd47-b05b-4cfb-b7bf-6a00ee32c878",
            price: 64289.43,
            price_sources: [],
            processed_ms: 1711239024717,
          },
          {
            leverage: -0.07,
            order_type: "SHORT",
            order_uuid: "b8ad45d9-da11-45d7-870d-fe873b279667",
            price: 64382.93,
            price_sources: [],
            processed_ms: 1711240245450,
          },
          {
            leverage: 0.07,
            order_type: "FLAT",
            order_uuid: "ae46bac4-0a32-4580-9585-b9788bba4000",
            price: 64585.79,
            price_sources: [],
            processed_ms: 1711241424649,
          },
        ],
        position_type: "FLAT",
        position_uuid: "40f451fc-45bb-4f0c-80e7-d8eafc997dda",
        return_at_close: 0.9983273488739295,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 64502.844000000005,
        close_ms: 1711248626446,
        current_return: 1.0011778718065867,
        initial_entry_price: 64502.69,
        is_closed_position: true,
        miner_hotkey: "5EcoYYwH1z2ew73FamyrRSSbWKh9qMoevy8V8ENa2rXiM3uH",
        net_leverage: 0.0,
        open_ms: 1711242625318,
        orders: [
          {
            leverage: -0.07,
            order_type: "SHORT",
            order_uuid: "434419ae-67ad-49bf-a04d-83440063865e",
            price: 64502.69,
            price_sources: [],
            processed_ms: 1711242625316,
          },
          {
            leverage: -0.07,
            order_type: "SHORT",
            order_uuid: "8bbd151a-4eec-425b-a0fa-487bf19d45dd",
            price: 64615.84,
            price_sources: [],
            processed_ms: 1711243833139,
          },
          {
            leverage: -0.07,
            order_type: "SHORT",
            order_uuid: "b68b9f45-feff-4955-aa9f-999cc128f86b",
            price: 64504.61,
            price_sources: [],
            processed_ms: 1711245020386,
          },
          {
            leverage: -0.07,
            order_type: "SHORT",
            order_uuid: "9e228168-8490-4196-b209-a27c0957d5a3",
            price: 64536.4,
            price_sources: [],
            processed_ms: 1711246238672,
          },
          {
            leverage: -0.07,
            order_type: "SHORT",
            order_uuid: "81345b2c-e423-4879-95d5-5261ea334f5b",
            price: 64354.68,
            price_sources: [],
            processed_ms: 1711247423743,
          },
          {
            leverage: 0.07,
            order_type: "FLAT",
            order_uuid: "5a060131-09df-4eb6-b3a6-f006b8546b7e",
            price: 64285.77,
            price_sources: [],
            processed_ms: 1711248626446,
          },
        ],
        position_type: "FLAT",
        position_uuid: "631ae39a-644f-4b75-9c0d-1fe73f296f6b",
        return_at_close: 1.000477047296322,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 64090.700000000004,
        close_ms: 1711255826290,
        current_return: 1.0002328881900777,
        initial_entry_price: 64007.11,
        is_closed_position: true,
        miner_hotkey: "5EcoYYwH1z2ew73FamyrRSSbWKh9qMoevy8V8ENa2rXiM3uH",
        net_leverage: 0.0,
        open_ms: 1711249830447,
        orders: [
          {
            leverage: -0.07,
            order_type: "SHORT",
            order_uuid: "cf4d382c-14c0-418c-b280-b722f0e343fe",
            price: 64007.11,
            price_sources: [],
            processed_ms: 1711249830446,
          },
          {
            leverage: -0.07,
            order_type: "SHORT",
            order_uuid: "bf9d8a72-32cf-4cb1-944f-20c8c9cec378",
            price: 64226.21,
            price_sources: [],
            processed_ms: 1711251042419,
          },
          {
            leverage: -0.07,
            order_type: "SHORT",
            order_uuid: "028648f7-afab-49c9-8f39-1050d4855356",
            price: 64116.47,
            price_sources: [],
            processed_ms: 1711252244198,
          },
          {
            leverage: -0.07,
            order_type: "SHORT",
            order_uuid: "816d1348-eea8-4dab-af1d-e0093e9b3f82",
            price: 64164.43,
            price_sources: [],
            processed_ms: 1711253429683,
          },
          {
            leverage: -0.07,
            order_type: "SHORT",
            order_uuid: "e13ea646-7d65-48a5-bcbe-3a529f7ae4a1",
            price: 63939.28,
            price_sources: [],
            processed_ms: 1711254627295,
          },
          {
            leverage: 0.07,
            order_type: "FLAT",
            order_uuid: "f361787a-d0f2-4497-aac9-941473687da3",
            price: 64048.11,
            price_sources: [],
            processed_ms: 1711255826290,
          },
        ],
        position_type: "FLAT",
        position_uuid: "a7314f80-c1b7-4268-a809-1bfee7fa1ea8",
        return_at_close: 0.9995327251683447,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 64285.23600000001,
        close_ms: 1711263033499,
        current_return: 1.0006119913703713,
        initial_entry_price: 64188.16,
        is_closed_position: true,
        miner_hotkey: "5EcoYYwH1z2ew73FamyrRSSbWKh9qMoevy8V8ENa2rXiM3uH",
        net_leverage: 0.0,
        open_ms: 1711257045422,
        orders: [
          {
            leverage: -0.07,
            order_type: "SHORT",
            order_uuid: "1e1da727-f313-4bc5-9914-ccf4eca72c85",
            price: 64188.16,
            price_sources: [],
            processed_ms: 1711257045420,
          },
          {
            leverage: -0.07,
            order_type: "SHORT",
            order_uuid: "5849be92-5ef3-49e5-b4f5-84baf33cef8b",
            price: 64298.45,
            price_sources: [],
            processed_ms: 1711258243303,
          },
          {
            leverage: -0.07,
            order_type: "SHORT",
            order_uuid: "62fd51d1-0aca-40ee-8cf3-d4c7ad848ef6",
            price: 64353.97,
            price_sources: [],
            processed_ms: 1711259448191,
          },
          {
            leverage: -0.07,
            order_type: "SHORT",
            order_uuid: "06943707-a4bd-4452-b531-a194bf082607",
            price: 64269.23,
            price_sources: [],
            processed_ms: 1711260630143,
          },
          {
            leverage: -0.07,
            order_type: "SHORT",
            order_uuid: "5a7637c4-8d95-4fea-b4df-4e93fb9a2fb0",
            price: 64316.37,
            price_sources: [],
            processed_ms: 1711261837259,
          },
          {
            leverage: 0.07,
            order_type: "FLAT",
            order_uuid: "7cd260d4-c512-4220-8c15-05e6abf07d31",
            price: 64173.0,
            price_sources: [],
            processed_ms: 1711263033499,
          },
        ],
        position_type: "FLAT",
        position_uuid: "aeba47ea-7ed3-4069-a643-8c26f05024e8",
        return_at_close: 0.9999115629764119,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 66355.97425081432,
        close_ms: 1711315581362,
        current_return: 1.0183938350445993,
        initial_entry_price: 64140.61,
        is_closed_position: true,
        miner_hotkey: "5EcoYYwH1z2ew73FamyrRSSbWKh9qMoevy8V8ENa2rXiM3uH",
        net_leverage: 0.0,
        open_ms: 1711264227640,
        orders: [
          {
            leverage: -0.07,
            order_type: "SHORT",
            order_uuid: "4dd9d795-fdcc-42bf-bc64-0075a86e96d4",
            price: 64140.61,
            price_sources: [],
            processed_ms: 1711264227638,
          },
          {
            leverage: -0.07,
            order_type: "SHORT",
            order_uuid: "9987ad80-8292-496a-9861-9ed10e53b952",
            price: 64143.95,
            price_sources: [],
            processed_ms: 1711265437319,
          },
          {
            leverage: -0.07,
            order_type: "SHORT",
            order_uuid: "e3822046-355f-4ee0-83c3-8c3b4c43df8a",
            price: 64322.28,
            price_sources: [],
            processed_ms: 1711266645062,
          },
          {
            leverage: -0.07,
            order_type: "SHORT",
            order_uuid: "b2223639-d4e0-4b2b-8230-19d196b81307",
            price: 64767.5,
            price_sources: [],
            processed_ms: 1711267835024,
          },
          {
            leverage: -12.0,
            order_type: "SHORT",
            order_uuid: "d8160a79-8fc8-4ae1-bff6-38d598b408d1",
            price: 66402.93,
            price_sources: [],
            processed_ms: 1711315236648,
          },
          {
            leverage: 10.0,
            order_type: "FLAT",
            order_uuid: "b5a7a27d-1f96-481b-9df0-ac8572810303",
            price: 66259.9,
            price_sources: [],
            processed_ms: 1711315581362,
          },
        ],
        position_type: "FLAT",
        position_uuid: "4e0ddc28-5fc7-4601-8e79-7b1ddc4bd716",
        return_at_close: 0.993382082455904,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 66523.0,
        close_ms: 1711317780964,
        current_return: 1.0735088164995554,
        initial_entry_price: 66523.0,
        is_closed_position: true,
        miner_hotkey: "5EcoYYwH1z2ew73FamyrRSSbWKh9qMoevy8V8ENa2rXiM3uH",
        net_leverage: 0.0,
        open_ms: 1711317506716,
        orders: [
          {
            leverage: 19.9,
            order_type: "LONG",
            order_uuid: "d6eee443-a42c-4319-b78f-d81ce31134ae",
            price: 66523.0,
            price_sources: [],
            processed_ms: 1711317506713,
          },
          {
            leverage: 0.10000000000000142,
            order_type: "FLAT",
            order_uuid: "c2fe9b7d-f0be-41ba-82e1-69562005fbdb",
            price: 66768.73,
            price_sources: [],
            processed_ms: 1711317780964,
          },
        ],
        position_type: "FLAT",
        position_uuid: "086a7bbc-8c35-4ec9-8b90-f8fa2222a656",
        return_at_close: 1.0307831656028732,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 69302.04,
        close_ms: 1711737388755,
        current_return: 1.0225729285891159,
        initial_entry_price: 69302.04,
        is_closed_position: true,
        miner_hotkey: "5EcoYYwH1z2ew73FamyrRSSbWKh9qMoevy8V8ENa2rXiM3uH",
        net_leverage: 0.0,
        open_ms: 1711733944700,
        orders: [
          {
            leverage: 5.0,
            order_type: "LONG",
            order_uuid: "0a5cbbea-d41c-4e3f-b8c9-aba402d8d9d2",
            price: 69302.04,
            price_sources: [],
            processed_ms: 1711733944697,
          },
          {
            leverage: 4.0,
            order_type: "FLAT",
            order_uuid: "d8628ae8-e23c-41dc-95d4-e3bb49b946bf",
            price: 69614.91,
            price_sources: [],
            processed_ms: 1711737388755,
          },
        ],
        position_type: "FLAT",
        position_uuid: "dda353c4-3ba3-435e-886a-ecd8843f36e0",
        return_at_close: 1.0123471993032247,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 70071.58,
        close_ms: 1711891677403,
        current_return: 1.0088478096255287,
        initial_entry_price: 70071.58,
        is_closed_position: true,
        miner_hotkey: "5EcoYYwH1z2ew73FamyrRSSbWKh9qMoevy8V8ENa2rXiM3uH",
        net_leverage: 0.0,
        open_ms: 1711776429410,
        orders: [
          {
            leverage: 1.0,
            order_type: "LONG",
            order_uuid: "84ab50ac-73ed-4492-841c-90265262b80a",
            price: 70071.58,
            price_sources: [],
            processed_ms: 1711776429407,
          },
          {
            leverage: 8.0,
            order_type: "FLAT",
            order_uuid: "9fe75f04-56c7-4b04-b137-e6d186dfe0da",
            price: 70691.56,
            price_sources: [],
            processed_ms: 1711891677403,
          },
        ],
        position_type: "FLAT",
        position_uuid: "bb19f7f7-3396-431b-a0e0-f42266c7dc2c",
        return_at_close: 1.0068301140062776,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 70729.29,
        close_ms: 1711954612492,
        current_return: 1.0147985367872348,
        initial_entry_price: 70729.29,
        is_closed_position: true,
        miner_hotkey: "5EcoYYwH1z2ew73FamyrRSSbWKh9qMoevy8V8ENa2rXiM3uH",
        net_leverage: 0.0,
        open_ms: 1711892443104,
        orders: [
          {
            leverage: -1.0,
            order_type: "SHORT",
            order_uuid: "1bf832ea-4352-4bb8-a99d-5809cb8ad740",
            price: 70729.29,
            price_sources: [],
            processed_ms: 1711892443102,
          },
          {
            leverage: 5.0,
            order_type: "FLAT",
            order_uuid: "b31d34b1-c0a8-4b89-9a9a-1fc34e1b332a",
            price: 69682.6,
            price_sources: [],
            processed_ms: 1711954612492,
          },
        ],
        position_type: "FLAT",
        position_uuid: "b417b8b6-1173-4150-9b39-b6d979e1f87e",
        return_at_close: 1.0127689397136603,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 0.64829,
        close_ms: 1711991358135,
        current_return: 0.9614370112141214,
        initial_entry_price: 0.64829,
        is_closed_position: true,
        miner_hotkey: "5EcoYYwH1z2ew73FamyrRSSbWKh9qMoevy8V8ENa2rXiM3uH",
        net_leverage: 0.0,
        open_ms: 1711990637452,
        orders: [
          {
            leverage: -100.0,
            order_type: "SHORT",
            order_uuid: "7e5705ef-3045-428a-8272-2fc38ec8d2d1",
            price: 0.64829,
            price_sources: [],
            processed_ms: 1711990637450,
          },
          {
            leverage: 100.0,
            order_type: "FLAT",
            order_uuid: "0210f018-5ef6-4834-aa02-52330c59d0c7",
            price: 0.64854,
            price_sources: [],
            processed_ms: 1711991358135,
          },
        ],
        position_type: "FLAT",
        position_uuid: "42f134af-c555-4724-88f3-efa29a4a28e7",
        return_at_close: 0.9547069521356225,
        trade_pair: ["AUDUSD", "AUD/USD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.64855,
        close_ms: 1711991786880,
        current_return: 0.9306190733174312,
        initial_entry_price: 0.64855,
        is_closed_position: true,
        miner_hotkey: "5EcoYYwH1z2ew73FamyrRSSbWKh9qMoevy8V8ENa2rXiM3uH",
        net_leverage: 300.0,
        open_ms: 1711991422205,
        orders: [
          {
            leverage: 300.0,
            order_type: "LONG",
            order_uuid: "df584d3c-73fb-40fa-8e65-332e5afca35f",
            price: 0.64855,
            price_sources: [],
            processed_ms: 1711991422202,
          },
        ],
        position_type: "FLAT",
        position_uuid: "42fd3bc6-8c84-48f1-a1b2-c3028524377e",
        return_at_close: 0.9110760727777651,
        trade_pair: ["AUDUSD", "AUD/USD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 69653.1,
        close_ms: 1711991786880,
        current_return: 0.98364322621678,
        initial_entry_price: 69653.1,
        is_closed_position: true,
        miner_hotkey: "5EcoYYwH1z2ew73FamyrRSSbWKh9qMoevy8V8ENa2rXiM3uH",
        net_leverage: 1.0,
        open_ms: 1711954715050,
        orders: [
          {
            leverage: 1.0,
            order_type: "LONG",
            order_uuid: "a4dc3b81-ddce-4dc7-87e8-937326a4a2b7",
            price: 69653.1,
            price_sources: [],
            processed_ms: 1711954715047,
          },
        ],
        position_type: "FLAT",
        position_uuid: "6816bacf-5c4c-41c6-b749-15b454c7f3d1",
        return_at_close: 0.9816759397643464,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
    ],
    thirty_day_returns: 1.0,
  },
  "5EfTAkz7d3p9D7y8eaAcewhYpxTk8KbSRrwynNg9NR2aQMjo": {
    all_time_returns: 1.0,
    n_positions: 0,
    percentage_profitable: 0.0,
    positions: [
      {
        average_entry_price: 65144.405000000006,
        close_ms: 1711212335996,
        current_return: 1.0871613636014794,
        initial_entry_price: 65151.0,
        is_closed_position: true,
        miner_hotkey: "5EfTAkz7d3p9D7y8eaAcewhYpxTk8KbSRrwynNg9NR2aQMjo",
        net_leverage: 0.0,
        open_ms: 1711210685192,
        orders: [
          {
            leverage: 5.0,
            order_type: "LONG",
            order_uuid: "d667e4e8-7688-4fcf-8c94-0e21e353fd3b",
            price: 65151.0,
            price_sources: [],
            processed_ms: 1711210685192,
          },
          {
            leverage: 5.0,
            order_type: "LONG",
            order_uuid: "8de38f56-70d7-4774-9b87-9b93c86597ab",
            price: 65137.81,
            price_sources: [],
            processed_ms: 1711210932054,
          },
          {
            leverage: 8.0,
            order_type: "FLAT",
            order_uuid: "40ef7bbd-af80-4753-a93b-c4666ee55064",
            price: 65712.27,
            price_sources: [],
            processed_ms: 1711212335996,
          },
        ],
        position_type: "FLAT",
        position_uuid: "49e52df2-d858-40d8-a3d6-050611fd74a9",
        return_at_close: 1.0654181363294497,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 64934.49,
        close_ms: 1711225964599,
        current_return: 1.0045498162840736,
        initial_entry_price: 64934.49,
        is_closed_position: true,
        miner_hotkey: "5EfTAkz7d3p9D7y8eaAcewhYpxTk8KbSRrwynNg9NR2aQMjo",
        net_leverage: 0.0,
        open_ms: 1711224236744,
        orders: [
          {
            leverage: 3.0,
            order_type: "LONG",
            order_uuid: "566c34fb-cbb8-433a-95ad-ac260180f29d",
            price: 64934.49,
            price_sources: [],
            processed_ms: 1711224236743,
          },
          {
            leverage: 3.0,
            order_type: "FLAT",
            order_uuid: "9b865008-62d4-42ca-9cc5-e060435d2c59",
            price: 65032.97,
            price_sources: [],
            processed_ms: 1711225964599,
          },
        ],
        position_type: "FLAT",
        position_uuid: "838095d9-19bf-4e18-ad61-7e1dc4d73688",
        return_at_close: 0.9985225173863691,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 64160.31999999999,
        close_ms: 1711264479531,
        current_return: 1.0011639134478447,
        initial_entry_price: 64180.03,
        is_closed_position: true,
        miner_hotkey: "5EfTAkz7d3p9D7y8eaAcewhYpxTk8KbSRrwynNg9NR2aQMjo",
        net_leverage: 0.0,
        open_ms: 1711264034985,
        orders: [
          {
            leverage: 5.0,
            order_type: "LONG",
            order_uuid: "d081cd0c-0a31-4687-b8cb-352d1219c44e",
            price: 64180.03,
            price_sources: [],
            processed_ms: 1711264034985,
          },
          {
            leverage: 5.0,
            order_type: "LONG",
            order_uuid: "ff6110fc-7a02-4fa6-bda0-8bdac416c71d",
            price: 64140.61,
            price_sources: [],
            processed_ms: 1711264257582,
          },
          {
            leverage: 8.0,
            order_type: "FLAT",
            order_uuid: "d4f000dd-3b38-402f-b5ea-1263e6f88115",
            price: 64167.79,
            price_sources: [],
            processed_ms: 1711264479531,
          },
        ],
        position_type: "FLAT",
        position_uuid: "bbb95172-8ddf-4af4-bb3a-be11e8ae3c15",
        return_at_close: 0.9811406351788878,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 64142.48,
        close_ms: 1711265079505,
        current_return: 0.9983115713642517,
        initial_entry_price: 64142.48,
        is_closed_position: true,
        miner_hotkey: "5EfTAkz7d3p9D7y8eaAcewhYpxTk8KbSRrwynNg9NR2aQMjo",
        net_leverage: 0.0,
        open_ms: 1711264743418,
        orders: [
          {
            leverage: -15.0,
            order_type: "SHORT",
            order_uuid: "c0afe58a-ca7e-4030-af8f-0cc39a5f16c3",
            price: 64142.48,
            price_sources: [],
            processed_ms: 1711264743417,
          },
          {
            leverage: 15.0,
            order_type: "FLAT",
            order_uuid: "e0a687f5-3ded-4b8a-b81b-d674339de793",
            price: 64149.7,
            price_sources: [],
            processed_ms: 1711265079505,
          },
        ],
        position_type: "FLAT",
        position_uuid: "74beb276-38e3-422f-b204-242b732c0b85",
        return_at_close: 0.9683622242233242,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 64172.42,
        close_ms: 1711265384471,
        current_return: 0.9957154490979148,
        initial_entry_price: 64172.42,
        is_closed_position: true,
        miner_hotkey: "5EfTAkz7d3p9D7y8eaAcewhYpxTk8KbSRrwynNg9NR2aQMjo",
        net_leverage: 0.0,
        open_ms: 1711265260452,
        orders: [
          {
            leverage: 5.0,
            order_type: "LONG",
            order_uuid: "b81b25f3-67db-4a38-9e85-f5ea4f272d6d",
            price: 64172.42,
            price_sources: [],
            processed_ms: 1711265260451,
          },
          {
            leverage: 15.0,
            order_type: "FLAT",
            order_uuid: "8da383f5-4642-41af-be3d-acade00eb540",
            price: 64117.43,
            price_sources: [],
            processed_ms: 1711265384471,
          },
        ],
        position_type: "FLAT",
        position_uuid: "1221800c-2731-43e2-8ad6-cfc4ca990572",
        return_at_close: 0.9857582946069356,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 64124.68,
        close_ms: 1711266362684,
        current_return: 0.9341213086755371,
        initial_entry_price: 64124.68,
        is_closed_position: true,
        miner_hotkey: "5EfTAkz7d3p9D7y8eaAcewhYpxTk8KbSRrwynNg9NR2aQMjo",
        net_leverage: 0.0,
        open_ms: 1711265497786,
        orders: [
          {
            leverage: -15.0,
            order_type: "SHORT",
            order_uuid: "db38b700-ba6f-41fb-9d25-daaa114644bc",
            price: 64124.68,
            price_sources: [],
            processed_ms: 1711265497785,
          },
          {
            leverage: 15.0,
            order_type: "FLAT",
            order_uuid: "22537036-df27-412e-a3e2-a2ec55674eb2",
            price: 64406.31,
            price_sources: [],
            processed_ms: 1711266362684,
          },
        ],
        position_type: "FLAT",
        position_uuid: "4d011be1-f63c-452b-99d0-657e62dc82e0",
        return_at_close: 0.9060976694152709,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 64335.45,
        close_ms: 1711267243466,
        current_return: 1.0597384490199417,
        initial_entry_price: 64335.45,
        is_closed_position: true,
        miner_hotkey: "5EfTAkz7d3p9D7y8eaAcewhYpxTk8KbSRrwynNg9NR2aQMjo",
        net_leverage: 0.0,
        open_ms: 1711266544718,
        orders: [
          {
            leverage: 15.0,
            order_type: "LONG",
            order_uuid: "67083fb3-d870-4318-9b50-5361badcde2c",
            price: 64335.45,
            price_sources: [],
            processed_ms: 1711266544716,
          },
          {
            leverage: 5.0,
            order_type: "FLAT",
            order_uuid: "11620a5a-9371-46a4-a7fd-ced29de4867f",
            price: 64591.67,
            price_sources: [],
            processed_ms: 1711267243466,
          },
        ],
        position_type: "FLAT",
        position_uuid: "ecb724a0-cc1a-4bb9-a443-5084dcb298ba",
        return_at_close: 1.0279462955493435,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 66807.17,
        close_ms: 1711359890387,
        current_return: 1.0206816124676446,
        initial_entry_price: 66807.17,
        is_closed_position: true,
        miner_hotkey: "5EfTAkz7d3p9D7y8eaAcewhYpxTk8KbSRrwynNg9NR2aQMjo",
        net_leverage: 0.0,
        open_ms: 1711359206807,
        orders: [
          {
            leverage: 8.0,
            order_type: "LONG",
            order_uuid: "b167ae47-fbbc-4b59-9d97-ddb8398e2d5a",
            price: 66807.17,
            price_sources: [],
            processed_ms: 1711359206806,
          },
          {
            leverage: 12.0,
            order_type: "FLAT",
            order_uuid: "8cde8d81-e9f0-48f4-bbd9-9313671b36f9",
            price: 66979.88,
            price_sources: [],
            processed_ms: 1711359890387,
          },
        ],
        position_type: "FLAT",
        position_uuid: "acba32b6-e41c-4e9f-92b1-2d74d8463a90",
        return_at_close: 1.0043507066681623,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 67049.99,
        close_ms: 1711378178438,
        current_return: 1.0301865816833082,
        initial_entry_price: 67049.99,
        is_closed_position: true,
        miner_hotkey: "5EfTAkz7d3p9D7y8eaAcewhYpxTk8KbSRrwynNg9NR2aQMjo",
        net_leverage: 0.0,
        open_ms: 1711360959493,
        orders: [
          {
            leverage: 1.0,
            order_type: "LONG",
            order_uuid: "7c989392-3da2-4509-8e17-51ac0cc98a6f",
            price: 67049.99,
            price_sources: [],
            processed_ms: 1711360959492,
          },
          {
            leverage: 6.0,
            order_type: "FLAT",
            order_uuid: "6ca55e8d-d2f6-4165-96b5-446d136c8d41",
            price: 69074.0,
            price_sources: [],
            processed_ms: 1711378178438,
          },
        ],
        position_type: "FLAT",
        position_uuid: "4269a246-b9f3-417d-a14b-0b1f5b1c75a7",
        return_at_close: 1.0281262085199416,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 69150.76,
        close_ms: 1711378867702,
        current_return: 1.0109890332369451,
        initial_entry_price: 69150.76,
        is_closed_position: true,
        miner_hotkey: "5EfTAkz7d3p9D7y8eaAcewhYpxTk8KbSRrwynNg9NR2aQMjo",
        net_leverage: 0.0,
        open_ms: 1711378448069,
        orders: [
          {
            leverage: 3.0,
            order_type: "LONG",
            order_uuid: "51e393dc-f83d-4f30-8d85-de48fdf9d62e",
            price: 69150.76,
            price_sources: [],
            processed_ms: 1711378448068,
          },
          {
            leverage: 3.0,
            order_type: "FLAT",
            order_uuid: "387839fd-5161-4f07-88c3-e99139afb302",
            price: 69404.06,
            price_sources: [],
            processed_ms: 1711378867702,
          },
        ],
        position_type: "FLAT",
        position_uuid: "f9bba888-4b42-4e8a-967f-60b2111a1e3a",
        return_at_close: 1.0049230990375235,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 70069.7,
        close_ms: 1711467745623,
        current_return: 1.0524269406034295,
        initial_entry_price: 70069.7,
        is_closed_position: true,
        miner_hotkey: "5EfTAkz7d3p9D7y8eaAcewhYpxTk8KbSRrwynNg9NR2aQMjo",
        net_leverage: 0.0,
        open_ms: 1711467374028,
        orders: [
          {
            leverage: 19.9,
            order_type: "LONG",
            order_uuid: "eb9fd9d4-8bea-4d1f-b4a1-195ec49f7246",
            price: 70069.7,
            price_sources: [],
            processed_ms: 1711467374026,
          },
          {
            leverage: 0.10000000000000142,
            order_type: "FLAT",
            order_uuid: "57db05dc-739e-4d6a-b58f-165ea2accec0",
            price: 70254.3,
            price_sources: [],
            processed_ms: 1711467745623,
          },
        ],
        position_type: "FLAT",
        position_uuid: "4cd23b44-abcc-4dcd-8670-849da79d84c7",
        return_at_close: 1.010540348367413,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 70252.89,
        close_ms: 1711467945718,
        current_return: 1.0108291203393898,
        initial_entry_price: 70252.89,
        is_closed_position: true,
        miner_hotkey: "5EfTAkz7d3p9D7y8eaAcewhYpxTk8KbSRrwynNg9NR2aQMjo",
        net_leverage: 0.0,
        open_ms: 1711467877527,
        orders: [
          {
            leverage: 19.9,
            order_type: "LONG",
            order_uuid: "035aa03d-aeed-4a64-9cf0-07e69aead4a9",
            price: 70252.89,
            price_sources: [],
            processed_ms: 1711467877526,
          },
          {
            leverage: 0.10000000000000142,
            order_type: "FLAT",
            order_uuid: "ddf0b834-e676-4eb2-bf0e-87c041fd082a",
            price: 70291.12,
            price_sources: [],
            processed_ms: 1711467945718,
          },
        ],
        position_type: "FLAT",
        position_uuid: "b6423e2f-da99-4cce-b288-3fa54df3d853",
        return_at_close: 0.9705981213498821,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 70333.08,
        close_ms: 1711468104990,
        current_return: 1.0359729163005515,
        initial_entry_price: 70333.08,
        is_closed_position: true,
        miner_hotkey: "5EfTAkz7d3p9D7y8eaAcewhYpxTk8KbSRrwynNg9NR2aQMjo",
        net_leverage: 0.0,
        open_ms: 1711468030313,
        orders: [
          {
            leverage: -19.9,
            order_type: "SHORT",
            order_uuid: "a1589861-3bf3-4461-8f5c-1f7326649012",
            price: 70333.08,
            price_sources: [],
            processed_ms: 1711468030312,
          },
          {
            leverage: 19.9,
            order_type: "FLAT",
            order_uuid: "c8903f83-0dc9-400b-afe4-254d3aa9d322",
            price: 70205.94,
            price_sources: [],
            processed_ms: 1711468104990,
          },
        ],
        position_type: "FLAT",
        position_uuid: "32f16239-8ada-4d3c-b02e-727b38a43c3b",
        return_at_close: 0.9947411942317896,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 69760.0,
        close_ms: 1711551117278,
        current_return: 1.0321226920871576,
        initial_entry_price: 69760.0,
        is_closed_position: true,
        miner_hotkey: "5EfTAkz7d3p9D7y8eaAcewhYpxTk8KbSRrwynNg9NR2aQMjo",
        net_leverage: 0.0,
        open_ms: 1711550895654,
        orders: [
          {
            leverage: -19.99,
            order_type: "SHORT",
            order_uuid: "3f9ae50e-b5e7-48c9-9f09-fa41eae8039e",
            price: 69760.0,
            price_sources: [],
            processed_ms: 1711550895653,
          },
          {
            leverage: 19.99,
            order_type: "FLAT",
            order_uuid: "3428a805-33ff-49a7-a00a-cf5a08a75db6",
            price: 69647.9,
            price_sources: [],
            processed_ms: 1711551117278,
          },
        ],
        position_type: "FLAT",
        position_uuid: "53d2d1b5-a692-4f06-a2b1-c24b0903035f",
        return_at_close: 0.990858426857513,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 1.0814000000000001,
        close_ms: 1711580524591,
        current_return: 0.9538561124467309,
        initial_entry_price: 1.0814,
        is_closed_position: true,
        miner_hotkey: "5EfTAkz7d3p9D7y8eaAcewhYpxTk8KbSRrwynNg9NR2aQMjo",
        net_leverage: 0.0,
        open_ms: 1711580456408,
        orders: [
          {
            leverage: 499.0,
            order_type: "LONG",
            order_uuid: "836aff42-9fa6-4e77-a5c2-d7488aa95e2d",
            price: 1.0814,
            price_sources: [],
            processed_ms: 1711580456407,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "b710546a-abf6-4ccc-b727-bc984dedf8d5",
            price: 1.0813,
            price_sources: [],
            processed_ms: 1711580524591,
          },
        ],
        position_type: "FLAT",
        position_uuid: "9364ff24-70c0-4870-9af8-0f912a7d60e0",
        return_at_close: 0.9205379184389665,
        trade_pair: ["EURUSD", "EUR/USD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 151.325,
        close_ms: 1711608810845,
        current_return: 1.0330083594912356,
        initial_entry_price: 151.325,
        is_closed_position: true,
        miner_hotkey: "5EfTAkz7d3p9D7y8eaAcewhYpxTk8KbSRrwynNg9NR2aQMjo",
        net_leverage: 0.0,
        open_ms: 1711608683459,
        orders: [
          {
            leverage: 499.0,
            order_type: "LONG",
            order_uuid: "25dc4e10-f5c1-493f-a600-380f4267d4df",
            price: 151.325,
            price_sources: [],
            processed_ms: 1711608683457,
          },
          {
            leverage: -499.0,
            order_type: "SHORT",
            order_uuid: "301ecb6d-6913-4b4f-8aa2-b333e6839eba",
            price: 151.33501,
            price_sources: [],
            processed_ms: 1711608810845,
          },
        ],
        position_type: "FLAT",
        position_uuid: "cca14fa5-e943-443b-accc-128cfec50241",
        return_at_close: 0.9969253774942067,
        trade_pair: ["USDJPY", "USD/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 1.0816,
        close_ms: 1711609765311,
        current_return: 1.0461353550295807,
        initial_entry_price: 1.0816,
        is_closed_position: true,
        miner_hotkey: "5EfTAkz7d3p9D7y8eaAcewhYpxTk8KbSRrwynNg9NR2aQMjo",
        net_leverage: 0.0,
        open_ms: 1711609693619,
        orders: [
          {
            leverage: -499.0,
            order_type: "SHORT",
            order_uuid: "076f2ce1-2e0d-44fb-b656-5d160cd304a8",
            price: 1.0816,
            price_sources: [],
            processed_ms: 1711609693618,
          },
          {
            leverage: 499.0,
            order_type: "FLAT",
            order_uuid: "3b26e77b-9a13-4e42-9541-a9d001a56058",
            price: 1.0815,
            price_sources: [],
            processed_ms: 1711609765311,
          },
        ],
        position_type: "FLAT",
        position_uuid: "f0e23e3d-1946-4997-8b86-b3b7f3e8e29a",
        return_at_close: 1.0095938470783974,
        trade_pair: ["EURUSD", "EUR/USD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 1.0797,
        close_ms: 1711613298949,
        current_return: 1.1386496248958917,
        initial_entry_price: 1.0797,
        is_closed_position: true,
        miner_hotkey: "5EfTAkz7d3p9D7y8eaAcewhYpxTk8KbSRrwynNg9NR2aQMjo",
        net_leverage: 0.0,
        open_ms: 1711613146619,
        orders: [
          {
            leverage: -499.0,
            order_type: "SHORT",
            order_uuid: "74442223-d017-4161-8e22-ebdf9497a339",
            price: 1.0797,
            price_sources: [],
            processed_ms: 1711613146617,
          },
          {
            leverage: 499.0,
            order_type: "FLAT",
            order_uuid: "7eadfbf7-4ff4-4758-9508-519da474de1a",
            price: 1.0794,
            price_sources: [],
            processed_ms: 1711613298949,
          },
        ],
        position_type: "FLAT",
        position_uuid: "10312885-569e-4de1-b4b9-4f1e08d6ed56",
        return_at_close: 1.0988765934982783,
        trade_pair: ["EURUSD", "EUR/USD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 1.0793,
        close_ms: 1711613552327,
        current_return: 1.1387010099138177,
        initial_entry_price: 1.0793,
        is_closed_position: true,
        miner_hotkey: "5EfTAkz7d3p9D7y8eaAcewhYpxTk8KbSRrwynNg9NR2aQMjo",
        net_leverage: 0.0,
        open_ms: 1711613374138,
        orders: [
          {
            leverage: -499.0,
            order_type: "SHORT",
            order_uuid: "f01900e6-4b7a-4cf9-b2a5-61c590757d85",
            price: 1.0793,
            price_sources: [],
            processed_ms: 1711613374136,
          },
          {
            leverage: 499.0,
            order_type: "FLAT",
            order_uuid: "6e357bee-7384-4bae-9ba9-7e1ab0ce3470",
            price: 1.079,
            price_sources: [],
            processed_ms: 1711613552327,
          },
        ],
        position_type: "FLAT",
        position_uuid: "41fbaf4f-ace0-4219-b97d-ff235582323f",
        return_at_close: 1.0989261836375281,
        trade_pair: ["EURUSD", "EUR/USD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 1.0792,
        close_ms: 1711613901537,
        current_return: 1.0924759080800492,
        initial_entry_price: 1.0792,
        is_closed_position: true,
        miner_hotkey: "5EfTAkz7d3p9D7y8eaAcewhYpxTk8KbSRrwynNg9NR2aQMjo",
        net_leverage: 0.0,
        open_ms: 1711613830220,
        orders: [
          {
            leverage: -499.0,
            order_type: "SHORT",
            order_uuid: "298bae7d-bd9b-4357-beb7-06e7569d3c1d",
            price: 1.0792,
            price_sources: [],
            processed_ms: 1711613830218,
          },
          {
            leverage: 499.0,
            order_type: "FLAT",
            order_uuid: "3fc527be-c945-4c9b-b083-47e950b19d4f",
            price: 1.079,
            price_sources: [],
            processed_ms: 1711613901537,
          },
        ],
        position_type: "FLAT",
        position_uuid: "dbaaace8-613a-43ef-8936-c5fa005a6c84",
        return_at_close: 1.054315724610813,
        trade_pair: ["EURUSD", "EUR/USD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 1.0792,
        close_ms: 1711614283726,
        current_return: 1.0924759080800492,
        initial_entry_price: 1.0792,
        is_closed_position: true,
        miner_hotkey: "5EfTAkz7d3p9D7y8eaAcewhYpxTk8KbSRrwynNg9NR2aQMjo",
        net_leverage: 0.0,
        open_ms: 1711614212432,
        orders: [
          {
            leverage: 499.0,
            order_type: "LONG",
            order_uuid: "869af8b2-cf67-4777-a12a-6c43b83ab37c",
            price: 1.0792,
            price_sources: [],
            processed_ms: 1711614212430,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "53f5311e-9ffa-42a6-929d-07472621f0cb",
            price: 1.0794,
            price_sources: [],
            processed_ms: 1711614283726,
          },
        ],
        position_type: "FLAT",
        position_uuid: "c913f78e-bb46-4917-8a0b-e8c7dbb1244a",
        return_at_close: 1.054315724610813,
        trade_pair: ["EURUSD", "EUR/USD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 1.0792,
        close_ms: 1711614942189,
        current_return: 1.0,
        initial_entry_price: 1.0792,
        is_closed_position: true,
        miner_hotkey: "5EfTAkz7d3p9D7y8eaAcewhYpxTk8KbSRrwynNg9NR2aQMjo",
        net_leverage: 0.0,
        open_ms: 1711614870042,
        orders: [
          {
            leverage: -499.0,
            order_type: "SHORT",
            order_uuid: "b2c8f97c-cc5e-4250-926e-f042720ac25b",
            price: 1.0792,
            price_sources: [],
            processed_ms: 1711614870040,
          },
          {
            leverage: 499.0,
            order_type: "FLAT",
            order_uuid: "63862144-46c7-47c4-ad20-ba25931d0545",
            price: 1.0792,
            price_sources: [],
            processed_ms: 1711614942189,
          },
        ],
        position_type: "FLAT",
        position_uuid: "86ae408c-5008-4e95-8545-156a608b03af",
        return_at_close: 0.96507,
        trade_pair: ["EURUSD", "EUR/USD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 1.079,
        close_ms: 1711615939707,
        current_return: 1.0462465245597725,
        initial_entry_price: 1.079,
        is_closed_position: true,
        miner_hotkey: "5EfTAkz7d3p9D7y8eaAcewhYpxTk8KbSRrwynNg9NR2aQMjo",
        net_leverage: 0.0,
        open_ms: 1711615868565,
        orders: [
          {
            leverage: 499.0,
            order_type: "LONG",
            order_uuid: "6d78acf0-f772-4fc6-89fd-da97eb003581",
            price: 1.079,
            price_sources: [],
            processed_ms: 1711615868563,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "53695bd8-f06c-457c-89bf-31e7481e1655",
            price: 1.0791,
            price_sources: [],
            processed_ms: 1711615939707,
          },
        ],
        position_type: "FLAT",
        position_uuid: "b05dbcf2-692a-4541-9183-c8b83eb1fa73",
        return_at_close: 1.0097011334568997,
        trade_pair: ["EURUSD", "EUR/USD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.856,
        close_ms: 1711616618154,
        current_return: 1.1165887850467162,
        initial_entry_price: 0.856,
        is_closed_position: true,
        miner_hotkey: "5EfTAkz7d3p9D7y8eaAcewhYpxTk8KbSRrwynNg9NR2aQMjo",
        net_leverage: 0.0,
        open_ms: 1711616455987,
        orders: [
          {
            leverage: -499.0,
            order_type: "SHORT",
            order_uuid: "d784b2d7-d1c1-4149-b0b1-552429d71d2b",
            price: 0.856,
            price_sources: [],
            processed_ms: 1711616455985,
          },
          {
            leverage: 499.0,
            order_type: "FLAT",
            order_uuid: "844b8ab0-3518-4349-abff-c6acf9e9e7a0",
            price: 0.8558,
            price_sources: [],
            processed_ms: 1711616618154,
          },
        ],
        position_type: "FLAT",
        position_uuid: "8924e6f1-01a3-4ca6-bfa7-1bbcd3630894",
        return_at_close: 1.0775863387850344,
        trade_pair: ["EURGBP", "EUR/GBP", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.8558999999999999,
        close_ms: 1711616813114,
        current_return: 1.0583012034115424,
        initial_entry_price: 0.8559,
        is_closed_position: true,
        miner_hotkey: "5EfTAkz7d3p9D7y8eaAcewhYpxTk8KbSRrwynNg9NR2aQMjo",
        net_leverage: 0.0,
        open_ms: 1711616686554,
        orders: [
          {
            leverage: -499.0,
            order_type: "SHORT",
            order_uuid: "88ec4b0a-0f99-4e10-a578-b0ebe184f83c",
            price: 0.8559,
            price_sources: [],
            processed_ms: 1711616686552,
          },
          {
            leverage: 499.0,
            order_type: "FLAT",
            order_uuid: "5a5bb827-790f-4378-bcc8-b97f422febb8",
            price: 0.8558,
            price_sources: [],
            processed_ms: 1711616813114,
          },
        ],
        position_type: "FLAT",
        position_uuid: "4270d619-6f63-4c57-b6ab-1401ce7c764a",
        return_at_close: 1.0213347423763772,
        trade_pair: ["EURGBP", "EUR/GBP", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.8558,
        close_ms: 1711617047849,
        current_return: 1.116616031783114,
        initial_entry_price: 0.8558,
        is_closed_position: true,
        miner_hotkey: "5EfTAkz7d3p9D7y8eaAcewhYpxTk8KbSRrwynNg9NR2aQMjo",
        net_leverage: 0.0,
        open_ms: 1711616976151,
        orders: [
          {
            leverage: 499.0,
            order_type: "LONG",
            order_uuid: "e68f9a36-e051-4a6b-9af5-047c82a52ec3",
            price: 0.8558,
            price_sources: [],
            processed_ms: 1711616976150,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "c2801d2d-1d22-4fde-b57f-a141d7f558f5",
            price: 0.856,
            price_sources: [],
            processed_ms: 1711617047849,
          },
        ],
        position_type: "FLAT",
        position_uuid: "41531606-44f7-4f31-a66a-99969ecc6fff",
        return_at_close: 1.0776126337929297,
        trade_pair: ["EURGBP", "EUR/GBP", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.85593,
        close_ms: 1711617187780,
        current_return: 1.0932786559648855,
        initial_entry_price: 0.85593,
        is_closed_position: true,
        miner_hotkey: "5EfTAkz7d3p9D7y8eaAcewhYpxTk8KbSRrwynNg9NR2aQMjo",
        net_leverage: 0.0,
        open_ms: 1711617116233,
        orders: [
          {
            leverage: 499.0,
            order_type: "LONG",
            order_uuid: "99906edb-7f15-4877-b2e0-50c3a245a504",
            price: 0.85593,
            price_sources: [],
            processed_ms: 1711617116231,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "56d500f1-4bb6-4cbe-a5be-77512ab84c0d",
            price: 0.85609,
            price_sources: [],
            processed_ms: 1711617187780,
          },
        ],
        position_type: "FLAT",
        position_uuid: "3583ab41-4988-4d11-8362-1ae951fe56ea",
        return_at_close: 1.055090432512032,
        trade_pair: ["EURGBP", "EUR/GBP", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.85609,
        close_ms: 1711617365840,
        current_return: 1.0058288264084119,
        initial_entry_price: 0.85609,
        is_closed_position: true,
        miner_hotkey: "5EfTAkz7d3p9D7y8eaAcewhYpxTk8KbSRrwynNg9NR2aQMjo",
        net_leverage: 0.0,
        open_ms: 1711617297424,
        orders: [
          {
            leverage: 499.0,
            order_type: "LONG",
            order_uuid: "ed96c896-b203-4ac8-9e09-4404ddf43f10",
            price: 0.85609,
            price_sources: [],
            processed_ms: 1711617297422,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "8a84403e-c35c-487e-9c5d-d00f5317352d",
            price: 0.8561,
            price_sources: [],
            processed_ms: 1711617365840,
          },
        ],
        position_type: "FLAT",
        position_uuid: "25044cb4-61c2-44e3-8035-e002473c1d64",
        return_at_close: 0.9706952255019661,
        trade_pair: ["EURGBP", "EUR/GBP", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.8561,
        close_ms: 1711617541542,
        current_return: 1.0582875832262522,
        initial_entry_price: 0.8561,
        is_closed_position: true,
        miner_hotkey: "5EfTAkz7d3p9D7y8eaAcewhYpxTk8KbSRrwynNg9NR2aQMjo",
        net_leverage: 0.0,
        open_ms: 1711617473751,
        orders: [
          {
            leverage: -499.0,
            order_type: "SHORT",
            order_uuid: "598a064a-57dd-4da1-9b0d-7ea3007b1158",
            price: 0.8561,
            price_sources: [],
            processed_ms: 1711617473749,
          },
          {
            leverage: 499.0,
            order_type: "FLAT",
            order_uuid: "6c8cc85a-9c0b-42fb-8c10-c0fccd31eabd",
            price: 0.856,
            price_sources: [],
            processed_ms: 1711617541542,
          },
        ],
        position_type: "FLAT",
        position_uuid: "470e2e3d-658d-46e9-b695-419454622e99",
        return_at_close: 1.0213215979441592,
        trade_pair: ["EURGBP", "EUR/GBP", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.8563,
        close_ms: 1711617717814,
        current_return: 1.0,
        initial_entry_price: 0.8563,
        is_closed_position: true,
        miner_hotkey: "5EfTAkz7d3p9D7y8eaAcewhYpxTk8KbSRrwynNg9NR2aQMjo",
        net_leverage: 0.0,
        open_ms: 1711617650323,
        orders: [
          {
            leverage: 499.0,
            order_type: "LONG",
            order_uuid: "895765c0-9992-4454-8e68-e0cf09d03598",
            price: 0.8563,
            price_sources: [],
            processed_ms: 1711617648954,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "3fb171bd-e830-4a8e-83ea-ade3a173873f",
            price: 0.8563,
            price_sources: [],
            processed_ms: 1711617717814,
          },
        ],
        position_type: "FLAT",
        position_uuid: "79d04aeb-aa73-41f2-a0a3-65b63799a2a9",
        return_at_close: 0.96507,
        trade_pair: ["EURGBP", "EUR/GBP", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.8562,
        close_ms: 1711617855847,
        current_return: 1.0582807755197319,
        initial_entry_price: 0.8562,
        is_closed_position: true,
        miner_hotkey: "5EfTAkz7d3p9D7y8eaAcewhYpxTk8KbSRrwynNg9NR2aQMjo",
        net_leverage: 0.0,
        open_ms: 1711617786837,
        orders: [
          {
            leverage: -499.0,
            order_type: "SHORT",
            order_uuid: "0fc962f4-ef18-4abc-8fd8-68fd22301edc",
            price: 0.8562,
            price_sources: [],
            processed_ms: 1711617786835,
          },
          {
            leverage: 499.0,
            order_type: "FLAT",
            order_uuid: "e3a6acd3-4fd1-4573-aed0-dd46e0501d2c",
            price: 0.8561,
            price_sources: [],
            processed_ms: 1711617855847,
          },
        ],
        position_type: "FLAT",
        position_uuid: "5966171e-87ba-4224-b690-f290cbaf4066",
        return_at_close: 1.0213150280308276,
        trade_pair: ["EURGBP", "EUR/GBP", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.85635,
        close_ms: 1711618374589,
        current_return: 1.087405850411621,
        initial_entry_price: 0.85635,
        is_closed_position: true,
        miner_hotkey: "5EfTAkz7d3p9D7y8eaAcewhYpxTk8KbSRrwynNg9NR2aQMjo",
        net_leverage: 0.0,
        open_ms: 1711618193570,
        orders: [
          {
            leverage: -499.0,
            order_type: "SHORT",
            order_uuid: "352d1d56-7777-411c-81b5-28fd9c0125bb",
            price: 0.85635,
            price_sources: [],
            processed_ms: 1711618193569,
          },
          {
            leverage: 499.0,
            order_type: "FLAT",
            order_uuid: "116a4a71-3870-4b8c-a86c-7fbee5ae6482",
            price: 0.8562,
            price_sources: [],
            processed_ms: 1711618374589,
          },
        ],
        position_type: "FLAT",
        position_uuid: "570f5a58-51a3-48da-a173-86052ba9e561",
        return_at_close: 1.049422764056743,
        trade_pair: ["EURGBP", "EUR/GBP", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.85615,
        close_ms: 1711618807687,
        current_return: 1.0874262687613057,
        initial_entry_price: 0.85615,
        is_closed_position: true,
        miner_hotkey: "5EfTAkz7d3p9D7y8eaAcewhYpxTk8KbSRrwynNg9NR2aQMjo",
        net_leverage: 0.0,
        open_ms: 1711618739368,
        orders: [
          {
            leverage: -499.0,
            order_type: "SHORT",
            order_uuid: "528ab224-36df-4d86-92c5-6b16ea4fb412",
            price: 0.85615,
            price_sources: [],
            processed_ms: 1711618739365,
          },
          {
            leverage: 499.0,
            order_type: "FLAT",
            order_uuid: "a4b5310a-9707-4899-86a6-d390f9968dcb",
            price: 0.856,
            price_sources: [],
            processed_ms: 1711618807687,
          },
        ],
        position_type: "FLAT",
        position_uuid: "1499c355-adcd-431c-bc48-537c56db663d",
        return_at_close: 1.0494424691934732,
        trade_pair: ["EURGBP", "EUR/GBP", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.8561,
        close_ms: 1711619162137,
        current_return: 1.0582875832262522,
        initial_entry_price: 0.8561,
        is_closed_position: true,
        miner_hotkey: "5EfTAkz7d3p9D7y8eaAcewhYpxTk8KbSRrwynNg9NR2aQMjo",
        net_leverage: 0.0,
        open_ms: 1711618953582,
        orders: [
          {
            leverage: -499.0,
            order_type: "SHORT",
            order_uuid: "f5bfcebf-df4b-4b2c-a938-3f354338e26e",
            price: 0.8561,
            price_sources: [],
            processed_ms: 1711618953580,
          },
          {
            leverage: 499.0,
            order_type: "FLAT",
            order_uuid: "9a43132f-6b84-4e89-8e30-ced775100449",
            price: 0.856,
            price_sources: [],
            processed_ms: 1711619162137,
          },
        ],
        position_type: "FLAT",
        position_uuid: "bfcd5435-2b2a-4ba6-90f9-97f8eaa3be9c",
        return_at_close: 1.0213215979441592,
        trade_pair: ["EURGBP", "EUR/GBP", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.8558999999999999,
        close_ms: 1711619551547,
        current_return: 1.0583012034115424,
        initial_entry_price: 0.8559,
        is_closed_position: true,
        miner_hotkey: "5EfTAkz7d3p9D7y8eaAcewhYpxTk8KbSRrwynNg9NR2aQMjo",
        net_leverage: 0.0,
        open_ms: 1711619275977,
        orders: [
          {
            leverage: -499.0,
            order_type: "SHORT",
            order_uuid: "375c7dc8-e4df-4f4d-bc35-0c046f9f77ed",
            price: 0.8559,
            price_sources: [],
            processed_ms: 1711619275975,
          },
          {
            leverage: 499.0,
            order_type: "FLAT",
            order_uuid: "b2aa543c-63a6-487b-9531-f2dc2459a2ff",
            price: 0.8558,
            price_sources: [],
            processed_ms: 1711619551547,
          },
        ],
        position_type: "FLAT",
        position_uuid: "5d349cf6-1af3-42bd-9ae5-0c90e17a2dba",
        return_at_close: 1.0213347423763772,
        trade_pair: ["EURGBP", "EUR/GBP", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.8558,
        close_ms: 1711619902953,
        current_return: 1.0,
        initial_entry_price: 0.8558,
        is_closed_position: true,
        miner_hotkey: "5EfTAkz7d3p9D7y8eaAcewhYpxTk8KbSRrwynNg9NR2aQMjo",
        net_leverage: 0.0,
        open_ms: 1711619748169,
        orders: [
          {
            leverage: -499.0,
            order_type: "SHORT",
            order_uuid: "d7ccd818-1508-427b-8ba4-33572e752a24",
            price: 0.8558,
            price_sources: [],
            processed_ms: 1711619748166,
          },
          {
            leverage: 499.0,
            order_type: "FLAT",
            order_uuid: "2886d03a-a9d3-4fea-be8d-fa6bcbc417da",
            price: 0.8558,
            price_sources: [],
            processed_ms: 1711619902953,
          },
        ],
        position_type: "FLAT",
        position_uuid: "6eb331d6-1872-485d-9eb6-ad0524eb7a47",
        return_at_close: 0.96507,
        trade_pair: ["EURGBP", "EUR/GBP", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.8557,
        close_ms: 1711620113738,
        current_return: 0.8833703400724682,
        initial_entry_price: 0.8557,
        is_closed_position: true,
        miner_hotkey: "5EfTAkz7d3p9D7y8eaAcewhYpxTk8KbSRrwynNg9NR2aQMjo",
        net_leverage: 499.0,
        open_ms: 1711620065968,
        orders: [
          {
            leverage: 499.0,
            order_type: "LONG",
            order_uuid: "3aa1a0d1-cce7-4318-9945-1abb23ea9275",
            price: 0.8557,
            price_sources: [],
            processed_ms: 1711620065966,
          },
        ],
        position_type: "FLAT",
        position_uuid: "dcd2d769-e170-4954-a550-aed6458b2d20",
        return_at_close: 0.8525142140937368,
        trade_pair: ["EURGBP", "EUR/GBP", 7e-5, 0.001, 500],
      },
    ],
    thirty_day_returns: 1.0,
  },
  "5Eh9p81ioCeoTArv7kSa1PWcaXw33UdRjVQfLQsFPpn474GC": {
    all_time_returns: 1.0787436224807738,
    n_positions: 45,
    percentage_profitable: 0.9777777777777777,
    positions: [
      {
        average_entry_price: 0.6689,
        close_ms: 1712147405830,
        current_return: 1.000012258932576,
        initial_entry_price: 0.6689,
        is_closed_position: true,
        miner_hotkey: "5Eh9p81ioCeoTArv7kSa1PWcaXw33UdRjVQfLQsFPpn474GC",
        net_leverage: 0.0,
        open_ms: 1712146750583,
        orders: [
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "a953c4db-fd9f-4920-acbb-e0243c0dae82",
            price: 0.6689,
            price_sources: [],
            processed_ms: 1712146750582,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "4627037f-00b9-47dd-b4f1-81d8245b6424",
            price: 0.66972,
            price_sources: [],
            processed_ms: 1712147405830,
          },
        ],
        position_type: "FLAT",
        position_uuid: "2b453f7d-8929-4820-a5a7-84548f121883",
        return_at_close: 1.0000115589239946,
        trade_pair: ["CADCHF", "CAD/CHF", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 163.688,
        close_ms: 1712148670774,
        current_return: 1.0000043986119935,
        initial_entry_price: 163.688,
        is_closed_position: true,
        miner_hotkey: "5Eh9p81ioCeoTArv7kSa1PWcaXw33UdRjVQfLQsFPpn474GC",
        net_leverage: 0.0,
        open_ms: 1712147646580,
        orders: [
          {
            leverage: -0.01,
            order_type: "SHORT",
            order_uuid: "555c7eb1-584e-4d9e-a807-475e45dff5c6",
            price: 163.688,
            price_sources: [],
            processed_ms: 1712147646579,
          },
          {
            leverage: 0.01,
            order_type: "FLAT",
            order_uuid: "cf315c0b-9685-4a85-b7e5-42dc090252cf",
            price: 163.616,
            price_sources: [],
            processed_ms: 1712148670774,
          },
        ],
        position_type: "FLAT",
        position_uuid: "d047ada8-52a8-4e5f-a699-014b0248d698",
        return_at_close: 1.0000036986089145,
        trade_pair: ["EURJPY", "EUR/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.65165,
        close_ms: 1712149392582,
        current_return: 1.0000050640681348,
        initial_entry_price: 0.65165,
        is_closed_position: true,
        miner_hotkey: "5Eh9p81ioCeoTArv7kSa1PWcaXw33UdRjVQfLQsFPpn474GC",
        net_leverage: 0.0,
        open_ms: 1712146557084,
        orders: [
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "09c94bff-2749-4802-9b6c-d3afe9a77060",
            price: 0.65165,
            price_sources: [],
            processed_ms: 1712146557083,
          },
          {
            leverage: 0.01,
            order_type: "FLAT",
            order_uuid: "5b5f82c8-6296-4c2e-aa57-ce717753062a",
            price: 0.65198,
            price_sources: [],
            processed_ms: 1712149392582,
          },
        ],
        position_type: "FLAT",
        position_uuid: "235975db-b4c4-47c6-90e8-cc48918fe708",
        return_at_close: 1.00000436406459,
        trade_pair: ["AUDUSD", "AUD/USD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 1.07763,
        close_ms: 1712149496406,
        current_return: 1.0000064957360133,
        initial_entry_price: 1.07763,
        is_closed_position: true,
        miner_hotkey: "5Eh9p81ioCeoTArv7kSa1PWcaXw33UdRjVQfLQsFPpn474GC",
        net_leverage: 0.0,
        open_ms: 1712147164139,
        orders: [
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "55155c34-e255-4da5-82e3-828a4d05ce57",
            price: 1.07763,
            price_sources: [],
            processed_ms: 1712147164139,
          },
          {
            leverage: 0.01,
            order_type: "FLAT",
            order_uuid: "385cc6cb-0820-4e03-9534-1d162e64ee33",
            price: 1.07833,
            price_sources: [],
            processed_ms: 1712149496406,
          },
        ],
        position_type: "FLAT",
        position_uuid: "61277f22-d102-4860-beb4-971661fed12f",
        return_at_close: 1.0000057957314663,
        trade_pair: ["EURUSD", "EUR/USD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.857,
        close_ms: 1712149598119,
        current_return: 1.0000058343057177,
        initial_entry_price: 0.857,
        is_closed_position: true,
        miner_hotkey: "5Eh9p81ioCeoTArv7kSa1PWcaXw33UdRjVQfLQsFPpn474GC",
        net_leverage: 0.0,
        open_ms: 1712147524022,
        orders: [
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "0bf06cbd-3769-43b9-bd6f-5c53f010263d",
            price: 0.857,
            price_sources: [],
            processed_ms: 1712147524021,
          },
          {
            leverage: 0.01,
            order_type: "FLAT",
            order_uuid: "e0690976-9152-4259-8562-c0fc1bdf278b",
            price: 0.8575,
            price_sources: [],
            processed_ms: 1712149598119,
          },
        ],
        position_type: "FLAT",
        position_uuid: "db4be03e-4034-403f-ae86-27099a781696",
        return_at_close: 1.0000051343016336,
        trade_pair: ["EURGBP", "EUR/GBP", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 1.46227,
        close_ms: 1712150129386,
        current_return: 1.0000064967482065,
        initial_entry_price: 1.46227,
        is_closed_position: true,
        miner_hotkey: "5Eh9p81ioCeoTArv7kSa1PWcaXw33UdRjVQfLQsFPpn474GC",
        net_leverage: 0.0,
        open_ms: 1712146984766,
        orders: [
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "049fe3db-fc72-44ec-96f4-c717aee8047c",
            price: 1.46227,
            price_sources: [],
            processed_ms: 1712146984765,
          },
          {
            leverage: 0.01,
            order_type: "FLAT",
            order_uuid: "3e77e164-e98d-4d2d-87f6-27aad4ca8b99",
            price: 1.46322,
            price_sources: [],
            processed_ms: 1712150129386,
          },
        ],
        position_type: "FLAT",
        position_uuid: "e7376be4-45e1-45f2-b20b-411fd3b396f0",
        return_at_close: 1.0000057967436589,
        trade_pair: ["EURCAD", "EUR/CAD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 163.711,
        close_ms: 1712150419800,
        current_return: 1.000005741825534,
        initial_entry_price: 163.711,
        is_closed_position: true,
        miner_hotkey: "5Eh9p81ioCeoTArv7kSa1PWcaXw33UdRjVQfLQsFPpn474GC",
        net_leverage: 0.0,
        open_ms: 1712149303007,
        orders: [
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "fb330325-0e8e-4914-a9f7-7fb5c61e508d",
            price: 163.711,
            price_sources: [],
            processed_ms: 1712149303006,
          },
          {
            leverage: 0.01,
            order_type: "FLAT",
            order_uuid: "bcb74b4d-e316-4204-9fc4-d67205a10dca",
            price: 163.805,
            price_sources: [],
            processed_ms: 1712150419800,
          },
        ],
        position_type: "FLAT",
        position_uuid: "f90b5798-0264-4d5f-b1a4-2508b48a95bf",
        return_at_close: 1.0000050418215147,
        trade_pair: ["EURJPY", "EUR/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 190.859,
        close_ms: 1712151157207,
        current_return: 1.0000060777851711,
        initial_entry_price: 190.859,
        is_closed_position: true,
        miner_hotkey: "5Eh9p81ioCeoTArv7kSa1PWcaXw33UdRjVQfLQsFPpn474GC",
        net_leverage: 0.0,
        open_ms: 1712148773827,
        orders: [
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "0f3d5b10-d694-4e64-bd2c-7ae03c4b0d81",
            price: 190.859,
            price_sources: [],
            processed_ms: 1712148773826,
          },
          {
            leverage: 0.01,
            order_type: "FLAT",
            order_uuid: "80f731c1-787e-4f4d-a113-47fcc9376da7",
            price: 190.975,
            price_sources: [],
            processed_ms: 1712151157207,
          },
        ],
        position_type: "FLAT",
        position_uuid: "6de3e821-72cc-40a9-aa81-df9b2a1c5d31",
        return_at_close: 1.0000053777809168,
        trade_pair: ["GBPJPY", "GBP/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 98.943,
        close_ms: 1712151725441,
        current_return: 1.000009803624309,
        initial_entry_price: 98.943,
        is_closed_position: true,
        miner_hotkey: "5Eh9p81ioCeoTArv7kSa1PWcaXw33UdRjVQfLQsFPpn474GC",
        net_leverage: 0.0,
        open_ms: 1712146647312,
        orders: [
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "8f816cbc-c2f7-4347-8f0f-642c6e62f204",
            price: 98.943,
            price_sources: [],
            processed_ms: 1712146647311,
          },
          {
            leverage: 0.01,
            order_type: "FLAT",
            order_uuid: "71e7d9d1-c3bc-4ed7-8af7-372e18016da0",
            price: 99.04,
            price_sources: [],
            processed_ms: 1712151725441,
          },
        ],
        position_type: "FLAT",
        position_uuid: "b7366fef-6cf6-4cea-a624-5a2cc012a006",
        return_at_close: 1.0000091036174465,
        trade_pair: ["AUDJPY", "AUD/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.66994,
        close_ms: 1712151934413,
        current_return: 1.0000088067588142,
        initial_entry_price: 0.66994,
        is_closed_position: true,
        miner_hotkey: "5Eh9p81ioCeoTArv7kSa1PWcaXw33UdRjVQfLQsFPpn474GC",
        net_leverage: 0.0,
        open_ms: 1712149215581,
        orders: [
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "c7db4979-77c6-40f1-bd55-d5748c78e91b",
            price: 0.66994,
            price_sources: [],
            processed_ms: 1712149215580,
          },
          {
            leverage: 0.01,
            order_type: "FLAT",
            order_uuid: "e53cfcc0-8f01-4882-beb2-4aed0e4c5a2e",
            price: 0.67053,
            price_sources: [],
            processed_ms: 1712151934413,
          },
        ],
        position_type: "FLAT",
        position_uuid: "f6e78888-5ecc-47a9-b22a-dea2005b0019",
        return_at_close: 1.0000081067526496,
        trade_pair: ["CADCHF", "CAD/CHF", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 151.848,
        close_ms: 1712152192825,
        current_return: 1.0000056635582952,
        initial_entry_price: 151.848,
        is_closed_position: true,
        miner_hotkey: "5Eh9p81ioCeoTArv7kSa1PWcaXw33UdRjVQfLQsFPpn474GC",
        net_leverage: 0.0,
        open_ms: 1712149037932,
        orders: [
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "cfdfbc58-ebdb-49ed-94ca-ad30938b43ae",
            price: 151.848,
            price_sources: [],
            processed_ms: 1712149037931,
          },
          {
            leverage: 0.01,
            order_type: "FLAT",
            order_uuid: "8f319bff-7012-43cf-a577-d45d12cebb4b",
            price: 151.934,
            price_sources: [],
            processed_ms: 1712152192825,
          },
        ],
        position_type: "FLAT",
        position_uuid: "ecd6d7f4-372e-4ec5-a184-8489247071e1",
        return_at_close: 1.0000049635543309,
        trade_pair: ["USDJPY", "USD/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.814185652173913,
        close_ms: 1713207552422,
        current_return: 1.0001705000555905,
        initial_entry_price: 0.80949,
        is_closed_position: true,
        miner_hotkey: "5Eh9p81ioCeoTArv7kSa1PWcaXw33UdRjVQfLQsFPpn474GC",
        net_leverage: 0.0,
        open_ms: 1712150732959,
        orders: [
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "a78cde1c-d36b-43c0-aa3a-8388685d3a92",
            price: 0.80949,
            price_sources: [],
            processed_ms: 1712150732958,
          },
          {
            leverage: 0.45,
            order_type: "LONG",
            order_uuid: "2b2ba48c-fb38-4174-a0ab-e3e292054618",
            price: 0.81429,
            price_sources: [],
            processed_ms: 1713207162627,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "41a4d8c7-bf95-4f4f-affc-5be97475c461",
            price: 0.8144856915,
            price_sources: [],
            processed_ms: 1713207552422,
          },
        ],
        position_type: "FLAT",
        position_uuid: "2f820917-d8ed-406a-a546-99dabf015a49",
        return_at_close: 1.0001382945654886,
        trade_pair: ["NZDCAD", "NZD/CAD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 1.80621,
        close_ms: 1713344407401,
        current_return: 1.0000302844076825,
        initial_entry_price: 1.80621,
        is_closed_position: true,
        miner_hotkey: "5Eh9p81ioCeoTArv7kSa1PWcaXw33UdRjVQfLQsFPpn474GC",
        net_leverage: 0.0,
        open_ms: 1712147788808,
        orders: [
          {
            leverage: -0.01,
            order_type: "SHORT",
            order_uuid: "e8363d7a-dc91-4d0c-921e-bc2b11cc12cf",
            price: 1.80621,
            price_sources: [],
            processed_ms: 1712147788807,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "7e6f253c-809c-4783-bce2-ba7986855433",
            price: 1.80074,
            price_sources: [],
            processed_ms: 1713344407401,
          },
        ],
        position_type: "FLAT",
        position_uuid: "da83afa3-fd0b-4268-86a1-36b26b9582a3",
        return_at_close: 1.0000295843864835,
        trade_pair: ["EURNZD", "EUR/NZD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 1.1718700000000115,
        close_ms: 1713733822463,
        current_return: 1.0000520768704562,
        initial_entry_price: 1.25718,
        is_closed_position: true,
        miner_hotkey: "5Eh9p81ioCeoTArv7kSa1PWcaXw33UdRjVQfLQsFPpn474GC",
        net_leverage: 0.0,
        open_ms: 1712148516933,
        orders: [
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "5bc5bda5-d5a8-4862-b35c-460636934ea9",
            price: 1.25718,
            price_sources: [],
            processed_ms: 1712148516932,
          },
          {
            leverage: 0.1,
            order_type: "LONG",
            order_uuid: "59e04ccc-b2ab-4c4b-93e0-52591feb2151",
            price: 1.25371,
            price_sources: [],
            processed_ms: 1712854536795,
          },
          {
            leverage: -0.108,
            order_type: "SHORT",
            order_uuid: "e1690e7a-3ad4-4c9a-9648-0116b929f61f",
            price: 1.25494,
            price_sources: [],
            processed_ms: 1712857282414,
          },
          {
            leverage: -0.001,
            order_type: "SHORT",
            order_uuid: "b8562bd0-3015-4a95-8195-110be88eaa86",
            price: 1.23741,
            price_sources: [],
            processed_ms: 1713733787838,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "a56d86db-9aec-4c82-baec-c2ef2f774b20",
            price: 1.23734,
            price_sources: [],
            processed_ms: 1713733822463,
          },
        ],
        position_type: "FLAT",
        position_uuid: "3ce934e0-7466-4130-b367-fdabe85a0d77",
        return_at_close: 1.0000443764694642,
        trade_pair: ["GBPUSD", "GBP/USD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 113.70344554455446,
        close_ms: 1714089895473,
        current_return: 1.0001710382904212,
        initial_entry_price: 111.934,
        is_closed_position: true,
        miner_hotkey: "5Eh9p81ioCeoTArv7kSa1PWcaXw33UdRjVQfLQsFPpn474GC",
        net_leverage: 0.0,
        open_ms: 1712146885354,
        orders: [
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "7579af3e-fcbd-449d-8f0b-1ab57e0c2354",
            price: 111.934,
            price_sources: [],
            processed_ms: 1712146885353,
          },
          {
            leverage: 0.05,
            order_type: "LONG",
            order_uuid: "7b01e834-1d7a-4156-bc61-b8f5743e57dd",
            price: 111.827,
            price_sources: [],
            processed_ms: 1712854497495,
          },
          {
            leverage: -0.059,
            order_type: "SHORT",
            order_uuid: "6618d6d6-5dd7-4584-973b-e76385005845",
            price: 111.938,
            price_sources: [],
            processed_ms: 1712855972414,
          },
          {
            leverage: 0.1,
            order_type: "LONG",
            order_uuid: "7807d7d8-8fe0-4489-a85a-7a06e918466f",
            price: 113.777,
            price_sources: [],
            processed_ms: 1714028757454,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "83d82436-a83d-46cd-9e48-06c81fed7559",
            price: 113.893,
            price_sources: [],
            processed_ms: 1714089895473,
          },
        ],
        position_type: "FLAT",
        position_uuid: "27f5225d-8fba-4db0-b408-0b2e6b0b31cc",
        return_at_close: 1.0001598363747923,
        trade_pair: ["CADJPY", "CAD/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 1.0933575303844958,
        close_ms: 1714100583571,
        current_return: 1.000224430864936,
        initial_entry_price: 1.0934,
        is_closed_position: true,
        miner_hotkey: "5Eh9p81ioCeoTArv7kSa1PWcaXw33UdRjVQfLQsFPpn474GC",
        net_leverage: 0.0,
        open_ms: 1714093900925,
        orders: [
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "163b35fc-8632-4d12-bc3e-09bb16f04c74",
            price: 1.0934,
            price_sources: [],
            processed_ms: 1714093900919,
          },
          {
            leverage: -0.01,
            order_type: "SHORT",
            order_uuid: "57401352-d421-401d-9f7f-f3b45938bb34",
            price: 1.0944192707721,
            price_sources: [],
            processed_ms: 1714094148667,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "793dd3ab-2ff4-4e11-a340-dfced01a5de4",
            price: 1.09438,
            price_sources: [],
            processed_ms: 1714100583571,
          },
        ],
        position_type: "FLAT",
        position_uuid: "130a16f5-4dfb-441b-80dc-bbca1d7d9255",
        return_at_close: 1.0002069269373959,
        trade_pair: ["AUDNZD", "AUD/NZD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 101.571,
        close_ms: 1714100974311,
        current_return: 1.0001870612674877,
        initial_entry_price: 101.571,
        is_closed_position: true,
        miner_hotkey: "5Eh9p81ioCeoTArv7kSa1PWcaXw33UdRjVQfLQsFPpn474GC",
        net_leverage: 0.0,
        open_ms: 1714093497151,
        orders: [
          {
            leverage: -0.25,
            order_type: "SHORT",
            order_uuid: "b99e0db7-9bd8-470e-9258-1cfd4e2890b7",
            price: 101.571,
            price_sources: [],
            processed_ms: 1714093497145,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "b6a2c5be-c38f-49db-b0cd-8a72eded9a39",
            price: 101.495,
            price_sources: [],
            processed_ms: 1714100974311,
          },
        ],
        position_type: "FLAT",
        position_uuid: "e962e00e-562b-47ed-9f25-98ada711fab2",
        return_at_close: 1.0001695579939156,
        trade_pair: ["AUDJPY", "AUD/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 113.944,
        close_ms: 1714102106511,
        current_return: 1.0003115565540968,
        initial_entry_price: 113.944,
        is_closed_position: true,
        miner_hotkey: "5Eh9p81ioCeoTArv7kSa1PWcaXw33UdRjVQfLQsFPpn474GC",
        net_leverage: 0.0,
        open_ms: 1714093476071,
        orders: [
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "424a663e-ade9-45bd-9c6a-f69dd98ccae0",
            price: 113.944,
            price_sources: [],
            processed_ms: 1714093476064,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "5d7dd33d-42ae-4f91-82e3-c8665d62e5e5",
            price: 114.086,
            price_sources: [],
            processed_ms: 1714102106511,
          },
        ],
        position_type: "FLAT",
        position_uuid: "e5960003-06a0-4527-b93b-3c37a375b98d",
        return_at_close: 1.000294051101857,
        trade_pair: ["CADJPY", "CAD/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 92.776,
        close_ms: 1714102192540,
        current_return: 1.000334138139174,
        initial_entry_price: 92.776,
        is_closed_position: true,
        miner_hotkey: "5Eh9p81ioCeoTArv7kSa1PWcaXw33UdRjVQfLQsFPpn474GC",
        net_leverage: 0.0,
        open_ms: 1714091901290,
        orders: [
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "fafa51a0-b24a-4c2e-b1eb-b6f0fb54378a",
            price: 92.776,
            price_sources: [],
            processed_ms: 1714091901286,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "10dbbc8e-3a94-443f-8b7d-1b20fe9dfb27",
            price: 92.9,
            price_sources: [],
            processed_ms: 1714102192540,
          },
        ],
        position_type: "FLAT",
        position_uuid: "7338b373-310e-492c-9fba-bbfc173547cd",
        return_at_close: 1.0003166322917565,
        trade_pair: ["NZDJPY", "NZD/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 166.92,
        close_ms: 1714102538892,
        current_return: 1.0005092259765156,
        initial_entry_price: 166.92,
        is_closed_position: true,
        miner_hotkey: "5Eh9p81ioCeoTArv7kSa1PWcaXw33UdRjVQfLQsFPpn474GC",
        net_leverage: 0.0,
        open_ms: 1714092039835,
        orders: [
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "b01064c4-b3e9-4cd0-b9ae-fd5ecd68cfe7",
            price: 166.92,
            price_sources: [],
            processed_ms: 1714092039830,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "ec450b29-8390-4a36-bc25-f7e31de3bde2",
            price: 167.26,
            price_sources: [],
            processed_ms: 1714102538892,
          },
        ],
        position_type: "FLAT",
        position_uuid: "87d2741c-2911-4dba-a6b1-f96d96aa35b5",
        return_at_close: 1.000491717065061,
        trade_pair: ["EURJPY", "EUR/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.59613,
        close_ms: 1714102971888,
        current_return: 1.000339691006995,
        initial_entry_price: 0.59613,
        is_closed_position: true,
        miner_hotkey: "5Eh9p81ioCeoTArv7kSa1PWcaXw33UdRjVQfLQsFPpn474GC",
        net_leverage: 0.0,
        open_ms: 1714091883663,
        orders: [
          {
            leverage: -0.25,
            order_type: "SHORT",
            order_uuid: "419a8162-0154-410f-94c7-ba947f016fda",
            price: 0.59613,
            price_sources: [],
            processed_ms: 1714091883659,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "4c95daab-f71f-4ac3-bc09-b308252b227e",
            price: 0.59532,
            price_sources: [],
            processed_ms: 1714102971888,
          },
        ],
        position_type: "FLAT",
        position_uuid: "522f3c71-ddd5-4d3e-b49a-0f4a7e64e7a4",
        return_at_close: 1.0003221850624024,
        trade_pair: ["NZDUSD", "NZD/USD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.65265,
        close_ms: 1714102988413,
        current_return: 1.000287290278097,
        initial_entry_price: 0.65265,
        is_closed_position: true,
        miner_hotkey: "5Eh9p81ioCeoTArv7kSa1PWcaXw33UdRjVQfLQsFPpn474GC",
        net_leverage: 0.0,
        open_ms: 1714093938670,
        orders: [
          {
            leverage: -0.25,
            order_type: "SHORT",
            order_uuid: "19605689-4704-464d-9cd8-c25d7bc6f7ed",
            price: 0.65265,
            price_sources: [],
            processed_ms: 1714093938661,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "39eedff1-f563-474a-bb00-a0ae9a66d62b",
            price: 0.6519,
            price_sources: [],
            processed_ms: 1714102988413,
          },
        ],
        position_type: "FLAT",
        position_uuid: "005a55d8-2ec9-4a9e-af87-cffcd8252425",
        return_at_close: 1.0002697852505171,
        trade_pair: ["AUDUSD", "AUD/USD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 1.7984373076923077,
        close_ms: 1714103004618,
        current_return: 1.0002519461743773,
        initial_entry_price: 1.7984,
        is_closed_position: true,
        miner_hotkey: "5Eh9p81ioCeoTArv7kSa1PWcaXw33UdRjVQfLQsFPpn474GC",
        net_leverage: 0.0,
        open_ms: 1714092028557,
        orders: [
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "b06fe51f-b319-4ac1-b77c-dc4b1a45463f",
            price: 1.7984,
            price_sources: [],
            processed_ms: 1714092024379,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "7079fb3f-12f4-4334-997a-2318ce2c0278",
            price: 1.79937,
            price_sources: [],
            processed_ms: 1714092158413,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "b8d6bb22-b025-493c-99b6-e5b4da57c2e5",
            price: 1.80018,
            price_sources: [],
            processed_ms: 1714103004618,
          },
        ],
        position_type: "FLAT",
        position_uuid: "53b41b5a-8134-490a-b191-eeeb2f968bec",
        return_at_close: 1.000233741588957,
        trade_pair: ["EURNZD", "EUR/NZD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 64354.2,
        close_ms: 1714104862137,
        current_return: 1.0012866293109073,
        initial_entry_price: 64354.2,
        is_closed_position: true,
        miner_hotkey: "5Eh9p81ioCeoTArv7kSa1PWcaXw33UdRjVQfLQsFPpn474GC",
        net_leverage: 0.0,
        open_ms: 1714104024364,
        orders: [
          {
            leverage: -1.0,
            order_type: "SHORT",
            order_uuid: "dd3b535d-4a39-4060-b42a-a85dc33a93c0",
            price: 64354.2,
            price_sources: [],
            processed_ms: 1714104024357,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "a86db5d8-613e-40be-a9d8-8d32c0ca1906",
            price: 64271.4,
            price_sources: [],
            processed_ms: 1714104862137,
          },
        ],
        position_type: "FLAT",
        position_uuid: "53148f47-f41c-46ac-bacd-33a6c0c6f1c1",
        return_at_close: 1.0002853426815963,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 0.97911,
        close_ms: 1714117572687,
        current_return: 1.0002400138901655,
        initial_entry_price: 0.97911,
        is_closed_position: true,
        miner_hotkey: "5Eh9p81ioCeoTArv7kSa1PWcaXw33UdRjVQfLQsFPpn474GC",
        net_leverage: 0.0,
        open_ms: 1714092592507,
        orders: [
          {
            leverage: -0.25,
            order_type: "SHORT",
            order_uuid: "b0f892aa-ba75-483c-864c-0ea138238e2f",
            price: 0.97911,
            price_sources: [],
            processed_ms: 1714092592501,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "763b7a91-9bb8-4973-baab-d72f129ceefe",
            price: 0.97817,
            price_sources: [],
            processed_ms: 1714117572687,
          },
        ],
        position_type: "FLAT",
        position_uuid: "4ab593d9-92b7-4d3b-b071-9e1319e8f70f",
        return_at_close: 1.0002225096899224,
        trade_pair: ["EURCHF", "EUR/CHF", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.66817,
        close_ms: 1714118757536,
        current_return: 1.0002581678315399,
        initial_entry_price: 0.66817,
        is_closed_position: true,
        miner_hotkey: "5Eh9p81ioCeoTArv7kSa1PWcaXw33UdRjVQfLQsFPpn474GC",
        net_leverage: 0.0,
        open_ms: 1714092830185,
        orders: [
          {
            leverage: -0.25,
            order_type: "SHORT",
            order_uuid: "a7960130-3086-42ed-bf62-d5463789e3a3",
            price: 0.66817,
            price_sources: [],
            processed_ms: 1714092830179,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "20e83cab-4815-4e02-b626-483c3d672cbf",
            price: 0.66748,
            price_sources: [],
            processed_ms: 1714118757536,
          },
        ],
        position_type: "FLAT",
        position_uuid: "f8e3656d-c760-4552-a525-1f100a57c74b",
        return_at_close: 1.0002406633136027,
        trade_pair: ["CADCHF", "CAD/CHF", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 1.25051,
        close_ms: 1714118923837,
        current_return: 1.0002059159862775,
        initial_entry_price: 1.25051,
        is_closed_position: true,
        miner_hotkey: "5Eh9p81ioCeoTArv7kSa1PWcaXw33UdRjVQfLQsFPpn474GC",
        net_leverage: 0.0,
        open_ms: 1714091939079,
        orders: [
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "8609e3a9-1891-4a4e-b99e-476edcd6cc42",
            price: 1.25051,
            price_sources: [],
            processed_ms: 1714091939074,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "bc1a62f5-15c4-4fa8-8c20-28dcdcb0268c",
            price: 1.25154,
            price_sources: [],
            processed_ms: 1714118923837,
          },
        ],
        position_type: "FLAT",
        position_uuid: "fd714755-41fb-48a0-b1d7-804044aae8e0",
        return_at_close: 1.0001884123827478,
        trade_pair: ["GBPUSD", "GBP/USD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.47610000000025116,
        close_ms: 1714160290863,
        current_return: 1.0006560887512896,
        initial_entry_price: 1.3566,
        is_closed_position: true,
        miner_hotkey: "5Eh9p81ioCeoTArv7kSa1PWcaXw33UdRjVQfLQsFPpn474GC",
        net_leverage: 0.0,
        open_ms: 1712148931661,
        orders: [
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "09cc0c0b-340c-4230-ae2b-d975e53b4063",
            price: 1.3566,
            price_sources: [],
            processed_ms: 1712148931660,
          },
          {
            leverage: 2.0,
            order_type: "LONG",
            order_uuid: "72f3cc86-8473-4096-b99f-4b758b4e4474",
            price: 1.37816,
            price_sources: [],
            processed_ms: 1713206602504,
          },
          {
            leverage: -2.0,
            order_type: "SHORT",
            order_uuid: "0f976bdd-8139-43ae-9533-2c6c5963e9a1",
            price: 1.37825,
            price_sources: [],
            processed_ms: 1713206638384,
          },
          {
            leverage: -0.009,
            order_type: "SHORT",
            order_uuid: "00d21ca6-f8e9-44ed-99ce-ed9d1ce24622",
            price: 1.3756,
            price_sources: [],
            processed_ms: 1713452367221,
          },
          {
            leverage: 0.15,
            order_type: "LONG",
            order_uuid: "05e1dae2-d2f2-43a0-a7e7-3feeb27e2baa",
            price: 1.36823,
            price_sources: [],
            processed_ms: 1714028728661,
          },
          {
            leverage: -0.15,
            order_type: "SHORT",
            order_uuid: "311bf345-147a-4d64-a893-0c745effc969",
            price: 1.37176,
            price_sources: [],
            processed_ms: 1714051432160,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "0cd10e3c-9e8d-40ac-8851-736c5ec64c00",
            price: 1.36615,
            price_sources: [],
            processed_ms: 1714160290863,
          },
        ],
        position_type: "FLAT",
        position_uuid: "a02dcd4d-4097-4981-8f54-0193d231bb72",
        return_at_close: 1.0005047895506705,
        trade_pair: ["USDCAD", "USD/CAD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 1.1081799999999966,
        close_ms: 1714165114618,
        current_return: 1.000363395670576,
        initial_entry_price: 1.07266,
        is_closed_position: true,
        miner_hotkey: "5Eh9p81ioCeoTArv7kSa1PWcaXw33UdRjVQfLQsFPpn474GC",
        net_leverage: 0.0,
        open_ms: 1714091982535,
        orders: [
          {
            leverage: -0.25,
            order_type: "SHORT",
            order_uuid: "0c37ded0-9703-4604-abf2-ec1e9084c79c",
            price: 1.07266,
            price_sources: [],
            processed_ms: 1714091982530,
          },
          {
            leverage: 0.24,
            order_type: "LONG",
            order_uuid: "30c9adec-f7f5-483b-8f5e-69145118c9d7",
            price: 1.07118,
            price_sources: [],
            processed_ms: 1714135794684,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "e8b14b33-0cae-43b0-91d2-1be36b6704da",
            price: 1.0692,
            price_sources: [],
            processed_ms: 1714165114618,
          },
        ],
        position_type: "FLAT",
        position_uuid: "d1a7a04d-f0ed-4ea5-918a-d82d9c57d6f5",
        return_at_close: 1.0003458893111516,
        trade_pair: ["EURUSD", "EUR/USD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 3138.99,
        close_ms: 1714180245085,
        current_return: 1.0252692745118652,
        initial_entry_price: 3138.99,
        is_closed_position: true,
        miner_hotkey: "5Eh9p81ioCeoTArv7kSa1PWcaXw33UdRjVQfLQsFPpn474GC",
        net_leverage: 0.0,
        open_ms: 1714104044161,
        orders: [
          {
            leverage: -2.0,
            order_type: "SHORT",
            order_uuid: "ebe1c16c-2848-41ac-a1fb-053ba76c5ec6",
            price: 3138.99,
            price_sources: [],
            processed_ms: 1714104044154,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "3707f65d-b2aa-415f-b090-cda901bcad96",
            price: 3099.33,
            price_sources: [],
            processed_ms: 1714180245085,
          },
        ],
        position_type: "FLAT",
        position_uuid: "5ff9476b-4671-462a-aec8-f6b09299538d",
        return_at_close: 1.0232187359628415,
        trade_pair: ["ETHUSD", "ETH/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 0.016019302490889217,
        close_ms: 1714415243945,
        current_return: 1.0049795242723842,
        initial_entry_price: 0.8844225365,
        is_closed_position: true,
        miner_hotkey: "5Eh9p81ioCeoTArv7kSa1PWcaXw33UdRjVQfLQsFPpn474GC",
        net_leverage: 0.0,
        open_ms: 1712146470615,
        orders: [
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "c5c95d78-de4a-4cb8-b83f-3bdfdf585816",
            price: 0.8844225365,
            price_sources: [],
            processed_ms: 1712146470615,
          },
          {
            leverage: 0.005,
            order_type: "LONG",
            order_uuid: "a1a24794-9d3d-401b-b175-5a9a86aa68c9",
            price: 0.8838109639,
            price_sources: [],
            processed_ms: 1712151413212,
          },
          {
            leverage: 0.9,
            order_type: "LONG",
            order_uuid: "48ed0eec-d246-4414-b979-5ef753f08cb5",
            price: 0.8881,
            price_sources: [],
            processed_ms: 1713206869956,
          },
          {
            leverage: -0.91,
            order_type: "SHORT",
            order_uuid: "c1d3f484-f18c-42fb-aca8-58ac5b42e009",
            price: 0.88839,
            price_sources: [],
            processed_ms: 1713206906510,
          },
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "47c9ae02-49be-4887-aaa4-adff7a0d9a1a",
            price: 0.8879,
            price_sources: [],
            processed_ms: 1713207021493,
          },
          {
            leverage: 1.0,
            order_type: "LONG",
            order_uuid: "ccc8cceb-27f6-40ff-b6ad-e954ee41f863",
            price: 0.8850806063,
            price_sources: [],
            processed_ms: 1713448808492,
          },
          {
            leverage: 1.2,
            order_type: "LONG",
            order_uuid: "0bff6378-6ad4-4db8-a159-240bba7d0f29",
            price: 0.88389,
            price_sources: [],
            processed_ms: 1713475234923,
          },
          {
            leverage: 1.0,
            order_type: "LONG",
            order_uuid: "b72bfaf6-2187-41c9-8b6e-cc816810291f",
            price: 0.88252,
            price_sources: [],
            processed_ms: 1713524370502,
          },
          {
            leverage: -3.2,
            order_type: "SHORT",
            order_uuid: "9f183bdd-195d-4867-bd30-7b01a04b0b75",
            price: 0.884605712811916,
            price_sources: [],
            processed_ms: 1713751997431,
          },
          {
            leverage: -0.3,
            order_type: "SHORT",
            order_uuid: "b77235be-171a-4099-ad4a-5f823eb1d98b",
            price: 0.891950561270388,
            price_sources: [],
            processed_ms: 1714028647105,
          },
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "041a8a2c-86ba-42c6-a074-cdcf87138be3",
            price: 0.89075923522006,
            price_sources: [],
            processed_ms: 1714105785300,
          },
          {
            leverage: -0.3,
            order_type: "SHORT",
            order_uuid: "5aba4a45-4e33-4f21-be8a-d08c4a68f501",
            price: 0.891937370529384,
            price_sources: [],
            processed_ms: 1714109319241,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "f41732a6-9ff9-4fcc-a326-9f591f51498e",
            price: 0.89682,
            price_sources: [],
            processed_ms: 1714415243945,
          },
        ],
        position_type: "FLAT",
        position_uuid: "82b1ba0f-d57f-4c3b-802a-617c62f64257",
        return_at_close: 1.004647830780398,
        trade_pair: ["AUDCAD", "AUD/CAD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 162.5460000000021,
        close_ms: 1714415259649,
        current_return: 1.0104416999730157,
        initial_entry_price: 155.626,
        is_closed_position: true,
        miner_hotkey: "5Eh9p81ioCeoTArv7kSa1PWcaXw33UdRjVQfLQsFPpn474GC",
        net_leverage: 0.0,
        open_ms: 1714091857739,
        orders: [
          {
            leverage: -0.25,
            order_type: "SHORT",
            order_uuid: "52e5e1dc-a677-43d1-9bcd-fea5a3bf1386",
            price: 155.626,
            price_sources: [],
            processed_ms: 1714091857735,
          },
          {
            leverage: -20.0,
            order_type: "SHORT",
            order_uuid: "75898945-6400-4d58-b737-596f95323fbd",
            price: 156.111,
            price_sources: [],
            processed_ms: 1714413058804,
          },
          {
            leverage: -10.0,
            order_type: "SHORT",
            order_uuid: "ee5c60b8-5368-4731-82fb-8c84a9174c43",
            price: 156.15,
            price_sources: [],
            processed_ms: 1714413130805,
          },
          {
            leverage: -20.0,
            order_type: "SHORT",
            order_uuid: "9fd1e4e3-2809-4b9e-836d-70dc2022cea0",
            price: 156.073,
            price_sources: [],
            processed_ms: 1714413500354,
          },
          {
            leverage: 50.0,
            order_type: "LONG",
            order_uuid: "18b65d31-98dc-423e-af19-9bb2843351f7",
            price: 156.069,
            price_sources: [],
            processed_ms: 1714414924947,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "3a1399ac-081c-48d6-8ae3-acc518f748c8",
            price: 156.046,
            price_sources: [],
            processed_ms: 1714415259649,
          },
        ],
        position_type: "FLAT",
        position_uuid: "c583a64d-b0ff-46b9-af0b-3561487e4aa7",
        return_at_close: 1.0068874712933606,
        trade_pair: ["USDJPY", "USD/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 156.10790581717455,
        close_ms: 1714427091171,
        current_return: 1.0455964625588698,
        initial_entry_price: 156.045,
        is_closed_position: true,
        miner_hotkey: "5Eh9p81ioCeoTArv7kSa1PWcaXw33UdRjVQfLQsFPpn474GC",
        net_leverage: 0.0,
        open_ms: 1714415324848,
        orders: [
          {
            leverage: 5.0,
            order_type: "LONG",
            order_uuid: "ad435334-c29f-4066-a121-e919227301e6",
            price: 156.045,
            price_sources: [],
            processed_ms: 1714415324842,
          },
          {
            leverage: 5.0,
            order_type: "LONG",
            order_uuid: "66dbd62a-f40b-4749-8bd0-5fb0f14b8566",
            price: 156.057,
            price_sources: [],
            processed_ms: 1714415384424,
          },
          {
            leverage: 9.9,
            order_type: "LONG",
            order_uuid: "f669163e-0b2b-430d-8222-787309aeb963",
            price: 155.978,
            price_sources: [],
            processed_ms: 1714415448194,
          },
          {
            leverage: -19.8,
            order_type: "SHORT",
            order_uuid: "a49a8643-0a15-4455-8ee4-ff281082175b",
            price: 156.006,
            price_sources: [],
            processed_ms: 1714415526881,
          },
          {
            leverage: 10.0,
            order_type: "LONG",
            order_uuid: "3aaac93f-6581-40aa-8960-8d47639fa6f9",
            price: 155.982,
            price_sources: [],
            processed_ms: 1714418538792,
          },
          {
            leverage: 25.0,
            order_type: "LONG",
            order_uuid: "deca845c-ad3c-4d3c-a2f4-cd37496d347d",
            price: 156.07,
            price_sources: [],
            processed_ms: 1714419252594,
          },
          {
            leverage: -34.0,
            order_type: "SHORT",
            order_uuid: "add1f7ec-faad-456f-a433-32e5d611faf1",
            price: 156.017,
            price_sources: [],
            processed_ms: 1714420746183,
          },
          {
            leverage: 35.0,
            order_type: "LONG",
            order_uuid: "76b6d5ac-460f-4a20-991c-9dc626238a6b",
            price: 156.078,
            price_sources: [],
            processed_ms: 1714421770133,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "82835f65-9e3a-45a0-a9c0-0cd7670253b5",
            price: 156.305,
            price_sources: [],
            processed_ms: 1714427091171,
          },
        ],
        position_type: "FLAT",
        position_uuid: "a5eb2c73-d6e0-48f1-a998-234794b3b676",
        return_at_close: 1.039016524019987,
        trade_pair: ["USDJPY", "USD/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.7655866299239693,
        close_ms: 1714963319185,
        current_return: 1.0003448300946003,
        initial_entry_price: 0.81427,
        is_closed_position: true,
        miner_hotkey: "5Eh9p81ioCeoTArv7kSa1PWcaXw33UdRjVQfLQsFPpn474GC",
        net_leverage: 0.0,
        open_ms: 1714091921815,
        orders: [
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "0f2a1d4c-7c54-40f5-8ae2-3d3f1cdb7077",
            price: 0.81427,
            price_sources: [],
            processed_ms: 1714091921811,
          },
          {
            leverage: -0.245,
            order_type: "SHORT",
            order_uuid: "88bc7fb7-9511-4315-baf9-dc90975f4625",
            price: 0.815263538164817,
            price_sources: [],
            processed_ms: 1714386629330,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "e3e93460-0e3f-419b-98cb-1503f47964f8",
            price: 0.82174359015,
            price_sources: [],
            processed_ms: 1714963319185,
          },
        ],
        position_type: "FLAT",
        position_uuid: "bbb44608-6978-4b7e-a3ac-0af4390be361",
        return_at_close: 1.0003273240600736,
        trade_pair: ["NZDCAD", "NZD/CAD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.85774,
        close_ms: 1715094961838,
        current_return: 1.0003206099750508,
        initial_entry_price: 0.85774,
        is_closed_position: true,
        miner_hotkey: "5Eh9p81ioCeoTArv7kSa1PWcaXw33UdRjVQfLQsFPpn474GC",
        net_leverage: 0.0,
        open_ms: 1714092070388,
        orders: [
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "23d52214-228b-4c23-980c-ce796c76b8ee",
            price: 0.85774,
            price_sources: [],
            processed_ms: 1714092070383,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "e4941b8a-0314-4aa4-9528-298ae1808a2d",
            price: 0.85884,
            price_sources: [],
            processed_ms: 1715094961838,
          },
        ],
        position_type: "FLAT",
        position_uuid: "25c45241-adf8-445a-9fb0-c897da6b46cc",
        return_at_close: 1.0003031043643762,
        trade_pair: ["EURGBP", "EUR/GBP", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 1.3201400000000019,
        close_ms: 1716579119879,
        current_return: 1.0005539251150029,
        initial_entry_price: 1.46518,
        is_closed_position: true,
        miner_hotkey: "5Eh9p81ioCeoTArv7kSa1PWcaXw33UdRjVQfLQsFPpn474GC",
        net_leverage: 0.0,
        open_ms: 1714092609674,
        orders: [
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "ffdb8ccb-fa47-4661-ac9a-b99cd5c0fda7",
            price: 1.46518,
            price_sources: [],
            processed_ms: 1714092609669,
          },
          {
            leverage: -0.245,
            order_type: "SHORT",
            order_uuid: "b6f3fdb4-d5b3-4236-9cb9-56968a0ce463",
            price: 1.46814,
            price_sources: [],
            processed_ms: 1714476771179,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "dfb80e12-e9e0-40f9-9c1a-b2aa3177c6f6",
            price: 1.48246,
            price_sources: [],
            processed_ms: 1716579119879,
          },
        ],
        position_type: "FLAT",
        position_uuid: "e2507b85-46a4-4171-bb40-258861236873",
        return_at_close: 1.0005364154213134,
        trade_pair: ["EURCAD", "EUR/CAD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 215.66200000000018,
        close_ms: 1718392347742,
        current_return: 1.0008312862942597,
        initial_entry_price: 194.59,
        is_closed_position: true,
        miner_hotkey: "5Eh9p81ioCeoTArv7kSa1PWcaXw33UdRjVQfLQsFPpn474GC",
        net_leverage: 0.0,
        open_ms: 1714091960238,
        orders: [
          {
            leverage: -0.25,
            order_type: "SHORT",
            order_uuid: "34b58ead-4f52-418d-b9e7-e320677e853a",
            price: 194.59,
            price_sources: [],
            processed_ms: 1714091956573,
          },
          {
            leverage: 0.24,
            order_type: "LONG",
            order_uuid: "7c8b2dcd-7e29-4964-9c8e-c28ab365a7fa",
            price: 193.712,
            price_sources: [],
            processed_ms: 1714650143526,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "a6401774-b1dc-47ad-8615-7c8b772ea9be",
            price: 199.486,
            price_sources: [],
            processed_ms: 1718392347742,
          },
        ],
        position_type: "FLAT",
        position_uuid: "431e1c33-ef46-4252-88d5-e1350fe53eba",
        return_at_close: 1.0008137717467496,
        trade_pair: ["GBPJPY", "GBP/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 58716.35000000008,
        close_ms: 1718392368671,
        current_return: 0.9936163614919947,
        initial_entry_price: 63057.33,
        is_closed_position: true,
        miner_hotkey: "5Eh9p81ioCeoTArv7kSa1PWcaXw33UdRjVQfLQsFPpn474GC",
        net_leverage: 0.0,
        open_ms: 1714412192385,
        orders: [
          {
            leverage: -1.0,
            order_type: "SHORT",
            order_uuid: "9d369c86-1b8f-41e0-8a74-4e7f4e3310e8",
            price: 63057.33,
            price_sources: [],
            processed_ms: 1714412192376,
          },
          {
            leverage: 0.99,
            order_type: "LONG",
            order_uuid: "f44ec055-0fb0-4faa-aad3-64e27acea0d9",
            price: 63116.0,
            price_sources: [],
            processed_ms: 1714427115287,
          },
          {
            leverage: -0.05,
            order_type: "SHORT",
            order_uuid: "e05dd6e4-8b0d-458e-871d-989abc907786",
            price: 59009.82,
            price_sources: [],
            processed_ms: 1714671026986,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "2d200e88-9407-4b6c-b7f0-6e8efdc08e42",
            price: 65425.27,
            price_sources: [],
            processed_ms: 1718392368671,
          },
        ],
        position_type: "FLAT",
        position_uuid: "8566b9cd-0c5d-4850-81c5-4d3e34b93717",
        return_at_close: 0.9925730643124281,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 61088.11000000001,
        close_ms: 1719323045894,
        current_return: 1.0021695547627845,
        initial_entry_price: 61088.11,
        is_closed_position: true,
        miner_hotkey: "5Eh9p81ioCeoTArv7kSa1PWcaXw33UdRjVQfLQsFPpn474GC",
        net_leverage: 0.0,
        open_ms: 1719320931878,
        orders: [
          {
            leverage: 0.6,
            order_type: "LONG",
            order_uuid: "d757c503-5fd7-46ab-89a1-a942d47518d3",
            price: 61088.11,
            price_sources: [],
            processed_ms: 1719320930677,
          },
          {
            leverage: -0.6,
            order_type: "SHORT",
            order_uuid: "cc89d120-7de3-486e-9d8d-a155bd4ce2fe",
            price: 61309.0,
            price_sources: [],
            processed_ms: 1719323045894,
          },
        ],
        position_type: "FLAT",
        position_uuid: "f0c52144-1c99-42a4-ad65-f63961cf0717",
        return_at_close: 1.0015682530299268,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 1.26746,
        close_ms: 1719332937006,
        current_return: 1.000254114528269,
        initial_entry_price: 1.26746,
        is_closed_position: true,
        miner_hotkey: "5Eh9p81ioCeoTArv7kSa1PWcaXw33UdRjVQfLQsFPpn474GC",
        net_leverage: 0.0,
        open_ms: 1719331139859,
        orders: [
          {
            leverage: 0.488,
            order_type: "LONG",
            order_uuid: "083a8366-5143-48e6-8e08-398c8c3a4ce9",
            price: 1.26746,
            price_sources: [],
            processed_ms: 1719331139825,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "610f8fd0-db55-4551-b45c-f54be451e540",
            price: 1.26812,
            price_sources: [],
            processed_ms: 1719332937006,
          },
        ],
        position_type: "FLAT",
        position_uuid: "e0cdf30e-5928-48eb-aafc-140a5bf7e2cf",
        return_at_close: 1.0002199458477168,
        trade_pair: ["GBPUSD", "GBP/USD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 60989.77999999999,
        close_ms: 1719421840922,
        current_return: 1.0019211087496953,
        initial_entry_price: 60989.78,
        is_closed_position: true,
        miner_hotkey: "5Eh9p81ioCeoTArv7kSa1PWcaXw33UdRjVQfLQsFPpn474GC",
        net_leverage: 0.0,
        open_ms: 1719421517247,
        orders: [
          {
            leverage: -1.2,
            order_type: "SHORT",
            order_uuid: "8c504981-c3d4-4f39-be63-f209cdbdcadd",
            price: 60989.78,
            price_sources: [],
            processed_ms: 1719421515988,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "fd2f01e4-8ca9-40e8-a182-8f6561058838",
            price: 60892.14,
            price_sources: [],
            processed_ms: 1719421840922,
          },
        ],
        position_type: "FLAT",
        position_uuid: "a381e70e-f1b3-48a2-b511-eb86e7291f04",
        return_at_close: 1.0007188034191956,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 1.2648743435553425,
        close_ms: 1719484245487,
        current_return: 1.0006347015219623,
        initial_entry_price: 1.2681,
        is_closed_position: true,
        miner_hotkey: "5Eh9p81ioCeoTArv7kSa1PWcaXw33UdRjVQfLQsFPpn474GC",
        net_leverage: 0.0,
        open_ms: 1719333228178,
        orders: [
          {
            leverage: 0.188,
            order_type: "LONG",
            order_uuid: "bd938d8c-e166-4938-b3f1-76e381f3d41a",
            price: 1.2681,
            price_sources: [],
            processed_ms: 1719333228070,
          },
          {
            leverage: 0.15,
            order_type: "LONG",
            order_uuid: "2422f58f-8e49-4ef6-b21e-ad890f46ea86",
            price: 1.267355,
            price_sources: [],
            processed_ms: 1719385139072,
          },
          {
            leverage: 0.1,
            order_type: "LONG",
            order_uuid: "d318f394-c81d-4a0b-8242-0a9a2510cff4",
            price: 1.26687,
            price_sources: [],
            processed_ms: 1719386062923,
          },
          {
            leverage: 0.178,
            order_type: "LONG",
            order_uuid: "3fac703a-e416-49cf-9b81-9536f23e456e",
            price: 1.26648,
            price_sources: [],
            processed_ms: 1719391919304,
          },
          {
            leverage: 0.191,
            order_type: "LONG",
            order_uuid: "f9d67c9d-af1f-42b9-9db8-fcef56773e3d",
            price: 1.266005,
            price_sources: [],
            processed_ms: 1719396886415,
          },
          {
            leverage: 0.164,
            order_type: "LONG",
            order_uuid: "1d6edb38-5185-488b-8229-b19d8a6e9496",
            price: 1.26531,
            price_sources: [],
            processed_ms: 1719399877091,
          },
          {
            leverage: 0.107,
            order_type: "LONG",
            order_uuid: "94c12e80-50c9-41a2-a541-030307fc1613",
            price: 1.264895,
            price_sources: [],
            processed_ms: 1719400771165,
          },
          {
            leverage: 0.188,
            order_type: "LONG",
            order_uuid: "b34a085d-3cbf-43ec-8620-b770eaa14056",
            price: 1.26442,
            price_sources: [],
            processed_ms: 1719401278413,
          },
          {
            leverage: 0.199,
            order_type: "LONG",
            order_uuid: "4d7e4f01-ebda-40ad-a074-86de113bbe33",
            price: 1.26386,
            price_sources: [],
            processed_ms: 1719407580228,
          },
          {
            leverage: 0.189,
            order_type: "LONG",
            order_uuid: "98ce74ad-c3e1-4941-b4fc-1db495dab1e9",
            price: 1.26341,
            price_sources: [],
            processed_ms: 1719408453085,
          },
          {
            leverage: 0.141,
            order_type: "LONG",
            order_uuid: "9857370c-a920-4aec-a22f-af256df44a15",
            price: 1.26278,
            price_sources: [],
            processed_ms: 1719408757098,
          },
          {
            leverage: 0.165,
            order_type: "LONG",
            order_uuid: "8c62a56a-8120-491e-93a3-4b01c216dca4",
            price: 1.26201,
            price_sources: [],
            processed_ms: 1719414124464,
          },
          {
            leverage: 0.127,
            order_type: "LONG",
            order_uuid: "c31e9dfc-264f-42fa-8cc6-45e98e777573",
            price: 1.261555,
            price_sources: [],
            processed_ms: 1719430203360,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "9b34af2f-73cc-43a7-8698-d308e13c5583",
            price: 1.26526,
            price_sources: [],
            processed_ms: 1719484245487,
          },
        ],
        position_type: "FLAT",
        position_uuid: "cf205597-eb5a-4113-a35f-dc14e9ae68a8",
        return_at_close: 1.000488518798417,
        trade_pair: ["GBPUSD", "GBP/USD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 1.2629373741007193,
        close_ms: 1719492029401,
        current_return: 1.0002925372780411,
        initial_entry_price: 1.265155,
        is_closed_position: true,
        miner_hotkey: "5Eh9p81ioCeoTArv7kSa1PWcaXw33UdRjVQfLQsFPpn474GC",
        net_leverage: 0.0,
        open_ms: 1719484277446,
        orders: [
          {
            leverage: 0.109,
            order_type: "LONG",
            order_uuid: "77760270-4958-4666-9f06-f3f0531bc391",
            price: 1.265155,
            price_sources: [],
            processed_ms: 1719484275871,
          },
          {
            leverage: 0.185,
            order_type: "LONG",
            order_uuid: "0343a188-3f64-4a62-beab-6d20c8ab57ec",
            price: 1.2647,
            price_sources: [],
            processed_ms: 1719486464905,
          },
          {
            leverage: 0.145,
            order_type: "LONG",
            order_uuid: "480566bf-ee05-4bf1-b1da-017fb7885f6d",
            price: 1.26412,
            price_sources: [],
            processed_ms: 1719489185512,
          },
          {
            leverage: -0.1,
            order_type: "SHORT",
            order_uuid: "80f18796-8234-4032-85c0-3cb1af90c163",
            price: 1.264865,
            price_sources: [],
            processed_ms: 1719491345927,
          },
          {
            leverage: -0.2,
            order_type: "SHORT",
            order_uuid: "d7c34919-727b-42ad-ab0f-01320c94f2f9",
            price: 1.26567,
            price_sources: [],
            processed_ms: 1719491561644,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "42f15eae-848a-4573-b6c7-f816fc5793d2",
            price: 1.2656,
            price_sources: [],
            processed_ms: 1719492029401,
          },
        ],
        position_type: "FLAT",
        position_uuid: "8c1811a3-5bb7-438d-a6ea-e3b7ca83bd09",
        return_at_close: 1.0002617982883706,
        trade_pair: ["GBPUSD", "GBP/USD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 1.2638752129817448,
        close_ms: 1719559723612,
        current_return: 1.0004129570338747,
        initial_entry_price: 1.26495,
        is_closed_position: true,
        miner_hotkey: "5Eh9p81ioCeoTArv7kSa1PWcaXw33UdRjVQfLQsFPpn474GC",
        net_leverage: 0.0,
        open_ms: 1719500423064,
        orders: [
          {
            leverage: 0.19,
            order_type: "LONG",
            order_uuid: "bf629709-b393-43c9-b643-40192da6cd1e",
            price: 1.26495,
            price_sources: [],
            processed_ms: 1719500420220,
          },
          {
            leverage: 0.134,
            order_type: "LONG",
            order_uuid: "1d493a55-b723-4bad-ac8d-e0c8878c2559",
            price: 1.2647,
            price_sources: [],
            processed_ms: 1719501779520,
          },
          {
            leverage: 0.153,
            order_type: "LONG",
            order_uuid: "9180ae6d-8361-4563-a9f4-bedba3fb3251",
            price: 1.2641,
            price_sources: [],
            processed_ms: 1719518017503,
          },
          {
            leverage: 0.199,
            order_type: "LONG",
            order_uuid: "77fb00e7-336d-435e-a9c5-45be32dfef42",
            price: 1.26374,
            price_sources: [],
            processed_ms: 1719518303151,
          },
          {
            leverage: 0.125,
            order_type: "LONG",
            order_uuid: "7b0dbd42-a47b-48c4-b118-3eae53981a7a",
            price: 1.26317,
            price_sources: [],
            processed_ms: 1719536099236,
          },
          {
            leverage: 0.185,
            order_type: "LONG",
            order_uuid: "3888377a-f605-4a27-9031-7a19ff54cfb8",
            price: 1.26261,
            price_sources: [],
            processed_ms: 1719537723240,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "78b31d83-cdbf-43de-a8d5-27a089148fd3",
            price: 1.264405,
            price_sources: [],
            processed_ms: 1719559723612,
          },
        ],
        position_type: "FLAT",
        position_uuid: "9fc6cccc-708b-48b8-bcd3-82f6e6246420",
        return_at_close: 1.0003439085315802,
        trade_pair: ["GBPUSD", "GBP/USD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 1.2637804739336491,
        close_ms: 1719568661268,
        current_return: 1.000440424732565,
        initial_entry_price: 1.264325,
        is_closed_position: true,
        miner_hotkey: "5Eh9p81ioCeoTArv7kSa1PWcaXw33UdRjVQfLQsFPpn474GC",
        net_leverage: 0.0,
        open_ms: 1719559752791,
        orders: [
          {
            leverage: 0.108,
            order_type: "LONG",
            order_uuid: "5418da21-695f-4fb3-a45e-9300cfc5bd62",
            price: 1.264325,
            price_sources: [],
            processed_ms: 1719559752710,
          },
          {
            leverage: 0.17,
            order_type: "LONG",
            order_uuid: "e2917636-ff8e-4cfb-a355-b2cc51c0f417",
            price: 1.26385,
            price_sources: [],
            processed_ms: 1719562161318,
          },
          {
            leverage: 0.144,
            order_type: "LONG",
            order_uuid: "4619b140-1428-44f4-b033-0958254a17fa",
            price: 1.26329,
            price_sources: [],
            processed_ms: 1719565352071,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "f8b3118a-e8e0-4dc4-95f1-1402ead93e10",
            price: 1.2651,
            price_sources: [],
            processed_ms: 1719568661268,
          },
        ],
        position_type: "FLAT",
        position_uuid: "84000e41-ded6-42a8-aeb4-db87aa2a4310",
        return_at_close: 1.0004108717224183,
        trade_pair: ["GBPUSD", "GBP/USD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 60206.9,
        close_ms: 0,
        current_return: 0.993124043921876,
        initial_entry_price: 60206.9,
        is_closed_position: false,
        miner_hotkey: "5Eh9p81ioCeoTArv7kSa1PWcaXw33UdRjVQfLQsFPpn474GC",
        net_leverage: 0.2,
        open_ms: 1719611590963,
        orders: [
          {
            leverage: 0.2,
            order_type: "LONG",
            order_uuid: "3fb801c3-9ada-4152-8273-486ae970826f",
            price: 60206.9,
            price_sources: [],
            processed_ms: 1719611590468,
          },
        ],
        position_type: "LONG",
        position_uuid: "6b726458-135e-47d0-84e0-483c38fd84c4",
        return_at_close: 0.9930247315174838,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 1.2645954494382023,
        close_ms: 0,
        current_return: 1.005471709746265,
        initial_entry_price: 1.2651,
        is_closed_position: false,
        miner_hotkey: "5Eh9p81ioCeoTArv7kSa1PWcaXw33UdRjVQfLQsFPpn474GC",
        net_leverage: 0.356,
        open_ms: 1719568691155,
        orders: [
          {
            leverage: 0.133,
            order_type: "LONG",
            order_uuid: "dc8f3936-366b-40ec-a8ba-d4977ebaf9db",
            price: 1.2651,
            price_sources: [],
            processed_ms: 1719568691102,
          },
          {
            leverage: 0.118,
            order_type: "LONG",
            order_uuid: "941a679f-83dc-4d0f-9646-b7326a800b16",
            price: 1.26461,
            price_sources: [],
            processed_ms: 1719568937152,
          },
          {
            leverage: 0.105,
            order_type: "LONG",
            order_uuid: "ac10e84b-1baf-48a6-86d9-82ed45435d11",
            price: 1.26394,
            price_sources: [],
            processed_ms: 1719575358099,
          },
        ],
        position_type: "LONG",
        position_uuid: "d53e0e1c-270d-4214-8961-e1411cac5e01",
        return_at_close: 1.0054591815687617,
        trade_pair: ["GBPUSD", "GBP/USD", 7e-5, 0.001, 500],
      },
    ],
    thirty_day_returns: 1.004018343735677,
  },
  "5En5V9gZYmsUQ7BWZzjU9TXb71VZFFBeSk8SQKVQ8RkJFDJB": {
    all_time_returns: 1.0,
    n_positions: 0,
    percentage_profitable: 0.0,
    positions: [
      {
        average_entry_price: 71013.74714285717,
        close_ms: 1712102409051,
        current_return: 0.9447466461344719,
        initial_entry_price: 70419.49,
        is_closed_position: true,
        miner_hotkey: "5En5V9gZYmsUQ7BWZzjU9TXb71VZFFBeSk8SQKVQ8RkJFDJB",
        net_leverage: 0.7,
        open_ms: 1711401373028,
        orders: [
          {
            leverage: 1.0,
            order_type: "LONG",
            order_uuid: "6877dd1e-ab27-4401-b65f-300185269b9e",
            price: 70419.49,
            price_sources: [],
            processed_ms: 1711401373028,
          },
          {
            leverage: -0.3,
            order_type: "SHORT",
            order_uuid: "f4c99f7e-dae7-463e-9873-9be13b493436",
            price: 69688.56,
            price_sources: [],
            processed_ms: 1711548183084,
          },
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "ca7f2f06-ffad-428b-b6dd-dc213b446642",
            price: 70343.77,
            price_sources: [],
            processed_ms: 1711864054327,
          },
          {
            leverage: -0.3,
            order_type: "SHORT",
            order_uuid: "e018d461-204b-4413-958c-9e6b98ab7dab",
            price: 69688.1,
            price_sources: [],
            processed_ms: 1711950592752,
          },
        ],
        position_type: "FLAT",
        position_uuid: "e1912c9d-7ef9-4700-85d9-11f1fa8fdd8c",
        return_at_close: 0.942857152842203,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
    ],
    thirty_day_returns: 1.0,
  },
  "5Enfwuk2sBgePA2PM5H2rtPfmPrSswvUEP7URVe7NoT8ivQh": {
    all_time_returns: 1.0001376458483686,
    n_positions: 1,
    percentage_profitable: 1.0,
    positions: [
      {
        average_entry_price: 160.732,
        close_ms: 1719601363552,
        current_return: 1.0001586491800014,
        initial_entry_price: 160.732,
        is_closed_position: true,
        miner_hotkey: "5Enfwuk2sBgePA2PM5H2rtPfmPrSswvUEP7URVe7NoT8ivQh",
        net_leverage: 0.0,
        open_ms: 1719567014483,
        orders: [
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "f90e05aa-680c-454b-baf1-7903bb80e582",
            price: 160.732,
            price_sources: [],
            processed_ms: 1719567014468,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "3eb56ac5-d126-496e-9c41-58f992b5a441",
            price: 160.817,
            price_sources: [],
            processed_ms: 1719601363552,
          },
        ],
        position_type: "FLAT",
        position_uuid: "2f3a2606-abff-4416-b3d0-25bae639462e",
        return_at_close: 1.0001376458483686,
        trade_pair: ["USDJPY", "USD/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 3377.52,
        close_ms: 0,
        current_return: 0.9964386591345129,
        initial_entry_price: 3377.52,
        is_closed_position: false,
        miner_hotkey: "5Enfwuk2sBgePA2PM5H2rtPfmPrSswvUEP7URVe7NoT8ivQh",
        net_leverage: 0.05,
        open_ms: 1719601478813,
        orders: [
          {
            leverage: 0.05,
            order_type: "LONG",
            order_uuid: "1df9c8ba-3d05-44aa-ae5b-e301c4593629",
            price: 3377.52,
            price_sources: [],
            processed_ms: 1719601477673,
          },
        ],
        position_type: "LONG",
        position_uuid: "eb60c443-ddd6-4baf-b0ac-69575b81e807",
        return_at_close: 0.9964137481680345,
        trade_pair: ["ETHUSD", "ETH/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 60995.0,
        close_ms: 0,
        current_return: 0.9859431100909911,
        initial_entry_price: 60995.0,
        is_closed_position: false,
        miner_hotkey: "5Enfwuk2sBgePA2PM5H2rtPfmPrSswvUEP7URVe7NoT8ivQh",
        net_leverage: 0.3,
        open_ms: 1719678357844,
        orders: [
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "f63df40e-7092-46a9-806a-74518760d257",
            price: 60995.0,
            price_sources: [],
            processed_ms: 1719678356523,
          },
        ],
        position_type: "LONG",
        position_uuid: "8c332f1b-bd8c-4dd6-8ec6-b374250f3f1a",
        return_at_close: 0.9857952186244775,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
    ],
    thirty_day_returns: 1.0001376458483686,
  },
  "5EsvxiMYxB12sv8QAMuT58KYWCz1vSGZ8gusEdC4ZjkHiXxE": {
    all_time_returns: 1.0,
    n_positions: 0,
    percentage_profitable: 0.0,
    positions: [
      {
        average_entry_price: 64632.380000000005,
        close_ms: 1711205508565,
        current_return: 0.9965602690168612,
        initial_entry_price: 64632.38,
        is_closed_position: true,
        miner_hotkey: "5EsvxiMYxB12sv8QAMuT58KYWCz1vSGZ8gusEdC4ZjkHiXxE",
        net_leverage: 0.0,
        open_ms: 1711201935810,
        orders: [
          {
            leverage: -0.3,
            order_type: "SHORT",
            order_uuid: "f1254d21-50f5-4f05-8161-66b92d1e2af8",
            price: 64632.38,
            price_sources: [],
            processed_ms: 1711201935809,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "3a9dfb32-3b33-4c8e-8809-5c92541216b9",
            price: 65373.44,
            price_sources: [],
            processed_ms: 1711205508565,
          },
        ],
        position_type: "FLAT",
        position_uuid: "64ac5bde-742e-42b9-abea-7a7838b51184",
        return_at_close: 0.9959623328554511,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 65104.619999999995,
        close_ms: 1711212837076,
        current_return: 0.9976479549377602,
        initial_entry_price: 65104.62,
        is_closed_position: true,
        miner_hotkey: "5EsvxiMYxB12sv8QAMuT58KYWCz1vSGZ8gusEdC4ZjkHiXxE",
        net_leverage: 0.0,
        open_ms: 1711209260083,
        orders: [
          {
            leverage: -0.3,
            order_type: "SHORT",
            order_uuid: "329cb456-0462-4238-a5d2-301dc8ad38d2",
            price: 65104.62,
            price_sources: [],
            processed_ms: 1711209260082,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "e795ab2b-13e4-4714-84ba-e252e13fd876",
            price: 65615.05,
            price_sources: [],
            processed_ms: 1711212837076,
          },
        ],
        position_type: "FLAT",
        position_uuid: "165ec038-9682-4f44-8822-5f4251ac38c2",
        return_at_close: 0.9970493661647974,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 65564.52,
        close_ms: 1711216502617,
        current_return: 0.9995084841618607,
        initial_entry_price: 65564.52,
        is_closed_position: true,
        miner_hotkey: "5EsvxiMYxB12sv8QAMuT58KYWCz1vSGZ8gusEdC4ZjkHiXxE",
        net_leverage: 0.0,
        open_ms: 1711212967583,
        orders: [
          {
            leverage: -0.3,
            order_type: "SHORT",
            order_uuid: "218446b3-1554-4334-b42b-784c2958a356",
            price: 65564.52,
            price_sources: [],
            processed_ms: 1711212967582,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "5ed6dd54-82a9-4b7f-8cc0-fd2384b746c8",
            price: 65671.94,
            price_sources: [],
            processed_ms: 1711216502617,
          },
        ],
        position_type: "FLAT",
        position_uuid: "bd270a0d-57db-4410-96ca-5992f7a7b8f9",
        return_at_close: 0.9989087790713636,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 65643.91,
        close_ms: 1711220177758,
        current_return: 1.0011854869705354,
        initial_entry_price: 65643.91,
        is_closed_position: true,
        miner_hotkey: "5EsvxiMYxB12sv8QAMuT58KYWCz1vSGZ8gusEdC4ZjkHiXxE",
        net_leverage: 0.0,
        open_ms: 1711216588665,
        orders: [
          {
            leverage: -0.3,
            order_type: "SHORT",
            order_uuid: "c9ccefcf-1265-422f-a17b-2208a92255bd",
            price: 65643.91,
            price_sources: [],
            processed_ms: 1711216588664,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "c3ab7765-6854-4048-a300-f81c26520f3b",
            price: 65384.51,
            price_sources: [],
            processed_ms: 1711220177758,
          },
        ],
        position_type: "FLAT",
        position_uuid: "696866a0-8204-40ba-8e9b-32043c5732c7",
        return_at_close: 1.000584775678353,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 65373.26,
        close_ms: 1711223818000,
        current_return: 1.0018348327741342,
        initial_entry_price: 65373.26,
        is_closed_position: true,
        miner_hotkey: "5EsvxiMYxB12sv8QAMuT58KYWCz1vSGZ8gusEdC4ZjkHiXxE",
        net_leverage: 0.0,
        open_ms: 1711220280666,
        orders: [
          {
            leverage: -0.3,
            order_type: "SHORT",
            order_uuid: "00e220ef-cbde-4335-a93c-5a6d58885286",
            price: 65373.26,
            price_sources: [],
            processed_ms: 1711220280665,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "75288ad5-5e11-41e6-8eaf-005d76d87084",
            price: 64973.43,
            price_sources: [],
            processed_ms: 1711223818000,
          },
        ],
        position_type: "FLAT",
        position_uuid: "076e35a5-d455-4cfd-bb16-11f3d72b0c1a",
        return_at_close: 1.0012337318744697,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 64913.94,
        close_ms: 1711227493661,
        current_return: 0.9992772892848594,
        initial_entry_price: 64913.94,
        is_closed_position: true,
        miner_hotkey: "5EsvxiMYxB12sv8QAMuT58KYWCz1vSGZ8gusEdC4ZjkHiXxE",
        net_leverage: 0.0,
        open_ms: 1711223946754,
        orders: [
          {
            leverage: -0.3,
            order_type: "SHORT",
            order_uuid: "867a7cf7-8959-4e72-9d6b-ef27ee6b1b28",
            price: 64913.94,
            price_sources: [],
            processed_ms: 1711223946754,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "c3d11982-c7df-4974-b82e-879328fc7dba",
            price: 65070.32,
            price_sources: [],
            processed_ms: 1711227493661,
          },
        ],
        position_type: "FLAT",
        position_uuid: "bcedefec-40b3-40cd-b053-2da8dc05b93d",
        return_at_close: 0.9986777229112884,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 65029.73000000001,
        close_ms: 1711231149043,
        current_return: 1.0003926819317872,
        initial_entry_price: 65029.73,
        is_closed_position: true,
        miner_hotkey: "5EsvxiMYxB12sv8QAMuT58KYWCz1vSGZ8gusEdC4ZjkHiXxE",
        net_leverage: 0.0,
        open_ms: 1711227596925,
        orders: [
          {
            leverage: -0.3,
            order_type: "SHORT",
            order_uuid: "c26d2f42-cc20-4e09-b694-2361c6d5867b",
            price: 65029.73,
            price_sources: [],
            processed_ms: 1711227596925,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "2046bb46-d630-4a8f-bdb6-e53caaa9ef9c",
            price: 64944.61,
            price_sources: [],
            processed_ms: 1711231149043,
          },
        ],
        position_type: "FLAT",
        position_uuid: "8edd783d-4b55-4653-928c-28f0a46fa1ba",
        return_at_close: 0.9997924463226281,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 64151.06,
        close_ms: 1711242141582,
        current_return: 0.9982538558209326,
        initial_entry_price: 64151.06,
        is_closed_position: true,
        miner_hotkey: "5EsvxiMYxB12sv8QAMuT58KYWCz1vSGZ8gusEdC4ZjkHiXxE",
        net_leverage: 0.0,
        open_ms: 1711238562835,
        orders: [
          {
            leverage: -0.3,
            order_type: "SHORT",
            order_uuid: "ca433375-4f77-475d-b073-780208f7a029",
            price: 64151.06,
            price_sources: [],
            processed_ms: 1711238562834,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "94f8626e-ca97-4dce-b34a-d40a72403439",
            price: 64524.45,
            price_sources: [],
            processed_ms: 1711242141582,
          },
        ],
        position_type: "FLAT",
        position_uuid: "3c0c3235-a6c2-437f-9737-77ba6139acc5",
        return_at_close: 0.99765490350744,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 64406.91999999999,
        close_ms: 1711249462185,
        current_return: 0.9991044751091964,
        initial_entry_price: 64406.92,
        is_closed_position: true,
        miner_hotkey: "5EsvxiMYxB12sv8QAMuT58KYWCz1vSGZ8gusEdC4ZjkHiXxE",
        net_leverage: 0.0,
        open_ms: 1711245955829,
        orders: [
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "6d06e3a8-b488-4603-812a-6a0f9aa60e36",
            price: 64406.92,
            price_sources: [],
            processed_ms: 1711245955828,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "f2a0b3fe-535a-453e-b55b-e303b3d01f52",
            price: 64214.66,
            price_sources: [],
            processed_ms: 1711249462185,
          },
        ],
        position_type: "FLAT",
        position_uuid: "13cb5078-4765-4701-8a57-d598c3e4d727",
        return_at_close: 0.9985050124241308,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 64111.17,
        close_ms: 1711253131768,
        current_return: 0.9997354751129951,
        initial_entry_price: 64111.17,
        is_closed_position: true,
        miner_hotkey: "5EsvxiMYxB12sv8QAMuT58KYWCz1vSGZ8gusEdC4ZjkHiXxE",
        net_leverage: 0.0,
        open_ms: 1711249617225,
        orders: [
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "6482f048-4eb3-41f0-8961-c88f38aaf2b7",
            price: 64111.17,
            price_sources: [],
            processed_ms: 1711249617224,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "cd4def84-c622-4809-86d2-41ca76b18718",
            price: 64054.64,
            price_sources: [],
            processed_ms: 1711253131768,
          },
        ],
        position_type: "FLAT",
        position_uuid: "97942e85-98d5-436a-82c1-c24cfba60ff4",
        return_at_close: 0.9991356338279272,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 64094.74,
        close_ms: 1711256867400,
        current_return: 1.0004075841480908,
        initial_entry_price: 64094.74,
        is_closed_position: true,
        miner_hotkey: "5EsvxiMYxB12sv8QAMuT58KYWCz1vSGZ8gusEdC4ZjkHiXxE",
        net_leverage: 0.0,
        open_ms: 1711253219948,
        orders: [
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "0e3a4131-da5e-4b1c-81b1-7ea83e29fd2b",
            price: 64094.74,
            price_sources: [],
            processed_ms: 1711253219338,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "f2cd9622-874f-46e8-8ad9-5fc5afb7781c",
            price: 64181.82,
            price_sources: [],
            processed_ms: 1711256867400,
          },
        ],
        position_type: "FLAT",
        position_uuid: "5e512694-4439-4261-9e6b-eb6b576c7ee3",
        return_at_close: 0.9998073395976019,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 64133.895000000004,
        close_ms: 1711260406117,
        current_return: 1.0010851745816294,
        initial_entry_price: 64139.91,
        is_closed_position: true,
        miner_hotkey: "5EsvxiMYxB12sv8QAMuT58KYWCz1vSGZ8gusEdC4ZjkHiXxE",
        net_leverage: 0.0,
        open_ms: 1711256981229,
        orders: [
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "88b1d075-211d-4a21-9ff7-b96c8931d17c",
            price: 64139.91,
            price_sources: [],
            processed_ms: 1711256981228,
          },
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "a60a7ace-e377-4590-bd4f-a7b90621728e",
            price: 64127.88,
            price_sources: [],
            processed_ms: 1711257222597,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "9cff5867-2705-4073-8b1b-6541359d2947",
            price: 64249.9,
            price_sources: [],
            processed_ms: 1711260406117,
          },
        ],
        position_type: "FLAT",
        position_uuid: "0d9e5bd1-7762-411b-a9e0-22d735e0f8a2",
        return_at_close: 0.9998838723721315,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 64257.990000000005,
        close_ms: 1711264070564,
        current_return: 0.997331460258872,
        initial_entry_price: 64257.99,
        is_closed_position: true,
        miner_hotkey: "5EsvxiMYxB12sv8QAMuT58KYWCz1vSGZ8gusEdC4ZjkHiXxE",
        net_leverage: 0.0,
        open_ms: 1711260492712,
        orders: [
          {
            leverage: 1.25,
            order_type: "LONG",
            order_uuid: "65f5dd2f-4d55-4203-962c-ca42912600aa",
            price: 64257.99,
            price_sources: [],
            processed_ms: 1711260492709,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "121bfe52-6ead-4de7-ba27-836921c711af",
            price: 64120.81,
            price_sources: [],
            processed_ms: 1711264070564,
          },
        ],
        position_type: "FLAT",
        position_uuid: "23b609dc-0daa-4a1d-9a4c-7aed9ea71e4b",
        return_at_close: 0.9948381316082249,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 64140.61,
        close_ms: 1711267753644,
        current_return: 1.0105303800509535,
        initial_entry_price: 64140.61,
        is_closed_position: true,
        miner_hotkey: "5EsvxiMYxB12sv8QAMuT58KYWCz1vSGZ8gusEdC4ZjkHiXxE",
        net_leverage: 0.0,
        open_ms: 1711264173839,
        orders: [
          {
            leverage: 1.25,
            order_type: "LONG",
            order_uuid: "c34534a4-5f11-4d36-866f-74d46d743117",
            price: 64140.61,
            price_sources: [],
            processed_ms: 1711264173837,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "73daabcf-b93b-4af6-b1b8-2b63a137e302",
            price: 64680.95,
            price_sources: [],
            processed_ms: 1711267753644,
          },
        ],
        position_type: "FLAT",
        position_uuid: "8015a64f-ddad-46ef-94d2-0e1737065b20",
        return_at_close: 1.0080040541008262,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 64767.5,
        close_ms: 1711271392330,
        current_return: 1.0059267765468791,
        initial_entry_price: 64767.5,
        is_closed_position: true,
        miner_hotkey: "5EsvxiMYxB12sv8QAMuT58KYWCz1vSGZ8gusEdC4ZjkHiXxE",
        net_leverage: 0.0,
        open_ms: 1711267854325,
        orders: [
          {
            leverage: 1.25,
            order_type: "LONG",
            order_uuid: "c4f0c774-8bc8-4a48-8993-a26f57ff9daa",
            price: 64767.5,
            price_sources: [],
            processed_ms: 1711267854323,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "7e697eb3-d57e-4fd9-a021-bedbbd877bb7",
            price: 65074.59,
            price_sources: [],
            processed_ms: 1711271392330,
          },
        ],
        position_type: "FLAT",
        position_uuid: "f744635a-55c4-4e98-aa0d-70b0f5814084",
        return_at_close: 1.003411959605512,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 64995.48,
        close_ms: 1711275057313,
        current_return: 0.9995545844111006,
        initial_entry_price: 64995.48,
        is_closed_position: true,
        miner_hotkey: "5EsvxiMYxB12sv8QAMuT58KYWCz1vSGZ8gusEdC4ZjkHiXxE",
        net_leverage: 0.0,
        open_ms: 1711271478769,
        orders: [
          {
            leverage: 1.25,
            order_type: "LONG",
            order_uuid: "ce7f885a-18b9-4e5b-955b-1bf030cf3c3c",
            price: 64995.48,
            price_sources: [],
            processed_ms: 1711271478768,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "1934e98b-8bfa-497e-8f1b-9abd15354820",
            price: 64972.32,
            price_sources: [],
            processed_ms: 1711275057313,
          },
        ],
        position_type: "FLAT",
        position_uuid: "656e788a-ca6d-44bd-86ad-7bcbd4ce8fdc",
        return_at_close: 0.9970556979500729,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 65022.33,
        close_ms: 1711285877970,
        current_return: 1.0081554675140063,
        initial_entry_price: 65022.33,
        is_closed_position: true,
        miner_hotkey: "5EsvxiMYxB12sv8QAMuT58KYWCz1vSGZ8gusEdC4ZjkHiXxE",
        net_leverage: 0.0,
        open_ms: 1711278855238,
        orders: [
          {
            leverage: 1.25,
            order_type: "LONG",
            order_uuid: "3a65ff1c-7a0f-441c-94e4-ee3206908519",
            price: 65022.33,
            price_sources: [],
            processed_ms: 1711278855236,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "9b2fd5b8-8bc4-4a14-a210-1de2dc1591be",
            price: 65446.56,
            price_sources: [],
            processed_ms: 1711285877970,
          },
        ],
        position_type: "FLAT",
        position_uuid: "8a45b8a5-a750-43b7-ab0d-1e3832391c77",
        return_at_close: 1.0056350788452213,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 65548.27,
        close_ms: 1711293251891,
        current_return: 0.9975996696785437,
        initial_entry_price: 65548.27,
        is_closed_position: true,
        miner_hotkey: "5EsvxiMYxB12sv8QAMuT58KYWCz1vSGZ8gusEdC4ZjkHiXxE",
        net_leverage: 0.0,
        open_ms: 1711291376548,
        orders: [
          {
            leverage: 1.25,
            order_type: "LONG",
            order_uuid: "8649f0f8-3355-45b0-9917-abb3384041f1",
            price: 65548.27,
            price_sources: [],
            processed_ms: 1711291376547,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "8b9473d5-d250-44f5-ae53-50d7fa883f19",
            price: 65422.4,
            price_sources: [],
            processed_ms: 1711293251891,
          },
        ],
        position_type: "FLAT",
        position_uuid: "e2df52d5-9758-449d-a1fd-16f53e5a060a",
        return_at_close: 0.9951056705043474,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: -14345.579999999172,
        close_ms: 1711313866018,
        current_return: 0.997605060833897,
        initial_entry_price: 65655.83,
        is_closed_position: true,
        miner_hotkey: "5EsvxiMYxB12sv8QAMuT58KYWCz1vSGZ8gusEdC4ZjkHiXxE",
        net_leverage: 0.0,
        open_ms: 1711305465348,
        orders: [
          {
            leverage: -1.25,
            order_type: "SHORT",
            order_uuid: "bdc0aca2-a2fd-4949-8cb7-c2fce4860b07",
            price: 65655.83,
            price_sources: [],
            processed_ms: 1711305465347,
          },
          {
            leverage: 0.625,
            order_type: "LONG",
            order_uuid: "63a9a7a7-cfed-4549-8bb9-cc95466dd3c7",
            price: 65761.89,
            price_sources: [],
            processed_ms: 1711306393328,
          },
          {
            leverage: 0.3125,
            order_type: "LONG",
            order_uuid: "927452bf-8218-42e8-9979-de2c98f76630",
            price: 65734.52,
            price_sources: [],
            processed_ms: 1711307331267,
          },
          {
            leverage: 0.15625,
            order_type: "LONG",
            order_uuid: "c5ed3573-0e58-4a3e-92e5-e556208f4880",
            price: 65810.0,
            price_sources: [],
            processed_ms: 1711308259943,
          },
          {
            leverage: 0.125,
            order_type: "LONG",
            order_uuid: "bd5c3318-4a8b-4a3e-a79e-a0a5e81e721a",
            price: 65919.74,
            price_sources: [],
            processed_ms: 1711309195669,
          },
          {
            leverage: 0.015625,
            order_type: "LONG",
            order_uuid: "ec624187-8f3c-4821-a080-4ee5c7947ebd",
            price: 65881.43,
            price_sources: [],
            processed_ms: 1711310146198,
          },
          {
            leverage: 0.0078125,
            order_type: "LONG",
            order_uuid: "af1ad823-75f8-4a6c-aeaa-9083e4ad5bb1",
            price: 65796.72,
            price_sources: [],
            processed_ms: 1711311058138,
          },
          {
            leverage: 0.00390625,
            order_type: "LONG",
            order_uuid: "a5960cba-5883-418d-a243-aec99b139aea",
            price: 66305.22,
            price_sources: [],
            processed_ms: 1711311999579,
          },
          {
            leverage: 0.001953125,
            order_type: "LONG",
            order_uuid: "bf16d20f-d36c-49d7-b734-966edba67363",
            price: 66236.66,
            price_sources: [],
            processed_ms: 1711312939635,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "0f1501bc-646e-4c38-a42d-4843cb7cc65e",
            price: 66162.18,
            price_sources: [],
            processed_ms: 1711313866018,
          },
        ],
        position_type: "FLAT",
        position_uuid: "af8f875b-7181-4f48-9b20-fb07ddbb30d4",
        return_at_close: 0.9951110481818123,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 60521.183333333254,
        close_ms: 1711322259978,
        current_return: 0.9901882274413533,
        initial_entry_price: 66308.61,
        is_closed_position: true,
        miner_hotkey: "5EsvxiMYxB12sv8QAMuT58KYWCz1vSGZ8gusEdC4ZjkHiXxE",
        net_leverage: 0.0,
        open_ms: 1711315733910,
        orders: [
          {
            leverage: -1.25,
            order_type: "SHORT",
            order_uuid: "37af1208-b20b-4850-8d88-29665ef635ed",
            price: 66308.61,
            price_sources: [],
            processed_ms: 1711315733908,
          },
          {
            leverage: 0.125,
            order_type: "LONG",
            order_uuid: "bdbfa73c-f3ff-4cca-8aac-77149c9f63d1",
            price: 66325.41,
            price_sources: [],
            processed_ms: 1711316665069,
          },
          {
            leverage: 0.125,
            order_type: "LONG",
            order_uuid: "d6636e50-6913-4b2c-8e57-3ff7f506122e",
            price: 66552.29,
            price_sources: [],
            processed_ms: 1711317599930,
          },
          {
            leverage: 0.5,
            order_type: "LONG",
            order_uuid: "acbfa63d-d0b9-4531-9576-ef3a8247634a",
            price: 66835.03,
            price_sources: [],
            processed_ms: 1711318530812,
          },
          {
            leverage: 0.125,
            order_type: "LONG",
            order_uuid: "fadbcdd7-fb3a-4ca5-b161-4e51305c127d",
            price: 66956.3,
            price_sources: [],
            processed_ms: 1711319461014,
          },
          {
            leverage: 0.1875,
            order_type: "LONG",
            order_uuid: "9bab25b3-aacf-4540-86b3-4ee90d7970e9",
            price: 66853.89,
            price_sources: [],
            processed_ms: 1711320397808,
          },
          {
            leverage: 0.09375,
            order_type: "LONG",
            order_uuid: "347ea1b5-f08e-4e84-bac1-aa700c62d990",
            price: 66987.01,
            price_sources: [],
            processed_ms: 1711321339163,
          },
          {
            leverage: 0.125,
            order_type: "LONG",
            order_uuid: "7ed8b77f-d6d9-4760-9e93-dba0a6a104f6",
            price: 67460.97,
            price_sources: [],
            processed_ms: 1711322259978,
          },
        ],
        position_type: "FLAT",
        position_uuid: "ca15cfd6-196b-400b-a5e5-4ad27dc83b47",
        return_at_close: 0.98771275687275,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 145630.35999998986,
        close_ms: 1711346892102,
        current_return: 1.0018346361122954,
        initial_entry_price: 66767.37,
        is_closed_position: true,
        miner_hotkey: "5EsvxiMYxB12sv8QAMuT58KYWCz1vSGZ8gusEdC4ZjkHiXxE",
        net_leverage: 0.0,
        open_ms: 1711326925072,
        orders: [
          {
            leverage: -1.25,
            order_type: "SHORT",
            order_uuid: "980d76c4-3ff5-4fc2-a9d4-9953649152fc",
            price: 66767.37,
            price_sources: [],
            processed_ms: 1711326925070,
          },
          {
            leverage: 0.125,
            order_type: "LONG",
            order_uuid: "98a7aba4-5707-4d92-a3df-529de3b0a557",
            price: 66520.63,
            price_sources: [],
            processed_ms: 1711327853104,
          },
          {
            leverage: 0.125,
            order_type: "LONG",
            order_uuid: "70ce77bf-53d6-4721-b62d-dd38fbb83af6",
            price: 66761.69,
            price_sources: [],
            processed_ms: 1711328799003,
          },
          {
            leverage: 0.5,
            order_type: "LONG",
            order_uuid: "95998817-17cd-4425-8a66-a25ae8e6457d",
            price: 66636.07,
            price_sources: [],
            processed_ms: 1711332248881,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "c6a0fb85-498a-49bb-a1d4-a61555b7856e",
            price: 66725.04,
            price_sources: [],
            processed_ms: 1711334060887,
          },
          {
            leverage: 0.2,
            order_type: "LONG",
            order_uuid: "c172fbeb-9de2-4ef4-ba9a-72546dc7b580",
            price: 66619.0,
            price_sources: [],
            processed_ms: 1711335892006,
          },
          {
            leverage: 0.024999999999999994,
            order_type: "LONG",
            order_uuid: "879f6958-32f2-4edf-8533-4030f9470dd9",
            price: 66829.57,
            price_sources: [],
            processed_ms: 1711337727954,
          },
          {
            leverage: 0.012499999999999997,
            order_type: "LONG",
            order_uuid: "bc63f60b-9957-40db-ad57-a4698e0c9e5c",
            price: 67204.12,
            price_sources: [],
            processed_ms: 1711339602237,
          },
          {
            leverage: 0.006249999999999999,
            order_type: "LONG",
            order_uuid: "808a3c67-e609-44b9-9c9a-7bbd6992151f",
            price: 67299.7,
            price_sources: [],
            processed_ms: 1711341396303,
          },
          {
            leverage: 0.0031249999999999993,
            order_type: "LONG",
            order_uuid: "5480f2ad-ab07-4249-838e-fb0eff40f423",
            price: 67575.55,
            price_sources: [],
            processed_ms: 1711343221227,
          },
          {
            leverage: 0.0015624999999999997,
            order_type: "LONG",
            order_uuid: "d5ee67b4-df2c-4a5a-8f4c-5daba50bc6b6",
            price: 67643.26,
            price_sources: [],
            processed_ms: 1711345079147,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "3ee627df-b885-4caf-aec2-9ceb790d750c",
            price: 67234.31,
            price_sources: [],
            processed_ms: 1711346892102,
          },
        ],
        position_type: "FLAT",
        position_uuid: "0424404e-0599-497a-976b-5035ab060425",
        return_at_close: 0.9993300495220148,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 66931.94,
        close_ms: 1711361556248,
        current_return: 1.0050906189182625,
        initial_entry_price: 66931.94,
        is_closed_position: true,
        miner_hotkey: "5EsvxiMYxB12sv8QAMuT58KYWCz1vSGZ8gusEdC4ZjkHiXxE",
        net_leverage: 0.0,
        open_ms: 1711359724363,
        orders: [
          {
            leverage: 1.25,
            order_type: "LONG",
            order_uuid: "d455dd67-6bf6-4439-81c4-2b25f5dbf6df",
            price: 66931.94,
            price_sources: [],
            processed_ms: 1711359724361,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "484e170d-11c4-4575-93e1-e84df1d55d73",
            price: 67204.52,
            price_sources: [],
            processed_ms: 1711361556248,
          },
        ],
        position_type: "FLAT",
        position_uuid: "5ecc3f2b-a63a-4755-9803-98d88fcfaf92",
        return_at_close: 1.002577892370967,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 67169.18222222223,
        close_ms: 1711376083465,
        current_return: 1.0562711555924698,
        initial_entry_price: 67086.28,
        is_closed_position: true,
        miner_hotkey: "5EsvxiMYxB12sv8QAMuT58KYWCz1vSGZ8gusEdC4ZjkHiXxE",
        net_leverage: 0.0,
        open_ms: 1711363379231,
        orders: [
          {
            leverage: 1.25,
            order_type: "LONG",
            order_uuid: "637b6198-bcf1-400b-8fb4-5e64c5257f5c",
            price: 67086.28,
            price_sources: [],
            processed_ms: 1711363379229,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "3b5568b7-5927-48cc-a127-baacd49548c0",
            price: 67126.17,
            price_sources: [],
            processed_ms: 1711369722174,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "6ce83359-9796-4b75-84e5-c70cad8e53d6",
            price: 67033.56,
            price_sources: [],
            processed_ms: 1711371559009,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "bc2d1490-7a08-419e-98c8-9df35d0144aa",
            price: 66924.37,
            price_sources: [],
            processed_ms: 1711373386189,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "8100ebba-ecae-433c-ace0-4036ac0ecc4e",
            price: 68007.14,
            price_sources: [],
            processed_ms: 1711375217409,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "51370f1b-c8b1-4491-87ee-28947a8ab3fb",
            price: 68846.97,
            price_sources: [],
            processed_ms: 1711376083465,
          },
        ],
        position_type: "FLAT",
        position_uuid: "0193337a-042e-4cb2-aa39-e124d2ff52aa",
        return_at_close: 1.0515179353923036,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 69235.28,
        close_ms: 1711376627508,
        current_return: 0.9975877543934248,
        initial_entry_price: 69235.28,
        is_closed_position: true,
        miner_hotkey: "5EsvxiMYxB12sv8QAMuT58KYWCz1vSGZ8gusEdC4ZjkHiXxE",
        net_leverage: 0.0,
        open_ms: 1711376607869,
        orders: [
          {
            leverage: -1.25,
            order_type: "SHORT",
            order_uuid: "02ee289a-21a2-4c99-8e87-07cecfdb8240",
            price: 69235.28,
            price_sources: [],
            processed_ms: 1711376607867,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "899df164-1b69-4374-834c-a6f90554fe29",
            price: 69368.89,
            price_sources: [],
            processed_ms: 1711376627508,
          },
        ],
        position_type: "FLAT",
        position_uuid: "ef829988-1baf-4f4a-a72e-5f9c6b3ed69c",
        return_at_close: 0.9950937850074413,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 44442.65666666644,
        close_ms: 1711390182337,
        current_return: 0.9858489154252174,
        initial_entry_price: 69295.36,
        is_closed_position: true,
        miner_hotkey: "5EsvxiMYxB12sv8QAMuT58KYWCz1vSGZ8gusEdC4ZjkHiXxE",
        net_leverage: 0.0,
        open_ms: 1711378330585,
        orders: [
          {
            leverage: -1.25,
            order_type: "SHORT",
            order_uuid: "5af82fb3-e9c0-47e9-bb59-73635bc87397",
            price: 69295.36,
            price_sources: [],
            processed_ms: 1711378330583,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "4bfa3ead-6f08-442d-ad75-ba9a64b99645",
            price: 69903.6,
            price_sources: [],
            processed_ms: 1711381905963,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "55d43343-7f89-4528-8ead-f1cbf195b12a",
            price: 69664.42,
            price_sources: [],
            processed_ms: 1711383707496,
          },
          {
            leverage: 0.375,
            order_type: "LONG",
            order_uuid: "167c2e23-d8ca-4cf2-85e4-53cc60a94d7a",
            price: 70069.71,
            price_sources: [],
            processed_ms: 1711385519158,
          },
          {
            leverage: 0.1875,
            order_type: "LONG",
            order_uuid: "91434ed7-126e-4bb4-8afe-ef5392c4701a",
            price: 70531.35,
            price_sources: [],
            processed_ms: 1711387343346,
          },
          {
            leverage: 0.15,
            order_type: "LONG",
            order_uuid: "e3a90ff7-ea57-4912-938a-bea594ce4495",
            price: 70398.84,
            price_sources: [],
            processed_ms: 1711389173589,
          },
          {
            leverage: 5.0375,
            order_type: "LONG",
            order_uuid: "06d60f5c-7add-43f8-b3a4-7da10464aec6",
            price: 70592.11,
            price_sources: [],
            processed_ms: 1711390182337,
          },
        ],
        position_type: "FLAT",
        position_uuid: "d5ae9501-19f7-4015-8477-d9b468394752",
        return_at_close: 0.9833842931366543,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 70696.50209302327,
        close_ms: 1711401545941,
        current_return: 0.9265437868950878,
        initial_entry_price: 70605.6,
        is_closed_position: true,
        miner_hotkey: "5EsvxiMYxB12sv8QAMuT58KYWCz1vSGZ8gusEdC4ZjkHiXxE",
        net_leverage: 0.0,
        open_ms: 1711391985992,
        orders: [
          {
            leverage: 5.0,
            order_type: "LONG",
            order_uuid: "baf6ea72-d5cc-4806-96ad-9b52d9b7cd85",
            price: 70605.6,
            price_sources: [],
            processed_ms: 1711391985990,
          },
          {
            leverage: 0.9000000000000004,
            order_type: "LONG",
            order_uuid: "2eebadb7-d6bd-422d-9639-5b78593014b2",
            price: 70633.63,
            price_sources: [],
            processed_ms: 1711394253732,
          },
          {
            leverage: 0.9000000000000004,
            order_type: "LONG",
            order_uuid: "44438aab-dd72-4c5d-9c3f-1e514f403fdb",
            price: 70844.04,
            price_sources: [],
            processed_ms: 1711396064330,
          },
          {
            leverage: 0.9000000000000004,
            order_type: "LONG",
            order_uuid: "61779018-4930-404c-ba97-20aa54a6f200",
            price: 70863.38,
            price_sources: [],
            processed_ms: 1711397882655,
          },
          {
            leverage: 0.9000000000000004,
            order_type: "LONG",
            order_uuid: "ad9e9167-e298-432a-92fc-27bc7f4a144e",
            price: 70949.97,
            price_sources: [],
            processed_ms: 1711399696257,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "d3f47a95-2013-405e-b0cd-798350338eec",
            price: 70093.43,
            price_sources: [],
            processed_ms: 1711401545941,
          },
        ],
        position_type: "FLAT",
        position_uuid: "5fc5b382-d6b6-4781-b08c-f95d5c53985c",
        return_at_close: 0.9106072337604922,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
    ],
    thirty_day_returns: 1.0,
  },
  "5Et6DsfKyfe2PBziKo48XNsTCWst92q8xWLdcFy6hig427qH": {
    all_time_returns: 1.0949497723430546,
    n_positions: 4,
    percentage_profitable: 0.75,
    positions: [
      {
        average_entry_price: 3627.6549999999997,
        close_ms: 1718219562461,
        current_return: 0.9971014219591856,
        initial_entry_price: 3713.89,
        is_closed_position: true,
        miner_hotkey: "5Et6DsfKyfe2PBziKo48XNsTCWst92q8xWLdcFy6hig427qH",
        net_leverage: 0.0,
        open_ms: 1716561956232,
        orders: [
          {
            leverage: 0.5,
            order_type: "LONG",
            order_uuid: "09f6f397-bf40-45af-ad51-a6f6f99072d8",
            price: 3713.89,
            price_sources: [],
            processed_ms: 1716561956057,
          },
          {
            leverage: -0.3,
            order_type: "SHORT",
            order_uuid: "e2afa747-8259-4add-be44-1adc11323804",
            price: 3771.38,
            price_sources: [],
            processed_ms: 1717075283970,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "2a48c8af-4d0d-42ef-872f-e5a4554c13eb",
            price: 3573.83,
            price_sources: [],
            processed_ms: 1718219562461,
          },
        ],
        position_type: "FLAT",
        position_uuid: "b673bece-d122-40df-bc94-40ed6ca323a9",
        return_at_close: 0.9966028712482061,
        trade_pair: ["ETHUSD", "ETH/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 5299.6,
        close_ms: 1718285431526,
        current_return: 1.012994376934108,
        initial_entry_price: 5299.6,
        is_closed_position: true,
        miner_hotkey: "5Et6DsfKyfe2PBziKo48XNsTCWst92q8xWLdcFy6hig427qH",
        net_leverage: 0.0,
        open_ms: 1716298242945,
        orders: [
          {
            leverage: 0.5,
            order_type: "LONG",
            order_uuid: "9d790eec-17cb-408f-862a-09128de308c6",
            price: 5299.6,
            price_sources: [],
            processed_ms: 1716298242164,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "d695ef2a-ba41-4436-925b-c925c6ea1f2e",
            price: 5437.33,
            price_sources: [],
            processed_ms: 1718285431526,
          },
        ],
        position_type: "FLAT",
        position_uuid: "609e9cba-59be-4968-b02c-2177eb244b82",
        return_at_close: 1.012948792187146,
        trade_pair: ["SPX", "SPX", 9e-5, 0.001, 500],
      },
      {
        average_entry_price: 5434.13,
        close_ms: 1719581747334,
        current_return: 1.0046469260028745,
        initial_entry_price: 5434.13,
        is_closed_position: true,
        miner_hotkey: "5Et6DsfKyfe2PBziKo48XNsTCWst92q8xWLdcFy6hig427qH",
        net_leverage: 0.0,
        open_ms: 1718285626617,
        orders: [
          {
            leverage: 0.4,
            order_type: "LONG",
            order_uuid: "63132999-0e10-4cdf-957e-4ee91d5a835a",
            price: 5434.13,
            price_sources: [],
            processed_ms: 1718285624473,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "2818c63b-eaea-4d8a-86b3-403cf068ace7",
            price: 5497.26,
            price_sources: [],
            processed_ms: 1719581747334,
          },
        ],
        position_type: "FLAT",
        position_uuid: "63132999-0e10-4cdf-957e-4ee91d5a835a",
        return_at_close: 1.0046107587135384,
        trade_pair: ["SPX", "SPX", 9e-5, 0.001, 500],
      },
      {
        average_entry_price: 120146.55999999995,
        close_ms: 1719843382305,
        current_return: 1.0807400841774961,
        initial_entry_price: 71054.62,
        is_closed_position: true,
        miner_hotkey: "5Et6DsfKyfe2PBziKo48XNsTCWst92q8xWLdcFy6hig427qH",
        net_leverage: 0.0,
        open_ms: 1717594307532,
        orders: [
          {
            leverage: -0.5,
            order_type: "SHORT",
            order_uuid: "445171f5-9aa6-4a5e-9f42-b92fd9c24649",
            price: 71054.62,
            price_sources: [],
            processed_ms: 1717594307089,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "0d9b1294-4e21-4130-a7e6-c97b06b2233f",
            price: 68940.97,
            price_sources: [],
            processed_ms: 1717784247000,
          },
          {
            leverage: -0.25,
            order_type: "SHORT",
            order_uuid: "aa20a907-32bc-45f5-b7f8-67c86c9a5ffe",
            price: 69720.65,
            price_sources: [],
            processed_ms: 1717966896945,
          },
          {
            leverage: -0.25,
            order_type: "SHORT",
            order_uuid: "a8d37a2f-def3-4810-aac2-29c435e7fc3f",
            price: 69840.03,
            price_sources: [],
            processed_ms: 1718202174341,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "49bbf678-d2b2-4cf6-a9c1-b531cc106e07",
            price: 67407.19,
            price_sources: [],
            processed_ms: 1718221915718,
          },
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "fc7b18df-af85-4e9a-aaeb-e779938cad7c",
            price: 61267.0,
            price_sources: [],
            processed_ms: 1719226366517,
          },
          {
            leverage: 0.1,
            order_type: "LONG",
            order_uuid: "507199c3-9fcd-45fb-9fd1-6d17d807db56",
            price: 59356.84,
            price_sources: [],
            processed_ms: 1719262334505,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "6f07aa50-52f6-4e07-9671-27afbaaf4b0b",
            price: 62777.0,
            price_sources: [],
            processed_ms: 1719843382305,
          },
        ],
        position_type: "FLAT",
        position_uuid: "76b94d5d-0896-433c-a3e9-e68e7c8adb94",
        return_at_close: 1.0796593440933187,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
    ],
    thirty_day_returns: 1.0046107587135384,
  },
  "5EtR9REUYwaAYzKbMbGjsT9i1ipchaKYoJ5g97ofcCVF2vZ1": {
    all_time_returns: 1.000795758513285,
    n_positions: 5,
    percentage_profitable: 1.0,
    positions: [
      {
        average_entry_price: 0.60922,
        close_ms: 1719789231185,
        current_return: 1.0000481792861144,
        initial_entry_price: 0.6092200000000001,
        is_closed_position: true,
        miner_hotkey: "5EtR9REUYwaAYzKbMbGjsT9i1ipchaKYoJ5g97ofcCVF2vZ1",
        net_leverage: 0.0,
        open_ms: 1719601420157,
        orders: [
          {
            leverage: 0.027177578413619643,
            order_type: "LONG",
            order_uuid: "ccbe2a8e-a7aa-48e4-af29-9ef9752b0855",
            price: 0.6092200000000001,
            price_sources: [],
            processed_ms: 1719601420151,
          },
          {
            leverage: 0.2507800158058348,
            order_type: "FLAT",
            order_uuid: "fb810345-4b14-45f5-b014-6ba1a2ba9099",
            price: 0.6103,
            price_sources: [],
            processed_ms: 1719789231185,
          },
        ],
        position_type: "FLAT",
        position_uuid: "ccbe2a8e-a7aa-48e4-af29-9ef9752b0855",
        return_at_close: 1.0000462767639677,
        trade_pair: ["NZDUSD", "NZD/USD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.60973,
        close_ms: 1719815476385,
        current_return: 1.0000139276110733,
        initial_entry_price: 0.60973,
        is_closed_position: true,
        miner_hotkey: "5EtR9REUYwaAYzKbMbGjsT9i1ipchaKYoJ5g97ofcCVF2vZ1",
        net_leverage: 0.0,
        open_ms: 1719810244276,
        orders: [
          {
            leverage: 0.014898389999661012,
            order_type: "LONG",
            order_uuid: "a8d7a56a-6f86-482b-b926-39a48f110d8b",
            price: 0.60973,
            price_sources: [],
            processed_ms: 1719810244270,
          },
          {
            leverage: 0.6974057369459903,
            order_type: "FLAT",
            order_uuid: "d33848bc-8e8f-4f22-9da7-f610455b8ec8",
            price: 0.6103,
            price_sources: [],
            processed_ms: 1719815476385,
          },
        ],
        position_type: "FLAT",
        position_uuid: "a8d7a56a-6f86-482b-b926-39a48f110d8b",
        return_at_close: 1.0000128847092484,
        trade_pair: ["NZDUSD", "NZD/USD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.609295,
        close_ms: 1719843554009,
        current_return: 1.0001348068766753,
        initial_entry_price: 0.609295,
        is_closed_position: true,
        miner_hotkey: "5EtR9REUYwaAYzKbMbGjsT9i1ipchaKYoJ5g97ofcCVF2vZ1",
        net_leverage: 0.0,
        open_ms: 1719842059073,
        orders: [
          {
            leverage: -0.11990825682321994,
            order_type: "SHORT",
            order_uuid: "feb20cd0-be56-4598-bd47-f9b02052dd54",
            price: 0.609295,
            price_sources: [],
            processed_ms: 1719842059064,
          },
          {
            leverage: 0.5944240365662049,
            order_type: "FLAT",
            order_uuid: "dab74369-89e3-4e84-9719-547b681e5f63",
            price: 0.60861,
            price_sources: [],
            processed_ms: 1719843554009,
          },
        ],
        position_type: "FLAT",
        position_uuid: "feb20cd0-be56-4598-bd47-f9b02052dd54",
        return_at_close: 1.0001264121671856,
        trade_pair: ["NZDUSD", "NZD/USD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.66656,
        close_ms: 1719844696269,
        current_return: 1.000419167587679,
        initial_entry_price: 0.66656,
        is_closed_position: true,
        miner_hotkey: "5EtR9REUYwaAYzKbMbGjsT9i1ipchaKYoJ5g97ofcCVF2vZ1",
        net_leverage: 0.0,
        open_ms: 1719813434686,
        orders: [
          {
            leverage: -0.2200002734199956,
            order_type: "SHORT",
            order_uuid: "9db28358-d0d5-4c76-9049-a5ce577cd111",
            price: 0.66656,
            price_sources: [],
            processed_ms: 1719813434683,
          },
          {
            leverage: 0.2365114390740682,
            order_type: "FLAT",
            order_uuid: "9309bcd5-a621-4e02-a7f0-b1a37bc37f0c",
            price: 0.66529,
            price_sources: [],
            processed_ms: 1719844696269,
          },
        ],
        position_type: "FLAT",
        position_uuid: "9db28358-d0d5-4c76-9049-a5ce577cd111",
        return_at_close: 1.0004037611133507,
        trade_pair: ["AUDUSD", "AUD/USD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.60744,
        close_ms: 1719876907425,
        current_return: 1.0002158310250437,
        initial_entry_price: 0.60744,
        is_closed_position: true,
        miner_hotkey: "5EtR9REUYwaAYzKbMbGjsT9i1ipchaKYoJ5g97ofcCVF2vZ1",
        net_leverage: 0.0,
        open_ms: 1719863288917,
        orders: [
          {
            leverage: -0.13728209199221258,
            order_type: "SHORT",
            order_uuid: "88d9ac10-06dc-4c68-98e7-d1f582137bdc",
            price: 0.60744,
            price_sources: [],
            processed_ms: 1719863288904,
          },
          {
            leverage: 0.4420548614606149,
            order_type: "FLAT",
            order_uuid: "554987d8-038b-45bf-b949-2e0930338f24",
            price: 0.6064849999999999,
            price_sources: [],
            processed_ms: 1719876907425,
          },
        ],
        position_type: "FLAT",
        position_uuid: "88d9ac10-06dc-4c68-98e7-d1f582137bdc",
        return_at_close: 1.000206219204523,
        trade_pair: ["NZDUSD", "NZD/USD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 160.767,
        close_ms: 0,
        current_return: 0.996781621090128,
        initial_entry_price: 160.767,
        is_closed_position: false,
        miner_hotkey: "5EtR9REUYwaAYzKbMbGjsT9i1ipchaKYoJ5g97ofcCVF2vZ1",
        net_leverage: -0.5539712229158346,
        open_ms: 1720468182790,
        orders: [
          {
            leverage: -0.5539712229158346,
            order_type: "SHORT",
            order_uuid: "1fee908b-15b6-40b2-a185-ccf2f99ca43f",
            price: 160.767,
            price_sources: [
              {
                close: 160.767,
                high: 160.767,
                lag_ms: 98,
                low: 160.767,
                open: 160.767,
                source: "Polygon_ws",
                start_ms: 1720468181000,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 160.767,
                websocket: true,
              },
              {
                close: 160.772,
                high: 160.772,
                lag_ms: 902,
                low: 160.772,
                open: 160.772,
                source: "TwelveData_ws",
                start_ms: 1720468182000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
              {
                close: 160.7695,
                high: 160.7695,
                lag_ms: 2099,
                low: 160.7695,
                open: 160.7695,
                source: "Polygon_rest",
                start_ms: 1720468178000,
                timespan_ms: 1000,
                volume: 4.0,
                vwap: null,
                websocket: false,
              },
              {
                close: 160.765,
                high: 160.77499,
                lag_ms: 641099,
                low: 160.75999,
                open: 160.77499,
                source: "TwelveData_rest",
                start_ms: 1720467480000,
                timespan_ms: 60000,
                volume: null,
                vwap: null,
                websocket: false,
              },
            ],
            processed_ms: 1720468181098,
          },
        ],
        position_type: "SHORT",
        position_uuid: "1fee908b-15b6-40b2-a185-ccf2f99ca43f",
        return_at_close: 0.9967622944984514,
        trade_pair: ["USDJPY", "USD/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.60545,
        close_ms: 0,
        current_return: 0.9997403808454616,
        initial_entry_price: 0.60545,
        is_closed_position: false,
        miner_hotkey: "5EtR9REUYwaAYzKbMbGjsT9i1ipchaKYoJ5g97ofcCVF2vZ1",
        net_leverage: -0.061641732202076645,
        open_ms: 1719894826092,
        orders: [
          {
            leverage: -0.061641732202076645,
            order_type: "SHORT",
            order_uuid: "284f168c-a521-41a9-9a20-6c2b78e3eeaa",
            price: 0.60545,
            price_sources: [],
            processed_ms: 1719894826089,
          },
        ],
        position_type: "SHORT",
        position_uuid: "284f168c-a521-41a9-9a20-6c2b78e3eeaa",
        return_at_close: 0.9997382239449526,
        trade_pair: ["NZDUSD", "NZD/USD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 3382.1105213619317,
        close_ms: 0,
        current_return: 0.9983255851070221,
        initial_entry_price: 3396.56,
        is_closed_position: false,
        miner_hotkey: "5EtR9REUYwaAYzKbMbGjsT9i1ipchaKYoJ5g97ofcCVF2vZ1",
        net_leverage: 0.023198068829755874,
        open_ms: 1719595429719,
        orders: [
          {
            leverage: 0.003198068829755874,
            order_type: "LONG",
            order_uuid: "f8d02a44-c339-44cc-a008-6155f9c79aa5",
            price: 3396.56,
            price_sources: [],
            processed_ms: 1719595428648,
          },
          {
            leverage: 0.02,
            order_type: "LONG",
            order_uuid: "dcb1db34-0d89-49e7-aa52-cf170a9b75f7",
            price: 3379.8,
            price_sources: [],
            processed_ms: 1719598676708,
          },
        ],
        position_type: "LONG",
        position_uuid: "f8d02a44-c339-44cc-a008-6155f9c79aa5",
        return_at_close: 0.9983140054942032,
        trade_pair: ["ETHUSD", "ETH/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 60598.63399165293,
        close_ms: 0,
        current_return: 0.9981343227277777,
        initial_entry_price: 60667.49,
        is_closed_position: false,
        miner_hotkey: "5EtR9REUYwaAYzKbMbGjsT9i1ipchaKYoJ5g97ofcCVF2vZ1",
        net_leverage: 0.04598001069191068,
        open_ms: 1719597355231,
        orders: [
          {
            leverage: 0.02598001069191068,
            order_type: "LONG",
            order_uuid: "6019c3bd-71c5-4efd-90a4-367a8142e7cb",
            price: 60667.49,
            price_sources: [],
            processed_ms: 1719597353442,
          },
          {
            leverage: 0.02,
            order_type: "LONG",
            order_uuid: "9792e29b-4642-4215-8293-c9c223fd1665",
            price: 60509.19,
            price_sources: [],
            processed_ms: 1719620654848,
          },
        ],
        position_type: "LONG",
        position_uuid: "6019c3bd-71c5-4efd-90a4-367a8142e7cb",
        return_at_close: 0.9981113756143621,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 1.3635950000000001,
        close_ms: 0,
        current_return: 1.0003096877537094,
        initial_entry_price: 1.3635950000000001,
        is_closed_position: false,
        miner_hotkey: "5EtR9REUYwaAYzKbMbGjsT9i1ipchaKYoJ5g97ofcCVF2vZ1",
        net_leverage: -0.16925397696161987,
        open_ms: 1720487242882,
        orders: [
          {
            leverage: -0.16925397696161987,
            order_type: "SHORT",
            order_uuid: "65a022dc-2650-4264-b3ca-26a4ba214891",
            price: 1.3635950000000001,
            price_sources: [
              {
                close: 1.3635950000000001,
                high: 1.3635950000000001,
                lag_ms: 1125,
                low: 1.3635950000000001,
                open: 1.3635950000000001,
                source: "Polygon_ws",
                start_ms: 1720487244000,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 1.36358,
                websocket: true,
              },
              {
                close: 1.3632,
                high: 1.3632,
                lag_ms: 2125,
                low: 1.3632,
                open: 1.3632,
                source: "TwelveData_ws",
                start_ms: 1720487245000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1720487242875,
          },
        ],
        position_type: "SHORT",
        position_uuid: "65a022dc-2650-4264-b3ca-26a4ba214891",
        return_at_close: 1.00030376202996,
        trade_pair: ["USDCAD", "USD/CAD", 7e-5, 0.001, 500],
      },
    ],
    thirty_day_returns: 1.000795758513285,
  },
  "5Eva7QSqpdypncGTa5YEn6kyvVaCFSXUF9D8UZcd6hMZwa1X": {
    all_time_returns: 1.0,
    n_positions: 0,
    percentage_profitable: 0.0,
    positions: [
      {
        average_entry_price: 63816.02,
        close_ms: 1711134293998,
        current_return: 1.001013068505369,
        initial_entry_price: 63816.02,
        is_closed_position: true,
        miner_hotkey: "5Eva7QSqpdypncGTa5YEn6kyvVaCFSXUF9D8UZcd6hMZwa1X",
        net_leverage: 0.0,
        open_ms: 1711133627998,
        orders: [
          {
            leverage: -5.0,
            order_type: "SHORT",
            order_uuid: "62f9e633-3755-477e-b302-4ed5fe6d084f",
            price: 63816.02,
            price_sources: [],
            processed_ms: 1711133627997,
          },
          {
            leverage: 5.0,
            order_type: "FLAT",
            order_uuid: "547ea803-0db3-48c2-a83c-48d248ac7716",
            price: 63803.09,
            price_sources: [],
            processed_ms: 1711134293998,
          },
        ],
        position_type: "FLAT",
        position_uuid: "d10bd684-5e3f-4406-a67a-bdc9d92aa038",
        return_at_close: 0.9910029378203153,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 64602.34199999999,
        close_ms: 1711182901070,
        current_return: 0.9946424991045455,
        initial_entry_price: 64687.81,
        is_closed_position: true,
        miner_hotkey: "5Eva7QSqpdypncGTa5YEn6kyvVaCFSXUF9D8UZcd6hMZwa1X",
        net_leverage: 0.0,
        open_ms: 1711178404181,
        orders: [
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "f0b21258-4486-487c-b251-0bf2cc78fa6c",
            price: 64687.81,
            price_sources: [],
            processed_ms: 1711178404180,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "6ed64a5a-b50d-4c7d-9b3e-10c75cd71f25",
            price: 64469.8,
            price_sources: [],
            processed_ms: 1711179309063,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "d007a677-6cb7-4917-b4b8-3f76f2847979",
            price: 64557.56,
            price_sources: [],
            processed_ms: 1711180225657,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "9999e570-8fd9-463f-8d81-e27ea1d72ead",
            price: 64747.12,
            price_sources: [],
            processed_ms: 1711181115869,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "3b10c49d-7a60-4c24-8968-6750121e7db2",
            price: 64549.42,
            price_sources: [],
            processed_ms: 1711182003146,
          },
          {
            leverage: 0.25,
            order_type: "FLAT",
            order_uuid: "bca8d6e4-dd18-41cc-be27-45db9f6d406c",
            price: 64325.09,
            price_sources: [],
            processed_ms: 1711182901070,
          },
        ],
        position_type: "FLAT",
        position_uuid: "a2a75819-2633-4b67-a52b-3e2b747beb77",
        return_at_close: 0.9921558928567842,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 64357.212,
        close_ms: 1711188333359,
        current_return: 1.00457297355144,
        initial_entry_price: 64476.1,
        is_closed_position: true,
        miner_hotkey: "5Eva7QSqpdypncGTa5YEn6kyvVaCFSXUF9D8UZcd6hMZwa1X",
        net_leverage: 0.0,
        open_ms: 1711183804976,
        orders: [
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "76679c18-243b-496f-bb4c-8564c6535d6d",
            price: 64476.1,
            price_sources: [],
            processed_ms: 1711183804975,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "fa639a05-bfe1-4d7d-8840-ba68aefcffe6",
            price: 64396.88,
            price_sources: [],
            processed_ms: 1711184753544,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "bb2e5a8f-0ba5-4c3f-aeb1-d96b48bf5929",
            price: 64288.97,
            price_sources: [],
            processed_ms: 1711185602865,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "d1c40791-c203-49cd-99c3-c7d187195e2b",
            price: 64326.56,
            price_sources: [],
            processed_ms: 1711186556268,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "99d0e379-1843-4b52-9d3c-4f62d5cbeeb4",
            price: 64297.55,
            price_sources: [],
            processed_ms: 1711187401987,
          },
          {
            leverage: 0.25,
            order_type: "FLAT",
            order_uuid: "6043c502-bf42-4333-92f9-9936e28bf202",
            price: 64593.09,
            price_sources: [],
            processed_ms: 1711188333359,
          },
        ],
        position_type: "FLAT",
        position_uuid: "067b5cee-9c50-4ec9-9434-afe2d43fe687",
        return_at_close: 1.0020615411175613,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 64524.898,
        close_ms: 1711193707261,
        current_return: 1.0008609310600045,
        initial_entry_price: 64569.63,
        is_closed_position: true,
        miner_hotkey: "5Eva7QSqpdypncGTa5YEn6kyvVaCFSXUF9D8UZcd6hMZwa1X",
        net_leverage: 0.0,
        open_ms: 1711189214827,
        orders: [
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "fea7d5a6-066c-4840-8de4-8d9388c20b76",
            price: 64569.63,
            price_sources: [],
            processed_ms: 1711189214826,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "1cc37162-b8c9-4eeb-878a-4cfe5065c395",
            price: 64651.84,
            price_sources: [],
            processed_ms: 1711190117861,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "9495705e-f728-48da-abfd-37c3c7de2ebd",
            price: 64528.62,
            price_sources: [],
            processed_ms: 1711191007106,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "0462eeb5-5261-4cb2-bd3c-5461996a8b97",
            price: 64361.79,
            price_sources: [],
            processed_ms: 1711191906247,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "0d14d86e-8fc2-40e1-85c6-770cde75b582",
            price: 64512.61,
            price_sources: [],
            processed_ms: 1711192807305,
          },
          {
            leverage: 0.25,
            order_type: "FLAT",
            order_uuid: "25c1d582-900a-4346-9c75-c9eae42f53fa",
            price: 64569.37,
            price_sources: [],
            processed_ms: 1711193707261,
          },
        ],
        position_type: "FLAT",
        position_uuid: "ea98b18f-c11e-4577-bc70-db99188b90d7",
        return_at_close: 0.9983587787323546,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 64645.579999999994,
        close_ms: 1711199105315,
        current_return: 0.9994411284820447,
        initial_entry_price: 64549.72,
        is_closed_position: true,
        miner_hotkey: "5Eva7QSqpdypncGTa5YEn6kyvVaCFSXUF9D8UZcd6hMZwa1X",
        net_leverage: 0.0,
        open_ms: 1711194604331,
        orders: [
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "4c390462-78f2-4e80-a714-918cfb11bb34",
            price: 64549.72,
            price_sources: [],
            processed_ms: 1711194604330,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "2e61cb85-3695-4d1f-82c0-a43c310293c8",
            price: 64585.11,
            price_sources: [],
            processed_ms: 1711195506377,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "7398b3b7-fc45-45ee-b1e2-d18791145be6",
            price: 64643.13,
            price_sources: [],
            processed_ms: 1711196409438,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "a5eda9ed-790e-4d33-8d40-a2ff301126ca",
            price: 64649.71,
            price_sources: [],
            processed_ms: 1711197305283,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "0f7b7cf3-385b-44f7-9927-344581409d45",
            price: 64800.23,
            price_sources: [],
            processed_ms: 1711198207252,
          },
          {
            leverage: 0.25,
            order_type: "FLAT",
            order_uuid: "a6d05899-afdc-4735-9108-6d4240c501d3",
            price: 64616.72,
            price_sources: [],
            processed_ms: 1711199105315,
          },
        ],
        position_type: "FLAT",
        position_uuid: "2a651a74-86c0-4dd6-bee7-847909df0491",
        return_at_close: 0.9969425256608396,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 64547.116,
        close_ms: 1711204507387,
        current_return: 1.0161926257433103,
        initial_entry_price: 64576.0,
        is_closed_position: true,
        miner_hotkey: "5Eva7QSqpdypncGTa5YEn6kyvVaCFSXUF9D8UZcd6hMZwa1X",
        net_leverage: 0.0,
        open_ms: 1711200043089,
        orders: [
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "cd5c4248-c932-4d14-9dc7-cb39b8f7d5db",
            price: 64576.0,
            price_sources: [],
            processed_ms: 1711200043088,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "412e5908-508f-4286-a5a4-d5ddc30e6c97",
            price: 64454.56,
            price_sources: [],
            processed_ms: 1711200923332,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "d634624e-d188-4ba5-b382-9770cc98141f",
            price: 64526.68,
            price_sources: [],
            processed_ms: 1711201810987,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "0fec0059-3ea7-4b3e-8ad3-df38cfa06602",
            price: 64593.1,
            price_sources: [],
            processed_ms: 1711202712144,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "1e557d05-d81d-4c1e-b43f-8c15f84432bb",
            price: 64585.24,
            price_sources: [],
            processed_ms: 1711203622313,
          },
          {
            leverage: 0.25,
            order_type: "FLAT",
            order_uuid: "e4f3c125-a700-43f7-bbd6-746d1943b762",
            price: 65383.64,
            price_sources: [],
            processed_ms: 1711204507387,
          },
        ],
        position_type: "FLAT",
        position_uuid: "b05230da-f7ed-4de7-8645-6f59afdade86",
        return_at_close: 1.013652144178952,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 65054.526,
        close_ms: 1711209919762,
        current_return: 1.0003061161250102,
        initial_entry_price: 65187.68,
        is_closed_position: true,
        miner_hotkey: "5Eva7QSqpdypncGTa5YEn6kyvVaCFSXUF9D8UZcd6hMZwa1X",
        net_leverage: 0.0,
        open_ms: 1711205405283,
        orders: [
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "7fcfa5ff-d887-4c01-a61d-8bd0c73e073d",
            price: 65187.68,
            price_sources: [],
            processed_ms: 1711205405282,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "d69a1cdd-89c2-4b74-9c03-07cd3c8c5834",
            price: 65204.0,
            price_sources: [],
            processed_ms: 1711206311917,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "5928190e-73f0-4f27-a037-2a28364c488b",
            price: 65006.84,
            price_sources: [],
            processed_ms: 1711207206366,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "1c340b9c-5465-49fc-b958-8b1d42a23218",
            price: 64927.67,
            price_sources: [],
            processed_ms: 1711208110282,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "3435226f-8d10-48e4-847f-4670e7d76c39",
            price: 64946.44,
            price_sources: [],
            processed_ms: 1711209009376,
          },
          {
            leverage: 0.25,
            order_type: "FLAT",
            order_uuid: "80addb73-39c6-4d90-867c-909bd1007263",
            price: 65070.49,
            price_sources: [],
            processed_ms: 1711209919762,
          },
        ],
        position_type: "FLAT",
        position_uuid: "f8810e1a-c4ef-4a17-8ccc-dc835b6fe635",
        return_at_close: 0.9978053508346977,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 65543.668,
        close_ms: 1711215307342,
        current_return: 1.0074813697546279,
        initial_entry_price: 65165.54,
        is_closed_position: true,
        miner_hotkey: "5Eva7QSqpdypncGTa5YEn6kyvVaCFSXUF9D8UZcd6hMZwa1X",
        net_leverage: 0.0,
        open_ms: 1711210809285,
        orders: [
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "857eb94a-fe71-40a9-ac4e-e6ca98113faf",
            price: 65165.54,
            price_sources: [],
            processed_ms: 1711210809284,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "4e00174b-a4e2-417b-94d0-33871390eb1d",
            price: 65515.23,
            price_sources: [],
            processed_ms: 1711211707353,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "0192628b-24bb-40e0-904b-33094a19e974",
            price: 65750.05,
            price_sources: [],
            processed_ms: 1711212607297,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "fe75afff-8d67-4d63-95c8-b15602226d3f",
            price: 65722.76,
            price_sources: [],
            processed_ms: 1711213507283,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "d7bb4b8e-be0d-4e5d-b91c-8f8e27f59cb2",
            price: 65564.76,
            price_sources: [],
            processed_ms: 1711214438171,
          },
          {
            leverage: 0.25,
            order_type: "FLAT",
            order_uuid: "caa87d3a-f1c7-4351-84a7-9ca2956d1af3",
            price: 65933.69,
            price_sources: [],
            processed_ms: 1711215307342,
          },
        ],
        position_type: "FLAT",
        position_uuid: "5c70678e-be81-4622-bb07-30207dfb6007",
        return_at_close: 1.0049626663302413,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 65552.27,
        close_ms: 1711220731350,
        current_return: 0.9972054410173607,
        initial_entry_price: 65779.61,
        is_closed_position: true,
        miner_hotkey: "5Eva7QSqpdypncGTa5YEn6kyvVaCFSXUF9D8UZcd6hMZwa1X",
        net_leverage: 0.0,
        open_ms: 1711216210307,
        orders: [
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "456f97c2-87bf-4e62-9174-65a723e87ec7",
            price: 65779.61,
            price_sources: [],
            processed_ms: 1711216210305,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "c284b914-52ac-42bf-b8a9-e9d9482efb81",
            price: 65586.97,
            price_sources: [],
            processed_ms: 1711217114760,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "5c569447-3d42-4266-bb30-6baf5f0ae755",
            price: 65516.37,
            price_sources: [],
            processed_ms: 1711218011909,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "4e02ec9f-f436-4f04-a0ea-a15a7e80d67a",
            price: 65479.0,
            price_sources: [],
            processed_ms: 1711218940486,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "5dab45f4-a424-4770-a99d-4aa66f49d9a8",
            price: 65399.4,
            price_sources: [],
            processed_ms: 1711219823363,
          },
          {
            leverage: 0.25,
            order_type: "FLAT",
            order_uuid: "5b480459-ce84-4f35-9719-998b13a75f86",
            price: 65405.21,
            price_sources: [],
            processed_ms: 1711220731350,
          },
        ],
        position_type: "FLAT",
        position_uuid: "852437dc-500c-4ff6-9c41-246e9749b207",
        return_at_close: 0.9947124274148174,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 65011.41799999999,
        close_ms: 1711226117195,
        current_return: 0.9994505494505496,
        initial_entry_price: 65310.7,
        is_closed_position: true,
        miner_hotkey: "5Eva7QSqpdypncGTa5YEn6kyvVaCFSXUF9D8UZcd6hMZwa1X",
        net_leverage: 0.0,
        open_ms: 1711221617255,
        orders: [
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "132dc406-f6a5-48b6-950a-6658dbd9dc95",
            price: 65310.7,
            price_sources: [],
            processed_ms: 1711221617252,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "8917d3a9-5488-4b23-a984-264870d56be5",
            price: 65026.82,
            price_sources: [],
            processed_ms: 1711222511855,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "d8b4b886-5cba-4b8a-a942-cf3478b12491",
            price: 64886.53,
            price_sources: [],
            processed_ms: 1711223411232,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "f4bf1f82-2299-4a03-b976-a2a5db90663f",
            price: 64945.21,
            price_sources: [],
            processed_ms: 1711224316265,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "b021cecd-f5c1-428b-8afa-e81f352d6631",
            price: 64887.83,
            price_sources: [],
            processed_ms: 1711225210003,
          },
          {
            leverage: 0.25,
            order_type: "FLAT",
            order_uuid: "e3aadd8d-11a4-40a3-b3ec-08ab613e9762",
            price: 64982.71,
            price_sources: [],
            processed_ms: 1711226117195,
          },
        ],
        position_type: "FLAT",
        position_uuid: "14b68c43-7667-47d7-8519-9e2b60646c2c",
        return_at_close: 0.9969519230769233,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 64949.469999999994,
        close_ms: 1711231539968,
        current_return: 1.0002230695915537,
        initial_entry_price: 64946.1,
        is_closed_position: true,
        miner_hotkey: "5Eva7QSqpdypncGTa5YEn6kyvVaCFSXUF9D8UZcd6hMZwa1X",
        net_leverage: 0.0,
        open_ms: 1711227014905,
        orders: [
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "8982d2fd-e900-42bd-9015-2d641623371b",
            price: 64946.1,
            price_sources: [],
            processed_ms: 1711227014904,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "57ba1674-dfce-4c18-aa60-80d0e849d646",
            price: 64901.36,
            price_sources: [],
            processed_ms: 1711227936834,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "3fbebcb2-86e3-411f-9cdf-f65daf42e4ce",
            price: 64956.57,
            price_sources: [],
            processed_ms: 1711228817262,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "d0063929-7e50-462c-b6cd-2bf041bc2174",
            price: 64980.47,
            price_sources: [],
            processed_ms: 1711229718255,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "25c29674-1903-4c87-b899-ad66dd45a644",
            price: 64962.85,
            price_sources: [],
            processed_ms: 1711230624205,
          },
          {
            leverage: 0.25,
            order_type: "FLAT",
            order_uuid: "f819ab12-d5f8-4a75-80df-cb762cc29cb4",
            price: 64961.06,
            price_sources: [],
            processed_ms: 1711231539968,
          },
        ],
        position_type: "FLAT",
        position_uuid: "033d1f4a-2b54-42a3-9e53-46a5e7165d8a",
        return_at_close: 0.9977225119175749,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 64842.862,
        close_ms: 1711236945108,
        current_return: 0.9927114519731713,
        initial_entry_price: 64848.65,
        is_closed_position: true,
        miner_hotkey: "5Eva7QSqpdypncGTa5YEn6kyvVaCFSXUF9D8UZcd6hMZwa1X",
        net_leverage: 0.0,
        open_ms: 1711232412988,
        orders: [
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "be7b15e0-bed7-4b54-bdc3-bd408de2e5c3",
            price: 64848.65,
            price_sources: [],
            processed_ms: 1711232412986,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "863958ea-b390-43a9-94e7-65f6afcd9afc",
            price: 65028.74,
            price_sources: [],
            processed_ms: 1711233314605,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "296e3650-f5c7-4899-94fc-596b3d4f1a9a",
            price: 64826.46,
            price_sources: [],
            processed_ms: 1711234217230,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "072ba74d-bd3e-4dc0-8a17-b584cf96af9f",
            price: 64761.96,
            price_sources: [],
            processed_ms: 1711235120212,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "3a47732a-c42b-45af-b26e-60813934430b",
            price: 64748.5,
            price_sources: [],
            processed_ms: 1711236015252,
          },
          {
            leverage: 0.25,
            order_type: "FLAT",
            order_uuid: "59c6e7c0-0d1a-439f-a00d-63cd8ca95259",
            price: 64464.74,
            price_sources: [],
            processed_ms: 1711236945108,
          },
        ],
        position_type: "FLAT",
        position_uuid: "16470f70-6228-41f8-a306-02c4f77c5041",
        return_at_close: 0.9902296733432384,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 64362.772,
        close_ms: 1711242322229,
        current_return: 1.0021251324514537,
        initial_entry_price: 64306.58,
        is_closed_position: true,
        miner_hotkey: "5Eva7QSqpdypncGTa5YEn6kyvVaCFSXUF9D8UZcd6hMZwa1X",
        net_leverage: 0.0,
        open_ms: 1711237819154,
        orders: [
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "a03df73e-31b4-4069-b29e-921af3ab7fe6",
            price: 64306.58,
            price_sources: [],
            processed_ms: 1711237819152,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "ce18b814-53c1-401e-b5b6-803ce5ada463",
            price: 64174.27,
            price_sources: [],
            processed_ms: 1711238721138,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "c39529e4-40de-4282-9605-83ff83e20af5",
            price: 64278.73,
            price_sources: [],
            processed_ms: 1711239619331,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "c2bf46b0-4ddf-4256-bd1f-f052993c0ec2",
            price: 64468.49,
            price_sources: [],
            processed_ms: 1711240516862,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "8ee4a151-af38-4eb9-accc-36e2c0c445f6",
            price: 64585.79,
            price_sources: [],
            processed_ms: 1711241421202,
          },
          {
            leverage: 0.25,
            order_type: "FLAT",
            order_uuid: "4fcb9323-9756-4eb5-82fe-f515c62503f0",
            price: 64472.1,
            price_sources: [],
            processed_ms: 1711242322229,
          },
        ],
        position_type: "FLAT",
        position_uuid: "86f2881c-326e-444c-ac39-9e0decf74eb4",
        return_at_close: 0.9996198196203252,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 64432.340000000004,
        close_ms: 1711247725269,
        current_return: 0.9976603664129391,
        initial_entry_price: 64401.11,
        is_closed_position: true,
        miner_hotkey: "5Eva7QSqpdypncGTa5YEn6kyvVaCFSXUF9D8UZcd6hMZwa1X",
        net_leverage: 0.0,
        open_ms: 1711243219189,
        orders: [
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "49f57a73-2858-42fd-82f4-0d8fea3624dd",
            price: 64401.11,
            price_sources: [],
            processed_ms: 1711243219188,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "c923c857-e3e8-4125-8ce3-1cfc6037d13b",
            price: 64562.75,
            price_sources: [],
            processed_ms: 1711244116283,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "be40c102-df7e-484d-9061-450b7dabf93e",
            price: 64504.61,
            price_sources: [],
            processed_ms: 1711245020398,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "94a54cb1-d155-451d-aa3f-ec1df6061a26",
            price: 64320.83,
            price_sources: [],
            processed_ms: 1711245916396,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "2edc9e11-edcf-4a3d-a06f-66b62089c33f",
            price: 64372.4,
            price_sources: [],
            processed_ms: 1711246843785,
          },
          {
            leverage: 0.25,
            order_type: "FLAT",
            order_uuid: "478d6d01-8857-4203-9d93-478f80e4279d",
            price: 64311.8,
            price_sources: [],
            processed_ms: 1711247725269,
          },
        ],
        position_type: "FLAT",
        position_uuid: "fb5fc8d2-a48c-41c2-899d-f11a2dfde10c",
        return_at_close: 0.9951662154969068,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 64184.195999999996,
        close_ms: 1711253117493,
        current_return: 0.9974555799829418,
        initial_entry_price: 64285.77,
        is_closed_position: true,
        miner_hotkey: "5Eva7QSqpdypncGTa5YEn6kyvVaCFSXUF9D8UZcd6hMZwa1X",
        net_leverage: 0.0,
        open_ms: 1711248625351,
        orders: [
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "3397fb8c-b59f-4aba-a03c-19986e88e9d8",
            price: 64285.77,
            price_sources: [],
            processed_ms: 1711248625349,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "be982333-895f-4a67-bd6d-9f5aabdeea65",
            price: 64200.38,
            price_sources: [],
            processed_ms: 1711249535833,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "b25549aa-10dd-4a48-9c8c-4bc891455539",
            price: 64210.5,
            price_sources: [],
            processed_ms: 1711250426306,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "36ba7da5-ed7e-47b5-baee-355f741db30b",
            price: 64107.86,
            price_sources: [],
            processed_ms: 1711251318343,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "1ba009c2-8d38-40e0-a653-a89d40e8dafe",
            price: 64116.47,
            price_sources: [],
            processed_ms: 1711252228597,
          },
          {
            leverage: 0.25,
            order_type: "FLAT",
            order_uuid: "ac5c830e-9f63-44a9-a3e7-c97fe81d8f9a",
            price: 64053.34,
            price_sources: [],
            processed_ms: 1711253117493,
          },
        ],
        position_type: "FLAT",
        position_uuid: "8778f5eb-3750-499e-9c6c-73f64075c181",
        return_at_close: 0.9949619410329844,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 64112.862,
        close_ms: 1711258527258,
        current_return: 1.0043877033554696,
        initial_entry_price: 64107.57,
        is_closed_position: true,
        miner_hotkey: "5Eva7QSqpdypncGTa5YEn6kyvVaCFSXUF9D8UZcd6hMZwa1X",
        net_leverage: 0.0,
        open_ms: 1711254019242,
        orders: [
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "a633e4d1-edf9-4018-9d1b-8808ca9ce5b8",
            price: 64107.57,
            price_sources: [],
            processed_ms: 1711254019240,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "db6ba8d4-3c7a-4151-8272-b999025d6633",
            price: 63943.16,
            price_sources: [],
            processed_ms: 1711254921283,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "5040863c-cc1e-4cc4-803a-8a32e67f9b24",
            price: 64023.31,
            price_sources: [],
            processed_ms: 1711255819607,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "530ff047-802a-40d0-a341-233d37c0bcf9",
            price: 64241.57,
            price_sources: [],
            processed_ms: 1711256719634,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "304ac435-c14f-45d4-877a-88bd734465ee",
            price: 64248.7,
            price_sources: [],
            processed_ms: 1711257634487,
          },
          {
            leverage: 0.25,
            order_type: "FLAT",
            order_uuid: "95baf9c5-393e-4a59-9a32-12b35eb62342",
            price: 64337.89,
            price_sources: [],
            processed_ms: 1711258527258,
          },
        ],
        position_type: "FLAT",
        position_uuid: "fc7e459f-321f-48b4-96e5-08f796439ce7",
        return_at_close: 1.001876734097081,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 64280.2,
        close_ms: 1711263928406,
        current_return: 0.997150136969017,
        initial_entry_price: 64353.97,
        is_closed_position: true,
        miner_hotkey: "5Eva7QSqpdypncGTa5YEn6kyvVaCFSXUF9D8UZcd6hMZwa1X",
        net_leverage: 0.0,
        open_ms: 1711259474177,
        orders: [
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "ef6a735d-82db-45c7-8aeb-684e602300f5",
            price: 64353.97,
            price_sources: [],
            processed_ms: 1711259474174,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "dfcfcc24-c952-40c2-a251-2578f4ea3a48",
            price: 64258.44,
            price_sources: [],
            processed_ms: 1711260327425,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "47cade2b-9446-4069-ab81-6726b37376f4",
            price: 64373.55,
            price_sources: [],
            processed_ms: 1711261222418,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "46b72d96-f569-4c9d-8153-0fea126d8537",
            price: 64242.04,
            price_sources: [],
            processed_ms: 1711262129887,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "51c8f54d-c679-4c6f-ad48-df58cd701c5a",
            price: 64173.0,
            price_sources: [],
            processed_ms: 1711263029232,
          },
          {
            leverage: 0.25,
            order_type: "FLAT",
            order_uuid: "5f38f589-a8a4-4636-8b6c-89a8d7fd9f88",
            price: 64133.48,
            price_sources: [],
            processed_ms: 1711263928406,
          },
        ],
        position_type: "FLAT",
        position_uuid: "4493c4c0-6cd7-4127-9cf5-2c44aeeb7a31",
        return_at_close: 0.9946572616265945,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 64373.81999999999,
        close_ms: 1711309308345,
        current_return: 1.0291824948150943,
        initial_entry_price: 64123.63,
        is_closed_position: true,
        miner_hotkey: "5Eva7QSqpdypncGTa5YEn6kyvVaCFSXUF9D8UZcd6hMZwa1X",
        net_leverage: 0.0,
        open_ms: 1711264891033,
        orders: [
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "768a3856-c72b-450e-b489-b0a6d80f17dc",
            price: 64123.63,
            price_sources: [],
            processed_ms: 1711264891031,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "c7db1781-ed3f-4103-9686-b714003487fd",
            price: 64096.58,
            price_sources: [],
            processed_ms: 1711265721305,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "0b5b67f6-04f2-44d2-baae-1abafa349f73",
            price: 64322.28,
            price_sources: [],
            processed_ms: 1711266621922,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "fead207c-7590-45d9-b61c-e9b1b332b23f",
            price: 64592.54,
            price_sources: [],
            processed_ms: 1711267524245,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "0eca438c-7f5b-46df-81a2-9c18737de737",
            price: 64734.07,
            price_sources: [],
            processed_ms: 1711268445336,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "3fcac500-ea4f-4f20-8d3e-21ec047076fe",
            price: 65870.85,
            price_sources: [],
            processed_ms: 1711309308345,
          },
        ],
        position_type: "FLAT",
        position_uuid: "621e68a0-9872-4ec5-8e87-f068d252f634",
        return_at_close: 1.0266095385780567,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 70664.36,
        close_ms: 1711453769229,
        current_return: 1.0140127215473265,
        initial_entry_price: 70664.36,
        is_closed_position: true,
        miner_hotkey: "5Eva7QSqpdypncGTa5YEn6kyvVaCFSXUF9D8UZcd6hMZwa1X",
        net_leverage: 0.0,
        open_ms: 1711453652212,
        orders: [
          {
            leverage: 10.0,
            order_type: "LONG",
            order_uuid: "0bcfd642-11c6-4408-bcfd-69cf0eeca2ef",
            price: 70664.36,
            price_sources: [],
            processed_ms: 1711453652209,
          },
          {
            leverage: 10.0,
            order_type: "FLAT",
            order_uuid: "c80b6fc9-fa14-4d73-bada-a5a91631547c",
            price: 70763.38,
            price_sources: [],
            processed_ms: 1711453769229,
          },
        ],
        position_type: "FLAT",
        position_uuid: "baa8eebd-e1c9-478a-991c-409fa5a834c7",
        return_at_close: 0.99373246711638,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 70780.69,
        close_ms: 1711454080997,
        current_return: 0.9800177138708306,
        initial_entry_price: 70780.69,
        is_closed_position: true,
        miner_hotkey: "5Eva7QSqpdypncGTa5YEn6kyvVaCFSXUF9D8UZcd6hMZwa1X",
        net_leverage: 0.0,
        open_ms: 1711453834274,
        orders: [
          {
            leverage: 19.0,
            order_type: "LONG",
            order_uuid: "e30724a7-fb53-4819-bc6e-b31a2f29fef2",
            price: 70780.69,
            price_sources: [],
            processed_ms: 1711453834271,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "52aa9311-bda6-47c5-9247-d52b8dedad2c",
            price: 70706.25,
            price_sources: [],
            processed_ms: 1711454080997,
          },
        ],
        position_type: "FLAT",
        position_uuid: "1fadcc93-30da-4e80-beb0-208404569052",
        return_at_close: 0.942777040743739,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 70781.11,
        close_ms: 1711457031215,
        current_return: 1.068029026388536,
        initial_entry_price: 70781.11,
        is_closed_position: true,
        miner_hotkey: "5Eva7QSqpdypncGTa5YEn6kyvVaCFSXUF9D8UZcd6hMZwa1X",
        net_leverage: 0.0,
        open_ms: 1711455368047,
        orders: [
          {
            leverage: 19.0,
            order_type: "LONG",
            order_uuid: "9e63fc07-4ccd-4bfd-bf5f-d61cc48220ba",
            price: 70781.11,
            price_sources: [],
            processed_ms: 1711455368045,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "c2095b8b-70ec-488e-b0b3-a8040a21e87c",
            price: 71034.54,
            price_sources: [],
            processed_ms: 1711457031215,
          },
        ],
        position_type: "FLAT",
        position_uuid: "7c17aee0-4e92-45e4-bcf2-e7a0684641a4",
        return_at_close: 1.0274439233857715,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 70922.36333333334,
        close_ms: 1711458205885,
        current_return: 1.037126442276437,
        initial_entry_price: 70913.07,
        is_closed_position: true,
        miner_hotkey: "5Eva7QSqpdypncGTa5YEn6kyvVaCFSXUF9D8UZcd6hMZwa1X",
        net_leverage: 0.0,
        open_ms: 1711457879178,
        orders: [
          {
            leverage: 10.0,
            order_type: "LONG",
            order_uuid: "b1963aee-4b2f-44f7-90cc-692396497a02",
            price: 70913.07,
            price_sources: [],
            processed_ms: 1711457879176,
          },
          {
            leverage: 5.0,
            order_type: "LONG",
            order_uuid: "5ec4366c-18f6-4d8f-95b4-97d48d707944",
            price: 70940.95,
            price_sources: [],
            processed_ms: 1711457943563,
          },
          {
            leverage: 5.0,
            order_type: "FLAT",
            order_uuid: "cdb9097f-590a-4337-bcd0-ba64738a0c99",
            price: 71097.88,
            price_sources: [],
            processed_ms: 1711458205885,
          },
        ],
        position_type: "FLAT",
        position_uuid: "909744fc-643c-434f-8b17-62aa94a36ca3",
        return_at_close: 1.0060126490081438,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 70784.26,
        close_ms: 1711459834193,
        current_return: 1.0133673785669308,
        initial_entry_price: 70784.26,
        is_closed_position: true,
        miner_hotkey: "5Eva7QSqpdypncGTa5YEn6kyvVaCFSXUF9D8UZcd6hMZwa1X",
        net_leverage: 0.0,
        open_ms: 1711459769355,
        orders: [
          {
            leverage: 19.0,
            order_type: "LONG",
            order_uuid: "86c51331-e797-4932-bbcf-c78230b87061",
            price: 70784.26,
            price_sources: [],
            processed_ms: 1711459769353,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "20c6430e-6c70-451a-8c25-23a0e956c1f3",
            price: 70834.06,
            price_sources: [],
            processed_ms: 1711459834193,
          },
        ],
        position_type: "FLAT",
        position_uuid: "92828381-be7d-474d-b5a2-af6457063213",
        return_at_close: 0.9748594181813874,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 70472.71,
        close_ms: 1711462518373,
        current_return: 0.9896456372970471,
        initial_entry_price: 70472.71,
        is_closed_position: true,
        miner_hotkey: "5Eva7QSqpdypncGTa5YEn6kyvVaCFSXUF9D8UZcd6hMZwa1X",
        net_leverage: 0.0,
        open_ms: 1711462335157,
        orders: [
          {
            leverage: 10.0,
            order_type: "LONG",
            order_uuid: "b8c841b3-f45d-4bfb-9e7a-86a64ed949b0",
            price: 70472.71,
            price_sources: [],
            processed_ms: 1711462335154,
          },
          {
            leverage: 2.0,
            order_type: "FLAT",
            order_uuid: "e491a02e-74cb-4787-b1e2-44d7ac28d682",
            price: 70399.74,
            price_sources: [],
            processed_ms: 1711462518373,
          },
        ],
        position_type: "FLAT",
        position_uuid: "517d2384-7636-46d2-9b5f-bf533acecf02",
        return_at_close: 0.9698527245511062,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 70192.55,
        close_ms: 1711462968553,
        current_return: 0.9995038647263847,
        initial_entry_price: 70192.55,
        is_closed_position: true,
        miner_hotkey: "5Eva7QSqpdypncGTa5YEn6kyvVaCFSXUF9D8UZcd6hMZwa1X",
        net_leverage: 0.0,
        open_ms: 1711462726585,
        orders: [
          {
            leverage: 19.9,
            order_type: "LONG",
            order_uuid: "86407398-a004-4f46-a4d7-80552bebae9b",
            price: 70192.55,
            price_sources: [],
            processed_ms: 1711462726582,
          },
          {
            leverage: 0.10000000000000142,
            order_type: "FLAT",
            order_uuid: "51db8305-a3af-4a7b-91db-3271b3e47215",
            price: 70190.8,
            price_sources: [],
            processed_ms: 1711462968553,
          },
        ],
        position_type: "FLAT",
        position_uuid: "a6179443-28f3-45ed-a6f8-0589b0c9d124",
        return_at_close: 0.9597236109102747,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 70099.31,
        close_ms: 1711467033339,
        current_return: 1.0116235095609372,
        initial_entry_price: 70099.31,
        is_closed_position: true,
        miner_hotkey: "5Eva7QSqpdypncGTa5YEn6kyvVaCFSXUF9D8UZcd6hMZwa1X",
        net_leverage: 0.0,
        open_ms: 1711466959283,
        orders: [
          {
            leverage: 12.0,
            order_type: "LONG",
            order_uuid: "b1c4530e-8697-4024-9efb-d0277bfe0f8e",
            price: 70099.31,
            price_sources: [],
            processed_ms: 1711466959280,
          },
          {
            leverage: 8.0,
            order_type: "FLAT",
            order_uuid: "f0631c99-337d-4fce-a7f3-aa325727aacf",
            price: 70167.21,
            price_sources: [],
            processed_ms: 1711467033339,
          },
        ],
        position_type: "FLAT",
        position_uuid: "0f6130cc-1bc1-4915-a363-635e82e82bc6",
        return_at_close: 0.9873445453314746,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 1.07885,
        close_ms: 1711732259877,
        current_return: 0.9999536543541734,
        initial_entry_price: 1.07885,
        is_closed_position: true,
        miner_hotkey: "5Eva7QSqpdypncGTa5YEn6kyvVaCFSXUF9D8UZcd6hMZwa1X",
        net_leverage: 0.0,
        open_ms: 1711732153929,
        orders: [
          {
            leverage: 1.0,
            order_type: "LONG",
            order_uuid: "403d9522-ccac-4b12-97f6-d579986ec23c",
            price: 1.07885,
            price_sources: [],
            processed_ms: 1711732153926,
          },
          {
            leverage: -1.0,
            order_type: "SHORT",
            order_uuid: "32140914-0c48-4d23-b374-cc07d71f3647",
            price: 1.0788,
            price_sources: [],
            processed_ms: 1711732259877,
          },
        ],
        position_type: "FLAT",
        position_uuid: "25ecbcf6-039b-4d7c-b1bf-3b46a77c93fa",
        return_at_close: 0.9998836575983686,
        trade_pair: ["EURUSD", "EUR/USD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 1.0788,
        close_ms: 1711732725179,
        current_return: 1.0,
        initial_entry_price: 1.0788,
        is_closed_position: true,
        miner_hotkey: "5Eva7QSqpdypncGTa5YEn6kyvVaCFSXUF9D8UZcd6hMZwa1X",
        net_leverage: 0.0,
        open_ms: 1711732510917,
        orders: [
          {
            leverage: -1.0,
            order_type: "SHORT",
            order_uuid: "836c808f-e0f7-4a77-98b8-d95c7b4c2796",
            price: 1.0788,
            price_sources: [],
            processed_ms: 1711732510914,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "181cddce-814f-46e4-9e5e-bce52c607433",
            price: 1.0788,
            price_sources: [],
            processed_ms: 1711732725179,
          },
        ],
        position_type: "FLAT",
        position_uuid: "1516b41c-996b-4088-b511-43cc645284d3",
        return_at_close: 0.99993,
        trade_pair: ["EURUSD", "EUR/USD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 69368.33,
        close_ms: 1711756893983,
        current_return: 0.9592602272535614,
        initial_entry_price: 69368.33,
        is_closed_position: true,
        miner_hotkey: "5Eva7QSqpdypncGTa5YEn6kyvVaCFSXUF9D8UZcd6hMZwa1X",
        net_leverage: -5.0,
        open_ms: 1711733771288,
        orders: [
          {
            leverage: -5.0,
            order_type: "SHORT",
            order_uuid: "df433ca3-a7a9-4e27-b4ee-0346363dcdcb",
            price: 69368.33,
            price_sources: [],
            processed_ms: 1711733771286,
          },
        ],
        position_type: "FLAT",
        position_uuid: "0a90ea4d-0611-44d2-8e0c-63a63e2fa628",
        return_at_close: 0.9496676249810257,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
    ],
    thirty_day_returns: 1.0,
  },
  "5EvaRWNZ1mn1vosdneWAV6zsTf2orcBJjp8NruaYPb9PYpK4": {
    all_time_returns: 1.0,
    n_positions: 0,
    percentage_profitable: 0.0,
    positions: [
      {
        average_entry_price: 3382.84,
        close_ms: 0,
        current_return: 1.010903116907687,
        initial_entry_price: 3382.84,
        is_closed_position: false,
        miner_hotkey: "5EvaRWNZ1mn1vosdneWAV6zsTf2orcBJjp8NruaYPb9PYpK4",
        net_leverage: -0.15,
        open_ms: 1719598077264,
        orders: [
          {
            leverage: -0.15,
            order_type: "SHORT",
            order_uuid: "777b2620-8497-4642-9e82-cc5cb49d5620",
            price: 3382.84,
            price_sources: [],
            processed_ms: 1719598076286,
          },
        ],
        position_type: "SHORT",
        position_uuid: "0de3254a-3c67-478f-86b7-b38255b72ca8",
        return_at_close: 1.010827299173919,
        trade_pair: ["ETHUSD", "ETH/USD", 0.003, 0.001, 20],
      },
    ],
    thirty_day_returns: 1.0,
  },
  "5Exax1W9RiNbARDejrthf4SK1FQ2u9DPUhCq9jm58gUysTy4": {
    all_time_returns: 1.0788472560527185,
    n_positions: 22,
    percentage_profitable: 0.7272727272727273,
    positions: [
      {
        average_entry_price: 156.907,
        close_ms: 1714485483951,
        current_return: 1.0003250332999802,
        initial_entry_price: 156.907,
        is_closed_position: true,
        miner_hotkey: "5Exax1W9RiNbARDejrthf4SK1FQ2u9DPUhCq9jm58gUysTy4",
        net_leverage: 0.0,
        open_ms: 1714471113515,
        orders: [
          {
            leverage: 0.1,
            order_type: "LONG",
            order_uuid: "c59045db-b823-4e70-a6c1-345452a9fba6",
            price: 156.907,
            price_sources: [],
            processed_ms: 1714471113514,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "e7cf60d3-8675-4d71-8349-fb58dc5c3160",
            price: 157.417,
            price_sources: [],
            processed_ms: 1714485483951,
          },
        ],
        position_type: "FLAT",
        position_uuid: "14a9d0e3-2b25-415a-bac5-6e695d8d5fc8",
        return_at_close: 1.0003180310247473,
        trade_pair: ["USDJPY", "USD/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 1.2519529999999999,
        close_ms: 1714699353541,
        current_return: 1.0002061880320081,
        initial_entry_price: 1.25468,
        is_closed_position: true,
        miner_hotkey: "5Exax1W9RiNbARDejrthf4SK1FQ2u9DPUhCq9jm58gUysTy4",
        net_leverage: 0.0,
        open_ms: 1714471024403,
        orders: [
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "333ec1e2-88ac-476f-be4d-e0ebb98ca035",
            price: 1.25468,
            price_sources: [],
            processed_ms: 1714471024403,
          },
          {
            leverage: 0.09,
            order_type: "LONG",
            order_uuid: "063c8277-c53b-44f6-b8d7-b8cce6a3339d",
            price: 1.25165,
            price_sources: [],
            processed_ms: 1714485817413,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "736b3ca7-0ad6-47b8-bebe-0d4587ab5e9f",
            price: 1.25454,
            price_sources: [],
            processed_ms: 1714699353541,
          },
        ],
        position_type: "FLAT",
        position_uuid: "0fee36aa-de9a-4dff-a67b-ec2029c70a85",
        return_at_close: 1.000199186588692,
        trade_pair: ["GBPUSD", "GBP/USD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 236.1310000000001,
        close_ms: 1715095641934,
        current_return: 1.0021524536122273,
        initial_entry_price: 196.873,
        is_closed_position: true,
        miner_hotkey: "5Exax1W9RiNbARDejrthf4SK1FQ2u9DPUhCq9jm58gUysTy4",
        net_leverage: 0.0,
        open_ms: 1714471089804,
        orders: [
          {
            leverage: -0.1,
            order_type: "SHORT",
            order_uuid: "5fd4a361-2943-444f-bf11-ba5b444298c5",
            price: 196.873,
            price_sources: [],
            processed_ms: 1714471089804,
          },
          {
            leverage: 0.09,
            order_type: "LONG",
            order_uuid: "a5c860c7-0c7e-457a-8017-ebebbf53a957",
            price: 192.511,
            price_sources: [],
            processed_ms: 1714670449947,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "2dff22b5-0fb1-4d24-ac93-e4976b82f2eb",
            price: 193.755,
            price_sources: [],
            processed_ms: 1715095641934,
          },
        ],
        position_type: "FLAT",
        position_uuid: "937d6734-bfab-4bc1-90d6-6e6c50d1ddd0",
        return_at_close: 1.002145438545052,
        trade_pair: ["GBPJPY", "GBP/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 193.758,
        close_ms: 1715186288804,
        current_return: 1.0004312080017341,
        initial_entry_price: 193.758,
        is_closed_position: true,
        miner_hotkey: "5Exax1W9RiNbARDejrthf4SK1FQ2u9DPUhCq9jm58gUysTy4",
        net_leverage: 0.0,
        open_ms: 1715096003124,
        orders: [
          {
            leverage: 0.15,
            order_type: "LONG",
            order_uuid: "77dd192c-9777-4dd4-8957-a7c62a0eb0b1",
            price: 193.758,
            price_sources: [],
            processed_ms: 1715096002940,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "11efed93-b718-493e-b9a0-bef3559e8655",
            price: 194.315,
            price_sources: [],
            processed_ms: 1715186288804,
          },
        ],
        position_type: "FLAT",
        position_uuid: "69ad95b1-21b6-4b9e-ba8b-5bd70abcc2c0",
        return_at_close: 1.00042070347405,
        trade_pair: ["GBPJPY", "GBP/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 1.78943,
        close_ms: 1715302246026,
        current_return: 1.0001584303381523,
        initial_entry_price: 1.78943,
        is_closed_position: true,
        miner_hotkey: "5Exax1W9RiNbARDejrthf4SK1FQ2u9DPUhCq9jm58gUysTy4",
        net_leverage: 0.0,
        open_ms: 1715272220941,
        orders: [
          {
            leverage: -0.15,
            order_type: "SHORT",
            order_uuid: "da284f8b-49ec-41fa-8369-b996c24d1726",
            price: 1.78943,
            price_sources: [],
            processed_ms: 1715272220598,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "20f80710-817d-447f-b5f8-46523001cead",
            price: 1.78754,
            price_sources: [],
            processed_ms: 1715302246026,
          },
        ],
        position_type: "FLAT",
        position_uuid: "91363b0c-b7eb-416f-8e06-4278dc26df62",
        return_at_close: 1.0001479286746338,
        trade_pair: ["EURNZD", "EUR/NZD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 1.07739,
        close_ms: 1715302265233,
        current_return: 1.0000389830980425,
        initial_entry_price: 1.07739,
        is_closed_position: true,
        miner_hotkey: "5Exax1W9RiNbARDejrthf4SK1FQ2u9DPUhCq9jm58gUysTy4",
        net_leverage: 0.0,
        open_ms: 1715272237635,
        orders: [
          {
            leverage: 0.15,
            order_type: "LONG",
            order_uuid: "616a9d23-06b7-4639-a464-b6ac730732b9",
            price: 1.07739,
            price_sources: [],
            processed_ms: 1715272237390,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "e70b550e-11f3-4bbc-80b2-366d07fcf1e4",
            price: 1.07767,
            price_sources: [],
            processed_ms: 1715302265233,
          },
        ],
        position_type: "FLAT",
        position_uuid: "41b182e3-c559-4d1b-9320-c9e0e5ba52f7",
        return_at_close: 1.00002848268872,
        trade_pair: ["EURUSD", "EUR/USD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.7458999999999931,
        close_ms: 1715303046105,
        current_return: 1.0001337616686454,
        initial_entry_price: 0.85914,
        is_closed_position: true,
        miner_hotkey: "5Exax1W9RiNbARDejrthf4SK1FQ2u9DPUhCq9jm58gUysTy4",
        net_leverage: 0.0,
        open_ms: 1715095968528,
        orders: [
          {
            leverage: 0.15,
            order_type: "LONG",
            order_uuid: "f1d7e7a9-0eac-4a68-ad8f-fe01f7403698",
            price: 0.85914,
            price_sources: [],
            processed_ms: 1715095968125,
          },
          {
            leverage: -0.149,
            order_type: "SHORT",
            order_uuid: "2a849fce-5cc6-4410-baea-88096c9780c9",
            price: 0.8599,
            price_sources: [],
            processed_ms: 1715186354530,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "baa6e0f7-373c-423d-9821-45f29b2220af",
            price: 0.86082,
            price_sources: [],
            processed_ms: 1715303046105,
          },
        ],
        position_type: "FLAT",
        position_uuid: "2a0bf833-9da8-4145-b8a2-b494989932fc",
        return_at_close: 1.0001232602641479,
        trade_pair: ["EURGBP", "EUR/GBP", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.90436,
        close_ms: 1715303062908,
        current_return: 1.000062873752319,
        initial_entry_price: 0.90436,
        is_closed_position: true,
        miner_hotkey: "5Exax1W9RiNbARDejrthf4SK1FQ2u9DPUhCq9jm58gUysTy4",
        net_leverage: 0.0,
        open_ms: 1715272151667,
        orders: [
          {
            leverage: 0.15,
            order_type: "LONG",
            order_uuid: "7e2d8b37-dda4-43af-9f2e-68e94832e50d",
            price: 0.90436,
            price_sources: [],
            processed_ms: 1715272151477,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "fb9c72e6-3182-4011-9388-762f9cb248ac",
            price: 0.904739070044315,
            price_sources: [],
            processed_ms: 1715303062908,
          },
        ],
        position_type: "FLAT",
        position_uuid: "16a8941c-f5c2-4ac5-bca7-e62abac52ca6",
        return_at_close: 1.0000523730921445,
        trade_pair: ["AUDCAD", "AUD/CAD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 113.69,
        close_ms: 1715303171040,
        current_return: 1.0000395813176182,
        initial_entry_price: 113.69,
        is_closed_position: true,
        miner_hotkey: "5Exax1W9RiNbARDejrthf4SK1FQ2u9DPUhCq9jm58gUysTy4",
        net_leverage: 0.0,
        open_ms: 1715272189677,
        orders: [
          {
            leverage: 0.15,
            order_type: "LONG",
            order_uuid: "65f00c95-fd73-4e51-939c-f4fbecffcb7d",
            price: 113.69,
            price_sources: [],
            processed_ms: 1715272189480,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "7a4a7313-193e-4883-8630-c5863ff8987e",
            price: 113.72,
            price_sources: [],
            processed_ms: 1715303171040,
          },
        ],
        position_type: "FLAT",
        position_uuid: "cf5731e0-f2c8-4652-bb94-a65afcef7391",
        return_at_close: 1.0000290809020143,
        trade_pair: ["CADJPY", "CAD/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.66118,
        close_ms: 1715303244641,
        current_return: 1.0197374391239913,
        initial_entry_price: 0.66118,
        is_closed_position: true,
        miner_hotkey: "5Exax1W9RiNbARDejrthf4SK1FQ2u9DPUhCq9jm58gUysTy4",
        net_leverage: 0.0,
        open_ms: 1715302837004,
        orders: [
          {
            leverage: 45.0,
            order_type: "LONG",
            order_uuid: "254120d2-3091-499c-b2a4-8733b493cd76",
            price: 0.66118,
            price_sources: [],
            processed_ms: 1715302836821,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "227cec37-74d3-47ef-814d-d6a730e9711f",
            price: 0.66147,
            price_sources: [],
            processed_ms: 1715303244641,
          },
        ],
        position_type: "FLAT",
        position_uuid: "2c7bd8c6-2b58-4374-a291-7f114e35231c",
        return_at_close: 1.0165252661907507,
        trade_pair: ["AUDUSD", "AUD/USD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.904589852822126,
        close_ms: 1715776327288,
        current_return: 1.051641878584449,
        initial_entry_price: 0.904589852822126,
        is_closed_position: true,
        miner_hotkey: "5Exax1W9RiNbARDejrthf4SK1FQ2u9DPUhCq9jm58gUysTy4",
        net_leverage: 0.0,
        open_ms: 1715776151687,
        orders: [
          {
            leverage: 15.0,
            order_type: "LONG",
            order_uuid: "fb9ccd91-bfe9-49ec-adff-b23d08a98524",
            price: 0.904589852822126,
            price_sources: [],
            processed_ms: 1715776149968,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "14cb8d53-4dd8-43cc-b7dc-ee94fc705db0",
            price: 0.907704167445337,
            price_sources: [],
            processed_ms: 1715776327288,
          },
        ],
        position_type: "FLAT",
        position_uuid: "3d13c127-7c68-465c-a0c2-bd7ff3d4d5ca",
        return_at_close: 1.0505376546119354,
        trade_pair: ["AUDCAD", "AUD/CAD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 102.83,
        close_ms: 1718389678564,
        current_return: 0.9983779052805601,
        initial_entry_price: 102.83,
        is_closed_position: true,
        miner_hotkey: "5Exax1W9RiNbARDejrthf4SK1FQ2u9DPUhCq9jm58gUysTy4",
        net_leverage: 0.0,
        open_ms: 1715272171194,
        orders: [
          {
            leverage: -0.15,
            order_type: "SHORT",
            order_uuid: "9fe70cba-9692-4cac-b440-f9256e52e303",
            price: 102.83,
            price_sources: [],
            processed_ms: 1715272170869,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "e35b2c03-8ee5-4907-b841-ac565879c311",
            price: 103.942,
            price_sources: [],
            processed_ms: 1718389678564,
          },
        ],
        position_type: "FLAT",
        position_uuid: "c56f1bd5-2205-4b3f-beb2-3e7e3940179f",
        return_at_close: 0.9983674223125547,
        trade_pair: ["AUDJPY", "AUD/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 1.2123800000000118,
        close_ms: 1718391023103,
        current_return: 1.000174628039766,
        initial_entry_price: 1.47462,
        is_closed_position: true,
        miner_hotkey: "5Exax1W9RiNbARDejrthf4SK1FQ2u9DPUhCq9jm58gUysTy4",
        net_leverage: 0.0,
        open_ms: 1715272204928,
        orders: [
          {
            leverage: 0.15,
            order_type: "LONG",
            order_uuid: "b8d18f7f-70c1-4137-9e3b-c614ce80bac8",
            price: 1.47462,
            price_sources: [],
            processed_ms: 1715272204615,
          },
          {
            leverage: -0.149,
            order_type: "SHORT",
            order_uuid: "f942ea0b-62d4-4575-90b2-9b82503858c7",
            price: 1.47638,
            price_sources: [],
            processed_ms: 1715700154463,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "5b817e8e-665a-4e6d-8abb-92662ed23234",
            price: 1.46989,
            price_sources: [],
            processed_ms: 1718391023103,
          },
        ],
        position_type: "FLAT",
        position_uuid: "9f1d5c7a-5fe3-45f2-9374-b1753a2b79e2",
        return_at_close: 1.0001641262061716,
        trade_pair: ["EURCAD", "EUR/CAD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 194.745,
        close_ms: 1718391055902,
        current_return: 0.996345220673188,
        initial_entry_price: 194.745,
        is_closed_position: true,
        miner_hotkey: "5Exax1W9RiNbARDejrthf4SK1FQ2u9DPUhCq9jm58gUysTy4",
        net_leverage: 0.0,
        open_ms: 1715272295880,
        orders: [
          {
            leverage: -0.15,
            order_type: "SHORT",
            order_uuid: "0926cc9b-15e6-48d5-b27f-6a4d22a95e97",
            price: 194.745,
            price_sources: [],
            processed_ms: 1715272295680,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "41b901ea-f1fc-4d41-a02a-c47e3f020379",
            price: 199.49,
            price_sources: [],
            processed_ms: 1718391055902,
          },
        ],
        position_type: "FLAT",
        position_uuid: "c8e361f2-fc40-4bda-bfb7-4606ea0099c5",
        return_at_close: 0.9963347590483709,
        trade_pair: ["GBPJPY", "GBP/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 68194.44867924527,
        close_ms: 1718391094497,
        current_return: 0.9953065302538524,
        initial_entry_price: 63902.11,
        is_closed_position: true,
        miner_hotkey: "5Exax1W9RiNbARDejrthf4SK1FQ2u9DPUhCq9jm58gUysTy4",
        net_leverage: 0.0,
        open_ms: 1714856338382,
        orders: [
          {
            leverage: 0.001,
            order_type: "LONG",
            order_uuid: "f7dc7073-653b-4553-a28f-e5b82a859658",
            price: 63902.11,
            price_sources: [],
            processed_ms: 1714856338201,
          },
          {
            leverage: 0.015,
            order_type: "LONG",
            order_uuid: "f647eaac-d822-4408-bdee-49aef7a6b5ce",
            price: 66353.49,
            price_sources: [],
            processed_ms: 1716156853728,
          },
          {
            leverage: 0.02,
            order_type: "LONG",
            order_uuid: "31b3ef2e-8108-4ed9-b2ee-70951afb11c5",
            price: 66405.21,
            price_sources: [],
            processed_ms: 1716157121758,
          },
          {
            leverage: 0.1,
            order_type: "LONG",
            order_uuid: "ffed4bfd-0bd3-411c-bd7f-6cd995e20d58",
            price: 68842.4,
            price_sources: [],
            processed_ms: 1716750393279,
          },
          {
            leverage: 0.02,
            order_type: "LONG",
            order_uuid: "f4e09792-f286-4d58-a505-e9ffb4b69172",
            price: 68535.42,
            price_sources: [],
            processed_ms: 1716813515921,
          },
          {
            leverage: -0.1,
            order_type: "SHORT",
            order_uuid: "407f7bf9-6a1e-4bb6-9f58-ad9150f5bfee",
            price: 68330.77,
            price_sources: [],
            processed_ms: 1717157954539,
          },
          {
            leverage: 0.05,
            order_type: "LONG",
            order_uuid: "8834de52-2a9d-4410-900a-07d4ae5cdf05",
            price: 68388.63,
            price_sources: [],
            processed_ms: 1717379888039,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "71c959d6-82d7-4806-a4ea-1a88f6e47653",
            price: 65364.99,
            price_sources: [],
            processed_ms: 1718391094497,
          },
        ],
        position_type: "FLAT",
        position_uuid: "937cc1b3-3ed5-4333-a2b5-2e5381965c01",
        return_at_close: 0.9951014971086201,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 76.00300000000566,
        close_ms: 1718391115320,
        current_return: 0.9944568109604842,
        initial_entry_price: 166.489,
        is_closed_position: true,
        miner_hotkey: "5Exax1W9RiNbARDejrthf4SK1FQ2u9DPUhCq9jm58gUysTy4",
        net_leverage: 0.0,
        open_ms: 1715095984768,
        orders: [
          {
            leverage: -0.15,
            order_type: "SHORT",
            order_uuid: "1ac77afe-3686-4fe2-a0fd-620797356cb0",
            price: 166.489,
            price_sources: [],
            processed_ms: 1715095984581,
          },
          {
            leverage: -1.0,
            order_type: "SHORT",
            order_uuid: "1c277a79-6b10-4a2b-80dd-c85cb7dd2ca3",
            price: 167.692,
            price_sources: [],
            processed_ms: 1715272102009,
          },
          {
            leverage: 1.14,
            order_type: "LONG",
            order_uuid: "6bf2c34e-df48-499e-9a09-0e45626ae344",
            price: 168.338,
            price_sources: [],
            processed_ms: 1715776442360,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "fa3eed5c-2612-423d-a184-996ba3a84709",
            price: 168.291,
            price_sources: [],
            processed_ms: 1718391115320,
          },
        ],
        position_type: "FLAT",
        position_uuid: "7be46b13-a24f-4bc9-a3e0-1ea45b4175dd",
        return_at_close: 0.9943767571872019,
        trade_pair: ["EURJPY", "EUR/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 3799.69,
        close_ms: 1718391166695,
        current_return: 0.9836470869992026,
        initial_entry_price: 3799.69,
        is_closed_position: true,
        miner_hotkey: "5Exax1W9RiNbARDejrthf4SK1FQ2u9DPUhCq9jm58gUysTy4",
        net_leverage: 0.0,
        open_ms: 1717586411064,
        orders: [
          {
            leverage: 0.15,
            order_type: "LONG",
            order_uuid: "dec512dd-d1f9-444a-9e91-efbd837b93c1",
            price: 3799.69,
            price_sources: [],
            processed_ms: 1717586410821,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "5ada2701-4db7-4985-8763-5e21617d3735",
            price: 3385.45,
            price_sources: [],
            processed_ms: 1718391166695,
          },
        ],
        position_type: "FLAT",
        position_uuid: "0c6640f3-40ac-4e07-a94b-beee40179ba3",
        return_at_close: 0.9834995399361527,
        trade_pair: ["ETHUSD", "ETH/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 3494.914285714286,
        close_ms: 1719085537772,
        current_return: 1.0010394331819494,
        initial_entry_price: 3498.83,
        is_closed_position: true,
        miner_hotkey: "5Exax1W9RiNbARDejrthf4SK1FQ2u9DPUhCq9jm58gUysTy4",
        net_leverage: 0.0,
        open_ms: 1719069226856,
        orders: [
          {
            leverage: -0.1,
            order_type: "SHORT",
            order_uuid: "bc254a14-2d17-409d-8d7f-9eb25283e629",
            price: 3498.83,
            price_sources: [],
            processed_ms: 1719069225565,
          },
          {
            leverage: -0.06,
            order_type: "SHORT",
            order_uuid: "718eb7e3-e2f3-4197-a1cc-c02dc65be0cf",
            price: 3506.4,
            price_sources: [],
            processed_ms: 1719069604048,
          },
          {
            leverage: -0.05,
            order_type: "SHORT",
            order_uuid: "4cc8f651-3d06-44d2-83f2-25730d94fce5",
            price: 3498.23,
            price_sources: [],
            processed_ms: 1719081521955,
          },
          {
            leverage: -0.05,
            order_type: "SHORT",
            order_uuid: "373b84db-5888-4927-8470-f494ea674533",
            price: 3492.08,
            price_sources: [],
            processed_ms: 1719082788166,
          },
          {
            leverage: -0.15,
            order_type: "SHORT",
            order_uuid: "12dd14ac-495f-4c21-82dc-9b72f4536b93",
            price: 3493.8,
            price_sources: [],
            processed_ms: 1719083689477,
          },
          {
            leverage: -0.05,
            order_type: "SHORT",
            order_uuid: "30dde9fb-6834-455e-97e6-7d77b545c699",
            price: 3492.79,
            price_sources: [],
            processed_ms: 1719084291571,
          },
          {
            leverage: -0.05,
            order_type: "SHORT",
            order_uuid: "2faef388-3f04-4a32-987b-7b0f6ddc46ac",
            price: 3485.2,
            price_sources: [],
            processed_ms: 1719084740574,
          },
          {
            leverage: -0.05,
            order_type: "SHORT",
            order_uuid: "52c3581b-6213-446d-99e2-b6c8ff43d9f9",
            price: 3488.0,
            price_sources: [],
            processed_ms: 1719085424466,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "d60a92e5-0490-45c7-96de-b3499f5e8584",
            price: 3488.42,
            price_sources: [],
            processed_ms: 1719085537772,
          },
        ],
        position_type: "FLAT",
        position_uuid: "f848ad00-f5fa-4b49-8844-89573aaae1a8",
        return_at_close: 1.0004788510993676,
        trade_pair: ["ETHUSD", "ETH/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 64254.65571428571,
        close_ms: 1719216334393,
        current_return: 1.009540743993144,
        initial_entry_price: 64271.56,
        is_closed_position: true,
        miner_hotkey: "5Exax1W9RiNbARDejrthf4SK1FQ2u9DPUhCq9jm58gUysTy4",
        net_leverage: 0.0,
        open_ms: 1719052168849,
        orders: [
          {
            leverage: -0.05,
            order_type: "SHORT",
            order_uuid: "431ac018-11a0-427d-9945-983aaeead267",
            price: 64271.56,
            price_sources: [],
            processed_ms: 1719052166697,
          },
          {
            leverage: -0.1,
            order_type: "SHORT",
            order_uuid: "8f6c500e-5d9b-4c2c-a55d-350bee768b48",
            price: 64248.5,
            price_sources: [],
            processed_ms: 1719052303477,
          },
          {
            leverage: -0.1,
            order_type: "SHORT",
            order_uuid: "33562ad3-0569-4426-928a-526aed79f24e",
            price: 64237.8,
            price_sources: [],
            processed_ms: 1719052430623,
          },
          {
            leverage: -0.05,
            order_type: "SHORT",
            order_uuid: "5e76148d-bc5a-49ed-a483-f650522f197c",
            price: 64255.25,
            price_sources: [],
            processed_ms: 1719052843116,
          },
          {
            leverage: -0.05,
            order_type: "SHORT",
            order_uuid: "63490919-8f5e-4558-ba43-d03673a5c787",
            price: 64283.18,
            price_sources: [],
            processed_ms: 1719065853712,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "30c02b45-8f47-46d1-8b1f-fd444a458496",
            price: 62502.66,
            price_sources: [],
            processed_ms: 1719216334393,
          },
        ],
        position_type: "FLAT",
        position_uuid: "198ac09d-57f1-4eac-863e-ed203f0d451d",
        return_at_close: 1.0091874047327465,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 3494.625,
        close_ms: 1719221878614,
        current_return: 1.0123811568071892,
        initial_entry_price: 3494.1,
        is_closed_position: true,
        miner_hotkey: "5Exax1W9RiNbARDejrthf4SK1FQ2u9DPUhCq9jm58gUysTy4",
        net_leverage: 0.0,
        open_ms: 1719085663514,
        orders: [
          {
            leverage: -0.05,
            order_type: "SHORT",
            order_uuid: "06e11447-2b06-4874-883e-85b9f8205179",
            price: 3494.1,
            price_sources: [],
            processed_ms: 1719085662823,
          },
          {
            leverage: -0.15,
            order_type: "SHORT",
            order_uuid: "f6ec98a3-de28-46af-bb73-9c1847276082",
            price: 3494.8,
            price_sources: [],
            processed_ms: 1719086032452,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "32332844-2ffb-4d5b-b944-05ca84d166e5",
            price: 3278.32,
            price_sources: [],
            processed_ms: 1719221878614,
          },
        ],
        position_type: "FLAT",
        position_uuid: "7f745dfe-a51c-40d1-8ea8-e7c0d47a1732",
        return_at_close: 1.0121786805758277,
        trade_pair: ["ETHUSD", "ETH/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 61087.35,
        close_ms: 1719321753223,
        current_return: 1.0000007800305628,
        initial_entry_price: 61087.35,
        is_closed_position: true,
        miner_hotkey: "5Exax1W9RiNbARDejrthf4SK1FQ2u9DPUhCq9jm58gUysTy4",
        net_leverage: 0.0,
        open_ms: 1719321674905,
        orders: [
          {
            leverage: 0.001,
            order_type: "LONG",
            order_uuid: "56788f01-8857-4781-9fa9-e10b7b5cb523",
            price: 61087.35,
            price_sources: [],
            processed_ms: 1719321674012,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "df9d7aaa-32d1-4d5c-b00b-588aba86f638",
            price: 61135.0,
            price_sources: [],
            processed_ms: 1719321753223,
          },
        ],
        position_type: "FLAT",
        position_uuid: "56788f01-8857-4781-9fa9-e10b7b5cb523",
        return_at_close: 0.9999997800297827,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 60884.26,
        close_ms: 1719733149636,
        current_return: 1.0195295138677878,
        initial_entry_price: 60884.26,
        is_closed_position: true,
        miner_hotkey: "5Exax1W9RiNbARDejrthf4SK1FQ2u9DPUhCq9jm58gUysTy4",
        net_leverage: 0.0,
        open_ms: 1719679416833,
        orders: [
          {
            leverage: 2.0,
            order_type: "LONG",
            order_uuid: "88b44560-f83b-4541-ba05-869279385942",
            price: 60884.26,
            price_sources: [],
            processed_ms: 1719679416139,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "7934ca40-2213-469e-bb7a-eeada9c842d5",
            price: 61478.78,
            price_sources: [],
            processed_ms: 1719733149636,
          },
        ],
        position_type: "FLAT",
        position_uuid: "f4649848-9ebb-4fab-889c-d8e0024b0f7c",
        return_at_close: 1.0174904548400523,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 3307.802352941176,
        close_ms: 0,
        current_return: 1.0087600735915068,
        initial_entry_price: 3315.6,
        is_closed_position: false,
        miner_hotkey: "5Exax1W9RiNbARDejrthf4SK1FQ2u9DPUhCq9jm58gUysTy4",
        net_leverage: -0.16999999999999998,
        open_ms: 1719228241618,
        orders: [
          {
            leverage: -0.2,
            order_type: "SHORT",
            order_uuid: "6aa42af3-7785-4277-aaec-cd3efb14624d",
            price: 3315.6,
            price_sources: [],
            processed_ms: 1719228241599,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "ca569537-580c-4122-bb68-08551692ae75",
            price: 3362.87,
            price_sources: [],
            processed_ms: 1719301110760,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "0bb88b84-d0f4-44ff-aa13-ab823c080b94",
            price: 3357.23,
            price_sources: [],
            processed_ms: 1719302313779,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "3f997e4c-dad8-462d-9df7-794a415509f8",
            price: 3359.26,
            price_sources: [],
            processed_ms: 1719302569986,
          },
        ],
        position_type: "SHORT",
        position_uuid: "94df5ff9-e109-44dd-bd6e-6b54461278d3",
        return_at_close: 1.0086440661830438,
        trade_pair: ["ETHUSD", "ETH/USD", 0.003, 0.001, 20],
      },
    ],
    thirty_day_returns: 1.0398415525075377,
  },
  "5EySUmguNd7eoWoTABxdLDnoCsGHekAPVfr7s43ooPMQr8nJ": {
    all_time_returns: 1.1967623965129761,
    n_positions: 5,
    percentage_profitable: 1.0,
    positions: [
      {
        average_entry_price: 1.24848,
        close_ms: 1715252088578,
        current_return: 1.0000520633089838,
        initial_entry_price: 1.24848,
        is_closed_position: true,
        miner_hotkey: "5EySUmguNd7eoWoTABxdLDnoCsGHekAPVfr7s43ooPMQr8nJ",
        net_leverage: 0.0,
        open_ms: 1715248396784,
        orders: [
          {
            leverage: 0.1,
            order_type: "LONG",
            order_uuid: "bd3ef549-edbb-4f99-b387-c255802e3519",
            price: 1.24848,
            price_sources: [],
            processed_ms: 1715248396552,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "2af58028-0a5a-4cea-ba1f-c1390ce49300",
            price: 1.24913,
            price_sources: [],
            processed_ms: 1715252088578,
          },
        ],
        position_type: "FLAT",
        position_uuid: "7a5741d8-ddaa-418c-aa57-0146b6fd3737",
        return_at_close: 1.0000450629445405,
        trade_pair: ["GBPUSD", "GBP/USD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 155.929,
        close_ms: 1715700105880,
        current_return: 1.0002988539655868,
        initial_entry_price: 155.929,
        is_closed_position: true,
        miner_hotkey: "5EySUmguNd7eoWoTABxdLDnoCsGHekAPVfr7s43ooPMQr8nJ",
        net_leverage: 0.0,
        open_ms: 1715248458215,
        orders: [
          {
            leverage: 0.1,
            order_type: "LONG",
            order_uuid: "97ab3013-1da7-48fd-a9fe-008dcb1cf202",
            price: 155.929,
            price_sources: [],
            processed_ms: 1715248457619,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "bcd2baf6-1525-407f-89b6-168491feae7d",
            price: 156.395,
            price_sources: [],
            processed_ms: 1715700105880,
          },
        ],
        position_type: "FLAT",
        position_uuid: "3b22f0a4-e92c-4d3d-8693-148299a64cac",
        return_at_close: 1.000291851873609,
        trade_pair: ["USDJPY", "USD/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.66347,
        close_ms: 1715776360380,
        current_return: 1.0526022276817337,
        initial_entry_price: 0.66347,
        is_closed_position: true,
        miner_hotkey: "5EySUmguNd7eoWoTABxdLDnoCsGHekAPVfr7s43ooPMQr8nJ",
        net_leverage: 0.0,
        open_ms: 1715775945324,
        orders: [
          {
            leverage: 10.0,
            order_type: "LONG",
            order_uuid: "e2f94f0f-ef97-4bcd-afeb-ea01c66ca657",
            price: 0.66347,
            price_sources: [],
            processed_ms: 1715775945045,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "681f6ee9-01aa-44f6-b332-686c2d9ebd98",
            price: 0.66696,
            price_sources: [],
            processed_ms: 1715776360380,
          },
        ],
        position_type: "FLAT",
        position_uuid: "3440f50e-dd83-4b03-b627-e187c5cb472b",
        return_at_close: 1.0518654061223565,
        trade_pair: ["AUDUSD", "AUD/USD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 66823.0,
        close_ms: 1716109949911,
        current_return: 1.0003486748574593,
        initial_entry_price: 66823.0,
        is_closed_position: true,
        miner_hotkey: "5EySUmguNd7eoWoTABxdLDnoCsGHekAPVfr7s43ooPMQr8nJ",
        net_leverage: 0.0,
        open_ms: 1715966014571,
        orders: [
          {
            leverage: 0.07,
            order_type: "LONG",
            order_uuid: "c6a279dc-0975-49d0-9676-330267c47405",
            price: 66823.0,
            price_sources: [],
            processed_ms: 1715966012768,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "2914d87b-8813-469b-84a8-bba884ca08af",
            price: 67155.85,
            price_sources: [],
            processed_ms: 1716109949911,
          },
        ],
        position_type: "FLAT",
        position_uuid: "46828f8f-4d79-4682-a535-2938c2f15a92",
        return_at_close: 1.0002786504502192,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 65554.58586728753,
        close_ms: 1718546193745,
        current_return: 1.1469042618790302,
        initial_entry_price: 68339.34,
        is_closed_position: true,
        miner_hotkey: "5EySUmguNd7eoWoTABxdLDnoCsGHekAPVfr7s43ooPMQr8nJ",
        net_leverage: 0.0,
        open_ms: 1717100649459,
        orders: [
          {
            leverage: 0.09,
            order_type: "LONG",
            order_uuid: "6b188e40-ac4e-47b6-a59e-a855e1b3463e",
            price: 68339.34,
            price_sources: [],
            processed_ms: 1717100649220,
          },
          {
            leverage: 0.2,
            order_type: "LONG",
            order_uuid: "86292c8a-f66c-4d5c-9758-88a4dccea273",
            price: 70857.0,
            price_sources: [],
            processed_ms: 1717586306258,
          },
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "80050e7d-b820-4561-94a7-ee66282c6184",
            price: 67752.54,
            price_sources: [],
            processed_ms: 1718276024559,
          },
          {
            leverage: 5.0,
            order_type: "LONG",
            order_uuid: "1cbe3c31-f021-4fb7-b059-f8ba90406c22",
            price: 65276.05,
            price_sources: [],
            processed_ms: 1718392896177,
          },
          {
            leverage: 3.0,
            order_type: "LONG",
            order_uuid: "95e5bbd3-cd8b-4e51-a6f7-e1e95076170e",
            price: 65361.98,
            price_sources: [],
            processed_ms: 1718393302050,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "e693ee03-0689-49ce-b166-48e67204a008",
            price: 66723.31,
            price_sources: [],
            processed_ms: 1718546193745,
          },
        ],
        position_type: "FLAT",
        position_uuid: "e4b18860-8da0-44cc-bda6-e44d0f54cf10",
        return_at_close: 1.1370523542694893,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 197.87468525896412,
        close_ms: 0,
        current_return: 0.9874273556074886,
        initial_entry_price: 194.695,
        is_closed_position: false,
        miner_hotkey: "5EySUmguNd7eoWoTABxdLDnoCsGHekAPVfr7s43ooPMQr8nJ",
        net_leverage: -0.251,
        open_ms: 1715248427366,
        orders: [
          {
            leverage: -0.1,
            order_type: "SHORT",
            order_uuid: "a35b6af2-c48b-4da5-9111-cd528988839e",
            price: 194.695,
            price_sources: [],
            processed_ms: 1715248427174,
          },
          {
            leverage: 0.099,
            order_type: "LONG",
            order_uuid: "7938103d-c11c-4e3e-a1e8-7e508047a8ea",
            price: 194.396,
            price_sources: [],
            processed_ms: 1715257423465,
          },
          {
            leverage: -0.25,
            order_type: "SHORT",
            order_uuid: "94f5b30a-9711-40f3-8278-89c45c772871",
            price: 197.769,
            price_sources: [],
            processed_ms: 1716159782354,
          },
        ],
        position_type: "SHORT",
        position_uuid: "6b6d1709-1933-4a1a-8177-8edda7193953",
        return_at_close: 0.9874118381865953,
        trade_pair: ["GBPJPY", "GBP/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 3696.3192307692307,
        close_ms: 0,
        current_return: 1.005003302600798,
        initial_entry_price: 2906.8,
        is_closed_position: false,
        miner_hotkey: "5EySUmguNd7eoWoTABxdLDnoCsGHekAPVfr7s43ooPMQr8nJ",
        net_leverage: -0.026000000000000002,
        open_ms: 1715369167779,
        orders: [
          {
            leverage: -0.001,
            order_type: "SHORT",
            order_uuid: "d22fe365-9780-4f61-9c43-6ef57037a9c4",
            price: 2906.8,
            price_sources: [],
            processed_ms: 1715369167591,
          },
          {
            leverage: -0.025,
            order_type: "SHORT",
            order_uuid: "48ea6432-0938-48cf-9356-6c9933f6a001",
            price: 3727.9,
            price_sources: [],
            processed_ms: 1716639079648,
          },
        ],
        position_type: "SHORT",
        position_uuid: "6cbdd2da-0b69-404e-9ed4-6f404cba0014",
        return_at_close: 1.0049902375578643,
        trade_pair: ["ETHUSD", "ETH/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 169.271,
        close_ms: 0,
        current_return: 0.9932008436176308,
        initial_entry_price: 169.271,
        is_closed_position: false,
        miner_hotkey: "5EySUmguNd7eoWoTABxdLDnoCsGHekAPVfr7s43ooPMQr8nJ",
        net_leverage: -0.2,
        open_ms: 1716159810748,
        orders: [
          {
            leverage: -0.2,
            order_type: "SHORT",
            order_uuid: "7764c1b6-20c0-4eda-848b-37708573e57a",
            price: 169.271,
            price_sources: [],
            processed_ms: 1716159810742,
          },
        ],
        position_type: "SHORT",
        position_uuid: "60807fc3-a2d3-4fe0-908b-8ea09e55abb0",
        return_at_close: 0.9931938912117255,
        trade_pair: ["EURJPY", "EUR/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.5259100000000012,
        close_ms: 0,
        current_return: 1.0022341623017004,
        initial_entry_price: 0.66629,
        is_closed_position: false,
        miner_hotkey: "5EySUmguNd7eoWoTABxdLDnoCsGHekAPVfr7s43ooPMQr8nJ",
        net_leverage: 0.010000000000000009,
        open_ms: 1715777939203,
        orders: [
          {
            leverage: 0.35,
            order_type: "LONG",
            order_uuid: "80755324-ac58-4a11-9073-7c0f6134bc6d",
            price: 0.66629,
            price_sources: [],
            processed_ms: 1715777937536,
          },
          {
            leverage: 0.2,
            order_type: "LONG",
            order_uuid: "19c14706-0def-4049-9392-7b9e1e74e7c0",
            price: 0.66621,
            price_sources: [],
            processed_ms: 1715778571471,
          },
          {
            leverage: -0.54,
            order_type: "SHORT",
            order_uuid: "06c50367-85df-4e8a-936b-68cf819c6f33",
            price: 0.66886,
            price_sources: [],
            processed_ms: 1715965915256,
          },
        ],
        position_type: "LONG",
        position_uuid: "d92127ea-1e6b-40f8-b970-457ef6e9d1ce",
        return_at_close: 1.0021959270684087,
        trade_pair: ["AUDUSD", "AUD/USD", 7e-5, 0.001, 500],
      },
    ],
    thirty_day_returns: 1.0,
  },
  "5F1sxW5apTPEYfDJUoHTRG4kGaUmkb3YVi5hwt5A9Fu8Gi6a": {
    all_time_returns: 1.2157715931739863,
    n_positions: 30,
    percentage_profitable: 0.9333333333333333,
    positions: [
      {
        average_entry_price: 0.8135885597186274,
        close_ms: 1713190629968,
        current_return: 1.000414051222304,
        initial_entry_price: 0.8150717448,
        is_closed_position: true,
        miner_hotkey: "5F1sxW5apTPEYfDJUoHTRG4kGaUmkb3YVi5hwt5A9Fu8Gi6a",
        net_leverage: 0.0,
        open_ms: 1713187006861,
        orders: [
          {
            leverage: 0.001,
            order_type: "LONG",
            order_uuid: "768cd29c-e7f4-412d-a924-a04c5ce5ca36",
            price: 0.8150717448,
            price_sources: [],
            processed_ms: 1713187006861,
          },
          {
            leverage: 0.001,
            order_type: "LONG",
            order_uuid: "5d5b8401-62ed-4ad3-b88d-6ae1c333c9d1",
            price: 0.815,
            price_sources: [],
            processed_ms: 1713187153413,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "ee077be5-ca84-46ed-832a-bc7dfdc619a6",
            price: 0.8150515953,
            price_sources: [],
            processed_ms: 1713187478574,
          },
          {
            leverage: 0.1,
            order_type: "LONG",
            order_uuid: "27d0b57c-f932-486c-9d49-b12b4c99120c",
            price: 0.81439,
            price_sources: [],
            processed_ms: 1713188059395,
          },
          {
            leverage: 0.5,
            order_type: "LONG",
            order_uuid: "7d7e2b18-f885-47c5-88d5-4ca728dbe281",
            price: 0.8133932217,
            price_sources: [],
            processed_ms: 1713189888084,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "17dcdfcd-07fb-4459-b516-89957affb96d",
            price: 0.81414,
            price_sources: [],
            processed_ms: 1713190629968,
          },
        ],
        position_type: "FLAT",
        position_uuid: "1bbe3493-a168-493b-870c-3ffcdaab78c0",
        return_at_close: 1.0003711934843498,
        trade_pair: ["NZDCAD", "NZD/CAD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 1.37613,
        close_ms: 1713203047991,
        current_return: 1.0000515939627797,
        initial_entry_price: 1.37613,
        is_closed_position: true,
        miner_hotkey: "5F1sxW5apTPEYfDJUoHTRG4kGaUmkb3YVi5hwt5A9Fu8Gi6a",
        net_leverage: 0.0,
        open_ms: 1713192153540,
        orders: [
          {
            leverage: 0.05,
            order_type: "LONG",
            order_uuid: "d7f0fe2d-0541-4c95-bf06-bb5b995d7c2f",
            price: 1.37613,
            price_sources: [],
            processed_ms: 1713192153539,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "0c2af5bf-b4ad-45b7-bb86-7280a80178e5",
            price: 1.37755,
            price_sources: [],
            processed_ms: 1713203047991,
          },
        ],
        position_type: "FLAT",
        position_uuid: "407a4d43-07d5-458a-a463-e20fe507c9c0",
        return_at_close: 1.0000480937822007,
        trade_pair: ["USDCAD", "USD/CAD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 1.4633599999999998,
        close_ms: 1713203063185,
        current_return: 1.0000222091624753,
        initial_entry_price: 1.46336,
        is_closed_position: true,
        miner_hotkey: "5F1sxW5apTPEYfDJUoHTRG4kGaUmkb3YVi5hwt5A9Fu8Gi6a",
        net_leverage: 0.0,
        open_ms: 1713192204433,
        orders: [
          {
            leverage: 0.05,
            order_type: "LONG",
            order_uuid: "7aceb5ac-2679-40d1-b41f-747c7262ee0f",
            price: 1.46336,
            price_sources: [],
            processed_ms: 1713192204432,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "540c75fe-124a-408b-b744-336efbf96dd1",
            price: 1.46401,
            price_sources: [],
            processed_ms: 1713203063185,
          },
        ],
        position_type: "FLAT",
        position_uuid: "7f3bab02-8441-4a01-ba63-70d89feedae8",
        return_at_close: 1.0000187090847432,
        trade_pair: ["EURCAD", "EUR/CAD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 1.06253,
        close_ms: 1713203079902,
        current_return: 1.0000094114989695,
        initial_entry_price: 1.06253,
        is_closed_position: true,
        miner_hotkey: "5F1sxW5apTPEYfDJUoHTRG4kGaUmkb3YVi5hwt5A9Fu8Gi6a",
        net_leverage: 0.0,
        open_ms: 1713191161332,
        orders: [
          {
            leverage: 0.05,
            order_type: "LONG",
            order_uuid: "67369468-1890-421c-91f2-ef2d555e01cb",
            price: 1.06253,
            price_sources: [],
            processed_ms: 1713191161331,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "c03c83a3-a012-45d4-a765-48aa6d45e09c",
            price: 1.06273,
            price_sources: [],
            processed_ms: 1713203079902,
          },
        ],
        position_type: "FLAT",
        position_uuid: "93905ed4-c18a-4509-b268-c05bd11da525",
        return_at_close: 1.0000059114660291,
        trade_pair: ["EURUSD", "EUR/USD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.64301,
        close_ms: 1713228942154,
        current_return: 1.0000079314474113,
        initial_entry_price: 0.64301,
        is_closed_position: true,
        miner_hotkey: "5F1sxW5apTPEYfDJUoHTRG4kGaUmkb3YVi5hwt5A9Fu8Gi6a",
        net_leverage: 0.0,
        open_ms: 1713227987889,
        orders: [
          {
            leverage: -0.01,
            order_type: "SHORT",
            order_uuid: "2a658fc4-a734-4464-9de8-223f03482397",
            price: 0.64301,
            price_sources: [],
            processed_ms: 1713227987887,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "a67781ed-2140-48f4-887a-b3dd27bfc4bf",
            price: 0.6425,
            price_sources: [],
            processed_ms: 1713228942154,
          },
        ],
        position_type: "FLAT",
        position_uuid: "5a127dc5-9745-4d04-b1fc-4d605c43779c",
        return_at_close: 1.0000072314418593,
        trade_pair: ["AUDUSD", "AUD/USD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 99.223,
        close_ms: 1713228959069,
        current_return: 1.0000057446358204,
        initial_entry_price: 99.223,
        is_closed_position: true,
        miner_hotkey: "5F1sxW5apTPEYfDJUoHTRG4kGaUmkb3YVi5hwt5A9Fu8Gi6a",
        net_leverage: 0.0,
        open_ms: 1713228004944,
        orders: [
          {
            leverage: -0.01,
            order_type: "SHORT",
            order_uuid: "d4f7e2fc-ec58-41a1-9e69-4bc2d3124cbb",
            price: 99.223,
            price_sources: [],
            processed_ms: 1713228004943,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "521f8290-95f8-4255-a892-939b150b67c3",
            price: 99.166,
            price_sources: [],
            processed_ms: 1713228959069,
          },
        ],
        position_type: "FLAT",
        position_uuid: "6ebb27f5-3ec2-476c-87ad-e91c9c72c2a1",
        return_at_close: 1.0000050446317992,
        trade_pair: ["AUDJPY", "AUD/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 154.329,
        close_ms: 1713234019139,
        current_return: 1.0000323983178794,
        initial_entry_price: 154.329,
        is_closed_position: true,
        miner_hotkey: "5F1sxW5apTPEYfDJUoHTRG4kGaUmkb3YVi5hwt5A9Fu8Gi6a",
        net_leverage: 0.0,
        open_ms: 1713228998371,
        orders: [
          {
            leverage: 0.1,
            order_type: "LONG",
            order_uuid: "a3292fbe-468b-4b4a-ac47-a2780e75e273",
            price: 154.329,
            price_sources: [],
            processed_ms: 1713228998369,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "506ba667-6156-4255-a3df-d8c4591c18d9",
            price: 154.379,
            price_sources: [],
            processed_ms: 1713234019139,
          },
        ],
        position_type: "FLAT",
        position_uuid: "eacefd8e-f931-4960-9c53-78417093dec0",
        return_at_close: 1.0000253980910911,
        trade_pair: ["USDJPY", "USD/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 1.06099,
        close_ms: 1713236975029,
        current_return: 1.0000021677866897,
        initial_entry_price: 1.06099,
        is_closed_position: true,
        miner_hotkey: "5F1sxW5apTPEYfDJUoHTRG4kGaUmkb3YVi5hwt5A9Fu8Gi6a",
        net_leverage: 0.0,
        open_ms: 1713236398769,
        orders: [
          {
            leverage: -0.01,
            order_type: "SHORT",
            order_uuid: "2c9ab81b-e423-4642-b151-c8fd20efc279",
            price: 1.06099,
            price_sources: [],
            processed_ms: 1713236398767,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "9349ce4c-facc-4e4b-b3a4-53a31886812e",
            price: 1.06076,
            price_sources: [],
            processed_ms: 1713236975029,
          },
        ],
        position_type: "FLAT",
        position_uuid: "52f1e525-3e4c-486e-98c3-14bc197676d7",
        return_at_close: 1.0000014677851723,
        trade_pair: ["EURUSD", "EUR/USD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.6369900000000007,
        close_ms: 1713248541365,
        current_return: 1.0000067673979822,
        initial_entry_price: 0.64131,
        is_closed_position: true,
        miner_hotkey: "5F1sxW5apTPEYfDJUoHTRG4kGaUmkb3YVi5hwt5A9Fu8Gi6a",
        net_leverage: 0.0,
        open_ms: 1713237706524,
        orders: [
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "03695687-ee92-44d6-bd07-51d71c9604b2",
            price: 0.64131,
            price_sources: [],
            processed_ms: 1713237706522,
          },
          {
            leverage: -0.009,
            order_type: "SHORT",
            order_uuid: "415758fd-d829-4995-bfa7-da0140105be0",
            price: 0.64179,
            price_sources: [],
            processed_ms: 1713240012125,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "1d8a3d0c-cef7-4341-b2c9-2738ed8925b2",
            price: 0.64133,
            price_sources: [],
            processed_ms: 1713248541365,
          },
        ],
        position_type: "FLAT",
        position_uuid: "9b0675ab-3f8e-4c78-8331-b91aeef1fadb",
        return_at_close: 1.0000060673932452,
        trade_pair: ["AUDUSD", "AUD/USD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.9129299999999999,
        close_ms: 1713248768365,
        current_return: 0.9999874015402986,
        initial_entry_price: 0.91281,
        is_closed_position: true,
        miner_hotkey: "5F1sxW5apTPEYfDJUoHTRG4kGaUmkb3YVi5hwt5A9Fu8Gi6a",
        net_leverage: 0.0,
        open_ms: 1713229033235,
        orders: [
          {
            leverage: -0.01,
            order_type: "SHORT",
            order_uuid: "07934c79-47ac-495b-80be-ea0c0310a143",
            price: 0.91281,
            price_sources: [],
            processed_ms: 1713229033233,
          },
          {
            leverage: -0.04,
            order_type: "SHORT",
            order_uuid: "bdeaacb8-bff5-457c-812a-09f1aac8f3e3",
            price: 0.91296,
            price_sources: [],
            processed_ms: 1713234052856,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "3903e982-6a6a-4d9a-b8e2-57476e4d81ad",
            price: 0.91316,
            price_sources: [],
            processed_ms: 1713248768365,
          },
        ],
        position_type: "FLAT",
        position_uuid: "364ef567-ae24-4a90-8e4c-5334113fdbe7",
        return_at_close: 0.9999839015843932,
        trade_pair: ["USDCHF", "USD/CHF", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 1.24244,
        close_ms: 1713248788100,
        current_return: 1.0000049901806123,
        initial_entry_price: 1.24244,
        is_closed_position: true,
        miner_hotkey: "5F1sxW5apTPEYfDJUoHTRG4kGaUmkb3YVi5hwt5A9Fu8Gi6a",
        net_leverage: 0.0,
        open_ms: 1713237641082,
        orders: [
          {
            leverage: -0.01,
            order_type: "SHORT",
            order_uuid: "92e667b6-2949-491c-9bae-0013d52a3124",
            price: 1.24244,
            price_sources: [],
            processed_ms: 1713237641080,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "d57831c0-b3f1-4572-bb0f-355f215e77e8",
            price: 1.24182,
            price_sources: [],
            processed_ms: 1713248788100,
          },
        ],
        position_type: "FLAT",
        position_uuid: "8ded0ebe-e57b-4e11-a0d9-3c8939a27070",
        return_at_close: 1.0000042901771191,
        trade_pair: ["GBPUSD", "GBP/USD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 1.46477,
        close_ms: 1713248827800,
        current_return: 1.0000034135051918,
        initial_entry_price: 1.46477,
        is_closed_position: true,
        miner_hotkey: "5F1sxW5apTPEYfDJUoHTRG4kGaUmkb3YVi5hwt5A9Fu8Gi6a",
        net_leverage: 0.0,
        open_ms: 1713236375901,
        orders: [
          {
            leverage: -0.01,
            order_type: "SHORT",
            order_uuid: "d9d4cddc-e5ef-4369-b729-65aabbcb7b86",
            price: 1.46477,
            price_sources: [],
            processed_ms: 1713236375899,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "f1f3e0a6-ad9b-4a2a-895f-234901e97c4d",
            price: 1.46427,
            price_sources: [],
            processed_ms: 1713248827800,
          },
        ],
        position_type: "FLAT",
        position_uuid: "c1f863ee-71a7-4940-9a16-247efc6745c8",
        return_at_close: 1.0000027135028025,
        trade_pair: ["EURCAD", "EUR/CAD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.8131269775000001,
        close_ms: 1713351167074,
        current_return: 1.000623907588012,
        initial_entry_price: 0.81456791,
        is_closed_position: true,
        miner_hotkey: "5F1sxW5apTPEYfDJUoHTRG4kGaUmkb3YVi5hwt5A9Fu8Gi6a",
        net_leverage: 0.0,
        open_ms: 1713191937383,
        orders: [
          {
            leverage: 0.05,
            order_type: "LONG",
            order_uuid: "ce5f2124-e288-4d2f-b775-9f775bfedf65",
            price: 0.81456791,
            price_sources: [],
            processed_ms: 1713191937382,
          },
          {
            leverage: 0.05,
            order_type: "LONG",
            order_uuid: "b2dc3a12-e1f8-4d66-9eb5-b37d38c096a7",
            price: 0.81408,
            price_sources: [],
            processed_ms: 1713203232776,
          },
          {
            leverage: 0.1,
            order_type: "LONG",
            order_uuid: "48c73206-1cd4-4ada-9323-9ebe02190ac6",
            price: 0.81193,
            price_sources: [],
            processed_ms: 1713234110306,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "702c7cc8-0880-4e12-ba85-85c31e396a96",
            price: 0.815668053,
            price_sources: [],
            processed_ms: 1713351167074,
          },
        ],
        position_type: "FLAT",
        position_uuid: "2e239d6b-9c66-45c2-bb24-5800671147bc",
        return_at_close: 1.0006098988533056,
        trade_pair: ["NZDCAD", "NZD/CAD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.8860035080300002,
        close_ms: 1713438250517,
        current_return: 1.0000582349922766,
        initial_entry_price: 0.88691,
        is_closed_position: true,
        miner_hotkey: "5F1sxW5apTPEYfDJUoHTRG4kGaUmkb3YVi5hwt5A9Fu8Gi6a",
        net_leverage: 0.0,
        open_ms: 1713227971767,
        orders: [
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "8c3ee353-a97d-4786-bfcb-f63c46aa0355",
            price: 0.88691,
            price_sources: [],
            processed_ms: 1713227971766,
          },
          {
            leverage: 0.09,
            order_type: "LONG",
            order_uuid: "ba810ca6-15b3-427f-92bb-a5b2ee7adf75",
            price: 0.8859027867,
            price_sources: [],
            processed_ms: 1713229720984,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "7b4170f7-f11b-44ea-9ab0-94d4a587aba7",
            price: 0.88652,
            price_sources: [],
            processed_ms: 1713438250517,
          },
        ],
        position_type: "FLAT",
        position_uuid: "96ae1517-53d0-4edc-ae2b-24b75dc6c55f",
        return_at_close: 1.0000512345846317,
        trade_pair: ["AUDCAD", "AUD/CAD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 169.80247400500002,
        close_ms: 1713445297887,
        current_return: 1.000072867615553,
        initial_entry_price: 168.97,
        is_closed_position: true,
        miner_hotkey: "5F1sxW5apTPEYfDJUoHTRG4kGaUmkb3YVi5hwt5A9Fu8Gi6a",
        net_leverage: 0.0,
        open_ms: 1713237657200,
        orders: [
          {
            leverage: -0.01,
            order_type: "SHORT",
            order_uuid: "8ea5fd77-bada-418b-96d2-43b5ef34140e",
            price: 168.97,
            price_sources: [],
            processed_ms: 1713237657198,
          },
          {
            leverage: -0.04,
            order_type: "SHORT",
            order_uuid: "7b9182f5-231b-40c8-b70d-1f457302bb6d",
            price: 169.57,
            price_sources: [],
            processed_ms: 1713418379112,
          },
          {
            leverage: -0.12,
            order_type: "SHORT",
            order_uuid: "8b9e1e37-ac09-4073-864f-61a4f153f191",
            price: 169.9121704,
            price_sources: [],
            processed_ms: 1713435161568,
          },
          {
            leverage: -0.03,
            order_type: "SHORT",
            order_uuid: "1d16f88c-91fe-41a8-a06c-eb92045d1d3e",
            price: 169.9511451,
            price_sources: [],
            processed_ms: 1713438100041,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "70bdad3d-3872-4c65-9668-d8f08575b9fd",
            price: 169.7409118,
            price_sources: [],
            processed_ms: 1713445297887,
          },
        ],
        position_type: "FLAT",
        position_uuid: "0e03a8ec-f4b9-4f56-9728-dc4d18b342a2",
        return_at_close: 1.0000588665954064,
        trade_pair: ["CHFJPY", "CHF/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.658255000000001,
        close_ms: 1713445615162,
        current_return: 1.0005003009328919,
        initial_entry_price: 0.6646,
        is_closed_position: true,
        miner_hotkey: "5F1sxW5apTPEYfDJUoHTRG4kGaUmkb3YVi5hwt5A9Fu8Gi6a",
        net_leverage: 0.0,
        open_ms: 1713191453484,
        orders: [
          {
            leverage: 0.05,
            order_type: "LONG",
            order_uuid: "57f0be34-e86f-4692-b032-dfe3226e0dbd",
            price: 0.6646,
            price_sources: [],
            processed_ms: 1713191453483,
          },
          {
            leverage: 0.05,
            order_type: "LONG",
            order_uuid: "e26ad178-949a-46a1-8d08-f76c75f2b0c6",
            price: 0.66269,
            price_sources: [],
            processed_ms: 1713203151575,
          },
          {
            leverage: 0.1,
            order_type: "LONG",
            order_uuid: "4f55d1ac-cfa6-48c4-a162-58cb4f05a4a6",
            price: 0.66161,
            price_sources: [],
            processed_ms: 1713228039278,
          },
          {
            leverage: 0.1,
            order_type: "LONG",
            order_uuid: "fcdaeadd-540b-4c13-ba86-6d8743b54f65",
            price: 0.66157,
            price_sources: [],
            processed_ms: 1713246667989,
          },
          {
            leverage: 0.1,
            order_type: "LONG",
            order_uuid: "9755013d-86bc-4aea-9e1e-1abc5165b36e",
            price: 0.66148,
            price_sources: [],
            processed_ms: 1713418317354,
          },
          {
            leverage: 0.2,
            order_type: "LONG",
            order_uuid: "6f144999-75b0-4809-83c5-a7e35dd1474a",
            price: 0.66034,
            price_sources: [],
            processed_ms: 1713436783673,
          },
          {
            leverage: 0.2,
            order_type: "LONG",
            order_uuid: "3eb9ca2e-2e70-45f8-af4f-694dd875715a",
            price: 0.66035,
            price_sources: [],
            processed_ms: 1713437319528,
          },
          {
            leverage: 0.2,
            order_type: "LONG",
            order_uuid: "e925d75f-850d-436c-91c7-654e26524113",
            price: 0.66081,
            price_sources: [],
            processed_ms: 1713444062628,
          },
          {
            leverage: -0.9,
            order_type: "SHORT",
            order_uuid: "227617c8-b527-4cb9-b9b1-f31fe4b8a553",
            price: 0.66145,
            price_sources: [],
            processed_ms: 1713445355965,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "092e4826-ff94-406d-ba89-168963cb0366",
            price: 0.66158,
            price_sources: [],
            processed_ms: 1713445615162,
          },
        ],
        position_type: "FLAT",
        position_uuid: "6c0f1d9c-af2f-4b61-8a04-73ad2ccd74e2",
        return_at_close: 1.0004302659118265,
        trade_pair: ["CADCHF", "CAD/CHF", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.869999999999995,
        close_ms: 1713767011956,
        current_return: 1.0000102615764874,
        initial_entry_price: 0.85367,
        is_closed_position: true,
        miner_hotkey: "5F1sxW5apTPEYfDJUoHTRG4kGaUmkb3YVi5hwt5A9Fu8Gi6a",
        net_leverage: 0.0,
        open_ms: 1713228095702,
        orders: [
          {
            leverage: -0.01,
            order_type: "SHORT",
            order_uuid: "b1675f51-5d65-4947-900d-ef2bf6fb4bd1",
            price: 0.85367,
            price_sources: [],
            processed_ms: 1713228095700,
          },
          {
            leverage: -0.04,
            order_type: "SHORT",
            order_uuid: "2e97b69b-4fd3-438f-81df-37c18d63e756",
            price: 0.85387,
            price_sources: [],
            processed_ms: 1713234070538,
          },
          {
            leverage: 0.049,
            order_type: "LONG",
            order_uuid: "bfcd5dae-31a2-4422-86da-3fbd9ba8a75b",
            price: 0.8535,
            price_sources: [],
            processed_ms: 1713257379216,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "1b4aa98b-b997-49e7-95f8-05f97d0f510d",
            price: 0.86124,
            price_sources: [],
            processed_ms: 1713767011956,
          },
        ],
        position_type: "FLAT",
        position_uuid: "5342ac8c-bb52-448c-bfe5-7fe4533f0122",
        return_at_close: 1.0000067615405719,
        trade_pair: ["EURGBP", "EUR/GBP", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 138.3450000000045,
        close_ms: 1714131293419,
        current_return: 1.0003006876248335,
        initial_entry_price: 192.256,
        is_closed_position: true,
        miner_hotkey: "5F1sxW5apTPEYfDJUoHTRG4kGaUmkb3YVi5hwt5A9Fu8Gi6a",
        net_leverage: 0.0,
        open_ms: 1713191345209,
        orders: [
          {
            leverage: 0.05,
            order_type: "LONG",
            order_uuid: "5aff86b6-0ca4-4fd9-a705-0bc8d24952c0",
            price: 192.256,
            price_sources: [],
            processed_ms: 1713191345208,
          },
          {
            leverage: 0.05,
            order_type: "LONG",
            order_uuid: "9acfd6ac-6a24-4f6a-8325-df5e868c2150",
            price: 191.928,
            price_sources: [],
            processed_ms: 1713203200207,
          },
          {
            leverage: -0.099,
            order_type: "SHORT",
            order_uuid: "4664fa5c-505e-4eaa-bd8a-bc6163b63158",
            price: 192.145,
            price_sources: [],
            processed_ms: 1713257356419,
          },
          {
            leverage: 0.1,
            order_type: "LONG",
            order_uuid: "eaaa00e7-c8e2-4eda-b542-89c5882cdf64",
            price: 194.603,
            price_sources: [],
            processed_ms: 1714092924941,
          },
          {
            leverage: -0.1,
            order_type: "SHORT",
            order_uuid: "11f7d851-9726-405d-854e-d23f6fd04db4",
            price: 195.088,
            price_sources: [],
            processed_ms: 1714102329297,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "5d061601-8016-4935-a3cf-c55fa938d690",
            price: 196.154,
            price_sources: [],
            processed_ms: 1714131293419,
          },
        ],
        position_type: "FLAT",
        position_uuid: "81b79e20-6601-4b17-ac53-a5aa2036005d",
        return_at_close: 1.0002866834152069,
        trade_pair: ["GBPJPY", "GBP/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.59512,
        close_ms: 1714134271524,
        current_return: 1.0003024600080657,
        initial_entry_price: 0.59512,
        is_closed_position: true,
        miner_hotkey: "5F1sxW5apTPEYfDJUoHTRG4kGaUmkb3YVi5hwt5A9Fu8Gi6a",
        net_leverage: 0.0,
        open_ms: 1714127455689,
        orders: [
          {
            leverage: -0.25,
            order_type: "SHORT",
            order_uuid: "24b7a208-ad39-464f-a8a0-5148a9159e42",
            price: 0.59512,
            price_sources: [],
            processed_ms: 1714127451928,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "ca647a08-f534-4128-80c2-9c7de6f9dd83",
            price: 0.5944,
            price_sources: [],
            processed_ms: 1714134271524,
          },
        ],
        position_type: "FLAT",
        position_uuid: "a51ef1ce-fe69-4417-9d34-f640b01a9942",
        return_at_close: 1.0002849547150154,
        trade_pair: ["NZDUSD", "NZD/USD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.840669999999987,
        close_ms: 1714134340482,
        current_return: 1.0001415046822157,
        initial_entry_price: 0.97283,
        is_closed_position: true,
        miner_hotkey: "5F1sxW5apTPEYfDJUoHTRG4kGaUmkb3YVi5hwt5A9Fu8Gi6a",
        net_leverage: 0.0,
        open_ms: 1713188255418,
        orders: [
          {
            leverage: 0.1,
            order_type: "LONG",
            order_uuid: "772af0e2-17ef-4436-a924-c2b682945660",
            price: 0.97283,
            price_sources: [],
            processed_ms: 1713188255418,
          },
          {
            leverage: 0.1,
            order_type: "LONG",
            order_uuid: "78d29966-6160-4e3c-b484-13f403a933b8",
            price: 0.9692,
            price_sources: [],
            processed_ms: 1713228021784,
          },
          {
            leverage: -0.199,
            order_type: "SHORT",
            order_uuid: "67636633-6eb3-4782-a04d-b377e422f606",
            price: 0.97167,
            price_sources: [],
            processed_ms: 1713418293756,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "e06c95f1-1c63-4f67-9e62-9ae9561ba9c2",
            price: 0.97833,
            price_sources: [],
            processed_ms: 1714134340482,
          },
        ],
        position_type: "FLAT",
        position_uuid: "851d9e90-a944-4479-b2ce-b4e7a67c8b3e",
        return_at_close: 1.0001275027011503,
        trade_pair: ["EURCHF", "EUR/CHF", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 94.55000000000516,
        close_ms: 1714134358763,
        current_return: 1.00018070044045,
        initial_entry_price: 112.158,
        is_closed_position: true,
        miner_hotkey: "5F1sxW5apTPEYfDJUoHTRG4kGaUmkb3YVi5hwt5A9Fu8Gi6a",
        net_leverage: 0.0,
        open_ms: 1713191308973,
        orders: [
          {
            leverage: 0.05,
            order_type: "LONG",
            order_uuid: "698495e7-0ae0-4e8c-884a-317374969db9",
            price: 112.158,
            price_sources: [],
            processed_ms: 1713191308973,
          },
          {
            leverage: 0.05,
            order_type: "LONG",
            order_uuid: "fda2d53c-5992-4a16-ae7e-005951ab7bba",
            price: 111.94,
            price_sources: [],
            processed_ms: 1713203183673,
          },
          {
            leverage: 0.1,
            order_type: "LONG",
            order_uuid: "c90184c8-f6fc-4aa9-8ba6-24a7da206e36",
            price: 111.876,
            price_sources: [],
            processed_ms: 1713228073097,
          },
          {
            leverage: -0.199,
            order_type: "SHORT",
            order_uuid: "3002e1f0-4762-4abd-b5ca-a1b8b0dd1483",
            price: 112.05,
            price_sources: [],
            processed_ms: 1713262590029,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "7ebc69d5-957f-41fa-94ed-3648c0e5fa31",
            price: 114.817,
            price_sources: [],
            processed_ms: 1714134358763,
          },
        ],
        position_type: "FLAT",
        position_uuid: "f4fec377-44d5-4641-aa55-de3eb99a007a",
        return_at_close: 1.0001666979106438,
        trade_pair: ["CADJPY", "CAD/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 134.48999999999944,
        close_ms: 1714134382936,
        current_return: 1.0002053071348906,
        initial_entry_price: 164.081,
        is_closed_position: true,
        miner_hotkey: "5F1sxW5apTPEYfDJUoHTRG4kGaUmkb3YVi5hwt5A9Fu8Gi6a",
        net_leverage: 0.0,
        open_ms: 1713191130810,
        orders: [
          {
            leverage: 0.05,
            order_type: "LONG",
            order_uuid: "1d75b9b8-679d-46e8-8ff6-b1ac9aa0fd62",
            price: 164.081,
            price_sources: [],
            processed_ms: 1713191130810,
          },
          {
            leverage: 0.05,
            order_type: "LONG",
            order_uuid: "b75b7ce3-b810-4a75-9d88-bf933ebe9e7f",
            price: 163.903,
            price_sources: [],
            processed_ms: 1713203216758,
          },
          {
            leverage: -0.099,
            order_type: "SHORT",
            order_uuid: "677e0810-3e43-4240-a02d-650ba9d1e5a5",
            price: 164.29,
            price_sources: [],
            processed_ms: 1713262566799,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "07342f36-7002-43da-b742-77aeec0ecf89",
            price: 168.177,
            price_sources: [],
            processed_ms: 1714134382936,
          },
        ],
        position_type: "FLAT",
        position_uuid: "b9f0666b-5c33-4784-b8ec-bae43777802c",
        return_at_close: 1.0001983056977408,
        trade_pair: ["EURJPY", "EUR/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 83.2479999999996,
        close_ms: 1714134998797,
        current_return: 1.0005562915357413,
        initial_entry_price: 91.337,
        is_closed_position: true,
        miner_hotkey: "5F1sxW5apTPEYfDJUoHTRG4kGaUmkb3YVi5hwt5A9Fu8Gi6a",
        net_leverage: 0.0,
        open_ms: 1713191567569,
        orders: [
          {
            leverage: 0.05,
            order_type: "LONG",
            order_uuid: "b4ef76e6-57b6-4ed9-b8eb-e06329dd0556",
            price: 91.337,
            price_sources: [],
            processed_ms: 1713191567568,
          },
          {
            leverage: 0.05,
            order_type: "LONG",
            order_uuid: "988ee19e-0bf6-4a24-92a6-592e8cd21a3d",
            price: 91.133,
            price_sources: [],
            processed_ms: 1713203167642,
          },
          {
            leverage: 0.1,
            order_type: "LONG",
            order_uuid: "5e3549ee-e300-4940-8953-feb94ce8c819",
            price: 90.978,
            price_sources: [],
            processed_ms: 1713228056488,
          },
          {
            leverage: -0.195,
            order_type: "SHORT",
            order_uuid: "67b3d0d0-0d6e-4bd3-b9b4-f30ca7f5a6f8",
            price: 91.308,
            price_sources: [],
            processed_ms: 1713418264854,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "fb6c0e81-75ec-4fc1-8790-ea461fe2e9e4",
            price: 93.41,
            price_sources: [],
            processed_ms: 1714134998797,
          },
        ],
        position_type: "FLAT",
        position_uuid: "bd0501cb-1d63-4e4d-b104-daa8198d7c3a",
        return_at_close: 1.0005422837476599,
        trade_pair: ["NZDJPY", "NZD/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 125.0810000000029,
        close_ms: 1714135207058,
        current_return: 1.0202575260652604,
        initial_entry_price: 156.722,
        is_closed_position: true,
        miner_hotkey: "5F1sxW5apTPEYfDJUoHTRG4kGaUmkb3YVi5hwt5A9Fu8Gi6a",
        net_leverage: 0.0,
        open_ms: 1714131400077,
        orders: [
          {
            leverage: 20.0,
            order_type: "LONG",
            order_uuid: "fd486538-925e-4acf-888b-0e24fb498178",
            price: 156.722,
            price_sources: [],
            processed_ms: 1714131400072,
          },
          {
            leverage: -19.9,
            order_type: "SHORT",
            order_uuid: "3a6e29a4-6b21-4e8b-a610-218f69df7a92",
            price: 156.881,
            price_sources: [],
            processed_ms: 1714134130723,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "f78c4f66-57dc-4d49-ac34-ed89914d1292",
            price: 156.829,
            price_sources: [],
            processed_ms: 1714135207058,
          },
        ],
        position_type: "FLAT",
        position_uuid: "6a31d602-388a-4127-9c89-0363f46da851",
        return_at_close: 1.0188291655287691,
        trade_pair: ["USDJPY", "USD/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.7970256085147447,
        close_ms: 1714161306653,
        current_return: 1.0001835672475858,
        initial_entry_price: 0.81276,
        is_closed_position: true,
        miner_hotkey: "5F1sxW5apTPEYfDJUoHTRG4kGaUmkb3YVi5hwt5A9Fu8Gi6a",
        net_leverage: 0.0,
        open_ms: 1714127388508,
        orders: [
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "a04c26c2-82aa-4800-81f1-5b3e26b3bbf9",
            price: 0.81276,
            price_sources: [],
            processed_ms: 1714127387744,
          },
          {
            leverage: -0.24,
            order_type: "SHORT",
            order_uuid: "815a9e28-6c39-475c-b2bb-684cfb48d7bb",
            price: 0.813415599645219,
            price_sources: [],
            processed_ms: 1714138930301,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "4cb728e3-ea22-4d42-ae74-07879ebf90bb",
            price: 0.811945220129523,
            price_sources: [],
            processed_ms: 1714161306653,
          },
        ],
        position_type: "FLAT",
        position_uuid: "e12ef135-a603-4d16-b232-76444dd0c4d6",
        return_at_close: 1.000166064035159,
        trade_pair: ["NZDCAD", "NZD/CAD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 1.178019999999994,
        close_ms: 1714161372787,
        current_return: 1.005702073263293,
        initial_entry_price: 1.25165,
        is_closed_position: true,
        miner_hotkey: "5F1sxW5apTPEYfDJUoHTRG4kGaUmkb3YVi5hwt5A9Fu8Gi6a",
        net_leverage: 0.0,
        open_ms: 1714131367495,
        orders: [
          {
            leverage: 20.0,
            order_type: "LONG",
            order_uuid: "55eecf67-dcdd-4502-88c0-22965011b8ca",
            price: 1.25165,
            price_sources: [],
            processed_ms: 1714131367491,
          },
          {
            leverage: -19.9,
            order_type: "SHORT",
            order_uuid: "98563bc0-7165-4013-b3cb-97d51efa07c9",
            price: 1.25202,
            price_sources: [],
            processed_ms: 1714134714982,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "c9e4cae4-d5c2-4717-afd4-1c8a0d062bd9",
            price: 1.24939,
            price_sources: [],
            processed_ms: 1714161372787,
          },
        ],
        position_type: "FLAT",
        position_uuid: "87628473-d746-46e6-8d1d-90fe753c0bc2",
        return_at_close: 1.0042940903607245,
        trade_pair: ["GBPUSD", "GBP/USD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 138.05999999999844,
        close_ms: 1714461765373,
        current_return: 0.9701434484727365,
        initial_entry_price: 196.168,
        is_closed_position: true,
        miner_hotkey: "5F1sxW5apTPEYfDJUoHTRG4kGaUmkb3YVi5hwt5A9Fu8Gi6a",
        net_leverage: 0.0,
        open_ms: 1714131383246,
        orders: [
          {
            leverage: -20.0,
            order_type: "SHORT",
            order_uuid: "c30e833d-1275-45c5-ae09-e6349a37045b",
            price: 196.168,
            price_sources: [],
            processed_ms: 1714131383240,
          },
          {
            leverage: 19.9,
            order_type: "LONG",
            order_uuid: "56ca2d53-fc08-4dcf-ba45-50df6a246e77",
            price: 196.46,
            price_sources: [],
            processed_ms: 1714134788981,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "250a4497-f12a-473b-9b87-ed9b1521ea31",
            price: 196.629,
            price_sources: [],
            processed_ms: 1714461765373,
          },
        ],
        position_type: "FLAT",
        position_uuid: "dc272a18-4680-47ef-9d4d-6872962ce100",
        return_at_close: 0.9687852476448747,
        trade_pair: ["GBPJPY", "GBP/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: -32938.90666666689,
        close_ms: 1714461784926,
        current_return: 1.2274053970525942,
        initial_entry_price: 63548.76,
        is_closed_position: true,
        miner_hotkey: "5F1sxW5apTPEYfDJUoHTRG4kGaUmkb3YVi5hwt5A9Fu8Gi6a",
        net_leverage: 0.0,
        open_ms: 1713259658275,
        orders: [
          {
            leverage: 1.0,
            order_type: "LONG",
            order_uuid: "6813eb15-2efa-48be-a5c7-cc0d750c7177",
            price: 63548.76,
            price_sources: [],
            processed_ms: 1713259658273,
          },
          {
            leverage: 4.0,
            order_type: "LONG",
            order_uuid: "1c4487a6-9388-4f06-8874-479e65dc86e1",
            price: 60451.52,
            price_sources: [],
            processed_ms: 1713373339169,
          },
          {
            leverage: -3.0,
            order_type: "SHORT",
            order_uuid: "d581e6a5-dedc-40fc-acaa-ee3605385256",
            price: 63985.6,
            price_sources: [],
            processed_ms: 1713453380409,
          },
          {
            leverage: -1.0,
            order_type: "SHORT",
            order_uuid: "27599c19-337a-418a-bb27-0cec458544cb",
            price: 63485.18,
            price_sources: [],
            processed_ms: 1713476857272,
          },
          {
            leverage: -0.45,
            order_type: "SHORT",
            order_uuid: "f001eb67-6235-4502-ba3b-f0ecaf43234d",
            price: 65225.66,
            price_sources: [],
            processed_ms: 1713526863543,
          },
          {
            leverage: 0.6,
            order_type: "LONG",
            order_uuid: "7ae69c36-ab01-4b8d-a3b3-10c859c3334f",
            price: 64716.81,
            price_sources: [],
            processed_ms: 1713540651644,
          },
          {
            leverage: -0.6,
            order_type: "SHORT",
            order_uuid: "23037d13-8331-4fe2-a44e-2c345c0bc058",
            price: 63764.13,
            price_sources: [],
            processed_ms: 1713543642028,
          },
          {
            leverage: 0.5,
            order_type: "LONG",
            order_uuid: "632e7e95-7e0b-4e62-bb23-4b18bc0a2425",
            price: 63958.48,
            price_sources: [],
            processed_ms: 1713584768087,
          },
          {
            leverage: -0.5,
            order_type: "SHORT",
            order_uuid: "caeefe47-6cd2-4afc-b405-78d48c68179d",
            price: 64027.52,
            price_sources: [],
            processed_ms: 1713598212127,
          },
          {
            leverage: 0.5,
            order_type: "LONG",
            order_uuid: "e0efcfa9-6b69-447c-83a5-cf3a80f236fc",
            price: 63937.49,
            price_sources: [],
            processed_ms: 1713624348787,
          },
          {
            leverage: -0.5,
            order_type: "SHORT",
            order_uuid: "f8b61d06-4093-4953-969d-933a0e91cb08",
            price: 64656.8,
            price_sources: [],
            processed_ms: 1713629253775,
          },
          {
            leverage: -0.1,
            order_type: "SHORT",
            order_uuid: "ebca88ea-9634-4416-aaf8-407da5f52d32",
            price: 66323.63,
            price_sources: [],
            processed_ms: 1713796090024,
          },
          {
            leverage: -0.3,
            order_type: "SHORT",
            order_uuid: "1003cc8f-8d6c-429d-9f41-e3ab509833a4",
            price: 63490.73,
            price_sources: [],
            processed_ms: 1714042642960,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "a965f22e-62d9-4e43-a07d-1486bd16330d",
            price: 63403.3,
            price_sources: [],
            processed_ms: 1714461784926,
          },
        ],
        position_type: "FLAT",
        position_uuid: "eadff9da-5e3d-478c-9692-ac6cea8db2e9",
        return_at_close: 1.219304521432047,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 103.6829999999994,
        close_ms: 1714963419669,
        current_return: 1.0001985089101708,
        initial_entry_price: 98.988,
        is_closed_position: true,
        miner_hotkey: "5F1sxW5apTPEYfDJUoHTRG4kGaUmkb3YVi5hwt5A9Fu8Gi6a",
        net_leverage: 0.0,
        open_ms: 1713237729422,
        orders: [
          {
            leverage: -0.01,
            order_type: "SHORT",
            order_uuid: "17a82168-0dd5-4d5e-a6df-72689a1955a2",
            price: 98.988,
            price_sources: [],
            processed_ms: 1713237729420,
          },
          {
            leverage: -0.02,
            order_type: "SHORT",
            order_uuid: "a8d045bc-b3f2-42f1-91e0-a151c6b0121c",
            price: 99.023,
            price_sources: [],
            processed_ms: 1713241647108,
          },
          {
            leverage: -0.03,
            order_type: "SHORT",
            order_uuid: "f66f175c-253e-4880-95a2-680e8b1f4229",
            price: 99.417,
            price_sources: [],
            processed_ms: 1713418342564,
          },
          {
            leverage: -0.14,
            order_type: "SHORT",
            order_uuid: "4e27df57-bcea-4d55-8832-e0e72e85c12f",
            price: 99.537,
            price_sources: [],
            processed_ms: 1713438080371,
          },
          {
            leverage: -0.2,
            order_type: "SHORT",
            order_uuid: "e1ae2f7a-99e4-419b-910b-41b26a265aa9",
            price: 99.535,
            price_sources: [],
            processed_ms: 1713438289694,
          },
          {
            leverage: 0.39,
            order_type: "LONG",
            order_uuid: "2a717d5c-e6f8-4972-822b-0fcdcb4bd69f",
            price: 99.38,
            price_sources: [],
            processed_ms: 1713444001988,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "4a055f4c-8105-4dc7-abee-e5cd300614af",
            price: 101.718,
            price_sources: [],
            processed_ms: 1714963419669,
          },
        ],
        position_type: "FLAT",
        position_uuid: "82e8576d-51b1-48cf-98de-1e297228cbf3",
        return_at_close: 1.0001705033519213,
        trade_pair: ["AUDJPY", "AUD/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.904346217132435,
        close_ms: 1715275156273,
        current_return: 1.0029969582265665,
        initial_entry_price: 0.904346217132435,
        is_closed_position: true,
        miner_hotkey: "5F1sxW5apTPEYfDJUoHTRG4kGaUmkb3YVi5hwt5A9Fu8Gi6a",
        net_leverage: 0.0,
        open_ms: 1715271542597,
        orders: [
          {
            leverage: 10.0,
            order_type: "LONG",
            order_uuid: "d465be72-bdf0-49a0-a693-d35f8185b496",
            price: 0.904346217132435,
            price_sources: [],
            processed_ms: 1715271542388,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "000d13b0-a98a-4365-9a41-f2032db48806",
            price: 0.904617245915945,
            price_sources: [],
            processed_ms: 1715275156273,
          },
        ],
        position_type: "FLAT",
        position_uuid: "d44fe632-61a3-48ec-a9b9-ade5737d4272",
        return_at_close: 1.002294860355808,
        trade_pair: ["AUDCAD", "AUD/CAD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 3734.2692079207923,
        close_ms: 0,
        current_return: 0.9608910699178981,
        initial_entry_price: 3085.19,
        is_closed_position: false,
        miner_hotkey: "5F1sxW5apTPEYfDJUoHTRG4kGaUmkb3YVi5hwt5A9Fu8Gi6a",
        net_leverage: 0.202,
        open_ms: 1714888694854,
        orders: [
          {
            leverage: 0.002,
            order_type: "LONG",
            order_uuid: "c34ac221-b1fc-4a64-b81f-995add02a9f2",
            price: 3085.19,
            price_sources: [],
            processed_ms: 1714888694689,
          },
          {
            leverage: 0.2,
            order_type: "LONG",
            order_uuid: "66cc411c-33d5-4be3-92c4-7cb08c384c30",
            price: 3740.76,
            price_sources: [],
            processed_ms: 1716580438445,
          },
        ],
        position_type: "LONG",
        position_uuid: "e900f01e-599e-4626-9104-c0ac0f30e27a",
        return_at_close: 0.9607940199198364,
        trade_pair: ["ETHUSD", "ETH/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 0.8541499999999999,
        close_ms: 0,
        current_return: 0.9973641976031771,
        initial_entry_price: 0.85363,
        is_closed_position: false,
        miner_hotkey: "5F1sxW5apTPEYfDJUoHTRG4kGaUmkb3YVi5hwt5A9Fu8Gi6a",
        net_leverage: 0.19999999999999996,
        open_ms: 1716302011747,
        orders: [
          {
            leverage: 1.0,
            order_type: "LONG",
            order_uuid: "c5909ef6-648e-44aa-aa18-4b60bb4b053b",
            price: 0.85363,
            price_sources: [],
            processed_ms: 1716302011404,
          },
          {
            leverage: -0.8,
            order_type: "SHORT",
            order_uuid: "7b4361ef-4e9a-4ef3-bddd-30aef442fcd5",
            price: 0.8535,
            price_sources: [],
            processed_ms: 1717157851575,
          },
        ],
        position_type: "LONG",
        position_uuid: "fe4bce99-6c20-446e-b1f7-4b8eae83166d",
        return_at_close: 0.997301363658728,
        trade_pair: ["EURGBP", "EUR/GBP", 7e-5, 0.001, 500],
      },
    ],
    thirty_day_returns: 1.0,
  },
  "5F1wUDt3Mv2SXyVwzQJxqFnnptc6yaNFejDL4LJE7chNcLwH": {
    all_time_returns: 1.0,
    n_positions: 0,
    percentage_profitable: 0.0,
    positions: [
      {
        average_entry_price: 64877.78,
        close_ms: 1711202025812,
        current_return: 0.9989501490340761,
        initial_entry_price: 64877.78,
        is_closed_position: true,
        miner_hotkey: "5F1wUDt3Mv2SXyVwzQJxqFnnptc6yaNFejDL4LJE7chNcLwH",
        net_leverage: 0.0,
        open_ms: 1711198450664,
        orders: [
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "df20913e-d5b7-46b0-bb51-eb7560ffede7",
            price: 64877.78,
            price_sources: [],
            processed_ms: 1711198450663,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "2bcf0d84-46f9-49f9-9151-1184bc978851",
            price: 64650.74,
            price_sources: [],
            processed_ms: 1711202025812,
          },
        ],
        position_type: "FLAT",
        position_uuid: "ba1ef31b-e953-454f-b2d3-364888d08cfd",
        return_at_close: 0.9983507789446556,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 65361.83,
        close_ms: 1711209371314,
        current_return: 0.9989737129453077,
        initial_entry_price: 65361.83,
        is_closed_position: true,
        miner_hotkey: "5F1wUDt3Mv2SXyVwzQJxqFnnptc6yaNFejDL4LJE7chNcLwH",
        net_leverage: 0.0,
        open_ms: 1711205780693,
        orders: [
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "29d8dc16-f5d8-4862-8dfa-cc48c3311d8d",
            price: 65361.83,
            price_sources: [],
            processed_ms: 1711205780692,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "ca8a06a8-4000-4ecd-8a4b-ddacaa461794",
            price: 65138.23,
            price_sources: [],
            processed_ms: 1711209371314,
          },
        ],
        position_type: "FLAT",
        position_uuid: "60cede60-791f-4f37-b21e-44320f88d640",
        return_at_close: 0.9983743287175405,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 65132.28999999999,
        close_ms: 1711213041041,
        current_return: 0.9972259842238005,
        initial_entry_price: 65132.29,
        is_closed_position: true,
        miner_hotkey: "5F1wUDt3Mv2SXyVwzQJxqFnnptc6yaNFejDL4LJE7chNcLwH",
        net_leverage: 0.0,
        open_ms: 1711209456578,
        orders: [
          {
            leverage: -0.3,
            order_type: "SHORT",
            order_uuid: "3be29083-22af-4985-9f76-e612b77201f8",
            price: 65132.29,
            price_sources: [],
            processed_ms: 1711209456577,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "3281f2f8-b3eb-4abf-a25e-aa17482c0482",
            price: 65734.55,
            price_sources: [],
            processed_ms: 1711213041041,
          },
        ],
        position_type: "FLAT",
        position_uuid: "df1deb44-5de7-4c02-924a-b7924e0d13ea",
        return_at_close: 0.9966276486332661,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 65709.38,
        close_ms: 1711216684011,
        current_return: 1.000281192730779,
        initial_entry_price: 65709.38,
        is_closed_position: true,
        miner_hotkey: "5F1wUDt3Mv2SXyVwzQJxqFnnptc6yaNFejDL4LJE7chNcLwH",
        net_leverage: 0.0,
        open_ms: 1711213148154,
        orders: [
          {
            leverage: -0.3,
            order_type: "SHORT",
            order_uuid: "09452b80-36ef-40a6-917b-e0ee30329c69",
            price: 65709.38,
            price_sources: [],
            processed_ms: 1711213148153,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "07103444-f05f-4b84-bb2a-36c36f3e10df",
            price: 65647.79,
            price_sources: [],
            processed_ms: 1711216684011,
          },
        ],
        position_type: "FLAT",
        position_uuid: "f85e5a1f-feda-4110-966f-dc53ce71f0da",
        return_at_close: 0.9996810240151406,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 65663.21,
        close_ms: 1711220347186,
        current_return: 1.0012352579168762,
        initial_entry_price: 65663.21,
        is_closed_position: true,
        miner_hotkey: "5F1wUDt3Mv2SXyVwzQJxqFnnptc6yaNFejDL4LJE7chNcLwH",
        net_leverage: 0.0,
        open_ms: 1711216814499,
        orders: [
          {
            leverage: -0.3,
            order_type: "SHORT",
            order_uuid: "e95ada8f-fefb-439d-9ae8-4c1fea3b0051",
            price: 65663.21,
            price_sources: [],
            processed_ms: 1711216814498,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "983b8eff-0849-4dac-9ae6-e05995deb7f0",
            price: 65392.84,
            price_sources: [],
            processed_ms: 1711220347186,
          },
        ],
        position_type: "FLAT",
        position_uuid: "a2dabe84-5d38-4373-a750-eb684986a8a7",
        return_at_close: 1.000634516762126,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 65385.29,
        close_ms: 1711224008405,
        current_return: 1.0020698233501755,
        initial_entry_price: 65385.29,
        is_closed_position: true,
        miner_hotkey: "5F1wUDt3Mv2SXyVwzQJxqFnnptc6yaNFejDL4LJE7chNcLwH",
        net_leverage: 0.0,
        open_ms: 1711220433838,
        orders: [
          {
            leverage: -0.3,
            order_type: "SHORT",
            order_uuid: "283c4e34-3572-4e13-ba88-5e103176f865",
            price: 65385.29,
            price_sources: [],
            processed_ms: 1711220433837,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "00987668-908b-4375-b8a0-be7e912bc160",
            price: 64934.17,
            price_sources: [],
            processed_ms: 1711224008405,
          },
        ],
        position_type: "FLAT",
        position_uuid: "6c2c055d-048e-4d0e-b2e1-f680e130f90a",
        return_at_close: 1.0014685814561652,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 64947.37,
        close_ms: 1711227692411,
        current_return: 0.9997710761806059,
        initial_entry_price: 64947.37,
        is_closed_position: true,
        miner_hotkey: "5F1wUDt3Mv2SXyVwzQJxqFnnptc6yaNFejDL4LJE7chNcLwH",
        net_leverage: 0.0,
        open_ms: 1711224096497,
        orders: [
          {
            leverage: -0.3,
            order_type: "SHORT",
            order_uuid: "27e5b782-1950-4287-9587-000251b2d742",
            price: 64947.37,
            price_sources: [],
            processed_ms: 1711224096188,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "076c4329-bd5e-4808-8e0a-1bd4c362c15c",
            price: 64996.93,
            price_sources: [],
            processed_ms: 1711227692411,
          },
        ],
        position_type: "FLAT",
        position_uuid: "65cdb48f-057a-4ce1-a11b-57dcf518b3c6",
        return_at_close: 0.9991712135348975,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 64970.80000000001,
        close_ms: 1711231328810,
        current_return: 1.0001062939043386,
        initial_entry_price: 64970.8,
        is_closed_position: true,
        miner_hotkey: "5F1wUDt3Mv2SXyVwzQJxqFnnptc6yaNFejDL4LJE7chNcLwH",
        net_leverage: 0.0,
        open_ms: 1711227795825,
        orders: [
          {
            leverage: -0.3,
            order_type: "SHORT",
            order_uuid: "97ff2954-8b03-415a-b5fe-68f6f7a7c2e5",
            price: 64970.8,
            price_sources: [],
            processed_ms: 1711227795824,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "587211cc-6788-49cd-b5dd-f7c58681ec8d",
            price: 64947.78,
            price_sources: [],
            processed_ms: 1711231328810,
          },
        ],
        position_type: "FLAT",
        position_uuid: "b8420adb-d691-46c6-8b2f-df94c17ab82e",
        return_at_close: 0.9995062301279959,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 64465.0,
        close_ms: 1711249641991,
        current_return: 0.9983872178701622,
        initial_entry_price: 64465.0,
        is_closed_position: true,
        miner_hotkey: "5F1wUDt3Mv2SXyVwzQJxqFnnptc6yaNFejDL4LJE7chNcLwH",
        net_leverage: 0.0,
        open_ms: 1711246106102,
        orders: [
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "9fb1ecf6-d880-4818-b32e-ac226369ddda",
            price: 64465.0,
            price_sources: [],
            processed_ms: 1711246106101,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "55b727d6-43b8-4386-a8d7-920796ca0b8f",
            price: 64118.44,
            price_sources: [],
            processed_ms: 1711249641991,
          },
        ],
        position_type: "FLAT",
        position_uuid: "8dbadff8-444d-418b-baf0-7fba5a84f852",
        return_at_close: 0.99778818553944,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 64172.59,
        close_ms: 1711257582757,
        current_return: 1.0004111101016806,
        initial_entry_price: 64172.59,
        is_closed_position: true,
        miner_hotkey: "5F1wUDt3Mv2SXyVwzQJxqFnnptc6yaNFejDL4LJE7chNcLwH",
        net_leverage: 0.0,
        open_ms: 1711253384767,
        orders: [
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "8169a73c-5f2c-46a8-9bb6-70d7d41725a5",
            price: 64172.59,
            price_sources: [],
            processed_ms: 1711253384765,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "2fe30a81-0767-4e16-9c5a-36aaece55df0",
            price: 64260.53,
            price_sources: [],
            processed_ms: 1711257582757,
          },
        ],
        position_type: "FLAT",
        position_uuid: "56723e9b-e07d-4983-ad5e-bc12beadc69f",
        return_at_close: 0.9998108634356195,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 64248.7,
        close_ms: 1711261264761,
        current_return: 1.0024290374746883,
        initial_entry_price: 64248.7,
        is_closed_position: true,
        miner_hotkey: "5F1wUDt3Mv2SXyVwzQJxqFnnptc6yaNFejDL4LJE7chNcLwH",
        net_leverage: 0.0,
        open_ms: 1711257669732,
        orders: [
          {
            leverage: 1.25,
            order_type: "LONG",
            order_uuid: "133ae03f-4a6c-467c-aa0c-8a7c855a5179",
            price: 64248.7,
            price_sources: [],
            processed_ms: 1711257669731,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "56a2d9bd-8c15-4ce4-ae37-cfef9ed12cdf",
            price: 64373.55,
            price_sources: [],
            processed_ms: 1711261264761,
          },
        ],
        position_type: "FLAT",
        position_uuid: "4df87807-c844-41c1-81fd-f93a01e6c6fc",
        return_at_close: 0.9999229648810016,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 64292.75,
        close_ms: 1711264909059,
        current_return: 0.9967119154181459,
        initial_entry_price: 64292.75,
        is_closed_position: true,
        miner_hotkey: "5F1wUDt3Mv2SXyVwzQJxqFnnptc6yaNFejDL4LJE7chNcLwH",
        net_leverage: 0.0,
        open_ms: 1711261350584,
        orders: [
          {
            leverage: 1.25,
            order_type: "LONG",
            order_uuid: "ab98fc1a-2bf0-4f73-a5c2-6c78bb6205de",
            price: 64292.75,
            price_sources: [],
            processed_ms: 1711261350583,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "e39ee54f-2694-4108-ae01-fd6a0654a235",
            price: 64123.63,
            price_sources: [],
            processed_ms: 1711264909059,
          },
        ],
        position_type: "FLAT",
        position_uuid: "00fa97d2-ccd5-49d3-8c81-8b730e67f16f",
        return_at_close: 0.9942201356296005,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 64143.92,
        close_ms: 1711268573824,
        current_return: 1.0125709498265776,
        initial_entry_price: 64143.92,
        is_closed_position: true,
        miner_hotkey: "5F1wUDt3Mv2SXyVwzQJxqFnnptc6yaNFejDL4LJE7chNcLwH",
        net_leverage: 0.0,
        open_ms: 1711265036414,
        orders: [
          {
            leverage: 1.25,
            order_type: "LONG",
            order_uuid: "c9960580-8d99-4604-af98-d73743662db0",
            price: 64143.92,
            price_sources: [],
            processed_ms: 1711265036412,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "d7766d05-b501-4fe0-a60e-54e8cc52b73c",
            price: 64789.0,
            price_sources: [],
            processed_ms: 1711268573824,
          },
        ],
        position_type: "FLAT",
        position_uuid: "d57fb24e-bc1d-49a6-9460-f5efdf18913e",
        return_at_close: 1.0100395224520111,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 64795.719999999994,
        close_ms: 1711272237287,
        current_return: 1.0008578884531263,
        initial_entry_price: 64795.72,
        is_closed_position: true,
        miner_hotkey: "5F1wUDt3Mv2SXyVwzQJxqFnnptc6yaNFejDL4LJE7chNcLwH",
        net_leverage: 0.0,
        open_ms: 1711268662218,
        orders: [
          {
            leverage: 1.25,
            order_type: "LONG",
            order_uuid: "d37b4646-818b-4c56-a1c4-2d3851e61456",
            price: 64795.72,
            price_sources: [],
            processed_ms: 1711268662216,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "19c673f6-e917-4488-a2ab-c912b90c6eed",
            price: 64840.19,
            price_sources: [],
            processed_ms: 1711272237287,
          },
        ],
        position_type: "FLAT",
        position_uuid: "b460b25e-f2df-4c1d-a929-72730e7c60a6",
        return_at_close: 0.9983557437319935,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 65499.48,
        close_ms: 1711285903779,
        current_return: 0.9989900683180996,
        initial_entry_price: 65499.48,
        is_closed_position: true,
        miner_hotkey: "5F1wUDt3Mv2SXyVwzQJxqFnnptc6yaNFejDL4LJE7chNcLwH",
        net_leverage: 0.0,
        open_ms: 1711282242975,
        orders: [
          {
            leverage: 1.25,
            order_type: "LONG",
            order_uuid: "08f62235-d597-4c45-bdc5-06e5bda24c09",
            price: 65499.48,
            price_sources: [],
            processed_ms: 1711282242974,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "91c9af7b-3b5d-4cc7-9ddd-1e9434bb6b3e",
            price: 65446.56,
            price_sources: [],
            processed_ms: 1711285903779,
          },
        ],
        position_type: "FLAT",
        position_uuid: "16eb7af7-dcd6-42f8-ba3c-edbfd20a87d2",
        return_at_close: 0.9964925931473043,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 65548.27,
        close_ms: 1711293235087,
        current_return: 0.9975996696785437,
        initial_entry_price: 65548.27,
        is_closed_position: true,
        miner_hotkey: "5F1wUDt3Mv2SXyVwzQJxqFnnptc6yaNFejDL4LJE7chNcLwH",
        net_leverage: 0.0,
        open_ms: 1711291398931,
        orders: [
          {
            leverage: 1.25,
            order_type: "LONG",
            order_uuid: "2e70ac3d-5f00-477e-a6e1-48c8cb515ee2",
            price: 65548.27,
            price_sources: [],
            processed_ms: 1711291398930,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "52088aeb-0dc4-44a5-889d-d5b11d26682c",
            price: 65422.4,
            price_sources: [],
            processed_ms: 1711293235087,
          },
        ],
        position_type: "FLAT",
        position_uuid: "2081ee16-b34d-48c6-acb4-ae839c921db4",
        return_at_close: 0.9951056705043474,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 65985.27000000002,
        close_ms: 1711317255975,
        current_return: 0.9998294799793694,
        initial_entry_price: 66289.87,
        is_closed_position: true,
        miner_hotkey: "5F1wUDt3Mv2SXyVwzQJxqFnnptc6yaNFejDL4LJE7chNcLwH",
        net_leverage: 0.0,
        open_ms: 1711312598820,
        orders: [
          {
            leverage: -1.25,
            order_type: "SHORT",
            order_uuid: "f6de9769-4ed6-4049-a5c3-8e10cc7121e8",
            price: 66289.87,
            price_sources: [],
            processed_ms: 1711312598819,
          },
          {
            leverage: 0.625,
            order_type: "LONG",
            order_uuid: "26b918a3-e221-4a37-a110-17585589072b",
            price: 66279.34,
            price_sources: [],
            processed_ms: 1711313529644,
          },
          {
            leverage: 0.3125,
            order_type: "LONG",
            order_uuid: "19996c7f-d97b-4ae3-947b-f08d506ffc12",
            price: 66323.47,
            price_sources: [],
            processed_ms: 1711314454748,
          },
          {
            leverage: 0.15625,
            order_type: "LONG",
            order_uuid: "a5b93a05-3575-4c01-8b88-e305606e5c83",
            price: 66289.35,
            price_sources: [],
            processed_ms: 1711315408622,
          },
          {
            leverage: 0.125,
            order_type: "LONG",
            order_uuid: "0d652fb8-7172-4aae-a5b8-1c14ae211b80",
            price: 66335.32,
            price_sources: [],
            processed_ms: 1711316321909,
          },
          {
            leverage: 0.125,
            order_type: "LONG",
            order_uuid: "394f564a-0739-43eb-ae34-89a93f9a2ad2",
            price: 66346.99,
            price_sources: [],
            processed_ms: 1711317255975,
          },
        ],
        position_type: "FLAT",
        position_uuid: "0cdb2184-cd8e-434b-8a6c-dfa9a03d851d",
        return_at_close: 0.9973299062794211,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 102745.67999999967,
        close_ms: 1711340997481,
        current_return: 1.0041577548870069,
        initial_entry_price: 66878.36,
        is_closed_position: true,
        miner_hotkey: "5F1wUDt3Mv2SXyVwzQJxqFnnptc6yaNFejDL4LJE7chNcLwH",
        net_leverage: 0.0,
        open_ms: 1711326554929,
        orders: [
          {
            leverage: -1.25,
            order_type: "SHORT",
            order_uuid: "d1a7bc81-07bd-44d0-977e-dca586d5e8e2",
            price: 66878.36,
            price_sources: [],
            processed_ms: 1711326554927,
          },
          {
            leverage: 0.125,
            order_type: "LONG",
            order_uuid: "0cc66141-0c58-4499-95d7-ca286cd93afa",
            price: 66604.42,
            price_sources: [],
            processed_ms: 1711327490167,
          },
          {
            leverage: 0.125,
            order_type: "LONG",
            order_uuid: "529da6bf-07c1-4688-bc85-b26f2b79d322",
            price: 66577.69,
            price_sources: [],
            processed_ms: 1711328420721,
          },
          {
            leverage: 0.125,
            order_type: "LONG",
            order_uuid: "66678c79-1387-45e2-b70f-f0e98540d645",
            price: 66796.48,
            price_sources: [],
            processed_ms: 1711329354308,
          },
          {
            leverage: 0.4375,
            order_type: "LONG",
            order_uuid: "90185099-f75a-490e-a6a4-3168bd68bdb7",
            price: 66673.54,
            price_sources: [],
            processed_ms: 1711330001055,
          },
          {
            leverage: 0.125,
            order_type: "LONG",
            order_uuid: "c2c057fe-9a29-484f-be27-431631f8f378",
            price: 66498.56,
            price_sources: [],
            processed_ms: 1711331834721,
          },
          {
            leverage: 0.15625,
            order_type: "LONG",
            order_uuid: "6c8ed45c-c50d-4d81-8cd4-293d2d65ff45",
            price: 66618.91,
            price_sources: [],
            processed_ms: 1711333662895,
          },
          {
            leverage: 0.125,
            order_type: "LONG",
            order_uuid: "5c993a5b-93ed-45e4-81e0-66435d5e91da",
            price: 66701.21,
            price_sources: [],
            processed_ms: 1711335504059,
          },
          {
            leverage: 0.015625,
            order_type: "LONG",
            order_uuid: "d82d9c42-d939-455e-afe2-fac6332ce8a5",
            price: 66795.41,
            price_sources: [],
            processed_ms: 1711337363390,
          },
          {
            leverage: 0.0078125,
            order_type: "LONG",
            order_uuid: "d91b9023-46b0-4c01-a587-40d0e5e3ffd3",
            price: 67250.9,
            price_sources: [],
            processed_ms: 1711339169978,
          },
          {
            leverage: 0.125,
            order_type: "LONG",
            order_uuid: "4095141c-7a90-46bb-97e0-940c5ce60b57",
            price: 67153.51,
            price_sources: [],
            processed_ms: 1711340997481,
          },
        ],
        position_type: "FLAT",
        position_uuid: "e746dfb9-c534-461d-b6a1-ef6133abd7fa",
        return_at_close: 1.0016473604997893,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 67507.36,
        close_ms: 1711344669190,
        current_return: 1.0011489487961016,
        initial_entry_price: 67507.36,
        is_closed_position: true,
        miner_hotkey: "5F1wUDt3Mv2SXyVwzQJxqFnnptc6yaNFejDL4LJE7chNcLwH",
        net_leverage: 0.0,
        open_ms: 1711342852930,
        orders: [
          {
            leverage: 1.25,
            order_type: "LONG",
            order_uuid: "3289770e-67e5-4e4b-8e98-42a2c1a50261",
            price: 67507.36,
            price_sources: [],
            processed_ms: 1711342852929,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "d2c15221-6f0f-47a2-953c-ad6e7ef207a9",
            price: 67569.41,
            price_sources: [],
            processed_ms: 1711344669190,
          },
        ],
        position_type: "FLAT",
        position_uuid: "d3676fe8-5457-49b5-b472-8379766fd087",
        return_at_close: 0.9986460764241114,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 66946.63,
        close_ms: 1711359326667,
        current_return: 0.9969826711217576,
        initial_entry_price: 66946.63,
        is_closed_position: true,
        miner_hotkey: "5F1wUDt3Mv2SXyVwzQJxqFnnptc6yaNFejDL4LJE7chNcLwH",
        net_leverage: 0.0,
        open_ms: 1711357483082,
        orders: [
          {
            leverage: 1.25,
            order_type: "LONG",
            order_uuid: "d6944c1e-5364-476b-99dc-d528edddc02f",
            price: 66946.63,
            price_sources: [],
            processed_ms: 1711357483080,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "fa2a5e39-239a-40b9-ab50-f265d48315fe",
            price: 66785.03,
            price_sources: [],
            processed_ms: 1711359326667,
          },
        ],
        position_type: "FLAT",
        position_uuid: "4d1d491d-d656-43fc-9f17-b49527f10aa1",
        return_at_close: 0.9944902144439534,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 67199.01,
        close_ms: 1711362979225,
        current_return: 0.9988452210828701,
        initial_entry_price: 67199.01,
        is_closed_position: true,
        miner_hotkey: "5F1wUDt3Mv2SXyVwzQJxqFnnptc6yaNFejDL4LJE7chNcLwH",
        net_leverage: 0.0,
        open_ms: 1711361151132,
        orders: [
          {
            leverage: 1.25,
            order_type: "LONG",
            order_uuid: "6980d18e-c119-4881-9422-bfc82c7fdece",
            price: 67199.01,
            price_sources: [],
            processed_ms: 1711361151130,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "1caad9cf-cf37-45c3-843e-fe6c7837b9a8",
            price: 67136.93,
            price_sources: [],
            processed_ms: 1711362979225,
          },
        ],
        position_type: "FLAT",
        position_uuid: "637ec939-b625-4a4c-ae93-08c24dbf939e",
        return_at_close: 0.9963481080301629,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 66968.30555555556,
        close_ms: 1711376046262,
        current_return: 1.0646166031948636,
        initial_entry_price: 66874.84,
        is_closed_position: true,
        miner_hotkey: "5F1wUDt3Mv2SXyVwzQJxqFnnptc6yaNFejDL4LJE7chNcLwH",
        net_leverage: 0.0,
        open_ms: 1711367263993,
        orders: [
          {
            leverage: 1.25,
            order_type: "LONG",
            order_uuid: "da4f835c-8a37-41e6-87b4-d79f874d05e0",
            price: 66874.84,
            price_sources: [],
            processed_ms: 1711367263991,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "f94813cb-1d88-4d45-bcce-82e334138f0d",
            price: 67079.04,
            price_sources: [],
            processed_ms: 1711369099449,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "e4d39785-858f-417b-88fb-f068815f3407",
            price: 67141.08,
            price_sources: [],
            processed_ms: 1711370951422,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "8bb98b9d-508e-448e-a180-8a399627d134",
            price: 66911.17,
            price_sources: [],
            processed_ms: 1711372765318,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "ca57a52a-c01c-4a66-894c-24780d1a52b3",
            price: 67209.26,
            price_sources: [],
            processed_ms: 1711374614374,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "d75c6ce5-c61c-4dfd-b44a-8b9bd15a3545",
            price: 68888.85,
            price_sources: [],
            processed_ms: 1711376046262,
          },
        ],
        position_type: "FLAT",
        position_uuid: "1975ee45-e45b-4afb-bca9-ce2a024254a4",
        return_at_close: 1.059825828480487,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 69235.28,
        close_ms: 1711376624861,
        current_return: 0.9975877543934248,
        initial_entry_price: 69235.28,
        is_closed_position: true,
        miner_hotkey: "5F1wUDt3Mv2SXyVwzQJxqFnnptc6yaNFejDL4LJE7chNcLwH",
        net_leverage: 0.0,
        open_ms: 1711376609948,
        orders: [
          {
            leverage: -1.25,
            order_type: "SHORT",
            order_uuid: "1d5143ab-6c1d-4791-8e41-64782967fb7a",
            price: 69235.28,
            price_sources: [],
            processed_ms: 1711376609946,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "5dd8bcea-252f-4644-aee1-27707a783402",
            price: 69368.89,
            price_sources: [],
            processed_ms: 1711376624861,
          },
        ],
        position_type: "FLAT",
        position_uuid: "3ced20fe-a76e-48e9-9f11-ca100c7a3c76",
        return_at_close: 0.9950937850074413,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 70710.63201097609,
        close_ms: 1711401507995,
        current_return: 0.9430729864929785,
        initial_entry_price: 69144.78,
        is_closed_position: true,
        miner_hotkey: "5F1wUDt3Mv2SXyVwzQJxqFnnptc6yaNFejDL4LJE7chNcLwH",
        net_leverage: 0.0,
        open_ms: 1711378029583,
        orders: [
          {
            leverage: 1.25,
            order_type: "LONG",
            order_uuid: "79bd82f6-c4f9-4430-860f-f49cef76a87d",
            price: 69144.78,
            price_sources: [],
            processed_ms: 1711378029582,
          },
          {
            leverage: -1.0,
            order_type: "SHORT",
            order_uuid: "d31623ea-d31f-4ca2-b065-8e97976bdaf5",
            price: 69212.28,
            price_sources: [],
            processed_ms: 1711378289962,
          },
          {
            leverage: -0.125,
            order_type: "SHORT",
            order_uuid: "d50f0e6e-db31-4b78-81c8-aaff8640528f",
            price: 69524.96,
            price_sources: [],
            processed_ms: 1711380388185,
          },
          {
            leverage: -0.1,
            order_type: "SHORT",
            order_uuid: "004b09f5-9ca6-4226-9647-db255271ff2c",
            price: 69777.86,
            price_sources: [],
            processed_ms: 1711382199093,
          },
          {
            leverage: -0.019999999999999997,
            order_type: "SHORT",
            order_uuid: "2085eef8-c1f3-4a82-a812-ca0e1eaa76e2",
            price: 69640.0,
            price_sources: [],
            processed_ms: 1711384029590,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "d47bf5e9-480b-4342-a4af-29e4d57b2f1c",
            price: 70241.49,
            price_sources: [],
            processed_ms: 1711385846063,
          },
          {
            leverage: -0.1275,
            order_type: "SHORT",
            order_uuid: "4548bb2d-ff6b-4c85-a0d2-8e17b0d4fa93",
            price: 70707.34,
            price_sources: [],
            processed_ms: 1711387668230,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "4f4aa990-ae8a-48fd-b0c2-056c91c0eaf1",
            price: 70530.92,
            price_sources: [],
            processed_ms: 1711389517120,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "2d76d8e2-02c3-49ba-8ccd-94262fde51f1",
            price: 70588.91,
            price_sources: [],
            processed_ms: 1711392206991,
          },
          {
            leverage: 0.8999999999999999,
            order_type: "LONG",
            order_uuid: "5a2556ea-2af9-42d3-a4fe-28d1471abe59",
            price: 70834.49,
            price_sources: [],
            processed_ms: 1711393404035,
          },
          {
            leverage: 0.8999999999999999,
            order_type: "LONG",
            order_uuid: "853838eb-ed08-4739-864d-a686e9780910",
            price: 70754.49,
            price_sources: [],
            processed_ms: 1711395224705,
          },
          {
            leverage: 0.8999999999999999,
            order_type: "LONG",
            order_uuid: "ff901616-3423-40e6-81a8-f6a564083092",
            price: 70993.43,
            price_sources: [],
            processed_ms: 1711397069274,
          },
          {
            leverage: 0.8999999999999995,
            order_type: "LONG",
            order_uuid: "44dce0cf-f97c-45ee-b938-c63b739c1137",
            price: 70782.84,
            price_sources: [],
            processed_ms: 1711398859900,
          },
          {
            leverage: 0.9000000000000004,
            order_type: "LONG",
            order_uuid: "e8f03544-abf5-44d8-81bb-df90b170ac9f",
            price: 70788.4,
            price_sources: [],
            processed_ms: 1711400668172,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "37e60cd0-63d8-4c59-b000-3fe4aa955c60",
            price: 70093.43,
            price_sources: [],
            processed_ms: 1711401507995,
          },
        ],
        position_type: "FLAT",
        position_uuid: "73e6458e-661c-4f55-a1a9-7168bece09ce",
        return_at_close: 0.9310440905502606,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 70318.548125,
        close_ms: 1711410529045,
        current_return: 0.9622431045315133,
        initial_entry_price: 70398.77,
        is_closed_position: true,
        miner_hotkey: "5F1wUDt3Mv2SXyVwzQJxqFnnptc6yaNFejDL4LJE7chNcLwH",
        net_leverage: 0.0,
        open_ms: 1711406117143,
        orders: [
          {
            leverage: 3.0,
            order_type: "LONG",
            order_uuid: "69153a43-5f2c-471a-9be5-6766c65a336f",
            price: 70398.77,
            price_sources: [],
            processed_ms: 1711406117141,
          },
          {
            leverage: 0.8999999999999999,
            order_type: "LONG",
            order_uuid: "7a89faab-d844-4635-a1a9-a91b14458cf5",
            price: 70309.85,
            price_sources: [],
            processed_ms: 1711407933333,
          },
          {
            leverage: 0.8999999999999999,
            order_type: "LONG",
            order_uuid: "54048640-723c-4576-a92a-37c51c622ebb",
            price: 70059.84,
            price_sources: [],
            processed_ms: 1711409788320,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "e7643871-7c56-4dd1-81c7-74304aac287a",
            price: 69764.79,
            price_sources: [],
            processed_ms: 1711410529045,
          },
        ],
        position_type: "FLAT",
        position_uuid: "3db39365-cd22-4d9c-bef0-100bee7262d6",
        return_at_close: 0.9530055707280107,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
    ],
    thirty_day_returns: 1.0,
  },
  "5F42rNzErYsnVqZVgB1HYTAuHWyAimoiBtvFhqGQaP7KuMGb": {
    all_time_returns: 1.0,
    n_positions: 0,
    percentage_profitable: 0.0,
    positions: [
      {
        average_entry_price: 3277.2,
        close_ms: 0,
        current_return: 1.0025677407543025,
        initial_entry_price: 3277.2,
        is_closed_position: false,
        miner_hotkey: "5F42rNzErYsnVqZVgB1HYTAuHWyAimoiBtvFhqGQaP7KuMGb",
        net_leverage: -0.06,
        open_ms: 1719244197568,
        orders: [
          {
            leverage: -0.06,
            order_type: "SHORT",
            order_uuid: "c341c7f8-8a84-4180-b749-c1edf864cac2",
            price: 3277.2,
            price_sources: [],
            processed_ms: 1719244196968,
          },
        ],
        position_type: "SHORT",
        position_uuid: "31f8cb4d-55a2-4f12-bc29-1d70d044fead",
        return_at_close: 1.00253766372208,
        trade_pair: ["ETHUSD", "ETH/USD", 0.003, 0.001, 20],
      },
    ],
    thirty_day_returns: 1.0,
  },
  "5F4RTc8mpCMDf4zRSxTTZEew34ZKBBk6Sd2vDz64uKUNC5iA": {
    all_time_returns: 1.0,
    n_positions: 0,
    percentage_profitable: 0.0,
    positions: [
      {
        average_entry_price: 64399.259999999995,
        close_ms: 1711240328237,
        current_return: 1.0000071739954777,
        initial_entry_price: 64399.26,
        is_closed_position: true,
        miner_hotkey: "5F4RTc8mpCMDf4zRSxTTZEew34ZKBBk6Sd2vDz64uKUNC5iA",
        net_leverage: 0.0,
        open_ms: 1711236790390,
        orders: [
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "41fd89d1-eab7-4f94-904f-bbb7aae4b887",
            price: 64399.26,
            price_sources: [],
            processed_ms: 1711236790390,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "1daac41e-5042-46a8-8b76-2747811e2a6d",
            price: 64400.8,
            price_sources: [],
            processed_ms: 1711240328237,
          },
        ],
        position_type: "FLAT",
        position_uuid: "7e2e681e-1a90-4a88-8dff-3acb448299d0",
        return_at_close: 0.9994071696910803,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 64311.8,
        close_ms: 1711251334508,
        current_return: 0.9989994060188021,
        initial_entry_price: 64311.8,
        is_closed_position: true,
        miner_hotkey: "5F4RTc8mpCMDf4zRSxTTZEew34ZKBBk6Sd2vDz64uKUNC5iA",
        net_leverage: 0.0,
        open_ms: 1711247740811,
        orders: [
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "22faaf39-35a2-406a-903e-6e2e8bf81e1a",
            price: 64311.8,
            price_sources: [],
            processed_ms: 1711247740810,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "5abc6eee-4292-4bcf-8188-3ca5801ecab0",
            price: 64097.3,
            price_sources: [],
            processed_ms: 1711251334508,
          },
        ],
        position_type: "FLAT",
        position_uuid: "45cad125-ba25-47cd-bae4-c3ce38e60576",
        return_at_close: 0.9984000063751908,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 64813.73,
        close_ms: 1711301802554,
        current_return: 0.9965549351966011,
        initial_entry_price: 64813.73,
        is_closed_position: true,
        miner_hotkey: "5F4RTc8mpCMDf4zRSxTTZEew34ZKBBk6Sd2vDz64uKUNC5iA",
        net_leverage: 0.0,
        open_ms: 1711299973847,
        orders: [
          {
            leverage: -1.25,
            order_type: "SHORT",
            order_uuid: "f603d82e-a07a-4b86-af1e-5becac1dde9c",
            price: 64813.73,
            price_sources: [],
            processed_ms: 1711299973846,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "3e7db8e8-d8f8-4c47-afeb-714712c6e4f9",
            price: 64992.36,
            price_sources: [],
            processed_ms: 1711301802554,
          },
        ],
        position_type: "FLAT",
        position_uuid: "84d5096c-8d59-444f-a0d7-bfe11633d8b8",
        return_at_close: 0.9940635478586096,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 163563.63666667478,
        close_ms: 1711348208922,
        current_return: 1.0021144134890227,
        initial_entry_price: 66740.58,
        is_closed_position: true,
        miner_hotkey: "5F4RTc8mpCMDf4zRSxTTZEew34ZKBBk6Sd2vDz64uKUNC5iA",
        net_leverage: 0.0,
        open_ms: 1711329013874,
        orders: [
          {
            leverage: -1.25,
            order_type: "SHORT",
            order_uuid: "e94cc5c1-67be-4824-9840-a387f41c406c",
            price: 66740.58,
            price_sources: [],
            processed_ms: 1711329013873,
          },
          {
            leverage: 0.625,
            order_type: "LONG",
            order_uuid: "b774e0ec-4fd2-456a-bd6b-ac7c4c1230dc",
            price: 66633.54,
            price_sources: [],
            processed_ms: 1711329909178,
          },
          {
            leverage: 0.3125,
            order_type: "LONG",
            order_uuid: "5cb51aa2-2492-442c-9c7e-f5716eb8e74b",
            price: 66480.83,
            price_sources: [],
            processed_ms: 1711331720949,
          },
          {
            leverage: 0.125,
            order_type: "LONG",
            order_uuid: "364e646b-4035-4183-b018-6cf0a323529c",
            price: 66612.56,
            price_sources: [],
            processed_ms: 1711333543997,
          },
          {
            leverage: 0.09375,
            order_type: "LONG",
            order_uuid: "442914e5-a443-4bc2-a1a6-a45317fcfe6a",
            price: 66689.34,
            price_sources: [],
            processed_ms: 1711335406411,
          },
          {
            leverage: 0.046875,
            order_type: "LONG",
            order_uuid: "e26b1ec5-bfa0-433e-a9dd-c521568c822d",
            price: 66828.12,
            price_sources: [],
            processed_ms: 1711337212920,
          },
          {
            leverage: 0.0234375,
            order_type: "LONG",
            order_uuid: "33ab8a8d-cdab-4ddb-b3ff-382632146376",
            price: 67209.79,
            price_sources: [],
            processed_ms: 1711339046403,
          },
          {
            leverage: 0.01171875,
            order_type: "LONG",
            order_uuid: "d5ff57bf-52a7-4678-9c47-b095d6a296cd",
            price: 67104.61,
            price_sources: [],
            processed_ms: 1711340892013,
          },
          {
            leverage: 0.005859375,
            order_type: "LONG",
            order_uuid: "40eeb05c-0286-4826-afd9-a211d019c1ad",
            price: 67525.3,
            price_sources: [],
            processed_ms: 1711342710892,
          },
          {
            leverage: 0.0029296875,
            order_type: "LONG",
            order_uuid: "5f4be5e4-067f-427e-8ef7-2e5aceb832e4",
            price: 67454.58,
            price_sources: [],
            processed_ms: 1711344545837,
          },
          {
            leverage: 0.00146484375,
            order_type: "LONG",
            order_uuid: "5a8fcc6c-836f-4a5b-a3cd-41c2a516a31b",
            price: 67417.23,
            price_sources: [],
            processed_ms: 1711346382644,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "eb68c3f4-2db4-4513-894a-a98fad9bb610",
            price: 67227.64,
            price_sources: [],
            processed_ms: 1711348208922,
          },
        ],
        position_type: "FLAT",
        position_uuid: "1902bcc0-569c-41d1-9478-b448c166ca32",
        return_at_close: 0.9996091274553003,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: -612273.7800000067,
        close_ms: 1711397348732,
        current_return: 1.015507953335281,
        initial_entry_price: 68838.73,
        is_closed_position: true,
        miner_hotkey: "5F4RTc8mpCMDf4zRSxTTZEew34ZKBBk6Sd2vDz64uKUNC5iA",
        net_leverage: 0.0,
        open_ms: 1711375944891,
        orders: [
          {
            leverage: 1.25,
            order_type: "LONG",
            order_uuid: "814ebb7c-433b-44ee-a45a-e0099f7eb559",
            price: 68838.73,
            price_sources: [],
            processed_ms: 1711375944890,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "3f24484f-6fac-459d-b4f2-ce46f315577d",
            price: 68868.54,
            price_sources: [],
            processed_ms: 1711376452648,
          },
          {
            leverage: -1.2,
            order_type: "SHORT",
            order_uuid: "093c36d7-e286-43ef-81c2-79c8fb5d5b80",
            price: 69210.38,
            price_sources: [],
            processed_ms: 1711376775235,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "7f22c7be-5871-4a5f-9615-7b0197e252c1",
            price: 69095.56,
            price_sources: [],
            processed_ms: 1711377931453,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "e8383685-b235-4edd-9764-50ca45ddb4c2",
            price: 69061.84,
            price_sources: [],
            processed_ms: 1711378207647,
          },
          {
            leverage: -0.4,
            order_type: "SHORT",
            order_uuid: "82c8f826-13da-47aa-a7fb-49c825d11137",
            price: 69479.79,
            price_sources: [],
            processed_ms: 1711380991423,
          },
          {
            leverage: -0.2,
            order_type: "SHORT",
            order_uuid: "189db34e-665b-4fc2-b48a-2a646a94e714",
            price: 69849.98,
            price_sources: [],
            processed_ms: 1711382813299,
          },
          {
            leverage: -0.1,
            order_type: "SHORT",
            order_uuid: "1c1e16a9-ec02-4f2f-8daa-8ed46138e1cf",
            price: 70067.4,
            price_sources: [],
            processed_ms: 1711384631074,
          },
          {
            leverage: -0.05,
            order_type: "SHORT",
            order_uuid: "84be3706-89a1-4e81-88c9-9c437ec06435",
            price: 70446.12,
            price_sources: [],
            processed_ms: 1711386443238,
          },
          {
            leverage: -0.025,
            order_type: "SHORT",
            order_uuid: "5fc716d8-a263-4a19-843b-e69814d370fa",
            price: 70465.33,
            price_sources: [],
            processed_ms: 1711388264913,
          },
          {
            leverage: -0.0125,
            order_type: "SHORT",
            order_uuid: "ee22ec29-4c61-4ff9-b911-5529579749e4",
            price: 70686.6,
            price_sources: [],
            processed_ms: 1711390081140,
          },
          {
            leverage: -0.00625,
            order_type: "SHORT",
            order_uuid: "e2d1b864-99e7-424e-86cf-b4c7cfe32be0",
            price: 70522.08,
            price_sources: [],
            processed_ms: 1711391894316,
          },
          {
            leverage: -0.003125,
            order_type: "SHORT",
            order_uuid: "c0255126-1d72-4b72-bcb2-ac4006badfdd",
            price: 70961.57,
            price_sources: [],
            processed_ms: 1711393729639,
          },
          {
            leverage: -0.0015625,
            order_type: "SHORT",
            order_uuid: "b3d9abd4-4f70-4079-80f3-a78175ec3434",
            price: 70673.68,
            price_sources: [],
            processed_ms: 1711395536500,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "87356c5f-a1ac-4bc9-9882-dd554fa5d1c3",
            price: 70956.82,
            price_sources: [],
            processed_ms: 1711397348732,
          },
        ],
        position_type: "FLAT",
        position_uuid: "ab0c2ad4-80c7-4d73-8447-5a4cbd25b496",
        return_at_close: 1.0124614294752752,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: -59206.85000001049,
        close_ms: 1711422792087,
        current_return: 1.0022537346173803,
        initial_entry_price: 70158.37,
        is_closed_position: true,
        miner_hotkey: "5F4RTc8mpCMDf4zRSxTTZEew34ZKBBk6Sd2vDz64uKUNC5iA",
        net_leverage: 0.0,
        open_ms: 1711402809136,
        orders: [
          {
            leverage: 1.25,
            order_type: "LONG",
            order_uuid: "9fdf5d78-009f-45e8-b770-9c92312b1965",
            price: 70158.37,
            price_sources: [],
            processed_ms: 1711402809135,
          },
          {
            leverage: -0.625,
            order_type: "SHORT",
            order_uuid: "1331ee63-50cc-4bb9-a0cf-11abfb5592a0",
            price: 70219.66,
            price_sources: [],
            processed_ms: 1711404647858,
          },
          {
            leverage: -0.3125,
            order_type: "SHORT",
            order_uuid: "e56e3026-780c-4d0f-8413-23220275c81d",
            price: 70534.0,
            price_sources: [],
            processed_ms: 1711406449113,
          },
          {
            leverage: -0.15625,
            order_type: "SHORT",
            order_uuid: "df4c4352-bf8f-4c2a-a7d3-28beb0211635",
            price: 70347.78,
            price_sources: [],
            processed_ms: 1711408253418,
          },
          {
            leverage: -0.078125,
            order_type: "SHORT",
            order_uuid: "9cd2e3a6-cdfa-4409-bfd4-7580d9ce1580",
            price: 69922.61,
            price_sources: [],
            processed_ms: 1711410090900,
          },
          {
            leverage: -0.0390625,
            order_type: "SHORT",
            order_uuid: "ab7f4afc-81f2-45e5-abf0-13496013437c",
            price: 69951.06,
            price_sources: [],
            processed_ms: 1711411879038,
          },
          {
            leverage: -0.01953125,
            order_type: "SHORT",
            order_uuid: "11341135-2d6e-4df2-9d2a-3b95f491396a",
            price: 70138.96,
            price_sources: [],
            processed_ms: 1711413698242,
          },
          {
            leverage: -0.009765625,
            order_type: "SHORT",
            order_uuid: "4dae464b-5214-421c-9e5c-ea1cb4144df5",
            price: 70029.27,
            price_sources: [],
            processed_ms: 1711415533452,
          },
          {
            leverage: -0.0048828125,
            order_type: "SHORT",
            order_uuid: "a918b82b-4e0b-41e1-8f5b-abac18c6fceb",
            price: 70260.0,
            price_sources: [],
            processed_ms: 1711417345987,
          },
          {
            leverage: -0.00244140625,
            order_type: "SHORT",
            order_uuid: "2d528c24-f517-482a-a012-d33a6a0c9214",
            price: 70228.65,
            price_sources: [],
            processed_ms: 1711419165252,
          },
          {
            leverage: -0.001220703125,
            order_type: "SHORT",
            order_uuid: "a3ebb56e-a6a1-4b91-8347-b1eb3d79cedb",
            price: 70256.19,
            price_sources: [],
            processed_ms: 1711420995960,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "15f42339-9389-4ebf-91fa-beccf4de630b",
            price: 70323.7,
            price_sources: [],
            processed_ms: 1711422792087,
          },
        ],
        position_type: "FLAT",
        position_uuid: "d3246bda-3bf1-4fd7-846b-682b8931a645",
        return_at_close: 0.9997481002808369,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 161803.2133333362,
        close_ms: 1711493653160,
        current_return: 0.9956979172225722,
        initial_entry_price: 70233.09,
        is_closed_position: true,
        miner_hotkey: "5F4RTc8mpCMDf4zRSxTTZEew34ZKBBk6Sd2vDz64uKUNC5iA",
        net_leverage: 0.0,
        open_ms: 1711464576185,
        orders: [
          {
            leverage: 1.25,
            order_type: "LONG",
            order_uuid: "2a641b20-4433-4dc4-bcfe-c635c67ccbd7",
            price: 70233.09,
            price_sources: [],
            processed_ms: 1711464576184,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "ad1f7d55-7655-455f-904b-13f2203c20b7",
            price: 69762.15,
            price_sources: [],
            processed_ms: 1711466393411,
          },
          {
            leverage: -0.75,
            order_type: "SHORT",
            order_uuid: "09dea069-7092-4ed5-a9b7-4e24fd3d6f88",
            price: 70343.53,
            price_sources: [],
            processed_ms: 1711468213124,
          },
          {
            leverage: -0.375,
            order_type: "SHORT",
            order_uuid: "22a1c418-4b47-49d1-8ae0-8b96db819589",
            price: 69836.39,
            price_sources: [],
            processed_ms: 1711470079041,
          },
          {
            leverage: -0.1875,
            order_type: "SHORT",
            order_uuid: "0a27c561-9150-4542-bb27-c2a42771b23b",
            price: 69668.01,
            price_sources: [],
            processed_ms: 1711471845246,
          },
          {
            leverage: -0.09375,
            order_type: "SHORT",
            order_uuid: "8d64f4da-8a76-40a8-a4da-ef10c5b57c06",
            price: 69836.42,
            price_sources: [],
            processed_ms: 1711473667619,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "3560a8d8-de2d-4c42-886f-1e7a6eae9872",
            price: 70143.95,
            price_sources: [],
            processed_ms: 1711475495301,
          },
          {
            leverage: -0.171875,
            order_type: "SHORT",
            order_uuid: "4cbc0ec9-a3c4-44d9-a3fc-552592364003",
            price: 70252.23,
            price_sources: [],
            processed_ms: 1711477306724,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "f6adcc21-d383-466c-aede-06e4e74f39fe",
            price: 70338.43,
            price_sources: [],
            processed_ms: 1711479155101,
          },
          {
            leverage: -0.2109375,
            order_type: "SHORT",
            order_uuid: "f4c2d409-3de3-44f7-ba39-19dcbf52b8ec",
            price: 69736.81,
            price_sources: [],
            processed_ms: 1711480969754,
          },
          {
            leverage: -0.10546875,
            order_type: "SHORT",
            order_uuid: "6fc723ef-fff9-4e05-85b0-1d44c799186c",
            price: 69701.9,
            price_sources: [],
            processed_ms: 1711482755287,
          },
          {
            leverage: -0.052734375,
            order_type: "SHORT",
            order_uuid: "f5daeee1-8ea1-4d08-8d07-6f417879d7b5",
            price: 69704.13,
            price_sources: [],
            processed_ms: 1711484572205,
          },
          {
            leverage: -0.0263671875,
            order_type: "SHORT",
            order_uuid: "85f28778-2cab-4193-8aee-ddef790d417e",
            price: 69684.26,
            price_sources: [],
            processed_ms: 1711486388612,
          },
          {
            leverage: -0.01318359375,
            order_type: "SHORT",
            order_uuid: "03851f53-0177-4116-bf38-91b8f8bd2885",
            price: 69949.21,
            price_sources: [],
            processed_ms: 1711488208242,
          },
          {
            leverage: -0.006591796875,
            order_type: "SHORT",
            order_uuid: "12a16a20-9b0f-4b8b-b102-c460ec594587",
            price: 70024.76,
            price_sources: [],
            processed_ms: 1711490028241,
          },
          {
            leverage: -0.0032958984375,
            order_type: "SHORT",
            order_uuid: "1e125cbe-9fc3-4679-905d-c3c2e1689236",
            price: 69772.4,
            price_sources: [],
            processed_ms: 1711491837326,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "2af8ffe2-3c4d-44e3-9897-1088545d1abe",
            price: 70129.1,
            price_sources: [],
            processed_ms: 1711493653160,
          },
        ],
        position_type: "FLAT",
        position_uuid: "2750522b-3b1d-41ea-8f5a-205aa98f90ea",
        return_at_close: 0.9927108234709044,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 71298.46,
        close_ms: 1711548267694,
        current_return: 0.962041606508752,
        initial_entry_price: 71298.46,
        is_closed_position: true,
        miner_hotkey: "5F4RTc8mpCMDf4zRSxTTZEew34ZKBBk6Sd2vDz64uKUNC5iA",
        net_leverage: 0.0,
        open_ms: 1711545720856,
        orders: [
          {
            leverage: 1.25,
            order_type: "LONG",
            order_uuid: "aba47320-e0ce-4d50-acf7-e2df58d8d0bf",
            price: 71298.46,
            price_sources: [],
            processed_ms: 1711545720854,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "6dfc011d-f027-40ad-a3d0-c43e54a0d748",
            price: 69133.36,
            price_sources: [],
            processed_ms: 1711548267694,
          },
        ],
        position_type: "FLAT",
        position_uuid: "f6caf0dd-a603-4177-b147-6724e782346f",
        return_at_close: 0.9596365024924802,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 77344.4599999997,
        close_ms: 1711559260354,
        current_return: 0.9963155631616563,
        initial_entry_price: 69486.62,
        is_closed_position: true,
        miner_hotkey: "5F4RTc8mpCMDf4zRSxTTZEew34ZKBBk6Sd2vDz64uKUNC5iA",
        net_leverage: 0.0,
        open_ms: 1711549340308,
        orders: [
          {
            leverage: 1.25,
            order_type: "LONG",
            order_uuid: "1216f89d-292c-4d30-8863-77c48f474627",
            price: 69486.62,
            price_sources: [],
            processed_ms: 1711549340306,
          },
          {
            leverage: -0.625,
            order_type: "SHORT",
            order_uuid: "51ac2b06-1e44-4b22-abcb-d1a4f973ba65",
            price: 69313.02,
            price_sources: [],
            processed_ms: 1711552957094,
          },
          {
            leverage: -0.3125,
            order_type: "SHORT",
            order_uuid: "ec39f05a-f60d-41ce-b756-7130d8288430",
            price: 69307.07,
            price_sources: [],
            processed_ms: 1711553794068,
          },
          {
            leverage: -0.25,
            order_type: "SHORT",
            order_uuid: "e02c83ad-59c4-422f-9028-cc1adf2eba94",
            price: 69209.08,
            price_sources: [],
            processed_ms: 1711555613902,
          },
          {
            leverage: -0.03125,
            order_type: "SHORT",
            order_uuid: "12d922ce-e7e9-4687-99c9-89934dc42249",
            price: 69116.6,
            price_sources: [],
            processed_ms: 1711557429732,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "2a101624-f111-4cbf-88b2-319bbb99da1c",
            price: 69151.85,
            price_sources: [],
            processed_ms: 1711559260354,
          },
        ],
        position_type: "FLAT",
        position_uuid: "2d47e98e-8b3b-4fbc-8696-d725e94dcc83",
        return_at_close: 0.9938247742537522,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 66908.40999999993,
        close_ms: 1711577484184,
        current_return: 0.9891938030609236,
        initial_entry_price: 68823.01,
        is_closed_position: true,
        miner_hotkey: "5F4RTc8mpCMDf4zRSxTTZEew34ZKBBk6Sd2vDz64uKUNC5iA",
        net_leverage: 0.0,
        open_ms: 1711570194217,
        orders: [
          {
            leverage: -3.5,
            order_type: "SHORT",
            order_uuid: "d1b889f5-1e9f-4bfe-819a-b94d545f442d",
            price: 68823.01,
            price_sources: [],
            processed_ms: 1711570194216,
          },
          {
            leverage: 1.0499999999999998,
            order_type: "LONG",
            order_uuid: "478d7ad0-3d0f-446d-8a40-7bb94f011506",
            price: 68943.8,
            price_sources: [],
            processed_ms: 1711572019588,
          },
          {
            leverage: 1.05,
            order_type: "LONG",
            order_uuid: "547b7712-59a5-49a7-85e0-7ca009a6ecd4",
            price: 68975.64,
            price_sources: [],
            processed_ms: 1711573856242,
          },
          {
            leverage: 1.05,
            order_type: "LONG",
            order_uuid: "5894bd4c-ec07-49e8-bf1a-805927ae8afe",
            price: 69187.79,
            price_sources: [],
            processed_ms: 1711575668609,
          },
          {
            leverage: 1.05,
            order_type: "LONG",
            order_uuid: "abcd9d1c-0a59-4e15-998b-9d46783a9e1a",
            price: 69033.31,
            price_sources: [],
            processed_ms: 1711577484184,
          },
        ],
        position_type: "FLAT",
        position_uuid: "b492e073-f164-4f3b-916c-064067d8d801",
        return_at_close: 0.9822694464394972,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 69234.96999999997,
        close_ms: 1711586575875,
        current_return: 0.9986270330508191,
        initial_entry_price: 69328.69,
        is_closed_position: true,
        miner_hotkey: "5F4RTc8mpCMDf4zRSxTTZEew34ZKBBk6Sd2vDz64uKUNC5iA",
        net_leverage: 0.0,
        open_ms: 1711579299350,
        orders: [
          {
            leverage: -3.5,
            order_type: "SHORT",
            order_uuid: "3b308709-b8ff-4fdc-bf0e-bd57fe90c099",
            price: 69328.69,
            price_sources: [],
            processed_ms: 1711579299349,
          },
          {
            leverage: 1.0499999999999998,
            order_type: "LONG",
            order_uuid: "368d2476-7fc2-4f01-8b44-c25e45c57bfa",
            price: 69395.0,
            price_sources: [],
            processed_ms: 1711581143276,
          },
          {
            leverage: 1.05,
            order_type: "LONG",
            order_uuid: "643ce826-48f9-464a-b5bb-eb7225d31587",
            price: 69137.27,
            price_sources: [],
            processed_ms: 1711582945068,
          },
          {
            leverage: 1.05,
            order_type: "LONG",
            order_uuid: "7a8a3893-8727-49ac-b42b-620dc6dc0ba6",
            price: 69485.04,
            price_sources: [],
            processed_ms: 1711584758217,
          },
          {
            leverage: 1.05,
            order_type: "LONG",
            order_uuid: "87398d42-e6b4-4db0-b5f5-26979b7961e3",
            price: 69506.93,
            price_sources: [],
            processed_ms: 1711586575875,
          },
        ],
        position_type: "FLAT",
        position_uuid: "920df79f-79f1-4c5a-b760-ef479aec774d",
        return_at_close: 0.9916366438194634,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 71288.26571428562,
        close_ms: 1711597492938,
        current_return: 1.009881009415404,
        initial_entry_price: 69538.17,
        is_closed_position: true,
        miner_hotkey: "5F4RTc8mpCMDf4zRSxTTZEew34ZKBBk6Sd2vDz64uKUNC5iA",
        net_leverage: 0.0,
        open_ms: 1711588408194,
        orders: [
          {
            leverage: -3.5,
            order_type: "SHORT",
            order_uuid: "5c8a02b0-b23d-41ac-af22-3f42ddc6ca39",
            price: 69538.17,
            price_sources: [],
            processed_ms: 1711588408192,
          },
          {
            leverage: 1.0499999999999998,
            order_type: "LONG",
            order_uuid: "544e872d-631a-4b03-b4a4-74aa21ffb1cd",
            price: 69424.5,
            price_sources: [],
            processed_ms: 1711590224873,
          },
          {
            leverage: 1.225,
            order_type: "LONG",
            order_uuid: "b24204a0-782d-483e-a581-1d96b678fe4d",
            price: 69531.4,
            price_sources: [],
            processed_ms: 1711592030447,
          },
          {
            leverage: 0.6125,
            order_type: "LONG",
            order_uuid: "a5c99390-15fb-4903-8bbe-a82cf2279c65",
            price: 69009.65,
            price_sources: [],
            processed_ms: 1711593849196,
          },
          {
            leverage: 0.30625,
            order_type: "LONG",
            order_uuid: "ea09bb3f-c233-4843-ac42-4e32f332e417",
            price: 69261.92,
            price_sources: [],
            processed_ms: 1711595694680,
          },
          {
            leverage: 1.05,
            order_type: "LONG",
            order_uuid: "7bbb86cb-c0a7-457e-889b-63ac9372181a",
            price: 69044.65,
            price_sources: [],
            processed_ms: 1711597492938,
          },
        ],
        position_type: "FLAT",
        position_uuid: "f1023884-1452-4e17-afbd-15d05b794332",
        return_at_close: 1.0028118423494963,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 69743.25000000001,
        close_ms: 1711618497540,
        current_return: 0.9768287085641199,
        initial_entry_price: 69676.63,
        is_closed_position: true,
        miner_hotkey: "5F4RTc8mpCMDf4zRSxTTZEew34ZKBBk6Sd2vDz64uKUNC5iA",
        net_leverage: 0.0,
        open_ms: 1711601117128,
        orders: [
          {
            leverage: -3.5,
            order_type: "SHORT",
            order_uuid: "f87e4164-e6e7-47a1-991a-8e68746cd065",
            price: 69676.63,
            price_sources: [],
            processed_ms: 1711601117126,
          },
          {
            leverage: 1.75,
            order_type: "LONG",
            order_uuid: "fe268c0d-8031-4e65-aabd-e2490ee81ba0",
            price: 69610.01,
            price_sources: [],
            processed_ms: 1711602935994,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "36be24bd-ceb3-4fed-8486-0f43f9cfbeee",
            price: 70665.82,
            price_sources: [],
            processed_ms: 1711618497540,
          },
        ],
        position_type: "FLAT",
        position_uuid: "483c8baa-7788-473f-8ce4-85abf9a214ec",
        return_at_close: 0.9699909076041711,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 70856.15,
        close_ms: 1711624353986,
        current_return: 0.9981767990499059,
        initial_entry_price: 70856.15,
        is_closed_position: true,
        miner_hotkey: "5F4RTc8mpCMDf4zRSxTTZEew34ZKBBk6Sd2vDz64uKUNC5iA",
        net_leverage: 0.0,
        open_ms: 1711624219545,
        orders: [
          {
            leverage: -3.5,
            order_type: "SHORT",
            order_uuid: "7a4b846b-913c-4886-998a-1476c4c1db9b",
            price: 70856.15,
            price_sources: [],
            processed_ms: 1711624219543,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "b60b5d18-37e3-4ff3-a6ef-3a7832e0e4eb",
            price: 70893.06,
            price_sources: [],
            processed_ms: 1711624353986,
          },
        ],
        position_type: "FLAT",
        position_uuid: "5a38710c-98d7-4603-8491-f0534307ff88",
        return_at_close: 0.9911895614565566,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 70800.17,
        close_ms: 1711633417007,
        current_return: 0.9930113868370657,
        initial_entry_price: 70800.17,
        is_closed_position: true,
        miner_hotkey: "5F4RTc8mpCMDf4zRSxTTZEew34ZKBBk6Sd2vDz64uKUNC5iA",
        net_leverage: 0.0,
        open_ms: 1711633330112,
        orders: [
          {
            leverage: -3.5,
            order_type: "SHORT",
            order_uuid: "c26516bc-15fe-44fc-9003-3fcdb176ae45",
            price: 70800.17,
            price_sources: [],
            processed_ms: 1711633330110,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "1b1e562d-9c3b-4e49-8ef1-88ef0d6e668d",
            price: 70941.54,
            price_sources: [],
            processed_ms: 1711633417007,
          },
        ],
        position_type: "FLAT",
        position_uuid: "15253abd-8fdf-437a-9387-38c4b3309189",
        return_at_close: 0.9860603071292062,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
    ],
    thirty_day_returns: 1.0,
  },
  "5F4pH5CwrH2ykJLMew6Fx7zhk6HSPC1TUVCFBs7N3nkigqGu": {
    all_time_returns: 1.0,
    n_positions: 0,
    percentage_profitable: 0.0,
    positions: [
      {
        average_entry_price: 64440.509999999995,
        close_ms: 1711196801571,
        current_return: 1.0024578017771741,
        initial_entry_price: 64440.51,
        is_closed_position: true,
        miner_hotkey: "5F4pH5CwrH2ykJLMew6Fx7zhk6HSPC1TUVCFBs7N3nkigqGu",
        net_leverage: 0.0,
        open_ms: 1711193268089,
        orders: [
          {
            leverage: 0.6,
            order_type: "LONG",
            order_uuid: "eb675f7a-f67a-405b-b59d-c361f9a72e8d",
            price: 64440.51,
            price_sources: [],
            processed_ms: 1711193268088,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "cc9e2824-fe37-4b34-9ba6-1dec3236f797",
            price: 64704.48,
            price_sources: [],
            processed_ms: 1711196801571,
          },
        ],
        position_type: "FLAT",
        position_uuid: "01abfb90-c367-4d2d-ac70-b04e779cdd03",
        return_at_close: 1.0012548524150415,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 64687.020000000004,
        close_ms: 1711200466777,
        current_return: 0.9984168694121324,
        initial_entry_price: 64687.02,
        is_closed_position: true,
        miner_hotkey: "5F4pH5CwrH2ykJLMew6Fx7zhk6HSPC1TUVCFBs7N3nkigqGu",
        net_leverage: 0.0,
        open_ms: 1711196910817,
        orders: [
          {
            leverage: 0.6,
            order_type: "LONG",
            order_uuid: "a967c366-251f-4073-ac7a-448cfc33a08a",
            price: 64687.02,
            price_sources: [],
            processed_ms: 1711196910816,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "90a89ec3-948f-4fff-b122-3fb051aa00cc",
            price: 64516.34,
            price_sources: [],
            processed_ms: 1711200466777,
          },
        ],
        position_type: "FLAT",
        position_uuid: "c06d1933-2c0a-4df5-95a3-b3975cf3aa1a",
        return_at_close: 0.9972187691688378,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 64545.81,
        close_ms: 1711204124602,
        current_return: 1.0020413408709257,
        initial_entry_price: 64545.81,
        is_closed_position: true,
        miner_hotkey: "5F4pH5CwrH2ykJLMew6Fx7zhk6HSPC1TUVCFBs7N3nkigqGu",
        net_leverage: 0.0,
        open_ms: 1711200577037,
        orders: [
          {
            leverage: 0.6,
            order_type: "LONG",
            order_uuid: "01bbea75-2a49-4cbf-b9a0-cc0ce45a120f",
            price: 64545.81,
            price_sources: [],
            processed_ms: 1711200577037,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "2b033841-4b2b-4e76-a926-df4f6389834f",
            price: 64765.41,
            price_sources: [],
            processed_ms: 1711204124602,
          },
        ],
        position_type: "FLAT",
        position_uuid: "f3a9ca0b-e495-45b3-9233-44827a31817c",
        return_at_close: 1.0008388912618806,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 64815.43000000001,
        close_ms: 1711207790586,
        current_return: 0.9998473511631412,
        initial_entry_price: 64815.43,
        is_closed_position: true,
        miner_hotkey: "5F4pH5CwrH2ykJLMew6Fx7zhk6HSPC1TUVCFBs7N3nkigqGu",
        net_leverage: 0.0,
        open_ms: 1711204242578,
        orders: [
          {
            leverage: 0.6,
            order_type: "LONG",
            order_uuid: "9255629b-d3aa-4679-a58d-d8daf95fdb56",
            price: 64815.43,
            price_sources: [],
            processed_ms: 1711204242577,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "09e88213-2b0d-4a17-b577-15933ce3f1b3",
            price: 64798.94,
            price_sources: [],
            processed_ms: 1711207790586,
          },
        ],
        position_type: "FLAT",
        position_uuid: "778a390a-48ce-4cb7-b65b-370841f6bcaf",
        return_at_close: 0.9986475343417455,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 64829.079999999994,
        close_ms: 1711211451680,
        current_return: 1.004117411507305,
        initial_entry_price: 64829.08,
        is_closed_position: true,
        miner_hotkey: "5F4pH5CwrH2ykJLMew6Fx7zhk6HSPC1TUVCFBs7N3nkigqGu",
        net_leverage: 0.0,
        open_ms: 1711207909275,
        orders: [
          {
            leverage: 0.6,
            order_type: "LONG",
            order_uuid: "a1f557c8-2ba5-410e-be54-ab1b96c3e2e7",
            price: 64829.08,
            price_sources: [],
            processed_ms: 1711207909274,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "d10ff8fc-69b6-4c13-94e3-31780eff65ea",
            price: 65273.96,
            price_sources: [],
            processed_ms: 1711211451680,
          },
        ],
        position_type: "FLAT",
        position_uuid: "017acebe-1852-438d-b011-6090f019f2cc",
        return_at_close: 1.0029124706134962,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 65447.28999999999,
        close_ms: 1711215114650,
        current_return: 1.0034659341891774,
        initial_entry_price: 65447.29,
        is_closed_position: true,
        miner_hotkey: "5F4pH5CwrH2ykJLMew6Fx7zhk6HSPC1TUVCFBs7N3nkigqGu",
        net_leverage: 0.0,
        open_ms: 1711211569675,
        orders: [
          {
            leverage: 0.6,
            order_type: "LONG",
            order_uuid: "6b84f883-133e-4196-a23f-e20143cf88df",
            price: 65447.29,
            price_sources: [],
            processed_ms: 1711211569674,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "18d3cc8f-0e91-4287-9e29-1c875f4b99f5",
            price: 65825.35,
            price_sources: [],
            processed_ms: 1711215114650,
          },
        ],
        position_type: "FLAT",
        position_uuid: "e73d6b01-6e6d-44ab-938f-679685bb0647",
        return_at_close: 1.0022617750681504,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 65897.21,
        close_ms: 1711218778774,
        current_return: 0.9969419646142834,
        initial_entry_price: 65897.21,
        is_closed_position: true,
        miner_hotkey: "5F4pH5CwrH2ykJLMew6Fx7zhk6HSPC1TUVCFBs7N3nkigqGu",
        net_leverage: 0.0,
        open_ms: 1711215224171,
        orders: [
          {
            leverage: 0.6,
            order_type: "LONG",
            order_uuid: "370dcf08-8b7b-4304-aa8f-899fe26ea00a",
            price: 65897.21,
            price_sources: [],
            processed_ms: 1711215224170,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "97c0bf37-6ba6-46fa-8a38-5f46f7228451",
            price: 65561.35,
            price_sources: [],
            processed_ms: 1711218778774,
          },
        ],
        position_type: "FLAT",
        position_uuid: "81698738-5614-4b82-95ed-3a5f08c6d63f",
        return_at_close: 0.9957456342567463,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 65509.2,
        close_ms: 1711222438256,
        current_return: 0.995726035427085,
        initial_entry_price: 65509.2,
        is_closed_position: true,
        miner_hotkey: "5F4pH5CwrH2ykJLMew6Fx7zhk6HSPC1TUVCFBs7N3nkigqGu",
        net_leverage: 0.0,
        open_ms: 1711218864384,
        orders: [
          {
            leverage: 0.6,
            order_type: "LONG",
            order_uuid: "6e649310-c6e7-4493-a715-a5d0c1d27126",
            price: 65509.2,
            price_sources: [],
            processed_ms: 1711218864383,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "131f7f9b-e52b-4bbb-84c6-d363b717a647",
            price: 65042.56,
            price_sources: [],
            processed_ms: 1711222438256,
          },
        ],
        position_type: "FLAT",
        position_uuid: "49d1964b-8044-434a-9ba7-326934a3dc4d",
        return_at_close: 0.9945311641845725,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 65085.12999999999,
        close_ms: 1711226096385,
        current_return: 0.99920516406743,
        initial_entry_price: 65085.13,
        is_closed_position: true,
        miner_hotkey: "5F4pH5CwrH2ykJLMew6Fx7zhk6HSPC1TUVCFBs7N3nkigqGu",
        net_leverage: 0.0,
        open_ms: 1711222541394,
        orders: [
          {
            leverage: 0.6,
            order_type: "LONG",
            order_uuid: "0a0d222a-b8aa-46ac-bafa-13b74e3a5743",
            price: 65085.13,
            price_sources: [],
            processed_ms: 1711222541393,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "a5aa070d-d19e-4417-a71c-b21c1a520994",
            price: 64998.91,
            price_sources: [],
            processed_ms: 1711226096385,
          },
        ],
        position_type: "FLAT",
        position_uuid: "bd2a2941-6539-4994-b098-4543535dd75b",
        return_at_close: 0.998006117870549,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 64998.41,
        close_ms: 1711229766449,
        current_return: 0.9998343959490701,
        initial_entry_price: 64998.41,
        is_closed_position: true,
        miner_hotkey: "5F4pH5CwrH2ykJLMew6Fx7zhk6HSPC1TUVCFBs7N3nkigqGu",
        net_leverage: 0.0,
        open_ms: 1711226184756,
        orders: [
          {
            leverage: 0.6,
            order_type: "LONG",
            order_uuid: "6ae84e02-d401-4117-9421-0d800eefcd13",
            price: 64998.41,
            price_sources: [],
            processed_ms: 1711226184755,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "7a8009ac-db65-4ed2-9fd0-47c5bc16d73a",
            price: 64980.47,
            price_sources: [],
            processed_ms: 1711229766449,
          },
        ],
        position_type: "FLAT",
        position_uuid: "40c61125-53fa-4c1f-89ff-3eaf71b22277",
        return_at_close: 0.9986345946739312,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 64855.34,
        close_ms: 1711233437342,
        current_return: 1.0014440445459079,
        initial_entry_price: 64855.34,
        is_closed_position: true,
        miner_hotkey: "5F4pH5CwrH2ykJLMew6Fx7zhk6HSPC1TUVCFBs7N3nkigqGu",
        net_leverage: 0.0,
        open_ms: 1711229852513,
        orders: [
          {
            leverage: 0.6,
            order_type: "LONG",
            order_uuid: "ef1b42de-0cbb-4283-aa18-35c4fb338994",
            price: 64855.34,
            price_sources: [],
            processed_ms: 1711229852512,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "00df1c01-dfec-445e-ba7b-5548b3ce5e2f",
            price: 65011.43,
            price_sources: [],
            processed_ms: 1711233437342,
          },
        ],
        position_type: "FLAT",
        position_uuid: "7a78936c-d41a-4c89-ae4b-2ca4da7e7fc0",
        return_at_close: 1.0002423116924528,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 65019.55000000001,
        close_ms: 1711237105765,
        current_return: 0.9945883353545202,
        initial_entry_price: 65019.55,
        is_closed_position: true,
        miner_hotkey: "5F4pH5CwrH2ykJLMew6Fx7zhk6HSPC1TUVCFBs7N3nkigqGu",
        net_leverage: 0.0,
        open_ms: 1711233524372,
        orders: [
          {
            leverage: 0.6,
            order_type: "LONG",
            order_uuid: "626f6538-c649-40f2-bef8-c6a6435a5689",
            price: 65019.55,
            price_sources: [],
            processed_ms: 1711233524371,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "7289065d-2a30-40fa-be86-f240199d0032",
            price: 64433.11,
            price_sources: [],
            processed_ms: 1711237105765,
          },
        ],
        position_type: "FLAT",
        position_uuid: "3dbdfc09-3ebf-473a-9f1a-2129048f9e9b",
        return_at_close: 0.9933948293520948,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 64399.55000000001,
        close_ms: 1711240754667,
        current_return: 1.000528078224149,
        initial_entry_price: 64399.55,
        is_closed_position: true,
        miner_hotkey: "5F4pH5CwrH2ykJLMew6Fx7zhk6HSPC1TUVCFBs7N3nkigqGu",
        net_leverage: 0.0,
        open_ms: 1711237209827,
        orders: [
          {
            leverage: 0.6,
            order_type: "LONG",
            order_uuid: "ac23da95-08a7-4368-8801-f8571296f77d",
            price: 64399.55,
            price_sources: [],
            processed_ms: 1711237209826,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "62d7ac5d-8b15-4a20-baec-39f8368849c4",
            price: 64456.23,
            price_sources: [],
            processed_ms: 1711240754667,
          },
        ],
        position_type: "FLAT",
        position_uuid: "69fef9b6-54a0-4d5b-9e6d-f0a5fb54de93",
        return_at_close: 0.9993274445302801,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 64469.83,
        close_ms: 1711244416464,
        current_return: 1.0004081909320997,
        initial_entry_price: 64469.83,
        is_closed_position: true,
        miner_hotkey: "5F4pH5CwrH2ykJLMew6Fx7zhk6HSPC1TUVCFBs7N3nkigqGu",
        net_leverage: 0.0,
        open_ms: 1711240885618,
        orders: [
          {
            leverage: 0.6,
            order_type: "LONG",
            order_uuid: "ab5a5414-c6b0-4836-bae3-ffe417b38b3f",
            price: 64469.83,
            price_sources: [],
            processed_ms: 1711240885617,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "6d913f33-79e0-4725-8272-703f4706435f",
            price: 64513.69,
            price_sources: [],
            processed_ms: 1711244416464,
          },
        ],
        position_type: "FLAT",
        position_uuid: "f23fb41d-ba9d-416e-b217-bb2621d1a148",
        return_at_close: 0.9992077011029812,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 64513.189999999995,
        close_ms: 1711248075862,
        current_return: 0.9985031898128119,
        initial_entry_price: 64513.19,
        is_closed_position: true,
        miner_hotkey: "5F4pH5CwrH2ykJLMew6Fx7zhk6HSPC1TUVCFBs7N3nkigqGu",
        net_leverage: 0.0,
        open_ms: 1711244503274,
        orders: [
          {
            leverage: 0.6,
            order_type: "LONG",
            order_uuid: "10469bb8-d37f-43ed-aab2-377e8e841353",
            price: 64513.19,
            price_sources: [],
            processed_ms: 1711244503272,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "8259b497-9be4-4cdd-92a9-6cc14504282e",
            price: 64352.25,
            price_sources: [],
            processed_ms: 1711248075862,
          },
        ],
        position_type: "FLAT",
        position_uuid: "c0548c29-d300-4e02-a477-7d1c60640e51",
        return_at_close: 0.9973049859850366,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 64325.149999999994,
        close_ms: 1711251745502,
        current_return: 0.9980851346635026,
        initial_entry_price: 64325.15,
        is_closed_position: true,
        miner_hotkey: "5F4pH5CwrH2ykJLMew6Fx7zhk6HSPC1TUVCFBs7N3nkigqGu",
        net_leverage: 0.0,
        open_ms: 1711248162349,
        orders: [
          {
            leverage: 0.6,
            order_type: "LONG",
            order_uuid: "f9d188bb-3dde-405f-a814-ea1ba9a54356",
            price: 64325.15,
            price_sources: [],
            processed_ms: 1711248162348,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "28addc52-b817-49cd-acb1-dd932fe3e5c0",
            price: 64119.86,
            price_sources: [],
            processed_ms: 1711251745502,
          },
        ],
        position_type: "FLAT",
        position_uuid: "ede3a771-8593-48a7-ae67-26cbd2dc90d0",
        return_at_close: 0.9968874325019064,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 64086.850000000006,
        close_ms: 1711255407393,
        current_return: 0.9994012812300807,
        initial_entry_price: 64086.85,
        is_closed_position: true,
        miner_hotkey: "5F4pH5CwrH2ykJLMew6Fx7zhk6HSPC1TUVCFBs7N3nkigqGu",
        net_leverage: 0.0,
        open_ms: 1711251832420,
        orders: [
          {
            leverage: 0.6,
            order_type: "LONG",
            order_uuid: "116d7656-9b33-4840-917c-8a5f6d0eb17a",
            price: 64086.85,
            price_sources: [],
            processed_ms: 1711251832418,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "85a603a2-44b6-4e7f-a97a-4075450179aa",
            price: 64022.9,
            price_sources: [],
            processed_ms: 1711255407393,
          },
        ],
        position_type: "FLAT",
        position_uuid: "26e14b6b-e2f7-4663-a3f6-8053741f135d",
        return_at_close: 0.9982019996926046,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 64077.99999999999,
        close_ms: 1711259565517,
        current_return: 1.0026946533911796,
        initial_entry_price: 64078.0,
        is_closed_position: true,
        miner_hotkey: "5F4pH5CwrH2ykJLMew6Fx7zhk6HSPC1TUVCFBs7N3nkigqGu",
        net_leverage: 0.0,
        open_ms: 1711255494457,
        orders: [
          {
            leverage: 0.6,
            order_type: "LONG",
            order_uuid: "538c5329-4f73-4c91-bd6a-bbe0b98aa30d",
            price: 64078.0,
            price_sources: [],
            processed_ms: 1711255494456,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "f02c7d2b-a7b8-4588-a9b7-4cd58dda46df",
            price: 64365.78,
            price_sources: [],
            processed_ms: 1711259565517,
          },
        ],
        position_type: "FLAT",
        position_uuid: "f5b67802-fd77-40fc-95eb-84acf8a5377c",
        return_at_close: 1.00149141980711,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 64140.61,
        close_ms: 1711267758082,
        current_return: 1.006043035761587,
        initial_entry_price: 64140.61,
        is_closed_position: true,
        miner_hotkey: "5F4pH5CwrH2ykJLMew6Fx7zhk6HSPC1TUVCFBs7N3nkigqGu",
        net_leverage: 0.0,
        open_ms: 1711264187824,
        orders: [
          {
            leverage: 0.7,
            order_type: "LONG",
            order_uuid: "1a9c22e2-ab2a-4b57-bac9-725ef11c6da4",
            price: 64140.61,
            price_sources: [],
            processed_ms: 1711264187823,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "c93e5d2a-a193-48f7-82d8-d1219d660fd4",
            price: 64694.33,
            price_sources: [],
            processed_ms: 1711267758082,
          },
        ],
        position_type: "FLAT",
        position_uuid: "3a41c8dd-214e-447b-9360-a6363ca64cdc",
        return_at_close: 1.004634575511521,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 64963.62,
        close_ms: 1711278702585,
        current_return: 1.0005175358146605,
        initial_entry_price: 64963.62,
        is_closed_position: true,
        miner_hotkey: "5F4pH5CwrH2ykJLMew6Fx7zhk6HSPC1TUVCFBs7N3nkigqGu",
        net_leverage: 0.0,
        open_ms: 1711275130379,
        orders: [
          {
            leverage: 0.7,
            order_type: "LONG",
            order_uuid: "781ef48e-c609-4ee0-a84b-5b5d739c414a",
            price: 64963.62,
            price_sources: [],
            processed_ms: 1711275130377,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "d19b217e-2d4b-4198-846a-6bc772450d35",
            price: 65011.65,
            price_sources: [],
            processed_ms: 1711278702585,
          },
        ],
        position_type: "FLAT",
        position_uuid: "28943992-8057-490d-baef-1468f47ece47",
        return_at_close: 0.99911681126452,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 65013.77,
        close_ms: 1711282538922,
        current_return: 1.0021817070445231,
        initial_entry_price: 65013.77,
        is_closed_position: true,
        miner_hotkey: "5F4pH5CwrH2ykJLMew6Fx7zhk6HSPC1TUVCFBs7N3nkigqGu",
        net_leverage: 0.0,
        open_ms: 1711278789319,
        orders: [
          {
            leverage: 0.7,
            order_type: "LONG",
            order_uuid: "18e4a0b6-2736-449b-8f45-49af6dfdfdc6",
            price: 65013.77,
            price_sources: [],
            processed_ms: 1711278789318,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "ee23e7f2-aef0-4334-916c-fa9dbb0e031a",
            price: 65216.4,
            price_sources: [],
            processed_ms: 1711282538922,
          },
        ],
        position_type: "FLAT",
        position_uuid: "4e55d50b-e10d-46df-b845-727dbae587d2",
        return_at_close: 1.000778652654661,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 65527.427142857145,
        close_ms: 1711299979598,
        current_return: 1.0836679079521285,
        initial_entry_price: 65681.91,
        is_closed_position: true,
        miner_hotkey: "5F4pH5CwrH2ykJLMew6Fx7zhk6HSPC1TUVCFBs7N3nkigqGu",
        net_leverage: 0.0,
        open_ms: 1711295386580,
        orders: [
          {
            leverage: -1.1,
            order_type: "SHORT",
            order_uuid: "5220cbaa-9ced-43ed-b4f5-b9e22cfaab75",
            price: 65681.91,
            price_sources: [],
            processed_ms: 1711295386579,
          },
          {
            leverage: -2.2,
            order_type: "SHORT",
            order_uuid: "40de8547-fef5-448c-aa7a-330302d4372e",
            price: 65675.38,
            price_sources: [],
            processed_ms: 1711297212508,
          },
          {
            leverage: -4.4,
            order_type: "SHORT",
            order_uuid: "1f301c2b-2fb6-4bff-9617-7dfd1740719c",
            price: 65414.83,
            price_sources: [],
            processed_ms: 1711299068074,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "3d9d93f2-a6e7-4387-8efa-21e391419afc",
            price: 64813.73,
            price_sources: [],
            processed_ms: 1711299979598,
          },
        ],
        position_type: "FLAT",
        position_uuid: "59979168-359b-4eda-bfd1-82e82ddaf447",
        return_at_close: 1.0669794221696658,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 65218.44296296296,
        close_ms: 1711307068328,
        current_return: 0.9706019038744812,
        initial_entry_price: 65035.8,
        is_closed_position: true,
        miner_hotkey: "5F4pH5CwrH2ykJLMew6Fx7zhk6HSPC1TUVCFBs7N3nkigqGu",
        net_leverage: 0.0,
        open_ms: 1711300871489,
        orders: [
          {
            leverage: -1.1,
            order_type: "SHORT",
            order_uuid: "1fcd707d-f560-4eba-9b53-8050373bcac4",
            price: 65035.8,
            price_sources: [],
            processed_ms: 1711300871487,
          },
          {
            leverage: -2.2,
            order_type: "SHORT",
            order_uuid: "66b526c2-de34-4f42-9acd-26b875562bd0",
            price: 65371.65,
            price_sources: [],
            processed_ms: 1711302704628,
          },
          {
            leverage: 0.10999999999999988,
            order_type: "LONG",
            order_uuid: "4a891fcb-7676-4a7c-a861-4cc14a815ec0",
            price: 65545.22,
            price_sources: [],
            processed_ms: 1711304684314,
          },
          {
            leverage: 0.10999999999999988,
            order_type: "LONG",
            order_uuid: "e2c4e45b-0157-4249-bad4-c97df2807799",
            price: 65641.91,
            price_sources: [],
            processed_ms: 1711305617717,
          },
          {
            leverage: 0.10999999999999988,
            order_type: "LONG",
            order_uuid: "b29a8d83-217d-45fd-851e-4f393818ad9d",
            price: 65705.91,
            price_sources: [],
            processed_ms: 1711306549438,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "4b79b68a-6739-4dcd-aa01-4370885667c3",
            price: 65862.19,
            price_sources: [],
            processed_ms: 1711307068328,
          },
        ],
        position_type: "FLAT",
        position_uuid: "b8fc2c7e-bbe6-4e22-a256-2e30d05cd23f",
        return_at_close: 0.9641959313089096,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 48892.02999999995,
        close_ms: 1711317739518,
        current_return: 0.992637153009936,
        initial_entry_price: 65732.79,
        is_closed_position: true,
        miner_hotkey: "5F4pH5CwrH2ykJLMew6Fx7zhk6HSPC1TUVCFBs7N3nkigqGu",
        net_leverage: 0.0,
        open_ms: 1711307475995,
        orders: [
          {
            leverage: -1.1,
            order_type: "SHORT",
            order_uuid: "24be7de7-88c7-45a7-999b-1fc7bce69f6c",
            price: 65732.79,
            price_sources: [],
            processed_ms: 1711307475993,
          },
          {
            leverage: 0.10999999999999999,
            order_type: "LONG",
            order_uuid: "ed8a590b-8ea1-4039-8b28-5470313dc5e7",
            price: 65798.03,
            price_sources: [],
            processed_ms: 1711308412395,
          },
          {
            leverage: 0.10999999999999999,
            order_type: "LONG",
            order_uuid: "2a00cec8-2d1e-40c1-9a07-9c55d8e12066",
            price: 65860.82,
            price_sources: [],
            processed_ms: 1711309343276,
          },
          {
            leverage: 0.10999999999999999,
            order_type: "LONG",
            order_uuid: "0b85ef5f-6c69-4037-88f2-1f42d11dc822",
            price: 65889.76,
            price_sources: [],
            processed_ms: 1711310281430,
          },
          {
            leverage: 0.10999999999999999,
            order_type: "LONG",
            order_uuid: "18603a12-8705-4438-84e7-9ede406a64b9",
            price: 66221.84,
            price_sources: [],
            processed_ms: 1711311214447,
          },
          {
            leverage: 0.10999999999999999,
            order_type: "LONG",
            order_uuid: "98e30823-daf6-4a35-a0c8-cbf290f75931",
            price: 66364.48,
            price_sources: [],
            processed_ms: 1711312139351,
          },
          {
            leverage: 0.10999999999999999,
            order_type: "LONG",
            order_uuid: "b27c3d35-60d0-43cd-9154-43eb0aa96d6b",
            price: 66248.55,
            price_sources: [],
            processed_ms: 1711313092294,
          },
          {
            leverage: 0.10999999999999999,
            order_type: "LONG",
            order_uuid: "d3a5e940-c546-4462-8590-b077d92beaac",
            price: 66134.33,
            price_sources: [],
            processed_ms: 1711314007565,
          },
          {
            leverage: 0.1650000000000001,
            order_type: "LONG",
            order_uuid: "100cc05f-a43a-4589-a29f-bed85845613b",
            price: 66382.24,
            price_sources: [],
            processed_ms: 1711314946213,
          },
          {
            leverage: 0.11000000000000001,
            order_type: "LONG",
            order_uuid: "5658962f-9d5b-4803-81e5-69ba1c905444",
            price: 66447.94,
            price_sources: [],
            processed_ms: 1711315874869,
          },
          {
            leverage: 0.02750000000000004,
            order_type: "LONG",
            order_uuid: "af72f70c-74e2-4ddd-b18f-616a7c9d3aa2",
            price: 66263.13,
            price_sources: [],
            processed_ms: 1711316813330,
          },
          {
            leverage: 0.11000000000000001,
            order_type: "LONG",
            order_uuid: "6d3fedf5-9305-4b84-a51a-82175da209fd",
            price: 66491.32,
            price_sources: [],
            processed_ms: 1711317739518,
          },
        ],
        position_type: "FLAT",
        position_uuid: "bc708681-5b2d-41a7-adc3-8b80e83ed1cb",
        return_at_close: 0.9904533512733142,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 5812.883999999447,
        close_ms: 1711361356628,
        current_return: 0.9841396671451088,
        initial_entry_price: 66664.26,
        is_closed_position: true,
        miner_hotkey: "5F4pH5CwrH2ykJLMew6Fx7zhk6HSPC1TUVCFBs7N3nkigqGu",
        net_leverage: 0.0,
        open_ms: 1711327061347,
        orders: [
          {
            leverage: -1.1,
            order_type: "SHORT",
            order_uuid: "01f3211c-25aa-4ceb-937b-8f930ff3afba",
            price: 66664.26,
            price_sources: [],
            processed_ms: 1711327061345,
          },
          {
            leverage: 0.10999999999999999,
            order_type: "LONG",
            order_uuid: "c2cba4a8-13e5-4039-ae77-93388550444f",
            price: 66536.6,
            price_sources: [],
            processed_ms: 1711328004717,
          },
          {
            leverage: 0.10999999999999999,
            order_type: "LONG",
            order_uuid: "499cfc12-4b79-499f-b3ac-ec8e1fa6b322",
            price: 66713.1,
            price_sources: [],
            processed_ms: 1711328921741,
          },
          {
            leverage: -1.1,
            order_type: "SHORT",
            order_uuid: "65fb9d4d-ce62-44f5-821b-d016229065ca",
            price: 66555.21,
            price_sources: [],
            processed_ms: 1711332031818,
          },
          {
            leverage: 0.1100000000000001,
            order_type: "LONG",
            order_uuid: "d7a187f7-3823-46c0-8f14-8c944450063e",
            price: 66682.76,
            price_sources: [],
            processed_ms: 1711333870960,
          },
          {
            leverage: 0.1100000000000001,
            order_type: "LONG",
            order_uuid: "a6c9fb81-6b41-4d9e-9134-0a99e7cd652d",
            price: 66673.16,
            price_sources: [],
            processed_ms: 1711335696420,
          },
          {
            leverage: 0.1100000000000001,
            order_type: "LONG",
            order_uuid: "6a094943-67d1-480b-bb56-19f2bb013f58",
            price: 66796.32,
            price_sources: [],
            processed_ms: 1711337535139,
          },
          {
            leverage: 0.1100000000000001,
            order_type: "LONG",
            order_uuid: "6498b297-0829-47c4-b63e-8c2548241f48",
            price: 67264.43,
            price_sources: [],
            processed_ms: 1711339367746,
          },
          {
            leverage: 0.1100000000000001,
            order_type: "LONG",
            order_uuid: "7b869820-3f19-4576-8977-12c9ed5df3f1",
            price: 67227.57,
            price_sources: [],
            processed_ms: 1711341222865,
          },
          {
            leverage: 0.1100000000000001,
            order_type: "LONG",
            order_uuid: "978adf96-b8a7-434e-b619-2a36dc386b63",
            price: 67460.69,
            price_sources: [],
            processed_ms: 1711343027709,
          },
          {
            leverage: 0.1100000000000001,
            order_type: "LONG",
            order_uuid: "f8aa5b24-daca-4242-96ef-581792e27be2",
            price: 67733.51,
            price_sources: [],
            processed_ms: 1711344862883,
          },
          {
            leverage: 0.1100000000000001,
            order_type: "LONG",
            order_uuid: "0de36a57-c97f-4f74-8d20-90f566ba8c75",
            price: 67294.81,
            price_sources: [],
            processed_ms: 1711346696384,
          },
          {
            leverage: 0.10999999999999999,
            order_type: "LONG",
            order_uuid: "0b0c89f2-6963-4ce2-8604-9cb9f9620140",
            price: 67157.89,
            price_sources: [],
            processed_ms: 1711348530583,
          },
          {
            leverage: 0.4949999999999997,
            order_type: "LONG",
            order_uuid: "c7f1003e-07ef-4015-a661-6978c7e9c868",
            price: 67237.38,
            price_sources: [],
            processed_ms: 1711350395447,
          },
          {
            leverage: 0.24749999999999986,
            order_type: "LONG",
            order_uuid: "cd3b9aa1-b555-45d3-8c80-97867ae2ceee",
            price: 67093.35,
            price_sources: [],
            processed_ms: 1711352193724,
          },
          {
            leverage: 0.11000000000000001,
            order_type: "LONG",
            order_uuid: "052eab00-361f-4809-8847-5228ab051ac6",
            price: 67004.24,
            price_sources: [],
            processed_ms: 1711354025923,
          },
          {
            leverage: 0.06874999999999992,
            order_type: "LONG",
            order_uuid: "8c58c65a-a38b-4fc1-9378-01def268bc8f",
            price: 66990.96,
            price_sources: [],
            processed_ms: 1711355857521,
          },
          {
            leverage: 0.03437499999999996,
            order_type: "LONG",
            order_uuid: "091c3e41-a93c-41ee-ac59-da8f9ddd6f30",
            price: 66868.24,
            price_sources: [],
            processed_ms: 1711357690581,
          },
          {
            leverage: 0.01718749999999998,
            order_type: "LONG",
            order_uuid: "f3e8620a-132d-46e7-81e2-8473e2948e42",
            price: 66863.58,
            price_sources: [],
            processed_ms: 1711359530085,
          },
          {
            leverage: 0.11000000000000001,
            order_type: "LONG",
            order_uuid: "bd2344b4-9ac7-4f8c-bb56-a7fdc07c2fa0",
            price: 67329.53,
            price_sources: [],
            processed_ms: 1711361356628,
          },
        ],
        position_type: "FLAT",
        position_uuid: "e1e2899b-d27d-4bd4-9951-d349376d29e7",
        return_at_close: 0.9802424740632142,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 262315.32333333493,
        close_ms: 1711378030364,
        current_return: 1.002963667550466,
        initial_entry_price: 67216.42,
        is_closed_position: true,
        miner_hotkey: "5F4pH5CwrH2ykJLMew6Fx7zhk6HSPC1TUVCFBs7N3nkigqGu",
        net_leverage: 0.0,
        open_ms: 1711363209432,
        orders: [
          {
            leverage: -1.1,
            order_type: "SHORT",
            order_uuid: "19a3714c-a34d-4eb1-8f42-c1ac7a48f2f3",
            price: 67216.42,
            price_sources: [],
            processed_ms: 1711363209429,
          },
          {
            leverage: 0.55,
            order_type: "LONG",
            order_uuid: "80f3b340-9b57-45bb-af5b-10a1bf8ba9c0",
            price: 66884.15,
            price_sources: [],
            processed_ms: 1711365014707,
          },
          {
            leverage: 0.275,
            order_type: "LONG",
            order_uuid: "3ed21d7d-bf2b-4fea-96a7-b5a3b01ebe56",
            price: 66955.13,
            price_sources: [],
            processed_ms: 1711368399320,
          },
          {
            leverage: 0.1375,
            order_type: "LONG",
            order_uuid: "87cf4dc8-79c9-476c-82a0-e8540afc340b",
            price: 67244.48,
            price_sources: [],
            processed_ms: 1711370237747,
          },
          {
            leverage: 0.06875,
            order_type: "LONG",
            order_uuid: "24816b0d-5b56-4641-be11-fc556a1ecd8b",
            price: 67197.62,
            price_sources: [],
            processed_ms: 1711372071302,
          },
          {
            leverage: 0.034375,
            order_type: "LONG",
            order_uuid: "71561660-6373-46c6-bddc-b149b100eb11",
            price: 67143.56,
            price_sources: [],
            processed_ms: 1711373905532,
          },
          {
            leverage: 0.0171875,
            order_type: "LONG",
            order_uuid: "7635136c-840d-4bd8-9656-7081936a57ba",
            price: 68234.56,
            price_sources: [],
            processed_ms: 1711375740474,
          },
          {
            leverage: -0.00859375,
            order_type: "SHORT",
            order_uuid: "4d846ba7-b830-4281-9d6a-a82d725ba3e2",
            price: 68888.85,
            price_sources: [],
            processed_ms: 1711376044028,
          },
          {
            leverage: 0.020625,
            order_type: "LONG",
            order_uuid: "0a47297c-56c5-4918-a34b-196f35014225",
            price: 69235.28,
            price_sources: [],
            processed_ms: 1711376570536,
          },
          {
            leverage: 0.004125000000000001,
            order_type: "LONG",
            order_uuid: "812550cf-5a32-4006-8c8b-1386b67ce7a3",
            price: 69296.54,
            price_sources: [],
            processed_ms: 1711376854094,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "316d0a45-0d77-4252-bc8e-0a00cf3b391d",
            price: 69144.78,
            price_sources: [],
            processed_ms: 1711378030364,
          },
        ],
        position_type: "FLAT",
        position_uuid: "75c9d676-020f-44b7-843f-4f14a57304aa",
        return_at_close: 1.000757147481855,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 69710.02658536585,
        close_ms: 1711386427201,
        current_return: 1.0545059164356383,
        initial_entry_price: 69212.28,
        is_closed_position: true,
        miner_hotkey: "5F4pH5CwrH2ykJLMew6Fx7zhk6HSPC1TUVCFBs7N3nkigqGu",
        net_leverage: 0.0,
        open_ms: 1711378287865,
        orders: [
          {
            leverage: 1.25,
            order_type: "LONG",
            order_uuid: "3185b9ad-72a9-4126-85c1-e971bc709c8a",
            price: 69212.28,
            price_sources: [],
            processed_ms: 1711378287862,
          },
          {
            leverage: -0.625,
            order_type: "SHORT",
            order_uuid: "7dfa5938-b8cd-4e60-8d44-ea16a9e0500f",
            price: 69598.91,
            price_sources: [],
            processed_ms: 1711381167543,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "8d82d9f2-392b-40e5-8dc0-30731f0ae863",
            price: 69697.46,
            price_sources: [],
            processed_ms: 1711382368634,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "08d133bd-c68b-405e-9b12-60c656939774",
            price: 69606.51,
            price_sources: [],
            processed_ms: 1711384178747,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "56c12296-621e-4fe0-b0fc-54b969dc2bc8",
            price: 70194.6,
            price_sources: [],
            processed_ms: 1711386025750,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "41ea2832-df66-44a6-bbb6-293afb66b0f3",
            price: 70446.12,
            price_sources: [],
            processed_ms: 1711386427201,
          },
        ],
        position_type: "FLAT",
        position_uuid: "2610ef7a-d713-46b3-a0ea-0d98f8eace55",
        return_at_close: 1.043697230792173,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 70712.58687500001,
        close_ms: 1711391695272,
        current_return: 0.978017127748521,
        initial_entry_price: 70744.85,
        is_closed_position: true,
        miner_hotkey: "5F4pH5CwrH2ykJLMew6Fx7zhk6HSPC1TUVCFBs7N3nkigqGu",
        net_leverage: 0.0,
        open_ms: 1711387825863,
        orders: [
          {
            leverage: 5.0,
            order_type: "LONG",
            order_uuid: "3e3aaf28-3e0b-465d-935d-148d5026cb76",
            price: 70744.85,
            price_sources: [],
            processed_ms: 1711387825861,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "ebec5fcc-3c01-49ad-9cfb-bbb8412ca011",
            price: 70597.33,
            price_sources: [],
            processed_ms: 1711389634824,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "794ce472-6c13-4ac2-a401-5756f0090eb8",
            price: 70720.3,
            price_sources: [],
            processed_ms: 1711391460915,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "f9f78709-06c8-41dc-933d-04df4b20b141",
            price: 70518.19,
            price_sources: [],
            processed_ms: 1711391695272,
          },
        ],
        position_type: "FLAT",
        position_uuid: "20b436f0-04b3-43f0-96e2-6e4886bf3f16",
        return_at_close: 0.9623688537045446,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 70927.52727272728,
        close_ms: 1711401079688,
        current_return: 0.9578054198297037,
        initial_entry_price: 70908.87,
        is_closed_position: true,
        miner_hotkey: "5F4pH5CwrH2ykJLMew6Fx7zhk6HSPC1TUVCFBs7N3nkigqGu",
        net_leverage: 0.0,
        open_ms: 1711393267660,
        orders: [
          {
            leverage: 5.0,
            order_type: "LONG",
            order_uuid: "00a476e0-fade-4447-bc70-2a103bcb5be9",
            price: 70908.87,
            price_sources: [],
            processed_ms: 1711393267658,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "df292dbe-caca-4d14-bd19-840004587dd7",
            price: 70679.06,
            price_sources: [],
            processed_ms: 1711395081841,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "ad79cba8-454a-4299-8bfd-dc04928e90a5",
            price: 71201.01,
            price_sources: [],
            processed_ms: 1711396931395,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "e38e7cd1-6580-4ad0-9298-5d2c1155742a",
            price: 70885.78,
            price_sources: [],
            processed_ms: 1711398713841,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "e37115f5-ff64-4c6b-ae62-2f0adb1f6bf9",
            price: 71006.45,
            price_sources: [],
            processed_ms: 1711400533694,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "53f17ab8-7ba5-4de7-8d94-ba2b4a661fce",
            price: 70655.53,
            price_sources: [],
            processed_ms: 1711401079688,
          },
        ],
        position_type: "FLAT",
        position_uuid: "881e8935-a7a5-42a4-8b53-6c271e93ad4c",
        return_at_close: 0.9367337005934502,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 69759.96000000008,
        close_ms: 1711424170381,
        current_return: 0.9948391842850394,
        initial_entry_price: 70210.8,
        is_closed_position: true,
        miner_hotkey: "5F4pH5CwrH2ykJLMew6Fx7zhk6HSPC1TUVCFBs7N3nkigqGu",
        net_leverage: 0.0,
        open_ms: 1711416891800,
        orders: [
          {
            leverage: -5.0,
            order_type: "SHORT",
            order_uuid: "b9720bd2-7136-4547-a151-4b37a3348369",
            price: 70210.8,
            price_sources: [],
            processed_ms: 1711416891797,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "487fe92d-ed3a-4a64-b65a-c530e296482c",
            price: 70134.28,
            price_sources: [],
            processed_ms: 1711418741942,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "de1e7679-78a5-4561-be71-40927cac181d",
            price: 70229.76,
            price_sources: [],
            processed_ms: 1711420534927,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "73a37d55-8735-4711-b09c-0df2b31e9c9a",
            price: 70418.64,
            price_sources: [],
            processed_ms: 1711422346591,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "39e21f4f-ec0b-4968-84da-be62e3641b22",
            price: 70484.65,
            price_sources: [],
            processed_ms: 1711424170381,
          },
        ],
        position_type: "FLAT",
        position_uuid: "4cb87b7b-0f6c-47f8-96cc-1cd66f2d3cc2",
        return_at_close: 0.9848907924421889,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 72280.48000000013,
        close_ms: 1711433262608,
        current_return: 1.0132735615289308,
        initial_entry_price: 70647.58,
        is_closed_position: true,
        miner_hotkey: "5F4pH5CwrH2ykJLMew6Fx7zhk6HSPC1TUVCFBs7N3nkigqGu",
        net_leverage: 0.0,
        open_ms: 1711426010449,
        orders: [
          {
            leverage: -5.0,
            order_type: "SHORT",
            order_uuid: "f5573d08-c679-484e-ade9-6ea5b5b57e93",
            price: 70647.58,
            price_sources: [],
            processed_ms: 1711426010447,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "63c33969-749d-466f-8f58-def8217a2855",
            price: 70561.23,
            price_sources: [],
            processed_ms: 1711427804914,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "0e78c6e3-145e-4742-82c4-cce958b9b927",
            price: 70526.62,
            price_sources: [],
            processed_ms: 1711429611642,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "2b949cd8-51c1-42d6-a798-73c5bd36fa9a",
            price: 70310.59,
            price_sources: [],
            processed_ms: 1711431467074,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "7a920879-3bd8-4869-a854-be62d12f34f8",
            price: 70404.99,
            price_sources: [],
            processed_ms: 1711433262608,
          },
        ],
        position_type: "FLAT",
        position_uuid: "ec7f47bd-7d98-447e-878f-ca0b90e225df",
        return_at_close: 1.0031408259136414,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 70386.64428571428,
        close_ms: 1711437868684,
        current_return: 0.9523704930914993,
        initial_entry_price: 70500.1,
        is_closed_position: true,
        miner_hotkey: "5F4pH5CwrH2ykJLMew6Fx7zhk6HSPC1TUVCFBs7N3nkigqGu",
        net_leverage: 0.0,
        open_ms: 1711435065843,
        orders: [
          {
            leverage: -5.0,
            order_type: "SHORT",
            order_uuid: "1fd56b16-458f-4f9c-a072-2bd0091a1425",
            price: 70500.1,
            price_sources: [],
            processed_ms: 1711435065840,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "47fec3f0-d5dd-444a-9fd0-0b379908b98d",
            price: 70764.83,
            price_sources: [],
            processed_ms: 1711436887169,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "1bad6267-6e68-4235-a612-ccabbbc56ce0",
            price: 71346.04,
            price_sources: [],
            processed_ms: 1711437868684,
          },
        ],
        position_type: "FLAT",
        position_uuid: "58029734-1e44-4a9a-b3f2-d4c117a4dec1",
        return_at_close: 0.9428467881605843,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
    ],
    thirty_day_returns: 1.0,
  },
  "5F7ThFe3ZRxTVFjz4M8ciTHbcVAdVDHLZ7SDyMgdFJpdLdEm": {
    all_time_returns: 1.000824077903687,
    n_positions: 218,
    percentage_profitable: 0.6743119266055045,
    positions: [
      {
        average_entry_price: 18.29575,
        close_ms: 1719597504774,
        current_return: 1.0000600959239168,
        initial_entry_price: 18.29575,
        is_closed_position: true,
        miner_hotkey: "5F7ThFe3ZRxTVFjz4M8ciTHbcVAdVDHLZ7SDyMgdFJpdLdEm",
        net_leverage: 0.0,
        open_ms: 1719596966488,
        orders: [
          {
            leverage: 0.05,
            order_type: "LONG",
            order_uuid: "c7ac8c72-602d-4719-a036-d9c5d6b40b5d",
            price: 18.29575,
            price_sources: [],
            processed_ms: 1719596966385,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "41a6ef20-7369-4820-87bd-83c097068bb3",
            price: 18.31774,
            price_sources: [],
            processed_ms: 1719597504774,
          },
        ],
        position_type: "FLAT",
        position_uuid: "117a2e43-b0ec-4f4a-a897-38464c1abc9f",
        return_at_close: 1.000056595713581,
        trade_pair: ["USDMXN", "USD/MXN", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 18.31309090909091,
        close_ms: 1719598604013,
        current_return: 1.0000378294035304,
        initial_entry_price: 18.31644,
        is_closed_position: true,
        miner_hotkey: "5F7ThFe3ZRxTVFjz4M8ciTHbcVAdVDHLZ7SDyMgdFJpdLdEm",
        net_leverage: 0.0,
        open_ms: 1719597536344,
        orders: [
          {
            leverage: 0.05,
            order_type: "LONG",
            order_uuid: "aaeea173-0ef4-4e78-a283-35122431742d",
            price: 18.31644,
            price_sources: [],
            processed_ms: 1719597536300,
          },
          {
            leverage: 0.06,
            order_type: "LONG",
            order_uuid: "cf1cbba1-4f49-453c-90b6-c8b3fe91a460",
            price: 18.3103,
            price_sources: [],
            processed_ms: 1719598215414,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "de833457-7086-488a-b49a-588585b38546",
            price: 18.31939,
            price_sources: [],
            processed_ms: 1719598604013,
          },
        ],
        position_type: "FLAT",
        position_uuid: "a9bd3550-9ea0-4335-bc1a-ae5e9b867c9d",
        return_at_close: 1.000030129112244,
        trade_pair: ["USDMXN", "USD/MXN", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 18.30695,
        close_ms: 1719599576327,
        current_return: 0.9999977613855385,
        initial_entry_price: 18.3149,
        is_closed_position: true,
        miner_hotkey: "5F7ThFe3ZRxTVFjz4M8ciTHbcVAdVDHLZ7SDyMgdFJpdLdEm",
        net_leverage: 0.0,
        open_ms: 1719598633222,
        orders: [
          {
            leverage: 0.05,
            order_type: "LONG",
            order_uuid: "86bfff3b-b838-47a0-a8b4-c4255bc34256",
            price: 18.3149,
            price_sources: [],
            processed_ms: 1719598632833,
          },
          {
            leverage: 0.05,
            order_type: "LONG",
            order_uuid: "137a5f66-c507-4c8b-963e-5665bcc6a0e9",
            price: 18.299,
            price_sources: [],
            processed_ms: 1719599472671,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "bebf91bf-b750-4042-9010-564e5564f7f2",
            price: 18.30654,
            price_sources: [],
            processed_ms: 1719599576327,
          },
        ],
        position_type: "FLAT",
        position_uuid: "4cda0d98-fc2e-4642-abf5-fbc2e6e7fd92",
        return_at_close: 0.9999907614012089,
        trade_pair: ["USDMXN", "USD/MXN", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 18.2947,
        close_ms: 1719600082025,
        current_return: 1.0000507797340212,
        initial_entry_price: 18.2947,
        is_closed_position: true,
        miner_hotkey: "5F7ThFe3ZRxTVFjz4M8ciTHbcVAdVDHLZ7SDyMgdFJpdLdEm",
        net_leverage: 0.0,
        open_ms: 1719599609221,
        orders: [
          {
            leverage: 0.05,
            order_type: "LONG",
            order_uuid: "3646050e-8360-4d61-a0ed-aacdbd8cd605",
            price: 18.2947,
            price_sources: [],
            processed_ms: 1719599607960,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "87623a98-77ae-4ef5-9fe2-b1d869b80e32",
            price: 18.31328,
            price_sources: [],
            processed_ms: 1719600082025,
          },
        ],
        position_type: "FLAT",
        position_uuid: "b6fe9136-6f5d-473b-acc2-704944d300d1",
        return_at_close: 1.000047279556292,
        trade_pair: ["USDMXN", "USD/MXN", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 18.32005,
        close_ms: 1719600689111,
        current_return: 1.0000094159131663,
        initial_entry_price: 18.32005,
        is_closed_position: true,
        miner_hotkey: "5F7ThFe3ZRxTVFjz4M8ciTHbcVAdVDHLZ7SDyMgdFJpdLdEm",
        net_leverage: 0.0,
        open_ms: 1719600112298,
        orders: [
          {
            leverage: 0.05,
            order_type: "LONG",
            order_uuid: "75237212-d261-4e4f-9cb1-5761ea83b6fd",
            price: 18.32005,
            price_sources: [],
            processed_ms: 1719600112287,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "d95431dc-ad55-4044-bd73-340ce84c0b42",
            price: 18.3235,
            price_sources: [],
            processed_ms: 1719600689111,
          },
        ],
        position_type: "FLAT",
        position_uuid: "122a5c3d-3fa8-4389-beca-ee29fa6da00f",
        return_at_close: 1.0000059158802106,
        trade_pair: ["USDMXN", "USD/MXN", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 18.31366869047619,
        close_ms: 1719604252185,
        current_return: 0.999970562795368,
        initial_entry_price: 18.3246,
        is_closed_position: true,
        miner_hotkey: "5F7ThFe3ZRxTVFjz4M8ciTHbcVAdVDHLZ7SDyMgdFJpdLdEm",
        net_leverage: 0.0,
        open_ms: 1719600719063,
        orders: [
          {
            leverage: 0.05,
            order_type: "LONG",
            order_uuid: "8229e180-2190-4849-b003-6a9c204936f3",
            price: 18.3246,
            price_sources: [],
            processed_ms: 1719600719049,
          },
          {
            leverage: 0.05500000000000001,
            order_type: "LONG",
            order_uuid: "cdcc8713-c185-4a04-93d0-e4ca6cf25a8f",
            price: 18.321235,
            price_sources: [],
            processed_ms: 1719602101504,
          },
          {
            leverage: 0.10500000000000001,
            order_type: "LONG",
            order_uuid: "33767ee4-1223-44d1-95ac-4dfb2ee75c6a",
            price: 18.3045,
            price_sources: [],
            processed_ms: 1719604216768,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "a96ccfa2-3099-47d2-9d38-72b4ecbf5943",
            price: 18.3111,
            price_sources: [],
            processed_ms: 1719604252185,
          },
        ],
        position_type: "FLAT",
        position_uuid: "de7ebb95-da4a-424d-923d-a6394731b7c0",
        return_at_close: 0.9999558632280948,
        trade_pair: ["USDMXN", "USD/MXN", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 18.304033636363634,
        close_ms: 1719605208940,
        current_return: 1.0000178206682615,
        initial_entry_price: 18.3102,
        is_closed_position: true,
        miner_hotkey: "5F7ThFe3ZRxTVFjz4M8ciTHbcVAdVDHLZ7SDyMgdFJpdLdEm",
        net_leverage: 0.0,
        open_ms: 1719604282691,
        orders: [
          {
            leverage: 0.05,
            order_type: "LONG",
            order_uuid: "0009fa79-58fa-4345-8554-dc2ca5d0868f",
            price: 18.3102,
            price_sources: [],
            processed_ms: 1719604282469,
          },
          {
            leverage: 0.06,
            order_type: "LONG",
            order_uuid: "9ad38962-cca3-47de-9acf-fe801c7ae608",
            price: 18.298895,
            price_sources: [],
            processed_ms: 1719604628749,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "6507dad4-0083-4c05-a23d-a08ccb400eb5",
            price: 18.307,
            price_sources: [],
            processed_ms: 1719605208940,
          },
        ],
        position_type: "FLAT",
        position_uuid: "c2fed4b4-ec76-4b1c-9367-a0307a8a7819",
        return_at_close: 1.0000101205310423,
        trade_pair: ["USDMXN", "USD/MXN", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 18.31982095238095,
        close_ms: 1719606493716,
        current_return: 1.0000239390024617,
        initial_entry_price: 18.32992,
        is_closed_position: true,
        miner_hotkey: "5F7ThFe3ZRxTVFjz4M8ciTHbcVAdVDHLZ7SDyMgdFJpdLdEm",
        net_leverage: 0.0,
        open_ms: 1719605238096,
        orders: [
          {
            leverage: 0.05,
            order_type: "LONG",
            order_uuid: "c62d5d47-6cb2-4d90-8efd-87aa4919b32b",
            price: 18.32992,
            price_sources: [],
            processed_ms: 1719605238086,
          },
          {
            leverage: 0.05500000000000001,
            order_type: "LONG",
            order_uuid: "f5f03e94-24e2-45eb-a9b1-1a548758c078",
            price: 18.31064,
            price_sources: [],
            processed_ms: 1719606204088,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "86376839-c8b9-4414-ad11-e66f628cdce5",
            price: 18.324,
            price_sources: [],
            processed_ms: 1719606493716,
          },
        ],
        position_type: "FLAT",
        position_uuid: "83c4a926-9584-42f9-b5c2-67fb3c0f9bcd",
        return_at_close: 1.00001658882651,
        trade_pair: ["USDMXN", "USD/MXN", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 18.31336818181818,
        close_ms: 1719607618639,
        current_return: 1.0001055362896956,
        initial_entry_price: 18.32545,
        is_closed_position: true,
        miner_hotkey: "5F7ThFe3ZRxTVFjz4M8ciTHbcVAdVDHLZ7SDyMgdFJpdLdEm",
        net_leverage: 0.0,
        open_ms: 1719606523484,
        orders: [
          {
            leverage: 0.05,
            order_type: "LONG",
            order_uuid: "b3e6386a-147a-4e4a-a479-34fbe2e573d3",
            price: 18.32545,
            price_sources: [],
            processed_ms: 1719606523462,
          },
          {
            leverage: 0.06,
            order_type: "LONG",
            order_uuid: "2200e099-10cb-483e-9aba-2821936f1de8",
            price: 18.3033,
            price_sources: [],
            processed_ms: 1719606752837,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "dd92ea4f-9ab2-4d60-8244-96a77988ce40",
            price: 18.330950000000005,
            price_sources: [],
            processed_ms: 1719607618639,
          },
        ],
        position_type: "FLAT",
        position_uuid: "41bcf736-4a93-4af5-922d-36480f50bb0f",
        return_at_close: 1.000097835477066,
        trade_pair: ["USDMXN", "USD/MXN", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 18.30238909090909,
        close_ms: 1719608014145,
        current_return: 1.0000171846255466,
        initial_entry_price: 18.3187,
        is_closed_position: true,
        miner_hotkey: "5F7ThFe3ZRxTVFjz4M8ciTHbcVAdVDHLZ7SDyMgdFJpdLdEm",
        net_leverage: 0.0,
        open_ms: 1719607652027,
        orders: [
          {
            leverage: 0.05,
            order_type: "LONG",
            order_uuid: "a21ff36d-a116-4c9a-92c3-f9e42bf80a84",
            price: 18.3187,
            price_sources: [],
            processed_ms: 1719607652020,
          },
          {
            leverage: 0.06,
            order_type: "LONG",
            order_uuid: "54ca226f-95ae-45a4-a7f8-db14e365a0a8",
            price: 18.304360000000003,
            price_sources: [],
            processed_ms: 1719607908840,
          },
          {
            leverage: 0.11,
            order_type: "LONG",
            order_uuid: "810ed542-66f2-4863-8ef5-0062c0440e2e",
            price: 18.2939,
            price_sources: [],
            processed_ms: 1719607984288,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "1a435f96-153e-4862-a34d-99d830caaf0c",
            price: 18.30382,
            price_sources: [],
            processed_ms: 1719608014145,
          },
        ],
        position_type: "FLAT",
        position_uuid: "053dfd9d-fc4e-4501-8983-d73ba685319e",
        return_at_close: 1.0000017843609033,
        trade_pair: ["USDMXN", "USD/MXN", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 18.300605,
        close_ms: 1719608273976,
        current_return: 1.0000644506991767,
        initial_entry_price: 18.30081,
        is_closed_position: true,
        miner_hotkey: "5F7ThFe3ZRxTVFjz4M8ciTHbcVAdVDHLZ7SDyMgdFJpdLdEm",
        net_leverage: 0.0,
        open_ms: 1719608044080,
        orders: [
          {
            leverage: 0.05,
            order_type: "LONG",
            order_uuid: "be3cc908-bbd0-4d69-ac67-447e13f853a1",
            price: 18.30081,
            price_sources: [],
            processed_ms: 1719608044073,
          },
          {
            leverage: 0.05,
            order_type: "LONG",
            order_uuid: "e49e5f70-1e8a-4636-b4c9-85f7f939ac4c",
            price: 18.3004,
            price_sources: [],
            processed_ms: 1719608244922,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "e429593e-5631-4ee9-9403-623c8242a677",
            price: 18.3124,
            price_sources: [],
            processed_ms: 1719608273976,
          },
        ],
        position_type: "FLAT",
        position_uuid: "10762cbf-2242-4fcd-8022-c868bc7723a7",
        return_at_close: 1.0000574502480217,
        trade_pair: ["USDMXN", "USD/MXN", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 18.3233,
        close_ms: 1719608338000,
        current_return: 0.9999214115361316,
        initial_entry_price: 18.3233,
        is_closed_position: true,
        miner_hotkey: "5F7ThFe3ZRxTVFjz4M8ciTHbcVAdVDHLZ7SDyMgdFJpdLdEm",
        net_leverage: 0.0,
        open_ms: 1719608307801,
        orders: [
          {
            leverage: 0.05,
            order_type: "LONG",
            order_uuid: "709ab066-60f8-4d9c-b7d6-c95e524a8374",
            price: 18.3233,
            price_sources: [],
            processed_ms: 1719608306105,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "efe1db8c-56c6-48a8-a8d2-7d544606298e",
            price: 18.2945,
            price_sources: [],
            processed_ms: 1719608338000,
          },
        ],
        position_type: "FLAT",
        position_uuid: "fbe64d81-bad0-4dcb-b40b-de59ce9c0b2c",
        return_at_close: 0.9999179118111912,
        trade_pair: ["USDMXN", "USD/MXN", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 18.287264069264072,
        close_ms: 1719781986821,
        current_return: 1.0003182319416541,
        initial_entry_price: 18.3184,
        is_closed_position: true,
        miner_hotkey: "5F7ThFe3ZRxTVFjz4M8ciTHbcVAdVDHLZ7SDyMgdFJpdLdEm",
        net_leverage: 0.0,
        open_ms: 1719608377525,
        orders: [
          {
            leverage: 0.05,
            order_type: "LONG",
            order_uuid: "15436498-22e0-492e-83ec-a702f67c647c",
            price: 18.3184,
            price_sources: [],
            processed_ms: 1719608376172,
          },
          {
            leverage: 0.06,
            order_type: "LONG",
            order_uuid: "5365b239-2e62-470d-902e-7d6f48a3d688",
            price: 18.28,
            price_sources: [],
            processed_ms: 1719781206195,
          },
          {
            leverage: 0.12100000000000001,
            order_type: "LONG",
            order_uuid: "97991cd2-f838-4ad8-809d-80b83d51f717",
            price: 18.278,
            price_sources: [],
            processed_ms: 1719781294794,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "4bc969e6-4ee7-4edd-9943-f4b2c8165733",
            price: 18.3125,
            price_sources: [],
            processed_ms: 1719781986821,
          },
        ],
        position_type: "FLAT",
        position_uuid: "14f4030a-5a74-44f3-b723-577c5f6500d6",
        return_at_close: 1.0003020567958436,
        trade_pair: ["USDMXN", "USD/MXN", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 18.3125,
        close_ms: 1719784964320,
        current_return: 0.9999795221843003,
        initial_entry_price: 18.3125,
        is_closed_position: true,
        miner_hotkey: "5F7ThFe3ZRxTVFjz4M8ciTHbcVAdVDHLZ7SDyMgdFJpdLdEm",
        net_leverage: 0.0,
        open_ms: 1719782104717,
        orders: [
          {
            leverage: 0.05,
            order_type: "LONG",
            order_uuid: "24e26fd4-3eb0-4c5f-802e-ef49e78c5af6",
            price: 18.3125,
            price_sources: [],
            processed_ms: 1719782104162,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "d57aabef-2b2e-4b16-b135-cdb41538ddba",
            price: 18.305,
            price_sources: [],
            processed_ms: 1719784964320,
          },
        ],
        position_type: "FLAT",
        position_uuid: "4a1040da-2b23-4c7c-8ca1-d05e43774f7f",
        return_at_close: 0.9999760222559726,
        trade_pair: ["USDMXN", "USD/MXN", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 18.29,
        close_ms: 1719788089648,
        current_return: 1.0000574084199017,
        initial_entry_price: 18.29,
        is_closed_position: true,
        miner_hotkey: "5F7ThFe3ZRxTVFjz4M8ciTHbcVAdVDHLZ7SDyMgdFJpdLdEm",
        net_leverage: 0.0,
        open_ms: 1719784998879,
        orders: [
          {
            leverage: 0.05,
            order_type: "LONG",
            order_uuid: "dee98d03-b09e-4185-9b06-de26c7d56c54",
            price: 18.29,
            price_sources: [],
            processed_ms: 1719784998139,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "b462ae4a-c4d7-426b-9c42-2ee2d76cc9be",
            price: 18.311,
            price_sources: [],
            processed_ms: 1719788089648,
          },
        ],
        position_type: "FLAT",
        position_uuid: "2def246d-3341-4a2d-94e8-758f1d2a6066",
        return_at_close: 1.000053908218972,
        trade_pair: ["USDMXN", "USD/MXN", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 18.27636363636364,
        close_ms: 1719791062182,
        current_return: 0.9999542167358532,
        initial_entry_price: 18.308,
        is_closed_position: true,
        miner_hotkey: "5F7ThFe3ZRxTVFjz4M8ciTHbcVAdVDHLZ7SDyMgdFJpdLdEm",
        net_leverage: 0.0,
        open_ms: 1719788139404,
        orders: [
          {
            leverage: 0.05,
            order_type: "LONG",
            order_uuid: "7d2c8200-b52a-4e55-8020-73c16228bb8f",
            price: 18.308,
            price_sources: [],
            processed_ms: 1719788138946,
          },
          {
            leverage: 0.06,
            order_type: "LONG",
            order_uuid: "8fe3bba9-b131-4ca2-80c3-41bef59558ec",
            price: 18.286,
            price_sources: [],
            processed_ms: 1719788469695,
          },
          {
            leverage: 0.132,
            order_type: "LONG",
            order_uuid: "81ad2f3e-3ca7-43a7-8d89-b141bbf2812b",
            price: 18.26,
            price_sources: [],
            processed_ms: 1719790210537,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "7ec99e93-6892-4b4a-8b83-958cdc399e4d",
            price: 18.2729,
            price_sources: [],
            processed_ms: 1719791062182,
          },
        ],
        position_type: "FLAT",
        position_uuid: "bb771427-369c-4d65-b29d-381a8f153bf7",
        return_at_close: 0.9999372775114217,
        trade_pair: ["USDMXN", "USD/MXN", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 18.28,
        close_ms: 1719791450480,
        current_return: 1.0000339168490153,
        initial_entry_price: 18.28,
        is_closed_position: true,
        miner_hotkey: "5F7ThFe3ZRxTVFjz4M8ciTHbcVAdVDHLZ7SDyMgdFJpdLdEm",
        net_leverage: 0.0,
        open_ms: 1719791092150,
        orders: [
          {
            leverage: 0.05,
            order_type: "LONG",
            order_uuid: "1a7fe84c-6a16-4158-928c-8ba2ded1dcf8",
            price: 18.28,
            price_sources: [],
            processed_ms: 1719791091738,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "ae8fb4d6-3895-4148-bb0b-7833b3bd2ffd",
            price: 18.2924,
            price_sources: [],
            processed_ms: 1719791450480,
          },
        ],
        position_type: "FLAT",
        position_uuid: "36b5e342-5b6a-4310-9d4b-ccc36c5638c3",
        return_at_close: 1.0000304167303062,
        trade_pair: ["USDMXN", "USD/MXN", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 18.27779380952381,
        close_ms: 1719792312184,
        current_return: 1.000031071623838,
        initial_entry_price: 18.29,
        is_closed_position: true,
        miner_hotkey: "5F7ThFe3ZRxTVFjz4M8ciTHbcVAdVDHLZ7SDyMgdFJpdLdEm",
        net_leverage: 0.0,
        open_ms: 1719791480588,
        orders: [
          {
            leverage: 0.05,
            order_type: "LONG",
            order_uuid: "0614a522-6d26-4813-8cec-b0c52d83bbe7",
            price: 18.29,
            price_sources: [],
            processed_ms: 1719791479994,
          },
          {
            leverage: 0.05,
            order_type: "LONG",
            order_uuid: "31cff904-481e-4a40-b8e9-d1202a5dc1fe",
            price: 18.2828,
            price_sources: [],
            processed_ms: 1719791734444,
          },
          {
            leverage: 0.11000000000000001,
            order_type: "LONG",
            order_uuid: "881b4dd3-3cc3-4a64-aef9-edfbc7329fe9",
            price: 18.26997,
            price_sources: [],
            processed_ms: 1719792148520,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "af7cbea9-c7c0-478f-970f-09ce3c07d70b",
            price: 18.2805,
            price_sources: [],
            processed_ms: 1719792312184,
          },
        ],
        position_type: "FLAT",
        position_uuid: "a33e81bb-e0fc-4707-a6d7-981c47d1f30d",
        return_at_close: 1.000016371167085,
        trade_pair: ["USDMXN", "USD/MXN", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 18.27856,
        close_ms: 1719795607978,
        current_return: 1.0000614381001567,
        initial_entry_price: 18.27856,
        is_closed_position: true,
        miner_hotkey: "5F7ThFe3ZRxTVFjz4M8ciTHbcVAdVDHLZ7SDyMgdFJpdLdEm",
        net_leverage: 0.0,
        open_ms: 1719792343998,
        orders: [
          {
            leverage: 0.05,
            order_type: "LONG",
            order_uuid: "68102193-84e9-4d65-be3a-0f960376192b",
            price: 18.27856,
            price_sources: [],
            processed_ms: 1719792343988,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "3bd4a64b-1f70-430b-b02c-fac0aa73b490",
            price: 18.30102,
            price_sources: [],
            processed_ms: 1719795607978,
          },
        ],
        position_type: "FLAT",
        position_uuid: "ad7cbb78-594e-4c44-9923-84bd27563fb0",
        return_at_close: 1.0000579378851233,
        trade_pair: ["USDMXN", "USD/MXN", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 18.30499,
        close_ms: 1719796555477,
        current_return: 0.9999782094979283,
        initial_entry_price: 18.31073,
        is_closed_position: true,
        miner_hotkey: "5F7ThFe3ZRxTVFjz4M8ciTHbcVAdVDHLZ7SDyMgdFJpdLdEm",
        net_leverage: 0.0,
        open_ms: 1719795636836,
        orders: [
          {
            leverage: 0.05,
            order_type: "LONG",
            order_uuid: "b3b2f302-40e6-45f8-9fa5-9265a846da4b",
            price: 18.31073,
            price_sources: [],
            processed_ms: 1719795636803,
          },
          {
            leverage: 0.05,
            order_type: "LONG",
            order_uuid: "5ab58f26-f6c3-4452-a444-48bfc799fed4",
            price: 18.29925,
            price_sources: [],
            processed_ms: 1719796121568,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "822862ad-f2c3-458d-94fb-59f4fd8f3ac9",
            price: 18.301,
            price_sources: [],
            processed_ms: 1719796555477,
          },
        ],
        position_type: "FLAT",
        position_uuid: "a16dfd1d-2c51-4fbe-827e-3da12931c0ec",
        return_at_close: 0.9999712096504618,
        trade_pair: ["USDMXN", "USD/MXN", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 18.27380909090909,
        close_ms: 1719798507327,
        current_return: 1.000094533726905,
        initial_entry_price: 18.294,
        is_closed_position: true,
        miner_hotkey: "5F7ThFe3ZRxTVFjz4M8ciTHbcVAdVDHLZ7SDyMgdFJpdLdEm",
        net_leverage: 0.0,
        open_ms: 1719796585043,
        orders: [
          {
            leverage: 0.05,
            order_type: "LONG",
            order_uuid: "71b4b6cc-7548-4c61-aa7d-689aa850c857",
            price: 18.294,
            price_sources: [],
            processed_ms: 1719796585016,
          },
          {
            leverage: 0.05,
            order_type: "LONG",
            order_uuid: "d0a0419d-a8b9-4425-b838-c2b0e8c0075b",
            price: 18.2906,
            price_sources: [],
            processed_ms: 1719796869479,
          },
          {
            leverage: 0.12,
            order_type: "LONG",
            order_uuid: "f11deefa-48bc-465f-8bb0-879b08aa867d",
            price: 18.2584,
            price_sources: [],
            processed_ms: 1719797162063,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "cce3535c-8fad-4c14-b0df-b65dd504cb4d",
            price: 18.28167,
            price_sources: [],
            processed_ms: 1719798507327,
          },
        ],
        position_type: "FLAT",
        position_uuid: "9ccb8f1d-05fa-4864-b77f-13e400388d66",
        return_at_close: 1.0000791322710856,
        trade_pair: ["USDMXN", "USD/MXN", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 18.28316,
        close_ms: 1719799638549,
        current_return: 1.0000156975052452,
        initial_entry_price: 18.28316,
        is_closed_position: true,
        miner_hotkey: "5F7ThFe3ZRxTVFjz4M8ciTHbcVAdVDHLZ7SDyMgdFJpdLdEm",
        net_leverage: 0.0,
        open_ms: 1719798540221,
        orders: [
          {
            leverage: 0.05,
            order_type: "LONG",
            order_uuid: "c75a363d-b7b4-4ddb-9e05-bd8fb5813505",
            price: 18.28316,
            price_sources: [],
            processed_ms: 1719798539549,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "335f8517-868d-4b22-856a-eb3463959edf",
            price: 18.2889,
            price_sources: [],
            processed_ms: 1719799638549,
          },
        ],
        position_type: "FLAT",
        position_uuid: "1a921c8a-533c-40ba-a57b-30a9b9eeb1c9",
        return_at_close: 1.000012197450304,
        trade_pair: ["USDMXN", "USD/MXN", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 18.283702499999997,
        close_ms: 1719801375823,
        current_return: 0.999966632218978,
        initial_entry_price: 18.2886,
        is_closed_position: true,
        miner_hotkey: "5F7ThFe3ZRxTVFjz4M8ciTHbcVAdVDHLZ7SDyMgdFJpdLdEm",
        net_leverage: 0.0,
        open_ms: 1719799669686,
        orders: [
          {
            leverage: 0.05,
            order_type: "LONG",
            order_uuid: "16c77c96-991b-4983-bbd8-1fb2b5c6d448",
            price: 18.2886,
            price_sources: [],
            processed_ms: 1719799669651,
          },
          {
            leverage: 0.05,
            order_type: "LONG",
            order_uuid: "2511a7ef-e86b-4e1a-aa23-c63fb31d78e5",
            price: 18.278805,
            price_sources: [],
            processed_ms: 1719801338656,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "57a60dc9-ddc1-4977-a654-5d12e4e0a1d5",
            price: 18.2776,
            price_sources: [],
            processed_ms: 1719801375823,
          },
        ],
        position_type: "FLAT",
        position_uuid: "319d03b5-5df0-4a12-ae9c-1fb848648489",
        return_at_close: 0.9999596324525525,
        trade_pair: ["USDMXN", "USD/MXN", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 18.280431818181818,
        close_ms: 1719804038220,
        current_return: 1.0000613036560762,
        initial_entry_price: 18.2901,
        is_closed_position: true,
        miner_hotkey: "5F7ThFe3ZRxTVFjz4M8ciTHbcVAdVDHLZ7SDyMgdFJpdLdEm",
        net_leverage: 0.0,
        open_ms: 1719801406150,
        orders: [
          {
            leverage: 0.05,
            order_type: "LONG",
            order_uuid: "98bc636e-2b94-486d-9e6e-de5239eac5fa",
            price: 18.2901,
            price_sources: [],
            processed_ms: 1719801406052,
          },
          {
            leverage: 0.06,
            order_type: "LONG",
            order_uuid: "d2b65439-a997-41bf-a488-932256f03c38",
            price: 18.272375,
            price_sources: [],
            processed_ms: 1719803679874,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "c55c0054-ba1c-453d-b678-f9219fe2b78d",
            price: 18.290625,
            price_sources: [],
            processed_ms: 1719804038220,
          },
        ],
        position_type: "FLAT",
        position_uuid: "8e158d16-61f2-4ad0-a185-f95205f67a77",
        return_at_close: 1.000053603184038,
        trade_pair: ["USDMXN", "USD/MXN", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 18.285145,
        close_ms: 1719806364907,
        current_return: 1.0000102678977936,
        initial_entry_price: 18.285145,
        is_closed_position: true,
        miner_hotkey: "5F7ThFe3ZRxTVFjz4M8ciTHbcVAdVDHLZ7SDyMgdFJpdLdEm",
        net_leverage: 0.0,
        open_ms: 1719804066869,
        orders: [
          {
            leverage: 0.05,
            order_type: "LONG",
            order_uuid: "14753157-e51b-4980-8b02-5e5c8487803e",
            price: 18.285145,
            price_sources: [],
            processed_ms: 1719804066840,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "ef9995d9-d8d7-49a8-a652-2db6a6e46d91",
            price: 18.2889,
            price_sources: [],
            processed_ms: 1719806364907,
          },
        ],
        position_type: "FLAT",
        position_uuid: "5371460e-d19f-494d-9c6d-a371fc4eb4ed",
        return_at_close: 1.0000067678618558,
        trade_pair: ["USDMXN", "USD/MXN", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 18.291333333333334,
        close_ms: 1719807478822,
        current_return: 0.9999510413547557,
        initial_entry_price: 18.30116,
        is_closed_position: true,
        miner_hotkey: "5F7ThFe3ZRxTVFjz4M8ciTHbcVAdVDHLZ7SDyMgdFJpdLdEm",
        net_leverage: 0.0,
        open_ms: 1719806394608,
        orders: [
          {
            leverage: 0.05,
            order_type: "LONG",
            order_uuid: "e00bc9c9-8fc2-4b95-8169-322668de437f",
            price: 18.30116,
            price_sources: [],
            processed_ms: 1719806394586,
          },
          {
            leverage: 0.05500000000000001,
            order_type: "LONG",
            order_uuid: "8c5a3807-8c04-471b-82c9-4bb0d4e2d26d",
            price: 18.2824,
            price_sources: [],
            processed_ms: 1719807447513,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "a32816cb-40b7-468d-9cb9-274d16e8ced0",
            price: 18.2828,
            price_sources: [],
            processed_ms: 1719807478822,
          },
        ],
        position_type: "FLAT",
        position_uuid: "16429fb6-9722-4c59-894e-240d20c23c78",
        return_at_close: 0.9999436917146017,
        trade_pair: ["USDMXN", "USD/MXN", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 18.29454,
        close_ms: 1719808212020,
        current_return: 1.0000162070213299,
        initial_entry_price: 18.29454,
        is_closed_position: true,
        miner_hotkey: "5F7ThFe3ZRxTVFjz4M8ciTHbcVAdVDHLZ7SDyMgdFJpdLdEm",
        net_leverage: 0.0,
        open_ms: 1719807510245,
        orders: [
          {
            leverage: 0.05,
            order_type: "LONG",
            order_uuid: "7c007afb-7153-4797-a12e-092ecea7128e",
            price: 18.29454,
            price_sources: [],
            processed_ms: 1719807510148,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "c36e56f2-b632-4906-8b34-c3fb3b28d017",
            price: 18.30047,
            price_sources: [],
            processed_ms: 1719808212020,
          },
        ],
        position_type: "FLAT",
        position_uuid: "b1d69c4c-0edc-4e77-a537-063d8591925c",
        return_at_close: 1.0000127069646052,
        trade_pair: ["USDMXN", "USD/MXN", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 18.2915,
        close_ms: 1719809174853,
        current_return: 0.9999644276679522,
        initial_entry_price: 18.30074,
        is_closed_position: true,
        miner_hotkey: "5F7ThFe3ZRxTVFjz4M8ciTHbcVAdVDHLZ7SDyMgdFJpdLdEm",
        net_leverage: 0.0,
        open_ms: 1719808246249,
        orders: [
          {
            leverage: 0.05,
            order_type: "LONG",
            order_uuid: "3b1cac28-4da3-4de8-adad-0a28002aa87b",
            price: 18.30074,
            price_sources: [],
            processed_ms: 1719808246080,
          },
          {
            leverage: 0.05500000000000001,
            order_type: "LONG",
            order_uuid: "cdf5ceaf-9063-4b99-94d2-3772281f1041",
            price: 18.2831,
            price_sources: [],
            processed_ms: 1719809133559,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "c9dc7c23-01a7-46bb-8ad3-39121b852eb4",
            price: 18.2853,
            price_sources: [],
            processed_ms: 1719809174853,
          },
        ],
        position_type: "FLAT",
        position_uuid: "201d07e7-44a5-4b4d-aaca-26bbfea1d446",
        return_at_close: 0.9999570779294089,
        trade_pair: ["USDMXN", "USD/MXN", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 18.28352,
        close_ms: 1719813311120,
        current_return: 1.0000451938166766,
        initial_entry_price: 18.2879,
        is_closed_position: true,
        miner_hotkey: "5F7ThFe3ZRxTVFjz4M8ciTHbcVAdVDHLZ7SDyMgdFJpdLdEm",
        net_leverage: 0.0,
        open_ms: 1719809206801,
        orders: [
          {
            leverage: 0.05,
            order_type: "LONG",
            order_uuid: "b9bf3311-4071-4c78-8ad4-c70cfe4e58fc",
            price: 18.2879,
            price_sources: [],
            processed_ms: 1719809206782,
          },
          {
            leverage: 0.05,
            order_type: "LONG",
            order_uuid: "6bbd9628-be3e-4300-9c03-108fe1eb6f2f",
            price: 18.27914,
            price_sources: [],
            processed_ms: 1719813201641,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "26d173c3-b83c-4ecb-8959-a62a2c0a3499",
            price: 18.291785,
            price_sources: [],
            processed_ms: 1719813311120,
          },
        ],
        position_type: "FLAT",
        position_uuid: "add6c484-4940-45ad-9e75-261e22b471a5",
        return_at_close: 1.00003819350032,
        trade_pair: ["USDMXN", "USD/MXN", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 18.27807142857143,
        close_ms: 1719813874880,
        current_return: 1.0000458604994464,
        initial_entry_price: 18.29025,
        is_closed_position: true,
        miner_hotkey: "5F7ThFe3ZRxTVFjz4M8ciTHbcVAdVDHLZ7SDyMgdFJpdLdEm",
        net_leverage: 0.0,
        open_ms: 1719813340720,
        orders: [
          {
            leverage: 0.05,
            order_type: "LONG",
            order_uuid: "ae1033aa-228f-4df6-9db0-64b36c25a33b",
            price: 18.29025,
            price_sources: [],
            processed_ms: 1719813340694,
          },
          {
            leverage: 0.05500000000000001,
            order_type: "LONG",
            order_uuid: "ed085c89-4792-4361-91fc-d39d85ba4aa6",
            price: 18.267,
            price_sources: [],
            processed_ms: 1719813791776,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "60aeee20-ae0c-478f-b262-49ad511862ae",
            price: 18.28606,
            price_sources: [],
            processed_ms: 1719813874880,
          },
        ],
        position_type: "FLAT",
        position_uuid: "d29db792-8a56-47fe-8769-1f016dd6bb70",
        return_at_close: 1.0000385101623719,
        trade_pair: ["USDMXN", "USD/MXN", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 18.28086,
        close_ms: 1719814549956,
        current_return: 1.000060035468791,
        initial_entry_price: 18.28086,
        is_closed_position: true,
        miner_hotkey: "5F7ThFe3ZRxTVFjz4M8ciTHbcVAdVDHLZ7SDyMgdFJpdLdEm",
        net_leverage: 0.0,
        open_ms: 1719813904988,
        orders: [
          {
            leverage: 0.05,
            order_type: "LONG",
            order_uuid: "8c5e2d32-5201-4808-b0a4-b8784f9aeb19",
            price: 18.28086,
            price_sources: [],
            processed_ms: 1719813904944,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "7b0437d3-e5b9-468c-b269-67f9308f2700",
            price: 18.30281,
            price_sources: [],
            processed_ms: 1719814549956,
          },
        ],
        position_type: "FLAT",
        position_uuid: "49e0568c-7c0a-46a1-b90b-31fdaacf86a2",
        return_at_close: 1.0000565352586668,
        trade_pair: ["USDMXN", "USD/MXN", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 18.278386590909086,
        close_ms: 1719815816864,
        current_return: 0.9999977556900501,
        initial_entry_price: 18.2907,
        is_closed_position: true,
        miner_hotkey: "5F7ThFe3ZRxTVFjz4M8ciTHbcVAdVDHLZ7SDyMgdFJpdLdEm",
        net_leverage: 0.0,
        open_ms: 1719814580458,
        orders: [
          {
            leverage: 0.05,
            order_type: "LONG",
            order_uuid: "5ac974f3-a34d-4f39-a171-6ac86c0f88e6",
            price: 18.2907,
            price_sources: [],
            processed_ms: 1719814579632,
          },
          {
            leverage: 0.06,
            order_type: "LONG",
            order_uuid: "55033aa3-53f7-443c-9059-471d62997538",
            price: 18.29198,
            price_sources: [],
            processed_ms: 1719814966460,
          },
          {
            leverage: 0.11,
            order_type: "LONG",
            order_uuid: "048ac35d-c530-4216-9083-52dbb9ae9162",
            price: 18.265375,
            price_sources: [],
            processed_ms: 1719815773402,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "54ed8112-8a4f-4d00-ab93-48d143f1ad1d",
            price: 18.2782,
            price_sources: [],
            processed_ms: 1719815816864,
          },
        ],
        position_type: "FLAT",
        position_uuid: "2e5bbcad-7b98-4afe-883f-1c8643609e94",
        return_at_close: 0.9999823557246125,
        trade_pair: ["USDMXN", "USD/MXN", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 18.269154761904762,
        close_ms: 1719816105614,
        current_return: 1.000038528575611,
        initial_entry_price: 18.27345,
        is_closed_position: true,
        miner_hotkey: "5F7ThFe3ZRxTVFjz4M8ciTHbcVAdVDHLZ7SDyMgdFJpdLdEm",
        net_leverage: 0.0,
        open_ms: 1719815847712,
        orders: [
          {
            leverage: 0.05,
            order_type: "LONG",
            order_uuid: "8d87810e-9251-4aa6-8077-b31f6ece5a5a",
            price: 18.27345,
            price_sources: [],
            processed_ms: 1719815847039,
          },
          {
            leverage: 0.05500000000000001,
            order_type: "LONG",
            order_uuid: "2b031cc1-99c4-44fc-b027-e8c18c326782",
            price: 18.26525,
            price_sources: [],
            processed_ms: 1719815925587,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "12234505-b5b3-4c53-85fe-9b33ead25640",
            price: 18.27586,
            price_sources: [],
            processed_ms: 1719816105614,
          },
        ],
        position_type: "FLAT",
        position_uuid: "8cb2626c-5e9a-48db-97f7-85d084182155",
        return_at_close: 1.000031178292426,
        trade_pair: ["USDMXN", "USD/MXN", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 18.2698,
        close_ms: 1719817084533,
        current_return: 1.0000636295963832,
        initial_entry_price: 18.2698,
        is_closed_position: true,
        miner_hotkey: "5F7ThFe3ZRxTVFjz4M8ciTHbcVAdVDHLZ7SDyMgdFJpdLdEm",
        net_leverage: 0.0,
        open_ms: 1719816134722,
        orders: [
          {
            leverage: 0.05,
            order_type: "LONG",
            order_uuid: "45d0dc26-ca02-4b49-a8ae-ff09c117779d",
            price: 18.2698,
            price_sources: [],
            processed_ms: 1719816134699,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "6f459bd3-543b-420b-928b-50969b22022d",
            price: 18.29305,
            price_sources: [],
            processed_ms: 1719817084533,
          },
        ],
        position_type: "FLAT",
        position_uuid: "7de286fe-b4e9-48de-98fd-a63d479bc39a",
        return_at_close: 1.0000601293736795,
        trade_pair: ["USDMXN", "USD/MXN", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 18.29241,
        close_ms: 1719817472383,
        current_return: 1.0000505400873914,
        initial_entry_price: 18.29241,
        is_closed_position: true,
        miner_hotkey: "5F7ThFe3ZRxTVFjz4M8ciTHbcVAdVDHLZ7SDyMgdFJpdLdEm",
        net_leverage: 0.0,
        open_ms: 1719817115888,
        orders: [
          {
            leverage: 0.05,
            order_type: "LONG",
            order_uuid: "f423b924-af33-422a-a30b-2bc6198ef1e4",
            price: 18.29241,
            price_sources: [],
            processed_ms: 1719817115847,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "7222bdf5-6258-49ab-91f0-a9de458834f1",
            price: 18.3109,
            price_sources: [],
            processed_ms: 1719817472383,
          },
        ],
        position_type: "FLAT",
        position_uuid: "fd4c4419-1d27-4389-a732-3e890e82ec00",
        return_at_close: 1.000047039910501,
        trade_pair: ["USDMXN", "USD/MXN", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 18.31104,
        close_ms: 1719817868462,
        current_return: 1.0000554583464403,
        initial_entry_price: 18.31104,
        is_closed_position: true,
        miner_hotkey: "5F7ThFe3ZRxTVFjz4M8ciTHbcVAdVDHLZ7SDyMgdFJpdLdEm",
        net_leverage: 0.0,
        open_ms: 1719817502491,
        orders: [
          {
            leverage: 0.05,
            order_type: "LONG",
            order_uuid: "a5b36e9b-58aa-444c-a936-a39454829ea9",
            price: 18.31104,
            price_sources: [],
            processed_ms: 1719817502476,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "fe7c76f3-5878-433c-b032-430b19f17e7a",
            price: 18.33135,
            price_sources: [],
            processed_ms: 1719817868462,
          },
        ],
        position_type: "FLAT",
        position_uuid: "0d3e9989-59da-4c26-8350-6f2f7d6f4644",
        return_at_close: 1.000051958152336,
        trade_pair: ["USDMXN", "USD/MXN", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 18.32386590909091,
        close_ms: 1719818124151,
        current_return: 0.9999785958962508,
        initial_entry_price: 18.325924999999998,
        is_closed_position: true,
        miner_hotkey: "5F7ThFe3ZRxTVFjz4M8ciTHbcVAdVDHLZ7SDyMgdFJpdLdEm",
        net_leverage: 0.0,
        open_ms: 1719817898896,
        orders: [
          {
            leverage: 0.05,
            order_type: "LONG",
            order_uuid: "56022854-05ad-4c08-84dc-c4d3743f4335",
            price: 18.325924999999998,
            price_sources: [],
            processed_ms: 1719817898864,
          },
          {
            leverage: 0.06,
            order_type: "LONG",
            order_uuid: "1bb0c6d1-70e8-48c2-bbd0-3dc22df9dbff",
            price: 18.32215,
            price_sources: [],
            processed_ms: 1719818017795,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "a22626a0-7fd5-411d-ac8b-5d6399bf85ea",
            price: 18.3203,
            price_sources: [],
            processed_ms: 1719818124151,
          },
        ],
        position_type: "FLAT",
        position_uuid: "edb63638-2305-4b9e-9fad-882311846c14",
        return_at_close: 0.9999708960610624,
        trade_pair: ["USDMXN", "USD/MXN", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 18.32504,
        close_ms: 1719819059631,
        current_return: 1.000065538738251,
        initial_entry_price: 18.32504,
        is_closed_position: true,
        miner_hotkey: "5F7ThFe3ZRxTVFjz4M8ciTHbcVAdVDHLZ7SDyMgdFJpdLdEm",
        net_leverage: 0.0,
        open_ms: 1719818155589,
        orders: [
          {
            leverage: 0.05,
            order_type: "LONG",
            order_uuid: "7db3faba-70e1-48c1-bb77-c115be717d4f",
            price: 18.32504,
            price_sources: [],
            processed_ms: 1719818155508,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "89d2a60c-fb97-443b-afc0-f3e25646dd02",
            price: 18.34906,
            price_sources: [],
            processed_ms: 1719819059631,
          },
        ],
        position_type: "FLAT",
        position_uuid: "766324e9-182a-420a-90c7-692865ed4dce",
        return_at_close: 1.0000620385088654,
        trade_pair: ["USDMXN", "USD/MXN", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 18.3501,
        close_ms: 1719819605512,
        current_return: 1.0000566754404607,
        initial_entry_price: 18.3501,
        is_closed_position: true,
        miner_hotkey: "5F7ThFe3ZRxTVFjz4M8ciTHbcVAdVDHLZ7SDyMgdFJpdLdEm",
        net_leverage: 0.0,
        open_ms: 1719819090753,
        orders: [
          {
            leverage: 0.05,
            order_type: "LONG",
            order_uuid: "d5e97579-b194-469c-8682-9945acfb7d9c",
            price: 18.3501,
            price_sources: [],
            processed_ms: 1719819090698,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "c0ca9d38-66e2-499e-b2ab-820bb1397146",
            price: 18.3709,
            price_sources: [],
            processed_ms: 1719819605512,
          },
        ],
        position_type: "FLAT",
        position_uuid: "c209228a-c66a-496b-a786-b761e9b4ba66",
        return_at_close: 1.0000531752420967,
        trade_pair: ["USDMXN", "USD/MXN", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 18.363879999999998,
        close_ms: 1719820654080,
        current_return: 1.0000267838040733,
        initial_entry_price: 18.36931,
        is_closed_position: true,
        miner_hotkey: "5F7ThFe3ZRxTVFjz4M8ciTHbcVAdVDHLZ7SDyMgdFJpdLdEm",
        net_leverage: 0.0,
        open_ms: 1719819635038,
        orders: [
          {
            leverage: 0.05,
            order_type: "LONG",
            order_uuid: "db39c90d-e051-425e-b624-ae521ec99bff",
            price: 18.36931,
            price_sources: [],
            processed_ms: 1719819634967,
          },
          {
            leverage: 0.05,
            order_type: "LONG",
            order_uuid: "d778e53c-713c-4efc-a2de-59ac222eb536",
            price: 18.35845,
            price_sources: [],
            processed_ms: 1719819975237,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "3a2a0208-780c-4b3d-be10-f8318071b236",
            price: 18.3688,
            price_sources: [],
            processed_ms: 1719820654080,
          },
        ],
        position_type: "FLAT",
        position_uuid: "f12abd45-4957-4804-9819-9f749a21ed3d",
        return_at_close: 1.0000197836165867,
        trade_pair: ["USDMXN", "USD/MXN", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 18.36836,
        close_ms: 1719820767950,
        current_return: 1.0000206605271238,
        initial_entry_price: 18.36836,
        is_closed_position: true,
        miner_hotkey: "5F7ThFe3ZRxTVFjz4M8ciTHbcVAdVDHLZ7SDyMgdFJpdLdEm",
        net_leverage: 0.0,
        open_ms: 1719820683877,
        orders: [
          {
            leverage: 0.05,
            order_type: "LONG",
            order_uuid: "4eaf3c1d-2299-45cc-a357-da203bc04273",
            price: 18.36836,
            price_sources: [],
            processed_ms: 1719820683810,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "9c4dde18-b341-4364-8839-450bc95a8360",
            price: 18.37595,
            price_sources: [],
            processed_ms: 1719820767950,
          },
        ],
        position_type: "FLAT",
        position_uuid: "217abe86-471b-4e2e-aa0d-651f0692d2f6",
        return_at_close: 1.0000171604548118,
        trade_pair: ["USDMXN", "USD/MXN", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 18.367874999999998,
        close_ms: 1719820950031,
        current_return: 1.0000122747341336,
        initial_entry_price: 18.37107,
        is_closed_position: true,
        miner_hotkey: "5F7ThFe3ZRxTVFjz4M8ciTHbcVAdVDHLZ7SDyMgdFJpdLdEm",
        net_leverage: 0.0,
        open_ms: 1719820798176,
        orders: [
          {
            leverage: 0.05,
            order_type: "LONG",
            order_uuid: "4f241b02-ee71-425f-9cbf-81d9c8774d94",
            price: 18.37107,
            price_sources: [],
            processed_ms: 1719820798018,
          },
          {
            leverage: 0.05,
            order_type: "LONG",
            order_uuid: "8f5bfc30-a7d6-451f-b56f-143109122c7e",
            price: 18.36468,
            price_sources: [],
            processed_ms: 1719820829893,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "5623c679-2fd6-4b4c-a211-f80e0fc183c0",
            price: 18.37013,
            price_sources: [],
            processed_ms: 1719820950031,
          },
        ],
        position_type: "FLAT",
        position_uuid: "8e0c8a2c-4c34-4591-acc5-b1fca71aa5dd",
        return_at_close: 1.0000052746482104,
        trade_pair: ["USDMXN", "USD/MXN", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 18.37028,
        close_ms: 1719821025681,
        current_return: 1.0000346755738072,
        initial_entry_price: 18.37028,
        is_closed_position: true,
        miner_hotkey: "5F7ThFe3ZRxTVFjz4M8ciTHbcVAdVDHLZ7SDyMgdFJpdLdEm",
        net_leverage: 0.0,
        open_ms: 1719820982027,
        orders: [
          {
            leverage: 0.05,
            order_type: "LONG",
            order_uuid: "9851408a-79f6-44a6-bd8b-f27a5a799525",
            price: 18.37028,
            price_sources: [],
            processed_ms: 1719820982000,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "6e5a1e67-9cb5-4961-8536-f6bb345efa35",
            price: 18.38302,
            price_sources: [],
            processed_ms: 1719821025681,
          },
        ],
        position_type: "FLAT",
        position_uuid: "ab43defa-5b38-46c1-85a2-335468e3ad22",
        return_at_close: 1.0000311754524427,
        trade_pair: ["USDMXN", "USD/MXN", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 18.3755925,
        close_ms: 1719823223327,
        current_return: 1.0000161966598162,
        initial_entry_price: 18.38342,
        is_closed_position: true,
        miner_hotkey: "5F7ThFe3ZRxTVFjz4M8ciTHbcVAdVDHLZ7SDyMgdFJpdLdEm",
        net_leverage: 0.0,
        open_ms: 1719821055880,
        orders: [
          {
            leverage: 0.05,
            order_type: "LONG",
            order_uuid: "0e057d98-a6ec-4577-9879-4afcdb71450f",
            price: 18.38342,
            price_sources: [],
            processed_ms: 1719821055853,
          },
          {
            leverage: 0.05,
            order_type: "LONG",
            order_uuid: "44e3ee1e-6676-437d-92b7-59e5e2f682a6",
            price: 18.367765,
            price_sources: [],
            processed_ms: 1719821195130,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "232df4b7-f17b-4a09-8a41-a35e24f9f010",
            price: 18.37857,
            price_sources: [],
            processed_ms: 1719823223327,
          },
        ],
        position_type: "FLAT",
        position_uuid: "3635cba8-54bd-42cf-a961-ee36dce45301",
        return_at_close: 1.0000091965464397,
        trade_pair: ["USDMXN", "USD/MXN", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 18.37107,
        close_ms: 1719825551030,
        current_return: 1.0000691032150006,
        initial_entry_price: 18.37107,
        is_closed_position: true,
        miner_hotkey: "5F7ThFe3ZRxTVFjz4M8ciTHbcVAdVDHLZ7SDyMgdFJpdLdEm",
        net_leverage: 0.0,
        open_ms: 1719823253096,
        orders: [
          {
            leverage: 0.05,
            order_type: "LONG",
            order_uuid: "56c168a9-178f-41bc-93c0-90fd65da3cf0",
            price: 18.37107,
            price_sources: [],
            processed_ms: 1719823253057,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "9f6d26c1-bcb5-4352-a19e-04bffeffe2c5",
            price: 18.39646,
            price_sources: [],
            processed_ms: 1719825551030,
          },
        ],
        position_type: "FLAT",
        position_uuid: "9f6ce836-72c3-42b7-8121-993e7f5ebcff",
        return_at_close: 1.0000656029731394,
        trade_pair: ["USDMXN", "USD/MXN", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 18.37494476190476,
        close_ms: 1719827193050,
        current_return: 0.9998601989802257,
        initial_entry_price: 18.393285,
        is_closed_position: true,
        miner_hotkey: "5F7ThFe3ZRxTVFjz4M8ciTHbcVAdVDHLZ7SDyMgdFJpdLdEm",
        net_leverage: 0.0,
        open_ms: 1719825583289,
        orders: [
          {
            leverage: 0.05,
            order_type: "LONG",
            order_uuid: "51e28701-bb08-4c7c-b19c-324152deadc7",
            price: 18.393285,
            price_sources: [],
            processed_ms: 1719825582662,
          },
          {
            leverage: 0.05,
            order_type: "LONG",
            order_uuid: "5e68731c-3472-4329-bc26-649acdf0790f",
            price: 18.380924999999998,
            price_sources: [],
            processed_ms: 1719826265756,
          },
          {
            leverage: 0.11000000000000001,
            order_type: "LONG",
            order_uuid: "9be14607-8a46-4fe7-9f8d-aacf047cd506",
            price: 18.36389,
            price_sources: [],
            processed_ms: 1719827071289,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "8dfa50d5-9d4b-427d-8a33-e814f582072b",
            price: 18.3627,
            price_sources: [],
            processed_ms: 1719827193050,
          },
        ],
        position_type: "FLAT",
        position_uuid: "7e5b11c4-2352-42ce-bb63-9f75b5d88073",
        return_at_close: 0.9998455010353007,
        trade_pair: ["USDMXN", "USD/MXN", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 18.33569357142857,
        close_ms: 1719834057231,
        current_return: 0.9999201465911144,
        initial_entry_price: 18.37492,
        is_closed_position: true,
        miner_hotkey: "5F7ThFe3ZRxTVFjz4M8ciTHbcVAdVDHLZ7SDyMgdFJpdLdEm",
        net_leverage: 0.0,
        open_ms: 1719827222801,
        orders: [
          {
            leverage: 0.05,
            order_type: "LONG",
            order_uuid: "c05648fd-5055-443c-ad59-3b85b1ceb0f5",
            price: 18.37492,
            price_sources: [],
            processed_ms: 1719827222653,
          },
          {
            leverage: 0.05,
            order_type: "LONG",
            order_uuid: "f118405b-a4b3-4208-898a-fd2d610ff8da",
            price: 18.35554,
            price_sources: [],
            processed_ms: 1719827441243,
          },
          {
            leverage: 0.1,
            order_type: "LONG",
            order_uuid: "c54d5f7f-8e45-4271-99c6-a62e832f0f45",
            price: 18.339605,
            price_sources: [],
            processed_ms: 1719828830276,
          },
          {
            leverage: 0.22000000000000003,
            order_type: "LONG",
            order_uuid: "a002630f-0393-4527-a615-5b86000cf7a4",
            price: 18.32049,
            price_sources: [],
            processed_ms: 1719832242179,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "5a623a39-e32a-42a7-842c-3eb9d3c3fdf3",
            price: 18.3322,
            price_sources: [],
            processed_ms: 1719834057231,
          },
        ],
        position_type: "FLAT",
        position_uuid: "7ddabfa8-465b-40ea-8d00-2f1a3c23a84f",
        return_at_close: 0.9998907489388047,
        trade_pair: ["USDMXN", "USD/MXN", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 18.3238,
        close_ms: 1719834581525,
        current_return: 1.0000978235955424,
        initial_entry_price: 18.3238,
        is_closed_position: true,
        miner_hotkey: "5F7ThFe3ZRxTVFjz4M8ciTHbcVAdVDHLZ7SDyMgdFJpdLdEm",
        net_leverage: 0.0,
        open_ms: 1719834087347,
        orders: [
          {
            leverage: 0.05,
            order_type: "LONG",
            order_uuid: "b4c41f56-6c16-44d3-bbc4-cd1797640602",
            price: 18.3238,
            price_sources: [],
            processed_ms: 1719834087280,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "9a167384-892d-4c25-871b-378083ddcf6f",
            price: 18.35965,
            price_sources: [],
            processed_ms: 1719834581525,
          },
        ],
        position_type: "FLAT",
        position_uuid: "37e10aff-2c59-4166-a931-af49e07360fa",
        return_at_close: 1.0000943232531598,
        trade_pair: ["USDMXN", "USD/MXN", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 18.355424999999997,
        close_ms: 1719835436238,
        current_return: 1.0000829591251632,
        initial_entry_price: 18.355424999999997,
        is_closed_position: true,
        miner_hotkey: "5F7ThFe3ZRxTVFjz4M8ciTHbcVAdVDHLZ7SDyMgdFJpdLdEm",
        net_leverage: 0.0,
        open_ms: 1719834612952,
        orders: [
          {
            leverage: 0.05,
            order_type: "LONG",
            order_uuid: "1eb5ee69-2f12-43e2-ad7f-65c80298ebca",
            price: 18.355424999999997,
            price_sources: [],
            processed_ms: 1719834612887,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "aaeba3e6-2a92-41f6-81f7-c0c34009a03f",
            price: 18.38588,
            price_sources: [],
            processed_ms: 1719835436238,
          },
        ],
        position_type: "FLAT",
        position_uuid: "b820481f-1350-4b46-8582-59f7b0ca40fa",
        return_at_close: 1.0000794588348063,
        trade_pair: ["USDMXN", "USD/MXN", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 18.356054214876036,
        close_ms: 1719837539047,
        current_return: 0.9999245850232735,
        initial_entry_price: 18.3685,
        is_closed_position: true,
        miner_hotkey: "5F7ThFe3ZRxTVFjz4M8ciTHbcVAdVDHLZ7SDyMgdFJpdLdEm",
        net_leverage: 0.0,
        open_ms: 1719835465976,
        orders: [
          {
            leverage: 0.05,
            order_type: "LONG",
            order_uuid: "37b2e580-cb6d-46b8-9998-9589dac2953a",
            price: 18.3685,
            price_sources: [],
            processed_ms: 1719835465860,
          },
          {
            leverage: 0.06,
            order_type: "LONG",
            order_uuid: "caef3d5e-1ff2-4a71-9e95-46ca93814786",
            price: 18.3669,
            price_sources: [],
            processed_ms: 1719835549895,
          },
          {
            leverage: 0.132,
            order_type: "LONG",
            order_uuid: "272ae234-31f6-46a8-8fe4-b374fed01b8f",
            price: 18.34641,
            price_sources: [],
            processed_ms: 1719837342953,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "519eec65-ca3f-4f45-8664-3a07e29eb173",
            price: 18.35033,
            price_sources: [],
            processed_ms: 1719837539047,
          },
        ],
        position_type: "FLAT",
        position_uuid: "c0615ad8-8043-460c-bce9-35a302649392",
        return_at_close: 0.9999076463008033,
        trade_pair: ["USDMXN", "USD/MXN", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 18.35931,
        close_ms: 1719837789268,
        current_return: 1.0000254366858015,
        initial_entry_price: 18.35931,
        is_closed_position: true,
        miner_hotkey: "5F7ThFe3ZRxTVFjz4M8ciTHbcVAdVDHLZ7SDyMgdFJpdLdEm",
        net_leverage: 0.0,
        open_ms: 1719837570559,
        orders: [
          {
            leverage: 0.05,
            order_type: "LONG",
            order_uuid: "d3463289-5ba6-43fe-975e-09cd94219180",
            price: 18.35931,
            price_sources: [],
            processed_ms: 1719837570469,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "3660991f-ae3a-45b6-8d52-7ddf91ffe657",
            price: 18.36865,
            price_sources: [],
            processed_ms: 1719837789268,
          },
        ],
        position_type: "FLAT",
        position_uuid: "88319bb0-ff86-4d9e-82d8-2261248736da",
        return_at_close: 1.000021936596773,
        trade_pair: ["USDMXN", "USD/MXN", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 18.34702285714286,
        close_ms: 1719838636802,
        current_return: 0.9999474946483138,
        initial_entry_price: 18.3587,
        is_closed_position: true,
        miner_hotkey: "5F7ThFe3ZRxTVFjz4M8ciTHbcVAdVDHLZ7SDyMgdFJpdLdEm",
        net_leverage: 0.0,
        open_ms: 1719837818933,
        orders: [
          {
            leverage: 0.05,
            order_type: "LONG",
            order_uuid: "1392731b-c000-43fd-a505-cb72006cea61",
            price: 18.3587,
            price_sources: [],
            processed_ms: 1719837818868,
          },
          {
            leverage: 0.06,
            order_type: "LONG",
            order_uuid: "2a8f35fa-ab27-449e-b655-4ebda6d248dd",
            price: 18.35452,
            price_sources: [],
            processed_ms: 1719837993026,
          },
          {
            leverage: 0.12100000000000001,
            order_type: "LONG",
            order_uuid: "9831a7bd-a8af-4962-99aa-90e9974322a8",
            price: 18.33848,
            price_sources: [],
            processed_ms: 1719838569787,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "5f26b553-ae0a-45e0-959f-76ca81f3b43d",
            price: 18.34285,
            price_sources: [],
            processed_ms: 1719838636802,
          },
        ],
        position_type: "FLAT",
        position_uuid: "027649ca-fd21-42a0-98ba-017e280c06a0",
        return_at_close: 0.9999313254973253,
        trade_pair: ["USDMXN", "USD/MXN", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 18.34555,
        close_ms: 1719838871281,
        current_return: 1.0000453788520922,
        initial_entry_price: 18.34555,
        is_closed_position: true,
        miner_hotkey: "5F7ThFe3ZRxTVFjz4M8ciTHbcVAdVDHLZ7SDyMgdFJpdLdEm",
        net_leverage: 0.0,
        open_ms: 1719838668882,
        orders: [
          {
            leverage: 0.05,
            order_type: "LONG",
            order_uuid: "5e76beb9-7786-4f28-a7c8-1e008d6fee0e",
            price: 18.34555,
            price_sources: [],
            processed_ms: 1719838668792,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "470bae5f-6230-4320-b7d8-5895dd13a2dd",
            price: 18.3622,
            price_sources: [],
            processed_ms: 1719838871281,
          },
        ],
        position_type: "FLAT",
        position_uuid: "b815284e-c5e7-4706-a5c4-f976a0c9ae82",
        return_at_close: 1.000041878693266,
        trade_pair: ["USDMXN", "USD/MXN", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 18.35062,
        close_ms: 1719840148307,
        current_return: 1.0000213037224306,
        initial_entry_price: 18.3536,
        is_closed_position: true,
        miner_hotkey: "5F7ThFe3ZRxTVFjz4M8ciTHbcVAdVDHLZ7SDyMgdFJpdLdEm",
        net_leverage: 0.0,
        open_ms: 1719838901887,
        orders: [
          {
            leverage: 0.05,
            order_type: "LONG",
            order_uuid: "ef44569d-1f23-4673-8dd5-1a4981b157f9",
            price: 18.3536,
            price_sources: [],
            processed_ms: 1719838901828,
          },
          {
            leverage: 0.05,
            order_type: "LONG",
            order_uuid: "6c768391-77d8-4d73-8977-bfa8c62686f9",
            price: 18.34764,
            price_sources: [],
            processed_ms: 1719839333568,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "5bd1c26e-46d0-43c1-a869-2d96afd69874",
            price: 18.35453,
            price_sources: [],
            processed_ms: 1719840148307,
          },
        ],
        position_type: "FLAT",
        position_uuid: "3458158c-1294-444a-a223-ae7181d33b83",
        return_at_close: 1.0000143035733045,
        trade_pair: ["USDMXN", "USD/MXN", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 18.3571,
        close_ms: 1719840476330,
        current_return: 1.0000159611267574,
        initial_entry_price: 18.3571,
        is_closed_position: true,
        miner_hotkey: "5F7ThFe3ZRxTVFjz4M8ciTHbcVAdVDHLZ7SDyMgdFJpdLdEm",
        net_leverage: 0.0,
        open_ms: 1719840177961,
        orders: [
          {
            leverage: 0.05,
            order_type: "LONG",
            order_uuid: "e9e87a6f-238a-4bcd-bf57-406695b82573",
            price: 18.3571,
            price_sources: [],
            processed_ms: 1719840177840,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "8173df65-4186-4b34-9329-a36a94d2f474",
            price: 18.36296,
            price_sources: [],
            processed_ms: 1719840476330,
          },
        ],
        position_type: "FLAT",
        position_uuid: "568287af-8511-4dd2-acf7-de6d7781ee32",
        return_at_close: 1.0000124610708934,
        trade_pair: ["USDMXN", "USD/MXN", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 18.331771900826446,
        close_ms: 1719841402185,
        current_return: 0.9998109882929486,
        initial_entry_price: 18.365,
        is_closed_position: true,
        miner_hotkey: "5F7ThFe3ZRxTVFjz4M8ciTHbcVAdVDHLZ7SDyMgdFJpdLdEm",
        net_leverage: 0.0,
        open_ms: 1719840505943,
        orders: [
          {
            leverage: 0.05,
            order_type: "LONG",
            order_uuid: "56f520fc-c90d-4285-959a-28b5467c93e7",
            price: 18.365,
            price_sources: [],
            processed_ms: 1719840505797,
          },
          {
            leverage: 0.06,
            order_type: "LONG",
            order_uuid: "531b2ac6-6a9e-489a-9065-dbf1ac05875d",
            price: 18.3559,
            price_sources: [],
            processed_ms: 1719840613934,
          },
          {
            leverage: 0.11,
            order_type: "LONG",
            order_uuid: "d0314148-c5db-474f-8c5b-444fd71b7271",
            price: 18.33848,
            price_sources: [],
            processed_ms: 1719840900312,
          },
          {
            leverage: 0.264,
            order_type: "LONG",
            order_uuid: "c01aed40-de6d-48e7-86b3-d7aa9a96881c",
            price: 18.3172,
            price_sources: [],
            processed_ms: 1719841222223,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "a55a7123-0352-4d60-8584-2b35834eb871",
            price: 18.3246,
            price_sources: [],
            processed_ms: 1719841402185,
          },
        ],
        position_type: "FLAT",
        position_uuid: "c93ea27b-abb5-4606-b1ad-b4e5f30fc315",
        return_at_close: 0.9997771146966652,
        trade_pair: ["USDMXN", "USD/MXN", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 18.321,
        close_ms: 1719842934634,
        current_return: 1.0000646798755526,
        initial_entry_price: 18.321,
        is_closed_position: true,
        miner_hotkey: "5F7ThFe3ZRxTVFjz4M8ciTHbcVAdVDHLZ7SDyMgdFJpdLdEm",
        net_leverage: 0.0,
        open_ms: 1719841431920,
        orders: [
          {
            leverage: 0.05,
            order_type: "LONG",
            order_uuid: "5f3e0da9-8abf-4662-ab1f-07f7ff6b9fc0",
            price: 18.321,
            price_sources: [],
            processed_ms: 1719841431848,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "a5894b1e-f196-46e5-8531-1589a24afa18",
            price: 18.3447,
            price_sources: [],
            processed_ms: 1719842934634,
          },
        ],
        position_type: "FLAT",
        position_uuid: "f9710a27-bbf6-4c4a-8ac1-298c88d03185",
        return_at_close: 1.000061179649173,
        trade_pair: ["USDMXN", "USD/MXN", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 18.34631,
        close_ms: 1719843086585,
        current_return: 1.0000700685859991,
        initial_entry_price: 18.34631,
        is_closed_position: true,
        miner_hotkey: "5F7ThFe3ZRxTVFjz4M8ciTHbcVAdVDHLZ7SDyMgdFJpdLdEm",
        net_leverage: 0.0,
        open_ms: 1719842964220,
        orders: [
          {
            leverage: 0.05,
            order_type: "LONG",
            order_uuid: "79dff558-27d0-42f7-b7cd-15029f7c9c10",
            price: 18.34631,
            price_sources: [],
            processed_ms: 1719842964026,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "5395cb96-5234-4590-8a9a-86c3e4118d13",
            price: 18.37202,
            price_sources: [],
            processed_ms: 1719843086585,
          },
        ],
        position_type: "FLAT",
        position_uuid: "72311266-28cc-4dd8-a233-e2952a5268ce",
        return_at_close: 1.000066568340759,
        trade_pair: ["USDMXN", "USD/MXN", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 18.369149999999998,
        close_ms: 1719843404267,
        current_return: 1.0000828563107167,
        initial_entry_price: 18.369149999999998,
        is_closed_position: true,
        miner_hotkey: "5F7ThFe3ZRxTVFjz4M8ciTHbcVAdVDHLZ7SDyMgdFJpdLdEm",
        net_leverage: 0.0,
        open_ms: 1719843115828,
        orders: [
          {
            leverage: 0.05,
            order_type: "LONG",
            order_uuid: "5d6f5842-4da1-4f6e-9b23-baf20f7c9870",
            price: 18.369149999999998,
            price_sources: [],
            processed_ms: 1719843115792,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "5fcaba1f-c843-4648-b04e-5d9b488b6575",
            price: 18.39959,
            price_sources: [],
            processed_ms: 1719843404267,
          },
        ],
        position_type: "FLAT",
        position_uuid: "2b1ee3d1-c9fe-4370-ad9f-cc6894950b2a",
        return_at_close: 1.0000793560207195,
        trade_pair: ["USDMXN", "USD/MXN", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 18.39415,
        close_ms: 1719843642291,
        current_return: 1.000077008179231,
        initial_entry_price: 18.39415,
        is_closed_position: true,
        miner_hotkey: "5F7ThFe3ZRxTVFjz4M8ciTHbcVAdVDHLZ7SDyMgdFJpdLdEm",
        net_leverage: 0.0,
        open_ms: 1719843433974,
        orders: [
          {
            leverage: 0.05,
            order_type: "LONG",
            order_uuid: "b31cc77e-9fd3-404f-bc91-086b866e5a3c",
            price: 18.39415,
            price_sources: [],
            processed_ms: 1719843433884,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "ab13bb82-5284-4200-be54-230a56bc97ac",
            price: 18.42248,
            price_sources: [],
            processed_ms: 1719843642291,
          },
        ],
        position_type: "FLAT",
        position_uuid: "1c3dc3aa-370b-441c-8fba-a3f1ee1f1550",
        return_at_close: 1.0000735079097023,
        trade_pair: ["USDMXN", "USD/MXN", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 18.40873818181818,
        close_ms: 1719844217179,
        current_return: 1.0000538355011268,
        initial_entry_price: 18.4135,
        is_closed_position: true,
        miner_hotkey: "5F7ThFe3ZRxTVFjz4M8ciTHbcVAdVDHLZ7SDyMgdFJpdLdEm",
        net_leverage: 0.0,
        open_ms: 1719843671900,
        orders: [
          {
            leverage: 0.05,
            order_type: "LONG",
            order_uuid: "e4b72c49-b0cc-44f7-920d-a4005678ba23",
            price: 18.4135,
            price_sources: [],
            processed_ms: 1719843671864,
          },
          {
            leverage: 0.06,
            order_type: "LONG",
            order_uuid: "9db6be6e-5f76-4e9a-99eb-37416934a142",
            price: 18.40477,
            price_sources: [],
            processed_ms: 1719843812063,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "667111c9-50e3-485e-a883-94e0a2b0568c",
            price: 18.41775,
            price_sources: [],
            processed_ms: 1719844217179,
          },
        ],
        position_type: "FLAT",
        position_uuid: "4add6151-c0d2-4985-afaa-48835d8009e3",
        return_at_close: 1.0000461350865935,
        trade_pair: ["USDMXN", "USD/MXN", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 18.407193333333332,
        close_ms: 1719844490282,
        current_return: 1.000036416321202,
        initial_entry_price: 18.41482,
        is_closed_position: true,
        miner_hotkey: "5F7ThFe3ZRxTVFjz4M8ciTHbcVAdVDHLZ7SDyMgdFJpdLdEm",
        net_leverage: 0.0,
        open_ms: 1719844247170,
        orders: [
          {
            leverage: 0.05,
            order_type: "LONG",
            order_uuid: "1ad8b5dc-cde5-4ab2-908f-45026b389227",
            price: 18.41482,
            price_sources: [],
            processed_ms: 1719844247028,
          },
          {
            leverage: 0.05500000000000001,
            order_type: "LONG",
            order_uuid: "f1f4793a-ac1b-4504-a72a-55534f0d0e57",
            price: 18.40026,
            price_sources: [],
            processed_ms: 1719844460418,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "f49a0ac1-ba3e-4ff2-a090-95fa5db5def5",
            price: 18.41358,
            price_sources: [],
            processed_ms: 1719844490282,
          },
        ],
        position_type: "FLAT",
        position_uuid: "2c927750-e407-4aff-8d18-e63e8ac5e205",
        return_at_close: 1.0000290660535422,
        trade_pair: ["USDMXN", "USD/MXN", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 18.41163090909091,
        close_ms: 1719844648615,
        current_return: 0.9999317213030187,
        initial_entry_price: 18.4157,
        is_closed_position: true,
        miner_hotkey: "5F7ThFe3ZRxTVFjz4M8ciTHbcVAdVDHLZ7SDyMgdFJpdLdEm",
        net_leverage: 0.0,
        open_ms: 1719844520500,
        orders: [
          {
            leverage: 0.05,
            order_type: "LONG",
            order_uuid: "1abe8a1b-c16d-4c59-b985-9b1a921d9435",
            price: 18.4157,
            price_sources: [],
            processed_ms: 1719844520320,
          },
          {
            leverage: 0.06,
            order_type: "LONG",
            order_uuid: "9da6b0a0-b163-44ea-8f02-c8bb181e693a",
            price: 18.40824,
            price_sources: [],
            processed_ms: 1719844572356,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "0f40ab60-bdf8-4c38-a9e6-948b9686bf9f",
            price: 18.4002,
            price_sources: [],
            processed_ms: 1719844648615,
          },
        ],
        position_type: "FLAT",
        position_uuid: "0b60d5d7-c62f-4920-8c7a-42c2abcb3e51",
        return_at_close: 0.9999240218287646,
        trade_pair: ["USDMXN", "USD/MXN", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 18.402465,
        close_ms: 1719845202972,
        current_return: 1.0000528054257949,
        initial_entry_price: 18.402465,
        is_closed_position: true,
        miner_hotkey: "5F7ThFe3ZRxTVFjz4M8ciTHbcVAdVDHLZ7SDyMgdFJpdLdEm",
        net_leverage: 0.0,
        open_ms: 1719844680227,
        orders: [
          {
            leverage: 0.05,
            order_type: "LONG",
            order_uuid: "91e4a571-2aec-464d-979b-d3cadf98d7e9",
            price: 18.402465,
            price_sources: [],
            processed_ms: 1719844680176,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "783b5da9-f841-4e29-ac38-18cda3da9c31",
            price: 18.4219,
            price_sources: [],
            processed_ms: 1719845202972,
          },
        ],
        position_type: "FLAT",
        position_uuid: "7f65eeb2-90c7-48fa-8142-8d561546a82b",
        return_at_close: 1.0000493052409758,
        trade_pair: ["USDMXN", "USD/MXN", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 18.422445238095236,
        close_ms: 1719846233989,
        current_return: 1.0000287960458676,
        initial_entry_price: 18.43135,
        is_closed_position: true,
        miner_hotkey: "5F7ThFe3ZRxTVFjz4M8ciTHbcVAdVDHLZ7SDyMgdFJpdLdEm",
        net_leverage: 0.0,
        open_ms: 1719845232728,
        orders: [
          {
            leverage: 0.05,
            order_type: "LONG",
            order_uuid: "c7c00c5d-1c2f-4617-8ff0-e5079d7d68d6",
            price: 18.43135,
            price_sources: [],
            processed_ms: 1719845232439,
          },
          {
            leverage: 0.05500000000000001,
            order_type: "LONG",
            order_uuid: "c4a9335e-2ee6-4e10-aeb1-52ff62c82ede",
            price: 18.41435,
            price_sources: [],
            processed_ms: 1719845747557,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "eb52ebca-85d8-4db0-ba09-4b1425d5c5ce",
            price: 18.4275,
            price_sources: [],
            processed_ms: 1719846233989,
          },
        ],
        position_type: "FLAT",
        position_uuid: "6399305c-1e44-439b-93c1-36b6ffacc75d",
        return_at_close: 1.0000214458342167,
        trade_pair: ["USDMXN", "USD/MXN", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 18.412814285714287,
        close_ms: 1719846863045,
        current_return: 0.9997982013885046,
        initial_entry_price: 18.43422,
        is_closed_position: true,
        miner_hotkey: "5F7ThFe3ZRxTVFjz4M8ciTHbcVAdVDHLZ7SDyMgdFJpdLdEm",
        net_leverage: 0.0,
        open_ms: 1719846263768,
        orders: [
          {
            leverage: 0.05,
            order_type: "LONG",
            order_uuid: "62eb78c0-3fb2-45c9-8872-69ca56028abb",
            price: 18.43422,
            price_sources: [],
            processed_ms: 1719846263691,
          },
          {
            leverage: 0.05,
            order_type: "LONG",
            order_uuid: "9bcd0352-9a96-431b-96be-2cecc23908d9",
            price: 18.4196,
            price_sources: [],
            processed_ms: 1719846515621,
          },
          {
            leverage: 0.11000000000000001,
            order_type: "LONG",
            order_uuid: "c01643b9-f76b-44e6-8061-dd80b4f5cad3",
            price: 18.4,
            price_sources: [],
            processed_ms: 1719846781488,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "d0d2fc49-fb38-490c-a5a9-c9e6d3bc95b2",
            price: 18.3951,
            price_sources: [],
            processed_ms: 1719846863045,
          },
        ],
        position_type: "FLAT",
        position_uuid: "f7fd76f4-46d7-446c-80fb-3f70c98ad93a",
        return_at_close: 0.9997835043549441,
        trade_pair: ["USDMXN", "USD/MXN", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 18.379039285714285,
        close_ms: 1719851671108,
        current_return: 0.9998966418165876,
        initial_entry_price: 18.40638,
        is_closed_position: true,
        miner_hotkey: "5F7ThFe3ZRxTVFjz4M8ciTHbcVAdVDHLZ7SDyMgdFJpdLdEm",
        net_leverage: 0.0,
        open_ms: 1719846893694,
        orders: [
          {
            leverage: 0.05,
            order_type: "LONG",
            order_uuid: "eeb43f3a-4e72-40a8-9551-bec56642f18a",
            price: 18.40638,
            price_sources: [],
            processed_ms: 1719846892980,
          },
          {
            leverage: 0.05,
            order_type: "LONG",
            order_uuid: "d21614bd-cece-4a17-a523-e4da362a067f",
            price: 18.380605000000003,
            price_sources: [],
            processed_ms: 1719848179704,
          },
          {
            leverage: 0.11000000000000001,
            order_type: "LONG",
            order_uuid: "f0c661e2-8c07-428f-9f43-72be16fb9831",
            price: 18.3659,
            price_sources: [],
            processed_ms: 1719851633724,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "f52b88d2-ad86-4ab1-9881-accb7036bba5",
            price: 18.36998,
            price_sources: [],
            processed_ms: 1719851671108,
          },
        ],
        position_type: "FLAT",
        position_uuid: "58125290-adb7-4962-a8c7-5906f254880a",
        return_at_close: 0.9998819433359528,
        trade_pair: ["USDMXN", "USD/MXN", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 18.365265454545455,
        close_ms: 1719852029162,
        current_return: 1.0000313441740554,
        initial_entry_price: 18.37024,
        is_closed_position: true,
        miner_hotkey: "5F7ThFe3ZRxTVFjz4M8ciTHbcVAdVDHLZ7SDyMgdFJpdLdEm",
        net_leverage: 0.0,
        open_ms: 1719851705295,
        orders: [
          {
            leverage: 0.05,
            order_type: "LONG",
            order_uuid: "63940bf5-18e9-4292-a8e8-4ab46fd6ddb1",
            price: 18.37024,
            price_sources: [],
            processed_ms: 1719851703176,
          },
          {
            leverage: 0.06,
            order_type: "LONG",
            order_uuid: "db176c11-8ab7-4613-a26f-666d787bf89d",
            price: 18.36112,
            price_sources: [],
            processed_ms: 1719851899364,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "9b4ec8ce-ce1d-488b-bae3-c4521887fe27",
            price: 18.3705,
            price_sources: [],
            processed_ms: 1719852029162,
          },
        ],
        position_type: "FLAT",
        position_uuid: "58972704-f8ff-4295-ba18-0fadb6b09a55",
        return_at_close: 1.0000236439327053,
        trade_pair: ["USDMXN", "USD/MXN", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 18.3689,
        close_ms: 1719853095420,
        current_return: 1.0000595844062519,
        initial_entry_price: 18.3689,
        is_closed_position: true,
        miner_hotkey: "5F7ThFe3ZRxTVFjz4M8ciTHbcVAdVDHLZ7SDyMgdFJpdLdEm",
        net_leverage: 0.0,
        open_ms: 1719852059828,
        orders: [
          {
            leverage: 0.05,
            order_type: "LONG",
            order_uuid: "6d5ceb96-3676-4820-9106-a6da3b4f7903",
            price: 18.3689,
            price_sources: [],
            processed_ms: 1719852059531,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "203f3b73-bce7-468a-b9cf-064bce8b6048",
            price: 18.39079,
            price_sources: [],
            processed_ms: 1719853095420,
          },
        ],
        position_type: "FLAT",
        position_uuid: "4a946120-6645-4936-86ea-3ec6d281ed7d",
        return_at_close: 1.0000560841977064,
        trade_pair: ["USDMXN", "USD/MXN", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 18.3879975,
        close_ms: 1719853791007,
        current_return: 0.9999557815789374,
        initial_entry_price: 18.39731,
        is_closed_position: true,
        miner_hotkey: "5F7ThFe3ZRxTVFjz4M8ciTHbcVAdVDHLZ7SDyMgdFJpdLdEm",
        net_leverage: 0.0,
        open_ms: 1719853124834,
        orders: [
          {
            leverage: 0.05,
            order_type: "LONG",
            order_uuid: "cd37d6ec-cfbf-425f-857b-7fb54a11c59c",
            price: 18.39731,
            price_sources: [],
            processed_ms: 1719853124517,
          },
          {
            leverage: 0.05,
            order_type: "LONG",
            order_uuid: "f26fc2dd-7ed2-4ec1-b70a-de9484be9ac7",
            price: 18.38628,
            price_sources: [],
            processed_ms: 1719853729354,
          },
          {
            leverage: 0.1,
            order_type: "LONG",
            order_uuid: "1fda0f9a-1286-4fcb-9a99-08d756046e19",
            price: 18.3842,
            price_sources: [],
            processed_ms: 1719853761093,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "ba0afb11-9da8-407a-a86a-b2ec68e41c36",
            price: 18.38393,
            price_sources: [],
            processed_ms: 1719853791007,
          },
        ],
        position_type: "FLAT",
        position_uuid: "cc43ed6c-283b-4aac-bcd3-8a39bf79a112",
        return_at_close: 0.9999417821979953,
        trade_pair: ["USDMXN", "USD/MXN", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 18.37851,
        close_ms: 1719855498250,
        current_return: 0.9999958655948402,
        initial_entry_price: 18.38233,
        is_closed_position: true,
        miner_hotkey: "5F7ThFe3ZRxTVFjz4M8ciTHbcVAdVDHLZ7SDyMgdFJpdLdEm",
        net_leverage: 0.0,
        open_ms: 1719853821359,
        orders: [
          {
            leverage: 0.05,
            order_type: "LONG",
            order_uuid: "e1e581da-3fdb-494f-b0ba-a1abae5df619",
            price: 18.38233,
            price_sources: [],
            processed_ms: 1719853821331,
          },
          {
            leverage: 0.05,
            order_type: "LONG",
            order_uuid: "06e58d76-8eb5-43df-9991-ce56e8940488",
            price: 18.37469,
            price_sources: [],
            processed_ms: 1719854015437,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "5de7d836-6831-4328-bad4-7ecdf29b3110",
            price: 18.37775,
            price_sources: [],
            processed_ms: 1719855498250,
          },
        ],
        position_type: "FLAT",
        position_uuid: "de12c991-47cb-4e91-ac14-8d34c47a6a17",
        return_at_close: 0.9999888656237811,
        trade_pair: ["USDMXN", "USD/MXN", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 18.372163636363638,
        close_ms: 1719857365092,
        current_return: 1.0000803986204945,
        initial_entry_price: 18.3834,
        is_closed_position: true,
        miner_hotkey: "5F7ThFe3ZRxTVFjz4M8ciTHbcVAdVDHLZ7SDyMgdFJpdLdEm",
        net_leverage: 0.0,
        open_ms: 1719855531899,
        orders: [
          {
            leverage: 0.05,
            order_type: "LONG",
            order_uuid: "ecab6e5a-97bf-4ea7-a0b7-812e5eb40585",
            price: 18.3834,
            price_sources: [],
            processed_ms: 1719855531860,
          },
          {
            leverage: 0.06,
            order_type: "LONG",
            order_uuid: "d3b85fa3-087c-42bf-86be-cc250785eece",
            price: 18.3628,
            price_sources: [],
            processed_ms: 1719857131176,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "27d7e98d-be8d-467b-bf2b-c1eddcaa9286",
            price: 18.3856,
            price_sources: [],
            processed_ms: 1719857365092,
          },
        ],
        position_type: "FLAT",
        position_uuid: "0f3c509c-32ec-4a14-a278-a428a44bd565",
        return_at_close: 1.000072698001425,
        trade_pair: ["USDMXN", "USD/MXN", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 18.375349999999997,
        close_ms: 1719858004686,
        current_return: 1.0000362238043696,
        initial_entry_price: 18.3857,
        is_closed_position: true,
        miner_hotkey: "5F7ThFe3ZRxTVFjz4M8ciTHbcVAdVDHLZ7SDyMgdFJpdLdEm",
        net_leverage: 0.0,
        open_ms: 1719857394177,
        orders: [
          {
            leverage: 0.05,
            order_type: "LONG",
            order_uuid: "ad2f552e-620b-4cb9-86b5-a87084e733b2",
            price: 18.3857,
            price_sources: [],
            processed_ms: 1719857393936,
          },
          {
            leverage: 0.05,
            order_type: "LONG",
            order_uuid: "16d3fbff-f4de-48e5-819c-b011f18e8b0c",
            price: 18.365,
            price_sources: [],
            processed_ms: 1719857819393,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "33da1b32-bebc-4aeb-85f8-f0e9f77f2a70",
            price: 18.38201,
            price_sources: [],
            processed_ms: 1719858004686,
          },
        ],
        position_type: "FLAT",
        position_uuid: "18e3d2a8-adc8-4845-aeed-eb42d82ef4ab",
        return_at_close: 1.000029223550803,
        trade_pair: ["USDMXN", "USD/MXN", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 18.354867857142857,
        close_ms: 1719862570648,
        current_return: 0.9999986537704219,
        initial_entry_price: 18.38468,
        is_closed_position: true,
        miner_hotkey: "5F7ThFe3ZRxTVFjz4M8ciTHbcVAdVDHLZ7SDyMgdFJpdLdEm",
        net_leverage: 0.0,
        open_ms: 1719858034963,
        orders: [
          {
            leverage: 0.05,
            order_type: "LONG",
            order_uuid: "6c73072f-9b5a-4c8d-933f-48dcfa185387",
            price: 18.38468,
            price_sources: [],
            processed_ms: 1719858034490,
          },
          {
            leverage: 0.05,
            order_type: "LONG",
            order_uuid: "9e658dcf-0fe7-4529-9cab-6a19e99c3183",
            price: 18.367885,
            price_sources: [],
            processed_ms: 1719858386922,
          },
          {
            leverage: 0.11000000000000001,
            order_type: "LONG",
            order_uuid: "9e332af6-e2ec-4d11-b07a-cc3d95bc4b58",
            price: 18.3354,
            price_sources: [],
            processed_ms: 1719861743291,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "22f4c053-bd0e-4234-ac1d-0e7e713ca19b",
            price: 18.35475,
            price_sources: [],
            processed_ms: 1719862570648,
          },
        ],
        position_type: "FLAT",
        position_uuid: "1460ebaa-0b47-4ca3-8582-b0400a11f1f7",
        return_at_close: 0.9999839537902114,
        trade_pair: ["USDMXN", "USD/MXN", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 18.35307,
        close_ms: 1719866671163,
        current_return: 1.0000423089978951,
        initial_entry_price: 18.35307,
        is_closed_position: true,
        miner_hotkey: "5F7ThFe3ZRxTVFjz4M8ciTHbcVAdVDHLZ7SDyMgdFJpdLdEm",
        net_leverage: 0.0,
        open_ms: 1719862601323,
        orders: [
          {
            leverage: 0.05,
            order_type: "LONG",
            order_uuid: "ebd6302e-94bc-4095-85b2-264e39903421",
            price: 18.35307,
            price_sources: [],
            processed_ms: 1719862600581,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "9245b096-d880-464f-85af-1583833c0285",
            price: 18.3686,
            price_sources: [],
            processed_ms: 1719866671163,
          },
        ],
        position_type: "FLAT",
        position_uuid: "1e539636-b4a9-4646-9a3c-af96fccbfb31",
        return_at_close: 1.0000388088498136,
        trade_pair: ["USDMXN", "USD/MXN", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 18.375910909090905,
        close_ms: 1719867261318,
        current_return: 0.9999197301120906,
        initial_entry_price: 18.378,
        is_closed_position: true,
        miner_hotkey: "5F7ThFe3ZRxTVFjz4M8ciTHbcVAdVDHLZ7SDyMgdFJpdLdEm",
        net_leverage: 0.0,
        open_ms: 1719866703965,
        orders: [
          {
            leverage: 0.05,
            order_type: "LONG",
            order_uuid: "4a25c8b3-0d31-4afb-bc3b-85e57cef5c81",
            price: 18.378,
            price_sources: [],
            processed_ms: 1719866703812,
          },
          {
            leverage: 0.06,
            order_type: "LONG",
            order_uuid: "a11b9f67-272f-433c-8e9e-9b2177526dc2",
            price: 18.37417,
            price_sources: [],
            processed_ms: 1719867231155,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "03730513-608e-4f8f-9056-50c0cc566ad4",
            price: 18.3625,
            price_sources: [],
            processed_ms: 1719867261318,
          },
        ],
        position_type: "FLAT",
        position_uuid: "93c4a932-fdfc-4370-8ff3-5a16cd10d4ec",
        return_at_close: 0.9999120307301687,
        trade_pair: ["USDMXN", "USD/MXN", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 18.36037476190476,
        close_ms: 1719867416598,
        current_return: 0.9999431350338728,
        initial_entry_price: 18.3628,
        is_closed_position: true,
        miner_hotkey: "5F7ThFe3ZRxTVFjz4M8ciTHbcVAdVDHLZ7SDyMgdFJpdLdEm",
        net_leverage: 0.0,
        open_ms: 1719867293489,
        orders: [
          {
            leverage: 0.05,
            order_type: "LONG",
            order_uuid: "b67ce8d3-c12b-47fb-bf2e-4f8ffc3696ac",
            price: 18.3628,
            price_sources: [],
            processed_ms: 1719867291120,
          },
          {
            leverage: 0.05500000000000001,
            order_type: "LONG",
            order_uuid: "f5df29e9-bb80-46b8-8578-2d5b71c77514",
            price: 18.35817,
            price_sources: [],
            processed_ms: 1719867383297,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "848b4078-e204-4b77-bc76-da2fcf42332c",
            price: 18.35043,
            price_sources: [],
            processed_ms: 1719867416598,
          },
        ],
        position_type: "FLAT",
        position_uuid: "5db84974-94a8-4f69-b95c-0e3ff9b4be56",
        return_at_close: 0.9999357854518303,
        trade_pair: ["USDMXN", "USD/MXN", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 18.351528095238095,
        close_ms: 1719867545479,
        current_return: 1.000064202546833,
        initial_entry_price: 18.3365,
        is_closed_position: true,
        miner_hotkey: "5F7ThFe3ZRxTVFjz4M8ciTHbcVAdVDHLZ7SDyMgdFJpdLdEm",
        net_leverage: 0.0,
        open_ms: 1719867445486,
        orders: [
          {
            leverage: 0.05,
            order_type: "LONG",
            order_uuid: "e44a44ad-98e8-435b-b5b0-095b5151431f",
            price: 18.3365,
            price_sources: [],
            processed_ms: 1719867445363,
          },
          {
            leverage: 0.05500000000000001,
            order_type: "LONG",
            order_uuid: "8b5bb30a-7a2b-443e-bd44-a55a04e4b344",
            price: 18.36519,
            price_sources: [],
            processed_ms: 1719867511568,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "061eddd9-a061-401c-a20f-64615d196e51",
            price: 18.362740000000002,
            price_sources: [],
            processed_ms: 1719867545479,
          },
        ],
        position_type: "FLAT",
        position_uuid: "51546ce8-2e78-44cd-81fc-40fa6391ce0f",
        return_at_close: 1.0000568520749442,
        trade_pair: ["USDMXN", "USD/MXN", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 18.35569523809524,
        close_ms: 1719870810034,
        current_return: 1.0000171788654761,
        initial_entry_price: 18.36559,
        is_closed_position: true,
        miner_hotkey: "5F7ThFe3ZRxTVFjz4M8ciTHbcVAdVDHLZ7SDyMgdFJpdLdEm",
        net_leverage: 0.0,
        open_ms: 1719867575561,
        orders: [
          {
            leverage: 0.05,
            order_type: "LONG",
            order_uuid: "e3496afc-832c-44a7-a88b-9837fdcfec67",
            price: 18.36559,
            price_sources: [],
            processed_ms: 1719867575491,
          },
          {
            leverage: 0.05500000000000001,
            order_type: "LONG",
            order_uuid: "fdf0b6d8-bcb8-4712-999b-416f8ed27584",
            price: 18.3467,
            price_sources: [],
            processed_ms: 1719867611206,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "020c2d1f-493b-40a2-ac48-2c9f18fad4a9",
            price: 18.3587,
            price_sources: [],
            processed_ms: 1719870810034,
          },
        ],
        position_type: "FLAT",
        position_uuid: "fe35c0c4-d2a0-49ab-85bd-5cd0878683af",
        return_at_close: 1.0000098287392116,
        trade_pair: ["USDMXN", "USD/MXN", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 18.3766,
        close_ms: 1719873077797,
        current_return: 1.000011998955193,
        initial_entry_price: 18.3766,
        is_closed_position: true,
        miner_hotkey: "5F7ThFe3ZRxTVFjz4M8ciTHbcVAdVDHLZ7SDyMgdFJpdLdEm",
        net_leverage: 0.0,
        open_ms: 1719870843439,
        orders: [
          {
            leverage: 0.05,
            order_type: "LONG",
            order_uuid: "30b60be7-103e-40a8-bc57-24979e165bca",
            price: 18.3766,
            price_sources: [],
            processed_ms: 1719870842151,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "fdbd5713-b31f-49e0-aaf1-d093d72f054e",
            price: 18.38101,
            price_sources: [],
            processed_ms: 1719873077797,
          },
        ],
        position_type: "FLAT",
        position_uuid: "63e562c1-da62-4bd0-9488-bfb369b3e0fd",
        return_at_close: 1.0000084989131965,
        trade_pair: ["USDMXN", "USD/MXN", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 18.37588,
        close_ms: 1719876664918,
        current_return: 1.0000107177287556,
        initial_entry_price: 18.38076,
        is_closed_position: true,
        miner_hotkey: "5F7ThFe3ZRxTVFjz4M8ciTHbcVAdVDHLZ7SDyMgdFJpdLdEm",
        net_leverage: 0.0,
        open_ms: 1719873107203,
        orders: [
          {
            leverage: 0.05,
            order_type: "LONG",
            order_uuid: "4daf8a8a-3657-4af4-8da9-dddd5eac1fd6",
            price: 18.38076,
            price_sources: [],
            processed_ms: 1719873106812,
          },
          {
            leverage: 0.05,
            order_type: "LONG",
            order_uuid: "2a1a951f-8eaa-4389-ac8e-2bc249fa6e21",
            price: 18.371,
            price_sources: [],
            processed_ms: 1719876636501,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "3264df0b-3399-4083-a0a6-88a99f65a3a2",
            price: 18.37785,
            price_sources: [],
            processed_ms: 1719876664918,
          },
        ],
        position_type: "FLAT",
        position_uuid: "75dda438-ef8e-4863-a35f-d824f485984e",
        return_at_close: 1.0000037176537315,
        trade_pair: ["USDMXN", "USD/MXN", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 18.3682,
        close_ms: 1719881170140,
        current_return: 1.0000640509140797,
        initial_entry_price: 18.3682,
        is_closed_position: true,
        miner_hotkey: "5F7ThFe3ZRxTVFjz4M8ciTHbcVAdVDHLZ7SDyMgdFJpdLdEm",
        net_leverage: 0.0,
        open_ms: 1719876696368,
        orders: [
          {
            leverage: 0.05,
            order_type: "LONG",
            order_uuid: "db5b65ba-1559-40e8-a706-18eddef597a7",
            price: 18.3682,
            price_sources: [],
            processed_ms: 1719876695093,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "9230411d-56c3-4f6a-86ac-20808c58c8b9",
            price: 18.39173,
            price_sources: [],
            processed_ms: 1719881170140,
          },
        ],
        position_type: "FLAT",
        position_uuid: "f3c05b97-9540-4782-bb64-a730adb80af0",
        return_at_close: 1.0000605506899014,
        trade_pair: ["USDMXN", "USD/MXN", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 18.38993,
        close_ms: 1719885677919,
        current_return: 1.000023341578788,
        initial_entry_price: 18.38993,
        is_closed_position: true,
        miner_hotkey: "5F7ThFe3ZRxTVFjz4M8ciTHbcVAdVDHLZ7SDyMgdFJpdLdEm",
        net_leverage: 0.0,
        open_ms: 1719881199608,
        orders: [
          {
            leverage: 0.05,
            order_type: "LONG",
            order_uuid: "4dcd71f8-bacf-4881-8401-0a7b6d4f1161",
            price: 18.38993,
            price_sources: [],
            processed_ms: 1719881199353,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "1555d284-e664-4911-8fe3-799bb6683df4",
            price: 18.398515,
            price_sources: [],
            processed_ms: 1719885677919,
          },
        ],
        position_type: "FLAT",
        position_uuid: "2044ba30-428e-4f12-91b6-978988f9cd5f",
        return_at_close: 1.0000198414970924,
        trade_pair: ["USDMXN", "USD/MXN", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 18.40118,
        close_ms: 1719886611879,
        current_return: 1.0000278786469128,
        initial_entry_price: 18.40118,
        is_closed_position: true,
        miner_hotkey: "5F7ThFe3ZRxTVFjz4M8ciTHbcVAdVDHLZ7SDyMgdFJpdLdEm",
        net_leverage: 0.0,
        open_ms: 1719885708890,
        orders: [
          {
            leverage: 0.05,
            order_type: "LONG",
            order_uuid: "9bbe8390-0e7e-49bb-9879-1e3d2fcdb947",
            price: 18.40118,
            price_sources: [],
            processed_ms: 1719885708224,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "42cf0011-3008-4724-8a9a-443052446f58",
            price: 18.41144,
            price_sources: [],
            processed_ms: 1719886611879,
          },
        ],
        position_type: "FLAT",
        position_uuid: "9dbcef33-6b41-470d-bf18-66d27acbdd41",
        return_at_close: 1.0000243785493375,
        trade_pair: ["USDMXN", "USD/MXN", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 18.41628,
        close_ms: 1719887815176,
        current_return: 1.0000462905646526,
        initial_entry_price: 18.41628,
        is_closed_position: true,
        miner_hotkey: "5F7ThFe3ZRxTVFjz4M8ciTHbcVAdVDHLZ7SDyMgdFJpdLdEm",
        net_leverage: 0.0,
        open_ms: 1719886641499,
        orders: [
          {
            leverage: 0.05,
            order_type: "LONG",
            order_uuid: "12dfe4ba-f36d-46d1-ab36-4333051a1b0f",
            price: 18.41628,
            price_sources: [],
            processed_ms: 1719886641091,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "ba2594cb-d8da-4e46-8369-daf940b491f9",
            price: 18.43333,
            price_sources: [],
            processed_ms: 1719887815176,
          },
        ],
        position_type: "FLAT",
        position_uuid: "7aa9196b-a7e3-443a-ba1c-26b4477be544",
        return_at_close: 1.0000427904026354,
        trade_pair: ["USDMXN", "USD/MXN", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 18.429185714285715,
        close_ms: 1719894520341,
        current_return: 1.0000196723018655,
        initial_entry_price: 18.43709,
        is_closed_position: true,
        miner_hotkey: "5F7ThFe3ZRxTVFjz4M8ciTHbcVAdVDHLZ7SDyMgdFJpdLdEm",
        net_leverage: 0.0,
        open_ms: 1719887845253,
        orders: [
          {
            leverage: 0.05,
            order_type: "LONG",
            order_uuid: "fc4d82d8-79e1-4c12-b138-a82a8f3f4911",
            price: 18.43709,
            price_sources: [],
            processed_ms: 1719887844780,
          },
          {
            leverage: 0.05500000000000001,
            order_type: "LONG",
            order_uuid: "4399e0ef-92e4-4a5c-b394-abf920c909d3",
            price: 18.422,
            price_sources: [],
            processed_ms: 1719888469966,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "befea90d-52bc-4802-b014-a85f483978b9",
            price: 18.43264,
            price_sources: [],
            processed_ms: 1719894520341,
          },
        ],
        position_type: "FLAT",
        position_uuid: "b810734e-a09c-4d1d-b27d-1568ec5dafa8",
        return_at_close: 1.000012322157274,
        trade_pair: ["USDMXN", "USD/MXN", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 18.41700227272727,
        close_ms: 1719896940519,
        current_return: 1.000092310956967,
        initial_entry_price: 18.425765,
        is_closed_position: true,
        miner_hotkey: "5F7ThFe3ZRxTVFjz4M8ciTHbcVAdVDHLZ7SDyMgdFJpdLdEm",
        net_leverage: 0.0,
        open_ms: 1719894548914,
        orders: [
          {
            leverage: 0.05,
            order_type: "LONG",
            order_uuid: "1123f1c0-7b77-4a36-8d1e-e850a69ab499",
            price: 18.425765,
            price_sources: [],
            processed_ms: 1719894548835,
          },
          {
            leverage: 0.06,
            order_type: "LONG",
            order_uuid: "20656330-6e45-417e-996a-8453c376d7dd",
            price: 18.4097,
            price_sources: [],
            processed_ms: 1719895558892,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "54684892-73d5-4e4a-bb86-8b125a79697d",
            price: 18.432465,
            price_sources: [],
            processed_ms: 1719896940519,
          },
        ],
        position_type: "FLAT",
        position_uuid: "59ed213c-026b-494a-8487-4afa98597eda",
        return_at_close: 1.0000846102461727,
        trade_pair: ["USDMXN", "USD/MXN", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 18.419774999999998,
        close_ms: 1719900380277,
        current_return: 1.0000072971236855,
        initial_entry_price: 18.43192,
        is_closed_position: true,
        miner_hotkey: "5F7ThFe3ZRxTVFjz4M8ciTHbcVAdVDHLZ7SDyMgdFJpdLdEm",
        net_leverage: 0.0,
        open_ms: 1719896971871,
        orders: [
          {
            leverage: 0.05,
            order_type: "LONG",
            order_uuid: "4f002f38-3c3e-4f2f-a483-e0071c010860",
            price: 18.43192,
            price_sources: [],
            processed_ms: 1719896971807,
          },
          {
            leverage: 0.05,
            order_type: "LONG",
            order_uuid: "e6be72dd-f783-4371-9e06-ba297994c6a0",
            price: 18.40763,
            price_sources: [],
            processed_ms: 1719900093782,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "2a4913bb-36ee-42e5-9faf-7362b87ab64a",
            price: 18.42112,
            price_sources: [],
            processed_ms: 1719900380277,
          },
        ],
        position_type: "FLAT",
        position_uuid: "0530a81c-75f4-4c47-80b1-3d3f2d85e780",
        return_at_close: 1.0000002970726056,
        trade_pair: ["USDMXN", "USD/MXN", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 18.412500000000005,
        close_ms: 1719901168871,
        current_return: 1.0000041804567448,
        initial_entry_price: 18.41904,
        is_closed_position: true,
        miner_hotkey: "5F7ThFe3ZRxTVFjz4M8ciTHbcVAdVDHLZ7SDyMgdFJpdLdEm",
        net_leverage: 0.0,
        open_ms: 1719900410015,
        orders: [
          {
            leverage: 0.05,
            order_type: "LONG",
            order_uuid: "a61203b4-7c19-4972-be54-af01fc1dfa0b",
            price: 18.41904,
            price_sources: [],
            processed_ms: 1719900408931,
          },
          {
            leverage: 0.06,
            order_type: "LONG",
            order_uuid: "602ee3e1-91ca-4bbc-8145-981b1e2f3446",
            price: 18.40705,
            price_sources: [],
            processed_ms: 1719900943961,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "0db1bec9-abe0-4fdb-aa32-8e24b0129923",
            price: 18.4132,
            price_sources: [],
            processed_ms: 1719901168871,
          },
        ],
        position_type: "FLAT",
        position_uuid: "5a705cd0-bd11-463d-8e6b-1d1f2179f5d0",
        return_at_close: 0.9999964804245552,
        trade_pair: ["USDMXN", "USD/MXN", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 18.414,
        close_ms: 1719902434824,
        current_return: 1.0000411643314868,
        initial_entry_price: 18.414,
        is_closed_position: true,
        miner_hotkey: "5F7ThFe3ZRxTVFjz4M8ciTHbcVAdVDHLZ7SDyMgdFJpdLdEm",
        net_leverage: 0.0,
        open_ms: 1719901198128,
        orders: [
          {
            leverage: 0.05,
            order_type: "LONG",
            order_uuid: "80770374-dfc7-47bc-9241-6199bcb35661",
            price: 18.414,
            price_sources: [],
            processed_ms: 1719901198048,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "499a67b4-5b90-4813-86e9-484d36720d9c",
            price: 18.42916,
            price_sources: [],
            processed_ms: 1719902434824,
          },
        ],
        position_type: "FLAT",
        position_uuid: "881e69c7-7906-47b5-a2e8-5336543f6d41",
        return_at_close: 1.0000376641874116,
        trade_pair: ["USDMXN", "USD/MXN", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 18.4264425,
        close_ms: 1719903663691,
        current_return: 1.0000670381636072,
        initial_entry_price: 18.43353,
        is_closed_position: true,
        miner_hotkey: "5F7ThFe3ZRxTVFjz4M8ciTHbcVAdVDHLZ7SDyMgdFJpdLdEm",
        net_leverage: 0.0,
        open_ms: 1719902464011,
        orders: [
          {
            leverage: 0.05,
            order_type: "LONG",
            order_uuid: "2c3e6611-f063-4cd1-83a7-0c4712bb83c0",
            price: 18.43353,
            price_sources: [],
            processed_ms: 1719902463951,
          },
          {
            leverage: 0.05,
            order_type: "LONG",
            order_uuid: "76b62e5c-7f08-40a6-a0bb-b82f7a12abcc",
            price: 18.419355000000003,
            price_sources: [],
            processed_ms: 1719903309856,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "e0ad37c9-4143-447f-bc4c-5990089804b9",
            price: 18.4388,
            price_sources: [],
            processed_ms: 1719903663691,
          },
        ],
        position_type: "FLAT",
        position_uuid: "c39669d0-d3fe-4703-90c4-8e04a7d5d8c6",
        return_at_close: 1.00006003769434,
        trade_pair: ["USDMXN", "USD/MXN", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 18.4395,
        close_ms: 1719903939625,
        current_return: 1.0000603053228125,
        initial_entry_price: 18.4395,
        is_closed_position: true,
        miner_hotkey: "5F7ThFe3ZRxTVFjz4M8ciTHbcVAdVDHLZ7SDyMgdFJpdLdEm",
        net_leverage: 0.0,
        open_ms: 1719903693662,
        orders: [
          {
            leverage: 0.05,
            order_type: "LONG",
            order_uuid: "76359a7b-e39a-4695-86d2-99f03de27577",
            price: 18.4395,
            price_sources: [],
            processed_ms: 1719903693264,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "c246f7ad-75fc-4720-adde-6bcffff5501f",
            price: 18.46174,
            price_sources: [],
            processed_ms: 1719903939625,
          },
        ],
        position_type: "FLAT",
        position_uuid: "304d64df-3f4b-49f4-a831-fcb7c3aae9db",
        return_at_close: 1.0000568051117438,
        trade_pair: ["USDMXN", "USD/MXN", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 18.45782,
        close_ms: 1719904370606,
        current_return: 1.0000637128328265,
        initial_entry_price: 18.45782,
        is_closed_position: true,
        miner_hotkey: "5F7ThFe3ZRxTVFjz4M8ciTHbcVAdVDHLZ7SDyMgdFJpdLdEm",
        net_leverage: 0.0,
        open_ms: 1719903969142,
        orders: [
          {
            leverage: 0.05,
            order_type: "LONG",
            order_uuid: "206df8dd-4592-4a24-a3e3-d8ce476b2daf",
            price: 18.45782,
            price_sources: [],
            processed_ms: 1719903968911,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "8fb47d89-ceaa-4167-986f-ad34a6991bc6",
            price: 18.48134,
            price_sources: [],
            processed_ms: 1719904370606,
          },
        ],
        position_type: "FLAT",
        position_uuid: "4539219e-64a0-4edf-9517-0d2601a49273",
        return_at_close: 1.0000602126098315,
        trade_pair: ["USDMXN", "USD/MXN", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 18.473170909090907,
        close_ms: 1719905011716,
        current_return: 1.0000325493041342,
        initial_entry_price: 18.48273,
        is_closed_position: true,
        miner_hotkey: "5F7ThFe3ZRxTVFjz4M8ciTHbcVAdVDHLZ7SDyMgdFJpdLdEm",
        net_leverage: 0.0,
        open_ms: 1719904400811,
        orders: [
          {
            leverage: 0.05,
            order_type: "LONG",
            order_uuid: "a9851e16-80ed-4434-86f2-8de4f3d82745",
            price: 18.48273,
            price_sources: [],
            processed_ms: 1719904399608,
          },
          {
            leverage: 0.06,
            order_type: "LONG",
            order_uuid: "75c8169b-2ba5-4341-996a-a68dec998fb1",
            price: 18.465204999999997,
            price_sources: [],
            processed_ms: 1719904814987,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "0abfc0b0-5df1-42ed-b7e7-0c60aeb4c9a7",
            price: 18.47864,
            price_sources: [],
            processed_ms: 1719905011716,
          },
        ],
        position_type: "FLAT",
        position_uuid: "3f7ab571-f31f-4f09-b232-3d845d93f08d",
        return_at_close: 1.0000248490535044,
        trade_pair: ["USDMXN", "USD/MXN", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 18.466112727272726,
        close_ms: 1719905940600,
        current_return: 0.9999915300102831,
        initial_entry_price: 18.477,
        is_closed_position: true,
        miner_hotkey: "5F7ThFe3ZRxTVFjz4M8ciTHbcVAdVDHLZ7SDyMgdFJpdLdEm",
        net_leverage: 0.0,
        open_ms: 1719905047406,
        orders: [
          {
            leverage: 0.05,
            order_type: "LONG",
            order_uuid: "925dade3-bbcf-41dd-9d55-fc0e039fb455",
            price: 18.477,
            price_sources: [],
            processed_ms: 1719905047010,
          },
          {
            leverage: 0.06,
            order_type: "LONG",
            order_uuid: "88c4f837-73d3-49d1-b69b-f99393401328",
            price: 18.45704,
            price_sources: [],
            processed_ms: 1719905839163,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "11297536-fca2-469d-88d4-8f96b378c3f8",
            price: 18.46469,
            price_sources: [],
            processed_ms: 1719905940600,
          },
        ],
        position_type: "FLAT",
        position_uuid: "5eb0170e-17fd-4fa2-a141-cd826ef00c8f",
        return_at_close: 0.999983830075502,
        trade_pair: ["USDMXN", "USD/MXN", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 18.460994999999997,
        close_ms: 1719906308240,
        current_return: 1.0000388521853778,
        initial_entry_price: 18.460994999999997,
        is_closed_position: true,
        miner_hotkey: "5F7ThFe3ZRxTVFjz4M8ciTHbcVAdVDHLZ7SDyMgdFJpdLdEm",
        net_leverage: 0.0,
        open_ms: 1719905971991,
        orders: [
          {
            leverage: 0.05,
            order_type: "LONG",
            order_uuid: "1bc9ef2f-1e9d-4607-af9b-ed12a7195d00",
            price: 18.460994999999997,
            price_sources: [],
            processed_ms: 1719905970117,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "fd61904e-70f9-44bc-976d-65a95345800c",
            price: 18.47534,
            price_sources: [],
            processed_ms: 1719906308240,
          },
        ],
        position_type: "FLAT",
        position_uuid: "90801e09-66e2-45d2-a0d7-89ecc8d3401b",
        return_at_close: 1.0000353520493952,
        trade_pair: ["USDMXN", "USD/MXN", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 18.469620000000003,
        close_ms: 1719906895352,
        current_return: 0.9999756426813835,
        initial_entry_price: 18.47494,
        is_closed_position: true,
        miner_hotkey: "5F7ThFe3ZRxTVFjz4M8ciTHbcVAdVDHLZ7SDyMgdFJpdLdEm",
        net_leverage: 0.0,
        open_ms: 1719906336347,
        orders: [
          {
            leverage: 0.05,
            order_type: "LONG",
            order_uuid: "8fdaf1dc-26c8-422a-bc17-d0291e6f4d29",
            price: 18.47494,
            price_sources: [],
            processed_ms: 1719906336304,
          },
          {
            leverage: 0.05,
            order_type: "LONG",
            order_uuid: "63be51b2-d353-4def-85ce-4be9bc0d5098",
            price: 18.4643,
            price_sources: [],
            processed_ms: 1719906543053,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "8d4a5964-75d6-402c-afdd-632c9af34b2f",
            price: 18.46512,
            price_sources: [],
            processed_ms: 1719906895352,
          },
        ],
        position_type: "FLAT",
        position_uuid: "30f1e8fb-328e-4cd3-8f0e-c2593a9e51da",
        return_at_close: 0.9999686428518848,
        trade_pair: ["USDMXN", "USD/MXN", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 18.433782348484847,
        close_ms: 1719910512009,
        current_return: 0.9998845913669844,
        initial_entry_price: 18.464,
        is_closed_position: true,
        miner_hotkey: "5F7ThFe3ZRxTVFjz4M8ciTHbcVAdVDHLZ7SDyMgdFJpdLdEm",
        net_leverage: 0.0,
        open_ms: 1719906924855,
        orders: [
          {
            leverage: 0.05,
            order_type: "LONG",
            order_uuid: "0a06f3f9-6e8e-41ee-bb33-b365bd0e880f",
            price: 18.464,
            price_sources: [],
            processed_ms: 1719906924360,
          },
          {
            leverage: 0.05500000000000001,
            order_type: "LONG",
            order_uuid: "26d5c528-696f-4b7a-9fc6-ef1df06cc001",
            price: 18.46309,
            price_sources: [],
            processed_ms: 1719907572977,
          },
          {
            leverage: 0.126,
            order_type: "LONG",
            order_uuid: "694fc222-f493-43e5-a0c7-74b22f8ef1ba",
            price: 18.43246,
            price_sources: [],
            processed_ms: 1719909306655,
          },
          {
            leverage: 0.231,
            order_type: "LONG",
            order_uuid: "b49c6d6c-f89e-4b7a-8937-2403e1a7b943",
            price: 18.420985,
            price_sources: [],
            processed_ms: 1719910075497,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "3eed9d5b-61e4-4f46-9690-a6df10a2320a",
            price: 18.42917,
            price_sources: [],
            processed_ms: 1719910512009,
          },
        ],
        position_type: "FLAT",
        position_uuid: "9c54e1cc-372f-4cd4-8687-311b229f6547",
        return_at_close: 0.9998522550992996,
        trade_pair: ["USDMXN", "USD/MXN", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 18.42711636363636,
        close_ms: 1719911628614,
        current_return: 1.0000314126240772,
        initial_entry_price: 18.43208,
        is_closed_position: true,
        miner_hotkey: "5F7ThFe3ZRxTVFjz4M8ciTHbcVAdVDHLZ7SDyMgdFJpdLdEm",
        net_leverage: 0.0,
        open_ms: 1719910540971,
        orders: [
          {
            leverage: 0.05,
            order_type: "LONG",
            order_uuid: "13ccd811-31a4-43d9-b1cb-187ff0148f24",
            price: 18.43208,
            price_sources: [],
            processed_ms: 1719910540865,
          },
          {
            leverage: 0.06,
            order_type: "LONG",
            order_uuid: "ff7b2062-177e-46c8-b889-8b89eae0fbff",
            price: 18.42298,
            price_sources: [],
            processed_ms: 1719910809501,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "a2320f76-4199-4020-8013-3f13d82e9518",
            price: 18.43238,
            price_sources: [],
            processed_ms: 1719911628614,
          },
        ],
        position_type: "FLAT",
        position_uuid: "68026fe9-c18b-4983-bc95-d648ef7f1441",
        return_at_close: 1.0000237123822,
        trade_pair: ["USDMXN", "USD/MXN", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 18.428,
        close_ms: 1719912321380,
        current_return: 1.000067668764923,
        initial_entry_price: 18.428,
        is_closed_position: true,
        miner_hotkey: "5F7ThFe3ZRxTVFjz4M8ciTHbcVAdVDHLZ7SDyMgdFJpdLdEm",
        net_leverage: 0.0,
        open_ms: 1719911659271,
        orders: [
          {
            leverage: 0.05,
            order_type: "LONG",
            order_uuid: "ea0bff19-6182-4925-b651-b84409c90781",
            price: 18.428,
            price_sources: [],
            processed_ms: 1719911658463,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "68eab07d-63f3-4e1d-8dbf-6c160a77b9f0",
            price: 18.45294,
            price_sources: [],
            processed_ms: 1719912321380,
          },
        ],
        position_type: "FLAT",
        position_uuid: "d07d82e5-8d00-4dc5-afad-e8d8ef83de82",
        return_at_close: 1.0000641685280822,
        trade_pair: ["USDMXN", "USD/MXN", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 18.44367738095238,
        close_ms: 1719913625002,
        current_return: 1.000010144651044,
        initial_entry_price: 18.450609999999998,
        is_closed_position: true,
        miner_hotkey: "5F7ThFe3ZRxTVFjz4M8ciTHbcVAdVDHLZ7SDyMgdFJpdLdEm",
        net_leverage: 0.0,
        open_ms: 1719912351233,
        orders: [
          {
            leverage: 0.05,
            order_type: "LONG",
            order_uuid: "1f626cd2-e66d-4bd8-9ed9-048edc645288",
            price: 18.450609999999998,
            price_sources: [],
            processed_ms: 1719912351071,
          },
          {
            leverage: 0.05500000000000001,
            order_type: "LONG",
            order_uuid: "fa772e8f-520b-4789-b559-da95a7af0736",
            price: 18.437375,
            price_sources: [],
            processed_ms: 1719913029868,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "e32f31d8-1312-4d85-b41d-e0cb103f7ee1",
            price: 18.44546,
            price_sources: [],
            processed_ms: 1719913625002,
          },
        ],
        position_type: "FLAT",
        position_uuid: "f59b7cac-4bc4-4496-9efc-3748b4fa5e73",
        return_at_close: 1.000002794576481,
        trade_pair: ["USDMXN", "USD/MXN", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 18.40553729338843,
        close_ms: 1719918015580,
        current_return: 1.000032346014473,
        initial_entry_price: 18.44524,
        is_closed_position: true,
        miner_hotkey: "5F7ThFe3ZRxTVFjz4M8ciTHbcVAdVDHLZ7SDyMgdFJpdLdEm",
        net_leverage: 0.0,
        open_ms: 1719913655572,
        orders: [
          {
            leverage: 0.05,
            order_type: "LONG",
            order_uuid: "801a1eb9-acbd-4ea3-b1e0-4a692ef99a9b",
            price: 18.44524,
            price_sources: [],
            processed_ms: 1719913655473,
          },
          {
            leverage: 0.06,
            order_type: "LONG",
            order_uuid: "4232ac06-2563-4470-b021-d98c679fa591",
            price: 18.423,
            price_sources: [],
            processed_ms: 1719914055571,
          },
          {
            leverage: 0.11,
            order_type: "LONG",
            order_uuid: "3ed2ed9e-504a-4219-9a91-33bdba5a65f3",
            price: 18.408054999999997,
            price_sources: [],
            processed_ms: 1719915269065,
          },
          {
            leverage: 0.264,
            order_type: "LONG",
            order_uuid: "08dfaf43-764e-4d85-8707-cb3a46653a29",
            price: 18.393,
            price_sources: [],
            processed_ms: 1719917757558,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "23921677-cec9-440e-a809-d82fa24f103b",
            price: 18.40677,
            price_sources: [],
            processed_ms: 1719918015580,
          },
        ],
        position_type: "FLAT",
        position_uuid: "62980a2b-7a71-416d-a684-f64953058168",
        return_at_close: 0.9999984649185901,
        trade_pair: ["USDMXN", "USD/MXN", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 18.388624999999998,
        close_ms: 1719920449340,
        current_return: 1.0000055722875192,
        initial_entry_price: 18.3946,
        is_closed_position: true,
        miner_hotkey: "5F7ThFe3ZRxTVFjz4M8ciTHbcVAdVDHLZ7SDyMgdFJpdLdEm",
        net_leverage: 0.0,
        open_ms: 1719918050580,
        orders: [
          {
            leverage: 0.05,
            order_type: "LONG",
            order_uuid: "bd720f77-6503-4a04-8c4d-71163228a805",
            price: 18.3946,
            price_sources: [],
            processed_ms: 1719918047267,
          },
          {
            leverage: 0.05,
            order_type: "LONG",
            order_uuid: "c67de1fd-292a-4709-a86d-8096ddc0aa63",
            price: 18.382649999999998,
            price_sources: [],
            processed_ms: 1719920100618,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "e9fcfbd3-21d3-4ac3-8088-2c44965fbc8c",
            price: 18.38965,
            price_sources: [],
            processed_ms: 1719920449340,
          },
        ],
        position_type: "FLAT",
        position_uuid: "39ea3b35-9ff7-4622-9e07-8b8a6ae7bbaa",
        return_at_close: 0.9999985722485132,
        trade_pair: ["USDMXN", "USD/MXN", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 18.301242324263036,
        close_ms: 1719922753733,
        current_return: 1.0003068489976927,
        initial_entry_price: 18.38707,
        is_closed_position: true,
        miner_hotkey: "5F7ThFe3ZRxTVFjz4M8ciTHbcVAdVDHLZ7SDyMgdFJpdLdEm",
        net_leverage: 0.0,
        open_ms: 1719920478718,
        orders: [
          {
            leverage: 0.05,
            order_type: "LONG",
            order_uuid: "f292167e-9c1e-4b79-94f8-337550bb71de",
            price: 18.38707,
            price_sources: [],
            processed_ms: 1719920478647,
          },
          {
            leverage: 0.05500000000000001,
            order_type: "LONG",
            order_uuid: "66703227-6b61-4bc0-afba-6c0a26808094",
            price: 18.36275,
            price_sources: [],
            processed_ms: 1719921778280,
          },
          {
            leverage: 0.10500000000000001,
            order_type: "LONG",
            order_uuid: "fd54c513-c71a-4971-8d9d-1873eae073ce",
            price: 18.3524,
            price_sources: [],
            processed_ms: 1719921808258,
          },
          {
            leverage: 0.252,
            order_type: "LONG",
            order_uuid: "1b17bc6d-c6a0-4235-bffc-a127cd2e3f4f",
            price: 18.322325,
            price_sources: [],
            processed_ms: 1719922216301,
          },
          {
            leverage: 0.5082000000000001,
            order_type: "LONG",
            order_uuid: "d8869718-eef1-4a98-8c90-a27a86cb447a",
            price: 18.31178,
            price_sources: [],
            processed_ms: 1719922602724,
          },
          {
            leverage: 0.9702000000000002,
            order_type: "LONG",
            order_uuid: "5d6a8ed7-af43-48a6-8e42-f447851b8856",
            price: 18.2768,
            price_sources: [],
            processed_ms: 1719922698072,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "5c49f316-6680-4ef5-ab6b-86fd3ba4b48e",
            price: 18.30415,
            price_sources: [],
            processed_ms: 1719922753733,
          },
        ],
        position_type: "FLAT",
        position_uuid: "596a8463-f6f0-4f1e-88f0-df59eb747d4b",
        return_at_close: 1.000170979319007,
        trade_pair: ["USDMXN", "USD/MXN", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 18.30679,
        close_ms: 1719922984177,
        current_return: 1.0000414054020395,
        initial_entry_price: 18.30679,
        is_closed_position: true,
        miner_hotkey: "5F7ThFe3ZRxTVFjz4M8ciTHbcVAdVDHLZ7SDyMgdFJpdLdEm",
        net_leverage: 0.0,
        open_ms: 1719922785288,
        orders: [
          {
            leverage: 0.05,
            order_type: "LONG",
            order_uuid: "fa2b4cd9-3bfd-4d53-b70c-03f45a3260f3",
            price: 18.30679,
            price_sources: [],
            processed_ms: 1719922785012,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "e6526c2e-d0eb-4553-80f7-5e6954caf8cf",
            price: 18.32195,
            price_sources: [],
            processed_ms: 1719922984177,
          },
        ],
        position_type: "FLAT",
        position_uuid: "dcbeeb33-caa3-4e0c-bba4-3dde6b366515",
        return_at_close: 1.0000379052571204,
        trade_pair: ["USDMXN", "USD/MXN", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 18.317195,
        close_ms: 1719923650032,
        current_return: 1.0000260112593544,
        initial_entry_price: 18.31899,
        is_closed_position: true,
        miner_hotkey: "5F7ThFe3ZRxTVFjz4M8ciTHbcVAdVDHLZ7SDyMgdFJpdLdEm",
        net_leverage: 0.0,
        open_ms: 1719923016132,
        orders: [
          {
            leverage: 0.05,
            order_type: "LONG",
            order_uuid: "c9193f22-5b6d-4cec-9778-8a5a21cd356e",
            price: 18.31899,
            price_sources: [],
            processed_ms: 1719923015831,
          },
          {
            leverage: 0.05,
            order_type: "LONG",
            order_uuid: "3d5056b2-bea3-44f3-9b68-9e2e73120670",
            price: 18.3154,
            price_sources: [],
            processed_ms: 1719923566676,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "dc386ab9-b84c-4cfa-bce6-b0626c67b338",
            price: 18.32196,
            price_sources: [],
            processed_ms: 1719923650032,
          },
        ],
        position_type: "FLAT",
        position_uuid: "07a453a2-184f-49ce-8a55-7c60a054452d",
        return_at_close: 1.0000190110772755,
        trade_pair: ["USDMXN", "USD/MXN", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 18.302638354978356,
        close_ms: 1719925609960,
        current_return: 0.9999701397470101,
        initial_entry_price: 18.32168,
        is_closed_position: true,
        miner_hotkey: "5F7ThFe3ZRxTVFjz4M8ciTHbcVAdVDHLZ7SDyMgdFJpdLdEm",
        net_leverage: 0.0,
        open_ms: 1719923681496,
        orders: [
          {
            leverage: 0.05,
            order_type: "LONG",
            order_uuid: "e7ece439-61ea-45ef-9546-d7907763ce32",
            price: 18.32168,
            price_sources: [],
            processed_ms: 1719923681088,
          },
          {
            leverage: 0.06,
            order_type: "LONG",
            order_uuid: "a60c0013-5a2e-4221-b879-956eb3191f47",
            price: 18.31375,
            price_sources: [],
            processed_ms: 1719924382336,
          },
          {
            leverage: 0.12100000000000001,
            order_type: "LONG",
            order_uuid: "a532105f-1d7f-4741-8290-389951a5e303",
            price: 18.28926,
            price_sources: [],
            processed_ms: 1719924917781,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "0fe87d29-41c7-47d7-9654-c0aed31ebfab",
            price: 18.30027,
            price_sources: [],
            processed_ms: 1719925609960,
          },
        ],
        position_type: "FLAT",
        position_uuid: "9e9de84a-9320-4f45-a82c-e57a9b098a7c",
        return_at_close: 0.9999539702298504,
        trade_pair: ["USDMXN", "USD/MXN", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 18.30295,
        close_ms: 1719926072342,
        current_return: 1.0000551823613133,
        initial_entry_price: 18.30295,
        is_closed_position: true,
        miner_hotkey: "5F7ThFe3ZRxTVFjz4M8ciTHbcVAdVDHLZ7SDyMgdFJpdLdEm",
        net_leverage: 0.0,
        open_ms: 1719925640138,
        orders: [
          {
            leverage: 0.05,
            order_type: "LONG",
            order_uuid: "5da226a7-bc01-49d1-929f-6588d9ef4573",
            price: 18.30295,
            price_sources: [],
            processed_ms: 1719925639886,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "3022be94-cbf1-4cc8-9eb1-b12bca1083be",
            price: 18.32315,
            price_sources: [],
            processed_ms: 1719926072342,
          },
        ],
        position_type: "FLAT",
        position_uuid: "b24d3554-ccd2-4bb6-a0a2-b611acf9a84c",
        return_at_close: 1.000051682168175,
        trade_pair: ["USDMXN", "USD/MXN", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 18.3134725,
        close_ms: 1719926985263,
        current_return: 1.0000083372904762,
        initial_entry_price: 18.3213,
        is_closed_position: true,
        miner_hotkey: "5F7ThFe3ZRxTVFjz4M8ciTHbcVAdVDHLZ7SDyMgdFJpdLdEm",
        net_leverage: 0.0,
        open_ms: 1719926101963,
        orders: [
          {
            leverage: 0.05,
            order_type: "LONG",
            order_uuid: "827af84f-e375-49d7-9368-a7946f458bc4",
            price: 18.3213,
            price_sources: [],
            processed_ms: 1719926101814,
          },
          {
            leverage: 0.05,
            order_type: "LONG",
            order_uuid: "1071602e-9d7d-41c8-ba5b-fa59ac21a8f4",
            price: 18.305645,
            price_sources: [],
            processed_ms: 1719926245333,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "6cc3249b-0887-49b3-b480-6836d5635b3c",
            price: 18.315,
            price_sources: [],
            processed_ms: 1719926985263,
          },
        ],
        position_type: "FLAT",
        position_uuid: "0753c901-b5ed-483d-b291-caa37a24a45c",
        return_at_close: 1.0000013372321153,
        trade_pair: ["USDMXN", "USD/MXN", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 18.305793719008264,
        close_ms: 1719928881184,
        current_return: 0.9999875316649196,
        initial_entry_price: 18.3168,
        is_closed_position: true,
        miner_hotkey: "5F7ThFe3ZRxTVFjz4M8ciTHbcVAdVDHLZ7SDyMgdFJpdLdEm",
        net_leverage: 0.0,
        open_ms: 1719927014915,
        orders: [
          {
            leverage: 0.05,
            order_type: "LONG",
            order_uuid: "99d78fa6-46e6-4199-a533-d364309b1a52",
            price: 18.3168,
            price_sources: [],
            processed_ms: 1719927014778,
          },
          {
            leverage: 0.06,
            order_type: "LONG",
            order_uuid: "cf446e72-ec51-4609-852e-05b4da57e8fa",
            price: 18.32039,
            price_sources: [],
            processed_ms: 1719927110080,
          },
          {
            leverage: 0.132,
            order_type: "LONG",
            order_uuid: "17d95a6f-7fcb-496a-90ed-62d334d442b8",
            price: 18.29499,
            price_sources: [],
            processed_ms: 1719927595436,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "b3525768-1bd7-47d2-bf58-a010ed355489",
            price: 18.30485,
            price_sources: [],
            processed_ms: 1719928881184,
          },
        ],
        position_type: "FLAT",
        position_uuid: "94bc39ab-e1ed-4dbf-9752-e70cfd2c3cbd",
        return_at_close: 0.9999705918761332,
        trade_pair: ["USDMXN", "USD/MXN", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 18.270347023809524,
        close_ms: 1719929407173,
        current_return: 1.0001087996259086,
        initial_entry_price: 18.2843,
        is_closed_position: true,
        miner_hotkey: "5F7ThFe3ZRxTVFjz4M8ciTHbcVAdVDHLZ7SDyMgdFJpdLdEm",
        net_leverage: 0.0,
        open_ms: 1719928913210,
        orders: [
          {
            leverage: 0.05,
            order_type: "LONG",
            order_uuid: "6082e101-55e8-4f98-af68-00e0eefecc13",
            price: 18.2843,
            price_sources: [],
            processed_ms: 1719928913058,
          },
          {
            leverage: 0.05500000000000001,
            order_type: "LONG",
            order_uuid: "1167b611-940c-448e-ad52-4e6bb1abef91",
            price: 18.2914,
            price_sources: [],
            processed_ms: 1719929042951,
          },
          {
            leverage: 0.10500000000000001,
            order_type: "LONG",
            order_uuid: "05c09ca2-629b-4be4-8892-5e8f59c36183",
            price: 18.252675,
            price_sources: [],
            processed_ms: 1719929289296,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "097d9d19-d39b-4b0d-865b-742270521759",
            price: 18.27982,
            price_sources: [],
            processed_ms: 1719929407173,
          },
        ],
        position_type: "FLAT",
        position_uuid: "b7e7965b-6167-4608-b6f9-7e18669894be",
        return_at_close: 1.000094098026554,
        trade_pair: ["USDMXN", "USD/MXN", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 18.27584285714286,
        close_ms: 1719929529086,
        current_return: 1.0000622107905879,
        initial_entry_price: 18.2826,
        is_closed_position: true,
        miner_hotkey: "5F7ThFe3ZRxTVFjz4M8ciTHbcVAdVDHLZ7SDyMgdFJpdLdEm",
        net_leverage: 0.0,
        open_ms: 1719929436773,
        orders: [
          {
            leverage: 0.05,
            order_type: "LONG",
            order_uuid: "f00b2acf-c9bf-4074-a7cc-fec7cbf12033",
            price: 18.2826,
            price_sources: [],
            processed_ms: 1719929436744,
          },
          {
            leverage: 0.05500000000000001,
            order_type: "LONG",
            order_uuid: "d5f214f9-84f3-49f3-be07-ffbf8339a16c",
            price: 18.2697,
            price_sources: [],
            processed_ms: 1719929491400,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "4efc9a31-4e9d-49d0-8fd0-34c5647114fc",
            price: 18.286675000000002,
            price_sources: [],
            processed_ms: 1719929529086,
          },
        ],
        position_type: "FLAT",
        position_uuid: "7f34a902-d471-429b-8667-a0fc4c51b488",
        return_at_close: 1.0000548603333386,
        trade_pair: ["USDMXN", "USD/MXN", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 18.29536,
        close_ms: 1719929863257,
        current_return: 1.0000167802109388,
        initial_entry_price: 18.29536,
        is_closed_position: true,
        miner_hotkey: "5F7ThFe3ZRxTVFjz4M8ciTHbcVAdVDHLZ7SDyMgdFJpdLdEm",
        net_leverage: 0.0,
        open_ms: 1719929559240,
        orders: [
          {
            leverage: 0.05,
            order_type: "LONG",
            order_uuid: "f5bf346e-decd-49e4-b5e2-27a17b7ee449",
            price: 18.29536,
            price_sources: [],
            processed_ms: 1719929559124,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "c8a62390-32df-455d-a13a-4095e7275b2f",
            price: 18.3015,
            price_sources: [],
            processed_ms: 1719929863257,
          },
        ],
        position_type: "FLAT",
        position_uuid: "0d1bdd26-e683-4524-8747-4b40f124c474",
        return_at_close: 1.000013280152208,
        trade_pair: ["USDMXN", "USD/MXN", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 18.29714,
        close_ms: 1719929998776,
        current_return: 0.9999961205090111,
        initial_entry_price: 18.30137,
        is_closed_position: true,
        miner_hotkey: "5F7ThFe3ZRxTVFjz4M8ciTHbcVAdVDHLZ7SDyMgdFJpdLdEm",
        net_leverage: 0.0,
        open_ms: 1719929893021,
        orders: [
          {
            leverage: 0.05,
            order_type: "LONG",
            order_uuid: "4c55f92f-8e1c-4cb1-991f-66d7de0690d8",
            price: 18.30137,
            price_sources: [],
            processed_ms: 1719929892942,
          },
          {
            leverage: 0.05,
            order_type: "LONG",
            order_uuid: "99831726-8ed5-4e14-877e-dcc506d1bc90",
            price: 18.29291,
            price_sources: [],
            processed_ms: 1719929953092,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "001cdd3b-a1c5-4f2e-9dd2-08214d526a75",
            price: 18.29643,
            price_sources: [],
            processed_ms: 1719929998776,
          },
        ],
        position_type: "FLAT",
        position_uuid: "f627d592-ac9c-4bc5-b66e-da0658f1baa3",
        return_at_close: 0.9999891205361675,
        trade_pair: ["USDMXN", "USD/MXN", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 18.241328961038963,
        close_ms: 1719930942882,
        current_return: 0.999636480486861,
        initial_entry_price: 18.29844,
        is_closed_position: true,
        miner_hotkey: "5F7ThFe3ZRxTVFjz4M8ciTHbcVAdVDHLZ7SDyMgdFJpdLdEm",
        net_leverage: 0.0,
        open_ms: 1719930029477,
        orders: [
          {
            leverage: 0.05,
            order_type: "LONG",
            order_uuid: "8acfee29-e223-461a-8683-3ac7b742486c",
            price: 18.29844,
            price_sources: [],
            processed_ms: 1719930029140,
          },
          {
            leverage: 0.06,
            order_type: "LONG",
            order_uuid: "3c5810a2-6ef3-41ce-b1b6-99827696d962",
            price: 18.2821,
            price_sources: [],
            processed_ms: 1719930058612,
          },
          {
            leverage: 0.11,
            order_type: "LONG",
            order_uuid: "2e618df9-3e4f-4327-9f6b-4ed18f4ba4e2",
            price: 18.27437,
            price_sources: [],
            processed_ms: 1719930389590,
          },
          {
            leverage: 0.24200000000000002,
            order_type: "LONG",
            order_uuid: "a081252e-390c-43aa-9e1a-44eabf4fd0d8",
            price: 18.25543,
            price_sources: [],
            processed_ms: 1719930635564,
          },
          {
            leverage: 0.462,
            order_type: "LONG",
            order_uuid: "a5536c89-b278-4d5a-9d17-398865129e13",
            price: 18.2146,
            price_sources: [],
            processed_ms: 1719930913255,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "7ae76c6a-4aa4-4c68-b717-b308914678d2",
            price: 18.23413,
            price_sources: [],
            processed_ms: 1719930942882,
          },
        ],
        position_type: "FLAT",
        position_uuid: "4532e010-99b9-4795-92bf-7310b527a824",
        return_at_close: 0.9995718239993031,
        trade_pair: ["USDMXN", "USD/MXN", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 18.233494761904762,
        close_ms: 1719931194694,
        current_return: 1.0000351446685145,
        initial_entry_price: 18.24032,
        is_closed_position: true,
        miner_hotkey: "5F7ThFe3ZRxTVFjz4M8ciTHbcVAdVDHLZ7SDyMgdFJpdLdEm",
        net_leverage: 0.0,
        open_ms: 1719930972807,
        orders: [
          {
            leverage: 0.05,
            order_type: "LONG",
            order_uuid: "5500d4f2-3943-4f26-810a-edc9fac5c021",
            price: 18.24032,
            price_sources: [],
            processed_ms: 1719930972748,
          },
          {
            leverage: 0.05500000000000001,
            order_type: "LONG",
            order_uuid: "11b1dc82-9e9b-4c52-a893-fc031dcff5cf",
            price: 18.22729,
            price_sources: [],
            processed_ms: 1719931114234,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "f38fe0c4-2784-4229-8fbd-98d3944ee435",
            price: 18.2396,
            price_sources: [],
            processed_ms: 1719931194694,
          },
        ],
        position_type: "FLAT",
        position_uuid: "54993dc0-aaf0-478c-b2f5-8a818b603f74",
        return_at_close: 1.0000277944102012,
        trade_pair: ["USDMXN", "USD/MXN", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 18.231099999999998,
        close_ms: 1719931306488,
        current_return: 1.0000512860455881,
        initial_entry_price: 18.23108,
        is_closed_position: true,
        miner_hotkey: "5F7ThFe3ZRxTVFjz4M8ciTHbcVAdVDHLZ7SDyMgdFJpdLdEm",
        net_leverage: 0.0,
        open_ms: 1719931225328,
        orders: [
          {
            leverage: 0.05,
            order_type: "LONG",
            order_uuid: "ef042395-b592-47cb-a7ba-a57127f59b7d",
            price: 18.23108,
            price_sources: [],
            processed_ms: 1719931225205,
          },
          {
            leverage: 0.05,
            order_type: "LONG",
            order_uuid: "09e6ce38-9c59-4103-a2c1-18d9bd8a8f79",
            price: 18.23112,
            price_sources: [],
            processed_ms: 1719931258788,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "a0661e88-76cf-4bd3-9b7c-2698ee49deff",
            price: 18.24045,
            price_sources: [],
            processed_ms: 1719931306488,
          },
        ],
        position_type: "FLAT",
        position_uuid: "485586ac-a64e-4a79-b6bd-2c7dbd22af02",
        return_at_close: 1.0000442856865859,
        trade_pair: ["USDMXN", "USD/MXN", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 18.233775238095237,
        close_ms: 1719931912848,
        current_return: 0.9999644537616571,
        initial_entry_price: 18.24103,
        is_closed_position: true,
        miner_hotkey: "5F7ThFe3ZRxTVFjz4M8ciTHbcVAdVDHLZ7SDyMgdFJpdLdEm",
        net_leverage: 0.0,
        open_ms: 1719931336658,
        orders: [
          {
            leverage: 0.05,
            order_type: "LONG",
            order_uuid: "927f35b4-8b16-456e-b23b-43af2c015a1b",
            price: 18.24103,
            price_sources: [],
            processed_ms: 1719931336606,
          },
          {
            leverage: 0.05500000000000001,
            order_type: "LONG",
            order_uuid: "89a5c460-a0b2-4e6d-a090-d720994ef81a",
            price: 18.22718,
            price_sources: [],
            processed_ms: 1719931851716,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "eba033d2-af1e-4821-b63f-87a69f2359f4",
            price: 18.227600000000002,
            price_sources: [],
            processed_ms: 1719931912848,
          },
        ],
        position_type: "FLAT",
        position_uuid: "f7c22c3f-a482-4d05-b0c0-d906556792a1",
        return_at_close: 0.999957104022922,
        trade_pair: ["USDMXN", "USD/MXN", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 18.216731818181817,
        close_ms: 1719933695138,
        current_return: 1.0000629085931092,
        initial_entry_price: 18.22565,
        is_closed_position: true,
        miner_hotkey: "5F7ThFe3ZRxTVFjz4M8ciTHbcVAdVDHLZ7SDyMgdFJpdLdEm",
        net_leverage: 0.0,
        open_ms: 1719931943054,
        orders: [
          {
            leverage: 0.05,
            order_type: "LONG",
            order_uuid: "637bd703-b42f-4b4e-9c82-1fb6745efa9f",
            price: 18.22565,
            price_sources: [],
            processed_ms: 1719931942709,
          },
          {
            leverage: 0.06,
            order_type: "LONG",
            order_uuid: "0dc6f466-dfd8-4b2f-84db-4b4baf904d40",
            price: 18.2093,
            price_sources: [],
            processed_ms: 1719933399448,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "86af005f-ea94-431f-a62d-73fcb87b019f",
            price: 18.227155,
            price_sources: [],
            processed_ms: 1719933695138,
          },
        ],
        position_type: "FLAT",
        position_uuid: "9f17d539-11ad-4f1a-8ddf-6e3fba714c9c",
        return_at_close: 1.000055208108713,
        trade_pair: ["USDMXN", "USD/MXN", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 18.23265,
        close_ms: 1719934184688,
        current_return: 1.0000307689776309,
        initial_entry_price: 18.23265,
        is_closed_position: true,
        miner_hotkey: "5F7ThFe3ZRxTVFjz4M8ciTHbcVAdVDHLZ7SDyMgdFJpdLdEm",
        net_leverage: 0.0,
        open_ms: 1719933725480,
        orders: [
          {
            leverage: 0.05,
            order_type: "LONG",
            order_uuid: "1934809b-9805-43e7-9a76-ce7d40888b20",
            price: 18.23265,
            price_sources: [],
            processed_ms: 1719933725367,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "9fb7f4f2-c4d5-4960-9deb-5999630048dc",
            price: 18.24387,
            price_sources: [],
            processed_ms: 1719934184688,
          },
        ],
        position_type: "FLAT",
        position_uuid: "b1d5fe94-27a4-4da8-aede-78dc10255fc3",
        return_at_close: 1.0000272688699394,
        trade_pair: ["USDMXN", "USD/MXN", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 18.2312,
        close_ms: 1719934809913,
        current_return: 1.0000943849006099,
        initial_entry_price: 18.2312,
        is_closed_position: true,
        miner_hotkey: "5F7ThFe3ZRxTVFjz4M8ciTHbcVAdVDHLZ7SDyMgdFJpdLdEm",
        net_leverage: 0.0,
        open_ms: 1719934215414,
        orders: [
          {
            leverage: 0.05,
            order_type: "LONG",
            order_uuid: "e7746dca-eca2-40c2-8b9b-42d9147e3228",
            price: 18.2312,
            price_sources: [],
            processed_ms: 1719934213032,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "02074f3b-94be-46b9-8c83-dd67f0d2d62e",
            price: 18.265614999999997,
            price_sources: [],
            processed_ms: 1719934809913,
          },
        ],
        position_type: "FLAT",
        position_uuid: "9df0c6d2-fc4a-4b4b-bf47-aad99eb32f6f",
        return_at_close: 1.0000908845702627,
        trade_pair: ["USDMXN", "USD/MXN", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 18.239923750000003,
        close_ms: 1719936342054,
        current_return: 0.9999159230211505,
        initial_entry_price: 18.254105000000003,
        is_closed_position: true,
        miner_hotkey: "5F7ThFe3ZRxTVFjz4M8ciTHbcVAdVDHLZ7SDyMgdFJpdLdEm",
        net_leverage: 0.0,
        open_ms: 1719934840356,
        orders: [
          {
            leverage: 0.05,
            order_type: "LONG",
            order_uuid: "a5b9d944-0010-4eab-8e38-ca16a0b88d48",
            price: 18.254105000000003,
            price_sources: [],
            processed_ms: 1719934840165,
          },
          {
            leverage: 0.05,
            order_type: "LONG",
            order_uuid: "6b41c67e-b325-46b2-9ab5-58d6e3d079e5",
            price: 18.24751,
            price_sources: [],
            processed_ms: 1719935507064,
          },
          {
            leverage: 0.1,
            order_type: "LONG",
            order_uuid: "2fbdde7c-121e-45a9-9d0d-8c8da59756cb",
            price: 18.22904,
            price_sources: [],
            processed_ms: 1719935889516,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "0e35ceb2-df62-4bde-a2b7-14f14fa19561",
            price: 18.23225,
            price_sources: [],
            processed_ms: 1719936342054,
          },
        ],
        position_type: "FLAT",
        position_uuid: "04efd8ec-330e-4b64-8a46-ebe415ac2fa3",
        return_at_close: 0.9999019241982282,
        trade_pair: ["USDMXN", "USD/MXN", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 18.23568380952381,
        close_ms: 1719939314736,
        current_return: 1.0000154608433107,
        initial_entry_price: 18.24286,
        is_closed_position: true,
        miner_hotkey: "5F7ThFe3ZRxTVFjz4M8ciTHbcVAdVDHLZ7SDyMgdFJpdLdEm",
        net_leverage: 0.0,
        open_ms: 1719936371142,
        orders: [
          {
            leverage: 0.05,
            order_type: "LONG",
            order_uuid: "da1f9eae-b267-4fc7-a33c-92bfe939ae94",
            price: 18.24286,
            price_sources: [],
            processed_ms: 1719936370867,
          },
          {
            leverage: 0.05500000000000001,
            order_type: "LONG",
            order_uuid: "a32a2517-1f0a-44ae-89d9-9b25474a500f",
            price: 18.22916,
            price_sources: [],
            processed_ms: 1719936926886,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "b7adbf18-7267-4094-8e26-0b1a654969c2",
            price: 18.23837,
            price_sources: [],
            processed_ms: 1719939314736,
          },
        ],
        position_type: "FLAT",
        position_uuid: "c4c5339d-a98d-4950-9b35-178256dcf684",
        return_at_close: 1.0000081107296737,
        trade_pair: ["USDMXN", "USD/MXN", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 18.236306363636363,
        close_ms: 1719940267822,
        current_return: 1.0000293829739888,
        initial_entry_price: 18.24526,
        is_closed_position: true,
        miner_hotkey: "5F7ThFe3ZRxTVFjz4M8ciTHbcVAdVDHLZ7SDyMgdFJpdLdEm",
        net_leverage: 0.0,
        open_ms: 1719939345507,
        orders: [
          {
            leverage: 0.05,
            order_type: "LONG",
            order_uuid: "e298feb3-7305-4089-b40d-2900c68ee222",
            price: 18.24526,
            price_sources: [],
            processed_ms: 1719939344818,
          },
          {
            leverage: 0.06,
            order_type: "LONG",
            order_uuid: "becba3de-54b2-4767-adef-645252d4bc11",
            price: 18.228845,
            price_sources: [],
            processed_ms: 1719940200126,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "2a296e02-0c41-4bcf-b9dc-0a52ae677c57",
            price: 18.24118,
            price_sources: [],
            processed_ms: 1719940267822,
          },
        ],
        position_type: "FLAT",
        position_uuid: "956c892d-0f8c-4a59-9763-ba89c8fe0e72",
        return_at_close: 1.00002168274774,
        trade_pair: ["USDMXN", "USD/MXN", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 18.238989523809526,
        close_ms: 1719940794183,
        current_return: 1.0000066789264386,
        initial_entry_price: 18.24395,
        is_closed_position: true,
        miner_hotkey: "5F7ThFe3ZRxTVFjz4M8ciTHbcVAdVDHLZ7SDyMgdFJpdLdEm",
        net_leverage: 0.0,
        open_ms: 1719940298375,
        orders: [
          {
            leverage: 0.05,
            order_type: "LONG",
            order_uuid: "f79d5c07-2f07-4e4e-b6d8-8b668aea87a2",
            price: 18.24395,
            price_sources: [],
            processed_ms: 1719940298208,
          },
          {
            leverage: 0.05500000000000001,
            order_type: "LONG",
            order_uuid: "af3129be-c212-42e2-abd2-c9946ac8cc82",
            price: 18.23448,
            price_sources: [],
            processed_ms: 1719940542093,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "98df4389-7645-428b-bb8a-a4bfb349b6db",
            price: 18.24015,
            price_sources: [],
            processed_ms: 1719940794183,
          },
        ],
        position_type: "FLAT",
        position_uuid: "15201a89-8da1-40a4-b028-fededf22d79f",
        return_at_close: 0.9999993288773485,
        trade_pair: ["USDMXN", "USD/MXN", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 18.2272,
        close_ms: 1719942103174,
        current_return: 1.000099549025632,
        initial_entry_price: 18.2272,
        is_closed_position: true,
        miner_hotkey: "5F7ThFe3ZRxTVFjz4M8ciTHbcVAdVDHLZ7SDyMgdFJpdLdEm",
        net_leverage: 0.0,
        open_ms: 1719940824030,
        orders: [
          {
            leverage: 0.05,
            order_type: "LONG",
            order_uuid: "faf1e2cd-ab8b-4152-a52d-b3d938849d94",
            price: 18.2272,
            price_sources: [],
            processed_ms: 1719940823756,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "1e790f62-84ba-4acb-b302-8ab123e0e864",
            price: 18.26349,
            price_sources: [],
            processed_ms: 1719942103174,
          },
        ],
        position_type: "FLAT",
        position_uuid: "cdd9942c-ca4a-4ad5-b4fa-f8e095d4f3ac",
        return_at_close: 1.0000960486772104,
        trade_pair: ["USDMXN", "USD/MXN", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 18.26229,
        close_ms: 1719944675073,
        current_return: 1.0000590287417406,
        initial_entry_price: 18.26229,
        is_closed_position: true,
        miner_hotkey: "5F7ThFe3ZRxTVFjz4M8ciTHbcVAdVDHLZ7SDyMgdFJpdLdEm",
        net_leverage: 0.0,
        open_ms: 1719942134300,
        orders: [
          {
            leverage: 0.05,
            order_type: "LONG",
            order_uuid: "0a40b482-ff12-44d6-977f-e337fdd7a34f",
            price: 18.26229,
            price_sources: [],
            processed_ms: 1719942134110,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "166054a3-124a-4b63-bb88-7c1e33de1b51",
            price: 18.28385,
            price_sources: [],
            processed_ms: 1719944675073,
          },
        ],
        position_type: "FLAT",
        position_uuid: "92227b56-ba2d-4249-a469-29ca447b1675",
        return_at_close: 1.00005552853514,
        trade_pair: ["USDMXN", "USD/MXN", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 18.277607272727273,
        close_ms: 1719947060072,
        current_return: 0.9999458069879887,
        initial_entry_price: 18.2828,
        is_closed_position: true,
        miner_hotkey: "5F7ThFe3ZRxTVFjz4M8ciTHbcVAdVDHLZ7SDyMgdFJpdLdEm",
        net_leverage: 0.0,
        open_ms: 1719944704798,
        orders: [
          {
            leverage: 0.05,
            order_type: "LONG",
            order_uuid: "7de9ec07-ac91-4079-802d-f95296699713",
            price: 18.2828,
            price_sources: [],
            processed_ms: 1719944704618,
          },
          {
            leverage: 0.06,
            order_type: "LONG",
            order_uuid: "6f357bb6-17bc-4e6d-8d5f-0fccb697461d",
            price: 18.27328,
            price_sources: [],
            processed_ms: 1719945131820,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "10c6dac4-689d-4624-8fa0-c1724b06e96f",
            price: 18.2686,
            price_sources: [],
            processed_ms: 1719947060072,
          },
        ],
        position_type: "FLAT",
        position_uuid: "2be3725b-27cf-4e15-8ea5-821a59860309",
        return_at_close: 0.9999381074052749,
        trade_pair: ["USDMXN", "USD/MXN", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 18.262197664399093,
        close_ms: 1719950300375,
        current_return: 0.9999039553902329,
        initial_entry_price: 18.28075,
        is_closed_position: true,
        miner_hotkey: "5F7ThFe3ZRxTVFjz4M8ciTHbcVAdVDHLZ7SDyMgdFJpdLdEm",
        net_leverage: 0.0,
        open_ms: 1719947090994,
        orders: [
          {
            leverage: 0.05,
            order_type: "LONG",
            order_uuid: "3446ae32-f4fe-4006-811f-bd7b67add2aa",
            price: 18.28075,
            price_sources: [],
            processed_ms: 1719947090103,
          },
          {
            leverage: 0.05500000000000001,
            order_type: "LONG",
            order_uuid: "be720081-3581-4be3-b88f-205f76e42b0c",
            price: 18.27038,
            price_sources: [],
            processed_ms: 1719947352188,
          },
          {
            leverage: 0.11550000000000002,
            order_type: "LONG",
            order_uuid: "cf12ec5d-05bb-4f70-b706-76d855fd764e",
            price: 18.25027,
            price_sources: [],
            processed_ms: 1719948810404,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "8f74aa23-bf35-4135-a6ec-d0e0de571f4e",
            price: 18.254235,
            price_sources: [],
            processed_ms: 1719950300375,
          },
        ],
        position_type: "FLAT",
        position_uuid: "7409be88-ad49-4546-8e36-207c867d9f40",
        return_at_close: 0.9998885218726814,
        trade_pair: ["USDMXN", "USD/MXN", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 18.249685714285715,
        close_ms: 1719951084450,
        current_return: 1.0000377427817786,
        initial_entry_price: 18.26177,
        is_closed_position: true,
        miner_hotkey: "5F7ThFe3ZRxTVFjz4M8ciTHbcVAdVDHLZ7SDyMgdFJpdLdEm",
        net_leverage: 0.0,
        open_ms: 1719950330002,
        orders: [
          {
            leverage: 0.05,
            order_type: "LONG",
            order_uuid: "05e55a6b-b3f2-462c-ba21-0073cab058b1",
            price: 18.26177,
            price_sources: [],
            processed_ms: 1719950329774,
          },
          {
            leverage: 0.05500000000000001,
            order_type: "LONG",
            order_uuid: "1c838169-a527-4429-8a8b-d5de52ae5f0a",
            price: 18.2387,
            price_sources: [],
            processed_ms: 1719950792568,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "f77320f5-ed9a-45d5-b188-251c8f0bacf7",
            price: 18.25625,
            price_sources: [],
            processed_ms: 1719951084450,
          },
        ],
        position_type: "FLAT",
        position_uuid: "f6aaa292-239b-44c0-af1b-a579b4f8651b",
        return_at_close: 1.0000303925043692,
        trade_pair: ["USDMXN", "USD/MXN", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 18.25425,
        close_ms: 1719954340541,
        current_return: 0.9999430882157293,
        initial_entry_price: 18.26511,
        is_closed_position: true,
        miner_hotkey: "5F7ThFe3ZRxTVFjz4M8ciTHbcVAdVDHLZ7SDyMgdFJpdLdEm",
        net_leverage: 0.0,
        open_ms: 1719951120542,
        orders: [
          {
            leverage: 0.05,
            order_type: "LONG",
            order_uuid: "45e24fb5-90fe-4fbc-a18b-d39f57e1646e",
            price: 18.26511,
            price_sources: [],
            processed_ms: 1719951119732,
          },
          {
            leverage: 0.06,
            order_type: "LONG",
            order_uuid: "78efb989-725f-4e76-8fd2-4f6b275ad49d",
            price: 18.2452,
            price_sources: [],
            processed_ms: 1719954309169,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "d28b4639-6bc9-4171-9b04-c282a0851842",
            price: 18.2448,
            price_sources: [],
            processed_ms: 1719954340541,
          },
        ],
        position_type: "FLAT",
        position_uuid: "81063331-210b-407f-a6fa-f37890548346",
        return_at_close: 0.99993538865395,
        trade_pair: ["USDMXN", "USD/MXN", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 18.244490476190478,
        close_ms: 1719954496661,
        current_return: 1.0000040836238462,
        initial_entry_price: 18.2436,
        is_closed_position: true,
        miner_hotkey: "5F7ThFe3ZRxTVFjz4M8ciTHbcVAdVDHLZ7SDyMgdFJpdLdEm",
        net_leverage: 0.0,
        open_ms: 1719954368939,
        orders: [
          {
            leverage: 0.05,
            order_type: "LONG",
            order_uuid: "ef5f0613-5fff-4ab0-bcb5-eb01c998ad98",
            price: 18.2436,
            price_sources: [],
            processed_ms: 1719954368763,
          },
          {
            leverage: 0.05500000000000001,
            order_type: "LONG",
            order_uuid: "68af2f8e-6cf4-466a-9ef7-5ccfc5b4946d",
            price: 18.2453,
            price_sources: [],
            processed_ms: 1719954458908,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "349ddf4e-b2aa-490b-aa77-a7cdb39c8448",
            price: 18.2452,
            price_sources: [],
            processed_ms: 1719954496661,
          },
        ],
        position_type: "FLAT",
        position_uuid: "6d42b1b4-9143-4aa8-8eea-2f56e2092eb3",
        return_at_close: 0.9999967335938316,
        trade_pair: ["USDMXN", "USD/MXN", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 18.242,
        close_ms: 1719954654852,
        current_return: 1.0000137021589122,
        initial_entry_price: 18.2453,
        is_closed_position: true,
        miner_hotkey: "5F7ThFe3ZRxTVFjz4M8ciTHbcVAdVDHLZ7SDyMgdFJpdLdEm",
        net_leverage: 0.0,
        open_ms: 1719954573118,
        orders: [
          {
            leverage: 0.05,
            order_type: "LONG",
            order_uuid: "63c4eb80-41f8-4726-be4e-b046c2ec239b",
            price: 18.2453,
            price_sources: [],
            processed_ms: 1719954572804,
          },
          {
            leverage: 0.05,
            order_type: "LONG",
            order_uuid: "8ccb7dce-5181-44f3-ad4b-2d456dcccb0d",
            price: 18.2387,
            price_sources: [],
            processed_ms: 1719954623041,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "c12e1a1d-9ac1-4aeb-bced-dd0b53e3d601",
            price: 18.2445,
            price_sources: [],
            processed_ms: 1719954654852,
          },
        ],
        position_type: "FLAT",
        position_uuid: "de97fe2c-c1cc-464f-a18c-5ce09ada1947",
        return_at_close: 1.000006702062997,
        trade_pair: ["USDMXN", "USD/MXN", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 18.241590909090906,
        close_ms: 1719954835024,
        current_return: 0.9999813650638795,
        initial_entry_price: 18.2453,
        is_closed_position: true,
        miner_hotkey: "5F7ThFe3ZRxTVFjz4M8ciTHbcVAdVDHLZ7SDyMgdFJpdLdEm",
        net_leverage: 0.0,
        open_ms: 1719954684844,
        orders: [
          {
            leverage: 0.05,
            order_type: "LONG",
            order_uuid: "47deb15a-1988-4435-97d4-705fe463a6b1",
            price: 18.2453,
            price_sources: [],
            processed_ms: 1719954684740,
          },
          {
            leverage: 0.06,
            order_type: "LONG",
            order_uuid: "06aac873-2a23-4ca7-9cc0-83b3e620c705",
            price: 18.2385,
            price_sources: [],
            processed_ms: 1719954803088,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "606dc9b8-c6e8-4d5e-b268-7128fbb2de30",
            price: 18.2385,
            price_sources: [],
            processed_ms: 1719954835024,
          },
        ],
        position_type: "FLAT",
        position_uuid: "c484ca80-6591-46c8-8907-e70519cbd62c",
        return_at_close: 0.9999736652073684,
        trade_pair: ["USDMXN", "USD/MXN", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 18.244027272727273,
        close_ms: 1719955106892,
        current_return: 1.0000070704690078,
        initial_entry_price: 18.2449,
        is_closed_position: true,
        miner_hotkey: "5F7ThFe3ZRxTVFjz4M8ciTHbcVAdVDHLZ7SDyMgdFJpdLdEm",
        net_leverage: 0.0,
        open_ms: 1719954865492,
        orders: [
          {
            leverage: 0.05,
            order_type: "LONG",
            order_uuid: "06ab0b9e-ad9b-4ae8-be76-ce1a71b70206",
            price: 18.2449,
            price_sources: [],
            processed_ms: 1719954864751,
          },
          {
            leverage: 0.06,
            order_type: "LONG",
            order_uuid: "3b39cc60-6d7b-4796-a6ad-763715b0386c",
            price: 18.2433,
            price_sources: [],
            processed_ms: 1719955072962,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "47884631-cc1e-4793-bc1d-5d7e2a634117",
            price: 18.2452,
            price_sources: [],
            processed_ms: 1719955106892,
          },
        ],
        position_type: "FLAT",
        position_uuid: "485146fb-3e00-4d19-89d4-14cdc646cab8",
        return_at_close: 0.9999993704145652,
        trade_pair: ["USDMXN", "USD/MXN", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 18.2452,
        close_ms: 1719955437055,
        current_return: 1.0000002740446803,
        initial_entry_price: 18.2452,
        is_closed_position: true,
        miner_hotkey: "5F7ThFe3ZRxTVFjz4M8ciTHbcVAdVDHLZ7SDyMgdFJpdLdEm",
        net_leverage: 0.0,
        open_ms: 1719955223135,
        orders: [
          {
            leverage: 0.05,
            order_type: "LONG",
            order_uuid: "6b49c21e-d6d5-4ec4-9c5b-8dad794c144d",
            price: 18.2452,
            price_sources: [],
            processed_ms: 1719955222995,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "9c9f2718-cd77-4060-b14a-216b6ea10309",
            price: 18.2453,
            price_sources: [],
            processed_ms: 1719955437055,
          },
        ],
        position_type: "FLAT",
        position_uuid: "41037915-a99a-48dd-b8fc-7e211421e95d",
        return_at_close: 0.9999967740437211,
        trade_pair: ["USDMXN", "USD/MXN", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 18.242649999999998,
        close_ms: 1719956331547,
        current_return: 1.0000183608929423,
        initial_entry_price: 18.2453,
        is_closed_position: true,
        miner_hotkey: "5F7ThFe3ZRxTVFjz4M8ciTHbcVAdVDHLZ7SDyMgdFJpdLdEm",
        net_leverage: 0.0,
        open_ms: 1719955467577,
        orders: [
          {
            leverage: 0.05,
            order_type: "LONG",
            order_uuid: "540b3ae5-e84d-4a44-927c-400cfa4a4bf2",
            price: 18.2453,
            price_sources: [],
            processed_ms: 1719955466853,
          },
          {
            leverage: 0.05,
            order_type: "LONG",
            order_uuid: "e8776d4e-476c-4ef6-9f35-0f2d29b5db6b",
            price: 18.24,
            price_sources: [],
            processed_ms: 1719956033913,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "1a3c6eca-40f4-4496-9f5f-bfe28ba912c7",
            price: 18.246,
            price_sources: [],
            processed_ms: 1719956331547,
          },
        ],
        position_type: "FLAT",
        position_uuid: "0789066e-3d0c-4174-847f-2d9ce98ea287",
        return_at_close: 1.000011360764416,
        trade_pair: ["USDMXN", "USD/MXN", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 18.251,
        close_ms: 1719961139363,
        current_return: 1.0,
        initial_entry_price: 18.251,
        is_closed_position: true,
        miner_hotkey: "5F7ThFe3ZRxTVFjz4M8ciTHbcVAdVDHLZ7SDyMgdFJpdLdEm",
        net_leverage: 0.0,
        open_ms: 1719956363174,
        orders: [
          {
            leverage: 0.05,
            order_type: "LONG",
            order_uuid: "8ab3deff-6faf-4c0b-a18b-8d1b42221d8e",
            price: 18.251,
            price_sources: [],
            processed_ms: 1719956362748,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "53e82fd9-6d9b-4552-9343-1af38e5053ae",
            price: 18.251,
            price_sources: [],
            processed_ms: 1719961139363,
          },
        ],
        position_type: "FLAT",
        position_uuid: "d951ff1d-ded5-4113-b173-1f8e9c16e671",
        return_at_close: 0.9999965,
        trade_pair: ["USDMXN", "USD/MXN", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 18.2492,
        close_ms: 1719965713990,
        current_return: 1.000072107218393,
        initial_entry_price: 18.2506,
        is_closed_position: true,
        miner_hotkey: "5F7ThFe3ZRxTVFjz4M8ciTHbcVAdVDHLZ7SDyMgdFJpdLdEm",
        net_leverage: 0.0,
        open_ms: 1719961170862,
        orders: [
          {
            leverage: 0.05,
            order_type: "LONG",
            order_uuid: "7c97fbdd-35a5-4829-b13a-5132406ce8ca",
            price: 18.2506,
            price_sources: [],
            processed_ms: 1719961170688,
          },
          {
            leverage: 0.05,
            order_type: "LONG",
            order_uuid: "eb51b37d-e855-4039-ba92-2ee2d258cb92",
            price: 18.2478,
            price_sources: [],
            processed_ms: 1719965684149,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "66f4d2f7-1fcc-4eb9-b965-0319ca3002c5",
            price: 18.26236,
            price_sources: [],
            processed_ms: 1719965713990,
          },
        ],
        position_type: "FLAT",
        position_uuid: "01c87624-e141-4e6b-99c1-a0079b17257c",
        return_at_close: 1.0000651067136423,
        trade_pair: ["USDMXN", "USD/MXN", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 18.247781818181817,
        close_ms: 1719966769016,
        current_return: 1.0000746090368113,
        initial_entry_price: 18.2498,
        is_closed_position: true,
        miner_hotkey: "5F7ThFe3ZRxTVFjz4M8ciTHbcVAdVDHLZ7SDyMgdFJpdLdEm",
        net_leverage: 0.0,
        open_ms: 1719965744153,
        orders: [
          {
            leverage: 0.05,
            order_type: "LONG",
            order_uuid: "4506d6b9-4669-47bc-9730-a81abfbe9dd1",
            price: 18.2498,
            price_sources: [],
            processed_ms: 1719965743773,
          },
          {
            leverage: 0.06,
            order_type: "LONG",
            order_uuid: "e6047931-f34e-4908-b233-054cb55edbc0",
            price: 18.2461,
            price_sources: [],
            processed_ms: 1719966677388,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "e507651f-e5ec-4c1f-93dd-eccea7bce2de",
            price: 18.26016,
            price_sources: [],
            processed_ms: 1719966769016,
          },
        ],
        position_type: "FLAT",
        position_uuid: "00dfaaeb-ae1c-4722-ba7d-24fea78c3b5b",
        return_at_close: 1.0000669084623217,
        trade_pair: ["USDMXN", "USD/MXN", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 18.253284999999998,
        close_ms: 1719968826415,
        current_return: 1.0000357059017049,
        initial_entry_price: 18.253284999999998,
        is_closed_position: true,
        miner_hotkey: "5F7ThFe3ZRxTVFjz4M8ciTHbcVAdVDHLZ7SDyMgdFJpdLdEm",
        net_leverage: 0.0,
        open_ms: 1719966803422,
        orders: [
          {
            leverage: 0.05,
            order_type: "LONG",
            order_uuid: "4e88604e-dba5-40bc-b745-b069aa0a95cb",
            price: 18.253284999999998,
            price_sources: [],
            processed_ms: 1719966802760,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "42decc5b-ecd0-49f2-9534-1bc51c20c77f",
            price: 18.26632,
            price_sources: [],
            processed_ms: 1719968826415,
          },
        ],
        position_type: "FLAT",
        position_uuid: "21eaa18d-fd7c-47f6-a574-4f311043899a",
        return_at_close: 1.0000322057767341,
        trade_pair: ["USDMXN", "USD/MXN", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 18.26606,
        close_ms: 1719974712067,
        current_return: 1.0000161501714109,
        initial_entry_price: 18.26606,
        is_closed_position: true,
        miner_hotkey: "5F7ThFe3ZRxTVFjz4M8ciTHbcVAdVDHLZ7SDyMgdFJpdLdEm",
        net_leverage: 0.0,
        open_ms: 1719968857400,
        orders: [
          {
            leverage: 0.05,
            order_type: "LONG",
            order_uuid: "19147eed-8565-4697-a9e3-7c94350b63d8",
            price: 18.26606,
            price_sources: [],
            processed_ms: 1719968857224,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "2690a1d5-5bf7-45ef-9d13-337e842f71b3",
            price: 18.27196,
            price_sources: [],
            processed_ms: 1719974712067,
          },
        ],
        position_type: "FLAT",
        position_uuid: "5405f5a0-8c4e-473b-9904-cd3f6572e6cc",
        return_at_close: 1.0000126501148852,
        trade_pair: ["USDMXN", "USD/MXN", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 18.27096,
        close_ms: 1719986119895,
        current_return: 1.0000350008975993,
        initial_entry_price: 18.27096,
        is_closed_position: true,
        miner_hotkey: "5F7ThFe3ZRxTVFjz4M8ciTHbcVAdVDHLZ7SDyMgdFJpdLdEm",
        net_leverage: 0.0,
        open_ms: 1719974745274,
        orders: [
          {
            leverage: 0.05,
            order_type: "LONG",
            order_uuid: "c83ca0b7-ef18-4e05-b528-2f9bc63e16d8",
            price: 18.27096,
            price_sources: [],
            processed_ms: 1719974741836,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "ca407f15-f3db-4034-98d9-2456baf50f3e",
            price: 18.283749999999998,
            price_sources: [],
            processed_ms: 1719986119895,
          },
        ],
        position_type: "FLAT",
        position_uuid: "7c18d3c3-b98b-4b58-bd73-c3783e122d7d",
        return_at_close: 1.0000315007750962,
        trade_pair: ["USDMXN", "USD/MXN", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 18.291240476190474,
        close_ms: 1719988626995,
        current_return: 1.0000144027793265,
        initial_entry_price: 18.29508,
        is_closed_position: true,
        miner_hotkey: "5F7ThFe3ZRxTVFjz4M8ciTHbcVAdVDHLZ7SDyMgdFJpdLdEm",
        net_leverage: 0.0,
        open_ms: 1719986153107,
        orders: [
          {
            leverage: 0.05,
            order_type: "LONG",
            order_uuid: "ce109334-943d-4b0f-8115-ce1adcd44b7b",
            price: 18.29508,
            price_sources: [],
            processed_ms: 1719986152895,
          },
          {
            leverage: 0.05500000000000001,
            order_type: "LONG",
            order_uuid: "702a1d5c-e06d-42b0-9366-43b5845100ff",
            price: 18.28775,
            price_sources: [],
            processed_ms: 1719986441386,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "f882722f-d916-4e02-b4f8-b607a1c50b76",
            price: 18.29375,
            price_sources: [],
            processed_ms: 1719988626995,
          },
        ],
        position_type: "FLAT",
        position_uuid: "727fd2b2-9f45-427e-80cf-1e76f8bd73c6",
        return_at_close: 1.0000070526734661,
        trade_pair: ["USDMXN", "USD/MXN", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 18.262705443722947,
        close_ms: 1719992762753,
        current_return: 0.9999203263100825,
        initial_entry_price: 18.29732,
        is_closed_position: true,
        miner_hotkey: "5F7ThFe3ZRxTVFjz4M8ciTHbcVAdVDHLZ7SDyMgdFJpdLdEm",
        net_leverage: 0.0,
        open_ms: 1719988656858,
        orders: [
          {
            leverage: 0.05,
            order_type: "LONG",
            order_uuid: "3a9083e2-c2b4-4860-9916-4b1af0eabfb7",
            price: 18.29732,
            price_sources: [],
            processed_ms: 1719988656620,
          },
          {
            leverage: 0.06,
            order_type: "LONG",
            order_uuid: "92d89f57-6c4f-4c5e-864b-f5fd1aa711e2",
            price: 18.2891,
            price_sources: [],
            processed_ms: 1719989500413,
          },
          {
            leverage: 0.12100000000000001,
            order_type: "LONG",
            order_uuid: "d51e0357-1642-4098-aa23-b1c0c3253f82",
            price: 18.263025,
            price_sources: [],
            processed_ms: 1719990115968,
          },
          {
            leverage: 0.231,
            order_type: "LONG",
            order_uuid: "57cdd6d9-d51d-45d1-9d7c-f08c374ddfb5",
            price: 18.24819,
            price_sources: [],
            processed_ms: 1719991531496,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "72f3c2d7-cba0-4ac4-af83-b1aa07532214",
            price: 18.25955,
            price_sources: [],
            processed_ms: 1719992762753,
          },
        ],
        position_type: "FLAT",
        position_uuid: "2da178b2-8510-4e5e-907a-e88bb7afb3e6",
        return_at_close: 0.9998879888867297,
        trade_pair: ["USDMXN", "USD/MXN", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 18.230798863636362,
        close_ms: 1720006821664,
        current_return: 0.9999539875536648,
        initial_entry_price: 18.25919,
        is_closed_position: true,
        miner_hotkey: "5F7ThFe3ZRxTVFjz4M8ciTHbcVAdVDHLZ7SDyMgdFJpdLdEm",
        net_leverage: 0.0,
        open_ms: 1719992792866,
        orders: [
          {
            leverage: 0.05,
            order_type: "LONG",
            order_uuid: "ac7e6954-d919-4f19-b46e-66c239b8c415",
            price: 18.25919,
            price_sources: [],
            processed_ms: 1719992792645,
          },
          {
            leverage: 0.06,
            order_type: "LONG",
            order_uuid: "d4313728-5d16-4535-bd16-398ec0c032c2",
            price: 18.22702,
            price_sources: [],
            processed_ms: 1720004749600,
          },
          {
            leverage: 0.11,
            order_type: "LONG",
            order_uuid: "fb658c5c-f663-4029-b17b-78a508dafc7e",
            price: 18.219955,
            price_sources: [],
            processed_ms: 1720005057662,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "47a31db0-9799-454d-a51d-e397136d62fe",
            price: 18.22698,
            price_sources: [],
            processed_ms: 1720006821664,
          },
        ],
        position_type: "FLAT",
        position_uuid: "b0cf5a2c-d5ee-4be7-ab43-0c5e59f2365a",
        return_at_close: 0.9999385882622565,
        trade_pair: ["USDMXN", "USD/MXN", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 18.2304,
        close_ms: 1720007728431,
        current_return: 1.0000583914779708,
        initial_entry_price: 18.2304,
        is_closed_position: true,
        miner_hotkey: "5F7ThFe3ZRxTVFjz4M8ciTHbcVAdVDHLZ7SDyMgdFJpdLdEm",
        net_leverage: 0.0,
        open_ms: 1720006852852,
        orders: [
          {
            leverage: 0.05,
            order_type: "LONG",
            order_uuid: "a5621043-7608-4235-927a-cc884c94ec3d",
            price: 18.2304,
            price_sources: [],
            processed_ms: 1720006852764,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "b3bc5a31-ee8a-4df5-a27a-7b3d192068a2",
            price: 18.25169,
            price_sources: [],
            processed_ms: 1720007728431,
          },
        ],
        position_type: "FLAT",
        position_uuid: "3cb24e01-f94e-4162-9019-52041ce1d90e",
        return_at_close: 1.0000548912736007,
        trade_pair: ["USDMXN", "USD/MXN", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 18.24912,
        close_ms: 1720008122596,
        current_return: 1.000050057208238,
        initial_entry_price: 18.24912,
        is_closed_position: true,
        miner_hotkey: "5F7ThFe3ZRxTVFjz4M8ciTHbcVAdVDHLZ7SDyMgdFJpdLdEm",
        net_leverage: 0.0,
        open_ms: 1720007757957,
        orders: [
          {
            leverage: 0.05,
            order_type: "LONG",
            order_uuid: "112626f3-367c-4288-bc44-46a1cb3a339d",
            price: 18.24912,
            price_sources: [],
            processed_ms: 1720007757863,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "875ebbcd-b821-4ae6-b9cb-a26c46a815f1",
            price: 18.26739,
            price_sources: [],
            processed_ms: 1720008122596,
          },
        ],
        position_type: "FLAT",
        position_uuid: "88f3806e-871d-4e5e-bfff-2eee8cd98adf",
        return_at_close: 1.0000465570330377,
        trade_pair: ["USDMXN", "USD/MXN", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 18.229001704545457,
        close_ms: 1720010779194,
        current_return: 0.9998265110307503,
        initial_entry_price: 18.264850000000003,
        is_closed_position: true,
        miner_hotkey: "5F7ThFe3ZRxTVFjz4M8ciTHbcVAdVDHLZ7SDyMgdFJpdLdEm",
        net_leverage: 0.0,
        open_ms: 1720008154046,
        orders: [
          {
            leverage: 0.05,
            order_type: "LONG",
            order_uuid: "4a92ca09-72f4-45dc-90e2-6972ca7d81dc",
            price: 18.264850000000003,
            price_sources: [],
            processed_ms: 1720008153868,
          },
          {
            leverage: 0.05,
            order_type: "LONG",
            order_uuid: "27d87d18-7f83-4d55-bcf6-43c57ed8e908",
            price: 18.253145,
            price_sources: [],
            processed_ms: 1720008861123,
          },
          {
            leverage: 0.12,
            order_type: "LONG",
            order_uuid: "d3bd0cfc-55d9-425d-914b-8beae6167ddd",
            price: 18.236,
            price_sources: [],
            processed_ms: 1720010281523,
          },
          {
            leverage: 0.22,
            order_type: "LONG",
            order_uuid: "08d223e6-9ee7-4b40-af07-4fd59a8a8107",
            price: 18.21155,
            price_sources: [],
            processed_ms: 1720010595420,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "813dcf55-3ece-421e-bc4e-e1e65c06d2e3",
            price: 18.2218,
            price_sources: [],
            processed_ms: 1720010779194,
          },
        ],
        position_type: "FLAT",
        position_uuid: "a8c84509-a70f-4195-955c-2a64be9d30b2",
        return_at_close: 0.9997957163742105,
        trade_pair: ["USDMXN", "USD/MXN", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 18.21675619047619,
        close_ms: 1720011257156,
        current_return: 1.0000295519398563,
        initial_entry_price: 18.223,
        is_closed_position: true,
        miner_hotkey: "5F7ThFe3ZRxTVFjz4M8ciTHbcVAdVDHLZ7SDyMgdFJpdLdEm",
        net_leverage: 0.0,
        open_ms: 1720010810328,
        orders: [
          {
            leverage: 0.05,
            order_type: "LONG",
            order_uuid: "aeaa37af-9cd5-4ffe-9b4f-8d99a6a8cfa2",
            price: 18.223,
            price_sources: [],
            processed_ms: 1720010809778,
          },
          {
            leverage: 0.05500000000000001,
            order_type: "LONG",
            order_uuid: "ce114ac9-ae7c-4a75-878a-70d895171ed1",
            price: 18.21108,
            price_sources: [],
            processed_ms: 1720010997350,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "b2a6d47e-a36e-4da6-a987-da87d044a956",
            price: 18.221885,
            price_sources: [],
            processed_ms: 1720011257156,
          },
        ],
        position_type: "FLAT",
        position_uuid: "70213bbb-759b-4ec9-b3d5-1619434e3948",
        return_at_close: 1.0000222017226497,
        trade_pair: ["USDMXN", "USD/MXN", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 18.222557272727276,
        close_ms: 1720011835302,
        current_return: 1.000026027707658,
        initial_entry_price: 18.22673,
        is_closed_position: true,
        miner_hotkey: "5F7ThFe3ZRxTVFjz4M8ciTHbcVAdVDHLZ7SDyMgdFJpdLdEm",
        net_leverage: 0.0,
        open_ms: 1720011287478,
        orders: [
          {
            leverage: 0.05,
            order_type: "LONG",
            order_uuid: "3bc19809-f90c-449c-ac68-45f5be84673d",
            price: 18.22673,
            price_sources: [],
            processed_ms: 1720011287200,
          },
          {
            leverage: 0.06,
            order_type: "LONG",
            order_uuid: "ee19ca56-1657-48ab-b9e9-1ac139378136",
            price: 18.21908,
            price_sources: [],
            processed_ms: 1720011635554,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "24883649-3ee9-4b31-ae45-fc7741eb3886",
            price: 18.22687,
            price_sources: [],
            processed_ms: 1720011835302,
          },
        ],
        position_type: "FLAT",
        position_uuid: "de01c8dd-5d2c-479f-b65c-313aef405c34",
        return_at_close: 1.0000183275072447,
        trade_pair: ["USDMXN", "USD/MXN", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 18.159888701298705,
        close_ms: 1720014502516,
        current_return: 0.9997250581938409,
        initial_entry_price: 18.21069,
        is_closed_position: true,
        miner_hotkey: "5F7ThFe3ZRxTVFjz4M8ciTHbcVAdVDHLZ7SDyMgdFJpdLdEm",
        net_leverage: 0.0,
        open_ms: 1720011864799,
        orders: [
          {
            leverage: 0.05,
            order_type: "LONG",
            order_uuid: "8688e8f5-822d-4cc2-8551-0932dbcd30aa",
            price: 18.21069,
            price_sources: [],
            processed_ms: 1720011864621,
          },
          {
            leverage: 0.05,
            order_type: "LONG",
            order_uuid: "b2e4b28b-12e9-4580-b921-5b99dac68744",
            price: 18.20323,
            price_sources: [],
            processed_ms: 1720011908464,
          },
          {
            leverage: 0.12,
            order_type: "LONG",
            order_uuid: "dd0107a7-4fb4-429b-a80a-dfa141cf654f",
            price: 18.18176,
            price_sources: [],
            processed_ms: 1720013479655,
          },
          {
            leverage: 0.22,
            order_type: "LONG",
            order_uuid: "9b07edd4-3e07-4ae6-90eb-5c0adba5d72b",
            price: 18.16605,
            price_sources: [],
            processed_ms: 1720013618924,
          },
          {
            leverage: 0.48400000000000004,
            order_type: "LONG",
            order_uuid: "e43f6b67-081c-4915-bf33-94f5d705646e",
            price: 18.14194,
            price_sources: [],
            processed_ms: 1720014388140,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "2d17bfb8-b75a-4f83-82ba-afba247cd73f",
            price: 18.15447,
            price_sources: [],
            processed_ms: 1720014502516,
          },
        ],
        position_type: "FLAT",
        position_uuid: "0aa3bb5b-6220-4295-a479-e53005d9da56",
        return_at_close: 0.9996603959770769,
        trade_pair: ["USDMXN", "USD/MXN", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 18.135479047619047,
        close_ms: 1720015338971,
        current_return: 1.0000236723075884,
        initial_entry_price: 18.145675,
        is_closed_position: true,
        miner_hotkey: "5F7ThFe3ZRxTVFjz4M8ciTHbcVAdVDHLZ7SDyMgdFJpdLdEm",
        net_leverage: 0.0,
        open_ms: 1720014529993,
        orders: [
          {
            leverage: 0.05,
            order_type: "LONG",
            order_uuid: "a419481a-717d-44a8-8f57-32b6e8c7c073",
            price: 18.145675,
            price_sources: [],
            processed_ms: 1720014529915,
          },
          {
            leverage: 0.05500000000000001,
            order_type: "LONG",
            order_uuid: "d43e88d7-5f04-4b65-a958-f91a4e16b5a9",
            price: 18.12621,
            price_sources: [],
            processed_ms: 1720015265634,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "2f56611c-4e98-46a3-b8d6-0511b01166fe",
            price: 18.13957,
            price_sources: [],
            processed_ms: 1720015338971,
          },
        ],
        position_type: "FLAT",
        position_uuid: "50ca9fe9-cfb8-4a0e-85a6-a59febb1a82f",
        return_at_close: 1.000016322133597,
        trade_pair: ["USDMXN", "USD/MXN", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 18.136107272727273,
        close_ms: 1720016080068,
        current_return: 1.0000208665658328,
        initial_entry_price: 18.14865,
        is_closed_position: true,
        miner_hotkey: "5F7ThFe3ZRxTVFjz4M8ciTHbcVAdVDHLZ7SDyMgdFJpdLdEm",
        net_leverage: 0.0,
        open_ms: 1720015368909,
        orders: [
          {
            leverage: 0.05,
            order_type: "LONG",
            order_uuid: "44245daa-5209-4fc1-827e-d672330bb209",
            price: 18.14865,
            price_sources: [],
            processed_ms: 1720015368837,
          },
          {
            leverage: 0.06,
            order_type: "LONG",
            order_uuid: "52bf6621-31c5-4836-8117-780322e9f715",
            price: 18.125655000000002,
            price_sources: [],
            processed_ms: 1720015401109,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "e40f281f-1f3c-4e44-b472-a9998f1449cb",
            price: 18.13955,
            price_sources: [],
            processed_ms: 1720016080068,
          },
        ],
        position_type: "FLAT",
        position_uuid: "ca450b28-01b2-463b-89f6-c9fd33f76e36",
        return_at_close: 1.0000131664051601,
        trade_pair: ["USDMXN", "USD/MXN", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 18.134774999999998,
        close_ms: 1720016333449,
        current_return: 1.0000282633871946,
        initial_entry_price: 18.133,
        is_closed_position: true,
        miner_hotkey: "5F7ThFe3ZRxTVFjz4M8ciTHbcVAdVDHLZ7SDyMgdFJpdLdEm",
        net_leverage: 0.0,
        open_ms: 1720016109848,
        orders: [
          {
            leverage: 0.05,
            order_type: "LONG",
            order_uuid: "3a05fe0b-6bd7-43f2-bf3e-d3175ff4a77c",
            price: 18.133,
            price_sources: [],
            processed_ms: 1720016109732,
          },
          {
            leverage: 0.05,
            order_type: "LONG",
            order_uuid: "269bb107-ca9f-4891-bcb4-14380078a044",
            price: 18.13655,
            price_sources: [],
            processed_ms: 1720016280215,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "9a7eb186-b507-4881-9730-251b5eecb12a",
            price: 18.1399,
            price_sources: [],
            processed_ms: 1720016333449,
          },
        ],
        position_type: "FLAT",
        position_uuid: "641ac17a-5f53-4435-baf2-c0e71641922d",
        return_at_close: 1.000021263189351,
        trade_pair: ["USDMXN", "USD/MXN", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 18.131615,
        close_ms: 1720016870095,
        current_return: 1.0000146661116334,
        initial_entry_price: 18.13705,
        is_closed_position: true,
        miner_hotkey: "5F7ThFe3ZRxTVFjz4M8ciTHbcVAdVDHLZ7SDyMgdFJpdLdEm",
        net_leverage: 0.0,
        open_ms: 1720016361964,
        orders: [
          {
            leverage: 0.05,
            order_type: "LONG",
            order_uuid: "5e1e8a73-e410-4e26-b042-b30a06ee5140",
            price: 18.13705,
            price_sources: [],
            processed_ms: 1720016361877,
          },
          {
            leverage: 0.05,
            order_type: "LONG",
            order_uuid: "f4dd6550-ca01-41ec-b9b0-587e534425a3",
            price: 18.12618,
            price_sources: [],
            processed_ms: 1720016762478,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "d1ef7e1a-fca0-4e68-a98d-757e9af2c8db",
            price: 18.134275000000002,
            price_sources: [],
            processed_ms: 1720016870095,
          },
        ],
        position_type: "FLAT",
        position_uuid: "2c342eba-8989-4669-a9e6-9b1df808cd66",
        return_at_close: 1.0000076660089705,
        trade_pair: ["USDMXN", "USD/MXN", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 18.126745000000003,
        close_ms: 1720017359732,
        current_return: 1.0000190508329156,
        initial_entry_price: 18.13569,
        is_closed_position: true,
        miner_hotkey: "5F7ThFe3ZRxTVFjz4M8ciTHbcVAdVDHLZ7SDyMgdFJpdLdEm",
        net_leverage: 0.0,
        open_ms: 1720016900041,
        orders: [
          {
            leverage: 0.05,
            order_type: "LONG",
            order_uuid: "091846f2-fa4a-4c1f-8b4f-a858cc0f431a",
            price: 18.13569,
            price_sources: [],
            processed_ms: 1720016899898,
          },
          {
            leverage: 0.05,
            order_type: "LONG",
            order_uuid: "a723100b-ada1-4ac0-9403-1ab9fb289c6c",
            price: 18.117800000000003,
            price_sources: [],
            processed_ms: 1720017331392,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "2645bdf6-6779-4eb8-90db-0abe92b2df05",
            price: 18.1302,
            price_sources: [],
            processed_ms: 1720017359732,
          },
        ],
        position_type: "FLAT",
        position_uuid: "8da5ddc7-5dca-471d-b09b-54e21c7426f6",
        return_at_close: 1.0000120506995598,
        trade_pair: ["USDMXN", "USD/MXN", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 18.127604090909088,
        close_ms: 1720017517752,
        current_return: 1.000022722278393,
        initial_entry_price: 18.134185000000002,
        is_closed_position: true,
        miner_hotkey: "5F7ThFe3ZRxTVFjz4M8ciTHbcVAdVDHLZ7SDyMgdFJpdLdEm",
        net_leverage: 0.0,
        open_ms: 1720017390245,
        orders: [
          {
            leverage: 0.05,
            order_type: "LONG",
            order_uuid: "5e63b901-0d40-4054-8df2-ac60112cea76",
            price: 18.134185000000002,
            price_sources: [],
            processed_ms: 1720017389980,
          },
          {
            leverage: 0.06,
            order_type: "LONG",
            order_uuid: "b74243c0-b1fb-495d-9c9d-cc73c297e5f8",
            price: 18.12212,
            price_sources: [],
            processed_ms: 1720017485723,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "98506149-6657-4f68-9fc3-dbb0a45ec5fc",
            price: 18.13135,
            price_sources: [],
            processed_ms: 1720017517752,
          },
        ],
        position_type: "FLAT",
        position_uuid: "3134cfa9-8386-4700-8923-0d3b7c488016",
        return_at_close: 1.0000150221034314,
        trade_pair: ["USDMXN", "USD/MXN", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 18.127,
        close_ms: 1720017659939,
        current_return: 1.0000572074805538,
        initial_entry_price: 18.127,
        is_closed_position: true,
        miner_hotkey: "5F7ThFe3ZRxTVFjz4M8ciTHbcVAdVDHLZ7SDyMgdFJpdLdEm",
        net_leverage: 0.0,
        open_ms: 1720017548234,
        orders: [
          {
            leverage: 0.05,
            order_type: "LONG",
            order_uuid: "b722fe91-8b64-47c6-9cfa-f7250fa09efa",
            price: 18.127,
            price_sources: [],
            processed_ms: 1720017548017,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "e3518616-fbf3-43f1-9379-7146419fd4d9",
            price: 18.14774,
            price_sources: [],
            processed_ms: 1720017659939,
          },
        ],
        position_type: "FLAT",
        position_uuid: "eb31dff8-0036-4405-9a53-3e6dfb1d5550",
        return_at_close: 1.0000537072803275,
        trade_pair: ["USDMXN", "USD/MXN", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 18.153,
        close_ms: 1720017840000,
        current_return: 1.000044372830937,
        initial_entry_price: 18.153,
        is_closed_position: true,
        miner_hotkey: "5F7ThFe3ZRxTVFjz4M8ciTHbcVAdVDHLZ7SDyMgdFJpdLdEm",
        net_leverage: 0.0,
        open_ms: 1720017689794,
        orders: [
          {
            leverage: 0.05,
            order_type: "LONG",
            order_uuid: "932b7329-9910-4a7c-9c16-2b9ec66eba5c",
            price: 18.153,
            price_sources: [],
            processed_ms: 1720017689728,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "81ed7dfe-d70d-4ad4-8d83-0edfe83b0db0",
            price: 18.16911,
            price_sources: [],
            processed_ms: 1720017840000,
          },
        ],
        position_type: "FLAT",
        position_uuid: "bcd886d9-92ff-4ad4-b6cb-3c00f03650ab",
        return_at_close: 1.000040872675632,
        trade_pair: ["USDMXN", "USD/MXN", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 18.1442125,
        close_ms: 1720018760880,
        current_return: 0.9999916141461401,
        initial_entry_price: 18.1675,
        is_closed_position: true,
        miner_hotkey: "5F7ThFe3ZRxTVFjz4M8ciTHbcVAdVDHLZ7SDyMgdFJpdLdEm",
        net_leverage: 0.0,
        open_ms: 1720017870496,
        orders: [
          {
            leverage: 0.05,
            order_type: "LONG",
            order_uuid: "644099e3-a95e-4b29-8918-6fd3b57ba896",
            price: 18.1675,
            price_sources: [],
            processed_ms: 1720017870057,
          },
          {
            leverage: 0.05,
            order_type: "LONG",
            order_uuid: "25442150-9caa-41e3-bea9-f96b25d4be64",
            price: 18.146155,
            price_sources: [],
            processed_ms: 1720018028185,
          },
          {
            leverage: 0.12,
            order_type: "LONG",
            order_uuid: "796b451f-66a5-4046-a084-0f10f26b3caa",
            price: 18.1337,
            price_sources: [],
            processed_ms: 1720018144062,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "4cf88183-2c96-48cc-94ab-d4005a77ed97",
            price: 18.14352,
            price_sources: [],
            processed_ms: 1720018760880,
          },
        ],
        position_type: "FLAT",
        position_uuid: "cd70a9fe-e73b-4020-913a-c11ad44c5ee3",
        return_at_close: 0.9999762142752823,
        trade_pair: ["USDMXN", "USD/MXN", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 18.14481,
        close_ms: 1720019275568,
        current_return: 1.0000101681968563,
        initial_entry_price: 18.14481,
        is_closed_position: true,
        miner_hotkey: "5F7ThFe3ZRxTVFjz4M8ciTHbcVAdVDHLZ7SDyMgdFJpdLdEm",
        net_leverage: 0.0,
        open_ms: 1720018791049,
        orders: [
          {
            leverage: 0.05,
            order_type: "LONG",
            order_uuid: "47e02e9c-0842-4570-9522-2115ec7660dd",
            price: 18.14481,
            price_sources: [],
            processed_ms: 1720018790770,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "0e3cadd9-be1a-425e-a4bc-315c469977ca",
            price: 18.1485,
            price_sources: [],
            processed_ms: 1720019275568,
          },
        ],
        position_type: "FLAT",
        position_uuid: "0135bcf9-c73d-44a8-99f5-da9d56cfb58e",
        return_at_close: 1.0000066681612676,
        trade_pair: ["USDMXN", "USD/MXN", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 18.13183476190476,
        close_ms: 1720020722400,
        current_return: 0.9999295170438901,
        initial_entry_price: 18.159,
        is_closed_position: true,
        miner_hotkey: "5F7ThFe3ZRxTVFjz4M8ciTHbcVAdVDHLZ7SDyMgdFJpdLdEm",
        net_leverage: 0.0,
        open_ms: 1720019305267,
        orders: [
          {
            leverage: 0.05,
            order_type: "LONG",
            order_uuid: "351de844-aaee-41dc-932f-5f248bbfea4c",
            price: 18.159,
            price_sources: [],
            processed_ms: 1720019305003,
          },
          {
            leverage: 0.05,
            order_type: "LONG",
            order_uuid: "236d5f43-02b2-481f-aa8f-cb8879be95b5",
            price: 18.13262,
            price_sources: [],
            processed_ms: 1720019651512,
          },
          {
            leverage: 0.11000000000000001,
            order_type: "LONG",
            order_uuid: "d473fd46-46e2-4235-8487-ae7bbd8abe2b",
            price: 18.11913,
            price_sources: [],
            processed_ms: 1720019837185,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "1ca007e1-2d7f-4c44-adf9-5955dab6e587",
            price: 18.12574,
            price_sources: [],
            processed_ms: 1720020722400,
          },
        ],
        position_type: "FLAT",
        position_uuid: "4827b5d4-32fd-40b0-85d8-1a213002b0fa",
        return_at_close: 0.9999148180799895,
        trade_pair: ["USDMXN", "USD/MXN", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 18.12161952380952,
        close_ms: 1720020927430,
        current_return: 1.000015816840218,
        initial_entry_price: 18.12625,
        is_closed_position: true,
        miner_hotkey: "5F7ThFe3ZRxTVFjz4M8ciTHbcVAdVDHLZ7SDyMgdFJpdLdEm",
        net_leverage: 0.0,
        open_ms: 1720020751801,
        orders: [
          {
            leverage: 0.05,
            order_type: "LONG",
            order_uuid: "bdff1c6f-91f9-4890-9f6d-505212074c15",
            price: 18.12625,
            price_sources: [],
            processed_ms: 1720020751654,
          },
          {
            leverage: 0.05500000000000001,
            order_type: "LONG",
            order_uuid: "baf66610-77e4-42dc-9411-e449c9a66cd0",
            price: 18.11741,
            price_sources: [],
            processed_ms: 1720020876276,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "5073c2e8-cf88-4423-a41a-0f8d9655f9d0",
            price: 18.12435,
            price_sources: [],
            processed_ms: 1720020927430,
          },
        ],
        position_type: "FLAT",
        position_uuid: "afb04895-9906-40f4-b60c-7997471eeba9",
        return_at_close: 1.0000084667239644,
        trade_pair: ["USDMXN", "USD/MXN", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 18.11731904761905,
        close_ms: 1720021331789,
        current_return: 1.0000036555858731,
        initial_entry_price: 18.12295,
        is_closed_position: true,
        miner_hotkey: "5F7ThFe3ZRxTVFjz4M8ciTHbcVAdVDHLZ7SDyMgdFJpdLdEm",
        net_leverage: 0.0,
        open_ms: 1720020958380,
        orders: [
          {
            leverage: 0.05,
            order_type: "LONG",
            order_uuid: "a13f1249-ee59-4b7d-99a6-932d0bbef5b7",
            price: 18.12295,
            price_sources: [],
            processed_ms: 1720020955831,
          },
          {
            leverage: 0.05500000000000001,
            order_type: "LONG",
            order_uuid: "5952094e-a01d-4147-845a-d1d92d5aec82",
            price: 18.1122,
            price_sources: [],
            processed_ms: 1720021268436,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "f7298158-3342-437d-b30f-0941bbe4439e",
            price: 18.11795,
            price_sources: [],
            processed_ms: 1720021331789,
          },
        ],
        position_type: "FLAT",
        position_uuid: "b726001e-1906-463b-8340-85e0229aacf1",
        return_at_close: 0.9999963055590047,
        trade_pair: ["USDMXN", "USD/MXN", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 18.1056,
        close_ms: 1720026466474,
        current_return: 1.0000946392276422,
        initial_entry_price: 18.1056,
        is_closed_position: true,
        miner_hotkey: "5F7ThFe3ZRxTVFjz4M8ciTHbcVAdVDHLZ7SDyMgdFJpdLdEm",
        net_leverage: 0.0,
        open_ms: 1720021360058,
        orders: [
          {
            leverage: 0.05,
            order_type: "LONG",
            order_uuid: "731853b0-bb96-4285-9f4c-aef3790495f2",
            price: 18.1056,
            price_sources: [],
            processed_ms: 1720021359879,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "ebda6be5-cad0-4530-a158-9de3b80e0320",
            price: 18.13987,
            price_sources: [
              {
                close: 18.13987,
                high: 18.13987,
                lag_ms: 474,
                low: 18.13987,
                open: 18.13987,
                source: "Polygon_ws",
                start_ms: 1720026466000,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 18.13987,
                websocket: true,
              },
              {
                close: 18.1332,
                high: 18.1332,
                lag_ms: 526,
                low: 18.1332,
                open: 18.1332,
                source: "TwelveData_ws",
                start_ms: 1720026467000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1720026466474,
          },
        ],
        position_type: "FLAT",
        position_uuid: "9cd00501-07a3-4ef1-b2b8-6211020905c2",
        return_at_close: 1.0000911388964049,
        trade_pair: ["USDMXN", "USD/MXN", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 18.1297,
        close_ms: 1720027543540,
        current_return: 1.0000485797475474,
        initial_entry_price: 18.1341,
        is_closed_position: true,
        miner_hotkey: "5F7ThFe3ZRxTVFjz4M8ciTHbcVAdVDHLZ7SDyMgdFJpdLdEm",
        net_leverage: 0.0,
        open_ms: 1720026497027,
        orders: [
          {
            leverage: 0.05,
            order_type: "LONG",
            order_uuid: "b4d5276f-45fe-4244-8a3a-f56691324665",
            price: 18.1341,
            price_sources: [
              {
                close: 18.1341,
                high: 18.1341,
                lag_ms: 433,
                low: 18.1341,
                open: 18.1341,
                source: "Polygon_ws",
                start_ms: 1720026496999,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 18.1341,
                websocket: true,
              },
              {
                close: 18.1321,
                high: 18.1321,
                lag_ms: 434,
                low: 18.1321,
                open: 18.1321,
                source: "TwelveData_ws",
                start_ms: 1720026497000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1720026496566,
          },
          {
            leverage: 0.05500000000000001,
            order_type: "LONG",
            order_uuid: "9f84577b-663d-4cc3-9afe-f95321630328",
            price: 18.1257,
            price_sources: [
              {
                close: 18.1257,
                high: 18.1257,
                lag_ms: 7,
                low: 18.1257,
                open: 18.1257,
                source: "Polygon_ws",
                start_ms: 1720026688999,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 18.1257,
                websocket: true,
              },
              {
                close: 18.12531,
                high: 18.12531,
                lag_ms: 8,
                low: 18.12531,
                open: 18.12531,
                source: "TwelveData_ws",
                start_ms: 1720026689000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1720026688992,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "1e10a010-2d3f-4920-88ed-bf85adbc379c",
            price: 18.13809,
            price_sources: [
              {
                close: 18.13809,
                high: 18.13809,
                lag_ms: 459,
                low: 18.13809,
                open: 18.13809,
                source: "Polygon_ws",
                start_ms: 1720027543999,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 18.13809,
                websocket: true,
              },
              {
                close: 18.133,
                high: 18.133,
                lag_ms: 540,
                low: 18.133,
                open: 18.133,
                source: "TwelveData_ws",
                start_ms: 1720027543000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1720027543540,
          },
        ],
        position_type: "FLAT",
        position_uuid: "970a9596-e1eb-40c6-9741-e8cf9dae2068",
        return_at_close: 1.0000412293904863,
        trade_pair: ["USDMXN", "USD/MXN", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 18.13485,
        close_ms: 1720029507167,
        current_return: 1.0000727880296776,
        initial_entry_price: 18.13485,
        is_closed_position: true,
        miner_hotkey: "5F7ThFe3ZRxTVFjz4M8ciTHbcVAdVDHLZ7SDyMgdFJpdLdEm",
        net_leverage: 0.0,
        open_ms: 1720027573425,
        orders: [
          {
            leverage: 0.05,
            order_type: "LONG",
            order_uuid: "8789509b-e27f-4b78-9529-bba30290a607",
            price: 18.13485,
            price_sources: [
              {
                close: 18.13485,
                high: 18.13485,
                lag_ms: 195,
                low: 18.13485,
                open: 18.13485,
                source: "Polygon_ws",
                start_ms: 1720027573000,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 18.13485,
                websocket: true,
              },
              {
                close: 18.13506,
                high: 18.13506,
                lag_ms: 195,
                low: 18.13506,
                open: 18.13506,
                source: "TwelveData_ws",
                start_ms: 1720027573000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1720027573195,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "42647f76-e690-4461-9bff-5c591c635f42",
            price: 18.16125,
            price_sources: [
              {
                close: 18.16125,
                high: 18.16125,
                lag_ms: 167,
                low: 18.16125,
                open: 18.16125,
                source: "Polygon_ws",
                start_ms: 1720029507000,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 18.16125,
                websocket: true,
              },
              {
                close: 18.1605,
                high: 18.1605,
                lag_ms: 167,
                low: 18.1605,
                open: 18.1605,
                source: "TwelveData_ws",
                start_ms: 1720029507000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1720029507167,
          },
        ],
        position_type: "FLAT",
        position_uuid: "6ef610c0-c67d-47c2-824f-b5c14ec498d0",
        return_at_close: 1.0000692877749193,
        trade_pair: ["USDMXN", "USD/MXN", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 18.156799999999997,
        close_ms: 1720029966369,
        current_return: 1.0000531832896562,
        initial_entry_price: 18.1636,
        is_closed_position: true,
        miner_hotkey: "5F7ThFe3ZRxTVFjz4M8ciTHbcVAdVDHLZ7SDyMgdFJpdLdEm",
        net_leverage: 0.0,
        open_ms: 1720029534169,
        orders: [
          {
            leverage: 0.05,
            order_type: "LONG",
            order_uuid: "82664770-d4b5-43aa-a44c-8cc8878166b6",
            price: 18.1636,
            price_sources: [
              {
                close: 18.1636,
                high: 18.1636,
                lag_ms: 30,
                low: 18.1636,
                open: 18.1636,
                source: "Polygon_ws",
                start_ms: 1720029533999,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 18.1636,
                websocket: true,
              },
              {
                close: 18.1606,
                high: 18.1606,
                lag_ms: 31,
                low: 18.1606,
                open: 18.1606,
                source: "TwelveData_ws",
                start_ms: 1720029534000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1720029533969,
          },
          {
            leverage: 0.05,
            order_type: "LONG",
            order_uuid: "f8e39fc8-efb3-421e-8969-719208bb3928",
            price: 18.15,
            price_sources: [
              {
                close: 18.15,
                high: 18.15,
                lag_ms: 16,
                low: 18.15,
                open: 18.15,
                source: "TwelveData_ws",
                start_ms: 1720029654000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
              {
                close: 18.15171,
                high: 18.15171,
                lag_ms: 984,
                low: 18.15171,
                open: 18.15171,
                source: "Polygon_ws",
                start_ms: 1720029655000,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 18.15171,
                websocket: true,
              },
            ],
            processed_ms: 1720029654016,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "b82625ee-3c04-4d8f-946d-ecafdb6e2487",
            price: 18.16646,
            price_sources: [
              {
                close: 18.16646,
                high: 18.16646,
                lag_ms: 369,
                low: 18.16646,
                open: 18.16646,
                source: "Polygon_ws",
                start_ms: 1720029966000,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 18.16646,
                websocket: true,
              },
              {
                close: 18.164,
                high: 18.164,
                lag_ms: 631,
                low: 18.164,
                open: 18.164,
                source: "TwelveData_ws",
                start_ms: 1720029967000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1720029966369,
          },
        ],
        position_type: "FLAT",
        position_uuid: "5107c604-23cd-4a97-adf3-f26c3ae45e11",
        return_at_close: 1.0000461829173732,
        trade_pair: ["USDMXN", "USD/MXN", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 18.1562,
        close_ms: 1720031412291,
        current_return: 1.0000709124155935,
        initial_entry_price: 18.1562,
        is_closed_position: true,
        miner_hotkey: "5F7ThFe3ZRxTVFjz4M8ciTHbcVAdVDHLZ7SDyMgdFJpdLdEm",
        net_leverage: 0.0,
        open_ms: 1720029995964,
        orders: [
          {
            leverage: 0.05,
            order_type: "LONG",
            order_uuid: "518a15d8-9528-4d42-9466-4444bb105414",
            price: 18.1562,
            price_sources: [
              {
                close: 18.1562,
                high: 18.1562,
                lag_ms: 298,
                low: 18.1562,
                open: 18.1562,
                source: "Polygon_ws",
                start_ms: 1720029996000,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 18.1562,
                websocket: true,
              },
              {
                close: 18.16891,
                high: 18.16891,
                lag_ms: 298,
                low: 18.16891,
                open: 18.16891,
                source: "TwelveData_ws",
                start_ms: 1720029996000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1720029995702,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "786a443f-b399-48b7-9581-3a14581c2077",
            price: 18.18195,
            price_sources: [
              {
                close: 18.18195,
                high: 18.18195,
                lag_ms: 291,
                low: 18.18195,
                open: 18.18195,
                source: "Polygon_ws",
                start_ms: 1720031412000,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 18.18195,
                websocket: true,
              },
              {
                close: 18.18147,
                high: 18.18147,
                lag_ms: 291,
                low: 18.18147,
                open: 18.18147,
                source: "TwelveData_ws",
                start_ms: 1720031412000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1720031412291,
          },
        ],
        position_type: "FLAT",
        position_uuid: "f114237a-d4cb-450a-813e-09a24b0054e9",
        return_at_close: 1.0000674121674,
        trade_pair: ["USDMXN", "USD/MXN", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 18.176575,
        close_ms: 1720032465146,
        current_return: 1.0000402864324096,
        initial_entry_price: 18.1823,
        is_closed_position: true,
        miner_hotkey: "5F7ThFe3ZRxTVFjz4M8ciTHbcVAdVDHLZ7SDyMgdFJpdLdEm",
        net_leverage: 0.0,
        open_ms: 1720031446616,
        orders: [
          {
            leverage: 0.05,
            order_type: "LONG",
            order_uuid: "5394173a-68ae-459f-8d37-d9c3bdcaa923",
            price: 18.1823,
            price_sources: [
              {
                close: 18.1823,
                high: 18.1823,
                lag_ms: 124,
                low: 18.1823,
                open: 18.1823,
                source: "Polygon_ws",
                start_ms: 1720031446000,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 18.1823,
                websocket: true,
              },
              {
                close: 18.18144,
                high: 18.18144,
                lag_ms: 124,
                low: 18.18144,
                open: 18.18144,
                source: "TwelveData_ws",
                start_ms: 1720031446000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1720031446124,
          },
          {
            leverage: 0.05,
            order_type: "LONG",
            order_uuid: "e6c5dcf7-efff-4341-ae6f-8982676f94d0",
            price: 18.17085,
            price_sources: [
              {
                close: 18.17085,
                high: 18.17085,
                lag_ms: 464,
                low: 18.17085,
                open: 18.17085,
                source: "Polygon_ws",
                start_ms: 1720031778000,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 18.17085,
                websocket: true,
              },
              {
                close: 18.17028,
                high: 18.17028,
                lag_ms: 464,
                low: 18.17028,
                open: 18.17028,
                source: "TwelveData_ws",
                start_ms: 1720031778000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1720031778464,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "82dc1147-ad36-4aa6-8f46-5e38def84831",
            price: 18.1839,
            price_sources: [
              {
                close: 18.1839,
                high: 18.1839,
                lag_ms: 146,
                low: 18.1839,
                open: 18.1839,
                source: "Polygon_ws",
                start_ms: 1720032465000,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 18.1839,
                websocket: true,
              },
              {
                close: 18.1835,
                high: 18.1835,
                lag_ms: 854,
                low: 18.1835,
                open: 18.1835,
                source: "TwelveData_ws",
                start_ms: 1720032466000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1720032465146,
          },
        ],
        position_type: "FLAT",
        position_uuid: "c657559d-d4ad-47b6-9bc8-59e4bdc94f3e",
        return_at_close: 1.0000332861504047,
        trade_pair: ["USDMXN", "USD/MXN", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 18.177254545454545,
        close_ms: 1720033420331,
        current_return: 1.000025776342856,
        initial_entry_price: 18.1814,
        is_closed_position: true,
        miner_hotkey: "5F7ThFe3ZRxTVFjz4M8ciTHbcVAdVDHLZ7SDyMgdFJpdLdEm",
        net_leverage: 0.0,
        open_ms: 1720032495148,
        orders: [
          {
            leverage: 0.05,
            order_type: "LONG",
            order_uuid: "d7266de4-2282-4cf5-9551-aaa95d44bf53",
            price: 18.1814,
            price_sources: [
              {
                close: 18.1814,
                high: 18.1814,
                lag_ms: 95,
                low: 18.1814,
                open: 18.1814,
                source: "Polygon_ws",
                start_ms: 1720032494999,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 18.1814,
                websocket: true,
              },
              {
                close: 18.1783,
                high: 18.1783,
                lag_ms: 96,
                low: 18.1783,
                open: 18.1783,
                source: "TwelveData_ws",
                start_ms: 1720032495000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1720032494904,
          },
          {
            leverage: 0.06,
            order_type: "LONG",
            order_uuid: "cfaf9e56-e867-4903-b657-77bd0b889982",
            price: 18.1738,
            price_sources: [
              {
                close: 18.1738,
                high: 18.1738,
                lag_ms: 111,
                low: 18.1738,
                open: 18.1738,
                source: "Polygon_ws",
                start_ms: 1720033105999,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 18.1738,
                websocket: true,
              },
              {
                close: 18.17379,
                high: 18.17379,
                lag_ms: 1112,
                low: 18.17379,
                open: 18.17379,
                source: "TwelveData_ws",
                start_ms: 1720033107000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1720033105888,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "49744081-49f5-4bec-a9ca-944d58ce9f24",
            price: 18.181514999999997,
            price_sources: [
              {
                close: 18.181514999999997,
                high: 18.181514999999997,
                lag_ms: 669,
                low: 18.181514999999997,
                open: 18.181514999999997,
                source: "Polygon_ws",
                start_ms: 1720033421000,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 18.18122,
                websocket: true,
              },
              {
                close: 18.178,
                high: 18.178,
                lag_ms: 669,
                low: 18.178,
                open: 18.178,
                source: "TwelveData_ws",
                start_ms: 1720033421000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1720033420331,
          },
        ],
        position_type: "FLAT",
        position_uuid: "8522be44-99a7-409f-89f4-6972f4332618",
        return_at_close: 1.000018076144378,
        trade_pair: ["USDMXN", "USD/MXN", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 18.176560000000002,
        close_ms: 1720034938035,
        current_return: 1.0000161707098942,
        initial_entry_price: 18.18102,
        is_closed_position: true,
        miner_hotkey: "5F7ThFe3ZRxTVFjz4M8ciTHbcVAdVDHLZ7SDyMgdFJpdLdEm",
        net_leverage: 0.0,
        open_ms: 1720033450903,
        orders: [
          {
            leverage: 0.05,
            order_type: "LONG",
            order_uuid: "b81bfae6-1d6a-465f-989c-0bcc638f66ac",
            price: 18.18102,
            price_sources: [
              {
                close: 18.18102,
                high: 18.18102,
                lag_ms: 148,
                low: 18.18102,
                open: 18.18102,
                source: "TwelveData_ws",
                start_ms: 1720033450000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
              {
                close: 18.181345,
                high: 18.181345,
                lag_ms: 852,
                low: 18.181345,
                open: 18.181345,
                source: "Polygon_ws",
                start_ms: 1720033451000,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 18.18174,
                websocket: true,
              },
              {
                close: 18.174775,
                high: 18.174775,
                lag_ms: 2149,
                low: 18.174775,
                open: 18.174775,
                source: "Polygon_rest",
                start_ms: 1720033447000,
                timespan_ms: 1000,
                volume: 2.0,
                vwap: null,
                websocket: false,
              },
              {
                close: 18.18405,
                high: 18.1885,
                lag_ms: 610149,
                low: 18.1805,
                open: 18.18055,
                source: "TwelveData_rest",
                start_ms: 1720032780000,
                timespan_ms: 60000,
                volume: null,
                vwap: null,
                websocket: false,
              },
            ],
            processed_ms: 1720033450148,
          },
          {
            leverage: 0.05,
            order_type: "LONG",
            order_uuid: "3061262a-9513-4a19-8dee-bcdf398e5a06",
            price: 18.1721,
            price_sources: [
              {
                close: 18.1721,
                high: 18.1721,
                lag_ms: 340,
                low: 18.1721,
                open: 18.1721,
                source: "Polygon_ws",
                start_ms: 1720034888000,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 18.1721,
                websocket: true,
              },
              {
                close: 18.17192,
                high: 18.17192,
                lag_ms: 340,
                low: 18.17192,
                open: 18.17192,
                source: "TwelveData_ws",
                start_ms: 1720034888000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1720034888340,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "0cbc2a4c-ba4d-4e70-9e3d-7fb7d3c3a629",
            price: 18.1795,
            price_sources: [
              {
                close: 18.1795,
                high: 18.1795,
                lag_ms: 36,
                low: 18.1795,
                open: 18.1795,
                source: "Polygon_ws",
                start_ms: 1720034937999,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 18.1795,
                websocket: true,
              },
              {
                close: 18.17955,
                high: 18.17955,
                lag_ms: 965,
                low: 18.17955,
                open: 18.17955,
                source: "TwelveData_ws",
                start_ms: 1720034939000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1720034938035,
          },
        ],
        position_type: "FLAT",
        position_uuid: "1f7c5520-89d3-4992-8db1-6a697b32d878",
        return_at_close: 1.0000091705966994,
        trade_pair: ["USDMXN", "USD/MXN", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 18.175,
        close_ms: 1720035926030,
        current_return: 1.000067977991747,
        initial_entry_price: 18.175,
        is_closed_position: true,
        miner_hotkey: "5F7ThFe3ZRxTVFjz4M8ciTHbcVAdVDHLZ7SDyMgdFJpdLdEm",
        net_leverage: 0.0,
        open_ms: 1720034968063,
        orders: [
          {
            leverage: 0.05,
            order_type: "LONG",
            order_uuid: "ccf2d08d-187b-4b9c-a444-7e7228bb0728",
            price: 18.175,
            price_sources: [
              {
                close: 18.175,
                high: 18.175,
                lag_ms: 191,
                low: 18.175,
                open: 18.175,
                source: "TwelveData_ws",
                start_ms: 1720034968000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
              {
                close: 18.17999,
                high: 18.17999,
                lag_ms: 810,
                low: 18.17999,
                open: 18.17999,
                source: "Polygon_ws",
                start_ms: 1720034966999,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 18.17999,
                websocket: true,
              },
            ],
            processed_ms: 1720034967809,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "2905baec-f5c3-4df9-a8c8-e1311b73ca9d",
            price: 18.19971,
            price_sources: [
              {
                close: 18.19971,
                high: 18.19971,
                lag_ms: 30,
                low: 18.19971,
                open: 18.19971,
                source: "Polygon_ws",
                start_ms: 1720035926000,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 18.19971,
                websocket: true,
              },
              {
                close: 18.20014,
                high: 18.20014,
                lag_ms: 30,
                low: 18.20014,
                open: 18.20014,
                source: "TwelveData_ws",
                start_ms: 1720035926000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1720035926030,
          },
        ],
        position_type: "FLAT",
        position_uuid: "9645a5ab-0d93-4b66-9423-995a602dae0f",
        return_at_close: 1.000064477753824,
        trade_pair: ["USDMXN", "USD/MXN", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 18.19575714285714,
        close_ms: 1720036201180,
        current_return: 0.9999941878040067,
        initial_entry_price: 18.194499999999998,
        is_closed_position: true,
        miner_hotkey: "5F7ThFe3ZRxTVFjz4M8ciTHbcVAdVDHLZ7SDyMgdFJpdLdEm",
        net_leverage: 0.0,
        open_ms: 1720035960976,
        orders: [
          {
            leverage: 0.05,
            order_type: "LONG",
            order_uuid: "2ed7c47a-0346-4917-ad9b-65fea2c40d5c",
            price: 18.194499999999998,
            price_sources: [
              {
                close: 18.194499999999998,
                high: 18.194499999999998,
                lag_ms: 160,
                low: 18.194499999999998,
                open: 18.194499999999998,
                source: "Polygon_ws",
                start_ms: 1720035960999,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 18.2001,
                websocket: true,
              },
              {
                close: 18.19895,
                high: 18.19895,
                lag_ms: 161,
                low: 18.19895,
                open: 18.19895,
                source: "TwelveData_ws",
                start_ms: 1720035961000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1720035960839,
          },
          {
            leverage: 0.05500000000000001,
            order_type: "LONG",
            order_uuid: "68533440-134e-4cc0-b1a0-8cab202aa548",
            price: 18.1969,
            price_sources: [
              {
                close: 18.1969,
                high: 18.1969,
                lag_ms: 361,
                low: 18.1969,
                open: 18.1969,
                source: "Polygon_ws",
                start_ms: 1720036095000,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 18.1969,
                websocket: true,
              },
              {
                close: 18.1895,
                high: 18.1895,
                lag_ms: 361,
                low: 18.1895,
                open: 18.1895,
                source: "TwelveData_ws",
                start_ms: 1720036095000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1720036095361,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "79760f55-5cc8-4e0b-8417-4199d25df808",
            price: 18.19475,
            price_sources: [
              {
                close: 18.19475,
                high: 18.19475,
                lag_ms: 180,
                low: 18.19475,
                open: 18.19475,
                source: "Polygon_ws",
                start_ms: 1720036201000,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 18.2007,
                websocket: true,
              },
              {
                close: 18.2004,
                high: 18.2004,
                lag_ms: 180,
                low: 18.2004,
                open: 18.2004,
                source: "TwelveData_ws",
                start_ms: 1720036201000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
              {
                close: 18.2012,
                high: 18.2012,
                lag_ms: 1181,
                low: 18.2012,
                open: 18.2012,
                source: "Polygon_rest",
                start_ms: 1720036199000,
                timespan_ms: 1000,
                volume: 2.0,
                vwap: null,
                websocket: false,
              },
              {
                close: 18.1866,
                high: 18.1878,
                lag_ms: 481181,
                low: 18.1855,
                open: 18.1876,
                source: "TwelveData_rest",
                start_ms: 1720035660000,
                timespan_ms: 60000,
                volume: null,
                vwap: null,
                websocket: false,
              },
            ],
            processed_ms: 1720036201180,
          },
        ],
        position_type: "FLAT",
        position_uuid: "27dd4fe0-81b6-41b3-b7f9-ea1fcddf8f54",
        return_at_close: 0.9999868378467264,
        trade_pair: ["USDMXN", "USD/MXN", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 18.193412857142857,
        close_ms: 1720037638872,
        current_return: 0.9999041592263949,
        initial_entry_price: 18.2005,
        is_closed_position: true,
        miner_hotkey: "5F7ThFe3ZRxTVFjz4M8ciTHbcVAdVDHLZ7SDyMgdFJpdLdEm",
        net_leverage: 0.0,
        open_ms: 1720036235268,
        orders: [
          {
            leverage: 0.05,
            order_type: "LONG",
            order_uuid: "78b5854b-6a39-4e46-aa82-e0e9ff10ed29",
            price: 18.2005,
            price_sources: [
              {
                close: 18.2005,
                high: 18.2005,
                lag_ms: 35,
                low: 18.2005,
                open: 18.2005,
                source: "Polygon_ws",
                start_ms: 1720036235000,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 18.2005,
                websocket: true,
              },
              {
                close: 18.20043,
                high: 18.20043,
                lag_ms: 35,
                low: 18.20043,
                open: 18.20043,
                source: "TwelveData_ws",
                start_ms: 1720036235000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1720036234965,
          },
          {
            leverage: 0.05500000000000001,
            order_type: "LONG",
            order_uuid: "87b136be-b18b-4b78-b83a-f6e0489a781b",
            price: 18.18697,
            price_sources: [
              {
                close: 18.18697,
                high: 18.18697,
                lag_ms: 265,
                low: 18.18697,
                open: 18.18697,
                source: "Polygon_ws",
                start_ms: 1720036513000,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 18.18697,
                websocket: true,
              },
              {
                close: 18.185,
                high: 18.185,
                lag_ms: 265,
                low: 18.185,
                open: 18.185,
                source: "TwelveData_ws",
                start_ms: 1720036513000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1720036513265,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "f88fd820-7473-4add-9ac8-ead9fd2a4be9",
            price: 18.1768,
            price_sources: [
              {
                close: 18.1768,
                high: 18.1768,
                lag_ms: 127,
                low: 18.1768,
                open: 18.1768,
                source: "Polygon_ws",
                start_ms: 1720037638999,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 18.1768,
                websocket: true,
              },
              {
                close: 18.18851,
                high: 18.18851,
                lag_ms: 872,
                low: 18.18851,
                open: 18.18851,
                source: "TwelveData_ws",
                start_ms: 1720037638000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1720037638872,
          },
        ],
        position_type: "FLAT",
        position_uuid: "67c55a47-c602-46b8-a850-100970843aab",
        return_at_close: 0.9998968099308246,
        trade_pair: ["USDMXN", "USD/MXN", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 18.1844,
        close_ms: 1720039379953,
        current_return: 1.0000448461318492,
        initial_entry_price: 18.1844,
        is_closed_position: true,
        miner_hotkey: "5F7ThFe3ZRxTVFjz4M8ciTHbcVAdVDHLZ7SDyMgdFJpdLdEm",
        net_leverage: 0.0,
        open_ms: 1720037669912,
        orders: [
          {
            leverage: 0.05,
            order_type: "LONG",
            order_uuid: "da788a7e-8cf2-402c-9ef9-65f472f19074",
            price: 18.1844,
            price_sources: [
              {
                close: 18.1844,
                high: 18.1844,
                lag_ms: 287,
                low: 18.1844,
                open: 18.1844,
                source: "Polygon_ws",
                start_ms: 1720037669999,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 18.1781,
                websocket: true,
              },
              {
                close: 18.19086,
                high: 18.19086,
                lag_ms: 712,
                low: 18.19086,
                open: 18.19086,
                source: "TwelveData_ws",
                start_ms: 1720037669000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1720037669712,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "fd7fcfd0-6940-4bc6-a20c-e3dfb71c6cf5",
            price: 18.20071,
            price_sources: [
              {
                close: 18.20071,
                high: 18.20071,
                lag_ms: 46,
                low: 18.20071,
                open: 18.20071,
                source: "Polygon_ws",
                start_ms: 1720039379999,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 18.20071,
                websocket: true,
              },
              {
                close: 18.196,
                high: 18.196,
                lag_ms: 47,
                low: 18.196,
                open: 18.196,
                source: "TwelveData_ws",
                start_ms: 1720039380000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1720039379953,
          },
        ],
        position_type: "FLAT",
        position_uuid: "0b8637c5-ddd2-411f-9b36-60e4b90717c6",
        return_at_close: 1.0000413459748876,
        trade_pair: ["USDMXN", "USD/MXN", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 18.172864772727273,
        close_ms: 1720040664212,
        current_return: 0.9995450608323021,
        initial_entry_price: 18.20661,
        is_closed_position: true,
        miner_hotkey: "5F7ThFe3ZRxTVFjz4M8ciTHbcVAdVDHLZ7SDyMgdFJpdLdEm",
        net_leverage: 0.0,
        open_ms: 1720039408829,
        orders: [
          {
            leverage: 0.05,
            order_type: "LONG",
            order_uuid: "4ffce78d-fdec-4142-89d0-b5a4c080d9a1",
            price: 18.20661,
            price_sources: [
              {
                close: 18.20661,
                high: 18.20661,
                lag_ms: 283,
                low: 18.20661,
                open: 18.20661,
                source: "Polygon_ws",
                start_ms: 1720039408999,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 18.20661,
                websocket: true,
              },
              {
                close: 18.20613,
                high: 18.20613,
                lag_ms: 284,
                low: 18.20613,
                open: 18.20613,
                source: "TwelveData_ws",
                start_ms: 1720039409000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1720039408716,
          },
          {
            leverage: 0.05,
            order_type: "LONG",
            order_uuid: "adb9dbcb-1b78-4226-92da-8f7a8aff4119",
            price: 18.1847,
            price_sources: [
              {
                close: 18.1847,
                high: 18.1847,
                lag_ms: 145,
                low: 18.1847,
                open: 18.1847,
                source: "Polygon_ws",
                start_ms: 1720039442999,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 18.1847,
                websocket: true,
              },
              {
                close: 18.19552,
                high: 18.19552,
                lag_ms: 856,
                low: 18.19552,
                open: 18.19552,
                source: "TwelveData_ws",
                start_ms: 1720039444000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1720039443144,
          },
          {
            leverage: 0.1,
            order_type: "LONG",
            order_uuid: "c54c2748-3195-44e0-aa91-7775b152b1a5",
            price: 18.17735,
            price_sources: [
              {
                close: 18.17735,
                high: 18.17735,
                lag_ms: 955,
                low: 18.17735,
                open: 18.17735,
                source: "Polygon_ws",
                start_ms: 1720039864999,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 18.17735,
                websocket: true,
              },
              {
                close: 18.176,
                high: 18.176,
                lag_ms: 1954,
                low: 18.176,
                open: 18.176,
                source: "TwelveData_ws",
                start_ms: 1720039864000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1720039865954,
          },
          {
            leverage: 0.24,
            order_type: "LONG",
            order_uuid: "b53dff40-4bf5-48f6-a8ba-35c0ee9c4f33",
            price: 18.1615,
            price_sources: [
              {
                close: 18.1615,
                high: 18.1615,
                lag_ms: 20,
                low: 18.1615,
                open: 18.1615,
                source: "Polygon_ws",
                start_ms: 1720040623999,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 18.1615,
                websocket: true,
              },
              {
                close: 18.1615,
                high: 18.1615,
                lag_ms: 20,
                low: 18.1615,
                open: 18.1615,
                source: "Polygon_rest",
                start_ms: 1720040623000,
                timespan_ms: 1000,
                volume: 1.0,
                vwap: null,
                websocket: false,
              },
              {
                close: 18.1715,
                high: 18.1715,
                lag_ms: 2979,
                low: 18.1715,
                open: 18.1715,
                source: "TwelveData_ws",
                start_ms: 1720040621000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
              {
                close: 18.1976,
                high: 18.202,
                lag_ms: 943980,
                low: 18.193,
                open: 18.1985,
                source: "TwelveData_rest",
                start_ms: 1720039620000,
                timespan_ms: 60000,
                volume: null,
                vwap: null,
                websocket: false,
              },
            ],
            processed_ms: 1720040623979,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "a601707e-733a-40df-ae29-6779dfff200d",
            price: 18.15404,
            price_sources: [
              {
                close: 18.15404,
                high: 18.15404,
                lag_ms: 788,
                low: 18.15404,
                open: 18.15404,
                source: "TwelveData_ws",
                start_ms: 1720040665000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
              {
                close: 18.1604,
                high: 18.1604,
                lag_ms: 2788,
                low: 18.1604,
                open: 18.1604,
                source: "Polygon_ws",
                start_ms: 1720040667000,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 18.1604,
                websocket: true,
              },
              {
                close: 18.1615,
                high: 18.1615,
                lag_ms: 4213,
                low: 18.1615,
                open: 18.1615,
                source: "Polygon_rest",
                start_ms: 1720040659000,
                timespan_ms: 1000,
                volume: 1.0,
                vwap: null,
                websocket: false,
              },
              {
                close: 18.1976,
                high: 18.202,
                lag_ms: 984213,
                low: 18.193,
                open: 18.1985,
                source: "TwelveData_rest",
                start_ms: 1720039620000,
                timespan_ms: 60000,
                volume: null,
                vwap: null,
                websocket: false,
              },
            ],
            processed_ms: 1720040664212,
          },
        ],
        position_type: "FLAT",
        position_uuid: "ed8ec0b2-9839-45d4-9ce6-c220d0418b69",
        return_at_close: 0.9995142748444285,
        trade_pair: ["USDMXN", "USD/MXN", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 18.16425,
        close_ms: 1720042141599,
        current_return: 0.9999859651053993,
        initial_entry_price: 18.169,
        is_closed_position: true,
        miner_hotkey: "5F7ThFe3ZRxTVFjz4M8ciTHbcVAdVDHLZ7SDyMgdFJpdLdEm",
        net_leverage: 0.0,
        open_ms: 1720040695067,
        orders: [
          {
            leverage: 0.05,
            order_type: "LONG",
            order_uuid: "2bc8dc72-994e-49a1-9c4a-c3eaf531bed5",
            price: 18.169,
            price_sources: [
              {
                close: 18.169,
                high: 18.169,
                lag_ms: 356,
                low: 18.169,
                open: 18.169,
                source: "Polygon_ws",
                start_ms: 1720040695000,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 18.169,
                websocket: true,
              },
              {
                close: 18.169,
                high: 18.169,
                lag_ms: 1356,
                low: 18.169,
                open: 18.169,
                source: "TwelveData_ws",
                start_ms: 1720040696000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1720040694644,
          },
          {
            leverage: 0.05,
            order_type: "LONG",
            order_uuid: "703f3bf0-bf07-432d-823c-24a0bdd91e4d",
            price: 18.1595,
            price_sources: [
              {
                close: 18.1595,
                high: 18.1595,
                lag_ms: 883,
                low: 18.1595,
                open: 18.1595,
                source: "Polygon_ws",
                start_ms: 1720042112000,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 18.1595,
                websocket: true,
              },
              {
                close: 18.1594,
                high: 18.1594,
                lag_ms: 3118,
                low: 18.1594,
                open: 18.1594,
                source: "Polygon_rest",
                start_ms: 1720042107000,
                timespan_ms: 1000,
                volume: 1.0,
                vwap: null,
                websocket: false,
              },
              {
                close: 18.1625,
                high: 18.1625,
                lag_ms: 8883,
                low: 18.1625,
                open: 18.1625,
                source: "TwelveData_ws",
                start_ms: 1720042120000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
              {
                close: 18.185,
                high: 18.185,
                lag_ms: 451118,
                low: 18.185,
                open: 18.185,
                source: "TwelveData_rest",
                start_ms: 1720041600000,
                timespan_ms: 60000,
                volume: null,
                vwap: null,
                websocket: false,
              },
            ],
            processed_ms: 1720042111117,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "412991ea-2eaf-4880-a9e7-aa5647e70220",
            price: 18.1617,
            price_sources: [
              {
                close: 18.1617,
                high: 18.1617,
                lag_ms: 401,
                low: 18.1617,
                open: 18.1617,
                source: "Polygon_ws",
                start_ms: 1720042142000,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 18.1617,
                websocket: true,
              },
              {
                close: 18.1597,
                high: 18.1597,
                lag_ms: 3600,
                low: 18.1597,
                open: 18.1597,
                source: "Polygon_rest",
                start_ms: 1720042137000,
                timespan_ms: 1000,
                volume: 1.0,
                vwap: null,
                websocket: false,
              },
              {
                close: 18.153,
                high: 18.153,
                lag_ms: 5599,
                low: 18.153,
                open: 18.153,
                source: "TwelveData_ws",
                start_ms: 1720042136000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
              {
                close: 18.185,
                high: 18.185,
                lag_ms: 481600,
                low: 18.185,
                open: 18.185,
                source: "TwelveData_rest",
                start_ms: 1720041600000,
                timespan_ms: 60000,
                volume: null,
                vwap: null,
                websocket: false,
              },
            ],
            processed_ms: 1720042141599,
          },
        ],
        position_type: "FLAT",
        position_uuid: "2671277f-414b-40e5-ac4f-d49f844eb8c4",
        return_at_close: 0.9999789652036436,
        trade_pair: ["USDMXN", "USD/MXN", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 18.160757142857143,
        close_ms: 1720042244181,
        current_return: 0.9999950445167578,
        initial_entry_price: 18.1617,
        is_closed_position: true,
        miner_hotkey: "5F7ThFe3ZRxTVFjz4M8ciTHbcVAdVDHLZ7SDyMgdFJpdLdEm",
        net_leverage: 0.0,
        open_ms: 1720042173650,
        orders: [
          {
            leverage: 0.05,
            order_type: "LONG",
            order_uuid: "af427d1c-8993-4f6a-bb4d-2f95c62829d1",
            price: 18.1617,
            price_sources: [
              {
                close: 18.1617,
                high: 18.1617,
                lag_ms: 1374,
                low: 18.1617,
                open: 18.1617,
                source: "Polygon_ws",
                start_ms: 1720042173000,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 18.1617,
                websocket: true,
              },
              {
                close: 18.1597,
                high: 18.1597,
                lag_ms: 3627,
                low: 18.1597,
                open: 18.1597,
                source: "Polygon_rest",
                start_ms: 1720042167000,
                timespan_ms: 1000,
                volume: 1.0,
                vwap: null,
                websocket: false,
              },
              {
                close: 18.15285,
                high: 18.15285,
                lag_ms: 5626,
                low: 18.15285,
                open: 18.15285,
                source: "TwelveData_ws",
                start_ms: 1720042166000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
              {
                close: 18.185,
                high: 18.185,
                lag_ms: 511627,
                low: 18.185,
                open: 18.185,
                source: "TwelveData_rest",
                start_ms: 1720041600000,
                timespan_ms: 60000,
                volume: null,
                vwap: null,
                websocket: false,
              },
            ],
            processed_ms: 1720042171626,
          },
          {
            leverage: 0.05500000000000001,
            order_type: "LONG",
            order_uuid: "b081e397-d876-4f05-a427-4116c710274c",
            price: 18.1599,
            price_sources: [
              {
                close: 18.1599,
                high: 18.1599,
                lag_ms: 256,
                low: 18.1599,
                open: 18.1599,
                source: "Polygon_ws",
                start_ms: 1720042212000,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 18.1599,
                websocket: true,
              },
              {
                close: 18.15304,
                high: 18.15304,
                lag_ms: 1256,
                low: 18.15304,
                open: 18.15304,
                source: "TwelveData_ws",
                start_ms: 1720042213000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1720042211744,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "4fb270a9-7f73-4e7f-a050-d8f2520cf7ee",
            price: 18.1599,
            price_sources: [
              {
                close: 18.1599,
                high: 18.1599,
                lag_ms: 1182,
                low: 18.1599,
                open: 18.1599,
                source: "Polygon_ws",
                start_ms: 1720042242999,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 18.1599,
                websocket: true,
              },
              {
                close: 18.14872,
                high: 18.14872,
                lag_ms: 2181,
                low: 18.14872,
                open: 18.14872,
                source: "TwelveData_ws",
                start_ms: 1720042242000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1720042244181,
          },
        ],
        position_type: "FLAT",
        position_uuid: "7bd86935-2f5d-489f-bbef-a0430bde80b2",
        return_at_close: 0.9999876945531806,
        trade_pair: ["USDMXN", "USD/MXN", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 18.168525,
        close_ms: 1720042499964,
        current_return: 1.000060966211232,
        initial_entry_price: 18.1658,
        is_closed_position: true,
        miner_hotkey: "5F7ThFe3ZRxTVFjz4M8ciTHbcVAdVDHLZ7SDyMgdFJpdLdEm",
        net_leverage: 0.0,
        open_ms: 1720042282633,
        orders: [
          {
            leverage: 0.05,
            order_type: "LONG",
            order_uuid: "273e4b60-0791-483c-a850-110e95c78f85",
            price: 18.1658,
            price_sources: [
              {
                close: 18.1658,
                high: 18.1658,
                lag_ms: 1,
                low: 18.1658,
                open: 18.1658,
                source: "Polygon_ws",
                start_ms: 1720042282000,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 18.1658,
                websocket: true,
              },
              {
                close: 18.1656,
                high: 18.1656,
                lag_ms: 4000,
                low: 18.1656,
                open: 18.1656,
                source: "Polygon_rest",
                start_ms: 1720042277000,
                timespan_ms: 1000,
                volume: 1.0,
                vwap: null,
                websocket: false,
              },
              {
                close: 18.153,
                high: 18.153,
                lag_ms: 4001,
                low: 18.153,
                open: 18.153,
                source: "TwelveData_ws",
                start_ms: 1720042286000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
              {
                close: 18.185,
                high: 18.185,
                lag_ms: 622000,
                low: 18.185,
                open: 18.185,
                source: "TwelveData_rest",
                start_ms: 1720041600000,
                timespan_ms: 60000,
                volume: null,
                vwap: null,
                websocket: false,
              },
            ],
            processed_ms: 1720042281999,
          },
          {
            leverage: 0.05,
            order_type: "LONG",
            order_uuid: "5968187f-d8f9-44a7-af45-101ad3405f7f",
            price: 18.17125,
            price_sources: [
              {
                close: 18.17125,
                high: 18.17125,
                lag_ms: 857,
                low: 18.17125,
                open: 18.17125,
                source: "Polygon_ws",
                start_ms: 1720042465999,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 18.1673,
                websocket: true,
              },
              {
                close: 18.1747,
                high: 18.1747,
                lag_ms: 3856,
                low: 18.1747,
                open: 18.1747,
                source: "TwelveData_ws",
                start_ms: 1720042463000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1720042466856,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "53aa009c-76fe-408c-96f3-58c5e5b7767c",
            price: 18.1796,
            price_sources: [
              {
                close: 18.1796,
                high: 18.1796,
                lag_ms: 36,
                low: 18.1796,
                open: 18.1796,
                source: "Polygon_ws",
                start_ms: 1720042500000,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 18.1796,
                websocket: true,
              },
              {
                close: 18.1809,
                high: 18.1809,
                lag_ms: 3965,
                low: 18.1809,
                open: 18.1809,
                source: "Polygon_rest",
                start_ms: 1720042495000,
                timespan_ms: 1000,
                volume: 1.0,
                vwap: null,
                websocket: false,
              },
              {
                close: 18.2295,
                high: 18.2295,
                lag_ms: 23036,
                low: 18.2295,
                open: 18.2295,
                source: "TwelveData_ws",
                start_ms: 1720042523000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
              {
                close: 18.1836,
                high: 18.1836,
                lag_ms: 179965,
                low: 18.1819,
                open: 18.1819,
                source: "TwelveData_rest",
                start_ms: 1720042260000,
                timespan_ms: 60000,
                volume: null,
                vwap: null,
                websocket: false,
              },
            ],
            processed_ms: 1720042499964,
          },
        ],
        position_type: "FLAT",
        position_uuid: "ee7cd612-c070-47f2-b8f4-0ac00a18dc43",
        return_at_close: 1.0000539657844687,
        trade_pair: ["USDMXN", "USD/MXN", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 18.182,
        close_ms: 1720042563651,
        current_return: 0.9999785502144979,
        initial_entry_price: 18.182,
        is_closed_position: true,
        miner_hotkey: "5F7ThFe3ZRxTVFjz4M8ciTHbcVAdVDHLZ7SDyMgdFJpdLdEm",
        net_leverage: 0.0,
        open_ms: 1720042530639,
        orders: [
          {
            leverage: 0.05,
            order_type: "LONG",
            order_uuid: "add4fcee-4ef3-4944-92af-ba3fde44ee00",
            price: 18.182,
            price_sources: [
              {
                close: 18.182,
                high: 18.182,
                lag_ms: 365,
                low: 18.182,
                open: 18.182,
                source: "Polygon_ws",
                start_ms: 1720042529999,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 18.182,
                websocket: true,
              },
              {
                close: 18.181,
                high: 18.181,
                lag_ms: 3635,
                low: 18.181,
                open: 18.181,
                source: "Polygon_rest",
                start_ms: 1720042525000,
                timespan_ms: 1000,
                volume: 1.0,
                vwap: null,
                websocket: false,
              },
              {
                close: 18.2295,
                high: 18.2295,
                lag_ms: 6634,
                low: 18.2295,
                open: 18.2295,
                source: "TwelveData_ws",
                start_ms: 1720042523000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
              {
                close: 18.1836,
                high: 18.1836,
                lag_ms: 209635,
                low: 18.1819,
                open: 18.1819,
                source: "TwelveData_rest",
                start_ms: 1720042260000,
                timespan_ms: 60000,
                volume: null,
                vwap: null,
                websocket: false,
              },
            ],
            processed_ms: 1720042529634,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "55f52348-5568-4cbd-9034-f5e43e1c0b29",
            price: 18.1742,
            price_sources: [
              {
                close: 18.1742,
                high: 18.1742,
                lag_ms: 349,
                low: 18.1742,
                open: 18.1742,
                source: "Polygon_ws",
                start_ms: 1720042564000,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 18.1742,
                websocket: true,
              },
              {
                close: 18.1762,
                high: 18.1762,
                lag_ms: 3652,
                low: 18.1762,
                open: 18.1762,
                source: "Polygon_rest",
                start_ms: 1720042559000,
                timespan_ms: 1000,
                volume: 1.0,
                vwap: null,
                websocket: false,
              },
              {
                close: 18.1875,
                high: 18.1875,
                lag_ms: 17651,
                low: 18.1875,
                open: 18.1875,
                source: "TwelveData_ws",
                start_ms: 1720042546000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
              {
                close: 18.1836,
                high: 18.1836,
                lag_ms: 243652,
                low: 18.1819,
                open: 18.1819,
                source: "TwelveData_rest",
                start_ms: 1720042260000,
                timespan_ms: 60000,
                volume: null,
                vwap: null,
                websocket: false,
              },
            ],
            processed_ms: 1720042563651,
          },
        ],
        position_type: "FLAT",
        position_uuid: "7c7481c4-416a-464f-913e-ec8ae95a9ba3",
        return_at_close: 0.9999750502895721,
        trade_pair: ["USDMXN", "USD/MXN", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 18.17280476190476,
        close_ms: 1720042719869,
        current_return: 0.9999988169066787,
        initial_entry_price: 18.1727,
        is_closed_position: true,
        miner_hotkey: "5F7ThFe3ZRxTVFjz4M8ciTHbcVAdVDHLZ7SDyMgdFJpdLdEm",
        net_leverage: 0.0,
        open_ms: 1720042594675,
        orders: [
          {
            leverage: 0.05,
            order_type: "LONG",
            order_uuid: "7f23581c-0734-4cf0-9c41-179bb2df0fcf",
            price: 18.1727,
            price_sources: [
              {
                close: 18.1727,
                high: 18.1727,
                lag_ms: 24,
                low: 18.1727,
                open: 18.1727,
                source: "Polygon_ws",
                start_ms: 1720042594000,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 18.1727,
                websocket: true,
              },
              {
                close: 18.1729,
                high: 18.1729,
                lag_ms: 4025,
                low: 18.1729,
                open: 18.1729,
                source: "Polygon_rest",
                start_ms: 1720042589000,
                timespan_ms: 1000,
                volume: 1.0,
                vwap: null,
                websocket: false,
              },
              {
                close: 18.1875,
                high: 18.1875,
                lag_ms: 48024,
                low: 18.1875,
                open: 18.1875,
                source: "TwelveData_ws",
                start_ms: 1720042546000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
              {
                close: 18.1836,
                high: 18.1836,
                lag_ms: 274025,
                low: 18.1819,
                open: 18.1819,
                source: "TwelveData_rest",
                start_ms: 1720042260000,
                timespan_ms: 60000,
                volume: null,
                vwap: null,
                websocket: false,
              },
            ],
            processed_ms: 1720042594024,
          },
          {
            leverage: 0.05500000000000001,
            order_type: "LONG",
            order_uuid: "1f281c29-deeb-4d59-9906-c0b3fe4a59f8",
            price: 18.1729,
            price_sources: [
              {
                close: 18.1729,
                high: 18.1729,
                lag_ms: 896,
                low: 18.1729,
                open: 18.1729,
                source: "Polygon_ws",
                start_ms: 1720042683000,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 18.1729,
                websocket: true,
              },
              {
                close: 18.18250084,
                high: 18.18250084,
                lag_ms: 2104,
                low: 18.18250084,
                open: 18.18250084,
                source: "TwelveData_ws",
                start_ms: 1720042680000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
              {
                close: 18.1727,
                high: 18.1727,
                lag_ms: 3105,
                low: 18.1727,
                open: 18.1727,
                source: "Polygon_rest",
                start_ms: 1720042678000,
                timespan_ms: 1000,
                volume: 1.0,
                vwap: null,
                websocket: false,
              },
              {
                close: 18.1836,
                high: 18.1836,
                lag_ms: 362105,
                low: 18.1819,
                open: 18.1819,
                source: "TwelveData_rest",
                start_ms: 1720042260000,
                timespan_ms: 60000,
                volume: null,
                vwap: null,
                websocket: false,
              },
            ],
            processed_ms: 1720042682104,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "1cb5c3d6-2af6-4a3a-85c7-c39f71d2295a",
            price: 18.1726,
            price_sources: [
              {
                close: 18.1726,
                high: 18.1726,
                lag_ms: 131,
                low: 18.1726,
                open: 18.1726,
                source: "Polygon_ws",
                start_ms: 1720042720000,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 18.1724,
                websocket: true,
              },
              {
                close: 18.1722,
                high: 18.1722,
                lag_ms: 3870,
                low: 18.1722,
                open: 18.1722,
                source: "Polygon_rest",
                start_ms: 1720042715000,
                timespan_ms: 1000,
                volume: 1.0,
                vwap: null,
                websocket: false,
              },
              {
                close: 18.18250084,
                high: 18.18250084,
                lag_ms: 2104,
                low: 18.18250084,
                open: 18.18250084,
                source: "TwelveData_ws",
                start_ms: 1720042680000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
              {
                close: 18.1836,
                high: 18.1836,
                lag_ms: 399870,
                low: 18.1819,
                open: 18.1819,
                source: "TwelveData_rest",
                start_ms: 1720042260000,
                timespan_ms: 60000,
                volume: null,
                vwap: null,
                websocket: false,
              },
            ],
            processed_ms: 1720042719869,
          },
        ],
        position_type: "FLAT",
        position_uuid: "96551fb8-7ec2-4522-8e89-90d0e0984696",
        return_at_close: 0.9999914669153744,
        trade_pair: ["USDMXN", "USD/MXN", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 18.17195,
        close_ms: 1720042845874,
        current_return: 1.0000008254502832,
        initial_entry_price: 18.1719,
        is_closed_position: true,
        miner_hotkey: "5F7ThFe3ZRxTVFjz4M8ciTHbcVAdVDHLZ7SDyMgdFJpdLdEm",
        net_leverage: 0.0,
        open_ms: 1720042770726,
        orders: [
          {
            leverage: 0.05,
            order_type: "LONG",
            order_uuid: "d8064d10-eac7-4561-97be-4854d3aa75b1",
            price: 18.1719,
            price_sources: [
              {
                close: 18.1719,
                high: 18.1719,
                lag_ms: 1459,
                low: 18.1719,
                open: 18.1719,
                source: "Polygon_ws",
                start_ms: 1720042768999,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 18.1719,
                websocket: true,
              },
              {
                close: 18.18250084,
                high: 18.18250084,
                lag_ms: 90458,
                low: 18.18250084,
                open: 18.18250084,
                source: "TwelveData_ws",
                start_ms: 1720042680000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1720042770458,
          },
          {
            leverage: 0.05,
            order_type: "LONG",
            order_uuid: "394becff-fc0b-4dea-9c6b-6e2755ab3018",
            price: 18.172,
            price_sources: [
              {
                close: 18.172,
                high: 18.172,
                lag_ms: 19,
                low: 18.172,
                open: 18.172,
                source: "Polygon_ws",
                start_ms: 1720042801999,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 18.172,
                websocket: true,
              },
              {
                close: 18.18250084,
                high: 18.18250084,
                lag_ms: 90458,
                low: 18.18250084,
                open: 18.18250084,
                source: "TwelveData_ws",
                start_ms: 1720042680000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1720042801980,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "b3b3da09-7d60-4ae8-aab5-dd724368ebfb",
            price: 18.1721,
            price_sources: [
              {
                close: 18.1721,
                high: 18.1721,
                lag_ms: 3875,
                low: 18.1721,
                open: 18.1721,
                source: "Polygon_ws",
                start_ms: 1720042841999,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 18.1721,
                websocket: true,
              },
              {
                close: 18.1721,
                high: 18.1721,
                lag_ms: 3875,
                low: 18.1721,
                open: 18.1721,
                source: "Polygon_rest",
                start_ms: 1720042841000,
                timespan_ms: 1000,
                volume: 1.0,
                vwap: null,
                websocket: false,
              },
              {
                close: 18.1825,
                high: 18.1843,
                lag_ms: 105875,
                low: 18.1825,
                open: 18.1831,
                source: "TwelveData_rest",
                start_ms: 1720042680000,
                timespan_ms: 60000,
                volume: null,
                vwap: null,
                websocket: false,
              },
              {
                close: 18.18250084,
                high: 18.18250084,
                lag_ms: 165874,
                low: 18.18250084,
                open: 18.18250084,
                source: "TwelveData_ws",
                start_ms: 1720042680000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1720042845874,
          },
        ],
        position_type: "FLAT",
        position_uuid: "87cb56f6-de9c-47f7-8ce6-1316bba50021",
        return_at_close: 0.9999938254445051,
        trade_pair: ["USDMXN", "USD/MXN", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 18.172,
        close_ms: 1720043071807,
        current_return: 1.0,
        initial_entry_price: 18.172,
        is_closed_position: true,
        miner_hotkey: "5F7ThFe3ZRxTVFjz4M8ciTHbcVAdVDHLZ7SDyMgdFJpdLdEm",
        net_leverage: 0.0,
        open_ms: 1720043044088,
        orders: [
          {
            leverage: 0.05,
            order_type: "LONG",
            order_uuid: "32cf6b78-9215-4bb6-88aa-e8c4da0eefc5",
            price: 18.172,
            price_sources: [
              {
                close: 18.172,
                high: 18.172,
                lag_ms: 785,
                low: 18.172,
                open: 18.172,
                source: "Polygon_ws",
                start_ms: 1720043042999,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 18.172,
                websocket: true,
              },
              {
                close: 18.18250084,
                high: 18.18250084,
                lag_ms: 363784,
                low: 18.18250084,
                open: 18.18250084,
                source: "TwelveData_ws",
                start_ms: 1720042680000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1720043043784,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "40400fa2-46ce-47a7-ba40-ec6c3bd434ae",
            price: 18.172,
            price_sources: [
              {
                close: 18.172,
                high: 18.172,
                lag_ms: 4808,
                low: 18.172,
                open: 18.172,
                source: "Polygon_ws",
                start_ms: 1720043066999,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 18.172,
                websocket: true,
              },
              {
                close: 18.172,
                high: 18.172,
                lag_ms: 4808,
                low: 18.172,
                open: 18.172,
                source: "Polygon_rest",
                start_ms: 1720043066000,
                timespan_ms: 1000,
                volume: 1.0,
                vwap: null,
                websocket: false,
              },
              {
                close: 18.1827,
                high: 18.1827,
                lag_ms: 88193,
                low: 18.1827,
                open: 18.1827,
                source: "TwelveData_rest",
                start_ms: 1720043160000,
                timespan_ms: 60000,
                volume: null,
                vwap: null,
                websocket: false,
              },
              {
                close: 18.18250084,
                high: 18.18250084,
                lag_ms: 391807,
                low: 18.18250084,
                open: 18.18250084,
                source: "TwelveData_ws",
                start_ms: 1720042680000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1720043071807,
          },
        ],
        position_type: "FLAT",
        position_uuid: "7d1fa9c7-84f7-4bfc-bbef-12cc2721f00a",
        return_at_close: 0.9999965,
        trade_pair: ["USDMXN", "USD/MXN", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 18.1719,
        close_ms: 1720043532130,
        current_return: 0.9999966982170372,
        initial_entry_price: 18.172,
        is_closed_position: true,
        miner_hotkey: "5F7ThFe3ZRxTVFjz4M8ciTHbcVAdVDHLZ7SDyMgdFJpdLdEm",
        net_leverage: 0.0,
        open_ms: 1720043102000,
        orders: [
          {
            leverage: 0.05,
            order_type: "LONG",
            order_uuid: "5994ffa5-6606-4be2-879f-4eec05caa1f0",
            price: 18.172,
            price_sources: [
              {
                close: 18.172,
                high: 18.172,
                lag_ms: 128,
                low: 18.172,
                open: 18.172,
                source: "Polygon_ws",
                start_ms: 1720043101999,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 18.172,
                websocket: true,
              },
              {
                close: 18.18250084,
                high: 18.18250084,
                lag_ms: 421871,
                low: 18.18250084,
                open: 18.18250084,
                source: "TwelveData_ws",
                start_ms: 1720042680000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1720043101871,
          },
          {
            leverage: 0.05,
            order_type: "LONG",
            order_uuid: "5d2f110f-709c-4959-a181-6f77eb351a4d",
            price: 18.1718,
            price_sources: [
              {
                close: 18.1718,
                high: 18.1718,
                lag_ms: 1531,
                low: 18.1718,
                open: 18.1718,
                source: "Polygon_ws",
                start_ms: 1720043498999,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 18.1718,
                websocket: true,
              },
              {
                close: 18.18250084,
                high: 18.18250084,
                lag_ms: 820530,
                low: 18.18250084,
                open: 18.18250084,
                source: "TwelveData_ws",
                start_ms: 1720042680000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1720043500530,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "466123ca-cf2f-43a3-b57c-54d84ef9a6ec",
            price: 18.1713,
            price_sources: [
              {
                close: 18.1713,
                high: 18.1713,
                lag_ms: 130,
                low: 18.1713,
                open: 18.1713,
                source: "Polygon_ws",
                start_ms: 1720043532000,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 18.1713,
                websocket: true,
              },
              {
                close: 18.1715,
                high: 18.1715,
                lag_ms: 4131,
                low: 18.1715,
                open: 18.1715,
                source: "Polygon_rest",
                start_ms: 1720043527000,
                timespan_ms: 1000,
                volume: 1.0,
                vwap: null,
                websocket: false,
              },
              {
                close: 18.1827,
                high: 18.1827,
                lag_ms: 312131,
                low: 18.1827,
                open: 18.1827,
                source: "TwelveData_rest",
                start_ms: 1720043160000,
                timespan_ms: 60000,
                volume: null,
                vwap: null,
                websocket: false,
              },
              {
                close: 18.18250084,
                high: 18.18250084,
                lag_ms: 852130,
                low: 18.18250084,
                open: 18.18250084,
                source: "TwelveData_ws",
                start_ms: 1720042680000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1720043532130,
          },
        ],
        position_type: "FLAT",
        position_uuid: "1fa2a744-5d44-48dd-a720-ca9752ed31e7",
        return_at_close: 0.9999896982401497,
        trade_pair: ["USDMXN", "USD/MXN", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 18.171349999999997,
        close_ms: 1720043679804,
        current_return: 0.9999997248408204,
        initial_entry_price: 18.1713,
        is_closed_position: true,
        miner_hotkey: "5F7ThFe3ZRxTVFjz4M8ciTHbcVAdVDHLZ7SDyMgdFJpdLdEm",
        net_leverage: 0.0,
        open_ms: 1720043566050,
        orders: [
          {
            leverage: 0.05,
            order_type: "LONG",
            order_uuid: "290203cd-7c12-4563-bc38-ba3cabcc5c52",
            price: 18.1713,
            price_sources: [
              {
                close: 18.1713,
                high: 18.1713,
                lag_ms: 119,
                low: 18.1713,
                open: 18.1713,
                source: "Polygon_ws",
                start_ms: 1720043566000,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 18.1713,
                websocket: true,
              },
              {
                close: 18.18250084,
                high: 18.18250084,
                lag_ms: 885881,
                low: 18.18250084,
                open: 18.18250084,
                source: "TwelveData_ws",
                start_ms: 1720042680000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1720043565881,
          },
          {
            leverage: 0.05,
            order_type: "LONG",
            order_uuid: "b1fecbd9-153c-4066-8d34-2785d899774e",
            price: 18.1714,
            price_sources: [
              {
                close: 18.1714,
                high: 18.1714,
                lag_ms: 1755,
                low: 18.1714,
                open: 18.1714,
                source: "Polygon_ws",
                start_ms: 1720043631999,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 18.1714,
                websocket: true,
              },
              {
                close: 18.18250084,
                high: 18.18250084,
                lag_ms: 953754,
                low: 18.18250084,
                open: 18.18250084,
                source: "TwelveData_ws",
                start_ms: 1720042680000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1720043633754,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "8fd1c19c-fcc4-4243-8619-872b08a79a2f",
            price: 18.1713,
            price_sources: [
              {
                close: 18.1713,
                high: 18.1713,
                lag_ms: 3196,
                low: 18.1713,
                open: 18.1713,
                source: "Polygon_ws",
                start_ms: 1720043683000,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 18.1713,
                websocket: true,
              },
              {
                close: 18.1714,
                high: 18.1714,
                lag_ms: 3805,
                low: 18.1714,
                open: 18.1714,
                source: "Polygon_rest",
                start_ms: 1720043675000,
                timespan_ms: 1000,
                volume: 1.0,
                vwap: null,
                websocket: false,
              },
              {
                close: 18.1835,
                high: 18.1839,
                lag_ms: 99805,
                low: 18.1825,
                open: 18.1839,
                source: "TwelveData_rest",
                start_ms: 1720043520000,
                timespan_ms: 60000,
                volume: null,
                vwap: null,
                websocket: false,
              },
              {
                close: 18.18250084,
                high: 18.18250084,
                lag_ms: 999804,
                low: 18.18250084,
                open: 18.18250084,
                source: "TwelveData_ws",
                start_ms: 1720042680000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1720043679804,
          },
        ],
        position_type: "FLAT",
        position_uuid: "c70e67de-2211-4abc-a5b4-5c4f4fd4acff",
        return_at_close: 0.9999927248427466,
        trade_pair: ["USDMXN", "USD/MXN", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 18.17285714285714,
        close_ms: 1720043798900,
        current_return: 0.9999962030958526,
        initial_entry_price: 18.1727,
        is_closed_position: true,
        miner_hotkey: "5F7ThFe3ZRxTVFjz4M8ciTHbcVAdVDHLZ7SDyMgdFJpdLdEm",
        net_leverage: 0.0,
        open_ms: 1720043718113,
        orders: [
          {
            leverage: 0.05,
            order_type: "LONG",
            order_uuid: "767166fd-d50e-420f-b475-e14c45a1f466",
            price: 18.1727,
            price_sources: [
              {
                close: 18.1727,
                high: 18.1727,
                lag_ms: 810,
                low: 18.1727,
                open: 18.1727,
                source: "Polygon_ws",
                start_ms: 1720043716999,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 18.1727,
                websocket: true,
              },
              {
                close: 18.18250084,
                high: 18.18250084,
                lag_ms: 1037809,
                low: 18.18250084,
                open: 18.18250084,
                source: "TwelveData_ws",
                start_ms: 1720042680000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1720043717809,
          },
          {
            leverage: 0.05500000000000001,
            order_type: "LONG",
            order_uuid: "a367bfa3-df84-45ed-b049-87d1f293ecfb",
            price: 18.173,
            price_sources: [
              {
                close: 18.173,
                high: 18.173,
                lag_ms: 79,
                low: 18.173,
                open: 18.173,
                source: "Polygon_ws",
                start_ms: 1720043761999,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 18.173,
                websocket: true,
              },
              {
                close: 18.18250084,
                high: 18.18250084,
                lag_ms: 1081920,
                low: 18.18250084,
                open: 18.18250084,
                source: "TwelveData_ws",
                start_ms: 1720042680000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1720043761920,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "900548e8-6d2d-4c88-99ea-4dc4e9bad6b6",
            price: 18.1722,
            price_sources: [
              {
                close: 18.1722,
                high: 18.1722,
                lag_ms: 99,
                low: 18.1722,
                open: 18.1722,
                source: "Polygon_ws",
                start_ms: 1720043798999,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 18.1722,
                websocket: true,
              },
              {
                close: 18.17021,
                high: 18.17021,
                lag_ms: 205100,
                low: 18.17021,
                open: 18.17021,
                source: "TwelveData_ws",
                start_ms: 1720044004000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1720043798900,
          },
        ],
        position_type: "FLAT",
        position_uuid: "5d19bf35-2e5e-464e-bcd2-4c551a121600",
        return_at_close: 0.9999888531237598,
        trade_pair: ["USDMXN", "USD/MXN", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 18.16957,
        close_ms: 1720044033710,
        current_return: 0.9999852225451675,
        initial_entry_price: 18.16957,
        is_closed_position: true,
        miner_hotkey: "5F7ThFe3ZRxTVFjz4M8ciTHbcVAdVDHLZ7SDyMgdFJpdLdEm",
        net_leverage: 0.0,
        open_ms: 1720044004113,
        orders: [
          {
            leverage: 0.05,
            order_type: "LONG",
            order_uuid: "38432b4a-7e4d-43fb-b382-8457f4545e4e",
            price: 18.16957,
            price_sources: [
              {
                close: 18.16957,
                high: 18.16957,
                lag_ms: 112,
                low: 18.16957,
                open: 18.16957,
                source: "Polygon_ws",
                start_ms: 1720044003999,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 18.16957,
                websocket: true,
              },
              {
                close: 18.17021,
                high: 18.17021,
                lag_ms: 113,
                low: 18.17021,
                open: 18.17021,
                source: "TwelveData_ws",
                start_ms: 1720044004000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1720044003887,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "f3b03c3f-27a1-4d42-b8d5-2399e9613491",
            price: 18.1642,
            price_sources: [
              {
                close: 18.1642,
                high: 18.1642,
                lag_ms: 711,
                low: 18.1642,
                open: 18.1642,
                source: "Polygon_ws",
                start_ms: 1720044032999,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 18.1642,
                websocket: true,
              },
              {
                close: 18.1577,
                high: 18.1577,
                lag_ms: 3290,
                low: 18.1577,
                open: 18.1577,
                source: "TwelveData_ws",
                start_ms: 1720044037000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1720044033710,
          },
        ],
        position_type: "FLAT",
        position_uuid: "5a0ab529-7fa5-4d8b-9267-a02070e27bd8",
        return_at_close: 0.9999817225968886,
        trade_pair: ["USDMXN", "USD/MXN", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 18.1685,
        close_ms: 1720048162935,
        current_return: 1.0000415279192008,
        initial_entry_price: 18.1685,
        is_closed_position: true,
        miner_hotkey: "5F7ThFe3ZRxTVFjz4M8ciTHbcVAdVDHLZ7SDyMgdFJpdLdEm",
        net_leverage: 0.0,
        open_ms: 1720044066070,
        orders: [
          {
            leverage: 0.05,
            order_type: "LONG",
            order_uuid: "c88d234d-23ea-48d0-b4e9-39d6356ac72a",
            price: 18.1685,
            price_sources: [
              {
                close: 18.1685,
                high: 18.1685,
                lag_ms: 57,
                low: 18.1685,
                open: 18.1685,
                source: "Polygon_ws",
                start_ms: 1720044065999,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 18.1685,
                websocket: true,
              },
              {
                close: 18.16951,
                high: 18.16951,
                lag_ms: 2942,
                low: 18.16951,
                open: 18.16951,
                source: "TwelveData_ws",
                start_ms: 1720044063000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1720044065942,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "2362d109-e46e-4e52-9770-173acc976957",
            price: 18.18359,
            price_sources: [
              {
                close: 18.18359,
                high: 18.18359,
                lag_ms: 64,
                low: 18.18359,
                open: 18.18359,
                source: "Polygon_ws",
                start_ms: 1720048162999,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 18.18359,
                websocket: true,
              },
              {
                close: 18.179,
                high: 18.179,
                lag_ms: 1935,
                low: 18.179,
                open: 18.179,
                source: "TwelveData_ws",
                start_ms: 1720048161000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1720048162935,
          },
        ],
        position_type: "FLAT",
        position_uuid: "fc18b885-98b4-49e5-95a5-0d47dc67d7e9",
        return_at_close: 1.000038027773853,
        trade_pair: ["USDMXN", "USD/MXN", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 18.149950216450215,
        close_ms: 1720051658437,
        current_return: 0.9999658832400871,
        initial_entry_price: 18.18124,
        is_closed_position: true,
        miner_hotkey: "5F7ThFe3ZRxTVFjz4M8ciTHbcVAdVDHLZ7SDyMgdFJpdLdEm",
        net_leverage: 0.0,
        open_ms: 1720048198792,
        orders: [
          {
            leverage: 0.05,
            order_type: "LONG",
            order_uuid: "d6317d24-3bcd-4ef8-9fc3-8d887f14a251",
            price: 18.18124,
            price_sources: [
              {
                close: 18.18124,
                high: 18.18124,
                lag_ms: 364,
                low: 18.18124,
                open: 18.18124,
                source: "Polygon_ws",
                start_ms: 1720048199000,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 18.1788,
                websocket: true,
              },
              {
                close: 18.1788,
                high: 18.1788,
                lag_ms: 364,
                low: 18.1788,
                open: 18.1788,
                source: "TwelveData_ws",
                start_ms: 1720048199000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1720048198636,
          },
          {
            leverage: 0.06,
            order_type: "LONG",
            order_uuid: "edbcd6d0-a22c-451b-8eb0-d15eff9b6873",
            price: 18.16189,
            price_sources: [
              {
                close: 18.16189,
                high: 18.16189,
                lag_ms: 26,
                low: 18.16189,
                open: 18.16189,
                source: "Polygon_ws",
                start_ms: 1720051343999,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 18.16189,
                websocket: true,
              },
              {
                close: 18.155,
                high: 18.155,
                lag_ms: 27,
                low: 18.155,
                open: 18.155,
                source: "TwelveData_ws",
                start_ms: 1720051344000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1720051343973,
          },
          {
            leverage: 0.12100000000000001,
            order_type: "LONG",
            order_uuid: "8a61b3d2-e02e-4743-bf24-f2d80972e069",
            price: 18.1311,
            price_sources: [
              {
                close: 18.1311,
                high: 18.1311,
                lag_ms: 778,
                low: 18.1311,
                open: 18.1311,
                source: "Polygon_ws",
                start_ms: 1720051499000,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 18.1311,
                websocket: true,
              },
              {
                close: 18.139,
                high: 18.139,
                lag_ms: 778,
                low: 18.139,
                open: 18.139,
                source: "TwelveData_ws",
                start_ms: 1720051499000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
              {
                close: 18.1304,
                high: 18.1304,
                lag_ms: 3223,
                low: 18.1304,
                open: 18.1304,
                source: "Polygon_rest",
                start_ms: 1720051494000,
                timespan_ms: 1000,
                volume: 1.0,
                vwap: null,
                websocket: false,
              },
              {
                close: 18.173,
                high: 18.1775,
                lag_ms: 238223,
                low: 18.1701,
                open: 18.1704,
                source: "TwelveData_rest",
                start_ms: 1720051200000,
                timespan_ms: 60000,
                volume: null,
                vwap: null,
                websocket: false,
              },
            ],
            processed_ms: 1720051498222,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "957fa4dc-eab3-4518-b003-d7c0834d1255",
            price: 18.147265,
            price_sources: [
              {
                close: 18.147265,
                high: 18.147265,
                lag_ms: 437,
                low: 18.147265,
                open: 18.147265,
                source: "Polygon_ws",
                start_ms: 1720051658000,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 18.1441,
                websocket: true,
              },
              {
                close: 18.141,
                high: 18.141,
                lag_ms: 437,
                low: 18.141,
                open: 18.141,
                source: "TwelveData_ws",
                start_ms: 1720051658000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1720051658437,
          },
        ],
        position_type: "FLAT",
        position_uuid: "00c4da55-7125-49d2-a4f2-f5f9b877f578",
        return_at_close: 0.9999497137917551,
        trade_pair: ["USDMXN", "USD/MXN", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 18.1423,
        close_ms: 1720051780001,
        current_return: 1.0000303158915904,
        initial_entry_price: 18.1423,
        is_closed_position: true,
        miner_hotkey: "5F7ThFe3ZRxTVFjz4M8ciTHbcVAdVDHLZ7SDyMgdFJpdLdEm",
        net_leverage: 0.0,
        open_ms: 1720051695629,
        orders: [
          {
            leverage: 0.05,
            order_type: "LONG",
            order_uuid: "1b0328cf-27f7-42dc-9081-5ec21c131e7c",
            price: 18.1423,
            price_sources: [
              {
                close: 18.1423,
                high: 18.1423,
                lag_ms: 303,
                low: 18.1423,
                open: 18.1423,
                source: "Polygon_ws",
                start_ms: 1720051695000,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 18.1423,
                websocket: true,
              },
              {
                close: 18.149,
                high: 18.149,
                lag_ms: 303,
                low: 18.149,
                open: 18.149,
                source: "TwelveData_ws",
                start_ms: 1720051695000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
              {
                close: 18.1437,
                high: 18.1437,
                lag_ms: 3698,
                low: 18.1437,
                open: 18.1437,
                source: "Polygon_rest",
                start_ms: 1720051690000,
                timespan_ms: 1000,
                volume: 1.0,
                vwap: null,
                websocket: false,
              },
              {
                close: 18.173,
                high: 18.1775,
                lag_ms: 434698,
                low: 18.1701,
                open: 18.1704,
                source: "TwelveData_rest",
                start_ms: 1720051200000,
                timespan_ms: 60000,
                volume: null,
                vwap: null,
                websocket: false,
              },
            ],
            processed_ms: 1720051694697,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "c9a1b7f3-a3f2-488b-8399-03c65df4408b",
            price: 18.1533,
            price_sources: [
              {
                close: 18.1533,
                high: 18.1533,
                lag_ms: 1,
                low: 18.1533,
                open: 18.1533,
                source: "Polygon_ws",
                start_ms: 1720051780000,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 18.1533,
                websocket: true,
              },
              {
                close: 18.159,
                high: 18.159,
                lag_ms: 1999,
                low: 18.159,
                open: 18.159,
                source: "TwelveData_ws",
                start_ms: 1720051782000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1720051780001,
          },
        ],
        position_type: "FLAT",
        position_uuid: "92895d75-597a-492e-911c-714b74eed495",
        return_at_close: 1.0000268157854848,
        trade_pair: ["USDMXN", "USD/MXN", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 18.1556,
        close_ms: 1720052320818,
        current_return: 1.0000388998435745,
        initial_entry_price: 18.1556,
        is_closed_position: true,
        miner_hotkey: "5F7ThFe3ZRxTVFjz4M8ciTHbcVAdVDHLZ7SDyMgdFJpdLdEm",
        net_leverage: 0.0,
        open_ms: 1720051810701,
        orders: [
          {
            leverage: 0.05,
            order_type: "LONG",
            order_uuid: "5e8ab115-615a-4317-8091-e0718c44f604",
            price: 18.1556,
            price_sources: [
              {
                close: 18.1556,
                high: 18.1556,
                lag_ms: 364,
                low: 18.1556,
                open: 18.1556,
                source: "Polygon_ws",
                start_ms: 1720051810000,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 18.1556,
                websocket: true,
              },
              {
                close: 18.163,
                high: 18.163,
                lag_ms: 636,
                low: 18.163,
                open: 18.163,
                source: "TwelveData_ws",
                start_ms: 1720051811000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1720051810364,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "a628afec-ee71-4950-95f3-d4a150c5c1d4",
            price: 18.169725,
            price_sources: [
              {
                close: 18.169725,
                high: 18.169725,
                lag_ms: 181,
                low: 18.169725,
                open: 18.169725,
                source: "Polygon_ws",
                start_ms: 1720052320999,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 18.16838,
                websocket: true,
              },
              {
                close: 18.1705,
                high: 18.1705,
                lag_ms: 818,
                low: 18.1705,
                open: 18.1705,
                source: "TwelveData_ws",
                start_ms: 1720052320000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1720052320818,
          },
        ],
        position_type: "FLAT",
        position_uuid: "25657a44-c7b7-4c83-9540-27dbd5d34116",
        return_at_close: 1.000035399707425,
        trade_pair: ["USDMXN", "USD/MXN", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 18.16211227272727,
        close_ms: 1720053514951,
        current_return: 0.9999569303301916,
        initial_entry_price: 18.164755,
        is_closed_position: true,
        miner_hotkey: "5F7ThFe3ZRxTVFjz4M8ciTHbcVAdVDHLZ7SDyMgdFJpdLdEm",
        net_leverage: 0.0,
        open_ms: 1720052358715,
        orders: [
          {
            leverage: 0.05,
            order_type: "LONG",
            order_uuid: "118305b8-d362-4141-bd5a-26dda67e9684",
            price: 18.164755,
            price_sources: [
              {
                close: 18.164755,
                high: 18.164755,
                lag_ms: 220,
                low: 18.164755,
                open: 18.164755,
                source: "Polygon_ws",
                start_ms: 1720052358000,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 18.17021,
                websocket: true,
              },
              {
                close: 18.167,
                high: 18.167,
                lag_ms: 780,
                low: 18.167,
                open: 18.167,
                source: "TwelveData_ws",
                start_ms: 1720052357000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
              {
                close: 18.17177,
                high: 18.17177,
                lag_ms: 2781,
                low: 18.17177,
                open: 18.17177,
                source: "Polygon_rest",
                start_ms: 1720052354000,
                timespan_ms: 1000,
                volume: 1.0,
                vwap: null,
                websocket: false,
              },
              {
                close: 18.1714,
                high: 18.178,
                lag_ms: 377781,
                low: 18.1673,
                open: 18.1673,
                source: "TwelveData_rest",
                start_ms: 1720051920000,
                timespan_ms: 60000,
                volume: null,
                vwap: null,
                websocket: false,
              },
            ],
            processed_ms: 1720052357780,
          },
          {
            leverage: 0.06,
            order_type: "LONG",
            order_uuid: "aa8cc7ab-9316-48cf-b353-56f097c91b63",
            price: 18.15991,
            price_sources: [
              {
                close: 18.15991,
                high: 18.15991,
                lag_ms: 375,
                low: 18.15991,
                open: 18.15991,
                source: "Polygon_ws",
                start_ms: 1720053333999,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 18.15991,
                websocket: true,
              },
              {
                close: 18.154,
                high: 18.154,
                lag_ms: 376,
                low: 18.154,
                open: 18.154,
                source: "TwelveData_ws",
                start_ms: 1720053334000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1720053333624,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "be01d589-2dde-410b-8efa-dccaf69f34f5",
            price: 18.155,
            price_sources: [
              {
                close: 18.155,
                high: 18.155,
                lag_ms: 49,
                low: 18.155,
                open: 18.155,
                source: "TwelveData_ws",
                start_ms: 1720053515000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
              {
                close: 18.1501,
                high: 18.1501,
                lag_ms: 952,
                low: 18.1501,
                open: 18.1501,
                source: "Polygon_ws",
                start_ms: 1720053513999,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 18.1501,
                websocket: true,
              },
            ],
            processed_ms: 1720053514951,
          },
        ],
        position_type: "FLAT",
        position_uuid: "733da8ce-913d-4c0c-9250-d69499278e61",
        return_at_close: 0.9999492306618281,
        trade_pair: ["USDMXN", "USD/MXN", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 18.135930735930735,
        close_ms: 1720076121512,
        current_return: 1.0000306469820537,
        initial_entry_price: 18.1597,
        is_closed_position: true,
        miner_hotkey: "5F7ThFe3ZRxTVFjz4M8ciTHbcVAdVDHLZ7SDyMgdFJpdLdEm",
        net_leverage: 0.0,
        open_ms: 1720053545320,
        orders: [
          {
            leverage: 0.05,
            order_type: "LONG",
            order_uuid: "51d84797-2fe2-4722-b4cb-86d0488ef292",
            price: 18.1597,
            price_sources: [
              {
                close: 18.1597,
                high: 18.1597,
                lag_ms: 924,
                low: 18.1597,
                open: 18.1597,
                source: "TwelveData_ws",
                start_ms: 1720053546000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
              {
                close: 18.1484,
                high: 18.1484,
                lag_ms: 1077,
                low: 18.1484,
                open: 18.1484,
                source: "Polygon_ws",
                start_ms: 1720053543999,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 18.1484,
                websocket: true,
              },
            ],
            processed_ms: 1720053545076,
          },
          {
            leverage: 0.05500000000000001,
            order_type: "LONG",
            order_uuid: "b0c89b58-22c5-49c8-8d72-ff2baa106532",
            price: 18.143430000000002,
            price_sources: [
              {
                close: 18.143430000000002,
                high: 18.143430000000002,
                lag_ms: 243,
                low: 18.143430000000002,
                open: 18.143430000000002,
                source: "Polygon_ws",
                start_ms: 1720073861000,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 18.14996,
                websocket: true,
              },
              {
                close: 18.1461,
                high: 18.1461,
                lag_ms: 243,
                low: 18.1461,
                open: 18.1461,
                source: "TwelveData_ws",
                start_ms: 1720073861000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1720073861243,
          },
          {
            leverage: 0.126,
            order_type: "LONG",
            order_uuid: "1d8a1518-4103-4b7a-9ac8-c562fcea5f01",
            price: 18.123224999999998,
            price_sources: [
              {
                close: 18.123224999999998,
                high: 18.123224999999998,
                lag_ms: 468,
                low: 18.123224999999998,
                open: 18.123224999999998,
                source: "Polygon_ws",
                start_ms: 1720076012000,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 18.12925,
                websocket: true,
              },
              {
                close: 18.12827,
                high: 18.12827,
                lag_ms: 532,
                low: 18.12827,
                open: 18.12827,
                source: "TwelveData_ws",
                start_ms: 1720076013000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1720076012468,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "914c90ef-3b93-4823-902d-246b7c845dfa",
            price: 18.13834,
            price_sources: [
              {
                close: 18.13834,
                high: 18.13834,
                lag_ms: 487,
                low: 18.13834,
                open: 18.13834,
                source: "Polygon_ws",
                start_ms: 1720076121999,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 18.13834,
                websocket: true,
              },
              {
                close: 18.13843,
                high: 18.13843,
                lag_ms: 488,
                low: 18.13843,
                open: 18.13843,
                source: "TwelveData_ws",
                start_ms: 1720076122000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1720076121512,
          },
        ],
        position_type: "FLAT",
        position_uuid: "4ea74d63-c37d-42e2-b2bc-7f8d1241d20c",
        return_at_close: 1.000014476486492,
        trade_pair: ["USDMXN", "USD/MXN", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 18.13204818181818,
        close_ms: 1720076705809,
        current_return: 1.0000151117114386,
        initial_entry_price: 18.13825,
        is_closed_position: true,
        miner_hotkey: "5F7ThFe3ZRxTVFjz4M8ciTHbcVAdVDHLZ7SDyMgdFJpdLdEm",
        net_leverage: 0.0,
        open_ms: 1720076152844,
        orders: [
          {
            leverage: 0.05,
            order_type: "LONG",
            order_uuid: "b56d6cd2-af20-4c67-b7b4-d38a62a212fd",
            price: 18.13825,
            price_sources: [
              {
                close: 18.13825,
                high: 18.13825,
                lag_ms: 373,
                low: 18.13825,
                open: 18.13825,
                source: "Polygon_ws",
                start_ms: 1720076152000,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 18.13825,
                websocket: true,
              },
              {
                close: 18.13804,
                high: 18.13804,
                lag_ms: 373,
                low: 18.13804,
                open: 18.13804,
                source: "TwelveData_ws",
                start_ms: 1720076152000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1720076152373,
          },
          {
            leverage: 0.06,
            order_type: "LONG",
            order_uuid: "ef525f14-fd90-4be6-9508-7bf1fe1f95af",
            price: 18.12688,
            price_sources: [
              {
                close: 18.12688,
                high: 18.12688,
                lag_ms: 468,
                low: 18.12688,
                open: 18.12688,
                source: "Polygon_ws",
                start_ms: 1720076632000,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 18.12688,
                websocket: true,
              },
              {
                close: 18.12645,
                high: 18.12645,
                lag_ms: 468,
                low: 18.12645,
                open: 18.12645,
                source: "TwelveData_ws",
                start_ms: 1720076632000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1720076632468,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "82c600c0-0ff5-42f8-9bf3-f9e7902df457",
            price: 18.13454,
            price_sources: [
              {
                close: 18.13454,
                high: 18.13454,
                lag_ms: 190,
                low: 18.13454,
                open: 18.13454,
                source: "Polygon_ws",
                start_ms: 1720076705999,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 18.13454,
                websocket: true,
              },
              {
                close: 18.13368,
                high: 18.13368,
                lag_ms: 1191,
                low: 18.13368,
                open: 18.13368,
                source: "TwelveData_ws",
                start_ms: 1720076707000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1720076705809,
          },
        ],
        position_type: "FLAT",
        position_uuid: "50c3cb42-493b-4bc3-9d67-07114a804fea",
        return_at_close: 1.0000074115950783,
        trade_pair: ["USDMXN", "USD/MXN", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 18.12235,
        close_ms: 1720080527516,
        current_return: 1.0000256506437484,
        initial_entry_price: 18.1282,
        is_closed_position: true,
        miner_hotkey: "5F7ThFe3ZRxTVFjz4M8ciTHbcVAdVDHLZ7SDyMgdFJpdLdEm",
        net_leverage: 0.0,
        open_ms: 1720076735428,
        orders: [
          {
            leverage: 0.05,
            order_type: "LONG",
            order_uuid: "01c23460-24d8-4c7f-b2ae-22975af1e060",
            price: 18.1282,
            price_sources: [
              {
                close: 18.1282,
                high: 18.1282,
                lag_ms: 132,
                low: 18.1282,
                open: 18.1282,
                source: "Polygon_ws",
                start_ms: 1720076735000,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 18.1282,
                websocket: true,
              },
              {
                close: 18.12734,
                high: 18.12734,
                lag_ms: 132,
                low: 18.12734,
                open: 18.12734,
                source: "TwelveData_ws",
                start_ms: 1720076735000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1720076735132,
          },
          {
            leverage: 0.05,
            order_type: "LONG",
            order_uuid: "aa332f5c-d17c-4b3b-8576-ad8c02fb0c3d",
            price: 18.1165,
            price_sources: [
              {
                close: 18.1165,
                high: 18.1165,
                lag_ms: 212,
                low: 18.1165,
                open: 18.1165,
                source: "Polygon_ws",
                start_ms: 1720079884000,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 18.1165,
                websocket: true,
              },
              {
                close: 18.1142,
                high: 18.1142,
                lag_ms: 212,
                low: 18.1142,
                open: 18.1142,
                source: "TwelveData_ws",
                start_ms: 1720079884000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1720079884212,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "2e94b267-c629-4ed3-97dc-f87a093c57c9",
            price: 18.127,
            price_sources: [
              {
                close: 18.127,
                high: 18.127,
                lag_ms: 484,
                low: 18.127,
                open: 18.127,
                source: "Polygon_ws",
                start_ms: 1720080528000,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 18.127,
                websocket: true,
              },
              {
                close: 18.129,
                high: 18.129,
                lag_ms: 484,
                low: 18.129,
                open: 18.129,
                source: "TwelveData_ws",
                start_ms: 1720080528000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1720080527516,
          },
        ],
        position_type: "FLAT",
        position_uuid: "a3571c17-3551-4f4a-8e26-4a4a27689c50",
        return_at_close: 1.000018650464194,
        trade_pair: ["USDMXN", "USD/MXN", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 18.104149999999997,
        close_ms: 1720086341208,
        current_return: 0.999967157011564,
        initial_entry_price: 18.1165,
        is_closed_position: true,
        miner_hotkey: "5F7ThFe3ZRxTVFjz4M8ciTHbcVAdVDHLZ7SDyMgdFJpdLdEm",
        net_leverage: 0.0,
        open_ms: 1720080559406,
        orders: [
          {
            leverage: 0.05,
            order_type: "LONG",
            order_uuid: "6151d8a1-1be7-4a8d-8cf1-ffbf5180aedd",
            price: 18.1165,
            price_sources: [
              {
                close: 18.1165,
                high: 18.1165,
                lag_ms: 52,
                low: 18.1165,
                open: 18.1165,
                source: "Polygon_ws",
                start_ms: 1720080559000,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 18.1165,
                websocket: true,
              },
              {
                close: 18.122,
                high: 18.122,
                lag_ms: 4052,
                low: 18.122,
                open: 18.122,
                source: "TwelveData_ws",
                start_ms: 1720080563000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1720080558948,
          },
          {
            leverage: 0.05,
            order_type: "LONG",
            order_uuid: "526aef4a-6771-4eef-b76a-917d7aac5182",
            price: 18.0918,
            price_sources: [
              {
                close: 18.0918,
                high: 18.0918,
                lag_ms: 115,
                low: 18.0918,
                open: 18.0918,
                source: "Polygon_ws",
                start_ms: 1720086205000,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 18.0918,
                websocket: true,
              },
              {
                close: 18.099,
                high: 18.099,
                lag_ms: 115,
                low: 18.099,
                open: 18.099,
                source: "TwelveData_ws",
                start_ms: 1720086205000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
              {
                close: 18.1039,
                high: 18.1039,
                lag_ms: 2116,
                low: 18.1039,
                open: 18.1039,
                source: "Polygon_rest",
                start_ms: 1720086202000,
                timespan_ms: 1000,
                volume: 1.0,
                vwap: null,
                websocket: false,
              },
              {
                close: 18.11385,
                high: 18.11395,
                lag_ms: 25116,
                low: 18.1132,
                open: 18.11365,
                source: "TwelveData_rest",
                start_ms: 1720086120000,
                timespan_ms: 60000,
                volume: null,
                vwap: null,
                websocket: false,
              },
            ],
            processed_ms: 1720086205115,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "5550a8f3-c8c5-4e06-ae75-cabd932e38ce",
            price: 18.0982,
            price_sources: [
              {
                close: 18.0982,
                high: 18.0982,
                lag_ms: 208,
                low: 18.0982,
                open: 18.0982,
                source: "Polygon_ws",
                start_ms: 1720086341000,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 18.0982,
                websocket: true,
              },
              {
                close: 18.1075,
                high: 18.1075,
                lag_ms: 208,
                low: 18.1075,
                open: 18.1075,
                source: "TwelveData_ws",
                start_ms: 1720086341000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
              {
                close: 18.11075,
                high: 18.11075,
                lag_ms: 3209,
                low: 18.11075,
                open: 18.11075,
                source: "Polygon_rest",
                start_ms: 1720086337000,
                timespan_ms: 1000,
                volume: 1.0,
                vwap: null,
                websocket: false,
              },
              {
                close: 18.1093,
                high: 18.11015,
                lag_ms: 41209,
                low: 18.109,
                open: 18.1091,
                source: "TwelveData_rest",
                start_ms: 1720086240000,
                timespan_ms: 60000,
                volume: null,
                vwap: null,
                websocket: false,
              },
            ],
            processed_ms: 1720086341208,
          },
        ],
        position_type: "FLAT",
        position_uuid: "7ad1bd97-8018-4e19-8a46-ff9a030fb86c",
        return_at_close: 0.9999601572414649,
        trade_pair: ["USDMXN", "USD/MXN", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 18.1092,
        close_ms: 1720091818273,
        current_return: 1.0000408632076514,
        initial_entry_price: 18.1092,
        is_closed_position: true,
        miner_hotkey: "5F7ThFe3ZRxTVFjz4M8ciTHbcVAdVDHLZ7SDyMgdFJpdLdEm",
        net_leverage: 0.0,
        open_ms: 1720086375442,
        orders: [
          {
            leverage: 0.05,
            order_type: "LONG",
            order_uuid: "d5f24d90-d6df-491b-97de-5ec0f491db0e",
            price: 18.1092,
            price_sources: [
              {
                close: 18.1092,
                high: 18.1092,
                lag_ms: 407,
                low: 18.1092,
                open: 18.1092,
                source: "Polygon_ws",
                start_ms: 1720086374999,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 18.1092,
                websocket: true,
              },
              {
                close: 18.10936,
                high: 18.10936,
                lag_ms: 408,
                low: 18.10936,
                open: 18.10936,
                source: "TwelveData_ws",
                start_ms: 1720086375000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1720086374592,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "1fb89807-3daf-4eed-9776-a30a8b1a93f7",
            price: 18.124,
            price_sources: [
              {
                close: 18.124,
                high: 18.124,
                lag_ms: 273,
                low: 18.124,
                open: 18.124,
                source: "TwelveData_ws",
                start_ms: 1720091818000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
              {
                close: 18.126855,
                high: 18.126855,
                lag_ms: 727,
                low: 18.126855,
                open: 18.126855,
                source: "Polygon_ws",
                start_ms: 1720091819000,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 18.12661,
                websocket: true,
              },
              {
                close: 18.12727,
                high: 18.12727,
                lag_ms: 3274,
                low: 18.12727,
                open: 18.12727,
                source: "Polygon_rest",
                start_ms: 1720091814000,
                timespan_ms: 1000,
                volume: 1.0,
                vwap: null,
                websocket: false,
              },
              {
                close: 18.1257,
                high: 18.1264,
                lag_ms: 118274,
                low: 18.12485,
                open: 18.12505,
                source: "TwelveData_rest",
                start_ms: 1720091640000,
                timespan_ms: 60000,
                volume: null,
                vwap: null,
                websocket: false,
              },
            ],
            processed_ms: 1720091818273,
          },
        ],
        position_type: "FLAT",
        position_uuid: "103efa98-b8e0-4cdc-b4d8-eccad2f41a48",
        return_at_close: 1.0000373630646302,
        trade_pair: ["USDMXN", "USD/MXN", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 18.12758,
        close_ms: 1720092017986,
        current_return: 1.0000006343924561,
        initial_entry_price: 18.12758,
        is_closed_position: true,
        miner_hotkey: "5F7ThFe3ZRxTVFjz4M8ciTHbcVAdVDHLZ7SDyMgdFJpdLdEm",
        net_leverage: 0.0,
        open_ms: 1720091849629,
        orders: [
          {
            leverage: 0.05,
            order_type: "LONG",
            order_uuid: "8127664e-ccd7-435e-9d14-7f14e681f71a",
            price: 18.12758,
            price_sources: [
              {
                close: 18.12758,
                high: 18.12758,
                lag_ms: 399,
                low: 18.12758,
                open: 18.12758,
                source: "Polygon_ws",
                start_ms: 1720091849999,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 18.12758,
                websocket: true,
              },
              {
                close: 18.1239,
                high: 18.1239,
                lag_ms: 400,
                low: 18.1239,
                open: 18.1239,
                source: "TwelveData_ws",
                start_ms: 1720091850000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1720091849600,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "3a4f89e6-6f6d-43d6-866f-7a7bd638371f",
            price: 18.12781,
            price_sources: [
              {
                close: 18.12781,
                high: 18.12781,
                lag_ms: 14,
                low: 18.12781,
                open: 18.12781,
                source: "Polygon_ws",
                start_ms: 1720092018000,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 18.12781,
                websocket: true,
              },
              {
                close: 18.12781,
                high: 18.12781,
                lag_ms: 14,
                low: 18.12781,
                open: 18.12781,
                source: "TwelveData_ws",
                start_ms: 1720092018000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1720092017986,
          },
        ],
        position_type: "FLAT",
        position_uuid: "dbaf4300-7513-4d7d-b26b-82f066bb01d1",
        return_at_close: 0.9999971343902357,
        trade_pair: ["USDMXN", "USD/MXN", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 18.09064074074074,
        close_ms: 1720107757991,
        current_return: 0.9999451761886584,
        initial_entry_price: 18.12716,
        is_closed_position: true,
        miner_hotkey: "5F7ThFe3ZRxTVFjz4M8ciTHbcVAdVDHLZ7SDyMgdFJpdLdEm",
        net_leverage: 0.0,
        open_ms: 1720092048026,
        orders: [
          {
            leverage: 0.05,
            order_type: "LONG",
            order_uuid: "74cfba4b-61de-4512-acb1-4f53d1f16f49",
            price: 18.12716,
            price_sources: [
              {
                close: 18.12716,
                high: 18.12716,
                lag_ms: 7,
                low: 18.12716,
                open: 18.12716,
                source: "Polygon_ws",
                start_ms: 1720092048000,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 18.12716,
                websocket: true,
              },
              {
                close: 18.12716,
                high: 18.12716,
                lag_ms: 7,
                low: 18.12716,
                open: 18.12716,
                source: "TwelveData_ws",
                start_ms: 1720092048000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1720092048007,
          },
          {
            leverage: 0.05,
            order_type: "LONG",
            order_uuid: "5123019c-9a0d-4bff-b455-bc217533678f",
            price: 18.1134,
            price_sources: [
              {
                close: 18.1134,
                high: 18.1134,
                lag_ms: 356,
                low: 18.1134,
                open: 18.1134,
                source: "Polygon_ws",
                start_ms: 1720093319000,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 18.1134,
                websocket: true,
              },
              {
                close: 18.12472,
                high: 18.12472,
                lag_ms: 356,
                low: 18.12472,
                open: 18.12472,
                source: "TwelveData_ws",
                start_ms: 1720093319000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1720093319356,
          },
          {
            leverage: 0.05,
            order_type: "LONG",
            order_uuid: "69830cf6-de9c-4336-9861-d8b0e599ea2c",
            price: 18.09914,
            price_sources: [
              {
                close: 18.09914,
                high: 18.09914,
                lag_ms: 101,
                low: 18.09914,
                open: 18.09914,
                source: "Polygon_ws",
                start_ms: 1720095567000,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 18.09914,
                websocket: true,
              },
              {
                close: 18.09891,
                high: 18.09891,
                lag_ms: 899,
                low: 18.09891,
                open: 18.09891,
                source: "TwelveData_ws",
                start_ms: 1720095568000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1720095567101,
          },
          {
            leverage: 0.12,
            order_type: "LONG",
            order_uuid: "d369e39f-7b6a-4612-b1a1-6d9f25610b33",
            price: 18.0624,
            price_sources: [
              {
                close: 18.0624,
                high: 18.0624,
                lag_ms: 117,
                low: 18.0624,
                open: 18.0624,
                source: "Polygon_ws",
                start_ms: 1720105165999,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 18.0624,
                websocket: true,
              },
              {
                close: 18.0593,
                high: 18.0593,
                lag_ms: 118,
                low: 18.0593,
                open: 18.0593,
                source: "TwelveData_ws",
                start_ms: 1720105166000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1720105165882,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "38fa628b-75cf-4e95-aac6-fdb580b27339",
            price: 18.08696,
            price_sources: [
              {
                close: 18.08696,
                high: 18.08696,
                lag_ms: 9,
                low: 18.08696,
                open: 18.08696,
                source: "Polygon_ws",
                start_ms: 1720107758000,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 18.08696,
                websocket: true,
              },
              {
                close: 18.084,
                high: 18.084,
                lag_ms: 9,
                low: 18.084,
                open: 18.084,
                source: "TwelveData_ws",
                start_ms: 1720107758000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1720107757991,
          },
        ],
        position_type: "FLAT",
        position_uuid: "2d76983c-8355-4417-b036-3646fbc23117",
        return_at_close: 0.9999262772248284,
        trade_pair: ["USDMXN", "USD/MXN", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 18.042229999999996,
        close_ms: 1720183147778,
        current_return: 1.0000268738125204,
        initial_entry_price: 18.08452,
        is_closed_position: true,
        miner_hotkey: "5F7ThFe3ZRxTVFjz4M8ciTHbcVAdVDHLZ7SDyMgdFJpdLdEm",
        net_leverage: 0.0,
        open_ms: 1720107791938,
        orders: [
          {
            leverage: 0.05,
            order_type: "LONG",
            order_uuid: "4142d4db-b4e6-4bfe-92bd-72d52ab37c07",
            price: 18.08452,
            price_sources: [
              {
                close: 18.08452,
                high: 18.08452,
                lag_ms: 73,
                low: 18.08452,
                open: 18.08452,
                source: "Polygon_ws",
                start_ms: 1720107791999,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 18.08452,
                websocket: true,
              },
              {
                close: 18.08413,
                high: 18.08413,
                lag_ms: 74,
                low: 18.08413,
                open: 18.08413,
                source: "TwelveData_ws",
                start_ms: 1720107792000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1720107791926,
          },
          {
            leverage: 0.05,
            order_type: "LONG",
            order_uuid: "eb779326-b491-4699-b150-f89f956a180b",
            price: 18.046,
            price_sources: [
              {
                close: 18.046,
                high: 18.046,
                lag_ms: 293,
                low: 18.046,
                open: 18.046,
                source: "Polygon_ws",
                start_ms: 1720150740999,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 18.046,
                websocket: true,
              },
              {
                close: 18.058,
                high: 18.058,
                lag_ms: 25292,
                low: 18.058,
                open: 18.058,
                source: "TwelveData_ws",
                start_ms: 1720150716000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1720150741292,
          },
          {
            leverage: 0.1,
            order_type: "LONG",
            order_uuid: "e8bb3d58-71e8-4486-8afe-e6cc0864eda7",
            price: 18.0192,
            price_sources: [
              {
                close: 18.0192,
                high: 18.0192,
                lag_ms: 316,
                low: 18.0192,
                open: 18.0192,
                source: "Polygon_ws",
                start_ms: 1720182651999,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 18.0192,
                websocket: true,
              },
              {
                close: 18.02002,
                high: 18.02002,
                lag_ms: 317,
                low: 18.02002,
                open: 18.02002,
                source: "TwelveData_ws",
                start_ms: 1720182652000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1720182651683,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "a2300ada-d893-411a-b31e-3d3d82651e78",
            price: 18.04466,
            price_sources: [
              {
                close: 18.04466,
                high: 18.04466,
                lag_ms: 221,
                low: 18.04466,
                open: 18.04466,
                source: "Polygon_ws",
                start_ms: 1720183147999,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 18.04466,
                websocket: true,
              },
              {
                close: 18.038,
                high: 18.038,
                lag_ms: 222,
                low: 18.038,
                open: 18.038,
                source: "TwelveData_ws",
                start_ms: 1720183148000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1720183147778,
          },
        ],
        position_type: "FLAT",
        position_uuid: "9198b47d-e4a5-44ed-b653-de6d25a2d910",
        return_at_close: 1.0000128734362872,
        trade_pair: ["USDMXN", "USD/MXN", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 18.03506714285714,
        close_ms: 1720183759627,
        current_return: 1.0000751805546706,
        initial_entry_price: 18.04855,
        is_closed_position: true,
        miner_hotkey: "5F7ThFe3ZRxTVFjz4M8ciTHbcVAdVDHLZ7SDyMgdFJpdLdEm",
        net_leverage: 0.0,
        open_ms: 1720183183563,
        orders: [
          {
            leverage: 0.05,
            order_type: "LONG",
            order_uuid: "bcda410e-0fcb-4037-84e3-be8812bde1e8",
            price: 18.04855,
            price_sources: [
              {
                close: 18.04855,
                high: 18.04855,
                lag_ms: 38,
                low: 18.04855,
                open: 18.04855,
                source: "Polygon_ws",
                start_ms: 1720183183000,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 18.04855,
                websocket: true,
              },
              {
                close: 18.04976,
                high: 18.04976,
                lag_ms: 38,
                low: 18.04976,
                open: 18.04976,
                source: "TwelveData_ws",
                start_ms: 1720183183000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1720183183038,
          },
          {
            leverage: 0.05500000000000001,
            order_type: "LONG",
            order_uuid: "17cdf72b-710f-4b67-9b89-da6ac282a994",
            price: 18.02281,
            price_sources: [
              {
                close: 18.02281,
                high: 18.02281,
                lag_ms: 488,
                low: 18.02281,
                open: 18.02281,
                source: "Polygon_ws",
                start_ms: 1720183399000,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 18.02281,
                websocket: true,
              },
              {
                close: 18.018,
                high: 18.018,
                lag_ms: 488,
                low: 18.018,
                open: 18.018,
                source: "TwelveData_ws",
                start_ms: 1720183399000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1720183399488,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "33f7e009-08e2-4765-b4ff-95973349bd9e",
            price: 18.04799,
            price_sources: [
              {
                close: 18.04799,
                high: 18.04799,
                lag_ms: 372,
                low: 18.04799,
                open: 18.04799,
                source: "Polygon_ws",
                start_ms: 1720183759999,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 18.04799,
                websocket: true,
              },
              {
                close: 18.0454,
                high: 18.0454,
                lag_ms: 373,
                low: 18.0454,
                open: 18.0454,
                source: "TwelveData_ws",
                start_ms: 1720183760000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1720183759627,
          },
        ],
        position_type: "FLAT",
        position_uuid: "52d9b57c-3ac1-4377-8d35-53067a1fcff7",
        return_at_close: 1.0000678300020935,
        trade_pair: ["USDMXN", "USD/MXN", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 18.04821,
        close_ms: 1720186391088,
        current_return: 1.0001023647220417,
        initial_entry_price: 18.04821,
        is_closed_position: true,
        miner_hotkey: "5F7ThFe3ZRxTVFjz4M8ciTHbcVAdVDHLZ7SDyMgdFJpdLdEm",
        net_leverage: 0.0,
        open_ms: 1720183789244,
        orders: [
          {
            leverage: 0.05,
            order_type: "LONG",
            order_uuid: "a125e134-293a-4083-bed0-66c7f171bebd",
            price: 18.04821,
            price_sources: [
              {
                close: 18.04821,
                high: 18.04821,
                lag_ms: 35,
                low: 18.04821,
                open: 18.04821,
                source: "Polygon_ws",
                start_ms: 1720183788999,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 18.04821,
                websocket: true,
              },
              {
                close: 18.0483,
                high: 18.0483,
                lag_ms: 36,
                low: 18.0483,
                open: 18.0483,
                source: "TwelveData_ws",
                start_ms: 1720183789000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1720183788964,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "1a6852e9-6f75-4aef-9294-014874d792ab",
            price: 18.08516,
            price_sources: [
              {
                close: 18.08516,
                high: 18.08516,
                lag_ms: 88,
                low: 18.08516,
                open: 18.08516,
                source: "Polygon_ws",
                start_ms: 1720186391000,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 18.08516,
                websocket: true,
              },
              {
                close: 18.084,
                high: 18.084,
                lag_ms: 88,
                low: 18.084,
                open: 18.084,
                source: "TwelveData_ws",
                start_ms: 1720186391000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1720186391088,
          },
        ],
        position_type: "FLAT",
        position_uuid: "52dbf98f-3ae2-44a9-9b20-b90928d7fe3c",
        return_at_close: 1.0000988643637652,
        trade_pair: ["USDMXN", "USD/MXN", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 18.08761,
        close_ms: 1720187266681,
        current_return: 1.0000958667286612,
        initial_entry_price: 18.08761,
        is_closed_position: true,
        miner_hotkey: "5F7ThFe3ZRxTVFjz4M8ciTHbcVAdVDHLZ7SDyMgdFJpdLdEm",
        net_leverage: 0.0,
        open_ms: 1720186420908,
        orders: [
          {
            leverage: 0.05,
            order_type: "LONG",
            order_uuid: "3a441cb4-448b-47ec-9409-87c7344fe26f",
            price: 18.08761,
            price_sources: [
              {
                close: 18.08761,
                high: 18.08761,
                lag_ms: 243,
                low: 18.08761,
                open: 18.08761,
                source: "Polygon_ws",
                start_ms: 1720186420999,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 18.08761,
                websocket: true,
              },
              {
                close: 18.08781,
                high: 18.08781,
                lag_ms: 756,
                low: 18.08781,
                open: 18.08781,
                source: "TwelveData_ws",
                start_ms: 1720186420000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1720186420756,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "d28cd501-f505-46a4-83ad-349e42f9ce68",
            price: 18.12229,
            price_sources: [
              {
                close: 18.12229,
                high: 18.12229,
                lag_ms: 318,
                low: 18.12229,
                open: 18.12229,
                source: "Polygon_ws",
                start_ms: 1720187266999,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 18.12229,
                websocket: true,
              },
              {
                close: 18.12386,
                high: 18.12386,
                lag_ms: 319,
                low: 18.12386,
                open: 18.12386,
                source: "TwelveData_ws",
                start_ms: 1720187267000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1720187266681,
          },
        ],
        position_type: "FLAT",
        position_uuid: "a3ead4d5-35bd-4111-a539-96769c29eaab",
        return_at_close: 1.0000923663931276,
        trade_pair: ["USDMXN", "USD/MXN", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 18.12044,
        close_ms: 1720187464122,
        current_return: 1.0000936511475438,
        initial_entry_price: 18.12044,
        is_closed_position: true,
        miner_hotkey: "5F7ThFe3ZRxTVFjz4M8ciTHbcVAdVDHLZ7SDyMgdFJpdLdEm",
        net_leverage: 0.0,
        open_ms: 1720187296269,
        orders: [
          {
            leverage: 0.05,
            order_type: "LONG",
            order_uuid: "d52c18cf-98e6-4630-afe7-153d8b53996b",
            price: 18.12044,
            price_sources: [
              {
                close: 18.12044,
                high: 18.12044,
                lag_ms: 99,
                low: 18.12044,
                open: 18.12044,
                source: "Polygon_ws",
                start_ms: 1720187295999,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 18.12044,
                websocket: true,
              },
              {
                close: 18.12125,
                high: 18.12125,
                lag_ms: 100,
                low: 18.12125,
                open: 18.12125,
                source: "TwelveData_ws",
                start_ms: 1720187296000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1720187295900,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "8c45c68a-d9aa-489a-87db-9c6a4724f774",
            price: 18.15438,
            price_sources: [
              {
                close: 18.15438,
                high: 18.15438,
                lag_ms: 122,
                low: 18.15438,
                open: 18.15438,
                source: "Polygon_ws",
                start_ms: 1720187464000,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 18.1463,
                websocket: true,
              },
              {
                close: 18.1568,
                high: 18.1568,
                lag_ms: 122,
                low: 18.1568,
                open: 18.1568,
                source: "TwelveData_ws",
                start_ms: 1720187464000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1720187464122,
          },
        ],
        position_type: "FLAT",
        position_uuid: "95223256-b0a5-4c6a-86ca-d77ec3bc1b27",
        return_at_close: 1.0000901508197648,
        trade_pair: ["USDMXN", "USD/MXN", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 18.153154999999998,
        close_ms: 1720188032689,
        current_return: 1.0000618487530128,
        initial_entry_price: 18.153154999999998,
        is_closed_position: true,
        miner_hotkey: "5F7ThFe3ZRxTVFjz4M8ciTHbcVAdVDHLZ7SDyMgdFJpdLdEm",
        net_leverage: 0.0,
        open_ms: 1720187494385,
        orders: [
          {
            leverage: 0.05,
            order_type: "LONG",
            order_uuid: "b2bd2e92-7392-4c4f-8e84-fa166a122557",
            price: 18.153154999999998,
            price_sources: [
              {
                close: 18.153154999999998,
                high: 18.153154999999998,
                lag_ms: 53,
                low: 18.153154999999998,
                open: 18.153154999999998,
                source: "Polygon_ws",
                start_ms: 1720187494000,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 18.1476,
                websocket: true,
              },
              {
                close: 18.16,
                high: 18.16,
                lag_ms: 947,
                low: 18.16,
                open: 18.16,
                source: "TwelveData_ws",
                start_ms: 1720187495000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1720187494053,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "71accfeb-a481-4065-a947-318119c271e0",
            price: 18.17561,
            price_sources: [
              {
                close: 18.17561,
                high: 18.17561,
                lag_ms: 310,
                low: 18.17561,
                open: 18.17561,
                source: "Polygon_ws",
                start_ms: 1720188032999,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 18.1707,
                websocket: true,
              },
              {
                close: 18.1783,
                high: 18.1783,
                lag_ms: 311,
                low: 18.1783,
                open: 18.1783,
                source: "TwelveData_ws",
                start_ms: 1720188033000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1720188032689,
          },
        ],
        position_type: "FLAT",
        position_uuid: "c8182522-3aa4-4ba9-8f48-9987ab4068ab",
        return_at_close: 1.0000583485365422,
        trade_pair: ["USDMXN", "USD/MXN", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 18.0973504844362,
        close_ms: 1720195147770,
        current_return: 0.9997038597901146,
        initial_entry_price: 18.167050000000003,
        is_closed_position: true,
        miner_hotkey: "5F7ThFe3ZRxTVFjz4M8ciTHbcVAdVDHLZ7SDyMgdFJpdLdEm",
        net_leverage: 0.0,
        open_ms: 1720188062318,
        orders: [
          {
            leverage: 0.05,
            order_type: "LONG",
            order_uuid: "e3a739a1-4f9c-40cb-b36c-59b96b80e227",
            price: 18.167050000000003,
            price_sources: [
              {
                close: 18.167050000000003,
                high: 18.167050000000003,
                lag_ms: 5,
                low: 18.167050000000003,
                open: 18.167050000000003,
                source: "Polygon_ws",
                start_ms: 1720188062000,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 18.1624,
                websocket: true,
              },
              {
                close: 18.17266,
                high: 18.17266,
                lag_ms: 5,
                low: 18.17266,
                open: 18.17266,
                source: "TwelveData_ws",
                start_ms: 1720188062000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1720188062005,
          },
          {
            leverage: 0.05500000000000001,
            order_type: "LONG",
            order_uuid: "6ac725b4-3018-4af2-add3-d9f138bef5f6",
            price: 18.14879,
            price_sources: [
              {
                close: 18.14879,
                high: 18.14879,
                lag_ms: 343,
                low: 18.14879,
                open: 18.14879,
                source: "Polygon_ws",
                start_ms: 1720189358000,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 18.14879,
                websocket: true,
              },
              {
                close: 18.1465,
                high: 18.1465,
                lag_ms: 343,
                low: 18.1465,
                open: 18.1465,
                source: "TwelveData_ws",
                start_ms: 1720189358000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1720189358343,
          },
          {
            leverage: 0.11550000000000002,
            order_type: "LONG",
            order_uuid: "deb21bfe-0999-41c6-9d8d-da08764357e6",
            price: 18.1003,
            price_sources: [
              {
                close: 18.1003,
                high: 18.1003,
                lag_ms: 477,
                low: 18.1003,
                open: 18.1003,
                source: "Polygon_ws",
                start_ms: 1720193785000,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 18.1003,
                websocket: true,
              },
              {
                close: 18.11203,
                high: 18.11203,
                lag_ms: 477,
                low: 18.11203,
                open: 18.11203,
                source: "TwelveData_ws",
                start_ms: 1720193785000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1720193784523,
          },
          {
            leverage: 0.2646,
            order_type: "LONG",
            order_uuid: "9d9f54e6-71b8-46b4-81d1-7d72c6c9418d",
            price: 18.0722,
            price_sources: [
              {
                close: 18.0722,
                high: 18.0722,
                lag_ms: 111,
                low: 18.0722,
                open: 18.0722,
                source: "Polygon_ws",
                start_ms: 1720195106000,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 18.0722,
                websocket: true,
              },
              {
                close: 18.09093,
                high: 18.09093,
                lag_ms: 111,
                low: 18.09093,
                open: 18.09093,
                source: "TwelveData_ws",
                start_ms: 1720195106000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
              {
                close: 18.0784,
                high: 18.0784,
                lag_ms: 3890,
                low: 18.0784,
                open: 18.0784,
                source: "Polygon_rest",
                start_ms: 1720195101000,
                timespan_ms: 1000,
                volume: 1.0,
                vwap: null,
                websocket: false,
              },
              {
                close: 18.0899,
                high: 18.09795,
                lag_ms: 25890,
                low: 18.0872,
                open: 18.0972,
                source: "TwelveData_rest",
                start_ms: 1720195020000,
                timespan_ms: 60000,
                volume: null,
                vwap: null,
                websocket: false,
              },
            ],
            processed_ms: 1720195105889,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "6ed3a400-38c0-4c0a-8520-e81541b5ea0f",
            price: 18.08626,
            price_sources: [
              {
                close: 18.08626,
                high: 18.08626,
                lag_ms: 229,
                low: 18.08626,
                open: 18.08626,
                source: "Polygon_ws",
                start_ms: 1720195147999,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 18.08626,
                websocket: true,
              },
              {
                close: 18.08735,
                high: 18.08735,
                lag_ms: 230,
                low: 18.08735,
                open: 18.08735,
                source: "TwelveData_ws",
                start_ms: 1720195148000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1720195147770,
          },
        ],
        position_type: "FLAT",
        position_uuid: "094b4733-31f4-46a9-966a-15ec9a024718",
        return_at_close: 0.9996699128461477,
        trade_pair: ["USDMXN", "USD/MXN", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 18.075965,
        close_ms: 1720206886006,
        current_return: 1.0000558619069337,
        initial_entry_price: 18.08925,
        is_closed_position: true,
        miner_hotkey: "5F7ThFe3ZRxTVFjz4M8ciTHbcVAdVDHLZ7SDyMgdFJpdLdEm",
        net_leverage: 0.0,
        open_ms: 1720195178171,
        orders: [
          {
            leverage: 0.05,
            order_type: "LONG",
            order_uuid: "632c8458-c524-4069-aa2c-d09cb3f3e5d7",
            price: 18.08925,
            price_sources: [
              {
                close: 18.08925,
                high: 18.08925,
                lag_ms: 30,
                low: 18.08925,
                open: 18.08925,
                source: "Polygon_ws",
                start_ms: 1720195177999,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 18.08925,
                websocket: true,
              },
              {
                close: 18.08959,
                high: 18.08959,
                lag_ms: 969,
                low: 18.08959,
                open: 18.08959,
                source: "TwelveData_ws",
                start_ms: 1720195177000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1720195177969,
          },
          {
            leverage: 0.05,
            order_type: "LONG",
            order_uuid: "c8ff2f5b-48e7-439f-a710-8b6a33182f74",
            price: 18.06268,
            price_sources: [
              {
                close: 18.06268,
                high: 18.06268,
                lag_ms: 976,
                low: 18.06268,
                open: 18.06268,
                source: "TwelveData_ws",
                start_ms: 1720202178000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
              {
                close: 18.06225,
                high: 18.06225,
                lag_ms: 977,
                low: 18.06225,
                open: 18.06225,
                source: "Polygon_ws",
                start_ms: 1720202177999,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 18.06225,
                websocket: true,
              },
            ],
            processed_ms: 1720202178976,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "96505924-f90f-4df4-ab6d-8c226bc29235",
            price: 18.08607,
            price_sources: [
              {
                close: 18.08607,
                high: 18.08607,
                lag_ms: 6,
                low: 18.08607,
                open: 18.08607,
                source: "Polygon_ws",
                start_ms: 1720206886000,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 18.08607,
                websocket: true,
              },
              {
                close: 18.08736,
                high: 18.08736,
                lag_ms: 6,
                low: 18.08736,
                open: 18.08736,
                source: "TwelveData_ws",
                start_ms: 1720206886000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1720206886006,
          },
        ],
        position_type: "FLAT",
        position_uuid: "1e921296-fa10-479a-ac60-855da533d9fa",
        return_at_close: 1.0000488615159004,
        trade_pair: ["USDMXN", "USD/MXN", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 18.02045909090909,
        close_ms: 1720455408663,
        current_return: 0.999872777898187,
        initial_entry_price: 18.08648,
        is_closed_position: true,
        miner_hotkey: "5F7ThFe3ZRxTVFjz4M8ciTHbcVAdVDHLZ7SDyMgdFJpdLdEm",
        net_leverage: 0.0,
        open_ms: 1720206918796,
        orders: [
          {
            leverage: 0.05,
            order_type: "LONG",
            order_uuid: "55bf1336-f4ed-4e08-8662-09c197af43c7",
            price: 18.08648,
            price_sources: [
              {
                close: 18.08648,
                high: 18.08648,
                lag_ms: 333,
                low: 18.08648,
                open: 18.08648,
                source: "Polygon_ws",
                start_ms: 1720206917000,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 18.08648,
                websocket: true,
              },
              {
                close: 18.082,
                high: 18.082,
                lag_ms: 333,
                low: 18.082,
                open: 18.082,
                source: "TwelveData_ws",
                start_ms: 1720206917000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1720206917333,
          },
          {
            leverage: 0.05,
            order_type: "LONG",
            order_uuid: "875ed96c-57ca-4e8a-b8f1-95dc6f2601af",
            price: 18.0279,
            price_sources: [
              {
                close: 18.0279,
                high: 18.0279,
                lag_ms: 328,
                low: 18.0279,
                open: 18.0279,
                source: "Polygon_ws",
                start_ms: 1720443444999,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 18.0279,
                websocket: true,
              },
              {
                close: 18.02761,
                high: 18.02761,
                lag_ms: 329,
                low: 18.02761,
                open: 18.02761,
                source: "TwelveData_ws",
                start_ms: 1720443445000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1720443444671,
          },
          {
            leverage: 0.12,
            order_type: "LONG",
            order_uuid: "4d6432af-4790-4e8b-a1bf-f5837950bed8",
            price: 17.98985,
            price_sources: [
              {
                close: 17.98985,
                high: 17.98985,
                lag_ms: 278,
                low: 17.98985,
                open: 17.98985,
                source: "Polygon_ws",
                start_ms: 1720448931999,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 17.98985,
                websocket: true,
              },
              {
                close: 17.99018,
                high: 17.99018,
                lag_ms: 721,
                low: 17.99018,
                open: 17.99018,
                source: "TwelveData_ws",
                start_ms: 1720448931000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1720448931721,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "1872fbb9-22bb-4362-9721-28aeb4955bf2",
            price: 18.01,
            price_sources: [
              {
                close: 18.01,
                high: 18.01,
                lag_ms: 337,
                low: 18.01,
                open: 18.01,
                source: "TwelveData_ws",
                start_ms: 1720455409000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
              {
                close: 18.008435,
                high: 18.008435,
                lag_ms: 664,
                low: 18.008435,
                open: 18.008435,
                source: "Polygon_ws",
                start_ms: 1720455407999,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 18.01477,
                websocket: true,
              },
            ],
            processed_ms: 1720455408663,
          },
        ],
        position_type: "FLAT",
        position_uuid: "5e51cc51-0546-4f3f-b54e-0970bd376d27",
        return_at_close: 0.9998573798574073,
        trade_pair: ["USDMXN", "USD/MXN", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 17.981254545454544,
        close_ms: 1720471256378,
        current_return: 1.0001267665096436,
        initial_entry_price: 18.0016,
        is_closed_position: true,
        miner_hotkey: "5F7ThFe3ZRxTVFjz4M8ciTHbcVAdVDHLZ7SDyMgdFJpdLdEm",
        net_leverage: 0.0,
        open_ms: 1720455443628,
        orders: [
          {
            leverage: 0.05,
            order_type: "LONG",
            order_uuid: "257413d8-d661-4d9b-a81d-39415e19449b",
            price: 18.0016,
            price_sources: [
              {
                close: 18.0016,
                high: 18.0016,
                lag_ms: 324,
                low: 18.0016,
                open: 18.0016,
                source: "Polygon_ws",
                start_ms: 1720455442999,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 18.0016,
                websocket: true,
              },
              {
                close: 18.0108,
                high: 18.0108,
                lag_ms: 325,
                low: 18.0108,
                open: 18.0108,
                source: "TwelveData_ws",
                start_ms: 1720455443000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1720455442675,
          },
          {
            leverage: 0.06,
            order_type: "LONG",
            order_uuid: "acb964ce-2042-4dbf-868f-1b85dc778760",
            price: 17.9643,
            price_sources: [
              {
                close: 17.9643,
                high: 17.9643,
                lag_ms: 439,
                low: 17.9643,
                open: 17.9643,
                source: "Polygon_ws",
                start_ms: 1720463422000,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 17.9643,
                websocket: true,
              },
              {
                close: 17.972,
                high: 17.972,
                lag_ms: 439,
                low: 17.972,
                open: 17.972,
                source: "TwelveData_ws",
                start_ms: 1720463422000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
              {
                close: 17.97663,
                high: 17.97663,
                lag_ms: 2562,
                low: 17.97663,
                open: 17.97663,
                source: "Polygon_rest",
                start_ms: 1720463418000,
                timespan_ms: 1000,
                volume: 1.0,
                vwap: null,
                websocket: false,
              },
              {
                close: 17.98135,
                high: 17.98145,
                lag_ms: 21562,
                low: 17.9806,
                open: 17.9806,
                source: "TwelveData_rest",
                start_ms: 1720463340000,
                timespan_ms: 60000,
                volume: null,
                vwap: null,
                websocket: false,
              },
            ],
            processed_ms: 1720463421561,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "c54b6c3f-e37e-4af4-88b0-c326a62cb130",
            price: 18.002,
            price_sources: [
              {
                close: 18.002,
                high: 18.002,
                lag_ms: 378,
                low: 18.002,
                open: 18.002,
                source: "TwelveData_ws",
                start_ms: 1720471256000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
              {
                close: 17.9901,
                high: 17.9901,
                lag_ms: 622,
                low: 17.9901,
                open: 17.9901,
                source: "Polygon_ws",
                start_ms: 1720471257000,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 17.9901,
                websocket: true,
              },
              {
                close: 18.00094,
                high: 18.00094,
                lag_ms: 3379,
                low: 18.00094,
                open: 18.00094,
                source: "Polygon_rest",
                start_ms: 1720471252000,
                timespan_ms: 1000,
                volume: 1.0,
                vwap: null,
                websocket: false,
              },
              {
                close: 18.00305,
                high: 18.0044,
                lag_ms: 56379,
                low: 18.00295,
                open: 18.0042,
                source: "TwelveData_rest",
                start_ms: 1720471140000,
                timespan_ms: 60000,
                volume: null,
                vwap: null,
                websocket: false,
              },
            ],
            processed_ms: 1720471256378,
          },
        ],
        position_type: "FLAT",
        position_uuid: "335e1c24-14e9-4bba-9678-50c553f84300",
        return_at_close: 1.0001190655335415,
        trade_pair: ["USDMXN", "USD/MXN", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 17.995836363636364,
        close_ms: 1720474282376,
        current_return: 1.0000465229187174,
        initial_entry_price: 18.00188,
        is_closed_position: true,
        miner_hotkey: "5F7ThFe3ZRxTVFjz4M8ciTHbcVAdVDHLZ7SDyMgdFJpdLdEm",
        net_leverage: 0.0,
        open_ms: 1720471290318,
        orders: [
          {
            leverage: 0.05,
            order_type: "LONG",
            order_uuid: "f4fbe9e5-21dd-4430-adb8-cc4644c26e90",
            price: 18.00188,
            price_sources: [
              {
                close: 18.00188,
                high: 18.00188,
                lag_ms: 189,
                low: 18.00188,
                open: 18.00188,
                source: "Polygon_ws",
                start_ms: 1720471289999,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 18.00188,
                websocket: true,
              },
              {
                close: 18.00195,
                high: 18.00195,
                lag_ms: 812,
                low: 18.00195,
                open: 18.00195,
                source: "TwelveData_ws",
                start_ms: 1720471291000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1720471290188,
          },
          {
            leverage: 0.06,
            order_type: "LONG",
            order_uuid: "97027183-9765-4afa-8dc9-ce99176e5eff",
            price: 17.9908,
            price_sources: [
              {
                close: 17.9908,
                high: 17.9908,
                lag_ms: 779,
                low: 17.9908,
                open: 17.9908,
                source: "Polygon_ws",
                start_ms: 1720473973000,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 17.9888,
                websocket: true,
              },
              {
                close: 17.97635,
                high: 17.97635,
                lag_ms: 11221,
                low: 17.97635,
                open: 17.97635,
                source: "TwelveData_ws",
                start_ms: 1720473961000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
              {
                close: 18.0027,
                high: 18.0027,
                lag_ms: 312222,
                low: 18.0018,
                open: 18.0018,
                source: "TwelveData_rest",
                start_ms: 1720473600000,
                timespan_ms: 60000,
                volume: null,
                vwap: null,
                websocket: false,
              },
            ],
            processed_ms: 1720473972221,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "92434c29-1ddb-4eb9-949f-ee0754c434b7",
            price: 18.00345,
            price_sources: [
              {
                close: 18.00345,
                high: 18.00345,
                lag_ms: 376,
                low: 18.00345,
                open: 18.00345,
                source: "Polygon_ws",
                start_ms: 1720474282000,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 18.00345,
                websocket: true,
              },
              {
                close: 17.991,
                high: 17.991,
                lag_ms: 376,
                low: 17.991,
                open: 17.991,
                source: "TwelveData_ws",
                start_ms: 1720474282000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
              {
                close: 17.9874,
                high: 17.9874,
                lag_ms: 2377,
                low: 17.9874,
                open: 17.9874,
                source: "Polygon_rest",
                start_ms: 1720474279000,
                timespan_ms: 1000,
                volume: 1.0,
                vwap: null,
                websocket: false,
              },
              {
                close: 18.0021,
                high: 18.0021,
                lag_ms: 202377,
                low: 17.9925,
                open: 17.9925,
                source: "TwelveData_rest",
                start_ms: 1720474020000,
                timespan_ms: 60000,
                volume: null,
                vwap: null,
                websocket: false,
              },
            ],
            processed_ms: 1720474282376,
          },
        ],
        position_type: "FLAT",
        position_uuid: "2e3e63c6-1f1c-4a23-9a0d-e5566d8b0260",
        return_at_close: 1.0000388225604908,
        trade_pair: ["USDMXN", "USD/MXN", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 17.9949,
        close_ms: 1720508124266,
        current_return: 1.000088844617086,
        initial_entry_price: 17.9949,
        is_closed_position: true,
        miner_hotkey: "5F7ThFe3ZRxTVFjz4M8ciTHbcVAdVDHLZ7SDyMgdFJpdLdEm",
        net_leverage: 0.0,
        open_ms: 1720474315042,
        orders: [
          {
            leverage: 0.05,
            order_type: "LONG",
            order_uuid: "53c03066-7d79-402b-8a2e-c66699c95c5b",
            price: 17.9949,
            price_sources: [
              {
                close: 17.9949,
                high: 17.9949,
                lag_ms: 30,
                low: 17.9949,
                open: 17.9949,
                source: "Polygon_ws",
                start_ms: 1720474314000,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 17.9949,
                websocket: true,
              },
              {
                close: 18.00458,
                high: 18.00458,
                lag_ms: 1030,
                low: 18.00458,
                open: 18.00458,
                source: "TwelveData_ws",
                start_ms: 1720474315000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
              {
                close: 17.996,
                high: 17.996,
                lag_ms: 3971,
                low: 17.996,
                open: 17.996,
                source: "Polygon_rest",
                start_ms: 1720474309000,
                timespan_ms: 1000,
                volume: 1.0,
                vwap: null,
                websocket: false,
              },
              {
                close: 18.0021,
                high: 18.0021,
                lag_ms: 233971,
                low: 17.9925,
                open: 17.9925,
                source: "TwelveData_rest",
                start_ms: 1720474020000,
                timespan_ms: 60000,
                volume: null,
                vwap: null,
                websocket: false,
              },
            ],
            processed_ms: 1720474313970,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "02a501cd-83fe-48c4-a363-a28d109a2f52",
            price: 18.026875,
            price_sources: [
              {
                close: 18.026875,
                high: 18.026875,
                lag_ms: 266,
                low: 18.026875,
                open: 18.026875,
                source: "Polygon_ws",
                start_ms: 1720508124000,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 18.03365,
                websocket: true,
              },
              {
                close: 18.0308,
                high: 18.0308,
                lag_ms: 266,
                low: 18.0308,
                open: 18.0308,
                source: "TwelveData_ws",
                start_ms: 1720508124000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1720508124266,
          },
        ],
        position_type: "FLAT",
        position_uuid: "10cce07b-b0d8-41da-b852-73678dd9c5fb",
        return_at_close: 1.0000853443061297,
        trade_pair: ["USDMXN", "USD/MXN", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 18.017486363636362,
        close_ms: 1720511304560,
        current_return: 1.0000929428958891,
        initial_entry_price: 18.02935,
        is_closed_position: true,
        miner_hotkey: "5F7ThFe3ZRxTVFjz4M8ciTHbcVAdVDHLZ7SDyMgdFJpdLdEm",
        net_leverage: 0.0,
        open_ms: 1720508155950,
        orders: [
          {
            leverage: 0.05,
            order_type: "LONG",
            order_uuid: "36a95454-fa34-4306-b425-8f72f167dc62",
            price: 18.02935,
            price_sources: [
              {
                close: 18.02935,
                high: 18.02935,
                lag_ms: 278,
                low: 18.02935,
                open: 18.02935,
                source: "Polygon_ws",
                start_ms: 1720508155999,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 18.0232,
                websocket: true,
              },
              {
                close: 18.03531,
                high: 18.03531,
                lag_ms: 279,
                low: 18.03531,
                open: 18.03531,
                source: "TwelveData_ws",
                start_ms: 1720508156000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1720508155721,
          },
          {
            leverage: 0.06,
            order_type: "LONG",
            order_uuid: "53e433a4-1a79-48f0-aa8a-e9913e03caf8",
            price: 18.0076,
            price_sources: [
              {
                close: 18.0076,
                high: 18.0076,
                lag_ms: 555,
                low: 18.0076,
                open: 18.0076,
                source: "Polygon_ws",
                start_ms: 1720508491000,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 18.00755,
                websocket: true,
              },
              {
                close: 18.0072,
                high: 18.0072,
                lag_ms: 555,
                low: 18.0072,
                open: 18.0072,
                source: "TwelveData_ws",
                start_ms: 1720508491000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1720508490445,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "045c4b35-7c70-4184-b69c-ad463b50424d",
            price: 18.03272,
            price_sources: [
              {
                close: 18.03272,
                high: 18.03272,
                lag_ms: 439,
                low: 18.03272,
                open: 18.03272,
                source: "Polygon_ws",
                start_ms: 1720511304999,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 18.03272,
                websocket: true,
              },
              {
                close: 18.03268,
                high: 18.03268,
                lag_ms: 440,
                low: 18.03268,
                open: 18.03268,
                source: "TwelveData_ws",
                start_ms: 1720511305000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1720511304560,
          },
        ],
        position_type: "FLAT",
        position_uuid: "5ef62a71-fa3d-4a8d-9d26-1cde8e0626dd",
        return_at_close: 1.0000852421802289,
        trade_pair: ["USDMXN", "USD/MXN", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 17.961180303030304,
        close_ms: 1720533972275,
        current_return: 0.9999876945975457,
        initial_entry_price: 18.03273,
        is_closed_position: true,
        miner_hotkey: "5F7ThFe3ZRxTVFjz4M8ciTHbcVAdVDHLZ7SDyMgdFJpdLdEm",
        net_leverage: 0.0,
        open_ms: 1720511338217,
        orders: [
          {
            leverage: 0.05,
            order_type: "LONG",
            order_uuid: "b30e2aa8-80d4-4ad2-8cd5-a4985a307115",
            price: 18.03273,
            price_sources: [
              {
                close: 18.03273,
                high: 18.03273,
                lag_ms: 48,
                low: 18.03273,
                open: 18.03273,
                source: "Polygon_ws",
                start_ms: 1720511338000,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 18.03273,
                websocket: true,
              },
              {
                close: 18.0305,
                high: 18.0305,
                lag_ms: 48,
                low: 18.0305,
                open: 18.0305,
                source: "TwelveData_ws",
                start_ms: 1720511338000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1720511338048,
          },
          {
            leverage: 0.05,
            order_type: "LONG",
            order_uuid: "a2d9877e-4e82-4857-be4a-800d3f055a53",
            price: 18.00935,
            price_sources: [
              {
                close: 18.00935,
                high: 18.00935,
                lag_ms: 482,
                low: 18.00935,
                open: 18.00935,
                source: "Polygon_ws",
                start_ms: 1720529202999,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 18.00935,
                websocket: true,
              },
              {
                close: 18.004,
                high: 18.004,
                lag_ms: 483,
                low: 18.004,
                open: 18.004,
                source: "TwelveData_ws",
                start_ms: 1720529203000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1720529202517,
          },
          {
            leverage: 0.12,
            order_type: "LONG",
            order_uuid: "a61f9f04-c81c-4ecd-b4fe-80e7a1c3ae1f",
            price: 17.96722,
            price_sources: [
              {
                close: 17.96722,
                high: 17.96722,
                lag_ms: 41,
                low: 17.96722,
                open: 17.96722,
                source: "Polygon_ws",
                start_ms: 1720531956999,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 17.96722,
                websocket: true,
              },
              {
                close: 17.963,
                high: 17.963,
                lag_ms: 42,
                low: 17.963,
                open: 17.963,
                source: "TwelveData_ws",
                start_ms: 1720531957000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1720531956958,
          },
          {
            leverage: 0.24200000000000002,
            order_type: "LONG",
            order_uuid: "4918a938-6370-4719-aa1e-d247030ef228",
            price: 17.93345,
            price_sources: [
              {
                close: 17.93345,
                high: 17.93345,
                lag_ms: 283,
                low: 17.93345,
                open: 17.93345,
                source: "Polygon_ws",
                start_ms: 1720533739999,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 17.93345,
                websocket: true,
              },
              {
                close: 17.9275,
                high: 17.9275,
                lag_ms: 284,
                low: 17.9275,
                open: 17.9275,
                source: "TwelveData_ws",
                start_ms: 1720533740000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1720533739716,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "e6f2c598-0255-4b35-b550-5da6a316ffa5",
            price: 17.9607,
            price_sources: [
              {
                close: 17.9607,
                high: 17.9607,
                lag_ms: 275,
                low: 17.9607,
                open: 17.9607,
                source: "Polygon_ws",
                start_ms: 1720533972000,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 17.9607,
                websocket: true,
              },
              {
                close: 17.96207,
                high: 17.96207,
                lag_ms: 275,
                low: 17.96207,
                open: 17.96207,
                source: "TwelveData_ws",
                start_ms: 1720533972000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1720533972275,
          },
        ],
        position_type: "FLAT",
        position_uuid: "a18db68a-c3cf-44be-8298-da379a1c6df6",
        return_at_close: 0.9999553549955024,
        trade_pair: ["USDMXN", "USD/MXN", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 17.941009523809523,
        close_ms: 1720536847656,
        current_return: 1.000075212533567,
        initial_entry_price: 17.96775,
        is_closed_position: true,
        miner_hotkey: "5F7ThFe3ZRxTVFjz4M8ciTHbcVAdVDHLZ7SDyMgdFJpdLdEm",
        net_leverage: 0.0,
        open_ms: 1720534001947,
        orders: [
          {
            leverage: 0.05,
            order_type: "LONG",
            order_uuid: "5c736890-863b-4d7a-84b8-0378fe040dec",
            price: 17.96775,
            price_sources: [
              {
                close: 17.96775,
                high: 17.96775,
                lag_ms: 88,
                low: 17.96775,
                open: 17.96775,
                source: "Polygon_ws",
                start_ms: 1720534001999,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 17.96775,
                websocket: true,
              },
              {
                close: 17.96786,
                high: 17.96786,
                lag_ms: 89,
                low: 17.96786,
                open: 17.96786,
                source: "TwelveData_ws",
                start_ms: 1720534002000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1720534001911,
          },
          {
            leverage: 0.05500000000000001,
            order_type: "LONG",
            order_uuid: "09370394-b482-462a-8955-6a9bfc57aaef",
            price: 17.9167,
            price_sources: [
              {
                close: 17.9167,
                high: 17.9167,
                lag_ms: 497,
                low: 17.9167,
                open: 17.9167,
                source: "Polygon_ws",
                start_ms: 1720534489000,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 17.9167,
                websocket: true,
              },
              {
                close: 17.925,
                high: 17.925,
                lag_ms: 497,
                low: 17.925,
                open: 17.925,
                source: "TwelveData_ws",
                start_ms: 1720534489000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1720534488503,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "bbdb2cda-bfa0-49c8-bc70-0dedf6b3cde9",
            price: 17.95388,
            price_sources: [
              {
                close: 17.95388,
                high: 17.95388,
                lag_ms: 343,
                low: 17.95388,
                open: 17.95388,
                source: "Polygon_ws",
                start_ms: 1720536847999,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 17.95388,
                websocket: true,
              },
              {
                close: 17.9511,
                high: 17.9511,
                lag_ms: 344,
                low: 17.9511,
                open: 17.9511,
                source: "TwelveData_ws",
                start_ms: 1720536848000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1720536847656,
          },
        ],
        position_type: "FLAT",
        position_uuid: "58cfba10-5c48-4224-894b-874f6607af08",
        return_at_close: 1.000067861980755,
        trade_pair: ["USDMXN", "USD/MXN", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 17.93322857142857,
        close_ms: 1720543149783,
        current_return: 1.0001100854725613,
        initial_entry_price: 17.95196,
        is_closed_position: true,
        miner_hotkey: "5F7ThFe3ZRxTVFjz4M8ciTHbcVAdVDHLZ7SDyMgdFJpdLdEm",
        net_leverage: 0.0,
        open_ms: 1720536877938,
        orders: [
          {
            leverage: 0.05,
            order_type: "LONG",
            order_uuid: "cdbc2cc7-1954-40c5-946a-7679d5e916be",
            price: 17.95196,
            price_sources: [
              {
                close: 17.95196,
                high: 17.95196,
                lag_ms: 71,
                low: 17.95196,
                open: 17.95196,
                source: "Polygon_ws",
                start_ms: 1720536877999,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 17.95196,
                websocket: true,
              },
              {
                close: 17.95163,
                high: 17.95163,
                lag_ms: 72,
                low: 17.95163,
                open: 17.95163,
                source: "TwelveData_ws",
                start_ms: 1720536878000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1720536877928,
          },
          {
            leverage: 0.05500000000000001,
            order_type: "LONG",
            order_uuid: "b5bc3772-2cb2-4608-a006-1bf69e95b696",
            price: 17.9162,
            price_sources: [
              {
                close: 17.9162,
                high: 17.9162,
                lag_ms: 316,
                low: 17.9162,
                open: 17.9162,
                source: "Polygon_ws",
                start_ms: 1720542589999,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 17.9162,
                websocket: true,
              },
              {
                close: 17.92828,
                high: 17.92828,
                lag_ms: 683,
                low: 17.92828,
                open: 17.92828,
                source: "TwelveData_ws",
                start_ms: 1720542589000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1720542589683,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "bde15de8-8aae-4c2d-b7e3-fef294229e79",
            price: 17.95205,
            price_sources: [
              {
                close: 17.95205,
                high: 17.95205,
                lag_ms: 216,
                low: 17.95205,
                open: 17.95205,
                source: "Polygon_ws",
                start_ms: 1720543149999,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 17.95205,
                websocket: true,
              },
              {
                close: 17.9494,
                high: 17.9494,
                lag_ms: 217,
                low: 17.9494,
                open: 17.9494,
                source: "TwelveData_ws",
                start_ms: 1720543150000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1720543149783,
          },
        ],
        position_type: "FLAT",
        position_uuid: "031cdc29-3c82-4a8a-8363-95f4617adbea",
        return_at_close: 1.000102734663433,
        trade_pair: ["USDMXN", "USD/MXN", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 17.834122535363353,
        close_ms: 1720597369805,
        current_return: 0.9994054313971306,
        initial_entry_price: 17.9475,
        is_closed_position: true,
        miner_hotkey: "5F7ThFe3ZRxTVFjz4M8ciTHbcVAdVDHLZ7SDyMgdFJpdLdEm",
        net_leverage: 0.0,
        open_ms: 1720543179037,
        orders: [
          {
            leverage: 0.05,
            order_type: "LONG",
            order_uuid: "6aadb859-845e-4ba9-aed0-2dd64be90418",
            price: 17.9475,
            price_sources: [
              {
                close: 17.9475,
                high: 17.9475,
                lag_ms: 25,
                low: 17.9475,
                open: 17.9475,
                source: "TwelveData_ws",
                start_ms: 1720543179000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
              {
                close: 17.95095,
                high: 17.95095,
                lag_ms: 975,
                low: 17.95095,
                open: 17.95095,
                source: "Polygon_ws",
                start_ms: 1720543180000,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 17.95095,
                websocket: true,
              },
            ],
            processed_ms: 1720543179025,
          },
          {
            leverage: 0.05500000000000001,
            order_type: "LONG",
            order_uuid: "cda28e57-5f78-46e9-bb13-2c251f0855ae",
            price: 17.9225,
            price_sources: [
              {
                close: 17.9225,
                high: 17.9225,
                lag_ms: 468,
                low: 17.9225,
                open: 17.9225,
                source: "Polygon_ws",
                start_ms: 1720544218999,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 17.9286,
                websocket: true,
              },
              {
                close: 17.9258,
                high: 17.9258,
                lag_ms: 469,
                low: 17.9258,
                open: 17.9258,
                source: "TwelveData_ws",
                start_ms: 1720544219000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1720544218531,
          },
          {
            leverage: 0.10500000000000001,
            order_type: "LONG",
            order_uuid: "45e66a53-3f90-43c4-9ffa-e86e86c75f1e",
            price: 17.894,
            price_sources: [
              {
                close: 17.894,
                high: 17.894,
                lag_ms: 1749,
                low: 17.894,
                open: 17.894,
                source: "TwelveData_ws",
                start_ms: 1720567918000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
              {
                close: 17.88908,
                high: 17.88908,
                lag_ms: 4749,
                low: 17.88908,
                open: 17.88908,
                source: "Polygon_ws",
                start_ms: 1720567921000,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 17.89576,
                websocket: true,
              },
              {
                close: 17.8838,
                high: 17.8838,
                lag_ms: 5252,
                low: 17.8838,
                open: 17.8838,
                source: "Polygon_rest",
                start_ms: 1720567910000,
                timespan_ms: 1000,
                volume: 1.0,
                vwap: null,
                websocket: false,
              },
              {
                close: 17.8995,
                high: 17.8995,
                lag_ms: 356252,
                low: 17.8983,
                open: 17.8984,
                source: "TwelveData_rest",
                start_ms: 1720567500000,
                timespan_ms: 60000,
                volume: null,
                vwap: null,
                websocket: false,
              },
            ],
            processed_ms: 1720567916251,
          },
          {
            leverage: 0.23100000000000004,
            order_type: "LONG",
            order_uuid: "5fa6f741-c4a3-4c4c-b832-ed2f238dade2",
            price: 17.8355,
            price_sources: [
              {
                close: 17.8355,
                high: 17.8355,
                lag_ms: 36,
                low: 17.8355,
                open: 17.8355,
                source: "Polygon_ws",
                start_ms: 1720593878000,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 17.8355,
                websocket: true,
              },
              {
                close: 17.8455,
                high: 17.8455,
                lag_ms: 964,
                low: 17.8455,
                open: 17.8455,
                source: "TwelveData_ws",
                start_ms: 1720593879000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1720593878036,
          },
          {
            leverage: 0.4851000000000001,
            order_type: "LONG",
            order_uuid: "138fd3af-a24a-4e06-9c8b-9b87bffe227d",
            price: 17.7988,
            price_sources: [
              {
                close: 17.7988,
                high: 17.7988,
                lag_ms: 263,
                low: 17.7988,
                open: 17.7988,
                source: "Polygon_ws",
                start_ms: 1720596806999,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 17.7988,
                websocket: true,
              },
              {
                close: 17.81115,
                high: 17.81115,
                lag_ms: 264,
                low: 17.81115,
                open: 17.81115,
                source: "TwelveData_ws",
                start_ms: 1720596807000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1720596806736,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "fa1d3bb6-5631-4fc1-a269-41e549fc7fee",
            price: 17.8226,
            price_sources: [
              {
                close: 17.8226,
                high: 17.8226,
                lag_ms: 195,
                low: 17.8226,
                open: 17.8226,
                source: "Polygon_ws",
                start_ms: 1720597370000,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 17.8226,
                websocket: true,
              },
              {
                close: 17.8333,
                high: 17.8333,
                lag_ms: 195,
                low: 17.8333,
                open: 17.8333,
                source: "TwelveData_ws",
                start_ms: 1720597370000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1720597369805,
          },
        ],
        position_type: "FLAT",
        position_uuid: "ca2c381d-828a-4202-9283-06e41d46e17c",
        return_at_close: 0.9993406429412294,
        trade_pair: ["USDMXN", "USD/MXN", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 17.802336904761905,
        close_ms: 1720606329485,
        current_return: 0.9999854403080657,
        initial_entry_price: 17.84035,
        is_closed_position: true,
        miner_hotkey: "5F7ThFe3ZRxTVFjz4M8ciTHbcVAdVDHLZ7SDyMgdFJpdLdEm",
        net_leverage: 0.0,
        open_ms: 1720597404774,
        orders: [
          {
            leverage: 0.05,
            order_type: "LONG",
            order_uuid: "0e4bbcf6-5536-43c2-8767-b92cc9fddc91",
            price: 17.84035,
            price_sources: [
              {
                close: 17.84035,
                high: 17.84035,
                lag_ms: 235,
                low: 17.84035,
                open: 17.84035,
                source: "Polygon_ws",
                start_ms: 1720597404999,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 17.84035,
                websocket: true,
              },
              {
                close: 17.8372,
                high: 17.8372,
                lag_ms: 236,
                low: 17.8372,
                open: 17.8372,
                source: "TwelveData_ws",
                start_ms: 1720597405000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1720597404764,
          },
          {
            leverage: 0.05500000000000001,
            order_type: "LONG",
            order_uuid: "f6431e8c-45fd-43d5-b1c3-afff3a8bfa3b",
            price: 17.81594,
            price_sources: [
              {
                close: 17.81594,
                high: 17.81594,
                lag_ms: 184,
                low: 17.81594,
                open: 17.81594,
                source: "Polygon_ws",
                start_ms: 1720600303999,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 17.81594,
                websocket: true,
              },
              {
                close: 17.811,
                high: 17.811,
                lag_ms: 185,
                low: 17.811,
                open: 17.811,
                source: "TwelveData_ws",
                start_ms: 1720600304000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1720600303815,
          },
          {
            leverage: 0.10500000000000001,
            order_type: "LONG",
            order_uuid: "a500853f-52ec-427a-8fdb-3fd5cefc144c",
            price: 17.77711,
            price_sources: [
              {
                close: 17.77711,
                high: 17.77711,
                lag_ms: 182,
                low: 17.77711,
                open: 17.77711,
                source: "TwelveData_ws",
                start_ms: 1720605859000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
              {
                close: 17.77699,
                high: 17.77699,
                lag_ms: 183,
                low: 17.77699,
                open: 17.77699,
                source: "Polygon_ws",
                start_ms: 1720605858999,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 17.77699,
                websocket: true,
              },
            ],
            processed_ms: 1720605859182,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "cda43538-6020-47ba-8b55-e5ec9d58b3cf",
            price: 17.8011,
            price_sources: [
              {
                close: 17.8011,
                high: 17.8011,
                lag_ms: 485,
                low: 17.8011,
                open: 17.8011,
                source: "TwelveData_ws",
                start_ms: 1720606329000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
              {
                close: 17.80215,
                high: 17.80215,
                lag_ms: 486,
                low: 17.80215,
                open: 17.80215,
                source: "Polygon_ws",
                start_ms: 1720606328999,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 17.80215,
                websocket: true,
              },
            ],
            processed_ms: 1720606329485,
          },
        ],
        position_type: "FLAT",
        position_uuid: "100c91d6-047d-41db-aa28-0a86dad5361b",
        return_at_close: 0.9999707405220931,
        trade_pair: ["USDMXN", "USD/MXN", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 17.80437,
        close_ms: 1720608063745,
        current_return: 1.0000768912351294,
        initial_entry_price: 17.80437,
        is_closed_position: true,
        miner_hotkey: "5F7ThFe3ZRxTVFjz4M8ciTHbcVAdVDHLZ7SDyMgdFJpdLdEm",
        net_leverage: 0.0,
        open_ms: 1720606362609,
        orders: [
          {
            leverage: 0.05,
            order_type: "LONG",
            order_uuid: "69fe9152-bcb7-4b21-b003-16fb33453ab4",
            price: 17.80437,
            price_sources: [
              {
                close: 17.80437,
                high: 17.80437,
                lag_ms: 420,
                low: 17.80437,
                open: 17.80437,
                source: "Polygon_ws",
                start_ms: 1720606362999,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 17.80437,
                websocket: true,
              },
              {
                close: 17.808,
                high: 17.808,
                lag_ms: 421,
                low: 17.808,
                open: 17.808,
                source: "TwelveData_ws",
                start_ms: 1720606363000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1720606362579,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "f20c87dd-630b-4f62-beff-eaea0437568a",
            price: 17.83175,
            price_sources: [
              {
                close: 17.83175,
                high: 17.83175,
                lag_ms: 254,
                low: 17.83175,
                open: 17.83175,
                source: "Polygon_ws",
                start_ms: 1720608063999,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 17.83175,
                websocket: true,
              },
              {
                close: 17.83157,
                high: 17.83157,
                lag_ms: 255,
                low: 17.83157,
                open: 17.83157,
                source: "TwelveData_ws",
                start_ms: 1720608064000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1720608063745,
          },
        ],
        position_type: "FLAT",
        position_uuid: "e658565a-dd8c-424c-bba2-4e069444bb25",
        return_at_close: 1.00007339096601,
        trade_pair: ["USDMXN", "USD/MXN", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 17.791544155844157,
        close_ms: 1720616312989,
        current_return: 1.0000516354622617,
        initial_entry_price: 17.83135,
        is_closed_position: true,
        miner_hotkey: "5F7ThFe3ZRxTVFjz4M8ciTHbcVAdVDHLZ7SDyMgdFJpdLdEm",
        net_leverage: 0.0,
        open_ms: 1720608095214,
        orders: [
          {
            leverage: 0.05,
            order_type: "LONG",
            order_uuid: "89ee2151-a6c4-4189-8bc6-26735cb69062",
            price: 17.83135,
            price_sources: [
              {
                close: 17.83135,
                high: 17.83135,
                lag_ms: 39,
                low: 17.83135,
                open: 17.83135,
                source: "Polygon_ws",
                start_ms: 1720608093999,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 17.83135,
                websocket: true,
              },
              {
                close: 17.827,
                high: 17.827,
                lag_ms: 40,
                low: 17.827,
                open: 17.827,
                source: "TwelveData_ws",
                start_ms: 1720608094000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1720608093960,
          },
          {
            leverage: 0.05500000000000001,
            order_type: "LONG",
            order_uuid: "136d5f9a-1798-4d50-a17b-133de298deb1",
            price: 17.80855,
            price_sources: [
              {
                close: 17.80855,
                high: 17.80855,
                lag_ms: 63,
                low: 17.80855,
                open: 17.80855,
                source: "Polygon_ws",
                start_ms: 1720610918999,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 17.80855,
                websocket: true,
              },
              {
                close: 17.80821,
                high: 17.80821,
                lag_ms: 64,
                low: 17.80821,
                open: 17.80821,
                source: "TwelveData_ws",
                start_ms: 1720610919000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1720610918936,
          },
          {
            leverage: 0.126,
            order_type: "LONG",
            order_uuid: "b34678e7-23c0-4017-ac19-793f89d88a37",
            price: 17.768325,
            price_sources: [
              {
                close: 17.768325,
                high: 17.768325,
                lag_ms: 268,
                low: 17.768325,
                open: 17.768325,
                source: "Polygon_ws",
                start_ms: 1720614544000,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 17.77455,
                websocket: true,
              },
              {
                close: 17.7719,
                high: 17.7719,
                lag_ms: 268,
                low: 17.7719,
                open: 17.7719,
                source: "TwelveData_ws",
                start_ms: 1720614544000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1720614544268,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "b14bb20f-4b6b-458a-a8d9-ca26d9d557f4",
            price: 17.79553,
            price_sources: [
              {
                close: 17.79553,
                high: 17.79553,
                lag_ms: 10,
                low: 17.79553,
                open: 17.79553,
                source: "Polygon_ws",
                start_ms: 1720616312999,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 17.79553,
                websocket: true,
              },
              {
                close: 17.79607,
                high: 17.79607,
                lag_ms: 11,
                low: 17.79607,
                open: 17.79607,
                source: "TwelveData_ws",
                start_ms: 1720616313000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1720616312989,
          },
        ],
        position_type: "FLAT",
        position_uuid: "ae873233-6cfd-4729-b496-5a54cd5d3723",
        return_at_close: 1.0000354646273164,
        trade_pair: ["USDMXN", "USD/MXN", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 17.7825,
        close_ms: 1720618540590,
        current_return: 1.0000946154927597,
        initial_entry_price: 17.7825,
        is_closed_position: true,
        miner_hotkey: "5F7ThFe3ZRxTVFjz4M8ciTHbcVAdVDHLZ7SDyMgdFJpdLdEm",
        net_leverage: 0.0,
        open_ms: 1720616343447,
        orders: [
          {
            leverage: 0.05,
            order_type: "LONG",
            order_uuid: "72ee30b4-4fb9-44c3-8549-bac5f516b597",
            price: 17.7825,
            price_sources: [
              {
                close: 17.7825,
                high: 17.7825,
                lag_ms: 408,
                low: 17.7825,
                open: 17.7825,
                source: "Polygon_ws",
                start_ms: 1720616343000,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 17.7825,
                websocket: true,
              },
              {
                close: 17.791,
                high: 17.791,
                lag_ms: 592,
                low: 17.791,
                open: 17.791,
                source: "TwelveData_ws",
                start_ms: 1720616344000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1720616343408,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "412abaa1-65f3-4f77-bea7-da04167e74b7",
            price: 17.81615,
            price_sources: [
              {
                close: 17.81615,
                high: 17.81615,
                lag_ms: 409,
                low: 17.81615,
                open: 17.81615,
                source: "Polygon_ws",
                start_ms: 1720618540999,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 17.81615,
                websocket: true,
              },
              {
                close: 17.81721,
                high: 17.81721,
                lag_ms: 410,
                low: 17.81721,
                open: 17.81721,
                source: "TwelveData_ws",
                start_ms: 1720618541000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1720618540590,
          },
        ],
        position_type: "FLAT",
        position_uuid: "a6e5a982-3dc5-4705-8fc1-faac878791d3",
        return_at_close: 1.0000911151616054,
        trade_pair: ["USDMXN", "USD/MXN", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 17.81115,
        close_ms: 1720623110213,
        current_return: 1.00008597142801,
        initial_entry_price: 17.81115,
        is_closed_position: true,
        miner_hotkey: "5F7ThFe3ZRxTVFjz4M8ciTHbcVAdVDHLZ7SDyMgdFJpdLdEm",
        net_leverage: 0.0,
        open_ms: 1720618569844,
        orders: [
          {
            leverage: 0.05,
            order_type: "LONG",
            order_uuid: "99c198de-679f-40fe-9345-62560c351a61",
            price: 17.81115,
            price_sources: [
              {
                close: 17.81115,
                high: 17.81115,
                lag_ms: 236,
                low: 17.81115,
                open: 17.81115,
                source: "Polygon_ws",
                start_ms: 1720618570000,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 17.81115,
                websocket: true,
              },
              {
                close: 17.81184,
                high: 17.81184,
                lag_ms: 236,
                low: 17.81184,
                open: 17.81184,
                source: "TwelveData_ws",
                start_ms: 1720618570000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1720618569764,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "03195c9b-51f5-4746-8174-df3a9da4ceca",
            price: 17.841775,
            price_sources: [
              {
                close: 17.841775,
                high: 17.841775,
                lag_ms: 213,
                low: 17.841775,
                open: 17.841775,
                source: "Polygon_ws",
                start_ms: 1720623110000,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 17.8352,
                websocket: true,
              },
              {
                close: 17.847,
                high: 17.847,
                lag_ms: 213,
                low: 17.847,
                open: 17.847,
                source: "TwelveData_ws",
                start_ms: 1720623110000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
              {
                close: 17.8352,
                high: 17.8352,
                lag_ms: 213,
                low: 17.8352,
                open: 17.8352,
                source: "Polygon_rest",
                start_ms: 1720623110000,
                timespan_ms: 1000,
                volume: 1.0,
                vwap: null,
                websocket: false,
              },
              {
                close: 17.8441,
                high: 17.8485,
                lag_ms: 350214,
                low: 17.8411,
                open: 17.8411,
                source: "TwelveData_rest",
                start_ms: 1720622700000,
                timespan_ms: 60000,
                volume: null,
                vwap: null,
                websocket: false,
              },
            ],
            processed_ms: 1720623110213,
          },
        ],
        position_type: "FLAT",
        position_uuid: "127dcd57-e8d7-4ae4-9c7a-500bbae0a4c1",
        return_at_close: 1.00008247112711,
        trade_pair: ["USDMXN", "USD/MXN", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 3386.9000000000005,
        close_ms: 0,
        current_return: 0.996310047536095,
        initial_entry_price: 3386.9,
        is_closed_position: false,
        miner_hotkey: "5F7ThFe3ZRxTVFjz4M8ciTHbcVAdVDHLZ7SDyMgdFJpdLdEm",
        net_leverage: 0.05,
        open_ms: 1719596225115,
        orders: [
          {
            leverage: 0.05,
            order_type: "LONG",
            order_uuid: "97377cfb-adbf-4ffc-b7d6-cc9f4e99ba48",
            price: 3386.9,
            price_sources: [],
            processed_ms: 1719596222703,
          },
        ],
        position_type: "LONG",
        position_uuid: "bbd289d3-7e88-42d0-a4b5-b3b9600a3a1b",
        return_at_close: 0.9962851397849065,
        trade_pair: ["ETHUSD", "ETH/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 57719.7,
        close_ms: 0,
        current_return: 1.002530418557269,
        initial_entry_price: 57719.7,
        is_closed_position: false,
        miner_hotkey: "5F7ThFe3ZRxTVFjz4M8ciTHbcVAdVDHLZ7SDyMgdFJpdLdEm",
        net_leverage: 0.35,
        open_ms: 1720094614236,
        orders: [
          {
            leverage: 0.35,
            order_type: "LONG",
            order_uuid: "67460d00-f9a1-4f51-bc1f-a8b2c4257506",
            price: 57719.7,
            price_sources: [
              {
                close: 57719.7,
                high: 57760.0,
                lag_ms: 50,
                low: 57719.7,
                open: 57719.7,
                source: "Polygon_ws",
                start_ms: 1720094614000,
                timespan_ms: 0,
                volume: 1.17550782,
                vwap: 57725.1145,
                websocket: true,
              },
              {
                close: 57672.2,
                high: 57672.2,
                lag_ms: 1950,
                low: 57672.2,
                open: 57672.2,
                source: "TwelveData_ws",
                start_ms: 1720094616000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
              {
                close: 57673.24,
                high: 57673.24,
                lag_ms: 3051,
                low: 57672.59,
                open: 57672.59,
                source: "Polygon_rest",
                start_ms: 1720094610000,
                timespan_ms: 1000,
                volume: 0.00039316,
                vwap: 57672.7332,
                websocket: false,
              },
              {
                close: 57602.62,
                high: 57662.81,
                lag_ms: 94051,
                low: 57595.0,
                open: 57651.96,
                source: "TwelveData_rest",
                start_ms: 1720094460000,
                timespan_ms: 60000,
                volume: null,
                vwap: null,
                websocket: false,
              },
            ],
            processed_ms: 1720094614050,
          },
        ],
        position_type: "LONG",
        position_uuid: "52f0dbe0-01d5-4e6d-8579-532940617dbf",
        return_at_close: 1.0023549757340213,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 17.83430909090909,
        close_ms: 0,
        current_return: 0.999887149878857,
        initial_entry_price: 17.84668,
        is_closed_position: false,
        miner_hotkey: "5F7ThFe3ZRxTVFjz4M8ciTHbcVAdVDHLZ7SDyMgdFJpdLdEm",
        net_leverage: 0.11,
        open_ms: 1720623145647,
        orders: [
          {
            leverage: 0.05,
            order_type: "LONG",
            order_uuid: "e1e44584-afbb-48bf-892e-a2c2d57206a0",
            price: 17.84668,
            price_sources: [
              {
                close: 17.84668,
                high: 17.84668,
                lag_ms: 386,
                low: 17.84668,
                open: 17.84668,
                source: "Polygon_ws",
                start_ms: 1720623146000,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 17.84668,
                websocket: true,
              },
              {
                close: 17.84695,
                high: 17.84695,
                lag_ms: 386,
                low: 17.84695,
                open: 17.84695,
                source: "TwelveData_ws",
                start_ms: 1720623146000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1720623145614,
          },
          {
            leverage: 0.06,
            order_type: "LONG",
            order_uuid: "5a8049dc-599d-47f5-9277-170138146ff3",
            price: 17.824,
            price_sources: [
              {
                close: 17.824,
                high: 17.824,
                lag_ms: 490,
                low: 17.824,
                open: 17.824,
                source: "Polygon_ws",
                start_ms: 1720628126000,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 17.824,
                websocket: true,
              },
              {
                close: 17.82411,
                high: 17.82411,
                lag_ms: 490,
                low: 17.82411,
                open: 17.82411,
                source: "TwelveData_ws",
                start_ms: 1720628126000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1720628126490,
          },
        ],
        position_type: "LONG",
        position_uuid: "098e07e4-9e81-4bb6-8021-80185d34e250",
        return_at_close: 0.99988330031333,
        trade_pair: ["USDMXN", "USD/MXN", 7e-5, 0.001, 500],
      },
    ],
    thirty_day_returns: 1.000824077903687,
  },
  "5F9ZdSs3aHRQAoFZSoS6hdQeRbMKNhRoceVrHoSDnjdeQFUY": {
    all_time_returns: 1.0,
    n_positions: 0,
    percentage_profitable: 0.0,
    positions: [
      {
        average_entry_price: 3371.7,
        close_ms: 0,
        current_return: 0.9930376368004271,
        initial_entry_price: 3371.7,
        is_closed_position: false,
        miner_hotkey: "5F9ZdSs3aHRQAoFZSoS6hdQeRbMKNhRoceVrHoSDnjdeQFUY",
        net_leverage: 0.1,
        open_ms: 1718389489398,
        orders: [
          {
            leverage: 0.1,
            order_type: "LONG",
            order_uuid: "e1f6b4ff-d7ed-472d-a05c-3163b4331dad",
            price: 3371.7,
            price_sources: [],
            processed_ms: 1718389488488,
          },
        ],
        position_type: "LONG",
        position_uuid: "35bf7fae-56a6-46dc-b210-44e3b1c617a5",
        return_at_close: 0.9929879849185871,
        trade_pair: ["ETHUSD", "ETH/USD", 0.003, 0.001, 20],
      },
    ],
    thirty_day_returns: 1.0,
  },
  "5FA3r1bcPJwZVJBXJzXkfFAXMMDo48Fhqy7XVaZWGSRkEXms": {
    all_time_returns: 1.0,
    n_positions: 0,
    percentage_profitable: 0.0,
    positions: [
      {
        average_entry_price: 3412.91,
        close_ms: 0,
        current_return: 0.9991914231550202,
        initial_entry_price: 3412.91,
        is_closed_position: false,
        miner_hotkey: "5FA3r1bcPJwZVJBXJzXkfFAXMMDo48Fhqy7XVaZWGSRkEXms",
        net_leverage: 0.01,
        open_ms: 1719341116453,
        orders: [
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "5e5c8aa8-9aa6-406a-834f-9d5bcf3cfb2b",
            price: 3412.91,
            price_sources: [],
            processed_ms: 1719341115807,
          },
        ],
        position_type: "LONG",
        position_uuid: "050c1fec-c9b5-4160-a859-19f69fc28846",
        return_at_close: 0.9991864271979044,
        trade_pair: ["ETHUSD", "ETH/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 173.46,
        close_ms: 0,
        current_return: 0.9967509512279489,
        initial_entry_price: 173.46,
        is_closed_position: false,
        miner_hotkey: "5FA3r1bcPJwZVJBXJzXkfFAXMMDo48Fhqy7XVaZWGSRkEXms",
        net_leverage: -0.36,
        open_ms: 1719843066731,
        orders: [
          {
            leverage: -0.36,
            order_type: "SHORT",
            order_uuid: "80bf3051-4169-4b56-91d4-9eb29b5f9fc5",
            price: 173.46,
            price_sources: [],
            processed_ms: 1719843066623,
          },
        ],
        position_type: "SHORT",
        position_uuid: "e2b4bbd4-104f-49cb-a351-10e03c92ef97",
        return_at_close: 0.9967383921659634,
        trade_pair: ["EURJPY", "EUR/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 61958.24166666667,
        close_ms: 0,
        current_return: 0.9992603083208761,
        initial_entry_price: 61991.91,
        is_closed_position: false,
        miner_hotkey: "5FA3r1bcPJwZVJBXJzXkfFAXMMDo48Fhqy7XVaZWGSRkEXms",
        net_leverage: 0.012,
        open_ms: 1719341385211,
        orders: [
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "e4db41ce-c9b6-4176-b11c-4bea976bf220",
            price: 61991.91,
            price_sources: [],
            processed_ms: 1719341384593,
          },
          {
            leverage: 0.001,
            order_type: "LONG",
            order_uuid: "cbdd9274-206b-46c2-a54b-fae3abca2994",
            price: 61881.1,
            price_sources: [],
            processed_ms: 1719343950841,
          },
          {
            leverage: 0.001,
            order_type: "LONG",
            order_uuid: "ad953446-a6b1-44e1-b71a-545aa9eb971d",
            price: 61698.7,
            price_sources: [],
            processed_ms: 1719359207007,
          },
        ],
        position_type: "LONG",
        position_uuid: "80098e13-a923-4dbb-a3e6-2e5fe8b2dfba",
        return_at_close: 0.9992543127590262,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
    ],
    thirty_day_returns: 1.0,
  },
  "5FBsRnSdBZJ8eqbjpKBFVEqa21o7fuwVXKX4sLEeBK1DzJLN": {
    all_time_returns: 1.0,
    n_positions: 0,
    percentage_profitable: 0.0,
    positions: [
      {
        average_entry_price: 65117.44999999999,
        close_ms: 1711213086473,
        current_return: 0.9943139665327803,
        initial_entry_price: 65117.45,
        is_closed_position: true,
        miner_hotkey: "5FBsRnSdBZJ8eqbjpKBFVEqa21o7fuwVXKX4sLEeBK1DzJLN",
        net_leverage: 0.0,
        open_ms: 1711209550239,
        orders: [
          {
            leverage: -0.6,
            order_type: "SHORT",
            order_uuid: "b6242285-136a-4b35-aeba-9801cbba8783",
            price: 65117.45,
            price_sources: [],
            processed_ms: 1711209550239,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "f086ffd6-fddf-453a-a262-5dc3a2009024",
            price: 65734.55,
            price_sources: [],
            processed_ms: 1711213086473,
          },
        ],
        position_type: "FLAT",
        position_uuid: "f3310e91-5073-4a07-bb7a-a8d0f2333158",
        return_at_close: 0.9931207897729409,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 65686.49,
        close_ms: 1711216793175,
        current_return: 1.000373958176179,
        initial_entry_price: 65686.49,
        is_closed_position: true,
        miner_hotkey: "5FBsRnSdBZJ8eqbjpKBFVEqa21o7fuwVXKX4sLEeBK1DzJLN",
        net_leverage: 0.0,
        open_ms: 1711213172498,
        orders: [
          {
            leverage: -0.6,
            order_type: "SHORT",
            order_uuid: "6fc0d860-8090-46b3-a207-ed53f383e1b3",
            price: 65686.49,
            price_sources: [],
            processed_ms: 1711213172497,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "b64274fe-a506-46de-b8fd-174ffacc86a1",
            price: 65645.55,
            price_sources: [],
            processed_ms: 1711216793175,
          },
        ],
        position_type: "FLAT",
        position_uuid: "7ac6a357-1f91-46a7-b396-a3d1ef09a5bd",
        return_at_close: 0.9991735094263676,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 65663.21,
        close_ms: 1711220402322,
        current_return: 1.0021581948247733,
        initial_entry_price: 65663.21,
        is_closed_position: true,
        miner_hotkey: "5FBsRnSdBZJ8eqbjpKBFVEqa21o7fuwVXKX4sLEeBK1DzJLN",
        net_leverage: 0.0,
        open_ms: 1711216812746,
        orders: [
          {
            leverage: -0.6,
            order_type: "SHORT",
            order_uuid: "79d0b521-e1c5-4ac0-9cb0-2188781d822e",
            price: 65663.21,
            price_sources: [],
            processed_ms: 1711216812746,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "cfb6d549-ce72-41aa-9ef9-f9067b130d42",
            price: 65427.02,
            price_sources: [],
            processed_ms: 1711220402322,
          },
        ],
        position_type: "FLAT",
        position_uuid: "aa22fc9d-5db1-4167-998d-0daca67805c5",
        return_at_close: 1.0009556049909836,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 65390.89,
        close_ms: 1711224071467,
        current_return: 1.0040695577013863,
        initial_entry_price: 65390.89,
        is_closed_position: true,
        miner_hotkey: "5FBsRnSdBZJ8eqbjpKBFVEqa21o7fuwVXKX4sLEeBK1DzJLN",
        net_leverage: 0.0,
        open_ms: 1711220488680,
        orders: [
          {
            leverage: -0.6,
            order_type: "SHORT",
            order_uuid: "deddd530-4694-4cd3-a9cc-21942c86860b",
            price: 65390.89,
            price_sources: [],
            processed_ms: 1711220488679,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "d91a27e2-d7c7-420a-81f0-84e2c93ebaaf",
            price: 64947.37,
            price_sources: [],
            processed_ms: 1711224071467,
          },
        ],
        position_type: "FLAT",
        position_uuid: "fcd292d7-989f-42b8-95ec-719a2e38eeff",
        return_at_close: 1.0028646742321445,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 64889.05999999999,
        close_ms: 1711227732894,
        current_return: 0.9992104370135736,
        initial_entry_price: 64889.06,
        is_closed_position: true,
        miner_hotkey: "5FBsRnSdBZJ8eqbjpKBFVEqa21o7fuwVXKX4sLEeBK1DzJLN",
        net_leverage: 0.0,
        open_ms: 1711224176127,
        orders: [
          {
            leverage: -0.6,
            order_type: "SHORT",
            order_uuid: "cad1944f-06a9-4637-9c40-d62468c73f4e",
            price: 64889.06,
            price_sources: [],
            processed_ms: 1711224176126,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "470ca4e6-466a-40df-bc57-610a253e949e",
            price: 64974.45,
            price_sources: [],
            processed_ms: 1711227732894,
          },
        ],
        position_type: "FLAT",
        position_uuid: "b568d7ff-9ea6-4abc-87fd-f628ce6672c6",
        return_at_close: 0.9980113844891573,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 64919.12,
        close_ms: 1711231390764,
        current_return: 0.9993242052572494,
        initial_entry_price: 64919.12,
        is_closed_position: true,
        miner_hotkey: "5FBsRnSdBZJ8eqbjpKBFVEqa21o7fuwVXKX4sLEeBK1DzJLN",
        net_leverage: 0.0,
        open_ms: 1711227864553,
        orders: [
          {
            leverage: -0.6,
            order_type: "SHORT",
            order_uuid: "1944af2e-779e-4c7d-8121-2285de8c4fcd",
            price: 64919.12,
            price_sources: [],
            processed_ms: 1711227864553,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "3370e922-1f83-4ffc-b225-08796e657410",
            price: 64992.24,
            price_sources: [],
            processed_ms: 1711231390764,
          },
        ],
        position_type: "FLAT",
        position_uuid: "ed633289-75db-4dba-bc8c-9323f0ef75a4",
        return_at_close: 0.9981250162109407,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 64164.42999999999,
        close_ms: 1711253452367,
        current_return: 1.0,
        initial_entry_price: 64164.43,
        is_closed_position: true,
        miner_hotkey: "5FBsRnSdBZJ8eqbjpKBFVEqa21o7fuwVXKX4sLEeBK1DzJLN",
        net_leverage: 0.0,
        open_ms: 1711253432368,
        orders: [
          {
            leverage: 0.6,
            order_type: "LONG",
            order_uuid: "3482e4df-dbee-466b-bb74-bcf5571e73b8",
            price: 64164.43,
            price_sources: [],
            processed_ms: 1711253432367,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "fcbf3c84-d37f-4e61-b968-4a28aa1af97d",
            price: 64164.43,
            price_sources: [],
            processed_ms: 1711253452367,
          },
        ],
        position_type: "FLAT",
        position_uuid: "52d7869d-cd4d-4478-a1f2-b6427921e4d4",
        return_at_close: 0.9988,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 64366.36,
        close_ms: 1711264386709,
        current_return: 0.9957738017187859,
        initial_entry_price: 64366.36,
        is_closed_position: true,
        miner_hotkey: "5FBsRnSdBZJ8eqbjpKBFVEqa21o7fuwVXKX4sLEeBK1DzJLN",
        net_leverage: 0.0,
        open_ms: 1711260793572,
        orders: [
          {
            leverage: 1.25,
            order_type: "LONG",
            order_uuid: "c356aafc-93a7-4d31-bb8e-e011a1f1515f",
            price: 64366.36,
            price_sources: [],
            processed_ms: 1711260793570,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "c59b9e8a-b2cc-47a9-a443-7041c10d21e9",
            price: 64148.74,
            price_sources: [],
            processed_ms: 1711264386709,
          },
        ],
        position_type: "FLAT",
        position_uuid: "9ca69c2c-0f51-48c6-b34f-f76d60bb268f",
        return_at_close: 0.993284367214489,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 64167.79,
        close_ms: 1711268027867,
        current_return: 1.014806299235177,
        initial_entry_price: 64167.79,
        is_closed_position: true,
        miner_hotkey: "5FBsRnSdBZJ8eqbjpKBFVEqa21o7fuwVXKX4sLEeBK1DzJLN",
        net_leverage: 0.0,
        open_ms: 1711264474316,
        orders: [
          {
            leverage: 1.25,
            order_type: "LONG",
            order_uuid: "46b27ac7-6e3a-48a9-a2eb-b9a7dd7c2ac2",
            price: 64167.79,
            price_sources: [],
            processed_ms: 1711264474315,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "3307999a-5ab7-47ef-9915-e1627637ab62",
            price: 64927.86,
            price_sources: [],
            processed_ms: 1711268027867,
          },
        ],
        position_type: "FLAT",
        position_uuid: "0eb6eb27-9332-4f40-a238-4ba623ad290d",
        return_at_close: 1.012269283487089,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 64901.969999999994,
        close_ms: 1711271696512,
        current_return: 1.0007755927901727,
        initial_entry_price: 64901.97,
        is_closed_position: true,
        miner_hotkey: "5FBsRnSdBZJ8eqbjpKBFVEqa21o7fuwVXKX4sLEeBK1DzJLN",
        net_leverage: 0.0,
        open_ms: 1711268156733,
        orders: [
          {
            leverage: 1.25,
            order_type: "LONG",
            order_uuid: "936e41d9-3e47-4f58-b6fc-629ef1eca5cb",
            price: 64901.97,
            price_sources: [],
            processed_ms: 1711268156732,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "85731102-3b8d-4097-8e66-4f9d006a33f5",
            price: 64942.24,
            price_sources: [],
            processed_ms: 1711271696512,
          },
        ],
        position_type: "FLAT",
        position_uuid: "004530c1-d4ac-42f6-8eb6-f052a7ca5cfd",
        return_at_close: 0.9982736538081973,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 64997.08,
        close_ms: 1711275356709,
        current_return: 0.9985435884196643,
        initial_entry_price: 64997.08,
        is_closed_position: true,
        miner_hotkey: "5FBsRnSdBZJ8eqbjpKBFVEqa21o7fuwVXKX4sLEeBK1DzJLN",
        net_leverage: 0.0,
        open_ms: 1711271783556,
        orders: [
          {
            leverage: 1.25,
            order_type: "LONG",
            order_uuid: "43b7fcb3-2897-4c3e-9cf0-b54abe2b8e22",
            price: 64997.08,
            price_sources: [],
            processed_ms: 1711271783554,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "b91b5c47-aa36-47c9-80d7-7d58d18859d3",
            price: 64921.35,
            price_sources: [],
            processed_ms: 1711275356709,
          },
        ],
        position_type: "FLAT",
        position_uuid: "3cf31bbf-b863-4460-a888-6f0d268cc4d6",
        return_at_close: 0.9960472294486151,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 64985.65,
        close_ms: 1711279012762,
        current_return: 1.0030635609553802,
        initial_entry_price: 64985.65,
        is_closed_position: true,
        miner_hotkey: "5FBsRnSdBZJ8eqbjpKBFVEqa21o7fuwVXKX4sLEeBK1DzJLN",
        net_leverage: 0.0,
        open_ms: 1711275443463,
        orders: [
          {
            leverage: 1.25,
            order_type: "LONG",
            order_uuid: "cd61a964-4ec1-4f57-a884-e83f086368d3",
            price: 64985.65,
            price_sources: [],
            processed_ms: 1711275443462,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "97879f04-c1a1-45ff-81d6-b1eb30a1a39d",
            price: 65144.92,
            price_sources: [],
            processed_ms: 1711279012762,
          },
        ],
        position_type: "FLAT",
        position_uuid: "1f376aa5-ffbb-4c6d-993f-abd3ef12b592",
        return_at_close: 1.0005559020529917,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 65136.37000000001,
        close_ms: 1711280981648,
        current_return: 1.0023909453351483,
        initial_entry_price: 65136.37,
        is_closed_position: true,
        miner_hotkey: "5FBsRnSdBZJ8eqbjpKBFVEqa21o7fuwVXKX4sLEeBK1DzJLN",
        net_leverage: 0.0,
        open_ms: 1711279255022,
        orders: [
          {
            leverage: 1.25,
            order_type: "LONG",
            order_uuid: "7534aca3-430e-44bb-87d7-57f407fed208",
            price: 65136.37,
            price_sources: [],
            processed_ms: 1711279255021,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "a62a73e6-0ce7-4b4f-abe3-4d289d1fdc0c",
            price: 65260.96,
            price_sources: [],
            processed_ms: 1711280981648,
          },
        ],
        position_type: "FLAT",
        position_uuid: "6b646aaf-c32d-4671-b2b9-88b18de3e883",
        return_at_close: 0.9998849679718105,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 65343.36,
        close_ms: 1711284635630,
        current_return: 1.0057947739448967,
        initial_entry_price: 65343.36,
        is_closed_position: true,
        miner_hotkey: "5FBsRnSdBZJ8eqbjpKBFVEqa21o7fuwVXKX4sLEeBK1DzJLN",
        net_leverage: 0.0,
        open_ms: 1711282841926,
        orders: [
          {
            leverage: 1.25,
            order_type: "LONG",
            order_uuid: "721b849d-36ac-4933-bad7-56388f15e05d",
            price: 65343.36,
            price_sources: [],
            processed_ms: 1711282841925,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "31e31569-c7da-4c38-9d63-a923950f45fa",
            price: 65646.28,
            price_sources: [],
            processed_ms: 1711284635630,
          },
        ],
        position_type: "FLAT",
        position_uuid: "c4d30310-fcd4-4360-b761-61eba872e362",
        return_at_close: 1.0032802870100346,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 65062.05,
        close_ms: 1711306839716,
        current_return: 1.014593376630463,
        initial_entry_price: 65062.05,
        is_closed_position: true,
        miner_hotkey: "5FBsRnSdBZJ8eqbjpKBFVEqa21o7fuwVXKX4sLEeBK1DzJLN",
        net_leverage: 0.0,
        open_ms: 1711301113613,
        orders: [
          {
            leverage: 1.25,
            order_type: "LONG",
            order_uuid: "01e4b279-1657-48b3-851e-d05bb15ce555",
            price: 65062.05,
            price_sources: [],
            processed_ms: 1711301113612,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "6d1b6513-08a2-48e3-a7fe-9c220134e899",
            price: 65821.63,
            price_sources: [],
            processed_ms: 1711306839716,
          },
        ],
        position_type: "FLAT",
        position_uuid: "03f34381-54d5-40bb-8e09-e24fed25f5d0",
        return_at_close: 1.0120568931888867,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 63261.91000000009,
        close_ms: 1711320824608,
        current_return: 0.9965625112137915,
        initial_entry_price: 66324.58,
        is_closed_position: true,
        miner_hotkey: "5FBsRnSdBZJ8eqbjpKBFVEqa21o7fuwVXKX4sLEeBK1DzJLN",
        net_leverage: 0.0,
        open_ms: 1711314304597,
        orders: [
          {
            leverage: -1.25,
            order_type: "SHORT",
            order_uuid: "c907d05a-8e0b-48db-8ade-3584003c7b4d",
            price: 66324.58,
            price_sources: [],
            processed_ms: 1711314304596,
          },
          {
            leverage: 0.625,
            order_type: "LONG",
            order_uuid: "6637178c-a037-4ac3-923f-6c9a7b2d95f8",
            price: 66402.93,
            price_sources: [],
            processed_ms: 1711315236356,
          },
          {
            leverage: 0.125,
            order_type: "LONG",
            order_uuid: "0c3b8bcd-921e-4a90-aa2e-8a84ffa6cbc4",
            price: 66321.98,
            price_sources: [],
            processed_ms: 1711316168622,
          },
          {
            leverage: 0.125,
            order_type: "LONG",
            order_uuid: "b4794eec-ddeb-4a01-bba0-20708b7e148d",
            price: 66371.38,
            price_sources: [],
            processed_ms: 1711317099632,
          },
          {
            leverage: 0.125,
            order_type: "LONG",
            order_uuid: "9e0bdf5a-c2e2-4397-a74e-5a886050b47f",
            price: 66736.59,
            price_sources: [],
            processed_ms: 1711318036624,
          },
          {
            leverage: 0.125,
            order_type: "LONG",
            order_uuid: "796b8b89-41e7-4c45-b77e-28ff95f0de37",
            price: 66715.65,
            price_sources: [],
            processed_ms: 1711319015510,
          },
          {
            leverage: 0.0625,
            order_type: "LONG",
            order_uuid: "8f65033a-9d48-47bb-abb0-94471c03f94a",
            price: 66909.19,
            price_sources: [],
            processed_ms: 1711319900700,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "a24a5072-4f12-468e-81ec-5bab61760c7e",
            price: 66909.75,
            price_sources: [],
            processed_ms: 1711320824608,
          },
        ],
        position_type: "FLAT",
        position_uuid: "6a897264-5fc2-488d-a25e-d3c6f6722050",
        return_at_close: 0.994071104935757,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 75036.7000000003,
        close_ms: 1711327342820,
        current_return: 1.003889047437213,
        initial_entry_price: 67329.63,
        is_closed_position: true,
        miner_hotkey: "5FBsRnSdBZJ8eqbjpKBFVEqa21o7fuwVXKX4sLEeBK1DzJLN",
        net_leverage: 0.0,
        open_ms: 1711322711328,
        orders: [
          {
            leverage: -1.25,
            order_type: "SHORT",
            order_uuid: "af02b71b-6e30-4bb7-b1b3-193a05cd3d11",
            price: 67329.63,
            price_sources: [],
            processed_ms: 1711322711326,
          },
          {
            leverage: 0.625,
            order_type: "LONG",
            order_uuid: "1affaa8b-df49-4ad0-b861-2069b1e30e12",
            price: 67178.11,
            price_sources: [],
            processed_ms: 1711323621066,
          },
          {
            leverage: 0.3125,
            order_type: "LONG",
            order_uuid: "7881221b-fae7-466c-8367-1d2760a81c58",
            price: 67255.01,
            price_sources: [],
            processed_ms: 1711324552651,
          },
          {
            leverage: 0.15625,
            order_type: "LONG",
            order_uuid: "59d6942d-3442-4370-a277-a512927c583b",
            price: 66841.12,
            price_sources: [],
            processed_ms: 1711325475563,
          },
          {
            leverage: 0.125,
            order_type: "LONG",
            order_uuid: "c3ca0fff-8bdd-4f0f-b467-4f5917d4701e",
            price: 66957.65,
            price_sources: [],
            processed_ms: 1711326429834,
          },
          {
            leverage: 0.125,
            order_type: "LONG",
            order_uuid: "6fba7f6c-4fbf-4f9d-a8a8-bb095ce97600",
            price: 66657.56,
            price_sources: [],
            processed_ms: 1711327342820,
          },
        ],
        position_type: "FLAT",
        position_uuid: "3555733a-f7bc-48ae-b69f-b4fa7c0bb462",
        return_at_close: 1.00137932481862,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 58339.07555555647,
        close_ms: 1711343453013,
        current_return: 0.9975612154583896,
        initial_entry_price: 66569.71,
        is_closed_position: true,
        miner_hotkey: "5FBsRnSdBZJ8eqbjpKBFVEqa21o7fuwVXKX4sLEeBK1DzJLN",
        net_leverage: 0.0,
        open_ms: 1711328272785,
        orders: [
          {
            leverage: -1.25,
            order_type: "SHORT",
            order_uuid: "d3f38947-374a-42f2-98bb-abc273cef2f0",
            price: 66569.71,
            price_sources: [],
            processed_ms: 1711328272783,
          },
          {
            leverage: 0.125,
            order_type: "LONG",
            order_uuid: "4696ba42-401b-4f0a-81fe-c3fa82cdfbf9",
            price: 66732.11,
            price_sources: [],
            processed_ms: 1711329214649,
          },
          {
            leverage: 0.5625,
            order_type: "LONG",
            order_uuid: "3a73f9b4-4b67-4cd0-93d7-988fe26842e2",
            price: 66574.98,
            price_sources: [],
            processed_ms: 1711332478781,
          },
          {
            leverage: 0.28125,
            order_type: "LONG",
            order_uuid: "8419b10e-9964-46bb-893f-94dbcf2d7859",
            price: 66647.79,
            price_sources: [],
            processed_ms: 1711334294691,
          },
          {
            leverage: 0.140625,
            order_type: "LONG",
            order_uuid: "6f5b580e-9299-4125-b69d-efaffdaf0727",
            price: 66774.77,
            price_sources: [],
            processed_ms: 1711336131682,
          },
          {
            leverage: 0.0703125,
            order_type: "LONG",
            order_uuid: "b3aff9f0-eeb6-43e5-a91e-eeb37e2ab0fa",
            price: 66978.48,
            price_sources: [],
            processed_ms: 1711337963983,
          },
          {
            leverage: 0.03515625,
            order_type: "LONG",
            order_uuid: "63e76fef-840d-42d0-a4e7-27c5d8dce1ae",
            price: 67419.07,
            price_sources: [],
            processed_ms: 1711339817214,
          },
          {
            leverage: 0.017578125,
            order_type: "LONG",
            order_uuid: "beae5986-5a1c-4805-8b27-6622dc040f6d",
            price: 67253.3,
            price_sources: [],
            processed_ms: 1711341626849,
          },
          {
            leverage: 0.5869140625,
            order_type: "LONG",
            order_uuid: "db2a75ac-e71b-4d19-8b43-b76d89dbd253",
            price: 67574.94,
            price_sources: [],
            processed_ms: 1711343453013,
          },
        ],
        position_type: "FLAT",
        position_uuid: "3633c665-db85-412b-bca4-129686f3c744",
        return_at_close: 0.9950673124197437,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 65877.46000000036,
        close_ms: 1711363617043,
        current_return: 0.999962086150594,
        initial_entry_price: 67079.05,
        is_closed_position: true,
        miner_hotkey: "5FBsRnSdBZJ8eqbjpKBFVEqa21o7fuwVXKX4sLEeBK1DzJLN",
        net_leverage: 0.0,
        open_ms: 1711352621001,
        orders: [
          {
            leverage: -1.25,
            order_type: "SHORT",
            order_uuid: "d06de158-6929-4ae5-9b4e-4d5b25d45150",
            price: 67079.05,
            price_sources: [],
            processed_ms: 1711352620999,
          },
          {
            leverage: 0.625,
            order_type: "LONG",
            order_uuid: "a1e57c70-59b8-460c-8cbb-a6c02162e40b",
            price: 67115.99,
            price_sources: [],
            processed_ms: 1711354449920,
          },
          {
            leverage: 0.5,
            order_type: "LONG",
            order_uuid: "d5af5537-e0ea-4014-8dac-cb52390a5140",
            price: 67094.48,
            price_sources: [],
            processed_ms: 1711356279128,
          },
          {
            leverage: 0.1,
            order_type: "LONG",
            order_uuid: "77ae6425-8511-4242-9a7a-5196003e707b",
            price: 66821.97,
            price_sources: [],
            processed_ms: 1711358117427,
          },
          {
            leverage: 0.019999999999999997,
            order_type: "LONG",
            order_uuid: "b8786de5-40be-40f0-a6b7-f0a6417df131",
            price: 66958.84,
            price_sources: [],
            processed_ms: 1711359954161,
          },
          {
            leverage: 0.0024999999999999988,
            order_type: "LONG",
            order_uuid: "06985ccc-6e81-4611-ab9a-14dd87f57cf1",
            price: 67204.52,
            price_sources: [],
            processed_ms: 1711361784167,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "265cdf4d-a1f2-4175-84e3-6cee33901036",
            price: 66894.75,
            price_sources: [],
            processed_ms: 1711363617043,
          },
        ],
        position_type: "FLAT",
        position_uuid: "1f8a4990-a939-4093-9812-0761a6de219f",
        return_at_close: 0.9974621809352175,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 65609.57500000001,
        close_ms: 1711376601757,
        current_return: 1.0542565123213155,
        initial_entry_price: 66825.25,
        is_closed_position: true,
        miner_hotkey: "5FBsRnSdBZJ8eqbjpKBFVEqa21o7fuwVXKX4sLEeBK1DzJLN",
        net_leverage: 0.0,
        open_ms: 1711365456458,
        orders: [
          {
            leverage: 1.25,
            order_type: "LONG",
            order_uuid: "ea7154e7-1a0e-4bf4-87aa-876d5fd4d24a",
            price: 66825.25,
            price_sources: [],
            processed_ms: 1711365456456,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "eb9fb283-5664-4b9c-b53c-557b3feb378b",
            price: 67108.97,
            price_sources: [],
            processed_ms: 1711369841838,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "dba8e8fc-f161-402b-80cf-6a02dd073423",
            price: 67104.24,
            price_sources: [],
            processed_ms: 1711371670926,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "cdc188d7-bc2a-47e5-b49b-cbcf5e9c65d9",
            price: 67055.84,
            price_sources: [],
            processed_ms: 1711373506262,
          },
          {
            leverage: -1.0,
            order_type: "SHORT",
            order_uuid: "7de9a1b7-725f-49d7-94e0-df567411a30e",
            price: 68239.25,
            price_sources: [],
            processed_ms: 1711375339045,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "e05afe59-ef94-475b-b7df-198c50a62731",
            price: 69235.28,
            price_sources: [],
            processed_ms: 1711376601757,
          },
        ],
        position_type: "FLAT",
        position_uuid: "3c78337c-f9b4-4f7e-bf07-2c3f6fae6ac6",
        return_at_close: 1.0500394862720301,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 69368.89,
        close_ms: 1711376776491,
        current_return: 1.0018280240609299,
        initial_entry_price: 69368.89,
        is_closed_position: true,
        miner_hotkey: "5FBsRnSdBZJ8eqbjpKBFVEqa21o7fuwVXKX4sLEeBK1DzJLN",
        net_leverage: 0.0,
        open_ms: 1711376620892,
        orders: [
          {
            leverage: -0.8,
            order_type: "SHORT",
            order_uuid: "a52c5c97-98ec-49c3-b716-7cd1317fec2f",
            price: 69368.89,
            price_sources: [],
            processed_ms: 1711376620889,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "b3fc8438-72a0-4bdb-a7da-a3111152b1b5",
            price: 69210.38,
            price_sources: [],
            processed_ms: 1711376776491,
          },
        ],
        position_type: "FLAT",
        position_uuid: "406f7b43-e853-4f2b-b205-4d3c6a9cc781",
        return_at_close: 1.0002250992224324,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 69272.14,
        close_ms: 1711376927181,
        current_return: 1.0,
        initial_entry_price: 69272.14,
        is_closed_position: true,
        miner_hotkey: "5FBsRnSdBZJ8eqbjpKBFVEqa21o7fuwVXKX4sLEeBK1DzJLN",
        net_leverage: 0.0,
        open_ms: 1711376907866,
        orders: [
          {
            leverage: 1.25,
            order_type: "LONG",
            order_uuid: "528bd5b6-f4c7-4978-b947-90893b540150",
            price: 69272.14,
            price_sources: [],
            processed_ms: 1711376907864,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "cacef160-593b-4865-94ea-371c6eb2bd28",
            price: 69272.14,
            price_sources: [],
            processed_ms: 1711376927181,
          },
        ],
        position_type: "FLAT",
        position_uuid: "56108099-7dbc-4eb5-b072-eba62eaccb11",
        return_at_close: 0.9975,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 69238.52,
        close_ms: 1711384044984,
        current_return: 0.9927518670243096,
        initial_entry_price: 69238.52,
        is_closed_position: true,
        miner_hotkey: "5FBsRnSdBZJ8eqbjpKBFVEqa21o7fuwVXKX4sLEeBK1DzJLN",
        net_leverage: 0.0,
        open_ms: 1711378344212,
        orders: [
          {
            leverage: -1.25,
            order_type: "SHORT",
            order_uuid: "87cf0816-7f65-4f18-88bc-3e63c939d100",
            price: 69238.52,
            price_sources: [],
            processed_ms: 1711378344210,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "5671aa52-1bcb-4502-8ec9-d801948fc9f4",
            price: 69640.0,
            price_sources: [],
            processed_ms: 1711384044984,
          },
        ],
        position_type: "FLAT",
        position_uuid: "0fbdb00e-8433-4dfe-86e2-886b4be7827d",
        return_at_close: 0.9902699873567489,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 70384.94571428571,
        close_ms: 1711393215772,
        current_return: 1.0510656370458478,
        initial_entry_price: 70236.86,
        is_closed_position: true,
        miner_hotkey: "5FBsRnSdBZJ8eqbjpKBFVEqa21o7fuwVXKX4sLEeBK1DzJLN",
        net_leverage: 0.0,
        open_ms: 1711385867895,
        orders: [
          {
            leverage: 5.0,
            order_type: "LONG",
            order_uuid: "c95210dc-e161-4bad-a325-617849441f9a",
            price: 70236.86,
            price_sources: [],
            processed_ms: 1711385867893,
          },
          {
            leverage: -2.5,
            order_type: "SHORT",
            order_uuid: "3b7dc79e-4797-46f3-aab3-3ab0ba5a6ca2",
            price: 70707.34,
            price_sources: [],
            processed_ms: 1711387690184,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "d39bf642-eeb2-446d-80eb-48d46f076a0c",
            price: 70499.62,
            price_sources: [],
            processed_ms: 1711389495967,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "d5c35e0f-a5c0-46c8-a45d-3d03822acd7b",
            price: 70793.17,
            price_sources: [],
            processed_ms: 1711391350229,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "6e194d66-b716-402a-8ea6-32c838cb56ae",
            price: 70892.99,
            price_sources: [],
            processed_ms: 1711393129625,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "1db55518-3143-43f7-9d15-761d16a96f47",
            price: 70897.33,
            price_sources: [],
            processed_ms: 1711393215772,
          },
        ],
        position_type: "FLAT",
        position_uuid: "4cf88ace-58de-4433-8a61-9dc5d16430ce",
        return_at_close: 1.0363507181272058,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 70760.97210526316,
        close_ms: 1711401376701,
        current_return: 0.9540361668638687,
        initial_entry_price: 70578.97,
        is_closed_position: true,
        miner_hotkey: "5FBsRnSdBZJ8eqbjpKBFVEqa21o7fuwVXKX4sLEeBK1DzJLN",
        net_leverage: 0.0,
        open_ms: 1711394948572,
        orders: [
          {
            leverage: 5.0,
            order_type: "LONG",
            order_uuid: "d1f062cd-1b8f-41ec-ad39-feb700a98a64",
            price: 70578.97,
            price_sources: [],
            processed_ms: 1711394948570,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "4fdb7b64-6267-4d69-abff-60e3ba357209",
            price: 71041.83,
            price_sources: [],
            processed_ms: 1711396758956,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "3bc0254c-c167-4e43-9c55-4fb90fcd263c",
            price: 70886.55,
            price_sources: [],
            processed_ms: 1711398587312,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "c8e062b5-589d-49b6-9d90-3cc026d3bfdf",
            price: 70961.21,
            price_sources: [],
            processed_ms: 1711400408532,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "146ae565-54d5-469f-8a90-3230e1bbf26d",
            price: 70419.49,
            price_sources: [],
            processed_ms: 1711401376701,
          },
        ],
        position_type: "FLAT",
        position_uuid: "f6607556-5660-4986-9f30-9a4d3cfe9fd6",
        return_at_close: 0.9359094796934552,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 69725.4425,
        close_ms: 1711406654936,
        current_return: 1.0566535044932936,
        initial_entry_price: 69623.76,
        is_closed_position: true,
        miner_hotkey: "5FBsRnSdBZJ8eqbjpKBFVEqa21o7fuwVXKX4sLEeBK1DzJLN",
        net_leverage: 0.0,
        open_ms: 1711402230872,
        orders: [
          {
            leverage: 5.0,
            order_type: "LONG",
            order_uuid: "cd685cbb-1787-4446-ae96-29d25db0647c",
            price: 69623.76,
            price_sources: [],
            processed_ms: 1711402230870,
          },
          {
            leverage: -2.5,
            order_type: "SHORT",
            order_uuid: "5199e746-4da7-4265-a801-36ac8e9c7a0b",
            price: 69924.25,
            price_sources: [],
            processed_ms: 1711404048321,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "94efad22-1a95-4f2f-9be5-e87d545a2fd6",
            price: 70395.73,
            price_sources: [],
            processed_ms: 1711405868540,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "ab14910a-b179-4ec7-8d6f-23ed80325285",
            price: 70711.55,
            price_sources: [],
            processed_ms: 1711406654936,
          },
        ],
        position_type: "FLAT",
        position_uuid: "efac26cc-cd94-449c-8bf8-b0510a1cc7da",
        return_at_close: 1.0460869694483608,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 70307.90923076922,
        close_ms: 1711409983710,
        current_return: 0.9695657570654974,
        initial_entry_price: 70332.29,
        is_closed_position: true,
        miner_hotkey: "5FBsRnSdBZJ8eqbjpKBFVEqa21o7fuwVXKX4sLEeBK1DzJLN",
        net_leverage: 0.0,
        open_ms: 1711407677947,
        orders: [
          {
            leverage: 5.0,
            order_type: "LONG",
            order_uuid: "06272c5e-ee74-4259-94d7-8b28a0344be5",
            price: 70332.29,
            price_sources: [],
            processed_ms: 1711407677945,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "40a4da08-3049-40d1-8181-6bf52548cabf",
            price: 70226.64,
            price_sources: [],
            processed_ms: 1711409500469,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "d59a48ba-639c-46dc-b1bc-f8b38af4783e",
            price: 69978.6,
            price_sources: [],
            processed_ms: 1711409983710,
          },
        ],
        position_type: "FLAT",
        position_uuid: "ee3fa20f-2b5d-402d-9380-ecd9b5754d6b",
        return_at_close: 0.9569614022236459,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 62318.570857142935,
        close_ms: 1711438581913,
        current_return: 1.044547896739783,
        initial_entry_price: 69914.45,
        is_closed_position: true,
        miner_hotkey: "5FBsRnSdBZJ8eqbjpKBFVEqa21o7fuwVXKX4sLEeBK1DzJLN",
        net_leverage: 0.0,
        open_ms: 1711411302983,
        orders: [
          {
            leverage: 5.0,
            order_type: "LONG",
            order_uuid: "ca5c493c-f4ae-47c2-a298-62a8837a60a7",
            price: 69914.45,
            price_sources: [],
            processed_ms: 1711411302981,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "8e3a7880-51cb-43be-a87d-2c29190066e8",
            price: 70140.44,
            price_sources: [],
            processed_ms: 1711413160737,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "b73eced5-d647-4655-b50b-973caa5a06f0",
            price: 69843.02,
            price_sources: [],
            processed_ms: 1711414948731,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "8def4a03-52c1-485b-9ac0-5000cd1926bb",
            price: 70200.56,
            price_sources: [],
            processed_ms: 1711416768971,
          },
          {
            leverage: -4.75,
            order_type: "SHORT",
            order_uuid: "7c841e44-dfb3-419b-8a92-07a549ba5cc8",
            price: 70157.49,
            price_sources: [],
            processed_ms: 1711418594880,
          },
          {
            leverage: -2.375,
            order_type: "SHORT",
            order_uuid: "1d007888-25a9-41e4-bdd7-232204a7bb0b",
            price: 70256.27,
            price_sources: [],
            processed_ms: 1711420406885,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "c84396e4-f8a3-43e1-b6a5-59774930a3c3",
            price: 70466.56,
            price_sources: [],
            processed_ms: 1711422221166,
          },
          {
            leverage: -1.9375,
            order_type: "SHORT",
            order_uuid: "177f171a-4656-4a48-b5cb-ea8f80eff7d4",
            price: 70452.86,
            price_sources: [],
            processed_ms: 1711424037721,
          },
          {
            leverage: -0.96875,
            order_type: "SHORT",
            order_uuid: "96628f96-3d5c-4651-a7ef-ea84a5031306",
            price: 70582.65,
            price_sources: [],
            processed_ms: 1711425855797,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "0cfd99a5-c9d4-413e-89d5-7f59c2efc8dc",
            price: 70599.56,
            price_sources: [],
            processed_ms: 1711427672911,
          },
          {
            leverage: -1.234375,
            order_type: "SHORT",
            order_uuid: "75c2d298-44a1-4d9e-82a2-61dfc993111d",
            price: 70550.59,
            price_sources: [],
            processed_ms: 1711429485589,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "ad667268-a4e6-4268-be4c-02d005b88a94",
            price: 70354.71,
            price_sources: [],
            processed_ms: 1711431308477,
          },
          {
            leverage: -1.3671875,
            order_type: "SHORT",
            order_uuid: "756283d3-6b57-45f3-a914-2e82fe636550",
            price: 70332.83,
            price_sources: [],
            processed_ms: 1711433121886,
          },
          {
            leverage: -0.68359375,
            order_type: "SHORT",
            order_uuid: "96b95436-2f4c-445b-82cf-97d937d10160",
            price: 70582.55,
            price_sources: [],
            processed_ms: 1711434953974,
          },
          {
            leverage: -0.341796875,
            order_type: "SHORT",
            order_uuid: "087f942d-bcaa-4a68-8177-4807439b1b1d",
            price: 70800.28,
            price_sources: [],
            processed_ms: 1711436754785,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "ba5856a5-d8e1-4f5e-9bda-b07fe2b1ab25",
            price: 71430.83,
            price_sources: [],
            processed_ms: 1711438581913,
          },
        ],
        position_type: "FLAT",
        position_uuid: "a2b61827-4220-48a9-8a93-dbe5a59d4b95",
        return_at_close: 1.024701486701727,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 69832.87,
        close_ms: 1711447656937,
        current_return: 0.9911535400760106,
        initial_entry_price: 70708.51,
        is_closed_position: true,
        miner_hotkey: "5FBsRnSdBZJ8eqbjpKBFVEqa21o7fuwVXKX4sLEeBK1DzJLN",
        net_leverage: 0.0,
        open_ms: 1711440398830,
        orders: [
          {
            leverage: -5.0,
            order_type: "SHORT",
            order_uuid: "8e59b0e7-c71e-40ab-8d70-a090a849418b",
            price: 70708.51,
            price_sources: [],
            processed_ms: 1711440398827,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "ad2d9f24-824b-43c6-a99a-edd701fdd79d",
            price: 70619.8,
            price_sources: [],
            processed_ms: 1711442208589,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "7e402f30-5849-4ee8-92ad-2d327ac289bd",
            price: 70698.53,
            price_sources: [],
            processed_ms: 1711444033580,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "eb487e10-0744-412e-bb1d-9aa8a3b27e33",
            price: 71099.08,
            price_sources: [],
            processed_ms: 1711445840081,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "0c116e0c-253a-4a5c-9b91-1386f5310604",
            price: 71083.91,
            price_sources: [],
            processed_ms: 1711447656937,
          },
        ],
        position_type: "FLAT",
        position_uuid: "fd0b2139-3b21-43b5-b300-a2d3b2c3afcf",
        return_at_close: 0.9812420046752506,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 74863.1000000001,
        close_ms: 1711456746858,
        current_return: 1.0137154396782668,
        initial_entry_price: 70913.33,
        is_closed_position: true,
        miner_hotkey: "5FBsRnSdBZJ8eqbjpKBFVEqa21o7fuwVXKX4sLEeBK1DzJLN",
        net_leverage: 0.0,
        open_ms: 1711449522653,
        orders: [
          {
            leverage: -5.0,
            order_type: "SHORT",
            order_uuid: "bbe67fe8-e3c5-4bb9-804e-6dd2563dadb6",
            price: 70913.33,
            price_sources: [],
            processed_ms: 1711449522650,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "53fb0555-c604-41d6-9dc8-6ad27e7d63c9",
            price: 70872.21,
            price_sources: [],
            processed_ms: 1711451294794,
          },
          {
            leverage: 1.75,
            order_type: "LONG",
            order_uuid: "00d92362-7d01-417a-819c-81626f4b9ac7",
            price: 70663.4,
            price_sources: [],
            processed_ms: 1711453106019,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "6a2720d5-d951-4487-9ed0-3e628c15f8aa",
            price: 70587.74,
            price_sources: [],
            processed_ms: 1711454922911,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "2be86bb5-10eb-4fe9-9dfc-889c22968b79",
            price: 70972.67,
            price_sources: [],
            processed_ms: 1711456746858,
          },
        ],
        position_type: "FLAT",
        position_uuid: "dfacdf4d-1079-4f1d-baf7-d04ab8108a3b",
        return_at_close: 1.003578285281484,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 71192.76142857144,
        close_ms: 1711460540094,
        current_return: 1.0524831565342723,
        initial_entry_price: 70935.52,
        is_closed_position: true,
        miner_hotkey: "5FBsRnSdBZJ8eqbjpKBFVEqa21o7fuwVXKX4sLEeBK1DzJLN",
        net_leverage: 0.0,
        open_ms: 1711458559553,
        orders: [
          {
            leverage: -5.0,
            order_type: "SHORT",
            order_uuid: "ede7575f-a860-429a-a8bf-75a17aabfcc7",
            price: 70935.52,
            price_sources: [],
            processed_ms: 1711458559550,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "147dfe08-6117-4795-9791-3ee7f6a8d4e5",
            price: 70335.29,
            price_sources: [],
            processed_ms: 1711460372909,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "482e7944-0cce-4852-8bca-bbe90bc1e0e3",
            price: 70129.07,
            price_sources: [],
            processed_ms: 1711460540094,
          },
        ],
        position_type: "FLAT",
        position_uuid: "52e84511-a7f0-4880-8858-9018359907cb",
        return_at_close: 1.0419583249689295,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 70551.33,
        close_ms: 1711463478118,
        current_return: 1.068625921013821,
        initial_entry_price: 70551.33,
        is_closed_position: true,
        miner_hotkey: "5FBsRnSdBZJ8eqbjpKBFVEqa21o7fuwVXKX4sLEeBK1DzJLN",
        net_leverage: 0.0,
        open_ms: 1711462191528,
        orders: [
          {
            leverage: -5.0,
            order_type: "SHORT",
            order_uuid: "5ff847b4-2dd1-4b33-b9c8-e20bff7938aa",
            price: 70551.33,
            price_sources: [],
            processed_ms: 1711462191525,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "4aa55e51-43d2-4bd3-9d50-4b3b12cd002d",
            price: 69583.0,
            price_sources: [],
            processed_ms: 1711463478118,
          },
        ],
        position_type: "FLAT",
        position_uuid: "e5f28ee7-cdd9-47b0-8479-5b26ea9fe41b",
        return_at_close: 1.0579396618036827,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 69777.37,
        close_ms: 1711464848763,
        current_return: 0.9687448810409444,
        initial_entry_price: 69777.37,
        is_closed_position: true,
        miner_hotkey: "5FBsRnSdBZJ8eqbjpKBFVEqa21o7fuwVXKX4sLEeBK1DzJLN",
        net_leverage: 0.0,
        open_ms: 1711464021911,
        orders: [
          {
            leverage: -5.0,
            order_type: "SHORT",
            order_uuid: "b07a772d-de4e-430d-bce6-ca23f6398ccb",
            price: 69777.37,
            price_sources: [],
            processed_ms: 1711464021909,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "029dea23-9095-4051-bd0a-be46366db97d",
            price: 70213.55,
            price_sources: [],
            processed_ms: 1711464848763,
          },
        ],
        position_type: "FLAT",
        position_uuid: "24f08584-a1d2-494d-a67d-727782c5ea03",
        return_at_close: 0.9590574322305349,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 69349.81620689658,
        close_ms: 1711478217752,
        current_return: 1.0527676009560953,
        initial_entry_price: 69930.15,
        is_closed_position: true,
        miner_hotkey: "5FBsRnSdBZJ8eqbjpKBFVEqa21o7fuwVXKX4sLEeBK1DzJLN",
        net_leverage: 0.0,
        open_ms: 1711465839423,
        orders: [
          {
            leverage: 5.0,
            order_type: "LONG",
            order_uuid: "7693ecae-04ec-48ba-bdfb-c38ad9ccdbbd",
            price: 69930.15,
            price_sources: [],
            processed_ms: 1711465839420,
          },
          {
            leverage: -2.5,
            order_type: "SHORT",
            order_uuid: "e71a8cdc-197e-473c-8d6c-20b53e02a56f",
            price: 70334.29,
            price_sources: [],
            processed_ms: 1711467649963,
          },
          {
            leverage: -1.25,
            order_type: "SHORT",
            order_uuid: "86c051af-6e14-44f2-8689-0d23bcd45be4",
            price: 70146.64,
            price_sources: [],
            processed_ms: 1711469457940,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "5fa8dbf0-a280-435b-b39c-79dd5d523d5a",
            price: 69701.5,
            price_sources: [],
            processed_ms: 1711471278996,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "9b6ac930-9503-4746-abdc-30b6c055d98d",
            price: 69558.88,
            price_sources: [],
            processed_ms: 1711473093030,
          },
          {
            leverage: -2.125,
            order_type: "SHORT",
            order_uuid: "9bbd51cc-ca77-43a9-a005-09c6409c12e3",
            price: 70091.93,
            price_sources: [],
            processed_ms: 1711474910073,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "36d8e99b-d1de-4af8-8c0a-f2bb53638b42",
            price: 70210.76,
            price_sources: [],
            processed_ms: 1711476728758,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "adb28a5c-2711-46d5-ace0-a916b6299917",
            price: 70367.76,
            price_sources: [],
            processed_ms: 1711478217752,
          },
        ],
        position_type: "FLAT",
        position_uuid: "2f563515-8115-4b4c-8ce1-6ccc23c55b47",
        return_at_close: 1.0422399249465344,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 70306.58,
        close_ms: 1711480191976,
        current_return: 0.9666731051346833,
        initial_entry_price: 70306.58,
        is_closed_position: true,
        miner_hotkey: "5FBsRnSdBZJ8eqbjpKBFVEqa21o7fuwVXKX4sLEeBK1DzJLN",
        net_leverage: 0.0,
        open_ms: 1711478544569,
        orders: [
          {
            leverage: 5.0,
            order_type: "LONG",
            order_uuid: "0046867f-4143-4462-81c4-f1ae078ed81f",
            price: 70306.58,
            price_sources: [],
            processed_ms: 1711478544566,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "3cd034a3-d739-4626-ae9a-0ba2adee5a24",
            price: 69837.96,
            price_sources: [],
            processed_ms: 1711480191976,
          },
        ],
        position_type: "FLAT",
        position_uuid: "3ee0a4ba-7dd9-40f4-b5ca-1a4211a80d4a",
        return_at_close: 0.9570063740833364,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 69878.71538461538,
        close_ms: 1711483324625,
        current_return: 0.961055562266389,
        initial_entry_price: 69870.44,
        is_closed_position: true,
        miner_hotkey: "5FBsRnSdBZJ8eqbjpKBFVEqa21o7fuwVXKX4sLEeBK1DzJLN",
        net_leverage: 0.0,
        open_ms: 1711480353320,
        orders: [
          {
            leverage: 5.0,
            order_type: "LONG",
            order_uuid: "b52f965c-fe2e-46c4-b170-7a38bbe7b3d5",
            price: 69870.44,
            price_sources: [],
            processed_ms: 1711480353316,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "a98d26ba-ce8d-4b3a-aae5-c7504dc6d2fe",
            price: 69906.3,
            price_sources: [],
            processed_ms: 1711482175217,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "4dccc690-5c96-4a8f-9a8c-13b766600c5f",
            price: 69460.09,
            price_sources: [],
            processed_ms: 1711483324625,
          },
        ],
        position_type: "FLAT",
        position_uuid: "a7a8d2bf-5521-47c7-a73c-297554231442",
        return_at_close: 0.9485618399569259,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 69887.9596,
        close_ms: 1711493939019,
        current_return: 1.0548684777192499,
        initial_entry_price: 69798.82,
        is_closed_position: true,
        miner_hotkey: "5FBsRnSdBZJ8eqbjpKBFVEqa21o7fuwVXKX4sLEeBK1DzJLN",
        net_leverage: 0.0,
        open_ms: 1711483989517,
        orders: [
          {
            leverage: 5.0,
            order_type: "LONG",
            order_uuid: "36088941-f23a-45ab-8740-22e9aeb17388",
            price: 69798.82,
            price_sources: [],
            processed_ms: 1711483989515,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "9b4c28c0-6f8b-477d-a121-41c1db4dcce8",
            price: 69783.03,
            price_sources: [],
            processed_ms: 1711485806900,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "232d77e4-8e30-40b6-be99-4b1b703993bf",
            price: 69871.15,
            price_sources: [],
            processed_ms: 1711487625623,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "54687319-b69d-43a1-97f5-63e1775e70f6",
            price: 70120.24,
            price_sources: [],
            processed_ms: 1711489439284,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "1ff97374-aadc-4525-8060-25aa9de0b3bf",
            price: 69924.42,
            price_sources: [],
            processed_ms: 1711491257451,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "f6e6afa7-e64e-4781-a5c1-26fee380feae",
            price: 70038.09,
            price_sources: [],
            processed_ms: 1711493068165,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "7e100d9c-1061-46d8-a36f-f663b0d107a0",
            price: 70194.34,
            price_sources: [],
            processed_ms: 1711493939019,
          },
        ],
        position_type: "FLAT",
        position_uuid: "e7e8f028-6ff1-4022-b918-feec2ac98e59",
        return_at_close: 1.0284967657762685,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 70223.37153846155,
        close_ms: 1711498430077,
        current_return: 0.9637243023931658,
        initial_entry_price: 70281.35,
        is_closed_position: true,
        miner_hotkey: "5FBsRnSdBZJ8eqbjpKBFVEqa21o7fuwVXKX4sLEeBK1DzJLN",
        net_leverage: 0.0,
        open_ms: 1711494893851,
        orders: [
          {
            leverage: 5.0,
            order_type: "LONG",
            order_uuid: "47122385-30d7-47ce-95d5-265593e9da34",
            price: 70281.35,
            price_sources: [],
            processed_ms: 1711494893847,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "025b8859-b4cc-4b3d-899d-02a015046dd3",
            price: 70030.11,
            price_sources: [],
            processed_ms: 1711496702136,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "29961aad-7f4e-4416-8825-bcc0df9df828",
            price: 69831.14,
            price_sources: [],
            processed_ms: 1711498430077,
          },
        ],
        position_type: "FLAT",
        position_uuid: "6b260b02-aed7-478e-9fd4-95f3936c4794",
        return_at_close: 0.9511958864620546,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 70039.54076923078,
        close_ms: 1711501246082,
        current_return: 1.0533844423679255,
        initial_entry_price: 69935.45,
        is_closed_position: true,
        miner_hotkey: "5FBsRnSdBZJ8eqbjpKBFVEqa21o7fuwVXKX4sLEeBK1DzJLN",
        net_leverage: 0.0,
        open_ms: 1711498516903,
        orders: [
          {
            leverage: 5.0,
            order_type: "LONG",
            order_uuid: "ee77dc8d-f6f2-434f-a517-625c03270a69",
            price: 69935.45,
            price_sources: [],
            processed_ms: 1711498516900,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "b999040b-b5a3-44fe-8952-ae2e7f7b281e",
            price: 70386.51,
            price_sources: [],
            processed_ms: 1711500336376,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "de7500b1-d7ec-4234-8797-5a85bdfd4cee",
            price: 70613.92,
            price_sources: [],
            processed_ms: 1711501246082,
          },
        ],
        position_type: "FLAT",
        position_uuid: "172a6277-69a6-46bd-ac45-d5454a313a0d",
        return_at_close: 1.0396904446171424,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 70628.05692307692,
        close_ms: 1711504719873,
        current_return: 0.9701669614432259,
        initial_entry_price: 70675.0,
        is_closed_position: true,
        miner_hotkey: "5FBsRnSdBZJ8eqbjpKBFVEqa21o7fuwVXKX4sLEeBK1DzJLN",
        net_leverage: 0.0,
        open_ms: 1711502158347,
        orders: [
          {
            leverage: 5.0,
            order_type: "LONG",
            order_uuid: "783faa59-79bc-4dd8-96f4-0e24168903e2",
            price: 70675.0,
            price_sources: [],
            processed_ms: 1711502158344,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "522e88b6-5cb5-43ab-8d3b-7ccae88b6e21",
            price: 70471.58,
            price_sources: [],
            processed_ms: 1711503972241,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "266a55da-c80e-44ba-9698-d569ab49b1f2",
            price: 70303.68,
            price_sources: [],
            processed_ms: 1711504719873,
          },
        ],
        position_type: "FLAT",
        position_uuid: "d87486a9-91f8-415a-bfb3-47a2fefdf9d8",
        return_at_close: 0.9575547909444639,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 70325.4952,
        close_ms: 1711515334977,
        current_return: 0.9588028839896477,
        initial_entry_price: 70224.94,
        is_closed_position: true,
        miner_hotkey: "5FBsRnSdBZJ8eqbjpKBFVEqa21o7fuwVXKX4sLEeBK1DzJLN",
        net_leverage: 0.0,
        open_ms: 1711505789118,
        orders: [
          {
            leverage: 5.0,
            order_type: "LONG",
            order_uuid: "7effe757-0ec3-40d4-9912-f47aba9c6890",
            price: 70224.94,
            price_sources: [],
            processed_ms: 1711505789115,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "49695586-db27-4d14-b203-2e610b02d579",
            price: 70393.89,
            price_sources: [],
            processed_ms: 1711507601989,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "66f58334-3b93-4d7c-bec9-8181dd5d8cce",
            price: 70578.12,
            price_sources: [],
            processed_ms: 1711509422397,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "9240878f-40f4-4cc8-a293-49b53ef819ba",
            price: 70391.21,
            price_sources: [],
            processed_ms: 1711511262015,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "372c2acc-f03e-4260-9435-70b0bd6f2f70",
            price: 70266.95,
            price_sources: [],
            processed_ms: 1711513048152,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "8847b20d-93e2-4cd8-9954-05e0b5d7f081",
            price: 70332.49,
            price_sources: [],
            processed_ms: 1711514864960,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "40023805-de18-4a0e-9719-83ff57492ed3",
            price: 70094.05,
            price_sources: [],
            processed_ms: 1711515334977,
          },
        ],
        position_type: "FLAT",
        position_uuid: "9c5e3133-9cc1-4d92-97f1-4991e2b8113d",
        return_at_close: 0.9348328118899065,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 70379.54375,
        close_ms: 1711521835000,
        current_return: 0.9821103981401539,
        initial_entry_price: 70379.99,
        is_closed_position: true,
        miner_hotkey: "5FBsRnSdBZJ8eqbjpKBFVEqa21o7fuwVXKX4sLEeBK1DzJLN",
        net_leverage: 0.0,
        open_ms: 1711516691870,
        orders: [
          {
            leverage: 5.0,
            order_type: "LONG",
            order_uuid: "8a9a2a53-c4bd-4881-aaf6-ae0a5e05649b",
            price: 70379.99,
            price_sources: [],
            processed_ms: 1711516691865,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "749a50f0-b874-4cef-acbd-70277bfef021",
            price: 70419.91,
            price_sources: [],
            processed_ms: 1711518495934,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "1cf7902c-0b54-4e3e-8ba2-62d9ac7b56bc",
            price: 70337.69,
            price_sources: [],
            processed_ms: 1711520322556,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "16244bea-ed6b-4e6b-8f80-89b61e200d3c",
            price: 70222.16,
            price_sources: [],
            processed_ms: 1711521835000,
          },
        ],
        position_type: "FLAT",
        position_uuid: "2196d97c-89a7-4642-8e81-721ac684e23b",
        return_at_close: 0.9663966317699114,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
    ],
    thirty_day_returns: 1.0,
  },
  "5FChPUTXqfNxyrs7wuLyZe9nCGE3UAYwVHcgQMsdYFuHbBmv": {
    all_time_returns: 1.0,
    n_positions: 0,
    percentage_profitable: 0.0,
    positions: [
      {
        average_entry_price: 64401.41,
        close_ms: 1711184453217,
        current_return: 1.0030528055758698,
        initial_entry_price: 64254.01,
        is_closed_position: true,
        miner_hotkey: "5FChPUTXqfNxyrs7wuLyZe9nCGE3UAYwVHcgQMsdYFuHbBmv",
        net_leverage: 0.0,
        open_ms: 1711182956697,
        orders: [
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "8f95ac35-0eca-43c2-a825-1437950d3f1e",
            price: 64254.01,
            price_sources: [],
            processed_ms: 1711182956697,
          },
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "373d2a65-64c5-417f-8dbd-a899b633dcc4",
            price: 64423.31,
            price_sources: [],
            processed_ms: 1711183254955,
          },
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "2adb4abb-4796-4032-a792-aeb645716fab",
            price: 64453.45,
            price_sources: [],
            processed_ms: 1711183554637,
          },
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "acd33d85-715f-4415-9caa-8b9b624a8167",
            price: 64501.96,
            price_sources: [],
            processed_ms: 1711183873057,
          },
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "281cd037-dad7-4585-82f5-1021893d111e",
            price: 64374.32,
            price_sources: [],
            processed_ms: 1711184154230,
          },
          {
            leverage: 0.3,
            order_type: "FLAT",
            order_uuid: "fa85aa11-2e50-4841-a397-98034e90326c",
            price: 64532.18,
            price_sources: [],
            processed_ms: 1711184453217,
          },
        ],
        position_type: "FLAT",
        position_uuid: "5dcf70bf-370a-4d40-8769-5ae8d76e9270",
        return_at_close: 1.0000436471591423,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 64339.52599999999,
        close_ms: 1711186253322,
        current_return: 1.0008796388893377,
        initial_entry_price: 64396.88,
        is_closed_position: true,
        miner_hotkey: "5FChPUTXqfNxyrs7wuLyZe9nCGE3UAYwVHcgQMsdYFuHbBmv",
        net_leverage: 0.0,
        open_ms: 1711184760181,
        orders: [
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "9a328ba4-aee0-43f8-bc9f-b486fcd5dc75",
            price: 64396.88,
            price_sources: [],
            processed_ms: 1711184760180,
          },
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "fd42def1-7a5f-4580-9d65-3fe6d66d3a48",
            price: 64429.74,
            price_sources: [],
            processed_ms: 1711185060538,
          },
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "50c78000-4482-43ff-992b-72a7731c958f",
            price: 64210.68,
            price_sources: [],
            processed_ms: 1711185357957,
          },
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "0982aae9-2499-4e13-8498-a1007c7db64f",
            price: 64296.97,
            price_sources: [],
            processed_ms: 1711185656064,
          },
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "a6571922-8754-4e5b-b931-7142449f1d33",
            price: 64363.36,
            price_sources: [],
            processed_ms: 1711185955981,
          },
          {
            leverage: 0.3,
            order_type: "FLAT",
            order_uuid: "78dcc7ae-81e4-451f-b7e9-c7b308441223",
            price: 64377.29,
            price_sources: [],
            processed_ms: 1711186253322,
          },
        ],
        position_type: "FLAT",
        position_uuid: "1d6a95de-0784-4f35-9942-466d07c5f099",
        return_at_close: 0.9978769999726698,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 64375.756,
        close_ms: 1711188054891,
        current_return: 1.0023188866309654,
        initial_entry_price: 64326.56,
        is_closed_position: true,
        miner_hotkey: "5FChPUTXqfNxyrs7wuLyZe9nCGE3UAYwVHcgQMsdYFuHbBmv",
        net_leverage: 0.0,
        open_ms: 1711186561445,
        orders: [
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "7c713fe9-eaad-4575-aec1-306fa60a19e8",
            price: 64326.56,
            price_sources: [],
            processed_ms: 1711186561444,
          },
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "82d23ed1-66c5-4dfb-b434-ee30d0bd53e5",
            price: 64375.75,
            price_sources: [],
            processed_ms: 1711186859323,
          },
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "89226c29-a9fb-48ed-9e9a-464ae7af6ea9",
            price: 64473.3,
            price_sources: [],
            processed_ms: 1711187167356,
          },
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "6c99e7a0-a0be-4d00-aeaf-a93083cbf0ee",
            price: 64301.14,
            price_sources: [],
            processed_ms: 1711187458033,
          },
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "baa9ca15-7861-40c1-a5b0-f38dee84a0fb",
            price: 64402.03,
            price_sources: [],
            processed_ms: 1711187757306,
          },
          {
            leverage: 0.3,
            order_type: "FLAT",
            order_uuid: "95463ff3-8850-4e3f-91f0-71936c5504cf",
            price: 64475.2,
            price_sources: [],
            processed_ms: 1711188054891,
          },
        ],
        position_type: "FLAT",
        position_uuid: "86fb76f1-901c-4e2e-8c43-a3c37da01fba",
        return_at_close: 0.9993119299710724,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 64562.32200000001,
        close_ms: 1711189866232,
        current_return: 0.999945148931565,
        initial_entry_price: 64593.09,
        is_closed_position: true,
        miner_hotkey: "5FChPUTXqfNxyrs7wuLyZe9nCGE3UAYwVHcgQMsdYFuHbBmv",
        net_leverage: 0.0,
        open_ms: 1711188361548,
        orders: [
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "b34af748-5f04-401b-a182-5840958f762e",
            price: 64593.09,
            price_sources: [],
            processed_ms: 1711188361547,
          },
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "cfab3f45-b334-4c6b-9e0d-e25817b179d5",
            price: 64552.45,
            price_sources: [],
            processed_ms: 1711188658274,
          },
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "ca89069f-5d40-488a-9a7b-1c13bca820d2",
            price: 64436.89,
            price_sources: [],
            processed_ms: 1711188955308,
          },
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "eacd1804-78ea-458a-b2dc-08dac6afcc44",
            price: 64572.76,
            price_sources: [],
            processed_ms: 1711189265559,
          },
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "f935dddb-2d5f-4db1-983a-4f6b81fbf642",
            price: 64656.42,
            price_sources: [],
            processed_ms: 1711189566126,
          },
          {
            leverage: 0.3,
            order_type: "FLAT",
            order_uuid: "ed46c57a-df8e-4c42-b2ab-3a2544620496",
            price: 64559.96,
            price_sources: [],
            processed_ms: 1711189866232,
          },
        ],
        position_type: "FLAT",
        position_uuid: "d1aaecfb-2b67-4cfb-bc33-4be4ff5ced15",
        return_at_close: 0.9969453134847703,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 64476.576,
        close_ms: 1711191658466,
        current_return: 0.9965649250206992,
        initial_entry_price: 64603.83,
        is_closed_position: true,
        miner_hotkey: "5FChPUTXqfNxyrs7wuLyZe9nCGE3UAYwVHcgQMsdYFuHbBmv",
        net_leverage: 0.0,
        open_ms: 1711190156173,
        orders: [
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "78945047-aaa8-45c5-acf2-9ad1314af563",
            price: 64603.83,
            price_sources: [],
            processed_ms: 1711190156172,
          },
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "9aab456a-6859-44b0-a7f4-6cb7e55907be",
            price: 64538.49,
            price_sources: [],
            processed_ms: 1711190456740,
          },
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "ae9c26df-c0b8-48f7-98f0-5fcab7974c75",
            price: 64530.51,
            price_sources: [],
            processed_ms: 1711190765512,
          },
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "f416b145-f9a0-460c-a17d-2b32af43c80e",
            price: 64486.88,
            price_sources: [],
            processed_ms: 1711191061580,
          },
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "a5f24349-c009-410f-9cac-90d8f8f222b3",
            price: 64223.17,
            price_sources: [],
            processed_ms: 1711191359380,
          },
          {
            leverage: 0.3,
            order_type: "FLAT",
            order_uuid: "349c6947-3a1f-47e2-b769-02b1cb835ff7",
            price: 64328.63,
            price_sources: [],
            processed_ms: 1711191658466,
          },
        ],
        position_type: "FLAT",
        position_uuid: "9469490a-9f51-4c6d-ae38-2259c49da96a",
        return_at_close: 0.9935752302456371,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 64396.296,
        close_ms: 1711193469579,
        current_return: 1.0042378699478631,
        initial_entry_price: 64278.99,
        is_closed_position: true,
        miner_hotkey: "5FChPUTXqfNxyrs7wuLyZe9nCGE3UAYwVHcgQMsdYFuHbBmv",
        net_leverage: 0.0,
        open_ms: 1711191967823,
        orders: [
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "12aa15ef-4e32-4460-99a3-d4f3dd8b549e",
            price: 64278.99,
            price_sources: [],
            processed_ms: 1711191967822,
          },
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "d8d2a4fc-add2-4074-9a9b-39c673602f9a",
            price: 64343.87,
            price_sources: [],
            processed_ms: 1711192258593,
          },
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "ed74ca55-e916-4bfc-a99b-7758964549b8",
            price: 64414.59,
            price_sources: [],
            processed_ms: 1711192559894,
          },
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "048ae406-2a2c-4f0b-b11f-b9967c0b86a8",
            price: 64481.76,
            price_sources: [],
            processed_ms: 1711192861253,
          },
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "59febb05-3e31-411e-bbad-2b778cbbf9ee",
            price: 64462.27,
            price_sources: [],
            processed_ms: 1711193180640,
          },
          {
            leverage: 0.3,
            order_type: "FLAT",
            order_uuid: "7b6f9dc5-52d8-4275-b8a2-60a26b3b025b",
            price: 64577.9,
            price_sources: [],
            processed_ms: 1711193469579,
          },
        ],
        position_type: "FLAT",
        position_uuid: "92db8a74-a36b-47e2-914f-21c6f940a4bd",
        return_at_close: 1.0012251563380195,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 64561.112,
        close_ms: 1711195263044,
        current_return: 1.0017796140632191,
        initial_entry_price: 64596.59,
        is_closed_position: true,
        miner_hotkey: "5FChPUTXqfNxyrs7wuLyZe9nCGE3UAYwVHcgQMsdYFuHbBmv",
        net_leverage: 0.0,
        open_ms: 1711193768018,
        orders: [
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "01135096-8d17-47de-92b9-3d1ec600cd9e",
            price: 64596.59,
            price_sources: [],
            processed_ms: 1711193768017,
          },
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "c73e05c0-5737-466e-ba50-da4b7787846f",
            price: 64562.67,
            price_sources: [],
            processed_ms: 1711194065750,
          },
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "0f278c9e-7912-4336-b973-4810edf2d9fb",
            price: 64565.31,
            price_sources: [],
            processed_ms: 1711194364850,
          },
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "88bb8333-69e3-463b-8328-2709227820f1",
            price: 64510.23,
            price_sources: [],
            processed_ms: 1711194665439,
          },
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "5502d334-6dca-4c52-9c56-2ed62a825790",
            price: 64570.76,
            price_sources: [],
            processed_ms: 1711194963074,
          },
          {
            leverage: 0.3,
            order_type: "FLAT",
            order_uuid: "60340f9f-d24e-4993-b599-1ca10bcc6298",
            price: 64637.75,
            price_sources: [],
            processed_ms: 1711195263044,
          },
        ],
        position_type: "FLAT",
        position_uuid: "85b9bd92-fe77-45e7-ba30-1088820be962",
        return_at_close: 0.9987742752210295,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 64668.032,
        close_ms: 1711197071102,
        current_return: 1.0010517115899462,
        initial_entry_price: 64634.64,
        is_closed_position: true,
        miner_hotkey: "5FChPUTXqfNxyrs7wuLyZe9nCGE3UAYwVHcgQMsdYFuHbBmv",
        net_leverage: 0.0,
        open_ms: 1711195569548,
        orders: [
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "f795cd28-7725-4b7a-aba0-ce096cbcd668",
            price: 64634.64,
            price_sources: [],
            processed_ms: 1711195569547,
          },
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "841cf999-c594-448a-91db-b648db41ee7d",
            price: 64735.17,
            price_sources: [],
            processed_ms: 1711195868735,
          },
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "6b21939e-d5d6-4b71-b2c8-b0b0a99afdbd",
            price: 64601.94,
            price_sources: [],
            processed_ms: 1711196176728,
          },
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "c6898fa8-28aa-44ed-b56f-e39eb5a915c8",
            price: 64616.91,
            price_sources: [],
            processed_ms: 1711196467226,
          },
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "0de37dc8-a0b6-4d46-bf5e-a177c8b9abf4",
            price: 64751.5,
            price_sources: [],
            processed_ms: 1711196763297,
          },
          {
            leverage: 0.3,
            order_type: "FLAT",
            order_uuid: "00cc564b-5ce1-4082-9e6e-5228e8212216",
            price: 64713.35,
            price_sources: [],
            processed_ms: 1711197071102,
          },
        ],
        position_type: "FLAT",
        position_uuid: "05067297-c5c5-41ba-aeb4-baedfa2ca427",
        return_at_close: 0.9980485564551764,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 64748.31800000001,
        close_ms: 1711198872455,
        current_return: 0.998949482119139,
        initial_entry_price: 64650.97,
        is_closed_position: true,
        miner_hotkey: "5FChPUTXqfNxyrs7wuLyZe9nCGE3UAYwVHcgQMsdYFuHbBmv",
        net_leverage: 0.0,
        open_ms: 1711197369549,
        orders: [
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "f918eca9-12de-4bf4-a33c-1e2759b35552",
            price: 64650.97,
            price_sources: [],
            processed_ms: 1711197369548,
          },
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "d2e62a6f-7613-4393-9470-8ca68c0227e4",
            price: 64707.25,
            price_sources: [],
            processed_ms: 1711197666456,
          },
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "9bf1fe98-2789-4432-b54e-f3d4e3266cae",
            price: 64703.64,
            price_sources: [],
            processed_ms: 1711197965042,
          },
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "63d8c7df-d606-46c4-88e1-2ff1aca0a419",
            price: 64821.96,
            price_sources: [],
            processed_ms: 1711198264213,
          },
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "8cb92632-cc33-4b11-96bd-ede34a6eb1ea",
            price: 64857.77,
            price_sources: [],
            processed_ms: 1711198574044,
          },
          {
            leverage: 0.3,
            order_type: "FLAT",
            order_uuid: "759ba271-3d2d-459f-97a5-800dc5920845",
            price: 64703.04,
            price_sources: [],
            processed_ms: 1711198872455,
          },
        ],
        position_type: "FLAT",
        position_uuid: "997c61ca-5fff-490a-8801-9088a23e9ef1",
        return_at_close: 0.9959526336727816,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 64577.98799999999,
        close_ms: 1711200667552,
        current_return: 0.9991839784535778,
        initial_entry_price: 64626.97,
        is_closed_position: true,
        miner_hotkey: "5FChPUTXqfNxyrs7wuLyZe9nCGE3UAYwVHcgQMsdYFuHbBmv",
        net_leverage: 0.0,
        open_ms: 1711199181220,
        orders: [
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "cd4fd7a7-34a1-4664-ada8-1e4857a00bf6",
            price: 64626.97,
            price_sources: [],
            processed_ms: 1711199181219,
          },
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "9c0c9bde-86d0-4bc0-b662-bd6e7c00403e",
            price: 64586.22,
            price_sources: [],
            processed_ms: 1711199471618,
          },
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "057729a8-1b69-451a-83c0-c9219d7a0929",
            price: 64577.5,
            price_sources: [],
            processed_ms: 1711199773274,
          },
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "2d26ef91-a773-47f4-bdef-2b3f07d15623",
            price: 64619.04,
            price_sources: [],
            processed_ms: 1711200070927,
          },
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "3f391ba6-38ef-4add-90f0-239d71898829",
            price: 64480.21,
            price_sources: [],
            processed_ms: 1711200368899,
          },
          {
            leverage: 0.3,
            order_type: "FLAT",
            order_uuid: "40531359-6e60-4a72-8cab-5485b20355b3",
            price: 64542.83,
            price_sources: [],
            processed_ms: 1711200667552,
          },
        ],
        position_type: "FLAT",
        position_uuid: "45afe6c0-207c-4976-b3ef-826af2f225ad",
        return_at_close: 0.996186426518217,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 64502.22400000001,
        close_ms: 1711202469932,
        current_return: 1.00221229904163,
        initial_entry_price: 64457.38,
        is_closed_position: true,
        miner_hotkey: "5FChPUTXqfNxyrs7wuLyZe9nCGE3UAYwVHcgQMsdYFuHbBmv",
        net_leverage: 0.0,
        open_ms: 1711200974856,
        orders: [
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "dd58fb4c-685d-4034-b66f-772608841c96",
            price: 64457.38,
            price_sources: [],
            processed_ms: 1711200974855,
          },
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "d2eb77c5-4862-4e1a-9d53-c97a6d34e85b",
            price: 64497.5,
            price_sources: [],
            processed_ms: 1711201271807,
          },
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "de498223-b65f-4013-ba89-e819f8a83616",
            price: 64354.38,
            price_sources: [],
            processed_ms: 1711201570179,
          },
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "7f936c18-c7d1-4602-a9c4-7400d56a43e3",
            price: 64526.68,
            price_sources: [],
            processed_ms: 1711201869306,
          },
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "09c81bb5-831f-48a5-9c35-c8609e3ebe8a",
            price: 64675.18,
            price_sources: [],
            processed_ms: 1711202190192,
          },
          {
            leverage: 0.3,
            order_type: "FLAT",
            order_uuid: "d03c30ee-b1ae-4a6c-84b6-95f4ceb01f97",
            price: 64597.29,
            price_sources: [],
            processed_ms: 1711202469932,
          },
        ],
        position_type: "FLAT",
        position_uuid: "ed0585b3-0b9e-4e7a-9e36-fe8b59b20f38",
        return_at_close: 0.9992056621445051,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 64599.798,
        close_ms: 1711204278903,
        current_return: 1.015930904457873,
        initial_entry_price: 64618.93,
        is_closed_position: true,
        miner_hotkey: "5FChPUTXqfNxyrs7wuLyZe9nCGE3UAYwVHcgQMsdYFuHbBmv",
        net_leverage: 0.0,
        open_ms: 1711202778316,
        orders: [
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "93e0d602-87a1-4c2a-8e32-18583f9747b0",
            price: 64618.93,
            price_sources: [],
            processed_ms: 1711202778314,
          },
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "8c377b1e-d4d1-4e84-a314-d18f281efdb2",
            price: 64605.04,
            price_sources: [],
            processed_ms: 1711203075061,
          },
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "0e09092d-e0b2-4330-9e5c-66a7a7879812",
            price: 64499.52,
            price_sources: [],
            processed_ms: 1711203374945,
          },
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "34dac516-5a7c-4e06-adde-7a73950c51f4",
            price: 64625.36,
            price_sources: [],
            processed_ms: 1711203673802,
          },
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "b05e232a-fc18-47aa-82fa-a239bc0620e5",
            price: 64650.14,
            price_sources: [],
            processed_ms: 1711203976677,
          },
          {
            leverage: 0.3,
            order_type: "FLAT",
            order_uuid: "74162267-a697-4ce9-a43f-8c0cc7a92611",
            price: 65286.09,
            price_sources: [],
            processed_ms: 1711204278903,
          },
        ],
        position_type: "FLAT",
        position_uuid: "f46483c0-cc43-49f1-b86b-54352415f159",
        return_at_close: 1.0128831117444994,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 65286.938,
        close_ms: 1711206070940,
        current_return: 0.999310356962798,
        initial_entry_price: 65355.55,
        is_closed_position: true,
        miner_hotkey: "5FChPUTXqfNxyrs7wuLyZe9nCGE3UAYwVHcgQMsdYFuHbBmv",
        net_leverage: 0.0,
        open_ms: 1711204577500,
        orders: [
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "0933ae2d-a0a4-4ba7-831f-0bc9526c8e97",
            price: 65355.55,
            price_sources: [],
            processed_ms: 1711204577499,
          },
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "ceacdda6-db0b-4ff0-b7d8-d1e790f4ab73",
            price: 65145.3,
            price_sources: [],
            processed_ms: 1711204874011,
          },
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "8a326d3a-1e6f-4efc-8ccd-730c8c3b9d6a",
            price: 65198.57,
            price_sources: [],
            processed_ms: 1711205193633,
          },
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "fcc38bd1-8471-4ac3-a24c-1d9db2b04ce4",
            price: 65373.44,
            price_sources: [],
            processed_ms: 1711205473551,
          },
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "14beefcb-b2c0-4547-8b9c-b5dd3c323066",
            price: 65361.83,
            price_sources: [],
            processed_ms: 1711205771876,
          },
          {
            leverage: 0.3,
            order_type: "FLAT",
            order_uuid: "2ee167a9-5651-4de4-8228-12e5da768229",
            price: 65256.89,
            price_sources: [],
            processed_ms: 1711206070940,
          },
        ],
        position_type: "FLAT",
        position_uuid: "4b4ed095-ed62-4bf4-acaa-9b0efb7fac92",
        return_at_close: 0.9963124258919096,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 65006.562,
        close_ms: 1711207873501,
        current_return: 0.9959207311158963,
        initial_entry_price: 65262.43,
        is_closed_position: true,
        miner_hotkey: "5FChPUTXqfNxyrs7wuLyZe9nCGE3UAYwVHcgQMsdYFuHbBmv",
        net_leverage: 0.0,
        open_ms: 1711206372824,
        orders: [
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "1501729a-5535-45eb-a148-24c0e701f0a2",
            price: 65262.43,
            price_sources: [],
            processed_ms: 1711206372821,
          },
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "fd542664-c2dc-4e35-a2d2-bf1c43ca1601",
            price: 65128.62,
            price_sources: [],
            processed_ms: 1711206675109,
          },
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "3953362d-bb87-478f-89b4-7036305eff0f",
            price: 65027.32,
            price_sources: [],
            processed_ms: 1711206977089,
          },
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "f9e8be80-5efc-413a-ac29-a268215f0e84",
            price: 64805.25,
            price_sources: [],
            processed_ms: 1711207276399,
          },
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "3e307ad4-9a0c-47df-8b6d-edc001605cad",
            price: 64809.19,
            price_sources: [],
            processed_ms: 1711207575248,
          },
          {
            leverage: 0.3,
            order_type: "FLAT",
            order_uuid: "159c2183-7b0d-4588-bb5d-387fb9981dc6",
            price: 64829.08,
            price_sources: [],
            processed_ms: 1711207873501,
          },
        ],
        position_type: "FLAT",
        position_uuid: "4ed4da20-b4db-4375-8a85-ebf6d2992618",
        return_at_close: 0.9929329689225486,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 64934.85999999999,
        close_ms: 1711209681520,
        current_return: 1.004075614915918,
        initial_entry_price: 64849.11,
        is_closed_position: true,
        miner_hotkey: "5FChPUTXqfNxyrs7wuLyZe9nCGE3UAYwVHcgQMsdYFuHbBmv",
        net_leverage: 0.0,
        open_ms: 1711208195885,
        orders: [
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "20dd0fb6-9942-4ddc-897c-d994124ae41f",
            price: 64849.11,
            price_sources: [],
            processed_ms: 1711208195883,
          },
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "f89ed2de-1824-46d9-ae58-cd0faf23d35d",
            price: 64864.71,
            price_sources: [],
            processed_ms: 1711208475992,
          },
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "92bf2b12-8ec2-4f03-bfc5-83f4a9b81d60",
            price: 64891.82,
            price_sources: [],
            processed_ms: 1711208776474,
          },
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "838365db-b5f9-45d4-a8bc-0b979a92083d",
            price: 64930.43,
            price_sources: [],
            processed_ms: 1711209074859,
          },
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "28bcb236-130b-4c04-bab4-cf81d4430a9a",
            price: 65138.23,
            price_sources: [],
            processed_ms: 1711209383294,
          },
          {
            leverage: 0.3,
            order_type: "FLAT",
            order_uuid: "e64e7ece-acad-4811-9fff-c65adb050a93",
            price: 65111.06,
            price_sources: [],
            processed_ms: 1711209681520,
          },
        ],
        position_type: "FLAT",
        position_uuid: "7d056365-965a-4279-8745-88733331ac3b",
        return_at_close: 1.0010633880711703,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 65142.096,
        close_ms: 1711211485785,
        current_return: 1.0030394064401684,
        initial_entry_price: 65077.18,
        is_closed_position: true,
        miner_hotkey: "5FChPUTXqfNxyrs7wuLyZe9nCGE3UAYwVHcgQMsdYFuHbBmv",
        net_leverage: 0.0,
        open_ms: 1711209974963,
        orders: [
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "3a8d840e-1168-4abf-a39a-89aea1b8d220",
            price: 65077.18,
            price_sources: [],
            processed_ms: 1711209974961,
          },
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "23e26a30-0f8c-4a8b-b760-6b99d39640ab",
            price: 65101.27,
            price_sources: [],
            processed_ms: 1711210277831,
          },
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "67ce56ab-8cdb-4884-965b-c4e40454cb8e",
            price: 65146.82,
            price_sources: [],
            processed_ms: 1711210579494,
          },
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "e21e9580-5326-4e4c-bc6b-41b1f89539af",
            price: 65155.67,
            price_sources: [],
            processed_ms: 1711210882593,
          },
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "63f4f0d5-1e5e-469e-ac10-05efe7642957",
            price: 65229.54,
            price_sources: [],
            processed_ms: 1711211205500,
          },
          {
            leverage: 0.3,
            order_type: "FLAT",
            order_uuid: "51f9ce17-f744-4f7d-ac23-428c71122fad",
            price: 65273.96,
            price_sources: [],
            processed_ms: 1711211485785,
          },
        ],
        position_type: "FLAT",
        position_uuid: "106bd234-6159-4f3d-a232-a10463807815",
        return_at_close: 1.0000302882208478,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 65571.136,
        close_ms: 1711213280682,
        current_return: 1.003755821381156,
        initial_entry_price: 65499.92,
        is_closed_position: true,
        miner_hotkey: "5FChPUTXqfNxyrs7wuLyZe9nCGE3UAYwVHcgQMsdYFuHbBmv",
        net_leverage: 0.0,
        open_ms: 1711211778952,
        orders: [
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "a66d8363-e107-468e-aaf3-867c7c1b7760",
            price: 65499.92,
            price_sources: [],
            processed_ms: 1711211778950,
          },
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "80d4b60a-2669-488b-b7af-4ad2506134a7",
            price: 65461.92,
            price_sources: [],
            processed_ms: 1711212081342,
          },
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "3df02b90-fdb6-49be-b87b-60d66ffdf518",
            price: 65671.68,
            price_sources: [],
            processed_ms: 1711212384920,
          },
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "19ebaebc-91a2-4e4b-9f65-2c3b5a2ce273",
            price: 65599.22,
            price_sources: [],
            processed_ms: 1711212684073,
          },
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "f758d0e2-843e-44c9-a52e-6cf0c8bdc74d",
            price: 65622.94,
            price_sources: [],
            processed_ms: 1711212980077,
          },
          {
            leverage: 0.3,
            order_type: "FLAT",
            order_uuid: "ae2249cc-d2f4-4c54-8878-1bfc29e63032",
            price: 65735.14,
            price_sources: [],
            processed_ms: 1711213280682,
          },
        ],
        position_type: "FLAT",
        position_uuid: "9298f1d6-b2a7-49e5-aa69-8e3d49b59165",
        return_at_close: 1.0007445539170126,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 65576.65200000002,
        close_ms: 1711215083898,
        current_return: 1.0056810759485475,
        initial_entry_price: 65664.85,
        is_closed_position: true,
        miner_hotkey: "5FChPUTXqfNxyrs7wuLyZe9nCGE3UAYwVHcgQMsdYFuHbBmv",
        net_leverage: 0.0,
        open_ms: 1711213584501,
        orders: [
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "db338c24-0df8-4692-9b14-aaf5e4c1f7f7",
            price: 65664.85,
            price_sources: [],
            processed_ms: 1711213584499,
          },
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "8777ea51-38e5-4965-a077-c6ac08242962",
            price: 65632.74,
            price_sources: [],
            processed_ms: 1711213884276,
          },
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "5bf81169-aa8f-47bc-8f40-2f91b51d6360",
            price: 65498.08,
            price_sources: [],
            processed_ms: 1711214207960,
          },
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "28b3b987-d2b4-4aee-8df2-1133643e069b",
            price: 65561.33,
            price_sources: [],
            processed_ms: 1711214487471,
          },
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "6b8fd4da-2a50-4221-b749-fb97311f1954",
            price: 65526.26,
            price_sources: [],
            processed_ms: 1711214785889,
          },
          {
            leverage: 0.3,
            order_type: "FLAT",
            order_uuid: "0eeaef79-0ede-433f-9f09-d2210603819e",
            price: 65825.35,
            price_sources: [],
            processed_ms: 1711215083898,
          },
        ],
        position_type: "FLAT",
        position_uuid: "b5ff323d-ef1c-4d2f-9392-c08fa24ff77b",
        return_at_close: 1.002664032720702,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 65759.374,
        close_ms: 1711216888312,
        current_return: 0.9970252158425218,
        initial_entry_price: 65931.17,
        is_closed_position: true,
        miner_hotkey: "5FChPUTXqfNxyrs7wuLyZe9nCGE3UAYwVHcgQMsdYFuHbBmv",
        net_leverage: 0.0,
        open_ms: 1711215386435,
        orders: [
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "6fb4c8bf-2ccc-457e-a042-83f59c19712b",
            price: 65931.17,
            price_sources: [],
            processed_ms: 1711215386432,
          },
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "20011474-d30d-44e8-862b-0904d85e1b7e",
            price: 65797.43,
            price_sources: [],
            processed_ms: 1711215685026,
          },
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "e72a9193-ab42-480c-bfbe-84cb5860fade",
            price: 65709.63,
            price_sources: [],
            processed_ms: 1711215979874,
          },
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "75a53698-7256-49e7-b44d-d8bad7274dd9",
            price: 65714.73,
            price_sources: [],
            processed_ms: 1711216290495,
          },
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "c0344b17-909b-4089-806c-cc28a8b594e7",
            price: 65643.91,
            price_sources: [],
            processed_ms: 1711216589823,
          },
          {
            leverage: 0.3,
            order_type: "FLAT",
            order_uuid: "569c2089-ee26-4324-99d5-1c4e3f791eee",
            price: 65628.62,
            price_sources: [],
            processed_ms: 1711216888312,
          },
        ],
        position_type: "FLAT",
        position_uuid: "57dedb7e-7432-495d-9eee-8c02462a867d",
        return_at_close: 0.9940341401949943,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 65568.238,
        close_ms: 1711218684254,
        current_return: 0.9989735335170429,
        initial_entry_price: 65654.36,
        is_closed_position: true,
        miner_hotkey: "5FChPUTXqfNxyrs7wuLyZe9nCGE3UAYwVHcgQMsdYFuHbBmv",
        net_leverage: 0.0,
        open_ms: 1711217214237,
        orders: [
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "baf6559e-9c77-4ec3-be19-a3cc1f82f884",
            price: 65654.36,
            price_sources: [],
            processed_ms: 1711217214235,
          },
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "9e90e7fd-4c5f-4ac5-8f9b-aba269356652",
            price: 65596.72,
            price_sources: [],
            processed_ms: 1711217484481,
          },
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "d51dafed-4397-4993-acdf-56e6555cffdb",
            price: 65529.12,
            price_sources: [],
            processed_ms: 1711217782833,
          },
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "b5bb7c46-63ca-4e2d-a2bb-14dae54906c6",
            price: 65546.13,
            price_sources: [],
            processed_ms: 1711218083146,
          },
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "e05353cf-169c-487e-b501-483ae3e382e7",
            price: 65514.86,
            price_sources: [],
            processed_ms: 1711218384090,
          },
          {
            leverage: 0.3,
            order_type: "FLAT",
            order_uuid: "0d3dd6d5-60b6-4971-8296-616f9efd9308",
            price: 65523.31,
            price_sources: [],
            processed_ms: 1711218684254,
          },
        ],
        position_type: "FLAT",
        position_uuid: "1ab9eae6-80b7-4de0-8c7b-907a82c1e84b",
        return_at_close: 0.9959766129164918,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 65420.39800000001,
        close_ms: 1711220510338,
        current_return: 0.9993235105677973,
        initial_entry_price: 65428.96,
        is_closed_position: true,
        miner_hotkey: "5FChPUTXqfNxyrs7wuLyZe9nCGE3UAYwVHcgQMsdYFuHbBmv",
        net_leverage: 0.0,
        open_ms: 1711218992091,
        orders: [
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "d160acd4-fde4-4c88-be6c-44203a18c44a",
            price: 65428.96,
            price_sources: [],
            processed_ms: 1711218992088,
          },
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "ce0afada-3e85-45e5-9338-1bdd72e65eac",
            price: 65447.13,
            price_sources: [],
            processed_ms: 1711219290784,
          },
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "c9f8eaf9-a9fd-42b0-a3be-6f0aedf1fa22",
            price: 65351.41,
            price_sources: [],
            processed_ms: 1711219587870,
          },
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "436af574-89b5-498b-afb0-86e468151a33",
            price: 65489.98,
            price_sources: [],
            processed_ms: 1711219886975,
          },
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "43f9c5ed-2a54-4820-978c-4ace57c410bd",
            price: 65384.51,
            price_sources: [],
            processed_ms: 1711220187317,
          },
          {
            leverage: 0.3,
            order_type: "FLAT",
            order_uuid: "a1a6d2f1-c2c8-4a4e-a321-724456704815",
            price: 65390.89,
            price_sources: [],
            processed_ms: 1711220510338,
          },
        ],
        position_type: "FLAT",
        position_uuid: "649a9ced-7631-4073-90f3-3ba54357499c",
        return_at_close: 0.996325540036094,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 65306.831999999995,
        close_ms: 1711222289022,
        current_return: 0.9951258931765441,
        initial_entry_price: 65412.6,
        is_closed_position: true,
        miner_hotkey: "5FChPUTXqfNxyrs7wuLyZe9nCGE3UAYwVHcgQMsdYFuHbBmv",
        net_leverage: 0.0,
        open_ms: 1711220790620,
        orders: [
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "95537d20-5831-4638-bb48-14ed52866299",
            price: 65412.6,
            price_sources: [],
            processed_ms: 1711220790618,
          },
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "cd9073cc-5b61-4784-bc6f-39b2b66a9ef1",
            price: 65350.78,
            price_sources: [],
            processed_ms: 1711221091331,
          },
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "6048b249-d152-4ab3-9231-8807f53ddddc",
            price: 65307.88,
            price_sources: [],
            processed_ms: 1711221385727,
          },
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "795a27e6-0e7a-45ea-af8c-c129fbeec3af",
            price: 65303.3,
            price_sources: [],
            processed_ms: 1711221692983,
          },
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "3674bf15-315e-4bc5-8f96-115d78283dd8",
            price: 65159.6,
            price_sources: [],
            processed_ms: 1711221991051,
          },
          {
            leverage: 0.3,
            order_type: "FLAT",
            order_uuid: "563ef148-a356-47db-a361-2a812ead1fee",
            price: 65094.28,
            price_sources: [],
            processed_ms: 1711222289022,
          },
        ],
        position_type: "FLAT",
        position_uuid: "736c03ad-8fb7-4720-a628-3e8f88a55f15",
        return_at_close: 0.9921405154970144,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 64949.24799999999,
        close_ms: 1711224091885,
        current_return: 0.9999567318060089,
        initial_entry_price: 65105.56,
        is_closed_position: true,
        miner_hotkey: "5FChPUTXqfNxyrs7wuLyZe9nCGE3UAYwVHcgQMsdYFuHbBmv",
        net_leverage: 0.0,
        open_ms: 1711222587162,
        orders: [
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "52bda1c2-8571-4d04-9be3-45f8c1f89a20",
            price: 65105.56,
            price_sources: [],
            processed_ms: 1711222587159,
          },
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "665f5a9d-c62b-439c-9ce2-52f0d6c76adf",
            price: 64965.34,
            price_sources: [],
            processed_ms: 1711222894131,
          },
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "9aa19fbe-f0ff-4c5b-96c3-d188d38a2df5",
            price: 64828.26,
            price_sources: [],
            processed_ms: 1711223186720,
          },
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "8ec28854-6f9a-43a2-8e42-20dc93e2dadc",
            price: 64873.65,
            price_sources: [],
            processed_ms: 1711223510660,
          },
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "75fa458f-be94-4a68-bacd-78d84dcfc140",
            price: 64973.43,
            price_sources: [],
            processed_ms: 1711223793039,
          },
          {
            leverage: 0.3,
            order_type: "FLAT",
            order_uuid: "efb8889d-1a86-4413-b869-18d5ea317433",
            price: 64947.37,
            price_sources: [],
            processed_ms: 1711224091885,
          },
        ],
        position_type: "FLAT",
        position_uuid: "d20799b8-3e61-485e-a403-2bfd49a0c202",
        return_at_close: 0.9969568616105908,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 64897.03599999999,
        close_ms: 1711225892267,
        current_return: 1.0046858767607298,
        initial_entry_price: 64945.37,
        is_closed_position: true,
        miner_hotkey: "5FChPUTXqfNxyrs7wuLyZe9nCGE3UAYwVHcgQMsdYFuHbBmv",
        net_leverage: 0.0,
        open_ms: 1711224388871,
        orders: [
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "fce78f2a-b566-4f34-bd92-0733e0b19726",
            price: 64945.37,
            price_sources: [],
            processed_ms: 1711224388868,
          },
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "681599fe-6999-42c6-a28c-250d519d6d4e",
            price: 64699.31,
            price_sources: [],
            processed_ms: 1711224688255,
          },
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "86ff555b-29a3-4beb-988b-72d7a30b4428",
            price: 64946.23,
            price_sources: [],
            processed_ms: 1711224988981,
          },
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "65e8fa9f-30a0-4695-ad49-30f0005e98fa",
            price: 64945.29,
            price_sources: [],
            processed_ms: 1711225293108,
          },
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "05e129e6-2430-4ae5-8de3-31c2f32cd9ea",
            price: 64948.98,
            price_sources: [],
            processed_ms: 1711225591635,
          },
          {
            leverage: 0.3,
            order_type: "FLAT",
            order_uuid: "41bc463f-2d62-42b2-a006-c8fbd2108c37",
            price: 65099.92,
            price_sources: [],
            processed_ms: 1711225892267,
          },
        ],
        position_type: "FLAT",
        position_uuid: "198b971e-4979-4c41-9652-8ef5b8958719",
        return_at_close: 1.0016718191304477,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 64957.06,
        close_ms: 1711227692826,
        current_return: 1.0009200994301246,
        initial_entry_price: 64998.41,
        is_closed_position: true,
        miner_hotkey: "5FChPUTXqfNxyrs7wuLyZe9nCGE3UAYwVHcgQMsdYFuHbBmv",
        net_leverage: 0.0,
        open_ms: 1711226194879,
        orders: [
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "38bceffa-9270-4bbe-899a-cf0612fae23c",
            price: 64998.41,
            price_sources: [],
            processed_ms: 1711226194876,
          },
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "d40cd58d-43b5-4780-acd9-78de2c10ff89",
            price: 64925.0,
            price_sources: [],
            processed_ms: 1711226515164,
          },
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "09cf798e-4240-4da2-9324-953998224063",
            price: 64965.0,
            price_sources: [],
            processed_ms: 1711226797083,
          },
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "748487dc-8f1a-485f-aac6-9e7b3690690b",
            price: 64893.51,
            price_sources: [],
            processed_ms: 1711227095002,
          },
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "6b1f39b2-713b-4324-b686-94d834bf498d",
            price: 65003.38,
            price_sources: [],
            processed_ms: 1711227398196,
          },
          {
            leverage: 0.3,
            order_type: "FLAT",
            order_uuid: "082336c6-5e78-4c81-bcf8-a47f67b7ab74",
            price: 64996.93,
            price_sources: [],
            processed_ms: 1711227692826,
          },
        ],
        position_type: "FLAT",
        position_uuid: "765dbe7a-05e2-43d6-b15e-102fca052da4",
        return_at_close: 0.9979173391318342,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 64907.469999999994,
        close_ms: 1711229493778,
        current_return: 0.9979366531401277,
        initial_entry_price: 64889.72,
        is_closed_position: true,
        miner_hotkey: "5FChPUTXqfNxyrs7wuLyZe9nCGE3UAYwVHcgQMsdYFuHbBmv",
        net_leverage: 0.0,
        open_ms: 1711227991710,
        orders: [
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "cb6f36dc-32fa-4549-b202-37a61c7b327f",
            price: 64889.72,
            price_sources: [],
            processed_ms: 1711227991707,
          },
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "8539776d-5022-4f9f-892e-78d5390908a4",
            price: 64926.64,
            price_sources: [],
            processed_ms: 1711228292874,
          },
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "fc89b05b-7bde-4487-ae40-f686b370adb5",
            price: 64887.37,
            price_sources: [],
            processed_ms: 1711228596902,
          },
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "29c0637b-e686-4ae2-8e26-183b6b79911a",
            price: 64922.58,
            price_sources: [],
            processed_ms: 1711228898593,
          },
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "4b4b98f9-5d14-4689-a21a-b5b106051605",
            price: 64911.04,
            price_sources: [],
            processed_ms: 1711229203128,
          },
          {
            leverage: 0.3,
            order_type: "FLAT",
            order_uuid: "f2104d67-7307-40b3-bf09-319b6a259c79",
            price: 64818.21,
            price_sources: [],
            processed_ms: 1711229493778,
          },
        ],
        position_type: "FLAT",
        position_uuid: "2574b044-a863-444c-8fae-df5fb041f541",
        return_at_close: 0.9949428431807074,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 64933.566,
        close_ms: 1711231292911,
        current_return: 1.0003282759852437,
        initial_entry_price: 64948.4,
        is_closed_position: true,
        miner_hotkey: "5FChPUTXqfNxyrs7wuLyZe9nCGE3UAYwVHcgQMsdYFuHbBmv",
        net_leverage: 0.0,
        open_ms: 1711229791898,
        orders: [
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "4704350f-33d0-4db8-a29f-b7b1033cb83f",
            price: 64948.4,
            price_sources: [],
            processed_ms: 1711229791895,
          },
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "b3dbec0b-3a3f-41d2-9253-0d8ebfc3b1e7",
            price: 64778.38,
            price_sources: [],
            processed_ms: 1711230092936,
          },
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "ced9e81c-9d42-49b8-a4f2-7ecf9b1653fc",
            price: 65015.78,
            price_sources: [],
            processed_ms: 1711230400156,
          },
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "31e32105-51b4-4b07-95cd-9188a826feb8",
            price: 64951.76,
            price_sources: [],
            processed_ms: 1711230691643,
          },
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "371d5c6e-9433-4d05-9d23-2a77dd39ae68",
            price: 64973.51,
            price_sources: [],
            processed_ms: 1711230994780,
          },
          {
            leverage: 0.3,
            order_type: "FLAT",
            order_uuid: "f56ce6ca-8136-4f56-af8b-be4343fa082b",
            price: 64947.78,
            price_sources: [],
            processed_ms: 1711231292911,
          },
        ],
        position_type: "FLAT",
        position_uuid: "efd8e396-e759-4e49-931a-5e79a4a4793d",
        return_at_close: 0.9973272911572879,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 64896.744000000006,
        close_ms: 1711233103079,
        current_return: 1.0018472241695424,
        initial_entry_price: 64975.33,
        is_closed_position: true,
        miner_hotkey: "5FChPUTXqfNxyrs7wuLyZe9nCGE3UAYwVHcgQMsdYFuHbBmv",
        net_leverage: 0.0,
        open_ms: 1711231596649,
        orders: [
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "a000ee38-0fac-4848-98ea-b77cd3521ea8",
            price: 64975.33,
            price_sources: [],
            processed_ms: 1711231596645,
          },
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "5f57fcb7-afd6-4927-aff5-52703c976423",
            price: 64962.74,
            price_sources: [],
            processed_ms: 1711231916422,
          },
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "fbd1b944-23ef-42fb-b2e4-becbf29ffd92",
            price: 64872.89,
            price_sources: [],
            processed_ms: 1711232196666,
          },
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "9198ce1b-f22b-4541-b5c4-7225e04b29bc",
            price: 64842.1,
            price_sources: [],
            processed_ms: 1711232498152,
          },
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "4674960e-143c-42ce-ba1b-c22566283f8c",
            price: 64830.66,
            price_sources: [],
            processed_ms: 1711232803179,
          },
          {
            leverage: 0.3,
            order_type: "FLAT",
            order_uuid: "d0391277-c9b7-4a2e-97f6-227ce4ddc6e0",
            price: 64976.76,
            price_sources: [],
            processed_ms: 1711233103079,
          },
        ],
        position_type: "FLAT",
        position_uuid: "15bceffb-a8a2-4eae-88af-6211b5ba0da8",
        return_at_close: 0.9988416824970338,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 64921.684,
        close_ms: 1711234930439,
        current_return: 0.9963186319697935,
        initial_entry_price: 65011.43,
        is_closed_position: true,
        miner_hotkey: "5FChPUTXqfNxyrs7wuLyZe9nCGE3UAYwVHcgQMsdYFuHbBmv",
        net_leverage: 0.0,
        open_ms: 1711233395798,
        orders: [
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "8c12080d-f22a-4366-99a1-6f109083f013",
            price: 65011.43,
            price_sources: [],
            processed_ms: 1711233395795,
          },
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "a7fa2b49-ac28-4e02-b66e-45b26c165f65",
            price: 64965.0,
            price_sources: [],
            processed_ms: 1711233694009,
          },
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "7a9fc068-9f92-4077-ba2c-e9ac0c22597f",
            price: 64949.53,
            price_sources: [],
            processed_ms: 1711233995657,
          },
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "505ac55a-1cce-4fad-95dc-651e29f6998d",
            price: 64849.78,
            price_sources: [],
            processed_ms: 1711234301299,
          },
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "8120eaee-4284-4e31-8e73-e08331fb709b",
            price: 64832.68,
            price_sources: [],
            processed_ms: 1711234603625,
          },
          {
            leverage: 0.3,
            order_type: "FLAT",
            order_uuid: "9fabe1fd-079d-41ee-b8a6-49e3aa633f46",
            price: 64762.13,
            price_sources: [],
            processed_ms: 1711234930439,
          },
        ],
        position_type: "FLAT",
        position_uuid: "e1ffa965-a8a1-4b2d-8cab-1eb5447d0fe0",
        return_at_close: 0.9933296760738841,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 64682.56799999999,
        close_ms: 1711236697557,
        current_return: 0.9938804295748215,
        initial_entry_price: 64785.92,
        is_closed_position: true,
        miner_hotkey: "5FChPUTXqfNxyrs7wuLyZe9nCGE3UAYwVHcgQMsdYFuHbBmv",
        net_leverage: 0.0,
        open_ms: 1711235200557,
        orders: [
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "42507572-2381-4233-b16a-09a729bc89a4",
            price: 64785.92,
            price_sources: [],
            processed_ms: 1711235200554,
          },
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "0fb02d6a-a5bf-4a2e-9e35-38e2b66701ad",
            price: 64591.35,
            price_sources: [],
            processed_ms: 1711235503063,
          },
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "f7eb3258-e4de-4378-949a-c3d1ef413bc8",
            price: 64639.01,
            price_sources: [],
            processed_ms: 1711235799901,
          },
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "109a4392-9e93-4aaa-b7c8-a647e698e400",
            price: 64764.04,
            price_sources: [],
            processed_ms: 1711236102242,
          },
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "ea75ca1e-fbf8-4578-b6aa-18cada9c7358",
            price: 64632.52,
            price_sources: [],
            processed_ms: 1711236402590,
          },
          {
            leverage: 0.3,
            order_type: "FLAT",
            order_uuid: "aff8a3a2-290e-4a98-a598-fd197e82fbfd",
            price: 64418.26,
            price_sources: [],
            processed_ms: 1711236697557,
          },
        ],
        position_type: "FLAT",
        position_uuid: "f929904f-9394-4af6-ad47-ebfe3f67b06c",
        return_at_close: 0.990898788286097,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 64340.882000000005,
        close_ms: 1711238504176,
        current_return: 0.9928160248447203,
        initial_entry_price: 64400.0,
        is_closed_position: true,
        miner_hotkey: "5FChPUTXqfNxyrs7wuLyZe9nCGE3UAYwVHcgQMsdYFuHbBmv",
        net_leverage: 0.0,
        open_ms: 1711237005419,
        orders: [
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "5dec4d8e-6fea-45d5-b547-72ab2dc85b57",
            price: 64400.0,
            price_sources: [],
            processed_ms: 1711237005415,
          },
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "3d3d7a2f-2928-4c15-9b95-731c10d904b5",
            price: 64417.16,
            price_sources: [],
            processed_ms: 1711237310074,
          },
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "40800ed4-bc9e-4ec1-9b1a-577cbe2ec993",
            price: 64331.84,
            price_sources: [],
            processed_ms: 1711237600118,
          },
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "4208b58f-f52d-46b6-8542-b981abe9123b",
            price: 64393.22,
            price_sources: [],
            processed_ms: 1711237899963,
          },
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "741e154c-96a2-44e8-bbfd-5f7eaeb1603f",
            price: 64162.19,
            price_sources: [],
            processed_ms: 1711238204330,
          },
          {
            leverage: 0.3,
            order_type: "FLAT",
            order_uuid: "f71753e3-60a6-4c6f-8147-e1f6145e0381",
            price: 64032.45,
            price_sources: [],
            processed_ms: 1711238504176,
          },
        ],
        position_type: "FLAT",
        position_uuid: "fff351a4-002b-41f3-85cb-4a514212e876",
        return_at_close: 0.9898375767701861,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
    ],
    thirty_day_returns: 1.0,
  },
  "5FCisP5b74sXkQdZeKoxCttaYv7TXvMjvu9saw6dxU4wh9vp": {
    all_time_returns: 1.0,
    n_positions: 0,
    percentage_profitable: 0.0,
    positions: [
      {
        average_entry_price: 64275.87,
        close_ms: 1711191279675,
        current_return: 1.0008487633072878,
        initial_entry_price: 64275.87,
        is_closed_position: true,
        miner_hotkey: "5FCisP5b74sXkQdZeKoxCttaYv7TXvMjvu9saw6dxU4wh9vp",
        net_leverage: 0.0,
        open_ms: 1711187512694,
        orders: [
          {
            leverage: 0.5,
            order_type: "LONG",
            order_uuid: "6b965d13-a3bf-4cb2-bb96-fb1099086717",
            price: 64275.87,
            price_sources: [],
            processed_ms: 1711187512694,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "598b3ac1-b46b-4c36-8767-336e823a2c56",
            price: 64384.98,
            price_sources: [],
            processed_ms: 1711191279675,
          },
        ],
        position_type: "FLAT",
        position_uuid: "337b6a38-5237-44d1-b6f0-2c31b7c8e551",
        return_at_close: 0.9998479145439805,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 64455.93,
        close_ms: 1711196210065,
        current_return: 1.0012104084139348,
        initial_entry_price: 64455.93,
        is_closed_position: true,
        miner_hotkey: "5FCisP5b74sXkQdZeKoxCttaYv7TXvMjvu9saw6dxU4wh9vp",
        net_leverage: 0.0,
        open_ms: 1711192629639,
        orders: [
          {
            leverage: 0.6,
            order_type: "LONG",
            order_uuid: "e4380036-fb6b-4ca7-8ee9-07d274b98a55",
            price: 64455.93,
            price_sources: [],
            processed_ms: 1711192629638,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "49f99ed0-adcb-433b-bbdb-ae90aaf61c73",
            price: 64585.96,
            price_sources: [],
            processed_ms: 1711196210065,
          },
        ],
        position_type: "FLAT",
        position_uuid: "02af07a2-03a6-4ca6-9e52-271ee62c01a9",
        return_at_close: 1.000008955923838,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 64591.670000000006,
        close_ms: 1711199870836,
        current_return: 0.9993090749937259,
        initial_entry_price: 64591.67,
        is_closed_position: true,
        miner_hotkey: "5FCisP5b74sXkQdZeKoxCttaYv7TXvMjvu9saw6dxU4wh9vp",
        net_leverage: 0.0,
        open_ms: 1711196296881,
        orders: [
          {
            leverage: 0.6,
            order_type: "LONG",
            order_uuid: "4a354870-e20a-4067-ba2a-7018b34afc9e",
            price: 64591.67,
            price_sources: [],
            processed_ms: 1711196296880,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "1d43b6e7-cfd7-4d7a-8d05-9bf03ea235df",
            price: 64517.29,
            price_sources: [],
            processed_ms: 1711199870836,
          },
        ],
        position_type: "FLAT",
        position_uuid: "4809d6d6-c9f4-4286-b21d-6ffa6786af73",
        return_at_close: 0.9981099041037335,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 64607.94000000001,
        close_ms: 1711203525651,
        current_return: 0.9987366258698234,
        initial_entry_price: 64607.94,
        is_closed_position: true,
        miner_hotkey: "5FCisP5b74sXkQdZeKoxCttaYv7TXvMjvu9saw6dxU4wh9vp",
        net_leverage: 0.0,
        open_ms: 1711199996984,
        orders: [
          {
            leverage: 0.6,
            order_type: "LONG",
            order_uuid: "147b5e7f-a2df-4197-88ab-8ae2b9bd0ff9",
            price: 64607.94,
            price_sources: [],
            processed_ms: 1711199996983,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "a1fa4d8d-8633-44b9-955f-fefe578f0bb4",
            price: 64471.9,
            price_sources: [],
            processed_ms: 1711203525651,
          },
        ],
        position_type: "FLAT",
        position_uuid: "b2f9fb90-35e3-4786-9328-f5a8d2f627ec",
        return_at_close: 0.9975381419187797,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 64585.240000000005,
        close_ms: 1711207194692,
        current_return: 1.0039166843693699,
        initial_entry_price: 64585.24,
        is_closed_position: true,
        miner_hotkey: "5FCisP5b74sXkQdZeKoxCttaYv7TXvMjvu9saw6dxU4wh9vp",
        net_leverage: 0.0,
        open_ms: 1711203612991,
        orders: [
          {
            leverage: 0.6,
            order_type: "LONG",
            order_uuid: "cd99a01c-c06d-4d04-9188-7b3d9cdd26dc",
            price: 64585.24,
            price_sources: [],
            processed_ms: 1711203612989,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "9ff16ef7-2c2e-437a-918a-8f9aed6899ff",
            price: 65006.84,
            price_sources: [],
            processed_ms: 1711207194692,
          },
        ],
        position_type: "FLAT",
        position_uuid: "9ab51610-8883-4bc2-ad98-3be6d43fd236",
        return_at_close: 1.0027119843481267,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 64805.25000000001,
        close_ms: 1711210877912,
        current_return: 1.00324436677584,
        initial_entry_price: 64805.25,
        is_closed_position: true,
        miner_hotkey: "5FCisP5b74sXkQdZeKoxCttaYv7TXvMjvu9saw6dxU4wh9vp",
        net_leverage: 0.0,
        open_ms: 1711207281613,
        orders: [
          {
            leverage: 0.6,
            order_type: "LONG",
            order_uuid: "ed695bb3-1435-47fd-a9a2-1d6d66db3bab",
            price: 64805.25,
            price_sources: [],
            processed_ms: 1711207281612,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "3b158fb0-7bed-4881-bc6c-416ef20dff58",
            price: 65155.67,
            price_sources: [],
            processed_ms: 1711210877912,
          },
        ],
        position_type: "FLAT",
        position_uuid: "d0860906-35f8-43d0-a58e-14c59dddd4c4",
        return_at_close: 1.002040473535709,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 65137.80999999999,
        close_ms: 1711214517168,
        current_return: 1.0039011443583996,
        initial_entry_price: 65137.81,
        is_closed_position: true,
        miner_hotkey: "5FCisP5b74sXkQdZeKoxCttaYv7TXvMjvu9saw6dxU4wh9vp",
        net_leverage: 0.0,
        open_ms: 1711210964768,
        orders: [
          {
            leverage: 0.6,
            order_type: "LONG",
            order_uuid: "f8ab40dd-5194-40f1-b045-14dd556aa96e",
            price: 65137.81,
            price_sources: [],
            processed_ms: 1711210964767,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "6b1bb712-46e8-4d54-94d4-92f4d29244bf",
            price: 65561.33,
            price_sources: [],
            processed_ms: 1711214517168,
          },
        ],
        position_type: "FLAT",
        position_uuid: "6f3ea5a3-8733-4b16-9fd3-c04d78f95b43",
        return_at_close: 1.0026964629851696,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 65528.69,
        close_ms: 1711218194280,
        current_return: 1.0003009674083214,
        initial_entry_price: 65528.69,
        is_closed_position: true,
        miner_hotkey: "5FCisP5b74sXkQdZeKoxCttaYv7TXvMjvu9saw6dxU4wh9vp",
        net_leverage: 0.0,
        open_ms: 1711214667392,
        orders: [
          {
            leverage: 0.6,
            order_type: "LONG",
            order_uuid: "965d4827-4c2b-4c68-9045-4ceb2fffc10c",
            price: 65528.69,
            price_sources: [],
            processed_ms: 1711214667391,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "32138056-b93d-40ed-80fe-646a168d5c0c",
            price: 65561.56,
            price_sources: [],
            processed_ms: 1711218194280,
          },
        ],
        position_type: "FLAT",
        position_uuid: "301d8a77-0dba-4f67-a2f7-eae913a23745",
        return_at_close: 0.9991006062474315,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 65522.740000000005,
        close_ms: 1711221849123,
        current_return: 0.9983450325795289,
        initial_entry_price: 65522.74,
        is_closed_position: true,
        miner_hotkey: "5FCisP5b74sXkQdZeKoxCttaYv7TXvMjvu9saw6dxU4wh9vp",
        net_leverage: 0.0,
        open_ms: 1711218297390,
        orders: [
          {
            leverage: 0.6,
            order_type: "LONG",
            order_uuid: "ba7308eb-a234-4993-a8f5-de51115b5ee2",
            price: 65522.74,
            price_sources: [],
            processed_ms: 1711218297389,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "5e7bc4f2-8232-44de-a334-47cfb46e879d",
            price: 65342.01,
            price_sources: [],
            processed_ms: 1711221849123,
          },
        ],
        position_type: "FLAT",
        position_uuid: "5a4ab8e5-77c7-49fe-b8dc-29697eb1e8f7",
        return_at_close: 0.9971470185404335,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 65159.59999999999,
        close_ms: 1711225509873,
        current_return: 0.9981542243967122,
        initial_entry_price: 65159.6,
        is_closed_position: true,
        miner_hotkey: "5FCisP5b74sXkQdZeKoxCttaYv7TXvMjvu9saw6dxU4wh9vp",
        net_leverage: 0.0,
        open_ms: 1711221980614,
        orders: [
          {
            leverage: 0.6,
            order_type: "LONG",
            order_uuid: "0bebbc68-bf51-4952-93b8-3632b9b2eff4",
            price: 65159.6,
            price_sources: [],
            processed_ms: 1711221980613,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "33da7d46-623a-4610-abd2-98e08a65edd1",
            price: 64959.15,
            price_sources: [],
            processed_ms: 1711225509873,
          },
        ],
        position_type: "FLAT",
        position_uuid: "0020df5b-7d7f-4fe5-a463-dc6509761973",
        return_at_close: 0.9969564393274362,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 64948.98,
        close_ms: 1711229173391,
        current_return: 0.9996495095073087,
        initial_entry_price: 64948.98,
        is_closed_position: true,
        miner_hotkey: "5FCisP5b74sXkQdZeKoxCttaYv7TXvMjvu9saw6dxU4wh9vp",
        net_leverage: 0.0,
        open_ms: 1711225595752,
        orders: [
          {
            leverage: 0.6,
            order_type: "LONG",
            order_uuid: "cbfd8df9-e7f0-48f2-b3d5-f108e60bd76e",
            price: 64948.98,
            price_sources: [],
            processed_ms: 1711225595751,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "0a344029-437c-4c6e-914f-391594ed61e0",
            price: 64911.04,
            price_sources: [],
            processed_ms: 1711229173391,
          },
        ],
        position_type: "FLAT",
        position_uuid: "f3e7ce13-7c23-46c9-9c82-d3e4ab1c79f2",
        return_at_close: 0.9984499300958999,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 64858.87999999999,
        close_ms: 1711232833061,
        current_return: 0.9997389409129483,
        initial_entry_price: 64858.88,
        is_closed_position: true,
        miner_hotkey: "5FCisP5b74sXkQdZeKoxCttaYv7TXvMjvu9saw6dxU4wh9vp",
        net_leverage: 0.0,
        open_ms: 1711229260025,
        orders: [
          {
            leverage: 0.6,
            order_type: "LONG",
            order_uuid: "cd230343-78d7-4174-b738-8d42362ba679",
            price: 64858.88,
            price_sources: [],
            processed_ms: 1711229260024,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "4c95f5cc-5dec-4296-949c-40f11ecbf21b",
            price: 64830.66,
            price_sources: [],
            processed_ms: 1711232833061,
          },
        ],
        position_type: "FLAT",
        position_uuid: "4165633a-c3a8-4ab5-acbb-2f3bd1835fcd",
        return_at_close: 0.9985392541838528,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 64948.6,
        close_ms: 1711236499811,
        current_return: 0.9961067059182185,
        initial_entry_price: 64948.6,
        is_closed_position: true,
        miner_hotkey: "5FCisP5b74sXkQdZeKoxCttaYv7TXvMjvu9saw6dxU4wh9vp",
        net_leverage: 0.0,
        open_ms: 1711232987136,
        orders: [
          {
            leverage: 0.6,
            order_type: "LONG",
            order_uuid: "f1dbe355-ea42-467c-8ed3-b2709db74d04",
            price: 64948.6,
            price_sources: [],
            processed_ms: 1711232987134,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "e51e06b6-5e13-40d9-bb3f-a0c2416c8e54",
            price: 64527.16,
            price_sources: [],
            processed_ms: 1711236499811,
          },
        ],
        position_type: "FLAT",
        position_uuid: "781ad89b-bc05-4221-993f-660ac6c5d186",
        return_at_close: 0.9949113778711166,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 64489.05,
        close_ms: 1711240166937,
        current_return: 0.9990212291854198,
        initial_entry_price: 64489.05,
        is_closed_position: true,
        miner_hotkey: "5FCisP5b74sXkQdZeKoxCttaYv7TXvMjvu9saw6dxU4wh9vp",
        net_leverage: 0.0,
        open_ms: 1711236586777,
        orders: [
          {
            leverage: 0.6,
            order_type: "LONG",
            order_uuid: "9b1d4662-ee32-4d7f-aed2-a4b396722e05",
            price: 64489.05,
            price_sources: [],
            processed_ms: 1711236586775,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "0c0e35e6-0a2b-4223-a1b4-fbfd5256bbdb",
            price: 64383.85,
            price_sources: [],
            processed_ms: 1711240166937,
          },
        ],
        position_type: "FLAT",
        position_uuid: "4ed7b42a-2db7-4fa5-8db6-7e0ddb7b900f",
        return_at_close: 0.9978224037103973,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 64382.93000000001,
        close_ms: 1711243847062,
        current_return: 1.0021705442731481,
        initial_entry_price: 64382.93,
        is_closed_position: true,
        miner_hotkey: "5FCisP5b74sXkQdZeKoxCttaYv7TXvMjvu9saw6dxU4wh9vp",
        net_leverage: 0.0,
        open_ms: 1711240253834,
        orders: [
          {
            leverage: 0.6,
            order_type: "LONG",
            order_uuid: "77e0b40f-5f42-4b34-b263-f07a87953c8d",
            price: 64382.93,
            price_sources: [],
            processed_ms: 1711240253832,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "3d7864d7-6ec7-4d4a-9337-a22c65cb890a",
            price: 64615.84,
            price_sources: [],
            processed_ms: 1711243847062,
          },
        ],
        position_type: "FLAT",
        position_uuid: "ff4958a4-3006-4e13-ae72-4b3b02996bc4",
        return_at_close: 1.0009679396200204,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 64579.55000000001,
        close_ms: 1711247503917,
        current_return: 0.9975203605475726,
        initial_entry_price: 64579.55,
        is_closed_position: true,
        miner_hotkey: "5FCisP5b74sXkQdZeKoxCttaYv7TXvMjvu9saw6dxU4wh9vp",
        net_leverage: 0.0,
        open_ms: 1711243933816,
        orders: [
          {
            leverage: 0.6,
            order_type: "LONG",
            order_uuid: "271371ce-02bb-4d39-ad3b-523e3f0ce876",
            price: 64579.55,
            price_sources: [],
            processed_ms: 1711243933815,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "33de0b7f-fc7b-4175-9456-b6df41ac0679",
            price: 64312.66,
            price_sources: [],
            processed_ms: 1711247503917,
          },
        ],
        position_type: "FLAT",
        position_uuid: "572debb5-8809-42c8-b6d3-76fa3b51e6a9",
        return_at_close: 0.9963233361149156,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 64292.42,
        close_ms: 1711251161891,
        current_return: 0.9989873456311025,
        initial_entry_price: 64292.42,
        is_closed_position: true,
        miner_hotkey: "5FCisP5b74sXkQdZeKoxCttaYv7TXvMjvu9saw6dxU4wh9vp",
        net_leverage: 0.0,
        open_ms: 1711247606811,
        orders: [
          {
            leverage: 0.6,
            order_type: "LONG",
            order_uuid: "6b2cbbae-d828-49df-b4f7-c828a8b4f837",
            price: 64292.42,
            price_sources: [],
            processed_ms: 1711247606809,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "72762a80-bf08-463c-8c82-1169c53d99e0",
            price: 64183.91,
            price_sources: [],
            processed_ms: 1711251161891,
          },
        ],
        position_type: "FLAT",
        position_uuid: "02047425-ada4-485c-ac0c-5c31475c633c",
        return_at_close: 0.9977885608163451,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 64133.33,
        close_ms: 1711254888658,
        current_return: 0.998220862693392,
        initial_entry_price: 64133.33,
        is_closed_position: true,
        miner_hotkey: "5FCisP5b74sXkQdZeKoxCttaYv7TXvMjvu9saw6dxU4wh9vp",
        net_leverage: 0.0,
        open_ms: 1711251251167,
        orders: [
          {
            leverage: 0.6,
            order_type: "LONG",
            order_uuid: "de17de04-f96e-4168-a2f0-54d2442d1c60",
            price: 64133.33,
            price_sources: [],
            processed_ms: 1711251251165,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "a5f797e4-6209-4107-ae00-61854868a85e",
            price: 63943.16,
            price_sources: [],
            processed_ms: 1711254888658,
          },
        ],
        position_type: "FLAT",
        position_uuid: "5ea25dfd-368c-4c84-994d-bb2ab3ec1941",
        return_at_close: 0.99702299765816,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 63943.16,
        close_ms: 1711256261944,
        current_return: 1.002361972727028,
        initial_entry_price: 63943.16,
        is_closed_position: true,
        miner_hotkey: "5FCisP5b74sXkQdZeKoxCttaYv7TXvMjvu9saw6dxU4wh9vp",
        net_leverage: 0.0,
        open_ms: 1711254908264,
        orders: [
          {
            leverage: 0.6,
            order_type: "LONG",
            order_uuid: "e37d5c7a-82b2-4751-a940-2bb26905d392",
            price: 63943.16,
            price_sources: [],
            processed_ms: 1711254908263,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "51f31ff4-ef90-456a-b875-e42938285d65",
            price: 64194.88,
            price_sources: [],
            processed_ms: 1711256261944,
          },
        ],
        position_type: "FLAT",
        position_uuid: "6784b89e-ed60-42b0-b463-867a57d3bfde",
        return_at_close: 1.0011591383597558,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 64220.38,
        close_ms: 1711259563026,
        current_return: 1.0013584472717227,
        initial_entry_price: 64220.38,
        is_closed_position: true,
        miner_hotkey: "5FCisP5b74sXkQdZeKoxCttaYv7TXvMjvu9saw6dxU4wh9vp",
        net_leverage: 0.0,
        open_ms: 1711256349808,
        orders: [
          {
            leverage: 0.6,
            order_type: "LONG",
            order_uuid: "e2e1d7e3-98d7-40d0-9281-d2a84d27f4a0",
            price: 64220.38,
            price_sources: [],
            processed_ms: 1711256349807,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "d69eca6b-62a5-4278-b165-94b30b1f736f",
            price: 64365.78,
            price_sources: [],
            processed_ms: 1711259563026,
          },
        ],
        position_type: "FLAT",
        position_uuid: "5618f606-be6d-4ad8-be89-854c332d2a4c",
        return_at_close: 1.0001568171349966,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 64368.530000000006,
        close_ms: 1711263232214,
        current_return: 0.9960481465088606,
        initial_entry_price: 64368.53,
        is_closed_position: true,
        miner_hotkey: "5FCisP5b74sXkQdZeKoxCttaYv7TXvMjvu9saw6dxU4wh9vp",
        net_leverage: 0.0,
        open_ms: 1711259649846,
        orders: [
          {
            leverage: 1.25,
            order_type: "LONG",
            order_uuid: "7349fea4-e876-4fd3-afaa-e4a322ebfad7",
            price: 64368.53,
            price_sources: [],
            processed_ms: 1711259649844,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "0d6e8e4f-9e5d-45a8-b04e-8099ae9c275d",
            price: 64165.03,
            price_sources: [],
            processed_ms: 1711263232214,
          },
        ],
        position_type: "FLAT",
        position_uuid: "26516fde-431e-4164-84d1-37976f15f8a2",
        return_at_close: 0.9935580261425886,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 64194.91000000001,
        close_ms: 1711266890958,
        current_return: 1.0035067032573142,
        initial_entry_price: 64194.91,
        is_closed_position: true,
        miner_hotkey: "5FCisP5b74sXkQdZeKoxCttaYv7TXvMjvu9saw6dxU4wh9vp",
        net_leverage: 0.0,
        open_ms: 1711263337168,
        orders: [
          {
            leverage: 1.25,
            order_type: "LONG",
            order_uuid: "2b2f02f7-c0a9-40e5-b1e1-7fc32695a7b5",
            price: 64194.91,
            price_sources: [],
            processed_ms: 1711263337166,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "d084bf8f-cf04-4df4-af50-2f62bf65ea9a",
            price: 64375.0,
            price_sources: [],
            processed_ms: 1711266890958,
          },
        ],
        position_type: "FLAT",
        position_uuid: "03f1b0c1-305a-47b7-9f32-188b3bd4c2d9",
        return_at_close: 1.000997936499171,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 64328.04,
        close_ms: 1711270593597,
        current_return: 1.0143790328447748,
        initial_entry_price: 64328.04,
        is_closed_position: true,
        miner_hotkey: "5FCisP5b74sXkQdZeKoxCttaYv7TXvMjvu9saw6dxU4wh9vp",
        net_leverage: 0.0,
        open_ms: 1711266979612,
        orders: [
          {
            leverage: 1.25,
            order_type: "LONG",
            order_uuid: "a4756a1b-edb6-42eb-9e35-dac6a080b578",
            price: 64328.04,
            price_sources: [],
            processed_ms: 1711266978198,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "06814a89-e031-4c23-bc5b-3eb5d733b746",
            price: 65068.02,
            price_sources: [],
            processed_ms: 1711270593597,
          },
        ],
        position_type: "FLAT",
        position_uuid: "e3feb702-f573-434a-917a-39fafc3eef8a",
        return_at_close: 1.011843085262663,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 64858.95,
        close_ms: 1711277917038,
        current_return: 1.0054691758038021,
        initial_entry_price: 64858.95,
        is_closed_position: true,
        miner_hotkey: "5FCisP5b74sXkQdZeKoxCttaYv7TXvMjvu9saw6dxU4wh9vp",
        net_leverage: 0.0,
        open_ms: 1711274295778,
        orders: [
          {
            leverage: 1.25,
            order_type: "LONG",
            order_uuid: "758ceedb-f220-45f5-8e7e-5f08f0c9b814",
            price: 64858.95,
            price_sources: [],
            processed_ms: 1711274295777,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "5d7cb771-e9f7-4440-9c5a-75b6cc6f94b5",
            price: 65142.73,
            price_sources: [],
            processed_ms: 1711277917038,
          },
        ],
        position_type: "FLAT",
        position_uuid: "24f36596-1d02-4713-bc1d-9cae8cd46738",
        return_at_close: 1.0029555028642927,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 65717.34,
        close_ms: 1711312406880,
        current_return: 1.02492757058232,
        initial_entry_price: 65142.73,
        is_closed_position: true,
        miner_hotkey: "5FCisP5b74sXkQdZeKoxCttaYv7TXvMjvu9saw6dxU4wh9vp",
        net_leverage: 0.0,
        open_ms: 1711277935971,
        orders: [
          {
            leverage: 1.25,
            order_type: "LONG",
            order_uuid: "c778fcb3-6efb-486d-8f8c-902b703ae456",
            price: 65142.73,
            price_sources: [],
            processed_ms: 1711277935969,
          },
          {
            leverage: 1.25,
            order_type: "LONG",
            order_uuid: "02bd9815-377a-4083-b52d-b75d91b012e7",
            price: 66291.95,
            price_sources: [],
            processed_ms: 1711311452770,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "140592f9-31ac-4c3e-b409-b1d732b9682f",
            price: 66366.88,
            price_sources: [],
            processed_ms: 1711312406880,
          },
        ],
        position_type: "FLAT",
        position_uuid: "f53d60a9-dcf9-40a4-83e1-28a121d078ca",
        return_at_close: 1.0198029327294085,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 66228.56,
        close_ms: 1711315185796,
        current_return: 1.0034431897658656,
        initial_entry_price: 66228.56,
        is_closed_position: true,
        miner_hotkey: "5FCisP5b74sXkQdZeKoxCttaYv7TXvMjvu9saw6dxU4wh9vp",
        net_leverage: 0.0,
        open_ms: 1711313319977,
        orders: [
          {
            leverage: 1.25,
            order_type: "LONG",
            order_uuid: "bb1a7299-5678-4d14-b48f-52f1a6e538fd",
            price: 66228.56,
            price_sources: [],
            processed_ms: 1711313319975,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "54bd8b26-0cb2-4ed4-ab86-845fa7d94584",
            price: 66410.99,
            price_sources: [],
            processed_ms: 1711315185796,
          },
        ],
        position_type: "FLAT",
        position_uuid: "a1501449-72d5-4920-b5db-ec8e83c00858",
        return_at_close: 1.000934581791451,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 66294.23,
        close_ms: 1711317997343,
        current_return: 1.0083408465563293,
        initial_entry_price: 66294.23,
        is_closed_position: true,
        miner_hotkey: "5FCisP5b74sXkQdZeKoxCttaYv7TXvMjvu9saw6dxU4wh9vp",
        net_leverage: 0.0,
        open_ms: 1711316119607,
        orders: [
          {
            leverage: 1.25,
            order_type: "LONG",
            order_uuid: "1346587f-5066-446b-8fd2-a8df03d315df",
            price: 66294.23,
            price_sources: [],
            processed_ms: 1711316119605,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "b8fe0e77-8f7f-4e79-8122-073395e80e81",
            price: 66736.59,
            price_sources: [],
            processed_ms: 1711317997343,
          },
        ],
        position_type: "FLAT",
        position_uuid: "9358198b-70ed-4e62-afbc-6f095e93d865",
        return_at_close: 1.0058199944399384,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 109769.07999999949,
        close_ms: 1711329196924,
        current_return: 1.0022457869883168,
        initial_entry_price: 67087.4,
        is_closed_position: true,
        miner_hotkey: "5FCisP5b74sXkQdZeKoxCttaYv7TXvMjvu9saw6dxU4wh9vp",
        net_leverage: 0.0,
        open_ms: 1711321718064,
        orders: [
          {
            leverage: -1.25,
            order_type: "SHORT",
            order_uuid: "d3ee0871-7e32-409a-b287-ae288f165362",
            price: 67087.4,
            price_sources: [],
            processed_ms: 1711321718062,
          },
          {
            leverage: 0.125,
            order_type: "LONG",
            order_uuid: "252b0144-39e8-4aba-8bba-296fc9062bf0",
            price: 67329.63,
            price_sources: [],
            processed_ms: 1711322656260,
          },
          {
            leverage: 0.125,
            order_type: "LONG",
            order_uuid: "4036e292-ad6e-42b6-b648-8b771ec65f9d",
            price: 67254.43,
            price_sources: [],
            processed_ms: 1711323589158,
          },
          {
            leverage: 0.125,
            order_type: "LONG",
            order_uuid: "f5be9162-6b9e-4986-a730-5fe3e6a670e0",
            price: 67255.01,
            price_sources: [],
            processed_ms: 1711324521993,
          },
          {
            leverage: 0.7,
            order_type: "LONG",
            order_uuid: "dbfda544-2288-4bd0-ad6b-e5c5be4e3c1e",
            price: 66841.12,
            price_sources: [],
            processed_ms: 1711325453213,
          },
          {
            leverage: 0.08750000000000002,
            order_type: "LONG",
            order_uuid: "eb9f9138-5b93-4bdf-87ae-59c758ec6f8c",
            price: 66955.02,
            price_sources: [],
            processed_ms: 1711326385139,
          },
          {
            leverage: 0.07000000000000002,
            order_type: "LONG",
            order_uuid: "30a1ae42-2379-493d-a5c0-e445f0792205",
            price: 66657.56,
            price_sources: [],
            processed_ms: 1711327306365,
          },
          {
            leverage: 0.014000000000000002,
            order_type: "LONG",
            order_uuid: "bc1cac01-3432-4106-a332-409d2199f9cb",
            price: 66556.93,
            price_sources: [],
            processed_ms: 1711328238918,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "f344ec11-332a-4882-94a7-cdc692f5dc12",
            price: 66722.22,
            price_sources: [],
            processed_ms: 1711329196924,
          },
        ],
        position_type: "FLAT",
        position_uuid: "a6c29355-8a64-44f6-992d-184a9995fd86",
        return_at_close: 0.999740172520846,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 66632.35,
        close_ms: 1711334810850,
        current_return: 1.0012068087047805,
        initial_entry_price: 66632.35,
        is_closed_position: true,
        miner_hotkey: "5FCisP5b74sXkQdZeKoxCttaYv7TXvMjvu9saw6dxU4wh9vp",
        net_leverage: 0.0,
        open_ms: 1711331139416,
        orders: [
          {
            leverage: 1.25,
            order_type: "LONG",
            order_uuid: "a7173622-76da-47de-b44b-fa04b09e4389",
            price: 66632.35,
            price_sources: [],
            processed_ms: 1711331139414,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "b1fdc535-4cc8-4f06-9d3f-5213b0f8b839",
            price: 66696.68,
            price_sources: [],
            processed_ms: 1711334810850,
          },
        ],
        position_type: "FLAT",
        position_uuid: "612bacc5-5e2a-4df0-853e-809ca8aa90db",
        return_at_close: 0.9987037916830186,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 67252.21,
        close_ms: 1711362294103,
        current_return: 0.9994124728391826,
        initial_entry_price: 67252.21,
        is_closed_position: true,
        miner_hotkey: "5FCisP5b74sXkQdZeKoxCttaYv7TXvMjvu9saw6dxU4wh9vp",
        net_leverage: 0.0,
        open_ms: 1711347633214,
        orders: [
          {
            leverage: 1.25,
            order_type: "LONG",
            order_uuid: "d9de3428-f959-415a-9a48-a3e1a9f6b27b",
            price: 67252.21,
            price_sources: [],
            processed_ms: 1711347633212,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "416f2d9a-68cd-4efb-891a-d99a456c63e4",
            price: 67220.6,
            price_sources: [],
            processed_ms: 1711362294103,
          },
        ],
        position_type: "FLAT",
        position_uuid: "c3a20dc3-ede2-4c89-b9bb-5ba62655c1f8",
        return_at_close: 0.9969139416570847,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: -1764964.8927272733,
        close_ms: 1711386746304,
        current_return: 1.0301297135396574,
        initial_entry_price: 67011.37,
        is_closed_position: true,
        miner_hotkey: "5FCisP5b74sXkQdZeKoxCttaYv7TXvMjvu9saw6dxU4wh9vp",
        net_leverage: 0.0,
        open_ms: 1711364124053,
        orders: [
          {
            leverage: 1.25,
            order_type: "LONG",
            order_uuid: "1d624bcf-6931-4328-9732-ac260a879f33",
            price: 67011.37,
            price_sources: [],
            processed_ms: 1711364124051,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "349a2e18-5f1a-42b4-9851-e9a28f46f497",
            price: 66842.88,
            price_sources: [],
            processed_ms: 1711367743012,
          },
          {
            leverage: -0.75,
            order_type: "SHORT",
            order_uuid: "0f57dcb7-5182-4a94-b447-0829a16ce7c0",
            price: 67131.89,
            price_sources: [],
            processed_ms: 1711369598621,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "26596c8f-23a9-49ab-9516-770035eb5961",
            price: 67035.48,
            price_sources: [],
            processed_ms: 1711371430697,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "e3920aec-cf66-49b0-83a6-c68a64f905c3",
            price: 66950.51,
            price_sources: [],
            processed_ms: 1711373241225,
          },
          {
            leverage: -0.625,
            order_type: "SHORT",
            order_uuid: "61d6234b-8c9f-424c-8bba-17cfb56ec1e5",
            price: 67666.99,
            price_sources: [],
            processed_ms: 1711375069195,
          },
          {
            leverage: -0.5,
            order_type: "SHORT",
            order_uuid: "78ca3d37-8b9c-43ab-80a3-640e1e79b4cb",
            price: 69235.28,
            price_sources: [],
            processed_ms: 1711376573468,
          },
          {
            leverage: -0.1,
            order_type: "SHORT",
            order_uuid: "091a782a-8648-400a-abab-7ebc292574e9",
            price: 69358.28,
            price_sources: [],
            processed_ms: 1711376883443,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "9c437c11-e214-4ee1-9af6-17273c43713d",
            price: 69144.78,
            price_sources: [],
            processed_ms: 1711378031063,
          },
          {
            leverage: -0.22000000000000003,
            order_type: "SHORT",
            order_uuid: "343b8348-6127-4e56-8783-7c175e57f2d0",
            price: 69295.36,
            price_sources: [],
            processed_ms: 1711378306848,
          },
          {
            leverage: -0.044,
            order_type: "SHORT",
            order_uuid: "9b829d38-5639-4e04-9913-3c1b0292dcdc",
            price: 69720.19,
            price_sources: [],
            processed_ms: 1711381292436,
          },
          {
            leverage: -0.005499999999999998,
            order_type: "SHORT",
            order_uuid: "282a86da-52c0-4396-9572-5f8cf7c144c2",
            price: 69650.0,
            price_sources: [],
            processed_ms: 1711383115123,
          },
          {
            leverage: -0.0043999999999999985,
            order_type: "SHORT",
            order_uuid: "8deb554a-fa80-4144-9e70-9c9a6e294ac3",
            price: 70247.63,
            price_sources: [],
            processed_ms: 1711384929790,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "0eaddae7-fbdd-4fec-8f06-f14d3fbc40c6",
            price: 70520.0,
            price_sources: [],
            processed_ms: 1711386746304,
          },
        ],
        position_type: "FLAT",
        position_uuid: "e74b16ad-6074-4232-ac5e-434a210bac83",
        return_at_close: 1.0270393243990386,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 69942.56999999998,
        close_ms: 1711397653710,
        current_return: 0.9965737920203811,
        initial_entry_price: 70527.68,
        is_closed_position: true,
        miner_hotkey: "5FCisP5b74sXkQdZeKoxCttaYv7TXvMjvu9saw6dxU4wh9vp",
        net_leverage: 0.0,
        open_ms: 1711388609470,
        orders: [
          {
            leverage: -1.25,
            order_type: "SHORT",
            order_uuid: "ad56c353-6f84-46f2-b832-d33f108428bd",
            price: 70527.68,
            price_sources: [],
            processed_ms: 1711388609468,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "ebb6274f-61f1-4ac9-a566-b3fbcfeb6a2e",
            price: 70667.02,
            price_sources: [],
            processed_ms: 1711390428358,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "b194cf86-6dc9-408d-bb4d-19cd26f6842d",
            price: 70588.91,
            price_sources: [],
            processed_ms: 1711392195484,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "865317fc-9604-4c9a-a927-14ba9c5927df",
            price: 70673.59,
            price_sources: [],
            processed_ms: 1711394017337,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "cd333ad9-cf4b-44f9-9d62-31bdfbabd182",
            price: 70766.31,
            price_sources: [],
            processed_ms: 1711395829189,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "dd5a3bac-3f6f-4849-9ce6-b9ade24c4f86",
            price: 70909.14,
            price_sources: [],
            processed_ms: 1711397653710,
          },
        ],
        position_type: "FLAT",
        position_uuid: "1a3f218f-4720-4862-8806-e9352fceddfc",
        return_at_close: 0.9940823575403303,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 595292.7100000074,
        close_ms: 1711412194009,
        current_return: 1.009265883140761,
        initial_entry_price: 70880.66,
        is_closed_position: true,
        miner_hotkey: "5FCisP5b74sXkQdZeKoxCttaYv7TXvMjvu9saw6dxU4wh9vp",
        net_leverage: 0.0,
        open_ms: 1711399475218,
        orders: [
          {
            leverage: -1.25,
            order_type: "SHORT",
            order_uuid: "3c483d20-44f7-4baa-934a-9007c809d3e1",
            price: 70880.66,
            price_sources: [],
            processed_ms: 1711399475216,
          },
          {
            leverage: 0.625,
            order_type: "LONG",
            order_uuid: "c2b56615-ea2a-4273-8720-fa571c14c66c",
            price: 70518.71,
            price_sources: [],
            processed_ms: 1711401310154,
          },
          {
            leverage: 0.5,
            order_type: "LONG",
            order_uuid: "0e1bb7b2-5821-49c5-a192-1271db861984",
            price: 70138.62,
            price_sources: [],
            processed_ms: 1711403154246,
          },
          {
            leverage: 0.1,
            order_type: "LONG",
            order_uuid: "1df7a9ae-9067-48bf-abfe-9257123e7e49",
            price: 70413.18,
            price_sources: [],
            processed_ms: 1711404925557,
          },
          {
            leverage: 0.012499999999999997,
            order_type: "LONG",
            order_uuid: "82dfec6f-72e7-457e-b5f4-09a7e4cfe1fc",
            price: 70604.42,
            price_sources: [],
            processed_ms: 1711406735368,
          },
          {
            leverage: 0.006249999999999999,
            order_type: "LONG",
            order_uuid: "3512e69c-fe4e-4e70-98de-327b3c31f052",
            price: 70383.21,
            price_sources: [],
            processed_ms: 1711408555579,
          },
          {
            leverage: 0.004999999999999999,
            order_type: "LONG",
            order_uuid: "1c521bff-fdd7-45f8-82e5-436f70c315be",
            price: 69887.41,
            price_sources: [],
            processed_ms: 1711410368984,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "88a34de8-46ab-4809-a259-b91db32117ac",
            price: 69875.18,
            price_sources: [],
            processed_ms: 1711412194009,
          },
        ],
        position_type: "FLAT",
        position_uuid: "90ddadd5-ccf3-47ae-b9bc-7a1439b5fbcb",
        return_at_close: 1.0067427184329092,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: -45737.61000000261,
        close_ms: 1711428535001,
        current_return: 1.002593246269748,
        initial_entry_price: 70095.8,
        is_closed_position: true,
        miner_hotkey: "5FCisP5b74sXkQdZeKoxCttaYv7TXvMjvu9saw6dxU4wh9vp",
        net_leverage: 0.0,
        open_ms: 1711414017836,
        orders: [
          {
            leverage: 1.25,
            order_type: "LONG",
            order_uuid: "70f66d98-e009-4a51-a996-64699e776d60",
            price: 70095.8,
            price_sources: [],
            processed_ms: 1711414017834,
          },
          {
            leverage: -0.625,
            order_type: "SHORT",
            order_uuid: "cb1dd303-f1b4-496d-b336-b537c39494af",
            price: 70240.91,
            price_sources: [],
            processed_ms: 1711415903508,
          },
          {
            leverage: -0.3125,
            order_type: "SHORT",
            order_uuid: "76ca9689-662a-4587-a3c4-af90db7693ad",
            price: 70241.43,
            price_sources: [],
            processed_ms: 1711417645455,
          },
          {
            leverage: -0.15625,
            order_type: "SHORT",
            order_uuid: "b61d69c9-e909-4d86-878b-24eee1763b17",
            price: 70135.18,
            price_sources: [],
            processed_ms: 1711419457276,
          },
          {
            leverage: -0.078125,
            order_type: "SHORT",
            order_uuid: "ae5ceea3-a7da-4e6f-8289-31214e2a3b9f",
            price: 70230.98,
            price_sources: [],
            processed_ms: 1711421269545,
          },
          {
            leverage: -0.0390625,
            order_type: "SHORT",
            order_uuid: "946ef410-10f3-4a69-b61a-fa26ce37d4fe",
            price: 70427.01,
            price_sources: [],
            processed_ms: 1711423105461,
          },
          {
            leverage: -0.03125,
            order_type: "SHORT",
            order_uuid: "13f0f865-5bcb-4cb6-bacb-e235f94c2c9a",
            price: 70490.81,
            price_sources: [],
            processed_ms: 1711424907346,
          },
          {
            leverage: -0.00625,
            order_type: "SHORT",
            order_uuid: "112599b8-710f-450c-bdc7-092c31fdde65",
            price: 70542.29,
            price_sources: [],
            processed_ms: 1711426726477,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "8a01cd93-811d-410d-92e9-a042cdec87a9",
            price: 70598.82,
            price_sources: [],
            processed_ms: 1711428535001,
          },
        ],
        position_type: "FLAT",
        position_uuid: "a21d1969-ebb1-446b-90c3-69b731a4daa5",
        return_at_close: 1.0000867631540737,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 71113.66999999998,
        close_ms: 1711437620442,
        current_return: 1.0003098203956173,
        initial_entry_price: 70532.8,
        is_closed_position: true,
        miner_hotkey: "5FCisP5b74sXkQdZeKoxCttaYv7TXvMjvu9saw6dxU4wh9vp",
        net_leverage: 0.0,
        open_ms: 1711430352154,
        orders: [
          {
            leverage: -1.25,
            order_type: "SHORT",
            order_uuid: "31d1a97b-3f8a-42f0-9597-3bc8c4d10263",
            price: 70532.8,
            price_sources: [],
            processed_ms: 1711430352151,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "09f5b9bd-5621-4afc-a2cb-10cb458f8cc5",
            price: 70333.14,
            price_sources: [],
            processed_ms: 1711432225694,
          },
          {
            leverage: 0.5,
            order_type: "LONG",
            order_uuid: "066fd7ba-0480-4bb3-b1e9-ef1d8253e931",
            price: 70372.38,
            price_sources: [],
            processed_ms: 1711433985379,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "46899aba-b698-431f-9873-fbce1ff40b7d",
            price: 70472.43,
            price_sources: [],
            processed_ms: 1711435807900,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "53a66187-c3ea-493e-ba4c-ab1fc2043104",
            price: 71026.26,
            price_sources: [],
            processed_ms: 1711437620442,
          },
        ],
        position_type: "FLAT",
        position_uuid: "daf950c3-4775-48d8-aae9-832a490e1678",
        return_at_close: 0.9978090458446284,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 77271.43999999989,
        close_ms: 1711446706673,
        current_return: 1.0053116886351894,
        initial_entry_price: 71056.97,
        is_closed_position: true,
        miner_hotkey: "5FCisP5b74sXkQdZeKoxCttaYv7TXvMjvu9saw6dxU4wh9vp",
        net_leverage: 0.0,
        open_ms: 1711439505179,
        orders: [
          {
            leverage: -1.25,
            order_type: "SHORT",
            order_uuid: "850e7954-fe67-4f90-8284-5adea1d00044",
            price: 71056.97,
            price_sources: [],
            processed_ms: 1711439505176,
          },
          {
            leverage: 1.0,
            order_type: "LONG",
            order_uuid: "4e79c1a2-0d5c-41c6-bc50-b983bbb696f2",
            price: 70737.86,
            price_sources: [],
            processed_ms: 1711441256159,
          },
          {
            leverage: 0.125,
            order_type: "LONG",
            order_uuid: "ede43b32-c462-426b-96de-28701f4ff051",
            price: 70573.19,
            price_sources: [],
            processed_ms: 1711443069511,
          },
          {
            leverage: 0.0625,
            order_type: "LONG",
            order_uuid: "6478c319-9810-473e-b914-ce3e866be4f0",
            price: 70915.82,
            price_sources: [],
            processed_ms: 1711444983423,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "8327b104-d232-442e-a97c-52ca06c47970",
            price: 71232.52,
            price_sources: [],
            processed_ms: 1711446706673,
          },
        ],
        position_type: "FLAT",
        position_uuid: "73285e3d-9224-4119-8a2d-b068c0bee4d5",
        return_at_close: 1.0027984094136015,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 70362.843,
        close_ms: 1711471559658,
        current_return: 0.9712679030890103,
        initial_entry_price: 70707.77,
        is_closed_position: true,
        miner_hotkey: "5FCisP5b74sXkQdZeKoxCttaYv7TXvMjvu9saw6dxU4wh9vp",
        net_leverage: 0.0,
        open_ms: 1711461375100,
        orders: [
          {
            leverage: 1.25,
            order_type: "LONG",
            order_uuid: "abe7cdae-9d71-4010-a842-8a8899d8b9f3",
            price: 70707.77,
            price_sources: [],
            processed_ms: 1711461375097,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "de133a27-abd5-46c4-929e-aef4c961e8b1",
            price: 69942.42,
            price_sources: [],
            processed_ms: 1711463073917,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "0cbe4354-3a06-4115-a2c9-e5e0023a3be0",
            price: 70270.93,
            price_sources: [],
            processed_ms: 1711464889456,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "ac96d774-1e81-49ec-bed9-50e51cf4c07d",
            price: 69893.3,
            price_sources: [],
            processed_ms: 1711466758681,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "aded0d2a-4e8d-4ebb-9ace-6a2fbc4d2ba3",
            price: 70242.94,
            price_sources: [],
            processed_ms: 1711468526292,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "7ebca4df-d977-4d3f-8075-ec5a6013e56b",
            price: 69739.99,
            price_sources: [],
            processed_ms: 1711470394487,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "0a55597b-f57c-4581-a7cd-bcd8881941d6",
            price: 69550.21,
            price_sources: [],
            processed_ms: 1711471559658,
          },
        ],
        position_type: "FLAT",
        position_uuid: "f76bf4df-5d52-46af-b6dd-a0ff2141cd5b",
        return_at_close: 0.9664115635735653,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 69903.711,
        close_ms: 1711501169278,
        current_return: 1.0564341486618019,
        initial_entry_price: 69649.23,
        is_closed_position: true,
        miner_hotkey: "5FCisP5b74sXkQdZeKoxCttaYv7TXvMjvu9saw6dxU4wh9vp",
        net_leverage: 0.0,
        open_ms: 1711472167297,
        orders: [
          {
            leverage: 1.25,
            order_type: "LONG",
            order_uuid: "7b09d5b2-c9a2-4e64-ad4b-b636eea95190",
            price: 69649.23,
            price_sources: [],
            processed_ms: 1711472167294,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "9219c798-f003-40c5-a8b0-a9396543b240",
            price: 69896.13,
            price_sources: [],
            processed_ms: 1711473973706,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "23bbf919-d8cf-451a-9fc9-b134ef97d6f5",
            price: 70138.69,
            price_sources: [],
            processed_ms: 1711475927881,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "c836caab-f2e2-4198-9d7e-79997b25ca51",
            price: 70216.15,
            price_sources: [],
            processed_ms: 1711477611557,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "3e6c4a6a-2d38-4216-889f-320c21864841",
            price: 70270.74,
            price_sources: [],
            processed_ms: 1711479439564,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "26c5fa52-94c6-45e8-8845-f78a0b7e6c97",
            price: 69762.07,
            price_sources: [],
            processed_ms: 1711481248533,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "ccf51856-91a8-4298-8eaf-609b71faefab",
            price: 69674.09,
            price_sources: [],
            processed_ms: 1711483069498,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "4fec2724-96f1-407e-a5ca-76dbb7696109",
            price: 69724.81,
            price_sources: [],
            processed_ms: 1711484923630,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "26e0e202-a4dc-4abf-8a68-f318bfa78275",
            price: 69742.04,
            price_sources: [],
            processed_ms: 1711486702230,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "adeca660-d16e-411f-af9f-0b7a04bacb46",
            price: 70092.66,
            price_sources: [],
            processed_ms: 1711488517299,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "783c4485-bd74-4cb1-bdea-220a5c1d87a1",
            price: 70023.08,
            price_sources: [],
            processed_ms: 1711490331276,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "40e4c63d-d33e-453e-982a-713f0263e239",
            price: 69895.23,
            price_sources: [],
            processed_ms: 1711492225292,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "d8e80d1e-6853-4c4f-b7e2-3d9a75ee42f5",
            price: 70194.34,
            price_sources: [],
            processed_ms: 1711493964384,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "e31db4ff-ecde-4dcc-836c-2e2f68d7eac2",
            price: 70093.25,
            price_sources: [],
            processed_ms: 1711495786720,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "bbbd35a5-43cf-4ec1-9ae0-3945261e3abe",
            price: 69990.18,
            price_sources: [],
            processed_ms: 1711497600249,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "749b5ad4-e89a-4436-99eb-774f874992ee",
            price: 70114.61,
            price_sources: [],
            processed_ms: 1711499419016,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "6b09f32d-9a5e-4695-a2a7-04003dc1b203",
            price: 70689.83,
            price_sources: [],
            processed_ms: 1711501169278,
          },
        ],
        position_type: "FLAT",
        position_uuid: "eb4b0dc6-5b38-4838-8ab7-fdf49fb6052a",
        return_at_close: 1.0458698071751837,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 70315.76818181819,
        close_ms: 1711524631809,
        current_return: 0.9715158833209767,
        initial_entry_price: 70330.38,
        is_closed_position: true,
        miner_hotkey: "5FCisP5b74sXkQdZeKoxCttaYv7TXvMjvu9saw6dxU4wh9vp",
        net_leverage: 0.0,
        open_ms: 1711504860902,
        orders: [
          {
            leverage: 1.25,
            order_type: "LONG",
            order_uuid: "e153b362-7453-483f-9ac7-19d9a6e19347",
            price: 70330.38,
            price_sources: [],
            processed_ms: 1711504860900,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "9158d1af-9e66-42cc-9457-99033e7b3e1f",
            price: 70406.23,
            price_sources: [],
            processed_ms: 1711506683222,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "8972816f-ca96-489f-bb83-51ab09433f2a",
            price: 70584.9,
            price_sources: [],
            processed_ms: 1711508513338,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "d5e94532-64ef-4f0c-a946-c0dc8f727a96",
            price: 70531.41,
            price_sources: [],
            processed_ms: 1711510318320,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "f82cceef-6ac4-4b6d-9dfd-c8a0f6ce0908",
            price: 70395.0,
            price_sources: [],
            processed_ms: 1711512140523,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "07f38d3f-1aa6-4aa2-868c-3afde9bf5d6e",
            price: 70279.95,
            price_sources: [],
            processed_ms: 1711513951385,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "b34c73c2-5c07-4d1a-bbb4-4f2b4be14741",
            price: 70216.69,
            price_sources: [],
            processed_ms: 1711515773424,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "cf08dd2e-1839-4f19-9c0c-6b430159850b",
            price: 70364.31,
            price_sources: [],
            processed_ms: 1711517587186,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "1e89bba2-0f42-4e73-9a88-6ebd86e41976",
            price: 70411.03,
            price_sources: [],
            processed_ms: 1711519397134,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "7852f9a5-347c-49b2-9f90-25143825e4c8",
            price: 70257.49,
            price_sources: [],
            processed_ms: 1711521223294,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "c7b69edb-bd3b-46f6-8801-382b1d6652b6",
            price: 70250.48,
            price_sources: [],
            processed_ms: 1711523084014,
          },
          {
            leverage: 0.375,
            order_type: "LONG",
            order_uuid: "869653dc-ec7a-4724-9bf6-5423b390a3e9",
            price: 69907.19,
            price_sources: [],
            processed_ms: 1711524250037,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "39ef1fbd-e951-451f-b5a6-610cbbd32b6e",
            price: 69830.12,
            price_sources: [],
            processed_ms: 1711524631809,
          },
        ],
        position_type: "FLAT",
        position_uuid: "6f0da714-8446-4a68-b037-64c089e6291d",
        return_at_close: 0.9635008772835787,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 69873.34548387099,
        close_ms: 1711545028440,
        current_return: 1.0581786395992983,
        initial_entry_price: 69678.0,
        is_closed_position: true,
        miner_hotkey: "5FCisP5b74sXkQdZeKoxCttaYv7TXvMjvu9saw6dxU4wh9vp",
        net_leverage: 0.0,
        open_ms: 1711526076724,
        orders: [
          {
            leverage: 1.25,
            order_type: "LONG",
            order_uuid: "f975f8d7-3606-49f8-bc7b-17c3df0323eb",
            price: 69678.0,
            price_sources: [],
            processed_ms: 1711526076721,
          },
          {
            leverage: 0.375,
            order_type: "LONG",
            order_uuid: "e4426fa1-a907-4297-b720-04737a1648c3",
            price: 69895.2,
            price_sources: [],
            processed_ms: 1711527874518,
          },
          {
            leverage: 0.375,
            order_type: "LONG",
            order_uuid: "ce766fdb-52e3-433f-bfd4-2d754562cdab",
            price: 69627.4,
            price_sources: [],
            processed_ms: 1711529683786,
          },
          {
            leverage: 0.375,
            order_type: "LONG",
            order_uuid: "c51dbd40-f3d8-49fd-afd0-b4d9c8c9935f",
            price: 69808.71,
            price_sources: [],
            processed_ms: 1711531498104,
          },
          {
            leverage: 0.375,
            order_type: "LONG",
            order_uuid: "e9b9a28b-ee3d-4980-ab2f-e610e6f42466",
            price: 69980.7,
            price_sources: [],
            processed_ms: 1711533318564,
          },
          {
            leverage: 0.375,
            order_type: "LONG",
            order_uuid: "c24c63c8-619a-42da-84b2-cf3087ff09d4",
            price: 70110.85,
            price_sources: [],
            processed_ms: 1711535137620,
          },
          {
            leverage: 0.375,
            order_type: "LONG",
            order_uuid: "998b180e-cf51-4897-b284-98bd3f45a394",
            price: 70090.0,
            price_sources: [],
            processed_ms: 1711539100304,
          },
          {
            leverage: 0.375,
            order_type: "LONG",
            order_uuid: "626cd40a-a83f-4a24-a110-9e6ba5c0b53c",
            price: 70251.71,
            price_sources: [],
            processed_ms: 1711542715596,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "b39247ee-2269-4be0-9dae-893c5a76f364",
            price: 70919.48,
            price_sources: [],
            processed_ms: 1711545028440,
          },
        ],
        position_type: "FLAT",
        position_uuid: "29c0eb46-d7e3-4700-98dd-22fe657f68da",
        return_at_close: 1.0499777551424037,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 89566.10999999968,
        close_ms: 1711561713223,
        current_return: 0.9885082826014124,
        initial_entry_price: 69783.48,
        is_closed_position: true,
        miner_hotkey: "5FCisP5b74sXkQdZeKoxCttaYv7TXvMjvu9saw6dxU4wh9vp",
        net_leverage: 0.0,
        open_ms: 1711549963006,
        orders: [
          {
            leverage: 1.25,
            order_type: "LONG",
            order_uuid: "62e4ae05-1aab-46ed-9a31-783a6b537a4c",
            price: 69783.48,
            price_sources: [],
            processed_ms: 1711549963002,
          },
          {
            leverage: -0.625,
            order_type: "SHORT",
            order_uuid: "ea365d7e-0868-4501-a422-e8e04a44d7ab",
            price: 69324.76,
            price_sources: [],
            processed_ms: 1711552412181,
          },
          {
            leverage: -0.3125,
            order_type: "SHORT",
            order_uuid: "b15add3c-6799-49e4-b63c-bcd40be5701f",
            price: 69030.59,
            price_sources: [],
            processed_ms: 1711554451331,
          },
          {
            leverage: -0.15625,
            order_type: "SHORT",
            order_uuid: "3891bd3c-c84c-4a18-b2da-2bede62ef4be",
            price: 68655.73,
            price_sources: [],
            processed_ms: 1711556269388,
          },
          {
            leverage: -0.078125,
            order_type: "SHORT",
            order_uuid: "e73403c7-abc6-4a7f-b3ac-54f47888602f",
            price: 69064.3,
            price_sources: [],
            processed_ms: 1711558081467,
          },
          {
            leverage: -0.0390625,
            order_type: "SHORT",
            order_uuid: "71311e18-de95-4aa6-8249-03c8297bc7c0",
            price: 69312.85,
            price_sources: [],
            processed_ms: 1711559900173,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "ba5e53ae-c5dd-4228-8c57-b7a3f2e697df",
            price: 69036.65,
            price_sources: [],
            processed_ms: 1711561713223,
          },
        ],
        position_type: "FLAT",
        position_uuid: "5a0d215e-152b-4072-8af8-fb7c494d0bbc",
        return_at_close: 0.986037011894909,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 67586.24999999994,
        close_ms: 1711578075277,
        current_return: 0.9927729832059576,
        initial_entry_price: 68886.93,
        is_closed_position: true,
        miner_hotkey: "5FCisP5b74sXkQdZeKoxCttaYv7TXvMjvu9saw6dxU4wh9vp",
        net_leverage: 0.0,
        open_ms: 1711570801317,
        orders: [
          {
            leverage: -3.5,
            order_type: "SHORT",
            order_uuid: "80d44d49-2d90-42ac-857d-bd77e8e3b377",
            price: 68886.93,
            price_sources: [],
            processed_ms: 1711570801314,
          },
          {
            leverage: 1.0499999999999998,
            order_type: "LONG",
            order_uuid: "dfbe7044-9862-4ad9-9017-76e6040d284c",
            price: 68889.44,
            price_sources: [],
            processed_ms: 1711572622668,
          },
          {
            leverage: 1.05,
            order_type: "LONG",
            order_uuid: "e4219a44-d5a1-44d4-b5b3-afc5932c765a",
            price: 69113.12,
            price_sources: [],
            processed_ms: 1711574442421,
          },
          {
            leverage: 1.05,
            order_type: "LONG",
            order_uuid: "363a6bd5-10c5-4092-bd5d-52ee3d0cdc37",
            price: 69091.79,
            price_sources: [],
            processed_ms: 1711576261458,
          },
          {
            leverage: 1.05,
            order_type: "LONG",
            order_uuid: "f32b636d-7966-4f54-9f51-347b82b0d860",
            price: 69008.67,
            price_sources: [],
            processed_ms: 1711578075277,
          },
        ],
        position_type: "FLAT",
        position_uuid: "f34aaf84-7d92-4c98-87f7-e4a0f312d783",
        return_at_close: 0.9858235723235159,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 69335.53,
        close_ms: 1711587197249,
        current_return: 0.9970160516359962,
        initial_entry_price: 69374.86,
        is_closed_position: true,
        miner_hotkey: "5FCisP5b74sXkQdZeKoxCttaYv7TXvMjvu9saw6dxU4wh9vp",
        net_leverage: 0.0,
        open_ms: 1711579886853,
        orders: [
          {
            leverage: -3.5,
            order_type: "SHORT",
            order_uuid: "29d64430-66af-4d48-a337-6d6e21720ed7",
            price: 69374.86,
            price_sources: [],
            processed_ms: 1711579886850,
          },
          {
            leverage: 1.0499999999999998,
            order_type: "LONG",
            order_uuid: "ad197e7f-73c4-47ff-9080-2f1f5a862b19",
            price: 69216.24,
            price_sources: [],
            processed_ms: 1711581720399,
          },
          {
            leverage: 1.05,
            order_type: "LONG",
            order_uuid: "26fa46b8-1963-4e3f-8193-ab208358671a",
            price: 69252.14,
            price_sources: [],
            processed_ms: 1711583539306,
          },
          {
            leverage: 0.7000000000000001,
            order_type: "LONG",
            order_uuid: "ee8271b5-486a-40ed-8fef-eb69f45e5680",
            price: 69836.2,
            price_sources: [],
            processed_ms: 1711585366394,
          },
          {
            leverage: 1.05,
            order_type: "LONG",
            order_uuid: "f44f4041-01f8-41ab-8ff4-4d62deb79540",
            price: 69631.26,
            price_sources: [],
            processed_ms: 1711587197249,
          },
        ],
        position_type: "FLAT",
        position_uuid: "fbc2b73c-94f3-444e-b592-fd778d727f58",
        return_at_close: 0.9900369392745442,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 71340.16428571429,
        close_ms: 1711598099956,
        current_return: 1.0092687380686023,
        initial_entry_price: 69491.02,
        is_closed_position: true,
        miner_hotkey: "5FCisP5b74sXkQdZeKoxCttaYv7TXvMjvu9saw6dxU4wh9vp",
        net_leverage: 0.0,
        open_ms: 1711589010157,
        orders: [
          {
            leverage: -3.5,
            order_type: "SHORT",
            order_uuid: "cf05d797-e055-4984-944e-95dfab82d679",
            price: 69491.02,
            price_sources: [],
            processed_ms: 1711589010153,
          },
          {
            leverage: 1.0499999999999998,
            order_type: "LONG",
            order_uuid: "ddfc2252-1cf3-42a0-b8bb-6e2aa2be0097",
            price: 69400.85,
            price_sources: [],
            processed_ms: 1711590826083,
          },
          {
            leverage: 1.225,
            order_type: "LONG",
            order_uuid: "6c0eb4d2-0cca-44b7-b200-e78c053ff6ef",
            price: 69413.89,
            price_sources: [],
            processed_ms: 1711592642556,
          },
          {
            leverage: 0.6125,
            order_type: "LONG",
            order_uuid: "58c2ab13-aec9-4fa5-b82f-a0c64e4c0de1",
            price: 69038.56,
            price_sources: [],
            processed_ms: 1711594459157,
          },
          {
            leverage: 0.30625,
            order_type: "LONG",
            order_uuid: "b604226d-3939-44f4-8333-2d054bd22368",
            price: 69164.47,
            price_sources: [],
            processed_ms: 1711596288578,
          },
          {
            leverage: 1.05,
            order_type: "LONG",
            order_uuid: "bab84b57-9aa1-4c05-84c5-316605ca762b",
            price: 69237.0,
            price_sources: [],
            processed_ms: 1711598099956,
          },
        ],
        position_type: "FLAT",
        position_uuid: "744a0791-4e45-40a1-8666-972b23cf7cf9",
        return_at_close: 1.002203856902122,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 71056.51,
        close_ms: 1711633222501,
        current_return: 1.0129027586634918,
        initial_entry_price: 71056.51,
        is_closed_position: true,
        miner_hotkey: "5FCisP5b74sXkQdZeKoxCttaYv7TXvMjvu9saw6dxU4wh9vp",
        net_leverage: 0.0,
        open_ms: 1711633083202,
        orders: [
          {
            leverage: -3.5,
            order_type: "SHORT",
            order_uuid: "a0b12d72-1a7e-481c-b701-d9eaccb10e48",
            price: 71056.51,
            price_sources: [],
            processed_ms: 1711633083194,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "2f45a67f-0f33-423c-aca4-5f9ec3d25fd7",
            price: 70794.56,
            price_sources: [],
            processed_ms: 1711633222501,
          },
        ],
        position_type: "FLAT",
        position_uuid: "0cb6572e-88c6-4508-8a98-a95ccdaa86d4",
        return_at_close: 1.0058124393528474,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 71157.75,
        close_ms: 1711635057692,
        current_return: 1.007538321546142,
        initial_entry_price: 71157.75,
        is_closed_position: true,
        miner_hotkey: "5FCisP5b74sXkQdZeKoxCttaYv7TXvMjvu9saw6dxU4wh9vp",
        net_leverage: 0.0,
        open_ms: 1711634902431,
        orders: [
          {
            leverage: -3.5,
            order_type: "SHORT",
            order_uuid: "7bfbf3b7-7b67-4112-b1bd-f43cc2542b42",
            price: 71157.75,
            price_sources: [],
            processed_ms: 1711634902427,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "ba62d1da-888c-4880-9504-d4e9e7b8ac02",
            price: 71004.49,
            price_sources: [],
            processed_ms: 1711635057692,
          },
        ],
        position_type: "FLAT",
        position_uuid: "53e18e6d-2a1f-4614-b772-cdfaa3ece3fa",
        return_at_close: 1.000485553295319,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 71268.1,
        close_ms: 1711636853167,
        current_return: 0.9946572870611118,
        initial_entry_price: 71268.1,
        is_closed_position: true,
        miner_hotkey: "5FCisP5b74sXkQdZeKoxCttaYv7TXvMjvu9saw6dxU4wh9vp",
        net_leverage: 0.0,
        open_ms: 1711636728671,
        orders: [
          {
            leverage: -3.5,
            order_type: "SHORT",
            order_uuid: "7412bc6a-f248-45de-b12f-1c027044e743",
            price: 71268.1,
            price_sources: [],
            processed_ms: 1711636728667,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "7158a4cd-185c-40b9-8306-a56ed57b06e7",
            price: 71376.89,
            price_sources: [],
            processed_ms: 1711636853167,
          },
        ],
        position_type: "FLAT",
        position_uuid: "142e5fae-a1b7-403e-a36f-935bfa91df83",
        return_at_close: 0.987694686051684,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 71364.97,
        close_ms: 1711638722832,
        current_return: 1.0033531156812647,
        initial_entry_price: 71364.97,
        is_closed_position: true,
        miner_hotkey: "5FCisP5b74sXkQdZeKoxCttaYv7TXvMjvu9saw6dxU4wh9vp",
        net_leverage: 0.0,
        open_ms: 1711638552171,
        orders: [
          {
            leverage: -3.5,
            order_type: "SHORT",
            order_uuid: "3edb50a9-70e7-42d5-8e3d-8f5f58e4f6c2",
            price: 71364.97,
            price_sources: [],
            processed_ms: 1711638552167,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "28e546f3-d208-49dc-9adb-76d183fd117f",
            price: 71296.6,
            price_sources: [],
            processed_ms: 1711638722832,
          },
        ],
        position_type: "FLAT",
        position_uuid: "672fee77-0c2a-4a7e-84c0-1bcfabc28a09",
        return_at_close: 0.9963296438714959,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 71895.41,
        close_ms: 1711681069514,
        current_return: 1.0107948305983103,
        initial_entry_price: 70892.15,
        is_closed_position: true,
        miner_hotkey: "5FCisP5b74sXkQdZeKoxCttaYv7TXvMjvu9saw6dxU4wh9vp",
        net_leverage: 0.0,
        open_ms: 1711666615843,
        orders: [
          {
            leverage: -5.0,
            order_type: "SHORT",
            order_uuid: "cb9a734b-2d2c-450c-baaf-4d4dc534424b",
            price: 70892.15,
            price_sources: [],
            processed_ms: 1711666615839,
          },
          {
            leverage: 2.5,
            order_type: "LONG",
            order_uuid: "d2da6610-b803-42ff-b549-e29ad3b35d32",
            price: 70743.98,
            price_sources: [],
            processed_ms: 1711670227292,
          },
          {
            leverage: 1.25,
            order_type: "LONG",
            order_uuid: "9f65d589-0991-47aa-ad74-e1410ff39d1d",
            price: 70722.66,
            price_sources: [],
            processed_ms: 1711673835210,
          },
          {
            leverage: 0.625,
            order_type: "LONG",
            order_uuid: "71d687c9-d74b-4ac3-b27e-3a06f66bda39",
            price: 70820.55,
            price_sources: [],
            processed_ms: 1711677456018,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "34e517a7-ab15-4deb-8b1d-501e39e1335c",
            price: 70670.98,
            price_sources: [],
            processed_ms: 1711681069514,
          },
        ],
        position_type: "FLAT",
        position_uuid: "4913ee4b-dc84-4639-ad8f-6b9f1bf883ea",
        return_at_close: 1.0006868822923272,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 70401.945625,
        close_ms: 1711692479269,
        current_return: 0.9661761646590513,
        initial_entry_price: 70469.98,
        is_closed_position: true,
        miner_hotkey: "5FCisP5b74sXkQdZeKoxCttaYv7TXvMjvu9saw6dxU4wh9vp",
        net_leverage: 0.0,
        open_ms: 1711684690760,
        orders: [
          {
            leverage: 5.0,
            order_type: "LONG",
            order_uuid: "ce5b49fa-dc45-49ed-9f89-6cb0f4c128b8",
            price: 70469.98,
            price_sources: [],
            processed_ms: 1711684690755,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "65c811ba-d2bb-4b81-9dbe-261afe0ac2a3",
            price: 70355.43,
            price_sources: [],
            processed_ms: 1711688301083,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "593a3c18-727f-47aa-8cd7-9f413b11971e",
            price: 70221.68,
            price_sources: [],
            processed_ms: 1711691927564,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "07343f63-3074-4ed7-bdf1-b25f86c35eb1",
            price: 70104.0,
            price_sources: [],
            processed_ms: 1711692479269,
          },
        ],
        position_type: "FLAT",
        position_uuid: "3bafca71-34d0-4cb9-bfb1-c3ea34813d53",
        return_at_close: 0.9507173460245065,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 64371.31081081061,
        close_ms: 1711749900401,
        current_return: 1.0429162828924319,
        initial_entry_price: 69881.58,
        is_closed_position: true,
        miner_hotkey: "5FCisP5b74sXkQdZeKoxCttaYv7TXvMjvu9saw6dxU4wh9vp",
        net_leverage: 0.0,
        open_ms: 1711699166580,
        orders: [
          {
            leverage: 5.0,
            order_type: "LONG",
            order_uuid: "8dc724da-c522-4b28-8422-131706a0bb22",
            price: 69881.58,
            price_sources: [],
            processed_ms: 1711699166576,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "a57c488d-066b-478c-9e87-5b784bc33f3c",
            price: 69951.65,
            price_sources: [],
            processed_ms: 1711702796532,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "3df63925-55b1-4f9b-b311-27398fcc6763",
            price: 69732.77,
            price_sources: [],
            processed_ms: 1711706409226,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "3d4fcd7d-ce8e-48aa-91c6-7c33cb6c5789",
            price: 70112.39,
            price_sources: [],
            processed_ms: 1711710040744,
          },
          {
            leverage: -4.75,
            order_type: "SHORT",
            order_uuid: "18a325f7-0d02-463d-9db2-119d79cabe0d",
            price: 70160.01,
            price_sources: [],
            processed_ms: 1711713653125,
          },
          {
            leverage: -3.125,
            order_type: "SHORT",
            order_uuid: "ae8f1a5b-6630-4ef9-be73-ca7fe997e7d4",
            price: 70163.25,
            price_sources: [],
            processed_ms: 1711717296259,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "993b39c0-d15b-46ad-8259-19ff99499914",
            price: 70378.33,
            price_sources: [],
            processed_ms: 1711720974695,
          },
          {
            leverage: -2.3125,
            order_type: "SHORT",
            order_uuid: "6806011f-9603-49c4-8537-fc6c0911891c",
            price: 70011.63,
            price_sources: [],
            processed_ms: 1711724530187,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "1a6bfe1b-7dc3-4253-829b-fc7915429ef8",
            price: 69310.89,
            price_sources: [],
            processed_ms: 1711728141685,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "e8e3cc0b-d9ef-4b76-aa27-15d10ae5fb95",
            price: 69282.52,
            price_sources: [],
            processed_ms: 1711731757333,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "180fc84d-db3b-48d5-a79e-a46620927515",
            price: 69451.06,
            price_sources: [],
            processed_ms: 1711735443640,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "312869b7-deed-4f21-9d9c-e4f9d4effc14",
            price: 69490.95,
            price_sources: [],
            processed_ms: 1711739008843,
          },
          {
            leverage: -4.15625,
            order_type: "SHORT",
            order_uuid: "cb5cad73-6047-4cf0-b936-c6ca9b2307b0",
            price: 69687.59,
            price_sources: [],
            processed_ms: 1711742643512,
          },
          {
            leverage: -2.078125,
            order_type: "SHORT",
            order_uuid: "45918f0f-56bc-4e55-8e91-b3536256075f",
            price: 69629.08,
            price_sources: [],
            processed_ms: 1711746247202,
          },
          {
            leverage: -1.0390625,
            order_type: "SHORT",
            order_uuid: "53f75eea-93b7-4e2c-af16-e478e8fa5685",
            price: 69558.87,
            price_sources: [],
            processed_ms: 1711749900401,
          },
        ],
        position_type: "FLAT",
        position_uuid: "cfe689da-a698-4390-8511-c799de69b820",
        return_at_close: 1.0231008735174756,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 69847.22333333333,
        close_ms: 1711760731603,
        current_return: 0.9992106838196833,
        initial_entry_price: 69838.76,
        is_closed_position: true,
        miner_hotkey: "5FCisP5b74sXkQdZeKoxCttaYv7TXvMjvu9saw6dxU4wh9vp",
        net_leverage: 0.0,
        open_ms: 1711753491227,
        orders: [
          {
            leverage: -0.51953125,
            order_type: "SHORT",
            order_uuid: "83be8e22-1b36-4b91-9f87-b3c2ae2a4c05",
            price: 69838.76,
            price_sources: [],
            processed_ms: 1711753491223,
          },
          {
            leverage: -0.259765625,
            order_type: "SHORT",
            order_uuid: "04e50d63-3027-4eb5-8f33-1ff14aa55083",
            price: 69864.15,
            price_sources: [],
            processed_ms: 1711757128927,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "4febf024-bc34-4528-bf90-67cdd62714bd",
            price: 69917.96,
            price_sources: [],
            processed_ms: 1711760731603,
          },
        ],
        position_type: "FLAT",
        position_uuid: "ea3306e3-13bf-4075-989b-bcd068a5f023",
        return_at_close: 0.9976533202929486,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 69990.47,
        close_ms: 1711767973070,
        current_return: 1.000235103436225,
        initial_entry_price: 69990.47,
        is_closed_position: true,
        miner_hotkey: "5FCisP5b74sXkQdZeKoxCttaYv7TXvMjvu9saw6dxU4wh9vp",
        net_leverage: 0.0,
        open_ms: 1711764362350,
        orders: [
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "30d44523-5e17-4325-9233-fe1fb5c720f8",
            price: 69990.47,
            price_sources: [],
            processed_ms: 1711764362345,
          },
          {
            leverage: -1.6298828125,
            order_type: "SHORT",
            order_uuid: "6349e8d6-b2ee-49a6-9a15-9dd9df36b517",
            price: 70001.44,
            price_sources: [],
            processed_ms: 1711767973070,
          },
        ],
        position_type: "FLAT",
        position_uuid: "69113b73-b2c8-44aa-97a5-e487fefee92d",
        return_at_close: 0.9972343981259162,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 69840.19,
        close_ms: 1711775210090,
        current_return: 1.0102901352358864,
        initial_entry_price: 69840.19,
        is_closed_position: true,
        miner_hotkey: "5FCisP5b74sXkQdZeKoxCttaYv7TXvMjvu9saw6dxU4wh9vp",
        net_leverage: 0.0,
        open_ms: 1711771593147,
        orders: [
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "0c2c4a17-ed13-435e-a4f1-55795d48df86",
            price: 69840.19,
            price_sources: [],
            processed_ms: 1711771593143,
          },
          {
            leverage: -1.56494140625,
            order_type: "SHORT",
            order_uuid: "f500497e-186e-4bd5-a966-b0ee188ba1f1",
            price: 70319.3,
            price_sources: [],
            processed_ms: 1711775210090,
          },
        ],
        position_type: "FLAT",
        position_uuid: "8ff6281c-56bb-4809-a023-53bc8d1f371f",
        return_at_close: 1.0072592648301788,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 69988.8,
        close_ms: 1711786123034,
        current_return: 1.0010955877784073,
        initial_entry_price: 69988.2,
        is_closed_position: true,
        miner_hotkey: "5FCisP5b74sXkQdZeKoxCttaYv7TXvMjvu9saw6dxU4wh9vp",
        net_leverage: 0.0,
        open_ms: 1711778867955,
        orders: [
          {
            leverage: -0.782470703125,
            order_type: "SHORT",
            order_uuid: "fed6c9ea-c7a0-4bf6-82de-293763c6dbb5",
            price: 69988.2,
            price_sources: [],
            processed_ms: 1711778867951,
          },
          {
            leverage: -0.3912353515625,
            order_type: "SHORT",
            order_uuid: "626144bb-6b0a-4e71-a272-d2ace66dd65d",
            price: 69990.0,
            price_sources: [],
            processed_ms: 1711782473076,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "94caa65a-3805-4679-8057-0e3a8064cbeb",
            price: 69923.47,
            price_sources: [],
            processed_ms: 1711786123034,
          },
        ],
        position_type: "FLAT",
        position_uuid: "0cc06a16-b7f3-45ce-bc5a-4a9fff695d5a",
        return_at_close: 0.9987456038730145,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 69375.23999999999,
        close_ms: 1711804180989,
        current_return: 0.9874822396745734,
        initial_entry_price: 69973.38,
        is_closed_position: true,
        miner_hotkey: "5FCisP5b74sXkQdZeKoxCttaYv7TXvMjvu9saw6dxU4wh9vp",
        net_leverage: 0.0,
        open_ms: 1711789694172,
        orders: [
          {
            leverage: -5.0,
            order_type: "SHORT",
            order_uuid: "6a9fef14-5ff9-40ae-b8ca-403daf4df61b",
            price: 69973.38,
            price_sources: [],
            processed_ms: 1711789694168,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "dd70147b-9bc5-403a-9839-9a785b9cf37b",
            price: 70039.86,
            price_sources: [],
            processed_ms: 1711793316204,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "0ce8927b-6b92-4f87-881d-662c95fade10",
            price: 70159.46,
            price_sources: [],
            processed_ms: 1711796933727,
          },
          {
            leverage: 1.0,
            order_type: "LONG",
            order_uuid: "51117e8d-0c34-4f48-af2f-c8658b3a0a63",
            price: 70192.68,
            price_sources: [],
            processed_ms: 1711800552732,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "10b69e6e-6b38-4ecb-a645-5ab5dcc92974",
            price: 70251.15,
            price_sources: [],
            processed_ms: 1711804180989,
          },
        ],
        position_type: "FLAT",
        position_uuid: "b55e1e4d-1d4f-4d25-af49-17fe024ab388",
        return_at_close: 0.9776074172778276,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 71781.99379310322,
        close_ms: 1711840405260,
        current_return: 0.9934369002445952,
        initial_entry_price: 70169.23,
        is_closed_position: true,
        miner_hotkey: "5FCisP5b74sXkQdZeKoxCttaYv7TXvMjvu9saw6dxU4wh9vp",
        net_leverage: 0.0,
        open_ms: 1711807796934,
        orders: [
          {
            leverage: 5.0,
            order_type: "LONG",
            order_uuid: "206ae2b5-fc1f-4b14-8964-7a3977661d0b",
            price: 70169.23,
            price_sources: [],
            processed_ms: 1711807796930,
          },
          {
            leverage: -2.5,
            order_type: "SHORT",
            order_uuid: "771c2409-daf1-4a1b-9da1-f6b7531258f5",
            price: 70180.4,
            price_sources: [],
            processed_ms: 1711811437686,
          },
          {
            leverage: -1.25,
            order_type: "SHORT",
            order_uuid: "d09211c8-08c7-4a00-bad2-af37893723ca",
            price: 70007.32,
            price_sources: [],
            processed_ms: 1711815050091,
          },
          {
            leverage: -0.625,
            order_type: "SHORT",
            order_uuid: "db10c069-e472-49db-b373-7ef3f05166ee",
            price: 70155.25,
            price_sources: [],
            processed_ms: 1711818679406,
          },
          {
            leverage: -0.3125,
            order_type: "SHORT",
            order_uuid: "3c349d16-4470-4591-bfdc-ebe7b44e66ad",
            price: 69982.31,
            price_sources: [],
            processed_ms: 1711822292706,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "ddbb3d3a-14ef-4c2f-af99-41f9cf55cdc7",
            price: 69860.39,
            price_sources: [],
            processed_ms: 1711826013803,
          },
          {
            leverage: -0.90625,
            order_type: "SHORT",
            order_uuid: "41b3bbea-63de-4d7c-9663-e0d08acc9ad3",
            price: 69827.46,
            price_sources: [],
            processed_ms: 1711829540251,
          },
          {
            leverage: -0.453125,
            order_type: "SHORT",
            order_uuid: "535282fc-8901-4c75-ac82-1b7e83a7057d",
            price: 69795.59,
            price_sources: [],
            processed_ms: 1711833220837,
          },
          {
            leverage: -0.2265625,
            order_type: "SHORT",
            order_uuid: "8dea2595-cdd7-406a-a5db-c6cafc0788ca",
            price: 69692.52,
            price_sources: [],
            processed_ms: 1711836778116,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "43491954-8ae0-4e43-8015-efe3ca5621c6",
            price: 69749.32,
            price_sources: [],
            processed_ms: 1711840405260,
          },
        ],
        position_type: "FLAT",
        position_uuid: "b2ccea15-4280-4ba7-97d0-a6c0d72a370f",
        return_at_close: 0.9835025312421493,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 69835.94999999998,
        close_ms: 1711876594825,
        current_return: 1.0036883479703385,
        initial_entry_price: 70239.78,
        is_closed_position: true,
        miner_hotkey: "5FCisP5b74sXkQdZeKoxCttaYv7TXvMjvu9saw6dxU4wh9vp",
        net_leverage: 0.0,
        open_ms: 1711862122224,
        orders: [
          {
            leverage: 5.0,
            order_type: "LONG",
            order_uuid: "48a671cf-facf-4847-a010-7cc71ba5684c",
            price: 70239.78,
            price_sources: [],
            processed_ms: 1711862122220,
          },
          {
            leverage: -2.5,
            order_type: "SHORT",
            order_uuid: "cc5926fe-4925-4130-98ce-36f2231c7011",
            price: 70296.57,
            price_sources: [],
            processed_ms: 1711865736888,
          },
          {
            leverage: -1.25,
            order_type: "SHORT",
            order_uuid: "623cbee0-2fcc-4b8c-9766-291f25ac228f",
            price: 70290.51,
            price_sources: [],
            processed_ms: 1711869351920,
          },
          {
            leverage: -0.625,
            order_type: "SHORT",
            order_uuid: "4b1a1d30-1908-44b0-9ec1-3ef58822cd00",
            price: 70314.99,
            price_sources: [],
            processed_ms: 1711872972544,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "5a646381-467a-4f80-8759-5d27b2f5264e",
            price: 70250.46,
            price_sources: [],
            processed_ms: 1711876594825,
          },
        ],
        position_type: "FLAT",
        position_uuid: "db227b21-0dd6-4b43-82c0-e3ac1d168fc3",
        return_at_close: 0.993651464490635,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 69181.66428571436,
        close_ms: 1711894698101,
        current_return: 0.9834233645357098,
        initial_entry_price: 70289.52,
        is_closed_position: true,
        miner_hotkey: "5FCisP5b74sXkQdZeKoxCttaYv7TXvMjvu9saw6dxU4wh9vp",
        net_leverage: 0.0,
        open_ms: 1711880219426,
        orders: [
          {
            leverage: -5.0,
            order_type: "SHORT",
            order_uuid: "e69dcd42-bb09-4bac-8b89-864200ab6eed",
            price: 70289.52,
            price_sources: [],
            processed_ms: 1711880219422,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "2650ad8f-c1ce-4f94-90d8-dfb103b60e88",
            price: 70397.86,
            price_sources: [],
            processed_ms: 1711883835728,
          },
          {
            leverage: 1.75,
            order_type: "LONG",
            order_uuid: "90f19332-9752-492e-bbe3-df2c85f9c110",
            price: 70535.0,
            price_sources: [],
            processed_ms: 1711887450674,
          },
          {
            leverage: 0.875,
            order_type: "LONG",
            order_uuid: "2cacdf54-d984-4649-9a73-15c3aed4dbea",
            price: 70720.69,
            price_sources: [],
            processed_ms: 1711891077640,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "29c51975-715f-4efe-8129-a720f2ea38ca",
            price: 70513.28,
            price_sources: [],
            processed_ms: 1711894698101,
          },
        ],
        position_type: "FLAT",
        position_uuid: "86a78134-f78a-4ec0-843e-868e83606da9",
        return_at_close: 0.9735891308903527,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 70739.635,
        close_ms: 1711916409715,
        current_return: 0.9959688674865244,
        initial_entry_price: 70646.4,
        is_closed_position: true,
        miner_hotkey: "5FCisP5b74sXkQdZeKoxCttaYv7TXvMjvu9saw6dxU4wh9vp",
        net_leverage: 0.0,
        open_ms: 1711901946952,
        orders: [
          {
            leverage: -5.0,
            order_type: "SHORT",
            order_uuid: "28d03165-87e7-4e69-b63d-ad030ffc4928",
            price: 70646.4,
            price_sources: [],
            processed_ms: 1711901946945,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "cab040e5-1966-48de-ae4e-ef4f165f03f7",
            price: 70504.48,
            price_sources: [],
            processed_ms: 1711905590346,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "b2b53131-2685-4e66-a7d9-e5fa4f632780",
            price: 70537.07,
            price_sources: [],
            processed_ms: 1711909173297,
          },
          {
            leverage: 1.0,
            order_type: "LONG",
            order_uuid: "e21ef8f9-a445-4c5b-b805-4db8a48ed4d9",
            price: 70930.04,
            price_sources: [],
            processed_ms: 1711912795330,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "f4905ff2-f238-4a19-b508-8870ebccbffd",
            price: 71024.42,
            price_sources: [],
            processed_ms: 1711916409715,
          },
        ],
        position_type: "FLAT",
        position_uuid: "53598e93-ffd9-43cf-a61b-53da813085fa",
        return_at_close: 0.9860091788116591,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 68001.34000000008,
        close_ms: 1711934519167,
        current_return: 0.9894192026580324,
        initial_entry_price: 70921.64,
        is_closed_position: true,
        miner_hotkey: "5FCisP5b74sXkQdZeKoxCttaYv7TXvMjvu9saw6dxU4wh9vp",
        net_leverage: 0.0,
        open_ms: 1711920026802,
        orders: [
          {
            leverage: -5.0,
            order_type: "SHORT",
            order_uuid: "d4e34d19-3917-45b5-9999-19be9e2c084b",
            price: 70921.64,
            price_sources: [],
            processed_ms: 1711920026793,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "80974c8b-7ccb-45c5-b4ab-e3428e611156",
            price: 70888.93,
            price_sources: [],
            processed_ms: 1711923638739,
          },
          {
            leverage: 1.75,
            order_type: "LONG",
            order_uuid: "e247613e-1db4-49c1-affd-5cbc066acfaa",
            price: 71113.5,
            price_sources: [],
            processed_ms: 1711927269008,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "f7f4e1c9-cc6c-4731-9d47-9179ec2f987a",
            price: 71217.23,
            price_sources: [],
            processed_ms: 1711930902316,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "3b2a5ce3-4715-4e92-9dd2-4730b0fba76d",
            price: 71002.97,
            price_sources: [],
            processed_ms: 1711934519167,
          },
        ],
        position_type: "FLAT",
        position_uuid: "7afdff68-0452-47f9-a952-f50f3c1e783a",
        return_at_close: 0.979525010631452,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 77745.65999999976,
        close_ms: 1711956234716,
        current_return: 1.0282948640865162,
        initial_entry_price: 70902.09,
        is_closed_position: true,
        miner_hotkey: "5FCisP5b74sXkQdZeKoxCttaYv7TXvMjvu9saw6dxU4wh9vp",
        net_leverage: 0.0,
        open_ms: 1711938165590,
        orders: [
          {
            leverage: -5.0,
            order_type: "SHORT",
            order_uuid: "bf68f227-5274-46fb-ae10-07bcb33224e0",
            price: 70902.09,
            price_sources: [],
            processed_ms: 1711938165581,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "c50c2874-e8cf-4b9f-96d6-4bb257ea790b",
            price: 70783.83,
            price_sources: [],
            processed_ms: 1711941745375,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "95db0200-7b5d-4183-89bf-17258b1ac062",
            price: 70573.61,
            price_sources: [],
            processed_ms: 1711945375109,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "e0e5e2d4-80e4-4ea2-a636-90f168d5ab40",
            price: 70449.8,
            price_sources: [],
            processed_ms: 1711948994410,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "7403fdbc-a387-46f2-bf61-bd5e935a3e5a",
            price: 69452.7,
            price_sources: [],
            processed_ms: 1711952659178,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "2760313c-7e3d-4d28-8982-d6815a5d6385",
            price: 69721.0,
            price_sources: [],
            processed_ms: 1711956234716,
          },
        ],
        position_type: "FLAT",
        position_uuid: "a856052c-880a-42f6-95b3-8ab5c2d9097c",
        return_at_close: 1.018011915445651,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 69266.0,
        close_ms: 1711985179552,
        current_return: 1.0048937580904873,
        initial_entry_price: 69680.6,
        is_closed_position: true,
        miner_hotkey: "5FCisP5b74sXkQdZeKoxCttaYv7TXvMjvu9saw6dxU4wh9vp",
        net_leverage: 0.0,
        open_ms: 1711970708861,
        orders: [
          {
            leverage: -5.0,
            order_type: "SHORT",
            order_uuid: "9fbe05df-8adf-4115-8c47-7a5cb21258c3",
            price: 69680.6,
            price_sources: [],
            processed_ms: 1711970708856,
          },
          {
            leverage: 2.5,
            order_type: "LONG",
            order_uuid: "135ad88c-e1d6-4842-85b0-8a2ed48caac8",
            price: 69721.8,
            price_sources: [],
            processed_ms: 1711974327749,
          },
          {
            leverage: 1.25,
            order_type: "LONG",
            order_uuid: "9ee6a6a1-3b04-4273-b6bf-011e7c10af88",
            price: 69886.0,
            price_sources: [],
            processed_ms: 1711977942533,
          },
          {
            leverage: 0.625,
            order_type: "LONG",
            order_uuid: "a5b44c2c-b975-45d9-93cb-f09d6b063a5d",
            price: 69519.6,
            price_sources: [],
            processed_ms: 1711981551663,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "ef74e805-8e4b-485d-8a70-dffac91980a2",
            price: 68720.4,
            price_sources: [],
            processed_ms: 1711985179552,
          },
        ],
        position_type: "FLAT",
        position_uuid: "d348791b-ea41-4f2c-97b5-933a199c111a",
        return_at_close: 0.9948448205095825,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 68570.96923076923,
        close_ms: 1711993191513,
        current_return: 0.9727645203418883,
        initial_entry_price: 68607.2,
        is_closed_position: true,
        miner_hotkey: "5FCisP5b74sXkQdZeKoxCttaYv7TXvMjvu9saw6dxU4wh9vp",
        net_leverage: 0.0,
        open_ms: 1711988794442,
        orders: [
          {
            leverage: 5.0,
            order_type: "LONG",
            order_uuid: "27a5d7b6-e67e-4157-81cf-9a8fee0bc37d",
            price: 68607.2,
            price_sources: [],
            processed_ms: 1711988794437,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "294aedc5-16e5-41bd-a2d9-8d237ade92d0",
            price: 68450.2,
            price_sources: [],
            processed_ms: 1711992449137,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "73c8dfbf-64f3-4daa-99e2-34abbcdd20ca",
            price: 68283.5,
            price_sources: [],
            processed_ms: 1711993191513,
          },
        ],
        position_type: "FLAT",
        position_uuid: "36934eee-b1e0-4175-baf4-bdb3c15677d2",
        return_at_close: 0.9601185815774438,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 69253.38214285715,
        close_ms: 1712018580446,
        current_return: 0.9709949481040426,
        initial_entry_price: 68627.7,
        is_closed_position: true,
        miner_hotkey: "5FCisP5b74sXkQdZeKoxCttaYv7TXvMjvu9saw6dxU4wh9vp",
        net_leverage: 0.0,
        open_ms: 1711996029891,
        orders: [
          {
            leverage: 5.0,
            order_type: "LONG",
            order_uuid: "ef18e76a-5c93-4091-85cd-10c30c344848",
            price: 68627.7,
            price_sources: [],
            processed_ms: 1711996029886,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "315f3b19-ab17-4013-8c6d-7baa8a02d107",
            price: 69485.9,
            price_sources: [],
            processed_ms: 1711999639654,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "9ba24e65-70cb-4963-a462-8d379ca1a8a8",
            price: 69370.6,
            price_sources: [],
            processed_ms: 1712003255400,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "018ffb98-4fa0-4ea5-b313-2026e3a095b5",
            price: 69768.0,
            price_sources: [],
            processed_ms: 1712006878216,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "e14e8840-39f4-4c70-bb3d-6f0b0edbf6a0",
            price: 69747.9,
            price_sources: [],
            processed_ms: 1712010494916,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "14bb2fa6-2a2e-47ab-95f7-2b8bd3c70131",
            price: 69785.4,
            price_sources: [],
            processed_ms: 1712014121415,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "543632e9-bf7e-47fc-a999-7b0041ae3ce6",
            price: 69448.1,
            price_sources: [],
            processed_ms: 1712017774683,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "4d81ca59-4e21-44a9-a82e-82a169425842",
            price: 69111.2,
            price_sources: [],
            processed_ms: 1712018580446,
          },
        ],
        position_type: "FLAT",
        position_uuid: "a02c3bda-793c-4242-888b-e4faeb2c924b",
        return_at_close: 0.9438070895571294,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
    ],
    thirty_day_returns: 1.0,
  },
  "5FEPBMuohFf4wqu7JAc5xV4k8Xam1EoUsbNMp2aB5Z4FvJ7T": {
    all_time_returns: 0.9927015489725609,
    n_positions: 6,
    percentage_profitable: 0.0,
    positions: [
      {
        average_entry_price: 62687.46,
        close_ms: 1719796126272,
        current_return: 0.9991612517080769,
        initial_entry_price: 62687.46,
        is_closed_position: true,
        miner_hotkey: "5FEPBMuohFf4wqu7JAc5xV4k8Xam1EoUsbNMp2aB5Z4FvJ7T",
        net_leverage: 0.0,
        open_ms: 1719792078814,
        orders: [
          {
            leverage: -0.1,
            order_type: "SHORT",
            order_uuid: "ad7efa9b-c6dd-4569-ae1e-4de44e783222",
            price: 62687.46,
            price_sources: [],
            processed_ms: 1719792078466,
          },
          {
            leverage: 0.001,
            order_type: "FLAT",
            order_uuid: "628f7e07-ad11-434f-9fc0-86bf7c67d95e",
            price: 63213.25,
            price_sources: [],
            processed_ms: 1719796126272,
          },
        ],
        position_type: "FLAT",
        position_uuid: "ad7efa9b-c6dd-4569-ae1e-4de44e783222",
        return_at_close: 0.9990613355829061,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 63190.9,
        close_ms: 1719907814470,
        current_return: 0.9988781612542312,
        initial_entry_price: 63190.9,
        is_closed_position: true,
        miner_hotkey: "5FEPBMuohFf4wqu7JAc5xV4k8Xam1EoUsbNMp2aB5Z4FvJ7T",
        net_leverage: 0.0,
        open_ms: 1719850562545,
        orders: [
          {
            leverage: 0.1,
            order_type: "LONG",
            order_uuid: "52a98092-31cd-4c89-bb73-3eddfc7579b7",
            price: 63190.9,
            price_sources: [],
            processed_ms: 1719850560774,
          },
          {
            leverage: 0.001,
            order_type: "FLAT",
            order_uuid: "9cceea43-761d-477f-89b1-5b2eaf2b07a9",
            price: 62482.0,
            price_sources: [],
            processed_ms: 1719907814470,
          },
        ],
        position_type: "FLAT",
        position_uuid: "52a98092-31cd-4c89-bb73-3eddfc7579b7",
        return_at_close: 0.9987782734381058,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 62775.6,
        close_ms: 1719929692048,
        current_return: 0.9992399913342127,
        initial_entry_price: 62775.6,
        is_closed_position: true,
        miner_hotkey: "5FEPBMuohFf4wqu7JAc5xV4k8Xam1EoUsbNMp2aB5Z4FvJ7T",
        net_leverage: 0.0,
        open_ms: 1719926159698,
        orders: [
          {
            leverage: 0.1,
            order_type: "LONG",
            order_uuid: "2e565adf-7dd0-43e0-aa42-420f0f8d4023",
            price: 62775.6,
            price_sources: [],
            processed_ms: 1719926159681,
          },
          {
            leverage: 0.001,
            order_type: "FLAT",
            order_uuid: "e193f36d-a3d8-4da1-bbbf-a4781840d008",
            price: 62298.5,
            price_sources: [],
            processed_ms: 1719929692048,
          },
        ],
        position_type: "FLAT",
        position_uuid: "2e565adf-7dd0-43e0-aa42-420f0f8d4023",
        return_at_close: 0.9991400673350793,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 61945.1,
        close_ms: 1719954779685,
        current_return: 0.9995603372113292,
        initial_entry_price: 62015.71,
        is_closed_position: true,
        miner_hotkey: "5FEPBMuohFf4wqu7JAc5xV4k8Xam1EoUsbNMp2aB5Z4FvJ7T",
        net_leverage: 0.0,
        open_ms: 1719932781092,
        orders: [
          {
            leverage: 0.1,
            order_type: "LONG",
            order_uuid: "9743f768-9cc3-435b-a73a-0b86bf939cf7",
            price: 62015.71,
            price_sources: [],
            processed_ms: 1719932768236,
          },
          {
            leverage: 0.1,
            order_type: "LONG",
            order_uuid: "1b6939b2-4892-436c-9469-abee76063b09",
            price: 61874.49,
            price_sources: [],
            processed_ms: 1719939880851,
          },
          {
            leverage: 0.001,
            order_type: "FLAT",
            order_uuid: "5ad5b406-6547-4f5a-bbae-bb5f0f94f5c8",
            price: 61808.77,
            price_sources: [],
            processed_ms: 1719954779685,
          },
        ],
        position_type: "FLAT",
        position_uuid: "9743f768-9cc3-435b-a73a-0b86bf939cf7",
        return_at_close: 0.9993604251438869,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 62121.675,
        close_ms: 1719971840421,
        current_return: 0.9980814637438931,
        initial_entry_price: 62224.0,
        is_closed_position: true,
        miner_hotkey: "5FEPBMuohFf4wqu7JAc5xV4k8Xam1EoUsbNMp2aB5Z4FvJ7T",
        net_leverage: 0.0,
        open_ms: 1719964871319,
        orders: [
          {
            leverage: 0.1,
            order_type: "LONG",
            order_uuid: "4ef61c1b-95e0-44e3-8416-53525c37bfcc",
            price: 62224.0,
            price_sources: [],
            processed_ms: 1719964870870,
          },
          {
            leverage: 0.1,
            order_type: "LONG",
            order_uuid: "56ea0f63-76f6-462b-b6ea-9cdfa311a112",
            price: 62019.35,
            price_sources: [],
            processed_ms: 1719970469184,
          },
          {
            leverage: 0.001,
            order_type: "FLAT",
            order_uuid: "078d2521-9e11-4c80-9416-b28ca07249e6",
            price: 61524.78,
            price_sources: [],
            processed_ms: 1719971840421,
          },
        ],
        position_type: "FLAT",
        position_uuid: "4ef61c1b-95e0-44e3-8416-53525c37bfcc",
        return_at_close: 0.9978818474511443,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 60160.58000000001,
        close_ms: 1720040534206,
        current_return: 0.9985778641848679,
        initial_entry_price: 60427.14,
        is_closed_position: true,
        miner_hotkey: "5FEPBMuohFf4wqu7JAc5xV4k8Xam1EoUsbNMp2aB5Z4FvJ7T",
        net_leverage: 0.0,
        open_ms: 1720016714927,
        orders: [
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "530887bd-6c73-4b97-b71b-be8b97760d01",
            price: 60427.14,
            price_sources: [],
            processed_ms: 1720016714347,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "54868725-4ec7-4459-bd19-482ad633128d",
            price: 59997.85,
            price_sources: [],
            processed_ms: 1720018910118,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "1e81d0e4-1abb-45c1-82f8-8b6acf7e9f2d",
            price: 60003.8,
            price_sources: [],
            processed_ms: 1720018999386,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "8fc529c3-ea11-4f75-8679-8e55b7c8c8a5",
            price: 60007.92,
            price_sources: [],
            processed_ms: 1720019355188,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "a78495bb-f8ec-4465-b2c3-f0561a2b4b38",
            price: 60204.3,
            price_sources: [],
            processed_ms: 1720019547080,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "03c549e5-c82d-4a6b-83c9-5c2c939f23c2",
            price: 60103.53,
            price_sources: [],
            processed_ms: 1720019838596,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "57cac27d-3abd-448b-a337-25d737d602a6",
            price: 60286.24,
            price_sources: [],
            processed_ms: 1720020246160,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "7e34a0cf-85b1-42a4-a55d-b264842b6f1c",
            price: 60268.55,
            price_sources: [],
            processed_ms: 1720021257189,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "1d681cf4-af19-439e-8193-33fb1d641898",
            price: 60180.0,
            price_sources: [],
            processed_ms: 1720021530814,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "3cd4e3cf-5281-4017-a23e-c6623f82a0cb",
            price: 60169.01,
            price_sources: [],
            processed_ms: 1720021665893,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "e1d9be48-2135-48a9-9dcc-9c3690e3ce32",
            price: 60145.93,
            price_sources: [],
            processed_ms: 1720022024049,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "9b8eba43-8085-4af9-9b43-96e1a96ee83f",
            price: 60132.69,
            price_sources: [],
            processed_ms: 1720022234237,
          },
          {
            leverage: 0.001,
            order_type: "FLAT",
            order_uuid: "316475db-8391-4a68-adc2-7c8f1e72e454",
            price: 59444.45,
            price_sources: [
              {
                close: 59444.45,
                high: 59444.45,
                lag_ms: 206,
                low: 59444.45,
                open: 59444.45,
                source: "Polygon_ws",
                start_ms: 1720040534000,
                timespan_ms: 0,
                volume: 0.02632678,
                vwap: 59444.45,
                websocket: true,
              },
              {
                close: 59509.4,
                high: 59509.4,
                lag_ms: 206,
                low: 59509.4,
                open: 59509.4,
                source: "TwelveData_ws",
                start_ms: 1720040534000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
              {
                close: 59480.0,
                high: 59601.0,
                lag_ms: 2207,
                low: 59450.21,
                open: 59473.49,
                source: "Polygon_rest",
                start_ms: 1720040531000,
                timespan_ms: 1000,
                volume: 0.30408335,
                vwap: 59460.9627,
                websocket: false,
              },
              {
                close: 59546.27,
                high: 59565.4,
                lag_ms: 74207,
                low: 59523.45,
                open: 59542.72,
                source: "TwelveData_rest",
                start_ms: 1720040400000,
                timespan_ms: 60000,
                volume: null,
                vwap: null,
                websocket: false,
              },
            ],
            processed_ms: 1720040534206,
          },
        ],
        position_type: "FLAT",
        position_uuid: "530887bd-6c73-4b97-b71b-be8b97760d01",
        return_at_close: 0.9984580348411658,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 3152.218,
        close_ms: 0,
        current_return: 0.9951770083426257,
        initial_entry_price: 3165.67,
        is_closed_position: false,
        miner_hotkey: "5FEPBMuohFf4wqu7JAc5xV4k8Xam1EoUsbNMp2aB5Z4FvJ7T",
        net_leverage: 0.9999999999999999,
        open_ms: 1720082583125,
        orders: [
          {
            leverage: 0.1,
            order_type: "LONG",
            order_uuid: "ba53d096-8b05-4025-9a51-51b8c54a0277",
            price: 3165.67,
            price_sources: [
              {
                close: 3165.67,
                high: 3165.68,
                lag_ms: 490,
                low: 3165.67,
                open: 3165.67,
                source: "Polygon_ws",
                start_ms: 1720082580000,
                timespan_ms: 0,
                volume: 0.34453,
                vwap: 3165.6799,
                websocket: true,
              },
              {
                close: 3166.45,
                high: 3166.67,
                lag_ms: 1491,
                low: 3166.45,
                open: 3166.67,
                source: "Polygon_rest",
                start_ms: 1720082578000,
                timespan_ms: 1000,
                volume: 5.40907181,
                vwap: 3166.5374,
                websocket: false,
              },
              {
                close: 3169.85,
                high: 3169.85,
                lag_ms: 3490,
                low: 3169.85,
                open: 3169.85,
                source: "TwelveData_ws",
                start_ms: 1720082577000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
              {
                close: 3166.18994,
                high: 3169.13989,
                lag_ms: 60491,
                low: 3163.62988,
                open: 3163.62988,
                source: "TwelveData_rest",
                start_ms: 1720082460000,
                timespan_ms: 60000,
                volume: null,
                vwap: null,
                websocket: false,
              },
            ],
            processed_ms: 1720082580490,
          },
          {
            leverage: 0.1,
            order_type: "LONG",
            order_uuid: "421ae8a8-b9ac-4483-a7be-986bc678825f",
            price: 3147.06,
            price_sources: [
              {
                close: 3147.06,
                high: 3147.25,
                lag_ms: 168,
                low: 3147.04,
                open: 3147.06,
                source: "Polygon_ws",
                start_ms: 1720084225000,
                timespan_ms: 0,
                volume: 1.45563066,
                vwap: 3147.1424,
                websocket: true,
              },
              {
                close: 3151.0,
                high: 3151.0,
                lag_ms: 2169,
                low: 3146.58,
                open: 3146.58,
                source: "Polygon_rest",
                start_ms: 1720084222000,
                timespan_ms: 1000,
                volume: 1.91829154,
                vwap: 3150.9825,
                websocket: false,
              },
              {
                close: 3149.43,
                high: 3149.43,
                lag_ms: 2832,
                low: 3149.43,
                open: 3149.43,
                source: "TwelveData_ws",
                start_ms: 1720084228000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
              {
                close: 3162.91992,
                high: 3162.91992,
                lag_ms: 265169,
                low: 3157.83008,
                open: 3157.83008,
                source: "TwelveData_rest",
                start_ms: 1720083900000,
                timespan_ms: 60000,
                volume: null,
                vwap: null,
                websocket: false,
              },
            ],
            processed_ms: 1720084225168,
          },
          {
            leverage: 0.1,
            order_type: "LONG",
            order_uuid: "2d870f5d-3021-4c67-8664-97d5c33d5927",
            price: 3154.6,
            price_sources: [
              {
                close: 3154.6,
                high: 3154.6,
                lag_ms: 328,
                low: 3149.57,
                open: 3154.6,
                source: "Polygon_ws",
                start_ms: 1720084615000,
                timespan_ms: 0,
                volume: 5.07109532,
                vwap: 3153.9744,
                websocket: true,
              },
              {
                close: 3151.37,
                high: 3151.37,
                lag_ms: 1672,
                low: 3151.37,
                open: 3151.37,
                source: "TwelveData_ws",
                start_ms: 1720084617000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
              {
                close: 3149.25,
                high: 3154.1,
                lag_ms: 2329,
                low: 3149.24,
                open: 3154.1,
                source: "Polygon_rest",
                start_ms: 1720084612000,
                timespan_ms: 1000,
                volume: 5.87287227,
                vwap: 3152.8426,
                websocket: false,
              },
              {
                close: 3147.65991,
                high: 3149.42993,
                lag_ms: 355329,
                low: 3146.55005,
                open: 3146.55005,
                source: "TwelveData_rest",
                start_ms: 1720084200000,
                timespan_ms: 60000,
                volume: null,
                vwap: null,
                websocket: false,
              },
            ],
            processed_ms: 1720084615328,
          },
          {
            leverage: 0.1,
            order_type: "LONG",
            order_uuid: "0a09f399-916a-4849-831f-523b31ad6bb0",
            price: 3145.6,
            price_sources: [
              {
                close: 3145.6,
                high: 3149.0,
                lag_ms: 8,
                low: 3144.95,
                open: 3145.6,
                source: "Polygon_ws",
                start_ms: 1720084740000,
                timespan_ms: 0,
                volume: 7.35317319,
                vwap: 3147.6576,
                websocket: true,
              },
              {
                close: 3145.32,
                high: 3149.0,
                lag_ms: 2009,
                low: 3145.04,
                open: 3145.4,
                source: "Polygon_rest",
                start_ms: 1720084737000,
                timespan_ms: 1000,
                volume: 5.74781435,
                vwap: 3148.1126,
                websocket: false,
              },
              {
                close: 3146.98,
                high: 3146.98,
                lag_ms: 2992,
                low: 3146.98,
                open: 3146.98,
                source: "TwelveData_ws",
                start_ms: 1720084743000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
              {
                close: 3151.37012,
                high: 3152.23999,
                lag_ms: 120009,
                low: 3143.36011,
                open: 3148.11011,
                source: "TwelveData_rest",
                start_ms: 1720084560000,
                timespan_ms: 60000,
                volume: null,
                vwap: null,
                websocket: false,
              },
            ],
            processed_ms: 1720084740008,
          },
          {
            leverage: 0.1,
            order_type: "LONG",
            order_uuid: "d783ca85-e063-4aa1-9ff8-1d03bad5f7c6",
            price: 3159.1,
            price_sources: [
              {
                close: 3159.1,
                high: 3159.1,
                lag_ms: 336,
                low: 3155.68,
                open: 3159.1,
                source: "Polygon_ws",
                start_ms: 1720085380999,
                timespan_ms: 0,
                volume: 7.01378366,
                vwap: 3158.947,
                websocket: true,
              },
              {
                close: 3158.62,
                high: 3158.62,
                lag_ms: 2337,
                low: 3158.62,
                open: 3158.62,
                source: "TwelveData_ws",
                start_ms: 1720085383000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
              {
                close: 3155.71,
                high: 3155.81,
                lag_ms: 2664,
                low: 3155.04,
                open: 3155.04,
                source: "Polygon_rest",
                start_ms: 1720085377000,
                timespan_ms: 1000,
                volume: 3.13181846,
                vwap: 3155.641,
                websocket: false,
              },
              {
                close: 3153.04004,
                high: 3156.52002,
                lag_ms: 280664,
                low: 3149.04004,
                open: 3156.52002,
                source: "TwelveData_rest",
                start_ms: 1720085040000,
                timespan_ms: 60000,
                volume: null,
                vwap: null,
                websocket: false,
              },
            ],
            processed_ms: 1720085380663,
          },
          {
            leverage: 0.1,
            order_type: "LONG",
            order_uuid: "e38792cd-1b41-4dc2-a53e-69161ebc7167",
            price: 3146.2,
            price_sources: [
              {
                close: 3146.2,
                high: 3146.2,
                lag_ms: 176,
                low: 3143.48,
                open: 3146.2,
                source: "Polygon_ws",
                start_ms: 1720087700999,
                timespan_ms: 0,
                volume: 5.19548397,
                vwap: 3145.8313,
                websocket: true,
              },
              {
                close: 3145.92,
                high: 3145.92,
                lag_ms: 823,
                low: 3145.92,
                open: 3145.92,
                source: "TwelveData_ws",
                start_ms: 1720087700000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
              {
                close: 3142.67,
                high: 3142.67,
                lag_ms: 2824,
                low: 3142.67,
                open: 3142.67,
                source: "Polygon_rest",
                start_ms: 1720087697000,
                timespan_ms: 1000,
                volume: 1.67098032,
                vwap: 3142.67,
                websocket: false,
              },
              {
                close: 3146.77002,
                high: 3151.47998,
                lag_ms: 80824,
                low: 3146.48999,
                open: 3151.47998,
                source: "TwelveData_rest",
                start_ms: 1720087560000,
                timespan_ms: 60000,
                volume: null,
                vwap: null,
                websocket: false,
              },
            ],
            processed_ms: 1720087700823,
          },
          {
            leverage: 0.1,
            order_type: "LONG",
            order_uuid: "6bcd5981-dc66-4f93-b1f1-a2fea1ad2b6d",
            price: 3157.78,
            price_sources: [
              {
                close: 3157.78,
                high: 3157.78,
                lag_ms: 268,
                low: 3157.77,
                open: 3157.78,
                source: "Polygon_ws",
                start_ms: 1720089963000,
                timespan_ms: 0,
                volume: 0.10254347,
                vwap: 3157.7707,
                websocket: true,
              },
              {
                close: 3158.44,
                high: 3158.58,
                lag_ms: 2269,
                low: 3155.62,
                open: 3158.31,
                source: "Polygon_rest",
                start_ms: 1720089960000,
                timespan_ms: 1000,
                volume: 4.99803076,
                vwap: 3158.3081,
                websocket: false,
              },
              {
                close: 3161.01,
                high: 3161.01,
                lag_ms: 4268,
                low: 3161.01,
                open: 3161.01,
                source: "TwelveData_ws",
                start_ms: 1720089959000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
              {
                close: 3157.42993,
                high: 3158.29004,
                lag_ms: 63269,
                low: 3157.27002,
                open: 3157.27002,
                source: "TwelveData_rest",
                start_ms: 1720089840000,
                timespan_ms: 60000,
                volume: null,
                vwap: null,
                websocket: false,
              },
            ],
            processed_ms: 1720089963268,
          },
          {
            leverage: 0.1,
            order_type: "LONG",
            order_uuid: "9e25d1cf-cb7e-4a17-a298-b0fb76d97781",
            price: 3145.89,
            price_sources: [
              {
                close: 3145.89,
                high: 3146.04,
                lag_ms: 56,
                low: 3145.89,
                open: 3145.89,
                source: "Polygon_ws",
                start_ms: 1720095219000,
                timespan_ms: 0,
                volume: 0.09808148,
                vwap: 3145.8902,
                websocket: true,
              },
              {
                close: 3148.87,
                high: 3148.87,
                lag_ms: 3944,
                low: 3148.87,
                open: 3148.87,
                source: "TwelveData_ws",
                start_ms: 1720095215000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
              {
                close: 3145.83,
                high: 3145.83,
                lag_ms: 3945,
                low: 3145.83,
                open: 3145.83,
                source: "Polygon_rest",
                start_ms: 1720095214000,
                timespan_ms: 1000,
                volume: 0.01816745,
                vwap: 3145.83,
                websocket: false,
              },
              {
                close: 3150.20996,
                high: 3151.78003,
                lag_ms: 98945,
                low: 3149.37012,
                open: 3151.78003,
                source: "TwelveData_rest",
                start_ms: 1720095060000,
                timespan_ms: 60000,
                volume: null,
                vwap: null,
                websocket: false,
              },
            ],
            processed_ms: 1720095218944,
          },
          {
            leverage: 0.1,
            order_type: "LONG",
            order_uuid: "da7ce201-49a5-4df5-b397-7ba0efaa5b65",
            price: 3156.18,
            price_sources: [
              {
                close: 3156.18,
                high: 3156.18,
                lag_ms: 145,
                low: 3156.08,
                open: 3156.18,
                source: "Polygon_ws",
                start_ms: 1720096489999,
                timespan_ms: 0,
                volume: 3.51821937,
                vwap: 3156.1241,
                websocket: true,
              },
              {
                close: 3159.2,
                high: 3159.2,
                lag_ms: 146,
                low: 3159.2,
                open: 3159.2,
                source: "TwelveData_ws",
                start_ms: 1720096490000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
              {
                close: 3156.0,
                high: 3156.0,
                lag_ms: 2855,
                low: 3155.84,
                open: 3155.84,
                source: "Polygon_rest",
                start_ms: 1720096486000,
                timespan_ms: 1000,
                volume: 0.62729348,
                vwap: 3155.8727,
                websocket: false,
              },
              {
                close: 3156.54004,
                high: 3156.54004,
                lag_ms: 109855,
                low: 3153.76001,
                open: 3154.03003,
                source: "TwelveData_rest",
                start_ms: 1720096320000,
                timespan_ms: 60000,
                volume: null,
                vwap: null,
                websocket: false,
              },
            ],
            processed_ms: 1720096489854,
          },
          {
            leverage: 0.1,
            order_type: "LONG",
            order_uuid: "46714338-daba-4b65-9a06-cb55917db9e7",
            price: 3144.1,
            price_sources: [
              {
                close: 3144.1,
                high: 3144.1,
                lag_ms: 476,
                low: 3144.1,
                open: 3144.1,
                source: "Polygon_ws",
                start_ms: 1720096964999,
                timespan_ms: 0,
                volume: 0.1,
                vwap: 3144.1,
                websocket: true,
              },
              {
                close: 3141.83,
                high: 3141.83,
                lag_ms: 477,
                low: 3141.83,
                open: 3141.83,
                source: "TwelveData_ws",
                start_ms: 1720096965000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
              {
                close: 3139.9,
                high: 3139.9,
                lag_ms: 2524,
                low: 3139.9,
                open: 3139.9,
                source: "Polygon_rest",
                start_ms: 1720096961000,
                timespan_ms: 1000,
                volume: 0.987,
                vwap: 3139.9,
                websocket: false,
              },
              {
                close: 3146.84009,
                high: 3151.0,
                lag_ms: 104524,
                low: 3146.17993,
                open: 3151.0,
                source: "TwelveData_rest",
                start_ms: 1720096800000,
                timespan_ms: 60000,
                volume: null,
                vwap: null,
                websocket: false,
              },
            ],
            processed_ms: 1720096964523,
          },
        ],
        position_type: "LONG",
        position_uuid: "ba53d096-8b05-4025-9a51-51b8c54a0277",
        return_at_close: 0.9946794198384544,
        trade_pair: ["ETHUSD", "ETH/USD", 0.003, 0.001, 20],
      },
    ],
    thirty_day_returns: 0.9927015489725609,
  },
  "5FFPapsywE9Zfg7UFSYh7y7Ds88q9rSgZpGHaQ3qRJAxobsD": {
    all_time_returns: 0.999094471696264,
    n_positions: 10,
    percentage_profitable: 0.9,
    positions: [
      {
        average_entry_price: 169.22223529411767,
        close_ms: 1718387660001,
        current_return: 0.9953193630497839,
        initial_entry_price: 168.93,
        is_closed_position: true,
        miner_hotkey: "5FFPapsywE9Zfg7UFSYh7y7Ds88q9rSgZpGHaQ3qRJAxobsD",
        net_leverage: 0.0,
        open_ms: 1718122743090,
        orders: [
          {
            leverage: 0.55,
            order_type: "LONG",
            order_uuid: "336868cd-1863-4d92-a810-a4b72215ee75",
            price: 168.93,
            price_sources: [],
            processed_ms: 1718122742516,
          },
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "543a6b1e-169a-4476-ac10-4b9a1f23610e",
            price: 169.758,
            price_sources: [],
            processed_ms: 1718273532489,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "37085cc9-e6fa-4f99-9a23-b3f7f580c4e1",
            price: 168.292,
            price_sources: [],
            processed_ms: 1718387660001,
          },
        ],
        position_type: "FLAT",
        position_uuid: "f6b153a3-b018-4e8e-ac0c-9c81e7038a7b",
        return_at_close: 0.9952601415476824,
        trade_pair: ["EURJPY", "EUR/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 3533.93,
        close_ms: 1718453463237,
        current_return: 1.0001000019807975,
        initial_entry_price: 3533.93,
        is_closed_position: true,
        miner_hotkey: "5FFPapsywE9Zfg7UFSYh7y7Ds88q9rSgZpGHaQ3qRJAxobsD",
        net_leverage: 0.0,
        open_ms: 1718449713375,
        orders: [
          {
            leverage: 0.03,
            order_type: "LONG",
            order_uuid: "3b119da4-5649-495c-9568-8f293d1d7160",
            price: 3533.93,
            price_sources: [],
            processed_ms: 1718449713361,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "028a9905-8ee7-45a4-a66e-5a755fd8dbea",
            price: 3545.71,
            price_sources: [],
            processed_ms: 1718453463237,
          },
        ],
        position_type: "FLAT",
        position_uuid: "af5adbde-3122-4e46-8a52-2c42cb4f895b",
        return_at_close: 1.000069998980738,
        trade_pair: ["ETHUSD", "ETH/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 3538.9457718120807,
        close_ms: 1718456154034,
        current_return: 1.000307939679771,
        initial_entry_price: 3543.09,
        is_closed_position: true,
        miner_hotkey: "5FFPapsywE9Zfg7UFSYh7y7Ds88q9rSgZpGHaQ3qRJAxobsD",
        net_leverage: 0.0,
        open_ms: 1718453492907,
        orders: [
          {
            leverage: 0.03,
            order_type: "LONG",
            order_uuid: "f310841b-fd41-4dbf-99de-4b1d5c903790",
            price: 3543.09,
            price_sources: [],
            processed_ms: 1718453492897,
          },
          {
            leverage: 0.027,
            order_type: "LONG",
            order_uuid: "4cb4b0bf-a5bf-4035-a3b8-b7ba3d67582b",
            price: 3537.72,
            price_sources: [],
            processed_ms: 1718455178979,
          },
          {
            leverage: 0.0324,
            order_type: "LONG",
            order_uuid: "d6076078-a994-4bb9-b135-512f18502d80",
            price: 3536.13,
            price_sources: [],
            processed_ms: 1718455212808,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "29b73b0f-4e85-4ba3-a4bb-766c3b8415c9",
            price: 3551.15,
            price_sources: [],
            processed_ms: 1718456154034,
          },
        ],
        position_type: "FLAT",
        position_uuid: "b0fad27b-7f24-4877-8b2d-a14047503543",
        return_at_close: 1.0002185121499636,
        trade_pair: ["ETHUSD", "ETH/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 3551.33,
        close_ms: 1718456294112,
        current_return: 1.0000956261456975,
        initial_entry_price: 3551.33,
        is_closed_position: true,
        miner_hotkey: "5FFPapsywE9Zfg7UFSYh7y7Ds88q9rSgZpGHaQ3qRJAxobsD",
        net_leverage: 0.0,
        open_ms: 1718456186447,
        orders: [
          {
            leverage: 0.03,
            order_type: "LONG",
            order_uuid: "504f486f-8f46-4fc2-a1aa-3fb2e5ab0540",
            price: 3551.33,
            price_sources: [],
            processed_ms: 1718456185808,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "2c752246-ae7e-4fee-8478-2af3aef343fd",
            price: 3562.65,
            price_sources: [],
            processed_ms: 1718456294112,
          },
        ],
        position_type: "FLAT",
        position_uuid: "3d0103c9-6cd8-4673-947b-9e78a0901019",
        return_at_close: 1.0000656232769132,
        trade_pair: ["ETHUSD", "ETH/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 3551.564404915873,
        close_ms: 1718458517793,
        current_return: 1.000706297298602,
        initial_entry_price: 3559.52,
        is_closed_position: true,
        miner_hotkey: "5FFPapsywE9Zfg7UFSYh7y7Ds88q9rSgZpGHaQ3qRJAxobsD",
        net_leverage: 0.0,
        open_ms: 1718456325359,
        orders: [
          {
            leverage: 0.03,
            order_type: "LONG",
            order_uuid: "af6ed661-feba-495d-af10-d6db283c1de4",
            price: 3559.52,
            price_sources: [],
            processed_ms: 1718456323970,
          },
          {
            leverage: 0.027,
            order_type: "LONG",
            order_uuid: "5789fe8e-b6cc-4a59-956f-af2df0911e84",
            price: 3555.55,
            price_sources: [],
            processed_ms: 1718456582176,
          },
          {
            leverage: 0.0297,
            order_type: "LONG",
            order_uuid: "714dbae8-dbaa-4306-98d5-161f2dd60c64",
            price: 3553.59,
            price_sources: [],
            processed_ms: 1718456659822,
          },
          {
            leverage: 0.032670000000000005,
            order_type: "LONG",
            order_uuid: "535df61e-45f9-485f-adb7-a30902376c24",
            price: 3552.02,
            price_sources: [],
            processed_ms: 1718456852441,
          },
          {
            leverage: 0.032670000000000005,
            order_type: "LONG",
            order_uuid: "522d8a0f-ec47-45d0-b6ad-137f72b9034a",
            price: 3551.56,
            price_sources: [],
            processed_ms: 1718456882158,
          },
          {
            leverage: 0.026136000000000006,
            order_type: "LONG",
            order_uuid: "cd18605d-e6a1-4e11-b06b-13a6f6055ff9",
            price: 3549.04,
            price_sources: [],
            processed_ms: 1718456996107,
          },
          {
            leverage: 0.02874960000000001,
            order_type: "LONG",
            order_uuid: "c7b3f68f-8584-4d89-a364-ecd466a978b6",
            price: 3547.44,
            price_sources: [],
            processed_ms: 1718457034080,
          },
          {
            leverage: 0.03162456000000002,
            order_type: "LONG",
            order_uuid: "e3792178-36dc-486a-854c-826d32da4688",
            price: 3547.47,
            price_sources: [],
            processed_ms: 1718457070421,
          },
          {
            leverage: 0.028462104000000016,
            order_type: "LONG",
            order_uuid: "63d9289d-0add-4dfc-8c16-4b7d9598bda3",
            price: 3547.8,
            price_sources: [],
            processed_ms: 1718457102000,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "c523eed6-9132-47d8-b24d-0ecfedc1a1de",
            price: 3560.98,
            price_sources: [],
            processed_ms: 1718458517793,
          },
        ],
        position_type: "FLAT",
        position_uuid: "dcba2413-0060-4dd3-9c4e-dad2681a91c0",
        return_at_close: 1.0004390964445613,
        trade_pair: ["ETHUSD", "ETH/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 3557.653693693694,
        close_ms: 1718459974425,
        current_return: 1.0003948070891984,
        initial_entry_price: 3564.86,
        is_closed_position: true,
        miner_hotkey: "5FFPapsywE9Zfg7UFSYh7y7Ds88q9rSgZpGHaQ3qRJAxobsD",
        net_leverage: 0.0,
        open_ms: 1718458547633,
        orders: [
          {
            leverage: 0.03,
            order_type: "LONG",
            order_uuid: "302513e5-d02b-4497-80ff-c937803671a2",
            price: 3564.86,
            price_sources: [],
            processed_ms: 1718458546844,
          },
          {
            leverage: 0.024,
            order_type: "LONG",
            order_uuid: "f9d5ccb2-deb9-459f-8a24-8074062ca7e0",
            price: 3558.8,
            price_sources: [],
            processed_ms: 1718458626851,
          },
          {
            leverage: 0.026400000000000003,
            order_type: "LONG",
            order_uuid: "a002fee8-e910-44b6-ac35-c60a591ee2d1",
            price: 3557.23,
            price_sources: [],
            processed_ms: 1718458662991,
          },
          {
            leverage: 0.026400000000000003,
            order_type: "LONG",
            order_uuid: "5153a2d5-f05a-411d-85e0-93f3c9ff20ab",
            price: 3553.83,
            price_sources: [],
            processed_ms: 1718458770890,
          },
          {
            leverage: 0.026400000000000003,
            order_type: "LONG",
            order_uuid: "4044ca25-4c40-4780-b956-3169550719d3",
            price: 3552.67,
            price_sources: [],
            processed_ms: 1718458811015,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "caf42805-36f5-4506-b1c6-9ce8ab0b79f1",
            price: 3568.22,
            price_sources: [],
            processed_ms: 1718459974425,
          },
        ],
        position_type: "FLAT",
        position_uuid: "0c30b4ea-c951-407b-a7fc-aad299c8db1a",
        return_at_close: 1.000261554500894,
        trade_pair: ["ETHUSD", "ETH/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 3558.6623546556443,
        close_ms: 1718464288711,
        current_return: 1.0006044695423264,
        initial_entry_price: 3566.81,
        is_closed_position: true,
        miner_hotkey: "5FFPapsywE9Zfg7UFSYh7y7Ds88q9rSgZpGHaQ3qRJAxobsD",
        net_leverage: 0.0,
        open_ms: 1718460005441,
        orders: [
          {
            leverage: 0.03,
            order_type: "LONG",
            order_uuid: "893b323c-55d1-4070-a79b-4af7bf7f3da3",
            price: 3566.81,
            price_sources: [],
            processed_ms: 1718460004910,
          },
          {
            leverage: 0.020999999999999998,
            order_type: "LONG",
            order_uuid: "c08bbc3d-b027-429a-a7fe-670716580924",
            price: 3561.85,
            price_sources: [],
            processed_ms: 1718460094678,
          },
          {
            leverage: 0.014699999999999998,
            order_type: "LONG",
            order_uuid: "9632afa7-9b00-4253-a1b4-afd419da048d",
            price: 3560.88,
            price_sources: [],
            processed_ms: 1718460156104,
          },
          {
            leverage: 0.01176,
            order_type: "LONG",
            order_uuid: "a1ec5776-b556-4da1-8d50-de85cdc64552",
            price: 3560.0,
            price_sources: [],
            processed_ms: 1718460306020,
          },
          {
            leverage: 0.010584,
            order_type: "LONG",
            order_uuid: "40ba9cc4-46da-47d8-a06c-ccb9693f989b",
            price: 3558.18,
            price_sources: [],
            processed_ms: 1718460349906,
          },
          {
            leverage: 0.010584,
            order_type: "LONG",
            order_uuid: "58fac31b-a491-4a2d-9a9a-41fe9d2dc9a9",
            price: 3558.48,
            price_sources: [],
            processed_ms: 1718460445896,
          },
          {
            leverage: 0.0084672,
            order_type: "LONG",
            order_uuid: "55c760f6-f5c5-4c4b-a9b3-33bc12bd7213",
            price: 3557.83,
            price_sources: [],
            processed_ms: 1718460482052,
          },
          {
            leverage: 0.00677376,
            order_type: "LONG",
            order_uuid: "43b8d322-6b22-414a-aad5-dcd632a0b129",
            price: 3557.3,
            price_sources: [],
            processed_ms: 1718460532069,
          },
          {
            leverage: 0.008128512,
            order_type: "LONG",
            order_uuid: "62e36db1-f6ce-48bb-83a2-6daeb48d8622",
            price: 3559.31,
            price_sources: [],
            processed_ms: 1718460606281,
          },
          {
            leverage: 0.0056899583999999994,
            order_type: "LONG",
            order_uuid: "4fd65322-8b54-49b0-b179-b948869bbaa5",
            price: 3556.44,
            price_sources: [],
            processed_ms: 1718460638228,
          },
          {
            leverage: 0.00625895424,
            order_type: "LONG",
            order_uuid: "888c6255-7dfd-40d0-8ce3-50b38c97fe72",
            price: 3557.23,
            price_sources: [],
            processed_ms: 1718460741971,
          },
          {
            leverage: 0.005007163392,
            order_type: "LONG",
            order_uuid: "d75fbf1f-04a1-4beb-bcda-2a656b8c9932",
            price: 3555.78,
            price_sources: [],
            processed_ms: 1718460778030,
          },
          {
            leverage: 0.004506447052800001,
            order_type: "LONG",
            order_uuid: "d31909a8-71b7-442b-80b2-0045df9ef97d",
            price: 3556.78,
            price_sources: [],
            processed_ms: 1718460817979,
          },
          {
            leverage: 0.0040558023475200005,
            order_type: "LONG",
            order_uuid: "52cb2be7-9919-4fd3-8e53-34c477324995",
            price: 3555.32,
            price_sources: [],
            processed_ms: 1718461703443,
          },
          {
            leverage: 0.004461382582272001,
            order_type: "LONG",
            order_uuid: "d5bbd754-f38e-4815-af9d-07ff74550e06",
            price: 3558.33,
            price_sources: [],
            processed_ms: 1718461847097,
          },
          {
            leverage: 0.003569106065817601,
            order_type: "LONG",
            order_uuid: "b29a48ee-5a40-425a-bf94-b748e51c3b31",
            price: 3556.31,
            price_sources: [],
            processed_ms: 1718461891103,
          },
          {
            leverage: 0.004282927278981121,
            order_type: "LONG",
            order_uuid: "69a7b15c-c18e-44ab-acf4-36760f0971c9",
            price: 3553.36,
            price_sources: [],
            processed_ms: 1718461922969,
          },
          {
            leverage: 0.003426341823184897,
            order_type: "LONG",
            order_uuid: "53ce24d7-a36e-4921-b9af-2a0252548fdf",
            price: 3552.92,
            price_sources: [],
            processed_ms: 1718461953015,
          },
          {
            leverage: 0.002398439276229428,
            order_type: "LONG",
            order_uuid: "7b4bdc0e-ca10-4c26-92b7-732c1112cd7b",
            price: 3551.11,
            price_sources: [],
            processed_ms: 1718461983326,
          },
          {
            leverage: 0.0026382832038523707,
            order_type: "LONG",
            order_uuid: "3c4bb89b-85f8-4335-890f-7d42c7f37e52",
            price: 3549.97,
            price_sources: [],
            processed_ms: 1718462012796,
          },
          {
            leverage: 0.002902111524237608,
            order_type: "LONG",
            order_uuid: "33cffe7d-e082-4f42-9d8e-339c2ea9d2ca",
            price: 3549.31,
            price_sources: [],
            processed_ms: 1718462044761,
          },
          {
            leverage: 0.002902111524237608,
            order_type: "LONG",
            order_uuid: "9038cc7b-ca29-4c46-ba2b-599326b5203e",
            price: 3550.07,
            price_sources: [],
            processed_ms: 1718462074857,
          },
          {
            leverage: 0.0026119003718138474,
            order_type: "LONG",
            order_uuid: "e7347613-01e1-4520-8c08-435f595f289a",
            price: 3550.56,
            price_sources: [],
            processed_ms: 1718462106795,
          },
          {
            leverage: 0.0028730904089952322,
            order_type: "LONG",
            order_uuid: "b30ddfa7-2571-459b-9740-2513a1cc7258",
            price: 3551.62,
            price_sources: [],
            processed_ms: 1718462143005,
          },
          {
            leverage: 0.0028730904089952322,
            order_type: "LONG",
            order_uuid: "dfeab570-ea8b-4d3f-9171-a75fdb4ba617",
            price: 3550.98,
            price_sources: [],
            processed_ms: 1718462172800,
          },
          {
            leverage: 0.0031603994498947555,
            order_type: "LONG",
            order_uuid: "f95afa29-ccfa-4333-af27-5b09cb0d219f",
            price: 3553.29,
            price_sources: [],
            processed_ms: 1718462212741,
          },
          {
            leverage: 0.00284435950490528,
            order_type: "LONG",
            order_uuid: "997083cd-2f22-4840-b1e2-5da0f7ad1b04",
            price: 3554.7,
            price_sources: [],
            processed_ms: 1718462252949,
          },
          {
            leverage: 0.0019910516534336962,
            order_type: "LONG",
            order_uuid: "53f6ed8e-228c-4020-a0f3-953d7d0fd263",
            price: 3554.86,
            price_sources: [],
            processed_ms: 1718462283104,
          },
          {
            leverage: 0.001592841322746957,
            order_type: "LONG",
            order_uuid: "93fd96fc-8f4d-46a9-9155-4b3d399a5e32",
            price: 3556.3,
            price_sources: [],
            processed_ms: 1718462332784,
          },
          {
            leverage: 0.0019114095872963483,
            order_type: "LONG",
            order_uuid: "945def79-5ff0-4477-8d6a-228c9c73e0df",
            price: 3552.97,
            price_sources: [],
            processed_ms: 1718463304150,
          },
          {
            leverage: 0.0019114095872963483,
            order_type: "LONG",
            order_uuid: "00f1c458-d47a-4e97-8147-bcd6f28cd15b",
            price: 3552.83,
            price_sources: [],
            processed_ms: 1718463335971,
          },
          {
            leverage: 0.0019114095872963483,
            order_type: "LONG",
            order_uuid: "7decbd4b-6582-44be-a4af-74e39fea45a1",
            price: 3554.04,
            price_sources: [],
            processed_ms: 1718463393972,
          },
          {
            leverage: 0.0015291276698370787,
            order_type: "LONG",
            order_uuid: "a0734caa-c4b5-4a2b-9fc3-f720d7534d8d",
            price: 3555.87,
            price_sources: [],
            processed_ms: 1718463652180,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "3fda7476-5628-4134-b9ce-66062ebd00c7",
            price: 3569.48,
            price_sources: [],
            processed_ms: 1718464288711,
          },
        ],
        position_type: "FLAT",
        position_uuid: "8d598379-6b2a-45bc-9033-641161785257",
        return_at_close: 1.0004050424772992,
        trade_pair: ["ETHUSD", "ETH/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 3568.22,
        close_ms: 1718464504176,
        current_return: 1.0001151834808393,
        initial_entry_price: 3568.22,
        is_closed_position: true,
        miner_hotkey: "5FFPapsywE9Zfg7UFSYh7y7Ds88q9rSgZpGHaQ3qRJAxobsD",
        net_leverage: 0.0,
        open_ms: 1718464320416,
        orders: [
          {
            leverage: 0.03,
            order_type: "LONG",
            order_uuid: "eb292323-9b60-4079-abb0-8256789eb9a3",
            price: 3568.22,
            price_sources: [],
            processed_ms: 1718464319807,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "65c12951-4819-4eec-bcc7-5960c1287777",
            price: 3581.92,
            price_sources: [],
            processed_ms: 1718464504176,
          },
        ],
        position_type: "FLAT",
        position_uuid: "0a0dec0b-8437-4618-a3d9-8352ee5ef7c9",
        return_at_close: 1.000085180025335,
        trade_pair: ["ETHUSD", "ETH/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 3576.067619047619,
        close_ms: 1718464686058,
        current_return: 1.0002263419924917,
        initial_entry_price: 3577.33,
        is_closed_position: true,
        miner_hotkey: "5FFPapsywE9Zfg7UFSYh7y7Ds88q9rSgZpGHaQ3qRJAxobsD",
        net_leverage: 0.0,
        open_ms: 1718464534347,
        orders: [
          {
            leverage: 0.03,
            order_type: "LONG",
            order_uuid: "fa1a3907-6832-4339-a3fc-5c75703e333a",
            price: 3577.33,
            price_sources: [],
            processed_ms: 1718464533860,
          },
          {
            leverage: 0.033,
            order_type: "LONG",
            order_uuid: "13a10fcb-ba09-4e79-8afc-8e6061670a56",
            price: 3574.92,
            price_sources: [],
            processed_ms: 1718464601992,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "35d89edc-502e-4f31-a53e-89d95a93c677",
            price: 3588.92,
            price_sources: [],
            processed_ms: 1718464686058,
          },
        ],
        position_type: "FLAT",
        position_uuid: "3d8da04c-90e1-4799-b545-fb799d519c64",
        return_at_close: 1.0001633277329463,
        trade_pair: ["ETHUSD", "ETH/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 3573.533809756461,
        close_ms: 1718548014520,
        current_return: 1.0025795786262097,
        initial_entry_price: 3585.51,
        is_closed_position: true,
        miner_hotkey: "5FFPapsywE9Zfg7UFSYh7y7Ds88q9rSgZpGHaQ3qRJAxobsD",
        net_leverage: 0.0,
        open_ms: 1718464718833,
        orders: [
          {
            leverage: 0.03,
            order_type: "LONG",
            order_uuid: "915b5b2f-9573-40c0-bb14-e313573d148e",
            price: 3585.51,
            price_sources: [],
            processed_ms: 1718464717860,
          },
          {
            leverage: 0.033,
            order_type: "LONG",
            order_uuid: "c3bb80e3-b3c7-41ae-8894-281694f4bbad",
            price: 3581.15,
            price_sources: [],
            processed_ms: 1718465122455,
          },
          {
            leverage: 0.036300000000000006,
            order_type: "LONG",
            order_uuid: "9155977c-db6b-4397-bdff-673148cf4783",
            price: 3576.32,
            price_sources: [],
            processed_ms: 1718465178105,
          },
          {
            leverage: 0.032670000000000005,
            order_type: "LONG",
            order_uuid: "695e801a-84b6-44cb-972a-3b422c6406dc",
            price: 3576.69,
            price_sources: [],
            processed_ms: 1718465253841,
          },
          {
            leverage: 0.022869,
            order_type: "LONG",
            order_uuid: "bb1ba47a-6d37-45f7-85db-2d14b1dd6aba",
            price: 3574.61,
            price_sources: [],
            processed_ms: 1718465283965,
          },
          {
            leverage: 0.0274428,
            order_type: "LONG",
            order_uuid: "76c40c70-91c8-4236-84bc-3907d66fcb9c",
            price: 3574.99,
            price_sources: [],
            processed_ms: 1718465404394,
          },
          {
            leverage: 0.03018708,
            order_type: "LONG",
            order_uuid: "58e5ebfd-d74f-4a83-8a44-4361ceb93b66",
            price: 3573.54,
            price_sources: [],
            processed_ms: 1718465530161,
          },
          {
            leverage: 0.024149664,
            order_type: "LONG",
            order_uuid: "bd8cee9d-7336-44b9-ad3a-371e4df30b51",
            price: 3572.53,
            price_sources: [],
            processed_ms: 1718465564106,
          },
          {
            leverage: 0.0289795968,
            order_type: "LONG",
            order_uuid: "29afe438-f0e3-496a-8766-154c89421421",
            price: 3569.04,
            price_sources: [],
            processed_ms: 1718465599963,
          },
          {
            leverage: 0.0289795968,
            order_type: "LONG",
            order_uuid: "f33131ad-7d83-4b16-94c0-ee554f313a42",
            price: 3570.63,
            price_sources: [],
            processed_ms: 1718465630017,
          },
          {
            leverage: 0.0289795968,
            order_type: "LONG",
            order_uuid: "12d9427b-277c-4a37-b07a-a349f11b00ab",
            price: 3571.71,
            price_sources: [],
            processed_ms: 1718465904427,
          },
          {
            leverage: 0.0289795968,
            order_type: "LONG",
            order_uuid: "cf62c41d-980a-4fde-a53d-4b0a5b6bceac",
            price: 3570.26,
            price_sources: [],
            processed_ms: 1718465935998,
          },
          {
            leverage: 0.02608163712,
            order_type: "LONG",
            order_uuid: "d53dc9c7-8d3b-4a6c-9a4d-17d224031bc3",
            price: 3569.1,
            price_sources: [],
            processed_ms: 1718465994203,
          },
          {
            leverage: 0.018257145984,
            order_type: "LONG",
            order_uuid: "fe02b1ab-375b-4c60-b94c-00816b70f986",
            price: 3568.72,
            price_sources: [],
            processed_ms: 1718466035887,
          },
          {
            leverage: 0.0200828605824,
            order_type: "LONG",
            order_uuid: "650700ae-5663-4dba-bad3-1c974e44fb11",
            price: 3566.92,
            price_sources: [],
            processed_ms: 1718466074099,
          },
          {
            leverage: 0.022091146640640002,
            order_type: "LONG",
            order_uuid: "fa69b200-48d8-47e3-960e-3975796826d1",
            price: 3566.43,
            price_sources: [],
            processed_ms: 1718466103868,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "eef8f7cd-8a34-4ae6-a22f-03e93a2e62af",
            price: 3594.6,
            price_sources: [],
            processed_ms: 1718548014520,
          },
        ],
        position_type: "FLAT",
        position_uuid: "296429c6-960c-4b0d-ac68-9a71bc410f06",
        return_at_close: 1.0021393963414051,
        trade_pair: ["ETHUSD", "ETH/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 65374.57,
        close_ms: 0,
        current_return: 0.980072333936575,
        initial_entry_price: 65374.57,
        is_closed_position: false,
        miner_hotkey: "5FFPapsywE9Zfg7UFSYh7y7Ds88q9rSgZpGHaQ3qRJAxobsD",
        net_leverage: 0.18,
        open_ms: 1718387740156,
        orders: [
          {
            leverage: 0.18,
            order_type: "LONG",
            order_uuid: "bc4182b8-4edb-4b45-bc7e-91aa609b0eff",
            price: 65374.57,
            price_sources: [],
            processed_ms: 1718387739496,
          },
        ],
        position_type: "LONG",
        position_uuid: "99911612-bb4a-4184-b74d-63f7d0e05798",
        return_at_close: 0.9799841274265206,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
    ],
    thirty_day_returns: 0.999094471696264,
  },
  "5FHGkVUwhwMh2b8R1VvGSTe9cdMLvYx6ccbAwYKamnBoBuR2": {
    all_time_returns: 1.0,
    n_positions: 0,
    percentage_profitable: 0.0,
    positions: [
      {
        average_entry_price: 64671.55285714286,
        close_ms: 1711204275362,
        current_return: 0.8665539560645812,
        initial_entry_price: 64471.9,
        is_closed_position: true,
        miner_hotkey: "5FHGkVUwhwMh2b8R1VvGSTe9cdMLvYx6ccbAwYKamnBoBuR2",
        net_leverage: -14.0,
        open_ms: 1711203509364,
        orders: [
          {
            leverage: -5.0,
            order_type: "SHORT",
            order_uuid: "fa998492-13ff-4a97-9db4-6cd73f71c475",
            price: 64471.9,
            price_sources: [],
            processed_ms: 1711203509364,
          },
          {
            leverage: -2.0,
            order_type: "SHORT",
            order_uuid: "96a7786a-4d12-42e8-811f-29627cf9409c",
            price: 64655.82,
            price_sources: [],
            processed_ms: 1711203742185,
          },
          {
            leverage: -2.0,
            order_type: "SHORT",
            order_uuid: "2789801d-f813-4d2f-b849-f8a64e7b2238",
            price: 64700.0,
            price_sources: [],
            processed_ms: 1711204087910,
          },
          {
            leverage: -5.0,
            order_type: "SHORT",
            order_uuid: "bc767c75-5968-4f61-8db6-f4cc11982af2",
            price: 64866.12,
            price_sources: [],
            processed_ms: 1711204209313,
          },
        ],
        position_type: "FLAT",
        position_uuid: "1b3eb36d-5473-4dd3-879d-53ee2bbc1e1f",
        return_at_close: 0.8422904452947729,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
    ],
    thirty_day_returns: 1.0,
  },
  "5FHPjCQpfW9g9HE1dMh1anM8LLynqeSjnJFGKMXLagvzKp3v": {
    all_time_returns: 1.0,
    n_positions: 0,
    percentage_profitable: 0.0,
    positions: [
      {
        average_entry_price: 64280.80000000001,
        close_ms: 1711241648677,
        current_return: 1.0014327295242125,
        initial_entry_price: 64280.8,
        is_closed_position: true,
        miner_hotkey: "5FHPjCQpfW9g9HE1dMh1anM8LLynqeSjnJFGKMXLagvzKp3v",
        net_leverage: 0.0,
        open_ms: 1711238114660,
        orders: [
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "5346664d-fcb4-4451-8eeb-4fc41487b428",
            price: 64280.8,
            price_sources: [],
            processed_ms: 1711238114660,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "e7d95012-96a6-47d5-be43-31d77f0b8abf",
            price: 64587.79,
            price_sources: [],
            processed_ms: 1711241648677,
          },
        ],
        position_type: "FLAT",
        position_uuid: "860e3a3f-28f3-42de-910e-a7c1b761155f",
        return_at_close: 1.000831869886498,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 29447.40952380934,
        close_ms: 1711393709051,
        current_return: 1.0197880377895554,
        initial_entry_price: 68838.73,
        is_closed_position: true,
        miner_hotkey: "5FHPjCQpfW9g9HE1dMh1anM8LLynqeSjnJFGKMXLagvzKp3v",
        net_leverage: 0.0,
        open_ms: 1711375956803,
        orders: [
          {
            leverage: 1.25,
            order_type: "LONG",
            order_uuid: "dbb612f0-9c0d-416b-9780-c3efde702c7a",
            price: 68838.73,
            price_sources: [],
            processed_ms: 1711375956802,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "17d1ae3e-2c65-4b04-917e-43cadc3f37aa",
            price: 68868.54,
            price_sources: [],
            processed_ms: 1711376475121,
          },
          {
            leverage: -1.2,
            order_type: "SHORT",
            order_uuid: "c8e7be9a-a05a-4fe0-8335-405e38057e65",
            price: 69210.38,
            price_sources: [],
            processed_ms: 1711376773890,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "64f743e1-ff3d-4385-ab62-130cf3153f0f",
            price: 69095.56,
            price_sources: [],
            processed_ms: 1711377923225,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "e14a101c-4456-47a1-b06e-eebc3b311ffb",
            price: 69061.84,
            price_sources: [],
            processed_ms: 1711378215353,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "2e8eca31-1977-4cad-8cb6-fca7fb35ce8a",
            price: 69975.64,
            price_sources: [],
            processed_ms: 1711382010112,
          },
          {
            leverage: -0.525,
            order_type: "SHORT",
            order_uuid: "5384afc1-5103-4e07-a7e1-12814463037b",
            price: 69797.01,
            price_sources: [],
            processed_ms: 1711383862073,
          },
          {
            leverage: -0.2625,
            order_type: "SHORT",
            order_uuid: "2d5efa3f-588f-4e0d-8ba1-65dfe1cede14",
            price: 70154.79,
            price_sources: [],
            processed_ms: 1711385648899,
          },
          {
            leverage: -0.13125,
            order_type: "SHORT",
            order_uuid: "bc4add69-a1a5-46bf-98b2-980ebc61e4ef",
            price: 70674.24,
            price_sources: [],
            processed_ms: 1711387461779,
          },
          {
            leverage: -0.065625,
            order_type: "SHORT",
            order_uuid: "b6fd2c73-46e9-48ff-a581-6ea01a8bdd51",
            price: 70448.68,
            price_sources: [],
            processed_ms: 1711389278194,
          },
          {
            leverage: -0.0328125,
            order_type: "SHORT",
            order_uuid: "7b67846b-de05-44f2-a677-f45467b04a3b",
            price: 70761.36,
            price_sources: [],
            processed_ms: 1711391095862,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "be060f10-76e2-47b9-8815-ca905b6e96d5",
            price: 70961.57,
            price_sources: [],
            processed_ms: 1711393709051,
          },
        ],
        position_type: "FLAT",
        position_uuid: "85d3de35-fc17-4bdc-ac35-df92f1d464a5",
        return_at_close: 1.0167286736761867,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 69443.45999999999,
        close_ms: 1711410055451,
        current_return: 1.00225261789862,
        initial_entry_price: 70158.37,
        is_closed_position: true,
        miner_hotkey: "5FHPjCQpfW9g9HE1dMh1anM8LLynqeSjnJFGKMXLagvzKp3v",
        net_leverage: 0.0,
        open_ms: 1711402795644,
        orders: [
          {
            leverage: 3.0,
            order_type: "LONG",
            order_uuid: "b50cab5e-41be-4b3e-be5d-dbaff5a713cc",
            price: 70158.37,
            price_sources: [],
            processed_ms: 1711402795643,
          },
          {
            leverage: -1.5,
            order_type: "SHORT",
            order_uuid: "bc0b56f7-074f-481f-9f25-c4fcd832932e",
            price: 70101.93,
            price_sources: [],
            processed_ms: 1711404605867,
          },
          {
            leverage: -0.75,
            order_type: "SHORT",
            order_uuid: "07e7763c-7c10-45eb-94e9-c8156014b763",
            price: 70534.0,
            price_sources: [],
            processed_ms: 1711406421624,
          },
          {
            leverage: -0.375,
            order_type: "SHORT",
            order_uuid: "203a7510-0ada-40f3-b911-57aa414dff21",
            price: 70347.78,
            price_sources: [],
            processed_ms: 1711408237295,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "42ca35a4-4edb-41c6-814e-45980db55c29",
            price: 69864.9,
            price_sources: [],
            processed_ms: 1711410055451,
          },
        ],
        position_type: "FLAT",
        position_uuid: "49b24f19-d32b-4af7-8b2f-875d9bdb77c8",
        return_at_close: 0.9962391021912282,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 72318.8692993631,
        close_ms: 1711484536185,
        current_return: 0.9863062359097496,
        initial_entry_price: 70261.35,
        is_closed_position: true,
        miner_hotkey: "5FHPjCQpfW9g9HE1dMh1anM8LLynqeSjnJFGKMXLagvzKp3v",
        net_leverage: 0.0,
        open_ms: 1711464557933,
        orders: [
          {
            leverage: 3.0,
            order_type: "LONG",
            order_uuid: "695d2718-ca8e-41d0-acf0-40d622bbaa81",
            price: 70261.35,
            price_sources: [],
            processed_ms: 1711464557932,
          },
          {
            leverage: 0.8999999999999999,
            order_type: "LONG",
            order_uuid: "e1bc0045-e071-486b-b8f6-264ec373451c",
            price: 69816.2,
            price_sources: [],
            processed_ms: 1711466379349,
          },
          {
            leverage: -1.95,
            order_type: "SHORT",
            order_uuid: "9eca9560-d285-414a-abff-0992f5b267b5",
            price: 70343.53,
            price_sources: [],
            processed_ms: 1711468189156,
          },
          {
            leverage: -0.975,
            order_type: "SHORT",
            order_uuid: "55752b95-18e1-43e7-a47a-79a7f3e3d14e",
            price: 69890.61,
            price_sources: [],
            processed_ms: 1711470000173,
          },
          {
            leverage: -0.4875,
            order_type: "SHORT",
            order_uuid: "8888600b-d058-4941-9751-2445a2fc3ace",
            price: 69741.37,
            price_sources: [],
            processed_ms: 1711471820921,
          },
          {
            leverage: -0.24375,
            order_type: "SHORT",
            order_uuid: "df7d31c5-c114-4512-86f1-a455fa40a75d",
            price: 69836.42,
            price_sources: [],
            processed_ms: 1711473638506,
          },
          {
            leverage: 0.8999999999999998,
            order_type: "LONG",
            order_uuid: "738c2431-ab92-46e5-966b-cd1eeb76574e",
            price: 70184.02,
            price_sources: [],
            processed_ms: 1711475455994,
          },
          {
            leverage: -0.5718749999999999,
            order_type: "SHORT",
            order_uuid: "52451108-ffc4-4605-bbfc-3103723bd19d",
            price: 70229.56,
            price_sources: [],
            processed_ms: 1711477272450,
          },
          {
            leverage: 0.8999999999999999,
            order_type: "LONG",
            order_uuid: "0283e87e-5918-4808-b7fd-cea28fac4883",
            price: 70342.68,
            price_sources: [],
            processed_ms: 1711479080952,
          },
          {
            leverage: -0.7359374999999999,
            order_type: "SHORT",
            order_uuid: "89e0b6ea-47ad-40ac-9c26-7a2e0e12f46a",
            price: 69736.81,
            price_sources: [],
            processed_ms: 1711480942534,
          },
          {
            leverage: -0.36796874999999996,
            order_type: "SHORT",
            order_uuid: "056bd2e6-15d1-4f9c-b798-4bde03c0d030",
            price: 69740.54,
            price_sources: [],
            processed_ms: 1711482725676,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "68edd51b-b300-4542-b648-599289f11118",
            price: 69704.13,
            price_sources: [],
            processed_ms: 1711484536185,
          },
        ],
        position_type: "FLAT",
        position_uuid: "31c3a7e2-10d2-4cbd-8a0e-6127ad3d3ea9",
        return_at_close: 0.9786130472696536,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 71266.94,
        close_ms: 1711546972873,
        current_return: 0.9703026396250494,
        initial_entry_price: 71266.94,
        is_closed_position: true,
        miner_hotkey: "5FHPjCQpfW9g9HE1dMh1anM8LLynqeSjnJFGKMXLagvzKp3v",
        net_leverage: 0.0,
        open_ms: 1711546068551,
        orders: [
          {
            leverage: 3.0,
            order_type: "LONG",
            order_uuid: "b693f406-7f09-4e06-9796-883655de4ded",
            price: 71266.94,
            price_sources: [],
            processed_ms: 1711546068550,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "92f163a2-74ac-4d6f-9de2-371d01654d06",
            price: 70561.46,
            price_sources: [],
            processed_ms: 1711546972873,
          },
        ],
        position_type: "FLAT",
        position_uuid: "1ab272ee-5130-4916-877c-0b5360cdcbe0",
        return_at_close: 0.964480823787299,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 136696.6299999991,
        close_ms: 1711566143257,
        current_return: 0.9770678899556344,
        initial_entry_price: 69639.14,
        is_closed_position: true,
        miner_hotkey: "5FHPjCQpfW9g9HE1dMh1anM8LLynqeSjnJFGKMXLagvzKp3v",
        net_leverage: 0.0,
        open_ms: 1711549638849,
        orders: [
          {
            leverage: 3.0,
            order_type: "LONG",
            order_uuid: "79b49aec-3fc0-40bd-8de4-2046605fa89e",
            price: 69639.14,
            price_sources: [],
            processed_ms: 1711549638848,
          },
          {
            leverage: -1.5,
            order_type: "SHORT",
            order_uuid: "968f01e3-9fd2-4922-b1ca-abbacd282f9b",
            price: 69261.59,
            price_sources: [],
            processed_ms: 1711553427211,
          },
          {
            leverage: -0.75,
            order_type: "SHORT",
            order_uuid: "e9e5b08b-56fd-4dca-8d97-e093700e71eb",
            price: 68843.93,
            price_sources: [],
            processed_ms: 1711555248534,
          },
          {
            leverage: -0.375,
            order_type: "SHORT",
            order_uuid: "1dd09037-1cc9-4ca0-88a8-9afb514882a4",
            price: 69071.14,
            price_sources: [],
            processed_ms: 1711557077927,
          },
          {
            leverage: -0.1875,
            order_type: "SHORT",
            order_uuid: "7aea6a77-59a7-44e9-9228-dcf89dc0af58",
            price: 69228.8,
            price_sources: [],
            processed_ms: 1711558878384,
          },
          {
            leverage: -0.09375,
            order_type: "SHORT",
            order_uuid: "9448fb1a-b921-48de-b289-77395996920a",
            price: 69116.72,
            price_sources: [],
            processed_ms: 1711560692914,
          },
          {
            leverage: -0.046875,
            order_type: "SHORT",
            order_uuid: "21a47000-df06-4406-956d-2b07c5d3dc3d",
            price: 68715.36,
            price_sources: [],
            processed_ms: 1711562514222,
          },
          {
            leverage: -0.0234375,
            order_type: "SHORT",
            order_uuid: "506c4b0a-2c19-4927-9e11-0287e8f3bdec",
            price: 68499.53,
            price_sources: [],
            processed_ms: 1711564332704,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "1563ba55-61b3-4169-9174-36293a7f1e11",
            price: 68559.14,
            price_sources: [],
            processed_ms: 1711566143257,
          },
        ],
        position_type: "FLAT",
        position_uuid: "5e5f8345-a3b2-4b12-8804-34bb022c031b",
        return_at_close: 0.9712054826159006,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 68553.88,
        close_ms: 1711578904651,
        current_return: 0.9964276851050214,
        initial_entry_price: 68944.51,
        is_closed_position: true,
        miner_hotkey: "5FHPjCQpfW9g9HE1dMh1anM8LLynqeSjnJFGKMXLagvzKp3v",
        net_leverage: 0.0,
        open_ms: 1711571593992,
        orders: [
          {
            leverage: -3.5,
            order_type: "SHORT",
            order_uuid: "d2bf0c9d-ad43-45fd-a9bc-d1f7e52ff701",
            price: 68944.51,
            price_sources: [],
            processed_ms: 1711571593990,
          },
          {
            leverage: 1.0499999999999998,
            order_type: "LONG",
            order_uuid: "99f038bd-83c1-4ac5-89e3-950319b4bc71",
            price: 68924.11,
            price_sources: [],
            processed_ms: 1711573422060,
          },
          {
            leverage: 1.05,
            order_type: "LONG",
            order_uuid: "c3ea3f96-d749-44f5-b858-531f66e66c34",
            price: 69144.11,
            price_sources: [],
            processed_ms: 1711575225964,
          },
          {
            leverage: 1.05,
            order_type: "LONG",
            order_uuid: "15dd8481-c22f-4293-915d-d51c3002047f",
            price: 68895.52,
            price_sources: [],
            processed_ms: 1711577048731,
          },
          {
            leverage: 1.05,
            order_type: "LONG",
            order_uuid: "a67ddae0-789d-4b7e-b8fd-1a88035f8593",
            price: 69257.57,
            price_sources: [],
            processed_ms: 1711578904651,
          },
        ],
        position_type: "FLAT",
        position_uuid: "95ace1b7-a3e8-4891-975e-8fbdc8fa86fb",
        return_at_close: 0.9894526913092863,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 69420.64999999983,
        close_ms: 1711589762259,
        current_return: 0.9999812280299819,
        initial_entry_price: 69358.73,
        is_closed_position: true,
        miner_hotkey: "5FHPjCQpfW9g9HE1dMh1anM8LLynqeSjnJFGKMXLagvzKp3v",
        net_leverage: 0.0,
        open_ms: 1711580675913,
        orders: [
          {
            leverage: -3.5,
            order_type: "SHORT",
            order_uuid: "0774e600-dc7d-4ca6-b848-d9a5213928f5",
            price: 69358.73,
            price_sources: [],
            processed_ms: 1711580675911,
          },
          {
            leverage: 1.0499999999999998,
            order_type: "LONG",
            order_uuid: "03fcb66b-359d-4a56-a60d-683cf8c5035a",
            price: 69069.21,
            price_sources: [],
            processed_ms: 1711582495674,
          },
          {
            leverage: 1.05,
            order_type: "LONG",
            order_uuid: "10e1cc17-dcfd-4c68-b18c-8a337e4094b2",
            price: 69396.92,
            price_sources: [],
            processed_ms: 1711584310424,
          },
          {
            leverage: 0.7000000000000001,
            order_type: "LONG",
            order_uuid: "86db4f98-b5e7-4f88-b4ab-317fdf625f66",
            price: 69599.69,
            price_sources: [],
            processed_ms: 1711586124923,
          },
          {
            leverage: 0.35000000000000003,
            order_type: "LONG",
            order_uuid: "21f55b4a-7290-4653-bbe7-d0a93311af38",
            price: 69568.88,
            price_sources: [],
            processed_ms: 1711587998670,
          },
          {
            leverage: 1.05,
            order_type: "LONG",
            order_uuid: "bfc4483f-7ea4-4a6e-8b93-4a19e3caab73",
            price: 69424.37,
            price_sources: [],
            processed_ms: 1711589762259,
          },
        ],
        position_type: "FLAT",
        position_uuid: "662e5c75-100b-4246-9073-5b50cc43bbe2",
        return_at_close: 0.992981359433772,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 67144.13999999987,
        close_ms: 1711618867396,
        current_return: 0.9825827294200332,
        initial_entry_price: 69258.01,
        is_closed_position: true,
        miner_hotkey: "5FHPjCQpfW9g9HE1dMh1anM8LLynqeSjnJFGKMXLagvzKp3v",
        net_leverage: 0.0,
        open_ms: 1711597031627,
        orders: [
          {
            leverage: -3.5,
            order_type: "SHORT",
            order_uuid: "cea54d7e-bc13-42a7-8ad2-59c509302cfd",
            price: 69258.01,
            price_sources: [],
            processed_ms: 1711597031625,
          },
          {
            leverage: 1.75,
            order_type: "LONG",
            order_uuid: "9710cdee-3d4b-4cbf-b9ce-39459aef5f5e",
            price: 69430.96,
            price_sources: [],
            processed_ms: 1711598839849,
          },
          {
            leverage: 1.05,
            order_type: "LONG",
            order_uuid: "fb280725-d46f-448a-b109-eab0e5ea9dbc",
            price: 69545.38,
            price_sources: [],
            processed_ms: 1711600657061,
          },
          {
            leverage: 0.35,
            order_type: "LONG",
            order_uuid: "1ff7d198-26cd-463f-98cf-b51d718b85ad",
            price: 69645.02,
            price_sources: [],
            processed_ms: 1711602478143,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "14afb468-4443-46e9-a050-1bb8b9365b27",
            price: 70590.67,
            price_sources: [],
            processed_ms: 1711618867396,
          },
        ],
        position_type: "FLAT",
        position_uuid: "60dc4640-583a-4dd5-9aab-f3a2e4fac49f",
        return_at_close: 0.9757046503140929,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 70800.26,
        close_ms: 1711634196827,
        current_return: 0.9950075748309396,
        initial_entry_price: 70800.26,
        is_closed_position: true,
        miner_hotkey: "5FHPjCQpfW9g9HE1dMh1anM8LLynqeSjnJFGKMXLagvzKp3v",
        net_leverage: 0.0,
        open_ms: 1711634078700,
        orders: [
          {
            leverage: -3.5,
            order_type: "SHORT",
            order_uuid: "0e527150-b1d2-4d3b-a98f-edd4ea9c70ef",
            price: 70800.26,
            price_sources: [],
            processed_ms: 1711634078698,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "2a280fc8-00f2-4aae-859a-493220c072f3",
            price: 70901.25,
            price_sources: [],
            processed_ms: 1711634196827,
          },
        ],
        position_type: "FLAT",
        position_uuid: "c5f3d513-cb12-4c20-a75e-afacd022743c",
        return_at_close: 0.988042521807123,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
    ],
    thirty_day_returns: 1.0,
  },
  "5FLPynmkUXdJKsNPRahZhVSqJ3BoCUXnWJUsTtncyj7B5aX9": {
    all_time_returns: 1.0,
    n_positions: 0,
    percentage_profitable: 0.0,
    positions: [
      {
        average_entry_price: 64653.33,
        close_ms: 1711207451687,
        current_return: 0.9984172508979816,
        initial_entry_price: 64653.33,
        is_closed_position: true,
        miner_hotkey: "5FLPynmkUXdJKsNPRahZhVSqJ3BoCUXnWJUsTtncyj7B5aX9",
        net_leverage: 0.0,
        open_ms: 1711203855602,
        orders: [
          {
            leverage: -0.6,
            order_type: "SHORT",
            order_uuid: "be1eca9a-85f3-49f7-8b28-97fd64ba5dee",
            price: 64653.33,
            price_sources: [],
            processed_ms: 1711203855602,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "a404bcd0-6fa9-4e07-b728-7ce7886f5310",
            price: 64823.88,
            price_sources: [],
            processed_ms: 1711207451687,
          },
        ],
        position_type: "FLAT",
        position_uuid: "64c7b9e3-b33d-4be9-b995-60792bcb1daa",
        return_at_close: 0.9972191501969041,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 65229.54000000001,
        close_ms: 1711214763811,
        current_return: 0.9973618394365499,
        initial_entry_price: 65229.54,
        is_closed_position: true,
        miner_hotkey: "5FLPynmkUXdJKsNPRahZhVSqJ3BoCUXnWJUsTtncyj7B5aX9",
        net_leverage: 0.0,
        open_ms: 1711211241543,
        orders: [
          {
            leverage: -0.6,
            order_type: "SHORT",
            order_uuid: "292a88e2-3129-4399-a666-2b88050a19eb",
            price: 65229.54,
            price_sources: [],
            processed_ms: 1711211241542,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "6440d829-64e0-4d39-9d29-6ba21281c3e5",
            price: 65516.35,
            price_sources: [],
            processed_ms: 1711214763811,
          },
        ],
        position_type: "FLAT",
        position_uuid: "9c783794-6211-4f36-8b62-f5b441a1af3d",
        return_at_close: 0.996165005229226,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 65524.16,
        close_ms: 1711222080837,
        current_return: 1.0033388905710505,
        initial_entry_price: 65524.16,
        is_closed_position: true,
        miner_hotkey: "5FLPynmkUXdJKsNPRahZhVSqJ3BoCUXnWJUsTtncyj7B5aX9",
        net_leverage: 0.0,
        open_ms: 1711218548807,
        orders: [
          {
            leverage: -0.6,
            order_type: "SHORT",
            order_uuid: "ac071b60-42ec-4bab-bb89-fa70d3d48544",
            price: 65524.16,
            price_sources: [],
            processed_ms: 1711218548807,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "4be1de7f-6da8-446d-b54b-63cd4a9b29bb",
            price: 65159.53,
            price_sources: [],
            processed_ms: 1711222080837,
          },
        ],
        position_type: "FLAT",
        position_uuid: "28149a5b-18ab-41aa-9983-bede088b7150",
        return_at_close: 1.0021348839023654,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 65150.83999999999,
        close_ms: 1711225738688,
        current_return: 1.0021020450388667,
        initial_entry_price: 65150.84,
        is_closed_position: true,
        miner_hotkey: "5FLPynmkUXdJKsNPRahZhVSqJ3BoCUXnWJUsTtncyj7B5aX9",
        net_leverage: 0.0,
        open_ms: 1711222167716,
        orders: [
          {
            leverage: -0.6,
            order_type: "SHORT",
            order_uuid: "ab2b40b9-3ec0-43d8-95e0-1133b83c137d",
            price: 65150.84,
            price_sources: [],
            processed_ms: 1711222167715,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "c9ae46d3-9f2d-4fdb-8ea4-860d9dc43e51",
            price: 64922.59,
            price_sources: [],
            processed_ms: 1711225738688,
          },
        ],
        position_type: "FLAT",
        position_uuid: "8ea91eec-6cda-4e2c-8dbb-d8cc0d9fc7b9",
        return_at_close: 1.00089952258482,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 65011.97,
        close_ms: 1711229420514,
        current_return: 1.0014160161582553,
        initial_entry_price: 65011.97,
        is_closed_position: true,
        miner_hotkey: "5FLPynmkUXdJKsNPRahZhVSqJ3BoCUXnWJUsTtncyj7B5aX9",
        net_leverage: 0.0,
        open_ms: 1711225825668,
        orders: [
          {
            leverage: -0.6,
            order_type: "SHORT",
            order_uuid: "8182667b-4a62-400d-9007-3072ce13eae6",
            price: 65011.97,
            price_sources: [],
            processed_ms: 1711225825667,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "30dba5c4-aaa7-406e-b62b-1a0b09c65325",
            price: 64858.54,
            price_sources: [],
            processed_ms: 1711229420514,
          },
        ],
        position_type: "FLAT",
        position_uuid: "4fbacaa0-7231-4246-a951-16c173b6ce27",
        return_at_close: 1.0002143169388653,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 64818.21,
        close_ms: 1711233067155,
        current_return: 0.998558923487705,
        initial_entry_price: 64818.21,
        is_closed_position: true,
        miner_hotkey: "5FLPynmkUXdJKsNPRahZhVSqJ3BoCUXnWJUsTtncyj7B5aX9",
        net_leverage: 0.0,
        open_ms: 1711229525141,
        orders: [
          {
            leverage: -0.6,
            order_type: "SHORT",
            order_uuid: "001f14a6-8e7e-4123-8d77-af9a5182f546",
            price: 64818.21,
            price_sources: [],
            processed_ms: 1711229525140,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "dee11f39-bf3b-43b9-ae5a-820d69d770b8",
            price: 64973.89,
            price_sources: [],
            processed_ms: 1711233067155,
          },
        ],
        position_type: "FLAT",
        position_uuid: "4caf1cde-c80f-41a2-8742-3adbf7f4957a",
        return_at_close: 0.9973606527795198,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 65012.40000000001,
        close_ms: 1711236734750,
        current_return: 1.005483323181424,
        initial_entry_price: 65012.4,
        is_closed_position: true,
        miner_hotkey: "5FLPynmkUXdJKsNPRahZhVSqJ3BoCUXnWJUsTtncyj7B5aX9",
        net_leverage: 0.0,
        open_ms: 1711233197314,
        orders: [
          {
            leverage: -0.6,
            order_type: "SHORT",
            order_uuid: "e11cc9f2-dac9-4a63-a198-a50eabe3b711",
            price: 65012.4,
            price_sources: [],
            processed_ms: 1711233197313,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "83dc9f99-cc2b-4dff-b350-e4af02945fdb",
            price: 64418.26,
            price_sources: [],
            processed_ms: 1711236734750,
          },
        ],
        position_type: "FLAT",
        position_uuid: "3ded05b7-3928-4c5f-9aec-89b46857f823",
        return_at_close: 1.0042767431936064,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 64514.73000000001,
        close_ms: 1711244059704,
        current_return: 1.0010991908359532,
        initial_entry_price: 64514.73,
        is_closed_position: true,
        miner_hotkey: "5FLPynmkUXdJKsNPRahZhVSqJ3BoCUXnWJUsTtncyj7B5aX9",
        net_leverage: 0.0,
        open_ms: 1711240526786,
        orders: [
          {
            leverage: 0.6,
            order_type: "LONG",
            order_uuid: "5e9c76e4-8482-4b6a-a535-0dce562c115f",
            price: 64514.73,
            price_sources: [],
            processed_ms: 1711240526785,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "742a3151-c882-4f58-9deb-d541cf99d467",
            price: 64632.92,
            price_sources: [],
            processed_ms: 1711244059704,
          },
        ],
        position_type: "FLAT",
        position_uuid: "99c165fa-9fd2-4e21-bdcc-d4376d5dcb4c",
        return_at_close: 0.9998978718069501,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 64354.02,
        close_ms: 1711251384564,
        current_return: 0.9977421457121094,
        initial_entry_price: 64354.02,
        is_closed_position: true,
        miner_hotkey: "5FLPynmkUXdJKsNPRahZhVSqJ3BoCUXnWJUsTtncyj7B5aX9",
        net_leverage: 0.0,
        open_ms: 1711247862417,
        orders: [
          {
            leverage: 0.6,
            order_type: "LONG",
            order_uuid: "c3cfe09b-5c4a-4743-a171-9ea059f51bd7",
            price: 64354.02,
            price_sources: [],
            processed_ms: 1711247862415,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "bbaac23e-6266-4671-a5fc-650b0793bbdb",
            price: 64111.85,
            price_sources: [],
            processed_ms: 1711251384564,
          },
        ],
        position_type: "FLAT",
        position_uuid: "bfa38078-cb7b-4905-9e77-2da4ab96e424",
        return_at_close: 0.9965448551372549,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 64151.44,
        close_ms: 1711255037804,
        current_return: 0.998629243552444,
        initial_entry_price: 64151.44,
        is_closed_position: true,
        miner_hotkey: "5FLPynmkUXdJKsNPRahZhVSqJ3BoCUXnWJUsTtncyj7B5aX9",
        net_leverage: 0.0,
        open_ms: 1711251471704,
        orders: [
          {
            leverage: 0.6,
            order_type: "LONG",
            order_uuid: "3d6436d5-6233-4652-a2ce-c84d21628379",
            price: 64151.44,
            price_sources: [],
            processed_ms: 1711251471702,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "b8492c3b-2de3-4349-91c3-7ade75f1cb40",
            price: 64004.88,
            price_sources: [],
            processed_ms: 1711255037804,
          },
        ],
        position_type: "FLAT",
        position_uuid: "bdb8a576-f28a-4459-a041-0ea03c999708",
        return_at_close: 0.997430888460181,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 63894.38,
        close_ms: 1711255967636,
        current_return: 1.0020290986468607,
        initial_entry_price: 63894.38,
        is_closed_position: true,
        miner_hotkey: "5FLPynmkUXdJKsNPRahZhVSqJ3BoCUXnWJUsTtncyj7B5aX9",
        net_leverage: 0.0,
        open_ms: 1711255124721,
        orders: [
          {
            leverage: 0.6,
            order_type: "LONG",
            order_uuid: "79619098-2826-41a9-ae76-cc5e176e556a",
            price: 63894.38,
            price_sources: [],
            processed_ms: 1711255124720,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "d6c9dd1e-268e-40a0-bdf0-822f8cc3ce58",
            price: 64110.46,
            price_sources: [],
            processed_ms: 1711255967636,
          },
        ],
        position_type: "FLAT",
        position_uuid: "f18ab471-bea6-4ab0-a85f-35f3593ea864",
        return_at_close: 1.0008266637284846,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 64146.579999999994,
        close_ms: 1711259337479,
        current_return: 1.0019638771077117,
        initial_entry_price: 64146.58,
        is_closed_position: true,
        miner_hotkey: "5FLPynmkUXdJKsNPRahZhVSqJ3BoCUXnWJUsTtncyj7B5aX9",
        net_leverage: 0.0,
        open_ms: 1711256054681,
        orders: [
          {
            leverage: 0.6,
            order_type: "LONG",
            order_uuid: "c249d2f7-955a-4f16-86a1-7a525f95545a",
            price: 64146.58,
            price_sources: [],
            processed_ms: 1711256054680,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "a1c33a7b-0af1-4b8d-83f2-d1669de94ae9",
            price: 64356.54,
            price_sources: [],
            processed_ms: 1711259337479,
          },
        ],
        position_type: "FLAT",
        position_uuid: "e78bb32b-98ea-4f5a-b922-cdd49f48fd85",
        return_at_close: 1.0007615204551825,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 64353.969999999994,
        close_ms: 1711263015625,
        current_return: 0.9964848710965307,
        initial_entry_price: 64353.97,
        is_closed_position: true,
        miner_hotkey: "5FLPynmkUXdJKsNPRahZhVSqJ3BoCUXnWJUsTtncyj7B5aX9",
        net_leverage: 0.0,
        open_ms: 1711259425439,
        orders: [
          {
            leverage: 1.25,
            order_type: "LONG",
            order_uuid: "bdca4ff4-714b-464f-bb1b-fb82040a3e3c",
            price: 64353.97,
            price_sources: [],
            processed_ms: 1711259425438,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "f7f6fbf4-a192-4b01-8f0d-95ff07223f80",
            price: 64173.0,
            price_sources: [],
            processed_ms: 1711263015625,
          },
        ],
        position_type: "FLAT",
        position_uuid: "dcb7bd68-aa50-43de-b35f-66d658cb3dfc",
        return_at_close: 0.9939936589187894,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 64117.11,
        close_ms: 1711266651836,
        current_return: 1.003999907357022,
        initial_entry_price: 64117.11,
        is_closed_position: true,
        miner_hotkey: "5FLPynmkUXdJKsNPRahZhVSqJ3BoCUXnWJUsTtncyj7B5aX9",
        net_leverage: 0.0,
        open_ms: 1711263118480,
        orders: [
          {
            leverage: 1.25,
            order_type: "LONG",
            order_uuid: "3b08db27-4470-4f90-84c8-00b5d1c9d94f",
            price: 64117.11,
            price_sources: [],
            processed_ms: 1711263118478,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "d495b338-10cd-46b2-b6fc-17aa5095c107",
            price: 64322.28,
            price_sources: [],
            processed_ms: 1711266651836,
          },
        ],
        position_type: "FLAT",
        position_uuid: "0931ef63-ff4f-4f8d-8819-12ba02041dfa",
        return_at_close: 1.0014899075886294,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 64344.009999999995,
        close_ms: 1711270316676,
        current_return: 1.0123154431935468,
        initial_entry_price: 64344.01,
        is_closed_position: true,
        miner_hotkey: "5FLPynmkUXdJKsNPRahZhVSqJ3BoCUXnWJUsTtncyj7B5aX9",
        net_leverage: 0.0,
        open_ms: 1711266785863,
        orders: [
          {
            leverage: 1.25,
            order_type: "LONG",
            order_uuid: "88d6aa5d-9a30-4daa-a784-2797e0f787cd",
            price: 64344.01,
            price_sources: [],
            processed_ms: 1711266785861,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "07934383-f382-4fa1-80d2-6ae745e874e1",
            price: 64977.95,
            price_sources: [],
            processed_ms: 1711270316676,
          },
        ],
        position_type: "FLAT",
        position_uuid: "762a633c-c99f-46d2-9df8-756ea31301e5",
        return_at_close: 1.009784654585563,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 64988.05,
        close_ms: 1711273974743,
        current_return: 1.000393726231207,
        initial_entry_price: 64988.05,
        is_closed_position: true,
        miner_hotkey: "5FLPynmkUXdJKsNPRahZhVSqJ3BoCUXnWJUsTtncyj7B5aX9",
        net_leverage: 0.0,
        open_ms: 1711270403649,
        orders: [
          {
            leverage: 1.25,
            order_type: "LONG",
            order_uuid: "a6d41faf-6f36-490e-b7e2-c8f8c2b0928b",
            price: 64988.05,
            price_sources: [],
            processed_ms: 1711270403647,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "8685542c-7c3d-4baf-a05a-4226b2dcb580",
            price: 65008.52,
            price_sources: [],
            processed_ms: 1711273974743,
          },
        ],
        position_type: "FLAT",
        position_uuid: "49aa8dec-9c53-4e4d-9526-08ddd1617245",
        return_at_close: 0.997892741915629,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 65145.23,
        close_ms: 1711286726774,
        current_return: 1.0077897107739122,
        initial_entry_price: 65145.23,
        is_closed_position: true,
        miner_hotkey: "5FLPynmkUXdJKsNPRahZhVSqJ3BoCUXnWJUsTtncyj7B5aX9",
        net_leverage: 0.0,
        open_ms: 1711277721672,
        orders: [
          {
            leverage: 1.25,
            order_type: "LONG",
            order_uuid: "9f9b32ab-9f5a-4ae3-959c-76610454691d",
            price: 65145.23,
            price_sources: [],
            processed_ms: 1711277721671,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "b359ec4c-f934-4d1f-b9a7-e57a3d601d0a",
            price: 65551.2,
            price_sources: [],
            processed_ms: 1711286726774,
          },
        ],
        position_type: "FLAT",
        position_uuid: "447fea18-a6ca-4971-b4ad-0e026272608d",
        return_at_close: 1.0052702364969774,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 65586.35,
        close_ms: 1711294044820,
        current_return: 0.9995929030964522,
        initial_entry_price: 65586.35,
        is_closed_position: true,
        miner_hotkey: "5FLPynmkUXdJKsNPRahZhVSqJ3BoCUXnWJUsTtncyj7B5aX9",
        net_leverage: 0.0,
        open_ms: 1711292245949,
        orders: [
          {
            leverage: 1.25,
            order_type: "LONG",
            order_uuid: "7ef77d45-659f-4984-8fe7-ae3900794c96",
            price: 65586.35,
            price_sources: [],
            processed_ms: 1711292245948,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "91666e0e-c800-47ac-b213-8f70e714e682",
            price: 65564.99,
            price_sources: [],
            processed_ms: 1711294044820,
          },
        ],
        position_type: "FLAT",
        position_uuid: "c67e7db3-5033-4287-8d3c-92314bf84093",
        return_at_close: 0.9970939208387112,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 65654.45,
        close_ms: 1711297716063,
        current_return: 1.0016449760831139,
        initial_entry_price: 65654.45,
        is_closed_position: true,
        miner_hotkey: "5FLPynmkUXdJKsNPRahZhVSqJ3BoCUXnWJUsTtncyj7B5aX9",
        net_leverage: 0.0,
        open_ms: 1711295878782,
        orders: [
          {
            leverage: -1.25,
            order_type: "SHORT",
            order_uuid: "e2ccb2af-2d1e-4104-9885-8306cb4a1d5c",
            price: 65654.45,
            price_sources: [],
            processed_ms: 1711295878781,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "32f16484-1593-4d1e-96c1-b3b36ddd76db",
            price: 65568.05,
            price_sources: [],
            processed_ms: 1711297716063,
          },
        ],
        position_type: "FLAT",
        position_uuid: "1cf1dbff-5cda-4047-a581-f7cf51edbbde",
        return_at_close: 0.9991408636429061,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 65035.280000000006,
        close_ms: 1711303403289,
        current_return: 1.0070011999640809,
        initial_entry_price: 65035.28,
        is_closed_position: true,
        miner_hotkey: "5FLPynmkUXdJKsNPRahZhVSqJ3BoCUXnWJUsTtncyj7B5aX9",
        net_leverage: 0.0,
        open_ms: 1711301390140,
        orders: [
          {
            leverage: 1.25,
            order_type: "LONG",
            order_uuid: "0734f95d-9b03-43fa-8d83-02bb6f6d77bf",
            price: 65035.28,
            price_sources: [],
            processed_ms: 1711301390139,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "8f76b52b-e0ba-4dd3-8cb0-5a9f1728c616",
            price: 65399.54,
            price_sources: [],
            processed_ms: 1711303403289,
          },
        ],
        position_type: "FLAT",
        position_uuid: "0a73e0d5-5144-43eb-895a-d02c162c3c13",
        return_at_close: 1.0044836969641708,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 62100.37000000024,
        close_ms: 1711311270445,
        current_return: 0.9975602653312925,
        initial_entry_price: 65607.32,
        is_closed_position: true,
        miner_hotkey: "5FLPynmkUXdJKsNPRahZhVSqJ3BoCUXnWJUsTtncyj7B5aX9",
        net_leverage: 0.0,
        open_ms: 1711305679129,
        orders: [
          {
            leverage: -1.25,
            order_type: "SHORT",
            order_uuid: "4780e097-3a0f-432b-a97b-a819b1197526",
            price: 65607.32,
            price_sources: [],
            processed_ms: 1711305679128,
          },
          {
            leverage: 0.625,
            order_type: "LONG",
            order_uuid: "857eff43-ee21-4e8b-aefe-b356d03a0b91",
            price: 65668.75,
            price_sources: [],
            processed_ms: 1711306611054,
          },
          {
            leverage: 0.3125,
            order_type: "LONG",
            order_uuid: "c9190f97-a4ef-4431-8882-a455ef897af4",
            price: 65786.9,
            price_sources: [],
            processed_ms: 1711307544749,
          },
          {
            leverage: 0.15625,
            order_type: "LONG",
            order_uuid: "b22985e2-c8e5-4d10-b600-bf8d25d728df",
            price: 65724.5,
            price_sources: [],
            processed_ms: 1711308469745,
          },
          {
            leverage: 0.078125,
            order_type: "LONG",
            order_uuid: "da009713-f1c5-450c-924f-2b8bc4e0e98a",
            price: 65817.97,
            price_sources: [],
            processed_ms: 1711309408581,
          },
          {
            leverage: 0.0390625,
            order_type: "LONG",
            order_uuid: "86f2b8aa-616c-4f04-b3c5-90c76aeb7377",
            price: 65804.73,
            price_sources: [],
            processed_ms: 1711310342352,
          },
          {
            leverage: 0.125,
            order_type: "LONG",
            order_uuid: "2cf8cf66-4ff4-49ad-a907-890b0323dde3",
            price: 66198.02,
            price_sources: [],
            processed_ms: 1711311270445,
          },
        ],
        position_type: "FLAT",
        position_uuid: "4e65a448-f025-47f2-a940-24c930c7d00d",
        return_at_close: 0.9950663646679643,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 66409.02285714286,
        close_ms: 1711319654820,
        current_return: 0.9953629129630881,
        initial_entry_price: 66463.87,
        is_closed_position: true,
        miner_hotkey: "5FLPynmkUXdJKsNPRahZhVSqJ3BoCUXnWJUsTtncyj7B5aX9",
        net_leverage: 0.0,
        open_ms: 1711315929834,
        orders: [
          {
            leverage: -1.25,
            order_type: "SHORT",
            order_uuid: "af81d044-610a-4edc-a570-97808fa32769",
            price: 66463.87,
            price_sources: [],
            processed_ms: 1711315929833,
          },
          {
            leverage: 0.125,
            order_type: "LONG",
            order_uuid: "f08fc99e-3c57-4169-a1cf-8d51f4f5ca46",
            price: 66225.63,
            price_sources: [],
            processed_ms: 1711316863054,
          },
          {
            leverage: 0.125,
            order_type: "LONG",
            order_uuid: "88dc2fd7-18f7-4d4d-8e84-392f3a73d0c5",
            price: 66768.73,
            price_sources: [],
            processed_ms: 1711317793790,
          },
          {
            leverage: 0.125,
            order_type: "LONG",
            order_uuid: "80d4f98c-5a03-4ef5-b18a-bb72556ce26e",
            price: 66781.18,
            price_sources: [],
            processed_ms: 1711318727813,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "d75bb4a8-39e1-4019-ab9c-5d75890ed24c",
            price: 66761.25,
            price_sources: [],
            processed_ms: 1711319654820,
          },
        ],
        position_type: "FLAT",
        position_uuid: "0991b878-4304-4401-abf7-835a53dce7fa",
        return_at_close: 0.9928745056806805,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 67567.32,
        close_ms: 1711323378573,
        current_return: 1.0054647350227894,
        initial_entry_price: 67567.32,
        is_closed_position: true,
        miner_hotkey: "5FLPynmkUXdJKsNPRahZhVSqJ3BoCUXnWJUsTtncyj7B5aX9",
        net_leverage: 0.0,
        open_ms: 1711322451318,
        orders: [
          {
            leverage: -1.25,
            order_type: "SHORT",
            order_uuid: "c28f3291-674c-46ad-925e-044ea012e017",
            price: 67567.32,
            price_sources: [],
            processed_ms: 1711322451316,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "6954996a-0651-4a16-8dc0-f229f5821300",
            price: 67271.93,
            price_sources: [],
            processed_ms: 1711323378573,
          },
        ],
        position_type: "FLAT",
        position_uuid: "727a6105-3411-43e9-b2a5-b0a170afe8b8",
        return_at_close: 1.0029510731852325,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 77077.67888888707,
        close_ms: 1711340828326,
        current_return: 1.0052598301168394,
        initial_entry_price: 66875.89,
        is_closed_position: true,
        miner_hotkey: "5FLPynmkUXdJKsNPRahZhVSqJ3BoCUXnWJUsTtncyj7B5aX9",
        net_leverage: 0.0,
        open_ms: 1711326172869,
        orders: [
          {
            leverage: -1.25,
            order_type: "SHORT",
            order_uuid: "8197dbdd-56db-4a22-bc3b-56172275c79a",
            price: 66875.89,
            price_sources: [],
            processed_ms: 1711326172867,
          },
          {
            leverage: -1.25,
            order_type: "SHORT",
            order_uuid: "b2989c94-7bd6-45e3-b022-8bef9edd5aba",
            price: 66664.26,
            price_sources: [],
            processed_ms: 1711327090012,
          },
          {
            leverage: 0.125,
            order_type: "LONG",
            order_uuid: "e4701114-3be6-4eb4-80a1-5ec597191ce1",
            price: 66567.78,
            price_sources: [],
            processed_ms: 1711328026833,
          },
          {
            leverage: 0.125,
            order_type: "LONG",
            order_uuid: "27b3983d-7d75-4567-81b6-6104db0d3ad7",
            price: 66713.1,
            price_sources: [],
            processed_ms: 1711328955220,
          },
          {
            leverage: 1.125,
            order_type: "LONG",
            order_uuid: "10d8ca62-c6b9-422f-aeaa-4388b3c2980b",
            price: 66669.52,
            price_sources: [],
            processed_ms: 1711329818853,
          },
          {
            leverage: 0.5625,
            order_type: "LONG",
            order_uuid: "ee580489-b734-41ca-b42f-3f22549320df",
            price: 66478.85,
            price_sources: [],
            processed_ms: 1711331655168,
          },
          {
            leverage: 0.28125,
            order_type: "LONG",
            order_uuid: "a1f0d0ae-7126-4617-8456-a81c1a554d98",
            price: 66565.86,
            price_sources: [],
            processed_ms: 1711333491242,
          },
          {
            leverage: 0.140625,
            order_type: "LONG",
            order_uuid: "16a43f65-eb72-477c-9e3b-01e5b8e5741d",
            price: 66713.37,
            price_sources: [],
            processed_ms: 1711335319765,
          },
          {
            leverage: 0.0703125,
            order_type: "LONG",
            order_uuid: "c80ec51b-7fa9-4bb8-8b0f-432d6fe31c04",
            price: 66801.13,
            price_sources: [],
            processed_ms: 1711337155065,
          },
          {
            leverage: 0.03515625,
            order_type: "LONG",
            order_uuid: "a3444e67-14da-4675-8cb7-3329bb672001",
            price: 67060.11,
            price_sources: [],
            processed_ms: 1711338994279,
          },
          {
            leverage: 0.125,
            order_type: "LONG",
            order_uuid: "0aabba28-b904-41a2-972e-d5a8ae809c70",
            price: 67072.18,
            price_sources: [],
            processed_ms: 1711340828326,
          },
        ],
        position_type: "FLAT",
        position_uuid: "e639a977-c529-42d0-acf5-ef1cf35e7cdc",
        return_at_close: 1.0002335309662551,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 67121.84,
        close_ms: 1711362803874,
        current_return: 1.0005728388852273,
        initial_entry_price: 67121.84,
        is_closed_position: true,
        miner_hotkey: "5FLPynmkUXdJKsNPRahZhVSqJ3BoCUXnWJUsTtncyj7B5aX9",
        net_leverage: 0.0,
        open_ms: 1711361034025,
        orders: [
          {
            leverage: 1.25,
            order_type: "LONG",
            order_uuid: "38db76f8-6f54-41f8-9e2c-6f80d8cf6df7",
            price: 67121.84,
            price_sources: [],
            processed_ms: 1711361034023,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "52383fa0-ce59-4661-b8bf-e10fdf9469b9",
            price: 67152.6,
            price_sources: [],
            processed_ms: 1711362803874,
          },
        ],
        position_type: "FLAT",
        position_uuid: "58c62a75-fe05-4614-ad70-ee4f63b51dca",
        return_at_close: 0.9980714067880143,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: -103921.3933333331,
        close_ms: 1711381943371,
        current_return: 1.048749092094577,
        initial_entry_price: 66884.72,
        is_closed_position: true,
        miner_hotkey: "5FLPynmkUXdJKsNPRahZhVSqJ3BoCUXnWJUsTtncyj7B5aX9",
        net_leverage: 0.0,
        open_ms: 1711366458196,
        orders: [
          {
            leverage: 1.25,
            order_type: "LONG",
            order_uuid: "6c8fb4da-9b80-4227-8390-782365f57557",
            price: 66884.72,
            price_sources: [],
            processed_ms: 1711366458194,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "04a90324-a1a7-4735-ae07-5bbbba2957ff",
            price: 66812.56,
            price_sources: [],
            processed_ms: 1711367681835,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "860d254e-ae1a-4338-9889-6e18994406c5",
            price: 67150.59,
            price_sources: [],
            processed_ms: 1711369514936,
          },
          {
            leverage: -0.875,
            order_type: "SHORT",
            order_uuid: "4ca03f71-7453-4595-a76a-c61ae250c6db",
            price: 67017.66,
            price_sources: [],
            processed_ms: 1711371346103,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "0fd1a6cd-c268-4660-9372-fcdd30c0e2dc",
            price: 66950.51,
            price_sources: [],
            processed_ms: 1711373208477,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "8bad0ac7-5fbf-4c6b-b7ee-fb2c3b6af2f9",
            price: 67630.76,
            price_sources: [],
            processed_ms: 1711375015886,
          },
          {
            leverage: -0.6875,
            order_type: "SHORT",
            order_uuid: "458bcc5f-4c13-485d-8449-b883e323e4b4",
            price: 69368.89,
            price_sources: [],
            processed_ms: 1711376610996,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "140aa0af-3952-4790-aa9d-ee2ab49783a6",
            price: 69272.14,
            price_sources: [],
            processed_ms: 1711376918228,
          },
          {
            leverage: -0.46875,
            order_type: "SHORT",
            order_uuid: "6285d5bd-4766-4939-bf6a-954a2515ff44",
            price: 69244.36,
            price_sources: [],
            processed_ms: 1711378070423,
          },
          {
            leverage: -0.375,
            order_type: "SHORT",
            order_uuid: "d89616e7-eafa-4fd6-a9e4-6e040a5f8474",
            price: 69295.36,
            price_sources: [],
            processed_ms: 1711378332689,
          },
          {
            leverage: -0.075,
            order_type: "SHORT",
            order_uuid: "166d42ab-55a4-43e6-8568-59d43e4cf441",
            price: 69772.64,
            price_sources: [],
            processed_ms: 1711381470111,
          },
          {
            leverage: -5.01875,
            order_type: "SHORT",
            order_uuid: "166dac7f-76a9-46e9-b272-caeebed7ea8b",
            price: 69975.64,
            price_sources: [],
            processed_ms: 1711381943371,
          },
        ],
        position_type: "FLAT",
        position_uuid: "5578babd-d282-4d83-bf71-d88286707f84",
        return_at_close: 1.045078470272246,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 69723.96,
        close_ms: 1711384859108,
        current_return: 0.9676388145481131,
        initial_entry_price: 69723.96,
        is_closed_position: true,
        miner_hotkey: "5FLPynmkUXdJKsNPRahZhVSqJ3BoCUXnWJUsTtncyj7B5aX9",
        net_leverage: 0.0,
        open_ms: 1711383754156,
        orders: [
          {
            leverage: -5.0,
            order_type: "SHORT",
            order_uuid: "15e4b4b9-86f6-451d-9f67-738d4c6d3743",
            price: 69723.96,
            price_sources: [],
            processed_ms: 1711383754154,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "332c44a1-bac3-4702-afcd-7590bf54710c",
            price: 70175.23,
            price_sources: [],
            processed_ms: 1711384859108,
          },
        ],
        position_type: "FLAT",
        position_uuid: "153c44d9-0d12-4f05-8883-98e6500ddf07",
        return_at_close: 0.957962426402632,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 70611.24272727272,
        close_ms: 1711396633985,
        current_return: 1.073533597996787,
        initial_entry_price: 70398.84,
        is_closed_position: true,
        miner_hotkey: "5FLPynmkUXdJKsNPRahZhVSqJ3BoCUXnWJUsTtncyj7B5aX9",
        net_leverage: 0.0,
        open_ms: 1711389207360,
        orders: [
          {
            leverage: 5.0,
            order_type: "LONG",
            order_uuid: "8774b1e0-d9cd-40da-a7aa-2ca4f539fbdf",
            price: 70398.84,
            price_sources: [],
            processed_ms: 1711389207357,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "6e80f0f8-9611-49cb-922b-39f15043f7e0",
            price: 70709.47,
            price_sources: [],
            processed_ms: 1711391019211,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "aba78a2d-7063-4fd9-b882-6ae854a11736",
            price: 70847.61,
            price_sources: [],
            processed_ms: 1711392832253,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "406e404a-d5ab-4994-b7ef-a644f7763a8a",
            price: 70645.91,
            price_sources: [],
            processed_ms: 1711394689187,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "61d40bf6-5739-467e-8160-7b9450ef6c31",
            price: 70949.99,
            price_sources: [],
            processed_ms: 1711396474883,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "cf6fbc58-0ea3-45ee-ae96-263a81a08285",
            price: 71081.85,
            price_sources: [],
            processed_ms: 1711396633985,
          },
        ],
        position_type: "FLAT",
        position_uuid: "1733554f-8e11-4eb5-a84d-7be0d5d8ec1c",
        return_at_close: 1.0499158588408577,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 70969.25615384615,
        close_ms: 1711401350974,
        current_return: 0.9496612699376872,
        initial_entry_price: 70988.68,
        is_closed_position: true,
        miner_hotkey: "5FLPynmkUXdJKsNPRahZhVSqJ3BoCUXnWJUsTtncyj7B5aX9",
        net_leverage: 0.0,
        open_ms: 1711398289073,
        orders: [
          {
            leverage: 5.0,
            order_type: "LONG",
            order_uuid: "b5611668-76f1-4e89-bb9c-a8166de6d2e6",
            price: 70988.68,
            price_sources: [],
            processed_ms: 1711398289071,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "e4615af7-410a-453c-af7f-96d010f8a789",
            price: 70904.51,
            price_sources: [],
            processed_ms: 1711400108029,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "a64af4c6-0d64-41a4-ae07-4641cdc1fd73",
            price: 70419.49,
            price_sources: [],
            processed_ms: 1711401350974,
          },
        ],
        position_type: "FLAT",
        position_uuid: "64fb6647-0542-486c-862a-0855d6f97f52",
        return_at_close: 0.9373156734284972,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 70200.84857142856,
        close_ms: 1711410381918,
        current_return: 0.9626510530461302,
        initial_entry_price: 69967.97,
        is_closed_position: true,
        miner_hotkey: "5FLPynmkUXdJKsNPRahZhVSqJ3BoCUXnWJUsTtncyj7B5aX9",
        net_leverage: 0.0,
        open_ms: 1711401917192,
        orders: [
          {
            leverage: 5.0,
            order_type: "LONG",
            order_uuid: "97269386-e0b1-45f0-a448-fc4f35a79899",
            price: 69967.97,
            price_sources: [],
            processed_ms: 1711401917190,
          },
          {
            leverage: -2.5,
            order_type: "SHORT",
            order_uuid: "0fc1e34f-4738-4be4-beae-64e93828c3c5",
            price: 69959.83,
            price_sources: [],
            processed_ms: 1711403731440,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "8d159d7a-acd2-45c5-99d3-72e5c8bd5ef7",
            price: 70338.03,
            price_sources: [],
            processed_ms: 1711405621442,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "b450d1a6-eb58-45dd-9a2c-2acb062049fd",
            price: 70320.83,
            price_sources: [],
            processed_ms: 1711407389617,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "fa9a7118-98c3-4e65-aaa4-9ff4e8945cd9",
            price: 70318.25,
            price_sources: [],
            processed_ms: 1711409184238,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "1200bac1-c1c4-4192-bde7-06c60cd30d5c",
            price: 69827.53,
            price_sources: [],
            processed_ms: 1711410381918,
          },
        ],
        position_type: "FLAT",
        position_uuid: "1f3d65c1-764e-4f2a-b5f3-08fc9f06438d",
        return_at_close: 0.9491739383034844,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 69757.20090909088,
        close_ms: 1711421752418,
        current_return: 1.0610336413532067,
        initial_entry_price: 69924.06,
        is_closed_position: true,
        miner_hotkey: "5FLPynmkUXdJKsNPRahZhVSqJ3BoCUXnWJUsTtncyj7B5aX9",
        net_leverage: 0.0,
        open_ms: 1711410998314,
        orders: [
          {
            leverage: 5.0,
            order_type: "LONG",
            order_uuid: "14255136-c9b1-4946-9138-0dd9ffd35c2d",
            price: 69924.06,
            price_sources: [],
            processed_ms: 1711410998311,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "8813819c-731b-4499-8a8e-c046a8733258",
            price: 69941.25,
            price_sources: [],
            processed_ms: 1711412815091,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "4a7bfda9-9342-4734-9580-25353b1f234c",
            price: 69929.34,
            price_sources: [],
            processed_ms: 1711414632215,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "2216c40c-72e6-4e22-a061-a8117ce94a29",
            price: 70181.31,
            price_sources: [],
            processed_ms: 1711416449515,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "2c4928df-e95b-4e85-9bc5-b5a488824b0f",
            price: 70249.49,
            price_sources: [],
            processed_ms: 1711418262567,
          },
          {
            leverage: -5.5,
            order_type: "SHORT",
            order_uuid: "1aec9496-30d4-40e8-b1e6-a863f64ebb6e",
            price: 70255.96,
            price_sources: [],
            processed_ms: 1711420084257,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "2c9bf866-eabd-4808-98e3-63224f2ffdec",
            price: 70533.15,
            price_sources: [],
            processed_ms: 1711421752418,
          },
        ],
        position_type: "FLAT",
        position_uuid: "6d90f9da-7c3f-4ea1-830a-533e5ef87788",
        return_at_close: 1.037690901243436,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 70891.39391304352,
        close_ms: 1711438268329,
        current_return: 1.0031448059573962,
        initial_entry_price: 70479.11,
        is_closed_position: true,
        miner_hotkey: "5FLPynmkUXdJKsNPRahZhVSqJ3BoCUXnWJUsTtncyj7B5aX9",
        net_leverage: 0.0,
        open_ms: 1711421898883,
        orders: [
          {
            leverage: 5.0,
            order_type: "LONG",
            order_uuid: "b95cc309-d5a5-4f0b-9f43-eb61063e6429",
            price: 70479.11,
            price_sources: [],
            processed_ms: 1711421898880,
          },
          {
            leverage: -2.5,
            order_type: "SHORT",
            order_uuid: "da960975-264b-4ef3-a9ce-92310d642991",
            price: 70481.32,
            price_sources: [],
            processed_ms: 1711423729971,
          },
          {
            leverage: -1.25,
            order_type: "SHORT",
            order_uuid: "7e17926c-bd4c-4925-81d0-cabbb4fc0cbd",
            price: 70447.0,
            price_sources: [],
            processed_ms: 1711425568841,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "b0dd74dd-a6c8-4124-9b67-e4e81a4d41e2",
            price: 70623.48,
            price_sources: [],
            processed_ms: 1711427362865,
          },
          {
            leverage: -1.375,
            order_type: "SHORT",
            order_uuid: "cfb38a73-0865-418e-aa67-dd9c68d800e9",
            price: 70612.75,
            price_sources: [],
            processed_ms: 1711429179059,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "5b4099c8-2f00-4b57-b0ae-1e62cf643c9c",
            price: 70384.99,
            price_sources: [],
            processed_ms: 1711430997106,
          },
          {
            leverage: -1.4375,
            order_type: "SHORT",
            order_uuid: "6483641a-30e5-44c9-916f-da0f320549bf",
            price: 70319.81,
            price_sources: [],
            processed_ms: 1711432815611,
          },
          {
            leverage: -0.71875,
            order_type: "SHORT",
            order_uuid: "a028bbba-efc9-4860-92b1-f1e6da6eb781",
            price: 70414.05,
            price_sources: [],
            processed_ms: 1711434632383,
          },
          {
            leverage: -0.359375,
            order_type: "SHORT",
            order_uuid: "eac06018-ecfc-4b6b-a452-1bead1f2322b",
            price: 70628.88,
            price_sources: [],
            processed_ms: 1711436446571,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "01f5331f-5b38-4957-a528-eef826be7621",
            price: 71508.14,
            price_sources: [],
            processed_ms: 1711438268329,
          },
        ],
        position_type: "FLAT",
        position_uuid: "183f849f-7a2c-4a73-b2a9-2ca1d20d9d09",
        return_at_close: 0.9931133578978222,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 68238.77000000014,
        close_ms: 1711447340985,
        current_return: 0.9793327248355052,
        initial_entry_price: 70512.44,
        is_closed_position: true,
        miner_hotkey: "5FLPynmkUXdJKsNPRahZhVSqJ3BoCUXnWJUsTtncyj7B5aX9",
        net_leverage: 0.0,
        open_ms: 1711440080308,
        orders: [
          {
            leverage: -5.0,
            order_type: "SHORT",
            order_uuid: "9e80c13b-99c1-43d2-9ec0-b86729fd8b23",
            price: 70512.44,
            price_sources: [],
            processed_ms: 1711440080305,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "83438cc5-b9b2-4266-a5c2-56e25e3191e7",
            price: 70610.68,
            price_sources: [],
            processed_ms: 1711441890478,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "3c3a1b60-01eb-4ee2-ae93-1e83f6091b07",
            price: 70727.53,
            price_sources: [],
            processed_ms: 1711443712987,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "8e7bffdd-fa2b-45cd-a69e-24551307f796",
            price: 70957.0,
            price_sources: [],
            processed_ms: 1711445528512,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "156dd6ee-1d38-4610-8625-2d7ed625247d",
            price: 71153.37,
            price_sources: [],
            processed_ms: 1711447340985,
          },
        ],
        position_type: "FLAT",
        position_uuid: "e1b69acd-4b06-4d27-ba6a-7056d33cb80d",
        return_at_close: 0.9695393975871501,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 74913.64999999998,
        close_ms: 1711458245079,
        current_return: 1.0138045251812073,
        initial_entry_price: 70964.23,
        is_closed_position: true,
        miner_hotkey: "5FLPynmkUXdJKsNPRahZhVSqJ3BoCUXnWJUsTtncyj7B5aX9",
        net_leverage: 0.0,
        open_ms: 1711449154113,
        orders: [
          {
            leverage: -5.0,
            order_type: "SHORT",
            order_uuid: "91dc6a01-b7fd-47ee-8e59-5444efd99bef",
            price: 70964.23,
            price_sources: [],
            processed_ms: 1711449154110,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "c25575df-a462-4b68-9a78-7e2bedf8ba5e",
            price: 70898.63,
            price_sources: [],
            processed_ms: 1711450973904,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "8153eec9-4a9e-4dbd-a7e6-01c3a8557436",
            price: 70680.94,
            price_sources: [],
            processed_ms: 1711452793140,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "1d704c66-4a5e-400e-9441-903b31b7be65",
            price: 70659.93,
            price_sources: [],
            processed_ms: 1711454612088,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "4540cc98-408b-40e3-b331-ea8ed4719fba",
            price: 70933.95,
            price_sources: [],
            processed_ms: 1711456427060,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "404adac7-2580-4e2a-95c5-8779e0c6e831",
            price: 70995.14,
            price_sources: [],
            processed_ms: 1711458245079,
          },
        ],
        position_type: "FLAT",
        position_uuid: "597e8c4d-5d05-4902-bc4c-68db84ee80f3",
        return_at_close: 1.0036664799293953,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 73150.66500000005,
        close_ms: 1711463825989,
        current_return: 1.047693305069166,
        initial_entry_price: 70600.58,
        is_closed_position: true,
        miner_hotkey: "5FLPynmkUXdJKsNPRahZhVSqJ3BoCUXnWJUsTtncyj7B5aX9",
        net_leverage: 0.0,
        open_ms: 1711460063624,
        orders: [
          {
            leverage: -5.0,
            order_type: "SHORT",
            order_uuid: "f73c268b-bf23-45a3-8d0f-302dc1cd15d3",
            price: 70600.58,
            price_sources: [],
            processed_ms: 1711460063621,
          },
          {
            leverage: 2.5,
            order_type: "LONG",
            order_uuid: "cb70a3a7-e7df-4b15-a1ae-074bcb7653fb",
            price: 70170.04,
            price_sources: [],
            processed_ms: 1711461950448,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "1ef6a652-b08f-46d0-a28c-a33e0460e1ec",
            price: 69618.09,
            price_sources: [],
            processed_ms: 1711463689277,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "f44a7a7c-54c3-43fc-897e-6ec164d1ed18",
            price: 69783.49,
            price_sources: [],
            processed_ms: 1711463825989,
          },
        ],
        position_type: "FLAT",
        position_uuid: "168131e1-d461-487d-86b4-1df102bd82b1",
        return_at_close: 1.0372163720184742,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 69716.01372781064,
        close_ms: 1711488741752,
        current_return: 1.073198337136149,
        initial_entry_price: 70110.37,
        is_closed_position: true,
        miner_hotkey: "5FLPynmkUXdJKsNPRahZhVSqJ3BoCUXnWJUsTtncyj7B5aX9",
        net_leverage: 0.0,
        open_ms: 1711465509720,
        orders: [
          {
            leverage: 5.0,
            order_type: "LONG",
            order_uuid: "ff5d68df-eaa0-410c-989a-16c21871e230",
            price: 70110.37,
            price_sources: [],
            processed_ms: 1711465509718,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "824d753b-731c-48df-98d3-c4f054acc49d",
            price: 70029.68,
            price_sources: [],
            processed_ms: 1711467323320,
          },
          {
            leverage: -3.25,
            order_type: "SHORT",
            order_uuid: "0546a237-d0fa-4606-b2fe-9cae383cd7f7",
            price: 70414.66,
            price_sources: [],
            processed_ms: 1711469141244,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "d1d13106-9f39-4fbb-9ef0-bf3f18728924",
            price: 69750.17,
            price_sources: [],
            processed_ms: 1711470955216,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "ff5dfc46-0a48-40b6-9e64-8257a2662099",
            price: 69905.15,
            price_sources: [],
            processed_ms: 1711472773007,
          },
          {
            leverage: -3.125,
            order_type: "SHORT",
            order_uuid: "264492bf-c680-47ae-bc73-6a5280889c77",
            price: 70072.9,
            price_sources: [],
            processed_ms: 1711474594064,
          },
          {
            leverage: -1.5625,
            order_type: "SHORT",
            order_uuid: "932f57c2-7c63-4f00-a05c-45646b47c7fe",
            price: 70264.76,
            price_sources: [],
            processed_ms: 1711476401156,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "a3b6b702-3758-4c82-b7a1-6d09a2768b70",
            price: 70410.29,
            price_sources: [],
            processed_ms: 1711478241699,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "afffc28f-4812-4951-ba32-dc45e93be4e8",
            price: 69935.45,
            price_sources: [],
            processed_ms: 1711480036802,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "73cc1387-5b15-41d3-98fc-96f060886383",
            price: 69694.25,
            price_sources: [],
            processed_ms: 1711481855034,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "b545f346-50c1-44f0-8d72-48489b24931b",
            price: 69638.29,
            price_sources: [],
            processed_ms: 1711483670250,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "0a3765ea-662e-43a2-badf-54e8b35a9d30",
            price: 69799.87,
            price_sources: [],
            processed_ms: 1711485494876,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "b652f7f2-2e25-49d1-8094-95aa432033cc",
            price: 69795.31,
            price_sources: [],
            processed_ms: 1711487309862,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "909d06e9-5f09-4001-9079-2d9e1c14d979",
            price: 70201.88,
            price_sources: [],
            processed_ms: 1711488741752,
          },
        ],
        position_type: "FLAT",
        position_uuid: "0130ddef-a7b4-470f-8520-1bf282d5ba30",
        return_at_close: 1.050527022264148,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 70132.53153846154,
        close_ms: 1711491838386,
        current_return: 0.9666531713573308,
        initial_entry_price: 70197.23,
        is_closed_position: true,
        miner_hotkey: "5FLPynmkUXdJKsNPRahZhVSqJ3BoCUXnWJUsTtncyj7B5aX9",
        net_leverage: 0.0,
        open_ms: 1711489125763,
        orders: [
          {
            leverage: 5.0,
            order_type: "LONG",
            order_uuid: "6fe6e98e-4fae-4549-994e-60b163b6ea68",
            price: 70197.23,
            price_sources: [],
            processed_ms: 1711489125760,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "ac25ac44-0b1f-4272-8b95-3c6ff6c24be7",
            price: 69916.87,
            price_sources: [],
            processed_ms: 1711490938131,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "d1f069f9-a5f0-4f8f-a344-da6ce9948b0e",
            price: 69772.4,
            price_sources: [],
            processed_ms: 1711491838386,
          },
        ],
        position_type: "FLAT",
        position_uuid: "2372974e-023a-49f2-9611-15a4277ef239",
        return_at_close: 0.9540866801296854,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 70057.95454545454,
        close_ms: 1711500111196,
        current_return: 1.052095368523939,
        initial_entry_price: 69959.77,
        is_closed_position: true,
        miner_hotkey: "5FLPynmkUXdJKsNPRahZhVSqJ3BoCUXnWJUsTtncyj7B5aX9",
        net_leverage: 0.0,
        open_ms: 1711492765954,
        orders: [
          {
            leverage: 5.0,
            order_type: "LONG",
            order_uuid: "74133ab9-3bd1-4809-aafc-c612570c0616",
            price: 69959.77,
            price_sources: [],
            processed_ms: 1711492765951,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "6f7db65a-164f-4b67-9248-a3ab5c77c768",
            price: 70250.07,
            price_sources: [],
            processed_ms: 1711494571119,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "f8f2f528-1957-4b7e-8be9-5441fc361690",
            price: 70092.6,
            price_sources: [],
            processed_ms: 1711496422013,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "e9f18201-73cc-4cbc-b110-04864f54ec9e",
            price: 69930.45,
            price_sources: [],
            processed_ms: 1711498211925,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "53df1602-1481-438c-9cef-513b4f91f51e",
            price: 70285.98,
            price_sources: [],
            processed_ms: 1711500022234,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "aad08130-de59-4b7b-85c0-1f575221de1c",
            price: 70389.28,
            price_sources: [],
            processed_ms: 1711500111196,
          },
        ],
        position_type: "FLAT",
        position_uuid: "e57ee147-2b1e-40e4-8b15-53e2ba7bebf0",
        return_at_close: 1.0289492704164123,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 70449.79857142856,
        close_ms: 1711513039062,
        current_return: 0.9637061912484168,
        initial_entry_price: 70532.14,
        is_closed_position: true,
        miner_hotkey: "5FLPynmkUXdJKsNPRahZhVSqJ3BoCUXnWJUsTtncyj7B5aX9",
        net_leverage: 0.0,
        open_ms: 1711501846397,
        orders: [
          {
            leverage: 5.0,
            order_type: "LONG",
            order_uuid: "7b4b01e3-b970-46fc-8f15-ce6cc8807d36",
            price: 70532.14,
            price_sources: [],
            processed_ms: 1711501846394,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "ad779f20-ec2b-4bc9-92ce-2b379442a21e",
            price: 70505.78,
            price_sources: [],
            processed_ms: 1711503655581,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "c1de84d8-2b3d-4ccc-bbaa-bf0a595c9968",
            price: 70272.3,
            price_sources: [],
            processed_ms: 1711505475308,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "ad0b5615-6173-43f3-8649-6d8af9aa05ca",
            price: 70330.89,
            price_sources: [],
            processed_ms: 1711507286283,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "7ccac91f-8558-477e-9fc9-e80c7b6ba7d5",
            price: 70565.09,
            price_sources: [],
            processed_ms: 1711509105806,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "c834d709-bd82-48b9-af80-f61b4f33a062",
            price: 70399.05,
            price_sources: [],
            processed_ms: 1711510924301,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "aa449b52-183e-4a84-8e92-2c44634baf08",
            price: 70351.21,
            price_sources: [],
            processed_ms: 1711512735156,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "e9ab6cf2-ddff-474e-90ef-98da60040d2c",
            price: 70266.95,
            price_sources: [],
            processed_ms: 1711513039062,
          },
        ],
        position_type: "FLAT",
        position_uuid: "c7ba6a6a-a28f-48e1-97fe-891636095d5d",
        return_at_close: 0.9367224178934611,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 70350.4059090909,
        close_ms: 1711524256573,
        current_return: 0.9306674765030616,
        initial_entry_price: 70318.73,
        is_closed_position: true,
        miner_hotkey: "5FLPynmkUXdJKsNPRahZhVSqJ3BoCUXnWJUsTtncyj7B5aX9",
        net_leverage: 0.0,
        open_ms: 1711514550914,
        orders: [
          {
            leverage: 5.0,
            order_type: "LONG",
            order_uuid: "6a5fe7a9-5437-48ad-9bc1-70a9da36aa21",
            price: 70318.73,
            price_sources: [],
            processed_ms: 1711514550911,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "19ab5bd7-a648-4251-ba63-da50f3219e02",
            price: 70335.62,
            price_sources: [],
            processed_ms: 1711516388426,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "57a7a02d-d404-42d1-9bd9-d02ae47a0113",
            price: 70481.43,
            price_sources: [],
            processed_ms: 1711518217929,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "f19ca988-6989-4560-a36f-4d0c0222b97d",
            price: 70468.0,
            price_sources: [],
            processed_ms: 1711520023256,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "e09404d0-6ffc-47ba-a524-3e5dbfa1060a",
            price: 70222.16,
            price_sources: [],
            processed_ms: 1711521832075,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "11d3b596-9790-4a99-886a-4a495d7db310",
            price: 69907.19,
            price_sources: [],
            processed_ms: 1711524256573,
          },
        ],
        position_type: "FLAT",
        position_uuid: "93ee6ea4-4d8e-4ad7-bf87-88ab36a7f8b2",
        return_at_close: 0.9101927920199943,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
    ],
    thirty_day_returns: 1.0,
  },
  "5FNHGRhAJskJ8uSxWD4KG3fiDXUjVPWytAfyLVLEoKDdn6vE": {
    all_time_returns: 1.0,
    n_positions: 0,
    percentage_profitable: 0.0,
    positions: [
      {
        average_entry_price: 64659.99,
        close_ms: 1711207371521,
        current_return: 0.9994047787511257,
        initial_entry_price: 64659.99,
        is_closed_position: true,
        miner_hotkey: "5FNHGRhAJskJ8uSxWD4KG3fiDXUjVPWytAfyLVLEoKDdn6vE",
        net_leverage: 0.0,
        open_ms: 1711203797547,
        orders: [
          {
            leverage: -0.3,
            order_type: "SHORT",
            order_uuid: "79783375-6c5a-406d-88be-b1ff7de5dc9c",
            price: 64659.99,
            price_sources: [],
            processed_ms: 1711203797546,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "52a3a43c-e21b-4b16-a7e6-cdc5fae2d340",
            price: 64788.28,
            price_sources: [],
            processed_ms: 1711207371521,
          },
        ],
        position_type: "FLAT",
        position_uuid: "0018d0bb-a72b-45dd-91e1-19116e3c294f",
        return_at_close: 0.998805135883875,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 65213.68,
        close_ms: 1711214701638,
        current_return: 0.9985508715349295,
        initial_entry_price: 65213.68,
        is_closed_position: true,
        miner_hotkey: "5FNHGRhAJskJ8uSxWD4KG3fiDXUjVPWytAfyLVLEoKDdn6vE",
        net_leverage: 0.0,
        open_ms: 1711211126654,
        orders: [
          {
            leverage: -0.3,
            order_type: "SHORT",
            order_uuid: "86d6722d-3f47-4530-870a-2c51c2ec5a32",
            price: 65213.68,
            price_sources: [],
            processed_ms: 1711211126653,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "b9425799-66a2-4651-a2ae-5c5e62154830",
            price: 65528.69,
            price_sources: [],
            processed_ms: 1711214701638,
          },
        ],
        position_type: "FLAT",
        position_uuid: "384f112a-9581-4838-bd37-060e332d906a",
        return_at_close: 0.9979517410120085,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 65526.26,
        close_ms: 1711218354604,
        current_return: 1.0000182674854325,
        initial_entry_price: 65526.26,
        is_closed_position: true,
        miner_hotkey: "5FNHGRhAJskJ8uSxWD4KG3fiDXUjVPWytAfyLVLEoKDdn6vE",
        net_leverage: 0.0,
        open_ms: 1711214788575,
        orders: [
          {
            leverage: -0.3,
            order_type: "SHORT",
            order_uuid: "675c1439-f5af-4d2d-ba3b-a1706e0249e8",
            price: 65526.26,
            price_sources: [],
            processed_ms: 1711214788575,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "d8eb837a-6380-4784-9ed5-755a5ffc4b7c",
            price: 65522.27,
            price_sources: [],
            processed_ms: 1711218354604,
          },
        ],
        position_type: "FLAT",
        position_uuid: "4fc6b964-819e-48c1-a686-faa5034e6f5c",
        return_at_close: 0.9994182565249412,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 65520.12999999999,
        close_ms: 1711222017064,
        current_return: 1.0016507751129309,
        initial_entry_price: 65520.13,
        is_closed_position: true,
        miner_hotkey: "5FNHGRhAJskJ8uSxWD4KG3fiDXUjVPWytAfyLVLEoKDdn6vE",
        net_leverage: 0.0,
        open_ms: 1711218441507,
        orders: [
          {
            leverage: -0.3,
            order_type: "SHORT",
            order_uuid: "320fbd55-327e-47b5-ba2a-07c4ce3cb74e",
            price: 65520.13,
            price_sources: [],
            processed_ms: 1711218441507,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "6afd5c18-c337-4631-ab9c-ff7171310d75",
            price: 65159.6,
            price_sources: [],
            processed_ms: 1711222017064,
          },
        ],
        position_type: "FLAT",
        position_uuid: "30a2ff2a-f1fc-4ca5-b365-1c63e28a1581",
        return_at_close: 1.001049784647863,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 65154.94,
        close_ms: 1711225687154,
        current_return: 1.0011202527390863,
        initial_entry_price: 65154.94,
        is_closed_position: true,
        miner_hotkey: "5FNHGRhAJskJ8uSxWD4KG3fiDXUjVPWytAfyLVLEoKDdn6vE",
        net_leverage: 0.0,
        open_ms: 1711222103717,
        orders: [
          {
            leverage: -0.3,
            order_type: "SHORT",
            order_uuid: "d744db33-a6d4-4cba-a9a3-c0391d8445eb",
            price: 65154.94,
            price_sources: [],
            processed_ms: 1711222103716,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "bedac61e-0ab9-44df-97e1-d6c91ad5cd47",
            price: 64911.64,
            price_sources: [],
            processed_ms: 1711225687154,
          },
        ],
        position_type: "FLAT",
        position_uuid: "c3978017-f08e-48f5-8580-a6acd97c8d7d",
        return_at_close: 1.000519580587443,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 64976.28,
        close_ms: 1711229368480,
        current_return: 1.0003456338220655,
        initial_entry_price: 64976.28,
        is_closed_position: true,
        miner_hotkey: "5FNHGRhAJskJ8uSxWD4KG3fiDXUjVPWytAfyLVLEoKDdn6vE",
        net_leverage: 0.0,
        open_ms: 1711225789525,
        orders: [
          {
            leverage: -0.3,
            order_type: "SHORT",
            order_uuid: "37c28d31-d017-4cc4-a681-aeb0535c60a6",
            price: 64976.28,
            price_sources: [],
            processed_ms: 1711225789524,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "6af51ee1-48a5-4214-970d-eb1eadba7239",
            price: 64901.42,
            price_sources: [],
            processed_ms: 1711229368480,
          },
        ],
        position_type: "FLAT",
        position_uuid: "460ec94b-56d4-4905-9362-b8750c9dce47",
        return_at_close: 0.9997454264417722,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 64818.21,
        close_ms: 1711233013802,
        current_return: 0.9993965121838446,
        initial_entry_price: 64818.21,
        is_closed_position: true,
        miner_hotkey: "5FNHGRhAJskJ8uSxWD4KG3fiDXUjVPWytAfyLVLEoKDdn6vE",
        net_leverage: 0.0,
        open_ms: 1711229471523,
        orders: [
          {
            leverage: -0.3,
            order_type: "SHORT",
            order_uuid: "1d77333a-2bdf-40cf-b2a7-752923d278de",
            price: 64818.21,
            price_sources: [],
            processed_ms: 1711229471522,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "7fcb982e-889f-474e-98c8-cd1b6e243fbf",
            price: 64948.6,
            price_sources: [],
            processed_ms: 1711233013802,
          },
        ],
        position_type: "FLAT",
        position_uuid: "58978f3e-75ce-4858-a049-ab035dfb6753",
        return_at_close: 0.9987968742765343,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 65000.0,
        close_ms: 1711236669531,
        current_return: 1.002733323076923,
        initial_entry_price: 65000.0,
        is_closed_position: true,
        miner_hotkey: "5FNHGRhAJskJ8uSxWD4KG3fiDXUjVPWytAfyLVLEoKDdn6vE",
        net_leverage: 0.0,
        open_ms: 1711233147039,
        orders: [
          {
            leverage: -0.3,
            order_type: "SHORT",
            order_uuid: "912794c8-b8b4-4cfb-907e-5b6aedf84f0e",
            price: 65000.0,
            price_sources: [],
            processed_ms: 1711233147038,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "42493aa3-12d6-4c77-8a00-27a5b881689c",
            price: 64407.78,
            price_sources: [],
            processed_ms: 1711236669531,
          },
        ],
        position_type: "FLAT",
        position_uuid: "28fd12d2-7857-49cf-b2a7-dc7bbd48b86a",
        return_at_close: 1.0021316830830769,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 64398.719999999994,
        close_ms: 1711244012972,
        current_return: 1.0010910154735995,
        initial_entry_price: 64398.72,
        is_closed_position: true,
        miner_hotkey: "5FNHGRhAJskJ8uSxWD4KG3fiDXUjVPWytAfyLVLEoKDdn6vE",
        net_leverage: 0.0,
        open_ms: 1711240419575,
        orders: [
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "81550798-dc97-4776-9bbd-c62345a01246",
            price: 64398.72,
            price_sources: [],
            processed_ms: 1711240419574,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "11f4a68b-b368-4cf5-86df-9b86a8c03e47",
            price: 64632.92,
            price_sources: [],
            processed_ms: 1711244012972,
          },
        ],
        position_type: "FLAT",
        position_uuid: "e6b1e349-9c31-447f-820d-f80519b63d0b",
        return_at_close: 1.0004903608643152,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 64340.7,
        close_ms: 1711251326707,
        current_return: 0.9988651040476713,
        initial_entry_price: 64340.7,
        is_closed_position: true,
        miner_hotkey: "5FNHGRhAJskJ8uSxWD4KG3fiDXUjVPWytAfyLVLEoKDdn6vE",
        net_leverage: 0.0,
        open_ms: 1711247804370,
        orders: [
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "26070c5c-93ce-485d-8590-11f8d2931a88",
            price: 64340.7,
            price_sources: [],
            processed_ms: 1711247804369,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "fbb1d7af-6def-4197-933e-30b24e0607d7",
            price: 64097.3,
            price_sources: [],
            processed_ms: 1711251326707,
          },
        ],
        position_type: "FLAT",
        position_uuid: "7031caf6-b1fe-4002-a8c4-277740796c17",
        return_at_close: 0.9982657849852427,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 64111.850000000006,
        close_ms: 1711255021079,
        current_return: 0.9994994529092516,
        initial_entry_price: 64111.85,
        is_closed_position: true,
        miner_hotkey: "5FNHGRhAJskJ8uSxWD4KG3fiDXUjVPWytAfyLVLEoKDdn6vE",
        net_leverage: 0.0,
        open_ms: 1711251413764,
        orders: [
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "03bf355d-52d3-4a52-9c43-be69f8a585af",
            price: 64111.85,
            price_sources: [],
            processed_ms: 1711251413763,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "bc3e7cec-8943-497b-bf56-49e87ec56f29",
            price: 64004.88,
            price_sources: [],
            processed_ms: 1711255021079,
          },
        ],
        position_type: "FLAT",
        position_uuid: "0662cb32-153f-47ee-b416-99fe7d1d2d7c",
        return_at_close: 0.998899753237506,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 63960.92,
        close_ms: 1711260066604,
        current_return: 1.0016316369433085,
        initial_entry_price: 63960.92,
        is_closed_position: true,
        miner_hotkey: "5FNHGRhAJskJ8uSxWD4KG3fiDXUjVPWytAfyLVLEoKDdn6vE",
        net_leverage: 0.0,
        open_ms: 1711255043146,
        orders: [
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "2e3cff4a-bbe2-4364-a953-fbefe0c71a70",
            price: 63960.92,
            price_sources: [],
            processed_ms: 1711255043145,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "aacc787a-3fb3-4c6f-ad89-47a39771e165",
            price: 64308.79,
            price_sources: [],
            processed_ms: 1711260066604,
          },
        ],
        position_type: "FLAT",
        position_uuid: "e7e8bda2-0fd1-4d2f-9deb-f6ac0acf9495",
        return_at_close: 1.0010306579611425,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 64314.67,
        close_ms: 1711263709830,
        current_return: 0.9984766306038731,
        initial_entry_price: 64314.67,
        is_closed_position: true,
        miner_hotkey: "5FNHGRhAJskJ8uSxWD4KG3fiDXUjVPWytAfyLVLEoKDdn6vE",
        net_leverage: 0.0,
        open_ms: 1711260152564,
        orders: [
          {
            leverage: 1.25,
            order_type: "LONG",
            order_uuid: "847130c5-97ac-4688-88e8-4f2af94949b2",
            price: 64314.67,
            price_sources: [],
            processed_ms: 1711260152563,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "6f136648-ffd7-4a9b-8f39-7873eea3e06b",
            price: 64236.29,
            price_sources: [],
            processed_ms: 1711263709830,
          },
        ],
        position_type: "FLAT",
        position_uuid: "74be6291-f3e3-49b6-94d9-f2413353151e",
        return_at_close: 0.9959804390273636,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 64161.44,
        close_ms: 1711267371942,
        current_return: 1.0067597064529723,
        initial_entry_price: 64161.44,
        is_closed_position: true,
        miner_hotkey: "5FNHGRhAJskJ8uSxWD4KG3fiDXUjVPWytAfyLVLEoKDdn6vE",
        net_leverage: 0.0,
        open_ms: 1711263838400,
        orders: [
          {
            leverage: 1.25,
            order_type: "LONG",
            order_uuid: "102fb2bd-4ad6-482b-9e59-7654616d64fa",
            price: 64161.44,
            price_sources: [],
            processed_ms: 1711263838399,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "b07e563f-7850-4077-8606-115bd213444e",
            price: 64508.41,
            price_sources: [],
            processed_ms: 1711267371942,
          },
        ],
        position_type: "FLAT",
        position_uuid: "51f1d68d-bbf2-4d0e-9957-9fed1d000b98",
        return_at_close: 1.00424280718684,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 64641.95,
        close_ms: 1711271027788,
        current_return: 1.0083401722875007,
        initial_entry_price: 64641.95,
        is_closed_position: true,
        miner_hotkey: "5FNHGRhAJskJ8uSxWD4KG3fiDXUjVPWytAfyLVLEoKDdn6vE",
        net_leverage: 0.0,
        open_ms: 1711267458915,
        orders: [
          {
            leverage: 1.25,
            order_type: "LONG",
            order_uuid: "7aab74a6-36a2-489f-a8c4-a021a215b15f",
            price: 64641.95,
            price_sources: [],
            processed_ms: 1711267458913,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "605e74b6-fd06-49e6-8033-d0e282062e96",
            price: 65073.25,
            price_sources: [],
            processed_ms: 1711271027788,
          },
        ],
        position_type: "FLAT",
        position_uuid: "23f9d6e0-d0a4-4a44-9925-699424fa00ab",
        return_at_close: 1.0058193218567821,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 65087.1,
        close_ms: 1711274696509,
        current_return: 0.9972676997438817,
        initial_entry_price: 65087.1,
        is_closed_position: true,
        miner_hotkey: "5FNHGRhAJskJ8uSxWD4KG3fiDXUjVPWytAfyLVLEoKDdn6vE",
        net_leverage: 0.0,
        open_ms: 1711271114986,
        orders: [
          {
            leverage: 1.25,
            order_type: "LONG",
            order_uuid: "d92579b7-ccc4-46ef-bf81-a595c4780c3f",
            price: 65087.1,
            price_sources: [],
            processed_ms: 1711271114984,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "8718758c-94e6-45ce-9631-0a09de824452",
            price: 64944.83,
            price_sources: [],
            processed_ms: 1711274696509,
          },
        ],
        position_type: "FLAT",
        position_uuid: "5f268e3f-6bb6-4fba-8d19-26b79da9ed52",
        return_at_close: 0.9947745304945221,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 64982.259999999995,
        close_ms: 1711280714893,
        current_return: 1.0027369008095441,
        initial_entry_price: 64982.26,
        is_closed_position: true,
        miner_hotkey: "5FNHGRhAJskJ8uSxWD4KG3fiDXUjVPWytAfyLVLEoKDdn6vE",
        net_leverage: 0.0,
        open_ms: 1711278458507,
        orders: [
          {
            leverage: 1.25,
            order_type: "LONG",
            order_uuid: "af75e3de-0c16-4551-baa8-37063b3572fb",
            price: 64982.26,
            price_sources: [],
            processed_ms: 1711278458506,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "b425ed6d-dde2-4425-a28c-9bd40e3a5305",
            price: 65124.54,
            price_sources: [],
            processed_ms: 1711280714893,
          },
        ],
        position_type: "FLAT",
        position_uuid: "ff7b01e6-c4b1-4e20-b2d8-c643c416a342",
        return_at_close: 1.0002300585575203,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 65521.77,
        close_ms: 1711286175683,
        current_return: 1.0006614213871208,
        initial_entry_price: 65521.77,
        is_closed_position: true,
        miner_hotkey: "5FNHGRhAJskJ8uSxWD4KG3fiDXUjVPWytAfyLVLEoKDdn6vE",
        net_leverage: 0.0,
        open_ms: 1711284341717,
        orders: [
          {
            leverage: 1.25,
            order_type: "LONG",
            order_uuid: "c3d7ff09-c1e0-4d20-b312-9b1fee83a73d",
            price: 65521.77,
            price_sources: [],
            processed_ms: 1711284341716,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "e09e2ea8-d2f8-411c-bf77-3a00a89fdb89",
            price: 65556.44,
            price_sources: [],
            processed_ms: 1711286175683,
          },
        ],
        position_type: "FLAT",
        position_uuid: "7bb01082-83c7-4821-8a95-e21ea4804c76",
        return_at_close: 0.998159767833653,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 65498.119999999995,
        close_ms: 1711317268778,
        current_return: 0.999577787111364,
        initial_entry_price: 66289.87,
        is_closed_position: true,
        miner_hotkey: "5FNHGRhAJskJ8uSxWD4KG3fiDXUjVPWytAfyLVLEoKDdn6vE",
        net_leverage: 0.0,
        open_ms: 1711312583644,
        orders: [
          {
            leverage: -1.25,
            order_type: "SHORT",
            order_uuid: "9110a346-9578-446b-8ecd-4342ae8f1aea",
            price: 66289.87,
            price_sources: [],
            processed_ms: 1711312583643,
          },
          {
            leverage: 0.625,
            order_type: "LONG",
            order_uuid: "01965e1e-13c2-4010-920e-1b947075879d",
            price: 66277.23,
            price_sources: [],
            processed_ms: 1711313542482,
          },
          {
            leverage: 0.3125,
            order_type: "LONG",
            order_uuid: "3a33aca7-1c7d-428a-8b66-d8eaf44f6984",
            price: 66323.47,
            price_sources: [],
            processed_ms: 1711314453834,
          },
          {
            leverage: 0.15625,
            order_type: "LONG",
            order_uuid: "0e0e8459-e0d9-4343-af6d-60fd8f10bf75",
            price: 66395.22,
            price_sources: [],
            processed_ms: 1711315382921,
          },
          {
            leverage: 0.125,
            order_type: "LONG",
            order_uuid: "3bffb57e-b7b4-4a36-98a3-5356d5ee1333",
            price: 66335.32,
            price_sources: [],
            processed_ms: 1711316318308,
          },
          {
            leverage: 0.125,
            order_type: "LONG",
            order_uuid: "e08dc454-13cb-4eb4-aac6-63d02078bff1",
            price: 66393.75,
            price_sources: [],
            processed_ms: 1711317268778,
          },
        ],
        position_type: "FLAT",
        position_uuid: "6b41e7a4-c476-42fa-8299-a30ce74bcb15",
        return_at_close: 0.9970788426435857,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 74807.15999999986,
        close_ms: 1711327499603,
        current_return: 1.0038068478838769,
        initial_entry_price: 67335.4,
        is_closed_position: true,
        miner_hotkey: "5FNHGRhAJskJ8uSxWD4KG3fiDXUjVPWytAfyLVLEoKDdn6vE",
        net_leverage: 0.0,
        open_ms: 1711322836264,
        orders: [
          {
            leverage: -1.25,
            order_type: "SHORT",
            order_uuid: "3aa492b2-aa4a-4a22-9fbb-531dfb11cc6c",
            price: 67335.4,
            price_sources: [],
            processed_ms: 1711322835061,
          },
          {
            leverage: 0.625,
            order_type: "LONG",
            order_uuid: "d56d439d-3ff5-4b81-b368-fec96926b418",
            price: 67195.94,
            price_sources: [],
            processed_ms: 1711323771727,
          },
          {
            leverage: 0.3125,
            order_type: "LONG",
            order_uuid: "d4169498-b76d-40c0-b49a-1e8d4f943cdd",
            price: 67246.58,
            price_sources: [],
            processed_ms: 1711324730361,
          },
          {
            leverage: 0.15625,
            order_type: "LONG",
            order_uuid: "08f48c3b-22e3-4a93-978d-93b58416ce14",
            price: 66942.16,
            price_sources: [],
            processed_ms: 1711325631657,
          },
          {
            leverage: 0.125,
            order_type: "LONG",
            order_uuid: "909d342d-a937-4214-abbf-a382eda7c99c",
            price: 66878.36,
            price_sources: [],
            processed_ms: 1711326566975,
          },
          {
            leverage: 0.125,
            order_type: "LONG",
            order_uuid: "d911eff3-3b4a-4466-8c34-afdd9fcfcc7a",
            price: 66604.42,
            price_sources: [],
            processed_ms: 1711327499603,
          },
        ],
        position_type: "FLAT",
        position_uuid: "7d977069-b077-4abc-925a-642a20542dd1",
        return_at_close: 1.0012973307641673,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 70696.40999999923,
        close_ms: 1711340811879,
        current_return: 1.0021265041832672,
        initial_entry_price: 66574.75,
        is_closed_position: true,
        miner_hotkey: "5FNHGRhAJskJ8uSxWD4KG3fiDXUjVPWytAfyLVLEoKDdn6vE",
        net_leverage: 0.0,
        open_ms: 1711328451595,
        orders: [
          {
            leverage: -1.25,
            order_type: "SHORT",
            order_uuid: "581c9048-7b77-4824-a5fa-e62cbeeb1255",
            price: 66574.75,
            price_sources: [],
            processed_ms: 1711328451594,
          },
          {
            leverage: -1.25,
            order_type: "SHORT",
            order_uuid: "7d0f7c97-a962-4611-acfb-bf012e503ffa",
            price: 66796.48,
            price_sources: [],
            processed_ms: 1711329358812,
          },
          {
            leverage: 1.25,
            order_type: "LONG",
            order_uuid: "fece2d49-71ae-47fe-8c5e-bcf23f1cf0c9",
            price: 66669.52,
            price_sources: [],
            processed_ms: 1711329822767,
          },
          {
            leverage: 0.625,
            order_type: "LONG",
            order_uuid: "536d4437-9e04-416c-aac1-49ee302cc9c0",
            price: 66478.85,
            price_sources: [],
            processed_ms: 1711331671846,
          },
          {
            leverage: 0.3125,
            order_type: "LONG",
            order_uuid: "6fa807fa-cc4e-4f9d-af96-68d0b4cc9e7d",
            price: 66565.86,
            price_sources: [],
            processed_ms: 1711333489707,
          },
          {
            leverage: 0.15625,
            order_type: "LONG",
            order_uuid: "1834f379-6cb7-4640-829d-d5752675e925",
            price: 66713.37,
            price_sources: [],
            processed_ms: 1711335316814,
          },
          {
            leverage: 0.078125,
            order_type: "LONG",
            order_uuid: "bc6a94d2-d471-48ce-a5bc-79b6df377319",
            price: 66828.12,
            price_sources: [],
            processed_ms: 1711337192118,
          },
          {
            leverage: 0.0390625,
            order_type: "LONG",
            order_uuid: "360c1689-adaa-4045-aab7-56c7937ac5bd",
            price: 67060.11,
            price_sources: [],
            processed_ms: 1711338981662,
          },
          {
            leverage: 0.125,
            order_type: "LONG",
            order_uuid: "3967b754-1263-4a72-84f5-ffb902848685",
            price: 67072.18,
            price_sources: [],
            processed_ms: 1711340811879,
          },
        ],
        position_type: "FLAT",
        position_uuid: "60fff9b4-566f-400a-a206-57331fbf5d56",
        return_at_close: 0.9971158716623508,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 67049.99,
        close_ms: 1711362805981,
        current_return: 1.0019129383911913,
        initial_entry_price: 67049.99,
        is_closed_position: true,
        miner_hotkey: "5FNHGRhAJskJ8uSxWD4KG3fiDXUjVPWytAfyLVLEoKDdn6vE",
        net_leverage: 0.0,
        open_ms: 1711360974732,
        orders: [
          {
            leverage: 1.25,
            order_type: "LONG",
            order_uuid: "2392750f-9688-4bff-8a34-5d4e9ec7f49d",
            price: 67049.99,
            price_sources: [],
            processed_ms: 1711360974730,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "d9d42b30-1c8d-4b83-a952-81f440713798",
            price: 67152.6,
            price_sources: [],
            processed_ms: 1711362805981,
          },
        ],
        position_type: "FLAT",
        position_uuid: "43bbaf96-7c37-4a43-b265-b520ec6e68f0",
        return_at_close: 0.9994081560452134,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 61579.01124999998,
        close_ms: 1711376013808,
        current_return: 1.0433505333934452,
        initial_entry_price: 66986.2,
        is_closed_position: true,
        miner_hotkey: "5FNHGRhAJskJ8uSxWD4KG3fiDXUjVPWytAfyLVLEoKDdn6vE",
        net_leverage: 0.0,
        open_ms: 1711368527434,
        orders: [
          {
            leverage: 1.25,
            order_type: "LONG",
            order_uuid: "a0c8e108-9e1a-4adb-b3ff-379b7639cfb4",
            price: 66986.2,
            price_sources: [],
            processed_ms: 1711368527432,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "b85a0fbc-670b-4cac-8a74-04543dcf1d99",
            price: 67172.98,
            price_sources: [],
            processed_ms: 1711370352673,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "885af7da-7d59-440f-9bca-5d79cb4d9bfe",
            price: 67181.19,
            price_sources: [],
            processed_ms: 1711372188850,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "c3f9d6aa-a9ac-4d4e-8936-e391c95aa0cd",
            price: 67222.56,
            price_sources: [],
            processed_ms: 1711374014202,
          },
          {
            leverage: -1.6,
            order_type: "SHORT",
            order_uuid: "9791715d-026f-491d-ba63-56d1df5161ce",
            price: 68434.58,
            price_sources: [],
            processed_ms: 1711375845673,
          },
          {
            leverage: -1.6,
            order_type: "SHORT",
            order_uuid: "bb9ae651-46ea-401a-934b-e61695c2ce52",
            price: 68838.73,
            price_sources: [],
            processed_ms: 1711376013808,
          },
        ],
        position_type: "FLAT",
        position_uuid: "dbfb10f3-c09b-41ed-83cf-5f2d13b2abd4",
        return_at_close: 1.0391771312598714,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 68356.28000000003,
        close_ms: 1711378279199,
        current_return: 0.9984524559018632,
        initial_entry_price: 69141.81,
        is_closed_position: true,
        miner_hotkey: "5FNHGRhAJskJ8uSxWD4KG3fiDXUjVPWytAfyLVLEoKDdn6vE",
        net_leverage: 0.0,
        open_ms: 1711376529334,
        orders: [
          {
            leverage: -1.25,
            order_type: "SHORT",
            order_uuid: "59ef60bb-cd29-4cb3-bb46-1bc0c2efb7d5",
            price: 69141.81,
            price_sources: [],
            processed_ms: 1711376529332,
          },
          {
            leverage: 0.625,
            order_type: "LONG",
            order_uuid: "c33bd6a9-36c6-43ed-9173-e2b15379b980",
            price: 69296.54,
            price_sources: [],
            processed_ms: 1711376846125,
          },
          {
            leverage: 0.5,
            order_type: "LONG",
            order_uuid: "4bd2f3d9-c639-4d6a-b49e-52ef52455dcd",
            price: 69144.78,
            price_sources: [],
            processed_ms: 1711377999914,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "b8971433-3166-4325-8b99-a83996c0e7a6",
            price: 69212.28,
            price_sources: [],
            processed_ms: 1711378279199,
          },
        ],
        position_type: "FLAT",
        position_uuid: "213b5db8-7a48-4cb1-afb8-f12f6d2ef4dc",
        return_at_close: 0.9959563247621086,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: -666491.6266666666,
        close_ms: 1711394328302,
        current_return: 0.9840241337027894,
        initial_entry_price: 69222.49,
        is_closed_position: true,
        miner_hotkey: "5FNHGRhAJskJ8uSxWD4KG3fiDXUjVPWytAfyLVLEoKDdn6vE",
        net_leverage: 0.0,
        open_ms: 1711379789148,
        orders: [
          {
            leverage: -1.25,
            order_type: "SHORT",
            order_uuid: "2985d2e5-3a73-4d9d-8189-8d717734518a",
            price: 69222.49,
            price_sources: [],
            processed_ms: 1711379789146,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "11fa6d9e-6c6a-42e4-9161-cc2f60452123",
            price: 69824.77,
            price_sources: [],
            processed_ms: 1711381605250,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "c320a8c3-0ffa-4a3f-88e1-6f30d0b08fde",
            price: 69701.12,
            price_sources: [],
            processed_ms: 1711383429575,
          },
          {
            leverage: 0.375,
            order_type: "LONG",
            order_uuid: "b220437e-bef0-453a-9433-ca08fbb40fc6",
            price: 70158.7,
            price_sources: [],
            processed_ms: 1711385240079,
          },
          {
            leverage: 0.1875,
            order_type: "LONG",
            order_uuid: "5ce94017-dd7e-4d45-9d01-8a54db85a4e6",
            price: 70635.36,
            price_sources: [],
            processed_ms: 1711387059132,
          },
          {
            leverage: 0.15,
            order_type: "LONG",
            order_uuid: "5297ba01-3f36-43bc-82f9-72196d863995",
            price: 70327.71,
            price_sources: [],
            processed_ms: 1711388868938,
          },
          {
            leverage: 0.030000000000000006,
            order_type: "LONG",
            order_uuid: "5c7ed89c-2e87-4652-9fb0-d134b56f7162",
            price: 70628.29,
            price_sources: [],
            processed_ms: 1711390692129,
          },
          {
            leverage: 0.006,
            order_type: "LONG",
            order_uuid: "fa984f9b-2f22-4b97-9fec-1bf4e8955e40",
            price: 70788.29,
            price_sources: [],
            processed_ms: 1711392519879,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "d043b735-c2f9-4d48-aa0e-c42af8a37afd",
            price: 70767.87,
            price_sources: [],
            processed_ms: 1711394328302,
          },
        ],
        position_type: "FLAT",
        position_uuid: "2fe14c13-0168-4fb1-93ec-58bfeef5d2e2",
        return_at_close: 0.9815640733685325,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 70774.06374999999,
        close_ms: 1711402231040,
        current_return: 0.9675461157245373,
        initial_entry_price: 70888.51,
        is_closed_position: true,
        miner_hotkey: "5FNHGRhAJskJ8uSxWD4KG3fiDXUjVPWytAfyLVLEoKDdn6vE",
        net_leverage: 0.0,
        open_ms: 1711396147718,
        orders: [
          {
            leverage: 1.25,
            order_type: "LONG",
            order_uuid: "85e0852d-6e79-42e6-9899-3eeb13dfa204",
            price: 70888.51,
            price_sources: [],
            processed_ms: 1711396147716,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "5c3870e3-3e2f-485b-a907-4cedca6941dd",
            price: 70860.91,
            price_sources: [],
            processed_ms: 1711397963471,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "ffa789d1-fd11-4b37-b16f-874fd4c4751e",
            price: 70916.72,
            price_sources: [],
            processed_ms: 1711399782865,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "83adbbd0-179a-4155-a1a3-8fd3780b81bc",
            price: 69972.33,
            price_sources: [],
            processed_ms: 1711401595397,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "867e72c5-e0a5-423f-bc95-d38655d08949",
            price: 69623.76,
            price_sources: [],
            processed_ms: 1711402231040,
          },
        ],
        position_type: "FLAT",
        position_uuid: "ffd293e5-2889-4e65-afe2-901bf163d21d",
        return_at_close: 0.9636759312616392,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: -259681.38333331907,
        close_ms: 1711441581816,
        current_return: 1.0099677708909376,
        initial_entry_price: 69908.9,
        is_closed_position: true,
        miner_hotkey: "5FNHGRhAJskJ8uSxWD4KG3fiDXUjVPWytAfyLVLEoKDdn6vE",
        net_leverage: 0.0,
        open_ms: 1711403419790,
        orders: [
          {
            leverage: 1.25,
            order_type: "LONG",
            order_uuid: "c447df41-3bc8-401e-8b60-c06c85654523",
            price: 69908.9,
            price_sources: [],
            processed_ms: 1711403419788,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "e7b03330-9b85-4a49-bc2b-64bcd01dec9c",
            price: 70377.23,
            price_sources: [],
            processed_ms: 1711405231994,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "74aea749-4fd8-4d0e-b834-ab471e745a92",
            price: 70397.91,
            price_sources: [],
            processed_ms: 1711407051120,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "d22ee5cc-34da-43fa-8df9-3ecde4074d77",
            price: 70433.13,
            price_sources: [],
            processed_ms: 1711408896445,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "e896c8ca-bc37-43f7-bbaf-bc1a8c136542",
            price: 69890.81,
            price_sources: [],
            processed_ms: 1711410687846,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "9bd6e64b-4dfb-421e-9f89-3af35e5c3256",
            price: 69945.23,
            price_sources: [],
            processed_ms: 1711412499202,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "b3fabcb2-85a2-4d13-bb46-a92590066820",
            price: 69992.44,
            price_sources: [],
            processed_ms: 1711414317615,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "83544616-bfac-462a-963a-05d4cdd409b9",
            price: 70208.9,
            price_sources: [],
            processed_ms: 1711416142696,
          },
          {
            leverage: -1.5,
            order_type: "SHORT",
            order_uuid: "02488898-37e5-4335-acde-c90c641a5e99",
            price: 70233.21,
            price_sources: [],
            processed_ms: 1711417957020,
          },
          {
            leverage: -0.75,
            order_type: "SHORT",
            order_uuid: "0bec2bad-da3e-4dd8-9d7d-2ddaac96afe8",
            price: 70257.69,
            price_sources: [],
            processed_ms: 1711419773074,
          },
          {
            leverage: -0.375,
            order_type: "SHORT",
            order_uuid: "257cd139-09ec-4851-8884-aefb2a06fe6e",
            price: 70371.38,
            price_sources: [],
            processed_ms: 1711421604534,
          },
          {
            leverage: -0.1875,
            order_type: "SHORT",
            order_uuid: "af917981-ff1b-4d76-979d-64724f1f9a74",
            price: 70493.83,
            price_sources: [],
            processed_ms: 1711423411091,
          },
          {
            leverage: -0.09375,
            order_type: "SHORT",
            order_uuid: "02c8f749-7dde-4d9a-892c-37b76df75dc9",
            price: 70494.73,
            price_sources: [],
            processed_ms: 1711425225056,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "7cdc7f3b-f511-4b39-9725-5d9816c2c52c",
            price: 70526.99,
            price_sources: [],
            processed_ms: 1711427039697,
          },
          {
            leverage: -0.171875,
            order_type: "SHORT",
            order_uuid: "31ef035b-1b38-497d-81ff-cc39828acc30",
            price: 70575.26,
            price_sources: [],
            processed_ms: 1711428872381,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "a5567b21-df4e-404d-9ed7-91ab2f7cf14d",
            price: 70457.47,
            price_sources: [],
            processed_ms: 1711430685137,
          },
          {
            leverage: -0.2109375,
            order_type: "SHORT",
            order_uuid: "278429df-50d7-49bd-a7bb-2e3bce89cf3c",
            price: 70424.95,
            price_sources: [],
            processed_ms: 1711432499428,
          },
          {
            leverage: -0.10546875,
            order_type: "SHORT",
            order_uuid: "3ba44064-ab5c-4e49-ae1a-dfeb6f2ed074",
            price: 70371.64,
            price_sources: [],
            processed_ms: 1711434338652,
          },
          {
            leverage: -0.052734375,
            order_type: "SHORT",
            order_uuid: "dc50c319-8e13-478d-b6e7-3854a2321d7a",
            price: 70468.05,
            price_sources: [],
            processed_ms: 1711436128942,
          },
          {
            leverage: -0.0421875,
            order_type: "SHORT",
            order_uuid: "b0ac7e67-cd6f-406d-9532-2b221df180ff",
            price: 71415.23,
            price_sources: [],
            processed_ms: 1711437947993,
          },
          {
            leverage: -0.008437499999999997,
            order_type: "SHORT",
            order_uuid: "ccd6d904-87f7-465b-b030-96d3fd578807",
            price: 70670.3,
            price_sources: [],
            processed_ms: 1711439769166,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "02364ad1-db8a-4a24-a66a-d8046c743800",
            price: 70670.45,
            price_sources: [],
            processed_ms: 1711441581816,
          },
        ],
        position_type: "FLAT",
        position_uuid: "3038fd5f-a4b7-492e-a851-52ebc568608d",
        return_at_close: 1.003907964265592,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 68670.23999999999,
        close_ms: 1711452488328,
        current_return: 0.9924409768254617,
        initial_entry_price: 70572.28,
        is_closed_position: true,
        miner_hotkey: "5FNHGRhAJskJ8uSxWD4KG3fiDXUjVPWytAfyLVLEoKDdn6vE",
        net_leverage: 0.0,
        open_ms: 1711443451112,
        orders: [
          {
            leverage: -1.25,
            order_type: "SHORT",
            order_uuid: "6d00924e-e870-4108-b26c-2ce22aea590e",
            price: 70572.28,
            price_sources: [],
            processed_ms: 1711443451109,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "2caf4bc6-bd5d-42aa-9433-7d725d0bc32b",
            price: 71058.78,
            price_sources: [],
            processed_ms: 1711445214759,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "91ed0def-4d07-4dd3-a426-5be79f19ab01",
            price: 71202.84,
            price_sources: [],
            processed_ms: 1711447028961,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "b585446a-1684-4d2a-9a57-65fdb2db24b8",
            price: 70959.54,
            price_sources: [],
            processed_ms: 1711448853442,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "d2658113-8272-41a4-8489-7ea6d2236667",
            price: 70970.0,
            price_sources: [],
            processed_ms: 1711450676351,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "930f13dc-381f-4559-8fde-826436d53f3d",
            price: 70804.07,
            price_sources: [],
            processed_ms: 1711452488328,
          },
        ],
        position_type: "FLAT",
        position_uuid: "d93f38b1-68b3-464d-944f-17b05402d38a",
        return_at_close: 0.9899598743833982,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 219875.63000000012,
        close_ms: 1711470674980,
        current_return: 1.0105704857349669,
        initial_entry_price: 70940.95,
        is_closed_position: true,
        miner_hotkey: "5FNHGRhAJskJ8uSxWD4KG3fiDXUjVPWytAfyLVLEoKDdn6vE",
        net_leverage: 0.0,
        open_ms: 1711457970315,
        orders: [
          {
            leverage: -1.25,
            order_type: "SHORT",
            order_uuid: "17590032-6a02-489e-bd42-a09f6f981728",
            price: 70940.95,
            price_sources: [],
            processed_ms: 1711457970312,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "e9c46516-20b9-4a71-b074-9c0783b0e17f",
            price: 70784.26,
            price_sources: [],
            processed_ms: 1711459755784,
          },
          {
            leverage: 0.5,
            order_type: "LONG",
            order_uuid: "49bbe6bf-6566-4cd5-9de2-6a9fc100c1e2",
            price: 70382.93,
            price_sources: [],
            processed_ms: 1711461573955,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "5805c9b9-c3ac-4730-9e9a-171fc7d7c147",
            price: 69870.89,
            price_sources: [],
            processed_ms: 1711463385945,
          },
          {
            leverage: 0.2,
            order_type: "LONG",
            order_uuid: "979b0494-0a3b-4821-beff-4061c9ec8315",
            price: 70306.66,
            price_sources: [],
            processed_ms: 1711465208112,
          },
          {
            leverage: 0.024999999999999994,
            order_type: "LONG",
            order_uuid: "c0b6cfac-d5f6-464b-9536-edb4e5100381",
            price: 70167.21,
            price_sources: [],
            processed_ms: 1711467027704,
          },
          {
            leverage: 0.019999999999999997,
            order_type: "LONG",
            order_uuid: "7ccd9b65-dc55-4b48-896a-4c68ecee3dfd",
            price: 70302.23,
            price_sources: [],
            processed_ms: 1711468893904,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "c39fb51c-7dbe-4f4c-8581-1bcb77391472",
            price: 69899.57,
            price_sources: [],
            processed_ms: 1711470674980,
          },
        ],
        position_type: "FLAT",
        position_uuid: "f1b8807b-ae7c-48bc-a054-67e283f94909",
        return_at_close: 1.0080440595206295,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 70135.64531531531,
        close_ms: 1711524662132,
        current_return: 0.9696142691585776,
        initial_entry_price: 69755.83,
        is_closed_position: true,
        miner_hotkey: "5FNHGRhAJskJ8uSxWD4KG3fiDXUjVPWytAfyLVLEoKDdn6vE",
        net_leverage: 0.0,
        open_ms: 1711472473228,
        orders: [
          {
            leverage: 1.25,
            order_type: "LONG",
            order_uuid: "ee6f25f6-56b2-431a-a857-20c6040add0c",
            price: 69755.83,
            price_sources: [],
            processed_ms: 1711472473225,
          },
          {
            leverage: -0.625,
            order_type: "SHORT",
            order_uuid: "646be6cf-c7ac-4865-97e6-86bef8664d4c",
            price: 70027.32,
            price_sources: [],
            processed_ms: 1711474310719,
          },
          {
            leverage: -0.3125,
            order_type: "SHORT",
            order_uuid: "c37af44a-770e-4ea9-a97a-59bb86ebabb6",
            price: 70070.99,
            price_sources: [],
            processed_ms: 1711476105955,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "c9ec7389-50f5-45df-b51b-4e77efa81b72",
            price: 70211.92,
            price_sources: [],
            processed_ms: 1711477923817,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "126b9033-9ffe-4e13-baaa-b9c44729ab1a",
            price: 69980.69,
            price_sources: [],
            processed_ms: 1711479740176,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "bec37305-353b-498d-8527-b00340b920c9",
            price: 69804.68,
            price_sources: [],
            processed_ms: 1711481561374,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "d8700c9d-bab4-4250-8cd4-f1e396a872e2",
            price: 69372.44,
            price_sources: [],
            processed_ms: 1711483376039,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "259164dd-a861-40d8-989a-a187a1946c6f",
            price: 69800.45,
            price_sources: [],
            processed_ms: 1711485186028,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "c726d0b3-51b8-40cf-bafb-4b0da0a98b95",
            price: 69890.04,
            price_sources: [],
            processed_ms: 1711487014237,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "dfeacf42-e850-4928-9a5d-f743cc33dd84",
            price: 70168.93,
            price_sources: [],
            processed_ms: 1711488836217,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "cfb320a3-5131-4a89-8100-3ca232065a83",
            price: 70018.75,
            price_sources: [],
            processed_ms: 1711490644506,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "052afd36-067f-432d-8560-38a06f44caad",
            price: 69939.92,
            price_sources: [],
            processed_ms: 1711492468203,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "ee0959da-b312-4f35-bd39-0cbfdf3383ac",
            price: 70248.22,
            price_sources: [],
            processed_ms: 1711494279312,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "d788fef5-e95f-466a-a2a9-c80c78c318ad",
            price: 70059.89,
            price_sources: [],
            processed_ms: 1711496094037,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "816d9df8-bb47-4e6f-bee5-0c23cccecdb0",
            price: 70022.73,
            price_sources: [],
            processed_ms: 1711497917920,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "a7123de7-c526-4d0a-b502-22289643d3d6",
            price: 70221.18,
            price_sources: [],
            processed_ms: 1711499726940,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "5b6c433d-d578-4b4c-89df-6508e087bfde",
            price: 70511.93,
            price_sources: [],
            processed_ms: 1711501586301,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "5d64a878-8cdf-4c0d-9ee3-cf70a8969cbb",
            price: 70548.99,
            price_sources: [],
            processed_ms: 1711503390931,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "f5eb645b-3c82-4f2d-b55d-d960c63db8a2",
            price: 70282.73,
            price_sources: [],
            processed_ms: 1711505177094,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "61446689-b9a5-43c3-95d4-5f59653e060a",
            price: 70392.22,
            price_sources: [],
            processed_ms: 1711506994838,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "68d8413e-b188-4049-9b03-2617ceb2a980",
            price: 70650.22,
            price_sources: [],
            processed_ms: 1711508805036,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "1a86fd1c-9553-4501-a5dd-b1951b43688b",
            price: 70424.26,
            price_sources: [],
            processed_ms: 1711510629096,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "cc7739a0-00eb-4160-a970-18bfafcb3de1",
            price: 70427.47,
            price_sources: [],
            processed_ms: 1711512442099,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "8c70d160-d873-4a70-92e9-f900e168ed1d",
            price: 70305.68,
            price_sources: [],
            processed_ms: 1711514261069,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "100fad3b-0d87-41c0-ba38-fd2075782e08",
            price: 70232.47,
            price_sources: [],
            processed_ms: 1711516072083,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "97193dc8-6524-4264-9f2e-a16717bc9e63",
            price: 70468.34,
            price_sources: [],
            processed_ms: 1711517950972,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "bca1636e-4e11-44f9-9b1a-23e7b1ea4f04",
            price: 70485.12,
            price_sources: [],
            processed_ms: 1711519709899,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "4b9a2e75-1313-4811-9e4a-978e8eec713b",
            price: 70289.09,
            price_sources: [],
            processed_ms: 1711521525981,
          },
          {
            leverage: 0.375,
            order_type: "LONG",
            order_uuid: "e422916b-4dfc-4ede-a637-cb3eb37da5e6",
            price: 70255.79,
            price_sources: [],
            processed_ms: 1711523386212,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "e6ce3c15-d2fe-41e5-b453-deeeddb347c0",
            price: 69830.12,
            price_sources: [],
            processed_ms: 1711524662132,
          },
        ],
        position_type: "FLAT",
        position_uuid: "48dab39a-df12-4bfc-98a3-c4fe57f3a574",
        return_at_close: 0.9561608711740024,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 69879.98107142856,
        close_ms: 1711545207820,
        current_return: 1.0692936020558335,
        initial_entry_price: 69803.36,
        is_closed_position: true,
        miner_hotkey: "5FNHGRhAJskJ8uSxWD4KG3fiDXUjVPWytAfyLVLEoKDdn6vE",
        net_leverage: 0.0,
        open_ms: 1711526551973,
        orders: [
          {
            leverage: 1.25,
            order_type: "LONG",
            order_uuid: "49ce9ba2-07e8-42e1-8491-a9aca19c9cd4",
            price: 69803.36,
            price_sources: [],
            processed_ms: 1711526551970,
          },
          {
            leverage: 0.375,
            order_type: "LONG",
            order_uuid: "d3b083c7-5c5a-4060-805b-35854a2122e4",
            price: 69866.14,
            price_sources: [],
            processed_ms: 1711528384409,
          },
          {
            leverage: 0.375,
            order_type: "LONG",
            order_uuid: "a3a6096f-0183-4097-98ce-90cdcef76a60",
            price: 69535.47,
            price_sources: [],
            processed_ms: 1711530178218,
          },
          {
            leverage: 0.375,
            order_type: "LONG",
            order_uuid: "e8b78266-aa1c-4de5-b2f6-67322a2c9011",
            price: 69761.64,
            price_sources: [],
            processed_ms: 1711532000107,
          },
          {
            leverage: 0.375,
            order_type: "LONG",
            order_uuid: "499c3f84-5688-4148-8732-18ed4f434154",
            price: 70056.69,
            price_sources: [],
            processed_ms: 1711533819866,
          },
          {
            leverage: 0.375,
            order_type: "LONG",
            order_uuid: "5bb11daf-3bd7-4c18-b0d9-e8c877cb848f",
            price: 70158.46,
            price_sources: [],
            processed_ms: 1711538250410,
          },
          {
            leverage: 0.375,
            order_type: "LONG",
            order_uuid: "80015dfd-59ca-4991-a401-3f53b13ce040",
            price: 70156.89,
            price_sources: [],
            processed_ms: 1711541869806,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "5ffe4f4e-8564-4c3b-98e9-ff2a444cb4ac",
            price: 71261.96,
            price_sources: [],
            processed_ms: 1711545207820,
          },
        ],
        position_type: "FLAT",
        position_uuid: "89840a2d-6469-459b-981b-fba724dc1885",
        return_at_close: 1.0618085468414427,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 70226.20999999993,
        close_ms: 1711560750107,
        current_return: 1.0006386001586738,
        initial_entry_price: 69261.6,
        is_closed_position: true,
        miner_hotkey: "5FNHGRhAJskJ8uSxWD4KG3fiDXUjVPWytAfyLVLEoKDdn6vE",
        net_leverage: 0.0,
        open_ms: 1711549107414,
        orders: [
          {
            leverage: -1.25,
            order_type: "SHORT",
            order_uuid: "5eda64fc-9dd7-40a6-88c5-1db6353b5c3c",
            price: 69261.6,
            price_sources: [],
            processed_ms: 1711549107411,
          },
          {
            leverage: 0.625,
            order_type: "LONG",
            order_uuid: "496593f2-309c-4db6-9e31-e8ede29657e4",
            price: 69319.55,
            price_sources: [],
            processed_ms: 1711552723005,
          },
          {
            leverage: 0.3125,
            order_type: "LONG",
            order_uuid: "88189b93-26b7-4acf-ac01-68de64255560",
            price: 69182.7,
            price_sources: [],
            processed_ms: 1711553487016,
          },
          {
            leverage: 0.15625,
            order_type: "LONG",
            order_uuid: "dd9cc425-049b-4aeb-a0b5-646833bd80b4",
            price: 69020.74,
            price_sources: [],
            processed_ms: 1711555301014,
          },
          {
            leverage: 0.078125,
            order_type: "LONG",
            order_uuid: "c13374dd-6cdc-4980-b096-3d535539ac60",
            price: 69145.02,
            price_sources: [],
            processed_ms: 1711557153886,
          },
          {
            leverage: 0.0390625,
            order_type: "LONG",
            order_uuid: "81925fd2-b5c4-4814-9afe-468ead2947d3",
            price: 69197.59,
            price_sources: [],
            processed_ms: 1711558936195,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "adeebe70-4e94-485e-9f90-1a231626e60e",
            price: 69093.91,
            price_sources: [],
            processed_ms: 1711560750107,
          },
        ],
        position_type: "FLAT",
        position_uuid: "af9a6a65-793a-455e-aaba-040319e67669",
        return_at_close: 0.9981370036582772,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 68344.89999999998,
        close_ms: 1711578913907,
        current_return: 0.9953657015387284,
        initial_entry_price: 68928.34,
        is_closed_position: true,
        miner_hotkey: "5FNHGRhAJskJ8uSxWD4KG3fiDXUjVPWytAfyLVLEoKDdn6vE",
        net_leverage: 0.0,
        open_ms: 1711571649148,
        orders: [
          {
            leverage: -3.5,
            order_type: "SHORT",
            order_uuid: "5654c267-c09f-4f0c-8f27-5fd9fd2ecbbe",
            price: 68928.34,
            price_sources: [],
            processed_ms: 1711571649145,
          },
          {
            leverage: 1.0499999999999998,
            order_type: "LONG",
            order_uuid: "b1f7672c-ebc7-4eb6-b71f-a2ee7b44a122",
            price: 68935.41,
            price_sources: [],
            processed_ms: 1711573468435,
          },
          {
            leverage: 1.05,
            order_type: "LONG",
            order_uuid: "b5b66b1a-2c91-4bb5-ae30-5c9be8e4eac8",
            price: 69103.82,
            price_sources: [],
            processed_ms: 1711575282936,
          },
          {
            leverage: 1.05,
            order_type: "LONG",
            order_uuid: "27829a0f-e108-4104-91df-930fd41099a8",
            price: 68940.27,
            price_sources: [],
            processed_ms: 1711577098925,
          },
          {
            leverage: 1.05,
            order_type: "LONG",
            order_uuid: "77ae9376-00ca-45b3-9906-57a060cfecca",
            price: 69257.57,
            price_sources: [],
            processed_ms: 1711578913907,
          },
        ],
        position_type: "FLAT",
        position_uuid: "5cdd57a3-cca0-479d-b976-4c764a2fd1cb",
        return_at_close: 0.9883981416279572,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 69423.56999999985,
        close_ms: 1711589812191,
        current_return: 0.9997954890378916,
        initial_entry_price: 69363.03,
        is_closed_position: true,
        miner_hotkey: "5FNHGRhAJskJ8uSxWD4KG3fiDXUjVPWytAfyLVLEoKDdn6vE",
        net_leverage: 0.0,
        open_ms: 1711580736684,
        orders: [
          {
            leverage: -3.5,
            order_type: "SHORT",
            order_uuid: "79ded328-b83e-439f-a316-127213e49bef",
            price: 69363.03,
            price_sources: [],
            processed_ms: 1711580736680,
          },
          {
            leverage: 1.0499999999999998,
            order_type: "LONG",
            order_uuid: "8780a146-1fca-4f45-b4f8-5a7c8ad68eeb",
            price: 69053.91,
            price_sources: [],
            processed_ms: 1711582553488,
          },
          {
            leverage: 1.05,
            order_type: "LONG",
            order_uuid: "b1e0b0d8-791a-46f8-a7bd-f11ec646f3db",
            price: 69448.9,
            price_sources: [],
            processed_ms: 1711584369062,
          },
          {
            leverage: 0.7000000000000001,
            order_type: "LONG",
            order_uuid: "0d5db5ca-dd02-4200-9d24-fdb41a9763ab",
            price: 69564.71,
            price_sources: [],
            processed_ms: 1711586220184,
          },
          {
            leverage: 0.35000000000000003,
            order_type: "LONG",
            order_uuid: "6b337578-4e77-4082-bd98-e4244de09eb5",
            price: 69568.88,
            price_sources: [],
            processed_ms: 1711588000199,
          },
          {
            leverage: 1.05,
            order_type: "LONG",
            order_uuid: "b65a499c-67a8-46dc-8247-90a928eaef90",
            price: 69464.1,
            price_sources: [],
            processed_ms: 1711589812191,
          },
        ],
        position_type: "FLAT",
        position_uuid: "632dad7c-6bab-4fac-9afe-14ffecf1cfb0",
        return_at_close: 0.9927969206146263,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 69429.23000000001,
        close_ms: 1711618480217,
        current_return: 0.9688763346192508,
        initial_entry_price: 69530.13,
        is_closed_position: true,
        miner_hotkey: "5FNHGRhAJskJ8uSxWD4KG3fiDXUjVPWytAfyLVLEoKDdn6vE",
        net_leverage: 0.0,
        open_ms: 1711600729086,
        orders: [
          {
            leverage: -3.5,
            order_type: "SHORT",
            order_uuid: "b692b935-8df2-4a42-aaa3-fefdf884c632",
            price: 69530.13,
            price_sources: [],
            processed_ms: 1711600729081,
          },
          {
            leverage: 1.75,
            order_type: "LONG",
            order_uuid: "c9b96fad-1ac0-4676-83f8-e976411b1642",
            price: 69631.03,
            price_sources: [],
            processed_ms: 1711602527437,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "c62c5511-172a-4bdb-9574-86bb7aae53f2",
            price: 70665.82,
            price_sources: [],
            processed_ms: 1711618480217,
          },
        ],
        position_type: "FLAT",
        position_uuid: "6a1b1261-38bb-4081-a8e7-4edb004d3d4e",
        return_at_close: 0.9620942002769161,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 70841.35,
        close_ms: 1711633218868,
        current_return: 1.0023117148388623,
        initial_entry_price: 70841.35,
        is_closed_position: true,
        miner_hotkey: "5FNHGRhAJskJ8uSxWD4KG3fiDXUjVPWytAfyLVLEoKDdn6vE",
        net_leverage: 0.0,
        open_ms: 1711633114885,
        orders: [
          {
            leverage: -3.5,
            order_type: "SHORT",
            order_uuid: "1eb9f9f3-e906-4a0f-82a2-bdfa751db29b",
            price: 70841.35,
            price_sources: [],
            processed_ms: 1711633114882,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "8914dd4a-8e5b-4f9c-87c3-682a7f118d12",
            price: 70794.56,
            price_sources: [],
            processed_ms: 1711633218868,
          },
        ],
        position_type: "FLAT",
        position_uuid: "41cc7b1b-1c37-4ff1-8c82-866448b6ba0d",
        return_at_close: 0.9952955328349903,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 71157.75,
        close_ms: 1711634970080,
        current_return: 0.9994092702481461,
        initial_entry_price: 71157.75,
        is_closed_position: true,
        miner_hotkey: "5FNHGRhAJskJ8uSxWD4KG3fiDXUjVPWytAfyLVLEoKDdn6vE",
        net_leverage: 0.0,
        open_ms: 1711634883789,
        orders: [
          {
            leverage: -3.5,
            order_type: "SHORT",
            order_uuid: "7e3ed8ba-cced-491e-a63e-e473e1b043b0",
            price: 71157.75,
            price_sources: [],
            processed_ms: 1711634883786,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "b2c9b3b8-fe3c-4bf5-a0bf-bf979fcf525b",
            price: 71169.76,
            price_sources: [],
            processed_ms: 1711634970080,
          },
        ],
        position_type: "FLAT",
        position_uuid: "40fdc013-109b-4be8-b862-c8889fc1c846",
        return_at_close: 0.9924134053564091,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 71323.18,
        close_ms: 1711636781235,
        current_return: 0.9934625741589199,
        initial_entry_price: 71323.18,
        is_closed_position: true,
        miner_hotkey: "5FNHGRhAJskJ8uSxWD4KG3fiDXUjVPWytAfyLVLEoKDdn6vE",
        net_leverage: 0.0,
        open_ms: 1711636695687,
        orders: [
          {
            leverage: -3.5,
            order_type: "SHORT",
            order_uuid: "323e33cf-2998-45e2-b4cc-6456beb36f63",
            price: 71323.18,
            price_sources: [],
            processed_ms: 1711636694789,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "c0075ec2-2eca-418c-ab56-8aad9e527616",
            price: 71456.4,
            price_sources: [],
            processed_ms: 1711636781235,
          },
        ],
        position_type: "FLAT",
        position_uuid: "c16f9a9e-495c-4ce1-86c9-1b5bcbe79403",
        return_at_close: 0.9865083361398075,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
    ],
    thirty_day_returns: 1.0,
  },
  "5FTR8y26ap56vvahaxbB4PYxSkTQFpkQDqZN32uTVcW9cKjy": {
    all_time_returns: 1.046940828830537,
    n_positions: 40,
    percentage_profitable: 0.775,
    positions: [
      {
        average_entry_price: 0.9077898309596699,
        close_ms: 1716920079117,
        current_return: 1.0024216063253049,
        initial_entry_price: 0.90778983095967,
        is_closed_position: true,
        miner_hotkey: "5FTR8y26ap56vvahaxbB4PYxSkTQFpkQDqZN32uTVcW9cKjy",
        net_leverage: 0.0,
        open_ms: 1716917104132,
        orders: [
          {
            leverage: -10.0,
            order_type: "SHORT",
            order_uuid: "83154d96-323c-42cb-b28a-f5dba8dc211a",
            price: 0.90778983095967,
            price_sources: [],
            processed_ms: 1716917104061,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "7e8137af-6360-4658-9dc5-3e390c31fe91",
            price: 0.90757,
            price_sources: [],
            processed_ms: 1716920079117,
          },
        ],
        position_type: "FLAT",
        position_uuid: "37d2e73d-436a-411e-b782-1a759cef4f58",
        return_at_close: 1.0017199112008772,
        trade_pair: ["AUDCAD", "AUD/CAD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 1.27657,
        close_ms: 1716960520019,
        current_return: 1.0058163672967413,
        initial_entry_price: 1.27657,
        is_closed_position: true,
        miner_hotkey: "5FTR8y26ap56vvahaxbB4PYxSkTQFpkQDqZN32uTVcW9cKjy",
        net_leverage: 0.0,
        open_ms: 1716919632990,
        orders: [
          {
            leverage: -5.0,
            order_type: "SHORT",
            order_uuid: "b21f5e7a-6864-4e2b-a9aa-c9e367010718",
            price: 1.27657,
            price_sources: [],
            processed_ms: 1716919632393,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "409c82ab-3046-4b4a-b559-07125453946c",
            price: 1.2750849999999998,
            price_sources: [],
            processed_ms: 1716960520019,
          },
        ],
        position_type: "FLAT",
        position_uuid: "34d66578-fad8-4668-830d-f01cf52357dd",
        return_at_close: 1.0054643315681875,
        trade_pair: ["GBPUSD", "GBP/USD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.66298,
        close_ms: 1717005713094,
        current_return: 1.003242933421823,
        initial_entry_price: 0.66298,
        is_closed_position: true,
        miner_hotkey: "5FTR8y26ap56vvahaxbB4PYxSkTQFpkQDqZN32uTVcW9cKjy",
        net_leverage: 0.0,
        open_ms: 1716983284684,
        orders: [
          {
            leverage: -2.0,
            order_type: "SHORT",
            order_uuid: "3e113a42-c17b-43aa-8d21-5be2203ef043",
            price: 0.66298,
            price_sources: [],
            processed_ms: 1716983284357,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "e6ebc4df-b88a-4cf6-8813-db4fb957d5b9",
            price: 0.661905,
            price_sources: [],
            processed_ms: 1717005713094,
          },
        ],
        position_type: "FLAT",
        position_uuid: "fb6fb60b-d182-43f8-9589-7cd1a12c9a36",
        return_at_close: 1.0031024794111438,
        trade_pair: ["AUDUSD", "AUD/USD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 365.14449999994764,
        close_ms: 1717104737679,
        current_return: 1.0252556491302651,
        initial_entry_price: 103.423,
        is_closed_position: true,
        miner_hotkey: "5FTR8y26ap56vvahaxbB4PYxSkTQFpkQDqZN32uTVcW9cKjy",
        net_leverage: 0.0,
        open_ms: 1717052132847,
        orders: [
          {
            leverage: -2.0,
            order_type: "SHORT",
            order_uuid: "4f9cce54-21af-471f-abe5-cfb100339d29",
            price: 103.423,
            price_sources: [],
            processed_ms: 1717052132844,
          },
          {
            leverage: -9.0,
            order_type: "SHORT",
            order_uuid: "ded609d1-624a-434b-9e2a-cb1a12b9801c",
            price: 104.04650000000001,
            price_sources: [],
            processed_ms: 1717086538102,
          },
          {
            leverage: -10.0,
            order_type: "SHORT",
            order_uuid: "c79de59b-7729-428a-85ad-1545ce8958cb",
            price: 104.12,
            price_sources: [],
            processed_ms: 1717088896597,
          },
          {
            leverage: -20.0,
            order_type: "SHORT",
            order_uuid: "54febd24-ffbc-4e5f-80a9-d33b0f1ef626",
            price: 104.116,
            price_sources: [],
            processed_ms: 1717092856422,
          },
          {
            leverage: 36.0,
            order_type: "LONG",
            order_uuid: "a86d4b88-3c34-4bf2-b6d2-5a4b30acf469",
            price: 104.0125,
            price_sources: [],
            processed_ms: 1717097820445,
          },
          {
            leverage: 4.99,
            order_type: "LONG",
            order_uuid: "256826d3-7ba6-404e-ae21-e0987d808876",
            price: 103.9445,
            price_sources: [],
            processed_ms: 1717098520727,
          },
          {
            leverage: 0.99,
            order_type: "FLAT",
            order_uuid: "99ffb6da-4126-4d1a-a824-9d8e6b1a5c1e",
            price: 103.943,
            price_sources: [],
            processed_ms: 1717104737679,
          },
        ],
        position_type: "FLAT",
        position_uuid: "5f8e57d5-5113-4186-9232-e6413a3b92c1",
        return_at_close: 1.0223131654172612,
        trade_pair: ["AUDJPY", "AUD/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: -0.10389000000010862,
        close_ms: 1717104937475,
        current_return: 1.0107911010751591,
        initial_entry_price: 1.363985,
        is_closed_position: true,
        miner_hotkey: "5FTR8y26ap56vvahaxbB4PYxSkTQFpkQDqZN32uTVcW9cKjy",
        net_leverage: 0.0,
        open_ms: 1716917237603,
        orders: [
          {
            leverage: 10.0,
            order_type: "LONG",
            order_uuid: "374234d1-f41d-41ef-b8ff-fe449e8e76c5",
            price: 1.363985,
            price_sources: [],
            processed_ms: 1716917237455,
          },
          {
            leverage: -5.0,
            order_type: "SHORT",
            order_uuid: "f50dbd47-d57e-42b0-9a8e-8e491d4e0059",
            price: 1.36514,
            price_sources: [],
            processed_ms: 1716918735116,
          },
          {
            leverage: -2.5,
            order_type: "SHORT",
            order_uuid: "a56301a3-b6fc-4bbe-9227-1b92f9b0f42e",
            price: 1.36647,
            price_sources: [],
            processed_ms: 1716960756407,
          },
          {
            leverage: -2.49,
            order_type: "SHORT",
            order_uuid: "d05c9018-9960-40c6-9ff4-6a44e84f0f2f",
            price: 1.36611,
            price_sources: [],
            processed_ms: 1716963777158,
          },
          {
            leverage: 2.0,
            order_type: "LONG",
            order_uuid: "05c8c192-be5d-45dc-8e11-9c72cfaaacb2",
            price: 1.37308,
            price_sources: [],
            processed_ms: 1717052507476,
          },
          {
            leverage: -2.0,
            order_type: "SHORT",
            order_uuid: "4b82cb48-3f7d-4a21-89d1-0f24430b2a02",
            price: 1.37178,
            price_sources: [],
            processed_ms: 1717058562140,
          },
          {
            leverage: 0.99,
            order_type: "FLAT",
            order_uuid: "f31c2b1d-1d70-411d-9d2a-1ec701de3ac0",
            price: 1.368,
            price_sources: [],
            processed_ms: 1717104937475,
          },
        ],
        position_type: "FLAT",
        position_uuid: "f0e6ac78-d135-4a11-b6d0-df5ef62c85a9",
        return_at_close: 1.009942036550256,
        trade_pair: ["USDCAD", "USD/CAD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 1.4796080693069296,
        close_ms: 1717160405818,
        current_return: 1.0026726063165856,
        initial_entry_price: 1.48213,
        is_closed_position: true,
        miner_hotkey: "5FTR8y26ap56vvahaxbB4PYxSkTQFpkQDqZN32uTVcW9cKjy",
        net_leverage: 0.0,
        open_ms: 1716960672846,
        orders: [
          {
            leverage: 5.0,
            order_type: "LONG",
            order_uuid: "aa5b7df3-b86e-4795-8b5d-b2e7fba1116b",
            price: 1.48213,
            price_sources: [],
            processed_ms: 1716960672390,
          },
          {
            leverage: -2.5,
            order_type: "SHORT",
            order_uuid: "73986335-b1fd-4092-b68b-ea6a94de5e72",
            price: 1.48199,
            price_sources: [],
            processed_ms: 1717098593703,
          },
          {
            leverage: 10.0,
            order_type: "LONG",
            order_uuid: "348a6e02-812b-4d10-8ebd-75f6b50dac86",
            price: 1.479125,
            price_sources: [],
            processed_ms: 1717142655194,
          },
          {
            leverage: -11.5,
            order_type: "SHORT",
            order_uuid: "d42fb0e7-7c06-4363-a9d9-6e4250025bdd",
            price: 1.479845,
            price_sources: [],
            processed_ms: 1717145911399,
          },
          {
            leverage: -0.99,
            order_type: "SHORT",
            order_uuid: "34defcfe-35ab-4ddf-a2ce-82ad9dc3139e",
            price: 1.480165,
            price_sources: [],
            processed_ms: 1717146024934,
          },
          {
            leverage: 1.0,
            order_type: "LONG",
            order_uuid: "3f52aa66-1874-40fa-be63-3c18cc01405c",
            price: 1.48106,
            price_sources: [],
            processed_ms: 1717146143177,
          },
          {
            leverage: 0.01,
            order_type: "FLAT",
            order_uuid: "0f86d2de-ffb0-4389-b8f8-c9f35723a48f",
            price: 1.48353,
            price_sources: [],
            processed_ms: 1717160405818,
          },
        ],
        position_type: "FLAT",
        position_uuid: "46eafc94-b580-4b95-bf45-8a041e2c1e60",
        return_at_close: 1.001549612997511,
        trade_pair: ["EURCAD", "EUR/CAD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 379.37400000001514,
        close_ms: 1717414320116,
        current_return: 1.0142278650824077,
        initial_entry_price: 156.54,
        is_closed_position: true,
        miner_hotkey: "5FTR8y26ap56vvahaxbB4PYxSkTQFpkQDqZN32uTVcW9cKjy",
        net_leverage: 0.0,
        open_ms: 1717053249937,
        orders: [
          {
            leverage: -2.0,
            order_type: "SHORT",
            order_uuid: "ab9297c6-e014-463a-988a-3b02a0c8c4e2",
            price: 156.54,
            price_sources: [],
            processed_ms: 1717053249128,
          },
          {
            leverage: 1.0,
            order_type: "LONG",
            order_uuid: "4b177e23-1616-4d91-9672-5de376b72c9a",
            price: 156.909,
            price_sources: [],
            processed_ms: 1717054932758,
          },
          {
            leverage: -10.0,
            order_type: "SHORT",
            order_uuid: "0ced626b-9329-4b7c-8557-eecc36758757",
            price: 156.947,
            price_sources: [],
            processed_ms: 1717060675724,
          },
          {
            leverage: 10.99,
            order_type: "LONG",
            order_uuid: "59d4b0e0-11cf-448d-98ba-43953eb7359b",
            price: 156.674,
            price_sources: [],
            processed_ms: 1717086486241,
          },
          {
            leverage: 0.01,
            order_type: "FLAT",
            order_uuid: "1bda2f34-98ba-4751-a1b9-f807a26800a5",
            price: 156.651,
            price_sources: [],
            processed_ms: 1717414320116,
          },
        ],
        position_type: "FLAT",
        position_uuid: "3d8e1b0c-5cf1-460a-936b-81f6588ad2b4",
        return_at_close: 1.0133759136757385,
        trade_pair: ["USDJPY", "USD/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.66568,
        close_ms: 1717607840511,
        current_return: 1.0009802006970316,
        initial_entry_price: 0.66568,
        is_closed_position: true,
        miner_hotkey: "5FTR8y26ap56vvahaxbB4PYxSkTQFpkQDqZN32uTVcW9cKjy",
        net_leverage: 0.0,
        open_ms: 1717573788242,
        orders: [
          {
            leverage: -0.5,
            order_type: "SHORT",
            order_uuid: "bb5fb791-7a03-4bd1-9494-1e2e1c63ac95",
            price: 0.66568,
            price_sources: [],
            processed_ms: 1717573788236,
          },
          {
            leverage: 0.01,
            order_type: "FLAT",
            order_uuid: "1ef00af2-869c-40b9-85c0-67ea60c4fbeb",
            price: 0.6643749999999999,
            price_sources: [],
            processed_ms: 1717607840511,
          },
        ],
        position_type: "FLAT",
        position_uuid: "f41dfdb4-b0e9-4a78-88f6-5f2d23e35f51",
        return_at_close: 1.0009451663900073,
        trade_pair: ["AUDUSD", "AUD/USD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 1.1005099999999992,
        close_ms: 1717651212263,
        current_return: 1.002193163569626,
        initial_entry_price: 1.08975,
        is_closed_position: true,
        miner_hotkey: "5FTR8y26ap56vvahaxbB4PYxSkTQFpkQDqZN32uTVcW9cKjy",
        net_leverage: 0.0,
        open_ms: 1717441633686,
        orders: [
          {
            leverage: -1.0,
            order_type: "SHORT",
            order_uuid: "1843883d-0354-49f7-b92e-f5e1228698d1",
            price: 1.08975,
            price_sources: [],
            processed_ms: 1717441630428,
          },
          {
            leverage: 0.8,
            order_type: "LONG",
            order_uuid: "5180240b-6567-443a-8130-e230b5321996",
            price: 1.0870600000000001,
            price_sources: [],
            processed_ms: 1717509282859,
          },
          {
            leverage: 0.01,
            order_type: "FLAT",
            order_uuid: "c45747b3-f16b-4dab-be57-aa520c894370",
            price: 1.08856,
            price_sources: [],
            processed_ms: 1717651212263,
          },
        ],
        position_type: "FLAT",
        position_uuid: "0844160c-86d9-434b-8cc2-2cb82433d10d",
        return_at_close: 1.0021230100481762,
        trade_pair: ["EURUSD", "EUR/USD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 199.57174771368364,
        close_ms: 1717743742561,
        current_return: 1.0040243888653662,
        initial_entry_price: 198.84949999999998,
        is_closed_position: true,
        miner_hotkey: "5FTR8y26ap56vvahaxbB4PYxSkTQFpkQDqZN32uTVcW9cKjy",
        net_leverage: 0.0,
        open_ms: 1717052439415,
        orders: [
          {
            leverage: -2.0,
            order_type: "SHORT",
            order_uuid: "986dfd93-ef45-4194-bcb2-3147bb9b36db",
            price: 198.84949999999998,
            price_sources: [],
            processed_ms: 1717052439410,
          },
          {
            leverage: -10.0,
            order_type: "SHORT",
            order_uuid: "52ea5a74-c2fc-44a4-953e-b2aa11564b30",
            price: 199.638,
            price_sources: [],
            processed_ms: 1717097920834,
          },
          {
            leverage: 5.0,
            order_type: "LONG",
            order_uuid: "7f23942d-cef5-4d8e-afb2-1a267eb52ebd",
            price: 199.616,
            price_sources: [],
            processed_ms: 1717098779337,
          },
          {
            leverage: 4.5,
            order_type: "LONG",
            order_uuid: "3d563863-1d1a-4495-b9f3-3f546818fe75",
            price: 199.536,
            price_sources: [],
            processed_ms: 1717137213031,
          },
          {
            leverage: -10.0,
            order_type: "SHORT",
            order_uuid: "a991bd53-b86a-498f-a753-0bec867b7f48",
            price: 200.15,
            price_sources: [],
            processed_ms: 1717151079887,
          },
          {
            leverage: -12.5,
            order_type: "SHORT",
            order_uuid: "4412a919-6003-4f85-8ad5-190abfb6ce4e",
            price: 200.149,
            price_sources: [],
            processed_ms: 1717151196487,
          },
          {
            leverage: 12.5,
            order_type: "LONG",
            order_uuid: "9d433357-e366-4ad8-84e5-733977e70451",
            price: 200.0875,
            price_sources: [],
            processed_ms: 1717151803888,
          },
          {
            leverage: 11.5,
            order_type: "LONG",
            order_uuid: "d6ca36b6-9c3b-4c7f-ad6d-2771fc6bd867",
            price: 200.06817411185364,
            price_sources: [],
            processed_ms: 1717152082572,
          },
          {
            leverage: 0.1,
            order_type: "FLAT",
            order_uuid: "65dd18c6-e201-4367-afad-39d3c2d26fba",
            price: 198.7715,
            price_sources: [],
            processed_ms: 1717743742561,
          },
        ],
        position_type: "FLAT",
        position_uuid: "5ca7577f-e05c-44dc-876a-d74b0e3dde08",
        return_at_close: 1.0015996699662564,
        trade_pair: ["GBPJPY", "GBP/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 103.663,
        close_ms: 1717750778413,
        current_return: 1.0011238339619728,
        initial_entry_price: 103.663,
        is_closed_position: true,
        miner_hotkey: "5FTR8y26ap56vvahaxbB4PYxSkTQFpkQDqZN32uTVcW9cKjy",
        net_leverage: 0.0,
        open_ms: 1717485621419,
        orders: [
          {
            leverage: -1.0,
            order_type: "SHORT",
            order_uuid: "cd903039-a5a6-447b-bade-1eebaa398ede",
            price: 103.663,
            price_sources: [],
            processed_ms: 1717485621408,
          },
          {
            leverage: 0.01,
            order_type: "FLAT",
            order_uuid: "260a6884-cc2d-42a5-afb2-ad0d7bc30a58",
            price: 103.54650000000001,
            price_sources: [],
            processed_ms: 1717750778413,
          },
        ],
        position_type: "FLAT",
        position_uuid: "e93be223-cb33-46b4-8985-b74365038a04",
        return_at_close: 1.0010537552935954,
        trade_pair: ["AUDJPY", "AUD/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 3705.45,
        close_ms: 1718006394670,
        current_return: 0.9969585340511948,
        initial_entry_price: 3705.45,
        is_closed_position: true,
        miner_hotkey: "5FTR8y26ap56vvahaxbB4PYxSkTQFpkQDqZN32uTVcW9cKjy",
        net_leverage: 0.0,
        open_ms: 1717832548924,
        orders: [
          {
            leverage: 0.2,
            order_type: "LONG",
            order_uuid: "ddc882e7-2751-4a90-9d8c-495c07f6f524",
            price: 3705.45,
            price_sources: [],
            processed_ms: 1717832548922,
          },
          {
            leverage: 0.01,
            order_type: "FLAT",
            order_uuid: "5b582ea1-abca-4f39-b11b-2d9a23fd0ab9",
            price: 3649.1,
            price_sources: [],
            processed_ms: 1718006394670,
          },
        ],
        position_type: "FLAT",
        position_uuid: "e4402a6c-0405-4e9e-ac16-9adc2f6d2c1f",
        return_at_close: 0.9967591423443846,
        trade_pair: ["ETHUSD", "ETH/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 1.07547,
        close_ms: 1718087998591,
        current_return: 1.0006834221317191,
        initial_entry_price: 1.07547,
        is_closed_position: true,
        miner_hotkey: "5FTR8y26ap56vvahaxbB4PYxSkTQFpkQDqZN32uTVcW9cKjy",
        net_leverage: 0.0,
        open_ms: 1717998979005,
        orders: [
          {
            leverage: 0.5,
            order_type: "LONG",
            order_uuid: "a0e1afb1-27c6-4fa6-843c-be6dc6fce4d3",
            price: 1.07547,
            price_sources: [],
            processed_ms: 1717998979002,
          },
          {
            leverage: 0.01,
            order_type: "FLAT",
            order_uuid: "9d235714-e2ad-426f-90e0-9e7638a6cf0c",
            price: 1.07694,
            price_sources: [],
            processed_ms: 1718087998591,
          },
        ],
        position_type: "FLAT",
        position_uuid: "80a08176-ab8a-4364-85ac-f0f2f6a3f585",
        return_at_close: 1.0006483982119445,
        trade_pair: ["EURUSD", "EUR/USD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.842965,
        close_ms: 1718129367276,
        current_return: 1.000440897405267,
        initial_entry_price: 0.84289,
        is_closed_position: true,
        miner_hotkey: "5FTR8y26ap56vvahaxbB4PYxSkTQFpkQDqZN32uTVcW9cKjy",
        net_leverage: 0.0,
        open_ms: 1718048622434,
        orders: [
          {
            leverage: 0.5,
            order_type: "LONG",
            order_uuid: "dbef949a-65c1-4730-9d11-258e85f7aa92",
            price: 0.84289,
            price_sources: [],
            processed_ms: 1718048622420,
          },
          {
            leverage: -0.3,
            order_type: "SHORT",
            order_uuid: "8b23d996-140b-4b9f-823f-14b5e161493c",
            price: 0.84284,
            price_sources: [],
            processed_ms: 1718088049571,
          },
          {
            leverage: 0.01,
            order_type: "FLAT",
            order_uuid: "8720d545-47b4-4d92-8a7d-530f60b897ce",
            price: 0.844823140069627,
            price_sources: [],
            processed_ms: 1718129367276,
          },
        ],
        position_type: "FLAT",
        position_uuid: "f05eaf75-bb87-40fc-a1ce-b977a73de63c",
        return_at_close: 1.0004058819738577,
        trade_pair: ["NZDCAD", "NZD/CAD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 155.1749801980198,
        close_ms: 1718129465336,
        current_return: 0.9875628707494359,
        initial_entry_price: 155.597,
        is_closed_position: true,
        miner_hotkey: "5FTR8y26ap56vvahaxbB4PYxSkTQFpkQDqZN32uTVcW9cKjy",
        net_leverage: 0.0,
        open_ms: 1717485570989,
        orders: [
          {
            leverage: -1.0,
            order_type: "SHORT",
            order_uuid: "b4ae2e0c-32bb-46cf-857f-e0fba8d386b5",
            price: 155.597,
            price_sources: [],
            processed_ms: 1717485570986,
          },
          {
            leverage: 0.5,
            order_type: "LONG",
            order_uuid: "f94f9f97-9162-4675-bc22-34b04520bfbe",
            price: 156.416,
            price_sources: [],
            processed_ms: 1717596734968,
          },
          {
            leverage: -0.5,
            order_type: "SHORT",
            order_uuid: "9aac878b-c389-4aca-b224-4443598d04ed",
            price: 155.8505,
            price_sources: [],
            processed_ms: 1717651116683,
          },
          {
            leverage: 0.75,
            order_type: "LONG",
            order_uuid: "ddc66f23-525e-43aa-9126-4253541eadb2",
            price: 155.868,
            price_sources: [],
            processed_ms: 1717652145802,
          },
          {
            leverage: -0.75,
            order_type: "SHORT",
            order_uuid: "ca0302b7-0047-4ee6-ab42-8519564841ae",
            price: 155.325,
            price_sources: [],
            processed_ms: 1717743788853,
          },
          {
            leverage: 0.5,
            order_type: "LONG",
            order_uuid: "267746f8-5d6a-4cc8-9352-8f7465f9b622",
            price: 157.01,
            price_sources: [],
            processed_ms: 1717999275916,
          },
          {
            leverage: 0.49,
            order_type: "LONG",
            order_uuid: "ffc3d8a8-02c1-43c0-a126-ead192824be7",
            price: 157.023,
            price_sources: [],
            processed_ms: 1718048531361,
          },
          {
            leverage: -1.0,
            order_type: "SHORT",
            order_uuid: "0430e4a6-d58f-4d78-9888-67b16beac146",
            price: 157.266,
            price_sources: [],
            processed_ms: 1718087951772,
          },
          {
            leverage: 0.01,
            order_type: "FLAT",
            order_uuid: "892a7ab0-d017-4e54-a796-73be1b999658",
            price: 157.091,
            price_sources: [],
            processed_ms: 1718129465336,
          },
        ],
        position_type: "FLAT",
        position_uuid: "e8b49631-6805-410b-ba0e-ae038e06c934",
        return_at_close: 0.9873382001963404,
        trade_pair: ["USDJPY", "USD/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 1.0732658756866467,
        close_ms: 1718195160376,
        current_return: 0.9986542656183617,
        initial_entry_price: 1.0750908756866466,
        is_closed_position: true,
        miner_hotkey: "5FTR8y26ap56vvahaxbB4PYxSkTQFpkQDqZN32uTVcW9cKjy",
        net_leverage: 0.0,
        open_ms: 1717651859576,
        orders: [
          {
            leverage: -0.5,
            order_type: "SHORT",
            order_uuid: "889b2c1c-c01c-4874-bae2-0db76207016c",
            price: 1.0750908756866466,
            price_sources: [],
            processed_ms: 1717651859572,
          },
          {
            leverage: -0.5,
            order_type: "SHORT",
            order_uuid: "0fee5bd5-abc7-4114-9fbb-391e3ea73c48",
            price: 1.0772650000000001,
            price_sources: [],
            processed_ms: 1717743819324,
          },
          {
            leverage: 0.5,
            order_type: "LONG",
            order_uuid: "aead298a-dcfd-4579-a6db-daef05089502",
            price: 1.07909,
            price_sources: [],
            processed_ms: 1718048477283,
          },
          {
            leverage: 0.5,
            order_type: "FLAT",
            order_uuid: "51597dce-c690-4d6f-abef-d43d192265a4",
            price: 1.076159449196241,
            price_sources: [],
            processed_ms: 1718195160376,
          },
        ],
        position_type: "FLAT",
        position_uuid: "5f092448-ac2c-4bbf-9619-e79ca12629a3",
        return_at_close: 0.9985843598197683,
        trade_pair: ["AUDNZD", "AUD/NZD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.65218,
        close_ms: 1718196763071,
        current_return: 1.0009813241743077,
        initial_entry_price: 0.65218,
        is_closed_position: true,
        miner_hotkey: "5FTR8y26ap56vvahaxbB4PYxSkTQFpkQDqZN32uTVcW9cKjy",
        net_leverage: 0.0,
        open_ms: 1718129540490,
        orders: [
          {
            leverage: -0.5,
            order_type: "SHORT",
            order_uuid: "f418abf9-fc57-4c97-85e5-b25bdc85b7ea",
            price: 0.65218,
            price_sources: [],
            processed_ms: 1718129540460,
          },
          {
            leverage: 0.5,
            order_type: "FLAT",
            order_uuid: "ba949a45-9520-449a-a42f-11941e71acfc",
            price: 0.6509,
            price_sources: [],
            processed_ms: 1718196763071,
          },
        ],
        position_type: "FLAT",
        position_uuid: "761cd2d7-e69f-4cd0-967d-525a994ab27b",
        return_at_close: 1.0009462898279615,
        trade_pair: ["CADCHF", "CAD/CHF", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 1.0742,
        close_ms: 1718199628387,
        current_return: 1.0038959225470117,
        initial_entry_price: 1.0742,
        is_closed_position: true,
        miner_hotkey: "5FTR8y26ap56vvahaxbB4PYxSkTQFpkQDqZN32uTVcW9cKjy",
        net_leverage: 0.0,
        open_ms: 1718129587468,
        orders: [
          {
            leverage: 0.5,
            order_type: "LONG",
            order_uuid: "2fd33def-5338-4655-9d69-68c41d93c7e0",
            price: 1.0742,
            price_sources: [],
            processed_ms: 1718129587444,
          },
          {
            leverage: 0.01,
            order_type: "FLAT",
            order_uuid: "7731136e-1cab-4c16-a377-6edaf4213713",
            price: 1.08257,
            price_sources: [],
            processed_ms: 1718199628387,
          },
        ],
        position_type: "FLAT",
        position_uuid: "2a996590-c16f-4f02-aa3a-0b9d44ad498b",
        return_at_close: 1.0038607861897224,
        trade_pair: ["EURUSD", "EUR/USD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.843245,
        close_ms: 1718263278222,
        current_return: 1.0005419539991343,
        initial_entry_price: 0.843245,
        is_closed_position: true,
        miner_hotkey: "5FTR8y26ap56vvahaxbB4PYxSkTQFpkQDqZN32uTVcW9cKjy",
        net_leverage: 0.0,
        open_ms: 1718199598294,
        orders: [
          {
            leverage: 0.2,
            order_type: "LONG",
            order_uuid: "65a60227-d114-472e-ae0a-499920dfc2d5",
            price: 0.843245,
            price_sources: [],
            processed_ms: 1718199598242,
          },
          {
            leverage: 0.1,
            order_type: "FLAT",
            order_uuid: "dc00f83a-c728-4d82-9b23-4bfd92767ea0",
            price: 0.84553,
            price_sources: [],
            processed_ms: 1718263278222,
          },
        ],
        position_type: "FLAT",
        position_uuid: "0f29b5f1-007f-41eb-a838-9b32581f1ea5",
        return_at_close: 1.0005279464117782,
        trade_pair: ["EURGBP", "EUR/GBP", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 1.0616699999999992,
        close_ms: 1718282239415,
        current_return: 1.0017346174127835,
        initial_entry_price: 1.08093,
        is_closed_position: true,
        miner_hotkey: "5FTR8y26ap56vvahaxbB4PYxSkTQFpkQDqZN32uTVcW9cKjy",
        net_leverage: 0.0,
        open_ms: 1718263325730,
        orders: [
          {
            leverage: 0.1,
            order_type: "LONG",
            order_uuid: "f0a64f6f-2d5d-4cdc-8a0d-8492bd343244",
            price: 1.08093,
            price_sources: [],
            processed_ms: 1718263325712,
          },
          {
            leverage: 0.9,
            order_type: "LONG",
            order_uuid: "2d207d27-763e-472e-ac00-d061900616ad",
            price: 1.07842,
            price_sources: [],
            processed_ms: 1718280690105,
          },
          {
            leverage: -0.9,
            order_type: "SHORT",
            order_uuid: "78f06e96-1a9e-4c5b-bf05-72dfbb1f88be",
            price: 1.08056,
            price_sources: [],
            processed_ms: 1718282197989,
          },
          {
            leverage: 0.01,
            order_type: "FLAT",
            order_uuid: "2a9786cc-491d-42de-87ac-a8d82291c416",
            price: 1.08042,
            price_sources: [],
            processed_ms: 1718282239415,
          },
        ],
        position_type: "FLAT",
        position_uuid: "69868061-aed0-49c1-a8f3-220bee6663c4",
        return_at_close: 1.0016644959895646,
        trade_pair: ["EURUSD", "EUR/USD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 97.0335,
        close_ms: 1718345530490,
        current_return: 1.00014273421035,
        initial_entry_price: 97.0335,
        is_closed_position: true,
        miner_hotkey: "5FTR8y26ap56vvahaxbB4PYxSkTQFpkQDqZN32uTVcW9cKjy",
        net_leverage: 0.0,
        open_ms: 1718282779421,
        orders: [
          {
            leverage: 0.1,
            order_type: "LONG",
            order_uuid: "74af7d78-d5b5-4d1e-9006-06abbad0b46e",
            price: 97.0335,
            price_sources: [],
            processed_ms: 1718282779375,
          },
          {
            leverage: 0.01,
            order_type: "FLAT",
            order_uuid: "8174b677-9937-4f8d-bcf1-3a0a48642ae9",
            price: 97.172,
            price_sources: [],
            processed_ms: 1718345530490,
          },
        ],
        position_type: "FLAT",
        position_uuid: "aaaafb01-e14b-458b-94c8-7a46bef3be93",
        return_at_close: 1.0001357332112104,
        trade_pair: ["NZDJPY", "NZD/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 115.074,
        close_ms: 1718390242917,
        current_return: 1.0005535568416846,
        initial_entry_price: 115.074,
        is_closed_position: true,
        miner_hotkey: "5FTR8y26ap56vvahaxbB4PYxSkTQFpkQDqZN32uTVcW9cKjy",
        net_leverage: 0.0,
        open_ms: 1718345564140,
        orders: [
          {
            leverage: -0.1,
            order_type: "SHORT",
            order_uuid: "d6e359ad-0cc9-4882-8d55-433f1c3b3dc4",
            price: 115.074,
            price_sources: [],
            processed_ms: 1718345564120,
          },
          {
            leverage: 0.01,
            order_type: "FLAT",
            order_uuid: "45ba08f1-61ee-43e7-95ab-53052fe0e8eb",
            price: 114.437,
            price_sources: [],
            processed_ms: 1718390242917,
          },
        ],
        position_type: "FLAT",
        position_uuid: "e0f73504-790a-48dd-9106-ee45850715c2",
        return_at_close: 1.0005465529667867,
        trade_pair: ["CADJPY", "CAD/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 3383.47,
        close_ms: 1718571528740,
        current_return: 1.0031693793649714,
        initial_entry_price: 3383.47,
        is_closed_position: true,
        miner_hotkey: "5FTR8y26ap56vvahaxbB4PYxSkTQFpkQDqZN32uTVcW9cKjy",
        net_leverage: 0.0,
        open_ms: 1718390033416,
        orders: [
          {
            leverage: 0.05,
            order_type: "LONG",
            order_uuid: "e398a0f3-1b2f-4824-8ca4-c37038b9ab90",
            price: 3383.47,
            price_sources: [],
            processed_ms: 1718390032988,
          },
          {
            leverage: 0.01,
            order_type: "FLAT",
            order_uuid: "efe06e9c-1416-4709-a89d-5a3f962bd888",
            price: 3597.94,
            price_sources: [],
            processed_ms: 1718571528740,
          },
        ],
        position_type: "FLAT",
        position_uuid: "87edc464-f426-4aea-a69f-40c79bd937d8",
        return_at_close: 1.0031192208960031,
        trade_pair: ["ETHUSD", "ETH/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 0.659805,
        close_ms: 1718787720535,
        current_return: 0.9983551958533202,
        initial_entry_price: 0.659805,
        is_closed_position: true,
        miner_hotkey: "5FTR8y26ap56vvahaxbB4PYxSkTQFpkQDqZN32uTVcW9cKjy",
        net_leverage: 0.0,
        open_ms: 1718371639337,
        orders: [
          {
            leverage: -0.15,
            order_type: "SHORT",
            order_uuid: "b73407b6-888b-4f14-931b-0e5375dd43d0",
            price: 0.659805,
            price_sources: [],
            processed_ms: 1718371639238,
          },
          {
            leverage: 0.01,
            order_type: "FLAT",
            order_uuid: "0b34714e-b4e7-422d-b4ae-6ba4aa923e47",
            price: 0.6670400000000001,
            price_sources: [],
            processed_ms: 1718787720535,
          },
        ],
        position_type: "FLAT",
        position_uuid: "e888454c-4b15-45b6-a70f-aab7d0dedf5b",
        return_at_close: 0.9983447131237637,
        trade_pair: ["AUDUSD", "AUD/USD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 65027.668,
        close_ms: 1718870468153,
        current_return: 1.0076242982083514,
        initial_entry_price: 65208.1,
        is_closed_position: true,
        miner_hotkey: "5FTR8y26ap56vvahaxbB4PYxSkTQFpkQDqZN32uTVcW9cKjy",
        net_leverage: 0.0,
        open_ms: 1718787728268,
        orders: [
          {
            leverage: 0.05,
            order_type: "LONG",
            order_uuid: "3bb0c83e-fe4e-48cc-bf23-7968f111799a",
            price: 65208.1,
            price_sources: [],
            processed_ms: 1718787725369,
          },
          {
            leverage: 0.45,
            order_type: "LONG",
            order_uuid: "1a3f1aa2-3367-42f3-9f5b-6e8dbec245d3",
            price: 65007.62,
            price_sources: [],
            processed_ms: 1718814654699,
          },
          {
            leverage: 0.01,
            order_type: "FLAT",
            order_uuid: "2d319a49-9061-4db6-84b4-c72043e631c6",
            price: 66022.0,
            price_sources: [],
            processed_ms: 1718870468153,
          },
        ],
        position_type: "FLAT",
        position_uuid: "eb3068d1-0b49-426a-993e-a6ed02838602",
        return_at_close: 1.0071204860592473,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 0.87679,
        close_ms: 1718872342092,
        current_return: 1.0078723669168166,
        initial_entry_price: 0.88347,
        is_closed_position: true,
        miner_hotkey: "5FTR8y26ap56vvahaxbB4PYxSkTQFpkQDqZN32uTVcW9cKjy",
        net_leverage: 0.0,
        open_ms: 1718863596658,
        orders: [
          {
            leverage: 1.0,
            order_type: "LONG",
            order_uuid: "59b61cc2-fe11-4bb4-9f03-270c7561d0c5",
            price: 0.88347,
            price_sources: [],
            processed_ms: 1718863596381,
          },
          {
            leverage: -0.5,
            order_type: "SHORT",
            order_uuid: "6769eb21-a68f-4a9a-91d5-4b97d5998407",
            price: 0.89015,
            price_sources: [],
            processed_ms: 1718871336422,
          },
          {
            leverage: 0.01,
            order_type: "FLAT",
            order_uuid: "df5de308-300d-4c4d-914d-63dd1d3d7cf6",
            price: 0.8907,
            price_sources: [],
            processed_ms: 1718872342092,
          },
        ],
        position_type: "FLAT",
        position_uuid: "14ac2735-2fed-4b00-a69b-ebede790a1c9",
        return_at_close: 1.0078018158511324,
        trade_pair: ["USDCHF", "USD/CHF", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.66566,
        close_ms: 1718906416372,
        current_return: 1.0001051587897727,
        initial_entry_price: 0.66566,
        is_closed_position: true,
        miner_hotkey: "5FTR8y26ap56vvahaxbB4PYxSkTQFpkQDqZN32uTVcW9cKjy",
        net_leverage: 0.0,
        open_ms: 1718897444185,
        orders: [
          {
            leverage: -0.5,
            order_type: "SHORT",
            order_uuid: "016deb43-0eac-4884-afd2-2b5453371991",
            price: 0.66566,
            price_sources: [],
            processed_ms: 1718897444136,
          },
          {
            leverage: 0.01,
            order_type: "FLAT",
            order_uuid: "0a8e4d0c-6714-4775-8b5d-67a052f2b0d8",
            price: 0.66552,
            price_sources: [],
            processed_ms: 1718906416372,
          },
        ],
        position_type: "FLAT",
        position_uuid: "e904d668-bc87-4703-9b8f-b6bc3fb02897",
        return_at_close: 1.000070155109215,
        trade_pair: ["AUDUSD", "AUD/USD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 1.2647,
        close_ms: 1718956959200,
        current_return: 1.0002530244326717,
        initial_entry_price: 1.2647,
        is_closed_position: true,
        miner_hotkey: "5FTR8y26ap56vvahaxbB4PYxSkTQFpkQDqZN32uTVcW9cKjy",
        net_leverage: 0.0,
        open_ms: 1718953964021,
        orders: [
          {
            leverage: -0.5,
            order_type: "SHORT",
            order_uuid: "5ea24ae3-22b2-4e6a-9741-4a041e9c1e90",
            price: 1.2647,
            price_sources: [],
            processed_ms: 1718953963900,
          },
          {
            leverage: 0.5,
            order_type: "FLAT",
            order_uuid: "7493a426-0315-45a5-a441-a8471e2bec9c",
            price: 1.26406,
            price_sources: [],
            processed_ms: 1718956959200,
          },
        ],
        position_type: "FLAT",
        position_uuid: "a7db60a1-74e1-4e89-b60f-e7bb72cdb48a",
        return_at_close: 1.0002180155768166,
        trade_pair: ["GBPUSD", "GBP/USD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 63780.35,
        close_ms: 1719176931631,
        current_return: 1.0006111600202883,
        initial_entry_price: 63780.35,
        is_closed_position: true,
        miner_hotkey: "5FTR8y26ap56vvahaxbB4PYxSkTQFpkQDqZN32uTVcW9cKjy",
        net_leverage: 0.0,
        open_ms: 1718994231151,
        orders: [
          {
            leverage: -0.25,
            order_type: "SHORT",
            order_uuid: "6a2dbb16-c3d3-4f21-b9f5-17d92650dd39",
            price: 63780.35,
            price_sources: [],
            processed_ms: 1718994229458,
          },
          {
            leverage: 0.01,
            order_type: "FLAT",
            order_uuid: "70ce8a27-3798-45f9-bb1d-75bbfde3e363",
            price: 63624.43,
            price_sources: [],
            processed_ms: 1719176931631,
          },
        ],
        position_type: "FLAT",
        position_uuid: "07f0a579-6c88-49d5-ba49-2cb05d891491",
        return_at_close: 1.0003610072302833,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 62510.48,
        close_ms: 1719225251974,
        current_return: 0.994743801359388,
        initial_entry_price: 62510.48,
        is_closed_position: true,
        miner_hotkey: "5FTR8y26ap56vvahaxbB4PYxSkTQFpkQDqZN32uTVcW9cKjy",
        net_leverage: 0.0,
        open_ms: 1719215317595,
        orders: [
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "10779de8-7f53-4ac8-a9c0-db6181f50e43",
            price: 62510.48,
            price_sources: [],
            processed_ms: 1719215317577,
          },
          {
            leverage: 0.01,
            order_type: "FLAT",
            order_uuid: "4af22d55-54c3-4a8d-b422-ae6a33dee581",
            price: 61196.21,
            price_sources: [],
            processed_ms: 1719225251974,
          },
        ],
        position_type: "FLAT",
        position_uuid: "21d7d7ac-d518-42a3-8363-faa3c9ed0f6b",
        return_at_close: 0.9944951154090482,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 3279.78,
        close_ms: 1719322095845,
        current_return: 0.993165700138424,
        initial_entry_price: 3279.78,
        is_closed_position: true,
        miner_hotkey: "5FTR8y26ap56vvahaxbB4PYxSkTQFpkQDqZN32uTVcW9cKjy",
        net_leverage: 0.0,
        open_ms: 1719252567418,
        orders: [
          {
            leverage: -0.25,
            order_type: "SHORT",
            order_uuid: "6e1cdf1e-45da-480b-b422-a4b825dbf48a",
            price: 3279.78,
            price_sources: [],
            processed_ms: 1719252565867,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "9c9bdea4-5612-4d2d-ad34-dd6ce4659448",
            price: 3369.44,
            price_sources: [],
            processed_ms: 1719322095845,
          },
        ],
        position_type: "FLAT",
        position_uuid: "8024b6d6-8f7f-4bc3-99fe-e2669a2a4221",
        return_at_close: 0.9929174087133894,
        trade_pair: ["ETHUSD", "ETH/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 158.64125,
        close_ms: 1719957307958,
        current_return: 0.982236482839385,
        initial_entry_price: 158.40050000000002,
        is_closed_position: true,
        miner_hotkey: "5FTR8y26ap56vvahaxbB4PYxSkTQFpkQDqZN32uTVcW9cKjy",
        net_leverage: 0.0,
        open_ms: 1718872372321,
        orders: [
          {
            leverage: -0.5,
            order_type: "SHORT",
            order_uuid: "447a7d9b-1860-4388-a799-3b60f24d9e2c",
            price: 158.40050000000002,
            price_sources: [],
            processed_ms: 1718872372300,
          },
          {
            leverage: -0.5,
            order_type: "SHORT",
            order_uuid: "66dc4bc1-5e1d-4086-85d9-5a9b9bad4ba2",
            price: 158.882,
            price_sources: [],
            processed_ms: 1718906382231,
          },
          {
            leverage: 0.01,
            order_type: "FLAT",
            order_uuid: "dfb63a7b-df0d-45d6-a510-1996b48bf2eb",
            price: 161.455,
            price_sources: [],
            processed_ms: 1719957307958,
          },
        ],
        position_type: "FLAT",
        position_uuid: "fc1b0506-bcfa-4d4e-b98e-8160b69c1952",
        return_at_close: 0.9821677262855862,
        trade_pair: ["USDJPY", "USD/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 1.4597500000000005,
        close_ms: 1719957355584,
        current_return: 0.9966741259315529,
        initial_entry_price: 1.46127,
        is_closed_position: true,
        miner_hotkey: "5FTR8y26ap56vvahaxbB4PYxSkTQFpkQDqZN32uTVcW9cKjy",
        net_leverage: 0.0,
        open_ms: 1719322151360,
        orders: [
          {
            leverage: -1.5,
            order_type: "SHORT",
            order_uuid: "f4c04cc1-6e50-4d35-86e9-ff1e815fa627",
            price: 1.46127,
            price_sources: [],
            processed_ms: 1719322150849,
          },
          {
            leverage: 1.0,
            order_type: "LONG",
            order_uuid: "5fb204f1-03b7-47c9-b739-2cdfc260387a",
            price: 1.46203,
            price_sources: [],
            processed_ms: 1719381359280,
          },
          {
            leverage: 0.01,
            order_type: "FLAT",
            order_uuid: "7d530a2f-f462-4729-921e-c398d773d771",
            price: 1.46947,
            price_sources: [],
            processed_ms: 1719957355584,
          },
        ],
        position_type: "FLAT",
        position_uuid: "4f526eae-2412-4d5d-9a80-3543d18af1cb",
        return_at_close: 0.99656947514833,
        trade_pair: ["EURCAD", "EUR/CAD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 115.51400000000001,
        close_ms: 1720078737129,
        current_return: 0.9874820368093911,
        initial_entry_price: 115.51400000000001,
        is_closed_position: true,
        miner_hotkey: "5FTR8y26ap56vvahaxbB4PYxSkTQFpkQDqZN32uTVcW9cKjy",
        net_leverage: 0.0,
        open_ms: 1718871367847,
        orders: [
          {
            leverage: -0.5,
            order_type: "SHORT",
            order_uuid: "f0cf86d3-0ebb-42b2-a928-4fad83feb688",
            price: 115.51400000000001,
            price_sources: [],
            processed_ms: 1718871367755,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "58682e0b-1ba5-4003-abdf-61893f015afa",
            price: 118.406,
            price_sources: [
              {
                close: 118.406,
                high: 118.406,
                lag_ms: 129,
                low: 118.406,
                open: 118.406,
                source: "TwelveData_ws",
                start_ms: 1720078737000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
              {
                close: 118.4082908044965,
                high: 118.4082908044965,
                lag_ms: 130,
                low: 118.4082908044965,
                open: 118.4082908044965,
                source: "Polygon_ws",
                start_ms: 1720078736999,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 118.412,
                websocket: true,
              },
            ],
            processed_ms: 1720078737129,
          },
        ],
        position_type: "FLAT",
        position_uuid: "6d5a4f02-7a27-48f1-b731-bbd38f64bdd2",
        return_at_close: 0.9874474749381027,
        trade_pair: ["CADJPY", "CAD/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 162.4385000000002,
        close_ms: 1720111739327,
        current_return: 1.0039821554792234,
        initial_entry_price: 161.282,
        is_closed_position: true,
        miner_hotkey: "5FTR8y26ap56vvahaxbB4PYxSkTQFpkQDqZN32uTVcW9cKjy",
        net_leverage: 0.0,
        open_ms: 1720079427478,
        orders: [
          {
            leverage: -5.0,
            order_type: "SHORT",
            order_uuid: "11d83438-66ce-4210-affa-52119f1bac49",
            price: 161.282,
            price_sources: [
              {
                close: 161.282,
                high: 161.282,
                lag_ms: 454,
                low: 161.282,
                open: 161.282,
                source: "TwelveData_ws",
                start_ms: 1720079427000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
              {
                close: 161.279,
                high: 161.279,
                lag_ms: 455,
                low: 161.279,
                open: 161.279,
                source: "Polygon_ws",
                start_ms: 1720079426999,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 161.281,
                websocket: true,
              },
            ],
            processed_ms: 1720079427454,
          },
          {
            leverage: 4.5,
            order_type: "LONG",
            order_uuid: "1da87e9f-3d03-4689-a067-582285bddc57",
            price: 161.1535,
            price_sources: [
              {
                close: 161.1535,
                high: 161.1535,
                lag_ms: 211,
                low: 161.1535,
                open: 161.1535,
                source: "Polygon_ws",
                start_ms: 1720111709000,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 161.154,
                websocket: true,
              },
              {
                close: 161.152,
                high: 161.152,
                lag_ms: 2789,
                low: 161.152,
                open: 161.152,
                source: "TwelveData_ws",
                start_ms: 1720111706000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1720111708789,
          },
          {
            leverage: 4.5,
            order_type: "FLAT",
            order_uuid: "e0b28f82-3f20-4930-8a4f-0496cd2c27ce",
            price: 161.154,
            price_sources: [
              {
                close: 161.154,
                high: 161.154,
                lag_ms: 1327,
                low: 161.154,
                open: 161.154,
                source: "TwelveData_ws",
                start_ms: 1720111738000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
              {
                close: 161.152,
                high: 161.152,
                lag_ms: 1673,
                low: 161.152,
                open: 161.152,
                source: "Polygon_ws",
                start_ms: 1720111741000,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 161.152,
                websocket: true,
              },
              {
                close: 161.152,
                high: 161.152,
                lag_ms: 2328,
                low: 161.152,
                open: 161.152,
                source: "Polygon_rest",
                start_ms: 1720111736000,
                timespan_ms: 1000,
                volume: 2.0,
                vwap: null,
                websocket: false,
              },
              {
                close: 161.155,
                high: 161.16,
                lag_ms: 59328,
                low: 161.155,
                open: 161.155,
                source: "TwelveData_rest",
                start_ms: 1720111620000,
                timespan_ms: 60000,
                volume: null,
                vwap: null,
                websocket: false,
              },
            ],
            processed_ms: 1720111739327,
          },
        ],
        position_type: "FLAT",
        position_uuid: "7eae33d6-a18e-469d-a5ff-c722960c8773",
        return_at_close: 1.0036307617248057,
        trade_pair: ["USDJPY", "USD/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 1.0811,
        close_ms: 1720208993575,
        current_return: 1.002654703542688,
        initial_entry_price: 1.0811,
        is_closed_position: true,
        miner_hotkey: "5FTR8y26ap56vvahaxbB4PYxSkTQFpkQDqZN32uTVcW9cKjy",
        net_leverage: 0.0,
        open_ms: 1720111810625,
        orders: [
          {
            leverage: 1.0,
            order_type: "LONG",
            order_uuid: "9c3db49f-85e0-4f3c-ae81-48f3c34019ed",
            price: 1.0811,
            price_sources: [
              {
                close: 1.0811,
                high: 1.0811,
                lag_ms: 436,
                low: 1.0811,
                open: 1.0811,
                source: "Polygon_ws",
                start_ms: 1720111811000,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 1.0811,
                websocket: true,
              },
              {
                close: 1.0812,
                high: 1.0812,
                lag_ms: 1564,
                low: 1.0812,
                open: 1.0812,
                source: "TwelveData_ws",
                start_ms: 1720111809000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1720111810564,
          },
          {
            leverage: 0.01,
            order_type: "FLAT",
            order_uuid: "8045eba8-a329-4577-9fab-e6a8a6efe338",
            price: 1.08397,
            price_sources: [
              {
                close: 1.08397,
                high: 1.08397,
                lag_ms: 1576,
                low: 1.08397,
                open: 1.08397,
                source: "Polygon_ws",
                start_ms: 1720208991999,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 1.08397,
                websocket: true,
              },
              {
                close: 1.084,
                high: 1.084,
                lag_ms: 5575,
                low: 1.084,
                open: 1.084,
                source: "TwelveData_ws",
                start_ms: 1720208988000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1720208993575,
          },
        ],
        position_type: "FLAT",
        position_uuid: "790993f1-9b30-475f-b6f5-88e9beefc7bd",
        return_at_close: 1.00258451771344,
        trade_pair: ["EURUSD", "EUR/USD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 56653.02,
        close_ms: 1720417253476,
        current_return: 1.0019801239192545,
        initial_entry_price: 56653.02,
        is_closed_position: true,
        miner_hotkey: "5FTR8y26ap56vvahaxbB4PYxSkTQFpkQDqZN32uTVcW9cKjy",
        net_leverage: 0.0,
        open_ms: 1720208958049,
        orders: [
          {
            leverage: -0.1,
            order_type: "SHORT",
            order_uuid: "58a6b8f6-74d4-4bd1-a777-5e9d263ba2d1",
            price: 56653.02,
            price_sources: [
              {
                close: 56653.02,
                high: 56656.27,
                lag_ms: 202,
                low: 56652.74,
                open: 56653.02,
                source: "Polygon_ws",
                start_ms: 1720208956000,
                timespan_ms: 0,
                volume: 5.40170043,
                vwap: 56655.9631,
                websocket: true,
              },
              {
                close: 56626.5,
                high: 56626.5,
                lag_ms: 798,
                low: 56626.5,
                open: 56626.5,
                source: "TwelveData_ws",
                start_ms: 1720208957000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
              {
                close: 56667.0,
                high: 56667.0,
                lag_ms: 3203,
                low: 56662.56,
                open: 56662.57,
                source: "Polygon_rest",
                start_ms: 1720208952000,
                timespan_ms: 1000,
                volume: 0.0761544,
                vwap: 56666.8648,
                websocket: false,
              },
              {
                close: 56648.14,
                high: 56660.17,
                lag_ms: 76203,
                low: 56629.47,
                open: 56648.0,
                source: "TwelveData_rest",
                start_ms: 1720208820000,
                timespan_ms: 60000,
                volume: null,
                vwap: null,
                websocket: false,
              },
            ],
            processed_ms: 1720208956202,
          },
          {
            leverage: 0.01,
            order_type: "FLAT",
            order_uuid: "534d8105-87b6-486b-bda9-0fbacdd0b7de",
            price: 55531.22,
            price_sources: [
              {
                close: 55531.22,
                high: 55540.3,
                lag_ms: 476,
                low: 55523.38,
                open: 55531.22,
                source: "Polygon_ws",
                start_ms: 1720417253000,
                timespan_ms: 0,
                volume: 0.24631766,
                vwap: 55528.2818,
                websocket: true,
              },
              {
                close: 55434.7,
                high: 55434.7,
                lag_ms: 2476,
                low: 55434.7,
                open: 55434.7,
                source: "TwelveData_ws",
                start_ms: 1720417251000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
              {
                close: 55721.0,
                high: 55721.0,
                lag_ms: 2477,
                low: 55479.7,
                open: 55720.0,
                source: "Polygon_rest",
                start_ms: 1720417250000,
                timespan_ms: 1000,
                volume: 0.02309113,
                vwap: 55679.675,
                websocket: false,
              },
              {
                close: 55453.89,
                high: 55453.89,
                lag_ms: 53477,
                low: 55397.28,
                open: 55422.57,
                source: "TwelveData_rest",
                start_ms: 1720417140000,
                timespan_ms: 60000,
                volume: null,
                vwap: null,
                websocket: false,
              },
            ],
            processed_ms: 1720417253476,
          },
        ],
        position_type: "FLAT",
        position_uuid: "7c917743-c45e-4835-bcb4-18b3671eb320",
        return_at_close: 1.0018799259068627,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 1.0806,
        close_ms: 1720417292403,
        current_return: 1.0015315565426615,
        initial_entry_price: 1.0806,
        is_closed_position: true,
        miner_hotkey: "5FTR8y26ap56vvahaxbB4PYxSkTQFpkQDqZN32uTVcW9cKjy",
        net_leverage: 0.0,
        open_ms: 1720386182918,
        orders: [
          {
            leverage: 1.0,
            order_type: "LONG",
            order_uuid: "9671cf80-a42b-4218-996f-f85bd3079c19",
            price: 1.0806,
            price_sources: [
              {
                close: 1.0806,
                high: 1.0806,
                lag_ms: 1849,
                low: 1.0806,
                open: 1.0806,
                source: "TwelveData_ws",
                start_ms: 1720386181000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
              {
                close: 1.08026,
                high: 1.08026,
                lag_ms: 1850,
                low: 1.08026,
                open: 1.08026,
                source: "Polygon_ws",
                start_ms: 1720386180999,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 1.08026,
                websocket: true,
              },
            ],
            processed_ms: 1720386182849,
          },
          {
            leverage: 0.01,
            order_type: "FLAT",
            order_uuid: "d2ed5fb8-48a7-4ee1-aca9-32f458f9a4e7",
            price: 1.082255,
            price_sources: [
              {
                close: 1.082255,
                high: 1.082255,
                lag_ms: 403,
                low: 1.082255,
                open: 1.082255,
                source: "Polygon_ws",
                start_ms: 1720417292000,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 1.08226,
                websocket: true,
              },
              {
                close: 1.0823,
                high: 1.0823,
                lag_ms: 597,
                low: 1.0823,
                open: 1.0823,
                source: "TwelveData_ws",
                start_ms: 1720417293000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1720417292403,
          },
        ],
        position_type: "FLAT",
        position_uuid: "afb1bbb4-1d8b-4a22-b580-c951e68fc82c",
        return_at_close: 1.0014614493337035,
        trade_pair: ["EURUSD", "EUR/USD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 3060.9419999999996,
        close_ms: 1720467781712,
        current_return: 1.0107305681467826,
        initial_entry_price: 3048.86,
        is_closed_position: true,
        miner_hotkey: "5FTR8y26ap56vvahaxbB4PYxSkTQFpkQDqZN32uTVcW9cKjy",
        net_leverage: 0.0,
        open_ms: 1720292891479,
        orders: [
          {
            leverage: -0.15,
            order_type: "SHORT",
            order_uuid: "049683ee-6efe-4acf-8ee1-829c8c010b28",
            price: 3048.86,
            price_sources: [
              {
                close: 3048.86,
                high: 3051.15,
                lag_ms: 500,
                low: 3048.86,
                open: 3048.86,
                source: "Polygon_ws",
                start_ms: 1720292890000,
                timespan_ms: 0,
                volume: 0.01045843,
                vwap: 3049.6403,
                websocket: true,
              },
              {
                close: 3048.46,
                high: 3048.46,
                lag_ms: 1500,
                low: 3048.46,
                open: 3048.46,
                source: "TwelveData_ws",
                start_ms: 1720292891000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
              {
                close: 3049.18,
                high: 3049.18,
                lag_ms: 2501,
                low: 3049.05,
                open: 3049.05,
                source: "Polygon_rest",
                start_ms: 1720292886000,
                timespan_ms: 1000,
                volume: 1.00018074,
                vwap: 3049.059,
                websocket: false,
              },
              {
                close: 3056.21997,
                high: 3056.21997,
                lag_ms: 489501,
                low: 3055.67993,
                open: 3055.67993,
                source: "TwelveData_rest",
                start_ms: 1720292340000,
                timespan_ms: 60000,
                volume: null,
                vwap: null,
                websocket: false,
              },
            ],
            processed_ms: 1720292889500,
          },
          {
            leverage: -0.35,
            order_type: "SHORT",
            order_uuid: "fd9c122a-e0ab-4027-96de-64a80bc81ffe",
            price: 3066.12,
            price_sources: [
              {
                close: 3066.12,
                high: 3066.12,
                lag_ms: 267,
                low: 3065.83,
                open: 3066.12,
                source: "Polygon_ws",
                start_ms: 1720429842999,
                timespan_ms: 0,
                volume: 2.49533659,
                vwap: 3066.0192,
                websocket: true,
              },
              {
                close: 3065.88,
                high: 3065.88,
                lag_ms: 1732,
                low: 3065.88,
                open: 3065.88,
                source: "TwelveData_ws",
                start_ms: 1720429841000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1720429842732,
          },
          {
            leverage: 0.01,
            order_type: "FLAT",
            order_uuid: "e7f1da77-79ce-4793-8d4f-3e2b6d10cfb0",
            price: 2995.51,
            price_sources: [
              {
                close: 2995.51,
                high: 3001.0,
                lag_ms: 288,
                low: 2995.5,
                open: 2995.51,
                source: "Polygon_ws",
                start_ms: 1720467782000,
                timespan_ms: 0,
                volume: 3.33190286,
                vwap: 3000.8114,
                websocket: true,
              },
              {
                close: 2995.53,
                high: 2995.53,
                lag_ms: 2713,
                low: 2995.53,
                open: 2995.53,
                source: "Polygon_rest",
                start_ms: 1720467778000,
                timespan_ms: 1000,
                volume: 0.00281283,
                vwap: 2995.53,
                websocket: false,
              },
              {
                close: 2995.8,
                high: 2995.8,
                lag_ms: 3288,
                low: 2995.8,
                open: 2995.8,
                source: "TwelveData_ws",
                start_ms: 1720467785000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
              {
                close: 2996.70996,
                high: 2996.70996,
                lag_ms: 181713,
                low: 2993.97998,
                open: 2993.97998,
                source: "TwelveData_rest",
                start_ms: 1720467540000,
                timespan_ms: 60000,
                volume: null,
                vwap: null,
                websocket: false,
              },
            ],
            processed_ms: 1720467781712,
          },
        ],
        position_type: "FLAT",
        position_uuid: "12bab51d-e540-4b52-9e42-eef7a549635d",
        return_at_close: 1.0102252028627092,
        trade_pair: ["ETHUSD", "ETH/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 57676.71999999999,
        close_ms: 1720531158227,
        current_return: 1.0020733612753319,
        initial_entry_price: 57652.76,
        is_closed_position: true,
        miner_hotkey: "5FTR8y26ap56vvahaxbB4PYxSkTQFpkQDqZN32uTVcW9cKjy",
        net_leverage: 0.0,
        open_ms: 1720507029710,
        orders: [
          {
            leverage: -0.1,
            order_type: "SHORT",
            order_uuid: "dac0aa88-04f4-4667-b1c2-6bf1cebb775d",
            price: 57652.76,
            price_sources: [
              {
                close: 57652.76,
                high: 57656.0,
                lag_ms: 13,
                low: 57652.76,
                open: 57652.76,
                source: "Polygon_ws",
                start_ms: 1720507029000,
                timespan_ms: 0,
                volume: 0.10000001,
                vwap: 57656.0,
                websocket: true,
              },
              {
                close: 57682.5,
                high: 57682.5,
                lag_ms: 1987,
                low: 57682.5,
                open: 57682.5,
                source: "TwelveData_ws",
                start_ms: 1720507031000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
              {
                close: 57805.0,
                high: 57805.0,
                lag_ms: 2014,
                low: 57647.61,
                open: 57647.61,
                source: "Polygon_rest",
                start_ms: 1720507026000,
                timespan_ms: 1000,
                volume: 0.12635611,
                vwap: 57664.9114,
                websocket: false,
              },
              {
                close: 57655.78,
                high: 57660.39,
                lag_ms: 69014,
                low: 57555.21,
                open: 57555.83,
                source: "TwelveData_rest",
                start_ms: 1720506900000,
                timespan_ms: 60000,
                volume: null,
                vwap: null,
                websocket: false,
              },
            ],
            processed_ms: 1720507029013,
          },
          {
            leverage: -0.2,
            order_type: "SHORT",
            order_uuid: "47db3b35-2645-48c6-b420-725850eba707",
            price: 57688.7,
            price_sources: [
              {
                close: 57688.7,
                high: 57749.22,
                lag_ms: 360,
                low: 57688.7,
                open: 57688.7,
                source: "Polygon_ws",
                start_ms: 1720514419999,
                timespan_ms: 0,
                volume: 0.60265719,
                vwap: 57745.2052,
                websocket: true,
              },
              {
                close: 57744.72,
                high: 57752.0,
                lag_ms: 2640,
                low: 57742.83,
                open: 57752.0,
                source: "Polygon_rest",
                start_ms: 1720514416000,
                timespan_ms: 1000,
                volume: 0.01940479,
                vwap: 57744.6906,
                websocket: false,
              },
              {
                close: 57712.7,
                high: 57712.7,
                lag_ms: 8639,
                low: 57712.7,
                open: 57712.7,
                source: "TwelveData_ws",
                start_ms: 1720514411000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
              {
                close: 57635.83,
                high: 57799.82,
                lag_ms: 79640,
                low: 57635.83,
                open: 57772.14,
                source: "TwelveData_rest",
                start_ms: 1720514280000,
                timespan_ms: 60000,
                volume: null,
                vwap: null,
                websocket: false,
              },
            ],
            processed_ms: 1720514419639,
          },
          {
            leverage: 0.01,
            order_type: "FLAT",
            order_uuid: "ac47156f-7f24-49e7-aed9-12ea6afde925",
            price: 57278.27,
            price_sources: [
              {
                close: 57278.27,
                high: 57443.0,
                lag_ms: 227,
                low: 57278.27,
                open: 57278.27,
                source: "Polygon_ws",
                start_ms: 1720531158000,
                timespan_ms: 0,
                volume: 0.58321512,
                vwap: 57421.4597,
                websocket: true,
              },
              {
                close: 57284.62,
                high: 57284.62,
                lag_ms: 3228,
                low: 57284.62,
                open: 57284.62,
                source: "Polygon_rest",
                start_ms: 1720531154000,
                timespan_ms: 1000,
                volume: 0.0011049,
                vwap: 57284.62,
                websocket: false,
              },
              {
                close: 57268.4,
                high: 57268.4,
                lag_ms: 6773,
                low: 57268.4,
                open: 57268.4,
                source: "TwelveData_ws",
                start_ms: 1720531165000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
              {
                close: 57263.63,
                high: 57265.7,
                lag_ms: 78228,
                low: 57220.16,
                open: 57258.07,
                source: "TwelveData_rest",
                start_ms: 1720531020000,
                timespan_ms: 60000,
                volume: null,
                vwap: null,
                websocket: false,
              },
            ],
            processed_ms: 1720531158227,
          },
        ],
        position_type: "FLAT",
        position_uuid: "08bf0288-9a04-4d27-955f-a4c346a0b175",
        return_at_close: 1.0017727392669493,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 160.765,
        close_ms: 0,
        current_return: 0.9970889186079059,
        initial_entry_price: 160.765,
        is_closed_position: false,
        miner_hotkey: "5FTR8y26ap56vvahaxbB4PYxSkTQFpkQDqZN32uTVcW9cKjy",
        net_leverage: -0.5,
        open_ms: 1720468032719,
        orders: [
          {
            leverage: -0.5,
            order_type: "SHORT",
            order_uuid: "e575fc38-d572-4b7b-99f6-27b79d4246d6",
            price: 160.765,
            price_sources: [
              {
                close: 160.765,
                high: 160.765,
                lag_ms: 314,
                low: 160.765,
                open: 160.765,
                source: "Polygon_ws",
                start_ms: 1720468032999,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 160.765,
                websocket: true,
              },
              {
                close: 160.767,
                high: 160.767,
                lag_ms: 685,
                low: 160.767,
                open: 160.767,
                source: "TwelveData_ws",
                start_ms: 1720468032000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1720468032685,
          },
        ],
        position_type: "SHORT",
        position_uuid: "e67e5088-79ee-4844-b2c2-dcfdc37cc477",
        return_at_close: 0.9970714695518303,
        trade_pair: ["USDJPY", "USD/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 3072.7800000000007,
        close_ms: 0,
        current_return: 0.9979217405948804,
        initial_entry_price: 3087.68,
        is_closed_position: false,
        miner_hotkey: "5FTR8y26ap56vvahaxbB4PYxSkTQFpkQDqZN32uTVcW9cKjy",
        net_leverage: -0.1,
        open_ms: 1720506987518,
        orders: [
          {
            leverage: -0.1,
            order_type: "SHORT",
            order_uuid: "118dc968-8d69-4683-ab92-a071104b9bcc",
            price: 3087.68,
            price_sources: [
              {
                close: 3087.68,
                high: 3087.68,
                lag_ms: 988,
                low: 3087.68,
                open: 3087.68,
                source: "TwelveData_ws",
                start_ms: 1720506988000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
              {
                close: 3088.33,
                high: 3095.3,
                lag_ms: 1988,
                low: 3088.33,
                open: 3088.33,
                source: "Polygon_ws",
                start_ms: 1720506989000,
                timespan_ms: 0,
                volume: 6.54432183,
                vwap: 3090.6302,
                websocket: true,
              },
              {
                close: 3088.09,
                high: 3088.09,
                lag_ms: 3013,
                low: 3088.09,
                open: 3088.09,
                source: "Polygon_rest",
                start_ms: 1720506983000,
                timespan_ms: 1000,
                volume: 0.00018678,
                vwap: 3088.09,
                websocket: false,
              },
              {
                close: 3079.94995,
                high: 3079.98999,
                lag_ms: 867013,
                low: 3077.05005,
                open: 3077.05005,
                source: "TwelveData_rest",
                start_ms: 1720506060000,
                timespan_ms: 60000,
                volume: null,
                vwap: null,
                websocket: false,
              },
            ],
            processed_ms: 1720506987012,
          },
          {
            leverage: -0.1,
            order_type: "SHORT",
            order_uuid: "547f85cc-cd20-4b2a-882c-dbcb469c4006",
            price: 3053.07,
            price_sources: [
              {
                close: 3053.07,
                high: 3053.07,
                lag_ms: 511,
                low: 3053.07,
                open: 3053.07,
                source: "Polygon_ws",
                start_ms: 1720533232000,
                timespan_ms: 0,
                volume: 0.02640206,
                vwap: 3053.07,
                websocket: true,
              },
              {
                close: 3052.63,
                high: 3052.63,
                lag_ms: 3489,
                low: 3052.63,
                open: 3052.63,
                source: "TwelveData_ws",
                start_ms: 1720533228000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
              {
                close: 3052.56,
                high: 3052.56,
                lag_ms: 3490,
                low: 3052.02,
                open: 3052.38,
                source: "Polygon_rest",
                start_ms: 1720533227000,
                timespan_ms: 1000,
                volume: 1.2121247,
                vwap: 3052.4399,
                websocket: false,
              },
              {
                close: 3054.23999,
                high: 3058.66992,
                lag_ms: 111490,
                low: 3053.79004,
                open: 3058.66992,
                source: "TwelveData_rest",
                start_ms: 1720533060000,
                timespan_ms: 60000,
                volume: null,
                vwap: null,
                websocket: false,
              },
            ],
            processed_ms: 1720533231489,
          },
          {
            leverage: 0.1,
            order_type: "LONG",
            order_uuid: "fc5fbd77-657d-4ece-8bbd-063d106f3b89",
            price: 3067.97,
            price_sources: [
              {
                close: 3067.97,
                high: 3068.17,
                lag_ms: 435,
                low: 3067.97,
                open: 3067.97,
                source: "Polygon_ws",
                start_ms: 1720555070999,
                timespan_ms: 0,
                volume: 0.14144044,
                vwap: 3068.1124,
                websocket: true,
              },
              {
                close: 3065.72,
                high: 3065.72,
                lag_ms: 436,
                low: 3065.72,
                open: 3065.72,
                source: "TwelveData_ws",
                start_ms: 1720555071000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
              {
                close: 3067.72,
                high: 3067.72,
                lag_ms: 2565,
                low: 3067.72,
                open: 3067.72,
                source: "Polygon_rest",
                start_ms: 1720555067000,
                timespan_ms: 1000,
                volume: 0.00403722,
                vwap: 3067.72,
                websocket: false,
              },
              {
                close: 3065.08008,
                high: 3065.54004,
                lag_ms: 170565,
                low: 3063.22998,
                open: 3063.22998,
                source: "TwelveData_rest",
                start_ms: 1720554840000,
                timespan_ms: 60000,
                volume: null,
                vwap: null,
                websocket: false,
              },
            ],
            processed_ms: 1720555070564,
          },
        ],
        position_type: "SHORT",
        position_uuid: "82854d4b-cd2b-4021-8bb6-3421b29366bf",
        return_at_close: 0.9977720523337912,
        trade_pair: ["ETHUSD", "ETH/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 1.0822,
        close_ms: 0,
        current_return: 1.0000739234891887,
        initial_entry_price: 1.0822,
        is_closed_position: false,
        miner_hotkey: "5FTR8y26ap56vvahaxbB4PYxSkTQFpkQDqZN32uTVcW9cKjy",
        net_leverage: 0.5,
        open_ms: 1720467861957,
        orders: [
          {
            leverage: 0.5,
            order_type: "LONG",
            order_uuid: "f6f9c5e7-3fbb-4b98-95be-3cabd5a76a35",
            price: 1.0822,
            price_sources: [
              {
                close: 1.0822,
                high: 1.0822,
                lag_ms: 165,
                low: 1.0822,
                open: 1.0822,
                source: "Polygon_ws",
                start_ms: 1720467861999,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 1.0822,
                websocket: true,
              },
              {
                close: 1.0824,
                high: 1.0824,
                lag_ms: 13166,
                low: 1.0824,
                open: 1.0824,
                source: "TwelveData_ws",
                start_ms: 1720467875000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1720467861834,
          },
        ],
        position_type: "LONG",
        position_uuid: "a0521d4d-003d-43fe-bd11-1a09c3bc311d",
        return_at_close: 1.0000564221955277,
        trade_pair: ["EURUSD", "EUR/USD", 7e-5, 0.001, 500],
      },
    ],
    thirty_day_returns: 0.9998505388185492,
  },
  "5FU9YidcK1ySmt8QzqEu2kjDGrwuEVZDR2XRstswnfJiFyc3": {
    all_time_returns: 1.0170342533936652,
    n_positions: 1,
    percentage_profitable: 1.0,
    positions: [
      {
        average_entry_price: 3536.0,
        close_ms: 1718276845125,
        current_return: 1.0190723981900451,
        initial_entry_price: 3536.0,
        is_closed_position: true,
        miner_hotkey: "5FU9YidcK1ySmt8QzqEu2kjDGrwuEVZDR2XRstswnfJiFyc3",
        net_leverage: 0.0,
        open_ms: 1718193945928,
        orders: [
          {
            leverage: -2.0,
            order_type: "SHORT",
            order_uuid: "e6601957-df3e-477f-b31c-40d28ee9579c",
            price: 3536.0,
            price_sources: [],
            processed_ms: 1718193945919,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "2a460d3a-e383-412f-b198-8e66a071033a",
            price: 3502.28,
            price_sources: [],
            processed_ms: 1718276845125,
          },
        ],
        position_type: "FLAT",
        position_uuid: "9b62fa2e-eea1-4e81-98d3-0366afda8bfc",
        return_at_close: 1.0170342533936652,
        trade_pair: ["ETHUSD", "ETH/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 66384.79555555555,
        close_ms: 0,
        current_return: 0.9718161550073963,
        initial_entry_price: 65844.6,
        is_closed_position: false,
        miner_hotkey: "5FU9YidcK1ySmt8QzqEu2kjDGrwuEVZDR2XRstswnfJiFyc3",
        net_leverage: 0.225,
        open_ms: 1715838777986,
        orders: [
          {
            leverage: 0.005,
            order_type: "LONG",
            order_uuid: "e4b21951-842d-4c7d-9b86-5cc97e51cc7b",
            price: 65844.6,
            price_sources: [],
            processed_ms: 1715838777020,
          },
          {
            leverage: 0.1,
            order_type: "LONG",
            order_uuid: "a4708143-71d4-4725-aa08-166544bc9e08",
            price: 66192.86,
            price_sources: [],
            processed_ms: 1716163915646,
          },
          {
            leverage: 0.12,
            order_type: "LONG",
            order_uuid: "b27eb2b4-5813-40ae-882a-f504f0806d90",
            price: 66567.25,
            price_sources: [],
            processed_ms: 1716175562503,
          },
        ],
        position_type: "LONG",
        position_uuid: "699e2313-24ad-4830-923c-548a5597afa0",
        return_at_close: 0.9717068256899579,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
    ],
    thirty_day_returns: 1.0170342533936652,
  },
  "5FUbUjTuSrcUbDKTWWbjiyY8QTJS5c9nvWseLSopCFJDju43": {
    all_time_returns: 1.0357031766769214,
    n_positions: 8,
    percentage_profitable: 1.0,
    positions: [
      {
        average_entry_price: 161.223,
        close_ms: 1720109173963,
        current_return: 1.000521017472693,
        initial_entry_price: 161.223,
        is_closed_position: true,
        miner_hotkey: "5FUbUjTuSrcUbDKTWWbjiyY8QTJS5c9nvWseLSopCFJDju43",
        net_leverage: 0.0,
        open_ms: 1719837200263,
        orders: [
          {
            leverage: -1.0,
            order_type: "SHORT",
            order_uuid: "d35865d0-3958-4aed-a1bc-c8c365413bb3",
            price: 161.223,
            price_sources: [],
            processed_ms: 1719837200233,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "adc3126c-19d1-41be-b713-690265e4799a",
            price: 161.139,
            price_sources: [
              {
                close: 161.139,
                high: 161.139,
                lag_ms: 36,
                low: 161.139,
                open: 161.139,
                source: "Polygon_ws",
                start_ms: 1720109173999,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 161.139,
                websocket: true,
              },
              {
                close: 161.139,
                high: 161.139,
                lag_ms: 37,
                low: 161.139,
                open: 161.139,
                source: "TwelveData_ws",
                start_ms: 1720109174000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1720109173963,
          },
        ],
        position_type: "FLAT",
        position_uuid: "9b20f894-e1a8-4059-b230-84551d311007",
        return_at_close: 1.00045098100147,
        trade_pair: ["USDJPY", "USD/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 3182.14,
        close_ms: 1720109218938,
        current_return: 1.0174253804043818,
        initial_entry_price: 3182.14,
        is_closed_position: true,
        miner_hotkey: "5FUbUjTuSrcUbDKTWWbjiyY8QTJS5c9nvWseLSopCFJDju43",
        net_leverage: 0.0,
        open_ms: 1720081737302,
        orders: [
          {
            leverage: -1.0,
            order_type: "SHORT",
            order_uuid: "d1c355e4-810d-41e7-8831-817dabcce6e3",
            price: 3182.14,
            price_sources: [
              {
                close: 3182.14,
                high: 3182.14,
                lag_ms: 18,
                low: 3182.14,
                open: 3182.14,
                source: "Polygon_ws",
                start_ms: 1720081735999,
                timespan_ms: 0,
                volume: 0.00121212,
                vwap: 3182.14,
                websocket: true,
              },
              {
                close: 3184.49,
                high: 3184.49,
                lag_ms: 1019,
                low: 3184.49,
                open: 3184.49,
                source: "TwelveData_ws",
                start_ms: 1720081737000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
              {
                close: 3181.99,
                high: 3181.99,
                lag_ms: 1982,
                low: 3181.98,
                open: 3181.98,
                source: "Polygon_rest",
                start_ms: 1720081733000,
                timespan_ms: 1000,
                volume: 0.52021062,
                vwap: 3181.99,
                websocket: false,
              },
              {
                close: 3189.87988,
                high: 3191.27002,
                lag_ms: 175982,
                low: 3189.80005,
                open: 3189.80005,
                source: "TwelveData_rest",
                start_ms: 1720081500000,
                timespan_ms: 60000,
                volume: null,
                vwap: null,
                websocket: false,
              },
            ],
            processed_ms: 1720081735981,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "6ab740d7-c061-4b2f-ba15-c1597242c4ab",
            price: 3126.69,
            price_sources: [
              {
                close: 3126.69,
                high: 3126.83,
                lag_ms: 62,
                low: 3126.69,
                open: 3126.69,
                source: "Polygon_ws",
                start_ms: 1720109219000,
                timespan_ms: 0,
                volume: 0.02256711,
                vwap: 3126.8222,
                websocket: true,
              },
              {
                close: 3129.86,
                high: 3129.86,
                lag_ms: 2062,
                low: 3129.86,
                open: 3129.86,
                source: "TwelveData_ws",
                start_ms: 1720109221000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1720109218938,
          },
        ],
        position_type: "FLAT",
        position_uuid: "18472f94-99ed-4a93-940f-315ce3cec4d5",
        return_at_close: 1.0164079550239775,
        trade_pair: ["ETHUSD", "ETH/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 1.275995,
        close_ms: 1720198506992,
        current_return: 1.002825246180432,
        initial_entry_price: 1.275995,
        is_closed_position: true,
        miner_hotkey: "5FUbUjTuSrcUbDKTWWbjiyY8QTJS5c9nvWseLSopCFJDju43",
        net_leverage: 0.0,
        open_ms: 1720109380285,
        orders: [
          {
            leverage: 1.0,
            order_type: "LONG",
            order_uuid: "9b5337d9-2abe-4a0f-b061-e90f3224755d",
            price: 1.275995,
            price_sources: [
              {
                close: 1.275995,
                high: 1.275995,
                lag_ms: 275,
                low: 1.275995,
                open: 1.275995,
                source: "Polygon_ws",
                start_ms: 1720109380000,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 1.27603,
                websocket: true,
              },
              {
                close: 1.276,
                high: 1.276,
                lag_ms: 275,
                low: 1.276,
                open: 1.276,
                source: "TwelveData_ws",
                start_ms: 1720109380000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1720109380275,
          },
          {
            leverage: 0.01,
            order_type: "FLAT",
            order_uuid: "31346f4f-4809-40fb-b0fd-082cac9e1469",
            price: 1.2796,
            price_sources: [
              {
                close: 1.2796,
                high: 1.2796,
                lag_ms: 7,
                low: 1.2796,
                open: 1.2796,
                source: "Polygon_ws",
                start_ms: 1720198506999,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 1.2796,
                websocket: true,
              },
              {
                close: 1.27957,
                high: 1.27957,
                lag_ms: 8,
                low: 1.27957,
                open: 1.27957,
                source: "TwelveData_ws",
                start_ms: 1720198507000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1720198506992,
          },
        ],
        position_type: "FLAT",
        position_uuid: "b1f2b2ff-0db7-414b-b2ec-8ec1fae4e080",
        return_at_close: 1.0027550484131993,
        trade_pair: ["GBPUSD", "GBP/USD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 2980.861818181818,
        close_ms: 1720202042060,
        current_return: 1.0097671848146235,
        initial_entry_price: 3130.38,
        is_closed_position: true,
        miner_hotkey: "5FUbUjTuSrcUbDKTWWbjiyY8QTJS5c9nvWseLSopCFJDju43",
        net_leverage: 0.0,
        open_ms: 1720111933718,
        orders: [
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "2c5cfac9-c279-404c-a5a2-0389ea2b8ab5",
            price: 3130.38,
            price_sources: [
              {
                close: 3130.38,
                high: 3130.38,
                lag_ms: 38,
                low: 3129.64,
                open: 3130.38,
                source: "Polygon_ws",
                start_ms: 1720111933000,
                timespan_ms: 0,
                volume: 3.7073603,
                vwap: 3130.261,
                websocket: true,
              },
              {
                close: 3133.02,
                high: 3133.02,
                lag_ms: 962,
                low: 3133.02,
                open: 3133.02,
                source: "TwelveData_ws",
                start_ms: 1720111932000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
              {
                close: 3130.67,
                high: 3130.67,
                lag_ms: 1963,
                low: 3130.39,
                open: 3130.39,
                source: "Polygon_rest",
                start_ms: 1720111930000,
                timespan_ms: 1000,
                volume: 0.00442386,
                vwap: 3130.6599,
                websocket: false,
              },
              {
                close: 3131.12012,
                high: 3133.29004,
                lag_ms: 492963,
                low: 3130.72998,
                open: 3133.29004,
                source: "TwelveData_rest",
                start_ms: 1720111380000,
                timespan_ms: 60000,
                volume: null,
                vwap: null,
                websocket: false,
              },
            ],
            processed_ms: 1720111932962,
          },
          {
            leverage: 1.25,
            order_type: "LONG",
            order_uuid: "3cbfe08b-60a3-45d1-bd6a-33f3ab046bf3",
            price: 2966.7,
            price_sources: [
              {
                close: 2966.7,
                high: 2966.7,
                lag_ms: 4,
                low: 2966.67,
                open: 2966.7,
                source: "Polygon_ws",
                start_ms: 1720198433000,
                timespan_ms: 0,
                volume: 0.06684264,
                vwap: 2966.6701,
                websocket: true,
              },
              {
                close: 2968.38,
                high: 2968.38,
                lag_ms: 2004,
                low: 2968.38,
                open: 2968.38,
                source: "TwelveData_ws",
                start_ms: 1720198431000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
              {
                close: 2966.76,
                high: 2966.76,
                lag_ms: 3005,
                low: 2966.76,
                open: 2966.76,
                source: "Polygon_rest",
                start_ms: 1720198429000,
                timespan_ms: 1000,
                volume: 0.31954183,
                vwap: 2966.76,
                websocket: false,
              },
              {
                close: 2975.69995,
                high: 2975.69995,
                lag_ms: 293005,
                low: 2973.1001,
                open: 2973.1001,
                source: "TwelveData_rest",
                start_ms: 1720198080000,
                timespan_ms: 60000,
                volume: null,
                vwap: null,
                websocket: false,
              },
            ],
            processed_ms: 1720198433004,
          },
          {
            leverage: 1.25,
            order_type: "LONG",
            order_uuid: "5ebeda5e-6ff9-49c3-a1d5-6df339e0f7b9",
            price: 2965.12,
            price_sources: [
              {
                close: 2965.12,
                high: 2965.18,
                lag_ms: 390,
                low: 2964.98,
                open: 2965.12,
                source: "Polygon_ws",
                start_ms: 1720198447000,
                timespan_ms: 0,
                volume: 0.16768407,
                vwap: 2965.1429,
                websocket: true,
              },
              {
                close: 2968.38,
                high: 2968.38,
                lag_ms: 610,
                low: 2968.38,
                open: 2968.38,
                source: "TwelveData_ws",
                start_ms: 1720198448000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
              {
                close: 2965.51,
                high: 2965.51,
                lag_ms: 2391,
                low: 2965.51,
                open: 2965.51,
                source: "Polygon_rest",
                start_ms: 1720198444000,
                timespan_ms: 1000,
                volume: 0.01602097,
                vwap: 2965.51,
                websocket: false,
              },
              {
                close: 2975.69995,
                high: 2975.69995,
                lag_ms: 307391,
                low: 2973.1001,
                open: 2973.1001,
                source: "TwelveData_rest",
                start_ms: 1720198080000,
                timespan_ms: 60000,
                volume: null,
                vwap: null,
                websocket: false,
              },
            ],
            processed_ms: 1720198447390,
          },
          {
            leverage: 0.01,
            order_type: "FLAT",
            order_uuid: "c62d061e-271e-48b3-ad1c-85f79147521a",
            price: 2991.98,
            price_sources: [
              {
                close: 2991.98,
                high: 2991.98,
                lag_ms: 60,
                low: 2991.98,
                open: 2991.98,
                source: "Polygon_ws",
                start_ms: 1720202042000,
                timespan_ms: 0,
                volume: 0.06011334,
                vwap: 2991.98,
                websocket: true,
              },
              {
                close: 2992.6,
                high: 2992.6,
                lag_ms: 1940,
                low: 2992.6,
                open: 2992.6,
                source: "TwelveData_ws",
                start_ms: 1720202044000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
              {
                close: 2992.63,
                high: 2992.66,
                lag_ms: 2061,
                low: 2992.63,
                open: 2992.66,
                source: "Polygon_rest",
                start_ms: 1720202039000,
                timespan_ms: 1000,
                volume: 0.77682,
                vwap: 2992.6364,
                websocket: false,
              },
              {
                close: 2997.21997,
                high: 2997.21997,
                lag_ms: 122061,
                low: 2992.34009,
                open: 2992.83008,
                source: "TwelveData_rest",
                start_ms: 1720201860000,
                timespan_ms: 60000,
                volume: null,
                vwap: null,
                websocket: false,
              },
            ],
            processed_ms: 1720202042060,
          },
        ],
        position_type: "FLAT",
        position_uuid: "503869b3-3ac5-4c88-bc91-23c14a05e245",
        return_at_close: 1.0069903250563832,
        trade_pair: ["ETHUSD", "ETH/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 59436.159999999996,
        close_ms: 1720531362083,
        current_return: 1.0037259854771785,
        initial_entry_price: 57840.0,
        is_closed_position: true,
        miner_hotkey: "5FUbUjTuSrcUbDKTWWbjiyY8QTJS5c9nvWseLSopCFJDju43",
        net_leverage: 0.0,
        open_ms: 1720333916856,
        orders: [
          {
            leverage: -0.1,
            order_type: "SHORT",
            order_uuid: "7282acba-64bc-4e10-a8d0-5587b91cd330",
            price: 57840.0,
            price_sources: [
              {
                close: 57840.0,
                high: 57840.0,
                lag_ms: 740,
                low: 57840.0,
                open: 57840.0,
                source: "Polygon_ws",
                start_ms: 1720333916000,
                timespan_ms: 0,
                volume: 0.00019646,
                vwap: 57840.0,
                websocket: true,
              },
              {
                close: 57715.0,
                high: 57715.0,
                lag_ms: 2261,
                low: 57700.0,
                open: 57700.0,
                source: "Polygon_rest",
                start_ms: 1720333912000,
                timespan_ms: 1000,
                volume: 0.00211282,
                vwap: 57708.5904,
                websocket: false,
              },
              {
                close: 57726.4,
                high: 57726.4,
                lag_ms: 4260,
                low: 57726.4,
                open: 57726.4,
                source: "TwelveData_ws",
                start_ms: 1720333911000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
              {
                close: 57727.49,
                high: 57749.88,
                lag_ms: 55261,
                low: 57724.23,
                open: 57742.66,
                source: "TwelveData_rest",
                start_ms: 1720333800000,
                timespan_ms: 60000,
                volume: null,
                vwap: null,
                websocket: false,
              },
            ],
            processed_ms: 1720333915260,
          },
          {
            leverage: -0.4,
            order_type: "SHORT",
            order_uuid: "bfadefb5-62db-47da-af92-6ca87263f253",
            price: 57703.73,
            price_sources: [
              {
                close: 57703.73,
                high: 57889.0,
                lag_ms: 1097,
                low: 57703.73,
                open: 57703.73,
                source: "Polygon_ws",
                start_ms: 1720514238999,
                timespan_ms: 0,
                volume: 0.56732326,
                vwap: 57856.8665,
                websocket: true,
              },
              {
                close: 57700.78,
                high: 57706.8,
                lag_ms: 2097,
                low: 57700.78,
                open: 57706.8,
                source: "Polygon_rest",
                start_ms: 1720514237000,
                timespan_ms: 1000,
                volume: 0.11509019,
                vwap: 57704.4333,
                websocket: false,
              },
              {
                close: 57713.3,
                high: 57713.3,
                lag_ms: 4096,
                low: 57713.3,
                open: 57713.3,
                source: "TwelveData_ws",
                start_ms: 1720514236000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
              {
                close: 57635.29,
                high: 57699.96,
                lag_ms: 80097,
                low: 57635.29,
                open: 57670.61,
                source: "TwelveData_rest",
                start_ms: 1720514100000,
                timespan_ms: 60000,
                volume: null,
                vwap: null,
                websocket: false,
              },
            ],
            processed_ms: 1720514240096,
          },
          {
            leverage: 0.4,
            order_type: "LONG",
            order_uuid: "8f49e008-96ae-465d-945f-0b938219d311",
            price: 57304.69,
            price_sources: [
              {
                close: 57304.69,
                high: 57304.69,
                lag_ms: 209,
                low: 57299.36,
                open: 57304.69,
                source: "Polygon_ws",
                start_ms: 1720531260000,
                timespan_ms: 0,
                volume: 0.00016962,
                vwap: 57303.4139,
                websocket: true,
              },
              {
                close: 57299.16,
                high: 57299.16,
                lag_ms: 2210,
                low: 57299.16,
                open: 57299.16,
                source: "Polygon_rest",
                start_ms: 1720531257000,
                timespan_ms: 1000,
                volume: 0.00102205,
                vwap: 57299.16,
                websocket: false,
              },
              {
                close: 57302.6,
                high: 57302.6,
                lag_ms: 16209,
                low: 57302.6,
                open: 57302.6,
                source: "TwelveData_ws",
                start_ms: 1720531244000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
              {
                close: 57307.08,
                high: 57313.88,
                lag_ms: 60210,
                low: 57263.36,
                open: 57286.47,
                source: "TwelveData_rest",
                start_ms: 1720531140000,
                timespan_ms: 60000,
                volume: null,
                vwap: null,
                websocket: false,
              },
            ],
            processed_ms: 1720531260209,
          },
          {
            leverage: 0.1,
            order_type: "FLAT",
            order_uuid: "2e20d49e-d99b-4bfb-8e86-0755a355a7c4",
            price: 57281.05,
            price_sources: [
              {
                close: 57281.05,
                high: 57281.06,
                lag_ms: 83,
                low: 57265.81,
                open: 57281.05,
                source: "Polygon_ws",
                start_ms: 1720531362000,
                timespan_ms: 0,
                volume: 0.08701753,
                vwap: 57268.3287,
                websocket: true,
              },
              {
                close: 57265.95,
                high: 57265.95,
                lag_ms: 2084,
                low: 57265.88,
                open: 57265.88,
                source: "Polygon_rest",
                start_ms: 1720531359000,
                timespan_ms: 1000,
                volume: 0.00067294,
                vwap: 57265.9167,
                websocket: false,
              },
              {
                close: 57287.1,
                high: 57287.1,
                lag_ms: 5917,
                low: 57287.1,
                open: 57287.1,
                source: "TwelveData_ws",
                start_ms: 1720531368000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
              {
                close: 57280.8,
                high: 57315.21,
                lag_ms: 42084,
                low: 57264.88,
                open: 57304.69,
                source: "TwelveData_rest",
                start_ms: 1720531260000,
                timespan_ms: 60000,
                volume: null,
                vwap: null,
                websocket: false,
              },
            ],
            processed_ms: 1720531362083,
          },
        ],
        position_type: "FLAT",
        position_uuid: "9d3b49c4-347f-4599-b823-252098ce7241",
        return_at_close: 1.00322412248444,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 0.831026848713978,
        close_ms: 1720590109963,
        current_return: 1.0002858377800175,
        initial_entry_price: 0.831026848713978,
        is_closed_position: true,
        miner_hotkey: "5FUbUjTuSrcUbDKTWWbjiyY8QTJS5c9nvWseLSopCFJDju43",
        net_leverage: 0.0,
        open_ms: 1719986817904,
        orders: [
          {
            leverage: -0.5,
            order_type: "SHORT",
            order_uuid: "5e304f85-4697-4271-8eb6-5f55c999b672",
            price: 0.831026848713978,
            price_sources: [],
            processed_ms: 1719986817891,
          },
          {
            leverage: 0.01,
            order_type: "FLAT",
            order_uuid: "6d199a5e-a05a-405d-b52c-340c861f7255",
            price: 0.8305517709748356,
            price_sources: [
              {
                close: 0.8305517709748356,
                high: 0.8305517709748356,
                lag_ms: 36,
                low: 0.8305517709748356,
                open: 0.8305517709748356,
                source: "Polygon_ws",
                start_ms: 1720590109999,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 0.830553541949671,
                websocket: true,
              },
              {
                close: 0.83052,
                high: 0.83052,
                lag_ms: 963,
                low: 0.83052,
                open: 0.83052,
                source: "TwelveData_ws",
                start_ms: 1720590109000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1720590109963,
          },
        ],
        position_type: "FLAT",
        position_uuid: "8c7fcab3-24ad-4300-9fd3-7f76311f906c",
        return_at_close: 1.0002508277756952,
        trade_pair: ["NZDCAD", "NZD/CAD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.83005,
        close_ms: 1720607883042,
        current_return: 1.003060056623095,
        initial_entry_price: 0.83005,
        is_closed_position: true,
        miner_hotkey: "5FUbUjTuSrcUbDKTWWbjiyY8QTJS5c9nvWseLSopCFJDju43",
        net_leverage: 0.0,
        open_ms: 1720591484648,
        orders: [
          {
            leverage: -1.0,
            order_type: "SHORT",
            order_uuid: "02b2f730-250a-4bdb-b3a4-31238cbb5a69",
            price: 0.83005,
            price_sources: [
              {
                close: 0.83005,
                high: 0.83005,
                lag_ms: 21,
                low: 0.83005,
                open: 0.83005,
                source: "Polygon_ws",
                start_ms: 1720591484000,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 0.83005,
                websocket: true,
              },
              {
                close: 0.83005,
                high: 0.83005,
                lag_ms: 979,
                low: 0.83005,
                open: 0.83005,
                source: "TwelveData_ws",
                start_ms: 1720591485000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1720591484021,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "16f027cb-327e-4b8a-a9f8-fa8fb0277e62",
            price: 0.82751,
            price_sources: [
              {
                close: 0.82751,
                high: 0.82751,
                lag_ms: 42,
                low: 0.82751,
                open: 0.82751,
                source: "Polygon_ws",
                start_ms: 1720607883000,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 0.82751,
                websocket: true,
              },
              {
                close: 0.82751,
                high: 0.82751,
                lag_ms: 42,
                low: 0.82751,
                open: 0.82751,
                source: "TwelveData_ws",
                start_ms: 1720607883000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1720607883042,
          },
        ],
        position_type: "FLAT",
        position_uuid: "e788014d-169f-433a-8405-222509c45f20",
        return_at_close: 1.0029898424191313,
        trade_pair: ["NZDCAD", "NZD/CAD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 58957.26,
        close_ms: 1720619441118,
        current_return: 1.0022851469013316,
        initial_entry_price: 58957.26,
        is_closed_position: true,
        miner_hotkey: "5FUbUjTuSrcUbDKTWWbjiyY8QTJS5c9nvWseLSopCFJDju43",
        net_leverage: 0.0,
        open_ms: 1720590155419,
        orders: [
          {
            leverage: -0.1,
            order_type: "SHORT",
            order_uuid: "4b0e09e8-e3cb-4cce-b4c5-c2c102870b48",
            price: 58957.26,
            price_sources: [
              {
                close: 58957.26,
                high: 58957.26,
                lag_ms: 1219,
                low: 58952.77,
                open: 58957.26,
                source: "Polygon_ws",
                start_ms: 1720590153999,
                timespan_ms: 0,
                volume: 0.00015131,
                vwap: 58954.7585,
                websocket: true,
              },
              {
                close: 58952.77,
                high: 58952.77,
                lag_ms: 2219,
                low: 58945.49,
                open: 58945.49,
                source: "Polygon_rest",
                start_ms: 1720590152000,
                timespan_ms: 1000,
                volume: 0.27719606,
                vwap: 58945.7485,
                websocket: false,
              },
              {
                close: 58967.0,
                high: 58967.0,
                lag_ms: 9782,
                low: 58967.0,
                open: 58967.0,
                source: "TwelveData_ws",
                start_ms: 1720590165000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
              {
                close: 58924.97,
                high: 58930.24,
                lag_ms: 35219,
                low: 58834.92,
                open: 58868.64,
                source: "TwelveData_rest",
                start_ms: 1720590060000,
                timespan_ms: 60000,
                volume: null,
                vwap: null,
                websocket: false,
              },
            ],
            processed_ms: 1720590155218,
          },
          {
            leverage: 0.01,
            order_type: "FLAT",
            order_uuid: "b8bec353-28e6-45eb-af3e-1fb5bc3fbfce",
            price: 57610.0,
            price_sources: [
              {
                close: 57610.0,
                high: 57610.0,
                lag_ms: 118,
                low: 57586.1,
                open: 57610.0,
                source: "Polygon_ws",
                start_ms: 1720619441000,
                timespan_ms: 0,
                volume: 0.00263233,
                vwap: 57600.1892,
                websocket: true,
              },
              {
                close: 57756.0,
                high: 57756.0,
                lag_ms: 118,
                low: 57756.0,
                open: 57756.0,
                source: "TwelveData_ws",
                start_ms: 1720619441000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
              {
                close: 57737.0,
                high: 57737.0,
                lag_ms: 2119,
                low: 57600.22,
                open: 57613.0,
                source: "Polygon_rest",
                start_ms: 1720619438000,
                timespan_ms: 1000,
                volume: 0.02872193,
                vwap: 57720.545,
                websocket: false,
              },
              {
                close: 57651.98,
                high: 57766.17,
                lag_ms: 41119,
                low: 57645.53,
                open: 57713.84,
                source: "TwelveData_rest",
                start_ms: 1720619340000,
                timespan_ms: 60000,
                volume: null,
                vwap: null,
                websocket: false,
              },
            ],
            processed_ms: 1720619441118,
          },
        ],
        position_type: "FLAT",
        position_uuid: "481bff4e-5f06-45c3-bea9-8a79837a9384",
        return_at_close: 1.0021849183866416,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 3084.43,
        close_ms: 0,
        current_return: 0.9965716001592783,
        initial_entry_price: 3063.82,
        is_closed_position: false,
        miner_hotkey: "5FUbUjTuSrcUbDKTWWbjiyY8QTJS5c9nvWseLSopCFJDju43",
        net_leverage: -0.2,
        open_ms: 1720531290578,
        orders: [
          {
            leverage: -0.1,
            order_type: "SHORT",
            order_uuid: "8dd6589e-2441-43bb-8cdd-3d5ac8d84605",
            price: 3063.82,
            price_sources: [
              {
                close: 3063.82,
                high: 3063.82,
                lag_ms: 392,
                low: 3063.82,
                open: 3063.82,
                source: "Polygon_ws",
                start_ms: 1720531290000,
                timespan_ms: 0,
                volume: 0.04361561,
                vwap: 3063.82,
                websocket: true,
              },
              {
                close: 3063.67,
                high: 3063.67,
                lag_ms: 392,
                low: 3063.67,
                open: 3063.67,
                source: "TwelveData_ws",
                start_ms: 1720531290000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
              {
                close: 3064.19,
                high: 3064.19,
                lag_ms: 2393,
                low: 3064.19,
                open: 3064.19,
                source: "Polygon_rest",
                start_ms: 1720531287000,
                timespan_ms: 1000,
                volume: 0.60124838,
                vwap: 3064.19,
                websocket: false,
              },
              {
                close: 3064.32007,
                high: 3064.51001,
                lag_ms: 210393,
                low: 3063.20996,
                open: 3064.51001,
                source: "TwelveData_rest",
                start_ms: 1720531020000,
                timespan_ms: 60000,
                volume: null,
                vwap: null,
                websocket: false,
              },
            ],
            processed_ms: 1720531290392,
          },
          {
            leverage: -0.1,
            order_type: "SHORT",
            order_uuid: "b5157bf2-db33-41db-9f32-67de425373be",
            price: 3105.04,
            price_sources: [
              {
                close: 3105.04,
                high: 3105.04,
                lag_ms: 260,
                low: 3105.04,
                open: 3105.04,
                source: "Polygon_ws",
                start_ms: 1720590137999,
                timespan_ms: 0,
                volume: 0.00493013,
                vwap: 3105.04,
                websocket: true,
              },
              {
                close: 3103.17,
                high: 3103.17,
                lag_ms: 1739,
                low: 3103.17,
                open: 3103.17,
                source: "TwelveData_ws",
                start_ms: 1720590136000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1720590137739,
          },
        ],
        position_type: "SHORT",
        position_uuid: "8df96ba4-3a42-4413-8aad-735724a2b569",
        return_at_close: 0.9964719429992623,
        trade_pair: ["ETHUSD", "ETH/USD", 0.003, 0.001, 20],
      },
    ],
    thirty_day_returns: 1.0357031766769214,
  },
  "5FWZ1VRRRqZGbY7XJ7RXyjEfeALhMWoe4XThSSNYQf3TDQFf": {
    all_time_returns: 0.9915846650475864,
    n_positions: 3,
    percentage_profitable: 0.3333333333333333,
    positions: [
      {
        average_entry_price: 66253.71,
        close_ms: 1719499318417,
        current_return: 0.9947018008500957,
        initial_entry_price: 66253.71,
        is_closed_position: true,
        miner_hotkey: "5FWZ1VRRRqZGbY7XJ7RXyjEfeALhMWoe4XThSSNYQf3TDQFf",
        net_leverage: 0.0,
        open_ms: 1718529701729,
        orders: [
          {
            leverage: 0.085,
            order_type: "LONG",
            order_uuid: "1f3f3d17-b3bc-495c-a91c-b9b3322b25b9",
            price: 66253.71,
            price_sources: [],
            processed_ms: 1718529701107,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "2772bde6-dd81-4382-9536-d40b47f34e5f",
            price: 62124.0,
            price_sources: [],
            processed_ms: 1719499318417,
          },
        ],
        position_type: "FLAT",
        position_uuid: "ed5aa65c-2de0-48b2-a8ab-5650b89ae8bc",
        return_at_close: 0.9946172511970235,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 3450.1999999999994,
        close_ms: 1719500402418,
        current_return: 0.9978001275288392,
        initial_entry_price: 3450.2,
        is_closed_position: true,
        miner_hotkey: "5FWZ1VRRRqZGbY7XJ7RXyjEfeALhMWoe4XThSSNYQf3TDQFf",
        net_leverage: 0.0,
        open_ms: 1719499734500,
        orders: [
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "1d9dcf0b-d209-4038-8456-e1911b56e683",
            price: 3450.2,
            price_sources: [],
            processed_ms: 1719499732724,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "2434b58a-2d77-4cd4-a88a-8d665d97f178",
            price: 3445.14,
            price_sources: [],
            processed_ms: 1719500402418,
          },
        ],
        position_type: "FLAT",
        position_uuid: "fa835c21-4bbd-4f6a-b01a-30001cec4819",
        return_at_close: 0.996303427337546,
        trade_pair: ["ETHUSD", "ETH/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 61869.0,
        close_ms: 1719508460201,
        current_return: 1.0026552877854822,
        initial_entry_price: 61869.0,
        is_closed_position: true,
        miner_hotkey: "5FWZ1VRRRqZGbY7XJ7RXyjEfeALhMWoe4XThSSNYQf3TDQFf",
        net_leverage: 0.0,
        open_ms: 1719500939289,
        orders: [
          {
            leverage: -2.0,
            order_type: "SHORT",
            order_uuid: "d0bac77a-36fa-4677-bbb6-0ed079b6730e",
            price: 61869.0,
            price_sources: [],
            processed_ms: 1719500938118,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "90e96afb-24de-47b4-9e40-3b04434a3e9d",
            price: 61786.86,
            price_sources: [],
            processed_ms: 1719508460201,
          },
        ],
        position_type: "FLAT",
        position_uuid: "8c691378-b13b-4285-95f0-33957308e509",
        return_at_close: 1.0006499772099111,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 60658.5,
        close_ms: 0,
        current_return: 1.0058196295655184,
        initial_entry_price: 60658.5,
        is_closed_position: false,
        miner_hotkey: "5FWZ1VRRRqZGbY7XJ7RXyjEfeALhMWoe4XThSSNYQf3TDQFf",
        net_leverage: -0.14,
        open_ms: 1719601579013,
        orders: [
          {
            leverage: -0.14,
            order_type: "SHORT",
            order_uuid: "b3407435-0bac-4c6b-8c94-6f50b7cca114",
            price: 60658.5,
            price_sources: [],
            processed_ms: 1719601578205,
          },
        ],
        position_type: "SHORT",
        position_uuid: "9abc3746-97bf-4558-828f-8c0358644f87",
        return_at_close: 1.0057492221914488,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
    ],
    thirty_day_returns: 0.9915846650475864,
  },
  "5FZskLgm7uzjDyQRLKKGnT1YqPoMmXUmsb7aYkRJsq8dEDJe": {
    all_time_returns: 1.0,
    n_positions: 0,
    percentage_profitable: 0.0,
    positions: [
      {
        average_entry_price: 64486.88,
        close_ms: 1711194648009,
        current_return: 1.0002172534940441,
        initial_entry_price: 64486.88,
        is_closed_position: true,
        miner_hotkey: "5FZskLgm7uzjDyQRLKKGnT1YqPoMmXUmsb7aYkRJsq8dEDJe",
        net_leverage: 0.0,
        open_ms: 1711191067332,
        orders: [
          {
            leverage: 0.6,
            order_type: "LONG",
            order_uuid: "fbedc42c-b2fc-4cb8-ab55-fa09b3aa12cd",
            price: 64486.88,
            price_sources: [],
            processed_ms: 1711191067332,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "c854e2d2-3434-407e-bbd6-a4572b94c35a",
            price: 64510.23,
            price_sources: [],
            processed_ms: 1711194648009,
          },
        ],
        position_type: "FLAT",
        position_uuid: "eb50d161-ac9d-484c-a330-55d175cfe6e0",
        return_at_close: 0.9990169927898512,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 65273.35999999999,
        close_ms: 1711209295035,
        current_return: 0.9984489231135031,
        initial_entry_price: 65273.36,
        is_closed_position: true,
        miner_hotkey: "5FZskLgm7uzjDyQRLKKGnT1YqPoMmXUmsb7aYkRJsq8dEDJe",
        net_leverage: 0.0,
        open_ms: 1711205715558,
        orders: [
          {
            leverage: 0.6,
            order_type: "LONG",
            order_uuid: "8d8f2297-8557-4630-b736-cb69422085df",
            price: 65273.36,
            price_sources: [],
            processed_ms: 1711205715557,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "f13a2537-dd3b-48c3-ae29-bc3436095325",
            price: 65104.62,
            price_sources: [],
            processed_ms: 1711209295035,
          },
        ],
        position_type: "FLAT",
        position_uuid: "d1274905-2d11-42fa-87a6-d46e11c2d6f1",
        return_at_close: 0.9972507844057669,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 65138.23,
        close_ms: 1711212953715,
        current_return: 0.9960733658252612,
        initial_entry_price: 65138.23,
        is_closed_position: true,
        miner_hotkey: "5FZskLgm7uzjDyQRLKKGnT1YqPoMmXUmsb7aYkRJsq8dEDJe",
        net_leverage: 0.0,
        open_ms: 1711209381723,
        orders: [
          {
            leverage: -0.6,
            order_type: "SHORT",
            order_uuid: "af8c929f-f9c2-4d21-aaac-b3feef6b0e1b",
            price: 65138.23,
            price_sources: [],
            processed_ms: 1711209381722,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "fa636a52-7a4c-439b-a661-de09476f844f",
            price: 65564.52,
            price_sources: [],
            processed_ms: 1711212953715,
          },
        ],
        position_type: "FLAT",
        position_uuid: "2570e218-3841-4d27-93a3-8e3fff29cded",
        return_at_close: 0.9948780777862709,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 65734.55,
        close_ms: 1711216615715,
        current_return: 1.0008273274860784,
        initial_entry_price: 65734.55,
        is_closed_position: true,
        miner_hotkey: "5FZskLgm7uzjDyQRLKKGnT1YqPoMmXUmsb7aYkRJsq8dEDJe",
        net_leverage: 0.0,
        open_ms: 1711213041624,
        orders: [
          {
            leverage: -0.6,
            order_type: "SHORT",
            order_uuid: "f5dae5c2-6e78-4cb9-ab68-de449ad1419c",
            price: 65734.55,
            price_sources: [],
            processed_ms: 1711213041623,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "8e47cbf6-28cf-46bc-8074-38e5b895cf8b",
            price: 65643.91,
            price_sources: [],
            processed_ms: 1711216615715,
          },
        ],
        position_type: "FLAT",
        position_uuid: "5dbcbb03-45b3-4ee1-b68c-29f366ea862f",
        return_at_close: 0.9996263346930951,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 65653.65,
        close_ms: 1711220275826,
        current_return: 1.002562447023128,
        initial_entry_price: 65653.65,
        is_closed_position: true,
        miner_hotkey: "5FZskLgm7uzjDyQRLKKGnT1YqPoMmXUmsb7aYkRJsq8dEDJe",
        net_leverage: 0.0,
        open_ms: 1711216702649,
        orders: [
          {
            leverage: -0.6,
            order_type: "SHORT",
            order_uuid: "3a774e9f-169b-4834-9dfe-74d8fe03b790",
            price: 65653.65,
            price_sources: [],
            processed_ms: 1711216702648,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "e3720869-b977-4dde-a2ea-08a2c3de8297",
            price: 65373.26,
            price_sources: [],
            processed_ms: 1711220275826,
          },
        ],
        position_type: "FLAT",
        position_uuid: "8eadc8f6-2d6a-4bf6-952b-a68d24dde4bc",
        return_at_close: 1.0013593720867002,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 65427.020000000004,
        close_ms: 1711223955610,
        current_return: 1.0045196923228354,
        initial_entry_price: 65427.02,
        is_closed_position: true,
        miner_hotkey: "5FZskLgm7uzjDyQRLKKGnT1YqPoMmXUmsb7aYkRJsq8dEDJe",
        net_leverage: 0.0,
        open_ms: 1711220362666,
        orders: [
          {
            leverage: -0.6,
            order_type: "SHORT",
            order_uuid: "d6f4f00d-b81e-471c-8ca6-cb6320bf7f90",
            price: 65427.02,
            price_sources: [],
            processed_ms: 1711220362665,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "3969210c-5ee6-4eea-9f3b-80daa08031be",
            price: 64934.17,
            price_sources: [],
            processed_ms: 1711223955610,
          },
        ],
        position_type: "FLAT",
        position_uuid: "0ac7b025-cae2-4a1e-aceb-c9ae89bc89b8",
        return_at_close: 1.003314268692048,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 64875.2,
        close_ms: 1711227609950,
        current_return: 0.9985708252151824,
        initial_entry_price: 64875.2,
        is_closed_position: true,
        miner_hotkey: "5FZskLgm7uzjDyQRLKKGnT1YqPoMmXUmsb7aYkRJsq8dEDJe",
        net_leverage: 0.0,
        open_ms: 1711224042679,
        orders: [
          {
            leverage: -0.6,
            order_type: "SHORT",
            order_uuid: "c62b9b4a-3a1b-4296-a483-e445ef38f261",
            price: 64875.2,
            price_sources: [],
            processed_ms: 1711224042677,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "3459bcc3-9709-46c6-b9c9-05d5c50c79c9",
            price: 65029.73,
            price_sources: [],
            processed_ms: 1711227609950,
          },
        ],
        position_type: "FLAT",
        position_uuid: "ea3a6201-9156-4378-bfd9-30a35a0477b8",
        return_at_close: 0.9973725402249242,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 64974.45,
        close_ms: 1711231269693,
        current_return: 1.0003240350630132,
        initial_entry_price: 64974.45,
        is_closed_position: true,
        miner_hotkey: "5FZskLgm7uzjDyQRLKKGnT1YqPoMmXUmsb7aYkRJsq8dEDJe",
        net_leverage: 0.0,
        open_ms: 1711227737516,
        orders: [
          {
            leverage: -0.6,
            order_type: "SHORT",
            order_uuid: "3a7a0b8e-a1b3-4ab3-acf5-301fb2248de3",
            price: 64974.45,
            price_sources: [],
            processed_ms: 1711227737515,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "2a97ee58-453d-4e5b-9c85-7db0fe228bf5",
            price: 64939.36,
            price_sources: [],
            processed_ms: 1711231269693,
          },
        ],
        position_type: "FLAT",
        position_uuid: "cb14cae6-ca05-4c8d-a046-cc18fda510ac",
        return_at_close: 0.9991236462209375,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 64417.0,
        close_ms: 1711249580737,
        current_return: 0.9971514041324495,
        initial_entry_price: 64417.0,
        is_closed_position: true,
        miner_hotkey: "5FZskLgm7uzjDyQRLKKGnT1YqPoMmXUmsb7aYkRJsq8dEDJe",
        net_leverage: 0.0,
        open_ms: 1711246004820,
        orders: [
          {
            leverage: 0.6,
            order_type: "LONG",
            order_uuid: "e6936ea0-fcca-4143-a52b-aceb9f9f35f4",
            price: 64417.0,
            price_sources: [],
            processed_ms: 1711246004819,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "8a2a8c23-7668-47f4-9f8d-31a9939550c6",
            price: 64111.17,
            price_sources: [],
            processed_ms: 1711249580737,
          },
        ],
        position_type: "FLAT",
        position_uuid: "955cdeca-41be-4d3d-88d4-eb91acf6a00e",
        return_at_close: 0.9959548224474906,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 64118.44,
        close_ms: 1711253247836,
        current_return: 0.9996027663804671,
        initial_entry_price: 64118.44,
        is_closed_position: true,
        miner_hotkey: "5FZskLgm7uzjDyQRLKKGnT1YqPoMmXUmsb7aYkRJsq8dEDJe",
        net_leverage: 0.0,
        open_ms: 1711249667740,
        orders: [
          {
            leverage: 0.6,
            order_type: "LONG",
            order_uuid: "23888314-55af-46a2-b84d-3b4f155f0085",
            price: 64118.44,
            price_sources: [],
            processed_ms: 1711249667739,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "83a71f42-3fff-4263-a51d-6ee873870e98",
            price: 64075.99,
            price_sources: [],
            processed_ms: 1711253247836,
          },
        ],
        position_type: "FLAT",
        position_uuid: "1c697cc5-317b-4fa0-af4e-c7efd9978124",
        return_at_close: 0.9984032430608105,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 64137.0,
        close_ms: 1711259519484,
        current_return: 1.0022803685859956,
        initial_entry_price: 64137.0,
        is_closed_position: true,
        miner_hotkey: "5FZskLgm7uzjDyQRLKKGnT1YqPoMmXUmsb7aYkRJsq8dEDJe",
        net_leverage: 0.0,
        open_ms: 1711253350812,
        orders: [
          {
            leverage: 0.6,
            order_type: "LONG",
            order_uuid: "33771428-9d96-4e7b-a6cf-516ee3788755",
            price: 64137.0,
            price_sources: [],
            processed_ms: 1711253350811,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "77ed287c-718a-45b8-8f36-54d105a15ba9",
            price: 64380.76,
            price_sources: [],
            processed_ms: 1711259519484,
          },
        ],
        position_type: "FLAT",
        position_uuid: "8f407ad6-fdb5-45c5-a5d8-3f39e0ea823a",
        return_at_close: 1.0010776321436925,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 64368.530000000006,
        close_ms: 1711263173469,
        current_return: 0.9957187930188865,
        initial_entry_price: 64368.53,
        is_closed_position: true,
        miner_hotkey: "5FZskLgm7uzjDyQRLKKGnT1YqPoMmXUmsb7aYkRJsq8dEDJe",
        net_leverage: 0.0,
        open_ms: 1711259622627,
        orders: [
          {
            leverage: 1.25,
            order_type: "LONG",
            order_uuid: "7c456004-e5c3-4e01-b422-0e24153f825d",
            price: 64368.53,
            price_sources: [],
            processed_ms: 1711259622625,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "7358eb54-d3a0-4d45-bbe8-0d5ecdb7d5d1",
            price: 64148.07,
            price_sources: [],
            processed_ms: 1711263173469,
          },
        ],
        position_type: "FLAT",
        position_uuid: "eaf65fa7-03a2-48fd-9f98-c9b84362b955",
        return_at_close: 0.9932294960363394,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 64203.15,
        close_ms: 1711266833611,
        current_return: 1.003822631755607,
        initial_entry_price: 64203.15,
        is_closed_position: true,
        miner_hotkey: "5FZskLgm7uzjDyQRLKKGnT1YqPoMmXUmsb7aYkRJsq8dEDJe",
        net_leverage: 0.0,
        open_ms: 1711263277595,
        orders: [
          {
            leverage: 1.25,
            order_type: "LONG",
            order_uuid: "a13f06dd-ae0d-49af-bcad-8079b1f26793",
            price: 64203.15,
            price_sources: [],
            processed_ms: 1711263277594,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "846447fc-c847-4e43-9976-2c9f64673205",
            price: 64399.49,
            price_sources: [],
            processed_ms: 1711266833611,
          },
        ],
        position_type: "FLAT",
        position_uuid: "6d78c17d-c2bc-4415-a408-559e1e440c0e",
        return_at_close: 1.001313075176218,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 64329.89,
        close_ms: 1711270487069,
        current_return: 1.0141456405412788,
        initial_entry_price: 64329.89,
        is_closed_position: true,
        miner_hotkey: "5FZskLgm7uzjDyQRLKKGnT1YqPoMmXUmsb7aYkRJsq8dEDJe",
        net_leverage: 0.0,
        open_ms: 1711266936804,
        orders: [
          {
            leverage: 1.25,
            order_type: "LONG",
            order_uuid: "d44dc436-5dae-4df4-96f2-c2ebdbd83fbb",
            price: 64329.89,
            price_sources: [],
            processed_ms: 1711266936802,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "11c0f89d-ebd2-4820-81e9-54de22551a77",
            price: 65057.88,
            price_sources: [],
            processed_ms: 1711270487069,
          },
        ],
        position_type: "FLAT",
        position_uuid: "528d24ab-8373-466c-9e1d-294901da6d95",
        return_at_close: 1.0116102764399255,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 65068.02,
        close_ms: 1711274152584,
        current_return: 0.9993366557027554,
        initial_entry_price: 65068.02,
        is_closed_position: true,
        miner_hotkey: "5FZskLgm7uzjDyQRLKKGnT1YqPoMmXUmsb7aYkRJsq8dEDJe",
        net_leverage: 0.0,
        open_ms: 1711270614345,
        orders: [
          {
            leverage: 1.25,
            order_type: "LONG",
            order_uuid: "493ea08e-ba72-49ae-996c-db9eb768a23b",
            price: 65068.02,
            price_sources: [],
            processed_ms: 1711270614344,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "1d855ce8-b701-42f0-8c92-0a6097fd50d4",
            price: 65033.49,
            price_sources: [],
            processed_ms: 1711274152584,
          },
        ],
        position_type: "FLAT",
        position_uuid: "be514f8e-5974-4775-b579-f98c41b58e8e",
        return_at_close: 0.9968383140634985,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 65142.73,
        close_ms: 1711280853627,
        current_return: 1.001191422281504,
        initial_entry_price: 65142.73,
        is_closed_position: true,
        miner_hotkey: "5FZskLgm7uzjDyQRLKKGnT1YqPoMmXUmsb7aYkRJsq8dEDJe",
        net_leverage: 0.0,
        open_ms: 1711277900837,
        orders: [
          {
            leverage: 1.25,
            order_type: "LONG",
            order_uuid: "a1a7bbc0-dcf7-404a-853c-e9ffc35e85f6",
            price: 65142.73,
            price_sources: [],
            processed_ms: 1711277900836,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "1de90b80-db00-4b53-9214-2db863558399",
            price: 65204.82,
            price_sources: [],
            processed_ms: 1711280853627,
          },
        ],
        position_type: "FLAT",
        position_uuid: "f36a394c-d4df-4427-9160-7177060739ca",
        return_at_close: 0.9986884437258002,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 65294.79,
        close_ms: 1711284519658,
        current_return: 1.0066262943796893,
        initial_entry_price: 65294.79,
        is_closed_position: true,
        miner_hotkey: "5FZskLgm7uzjDyQRLKKGnT1YqPoMmXUmsb7aYkRJsq8dEDJe",
        net_leverage: 0.0,
        open_ms: 1711282689146,
        orders: [
          {
            leverage: 1.25,
            order_type: "LONG",
            order_uuid: "d28523c4-6ba6-411d-9aaf-d20096ea3c9b",
            price: 65294.79,
            price_sources: [],
            processed_ms: 1711282689144,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "48e3ffa3-b4a9-4687-97ae-6e513cc3ce0b",
            price: 65640.92,
            price_sources: [],
            processed_ms: 1711284519658,
          },
        ],
        position_type: "FLAT",
        position_uuid: "f9578207-14a0-4fba-8021-a4600d901521",
        return_at_close: 1.0041097286437402,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 65586.15,
        close_ms: 1711297350972,
        current_return: 0.9995412522308443,
        initial_entry_price: 65586.15,
        is_closed_position: true,
        miner_hotkey: "5FZskLgm7uzjDyQRLKKGnT1YqPoMmXUmsb7aYkRJsq8dEDJe",
        net_leverage: 0.0,
        open_ms: 1711295511951,
        orders: [
          {
            leverage: -1.25,
            order_type: "SHORT",
            order_uuid: "88345ed5-4750-4ef4-b831-58007a5f8230",
            price: 65586.15,
            price_sources: [],
            processed_ms: 1711295511950,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "2258ddb1-a315-4de3-bb93-cdc51dd827e0",
            price: 65610.22,
            price_sources: [],
            processed_ms: 1711297350972,
          },
        ],
        position_type: "FLAT",
        position_uuid: "05c6ef9a-1cdf-4a33-aeb8-02c6a69a7b2f",
        return_at_close: 0.9970423991002673,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 64976.98,
        close_ms: 1711305573598,
        current_return: 1.0127916455951016,
        initial_entry_price: 64976.98,
        is_closed_position: true,
        miner_hotkey: "5FZskLgm7uzjDyQRLKKGnT1YqPoMmXUmsb7aYkRJsq8dEDJe",
        net_leverage: 0.0,
        open_ms: 1711301017706,
        orders: [
          {
            leverage: 1.25,
            order_type: "LONG",
            order_uuid: "959b6bbb-8513-4539-9894-a6bf5b6875af",
            price: 64976.98,
            price_sources: [],
            processed_ms: 1711301017705,
          },
          {
            leverage: -1.25,
            order_type: "SHORT",
            order_uuid: "3784ea3a-3728-483e-b236-e8f9c8708595",
            price: 65641.91,
            price_sources: [],
            processed_ms: 1711305573598,
          },
        ],
        position_type: "FLAT",
        position_uuid: "029659ab-5c92-411b-814c-8e0dad3d4146",
        return_at_close: 1.0102596664811139,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 65913.89000000001,
        close_ms: 1711313016751,
        current_return: 0.9965516421115627,
        initial_entry_price: 66105.74,
        is_closed_position: true,
        miner_hotkey: "5FZskLgm7uzjDyQRLKKGnT1YqPoMmXUmsb7aYkRJsq8dEDJe",
        net_leverage: 0.0,
        open_ms: 1711311162794,
        orders: [
          {
            leverage: -1.25,
            order_type: "SHORT",
            order_uuid: "58af92fe-906b-461d-b05b-52b30d609f6f",
            price: 66105.74,
            price_sources: [],
            processed_ms: 1711311162792,
          },
          {
            leverage: 0.625,
            order_type: "LONG",
            order_uuid: "ec7b82f5-b81a-4988-95b0-d20764ad9081",
            price: 66297.59,
            price_sources: [],
            processed_ms: 1711312091710,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "5be2e9af-69e2-4f0a-8b35-074edbb65839",
            price: 66278.62,
            price_sources: [],
            processed_ms: 1711313016751,
          },
        ],
        position_type: "FLAT",
        position_uuid: "c0e04d73-1134-401c-ac00-a29ccdd10356",
        return_at_close: 0.9940602630062838,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 28204.79444444181,
        close_ms: 1711325172792,
        current_return: 0.9794736019255919,
        initial_entry_price: 66383.18,
        is_closed_position: true,
        miner_hotkey: "5FZskLgm7uzjDyQRLKKGnT1YqPoMmXUmsb7aYkRJsq8dEDJe",
        net_leverage: 0.0,
        open_ms: 1711315811736,
        orders: [
          {
            leverage: -1.25,
            order_type: "SHORT",
            order_uuid: "f232ed3f-31e3-480a-919d-ddbc4c7bfb87",
            price: 66383.18,
            price_sources: [],
            processed_ms: 1711315811734,
          },
          {
            leverage: -1.25,
            order_type: "SHORT",
            order_uuid: "dec62a7e-9c4f-48aa-bbe6-a3410be92918",
            price: 66301.54,
            price_sources: [],
            processed_ms: 1711316733648,
          },
          {
            leverage: 0.125,
            order_type: "LONG",
            order_uuid: "82e964f7-2812-43e7-a735-9e326abe00db",
            price: 66491.32,
            price_sources: [],
            processed_ms: 1711317699044,
          },
          {
            leverage: 1.1875,
            order_type: "LONG",
            order_uuid: "7aca6349-a4ad-4464-a96d-e22392d05eb2",
            price: 66796.57,
            price_sources: [],
            processed_ms: 1711318604001,
          },
          {
            leverage: 0.125,
            order_type: "LONG",
            order_uuid: "142fc5b7-2e67-4d06-91fd-59630ae3d89f",
            price: 66952.24,
            price_sources: [],
            processed_ms: 1711319539258,
          },
          {
            leverage: 0.53125,
            order_type: "LONG",
            order_uuid: "a095f361-299b-4c4c-90e8-5cf78aafee50",
            price: 66879.37,
            price_sources: [],
            processed_ms: 1711320468100,
          },
          {
            leverage: 0.265625,
            order_type: "LONG",
            order_uuid: "78956975-80cd-4197-915c-961746a5bdfb",
            price: 67076.1,
            price_sources: [],
            processed_ms: 1711321441144,
          },
          {
            leverage: 0.125,
            order_type: "LONG",
            order_uuid: "e08036e0-28e1-47c9-abf0-88f8b8de36a8",
            price: 67345.9,
            price_sources: [],
            processed_ms: 1711322336961,
          },
          {
            leverage: 0.0703125,
            order_type: "LONG",
            order_uuid: "4fde0f59-fbee-4aca-bd13-61b0a81552bc",
            price: 67285.93,
            price_sources: [],
            processed_ms: 1711323267925,
          },
          {
            leverage: 0.03515625,
            order_type: "LONG",
            order_uuid: "b1288e0e-d2ff-4bed-b347-c250419c1adb",
            price: 67325.71,
            price_sources: [],
            processed_ms: 1711324204967,
          },
          {
            leverage: 0.125,
            order_type: "LONG",
            order_uuid: "531856f2-aa48-4c73-99f9-9a9c0f9c8f6f",
            price: 66963.41,
            price_sources: [],
            processed_ms: 1711325172792,
          },
        ],
        position_type: "FLAT",
        position_uuid: "129f3e13-90e8-4c1b-b27b-372c3bc3be91",
        return_at_close: 0.9745762339159639,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 115495.02428571216,
        close_ms: 1711340816111,
        current_return: 1.0039618144151734,
        initial_entry_price: 66841.2,
        is_closed_position: true,
        miner_hotkey: "5FZskLgm7uzjDyQRLKKGnT1YqPoMmXUmsb7aYkRJsq8dEDJe",
        net_leverage: 0.0,
        open_ms: 1711326059916,
        orders: [
          {
            leverage: -1.25,
            order_type: "SHORT",
            order_uuid: "87fc4bf9-9a95-4408-abd0-84beef235288",
            price: 66841.2,
            price_sources: [],
            processed_ms: 1711326059913,
          },
          {
            leverage: 0.125,
            order_type: "LONG",
            order_uuid: "7c942f91-fa94-4a7e-a516-c37baf31ef78",
            price: 66716.4,
            price_sources: [],
            processed_ms: 1711326995833,
          },
          {
            leverage: 0.125,
            order_type: "LONG",
            order_uuid: "ae1a7d20-b9a2-47fa-ab16-c7dc24d6c0b2",
            price: 66567.27,
            price_sources: [],
            processed_ms: 1711327929593,
          },
          {
            leverage: 0.125,
            order_type: "LONG",
            order_uuid: "2de3a716-f6f5-4750-acac-87f02946cd8f",
            price: 66670.65,
            price_sources: [],
            processed_ms: 1711328860152,
          },
          {
            leverage: 0.4375,
            order_type: "LONG",
            order_uuid: "cfa3408d-1b05-4a35-beea-fddaa0c4332b",
            price: 66669.52,
            price_sources: [],
            processed_ms: 1711329829670,
          },
          {
            leverage: 0.21875,
            order_type: "LONG",
            order_uuid: "67abed88-58bb-4bf5-b264-891f2aaa91b5",
            price: 66478.85,
            price_sources: [],
            processed_ms: 1711331685797,
          },
          {
            leverage: 0.109375,
            order_type: "LONG",
            order_uuid: "197cb27b-0455-4238-b95f-c694c5e819a7",
            price: 66565.86,
            price_sources: [],
            processed_ms: 1711333480691,
          },
          {
            leverage: 0.0875,
            order_type: "LONG",
            order_uuid: "de5ccf6d-89be-4bac-91c8-b01de5768afa",
            price: 66713.37,
            price_sources: [],
            processed_ms: 1711335313873,
          },
          {
            leverage: 0.010937500000000003,
            order_type: "LONG",
            order_uuid: "6eee04c5-69a7-4e36-8781-fc89fa9b30dc",
            price: 66801.13,
            price_sources: [],
            processed_ms: 1711337146512,
          },
          {
            leverage: 0.005468750000000001,
            order_type: "LONG",
            order_uuid: "9173c954-a627-4bb3-a2a6-14a229ba7d2a",
            price: 67060.11,
            price_sources: [],
            processed_ms: 1711338979925,
          },
          {
            leverage: 0.125,
            order_type: "LONG",
            order_uuid: "bb653ff4-b523-4834-a52e-dffbac224592",
            price: 67072.18,
            price_sources: [],
            processed_ms: 1711340816111,
          },
        ],
        position_type: "FLAT",
        position_uuid: "448f370f-a500-4683-91dc-39203bb16ed5",
        return_at_close: 1.0014519098791355,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 67049.99,
        close_ms: 1711362851052,
        current_return: 1.0019129383911913,
        initial_entry_price: 67049.99,
        is_closed_position: true,
        miner_hotkey: "5FZskLgm7uzjDyQRLKKGnT1YqPoMmXUmsb7aYkRJsq8dEDJe",
        net_leverage: 0.0,
        open_ms: 1711360973116,
        orders: [
          {
            leverage: 1.25,
            order_type: "LONG",
            order_uuid: "a90e5258-1ef5-4cb6-9944-e46269c32a31",
            price: 67049.99,
            price_sources: [],
            processed_ms: 1711360973114,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "c88bea9c-70c7-47a3-9b72-05f5a580e0bb",
            price: 67152.6,
            price_sources: [],
            processed_ms: 1711362851052,
          },
        ],
        position_type: "FLAT",
        position_uuid: "054862b3-ef9e-47d8-a185-cfca96c8bbc0",
        return_at_close: 0.9994081560452134,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: -429046.90000000084,
        close_ms: 1711383508070,
        current_return: 1.016222092705557,
        initial_entry_price: 67253.25,
        is_closed_position: true,
        miner_hotkey: "5FZskLgm7uzjDyQRLKKGnT1YqPoMmXUmsb7aYkRJsq8dEDJe",
        net_leverage: 0.0,
        open_ms: 1711370150930,
        orders: [
          {
            leverage: 1.25,
            order_type: "LONG",
            order_uuid: "a8c2763f-048f-43c7-bb5a-051c469f46da",
            price: 67253.25,
            price_sources: [],
            processed_ms: 1711370150928,
          },
          {
            leverage: -0.625,
            order_type: "SHORT",
            order_uuid: "febdf086-abd7-4b55-b2b7-a2c65c652228",
            price: 67185.79,
            price_sources: [],
            processed_ms: 1711371979999,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "96ef5aea-e2fb-4685-a801-21a9de429252",
            price: 67212.0,
            price_sources: [],
            processed_ms: 1711373812354,
          },
          {
            leverage: -0.4375,
            order_type: "SHORT",
            order_uuid: "61c99d87-ca5c-4d00-88fb-5260d93fa111",
            price: 67827.85,
            price_sources: [],
            processed_ms: 1711375652559,
          },
          {
            leverage: -0.35,
            order_type: "SHORT",
            order_uuid: "092434f7-a86c-4cce-a8b6-bc71d660f971",
            price: 69235.28,
            price_sources: [],
            processed_ms: 1711376586568,
          },
          {
            leverage: -0.04375000000000001,
            order_type: "SHORT",
            order_uuid: "aa35a1b8-bca9-462a-87e4-55051ef26d40",
            price: 69272.14,
            price_sources: [],
            processed_ms: 1711376901034,
          },
          {
            leverage: -0.021875000000000006,
            order_type: "SHORT",
            order_uuid: "7932057d-c9f0-483d-8d2c-250b54880245",
            price: 69244.36,
            price_sources: [],
            processed_ms: 1711378066328,
          },
          {
            leverage: -0.017500000000000005,
            order_type: "SHORT",
            order_uuid: "d5a09754-f607-4767-b613-1529be18b576",
            price: 69295.36,
            price_sources: [],
            processed_ms: 1711378334628,
          },
          {
            leverage: -0.0021875,
            order_type: "SHORT",
            order_uuid: "4fabd140-7c6b-408c-854c-b1c06ca18180",
            price: 69442.82,
            price_sources: [],
            processed_ms: 1711380203053,
          },
          {
            leverage: -5.0021875,
            order_type: "SHORT",
            order_uuid: "ccd87869-63bb-446a-affa-5348aebbe648",
            price: 69690.68,
            price_sources: [],
            processed_ms: 1711383508070,
          },
        ],
        position_type: "FLAT",
        position_uuid: "ccec1706-6f79-4073-a4a8-e990cf8477d7",
        return_at_close: 1.013681537473793,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 70035.89,
        close_ms: 1711386623293,
        current_return: 0.9548931269382022,
        initial_entry_price: 70035.89,
        is_closed_position: true,
        miner_hotkey: "5FZskLgm7uzjDyQRLKKGnT1YqPoMmXUmsb7aYkRJsq8dEDJe",
        net_leverage: 0.0,
        open_ms: 1711385321071,
        orders: [
          {
            leverage: -5.0,
            order_type: "SHORT",
            order_uuid: "f990b7b1-636a-45bb-bbc0-76c780287e72",
            price: 70035.89,
            price_sources: [],
            processed_ms: 1711385321069,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "208809c3-c731-42e9-9d72-01eec279e92a",
            price: 70667.71,
            price_sources: [],
            processed_ms: 1711386623293,
          },
        ],
        position_type: "FLAT",
        position_uuid: "d904bd5e-79cf-46ae-a91a-53045b466ed9",
        return_at_close: 0.9453441956688201,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 70773.82719999999,
        close_ms: 1711401306953,
        current_return: 0.9548589130914873,
        initial_entry_price: 70644.4,
        is_closed_position: true,
        miner_hotkey: "5FZskLgm7uzjDyQRLKKGnT1YqPoMmXUmsb7aYkRJsq8dEDJe",
        net_leverage: 0.0,
        open_ms: 1711390774884,
        orders: [
          {
            leverage: 5.0,
            order_type: "LONG",
            order_uuid: "fbda9349-586d-45f4-9d42-e5d3ba9af8dc",
            price: 70644.4,
            price_sources: [],
            processed_ms: 1711390774882,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "5c060574-be2d-4a2b-aebe-758e5dbf8fb4",
            price: 70799.99,
            price_sources: [],
            processed_ms: 1711392585066,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "868a8a9d-0c39-47f7-9171-967c7d9c1925",
            price: 70758.49,
            price_sources: [],
            processed_ms: 1711394448891,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "1464be48-6a3a-47be-841e-10427f7673b1",
            price: 70910.32,
            price_sources: [],
            processed_ms: 1711396228035,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "cd341911-9fbf-41ac-807d-29353f569409",
            price: 70872.35,
            price_sources: [],
            processed_ms: 1711398051328,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "7c9ace9f-2f88-4aa3-b8e0-00872a2e41e9",
            price: 70959.41,
            price_sources: [],
            processed_ms: 1711399904960,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "97d9716c-8231-4e04-8219-2dbf2558cb16",
            price: 70518.71,
            price_sources: [],
            processed_ms: 1711401306953,
          },
        ],
        position_type: "FLAT",
        position_uuid: "2998b4d7-7b1f-4a33-92b6-8d7a8c8d4fa3",
        return_at_close: 0.9309874402642001,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
    ],
    thirty_day_returns: 1.0,
  },
  "5FbaR3qjbbnYpkDCkuh4TUqqen1UMSscqjmhoDWQgGRh189o": {
    all_time_returns: 1.006818817317601,
    n_positions: 17,
    percentage_profitable: 0.5294117647058824,
    positions: [
      {
        average_entry_price: 1.0758075,
        close_ms: 1718197271072,
        current_return: 1.0060671496238507,
        initial_entry_price: 1.0767,
        is_closed_position: true,
        miner_hotkey: "5FbaR3qjbbnYpkDCkuh4TUqqen1UMSscqjmhoDWQgGRh189o",
        net_leverage: 0.0,
        open_ms: 1718089486263,
        orders: [
          {
            leverage: 0.5,
            order_type: "LONG",
            order_uuid: "38107172-8702-4531-a675-809419e60c3d",
            price: 1.0767,
            price_sources: [],
            processed_ms: 1718089486197,
          },
          {
            leverage: 0.5,
            order_type: "LONG",
            order_uuid: "780e8c9e-617d-470e-814b-b7d0d1096415",
            price: 1.074915,
            price_sources: [],
            processed_ms: 1718096182874,
          },
          {
            leverage: 0.5,
            order_type: "FLAT",
            order_uuid: "34eb4739-e983-4990-93d1-0580b6e94501",
            price: 1.08234,
            price_sources: [],
            processed_ms: 1718197271072,
          },
        ],
        position_type: "FLAT",
        position_uuid: "cc8d5132-16b7-4e5d-8828-36b1693c236f",
        return_at_close: 1.005996724923377,
        trade_pair: ["EURUSD", "EUR/USD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.845575,
        close_ms: 1718197301487,
        current_return: 0.9959777922154628,
        initial_entry_price: 0.84655,
        is_closed_position: true,
        miner_hotkey: "5FbaR3qjbbnYpkDCkuh4TUqqen1UMSscqjmhoDWQgGRh189o",
        net_leverage: 0.0,
        open_ms: 1718089515302,
        orders: [
          {
            leverage: 0.5,
            order_type: "LONG",
            order_uuid: "794d189d-1bd3-4717-8a3c-463724ff2ee8",
            price: 0.84655,
            price_sources: [],
            processed_ms: 1718089515299,
          },
          {
            leverage: 0.5,
            order_type: "LONG",
            order_uuid: "3a83f057-9112-42e8-9215-a1312fc11b1f",
            price: 0.8446,
            price_sources: [],
            processed_ms: 1718096139711,
          },
          {
            leverage: 0.5,
            order_type: "FLAT",
            order_uuid: "dcdfa8d6-949b-4f97-8c8a-fe6df57241c1",
            price: 0.84217,
            price_sources: [],
            processed_ms: 1718197301487,
          },
        ],
        position_type: "FLAT",
        position_uuid: "4ea04bab-ca9e-40c7-8339-d1eeecc601c9",
        return_at_close: 0.9959080737700077,
        trade_pair: ["EURGBP", "EUR/GBP", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 1.0716700000000001,
        close_ms: 1718282591740,
        current_return: 1.0039358762771895,
        initial_entry_price: 1.07854,
        is_closed_position: true,
        miner_hotkey: "5FbaR3qjbbnYpkDCkuh4TUqqen1UMSscqjmhoDWQgGRh189o",
        net_leverage: 0.0,
        open_ms: 1718280904269,
        orders: [
          {
            leverage: 1.0,
            order_type: "LONG",
            order_uuid: "07437404-5af8-4032-a449-0b94980428f2",
            price: 1.07854,
            price_sources: [],
            processed_ms: 1718280903981,
          },
          {
            leverage: 1.0,
            order_type: "LONG",
            order_uuid: "304de8bb-7fec-4367-9911-69bed63ca58f",
            price: 1.079065,
            price_sources: [],
            processed_ms: 1718281780908,
          },
          {
            leverage: -1.5,
            order_type: "SHORT",
            order_uuid: "f089d835-7eba-4bba-88e1-ab7d6f1312d7",
            price: 1.08118,
            price_sources: [],
            processed_ms: 1718282056164,
          },
          {
            leverage: 0.01,
            order_type: "FLAT",
            order_uuid: "05cd6ee5-60f3-401b-9472-dbcb020202de",
            price: 1.08016,
            price_sources: [],
            processed_ms: 1718282591740,
          },
        ],
        position_type: "FLAT",
        position_uuid: "65c9d20f-503f-4789-a134-49c2ca1c283b",
        return_at_close: 1.0037953252545107,
        trade_pair: ["EURUSD", "EUR/USD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 1.4825533333333334,
        close_ms: 1718283468783,
        current_return: 1.0000320491194925,
        initial_entry_price: 1.4821,
        is_closed_position: true,
        miner_hotkey: "5FbaR3qjbbnYpkDCkuh4TUqqen1UMSscqjmhoDWQgGRh189o",
        net_leverage: 0.0,
        open_ms: 1718089546414,
        orders: [
          {
            leverage: 0.5,
            order_type: "LONG",
            order_uuid: "1dd9c5da-8c38-4c0f-9f7b-3cea7ab0f8a4",
            price: 1.4821,
            price_sources: [],
            processed_ms: 1718089546404,
          },
          {
            leverage: 0.5,
            order_type: "LONG",
            order_uuid: "b4f00cf0-7e43-47dc-ba73-894f58214da7",
            price: 1.481115,
            price_sources: [],
            processed_ms: 1718100469132,
          },
          {
            leverage: 0.5,
            order_type: "LONG",
            order_uuid: "f033f22b-5505-4339-bf40-6ff48a4ecdab",
            price: 1.484445,
            price_sources: [],
            processed_ms: 1718208551001,
          },
          {
            leverage: 0.01,
            order_type: "FLAT",
            order_uuid: "cd42cdec-93d2-4ff2-bb0d-90940fce938e",
            price: 1.482585,
            price_sources: [],
            processed_ms: 1718283468783,
          },
        ],
        position_type: "FLAT",
        position_uuid: "45c14bc5-1402-472f-975c-70ec32aea83f",
        return_at_close: 0.9999270457543349,
        trade_pair: ["EURCAD", "EUR/CAD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 1.74644,
        close_ms: 1718299456236,
        current_return: 1.0014629761114038,
        initial_entry_price: 1.74644,
        is_closed_position: true,
        miner_hotkey: "5FbaR3qjbbnYpkDCkuh4TUqqen1UMSscqjmhoDWQgGRh189o",
        net_leverage: 0.0,
        open_ms: 1718283698931,
        orders: [
          {
            leverage: -0.5,
            order_type: "SHORT",
            order_uuid: "690c4319-a7b3-40c8-abb9-1f1a260aeea7",
            price: 1.74644,
            price_sources: [],
            processed_ms: 1718283698753,
          },
          {
            leverage: 0.01,
            order_type: "FLAT",
            order_uuid: "03f48c19-1131-44c3-9d3b-8e1706a306c4",
            price: 1.74133,
            price_sources: [],
            processed_ms: 1718299456236,
          },
        ],
        position_type: "FLAT",
        position_uuid: "482ab680-a574-40d9-9fc2-f6964abc32a0",
        return_at_close: 1.00142792490724,
        trade_pair: ["EURNZD", "EUR/NZD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.96057,
        close_ms: 1718345241281,
        current_return: 1.0004580613594012,
        initial_entry_price: 0.96057,
        is_closed_position: true,
        miner_hotkey: "5FbaR3qjbbnYpkDCkuh4TUqqen1UMSscqjmhoDWQgGRh189o",
        net_leverage: 0.0,
        open_ms: 1718299910607,
        orders: [
          {
            leverage: -0.5,
            order_type: "SHORT",
            order_uuid: "5cacb329-0c72-4c0f-849f-42a323963472",
            price: 0.96057,
            price_sources: [],
            processed_ms: 1718299910599,
          },
          {
            leverage: 0.5,
            order_type: "FLAT",
            order_uuid: "fcda7380-236e-4d83-a3f9-79a72e92746b",
            price: 0.9596899999999999,
            price_sources: [],
            processed_ms: 1718345241281,
          },
        ],
        position_type: "FLAT",
        position_uuid: "c0a8d43c-506d-42cc-8f09-c2cbcce609b9",
        return_at_close: 1.0004230453272536,
        trade_pair: ["EURCHF", "EUR/CHF", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.84207,
        close_ms: 1718349937917,
        current_return: 0.9998159297920601,
        initial_entry_price: 0.84207,
        is_closed_position: true,
        miner_hotkey: "5FbaR3qjbbnYpkDCkuh4TUqqen1UMSscqjmhoDWQgGRh189o",
        net_leverage: 0.0,
        open_ms: 1718299817490,
        orders: [
          {
            leverage: 0.5,
            order_type: "LONG",
            order_uuid: "02d74e76-ac73-4c3c-9cde-5fac754c3606",
            price: 0.84207,
            price_sources: [],
            processed_ms: 1718299817482,
          },
          {
            leverage: 0.5,
            order_type: "FLAT",
            order_uuid: "3590214e-6234-4f61-8e95-169aff16aef7",
            price: 0.8417600000000001,
            price_sources: [],
            processed_ms: 1718349937917,
          },
        ],
        position_type: "FLAT",
        position_uuid: "8b2ad222-6a90-44f9-af5e-9737a628b657",
        return_at_close: 0.9997809362345174,
        trade_pair: ["EURGBP", "EUR/GBP", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 169.1925,
        close_ms: 1718361409157,
        current_return: 1.003831434608508,
        initial_entry_price: 169.1925,
        is_closed_position: true,
        miner_hotkey: "5FbaR3qjbbnYpkDCkuh4TUqqen1UMSscqjmhoDWQgGRh189o",
        net_leverage: 0.0,
        open_ms: 1718349968388,
        orders: [
          {
            leverage: -0.5,
            order_type: "SHORT",
            order_uuid: "0ecf1a7c-98a6-4a1c-be99-85ddd6a20b50",
            price: 169.1925,
            price_sources: [],
            processed_ms: 1718349968378,
          },
          {
            leverage: 0.01,
            order_type: "FLAT",
            order_uuid: "73f6c7a6-f4a8-4fc3-a103-72eafdd58a9c",
            price: 167.89600000000002,
            price_sources: [],
            processed_ms: 1718361409157,
          },
        ],
        position_type: "FLAT",
        position_uuid: "c2e28e13-4d86-45c5-8e44-54998fce8ce1",
        return_at_close: 1.0037963005082966,
        trade_pair: ["EURJPY", "EUR/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 157.595,
        close_ms: 1718364572937,
        current_return: 1.0024779074324581,
        initial_entry_price: 158.198,
        is_closed_position: true,
        miner_hotkey: "5FbaR3qjbbnYpkDCkuh4TUqqen1UMSscqjmhoDWQgGRh189o",
        net_leverage: 0.0,
        open_ms: 1718345396532,
        orders: [
          {
            leverage: -0.5,
            order_type: "SHORT",
            order_uuid: "ac137ed0-a243-46bc-82c8-f4335da67fe8",
            price: 158.198,
            price_sources: [],
            processed_ms: 1718345396469,
          },
          {
            leverage: -0.5,
            order_type: "SHORT",
            order_uuid: "a11e345d-9329-4d57-ade0-5daa034508e1",
            price: 156.992,
            price_sources: [],
            processed_ms: 1718360000321,
          },
          {
            leverage: 0.01,
            order_type: "FLAT",
            order_uuid: "dbffaedd-675c-4015-a324-1a3a17ce60b3",
            price: 157.203,
            price_sources: [],
            processed_ms: 1718364572937,
          },
        ],
        position_type: "FLAT",
        position_uuid: "026992b1-3fae-47e7-8aa7-5b429d08f784",
        return_at_close: 1.002407733978938,
        trade_pair: ["USDJPY", "USD/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.6658850000000001,
        close_ms: 1718367279962,
        current_return: 0.9966060205591055,
        initial_entry_price: 0.6658850000000001,
        is_closed_position: true,
        miner_hotkey: "5FbaR3qjbbnYpkDCkuh4TUqqen1UMSscqjmhoDWQgGRh189o",
        net_leverage: 0.0,
        open_ms: 1718283614297,
        orders: [
          {
            leverage: 0.5,
            order_type: "LONG",
            order_uuid: "4d1d6a26-2327-4e35-a062-4c9d389d9c2e",
            price: 0.6658850000000001,
            price_sources: [],
            processed_ms: 1718283614288,
          },
          {
            leverage: 0.01,
            order_type: "FLAT",
            order_uuid: "8d8a6908-1cc6-4dc3-97c9-bde7ed46a2bb",
            price: 0.661365,
            price_sources: [],
            processed_ms: 1718367279962,
          },
        ],
        position_type: "FLAT",
        position_uuid: "65f3150f-0c88-4d41-8d02-77a05a0e6dc1",
        return_at_close: 0.9965711393483859,
        trade_pair: ["AUDUSD", "AUD/USD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.61331,
        close_ms: 1718604382625,
        current_return: 1.0028044545172914,
        initial_entry_price: 0.61331,
        is_closed_position: true,
        miner_hotkey: "5FbaR3qjbbnYpkDCkuh4TUqqen1UMSscqjmhoDWQgGRh189o",
        net_leverage: 0.0,
        open_ms: 1718367351989,
        orders: [
          {
            leverage: -1.0,
            order_type: "SHORT",
            order_uuid: "a0a6c1ac-d20c-4ad4-bdda-9f66b026a623",
            price: 0.61331,
            price_sources: [],
            processed_ms: 1718367351973,
          },
          {
            leverage: 0.01,
            order_type: "FLAT",
            order_uuid: "bfdf20b7-ca27-4184-b2c3-e2be0a02dc87",
            price: 0.6115900000000001,
            price_sources: [],
            processed_ms: 1718604382625,
          },
        ],
        position_type: "FLAT",
        position_uuid: "be28d92d-7d88-4bbf-8c4c-e19715bf4cbb",
        return_at_close: 1.0027342582054752,
        trade_pair: ["NZDUSD", "NZD/USD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 169.474,
        close_ms: 1718714441495,
        current_return: 1.002726081876866,
        initial_entry_price: 169.474,
        is_closed_position: true,
        miner_hotkey: "5FbaR3qjbbnYpkDCkuh4TUqqen1UMSscqjmhoDWQgGRh189o",
        net_leverage: 0.0,
        open_ms: 1718701234811,
        orders: [
          {
            leverage: -2.0,
            order_type: "SHORT",
            order_uuid: "d24e0c16-6b5e-4be9-9d75-d9cf8b7e1a18",
            price: 169.474,
            price_sources: [],
            processed_ms: 1718701234784,
          },
          {
            leverage: 0.01,
            order_type: "FLAT",
            order_uuid: "9952d282-cf5c-4efb-ac8b-45a50cc4a4c7",
            price: 169.243,
            price_sources: [],
            processed_ms: 1718714441495,
          },
        ],
        position_type: "FLAT",
        position_uuid: "b3d085c8-3f8f-4ed4-952f-56f6b10f800c",
        return_at_close: 1.0025857002254033,
        trade_pair: ["EURJPY", "EUR/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 1.26701,
        close_ms: 1718780524724,
        current_return: 0.9860996997945963,
        initial_entry_price: 1.2658,
        is_closed_position: true,
        miner_hotkey: "5FbaR3qjbbnYpkDCkuh4TUqqen1UMSscqjmhoDWQgGRh189o",
        net_leverage: 0.0,
        open_ms: 1718373067353,
        orders: [
          {
            leverage: -1.0,
            order_type: "SHORT",
            order_uuid: "3212a960-a2fa-4f67-aae7-d406e39073a5",
            price: 1.2658,
            price_sources: [],
            processed_ms: 1718373067230,
          },
          {
            leverage: -1.0,
            order_type: "SHORT",
            order_uuid: "f26f3ba2-bfce-478f-8d1b-cdf3007a5442",
            price: 1.26657,
            price_sources: [],
            processed_ms: 1718604477955,
          },
          {
            leverage: -1.0,
            order_type: "SHORT",
            order_uuid: "139efb66-40ba-4d42-9f96-117f27ea6a50",
            price: 1.26866,
            price_sources: [],
            processed_ms: 1718638681989,
          },
          {
            leverage: 0.01,
            order_type: "FLAT",
            order_uuid: "6ce9af9b-a7e5-4cbc-8d63-f2ccf23949f2",
            price: 1.272875,
            price_sources: [],
            processed_ms: 1718780524724,
          },
        ],
        position_type: "FLAT",
        position_uuid: "bfdc9273-b996-475f-a0af-21ba29168688",
        return_at_close: 0.9858926188576395,
        trade_pair: ["GBPUSD", "GBP/USD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 1.07029,
        close_ms: 1718780556041,
        current_return: 0.9943654062149009,
        initial_entry_price: 1.0684,
        is_closed_position: true,
        miner_hotkey: "5FbaR3qjbbnYpkDCkuh4TUqqen1UMSscqjmhoDWQgGRh189o",
        net_leverage: 0.0,
        open_ms: 1718367313012,
        orders: [
          {
            leverage: -1.0,
            order_type: "SHORT",
            order_uuid: "897f0179-2789-4b20-856f-6d29caa0d869",
            price: 1.0684,
            price_sources: [],
            processed_ms: 1718367312951,
          },
          {
            leverage: -1.0,
            order_type: "SHORT",
            order_uuid: "74f1cbe5-85fb-44af-b901-e0478d80b46a",
            price: 1.07218,
            price_sources: [],
            processed_ms: 1718638636113,
          },
          {
            leverage: 0.01,
            order_type: "FLAT",
            order_uuid: "a8b90909-fb78-4d67-aa92-cc3a88b2d9c1",
            price: 1.0733,
            price_sources: [],
            processed_ms: 1718780556041,
          },
        ],
        position_type: "FLAT",
        position_uuid: "6696dc0b-a571-4ccd-9f43-a046cdf280d6",
        return_at_close: 0.9942261950580308,
        trade_pair: ["EURUSD", "EUR/USD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 170.986,
        close_ms: 1720592713980,
        current_return: 0.8722351537552775,
        initial_entry_price: 170.986,
        is_closed_position: true,
        miner_hotkey: "5FbaR3qjbbnYpkDCkuh4TUqqen1UMSscqjmhoDWQgGRh189o",
        net_leverage: 0.0,
        open_ms: 1719210516144,
        orders: [
          {
            leverage: -6.0,
            order_type: "SHORT",
            order_uuid: "dde67dd3-13a4-4243-ae0d-a2fbccc78357",
            price: 170.986,
            price_sources: [],
            processed_ms: 1719210516126,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "0be8d19b-c11a-47f8-a24d-d68f2864a634",
            price: 174.627,
            price_sources: [
              {
                close: 174.627,
                high: 174.627,
                lag_ms: 19,
                low: 174.627,
                open: 174.627,
                source: "Polygon_ws",
                start_ms: 1720592713999,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 174.644,
                websocket: true,
              },
              {
                close: 174.638,
                high: 174.638,
                lag_ms: 980,
                low: 174.638,
                open: 174.638,
                source: "TwelveData_ws",
                start_ms: 1720592713000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1720592713980,
          },
        ],
        position_type: "FLAT",
        position_uuid: "00acec0d-da8d-4540-a9c3-3914735f6a34",
        return_at_close: 0.8718688149907003,
        trade_pair: ["EURJPY", "EUR/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 157.636,
        close_ms: 1720592732427,
        current_return: 0.8560481108376256,
        initial_entry_price: 157.636,
        is_closed_position: true,
        miner_hotkey: "5FbaR3qjbbnYpkDCkuh4TUqqen1UMSscqjmhoDWQgGRh189o",
        net_leverage: 0.0,
        open_ms: 1718780586879,
        orders: [
          {
            leverage: -6.0,
            order_type: "SHORT",
            order_uuid: "661779c9-9420-4482-a44c-53134a1386bd",
            price: 157.636,
            price_sources: [],
            processed_ms: 1718780586855,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "84f7c18f-c0bf-4bd3-936b-00dd084af46f",
            price: 161.418,
            price_sources: [
              {
                close: 161.418,
                high: 161.418,
                lag_ms: 427,
                low: 161.418,
                open: 161.418,
                source: "TwelveData_ws",
                start_ms: 1720592732000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
              {
                close: 161.422,
                high: 161.422,
                lag_ms: 1428,
                low: 161.422,
                open: 161.422,
                source: "Polygon_ws",
                start_ms: 1720592730999,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 161.422,
                websocket: true,
              },
            ],
            processed_ms: 1720592732427,
          },
        ],
        position_type: "FLAT",
        position_uuid: "cf90ae61-a174-41ab-8575-fa71c4bf6c21",
        return_at_close: 0.8556885706310737,
        trade_pair: ["USDJPY", "USD/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 2321665.4699996444,
        close_ms: 1720626567725,
        current_return: 1.3835674994611753,
        initial_entry_price: 59017.36,
        is_closed_position: true,
        miner_hotkey: "5FbaR3qjbbnYpkDCkuh4TUqqen1UMSscqjmhoDWQgGRh189o",
        net_leverage: 0.0,
        open_ms: 1720592759565,
        orders: [
          {
            leverage: -20.0,
            order_type: "SHORT",
            order_uuid: "a95289f1-768e-45f0-9b90-fe7646865e75",
            price: 59017.36,
            price_sources: [
              {
                close: 59017.36,
                high: 59017.36,
                lag_ms: 116,
                low: 59017.06,
                open: 59017.36,
                source: "Polygon_ws",
                start_ms: 1720592756999,
                timespan_ms: 0,
                volume: 3.272e-5,
                vwap: 59017.2278,
                websocket: true,
              },
              {
                close: 59019.7,
                high: 59019.7,
                lag_ms: 2117,
                low: 59019.7,
                open: 59019.7,
                source: "TwelveData_ws",
                start_ms: 1720592759000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
              {
                close: 59017.36,
                high: 59017.36,
                lag_ms: 2884,
                low: 59017.36,
                open: 59017.36,
                source: "Polygon_rest",
                start_ms: 1720592753000,
                timespan_ms: 1000,
                volume: 8.254e-5,
                vwap: 59017.36,
                websocket: false,
              },
              {
                close: 59020.28,
                high: 59048.09,
                lag_ms: 56884,
                low: 59011.54,
                open: 59048.09,
                source: "TwelveData_rest",
                start_ms: 1720592640000,
                timespan_ms: 60000,
                volume: null,
                vwap: null,
                websocket: false,
              },
            ],
            processed_ms: 1720592756883,
          },
          {
            leverage: 19.99,
            order_type: "LONG",
            order_uuid: "135540ca-51ad-43fb-b11a-787332ba79e1",
            price: 57885.47,
            price_sources: [
              {
                close: 57885.47,
                high: 57885.47,
                lag_ms: 175,
                low: 57884.76,
                open: 57885.47,
                source: "Polygon_ws",
                start_ms: 1720626547999,
                timespan_ms: 0,
                volume: 0.00015612,
                vwap: 57885.1867,
                websocket: true,
              },
              {
                close: 57875.3,
                high: 57875.3,
                lag_ms: 2824,
                low: 57875.3,
                open: 57875.3,
                source: "TwelveData_ws",
                start_ms: 1720626545000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
              {
                close: 57879.5,
                high: 57887.59,
                lag_ms: 2825,
                low: 57859.9,
                open: 57884.57,
                source: "Polygon_rest",
                start_ms: 1720626544000,
                timespan_ms: 1000,
                volume: 0.03611891,
                vwap: 57880.0757,
                websocket: false,
              },
              {
                close: 57859.58,
                high: 57898.46,
                lag_ms: 67825,
                low: 57849.39,
                open: 57865.94,
                source: "TwelveData_rest",
                start_ms: 1720626420000,
                timespan_ms: 60000,
                volume: null,
                vwap: null,
                websocket: false,
              },
            ],
            processed_ms: 1720626547824,
          },
          {
            leverage: 19.99,
            order_type: "FLAT",
            order_uuid: "5a061957-1276-4cb1-9c6c-850b71ed2f95",
            price: 57951.35,
            price_sources: [
              {
                close: 57951.35,
                high: 57953.0,
                lag_ms: 274,
                low: 57949.87,
                open: 57951.35,
                source: "Polygon_ws",
                start_ms: 1720626567999,
                timespan_ms: 0,
                volume: 1.02100333,
                vwap: 57952.9968,
                websocket: true,
              },
              {
                close: 57971.91,
                high: 57978.69,
                lag_ms: 2726,
                low: 57971.91,
                open: 57978.69,
                source: "Polygon_rest",
                start_ms: 1720626564000,
                timespan_ms: 1000,
                volume: 0.01658215,
                vwap: 57976.0626,
                websocket: false,
              },
              {
                close: 57865.8,
                high: 57865.8,
                lag_ms: 16275,
                low: 57865.8,
                open: 57865.8,
                source: "TwelveData_ws",
                start_ms: 1720626584000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
              {
                close: 57905.24,
                high: 57908.37,
                lag_ms: 27726,
                low: 57826.83,
                open: 57854.33,
                source: "TwelveData_rest",
                start_ms: 1720626480000,
                timespan_ms: 60000,
                volume: null,
                vwap: null,
                websocket: false,
              },
            ],
            processed_ms: 1720626567725,
          },
        ],
        position_type: "FLAT",
        position_uuid: "f423f701-a47c-4796-a431-77b82f354e6a",
        return_at_close: 1.3558961494719517,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 57963.39,
        close_ms: 0,
        current_return: 0.9700483356822297,
        initial_entry_price: 57963.39,
        is_closed_position: false,
        miner_hotkey: "5FbaR3qjbbnYpkDCkuh4TUqqen1UMSscqjmhoDWQgGRh189o",
        net_leverage: -10.0,
        open_ms: 1720626643516,
        orders: [
          {
            leverage: -10.0,
            order_type: "SHORT",
            order_uuid: "f1d00c48-1570-4a81-8add-b4116bf3b837",
            price: 57963.39,
            price_sources: [
              {
                close: 57963.39,
                high: 57963.39,
                lag_ms: 332,
                low: 57963.39,
                open: 57963.39,
                source: "Polygon_ws",
                start_ms: 1720626643000,
                timespan_ms: 0,
                volume: 3.416e-5,
                vwap: 57963.39,
                websocket: true,
              },
              {
                close: 57959.43,
                high: 57959.43,
                lag_ms: 2333,
                low: 57953.89,
                open: 57953.89,
                source: "Polygon_rest",
                start_ms: 1720626640000,
                timespan_ms: 1000,
                volume: 0.26766357,
                vwap: 57958.5422,
                websocket: false,
              },
              {
                close: 57935.0,
                high: 57935.0,
                lag_ms: 5332,
                low: 57935.0,
                open: 57935.0,
                source: "TwelveData_ws",
                start_ms: 1720626638000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
              {
                close: 57906.8,
                high: 58006.01,
                lag_ms: 43333,
                low: 57863.54,
                open: 57905.09,
                source: "TwelveData_rest",
                start_ms: 1720626540000,
                timespan_ms: 60000,
                volume: null,
                vwap: null,
                websocket: false,
              },
            ],
            processed_ms: 1720626643332,
          },
        ],
        position_type: "SHORT",
        position_uuid: "1e3af9a8-0af9-4cd3-bfdd-d873f1f0942e",
        return_at_close: 0.9651980940038185,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 1.0823,
        close_ms: 0,
        current_return: 1.0000055437494226,
        initial_entry_price: 1.0823,
        is_closed_position: false,
        miner_hotkey: "5FbaR3qjbbnYpkDCkuh4TUqqen1UMSscqjmhoDWQgGRh189o",
        net_leverage: 0.1,
        open_ms: 1720626584778,
        orders: [
          {
            leverage: 0.1,
            order_type: "LONG",
            order_uuid: "30e962b0-4b90-4f2b-a320-1635ab039ae5",
            price: 1.0823,
            price_sources: [
              {
                close: 1.0823,
                high: 1.0823,
                lag_ms: 223,
                low: 1.0823,
                open: 1.0823,
                source: "Polygon_ws",
                start_ms: 1720626584999,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 1.0823,
                websocket: true,
              },
              {
                close: 1.0824,
                high: 1.0824,
                lag_ms: 1776,
                low: 1.0824,
                open: 1.0824,
                source: "TwelveData_ws",
                start_ms: 1720626583000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1720626584776,
          },
        ],
        position_type: "LONG",
        position_uuid: "59df3bec-4b52-42b5-b5fa-7e38dbdabd60",
        return_at_close: 1.0000020437300194,
        trade_pair: ["EURUSD", "EUR/USD", 7e-5, 0.001, 500],
      },
    ],
    thirty_day_returns: 1.006818817317601,
  },
  "5Fc55y5Kk5ToQ7fTCGsWKcuTxmC1rJkTNQAhn5yCEjNcYrtp": {
    all_time_returns: 1.0,
    n_positions: 0,
    percentage_profitable: 0.0,
    positions: [
      {
        average_entry_price: 64558.30000000001,
        close_ms: 1711197707692,
        current_return: 1.0017809948527143,
        initial_entry_price: 64558.3,
        is_closed_position: true,
        miner_hotkey: "5Fc55y5Kk5ToQ7fTCGsWKcuTxmC1rJkTNQAhn5yCEjNcYrtp",
        net_leverage: 0.0,
        open_ms: 1711194163246,
        orders: [
          {
            leverage: 0.6,
            order_type: "LONG",
            order_uuid: "1a6388b5-545a-4c57-8272-cd587719dda7",
            price: 64558.3,
            price_sources: [],
            processed_ms: 1711194163246,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "ab0a1680-1ba4-484d-b2c4-5487463bd556",
            price: 64749.93,
            price_sources: [],
            processed_ms: 1711197707692,
          },
        ],
        position_type: "FLAT",
        position_uuid: "f4b298e5-9e23-464f-92ff-e51ddef10a92",
        return_at_close: 1.000578857658891,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 64757.200000000004,
        close_ms: 1711201364511,
        current_return: 0.9974328414446578,
        initial_entry_price: 64757.2,
        is_closed_position: true,
        miner_hotkey: "5Fc55y5Kk5ToQ7fTCGsWKcuTxmC1rJkTNQAhn5yCEjNcYrtp",
        net_leverage: 0.0,
        open_ms: 1711197819957,
        orders: [
          {
            leverage: 0.6,
            order_type: "LONG",
            order_uuid: "cacefa31-dec7-41d3-8689-535cec7cd276",
            price: 64757.2,
            price_sources: [],
            processed_ms: 1711197819956,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "3c32ce45-81c5-4848-9940-50512ccfcf3b",
            price: 64480.13,
            price_sources: [],
            processed_ms: 1711201364511,
          },
        ],
        position_type: "FLAT",
        position_uuid: "1e334241-74b5-4b1c-9d3f-8f16b26eabd7",
        return_at_close: 0.9962359220349243,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 64458.4,
        close_ms: 1711205023740,
        current_return: 1.006152433197225,
        initial_entry_price: 64458.4,
        is_closed_position: true,
        miner_hotkey: "5Fc55y5Kk5ToQ7fTCGsWKcuTxmC1rJkTNQAhn5yCEjNcYrtp",
        net_leverage: 0.0,
        open_ms: 1711201476537,
        orders: [
          {
            leverage: 0.6,
            order_type: "LONG",
            order_uuid: "ec0bfe23-ff19-4c3e-a017-3be4c976e1ba",
            price: 64458.4,
            price_sources: [],
            processed_ms: 1711201476536,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "7aed3e7b-f90b-4c8b-80cf-e7dcad70269a",
            price: 65119.36,
            price_sources: [],
            processed_ms: 1711205023740,
          },
        ],
        position_type: "FLAT",
        position_uuid: "18269a1b-5d20-43da-b3e5-c2e7f70a17e9",
        return_at_close: 1.0049450502773882,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 65084.72000000001,
        close_ms: 1711208689600,
        current_return: 0.9977942288143822,
        initial_entry_price: 65084.72,
        is_closed_position: true,
        miner_hotkey: "5Fc55y5Kk5ToQ7fTCGsWKcuTxmC1rJkTNQAhn5yCEjNcYrtp",
        net_leverage: 0.0,
        open_ms: 1711205145648,
        orders: [
          {
            leverage: 0.6,
            order_type: "LONG",
            order_uuid: "031846ec-c2ec-47c8-a797-dfe56f4cce8f",
            price: 65084.72,
            price_sources: [],
            processed_ms: 1711205145647,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "e05605f7-8637-4f10-80d7-fbc2b521d84e",
            price: 64845.45,
            price_sources: [],
            processed_ms: 1711208689600,
          },
        ],
        position_type: "FLAT",
        position_uuid: "b49861eb-7c62-49b2-9c67-8f1e5ec37607",
        return_at_close: 0.9965968757398049,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 64891.82,
        close_ms: 1711212353717,
        current_return: 1.0075860100702987,
        initial_entry_price: 64891.82,
        is_closed_position: true,
        miner_hotkey: "5Fc55y5Kk5ToQ7fTCGsWKcuTxmC1rJkTNQAhn5yCEjNcYrtp",
        net_leverage: 0.0,
        open_ms: 1711208799900,
        orders: [
          {
            leverage: 0.6,
            order_type: "LONG",
            order_uuid: "b95b1845-d252-4fd3-92a9-abaf494f2af7",
            price: 64891.82,
            price_sources: [],
            processed_ms: 1711208799899,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "82cb0bc0-4e8a-4261-a099-31777b562563",
            price: 65712.27,
            price_sources: [],
            processed_ms: 1711212353717,
          },
        ],
        position_type: "FLAT",
        position_uuid: "26d4d54f-feee-4af6-91e0-01adade66b20",
        return_at_close: 1.0063769068582145,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 65645.37,
        close_ms: 1711216014134,
        current_return: 1.0005873376903809,
        initial_entry_price: 65645.37,
        is_closed_position: true,
        miner_hotkey: "5Fc55y5Kk5ToQ7fTCGsWKcuTxmC1rJkTNQAhn5yCEjNcYrtp",
        net_leverage: 0.0,
        open_ms: 1711212462233,
        orders: [
          {
            leverage: 0.6,
            order_type: "LONG",
            order_uuid: "2421da8d-3ee2-4354-b2a4-c25c2a30405c",
            price: 65645.37,
            price_sources: [],
            processed_ms: 1711212462232,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "8d89fe39-6132-4676-9d90-b80bf1ba1003",
            price: 65709.63,
            price_sources: [],
            processed_ms: 1711216014134,
          },
        ],
        position_type: "FLAT",
        position_uuid: "589e54a4-6790-45ae-abfd-6188ad9403f3",
        return_at_close: 0.9993866328851525,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 65771.39,
        close_ms: 1711219673618,
        current_return: 0.9962036684947665,
        initial_entry_price: 65771.39,
        is_closed_position: true,
        miner_hotkey: "5Fc55y5Kk5ToQ7fTCGsWKcuTxmC1rJkTNQAhn5yCEjNcYrtp",
        net_leverage: 0.0,
        open_ms: 1711216140556,
        orders: [
          {
            leverage: 0.6,
            order_type: "LONG",
            order_uuid: "3ece7e04-19f9-4a8c-a1e2-5a1d27f4d6a5",
            price: 65771.39,
            price_sources: [],
            processed_ms: 1711216140555,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "59cb7025-5e26-4b5b-8427-4696fb488a8d",
            price: 65355.24,
            price_sources: [],
            processed_ms: 1711219673618,
          },
        ],
        position_type: "FLAT",
        position_uuid: "d869fb3a-5825-4d71-b588-fbf03031679d",
        return_at_close: 0.9950082240925728,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 65399.4,
        close_ms: 1711223336806,
        current_return: 0.9956908778979623,
        initial_entry_price: 65399.4,
        is_closed_position: true,
        miner_hotkey: "5Fc55y5Kk5ToQ7fTCGsWKcuTxmC1rJkTNQAhn5yCEjNcYrtp",
        net_leverage: 0.0,
        open_ms: 1711219781297,
        orders: [
          {
            leverage: 0.6,
            order_type: "LONG",
            order_uuid: "dac7a1fb-ac48-41c0-a669-a7abfca4fe7e",
            price: 65399.4,
            price_sources: [],
            processed_ms: 1711219781296,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "0110de33-4b04-48a6-b51b-17535b609247",
            price: 64929.71,
            price_sources: [],
            processed_ms: 1711223336806,
          },
        ],
        position_type: "FLAT",
        position_uuid: "70cdf81b-10d1-4cf5-90de-38dc729d2162",
        return_at_close: 0.9944960488444847,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 64886.53,
        close_ms: 1711226995813,
        current_return: 1.000703412557275,
        initial_entry_price: 64886.53,
        is_closed_position: true,
        miner_hotkey: "5Fc55y5Kk5ToQ7fTCGsWKcuTxmC1rJkTNQAhn5yCEjNcYrtp",
        net_leverage: 0.0,
        open_ms: 1711223450807,
        orders: [
          {
            leverage: 0.6,
            order_type: "LONG",
            order_uuid: "259f7ad4-27e8-4885-8eb2-c4ab110885ba",
            price: 64886.53,
            price_sources: [],
            processed_ms: 1711223450805,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "b64da054-7b10-47d0-abc1-df9a50831cbc",
            price: 64962.6,
            price_sources: [],
            processed_ms: 1711226995813,
          },
        ],
        position_type: "FLAT",
        position_uuid: "6eee2d6a-ff28-4198-aeb1-22e5ea74de22",
        return_at_close: 0.9995025684622062,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 64893.51,
        close_ms: 1711230655745,
        current_return: 1.0006411118769811,
        initial_entry_price: 64893.51,
        is_closed_position: true,
        miner_hotkey: "5Fc55y5Kk5ToQ7fTCGsWKcuTxmC1rJkTNQAhn5yCEjNcYrtp",
        net_leverage: 0.0,
        open_ms: 1711227107167,
        orders: [
          {
            leverage: 0.6,
            order_type: "LONG",
            order_uuid: "bdcb73f6-e11d-43d4-9e4e-eff79c7c7aa6",
            price: 64893.51,
            price_sources: [],
            processed_ms: 1711227107166,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "873d3e1f-602f-436f-880d-a3ea35b51b0e",
            price: 64962.85,
            price_sources: [],
            processed_ms: 1711230655745,
          },
        ],
        position_type: "FLAT",
        position_uuid: "f3aea3ff-bc14-4db9-a3d5-34cf38828fa2",
        return_at_close: 0.9994403425427287,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 64959.63,
        close_ms: 1711234316789,
        current_return: 0.9989853698366201,
        initial_entry_price: 64959.63,
        is_closed_position: true,
        miner_hotkey: "5Fc55y5Kk5ToQ7fTCGsWKcuTxmC1rJkTNQAhn5yCEjNcYrtp",
        net_leverage: 0.0,
        open_ms: 1711230770223,
        orders: [
          {
            leverage: 0.6,
            order_type: "LONG",
            order_uuid: "e77e69ca-332b-49fc-b609-4de8021e1f3c",
            price: 64959.63,
            price_sources: [],
            processed_ms: 1711230770222,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "67a33d89-35cf-4fc4-92d5-72af7259adbd",
            price: 64849.78,
            price_sources: [],
            processed_ms: 1711234316789,
          },
        ],
        position_type: "FLAT",
        position_uuid: "57f3fe3f-3fe6-43e5-a48c-d563264e5aed",
        return_at_close: 0.9977865873928162,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 64873.53999999999,
        close_ms: 1711237985437,
        current_return: 0.9959416427714597,
        initial_entry_price: 64873.54,
        is_closed_position: true,
        miner_hotkey: "5Fc55y5Kk5ToQ7fTCGsWKcuTxmC1rJkTNQAhn5yCEjNcYrtp",
        net_leverage: 0.0,
        open_ms: 1711234429742,
        orders: [
          {
            leverage: 0.6,
            order_type: "LONG",
            order_uuid: "928b2634-19d1-4e0e-9b2d-689dd897be9f",
            price: 64873.54,
            price_sources: [],
            processed_ms: 1711234429741,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "4833e9cf-3fa8-4f1a-adec-8eab9267c79d",
            price: 64434.74,
            price_sources: [],
            processed_ms: 1711237985437,
          },
        ],
        position_type: "FLAT",
        position_uuid: "912142f0-312d-48fc-a50b-08979f89563d",
        return_at_close: 0.9947465128001339,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 64280.80000000001,
        close_ms: 1711241637530,
        current_return: 1.002865459048425,
        initial_entry_price: 64280.8,
        is_closed_position: true,
        miner_hotkey: "5Fc55y5Kk5ToQ7fTCGsWKcuTxmC1rJkTNQAhn5yCEjNcYrtp",
        net_leverage: 0.0,
        open_ms: 1711238113578,
        orders: [
          {
            leverage: 0.6,
            order_type: "LONG",
            order_uuid: "27b79fd1-a605-4a46-bfcf-1e432804ffa1",
            price: 64280.8,
            price_sources: [],
            processed_ms: 1711238113576,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "1b80b1c8-47f3-4179-8c93-91751ae1a3d0",
            price: 64587.79,
            price_sources: [],
            processed_ms: 1711241637530,
          },
        ],
        position_type: "FLAT",
        position_uuid: "3b58b9a3-7cdb-4152-95d8-0c3cd5001fae",
        return_at_close: 1.0016620204975668,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 64535.329999999994,
        close_ms: 1711245306602,
        current_return: 1.000072239500441,
        initial_entry_price: 64535.33,
        is_closed_position: true,
        miner_hotkey: "5Fc55y5Kk5ToQ7fTCGsWKcuTxmC1rJkTNQAhn5yCEjNcYrtp",
        net_leverage: 0.0,
        open_ms: 1711241724589,
        orders: [
          {
            leverage: 0.6,
            order_type: "LONG",
            order_uuid: "99b38434-3934-43db-b9fc-e83c27df66f8",
            price: 64535.33,
            price_sources: [],
            processed_ms: 1711241724587,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "52693e7b-3ac1-4c6e-9db3-bf384107cf10",
            price: 64543.1,
            price_sources: [],
            processed_ms: 1711245306602,
          },
        ],
        position_type: "FLAT",
        position_uuid: "e27b916f-dbf2-496e-b48a-57f8aedc3706",
        return_at_close: 0.9988721528130406,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 64528.75,
        close_ms: 1711248967353,
        current_return: 0.9972221684133041,
        initial_entry_price: 64528.75,
        is_closed_position: true,
        miner_hotkey: "5Fc55y5Kk5ToQ7fTCGsWKcuTxmC1rJkTNQAhn5yCEjNcYrtp",
        net_leverage: 0.0,
        open_ms: 1711245392589,
        orders: [
          {
            leverage: 0.6,
            order_type: "LONG",
            order_uuid: "cb35c5fa-936b-451c-8ba6-9ef3ec8ab93e",
            price: 64528.75,
            price_sources: [],
            processed_ms: 1711245392588,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "4475c099-9dee-45f7-a05c-e6783606ceae",
            price: 64230.0,
            price_sources: [],
            processed_ms: 1711248967353,
          },
        ],
        position_type: "FLAT",
        position_uuid: "8d50bf06-6844-4b58-9f65-7854baa08115",
        return_at_close: 0.9960255018112082,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 64222.560000000005,
        close_ms: 1711252626580,
        current_return: 0.9988502171199652,
        initial_entry_price: 64222.56,
        is_closed_position: true,
        miner_hotkey: "5Fc55y5Kk5ToQ7fTCGsWKcuTxmC1rJkTNQAhn5yCEjNcYrtp",
        net_leverage: 0.0,
        open_ms: 1711249053620,
        orders: [
          {
            leverage: 0.6,
            order_type: "LONG",
            order_uuid: "6ea5379f-2b60-43d2-a55c-82b4ca5f21a2",
            price: 64222.56,
            price_sources: [],
            processed_ms: 1711249053619,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "44b07606-adad-4a04-a6d0-5db9cac1725f",
            price: 64099.49,
            price_sources: [],
            processed_ms: 1711252626580,
          },
        ],
        position_type: "FLAT",
        position_uuid: "435109f8-3c31-4366-a1e1-6a855a820904",
        return_at_close: 0.9976515968594213,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 64019.47,
        close_ms: 1711256891750,
        current_return: 1.0013336567766025,
        initial_entry_price: 64019.47,
        is_closed_position: true,
        miner_hotkey: "5Fc55y5Kk5ToQ7fTCGsWKcuTxmC1rJkTNQAhn5yCEjNcYrtp",
        net_leverage: 0.0,
        open_ms: 1711252712464,
        orders: [
          {
            leverage: 0.6,
            order_type: "LONG",
            order_uuid: "2dc3a423-9c0e-4869-9807-bff42554914c",
            price: 64019.47,
            price_sources: [],
            processed_ms: 1711252712462,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "68f51252-4e2c-4121-bf4d-1a17a1935e33",
            price: 64161.77,
            price_sources: [],
            processed_ms: 1711256891750,
          },
        ],
        position_type: "FLAT",
        position_uuid: "5d692735-d995-4432-9a48-688ddb41c807",
        return_at_close: 1.0001320563884706,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 64139.91000000001,
        close_ms: 1711259440736,
        current_return: 1.0020024349893848,
        initial_entry_price: 64139.91,
        is_closed_position: true,
        miner_hotkey: "5Fc55y5Kk5ToQ7fTCGsWKcuTxmC1rJkTNQAhn5yCEjNcYrtp",
        net_leverage: 0.0,
        open_ms: 1711256951482,
        orders: [
          {
            leverage: 0.6,
            order_type: "LONG",
            order_uuid: "7933e2c8-bd75-4a69-a39b-24b362f55386",
            price: 64139.91,
            price_sources: [],
            processed_ms: 1711256951480,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "00b42238-d56a-482a-a20c-58805325d64a",
            price: 64353.97,
            price_sources: [],
            processed_ms: 1711259440736,
          },
        ],
        position_type: "FLAT",
        position_uuid: "ffe51721-ec2e-4ac8-bcc7-00c2fad8ade1",
        return_at_close: 1.0008000320673975,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 64380.759999999995,
        close_ms: 1711263097587,
        current_return: 0.9948810405468964,
        initial_entry_price: 64380.76,
        is_closed_position: true,
        miner_hotkey: "5Fc55y5Kk5ToQ7fTCGsWKcuTxmC1rJkTNQAhn5yCEjNcYrtp",
        net_leverage: 0.0,
        open_ms: 1711259528783,
        orders: [
          {
            leverage: 1.25,
            order_type: "LONG",
            order_uuid: "2bd307fb-dffb-4f00-8f63-9a6fedb37d59",
            price: 64380.76,
            price_sources: [],
            processed_ms: 1711259528781,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "2c2c5e3d-40ec-448b-b415-881714c1b329",
            price: 64117.11,
            price_sources: [],
            processed_ms: 1711263097587,
          },
        ],
        position_type: "FLAT",
        position_uuid: "8babfc44-5569-404c-a039-cc961280a351",
        return_at_close: 0.9923938379455293,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 64148.06999999999,
        close_ms: 1711266765088,
        current_return: 1.0038181195474785,
        initial_entry_price: 64148.07,
        is_closed_position: true,
        miner_hotkey: "5Fc55y5Kk5ToQ7fTCGsWKcuTxmC1rJkTNQAhn5yCEjNcYrtp",
        net_leverage: 0.0,
        open_ms: 1711263186197,
        orders: [
          {
            leverage: 1.25,
            order_type: "LONG",
            order_uuid: "54818a27-8e38-4547-b43d-dcf4f017de88",
            price: 64148.07,
            price_sources: [],
            processed_ms: 1711263186195,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "6022071c-e31d-4bf6-8ff2-243f83faaf9b",
            price: 64344.01,
            price_sources: [],
            processed_ms: 1711266765088,
          },
        ],
        position_type: "FLAT",
        position_uuid: "1d450a40-74d1-4671-9c65-8dacd3508b40",
        return_at_close: 1.0013085742486099,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 64375.0,
        close_ms: 1711270423611,
        current_return: 1.0119038834951457,
        initial_entry_price: 64375.0,
        is_closed_position: true,
        miner_hotkey: "5Fc55y5Kk5ToQ7fTCGsWKcuTxmC1rJkTNQAhn5yCEjNcYrtp",
        net_leverage: 0.0,
        open_ms: 1711266851508,
        orders: [
          {
            leverage: 1.25,
            order_type: "LONG",
            order_uuid: "6acf2f9f-c95c-42fe-a040-1f5bf54bf49a",
            price: 64375.0,
            price_sources: [],
            processed_ms: 1711266851507,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "04460e88-d706-416f-8b0e-b994e2f23042",
            price: 64988.05,
            price_sources: [],
            processed_ms: 1711270423611,
          },
        ],
        position_type: "FLAT",
        position_uuid: "29b9adc8-7400-453d-b241-169a4f54f72f",
        return_at_close: 1.009374123786408,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 65057.87999999999,
        close_ms: 1711274080540,
        current_return: 0.9985339977263326,
        initial_entry_price: 65057.88,
        is_closed_position: true,
        miner_hotkey: "5Fc55y5Kk5ToQ7fTCGsWKcuTxmC1rJkTNQAhn5yCEjNcYrtp",
        net_leverage: 0.0,
        open_ms: 1711270510452,
        orders: [
          {
            leverage: 1.25,
            order_type: "LONG",
            order_uuid: "ae4a1fbc-6463-48d6-8903-91f30891bcab",
            price: 65057.88,
            price_sources: [],
            processed_ms: 1711270510450,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "06d05758-4473-44d7-82e1-f4a8e5a9ccf8",
            price: 64981.58,
            price_sources: [],
            processed_ms: 1711274080540,
          },
        ],
        position_type: "FLAT",
        position_uuid: "37d98b5b-a49b-4ec2-b5e1-67bb5a743f88",
        return_at_close: 0.9960376627320168,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 65033.490000000005,
        close_ms: 1711277750735,
        current_return: 1.0021477395723342,
        initial_entry_price: 65033.49,
        is_closed_position: true,
        miner_hotkey: "5Fc55y5Kk5ToQ7fTCGsWKcuTxmC1rJkTNQAhn5yCEjNcYrtp",
        net_leverage: 0.0,
        open_ms: 1711274168121,
        orders: [
          {
            leverage: 1.25,
            order_type: "LONG",
            order_uuid: "ac1eae2b-bb00-43cd-8450-45acfcaee09e",
            price: 65033.49,
            price_sources: [],
            processed_ms: 1711274168120,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "d0737f59-fadc-49b1-97a4-34dd21c3dcbb",
            price: 65145.23,
            price_sources: [],
            processed_ms: 1711277750735,
          },
        ],
        position_type: "FLAT",
        position_uuid: "1967dec1-2a9d-4a67-aa4c-1e0195815be9",
        return_at_close: 0.9996423702234034,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 65156.55,
        close_ms: 1711313484831,
        current_return: 1.0215402365533472,
        initial_entry_price: 65156.55,
        is_closed_position: true,
        miner_hotkey: "5Fc55y5Kk5ToQ7fTCGsWKcuTxmC1rJkTNQAhn5yCEjNcYrtp",
        net_leverage: 0.0,
        open_ms: 1711277837621,
        orders: [
          {
            leverage: 1.25,
            order_type: "LONG",
            order_uuid: "2c197f01-3192-4aba-86da-e16aad95132d",
            price: 65156.55,
            price_sources: [],
            processed_ms: 1711277837620,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "f72bf82d-1aca-4bf8-a573-3987f3658dc5",
            price: 66279.34,
            price_sources: [],
            processed_ms: 1711313484831,
          },
        ],
        position_type: "FLAT",
        position_uuid: "73e02370-b4be-48fd-ad75-9cd439ac41db",
        return_at_close: 1.018986385961964,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 66381.58,
        close_ms: 1711319066606,
        current_return: 1.0068108125778266,
        initial_entry_price: 66381.58,
        is_closed_position: true,
        miner_hotkey: "5Fc55y5Kk5ToQ7fTCGsWKcuTxmC1rJkTNQAhn5yCEjNcYrtp",
        net_leverage: 0.0,
        open_ms: 1711317207757,
        orders: [
          {
            leverage: 1.25,
            order_type: "LONG",
            order_uuid: "359aab0b-028a-4474-8406-f299ee424a27",
            price: 66381.58,
            price_sources: [],
            processed_ms: 1711317207755,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "90c97932-134d-4ecf-9344-29a71d329f06",
            price: 66743.27,
            price_sources: [],
            processed_ms: 1711319066606,
          },
        ],
        position_type: "FLAT",
        position_uuid: "79d1ce6b-dc5a-4629-934e-30bad5e79b87",
        return_at_close: 1.004293785546382,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 322095.200000009,
        close_ms: 1711332881625,
        current_return: 1.0075925727032142,
        initial_entry_price: 67335.4,
        is_closed_position: true,
        miner_hotkey: "5Fc55y5Kk5ToQ7fTCGsWKcuTxmC1rJkTNQAhn5yCEjNcYrtp",
        net_leverage: 0.0,
        open_ms: 1711322805612,
        orders: [
          {
            leverage: -1.25,
            order_type: "SHORT",
            order_uuid: "3a83f214-a04d-4c8d-9907-1fd58bc820ca",
            price: 67335.4,
            price_sources: [],
            processed_ms: 1711322805610,
          },
          {
            leverage: 0.125,
            order_type: "LONG",
            order_uuid: "48496a58-660b-4060-b829-a70015bb0c18",
            price: 67195.94,
            price_sources: [],
            processed_ms: 1711323738998,
          },
          {
            leverage: 0.125,
            order_type: "LONG",
            order_uuid: "df991a32-f0a2-45d3-b06f-c84299aedf61",
            price: 67225.58,
            price_sources: [],
            processed_ms: 1711324669687,
          },
          {
            leverage: 0.8,
            order_type: "LONG",
            order_uuid: "23ebde66-12e9-4f43-a3d2-2d5e237242d9",
            price: 66884.18,
            price_sources: [],
            processed_ms: 1711325596631,
          },
          {
            leverage: 0.09999999999999998,
            order_type: "LONG",
            order_uuid: "7174590c-9679-4c77-855c-1a615cdd7cfa",
            price: 66878.36,
            price_sources: [],
            processed_ms: 1711326524543,
          },
          {
            leverage: 0.07999999999999999,
            order_type: "LONG",
            order_uuid: "2b015087-3433-4591-90e9-119fe1d713a8",
            price: 66604.42,
            price_sources: [],
            processed_ms: 1711327482546,
          },
          {
            leverage: 0.015999999999999993,
            order_type: "LONG",
            order_uuid: "18119086-cf4a-4498-bb80-6bd779939aa7",
            price: 66577.69,
            price_sources: [],
            processed_ms: 1711328396813,
          },
          {
            leverage: 0.0019999999999999983,
            order_type: "LONG",
            order_uuid: "c87750dc-e7bd-4a91-a0e1-7860f654c147",
            price: 66796.48,
            price_sources: [],
            processed_ms: 1711329333043,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "976b6586-7df8-4033-8514-54a1d61e937f",
            price: 66470.74,
            price_sources: [],
            processed_ms: 1711332881625,
          },
        ],
        position_type: "FLAT",
        position_uuid: "8465c627-4076-40f1-bbb3-686ec83dd71a",
        return_at_close: 1.0050735912714563,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 25918.687142862575,
        close_ms: 1711354891042,
        current_return: 0.9991448890435322,
        initial_entry_price: 67251.6,
        is_closed_position: true,
        miner_hotkey: "5Fc55y5Kk5ToQ7fTCGsWKcuTxmC1rJkTNQAhn5yCEjNcYrtp",
        net_leverage: 0.0,
        open_ms: 1711340201754,
        orders: [
          {
            leverage: -1.25,
            order_type: "SHORT",
            order_uuid: "9ca260ff-5007-410e-8864-52ebdeba9c81",
            price: 67251.6,
            price_sources: [],
            processed_ms: 1711340201752,
          },
          {
            leverage: 0.125,
            order_type: "LONG",
            order_uuid: "e9de49b6-6c41-4927-a189-7ab8c00140d3",
            price: 67346.18,
            price_sources: [],
            processed_ms: 1711342036776,
          },
          {
            leverage: 0.125,
            order_type: "LONG",
            order_uuid: "c018bd70-2c30-4048-987e-ce711d9ffa84",
            price: 67537.17,
            price_sources: [],
            processed_ms: 1711343877884,
          },
          {
            leverage: 0.125,
            order_type: "LONG",
            order_uuid: "ef186ac6-3c0f-4093-b953-958a52acd10d",
            price: 67415.64,
            price_sources: [],
            processed_ms: 1711345701075,
          },
          {
            leverage: 0.7,
            order_type: "LONG",
            order_uuid: "54835b23-0d8c-4bdd-999a-e14f4dda0e22",
            price: 67266.79,
            price_sources: [],
            processed_ms: 1711347545104,
          },
          {
            leverage: 0.14000000000000004,
            order_type: "LONG",
            order_uuid: "cc80708d-536d-4880-817e-123c28c42701",
            price: 67152.1,
            price_sources: [],
            processed_ms: 1711349366164,
          },
          {
            leverage: 0.028000000000000004,
            order_type: "LONG",
            order_uuid: "8526047b-60d6-4f8c-9376-d0ee50f73baf",
            price: 67085.96,
            price_sources: [],
            processed_ms: 1711351217091,
          },
          {
            leverage: 0.005599999999999999,
            order_type: "LONG",
            order_uuid: "aee803af-d331-489f-97be-eefba52bac7e",
            price: 66854.68,
            price_sources: [],
            processed_ms: 1711353038967,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "1d419435-ab74-4562-beb9-3d3a41f2d248",
            price: 66995.53,
            price_sources: [],
            processed_ms: 1711354891042,
          },
        ],
        position_type: "FLAT",
        position_uuid: "97c65c89-0c3b-4ce2-bbae-98ea59cea033",
        return_at_close: 0.9966470268209234,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 66883.0,
        close_ms: 1711362195986,
        current_return: 1.0067705919291898,
        initial_entry_price: 66883.0,
        is_closed_position: true,
        miner_hotkey: "5Fc55y5Kk5ToQ7fTCGsWKcuTxmC1rJkTNQAhn5yCEjNcYrtp",
        net_leverage: 0.0,
        open_ms: 1711356697961,
        orders: [
          {
            leverage: 1.25,
            order_type: "LONG",
            order_uuid: "e830a2ab-0941-483b-be99-dc8628af6cd7",
            price: 66883.0,
            price_sources: [],
            processed_ms: 1711356697959,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "3a259345-646c-4365-bd71-469c6fc49ebd",
            price: 67245.27,
            price_sources: [],
            processed_ms: 1711362195986,
          },
        ],
        position_type: "FLAT",
        position_uuid: "224c5dd3-a07f-4f72-84e9-0c6709ff7229",
        return_at_close: 1.004253665449367,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 69232.76943437502,
        close_ms: 1711384680972,
        current_return: 1.0611450494134234,
        initial_entry_price: 67056.17,
        is_closed_position: true,
        miner_hotkey: "5Fc55y5Kk5ToQ7fTCGsWKcuTxmC1rJkTNQAhn5yCEjNcYrtp",
        net_leverage: 0.0,
        open_ms: 1711364024176,
        orders: [
          {
            leverage: 1.25,
            order_type: "LONG",
            order_uuid: "9e4c78bc-cff6-4829-a415-ea0cb173fc76",
            price: 67056.17,
            price_sources: [],
            processed_ms: 1711364024174,
          },
          {
            leverage: -0.625,
            order_type: "SHORT",
            order_uuid: "99b32282-0946-469d-83f0-b86a68ed7a16",
            price: 67078.4,
            price_sources: [],
            processed_ms: 1711368876860,
          },
          {
            leverage: -0.3125,
            order_type: "SHORT",
            order_uuid: "ff860c0a-0c43-42b4-b42b-80330739c1da",
            price: 67075.7,
            price_sources: [],
            processed_ms: 1711370742809,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "30433785-dfbb-4775-938a-07eb69e988c5",
            price: 66982.9,
            price_sources: [],
            processed_ms: 1711372544797,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "70ffb5f5-6e83-4a37-9948-b63a2e5e2347",
            price: 67156.75,
            price_sources: [],
            processed_ms: 1711374398025,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "44d35068-ea28-499c-b17b-f3c029c98f50",
            price: 68888.85,
            price_sources: [],
            processed_ms: 1711376049892,
          },
          {
            leverage: -0.53125,
            order_type: "SHORT",
            order_uuid: "3a9a79a1-1437-4f3a-bf80-8bdfab230862",
            price: 69141.81,
            price_sources: [],
            processed_ms: 1711376537560,
          },
          {
            leverage: -0.265625,
            order_type: "SHORT",
            order_uuid: "c3e5a5f2-c578-4ca1-820c-ce96f30bf1a4",
            price: 69358.28,
            price_sources: [],
            processed_ms: 1711376872901,
          },
          {
            leverage: -0.2125,
            order_type: "SHORT",
            order_uuid: "6551f56e-48f7-4222-a774-fee6bbc75536",
            price: 69144.78,
            price_sources: [],
            processed_ms: 1711378034744,
          },
          {
            leverage: -0.026562500000000003,
            order_type: "SHORT",
            order_uuid: "94bf9e8e-f8c9-438d-91df-28969918f733",
            price: 69212.28,
            price_sources: [],
            processed_ms: 1711378281450,
          },
          {
            leverage: 4.9734375,
            order_type: "LONG",
            order_uuid: "e1c785c5-29c6-4af7-913b-2ff15bd6211c",
            price: 69601.41,
            price_sources: [],
            processed_ms: 1711383083816,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "2ee7b3a2-d2ef-4f36-859a-476e38a31bf7",
            price: 70052.8,
            price_sources: [],
            processed_ms: 1711384680972,
          },
        ],
        position_type: "FLAT",
        position_uuid: "8e5a8a11-6c8c-4d5b-8b40-b94435a88355",
        return_at_close: 1.0505335989192892,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 69957.71461538458,
        close_ms: 1711396605873,
        current_return: 1.05206167475333,
        initial_entry_price: 70175.23,
        is_closed_position: true,
        miner_hotkey: "5Fc55y5Kk5ToQ7fTCGsWKcuTxmC1rJkTNQAhn5yCEjNcYrtp",
        net_leverage: 0.0,
        open_ms: 1711384898881,
        orders: [
          {
            leverage: 5.0,
            order_type: "LONG",
            order_uuid: "61119672-de25-4a10-a0fa-4ca7eefe1329",
            price: 70175.23,
            price_sources: [],
            processed_ms: 1711384898879,
          },
          {
            leverage: -2.5,
            order_type: "SHORT",
            order_uuid: "62e12bce-6996-4e4a-a379-557fd77d5d26",
            price: 70544.13,
            price_sources: [],
            processed_ms: 1711386725676,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "e7e6e1b7-aee4-4c83-a43e-ab8e89d33e25",
            price: 70483.64,
            price_sources: [],
            processed_ms: 1711388536726,
          },
          {
            leverage: -2.0,
            order_type: "SHORT",
            order_uuid: "19669304-698d-4038-aed2-78eab338687a",
            price: 70616.38,
            price_sources: [],
            processed_ms: 1711390363863,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "66d6123a-cce9-441e-8977-d892fd5dde09",
            price: 70588.91,
            price_sources: [],
            processed_ms: 1711392175923,
          },
          {
            leverage: -1.75,
            order_type: "SHORT",
            order_uuid: "231e592d-f977-4be6-8542-07158501ff8f",
            price: 70673.59,
            price_sources: [],
            processed_ms: 1711394023655,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "40d35b80-ad09-426c-8171-ab4493fc2a72",
            price: 70766.31,
            price_sources: [],
            processed_ms: 1711395813258,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "e6376608-67df-4edd-b0f2-4361b0c2e852",
            price: 71081.85,
            price_sources: [],
            processed_ms: 1711396605873,
          },
        ],
        position_type: "FLAT",
        position_uuid: "5864a7a4-cdfd-4140-849c-452a509d5d23",
        return_at_close: 1.0415410580057967,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 70518.71,
        close_ms: 1711401698773,
        current_return: 0.9770585706970525,
        initial_entry_price: 70518.71,
        is_closed_position: true,
        miner_hotkey: "5Fc55y5Kk5ToQ7fTCGsWKcuTxmC1rJkTNQAhn5yCEjNcYrtp",
        net_leverage: 0.0,
        open_ms: 1711401268762,
        orders: [
          {
            leverage: 5.0,
            order_type: "LONG",
            order_uuid: "18dd10a1-1a10-48bd-8624-074b0a1122d6",
            price: 70518.71,
            price_sources: [],
            processed_ms: 1711401268759,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "1af9ba10-1c57-4633-9492-7af9493c8766",
            price: 70195.15,
            price_sources: [],
            processed_ms: 1711401698773,
          },
        ],
        position_type: "FLAT",
        position_uuid: "ae3ee504-2e01-44c9-bc72-7d09073f01bf",
        return_at_close: 0.967287984990082,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 67028.65857142812,
        close_ms: 1711419428687,
        current_return: 1.015516959397661,
        initial_entry_price: 70070.59,
        is_closed_position: true,
        miner_hotkey: "5Fc55y5Kk5ToQ7fTCGsWKcuTxmC1rJkTNQAhn5yCEjNcYrtp",
        net_leverage: 0.0,
        open_ms: 1711403079999,
        orders: [
          {
            leverage: 5.0,
            order_type: "LONG",
            order_uuid: "88cd55cc-2fd2-4628-93c4-68ee5a80dafb",
            price: 70070.59,
            price_sources: [],
            processed_ms: 1711403079997,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "a13336c5-7459-4e48-97e2-76a867b393cd",
            price: 70327.86,
            price_sources: [],
            processed_ms: 1711404899808,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "22a39f72-ee96-4119-b6ba-a5eea5b15c0f",
            price: 70604.42,
            price_sources: [],
            processed_ms: 1711406710806,
          },
          {
            leverage: -4.0,
            order_type: "SHORT",
            order_uuid: "9455c1db-9983-4597-b117-c7744fb55799",
            price: 70383.21,
            price_sources: [],
            processed_ms: 1711408544611,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "82291188-62e0-452d-ae4b-beb55eb9fbe7",
            price: 69887.41,
            price_sources: [],
            processed_ms: 1711410345169,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "4d2aa7ea-3c5a-465e-bfbd-2033f30acf64",
            price: 69878.5,
            price_sources: [],
            processed_ms: 1711412167847,
          },
          {
            leverage: -3.5,
            order_type: "SHORT",
            order_uuid: "c7b97836-10ca-4b23-b20a-a39123ad09e9",
            price: 70095.8,
            price_sources: [],
            processed_ms: 1711413976716,
          },
          {
            leverage: -1.75,
            order_type: "SHORT",
            order_uuid: "ff62250f-8408-4b15-bbcc-0a2bdb2c4692",
            price: 70133.75,
            price_sources: [],
            processed_ms: 1711415796753,
          },
          {
            leverage: -1.4,
            order_type: "SHORT",
            order_uuid: "1efe722e-22bd-4b1f-afcd-bf4fe1097c4d",
            price: 70241.43,
            price_sources: [],
            processed_ms: 1711417609035,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "c57b3369-fda4-4230-b82e-1b7eb6605db9",
            price: 70135.18,
            price_sources: [],
            processed_ms: 1711419428687,
          },
        ],
        position_type: "FLAT",
        position_uuid: "56eb1ed5-a037-4575-abe2-ca94a6436897",
        return_at_close: 0.9992686880472984,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 67926.28999999992,
        close_ms: 1711428515847,
        current_return: 0.9809732827307829,
        initial_entry_price: 70230.98,
        is_closed_position: true,
        miner_hotkey: "5Fc55y5Kk5ToQ7fTCGsWKcuTxmC1rJkTNQAhn5yCEjNcYrtp",
        net_leverage: 0.0,
        open_ms: 1711421246222,
        orders: [
          {
            leverage: -5.0,
            order_type: "SHORT",
            order_uuid: "0a81967f-775a-4bca-a549-2b08cce7dba5",
            price: 70230.98,
            price_sources: [],
            processed_ms: 1711421246219,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "2ab70603-a8ec-4fb8-80bc-d49e54ea80c0",
            price: 70390.25,
            price_sources: [],
            processed_ms: 1711423090748,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "bc344f3c-e357-4021-a864-220d6b1f55a3",
            price: 70499.67,
            price_sources: [],
            processed_ms: 1711424885376,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "778c5692-c157-442d-a0d3-8983908bb0be",
            price: 70571.25,
            price_sources: [],
            processed_ms: 1711426692944,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "e7153415-0928-474c-987e-0ef6535b8f46",
            price: 70598.82,
            price_sources: [],
            processed_ms: 1711428515847,
          },
        ],
        position_type: "FLAT",
        position_uuid: "e2f65226-7538-4dca-91a2-4e45c9486ae7",
        return_at_close: 0.9711635499034751,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 71190.47285714289,
        close_ms: 1711437613979,
        current_return: 1.002037155054103,
        initial_entry_price: 70532.8,
        is_closed_position: true,
        miner_hotkey: "5Fc55y5Kk5ToQ7fTCGsWKcuTxmC1rJkTNQAhn5yCEjNcYrtp",
        net_leverage: 0.0,
        open_ms: 1711430332890,
        orders: [
          {
            leverage: -5.0,
            order_type: "SHORT",
            order_uuid: "6388497d-e7da-4d66-9fdb-babbf0cbecbb",
            price: 70532.8,
            price_sources: [],
            processed_ms: 1711430332887,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "648e3e8b-c8d0-48af-b687-21d26bd9ff1b",
            price: 70371.53,
            price_sources: [],
            processed_ms: 1711432145916,
          },
          {
            leverage: 1.75,
            order_type: "LONG",
            order_uuid: "1618b05b-03a2-4a8a-bd8c-a41e41d589ca",
            price: 70372.38,
            price_sources: [],
            processed_ms: 1711433983429,
          },
          {
            leverage: 0.875,
            order_type: "LONG",
            order_uuid: "50ab30ba-4757-4cf9-be02-4658435d45ed",
            price: 70472.43,
            price_sources: [],
            processed_ms: 1711435795787,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "26ca5d49-2ca1-4874-81ea-381478909558",
            price: 71026.26,
            price_sources: [],
            processed_ms: 1711437613979,
          },
        ],
        position_type: "FLAT",
        position_uuid: "e1375cd4-413c-4770-bc7a-cebb9dbfc901",
        return_at_close: 0.9920167835035619,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 79132.28999999976,
        close_ms: 1711444884080,
        current_return: 1.0231589410377222,
        initial_entry_price: 71047.29,
        is_closed_position: true,
        miner_hotkey: "5Fc55y5Kk5ToQ7fTCGsWKcuTxmC1rJkTNQAhn5yCEjNcYrtp",
        net_leverage: 0.0,
        open_ms: 1711439451023,
        orders: [
          {
            leverage: -5.0,
            order_type: "SHORT",
            order_uuid: "a3ce8efe-630a-49a3-a92f-6ac5e4332d41",
            price: 71047.29,
            price_sources: [],
            processed_ms: 1711439451020,
          },
          {
            leverage: 4.0,
            order_type: "LONG",
            order_uuid: "26d9838b-d0f6-45bb-859f-ff2530a82c59",
            price: 70737.86,
            price_sources: [],
            processed_ms: 1711441249752,
          },
          {
            leverage: 0.8,
            order_type: "LONG",
            order_uuid: "97429069-081c-44bd-81a7-b453b284c2a4",
            price: 70573.19,
            price_sources: [],
            processed_ms: 1711443068118,
          },
          {
            leverage: 5.2,
            order_type: "LONG",
            order_uuid: "5b4a1f58-9b14-4f81-8099-45cc4c1c4365",
            price: 70905.39,
            price_sources: [],
            processed_ms: 1711444884080,
          },
        ],
        position_type: "FLAT",
        position_uuid: "dd566800-4d0c-48cb-a7c4-d0af0675d4db",
        return_at_close: 1.012927351627345,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 70873.50000000001,
        close_ms: 1711460374798,
        current_return: 0.9164303335793678,
        initial_entry_price: 70842.81,
        is_closed_position: true,
        miner_hotkey: "5Fc55y5Kk5ToQ7fTCGsWKcuTxmC1rJkTNQAhn5yCEjNcYrtp",
        net_leverage: 0.0,
        open_ms: 1711452153785,
        orders: [
          {
            leverage: 5.0,
            order_type: "LONG",
            order_uuid: "7b4a1d93-65cd-474b-b11d-3494dff1d606",
            price: 70842.81,
            price_sources: [],
            processed_ms: 1711452153782,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "fcbff1dc-752e-4ff3-b5d8-10615a04b3c8",
            price: 70725.55,
            price_sources: [],
            processed_ms: 1711453963936,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "684434e6-4f5a-4965-b96c-4d5295629aaf",
            price: 70983.25,
            price_sources: [],
            processed_ms: 1711455805356,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "c803a7bf-df5c-4408-aca9-2514bdb83251",
            price: 70906.92,
            price_sources: [],
            processed_ms: 1711457597952,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "1158c34d-508d-413a-a0f3-92643fd295b8",
            price: 70980.58,
            price_sources: [],
            processed_ms: 1711459417204,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "89f8f69a-38fc-41bf-a1db-f004a8fa887f",
            price: 70335.29,
            price_sources: [],
            processed_ms: 1711460374798,
          },
        ],
        position_type: "FLAT",
        position_uuid: "02bce53c-2234-479d-8b67-a608c70d66f9",
        return_at_close: 0.8962688662406216,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
    ],
    thirty_day_returns: 1.0,
  },
  "5Fgdtd86JprwGDii2jYkJUgUKo2NWojENtzKmr2piPHyNQGu": {
    all_time_returns: 1.0,
    n_positions: 0,
    percentage_profitable: 0.0,
    positions: [
      {
        average_entry_price: 61568.84,
        close_ms: 0,
        current_return: 0.998717982667856,
        initial_entry_price: 61568.84,
        is_closed_position: false,
        miner_hotkey: "5Fgdtd86JprwGDii2jYkJUgUKo2NWojENtzKmr2piPHyNQGu",
        net_leverage: 0.023,
        open_ms: 1719573874024,
        orders: [
          {
            leverage: 0.023,
            order_type: "LONG",
            order_uuid: "51e0664b-653b-4815-aa52-dd97f7839110",
            price: 61568.84,
            price_sources: [],
            processed_ms: 1719573874016,
          },
        ],
        position_type: "LONG",
        position_uuid: "974805c3-7f2e-4eb9-8183-0b1b32817dab",
        return_at_close: 0.9987064974110553,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
    ],
    thirty_day_returns: 1.0,
  },
  "5FhbvTiD4SRNJDmXRH9cqNFYzdd49vWgb6RXDyRXxfoZGSbg": {
    all_time_returns: 1.0,
    n_positions: 0,
    percentage_profitable: 0.0,
    positions: [
      {
        average_entry_price: 64371.74999999999,
        close_ms: 1711240928448,
        current_return: 1.000457094921297,
        initial_entry_price: 64371.75,
        is_closed_position: true,
        miner_hotkey: "5FhbvTiD4SRNJDmXRH9cqNFYzdd49vWgb6RXDyRXxfoZGSbg",
        net_leverage: 0.0,
        open_ms: 1711237352583,
        orders: [
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "1cc18f17-330e-4374-9adf-0f8f8779f2d7",
            price: 64371.75,
            price_sources: [],
            processed_ms: 1711237352582,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "561f0398-43d9-4866-84a6-84d6d53b3694",
            price: 64469.83,
            price_sources: [],
            processed_ms: 1711240928448,
          },
        ],
        position_type: "FLAT",
        position_uuid: "5abfd053-5213-4d16-85d3-894a7fcd23e9",
        return_at_close: 0.9998568206643441,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 64118.21,
        close_ms: 1711255578576,
        current_return: 0.999714963970454,
        initial_entry_price: 64118.21,
        is_closed_position: true,
        miner_hotkey: "5FhbvTiD4SRNJDmXRH9cqNFYzdd49vWgb6RXDyRXxfoZGSbg",
        net_leverage: 0.0,
        open_ms: 1711252002536,
        orders: [
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "b8026989-5eb4-4769-b7f1-8caa6a7c0d7f",
            price: 64118.21,
            price_sources: [],
            processed_ms: 1711252002535,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "ac9afff8-9926-468c-a6f6-3d26b077a0ce",
            price: 64057.29,
            price_sources: [],
            processed_ms: 1711255578576,
          },
        ],
        position_type: "FLAT",
        position_uuid: "7c0be3b4-1d67-4ce6-bccd-31a8ee17f7fe",
        return_at_close: 0.9991151349920717,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 64094.47,
        close_ms: 1711259140729,
        current_return: 1.0009025583642395,
        initial_entry_price: 64094.47,
        is_closed_position: true,
        miner_hotkey: "5FhbvTiD4SRNJDmXRH9cqNFYzdd49vWgb6RXDyRXxfoZGSbg",
        net_leverage: 0.0,
        open_ms: 1711255681546,
        orders: [
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "261b8dfb-9cb3-41e3-9933-bc9c957d2739",
            price: 64094.47,
            price_sources: [],
            processed_ms: 1711255681545,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "13e6e2d3-1dea-4c8f-9c4d-5a6d458f56e7",
            price: 64287.3,
            price_sources: [],
            processed_ms: 1711259140729,
          },
        ],
        position_type: "FLAT",
        position_uuid: "a137da1d-6e7f-42a7-a7fa-40b915e6d97a",
        return_at_close: 1.000302016829221,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 64359.11,
        close_ms: 1711262798888,
        current_return: 0.9952122162658867,
        initial_entry_price: 64359.11,
        is_closed_position: true,
        miner_hotkey: "5FhbvTiD4SRNJDmXRH9cqNFYzdd49vWgb6RXDyRXxfoZGSbg",
        net_leverage: 0.0,
        open_ms: 1711259268257,
        orders: [
          {
            leverage: 1.25,
            order_type: "LONG",
            order_uuid: "b7a94221-b782-498d-a2b4-1fd718383195",
            price: 64359.11,
            price_sources: [],
            processed_ms: 1711259268256,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "f52b4d48-0d41-4ff8-9fca-ca96cc7ba66b",
            price: 64112.6,
            price_sources: [],
            processed_ms: 1711262798888,
          },
        ],
        position_type: "FLAT",
        position_uuid: "a08d8313-3fc6-47ec-9b33-f666ab46cdd5",
        return_at_close: 0.992724185725222,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 64152.280000000006,
        close_ms: 1711266463867,
        current_return: 1.0039780347635345,
        initial_entry_price: 64152.28,
        is_closed_position: true,
        miner_hotkey: "5FhbvTiD4SRNJDmXRH9cqNFYzdd49vWgb6RXDyRXxfoZGSbg",
        net_leverage: 0.0,
        open_ms: 1711262887619,
        orders: [
          {
            leverage: 1.25,
            order_type: "LONG",
            order_uuid: "e9b255d0-c738-4fac-afa4-bd8818d7d325",
            price: 64152.28,
            price_sources: [],
            processed_ms: 1711262887619,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "bf7f0058-dc49-4b38-bdd0-780d69717b4a",
            price: 64356.44,
            price_sources: [],
            processed_ms: 1711266463867,
          },
        ],
        position_type: "FLAT",
        position_uuid: "8b2b47a1-23fd-4328-a6ed-bfb7d6fac31c",
        return_at_close: 1.0014680896766257,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 64329.58,
        close_ms: 1711270130619,
        current_return: 1.0109370370520063,
        initial_entry_price: 64329.58,
        is_closed_position: true,
        miner_hotkey: "5FhbvTiD4SRNJDmXRH9cqNFYzdd49vWgb6RXDyRXxfoZGSbg",
        net_leverage: 0.0,
        open_ms: 1711266550499,
        orders: [
          {
            leverage: 1.25,
            order_type: "LONG",
            order_uuid: "2a5e6545-b5e1-4605-8abd-44bc3a5535fe",
            price: 64329.58,
            price_sources: [],
            processed_ms: 1711266550498,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "5a599fbf-5385-40d0-8755-25e42a214204",
            price: 64892.44,
            price_sources: [],
            processed_ms: 1711270130619,
          },
        ],
        position_type: "FLAT",
        position_uuid: "f4f34e46-f079-4de7-a5ae-1d97482df36f",
        return_at_close: 1.0084096944593763,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 64968.0,
        close_ms: 1711273787870,
        current_return: 1.0005100587981777,
        initial_entry_price: 64968.0,
        is_closed_position: true,
        miner_hotkey: "5FhbvTiD4SRNJDmXRH9cqNFYzdd49vWgb6RXDyRXxfoZGSbg",
        net_leverage: 0.0,
        open_ms: 1711270217551,
        orders: [
          {
            leverage: 1.25,
            order_type: "LONG",
            order_uuid: "570cb240-7468-403e-a792-16b522481d1a",
            price: 64968.0,
            price_sources: [],
            processed_ms: 1711270217550,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "4d1d1035-fa12-41ba-b601-d025f59c5497",
            price: 64994.51,
            price_sources: [],
            processed_ms: 1711273787870,
          },
        ],
        position_type: "FLAT",
        position_uuid: "30825961-f5f4-4ae4-8641-8925c2ec621d",
        return_at_close: 0.9980087836511823,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 64999.5,
        close_ms: 1711277453739,
        current_return: 1.0038750298079215,
        initial_entry_price: 64999.5,
        is_closed_position: true,
        miner_hotkey: "5FhbvTiD4SRNJDmXRH9cqNFYzdd49vWgb6RXDyRXxfoZGSbg",
        net_leverage: 0.0,
        open_ms: 1711273876003,
        orders: [
          {
            leverage: 1.25,
            order_type: "LONG",
            order_uuid: "30752996-6692-4903-9f8e-3a239c514827",
            price: 64999.5,
            price_sources: [],
            processed_ms: 1711273876002,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "acb2a500-5d08-4e6e-8158-ae7af8a2bb37",
            price: 65201.0,
            price_sources: [],
            processed_ms: 1711277453739,
          },
        ],
        position_type: "FLAT",
        position_uuid: "a4cbe4b2-4eb5-4e88-9b73-3d1e0b3abfeb",
        return_at_close: 1.0013653422334017,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 65888.56,
        close_ms: 1711307899076,
        current_return: 0.9993957600530352,
        initial_entry_price: 65888.56,
        is_closed_position: true,
        miner_hotkey: "5FhbvTiD4SRNJDmXRH9cqNFYzdd49vWgb6RXDyRXxfoZGSbg",
        net_leverage: 0.0,
        open_ms: 1711306958776,
        orders: [
          {
            leverage: 1.25,
            order_type: "LONG",
            order_uuid: "8bda3786-f5d1-40ba-be74-72164046e9fd",
            price: 65888.56,
            price_sources: [],
            processed_ms: 1711306958774,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "745d035c-f86a-42ca-9e8c-b1198bbb9e5d",
            price: 65856.71,
            price_sources: [],
            processed_ms: 1711307899076,
          },
        ],
        position_type: "FLAT",
        position_uuid: "2836d38f-c480-41cf-9fb5-1a858ec66259",
        return_at_close: 0.9968972706529027,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 66208.88,
        close_ms: 1711313484089,
        current_return: 1.0013302596268052,
        initial_entry_price: 66208.88,
        is_closed_position: true,
        miner_hotkey: "5FhbvTiD4SRNJDmXRH9cqNFYzdd49vWgb6RXDyRXxfoZGSbg",
        net_leverage: 0.0,
        open_ms: 1711312576678,
        orders: [
          {
            leverage: 1.25,
            order_type: "LONG",
            order_uuid: "c2964eda-2646-409d-90cb-10dddb4fce59",
            price: 66208.88,
            price_sources: [],
            processed_ms: 1711312576677,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "a3777425-01a6-452a-ab0f-631c8cea772c",
            price: 66279.34,
            price_sources: [],
            processed_ms: 1711313484089,
          },
        ],
        position_type: "FLAT",
        position_uuid: "e69c18c9-b48a-4290-8a15-4efb176c6c74",
        return_at_close: 0.9988269339777383,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 66884.18,
        close_ms: 1711326517501,
        current_return: 0.9998912298842568,
        initial_entry_price: 66884.18,
        is_closed_position: true,
        miner_hotkey: "5FhbvTiD4SRNJDmXRH9cqNFYzdd49vWgb6RXDyRXxfoZGSbg",
        net_leverage: 0.0,
        open_ms: 1711325588580,
        orders: [
          {
            leverage: 1.25,
            order_type: "LONG",
            order_uuid: "8a65224e-69ca-4b70-a4b1-058819f546a8",
            price: 66884.18,
            price_sources: [],
            processed_ms: 1711325588579,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "3f2218ac-a88c-48f9-9ad3-cfca35d1be4d",
            price: 66878.36,
            price_sources: [],
            processed_ms: 1711326517501,
          },
        ],
        position_type: "FLAT",
        position_uuid: "f5c27683-3c93-4158-ac8f-71a24a49d596",
        return_at_close: 0.9973915018095463,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 66604.31,
        close_ms: 1711328384625,
        current_return: 0.9995004077063482,
        initial_entry_price: 66604.31,
        is_closed_position: true,
        miner_hotkey: "5FhbvTiD4SRNJDmXRH9cqNFYzdd49vWgb6RXDyRXxfoZGSbg",
        net_leverage: 0.0,
        open_ms: 1711327452907,
        orders: [
          {
            leverage: 1.25,
            order_type: "LONG",
            order_uuid: "601b5866-e6c5-4468-81b0-73a4ecd2b6d1",
            price: 66604.31,
            price_sources: [],
            processed_ms: 1711327452905,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "b4ab7381-8bf8-4036-be7d-49394c20388f",
            price: 66577.69,
            price_sources: [],
            processed_ms: 1711328384625,
          },
        ],
        position_type: "FLAT",
        position_uuid: "9e8fb43b-825b-4dc7-9b7f-d3fb381b7110",
        return_at_close: 0.9970016566870824,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 66883.57,
        close_ms: 1711359621334,
        current_return: 0.999353540787371,
        initial_entry_price: 66883.57,
        is_closed_position: true,
        miner_hotkey: "5FhbvTiD4SRNJDmXRH9cqNFYzdd49vWgb6RXDyRXxfoZGSbg",
        net_leverage: 0.0,
        open_ms: 1711357790211,
        orders: [
          {
            leverage: 1.25,
            order_type: "LONG",
            order_uuid: "ac260005-378b-4bc2-9dac-9fa13867dd4c",
            price: 66883.57,
            price_sources: [],
            processed_ms: 1711357790210,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "223ea4b1-77ac-4e00-b6d5-5c09240208fe",
            price: 66848.98,
            price_sources: [],
            processed_ms: 1711359621334,
          },
        ],
        position_type: "FLAT",
        position_uuid: "030bb16f-d151-4599-8c4e-f1e067a41ed6",
        return_at_close: 0.9968551569354027,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 67176.26727272727,
        close_ms: 1711375932053,
        current_return: 1.0573312166495934,
        initial_entry_price: 67259.3,
        is_closed_position: true,
        miner_hotkey: "5FhbvTiD4SRNJDmXRH9cqNFYzdd49vWgb6RXDyRXxfoZGSbg",
        net_leverage: 0.0,
        open_ms: 1711361483429,
        orders: [
          {
            leverage: 1.25,
            order_type: "LONG",
            order_uuid: "caf1cd41-1582-4bbe-aaca-ff7a6e9f68e6",
            price: 67259.3,
            price_sources: [],
            processed_ms: 1711361483428,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "0e6b8ac3-e56b-4f75-990f-377a4588fbf8",
            price: 66902.47,
            price_sources: [],
            processed_ms: 1711366840935,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "8993ffe2-c1cc-49ff-864b-1500bc1ff79e",
            price: 66853.03,
            price_sources: [],
            processed_ms: 1711367807631,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "c1c02c78-bc05-4ea5-9f1a-832db0e60aec",
            price: 67131.89,
            price_sources: [],
            processed_ms: 1711369628557,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "371a4640-5067-4fd0-b85b-b9bd7ed752e0",
            price: 67045.19,
            price_sources: [],
            processed_ms: 1711371494214,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "b8bc36af-6d36-4b13-89ae-c73ed631d2fa",
            price: 66956.01,
            price_sources: [],
            processed_ms: 1711373294993,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "087b73ce-bd39-4112-8169-e5646f89bc38",
            price: 67753.85,
            price_sources: [],
            processed_ms: 1711375120733,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "1967fece-700c-4e4c-aefc-76a3a42e131e",
            price: 68578.47,
            price_sources: [],
            processed_ms: 1711375932053,
          },
        ],
        position_type: "FLAT",
        position_uuid: "b7be6f12-fd65-4f80-b1be-171129eec48e",
        return_at_close: 1.0515158949580208,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 68868.54,
        close_ms: 1711376481309,
        current_return: 0.9950400066561597,
        initial_entry_price: 68868.54,
        is_closed_position: true,
        miner_hotkey: "5FhbvTiD4SRNJDmXRH9cqNFYzdd49vWgb6RXDyRXxfoZGSbg",
        net_leverage: 0.0,
        open_ms: 1711376478596,
        orders: [
          {
            leverage: -1.25,
            order_type: "SHORT",
            order_uuid: "1f55f991-c044-48a7-8947-19cd82ee7b56",
            price: 68868.54,
            price_sources: [],
            processed_ms: 1711376477994,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "52f285e0-4cba-4126-8f7d-99b1c84f7db9",
            price: 69141.81,
            price_sources: [],
            processed_ms: 1711376481309,
          },
        ],
        position_type: "FLAT",
        position_uuid: "a71ed35d-03e8-4063-af69-d7c38cf34303",
        return_at_close: 0.9925524066395193,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 69669.66000000003,
        close_ms: 1711378209049,
        current_return: 1.0021955521700647,
        initial_entry_price: 69210.38,
        is_closed_position: true,
        miner_hotkey: "5FhbvTiD4SRNJDmXRH9cqNFYzdd49vWgb6RXDyRXxfoZGSbg",
        net_leverage: 0.0,
        open_ms: 1711376768073,
        orders: [
          {
            leverage: -1.25,
            order_type: "SHORT",
            order_uuid: "de9c8b3b-300a-4fcf-bb81-f9991f513d51",
            price: 69210.38,
            price_sources: [],
            processed_ms: 1711376768071,
          },
          {
            leverage: 1.0,
            order_type: "LONG",
            order_uuid: "d19f923c-5fe2-4254-b1f3-63663a63885a",
            price: 69095.56,
            price_sources: [],
            processed_ms: 1711377928825,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "e8be3de9-897a-47a5-9713-7da9d38d0048",
            price: 69061.84,
            price_sources: [],
            processed_ms: 1711378209049,
          },
        ],
        position_type: "FLAT",
        position_uuid: "82bcd831-53e0-47dc-8d81-dac0af6a1aed",
        return_at_close: 0.9996900632896396,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 61664.09999999996,
        close_ms: 1711390875947,
        current_return: 0.9837662539334749,
        initial_entry_price: 69226.32,
        is_closed_position: true,
        miner_hotkey: "5FhbvTiD4SRNJDmXRH9cqNFYzdd49vWgb6RXDyRXxfoZGSbg",
        net_leverage: 0.0,
        open_ms: 1711379964285,
        orders: [
          {
            leverage: -1.25,
            order_type: "SHORT",
            order_uuid: "b2354321-f766-42c8-bbcc-0b1996ebeba7",
            price: 69226.32,
            price_sources: [],
            processed_ms: 1711379962904,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "8c03f1be-37ec-419f-98f4-698886ff6c16",
            price: 69941.84,
            price_sources: [],
            processed_ms: 1711381783412,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "ae334be6-2192-4503-97cf-ea988883a767",
            price: 69676.09,
            price_sources: [],
            processed_ms: 1711383603795,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "24bef163-62b2-40fd-bc4b-d0af157c0b83",
            price: 70016.85,
            price_sources: [],
            processed_ms: 1711385417038,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "f92fa142-3b19-47c7-b985-9ec686fa8b39",
            price: 70500.0,
            price_sources: [],
            processed_ms: 1711387229874,
          },
          {
            leverage: 0.125,
            order_type: "LONG",
            order_uuid: "47263e17-4213-400f-9fc6-75c3a54ecd77",
            price: 70329.54,
            price_sources: [],
            processed_ms: 1711389056525,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "9a791a24-8ec4-4d83-b41a-8087b101d605",
            price: 70654.52,
            price_sources: [],
            processed_ms: 1711390875947,
          },
        ],
        position_type: "FLAT",
        position_uuid: "3bdacdc1-29dd-4ae3-ae98-f1e5b6a270e4",
        return_at_close: 0.9813068382986413,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 213194.84000001274,
        close_ms: 1711429051230,
        current_return: 0.9960171407080788,
        initial_entry_price: 69933.28,
        is_closed_position: true,
        miner_hotkey: "5FhbvTiD4SRNJDmXRH9cqNFYzdd49vWgb6RXDyRXxfoZGSbg",
        net_leverage: 0.0,
        open_ms: 1711403598261,
        orders: [
          {
            leverage: 1.25,
            order_type: "LONG",
            order_uuid: "f539efe7-0dde-4aac-a339-30438f09acce",
            price: 69933.28,
            price_sources: [],
            processed_ms: 1711403598259,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "9a473147-a7b9-4730-88c9-1334ce8bf4dc",
            price: 70353.59,
            price_sources: [],
            processed_ms: 1711405433849,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "48243f75-16aa-4b8d-a49c-f0183066d609",
            price: 70361.41,
            price_sources: [],
            processed_ms: 1711407239521,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "a89dfb0b-6586-4c0c-92c8-e8f1366aefa0",
            price: 70329.67,
            price_sources: [],
            processed_ms: 1711409048932,
          },
          {
            leverage: -1.0,
            order_type: "SHORT",
            order_uuid: "5354576f-fd4e-4c00-a779-50c5fde5f0f0",
            price: 69924.43,
            price_sources: [],
            processed_ms: 1711410865741,
          },
          {
            leverage: -0.5,
            order_type: "SHORT",
            order_uuid: "77d2d734-64d9-48e3-a9b4-dadba4f32bdd",
            price: 69816.64,
            price_sources: [],
            processed_ms: 1711412687861,
          },
          {
            leverage: -0.25,
            order_type: "SHORT",
            order_uuid: "2a9a736a-e161-4a6d-9946-d1f55af243c2",
            price: 69973.51,
            price_sources: [],
            processed_ms: 1711414502284,
          },
          {
            leverage: -0.125,
            order_type: "SHORT",
            order_uuid: "3a570505-8436-469d-8f48-615bf3bf37c8",
            price: 70271.67,
            price_sources: [],
            processed_ms: 1711416334802,
          },
          {
            leverage: -0.0625,
            order_type: "SHORT",
            order_uuid: "bc3b41f6-72c3-46a3-9b5c-e8e4bfdf2615",
            price: 70271.46,
            price_sources: [],
            processed_ms: 1711418132932,
          },
          {
            leverage: -0.03125,
            order_type: "SHORT",
            order_uuid: "bf46799a-1d7f-4996-849d-967d456555fe",
            price: 70237.09,
            price_sources: [],
            processed_ms: 1711419951069,
          },
          {
            leverage: -0.015625,
            order_type: "SHORT",
            order_uuid: "2c22428e-6a5c-4157-906a-761268a3f9cd",
            price: 70441.65,
            price_sources: [],
            processed_ms: 1711421777940,
          },
          {
            leverage: -0.0078125,
            order_type: "SHORT",
            order_uuid: "cbd3c37d-694b-43e3-b09e-ec487428dc3f",
            price: 70486.95,
            price_sources: [],
            processed_ms: 1711423597748,
          },
          {
            leverage: -0.00390625,
            order_type: "SHORT",
            order_uuid: "47219c23-30ad-4261-8ed6-8ad7d1c9b039",
            price: 70464.85,
            price_sources: [],
            processed_ms: 1711425413005,
          },
          {
            leverage: -0.001953125,
            order_type: "SHORT",
            order_uuid: "0c6e6d4f-9c33-4622-a89d-31c71730fe72",
            price: 70567.1,
            price_sources: [],
            processed_ms: 1711427254797,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "adf44649-babe-4907-84f6-ecf44536233b",
            price: 70585.22,
            price_sources: [],
            processed_ms: 1711429051230,
          },
        ],
        position_type: "FLAT",
        position_uuid: "8c486466-cb02-43be-b5f9-65b03ce9dfe9",
        return_at_close: 0.9920330721452465,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 69670.13988487088,
        close_ms: 1711501330003,
        current_return: 1.0509577179052967,
        initial_entry_price: 70378.89,
        is_closed_position: true,
        miner_hotkey: "5FhbvTiD4SRNJDmXRH9cqNFYzdd49vWgb6RXDyRXxfoZGSbg",
        net_leverage: 0.0,
        open_ms: 1711432688349,
        orders: [
          {
            leverage: 1.25,
            order_type: "LONG",
            order_uuid: "dec5a005-e80e-4797-9a0d-f5ca339b38d8",
            price: 70378.89,
            price_sources: [],
            processed_ms: 1711432688347,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "763b1b73-6312-4126-92cf-a3a0130d5668",
            price: 70377.52,
            price_sources: [],
            processed_ms: 1711434529279,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "7ccec965-831a-4f4f-9713-c4d91c442789",
            price: 70592.95,
            price_sources: [],
            processed_ms: 1711436319919,
          },
          {
            leverage: -0.875,
            order_type: "SHORT",
            order_uuid: "f71f434e-9121-4cc9-b41f-28a6dbf55227",
            price: 71364.94,
            price_sources: [],
            processed_ms: 1711438144673,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "3990c353-0e13-4285-97cc-dae555ca7278",
            price: 70487.51,
            price_sources: [],
            processed_ms: 1711439961673,
          },
          {
            leverage: -0.5625,
            order_type: "SHORT",
            order_uuid: "1a5821f3-410c-4052-998f-d7ab5ed614bf",
            price: 70626.87,
            price_sources: [],
            processed_ms: 1711441774424,
          },
          {
            leverage: -0.28125,
            order_type: "SHORT",
            order_uuid: "5dbb50dd-40fd-4139-a3f9-ada043ae24cf",
            price: 70656.63,
            price_sources: [],
            processed_ms: 1711443623456,
          },
          {
            leverage: -0.140625,
            order_type: "SHORT",
            order_uuid: "b23d4a2f-e2d0-488b-bdcb-fb12a60c5d8b",
            price: 71058.76,
            price_sources: [],
            processed_ms: 1711445410216,
          },
          {
            leverage: -0.0703125,
            order_type: "SHORT",
            order_uuid: "c27ad5e3-3341-401e-9cb7-5dbb9078e97b",
            price: 71159.05,
            price_sources: [],
            processed_ms: 1711447239144,
          },
          {
            leverage: -0.03515625,
            order_type: "SHORT",
            order_uuid: "d51d9343-caea-48d7-a4d5-be406af618ef",
            price: 70933.23,
            price_sources: [],
            processed_ms: 1711449055651,
          },
          {
            leverage: -0.017578125,
            order_type: "SHORT",
            order_uuid: "3ba1ddff-0cfe-4e8a-8e83-531560eb30ee",
            price: 70919.95,
            price_sources: [],
            processed_ms: 1711450894317,
          },
          {
            leverage: -0.0087890625,
            order_type: "SHORT",
            order_uuid: "5a4d0369-3e87-498d-be51-04f897f14f9d",
            price: 70737.53,
            price_sources: [],
            processed_ms: 1711452700419,
          },
          {
            leverage: -0.00439453125,
            order_type: "SHORT",
            order_uuid: "84585880-8f11-4675-8459-fe28a2716c34",
            price: 70682.37,
            price_sources: [],
            processed_ms: 1711454499872,
          },
          {
            leverage: -0.002197265625,
            order_type: "SHORT",
            order_uuid: "c2f84618-9396-468d-91b7-ca4b5f20d61e",
            price: 70835.31,
            price_sources: [],
            processed_ms: 1711456325896,
          },
          {
            leverage: -0.0010986328125,
            order_type: "SHORT",
            order_uuid: "312bffe5-65ec-44f4-827f-8464ddce704a",
            price: 71069.26,
            price_sources: [],
            processed_ms: 1711458137114,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "967d0b3b-ee3f-4339-952d-59caeed88c9e",
            price: 70702.65,
            price_sources: [],
            processed_ms: 1711459955134,
          },
          {
            leverage: -0.12554931640625,
            order_type: "SHORT",
            order_uuid: "27faebb5-d29d-4baf-96c6-fc1eb17ee57a",
            price: 70361.81,
            price_sources: [],
            processed_ms: 1711461803956,
          },
          {
            leverage: -0.100439453125,
            order_type: "SHORT",
            order_uuid: "55bd457f-d074-4231-b75f-e0160c5b2bd0",
            price: 69537.4,
            price_sources: [],
            processed_ms: 1711463586496,
          },
          {
            leverage: 0.24999999999999997,
            order_type: "LONG",
            order_uuid: "c63c1773-114f-40bb-a09b-d9c585c3a3f3",
            price: 70076.17,
            price_sources: [],
            processed_ms: 1711465400053,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "bf7220ee-52f2-4f83-ac89-4f1abdef4262",
            price: 70067.08,
            price_sources: [],
            processed_ms: 1711467229012,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "c54818af-4eff-4506-91bb-31622f4ae976",
            price: 70323.63,
            price_sources: [],
            processed_ms: 1711469089784,
          },
          {
            leverage: 0.2500000000000001,
            order_type: "LONG",
            order_uuid: "82ba6403-cfed-4e69-9e32-3cfa2fbc2a7d",
            price: 69884.73,
            price_sources: [],
            processed_ms: 1711470871870,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "800e2fd7-c15f-46b2-bba0-6b355c45fad9",
            price: 69883.92,
            price_sources: [],
            processed_ms: 1711472685144,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "1907b565-458c-4f7a-a70e-7b3fef23e3d4",
            price: 70024.86,
            price_sources: [],
            processed_ms: 1711474523622,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "cc875456-c4e8-41ca-bfc0-df14542fb245",
            price: 70144.01,
            price_sources: [],
            processed_ms: 1711476308903,
          },
          {
            leverage: -0.887554931640625,
            order_type: "SHORT",
            order_uuid: "ed9b547b-5013-421e-ba21-25ae94106ee7",
            price: 70308.85,
            price_sources: [],
            processed_ms: 1711478131900,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "5edd932a-77c9-4f17-8af9-90043de021a7",
            price: 70019.52,
            price_sources: [],
            processed_ms: 1711479945381,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "de329afc-6f50-4d40-b781-e5b229f8c807",
            price: 69740.81,
            price_sources: [],
            processed_ms: 1711481766132,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "e07a3cd4-86ca-4936-8df9-d588a18162d5",
            price: 69671.78,
            price_sources: [],
            processed_ms: 1711483578976,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "57565842-b35c-41a9-9579-a9e25617dc9b",
            price: 69799.43,
            price_sources: [],
            processed_ms: 1711485420299,
          },
          {
            leverage: 0.24999999999999978,
            order_type: "LONG",
            order_uuid: "2f6fd79d-fedd-46ea-b0f1-898bb89128ce",
            price: 69831.5,
            price_sources: [],
            processed_ms: 1711487212912,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "ecd07ddd-0664-42e9-991c-2a1c45074fb9",
            price: 70143.67,
            price_sources: [],
            processed_ms: 1711489028659,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "0acacdfa-10f3-4770-932e-d5ec246c8e0a",
            price: 70001.13,
            price_sources: [],
            processed_ms: 1711490845412,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "5d7b3971-346a-4afc-9c23-e4879b928b45",
            price: 69912.83,
            price_sources: [],
            processed_ms: 1711492652952,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "ae1c8407-702f-4588-8abf-d233ef18daf7",
            price: 70205.33,
            price_sources: [],
            processed_ms: 1711494488376,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "8c1ca6d7-11a9-409d-87eb-2f1de666b6d2",
            price: 70083.61,
            price_sources: [],
            processed_ms: 1711496303078,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "7f35fc09-4289-45ad-a25e-39c3cc7b6930",
            price: 69923.23,
            price_sources: [],
            processed_ms: 1711498112994,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "81a08e7c-095c-4b40-a1c9-f477b648aa65",
            price: 70264.49,
            price_sources: [],
            processed_ms: 1711499934098,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "a031e63e-0dd2-417c-9c0d-a72a5cd4e72b",
            price: 70592.66,
            price_sources: [],
            processed_ms: 1711501330003,
          },
        ],
        position_type: "FLAT",
        position_uuid: "fd3faa4d-3d46-4ef2-8330-a62fcc0b3ecc",
        return_at_close: 1.0427864061869196,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 70469.36739130432,
        close_ms: 1711547246434,
        current_return: 0.9797194538176166,
        initial_entry_price: 70550.06,
        is_closed_position: true,
        miner_hotkey: "5FhbvTiD4SRNJDmXRH9cqNFYzdd49vWgb6RXDyRXxfoZGSbg",
        net_leverage: 0.0,
        open_ms: 1711503594746,
        orders: [
          {
            leverage: 1.25,
            order_type: "LONG",
            order_uuid: "c23b6f92-3c79-4f9b-a197-808df129339a",
            price: 70550.06,
            price_sources: [],
            processed_ms: 1711503594744,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "65d63d2a-da2d-4e10-9214-3152c38af58f",
            price: 70272.39,
            price_sources: [],
            processed_ms: 1711505379948,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "57828430-a9a2-4573-bcea-b7df5bb10ae8",
            price: 70336.93,
            price_sources: [],
            processed_ms: 1711507206965,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "7626f51e-189a-4ba0-a77f-13aaf267eaf7",
            price: 70590.43,
            price_sources: [],
            processed_ms: 1711509027261,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "fa58c64a-3831-44f7-ac38-e98377ac1d68",
            price: 70435.87,
            price_sources: [],
            processed_ms: 1711510841929,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "97cc71f2-db08-4f94-8352-b077219341bd",
            price: 70417.75,
            price_sources: [],
            processed_ms: 1711512655084,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "351d9266-57a1-4952-b290-148c2641778a",
            price: 70392.43,
            price_sources: [],
            processed_ms: 1711514475863,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "c7150a1f-e5f1-4b7a-b1ee-c65e0b1755da",
            price: 70255.97,
            price_sources: [],
            processed_ms: 1711516288999,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "9a15f55b-62ce-44d7-bebf-76e4ba17f6c6",
            price: 70474.26,
            price_sources: [],
            processed_ms: 1711518133932,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "968847b9-8606-4d48-9d44-f0be7c71d4c9",
            price: 70544.23,
            price_sources: [],
            processed_ms: 1711519929121,
          },
          {
            leverage: -1.75,
            order_type: "SHORT",
            order_uuid: "abddf269-bb4e-43e9-ba35-f76077f3b540",
            price: 70238.07,
            price_sources: [],
            processed_ms: 1711521737926,
          },
          {
            leverage: 0.375,
            order_type: "LONG",
            order_uuid: "47f6cd7c-d761-45c4-8e4f-7774a27962b3",
            price: 70109.34,
            price_sources: [],
            processed_ms: 1711536868112,
          },
          {
            leverage: 0.375,
            order_type: "LONG",
            order_uuid: "4b8314ff-efac-4a55-ba86-23796940a747",
            price: 70186.74,
            price_sources: [],
            processed_ms: 1711540482913,
          },
          {
            leverage: 0.375,
            order_type: "LONG",
            order_uuid: "facfac7c-70fd-434c-a817-c9b2bca3cd8e",
            price: 70099.69,
            price_sources: [],
            processed_ms: 1711544143728,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "0aa0e077-f2e7-4b67-8fe5-a04e6e2f5241",
            price: 69971.7,
            price_sources: [],
            processed_ms: 1711547246434,
          },
        ],
        position_type: "FLAT",
        position_uuid: "51130243-f12b-4601-86f9-2f798fba0827",
        return_at_close: 0.9728614176408933,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 80523.61999999975,
        close_ms: 1711565842772,
        current_return: 1.0134000325352388,
        initial_entry_price: 70093.23,
        is_closed_position: true,
        miner_hotkey: "5FhbvTiD4SRNJDmXRH9cqNFYzdd49vWgb6RXDyRXxfoZGSbg",
        net_leverage: 0.0,
        open_ms: 1711547712859,
        orders: [
          {
            leverage: -1.25,
            order_type: "SHORT",
            order_uuid: "11eea57f-b1ca-4e40-93f4-30979a8db798",
            price: 70093.23,
            price_sources: [],
            processed_ms: 1711547712857,
          },
          {
            leverage: 0.625,
            order_type: "LONG",
            order_uuid: "44d31420-1016-465d-b64e-4bd34c0d868c",
            price: 69722.89,
            price_sources: [],
            processed_ms: 1711551356892,
          },
          {
            leverage: 0.3125,
            order_type: "LONG",
            order_uuid: "eaf622d0-7dcc-4bce-9248-c310e7951ddf",
            price: 69032.07,
            price_sources: [],
            processed_ms: 1711554971404,
          },
          {
            leverage: 0.15625,
            order_type: "LONG",
            order_uuid: "ae24059b-30b4-491c-828f-c0db18da7ffa",
            price: 69090.67,
            price_sources: [],
            processed_ms: 1711558589465,
          },
          {
            leverage: 0.078125,
            order_type: "LONG",
            order_uuid: "5d4f76d9-b86e-498a-9889-3294f0815d5d",
            price: 68875.32,
            price_sources: [],
            processed_ms: 1711562206213,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "2151888d-1a60-4f6a-99ff-9e6abe201451",
            price: 68501.2,
            price_sources: [],
            processed_ms: 1711565842772,
          },
        ],
        position_type: "FLAT",
        position_uuid: "b80ab85b-f57a-43f0-a133-3eeb44edbfe3",
        return_at_close: 1.0108665324539008,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 66933.55782608705,
        close_ms: 1711618494239,
        current_return: 1.0390180205539008,
        initial_entry_price: 68751.91,
        is_closed_position: true,
        miner_hotkey: "5FhbvTiD4SRNJDmXRH9cqNFYzdd49vWgb6RXDyRXxfoZGSbg",
        net_leverage: 0.0,
        open_ms: 1711569434109,
        orders: [
          {
            leverage: 1.25,
            order_type: "LONG",
            order_uuid: "0589a582-05e0-46d4-b854-84115cc648ca",
            price: 68751.91,
            price_sources: [],
            processed_ms: 1711569434106,
          },
          {
            leverage: 0.375,
            order_type: "LONG",
            order_uuid: "a96161af-109b-4cbc-bfe3-e67cbb688618",
            price: 68838.11,
            price_sources: [],
            processed_ms: 1711573060865,
          },
          {
            leverage: 0.375,
            order_type: "LONG",
            order_uuid: "018e87f4-d896-40ac-9e11-99c479340e02",
            price: 68892.53,
            price_sources: [],
            processed_ms: 1711576685985,
          },
          {
            leverage: -1.0,
            order_type: "SHORT",
            order_uuid: "4936153c-ec04-4215-a77c-8ea1a2a00ada",
            price: 69405.83,
            price_sources: [],
            processed_ms: 1711580314587,
          },
          {
            leverage: 0.375,
            order_type: "LONG",
            order_uuid: "400b2d4c-3006-45b0-9e86-0c23207b61bd",
            price: 69522.13,
            price_sources: [],
            processed_ms: 1711583908984,
          },
          {
            leverage: 0.375,
            order_type: "LONG",
            order_uuid: "b5520a12-0459-47d8-9b32-509865c67ff7",
            price: 69651.98,
            price_sources: [],
            processed_ms: 1711587535085,
          },
          {
            leverage: 0.375,
            order_type: "LONG",
            order_uuid: "d3b79856-95d2-45c7-b432-a27f436da8d3",
            price: 69404.52,
            price_sources: [],
            processed_ms: 1711591183288,
          },
          {
            leverage: 0.375,
            order_type: "LONG",
            order_uuid: "42c2f353-0a1d-45aa-81cb-27767cb9dc71",
            price: 69060.03,
            price_sources: [],
            processed_ms: 1711594781184,
          },
          {
            leverage: 0.375,
            order_type: "LONG",
            order_uuid: "3d793a56-2607-4e27-95c7-d0804ba10b9d",
            price: 69278.73,
            price_sources: [],
            processed_ms: 1711598394510,
          },
          {
            leverage: -1.4375,
            order_type: "SHORT",
            order_uuid: "1543d5ef-31c1-47a5-bb32-deef2805ec29",
            price: 69657.73,
            price_sources: [],
            processed_ms: 1711601500995,
          },
          {
            leverage: -0.71875,
            order_type: "SHORT",
            order_uuid: "ac996079-5774-40ba-a930-69a1b81ff75f",
            price: 69614.73,
            price_sources: [],
            processed_ms: 1711603322609,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "aac84d1b-fd15-4271-8dc4-7c45a0fb7ebb",
            price: 70665.82,
            price_sources: [],
            processed_ms: 1711618494239,
          },
        ],
        position_type: "FLAT",
        position_uuid: "d2182599-616a-469d-aa6c-43867d5d698f",
        return_at_close: 1.033043666935716,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 70799.42,
        close_ms: 1711634208971,
        current_return: 0.9949659898343799,
        initial_entry_price: 70799.42,
        is_closed_position: true,
        miner_hotkey: "5FhbvTiD4SRNJDmXRH9cqNFYzdd49vWgb6RXDyRXxfoZGSbg",
        net_leverage: 0.0,
        open_ms: 1711634074103,
        orders: [
          {
            leverage: -3.5,
            order_type: "SHORT",
            order_uuid: "c9ed82db-faa4-4af6-bbd8-307622702330",
            price: 70799.42,
            price_sources: [],
            processed_ms: 1711634074084,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "00eca72e-6613-4f21-a060-72fb78f5dbd8",
            price: 70901.25,
            price_sources: [],
            processed_ms: 1711634208971,
          },
        ],
        position_type: "FLAT",
        position_uuid: "821219c8-8bab-4fd9-ba61-5244f6050189",
        return_at_close: 0.9880012279055392,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 71412.58,
        close_ms: 1711637818097,
        current_return: 0.9958869991813768,
        initial_entry_price: 71412.58,
        is_closed_position: true,
        miner_hotkey: "5FhbvTiD4SRNJDmXRH9cqNFYzdd49vWgb6RXDyRXxfoZGSbg",
        net_leverage: 0.0,
        open_ms: 1711637731536,
        orders: [
          {
            leverage: -3.5,
            order_type: "SHORT",
            order_uuid: "8bab9167-1d3b-4c64-ba50-a7706c3e1aed",
            price: 71412.58,
            price_sources: [],
            processed_ms: 1711637731533,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "7d9f4bfa-a845-4d8e-bfdb-d3958c1fd8de",
            price: 71496.5,
            price_sources: [],
            processed_ms: 1711637818097,
          },
        ],
        position_type: "FLAT",
        position_uuid: "9b13858f-58fa-4312-af2f-2f6ec8774699",
        return_at_close: 0.9889157901871072,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 71035.94571428574,
        close_ms: 1711677117511,
        current_return: 1.002159150971892,
        initial_entry_price: 70816.03,
        is_closed_position: true,
        miner_hotkey: "5FhbvTiD4SRNJDmXRH9cqNFYzdd49vWgb6RXDyRXxfoZGSbg",
        net_leverage: 0.0,
        open_ms: 1711662639074,
        orders: [
          {
            leverage: -5.0,
            order_type: "SHORT",
            order_uuid: "9268fa54-aedb-4b68-ab06-904b1c0ac99d",
            price: 70816.03,
            price_sources: [],
            processed_ms: 1711662639071,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "97689fc5-e3b9-40f5-ae59-70d1077f5dd8",
            price: 70844.26,
            price_sources: [],
            processed_ms: 1711666248509,
          },
          {
            leverage: 1.75,
            order_type: "LONG",
            order_uuid: "789aba8c-3a8c-43b0-96b1-462fa74fef66",
            price: 70753.33,
            price_sources: [],
            processed_ms: 1711669882964,
          },
          {
            leverage: 0.875,
            order_type: "LONG",
            order_uuid: "8e6883ba-88ea-465a-8480-507af13ab6ed",
            price: 70673.12,
            price_sources: [],
            processed_ms: 1711673478045,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "be51d667-dc91-4ae6-bc9f-a8fbca9b85ff",
            price: 70861.2,
            price_sources: [],
            processed_ms: 1711677117511,
          },
        ],
        position_type: "FLAT",
        position_uuid: "d7e79d57-7581-4cc0-93d4-fc9d729deb70",
        return_at_close: 0.992137559462173,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 70088.05600000001,
        close_ms: 1711704981933,
        current_return: 0.9668447754665184,
        initial_entry_price: 70365.23,
        is_closed_position: true,
        miner_hotkey: "5FhbvTiD4SRNJDmXRH9cqNFYzdd49vWgb6RXDyRXxfoZGSbg",
        net_leverage: 0.0,
        open_ms: 1711687981719,
        orders: [
          {
            leverage: 5.0,
            order_type: "LONG",
            order_uuid: "aab5243a-ab94-42c3-ad1e-881af126bdb7",
            price: 70365.23,
            price_sources: [],
            processed_ms: 1711687981717,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "b573b9de-d2c3-488a-b1f5-b8c33de704f0",
            price: 70242.81,
            price_sources: [],
            processed_ms: 1711691571035,
          },
          {
            leverage: -3.25,
            order_type: "SHORT",
            order_uuid: "774931ef-c475-4ff0-90e6-06e373e7dba8",
            price: 70475.22,
            price_sources: [],
            processed_ms: 1711695234520,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "ee37708f-70ca-42f1-8e8e-2d6af17bcb0a",
            price: 69870.17,
            price_sources: [],
            processed_ms: 1711698803480,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "1bfdcd1d-28cc-4558-96e1-e94fb140df3d",
            price: 70066.13,
            price_sources: [],
            processed_ms: 1711702452107,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "5e5b8a87-51d8-45ae-8879-52238ce63210",
            price: 69714.78,
            price_sources: [],
            processed_ms: 1711704981933,
          },
        ],
        position_type: "FLAT",
        position_uuid: "fc3d3753-fcf8-4c95-b5b6-aa4412796832",
        return_at_close: 0.9542757933854537,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 62488.282727272956,
        close_ms: 1711753124834,
        current_return: 1.0398322243622484,
        initial_entry_price: 69791.42,
        is_closed_position: true,
        miner_hotkey: "5FhbvTiD4SRNJDmXRH9cqNFYzdd49vWgb6RXDyRXxfoZGSbg",
        net_leverage: 0.0,
        open_ms: 1711706064127,
        orders: [
          {
            leverage: 5.0,
            order_type: "LONG",
            order_uuid: "2f31d813-944d-4705-a0f8-f7fb7a085695",
            price: 69791.42,
            price_sources: [],
            processed_ms: 1711706064124,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "ef5aadcd-6004-4c25-b2bc-5eb766dc93b7",
            price: 70026.85,
            price_sources: [],
            processed_ms: 1711709681444,
          },
          {
            leverage: -3.25,
            order_type: "SHORT",
            order_uuid: "39a36e42-3c33-4dac-b22e-a763470c5ef1",
            price: 70184.72,
            price_sources: [],
            processed_ms: 1711713320449,
          },
          {
            leverage: -1.625,
            order_type: "SHORT",
            order_uuid: "7e025e5c-3e3e-4ebe-9efa-b7ca6ef47630",
            price: 70110.84,
            price_sources: [],
            processed_ms: 1711716918971,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "d9ea2618-99f5-437a-a1cd-f2cb56619c80",
            price: 70446.91,
            price_sources: [],
            processed_ms: 1711720542076,
          },
          {
            leverage: -1.5625,
            order_type: "SHORT",
            order_uuid: "b0fcbb4c-8862-407f-9b8e-afc4de22cb5a",
            price: 70310.64,
            price_sources: [],
            processed_ms: 1711724154599,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "54f24617-7505-4d5d-8cde-a3b028261f5f",
            price: 69318.61,
            price_sources: [],
            processed_ms: 1711727810551,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "f655cdb5-fa6a-4afa-a6fc-4caeaaed6e3f",
            price: 69290.34,
            price_sources: [],
            processed_ms: 1711731392995,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "8bf1e223-44fa-49d9-839c-107456c4e2a3",
            price: 69313.17,
            price_sources: [],
            processed_ms: 1711735008082,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "2a5141a9-565e-4ee8-9be3-e178497b3b22",
            price: 69505.83,
            price_sources: [],
            processed_ms: 1711738643965,
          },
          {
            leverage: -3.78125,
            order_type: "SHORT",
            order_uuid: "699976c5-1287-4c53-bb51-9b8730974c8b",
            price: 69634.17,
            price_sources: [],
            processed_ms: 1711742250599,
          },
          {
            leverage: -3.025,
            order_type: "SHORT",
            order_uuid: "5bbc2ae3-4bd9-4b6d-9249-27bf593d5b9a",
            price: 69659.76,
            price_sources: [],
            processed_ms: 1711745868379,
          },
          {
            leverage: -0.37812500000000004,
            order_type: "SHORT",
            order_uuid: "043c2778-ff99-4d5b-82c6-3c0e56c49c40",
            price: 69462.41,
            price_sources: [],
            processed_ms: 1711749477008,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "e152521c-9c44-49f4-8720-9a1a191c102c",
            price: 69840.21,
            price_sources: [],
            processed_ms: 1711753124834,
          },
        ],
        position_type: "FLAT",
        position_uuid: "1c7bac30-4692-46dd-b6c6-0afac8717f2a",
        return_at_close: 1.0241047619687693,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 68396.83368421072,
        close_ms: 1711789307643,
        current_return: 1.013584400640025,
        initial_entry_price: 69984.57,
        is_closed_position: true,
        miner_hotkey: "5FhbvTiD4SRNJDmXRH9cqNFYzdd49vWgb6RXDyRXxfoZGSbg",
        net_leverage: 0.0,
        open_ms: 1711760357622,
        orders: [
          {
            leverage: 5.0,
            order_type: "LONG",
            order_uuid: "5f591270-5ae5-4458-84d3-dd993e96c3e6",
            price: 69984.57,
            price_sources: [],
            processed_ms: 1711760357619,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "bb59977a-0b7b-48af-9f0d-07e732e7fcc8",
            price: 70031.38,
            price_sources: [],
            processed_ms: 1711763972956,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "86b63e14-04c5-4feb-b1e0-9b2cafe7776c",
            price: 69999.22,
            price_sources: [],
            processed_ms: 1711767593158,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "5ec72ed8-a1e8-45fc-8a2d-8b8cc7c8aec9",
            price: 69806.4,
            price_sources: [],
            processed_ms: 1711771212014,
          },
          {
            leverage: -4.75,
            order_type: "SHORT",
            order_uuid: "d7548e0a-acde-485d-81c3-5989ce86f3ce",
            price: 70116.56,
            price_sources: [],
            processed_ms: 1711774835914,
          },
          {
            leverage: -2.375,
            order_type: "SHORT",
            order_uuid: "e9c34992-6be1-436e-a749-772db9e8dd82",
            price: 70049.83,
            price_sources: [],
            processed_ms: 1711778476196,
          },
          {
            leverage: -1.1875,
            order_type: "SHORT",
            order_uuid: "2b51b6bb-c7b6-4cc1-b272-e66404663ccc",
            price: 70006.22,
            price_sources: [],
            processed_ms: 1711782073741,
          },
          {
            leverage: -0.59375,
            order_type: "SHORT",
            order_uuid: "33c6fd49-d523-405d-9bd5-1079ffddb006",
            price: 69917.2,
            price_sources: [],
            processed_ms: 1711785725862,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "546d314f-c192-48e2-a5b8-b30a05be7f1e",
            price: 69998.01,
            price_sources: [],
            processed_ms: 1711789307643,
          },
        ],
        position_type: "FLAT",
        position_uuid: "15aaeae8-8917-46e4-a212-58a0655de7c0",
        return_at_close: 0.9943262970278645,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 69441.5557142857,
        close_ms: 1711807389474,
        current_return: 0.9905056568102804,
        initial_entry_price: 70064.22,
        is_closed_position: true,
        miner_hotkey: "5FhbvTiD4SRNJDmXRH9cqNFYzdd49vWgb6RXDyRXxfoZGSbg",
        net_leverage: 0.0,
        open_ms: 1711792927717,
        orders: [
          {
            leverage: -5.0,
            order_type: "SHORT",
            order_uuid: "c7f4c76a-576d-4f19-ac46-da471a27396c",
            price: 70064.22,
            price_sources: [],
            processed_ms: 1711792927713,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "2b0f0078-3b21-469e-be13-f23469583ce2",
            price: 70103.79,
            price_sources: [],
            processed_ms: 1711796542165,
          },
          {
            leverage: 1.75,
            order_type: "LONG",
            order_uuid: "2b560c58-5c9b-4dca-8329-2029d4a4c45d",
            price: 70226.26,
            price_sources: [],
            processed_ms: 1711800159996,
          },
          {
            leverage: 0.875,
            order_type: "LONG",
            order_uuid: "41d14d47-f77d-48d1-bbe9-42aeab27caa0",
            price: 70294.97,
            price_sources: [],
            processed_ms: 1711803773069,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "ffbd2529-1b4c-41db-ba07-114ba2923516",
            price: 70201.8,
            price_sources: [],
            processed_ms: 1711807389474,
          },
        ],
        position_type: "FLAT",
        position_uuid: "6c4450e9-cd67-417b-a8cc-ea8a8fd8dfa6",
        return_at_close: 0.9806006002421775,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 70151.40999999993,
        close_ms: 1711839945851,
        current_return: 0.9967682791794783,
        initial_entry_price: 69900.84,
        is_closed_position: true,
        miner_hotkey: "5FhbvTiD4SRNJDmXRH9cqNFYzdd49vWgb6RXDyRXxfoZGSbg",
        net_leverage: 0.0,
        open_ms: 1711825469969,
        orders: [
          {
            leverage: 5.0,
            order_type: "LONG",
            order_uuid: "bbb76a51-5a65-4bfc-8a24-1e13bbe685b2",
            price: 69900.84,
            price_sources: [],
            processed_ms: 1711825469966,
          },
          {
            leverage: -2.5,
            order_type: "SHORT",
            order_uuid: "0aa70313-56b4-4739-89be-2b29f510170e",
            price: 69884.2,
            price_sources: [],
            processed_ms: 1711829087567,
          },
          {
            leverage: -1.25,
            order_type: "SHORT",
            order_uuid: "f81cee4b-80a7-4b96-90f2-584cf92cd882",
            price: 69879.25,
            price_sources: [],
            processed_ms: 1711832711926,
          },
          {
            leverage: -0.625,
            order_type: "SHORT",
            order_uuid: "d53801d6-22ae-42dd-9f29-e76683f3c48d",
            price: 69760.01,
            price_sources: [],
            processed_ms: 1711836422754,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "c8c6691e-11a6-41f7-bd0e-689645c995a3",
            price: 69789.97,
            price_sources: [],
            processed_ms: 1711839945851,
          },
        ],
        position_type: "FLAT",
        position_uuid: "0b906d86-b847-4db3-b11e-a15b35c34d4e",
        return_at_close: 0.9868005963876835,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 69117.26000000007,
        close_ms: 1711890584937,
        current_return: 0.9899112021177074,
        initial_entry_price: 70226.9,
        is_closed_position: true,
        miner_hotkey: "5FhbvTiD4SRNJDmXRH9cqNFYzdd49vWgb6RXDyRXxfoZGSbg",
        net_leverage: 0.0,
        open_ms: 1711876104784,
        orders: [
          {
            leverage: -5.0,
            order_type: "SHORT",
            order_uuid: "7e81333a-77a0-44ea-804e-0c004db32a45",
            price: 70226.9,
            price_sources: [],
            processed_ms: 1711876104781,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "73cebfcc-a8c4-4fa3-8eb9-c24e4ed521b9",
            price: 70264.45,
            price_sources: [],
            processed_ms: 1711879731991,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "04d0786a-3aa4-45d3-98ca-5acb63599bca",
            price: 70339.76,
            price_sources: [],
            processed_ms: 1711883350997,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "36f349d0-123a-4551-977c-4d639a369046",
            price: 70446.37,
            price_sources: [],
            processed_ms: 1711887026890,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "2c8063cf-f497-4c17-8dbb-f2d07e49e7f8",
            price: 70534.27,
            price_sources: [],
            processed_ms: 1711890584937,
          },
        ],
        position_type: "FLAT",
        position_uuid: "4fbf5037-0a3c-47d6-bda4-27985b2a4c4a",
        return_at_close: 0.9800120900965303,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 71068.86000000002,
        close_ms: 1711905064293,
        current_return: 1.0083710597954094,
        initial_entry_price: 70584.85,
        is_closed_position: true,
        miner_hotkey: "5FhbvTiD4SRNJDmXRH9cqNFYzdd49vWgb6RXDyRXxfoZGSbg",
        net_leverage: 0.0,
        open_ms: 1711894208861,
        orders: [
          {
            leverage: -5.0,
            order_type: "SHORT",
            order_uuid: "b3149e1f-faf6-4abc-952f-d2d2d7117360",
            price: 70584.85,
            price_sources: [],
            processed_ms: 1711894208856,
          },
          {
            leverage: 2.5,
            order_type: "LONG",
            order_uuid: "ae9ad889-b4f0-42fa-b62d-2abe9cda5145",
            price: 70422.68,
            price_sources: [],
            processed_ms: 1711897813439,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "981167a9-702d-4fac-a36a-b32fc417dceb",
            price: 70532.46,
            price_sources: [],
            processed_ms: 1711901428866,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "df72302c-9e58-4745-8d87-270218bdeb4e",
            price: 70477.99,
            price_sources: [],
            processed_ms: 1711905064293,
          },
        ],
        position_type: "FLAT",
        position_uuid: "26375364-4ea1-4652-a1d0-aa74059293cf",
        return_at_close: 0.9982873491974553,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 70469.61,
        close_ms: 1711910530367,
        current_return: 0.9556283907346731,
        initial_entry_price: 70469.61,
        is_closed_position: true,
        miner_hotkey: "5FhbvTiD4SRNJDmXRH9cqNFYzdd49vWgb6RXDyRXxfoZGSbg",
        net_leverage: 0.0,
        open_ms: 1711908679105,
        orders: [
          {
            leverage: -5.0,
            order_type: "SHORT",
            order_uuid: "e65123de-0113-4241-b085-7d7ab6e4ce0e",
            price: 70469.61,
            price_sources: [],
            processed_ms: 1711908679100,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "692ae127-8b91-4c32-a335-fc522a324290",
            price: 71094.98,
            price_sources: [],
            processed_ms: 1711910530367,
          },
        ],
        position_type: "FLAT",
        position_uuid: "049993af-d8bb-4036-93c0-d7d8187a98df",
        return_at_close: 0.9460721068273263,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 71351.86000000007,
        close_ms: 1711930502187,
        current_return: 1.0011234094653623,
        initial_entry_price: 71024.86,
        is_closed_position: true,
        miner_hotkey: "5FhbvTiD4SRNJDmXRH9cqNFYzdd49vWgb6RXDyRXxfoZGSbg",
        net_leverage: 0.0,
        open_ms: 1711915910836,
        orders: [
          {
            leverage: -5.0,
            order_type: "SHORT",
            order_uuid: "c225e63c-c111-488a-98fe-97ec542cea24",
            price: 71024.86,
            price_sources: [],
            processed_ms: 1711915910830,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "c05097c4-274d-4f07-af71-fd53f97b976a",
            price: 70873.93,
            price_sources: [],
            processed_ms: 1711919529394,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "bfc4ab50-ca4a-4d56-8fb1-d0f98182100e",
            price: 70972.12,
            price_sources: [],
            processed_ms: 1711923155145,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "1a6de968-6798-4269-951e-3295d145b83e",
            price: 71119.53,
            price_sources: [],
            processed_ms: 1711926786932,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "722e1e5e-5283-4540-b140-77a537aec2f3",
            price: 71192.28,
            price_sources: [],
            processed_ms: 1711930502187,
          },
        ],
        position_type: "FLAT",
        position_uuid: "74a5d431-7cd2-493b-8f78-66f4e66f6f02",
        return_at_close: 0.9911121753707086,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 73794.57999999996,
        close_ms: 1711948505065,
        current_return: 1.0232778777123965,
        initial_entry_price: 71052.01,
        is_closed_position: true,
        miner_hotkey: "5FhbvTiD4SRNJDmXRH9cqNFYzdd49vWgb6RXDyRXxfoZGSbg",
        net_leverage: 0.0,
        open_ms: 1711934016127,
        orders: [
          {
            leverage: -5.0,
            order_type: "SHORT",
            order_uuid: "f84aed64-f903-41f6-9564-7100de5bac2b",
            price: 71052.01,
            price_sources: [],
            processed_ms: 1711934016122,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "76a5f7f3-399f-46a4-94b1-7bc9bbe85f11",
            price: 70857.11,
            price_sources: [],
            processed_ms: 1711937628332,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "cd600ff8-eb8d-44f6-aec2-dba2c98f22e0",
            price: 70736.17,
            price_sources: [],
            processed_ms: 1711941247965,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "9db2f618-7459-48e6-9f68-63ca954f60b7",
            price: 70648.56,
            price_sources: [],
            processed_ms: 1711944921339,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "9af1a315-d33b-46f5-b632-ec9002610833",
            price: 70486.7,
            price_sources: [],
            processed_ms: 1711948505065,
          },
        ],
        position_type: "FLAT",
        position_uuid: "d9e30485-453b-4a69-a9f4-ae17fd221069",
        return_at_close: 1.0130450989352726,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 67111.34857142864,
        close_ms: 1711984854237,
        current_return: 0.9911811351112535,
        initial_entry_price: 69485.7,
        is_closed_position: true,
        miner_hotkey: "5FhbvTiD4SRNJDmXRH9cqNFYzdd49vWgb6RXDyRXxfoZGSbg",
        net_leverage: 0.0,
        open_ms: 1711966575495,
        orders: [
          {
            leverage: -5.0,
            order_type: "SHORT",
            order_uuid: "ee6ce5e2-061e-4ca9-9e80-582d9b8e6be8",
            price: 69485.7,
            price_sources: [],
            processed_ms: 1711966575485,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "20eb9efb-3fda-4e13-bf88-3e48ee1c2ad7",
            price: 69733.2,
            price_sources: [],
            processed_ms: 1711970304652,
          },
          {
            leverage: 1.75,
            order_type: "LONG",
            order_uuid: "c8a9ffbc-e0ff-4087-9e01-12877f7a9ffd",
            price: 69693.3,
            price_sources: [],
            processed_ms: 1711973816083,
          },
          {
            leverage: 0.875,
            order_type: "LONG",
            order_uuid: "38cd5315-4fde-4588-8d04-bbdfe0e8eda8",
            price: 69710.29,
            price_sources: [],
            processed_ms: 1711977640537,
          },
          {
            leverage: 0.4375,
            order_type: "LONG",
            order_uuid: "09684f1a-2db8-4f2d-9f54-5e16d3a494f9",
            price: 69731.9,
            price_sources: [],
            processed_ms: 1711981125594,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "7743fab0-f686-4558-946a-153f82de6fcd",
            price: 68512.0,
            price_sources: [],
            processed_ms: 1711984854237,
          },
        ],
        position_type: "FLAT",
        position_uuid: "51aa3545-032a-4b4e-b335-8f4374ac48ed",
        return_at_close: 0.981269323760141,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 68560.21764705886,
        close_ms: 1712031045660,
        current_return: 0.7718055339768788,
        initial_entry_price: 68225.8,
        is_closed_position: true,
        miner_hotkey: "5FhbvTiD4SRNJDmXRH9cqNFYzdd49vWgb6RXDyRXxfoZGSbg",
        net_leverage: 8.5,
        open_ms: 1711988297604,
        orders: [
          {
            leverage: 5.0,
            order_type: "LONG",
            order_uuid: "74b2f231-18c3-4e9f-95f8-be5a3248eb83",
            price: 68225.8,
            price_sources: [],
            processed_ms: 1711988297601,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "8d0002d4-5f55-4dff-a4a1-301b45749165",
            price: 68543.2,
            price_sources: [],
            processed_ms: 1711991917926,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "5f224173-f199-4aaa-bdcb-c61ef2b27abc",
            price: 68635.5,
            price_sources: [],
            processed_ms: 1711995658758,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "ef243af1-ced5-4270-9f83-dddfc1dba54c",
            price: 69379.5,
            price_sources: [],
            processed_ms: 1711999348545,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "028c2cae-49e6-42b2-82af-5e65713077b3",
            price: 69448.1,
            price_sources: [],
            processed_ms: 1712002936743,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "c0e1ac51-9006-44b9-a919-0b4caf08a636",
            price: 69743.6,
            price_sources: [],
            processed_ms: 1712006578591,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "285c5049-230a-4d85-8be3-777e5f7d7f0c",
            price: 69725.6,
            price_sources: [],
            processed_ms: 1712010021474,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "c532b62e-6904-4c8b-b81f-4bf49c65fdcf",
            price: 69788.8,
            price_sources: [],
            processed_ms: 1712013632651,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "6236612b-f744-423d-96ac-f135cc54ed85",
            price: 69399.2,
            price_sources: [],
            processed_ms: 1712017269459,
          },
          {
            leverage: -8.5,
            order_type: "SHORT",
            order_uuid: "a76e48f9-88d8-4fea-9a93-ce83877d45a7",
            price: 69454.4,
            price_sources: [],
            processed_ms: 1712020877827,
          },
        ],
        position_type: "FLAT",
        position_uuid: "5140fef7-477b-4147-8163-724d69591c81",
        return_at_close: 0.7455641458216649,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
    ],
    thirty_day_returns: 1.0,
  },
  "5Fhn66JUv5mtLJJyHhBnYY7efYF23k6f1jYEZPqdCLqBrvEw": {
    all_time_returns: 1.0,
    n_positions: 0,
    percentage_profitable: 0.0,
    positions: [
      {
        average_entry_price: 64536.1,
        close_ms: 1711197649626,
        current_return: 1.0015912024432836,
        initial_entry_price: 64536.1,
        is_closed_position: true,
        miner_hotkey: "5Fhn66JUv5mtLJJyHhBnYY7efYF23k6f1jYEZPqdCLqBrvEw",
        net_leverage: 0.0,
        open_ms: 1711194118744,
        orders: [
          {
            leverage: 0.6,
            order_type: "LONG",
            order_uuid: "18021766-a37f-408b-975e-9e0fd76ad81e",
            price: 64536.1,
            price_sources: [],
            processed_ms: 1711194118744,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "837ee8d9-623d-48e7-a94e-92c74733f46e",
            price: 64707.25,
            price_sources: [],
            processed_ms: 1711197649626,
          },
        ],
        position_type: "FLAT",
        position_uuid: "5b069f9a-e9ec-44a8-b75c-10b44ad89906",
        return_at_close: 1.0003892930003517,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 64749.93,
        close_ms: 1711201305812,
        current_return: 0.9976608777800995,
        initial_entry_price: 64749.93,
        is_closed_position: true,
        miner_hotkey: "5Fhn66JUv5mtLJJyHhBnYY7efYF23k6f1jYEZPqdCLqBrvEw",
        net_leverage: 0.0,
        open_ms: 1711197736547,
        orders: [
          {
            leverage: 0.6,
            order_type: "LONG",
            order_uuid: "30a35f87-ae0b-4c14-b1c0-f9a1b7f239e0",
            price: 64749.93,
            price_sources: [],
            processed_ms: 1711197736546,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "9e0957ed-27c8-47a7-b9dc-e9e634e96db3",
            price: 64497.5,
            price_sources: [],
            processed_ms: 1711201305812,
          },
        ],
        position_type: "FLAT",
        position_uuid: "2c27ad7c-7509-44af-b5d9-9231bad6adec",
        return_at_close: 0.9964636847267634,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 64462.96,
        close_ms: 1711204972957,
        current_return: 1.0059767345464745,
        initial_entry_price: 64462.96,
        is_closed_position: true,
        miner_hotkey: "5Fhn66JUv5mtLJJyHhBnYY7efYF23k6f1jYEZPqdCLqBrvEw",
        net_leverage: 0.0,
        open_ms: 1711201392765,
        orders: [
          {
            leverage: 0.6,
            order_type: "LONG",
            order_uuid: "cb282f59-52f0-474a-bff3-9ca6892586ec",
            price: 64462.96,
            price_sources: [],
            processed_ms: 1711201392765,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "2e54b425-07a7-47c4-9dda-fc3a8c927a09",
            price: 65105.09,
            price_sources: [],
            processed_ms: 1711204972957,
          },
        ],
        position_type: "FLAT",
        position_uuid: "d697886c-c37b-430d-92af-178fd2c5133d",
        return_at_close: 1.0047695624650188,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 65105.47,
        close_ms: 1711208657325,
        current_return: 0.9976037036519358,
        initial_entry_price: 65105.47,
        is_closed_position: true,
        miner_hotkey: "5Fhn66JUv5mtLJJyHhBnYY7efYF23k6f1jYEZPqdCLqBrvEw",
        net_leverage: 0.0,
        open_ms: 1711205108800,
        orders: [
          {
            leverage: 0.6,
            order_type: "LONG",
            order_uuid: "b3687c34-b8dc-4157-9dea-d476b9fba5bd",
            price: 65105.47,
            price_sources: [],
            processed_ms: 1711205108799,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "24d0820f-3a1c-43f5-a33c-841bd0eb8df1",
            price: 64845.45,
            price_sources: [],
            processed_ms: 1711208657325,
          },
        ],
        position_type: "FLAT",
        position_uuid: "26194292-6ecc-4497-b6d2-09b464e5db4a",
        return_at_close: 0.9964065792075536,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 64865.18,
        close_ms: 1711212302680,
        current_return: 1.007430026402455,
        initial_entry_price: 64865.18,
        is_closed_position: true,
        miner_hotkey: "5Fhn66JUv5mtLJJyHhBnYY7efYF23k6f1jYEZPqdCLqBrvEw",
        net_leverage: 0.0,
        open_ms: 1711208760628,
        orders: [
          {
            leverage: 0.6,
            order_type: "LONG",
            order_uuid: "01309d5a-c866-43df-8bfc-50a8df8a7338",
            price: 64865.18,
            price_sources: [],
            processed_ms: 1711208760626,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "f09d2d1a-5edc-451f-a013-196147bd94d7",
            price: 65668.43,
            price_sources: [],
            processed_ms: 1711212302680,
          },
        ],
        position_type: "FLAT",
        position_uuid: "1158de31-0fd8-4d04-ab89-8557f82aa893",
        return_at_close: 1.006221110370772,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 65671.68,
        close_ms: 1711215957948,
        current_return: 0.9997789914922233,
        initial_entry_price: 65671.68,
        is_closed_position: true,
        miner_hotkey: "5Fhn66JUv5mtLJJyHhBnYY7efYF23k6f1jYEZPqdCLqBrvEw",
        net_leverage: 0.0,
        open_ms: 1711212390043,
        orders: [
          {
            leverage: 0.6,
            order_type: "LONG",
            order_uuid: "a7259b4e-858d-4268-8488-6b4dea1f1df2",
            price: 65671.68,
            price_sources: [],
            processed_ms: 1711212390042,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "9bfc27dc-de74-4943-aa23-5eb29c47c033",
            price: 65647.49,
            price_sources: [],
            processed_ms: 1711215957948,
          },
        ],
        position_type: "FLAT",
        position_uuid: "730be3bf-d0ec-4277-b2db-6acc65bdabe7",
        return_at_close: 0.9985792567024326,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 65682.69,
        close_ms: 1711219629198,
        current_return: 0.9969738145621015,
        initial_entry_price: 65682.69,
        is_closed_position: true,
        miner_hotkey: "5Fhn66JUv5mtLJJyHhBnYY7efYF23k6f1jYEZPqdCLqBrvEw",
        net_leverage: 0.0,
        open_ms: 1711216045016,
        orders: [
          {
            leverage: 0.6,
            order_type: "LONG",
            order_uuid: "5f725a86-c617-4659-afec-3ecb460f4f52",
            price: 65682.69,
            price_sources: [],
            processed_ms: 1711216045015,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "f6ac1f7e-36f1-4709-b84d-c2c44e9c4f51",
            price: 65351.41,
            price_sources: [],
            processed_ms: 1711219629198,
          },
        ],
        position_type: "FLAT",
        position_uuid: "a847e249-35f2-40a5-8f29-84eda744eae5",
        return_at_close: 0.995777445984627,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 65348.05,
        close_ms: 1711223284311,
        current_return: 0.9956728930702599,
        initial_entry_price: 65348.05,
        is_closed_position: true,
        miner_hotkey: "5Fhn66JUv5mtLJJyHhBnYY7efYF23k6f1jYEZPqdCLqBrvEw",
        net_leverage: 0.0,
        open_ms: 1711219715703,
        orders: [
          {
            leverage: 0.6,
            order_type: "LONG",
            order_uuid: "785f88b1-53de-4193-88b2-1f569d3cc33f",
            price: 65348.05,
            price_sources: [],
            processed_ms: 1711219715702,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "04d0f2c2-a190-44da-befd-1cbe1b5a4dda",
            price: 64876.77,
            price_sources: [],
            processed_ms: 1711223284311,
          },
        ],
        position_type: "FLAT",
        position_uuid: "e10526d9-d3fa-4ea0-8309-0cec583668c8",
        return_at_close: 0.9944780855985755,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 64901.27,
        close_ms: 1711226950021,
        current_return: 1.0006321602027202,
        initial_entry_price: 64901.27,
        is_closed_position: true,
        miner_hotkey: "5Fhn66JUv5mtLJJyHhBnYY7efYF23k6f1jYEZPqdCLqBrvEw",
        net_leverage: 0.0,
        open_ms: 1711223370913,
        orders: [
          {
            leverage: 0.6,
            order_type: "LONG",
            order_uuid: "5a0e2396-48d8-4980-9c15-99f701b391eb",
            price: 64901.27,
            price_sources: [],
            processed_ms: 1711223370912,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "9f09b3d0-9160-4c18-9a66-7b6662dc6793",
            price: 64969.65,
            price_sources: [],
            processed_ms: 1711226950021,
          },
        ],
        position_type: "FLAT",
        position_uuid: "9d1672f6-98c0-46f1-b105-1d747aa97dd2",
        return_at_close: 0.9994314016104769,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 64946.1,
        close_ms: 1711230616219,
        current_return: 1.000154743702855,
        initial_entry_price: 64946.1,
        is_closed_position: true,
        miner_hotkey: "5Fhn66JUv5mtLJJyHhBnYY7efYF23k6f1jYEZPqdCLqBrvEw",
        net_leverage: 0.0,
        open_ms: 1711227036651,
        orders: [
          {
            leverage: 0.6,
            order_type: "LONG",
            order_uuid: "7875d43e-c8c6-437d-bc21-651c7737db90",
            price: 64946.1,
            price_sources: [],
            processed_ms: 1711227036650,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "0e37cf46-f385-4760-a09b-3fa95b3b29df",
            price: 64962.85,
            price_sources: [],
            processed_ms: 1711230616219,
          },
        ],
        position_type: "FLAT",
        position_uuid: "b4f5275c-b0d7-4334-b8ce-add5a8ba8d1e",
        return_at_close: 0.9989545580104117,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 64951.759999999995,
        close_ms: 1711234271002,
        current_return: 0.9988425255913004,
        initial_entry_price: 64951.76,
        is_closed_position: true,
        miner_hotkey: "5Fhn66JUv5mtLJJyHhBnYY7efYF23k6f1jYEZPqdCLqBrvEw",
        net_leverage: 0.0,
        open_ms: 1711230703841,
        orders: [
          {
            leverage: 0.6,
            order_type: "LONG",
            order_uuid: "b4fde8fe-2735-43ec-a115-f5e74062be3e",
            price: 64951.76,
            price_sources: [],
            processed_ms: 1711230703840,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "fe06feb5-f79c-4eda-b1fa-988cb5ea361d",
            price: 64826.46,
            price_sources: [],
            processed_ms: 1711234271002,
          },
        ],
        position_type: "FLAT",
        position_uuid: "dba8d519-e54a-436c-8f85-d8a4a40e430e",
        return_at_close: 0.9976439145605909,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 64855.46,
        close_ms: 1711237939965,
        current_return: 0.9961077756599058,
        initial_entry_price: 64855.46,
        is_closed_position: true,
        miner_hotkey: "5Fhn66JUv5mtLJJyHhBnYY7efYF23k6f1jYEZPqdCLqBrvEw",
        net_leverage: 0.0,
        open_ms: 1711234357812,
        orders: [
          {
            leverage: 0.6,
            order_type: "LONG",
            order_uuid: "25f264af-bbf6-4c99-845e-63dc0d1f5506",
            price: 64855.46,
            price_sources: [],
            processed_ms: 1711234357810,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "7873bd89-2b94-4452-90a5-d7a0811cda7d",
            price: 64434.74,
            price_sources: [],
            processed_ms: 1711237939965,
          },
        ],
        position_type: "FLAT",
        position_uuid: "432ce52d-693c-4097-aab1-a51dc60305ad",
        return_at_close: 0.9949124463291139,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 64312.52,
        close_ms: 1711241600745,
        current_return: 1.0025681158194393,
        initial_entry_price: 64312.52,
        is_closed_position: true,
        miner_hotkey: "5Fhn66JUv5mtLJJyHhBnYY7efYF23k6f1jYEZPqdCLqBrvEw",
        net_leverage: 0.0,
        open_ms: 1711238028384,
        orders: [
          {
            leverage: 0.6,
            order_type: "LONG",
            order_uuid: "3903f0b5-c58d-452b-aabc-def8afc912ea",
            price: 64312.52,
            price_sources: [],
            processed_ms: 1711238028383,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "5ad5d433-06b7-4242-9a8c-e555fc46b95a",
            price: 64587.79,
            price_sources: [],
            processed_ms: 1711241600745,
          },
        ],
        position_type: "FLAT",
        position_uuid: "440d6465-20c9-4820-adf4-274b7dea34d2",
        return_at_close: 1.001365034080456,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 64569.94,
        close_ms: 1711245281751,
        current_return: 0.9997505960203773,
        initial_entry_price: 64569.94,
        is_closed_position: true,
        miner_hotkey: "5Fhn66JUv5mtLJJyHhBnYY7efYF23k6f1jYEZPqdCLqBrvEw",
        net_leverage: 0.0,
        open_ms: 1711241705455,
        orders: [
          {
            leverage: 0.6,
            order_type: "LONG",
            order_uuid: "5872fa4e-e086-4ab3-9482-173a3836df6e",
            price: 64569.94,
            price_sources: [],
            processed_ms: 1711241705453,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "f22ad04c-60e6-4720-8831-b247c5239344",
            price: 64543.1,
            price_sources: [],
            processed_ms: 1711245281751,
          },
        ],
        position_type: "FLAT",
        position_uuid: "a76d221a-d5de-4e73-8f99-11dd34c0edef",
        return_at_close: 0.9985508953051528,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 64516.34,
        close_ms: 1711248936817,
        current_return: 0.9973370467078573,
        initial_entry_price: 64516.34,
        is_closed_position: true,
        miner_hotkey: "5Fhn66JUv5mtLJJyHhBnYY7efYF23k6f1jYEZPqdCLqBrvEw",
        net_leverage: 0.0,
        open_ms: 1711245369463,
        orders: [
          {
            leverage: 0.6,
            order_type: "LONG",
            order_uuid: "9cb0f5f7-ccd5-40f4-93cc-ab6c4e23bf9c",
            price: 64516.34,
            price_sources: [],
            processed_ms: 1711245369453,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "09a91ace-c626-44a6-a528-0e24229bdde0",
            price: 64230.0,
            price_sources: [],
            processed_ms: 1711248936817,
          },
        ],
        position_type: "FLAT",
        position_uuid: "884199d3-1e63-4b31-a4be-15777e900a19",
        return_at_close: 0.9961402422518079,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 64222.560000000005,
        close_ms: 1711252593894,
        current_return: 0.9988502171199652,
        initial_entry_price: 64222.56,
        is_closed_position: true,
        miner_hotkey: "5Fhn66JUv5mtLJJyHhBnYY7efYF23k6f1jYEZPqdCLqBrvEw",
        net_leverage: 0.0,
        open_ms: 1711249080759,
        orders: [
          {
            leverage: 0.6,
            order_type: "LONG",
            order_uuid: "1d941689-3ab5-4491-84fa-d7a517c659f7",
            price: 64222.56,
            price_sources: [],
            processed_ms: 1711249080758,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "1e8e569a-bf32-425a-b7fb-6a5621cc7e1b",
            price: 64099.49,
            price_sources: [],
            processed_ms: 1711252593894,
          },
        ],
        position_type: "FLAT",
        position_uuid: "b73663c6-5851-47f5-9971-b5ede468acdb",
        return_at_close: 0.9976515968594213,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 64024.04,
        close_ms: 1711258847677,
        current_return: 1.002745468733307,
        initial_entry_price: 64024.04,
        is_closed_position: true,
        miner_hotkey: "5Fhn66JUv5mtLJJyHhBnYY7efYF23k6f1jYEZPqdCLqBrvEw",
        net_leverage: 0.0,
        open_ms: 1711252767894,
        orders: [
          {
            leverage: 0.6,
            order_type: "LONG",
            order_uuid: "39dc309e-08cd-43de-b42f-4ab0191c9d07",
            price: 64024.04,
            price_sources: [],
            processed_ms: 1711252767893,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "55313339-1afb-46c0-94ce-1939d3eb28ab",
            price: 64317.0,
            price_sources: [],
            processed_ms: 1711258847677,
          },
        ],
        position_type: "FLAT",
        position_uuid: "71104427-b50b-4c4a-a01d-5cad14f8cb78",
        return_at_close: 1.001542174170827,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 64329.85,
        close_ms: 1711262507829,
        current_return: 0.9977832996657072,
        initial_entry_price: 64329.85,
        is_closed_position: true,
        miner_hotkey: "5Fhn66JUv5mtLJJyHhBnYY7efYF23k6f1jYEZPqdCLqBrvEw",
        net_leverage: 0.0,
        open_ms: 1711258950566,
        orders: [
          {
            leverage: 1.25,
            order_type: "LONG",
            order_uuid: "b5db9a89-0a61-4587-93fc-9df1d37e49cc",
            price: 64329.85,
            price_sources: [],
            processed_ms: 1711258950564,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "eca5525b-b97f-4455-b409-d7796905e6c1",
            price: 64215.77,
            price_sources: [],
            processed_ms: 1711262507829,
          },
        ],
        position_type: "FLAT",
        position_uuid: "a1cf9c55-00ad-4a79-82d8-29d77bea80e8",
        return_at_close: 0.9952888414165431,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 64192.0,
        close_ms: 1711266169012,
        current_return: 1.000816106368395,
        initial_entry_price: 64192.0,
        is_closed_position: true,
        miner_hotkey: "5Fhn66JUv5mtLJJyHhBnYY7efYF23k6f1jYEZPqdCLqBrvEw",
        net_leverage: 0.0,
        open_ms: 1711262594521,
        orders: [
          {
            leverage: 1.25,
            order_type: "LONG",
            order_uuid: "dfea7f47-a751-414b-b0fa-a1a3143f097b",
            price: 64192.0,
            price_sources: [],
            processed_ms: 1711262594519,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "ab0eba3b-38bd-41ba-b0fc-0286e27d5f44",
            price: 64233.91,
            price_sources: [],
            processed_ms: 1711266169012,
          },
        ],
        position_type: "FLAT",
        position_uuid: "3da0f8d6-b657-4249-9f43-b78080d10499",
        return_at_close: 0.998314066102474,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 64350.41000000001,
        close_ms: 1711269832029,
        current_return: 1.008335416977141,
        initial_entry_price: 64350.41,
        is_closed_position: true,
        miner_hotkey: "5Fhn66JUv5mtLJJyHhBnYY7efYF23k6f1jYEZPqdCLqBrvEw",
        net_leverage: 0.0,
        open_ms: 1711266255476,
        orders: [
          {
            leverage: 1.25,
            order_type: "LONG",
            order_uuid: "416a836c-3b34-41fe-aae1-25e5a61b8767",
            price: 64350.41,
            price_sources: [],
            processed_ms: 1711266255474,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "1d4a3d28-6def-4761-bf57-364aa3a4193f",
            price: 64779.52,
            price_sources: [],
            processed_ms: 1711269832029,
          },
        ],
        position_type: "FLAT",
        position_uuid: "321d04a4-2849-488e-8409-a99ed01999ba",
        return_at_close: 1.0058145784346981,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 64953.42,
        close_ms: 1711273555231,
        current_return: 0.9995225424619674,
        initial_entry_price: 64953.42,
        is_closed_position: true,
        miner_hotkey: "5Fhn66JUv5mtLJJyHhBnYY7efYF23k6f1jYEZPqdCLqBrvEw",
        net_leverage: 0.0,
        open_ms: 1711269969495,
        orders: [
          {
            leverage: 1.25,
            order_type: "LONG",
            order_uuid: "ac8b2e9b-0b3a-4f3d-aad9-6e1b5f9622b1",
            price: 64953.42,
            price_sources: [],
            processed_ms: 1711269969494,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "37eed93e-5dc5-45d9-9992-447d2e23a62a",
            price: 64928.61,
            price_sources: [],
            processed_ms: 1711273555231,
          },
        ],
        position_type: "FLAT",
        position_uuid: "ef3bd23b-2d20-4eb2-99ba-aa8febc60028",
        return_at_close: 0.9970237361058125,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 64928.61,
        close_ms: 1711277154875,
        current_return: 1.004567516538549,
        initial_entry_price: 64928.61,
        is_closed_position: true,
        miner_hotkey: "5Fhn66JUv5mtLJJyHhBnYY7efYF23k6f1jYEZPqdCLqBrvEw",
        net_leverage: 0.0,
        open_ms: 1711273575129,
        orders: [
          {
            leverage: 1.25,
            order_type: "LONG",
            order_uuid: "9d3a330c-d307-4fad-ba03-768073b99d1c",
            price: 64928.61,
            price_sources: [],
            processed_ms: 1711273575128,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "cca73734-bef7-4c25-b082-29d7fc42a9b5",
            price: 65165.86,
            price_sources: [],
            processed_ms: 1711277154875,
          },
        ],
        position_type: "FLAT",
        position_uuid: "14e0ea1b-dfad-4190-8d5f-5b29944d6a8a",
        return_at_close: 1.0020560977472026,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 65167.65,
        close_ms: 1711313511750,
        current_return: 1.0213236552185017,
        initial_entry_price: 65167.65,
        is_closed_position: true,
        miner_hotkey: "5Fhn66JUv5mtLJJyHhBnYY7efYF23k6f1jYEZPqdCLqBrvEw",
        net_leverage: 0.0,
        open_ms: 1711277241718,
        orders: [
          {
            leverage: 1.25,
            order_type: "LONG",
            order_uuid: "fcedd7c2-88ec-47ba-881e-838880533291",
            price: 65167.65,
            price_sources: [],
            processed_ms: 1711277241717,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "c3f6e34b-f82d-4b18-b4ad-cb09fdaf5ec8",
            price: 66279.34,
            price_sources: [],
            processed_ms: 1711313511750,
          },
        ],
        position_type: "FLAT",
        position_uuid: "e2d3dfd1-97db-44a8-ae70-769d11c2ddce",
        return_at_close: 1.0187703460804554,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 66346.99,
        close_ms: 1711319099999,
        current_return: 1.007466050833655,
        initial_entry_price: 66346.99,
        is_closed_position: true,
        miner_hotkey: "5Fhn66JUv5mtLJJyHhBnYY7efYF23k6f1jYEZPqdCLqBrvEw",
        net_leverage: 0.0,
        open_ms: 1711317231835,
        orders: [
          {
            leverage: 1.25,
            order_type: "LONG",
            order_uuid: "3e06324d-7175-4536-b156-001fcc025388",
            price: 66346.99,
            price_sources: [],
            processed_ms: 1711317231834,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "d36136d9-0fda-4055-8f8b-7a8c157d3bf2",
            price: 66743.27,
            price_sources: [],
            processed_ms: 1711319099999,
          },
        ],
        position_type: "FLAT",
        position_uuid: "f3b8caa7-8094-4afd-8663-4c84c94e3da6",
        return_at_close: 1.004947385706571,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 924795.4300000223,
        close_ms: 1711338421551,
        current_return: 1.015126422348052,
        initial_entry_price: 67335.4,
        is_closed_position: true,
        miner_hotkey: "5Fhn66JUv5mtLJJyHhBnYY7efYF23k6f1jYEZPqdCLqBrvEw",
        net_leverage: 0.0,
        open_ms: 1711322830979,
        orders: [
          {
            leverage: -1.25,
            order_type: "SHORT",
            order_uuid: "301aa18d-b0d6-4477-8783-106ec785af38",
            price: 67335.4,
            price_sources: [],
            processed_ms: 1711322830977,
          },
          {
            leverage: 0.125,
            order_type: "LONG",
            order_uuid: "f68451d6-2aab-4c5b-ad4b-5d62ac5bda7b",
            price: 67195.94,
            price_sources: [],
            processed_ms: 1711323769120,
          },
          {
            leverage: -1.25,
            order_type: "SHORT",
            order_uuid: "740a2e15-9e4e-4f84-ba59-35091f488ef1",
            price: 67225.58,
            price_sources: [],
            processed_ms: 1711324687173,
          },
          {
            leverage: 1.9,
            order_type: "LONG",
            order_uuid: "48af861b-5ad1-429e-ba6f-5df68a0916c7",
            price: 66884.18,
            price_sources: [],
            processed_ms: 1711325617710,
          },
          {
            leverage: 0.23750000000000004,
            order_type: "LONG",
            order_uuid: "ae6897ef-8ae7-42e1-a688-d52d1c3d393a",
            price: 66878.36,
            price_sources: [],
            processed_ms: 1711326552037,
          },
          {
            leverage: 0.19000000000000003,
            order_type: "LONG",
            order_uuid: "16cc3d55-40b5-4230-bbcf-c387d53b2bd9",
            price: 66604.42,
            price_sources: [],
            processed_ms: 1711327482680,
          },
          {
            leverage: 0.03800000000000001,
            order_type: "LONG",
            order_uuid: "4eb451ee-9178-4ed9-b607-a633232f243d",
            price: 66620.61,
            price_sources: [],
            processed_ms: 1711331083206,
          },
          {
            leverage: 0.004750000000000001,
            order_type: "LONG",
            order_uuid: "e627b191-262f-4032-b93d-336c42ea1fd5",
            price: 66487.02,
            price_sources: [],
            processed_ms: 1711332969573,
          },
          {
            leverage: 0.0023750000000000004,
            order_type: "LONG",
            order_uuid: "31eb255f-73bb-4a85-8b90-71751eef4807",
            price: 66709.66,
            price_sources: [],
            processed_ms: 1711334750539,
          },
          {
            leverage: 0.0011875000000000002,
            order_type: "LONG",
            order_uuid: "3f6bbffa-f035-488a-a762-e0bf1c0b36fc",
            price: 66790.45,
            price_sources: [],
            processed_ms: 1711336581947,
          },
          {
            leverage: 0.125,
            order_type: "LONG",
            order_uuid: "c01debb2-63c5-411b-8ecd-ffc853c305e9",
            price: 67074.42,
            price_sources: [],
            processed_ms: 1711338421551,
          },
        ],
        position_type: "FLAT",
        position_uuid: "f668123f-8d39-4121-82da-cb069e9cf54b",
        return_at_close: 1.0103045718418988,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: -56123.098571423325,
        close_ms: 1711354908038,
        current_return: 0.9974338084848378,
        initial_entry_price: 67168.05,
        is_closed_position: true,
        miner_hotkey: "5Fhn66JUv5mtLJJyHhBnYY7efYF23k6f1jYEZPqdCLqBrvEw",
        net_leverage: 0.0,
        open_ms: 1711340254764,
        orders: [
          {
            leverage: -1.25,
            order_type: "SHORT",
            order_uuid: "017c72f4-b1af-4c51-ab41-d7138d52e325",
            price: 67168.05,
            price_sources: [],
            processed_ms: 1711340254762,
          },
          {
            leverage: 0.125,
            order_type: "LONG",
            order_uuid: "83e5b670-db0a-40d1-be7a-eb510c77686b",
            price: 67346.18,
            price_sources: [],
            processed_ms: 1711342078914,
          },
          {
            leverage: 0.125,
            order_type: "LONG",
            order_uuid: "ccbd2c88-cce4-44b2-b85b-6edc57f29d8b",
            price: 67555.13,
            price_sources: [],
            processed_ms: 1711343921178,
          },
          {
            leverage: 0.125,
            order_type: "LONG",
            order_uuid: "d486de34-4092-4ef8-b6c4-48960ac18909",
            price: 67415.64,
            price_sources: [],
            processed_ms: 1711345745545,
          },
          {
            leverage: 0.7,
            order_type: "LONG",
            order_uuid: "6a2384a2-c1b2-4cd0-816e-edde9bae184f",
            price: 67274.95,
            price_sources: [],
            processed_ms: 1711347582095,
          },
          {
            leverage: 0.14000000000000004,
            order_type: "LONG",
            order_uuid: "e8c1a681-82bb-4eac-a89a-712a468ffcdd",
            price: 67169.7,
            price_sources: [],
            processed_ms: 1711349411573,
          },
          {
            leverage: 0.028000000000000004,
            order_type: "LONG",
            order_uuid: "f0c1a375-c88b-4bc5-8cdc-120141589812",
            price: 67085.96,
            price_sources: [],
            processed_ms: 1711351246986,
          },
          {
            leverage: 0.005599999999999999,
            order_type: "LONG",
            order_uuid: "44d9e139-65c7-4fef-8abb-c62a44fb0dc2",
            price: 66854.68,
            price_sources: [],
            processed_ms: 1711353076015,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "d7dc0fe6-bbeb-44c1-a32c-fac367c0a6a1",
            price: 66995.53,
            price_sources: [],
            processed_ms: 1711354908038,
          },
        ],
        position_type: "FLAT",
        position_uuid: "8715299a-f4e6-4c33-908c-6a6263b99059",
        return_at_close: 0.9949402239636257,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 66922.47,
        close_ms: 1711369457510,
        current_return: 1.0042997516379775,
        initial_entry_price: 66922.47,
        is_closed_position: true,
        miner_hotkey: "5Fhn66JUv5mtLJJyHhBnYY7efYF23k6f1jYEZPqdCLqBrvEw",
        net_leverage: 0.0,
        open_ms: 1711356736788,
        orders: [
          {
            leverage: 1.25,
            order_type: "LONG",
            order_uuid: "5b1ab073-ae02-4133-9d25-0bf77c54c46e",
            price: 66922.47,
            price_sources: [],
            processed_ms: 1711356736787,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "4c658708-7fd6-437f-a8cc-e074a2019f66",
            price: 67152.67,
            price_sources: [],
            processed_ms: 1711369457510,
          },
        ],
        position_type: "FLAT",
        position_uuid: "ee64cbf0-fa69-42a6-85a8-7f397a39aa76",
        return_at_close: 1.0017890022588827,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 67080.73375000001,
        close_ms: 1711376109938,
        current_return: 1.0526638656577187,
        initial_entry_price: 67075.83,
        is_closed_position: true,
        miner_hotkey: "5Fhn66JUv5mtLJJyHhBnYY7efYF23k6f1jYEZPqdCLqBrvEw",
        net_leverage: 0.0,
        open_ms: 1711371250280,
        orders: [
          {
            leverage: 1.25,
            order_type: "LONG",
            order_uuid: "d8c2102b-80cc-4ac7-9dd3-cea0d18c13aa",
            price: 67075.83,
            price_sources: [],
            processed_ms: 1711371250278,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "fafcbc2c-b373-4337-a41b-96374e624580",
            price: 66898.36,
            price_sources: [],
            processed_ms: 1711373079806,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "47062282-5753-4ebd-9e59-e7a7108738fd",
            price: 67185.56,
            price_sources: [],
            processed_ms: 1711373693995,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "9b309e5f-6495-44fc-91c7-4691363feace",
            price: 67182.8,
            price_sources: [],
            processed_ms: 1711374559166,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "a399b6a2-257f-4bca-95f5-347ee2c3b78d",
            price: 68846.97,
            price_sources: [],
            processed_ms: 1711376109938,
          },
        ],
        position_type: "FLAT",
        position_uuid: "fe2a496b-b9c3-45c0-a31b-71b9e64aaaee",
        return_at_close: 1.0484532101950879,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: -19514.60999999897,
        close_ms: 1711388749987,
        current_return: 0.995929889525463,
        initial_entry_price: 69095.56,
        is_closed_position: true,
        miner_hotkey: "5Fhn66JUv5mtLJJyHhBnYY7efYF23k6f1jYEZPqdCLqBrvEw",
        net_leverage: 0.0,
        open_ms: 1711377921635,
        orders: [
          {
            leverage: -1.25,
            order_type: "SHORT",
            order_uuid: "f94c0037-e79b-4f98-aabc-3d2d09a164f5",
            price: 69095.56,
            price_sources: [],
            processed_ms: 1711377921633,
          },
          {
            leverage: 0.625,
            order_type: "LONG",
            order_uuid: "0a7ab56a-137f-4fea-ac42-b8a1f4eebd80",
            price: 69061.84,
            price_sources: [],
            processed_ms: 1711378211024,
          },
          {
            leverage: 0.3125,
            order_type: "LONG",
            order_uuid: "3e377e67-0e3f-4fb5-b181-184ddf08eb98",
            price: 69304.52,
            price_sources: [],
            processed_ms: 1711379675743,
          },
          {
            leverage: 0.15625,
            order_type: "LONG",
            order_uuid: "8b0503b2-a79e-43f2-8982-1bc02f3813fc",
            price: 69808.57,
            price_sources: [],
            processed_ms: 1711381487013,
          },
          {
            leverage: 0.125,
            order_type: "LONG",
            order_uuid: "816a9db3-00d9-4b5b-9b59-453453dd3765",
            price: 69833.53,
            price_sources: [],
            processed_ms: 1711383302007,
          },
          {
            leverage: 0.025,
            order_type: "LONG",
            order_uuid: "a9f276eb-5615-4a73-a6bf-050d7f64c2d6",
            price: 70071.83,
            price_sources: [],
            processed_ms: 1711385122085,
          },
          {
            leverage: 0.0031249999999999993,
            order_type: "LONG",
            order_uuid: "af0dd3eb-473d-4c2c-92e3-9aa906700716",
            price: 70574.27,
            price_sources: [],
            processed_ms: 1711386936095,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "4ae915c1-f438-4bf9-a6c7-fe1457f2727b",
            price: 70477.89,
            price_sources: [],
            processed_ms: 1711388749987,
          },
        ],
        position_type: "FLAT",
        position_uuid: "bba23e18-8fc1-4ce7-92e7-b26f17bf00bf",
        return_at_close: 0.9934400648016494,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: -99918.50684210773,
        close_ms: 1711423258055,
        current_return: 1.008957593716277,
        initial_entry_price: 70593.26,
        is_closed_position: true,
        miner_hotkey: "5Fhn66JUv5mtLJJyHhBnYY7efYF23k6f1jYEZPqdCLqBrvEw",
        net_leverage: 0.0,
        open_ms: 1711390571066,
        orders: [
          {
            leverage: 1.25,
            order_type: "LONG",
            order_uuid: "b5f2209a-b81d-490b-8034-09d2a04a27a4",
            price: 70593.26,
            price_sources: [],
            processed_ms: 1711390571064,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "95ffa276-ea66-431d-893d-0b3f635d5773",
            price: 70724.71,
            price_sources: [],
            processed_ms: 1711392384887,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "df1434ce-7226-4d26-a49c-5327f79fc91b",
            price: 70633.63,
            price_sources: [],
            processed_ms: 1711394202282,
          },
          {
            leverage: -0.875,
            order_type: "SHORT",
            order_uuid: "710819ad-13b4-4d93-8edf-776197332649",
            price: 70832.71,
            price_sources: [],
            processed_ms: 1711396010166,
          },
          {
            leverage: -0.4375,
            order_type: "SHORT",
            order_uuid: "25427367-a687-45f7-90aa-5a648e820353",
            price: 70881.69,
            price_sources: [],
            processed_ms: 1711397833273,
          },
          {
            leverage: -0.21875,
            order_type: "SHORT",
            order_uuid: "b0e48076-880b-4ed9-af3d-9e755ac799bc",
            price: 70949.97,
            price_sources: [],
            processed_ms: 1711399650917,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "d868c595-eef2-422f-8ff1-f959441a5a4e",
            price: 70123.98,
            price_sources: [],
            processed_ms: 1711401469190,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "ce0c4f45-68cd-46ad-85bf-dfc563b98ca1",
            price: 70008.49,
            price_sources: [],
            processed_ms: 1711403281308,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "8f641c7f-dafd-4390-bdbc-e867a315bcb5",
            price: 70399.7,
            price_sources: [],
            processed_ms: 1711405098190,
          },
          {
            leverage: -0.484375,
            order_type: "SHORT",
            order_uuid: "07c6aad5-f4da-4558-8b87-28aafd2087f8",
            price: 70451.11,
            price_sources: [],
            processed_ms: 1711406919112,
          },
          {
            leverage: -0.2421875,
            order_type: "SHORT",
            order_uuid: "4f56fbf6-3016-48cd-9311-73262e358988",
            price: 70381.16,
            price_sources: [],
            processed_ms: 1711408728082,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "52ff34c0-598e-4af9-8af1-a9566fcf98c0",
            price: 69764.79,
            price_sources: [],
            processed_ms: 1711410550343,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "b686e7db-b474-4846-8674-a658cea8327a",
            price: 69763.87,
            price_sources: [],
            processed_ms: 1711412365899,
          },
          {
            leverage: -0.37109375,
            order_type: "SHORT",
            order_uuid: "98de2539-01fb-4b4d-a40c-a18a20ead80f",
            price: 70041.74,
            price_sources: [],
            processed_ms: 1711414186438,
          },
          {
            leverage: -0.185546875,
            order_type: "SHORT",
            order_uuid: "9c7621f5-2ec5-44f4-91f5-c8a523149248",
            price: 70229.14,
            price_sources: [],
            processed_ms: 1711415997182,
          },
          {
            leverage: -0.1484375,
            order_type: "SHORT",
            order_uuid: "bdb00361-871f-480d-b5aa-0b7686406534",
            price: 70193.4,
            price_sources: [],
            processed_ms: 1711417812243,
          },
          {
            leverage: -0.0185546875,
            order_type: "SHORT",
            order_uuid: "266d0293-1c34-4f20-9d8d-199a45d821b2",
            price: 70187.21,
            price_sources: [],
            processed_ms: 1711419633919,
          },
          {
            leverage: -0.01484375,
            order_type: "SHORT",
            order_uuid: "4056d69a-51c9-458c-bc73-35cd76b500eb",
            price: 70252.78,
            price_sources: [],
            processed_ms: 1711421446033,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "b9cd9b45-7257-4610-abb2-a8ccd81e4eb2",
            price: 70482.03,
            price_sources: [],
            processed_ms: 1711423258055,
          },
        ],
        position_type: "FLAT",
        position_uuid: "ae954008-238f-4a60-8bfc-a564c0cf9cf6",
        return_at_close: 1.00542624213827,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 69386.71000000014,
        close_ms: 1711437834194,
        current_return: 0.9984246562754174,
        initial_entry_price: 70461.21,
        is_closed_position: true,
        miner_hotkey: "5Fhn66JUv5mtLJJyHhBnYY7efYF23k6f1jYEZPqdCLqBrvEw",
        net_leverage: 0.0,
        open_ms: 1711425073780,
        orders: [
          {
            leverage: -1.25,
            order_type: "SHORT",
            order_uuid: "b01ccd62-631e-4c8e-bfc9-67d6b12eb5ba",
            price: 70461.21,
            price_sources: [],
            processed_ms: 1711425073778,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "3308291f-b115-4ac6-8796-8f67ad354e92",
            price: 70537.82,
            price_sources: [],
            processed_ms: 1711426907419,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "7d0573dd-4ff2-4ce5-8f96-306ce9f8aa38",
            price: 70647.01,
            price_sources: [],
            processed_ms: 1711428765836,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "0e5e38b5-f69f-4b56-a7ad-d3d6f8b674da",
            price: 70534.62,
            price_sources: [],
            processed_ms: 1711430581161,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "aa25e34f-a02a-44ae-a98d-7ea14820e35c",
            price: 70424.67,
            price_sources: [],
            processed_ms: 1711432348280,
          },
          {
            leverage: 0.125,
            order_type: "LONG",
            order_uuid: "805f96aa-982e-4294-a03b-e9414101865c",
            price: 70387.52,
            price_sources: [],
            processed_ms: 1711434163102,
          },
          {
            leverage: 0.0625,
            order_type: "LONG",
            order_uuid: "655c559e-9660-4edf-82dd-869e3f395714",
            price: 70485.97,
            price_sources: [],
            processed_ms: 1711435983688,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "4413b1a8-0f0b-4a67-bb0a-dedc4c7bed72",
            price: 71162.72,
            price_sources: [],
            processed_ms: 1711437834194,
          },
        ],
        position_type: "FLAT",
        position_uuid: "3b9dfa06-c3d3-4f65-9e01-bebc65576d02",
        return_at_close: 0.9959285946347289,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 159464.07000000216,
        close_ms: 1711450509252,
        current_return: 1.0031236733253337,
        initial_entry_price: 70835.0,
        is_closed_position: true,
        miner_hotkey: "5Fhn66JUv5mtLJJyHhBnYY7efYF23k6f1jYEZPqdCLqBrvEw",
        net_leverage: 0.0,
        open_ms: 1711439666874,
        orders: [
          {
            leverage: -1.25,
            order_type: "SHORT",
            order_uuid: "339c256e-7f46-451d-8233-f04b8bc27014",
            price: 70835.0,
            price_sources: [],
            processed_ms: 1711439666869,
          },
          {
            leverage: 1.0,
            order_type: "LONG",
            order_uuid: "62cdf6c9-7e03-40f1-98b5-00f2246af056",
            price: 70656.65,
            price_sources: [],
            processed_ms: 1711441499036,
          },
          {
            leverage: 0.2,
            order_type: "LONG",
            order_uuid: "58119bf0-adf5-4321-b94d-05d1a05b86cb",
            price: 70583.6,
            price_sources: [],
            processed_ms: 1711443246018,
          },
          {
            leverage: 0.039999999999999994,
            order_type: "LONG",
            order_uuid: "7e4f2d10-e89d-4728-bf11-2ac98bc5f5db",
            price: 70958.1,
            price_sources: [],
            processed_ms: 1711445058984,
          },
          {
            leverage: 0.0049999999999999975,
            order_type: "LONG",
            order_uuid: "60b10874-32d9-417b-8ad3-cb96399e7c4b",
            price: 71191.53,
            price_sources: [],
            processed_ms: 1711446875431,
          },
          {
            leverage: 0.0024999999999999988,
            order_type: "LONG",
            order_uuid: "d47d70aa-c998-427e-97f9-05c6e2ee4fbc",
            price: 70975.27,
            price_sources: [],
            processed_ms: 1711448714737,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "10f9cb2f-1548-411f-8231-8f3990dbd6d5",
            price: 70957.91,
            price_sources: [],
            processed_ms: 1711450509252,
          },
        ],
        position_type: "FLAT",
        position_uuid: "7b56df1d-5e84-4295-b382-052a12648f16",
        return_at_close: 1.0006158641420204,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 70731.13363636365,
        close_ms: 1711463316978,
        current_return: 0.966614797689819,
        initial_entry_price: 70851.6,
        is_closed_position: true,
        miner_hotkey: "5Fhn66JUv5mtLJJyHhBnYY7efYF23k6f1jYEZPqdCLqBrvEw",
        net_leverage: 0.0,
        open_ms: 1711452327143,
        orders: [
          {
            leverage: 1.25,
            order_type: "LONG",
            order_uuid: "67ea3426-4d5d-4456-b329-fc2c44fecddb",
            price: 70851.6,
            price_sources: [],
            processed_ms: 1711452327140,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "d8b77ae7-8dc6-4911-8c87-c5132d1f8eca",
            price: 70716.89,
            price_sources: [],
            processed_ms: 1711454149599,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "42e7e91a-34d8-4227-b0e3-4ed0ada9d138",
            price: 70901.65,
            price_sources: [],
            processed_ms: 1711455960309,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "17465622-f27d-410b-84ae-a44a3c438248",
            price: 70876.94,
            price_sources: [],
            processed_ms: 1711457778184,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "cf896c76-4836-4c06-bcb4-0bcf0af7cffa",
            price: 70775.59,
            price_sources: [],
            processed_ms: 1711459666791,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "46156050-8785-4990-91b0-e6a2ab74e398",
            price: 70629.17,
            price_sources: [],
            processed_ms: 1711461413352,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "4b6d60fc-a1bf-4c56-a62b-7444f4de04a7",
            price: 69884.23,
            price_sources: [],
            processed_ms: 1711463229584,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "871adfdd-7a5b-4d2d-b921-d41c7eb86043",
            price: 69870.99,
            price_sources: [],
            processed_ms: 1711463316978,
          },
        ],
        position_type: "FLAT",
        position_uuid: "a96b985c-241f-4503-800b-322782311a34",
        return_at_close: 0.961298416302525,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 70077.48533333332,
        close_ms: 1711483335504,
        current_return: 0.967059187344259,
        initial_entry_price: 70284.62,
        is_closed_position: true,
        miner_hotkey: "5Fhn66JUv5mtLJJyHhBnYY7efYF23k6f1jYEZPqdCLqBrvEw",
        net_leverage: 0.0,
        open_ms: 1711465045568,
        orders: [
          {
            leverage: 1.25,
            order_type: "LONG",
            order_uuid: "332d2745-f5a0-4c55-9be3-0bd47f411647",
            price: 70284.62,
            price_sources: [],
            processed_ms: 1711465045565,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "214c959a-2b57-4372-b619-451ced33c597",
            price: 70058.39,
            price_sources: [],
            processed_ms: 1711466856933,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "7734fb1a-ead2-48db-aac1-0872ebadaaac",
            price: 70278.26,
            price_sources: [],
            processed_ms: 1711468690541,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "2da65ebf-766a-467c-8dbb-16e516c55b13",
            price: 69779.27,
            price_sources: [],
            processed_ms: 1711470489793,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "d0946955-485f-4313-a691-79d9f516c9c6",
            price: 69711.04,
            price_sources: [],
            processed_ms: 1711472312902,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "968ba0dc-34c6-41a1-808e-2a8d775d1bc9",
            price: 70051.08,
            price_sources: [],
            processed_ms: 1711474130332,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "8967fa0f-5b15-4a9d-9933-9fbdc748b812",
            price: 70061.89,
            price_sources: [],
            processed_ms: 1711475981692,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "94511a02-2a7f-42f5-b36e-bafa66c84f9f",
            price: 70196.07,
            price_sources: [],
            processed_ms: 1711477758390,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "c6d6e8e0-346a-48ac-a16f-9b0eb025c2fa",
            price: 70088.88,
            price_sources: [],
            processed_ms: 1711479570943,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "3faf3289-c13a-4314-a7b9-d26ace3bf34f",
            price: 69959.1,
            price_sources: [],
            processed_ms: 1711481395269,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "c77aa27c-8273-4217-9c13-eeec19cc4d87",
            price: 69555.2,
            price_sources: [],
            processed_ms: 1711483208721,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "1b8c389a-1d7c-4c2b-b766-aff855ae3d4b",
            price: 69460.09,
            price_sources: [],
            processed_ms: 1711483335504,
          },
        ],
        position_type: "FLAT",
        position_uuid: "40f83ee9-774f-4c8e-9563-fdb250f66e57",
        return_at_close: 0.9598062434391771,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 70167.61603773586,
        close_ms: 1711524629191,
        current_return: 0.9679396867944392,
        initial_entry_price: 69740.78,
        is_closed_position: true,
        miner_hotkey: "5Fhn66JUv5mtLJJyHhBnYY7efYF23k6f1jYEZPqdCLqBrvEw",
        net_leverage: 0.0,
        open_ms: 1711485021849,
        orders: [
          {
            leverage: 1.25,
            order_type: "LONG",
            order_uuid: "2b400809-ed2a-42d1-869c-673541bbd012",
            price: 69740.78,
            price_sources: [],
            processed_ms: 1711485021847,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "97f4a6a7-1872-4523-97a8-cb8dafaee2ac",
            price: 69885.24,
            price_sources: [],
            processed_ms: 1711486881015,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "e31f0442-b83e-4c7c-9cde-89966070a575",
            price: 70121.64,
            price_sources: [],
            processed_ms: 1711488671482,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "b2b9b300-5159-4a00-906c-0ec169f071ba",
            price: 70007.35,
            price_sources: [],
            processed_ms: 1711490489768,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "06329403-e4b9-4c99-90af-cb4c4ff12b4f",
            price: 69802.16,
            price_sources: [],
            processed_ms: 1711492304290,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "58388d1f-d9c3-4613-a74d-17e1bb3d84f4",
            price: 70250.13,
            price_sources: [],
            processed_ms: 1711494190853,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "6a947e8e-7142-47c6-8bff-34af96ca0db1",
            price: 70111.18,
            price_sources: [],
            processed_ms: 1711495940252,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "ef63ccf8-d136-4b59-a1eb-54024bd477ab",
            price: 69976.4,
            price_sources: [],
            processed_ms: 1711497762169,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "1958c5cd-e77e-42bc-a321-1f0ed00483da",
            price: 70117.33,
            price_sources: [],
            processed_ms: 1711499571457,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "26d979ee-7a83-485c-9112-dae1a3c3641f",
            price: 70555.03,
            price_sources: [],
            processed_ms: 1711501436232,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "fb426024-3a22-4a5c-99a3-c293a77ff95f",
            price: 70504.48,
            price_sources: [],
            processed_ms: 1711503217156,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "2b3ccc90-a3e8-4503-8976-5be0bdb17bb5",
            price: 70303.87,
            price_sources: [],
            processed_ms: 1711505028023,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "65e43731-2dfb-4561-b04a-32be49abe968",
            price: 70436.86,
            price_sources: [],
            processed_ms: 1711506848222,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "61cf08e8-3e98-44fd-82a7-da559020dd3f",
            price: 70585.35,
            price_sources: [],
            processed_ms: 1711508658278,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "443949c1-4a28-440f-91e9-0e9bcca5e6c2",
            price: 70465.51,
            price_sources: [],
            processed_ms: 1711510480020,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "81c9f4c6-e7c9-4e5b-ac7c-aff59c4ceb3f",
            price: 70429.17,
            price_sources: [],
            processed_ms: 1711512327156,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "d9325f16-bcfb-4f22-b203-a637c8c03918",
            price: 70362.69,
            price_sources: [],
            processed_ms: 1711514113233,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "eab99de2-1bc9-4e0f-bbcf-9ecdf954f7d6",
            price: 70224.23,
            price_sources: [],
            processed_ms: 1711515927909,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "2c68ef9b-dc22-4e20-aeeb-c80a8382eaaf",
            price: 70421.48,
            price_sources: [],
            processed_ms: 1711517747207,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "00494c29-21c7-4be2-b59f-43d1e7b25f84",
            price: 70483.55,
            price_sources: [],
            processed_ms: 1711519569635,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "b701fdeb-821b-462c-aa40-f64b68b780ea",
            price: 70283.26,
            price_sources: [],
            processed_ms: 1711521378416,
          },
          {
            leverage: 0.375,
            order_type: "LONG",
            order_uuid: "c64002ef-3675-4f28-9ba8-beb0e8160dbd",
            price: 70274.01,
            price_sources: [],
            processed_ms: 1711523369177,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "e69783fd-c391-4ba1-9186-68205ce9eb88",
            price: 69830.12,
            price_sources: [],
            processed_ms: 1711524629191,
          },
        ],
        position_type: "FLAT",
        position_uuid: "70881af1-623f-44da-93d8-a9f35a747aba",
        return_at_close: 0.955114485944413,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
    ],
    thirty_day_returns: 1.0,
  },
  "5FjQVqqZBAHkPbymJfnyG94cnS2CLwQvSJSpCuEeYr4sLuAq": {
    all_time_returns: 1.0204390301839628,
    n_positions: 6,
    percentage_profitable: 0.8333333333333334,
    positions: [
      {
        average_entry_price: 1.07656,
        close_ms: 1718195686935,
        current_return: 1.010031953630081,
        initial_entry_price: 1.07656,
        is_closed_position: true,
        miner_hotkey: "5FjQVqqZBAHkPbymJfnyG94cnS2CLwQvSJSpCuEeYr4sLuAq",
        net_leverage: 0.0,
        open_ms: 1718195060667,
        orders: [
          {
            leverage: 2.0,
            order_type: "LONG",
            order_uuid: "b5f02459-a35a-4c7d-b58a-e227d24e5ad1",
            price: 1.07656,
            price_sources: [],
            processed_ms: 1718195060657,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "a25f5ffe-5046-480f-9d17-af25f965cb6c",
            price: 1.08196,
            price_sources: [],
            processed_ms: 1718195686935,
          },
        ],
        position_type: "FLAT",
        position_uuid: "b5456e6e-0f3f-4a30-be8d-39426bffe7f4",
        return_at_close: 1.0098905491565728,
        trade_pair: ["EURUSD", "EUR/USD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 144.12049999999968,
        close_ms: 1718386291429,
        current_return: 1.0084284017590603,
        initial_entry_price: 155.765,
        is_closed_position: true,
        miner_hotkey: "5FjQVqqZBAHkPbymJfnyG94cnS2CLwQvSJSpCuEeYr4sLuAq",
        net_leverage: 0.0,
        open_ms: 1716161142441,
        orders: [
          {
            leverage: 0.5,
            order_type: "LONG",
            order_uuid: "2a50f846-172c-4db1-a2a7-e0c329bdc378",
            price: 155.765,
            price_sources: [],
            processed_ms: 1716161142432,
          },
          {
            leverage: 0.5,
            order_type: "LONG",
            order_uuid: "40e0ce7d-2af5-4145-9ad9-ce4beb93a8e2",
            price: 155.822,
            price_sources: [],
            processed_ms: 1716174704825,
          },
          {
            leverage: -0.9,
            order_type: "SHORT",
            order_uuid: "4312664c-0fab-4530-a349-3be587f60f2c",
            price: 157.09050000000002,
            price_sources: [],
            processed_ms: 1717158238113,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "421a9bd4-64d0-45a0-bcdf-385b393590fc",
            price: 157.249,
            price_sources: [],
            processed_ms: 1718386291429,
          },
        ],
        position_type: "FLAT",
        position_uuid: "11714659-e912-4f01-b399-9c7d6db6657e",
        return_at_close: 1.008357811770937,
        trade_pair: ["USDJPY", "USD/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 3320.213076923076,
        close_ms: 1718386315272,
        current_return: 1.0006774314309472,
        initial_entry_price: 3018.49,
        is_closed_position: true,
        miner_hotkey: "5FjQVqqZBAHkPbymJfnyG94cnS2CLwQvSJSpCuEeYr4sLuAq",
        net_leverage: 0.0,
        open_ms: 1715805062034,
        orders: [
          {
            leverage: 0.001,
            order_type: "LONG",
            order_uuid: "bef704d8-535b-4221-a669-0b6be89c76e9",
            price: 3018.49,
            price_sources: [],
            processed_ms: 1715805059739,
          },
          {
            leverage: 0.015,
            order_type: "LONG",
            order_uuid: "9ae8cc75-ffa2-409a-9c12-737fa61cfb03",
            price: 3069.33,
            price_sources: [],
            processed_ms: 1716160920423,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "cfb75c17-ea00-4463-8c2d-6985b7bc4ad7",
            price: 3726.71,
            price_sources: [],
            processed_ms: 1716577962378,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "176fb4f7-d957-4137-bafa-a83e7fc57054",
            price: 3398.86,
            price_sources: [],
            processed_ms: 1718386315272,
          },
        ],
        position_type: "FLAT",
        position_uuid: "375bad20-66f2-4530-abec-60b32ae88e69",
        return_at_close: 1.00065141381773,
        trade_pair: ["ETHUSD", "ETH/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 61494.06,
        close_ms: 1719559355529,
        current_return: 1.0009020383432157,
        initial_entry_price: 61494.06,
        is_closed_position: true,
        miner_hotkey: "5FjQVqqZBAHkPbymJfnyG94cnS2CLwQvSJSpCuEeYr4sLuAq",
        net_leverage: 0.0,
        open_ms: 1719557690250,
        orders: [
          {
            leverage: 0.5,
            order_type: "LONG",
            order_uuid: "96690fcc-b85f-45e6-b207-0e60aff95069",
            price: 61494.06,
            price_sources: [],
            processed_ms: 1719557689783,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "1615bda2-a3cf-4c15-a992-a5cf74bce74a",
            price: 61605.0,
            price_sources: [],
            processed_ms: 1719559355529,
          },
        ],
        position_type: "FLAT",
        position_uuid: "72805c97-137a-49a7-9d13-04874ef4d53a",
        return_at_close: 1.0004015873240442,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 61382.740000000005,
        close_ms: 1719570031959,
        current_return: 1.001683795803185,
        initial_entry_price: 61382.74,
        is_closed_position: true,
        miner_hotkey: "5FjQVqqZBAHkPbymJfnyG94cnS2CLwQvSJSpCuEeYr4sLuAq",
        net_leverage: 0.0,
        open_ms: 1719565264853,
        orders: [
          {
            leverage: 0.6,
            order_type: "LONG",
            order_uuid: "05c9f952-7421-433f-94d3-7350468c597a",
            price: 61382.74,
            price_sources: [],
            processed_ms: 1719565264168,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "d89e7e78-a537-4dbb-b0af-5affeb3766e1",
            price: 61555.0,
            price_sources: [],
            processed_ms: 1719570031959,
          },
        ],
        position_type: "FLAT",
        position_uuid: "d3cea043-e6ed-4e9f-b0fa-13194c022406",
        return_at_close: 1.001082785525703,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 3379.99,
        close_ms: 1719692819824,
        current_return: 1.0012346190373345,
        initial_entry_price: 3379.99,
        is_closed_position: true,
        miner_hotkey: "5FjQVqqZBAHkPbymJfnyG94cnS2CLwQvSJSpCuEeYr4sLuAq",
        net_leverage: 0.0,
        open_ms: 1719672835906,
        orders: [
          {
            leverage: 1.3,
            order_type: "LONG",
            order_uuid: "468c6b6a-c9b7-4ca4-9ba7-0438a8ac2d36",
            price: 3379.99,
            price_sources: [],
            processed_ms: 1719672835192,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "7ed217fc-27eb-49e9-aea1-f23bea4a7ba1",
            price: 3383.2,
            price_sources: [],
            processed_ms: 1719692819824,
          },
        ],
        position_type: "FLAT",
        position_uuid: "f86446a3-3fcd-4a8f-b2cb-48d376ecc575",
        return_at_close: 0.9999330140325859,
        trade_pair: ["ETHUSD", "ETH/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 60215.6,
        close_ms: 0,
        current_return: 0.9965480705996452,
        initial_entry_price: 60215.6,
        is_closed_position: false,
        miner_hotkey: "5FjQVqqZBAHkPbymJfnyG94cnS2CLwQvSJSpCuEeYr4sLuAq",
        net_leverage: 0.1,
        open_ms: 1719603585117,
        orders: [
          {
            leverage: 0.1,
            order_type: "LONG",
            order_uuid: "1406d7ec-009f-463e-b88d-bcbb1983e378",
            price: 60215.6,
            price_sources: [],
            processed_ms: 1719603584408,
          },
        ],
        position_type: "LONG",
        position_uuid: "52220a1e-e8ea-42c0-968b-69598b3003f9",
        return_at_close: 0.9964982431961152,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
    ],
    thirty_day_returns: 1.0113222934622121,
  },
  "5Fjz3ENZwDkn2txvryhPofbn2T3DbyHferTxvsastmmggFFb": {
    all_time_returns: 1.1004156468706927,
    n_positions: 34,
    percentage_profitable: 0.9705882352941176,
    positions: [
      {
        average_entry_price: 63605.39,
        close_ms: 1713055269684,
        current_return: 1.000045944219507,
        initial_entry_price: 63605.39,
        is_closed_position: true,
        miner_hotkey: "5Fjz3ENZwDkn2txvryhPofbn2T3DbyHferTxvsastmmggFFb",
        net_leverage: 0.0,
        open_ms: 1713054237106,
        orders: [
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "f3fe39b5-ba39-42b4-8dd4-5dbc98e650f8",
            price: 63605.39,
            price_sources: [],
            processed_ms: 1713054237106,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "8a8a8965-342e-4a3d-8b11-a8a9bba44c6e",
            price: 63897.62,
            price_sources: [],
            processed_ms: 1713055269684,
          },
        ],
        position_type: "FLAT",
        position_uuid: "a5549435-ecd9-49e2-85b2-6bce8680397e",
        return_at_close: 1.000035943760065,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 1.7926400000000002,
        close_ms: 1713174737261,
        current_return: 1.000004239557301,
        initial_entry_price: 1.79264,
        is_closed_position: true,
        miner_hotkey: "5Fjz3ENZwDkn2txvryhPofbn2T3DbyHferTxvsastmmggFFb",
        net_leverage: 0.0,
        open_ms: 1713174123718,
        orders: [
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "ad7c2535-e92a-47e2-9fba-80af4a7c9a54",
            price: 1.79264,
            price_sources: [],
            processed_ms: 1713174123717,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "e3018cc4-f00d-466c-92fe-9d02accb2455",
            price: 1.7934,
            price_sources: [],
            processed_ms: 1713174737261,
          },
        ],
        position_type: "FLAT",
        position_uuid: "51002cd6-6aad-4ac9-97fd-d5f5be990086",
        return_at_close: 1.0000035395543334,
        trade_pair: ["EURNZD", "EUR/NZD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 1.37359,
        close_ms: 1713174767847,
        current_return: 1.0000015288404838,
        initial_entry_price: 1.37359,
        is_closed_position: true,
        miner_hotkey: "5Fjz3ENZwDkn2txvryhPofbn2T3DbyHferTxvsastmmggFFb",
        net_leverage: 0.0,
        open_ms: 1713174072894,
        orders: [
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "1a06cf9f-0b35-415b-b216-ccccaf478fa3",
            price: 1.37359,
            price_sources: [],
            processed_ms: 1713174072893,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "9bce4b97-e728-4284-b575-b20de7601f1c",
            price: 1.3738,
            price_sources: [],
            processed_ms: 1713174767847,
          },
        ],
        position_type: "FLAT",
        position_uuid: "0540cd18-64da-4be2-bf11-3a4aa935ad39",
        return_at_close: 1.0000008288394135,
        trade_pair: ["USDCAD", "USD/CAD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 163.98,
        close_ms: 1713176125041,
        current_return: 1.0000015855592146,
        initial_entry_price: 163.98,
        is_closed_position: true,
        miner_hotkey: "5Fjz3ENZwDkn2txvryhPofbn2T3DbyHferTxvsastmmggFFb",
        net_leverage: 0.0,
        open_ms: 1713174140071,
        orders: [
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "f036212d-ed15-40b9-89c6-93592cc3ee2b",
            price: 163.98,
            price_sources: [],
            processed_ms: 1713174140071,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "d0932801-c357-458d-98d0-3549dddda351",
            price: 164.006,
            price_sources: [],
            processed_ms: 1713176125041,
          },
        ],
        position_type: "FLAT",
        position_uuid: "fb4933d1-2488-482d-80e2-789bdae16eca",
        return_at_close: 1.0000008855581046,
        trade_pair: ["EURJPY", "EUR/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 192.14,
        close_ms: 1713178534916,
        current_return: 1.0000082752159885,
        initial_entry_price: 192.14,
        is_closed_position: true,
        miner_hotkey: "5Fjz3ENZwDkn2txvryhPofbn2T3DbyHferTxvsastmmggFFb",
        net_leverage: 0.0,
        open_ms: 1713174089986,
        orders: [
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "3643265f-da27-43a2-864d-c49b167741d0",
            price: 192.14,
            price_sources: [],
            processed_ms: 1713174089985,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "e9052e91-f56c-457f-b876-b9a9a62d0923",
            price: 192.299,
            price_sources: [],
            processed_ms: 1713178534916,
          },
        ],
        position_type: "FLAT",
        position_uuid: "692e8f47-7a2f-4906-acc0-1636e846e59d",
        return_at_close: 1.0000075752101958,
        trade_pair: ["GBPJPY", "GBP/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 1.24894,
        close_ms: 1713178551202,
        current_return: 1.0000034429195959,
        initial_entry_price: 1.24894,
        is_closed_position: true,
        miner_hotkey: "5Fjz3ENZwDkn2txvryhPofbn2T3DbyHferTxvsastmmggFFb",
        net_leverage: 0.0,
        open_ms: 1713174107365,
        orders: [
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "dd29b870-384a-4eec-a42b-e40be32d5b79",
            price: 1.24894,
            price_sources: [],
            processed_ms: 1713174107364,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "fd56a5ea-4de2-4d73-b798-efda63e9ce56",
            price: 1.24937,
            price_sources: [],
            processed_ms: 1713178551202,
          },
        ],
        position_type: "FLAT",
        position_uuid: "aa0e9780-220b-4f25-8c4e-e4683d364ec6",
        return_at_close: 1.0000027429171858,
        trade_pair: ["GBPUSD", "GBP/USD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.913,
        close_ms: 1713178590095,
        current_return: 1.000003395399781,
        initial_entry_price: 0.913,
        is_closed_position: true,
        miner_hotkey: "5Fjz3ENZwDkn2txvryhPofbn2T3DbyHferTxvsastmmggFFb",
        net_leverage: 0.0,
        open_ms: 1713174056591,
        orders: [
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "76450310-6366-4892-a302-5662dbb50a24",
            price: 0.913,
            price_sources: [],
            processed_ms: 1713174056590,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "2327f01b-9bb3-45ff-8e9d-98fcf70fdaca",
            price: 0.91331,
            price_sources: [],
            processed_ms: 1713178590095,
          },
        ],
        position_type: "FLAT",
        position_uuid: "78a141c7-cc3a-42a1-86ea-8c4860ddd208",
        return_at_close: 1.0000026953974042,
        trade_pair: ["USDCHF", "USD/CHF", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.97309,
        close_ms: 1713178628112,
        current_return: 1.0000041106166953,
        initial_entry_price: 0.97309,
        is_closed_position: true,
        miner_hotkey: "5Fjz3ENZwDkn2txvryhPofbn2T3DbyHferTxvsastmmggFFb",
        net_leverage: 0.0,
        open_ms: 1713174175531,
        orders: [
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "b836ada5-460d-4096-af45-062269140563",
            price: 0.97309,
            price_sources: [],
            processed_ms: 1713174175530,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "cf48e500-5ec9-4602-a5a1-8abcb812041b",
            price: 0.97349,
            price_sources: [],
            processed_ms: 1713178628112,
          },
        ],
        position_type: "FLAT",
        position_uuid: "c520f9f3-4b46-42f7-bbcb-76e478573279",
        return_at_close: 1.0000034106138178,
        trade_pair: ["EURCHF", "EUR/CHF", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 153.879,
        close_ms: 1713178662441,
        current_return: 1.0000030543478968,
        initial_entry_price: 153.879,
        is_closed_position: true,
        miner_hotkey: "5Fjz3ENZwDkn2txvryhPofbn2T3DbyHferTxvsastmmggFFb",
        net_leverage: 0.0,
        open_ms: 1713174039757,
        orders: [
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "558e65c3-cd13-4d5d-9b79-faca142a78f5",
            price: 153.879,
            price_sources: [],
            processed_ms: 1713174039756,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "a9c1d78d-ebf2-4a4a-a8fe-08fdfb18d6f5",
            price: 153.926,
            price_sources: [],
            processed_ms: 1713178662441,
          },
        ],
        position_type: "FLAT",
        position_uuid: "7ea9cae8-6806-4300-a31b-5e2062ff7b19",
        return_at_close: 1.0000023543457588,
        trade_pair: ["USDJPY", "USD/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 1.06583,
        close_ms: 1713179877613,
        current_return: 1.000004785003237,
        initial_entry_price: 1.06583,
        is_closed_position: true,
        miner_hotkey: "5Fjz3ENZwDkn2txvryhPofbn2T3DbyHferTxvsastmmggFFb",
        net_leverage: 0.0,
        open_ms: 1713174191819,
        orders: [
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "a09c5d6c-0286-49a4-aa9e-4375d2ae3221",
            price: 1.06583,
            price_sources: [],
            processed_ms: 1713174191818,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "af0ae1e6-3fa9-4bc3-abe0-b33888853059",
            price: 1.06634,
            price_sources: [],
            processed_ms: 1713179877613,
          },
        ],
        position_type: "FLAT",
        position_uuid: "a6169793-d6c9-40bc-9009-0895b6f4df7b",
        return_at_close: 1.0000040849998875,
        trade_pair: ["EURUSD", "EUR/USD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.8532999999999998,
        close_ms: 1713182205617,
        current_return: 1.0000059767959686,
        initial_entry_price: 0.8533,
        is_closed_position: true,
        miner_hotkey: "5Fjz3ENZwDkn2txvryhPofbn2T3DbyHferTxvsastmmggFFb",
        net_leverage: 0.0,
        open_ms: 1713174156792,
        orders: [
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "8a18f856-eb48-454c-b2ec-e62dfd869c86",
            price: 0.8533,
            price_sources: [],
            processed_ms: 1713174156791,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "f85905c9-7b87-4ead-8565-978d3b9858ea",
            price: 0.85381,
            price_sources: [],
            processed_ms: 1713182205617,
          },
        ],
        position_type: "FLAT",
        position_uuid: "da1d4de9-80f1-4639-ba5b-3f1463b89af8",
        return_at_close: 1.0000052767917849,
        trade_pair: ["EURGBP", "EUR/GBP", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 99.139,
        close_ms: 1713316245889,
        current_return: 1.0000002017369551,
        initial_entry_price: 99.139,
        is_closed_position: true,
        miner_hotkey: "5Fjz3ENZwDkn2txvryhPofbn2T3DbyHferTxvsastmmggFFb",
        net_leverage: 0.0,
        open_ms: 1713316226583,
        orders: [
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "d0adaf82-6da7-40cf-9b49-1401276a86c0",
            price: 99.139,
            price_sources: [],
            processed_ms: 1713316226582,
          },
          {
            leverage: -0.01,
            order_type: "SHORT",
            order_uuid: "5344e0b3-3ccf-4ed5-864b-bc52d02ee901",
            price: 99.141,
            price_sources: [],
            processed_ms: 1713316245889,
          },
        ],
        position_type: "FLAT",
        position_uuid: "9519040d-9dd2-4aa9-b77b-02c13e5ac104",
        return_at_close: 0.999999501736814,
        trade_pair: ["AUDJPY", "AUD/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 3151.0099999999998,
        close_ms: 1713657550288,
        current_return: 1.000218380114713,
        initial_entry_price: 3150.47,
        is_closed_position: true,
        miner_hotkey: "5Fjz3ENZwDkn2txvryhPofbn2T3DbyHferTxvsastmmggFFb",
        net_leverage: 0.0,
        open_ms: 1713657082644,
        orders: [
          {
            leverage: 0.05,
            order_type: "LONG",
            order_uuid: "c3d995ec-e7d9-4c8e-a9b4-840903b9ab1e",
            price: 3150.47,
            price_sources: [],
            processed_ms: 1713657082142,
          },
          {
            leverage: 0.05,
            order_type: "LONG",
            order_uuid: "63665c56-e315-4bff-9241-21ad5b5ce68f",
            price: 3151.55,
            price_sources: [],
            processed_ms: 1713657147798,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "067fb527-a27e-48ba-946d-a002bab5fc99",
            price: 3157.89,
            price_sources: [],
            processed_ms: 1713657550288,
          },
        ],
        position_type: "FLAT",
        position_uuid: "88291bc5-1be0-4bbf-a38c-e78d631ea3aa",
        return_at_close: 1.0001183582767017,
        trade_pair: ["ETHUSD", "ETH/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 194.797,
        close_ms: 1714042653596,
        current_return: 1.000285425340226,
        initial_entry_price: 194.797,
        is_closed_position: true,
        miner_hotkey: "5Fjz3ENZwDkn2txvryhPofbn2T3DbyHferTxvsastmmggFFb",
        net_leverage: 0.0,
        open_ms: 1714040822882,
        orders: [
          {
            leverage: -0.2,
            order_type: "SHORT",
            order_uuid: "5ea6cbe9-06b2-45a1-937d-b66e10a97378",
            price: 194.797,
            price_sources: [],
            processed_ms: 1714040822881,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "3c83387f-0e49-4d62-8101-10187e9b13b3",
            price: 194.519,
            price_sources: [],
            processed_ms: 1714042653596,
          },
        ],
        position_type: "FLAT",
        position_uuid: "f3b0576c-4e0e-47e7-969f-c46df4175c65",
        return_at_close: 1.0002714213442714,
        trade_pair: ["GBPJPY", "GBP/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 64266.36,
        close_ms: 1714097019355,
        current_return: 1.0003309974300707,
        initial_entry_price: 64266.36,
        is_closed_position: true,
        miner_hotkey: "5Fjz3ENZwDkn2txvryhPofbn2T3DbyHferTxvsastmmggFFb",
        net_leverage: 0.0,
        open_ms: 1714028266703,
        orders: [
          {
            leverage: 0.1,
            order_type: "LONG",
            order_uuid: "f5c26812-b477-4934-96af-96cb496afbf2",
            price: 64266.36,
            price_sources: [],
            processed_ms: 1714028266701,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "d690fafd-3155-4df4-b95e-36abbfb2c4e5",
            price: 64479.08,
            price_sources: [],
            processed_ms: 1714097019355,
          },
        ],
        position_type: "FLAT",
        position_uuid: "241b1b93-8c0a-43dd-afd8-e052ab3b4992",
        return_at_close: 1.0002309643303278,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 129.99699999999865,
        close_ms: 1714411348082,
        current_return: 1.0013596811428345,
        initial_entry_price: 101.362,
        is_closed_position: true,
        miner_hotkey: "5Fjz3ENZwDkn2txvryhPofbn2T3DbyHferTxvsastmmggFFb",
        net_leverage: 0.0,
        open_ms: 1714029821631,
        orders: [
          {
            leverage: -0.35,
            order_type: "SHORT",
            order_uuid: "c9e95f53-4ede-477a-8825-9d8c6d558f60",
            price: 101.362,
            price_sources: [],
            processed_ms: 1714029821629,
          },
          {
            leverage: 0.345,
            order_type: "LONG",
            order_uuid: "4374938e-6db6-4f9a-81a5-d6f504c88b82",
            price: 100.947,
            price_sources: [],
            processed_ms: 1714052479274,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "70c6c8a4-55b4-4f73-9e6e-89e9ac3a328a",
            price: 102.433,
            price_sources: [],
            processed_ms: 1714411348082,
          },
        ],
        position_type: "FLAT",
        position_uuid: "60633a65-e913-4b21-84b5-28d9f336b318",
        return_at_close: 1.0013351478306465,
        trade_pair: ["AUDJPY", "AUD/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 167.69799999999998,
        close_ms: 1714475907758,
        current_return: 1.000283843575952,
        initial_entry_price: 167.698,
        is_closed_position: true,
        miner_hotkey: "5Fjz3ENZwDkn2txvryhPofbn2T3DbyHferTxvsastmmggFFb",
        net_leverage: 0.0,
        open_ms: 1714465158308,
        orders: [
          {
            leverage: 0.1,
            order_type: "LONG",
            order_uuid: "1a83b05b-6a6e-497d-b24b-1376fcd24eab",
            price: 167.698,
            price_sources: [],
            processed_ms: 1714465158305,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "2fb6ea12-e32d-4edf-93ed-c925e73441e7",
            price: 168.174,
            price_sources: [],
            processed_ms: 1714475907758,
          },
        ],
        position_type: "FLAT",
        position_uuid: "7d77e59c-dbe5-408a-95ff-71e6123fcddd",
        return_at_close: 1.000276841589047,
        trade_pair: ["EURJPY", "EUR/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 1.4650599999999998,
        close_ms: 1714475924128,
        current_return: 1.0001938487160935,
        initial_entry_price: 1.46506,
        is_closed_position: true,
        miner_hotkey: "5Fjz3ENZwDkn2txvryhPofbn2T3DbyHferTxvsastmmggFFb",
        net_leverage: 0.0,
        open_ms: 1714464932360,
        orders: [
          {
            leverage: 0.1,
            order_type: "LONG",
            order_uuid: "61f284a0-4097-4c60-9c57-330345e81bbe",
            price: 1.46506,
            price_sources: [],
            processed_ms: 1714464932357,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "7f47c95e-f835-45b1-a94e-8a86dec90cdd",
            price: 1.4679,
            price_sources: [],
            processed_ms: 1714475924128,
          },
        ],
        position_type: "FLAT",
        position_uuid: "7ad202a1-eaa0-4d69-8c64-260f0b8b89ff",
        return_at_close: 1.0001868473591524,
        trade_pair: ["EURCAD", "EUR/CAD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 171.7455548471678,
        close_ms: 1714475940729,
        current_return: 1.0001841116590326,
        initial_entry_price: 171.7455548471678,
        is_closed_position: true,
        miner_hotkey: "5Fjz3ENZwDkn2txvryhPofbn2T3DbyHferTxvsastmmggFFb",
        net_leverage: 0.0,
        open_ms: 1714465116768,
        orders: [
          {
            leverage: 0.15,
            order_type: "LONG",
            order_uuid: "99c13c78-98d8-48dd-a78c-d03f20893b8f",
            price: 171.7455548471678,
            price_sources: [],
            processed_ms: 1714465116765,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "38bfc59f-03a4-4a2e-b6b0-f9316fd6e3e9",
            price: 171.9563572407305,
            price_sources: [],
            processed_ms: 1714475940729,
          },
        ],
        position_type: "FLAT",
        position_uuid: "db2edfc6-90ec-4028-8e05-5e163097b391",
        return_at_close: 1.0001736097258602,
        trade_pair: ["CHFJPY", "CHF/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.9768700000000001,
        close_ms: 1714475958330,
        current_return: 1.0001489451001668,
        initial_entry_price: 0.97687,
        is_closed_position: true,
        miner_hotkey: "5Fjz3ENZwDkn2txvryhPofbn2T3DbyHferTxvsastmmggFFb",
        net_leverage: 0.0,
        open_ms: 1714465002460,
        orders: [
          {
            leverage: 0.15,
            order_type: "LONG",
            order_uuid: "66294928-9e6b-4997-9116-200f7e8ca977",
            price: 0.97687,
            price_sources: [],
            processed_ms: 1714465002458,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "3db25c6d-f6b6-441d-bb52-681a85e979c6",
            price: 0.97784,
            price_sources: [],
            processed_ms: 1714475958330,
          },
        ],
        position_type: "FLAT",
        position_uuid: "09fa6c40-dd24-44ed-8b5e-c22643549aa1",
        return_at_close: 1.0001384435362433,
        trade_pair: ["EURCHF", "EUR/CHF", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 1.25311,
        close_ms: 1714475975657,
        current_return: 1.0001508247480269,
        initial_entry_price: 1.25311,
        is_closed_position: true,
        miner_hotkey: "5Fjz3ENZwDkn2txvryhPofbn2T3DbyHferTxvsastmmggFFb",
        net_leverage: 0.0,
        open_ms: 1714464899944,
        orders: [
          {
            leverage: 0.15,
            order_type: "LONG",
            order_uuid: "85bd1e5f-5d1f-4e1a-8b35-6fafb07d4332",
            price: 1.25311,
            price_sources: [],
            processed_ms: 1714464899941,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "bacdb0ac-382c-4532-8181-6f29bfe38497",
            price: 1.25437,
            price_sources: [],
            processed_ms: 1714475975657,
          },
        ],
        position_type: "FLAT",
        position_uuid: "41bfbced-ccd3-479c-bc43-97d367e0a198",
        return_at_close: 1.000140323164367,
        trade_pair: ["GBPUSD", "GBP/USD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 1.36982,
        close_ms: 1714476069040,
        current_return: 1.000122643851017,
        initial_entry_price: 1.36982,
        is_closed_position: true,
        miner_hotkey: "5Fjz3ENZwDkn2txvryhPofbn2T3DbyHferTxvsastmmggFFb",
        net_leverage: 0.0,
        open_ms: 1714465100724,
        orders: [
          {
            leverage: -0.15,
            order_type: "SHORT",
            order_uuid: "b43c418b-f5e5-4bfb-ad85-6586007be25b",
            price: 1.36982,
            price_sources: [],
            processed_ms: 1714465100722,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "141b5a92-167a-41da-8be7-136af8d0978b",
            price: 1.3687,
            price_sources: [],
            processed_ms: 1714476069040,
          },
        ],
        position_type: "FLAT",
        position_uuid: "41268a19-710e-4340-b87e-22d7be4f4ca8",
        return_at_close: 1.0001121425632564,
        trade_pair: ["USDCAD", "USD/CAD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 156.841,
        close_ms: 1714486993673,
        current_return: 1.0004332413080763,
        initial_entry_price: 156.841,
        is_closed_position: true,
        miner_hotkey: "5Fjz3ENZwDkn2txvryhPofbn2T3DbyHferTxvsastmmggFFb",
        net_leverage: 0.0,
        open_ms: 1714464967583,
        orders: [
          {
            leverage: 0.15,
            order_type: "LONG",
            order_uuid: "d72ac7f2-dc0f-42f1-98eb-12011848cf6e",
            price: 156.841,
            price_sources: [],
            processed_ms: 1714464967581,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "859c5dbe-119f-42b7-bd23-2481ff072b39",
            price: 157.294,
            price_sources: [],
            processed_ms: 1714486993673,
          },
        ],
        position_type: "FLAT",
        position_uuid: "d72c37ae-5d26-4b44-b43b-ef3ae92c0c3c",
        return_at_close: 1.0004227367590426,
        trade_pair: ["USDJPY", "USD/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.65243,
        close_ms: 1714487013499,
        current_return: 1.000551783333078,
        initial_entry_price: 0.65243,
        is_closed_position: true,
        miner_hotkey: "5Fjz3ENZwDkn2txvryhPofbn2T3DbyHferTxvsastmmggFFb",
        net_leverage: 0.0,
        open_ms: 1714465043846,
        orders: [
          {
            leverage: -0.15,
            order_type: "SHORT",
            order_uuid: "4150ec91-10f1-471b-af08-23c8c6cfeb5c",
            price: 0.65243,
            price_sources: [],
            processed_ms: 1714465043841,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "6932d481-de2a-49ed-bab5-3148af75cd95",
            price: 0.65003,
            price_sources: [],
            processed_ms: 1714487013499,
          },
        ],
        position_type: "FLAT",
        position_uuid: "4f7b010e-9cf7-434c-bf51-012f5add2096",
        return_at_close: 1.000541277539353,
        trade_pair: ["AUDUSD", "AUD/USD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.812722839531807,
        close_ms: 1714523878092,
        current_return: 1.000399183970218,
        initial_entry_price: 0.812722839531807,
        is_closed_position: true,
        miner_hotkey: "5Fjz3ENZwDkn2txvryhPofbn2T3DbyHferTxvsastmmggFFb",
        net_leverage: 0.0,
        open_ms: 1714465028014,
        orders: [
          {
            leverage: -0.15,
            order_type: "SHORT",
            order_uuid: "857265d6-ada0-434a-ad66-7d76775d6ec8",
            price: 0.812722839531807,
            price_sources: [],
            processed_ms: 1714465028010,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "12e66a2b-325f-464f-999f-7e522b13ae92",
            price: 0.81056,
            price_sources: [],
            processed_ms: 1714523878092,
          },
        ],
        position_type: "FLAT",
        position_uuid: "c10f7f44-f0f1-45c4-9dc6-2aeb89c7df78",
        return_at_close: 1.0003886797787862,
        trade_pair: ["NZDCAD", "NZD/CAD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.8921533945883402,
        close_ms: 1714544282753,
        current_return: 1.0240939821597843,
        initial_entry_price: 0.89367,
        is_closed_position: true,
        miner_hotkey: "5Fjz3ENZwDkn2txvryhPofbn2T3DbyHferTxvsastmmggFFb",
        net_leverage: 0.0,
        open_ms: 1714464949022,
        orders: [
          {
            leverage: 0.15,
            order_type: "LONG",
            order_uuid: "ebd5351f-2b23-443b-a577-d754a42da045",
            price: 0.89367,
            price_sources: [],
            processed_ms: 1714464949012,
          },
          {
            leverage: 20.0,
            order_type: "LONG",
            order_uuid: "f52048fa-dc15-491e-a9d6-24aaea5e40c2",
            price: 0.892264565989008,
            price_sources: [],
            processed_ms: 1714493489597,
          },
          {
            leverage: 15.0,
            order_type: "LONG",
            order_uuid: "92600459-d9f2-485d-b773-5e8c44995464",
            price: 0.89199,
            price_sources: [],
            processed_ms: 1714493728856,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "1a2e7646-fe8c-4f44-9b0a-60726fc683ed",
            price: 0.892765971232344,
            price_sources: [],
            processed_ms: 1714544282753,
          },
        ],
        position_type: "FLAT",
        position_uuid: "ee93c61d-4d44-4dbc-9985-a4b79ef85634",
        return_at_close: 1.0215741989166802,
        trade_pair: ["AUDCAD", "AUD/CAD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.8922911111111111,
        close_ms: 1714575661301,
        current_return: 1.0564272105794017,
        initial_entry_price: 0.8923,
        is_closed_position: true,
        miner_hotkey: "5Fjz3ENZwDkn2txvryhPofbn2T3DbyHferTxvsastmmggFFb",
        net_leverage: 0.0,
        open_ms: 1714544457053,
        orders: [
          {
            leverage: 35.0,
            order_type: "LONG",
            order_uuid: "5ee51d78-13e0-46a1-93b9-563b73494d1b",
            price: 0.8923,
            price_sources: [],
            processed_ms: 1714544457049,
          },
          {
            leverage: 10.0,
            order_type: "LONG",
            order_uuid: "0b950017-7ceb-4116-953d-e5762c4a59e3",
            price: 0.89226,
            price_sources: [],
            processed_ms: 1714545565228,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "34016405-502b-4842-bf67-e2d36325bff3",
            price: 0.89341,
            price_sources: [],
            processed_ms: 1714575661301,
          },
        ],
        position_type: "FLAT",
        position_uuid: "2a419065-3336-4cbc-9554-e69756541e49",
        return_at_close: 1.0530994648660765,
        trade_pair: ["AUDCAD", "AUD/CAD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.5759499999999912,
        close_ms: 1714966083357,
        current_return: 1.0001291314258294,
        initial_entry_price: 0.66684,
        is_closed_position: true,
        miner_hotkey: "5Fjz3ENZwDkn2txvryhPofbn2T3DbyHferTxvsastmmggFFb",
        net_leverage: 0.0,
        open_ms: 1714464983986,
        orders: [
          {
            leverage: 0.15,
            order_type: "LONG",
            order_uuid: "fc0735d1-c4ce-409d-ba19-46951e50d92e",
            price: 0.66684,
            price_sources: [],
            processed_ms: 1714464983983,
          },
          {
            leverage: -0.149,
            order_type: "SHORT",
            order_uuid: "9a9b77b6-4847-4b30-87ed-1b538ec6f18f",
            price: 0.66745,
            price_sources: [],
            processed_ms: 1714524284997,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "b18ef042-a88f-4be5-a865-90bb7da2bee2",
            price: 0.66206,
            price_sources: [],
            processed_ms: 1714966083357,
          },
        ],
        position_type: "FLAT",
        position_uuid: "c262ccc8-535a-45dc-a788-e9a080387585",
        return_at_close: 1.0001186300699494,
        trade_pair: ["CADCHF", "CAD/CHF", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 1.07653,
        close_ms: 1718195514738,
        current_return: 1.0106824705303148,
        initial_entry_price: 1.07653,
        is_closed_position: true,
        miner_hotkey: "5Fjz3ENZwDkn2txvryhPofbn2T3DbyHferTxvsastmmggFFb",
        net_leverage: 0.0,
        open_ms: 1718194330461,
        orders: [
          {
            leverage: 2.5,
            order_type: "LONG",
            order_uuid: "6a0668b4-0c0b-40ff-a1f3-a41c6bc78455",
            price: 1.07653,
            price_sources: [],
            processed_ms: 1718194330446,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "c4eeb0f1-08ca-4814-bc30-cc94856303d8",
            price: 1.08113,
            price_sources: [],
            processed_ms: 1718195514738,
          },
        ],
        position_type: "FLAT",
        position_uuid: "66dbb9be-72b4-47f4-a30f-d57db646b578",
        return_at_close: 1.010505601097972,
        trade_pair: ["EURUSD", "EUR/USD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 436.94600000000213,
        close_ms: 1718390087420,
        current_return: 1.0028163915815214,
        initial_entry_price: 114.51,
        is_closed_position: true,
        miner_hotkey: "5Fjz3ENZwDkn2txvryhPofbn2T3DbyHferTxvsastmmggFFb",
        net_leverage: 0.0,
        open_ms: 1714465066251,
        orders: [
          {
            leverage: -0.15,
            order_type: "SHORT",
            order_uuid: "b56053fa-bd69-4f0e-ac97-e6fee76646ad",
            price: 114.51,
            price_sources: [],
            processed_ms: 1714465066248,
          },
          {
            leverage: 0.149,
            order_type: "LONG",
            order_uuid: "29fa1c52-9a54-4420-9bbd-eac2bb8221fe",
            price: 112.346,
            price_sources: [],
            processed_ms: 1714670121747,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "cc992fb0-7015-4138-b6cd-380f5b173660",
            price: 114.441,
            price_sources: [],
            processed_ms: 1718390087420,
          },
        ],
        position_type: "FLAT",
        position_uuid: "dc6226f5-5533-4e7f-9fbc-d3214173837b",
        return_at_close: 1.0028058620094098,
        trade_pair: ["CADJPY", "CAD/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 253.69900000000007,
        close_ms: 1718390113465,
        current_return: 1.0027594295221764,
        initial_entry_price: 196.537,
        is_closed_position: true,
        miner_hotkey: "5Fjz3ENZwDkn2txvryhPofbn2T3DbyHferTxvsastmmggFFb",
        net_leverage: 0.0,
        open_ms: 1714464915686,
        orders: [
          {
            leverage: -0.15,
            order_type: "SHORT",
            order_uuid: "39929ed6-ca0b-4edd-b9b1-b2da76e437e7",
            price: 196.537,
            price_sources: [],
            processed_ms: 1714464915684,
          },
          {
            leverage: 0.14,
            order_type: "LONG",
            order_uuid: "8772aeee-ff31-4987-9c76-21c9a9d93acc",
            price: 192.454,
            price_sources: [],
            processed_ms: 1714670064161,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "48be50d8-0352-444a-a523-1f763c53ea68",
            price: 199.466,
            price_sources: [],
            processed_ms: 1718390113465,
          },
        ],
        position_type: "FLAT",
        position_uuid: "82327f49-e64f-4842-9e6b-39e58078e136",
        return_at_close: 1.0027489005481665,
        trade_pair: ["GBPJPY", "GBP/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 2.0170199999999787,
        close_ms: 1718390167063,
        current_return: 1.0001516372069283,
        initial_entry_price: 1.80246,
        is_closed_position: true,
        miner_hotkey: "5Fjz3ENZwDkn2txvryhPofbn2T3DbyHferTxvsastmmggFFb",
        net_leverage: 0.0,
        open_ms: 1714465084685,
        orders: [
          {
            leverage: -0.15,
            order_type: "SHORT",
            order_uuid: "99ab3c31-e563-4e0a-836d-950027b6146c",
            price: 1.80246,
            price_sources: [],
            processed_ms: 1714465084683,
          },
          {
            leverage: 0.149,
            order_type: "LONG",
            order_uuid: "14402155-6c81-4505-8028-ab30edf6ab48",
            price: 1.80102,
            price_sources: [],
            processed_ms: 1714670142338,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "c2a6d63a-4537-4020-9d68-cd695d117762",
            price: 1.7437,
            price_sources: [],
            processed_ms: 1718390167063,
          },
        ],
        position_type: "FLAT",
        position_uuid: "80516562-2334-47cf-8428-ddcaf0997b0c",
        return_at_close: 1.0001411356147376,
        trade_pair: ["EURNZD", "EUR/NZD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 66385.2495049505,
        close_ms: 1718390187714,
        current_return: 0.99830848160884,
        initial_entry_price: 64036.2,
        is_closed_position: true,
        miner_hotkey: "5Fjz3ENZwDkn2txvryhPofbn2T3DbyHferTxvsastmmggFFb",
        net_leverage: 0.0,
        open_ms: 1714852971880,
        orders: [
          {
            leverage: 0.001,
            order_type: "LONG",
            order_uuid: "71adea3e-2431-4549-919e-a57089d5eb11",
            price: 64036.2,
            price_sources: [],
            processed_ms: 1714852971699,
          },
          {
            leverage: 0.1,
            order_type: "LONG",
            order_uuid: "4da3f503-0ba1-4927-8868-0c828260a984",
            price: 66408.74,
            price_sources: [],
            processed_ms: 1716157867106,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "ac3a8a62-3b3b-49ee-ad2e-e898d2bc3797",
            price: 65312.79,
            price_sources: [],
            processed_ms: 1718390187714,
          },
        ],
        position_type: "FLAT",
        position_uuid: "78649790-0763-4409-b9f9-769e641298b1",
        return_at_close: 0.9982076524521974,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 61273.0,
        close_ms: 1719320155141,
        current_return: 1.0022841218807632,
        initial_entry_price: 61273.0,
        is_closed_position: true,
        miner_hotkey: "5Fjz3ENZwDkn2txvryhPofbn2T3DbyHferTxvsastmmggFFb",
        net_leverage: 0.0,
        open_ms: 1719315673348,
        orders: [
          {
            leverage: -0.5,
            order_type: "SHORT",
            order_uuid: "4d50c824-5208-4b2e-805e-92d5134d0246",
            price: 61273.0,
            price_sources: [],
            processed_ms: 1719315671729,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "4887e251-940c-4120-94be-6141e2fca38a",
            price: 60993.09,
            price_sources: [],
            processed_ms: 1719320155141,
          },
        ],
        position_type: "FLAT",
        position_uuid: "18de84a2-4ec7-4915-8c64-6349eede71e7",
        return_at_close: 1.0017829798198228,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 61223.87,
        close_ms: 1719400542872,
        current_return: 1.0024588448917064,
        initial_entry_price: 61223.87,
        is_closed_position: true,
        miner_hotkey: "5Fjz3ENZwDkn2txvryhPofbn2T3DbyHferTxvsastmmggFFb",
        net_leverage: 0.0,
        open_ms: 1719398550856,
        orders: [
          {
            leverage: 0.5,
            order_type: "LONG",
            order_uuid: "4ac22096-d08f-4b0e-915a-278db623e471",
            price: 61223.87,
            price_sources: [],
            processed_ms: 1719398550388,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "e5aefa16-a1b0-4cac-b3cc-b1c82adbf5fa",
            price: 61524.95,
            price_sources: [],
            processed_ms: 1719400542872,
          },
        ],
        position_type: "FLAT",
        position_uuid: "3bb84141-14e6-4a17-91c5-cb71fb597ded",
        return_at_close: 1.0019576154692607,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 60719.0,
        close_ms: 0,
        current_return: 1.0013820221018133,
        initial_entry_price: 60719.0,
        is_closed_position: false,
        miner_hotkey: "5Fjz3ENZwDkn2txvryhPofbn2T3DbyHferTxvsastmmggFFb",
        net_leverage: -0.0325,
        open_ms: 1719601231242,
        orders: [
          {
            leverage: -0.0325,
            order_type: "SHORT",
            order_uuid: "0413711b-ff1f-4647-b733-071d4da34366",
            price: 60719.0,
            price_sources: [],
            processed_ms: 1719601229102,
          },
        ],
        position_type: "SHORT",
        position_uuid: "bd921f81-749c-4f27-9723-ff4a3c93ef15",
        return_at_close: 1.001365749643954,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
    ],
    thirty_day_returns: 1.0142890206465414,
  },
  "5FmqXG5YBU1Hke9jHD5FT41CUM9gVod7nFgYvbd7PmpqcUJm": {
    all_time_returns: 1.030726642369553,
    n_positions: 70,
    percentage_profitable: 0.9857142857142858,
    positions: [
      {
        average_entry_price: 157.7963712178398,
        close_ms: 1718689420673,
        current_return: 1.0000522366663298,
        initial_entry_price: 157.901,
        is_closed_position: true,
        miner_hotkey: "5FmqXG5YBU1Hke9jHD5FT41CUM9gVod7nFgYvbd7PmpqcUJm",
        net_leverage: 0.0,
        open_ms: 1718634281804,
        orders: [
          {
            leverage: 0.349,
            order_type: "LONG",
            order_uuid: "7ee8d2d9-0756-4667-a2d9-c518f2f493aa",
            price: 157.901,
            price_sources: [],
            processed_ms: 1718634281792,
          },
          {
            leverage: 0.389,
            order_type: "LONG",
            order_uuid: "9e137844-25ea-4bb8-8c46-96ef444215af",
            price: 157.867,
            price_sources: [],
            processed_ms: 1718634389092,
          },
          {
            leverage: 0.265,
            order_type: "LONG",
            order_uuid: "b22b1108-c2a6-4c61-bb8e-8d405e3f75e8",
            price: 157.83500671,
            price_sources: [],
            processed_ms: 1718635740149,
          },
          {
            leverage: 0.317,
            order_type: "LONG",
            order_uuid: "7304efd3-9d9a-4dac-b5ed-189f3a29006a",
            price: 157.793,
            price_sources: [],
            processed_ms: 1718639147206,
          },
          {
            leverage: 0.321,
            order_type: "LONG",
            order_uuid: "3819d42d-f1b9-49fa-bee7-d84bcc6eb8f9",
            price: 157.754,
            price_sources: [],
            processed_ms: 1718640710130,
          },
          {
            leverage: 0.375,
            order_type: "LONG",
            order_uuid: "75fcbec1-7221-421c-9552-3bf6b4f220b9",
            price: 157.694,
            price_sources: [],
            processed_ms: 1718649742505,
          },
          {
            leverage: 0.257,
            order_type: "LONG",
            order_uuid: "10dbbb00-cc77-44b1-a46b-27c44427c5a3",
            price: 157.714,
            price_sources: [],
            processed_ms: 1718658099100,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "84a90528-1dba-4dc2-b6da-05c0fa582f3c",
            price: 157.8,
            price_sources: [],
            processed_ms: 1718689420673,
          },
        ],
        position_type: "FLAT",
        position_uuid: "d2224c08-ebe5-45c0-8076-e109f1adaa26",
        return_at_close: 0.9998931183549538,
        trade_pair: ["USDJPY", "USD/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 157.8,
        close_ms: 1718693202540,
        current_return: 1.0003173732572876,
        initial_entry_price: 157.8,
        is_closed_position: true,
        miner_hotkey: "5FmqXG5YBU1Hke9jHD5FT41CUM9gVod7nFgYvbd7PmpqcUJm",
        net_leverage: 0.0,
        open_ms: 1718689450999,
        orders: [
          {
            leverage: 0.287,
            order_type: "LONG",
            order_uuid: "3fcb70ec-35c4-445f-bb5f-f3d187cf9823",
            price: 157.8,
            price_sources: [],
            processed_ms: 1718689450980,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "9cf475b6-ef80-4389-8826-6607d3beb988",
            price: 157.97449999999998,
            price_sources: [],
            processed_ms: 1718693202540,
          },
        ],
        position_type: "FLAT",
        position_uuid: "9361ad58-6450-44aa-bdb8-b98df9b9b6a9",
        return_at_close: 1.0002972768812588,
        trade_pair: ["USDJPY", "USD/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 157.957,
        close_ms: 1718697639947,
        current_return: 1.0003529568173617,
        initial_entry_price: 157.957,
        is_closed_position: true,
        miner_hotkey: "5FmqXG5YBU1Hke9jHD5FT41CUM9gVod7nFgYvbd7PmpqcUJm",
        net_leverage: 0.0,
        open_ms: 1718693232446,
        orders: [
          {
            leverage: 0.303,
            order_type: "LONG",
            order_uuid: "39388e6c-5f20-437e-af6c-d597f0692599",
            price: 157.957,
            price_sources: [],
            processed_ms: 1718693232440,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "60247e2f-8034-4b5e-a0e8-bfa1ade0d95e",
            price: 158.141,
            price_sources: [],
            processed_ms: 1718697639947,
          },
        ],
        position_type: "FLAT",
        position_uuid: "3d25732d-7783-4da0-acf2-182848a469d4",
        return_at_close: 1.0003317393311475,
        trade_pair: ["USDJPY", "USD/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 158.089,
        close_ms: 1718700676640,
        current_return: 1.0002132216662767,
        initial_entry_price: 158.089,
        is_closed_position: true,
        miner_hotkey: "5FmqXG5YBU1Hke9jHD5FT41CUM9gVod7nFgYvbd7PmpqcUJm",
        net_leverage: 0.0,
        open_ms: 1718699039675,
        orders: [
          {
            leverage: 0.318,
            order_type: "LONG",
            order_uuid: "5914ed34-1988-4567-8b10-7c0e3b878321",
            price: 158.089,
            price_sources: [],
            processed_ms: 1718699039664,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "a7999c73-e812-4443-b2b1-b37da6afd895",
            price: 158.195,
            price_sources: [],
            processed_ms: 1718700676640,
          },
        ],
        position_type: "FLAT",
        position_uuid: "6f27f83f-47a0-4b94-97e4-e9d9147716b1",
        return_at_close: 1.0001909569199623,
        trade_pair: ["USDJPY", "USD/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 158.1076578754579,
        close_ms: 1718706706244,
        current_return: 1.0001755394137093,
        initial_entry_price: 158.181,
        is_closed_position: true,
        miner_hotkey: "5FmqXG5YBU1Hke9jHD5FT41CUM9gVod7nFgYvbd7PmpqcUJm",
        net_leverage: 0.0,
        open_ms: 1718700709549,
        orders: [
          {
            leverage: 0.344,
            order_type: "LONG",
            order_uuid: "0e072054-3d98-4b7d-92ab-8bb1772b2c62",
            price: 158.181,
            price_sources: [],
            processed_ms: 1718700707446,
          },
          {
            leverage: 0.312,
            order_type: "LONG",
            order_uuid: "1c7f430e-6d27-4065-87b0-b69844bb18e8",
            price: 158.139,
            price_sources: [],
            processed_ms: 1718701250481,
          },
          {
            leverage: 0.347,
            order_type: "LONG",
            order_uuid: "49c81a1b-120a-438f-b921-d18736bf4874",
            price: 158.093,
            price_sources: [],
            processed_ms: 1718701664686,
          },
          {
            leverage: 0.362,
            order_type: "LONG",
            order_uuid: "7e8b15c8-a52f-4128-8d40-6eecf78d70b4",
            price: 158.025,
            price_sources: [],
            processed_ms: 1718701842496,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "18d7029a-4885-4698-9cac-36207313362f",
            price: 158.128,
            price_sources: [],
            processed_ms: 1718706706244,
          },
        ],
        position_type: "FLAT",
        position_uuid: "c6c76c14-4ae4-4abf-b13c-12b44c0386b4",
        return_at_close: 1.0000799726409182,
        trade_pair: ["USDJPY", "USD/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 157.8504667068394,
        close_ms: 1718715381488,
        current_return: 1.0005989097164252,
        initial_entry_price: 158.124,
        is_closed_position: true,
        miner_hotkey: "5FmqXG5YBU1Hke9jHD5FT41CUM9gVod7nFgYvbd7PmpqcUJm",
        net_leverage: 0.0,
        open_ms: 1718706736219,
        orders: [
          {
            leverage: 0.375,
            order_type: "LONG",
            order_uuid: "f20ab60e-a0b1-4109-938a-5f70f3542ab1",
            price: 158.124,
            price_sources: [],
            processed_ms: 1718706736074,
          },
          {
            leverage: 0.374,
            order_type: "LONG",
            order_uuid: "afc14c80-9057-4198-a364-98bb76e6bec6",
            price: 158.094,
            price_sources: [],
            processed_ms: 1718707890989,
          },
          {
            leverage: 0.28,
            order_type: "LONG",
            order_uuid: "e4db1c27-6b6e-47a6-9cf0-65522d02d9a4",
            price: 158.043,
            price_sources: [],
            processed_ms: 1718711390352,
          },
          {
            leverage: 0.312,
            order_type: "LONG",
            order_uuid: "fd513263-abea-4ca5-ac91-a42491e90592",
            price: 157.84,
            price_sources: [],
            processed_ms: 1718713804524,
          },
          {
            leverage: 0.382,
            order_type: "LONG",
            order_uuid: "0ddf9926-0373-4b64-85eb-03700df9d97a",
            price: 157.824,
            price_sources: [],
            processed_ms: 1718713854259,
          },
          {
            leverage: 0.363,
            order_type: "LONG",
            order_uuid: "050dbc6f-51b6-4e72-aa04-7fb96b4b7d1d",
            price: 157.7905,
            price_sources: [],
            processed_ms: 1718714106735,
          },
          {
            leverage: 0.315,
            order_type: "LONG",
            order_uuid: "d5033941-b0b0-4808-823d-2cd3bc13a9a5",
            price: 157.733,
            price_sources: [],
            processed_ms: 1718714240471,
          },
          {
            leverage: 0.264,
            order_type: "LONG",
            order_uuid: "39b04e90-2682-475e-90d4-460031356e76",
            price: 157.704,
            price_sources: [],
            processed_ms: 1718714422656,
          },
          {
            leverage: 0.319,
            order_type: "LONG",
            order_uuid: "457df86c-8af0-4028-bbf4-4b2b191f5b95",
            price: 157.6575,
            price_sources: [],
            processed_ms: 1718714494388,
          },
          {
            leverage: 0.335,
            order_type: "LONG",
            order_uuid: "2101c1ac-4aa7-4308-a3be-5dd3ecf184be",
            price: 157.626,
            price_sources: [],
            processed_ms: 1718714524741,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "1e376faf-85bb-47b7-90c7-6a0f17a1c8fd",
            price: 157.879,
            price_sources: [],
            processed_ms: 1718715381488,
          },
        ],
        position_type: "FLAT",
        position_uuid: "297449fe-5e49-405a-854b-de7bba612c0f",
        return_at_close: 1.0003664405717307,
        trade_pair: ["USDJPY", "USD/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 157.77177516274412,
        close_ms: 1718716538076,
        current_return: 1.0004961662586418,
        initial_entry_price: 157.8745,
        is_closed_position: true,
        miner_hotkey: "5FmqXG5YBU1Hke9jHD5FT41CUM9gVod7nFgYvbd7PmpqcUJm",
        net_leverage: 0.0,
        open_ms: 1718715412572,
        orders: [
          {
            leverage: 0.34,
            order_type: "LONG",
            order_uuid: "d0dc60e4-120e-432a-a073-a7202f201f7b",
            price: 157.8745,
            price_sources: [],
            processed_ms: 1718715411737,
          },
          {
            leverage: 0.282,
            order_type: "LONG",
            order_uuid: "cf72e4ba-72a8-4ced-b704-862f0e16b115",
            price: 157.8485,
            price_sources: [],
            processed_ms: 1718715467300,
          },
          {
            leverage: 0.31,
            order_type: "LONG",
            order_uuid: "34882ae3-2a92-42fb-8409-269c5283ccab",
            price: 157.804,
            price_sources: [],
            processed_ms: 1718715776589,
          },
          {
            leverage: 0.37,
            order_type: "LONG",
            order_uuid: "89c3b037-82fe-4ebc-b9c8-7abab804f1e7",
            price: 157.75799999999998,
            price_sources: [],
            processed_ms: 1718715911413,
          },
          {
            leverage: 0.317,
            order_type: "LONG",
            order_uuid: "a12ec624-e421-497a-a66d-7349906ab0b2",
            price: 157.71,
            price_sources: [],
            processed_ms: 1718716116560,
          },
          {
            leverage: 0.378,
            order_type: "LONG",
            order_uuid: "ac7a05a3-3ac0-4e46-b021-afb8774fe986",
            price: 157.661,
            price_sources: [],
            processed_ms: 1718716304282,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "50d6b852-734c-49cd-8b77-b55207906343",
            price: 157.81099999999998,
            price_sources: [],
            processed_ms: 1718716538076,
          },
        ],
        position_type: "FLAT",
        position_uuid: "3d2619f9-8ed7-4e58-8a48-aa3cec704a02",
        return_at_close: 1.0003563068995605,
        trade_pair: ["USDJPY", "USD/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 157.7814108108108,
        close_ms: 1718716991000,
        current_return: 1.0003028897338404,
        initial_entry_price: 157.8,
        is_closed_position: true,
        miner_hotkey: "5FmqXG5YBU1Hke9jHD5FT41CUM9gVod7nFgYvbd7PmpqcUJm",
        net_leverage: 0.0,
        open_ms: 1718716568120,
        orders: [
          {
            leverage: 0.378,
            order_type: "LONG",
            order_uuid: "e7108a9a-90b4-4a68-b7ce-75b4d6e3c784",
            price: 157.8,
            price_sources: [],
            processed_ms: 1718716567972,
          },
          {
            leverage: 0.362,
            order_type: "LONG",
            order_uuid: "94c4b932-4405-4044-b052-2b0b2f69ab0c",
            price: 157.762,
            price_sources: [],
            processed_ms: 1718716772184,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "a62ab28c-cb6e-4420-b6b2-6d4c0aa3a3cd",
            price: 157.846,
            price_sources: [],
            processed_ms: 1718716991000,
          },
        ],
        position_type: "FLAT",
        position_uuid: "9f1e52b0-caa6-4d32-a8aa-0af7cb9c68cb",
        return_at_close: 1.0002510740441521,
        trade_pair: ["USDJPY", "USD/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 157.79031912681913,
        close_ms: 1718719814207,
        current_return: 1.0004916931945962,
        initial_entry_price: 157.85250000000002,
        is_closed_position: true,
        miner_hotkey: "5FmqXG5YBU1Hke9jHD5FT41CUM9gVod7nFgYvbd7PmpqcUJm",
        net_leverage: 0.0,
        open_ms: 1718717021301,
        orders: [
          {
            leverage: 0.294,
            order_type: "LONG",
            order_uuid: "1e92d885-fe28-4efc-912e-c9469537ea1b",
            price: 157.85250000000002,
            price_sources: [],
            processed_ms: 1718717021116,
          },
          {
            leverage: 0.296,
            order_type: "LONG",
            order_uuid: "dae311d8-bb51-4c49-986e-fef976ace443",
            price: 157.783,
            price_sources: [],
            processed_ms: 1718717498815,
          },
          {
            leverage: 0.372,
            order_type: "LONG",
            order_uuid: "85027dde-9c02-4b35-a61f-229d365a3e04",
            price: 157.747,
            price_sources: [],
            processed_ms: 1718718699188,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "ba8c11c4-14eb-424d-9b9d-45a595fd1e5a",
            price: 157.871,
            price_sources: [],
            processed_ms: 1718719814207,
          },
        ],
        position_type: "FLAT",
        position_uuid: "a2786a91-2c03-4509-b34d-444ef148572a",
        return_at_close: 1.0004243200839764,
        trade_pair: ["USDJPY", "USD/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 157.84226726519339,
        close_ms: 1718721295065,
        current_return: 1.000374832931101,
        initial_entry_price: 157.869,
        is_closed_position: true,
        miner_hotkey: "5FmqXG5YBU1Hke9jHD5FT41CUM9gVod7nFgYvbd7PmpqcUJm",
        net_leverage: 0.0,
        open_ms: 1718719846306,
        orders: [
          {
            leverage: 0.333,
            order_type: "LONG",
            order_uuid: "c7143279-6374-40d3-bb5c-e9f26b1b8028",
            price: 157.869,
            price_sources: [],
            processed_ms: 1718719846287,
          },
          {
            leverage: 0.391,
            order_type: "LONG",
            order_uuid: "27c18611-f2af-4f79-b68e-a17eee8cac6c",
            price: 157.8195,
            price_sources: [],
            processed_ms: 1718719928079,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "3bfe199d-93e6-4c66-b970-49fa06d7fae5",
            price: 157.924,
            price_sources: [],
            processed_ms: 1718721295065,
          },
        ],
        position_type: "FLAT",
        position_uuid: "2a22f83f-0c7e-4377-9a57-006e7189c496",
        return_at_close: 1.000324133934568,
        trade_pair: ["USDJPY", "USD/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 157.81988926746163,
        close_ms: 1718733064803,
        current_return: 1.00022425769847,
        initial_entry_price: 157.921,
        is_closed_position: true,
        miner_hotkey: "5FmqXG5YBU1Hke9jHD5FT41CUM9gVod7nFgYvbd7PmpqcUJm",
        net_leverage: 0.0,
        open_ms: 1718721326337,
        orders: [
          {
            leverage: 0.359,
            order_type: "LONG",
            order_uuid: "1b5437d8-02f0-4259-acdc-96ff56902090",
            price: 157.921,
            price_sources: [],
            processed_ms: 1718721324889,
          },
          {
            leverage: 0.383,
            order_type: "LONG",
            order_uuid: "a92aa9f6-92b9-43c7-a545-e50447156b10",
            price: 157.877,
            price_sources: [],
            processed_ms: 1718723783177,
          },
          {
            leverage: 0.273,
            order_type: "LONG",
            order_uuid: "442daece-7cf2-4983-95cc-f0d774071267",
            price: 157.834,
            price_sources: [],
            processed_ms: 1718725246388,
          },
          {
            leverage: 0.379,
            order_type: "LONG",
            order_uuid: "6d1b5116-641c-4452-bb6b-b70f50b71fa0",
            price: 157.813,
            price_sources: [],
            processed_ms: 1718725316341,
          },
          {
            leverage: 0.367,
            order_type: "LONG",
            order_uuid: "e5bd572c-bb19-4447-9a87-22a229b2d297",
            price: 157.658,
            price_sources: [],
            processed_ms: 1718730128741,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "82849df3-8457-46c2-b36f-376b102a70d0",
            price: 157.84,
            price_sources: [],
            processed_ms: 1718733064803,
          },
        ],
        position_type: "FLAT",
        position_uuid: "3e38cb5d-93c2-4901-bb21-c530aa1b1694",
        return_at_close: 1.0001009600542234,
        trade_pair: ["USDJPY", "USD/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 157.81084126984123,
        close_ms: 1718744423201,
        current_return: 1.0003362137779863,
        initial_entry_price: 157.846,
        is_closed_position: true,
        miner_hotkey: "5FmqXG5YBU1Hke9jHD5FT41CUM9gVod7nFgYvbd7PmpqcUJm",
        net_leverage: 0.0,
        open_ms: 1718733093823,
        orders: [
          {
            leverage: 0.318,
            order_type: "LONG",
            order_uuid: "db486102-54cd-4370-aa90-772862e23e4e",
            price: 157.846,
            price_sources: [],
            processed_ms: 1718733093802,
          },
          {
            leverage: 0.291,
            order_type: "LONG",
            order_uuid: "b25958f6-32d4-429b-a4b3-d4563244f192",
            price: 157.819,
            price_sources: [],
            processed_ms: 1718734030670,
          },
          {
            leverage: 0.336,
            order_type: "LONG",
            order_uuid: "9dddb568-9497-4e15-83c1-2b2729ce9dc7",
            price: 157.7705,
            price_sources: [],
            processed_ms: 1718737087637,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "392c1c67-6f82-4593-a2b0-b14ee76b39cc",
            price: 157.867,
            price_sources: [],
            processed_ms: 1718744423201,
          },
        ],
        position_type: "FLAT",
        position_uuid: "5ce1b01f-4771-4cae-9b1a-418ed1c7bbab",
        return_at_close: 1.0002700415374448,
        trade_pair: ["USDJPY", "USD/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 157.87257839262188,
        close_ms: 1718756577980,
        current_return: 1.00019672728194,
        initial_entry_price: 157.881,
        is_closed_position: true,
        miner_hotkey: "5FmqXG5YBU1Hke9jHD5FT41CUM9gVod7nFgYvbd7PmpqcUJm",
        net_leverage: 0.0,
        open_ms: 1718744460945,
        orders: [
          {
            leverage: 0.383,
            order_type: "LONG",
            order_uuid: "b6720e99-29d6-4915-a99f-a8f435a32a2e",
            price: 157.881,
            price_sources: [],
            processed_ms: 1718744459791,
          },
          {
            leverage: 0.376,
            order_type: "LONG",
            order_uuid: "25b8275b-7e3a-40c8-a2d1-8f61fa19201a",
            price: 157.864,
            price_sources: [],
            processed_ms: 1718744529780,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "9560b7dd-6ed7-46b7-8ec0-9fd25c07ae05",
            price: 157.9135,
            price_sources: [],
            processed_ms: 1718756577980,
          },
        ],
        position_type: "FLAT",
        position_uuid: "e6acc241-196c-476c-b93a-160ef797f9df",
        return_at_close: 1.0001435868298196,
        trade_pair: ["USDJPY", "USD/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 157.82372413793104,
        close_ms: 1718770865990,
        current_return: 1.0002891322107317,
        initial_entry_price: 157.907,
        is_closed_position: true,
        miner_hotkey: "5FmqXG5YBU1Hke9jHD5FT41CUM9gVod7nFgYvbd7PmpqcUJm",
        net_leverage: 0.0,
        open_ms: 1718756607151,
        orders: [
          {
            leverage: 0.324,
            order_type: "LONG",
            order_uuid: "4829138a-076b-4e9e-9311-85e6f7d83c74",
            price: 157.907,
            price_sources: [],
            processed_ms: 1718756607084,
          },
          {
            leverage: 0.287,
            order_type: "LONG",
            order_uuid: "5a773092-db2c-4ba6-a662-0eadd75a0472",
            price: 157.868,
            price_sources: [],
            processed_ms: 1718757284057,
          },
          {
            leverage: 0.277,
            order_type: "LONG",
            order_uuid: "905a3885-7be9-46fb-b215-a56d52ca9c99",
            price: 157.819,
            price_sources: [],
            processed_ms: 1718759391236,
          },
          {
            leverage: 0.367,
            order_type: "LONG",
            order_uuid: "00fe6d59-90bd-4ab9-8784-cb605e6ebfa7",
            price: 157.781,
            price_sources: [],
            processed_ms: 1718759711396,
          },
          {
            leverage: 0.253,
            order_type: "LONG",
            order_uuid: "3b58c72c-562b-485c-acb1-c360f4eda448",
            price: 157.734,
            price_sources: [],
            processed_ms: 1718761900125,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "5dbd506f-1866-4e87-b2ce-938d5e6c45af",
            price: 157.854,
            price_sources: [],
            processed_ms: 1718770865990,
          },
        ],
        position_type: "FLAT",
        position_uuid: "f5abfd58-575c-4a65-b0ef-2fa1270eed59",
        return_at_close: 1.0001835416899356,
        trade_pair: ["USDJPY", "USD/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 157.74507785032648,
        close_ms: 1718785032497,
        current_return: 1.0003647881613622,
        initial_entry_price: 157.856,
        is_closed_position: true,
        miner_hotkey: "5FmqXG5YBU1Hke9jHD5FT41CUM9gVod7nFgYvbd7PmpqcUJm",
        net_leverage: 0.0,
        open_ms: 1718770899127,
        orders: [
          {
            leverage: 0.291,
            order_type: "LONG",
            order_uuid: "bac5e811-d910-417d-8dcf-bed5adeb7f8a",
            price: 157.856,
            price_sources: [],
            processed_ms: 1718770898870,
          },
          {
            leverage: 0.324,
            order_type: "LONG",
            order_uuid: "3fb20fe4-a4d8-480a-8091-b915e1309815",
            price: 157.81650000000002,
            price_sources: [],
            processed_ms: 1718771647671,
          },
          {
            leverage: 0.357,
            order_type: "LONG",
            order_uuid: "145352ab-54f6-47dd-9e0b-035fad98e046",
            price: 157.776,
            price_sources: [],
            processed_ms: 1718775989651,
          },
          {
            leverage: 0.325,
            order_type: "LONG",
            order_uuid: "0ecfcec1-0396-4b24-872e-14acc013e90d",
            price: 157.726,
            price_sources: [],
            processed_ms: 1718779030035,
          },
          {
            leverage: 0.396,
            order_type: "LONG",
            order_uuid: "b67302be-1b20-4e19-a1a3-c09ae90df47e",
            price: 157.67649999999998,
            price_sources: [],
            processed_ms: 1718780120032,
          },
          {
            leverage: 0.298,
            order_type: "LONG",
            order_uuid: "6812a068-4b81-47fd-82a5-106d8a92d6da",
            price: 157.63400000000001,
            price_sources: [],
            processed_ms: 1718780428420,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "10436e4d-e9da-45fe-beea-0c2567693248",
            price: 157.774,
            price_sources: [],
            processed_ms: 1718785032497,
          },
        ],
        position_type: "FLAT",
        position_uuid: "8e1530f6-1beb-4d64-be81-0dbad07bce7a",
        return_at_close: 1.0002253673208361,
        trade_pair: ["USDJPY", "USD/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 157.76285814606743,
        close_ms: 1718789630662,
        current_return: 1.0003345925423557,
        initial_entry_price: 157.771,
        is_closed_position: true,
        miner_hotkey: "5FmqXG5YBU1Hke9jHD5FT41CUM9gVod7nFgYvbd7PmpqcUJm",
        net_leverage: 0.0,
        open_ms: 1718785066132,
        orders: [
          {
            leverage: 0.371,
            order_type: "LONG",
            order_uuid: "ff4eedc4-c2bb-4a55-b5aa-e9c80d37e5c8",
            price: 157.771,
            price_sources: [],
            processed_ms: 1718785066105,
          },
          {
            leverage: 0.341,
            order_type: "LONG",
            order_uuid: "6afdf296-a418-4684-bacf-08ecd2c02c07",
            price: 157.754,
            price_sources: [],
            processed_ms: 1718785126211,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "c5a5c411-3e14-4a0d-ac30-2f9a8b7350df",
            price: 157.837,
            price_sources: [],
            processed_ms: 1718789630662,
          },
        ],
        position_type: "FLAT",
        position_uuid: "5f06b36c-8f37-451a-b022-32a6a07a6150",
        return_at_close: 1.0002847358662634,
        trade_pair: ["USDJPY", "USD/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 157.8365,
        close_ms: 1718812529240,
        current_return: 1.0002240451353142,
        initial_entry_price: 157.8365,
        is_closed_position: true,
        miner_hotkey: "5FmqXG5YBU1Hke9jHD5FT41CUM9gVod7nFgYvbd7PmpqcUJm",
        net_leverage: 0.0,
        open_ms: 1718789660681,
        orders: [
          {
            leverage: 0.345,
            order_type: "LONG",
            order_uuid: "debe7f47-1eaa-4838-8a5c-7191ef946aa4",
            price: 157.8365,
            price_sources: [],
            processed_ms: 1718789660230,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "5804c059-9c23-4468-bff3-8826ba6f9442",
            price: 157.939,
            price_sources: [],
            processed_ms: 1718812529240,
          },
        ],
        position_type: "FLAT",
        position_uuid: "7b27daef-011b-47cf-9be4-2882c69a1438",
        return_at_close: 1.000199889724624,
        trade_pair: ["USDJPY", "USD/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 157.927,
        close_ms: 1718846437436,
        current_return: 1.0003872168786845,
        initial_entry_price: 157.927,
        is_closed_position: true,
        miner_hotkey: "5FmqXG5YBU1Hke9jHD5FT41CUM9gVod7nFgYvbd7PmpqcUJm",
        net_leverage: 0.0,
        open_ms: 1718812560707,
        orders: [
          {
            leverage: 0.364,
            order_type: "LONG",
            order_uuid: "bbf97bab-6b7a-48f7-8f11-71c031ee7471",
            price: 157.927,
            price_sources: [],
            processed_ms: 1718812560038,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "c9ab457b-af73-42ee-8b69-9a22c5113643",
            price: 158.095,
            price_sources: [],
            processed_ms: 1718846437436,
          },
        ],
        position_type: "FLAT",
        position_uuid: "7a4475ba-acb5-459e-9605-768deff8b05d",
        return_at_close: 1.0003617270123983,
        trade_pair: ["USDJPY", "USD/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 158.06832304526748,
        close_ms: 1718856639732,
        current_return: 1.0004112227567297,
        initial_entry_price: 158.0895,
        is_closed_position: true,
        miner_hotkey: "5FmqXG5YBU1Hke9jHD5FT41CUM9gVod7nFgYvbd7PmpqcUJm",
        net_leverage: 0.0,
        open_ms: 1718846466960,
        orders: [
          {
            leverage: 0.397,
            order_type: "LONG",
            order_uuid: "0d9423a0-7214-4238-8812-f1c779657232",
            price: 158.0895,
            price_sources: [],
            processed_ms: 1718846466864,
          },
          {
            leverage: 0.332,
            order_type: "LONG",
            order_uuid: "364077cd-e531-4042-925d-aab1fc1d9e50",
            price: 158.043,
            price_sources: [],
            processed_ms: 1718847409769,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "e52a202e-fdba-47dd-921b-fb6da5a1c7af",
            price: 158.1575,
            price_sources: [],
            processed_ms: 1718856639732,
          },
        ],
        position_type: "FLAT",
        position_uuid: "13f109f8-6ea5-405e-a6cb-72e56d9d233e",
        return_at_close: 1.0003601717720325,
        trade_pair: ["USDJPY", "USD/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 158.0992878787879,
        close_ms: 1718868926336,
        current_return: 1.000378648452076,
        initial_entry_price: 158.115,
        is_closed_position: true,
        miner_hotkey: "5FmqXG5YBU1Hke9jHD5FT41CUM9gVod7nFgYvbd7PmpqcUJm",
        net_leverage: 0.0,
        open_ms: 1718856669392,
        orders: [
          {
            leverage: 0.355,
            order_type: "LONG",
            order_uuid: "1ebd0b87-3f6a-46e5-94ab-171d294b29a5",
            price: 158.115,
            price_sources: [],
            processed_ms: 1718856669093,
          },
          {
            leverage: 0.305,
            order_type: "LONG",
            order_uuid: "3a529dcc-efcc-42fb-8c90-1f9b899ab0a6",
            price: 158.08100000000002,
            price_sources: [],
            processed_ms: 1718863151089,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "5a3e4e6a-0f1a-49b0-b81d-3b776fe93584",
            price: 158.19,
            price_sources: [],
            processed_ms: 1718868926336,
          },
        ],
        position_type: "FLAT",
        position_uuid: "c9cc5838-2fff-4fcf-b936-c5b1a9f12662",
        return_at_close: 1.0003324309585175,
        trade_pair: ["USDJPY", "USD/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 158.176,
        close_ms: 1718871368102,
        current_return: 1.0003826339014767,
        initial_entry_price: 158.176,
        is_closed_position: true,
        miner_hotkey: "5FmqXG5YBU1Hke9jHD5FT41CUM9gVod7nFgYvbd7PmpqcUJm",
        net_leverage: 0.0,
        open_ms: 1718868955813,
        orders: [
          {
            leverage: 0.257,
            order_type: "LONG",
            order_uuid: "7d7b76a1-95a8-4b54-82d3-d07f2f2d03e4",
            price: 158.176,
            price_sources: [],
            processed_ms: 1718868955778,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "47eb6605-8c9c-4458-849c-22d283402682",
            price: 158.4115,
            price_sources: [],
            processed_ms: 1718871368102,
          },
        ],
        position_type: "FLAT",
        position_uuid: "b499abba-5945-4cb5-bd6e-8e097dac1d9a",
        return_at_close: 1.000364637017893,
        trade_pair: ["USDJPY", "USD/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 158.40116129032256,
        close_ms: 1718873595536,
        current_return: 1.0002575253580426,
        initial_entry_price: 158.43099999999998,
        is_closed_position: true,
        miner_hotkey: "5FmqXG5YBU1Hke9jHD5FT41CUM9gVod7nFgYvbd7PmpqcUJm",
        net_leverage: 0.0,
        open_ms: 1718871398705,
        orders: [
          {
            leverage: 0.374,
            order_type: "LONG",
            order_uuid: "4fcac776-327f-429d-bf1c-996c57347c51",
            price: 158.43099999999998,
            price_sources: [],
            processed_ms: 1718871398036,
          },
          {
            leverage: 0.37,
            order_type: "LONG",
            order_uuid: "71ee5773-32a9-4b8d-a9c0-1e9bf345061d",
            price: 158.371,
            price_sources: [],
            processed_ms: 1718871787937,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "cca82c20-659e-4766-b3a3-eeec94ee4a95",
            price: 158.45600000000002,
            price_sources: [],
            processed_ms: 1718873595536,
          },
        ],
        position_type: "FLAT",
        position_uuid: "ef8106c3-eb3e-434c-8ee3-e432067fabd7",
        return_at_close: 1.000205431946122,
        trade_pair: ["USDJPY", "USD/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 158.35899064254522,
        close_ms: 1718886819766,
        current_return: 1.0005767497633322,
        initial_entry_price: 158.45,
        is_closed_position: true,
        miner_hotkey: "5FmqXG5YBU1Hke9jHD5FT41CUM9gVod7nFgYvbd7PmpqcUJm",
        net_leverage: 0.0,
        open_ms: 1718873624850,
        orders: [
          {
            leverage: 0.315,
            order_type: "LONG",
            order_uuid: "84ac326d-b7e3-4e84-975a-959c9fe46de6",
            price: 158.45,
            price_sources: [],
            processed_ms: 1718873624768,
          },
          {
            leverage: 0.292,
            order_type: "LONG",
            order_uuid: "ede30ab5-261c-4c88-9dbe-362a9618e3a8",
            price: 158.408,
            price_sources: [],
            processed_ms: 1718875137966,
          },
          {
            leverage: 0.299,
            order_type: "LONG",
            order_uuid: "c894a292-a4ea-4a0f-b037-2ea302020bc1",
            price: 158.36849999999998,
            price_sources: [],
            processed_ms: 1718876079791,
          },
          {
            leverage: 0.343,
            order_type: "LONG",
            order_uuid: "b4eb6ee2-8e77-4c44-8a02-63a725b7d72e",
            price: 158.3255,
            price_sources: [],
            processed_ms: 1718886580079,
          },
          {
            leverage: 0.354,
            order_type: "LONG",
            order_uuid: "29e198a3-53a7-4bab-9630-29cd0eb56e28",
            price: 158.262,
            price_sources: [],
            processed_ms: 1718886611786,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "d7ff7483-0571-49a6-bf3b-52130ae19418",
            price: 158.416,
            price_sources: [],
            processed_ms: 1718886819766,
          },
        ],
        position_type: "FLAT",
        position_uuid: "2a556b1c-ffe5-42ce-93eb-f524f5f2e0b0",
        return_at_close: 1.0004644750462413,
        trade_pair: ["USDJPY", "USD/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 158.407,
        close_ms: 1718888439291,
        current_return: 1.000318148819181,
        initial_entry_price: 158.407,
        is_closed_position: true,
        miner_hotkey: "5FmqXG5YBU1Hke9jHD5FT41CUM9gVod7nFgYvbd7PmpqcUJm",
        net_leverage: 0.0,
        open_ms: 1718886849787,
        orders: [
          {
            leverage: 0.321,
            order_type: "LONG",
            order_uuid: "8bc44948-9d92-4866-9612-6c60511f03ab",
            price: 158.407,
            price_sources: [],
            processed_ms: 1718886849657,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "20f1aaf0-cc53-4c0c-a2da-a45391c0e3e4",
            price: 158.564,
            price_sources: [],
            processed_ms: 1718888439291,
          },
        ],
        position_type: "FLAT",
        position_uuid: "7b89c87d-2620-493a-ae63-fc0dd7cdfc04",
        return_at_close: 1.000295671670377,
        trade_pair: ["USDJPY", "USD/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 158.52859683794466,
        close_ms: 1718890267582,
        current_return: 1.000518950216655,
        initial_entry_price: 158.547,
        is_closed_position: true,
        miner_hotkey: "5FmqXG5YBU1Hke9jHD5FT41CUM9gVod7nFgYvbd7PmpqcUJm",
        net_leverage: 0.0,
        open_ms: 1718888469441,
        orders: [
          {
            leverage: 0.371,
            order_type: "LONG",
            order_uuid: "f8a04ba5-ebf6-45aa-9c2a-810f0195b40b",
            price: 158.547,
            price_sources: [],
            processed_ms: 1718888469392,
          },
          {
            leverage: 0.388,
            order_type: "LONG",
            order_uuid: "153d2257-26bd-43d6-8145-2e84cc084019",
            price: 158.511,
            price_sources: [],
            processed_ms: 1718888732402,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "7950bd48-24ea-48b7-9144-263470a4c88b",
            price: 158.637,
            price_sources: [],
            processed_ms: 1718890267582,
          },
        ],
        position_type: "FLAT",
        position_uuid: "43dd7a62-3fec-4755-8045-143bdeafb4ed",
        return_at_close: 1.00046579264483,
        trade_pair: ["USDJPY", "USD/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 158.624,
        close_ms: 1718901184290,
        current_return: 1.0003172281621948,
        initial_entry_price: 158.624,
        is_closed_position: true,
        miner_hotkey: "5FmqXG5YBU1Hke9jHD5FT41CUM9gVod7nFgYvbd7PmpqcUJm",
        net_leverage: 0.0,
        open_ms: 1718890297801,
        orders: [
          {
            leverage: 0.296,
            order_type: "LONG",
            order_uuid: "ed4d4620-6c00-488d-b3d6-b95b01f3ecb3",
            price: 158.624,
            price_sources: [],
            processed_ms: 1718890297741,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "1beb9034-3fe6-40f4-8832-48e63453a27e",
            price: 158.794,
            price_sources: [],
            processed_ms: 1718901184290,
          },
        ],
        position_type: "FLAT",
        position_uuid: "92a45f42-21d3-4f3b-89df-63464f988d21",
        return_at_close: 1.0002965015892273,
        trade_pair: ["USDJPY", "USD/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 158.73854716981134,
        close_ms: 1718909893740,
        current_return: 1.0004296316354577,
        initial_entry_price: 158.79649999999998,
        is_closed_position: true,
        miner_hotkey: "5FmqXG5YBU1Hke9jHD5FT41CUM9gVod7nFgYvbd7PmpqcUJm",
        net_leverage: 0.0,
        open_ms: 1718901214701,
        orders: [
          {
            leverage: 0.258,
            order_type: "LONG",
            order_uuid: "55e1c035-dc53-4a91-a0c9-0df5286d84d5",
            price: 158.79649999999998,
            price_sources: [],
            processed_ms: 1718901214046,
          },
          {
            leverage: 0.289,
            order_type: "LONG",
            order_uuid: "eff1552d-0bd0-4cb1-b1e6-ac446781b086",
            price: 158.728,
            price_sources: [],
            processed_ms: 1718907767977,
          },
          {
            leverage: 0.301,
            order_type: "LONG",
            order_uuid: "1b2b8c6d-d073-4acf-a7f3-7c52cff72f50",
            price: 158.699,
            price_sources: [],
            processed_ms: 1718907979973,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "1b592d4a-8a27-4626-9399-d5e382220063",
            price: 158.819,
            price_sources: [],
            processed_ms: 1718909893740,
          },
        ],
        position_type: "FLAT",
        position_uuid: "fd4a9d60-34c8-4201-a4eb-e5d53ee11bec",
        return_at_close: 1.000370246132524,
        trade_pair: ["USDJPY", "USD/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 158.822,
        close_ms: 1718917323856,
        current_return: 1.0003564367656874,
        initial_entry_price: 158.822,
        is_closed_position: true,
        miner_hotkey: "5FmqXG5YBU1Hke9jHD5FT41CUM9gVod7nFgYvbd7PmpqcUJm",
        net_leverage: 0.0,
        open_ms: 1718909924877,
        orders: [
          {
            leverage: 0.37,
            order_type: "LONG",
            order_uuid: "7f29e8e6-ff38-46bb-a289-81f1e03d9f3c",
            price: 158.822,
            price_sources: [],
            processed_ms: 1718909924838,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "e2db6617-02bd-4f5d-94a5-3f70b4a3cdcf",
            price: 158.975,
            price_sources: [],
            processed_ms: 1718917323856,
          },
        ],
        position_type: "FLAT",
        position_uuid: "93944fe4-d3eb-48a3-be1e-a03db3071958",
        return_at_close: 1.0003305275339751,
        trade_pair: ["USDJPY", "USD/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 158.8935855172414,
        close_ms: 1718926220048,
        current_return: 1.0002774625025164,
        initial_entry_price: 158.944,
        is_closed_position: true,
        miner_hotkey: "5FmqXG5YBU1Hke9jHD5FT41CUM9gVod7nFgYvbd7PmpqcUJm",
        net_leverage: 0.0,
        open_ms: 1718917354507,
        orders: [
          {
            leverage: 0.365,
            order_type: "LONG",
            order_uuid: "90b994ca-0f2b-47f6-8cdd-05f3f591fbe2",
            price: 158.944,
            price_sources: [],
            processed_ms: 1718917353638,
          },
          {
            leverage: 0.394,
            order_type: "LONG",
            order_uuid: "e069e5ee-43e7-415b-8aa9-f33d59e656ca",
            price: 158.904,
            price_sources: [],
            processed_ms: 1718917517007,
          },
          {
            leverage: 0.37,
            order_type: "LONG",
            order_uuid: "3239a9cd-f373-4c58-b0c1-43070102495d",
            price: 158.894,
            price_sources: [],
            processed_ms: 1718917773084,
          },
          {
            leverage: 0.321,
            order_type: "LONG",
            order_uuid: "c76a4253-ee38-47a9-b08a-8392e43917cb",
            price: 158.823,
            price_sources: [],
            processed_ms: 1718925772782,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "6606cb7c-7cae-4af4-a673-4df5e4e9913c",
            price: 158.924,
            price_sources: [],
            processed_ms: 1718926220048,
          },
        ],
        position_type: "FLAT",
        position_uuid: "cbae56c7-f854-45c9-b466-26a92b7f5c6e",
        return_at_close: 1.0001759343400725,
        trade_pair: ["USDJPY", "USD/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 158.8892166998012,
        close_ms: 1718929016336,
        current_return: 1.0004923737132212,
        initial_entry_price: 158.924,
        is_closed_position: true,
        miner_hotkey: "5FmqXG5YBU1Hke9jHD5FT41CUM9gVod7nFgYvbd7PmpqcUJm",
        net_leverage: 0.0,
        open_ms: 1718926249865,
        orders: [
          {
            leverage: 0.33,
            order_type: "LONG",
            order_uuid: "cdaead9e-9a57-4c20-88cf-4bdee66fcd3a",
            price: 158.924,
            price_sources: [],
            processed_ms: 1718926249853,
          },
          {
            leverage: 0.343,
            order_type: "LONG",
            order_uuid: "a3de963d-d71e-4a88-950d-fd4e0ad5ba0e",
            price: 158.888,
            price_sources: [],
            processed_ms: 1718926285094,
          },
          {
            leverage: 0.333,
            order_type: "LONG",
            order_uuid: "24d8be4c-a947-438c-b297-99764685c8ef",
            price: 158.856,
            price_sources: [],
            processed_ms: 1718927248645,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "e1f42774-30c8-4a95-95ee-abfa97610177",
            price: 158.96699999999998,
            price_sources: [],
            processed_ms: 1718929016336,
          },
        ],
        position_type: "FLAT",
        position_uuid: "fdd67440-c25d-4e78-b75d-bac3737a3956",
        return_at_close: 1.0004219190402643,
        trade_pair: ["USDJPY", "USD/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 158.96190370370368,
        close_ms: 1718931329885,
        current_return: 1.0002976254128009,
        initial_entry_price: 158.975,
        is_closed_position: true,
        miner_hotkey: "5FmqXG5YBU1Hke9jHD5FT41CUM9gVod7nFgYvbd7PmpqcUJm",
        net_leverage: 0.0,
        open_ms: 1718929045400,
        orders: [
          {
            leverage: 0.335,
            order_type: "LONG",
            order_uuid: "a5102800-cd55-4b91-9e02-8cde4a510582",
            price: 158.975,
            price_sources: [],
            processed_ms: 1718929045344,
          },
          {
            leverage: 0.34,
            order_type: "LONG",
            order_uuid: "c31979aa-01b4-4442-a162-c3a77bf010e8",
            price: 158.949,
            price_sources: [],
            processed_ms: 1718929351586,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "ac29527c-548b-400e-8597-8352a1c515f7",
            price: 159.03199999999998,
            price_sources: [],
            processed_ms: 1718931329885,
          },
        ],
        position_type: "FLAT",
        position_uuid: "f9423254-fe16-4ea4-ae85-d683bc356c32",
        return_at_close: 1.00025036135,
        trade_pair: ["USDJPY", "USD/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 158.94352176165802,
        close_ms: 1718977509549,
        current_return: 1.0011223807772236,
        initial_entry_price: 159.0405,
        is_closed_position: true,
        miner_hotkey: "5FmqXG5YBU1Hke9jHD5FT41CUM9gVod7nFgYvbd7PmpqcUJm",
        net_leverage: 0.0,
        open_ms: 1718931361161,
        orders: [
          {
            leverage: 0.341,
            order_type: "LONG",
            order_uuid: "679316a6-15f4-4dba-b548-22faa174e52c",
            price: 159.0405,
            price_sources: [],
            processed_ms: 1718931360981,
          },
          {
            leverage: 0.271,
            order_type: "LONG",
            order_uuid: "d462f2e2-2d04-41f5-829f-9e543ad34db3",
            price: 158.9785,
            price_sources: [],
            processed_ms: 1718932966530,
          },
          {
            leverage: 0.367,
            order_type: "LONG",
            order_uuid: "d8692c2f-2150-4473-aa5c-27e011060e6b",
            price: 158.923,
            price_sources: [],
            processed_ms: 1718934750897,
          },
          {
            leverage: 0.386,
            order_type: "LONG",
            order_uuid: "829da476-60dc-47d5-923c-68a1bd18bf86",
            price: 158.8585,
            price_sources: [],
            processed_ms: 1718937272255,
          },
          {
            leverage: 0.269,
            order_type: "LONG",
            order_uuid: "5d96d1b0-2498-4554-8f7f-781097f20bea",
            price: 158.8225,
            price_sources: [],
            processed_ms: 1718946609179,
          },
          {
            leverage: -1.0,
            order_type: "SHORT",
            order_uuid: "be13152e-e305-475d-ba5a-8bd5f6389b9c",
            price: 158.912,
            price_sources: [],
            processed_ms: 1718974704122,
          },
          {
            leverage: 0.331,
            order_type: "LONG",
            order_uuid: "00e33cf0-23f5-4b42-880b-46fa65c93eb5",
            price: 158.94,
            price_sources: [],
            processed_ms: 1718974841182,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "d153a95b-7e87-4657-a484-cb0912f469dd",
            price: 159.1285,
            price_sources: [],
            processed_ms: 1718977509549,
          },
        ],
        position_type: "FLAT",
        position_uuid: "e12f1ee6-0c56-48f1-9079-fb39f0ce3025",
        return_at_close: 1.0009846763937476,
        trade_pair: ["USDJPY", "USD/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 159.1825,
        close_ms: 1718979783225,
        current_return: 1.0002655254189374,
        initial_entry_price: 159.1825,
        is_closed_position: true,
        miner_hotkey: "5FmqXG5YBU1Hke9jHD5FT41CUM9gVod7nFgYvbd7PmpqcUJm",
        net_leverage: 0.0,
        open_ms: 1718977538647,
        orders: [
          {
            leverage: 0.386,
            order_type: "LONG",
            order_uuid: "76a5d2c6-fc4c-4fda-8c60-0bf20b91875b",
            price: 159.1825,
            price_sources: [],
            processed_ms: 1718977538616,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "6e8dc39c-59fe-4f2d-9069-7508450aadad",
            price: 159.292,
            price_sources: [],
            processed_ms: 1718979783225,
          },
        ],
        position_type: "FLAT",
        position_uuid: "d6b6fd1b-ceb3-4274-9e7e-b8c4e1d3741a",
        return_at_close: 1.0002384982444406,
        trade_pair: ["USDJPY", "USD/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 159.30700000000002,
        close_ms: 1718981912997,
        current_return: 1.000373542907719,
        initial_entry_price: 159.30700000000002,
        is_closed_position: true,
        miner_hotkey: "5FmqXG5YBU1Hke9jHD5FT41CUM9gVod7nFgYvbd7PmpqcUJm",
        net_leverage: 0.0,
        open_ms: 1718979812783,
        orders: [
          {
            leverage: 0.261,
            order_type: "LONG",
            order_uuid: "0c6b4b1b-ae54-4772-9ec7-eda40a33fcd2",
            price: 159.30700000000002,
            price_sources: [],
            processed_ms: 1718979812731,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "9bd82889-07aa-45e8-8b76-ef7d023936ed",
            price: 159.535,
            price_sources: [],
            processed_ms: 1718981912997,
          },
        ],
        position_type: "FLAT",
        position_uuid: "03b7a990-5bdd-4515-9372-646e94313727",
        return_at_close: 1.0003552660830901,
        trade_pair: ["USDJPY", "USD/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 159.49273458646618,
        close_ms: 1718990631736,
        current_return: 1.0005690210579092,
        initial_entry_price: 159.56,
        is_closed_position: true,
        miner_hotkey: "5FmqXG5YBU1Hke9jHD5FT41CUM9gVod7nFgYvbd7PmpqcUJm",
        net_leverage: 0.0,
        open_ms: 1718981943037,
        orders: [
          {
            leverage: 0.281,
            order_type: "LONG",
            order_uuid: "60a84f08-f33d-46bf-8b42-e056472666a5",
            price: 159.56,
            price_sources: [],
            processed_ms: 1718981942992,
          },
          {
            leverage: 0.362,
            order_type: "LONG",
            order_uuid: "7bd743b7-55cf-47e6-a905-f071af57d374",
            price: 159.519,
            price_sources: [],
            processed_ms: 1718983520641,
          },
          {
            leverage: 0.397,
            order_type: "LONG",
            order_uuid: "578e26e0-ccb5-41ad-93cb-5f6b45bb1911",
            price: 159.467,
            price_sources: [],
            processed_ms: 1718984018024,
          },
          {
            leverage: 0.29,
            order_type: "LONG",
            order_uuid: "caf1c17e-10d0-4e69-be45-5be98e6a498f",
            price: 159.43,
            price_sources: [],
            processed_ms: 1718984976724,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "7a64bdbf-6013-46e9-93ee-9b66ae41f6cf",
            price: 159.561,
            price_sources: [],
            processed_ms: 1718990631736,
          },
        ],
        position_type: "FLAT",
        position_uuid: "e84dd9f6-35d4-4fcd-8247-3e292a738de8",
        return_at_close: 1.0004758680820487,
        trade_pair: ["USDJPY", "USD/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 159.52560303030307,
        close_ms: 1719000085722,
        current_return: 1.0003842880154434,
        initial_entry_price: 159.547,
        is_closed_position: true,
        miner_hotkey: "5FmqXG5YBU1Hke9jHD5FT41CUM9gVod7nFgYvbd7PmpqcUJm",
        net_leverage: 0.0,
        open_ms: 1718990663035,
        orders: [
          {
            leverage: 0.353,
            order_type: "LONG",
            order_uuid: "2f52f452-b286-4f19-8a8d-d91de43c5830",
            price: 159.547,
            price_sources: [],
            processed_ms: 1718990661580,
          },
          {
            leverage: 0.307,
            order_type: "LONG",
            order_uuid: "b9a2029b-edd2-4545-8478-0d7677a9848e",
            price: 159.501,
            price_sources: [],
            processed_ms: 1718991410095,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "ef3b8667-5b67-4ff2-a728-6ba16bc72f82",
            price: 159.61849999999998,
            price_sources: [],
            processed_ms: 1719000085722,
          },
        ],
        position_type: "FLAT",
        position_uuid: "1c048a5a-02ab-491c-a6a2-379b0da611d1",
        return_at_close: 1.0003380702613371,
        trade_pair: ["USDJPY", "USD/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 159.60178165938862,
        close_ms: 1719002186172,
        current_return: 1.0005130931051334,
        initial_entry_price: 159.626,
        is_closed_position: true,
        miner_hotkey: "5FmqXG5YBU1Hke9jHD5FT41CUM9gVod7nFgYvbd7PmpqcUJm",
        net_leverage: 0.0,
        open_ms: 1719000116627,
        orders: [
          {
            leverage: 0.333,
            order_type: "LONG",
            order_uuid: "2bf7e251-f9ff-418a-bbde-fb31051218d0",
            price: 159.626,
            price_sources: [],
            processed_ms: 1719000116204,
          },
          {
            leverage: 0.354,
            order_type: "LONG",
            order_uuid: "c4a19cc5-1bc4-4e30-9eaf-48043e8f131c",
            price: 159.579,
            price_sources: [],
            processed_ms: 1719000937783,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "80a2123c-589c-448f-bab5-5e0f07913edf",
            price: 159.721,
            price_sources: [],
            processed_ms: 1719002186172,
          },
        ],
        position_type: "FLAT",
        position_uuid: "ef94382d-7ce7-4df0-b462-7ad853a9a9ac",
        return_at_close: 1.000464978430486,
        trade_pair: ["USDJPY", "USD/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 159.5721076923077,
        close_ms: 1719321697653,
        current_return: 1.0004517151122745,
        initial_entry_price: 159.698,
        is_closed_position: true,
        miner_hotkey: "5FmqXG5YBU1Hke9jHD5FT41CUM9gVod7nFgYvbd7PmpqcUJm",
        net_leverage: 0.0,
        open_ms: 1719002216664,
        orders: [
          {
            leverage: 0.381,
            order_type: "LONG",
            order_uuid: "5563ca1f-933e-4b65-87d7-8b038dcc91df",
            price: 159.698,
            price_sources: [],
            processed_ms: 1719002216566,
          },
          {
            leverage: 0.334,
            order_type: "LONG",
            order_uuid: "cfac7666-2928-4298-9f41-cb29b4c94f8c",
            price: 159.42849999999999,
            price_sources: [],
            processed_ms: 1719313240468,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "20fd3695-58a0-4202-81bd-dd622217c13c",
            price: 159.673,
            price_sources: [],
            processed_ms: 1719321697653,
          },
        ],
        position_type: "FLAT",
        position_uuid: "f5966fe6-c852-4af4-b132-ec98ed3d59d1",
        return_at_close: 1.000401642503933,
        trade_pair: ["USDJPY", "USD/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 159.61992730210017,
        close_ms: 1719324338991,
        current_return: 1.0004735058900154,
        initial_entry_price: 159.677,
        is_closed_position: true,
        miner_hotkey: "5FmqXG5YBU1Hke9jHD5FT41CUM9gVod7nFgYvbd7PmpqcUJm",
        net_leverage: 0.0,
        open_ms: 1719321727230,
        orders: [
          {
            leverage: 0.27,
            order_type: "LONG",
            order_uuid: "63572f70-5438-4c30-b39c-8dd7f58568c4",
            price: 159.677,
            price_sources: [],
            processed_ms: 1719321727211,
          },
          {
            leverage: 0.294,
            order_type: "LONG",
            order_uuid: "ab78467c-a6e4-45ba-be7a-469dcc7bbb33",
            price: 159.639,
            price_sources: [],
            processed_ms: 1719322508238,
          },
          {
            leverage: 0.379,
            order_type: "LONG",
            order_uuid: "c2eaba2f-2cd0-4e22-94b7-adc1284ce172",
            price: 159.601,
            price_sources: [],
            processed_ms: 1719322596247,
          },
          {
            leverage: 0.295,
            order_type: "LONG",
            order_uuid: "8a4fb166-7b41-43f9-8dc9-76ee04a7cdf7",
            price: 159.573,
            price_sources: [],
            processed_ms: 1719323513866,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "848a8e44-c634-44f7-acdf-41c9c7dd2293",
            price: 159.68099999999998,
            price_sources: [],
            processed_ms: 1719324338991,
          },
        ],
        position_type: "FLAT",
        position_uuid: "495f72ba-fe98-419c-a14f-189ce8d22c27",
        return_at_close: 1.000386804855995,
        trade_pair: ["USDJPY", "USD/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 159.6497432216906,
        close_ms: 1719329623077,
        current_return: 1.0003858238375714,
        initial_entry_price: 159.6765,
        is_closed_position: true,
        miner_hotkey: "5FmqXG5YBU1Hke9jHD5FT41CUM9gVod7nFgYvbd7PmpqcUJm",
        net_leverage: 0.0,
        open_ms: 1719324368229,
        orders: [
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "595a8efc-c88b-42bc-9aca-c56d5b8ececf",
            price: 159.6765,
            price_sources: [],
            processed_ms: 1719324368193,
          },
          {
            leverage: 0.377,
            order_type: "LONG",
            order_uuid: "d81789e7-7c98-46a2-8e52-433d80ceca05",
            price: 159.632,
            price_sources: [],
            processed_ms: 1719326098420,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "fc2febe8-ffda-4073-ae73-05529cd12c4c",
            price: 159.748,
            price_sources: [],
            processed_ms: 1719329623077,
          },
        ],
        position_type: "FLAT",
        position_uuid: "46f7227e-8237-42b1-a55f-88c85bde31f7",
        return_at_close: 1.000341916903763,
        trade_pair: ["USDJPY", "USD/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 159.6810481838399,
        close_ms: 1719361300823,
        current_return: 1.000514704225352,
        initial_entry_price: 159.75,
        is_closed_position: true,
        miner_hotkey: "5FmqXG5YBU1Hke9jHD5FT41CUM9gVod7nFgYvbd7PmpqcUJm",
        net_leverage: 0.0,
        open_ms: 1719329652042,
        orders: [
          {
            leverage: 0.35,
            order_type: "LONG",
            order_uuid: "435398a8-2dae-45bb-a40f-b05ff7a61eb1",
            price: 159.75,
            price_sources: [],
            processed_ms: 1719329652007,
          },
          {
            leverage: 0.257,
            order_type: "LONG",
            order_uuid: "ff4dafd6-8339-4914-b4b4-127ea7dc138e",
            price: 159.699,
            price_sources: [],
            processed_ms: 1719329892465,
          },
          {
            leverage: 0.367,
            order_type: "LONG",
            order_uuid: "501a7b42-e404-4026-9b0b-90b395b12e73",
            price: 159.6605,
            price_sources: [],
            processed_ms: 1719335263407,
          },
          {
            leverage: 0.375,
            order_type: "LONG",
            order_uuid: "d77d464b-9adc-457d-ab00-5fe5d2318a12",
            price: 159.6245,
            price_sources: [],
            processed_ms: 1719342116810,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "5e8a4968-39ee-46d9-a463-63f1366b0f63",
            price: 159.742,
            price_sources: [],
            processed_ms: 1719361300823,
          },
        ],
        position_type: "FLAT",
        position_uuid: "d663f07f-89ba-4ac8-97fe-07dd6ecf9fbe",
        return_at_close: 1.000420225621832,
        trade_pair: ["USDJPY", "USD/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 159.71371911037892,
        close_ms: 1719365475124,
        current_return: 1.0004418627945049,
        initial_entry_price: 159.7385,
        is_closed_position: true,
        miner_hotkey: "5FmqXG5YBU1Hke9jHD5FT41CUM9gVod7nFgYvbd7PmpqcUJm",
        net_leverage: 0.0,
        open_ms: 1719361330909,
        orders: [
          {
            leverage: 0.331,
            order_type: "LONG",
            order_uuid: "3b54947f-ea75-41c8-81d1-15039d8e3fb6",
            price: 159.7385,
            price_sources: [],
            processed_ms: 1719361330886,
          },
          {
            leverage: 0.276,
            order_type: "LONG",
            order_uuid: "ce97db4a-d316-40f6-a9ae-3599f6c9bded",
            price: 159.684,
            price_sources: [],
            processed_ms: 1719363178355,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "d05a2e1a-44ce-44e4-a87b-0fdeee27fb21",
            price: 159.83,
            price_sources: [],
            processed_ms: 1719365475124,
          },
        ],
        position_type: "FLAT",
        position_uuid: "3fecd782-c6b7-4a58-a9a9-a7f3467f7001",
        return_at_close: 1.0003993540197547,
        trade_pair: ["USDJPY", "USD/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 159.78836184210525,
        close_ms: 1719385315684,
        current_return: 1.0003659671909608,
        initial_entry_price: 159.834,
        is_closed_position: true,
        miner_hotkey: "5FmqXG5YBU1Hke9jHD5FT41CUM9gVod7nFgYvbd7PmpqcUJm",
        net_leverage: 0.0,
        open_ms: 1719365504169,
        orders: [
          {
            leverage: 0.254,
            order_type: "LONG",
            order_uuid: "d140e81a-6884-47da-8510-b913196327d4",
            price: 159.834,
            price_sources: [],
            processed_ms: 1719365504130,
          },
          {
            leverage: 0.35,
            order_type: "LONG",
            order_uuid: "94ec7198-4319-463a-8a09-d8d5cd57b6b0",
            price: 159.789,
            price_sources: [],
            processed_ms: 1719366866539,
          },
          {
            leverage: 0.308,
            order_type: "LONG",
            order_uuid: "8bc27fee-e773-4d59-943c-23fc93e05f1a",
            price: 159.75,
            price_sources: [],
            processed_ms: 1719377619204,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "ad4d4ecb-51c4-45b8-8053-ab1176e0ede8",
            price: 159.85250000000002,
            price_sources: [],
            processed_ms: 1719385315684,
          },
        ],
        position_type: "FLAT",
        position_uuid: "bc017318-66ff-4577-961a-1c63dff7f398",
        return_at_close: 1.0003021038276154,
        trade_pair: ["USDJPY", "USD/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 159.8545,
        close_ms: 1719394524228,
        current_return: 1.0004189903943899,
        initial_entry_price: 159.8545,
        is_closed_position: true,
        miner_hotkey: "5FmqXG5YBU1Hke9jHD5FT41CUM9gVod7nFgYvbd7PmpqcUJm",
        net_leverage: 0.0,
        open_ms: 1719385344988,
        orders: [
          {
            leverage: 0.367,
            order_type: "LONG",
            order_uuid: "45bc618a-c8bc-4305-82ec-0046e33885fc",
            price: 159.8545,
            price_sources: [],
            processed_ms: 1719385344838,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "a6130969-b9ca-4c38-93bf-a8e8b8370888",
            price: 160.037,
            price_sources: [],
            processed_ms: 1719394524228,
          },
        ],
        position_type: "FLAT",
        position_uuid: "066414ae-ee1e-4e6b-af81-c746b4eab943",
        return_at_close: 1.0003932896305268,
        trade_pair: ["USDJPY", "USD/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 159.958,
        close_ms: 1719396741348,
        current_return: 1.000408882331612,
        initial_entry_price: 159.958,
        is_closed_position: true,
        miner_hotkey: "5FmqXG5YBU1Hke9jHD5FT41CUM9gVod7nFgYvbd7PmpqcUJm",
        net_leverage: 0.0,
        open_ms: 1719394554981,
        orders: [
          {
            leverage: 0.394,
            order_type: "LONG",
            order_uuid: "667ae96d-1f56-45d2-93b3-9c7322c63087",
            price: 159.958,
            price_sources: [],
            processed_ms: 1719394554694,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "74fc06f8-08ce-4626-a482-1c9afbe800c6",
            price: 160.124,
            price_sources: [],
            processed_ms: 1719396741348,
          },
        ],
        position_type: "FLAT",
        position_uuid: "51a88ca5-4c61-468e-bbf8-8d05120a1fb1",
        return_at_close: 1.0003812910546372,
        trade_pair: ["USDJPY", "USD/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 160.12201335311573,
        close_ms: 1719399264705,
        current_return: 1.0003955738590418,
        initial_entry_price: 160.1395,
        is_closed_position: true,
        miner_hotkey: "5FmqXG5YBU1Hke9jHD5FT41CUM9gVod7nFgYvbd7PmpqcUJm",
        net_leverage: 0.0,
        open_ms: 1719396771003,
        orders: [
          {
            leverage: 0.39,
            order_type: "LONG",
            order_uuid: "c41096e4-4416-4b17-b0d7-47c2a5cea3d9",
            price: 160.1395,
            price_sources: [],
            processed_ms: 1719396770784,
          },
          {
            leverage: 0.284,
            order_type: "LONG",
            order_uuid: "b13ef8d3-fb69-43cb-95e6-a90449f735df",
            price: 160.098,
            price_sources: [],
            processed_ms: 1719396951793,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "b4570153-dd76-44ab-8301-9bc215485f93",
            price: 160.216,
            price_sources: [],
            processed_ms: 1719399264705,
          },
        ],
        position_type: "FLAT",
        position_uuid: "39c6ef43-2c5f-4dc7-b443-de388a6fefc7",
        return_at_close: 1.000348375195867,
        trade_pair: ["USDJPY", "USD/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 160.24300395256915,
        close_ms: 1719403088207,
        current_return: 1.000461732131158,
        initial_entry_price: 160.265,
        is_closed_position: true,
        miner_hotkey: "5FmqXG5YBU1Hke9jHD5FT41CUM9gVod7nFgYvbd7PmpqcUJm",
        net_leverage: 0.0,
        open_ms: 1719399294113,
        orders: [
          {
            leverage: 0.388,
            order_type: "LONG",
            order_uuid: "c3465b6a-115a-44e3-83f7-2efae269302a",
            price: 160.265,
            price_sources: [],
            processed_ms: 1719399293970,
          },
          {
            leverage: 0.371,
            order_type: "LONG",
            order_uuid: "05fdb284-e88b-48b9-b35c-d2a827134866",
            price: 160.22,
            price_sources: [],
            processed_ms: 1719399981431,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "f934cee2-a3dd-40c7-a314-4a804ec8549c",
            price: 160.34050000000002,
            price_sources: [],
            processed_ms: 1719403088207,
          },
        ],
        position_type: "FLAT",
        position_uuid: "f94b1a46-275b-4322-b410-513c6672d007",
        return_at_close: 1.0004085775993299,
        trade_pair: ["USDJPY", "USD/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 160.20258345864661,
        close_ms: 1719407481307,
        current_return: 1.000741754403473,
        initial_entry_price: 160.328,
        is_closed_position: true,
        miner_hotkey: "5FmqXG5YBU1Hke9jHD5FT41CUM9gVod7nFgYvbd7PmpqcUJm",
        net_leverage: 0.0,
        open_ms: 1719403122070,
        orders: [
          {
            leverage: 0.35,
            order_type: "LONG",
            order_uuid: "e639f14e-9169-42d5-b67f-edde2e23af46",
            price: 160.328,
            price_sources: [],
            processed_ms: 1719403121944,
          },
          {
            leverage: 0.362,
            order_type: "LONG",
            order_uuid: "14820202-4fc4-4354-b29b-9e4b2735fcf3",
            price: 160.2845,
            price_sources: [],
            processed_ms: 1719406330089,
          },
          {
            leverage: 0.256,
            order_type: "LONG",
            order_uuid: "2626fccb-95c9-4c71-ae84-b0ba0311c600",
            price: 160.177,
            price_sources: [],
            processed_ms: 1719407111154,
          },
          {
            leverage: 0.362,
            order_type: "LONG",
            order_uuid: "1d50fd9e-1967-4fa2-8fe0-32ccc98b5fa4",
            price: 160.01749999999998,
            price_sources: [],
            processed_ms: 1719407140899,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "fe751032-cb66-44a9-a5b8-f2221341e585",
            price: 160.292,
            price_sources: [],
            processed_ms: 1719407481307,
          },
        ],
        position_type: "FLAT",
        position_uuid: "7a4d4ef5-50ef-43d0-a11b-43f5408e91d3",
        return_at_close: 1.000648585346138,
        trade_pair: ["USDJPY", "USD/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 160.2645,
        close_ms: 1719408287432,
        current_return: 1.0004805680609243,
        initial_entry_price: 160.2645,
        is_closed_position: true,
        miner_hotkey: "5FmqXG5YBU1Hke9jHD5FT41CUM9gVod7nFgYvbd7PmpqcUJm",
        net_leverage: 0.0,
        open_ms: 1719407510994,
        orders: [
          {
            leverage: 0.388,
            order_type: "LONG",
            order_uuid: "1c70e4d5-382c-4edd-b2d0-bf781b3a04d4",
            price: 160.2645,
            price_sources: [],
            processed_ms: 1719407510836,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "dbbd8576-b7c0-4d0f-8137-75b2f7f6ae84",
            price: 160.46300000000002,
            price_sources: [],
            processed_ms: 1719408287432,
          },
        ],
        position_type: "FLAT",
        position_uuid: "f0a79460-5948-4f34-8301-3caa13e8d726",
        return_at_close: 1.0004533950086958,
        trade_pair: ["USDJPY", "USD/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 160.4435,
        close_ms: 1719408632532,
        current_return: 1.0003799000894396,
        initial_entry_price: 160.4435,
        is_closed_position: true,
        miner_hotkey: "5FmqXG5YBU1Hke9jHD5FT41CUM9gVod7nFgYvbd7PmpqcUJm",
        net_leverage: 0.0,
        open_ms: 1719408315936,
        orders: [
          {
            leverage: 0.387,
            order_type: "LONG",
            order_uuid: "81490790-4590-4490-9235-e264b3708a09",
            price: 160.4435,
            price_sources: [],
            processed_ms: 1719408315821,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "561a2b25-5ee8-43b8-b023-8a3d3567e1bc",
            price: 160.601,
            price_sources: [],
            processed_ms: 1719408632532,
          },
        ],
        position_type: "FLAT",
        position_uuid: "cf493cf8-270c-4c9c-a7ff-f931058b1a09",
        return_at_close: 1.0003527997979462,
        trade_pair: ["USDJPY", "USD/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 160.49900084246002,
        close_ms: 1719411729122,
        current_return: 1.0004286950782104,
        initial_entry_price: 160.592,
        is_closed_position: true,
        miner_hotkey: "5FmqXG5YBU1Hke9jHD5FT41CUM9gVod7nFgYvbd7PmpqcUJm",
        net_leverage: 0.0,
        open_ms: 1719408662339,
        orders: [
          {
            leverage: 0.292,
            order_type: "LONG",
            order_uuid: "fb50e08e-bf5b-4e35-94a1-28da296f9ba3",
            price: 160.592,
            price_sources: [],
            processed_ms: 1719408662110,
          },
          {
            leverage: 0.382,
            order_type: "LONG",
            order_uuid: "2db2688d-af61-42f3-996e-0192a25ee4f3",
            price: 160.506,
            price_sources: [],
            processed_ms: 1719408781962,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "6bc8dcbc-9737-4a27-9e93-8d4a3f378a7c",
            price: 160.467,
            price_sources: [],
            processed_ms: 1719409282794,
          },
          {
            leverage: 0.263,
            order_type: "LONG",
            order_uuid: "c1df5bee-c680-4299-8256-673e5fa5279f",
            price: 160.416,
            price_sources: [],
            processed_ms: 1719410289410,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "2f9aaa68-19f6-4355-8bc0-806b2bd00ba4",
            price: 160.55700000000002,
            price_sources: [],
            processed_ms: 1719411729122,
          },
        ],
        position_type: "FLAT",
        position_uuid: "67cd74f2-b5b0-4225-a07e-e7d132c91e6c",
        return_at_close: 1.0003455694579364,
        trade_pair: ["USDJPY", "USD/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 160.5732,
        close_ms: 1719414230207,
        current_return: 1.0003844606753465,
        initial_entry_price: 160.599,
        is_closed_position: true,
        miner_hotkey: "5FmqXG5YBU1Hke9jHD5FT41CUM9gVod7nFgYvbd7PmpqcUJm",
        net_leverage: 0.0,
        open_ms: 1719411758775,
        orders: [
          {
            leverage: 0.336,
            order_type: "LONG",
            order_uuid: "74e9e539-b3c1-4401-8961-f9faaa26e08b",
            price: 160.599,
            price_sources: [],
            processed_ms: 1719411758738,
          },
          {
            leverage: 0.344,
            order_type: "LONG",
            order_uuid: "9d2552d5-f6bf-44df-8e18-15fdecb9a2b3",
            price: 160.548,
            price_sources: [],
            processed_ms: 1719412125312,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "df7414ee-a1ec-4b7c-bf13-cf71856e3e6d",
            price: 160.664,
            price_sources: [],
            processed_ms: 1719414230207,
          },
        ],
        position_type: "FLAT",
        position_uuid: "7e843a09-e675-4987-bf0f-110b6b761738",
        return_at_close: 1.0003368423750183,
        trade_pair: ["USDJPY", "USD/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 160.62975367047306,
        close_ms: 1719426200357,
        current_return: 1.0003596091002458,
        initial_entry_price: 160.655,
        is_closed_position: true,
        miner_hotkey: "5FmqXG5YBU1Hke9jHD5FT41CUM9gVod7nFgYvbd7PmpqcUJm",
        net_leverage: 0.0,
        open_ms: 1719414260424,
        orders: [
          {
            leverage: 0.321,
            order_type: "LONG",
            order_uuid: "95041150-5911-498e-a738-f43a9ed8db4e",
            price: 160.655,
            price_sources: [],
            processed_ms: 1719414260258,
          },
          {
            leverage: 0.292,
            order_type: "LONG",
            order_uuid: "0ecfbca0-5b2a-431d-9c46-78ae4a7888b1",
            price: 160.602,
            price_sources: [],
            processed_ms: 1719418955664,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "c30f1a48-8cfa-4242-bad3-a8b573bc6528",
            price: 160.724,
            price_sources: [],
            processed_ms: 1719426200357,
          },
        ],
        position_type: "FLAT",
        position_uuid: "9fb26c9d-0469-4e51-85b7-27163994afc8",
        return_at_close: 1.0003166836694193,
        trade_pair: ["USDJPY", "USD/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 160.70669686411148,
        close_ms: 1719430428278,
        current_return: 1.0004189241317523,
        initial_entry_price: 160.726,
        is_closed_position: true,
        miner_hotkey: "5FmqXG5YBU1Hke9jHD5FT41CUM9gVod7nFgYvbd7PmpqcUJm",
        net_leverage: 0.0,
        open_ms: 1719426232264,
        orders: [
          {
            leverage: 0.297,
            order_type: "LONG",
            order_uuid: "990f360b-76f8-4b96-a264-de527f4d8976",
            price: 160.726,
            price_sources: [],
            processed_ms: 1719426231960,
          },
          {
            leverage: 0.277,
            order_type: "LONG",
            order_uuid: "32bcb8a8-4fd6-460d-bd6a-5ff183018aad",
            price: 160.686,
            price_sources: [],
            processed_ms: 1719427368024,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "8e55c2f0-0a03-41c3-9e06-fd9e27a6add8",
            price: 160.824,
            price_sources: [],
            processed_ms: 1719430428278,
          },
        ],
        position_type: "FLAT",
        position_uuid: "8174df1c-bbc1-4f64-b158-5d063868e7fd",
        return_at_close: 1.0003787272993807,
        trade_pair: ["USDJPY", "USD/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 160.6857869930581,
        close_ms: 1719450027592,
        current_return: 1.000378039074256,
        initial_entry_price: 160.822,
        is_closed_position: true,
        miner_hotkey: "5FmqXG5YBU1Hke9jHD5FT41CUM9gVod7nFgYvbd7PmpqcUJm",
        net_leverage: 0.0,
        open_ms: 1719430460883,
        orders: [
          {
            leverage: 0.39,
            order_type: "LONG",
            order_uuid: "ef59cdea-fa41-4b3c-a963-af27855fcbc0",
            price: 160.822,
            price_sources: [],
            processed_ms: 1719430460841,
          },
          {
            leverage: 0.369,
            order_type: "LONG",
            order_uuid: "6cd2aa96-b591-4beb-ba87-512c1d831b1d",
            price: 160.7685,
            price_sources: [],
            processed_ms: 1719431143207,
          },
          {
            leverage: 0.383,
            order_type: "LONG",
            order_uuid: "7079445f-e2b8-4786-9952-270c3fa128d9",
            price: 160.776,
            price_sources: [],
            processed_ms: 1719435860320,
          },
          {
            leverage: 0.316,
            order_type: "LONG",
            order_uuid: "974b6e6b-54d7-4129-9eb7-0d726f329770",
            price: 160.738,
            price_sources: [],
            processed_ms: 1719436102443,
          },
          {
            leverage: 0.393,
            order_type: "LONG",
            order_uuid: "1e5e585a-8a7a-4ae6-a6e5-1a9392386c18",
            price: 160.6395,
            price_sources: [],
            processed_ms: 1719443793112,
          },
          {
            leverage: 0.254,
            order_type: "LONG",
            order_uuid: "a3c5b37a-04ef-4e04-9da7-eaded4218d16",
            price: 160.599,
            price_sources: [],
            processed_ms: 1719443908054,
          },
          {
            leverage: 0.313,
            order_type: "LONG",
            order_uuid: "587b2c7c-00f9-4571-bb66-733d4d0de4e6",
            price: 160.555,
            price_sources: [],
            processed_ms: 1719446790641,
          },
          {
            leverage: 0.319,
            order_type: "LONG",
            order_uuid: "af477d15-cb6f-45ed-883e-0caa574521f0",
            price: 160.518,
            price_sources: [],
            processed_ms: 1719446852496,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "c7b122e2-f4f5-4a7d-92c9-1aca54f781a1",
            price: 160.708,
            price_sources: [],
            processed_ms: 1719450027592,
          },
        ],
        position_type: "FLAT",
        position_uuid: "898c5b60-ad0b-4b93-bd5c-5a47a3098b0e",
        return_at_close: 1.0001863766457497,
        trade_pair: ["USDJPY", "USD/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 160.52594633058985,
        close_ms: 1719474056352,
        current_return: 1.0005090320212064,
        initial_entry_price: 160.706,
        is_closed_position: true,
        miner_hotkey: "5FmqXG5YBU1Hke9jHD5FT41CUM9gVod7nFgYvbd7PmpqcUJm",
        net_leverage: 0.0,
        open_ms: 1719450056980,
        orders: [
          {
            leverage: 0.357,
            order_type: "LONG",
            order_uuid: "9c6b0c11-4730-4f6d-8bd3-f363179d0679",
            price: 160.706,
            price_sources: [],
            processed_ms: 1719450056878,
          },
          {
            leverage: 0.318,
            order_type: "LONG",
            order_uuid: "addad7af-04fa-4257-ab37-8bb3e929ab8d",
            price: 160.654,
            price_sources: [],
            processed_ms: 1719450285200,
          },
          {
            leverage: 0.393,
            order_type: "LONG",
            order_uuid: "d09a277b-9a6a-4c5e-a2fe-5001f4d879e5",
            price: 160.60649999999998,
            price_sources: [],
            processed_ms: 1719450702193,
          },
          {
            leverage: 0.298,
            order_type: "LONG",
            order_uuid: "bc226242-8391-4ea2-abd8-ad619ffa9112",
            price: 160.561,
            price_sources: [],
            processed_ms: 1719451817050,
          },
          {
            leverage: 0.376,
            order_type: "LONG",
            order_uuid: "4932c07c-7d4e-41d5-9926-c130bf8a94c7",
            price: 160.517,
            price_sources: [],
            processed_ms: 1719453029895,
          },
          {
            leverage: 0.266,
            order_type: "LONG",
            order_uuid: "a7e3fb1e-8fac-48f9-92a2-0545fe42ba1b",
            price: 160.459,
            price_sources: [],
            processed_ms: 1719453159043,
          },
          {
            leverage: 0.352,
            order_type: "LONG",
            order_uuid: "0cd10543-b9d6-4762-9051-0aaa690535e9",
            price: 160.4295,
            price_sources: [],
            processed_ms: 1719453190612,
          },
          {
            leverage: 0.27,
            order_type: "LONG",
            order_uuid: "bf916944-b900-40ba-88be-05abf28b5202",
            price: 160.387,
            price_sources: [],
            processed_ms: 1719454140546,
          },
          {
            leverage: 0.286,
            order_type: "LONG",
            order_uuid: "b46c6b4c-ea13-43f3-acee-3d1b88f934ee",
            price: 160.3355,
            price_sources: [],
            processed_ms: 1719461196516,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "9a3cae3d-d785-47f4-b017-65e2d4b49268",
            price: 160.554,
            price_sources: [],
            processed_ms: 1719474056352,
          },
        ],
        position_type: "FLAT",
        position_uuid: "bd4ad1d4-0e7e-4598-b64a-97f8fc2dbb2b",
        return_at_close: 1.0003048081175903,
        trade_pair: ["USDJPY", "USD/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 160.4731044034091,
        close_ms: 1719487485292,
        current_return: 1.0004551167860478,
        initial_entry_price: 160.55,
        is_closed_position: true,
        miner_hotkey: "5FmqXG5YBU1Hke9jHD5FT41CUM9gVod7nFgYvbd7PmpqcUJm",
        net_leverage: 0.0,
        open_ms: 1719474088426,
        orders: [
          {
            leverage: 0.26,
            order_type: "LONG",
            order_uuid: "8c4df662-3ab0-40c1-b886-4ceb15fec903",
            price: 160.55,
            price_sources: [],
            processed_ms: 1719474086431,
          },
          {
            leverage: 0.351,
            order_type: "LONG",
            order_uuid: "3c52ea44-0d6a-41dd-9bac-090abacf1aa9",
            price: 160.505,
            price_sources: [],
            processed_ms: 1719474540570,
          },
          {
            leverage: 0.398,
            order_type: "LONG",
            order_uuid: "b297d4b2-3f7b-4807-ae3a-82633e372d1c",
            price: 160.456,
            price_sources: [],
            processed_ms: 1719482590032,
          },
          {
            leverage: 0.399,
            order_type: "LONG",
            order_uuid: "122e3bae-d3cb-48c6-8ec1-677678d0e855",
            price: 160.412,
            price_sources: [],
            processed_ms: 1719483185292,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "03e3d41a-e0f9-4e56-ab80-37f07336d9c1",
            price: 160.525,
            price_sources: [],
            processed_ms: 1719487485292,
          },
        ],
        position_type: "FLAT",
        position_uuid: "19ae292e-76c1-41be-b463-082098b90806",
        return_at_close: 1.0003565119297375,
        trade_pair: ["USDJPY", "USD/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 160.4303754197448,
        close_ms: 1719495461637,
        current_return: 1.0003768072608126,
        initial_entry_price: 160.533,
        is_closed_position: true,
        miner_hotkey: "5FmqXG5YBU1Hke9jHD5FT41CUM9gVod7nFgYvbd7PmpqcUJm",
        net_leverage: 0.0,
        open_ms: 1719487521880,
        orders: [
          {
            leverage: 0.266,
            order_type: "LONG",
            order_uuid: "c35fae0a-952a-4e2b-8619-81aeaca5b580",
            price: 160.533,
            price_sources: [],
            processed_ms: 1719487519673,
          },
          {
            leverage: 0.351,
            order_type: "LONG",
            order_uuid: "b1e1be45-27e6-4cc6-b364-238d84b2dc34",
            price: 160.477,
            price_sources: [],
            processed_ms: 1719488536530,
          },
          {
            leverage: 0.271,
            order_type: "LONG",
            order_uuid: "3f053bd7-2874-47ef-ba02-f749a8a98954",
            price: 160.43,
            price_sources: [],
            processed_ms: 1719491393050,
          },
          {
            leverage: 0.253,
            order_type: "LONG",
            order_uuid: "d668534b-7965-4551-befe-73d7ea298860",
            price: 160.366,
            price_sources: [],
            processed_ms: 1719491447065,
          },
          {
            leverage: 0.348,
            order_type: "LONG",
            order_uuid: "cc1917af-0df1-4cf8-af02-e28f28798631",
            price: 160.352,
            price_sources: [],
            processed_ms: 1719492206344,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "300c216d-23f6-4892-af87-9179c801009b",
            price: 160.471,
            price_sources: [],
            processed_ms: 1719495461637,
          },
        ],
        position_type: "FLAT",
        position_uuid: "379f7953-11d9-4ff4-89be-f2e03ac8b619",
        return_at_close: 1.0002725379861916,
        trade_pair: ["USDJPY", "USD/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 160.45707918552034,
        close_ms: 1719497302856,
        current_return: 1.0003508479487524,
        initial_entry_price: 160.4755,
        is_closed_position: true,
        miner_hotkey: "5FmqXG5YBU1Hke9jHD5FT41CUM9gVod7nFgYvbd7PmpqcUJm",
        net_leverage: 0.0,
        open_ms: 1719495495444,
        orders: [
          {
            leverage: 0.309,
            order_type: "LONG",
            order_uuid: "5945a80a-7215-48f1-9a0c-db1851811f5d",
            price: 160.4755,
            price_sources: [],
            processed_ms: 1719495493911,
          },
          {
            leverage: 0.354,
            order_type: "LONG",
            order_uuid: "276c8580-93e6-4e6b-aad7-a6770528d16b",
            price: 160.441,
            price_sources: [],
            processed_ms: 1719496874056,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "d99498e8-5946-4d09-944f-32b732203653",
            price: 160.542,
            price_sources: [],
            processed_ms: 1719497302856,
          },
        ],
        position_type: "FLAT",
        position_uuid: "660ff2a9-fbb9-4fab-9504-68933106ad49",
        return_at_close: 1.000304421665899,
        trade_pair: ["USDJPY", "USD/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 160.51432854578098,
        close_ms: 1719498528356,
        current_return: 1.0004082785685364,
        initial_entry_price: 160.535,
        is_closed_position: true,
        miner_hotkey: "5FmqXG5YBU1Hke9jHD5FT41CUM9gVod7nFgYvbd7PmpqcUJm",
        net_leverage: 0.0,
        open_ms: 1719497332927,
        orders: [
          {
            leverage: 0.254,
            order_type: "LONG",
            order_uuid: "4feddc5b-5a80-4ef5-80d0-374a0f70b679",
            price: 160.535,
            price_sources: [],
            processed_ms: 1719497331401,
          },
          {
            leverage: 0.303,
            order_type: "LONG",
            order_uuid: "e9969a3e-6859-44f9-af82-f66cccb90cbd",
            price: 160.497,
            price_sources: [],
            processed_ms: 1719497672386,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "4d10a3b6-3e52-40b4-8209-94ed372f3165",
            price: 160.632,
            price_sources: [],
            processed_ms: 1719498528356,
          },
        ],
        position_type: "FLAT",
        position_uuid: "f6448123-d74d-416d-965e-0c10dbfeebb6",
        return_at_close: 1.000369272649755,
        trade_pair: ["USDJPY", "USD/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 160.58128520371693,
        close_ms: 1719500850579,
        current_return: 1.0003110177404295,
        initial_entry_price: 160.65,
        is_closed_position: true,
        miner_hotkey: "5FmqXG5YBU1Hke9jHD5FT41CUM9gVod7nFgYvbd7PmpqcUJm",
        net_leverage: 0.0,
        open_ms: 1719498564085,
        orders: [
          {
            leverage: 0.397,
            order_type: "LONG",
            order_uuid: "eb097cc4-1eae-4960-89f5-e85cd6d30744",
            price: 160.65,
            price_sources: [],
            processed_ms: 1719498558677,
          },
          {
            leverage: 0.269,
            order_type: "LONG",
            order_uuid: "cb85516e-76a4-467b-90ba-d98285442811",
            price: 160.607,
            price_sources: [],
            processed_ms: 1719499117502,
          },
          {
            leverage: 0.362,
            order_type: "LONG",
            order_uuid: "32aa6ad0-2eb8-4a24-b164-67bba8a65bfb",
            price: 160.565,
            price_sources: [],
            processed_ms: 1719499800259,
          },
          {
            leverage: 0.371,
            order_type: "LONG",
            order_uuid: "4027bde7-ec3b-4203-ad8c-9cd19c5c1053",
            price: 160.505,
            price_sources: [],
            processed_ms: 1719499865924,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "f74c9667-ac12-4c65-8911-a9d0f3a7c6e6",
            price: 160.617,
            price_sources: [],
            processed_ms: 1719500850579,
          },
        ],
        position_type: "FLAT",
        position_uuid: "ee0d0424-c5ea-4b07-931f-85e9ed74222c",
        return_at_close: 1.0002130572824621,
        trade_pair: ["USDJPY", "USD/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 160.62,
        close_ms: 1719535917892,
        current_return: 1.0004001805503673,
        initial_entry_price: 160.62,
        is_closed_position: true,
        miner_hotkey: "5FmqXG5YBU1Hke9jHD5FT41CUM9gVod7nFgYvbd7PmpqcUJm",
        net_leverage: 0.0,
        open_ms: 1719500883745,
        orders: [
          {
            leverage: 0.323,
            order_type: "LONG",
            order_uuid: "6f80472a-72c9-40cc-8f9f-b46a49b7f58e",
            price: 160.62,
            price_sources: [],
            processed_ms: 1719500881059,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "0d7ae5a1-00cf-4300-bc1e-173097f9267e",
            price: 160.819,
            price_sources: [],
            processed_ms: 1719535917892,
          },
        ],
        position_type: "FLAT",
        position_uuid: "6e1c636f-6c07-4704-b7e4-5eac22c5366b",
        return_at_close: 1.000377561502285,
        trade_pair: ["USDJPY", "USD/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 160.8145,
        close_ms: 1719536363406,
        current_return: 1.000400281069182,
        initial_entry_price: 160.8145,
        is_closed_position: true,
        miner_hotkey: "5FmqXG5YBU1Hke9jHD5FT41CUM9gVod7nFgYvbd7PmpqcUJm",
        net_leverage: 0.0,
        open_ms: 1719535946901,
        orders: [
          {
            leverage: 0.258,
            order_type: "LONG",
            order_uuid: "97e9730f-b41a-4870-87b9-30698d1eb888",
            price: 160.8145,
            price_sources: [],
            processed_ms: 1719535946865,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "ae878f34-498f-4aa8-894a-7d709e511feb",
            price: 161.064,
            price_sources: [],
            processed_ms: 1719536363406,
          },
        ],
        position_type: "FLAT",
        position_uuid: "65d9f5d1-2fe5-469c-8fd1-cf737afa60aa",
        return_at_close: 1.000382213840106,
        trade_pair: ["USDJPY", "USD/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 161.0555642909625,
        close_ms: 1719537916336,
        current_return: 1.00057809542134,
        initial_entry_price: 161.117,
        is_closed_position: true,
        miner_hotkey: "5FmqXG5YBU1Hke9jHD5FT41CUM9gVod7nFgYvbd7PmpqcUJm",
        net_leverage: 0.0,
        open_ms: 1719536393626,
        orders: [
          {
            leverage: 0.336,
            order_type: "LONG",
            order_uuid: "8199e576-b73c-4f2c-b336-ab9b16446879",
            price: 161.117,
            price_sources: [],
            processed_ms: 1719536393566,
          },
          {
            leverage: 0.293,
            order_type: "LONG",
            order_uuid: "55905aa4-9468-4e22-83c6-b156b047935b",
            price: 161.079,
            price_sources: [],
            processed_ms: 1719536424933,
          },
          {
            leverage: 0.36,
            order_type: "LONG",
            order_uuid: "cee9b6eb-e9e5-4a17-8100-c2b988cfe8f6",
            price: 161.05,
            price_sources: [],
            processed_ms: 1719536551467,
          },
          {
            leverage: 0.372,
            order_type: "LONG",
            order_uuid: "35b20de4-0c0f-466d-b6da-a2efa7f5b270",
            price: 160.987,
            price_sources: [],
            processed_ms: 1719536653396,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "9940d5e1-3211-48a0-ab8c-ccf0b519467b",
            price: 161.124,
            price_sources: [],
            processed_ms: 1719537916336,
          },
        ],
        position_type: "FLAT",
        position_uuid: "2401772b-1bd9-4f51-a17c-7a53321c7ce0",
        return_at_close: 1.0004827703461892,
        trade_pair: ["USDJPY", "USD/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 160.97625600686501,
        close_ms: 1719547445493,
        current_return: 1.000615664647317,
        initial_entry_price: 161.108,
        is_closed_position: true,
        miner_hotkey: "5FmqXG5YBU1Hke9jHD5FT41CUM9gVod7nFgYvbd7PmpqcUJm",
        net_leverage: 0.0,
        open_ms: 1719537944974,
        orders: [
          {
            leverage: 0.397,
            order_type: "LONG",
            order_uuid: "c3fa93e9-3e83-43ec-aa63-2e955f284b12",
            price: 161.108,
            price_sources: [],
            processed_ms: 1719537944932,
          },
          {
            leverage: 0.268,
            order_type: "LONG",
            order_uuid: "cafeb791-2b23-4199-9f84-983018f319eb",
            price: 160.991,
            price_sources: [],
            processed_ms: 1719538862370,
          },
          {
            leverage: 0.328,
            order_type: "LONG",
            order_uuid: "eb6dee3b-9fcf-4a64-a682-aedc692ee8ae",
            price: 160.9855,
            price_sources: [],
            processed_ms: 1719539276595,
          },
          {
            leverage: 0.358,
            order_type: "LONG",
            order_uuid: "3734d6ab-c55f-4041-96ae-437e4ec27ffe",
            price: 160.949,
            price_sources: [],
            processed_ms: 1719540874615,
          },
          {
            leverage: 0.397,
            order_type: "LONG",
            order_uuid: "bc59f802-ba61-440b-a390-46a44eaff41e",
            price: 160.8515,
            price_sources: [],
            processed_ms: 1719542193808,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "34e8ca29-9d35-4918-848a-2ecc384d7043",
            price: 161.033,
            price_sources: [],
            processed_ms: 1719547445493,
          },
        ],
        position_type: "FLAT",
        position_uuid: "0dfd7b5f-286a-4467-8764-a286678f2e48",
        return_at_close: 1.0004932293145907,
        trade_pair: ["USDJPY", "USD/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 161.00890566037737,
        close_ms: 1719548612447,
        current_return: 1.0003768629837548,
        initial_entry_price: 161.032,
        is_closed_position: true,
        miner_hotkey: "5FmqXG5YBU1Hke9jHD5FT41CUM9gVod7nFgYvbd7PmpqcUJm",
        net_leverage: 0.0,
        open_ms: 1719547476965,
        orders: [
          {
            leverage: 0.277,
            order_type: "LONG",
            order_uuid: "594317de-cafb-47d2-9b82-0849df7a3c5b",
            price: 161.032,
            price_sources: [],
            processed_ms: 1719547476807,
          },
          {
            leverage: 0.306,
            order_type: "LONG",
            order_uuid: "4012d053-1bef-4f35-b18e-4ddf95ed95fb",
            price: 160.988,
            price_sources: [],
            processed_ms: 1719547617105,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "1bc4c5a9-a059-401d-a1d5-2ec46ebe24f1",
            price: 161.113,
            price_sources: [],
            processed_ms: 1719548612447,
          },
        ],
        position_type: "FLAT",
        position_uuid: "1f1ebc99-cd8b-4786-9825-bf187188f989",
        return_at_close: 1.0003360376039765,
        trade_pair: ["USDJPY", "USD/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 160.9986113671275,
        close_ms: 1719558220038,
        current_return: 1.0005684052735466,
        initial_entry_price: 161.106,
        is_closed_position: true,
        miner_hotkey: "5FmqXG5YBU1Hke9jHD5FT41CUM9gVod7nFgYvbd7PmpqcUJm",
        net_leverage: 0.0,
        open_ms: 1719548641772,
        orders: [
          {
            leverage: 0.308,
            order_type: "LONG",
            order_uuid: "1845753d-d8a6-4623-a772-15d290c28970",
            price: 161.106,
            price_sources: [],
            processed_ms: 1719548641727,
          },
          {
            leverage: 0.314,
            order_type: "LONG",
            order_uuid: "9621bcd3-4d16-4828-96ea-c98402b54e91",
            price: 161.067,
            price_sources: [],
            processed_ms: 1719549417419,
          },
          {
            leverage: 0.323,
            order_type: "LONG",
            order_uuid: "b04efcdb-4daa-470d-a244-2f414081ae0d",
            price: 161.02,
            price_sources: [],
            processed_ms: 1719551103679,
          },
          {
            leverage: 0.296,
            order_type: "LONG",
            order_uuid: "b63ad497-d370-4d4b-90d4-442f292abcbb",
            price: 160.9855,
            price_sources: [],
            processed_ms: 1719551579715,
          },
          {
            leverage: 0.398,
            order_type: "LONG",
            order_uuid: "98c3ac7b-eb61-4e31-aa53-8fafbc525a6f",
            price: 160.942,
            price_sources: [],
            processed_ms: 1719552394163,
          },
          {
            leverage: 0.314,
            order_type: "LONG",
            order_uuid: "aeef75f7-6643-4df6-acd5-028ca6d842df",
            price: 160.887,
            price_sources: [],
            processed_ms: 1719555840737,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "0eb7ccc2-b674-4fd8-b5b3-7bd390164979",
            price: 161.0455,
            price_sources: [],
            processed_ms: 1719558220038,
          },
        ],
        position_type: "FLAT",
        position_uuid: "99d4117a-4984-45f5-9c23-d54612200526",
        return_at_close: 1.0004316175668617,
        trade_pair: ["USDJPY", "USD/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 160.7509815873016,
        close_ms: 1719589780996,
        current_return: 1.001555550036015,
        initial_entry_price: 161.044,
        is_closed_position: true,
        miner_hotkey: "5FmqXG5YBU1Hke9jHD5FT41CUM9gVod7nFgYvbd7PmpqcUJm",
        net_leverage: 0.0,
        open_ms: 1719558249861,
        orders: [
          {
            leverage: 0.276,
            order_type: "LONG",
            order_uuid: "0b55ba0b-e733-47c1-b1a9-c42a8e30e7c4",
            price: 161.044,
            price_sources: [],
            processed_ms: 1719558249824,
          },
          {
            leverage: 0.307,
            order_type: "LONG",
            order_uuid: "bb6342d0-d033-4e50-9ad6-711d7c609d3d",
            price: 160.998,
            price_sources: [],
            processed_ms: 1719558492598,
          },
          {
            leverage: 0.377,
            order_type: "LONG",
            order_uuid: "a158084c-efbd-4d88-aae1-0f5645ce0c0f",
            price: 160.9425,
            price_sources: [],
            processed_ms: 1719558983079,
          },
          {
            leverage: 0.345,
            order_type: "LONG",
            order_uuid: "4e499609-385e-4c41-a8e8-196677bccce5",
            price: 160.916,
            price_sources: [],
            processed_ms: 1719559054093,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "abb35a9c-4e14-40a1-a131-91546234f149",
            price: 160.88,
            price_sources: [],
            processed_ms: 1719559316233,
          },
          {
            leverage: 0.379,
            order_type: "LONG",
            order_uuid: "94ef5095-6c36-4675-9e52-dc58a3a733a8",
            price: 160.83,
            price_sources: [],
            processed_ms: 1719565721183,
          },
          {
            leverage: 0.256,
            order_type: "LONG",
            order_uuid: "05842a7a-f24e-447e-8bbb-41e78c195642",
            price: 160.777,
            price_sources: [],
            processed_ms: 1719565761213,
          },
          {
            leverage: 0.266,
            order_type: "LONG",
            order_uuid: "60b4fa4c-8dc2-4b77-83c1-29662a6d046e",
            price: 160.745,
            price_sources: [],
            processed_ms: 1719565818822,
          },
          {
            leverage: 0.291,
            order_type: "LONG",
            order_uuid: "9cbd6524-2efd-410b-9255-d08edaeac973",
            price: 160.719,
            price_sources: [],
            processed_ms: 1719567265517,
          },
          {
            leverage: 0.39,
            order_type: "LONG",
            order_uuid: "561d70b9-69a9-4ce1-b9f6-dc76687b94d1",
            price: 160.676,
            price_sources: [],
            processed_ms: 1719567379104,
          },
          {
            leverage: 0.329,
            order_type: "LONG",
            order_uuid: "ff878160-2d15-4a23-ba69-4b24c2eda6da",
            price: 160.635,
            price_sources: [],
            processed_ms: 1719570426021,
          },
          {
            leverage: 0.284,
            order_type: "LONG",
            order_uuid: "17f54819-3162-4ea0-b3ed-46c77b747ec6",
            price: 160.595,
            price_sources: [],
            processed_ms: 1719570722334,
          },
          {
            leverage: 0.391,
            order_type: "LONG",
            order_uuid: "f8b65811-7ce1-428c-92fe-3dc367eb0c61",
            price: 160.544,
            price_sources: [],
            processed_ms: 1719571048660,
          },
          {
            leverage: 0.279,
            order_type: "LONG",
            order_uuid: "7a51f29f-d3bc-4ffe-a178-5111666d33d4",
            price: 160.507,
            price_sources: [],
            processed_ms: 1719572282216,
          },
          {
            leverage: 0.305,
            order_type: "LONG",
            order_uuid: "2d61fce8-90ee-4134-aa6b-8553f4ed3bf4",
            price: 160.4785,
            price_sources: [],
            processed_ms: 1719572333986,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "eab51121-07fd-4f64-b744-5ea3429630a0",
            price: 160.804,
            price_sources: [],
            processed_ms: 1719589780996,
          },
        ],
        position_type: "FLAT",
        position_uuid: "e6eb9bcc-fa94-4543-adca-f15004680644",
        return_at_close: 1.0012242855378404,
        trade_pair: ["USDJPY", "USD/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 60259.76999999999,
        close_ms: 1719623977065,
        current_return: 1.0046704791604748,
        initial_entry_price: 60259.77,
        is_closed_position: true,
        miner_hotkey: "5FmqXG5YBU1Hke9jHD5FT41CUM9gVod7nFgYvbd7PmpqcUJm",
        net_leverage: 0.0,
        open_ms: 1719615797979,
        orders: [
          {
            leverage: 0.6,
            order_type: "LONG",
            order_uuid: "f27cf95d-66dd-47c7-a152-f4bb27fbdc23",
            price: 60259.77,
            price_sources: [],
            processed_ms: 1719615794480,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "445900c8-8aa3-427d-a574-e0c544f62670",
            price: 60728.84,
            price_sources: [],
            processed_ms: 1719623977065,
          },
        ],
        position_type: "FLAT",
        position_uuid: "eabbb730-ff98-422d-b372-0bf9f41e0ea4",
        return_at_close: 1.0040676768729784,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 60792.07692307693,
        close_ms: 1719672392839,
        current_return: 1.0030370781908986,
        initial_entry_price: 60765.64,
        is_closed_position: true,
        miner_hotkey: "5FmqXG5YBU1Hke9jHD5FT41CUM9gVod7nFgYvbd7PmpqcUJm",
        net_leverage: 0.0,
        open_ms: 1719644507417,
        orders: [
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "f3b614b8-9601-48a8-a9b3-e656d2a594f9",
            price: 60765.64,
            price_sources: [],
            processed_ms: 1719644506798,
          },
          {
            leverage: 0.4,
            order_type: "LONG",
            order_uuid: "83ae8c6c-8557-4dc2-b6d9-0ed26c9de776",
            price: 60808.6,
            price_sources: [],
            processed_ms: 1719645497027,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "c57e2c8c-1a5a-41ef-bad7-1aa04475ab02",
            price: 61076.0,
            price_sources: [],
            processed_ms: 1719672392839,
          },
        ],
        position_type: "FLAT",
        position_uuid: "42266773-e1c3-403e-bb26-7011bf37eb91",
        return_at_close: 1.0023851040900744,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 3531.31,
        close_ms: 0,
        current_return: 0.9927411074077326,
        initial_entry_price: 3531.31,
        is_closed_position: false,
        miner_hotkey: "5FmqXG5YBU1Hke9jHD5FT41CUM9gVod7nFgYvbd7PmpqcUJm",
        net_leverage: 0.065,
        open_ms: 1718630582716,
        orders: [
          {
            leverage: 0.065,
            order_type: "LONG",
            order_uuid: "752fed46-b71a-410c-80ad-bec03182c3b8",
            price: 3531.31,
            price_sources: [],
            processed_ms: 1718630582706,
          },
        ],
        position_type: "LONG",
        position_uuid: "8af3a99c-c341-46b5-b704-30328282005d",
        return_at_close: 0.9927088433217418,
        trade_pair: ["ETHUSD", "ETH/USD", 0.003, 0.001, 20],
      },
    ],
    thirty_day_returns: 1.030726642369553,
  },
  "5Fn3inHyUCr1GGUvSsj81U2tg7er9axPBqEn7GMeNtx52tDy": {
    all_time_returns: 1.0,
    n_positions: 0,
    percentage_profitable: 0.0,
    positions: [
      {
        average_entry_price: 64576.37,
        close_ms: 1711193262316,
        current_return: 0.998737680671738,
        initial_entry_price: 64576.37,
        is_closed_position: true,
        miner_hotkey: "5Fn3inHyUCr1GGUvSsj81U2tg7er9axPBqEn7GMeNtx52tDy",
        net_leverage: 0.0,
        open_ms: 1711189690019,
        orders: [
          {
            leverage: 0.6,
            order_type: "LONG",
            order_uuid: "5b105dbe-85ca-4a6f-a50e-534f93033217",
            price: 64576.37,
            price_sources: [],
            processed_ms: 1711189690018,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "b9076ca5-6c9d-4758-af17-2cd5d57b2003",
            price: 64440.51,
            price_sources: [],
            processed_ms: 1711193262316,
          },
        ],
        position_type: "FLAT",
        position_uuid: "c73fa106-7c39-48e8-8edb-84c9dcd7f89f",
        return_at_close: 0.9975391954549319,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 64453.56,
        close_ms: 1711196928003,
        current_return: 1.0021732856959336,
        initial_entry_price: 64453.56,
        is_closed_position: true,
        miner_hotkey: "5Fn3inHyUCr1GGUvSsj81U2tg7er9axPBqEn7GMeNtx52tDy",
        net_leverage: 0.0,
        open_ms: 1711193349004,
        orders: [
          {
            leverage: 0.6,
            order_type: "LONG",
            order_uuid: "3d2b82a3-bb1e-49b9-80de-e69e86b3065f",
            price: 64453.56,
            price_sources: [],
            processed_ms: 1711193349004,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "1b2d3f04-24b8-4666-b87e-cd6a19c7b321",
            price: 64687.02,
            price_sources: [],
            processed_ms: 1711196928003,
          },
        ],
        position_type: "FLAT",
        position_uuid: "1681673d-12ef-49a5-a57a-42d80434cf0d",
        return_at_close: 1.0009706777530984,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 64709.170000000006,
        close_ms: 1711200584012,
        current_return: 0.9984852842340582,
        initial_entry_price: 64709.17,
        is_closed_position: true,
        miner_hotkey: "5Fn3inHyUCr1GGUvSsj81U2tg7er9axPBqEn7GMeNtx52tDy",
        net_leverage: 0.0,
        open_ms: 1711197014065,
        orders: [
          {
            leverage: 0.6,
            order_type: "LONG",
            order_uuid: "8cb16382-3441-4594-b45d-d1a30b5c347f",
            price: 64709.17,
            price_sources: [],
            processed_ms: 1711197014064,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "c8e40d66-c910-46e1-8bf2-7a142c8620d2",
            price: 64545.81,
            price_sources: [],
            processed_ms: 1711200584012,
          },
        ],
        position_type: "FLAT",
        position_uuid: "1d21ee91-0cab-4c67-8b9f-86826ebb7eaa",
        return_at_close: 0.9972871018929774,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 64542.829999999994,
        close_ms: 1711204251940,
        current_return: 1.0025341312117861,
        initial_entry_price: 64542.83,
        is_closed_position: true,
        miner_hotkey: "5Fn3inHyUCr1GGUvSsj81U2tg7er9axPBqEn7GMeNtx52tDy",
        net_leverage: 0.0,
        open_ms: 1711200670151,
        orders: [
          {
            leverage: 0.6,
            order_type: "LONG",
            order_uuid: "8e524d58-3293-4584-822a-e71c6646f7c0",
            price: 64542.83,
            price_sources: [],
            processed_ms: 1711200670150,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "c057912a-992c-4dee-8dc1-cd2832176099",
            price: 64815.43,
            price_sources: [],
            processed_ms: 1711204251940,
          },
        ],
        position_type: "FLAT",
        position_uuid: "6de61d87-c97e-49b4-bb0d-b6885e69a569",
        return_at_close: 1.001331090254332,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 64942.21000000001,
        close_ms: 1711211579831,
        current_return: 1.0046664257345106,
        initial_entry_price: 64942.21,
        is_closed_position: true,
        miner_hotkey: "5Fn3inHyUCr1GGUvSsj81U2tg7er9axPBqEn7GMeNtx52tDy",
        net_leverage: 0.0,
        open_ms: 1711208001972,
        orders: [
          {
            leverage: 0.6,
            order_type: "LONG",
            order_uuid: "42da5c4b-f089-47d1-9a56-5aaf5ec90ad1",
            price: 64942.21,
            price_sources: [],
            processed_ms: 1711208001971,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "a27a169b-c7bb-49be-a9c9-938309c886ac",
            price: 65447.29,
            price_sources: [],
            processed_ms: 1711211579831,
          },
        ],
        position_type: "FLAT",
        position_uuid: "d33788c2-ecf4-49d8-8353-563db753b220",
        return_at_close: 1.0034608260236293,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 65428.96,
        close_ms: 1711222551321,
        current_return: 0.9968469925244112,
        initial_entry_price: 65428.96,
        is_closed_position: true,
        miner_hotkey: "5Fn3inHyUCr1GGUvSsj81U2tg7er9axPBqEn7GMeNtx52tDy",
        net_leverage: 0.0,
        open_ms: 1711218977142,
        orders: [
          {
            leverage: 0.6,
            order_type: "LONG",
            order_uuid: "63e0d7b0-ba94-4340-bf84-ba6957a9dadd",
            price: 65428.96,
            price_sources: [],
            processed_ms: 1711218977141,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "395f1538-b555-4a41-8ab2-17323c135942",
            price: 65085.13,
            price_sources: [],
            processed_ms: 1711222551321,
          },
        ],
        position_type: "FLAT",
        position_uuid: "40d0550e-1ae5-448e-8bb8-efcf1ec492b7",
        return_at_close: 0.9956507761333819,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 65105.56,
        close_ms: 1711226237409,
        current_return: 0.9988205615618697,
        initial_entry_price: 65105.56,
        is_closed_position: true,
        miner_hotkey: "5Fn3inHyUCr1GGUvSsj81U2tg7er9axPBqEn7GMeNtx52tDy",
        net_leverage: 0.0,
        open_ms: 1711222637926,
        orders: [
          {
            leverage: 0.6,
            order_type: "LONG",
            order_uuid: "ee03882b-c447-43dc-a376-1277015fde73",
            price: 65105.56,
            price_sources: [],
            processed_ms: 1711222637925,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "ba8380cb-54bf-4938-a9c3-f8e90f167c7d",
            price: 64977.58,
            price_sources: [],
            processed_ms: 1711226237409,
          },
        ],
        position_type: "FLAT",
        position_uuid: "968deb56-be71-438b-9d6e-fdfed4d1fc2a",
        return_at_close: 0.9976219768879955,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 64992.69,
        close_ms: 1711237201233,
        current_return: 0.9945242457267117,
        initial_entry_price: 64992.69,
        is_closed_position: true,
        miner_hotkey: "5Fn3inHyUCr1GGUvSsj81U2tg7er9axPBqEn7GMeNtx52tDy",
        net_leverage: 0.0,
        open_ms: 1711233630459,
        orders: [
          {
            leverage: 0.6,
            order_type: "LONG",
            order_uuid: "b363c48d-20e0-4b86-8fcb-381c0ec44ab6",
            price: 64992.69,
            price_sources: [],
            processed_ms: 1711233630459,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "39d4d732-75e5-40b8-863a-89ba0deb910d",
            price: 64399.55,
            price_sources: [],
            processed_ms: 1711237201233,
          },
        ],
        position_type: "FLAT",
        position_uuid: "e3e74f5f-c808-4362-bf71-a439560d9289",
        return_at_close: 0.9933308166318396,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 64417.16,
        close_ms: 1711240853958,
        current_return: 1.0004393549793253,
        initial_entry_price: 64417.16,
        is_closed_position: true,
        miner_hotkey: "5Fn3inHyUCr1GGUvSsj81U2tg7er9axPBqEn7GMeNtx52tDy",
        net_leverage: 0.0,
        open_ms: 1711237333039,
        orders: [
          {
            leverage: 0.6,
            order_type: "LONG",
            order_uuid: "3be7825e-1ea5-434b-b6d7-e81b5146a4b4",
            price: 64417.16,
            price_sources: [],
            processed_ms: 1711237333038,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "94d0bc02-1ead-4ed7-98c7-e64df9c1eb2c",
            price: 64464.33,
            price_sources: [],
            processed_ms: 1711240853958,
          },
        ],
        position_type: "FLAT",
        position_uuid: "59b031e1-8e15-40a6-a709-f1032683e57f",
        return_at_close: 0.9992388277533502,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 64486.38999999999,
        close_ms: 1711244524334,
        current_return: 1.000249354941407,
        initial_entry_price: 64486.39,
        is_closed_position: true,
        miner_hotkey: "5Fn3inHyUCr1GGUvSsj81U2tg7er9axPBqEn7GMeNtx52tDy",
        net_leverage: 0.0,
        open_ms: 1711240941137,
        orders: [
          {
            leverage: 0.6,
            order_type: "LONG",
            order_uuid: "0a3155f4-0c82-4c9e-9a35-5d1f59012686",
            price: 64486.39,
            price_sources: [],
            processed_ms: 1711240941136,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "0d53049d-8bac-4416-bc47-8a714d7237f9",
            price: 64513.19,
            price_sources: [],
            processed_ms: 1711244524334,
          },
        ],
        position_type: "FLAT",
        position_uuid: "a1dcca8c-23d0-41b2-baab-e9371c2aeef1",
        return_at_close: 0.9990490557154774,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 64500.63,
        close_ms: 1711248187310,
        current_return: 0.9983676438509206,
        initial_entry_price: 64500.63,
        is_closed_position: true,
        miner_hotkey: "5Fn3inHyUCr1GGUvSsj81U2tg7er9axPBqEn7GMeNtx52tDy",
        net_leverage: 0.0,
        open_ms: 1711244611115,
        orders: [
          {
            leverage: 0.6,
            order_type: "LONG",
            order_uuid: "0deaa898-6593-49f4-8420-e85a1e2bb103",
            price: 64500.63,
            price_sources: [],
            processed_ms: 1711244611114,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "fe80d178-afbf-476d-a996-a1319951a74a",
            price: 64325.15,
            price_sources: [],
            processed_ms: 1711248187310,
          },
        ],
        position_type: "FLAT",
        position_uuid: "4fd793cd-adf1-4f22-b708-9348319e29ba",
        return_at_close: 0.9971696026782995,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 64359.65,
        close_ms: 1711251840046,
        current_return: 0.9974567916388607,
        initial_entry_price: 64359.65,
        is_closed_position: true,
        miner_hotkey: "5Fn3inHyUCr1GGUvSsj81U2tg7er9axPBqEn7GMeNtx52tDy",
        net_leverage: 0.0,
        open_ms: 1711248272896,
        orders: [
          {
            leverage: 0.6,
            order_type: "LONG",
            order_uuid: "0decb285-d5a0-42ed-98db-62d0a966710d",
            price: 64359.65,
            price_sources: [],
            processed_ms: 1711248272895,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "09e4610e-cfaf-4ba7-b398-33a5b8335155",
            price: 64086.85,
            price_sources: [],
            processed_ms: 1711251840046,
          },
        ],
        position_type: "FLAT",
        position_uuid: "b39448f5-6dbc-49ff-9e39-b38ce09cdcf9",
        return_at_close: 0.9962598434888941,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 64080.57,
        close_ms: 1711255508170,
        current_return: 0.9999759365436356,
        initial_entry_price: 64080.57,
        is_closed_position: true,
        miner_hotkey: "5Fn3inHyUCr1GGUvSsj81U2tg7er9axPBqEn7GMeNtx52tDy",
        net_leverage: 0.0,
        open_ms: 1711251975501,
        orders: [
          {
            leverage: 0.6,
            order_type: "LONG",
            order_uuid: "81f8b7b4-5f80-4cbd-8c68-3dc3b6b0f55b",
            price: 64080.57,
            price_sources: [],
            processed_ms: 1711251975500,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "1cc078d8-3111-42b2-962d-11b2b76a871b",
            price: 64078.0,
            price_sources: [],
            processed_ms: 1711255508170,
          },
        ],
        position_type: "FLAT",
        position_uuid: "468b690e-9813-4643-8fb2-e058896e05d0",
        return_at_close: 0.9987759654197832,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 64359.11,
        close_ms: 1711262864175,
        current_return: 0.9959828919324708,
        initial_entry_price: 64359.11,
        is_closed_position: true,
        miner_hotkey: "5Fn3inHyUCr1GGUvSsj81U2tg7er9axPBqEn7GMeNtx52tDy",
        net_leverage: 0.0,
        open_ms: 1711259286054,
        orders: [
          {
            leverage: 1.25,
            order_type: "LONG",
            order_uuid: "d26e8dd5-f42c-493a-b577-454b15998183",
            price: 64359.11,
            price_sources: [],
            processed_ms: 1711259286052,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "8996f1e5-97f5-43f4-b950-bcbb823488fe",
            price: 64152.28,
            price_sources: [],
            processed_ms: 1711262864175,
          },
        ],
        position_type: "FLAT",
        position_uuid: "682aa2c0-66b2-451d-92b6-ae347c842987",
        return_at_close: 0.9934929347026397,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 64151.219999999994,
        close_ms: 1711266525037,
        current_return: 1.0035897602570927,
        initial_entry_price: 64151.22,
        is_closed_position: true,
        miner_hotkey: "5Fn3inHyUCr1GGUvSsj81U2tg7er9axPBqEn7GMeNtx52tDy",
        net_leverage: 0.0,
        open_ms: 1711262951119,
        orders: [
          {
            leverage: 1.25,
            order_type: "LONG",
            order_uuid: "a0546d07-3df3-4f49-9ef2-b20a5c435ccf",
            price: 64151.22,
            price_sources: [],
            processed_ms: 1711262951117,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "a778ab97-da93-413d-9c08-b49131e74676",
            price: 64335.45,
            price_sources: [],
            processed_ms: 1711266525037,
          },
        ],
        position_type: "FLAT",
        position_uuid: "6f82a8fb-e640-443a-a0d1-04fb06f28703",
        return_at_close: 1.00108078585645,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 65159.85,
        close_ms: 1711284672023,
        current_return: 1.0093314748269064,
        initial_entry_price: 65159.85,
        is_closed_position: true,
        miner_hotkey: "5Fn3inHyUCr1GGUvSsj81U2tg7er9axPBqEn7GMeNtx52tDy",
        net_leverage: 0.0,
        open_ms: 1711277590155,
        orders: [
          {
            leverage: 1.25,
            order_type: "LONG",
            order_uuid: "0d25c930-eab4-495c-9596-7d0558d5ad6d",
            price: 65159.85,
            price_sources: [],
            processed_ms: 1711277590154,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "b0d43ae5-3e73-4f87-85d1-1e619e42eac0",
            price: 65646.28,
            price_sources: [],
            processed_ms: 1711284672023,
          },
        ],
        position_type: "FLAT",
        position_uuid: "2775e8d6-b109-40c6-9f72-782f445591bc",
        return_at_close: 1.0068081461398393,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 65704.91,
        close_ms: 1711295653962,
        current_return: 0.998571263547884,
        initial_entry_price: 65704.91,
        is_closed_position: true,
        miner_hotkey: "5Fn3inHyUCr1GGUvSsj81U2tg7er9axPBqEn7GMeNtx52tDy",
        net_leverage: 0.0,
        open_ms: 1711286500119,
        orders: [
          {
            leverage: 1.25,
            order_type: "LONG",
            order_uuid: "0352548b-d155-4113-9785-ba0c31d0eab7",
            price: 65704.91,
            price_sources: [],
            processed_ms: 1711286500118,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "00759d75-e4ef-4fe3-8047-ced09242a1fb",
            price: 65629.81,
            price_sources: [],
            processed_ms: 1711295653962,
          },
        ],
        position_type: "FLAT",
        position_uuid: "508d2bc3-a540-413f-bb5b-d733fa92caa7",
        return_at_close: 0.9960748353890143,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 65478.990000000005,
        close_ms: 1711306599172,
        current_return: 1.0036225360226234,
        initial_entry_price: 65478.99,
        is_closed_position: true,
        miner_hotkey: "5Fn3inHyUCr1GGUvSsj81U2tg7er9axPBqEn7GMeNtx52tDy",
        net_leverage: 0.0,
        open_ms: 1711297485467,
        orders: [
          {
            leverage: 1.25,
            order_type: "LONG",
            order_uuid: "d79bee5b-2c24-4139-ae76-787d51ab374b",
            price: 65478.99,
            price_sources: [],
            processed_ms: 1711297483771,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "c491383f-e74d-4b5b-b68e-b6a4bfc97da4",
            price: 65668.75,
            price_sources: [],
            processed_ms: 1711306599172,
          },
        ],
        position_type: "FLAT",
        position_uuid: "abbc5ff2-1655-4303-9947-3c8925bd2dad",
        return_at_close: 1.001113479682567,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 65753.15,
        close_ms: 1711311258178,
        current_return: 1.0089100294054354,
        initial_entry_price: 65753.15,
        is_closed_position: true,
        miner_hotkey: "5Fn3inHyUCr1GGUvSsj81U2tg7er9axPBqEn7GMeNtx52tDy",
        net_leverage: 0.0,
        open_ms: 1711307525262,
        orders: [
          {
            leverage: 1.25,
            order_type: "LONG",
            order_uuid: "584d0d64-448c-41d4-8ff6-47585bede8f7",
            price: 65753.15,
            price_sources: [],
            processed_ms: 1711307525260,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "f98f8be2-ecc3-4ce5-b6dc-68b9a47a2f5f",
            price: 66221.84,
            price_sources: [],
            processed_ms: 1711311258178,
          },
        ],
        position_type: "FLAT",
        position_uuid: "57320fc4-65e7-424c-84c0-b08379cea580",
        return_at_close: 1.006387754331922,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 66153.63,
        close_ms: 1711314977254,
        current_return: 1.0043196798119771,
        initial_entry_price: 66153.63,
        is_closed_position: true,
        miner_hotkey: "5Fn3inHyUCr1GGUvSsj81U2tg7er9axPBqEn7GMeNtx52tDy",
        net_leverage: 0.0,
        open_ms: 1711314062035,
        orders: [
          {
            leverage: 1.25,
            order_type: "LONG",
            order_uuid: "f51e38c4-5cd2-4450-834c-3ca807e341b1",
            price: 66153.63,
            price_sources: [],
            processed_ms: 1711314062034,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "44dbfb86-0631-4918-b706-1e9c245869f5",
            price: 66382.24,
            price_sources: [],
            processed_ms: 1711314977254,
          },
        ],
        position_type: "FLAT",
        position_uuid: "4e1cd432-3d3c-4f87-827b-9f42e68738d3",
        return_at_close: 1.0018088806124472,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 66263.13,
        close_ms: 1711318688881,
        current_return: 1.0097773075313525,
        initial_entry_price: 66263.13,
        is_closed_position: true,
        miner_hotkey: "5Fn3inHyUCr1GGUvSsj81U2tg7er9axPBqEn7GMeNtx52tDy",
        net_leverage: 0.0,
        open_ms: 1711316832219,
        orders: [
          {
            leverage: 1.25,
            order_type: "LONG",
            order_uuid: "a86e6611-3308-439b-ab5f-8ddd7e945214",
            price: 66263.13,
            price_sources: [],
            processed_ms: 1711316832218,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "9e80ac96-f16f-4c72-958c-47bc4a52bedf",
            price: 66781.43,
            price_sources: [],
            processed_ms: 1711318688881,
          },
        ],
        position_type: "FLAT",
        position_uuid: "2d0a1bde-ec22-48e8-8502-107682129a15",
        return_at_close: 1.0072528642625243,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 66875.89,
        close_ms: 1711327065385,
        current_return: 0.9960443517088146,
        initial_entry_price: 66875.89,
        is_closed_position: true,
        miner_hotkey: "5Fn3inHyUCr1GGUvSsj81U2tg7er9axPBqEn7GMeNtx52tDy",
        net_leverage: 0.0,
        open_ms: 1711326154696,
        orders: [
          {
            leverage: 1.25,
            order_type: "LONG",
            order_uuid: "f11df184-5717-4425-9a1d-33847a3fc0be",
            price: 66875.89,
            price_sources: [],
            processed_ms: 1711326154695,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "d470a69e-47c8-441b-8ccf-fd2e03d364fe",
            price: 66664.26,
            price_sources: [],
            processed_ms: 1711327065385,
          },
        ],
        position_type: "FLAT",
        position_uuid: "415d5a74-1ca4-41a8-9f6f-c8ceda31d8a3",
        return_at_close: 0.9935542408295426,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 66645.1,
        close_ms: 1711336329624,
        current_return: 1.0035493982303274,
        initial_entry_price: 66645.1,
        is_closed_position: true,
        miner_hotkey: "5Fn3inHyUCr1GGUvSsj81U2tg7er9axPBqEn7GMeNtx52tDy",
        net_leverage: 0.0,
        open_ms: 1711334498363,
        orders: [
          {
            leverage: 1.25,
            order_type: "LONG",
            order_uuid: "be1f0311-7767-4fd1-8a02-72f252c92fe9",
            price: 66645.1,
            price_sources: [],
            processed_ms: 1711334498362,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "3b17715f-d8b0-4116-b541-b5c97d791b18",
            price: 66834.34,
            price_sources: [],
            processed_ms: 1711336329624,
          },
        ],
        position_type: "FLAT",
        position_uuid: "fc4037db-3f17-4eb4-9ca3-0dc2ace83270",
        return_at_close: 1.0010405247347516,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 67066.73,
        close_ms: 1711339988062,
        current_return: 1.004680972219758,
        initial_entry_price: 67066.73,
        is_closed_position: true,
        miner_hotkey: "5Fn3inHyUCr1GGUvSsj81U2tg7er9axPBqEn7GMeNtx52tDy",
        net_leverage: 0.0,
        open_ms: 1711338158932,
        orders: [
          {
            leverage: 1.25,
            order_type: "LONG",
            order_uuid: "6e973a68-2401-4598-bd1f-2df34d973f38",
            price: 67066.73,
            price_sources: [],
            processed_ms: 1711338158930,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "1829aa06-8f07-4849-86a0-befe38103432",
            price: 67317.88,
            price_sources: [],
            processed_ms: 1711339988062,
          },
        ],
        position_type: "FLAT",
        position_uuid: "57963fc1-518d-49dc-95f4-8cda0e846360",
        return_at_close: 1.0021692697892086,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 67188.93000000001,
        close_ms: 1711375361295,
        current_return: 1.0481766220515203,
        initial_entry_price: 67350.82,
        is_closed_position: true,
        miner_hotkey: "5Fn3inHyUCr1GGUvSsj81U2tg7er9axPBqEn7GMeNtx52tDy",
        net_leverage: 0.0,
        open_ms: 1711341815247,
        orders: [
          {
            leverage: 1.25,
            order_type: "LONG",
            order_uuid: "578b7d52-dbd8-4df9-830f-4cdef91044cc",
            price: 67350.82,
            price_sources: [],
            processed_ms: 1711341815246,
          },
          {
            leverage: 1.25,
            order_type: "LONG",
            order_uuid: "d301f9f7-82a2-4fd2-9d6c-aaf115658696",
            price: 67460.35,
            price_sources: [],
            processed_ms: 1711345468526,
          },
          {
            leverage: -1.25,
            order_type: "SHORT",
            order_uuid: "a6f54cd6-5cfc-4d18-9360-d3e2cb0fd8c5",
            price: 67234.36,
            price_sources: [],
            processed_ms: 1711347295170,
          },
          {
            leverage: 1.25,
            order_type: "LONG",
            order_uuid: "a10c164a-ff1f-4f4e-bbf0-3f35a057db98",
            price: 66848.16,
            price_sources: [],
            processed_ms: 1711366537251,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "3a175927-d40f-4062-975b-2bac42d0a95b",
            price: 67102.18,
            price_sources: [],
            processed_ms: 1711370623171,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "b8773b3d-938a-467d-bbd6-0b0893a1ff2e",
            price: 67006.06,
            price_sources: [],
            processed_ms: 1711372468974,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "041dd06e-afce-464d-9dbe-493d44da3547",
            price: 67223.0,
            price_sources: [],
            processed_ms: 1711374283195,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "e622c256-6859-48aa-a1eb-9676e225bef4",
            price: 68187.31,
            price_sources: [],
            processed_ms: 1711375361295,
          },
        ],
        position_type: "FLAT",
        position_uuid: "c2718ad8-3f1f-493d-b15f-22e33d389f81",
        return_at_close: 1.0413634740081854,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: -155596.36217195826,
        close_ms: 1711419519830,
        current_return: 1.0090527393965065,
        initial_entry_price: 68888.85,
        is_closed_position: true,
        miner_hotkey: "5Fn3inHyUCr1GGUvSsj81U2tg7er9axPBqEn7GMeNtx52tDy",
        net_leverage: 0.0,
        open_ms: 1711376046440,
        orders: [
          {
            leverage: 1.25,
            order_type: "LONG",
            order_uuid: "a4eefbc9-5cc2-4e8d-a17c-e0a961d06a18",
            price: 68888.85,
            price_sources: [],
            processed_ms: 1711376046437,
          },
          {
            leverage: -1.0,
            order_type: "SHORT",
            order_uuid: "59884efb-f718-4dab-b9be-0e7c649f49f8",
            price: 69235.28,
            price_sources: [],
            processed_ms: 1711376555504,
          },
          {
            leverage: -0.2,
            order_type: "SHORT",
            order_uuid: "1ef4ca0c-8114-4ac8-b3f3-1b92e23c8f1b",
            price: 69296.54,
            price_sources: [],
            processed_ms: 1711376860069,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "a7836ad9-1b66-4089-88fd-e6772be42dd9",
            price: 69144.78,
            price_sources: [],
            processed_ms: 1711378019134,
          },
          {
            leverage: 0.25000000000000006,
            order_type: "LONG",
            order_uuid: "9cfbda21-c868-4b33-bbe7-d634dbab5119",
            price: 69212.28,
            price_sources: [],
            processed_ms: 1711378299237,
          },
          {
            leverage: -0.275,
            order_type: "SHORT",
            order_uuid: "3701ce13-1f81-47ab-87f3-69a42929ff5f",
            price: 69462.01,
            price_sources: [],
            processed_ms: 1711379543384,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "f632ac91-ca6c-4e97-82ec-fbc9a9ddf04d",
            price: 69804.53,
            price_sources: [],
            processed_ms: 1711381388375,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "1a9b6015-b6c1-4a0a-9491-b1789872c51d",
            price: 69704.71,
            price_sources: [],
            processed_ms: 1711383179100,
          },
          {
            leverage: 0.2499999999999999,
            order_type: "LONG",
            order_uuid: "79afb084-0fc4-4113-ac22-8d770bf87c16",
            price: 70239.51,
            price_sources: [],
            processed_ms: 1711384987277,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "3ad6cec4-053f-4e83-9bee-19114dab48d0",
            price: 70520.0,
            price_sources: [],
            processed_ms: 1711386802620,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "b49955f5-4b49-49c9-a6a6-e0ca77ff8713",
            price: 70450.05,
            price_sources: [],
            processed_ms: 1711388629091,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "d4f9c10b-0ed0-43be-829b-c245a177bc48",
            price: 70667.02,
            price_sources: [],
            processed_ms: 1711390440351,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "3d763771-13bc-49de-bc2b-e3df85460721",
            price: 70726.21,
            price_sources: [],
            processed_ms: 1711392261160,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "2e65de3f-c2f0-4b53-970a-9348a43f38a5",
            price: 70661.08,
            price_sources: [],
            processed_ms: 1711394072186,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "e0077297-6b76-4cdb-a163-f647f3eaa976",
            price: 70816.43,
            price_sources: [],
            processed_ms: 1711395906312,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "e814bace-fd54-41b9-ae55-88f1c682282b",
            price: 70826.43,
            price_sources: [],
            processed_ms: 1711397705810,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "f2455595-3ce5-4dad-95a9-65b9e7aa5f98",
            price: 70895.69,
            price_sources: [],
            processed_ms: 1711399536033,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "2b3a4d65-ca58-4905-813c-fa815ed910d1",
            price: 70518.71,
            price_sources: [],
            processed_ms: 1711401330153,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "1d8d466f-3901-4046-9c61-e38942a892a1",
            price: 70138.62,
            price_sources: [],
            processed_ms: 1711403165578,
          },
          {
            leverage: -1.7625,
            order_type: "SHORT",
            order_uuid: "7b633070-0e96-4112-b2a7-287c93c9c509",
            price: 70413.18,
            price_sources: [],
            processed_ms: 1711404966259,
          },
          {
            leverage: -0.88125,
            order_type: "SHORT",
            order_uuid: "86ca2c1f-e325-4ced-8104-33411cf12b91",
            price: 70504.39,
            price_sources: [],
            processed_ms: 1711406786260,
          },
          {
            leverage: -0.440625,
            order_type: "SHORT",
            order_uuid: "a562bdc8-e6e3-4f9d-aca1-b8dd33c842ed",
            price: 70448.12,
            price_sources: [],
            processed_ms: 1711408599403,
          },
          {
            leverage: 0.25000000000000006,
            order_type: "LONG",
            order_uuid: "c768e1b3-3bb7-472e-9545-3df32019bc3c",
            price: 69827.53,
            price_sources: [],
            processed_ms: 1711410421019,
          },
          {
            leverage: -0.3453125,
            order_type: "SHORT",
            order_uuid: "8b69c79e-261a-42e6-b8d5-8216ac768914",
            price: 69828.84,
            price_sources: [],
            processed_ms: 1711412236019,
          },
          {
            leverage: -0.27625,
            order_type: "SHORT",
            order_uuid: "8bed8514-3672-4b45-a8ef-3e05cd120c9f",
            price: 70015.4,
            price_sources: [],
            processed_ms: 1711414046280,
          },
          {
            leverage: -0.05525000000000002,
            order_type: "SHORT",
            order_uuid: "8c9e0476-64df-4dcf-a33e-e047beedef53",
            price: 70149.15,
            price_sources: [],
            processed_ms: 1711415884012,
          },
          {
            leverage: -0.011050000000000004,
            order_type: "SHORT",
            order_uuid: "bcbf055c-c5ba-4432-a354-685c9c633f32",
            price: 70222.56,
            price_sources: [],
            processed_ms: 1711417683215,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "60ac238b-fba9-48e9-aff7-ef4de9ef785b",
            price: 70153.07,
            price_sources: [],
            processed_ms: 1711419519830,
          },
        ],
        position_type: "FLAT",
        position_uuid: "5909cc01-b138-4fa4-8b2d-c7ce34c7ef1e",
        return_at_close: 1.0019389175837612,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 69335.74000000002,
        close_ms: 1711430395108,
        current_return: 0.9956875967624426,
        initial_entry_price: 70275.2,
        is_closed_position: true,
        miner_hotkey: "5Fn3inHyUCr1GGUvSsj81U2tg7er9axPBqEn7GMeNtx52tDy",
        net_leverage: 0.0,
        open_ms: 1711421317292,
        orders: [
          {
            leverage: -1.25,
            order_type: "SHORT",
            order_uuid: "deb26be6-acbf-4cbb-810d-a45d80732a00",
            price: 70275.2,
            price_sources: [],
            processed_ms: 1711421317289,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "4190f9cc-da86-42e6-bc97-9ea25bc9070a",
            price: 70427.01,
            price_sources: [],
            processed_ms: 1711423161723,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "053d069b-34c5-4d9f-8d32-3e5599108349",
            price: 70490.81,
            price_sources: [],
            processed_ms: 1711424947753,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "f9f84ed5-9344-4eff-81c2-17dd5cbabb17",
            price: 70542.29,
            price_sources: [],
            processed_ms: 1711426757983,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "ffbdff64-19a8-464b-8f80-5a6c49ca651b",
            price: 70580.15,
            price_sources: [],
            processed_ms: 1711428574904,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "c5d3acef-8c80-4470-8454-ea10f5e42977",
            price: 70547.96,
            price_sources: [],
            processed_ms: 1711430395108,
          },
        ],
        position_type: "FLAT",
        position_uuid: "a2349a1a-1385-4a0d-869d-0203dd4b7741",
        return_at_close: 0.9931983777705365,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 68927.59000000003,
        close_ms: 1711444920346,
        current_return: 0.9929454058250234,
        initial_entry_price: 70450.19,
        is_closed_position: true,
        miner_hotkey: "5Fn3inHyUCr1GGUvSsj81U2tg7er9axPBqEn7GMeNtx52tDy",
        net_leverage: 0.0,
        open_ms: 1711435852919,
        orders: [
          {
            leverage: -1.25,
            order_type: "SHORT",
            order_uuid: "d29e9299-c6fe-4ed6-b2e6-c0539a1d8b38",
            price: 70450.19,
            price_sources: [],
            processed_ms: 1711435852916,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "56ed0fef-0d4f-469e-ae97-7ac1a4998c22",
            price: 70973.02,
            price_sources: [],
            processed_ms: 1711437659088,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "67b81b01-815f-498b-b3b4-8f18f75398a2",
            price: 71047.29,
            price_sources: [],
            processed_ms: 1711439475752,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "06b869b7-7af7-46f1-99af-1b5357017d5f",
            price: 70737.86,
            price_sources: [],
            processed_ms: 1711441297927,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "49452358-47e4-4450-a701-daa6d69921bb",
            price: 70565.19,
            price_sources: [],
            processed_ms: 1711443120243,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "1e12ee90-e9b2-43ef-be8f-b383ab64931a",
            price: 70915.58,
            price_sources: [],
            processed_ms: 1711444920346,
          },
        ],
        position_type: "FLAT",
        position_uuid: "e819e912-4d81-444f-af20-4283078dffc8",
        return_at_close: 0.9904630423104609,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 75004.15666666672,
        close_ms: 1711457664043,
        current_return: 1.0054485033661593,
        initial_entry_price: 71232.52,
        is_closed_position: true,
        miner_hotkey: "5Fn3inHyUCr1GGUvSsj81U2tg7er9axPBqEn7GMeNtx52tDy",
        net_leverage: 0.0,
        open_ms: 1711446736035,
        orders: [
          {
            leverage: -1.25,
            order_type: "SHORT",
            order_uuid: "a36f6af4-1dd8-46c3-bed9-4e9ed037ba45",
            price: 71232.52,
            price_sources: [],
            processed_ms: 1711446736033,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "d900fc69-696f-44bb-9f1d-5d787d63ea0b",
            price: 71139.87,
            price_sources: [],
            processed_ms: 1711448559435,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "0707f32e-3a12-471f-8fe1-b684fcdf5ba3",
            price: 70984.21,
            price_sources: [],
            processed_ms: 1711450374277,
          },
          {
            leverage: 0.375,
            order_type: "LONG",
            order_uuid: "13422dbb-7f26-4423-8132-2f0d15020e0d",
            price: 70842.81,
            price_sources: [],
            processed_ms: 1711452196769,
          },
          {
            leverage: 0.1875,
            order_type: "LONG",
            order_uuid: "57c803c4-e697-4847-9e58-c8ce2d2138e9",
            price: 70725.55,
            price_sources: [],
            processed_ms: 1711453999337,
          },
          {
            leverage: 0.09375,
            order_type: "LONG",
            order_uuid: "ed24b9a1-6bf2-42f8-b2c3-dabdb25f3057",
            price: 70942.89,
            price_sources: [],
            processed_ms: 1711455819511,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "afa09c59-035a-413a-9f7d-51c927592277",
            price: 70864.31,
            price_sources: [],
            processed_ms: 1711457664043,
          },
        ],
        position_type: "FLAT",
        position_uuid: "3d01e33b-ffc3-487c-a292-8335f976ae25",
        return_at_close: 1.002934882107744,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 592589.2600000085,
        close_ms: 1711472204373,
        current_return: 1.0147349728615913,
        initial_entry_price: 70980.58,
        is_closed_position: true,
        miner_hotkey: "5Fn3inHyUCr1GGUvSsj81U2tg7er9axPBqEn7GMeNtx52tDy",
        net_leverage: 0.0,
        open_ms: 1711459456295,
        orders: [
          {
            leverage: -1.25,
            order_type: "SHORT",
            order_uuid: "bdf934b8-f07b-4ff7-b350-475f141c5599",
            price: 70980.58,
            price_sources: [],
            processed_ms: 1711459456292,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "1d3e753f-5ba2-44a6-bd2a-227d0cfce37b",
            price: 70737.34,
            price_sources: [],
            processed_ms: 1711461275386,
          },
          {
            leverage: 0.8,
            order_type: "LONG",
            order_uuid: "6a2f3587-5463-4852-b59c-0128f67f841e",
            price: 69942.42,
            price_sources: [],
            processed_ms: 1711463097783,
          },
          {
            leverage: 0.15999999999999998,
            order_type: "LONG",
            order_uuid: "139fc900-2462-4ed8-8715-a4fc99f92837",
            price: 70270.93,
            price_sources: [],
            processed_ms: 1711464907095,
          },
          {
            leverage: 0.03199999999999999,
            order_type: "LONG",
            order_uuid: "66a5fa58-ec7b-44cb-8d4c-0e9e19d6bfcd",
            price: 69947.29,
            price_sources: [],
            processed_ms: 1711466723356,
          },
          {
            leverage: 0.003999999999999997,
            order_type: "LONG",
            order_uuid: "f96be499-56df-477a-8dd1-1b4250563ef2",
            price: 70242.94,
            price_sources: [],
            processed_ms: 1711468538827,
          },
          {
            leverage: 0.0019999999999999983,
            order_type: "LONG",
            order_uuid: "6dc9cfd3-6f35-4cda-8704-17bdb237936a",
            price: 69820.82,
            price_sources: [],
            processed_ms: 1711470354532,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "7d650577-15a9-43b3-bb40-84110d30f7b5",
            price: 69640.8,
            price_sources: [],
            processed_ms: 1711472204373,
          },
        ],
        position_type: "FLAT",
        position_uuid: "b4b4c8de-8086-4476-b2da-b936c9aca537",
        return_at_close: 1.0121981354294374,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 299455.5599999977,
        close_ms: 1711490338033,
        current_return: 1.0040843965384032,
        initial_entry_price: 70216.15,
        is_closed_position: true,
        miner_hotkey: "5Fn3inHyUCr1GGUvSsj81U2tg7er9axPBqEn7GMeNtx52tDy",
        net_leverage: 0.0,
        open_ms: 1711477624566,
        orders: [
          {
            leverage: -1.25,
            order_type: "SHORT",
            order_uuid: "767bcacf-eeb4-43fc-82bf-5ffda8ace56f",
            price: 70216.15,
            price_sources: [],
            processed_ms: 1711477624564,
          },
          {
            leverage: 0.625,
            order_type: "LONG",
            order_uuid: "4d259efd-c225-4e35-957f-30bf87c8f694",
            price: 70226.2,
            price_sources: [],
            processed_ms: 1711479440982,
          },
          {
            leverage: 0.5,
            order_type: "LONG",
            order_uuid: "db1651c1-b022-4214-8dc1-c23b952b371f",
            price: 69762.07,
            price_sources: [],
            processed_ms: 1711481262077,
          },
          {
            leverage: 0.1,
            order_type: "LONG",
            order_uuid: "469210cd-a0ab-43c2-a309-cc9efbf027eb",
            price: 69674.09,
            price_sources: [],
            processed_ms: 1711483067179,
          },
          {
            leverage: 0.019999999999999997,
            order_type: "LONG",
            order_uuid: "cca90440-3097-4080-869a-687047510c97",
            price: 69701.82,
            price_sources: [],
            processed_ms: 1711484879486,
          },
          {
            leverage: 0.0024999999999999988,
            order_type: "LONG",
            order_uuid: "7748bbfe-e45b-4f37-ba8f-045040753b37",
            price: 69758.73,
            price_sources: [],
            processed_ms: 1711486694282,
          },
          {
            leverage: 0.0012499999999999994,
            order_type: "LONG",
            order_uuid: "3600c6d3-8da1-45f2-b9b2-500fc083a10f",
            price: 70092.66,
            price_sources: [],
            processed_ms: 1711488513065,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "b382b266-0233-470c-bdfc-195ae33103e7",
            price: 70023.08,
            price_sources: [],
            processed_ms: 1711490338033,
          },
        ],
        position_type: "FLAT",
        position_uuid: "9d4b3800-09bb-4dd6-b801-e27c6c3b7ddb",
        return_at_close: 1.0015741855470572,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: -68678.03609137074,
        close_ms: 1711523026980,
        current_return: 1.0038254016015768,
        initial_entry_price: 69884.25,
        is_closed_position: true,
        miner_hotkey: "5Fn3inHyUCr1GGUvSsj81U2tg7er9axPBqEn7GMeNtx52tDy",
        net_leverage: 0.0,
        open_ms: 1711492148815,
        orders: [
          {
            leverage: 1.25,
            order_type: "LONG",
            order_uuid: "a0cf5f00-ad48-4f87-acd7-a225eed50445",
            price: 69884.25,
            price_sources: [],
            processed_ms: 1711492148813,
          },
          {
            leverage: -0.625,
            order_type: "SHORT",
            order_uuid: "0b0b056e-b34d-4d9d-a068-033a0bb1253a",
            price: 70194.34,
            price_sources: [],
            processed_ms: 1711493973543,
          },
          {
            leverage: -0.3125,
            order_type: "SHORT",
            order_uuid: "d12c25d9-4ce9-40f6-ae64-3857b4dbb1e9",
            price: 70093.25,
            price_sources: [],
            processed_ms: 1711495790172,
          },
          {
            leverage: -0.15625,
            order_type: "SHORT",
            order_uuid: "a3768461-b999-4ad3-80e1-dbd15042fa6b",
            price: 69990.18,
            price_sources: [],
            processed_ms: 1711497609125,
          },
          {
            leverage: -0.078125,
            order_type: "SHORT",
            order_uuid: "3e45275d-ab12-45b8-baef-f6d430708d6a",
            price: 70114.61,
            price_sources: [],
            processed_ms: 1711499423330,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "2b865353-0609-49f1-93da-3d5f7c7d3d8a",
            price: 70613.92,
            price_sources: [],
            processed_ms: 1711501275952,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "146a6d1b-ddf3-4ac9-b2f4-7dca860585c3",
            price: 70458.67,
            price_sources: [],
            processed_ms: 1711503052174,
          },
          {
            leverage: -0.2890625,
            order_type: "SHORT",
            order_uuid: "32c154d6-b326-4a29-8837-1ebc15866284",
            price: 70330.38,
            price_sources: [],
            processed_ms: 1711504870250,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "de4c45f5-6922-4b46-b405-de0b117c0539",
            price: 70406.23,
            price_sources: [],
            processed_ms: 1711506687549,
          },
          {
            leverage: -0.26953125,
            order_type: "SHORT",
            order_uuid: "5e0ab45b-87d5-4a7e-88aa-8ba3484ee867",
            price: 70584.9,
            price_sources: [],
            processed_ms: 1711508506020,
          },
          {
            leverage: -0.134765625,
            order_type: "SHORT",
            order_uuid: "aa3dece1-a839-4a3b-9120-024a5e7f193a",
            price: 70531.41,
            price_sources: [],
            processed_ms: 1711510322459,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "53d865e8-d35b-4947-9759-2a7066d64544",
            price: 70402.14,
            price_sources: [],
            processed_ms: 1711512156375,
          },
          {
            leverage: -0.1923828125,
            order_type: "SHORT",
            order_uuid: "b288756a-b242-4a27-aa99-535e9ca7c039",
            price: 70279.95,
            price_sources: [],
            processed_ms: 1711513953070,
          },
          {
            leverage: -0.09619140625,
            order_type: "SHORT",
            order_uuid: "c7e4b699-7a3d-475c-a360-67ba7e8b29b8",
            price: 70216.69,
            price_sources: [],
            processed_ms: 1711515768406,
          },
          {
            leverage: -0.076953125,
            order_type: "SHORT",
            order_uuid: "91b38bd0-cde2-41e7-83a0-86c3c4992df9",
            price: 70364.31,
            price_sources: [],
            processed_ms: 1711517598485,
          },
          {
            leverage: -0.015390625000000002,
            order_type: "SHORT",
            order_uuid: "145ee90a-ecdd-4dd7-8d7f-f2236b594bf0",
            price: 70411.03,
            price_sources: [],
            processed_ms: 1711519399322,
          },
          {
            leverage: -0.0019238281250000006,
            order_type: "SHORT",
            order_uuid: "5f61b0d6-0866-4afe-874b-ac69ccd608a5",
            price: 70257.49,
            price_sources: [],
            processed_ms: 1711521214494,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "630b1d05-6ae4-4c32-b7c8-f5f6f1e61b34",
            price: 70282.05,
            price_sources: [],
            processed_ms: 1711523026980,
          },
        ],
        position_type: "FLAT",
        position_uuid: "51d5a51a-a9f6-4f68-8c1b-353025e91028",
        return_at_close: 1.0013158380975729,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 55932.09000000014,
        close_ms: 1711556978982,
        current_return: 0.9941451109866205,
        initial_entry_price: 70188.19,
        is_closed_position: true,
        miner_hotkey: "5Fn3inHyUCr1GGUvSsj81U2tg7er9axPBqEn7GMeNtx52tDy",
        net_leverage: 0.0,
        open_ms: 1711535446537,
        orders: [
          {
            leverage: -1.25,
            order_type: "SHORT",
            order_uuid: "091fc977-1469-4fe6-84b3-6dcd5e91448e",
            price: 70188.19,
            price_sources: [],
            processed_ms: 1711535446532,
          },
          {
            leverage: -1.25,
            order_type: "SHORT",
            order_uuid: "cabe082a-c3fc-41f5-8f38-f6b36272ff32",
            price: 70125.56,
            price_sources: [],
            processed_ms: 1711535552687,
          },
          {
            leverage: 1.25,
            order_type: "LONG",
            order_uuid: "ffa2a0f0-65cc-4449-a5dd-9a4bc0665323",
            price: 70071.95,
            price_sources: [],
            processed_ms: 1711538935961,
          },
          {
            leverage: 0.625,
            order_type: "LONG",
            order_uuid: "6b97a637-a262-41fa-b3a8-5d19248dad06",
            price: 70166.72,
            price_sources: [],
            processed_ms: 1711542523532,
          },
          {
            leverage: 0.5,
            order_type: "LONG",
            order_uuid: "0f093144-f01c-4da4-92c4-abca4f0632a6",
            price: 71367.95,
            price_sources: [],
            processed_ms: 1711546134562,
          },
          {
            leverage: 0.0625,
            order_type: "LONG",
            order_uuid: "1ee747e3-9a92-4d95-99fb-0f6e52e89056",
            price: 69628.36,
            price_sources: [],
            processed_ms: 1711549768566,
          },
          {
            leverage: 0.03125,
            order_type: "LONG",
            order_uuid: "890389e6-bf0f-43a2-ac34-b47d7668ac83",
            price: 69261.59,
            price_sources: [],
            processed_ms: 1711553371515,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "f0f0bc7a-47a4-474a-8e2c-143495125a9c",
            price: 69082.3,
            price_sources: [],
            processed_ms: 1711556978982,
          },
        ],
        position_type: "FLAT",
        position_uuid: "d6c1007e-8c98-42e0-96a7-39615b2f76a2",
        return_at_close: 0.9891743854316875,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 80428.71000000006,
        close_ms: 1711582295546,
        current_return: 1.006372092302934,
        initial_entry_price: 69110.75,
        is_closed_position: true,
        miner_hotkey: "5Fn3inHyUCr1GGUvSsj81U2tg7er9axPBqEn7GMeNtx52tDy",
        net_leverage: 0.0,
        open_ms: 1711560600266,
        orders: [
          {
            leverage: -1.25,
            order_type: "SHORT",
            order_uuid: "2bd94ae1-eee3-444c-8301-34e87a86c8cf",
            price: 69110.75,
            price_sources: [],
            processed_ms: 1711560600264,
          },
          {
            leverage: 0.625,
            order_type: "LONG",
            order_uuid: "35da12ac-b19a-42b6-80d8-74263ab2de3c",
            price: 68624.5,
            price_sources: [],
            processed_ms: 1711564215516,
          },
          {
            leverage: 0.3125,
            order_type: "LONG",
            order_uuid: "966cf98f-6bcb-4a0e-858f-18d77e484864",
            price: 68711.73,
            price_sources: [],
            processed_ms: 1711567836368,
          },
          {
            leverage: 0.15625,
            order_type: "LONG",
            order_uuid: "b835346e-182a-47ba-a048-6294d8474841",
            price: 68959.72,
            price_sources: [],
            processed_ms: 1711571471232,
          },
          {
            leverage: 0.078125,
            order_type: "LONG",
            order_uuid: "138d00e8-d77f-4aac-b864-532a484be344",
            price: 69142.65,
            price_sources: [],
            processed_ms: 1711575066602,
          },
          {
            leverage: 0.0390625,
            order_type: "LONG",
            order_uuid: "9f591550-5bb8-49f3-85ca-8da239eee74f",
            price: 69305.27,
            price_sources: [],
            processed_ms: 1711578677059,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "81a2620d-ef2c-45fa-a17e-8aec229fbcbe",
            price: 69154.98,
            price_sources: [],
            processed_ms: 1711582295546,
          },
        ],
        position_type: "FLAT",
        position_uuid: "916cfc97-7186-42e0-8383-4ddc629cba94",
        return_at_close: 1.0038561620721766,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 83761.36000000029,
        close_ms: 1711607622195,
        current_return: 0.9923435943444999,
        initial_entry_price: 69785.52,
        is_closed_position: true,
        miner_hotkey: "5Fn3inHyUCr1GGUvSsj81U2tg7er9axPBqEn7GMeNtx52tDy",
        net_leverage: 0.0,
        open_ms: 1711585923302,
        orders: [
          {
            leverage: 1.25,
            order_type: "LONG",
            order_uuid: "20296cd5-a2f4-4a37-ac5c-371355e4018c",
            price: 69785.52,
            price_sources: [],
            processed_ms: 1711585923299,
          },
          {
            leverage: -0.625,
            order_type: "SHORT",
            order_uuid: "66b4b0f2-06e1-49d4-b421-f98c8103f568",
            price: 69284.76,
            price_sources: [],
            processed_ms: 1711589526451,
          },
          {
            leverage: -0.3125,
            order_type: "SHORT",
            order_uuid: "d4d99d72-b58f-47f7-83ba-ecac49e53c39",
            price: 69336.72,
            price_sources: [],
            processed_ms: 1711593154978,
          },
          {
            leverage: -0.15625,
            order_type: "SHORT",
            order_uuid: "ba3e8c11-8406-42bb-a294-0860d3545327",
            price: 69291.74,
            price_sources: [],
            processed_ms: 1711596754550,
          },
          {
            leverage: -0.078125,
            order_type: "SHORT",
            order_uuid: "5ec5034a-a064-4b8e-8be7-1ae6ccab03a6",
            price: 69605.9,
            price_sources: [],
            processed_ms: 1711600380205,
          },
          {
            leverage: -0.0390625,
            order_type: "SHORT",
            order_uuid: "beb6d60d-f9ef-4c19-9d1a-a93f371a4034",
            price: 69746.6,
            price_sources: [],
            processed_ms: 1711603990532,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "760e9dc1-8216-484a-9dcb-dd210cab9944",
            price: 70083.12,
            price_sources: [],
            processed_ms: 1711607622195,
          },
        ],
        position_type: "FLAT",
        position_uuid: "d2dfc27c-29c7-4c61-989b-a6fb22a3971f",
        return_at_close: 0.9898627353586388,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 60347.96000000018,
        close_ms: 1711629305235,
        current_return: 0.9955340732351192,
        initial_entry_price: 70294.69,
        is_closed_position: true,
        miner_hotkey: "5Fn3inHyUCr1GGUvSsj81U2tg7er9axPBqEn7GMeNtx52tDy",
        net_leverage: 0.0,
        open_ms: 1711611226306,
        orders: [
          {
            leverage: -1.25,
            order_type: "SHORT",
            order_uuid: "48174a0f-3a12-4eea-b51d-a7a74f669bb3",
            price: 70294.69,
            price_sources: [],
            processed_ms: 1711611226303,
          },
          {
            leverage: 0.625,
            order_type: "LONG",
            order_uuid: "b725dc99-3348-4ef9-88f9-0f9d30ea9ffb",
            price: 70555.86,
            price_sources: [],
            processed_ms: 1711614834641,
          },
          {
            leverage: 0.3125,
            order_type: "LONG",
            order_uuid: "15c5d538-1a79-41d4-8254-29d653b6cfc0",
            price: 70641.42,
            price_sources: [],
            processed_ms: 1711618455443,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "6a9de0a6-0c3c-4871-8e3a-203bf6b2a767",
            price: 70398.75,
            price_sources: [],
            processed_ms: 1711622085440,
          },
          {
            leverage: 0.03125,
            order_type: "LONG",
            order_uuid: "8dedc747-1d68-4091-8355-4736dcb4812e",
            price: 70718.24,
            price_sources: [],
            processed_ms: 1711625689621,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "6a497a9d-b589-499e-b245-4bdfeb639afa",
            price: 70393.75,
            price_sources: [],
            processed_ms: 1711629305235,
          },
        ],
        position_type: "FLAT",
        position_uuid: "8fcf7de9-08a4-43a1-9ac3-d0e754fd1f0d",
        return_at_close: 0.9930452380520315,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 70814.80999999994,
        close_ms: 1711647386457,
        current_return: 1.0001685876721973,
        initial_entry_price: 71127.68,
        is_closed_position: true,
        miner_hotkey: "5Fn3inHyUCr1GGUvSsj81U2tg7er9axPBqEn7GMeNtx52tDy",
        net_leverage: 0.0,
        open_ms: 1711632921390,
        orders: [
          {
            leverage: -1.25,
            order_type: "SHORT",
            order_uuid: "223e3b8d-2268-4e05-855c-f139e4ff985c",
            price: 71127.68,
            price_sources: [],
            processed_ms: 1711632921387,
          },
          {
            leverage: 0.375,
            order_type: "LONG",
            order_uuid: "fa45b08e-de71-485d-af6d-ccf2755690b8",
            price: 71376.81,
            price_sources: [],
            processed_ms: 1711636530265,
          },
          {
            leverage: 0.375,
            order_type: "LONG",
            order_uuid: "df0b7ed3-01a8-4d71-94b2-592bcd39f944",
            price: 71137.15,
            price_sources: [],
            processed_ms: 1711640147610,
          },
          {
            leverage: 0.375,
            order_type: "LONG",
            order_uuid: "a79d0b99-6311-4e44-a3c4-16f04989f8fc",
            price: 70973.37,
            price_sources: [],
            processed_ms: 1711643771416,
          },
          {
            leverage: 0.375,
            order_type: "LONG",
            order_uuid: "cb3e08a0-72aa-4dc6-8da6-2288349d7b2e",
            price: 70718.88,
            price_sources: [],
            processed_ms: 1711647386457,
          },
        ],
        position_type: "FLAT",
        position_uuid: "2d336ac3-b451-49ce-a444-cd2c9ff1964f",
        return_at_close: 0.9976681662030169,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 70998.46000000002,
        close_ms: 1711665463235,
        current_return: 1.0002807528313216,
        initial_entry_price: 70818.52,
        is_closed_position: true,
        miner_hotkey: "5Fn3inHyUCr1GGUvSsj81U2tg7er9axPBqEn7GMeNtx52tDy",
        net_leverage: 0.0,
        open_ms: 1711651003273,
        orders: [
          {
            leverage: -1.25,
            order_type: "SHORT",
            order_uuid: "42ca1792-cba7-4451-afba-b0da79e14265",
            price: 70818.52,
            price_sources: [],
            processed_ms: 1711651003270,
          },
          {
            leverage: 0.375,
            order_type: "LONG",
            order_uuid: "998f5a14-01ac-44f0-b87a-9da3418c73e8",
            price: 70871.49,
            price_sources: [],
            processed_ms: 1711654621411,
          },
          {
            leverage: 0.375,
            order_type: "LONG",
            order_uuid: "71114cc6-9eab-4def-a1c1-ec5a0f2d19b5",
            price: 70689.27,
            price_sources: [],
            processed_ms: 1711658239286,
          },
          {
            leverage: 0.375,
            order_type: "LONG",
            order_uuid: "2c3ffa24-e532-405a-8cf8-9a6f84954f0e",
            price: 70834.82,
            price_sources: [],
            processed_ms: 1711661848774,
          },
          {
            leverage: 0.375,
            order_type: "LONG",
            order_uuid: "31623cf2-e339-47e9-925c-6eabd0928d12",
            price: 70839.4,
            price_sources: [],
            processed_ms: 1711665463235,
          },
        ],
        position_type: "FLAT",
        position_uuid: "3ae85655-4bdf-49a9-b7ef-3e3a6da05fb6",
        return_at_close: 0.9977800509492433,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 61096.32399244572,
        close_ms: 1711784843415,
        current_return: 1.0061254430582462,
        initial_entry_price: 70538.7,
        is_closed_position: true,
        miner_hotkey: "5Fn3inHyUCr1GGUvSsj81U2tg7er9axPBqEn7GMeNtx52tDy",
        net_leverage: 0.0,
        open_ms: 1711687176300,
        orders: [
          {
            leverage: 1.25,
            order_type: "LONG",
            order_uuid: "22f316e0-37e1-4b39-8abf-832650ad5fa6",
            price: 70538.7,
            price_sources: [],
            processed_ms: 1711687176296,
          },
          {
            leverage: 0.375,
            order_type: "LONG",
            order_uuid: "f7c23f50-7850-4ecb-a004-14866be5bfb4",
            price: 70249.85,
            price_sources: [],
            processed_ms: 1711690808662,
          },
          {
            leverage: -0.8125,
            order_type: "SHORT",
            order_uuid: "962745ae-7207-4e64-8211-9647d49bee3c",
            price: 70447.54,
            price_sources: [],
            processed_ms: 1711694405442,
          },
          {
            leverage: 0.375,
            order_type: "LONG",
            order_uuid: "dac0b03e-b4a0-48d0-ad0e-3e4f07c13990",
            price: 69960.42,
            price_sources: [],
            processed_ms: 1711698040435,
          },
          {
            leverage: 0.375,
            order_type: "LONG",
            order_uuid: "216ccb27-a208-4596-a91a-6d9b74b7cb26",
            price: 69977.65,
            price_sources: [],
            processed_ms: 1711701643367,
          },
          {
            leverage: 0.375,
            order_type: "LONG",
            order_uuid: "7e24e48a-3f1c-4b19-8434-7e17d86a6240",
            price: 69680.98,
            price_sources: [],
            processed_ms: 1711705286743,
          },
          {
            leverage: 0.375,
            order_type: "LONG",
            order_uuid: "adfd18f2-8c6a-48d5-949a-2b0ba480df24",
            price: 69938.43,
            price_sources: [],
            processed_ms: 1711708876735,
          },
          {
            leverage: -1.15625,
            order_type: "SHORT",
            order_uuid: "1554e10d-ce0d-4aca-ae59-2e2af3d77cb0",
            price: 70219.75,
            price_sources: [],
            processed_ms: 1711712488675,
          },
          {
            leverage: -0.578125,
            order_type: "SHORT",
            order_uuid: "75510b59-bb13-431e-84da-374abd98bf47",
            price: 70097.38,
            price_sources: [],
            processed_ms: 1711716103320,
          },
          {
            leverage: -0.2890625,
            order_type: "SHORT",
            order_uuid: "8fdef5f2-456c-4a4e-bf9d-f84e8ceaba2f",
            price: 70569.11,
            price_sources: [],
            processed_ms: 1711719726571,
          },
          {
            leverage: -0.14453125,
            order_type: "SHORT",
            order_uuid: "8da06e16-8d91-454a-86c8-45d93e656232",
            price: 70366.33,
            price_sources: [],
            processed_ms: 1711723386583,
          },
          {
            leverage: 0.375,
            order_type: "LONG",
            order_uuid: "f4619145-48f3-48f1-8093-cf703f4f8dd3",
            price: 69283.16,
            price_sources: [],
            processed_ms: 1711726958249,
          },
          {
            leverage: 0.375,
            order_type: "LONG",
            order_uuid: "d2c4135c-f39d-4db7-b857-5509149d27d5",
            price: 69330.23,
            price_sources: [],
            processed_ms: 1711730572227,
          },
          {
            leverage: 0.375,
            order_type: "LONG",
            order_uuid: "d0748b80-3378-4590-90aa-f60f1994cab2",
            price: 69431.04,
            price_sources: [],
            processed_ms: 1711734190725,
          },
          {
            leverage: 0.375,
            order_type: "LONG",
            order_uuid: "77996a08-5f19-47fb-ae3c-3ebabe4216bf",
            price: 69501.39,
            price_sources: [],
            processed_ms: 1711737840056,
          },
          {
            leverage: -0.822265625,
            order_type: "SHORT",
            order_uuid: "cd4c5245-14ac-44eb-9c00-1e26c609ffe5",
            price: 69502.7,
            price_sources: [],
            processed_ms: 1711741440394,
          },
          {
            leverage: -0.4111328125,
            order_type: "SHORT",
            order_uuid: "d69431d9-9026-4630-8955-1bf037b93be1",
            price: 69657.76,
            price_sources: [],
            processed_ms: 1711745057740,
          },
          {
            leverage: -0.20556640625,
            order_type: "SHORT",
            order_uuid: "be043b63-8670-47f1-9a8d-91760340c84f",
            price: 69407.76,
            price_sources: [],
            processed_ms: 1711748696910,
          },
          {
            leverage: -0.102783203125,
            order_type: "SHORT",
            order_uuid: "b151b0a9-db3a-4222-8424-e4e8f3619fc0",
            price: 69738.32,
            price_sources: [],
            processed_ms: 1711752288154,
          },
          {
            leverage: -0.0513916015625,
            order_type: "SHORT",
            order_uuid: "ef18b6a0-e43f-4495-91cc-960ffee449f1",
            price: 69937.03,
            price_sources: [],
            processed_ms: 1711755906450,
          },
          {
            leverage: -0.02569580078125,
            order_type: "SHORT",
            order_uuid: "c7fa0c03-833c-4deb-8b00-aa707cff5433",
            price: 69932.97,
            price_sources: [],
            processed_ms: 1711759539034,
          },
          {
            leverage: 0.375,
            order_type: "LONG",
            order_uuid: "08e06245-ee81-4ba0-b0c5-c2b86d3b9fd7",
            price: 70093.29,
            price_sources: [],
            processed_ms: 1711763131793,
          },
          {
            leverage: 0.375,
            order_type: "LONG",
            order_uuid: "da041019-d044-4e2d-9bf1-da1ff85ad5e5",
            price: 70015.51,
            price_sources: [],
            processed_ms: 1711766754319,
          },
          {
            leverage: -0.387847900390625,
            order_type: "SHORT",
            order_uuid: "cfe9f8fc-8dba-4f93-a0a3-5f2de92d25cc",
            price: 69887.82,
            price_sources: [],
            processed_ms: 1711770368823,
          },
          {
            leverage: -0.1939239501953125,
            order_type: "SHORT",
            order_uuid: "41472fca-e112-4973-8e53-e704348d5470",
            price: 69994.25,
            price_sources: [],
            processed_ms: 1711773985305,
          },
          {
            leverage: -0.09696197509765625,
            order_type: "SHORT",
            order_uuid: "1d30a21d-b7a7-4321-b457-4eeb08af7cee",
            price: 70077.88,
            price_sources: [],
            processed_ms: 1711777596256,
          },
          {
            leverage: -0.048480987548828125,
            order_type: "SHORT",
            order_uuid: "795db387-fa4a-40b8-aa54-936a8396d115",
            price: 70043.14,
            price_sources: [],
            processed_ms: 1711781223000,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "c38fe233-8bc0-498d-9914-6ee756e204fa",
            price: 70008.7,
            price_sources: [],
            processed_ms: 1711784843415,
          },
        ],
        position_type: "FLAT",
        position_uuid: "b9e69038-5580-49e0-8076-93a75befcd5c",
        return_at_close: 1.0014721128841018,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 68007.66428571442,
        close_ms: 1711810185503,
        current_return: 0.9987126905370926,
        initial_entry_price: 70069.69,
        is_closed_position: true,
        miner_hotkey: "5Fn3inHyUCr1GGUvSsj81U2tg7er9axPBqEn7GMeNtx52tDy",
        net_leverage: 0.0,
        open_ms: 1711792121003,
        orders: [
          {
            leverage: -1.25,
            order_type: "SHORT",
            order_uuid: "656de029-ada2-4f90-9e01-fe6abb203309",
            price: 70069.69,
            price_sources: [],
            processed_ms: 1711792120998,
          },
          {
            leverage: 0.375,
            order_type: "LONG",
            order_uuid: "12060e68-c2e8-45f9-8003-97463b79a4f6",
            price: 70035.68,
            price_sources: [],
            processed_ms: 1711795707865,
          },
          {
            leverage: 0.4375,
            order_type: "LONG",
            order_uuid: "1554a3bb-6361-4846-9d3e-116248968451",
            price: 70183.2,
            price_sources: [],
            processed_ms: 1711799325304,
          },
          {
            leverage: 0.21875,
            order_type: "LONG",
            order_uuid: "6457622d-117d-4b47-a2ea-ca3258f79e17",
            price: 70242.33,
            price_sources: [],
            processed_ms: 1711803004964,
          },
          {
            leverage: 0.175,
            order_type: "LONG",
            order_uuid: "5f0c8195-80fc-4adc-8f20-a189d624afb6",
            price: 70158.5,
            price_sources: [],
            processed_ms: 1711806572502,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "5c6fa05d-cee7-49f4-a6f7-945eed947897",
            price: 70069.41,
            price_sources: [],
            processed_ms: 1711810185503,
          },
        ],
        position_type: "FLAT",
        position_uuid: "e5aa3e52-c70d-487c-9c65-242734aa9e9a",
        return_at_close: 0.9962159088107498,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 73019.19999999988,
        close_ms: 1711835497234,
        current_return: 1.0036631587805376,
        initial_entry_price: 70201.11,
        is_closed_position: true,
        miner_hotkey: "5Fn3inHyUCr1GGUvSsj81U2tg7er9axPBqEn7GMeNtx52tDy",
        net_leverage: 0.0,
        open_ms: 1711817419562,
        orders: [
          {
            leverage: -1.25,
            order_type: "SHORT",
            order_uuid: "31f020c4-a514-46c7-92bd-5d1afd7837ba",
            price: 70201.11,
            price_sources: [],
            processed_ms: 1711817419559,
          },
          {
            leverage: 0.625,
            order_type: "LONG",
            order_uuid: "4805d154-9603-4d9a-8064-f4c9ad72dd80",
            price: 70062.21,
            price_sources: [],
            processed_ms: 1711821031450,
          },
          {
            leverage: 0.3125,
            order_type: "LONG",
            order_uuid: "8cadd00c-7d10-48c5-befe-cefc220afbb3",
            price: 70003.86,
            price_sources: [],
            processed_ms: 1711824654301,
          },
          {
            leverage: 0.15625,
            order_type: "LONG",
            order_uuid: "81818e21-6d33-4333-8987-950ca329326b",
            price: 69903.78,
            price_sources: [],
            processed_ms: 1711828272305,
          },
          {
            leverage: 0.078125,
            order_type: "LONG",
            order_uuid: "eb26fea3-4da3-4404-92ad-86225d8ad581",
            price: 69877.88,
            price_sources: [],
            processed_ms: 1711831882361,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "6e832383-e3fb-4c50-af06-68f2319877a4",
            price: 69727.58,
            price_sources: [],
            processed_ms: 1711835497234,
          },
        ],
        position_type: "FLAT",
        position_uuid: "82ed48ff-8260-4813-bce8-686b95123eb2",
        return_at_close: 1.0011540008835862,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 66794.46000000004,
        close_ms: 1711860839216,
        current_return: 0.9981609849560457,
        initial_entry_price: 69728.01,
        is_closed_position: true,
        miner_hotkey: "5Fn3inHyUCr1GGUvSsj81U2tg7er9axPBqEn7GMeNtx52tDy",
        net_leverage: 0.0,
        open_ms: 1711839126270,
        orders: [
          {
            leverage: -1.25,
            order_type: "SHORT",
            order_uuid: "4e2659ef-7f16-4e1a-9f83-f668480ac92c",
            price: 69728.01,
            price_sources: [],
            processed_ms: 1711839126266,
          },
          {
            leverage: 0.625,
            order_type: "LONG",
            order_uuid: "94b96f9d-ee6c-40f1-b543-d315b083d4d8",
            price: 69691.84,
            price_sources: [],
            processed_ms: 1711842742171,
          },
          {
            leverage: 0.3125,
            order_type: "LONG",
            order_uuid: "35982ac2-1879-4233-8138-aef8931f7e57",
            price: 69917.66,
            price_sources: [],
            processed_ms: 1711846357236,
          },
          {
            leverage: 0.15625,
            order_type: "LONG",
            order_uuid: "1331c121-3a0f-4526-aa9f-40350a8f3861",
            price: 70066.22,
            price_sources: [],
            processed_ms: 1711849989226,
          },
          {
            leverage: 0.078125,
            order_type: "LONG",
            order_uuid: "ae5d06cf-34d6-4105-a3f9-efd5f8a69c44",
            price: 69951.86,
            price_sources: [],
            processed_ms: 1711853610392,
          },
          {
            leverage: 0.0390625,
            order_type: "LONG",
            order_uuid: "208f67e6-e712-4262-8a27-2113156de660",
            price: 69922.54,
            price_sources: [],
            processed_ms: 1711857219001,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "3b9e3a4b-2edf-47ca-8fe8-1593c11a28c9",
            price: 70077.17,
            price_sources: [],
            processed_ms: 1711860839216,
          },
        ],
        position_type: "FLAT",
        position_uuid: "c212b2b6-7152-4403-b382-f543ab8a740a",
        return_at_close: 0.9956655824936556,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 70335.86000000002,
        close_ms: 1711878950156,
        current_return: 1.0003555749890314,
        initial_entry_price: 70291.08,
        is_closed_position: true,
        miner_hotkey: "5Fn3inHyUCr1GGUvSsj81U2tg7er9axPBqEn7GMeNtx52tDy",
        net_leverage: 0.0,
        open_ms: 1711868077768,
        orders: [
          {
            leverage: -1.25,
            order_type: "SHORT",
            order_uuid: "0be164b7-183f-4f18-8d93-54317334be42",
            price: 70291.08,
            price_sources: [],
            processed_ms: 1711868077765,
          },
          {
            leverage: 0.625,
            order_type: "LONG",
            order_uuid: "55fbc200-ccf6-4156-bd34-cc3def95c8a8",
            price: 70285.38,
            price_sources: [],
            processed_ms: 1711871754129,
          },
          {
            leverage: 0.3125,
            order_type: "LONG",
            order_uuid: "1bf8dbe9-f544-4464-80b1-dc6ea8f8b3d3",
            price: 70257.7,
            price_sources: [],
            processed_ms: 1711875333377,
          },
          {
            leverage: 0.375,
            order_type: "LONG",
            order_uuid: "768871b4-4f58-4173-845b-4866ac72c598",
            price: 70255.88,
            price_sources: [],
            processed_ms: 1711878950156,
          },
        ],
        position_type: "FLAT",
        position_uuid: "e4aba1e6-f3a0-4866-aa52-6d6e3e2ff20e",
        return_at_close: 0.9978546860515588,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 68202.14000000004,
        close_ms: 1711897037604,
        current_return: 0.9958157478744523,
        initial_entry_price: 70294.82,
        is_closed_position: true,
        miner_hotkey: "5Fn3inHyUCr1GGUvSsj81U2tg7er9axPBqEn7GMeNtx52tDy",
        net_leverage: 0.0,
        open_ms: 1711882597063,
        orders: [
          {
            leverage: -1.25,
            order_type: "SHORT",
            order_uuid: "4122bdd7-cdd8-4e1b-8523-2b087b825bf4",
            price: 70294.82,
            price_sources: [],
            processed_ms: 1711882597059,
          },
          {
            leverage: 0.375,
            order_type: "LONG",
            order_uuid: "a1a739b5-ae55-4637-803a-c372abda69f0",
            price: 70429.96,
            price_sources: [],
            processed_ms: 1711886181095,
          },
          {
            leverage: 0.375,
            order_type: "LONG",
            order_uuid: "605f7427-ccbf-4591-8894-26691863b247",
            price: 70519.46,
            price_sources: [],
            processed_ms: 1711889805115,
          },
          {
            leverage: 0.375,
            order_type: "LONG",
            order_uuid: "869c8fd7-50a4-470d-aeb2-f8579be7a1b6",
            price: 70632.6,
            price_sources: [],
            processed_ms: 1711893434679,
          },
          {
            leverage: 0.375,
            order_type: "LONG",
            order_uuid: "586d3b49-6f68-4c83-8972-716f03a50f0d",
            price: 70555.19,
            price_sources: [],
            processed_ms: 1711897037604,
          },
        ],
        position_type: "FLAT",
        position_uuid: "7c98e7f8-1153-4d24-ae7f-8cfbe4b7bae7",
        return_at_close: 0.9933262085047662,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 69419.62499999996,
        close_ms: 1711915123248,
        current_return: 0.994329146413047,
        initial_entry_price: 70376.31,
        is_closed_position: true,
        miner_hotkey: "5Fn3inHyUCr1GGUvSsj81U2tg7er9axPBqEn7GMeNtx52tDy",
        net_leverage: 0.0,
        open_ms: 1711900644486,
        orders: [
          {
            leverage: -1.25,
            order_type: "SHORT",
            order_uuid: "8cf948f2-688f-4332-9b90-0ea38ec87b46",
            price: 70376.31,
            price_sources: [],
            processed_ms: 1711900644477,
          },
          {
            leverage: 0.375,
            order_type: "LONG",
            order_uuid: "2b9918eb-0cf1-4c4b-81b0-12505baf2a2a",
            price: 70468.01,
            price_sources: [],
            processed_ms: 1711904266355,
          },
          {
            leverage: 0.375,
            order_type: "LONG",
            order_uuid: "1349fb8b-0356-48c7-af6d-d8e839546ae1",
            price: 70498.24,
            price_sources: [],
            processed_ms: 1711907892280,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "5306c577-9a9b-4c53-be30-1d6a793872a8",
            price: 71012.55,
            price_sources: [],
            processed_ms: 1711911507837,
          },
          {
            leverage: 0.375,
            order_type: "LONG",
            order_uuid: "eaee530e-6bb7-44dd-9305-ae840d4e2de0",
            price: 71016.0,
            price_sources: [],
            processed_ms: 1711915123248,
          },
        ],
        position_type: "FLAT",
        position_uuid: "bde7503c-234c-4862-80b3-450b5c839931",
        return_at_close: 0.9918433235470144,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 70269.39500000002,
        close_ms: 1711933200722,
        current_return: 0.9967954884146739,
        initial_entry_price: 70847.38,
        is_closed_position: true,
        miner_hotkey: "5Fn3inHyUCr1GGUvSsj81U2tg7er9axPBqEn7GMeNtx52tDy",
        net_leverage: 0.0,
        open_ms: 1711918735466,
        orders: [
          {
            leverage: -1.25,
            order_type: "SHORT",
            order_uuid: "b842a7e2-8d41-43bc-a3bb-9548231f29bf",
            price: 70847.38,
            price_sources: [],
            processed_ms: 1711918735462,
          },
          {
            leverage: 0.375,
            order_type: "LONG",
            order_uuid: "5aa1c2da-4733-474f-b801-2f058a9b90dc",
            price: 70791.37,
            price_sources: [],
            processed_ms: 1711922368299,
          },
          {
            leverage: 0.375,
            order_type: "LONG",
            order_uuid: "32061d10-c97d-45bc-b50f-b3bc926241ba",
            price: 70961.72,
            price_sources: [],
            processed_ms: 1711925971698,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "eb0cadbd-2200-4d08-9ee2-5dc6789068d0",
            price: 71337.87,
            price_sources: [],
            processed_ms: 1711929580253,
          },
          {
            leverage: 0.375,
            order_type: "LONG",
            order_uuid: "20ac1158-4905-4b91-8c13-e05989b82dbe",
            price: 71177.52,
            price_sources: [],
            processed_ms: 1711933200722,
          },
        ],
        position_type: "FLAT",
        position_uuid: "529ccfc0-6932-4d94-9be6-bd571dd7a231",
        return_at_close: 0.9943034996936373,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 86438.05000000013,
        close_ms: 1711954915168,
        current_return: 1.0059372810748777,
        initial_entry_price: 70857.56,
        is_closed_position: true,
        miner_hotkey: "5Fn3inHyUCr1GGUvSsj81U2tg7er9axPBqEn7GMeNtx52tDy",
        net_leverage: 0.0,
        open_ms: 1711936831321,
        orders: [
          {
            leverage: -1.25,
            order_type: "SHORT",
            order_uuid: "64be22cc-bc4f-41a3-827a-635c8e1bd0ec",
            price: 70857.56,
            price_sources: [],
            processed_ms: 1711936831316,
          },
          {
            leverage: 0.375,
            order_type: "LONG",
            order_uuid: "d1a537cc-e2fd-49fa-a69b-05f0f3a137a0",
            price: 70892.1,
            price_sources: [],
            processed_ms: 1711940465556,
          },
          {
            leverage: 0.375,
            order_type: "LONG",
            order_uuid: "71baf4ca-c6ab-4cd3-86cc-c8fc4d008180",
            price: 70597.01,
            price_sources: [],
            processed_ms: 1711944054534,
          },
          {
            leverage: 0.375,
            order_type: "LONG",
            order_uuid: "7c08dc15-f36d-40ca-a07d-4e3f23a010ee",
            price: 70468.78,
            price_sources: [],
            processed_ms: 1711947690750,
          },
          {
            leverage: 0.1,
            order_type: "LONG",
            order_uuid: "2ba46e36-1e45-49a9-a176-0632b004b3fc",
            price: 69267.9,
            price_sources: [],
            processed_ms: 1711951305650,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "7ccded8c-9a18-4eea-9156-0128c9b9d570",
            price: 69610.0,
            price_sources: [],
            processed_ms: 1711954915168,
          },
        ],
        position_type: "FLAT",
        position_uuid: "04fb46da-83d7-4705-a9a4-8fd776341584",
        return_at_close: 1.0034224378721905,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 70044.25,
        close_ms: 1711969440469,
        current_return: 1.0012369175977534,
        initial_entry_price: 69578.2,
        is_closed_position: true,
        miner_hotkey: "5Fn3inHyUCr1GGUvSsj81U2tg7er9axPBqEn7GMeNtx52tDy",
        net_leverage: 0.0,
        open_ms: 1711958535248,
        orders: [
          {
            leverage: -1.25,
            order_type: "SHORT",
            order_uuid: "857e8f32-9ae5-43e5-a388-d830037cab11",
            price: 69578.2,
            price_sources: [],
            processed_ms: 1711958535244,
          },
          {
            leverage: 0.625,
            order_type: "LONG",
            order_uuid: "bb18ee07-d55a-4799-a1d1-b3cd578884fd",
            price: 69464.2,
            price_sources: [],
            processed_ms: 1711962194472,
          },
          {
            leverage: 0.375,
            order_type: "LONG",
            order_uuid: "4585ff79-0025-4bea-b2b3-e291d32a92d9",
            price: 69457.5,
            price_sources: [],
            processed_ms: 1711965811992,
          },
          {
            leverage: 0.375,
            order_type: "LONG",
            order_uuid: "a0c968b0-8905-4d59-8fd5-fbee22f7c4cf",
            price: 69700.0,
            price_sources: [],
            processed_ms: 1711969440469,
          },
        ],
        position_type: "FLAT",
        position_uuid: "02c18164-60e7-41b0-90b5-d0eef825de2b",
        return_at_close: 0.998733825303759,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 69008.95822784818,
        close_ms: 1712034582666,
        current_return: 0.9797967588744103,
        initial_entry_price: 68810.2,
        is_closed_position: true,
        miner_hotkey: "5Fn3inHyUCr1GGUvSsj81U2tg7er9axPBqEn7GMeNtx52tDy",
        net_leverage: 0.0,
        open_ms: 1711983876026,
        orders: [
          {
            leverage: 1.25,
            order_type: "LONG",
            order_uuid: "5f8f2643-92c9-4ad5-ae96-cd23346a230c",
            price: 68810.2,
            price_sources: [],
            processed_ms: 1711983876022,
          },
          {
            leverage: 0.375,
            order_type: "LONG",
            order_uuid: "2fc92ee5-7777-4a75-8d6e-fb707a01931a",
            price: 68388.1,
            price_sources: [],
            processed_ms: 1711987509202,
          },
          {
            leverage: -0.8125,
            order_type: "SHORT",
            order_uuid: "58efccc9-7b28-474d-89d0-1808fe6e8973",
            price: 68551.5,
            price_sources: [],
            processed_ms: 1711991135394,
          },
          {
            leverage: 0.375,
            order_type: "LONG",
            order_uuid: "5daa7b9a-a8e3-4415-80c3-382631844254",
            price: 68508.0,
            price_sources: [],
            processed_ms: 1711994767070,
          },
          {
            leverage: -0.59375,
            order_type: "SHORT",
            order_uuid: "af900e92-7537-4188-bd32-8cda0fedfe87",
            price: 69048.1,
            price_sources: [],
            processed_ms: 1711998382072,
          },
          {
            leverage: 0.375,
            order_type: "LONG",
            order_uuid: "b17df269-7c32-471c-b52d-e99738a0181d",
            price: 69653.0,
            price_sources: [],
            processed_ms: 1712001989842,
          },
          {
            leverage: 0.375,
            order_type: "LONG",
            order_uuid: "ded5f708-5d3b-4f0f-a976-ee2046dd7011",
            price: 69662.5,
            price_sources: [],
            processed_ms: 1712005678060,
          },
          {
            leverage: 0.375,
            order_type: "LONG",
            order_uuid: "7e9744e1-fd28-479c-811e-36f82598cb45",
            price: 69689.7,
            price_sources: [],
            processed_ms: 1712009230946,
          },
          {
            leverage: 0.375,
            order_type: "LONG",
            order_uuid: "588ffa57-b9da-49b0-a0a9-59dc281c3b34",
            price: 69836.8,
            price_sources: [],
            processed_ms: 1712012843155,
          },
          {
            leverage: 0.375,
            order_type: "LONG",
            order_uuid: "b1168678-ec46-4472-8d7d-20f6bee47a1f",
            price: 69566.9,
            price_sources: [],
            processed_ms: 1712016464104,
          },
          {
            leverage: -1.234375,
            order_type: "SHORT",
            order_uuid: "6048484e-4fee-4d0c-ab31-0eef7047ee81",
            price: 69611.9,
            price_sources: [],
            processed_ms: 1712020076213,
          },
          {
            leverage: -0.6171875,
            order_type: "SHORT",
            order_uuid: "534ffd30-a5ba-4d63-9bd1-aa7c7ba1cdd4",
            price: 69327.5,
            price_sources: [],
            processed_ms: 1712023701861,
          },
          {
            leverage: -1.09375,
            order_type: "SHORT",
            order_uuid: "580e0211-f7bf-4c22-b923-3d3680ecde4a",
            price: 66756.5,
            price_sources: [],
            processed_ms: 1712034582666,
          },
        ],
        position_type: "FLAT",
        position_uuid: "6d5245c5-442a-4118-9252-c1a2bd705947",
        return_at_close: 0.9749590123774678,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 66827.16666666667,
        close_ms: 1712045407952,
        current_return: 1.0005647084139924,
        initial_entry_price: 66845.2,
        is_closed_position: true,
        miner_hotkey: "5Fn3inHyUCr1GGUvSsj81U2tg7er9axPBqEn7GMeNtx52tDy",
        net_leverage: 0.0,
        open_ms: 1712038235135,
        orders: [
          {
            leverage: -0.13671875,
            order_type: "SHORT",
            order_uuid: "8d343579-19cd-4e1e-ac77-81dc0e1c4af5",
            price: 66845.2,
            price_sources: [],
            processed_ms: 1712038235131,
          },
          {
            leverage: -0.068359375,
            order_type: "SHORT",
            order_uuid: "08c07148-ab0b-43e3-8286-6c4c0a5bbc47",
            price: 66791.1,
            price_sources: [],
            processed_ms: 1712041789479,
          },
          {
            leverage: 0.375,
            order_type: "LONG",
            order_uuid: "c4262689-7e82-46c4-b547-4a71078837d3",
            price: 66643.1,
            price_sources: [],
            processed_ms: 1712045407952,
          },
        ],
        position_type: "FLAT",
        position_uuid: "feea042c-2608-48c0-be5b-b28b06a3c8a4",
        return_at_close: 1.000154320545307,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 66112.45,
        close_ms: 1712054860755,
        current_return: 0.9960639020611226,
        initial_entry_price: 66090.2,
        is_closed_position: true,
        miner_hotkey: "5Fn3inHyUCr1GGUvSsj81U2tg7er9axPBqEn7GMeNtx52tDy",
        net_leverage: 0.0,
        open_ms: 1712049024154,
        orders: [
          {
            leverage: 0.375,
            order_type: "LONG",
            order_uuid: "a461cb66-2fc4-4648-b284-1c4b3360e28a",
            price: 66090.2,
            price_sources: [],
            processed_ms: 1712049024150,
          },
          {
            leverage: 0.375,
            order_type: "LONG",
            order_uuid: "67212e5c-5418-4b6e-8ce0-f187140ce9a5",
            price: 66134.7,
            price_sources: [],
            processed_ms: 1712052644190,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "74da207c-b584-4ed6-8794-aef9ce9f4e3c",
            price: 65765.6,
            price_sources: [],
            processed_ms: 1712054860755,
          },
        ],
        position_type: "FLAT",
        position_uuid: "eb011454-a909-4678-b2e3-62c6df1d992c",
        return_at_close: 0.9945698062080309,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 65586.53125,
        close_ms: 1712065030586,
        current_return: 0.9710572730483713,
        initial_entry_price: 65683.6,
        is_closed_position: true,
        miner_hotkey: "5Fn3inHyUCr1GGUvSsj81U2tg7er9axPBqEn7GMeNtx52tDy",
        net_leverage: 0.0,
        open_ms: 1712056270027,
        orders: [
          {
            leverage: 1.25,
            order_type: "LONG",
            order_uuid: "f29192f6-148b-42fe-8bf2-d9f1d33d13d4",
            price: 65683.6,
            price_sources: [],
            processed_ms: 1712056270022,
          },
          {
            leverage: 0.375,
            order_type: "LONG",
            order_uuid: "b6d23242-7842-4ac9-95e6-5577fd627c21",
            price: 65743.5,
            price_sources: [],
            processed_ms: 1712059897153,
          },
          {
            leverage: 0.375,
            order_type: "LONG",
            order_uuid: "06f7de82-3e5b-4523-b918-b52177e3b326",
            price: 65106.0,
            price_sources: [],
            processed_ms: 1712063529309,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "ac4eff9b-46d1-4252-b072-9a6d7b0ca3a0",
            price: 64636.0,
            price_sources: [],
            processed_ms: 1712065030586,
          },
        ],
        position_type: "FLAT",
        position_uuid: "dd00bb8f-4358-4929-a150-9533f4f14d5d",
        return_at_close: 0.9671730439561779,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 65424.542857142886,
        close_ms: 1712102409059,
        current_return: 1.0004108289394542,
        initial_entry_price: 65507.8,
        is_closed_position: true,
        miner_hotkey: "5Fn3inHyUCr1GGUvSsj81U2tg7er9axPBqEn7GMeNtx52tDy",
        net_leverage: 0.875,
        open_ms: 1712067125389,
        orders: [
          {
            leverage: 1.25,
            order_type: "LONG",
            order_uuid: "ec3966f9-bbe4-4dc3-aece-59ad2eb1be42",
            price: 65507.8,
            price_sources: [],
            processed_ms: 1712067125385,
          },
          {
            leverage: -0.625,
            order_type: "SHORT",
            order_uuid: "88460e9f-b51c-4001-ac5e-ae3e8fc4971b",
            price: 66095.5,
            price_sources: [],
            processed_ms: 1712070737979,
          },
          {
            leverage: 0.375,
            order_type: "LONG",
            order_uuid: "c5ee6670-5cae-402a-81e7-2ceb3fdbe40b",
            price: 65315.9,
            price_sources: [],
            processed_ms: 1712074359195,
          },
          {
            leverage: -0.5,
            order_type: "SHORT",
            order_uuid: "b04adaef-f8c3-439d-b6a9-2de326169f39",
            price: 65595.5,
            price_sources: [],
            processed_ms: 1712077975284,
          },
          {
            leverage: -0.25,
            order_type: "SHORT",
            order_uuid: "9be4c979-e1c5-467e-8266-8e8b6c76a15d",
            price: 65513.1,
            price_sources: [],
            processed_ms: 1712081599244,
          },
          {
            leverage: 0.375,
            order_type: "LONG",
            order_uuid: "7f2fe51b-b9dd-4cd6-80bd-aa045173dfbc",
            price: 66084.0,
            price_sources: [],
            processed_ms: 1712085233756,
          },
          {
            leverage: 0.375,
            order_type: "LONG",
            order_uuid: "6befd639-34f9-4deb-8fd2-1a25a606c5f5",
            price: 66109.0,
            price_sources: [],
            processed_ms: 1712088827917,
          },
          {
            leverage: 0.375,
            order_type: "LONG",
            order_uuid: "e71ed478-316f-4613-a528-71e3829c2912",
            price: 65754.0,
            price_sources: [],
            processed_ms: 1712092446427,
          },
          {
            leverage: 0.375,
            order_type: "LONG",
            order_uuid: "60208b2a-309b-4456-8093-ee42ffd2c84d",
            price: 65719.4,
            price_sources: [],
            processed_ms: 1712096057116,
          },
          {
            leverage: -0.875,
            order_type: "SHORT",
            order_uuid: "74a0c3d9-a829-4e55-80ab-cad560744f1b",
            price: 65738.2,
            price_sources: [],
            processed_ms: 1712099679789,
          },
        ],
        position_type: "FLAT",
        position_uuid: "805c3320-a729-4f77-a7ac-9fd3c38b136a",
        return_at_close: 0.9969093910381662,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
    ],
    thirty_day_returns: 1.0,
  },
  "5FqSBwa7KXvv8piHdMyVbcXQwNWvT9WjHZGHAQwtoGVQD3vo": {
    all_time_returns: 0.8465614227233025,
    n_positions: 14,
    percentage_profitable: 0.35714285714285715,
    positions: [
      {
        average_entry_price: 97.51050000000001,
        close_ms: 1718980347773,
        current_return: 1.0000512765291945,
        initial_entry_price: 97.51050000000001,
        is_closed_position: true,
        miner_hotkey: "5FqSBwa7KXvv8piHdMyVbcXQwNWvT9WjHZGHAQwtoGVQD3vo",
        net_leverage: 0.0,
        open_ms: 1718980164559,
        orders: [
          {
            leverage: -1.0,
            order_type: "SHORT",
            order_uuid: "4c0ca25a-44c2-419a-a826-142ce864ce70",
            price: 97.51050000000001,
            price_sources: [],
            processed_ms: 1718980164556,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "043b9775-a325-41bc-bd2f-7f248138abb1",
            price: 97.5055,
            price_sources: [],
            processed_ms: 1718980347773,
          },
        ],
        position_type: "FLAT",
        position_uuid: "e9ef1fb8-909e-4bb6-917c-91f4c1e235bc",
        return_at_close: 0.9999812729398374,
        trade_pair: ["NZDJPY", "NZD/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 159.50933333333333,
        close_ms: 1719224747245,
        current_return: 1.001926286596308,
        initial_entry_price: 159.374,
        is_closed_position: true,
        miner_hotkey: "5FqSBwa7KXvv8piHdMyVbcXQwNWvT9WjHZGHAQwtoGVQD3vo",
        net_leverage: 0.0,
        open_ms: 1718980019355,
        orders: [
          {
            leverage: -1.0,
            order_type: "SHORT",
            order_uuid: "f3512d53-3a62-4d79-b96b-a27c1a74f318",
            price: 159.374,
            price_sources: [],
            processed_ms: 1718980019321,
          },
          {
            leverage: -1.0,
            order_type: "SHORT",
            order_uuid: "64858e18-364a-43b9-af79-fbcd84e100ed",
            price: 159.3985,
            price_sources: [],
            processed_ms: 1718981563942,
          },
          {
            leverage: -1.0,
            order_type: "SHORT",
            order_uuid: "e4402246-fb2d-4872-8c4e-8b87b5cbf6ab",
            price: 159.75549999999998,
            price_sources: [],
            processed_ms: 1719210587219,
          },
          {
            leverage: 0.01,
            order_type: "FLAT",
            order_uuid: "3dddde5c-0619-4935-91eb-32232a8419e2",
            price: 159.407,
            price_sources: [],
            processed_ms: 1719224747245,
          },
        ],
        position_type: "FLAT",
        position_uuid: "d687ebb0-ab07-40e0-9850-60a9d840518a",
        return_at_close: 1.0017158820761227,
        trade_pair: ["USDJPY", "USD/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 159.433,
        close_ms: 1719293983354,
        current_return: 1.00059586158449,
        initial_entry_price: 159.433,
        is_closed_position: true,
        miner_hotkey: "5FqSBwa7KXvv8piHdMyVbcXQwNWvT9WjHZGHAQwtoGVQD3vo",
        net_leverage: 0.0,
        open_ms: 1719234600446,
        orders: [
          {
            leverage: -1.0,
            order_type: "SHORT",
            order_uuid: "d37e3b4b-6a47-465e-9ced-fd5f1d37cdcf",
            price: 159.433,
            price_sources: [],
            processed_ms: 1719234600251,
          },
          {
            leverage: 0.01,
            order_type: "FLAT",
            order_uuid: "ddfca9e6-3881-4c27-9cf1-1b5cb51e7e62",
            price: 159.338,
            price_sources: [],
            processed_ms: 1719293983354,
          },
        ],
        position_type: "FLAT",
        position_uuid: "92c88eb1-03e1-4899-a558-45f9414b202c",
        return_at_close: 1.000525819874179,
        trade_pair: ["USDJPY", "USD/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 1.46589,
        close_ms: 1719300930024,
        current_return: 0.998669750117676,
        initial_entry_price: 1.46589,
        is_closed_position: true,
        miner_hotkey: "5FqSBwa7KXvv8piHdMyVbcXQwNWvT9WjHZGHAQwtoGVQD3vo",
        net_leverage: 0.0,
        open_ms: 1719295201523,
        orders: [
          {
            leverage: 1.0,
            order_type: "LONG",
            order_uuid: "4ce88580-1053-4ca5-99d2-998ca4f2d1eb",
            price: 1.46589,
            price_sources: [],
            processed_ms: 1719295201498,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "2f9f986a-62da-4d65-a3b3-8475a927addb",
            price: 1.46394,
            price_sources: [],
            processed_ms: 1719300930024,
          },
        ],
        position_type: "FLAT",
        position_uuid: "121fbe8c-e1a0-4242-8fd9-9a1c7407cf86",
        return_at_close: 0.9985998432351677,
        trade_pair: ["EURCAD", "EUR/CAD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.91026,
        close_ms: 1719318960011,
        current_return: 0.9971876697872047,
        initial_entry_price: 0.91026,
        is_closed_position: true,
        miner_hotkey: "5FqSBwa7KXvv8piHdMyVbcXQwNWvT9WjHZGHAQwtoGVQD3vo",
        net_leverage: 0.0,
        open_ms: 1719300764762,
        orders: [
          {
            leverage: 1.0,
            order_type: "LONG",
            order_uuid: "ab31282c-699d-4ad2-a902-7e4c58763ab2",
            price: 0.91026,
            price_sources: [],
            processed_ms: 1719300764760,
          },
          {
            leverage: 3.0,
            order_type: "FLAT",
            order_uuid: "2f7820ea-9ef5-4f4e-9eb9-b3fc38646ea3",
            price: 0.907700048300501,
            price_sources: [],
            processed_ms: 1719318960011,
          },
        ],
        position_type: "FLAT",
        position_uuid: "82eb8884-cce8-42a9-a005-cc1e42b7b7e9",
        return_at_close: 0.9971178666503197,
        trade_pair: ["AUDCAD", "AUD/CAD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.8366404738712248,
        close_ms: 1719318989833,
        current_return: 0.9969613555654713,
        initial_entry_price: 0.8362109477424495,
        is_closed_position: true,
        miner_hotkey: "5FqSBwa7KXvv8piHdMyVbcXQwNWvT9WjHZGHAQwtoGVQD3vo",
        net_leverage: 0.0,
        open_ms: 1719300960508,
        orders: [
          {
            leverage: 1.0,
            order_type: "LONG",
            order_uuid: "2a661e2e-ee5f-404f-87b0-4a4481cfc92a",
            price: 0.8362109477424495,
            price_sources: [],
            processed_ms: 1719300960335,
          },
          {
            leverage: 1.0,
            order_type: "LONG",
            order_uuid: "5caa8605-e240-4297-a041-e7ed69bae620",
            price: 0.83707,
            price_sources: [],
            processed_ms: 1719316876461,
          },
          {
            leverage: 3.0,
            order_type: "FLAT",
            order_uuid: "dc487e3d-795b-4b7f-86a7-ac52a63b73bf",
            price: 0.83537,
            price_sources: [],
            processed_ms: 1719318989833,
          },
        ],
        position_type: "FLAT",
        position_uuid: "628f38a5-8fe2-4066-a025-ce9287bce8ae",
        return_at_close: 0.9968217809756921,
        trade_pair: ["NZDCAD", "NZD/CAD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.9076046409410921,
        close_ms: 1719338334213,
        current_return: 1.0001979286277183,
        initial_entry_price: 0.9076046409410921,
        is_closed_position: true,
        miner_hotkey: "5FqSBwa7KXvv8piHdMyVbcXQwNWvT9WjHZGHAQwtoGVQD3vo",
        net_leverage: 0.0,
        open_ms: 1719319426515,
        orders: [
          {
            leverage: -1.0,
            order_type: "SHORT",
            order_uuid: "7d523f29-c7a4-4ce6-a04c-0eef1fe859e5",
            price: 0.9076046409410921,
            price_sources: [],
            processed_ms: 1719319426492,
          },
          {
            leverage: 1.5,
            order_type: "FLAT",
            order_uuid: "e9939d6f-337d-432e-aedd-fe9d21e55daa",
            price: 0.9074249999999999,
            price_sources: [],
            processed_ms: 1719338334213,
          },
        ],
        position_type: "FLAT",
        position_uuid: "6f767883-ceed-4203-8cff-9f6bf506c531",
        return_at_close: 1.0001279147727142,
        trade_pair: ["AUDCAD", "AUD/CAD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 1.071135,
        close_ms: 1719338870511,
        current_return: 0.9997339270960243,
        initial_entry_price: 1.071135,
        is_closed_position: true,
        miner_hotkey: "5FqSBwa7KXvv8piHdMyVbcXQwNWvT9WjHZGHAQwtoGVQD3vo",
        net_leverage: 0.0,
        open_ms: 1719319396907,
        orders: [
          {
            leverage: -1.0,
            order_type: "SHORT",
            order_uuid: "8ce98477-f425-422b-92a1-40b3a5fc1a62",
            price: 1.071135,
            price_sources: [],
            processed_ms: 1719319396899,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "5f274311-e500-4f1f-834a-14cf8765fbe5",
            price: 1.07142,
            price_sources: [],
            processed_ms: 1719338870511,
          },
        ],
        position_type: "FLAT",
        position_uuid: "bceedb47-631e-4caf-b7a9-03b9b26b3bfe",
        return_at_close: 0.9996639457211275,
        trade_pair: ["EURUSD", "EUR/USD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 1.07398,
        close_ms: 1720078561600,
        current_return: 0.9949999068883966,
        initial_entry_price: 1.07398,
        is_closed_position: true,
        miner_hotkey: "5FqSBwa7KXvv8piHdMyVbcXQwNWvT9WjHZGHAQwtoGVQD3vo",
        net_leverage: 0.0,
        open_ms: 1719986612955,
        orders: [
          {
            leverage: -1.0,
            order_type: "SHORT",
            order_uuid: "887740a6-bfc7-4c63-9a48-06186ec3b6d7",
            price: 1.07398,
            price_sources: [],
            processed_ms: 1719986612933,
          },
          {
            leverage: 0.01,
            order_type: "FLAT",
            order_uuid: "4256ca5c-1edb-4cd9-a995-be808dbbd505",
            price: 1.0793499999999998,
            price_sources: [
              {
                close: 1.0793499999999998,
                high: 1.0793499999999998,
                lag_ms: 399,
                low: 1.0793499999999998,
                open: 1.0793499999999998,
                source: "Polygon_ws",
                start_ms: 1720078561999,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 1.0794,
                websocket: true,
              },
              {
                close: 1.0795,
                high: 1.0795,
                lag_ms: 400,
                low: 1.0795,
                open: 1.0795,
                source: "TwelveData_ws",
                start_ms: 1720078562000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1720078561600,
          },
        ],
        position_type: "FLAT",
        position_uuid: "c716ac40-bb03-4b38-829e-34d4c52edee5",
        return_at_close: 0.9949302568949143,
        trade_pair: ["EURUSD", "EUR/USD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 159.74616666666668,
        close_ms: 1720078593123,
        current_return: 0.9844766484462562,
        initial_entry_price: 159.711,
        is_closed_position: true,
        miner_hotkey: "5FqSBwa7KXvv8piHdMyVbcXQwNWvT9WjHZGHAQwtoGVQD3vo",
        net_leverage: 0.0,
        open_ms: 1719338919671,
        orders: [
          {
            leverage: -1.0,
            order_type: "SHORT",
            order_uuid: "75d1dede-5547-45e0-8301-856f8e343170",
            price: 159.711,
            price_sources: [],
            processed_ms: 1719338917535,
          },
          {
            leverage: -0.5,
            order_type: "SHORT",
            order_uuid: "cbb160d5-ce5a-45d1-a4a1-658c2f52c709",
            price: 159.81650000000002,
            price_sources: [],
            processed_ms: 1719381592223,
          },
          {
            leverage: 0.01,
            order_type: "FLAT",
            order_uuid: "47bcdf1e-23e6-4d1c-9522-f73a88eef60f",
            price: 161.399,
            price_sources: [
              {
                close: 161.399,
                high: 161.399,
                lag_ms: 123,
                low: 161.399,
                open: 161.399,
                source: "Polygon_ws",
                start_ms: 1720078593000,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 161.399,
                websocket: true,
              },
              {
                close: 161.404,
                high: 161.404,
                lag_ms: 123,
                low: 161.404,
                open: 161.404,
                source: "TwelveData_ws",
                start_ms: 1720078593000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1720078593123,
          },
        ],
        position_type: "FLAT",
        position_uuid: "684ecf76-c820-48bb-b918-2e1a8aef6919",
        return_at_close: 0.9843732783981692,
        trade_pair: ["USDJPY", "USD/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 58506.82,
        close_ms: 1720110766012,
        current_return: 1.012631177014919,
        initial_entry_price: 58506.82,
        is_closed_position: true,
        miner_hotkey: "5FqSBwa7KXvv8piHdMyVbcXQwNWvT9WjHZGHAQwtoGVQD3vo",
        net_leverage: 0.0,
        open_ms: 1720078625944,
        orders: [
          {
            leverage: -1.0,
            order_type: "SHORT",
            order_uuid: "41d9f30d-1dbd-405a-a0b2-8c4b6cc11dc8",
            price: 58506.82,
            price_sources: [
              {
                close: 58506.82,
                high: 58509.74,
                lag_ms: 402,
                low: 58479.9,
                open: 58506.82,
                source: "Polygon_ws",
                start_ms: 1720078624000,
                timespan_ms: 0,
                volume: 0.00087718,
                vwap: 58486.6467,
                websocket: true,
              },
              {
                close: 58491.1,
                high: 58491.1,
                lag_ms: 598,
                low: 58491.1,
                open: 58491.1,
                source: "TwelveData_ws",
                start_ms: 1720078625000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
              {
                close: 58517.07,
                high: 58530.0,
                lag_ms: 2403,
                low: 58480.0,
                open: 58530.0,
                source: "Polygon_rest",
                start_ms: 1720078621000,
                timespan_ms: 1000,
                volume: 0.00507931,
                vwap: 58492.189,
                websocket: false,
              },
              {
                close: 58438.13,
                high: 58467.89,
                lag_ms: 64403,
                low: 58383.61,
                open: 58452.02,
                source: "TwelveData_rest",
                start_ms: 1720078500000,
                timespan_ms: 60000,
                volume: null,
                vwap: null,
                websocket: false,
              },
            ],
            processed_ms: 1720078624402,
          },
          {
            leverage: 0.01,
            order_type: "FLAT",
            order_uuid: "1bfefd05-5220-488f-8a86-019a9ba86700",
            price: 57767.81,
            price_sources: [
              {
                close: 57767.81,
                high: 57769.58,
                lag_ms: 12,
                low: 57767.81,
                open: 57767.81,
                source: "Polygon_ws",
                start_ms: 1720110766000,
                timespan_ms: 0,
                volume: 0.0085022,
                vwap: 57768.4873,
                websocket: true,
              },
              {
                close: 57769.8,
                high: 57855.0,
                lag_ms: 2013,
                low: 57769.8,
                open: 57772.32,
                source: "Polygon_rest",
                start_ms: 1720110763000,
                timespan_ms: 1000,
                volume: 0.04666107,
                vwap: 57773.5339,
                websocket: false,
              },
              {
                close: 57809.4,
                high: 57809.4,
                lag_ms: 5012,
                low: 57809.4,
                open: 57809.4,
                source: "TwelveData_ws",
                start_ms: 1720110761000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
              {
                close: 57826.65,
                high: 57896.43,
                lag_ms: 106013,
                low: 57826.64,
                open: 57896.43,
                source: "TwelveData_rest",
                start_ms: 1720110600000,
                timespan_ms: 60000,
                volume: null,
                vwap: null,
                websocket: false,
              },
            ],
            processed_ms: 1720110766012,
          },
        ],
        position_type: "FLAT",
        position_uuid: "db32deb6-ed46-4825-b802-23134b7c93df",
        return_at_close: 1.0116185458379041,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 57610.53333333333,
        close_ms: 1720160100870,
        current_return: 0.9595056958827068,
        initial_entry_price: 57745.22,
        is_closed_position: true,
        miner_hotkey: "5FqSBwa7KXvv8piHdMyVbcXQwNWvT9WjHZGHAQwtoGVQD3vo",
        net_leverage: 0.0,
        open_ms: 1720110796826,
        orders: [
          {
            leverage: 1.0,
            order_type: "LONG",
            order_uuid: "4788cde5-2f71-4887-8a49-ce774de74159",
            price: 57745.22,
            price_sources: [
              {
                close: 57745.22,
                high: 57745.7,
                lag_ms: 469,
                low: 57745.22,
                open: 57745.22,
                source: "Polygon_ws",
                start_ms: 1720110796000,
                timespan_ms: 0,
                volume: 0.00175959,
                vwap: 57745.3455,
                websocket: true,
              },
              {
                close: 57745.0,
                high: 57745.0,
                lag_ms: 2470,
                low: 57745.0,
                open: 57745.0,
                source: "Polygon_rest",
                start_ms: 1720110793000,
                timespan_ms: 1000,
                volume: 0.00075,
                vwap: 57745.0,
                websocket: false,
              },
              {
                close: 57773.4,
                high: 57773.4,
                lag_ms: 3531,
                low: 57773.4,
                open: 57773.4,
                source: "TwelveData_ws",
                start_ms: 1720110800000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
              {
                close: 57781.81,
                high: 57867.47,
                lag_ms: 76470,
                low: 57781.08,
                open: 57826.64,
                source: "TwelveData_rest",
                start_ms: 1720110660000,
                timespan_ms: 60000,
                volume: null,
                vwap: null,
                websocket: false,
              },
            ],
            processed_ms: 1720110796469,
          },
          {
            leverage: -0.25,
            order_type: "SHORT",
            order_uuid: "b874d513-2a71-46f3-9ae4-706f70a5cb51",
            price: 58149.28,
            price_sources: [
              {
                close: 58149.28,
                high: 58149.28,
                lag_ms: 395,
                low: 58149.28,
                open: 58149.28,
                source: "Polygon_ws",
                start_ms: 1720126005999,
                timespan_ms: 0,
                volume: 0.00521168,
                vwap: 58149.28,
                websocket: true,
              },
              {
                close: 58142.2,
                high: 58148.0,
                lag_ms: 2605,
                low: 58139.22,
                open: 58139.22,
                source: "Polygon_rest",
                start_ms: 1720126002000,
                timespan_ms: 1000,
                volume: 0.03840149,
                vwap: 58145.3177,
                websocket: false,
              },
              {
                close: 58155.2,
                high: 58155.2,
                lag_ms: 9604,
                low: 58155.2,
                open: 58155.2,
                source: "TwelveData_ws",
                start_ms: 1720125996000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
              {
                close: 58166.22,
                high: 58191.12,
                lag_ms: 45605,
                low: 58145.92,
                open: 58181.5,
                source: "TwelveData_rest",
                start_ms: 1720125900000,
                timespan_ms: 60000,
                volume: null,
                vwap: null,
                websocket: false,
              },
            ],
            processed_ms: 1720126005604,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "9b00ef30-85bc-4283-88b0-68938a1cb4aa",
            price: 54492.73,
            price_sources: [
              {
                close: 54492.73,
                high: 54492.73,
                lag_ms: 129,
                low: 54492.73,
                open: 54492.73,
                source: "Polygon_ws",
                start_ms: 1720160100999,
                timespan_ms: 0,
                volume: 6.06e-6,
                vwap: 54492.73,
                websocket: true,
              },
              {
                close: 54492.73,
                high: 54492.73,
                lag_ms: 2871,
                low: 54492.73,
                open: 54492.73,
                source: "Polygon_rest",
                start_ms: 1720160097000,
                timespan_ms: 1000,
                volume: 0.00177591,
                vwap: 54492.73,
                websocket: false,
              },
              {
                close: 54495.5,
                high: 54495.5,
                lag_ms: 3130,
                low: 54495.5,
                open: 54495.5,
                source: "TwelveData_ws",
                start_ms: 1720160104000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
              {
                close: 54490.49,
                high: 54528.94,
                lag_ms: 60871,
                low: 54478.6,
                open: 54528.94,
                source: "TwelveData_rest",
                start_ms: 1720159980000,
                timespan_ms: 60000,
                volume: null,
                vwap: null,
                websocket: false,
              },
            ],
            processed_ms: 1720160100870,
          },
        ],
        position_type: "FLAT",
        position_uuid: "b7e40bd9-5782-45fb-8195-681dca009604",
        return_at_close: 0.9585461901868242,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 174.061,
        close_ms: 1720160139159,
        current_return: 1.0013098856148133,
        initial_entry_price: 174.061,
        is_closed_position: true,
        miner_hotkey: "5FqSBwa7KXvv8piHdMyVbcXQwNWvT9WjHZGHAQwtoGVQD3vo",
        net_leverage: 0.0,
        open_ms: 1720080344252,
        orders: [
          {
            leverage: -1.0,
            order_type: "SHORT",
            order_uuid: "4fa0b673-a55b-4863-874e-d52604ebd12c",
            price: 174.061,
            price_sources: [
              {
                close: 174.061,
                high: 174.061,
                lag_ms: 229,
                low: 174.061,
                open: 174.061,
                source: "Polygon_ws",
                start_ms: 1720080344000,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 174.059,
                websocket: true,
              },
              {
                close: 174.062,
                high: 174.062,
                lag_ms: 229,
                low: 174.062,
                open: 174.062,
                source: "TwelveData_ws",
                start_ms: 1720080344000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1720080344229,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "59bb537e-d5e5-4a2c-830e-b8d5d582a455",
            price: 173.833,
            price_sources: [
              {
                close: 173.833,
                high: 173.833,
                lag_ms: 159,
                low: 173.833,
                open: 173.833,
                source: "Polygon_ws",
                start_ms: 1720160139000,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 173.81,
                websocket: true,
              },
              {
                close: 173.833,
                high: 173.833,
                lag_ms: 159,
                low: 173.833,
                open: 173.833,
                source: "TwelveData_ws",
                start_ms: 1720160139000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1720160139159,
          },
        ],
        position_type: "FLAT",
        position_uuid: "9b644ac5-f005-466e-a568-c8978480b2bf",
        return_at_close: 1.0012397939228201,
        trade_pair: ["EURJPY", "EUR/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 57497.559798994975,
        close_ms: 1720619335977,
        current_return: 0.913351370215266,
        initial_entry_price: 54444.0,
        is_closed_position: true,
        miner_hotkey: "5FqSBwa7KXvv8piHdMyVbcXQwNWvT9WjHZGHAQwtoGVQD3vo",
        net_leverage: 0.0,
        open_ms: 1720160169404,
        orders: [
          {
            leverage: -3.0,
            order_type: "SHORT",
            order_uuid: "813ab240-f911-4013-9d33-4fcdaff21182",
            price: 54444.0,
            price_sources: [
              {
                close: 54444.0,
                high: 54454.65,
                lag_ms: 393,
                low: 54444.0,
                open: 54444.0,
                source: "Polygon_ws",
                start_ms: 1720160169000,
                timespan_ms: 0,
                volume: 0.05112239,
                vwap: 54449.9966,
                websocket: true,
              },
              {
                close: 54477.0,
                high: 54477.0,
                lag_ms: 393,
                low: 54477.0,
                open: 54477.0,
                source: "TwelveData_ws",
                start_ms: 1720160169000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1720160169393,
          },
          {
            leverage: -5.0,
            order_type: "SHORT",
            order_uuid: "950e6e2f-b438-482d-805a-301f6b9c1eed",
            price: 56638.02,
            price_sources: [
              {
                close: 56638.02,
                high: 56648.2,
                lag_ms: 28,
                low: 56634.95,
                open: 56638.02,
                source: "Polygon_ws",
                start_ms: 1720208678000,
                timespan_ms: 0,
                volume: 0.46348917,
                vwap: 56637.9301,
                websocket: true,
              },
              {
                close: 56636.64,
                high: 56636.64,
                lag_ms: 2029,
                low: 56636.64,
                open: 56636.64,
                source: "Polygon_rest",
                start_ms: 1720208675000,
                timespan_ms: 1000,
                volume: 0.00017832,
                vwap: 56636.64,
                websocket: false,
              },
              {
                close: 56641.4,
                high: 56641.4,
                lag_ms: 10972,
                low: 56641.4,
                open: 56641.4,
                source: "TwelveData_ws",
                start_ms: 1720208689000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
              {
                close: 56627.78,
                high: 56641.89,
                lag_ms: 38029,
                low: 56600.76,
                open: 56605.14,
                source: "TwelveData_rest",
                start_ms: 1720208580000,
                timespan_ms: 60000,
                volume: null,
                vwap: null,
                websocket: false,
              },
            ],
            processed_ms: 1720208678028,
          },
          {
            leverage: -2.0,
            order_type: "SHORT",
            order_uuid: "e7d14cbb-c312-4367-8db1-aa25b23d79bf",
            price: 55477.92,
            price_sources: [
              {
                close: 55477.92,
                high: 55477.92,
                lag_ms: 118,
                low: 55477.92,
                open: 55477.92,
                source: "Polygon_ws",
                start_ms: 1720418273000,
                timespan_ms: 0,
                volume: 1.798e-5,
                vwap: 55477.92,
                websocket: true,
              },
              {
                close: 55480.2,
                high: 55480.2,
                lag_ms: 4118,
                low: 55480.2,
                open: 55480.2,
                source: "TwelveData_ws",
                start_ms: 1720418269000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
              {
                close: 55488.95,
                high: 55493.52,
                lag_ms: 5119,
                low: 55488.95,
                open: 55493.52,
                source: "Polygon_rest",
                start_ms: 1720418267000,
                timespan_ms: 1000,
                volume: 0.61378745,
                vwap: 55493.3224,
                websocket: false,
              },
              {
                close: 55517.29,
                high: 55562.23,
                lag_ms: 53119,
                low: 55510.76,
                open: 55525.2,
                source: "TwelveData_rest",
                start_ms: 1720418160000,
                timespan_ms: 60000,
                volume: null,
                vwap: null,
                websocket: false,
              },
            ],
            processed_ms: 1720418273118,
          },
          {
            leverage: -9.9,
            order_type: "SHORT",
            order_uuid: "151c1caa-20ac-4230-8f00-f9ec6bd4e8f0",
            price: 59265.0,
            price_sources: [
              {
                close: 59265.0,
                high: 59265.0,
                lag_ms: 191,
                low: 59265.0,
                open: 59265.0,
                source: "Polygon_ws",
                start_ms: 1720593112999,
                timespan_ms: 0,
                volume: 0.00162842,
                vwap: 59265.0,
                websocket: true,
              },
              {
                close: 59127.2,
                high: 59127.2,
                lag_ms: 1808,
                low: 59127.2,
                open: 59127.2,
                source: "TwelveData_ws",
                start_ms: 1720593111000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1720593112808,
          },
          {
            leverage: 0.01,
            order_type: "FLAT",
            order_uuid: "7ecd6107-2785-4624-8519-c4f98b745d33",
            price: 57734.62,
            price_sources: [
              {
                close: 57734.62,
                high: 57746.72,
                lag_ms: 22,
                low: 57706.6,
                open: 57734.62,
                source: "Polygon_ws",
                start_ms: 1720619335999,
                timespan_ms: 0,
                volume: 0.14815177,
                vwap: 57742.9624,
                websocket: true,
              },
              {
                close: 57720.0,
                high: 57720.0,
                lag_ms: 2978,
                low: 57706.6,
                open: 57711.79,
                source: "Polygon_rest",
                start_ms: 1720619332000,
                timespan_ms: 1000,
                volume: 1.80608124,
                vwap: 57715.3734,
                websocket: false,
              },
              {
                close: 57690.1,
                high: 57690.1,
                lag_ms: 5977,
                low: 57690.1,
                open: 57690.1,
                source: "TwelveData_ws",
                start_ms: 1720619330000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
              {
                close: 57836.41,
                high: 57843.58,
                lag_ms: 115978,
                low: 57699.93,
                open: 57793.75,
                source: "TwelveData_rest",
                start_ms: 1720619160000,
                timespan_ms: 60000,
                volume: null,
                vwap: null,
                websocket: false,
              },
            ],
            processed_ms: 1720619335977,
          },
        ],
        position_type: "FLAT",
        position_uuid: "5e5bc882-ff18-4fa9-8d05-1047db618534",
        return_at_close: 0.8951756779479823,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 3107.3445226130657,
        close_ms: 0,
        current_return: 0.8104008573249181,
        initial_entry_price: 3107.34,
        is_closed_position: false,
        miner_hotkey: "5FqSBwa7KXvv8piHdMyVbcXQwNWvT9WjHZGHAQwtoGVQD3vo",
        net_leverage: -19.9,
        open_ms: 1720592791769,
        orders: [
          {
            leverage: -20.0,
            order_type: "SHORT",
            order_uuid: "387ce888-aa1f-4f10-bb9b-29b5eb01c007",
            price: 3107.34,
            price_sources: [
              {
                close: 3107.34,
                high: 3107.34,
                lag_ms: 49,
                low: 3107.34,
                open: 3107.34,
                source: "Polygon_ws",
                start_ms: 1720592790000,
                timespan_ms: 0,
                volume: 0.00159311,
                vwap: 3107.34,
                websocket: true,
              },
              {
                close: 3107.14,
                high: 3107.14,
                lag_ms: 2050,
                low: 3107.14,
                open: 3107.14,
                source: "Polygon_rest",
                start_ms: 1720592787000,
                timespan_ms: 1000,
                volume: 0.03663138,
                vwap: 3107.14,
                websocket: false,
              },
              {
                close: 3105.59,
                high: 3105.59,
                lag_ms: 3951,
                low: 3105.59,
                open: 3105.59,
                source: "TwelveData_ws",
                start_ms: 1720592794000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
              {
                close: 3106.08008,
                high: 3107.97998,
                lag_ms: 150050,
                low: 3105.96997,
                open: 3107.97998,
                source: "TwelveData_rest",
                start_ms: 1720592580000,
                timespan_ms: 60000,
                volume: null,
                vwap: null,
                websocket: false,
              },
            ],
            processed_ms: 1720592790049,
          },
          {
            leverage: 0.1,
            order_type: "LONG",
            order_uuid: "70b11d42-4d9b-47df-a7d9-331066bc4ce5",
            price: 3106.44,
            price_sources: [
              {
                close: 3106.44,
                high: 3106.44,
                lag_ms: 102,
                low: 3106.2,
                open: 3106.44,
                source: "Polygon_ws",
                start_ms: 1720593136999,
                timespan_ms: 0,
                volume: 1.68417601,
                vwap: 3106.3459,
                websocket: true,
              },
              {
                close: 3106.48,
                high: 3106.48,
                lag_ms: 2898,
                low: 3106.48,
                open: 3106.48,
                source: "Polygon_rest",
                start_ms: 1720593133000,
                timespan_ms: 1000,
                volume: 0.01716386,
                vwap: 3106.48,
                websocket: false,
              },
              {
                close: 3105.63,
                high: 3105.63,
                lag_ms: 3897,
                low: 3105.63,
                open: 3105.63,
                source: "TwelveData_ws",
                start_ms: 1720593133000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
              {
                close: 3106.08008,
                high: 3107.97998,
                lag_ms: 496898,
                low: 3105.96997,
                open: 3107.97998,
                source: "TwelveData_rest",
                start_ms: 1720592580000,
                timespan_ms: 60000,
                volume: null,
                vwap: null,
                websocket: false,
              },
            ],
            processed_ms: 1720593136897,
          },
        ],
        position_type: "SHORT",
        position_uuid: "96c2c9ac-7b48-4c6e-a6ae-71c281185cf0",
        return_at_close: 0.8022563287088026,
        trade_pair: ["ETHUSD", "ETH/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 57601.99999999999,
        close_ms: 0,
        current_return: 0.8151713482170733,
        initial_entry_price: 57602.0,
        is_closed_position: false,
        miner_hotkey: "5FqSBwa7KXvv8piHdMyVbcXQwNWvT9WjHZGHAQwtoGVQD3vo",
        net_leverage: -19.9,
        open_ms: 1720619502846,
        orders: [
          {
            leverage: -19.9,
            order_type: "SHORT",
            order_uuid: "cd089406-f521-4ab1-b290-eb82bdfea0a4",
            price: 57602.0,
            price_sources: [
              {
                close: 57602.0,
                high: 57602.0,
                lag_ms: 414,
                low: 57601.0,
                open: 57602.0,
                source: "Polygon_ws",
                start_ms: 1720619502999,
                timespan_ms: 0,
                volume: 0.00685594,
                vwap: 57601.7254,
                websocket: true,
              },
              {
                close: 57731.0,
                high: 57731.0,
                lag_ms: 2586,
                low: 57591.66,
                open: 57591.66,
                source: "Polygon_rest",
                start_ms: 1720619499000,
                timespan_ms: 1000,
                volume: 0.00127138,
                vwap: 57616.6777,
                websocket: false,
              },
              {
                close: 57633.4,
                high: 57633.4,
                lag_ms: 4415,
                low: 57633.4,
                open: 57633.4,
                source: "TwelveData_ws",
                start_ms: 1720619507000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
              {
                close: 57651.98,
                high: 57766.17,
                lag_ms: 102586,
                low: 57645.53,
                open: 57713.84,
                source: "TwelveData_rest",
                start_ms: 1720619340000,
                timespan_ms: 60000,
                volume: null,
                vwap: null,
                websocket: false,
              },
            ],
            processed_ms: 1720619502585,
          },
        ],
        position_type: "SHORT",
        position_uuid: "a5ff5d62-d3a2-48b0-a43f-bae3f13fd980",
        return_at_close: 0.8070603933023134,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
    ],
    thirty_day_returns: 0.8465614227233025,
  },
  "5FvmeMUskojSA2sVnAdEGxMajFn58f5A9qZ1B9ax6FTbpjXJ": {
    all_time_returns: 1.0,
    n_positions: 0,
    percentage_profitable: 0.0,
    positions: [
      {
        average_entry_price: 65128.071578947376,
        close_ms: 1711207077241,
        current_return: 1.055472467270353,
        initial_entry_price: 65084.72,
        is_closed_position: true,
        miner_hotkey: "5FvmeMUskojSA2sVnAdEGxMajFn58f5A9qZ1B9ax6FTbpjXJ",
        net_leverage: 0.0,
        open_ms: 1711205146423,
        orders: [
          {
            leverage: -11.0,
            order_type: "SHORT",
            order_uuid: "60a45f4f-de20-4bb9-a8e9-f2c0c05528f0",
            price: 65084.72,
            price_sources: [],
            processed_ms: 1711205146423,
          },
          {
            leverage: -8.0,
            order_type: "SHORT",
            order_uuid: "fb6b0229-091a-4947-8fd2-b7dd576296cb",
            price: 65187.68,
            price_sources: [],
            processed_ms: 1711205392991,
          },
          {
            leverage: 5.0,
            order_type: "FLAT",
            order_uuid: "f41669b5-8090-43b1-bb86-f61359618b13",
            price: 64938.05,
            price_sources: [],
            processed_ms: 1711207077241,
          },
        ],
        position_type: "FLAT",
        position_uuid: "c860bd02-fdad-4227-bb92-32edb18a1e6c",
        return_at_close: 1.0153645135140796,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 64876.646,
        close_ms: 1711209654344,
        current_return: 1.0065504695011376,
        initial_entry_price: 64799.63,
        is_closed_position: true,
        miner_hotkey: "5FvmeMUskojSA2sVnAdEGxMajFn58f5A9qZ1B9ax6FTbpjXJ",
        net_leverage: 0.0,
        open_ms: 1711207403266,
        orders: [
          {
            leverage: 0.4,
            order_type: "LONG",
            order_uuid: "35e0ab99-602b-4c7d-acd9-31da86b4cb1c",
            price: 64799.63,
            price_sources: [],
            processed_ms: 1711207403265,
          },
          {
            leverage: 0.4,
            order_type: "LONG",
            order_uuid: "63abb6f9-1bff-4ba0-ac76-feee0cba9b17",
            price: 64823.21,
            price_sources: [],
            processed_ms: 1711207854181,
          },
          {
            leverage: 0.4,
            order_type: "LONG",
            order_uuid: "54180133-be34-4cf7-a0f8-766b4d57baf4",
            price: 64899.45,
            price_sources: [],
            processed_ms: 1711208325875,
          },
          {
            leverage: 0.4,
            order_type: "LONG",
            order_uuid: "ecafcaa2-d7e9-46ee-b22d-9877d50a31ec",
            price: 64865.18,
            price_sources: [],
            processed_ms: 1711208759068,
          },
          {
            leverage: 0.4,
            order_type: "LONG",
            order_uuid: "33eb03d3-6605-4106-828d-3b3514a2f4b9",
            price: 64995.76,
            price_sources: [],
            processed_ms: 1711209210247,
          },
          {
            leverage: 0.4,
            order_type: "FLAT",
            order_uuid: "daccf509-869f-4bc9-ae2d-a5d87f72a388",
            price: 65088.88,
            price_sources: [],
            processed_ms: 1711209654344,
          },
        ],
        position_type: "FLAT",
        position_uuid: "656097dc-543d-4990-babd-e8eca952478f",
        return_at_close: 1.002524267623133,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 65210.548,
        close_ms: 1711212359542,
        current_return: 1.0154533671894155,
        initial_entry_price: 64933.68,
        is_closed_position: true,
        miner_hotkey: "5FvmeMUskojSA2sVnAdEGxMajFn58f5A9qZ1B9ax6FTbpjXJ",
        net_leverage: 0.0,
        open_ms: 1711210125100,
        orders: [
          {
            leverage: 0.4,
            order_type: "LONG",
            order_uuid: "f5f322e8-c029-446f-bed3-ae9a45130994",
            price: 64933.68,
            price_sources: [],
            processed_ms: 1711210125099,
          },
          {
            leverage: 0.4,
            order_type: "LONG",
            order_uuid: "9c6c515b-d2d8-4dd4-89cd-1c525e169569",
            price: 65220.79,
            price_sources: [],
            processed_ms: 1711210560480,
          },
          {
            leverage: 0.4,
            order_type: "LONG",
            order_uuid: "918cfd2a-5d3e-4f95-abf2-c1ace2a635ea",
            price: 65137.81,
            price_sources: [],
            processed_ms: 1711211006737,
          },
          {
            leverage: 0.4,
            order_type: "LONG",
            order_uuid: "9d816ffc-44ce-4a80-80b1-503825fa1d4e",
            price: 65273.96,
            price_sources: [],
            processed_ms: 1711211457951,
          },
          {
            leverage: 0.4,
            order_type: "LONG",
            order_uuid: "8efe76b8-287d-437e-8fc7-2c97d1973fbb",
            price: 65486.5,
            price_sources: [],
            processed_ms: 1711211905051,
          },
          {
            leverage: 0.4,
            order_type: "FLAT",
            order_uuid: "9db13384-5b02-4ac8-b677-a64574c00b2f",
            price: 65712.27,
            price_sources: [],
            processed_ms: 1711212359542,
          },
        ],
        position_type: "FLAT",
        position_uuid: "0e72e2e5-82a2-4400-b3ca-b2d2b21051af",
        return_at_close: 1.011391553720658,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 3422.49,
        close_ms: 1711213546747,
        current_return: 0.9986553649535864,
        initial_entry_price: 3422.49,
        is_closed_position: true,
        miner_hotkey: "5FvmeMUskojSA2sVnAdEGxMajFn58f5A9qZ1B9ax6FTbpjXJ",
        net_leverage: 0.0,
        open_ms: 1711213423190,
        orders: [
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "e2b03b6e-33dc-4906-bef1-5a6dc727683f",
            price: 3422.49,
            price_sources: [],
            processed_ms: 1711213423189,
          },
          {
            leverage: 8.0,
            order_type: "FLAT",
            order_uuid: "47f6c962-7fcd-46b4-aa43-4308857f1463",
            price: 3407.15,
            price_sources: [],
            processed_ms: 1711213546747,
          },
        ],
        position_type: "FLAT",
        position_uuid: "c18d3d7f-15c3-4edd-927c-65b5c94ade0f",
        return_at_close: 0.9980561717346143,
        trade_pair: ["ETHUSD", "ETH/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 65598.03800000002,
        close_ms: 1711215066283,
        current_return: 1.0023150786290642,
        initial_entry_price: 65615.05,
        is_closed_position: true,
        miner_hotkey: "5FvmeMUskojSA2sVnAdEGxMajFn58f5A9qZ1B9ax6FTbpjXJ",
        net_leverage: 0.0,
        open_ms: 1711212810059,
        orders: [
          {
            leverage: 0.4,
            order_type: "LONG",
            order_uuid: "9ac5f5eb-151b-427a-bfd3-edec89d815db",
            price: 65615.05,
            price_sources: [],
            processed_ms: 1711212810058,
          },
          {
            leverage: 0.4,
            order_type: "LONG",
            order_uuid: "2e21943d-947c-4f76-a026-577aa32e8e4a",
            price: 65665.01,
            price_sources: [],
            processed_ms: 1711213262821,
          },
          {
            leverage: 0.4,
            order_type: "LONG",
            order_uuid: "b75f225b-55fc-49a8-8ddc-8fa862f6c38f",
            price: 65607.56,
            price_sources: [],
            processed_ms: 1711213709000,
          },
          {
            leverage: 0.4,
            order_type: "LONG",
            order_uuid: "853e2396-e739-4133-a126-732a49d6ca80",
            price: 65528.49,
            price_sources: [],
            processed_ms: 1711214158768,
          },
          {
            leverage: 0.4,
            order_type: "LONG",
            order_uuid: "46db25ef-efc0-40bf-a4b2-dd288fde0f46",
            price: 65574.08,
            price_sources: [],
            processed_ms: 1711214617152,
          },
          {
            leverage: 0.4,
            order_type: "FLAT",
            order_uuid: "43297181-3e7f-4a13-9fc4-23755be5c121",
            price: 65673.99,
            price_sources: [],
            processed_ms: 1711215066283,
          },
        ],
        position_type: "FLAT",
        position_uuid: "ec305887-10db-4202-9c42-5121b846bfa5",
        return_at_close: 0.998305818314548,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 65694.92199999999,
        close_ms: 1711217764884,
        current_return: 0.9963959695073485,
        initial_entry_price: 65844.06,
        is_closed_position: true,
        miner_hotkey: "5FvmeMUskojSA2sVnAdEGxMajFn58f5A9qZ1B9ax6FTbpjXJ",
        net_leverage: 0.0,
        open_ms: 1711215515556,
        orders: [
          {
            leverage: 0.4,
            order_type: "LONG",
            order_uuid: "08d324e0-13c6-4fe2-9149-f80a7946aed6",
            price: 65844.06,
            price_sources: [],
            processed_ms: 1711215515555,
          },
          {
            leverage: 0.4,
            order_type: "LONG",
            order_uuid: "5a445a8a-944a-45f1-8231-823a218d9262",
            price: 65647.49,
            price_sources: [],
            processed_ms: 1711215964254,
          },
          {
            leverage: 0.4,
            order_type: "LONG",
            order_uuid: "e0944e56-ebe9-4d91-a6a5-dc0e3ee086ea",
            price: 65703.06,
            price_sources: [],
            processed_ms: 1711216417821,
          },
          {
            leverage: 0.4,
            order_type: "LONG",
            order_uuid: "addb607d-4e6b-47e0-94f7-175bff7f98e5",
            price: 65663.21,
            price_sources: [],
            processed_ms: 1711216868128,
          },
          {
            leverage: 0.4,
            order_type: "LONG",
            order_uuid: "7d719e26-6478-482e-a2f1-2ceabc1c6588",
            price: 65616.79,
            price_sources: [],
            processed_ms: 1711217310102,
          },
          {
            leverage: 0.4,
            order_type: "FLAT",
            order_uuid: "362bf6bf-7818-48eb-9967-2dfa3cdde731",
            price: 65576.27,
            price_sources: [],
            processed_ms: 1711217764884,
          },
        ],
        position_type: "FLAT",
        position_uuid: "be2c6410-c6f6-4fc6-b3be-1ea3adcc4f9d",
        return_at_close: 0.9924103856293192,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 65454.884000000005,
        close_ms: 1711220463657,
        current_return: 0.9978769876738746,
        initial_entry_price: 65561.56,
        is_closed_position: true,
        miner_hotkey: "5FvmeMUskojSA2sVnAdEGxMajFn58f5A9qZ1B9ax6FTbpjXJ",
        net_leverage: 0.0,
        open_ms: 1711218218185,
        orders: [
          {
            leverage: 0.4,
            order_type: "LONG",
            order_uuid: "df5a7351-6f66-42e8-afa9-211b5c0c6551",
            price: 65561.56,
            price_sources: [],
            processed_ms: 1711218218183,
          },
          {
            leverage: 0.4,
            order_type: "LONG",
            order_uuid: "50655ca7-5e02-41c1-8423-80d461c0feb7",
            price: 65513.73,
            price_sources: [],
            processed_ms: 1711218659749,
          },
          {
            leverage: 0.4,
            order_type: "LONG",
            order_uuid: "c15975d6-aeca-4c48-8242-147a43242222",
            price: 65415.8,
            price_sources: [],
            processed_ms: 1711219114770,
          },
          {
            leverage: 0.4,
            order_type: "LONG",
            order_uuid: "faca8e11-773e-4ded-b236-d40f12c0c225",
            price: 65407.15,
            price_sources: [],
            processed_ms: 1711219566093,
          },
          {
            leverage: 0.4,
            order_type: "LONG",
            order_uuid: "3b056a6f-85f6-4b72-8227-80348576bfbb",
            price: 65376.18,
            price_sources: [],
            processed_ms: 1711220015256,
          },
          {
            leverage: 0.4,
            order_type: "FLAT",
            order_uuid: "1fb1503e-f64c-4c2d-a507-c77c0697c19d",
            price: 65385.29,
            price_sources: [],
            processed_ms: 1711220463657,
          },
        ],
        position_type: "FLAT",
        position_uuid: "ea64db8e-9b08-415b-a982-45aff3771f72",
        return_at_close: 0.9938854797231791,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 65251.22200000001,
        close_ms: 1711223169688,
        current_return: 0.9890995979357384,
        initial_entry_price: 65350.25,
        is_closed_position: true,
        miner_hotkey: "5FvmeMUskojSA2sVnAdEGxMajFn58f5A9qZ1B9ax6FTbpjXJ",
        net_leverage: 0.0,
        open_ms: 1711220916004,
        orders: [
          {
            leverage: 0.4,
            order_type: "LONG",
            order_uuid: "e6a804bf-d211-4b99-a921-46ea3acc7f49",
            price: 65350.25,
            price_sources: [],
            processed_ms: 1711220916003,
          },
          {
            leverage: 0.4,
            order_type: "LONG",
            order_uuid: "a4d3ee54-286c-4047-b397-9bbc3a8bd11b",
            price: 65376.6,
            price_sources: [],
            processed_ms: 1711221366208,
          },
          {
            leverage: 0.4,
            order_type: "LONG",
            order_uuid: "f27d8f2b-d1db-4e24-b36a-ce373682d73f",
            price: 65342.01,
            price_sources: [],
            processed_ms: 1711221819025,
          },
          {
            leverage: 0.4,
            order_type: "LONG",
            order_uuid: "3b38bfbc-ed08-4907-bbf7-5bc116edf8fe",
            price: 65136.07,
            price_sources: [],
            processed_ms: 1711222268056,
          },
          {
            leverage: 0.4,
            order_type: "LONG",
            order_uuid: "572981fe-4aa3-4d3b-807b-afc0c0c299f4",
            price: 65051.18,
            price_sources: [],
            processed_ms: 1711222720504,
          },
          {
            leverage: 0.4,
            order_type: "FLAT",
            order_uuid: "afba0425-34bb-4913-ac4c-6df00b2c35dc",
            price: 64895.05,
            price_sources: [],
            processed_ms: 1711223169688,
          },
        ],
        position_type: "FLAT",
        position_uuid: "01671993-269d-41c2-9f0f-5b5ee2d53ee6",
        return_at_close: 0.9851431995439954,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 64913.514,
        close_ms: 1711225873990,
        current_return: 1.0057405122533971,
        initial_entry_price: 64944.03,
        is_closed_position: true,
        miner_hotkey: "5FvmeMUskojSA2sVnAdEGxMajFn58f5A9qZ1B9ax6FTbpjXJ",
        net_leverage: 0.0,
        open_ms: 1711223614243,
        orders: [
          {
            leverage: 0.4,
            order_type: "LONG",
            order_uuid: "7779c682-2026-43bb-bb4c-e3ce296c5880",
            price: 64944.03,
            price_sources: [],
            processed_ms: 1711223614241,
          },
          {
            leverage: 0.4,
            order_type: "LONG",
            order_uuid: "c9b6715a-30cd-447f-bbe3-78c0815ec9fe",
            price: 64875.2,
            price_sources: [],
            processed_ms: 1711224067512,
          },
          {
            leverage: 0.4,
            order_type: "LONG",
            order_uuid: "24156ef4-0222-41cb-82db-e8db6366acff",
            price: 64836.78,
            price_sources: [],
            processed_ms: 1711224519515,
          },
          {
            leverage: 0.4,
            order_type: "LONG",
            order_uuid: "ca2e3bbb-bd25-4613-88cd-c27c1fdcf148",
            price: 64946.23,
            price_sources: [],
            processed_ms: 1711224972416,
          },
          {
            leverage: 0.4,
            order_type: "LONG",
            order_uuid: "ae43327e-761b-4df3-a367-ae61b5558512",
            price: 64965.33,
            price_sources: [],
            processed_ms: 1711225414717,
          },
          {
            leverage: 0.4,
            order_type: "FLAT",
            order_uuid: "1d40bd00-1d88-4f59-bdd6-b28420c4472b",
            price: 65099.92,
            price_sources: [],
            processed_ms: 1711225873990,
          },
        ],
        position_type: "FLAT",
        position_uuid: "91aaf24b-e4c4-4ca0-bb5e-f9f0bf4d4e50",
        return_at_close: 1.0017175502043836,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 64980.488000000005,
        close_ms: 1711228565546,
        current_return: 0.9979796304226761,
        initial_entry_price: 64986.13,
        is_closed_position: true,
        miner_hotkey: "5FvmeMUskojSA2sVnAdEGxMajFn58f5A9qZ1B9ax6FTbpjXJ",
        net_leverage: 0.0,
        open_ms: 1711226316074,
        orders: [
          {
            leverage: 0.4,
            order_type: "LONG",
            order_uuid: "266c0178-1cc5-40aa-ad44-6a08626a7439",
            price: 64986.13,
            price_sources: [],
            processed_ms: 1711226316072,
          },
          {
            leverage: 0.4,
            order_type: "LONG",
            order_uuid: "96f70618-9aef-4cd2-90b9-9d8df7f77aa7",
            price: 65005.4,
            price_sources: [],
            processed_ms: 1711226770211,
          },
          {
            leverage: 0.4,
            order_type: "LONG",
            order_uuid: "5441d9a6-1ee5-400f-b76e-8c3ee72f9869",
            price: 64993.42,
            price_sources: [],
            processed_ms: 1711227215123,
          },
          {
            leverage: 0.4,
            order_type: "LONG",
            order_uuid: "44f5b40c-4c09-495c-af46-468e6b8d2d34",
            price: 65008.18,
            price_sources: [],
            processed_ms: 1711227668347,
          },
          {
            leverage: 0.4,
            order_type: "LONG",
            order_uuid: "8cdd8758-0c9f-45ea-8e7f-8cfe91ab570a",
            price: 64909.31,
            price_sources: [],
            processed_ms: 1711228124627,
          },
          {
            leverage: 0.4,
            order_type: "FLAT",
            order_uuid: "090f6c8a-5d54-40be-9280-5d56dc1449d3",
            price: 64914.84,
            price_sources: [],
            processed_ms: 1711228565546,
          },
        ],
        position_type: "FLAT",
        position_uuid: "e728b1b9-19d3-4efb-9218-a7b1c1b65250",
        return_at_close: 0.9939877119009854,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 64911.134,
        close_ms: 1711231267997,
        current_return: 1.0008702728860173,
        initial_entry_price: 64867.01,
        is_closed_position: true,
        miner_hotkey: "5FvmeMUskojSA2sVnAdEGxMajFn58f5A9qZ1B9ax6FTbpjXJ",
        net_leverage: 0.0,
        open_ms: 1711229019531,
        orders: [
          {
            leverage: 0.4,
            order_type: "LONG",
            order_uuid: "643961f7-455b-4281-a009-b7a6e67273ae",
            price: 64867.01,
            price_sources: [],
            processed_ms: 1711229019530,
          },
          {
            leverage: 0.4,
            order_type: "LONG",
            order_uuid: "18e355a6-0aa9-40db-a057-7d87e1dd98ca",
            price: 64818.21,
            price_sources: [],
            processed_ms: 1711229474781,
          },
          {
            leverage: 0.4,
            order_type: "LONG",
            order_uuid: "7244fa62-e429-4ee3-b22e-02faab6499bc",
            price: 64855.34,
            price_sources: [],
            processed_ms: 1711229924744,
          },
          {
            leverage: 0.4,
            order_type: "LONG",
            order_uuid: "c1e9ec10-de0e-426f-a348-093a03e0f03c",
            price: 65031.21,
            price_sources: [],
            processed_ms: 1711230370961,
          },
          {
            leverage: 0.4,
            order_type: "LONG",
            order_uuid: "744aee8c-e6d5-4762-a4b6-fc8a6b886738",
            price: 64983.9,
            price_sources: [],
            processed_ms: 1711230826454,
          },
          {
            leverage: 0.4,
            order_type: "FLAT",
            order_uuid: "ee800a2b-d4e0-4d6a-bd6a-ed23491793a1",
            price: 64939.36,
            price_sources: [],
            processed_ms: 1711231267997,
          },
        ],
        position_type: "FLAT",
        position_uuid: "e62a58dc-2afa-42b3-b1f1-3c8ba3f7141a",
        return_at_close: 0.9968667917944732,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 64946.654,
        close_ms: 1711233968169,
        current_return: 0.997871745937131,
        initial_entry_price: 64958.41,
        is_closed_position: true,
        miner_hotkey: "5FvmeMUskojSA2sVnAdEGxMajFn58f5A9qZ1B9ax6FTbpjXJ",
        net_leverage: 0.0,
        open_ms: 1711231720455,
        orders: [
          {
            leverage: 0.4,
            order_type: "LONG",
            order_uuid: "c6a91e1e-eb0f-44e0-a841-9a49ed6a0fc2",
            price: 64958.41,
            price_sources: [],
            processed_ms: 1711231720454,
          },
          {
            leverage: 0.4,
            order_type: "LONG",
            order_uuid: "6e2c146d-b2e7-4e93-b1db-8fc2271737ba",
            price: 64924.25,
            price_sources: [],
            processed_ms: 1711232173389,
          },
          {
            leverage: 0.4,
            order_type: "LONG",
            order_uuid: "9d8a93b9-cd35-422b-825e-21a133a19912",
            price: 64857.17,
            price_sources: [],
            processed_ms: 1711232620697,
          },
          {
            leverage: 0.4,
            order_type: "LONG",
            order_uuid: "dcdcd0bb-93c1-4013-ba3d-c6307d1c4389",
            price: 64973.89,
            price_sources: [],
            processed_ms: 1711233074871,
          },
          {
            leverage: 0.4,
            order_type: "LONG",
            order_uuid: "cf06c5a8-264f-4367-a58e-3cf0a63520a0",
            price: 65019.55,
            price_sources: [],
            processed_ms: 1711233525317,
          },
          {
            leverage: 0.4,
            order_type: "FLAT",
            order_uuid: "03fa5d24-5140-43dc-aff9-056372ed7748",
            price: 64877.53,
            price_sources: [],
            processed_ms: 1711233968169,
          },
        ],
        position_type: "FLAT",
        position_uuid: "ea659fb9-8a0b-4270-91dd-dc0dd90c3db8",
        return_at_close: 0.9938802589533825,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 64746.590000000004,
        close_ms: 1711236670560,
        current_return: 0.9895547552977684,
        initial_entry_price: 64873.54,
        is_closed_position: true,
        miner_hotkey: "5FvmeMUskojSA2sVnAdEGxMajFn58f5A9qZ1B9ax6FTbpjXJ",
        net_leverage: 0.0,
        open_ms: 1711234420156,
        orders: [
          {
            leverage: 0.4,
            order_type: "LONG",
            order_uuid: "cbfb2517-c56d-43cd-8519-cef1f766d612",
            price: 64873.54,
            price_sources: [],
            processed_ms: 1711234420154,
          },
          {
            leverage: 0.4,
            order_type: "LONG",
            order_uuid: "aea5e53e-ef4c-4aa1-967c-28f523ab2d9b",
            price: 64744.61,
            price_sources: [],
            processed_ms: 1711234874779,
          },
          {
            leverage: 0.4,
            order_type: "LONG",
            order_uuid: "e8038bda-1e9d-4931-98d6-0db94652ead4",
            price: 64777.61,
            price_sources: [],
            processed_ms: 1711235328830,
          },
          {
            leverage: 0.4,
            order_type: "LONG",
            order_uuid: "597b49b4-8ad6-49eb-ae52-f641657cd969",
            price: 64630.51,
            price_sources: [],
            processed_ms: 1711235773450,
          },
          {
            leverage: 0.4,
            order_type: "LONG",
            order_uuid: "b9472269-d906-405a-9fe0-61fec6b2190a",
            price: 64706.68,
            price_sources: [],
            processed_ms: 1711236227289,
          },
          {
            leverage: 0.4,
            order_type: "FLAT",
            order_uuid: "a18610b8-ea87-441b-bd92-67eb12791adf",
            price: 64407.78,
            price_sources: [],
            processed_ms: 1711236670560,
          },
        ],
        position_type: "FLAT",
        position_uuid: "708ef6cd-4c43-4c32-96ed-e1152e7b04de",
        return_at_close: 0.9855965362765773,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 64251.91,
        close_ms: 1711239371217,
        current_return: 0.9979420518426008,
        initial_entry_price: 64433.11,
        is_closed_position: true,
        miner_hotkey: "5FvmeMUskojSA2sVnAdEGxMajFn58f5A9qZ1B9ax6FTbpjXJ",
        net_leverage: 0.0,
        open_ms: 1711237125573,
        orders: [
          {
            leverage: 0.4,
            order_type: "LONG",
            order_uuid: "0ab4d535-5258-452e-9233-195d25a5e4b3",
            price: 64433.11,
            price_sources: [],
            processed_ms: 1711237125571,
          },
          {
            leverage: 0.4,
            order_type: "LONG",
            order_uuid: "b194ac19-f438-4609-8877-c056db599128",
            price: 64331.84,
            price_sources: [],
            processed_ms: 1711237579821,
          },
          {
            leverage: 0.4,
            order_type: "LONG",
            order_uuid: "c2013709-ac07-4f79-a5b6-3289c6b6d097",
            price: 64312.52,
            price_sources: [],
            processed_ms: 1711238020895,
          },
          {
            leverage: 0.4,
            order_type: "LONG",
            order_uuid: "555461a6-122a-4a37-ad33-734f1612611f",
            price: 63998.86,
            price_sources: [],
            processed_ms: 1711238477265,
          },
          {
            leverage: 0.4,
            order_type: "LONG",
            order_uuid: "f62a695d-e63b-4032-8af8-341b1e1a67db",
            price: 64183.22,
            price_sources: [],
            processed_ms: 1711238923407,
          },
          {
            leverage: 0.4,
            order_type: "FLAT",
            order_uuid: "ad78f47d-922b-421b-a18b-f42fb332cec4",
            price: 64185.61,
            price_sources: [],
            processed_ms: 1711239371217,
          },
        ],
        position_type: "FLAT",
        position_uuid: "934f2377-d08f-4ead-a8d6-508ac1694d9c",
        return_at_close: 0.9939502836352303,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 64462.088,
        close_ms: 1711242080807,
        current_return: 1.0023954458811533,
        initial_entry_price: 64365.47,
        is_closed_position: true,
        miner_hotkey: "5FvmeMUskojSA2sVnAdEGxMajFn58f5A9qZ1B9ax6FTbpjXJ",
        net_leverage: 0.0,
        open_ms: 1711239830529,
        orders: [
          {
            leverage: 0.4,
            order_type: "LONG",
            order_uuid: "cfaa28b5-604e-4b23-8fa5-e392f6644af2",
            price: 64365.47,
            price_sources: [],
            processed_ms: 1711239830527,
          },
          {
            leverage: 0.4,
            order_type: "LONG",
            order_uuid: "2c659cda-b9b0-4cbd-92f5-245bb06950b9",
            price: 64382.93,
            price_sources: [],
            processed_ms: 1711240278651,
          },
          {
            leverage: 0.4,
            order_type: "LONG",
            order_uuid: "b34036f9-cd7d-4c96-b8c5-90b802387359",
            price: 64456.23,
            price_sources: [],
            processed_ms: 1711240731113,
          },
          {
            leverage: 0.4,
            order_type: "LONG",
            order_uuid: "76c3eef2-4da1-4e0c-b541-762be12633d4",
            price: 64518.02,
            price_sources: [],
            processed_ms: 1711241175107,
          },
          {
            leverage: 0.4,
            order_type: "LONG",
            order_uuid: "aff82e06-e5a1-4295-91d1-cd0c4bd6984c",
            price: 64587.79,
            price_sources: [],
            processed_ms: 1711241623410,
          },
          {
            leverage: 0.4,
            order_type: "FLAT",
            order_uuid: "23e84e31-4ff0-4458-a58a-c0230ccbffd2",
            price: 64539.18,
            price_sources: [],
            processed_ms: 1711242080807,
          },
        ],
        position_type: "FLAT",
        position_uuid: "9289b161-0136-4bdd-9b3f-bf8aa216c311",
        return_at_close: 0.9983858640976286,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 64483.0,
        close_ms: 1711244776565,
        current_return: 1.0001895566685246,
        initial_entry_price: 64466.21,
        is_closed_position: true,
        miner_hotkey: "5FvmeMUskojSA2sVnAdEGxMajFn58f5A9qZ1B9ax6FTbpjXJ",
        net_leverage: 0.0,
        open_ms: 1711242528082,
        orders: [
          {
            leverage: 0.4,
            order_type: "LONG",
            order_uuid: "a7a56789-7fb6-44ef-b4a0-040f0d71a174",
            price: 64466.21,
            price_sources: [],
            processed_ms: 1711242528081,
          },
          {
            leverage: 0.4,
            order_type: "LONG",
            order_uuid: "4dc2c84e-cbea-4b6e-ac18-a90fd777114b",
            price: 64399.58,
            price_sources: [],
            processed_ms: 1711242982236,
          },
          {
            leverage: 0.4,
            order_type: "LONG",
            order_uuid: "75a81f1c-699e-491b-bdf6-aaa4b7e71c9e",
            price: 64447.18,
            price_sources: [],
            processed_ms: 1711243428545,
          },
          {
            leverage: 0.4,
            order_type: "LONG",
            order_uuid: "04909bbc-6f62-4d3b-9e64-b706957c1a95",
            price: 64615.84,
            price_sources: [],
            processed_ms: 1711243876265,
          },
          {
            leverage: 0.4,
            order_type: "LONG",
            order_uuid: "41616e73-6f1f-4a8e-aeda-909a785b9738",
            price: 64486.19,
            price_sources: [],
            processed_ms: 1711244330621,
          },
          {
            leverage: 0.4,
            order_type: "FLAT",
            order_uuid: "358a386a-6f88-446f-bffd-c87a5601b35a",
            price: 64489.11,
            price_sources: [],
            processed_ms: 1711244776565,
          },
        ],
        position_type: "FLAT",
        position_uuid: "611ed443-7e1f-4b66-9f54-5c2472bd13a3",
        return_at_close: 0.9961887984418505,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 64461.628000000004,
        close_ms: 1711247477959,
        current_return: 0.9966869593555573,
        initial_entry_price: 64561.84,
        is_closed_position: true,
        miner_hotkey: "5FvmeMUskojSA2sVnAdEGxMajFn58f5A9qZ1B9ax6FTbpjXJ",
        net_leverage: 0.0,
        open_ms: 1711245229198,
        orders: [
          {
            leverage: 0.4,
            order_type: "LONG",
            order_uuid: "a54c36e6-3a1d-4b26-adf2-f6078a280078",
            price: 64561.84,
            price_sources: [],
            processed_ms: 1711245229195,
          },
          {
            leverage: 0.4,
            order_type: "LONG",
            order_uuid: "9fc6428f-7a80-413a-8742-d4c7d8093840",
            price: 64486.01,
            price_sources: [],
            processed_ms: 1711245675288,
          },
          {
            leverage: 0.4,
            order_type: "LONG",
            order_uuid: "4a4b00d8-3c50-4677-8ffa-7c02e9c7a6c0",
            price: 64465.0,
            price_sources: [],
            processed_ms: 1711246134095,
          },
          {
            leverage: 0.4,
            order_type: "LONG",
            order_uuid: "bd283b87-f7ed-4aff-8fc7-c0e1aeb5f2c9",
            price: 64469.34,
            price_sources: [],
            processed_ms: 1711246578240,
          },
          {
            leverage: 0.4,
            order_type: "LONG",
            order_uuid: "378bc761-daf2-46f8-b7f4-593a1a294ff8",
            price: 64325.95,
            price_sources: [],
            processed_ms: 1711247033823,
          },
          {
            leverage: 0.4,
            order_type: "FLAT",
            order_uuid: "897de7ed-0701-4e27-b83e-932cb4f3b924",
            price: 64354.68,
            price_sources: [],
            processed_ms: 1711247477959,
          },
        ],
        position_type: "FLAT",
        position_uuid: "1f990cf7-7ec5-4cff-96f6-a8ba44b8ba98",
        return_at_close: 0.992700211518135,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 64251.852000000006,
        close_ms: 1711250181143,
        current_return: 0.9970499707396329,
        initial_entry_price: 64353.26,
        is_closed_position: true,
        miner_hotkey: "5FvmeMUskojSA2sVnAdEGxMajFn58f5A9qZ1B9ax6FTbpjXJ",
        net_leverage: 0.0,
        open_ms: 1711247935042,
        orders: [
          {
            leverage: 0.4,
            order_type: "LONG",
            order_uuid: "9010d19a-fdee-41ed-96ce-527cbbdfdb3a",
            price: 64353.26,
            price_sources: [],
            processed_ms: 1711247935040,
          },
          {
            leverage: 0.4,
            order_type: "LONG",
            order_uuid: "5452ff60-af3a-4d86-bcbb-3907f9584f63",
            price: 64375.14,
            price_sources: [],
            processed_ms: 1711248376149,
          },
          {
            leverage: 0.4,
            order_type: "LONG",
            order_uuid: "4c4528d7-4a08-4cb8-9d0b-b8daa836fc42",
            price: 64196.7,
            price_sources: [],
            processed_ms: 1711248827935,
          },
          {
            leverage: 0.4,
            order_type: "LONG",
            order_uuid: "2e2c7d02-8268-4140-a495-237a682355a6",
            price: 64240.46,
            price_sources: [],
            processed_ms: 1711249282834,
          },
          {
            leverage: 0.4,
            order_type: "LONG",
            order_uuid: "e0e14290-d0b9-4280-b96b-e9a75555d5c5",
            price: 64093.7,
            price_sources: [],
            processed_ms: 1711249728362,
          },
          {
            leverage: 0.4,
            order_type: "FLAT",
            order_uuid: "5844c31d-464d-4203-98dd-05a2a9436ed7",
            price: 64156.93,
            price_sources: [],
            processed_ms: 1711250181143,
          },
        ],
        position_type: "FLAT",
        position_uuid: "08ccbd05-02f9-4d16-a590-0fd6facd3bb0",
        return_at_close: 0.9930617708566744,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 64161.5,
        close_ms: 1711252880926,
        current_return: 0.995477865419144,
        initial_entry_price: 64199.77,
        is_closed_position: true,
        miner_hotkey: "5FvmeMUskojSA2sVnAdEGxMajFn58f5A9qZ1B9ax6FTbpjXJ",
        net_leverage: 0.0,
        open_ms: 1711250636380,
        orders: [
          {
            leverage: 0.4,
            order_type: "LONG",
            order_uuid: "e67dd100-c552-4b8f-b3e9-81258f338d72",
            price: 64199.77,
            price_sources: [],
            processed_ms: 1711250636378,
          },
          {
            leverage: 0.4,
            order_type: "LONG",
            order_uuid: "fba18290-769d-4bfd-809a-af40c69ffb7a",
            price: 64226.21,
            price_sources: [],
            processed_ms: 1711251082481,
          },
          {
            leverage: 0.4,
            order_type: "LONG",
            order_uuid: "1bdc9790-d8f3-4822-8f19-6c56958f467d",
            price: 64175.51,
            price_sources: [],
            processed_ms: 1711251532480,
          },
          {
            leverage: 0.4,
            order_type: "LONG",
            order_uuid: "d2170ca0-1156-46bf-804a-3a8cd547576a",
            price: 64118.21,
            price_sources: [],
            processed_ms: 1711251987559,
          },
          {
            leverage: 0.4,
            order_type: "LONG",
            order_uuid: "f15f5c49-4139-4982-af8a-6474ef4b0fd7",
            price: 64087.8,
            price_sources: [],
            processed_ms: 1711252432340,
          },
          {
            leverage: 0.4,
            order_type: "FLAT",
            order_uuid: "4e1eaee3-703a-47e4-afaf-d40fc23156e5",
            price: 64016.34,
            price_sources: [],
            processed_ms: 1711252880926,
          },
        ],
        position_type: "FLAT",
        position_uuid: "24f30da6-c174-4b96-8e44-633cc432f10a",
        return_at_close: 0.9914959539574674,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 64008.46000000001,
        close_ms: 1711255588475,
        current_return: 1.0019461465300838,
        initial_entry_price: 64137.0,
        is_closed_position: true,
        miner_hotkey: "5FvmeMUskojSA2sVnAdEGxMajFn58f5A9qZ1B9ax6FTbpjXJ",
        net_leverage: 0.0,
        open_ms: 1711253335712,
        orders: [
          {
            leverage: 0.4,
            order_type: "LONG",
            order_uuid: "3a77285b-2d12-4b6c-9101-e2a381b0ea96",
            price: 64137.0,
            price_sources: [],
            processed_ms: 1711253335710,
          },
          {
            leverage: 0.4,
            order_type: "LONG",
            order_uuid: "bb6e35fb-d1a5-4b2e-b526-ecaf8637d10d",
            price: 64102.56,
            price_sources: [],
            processed_ms: 1711253782276,
          },
          {
            leverage: 0.4,
            order_type: "LONG",
            order_uuid: "9cfb93d7-fc59-430c-940d-c95ded9f179a",
            price: 63969.08,
            price_sources: [],
            processed_ms: 1711254235182,
          },
          {
            leverage: 0.4,
            order_type: "LONG",
            order_uuid: "9d182c55-40a3-4253-85d1-25d770f95793",
            price: 63939.28,
            price_sources: [],
            processed_ms: 1711254681072,
          },
          {
            leverage: 0.4,
            order_type: "LONG",
            order_uuid: "d0b5a3d2-8db8-47be-9b34-d534df4c6cf8",
            price: 63894.38,
            price_sources: [],
            processed_ms: 1711255132699,
          },
          {
            leverage: 0.4,
            order_type: "FLAT",
            order_uuid: "af9a46a0-160c-434f-b3db-34da5f6b62d2",
            price: 64070.87,
            price_sources: [],
            processed_ms: 1711255588475,
          },
        ],
        position_type: "FLAT",
        position_uuid: "74fef0b9-c635-4df1-9c1d-9106703a506c",
        return_at_close: 0.9979383619439635,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 64205.602000000006,
        close_ms: 1711258285890,
        current_return: 1.0025821485728466,
        initial_entry_price: 64146.58,
        is_closed_position: true,
        miner_hotkey: "5FvmeMUskojSA2sVnAdEGxMajFn58f5A9qZ1B9ax6FTbpjXJ",
        net_leverage: 0.0,
        open_ms: 1711256038483,
        orders: [
          {
            leverage: 0.4,
            order_type: "LONG",
            order_uuid: "c2be5831-9da4-479a-9acc-f39a3009cea0",
            price: 64146.58,
            price_sources: [],
            processed_ms: 1711256038481,
          },
          {
            leverage: 0.4,
            order_type: "LONG",
            order_uuid: "6423ff8d-180e-4102-9b4f-040c11283180",
            price: 64203.69,
            price_sources: [],
            processed_ms: 1711256483241,
          },
          {
            leverage: 0.4,
            order_type: "LONG",
            order_uuid: "f5b8e27a-35b0-4357-b77f-0f6241f51747",
            price: 64161.77,
            price_sources: [],
            processed_ms: 1711256933332,
          },
          {
            leverage: 0.4,
            order_type: "LONG",
            order_uuid: "7712abb5-c2dc-4c92-b281-41435c59ec3b",
            price: 64208.69,
            price_sources: [],
            processed_ms: 1711257386434,
          },
          {
            leverage: 0.4,
            order_type: "LONG",
            order_uuid: "21c18d7a-2183-4a63-8d8c-97da4038361f",
            price: 64307.28,
            price_sources: [],
            processed_ms: 1711257834475,
          },
          {
            leverage: 0.4,
            order_type: "FLAT",
            order_uuid: "80e3cf22-a413-4084-89a3-8f5f8ebdb49e",
            price: 64288.42,
            price_sources: [],
            processed_ms: 1711258285890,
          },
        ],
        position_type: "FLAT",
        position_uuid: "a3a4d86b-c53e-4acb-8a94-a12d3ad44ac3",
        return_at_close: 0.9985718199785552,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 64315.554000000004,
        close_ms: 1711260988648,
        current_return: 1.0026518833328044,
        initial_entry_price: 64321.08,
        is_closed_position: true,
        miner_hotkey: "5FvmeMUskojSA2sVnAdEGxMajFn58f5A9qZ1B9ax6FTbpjXJ",
        net_leverage: 0.0,
        open_ms: 1711258736767,
        orders: [
          {
            leverage: 0.4,
            order_type: "LONG",
            order_uuid: "a3e6f6ee-5658-4699-ac7e-2b6499c5ec3a",
            price: 64321.08,
            price_sources: [],
            processed_ms: 1711258736765,
          },
          {
            leverage: 0.4,
            order_type: "LONG",
            order_uuid: "100396b2-649e-404c-9e8d-e66b199795ef",
            price: 64335.64,
            price_sources: [],
            processed_ms: 1711259185614,
          },
          {
            leverage: 0.4,
            order_type: "LONG",
            order_uuid: "9ee7c59d-251f-4af9-b2f5-a69399f664dd",
            price: 64368.53,
            price_sources: [],
            processed_ms: 1711259637601,
          },
          {
            leverage: 0.4,
            order_type: "LONG",
            order_uuid: "3f393265-698b-41d2-8005-c02dc24f2793",
            price: 64294.53,
            price_sources: [],
            processed_ms: 1711260087618,
          },
          {
            leverage: 0.4,
            order_type: "LONG",
            order_uuid: "9a1f79a7-2d4c-4331-a16a-925a8752e781",
            price: 64257.99,
            price_sources: [],
            processed_ms: 1711260540231,
          },
          {
            leverage: 0.4,
            order_type: "FLAT",
            order_uuid: "ca2071de-9098-4cd9-b11c-0ed4ffb78cb3",
            price: 64400.84,
            price_sources: [],
            processed_ms: 1711260988648,
          },
        ],
        position_type: "FLAT",
        position_uuid: "307f8bce-db80-4737-bce7-fcd679a19a59",
        return_at_close: 0.9986412757994731,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 64229.64400000001,
        close_ms: 1711263693559,
        current_return: 1.0002066752112735,
        initial_entry_price: 64313.47,
        is_closed_position: true,
        miner_hotkey: "5FvmeMUskojSA2sVnAdEGxMajFn58f5A9qZ1B9ax6FTbpjXJ",
        net_leverage: 0.0,
        open_ms: 1711261433971,
        orders: [
          {
            leverage: 0.4,
            order_type: "LONG",
            order_uuid: "dca58008-672f-4d02-aa40-5f3b4b8acf6f",
            price: 64313.47,
            price_sources: [],
            processed_ms: 1711261433968,
          },
          {
            leverage: 0.4,
            order_type: "LONG",
            order_uuid: "2e75a5e7-fa6a-4a00-a9bd-8936b55778c6",
            price: 64287.92,
            price_sources: [],
            processed_ms: 1711261884207,
          },
          {
            leverage: 0.4,
            order_type: "LONG",
            order_uuid: "05cb7de3-db0c-4190-9c5d-2b3afa133157",
            price: 64269.2,
            price_sources: [],
            processed_ms: 1711262333294,
          },
          {
            leverage: 0.4,
            order_type: "LONG",
            order_uuid: "4235881c-7b37-4eed-b87c-09486c9d82c5",
            price: 64112.6,
            price_sources: [],
            processed_ms: 1711262786281,
          },
          {
            leverage: 0.4,
            order_type: "LONG",
            order_uuid: "86b8695d-39ae-43df-ae0a-26576e01e7a3",
            price: 64165.03,
            price_sources: [],
            processed_ms: 1711263236393,
          },
          {
            leverage: 0.4,
            order_type: "FLAT",
            order_uuid: "19be7b5f-9d8f-4683-ac07-2c0b9eb08725",
            price: 64236.29,
            price_sources: [],
            processed_ms: 1711263693559,
          },
        ],
        position_type: "FLAT",
        position_uuid: "c5f4c1b9-9416-4775-abe2-f67916046dbd",
        return_at_close: 0.9962058485104284,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 64129.086,
        close_ms: 1711266391892,
        current_return: 1.006751131185024,
        initial_entry_price: 64120.81,
        is_closed_position: true,
        miner_hotkey: "5FvmeMUskojSA2sVnAdEGxMajFn58f5A9qZ1B9ax6FTbpjXJ",
        net_leverage: 0.0,
        open_ms: 1711264136145,
        orders: [
          {
            leverage: 0.4,
            order_type: "LONG",
            order_uuid: "7d799617-fe33-4810-9611-34a8f240ad94",
            price: 64120.81,
            price_sources: [],
            processed_ms: 1711264136142,
          },
          {
            leverage: 0.4,
            order_type: "LONG",
            order_uuid: "7f8f00f9-ed7d-4a5a-b600-f657adb7a7dd",
            price: 64158.79,
            price_sources: [],
            processed_ms: 1711264585547,
          },
          {
            leverage: 0.4,
            order_type: "LONG",
            order_uuid: "69a9173b-f4c7-4deb-b9b6-6b7eca45c9e0",
            price: 64143.92,
            price_sources: [],
            processed_ms: 1711265042324,
          },
          {
            leverage: 0.4,
            order_type: "LONG",
            order_uuid: "1a786fe8-112d-4274-9813-f0eee2a3a8e0",
            price: 64124.68,
            price_sources: [],
            processed_ms: 1711265485372,
          },
          {
            leverage: 0.4,
            order_type: "LONG",
            order_uuid: "123fae8e-a359-4cb6-b4ae-6d8d1b8b6397",
            price: 64097.23,
            price_sources: [],
            processed_ms: 1711265946884,
          },
          {
            leverage: 0.4,
            order_type: "FLAT",
            order_uuid: "0f11dc74-d30b-48b0-b090-24c0f2717ef4",
            price: 64345.53,
            price_sources: [],
            processed_ms: 1711266391892,
          },
        ],
        position_type: "FLAT",
        position_uuid: "abd25d89-a2c7-454f-88b4-670d3d88ee77",
        return_at_close: 1.0027241266602838,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 64669.90400000001,
        close_ms: 1711308858995,
        current_return: 1.0317988853638436,
        initial_entry_price: 64399.49,
        is_closed_position: true,
        miner_hotkey: "5FvmeMUskojSA2sVnAdEGxMajFn58f5A9qZ1B9ax6FTbpjXJ",
        net_leverage: 0.0,
        open_ms: 1711266837024,
        orders: [
          {
            leverage: 0.4,
            order_type: "LONG",
            order_uuid: "cf5624c0-a7eb-4120-b4de-934ecced2842",
            price: 64399.49,
            price_sources: [],
            processed_ms: 1711266837021,
          },
          {
            leverage: 0.4,
            order_type: "LONG",
            order_uuid: "f2cac10a-5d54-453c-9975-46ce9b9f8dfb",
            price: 64539.3,
            price_sources: [],
            processed_ms: 1711267287492,
          },
          {
            leverage: 0.4,
            order_type: "LONG",
            order_uuid: "811730fb-50ff-4d45-b7ad-8c31b664e203",
            price: 64680.95,
            price_sources: [],
            processed_ms: 1711267742135,
          },
          {
            leverage: 0.4,
            order_type: "LONG",
            order_uuid: "0d145165-a6fe-4988-b5cf-905bbfb3be16",
            price: 64927.87,
            price_sources: [],
            processed_ms: 1711268191544,
          },
          {
            leverage: 0.4,
            order_type: "LONG",
            order_uuid: "c989029f-e06d-4f12-9b45-aff327a876c5",
            price: 64801.91,
            price_sources: [],
            processed_ms: 1711268638249,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "cdb851a9-d54a-410e-a29c-b54497e5a279",
            price: 65693.82,
            price_sources: [],
            processed_ms: 1711308858995,
          },
        ],
        position_type: "FLAT",
        position_uuid: "e8b7579d-bcaa-45b1-a7a4-8f4377332da1",
        return_at_close: 1.027671689822388,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 70345.6047368421,
        close_ms: 1711435121688,
        current_return: 1.041720723107469,
        initial_entry_price: 70358.56,
        is_closed_position: true,
        miner_hotkey: "5FvmeMUskojSA2sVnAdEGxMajFn58f5A9qZ1B9ax6FTbpjXJ",
        net_leverage: 0.0,
        open_ms: 1711433603943,
        orders: [
          {
            leverage: 10.0,
            order_type: "LONG",
            order_uuid: "9fa28b18-d91f-47f9-a1bb-4f3674c0c653",
            price: 70358.56,
            price_sources: [],
            processed_ms: 1711433603940,
          },
          {
            leverage: 9.0,
            order_type: "LONG",
            order_uuid: "c063b529-ce9c-4cbd-a1f9-f7d8d7e362f5",
            price: 70331.21,
            price_sources: [],
            processed_ms: 1711434192706,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "9c33bcf3-5eb6-445c-88bb-6a106a389ade",
            price: 70500.1,
            price_sources: [],
            processed_ms: 1711435121688,
          },
        ],
        position_type: "FLAT",
        position_uuid: "236ae72b-f371-426e-885f-ca9cd6a1ba5b",
        return_at_close: 1.0021353356293852,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 70808.60368421052,
        close_ms: 1711438579734,
        current_return: 1.1669870867255707,
        initial_entry_price: 70797.69,
        is_closed_position: true,
        miner_hotkey: "5FvmeMUskojSA2sVnAdEGxMajFn58f5A9qZ1B9ax6FTbpjXJ",
        net_leverage: 0.0,
        open_ms: 1711436776555,
        orders: [
          {
            leverage: 10.0,
            order_type: "LONG",
            order_uuid: "d874f6ae-4264-44d8-b0de-9300903f408e",
            price: 70797.69,
            price_sources: [],
            processed_ms: 1711436776552,
          },
          {
            leverage: 9.0,
            order_type: "LONG",
            order_uuid: "90b8fc49-7cfe-48f8-bcef-9b0cd7a315fb",
            price: 70820.73,
            price_sources: [],
            processed_ms: 1711436939938,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "e10f536b-fdd0-4dc5-a039-d2c74ec4a46e",
            price: 71430.83,
            price_sources: [],
            processed_ms: 1711438579734,
          },
        ],
        position_type: "FLAT",
        position_uuid: "2401bf0c-9902-4657-95f2-f4a7facbfe29",
        return_at_close: 1.122641577429999,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 69758.0,
        close_ms: 1711486100611,
        current_return: 0.9848981765532278,
        initial_entry_price: 69758.0,
        is_closed_position: true,
        miner_hotkey: "5FvmeMUskojSA2sVnAdEGxMajFn58f5A9qZ1B9ax6FTbpjXJ",
        net_leverage: 0.0,
        open_ms: 1711486015030,
        orders: [
          {
            leverage: -19.99,
            order_type: "SHORT",
            order_uuid: "afeaf212-110c-4132-83b5-e8c6e312c636",
            price: 69758.0,
            price_sources: [],
            processed_ms: 1711486015027,
          },
          {
            leverage: 19.99,
            order_type: "FLAT",
            order_uuid: "1daae212-0e79-44c7-8c01-f92de38e8d98",
            price: 69810.7,
            price_sources: [],
            processed_ms: 1711486100611,
          },
        ],
        position_type: "FLAT",
        position_uuid: "500fefff-0ff6-4589-aa3f-ac3ada4953e1",
        return_at_close: 0.9455219474546297,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 70162.22,
        close_ms: 1711489359349,
        current_return: 1.0248670466812477,
        initial_entry_price: 70162.22,
        is_closed_position: true,
        miner_hotkey: "5FvmeMUskojSA2sVnAdEGxMajFn58f5A9qZ1B9ax6FTbpjXJ",
        net_leverage: 0.0,
        open_ms: 1711489288197,
        orders: [
          {
            leverage: -19.99,
            order_type: "SHORT",
            order_uuid: "90a0aaaf-7b10-45f0-a8d3-275ac5daee03",
            price: 70162.22,
            price_sources: [],
            processed_ms: 1711489288193,
          },
          {
            leverage: 19.99,
            order_type: "FLAT",
            order_uuid: "68872949-b685-4934-b1db-005ed98c3d93",
            price: 70074.94,
            price_sources: [],
            processed_ms: 1711489359349,
          },
        ],
        position_type: "FLAT",
        position_uuid: "9e10788b-e144-4aec-8e38-950948999c62",
        return_at_close: 0.9838928621549314,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 69912.83,
        close_ms: 1711492802595,
        current_return: 1.0113799141016042,
        initial_entry_price: 69912.83,
        is_closed_position: true,
        miner_hotkey: "5FvmeMUskojSA2sVnAdEGxMajFn58f5A9qZ1B9ax6FTbpjXJ",
        net_leverage: 0.0,
        open_ms: 1711492629760,
        orders: [
          {
            leverage: 19.99,
            order_type: "LONG",
            order_uuid: "e3abb703-2065-493e-9e93-32d0d7588f2d",
            price: 69912.83,
            price_sources: [],
            processed_ms: 1711492629757,
          },
          {
            leverage: 0.010000000000001563,
            order_type: "FLAT",
            order_uuid: "7102ee3d-5e18-4bfd-81c6-6c6e6e757bc5",
            price: 69952.63,
            price_sources: [],
            processed_ms: 1711492802595,
          },
        ],
        position_type: "FLAT",
        position_uuid: "975fa2d0-8e61-42ee-9b79-c56563d2bacf",
        return_at_close: 0.9709449451358221,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 69926.55,
        close_ms: 1711494721243,
        current_return: 1.1093057844266592,
        initial_entry_price: 69926.55,
        is_closed_position: true,
        miner_hotkey: "5FvmeMUskojSA2sVnAdEGxMajFn58f5A9qZ1B9ax6FTbpjXJ",
        net_leverage: 0.0,
        open_ms: 1711492870668,
        orders: [
          {
            leverage: 19.99,
            order_type: "LONG",
            order_uuid: "4b2c8602-c9d8-440e-a0f2-4184cd1fd7f1",
            price: 69926.55,
            price_sources: [],
            processed_ms: 1711492870665,
          },
          {
            leverage: 0.010000000000001563,
            order_type: "FLAT",
            order_uuid: "ba2e2770-3dbf-4799-9286-7ca613d1b410",
            price: 70308.91,
            price_sources: [],
            processed_ms: 1711494721243,
          },
        ],
        position_type: "FLAT",
        position_uuid: "503b2b19-4e9b-43b3-a178-fac4cff65bd0",
        return_at_close: 1.0649557391652813,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 70281.35,
        close_ms: 1711495065726,
        current_return: 1.0069258274065571,
        initial_entry_price: 70281.35,
        is_closed_position: true,
        miner_hotkey: "5FvmeMUskojSA2sVnAdEGxMajFn58f5A9qZ1B9ax6FTbpjXJ",
        net_leverage: 0.0,
        open_ms: 1711494881286,
        orders: [
          {
            leverage: 19.99,
            order_type: "LONG",
            order_uuid: "5a8d7030-9102-4175-b929-7b950c7582d1",
            price: 70281.35,
            price_sources: [],
            processed_ms: 1711494881283,
          },
          {
            leverage: 0.010000000000001563,
            order_type: "FLAT",
            order_uuid: "b3ad9188-092d-4a0f-b107-219be3eefcbb",
            price: 70305.7,
            price_sources: [],
            processed_ms: 1711495065726,
          },
        ],
        position_type: "FLAT",
        position_uuid: "69073711-90f2-4f47-ac67-5b0fbcd1c9e7",
        return_at_close: 0.966668932826843,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 69920.94,
        close_ms: 1711528644428,
        current_return: 1.0398936942209325,
        initial_entry_price: 69920.94,
        is_closed_position: true,
        miner_hotkey: "5FvmeMUskojSA2sVnAdEGxMajFn58f5A9qZ1B9ax6FTbpjXJ",
        net_leverage: 0.0,
        open_ms: 1711527779347,
        orders: [
          {
            leverage: -19.99,
            order_type: "SHORT",
            order_uuid: "18e7a095-c4d7-497b-9eb4-c766ce33f8dc",
            price: 69920.94,
            price_sources: [],
            processed_ms: 1711527779344,
          },
          {
            leverage: 19.99,
            order_type: "FLAT",
            order_uuid: "b2f08ea2-622f-441f-8b15-e4b70a3f2b63",
            price: 69781.4,
            price_sources: [],
            processed_ms: 1711528644428,
          },
        ],
        position_type: "FLAT",
        position_uuid: "f6d6479c-a067-44e9-8090-e1f29bdfdc66",
        return_at_close: 0.9983187443259796,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 69526.87,
        close_ms: 1711530020768,
        current_return: 1.0005692791865926,
        initial_entry_price: 69526.87,
        is_closed_position: true,
        miner_hotkey: "5FvmeMUskojSA2sVnAdEGxMajFn58f5A9qZ1B9ax6FTbpjXJ",
        net_leverage: 0.0,
        open_ms: 1711529943658,
        orders: [
          {
            leverage: -19.99,
            order_type: "SHORT",
            order_uuid: "27f0005c-e6d3-4f05-be3a-36c6f7b173fa",
            price: 69526.87,
            price_sources: [],
            processed_ms: 1711529943655,
          },
          {
            leverage: 19.99,
            order_type: "FLAT",
            order_uuid: "a09015c0-dc82-42ad-bb22-fe75d112e9e7",
            price: 69524.89,
            price_sources: [],
            processed_ms: 1711530020768,
          },
        ],
        position_type: "FLAT",
        position_uuid: "5f3e291c-b1da-4d88-ac9a-93344ea8eeef",
        return_at_close: 0.9605665194047126,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 69502.96,
        close_ms: 1711530822149,
        current_return: 1.078777378690057,
        initial_entry_price: 69502.96,
        is_closed_position: true,
        miner_hotkey: "5FvmeMUskojSA2sVnAdEGxMajFn58f5A9qZ1B9ax6FTbpjXJ",
        net_leverage: 0.0,
        open_ms: 1711530400059,
        orders: [
          {
            leverage: 19.99,
            order_type: "LONG",
            order_uuid: "9011177d-254d-42c5-b6fb-509251a843e9",
            price: 69502.96,
            price_sources: [],
            processed_ms: 1711530400056,
          },
          {
            leverage: 0.010000000000001563,
            order_type: "FLAT",
            order_uuid: "c0309bfe-bee5-4f2d-b23f-a91db895fcb5",
            price: 69776.86,
            price_sources: [],
            processed_ms: 1711530822149,
          },
        ],
        position_type: "FLAT",
        position_uuid: "3e988eeb-ccf9-4fc9-9dec-c75ab4f0af95",
        return_at_close: 1.0356478590900284,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 69710.84,
        close_ms: 1711531945632,
        current_return: 1.0145672036085072,
        initial_entry_price: 69710.84,
        is_closed_position: true,
        miner_hotkey: "5FvmeMUskojSA2sVnAdEGxMajFn58f5A9qZ1B9ax6FTbpjXJ",
        net_leverage: 0.0,
        open_ms: 1711531088881,
        orders: [
          {
            leverage: 19.99,
            order_type: "LONG",
            order_uuid: "cda65581-a48c-47a8-be7e-016506592fa6",
            price: 69710.84,
            price_sources: [],
            processed_ms: 1711531088878,
          },
          {
            leverage: 0.010000000000001563,
            order_type: "FLAT",
            order_uuid: "ebcf85e5-183f-49c7-a28a-0b649944ed05",
            price: 69761.64,
            price_sources: [],
            processed_ms: 1711531945632,
          },
        ],
        position_type: "FLAT",
        position_uuid: "20dcf4b8-bd2f-4e29-954f-f26efb7c1bb8",
        return_at_close: 0.974004806808239,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 69787.07,
        close_ms: 1711532431994,
        current_return: 1.0260261879457,
        initial_entry_price: 69787.07,
        is_closed_position: true,
        miner_hotkey: "5FvmeMUskojSA2sVnAdEGxMajFn58f5A9qZ1B9ax6FTbpjXJ",
        net_leverage: 0.0,
        open_ms: 1711532208266,
        orders: [
          {
            leverage: 19.99,
            order_type: "LONG",
            order_uuid: "28416aa9-599f-4194-a9e3-adee1c527548",
            price: 69787.07,
            price_sources: [],
            processed_ms: 1711532208263,
          },
          {
            leverage: 0.010000000000001563,
            order_type: "FLAT",
            order_uuid: "8b269dac-3dba-4e14-b5c8-636c1554aa72",
            price: 69877.93,
            price_sources: [],
            processed_ms: 1711532431994,
          },
        ],
        position_type: "FLAT",
        position_uuid: "2b71df5a-5ee5-48f7-9e0d-c710d1ae2bdb",
        return_at_close: 0.9850056609516309,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 69688.56,
        close_ms: 1711548316013,
        current_return: 1.1592578179259256,
        initial_entry_price: 69688.56,
        is_closed_position: true,
        miner_hotkey: "5FvmeMUskojSA2sVnAdEGxMajFn58f5A9qZ1B9ax6FTbpjXJ",
        net_leverage: 0.0,
        open_ms: 1711548208219,
        orders: [
          {
            leverage: -19.99,
            order_type: "SHORT",
            order_uuid: "9cdd00a8-784b-4d2a-9f3a-da07cf2640db",
            price: 69688.56,
            price_sources: [],
            processed_ms: 1711548208216,
          },
          {
            leverage: 19.99,
            order_type: "FLAT",
            order_uuid: "1b5a14d6-cef6-4ce6-8275-349420833737",
            price: 69133.36,
            price_sources: [],
            processed_ms: 1711548316013,
          },
        ],
        position_type: "FLAT",
        position_uuid: "32481f82-b3f9-42ec-b605-43f8ff3d9a90",
        return_at_close: 1.112910690365247,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 69351.96,
        close_ms: 1711548828778,
        current_return: 1.1942390842306416,
        initial_entry_price: 69351.96,
        is_closed_position: true,
        miner_hotkey: "5FvmeMUskojSA2sVnAdEGxMajFn58f5A9qZ1B9ax6FTbpjXJ",
        net_leverage: 0.0,
        open_ms: 1711548565605,
        orders: [
          {
            leverage: -19.99,
            order_type: "SHORT",
            order_uuid: "95c14c64-8fb7-4252-b43f-c715cf1bbe28",
            price: 69351.96,
            price_sources: [],
            processed_ms: 1711548565602,
          },
          {
            leverage: 19.99,
            order_type: "FLAT",
            order_uuid: "e77f4cd0-246e-4455-9a42-13f950f7e535",
            price: 68678.08,
            price_sources: [],
            processed_ms: 1711548828778,
          },
        ],
        position_type: "FLAT",
        position_uuid: "963df188-8d2c-46c1-99e7-8defa24484ec",
        return_at_close: 1.1464934056431006,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 1.0814000000000001,
        close_ms: 1711582542438,
        current_return: 1.0461438875532691,
        initial_entry_price: 1.0814,
        is_closed_position: true,
        miner_hotkey: "5FvmeMUskojSA2sVnAdEGxMajFn58f5A9qZ1B9ax6FTbpjXJ",
        net_leverage: 0.0,
        open_ms: 1711582470432,
        orders: [
          {
            leverage: -499.0,
            order_type: "SHORT",
            order_uuid: "2f65408f-9479-4cb5-8ce8-04e787c5bd25",
            price: 1.0814,
            price_sources: [],
            processed_ms: 1711582470429,
          },
          {
            leverage: 499.0,
            order_type: "FLAT",
            order_uuid: "914e843d-e188-4cdd-944e-fcd9d1c20e82",
            price: 1.0813,
            price_sources: [],
            processed_ms: 1711582542438,
          },
        ],
        position_type: "FLAT",
        position_uuid: "4143547f-07a0-487f-aa93-da8b5e8a676b",
        return_at_close: 1.0096020815610334,
        trade_pair: ["EURUSD", "EUR/USD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 1.0814000000000001,
        close_ms: 1711583175158,
        current_return: 1.0461438875532691,
        initial_entry_price: 1.0814,
        is_closed_position: true,
        miner_hotkey: "5FvmeMUskojSA2sVnAdEGxMajFn58f5A9qZ1B9ax6FTbpjXJ",
        net_leverage: 0.0,
        open_ms: 1711582896007,
        orders: [
          {
            leverage: -499.0,
            order_type: "SHORT",
            order_uuid: "32ed6c8e-3dba-423b-a1cb-eb2efa648515",
            price: 1.0814,
            price_sources: [],
            processed_ms: 1711582896004,
          },
          {
            leverage: 499.0,
            order_type: "FLAT",
            order_uuid: "5f160ea8-bf1e-46af-9688-aaa71255f0bf",
            price: 1.0813,
            price_sources: [],
            processed_ms: 1711583175158,
          },
        ],
        position_type: "FLAT",
        position_uuid: "2e07fbf5-bc0d-4346-ac3a-10fb0a4b484c",
        return_at_close: 1.0096020815610334,
        trade_pair: ["EURUSD", "EUR/USD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 1.0814000000000001,
        close_ms: 1711583644689,
        current_return: 1.092287775106436,
        initial_entry_price: 1.0814,
        is_closed_position: true,
        miner_hotkey: "5FvmeMUskojSA2sVnAdEGxMajFn58f5A9qZ1B9ax6FTbpjXJ",
        net_leverage: 0.0,
        open_ms: 1711583530698,
        orders: [
          {
            leverage: -499.0,
            order_type: "SHORT",
            order_uuid: "870c4ffa-2e64-44f2-9cd6-ddecd4c9c184",
            price: 1.0814,
            price_sources: [],
            processed_ms: 1711583530694,
          },
          {
            leverage: 499.0,
            order_type: "FLAT",
            order_uuid: "36560b73-3d9f-4396-b8ae-9954b925e69c",
            price: 1.0812,
            price_sources: [],
            processed_ms: 1711583644689,
          },
        ],
        position_type: "FLAT",
        position_uuid: "3901dccf-5fb8-4f78-bb55-d2581a6b52f3",
        return_at_close: 1.054134163121968,
        trade_pair: ["EURUSD", "EUR/USD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 1.0813,
        close_ms: 1711584222965,
        current_return: 1.0692222324978604,
        initial_entry_price: 1.0813,
        is_closed_position: true,
        miner_hotkey: "5FvmeMUskojSA2sVnAdEGxMajFn58f5A9qZ1B9ax6FTbpjXJ",
        net_leverage: 0.0,
        open_ms: 1711584076755,
        orders: [
          {
            leverage: -499.0,
            order_type: "SHORT",
            order_uuid: "6809bdf6-6d75-46b7-858f-43ea58acba54",
            price: 1.0813,
            price_sources: [],
            processed_ms: 1711584076751,
          },
          {
            leverage: 499.0,
            order_type: "FLAT",
            order_uuid: "8a70486d-3ba3-4274-a6c1-f1c644497906",
            price: 1.08115,
            price_sources: [],
            processed_ms: 1711584222965,
          },
        ],
        position_type: "FLAT",
        position_uuid: "714e3e5f-9ad1-42f5-a1f4-ff0e52062009",
        return_at_close: 1.03187429991671,
        trade_pair: ["EURUSD", "EUR/USD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 1.0811,
        close_ms: 1711585010454,
        current_return: 1.0923133845157609,
        initial_entry_price: 1.0811,
        is_closed_position: true,
        miner_hotkey: "5FvmeMUskojSA2sVnAdEGxMajFn58f5A9qZ1B9ax6FTbpjXJ",
        net_leverage: 0.0,
        open_ms: 1711584485502,
        orders: [
          {
            leverage: -499.0,
            order_type: "SHORT",
            order_uuid: "be216cd1-5795-4340-a8b0-d5db306b5d4a",
            price: 1.0811,
            price_sources: [],
            processed_ms: 1711584485498,
          },
          {
            leverage: 499.0,
            order_type: "FLAT",
            order_uuid: "d9032d39-0802-41ea-9b3e-c335f5ae848c",
            price: 1.0809,
            price_sources: [],
            processed_ms: 1711585010454,
          },
        ],
        position_type: "FLAT",
        position_uuid: "bc1ab2cd-cdcd-4fb7-985f-76cc011270d8",
        return_at_close: 1.0541588779946254,
        trade_pair: ["EURUSD", "EUR/USD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.9056,
        close_ms: 1711620820892,
        current_return: 1.110203180212002,
        initial_entry_price: 0.9056,
        is_closed_position: true,
        miner_hotkey: "5FvmeMUskojSA2sVnAdEGxMajFn58f5A9qZ1B9ax6FTbpjXJ",
        net_leverage: 0.0,
        open_ms: 1711620740002,
        orders: [
          {
            leverage: -499.0,
            order_type: "SHORT",
            order_uuid: "523a476f-2ce7-443e-b000-616d132a0965",
            price: 0.9056,
            price_sources: [],
            processed_ms: 1711620739998,
          },
          {
            leverage: 499.0,
            order_type: "FLAT",
            order_uuid: "65411191-27eb-41c9-894a-5cdb6f0be0e7",
            price: 0.9054,
            price_sources: [],
            processed_ms: 1711620820892,
          },
        ],
        position_type: "FLAT",
        position_uuid: "6e5186d0-58a2-4953-aaf6-13054dec4afd",
        return_at_close: 1.0714237831271969,
        trade_pair: ["USDCHF", "USD/CHF", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.90555,
        close_ms: 1711620973688,
        current_return: 1.110209265087565,
        initial_entry_price: 0.90555,
        is_closed_position: true,
        miner_hotkey: "5FvmeMUskojSA2sVnAdEGxMajFn58f5A9qZ1B9ax6FTbpjXJ",
        net_leverage: 0.0,
        open_ms: 1711620906445,
        orders: [
          {
            leverage: 499.0,
            order_type: "LONG",
            order_uuid: "f3db8756-5ded-47d1-9ae4-e93c52c879f8",
            price: 0.90555,
            price_sources: [],
            processed_ms: 1711620906442,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "d9b037c4-60ef-4abc-a52b-a6dce69c96c1",
            price: 0.90575,
            price_sources: [],
            processed_ms: 1711620973688,
          },
        ],
        position_type: "FLAT",
        position_uuid: "0ca518fd-9e4a-467f-89f5-5890be30e067",
        return_at_close: 1.0714296554580562,
        trade_pair: ["USDCHF", "USD/CHF", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.9056,
        close_ms: 1711621142769,
        current_return: 1.165304770318003,
        initial_entry_price: 0.9056,
        is_closed_position: true,
        miner_hotkey: "5FvmeMUskojSA2sVnAdEGxMajFn58f5A9qZ1B9ax6FTbpjXJ",
        net_leverage: 0.0,
        open_ms: 1711621074403,
        orders: [
          {
            leverage: -499.0,
            order_type: "SHORT",
            order_uuid: "6bd5755e-bc17-48d3-b696-c99fcfc6dba5",
            price: 0.9056,
            price_sources: [],
            processed_ms: 1711621074399,
          },
          {
            leverage: 499.0,
            order_type: "FLAT",
            order_uuid: "b4c50e31-a059-47e2-b3f5-1fbb4ea0602a",
            price: 0.9053,
            price_sources: [],
            processed_ms: 1711621142769,
          },
        ],
        position_type: "FLAT",
        position_uuid: "ab103575-ab26-45a0-aba1-0f2aa53f894b",
        return_at_close: 1.1246006746907953,
        trade_pair: ["USDCHF", "USD/CHF", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.9052,
        close_ms: 1711621278452,
        current_return: 0.9448740609810048,
        initial_entry_price: 0.9052,
        is_closed_position: true,
        miner_hotkey: "5FvmeMUskojSA2sVnAdEGxMajFn58f5A9qZ1B9ax6FTbpjXJ",
        net_leverage: 0.0,
        open_ms: 1711621210486,
        orders: [
          {
            leverage: -499.0,
            order_type: "SHORT",
            order_uuid: "2c964327-46f3-44b7-9d23-fe0b8143f15f",
            price: 0.9052,
            price_sources: [],
            processed_ms: 1711621210483,
          },
          {
            leverage: 499.0,
            order_type: "FLAT",
            order_uuid: "3cc50965-9583-4196-a6cc-0f02d6cad421",
            price: 0.9053,
            price_sources: [],
            processed_ms: 1711621278452,
          },
        ],
        position_type: "FLAT",
        position_uuid: "2978f6f8-7bf9-42b6-a5f6-839994f5bbdd",
        return_at_close: 0.9118696100309382,
        trade_pair: ["USDCHF", "USD/CHF", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.9053,
        close_ms: 1711621714050,
        current_return: 1.0551198497735497,
        initial_entry_price: 0.9053,
        is_closed_position: true,
        miner_hotkey: "5FvmeMUskojSA2sVnAdEGxMajFn58f5A9qZ1B9ax6FTbpjXJ",
        net_leverage: 0.0,
        open_ms: 1711621626815,
        orders: [
          {
            leverage: 499.0,
            order_type: "LONG",
            order_uuid: "58ecf832-b745-4e34-8804-4f9ab9dae8bf",
            price: 0.9053,
            price_sources: [],
            processed_ms: 1711621626811,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "841795dd-3e43-438b-a009-8263c5b54881",
            price: 0.9054,
            price_sources: [],
            processed_ms: 1711621714050,
          },
        ],
        position_type: "FLAT",
        position_uuid: "454e25cf-79a0-438d-90d4-27f0c8781f27",
        return_at_close: 1.0182645134209596,
        trade_pair: ["USDCHF", "USD/CHF", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.90535,
        close_ms: 1711621949986,
        current_return: 0.9724415971723673,
        initial_entry_price: 0.90535,
        is_closed_position: true,
        miner_hotkey: "5FvmeMUskojSA2sVnAdEGxMajFn58f5A9qZ1B9ax6FTbpjXJ",
        net_leverage: 0.0,
        open_ms: 1711621829625,
        orders: [
          {
            leverage: -499.0,
            order_type: "SHORT",
            order_uuid: "f38b1936-9709-4af5-95ef-072835265266",
            price: 0.90535,
            price_sources: [],
            processed_ms: 1711621829621,
          },
          {
            leverage: 499.0,
            order_type: "FLAT",
            order_uuid: "24dd129e-7447-4300-b176-94cdd8fdab54",
            price: 0.9054,
            price_sources: [],
            processed_ms: 1711621949986,
          },
        ],
        position_type: "FLAT",
        position_uuid: "91e18939-c412-49c5-ba99-309b25cd22c0",
        return_at_close: 0.9384742121831365,
        trade_pair: ["USDCHF", "USD/CHF", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 1.0796,
        close_ms: 1711641810908,
        current_return: 1.0,
        initial_entry_price: 1.0796,
        is_closed_position: true,
        miner_hotkey: "5FvmeMUskojSA2sVnAdEGxMajFn58f5A9qZ1B9ax6FTbpjXJ",
        net_leverage: 0.0,
        open_ms: 1711641754771,
        orders: [
          {
            leverage: 499.0,
            order_type: "LONG",
            order_uuid: "a46dfb37-52de-43f1-aeeb-b6287fca5244",
            price: 1.0796,
            price_sources: [],
            processed_ms: 1711641754767,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "a163a8df-1b6c-454d-9e5e-a80fac5aab34",
            price: 1.0796,
            price_sources: [],
            processed_ms: 1711641810908,
          },
        ],
        position_type: "FLAT",
        position_uuid: "cd22a519-a4a4-4ada-a86c-fae391945e02",
        return_at_close: 0.96507,
        trade_pair: ["EURUSD", "EUR/USD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 1.2633,
        close_ms: 1711642117190,
        current_return: 1.177748753265282,
        initial_entry_price: 1.2633,
        is_closed_position: true,
        miner_hotkey: "5FvmeMUskojSA2sVnAdEGxMajFn58f5A9qZ1B9ax6FTbpjXJ",
        net_leverage: 0.0,
        open_ms: 1711641979076,
        orders: [
          {
            leverage: -499.0,
            order_type: "SHORT",
            order_uuid: "b52cd7bd-5c63-4692-a91e-5d5b3c71e6c5",
            price: 1.2633,
            price_sources: [],
            processed_ms: 1711641979059,
          },
          {
            leverage: 499.0,
            order_type: "FLAT",
            order_uuid: "fab61043-b77f-4da2-83d8-76b3c9979267",
            price: 1.26285,
            price_sources: [],
            processed_ms: 1711642117190,
          },
        ],
        position_type: "FLAT",
        position_uuid: "21581a20-e643-4272-af4d-64f528a25505",
        return_at_close: 1.1366099893137256,
        trade_pair: ["GBPUSD", "GBP/USD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 1.2627,
        close_ms: 1711642501147,
        current_return: 1.1383147224202392,
        initial_entry_price: 1.2627,
        is_closed_position: true,
        miner_hotkey: "5FvmeMUskojSA2sVnAdEGxMajFn58f5A9qZ1B9ax6FTbpjXJ",
        net_leverage: 0.0,
        open_ms: 1711642408587,
        orders: [
          {
            leverage: 499.0,
            order_type: "LONG",
            order_uuid: "59044a41-9cb6-4714-983e-3f955e701e3f",
            price: 1.2627,
            price_sources: [],
            processed_ms: 1711642408583,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "01ee126c-dd8f-4eb4-a37e-0e3b4d4e3f7a",
            price: 1.26305,
            price_sources: [],
            processed_ms: 1711642501147,
          },
        ],
        position_type: "FLAT",
        position_uuid: "ce738e7a-525c-455e-9088-85de72b192ca",
        return_at_close: 1.0985533891661003,
        trade_pair: ["GBPUSD", "GBP/USD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 1.2634,
        close_ms: 1711642651925,
        current_return: 1.0,
        initial_entry_price: 1.2634,
        is_closed_position: true,
        miner_hotkey: "5FvmeMUskojSA2sVnAdEGxMajFn58f5A9qZ1B9ax6FTbpjXJ",
        net_leverage: 0.0,
        open_ms: 1711642601757,
        orders: [
          {
            leverage: 499.0,
            order_type: "LONG",
            order_uuid: "414d00fe-6e74-4690-92cf-0e770a6975af",
            price: 1.2634,
            price_sources: [],
            processed_ms: 1711642601753,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "97f97152-a0ef-4ac7-9dfc-6a348cce7a2f",
            price: 1.2634,
            price_sources: [],
            processed_ms: 1711642651925,
          },
        ],
        position_type: "FLAT",
        position_uuid: "4a4c0780-dc9e-4199-a755-4ad48b9279aa",
        return_at_close: 0.96507,
        trade_pair: ["GBPUSD", "GBP/USD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 1.2634,
        close_ms: 1711643182306,
        current_return: 1.1974829824283462,
        initial_entry_price: 1.2634,
        is_closed_position: true,
        miner_hotkey: "5FvmeMUskojSA2sVnAdEGxMajFn58f5A9qZ1B9ax6FTbpjXJ",
        net_leverage: 0.0,
        open_ms: 1711643011699,
        orders: [
          {
            leverage: 499.0,
            order_type: "LONG",
            order_uuid: "c30c28db-b989-4b68-9ce8-03be71bacbc8",
            price: 1.2634,
            price_sources: [],
            processed_ms: 1711643011695,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "23d16f74-35d4-4b6d-9f85-3b682315bfb8",
            price: 1.2639,
            price_sources: [],
            processed_ms: 1711643182306,
          },
        ],
        position_type: "FLAT",
        position_uuid: "59a48323-74ed-43b2-9500-6c8b9b42b67b",
        return_at_close: 1.155654901852124,
        trade_pair: ["GBPUSD", "GBP/USD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 1.26425,
        close_ms: 1711643977643,
        current_return: 1.0592050622898448,
        initial_entry_price: 1.26425,
        is_closed_position: true,
        miner_hotkey: "5FvmeMUskojSA2sVnAdEGxMajFn58f5A9qZ1B9ax6FTbpjXJ",
        net_leverage: 0.0,
        open_ms: 1711643917498,
        orders: [
          {
            leverage: 499.0,
            order_type: "LONG",
            order_uuid: "6abaa271-30f8-4b61-a28d-156f61120b50",
            price: 1.26425,
            price_sources: [],
            processed_ms: 1711643917494,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "14a7c7f9-cc6b-4525-b1fa-ecccd0502198",
            price: 1.2644,
            price_sources: [],
            processed_ms: 1711643977643,
          },
        ],
        position_type: "FLAT",
        position_uuid: "909d07c3-b52e-4465-ae4f-fb761f7af0cb",
        return_at_close: 1.0222070294640604,
        trade_pair: ["GBPUSD", "GBP/USD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 1.26445,
        close_ms: 1711644199506,
        current_return: 0.9605362015105425,
        initial_entry_price: 1.26445,
        is_closed_position: true,
        miner_hotkey: "5FvmeMUskojSA2sVnAdEGxMajFn58f5A9qZ1B9ax6FTbpjXJ",
        net_leverage: 0.0,
        open_ms: 1711644141550,
        orders: [
          {
            leverage: 499.0,
            order_type: "LONG",
            order_uuid: "6f8a99a7-b979-4efe-bc50-b6ed69074377",
            price: 1.26445,
            price_sources: [],
            processed_ms: 1711644141547,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "2b019552-f0b7-4cec-95d1-1ff891bef142",
            price: 1.26435,
            price_sources: [],
            processed_ms: 1711644199506,
          },
        ],
        position_type: "FLAT",
        position_uuid: "30b8c011-8081-4138-b7a8-a38164206d09",
        return_at_close: 0.9269846719917793,
        trade_pair: ["GBPUSD", "GBP/USD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 1.0787,
        close_ms: 1711653107014,
        current_return: 1.046259386298317,
        initial_entry_price: 1.0787,
        is_closed_position: true,
        miner_hotkey: "5FvmeMUskojSA2sVnAdEGxMajFn58f5A9qZ1B9ax6FTbpjXJ",
        net_leverage: 0.0,
        open_ms: 1711653000037,
        orders: [
          {
            leverage: 499.0,
            order_type: "LONG",
            order_uuid: "9d7727bb-5955-4f3e-a9ef-ded75a7f4af0",
            price: 1.0787,
            price_sources: [],
            processed_ms: 1711653000033,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "b2147ae9-03aa-4512-8171-3e15dbb73773",
            price: 1.0788,
            price_sources: [],
            processed_ms: 1711653107014,
          },
        ],
        position_type: "FLAT",
        position_uuid: "ddb9cb84-74a6-4bd6-a7e6-df2b86bffc7a",
        return_at_close: 1.0097135459349167,
        trade_pair: ["EURUSD", "EUR/USD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 1.0785,
        close_ms: 1711656849595,
        current_return: 1.0462679647658735,
        initial_entry_price: 1.0785,
        is_closed_position: true,
        miner_hotkey: "5FvmeMUskojSA2sVnAdEGxMajFn58f5A9qZ1B9ax6FTbpjXJ",
        net_leverage: 0.0,
        open_ms: 1711656782672,
        orders: [
          {
            leverage: 499.0,
            order_type: "LONG",
            order_uuid: "82611cb4-0c6e-4efa-8247-b8f2f17e712c",
            price: 1.0785,
            price_sources: [],
            processed_ms: 1711656782664,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "3e77d972-5598-4329-8f75-00de7b07b405",
            price: 1.0786,
            price_sources: [],
            processed_ms: 1711656849595,
          },
        ],
        position_type: "FLAT",
        position_uuid: "1b900ebc-3afb-4435-9d28-56f4dc6d428c",
        return_at_close: 1.0097218247566015,
        trade_pair: ["EURUSD", "EUR/USD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 1.07865,
        close_ms: 1711657033558,
        current_return: 1.0231307653084336,
        initial_entry_price: 1.07865,
        is_closed_position: true,
        miner_hotkey: "5FvmeMUskojSA2sVnAdEGxMajFn58f5A9qZ1B9ax6FTbpjXJ",
        net_leverage: 0.0,
        open_ms: 1711656902637,
        orders: [
          {
            leverage: 499.0,
            order_type: "LONG",
            order_uuid: "77912daa-e5ab-407c-8a3f-18dc885d5d0b",
            price: 1.07865,
            price_sources: [],
            processed_ms: 1711656902633,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "aa44c25c-8a14-41c7-badd-3007f2acef56",
            price: 1.0787,
            price_sources: [],
            processed_ms: 1711657033558,
          },
        ],
        position_type: "FLAT",
        position_uuid: "b64a4baf-816b-425f-90f3-732c23f9282e",
        return_at_close: 0.98739280767621,
        trade_pair: ["EURUSD", "EUR/USD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 1.0787,
        close_ms: 1711657136818,
        current_return: 1.046259386298317,
        initial_entry_price: 1.0787,
        is_closed_position: true,
        miner_hotkey: "5FvmeMUskojSA2sVnAdEGxMajFn58f5A9qZ1B9ax6FTbpjXJ",
        net_leverage: 0.0,
        open_ms: 1711657084054,
        orders: [
          {
            leverage: 499.0,
            order_type: "LONG",
            order_uuid: "1c862e1f-bf8b-4e63-9b0e-ad924d304941",
            price: 1.0787,
            price_sources: [],
            processed_ms: 1711657084050,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "196bdb6a-2ba9-4152-a7a3-9f88117fe8ae",
            price: 1.0788,
            price_sources: [],
            processed_ms: 1711657136818,
          },
        ],
        position_type: "FLAT",
        position_uuid: "69ef5da3-09c4-406f-95c3-cd7a8fe92755",
        return_at_close: 1.0097135459349167,
        trade_pair: ["EURUSD", "EUR/USD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 1.07875,
        close_ms: 1711657442081,
        current_return: 1.0693858632677147,
        initial_entry_price: 1.07875,
        is_closed_position: true,
        miner_hotkey: "5FvmeMUskojSA2sVnAdEGxMajFn58f5A9qZ1B9ax6FTbpjXJ",
        net_leverage: 0.0,
        open_ms: 1711657349078,
        orders: [
          {
            leverage: -499.0,
            order_type: "SHORT",
            order_uuid: "729967f6-2db2-4e39-b80b-e54a54b8d496",
            price: 1.07875,
            price_sources: [],
            processed_ms: 1711657349074,
          },
          {
            leverage: 499.0,
            order_type: "FLAT",
            order_uuid: "5bf84cff-c9f0-473f-8e90-3324ee9f94df",
            price: 1.0786,
            price_sources: [],
            processed_ms: 1711657442081,
          },
        ],
        position_type: "FLAT",
        position_uuid: "b17e3255-3e72-4df8-9c9f-e37877172540",
        return_at_close: 1.0320322150637733,
        trade_pair: ["EURUSD", "EUR/USD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 1.0788,
        close_ms: 1711657988725,
        current_return: 1.0,
        initial_entry_price: 1.0788,
        is_closed_position: true,
        miner_hotkey: "5FvmeMUskojSA2sVnAdEGxMajFn58f5A9qZ1B9ax6FTbpjXJ",
        net_leverage: 0.0,
        open_ms: 1711657815440,
        orders: [
          {
            leverage: 499.0,
            order_type: "LONG",
            order_uuid: "90395bee-b434-4b09-a2f2-c290bde7c56b",
            price: 1.0788,
            price_sources: [],
            processed_ms: 1711657815436,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "caf92b46-5859-4b34-a47c-b4a6f997577a",
            price: 1.0788,
            price_sources: [],
            processed_ms: 1711657988725,
          },
        ],
        position_type: "FLAT",
        position_uuid: "8932036d-91e8-43a9-ac97-ba620a207b38",
        return_at_close: 0.96507,
        trade_pair: ["EURUSD", "EUR/USD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 1.0788,
        close_ms: 1711658423572,
        current_return: 1.0,
        initial_entry_price: 1.0788,
        is_closed_position: true,
        miner_hotkey: "5FvmeMUskojSA2sVnAdEGxMajFn58f5A9qZ1B9ax6FTbpjXJ",
        net_leverage: 0.0,
        open_ms: 1711658360453,
        orders: [
          {
            leverage: 499.0,
            order_type: "LONG",
            order_uuid: "7339e153-6424-444f-993d-31ddae7d3986",
            price: 1.0788,
            price_sources: [],
            processed_ms: 1711658360448,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "3fd1b6cc-4627-4cc4-985b-82b84354b919",
            price: 1.0788,
            price_sources: [],
            processed_ms: 1711658423572,
          },
        ],
        position_type: "FLAT",
        position_uuid: "d391d8ce-276d-4b51-ad88-52801eedd527",
        return_at_close: 0.96507,
        trade_pair: ["EURUSD", "EUR/USD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.6516,
        close_ms: 1711658682711,
        current_return: 1.0765807243707712,
        initial_entry_price: 0.6516,
        is_closed_position: true,
        miner_hotkey: "5FvmeMUskojSA2sVnAdEGxMajFn58f5A9qZ1B9ax6FTbpjXJ",
        net_leverage: 0.0,
        open_ms: 1711658551393,
        orders: [
          {
            leverage: -499.0,
            order_type: "SHORT",
            order_uuid: "15cbc51f-9dbe-4496-83b2-f81b6c3751b2",
            price: 0.6516,
            price_sources: [],
            processed_ms: 1711658551389,
          },
          {
            leverage: 499.0,
            order_type: "FLAT",
            order_uuid: "cad2e2ac-b16a-4aa0-8f85-6844584b136d",
            price: 0.6515,
            price_sources: [],
            processed_ms: 1711658682711,
          },
        ],
        position_type: "FLAT",
        position_uuid: "6735abfd-bc2a-4a5c-a558-f29b661a9140",
        return_at_close: 1.0389757596685,
        trade_pair: ["AUDUSD", "AUD/USD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.6515,
        close_ms: 1711658877097,
        current_return: 1.0382962394474249,
        initial_entry_price: 0.6515,
        is_closed_position: true,
        miner_hotkey: "5FvmeMUskojSA2sVnAdEGxMajFn58f5A9qZ1B9ax6FTbpjXJ",
        net_leverage: 0.0,
        open_ms: 1711658778099,
        orders: [
          {
            leverage: -499.0,
            order_type: "SHORT",
            order_uuid: "5674e441-ae68-4dc3-88ca-f8589b5131ba",
            price: 0.6515,
            price_sources: [],
            processed_ms: 1711658778084,
          },
          {
            leverage: 499.0,
            order_type: "FLAT",
            order_uuid: "77a22a18-76aa-4317-8a5b-c03b98db92ed",
            price: 0.65145,
            price_sources: [],
            processed_ms: 1711658877097,
          },
        ],
        position_type: "FLAT",
        position_uuid: "64204ca4-d98f-4b57-af54-5c3fa1ffb770",
        return_at_close: 1.0020285518035263,
        trade_pair: ["AUDUSD", "AUD/USD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.6515,
        close_ms: 1711659058499,
        current_return: 1.0765924788948495,
        initial_entry_price: 0.6515,
        is_closed_position: true,
        miner_hotkey: "5FvmeMUskojSA2sVnAdEGxMajFn58f5A9qZ1B9ax6FTbpjXJ",
        net_leverage: 0.0,
        open_ms: 1711659005567,
        orders: [
          {
            leverage: 499.0,
            order_type: "LONG",
            order_uuid: "ae43816b-b57b-4336-85c3-ae737e2c40cd",
            price: 0.6515,
            price_sources: [],
            processed_ms: 1711659005561,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "46b6bdbe-440c-4bf7-80c9-19031f21f5d4",
            price: 0.6516,
            price_sources: [],
            processed_ms: 1711659058499,
          },
        ],
        position_type: "FLAT",
        position_uuid: "063e29c3-43de-495d-8ef7-fee02beef09a",
        return_at_close: 1.0389871036070524,
        trade_pair: ["AUDUSD", "AUD/USD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.65155,
        close_ms: 1711659530891,
        current_return: 1.0382933005908943,
        initial_entry_price: 0.65155,
        is_closed_position: true,
        miner_hotkey: "5FvmeMUskojSA2sVnAdEGxMajFn58f5A9qZ1B9ax6FTbpjXJ",
        net_leverage: 0.0,
        open_ms: 1711659463398,
        orders: [
          {
            leverage: 499.0,
            order_type: "LONG",
            order_uuid: "b2ca04aa-1c5f-409c-92b8-c73f84b37c9b",
            price: 0.65155,
            price_sources: [],
            processed_ms: 1711659463393,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "9c08ce3c-7693-44a1-be99-9e48d932040c",
            price: 0.6516,
            price_sources: [],
            processed_ms: 1711659530891,
          },
        ],
        position_type: "FLAT",
        position_uuid: "9c6c7e2e-1a65-4de5-b61c-fddeaa8caf9c",
        return_at_close: 1.0020257156012544,
        trade_pair: ["AUDUSD", "AUD/USD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.6516,
        close_ms: 1711659727503,
        current_return: 1.1531614487416273,
        initial_entry_price: 0.6516,
        is_closed_position: true,
        miner_hotkey: "5FvmeMUskojSA2sVnAdEGxMajFn58f5A9qZ1B9ax6FTbpjXJ",
        net_leverage: 0.0,
        open_ms: 1711659658523,
        orders: [
          {
            leverage: 499.0,
            order_type: "LONG",
            order_uuid: "90cef6e9-3655-4d8b-a72b-8e10cde0f659",
            price: 0.6516,
            price_sources: [],
            processed_ms: 1711659658518,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "255b759f-dabf-4a06-a0f4-1a48ceda2179",
            price: 0.6518,
            price_sources: [],
            processed_ms: 1711659727503,
          },
        ],
        position_type: "FLAT",
        position_uuid: "980f9d43-32f2-4fc8-b701-db693001b88b",
        return_at_close: 1.1128815193370822,
        trade_pair: ["AUDUSD", "AUD/USD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.6516,
        close_ms: 1711661344366,
        current_return: 0.9617096378146144,
        initial_entry_price: 0.6516,
        is_closed_position: true,
        miner_hotkey: "5FvmeMUskojSA2sVnAdEGxMajFn58f5A9qZ1B9ax6FTbpjXJ",
        net_leverage: 0.0,
        open_ms: 1711661251460,
        orders: [
          {
            leverage: -499.0,
            order_type: "SHORT",
            order_uuid: "be76d900-fbfe-407a-a0e4-5e517c8b8916",
            price: 0.6516,
            price_sources: [],
            processed_ms: 1711661251455,
          },
          {
            leverage: 499.0,
            order_type: "FLAT",
            order_uuid: "fdd29bfe-4ec7-40e0-95f9-ca82b50a5d0b",
            price: 0.65165,
            price_sources: [],
            processed_ms: 1711661344366,
          },
        ],
        position_type: "FLAT",
        position_uuid: "84f59aef-2712-466a-8847-de02901c5b52",
        return_at_close: 0.9281171201657499,
        trade_pair: ["AUDUSD", "AUD/USD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 1.079,
        close_ms: 1711662345669,
        current_return: 1.0,
        initial_entry_price: 1.079,
        is_closed_position: true,
        miner_hotkey: "5FvmeMUskojSA2sVnAdEGxMajFn58f5A9qZ1B9ax6FTbpjXJ",
        net_leverage: 0.0,
        open_ms: 1711661924088,
        orders: [
          {
            leverage: 499.0,
            order_type: "LONG",
            order_uuid: "1e28329d-0f81-4a25-8771-deede7377e9d",
            price: 1.079,
            price_sources: [],
            processed_ms: 1711661924084,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "fb4f1064-2050-46ad-9fb8-4d1984c3d8c5",
            price: 1.079,
            price_sources: [],
            processed_ms: 1711662345669,
          },
        ],
        position_type: "FLAT",
        position_uuid: "e1494df1-34a4-4eae-8add-14bfd9e9391f",
        return_at_close: 0.96507,
        trade_pair: ["EURUSD", "EUR/USD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 69399.0,
        close_ms: 1711736332741,
        current_return: 1.0032277122148734,
        initial_entry_price: 69399.0,
        is_closed_position: true,
        miner_hotkey: "5FvmeMUskojSA2sVnAdEGxMajFn58f5A9qZ1B9ax6FTbpjXJ",
        net_leverage: 0.0,
        open_ms: 1711733041148,
        orders: [
          {
            leverage: 2.0,
            order_type: "LONG",
            order_uuid: "35f1b652-f15a-4be8-8aa5-e5b546ac5b8d",
            price: 69399.0,
            price_sources: [],
            processed_ms: 1711733041143,
          },
          {
            leverage: 4.0,
            order_type: "FLAT",
            order_uuid: "216d0f7a-c083-444a-8909-168bf6795b47",
            price: 69511.0,
            price_sources: [],
            processed_ms: 1711736332741,
          },
        ],
        position_type: "FLAT",
        position_uuid: "9e7b0e73-9fb7-4362-b773-d224c3325664",
        return_at_close: 0.9992148013660139,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 0.6485150000000001,
        close_ms: 1711992405662,
        current_return: 0.9059237211023548,
        initial_entry_price: 0.64841,
        is_closed_position: true,
        miner_hotkey: "5FvmeMUskojSA2sVnAdEGxMajFn58f5A9qZ1B9ax6FTbpjXJ",
        net_leverage: 200.0,
        open_ms: 1711991166055,
        orders: [
          {
            leverage: 100.0,
            order_type: "LONG",
            order_uuid: "d5ff3966-227f-400a-9669-84e7fc79b9a7",
            price: 0.64841,
            price_sources: [],
            processed_ms: 1711991166050,
          },
          {
            leverage: 100.0,
            order_type: "LONG",
            order_uuid: "cd265c36-99e9-4232-8a41-2ad321903ba2",
            price: 0.64862,
            price_sources: [],
            processed_ms: 1711991469492,
          },
        ],
        position_type: "FLAT",
        position_uuid: "cdc15a79-254d-473d-9ae6-6a1d88d914c5",
        return_at_close: 0.8932407890069217,
        trade_pair: ["AUDUSD", "AUD/USD", 7e-5, 0.001, 500],
      },
    ],
    thirty_day_returns: 1.0,
  },
  "5FvsLcoZAXwKB3QRFmKdpL6Cp3tqkfNpSdWA7ubMZFCaPtbZ": {
    all_time_returns: 1.0141366074722844,
    n_positions: 4,
    percentage_profitable: 1.0,
    positions: [
      {
        average_entry_price: 60828.58000000001,
        close_ms: 1719331611435,
        current_return: 1.001107083298673,
        initial_entry_price: 60828.58,
        is_closed_position: true,
        miner_hotkey: "5FvsLcoZAXwKB3QRFmKdpL6Cp3tqkfNpSdWA7ubMZFCaPtbZ",
        net_leverage: 0.0,
        open_ms: 1719306324925,
        orders: [
          {
            leverage: 0.0705,
            order_type: "LONG",
            order_uuid: "9ab00316-b361-46f6-b781-c010c6de24f7",
            price: 60828.58,
            price_sources: [],
            processed_ms: 1719306324865,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "437d6142-873c-4ba1-a333-e5a43484857a",
            price: 61783.79,
            price_sources: [],
            processed_ms: 1719331611435,
          },
        ],
        position_type: "FLAT",
        position_uuid: "9ab00316-b361-46f6-b781-c010c6de24f7",
        return_at_close: 1.0010365052493004,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 55998.573855421695,
        close_ms: 1720280708843,
        current_return: 1.006351415235046,
        initial_entry_price: 60174.81,
        is_closed_position: true,
        miner_hotkey: "5FvsLcoZAXwKB3QRFmKdpL6Cp3tqkfNpSdWA7ubMZFCaPtbZ",
        net_leverage: 0.0,
        open_ms: 1720020078009,
        orders: [
          {
            leverage: 0.0705,
            order_type: "LONG",
            order_uuid: "27e144bd-2788-4279-8c68-614bf31740d4",
            price: 60174.81,
            price_sources: [],
            processed_ms: 1720020077672,
          },
          {
            leverage: 0.11,
            order_type: "LONG",
            order_uuid: "e3796213-c294-4bc1-8067-fef3740f1815",
            price: 54651.4,
            price_sources: [
              {
                close: 54651.4,
                high: 54651.6,
                lag_ms: 432,
                low: 54651.4,
                open: 54651.4,
                source: "Polygon_ws",
                start_ms: 1720159087999,
                timespan_ms: 0,
                volume: 0.02207729,
                vwap: 54651.5633,
                websocket: true,
              },
              {
                close: 54618.4,
                high: 54618.4,
                lag_ms: 567,
                low: 54618.4,
                open: 54618.4,
                source: "TwelveData_ws",
                start_ms: 1720159087000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1720159087567,
          },
          {
            leverage: 0.11,
            order_type: "LONG",
            order_uuid: "2408036f-eeb7-485f-908f-6a2880b5ba2d",
            price: 54669.16,
            price_sources: [
              {
                close: 54669.16,
                high: 54673.8,
                lag_ms: 108,
                low: 54662.1,
                open: 54669.16,
                source: "Polygon_ws",
                start_ms: 1720159105999,
                timespan_ms: 0,
                volume: 0.04174704,
                vwap: 54670.5038,
                websocket: true,
              },
              {
                close: 54630.3,
                high: 54630.3,
                lag_ms: 1109,
                low: 54630.3,
                open: 54630.3,
                source: "TwelveData_ws",
                start_ms: 1720159107000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
              {
                close: 54668.44,
                high: 54668.44,
                lag_ms: 2892,
                low: 54661.29,
                open: 54661.29,
                source: "Polygon_rest",
                start_ms: 1720159102000,
                timespan_ms: 1000,
                volume: 0.45018087,
                vwap: 54664.0753,
                websocket: false,
              },
              {
                close: 54616.02,
                high: 54669.41,
                lag_ms: 25892,
                low: 54610.16,
                open: 54665.3,
                source: "TwelveData_rest",
                start_ms: 1720159020000,
                timespan_ms: 60000,
                volume: null,
                vwap: null,
                websocket: false,
              },
            ],
            processed_ms: 1720159105891,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "329c9d04-fc0b-460c-968e-f87f8364edb4",
            price: 57314.22,
            price_sources: [
              {
                close: 57314.22,
                high: 57320.56,
                lag_ms: 156,
                low: 57313.94,
                open: 57314.22,
                source: "Polygon_ws",
                start_ms: 1720280708999,
                timespan_ms: 0,
                volume: 0.03381986,
                vwap: 57314.0493,
                websocket: true,
              },
              {
                close: 57359.8,
                high: 57359.8,
                lag_ms: 2157,
                low: 57359.8,
                open: 57359.8,
                source: "TwelveData_ws",
                start_ms: 1720280711000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
              {
                close: 57313.95,
                high: 57328.3,
                lag_ms: 2844,
                low: 57313.94,
                open: 57313.95,
                source: "Polygon_rest",
                start_ms: 1720280705000,
                timespan_ms: 1000,
                volume: 0.08324668,
                vwap: 57325.33,
                websocket: false,
              },
              {
                close: 57311.09,
                high: 57335.88,
                lag_ms: 68844,
                low: 57275.01,
                open: 57305.96,
                source: "TwelveData_rest",
                start_ms: 1720280580000,
                timespan_ms: 60000,
                volume: null,
                vwap: null,
                websocket: false,
              },
            ],
            processed_ms: 1720280708843,
          },
        ],
        position_type: "FLAT",
        position_uuid: "27e144bd-2788-4279-8c68-614bf31740d4",
        return_at_close: 1.00605907014892,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 55062.15,
        close_ms: 1720420208778,
        current_return: 1.0002673287367094,
        initial_entry_price: 55062.15,
        is_closed_position: true,
        miner_hotkey: "5FvsLcoZAXwKB3QRFmKdpL6Cp3tqkfNpSdWA7ubMZFCaPtbZ",
        net_leverage: 0.0,
        open_ms: 1720400411975,
        orders: [
          {
            leverage: 0.0235,
            order_type: "LONG",
            order_uuid: "afc12ae4-caeb-40f7-bebb-a45029184293",
            price: 55062.15,
            price_sources: [
              {
                close: 55062.15,
                high: 55063.32,
                lag_ms: 374,
                low: 55054.95,
                open: 55062.15,
                source: "Polygon_ws",
                start_ms: 1720400410999,
                timespan_ms: 0,
                volume: 0.49561629,
                vwap: 55058.1325,
                websocket: true,
              },
              {
                close: 55087.1,
                high: 55087.1,
                lag_ms: 2625,
                low: 55087.1,
                open: 55087.1,
                source: "TwelveData_ws",
                start_ms: 1720400408000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
              {
                close: 55280.0,
                high: 55280.0,
                lag_ms: 2626,
                low: 55065.68,
                open: 55080.56,
                source: "Polygon_rest",
                start_ms: 1720400407000,
                timespan_ms: 1000,
                volume: 0.47268454,
                vwap: 55072.2577,
                websocket: false,
              },
              {
                close: 55084.0,
                high: 55162.13,
                lag_ms: 70626,
                low: 55061.26,
                open: 55160.48,
                source: "TwelveData_rest",
                start_ms: 1720400280000,
                timespan_ms: 60000,
                volume: null,
                vwap: null,
                websocket: false,
              },
            ],
            processed_ms: 1720400410625,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "a258c13a-1c8d-4b74-8521-4a5b573de7cc",
            price: 55688.52,
            price_sources: [
              {
                close: 55688.52,
                high: 55691.85,
                lag_ms: 221,
                low: 55684.4,
                open: 55688.52,
                source: "Polygon_ws",
                start_ms: 1720420208999,
                timespan_ms: 0,
                volume: 0.05426367,
                vwap: 55689.3076,
                websocket: true,
              },
              {
                close: 55923.0,
                high: 55923.0,
                lag_ms: 2779,
                low: 55700.36,
                open: 55714.0,
                source: "Polygon_rest",
                start_ms: 1720420205000,
                timespan_ms: 1000,
                volume: 0.35055695,
                vwap: 55709.8395,
                websocket: false,
              },
              {
                close: 55696.5,
                high: 55696.5,
                lag_ms: 6222,
                low: 55696.5,
                open: 55696.5,
                source: "TwelveData_ws",
                start_ms: 1720420215000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
              {
                close: 55702.92,
                high: 55737.34,
                lag_ms: 68779,
                low: 55689.97,
                open: 55736.46,
                source: "TwelveData_rest",
                start_ms: 1720420080000,
                timespan_ms: 60000,
                volume: null,
                vwap: null,
                websocket: false,
              },
            ],
            processed_ms: 1720420208778,
          },
        ],
        position_type: "FLAT",
        position_uuid: "afc12ae4-caeb-40f7-bebb-a45029184293",
        return_at_close: 1.000243822454484,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 3062.17,
        close_ms: 1720628106142,
        current_return: 1.0069881025547243,
        initial_entry_price: 3062.17,
        is_closed_position: true,
        miner_hotkey: "5FvsLcoZAXwKB3QRFmKdpL6Cp3tqkfNpSdWA7ubMZFCaPtbZ",
        net_leverage: 0.0,
        open_ms: 1720297224228,
        orders: [
          {
            leverage: 0.2467,
            order_type: "LONG",
            order_uuid: "af430d9f-5096-45be-83b6-b85670673a23",
            price: 3062.17,
            price_sources: [
              {
                close: 3062.17,
                high: 3062.17,
                lag_ms: 719,
                low: 3062.09,
                open: 3062.17,
                source: "Polygon_ws",
                start_ms: 1720297221999,
                timespan_ms: 0,
                volume: 1.5862585,
                vwap: 3062.1031,
                websocket: true,
              },
              {
                close: 3061.47,
                high: 3061.47,
                lag_ms: 2282,
                low: 3061.47,
                open: 3061.47,
                source: "TwelveData_ws",
                start_ms: 1720297225000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
              {
                close: 3062.08,
                high: 3062.08,
                lag_ms: 2719,
                low: 3062.08,
                open: 3062.08,
                source: "Polygon_rest",
                start_ms: 1720297219000,
                timespan_ms: 1000,
                volume: 0.00046355,
                vwap: 3062.08,
                websocket: false,
              },
              {
                close: 3062.06006,
                high: 3062.20996,
                lag_ms: 802719,
                low: 3061.95996,
                open: 3061.95996,
                source: "TwelveData_rest",
                start_ms: 1720296360000,
                timespan_ms: 60000,
                volume: null,
                vwap: null,
                websocket: false,
              },
            ],
            processed_ms: 1720297222718,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "4039592d-b0b3-4f75-bf0d-8dee57c41a3e",
            price: 3148.91,
            price_sources: [
              {
                close: 3148.91,
                high: 3149.15,
                lag_ms: 142,
                low: 3148.91,
                open: 3148.91,
                source: "Polygon_ws",
                start_ms: 1720628106000,
                timespan_ms: 0,
                volume: 2.57729709,
                vwap: 3149.0553,
                websocket: true,
              },
              {
                close: 3149.2,
                high: 3149.2,
                lag_ms: 1142,
                low: 3149.2,
                open: 3149.2,
                source: "TwelveData_ws",
                start_ms: 1720628105000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1720628106142,
          },
        ],
        position_type: "FLAT",
        position_uuid: "af430d9f-5096-45be-83b6-b85670673a23",
        return_at_close: 1.006739678589824,
        trade_pair: ["ETHUSD", "ETH/USD", 0.003, 0.001, 20],
      },
    ],
    thirty_day_returns: 1.0141366074722844,
  },
  "5Fy6BohhPZCdFhs8QEcdvd2EYXo6t4NUY2grX3f2PYZCYaqT": {
    all_time_returns: 1.0,
    n_positions: 0,
    percentage_profitable: 0.0,
    positions: [
      {
        average_entry_price: 65348.049999999996,
        close_ms: 1711222761085,
        current_return: 0.896478165760112,
        initial_entry_price: 65348.05,
        is_closed_position: true,
        miner_hotkey: "5Fy6BohhPZCdFhs8QEcdvd2EYXo6t4NUY2grX3f2PYZCYaqT",
        net_leverage: 19.0,
        open_ms: 1711219728863,
        orders: [
          {
            leverage: 19.0,
            order_type: "LONG",
            order_uuid: "a87c918c-32b8-40ea-ac86-59bc31195d79",
            price: 65348.05,
            price_sources: [],
            processed_ms: 1711219728862,
          },
        ],
        position_type: "FLAT",
        position_uuid: "868ee6b7-3bd4-47e0-b4fa-00cdf085d492",
        return_at_close: 0.8624119954612277,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
    ],
    thirty_day_returns: 1.0,
  },
  "5FyA9w1gckwsoc8ter8JS3k5JGmNb8bdaoRELzgKKCdhFi6D": {
    all_time_returns: 1.0,
    n_positions: 0,
    percentage_profitable: 0.0,
    positions: [
      {
        average_entry_price: 64499.91000000001,
        close_ms: 1711198310528,
        current_return: 1.0029035389351706,
        initial_entry_price: 64499.91,
        is_closed_position: true,
        miner_hotkey: "5FyA9w1gckwsoc8ter8JS3k5JGmNb8bdaoRELzgKKCdhFi6D",
        net_leverage: 0.0,
        open_ms: 1711194739482,
        orders: [
          {
            leverage: 0.6,
            order_type: "LONG",
            order_uuid: "ca95dc94-2eb5-4e8c-bfb4-c4833badb6a9",
            price: 64499.91,
            price_sources: [],
            processed_ms: 1711194739482,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "8002e9bd-2172-4d23-8239-4049dd2cde17",
            price: 64812.04,
            price_sources: [],
            processed_ms: 1711198310528,
          },
        ],
        position_type: "FLAT",
        position_uuid: "caecd569-8905-45e1-b8e2-434361b9c6f1",
        return_at_close: 1.0017000546884485,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 64891.55999999999,
        close_ms: 1711201992909,
        current_return: 0.9977733313854684,
        initial_entry_price: 64891.56,
        is_closed_position: true,
        miner_hotkey: "5FyA9w1gckwsoc8ter8JS3k5JGmNb8bdaoRELzgKKCdhFi6D",
        net_leverage: 0.0,
        open_ms: 1711198397481,
        orders: [
          {
            leverage: 0.6,
            order_type: "LONG",
            order_uuid: "df574275-3c03-47e3-82b2-31938b112dcc",
            price: 64891.56,
            price_sources: [],
            processed_ms: 1711198397481,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "1ec40153-70a9-4492-8c7e-6040006583b9",
            price: 64650.74,
            price_sources: [],
            processed_ms: 1711201992909,
          },
        ],
        position_type: "FLAT",
        position_uuid: "8420e535-b822-4713-a657-d851c9bf914d",
        return_at_close: 0.9965760033878058,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 64632.43,
        close_ms: 1711205652786,
        current_return: 1.0061079244583562,
        initial_entry_price: 64632.43,
        is_closed_position: true,
        miner_hotkey: "5FyA9w1gckwsoc8ter8JS3k5JGmNb8bdaoRELzgKKCdhFi6D",
        net_leverage: 0.0,
        open_ms: 1711202079534,
        orders: [
          {
            leverage: 0.6,
            order_type: "LONG",
            order_uuid: "6d6c7015-89dc-40ed-b48d-a5464c0895b0",
            price: 64632.43,
            price_sources: [],
            processed_ms: 1711202079533,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "5a4e4ec3-b5fb-4914-b47b-4e3e819a054e",
            price: 65290.38,
            price_sources: [],
            processed_ms: 1711205652786,
          },
        ],
        position_type: "FLAT",
        position_uuid: "36cb68c5-4e32-4c34-9f96-441386ef83e8",
        return_at_close: 1.0049005949490062,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 65273.35999999999,
        close_ms: 1711209335248,
        current_return: 0.9984489231135031,
        initial_entry_price: 65273.36,
        is_closed_position: true,
        miner_hotkey: "5FyA9w1gckwsoc8ter8JS3k5JGmNb8bdaoRELzgKKCdhFi6D",
        net_leverage: 0.0,
        open_ms: 1711205755654,
        orders: [
          {
            leverage: 0.6,
            order_type: "LONG",
            order_uuid: "23420271-ddb6-4c39-aa99-87a0a0a8c73c",
            price: 65273.36,
            price_sources: [],
            processed_ms: 1711205755653,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "c2018ee6-748a-44db-918e-f3dc3bce258e",
            price: 65104.62,
            price_sources: [],
            processed_ms: 1711209335248,
          },
        ],
        position_type: "FLAT",
        position_uuid: "b91fe132-bba6-4487-8a20-4b0e666d2cab",
        return_at_close: 0.9972507844057669,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 65104.619999999995,
        close_ms: 1711212954932,
        current_return: 1.0042384088871112,
        initial_entry_price: 65104.62,
        is_closed_position: true,
        miner_hotkey: "5FyA9w1gckwsoc8ter8JS3k5JGmNb8bdaoRELzgKKCdhFi6D",
        net_leverage: 0.0,
        open_ms: 1711209354791,
        orders: [
          {
            leverage: 0.6,
            order_type: "LONG",
            order_uuid: "bb0d8394-e6dd-4461-a804-45b7f19e9009",
            price: 65104.62,
            price_sources: [],
            processed_ms: 1711209354790,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "1c9317bb-17a7-4f4d-9c04-617802b2bbd3",
            price: 65564.52,
            price_sources: [],
            processed_ms: 1711212954932,
          },
        ],
        position_type: "FLAT",
        position_uuid: "84b2c1a3-41d3-438f-becc-f464199d5831",
        return_at_close: 1.0030333227964467,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 65734.55,
        close_ms: 1711216616924,
        current_return: 0.9991726725139215,
        initial_entry_price: 65734.55,
        is_closed_position: true,
        miner_hotkey: "5FyA9w1gckwsoc8ter8JS3k5JGmNb8bdaoRELzgKKCdhFi6D",
        net_leverage: 0.0,
        open_ms: 1711213081112,
        orders: [
          {
            leverage: 0.6,
            order_type: "LONG",
            order_uuid: "468219f6-1e5d-4f43-a6c2-02ad157aa558",
            price: 65734.55,
            price_sources: [],
            processed_ms: 1711213081111,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "acc4f446-0d81-4738-8f61-a89715500a2c",
            price: 65643.91,
            price_sources: [],
            processed_ms: 1711216616924,
          },
        ],
        position_type: "FLAT",
        position_uuid: "9ed97395-d19c-41e9-98a2-d2784f4f329d",
        return_at_close: 0.9979736653069048,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 65653.65,
        close_ms: 1711220318798,
        current_return: 0.997616492000064,
        initial_entry_price: 65653.65,
        is_closed_position: true,
        miner_hotkey: "5FyA9w1gckwsoc8ter8JS3k5JGmNb8bdaoRELzgKKCdhFi6D",
        net_leverage: 0.0,
        open_ms: 1711216704055,
        orders: [
          {
            leverage: 0.6,
            order_type: "LONG",
            order_uuid: "d752ac77-68a1-42cb-aa08-3860dfa283f2",
            price: 65653.65,
            price_sources: [],
            processed_ms: 1711216704053,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "b4d5982d-c027-44a1-a6c6-75fdc73b8fd7",
            price: 65392.84,
            price_sources: [],
            processed_ms: 1711220318798,
          },
        ],
        position_type: "FLAT",
        position_uuid: "3efb3c66-a2c8-447d-a186-164faba20482",
        return_at_close: 0.9964193522096639,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 65392.84,
        close_ms: 1711223945323,
        current_return: 0.9956059409562271,
        initial_entry_price: 65392.84,
        is_closed_position: true,
        miner_hotkey: "5FyA9w1gckwsoc8ter8JS3k5JGmNb8bdaoRELzgKKCdhFi6D",
        net_leverage: 0.0,
        open_ms: 1711220338297,
        orders: [
          {
            leverage: 0.6,
            order_type: "LONG",
            order_uuid: "af28d915-cb3f-4241-acc8-e8b34080d0fe",
            price: 65392.84,
            price_sources: [],
            processed_ms: 1711220338297,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "f98eb735-3e63-4908-88d0-22d3faae6cf4",
            price: 64913.94,
            price_sources: [],
            processed_ms: 1711223945323,
          },
        ],
        position_type: "FLAT",
        position_uuid: "69a5731c-4119-4a44-9af6-ecfd6b64b7e5",
        return_at_close: 0.9944112138270796,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 64947.37,
        close_ms: 1711227607858,
        current_return: 1.0007608622181314,
        initial_entry_price: 64947.37,
        is_closed_position: true,
        miner_hotkey: "5FyA9w1gckwsoc8ter8JS3k5JGmNb8bdaoRELzgKKCdhFi6D",
        net_leverage: 0.0,
        open_ms: 1711224073142,
        orders: [
          {
            leverage: 0.6,
            order_type: "LONG",
            order_uuid: "b912a217-61c1-4897-8d57-0ebaeb1f40c1",
            price: 64947.37,
            price_sources: [],
            processed_ms: 1711224073141,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "6fdb614c-ec6d-4fbd-9c79-6198960dfb50",
            price: 65029.73,
            price_sources: [],
            processed_ms: 1711227607858,
          },
        ],
        position_type: "FLAT",
        position_uuid: "a0277a10-2f56-4d97-b077-fac339675de7",
        return_at_close: 0.9995599491834697,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 64996.92999999999,
        close_ms: 1711231268424,
        current_return: 0.9994685595150418,
        initial_entry_price: 64996.93,
        is_closed_position: true,
        miner_hotkey: "5FyA9w1gckwsoc8ter8JS3k5JGmNb8bdaoRELzgKKCdhFi6D",
        net_leverage: 0.0,
        open_ms: 1711227694621,
        orders: [
          {
            leverage: 0.6,
            order_type: "LONG",
            order_uuid: "e74941ab-befb-4263-9dc2-a7fc3ee89a18",
            price: 64996.93,
            price_sources: [],
            processed_ms: 1711227694620,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "dbe1a400-2bd3-4cfa-a228-7fad712bc63b",
            price: 64939.36,
            price_sources: [],
            processed_ms: 1711231268424,
          },
        ],
        position_type: "FLAT",
        position_uuid: "17d9d5d7-9b25-4841-bfc5-b8e621c0349b",
        return_at_close: 0.9982691972436237,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 64992.24,
        close_ms: 1711234948642,
        current_return: 0.997662120893202,
        initial_entry_price: 64992.24,
        is_closed_position: true,
        miner_hotkey: "5FyA9w1gckwsoc8ter8JS3k5JGmNb8bdaoRELzgKKCdhFi6D",
        net_leverage: 0.0,
        open_ms: 1711231355559,
        orders: [
          {
            leverage: 0.6,
            order_type: "LONG",
            order_uuid: "29be2f63-bdce-4e85-94a5-1d65d8dd3b9b",
            price: 64992.24,
            price_sources: [],
            processed_ms: 1711231355558,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "90f40684-cd83-406b-a2ad-2b5e29d8464c",
            price: 64739.0,
            price_sources: [],
            processed_ms: 1711234948642,
          },
        ],
        position_type: "FLAT",
        position_uuid: "a768fbb2-179f-4ec4-9352-27727305ba3b",
        return_at_close: 0.9964649263481302,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 64760.75,
        close_ms: 1711238597932,
        current_return: 0.9943513007492965,
        initial_entry_price: 64760.75,
        is_closed_position: true,
        miner_hotkey: "5FyA9w1gckwsoc8ter8JS3k5JGmNb8bdaoRELzgKKCdhFi6D",
        net_leverage: 0.0,
        open_ms: 1711235035790,
        orders: [
          {
            leverage: 0.6,
            order_type: "LONG",
            order_uuid: "9cca2487-5691-40fb-86d9-a81507b24730",
            price: 64760.75,
            price_sources: [],
            processed_ms: 1711235035789,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "d1f88c44-bf4e-4ccc-9c25-77df66f86c81",
            price: 64151.06,
            price_sources: [],
            processed_ms: 1711238597932,
          },
        ],
        position_type: "FLAT",
        position_uuid: "0f3558cf-9928-4e8a-a1a0-54c812bbfbe3",
        return_at_close: 0.9931580791883973,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 64108.76,
        close_ms: 1711242257830,
        current_return: 1.003578543712279,
        initial_entry_price: 64108.76,
        is_closed_position: true,
        miner_hotkey: "5FyA9w1gckwsoc8ter8JS3k5JGmNb8bdaoRELzgKKCdhFi6D",
        net_leverage: 0.0,
        open_ms: 1711238714262,
        orders: [
          {
            leverage: 0.6,
            order_type: "LONG",
            order_uuid: "5d1c0d24-90bd-4d91-b430-99e6476375c9",
            price: 64108.76,
            price_sources: [],
            processed_ms: 1711238714261,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "3ae86e13-df8f-4117-a58d-82bc6faeb989",
            price: 64491.12,
            price_sources: [],
            processed_ms: 1711242257830,
          },
        ],
        position_type: "FLAT",
        position_uuid: "d3258cfc-2e22-453a-8fbf-2d0d266ceb46",
        return_at_close: 1.0023742494598245,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 64472.09999999999,
        close_ms: 1711245931831,
        current_return: 0.9993934120340427,
        initial_entry_price: 64472.1,
        is_closed_position: true,
        miner_hotkey: "5FyA9w1gckwsoc8ter8JS3k5JGmNb8bdaoRELzgKKCdhFi6D",
        net_leverage: 0.0,
        open_ms: 1711242344689,
        orders: [
          {
            leverage: 0.6,
            order_type: "LONG",
            order_uuid: "3ab6bbd0-43a5-4499-8de0-1ae9e8d96930",
            price: 64472.1,
            price_sources: [],
            processed_ms: 1711242344685,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "be0628fd-ab75-49df-b4c8-970748f18dda",
            price: 64406.92,
            price_sources: [],
            processed_ms: 1711245931831,
          },
        ],
        position_type: "FLAT",
        position_uuid: "599f7ed9-19ac-4b95-9796-9f09e28695fb",
        return_at_close: 0.9981941399396019,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 64417.0,
        close_ms: 1711249585932,
        current_return: 0.9971514041324495,
        initial_entry_price: 64417.0,
        is_closed_position: true,
        miner_hotkey: "5FyA9w1gckwsoc8ter8JS3k5JGmNb8bdaoRELzgKKCdhFi6D",
        net_leverage: 0.0,
        open_ms: 1711246019904,
        orders: [
          {
            leverage: 0.6,
            order_type: "LONG",
            order_uuid: "17d4e64f-e1b0-46de-a906-1309f34c5afe",
            price: 64417.0,
            price_sources: [],
            processed_ms: 1711246019903,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "de95e0a7-d544-40b5-8b22-f478cd48091c",
            price: 64111.17,
            price_sources: [],
            processed_ms: 1711249585932,
          },
        ],
        position_type: "FLAT",
        position_uuid: "b8080572-28cd-47fc-a545-5c2fad83d6e0",
        return_at_close: 0.9959548224474906,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 64093.69999999999,
        close_ms: 1711253256189,
        current_return: 0.9998342114747628,
        initial_entry_price: 64093.7,
        is_closed_position: true,
        miner_hotkey: "5FyA9w1gckwsoc8ter8JS3k5JGmNb8bdaoRELzgKKCdhFi6D",
        net_leverage: 0.0,
        open_ms: 1711249718912,
        orders: [
          {
            leverage: 0.6,
            order_type: "LONG",
            order_uuid: "12ec370a-51bb-47ab-adce-68b5dfeda7b3",
            price: 64093.7,
            price_sources: [],
            processed_ms: 1711249718911,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "f8a44ccb-1fb4-45fa-aeb7-2137491fbaa8",
            price: 64075.99,
            price_sources: [],
            processed_ms: 1711253256189,
          },
        ],
        position_type: "FLAT",
        position_uuid: "18e7e7b7-8415-4189-87a2-8f297ffea7c0",
        return_at_close: 0.9986344104209931,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 64137.0,
        close_ms: 1711258780670,
        current_return: 1.0015737873614294,
        initial_entry_price: 64137.0,
        is_closed_position: true,
        miner_hotkey: "5FyA9w1gckwsoc8ter8JS3k5JGmNb8bdaoRELzgKKCdhFi6D",
        net_leverage: 0.0,
        open_ms: 1711253343256,
        orders: [
          {
            leverage: 0.6,
            order_type: "LONG",
            order_uuid: "c7034d76-2a50-4d75-9324-98a43a1aa542",
            price: 64137.0,
            price_sources: [],
            processed_ms: 1711253343255,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "b87acd2e-2854-4061-9444-c0b54a2a1ce2",
            price: 64305.23,
            price_sources: [],
            processed_ms: 1711258780670,
          },
        ],
        position_type: "FLAT",
        position_uuid: "95a1b8eb-2dab-4c87-9be8-93ae13f318fb",
        return_at_close: 1.0003718988165957,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 64317.0,
        close_ms: 1711262448713,
        current_return: 0.9982943856212199,
        initial_entry_price: 64317.0,
        is_closed_position: true,
        miner_hotkey: "5FyA9w1gckwsoc8ter8JS3k5JGmNb8bdaoRELzgKKCdhFi6D",
        net_leverage: 0.0,
        open_ms: 1711258868638,
        orders: [
          {
            leverage: 1.25,
            order_type: "LONG",
            order_uuid: "5d6c17ee-0fc6-41cc-82b4-1d71c8a882e3",
            price: 64317.0,
            price_sources: [],
            processed_ms: 1711258868637,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "4e6593ba-d604-42d5-bffa-10e5f305bcd4",
            price: 64229.24,
            price_sources: [],
            processed_ms: 1711262448713,
          },
        ],
        position_type: "FLAT",
        position_uuid: "1e817bbc-9e25-47f4-9c35-b6d28d3730ef",
        return_at_close: 0.9957986496571669,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 64180.73,
        close_ms: 1711266104893,
        current_return: 0.9985201944571213,
        initial_entry_price: 64180.73,
        is_closed_position: true,
        miner_hotkey: "5FyA9w1gckwsoc8ter8JS3k5JGmNb8bdaoRELzgKKCdhFi6D",
        net_leverage: 0.0,
        open_ms: 1711262535718,
        orders: [
          {
            leverage: 1.25,
            order_type: "LONG",
            order_uuid: "b5309adb-9522-4ec0-831a-e3e8c3d49308",
            price: 64180.73,
            price_sources: [],
            processed_ms: 1711262535716,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "eda7c2bc-d423-4346-beaf-36bdf2d3b80b",
            price: 64104.75,
            price_sources: [],
            processed_ms: 1711266104893,
          },
        ],
        position_type: "FLAT",
        position_uuid: "0613972a-f1fa-4242-b6e6-33961f4542f6",
        return_at_close: 0.9960238939709785,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 64324.58999999999,
        close_ms: 1711269763705,
        current_return: 1.0087794963014922,
        initial_entry_price: 64324.59,
        is_closed_position: true,
        miner_hotkey: "5FyA9w1gckwsoc8ter8JS3k5JGmNb8bdaoRELzgKKCdhFi6D",
        net_leverage: 0.0,
        open_ms: 1711266231703,
        orders: [
          {
            leverage: 1.25,
            order_type: "LONG",
            order_uuid: "8d4a143c-e1ce-48d5-86de-1095abcb8080",
            price: 64324.59,
            price_sources: [],
            processed_ms: 1711266231702,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "c41adab2-2563-4e2b-b591-5017305c59fb",
            price: 64776.38,
            price_sources: [],
            processed_ms: 1711269763705,
          },
        ],
        position_type: "FLAT",
        position_uuid: "420775f4-33ca-4a44-809f-2f00e1fa587e",
        return_at_close: 1.0062575475607385,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 64779.52,
        close_ms: 1711273425058,
        current_return: 1.0032002784213283,
        initial_entry_price: 64779.52,
        is_closed_position: true,
        miner_hotkey: "5FyA9w1gckwsoc8ter8JS3k5JGmNb8bdaoRELzgKKCdhFi6D",
        net_leverage: 0.0,
        open_ms: 1711269851029,
        orders: [
          {
            leverage: 1.25,
            order_type: "LONG",
            order_uuid: "ad4ef90e-4929-4c0d-b323-37247e90939b",
            price: 64779.52,
            price_sources: [],
            processed_ms: 1711269851028,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "bee6e5a5-aaf2-43d5-a1df-b097e836a07e",
            price: 64945.37,
            price_sources: [],
            processed_ms: 1711273425058,
          },
        ],
        position_type: "FLAT",
        position_uuid: "788e4c09-37db-42fe-81b6-c7ab074b8fe4",
        return_at_close: 1.0006922777252751,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 64928.61,
        close_ms: 1711277093823,
        current_return: 1.0047704317095345,
        initial_entry_price: 64928.61,
        is_closed_position: true,
        miner_hotkey: "5FyA9w1gckwsoc8ter8JS3k5JGmNb8bdaoRELzgKKCdhFi6D",
        net_leverage: 0.0,
        open_ms: 1711273552217,
        orders: [
          {
            leverage: 1.25,
            order_type: "LONG",
            order_uuid: "e2ecac6e-deff-4a41-9e94-2b7fe7979278",
            price: 64928.61,
            price_sources: [],
            processed_ms: 1711273552216,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "5cd480f0-3691-452d-9fdd-f69db5e10f9b",
            price: 65176.4,
            price_sources: [],
            processed_ms: 1711277093823,
          },
        ],
        position_type: "FLAT",
        position_uuid: "18846086-e6fa-41d0-b28f-b6521b0d873c",
        return_at_close: 1.0022585056302606,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 65212.869999999995,
        close_ms: 1711312436118,
        current_return: 1.0221304530191049,
        initial_entry_price: 65182.24,
        is_closed_position: true,
        miner_hotkey: "5FyA9w1gckwsoc8ter8JS3k5JGmNb8bdaoRELzgKKCdhFi6D",
        net_leverage: 0.0,
        open_ms: 1711277180600,
        orders: [
          {
            leverage: 1.25,
            order_type: "LONG",
            order_uuid: "6947cb9c-e6ff-4cd7-8436-94ec4e62ee40",
            price: 65182.24,
            price_sources: [],
            processed_ms: 1711277180598,
          },
          {
            leverage: 1.25,
            order_type: "LONG",
            order_uuid: "7480b9d2-bc90-49f2-8076-9446b0dc3802",
            price: 65696.48,
            price_sources: [],
            processed_ms: 1711291094852,
          },
          {
            leverage: -1.25,
            order_type: "SHORT",
            order_uuid: "f497942b-4a5f-4c5f-9a07-1857ef66d8e4",
            price: 65665.85,
            price_sources: [],
            processed_ms: 1711292926921,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "06660336-a984-4a79-9339-6bcaf7c0b31b",
            price: 66366.88,
            price_sources: [],
            processed_ms: 1711312436118,
          },
        ],
        position_type: "FLAT",
        position_uuid: "35d70feb-a104-40df-b504-74657bc655ac",
        return_at_close: 1.0170198007540094,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 66228.56,
        close_ms: 1711315223951,
        current_return: 1.003291065063169,
        initial_entry_price: 66228.56,
        is_closed_position: true,
        miner_hotkey: "5FyA9w1gckwsoc8ter8JS3k5JGmNb8bdaoRELzgKKCdhFi6D",
        net_leverage: 0.0,
        open_ms: 1711313330035,
        orders: [
          {
            leverage: 1.25,
            order_type: "LONG",
            order_uuid: "2491f203-7353-4096-8aa8-fd17e2cb8f1a",
            price: 66228.56,
            price_sources: [],
            processed_ms: 1711313330033,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "b5d726b5-d2ff-4674-ad8b-914a594f2fbf",
            price: 66402.93,
            price_sources: [],
            processed_ms: 1711315223951,
          },
        ],
        position_type: "FLAT",
        position_uuid: "2f54c5be-a25d-4805-9192-785be06c3299",
        return_at_close: 1.0007828374005112,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 66321.98,
        close_ms: 1711318948407,
        current_return: 1.0077040824173225,
        initial_entry_price: 66321.98,
        is_closed_position: true,
        miner_hotkey: "5FyA9w1gckwsoc8ter8JS3k5JGmNb8bdaoRELzgKKCdhFi6D",
        net_leverage: 0.0,
        open_ms: 1711316130880,
        orders: [
          {
            leverage: 1.25,
            order_type: "LONG",
            order_uuid: "eb8e41b5-7873-46e7-a460-3b29ceb1f67c",
            price: 66321.98,
            price_sources: [],
            processed_ms: 1711316130878,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "c9af07bd-9f37-419b-a1ed-8e611d39f569",
            price: 66730.74,
            price_sources: [],
            processed_ms: 1711318948407,
          },
        ],
        position_type: "FLAT",
        position_uuid: "c43e441f-e92e-41a7-9e37-0a04a47ae1c3",
        return_at_close: 1.0051848222112791,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 67899.29250000004,
        close_ms: 1711326368610,
        current_return: 1.0070105109499459,
        initial_entry_price: 67346.91,
        is_closed_position: true,
        miner_hotkey: "5FyA9w1gckwsoc8ter8JS3k5JGmNb8bdaoRELzgKKCdhFi6D",
        net_leverage: 0.0,
        open_ms: 1711322649848,
        orders: [
          {
            leverage: -1.25,
            order_type: "SHORT",
            order_uuid: "b1697813-22e3-4c6b-9f6e-4e4355b071b7",
            price: 67346.91,
            price_sources: [],
            processed_ms: 1711322649846,
          },
          {
            leverage: 0.125,
            order_type: "LONG",
            order_uuid: "dee699c4-2f34-4cbb-8dc6-31544c4399e3",
            price: 67254.43,
            price_sources: [],
            processed_ms: 1711323583923,
          },
          {
            leverage: 0.125,
            order_type: "LONG",
            order_uuid: "b5c7d212-d7ac-45f5-8847-5aba5971d900",
            price: 67253.02,
            price_sources: [],
            processed_ms: 1711324514899,
          },
          {
            leverage: 0.5,
            order_type: "LONG",
            order_uuid: "5d43ec13-ddbd-4cfa-ab4d-3190aee21925",
            price: 66841.12,
            price_sources: [],
            processed_ms: 1711325449889,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "f7615a20-a43c-43ff-aba2-83382866843e",
            price: 66955.02,
            price_sources: [],
            processed_ms: 1711326368610,
          },
        ],
        position_type: "FLAT",
        position_uuid: "ac942c26-3a76-4550-8d2a-5e0a417786c1",
        return_at_close: 1.004492984672571,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 66657.56,
        close_ms: 1711329171900,
        current_return: 1.0012125406330505,
        initial_entry_price: 66657.56,
        is_closed_position: true,
        miner_hotkey: "5FyA9w1gckwsoc8ter8JS3k5JGmNb8bdaoRELzgKKCdhFi6D",
        net_leverage: 0.0,
        open_ms: 1711327295871,
        orders: [
          {
            leverage: 1.25,
            order_type: "LONG",
            order_uuid: "6b87565b-5884-4a33-a36e-797b13aa38bf",
            price: 66657.56,
            price_sources: [],
            processed_ms: 1711327295869,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "3efd43ca-c110-41fb-9066-01c8b2c986fd",
            price: 66722.22,
            price_sources: [],
            processed_ms: 1711329171900,
          },
        ],
        position_type: "FLAT",
        position_uuid: "28f08198-0e20-4197-8d8f-276c955e9abf",
        return_at_close: 0.998709509281468,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 66705.96,
        close_ms: 1711334085784,
        current_return: 1.0003575392663562,
        initial_entry_price: 66705.96,
        is_closed_position: true,
        miner_hotkey: "5FyA9w1gckwsoc8ter8JS3k5JGmNb8bdaoRELzgKKCdhFi6D",
        net_leverage: 0.0,
        open_ms: 1711330425885,
        orders: [
          {
            leverage: 1.25,
            order_type: "LONG",
            order_uuid: "8e14eb00-dfe9-4a3a-be8a-53b72211ed15",
            price: 66705.96,
            price_sources: [],
            processed_ms: 1711330425882,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "53550fdf-1bea-447d-8d62-e84d115f0a79",
            price: 66725.04,
            price_sources: [],
            processed_ms: 1711334085784,
          },
        ],
        position_type: "FLAT",
        position_uuid: "f8a1a9be-261e-4981-aa4e-700d3133abc7",
        return_at_close: 0.9978566454181903,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 58201.06857143104,
        close_ms: 1711356083076,
        current_return: 0.9994185127712409,
        initial_entry_price: 67204.12,
        is_closed_position: true,
        miner_hotkey: "5FyA9w1gckwsoc8ter8JS3k5JGmNb8bdaoRELzgKKCdhFi6D",
        net_leverage: 0.0,
        open_ms: 1711339581906,
        orders: [
          {
            leverage: -1.25,
            order_type: "SHORT",
            order_uuid: "c89ed875-229b-4630-a9d5-264e3e5a51c7",
            price: 67204.12,
            price_sources: [],
            processed_ms: 1711339581905,
          },
          {
            leverage: 0.125,
            order_type: "LONG",
            order_uuid: "3b391204-441e-4151-85fd-02930e86e8d6",
            price: 67299.7,
            price_sources: [],
            processed_ms: 1711341415093,
          },
          {
            leverage: 0.125,
            order_type: "LONG",
            order_uuid: "0ae7c8be-5f3a-4b31-b27d-78c825f590a8",
            price: 67500.46,
            price_sources: [],
            processed_ms: 1711343258229,
          },
          {
            leverage: 0.125,
            order_type: "LONG",
            order_uuid: "1f63b10d-8731-44c0-8825-3482a03fba9e",
            price: 67643.26,
            price_sources: [],
            processed_ms: 1711345086901,
          },
          {
            leverage: 0.4375,
            order_type: "LONG",
            order_uuid: "d08a2852-e492-4dc9-9e52-674a12e805a3",
            price: 67234.31,
            price_sources: [],
            processed_ms: 1711346920695,
          },
          {
            leverage: 0.35,
            order_type: "LONG",
            order_uuid: "8a2ec9e0-0693-449a-8941-f82f4b8b3547",
            price: 66988.59,
            price_sources: [],
            processed_ms: 1711348761537,
          },
          {
            leverage: 0.04375000000000001,
            order_type: "LONG",
            order_uuid: "9b3690b3-d938-4588-a3a8-ec480ce19363",
            price: 67250.84,
            price_sources: [],
            processed_ms: 1711350581178,
          },
          {
            leverage: 0.021875000000000006,
            order_type: "LONG",
            order_uuid: "75b7997c-e229-48a6-aaea-24e6a7ea4086",
            price: 67038.0,
            price_sources: [],
            processed_ms: 1711352421012,
          },
          {
            leverage: 0.017500000000000005,
            order_type: "LONG",
            order_uuid: "3d32fd2e-3133-42ab-936a-c014755df20f",
            price: 67165.44,
            price_sources: [],
            processed_ms: 1711354259753,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "a4f58a9c-fb45-4142-a5a0-18bf41d5309d",
            price: 67133.26,
            price_sources: [],
            processed_ms: 1711356083076,
          },
        ],
        position_type: "FLAT",
        position_uuid: "ae42b212-5f33-4181-a572-b13c864d39de",
        return_at_close: 0.9969199664893129,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 66859.08,
        close_ms: 1711361585570,
        current_return: 1.0064583598817094,
        initial_entry_price: 66859.08,
        is_closed_position: true,
        miner_hotkey: "5FyA9w1gckwsoc8ter8JS3k5JGmNb8bdaoRELzgKKCdhFi6D",
        net_leverage: 0.0,
        open_ms: 1711357917049,
        orders: [
          {
            leverage: 1.25,
            order_type: "LONG",
            order_uuid: "1f209cd6-d95b-43ed-8089-2741a60dadb1",
            price: 66859.08,
            price_sources: [],
            processed_ms: 1711357917047,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "93166e24-386a-4672-a707-4ea7ecee704e",
            price: 67204.52,
            price_sources: [],
            processed_ms: 1711361585570,
          },
        ],
        position_type: "FLAT",
        position_uuid: "739286a4-409a-4a88-969e-da8f23d23357",
        return_at_close: 1.0039422139820051,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 59121.18277910863,
        close_ms: 1711396989153,
        current_return: 1.0363086753174096,
        initial_entry_price: 66894.92,
        is_closed_position: true,
        miner_hotkey: "5FyA9w1gckwsoc8ter8JS3k5JGmNb8bdaoRELzgKKCdhFi6D",
        net_leverage: 0.0,
        open_ms: 1711365265950,
        orders: [
          {
            leverage: 1.25,
            order_type: "LONG",
            order_uuid: "5887640d-e9f8-4548-94df-63d59b714628",
            price: 66894.92,
            price_sources: [],
            processed_ms: 1711365265947,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "9353becd-6bdc-4439-a245-bb7e03b384b9",
            price: 66880.43,
            price_sources: [],
            processed_ms: 1711367322036,
          },
          {
            leverage: -0.75,
            order_type: "SHORT",
            order_uuid: "1d8ff5cf-5370-4def-bca9-c2b55f0e807b",
            price: 67097.18,
            price_sources: [],
            processed_ms: 1711369171475,
          },
          {
            leverage: -0.375,
            order_type: "SHORT",
            order_uuid: "196067a8-01ca-430f-93d1-aff64b91a745",
            price: 67143.06,
            price_sources: [],
            processed_ms: 1711370992123,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "54bff029-846d-4573-945f-4984d165b331",
            price: 66898.93,
            price_sources: [],
            processed_ms: 1711372818129,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "a74a1165-869e-4d16-975d-dd3ce8b6b5db",
            price: 67236.63,
            price_sources: [],
            processed_ms: 1711374694787,
          },
          {
            leverage: -0.4375,
            order_type: "SHORT",
            order_uuid: "b16d4453-1e8a-4325-8539-79de19bdc472",
            price: 68868.54,
            price_sources: [],
            processed_ms: 1711376459151,
          },
          {
            leverage: -0.21875,
            order_type: "SHORT",
            order_uuid: "2657b657-500d-449f-bbcc-f32d2b041346",
            price: 69296.54,
            price_sources: [],
            processed_ms: 1711376788444,
          },
          {
            leverage: -0.175,
            order_type: "SHORT",
            order_uuid: "3eaf0560-d487-47d6-8e30-215aed5b21cb",
            price: 69095.56,
            price_sources: [],
            processed_ms: 1711377923624,
          },
          {
            leverage: -0.021875000000000006,
            order_type: "SHORT",
            order_uuid: "3fd5e1b2-3d9e-4e53-aabe-56766a380c25",
            price: 69061.84,
            price_sources: [],
            processed_ms: 1711378212544,
          },
          {
            leverage: -0.010937500000000003,
            order_type: "SHORT",
            order_uuid: "a19802a7-5362-4c38-b3a3-343a4aabf630",
            price: 69808.57,
            price_sources: [],
            processed_ms: 1711381526208,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "fc4dc05f-5057-4e65-a2d5-505caabf99dc",
            price: 69749.69,
            price_sources: [],
            processed_ms: 1711383380015,
          },
          {
            leverage: -0.13046875,
            order_type: "SHORT",
            order_uuid: "0e6434b7-3a3d-4ddd-b5a6-4a43353e60d9",
            price: 70129.37,
            price_sources: [],
            processed_ms: 1711385167073,
          },
          {
            leverage: -0.065234375,
            order_type: "SHORT",
            order_uuid: "920a0312-fef2-4644-82b9-e922431dddf1",
            price: 70574.27,
            price_sources: [],
            processed_ms: 1711386977041,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "712c3b51-1afa-4a97-99ea-75f46065c970",
            price: 70404.43,
            price_sources: [],
            processed_ms: 1711388814551,
          },
          {
            leverage: 0.24999999999999994,
            order_type: "LONG",
            order_uuid: "aa8611bd-4a8c-4538-94ed-a5817c6a6bb6",
            price: 70594.54,
            price_sources: [],
            processed_ms: 1711390611973,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "c8e0c483-c691-428a-9f9f-5eb63ab6397f",
            price: 70717.99,
            price_sources: [],
            processed_ms: 1711392430043,
          },
          {
            leverage: -0.4076171875,
            order_type: "SHORT",
            order_uuid: "45545941-88d6-4140-b02c-14fc56abee22",
            price: 70834.49,
            price_sources: [],
            processed_ms: 1711393347435,
          },
          {
            leverage: -0.20380859375,
            order_type: "SHORT",
            order_uuid: "a55365a5-1943-4a72-9807-50ba028fed57",
            price: 70688.24,
            price_sources: [],
            processed_ms: 1711395167092,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "d4883dde-4b3b-49d7-8049-4fe9dae0f6a7",
            price: 71038.57,
            price_sources: [],
            processed_ms: 1711396989153,
          },
        ],
        position_type: "FLAT",
        position_uuid: "04acc797-38f9-4628-99ed-ddb8ac3cff6b",
        return_at_close: 1.0331997492914573,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 67587.86500000015,
        close_ms: 1711420596554,
        current_return: 1.0228833291850223,
        initial_entry_price: 69948.17,
        is_closed_position: true,
        miner_hotkey: "5FyA9w1gckwsoc8ter8JS3k5JGmNb8bdaoRELzgKKCdhFi6D",
        net_leverage: 0.0,
        open_ms: 1711402435069,
        orders: [
          {
            leverage: 3.0,
            order_type: "LONG",
            order_uuid: "42cfdb65-9d63-4b20-8bc0-f4aeb9ac187e",
            price: 69948.17,
            price_sources: [],
            processed_ms: 1711402435066,
          },
          {
            leverage: 0.8999999999999999,
            order_type: "LONG",
            order_uuid: "0e820460-37e6-49e3-9606-ca421e967718",
            price: 70096.36,
            price_sources: [],
            processed_ms: 1711404283498,
          },
          {
            leverage: 0.8999999999999999,
            order_type: "LONG",
            order_uuid: "2bb3154b-ed76-4845-91ff-7462397aba90",
            price: 70415.95,
            price_sources: [],
            processed_ms: 1711406098266,
          },
          {
            leverage: -2.4,
            order_type: "SHORT",
            order_uuid: "5377b3c1-7acd-45d3-89b1-400aff67d4c5",
            price: 70309.85,
            price_sources: [],
            processed_ms: 1711407905578,
          },
          {
            leverage: 0.8999999999999999,
            order_type: "LONG",
            order_uuid: "5738e5ba-bfb6-4a81-8a01-f9b8d5574071",
            price: 70129.39,
            price_sources: [],
            processed_ms: 1711409703038,
          },
          {
            leverage: 0.8999999999999995,
            order_type: "LONG",
            order_uuid: "6a86cd30-b916-4ac8-8b6f-bae4114bfaf6",
            price: 69779.46,
            price_sources: [],
            processed_ms: 1711411518548,
          },
          {
            leverage: 0.9000000000000004,
            order_type: "LONG",
            order_uuid: "482315cc-e82b-4e40-add2-d4ee8be7d858",
            price: 70184.55,
            price_sources: [],
            processed_ms: 1711413331432,
          },
          {
            leverage: 0.9000000000000004,
            order_type: "LONG",
            order_uuid: "b2f3f97c-240d-4b2f-aca2-89311c62385c",
            price: 69961.36,
            price_sources: [],
            processed_ms: 1711415149982,
          },
          {
            leverage: -4.8,
            order_type: "SHORT",
            order_uuid: "0b3fbdcc-7323-46d7-a17f-3b04a0bfb81a",
            price: 70207.4,
            price_sources: [],
            processed_ms: 1711416970431,
          },
          {
            leverage: -0.6000000000000001,
            order_type: "SHORT",
            order_uuid: "464f0763-d211-4959-8ffe-5f57c09ed803",
            price: 70104.99,
            price_sources: [],
            processed_ms: 1711418786198,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "7103ba3d-8e1e-48f1-a607-c94618fed0f1",
            price: 70255.61,
            price_sources: [],
            processed_ms: 1711420596554,
          },
        ],
        position_type: "FLAT",
        position_uuid: "fde92dc0-7dfd-4582-bb10-232510c8d2b2",
        return_at_close: 1.010608729234802,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 68712.93999999993,
        close_ms: 1711429696329,
        current_return: 0.9924935230187739,
        initial_entry_price: 70368.43,
        is_closed_position: true,
        miner_hotkey: "5FyA9w1gckwsoc8ter8JS3k5JGmNb8bdaoRELzgKKCdhFi6D",
        net_leverage: 0.0,
        open_ms: 1711422429192,
        orders: [
          {
            leverage: -3.0,
            order_type: "SHORT",
            order_uuid: "b5ac70a6-9a62-4a9f-bd6d-41b04a46ccff",
            price: 70368.43,
            price_sources: [],
            processed_ms: 1711422429189,
          },
          {
            leverage: 0.8999999999999999,
            order_type: "LONG",
            order_uuid: "feab050c-0169-4543-8a1f-4abf3bc4fec6",
            price: 70386.2,
            price_sources: [],
            processed_ms: 1711424244539,
          },
          {
            leverage: 0.8999999999999999,
            order_type: "LONG",
            order_uuid: "6647c6b4-9460-4eef-b83c-dada65811cba",
            price: 70675.45,
            price_sources: [],
            processed_ms: 1711426068054,
          },
          {
            leverage: 0.8999999999999999,
            order_type: "LONG",
            order_uuid: "b1411ca8-0feb-4c10-8c30-6e2ef310e547",
            price: 70595.47,
            price_sources: [],
            processed_ms: 1711427908639,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "225b280d-f919-45e1-8c58-dbb99824cc34",
            price: 70473.67,
            price_sources: [],
            processed_ms: 1711429696329,
          },
        ],
        position_type: "FLAT",
        position_uuid: "207255d7-e186-49ad-a05f-00c5775f37ad",
        return_at_close: 0.9865385618806612,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 66658.06999999995,
        close_ms: 1711440612217,
        current_return: 0.9826577861019852,
        initial_entry_price: 70402.43,
        is_closed_position: true,
        miner_hotkey: "5FyA9w1gckwsoc8ter8JS3k5JGmNb8bdaoRELzgKKCdhFi6D",
        net_leverage: 0.0,
        open_ms: 1711433330100,
        orders: [
          {
            leverage: -3.0,
            order_type: "SHORT",
            order_uuid: "c1221c1d-a361-4fac-a458-2b110cb88690",
            price: 70402.43,
            price_sources: [],
            processed_ms: 1711433330098,
          },
          {
            leverage: 0.8999999999999999,
            order_type: "LONG",
            order_uuid: "61be6757-5a04-416c-a6dc-d30ce4837800",
            price: 70463.92,
            price_sources: [],
            processed_ms: 1711435157659,
          },
          {
            leverage: 0.8999999999999999,
            order_type: "LONG",
            order_uuid: "7a9c85b4-c12d-46d2-8267-6ac5c7212a7e",
            price: 70806.17,
            price_sources: [],
            processed_ms: 1711436971354,
          },
          {
            leverage: 0.8999999999999999,
            order_type: "LONG",
            order_uuid: "07e264b3-939c-45b4-bd71-a9f9f43751f3",
            price: 71185.32,
            price_sources: [],
            processed_ms: 1711438793199,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "a11a2170-02ba-4a87-a50a-5962479ea358",
            price: 70727.85,
            price_sources: [],
            processed_ms: 1711440612217,
          },
        ],
        position_type: "FLAT",
        position_uuid: "c498567c-0183-4eda-868d-f9811cba9111",
        return_at_close: 0.9767618393853733,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 70463.26464088398,
        close_ms: 1711463186302,
        current_return: 0.9442694090457965,
        initial_entry_price: 70521.37,
        is_closed_position: true,
        miner_hotkey: "5FyA9w1gckwsoc8ter8JS3k5JGmNb8bdaoRELzgKKCdhFi6D",
        net_leverage: 0.0,
        open_ms: 1711442423637,
        orders: [
          {
            leverage: 3.0,
            order_type: "LONG",
            order_uuid: "10c9ef79-d361-423a-9db3-f42dfc74cca5",
            price: 70521.37,
            price_sources: [],
            processed_ms: 1711442423634,
          },
          {
            leverage: 0.8999999999999999,
            order_type: "LONG",
            order_uuid: "24721eeb-a8d3-4ecb-bfe6-1198d39f225d",
            price: 70674.64,
            price_sources: [],
            processed_ms: 1711444238308,
          },
          {
            leverage: -1.95,
            order_type: "SHORT",
            order_uuid: "bc39e2f0-598b-450f-944e-527b3c499236",
            price: 70989.82,
            price_sources: [],
            processed_ms: 1711446059547,
          },
          {
            leverage: -0.975,
            order_type: "SHORT",
            order_uuid: "77228970-2a19-4ce8-9837-3f3418665da2",
            price: 71096.18,
            price_sources: [],
            processed_ms: 1711447871401,
          },
          {
            leverage: -0.4875,
            order_type: "SHORT",
            order_uuid: "0ce201b7-b961-4ba3-818c-b64c13cf0100",
            price: 70950.02,
            price_sources: [],
            processed_ms: 1711449686594,
          },
          {
            leverage: 0.8999999999999999,
            order_type: "LONG",
            order_uuid: "25211a5a-6079-4928-94b8-59bb53fdc306",
            price: 70789.99,
            price_sources: [],
            processed_ms: 1711451505230,
          },
          {
            leverage: 0.8999999999999997,
            order_type: "LONG",
            order_uuid: "5062ee89-eee6-4d4e-94a3-40ba4fc3948c",
            price: 70694.91,
            price_sources: [],
            processed_ms: 1711453319160,
          },
          {
            leverage: 0.8999999999999999,
            order_type: "LONG",
            order_uuid: "cd6dc74a-99a0-4f0f-b3e7-3229637fdbc4",
            price: 70699.86,
            price_sources: [],
            processed_ms: 1711455135906,
          },
          {
            leverage: 0.8999999999999999,
            order_type: "LONG",
            order_uuid: "dadffde3-6683-4447-96cc-2fb4ad88a0c1",
            price: 71074.3,
            price_sources: [],
            processed_ms: 1711456953066,
          },
          {
            leverage: 0.8999999999999995,
            order_type: "LONG",
            order_uuid: "606ebeed-065f-48a1-b5c4-35b9802eaf0f",
            price: 70909.08,
            price_sources: [],
            processed_ms: 1711458775472,
          },
          {
            leverage: 0.9000000000000004,
            order_type: "LONG",
            order_uuid: "36085740-952c-492d-8aa4-e659892804cd",
            price: 70353.17,
            price_sources: [],
            processed_ms: 1711460588946,
          },
          {
            leverage: 0.9000000000000004,
            order_type: "LONG",
            order_uuid: "4168e989-2ac6-4025-8fa9-ba4f7eab56b1",
            price: 70406.67,
            price_sources: [],
            processed_ms: 1711462419249,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "25dfc558-b70e-42a4-9f15-a7a0b7c529a3",
            price: 69884.23,
            price_sources: [],
            processed_ms: 1711463186302,
          },
        ],
        position_type: "FLAT",
        position_uuid: "3e2517fb-8cc9-433b-882a-891ab305b42c",
        return_at_close: 0.9314509518179998,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 69922.78,
        close_ms: 1711471631028,
        current_return: 0.9687546442650412,
        initial_entry_price: 69871.12,
        is_closed_position: true,
        miner_hotkey: "5FyA9w1gckwsoc8ter8JS3k5JGmNb8bdaoRELzgKKCdhFi6D",
        net_leverage: 0.0,
        open_ms: 1711464250399,
        orders: [
          {
            leverage: 3.0,
            order_type: "LONG",
            order_uuid: "d4379edd-9183-49df-8ea2-04cac6693ce3",
            price: 69871.12,
            price_sources: [],
            processed_ms: 1711464250396,
          },
          {
            leverage: 0.8999999999999999,
            order_type: "LONG",
            order_uuid: "eb1f670a-08e6-4de6-a160-396e5c371d7f",
            price: 70077.4,
            price_sources: [],
            processed_ms: 1711466049667,
          },
          {
            leverage: 0.8999999999999999,
            order_type: "LONG",
            order_uuid: "9c473707-6eca-4602-b4c6-0885360dea2d",
            price: 70252.89,
            price_sources: [],
            processed_ms: 1711467869919,
          },
          {
            leverage: 0.8999999999999995,
            order_type: "LONG",
            order_uuid: "56f4972f-9f8b-4a49-a109-6a23f3dd0c26",
            price: 70073.59,
            price_sources: [],
            processed_ms: 1711469680186,
          },
          {
            leverage: 0.9000000000000004,
            order_type: "LONG",
            order_uuid: "a6a495d1-7131-4b99-b0d7-c64c5e531052",
            price: 69459.44,
            price_sources: [],
            processed_ms: 1711471528160,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "58902f50-c082-4e3a-b127-0c6160510f20",
            price: 69592.0,
            price_sources: [],
            processed_ms: 1711471631028,
          },
        ],
        position_type: "FLAT",
        position_uuid: "7b918943-fe91-4779-87d9-823c912bca72",
        return_at_close: 0.9559670829607426,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
    ],
    thirty_day_returns: 1.0,
  },
  "5FzrMf85gr2tfvhLuiuiZB8Gj6CyXQddo52YGSdYGyDsfNWf": {
    all_time_returns: 1.0,
    n_positions: 0,
    percentage_profitable: 0.0,
    positions: [
      {
        average_entry_price: 63483.58,
        close_ms: 1711142081766,
        current_return: 1.004628598450182,
        initial_entry_price: 63483.58,
        is_closed_position: true,
        miner_hotkey: "5FzrMf85gr2tfvhLuiuiZB8Gj6CyXQddo52YGSdYGyDsfNWf",
        net_leverage: 0.0,
        open_ms: 1711141205980,
        orders: [
          {
            leverage: -1.0,
            order_type: "SHORT",
            order_uuid: "a1a5f355-670d-4c36-80de-e06bd7fd3c90",
            price: 63483.58,
            price_sources: [],
            processed_ms: 1711141205979,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "c7d3e2a4-3b1c-468d-ae96-5c7be265be7e",
            price: 63189.74,
            price_sources: [],
            processed_ms: 1711142081766,
          },
        ],
        position_type: "FLAT",
        position_uuid: "71538160-af1f-4deb-ba7c-f96fcdde1bac",
        return_at_close: 1.0026193412532816,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 63688.99500000002,
        close_ms: 1711178161754,
        current_return: 1.0130469759905278,
        initial_entry_price: 63109.26,
        is_closed_position: true,
        miner_hotkey: "5FzrMf85gr2tfvhLuiuiZB8Gj6CyXQddo52YGSdYGyDsfNWf",
        net_leverage: 0.0,
        open_ms: 1711142183303,
        orders: [
          {
            leverage: 0.5,
            order_type: "LONG",
            order_uuid: "b19585a4-3e5e-4637-a7b6-c65477b34b80",
            price: 63109.26,
            price_sources: [],
            processed_ms: 1711142183302,
          },
          {
            leverage: 0.1,
            order_type: "LONG",
            order_uuid: "e543e7e8-aea9-4b3e-aeeb-62e95b6ed2af",
            price: 64156.85,
            price_sources: [],
            processed_ms: 1711176980649,
          },
          {
            leverage: 0.1,
            order_type: "LONG",
            order_uuid: "59c198a5-721f-4636-a5a0-a32a32e0d6c7",
            price: 64170.99,
            price_sources: [],
            processed_ms: 1711177202038,
          },
          {
            leverage: 0.1,
            order_type: "LONG",
            order_uuid: "1918418a-dbec-47d9-928f-a5d6fcbba718",
            price: 64215.17,
            price_sources: [],
            processed_ms: 1711177462644,
          },
          {
            leverage: 0.1,
            order_type: "LONG",
            order_uuid: "c32f1bfa-3d59-4672-a350-9f89c0520b5d",
            price: 64288.32,
            price_sources: [],
            processed_ms: 1711177685028,
          },
          {
            leverage: 0.1,
            order_type: "LONG",
            order_uuid: "0799a074-72cd-4519-b98e-76713b8e9502",
            price: 64512.32,
            price_sources: [],
            processed_ms: 1711177931539,
          },
          {
            leverage: 0.1,
            order_type: "FLAT",
            order_uuid: "89345bb5-0a2f-43f5-a97e-e106efd05228",
            price: 64512.38,
            price_sources: [],
            processed_ms: 1711178161754,
          },
        ],
        position_type: "FLAT",
        position_uuid: "4857c4ea-40ea-4ff2-b0e6-971a8be0fce4",
        return_at_close: 1.0110208820385467,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 64607.842000000004,
        close_ms: 1711179603752,
        current_return: 0.9996601214355533,
        initial_entry_price: 64687.81,
        is_closed_position: true,
        miner_hotkey: "5FzrMf85gr2tfvhLuiuiZB8Gj6CyXQddo52YGSdYGyDsfNWf",
        net_leverage: 0.0,
        open_ms: 1711178423616,
        orders: [
          {
            leverage: 0.1,
            order_type: "LONG",
            order_uuid: "f563b8a8-b53b-41e5-8415-883e572ebe17",
            price: 64687.81,
            price_sources: [],
            processed_ms: 1711178423616,
          },
          {
            leverage: 0.1,
            order_type: "LONG",
            order_uuid: "4f2558da-6c5d-4bbe-9a80-4bd01238260a",
            price: 64711.11,
            price_sources: [],
            processed_ms: 1711178645022,
          },
          {
            leverage: 0.1,
            order_type: "LONG",
            order_uuid: "ccbf31d3-0596-487c-80a6-9d216caeddf5",
            price: 64626.82,
            price_sources: [],
            processed_ms: 1711178904703,
          },
          {
            leverage: 0.1,
            order_type: "LONG",
            order_uuid: "9eb7be71-04ea-48fb-872f-ade0c4e18f2d",
            price: 64538.75,
            price_sources: [],
            processed_ms: 1711179123660,
          },
          {
            leverage: 0.1,
            order_type: "LONG",
            order_uuid: "dda05c85-9509-4ea9-b055-9cf9aef30a31",
            price: 64474.72,
            price_sources: [],
            processed_ms: 1711179372529,
          },
          {
            leverage: 0.1,
            order_type: "FLAT",
            order_uuid: "6d9d2ec8-a929-468b-bde8-cb6b9a58794e",
            price: 64563.87,
            price_sources: [],
            processed_ms: 1711179603752,
          },
        ],
        position_type: "FLAT",
        position_uuid: "d789275d-bd99-44df-b359-c070c8af7360",
        return_at_close: 0.9986604613141177,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 64596.8,
        close_ms: 1711181066002,
        current_return: 1.0008746050295427,
        initial_entry_price: 64726.36,
        is_closed_position: true,
        miner_hotkey: "5FzrMf85gr2tfvhLuiuiZB8Gj6CyXQddo52YGSdYGyDsfNWf",
        net_leverage: 0.0,
        open_ms: 1711179867656,
        orders: [
          {
            leverage: 0.1,
            order_type: "LONG",
            order_uuid: "89c774bf-6d61-4b9f-94d7-cfd4784a93d5",
            price: 64726.36,
            price_sources: [],
            processed_ms: 1711179867655,
          },
          {
            leverage: 0.1,
            order_type: "LONG",
            order_uuid: "fdd9de3e-c18b-460c-ab26-60fca14d42b6",
            price: 64588.26,
            price_sources: [],
            processed_ms: 1711180089869,
          },
          {
            leverage: 0.1,
            order_type: "LONG",
            order_uuid: "2566d823-4ae5-487f-a536-a502366270fb",
            price: 64562.08,
            price_sources: [],
            processed_ms: 1711180327834,
          },
          {
            leverage: 0.1,
            order_type: "LONG",
            order_uuid: "d6016060-de10-4ed5-b109-327241c98392",
            price: 64530.4,
            price_sources: [],
            processed_ms: 1711180586701,
          },
          {
            leverage: 0.1,
            order_type: "LONG",
            order_uuid: "a15e58e0-ffc3-48b7-95f2-60078500723e",
            price: 64576.9,
            price_sources: [],
            processed_ms: 1711180807936,
          },
          {
            leverage: 0.1,
            order_type: "FLAT",
            order_uuid: "a50c82cd-da13-4da6-ad96-8df49a402c35",
            price: 64710.02,
            price_sources: [],
            processed_ms: 1711181066002,
          },
        ],
        position_type: "FLAT",
        position_uuid: "0c53d1ea-8436-4054-9402-e462f91d078a",
        return_at_close: 0.9998737304245132,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 64621.746,
        close_ms: 1711182507931,
        current_return: 0.9989830857936192,
        initial_entry_price: 64797.01,
        is_closed_position: true,
        miner_hotkey: "5FzrMf85gr2tfvhLuiuiZB8Gj6CyXQddo52YGSdYGyDsfNWf",
        net_leverage: 0.0,
        open_ms: 1711181294817,
        orders: [
          {
            leverage: 0.1,
            order_type: "LONG",
            order_uuid: "e282f326-0bb6-4e10-9ad3-19dc64ad87c1",
            price: 64797.01,
            price_sources: [],
            processed_ms: 1711181294816,
          },
          {
            leverage: 0.1,
            order_type: "LONG",
            order_uuid: "910eac09-8b29-4200-9956-4c5c26bc11fa",
            price: 64653.45,
            price_sources: [],
            processed_ms: 1711181543567,
          },
          {
            leverage: 0.1,
            order_type: "LONG",
            order_uuid: "b7a755ab-b1dc-49f8-9dfb-90a5e285ea29",
            price: 64564.56,
            price_sources: [],
            processed_ms: 1711181770720,
          },
          {
            leverage: 0.1,
            order_type: "LONG",
            order_uuid: "c423a45c-6b22-4179-ab8b-c47224a79c2f",
            price: 64549.42,
            price_sources: [],
            processed_ms: 1711182029594,
          },
          {
            leverage: 0.1,
            order_type: "LONG",
            order_uuid: "17d3b115-cd38-4ee3-b78c-d04351994036",
            price: 64544.29,
            price_sources: [],
            processed_ms: 1711182251014,
          },
          {
            leverage: 0.1,
            order_type: "FLAT",
            order_uuid: "fb7b4e58-8910-45a1-b429-cfd0708cca5b",
            price: 64489.96,
            price_sources: [],
            processed_ms: 1711182507931,
          },
        ],
        position_type: "FLAT",
        position_uuid: "e382d7b4-9e2b-4f95-8881-0d308c710fd6",
        return_at_close: 0.9979841027078256,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 64396.66200000001,
        close_ms: 1711183932053,
        current_return: 1.0000513016074917,
        initial_entry_price: 64403.44,
        is_closed_position: true,
        miner_hotkey: "5FzrMf85gr2tfvhLuiuiZB8Gj6CyXQddo52YGSdYGyDsfNWf",
        net_leverage: 0.0,
        open_ms: 1711182727746,
        orders: [
          {
            leverage: 0.1,
            order_type: "LONG",
            order_uuid: "7fc185f0-96ad-44bd-b637-ea546c7b1cf3",
            price: 64403.44,
            price_sources: [],
            processed_ms: 1711182727744,
          },
          {
            leverage: 0.1,
            order_type: "LONG",
            order_uuid: "a19391c0-ab22-450c-addb-25c3e9e4e53f",
            price: 64254.01,
            price_sources: [],
            processed_ms: 1711182975358,
          },
          {
            leverage: 0.1,
            order_type: "LONG",
            order_uuid: "29c2284d-788a-416b-a0ad-dd882dc44a1c",
            price: 64416.84,
            price_sources: [],
            processed_ms: 1711183213259,
          },
          {
            leverage: 0.1,
            order_type: "LONG",
            order_uuid: "1a7faf3c-7606-465c-8083-8e63414b75c6",
            price: 64439.36,
            price_sources: [],
            processed_ms: 1711183449271,
          },
          {
            leverage: 0.1,
            order_type: "LONG",
            order_uuid: "e5602063-3b38-49d3-adce-9402355ce09a",
            price: 64469.66,
            price_sources: [],
            processed_ms: 1711183697905,
          },
          {
            leverage: 0.1,
            order_type: "FLAT",
            order_uuid: "22885853-7d52-42fe-a3bd-d9963e1536c0",
            price: 64403.27,
            price_sources: [],
            processed_ms: 1711183932053,
          },
        ],
        position_type: "FLAT",
        position_uuid: "bd532d22-e0a3-4ae6-9a5e-400eb3b428b6",
        return_at_close: 0.9990512503058842,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 64408.83000000001,
        close_ms: 1711185372838,
        current_return: 0.9984609546166856,
        initial_entry_price: 64374.32,
        is_closed_position: true,
        miner_hotkey: "5FzrMf85gr2tfvhLuiuiZB8Gj6CyXQddo52YGSdYGyDsfNWf",
        net_leverage: 0.0,
        open_ms: 1711184188225,
        orders: [
          {
            leverage: 0.1,
            order_type: "LONG",
            order_uuid: "be8d05d5-6e37-4bcb-9063-7bd79522cc00",
            price: 64374.32,
            price_sources: [],
            processed_ms: 1711184188224,
          },
          {
            leverage: 0.1,
            order_type: "LONG",
            order_uuid: "b230ac6d-eecf-4607-9e38-adc3a3d4557f",
            price: 64479.43,
            price_sources: [],
            processed_ms: 1711184410117,
          },
          {
            leverage: 0.1,
            order_type: "LONG",
            order_uuid: "0b3d689a-47a8-43cc-a44f-57537eb5a003",
            price: 64463.29,
            price_sources: [],
            processed_ms: 1711184654425,
          },
          {
            leverage: 0.1,
            order_type: "LONG",
            order_uuid: "5912809f-29dc-4bee-869f-54b1f0b83eef",
            price: 64414.37,
            price_sources: [],
            processed_ms: 1711184898317,
          },
          {
            leverage: 0.1,
            order_type: "LONG",
            order_uuid: "93e4670f-6da2-4579-8f16-de3f9abc650b",
            price: 64312.74,
            price_sources: [],
            processed_ms: 1711185144852,
          },
          {
            leverage: 0.1,
            order_type: "FLAT",
            order_uuid: "43127f7b-42cf-4bd3-ab98-76d5aba5d7a8",
            price: 64210.68,
            price_sources: [],
            processed_ms: 1711185372838,
          },
        ],
        position_type: "FLAT",
        position_uuid: "c7a327b1-9fd6-400e-b7f5-0ebbf987d8cd",
        return_at_close: 0.997462493662069,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 64327.366,
        close_ms: 1711186816961,
        current_return: 0.9999907760226987,
        initial_entry_price: 64288.97,
        is_closed_position: true,
        miner_hotkey: "5FzrMf85gr2tfvhLuiuiZB8Gj6CyXQddo52YGSdYGyDsfNWf",
        net_leverage: 0.0,
        open_ms: 1711185630032,
        orders: [
          {
            leverage: 0.1,
            order_type: "LONG",
            order_uuid: "b129337f-387d-4689-a6f5-4498bf4639d7",
            price: 64288.97,
            price_sources: [],
            processed_ms: 1711185630031,
          },
          {
            leverage: 0.1,
            order_type: "LONG",
            order_uuid: "ff747dcc-3033-4c99-9997-ffe44ace9803",
            price: 64314.64,
            price_sources: [],
            processed_ms: 1711185851362,
          },
          {
            leverage: 0.1,
            order_type: "LONG",
            order_uuid: "2c52a8b8-6a01-4ba0-b29d-30bb49a60fde",
            price: 64366.05,
            price_sources: [],
            processed_ms: 1711186109071,
          },
          {
            leverage: 0.1,
            order_type: "LONG",
            order_uuid: "345aa72b-2301-4cf3-9d02-21a0e52f7421",
            price: 64340.61,
            price_sources: [],
            processed_ms: 1711186339202,
          },
          {
            leverage: 0.1,
            order_type: "LONG",
            order_uuid: "17270d2f-75cf-466f-9c17-0aee810bc39d",
            price: 64326.56,
            price_sources: [],
            processed_ms: 1711186586020,
          },
          {
            leverage: 0.1,
            order_type: "FLAT",
            order_uuid: "f5b60f0d-23dd-48a2-b8ce-de3ad1ea32ea",
            price: 64326.18,
            price_sources: [],
            processed_ms: 1711186816961,
          },
        ],
        position_type: "FLAT",
        position_uuid: "e626231a-a532-41c3-af92-be1ba3249ed5",
        return_at_close: 0.998990785246676,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 64372.026,
        close_ms: 1711188253753,
        current_return: 1.0014628960918974,
        initial_entry_price: 64336.08,
        is_closed_position: true,
        miner_hotkey: "5FzrMf85gr2tfvhLuiuiZB8Gj6CyXQddo52YGSdYGyDsfNWf",
        net_leverage: 0.0,
        open_ms: 1711187054420,
        orders: [
          {
            leverage: 0.1,
            order_type: "LONG",
            order_uuid: "d5cd3cf4-d899-45fa-b08a-86cfdfacbe5a",
            price: 64336.08,
            price_sources: [],
            processed_ms: 1711187054419,
          },
          {
            leverage: 0.1,
            order_type: "LONG",
            order_uuid: "cee7c01e-853c-4df6-bcd3-5aa071581024",
            price: 64375.01,
            price_sources: [],
            processed_ms: 1711187301950,
          },
          {
            leverage: 0.1,
            order_type: "LONG",
            order_uuid: "0590ad53-b5b5-4c27-b272-0421fbdb0e4b",
            price: 64275.87,
            price_sources: [],
            processed_ms: 1711187538255,
          },
          {
            leverage: 0.1,
            order_type: "LONG",
            order_uuid: "c396ca0c-280b-41bc-b3b9-b85ecdcc0bf3",
            price: 64386.18,
            price_sources: [],
            processed_ms: 1711187797012,
          },
          {
            leverage: 0.1,
            order_type: "LONG",
            order_uuid: "bdd05a7d-5928-4b58-9f7a-fc1b0233c098",
            price: 64486.99,
            price_sources: [],
            processed_ms: 1711188022494,
          },
          {
            leverage: 0.1,
            order_type: "FLAT",
            order_uuid: "f62acb0f-f628-4737-acb3-4fc8c5874613",
            price: 64560.26,
            price_sources: [],
            processed_ms: 1711188253753,
          },
        ],
        position_type: "FLAT",
        position_uuid: "866c6982-61f4-4fd2-9f55-9d8e83fc86a0",
        return_at_close: 1.0004614331958055,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 64531.632000000005,
        close_ms: 1711189701474,
        current_return: 1.000346762977142,
        initial_entry_price: 64508.04,
        is_closed_position: true,
        miner_hotkey: "5FzrMf85gr2tfvhLuiuiZB8Gj6CyXQddo52YGSdYGyDsfNWf",
        net_leverage: 0.0,
        open_ms: 1711188510035,
        orders: [
          {
            leverage: 0.1,
            order_type: "LONG",
            order_uuid: "b5a08f8a-e375-40e8-9508-807b894ff0d5",
            price: 64508.04,
            price_sources: [],
            processed_ms: 1711188510033,
          },
          {
            leverage: 0.1,
            order_type: "LONG",
            order_uuid: "0b4ffd2d-bb73-49f6-8606-7b98eac52c9f",
            price: 64508.83,
            price_sources: [],
            processed_ms: 1711188734492,
          },
          {
            leverage: 0.1,
            order_type: "LONG",
            order_uuid: "f34d1cdc-4d2b-41cc-b596-55156f97ccd1",
            price: 64436.89,
            price_sources: [],
            processed_ms: 1711188975521,
          },
          {
            leverage: 0.1,
            order_type: "LONG",
            order_uuid: "6697ee52-df95-4fe4-965f-017dfbc4edc5",
            price: 64569.63,
            price_sources: [],
            processed_ms: 1711189214286,
          },
          {
            leverage: 0.1,
            order_type: "LONG",
            order_uuid: "fb0057b0-5a4a-4600-bcc4-e3c1404a2347",
            price: 64634.77,
            price_sources: [],
            processed_ms: 1711189461981,
          },
          {
            leverage: 0.1,
            order_type: "FLAT",
            order_uuid: "bf9cda9a-e53a-420d-8bb4-2f560e1603e1",
            price: 64576.37,
            price_sources: [],
            processed_ms: 1711189701474,
          },
        ],
        position_type: "FLAT",
        position_uuid: "5bbb077d-d784-4837-8d06-0b641e4f113e",
        return_at_close: 0.999346416214165,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 64565.826,
        close_ms: 1711191141654,
        current_return: 0.9991808927514977,
        initial_entry_price: 64586.17,
        is_closed_position: true,
        miner_hotkey: "5FzrMf85gr2tfvhLuiuiZB8Gj6CyXQddo52YGSdYGyDsfNWf",
        net_leverage: 0.0,
        open_ms: 1711189938988,
        orders: [
          {
            leverage: 0.1,
            order_type: "LONG",
            order_uuid: "ce3fd851-4557-413f-8cf1-0d0cb72c3e0f",
            price: 64586.17,
            price_sources: [],
            processed_ms: 1711189938987,
          },
          {
            leverage: 0.1,
            order_type: "LONG",
            order_uuid: "8361f5dd-a7cb-4885-98e8-720bb57d77f4",
            price: 64603.83,
            price_sources: [],
            processed_ms: 1711190187076,
          },
          {
            leverage: 0.1,
            order_type: "LONG",
            order_uuid: "da91ed0a-9c93-43dc-8710-00d83c8de74f",
            price: 64550.27,
            price_sources: [],
            processed_ms: 1711190423478,
          },
          {
            leverage: 0.1,
            order_type: "LONG",
            order_uuid: "de696b2f-0d80-41d1-b24c-1b492bedf299",
            price: 64556.04,
            price_sources: [],
            processed_ms: 1711190659338,
          },
          {
            leverage: 0.1,
            order_type: "LONG",
            order_uuid: "052334df-b2e6-4446-bfbe-db2cf9a56a63",
            price: 64532.82,
            price_sources: [],
            processed_ms: 1711190907176,
          },
          {
            leverage: 0.1,
            order_type: "FLAT",
            order_uuid: "fe1e486a-c35e-44e9-b8f9-e9e2e2eaf15e",
            price: 64460.02,
            price_sources: [],
            processed_ms: 1711191141654,
          },
        ],
        position_type: "FLAT",
        position_uuid: "e105da43-8f22-4a5f-b26a-7279115667f2",
        return_at_close: 0.9981817118587462,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 64325.2,
        close_ms: 1711192599386,
        current_return: 1.001017235500484,
        initial_entry_price: 64257.49,
        is_closed_position: true,
        miner_hotkey: "5FzrMf85gr2tfvhLuiuiZB8Gj6CyXQddo52YGSdYGyDsfNWf",
        net_leverage: 0.0,
        open_ms: 1711191394067,
        orders: [
          {
            leverage: 0.1,
            order_type: "LONG",
            order_uuid: "75586de0-ece0-4a1d-bae2-bfbf3edf2d00",
            price: 64257.49,
            price_sources: [],
            processed_ms: 1711191394066,
          },
          {
            leverage: 0.1,
            order_type: "LONG",
            order_uuid: "aceaa4f0-8569-4fb2-a28c-6dc3d983599a",
            price: 64356.25,
            price_sources: [],
            processed_ms: 1711191620456,
          },
          {
            leverage: 0.1,
            order_type: "LONG",
            order_uuid: "866934ca-c421-44e3-ade6-ab09e4bc6674",
            price: 64361.79,
            price_sources: [],
            processed_ms: 1711191877443,
          },
          {
            leverage: 0.1,
            order_type: "LONG",
            order_uuid: "580e09b5-3fc8-4760-925e-2962f90c33a1",
            price: 64272.65,
            price_sources: [],
            processed_ms: 1711192118567,
          },
          {
            leverage: 0.1,
            order_type: "LONG",
            order_uuid: "20410feb-18ba-4b72-994f-0acea6c46dc8",
            price: 64377.82,
            price_sources: [],
            processed_ms: 1711192342565,
          },
          {
            leverage: 0.1,
            order_type: "FLAT",
            order_uuid: "388de47f-8da9-4f67-b937-230c678e329b",
            price: 64455.93,
            price_sources: [],
            processed_ms: 1711192599386,
          },
        ],
        position_type: "FLAT",
        position_uuid: "d3bab925-8796-4b23-958f-e2df07cf2c87",
        return_at_close: 1.0000162182649837,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 64488.328,
        close_ms: 1711194027386,
        current_return: 1.0002716427095042,
        initial_entry_price: 64481.76,
        is_closed_position: true,
        miner_hotkey: "5FzrMf85gr2tfvhLuiuiZB8Gj6CyXQddo52YGSdYGyDsfNWf",
        net_leverage: 0.0,
        open_ms: 1711192833291,
        orders: [
          {
            leverage: 0.1,
            order_type: "LONG",
            order_uuid: "77f1c477-ebbf-417b-8666-576313f2354b",
            price: 64481.76,
            price_sources: [],
            processed_ms: 1711192833289,
          },
          {
            leverage: 0.1,
            order_type: "LONG",
            order_uuid: "186dcbcc-1ccb-4f15-8bc1-979c1e475cd1",
            price: 64447.57,
            price_sources: [],
            processed_ms: 1711193080550,
          },
          {
            leverage: 0.1,
            order_type: "LONG",
            order_uuid: "6797dfa2-dc06-4f02-a4a4-1f38b45a3857",
            price: 64453.56,
            price_sources: [],
            processed_ms: 1711193312202,
          },
          {
            leverage: 0.1,
            order_type: "LONG",
            order_uuid: "62ffc92f-75ac-4ca5-a84e-5ee9d1d43342",
            price: 64491.29,
            price_sources: [],
            processed_ms: 1711193551149,
          },
          {
            leverage: 0.1,
            order_type: "LONG",
            order_uuid: "89b0cb15-90ac-4664-8ef4-33f122966cca",
            price: 64567.46,
            price_sources: [],
            processed_ms: 1711193798560,
          },
          {
            leverage: 0.1,
            order_type: "FLAT",
            order_uuid: "13d2ff01-ff80-4bf1-ba12-da5326ed27f8",
            price: 64523.36,
            price_sources: [],
            processed_ms: 1711194027386,
          },
        ],
        position_type: "FLAT",
        position_uuid: "7fc46f03-8591-4580-82a2-aa88bcd9339c",
        return_at_close: 0.9992713710667946,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 64568.45200000001,
        close_ms: 1711195462407,
        current_return: 1.0000054815605104,
        initial_entry_price: 64580.15,
        is_closed_position: true,
        miner_hotkey: "5FzrMf85gr2tfvhLuiuiZB8Gj6CyXQddo52YGSdYGyDsfNWf",
        net_leverage: 0.0,
        open_ms: 1711194266013,
        orders: [
          {
            leverage: 0.1,
            order_type: "LONG",
            order_uuid: "1ecd7d7d-cf3f-440e-af5c-e3724acccad6",
            price: 64580.15,
            price_sources: [],
            processed_ms: 1711194266011,
          },
          {
            leverage: 0.1,
            order_type: "LONG",
            order_uuid: "4f237b71-8ac8-422a-9a38-a2f83fb6e5fa",
            price: 64553.69,
            price_sources: [],
            processed_ms: 1711194514444,
          },
          {
            leverage: 0.1,
            order_type: "LONG",
            order_uuid: "78861e53-5faf-40cf-b8bf-e789382e7c64",
            price: 64499.91,
            price_sources: [],
            processed_ms: 1711194743287,
          },
          {
            leverage: 0.1,
            order_type: "LONG",
            order_uuid: "e884ad7b-f4f7-43cf-b64c-5bf124ef6873",
            price: 64570.76,
            price_sources: [],
            processed_ms: 1711194983797,
          },
          {
            leverage: 0.1,
            order_type: "LONG",
            order_uuid: "a805fde6-b03f-45a0-847e-20a757e39e80",
            price: 64637.75,
            price_sources: [],
            processed_ms: 1711195233423,
          },
          {
            leverage: 0.1,
            order_type: "FLAT",
            order_uuid: "2deba71e-f097-45e5-9a09-4287299f41e1",
            price: 64569.16,
            price_sources: [],
            processed_ms: 1711195462407,
          },
        ],
        position_type: "FLAT",
        position_uuid: "e21cca1d-aef5-44e1-83ad-d15ac2c9bebc",
        return_at_close: 0.9990054760789499,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 64665.21,
        close_ms: 1711196906691,
        current_return: 1.00029033939717,
        initial_entry_price: 64682.92,
        is_closed_position: true,
        miner_hotkey: "5FzrMf85gr2tfvhLuiuiZB8Gj6CyXQddo52YGSdYGyDsfNWf",
        net_leverage: 0.0,
        open_ms: 1711195724743,
        orders: [
          {
            leverage: 0.1,
            order_type: "LONG",
            order_uuid: "fb3fdcc3-2829-4afa-8f44-82f1605e8a38",
            price: 64682.92,
            price_sources: [],
            processed_ms: 1711195724742,
          },
          {
            leverage: 0.1,
            order_type: "LONG",
            order_uuid: "be050fae-3077-45e4-918d-6b0532539b30",
            price: 64692.43,
            price_sources: [],
            processed_ms: 1711195945762,
          },
          {
            leverage: 0.1,
            order_type: "LONG",
            order_uuid: "9006ea84-57f1-4683-9865-8cc4644691f2",
            price: 64585.96,
            price_sources: [],
            processed_ms: 1711196207428,
          },
          {
            leverage: 0.1,
            order_type: "LONG",
            order_uuid: "1b816eb2-1a41-4215-a07e-12e9f30edf50",
            price: 64616.91,
            price_sources: [],
            processed_ms: 1711196432676,
          },
          {
            leverage: 0.1,
            order_type: "LONG",
            order_uuid: "1804f561-2f8a-454a-9d6d-dad331a33b00",
            price: 64747.83,
            price_sources: [],
            processed_ms: 1711196679797,
          },
          {
            leverage: 0.1,
            order_type: "FLAT",
            order_uuid: "f66abcc2-37d4-4848-a390-43db00a5e96c",
            price: 64702.77,
            price_sources: [],
            processed_ms: 1711196906691,
          },
        ],
        position_type: "FLAT",
        position_uuid: "63924bc4-0df8-46eb-a667-11f6d37ccaa3",
        return_at_close: 0.9992900490577729,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 64722.14000000001,
        close_ms: 1711198352594,
        current_return: 1.0006949931119147,
        initial_entry_price: 64676.9,
        is_closed_position: true,
        miner_hotkey: "5FzrMf85gr2tfvhLuiuiZB8Gj6CyXQddo52YGSdYGyDsfNWf",
        net_leverage: 0.0,
        open_ms: 1711197166462,
        orders: [
          {
            leverage: 0.1,
            order_type: "LONG",
            order_uuid: "e36ed2bf-70ef-489b-b0d6-d226addf707e",
            price: 64676.9,
            price_sources: [],
            processed_ms: 1711197166461,
          },
          {
            leverage: 0.1,
            order_type: "LONG",
            order_uuid: "0d1b2850-d366-43ce-8ee0-bc4c345984a2",
            price: 64650.97,
            price_sources: [],
            processed_ms: 1711197388047,
          },
          {
            leverage: 0.1,
            order_type: "LONG",
            order_uuid: "2dad5e55-3bc3-47cb-ba44-421059e4dc7c",
            price: 64707.25,
            price_sources: [],
            processed_ms: 1711197648519,
          },
          {
            leverage: 0.1,
            order_type: "LONG",
            order_uuid: "d78a613a-f05d-4b4c-b832-f7f863e2c6b0",
            price: 64757.2,
            price_sources: [],
            processed_ms: 1711197869904,
          },
          {
            leverage: 0.1,
            order_type: "LONG",
            order_uuid: "334b23b3-4e47-45ad-802c-9bba07b00911",
            price: 64818.38,
            price_sources: [],
            processed_ms: 1711198120510,
          },
          {
            leverage: 0.1,
            order_type: "FLAT",
            order_uuid: "127d64e6-0c26-40ec-aba6-18b111c390c1",
            price: 64812.04,
            price_sources: [],
            processed_ms: 1711198352594,
          },
        ],
        position_type: "FLAT",
        position_uuid: "dcf6d182-552b-41ac-8f71-7dfe2bb88a32",
        return_at_close: 0.9996942981188027,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 64679.672000000006,
        close_ms: 1711199806251,
        current_return: 0.998860969306463,
        initial_entry_price: 64801.59,
        is_closed_position: true,
        miner_hotkey: "5FzrMf85gr2tfvhLuiuiZB8Gj6CyXQddo52YGSdYGyDsfNWf",
        net_leverage: 0.0,
        open_ms: 1711198610428,
        orders: [
          {
            leverage: 0.1,
            order_type: "LONG",
            order_uuid: "d534cfee-b7e3-4dfb-9c64-57309dd80d0f",
            price: 64801.59,
            price_sources: [],
            processed_ms: 1711198610426,
          },
          {
            leverage: 0.1,
            order_type: "LONG",
            order_uuid: "a650d20e-4a99-4fda-8f53-3e20deb18b5a",
            price: 64703.04,
            price_sources: [],
            processed_ms: 1711198831880,
          },
          {
            leverage: 0.1,
            order_type: "LONG",
            order_uuid: "a7b329d5-023e-44c3-8f75-923e9c5f4847",
            price: 64605.56,
            price_sources: [],
            processed_ms: 1711199070179,
          },
          {
            leverage: 0.1,
            order_type: "LONG",
            order_uuid: "cca69622-92ef-4a8e-aa44-0892b5927dce",
            price: 64594.3,
            price_sources: [],
            processed_ms: 1711199323712,
          },
          {
            leverage: 0.1,
            order_type: "LONG",
            order_uuid: "c38fc8b9-db6b-4de7-b7d7-67a5e90e2861",
            price: 64693.87,
            price_sources: [],
            processed_ms: 1711199551644,
          },
          {
            leverage: 0.1,
            order_type: "FLAT",
            order_uuid: "2a108ef2-0810-4807-ae07-cfd8d529bd4c",
            price: 64532.05,
            price_sources: [],
            processed_ms: 1711199806251,
          },
        ],
        position_type: "FLAT",
        position_uuid: "6c0db518-8816-4fcf-a1b6-c0d27ab4ac50",
        return_at_close: 0.9978621083371565,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 64520.8,
        close_ms: 1711201234952,
        current_return: 1.0001365832507434,
        initial_entry_price: 64576.0,
        is_closed_position: true,
        miner_hotkey: "5FzrMf85gr2tfvhLuiuiZB8Gj6CyXQddo52YGSdYGyDsfNWf",
        net_leverage: 0.0,
        open_ms: 1711200029405,
        orders: [
          {
            leverage: 0.1,
            order_type: "LONG",
            order_uuid: "4be57a6d-5c79-4e9c-8ad1-6fea2824ef01",
            price: 64576.0,
            price_sources: [],
            processed_ms: 1711200029402,
          },
          {
            leverage: 0.1,
            order_type: "LONG",
            order_uuid: "25b01605-144e-4304-8382-b51ab1b10763",
            price: 64568.29,
            price_sources: [],
            processed_ms: 1711200276401,
          },
          {
            leverage: 0.1,
            order_type: "LONG",
            order_uuid: "89726d2e-5461-49ec-89c0-698cc348c1d8",
            price: 64517.68,
            price_sources: [],
            processed_ms: 1711200520593,
          },
          {
            leverage: 0.1,
            order_type: "LONG",
            order_uuid: "d3eade53-10db-4944-bdf0-a9d23e5c2e77",
            price: 64484.65,
            price_sources: [],
            processed_ms: 1711200753011,
          },
          {
            leverage: 0.1,
            order_type: "LONG",
            order_uuid: "0aba59ad-239f-497a-a256-8ebfb2eb2926",
            price: 64457.38,
            price_sources: [],
            processed_ms: 1711201000186,
          },
          {
            leverage: 0.1,
            order_type: "FLAT",
            order_uuid: "393a649b-dc69-4915-95f4-d3dcc13787c1",
            price: 64538.44,
            price_sources: [],
            processed_ms: 1711201234952,
          },
        ],
        position_type: "FLAT",
        position_uuid: "8c92132f-bac2-4356-bd5d-229792ff2afb",
        return_at_close: 0.9991364466674927,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 64533.82,
        close_ms: 1711202679532,
        current_return: 1.000615358122448,
        initial_entry_price: 64458.4,
        is_closed_position: true,
        miner_hotkey: "5FzrMf85gr2tfvhLuiuiZB8Gj6CyXQddo52YGSdYGyDsfNWf",
        net_leverage: 0.0,
        open_ms: 1711201495157,
        orders: [
          {
            leverage: 0.1,
            order_type: "LONG",
            order_uuid: "0e1b280a-9491-4c16-9e0d-92baad088f33",
            price: 64458.4,
            price_sources: [],
            processed_ms: 1711201495155,
          },
          {
            leverage: 0.1,
            order_type: "LONG",
            order_uuid: "96e6f6a5-d3b0-47a0-896b-1f9249ce15d4",
            price: 64305.85,
            price_sources: [],
            processed_ms: 1711201716439,
          },
          {
            leverage: 0.1,
            order_type: "LONG",
            order_uuid: "21658371-3835-4b84-aef9-ad7c7b0fe5b8",
            price: 64632.38,
            price_sources: [],
            processed_ms: 1711201974339,
          },
          {
            leverage: 0.1,
            order_type: "LONG",
            order_uuid: "64fe67fa-a59b-4819-92c9-395482889bc9",
            price: 64675.18,
            price_sources: [],
            processed_ms: 1711202200949,
          },
          {
            leverage: 0.1,
            order_type: "LONG",
            order_uuid: "fdda00a9-a082-483d-a643-30866ab95fe4",
            price: 64597.29,
            price_sources: [],
            processed_ms: 1711202448101,
          },
          {
            leverage: 0.1,
            order_type: "FLAT",
            order_uuid: "5e8b90ad-3b06-4998-b2bd-0941d8d8416d",
            price: 64613.15,
            price_sources: [],
            processed_ms: 1711202679532,
          },
        ],
        position_type: "FLAT",
        position_uuid: "41cf9513-eeee-404f-be76-c6db11ee1b2a",
        return_at_close: 0.9996147427643255,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 64583.48000000001,
        close_ms: 1711204120415,
        current_return: 1.0014077314191137,
        initial_entry_price: 64618.15,
        is_closed_position: true,
        miner_hotkey: "5FzrMf85gr2tfvhLuiuiZB8Gj6CyXQddo52YGSdYGyDsfNWf",
        net_leverage: 0.0,
        open_ms: 1711202935389,
        orders: [
          {
            leverage: 0.1,
            order_type: "LONG",
            order_uuid: "517bacc3-f2a5-4223-985a-c19226b55c4a",
            price: 64618.15,
            price_sources: [],
            processed_ms: 1711202935387,
          },
          {
            leverage: 0.1,
            order_type: "LONG",
            order_uuid: "9c8a86fb-1e67-405e-a41f-2d9d54847867",
            price: 64561.16,
            price_sources: [],
            processed_ms: 1711203156765,
          },
          {
            leverage: 0.1,
            order_type: "LONG",
            order_uuid: "f676103a-e819-432d-a548-f3ad49dd3681",
            price: 64499.52,
            price_sources: [],
            processed_ms: 1711203413147,
          },
          {
            leverage: 0.1,
            order_type: "LONG",
            order_uuid: "704556a8-bd6d-4fdd-81dc-a633bff282a8",
            price: 64585.24,
            price_sources: [],
            processed_ms: 1711203642366,
          },
          {
            leverage: 0.1,
            order_type: "LONG",
            order_uuid: "574a905f-e5fe-47ca-bcc3-93fc445c9c45",
            price: 64653.33,
            price_sources: [],
            processed_ms: 1711203889386,
          },
          {
            leverage: 0.1,
            order_type: "FLAT",
            order_uuid: "9b108210-324b-48d8-a795-2009619fb81a",
            price: 64765.41,
            price_sources: [],
            processed_ms: 1711204120415,
          },
        ],
        position_type: "FLAT",
        position_uuid: "f6f550c0-a8a1-4ea8-9b7a-beb74451679f",
        return_at_close: 1.0004063236876946,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 65203.622,
        close_ms: 1711205558716,
        current_return: 1.0010606749166842,
        initial_entry_price: 65221.68,
        is_closed_position: true,
        miner_hotkey: "5FzrMf85gr2tfvhLuiuiZB8Gj6CyXQddo52YGSdYGyDsfNWf",
        net_leverage: 0.0,
        open_ms: 1711204377594,
        orders: [
          {
            leverage: 0.1,
            order_type: "LONG",
            order_uuid: "4c1a1aaf-f61f-4a07-8544-5edddc191c79",
            price: 65221.68,
            price_sources: [],
            processed_ms: 1711204377592,
          },
          {
            leverage: 0.1,
            order_type: "LONG",
            order_uuid: "2530d60e-0e95-48c5-859e-7a7ccdcb5713",
            price: 65355.55,
            price_sources: [],
            processed_ms: 1711204598975,
          },
          {
            leverage: 0.1,
            order_type: "LONG",
            order_uuid: "2df44f6d-018e-411c-a299-885a4cbf8e21",
            price: 65182.7,
            price_sources: [],
            processed_ms: 1711204858737,
          },
          {
            leverage: 0.1,
            order_type: "LONG",
            order_uuid: "553c4603-04cf-48ad-ac38-192d75241414",
            price: 65105.47,
            price_sources: [],
            processed_ms: 1711205079816,
          },
          {
            leverage: 0.1,
            order_type: "LONG",
            order_uuid: "1d73522c-785e-471a-ab36-f043fd018bcf",
            price: 65152.71,
            price_sources: [],
            processed_ms: 1711205326313,
          },
          {
            leverage: 0.1,
            order_type: "FLAT",
            order_uuid: "2e61ab3e-31b1-42e5-9bb2-133bffbed2c3",
            price: 65341.98,
            price_sources: [],
            processed_ms: 1711205558716,
          },
        ],
        position_type: "FLAT",
        position_uuid: "cd7693d8-73cc-4b52-8f06-caecd2fb4bea",
        return_at_close: 1.0000596142417675,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 65221.822,
        close_ms: 1711206997325,
        current_return: 0.9985121132624346,
        initial_entry_price: 65361.83,
        is_closed_position: true,
        miner_hotkey: "5FzrMf85gr2tfvhLuiuiZB8Gj6CyXQddo52YGSdYGyDsfNWf",
        net_leverage: 0.0,
        open_ms: 1711205814576,
        orders: [
          {
            leverage: 0.1,
            order_type: "LONG",
            order_uuid: "57ccb6a1-c257-4e39-9c02-d4c4da3ca1c3",
            price: 65361.83,
            price_sources: [],
            processed_ms: 1711205814573,
          },
          {
            leverage: 0.1,
            order_type: "LONG",
            order_uuid: "308cb8d8-2e18-4888-8ada-1ed673a66f5b",
            price: 65255.37,
            price_sources: [],
            processed_ms: 1711206040659,
          },
          {
            leverage: 0.1,
            order_type: "LONG",
            order_uuid: "2d7b521e-fdf2-40f6-9965-c1b84bc28c75",
            price: 65132.84,
            price_sources: [],
            processed_ms: 1711206296368,
          },
          {
            leverage: 0.1,
            order_type: "LONG",
            order_uuid: "0bb20709-c25c-4f18-b24f-c1198dd13884",
            price: 65268.35,
            price_sources: [],
            processed_ms: 1711206525709,
          },
          {
            leverage: 0.1,
            order_type: "LONG",
            order_uuid: "bb6f0df5-7643-4241-981b-4b0fecd96fe5",
            price: 65090.72,
            price_sources: [],
            processed_ms: 1711206772572,
          },
          {
            leverage: 0.1,
            order_type: "FLAT",
            order_uuid: "309326ba-05a6-42ae-a45c-e551d1db054e",
            price: 65027.32,
            price_sources: [],
            processed_ms: 1711206997325,
          },
        ],
        position_type: "FLAT",
        position_uuid: "50e8efef-f47d-4fbb-b930-2288557d4454",
        return_at_close: 0.9975136011491722,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 64852.134000000005,
        close_ms: 1711208442558,
        current_return: 1.0002370034186563,
        initial_entry_price: 64885.14,
        is_closed_position: true,
        miner_hotkey: "5FzrMf85gr2tfvhLuiuiZB8Gj6CyXQddo52YGSdYGyDsfNWf",
        net_leverage: 0.0,
        open_ms: 1711207256977,
        orders: [
          {
            leverage: 0.1,
            order_type: "LONG",
            order_uuid: "294c757b-d95a-4a1c-b391-7ad638e0f476",
            price: 64885.14,
            price_sources: [],
            processed_ms: 1711207256974,
          },
          {
            leverage: 0.1,
            order_type: "LONG",
            order_uuid: "c266300d-e8d6-4250-b87b-cf396772cfd4",
            price: 64823.88,
            price_sources: [],
            processed_ms: 1711207477730,
          },
          {
            leverage: 0.1,
            order_type: "LONG",
            order_uuid: "58fbdbe9-e39e-4436-b78a-bd4682baedbf",
            price: 64819.59,
            price_sources: [],
            processed_ms: 1711207737512,
          },
          {
            leverage: 0.1,
            order_type: "LONG",
            order_uuid: "48a7f9b7-619b-4f30-b0aa-0f0554c602e4",
            price: 64882.95,
            price_sources: [],
            processed_ms: 1711207964805,
          },
          {
            leverage: 0.1,
            order_type: "LONG",
            order_uuid: "0b15e6ce-3b16-487a-9f24-66cc782ee4a9",
            price: 64849.11,
            price_sources: [],
            processed_ms: 1711208218100,
          },
          {
            leverage: 0.1,
            order_type: "FLAT",
            order_uuid: "494376f4-ceb2-4e47-93e7-8c2943b644bb",
            price: 64882.89,
            price_sources: [],
            processed_ms: 1711208442558,
          },
        ],
        position_type: "FLAT",
        position_uuid: "01c69a26-0067-4ef9-a2db-e2b4324dfe65",
        return_at_close: 0.9992367664152376,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 64988.938,
        close_ms: 1711209892853,
        current_return: 1.0005415337544885,
        initial_entry_price: 64845.45,
        is_closed_position: true,
        miner_hotkey: "5FzrMf85gr2tfvhLuiuiZB8Gj6CyXQddo52YGSdYGyDsfNWf",
        net_leverage: 0.0,
        open_ms: 1711208694321,
        orders: [
          {
            leverage: 0.1,
            order_type: "LONG",
            order_uuid: "b750629b-4c49-4257-9b9a-81b7d9fe7b95",
            price: 64845.45,
            price_sources: [],
            processed_ms: 1711208694318,
          },
          {
            leverage: 0.1,
            order_type: "LONG",
            order_uuid: "05d79f86-f35f-4043-8685-1400009c5930",
            price: 64916.03,
            price_sources: [],
            processed_ms: 1711208921619,
          },
          {
            leverage: 0.1,
            order_type: "LONG",
            order_uuid: "239c6465-9247-44ef-9737-83f9c5e9a633",
            price: 64956.1,
            price_sources: [],
            processed_ms: 1711209181357,
          },
          {
            leverage: 0.1,
            order_type: "LONG",
            order_uuid: "0d83c672-802f-4d66-abc5-996ea8910b82",
            price: 65138.23,
            price_sources: [],
            processed_ms: 1711209408641,
          },
          {
            leverage: 0.1,
            order_type: "LONG",
            order_uuid: "09ad8b31-cf4f-4a62-a4c1-e72ca3c12f81",
            price: 65088.88,
            price_sources: [],
            processed_ms: 1711209660556,
          },
          {
            leverage: 0.1,
            order_type: "FLAT",
            order_uuid: "59f67dd7-4b1d-4873-ab1d-881eab1687c9",
            price: 65059.17,
            price_sources: [],
            processed_ms: 1711209892853,
          },
        ],
        position_type: "FLAT",
        position_uuid: "43eb4067-3943-45ae-9f7c-c157e3fc9aec",
        return_at_close: 0.999540992220734,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 65107.69200000001,
        close_ms: 1711211340379,
        current_return: 1.0007775471835263,
        initial_entry_price: 64933.68,
        is_closed_position: true,
        miner_hotkey: "5FzrMf85gr2tfvhLuiuiZB8Gj6CyXQddo52YGSdYGyDsfNWf",
        net_leverage: 0.0,
        open_ms: 1711210141493,
        orders: [
          {
            leverage: 0.1,
            order_type: "LONG",
            order_uuid: "beea50a1-0da1-4e96-9b82-4689df29ec2f",
            price: 64933.68,
            price_sources: [],
            processed_ms: 1711210141490,
          },
          {
            leverage: 0.1,
            order_type: "LONG",
            order_uuid: "81e3e46a-143e-4a34-bf10-8be18a938ee3",
            price: 65101.27,
            price_sources: [],
            processed_ms: 1711210368561,
          },
          {
            leverage: 0.1,
            order_type: "LONG",
            order_uuid: "b4f293ba-8a0e-4cd6-ae7b-94a3af3deb4a",
            price: 65146.82,
            price_sources: [],
            processed_ms: 1711210600929,
          },
          {
            leverage: 0.1,
            order_type: "LONG",
            order_uuid: "75169359-b5c4-4572-97b1-1452e41fce0c",
            price: 65176.15,
            price_sources: [],
            processed_ms: 1711210859358,
          },
          {
            leverage: 0.1,
            order_type: "LONG",
            order_uuid: "140a0de2-5cb4-465d-b958-bf52427d9700",
            price: 65180.54,
            price_sources: [],
            processed_ms: 1711211082806,
          },
          {
            leverage: 0.1,
            order_type: "FLAT",
            order_uuid: "91ef6020-54e1-41fe-98d0-5fc9da7ed0f2",
            price: 65208.67,
            price_sources: [],
            processed_ms: 1711211340379,
          },
        ],
        position_type: "FLAT",
        position_uuid: "95793398-bf67-4717-85a5-a50b9d8a452f",
        return_at_close: 0.9997767696363428,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 65573.89800000002,
        close_ms: 1711212789437,
        current_return: 1.0001162156599608,
        initial_entry_price: 65447.29,
        is_closed_position: true,
        miner_hotkey: "5FzrMf85gr2tfvhLuiuiZB8Gj6CyXQddo52YGSdYGyDsfNWf",
        net_leverage: 0.0,
        open_ms: 1711211562776,
        orders: [
          {
            leverage: 0.1,
            order_type: "LONG",
            order_uuid: "5166ac5f-add1-4308-9dfb-1f6604c93f22",
            price: 65447.29,
            price_sources: [],
            processed_ms: 1711211562773,
          },
          {
            leverage: 0.1,
            order_type: "LONG",
            order_uuid: "a90fb873-fdd8-4668-955f-1aa5b4a4f57a",
            price: 65499.92,
            price_sources: [],
            processed_ms: 1711211809558,
          },
          {
            leverage: 0.1,
            order_type: "LONG",
            order_uuid: "7b17b4a0-3de5-417c-a0fb-166b2512a54b",
            price: 65503.8,
            price_sources: [],
            processed_ms: 1711212043506,
          },
          {
            leverage: 0.1,
            order_type: "LONG",
            order_uuid: "5d13f334-bd38-4a85-a80c-aeef4226fb99",
            price: 65668.43,
            price_sources: [],
            processed_ms: 1711212304041,
          },
          {
            leverage: 0.1,
            order_type: "LONG",
            order_uuid: "b4973531-f38a-4abd-8824-b7e6c2a4342b",
            price: 65750.05,
            price_sources: [],
            processed_ms: 1711212525550,
          },
          {
            leverage: 0.1,
            order_type: "FLAT",
            order_uuid: "2b717a80-a99f-4670-9263-8656d2dc06f0",
            price: 65589.11,
            price_sources: [],
            processed_ms: 1711212789437,
          },
        ],
        position_type: "FLAT",
        position_uuid: "f984e7fd-a751-4388-8642-461e1bc240f8",
        return_at_close: 0.9991160994443008,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 65649.21600000001,
        close_ms: 1711214218298,
        current_return: 0.9988484514713909,
        initial_entry_price: 65622.94,
        is_closed_position: true,
        miner_hotkey: "5FzrMf85gr2tfvhLuiuiZB8Gj6CyXQddo52YGSdYGyDsfNWf",
        net_leverage: 0.0,
        open_ms: 1711213017250,
        orders: [
          {
            leverage: 0.1,
            order_type: "LONG",
            order_uuid: "2783b7d7-64aa-4d31-b9f6-021e1861e7a1",
            price: 65622.94,
            price_sources: [],
            processed_ms: 1711213017247,
          },
          {
            leverage: 0.1,
            order_type: "LONG",
            order_uuid: "2b13e279-3e4a-4d20-83fe-ca1d0ea87440",
            price: 65665.01,
            price_sources: [],
            processed_ms: 1711213264486,
          },
          {
            leverage: 0.1,
            order_type: "LONG",
            order_uuid: "e3972a35-d1f9-43e9-9bde-f1ba59e32ef1",
            price: 65722.76,
            price_sources: [],
            processed_ms: 1711213497434,
          },
          {
            leverage: 0.1,
            order_type: "LONG",
            order_uuid: "20bc670c-982b-4ae9-a961-55ddb84dcce1",
            price: 65607.56,
            price_sources: [],
            processed_ms: 1711213739960,
          },
          {
            leverage: 0.1,
            order_type: "LONG",
            order_uuid: "f42b8187-6928-4264-89c0-47119eeb859b",
            price: 65627.81,
            price_sources: [],
            processed_ms: 1711213989282,
          },
          {
            leverage: 0.1,
            order_type: "FLAT",
            order_uuid: "f249c4d6-bb63-4e24-a100-43723f56358c",
            price: 65498.08,
            price_sources: [],
            processed_ms: 1711214218298,
          },
        ],
        position_type: "FLAT",
        position_uuid: "39532205-e82c-4063-808c-b4db582f902a",
        return_at_close: 0.9978496030199195,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 65691.576,
        close_ms: 1711215655520,
        current_return: 1.001047391922124,
        initial_entry_price: 65564.76,
        is_closed_position: true,
        miner_hotkey: "5FzrMf85gr2tfvhLuiuiZB8Gj6CyXQddo52YGSdYGyDsfNWf",
        net_leverage: 0.0,
        open_ms: 1711214445680,
        orders: [
          {
            leverage: 0.1,
            order_type: "LONG",
            order_uuid: "36ad3db8-e164-44ba-b7e0-6bd4e890f20a",
            price: 65564.76,
            price_sources: [],
            processed_ms: 1711214445677,
          },
          {
            leverage: 0.1,
            order_type: "LONG",
            order_uuid: "4ae09e82-8870-4727-bb9f-0e8132f8576c",
            price: 65528.69,
            price_sources: [],
            processed_ms: 1711214697211,
          },
          {
            leverage: 0.1,
            order_type: "LONG",
            order_uuid: "e241d3f9-e28f-46c7-a6d8-82d7b8884f71",
            price: 65644.16,
            price_sources: [],
            processed_ms: 1711214925673,
          },
          {
            leverage: 0.1,
            order_type: "LONG",
            order_uuid: "a6a20c4f-87bb-4014-844d-79e4e856bbd9",
            price: 65789.1,
            price_sources: [],
            processed_ms: 1711215180299,
          },
          {
            leverage: 0.1,
            order_type: "LONG",
            order_uuid: "3aa5b82c-cc04-45a1-b2a5-c9fb3f514fca",
            price: 65931.17,
            price_sources: [],
            processed_ms: 1711215430392,
          },
          {
            leverage: 0.1,
            order_type: "FLAT",
            order_uuid: "f7305a6d-dcc2-4b65-a38c-b71b62fab70a",
            price: 65828.92,
            price_sources: [],
            processed_ms: 1711215655520,
          },
        ],
        position_type: "FLAT",
        position_uuid: "16de4786-b295-4ba0-bbff-3b011488513d",
        return_at_close: 1.000046344530202,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 65688.61600000001,
        close_ms: 1711217093470,
        current_return: 0.9991375158085808,
        initial_entry_price: 65668.45,
        is_closed_position: true,
        miner_hotkey: "5FzrMf85gr2tfvhLuiuiZB8Gj6CyXQddo52YGSdYGyDsfNWf",
        net_leverage: 0.0,
        open_ms: 1711215889443,
        orders: [
          {
            leverage: 0.1,
            order_type: "LONG",
            order_uuid: "7de1bad9-d42f-4685-9b62-4dcc073b8728",
            price: 65668.45,
            price_sources: [],
            processed_ms: 1711215889440,
          },
          {
            leverage: 0.1,
            order_type: "LONG",
            order_uuid: "6b096256-333f-4e88-8a3d-82cfff35785a",
            price: 65771.39,
            price_sources: [],
            processed_ms: 1711216137410,
          },
          {
            leverage: 0.1,
            order_type: "LONG",
            order_uuid: "d01b4c2e-92a3-490c-a7b3-b1276e4adf98",
            price: 65696.12,
            price_sources: [],
            processed_ms: 1711216376809,
          },
          {
            leverage: 0.1,
            order_type: "LONG",
            order_uuid: "ba2cd2bc-51c3-4491-9607-be696db5ef9c",
            price: 65643.91,
            price_sources: [],
            processed_ms: 1711216618069,
          },
          {
            leverage: 0.1,
            order_type: "LONG",
            order_uuid: "bf0cff13-90b4-443b-9d59-133718f30359",
            price: 65663.21,
            price_sources: [],
            processed_ms: 1711216864327,
          },
          {
            leverage: 0.1,
            order_type: "FLAT",
            order_uuid: "ce4e5961-b2f3-414a-a2c6-063d20974e17",
            price: 65575.34,
            price_sources: [],
            processed_ms: 1711217093470,
          },
        ],
        position_type: "FLAT",
        position_uuid: "9614ad80-4655-48ed-8a95-2ae9d7cb423d",
        return_at_close: 0.9981383782927722,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 65550.246,
        close_ms: 1711218532274,
        current_return: 0.9998012246560675,
        initial_entry_price: 65616.79,
        is_closed_position: true,
        miner_hotkey: "5FzrMf85gr2tfvhLuiuiZB8Gj6CyXQddo52YGSdYGyDsfNWf",
        net_leverage: 0.0,
        open_ms: 1711217346763,
        orders: [
          {
            leverage: 0.1,
            order_type: "LONG",
            order_uuid: "8555d52d-b697-478c-89c5-aa233bea7672",
            price: 65616.79,
            price_sources: [],
            processed_ms: 1711217346760,
          },
          {
            leverage: 0.1,
            order_type: "LONG",
            order_uuid: "6fd74263-b585-473a-895d-6002ee7d43f7",
            price: 65566.21,
            price_sources: [],
            processed_ms: 1711217572624,
          },
          {
            leverage: 0.1,
            order_type: "LONG",
            order_uuid: "c559cbe0-c372-4d4a-99c2-ebec454fd6ba",
            price: 65529.12,
            price_sources: [],
            processed_ms: 1711217828327,
          },
          {
            leverage: 0.1,
            order_type: "LONG",
            order_uuid: "aec392b8-4498-4444-ab4a-295297e1c821",
            price: 65516.37,
            price_sources: [],
            processed_ms: 1711218058504,
          },
          {
            leverage: 0.1,
            order_type: "LONG",
            order_uuid: "297a88a4-6dfe-4608-8cb6-70bdcdb08eb7",
            price: 65522.74,
            price_sources: [],
            processed_ms: 1711218305417,
          },
          {
            leverage: 0.1,
            order_type: "FLAT",
            order_uuid: "6567c0bd-c74d-46b9-a652-2ad749c215ab",
            price: 65524.16,
            price_sources: [],
            processed_ms: 1711218532274,
          },
        ],
        position_type: "FLAT",
        position_uuid: "a8d3b5b9-7ef3-44d8-8392-71b8acca6f2f",
        return_at_close: 0.9988014234314114,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 65421.376000000004,
        close_ms: 1711219976327,
        current_return: 1.000513103704277,
        initial_entry_price: 65516.97,
        is_closed_position: true,
        miner_hotkey: "5FzrMf85gr2tfvhLuiuiZB8Gj6CyXQddo52YGSdYGyDsfNWf",
        net_leverage: 0.0,
        open_ms: 1711218794275,
        orders: [
          {
            leverage: 0.1,
            order_type: "LONG",
            order_uuid: "17ffeed5-f3f2-4b2d-a088-b9c6c1c3843d",
            price: 65516.97,
            price_sources: [],
            processed_ms: 1711218794272,
          },
          {
            leverage: 0.1,
            order_type: "LONG",
            order_uuid: "d8e37b98-1c6f-495a-979a-b4b0da5c60af",
            price: 65428.96,
            price_sources: [],
            processed_ms: 1711219015607,
          },
          {
            leverage: 0.1,
            order_type: "LONG",
            order_uuid: "870f47f2-12fd-4c3c-bbf4-04d4ffe03b02",
            price: 65382.15,
            price_sources: [],
            processed_ms: 1711219255694,
          },
          {
            leverage: 0.1,
            order_type: "LONG",
            order_uuid: "3c7badb2-5079-46cb-a16f-54e0e63bbe4d",
            price: 65430.75,
            price_sources: [],
            processed_ms: 1711219498633,
          },
          {
            leverage: 0.1,
            order_type: "LONG",
            order_uuid: "a47f0c3a-ca13-481f-9fd6-8c9762502a45",
            price: 65348.05,
            price_sources: [],
            processed_ms: 1711219745884,
          },
          {
            leverage: 0.1,
            order_type: "FLAT",
            order_uuid: "87838367-4e41-4d8b-9a40-737fa81ab6c2",
            price: 65488.61,
            price_sources: [],
            processed_ms: 1711219976327,
          },
        ],
        position_type: "FLAT",
        position_uuid: "7c18beea-40ae-456b-a88d-be1bf01a0c91",
        return_at_close: 0.9995125906005726,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 65390.626000000004,
        close_ms: 1711221421433,
        current_return: 0.9993671265590854,
        initial_entry_price: 65373.26,
        is_closed_position: true,
        miner_hotkey: "5FzrMf85gr2tfvhLuiuiZB8Gj6CyXQddo52YGSdYGyDsfNWf",
        net_leverage: 0.0,
        open_ms: 1711220235369,
        orders: [
          {
            leverage: 0.1,
            order_type: "LONG",
            order_uuid: "440def2a-305d-4495-8ef4-e291119ccdb9",
            price: 65373.26,
            price_sources: [],
            processed_ms: 1711220235365,
          },
          {
            leverage: 0.1,
            order_type: "LONG",
            order_uuid: "d329eb72-5cfe-4b25-9fa4-5e62e884c64a",
            price: 65385.29,
            price_sources: [],
            processed_ms: 1711220457609,
          },
          {
            leverage: 0.1,
            order_type: "LONG",
            order_uuid: "6b5c042c-0515-4893-9a35-92022df0b97c",
            price: 65475.05,
            price_sources: [],
            processed_ms: 1711220695618,
          },
          {
            leverage: 0.1,
            order_type: "LONG",
            order_uuid: "07948d45-28ac-4e58-82cb-05dcb2e0b5ad",
            price: 65350.25,
            price_sources: [],
            processed_ms: 1711220938236,
          },
          {
            leverage: 0.1,
            order_type: "LONG",
            order_uuid: "4f0fa119-bd6e-426a-b38b-4311218f74c5",
            price: 65369.28,
            price_sources: [],
            processed_ms: 1711221185694,
          },
          {
            leverage: 0.1,
            order_type: "FLAT",
            order_uuid: "6cb07cd4-9794-4b75-ba7b-49224b3f2192",
            price: 65307.88,
            price_sources: [],
            processed_ms: 1711221421433,
          },
        ],
        position_type: "FLAT",
        position_uuid: "aae33b2d-aeba-4e74-9768-617a589cd4af",
        return_at_close: 0.9983677594325263,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 65187.64,
        close_ms: 1711222858428,
        current_return: 0.9982981349151058,
        initial_entry_price: 65310.7,
        is_closed_position: true,
        miner_hotkey: "5FzrMf85gr2tfvhLuiuiZB8Gj6CyXQddo52YGSdYGyDsfNWf",
        net_leverage: 0.0,
        open_ms: 1711221662666,
        orders: [
          {
            leverage: 0.1,
            order_type: "LONG",
            order_uuid: "ff950fdc-fb5d-4142-94ab-4894c3919976",
            price: 65310.7,
            price_sources: [],
            processed_ms: 1711221662662,
          },
          {
            leverage: 0.1,
            order_type: "LONG",
            order_uuid: "42c04151-a068-4e85-9038-c7219a95d1d4",
            price: 65309.55,
            price_sources: [],
            processed_ms: 1711221911265,
          },
          {
            leverage: 0.1,
            order_type: "LONG",
            order_uuid: "a630f71a-0917-4ddc-84ac-7b5e0fddcc0f",
            price: 65154.94,
            price_sources: [],
            processed_ms: 1711222140788,
          },
          {
            leverage: 0.1,
            order_type: "LONG",
            order_uuid: "36e1d266-e0ef-41d4-a74a-0710d8422de7",
            price: 65057.45,
            price_sources: [],
            processed_ms: 1711222378839,
          },
          {
            leverage: 0.1,
            order_type: "LONG",
            order_uuid: "cfd098d8-6c23-46d1-95f3-8c585cb6aff5",
            price: 65105.56,
            price_sources: [],
            processed_ms: 1711222625445,
          },
          {
            leverage: 0.1,
            order_type: "FLAT",
            order_uuid: "0bc0318f-d8fb-4e85-9605-e9571e753acf",
            price: 64965.34,
            price_sources: [],
            processed_ms: 1711222858428,
          },
        ],
        position_type: "FLAT",
        position_uuid: "4d04c131-13da-40c2-9a4d-5a1f275bb8b2",
        return_at_close: 0.9972998367801906,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 64923.484000000004,
        close_ms: 1711224303677,
        current_return: 1.0001978271069982,
        initial_entry_price: 64895.05,
        is_closed_position: true,
        miner_hotkey: "5FzrMf85gr2tfvhLuiuiZB8Gj6CyXQddo52YGSdYGyDsfNWf",
        net_leverage: 0.0,
        open_ms: 1711223099041,
        orders: [
          {
            leverage: 0.1,
            order_type: "LONG",
            order_uuid: "5e6ce7b5-24c4-4ced-b461-e6c1d7728505",
            price: 64895.05,
            price_sources: [],
            processed_ms: 1711223099038,
          },
          {
            leverage: 0.1,
            order_type: "LONG",
            order_uuid: "40d2fda2-b8c7-4d29-81bd-7aee00165e6d",
            price: 64929.71,
            price_sources: [],
            processed_ms: 1711223347531,
          },
          {
            leverage: 0.1,
            order_type: "LONG",
            order_uuid: "7578b19d-9350-4c72-88e8-484ceff0dedd",
            price: 64944.03,
            price_sources: [],
            processed_ms: 1711223591381,
          },
          {
            leverage: 0.1,
            order_type: "LONG",
            order_uuid: "c0a6a00f-8b24-429c-9ac8-cc28b34a38fa",
            price: 64973.43,
            price_sources: [],
            processed_ms: 1711223819105,
          },
          {
            leverage: 0.1,
            order_type: "LONG",
            order_uuid: "5158161c-6a8c-441d-b789-f3eb8d626b6b",
            price: 64875.2,
            price_sources: [],
            processed_ms: 1711224069526,
          },
          {
            leverage: 0.1,
            order_type: "FLAT",
            order_uuid: "c2690100-bac5-4a6d-af59-d8b5338afdce",
            price: 64949.16,
            price_sources: [],
            processed_ms: 1711224303677,
          },
        ],
        position_type: "FLAT",
        position_uuid: "0c5e122a-288a-45c8-ba22-5d9166dc9b68",
        return_at_close: 0.9991976292798912,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 64879.242000000006,
        close_ms: 1711225738757,
        current_return: 1.0003342855706283,
        initial_entry_price: 64836.78,
        is_closed_position: true,
        miner_hotkey: "5FzrMf85gr2tfvhLuiuiZB8Gj6CyXQddo52YGSdYGyDsfNWf",
        net_leverage: 0.0,
        open_ms: 1711224541359,
        orders: [
          {
            leverage: 0.1,
            order_type: "LONG",
            order_uuid: "ddb008aa-4605-4c55-bac4-d6574bb3dd5c",
            price: 64836.78,
            price_sources: [],
            processed_ms: 1711224541355,
          },
          {
            leverage: 0.1,
            order_type: "LONG",
            order_uuid: "6edfa554-9a6a-4c3e-9193-a4814d02dcc6",
            price: 64743.46,
            price_sources: [],
            processed_ms: 1711224789460,
          },
          {
            leverage: 0.1,
            order_type: "LONG",
            order_uuid: "44c2f234-e3bd-4802-a80d-1a77f4d56fa1",
            price: 64946.23,
            price_sources: [],
            processed_ms: 1711225024293,
          },
          {
            leverage: 0.1,
            order_type: "LONG",
            order_uuid: "af9fcc5b-89b7-4687-8c2a-9ad3d8579b91",
            price: 64910.59,
            price_sources: [],
            processed_ms: 1711225267828,
          },
          {
            leverage: 0.1,
            order_type: "LONG",
            order_uuid: "f502fd5b-58fb-4d9f-8da3-5e12048f78cc",
            price: 64959.15,
            price_sources: [],
            processed_ms: 1711225510339,
          },
          {
            leverage: 0.1,
            order_type: "FLAT",
            order_uuid: "89e5d51b-2c9a-4620-8fb0-97c9b01776ee",
            price: 64922.59,
            price_sources: [],
            processed_ms: 1711225738757,
          },
        ],
        position_type: "FLAT",
        position_uuid: "cc3b555c-a006-4846-b5a0-f9274f992d95",
        return_at_close: 0.9993339512850576,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 64986.606,
        close_ms: 1711227188195,
        current_return: 0.9998105883830924,
        initial_entry_price: 65032.97,
        is_closed_position: true,
        miner_hotkey: "5FzrMf85gr2tfvhLuiuiZB8Gj6CyXQddo52YGSdYGyDsfNWf",
        net_leverage: 0.0,
        open_ms: 1711225992298,
        orders: [
          {
            leverage: 0.1,
            order_type: "LONG",
            order_uuid: "c1b2ff04-1b2f-4d7d-875d-3f5a0264d3d9",
            price: 65032.97,
            price_sources: [],
            processed_ms: 1711225992295,
          },
          {
            leverage: 0.1,
            order_type: "LONG",
            order_uuid: "5b87b717-13ce-49d7-854e-146ae2c2c1b3",
            price: 64977.58,
            price_sources: [],
            processed_ms: 1711226240296,
          },
          {
            leverage: 0.1,
            order_type: "LONG",
            order_uuid: "7940798c-d4df-4543-b5d6-054471fa2412",
            price: 64985.37,
            price_sources: [],
            processed_ms: 1711226466412,
          },
          {
            leverage: 0.1,
            order_type: "LONG",
            order_uuid: "7af4aae9-4040-43bc-a14b-e7e8e4ece980",
            price: 64974.51,
            price_sources: [],
            processed_ms: 1711226706224,
          },
          {
            leverage: 0.1,
            order_type: "LONG",
            order_uuid: "1fe4c875-3c3e-4e90-b5ed-c407e10a1647",
            price: 64962.6,
            price_sources: [],
            processed_ms: 1711226952382,
          },
          {
            leverage: 0.1,
            order_type: "FLAT",
            order_uuid: "2abb4f00-4d6c-451d-b0fa-2ce23cb5f647",
            price: 64961.97,
            price_sources: [],
            processed_ms: 1711227188195,
          },
        ],
        position_type: "FLAT",
        position_uuid: "f3c354e7-ab48-4403-9e96-f8a7d9c9185f",
        return_at_close: 0.9988107777947094,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 64965.984000000004,
        close_ms: 1711228631260,
        current_return: 0.9993959304334141,
        initial_entry_price: 65070.32,
        is_closed_position: true,
        miner_hotkey: "5FzrMf85gr2tfvhLuiuiZB8Gj6CyXQddo52YGSdYGyDsfNWf",
        net_leverage: 0.0,
        open_ms: 1711227445499,
        orders: [
          {
            leverage: 0.1,
            order_type: "LONG",
            order_uuid: "26ee1490-81eb-49aa-8940-9af2ed4aad3c",
            price: 65070.32,
            price_sources: [],
            processed_ms: 1711227443499,
          },
          {
            leverage: 0.1,
            order_type: "LONG",
            order_uuid: "910039a4-e929-4d78-88dd-1a720aacc299",
            price: 65008.18,
            price_sources: [],
            processed_ms: 1711227665123,
          },
          {
            leverage: 0.1,
            order_type: "LONG",
            order_uuid: "c607e817-2fc1-48ff-b1a0-2a7fe58f147f",
            price: 64901.36,
            price_sources: [],
            processed_ms: 1711227921408,
          },
          {
            leverage: 0.1,
            order_type: "LONG",
            order_uuid: "63c9c805-6048-4551-a888-1fca9f1517d4",
            price: 64909.31,
            price_sources: [],
            processed_ms: 1711228148502,
          },
          {
            leverage: 0.1,
            order_type: "LONG",
            order_uuid: "d43e5ace-5930-4b61-86dd-52e6533285a6",
            price: 64940.75,
            price_sources: [],
            processed_ms: 1711228395154,
          },
          {
            leverage: 0.1,
            order_type: "FLAT",
            order_uuid: "76eec480-f747-4e0c-94b3-2246335f4729",
            price: 64887.37,
            price_sources: [],
            processed_ms: 1711228631260,
          },
        ],
        position_type: "FLAT",
        position_uuid: "7a782cf4-dedf-4d72-b71b-5a68cc4b13f1",
        return_at_close: 0.9983965345029807,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 64919.934,
        close_ms: 1711230087467,
        current_return: 0.998909824594155,
        initial_entry_price: 64922.58,
        is_closed_position: true,
        miner_hotkey: "5FzrMf85gr2tfvhLuiuiZB8Gj6CyXQddo52YGSdYGyDsfNWf",
        net_leverage: 0.0,
        open_ms: 1711228878449,
        orders: [
          {
            leverage: 0.1,
            order_type: "LONG",
            order_uuid: "72db0541-7270-4e51-b150-dbb2080bfd60",
            price: 64922.58,
            price_sources: [],
            processed_ms: 1711228878446,
          },
          {
            leverage: 0.1,
            order_type: "LONG",
            order_uuid: "8cd9b73e-03b9-4506-9eea-de5dbfe72950",
            price: 64912.67,
            price_sources: [],
            processed_ms: 1711229125384,
          },
          {
            leverage: 0.1,
            order_type: "LONG",
            order_uuid: "18cd6798-72ed-47ac-b44a-d9d8a8b166b1",
            price: 64901.42,
            price_sources: [],
            processed_ms: 1711229342432,
          },
          {
            leverage: 0.1,
            order_type: "LONG",
            order_uuid: "3ecac4e6-3ee4-4ad9-9c8f-01fb9cdc9356",
            price: 64914.6,
            price_sources: [],
            processed_ms: 1711229602438,
          },
          {
            leverage: 0.1,
            order_type: "LONG",
            order_uuid: "7c5a40be-bf55-4c73-a023-bebe327751fa",
            price: 64948.4,
            price_sources: [],
            processed_ms: 1711229823859,
          },
          {
            leverage: 0.1,
            order_type: "FLAT",
            order_uuid: "d82397ac-5de2-481b-a5cc-111a13ad4547",
            price: 64778.38,
            price_sources: [],
            processed_ms: 1711230087467,
          },
        ],
        position_type: "FLAT",
        position_uuid: "fe31d666-f125-4046-9a5a-e9575c4cee8f",
        return_at_close: 0.9979109147695608,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
    ],
    thirty_day_returns: 1.0,
  },
  "5G1NjW9YhXLadMWajvTkfcJy6up3yH2q1YzMXDTi6ijanChe": {
    all_time_returns: 1.0,
    n_positions: 0,
    percentage_profitable: 0.0,
    positions: [
      {
        average_entry_price: 70517.04666666666,
        close_ms: 1711483348427,
        current_return: 0.9026575402618213,
        initial_entry_price: 70551.33,
        is_closed_position: true,
        miner_hotkey: "5G1NjW9YhXLadMWajvTkfcJy6up3yH2q1YzMXDTi6ijanChe",
        net_leverage: 6.0,
        open_ms: 1711462198697,
        orders: [
          {
            leverage: 1.0,
            order_type: "LONG",
            order_uuid: "ff7b1e7d-55ae-4d92-9527-5c62f5612fc6",
            price: 70551.33,
            price_sources: [],
            processed_ms: 1711462198697,
          },
          {
            leverage: 1.0,
            order_type: "LONG",
            order_uuid: "7c4c1886-4d9e-48f8-aa0c-6e475c461f4b",
            price: 70551.33,
            price_sources: [],
            processed_ms: 1711462203091,
          },
          {
            leverage: 1.0,
            order_type: "LONG",
            order_uuid: "dba0d71c-9afd-41bc-aaee-f0886f28ccae",
            price: 70551.33,
            price_sources: [],
            processed_ms: 1711462203503,
          },
          {
            leverage: 1.0,
            order_type: "LONG",
            order_uuid: "ccca8092-0df2-42ca-93d2-4eccf013040c",
            price: 70551.33,
            price_sources: [],
            processed_ms: 1711462203847,
          },
          {
            leverage: 1.0,
            order_type: "LONG",
            order_uuid: "d061d7c4-2d59-48ad-8162-52863a891ed1",
            price: 70448.48,
            price_sources: [],
            processed_ms: 1711462244488,
          },
          {
            leverage: 1.0,
            order_type: "LONG",
            order_uuid: "6f8a64c0-b2b8-4095-a327-a0f2e3bf2b0a",
            price: 70448.48,
            price_sources: [],
            processed_ms: 1711462245017,
          },
        ],
        position_type: "FLAT",
        position_uuid: "1761a1d7-6752-488b-b152-0e19301ac30a",
        return_at_close: 0.8918256497786795,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
    ],
    thirty_day_returns: 1.0,
  },
  "5G1WnkYCCv7puTgxfvjBqush8eoshaRym6mgWfonHnuX8gWv": {
    all_time_returns: 1.0085775943077158,
    n_positions: 8,
    percentage_profitable: 0.625,
    positions: [
      {
        average_entry_price: 195.173,
        close_ms: 1715351019549,
        current_return: 1.0001024731904515,
        initial_entry_price: 195.173,
        is_closed_position: true,
        miner_hotkey: "5G1WnkYCCv7puTgxfvjBqush8eoshaRym6mgWfonHnuX8gWv",
        net_leverage: 0.0,
        open_ms: 1715338461275,
        orders: [
          {
            leverage: -0.1,
            order_type: "SHORT",
            order_uuid: "49e485a5-1fa2-40e6-b348-42e966c01598",
            price: 195.173,
            price_sources: [],
            processed_ms: 1715338460644,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "4ba517cb-6f1b-4887-9a9c-6bcb2937017a",
            price: 194.973,
            price_sources: [],
            processed_ms: 1715351019549,
          },
        ],
        position_type: "FLAT",
        position_uuid: "14e7ff6d-8781-4e8c-abf2-fd2cbaf572df",
        return_at_close: 1.0000954724731392,
        trade_pair: ["GBPJPY", "GBP/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 2914.22,
        close_ms: 1716461430099,
        current_return: 0.9996817398823699,
        initial_entry_price: 2914.22,
        is_closed_position: true,
        miner_hotkey: "5G1WnkYCCv7puTgxfvjBqush8eoshaRym6mgWfonHnuX8gWv",
        net_leverage: 0.0,
        open_ms: 1715389022847,
        orders: [
          {
            leverage: -0.001,
            order_type: "SHORT",
            order_uuid: "42b0dede-0458-44d4-9ecd-0efcce9564a3",
            price: 2914.22,
            price_sources: [],
            processed_ms: 1715389022234,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "7b43a188-e7dd-4283-890d-a7780c6ad1d8",
            price: 3841.7,
            price_sources: [],
            processed_ms: 1716461430099,
          },
        ],
        position_type: "FLAT",
        position_uuid: "435dfdf6-66b2-49b4-a01f-374ae8a546ce",
        return_at_close: 0.9996807402006299,
        trade_pair: ["ETHUSD", "ETH/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 0.8553375000000001,
        close_ms: 1718275890510,
        current_return: 1.0070369050470587,
        initial_entry_price: 0.855845,
        is_closed_position: true,
        miner_hotkey: "5G1WnkYCCv7puTgxfvjBqush8eoshaRym6mgWfonHnuX8gWv",
        net_leverage: 0.0,
        open_ms: 1716160540868,
        orders: [
          {
            leverage: -0.5,
            order_type: "SHORT",
            order_uuid: "a796739d-ad14-4975-ba4f-6756de55e9da",
            price: 0.855845,
            price_sources: [],
            processed_ms: 1716160540447,
          },
          {
            leverage: -1.0,
            order_type: "SHORT",
            order_uuid: "4d5f3dbc-a7f7-4c5f-993c-8cf57b9e5b9d",
            price: 0.8535200000000001,
            price_sources: [],
            processed_ms: 1716304686821,
          },
          {
            leverage: 0.9,
            order_type: "LONG",
            order_uuid: "0949b0ca-b734-4f45-b233-877d37af539d",
            price: 0.8536,
            price_sources: [],
            processed_ms: 1717158095553,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "7906cf90-7bc4-4c0f-9e9a-be6d772f8a97",
            price: 0.8453,
            price_sources: [],
            processed_ms: 1718275890510,
          },
        ],
        position_type: "FLAT",
        position_uuid: "4e058083-2d76-48f7-92cb-85953023aecf",
        return_at_close: 1.0069311661720286,
        trade_pair: ["EURGBP", "EUR/GBP", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 2937.000000000009,
        close_ms: 1718275901268,
        current_return: 1.0043950848449386,
        initial_entry_price: 3845.25,
        is_closed_position: true,
        miner_hotkey: "5G1WnkYCCv7puTgxfvjBqush8eoshaRym6mgWfonHnuX8gWv",
        net_leverage: 0.0,
        open_ms: 1716461450368,
        orders: [
          {
            leverage: 2.0,
            order_type: "LONG",
            order_uuid: "0b19f145-10db-4e2a-9c43-84b00974c3a9",
            price: 3845.25,
            price_sources: [],
            processed_ms: 1716461450158,
          },
          {
            leverage: -1.0,
            order_type: "SHORT",
            order_uuid: "ceaf6072-0f27-42b3-a8df-ced50c47f047",
            price: 3924.64,
            price_sources: [],
            processed_ms: 1716467534202,
          },
          {
            leverage: -0.7,
            order_type: "SHORT",
            order_uuid: "48c1ad9a-fce4-4abd-aa43-bf0a5e56d4d6",
            price: 3783.2,
            price_sources: [],
            processed_ms: 1716473097903,
          },
          {
            leverage: -0.27,
            order_type: "SHORT",
            order_uuid: "5a9a8196-3a0c-4282-a943-fad9176cbc08",
            price: 3813.0,
            price_sources: [],
            processed_ms: 1717158123091,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "1e580f90-c1c1-40a8-abb1-c4e551c2163f",
            price: 3500.34,
            price_sources: [],
            processed_ms: 1718275901268,
          },
        ],
        position_type: "FLAT",
        position_uuid: "4be033f3-8c85-4afa-802d-e07d05d81baf",
        return_at_close: 1.0023862946752486,
        trade_pair: ["ETHUSD", "ETH/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 155.753,
        close_ms: 1718275912076,
        current_return: 1.000914589125089,
        initial_entry_price: 155.753,
        is_closed_position: true,
        miner_hotkey: "5G1WnkYCCv7puTgxfvjBqush8eoshaRym6mgWfonHnuX8gWv",
        net_leverage: 0.0,
        open_ms: 1715338444247,
        orders: [
          {
            leverage: 0.1,
            order_type: "LONG",
            order_uuid: "d1413401-3d4f-45d4-a5a8-b64a06231df0",
            price: 155.753,
            price_sources: [],
            processed_ms: 1715338444246,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "53246d46-b8cd-4f91-8c6e-ce99805496dc",
            price: 157.1775,
            price_sources: [],
            processed_ms: 1718275912076,
          },
        ],
        position_type: "FLAT",
        position_uuid: "9f1f5a1c-16d8-4754-afb7-2c8cd53b9713",
        return_at_close: 1.0009075827229652,
        trade_pair: ["USDJPY", "USD/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 159.487,
        close_ms: 1719304871780,
        current_return: 0.9999992789380953,
        initial_entry_price: 159.487,
        is_closed_position: true,
        miner_hotkey: "5G1WnkYCCv7puTgxfvjBqush8eoshaRym6mgWfonHnuX8gWv",
        net_leverage: 0.0,
        open_ms: 1719304797996,
        orders: [
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "78316e70-7b07-4cd7-96f7-d4035aed946b",
            price: 159.487,
            price_sources: [],
            processed_ms: 1719304797986,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "09f03b5d-9636-4c58-b564-6270d884f69c",
            price: 159.4755,
            price_sources: [],
            processed_ms: 1719304871780,
          },
        ],
        position_type: "FLAT",
        position_uuid: "c8662e23-382d-424c-9cca-d239bb45d61d",
        return_at_close: 0.9999985789386,
        trade_pair: ["USDJPY", "USD/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 61263.26,
        close_ms: 1719482996783,
        current_return: 0.998850403977849,
        initial_entry_price: 61263.26,
        is_closed_position: true,
        miner_hotkey: "5G1WnkYCCv7puTgxfvjBqush8eoshaRym6mgWfonHnuX8gWv",
        net_leverage: 0.0,
        open_ms: 1719482462038,
        orders: [
          {
            leverage: 0.6,
            order_type: "LONG",
            order_uuid: "b0570375-48af-4fbe-b5c5-4225af0a1b26",
            price: 61263.26,
            price_sources: [],
            processed_ms: 1719482460315,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "f5430fa2-4709-4883-847a-97b26551bbd1",
            price: 61145.88,
            price_sources: [],
            processed_ms: 1719482996783,
          },
        ],
        position_type: "FLAT",
        position_uuid: "3b072670-a713-4179-a4ae-2d1939041105",
        return_at_close: 0.9982510937354622,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 61155.49999999999,
        close_ms: 1719485706596,
        current_return: 1.0015207135907647,
        initial_entry_price: 61155.5,
        is_closed_position: true,
        miner_hotkey: "5G1WnkYCCv7puTgxfvjBqush8eoshaRym6mgWfonHnuX8gWv",
        net_leverage: 0.0,
        open_ms: 1719483678363,
        orders: [
          {
            leverage: 1.2,
            order_type: "LONG",
            order_uuid: "6c4cd671-cad5-4498-bd7f-233fd6b4b002",
            price: 61155.5,
            price_sources: [],
            processed_ms: 1719483675846,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "fa6a2ad6-ff1b-45d0-a902-f1cb6e22364d",
            price: 61233.0,
            price_sources: [],
            processed_ms: 1719485706596,
          },
        ],
        position_type: "FLAT",
        position_uuid: "c8b01063-5b7d-4a91-9749-92b193c41d01",
        return_at_close: 1.0003188887344558,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 3465.0760000000005,
        close_ms: 0,
        current_return: 0.9287535500979984,
        initial_entry_price: 3454.13,
        is_closed_position: false,
        miner_hotkey: "5G1WnkYCCv7puTgxfvjBqush8eoshaRym6mgWfonHnuX8gWv",
        net_leverage: 0.75,
        open_ms: 1719577595212,
        orders: [
          {
            leverage: 0.9,
            order_type: "LONG",
            order_uuid: "8d52694f-dfaf-42c7-a139-8ae0b0271108",
            price: 3454.13,
            price_sources: [],
            processed_ms: 1719577594515,
          },
          {
            leverage: -0.15,
            order_type: "SHORT",
            order_uuid: "4d5aad1e-afdd-4a20-92d2-37db71c8c761",
            price: 3399.4,
            price_sources: [],
            processed_ms: 1719593848647,
          },
        ],
        position_type: "LONG",
        position_uuid: "c95daaa5-10a3-42f5-ab1a-8ea000b9c5bd",
        return_at_close: 0.928265954484197,
        trade_pair: ["ETHUSD", "ETH/USD", 0.003, 0.001, 20],
      },
    ],
    thirty_day_returns: 0.9985680057349479,
  },
  "5G1naqqXwAurzAHo64nLoesygBvfghWtDbHAa7rRtbVDyMM4": {
    all_time_returns: 1.0,
    n_positions: 0,
    percentage_profitable: 0.0,
    positions: [
      {
        average_entry_price: 65146.82,
        close_ms: 1711214152691,
        current_return: 0.9982424161302117,
        initial_entry_price: 65146.82,
        is_closed_position: true,
        miner_hotkey: "5G1naqqXwAurzAHo64nLoesygBvfghWtDbHAa7rRtbVDyMM4",
        net_leverage: 0.0,
        open_ms: 1711210586409,
        orders: [
          {
            leverage: -0.3,
            order_type: "SHORT",
            order_uuid: "03308a61-4062-41f3-9020-5b9cfb56c327",
            price: 65146.82,
            price_sources: [],
            processed_ms: 1711210586409,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "d515c691-ea17-44b2-852e-e9fcf14fcd08",
            price: 65528.49,
            price_sources: [],
            processed_ms: 1711214152691,
          },
        ],
        position_type: "FLAT",
        position_uuid: "eaa44ff7-62f4-4b36-84d7-7a9734f263e0",
        return_at_close: 0.9976434706805335,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 65531.049999999996,
        close_ms: 1711221484870,
        current_return: 1.001234956558761,
        initial_entry_price: 65531.05,
        is_closed_position: true,
        miner_hotkey: "5G1naqqXwAurzAHo64nLoesygBvfghWtDbHAa7rRtbVDyMM4",
        net_leverage: 0.0,
        open_ms: 1711217905699,
        orders: [
          {
            leverage: -0.3,
            order_type: "SHORT",
            order_uuid: "7795f432-0d15-44cf-9835-d374a2bcebc0",
            price: 65531.05,
            price_sources: [],
            processed_ms: 1711217905698,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "144242e2-2b62-40f7-8182-9a903ef7c3a9",
            price: 65261.29,
            price_sources: [],
            processed_ms: 1711221484870,
          },
        ],
        position_type: "FLAT",
        position_uuid: "2f884922-c3ae-412b-892c-8d768e8a562a",
        return_at_close: 1.0006342155848258,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 65319.67,
        close_ms: 1711225140734,
        current_return: 1.0018972845392513,
        initial_entry_price: 65319.67,
        is_closed_position: true,
        miner_hotkey: "5G1naqqXwAurzAHo64nLoesygBvfghWtDbHAa7rRtbVDyMM4",
        net_leverage: 0.0,
        open_ms: 1711221570533,
        orders: [
          {
            leverage: -0.3,
            order_type: "SHORT",
            order_uuid: "372158f7-539c-4646-9c9d-453ba4150d49",
            price: 65319.67,
            price_sources: [],
            processed_ms: 1711221570533,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "b9f300c4-98ca-4012-b1f9-08d79ba8b7b3",
            price: 64906.57,
            price_sources: [],
            processed_ms: 1711225140734,
          },
        ],
        position_type: "FLAT",
        position_uuid: "60136288-12c5-44c3-a765-99519d1fe261",
        return_at_close: 1.0012961461685277,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 64910.590000000004,
        close_ms: 1711228806856,
        current_return: 0.9996599322236942,
        initial_entry_price: 64910.59,
        is_closed_position: true,
        miner_hotkey: "5G1naqqXwAurzAHo64nLoesygBvfghWtDbHAa7rRtbVDyMM4",
        net_leverage: 0.0,
        open_ms: 1711225227414,
        orders: [
          {
            leverage: -0.3,
            order_type: "SHORT",
            order_uuid: "a5d1cfba-28ff-48a1-b83b-d54a90190e32",
            price: 64910.59,
            price_sources: [],
            processed_ms: 1711225227414,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "4910c245-64f2-4dfa-a83b-e9ef12123c70",
            price: 64984.17,
            price_sources: [],
            processed_ms: 1711228806856,
          },
        ],
        position_type: "FLAT",
        position_uuid: "65bdf2fd-42e5-4f7a-bb6f-c6d294ca9800",
        return_at_close: 0.99906013626436,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 64922.58000000001,
        close_ms: 1711232458646,
        current_return: 1.000358396108103,
        initial_entry_price: 64922.58,
        is_closed_position: true,
        miner_hotkey: "5G1naqqXwAurzAHo64nLoesygBvfghWtDbHAa7rRtbVDyMM4",
        net_leverage: 0.0,
        open_ms: 1711228892575,
        orders: [
          {
            leverage: -0.3,
            order_type: "SHORT",
            order_uuid: "2e7f8308-7de9-4838-ae61-e6855c361d48",
            price: 64922.58,
            price_sources: [],
            processed_ms: 1711228892574,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "ae60af21-7319-4203-a4f8-d7122fe78465",
            price: 64845.02,
            price_sources: [],
            processed_ms: 1711232458646,
          },
        ],
        position_type: "FLAT",
        position_uuid: "dfbb1042-b266-42d2-8af3-053c249b7e08",
        return_at_close: 0.9997581810704381,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 64851.79,
        close_ms: 1711236128136,
        current_return: 0.999594074427244,
        initial_entry_price: 64851.79,
        is_closed_position: true,
        miner_hotkey: "5G1naqqXwAurzAHo64nLoesygBvfghWtDbHAa7rRtbVDyMM4",
        net_leverage: 0.0,
        open_ms: 1711232545498,
        orders: [
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "c119f26d-5447-49f5-b743-7c365927f6f8",
            price: 64851.79,
            price_sources: [],
            processed_ms: 1711232545497,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "64267e78-4a71-4955-a496-aa904497f58d",
            price: 64764.04,
            price_sources: [],
            processed_ms: 1711236128136,
          },
        ],
        position_type: "FLAT",
        position_uuid: "57b19ac8-6faf-4c67-972e-a449dabd3555",
        return_at_close: 0.9989943179825876,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 64370.770000000004,
        close_ms: 1711250772566,
        current_return: 0.9991015953358955,
        initial_entry_price: 64370.77,
        is_closed_position: true,
        miner_hotkey: "5G1naqqXwAurzAHo64nLoesygBvfghWtDbHAa7rRtbVDyMM4",
        net_leverage: 0.0,
        open_ms: 1711247194474,
        orders: [
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "dc0aa742-29f5-4114-864c-5295e74f9553",
            price: 64370.77,
            price_sources: [],
            processed_ms: 1711247194473,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "ba30df79-8269-40e5-bc64-3120500db628",
            price: 64178.0,
            price_sources: [],
            processed_ms: 1711250772566,
          },
        ],
        position_type: "FLAT",
        position_uuid: "4c0b0623-efbe-4de5-89eb-e6786c070e93",
        return_at_close: 0.998502134378694,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 64195.83,
        close_ms: 1711254436474,
        current_return: 0.9985845809610998,
        initial_entry_price: 64195.83,
        is_closed_position: true,
        miner_hotkey: "5G1naqqXwAurzAHo64nLoesygBvfghWtDbHAa7rRtbVDyMM4",
        net_leverage: 0.0,
        open_ms: 1711250859780,
        orders: [
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "342dad03-0503-42d2-ace3-e71074a5c6e0",
            price: 64195.83,
            price_sources: [],
            processed_ms: 1711250859779,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "eb8e27b9-a672-4ec2-9ec8-0a2c9e40ace5",
            price: 63892.95,
            price_sources: [],
            processed_ms: 1711254436474,
          },
        ],
        position_type: "FLAT",
        position_uuid: "0d1ea5d0-b8f1-455a-a878-7e1018f1352e",
        return_at_close: 0.9979854302125231,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 63900.11,
        close_ms: 1711258301887,
        current_return: 1.0018230485049242,
        initial_entry_price: 63900.11,
        is_closed_position: true,
        miner_hotkey: "5G1naqqXwAurzAHo64nLoesygBvfghWtDbHAa7rRtbVDyMM4",
        net_leverage: 0.0,
        open_ms: 1711254539536,
        orders: [
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "7355f9a7-601b-4e0a-ab1a-16ddb51103da",
            price: 63900.11,
            price_sources: [],
            processed_ms: 1711254539534,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "3c42ea27-8998-474c-a48a-22f2afd2f412",
            price: 64288.42,
            price_sources: [],
            processed_ms: 1711258301887,
          },
        ],
        position_type: "FLAT",
        position_uuid: "965ffd6f-a2d4-43ee-9945-4261101dc23e",
        return_at_close: 1.0012219546758212,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 64354.27,
        close_ms: 1711261960643,
        current_return: 0.9983002293398714,
        initial_entry_price: 64354.27,
        is_closed_position: true,
        miner_hotkey: "5G1naqqXwAurzAHo64nLoesygBvfghWtDbHAa7rRtbVDyMM4",
        net_leverage: 0.0,
        open_ms: 1711258430866,
        orders: [
          {
            leverage: 1.25,
            order_type: "LONG",
            order_uuid: "d55611c6-34f8-4284-9ed3-212694ee82bf",
            price: 64354.27,
            price_sources: [],
            processed_ms: 1711258430864,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "0f58aafd-9792-4566-a751-986f5534b0a0",
            price: 64266.76,
            price_sources: [],
            processed_ms: 1711261960643,
          },
        ],
        position_type: "FLAT",
        position_uuid: "9151ff37-411c-42a1-ab13-6acb63bdb100",
        return_at_close: 0.9958044787665218,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 64208.12000000001,
        close_ms: 1711265628789,
        current_return: 0.9975552157577575,
        initial_entry_price: 64208.12,
        is_closed_position: true,
        miner_hotkey: "5G1naqqXwAurzAHo64nLoesygBvfghWtDbHAa7rRtbVDyMM4",
        net_leverage: 0.0,
        open_ms: 1711262075445,
        orders: [
          {
            leverage: 1.25,
            order_type: "LONG",
            order_uuid: "0e7567da-d34c-4d9f-b90d-036c2e3edfcf",
            price: 64208.12,
            price_sources: [],
            processed_ms: 1711262075444,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "ab783c91-ba05-476f-8812-744a40111360",
            price: 64082.54,
            price_sources: [],
            processed_ms: 1711265628789,
          },
        ],
        position_type: "FLAT",
        position_uuid: "f6930858-a8dc-403d-acb5-9164264c02ea",
        return_at_close: 0.9950613277183632,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 64096.58,
        close_ms: 1711269283819,
        current_return: 1.012526409365367,
        initial_entry_price: 64096.58,
        is_closed_position: true,
        miner_hotkey: "5G1naqqXwAurzAHo64nLoesygBvfghWtDbHAa7rRtbVDyMM4",
        net_leverage: 0.0,
        open_ms: 1711265739840,
        orders: [
          {
            leverage: 1.25,
            order_type: "LONG",
            order_uuid: "9d01a23d-48a0-4e3b-bb9b-0533954142e2",
            price: 64096.58,
            price_sources: [],
            processed_ms: 1711265739839,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "a66b3f64-9c57-4425-94b0-1d950eaa566c",
            price: 64738.9,
            price_sources: [],
            processed_ms: 1711269283819,
          },
        ],
        position_type: "FLAT",
        position_uuid: "cda5b537-be42-47f9-b00e-909c719adc87",
        return_at_close: 1.0099950933419537,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 64778.62000000001,
        close_ms: 1711272943188,
        current_return: 1.0040103895390173,
        initial_entry_price: 64778.62,
        is_closed_position: true,
        miner_hotkey: "5G1naqqXwAurzAHo64nLoesygBvfghWtDbHAa7rRtbVDyMM4",
        net_leverage: 0.0,
        open_ms: 1711269370576,
        orders: [
          {
            leverage: 1.25,
            order_type: "LONG",
            order_uuid: "4168a911-61f7-4dd1-98f9-2277426007cc",
            price: 64778.62,
            price_sources: [],
            processed_ms: 1711269370575,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "2e5e7225-8123-45ab-8c02-98c0c0d42595",
            price: 64986.45,
            price_sources: [],
            processed_ms: 1711272943188,
          },
        ],
        position_type: "FLAT",
        position_uuid: "a0265e65-fb02-41c9-afe0-247f35169328",
        return_at_close: 1.0015003635651698,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 65035.1,
        close_ms: 1711285387467,
        current_return: 1.013808697149693,
        initial_entry_price: 65035.1,
        is_closed_position: true,
        miner_hotkey: "5G1naqqXwAurzAHo64nLoesygBvfghWtDbHAa7rRtbVDyMM4",
        net_leverage: 0.0,
        open_ms: 1711276698614,
        orders: [
          {
            leverage: 1.25,
            order_type: "LONG",
            order_uuid: "635612b8-fee6-4a2f-aafd-470c9d9120d2",
            price: 65035.1,
            price_sources: [],
            processed_ms: 1711276698613,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "bc6c2251-70e2-45e9-9c83-788c8b794c8f",
            price: 65753.54,
            price_sources: [],
            processed_ms: 1711285387467,
          },
        ],
        position_type: "FLAT",
        position_uuid: "ef700e97-2954-411e-ba3a-6598fd5a8419",
        return_at_close: 1.0112741754068186,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 65679.48333333334,
        close_ms: 1711300038230,
        current_return: 1.0457190603785524,
        initial_entry_price: 65799.69,
        is_closed_position: true,
        miner_hotkey: "5G1naqqXwAurzAHo64nLoesygBvfghWtDbHAa7rRtbVDyMM4",
        net_leverage: 0.0,
        open_ms: 1711296361755,
        orders: [
          {
            leverage: -1.25,
            order_type: "SHORT",
            order_uuid: "b84e70e9-ba61-4290-a1c9-47f542b3e765",
            price: 65799.69,
            price_sources: [],
            processed_ms: 1711296361754,
          },
          {
            leverage: -2.5,
            order_type: "SHORT",
            order_uuid: "5f4cbe22-95b4-41a7-b511-686a1d4ea03d",
            price: 65619.38,
            price_sources: [],
            processed_ms: 1711298190782,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "2561c149-12f2-495a-9a92-4b97786a703b",
            price: 64877.27,
            price_sources: [],
            processed_ms: 1711300038230,
          },
        ],
        position_type: "FLAT",
        position_uuid: "a15d5d1a-8e24-4bfa-8044-45949fa39e4b",
        return_at_close: 1.0378761674257133,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 58742.2800000001,
        close_ms: 1711315796659,
        current_return: 0.9963750562654042,
        initial_entry_price: 65870.85,
        is_closed_position: true,
        miner_hotkey: "5G1naqqXwAurzAHo64nLoesygBvfghWtDbHAa7rRtbVDyMM4",
        net_leverage: 0.0,
        open_ms: 1711309304002,
        orders: [
          {
            leverage: -1.25,
            order_type: "SHORT",
            order_uuid: "e787b90a-5a1f-499d-9e53-871bc332c211",
            price: 65870.85,
            price_sources: [],
            processed_ms: 1711309304001,
          },
          {
            leverage: 0.625,
            order_type: "LONG",
            order_uuid: "53c0bb61-22ad-46da-948c-8c7b6ca78ac6",
            price: 65880.9,
            price_sources: [],
            processed_ms: 1711310206329,
          },
          {
            leverage: 0.125,
            order_type: "LONG",
            order_uuid: "e1271633-407e-4c1f-a78d-702b774a25d9",
            price: 66105.74,
            price_sources: [],
            processed_ms: 1711311140714,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "ecc5d894-8ce8-4f7f-a335-79b386c378fe",
            price: 66297.59,
            price_sources: [],
            processed_ms: 1711312074665,
          },
          {
            leverage: 0.125,
            order_type: "LONG",
            order_uuid: "b6ca81af-d75e-4697-b2d2-30aa04c86608",
            price: 66278.62,
            price_sources: [],
            processed_ms: 1711313014313,
          },
          {
            leverage: 0.0625,
            order_type: "LONG",
            order_uuid: "32ae94bf-14b1-4f33-89c7-f8418703f5d6",
            price: 66117.9,
            price_sources: [],
            processed_ms: 1711313934815,
          },
          {
            leverage: 0.03125,
            order_type: "LONG",
            order_uuid: "26375b1d-a51f-4a02-a68c-6a0ed5029d6d",
            price: 66319.76,
            price_sources: [],
            processed_ms: 1711314872083,
          },
          {
            leverage: 0.125,
            order_type: "LONG",
            order_uuid: "3da8d297-e122-4ce2-8b04-b1b35fa5abf9",
            price: 66383.18,
            price_sources: [],
            processed_ms: 1711315796659,
          },
        ],
        position_type: "FLAT",
        position_uuid: "a15cc0f1-8a66-4a2e-80d5-53c3ef736158",
        return_at_close: 0.9938841186247408,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 60910.71714285699,
        close_ms: 1711322326035,
        current_return: 0.9893841361000059,
        initial_entry_price: 66301.54,
        is_closed_position: true,
        miner_hotkey: "5G1naqqXwAurzAHo64nLoesygBvfghWtDbHAa7rRtbVDyMM4",
        net_leverage: 0.0,
        open_ms: 1711316745216,
        orders: [
          {
            leverage: -1.25,
            order_type: "SHORT",
            order_uuid: "f860b0d2-1dfe-4efc-bb87-f3780d1f73e4",
            price: 66301.54,
            price_sources: [],
            processed_ms: 1711316745215,
          },
          {
            leverage: 0.125,
            order_type: "LONG",
            order_uuid: "8ca2fc54-d4ed-4725-a75a-e0b14410075c",
            price: 66514.11,
            price_sources: [],
            processed_ms: 1711317657725,
          },
          {
            leverage: 0.5625,
            order_type: "LONG",
            order_uuid: "2b9988c9-5ab8-4d41-a217-46a39a334a4c",
            price: 66796.57,
            price_sources: [],
            processed_ms: 1711318594774,
          },
          {
            leverage: 0.125,
            order_type: "LONG",
            order_uuid: "79861868-e2d4-4a1a-b203-0960c2481597",
            price: 66952.24,
            price_sources: [],
            processed_ms: 1711319520799,
          },
          {
            leverage: 0.21875,
            order_type: "LONG",
            order_uuid: "38b48d56-5333-493c-87e1-60d8b33447c7",
            price: 66879.37,
            price_sources: [],
            processed_ms: 1711320461861,
          },
          {
            leverage: 0.109375,
            order_type: "LONG",
            order_uuid: "41f16138-2ea1-421f-8c49-df01a09c43d3",
            price: 67004.24,
            price_sources: [],
            processed_ms: 1711321387221,
          },
          {
            leverage: 0.125,
            order_type: "LONG",
            order_uuid: "d6a4766f-c04b-4804-9eb0-8669f561e4ac",
            price: 67345.9,
            price_sources: [],
            processed_ms: 1711322326035,
          },
        ],
        position_type: "FLAT",
        position_uuid: "41f0c243-dd87-4a1a-ab2c-9ec95f70e9ad",
        return_at_close: 0.9869106757597559,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 75179.41428571376,
        close_ms: 1711337690853,
        current_return: 1.0027213903999328,
        initial_entry_price: 66841.2,
        is_closed_position: true,
        miner_hotkey: "5G1naqqXwAurzAHo64nLoesygBvfghWtDbHAa7rRtbVDyMM4",
        net_leverage: 0.0,
        open_ms: 1711326051025,
        orders: [
          {
            leverage: -1.25,
            order_type: "SHORT",
            order_uuid: "153c9dba-73aa-4458-af7d-7c131b9597fe",
            price: 66841.2,
            price_sources: [],
            processed_ms: 1711326051023,
          },
          {
            leverage: 0.125,
            order_type: "LONG",
            order_uuid: "aed2b9d3-0f51-4132-ab43-140fe3b17d68",
            price: 66751.8,
            price_sources: [],
            processed_ms: 1711326980707,
          },
          {
            leverage: 0.125,
            order_type: "LONG",
            order_uuid: "26341587-1823-449c-aaf4-c9819861ccf1",
            price: 66567.27,
            price_sources: [],
            processed_ms: 1711327926038,
          },
          {
            leverage: 0.125,
            order_type: "LONG",
            order_uuid: "0be88078-9f93-4817-bdcd-4d9036443367",
            price: 66761.69,
            price_sources: [],
            processed_ms: 1711328843889,
          },
          {
            leverage: 0.4375,
            order_type: "LONG",
            order_uuid: "7f5cc2b1-63a1-4376-a6e2-c1da772a81d1",
            price: 66718.84,
            price_sources: [],
            processed_ms: 1711330362727,
          },
          {
            leverage: 0.21875,
            order_type: "LONG",
            order_uuid: "c3ea39f0-0926-4757-884d-f3eb81b4f156",
            price: 66636.07,
            price_sources: [],
            processed_ms: 1711332206686,
          },
          {
            leverage: 0.109375,
            order_type: "LONG",
            order_uuid: "f57b4616-4225-41bd-8e8b-9f8905b81cc3",
            price: 66757.12,
            price_sources: [],
            processed_ms: 1711334024847,
          },
          {
            leverage: 0.0875,
            order_type: "LONG",
            order_uuid: "e1267840-c7e8-4960-a70d-ab8cfbd32278",
            price: 66619.0,
            price_sources: [],
            processed_ms: 1711335874744,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "0fa032ba-fb15-4424-85ac-6846965de3e2",
            price: 66863.94,
            price_sources: [],
            processed_ms: 1711337690853,
          },
        ],
        position_type: "FLAT",
        position_uuid: "080066e4-2bcc-4bef-955e-98d2daf75409",
        return_at_close: 1.000214586923933,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 66978.166,
        close_ms: 1711375846647,
        current_return: 1.0544521169197298,
        initial_entry_price: 66866.73,
        is_closed_position: true,
        miner_hotkey: "5G1naqqXwAurzAHo64nLoesygBvfghWtDbHAa7rRtbVDyMM4",
        net_leverage: 0.0,
        open_ms: 1711365165927,
        orders: [
          {
            leverage: 1.25,
            order_type: "LONG",
            order_uuid: "757940b9-4b93-4403-aa98-db9021892c02",
            price: 66866.73,
            price_sources: [],
            processed_ms: 1711365165925,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "d4ff0d27-a69a-4117-b089-cec00dc46045",
            price: 66806.06,
            price_sources: [],
            processed_ms: 1711367561025,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "52d6936c-5384-48be-90d0-03e05aa0837f",
            price: 67152.67,
            price_sources: [],
            processed_ms: 1711369402497,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "8e7c5f03-80fc-41ea-8874-54fcf006a7c1",
            price: 67075.83,
            price_sources: [],
            processed_ms: 1711371227704,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "24b6a266-ae4b-4e52-b231-e057152026af",
            price: 66872.78,
            price_sources: [],
            processed_ms: 1711373058216,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "0abf40ef-7162-444d-8b15-d787e297b2f5",
            price: 67540.67,
            price_sources: [],
            processed_ms: 1711374892741,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "9af6a0fb-1a9b-43b6-837f-e79b23f8a9ac",
            price: 68434.58,
            price_sources: [],
            processed_ms: 1711375846647,
          },
        ],
        position_type: "FLAT",
        position_uuid: "f80bef78-16c4-4a90-a5eb-8c5c3925ae3c",
        return_at_close: 1.0491798563351311,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 68925.28,
        close_ms: 1711376429416,
        current_return: 1.0010290128672674,
        initial_entry_price: 68925.28,
        is_closed_position: true,
        miner_hotkey: "5G1naqqXwAurzAHo64nLoesygBvfghWtDbHAa7rRtbVDyMM4",
        net_leverage: 0.0,
        open_ms: 1711376374430,
        orders: [
          {
            leverage: -1.25,
            order_type: "SHORT",
            order_uuid: "c0265051-2dd1-4970-bf9a-9f302dc1a11d",
            price: 68925.28,
            price_sources: [],
            processed_ms: 1711376374429,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "91ab4729-2f9d-47a5-822e-6402556ca387",
            price: 68868.54,
            price_sources: [],
            processed_ms: 1711376429416,
          },
        ],
        position_type: "FLAT",
        position_uuid: "282e58f8-1e03-4eae-b5ff-d31a95da740f",
        return_at_close: 0.9985264403350993,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 224265.94000000256,
        close_ms: 1711384289651,
        current_return: 0.9944240054773165,
        initial_entry_price: 69355.13,
        is_closed_position: true,
        miner_hotkey: "5G1naqqXwAurzAHo64nLoesygBvfghWtDbHAa7rRtbVDyMM4",
        net_leverage: 0.0,
        open_ms: 1711376699122,
        orders: [
          {
            leverage: 1.25,
            order_type: "LONG",
            order_uuid: "84d81c7c-ff9e-46ed-829b-c89d71fea641",
            price: 69355.13,
            price_sources: [],
            processed_ms: 1711376699120,
          },
          {
            leverage: -0.625,
            order_type: "SHORT",
            order_uuid: "0f7abeaf-ce38-47bb-ac92-77d50f113f5f",
            price: 68941.6,
            price_sources: [],
            processed_ms: 1711377832493,
          },
          {
            leverage: -0.5,
            order_type: "SHORT",
            order_uuid: "302659b9-c9fc-48b1-b198-ec9f0b5eac72",
            price: 69074.0,
            price_sources: [],
            processed_ms: 1711378130425,
          },
          {
            leverage: -0.1,
            order_type: "SHORT",
            order_uuid: "2bea060e-a192-4dd2-807e-18fa118263a5",
            price: 69404.06,
            price_sources: [],
            processed_ms: 1711378827867,
          },
          {
            leverage: -0.012499999999999997,
            order_type: "SHORT",
            order_uuid: "24ba315c-82c0-41fa-a7cc-7e7d82a8d0e1",
            price: 69527.42,
            price_sources: [],
            processed_ms: 1711380642062,
          },
          {
            leverage: -0.009999999999999998,
            order_type: "SHORT",
            order_uuid: "a8e8c223-f5a9-4b6c-ac1b-47fa6a3bdefe",
            price: 69824.89,
            price_sources: [],
            processed_ms: 1711382469895,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "94436487-854e-41f9-b00b-c498e1548eb3",
            price: 69576.41,
            price_sources: [],
            processed_ms: 1711384289651,
          },
        ],
        position_type: "FLAT",
        position_uuid: "cab6b7f9-2fc8-421f-a3c4-432fe8f71e3a",
        return_at_close: 0.9919379454636232,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 70713.53454545456,
        close_ms: 1711402104319,
        current_return: 0.9578264130153054,
        initial_entry_price: 70606.1,
        is_closed_position: true,
        miner_hotkey: "5G1naqqXwAurzAHo64nLoesygBvfghWtDbHAa7rRtbVDyMM4",
        net_leverage: 0.0,
        open_ms: 1711389749351,
        orders: [
          {
            leverage: 1.25,
            order_type: "LONG",
            order_uuid: "ae546aa6-2551-4d05-89d5-7b370465652c",
            price: 70606.1,
            price_sources: [],
            processed_ms: 1711389749348,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "1de138a7-0503-40ae-bd6c-32bef4d7d685",
            price: 70635.37,
            price_sources: [],
            processed_ms: 1711391544036,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "c5596ad0-0a79-443e-a2f7-4c070e99c6a8",
            price: 70834.49,
            price_sources: [],
            processed_ms: 1711393356049,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "01dc3732-a6e5-461c-b670-00be45b6ef00",
            price: 70688.24,
            price_sources: [],
            processed_ms: 1711395177174,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "66c6cc84-2fec-4bf7-9f75-7e28c305de88",
            price: 71038.57,
            price_sources: [],
            processed_ms: 1711396998756,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "3ccd4505-c709-474b-a4df-be5035566a52",
            price: 70782.84,
            price_sources: [],
            processed_ms: 1711398814085,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "fcff1ab4-7db5-4c2a-938d-06d052db82b1",
            price: 70838.87,
            price_sources: [],
            processed_ms: 1711400626103,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "478277c4-15d0-4dbf-87ed-3b33ec45a3a6",
            price: 69630.73,
            price_sources: [],
            processed_ms: 1711402104319,
          },
        ],
        position_type: "FLAT",
        position_uuid: "54bdad69-091e-4e25-9359-1aea000ea732",
        return_at_close: 0.9525583677437213,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 123328.52435896422,
        close_ms: 1711442402039,
        current_return: 0.9977150517966814,
        initial_entry_price: 70415.95,
        is_closed_position: true,
        miner_hotkey: "5G1naqqXwAurzAHo64nLoesygBvfghWtDbHAa7rRtbVDyMM4",
        net_leverage: 0.0,
        open_ms: 1711406082343,
        orders: [
          {
            leverage: 1.25,
            order_type: "LONG",
            order_uuid: "e2457319-dadf-46e9-bd57-250a74d3ed41",
            price: 70415.95,
            price_sources: [],
            processed_ms: 1711406082342,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "358c0035-d32c-4b4e-af19-da7c55f09b37",
            price: 70309.85,
            price_sources: [],
            processed_ms: 1711407892164,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "e0baf1b4-e14c-4d18-830c-76113286db68",
            price: 70129.39,
            price_sources: [],
            processed_ms: 1711409707761,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "ad2a746b-97de-41c9-96b2-47c68ffb43c1",
            price: 69779.46,
            price_sources: [],
            processed_ms: 1711411546822,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "a54a76da-a566-486f-8ebb-d65d636f478b",
            price: 70184.55,
            price_sources: [],
            processed_ms: 1711413336933,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "796bdcd7-beef-4afe-84bf-26f9e3ac542b",
            price: 69961.36,
            price_sources: [],
            processed_ms: 1711415160113,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "6ac15b60-f066-4415-bef5-f0fdb7339626",
            price: 70248.52,
            price_sources: [],
            processed_ms: 1711416985296,
          },
          {
            leverage: -1.375,
            order_type: "SHORT",
            order_uuid: "c596bc4e-172a-457d-94c3-5f0335b26a92",
            price: 70104.99,
            price_sources: [],
            processed_ms: 1711418788800,
          },
          {
            leverage: -0.6875,
            order_type: "SHORT",
            order_uuid: "62118d7b-d0ce-4fdc-9df9-d319fc2bbf2c",
            price: 70255.61,
            price_sources: [],
            processed_ms: 1711420609125,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "321aa71e-4516-48e9-b97e-1137ec9dd1ed",
            price: 70368.43,
            price_sources: [],
            processed_ms: 1711422418062,
          },
          {
            leverage: -0.46875,
            order_type: "SHORT",
            order_uuid: "28afc15f-9459-4805-a0b0-ee6446d67266",
            price: 70386.2,
            price_sources: [],
            processed_ms: 1711424235366,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "6da976b5-bbb2-418f-b3f7-70c2351a4c57",
            price: 70675.45,
            price_sources: [],
            processed_ms: 1711426097538,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "a1dd54f5-627a-4bc7-be7c-277c900e4334",
            price: 70564.42,
            price_sources: [],
            processed_ms: 1711427873092,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "dbd92bd1-a777-45ee-ab80-5f86882b1e93",
            price: 70473.67,
            price_sources: [],
            processed_ms: 1711429684202,
          },
          {
            leverage: -0.609375,
            order_type: "SHORT",
            order_uuid: "dfe8b1a7-909a-412d-8cad-8cc03cd0750d",
            price: 70310.59,
            price_sources: [],
            processed_ms: 1711431498503,
          },
          {
            leverage: -0.3046875,
            order_type: "SHORT",
            order_uuid: "49f3587d-b71b-4b98-a180-4040917bd067",
            price: 70402.43,
            price_sources: [],
            processed_ms: 1711433319867,
          },
          {
            leverage: -0.15234375,
            order_type: "SHORT",
            order_uuid: "1d5b2615-e427-4a7d-8768-dd9b0d799983",
            price: 70463.92,
            price_sources: [],
            processed_ms: 1711435170666,
          },
          {
            leverage: -0.076171875,
            order_type: "SHORT",
            order_uuid: "4f6a24b0-9f72-43c0-ac60-a4d2d12f87ab",
            price: 70806.17,
            price_sources: [],
            processed_ms: 1711436972955,
          },
          {
            leverage: -0.0609375,
            order_type: "SHORT",
            order_uuid: "f168b254-51a8-4a6d-aa46-88a016f42559",
            price: 71267.99,
            price_sources: [],
            processed_ms: 1711438765216,
          },
          {
            leverage: -0.0121875,
            order_type: "SHORT",
            order_uuid: "a7708620-2005-4869-bf76-e1bd277d07b1",
            price: 70727.85,
            price_sources: [],
            processed_ms: 1711440587871,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "04f12316-8531-42bb-bd4f-04dba171c615",
            price: 70521.37,
            price_sources: [],
            processed_ms: 1711442402039,
          },
        ],
        position_type: "FLAT",
        position_uuid: "f36eaee9-8b40-43b7-af05-da5246831584",
        return_at_close: 0.9922276190117997,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 68178.01000000001,
        close_ms: 1711455130393,
        current_return: 0.9955380036782342,
        initial_entry_price: 70648.03,
        is_closed_position: true,
        miner_hotkey: "5G1naqqXwAurzAHo64nLoesygBvfghWtDbHAa7rRtbVDyMM4",
        net_leverage: 0.0,
        open_ms: 1711444213470,
        orders: [
          {
            leverage: -1.25,
            order_type: "SHORT",
            order_uuid: "2a3e2136-b911-4536-9b93-fcb0eebddf0e",
            price: 70648.03,
            price_sources: [],
            processed_ms: 1711444213468,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "c70353f5-eb56-4bc2-83e5-068e823e5d3f",
            price: 70989.82,
            price_sources: [],
            processed_ms: 1711446031325,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "9bd50b3c-5d49-4c12-98dd-33d190ba41ca",
            price: 71096.18,
            price_sources: [],
            processed_ms: 1711447879169,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "b0b7f1df-021b-415e-855a-48ba21c9c805",
            price: 70950.02,
            price_sources: [],
            processed_ms: 1711449681311,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "966523ae-69e8-4b5f-903b-414f6db14b57",
            price: 70767.67,
            price_sources: [],
            processed_ms: 1711451499755,
          },
          {
            leverage: 0.125,
            order_type: "LONG",
            order_uuid: "a5eb2c2e-802f-4d0d-a3a4-02e69226af20",
            price: 70694.91,
            price_sources: [],
            processed_ms: 1711453306912,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "f40e6e8e-2e22-4090-9d18-4779c5c49c76",
            price: 70699.86,
            price_sources: [],
            processed_ms: 1711455130393,
          },
        ],
        position_type: "FLAT",
        position_uuid: "6bdab303-cafd-4329-9dec-6de4f4434c58",
        return_at_close: 0.9930491586690386,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 537258.0566666651,
        close_ms: 1711473292494,
        current_return: 1.0123392911397766,
        initial_entry_price: 71060.06,
        is_closed_position: true,
        miner_hotkey: "5G1naqqXwAurzAHo64nLoesygBvfghWtDbHAa7rRtbVDyMM4",
        net_leverage: 0.0,
        open_ms: 1711456938752,
        orders: [
          {
            leverage: -1.25,
            order_type: "SHORT",
            order_uuid: "212f2b35-c7ec-4634-9f2c-24c6464dc7f6",
            price: 71060.06,
            price_sources: [],
            processed_ms: 1711456938749,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "4d6bd0f4-1c3b-4867-a84e-03a1ae78478e",
            price: 70909.08,
            price_sources: [],
            processed_ms: 1711458761051,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "eabb7c04-9070-49b6-90ec-738632f6fb2b",
            price: 70353.17,
            price_sources: [],
            processed_ms: 1711460580375,
          },
          {
            leverage: 0.375,
            order_type: "LONG",
            order_uuid: "51ae860d-599d-4788-bffa-d80fec31027e",
            price: 70420.81,
            price_sources: [],
            processed_ms: 1711462394319,
          },
          {
            leverage: 0.1875,
            order_type: "LONG",
            order_uuid: "ee743c78-7fb2-4c19-9ce6-ca64700eff3b",
            price: 69762.69,
            price_sources: [],
            processed_ms: 1711464216010,
          },
          {
            leverage: 0.09375,
            order_type: "LONG",
            order_uuid: "1a4b0c7e-3daf-4722-9635-08d180a37a09",
            price: 70101.47,
            price_sources: [],
            processed_ms: 1711466024112,
          },
          {
            leverage: 0.075,
            order_type: "LONG",
            order_uuid: "4bf6ffdd-c423-4bfa-8349-b4457a4adcd8",
            price: 70185.96,
            price_sources: [],
            processed_ms: 1711467844306,
          },
          {
            leverage: 0.009375000000000001,
            order_type: "LONG",
            order_uuid: "02f23db1-f925-4d93-b07f-5ece50b1b9f9",
            price: 70073.59,
            price_sources: [],
            processed_ms: 1711469692172,
          },
          {
            leverage: 0.0075000000000000015,
            order_type: "LONG",
            order_uuid: "ed03996f-59ff-48b4-8f5c-0049b9f11384",
            price: 69459.44,
            price_sources: [],
            processed_ms: 1711471475199,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "bdd105fb-a127-4e0b-99b2-4eb4ad1576d7",
            price: 69614.98,
            price_sources: [],
            processed_ms: 1711473292494,
          },
        ],
        position_type: "FLAT",
        position_uuid: "b57b7ca8-dbae-4f1d-8e9a-3c7275cbecef",
        return_at_close: 1.0098084429119272,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 70195.0976666667,
        close_ms: 1711524194714,
        current_return: 0.9706830694995937,
        initial_entry_price: 70238.68,
        is_closed_position: true,
        miner_hotkey: "5G1naqqXwAurzAHo64nLoesygBvfghWtDbHAa7rRtbVDyMM4",
        net_leverage: 0.0,
        open_ms: 1711476922991,
        orders: [
          {
            leverage: 1.25,
            order_type: "LONG",
            order_uuid: "556fb712-ee02-45d5-b7d6-efc75764b530",
            price: 70238.68,
            price_sources: [],
            processed_ms: 1711476922987,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "b81f375c-c7b0-4335-8454-c0c99ca81014",
            price: 70385.38,
            price_sources: [],
            processed_ms: 1711478801194,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "917dee9e-fcab-4c94-a41a-66117bac8065",
            price: 69835.89,
            price_sources: [],
            processed_ms: 1711480557260,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "d8b5a24d-95d0-423e-9343-e47763d0d95c",
            price: 69796.0,
            price_sources: [],
            processed_ms: 1711482371864,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "cc651597-7f1e-4871-a1c7-10d8f0d965c0",
            price: 69621.42,
            price_sources: [],
            processed_ms: 1711484195246,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "eb1f22c5-5ffa-4e3f-9f14-fe2299eadf05",
            price: 69748.81,
            price_sources: [],
            processed_ms: 1711486068840,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "34338542-e03b-466a-8bf3-69a491492432",
            price: 69877.8,
            price_sources: [],
            processed_ms: 1711487825616,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "56b962ee-8e44-45e3-8e0d-745b3a062a80",
            price: 70130.69,
            price_sources: [],
            processed_ms: 1711489638135,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "52b09ebd-b475-409f-9c21-3fcb7b464328",
            price: 69883.24,
            price_sources: [],
            processed_ms: 1711491454076,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "68ff0036-3097-467e-ba56-07b9316ddecf",
            price: 70108.81,
            price_sources: [],
            processed_ms: 1711493272374,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "03f989b5-06ce-4af7-a4a2-99532873e6f6",
            price: 70305.7,
            price_sources: [],
            processed_ms: 1711495089476,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "77ecbca2-8be2-4502-8801-7793b1e57ef6",
            price: 69959.4,
            price_sources: [],
            processed_ms: 1711496906362,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "35cdcf31-a820-43a7-8fe9-0681c550f2a6",
            price: 69924.56,
            price_sources: [],
            processed_ms: 1711498790116,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "64a14eca-474c-48fb-b27e-7fb40c96dcd3",
            price: 70379.27,
            price_sources: [],
            processed_ms: 1711500533986,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "d3b8a94e-d135-4601-ad2a-45bb0a3fcf49",
            price: 70698.0,
            price_sources: [],
            processed_ms: 1711502356435,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "a655c091-2250-47ad-900b-5785868d0896",
            price: 70398.82,
            price_sources: [],
            processed_ms: 1711504173142,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "1eeb4498-3a5a-4999-9602-100e6607a290",
            price: 70214.4,
            price_sources: [],
            processed_ms: 1711505984230,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "55a2dfa4-9e7e-4ce8-b512-d8b57fa358a4",
            price: 70456.35,
            price_sources: [],
            processed_ms: 1711507848492,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "0096ac02-b137-4d1c-964f-e5e50b68994e",
            price: 70531.52,
            price_sources: [],
            processed_ms: 1711509618087,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "1a8739e8-bd2b-4001-a785-cfcba6d13648",
            price: 70503.36,
            price_sources: [],
            processed_ms: 1711511428946,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "79f1a370-873f-4101-ac04-ea37dd665b0a",
            price: 70267.28,
            price_sources: [],
            processed_ms: 1711513250682,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "21bed405-2a3b-48ed-af69-6b6becb8a9cf",
            price: 70255.34,
            price_sources: [],
            processed_ms: 1711515062320,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "365a2e8c-9ce3-4b86-9c61-1f4429e816bb",
            price: 70381.36,
            price_sources: [],
            processed_ms: 1711516885542,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "deb65ba1-0380-462e-8e70-814b3ed762f9",
            price: 70405.59,
            price_sources: [],
            processed_ms: 1711518730593,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "f51a6ab3-a67a-45c8-afc0-ad00367ddca3",
            price: 70404.42,
            price_sources: [],
            processed_ms: 1711520508910,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "c221d94a-3e21-4c8a-bf9a-a80c5c393d26",
            price: 70186.12,
            price_sources: [],
            processed_ms: 1711522330969,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "b523335a-493c-4799-8041-0649d493980b",
            price: 69920.54,
            price_sources: [],
            processed_ms: 1711524194714,
          },
        ],
        position_type: "FLAT",
        position_uuid: "f32f64b8-a2f2-4c01-a561-5e858fe40b26",
        return_at_close: 0.9561228234570998,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 69881.16617647061,
        close_ms: 1711544921926,
        current_return: 1.0652202093917724,
        initial_entry_price: 69678.0,
        is_closed_position: true,
        miner_hotkey: "5G1naqqXwAurzAHo64nLoesygBvfghWtDbHAa7rRtbVDyMM4",
        net_leverage: 0.0,
        open_ms: 1711526089803,
        orders: [
          {
            leverage: 1.25,
            order_type: "LONG",
            order_uuid: "7294e223-a1ff-4297-a90a-220a273f4848",
            price: 69678.0,
            price_sources: [],
            processed_ms: 1711526089800,
          },
          {
            leverage: 0.375,
            order_type: "LONG",
            order_uuid: "28219ed6-a0d3-41ce-a3af-226fb967b496",
            price: 69895.2,
            price_sources: [],
            processed_ms: 1711527882930,
          },
          {
            leverage: 0.375,
            order_type: "LONG",
            order_uuid: "789ec554-b923-4d3f-948f-e6980205cb41",
            price: 69627.4,
            price_sources: [],
            processed_ms: 1711529710267,
          },
          {
            leverage: 0.375,
            order_type: "LONG",
            order_uuid: "57074f2c-bd62-4f31-9e0b-13bb069a2934",
            price: 69808.71,
            price_sources: [],
            processed_ms: 1711531537681,
          },
          {
            leverage: 0.375,
            order_type: "LONG",
            order_uuid: "90f4e6bf-5d92-44be-882d-9efa4d8096a0",
            price: 69990.5,
            price_sources: [],
            processed_ms: 1711533350003,
          },
          {
            leverage: 0.375,
            order_type: "LONG",
            order_uuid: "dc5ea5c7-8278-46b4-b487-384c1cc1d369",
            price: 70115.18,
            price_sources: [],
            processed_ms: 1711535160213,
          },
          {
            leverage: 0.375,
            order_type: "LONG",
            order_uuid: "0628b78d-26b3-4842-ac66-2432dceb356a",
            price: 70039.59,
            price_sources: [],
            processed_ms: 1711536343887,
          },
          {
            leverage: 0.375,
            order_type: "LONG",
            order_uuid: "a9ec7da4-a60e-47cc-96d3-f324401fbba4",
            price: 70225.57,
            price_sources: [],
            processed_ms: 1711539943896,
          },
          {
            leverage: 0.375,
            order_type: "LONG",
            order_uuid: "7cb19d48-7d05-43e5-9bb9-958b21ac415e",
            price: 70024.4,
            price_sources: [],
            processed_ms: 1711543568933,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "12bbdf43-2c59-4661-8a37-b06b4ec75b78",
            price: 70950.44,
            price_sources: [],
            processed_ms: 1711544921926,
          },
        ],
        position_type: "FLAT",
        position_uuid: "7ad58cab-07a6-445d-abc2-4be1c06c5a65",
        return_at_close: 1.0561658376119425,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 123371.88714285745,
        close_ms: 1711563058944,
        current_return: 1.0212208662874027,
        initial_entry_price: 70560.33,
        is_closed_position: true,
        miner_hotkey: "5G1naqqXwAurzAHo64nLoesygBvfghWtDbHAa7rRtbVDyMM4",
        net_leverage: 0.0,
        open_ms: 1711547184352,
        orders: [
          {
            leverage: -1.25,
            order_type: "SHORT",
            order_uuid: "f7b575c6-72fa-4387-bd07-77ed09c6cbe1",
            price: 70560.33,
            price_sources: [],
            processed_ms: 1711547184349,
          },
          {
            leverage: 0.375,
            order_type: "LONG",
            order_uuid: "570038d0-b00e-466c-a9c7-f7d566174799",
            price: 69858.5,
            price_sources: [],
            processed_ms: 1711550817312,
          },
          {
            leverage: 0.4375,
            order_type: "LONG",
            order_uuid: "8ec00683-afd2-48bc-86c2-b80bdd356373",
            price: 69256.05,
            price_sources: [],
            processed_ms: 1711553969050,
          },
          {
            leverage: 0.21875,
            order_type: "LONG",
            order_uuid: "13120364-90c8-4f38-9073-5da41a7e3d40",
            price: 69045.46,
            price_sources: [],
            processed_ms: 1711555809093,
          },
          {
            leverage: 0.109375,
            order_type: "LONG",
            order_uuid: "2da0ac0c-77d7-4119-9ebc-b0b0ad477352",
            price: 69141.53,
            price_sources: [],
            processed_ms: 1711557609226,
          },
          {
            leverage: 0.0546875,
            order_type: "LONG",
            order_uuid: "dbbf4870-7169-48f7-8737-cffca9d20ad1",
            price: 69078.58,
            price_sources: [],
            processed_ms: 1711559421455,
          },
          {
            leverage: 0.02734375,
            order_type: "LONG",
            order_uuid: "46c84837-7fe4-4ef4-a370-1ea019309a07",
            price: 69000.01,
            price_sources: [],
            processed_ms: 1711561250791,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "93a46224-25a9-4edb-b4c9-1d8cc57c8c99",
            price: 68611.61,
            price_sources: [],
            processed_ms: 1711563058944,
          },
        ],
        position_type: "FLAT",
        position_uuid: "b535503e-4fe6-4f67-a711-4c907f95da1c",
        return_at_close: 1.0186678141216843,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 67825.64999999992,
        close_ms: 1711577592213,
        current_return: 0.9941854414165529,
        initial_entry_price: 68891.73,
        is_closed_position: true,
        miner_hotkey: "5G1naqqXwAurzAHo64nLoesygBvfghWtDbHAa7rRtbVDyMM4",
        net_leverage: 0.0,
        open_ms: 1711570333408,
        orders: [
          {
            leverage: -3.5,
            order_type: "SHORT",
            order_uuid: "f2347952-f9a9-4a13-a8a6-6c266c4cf236",
            price: 68891.73,
            price_sources: [],
            processed_ms: 1711570333405,
          },
          {
            leverage: 1.0499999999999998,
            order_type: "LONG",
            order_uuid: "5ea6997e-62f1-401a-a561-b8e05534c142",
            price: 68915.51,
            price_sources: [],
            processed_ms: 1711572186214,
          },
          {
            leverage: 1.05,
            order_type: "LONG",
            order_uuid: "1a7d2729-7d58-45a2-b419-21787bc23e97",
            price: 68996.57,
            price_sources: [],
            processed_ms: 1711573969075,
          },
          {
            leverage: 1.05,
            order_type: "LONG",
            order_uuid: "9c4e2c4f-ccfb-435a-a03e-4566833ddecf",
            price: 69118.47,
            price_sources: [],
            processed_ms: 1711575776069,
          },
          {
            leverage: 1.05,
            order_type: "LONG",
            order_uuid: "2b57c089-e7e9-4861-9e50-3d4792e8554c",
            price: 68970.15,
            price_sources: [],
            processed_ms: 1711577592213,
          },
        ],
        position_type: "FLAT",
        position_uuid: "d1091648-ecfe-43fa-93ad-094a1d90ced0",
        return_at_close: 0.9872261433266369,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 69293.23000000003,
        close_ms: 1711586684225,
        current_return: 0.9988057056609481,
        initial_entry_price: 69308.71,
        is_closed_position: true,
        miner_hotkey: "5G1naqqXwAurzAHo64nLoesygBvfghWtDbHAa7rRtbVDyMM4",
        net_leverage: 0.0,
        open_ms: 1711579416008,
        orders: [
          {
            leverage: -3.5,
            order_type: "SHORT",
            order_uuid: "2d354690-93ca-415e-8719-815916b80e5b",
            price: 69308.71,
            price_sources: [],
            processed_ms: 1711579416005,
          },
          {
            leverage: 1.0499999999999998,
            order_type: "LONG",
            order_uuid: "b02118bc-faf4-4dd2-80cd-3323227eb546",
            price: 69346.33,
            price_sources: [],
            processed_ms: 1711581257882,
          },
          {
            leverage: 1.05,
            order_type: "LONG",
            order_uuid: "8e9b5f57-0a64-41de-ba1f-bb0644760a62",
            price: 69100.03,
            price_sources: [],
            processed_ms: 1711583052970,
          },
          {
            leverage: 1.05,
            order_type: "LONG",
            order_uuid: "068c3601-46f7-4fa2-b6f2-a674d18d2cba",
            price: 69484.93,
            price_sources: [],
            processed_ms: 1711584867073,
          },
          {
            leverage: 1.05,
            order_type: "LONG",
            order_uuid: "65038288-6aa5-45d1-b0f3-210af8f3e707",
            price: 69529.73,
            price_sources: [],
            processed_ms: 1711586684225,
          },
        ],
        position_type: "FLAT",
        position_uuid: "1abaac44-9afc-4682-bae5-3d8bb7d788f7",
        return_at_close: 0.9918140657213215,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 79384.38857142837,
        close_ms: 1711601243871,
        current_return: 1.0107447684454018,
        initial_entry_price: 69561.78,
        is_closed_position: true,
        miner_hotkey: "5G1naqqXwAurzAHo64nLoesygBvfghWtDbHAa7rRtbVDyMM4",
        net_leverage: 0.0,
        open_ms: 1711588520469,
        orders: [
          {
            leverage: -3.5,
            order_type: "SHORT",
            order_uuid: "5a47470e-89ac-4944-921d-84fb393ff12f",
            price: 69561.78,
            price_sources: [],
            processed_ms: 1711588520466,
          },
          {
            leverage: 1.0499999999999998,
            order_type: "LONG",
            order_uuid: "b515b61d-f899-4dfe-a770-411cde02ef8f",
            price: 69461.29,
            price_sources: [],
            processed_ms: 1711590321975,
          },
          {
            leverage: 1.225,
            order_type: "LONG",
            order_uuid: "3a6c7676-bc25-4176-86af-f4ee056cdbed",
            price: 69500.0,
            price_sources: [],
            processed_ms: 1711592131010,
          },
          {
            leverage: 0.6125,
            order_type: "LONG",
            order_uuid: "03cb312c-d4fe-4eb7-b00f-501ce8f89667",
            price: 68929.67,
            price_sources: [],
            processed_ms: 1711593955166,
          },
          {
            leverage: 0.30625,
            order_type: "LONG",
            order_uuid: "8c60bb23-2ff1-4043-ade8-3d11624a890a",
            price: 69245.93,
            price_sources: [],
            processed_ms: 1711595788391,
          },
          {
            leverage: 0.153125,
            order_type: "LONG",
            order_uuid: "58cc4d26-7991-433d-a01a-9f3e22e2a1c9",
            price: 69045.54,
            price_sources: [],
            processed_ms: 1711597586501,
          },
          {
            leverage: 0.0765625,
            order_type: "LONG",
            order_uuid: "35ff3830-2481-4fc5-8f5e-115f27c29faf",
            price: 69458.56,
            price_sources: [],
            processed_ms: 1711599400904,
          },
          {
            leverage: 1.05,
            order_type: "LONG",
            order_uuid: "5e9f663a-cc52-43d2-80d5-92576794c629",
            price: 69622.1,
            price_sources: [],
            processed_ms: 1711601243871,
          },
        ],
        position_type: "FLAT",
        position_uuid: "9aa48eaf-8976-4ef2-a526-d09c049853af",
        return_at_close: 1.003669555066284,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 71056.51,
        close_ms: 1711633210395,
        current_return: 1.0129027586634918,
        initial_entry_price: 71056.51,
        is_closed_position: true,
        miner_hotkey: "5G1naqqXwAurzAHo64nLoesygBvfghWtDbHAa7rRtbVDyMM4",
        net_leverage: 0.0,
        open_ms: 1711633102116,
        orders: [
          {
            leverage: -3.5,
            order_type: "SHORT",
            order_uuid: "0957f844-baa1-4cd0-8b22-aa9c95e4192f",
            price: 71056.51,
            price_sources: [],
            processed_ms: 1711633102113,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "14fe4f2d-eea0-4e9d-8411-ca3318147ed8",
            price: 70794.56,
            price_sources: [],
            processed_ms: 1711633210395,
          },
        ],
        position_type: "FLAT",
        position_uuid: "bd49ce1d-c35b-44db-b741-60537a0a0cbc",
        return_at_close: 1.0058124393528474,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 71157.75,
        close_ms: 1711634981179,
        current_return: 0.9994092702481461,
        initial_entry_price: 71157.75,
        is_closed_position: true,
        miner_hotkey: "5G1naqqXwAurzAHo64nLoesygBvfghWtDbHAa7rRtbVDyMM4",
        net_leverage: 0.0,
        open_ms: 1711634892987,
        orders: [
          {
            leverage: -3.5,
            order_type: "SHORT",
            order_uuid: "9d63ac41-c379-41a6-b4dd-98d0ed97f98c",
            price: 71157.75,
            price_sources: [],
            processed_ms: 1711634892983,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "feb39489-05b4-40c8-9d1f-7cb9aaf43cb0",
            price: 71169.76,
            price_sources: [],
            processed_ms: 1711634981179,
          },
        ],
        position_type: "FLAT",
        position_uuid: "ab192b6a-d8d8-4a97-81f5-12a8c346f729",
        return_at_close: 0.9924134053564091,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 71323.18,
        close_ms: 1711636795103,
        current_return: 0.9934625741589199,
        initial_entry_price: 71323.18,
        is_closed_position: true,
        miner_hotkey: "5G1naqqXwAurzAHo64nLoesygBvfghWtDbHAa7rRtbVDyMM4",
        net_leverage: 0.0,
        open_ms: 1711636707986,
        orders: [
          {
            leverage: -3.5,
            order_type: "SHORT",
            order_uuid: "9a6582e6-46f6-4c92-b3d0-9da0fe290a0f",
            price: 71323.18,
            price_sources: [],
            processed_ms: 1711636707983,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "69a0fb92-1e04-4787-b87e-98a723be4572",
            price: 71456.4,
            price_sources: [],
            processed_ms: 1711636795103,
          },
        ],
        position_type: "FLAT",
        position_uuid: "9797dbb4-99ee-4556-bd6d-83da70d59f04",
        return_at_close: 0.9865083361398075,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 71364.97,
        close_ms: 1711638615212,
        current_return: 1.0044615026111552,
        initial_entry_price: 71364.97,
        is_closed_position: true,
        miner_hotkey: "5G1naqqXwAurzAHo64nLoesygBvfghWtDbHAa7rRtbVDyMM4",
        net_leverage: 0.0,
        open_ms: 1711638528060,
        orders: [
          {
            leverage: -3.5,
            order_type: "SHORT",
            order_uuid: "c6447a76-bd09-4067-89f1-1e181ccbfc00",
            price: 71364.97,
            price_sources: [],
            processed_ms: 1711638528057,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "42ede4f3-622e-4afc-b5cd-c531adb25245",
            price: 71274.0,
            price_sources: [],
            processed_ms: 1711638615212,
          },
        ],
        position_type: "FLAT",
        position_uuid: "55c482ba-4253-41ac-a0e5-68142958f6e1",
        return_at_close: 0.997430272092877,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 71872.45999999989,
        close_ms: 1711678888049,
        current_return: 1.0039384223126737,
        initial_entry_price: 70824.68,
        is_closed_position: true,
        miner_hotkey: "5G1naqqXwAurzAHo64nLoesygBvfghWtDbHAa7rRtbVDyMM4",
        net_leverage: 0.0,
        open_ms: 1711660770466,
        orders: [
          {
            leverage: -5.0,
            order_type: "SHORT",
            order_uuid: "4a264928-eb90-42d0-83e7-1c68ce20eb10",
            price: 70824.68,
            price_sources: [],
            processed_ms: 1711660770463,
          },
          {
            leverage: 2.5,
            order_type: "LONG",
            order_uuid: "06c6cfbb-7376-457b-99e1-eeb86c391447",
            price: 70636.01,
            price_sources: [],
            processed_ms: 1711664390734,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "301f1fc7-e7ab-47ae-9c0e-269681098cfa",
            price: 70997.98,
            price_sources: [],
            processed_ms: 1711667999488,
          },
          {
            leverage: 0.5,
            order_type: "LONG",
            order_uuid: "2c30f445-2efc-447b-b45d-71480f669dc5",
            price: 70733.34,
            price_sources: [],
            processed_ms: 1711671643406,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "cfda1085-697e-45c4-a956-f24b1f5abac2",
            price: 70806.48,
            price_sources: [],
            processed_ms: 1711675248452,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "e02608d6-da54-4fbb-bf9a-9b8804f60e20",
            price: 70756.71,
            price_sources: [],
            processed_ms: 1711678888049,
          },
        ],
        position_type: "FLAT",
        position_uuid: "9ab2b618-46f1-4c80-89e9-9034add13499",
        return_at_close: 0.9938990380895469,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 70374.03736842105,
        close_ms: 1711697697545,
        current_return: 0.963267070499677,
        initial_entry_price: 70406.99,
        is_closed_position: true,
        miner_hotkey: "5G1naqqXwAurzAHo64nLoesygBvfghWtDbHAa7rRtbVDyMM4",
        net_leverage: 0.0,
        open_ms: 1711686112124,
        orders: [
          {
            leverage: 5.0,
            order_type: "LONG",
            order_uuid: "ddc91fb4-cda9-49af-a619-942666b9cbb6",
            price: 70406.99,
            price_sources: [],
            processed_ms: 1711686112121,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "e7e864ff-3c18-425e-86c8-1027abfc8f25",
            price: 70356.21,
            price_sources: [],
            processed_ms: 1711689772650,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "6342294f-3b83-455c-aa6f-e1a5e7340a00",
            price: 70349.95,
            price_sources: [],
            processed_ms: 1711693366127,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "585f94c9-de7e-4a02-bf06-5fd63472bc99",
            price: 70306.11,
            price_sources: [],
            processed_ms: 1711697000131,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "46eac4e6-5d54-41ab-a60c-f1e33dcadfdf",
            price: 70101.8,
            price_sources: [],
            processed_ms: 1711697697545,
          },
        ],
        position_type: "FLAT",
        position_uuid: "7c6d8b11-aca4-4f08-975c-19fa7581ceb5",
        return_at_close: 0.9449649961601831,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 56652.8339044771,
        close_ms: 1711783787745,
        current_return: 1.0390318945187764,
        initial_entry_price: 69858.14,
        is_closed_position: true,
        miner_hotkey: "5G1naqqXwAurzAHo64nLoesygBvfghWtDbHAa7rRtbVDyMM4",
        net_leverage: 0.0,
        open_ms: 1711700590908,
        orders: [
          {
            leverage: 5.0,
            order_type: "LONG",
            order_uuid: "26e8fef6-d1b2-40d5-a577-7630498993e0",
            price: 69858.14,
            price_sources: [],
            processed_ms: 1711700590905,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "f5f0a6f9-1a52-49a3-9578-2410bb2c46bc",
            price: 69917.19,
            price_sources: [],
            processed_ms: 1711704216163,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "f970e1c2-e92d-4548-91c0-8bcf44f62a62",
            price: 69896.77,
            price_sources: [],
            processed_ms: 1711707836788,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "966d252c-1138-4561-809f-ce951e8f61f8",
            price: 70287.52,
            price_sources: [],
            processed_ms: 1711711487224,
          },
          {
            leverage: -4.75,
            order_type: "SHORT",
            order_uuid: "b94dece5-5289-44c4-98d8-84abe3c7a733",
            price: 70157.85,
            price_sources: [],
            processed_ms: 1711715071035,
          },
          {
            leverage: -2.375,
            order_type: "SHORT",
            order_uuid: "29a09973-bfe6-4ada-88f9-7c5c5f8c32e7",
            price: 70227.78,
            price_sources: [],
            processed_ms: 1711718686189,
          },
          {
            leverage: -1.1875,
            order_type: "SHORT",
            order_uuid: "77586033-b255-4ee3-bfc5-fa73309bc9c8",
            price: 70420.06,
            price_sources: [],
            processed_ms: 1711722296186,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "33393d29-e684-44cf-8aba-5407595d5f7c",
            price: 69889.7,
            price_sources: [],
            processed_ms: 1711725931862,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "1343d37d-1ea7-4331-a101-e541830b5982",
            price: 69352.79,
            price_sources: [],
            processed_ms: 1711729630447,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "b7e57382-9c9b-467a-b60e-ab7c19726e1b",
            price: 69404.28,
            price_sources: [],
            processed_ms: 1711733161058,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "ff8b3099-5c05-482c-826b-e27ca5eaf935",
            price: 69468.21,
            price_sources: [],
            processed_ms: 1711736771176,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "d0ac18a5-7b46-47a9-85f6-8a82627d3e81",
            price: 69505.82,
            price_sources: [],
            processed_ms: 1711740392087,
          },
          {
            leverage: -4.34375,
            order_type: "SHORT",
            order_uuid: "84e1eb5c-ac7d-4e13-a056-51db81ba14d2",
            price: 69678.46,
            price_sources: [],
            processed_ms: 1711744011188,
          },
          {
            leverage: -2.171875,
            order_type: "SHORT",
            order_uuid: "a386786d-b46d-45b2-94af-45f5a2763e53",
            price: 69490.87,
            price_sources: [],
            processed_ms: 1711747628844,
          },
          {
            leverage: -1.0859375,
            order_type: "SHORT",
            order_uuid: "aaa56599-25fe-416c-813d-8f81ff33903c",
            price: 69736.38,
            price_sources: [],
            processed_ms: 1711751248598,
          },
          {
            leverage: -0.54296875,
            order_type: "SHORT",
            order_uuid: "9a96661a-4222-4bf6-aab6-4fa7b7e58e6b",
            price: 69935.05,
            price_sources: [],
            processed_ms: 1711754859997,
          },
          {
            leverage: -0.271484375,
            order_type: "SHORT",
            order_uuid: "379208d2-3a36-432b-ae49-b1215d64575b",
            price: 69837.46,
            price_sources: [],
            processed_ms: 1711758477060,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "4d823312-121f-4bef-bb4b-f4d03fe13f85",
            price: 70130.88,
            price_sources: [],
            processed_ms: 1711762124337,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "f207f74b-c47a-4e45-bcc1-e25a84d60043",
            price: 69977.69,
            price_sources: [],
            processed_ms: 1711765705976,
          },
          {
            leverage: -1.6357421875,
            order_type: "SHORT",
            order_uuid: "02687967-c061-4985-bf66-a21a3b20ab46",
            price: 69923.02,
            price_sources: [],
            processed_ms: 1711769328897,
          },
          {
            leverage: -0.81787109375,
            order_type: "SHORT",
            order_uuid: "af9da0cf-d4a0-4fe4-be06-ac52520cebf0",
            price: 69911.72,
            price_sources: [],
            processed_ms: 1711772972967,
          },
          {
            leverage: -0.408935546875,
            order_type: "SHORT",
            order_uuid: "a80ecf93-5beb-4c36-a6e3-6d3f77d400dc",
            price: 70038.49,
            price_sources: [],
            processed_ms: 1711776594555,
          },
          {
            leverage: -0.2044677734375,
            order_type: "SHORT",
            order_uuid: "afb04ee6-a5c7-465a-a34e-c6f8ca76eaf5",
            price: 70131.85,
            price_sources: [],
            processed_ms: 1711780174751,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "3691bd2a-4fc8-47f9-ba26-e5fcbe86999f",
            price: 69988.41,
            price_sources: [],
            processed_ms: 1711783787745,
          },
        ],
        position_type: "FLAT",
        position_uuid: "47d48d73-b6e4-4624-bc89-18a6e418437e",
        return_at_close: 1.0192902885229196,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 67395.83000000013,
        close_ms: 1711805505238,
        current_return: 0.9900983986020196,
        initial_entry_price: 69969.49,
        is_closed_position: true,
        miner_hotkey: "5G1naqqXwAurzAHo64nLoesygBvfghWtDbHAa7rRtbVDyMM4",
        net_leverage: 0.0,
        open_ms: 1711787402266,
        orders: [
          {
            leverage: -5.0,
            order_type: "SHORT",
            order_uuid: "554e2fee-5bce-4968-b76c-5c3bea4cff03",
            price: 69969.49,
            price_sources: [],
            processed_ms: 1711787402263,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "94411773-a5da-4fc2-8532-d228a17e98b3",
            price: 70109.49,
            price_sources: [],
            processed_ms: 1711791024917,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "8430f981-a21f-4fcf-b02f-9d0bddbd202c",
            price: 70073.2,
            price_sources: [],
            processed_ms: 1711794641371,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "151bd1a2-edc2-47dd-b202-2f9088eca08a",
            price: 70121.96,
            price_sources: [],
            processed_ms: 1711798268116,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "bbbefdec-ccda-49b9-b538-817b5107881b",
            price: 70166.07,
            price_sources: [],
            processed_ms: 1711801884588,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "cffefc2d-3a6b-4327-9894-bb1cd95a59f0",
            price: 70167.07,
            price_sources: [],
            processed_ms: 1711805505238,
          },
        ],
        position_type: "FLAT",
        position_uuid: "26342942-8ecb-45d0-bab7-8a98886bbd4f",
        return_at_close: 0.9801974146159994,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 74261.8846268655,
        close_ms: 1711845304107,
        current_return: 0.9868857741175929,
        initial_entry_price: 70172.22,
        is_closed_position: true,
        miner_hotkey: "5G1naqqXwAurzAHo64nLoesygBvfghWtDbHAa7rRtbVDyMM4",
        net_leverage: 0.0,
        open_ms: 1711809132022,
        orders: [
          {
            leverage: 5.0,
            order_type: "LONG",
            order_uuid: "94d81338-d790-4b1b-9cc6-bd3b0908472c",
            price: 70172.22,
            price_sources: [],
            processed_ms: 1711809132017,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "30cd7dde-2509-4b53-a12a-b83bc11318b3",
            price: 70006.35,
            price_sources: [],
            processed_ms: 1711812750847,
          },
          {
            leverage: -3.25,
            order_type: "SHORT",
            order_uuid: "e56a9afc-8a44-49aa-9ba2-12928b25ce50",
            price: 70032.29,
            price_sources: [],
            processed_ms: 1711816368108,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "dc84f18d-84b2-427b-ada3-11b856b84312",
            price: 70066.64,
            price_sources: [],
            processed_ms: 1711819988535,
          },
          {
            leverage: -2.375,
            order_type: "SHORT",
            order_uuid: "3f92a7dd-3d02-4ac4-9458-b2e25ae8fb4b",
            price: 70034.89,
            price_sources: [],
            processed_ms: 1711823598144,
          },
          {
            leverage: -1.1875,
            order_type: "SHORT",
            order_uuid: "fa5af692-e627-4abb-82cf-06232879ce83",
            price: 69958.68,
            price_sources: [],
            processed_ms: 1711827221920,
          },
          {
            leverage: -0.59375,
            order_type: "SHORT",
            order_uuid: "f0cde9a2-ffac-4e69-933d-4335e5038f9d",
            price: 69911.13,
            price_sources: [],
            processed_ms: 1711830838287,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "4ca1feb4-b89a-41c0-9f85-39e7e7997bc6",
            price: 69753.49,
            price_sources: [],
            processed_ms: 1711834459510,
          },
          {
            leverage: -1.675,
            order_type: "SHORT",
            order_uuid: "95fa33d6-5b51-48cf-a1f4-688e3377921e",
            price: 69822.8,
            price_sources: [],
            processed_ms: 1711838072497,
          },
          {
            leverage: -0.20937499999999998,
            order_type: "SHORT",
            order_uuid: "480bfa26-0596-49d2-98aa-9e2377016d03",
            price: 69614.69,
            price_sources: [],
            processed_ms: 1711841689626,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "d4fec7dc-c079-4ceb-97d2-e35ff2aa0b37",
            price: 69866.64,
            price_sources: [],
            processed_ms: 1711845304107,
          },
        ],
        position_type: "FLAT",
        position_uuid: "5065a395-0fd4-428d-9d68-bfd25e55d698",
        return_at_close: 0.9740562590540642,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 69771.51999999999,
        close_ms: 1711877855597,
        current_return: 1.004452994605495,
        initial_entry_price: 70219.6,
        is_closed_position: true,
        miner_hotkey: "5G1naqqXwAurzAHo64nLoesygBvfghWtDbHAa7rRtbVDyMM4",
        net_leverage: 0.0,
        open_ms: 1711863388063,
        orders: [
          {
            leverage: 5.0,
            order_type: "LONG",
            order_uuid: "b72277fc-b0c3-48e8-af8c-e2c3a4d0337f",
            price: 70219.6,
            price_sources: [],
            processed_ms: 1711863388059,
          },
          {
            leverage: -2.5,
            order_type: "SHORT",
            order_uuid: "5b379b2c-4b67-4325-a7e9-ca15e0c5b0ff",
            price: 70277.33,
            price_sources: [],
            processed_ms: 1711867004797,
          },
          {
            leverage: -1.25,
            order_type: "SHORT",
            order_uuid: "4a463c96-c304-4d3a-9d52-1c2c2b8b5efb",
            price: 70234.24,
            price_sources: [],
            processed_ms: 1711870653816,
          },
          {
            leverage: -0.625,
            order_type: "SHORT",
            order_uuid: "844d576a-5826-4c5b-a5b3-a6adbce19f41",
            price: 70407.48,
            price_sources: [],
            processed_ms: 1711874239922,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "e84f1f63-6429-4ca5-8645-5326b3fb46db",
            price: 70271.82,
            price_sources: [],
            processed_ms: 1711877855597,
          },
        ],
        position_type: "FLAT",
        position_uuid: "017b500f-4802-4536-83cb-64a9447b1999",
        return_at_close: 0.99440846465944,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 69126.02499999998,
        close_ms: 1711895930244,
        current_return: 0.9782808407546942,
        initial_entry_price: 70220.72,
        is_closed_position: true,
        miner_hotkey: "5G1naqqXwAurzAHo64nLoesygBvfghWtDbHAa7rRtbVDyMM4",
        net_leverage: 0.0,
        open_ms: 1711881467642,
        orders: [
          {
            leverage: -5.0,
            order_type: "SHORT",
            order_uuid: "7ee49fe4-0cb1-488a-9315-cef50e4caa11",
            price: 70220.72,
            price_sources: [],
            processed_ms: 1711881467637,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "19699d57-ad85-4d23-84f8-05581b4c5850",
            price: 70381.78,
            price_sources: [],
            processed_ms: 1711885080282,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "ee1b7adf-35b1-44cb-9f27-d8f4d25f2087",
            price: 70481.05,
            price_sources: [],
            processed_ms: 1711888699147,
          },
          {
            leverage: 1.0,
            order_type: "LONG",
            order_uuid: "1086bc82-af16-45e5-92db-be37c8ac9d9c",
            price: 70683.33,
            price_sources: [],
            processed_ms: 1711892311280,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "a8f0b752-7bf7-4efa-9e5e-d2d219cd5b96",
            price: 70651.16,
            price_sources: [],
            processed_ms: 1711895930244,
          },
        ],
        position_type: "FLAT",
        position_uuid: "9fb0002d-355b-4970-8810-90a9bae6fbd0",
        return_at_close: 0.9684980323471473,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
    ],
    thirty_day_returns: 1.0,
  },
  "5G22DcCMnxFiiyaEpa6zx16EFieCdwXtY82aVZ7jPrRMTFr9": {
    all_time_returns: 1.0,
    n_positions: 0,
    percentage_profitable: 0.0,
    positions: [
      {
        average_entry_price: 64371.74999999999,
        close_ms: 1711240936340,
        current_return: 1.000457094921297,
        initial_entry_price: 64371.75,
        is_closed_position: true,
        miner_hotkey: "5G22DcCMnxFiiyaEpa6zx16EFieCdwXtY82aVZ7jPrRMTFr9",
        net_leverage: 0.0,
        open_ms: 1711237355242,
        orders: [
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "3edfeadd-b475-43e5-b4b4-db941f0f5f69",
            price: 64371.75,
            price_sources: [],
            processed_ms: 1711237355242,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "bea03cb6-e856-447f-9a86-85ca85928e1f",
            price: 64469.83,
            price_sources: [],
            processed_ms: 1711240936340,
          },
        ],
        position_type: "FLAT",
        position_uuid: "f10d371e-087d-44c1-a628-8707c4eea6bc",
        return_at_close: 0.9998568206643441,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 64339.03,
        close_ms: 1711251918343,
        current_return: 0.9987905630532509,
        initial_entry_price: 64339.03,
        is_closed_position: true,
        miner_hotkey: "5G22DcCMnxFiiyaEpa6zx16EFieCdwXtY82aVZ7jPrRMTFr9",
        net_leverage: 0.0,
        open_ms: 1711248396067,
        orders: [
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "3c7dbb6d-8f2b-46e5-bcee-e57343bdabdf",
            price: 64339.03,
            price_sources: [],
            processed_ms: 1711248396066,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "c4d1c496-5e7c-4c6c-b67c-d74e652117d0",
            price: 64079.65,
            price_sources: [],
            processed_ms: 1711251918343,
          },
        ],
        position_type: "FLAT",
        position_uuid: "d1f68b45-90a6-4d7c-a4c6-9f18da8c32c8",
        return_at_close: 0.9981912887154188,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 66234.50000000025,
        close_ms: 1711337392391,
        current_return: 0.9993425860006421,
        initial_entry_price: 66656.77,
        is_closed_position: true,
        miner_hotkey: "5G22DcCMnxFiiyaEpa6zx16EFieCdwXtY82aVZ7jPrRMTFr9",
        net_leverage: 0.0,
        open_ms: 1711328574439,
        orders: [
          {
            leverage: -1.25,
            order_type: "SHORT",
            order_uuid: "0b147140-0088-455b-88f0-556a2401cfec",
            price: 66656.77,
            price_sources: [],
            processed_ms: 1711328574439,
          },
          {
            leverage: 0.625,
            order_type: "LONG",
            order_uuid: "fecbddaf-5adc-4bc7-b7ba-e3a16640476c",
            price: 66757.49,
            price_sources: [],
            processed_ms: 1711330062439,
          },
          {
            leverage: 0.3125,
            order_type: "LONG",
            order_uuid: "fa581239-36ca-4a4b-86a5-6dc2d5dec3f5",
            price: 66540.09,
            price_sources: [],
            processed_ms: 1711331897219,
          },
          {
            leverage: 0.15625,
            order_type: "LONG",
            order_uuid: "6fcb8bc6-2a50-4ffc-b58d-5e53b95123c9",
            price: 66669.59,
            price_sources: [],
            processed_ms: 1711333724489,
          },
          {
            leverage: 0.078125,
            order_type: "LONG",
            order_uuid: "a82407af-98cf-4ab8-97d3-e754ec03ff0b",
            price: 66714.36,
            price_sources: [],
            processed_ms: 1711335559611,
          },
          {
            leverage: 0.125,
            order_type: "LONG",
            order_uuid: "e2da28ef-374d-47d7-8356-370d0d6ec8ef",
            price: 66795.41,
            price_sources: [],
            processed_ms: 1711337392391,
          },
        ],
        position_type: "FLAT",
        position_uuid: "ede422d3-f28e-4c4c-9559-26eb081652b9",
        return_at_close: 0.9968442295356406,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 10211.509999999336,
        close_ms: 1711394189719,
        current_return: 1.0308578492986438,
        initial_entry_price: 68838.73,
        is_closed_position: true,
        miner_hotkey: "5G22DcCMnxFiiyaEpa6zx16EFieCdwXtY82aVZ7jPrRMTFr9",
        net_leverage: 0.0,
        open_ms: 1711375961133,
        orders: [
          {
            leverage: 1.25,
            order_type: "LONG",
            order_uuid: "d6113533-962a-45ba-a9c8-5637635d24f3",
            price: 68838.73,
            price_sources: [],
            processed_ms: 1711375961133,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "e443b161-1fa4-4a9f-9268-0ce89f90b912",
            price: 69141.81,
            price_sources: [],
            processed_ms: 1711376490520,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "6a8ef45e-5d78-43a9-9aec-7e877d1b4131",
            price: 69296.54,
            price_sources: [],
            processed_ms: 1711376787479,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "c2f2fff6-08fb-42be-a193-9590504683ef",
            price: 69095.56,
            price_sources: [],
            processed_ms: 1711377930650,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "693aef20-c5ad-48e9-9d52-2af1556455c9",
            price: 69061.84,
            price_sources: [],
            processed_ms: 1711378212935,
          },
          {
            leverage: -1.125,
            order_type: "SHORT",
            order_uuid: "eab90fbb-133d-4b10-b8c8-2a3e6feee73e",
            price: 69777.86,
            price_sources: [],
            processed_ms: 1711382250605,
          },
          {
            leverage: -0.5625,
            order_type: "SHORT",
            order_uuid: "224b8c1f-ca1b-4de0-8e15-238a523dcbd0",
            price: 69682.85,
            price_sources: [],
            processed_ms: 1711384061691,
          },
          {
            leverage: -0.28125,
            order_type: "SHORT",
            order_uuid: "eebe49ef-d9eb-467f-90a5-fa75c8150d64",
            price: 70236.86,
            price_sources: [],
            processed_ms: 1711385881867,
          },
          {
            leverage: -0.140625,
            order_type: "SHORT",
            order_uuid: "98e56188-df21-4a41-bdec-776b82b18f46",
            price: 70680.97,
            price_sources: [],
            processed_ms: 1711387731691,
          },
          {
            leverage: -0.0703125,
            order_type: "SHORT",
            order_uuid: "40d66a5c-042f-464a-9672-015203c080e5",
            price: 70530.92,
            price_sources: [],
            processed_ms: 1711389524567,
          },
          {
            leverage: -0.03515625,
            order_type: "SHORT",
            order_uuid: "d360d6dd-0d7f-4c79-ae9f-9a67ac2755d0",
            price: 70793.17,
            price_sources: [],
            processed_ms: 1711391339913,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "0ed2faf9-79e7-4643-b5fc-02612a5cb00e",
            price: 70633.63,
            price_sources: [],
            processed_ms: 1711394189719,
          },
        ],
        position_type: "FLAT",
        position_uuid: "263e2ed2-e87d-4bf4-a412-6f871f8f5eb2",
        return_at_close: 1.0262189889767999,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 67154.6200000001,
        close_ms: 1711410540700,
        current_return: 1.0139813578324566,
        initial_entry_price: 70008.49,
        is_closed_position: true,
        miner_hotkey: "5G22DcCMnxFiiyaEpa6zx16EFieCdwXtY82aVZ7jPrRMTFr9",
        net_leverage: 0.0,
        open_ms: 1711403275820,
        orders: [
          {
            leverage: 3.0,
            order_type: "LONG",
            order_uuid: "215611d4-0396-4300-89bc-534f667d37f3",
            price: 70008.49,
            price_sources: [],
            processed_ms: 1711403275819,
          },
          {
            leverage: -1.5,
            order_type: "SHORT",
            order_uuid: "419c52e3-9908-4796-aa75-4f21cb6b40ce",
            price: 70399.7,
            price_sources: [],
            processed_ms: 1711405090969,
          },
          {
            leverage: -0.75,
            order_type: "SHORT",
            order_uuid: "3cff9182-7cad-40fb-80b6-f2243e27a324",
            price: 70466.67,
            price_sources: [],
            processed_ms: 1711406958645,
          },
          {
            leverage: -0.375,
            order_type: "SHORT",
            order_uuid: "11edcb6f-d873-4e9c-ab76-5f1ca5cb17c4",
            price: 70381.16,
            price_sources: [],
            processed_ms: 1711408741679,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "eecc28d5-a6b6-44fd-bc72-be4424d5aa69",
            price: 69764.79,
            price_sources: [],
            processed_ms: 1711410540700,
          },
        ],
        position_type: "FLAT",
        position_uuid: "bce28ea5-9bef-4cc1-9932-991f4168b1f2",
        return_at_close: 1.007897469685462,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 71761.04000000001,
        close_ms: 1711472322884,
        current_return: 0.9890623297102552,
        initial_entry_price: 70284.62,
        is_closed_position: true,
        miner_hotkey: "5G22DcCMnxFiiyaEpa6zx16EFieCdwXtY82aVZ7jPrRMTFr9",
        net_leverage: 0.0,
        open_ms: 1711465057638,
        orders: [
          {
            leverage: 3.0,
            order_type: "LONG",
            order_uuid: "ae997afa-81c0-486a-a0a4-1d4c087656e4",
            price: 70284.62,
            price_sources: [],
            processed_ms: 1711465057637,
          },
          {
            leverage: -1.5,
            order_type: "SHORT",
            order_uuid: "6f73cdc6-1419-44ae-9b48-9f274377dc46",
            price: 70058.39,
            price_sources: [],
            processed_ms: 1711466874845,
          },
          {
            leverage: -0.75,
            order_type: "SHORT",
            order_uuid: "9848f042-82c8-4655-8077-462a9dc4e92a",
            price: 70278.26,
            price_sources: [],
            processed_ms: 1711468693347,
          },
          {
            leverage: -0.375,
            order_type: "SHORT",
            order_uuid: "580f6c01-8060-4f05-8ce8-44a6820119d5",
            price: 69725.84,
            price_sources: [],
            processed_ms: 1711470505794,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "12e06ff4-b6ae-48d0-b56d-0796f6b02779",
            price: 69711.04,
            price_sources: [],
            processed_ms: 1711472322884,
          },
        ],
        position_type: "FLAT",
        position_uuid: "508d4efd-eec5-4d67-b137-b6a88b057446",
        return_at_close: 0.9831279557319936,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 69812.65,
        close_ms: 1711552731071,
        current_return: 0.9788104304878847,
        initial_entry_price: 69812.65,
        is_closed_position: true,
        miner_hotkey: "5G22DcCMnxFiiyaEpa6zx16EFieCdwXtY82aVZ7jPrRMTFr9",
        net_leverage: 0.0,
        open_ms: 1711550255780,
        orders: [
          {
            leverage: 3.0,
            order_type: "LONG",
            order_uuid: "366b3dda-ee8f-4c75-b9c6-fa56592459e9",
            price: 69812.65,
            price_sources: [],
            processed_ms: 1711550255778,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "22dc0fc0-b34e-4211-baed-f7b71e40156f",
            price: 69319.55,
            price_sources: [],
            processed_ms: 1711552731071,
          },
        ],
        position_type: "FLAT",
        position_uuid: "6a34d124-5fdf-43b1-939e-ac2c7011d4ad",
        return_at_close: 0.9729375679049574,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 75251.7000000001,
        close_ms: 1711575585909,
        current_return: 1.006588304303883,
        initial_entry_price: 69180.32,
        is_closed_position: true,
        miner_hotkey: "5G22DcCMnxFiiyaEpa6zx16EFieCdwXtY82aVZ7jPrRMTFr9",
        net_leverage: 0.0,
        open_ms: 1711553867679,
        orders: [
          {
            leverage: -3.0,
            order_type: "SHORT",
            order_uuid: "d8bad8d5-f948-41ae-8e3b-38a60b936676",
            price: 69180.32,
            price_sources: [],
            processed_ms: 1711553867678,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "7ee3e2c7-7c16-46de-8ee7-aae78f095c6f",
            price: 69092.91,
            price_sources: [],
            processed_ms: 1711557524306,
          },
          {
            leverage: 0.8999999999999999,
            order_type: "LONG",
            order_uuid: "d61a2f1a-795c-4dd5-968e-ccc5ed4bc9e1",
            price: 69087.91,
            price_sources: [],
            processed_ms: 1711561127683,
          },
          {
            leverage: 0.30000000000000004,
            order_type: "LONG",
            order_uuid: "7f06e0ff-c09f-4f02-ad3b-214dba39227c",
            price: 68757.37,
            price_sources: [],
            processed_ms: 1711564740940,
          },
          {
            leverage: 0.15000000000000002,
            order_type: "LONG",
            order_uuid: "f859077f-80c6-4ddf-9c85-c0f03fc8d521",
            price: 68537.35,
            price_sources: [],
            processed_ms: 1711568355197,
          },
          {
            leverage: 0.07500000000000001,
            order_type: "LONG",
            order_uuid: "a0a52ada-bb19-440a-9f46-3f3ea9b92609",
            price: 68943.8,
            price_sources: [],
            processed_ms: 1711571995229,
          },
          {
            leverage: 0.8999999999999999,
            order_type: "LONG",
            order_uuid: "f9ad9b98-332d-45d7-8c57-439f274a2419",
            price: 69174.62,
            price_sources: [],
            processed_ms: 1711575585909,
          },
        ],
        position_type: "FLAT",
        position_uuid: "81ddedbd-5175-44b7-9ece-976024953c65",
        return_at_close: 1.0005487744780597,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 78393.7100000001,
        close_ms: 1711604561940,
        current_return: 1.0047094971692543,
        initial_entry_price: 69366.88,
        is_closed_position: true,
        miner_hotkey: "5G22DcCMnxFiiyaEpa6zx16EFieCdwXtY82aVZ7jPrRMTFr9",
        net_leverage: 0.0,
        open_ms: 1711579227252,
        orders: [
          {
            leverage: -3.0,
            order_type: "SHORT",
            order_uuid: "4abffd7c-2aa2-4b01-adcb-556680a2339d",
            price: 69366.88,
            price_sources: [],
            processed_ms: 1711579227251,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "05d9818e-55f8-4787-8bc6-9d5ccd6a4a7d",
            price: 69073.36,
            price_sources: [],
            processed_ms: 1711582825122,
          },
          {
            leverage: 0.8999999999999999,
            order_type: "LONG",
            order_uuid: "ae42d463-bf3e-43c8-b2db-596130cbbf21",
            price: 69507.02,
            price_sources: [],
            processed_ms: 1711586453645,
          },
          {
            leverage: 0.30000000000000004,
            order_type: "LONG",
            order_uuid: "e196c171-8bb9-43ff-9784-316e0c8a8331",
            price: 69461.73,
            price_sources: [],
            processed_ms: 1711590058717,
          },
          {
            leverage: 0.15000000000000002,
            order_type: "LONG",
            order_uuid: "ba1fdd45-4144-4056-b4d4-60d692c940ed",
            price: 69100.15,
            price_sources: [],
            processed_ms: 1711593691924,
          },
          {
            leverage: 0.07500000000000001,
            order_type: "LONG",
            order_uuid: "826d6a06-4746-4f6a-b7ef-657902e6f332",
            price: 69039.97,
            price_sources: [],
            processed_ms: 1711597306841,
          },
          {
            leverage: 0.037500000000000006,
            order_type: "LONG",
            order_uuid: "560b3c05-9935-4b4b-bee2-aa2b919c9494",
            price: 69679.43,
            price_sources: [],
            processed_ms: 1711600937734,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "ac387a70-704b-4a3a-bca1-c83ca0916a57",
            price: 69682.16,
            price_sources: [],
            processed_ms: 1711604561940,
          },
        ],
        position_type: "FLAT",
        position_uuid: "38116659-fcff-45d2-a726-aca60ad69957",
        return_at_close: 0.9986812401862387,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 67016.86000000044,
        close_ms: 1711789104320,
        current_return: 1.0009957751133536,
        initial_entry_price: 69962.38,
        is_closed_position: true,
        miner_hotkey: "5G22DcCMnxFiiyaEpa6zx16EFieCdwXtY82aVZ7jPrRMTFr9",
        net_leverage: 0.0,
        open_ms: 1711760152909,
        orders: [
          {
            leverage: 3.0,
            order_type: "LONG",
            order_uuid: "b0839ccc-d2e6-4d12-b91d-613a34543f37",
            price: 69962.38,
            price_sources: [],
            processed_ms: 1711760152908,
          },
          {
            leverage: -1.5,
            order_type: "SHORT",
            order_uuid: "30a431e4-3f8f-4b44-b345-ce57fd3a46d3",
            price: 70012.84,
            price_sources: [],
            processed_ms: 1711763769672,
          },
          {
            leverage: -0.75,
            order_type: "SHORT",
            order_uuid: "202e280c-1a26-46cd-9382-c788d568e91e",
            price: 69961.3,
            price_sources: [],
            processed_ms: 1711767405015,
          },
          {
            leverage: -0.375,
            order_type: "SHORT",
            order_uuid: "d83f545b-0f99-494f-84b0-c312b683f909",
            price: 69840.53,
            price_sources: [],
            processed_ms: 1711771001638,
          },
          {
            leverage: -0.1875,
            order_type: "SHORT",
            order_uuid: "66613444-8c39-443f-afbe-956b12fcf665",
            price: 70112.81,
            price_sources: [],
            processed_ms: 1711774729791,
          },
          {
            leverage: -0.09375,
            order_type: "SHORT",
            order_uuid: "664a60d9-bb2b-46a1-8481-de91c7607790",
            price: 70056.88,
            price_sources: [],
            processed_ms: 1711778256548,
          },
          {
            leverage: -0.046875,
            order_type: "SHORT",
            order_uuid: "eab8743f-cffe-40f8-900c-6f8903832c31",
            price: 70047.64,
            price_sources: [],
            processed_ms: 1711781860565,
          },
          {
            leverage: -0.0234375,
            order_type: "SHORT",
            order_uuid: "3eaa7aba-c3f9-49b5-a19c-a55b5ce2a031",
            price: 69910.66,
            price_sources: [],
            processed_ms: 1711785486877,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "e7ce8c2e-9cd3-4f68-9c6c-0803b5b45c13",
            price: 69989.31,
            price_sources: [],
            processed_ms: 1711789104320,
          },
        ],
        position_type: "FLAT",
        position_uuid: "46385a81-4e81-4c9e-8693-9005aef83dd1",
        return_at_close: 0.9949898004626735,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 84649.85999999846,
        close_ms: 1711832512920,
        current_return: 0.9950686785058047,
        initial_entry_price: 70259.2,
        is_closed_position: true,
        miner_hotkey: "5G22DcCMnxFiiyaEpa6zx16EFieCdwXtY82aVZ7jPrRMTFr9",
        net_leverage: 0.0,
        open_ms: 1711803578037,
        orders: [
          {
            leverage: 3.0,
            order_type: "LONG",
            order_uuid: "f92f2bdb-63cc-457e-a807-163c2e29523c",
            price: 70259.2,
            price_sources: [],
            processed_ms: 1711803578036,
          },
          {
            leverage: -1.5,
            order_type: "SHORT",
            order_uuid: "4aaecd1e-a318-456c-a7dc-4bb1fdd0a462",
            price: 70212.36,
            price_sources: [],
            processed_ms: 1711807309220,
          },
          {
            leverage: -0.75,
            order_type: "SHORT",
            order_uuid: "5df3ba0c-3481-4197-966f-b34f468d2884",
            price: 70099.07,
            price_sources: [],
            processed_ms: 1711810815844,
          },
          {
            leverage: -0.375,
            order_type: "SHORT",
            order_uuid: "f30894ae-ebae-4689-8176-93bfe749d59e",
            price: 69983.67,
            price_sources: [],
            processed_ms: 1711814492693,
          },
          {
            leverage: -0.1875,
            order_type: "SHORT",
            order_uuid: "4141d7cd-eb18-4508-877d-f0853e91b42d",
            price: 70224.43,
            price_sources: [],
            processed_ms: 1711818045905,
          },
          {
            leverage: -0.09375,
            order_type: "SHORT",
            order_uuid: "b78091c4-99bd-44be-83ae-d725544236e3",
            price: 70100.35,
            price_sources: [],
            processed_ms: 1711821657072,
          },
          {
            leverage: -0.046875,
            order_type: "SHORT",
            order_uuid: "508079a1-6d69-41e8-a1ff-8476927fc48f",
            price: 69961.27,
            price_sources: [],
            processed_ms: 1711825280613,
          },
          {
            leverage: -0.0234375,
            order_type: "SHORT",
            order_uuid: "e1edcc9d-7b9e-47a7-83f8-2d3d6974b651",
            price: 69908.36,
            price_sources: [],
            processed_ms: 1711828895178,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "6954b037-891b-431c-97b0-7bb9864f9cce",
            price: 69867.11,
            price_sources: [],
            processed_ms: 1711832512920,
          },
        ],
        position_type: "FLAT",
        position_uuid: "0ad35e28-d4fb-4c98-a43e-efc048d06957",
        return_at_close: 0.9890982664347698,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 71327.14,
        close_ms: 1711948328673,
        current_return: 1.0083404635627404,
        initial_entry_price: 70871.96,
        is_closed_position: true,
        miner_hotkey: "5G22DcCMnxFiiyaEpa6zx16EFieCdwXtY82aVZ7jPrRMTFr9",
        net_leverage: 0.0,
        open_ms: 1711937457104,
        orders: [
          {
            leverage: -3.0,
            order_type: "SHORT",
            order_uuid: "8f2725bb-6228-415b-9d41-cbfbceb2d1f2",
            price: 70871.96,
            price_sources: [],
            processed_ms: 1711937457102,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "0123df3b-939c-4442-bb53-514a9aee0fc2",
            price: 70754.59,
            price_sources: [],
            processed_ms: 1711941081654,
          },
          {
            leverage: 0.75,
            order_type: "LONG",
            order_uuid: "693d22c4-29e8-4fd2-8978-9e1557e52f4a",
            price: 70651.52,
            price_sources: [],
            processed_ms: 1711944767166,
          },
          {
            leverage: 0.8999999999999999,
            order_type: "LONG",
            order_uuid: "0d425683-4f88-4e96-8a9b-f0f773eca21c",
            price: 70539.0,
            price_sources: [],
            processed_ms: 1711948328673,
          },
        ],
        position_type: "FLAT",
        position_uuid: "6d21a6cd-88ff-4cdc-a70d-725589bc6c0a",
        return_at_close: 1.0022904207813639,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 18365.69999999979,
        close_ms: 1711980974073,
        current_return: 1.0173851704200845,
        initial_entry_price: 69248.0,
        is_closed_position: true,
        miner_hotkey: "5G22DcCMnxFiiyaEpa6zx16EFieCdwXtY82aVZ7jPrRMTFr9",
        net_leverage: 0.0,
        open_ms: 1711952045023,
        orders: [
          {
            leverage: 3.0,
            order_type: "LONG",
            order_uuid: "38ce5814-99e2-4cec-9824-d973d89b762b",
            price: 69248.0,
            price_sources: [],
            processed_ms: 1711952045022,
          },
          {
            leverage: -1.5,
            order_type: "SHORT",
            order_uuid: "39cd8803-4e2c-4aac-aab4-c8f813f2dc5f",
            price: 69743.6,
            price_sources: [],
            processed_ms: 1711955704508,
          },
          {
            leverage: -0.75,
            order_type: "SHORT",
            order_uuid: "090ba7b2-d815-48d2-9dc3-dc66263fea5c",
            price: 69505.9,
            price_sources: [],
            processed_ms: 1711959218217,
          },
          {
            leverage: -0.375,
            order_type: "SHORT",
            order_uuid: "fac8b878-5b49-4f2b-9333-4ba43c0e8291",
            price: 69592.5,
            price_sources: [],
            processed_ms: 1711962857678,
          },
          {
            leverage: -0.1875,
            order_type: "SHORT",
            order_uuid: "aa85359e-6a91-47b6-b135-192cbb855053",
            price: 69509.0,
            price_sources: [],
            processed_ms: 1711966471321,
          },
          {
            leverage: -0.09375,
            order_type: "SHORT",
            order_uuid: "fbafb470-f008-49c6-8ad7-e4ba7217880c",
            price: 69749.8,
            price_sources: [],
            processed_ms: 1711970124324,
          },
          {
            leverage: -0.046875,
            order_type: "SHORT",
            order_uuid: "174d2c3c-e371-4e7b-9c31-a80bd1ed1b58",
            price: 69639.5,
            price_sources: [],
            processed_ms: 1711973730600,
          },
          {
            leverage: -0.0234375,
            order_type: "SHORT",
            order_uuid: "fc775b08-0d68-4855-8d35-67b1a4ff344c",
            price: 69768.9,
            price_sources: [],
            processed_ms: 1711977350128,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "1de86472-51f3-4314-adbe-b7c02140ba2e",
            price: 69731.6,
            price_sources: [],
            processed_ms: 1711980974073,
          },
        ],
        position_type: "FLAT",
        position_uuid: "6320f4df-8044-4986-91c5-3a025bd0126a",
        return_at_close: 1.011280859397564,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 68176.90952380953,
        close_ms: 1712045903623,
        current_return: 0.9704381092316513,
        initial_entry_price: 69760.0,
        is_closed_position: true,
        miner_hotkey: "5G22DcCMnxFiiyaEpa6zx16EFieCdwXtY82aVZ7jPrRMTFr9",
        net_leverage: 0.0,
        open_ms: 1712007314625,
        orders: [
          {
            leverage: 3.0,
            order_type: "LONG",
            order_uuid: "0d0f253c-c6e0-4af0-bb74-8eade21fafce",
            price: 69760.0,
            price_sources: [],
            processed_ms: 1712007314623,
          },
          {
            leverage: -1.5,
            order_type: "SHORT",
            order_uuid: "072d7206-ed0c-4f3a-8d7a-abceb6a27c90",
            price: 69858.1,
            price_sources: [],
            processed_ms: 1712012946179,
          },
          {
            leverage: -0.75,
            order_type: "SHORT",
            order_uuid: "3dd0acf0-b7d1-45d6-8c4d-91ad425efe5c",
            price: 69519.4,
            price_sources: [],
            processed_ms: 1712016575942,
          },
          {
            leverage: -0.375,
            order_type: "SHORT",
            order_uuid: "a716165b-f0e3-47d3-b83a-d30b38a99685",
            price: 69517.5,
            price_sources: [],
            processed_ms: 1712020246791,
          },
          {
            leverage: 0.8999999999999999,
            order_type: "LONG",
            order_uuid: "9c985c4c-a08d-44b0-8b28-25728b60d526",
            price: 66596.3,
            price_sources: [],
            processed_ms: 1712033403531,
          },
          {
            leverage: 0.8999999999999999,
            order_type: "LONG",
            order_uuid: "f68f9c40-6efd-4923-b89f-3fdf9438e4dc",
            price: 66670.0,
            price_sources: [],
            processed_ms: 1712036993590,
          },
          {
            leverage: 0.8999999999999999,
            order_type: "LONG",
            order_uuid: "63454de1-14f9-46c4-b351-e13b6a1da501",
            price: 67050.0,
            price_sources: [],
            processed_ms: 1712040632923,
          },
          {
            leverage: -1.8937499999999998,
            order_type: "SHORT",
            order_uuid: "f55c9ae1-f400-4633-9e4b-61f39a8a8d24",
            price: 66553.1,
            price_sources: [],
            processed_ms: 1712044314109,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "92179fac-372c-453b-83b9-b0cf11406183",
            price: 66431.1,
            price_sources: [],
            processed_ms: 1712045903623,
          },
        ],
        position_type: "FLAT",
        position_uuid: "4698b9db-647b-4ccf-9680-31151176be91",
        return_at_close: 0.9644699148598767,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 127012.10000000028,
        close_ms: 1712094836712,
        current_return: 1.035091666653981,
        initial_entry_price: 65689.1,
        is_closed_position: true,
        miner_hotkey: "5G22DcCMnxFiiyaEpa6zx16EFieCdwXtY82aVZ7jPrRMTFr9",
        net_leverage: 0.0,
        open_ms: 1712069950285,
        orders: [
          {
            leverage: -3.0,
            order_type: "SHORT",
            order_uuid: "0c4684fc-30f7-41e1-80e6-ddd7e85288ed",
            price: 65689.1,
            price_sources: [],
            processed_ms: 1712069950284,
          },
          {
            leverage: 2.4,
            order_type: "LONG",
            order_uuid: "c8088466-8054-4b26-94d8-1cd0a5230f29",
            price: 64739.7,
            price_sources: [],
            processed_ms: 1712073575919,
          },
          {
            leverage: 0.30000000000000004,
            order_type: "LONG",
            order_uuid: "11239521-d368-4f6c-b184-53b548333335",
            price: 65585.3,
            price_sources: [],
            processed_ms: 1712077195698,
          },
          {
            leverage: 0.15000000000000002,
            order_type: "LONG",
            order_uuid: "50132f67-1f39-4af3-9549-53f90715cee1",
            price: 65485.4,
            price_sources: [],
            processed_ms: 1712081524311,
          },
          {
            leverage: 0.07500000000000001,
            order_type: "LONG",
            order_uuid: "80b1763d-4ccd-4b03-bbcd-0f6be14bdb1a",
            price: 66012.5,
            price_sources: [],
            processed_ms: 1712085190930,
          },
          {
            leverage: 0.037500000000000006,
            order_type: "LONG",
            order_uuid: "7fdf2857-e357-4f47-b1c5-c69e18f60563",
            price: 66126.1,
            price_sources: [],
            processed_ms: 1712088783269,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "ab4cc982-b7f0-40dc-8098-fdde17243015",
            price: 65541.7,
            price_sources: [],
            processed_ms: 1712094836712,
          },
        ],
        position_type: "FLAT",
        position_uuid: "9df66a91-7bc5-41e5-bda0-f5a524f89bd4",
        return_at_close: 1.028881116654057,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: -7463.314285713881,
        close_ms: 1712168196192,
        current_return: 1.0367797879734306,
        initial_entry_price: 65429.3,
        is_closed_position: true,
        miner_hotkey: "5G22DcCMnxFiiyaEpa6zx16EFieCdwXtY82aVZ7jPrRMTFr9",
        net_leverage: 0.0,
        open_ms: 1712102218049,
        orders: [
          {
            leverage: 3.0,
            order_type: "LONG",
            order_uuid: "2978d179-1126-4453-ad97-a46253925b79",
            price: 65429.3,
            price_sources: [],
            processed_ms: 1712102218047,
          },
          {
            leverage: -1.5,
            order_type: "SHORT",
            order_uuid: "c87f32f9-8d3d-47a0-8808-1290febe242b",
            price: 65471.6,
            price_sources: [],
            processed_ms: 1712107881203,
          },
          {
            leverage: 0.8999999999999999,
            order_type: "LONG",
            order_uuid: "071e4feb-e34c-484c-9140-c9f7986c8332",
            price: 65903.8,
            price_sources: [],
            processed_ms: 1712111503219,
          },
          {
            leverage: 0.8999999999999999,
            order_type: "LONG",
            order_uuid: "969c062f-0130-422a-b5f6-1f32fba48c9f",
            price: 65914.5,
            price_sources: [],
            processed_ms: 1712115139270,
          },
          {
            leverage: 0.8999999999999995,
            order_type: "LONG",
            order_uuid: "09c2cb02-10b9-4b4a-88f7-6c506ad20895",
            price: 66279.1,
            price_sources: [],
            processed_ms: 1712126628690,
          },
          {
            leverage: -2.0999999999999996,
            order_type: "SHORT",
            order_uuid: "80045347-0a22-46e2-8c70-91fa86942a0e",
            price: 66382.1,
            price_sources: [],
            processed_ms: 1712130253523,
          },
          {
            leverage: -1.0499999999999998,
            order_type: "SHORT",
            order_uuid: "6272f13e-cd7e-42e8-ae20-cf36050dacb7",
            price: 66605.1,
            price_sources: [],
            processed_ms: 1712133882619,
          },
          {
            leverage: -0.5249999999999999,
            order_type: "SHORT",
            order_uuid: "9d20cf02-5242-4cf2-95a2-5a7af527c8c3",
            price: 66297.6,
            price_sources: [],
            processed_ms: 1712140386196,
          },
          {
            leverage: -0.26249999999999996,
            order_type: "SHORT",
            order_uuid: "c2ae7ab7-e845-4639-8237-a42a1cc5f4ce",
            price: 65938.7,
            price_sources: [],
            processed_ms: 1712144048872,
          },
          {
            leverage: -0.13124999999999998,
            order_type: "SHORT",
            order_uuid: "f65a7340-9ce6-418b-8a25-c910cddd7e2e",
            price: 65872.7,
            price_sources: [],
            processed_ms: 1712147649596,
          },
          {
            leverage: -0.06562499999999999,
            order_type: "SHORT",
            order_uuid: "44e21a8f-9817-4c67-ad07-dffa965ac821",
            price: 66100.6,
            price_sources: [],
            processed_ms: 1712161016593,
          },
          {
            leverage: -0.032812499999999994,
            order_type: "SHORT",
            order_uuid: "6d5cedc2-3f70-4811-9025-c8beb1c7357d",
            price: 66331.2,
            price_sources: [],
            processed_ms: 1712164477631,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "9cf24d92-3706-46b7-b803-77df545ffdd7",
            price: 65876.9,
            price_sources: [],
            processed_ms: 1712168196192,
          },
        ],
        position_type: "FLAT",
        position_uuid: "225b0e61-b9a5-48c6-af9c-d752f8bb6214",
        return_at_close: 1.0280708377544538,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 74088.3000000002,
        close_ms: 1712425002734,
        current_return: 1.0032576176366532,
        initial_entry_price: 67836.0,
        is_closed_position: true,
        miner_hotkey: "5G22DcCMnxFiiyaEpa6zx16EFieCdwXtY82aVZ7jPrRMTFr9",
        net_leverage: 0.0,
        open_ms: 1712345948534,
        orders: [
          {
            leverage: -3.0,
            order_type: "SHORT",
            order_uuid: "d36d0c4c-27ed-4ec5-a868-53adf7fa2eeb",
            price: 67836.0,
            price_sources: [],
            processed_ms: 1712345948532,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "cac39233-318b-4d75-9d9b-2f7c148aee81",
            price: 67874.9,
            price_sources: [],
            processed_ms: 1712367508163,
          },
          {
            leverage: 0.8999999999999999,
            order_type: "LONG",
            order_uuid: "aa43700c-f490-4893-892a-334eb760f1db",
            price: 67509.9,
            price_sources: [],
            processed_ms: 1712371160799,
          },
          {
            leverage: 0.30000000000000004,
            order_type: "LONG",
            order_uuid: "613030fb-2bec-490d-abc6-d4d26297816e",
            price: 67809.3,
            price_sources: [],
            processed_ms: 1712374748430,
          },
          {
            leverage: 0.15000000000000002,
            order_type: "LONG",
            order_uuid: "426cbda0-8462-4b20-aba7-5f8192c459dd",
            price: 67941.1,
            price_sources: [],
            processed_ms: 1712396843425,
          },
          {
            leverage: 0.07500000000000001,
            order_type: "LONG",
            order_uuid: "4be2841f-47da-4348-842c-2de0302cfffd",
            price: 67793.1,
            price_sources: [],
            processed_ms: 1712400451901,
          },
          {
            leverage: 0.037500000000000006,
            order_type: "LONG",
            order_uuid: "015efeb0-888b-4679-b071-43134db7e499",
            price: 67733.1,
            price_sources: [],
            processed_ms: 1712404128698,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "b0146b81-1532-40f2-8511-20b8660035c6",
            price: 68195.4,
            price_sources: [],
            processed_ms: 1712425002734,
          },
        ],
        position_type: "FLAT",
        position_uuid: "7b392f8c-752b-46ab-bba6-477191193a4f",
        return_at_close: 0.9972380719308332,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 71870.55000000009,
        close_ms: 1712546778093,
        current_return: 1.0227672411810707,
        initial_entry_price: 69577.6,
        is_closed_position: true,
        miner_hotkey: "5G22DcCMnxFiiyaEpa6zx16EFieCdwXtY82aVZ7jPrRMTFr9",
        net_leverage: 0.0,
        open_ms: 1712513804113,
        orders: [
          {
            leverage: -3.0,
            order_type: "SHORT",
            order_uuid: "ea220b42-38c5-40cf-abcd-da885e971d9b",
            price: 69577.6,
            price_sources: [],
            processed_ms: 1712513804111,
          },
          {
            leverage: -3.0,
            order_type: "SHORT",
            order_uuid: "7e538a8f-eab8-4209-a1b4-c7c929a4ee6a",
            price: 69540.8,
            price_sources: [],
            processed_ms: 1712513889192,
          },
          {
            leverage: 3.0,
            order_type: "LONG",
            order_uuid: "420d1ebc-8843-4d95-8a26-c5facd4e7765",
            price: 69320.3,
            price_sources: [],
            processed_ms: 1712517412414,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "ccc3f20a-7adb-4cc3-9a1b-71d4a6fa8399",
            price: 69264.2,
            price_sources: [],
            processed_ms: 1712521036362,
          },
          {
            leverage: 0.8999999999999999,
            order_type: "LONG",
            order_uuid: "b2298113-5d08-41f9-b1cf-4bbb84c3d2f1",
            price: 69306.3,
            price_sources: [],
            processed_ms: 1712543153223,
          },
          {
            leverage: 0.8999999999999999,
            order_type: "LONG",
            order_uuid: "d2d56509-6ca4-40e4-a030-774027af3276",
            price: 69230.4,
            price_sources: [],
            processed_ms: 1712546778093,
          },
        ],
        position_type: "FLAT",
        position_uuid: "208deaf9-1d74-41e9-8cec-ab88c09ff293",
        return_at_close: 1.0104940342868978,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 69326.5,
        close_ms: 1712564018608,
        current_return: 0.9192473296647022,
        initial_entry_price: 69326.5,
        is_closed_position: true,
        miner_hotkey: "5G22DcCMnxFiiyaEpa6zx16EFieCdwXtY82aVZ7jPrRMTFr9",
        net_leverage: -3.0,
        open_ms: 1712550402078,
        orders: [
          {
            leverage: -3.0,
            order_type: "SHORT",
            order_uuid: "f513a550-a787-495f-ad7b-be4bf515fa88",
            price: 69326.5,
            price_sources: [],
            processed_ms: 1712550400429,
          },
        ],
        position_type: "FLAT",
        position_uuid: "da0630b8-b947-4c70-962a-9ec5e593f9c4",
        return_at_close: 0.9137318456867141,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
    ],
    thirty_day_returns: 1.0,
  },
  "5G3ys2356ovgUivX3endMP7f37LPEjRkzDAM3Km8CxQnErCw": {
    all_time_returns: 1.0626857217178403,
    n_positions: 201,
    percentage_profitable: 1.0,
    positions: [
      {
        average_entry_price: 0.9759,
        close_ms: 1712541029403,
        current_return: 1.0000342248181167,
        initial_entry_price: 0.9759,
        is_closed_position: true,
        miner_hotkey: "5G3ys2356ovgUivX3endMP7f37LPEjRkzDAM3Km8CxQnErCw",
        net_leverage: 0.0,
        open_ms: 1712524610463,
        orders: [
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "c320be29-ad13-4dbd-8300-4b2b1f136e89",
            price: 0.9759,
            price_sources: [],
            processed_ms: 1712524610462,
          },
          {
            leverage: 0.01,
            order_type: "FLAT",
            order_uuid: "0e831019-ab0e-4eb5-b557-96d30e3c4315",
            price: 0.97924,
            price_sources: [],
            processed_ms: 1712541029403,
          },
        ],
        position_type: "FLAT",
        position_uuid: "6e5baf35-65ec-48eb-8226-33ab8e46a711",
        return_at_close: 1.0000335247941594,
        trade_pair: ["EURCHF", "EUR/CHF", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 164.245,
        close_ms: 1712541455535,
        current_return: 1.0022502968126883,
        initial_entry_price: 164.245,
        is_closed_position: true,
        miner_hotkey: "5G3ys2356ovgUivX3endMP7f37LPEjRkzDAM3Km8CxQnErCw",
        net_leverage: 0.0,
        open_ms: 1712524507570,
        orders: [
          {
            leverage: 1.65,
            order_type: "LONG",
            order_uuid: "7ce42dc6-47bf-4715-8d7d-05b1a4d6c480",
            price: 164.245,
            price_sources: [],
            processed_ms: 1712524507570,
          },
          {
            leverage: 1.65,
            order_type: "FLAT",
            order_uuid: "ac0e0040-618b-4028-8da9-99d2c5817193",
            price: 164.469,
            price_sources: [],
            processed_ms: 1712541455535,
          },
        ],
        position_type: "FLAT",
        position_uuid: "cd38b017-0363-4d00-bcfd-f96af115f83d",
        return_at_close: 1.0021345369034065,
        trade_pair: ["EURJPY", "EUR/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 91.046,
        close_ms: 1712541575370,
        current_return: 1.016144915756869,
        initial_entry_price: 91.046,
        is_closed_position: true,
        miner_hotkey: "5G3ys2356ovgUivX3endMP7f37LPEjRkzDAM3Km8CxQnErCw",
        net_leverage: 0.0,
        open_ms: 1712524914236,
        orders: [
          {
            leverage: 9.13,
            order_type: "LONG",
            order_uuid: "8da7521a-b5bc-43ea-b30a-94a15f041962",
            price: 91.046,
            price_sources: [],
            processed_ms: 1712524914236,
          },
          {
            leverage: 9.13,
            order_type: "FLAT",
            order_uuid: "ea48ff73-2306-4f47-a66f-ea4d6ae282c2",
            price: 91.207,
            price_sources: [],
            processed_ms: 1712541575370,
          },
        ],
        position_type: "FLAT",
        position_uuid: "25967a8c-cd84-424c-a853-3473cb5737cb",
        return_at_close: 1.0154954975412087,
        trade_pair: ["NZDJPY", "NZD/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.65636,
        close_ms: 1712541677177,
        current_return: 1.0000150831860564,
        initial_entry_price: 0.65636,
        is_closed_position: true,
        miner_hotkey: "5G3ys2356ovgUivX3endMP7f37LPEjRkzDAM3Km8CxQnErCw",
        net_leverage: 0.0,
        open_ms: 1712535622010,
        orders: [
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "0d98e533-d996-4ec3-8049-72342ea00ece",
            price: 0.65636,
            price_sources: [],
            processed_ms: 1712535620517,
          },
          {
            leverage: 0.01,
            order_type: "FLAT",
            order_uuid: "8da6dda1-5cfe-4da1-840d-ae2e191bd92f",
            price: 0.65735,
            price_sources: [],
            processed_ms: 1712541677177,
          },
        ],
        position_type: "FLAT",
        position_uuid: "3efd48db-b3d1-47fd-9305-7851392990aa",
        return_at_close: 1.0000143831754982,
        trade_pair: ["AUDUSD", "AUD/USD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 99.583,
        close_ms: 1712542268213,
        current_return: 1.0022694636634766,
        initial_entry_price: 99.583,
        is_closed_position: true,
        miner_hotkey: "5G3ys2356ovgUivX3endMP7f37LPEjRkzDAM3Km8CxQnErCw",
        net_leverage: 0.0,
        open_ms: 1712524821912,
        orders: [
          {
            leverage: 1.0,
            order_type: "LONG",
            order_uuid: "e5826ff0-33b9-4371-9d2c-fd1002abbb90",
            price: 99.583,
            price_sources: [],
            processed_ms: 1712524821912,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "ef866f80-8f32-41da-848f-7bf72fe7552f",
            price: 99.809,
            price_sources: [],
            processed_ms: 1712542268213,
          },
        ],
        position_type: "FLAT",
        position_uuid: "9e631df1-9963-4531-8e4a-9510c9abc3dc",
        return_at_close: 1.00219930480102,
        trade_pair: ["AUDJPY", "AUD/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.60134,
        close_ms: 1712547295621,
        current_return: 1.0000144676888283,
        initial_entry_price: 0.60134,
        is_closed_position: true,
        miner_hotkey: "5G3ys2356ovgUivX3endMP7f37LPEjRkzDAM3Km8CxQnErCw",
        net_leverage: 0.0,
        open_ms: 1712543112418,
        orders: [
          {
            leverage: -0.01,
            order_type: "SHORT",
            order_uuid: "6ec6fbdf-8241-4c13-b4d5-012030f74423",
            price: 0.60134,
            price_sources: [],
            processed_ms: 1712543111699,
          },
          {
            leverage: 0.02,
            order_type: "FLAT",
            order_uuid: "4264d3d2-31dc-4d66-a266-b1f3ddbb8c4c",
            price: 0.60047,
            price_sources: [],
            processed_ms: 1712547295621,
          },
        ],
        position_type: "FLAT",
        position_uuid: "7d49f934-55e2-4d4c-afeb-77322a600061",
        return_at_close: 1.0000137676787009,
        trade_pair: ["NZDUSD", "NZD/USD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 164.412,
        close_ms: 1712556405376,
        current_return: 1.000004561710824,
        initial_entry_price: 164.412,
        is_closed_position: true,
        miner_hotkey: "5G3ys2356ovgUivX3endMP7f37LPEjRkzDAM3Km8CxQnErCw",
        net_leverage: 0.0,
        open_ms: 1712548811178,
        orders: [
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "756639bc-452d-443d-bc50-c3501da98279",
            price: 164.412,
            price_sources: [],
            processed_ms: 1712548811177,
          },
          {
            leverage: 0.01,
            order_type: "FLAT",
            order_uuid: "0e148678-3316-4dbd-ad2c-ee595cdfd7b7",
            price: 164.487,
            price_sources: [],
            processed_ms: 1712556405376,
          },
        ],
        position_type: "FLAT",
        position_uuid: "f59275bc-a5aa-48ba-96b8-0f4daf0c37dd",
        return_at_close: 1.0000038617076308,
        trade_pair: ["EURJPY", "EUR/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.65729,
        close_ms: 1712560545161,
        current_return: 1.0000334707663283,
        initial_entry_price: 0.65729,
        is_closed_position: true,
        miner_hotkey: "5G3ys2356ovgUivX3endMP7f37LPEjRkzDAM3Km8CxQnErCw",
        net_leverage: 0.0,
        open_ms: 1712547920293,
        orders: [
          {
            leverage: 0.02,
            order_type: "LONG",
            order_uuid: "c5f03959-242f-4d71-a5ba-190e049b73c8",
            price: 0.65729,
            price_sources: [],
            processed_ms: 1712547920292,
          },
          {
            leverage: 0.02,
            order_type: "FLAT",
            order_uuid: "c051af8e-2a3d-4141-adef-e3ca6308ff24",
            price: 0.65839,
            price_sources: [],
            processed_ms: 1712560545161,
          },
        ],
        position_type: "FLAT",
        position_uuid: "5c6112c7-f2a2-4978-b2ef-93c185f7cb6b",
        return_at_close: 1.0000320707194692,
        trade_pair: ["AUDUSD", "AUD/USD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 164.396,
        close_ms: 1712568481623,
        current_return: 1.000004562154797,
        initial_entry_price: 164.396,
        is_closed_position: true,
        miner_hotkey: "5G3ys2356ovgUivX3endMP7f37LPEjRkzDAM3Km8CxQnErCw",
        net_leverage: 0.0,
        open_ms: 1712568015527,
        orders: [
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "c7419c5e-d4e4-4cbb-aa36-9df4dacb5465",
            price: 164.396,
            price_sources: [],
            processed_ms: 1712568015526,
          },
          {
            leverage: 0.01,
            order_type: "FLAT",
            order_uuid: "a998d131-3ffa-45b2-a5e1-41f8ba8fa40f",
            price: 164.471,
            price_sources: [],
            processed_ms: 1712568481623,
          },
        ],
        position_type: "FLAT",
        position_uuid: "9db625b5-425c-4c59-88a8-2c0b455a452d",
        return_at_close: 1.0000038621516036,
        trade_pair: ["EURJPY", "EUR/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 167.738,
        close_ms: 1712580669110,
        current_return: 1.0000054632343298,
        initial_entry_price: 167.738,
        is_closed_position: true,
        miner_hotkey: "5G3ys2356ovgUivX3endMP7f37LPEjRkzDAM3Km8CxQnErCw",
        net_leverage: 0.0,
        open_ms: 1712565315644,
        orders: [
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "f88e751b-952f-4358-963e-437c2f053672",
            price: 167.738,
            price_sources: [],
            processed_ms: 1712565315643,
          },
          {
            leverage: 0.01,
            order_type: "FLAT",
            order_uuid: "0dce2377-11fc-4a14-8d19-4094ab9efa35",
            price: 167.8296392,
            price_sources: [],
            processed_ms: 1712580669110,
          },
        ],
        position_type: "FLAT",
        position_uuid: "32a773b9-f7b9-4a96-a8aa-fbdd5c95f963",
        return_at_close: 1.0000047632305056,
        trade_pair: ["CHFJPY", "CHF/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 167.749,
        close_ms: 1712585057267,
        current_return: 1.0000056449874515,
        initial_entry_price: 167.749,
        is_closed_position: true,
        miner_hotkey: "5G3ys2356ovgUivX3endMP7f37LPEjRkzDAM3Km8CxQnErCw",
        net_leverage: 0.0,
        open_ms: 1712582143070,
        orders: [
          {
            leverage: -0.01,
            order_type: "SHORT",
            order_uuid: "d5e50fe3-6440-4ed2-aea1-594cfc5ebf52",
            price: 167.749,
            price_sources: [],
            processed_ms: 1712582143069,
          },
          {
            leverage: 0.01,
            order_type: "FLAT",
            order_uuid: "906b46fd-3bf5-4e0e-80d6-d6649462c3cf",
            price: 167.6543059,
            price_sources: [],
            processed_ms: 1712585057267,
          },
        ],
        position_type: "FLAT",
        position_uuid: "89451420-26fb-41bd-81b1-b5db3029a9aa",
        return_at_close: 1.0000049449835,
        trade_pair: ["CHFJPY", "CHF/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 164.82049999999998,
        close_ms: 1712642923434,
        current_return: 1.0000181462869906,
        initial_entry_price: 164.772,
        is_closed_position: true,
        miner_hotkey: "5G3ys2356ovgUivX3endMP7f37LPEjRkzDAM3Km8CxQnErCw",
        net_leverage: 0.0,
        open_ms: 1712611243037,
        orders: [
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "6e11afde-0c20-44a1-ba93-99c70424d6ed",
            price: 164.772,
            price_sources: [],
            processed_ms: 1712611241921,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "dec8f6f8-35f5-46ee-81c4-98aefeb3fc79",
            price: 164.869,
            price_sources: [],
            processed_ms: 1712611343086,
          },
          {
            leverage: 0.01,
            order_type: "FLAT",
            order_uuid: "d2435b2b-b096-453d-88d2-c055d3f69d5b",
            price: 164.97,
            price_sources: [],
            processed_ms: 1712642923434,
          },
        ],
        position_type: "FLAT",
        position_uuid: "19c61ff3-9a69-4a32-ac5d-53eea1b98346",
        return_at_close: 1.0000167462615857,
        trade_pair: ["EURJPY", "EUR/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 151.84,
        close_ms: 1712654905366,
        current_return: 1.000003490516333,
        initial_entry_price: 151.84,
        is_closed_position: true,
        miner_hotkey: "5G3ys2356ovgUivX3endMP7f37LPEjRkzDAM3Km8CxQnErCw",
        net_leverage: 0.0,
        open_ms: 1712607938582,
        orders: [
          {
            leverage: -0.01,
            order_type: "SHORT",
            order_uuid: "170bc2ff-773a-43cc-bfaf-04220fe329e0",
            price: 151.84,
            price_sources: [],
            processed_ms: 1712607938581,
          },
          {
            leverage: 0.01,
            order_type: "FLAT",
            order_uuid: "dc08e8fd-58a3-464d-a28c-6aa2bbc917ad",
            price: 151.787,
            price_sources: [],
            processed_ms: 1712654905366,
          },
        ],
        position_type: "FLAT",
        position_uuid: "cef29890-9b71-4647-b204-3e49d4e32780",
        return_at_close: 1.0000027905138897,
        trade_pair: ["USDJPY", "USD/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 164.888,
        close_ms: 1712664438646,
        current_return: 1.0000059434282664,
        initial_entry_price: 164.888,
        is_closed_position: true,
        miner_hotkey: "5G3ys2356ovgUivX3endMP7f37LPEjRkzDAM3Km8CxQnErCw",
        net_leverage: 0.0,
        open_ms: 1712663731106,
        orders: [
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "acc14c48-1dcb-4e21-8402-f36bd333dbe8",
            price: 164.888,
            price_sources: [],
            processed_ms: 1712663731105,
          },
          {
            leverage: 0.01,
            order_type: "FLAT",
            order_uuid: "bf792fe2-168b-446e-b627-6005eaa7df53",
            price: 164.986,
            price_sources: [],
            processed_ms: 1712664438646,
          },
        ],
        position_type: "FLAT",
        position_uuid: "ab7cdcd4-a141-40e0-9b53-43fd8221d07c",
        return_at_close: 1.000005243424106,
        trade_pair: ["EURJPY", "EUR/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 100.4585,
        close_ms: 1712673728969,
        current_return: 1.0000162658417324,
        initial_entry_price: 100.21,
        is_closed_position: true,
        miner_hotkey: "5G3ys2356ovgUivX3endMP7f37LPEjRkzDAM3Km8CxQnErCw",
        net_leverage: 0.0,
        open_ms: 1712585174928,
        orders: [
          {
            leverage: -0.01,
            order_type: "SHORT",
            order_uuid: "13d571ea-534b-4797-9b4a-11efdf241d52",
            price: 100.21,
            price_sources: [],
            processed_ms: 1712585174926,
          },
          {
            leverage: -0.01,
            order_type: "SHORT",
            order_uuid: "4d22cf9e-3aa6-4534-8e4a-88f975369323",
            price: 100.707,
            price_sources: [],
            processed_ms: 1712669139943,
          },
          {
            leverage: 0.01,
            order_type: "FLAT",
            order_uuid: "7ba9e5f2-1135-43a4-a849-4450e0d28d85",
            price: 100.377,
            price_sources: [],
            processed_ms: 1712673728969,
          },
        ],
        position_type: "FLAT",
        position_uuid: "eb151f82-2d3c-46d0-a40e-60ea5e3e0da8",
        return_at_close: 1.0000148658189603,
        trade_pair: ["AUDJPY", "AUD/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 167.8422179,
        close_ms: 1712675864540,
        current_return: 1.0000036358194477,
        initial_entry_price: 167.8422179,
        is_closed_position: true,
        miner_hotkey: "5G3ys2356ovgUivX3endMP7f37LPEjRkzDAM3Km8CxQnErCw",
        net_leverage: 0.0,
        open_ms: 1712674833249,
        orders: [
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "4d15061a-ec35-4289-9318-dc3bc916b93e",
            price: 167.8422179,
            price_sources: [],
            processed_ms: 1712674833247,
          },
          {
            leverage: 0.01,
            order_type: "FLAT",
            order_uuid: "57f71089-e336-4760-b386-e4d50d334154",
            price: 167.9032423,
            price_sources: [],
            processed_ms: 1712675864540,
          },
        ],
        position_type: "FLAT",
        position_uuid: "46ab26e7-c569-4c54-835f-b5e0dbc01c13",
        return_at_close: 1.0000029358169027,
        trade_pair: ["CHFJPY", "CHF/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 1.35945,
        close_ms: 1712677392852,
        current_return: 1.00001618301519,
        initial_entry_price: 1.35945,
        is_closed_position: true,
        miner_hotkey: "5G3ys2356ovgUivX3endMP7f37LPEjRkzDAM3Km8CxQnErCw",
        net_leverage: 0.0,
        open_ms: 1712675054870,
        orders: [
          {
            leverage: -0.02,
            order_type: "SHORT",
            order_uuid: "c2e37b79-c6f1-422d-a731-08930698e196",
            price: 1.35945,
            price_sources: [],
            processed_ms: 1712675054868,
          },
          {
            leverage: 0.02,
            order_type: "FLAT",
            order_uuid: "8a699afd-63d5-4c89-a2f9-213c5a7b0c4c",
            price: 1.35835,
            price_sources: [],
            processed_ms: 1712677392852,
          },
        ],
        position_type: "FLAT",
        position_uuid: "61a307ca-47f4-4b59-a3c9-47d2677323db",
        return_at_close: 1.0000147829925337,
        trade_pair: ["USDCAD", "USD/CAD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 164.666,
        close_ms: 1712693049060,
        current_return: 1.0000058299831174,
        initial_entry_price: 164.666,
        is_closed_position: true,
        miner_hotkey: "5G3ys2356ovgUivX3endMP7f37LPEjRkzDAM3Km8CxQnErCw",
        net_leverage: 0.0,
        open_ms: 1712675442360,
        orders: [
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "7e0386a3-52b2-4d66-8dde-eeb8706219a8",
            price: 164.666,
            price_sources: [],
            processed_ms: 1712675442359,
          },
          {
            leverage: 0.01,
            order_type: "FLAT",
            order_uuid: "e7975a9c-a66c-442d-882c-c87ef61797fe",
            price: 164.762,
            price_sources: [],
            processed_ms: 1712693049060,
          },
        ],
        position_type: "FLAT",
        position_uuid: "9019dead-d56b-488e-a339-de28300fa652",
        return_at_close: 1.0000051299790365,
        trade_pair: ["EURJPY", "EUR/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 151.792,
        close_ms: 1712752277929,
        current_return: 1.0000335986086224,
        initial_entry_price: 151.792,
        is_closed_position: true,
        miner_hotkey: "5G3ys2356ovgUivX3endMP7f37LPEjRkzDAM3Km8CxQnErCw",
        net_leverage: 0.0,
        open_ms: 1712655628400,
        orders: [
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "bd6b4f0b-4d12-4887-9dc5-6a3c49a27b38",
            price: 151.792,
            price_sources: [],
            processed_ms: 1712655628398,
          },
          {
            leverage: 0.01,
            order_type: "FLAT",
            order_uuid: "53c90fad-6451-486a-92b5-e99faac5afa7",
            price: 152.302,
            price_sources: [],
            processed_ms: 1712752277929,
          },
        ],
        position_type: "FLAT",
        position_uuid: "487a50c7-256a-4213-a53a-5f78c26417b6",
        return_at_close: 1.0000328985851035,
        trade_pair: ["USDJPY", "USD/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.9035,
        close_ms: 1712752772019,
        current_return: 1.0001268400664085,
        initial_entry_price: 0.9035,
        is_closed_position: true,
        miner_hotkey: "5G3ys2356ovgUivX3endMP7f37LPEjRkzDAM3Km8CxQnErCw",
        net_leverage: 0.0,
        open_ms: 1712700371826,
        orders: [
          {
            leverage: 0.02,
            order_type: "LONG",
            order_uuid: "3999d142-3594-4cdf-8639-7593bf9e137f",
            price: 0.9035,
            price_sources: [],
            processed_ms: 1712700371825,
          },
          {
            leverage: 0.02,
            order_type: "FLAT",
            order_uuid: "f56ff86e-67fe-466b-b2ea-4f514afe6296",
            price: 0.90923,
            price_sources: [],
            processed_ms: 1712752772019,
          },
        ],
        position_type: "FLAT",
        position_uuid: "312d2ef7-e7de-48d0-8051-a190a1c869b7",
        return_at_close: 1.0001254398888324,
        trade_pair: ["USDCHF", "USD/CHF", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.60453,
        close_ms: 1712752777613,
        current_return: 1.000307385229541,
        initial_entry_price: 0.6012,
        is_closed_position: true,
        miner_hotkey: "5G3ys2356ovgUivX3endMP7f37LPEjRkzDAM3Km8CxQnErCw",
        net_leverage: 0.0,
        open_ms: 1712554513456,
        orders: [
          {
            leverage: -0.02,
            order_type: "SHORT",
            order_uuid: "823c90f3-5df2-4e42-bfcc-ec671a3c4713",
            price: 0.6012,
            price_sources: [],
            processed_ms: 1712554513455,
          },
          {
            leverage: -0.02,
            order_type: "SHORT",
            order_uuid: "bdf4e7e7-0364-44fe-9542-5a1551c74f33",
            price: 0.60511,
            price_sources: [],
            processed_ms: 1712656830237,
          },
          {
            leverage: -0.02,
            order_type: "SHORT",
            order_uuid: "c08ff8b5-960d-4c3a-8d3d-5bebb7b1b099",
            price: 0.60728,
            price_sources: [],
            processed_ms: 1712669770219,
          },
          {
            leverage: 0.02,
            order_type: "FLAT",
            order_uuid: "424f8d09-5948-47aa-91a3-6016d0c6787b",
            price: 0.60145,
            price_sources: [],
            processed_ms: 1712752777613,
          },
        ],
        position_type: "FLAT",
        position_uuid: "7d26b5a9-571b-41c7-bcd9-f7319727f6a9",
        return_at_close: 1.000303183938523,
        trade_pair: ["NZDUSD", "NZD/USD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.662415,
        close_ms: 1712752777800,
        current_return: 1.000427779964276,
        initial_entry_price: 0.66062,
        is_closed_position: true,
        miner_hotkey: "5G3ys2356ovgUivX3endMP7f37LPEjRkzDAM3Km8CxQnErCw",
        net_leverage: 0.0,
        open_ms: 1712619033547,
        orders: [
          {
            leverage: -0.02,
            order_type: "SHORT",
            order_uuid: "6f273508-511a-4090-9603-4520131d8706",
            price: 0.66062,
            price_sources: [],
            processed_ms: 1712619033545,
          },
          {
            leverage: -0.02,
            order_type: "SHORT",
            order_uuid: "d16dd0d9-98c2-421c-8354-8cc675c7f07b",
            price: 0.66421,
            price_sources: [],
            processed_ms: 1712669769890,
          },
          {
            leverage: 0.02,
            order_type: "FLAT",
            order_uuid: "5ba5884b-44a3-4e47-b83b-9b8c8d5aacec",
            price: 0.65535,
            price_sources: [],
            processed_ms: 1712752777800,
          },
        ],
        position_type: "FLAT",
        position_uuid: "437c0017-a9a9-4de6-b157-c426b2d383be",
        return_at_close: 1.000424978766492,
        trade_pair: ["AUDUSD", "AUD/USD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.9075999999999999,
        close_ms: 1712754826810,
        current_return: 1.0000572939620977,
        initial_entry_price: 0.9076,
        is_closed_position: true,
        miner_hotkey: "5G3ys2356ovgUivX3endMP7f37LPEjRkzDAM3Km8CxQnErCw",
        net_leverage: 0.0,
        open_ms: 1712753264709,
        orders: [
          {
            leverage: 0.02,
            order_type: "LONG",
            order_uuid: "de55addb-bc19-4034-ad72-10e50ebef9a0",
            price: 0.9076,
            price_sources: [],
            processed_ms: 1712753264708,
          },
          {
            leverage: 0.02,
            order_type: "FLAT",
            order_uuid: "39b28f22-b7d3-4bc5-8957-b8f3371ad78a",
            price: 0.9102,
            price_sources: [],
            processed_ms: 1712754826810,
          },
        ],
        position_type: "FLAT",
        position_uuid: "2bb3004f-d529-4ad4-a2ec-e50818fe214e",
        return_at_close: 1.0000558938818862,
        trade_pair: ["USDCHF", "USD/CHF", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.8983933280666667,
        close_ms: 1712755047949,
        current_return: 1.0005868118361056,
        initial_entry_price: 0.8961978843,
        is_closed_position: true,
        miner_hotkey: "5G3ys2356ovgUivX3endMP7f37LPEjRkzDAM3Km8CxQnErCw",
        net_leverage: 0.0,
        open_ms: 1712583322608,
        orders: [
          {
            leverage: -0.02,
            order_type: "SHORT",
            order_uuid: "b289a6ff-59db-4510-9ab0-1e2f9bf6d84f",
            price: 0.8961978843,
            price_sources: [],
            processed_ms: 1712583322607,
          },
          {
            leverage: -0.02,
            order_type: "SHORT",
            order_uuid: "fb0b4618-a45d-4ad1-9cf5-98b9c0b6181d",
            price: 0.897827092,
            price_sources: [],
            processed_ms: 1712586345401,
          },
          {
            leverage: -0.02,
            order_type: "SHORT",
            order_uuid: "32155423-4eb3-4be2-b7e9-2daac4314637",
            price: 0.89879,
            price_sources: [],
            processed_ms: 1712662254205,
          },
          {
            leverage: -0.03,
            order_type: "SHORT",
            order_uuid: "b215fa45-9512-4f7e-bb59-20bd907a0179",
            price: 0.89997,
            price_sources: [],
            processed_ms: 1712667047839,
          },
          {
            leverage: 0.03,
            order_type: "FLAT",
            order_uuid: "17050b2b-46fc-481a-aa40-8ed80f8e34d9",
            price: 0.89255,
            price_sources: [],
            processed_ms: 1712755047949,
          },
        ],
        position_type: "FLAT",
        position_uuid: "08be654b-ef5d-4e31-bd4c-d9fd70bdde47",
        return_at_close: 1.000580508139191,
        trade_pair: ["AUDCAD", "AUD/CAD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.91351,
        close_ms: 1712770373316,
        current_return: 1.0000188284747842,
        initial_entry_price: 0.91351,
        is_closed_position: true,
        miner_hotkey: "5G3ys2356ovgUivX3endMP7f37LPEjRkzDAM3Km8CxQnErCw",
        net_leverage: 0.0,
        open_ms: 1712769958164,
        orders: [
          {
            leverage: -0.02,
            order_type: "SHORT",
            order_uuid: "8c58a7ff-f938-48d1-8816-71b4f179dac4",
            price: 0.91351,
            price_sources: [],
            processed_ms: 1712769958162,
          },
          {
            leverage: 0.02,
            order_type: "FLAT",
            order_uuid: "797f7d71-6bde-4961-a2de-860ac23f7c57",
            price: 0.91265,
            price_sources: [],
            processed_ms: 1712770373316,
          },
        ],
        position_type: "FLAT",
        position_uuid: "b6a4e539-98ee-42e3-b4d9-cec1eb666572",
        return_at_close: 1.0000174284484242,
        trade_pair: ["USDCHF", "USD/CHF", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 152.84,
        close_ms: 1712774070785,
        current_return: 1.000013216435488,
        initial_entry_price: 152.84,
        is_closed_position: true,
        miner_hotkey: "5G3ys2356ovgUivX3endMP7f37LPEjRkzDAM3Km8CxQnErCw",
        net_leverage: 0.0,
        open_ms: 1712771170197,
        orders: [
          {
            leverage: 0.02,
            order_type: "LONG",
            order_uuid: "51b4d45e-bc7d-4076-b87a-1abc786649bd",
            price: 152.84,
            price_sources: [],
            processed_ms: 1712771170194,
          },
          {
            leverage: 0.02,
            order_type: "FLAT",
            order_uuid: "34eb916c-7537-4f68-a5ce-a940f10ccef1",
            price: 152.941,
            price_sources: [],
            processed_ms: 1712774070785,
          },
        ],
        position_type: "FLAT",
        position_uuid: "01d94cd7-ca67-43af-aaa2-fdad756fdb00",
        return_at_close: 1.0000118164169849,
        trade_pair: ["USDJPY", "USD/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.8905987743,
        close_ms: 1712816414050,
        current_return: 1.0000687591009185,
        initial_entry_price: 0.8905987743,
        is_closed_position: true,
        miner_hotkey: "5G3ys2356ovgUivX3endMP7f37LPEjRkzDAM3Km8CxQnErCw",
        net_leverage: 0.0,
        open_ms: 1712794582654,
        orders: [
          {
            leverage: 0.03,
            order_type: "LONG",
            order_uuid: "ba171533-03f1-405b-8df8-93927ed6322e",
            price: 0.8905987743,
            price_sources: [],
            processed_ms: 1712794582651,
          },
          {
            leverage: 0.03,
            order_type: "FLAT",
            order_uuid: "9b1002dc-ccd4-4aa3-9ffc-8bce658a1531",
            price: 0.89264,
            price_sources: [],
            processed_ms: 1712816414050,
          },
        ],
        position_type: "FLAT",
        position_uuid: "09e2a03b-dafb-42a7-b292-771ea4fb8f08",
        return_at_close: 1.0000666589565244,
        trade_pair: ["AUDCAD", "AUD/CAD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.6517,
        close_ms: 1712822415574,
        current_return: 1.0000487954580328,
        initial_entry_price: 0.6517,
        is_closed_position: true,
        miner_hotkey: "5G3ys2356ovgUivX3endMP7f37LPEjRkzDAM3Km8CxQnErCw",
        net_leverage: 0.0,
        open_ms: 1712819167143,
        orders: [
          {
            leverage: 0.03,
            order_type: "LONG",
            order_uuid: "93adeb62-aa31-4b30-bce1-ffb20b270981",
            price: 0.6517,
            price_sources: [],
            processed_ms: 1712819164498,
          },
          {
            leverage: 0.03,
            order_type: "FLAT",
            order_uuid: "ab6a9466-9ea4-4683-babf-6b655ca72c18",
            price: 0.65276,
            price_sources: [],
            processed_ms: 1712822415574,
          },
        ],
        position_type: "FLAT",
        position_uuid: "a603b688-5479-480d-8b45-031d89f03678",
        return_at_close: 1.0000466953555625,
        trade_pair: ["AUDUSD", "AUD/USD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 1.36839,
        close_ms: 1712823049632,
        current_return: 1.000018854274001,
        initial_entry_price: 1.36839,
        is_closed_position: true,
        miner_hotkey: "5G3ys2356ovgUivX3endMP7f37LPEjRkzDAM3Km8CxQnErCw",
        net_leverage: 0.0,
        open_ms: 1712816711434,
        orders: [
          {
            leverage: -0.03,
            order_type: "SHORT",
            order_uuid: "e00c6c5a-480a-4195-8086-c86c8cc8269d",
            price: 1.36839,
            price_sources: [],
            processed_ms: 1712816711432,
          },
          {
            leverage: 0.03,
            order_type: "FLAT",
            order_uuid: "ed82beff-336e-429f-90ff-5ba36786f722",
            price: 1.36753,
            price_sources: [],
            processed_ms: 1712823049632,
          },
        ],
        position_type: "FLAT",
        position_uuid: "4df54281-4682-4fd3-a82d-3d4c65038c17",
        return_at_close: 1.000016754234407,
        trade_pair: ["USDCAD", "USD/CAD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.9130600000000001,
        close_ms: 1712832135646,
        current_return: 1.000016428274155,
        initial_entry_price: 0.91306,
        is_closed_position: true,
        miner_hotkey: "5G3ys2356ovgUivX3endMP7f37LPEjRkzDAM3Km8CxQnErCw",
        net_leverage: 0.0,
        open_ms: 1712794867364,
        orders: [
          {
            leverage: -0.02,
            order_type: "SHORT",
            order_uuid: "fd2da86b-c6a5-4ae2-ba3f-3402ee026550",
            price: 0.91306,
            price_sources: [],
            processed_ms: 1712794867362,
          },
          {
            leverage: 0.02,
            order_type: "FLAT",
            order_uuid: "97141cab-567d-407f-ad04-efb15f1a18a5",
            price: 0.91231,
            price_sources: [],
            processed_ms: 1712832135646,
          },
        ],
        position_type: "FLAT",
        position_uuid: "a47e70f6-bd21-4476-b0a9-e39721485521",
        return_at_close: 1.0000150282511555,
        trade_pair: ["USDCHF", "USD/CHF", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 1.36756,
        close_ms: 1712843670564,
        current_return: 1.000026104887537,
        initial_entry_price: 1.36756,
        is_closed_position: true,
        miner_hotkey: "5G3ys2356ovgUivX3endMP7f37LPEjRkzDAM3Km8CxQnErCw",
        net_leverage: 0.0,
        open_ms: 1712839574714,
        orders: [
          {
            leverage: 0.03,
            order_type: "LONG",
            order_uuid: "356010b9-4852-45a1-ace6-182ad5d93ff6",
            price: 1.36756,
            price_sources: [],
            processed_ms: 1712839574711,
          },
          {
            leverage: 0.03,
            order_type: "FLAT",
            order_uuid: "0c04f975-3220-4e81-a970-6b37df8b60ee",
            price: 1.36875,
            price_sources: [],
            processed_ms: 1712843670564,
          },
        ],
        position_type: "FLAT",
        position_uuid: "1c2a49f3-e0ee-4df0-bbcb-168f6ca75214",
        return_at_close: 1.0000240048327167,
        trade_pair: ["USDCAD", "USD/CAD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.6544900000000001,
        close_ms: 1712845079930,
        current_return: 1.00011275955324,
        initial_entry_price: 0.65449,
        is_closed_position: true,
        miner_hotkey: "5G3ys2356ovgUivX3endMP7f37LPEjRkzDAM3Km8CxQnErCw",
        net_leverage: 0.0,
        open_ms: 1712839852897,
        orders: [
          {
            leverage: -0.03,
            order_type: "SHORT",
            order_uuid: "c06d9cd3-8308-4a7c-a7d0-899f7762febd",
            price: 0.65449,
            price_sources: [],
            processed_ms: 1712839852895,
          },
          {
            leverage: 0.03,
            order_type: "FLAT",
            order_uuid: "7abbdb7d-7965-48eb-a239-370247cefa80",
            price: 0.65203,
            price_sources: [],
            processed_ms: 1712845079930,
          },
        ],
        position_type: "FLAT",
        position_uuid: "c493fee6-a212-4e34-9812-1f69679240c7",
        return_at_close: 1.0001106593164448,
        trade_pair: ["AUDUSD", "AUD/USD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.59788,
        close_ms: 1712846693915,
        current_return: 1.000023081554827,
        initial_entry_price: 0.59788,
        is_closed_position: true,
        miner_hotkey: "5G3ys2356ovgUivX3endMP7f37LPEjRkzDAM3Km8CxQnErCw",
        net_leverage: 0.0,
        open_ms: 1712789196061,
        orders: [
          {
            leverage: -0.03,
            order_type: "SHORT",
            order_uuid: "8a4dfa35-19e8-4ace-b206-26a84620c654",
            price: 0.59788,
            price_sources: [],
            processed_ms: 1712789196059,
          },
          {
            leverage: 0.03,
            order_type: "FLAT",
            order_uuid: "b4ed567d-5df4-4ff3-88a5-41b2c4ea85e5",
            price: 0.59742,
            price_sources: [],
            processed_ms: 1712846693915,
          },
        ],
        position_type: "FLAT",
        position_uuid: "8010d9b2-ce26-4c4e-814a-172500e4ce26",
        return_at_close: 1.0000209815063557,
        trade_pair: ["NZDUSD", "NZD/USD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.9117100000000001,
        close_ms: 1712850197332,
        current_return: 1.0000256660560924,
        initial_entry_price: 0.91171,
        is_closed_position: true,
        miner_hotkey: "5G3ys2356ovgUivX3endMP7f37LPEjRkzDAM3Km8CxQnErCw",
        net_leverage: 0.0,
        open_ms: 1712847385687,
        orders: [
          {
            leverage: -0.02,
            order_type: "SHORT",
            order_uuid: "949fd089-00b2-40ee-9f88-637702e2b252",
            price: 0.91171,
            price_sources: [],
            processed_ms: 1712847385685,
          },
          {
            leverage: 0.02,
            order_type: "FLAT",
            order_uuid: "de594b51-37bf-42e6-bb77-1417573d5b07",
            price: 0.91054,
            price_sources: [],
            processed_ms: 1712850197332,
          },
        ],
        position_type: "FLAT",
        position_uuid: "af00fd6d-5043-45c6-b1f7-b2ca6ea5be85",
        return_at_close: 1.00002426602016,
        trade_pair: ["USDCHF", "USD/CHF", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.5975,
        close_ms: 1712854203250,
        current_return: 1.0000532217573221,
        initial_entry_price: 0.5975,
        is_closed_position: true,
        miner_hotkey: "5G3ys2356ovgUivX3endMP7f37LPEjRkzDAM3Km8CxQnErCw",
        net_leverage: 0.0,
        open_ms: 1712848020953,
        orders: [
          {
            leverage: 0.03,
            order_type: "LONG",
            order_uuid: "d8b74337-1440-404f-a013-fda71ae8338f",
            price: 0.5975,
            price_sources: [],
            processed_ms: 1712848020950,
          },
          {
            leverage: 0.03,
            order_type: "FLAT",
            order_uuid: "33a27c88-10dd-4f03-9159-7883ace23de6",
            price: 0.59856,
            price_sources: [],
            processed_ms: 1712854203250,
          },
        ],
        position_type: "FLAT",
        position_uuid: "9b4c98a7-05fd-4f45-aab7-646a6825014d",
        return_at_close: 1.0000511216455565,
        trade_pair: ["NZDUSD", "NZD/USD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 153.294,
        close_ms: 1712858050765,
        current_return: 1.0000178741503256,
        initial_entry_price: 153.294,
        is_closed_position: true,
        miner_hotkey: "5G3ys2356ovgUivX3endMP7f37LPEjRkzDAM3Km8CxQnErCw",
        net_leverage: 0.0,
        open_ms: 1712848344210,
        orders: [
          {
            leverage: -0.02,
            order_type: "SHORT",
            order_uuid: "49355ac4-5e78-4d52-b474-4529c63c7135",
            price: 153.294,
            price_sources: [],
            processed_ms: 1712848344206,
          },
          {
            leverage: 0.02,
            order_type: "FLAT",
            order_uuid: "78897de4-4360-4448-a323-29d1a72ce2c9",
            price: 153.157,
            price_sources: [],
            processed_ms: 1712858050765,
          },
        ],
        position_type: "FLAT",
        position_uuid: "49e4c8d8-8a2c-4e9d-b175-81b974657808",
        return_at_close: 1.0000164741253017,
        trade_pair: ["USDJPY", "USD/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 153.032,
        close_ms: 1712889962004,
        current_return: 1.0000151602279261,
        initial_entry_price: 153.032,
        is_closed_position: true,
        miner_hotkey: "5G3ys2356ovgUivX3endMP7f37LPEjRkzDAM3Km8CxQnErCw",
        net_leverage: 0.0,
        open_ms: 1712884307726,
        orders: [
          {
            leverage: 0.02,
            order_type: "LONG",
            order_uuid: "c8b381bd-4a83-4ee9-9a81-3e307548c486",
            price: 153.032,
            price_sources: [],
            processed_ms: 1712884307724,
          },
          {
            leverage: 0.02,
            order_type: "FLAT",
            order_uuid: "5a0c0f0e-a478-42f8-b0fc-22391435bd74",
            price: 153.148,
            price_sources: [],
            processed_ms: 1712889962004,
          },
        ],
        position_type: "FLAT",
        position_uuid: "7e6625d6-1a90-408d-b9e3-9fc4ddf5f540",
        return_at_close: 1.0000137602067019,
        trade_pair: ["USDJPY", "USD/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.9093200000000001,
        close_ms: 1712894674273,
        current_return: 1.0000369506884266,
        initial_entry_price: 0.90932,
        is_closed_position: true,
        miner_hotkey: "5G3ys2356ovgUivX3endMP7f37LPEjRkzDAM3Km8CxQnErCw",
        net_leverage: 0.0,
        open_ms: 1712870247203,
        orders: [
          {
            leverage: 0.02,
            order_type: "LONG",
            order_uuid: "5661430a-1388-4edb-bb2c-5f99efa925fb",
            price: 0.90932,
            price_sources: [],
            processed_ms: 1712870247200,
          },
          {
            leverage: 0.02,
            order_type: "FLAT",
            order_uuid: "61582180-2168-4c7b-8d36-7ca50f5b632a",
            price: 0.911,
            price_sources: [],
            processed_ms: 1712894674273,
          },
        ],
        position_type: "FLAT",
        position_uuid: "b692bf8e-7346-40cb-846d-77c33847ddb8",
        return_at_close: 1.0000355506366956,
        trade_pair: ["USDCHF", "USD/CHF", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.59911,
        close_ms: 1712903944794,
        current_return: 1.0000575854183706,
        initial_entry_price: 0.59911,
        is_closed_position: true,
        miner_hotkey: "5G3ys2356ovgUivX3endMP7f37LPEjRkzDAM3Km8CxQnErCw",
        net_leverage: 0.0,
        open_ms: 1712856378409,
        orders: [
          {
            leverage: -0.03,
            order_type: "SHORT",
            order_uuid: "313a9223-f2a3-4076-bc4b-b74be2b75e6c",
            price: 0.59911,
            price_sources: [],
            processed_ms: 1712856378406,
          },
          {
            leverage: 0.03,
            order_type: "FLAT",
            order_uuid: "702f1629-8f8a-4ab3-a443-4c2decf498e6",
            price: 0.59796,
            price_sources: [],
            processed_ms: 1712903944794,
          },
        ],
        position_type: "FLAT",
        position_uuid: "a099c18c-fd57-4286-8b10-b80f7d7ce7a8",
        return_at_close: 1.0000554852974413,
        trade_pair: ["NZDUSD", "NZD/USD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.9705547777777773,
        close_ms: 1712950052187,
        current_return: 1.0015063378705573,
        initial_entry_price: 0.98298,
        is_closed_position: true,
        miner_hotkey: "5G3ys2356ovgUivX3endMP7f37LPEjRkzDAM3Km8CxQnErCw",
        net_leverage: 0.0,
        open_ms: 1712627733479,
        orders: [
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "19a63e81-1def-47ce-b4fe-6a971d32d09e",
            price: 0.98298,
            price_sources: [],
            processed_ms: 1712627733478,
          },
          {
            leverage: 0.02,
            order_type: "LONG",
            order_uuid: "8fcdceab-dc2e-4069-902e-e18153f38629",
            price: 0.98059,
            price_sources: [],
            processed_ms: 1712753828700,
          },
          {
            leverage: 0.02,
            order_type: "LONG",
            order_uuid: "b0a38a1f-dc2f-4c40-8f6d-602c7f6be476",
            price: 0.97657,
            price_sources: [],
            processed_ms: 1712837791672,
          },
          {
            leverage: 0.02,
            order_type: "LONG",
            order_uuid: "31f3131d-1a49-42ea-b6fe-2eb095309977",
            price: 0.97568,
            price_sources: [],
            processed_ms: 1712838110769,
          },
          {
            leverage: 0.02,
            order_type: "LONG",
            order_uuid: "5ea82271-8541-4163-b716-4fe1d49c25fe",
            price: 0.9756,
            price_sources: [],
            processed_ms: 1712838356265,
          },
          {
            leverage: 0.02,
            order_type: "LONG",
            order_uuid: "83bc0cd4-c5dc-4e11-a331-59e598b9c9ec",
            price: 0.97568,
            price_sources: [],
            processed_ms: 1712839054990,
          },
          {
            leverage: 0.02,
            order_type: "LONG",
            order_uuid: "22b48d07-691d-48b7-bb3e-d566a8eb28e4",
            price: 0.97449,
            price_sources: [],
            processed_ms: 1712904291232,
          },
          {
            leverage: 0.03,
            order_type: "LONG",
            order_uuid: "9ffd5a1d-ff01-4fba-ada7-ba6fead841e2",
            price: 0.97423,
            price_sources: [],
            processed_ms: 1712905333308,
          },
          {
            leverage: 0.03,
            order_type: "LONG",
            order_uuid: "38e623f2-1646-49d9-9d9d-e47fc243bb0a",
            price: 0.97373,
            price_sources: [],
            processed_ms: 1712912512259,
          },
          {
            leverage: 0.03,
            order_type: "LONG",
            order_uuid: "2d6acc19-d914-48cd-ab25-9084b3c68644",
            price: 0.97309,
            price_sources: [],
            processed_ms: 1712914322008,
          },
          {
            leverage: 0.03,
            order_type: "LONG",
            order_uuid: "b03e06fd-5c4e-4f7f-95e9-bc3016d4640d",
            price: 0.973,
            price_sources: [],
            processed_ms: 1712916738697,
          },
          {
            leverage: 0.03,
            order_type: "LONG",
            order_uuid: "991a55ee-29f5-4365-8902-b0e9ba985449",
            price: 0.97253,
            price_sources: [],
            processed_ms: 1712919113074,
          },
          {
            leverage: 0.03,
            order_type: "LONG",
            order_uuid: "d5686f4c-d79f-4df4-87b0-70e3f402416b",
            price: 0.97219,
            price_sources: [],
            processed_ms: 1712920329056,
          },
          {
            leverage: 0.03,
            order_type: "LONG",
            order_uuid: "618cfd39-d44a-47b8-8712-a4085de1eeec",
            price: 0.97194,
            price_sources: [],
            processed_ms: 1712923326588,
          },
          {
            leverage: 0.03,
            order_type: "LONG",
            order_uuid: "c5f447e5-8f2e-41b3-ac00-51b077f87e5e",
            price: 0.9717,
            price_sources: [],
            processed_ms: 1712923911570,
          },
          {
            leverage: 0.03,
            order_type: "LONG",
            order_uuid: "705afbe1-ad6e-4149-8c86-1244b6f9578a",
            price: 0.97126,
            price_sources: [],
            processed_ms: 1712924813745,
          },
          {
            leverage: 0.04,
            order_type: "LONG",
            order_uuid: "65ca4026-4879-4eae-bb39-16ffce8707a4",
            price: 0.96955,
            price_sources: [],
            processed_ms: 1712925742505,
          },
          {
            leverage: 0.04,
            order_type: "LONG",
            order_uuid: "1341bb9e-bfc9-4c57-af9d-d40502b100b7",
            price: 0.96868,
            price_sources: [],
            processed_ms: 1712926120594,
          },
          {
            leverage: 0.04,
            order_type: "LONG",
            order_uuid: "896313ed-4c87-48f5-bd6c-d23e17002e93",
            price: 0.96967,
            price_sources: [],
            processed_ms: 1712926765454,
          },
          {
            leverage: 0.05,
            order_type: "LONG",
            order_uuid: "9c0fda0a-18d4-4272-bf52-8353792a566d",
            price: 0.969,
            price_sources: [],
            processed_ms: 1712927117717,
          },
          {
            leverage: 0.05,
            order_type: "LONG",
            order_uuid: "8622ee19-9d19-4787-ad88-e3798968733f",
            price: 0.96882,
            price_sources: [],
            processed_ms: 1712927229306,
          },
          {
            leverage: 0.05,
            order_type: "LONG",
            order_uuid: "726cf09d-aed1-42d2-ba73-c23531882a12",
            price: 0.96836,
            price_sources: [],
            processed_ms: 1712927490481,
          },
          {
            leverage: 0.05,
            order_type: "LONG",
            order_uuid: "04701952-1691-4fe6-86ef-08faccafb3c5",
            price: 0.96965,
            price_sources: [],
            processed_ms: 1712927862830,
          },
          {
            leverage: 0.05,
            order_type: "LONG",
            order_uuid: "a4eeb2f2-a4c3-4be9-aebe-fb1f16559855",
            price: 0.96936,
            price_sources: [],
            processed_ms: 1712927972982,
          },
          {
            leverage: 0.05,
            order_type: "LONG",
            order_uuid: "cf3ace47-8d3a-4b9d-9455-63bbb0eeca75",
            price: 0.96983,
            price_sources: [],
            processed_ms: 1712928235865,
          },
          {
            leverage: 0.04,
            order_type: "LONG",
            order_uuid: "57b5cb52-2990-4c8b-9e94-88e3ce2849db",
            price: 0.96964,
            price_sources: [],
            processed_ms: 1712928613804,
          },
          {
            leverage: 0.06,
            order_type: "LONG",
            order_uuid: "e1ffc7ec-4fe3-4e22-a657-9d810859f965",
            price: 0.96837,
            price_sources: [],
            processed_ms: 1712931403125,
          },
          {
            leverage: 0.06,
            order_type: "LONG",
            order_uuid: "e77607dd-8d49-4031-ba2f-9844aba1c4b1",
            price: 0.96875,
            price_sources: [],
            processed_ms: 1712931792599,
          },
          {
            leverage: 0.06,
            order_type: "LONG",
            order_uuid: "f6bc4098-7d51-4522-b2d4-261ba5a15466",
            price: 0.96816,
            price_sources: [],
            processed_ms: 1712932634541,
          },
          {
            leverage: 0.06,
            order_type: "LONG",
            order_uuid: "a9afcd85-2ba0-43c5-9ae8-7436167622be",
            price: 0.96827,
            price_sources: [],
            processed_ms: 1712934134213,
          },
          {
            leverage: -0.02,
            order_type: "SHORT",
            order_uuid: "1d0fdda7-26b0-46a9-8281-8f5710f18033",
            price: 0.9719,
            price_sources: [],
            processed_ms: 1712944500626,
          },
          {
            leverage: -0.04,
            order_type: "SHORT",
            order_uuid: "665e25da-2b70-4168-9b08-10f0ff27a417",
            price: 0.97193,
            price_sources: [],
            processed_ms: 1712948529296,
          },
          {
            leverage: -0.04,
            order_type: "SHORT",
            order_uuid: "4cfaf1b4-4db1-4b59-a944-382bcc53feb3",
            price: 0.97185,
            price_sources: [],
            processed_ms: 1712948952616,
          },
          {
            leverage: -0.04,
            order_type: "SHORT",
            order_uuid: "203858f0-007e-4d2e-9ae9-c3b449acc8b5",
            price: 0.97185,
            price_sources: [],
            processed_ms: 1712949249559,
          },
          {
            leverage: -0.03,
            order_type: "SHORT",
            order_uuid: "8d98e019-fa37-4ade-8497-3ef28a81a35a",
            price: 0.9719,
            price_sources: [],
            processed_ms: 1712949668438,
          },
          {
            leverage: -0.03,
            order_type: "SHORT",
            order_uuid: "95ab7060-f117-422e-87b8-f019afab9808",
            price: 0.97194,
            price_sources: [],
            processed_ms: 1712949781070,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "dc0d8f4d-2cdc-4ba2-a4a5-61d393dabca9",
            price: 0.9722,
            price_sources: [],
            processed_ms: 1712950052187,
          },
        ],
        position_type: "FLAT",
        position_uuid: "140e032e-011b-4823-bd29-47725f78af61",
        return_at_close: 1.0014292218825414,
        trade_pair: ["EURCHF", "EUR/CHF", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 153.108,
        close_ms: 1712952587798,
        current_return: 1.0000194633853228,
        initial_entry_price: 153.108,
        is_closed_position: true,
        miner_hotkey: "5G3ys2356ovgUivX3endMP7f37LPEjRkzDAM3Km8CxQnErCw",
        net_leverage: 0.0,
        open_ms: 1712924300964,
        orders: [
          {
            leverage: 0.02,
            order_type: "LONG",
            order_uuid: "181c44fb-f4ef-4629-bd2e-dc8afe741092",
            price: 153.108,
            price_sources: [],
            processed_ms: 1712924300961,
          },
          {
            leverage: 0.02,
            order_type: "FLAT",
            order_uuid: "68c53f6c-5dd3-4f35-9b67-d065759c35ca",
            price: 153.257,
            price_sources: [],
            processed_ms: 1712952587798,
          },
        ],
        position_type: "FLAT",
        position_uuid: "3b939ea5-637e-48b3-b36d-7bf3166fb775",
        return_at_close: 1.000018063358074,
        trade_pair: ["USDJPY", "USD/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.96996,
        close_ms: 1713131241432,
        current_return: 1.0000616520268877,
        initial_entry_price: 0.96996,
        is_closed_position: true,
        miner_hotkey: "5G3ys2356ovgUivX3endMP7f37LPEjRkzDAM3Km8CxQnErCw",
        net_leverage: 0.0,
        open_ms: 1713129956867,
        orders: [
          {
            leverage: 0.02,
            order_type: "LONG",
            order_uuid: "9afcc9bb-c43d-4470-ab51-29363215cdf8",
            price: 0.96996,
            price_sources: [],
            processed_ms: 1713129956864,
          },
          {
            leverage: 0.02,
            order_type: "FLAT",
            order_uuid: "74aa87a0-ff5c-4fac-9729-d320fd4473b6",
            price: 0.97295,
            price_sources: [],
            processed_ms: 1713131241432,
          },
        ],
        position_type: "FLAT",
        position_uuid: "9b24f59c-4f7e-4cfd-8f71-6c4011088712",
        return_at_close: 1.0000602519405748,
        trade_pair: ["EURCHF", "EUR/CHF", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 153.06,
        close_ms: 1713133533644,
        current_return: 1.0000237815235855,
        initial_entry_price: 153.06,
        is_closed_position: true,
        miner_hotkey: "5G3ys2356ovgUivX3endMP7f37LPEjRkzDAM3Km8CxQnErCw",
        net_leverage: 0.0,
        open_ms: 1713129351745,
        orders: [
          {
            leverage: 0.02,
            order_type: "LONG",
            order_uuid: "1c23a7de-584b-4898-80cf-8cd4eb0daca9",
            price: 153.06,
            price_sources: [],
            processed_ms: 1713129351742,
          },
          {
            leverage: 0.02,
            order_type: "FLAT",
            order_uuid: "6a623d8a-d956-469a-bcdc-a01862567a05",
            price: 153.242,
            price_sources: [],
            processed_ms: 1713133533644,
          },
        ],
        position_type: "FLAT",
        position_uuid: "d56308e0-ae78-4ef9-9cf8-92b62570ead0",
        return_at_close: 1.0000223814902913,
        trade_pair: ["USDJPY", "USD/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.5943,
        close_ms: 1713142654800,
        current_return: 1.0000403836446239,
        initial_entry_price: 0.5943,
        is_closed_position: true,
        miner_hotkey: "5G3ys2356ovgUivX3endMP7f37LPEjRkzDAM3Km8CxQnErCw",
        net_leverage: 0.0,
        open_ms: 1713135356657,
        orders: [
          {
            leverage: -0.03,
            order_type: "SHORT",
            order_uuid: "dbf43bcb-55de-4dc9-8d24-ff39a0dea311",
            price: 0.5943,
            price_sources: [],
            processed_ms: 1713135356650,
          },
          {
            leverage: 0.03,
            order_type: "FLAT",
            order_uuid: "1cd21d64-d6b3-430e-8ad3-4c93de4f0349",
            price: 0.5935,
            price_sources: [],
            processed_ms: 1713142654800,
          },
        ],
        position_type: "FLAT",
        position_uuid: "17f796bc-9dd6-494a-9bb0-4fa17db11dc1",
        return_at_close: 1.0000382835598183,
        trade_pair: ["NZDUSD", "NZD/USD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 1.245,
        close_ms: 1713144719542,
        current_return: 1.0000130120481927,
        initial_entry_price: 1.245,
        is_closed_position: true,
        miner_hotkey: "5G3ys2356ovgUivX3endMP7f37LPEjRkzDAM3Km8CxQnErCw",
        net_leverage: 0.0,
        open_ms: 1713144277310,
        orders: [
          {
            leverage: 0.03,
            order_type: "LONG",
            order_uuid: "b31d3fd6-bce0-4dce-a147-5c8f8b7e71b1",
            price: 1.245,
            price_sources: [],
            processed_ms: 1713144277307,
          },
          {
            leverage: 0.03,
            order_type: "FLAT",
            order_uuid: "65bb2c87-5edd-49be-9ac8-f4fb878792d1",
            price: 1.24554,
            price_sources: [],
            processed_ms: 1713144719542,
          },
        ],
        position_type: "FLAT",
        position_uuid: "41d6560c-eaba-4bf3-b22a-dffdcb721d54",
        return_at_close: 1.0000109120208673,
        trade_pair: ["GBPUSD", "GBP/USD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.59354,
        close_ms: 1713146521840,
        current_return: 1.000034875492806,
        initial_entry_price: 0.59354,
        is_closed_position: true,
        miner_hotkey: "5G3ys2356ovgUivX3endMP7f37LPEjRkzDAM3Km8CxQnErCw",
        net_leverage: 0.0,
        open_ms: 1713144065631,
        orders: [
          {
            leverage: 0.03,
            order_type: "LONG",
            order_uuid: "7dd68c64-d33f-4498-9166-6e88364de4fd",
            price: 0.59354,
            price_sources: [],
            processed_ms: 1713144065628,
          },
          {
            leverage: 0.03,
            order_type: "FLAT",
            order_uuid: "c37c3438-f0b7-43fe-a3ab-382815593294",
            price: 0.59423,
            price_sources: [],
            processed_ms: 1713146521840,
          },
        ],
        position_type: "FLAT",
        position_uuid: "5358b779-56b0-45e8-9081-8755a30fbc70",
        return_at_close: 1.0000327754195673,
        trade_pair: ["NZDUSD", "NZD/USD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 1.24798,
        close_ms: 1713186169501,
        current_return: 1.000015144473469,
        initial_entry_price: 1.24798,
        is_closed_position: true,
        miner_hotkey: "5G3ys2356ovgUivX3endMP7f37LPEjRkzDAM3Km8CxQnErCw",
        net_leverage: 0.0,
        open_ms: 1713183368105,
        orders: [
          {
            leverage: 0.03,
            order_type: "LONG",
            order_uuid: "92404dfe-abb8-4cf1-a783-912ac958c64c",
            price: 1.24798,
            price_sources: [],
            processed_ms: 1713183368102,
          },
          {
            leverage: 0.03,
            order_type: "FLAT",
            order_uuid: "dd00a663-14d3-4d48-b213-5912ff76d168",
            price: 1.24861,
            price_sources: [],
            processed_ms: 1713186169501,
          },
        ],
        position_type: "FLAT",
        position_uuid: "24652bdf-e5fd-4222-93d9-c7a6a2e844ac",
        return_at_close: 1.0000130444416657,
        trade_pair: ["GBPUSD", "GBP/USD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 1.46695,
        close_ms: 1713270622410,
        current_return: 1.0001157978430613,
        initial_entry_price: 1.47153,
        is_closed_position: true,
        miner_hotkey: "5G3ys2356ovgUivX3endMP7f37LPEjRkzDAM3Km8CxQnErCw",
        net_leverage: 0.0,
        open_ms: 1712753824463,
        orders: [
          {
            leverage: 0.03,
            order_type: "LONG",
            order_uuid: "afa7ffef-ec24-45bc-82ea-0d95706445c0",
            price: 1.47153,
            price_sources: [],
            processed_ms: 1712753824462,
          },
          {
            leverage: 0.03,
            order_type: "LONG",
            order_uuid: "290456ed-24cc-498b-a8e4-6c68ca582db2",
            price: 1.46237,
            price_sources: [],
            processed_ms: 1712929666649,
          },
          {
            leverage: 0.03,
            order_type: "FLAT",
            order_uuid: "9c2a7db3-5f21-4719-9077-b3bc614bca70",
            price: 1.46979,
            price_sources: [],
            processed_ms: 1713270622410,
          },
        ],
        position_type: "FLAT",
        position_uuid: "b5331b51-0572-468e-a9d3-594d2de0b2e2",
        return_at_close: 1.0001115973567103,
        trade_pair: ["EURCAD", "EUR/CAD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.59062,
        close_ms: 1713337348527,
        current_return: 1.000082850599488,
        initial_entry_price: 0.59384,
        is_closed_position: true,
        miner_hotkey: "5G3ys2356ovgUivX3endMP7f37LPEjRkzDAM3Km8CxQnErCw",
        net_leverage: 0.0,
        open_ms: 1713165652719,
        orders: [
          {
            leverage: 0.03,
            order_type: "LONG",
            order_uuid: "5309d93a-9ea6-498a-b9ff-60c7e8e8a432",
            price: 0.59384,
            price_sources: [],
            processed_ms: 1713165652715,
          },
          {
            leverage: 0.03,
            order_type: "LONG",
            order_uuid: "32bbddeb-e788-4fe1-8f6e-f41e8da43f92",
            price: 0.59035,
            price_sources: [],
            processed_ms: 1713208509204,
          },
          {
            leverage: 0.03,
            order_type: "LONG",
            order_uuid: "1fc584a5-6822-4396-b454-e096a7e6c6ba",
            price: 0.58863,
            price_sources: [],
            processed_ms: 1713230429859,
          },
          {
            leverage: -0.03,
            order_type: "SHORT",
            order_uuid: "f945f9de-ece4-4c5c-9c71-40cd8d10dfcc",
            price: 0.59158,
            price_sources: [],
            processed_ms: 1713337128099,
          },
          {
            leverage: 0.03,
            order_type: "FLAT",
            order_uuid: "f412ec2d-025c-41b4-a23d-8ebe196f2956",
            price: 0.59144,
            price_sources: [],
            processed_ms: 1713337348527,
          },
        ],
        position_type: "FLAT",
        position_uuid: "bca165f4-a2c4-4d67-99df-f96227deb9a4",
        return_at_close: 1.0000765500775293,
        trade_pair: ["NZDUSD", "NZD/USD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.91085,
        close_ms: 1713337349809,
        current_return: 1.0000120766317175,
        initial_entry_price: 0.91085,
        is_closed_position: true,
        miner_hotkey: "5G3ys2356ovgUivX3endMP7f37LPEjRkzDAM3Km8CxQnErCw",
        net_leverage: 0.0,
        open_ms: 1712896008078,
        orders: [
          {
            leverage: -0.02,
            order_type: "SHORT",
            order_uuid: "33b9d327-9006-4452-b74e-29a7e41324c5",
            price: 0.91085,
            price_sources: [],
            processed_ms: 1712896008075,
          },
          {
            leverage: 0.02,
            order_type: "FLAT",
            order_uuid: "2259b7c4-8c42-4268-b9da-f543cb3f2a2b",
            price: 0.9103,
            price_sources: [],
            processed_ms: 1713337349809,
          },
        ],
        position_type: "FLAT",
        position_uuid: "e08ba2cc-75cf-4594-874b-d545f373d973",
        return_at_close: 1.0000106766148102,
        trade_pair: ["USDCHF", "USD/CHF", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.6428717021276595,
        close_ms: 1713360739543,
        current_return: 1.0007024148379398,
        initial_entry_price: 0.65346,
        is_closed_position: true,
        miner_hotkey: "5G3ys2356ovgUivX3endMP7f37LPEjRkzDAM3Km8CxQnErCw",
        net_leverage: 0.0,
        open_ms: 1712880980869,
        orders: [
          {
            leverage: 0.03,
            order_type: "LONG",
            order_uuid: "e1c7ebf8-eaec-4342-9f3c-1664206260e8",
            price: 0.65346,
            price_sources: [],
            processed_ms: 1712880980866,
          },
          {
            leverage: 0.03,
            order_type: "LONG",
            order_uuid: "8ab5054e-174f-461d-b64d-cb88c6bb9496",
            price: 0.64973,
            price_sources: [],
            processed_ms: 1712917625818,
          },
          {
            leverage: 0.04,
            order_type: "LONG",
            order_uuid: "b8234d76-c03b-4fa8-81f1-760ac76e22a8",
            price: 0.64782,
            price_sources: [],
            processed_ms: 1712926570822,
          },
          {
            leverage: 0.05,
            order_type: "LONG",
            order_uuid: "d26d5602-a2d6-4084-aaee-99ae89534432",
            price: 0.64619,
            price_sources: [],
            processed_ms: 1712940431502,
          },
          {
            leverage: 0.06,
            order_type: "LONG",
            order_uuid: "16995b92-c145-49aa-9b69-fe96d7793f43",
            price: 0.64512,
            price_sources: [],
            processed_ms: 1713202222473,
          },
          {
            leverage: 0.07,
            order_type: "LONG",
            order_uuid: "25cdb37e-41b3-4595-9eef-b82dec7cb79a",
            price: 0.64421,
            price_sources: [],
            processed_ms: 1713207313767,
          },
          {
            leverage: 0.09,
            order_type: "LONG",
            order_uuid: "3a11f12b-85e3-4ddf-afb9-f89f408dc2eb",
            price: 0.64319,
            price_sources: [],
            processed_ms: 1713227746190,
          },
          {
            leverage: 0.11,
            order_type: "LONG",
            order_uuid: "37086d23-b091-4fe0-afca-0cd50c7e1547",
            price: 0.64253,
            price_sources: [],
            processed_ms: 1713229221947,
          },
          {
            leverage: 0.13,
            order_type: "LONG",
            order_uuid: "9cbf003d-22bb-49bd-99a6-7f49ddafdd95",
            price: 0.64128,
            price_sources: [],
            processed_ms: 1713230430804,
          },
          {
            leverage: 0.15,
            order_type: "LONG",
            order_uuid: "e3bbe403-2f9f-4f8c-8d56-249e027733e3",
            price: 0.64051,
            price_sources: [],
            processed_ms: 1713273636546,
          },
          {
            leverage: 0.18,
            order_type: "LONG",
            order_uuid: "bb026854-1d64-4cdd-97c6-f9ddc3563a49",
            price: 0.63984,
            price_sources: [],
            processed_ms: 1713275722647,
          },
          {
            leverage: 0.18,
            order_type: "FLAT",
            order_uuid: "eeba6aca-5aa7-42b6-b3e6-37713213692a",
            price: 0.64336,
            price_sources: [],
            processed_ms: 1713360739543,
          },
        ],
        position_type: "FLAT",
        position_uuid: "8deafaf4-533f-41ab-918e-95030ad3a615",
        return_at_close: 1.0006365686190435,
        trade_pair: ["AUDUSD", "AUD/USD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.88088,
        close_ms: 1713499449119,
        current_return: 1.0000037462537463,
        initial_entry_price: 0.88088,
        is_closed_position: true,
        miner_hotkey: "5G3ys2356ovgUivX3endMP7f37LPEjRkzDAM3Km8CxQnErCw",
        net_leverage: 0.0,
        open_ms: 1713489880168,
        orders: [
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "cfb27e6b-f042-4aef-8f44-66543f2cc84d",
            price: 0.88088,
            price_sources: [],
            processed_ms: 1713489880162,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "7b2cee64-0a9d-4977-b30a-743893e49f66",
            price: 0.88121,
            price_sources: [],
            processed_ms: 1713499449119,
          },
        ],
        position_type: "FLAT",
        position_uuid: "fd417038-ce9b-4e8f-91f5-7172c69432d5",
        return_at_close: 1.000003046251124,
        trade_pair: ["AUDCAD", "AUD/CAD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 1.36936,
        close_ms: 1713841484848,
        current_return: 1.0000028480458025,
        initial_entry_price: 1.36936,
        is_closed_position: true,
        miner_hotkey: "5G3ys2356ovgUivX3endMP7f37LPEjRkzDAM3Km8CxQnErCw",
        net_leverage: 0.0,
        open_ms: 1713841045312,
        orders: [
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "99c4e54a-5c3b-4f75-a908-f0e3c009ab59",
            price: 1.36936,
            price_sources: [],
            processed_ms: 1713841045307,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "16b16701-6e0a-4ef9-86f0-54f6997054b4",
            price: 1.36975,
            price_sources: [],
            processed_ms: 1713841484848,
          },
        ],
        position_type: "FLAT",
        position_uuid: "b0f48af7-1480-494f-996b-4b52dadd1535",
        return_at_close: 1.0000021480438088,
        trade_pair: ["USDCAD", "USD/CAD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.5933,
        close_ms: 1714024428696,
        current_return: 1.0000397417158267,
        initial_entry_price: 0.5933,
        is_closed_position: true,
        miner_hotkey: "5G3ys2356ovgUivX3endMP7f37LPEjRkzDAM3Km8CxQnErCw",
        net_leverage: 0.0,
        open_ms: 1714016707636,
        orders: [
          {
            leverage: 0.019982,
            order_type: "LONG",
            order_uuid: "473f05b8-8bb3-4b4f-a1d2-ea75cd9fa128",
            price: 0.5933,
            price_sources: [],
            processed_ms: 1714016707632,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "88252697-9db7-45be-8792-76fbb1c7eda2",
            price: 0.59448,
            price_sources: [],
            processed_ms: 1714024428696,
          },
        ],
        position_type: "FLAT",
        position_uuid: "8af291da-8e3c-42fe-b466-07d144e05613",
        return_at_close: 1.0000383429202382,
        trade_pair: ["NZDUSD", "NZD/USD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 1.25065,
        close_ms: 1714044827438,
        current_return: 1.0000084679646584,
        initial_entry_price: 1.25065,
        is_closed_position: true,
        miner_hotkey: "5G3ys2356ovgUivX3endMP7f37LPEjRkzDAM3Km8CxQnErCw",
        net_leverage: 0.0,
        open_ms: 1714043722992,
        orders: [
          {
            leverage: 0.019982,
            order_type: "LONG",
            order_uuid: "12a506ee-0d1e-491a-8043-1caaecf6e718",
            price: 1.25065,
            price_sources: [],
            processed_ms: 1714043722987,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "be35aa25-bd39-4e26-9347-4a00fa6a56ad",
            price: 1.25118,
            price_sources: [],
            processed_ms: 1714044827438,
          },
        ],
        position_type: "FLAT",
        position_uuid: "ba2e52b1-504f-45a2-9d9a-0a0f9ef27545",
        return_at_close: 1.0000070692128138,
        trade_pair: ["GBPUSD", "GBP/USD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 1.07174,
        close_ms: 1714048319407,
        current_return: 1.0000261022262862,
        initial_entry_price: 1.07174,
        is_closed_position: true,
        miner_hotkey: "5G3ys2356ovgUivX3endMP7f37LPEjRkzDAM3Km8CxQnErCw",
        net_leverage: 0.0,
        open_ms: 1714028713271,
        orders: [
          {
            leverage: -0.019982,
            order_type: "SHORT",
            order_uuid: "f5873326-48bd-4794-8c50-77550347aba1",
            price: 1.07174,
            price_sources: [],
            processed_ms: 1714028713262,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "ac01b334-9f84-42e0-bb7b-d6fcb87c91ca",
            price: 1.07034,
            price_sources: [],
            processed_ms: 1714048319407,
          },
        ],
        position_type: "FLAT",
        position_uuid: "89a5b255-f369-4537-aa7b-f85ae835680c",
        return_at_close: 1.000024703449776,
        trade_pair: ["EURUSD", "EUR/USD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.9783499999999999,
        close_ms: 1714053433994,
        current_return: 1.0000077611897582,
        initial_entry_price: 0.97835,
        is_closed_position: true,
        miner_hotkey: "5G3ys2356ovgUivX3endMP7f37LPEjRkzDAM3Km8CxQnErCw",
        net_leverage: 0.0,
        open_ms: 1714049419732,
        orders: [
          {
            leverage: 0.009991,
            order_type: "LONG",
            order_uuid: "08ce85c3-77c1-417a-b752-25f21558f311",
            price: 0.97835,
            price_sources: [],
            processed_ms: 1714049419723,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "e5c52e45-4598-48e7-a1c8-eb14bd68a000",
            price: 0.97911,
            price_sources: [],
            processed_ms: 1714053433994,
          },
        ],
        position_type: "FLAT",
        position_uuid: "639528a7-0d43-4985-9821-db0b4e4516d3",
        return_at_close: 1.0000070618143302,
        trade_pair: ["EURCHF", "EUR/CHF", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.9797099999999999,
        close_ms: 1714067964528,
        current_return: 1.0000086682283533,
        initial_entry_price: 0.97971,
        is_closed_position: true,
        miner_hotkey: "5G3ys2356ovgUivX3endMP7f37LPEjRkzDAM3Km8CxQnErCw",
        net_leverage: 0.0,
        open_ms: 1714064479811,
        orders: [
          {
            leverage: -0.009991,
            order_type: "SHORT",
            order_uuid: "9ba37f6b-1737-4121-b267-19cd9f43eadc",
            price: 0.97971,
            price_sources: [],
            processed_ms: 1714064479806,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "ede73de4-2dbd-483b-b6ad-8c34e4bf3683",
            price: 0.97886,
            price_sources: [],
            processed_ms: 1714067964528,
          },
        ],
        position_type: "FLAT",
        position_uuid: "de963b6b-ee34-4df5-af3d-601f2b18a0f2",
        return_at_close: 1.000007968852291,
        trade_pair: ["EURCHF", "EUR/CHF", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 1.07211,
        close_ms: 1714112097801,
        current_return: 1.0000184507093488,
        initial_entry_price: 1.07211,
        is_closed_position: true,
        miner_hotkey: "5G3ys2356ovgUivX3endMP7f37LPEjRkzDAM3Km8CxQnErCw",
        net_leverage: 0.0,
        open_ms: 1714103726461,
        orders: [
          {
            leverage: 0.019981,
            order_type: "LONG",
            order_uuid: "c5173414-ae52-4cba-a06f-8b8a8614fc7c",
            price: 1.07211,
            price_sources: [],
            processed_ms: 1714103726456,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "d30eef1c-257f-4c5a-a7ea-94f5e6e8fe40",
            price: 1.0731,
            price_sources: [],
            processed_ms: 1714112097801,
          },
        ],
        position_type: "FLAT",
        position_uuid: "02963796-219b-4b4c-982a-ff1115617863",
        return_at_close: 1.0000170520135423,
        trade_pair: ["EURUSD", "EUR/USD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 1.25038,
        close_ms: 1714112148046,
        current_return: 1.000012783953678,
        initial_entry_price: 1.25038,
        is_closed_position: true,
        miner_hotkey: "5G3ys2356ovgUivX3endMP7f37LPEjRkzDAM3Km8CxQnErCw",
        net_leverage: 0.0,
        open_ms: 1714091420002,
        orders: [
          {
            leverage: 0.019981,
            order_type: "LONG",
            order_uuid: "c158ac39-aa24-46a9-9955-16da8d04bd6c",
            price: 1.25038,
            price_sources: [],
            processed_ms: 1714091417318,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "232e3d69-f3c9-46b4-b924-fb25f384bf0c",
            price: 1.25118,
            price_sources: [],
            processed_ms: 1714112148046,
          },
        ],
        position_type: "FLAT",
        position_uuid: "3283e2c5-9de5-4b09-aed3-497394138f14",
        return_at_close: 1.0000113852657975,
        trade_pair: ["GBPUSD", "GBP/USD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 1.25021,
        close_ms: 1714117990209,
        current_return: 1.0000142240823542,
        initial_entry_price: 1.25021,
        is_closed_position: true,
        miner_hotkey: "5G3ys2356ovgUivX3endMP7f37LPEjRkzDAM3Km8CxQnErCw",
        net_leverage: 0.0,
        open_ms: 1714116914074,
        orders: [
          {
            leverage: 0.019981,
            order_type: "LONG",
            order_uuid: "1279b7e2-1dad-479e-8d7e-a2252a7c6d9b",
            price: 1.25021,
            price_sources: [],
            processed_ms: 1714116914057,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "e72c7669-f7a4-4e15-af9a-9875805093d5",
            price: 1.2511,
            price_sources: [],
            processed_ms: 1714117990209,
          },
        ],
        position_type: "FLAT",
        position_uuid: "61675e61-27cb-4041-a02c-a642b1e348b5",
        return_at_close: 1.0000128253924594,
        trade_pair: ["GBPUSD", "GBP/USD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.9782000000000001,
        close_ms: 1714120582366,
        current_return: 1.000007148844817,
        initial_entry_price: 0.9782,
        is_closed_position: true,
        miner_hotkey: "5G3ys2356ovgUivX3endMP7f37LPEjRkzDAM3Km8CxQnErCw",
        net_leverage: 0.0,
        open_ms: 1714119620396,
        orders: [
          {
            leverage: 0.00999,
            order_type: "LONG",
            order_uuid: "77448e3a-114b-474d-997a-99543160dd2d",
            price: 0.9782,
            price_sources: [],
            processed_ms: 1714119620388,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "4190974c-b135-4cc6-b7ab-152fac6ab483",
            price: 0.9789,
            price_sources: [],
            processed_ms: 1714120582366,
          },
        ],
        position_type: "FLAT",
        position_uuid: "c138b8ad-1f76-4bd9-b186-f5db7d099061",
        return_at_close: 1.0000064495398178,
        trade_pair: ["EURCHF", "EUR/CHF", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 1.07436,
        close_ms: 1714123438653,
        current_return: 1.0000152496369932,
        initial_entry_price: 1.07436,
        is_closed_position: true,
        miner_hotkey: "5G3ys2356ovgUivX3endMP7f37LPEjRkzDAM3Km8CxQnErCw",
        net_leverage: 0.0,
        open_ms: 1714121718798,
        orders: [
          {
            leverage: -0.01998,
            order_type: "SHORT",
            order_uuid: "0c328811-7c46-44cf-888d-a60e8f7ae2ca",
            price: 1.07436,
            price_sources: [],
            processed_ms: 1714121718793,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "4f231258-296b-4670-aabe-9fe6031c13e6",
            price: 1.07354,
            price_sources: [],
            processed_ms: 1714123438653,
          },
        ],
        position_type: "FLAT",
        position_uuid: "cbd65a69-1e42-4610-93ac-a20cfcdc3a8f",
        return_at_close: 1.000013851015665,
        trade_pair: ["EURUSD", "EUR/USD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 1.07189,
        close_ms: 1714134611170,
        current_return: 1.000034111149465,
        initial_entry_price: 1.07189,
        is_closed_position: true,
        miner_hotkey: "5G3ys2356ovgUivX3endMP7f37LPEjRkzDAM3Km8CxQnErCw",
        net_leverage: 0.0,
        open_ms: 1714134069295,
        orders: [
          {
            leverage: 0.01998,
            order_type: "LONG",
            order_uuid: "d9daa125-f5b5-4291-8540-b83f709ae53c",
            price: 1.07189,
            price_sources: [],
            processed_ms: 1714134069285,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "83dd1745-3d6a-4671-b35f-8107ac114a17",
            price: 1.07372,
            price_sources: [],
            processed_ms: 1714134611170,
          },
        ],
        position_type: "FLAT",
        position_uuid: "cf70640b-ad9a-4965-8c94-49756efd0648",
        return_at_close: 1.0000327125017572,
        trade_pair: ["EURUSD", "EUR/USD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.59445,
        close_ms: 1714144034886,
        current_return: 1.0000248733114643,
        initial_entry_price: 0.59445,
        is_closed_position: true,
        miner_hotkey: "5G3ys2356ovgUivX3endMP7f37LPEjRkzDAM3Km8CxQnErCw",
        net_leverage: 0.0,
        open_ms: 1714064417038,
        orders: [
          {
            leverage: -0.019981,
            order_type: "SHORT",
            order_uuid: "c8388225-e3b1-4883-b5d4-dfb906ad0aed",
            price: 0.59445,
            price_sources: [],
            processed_ms: 1714064417029,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "9124b3ac-1aa7-4351-bbb8-d0f51870af35",
            price: 0.59371,
            price_sources: [],
            processed_ms: 1714144034886,
          },
        ],
        position_type: "FLAT",
        position_uuid: "4a11590c-4dd7-40e1-86b8-bf0a5a712a4e",
        return_at_close: 1.0000234746066747,
        trade_pair: ["NZDUSD", "NZD/USD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 1.06825,
        close_ms: 1714148480563,
        current_return: 1.000016271219284,
        initial_entry_price: 1.06825,
        is_closed_position: true,
        miner_hotkey: "5G3ys2356ovgUivX3endMP7f37LPEjRkzDAM3Km8CxQnErCw",
        net_leverage: 0.0,
        open_ms: 1714145724847,
        orders: [
          {
            leverage: 0.019979,
            order_type: "LONG",
            order_uuid: "08d169e8-0736-4c3a-b15f-3f3b4799f82c",
            price: 1.06825,
            price_sources: [],
            processed_ms: 1714145724836,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "1d893336-2278-4588-a2f1-8be8a67d0aca",
            price: 1.06912,
            price_sources: [],
            processed_ms: 1714148480563,
          },
        ],
        position_type: "FLAT",
        position_uuid: "2d7a5e6c-22b7-4e0f-b164-c9c92e0026bd",
        return_at_close: 1.000014872666528,
        trade_pair: ["EURUSD", "EUR/USD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 1.07008,
        close_ms: 1714163750739,
        current_return: 1.0000182971553528,
        initial_entry_price: 1.07008,
        is_closed_position: true,
        miner_hotkey: "5G3ys2356ovgUivX3endMP7f37LPEjRkzDAM3Km8CxQnErCw",
        net_leverage: 0.0,
        open_ms: 1714159219946,
        orders: [
          {
            leverage: -0.019979,
            order_type: "SHORT",
            order_uuid: "4b2b5783-33af-4c59-8507-a13822009712",
            price: 1.07008,
            price_sources: [],
            processed_ms: 1714159219939,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "8dd8cc67-99d2-4dbb-9ef5-7e10d915d90f",
            price: 1.0691,
            price_sources: [],
            processed_ms: 1714163750739,
          },
        ],
        position_type: "FLAT",
        position_uuid: "625ad9fe-0316-43b3-87cb-7c0ae49e7c79",
        return_at_close: 1.0000168985997637,
        trade_pair: ["EURUSD", "EUR/USD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.9781,
        close_ms: 1714363601683,
        current_return: 1.0000042893160208,
        initial_entry_price: 0.9781,
        is_closed_position: true,
        miner_hotkey: "5G3ys2356ovgUivX3endMP7f37LPEjRkzDAM3Km8CxQnErCw",
        net_leverage: 0.0,
        open_ms: 1714355120494,
        orders: [
          {
            leverage: -0.009989,
            order_type: "SHORT",
            order_uuid: "ba003bd3-34f5-4600-8e68-43a7c5347c84",
            price: 0.9781,
            price_sources: [],
            processed_ms: 1714355120439,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "04c5a64c-1ac4-4f30-ae8f-7c4bedb79294",
            price: 0.97768,
            price_sources: [],
            processed_ms: 1714363601683,
          },
        ],
        position_type: "FLAT",
        position_uuid: "b3a3747d-e865-4907-848b-123737cf7598",
        return_at_close: 1.0000035900830215,
        trade_pair: ["EURCHF", "EUR/CHF", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.97725,
        close_ms: 1714373755134,
        current_return: 1.0000106304016372,
        initial_entry_price: 0.97725,
        is_closed_position: true,
        miner_hotkey: "5G3ys2356ovgUivX3endMP7f37LPEjRkzDAM3Km8CxQnErCw",
        net_leverage: 0.0,
        open_ms: 1714365034664,
        orders: [
          {
            leverage: 0.009989,
            order_type: "LONG",
            order_uuid: "e70dcaa3-ce0f-4542-b520-d60baba00b9f",
            price: 0.97725,
            price_sources: [],
            processed_ms: 1714365031698,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "685c21a8-1ec6-4267-a1a9-ad2eac0a8e68",
            price: 0.97829,
            price_sources: [],
            processed_ms: 1714373755134,
          },
        ],
        position_type: "FLAT",
        position_uuid: "1b51ac0d-2be8-4a8b-8a5a-13557d2360d0",
        return_at_close: 1.0000099311642041,
        trade_pair: ["EURCHF", "EUR/CHF", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 1.0701,
        close_ms: 1714398011097,
        current_return: 1.000018855976077,
        initial_entry_price: 1.0701,
        is_closed_position: true,
        miner_hotkey: "5G3ys2356ovgUivX3endMP7f37LPEjRkzDAM3Km8CxQnErCw",
        net_leverage: 0.0,
        open_ms: 1714344499065,
        orders: [
          {
            leverage: -0.019978,
            order_type: "SHORT",
            order_uuid: "edbfd807-90e6-4332-b340-113f34a19700",
            price: 1.0701,
            price_sources: [],
            processed_ms: 1714344499059,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "806c5db6-85ed-4e37-8d25-0e843c621792",
            price: 1.06909,
            price_sources: [],
            processed_ms: 1714398011097,
          },
        ],
        position_type: "FLAT",
        position_uuid: "cdb189fa-06fb-4220-9aab-11cf898d962e",
        return_at_close: 1.0000174574897076,
        trade_pair: ["EURUSD", "EUR/USD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 1.07003,
        close_ms: 1714399571326,
        current_return: 1.0000154965187893,
        initial_entry_price: 1.07003,
        is_closed_position: true,
        miner_hotkey: "5G3ys2356ovgUivX3endMP7f37LPEjRkzDAM3Km8CxQnErCw",
        net_leverage: 0.0,
        open_ms: 1714399214398,
        orders: [
          {
            leverage: 0.019978,
            order_type: "LONG",
            order_uuid: "46fc5007-4de1-4841-b3f9-aa79083164a0",
            price: 1.07003,
            price_sources: [],
            processed_ms: 1714399214386,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "39e99fbb-cffa-44e2-b623-0db25d7ef299",
            price: 1.07086,
            price_sources: [],
            processed_ms: 1714399571326,
          },
        ],
        position_type: "FLAT",
        position_uuid: "c2ca36f7-dac4-4990-939c-7e67c6cba2cf",
        return_at_close: 1.000014098037118,
        trade_pair: ["EURUSD", "EUR/USD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 1.46382,
        close_ms: 1714420501422,
        current_return: 1.000010919375333,
        initial_entry_price: 1.46382,
        is_closed_position: true,
        miner_hotkey: "5G3ys2356ovgUivX3endMP7f37LPEjRkzDAM3Km8CxQnErCw",
        net_leverage: 0.0,
        open_ms: 1714134073901,
        orders: [
          {
            leverage: 0.01998,
            order_type: "LONG",
            order_uuid: "9bd5fa92-3097-4ff6-8786-22861de56c0f",
            price: 1.46382,
            price_sources: [],
            processed_ms: 1714134073895,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "343d3a0e-66b9-45b4-a6e6-4136df0e8231",
            price: 1.46462,
            price_sources: [],
            processed_ms: 1714420501422,
          },
        ],
        position_type: "FLAT",
        position_uuid: "030ea096-ad1e-4e1c-b2ba-1a3eff9c6dc5",
        return_at_close: 1.0000095207600612,
        trade_pair: ["EURCAD", "EUR/CAD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.596199955201842,
        close_ms: 1714446321940,
        current_return: 1.00004974073451,
        initial_entry_price: 0.59441,
        is_closed_position: true,
        miner_hotkey: "5G3ys2356ovgUivX3endMP7f37LPEjRkzDAM3Km8CxQnErCw",
        net_leverage: 0.0,
        open_ms: 1714156520812,
        orders: [
          {
            leverage: -0.019979,
            order_type: "SHORT",
            order_uuid: "ce571ff2-18b5-486c-9668-0075f3bc65b1",
            price: 0.59441,
            price_sources: [],
            processed_ms: 1714156520802,
          },
          {
            leverage: -0.019978,
            order_type: "SHORT",
            order_uuid: "9ba2ea60-9474-4fa0-bba6-7de4179162a2",
            price: 0.59799,
            price_sources: [],
            processed_ms: 1714365327433,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "519e9205-66b4-4046-a710-5f6a0fe3796e",
            price: 0.59546,
            price_sources: [],
            processed_ms: 1714446321940,
          },
        ],
        position_type: "FLAT",
        position_uuid: "fda76395-9144-499a-b751-a3014c823ece",
        return_at_close: 1.0000469436053856,
        trade_pair: ["NZDUSD", "NZD/USD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.59439,
        close_ms: 1714480213601,
        current_return: 1.0000604966436177,
        initial_entry_price: 0.59439,
        is_closed_position: true,
        miner_hotkey: "5G3ys2356ovgUivX3endMP7f37LPEjRkzDAM3Km8CxQnErCw",
        net_leverage: 0.0,
        open_ms: 1714468511437,
        orders: [
          {
            leverage: -0.019977,
            order_type: "SHORT",
            order_uuid: "b4a8a624-eeb1-475d-96be-4bebb9143e0a",
            price: 0.59439,
            price_sources: [],
            processed_ms: 1714468511430,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "ec4688ae-6e6f-4edd-8f66-481e1bd66c8c",
            price: 0.59259,
            price_sources: [],
            processed_ms: 1714480213601,
          },
        ],
        position_type: "FLAT",
        position_uuid: "74c5ca19-9233-43c4-a1b0-8d20b153a2d4",
        return_at_close: 1.0000590981690198,
        trade_pair: ["NZDUSD", "NZD/USD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 1.2533378492341574,
        close_ms: 1714480213655,
        current_return: 1.0000982986178952,
        initial_entry_price: 1.24882,
        is_closed_position: true,
        miner_hotkey: "5G3ys2356ovgUivX3endMP7f37LPEjRkzDAM3Km8CxQnErCw",
        net_leverage: 0.0,
        open_ms: 1714151418083,
        orders: [
          {
            leverage: -0.019979,
            order_type: "SHORT",
            order_uuid: "672262db-0cb6-4dc5-a9b9-d51f7029f008",
            price: 1.24882,
            price_sources: [],
            processed_ms: 1714151418077,
          },
          {
            leverage: -0.029966,
            order_type: "SHORT",
            order_uuid: "3d27f9ca-deca-45b0-8d64-a64d7566b050",
            price: 1.25635,
            price_sources: [],
            processed_ms: 1714407315565,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "7a9a259a-a4fd-46de-b7ee-bcc15d56a5e1",
            price: 1.25088,
            price_sources: [],
            processed_ms: 1714480213655,
          },
        ],
        position_type: "FLAT",
        position_uuid: "6d771f90-12e7-42a2-8556-0c89c018c37d",
        return_at_close: 1.0000948021242286,
        trade_pair: ["GBPUSD", "GBP/USD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 1.2519,
        close_ms: 1714482711584,
        current_return: 1.000014679287483,
        initial_entry_price: 1.2519,
        is_closed_position: true,
        miner_hotkey: "5G3ys2356ovgUivX3endMP7f37LPEjRkzDAM3Km8CxQnErCw",
        net_leverage: 0.0,
        open_ms: 1714481139478,
        orders: [
          {
            leverage: 0.019975,
            order_type: "LONG",
            order_uuid: "304afa8d-e146-4005-a47e-88caaae167d5",
            price: 1.2519,
            price_sources: [],
            processed_ms: 1714481139471,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "7fcb02b1-d89a-4caa-8e82-bcc8a18a8ace",
            price: 1.25282,
            price_sources: [],
            processed_ms: 1714482711584,
          },
        ],
        position_type: "FLAT",
        position_uuid: "2fd9c0d7-3fe7-4227-a452-3762ce73a6c5",
        return_at_close: 1.0000132810169577,
        trade_pair: ["GBPUSD", "GBP/USD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.590485,
        close_ms: 1714588443762,
        current_return: 1.0000428278999882,
        initial_entry_price: 0.59233,
        is_closed_position: true,
        miner_hotkey: "5G3ys2356ovgUivX3endMP7f37LPEjRkzDAM3Km8CxQnErCw",
        net_leverage: 0.0,
        open_ms: 1714482930402,
        orders: [
          {
            leverage: 0.019975,
            order_type: "LONG",
            order_uuid: "b3051e9d-be2e-44ce-bad3-0dea31cec2bf",
            price: 0.59233,
            price_sources: [],
            processed_ms: 1714482930390,
          },
          {
            leverage: 0.019975,
            order_type: "LONG",
            order_uuid: "863c34de-72c4-476d-abd9-caa2d609b0e2",
            price: 0.58864,
            price_sources: [],
            processed_ms: 1714509940020,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "f3d76011-9436-4585-a941-3f87fd9de1f6",
            price: 0.59112,
            price_sources: [],
            processed_ms: 1714588443762,
          },
        ],
        position_type: "FLAT",
        position_uuid: "ab1aa7cc-5aec-46ca-8f9e-2a6dd23d5c3e",
        return_at_close: 1.00004003128022,
        trade_pair: ["NZDUSD", "NZD/USD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.5919,
        close_ms: 1714615236977,
        current_return: 1.0000317224193276,
        initial_entry_price: 0.5919,
        is_closed_position: true,
        miner_hotkey: "5G3ys2356ovgUivX3endMP7f37LPEjRkzDAM3Km8CxQnErCw",
        net_leverage: 0.0,
        open_ms: 1714614318728,
        orders: [
          {
            leverage: 0.019975,
            order_type: "LONG",
            order_uuid: "61253048-8467-4400-a227-4beb8ae81e3c",
            price: 0.5919,
            price_sources: [],
            processed_ms: 1714614316043,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "f475c74c-e5f3-46f7-8161-4f246c52a3a5",
            price: 0.59284,
            price_sources: [],
            processed_ms: 1714615236977,
          },
        ],
        position_type: "FLAT",
        position_uuid: "f5dee811-20c8-480a-a7ed-a8927885d213",
        return_at_close: 1.0000303241249717,
        trade_pair: ["NZDUSD", "NZD/USD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.59296,
        close_ms: 1714653635633,
        current_return: 1.000030990420939,
        initial_entry_price: 0.59296,
        is_closed_position: true,
        miner_hotkey: "5G3ys2356ovgUivX3endMP7f37LPEjRkzDAM3Km8CxQnErCw",
        net_leverage: 0.0,
        open_ms: 1714643420422,
        orders: [
          {
            leverage: 0.019974,
            order_type: "LONG",
            order_uuid: "692ebc9a-295e-408d-a78a-cf2a53b3aba1",
            price: 0.59296,
            price_sources: [],
            processed_ms: 1714643420239,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "a4ee6531-e5f0-4cd9-a474-bd1bffe52eb0",
            price: 0.59388,
            price_sources: [],
            processed_ms: 1714653635633,
          },
        ],
        position_type: "FLAT",
        position_uuid: "56dd74e9-d0af-4e3a-bb8d-7d33c662df15",
        return_at_close: 1.0000295921976088,
        trade_pair: ["NZDUSD", "NZD/USD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.59305,
        close_ms: 1714657619937,
        current_return: 1.000029638512773,
        initial_entry_price: 0.59305,
        is_closed_position: true,
        miner_hotkey: "5G3ys2356ovgUivX3endMP7f37LPEjRkzDAM3Km8CxQnErCw",
        net_leverage: 0.0,
        open_ms: 1714654519198,
        orders: [
          {
            leverage: -0.019974,
            order_type: "SHORT",
            order_uuid: "7398c9fc-6a65-45ef-aa63-34d791ac71da",
            price: 0.59305,
            price_sources: [],
            processed_ms: 1714654519190,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "9d9fc075-4d5a-43af-b0c5-7af88d1e7a1d",
            price: 0.59217,
            price_sources: [],
            processed_ms: 1714657619937,
          },
        ],
        position_type: "FLAT",
        position_uuid: "220ed228-f706-4c10-8024-69922384b082",
        return_at_close: 1.000028240291333,
        trade_pair: ["NZDUSD", "NZD/USD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 1.24827,
        close_ms: 1714658970685,
        current_return: 1.0000120016102285,
        initial_entry_price: 1.24827,
        is_closed_position: true,
        miner_hotkey: "5G3ys2356ovgUivX3endMP7f37LPEjRkzDAM3Km8CxQnErCw",
        net_leverage: 0.0,
        open_ms: 1714548919942,
        orders: [
          {
            leverage: -0.019975,
            order_type: "SHORT",
            order_uuid: "eca2b774-9511-4a04-ad50-6f768e6983ec",
            price: 1.24827,
            price_sources: [],
            processed_ms: 1714548919670,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "5660249e-ad8c-4d47-b9ce-97fbf1a7bc69",
            price: 1.24752,
            price_sources: [],
            processed_ms: 1714658970685,
          },
        ],
        position_type: "FLAT",
        position_uuid: "f0bed410-3e69-4a08-b7fa-303f4a4ddbe3",
        return_at_close: 1.0000106033434473,
        trade_pair: ["GBPUSD", "GBP/USD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.59519,
        close_ms: 1714698014318,
        current_return: 1.0000557052033805,
        initial_entry_price: 0.59519,
        is_closed_position: true,
        miner_hotkey: "5G3ys2356ovgUivX3endMP7f37LPEjRkzDAM3Km8CxQnErCw",
        net_leverage: 0.0,
        open_ms: 1714684696262,
        orders: [
          {
            leverage: 0.019973,
            order_type: "LONG",
            order_uuid: "c283b4d3-cbb0-469e-a2e8-ad4bfe41a80a",
            price: 0.59519,
            price_sources: [],
            processed_ms: 1714684696064,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "04c6b7b9-4124-4d09-98e3-37c3858d2c41",
            price: 0.59685,
            price_sources: [],
            processed_ms: 1714698014318,
          },
        ],
        position_type: "FLAT",
        position_uuid: "cdade0fd-08d7-4237-9d2c-4c8196fb7496",
        return_at_close: 1.0000543070154986,
        trade_pair: ["NZDUSD", "NZD/USD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.8566002202202204,
        close_ms: 1714742658220,
        current_return: 1.0000111569632044,
        initial_entry_price: 0.8588,
        is_closed_position: true,
        miner_hotkey: "5G3ys2356ovgUivX3endMP7f37LPEjRkzDAM3Km8CxQnErCw",
        net_leverage: 0.0,
        open_ms: 1714023006209,
        orders: [
          {
            leverage: 0.019982,
            order_type: "LONG",
            order_uuid: "0b2505f1-7514-415e-a907-6f7fb7bf3732",
            price: 0.8588,
            price_sources: [],
            processed_ms: 1714023006201,
          },
          {
            leverage: 0.019978,
            order_type: "LONG",
            order_uuid: "5886a807-d375-4910-ac8c-776c64569aaa",
            price: 0.8544,
            price_sources: [],
            processed_ms: 1714395308205,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "23179762-ed5f-4d6f-85ff-4b65b1c6490b",
            price: 0.85684,
            price_sources: [],
            processed_ms: 1714742658220,
          },
        ],
        position_type: "FLAT",
        position_uuid: "20d62c7c-f067-4fc2-ab08-ecce17d7d1d3",
        return_at_close: 1.0000083597319962,
        trade_pair: ["EURGBP", "EUR/GBP", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 1.2571160434982875,
        close_ms: 1714744992489,
        current_return: 1.000048952526787,
        initial_entry_price: 1.2506,
        is_closed_position: true,
        miner_hotkey: "5G3ys2356ovgUivX3endMP7f37LPEjRkzDAM3Km8CxQnErCw",
        net_leverage: 0.0,
        open_ms: 1714666216340,
        orders: [
          {
            leverage: -0.019973,
            order_type: "SHORT",
            order_uuid: "d7498c09-9755-4d52-b3cf-aad9d1abf174",
            price: 1.2506,
            price_sources: [],
            processed_ms: 1714666215831,
          },
          {
            leverage: -0.02996,
            order_type: "SHORT",
            order_uuid: "4197aad8-34de-4385-a1f4-81e6cb724e96",
            price: 1.26146,
            price_sources: [],
            processed_ms: 1714739729167,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "ca619075-6547-4cc6-88c2-ade37277e0bf",
            price: 1.25589,
            price_sources: [],
            processed_ms: 1714744992489,
          },
        ],
        position_type: "FLAT",
        position_uuid: "8758e58b-28ae-4e3f-8d5c-5a94ee35ed5e",
        return_at_close: 1.0000454570456827,
        trade_pair: ["GBPUSD", "GBP/USD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 1.25446,
        close_ms: 1714957254744,
        current_return: 1.0000120998038997,
        initial_entry_price: 1.25446,
        is_closed_position: true,
        miner_hotkey: "5G3ys2356ovgUivX3endMP7f37LPEjRkzDAM3Km8CxQnErCw",
        net_leverage: 0.0,
        open_ms: 1714953193441,
        orders: [
          {
            leverage: 0.019972,
            order_type: "LONG",
            order_uuid: "767bfb8c-0804-4365-8be2-d95abb78c90f",
            price: 1.25446,
            price_sources: [],
            processed_ms: 1714953192914,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "6657d450-b85b-4679-90e7-271ed3185560",
            price: 1.25522,
            price_sources: [],
            processed_ms: 1714957254744,
          },
        ],
        position_type: "FLAT",
        position_uuid: "dac449e9-d47a-4b0e-9a04-1c31aaaf2caf",
        return_at_close: 1.0000107017469837,
        trade_pair: ["GBPUSD", "GBP/USD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.60083,
        close_ms: 1714990203575,
        current_return: 1.0000428804819999,
        initial_entry_price: 0.60083,
        is_closed_position: true,
        miner_hotkey: "5G3ys2356ovgUivX3endMP7f37LPEjRkzDAM3Km8CxQnErCw",
        net_leverage: 0.0,
        open_ms: 1714946575473,
        orders: [
          {
            leverage: 0.019972,
            order_type: "LONG",
            order_uuid: "0985b54c-235c-4ed1-8f53-fc62985df2db",
            price: 0.60083,
            price_sources: [],
            processed_ms: 1714946575262,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "854a4307-3a38-4ef1-80bd-1960f0c85853",
            price: 0.60212,
            price_sources: [],
            processed_ms: 1714990203575,
          },
        ],
        position_type: "FLAT",
        position_uuid: "33542539-8d83-47d8-b292-e2ca5851e37c",
        return_at_close: 1.0000414823820512,
        trade_pair: ["NZDUSD", "NZD/USD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 1.25503,
        close_ms: 1715061611598,
        current_return: 1.0000195736834976,
        initial_entry_price: 1.25503,
        is_closed_position: true,
        miner_hotkey: "5G3ys2356ovgUivX3endMP7f37LPEjRkzDAM3Km8CxQnErCw",
        net_leverage: 0.0,
        open_ms: 1714977028382,
        orders: [
          {
            leverage: -0.019972,
            order_type: "SHORT",
            order_uuid: "3b7a847e-bc88-4da6-96f2-70e4d178a3cd",
            price: 1.25503,
            price_sources: [],
            processed_ms: 1714977028168,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "007e608f-8ead-4212-b884-5209a5d22260",
            price: 1.2538,
            price_sources: [],
            processed_ms: 1715061611598,
          },
        ],
        position_type: "FLAT",
        position_uuid: "805e28f7-d83c-4b54-929c-1dbcdec758fc",
        return_at_close: 1.0000181756161328,
        trade_pair: ["GBPUSD", "GBP/USD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 1.2543099999999998,
        close_ms: 1715097240786,
        current_return: 1.0000636869673367,
        initial_entry_price: 1.25431,
        is_closed_position: true,
        miner_hotkey: "5G3ys2356ovgUivX3endMP7f37LPEjRkzDAM3Km8CxQnErCw",
        net_leverage: 0.0,
        open_ms: 1715080526383,
        orders: [
          {
            leverage: -0.099854,
            order_type: "SHORT",
            order_uuid: "6e293462-aeea-49ed-8290-2cac120c1d6d",
            price: 1.25431,
            price_sources: [],
            processed_ms: 1715080526205,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "11d64698-b7a5-45a2-a681-01e6574bdce7",
            price: 1.25351,
            price_sources: [],
            processed_ms: 1715097240786,
          },
        ],
        position_type: "FLAT",
        position_uuid: "d691bd82-c36c-46d6-ad0f-77b206331178",
        return_at_close: 1.000056696742179,
        trade_pair: ["GBPUSD", "GBP/USD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 1.24886,
        close_ms: 1715170089566,
        current_return: 1.0001918805951027,
        initial_entry_price: 1.24886,
        is_closed_position: true,
        miner_hotkey: "5G3ys2356ovgUivX3endMP7f37LPEjRkzDAM3Km8CxQnErCw",
        net_leverage: 0.0,
        open_ms: 1715163017024,
        orders: [
          {
            leverage: -0.29954,
            order_type: "SHORT",
            order_uuid: "958118ae-a4ae-4690-bb2c-b955b2efc5bb",
            price: 1.24886,
            price_sources: [],
            processed_ms: 1715163016814,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "21235923-2bfc-43d7-b649-b6c8e7894713",
            price: 1.24806,
            price_sources: [],
            processed_ms: 1715170089566,
          },
        ],
        position_type: "FLAT",
        position_uuid: "c8bdbc7f-367d-4a33-9830-27326377ac4b",
        return_at_close: 1.0001709087717887,
        trade_pair: ["GBPUSD", "GBP/USD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 1.24786,
        close_ms: 1715178186277,
        current_return: 1.000213587589954,
        initial_entry_price: 1.24786,
        is_closed_position: true,
        miner_hotkey: "5G3ys2356ovgUivX3endMP7f37LPEjRkzDAM3Km8CxQnErCw",
        net_leverage: 0.0,
        open_ms: 1715170817550,
        orders: [
          {
            leverage: 0.299469,
            order_type: "LONG",
            order_uuid: "53991d1e-62ae-4f99-bb8f-ec085ced31bb",
            price: 1.24786,
            price_sources: [],
            processed_ms: 1715170817317,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "b540a0a1-c0fc-410d-9e13-e8d835e16b46",
            price: 1.24875,
            price_sources: [],
            processed_ms: 1715178186277,
          },
        ],
        position_type: "FLAT",
        position_uuid: "64ada462-12e0-4d08-b536-13d56f51e021",
        return_at_close: 1.0001926202825537,
        trade_pair: ["GBPUSD", "GBP/USD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.5982234461534058,
        close_ms: 1715182266701,
        current_return: 1.0007353528600622,
        initial_entry_price: 0.60191,
        is_closed_position: true,
        miner_hotkey: "5G3ys2356ovgUivX3endMP7f37LPEjRkzDAM3Km8CxQnErCw",
        net_leverage: 0.0,
        open_ms: 1715004021228,
        orders: [
          {
            leverage: 0.019971,
            order_type: "LONG",
            order_uuid: "1b5169fc-ad00-4261-8e1b-207a03d84573",
            price: 0.60191,
            price_sources: [],
            processed_ms: 1715004020943,
          },
          {
            leverage: 0.329494,
            order_type: "LONG",
            order_uuid: "8646b260-4033-4616-a283-361005533c22",
            price: 0.598,
            price_sources: [],
            processed_ms: 1715158518346,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "2bfc2577-a96f-4361-8028-f67fbccb753b",
            price: 0.59949,
            price_sources: [],
            processed_ms: 1715182266701,
          },
        ],
        position_type: "FLAT",
        position_uuid: "6aa6322f-729b-4d1a-bacf-851303af4ea5",
        return_at_close: 1.0007108723214562,
        trade_pair: ["NZDUSD", "NZD/USD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 1.24877,
        close_ms: 1715235421057,
        current_return: 1.0001486471808259,
        initial_entry_price: 1.24877,
        is_closed_position: true,
        miner_hotkey: "5G3ys2356ovgUivX3endMP7f37LPEjRkzDAM3Km8CxQnErCw",
        net_leverage: 0.0,
        open_ms: 1715178952066,
        orders: [
          {
            leverage: -0.299397,
            order_type: "SHORT",
            order_uuid: "5d8c275e-8cd6-4932-9f05-f14a55e20fc7",
            price: 1.24877,
            price_sources: [],
            processed_ms: 1715178951860,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "04dc9224-7938-468c-b83b-b0fa3fe6d983",
            price: 1.24815,
            price_sources: [],
            processed_ms: 1715235421057,
          },
        ],
        position_type: "FLAT",
        position_uuid: "299adcab-8814-4fae-b1d2-b4b6026cbe6c",
        return_at_close: 1.0001276862755095,
        trade_pair: ["GBPUSD", "GBP/USD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 1.24897,
        close_ms: 1715252434267,
        current_return: 1.0005438443597525,
        initial_entry_price: 1.24897,
        is_closed_position: true,
        miner_hotkey: "5G3ys2356ovgUivX3endMP7f37LPEjRkzDAM3Km8CxQnErCw",
        net_leverage: 0.0,
        open_ms: 1715251522999,
        orders: [
          {
            leverage: -0.299227,
            order_type: "SHORT",
            order_uuid: "4b87ff27-af70-4a66-92d4-3134476c0293",
            price: 1.24897,
            price_sources: [],
            processed_ms: 1715251522787,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "9493488f-62d1-4ea1-a96b-839fe958e5fa",
            price: 1.2467,
            price_sources: [],
            processed_ms: 1715252434267,
          },
        ],
        position_type: "FLAT",
        position_uuid: "f3562e48-25cf-4fa9-a149-1c53c95d8bbe",
        return_at_close: 1.0005228870784484,
        trade_pair: ["GBPUSD", "GBP/USD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 1.2458,
        close_ms: 1715254540592,
        current_return: 1.0002425353909135,
        initial_entry_price: 1.2458,
        is_closed_position: true,
        miner_hotkey: "5G3ys2356ovgUivX3endMP7f37LPEjRkzDAM3Km8CxQnErCw",
        net_leverage: 0.0,
        open_ms: 1715253617318,
        orders: [
          {
            leverage: 0.299159,
            order_type: "LONG",
            order_uuid: "3efd604d-b11d-4ba7-ab7f-3e5897d2b1d3",
            price: 1.2458,
            price_sources: [],
            processed_ms: 1715253614037,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "1e03432f-8fad-48aa-8f76-a051b1ea3456",
            price: 1.24681,
            price_sources: [],
            processed_ms: 1715254540592,
          },
        ],
        position_type: "FLAT",
        position_uuid: "ccff3a8e-9c79-4ee0-ba44-e0e0bb945f48",
        return_at_close: 1.0002215891819484,
        trade_pair: ["GBPUSD", "GBP/USD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.59984,
        close_ms: 1715257806225,
        current_return: 1.0005437407141904,
        initial_entry_price: 0.59984,
        is_closed_position: true,
        miner_hotkey: "5G3ys2356ovgUivX3endMP7f37LPEjRkzDAM3Km8CxQnErCw",
        net_leverage: 0.0,
        open_ms: 1715236225295,
        orders: [
          {
            leverage: 0.299227,
            order_type: "LONG",
            order_uuid: "e66b332b-83da-4906-9581-70e465d3d961",
            price: 0.59984,
            price_sources: [],
            processed_ms: 1715236225121,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "e3a8f5ce-4336-4d57-bdc2-45097232b85c",
            price: 0.60093,
            price_sources: [],
            processed_ms: 1715257806225,
          },
        ],
        position_type: "FLAT",
        position_uuid: "f7d49694-51be-4d8b-8e13-15d49c8dc339",
        return_at_close: 1.0005227834350572,
        trade_pair: ["NZDUSD", "NZD/USD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.60137,
        close_ms: 1715260519542,
        current_return: 1.000397786387748,
        initial_entry_price: 0.60137,
        is_closed_position: true,
        miner_hotkey: "5G3ys2356ovgUivX3endMP7f37LPEjRkzDAM3Km8CxQnErCw",
        net_leverage: 0.0,
        open_ms: 1715259626211,
        orders: [
          {
            leverage: -0.299021,
            order_type: "SHORT",
            order_uuid: "f3bfe496-af51-49d0-9463-b6f3a01a92cd",
            price: 0.60137,
            price_sources: [],
            processed_ms: 1715259626200,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "bdea79c7-b215-4217-8a75-c90abc889e43",
            price: 0.60057,
            price_sources: [],
            processed_ms: 1715260519542,
          },
        ],
        position_type: "FLAT",
        position_uuid: "a35a7f80-92c2-41fa-b37f-8186e5e00c6e",
        return_at_close: 1.0003768465914942,
        trade_pair: ["NZDUSD", "NZD/USD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 1.2525,
        close_ms: 1715320807545,
        current_return: 1.0002315260279442,
        initial_entry_price: 1.2525,
        is_closed_position: true,
        miner_hotkey: "5G3ys2356ovgUivX3endMP7f37LPEjRkzDAM3Km8CxQnErCw",
        net_leverage: 0.0,
        open_ms: 1715289517627,
        orders: [
          {
            leverage: 0.298955,
            order_type: "LONG",
            order_uuid: "59d1b12f-7fd5-462e-b72c-a1166e7cd6e9",
            price: 1.2525,
            price_sources: [],
            processed_ms: 1715289517392,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "83090a99-6cb1-4b7f-a795-61beacbeb0a6",
            price: 1.25347,
            price_sources: [],
            processed_ms: 1715320807545,
          },
        ],
        position_type: "FLAT",
        position_uuid: "1b8b840d-5463-4203-b1af-70499fb58cd5",
        return_at_close: 1.0002105943328337,
        trade_pair: ["GBPUSD", "GBP/USD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 1.25342,
        close_ms: 1715328216915,
        current_return: 1.0001979320419332,
        initial_entry_price: 1.25342,
        is_closed_position: true,
        miner_hotkey: "5G3ys2356ovgUivX3endMP7f37LPEjRkzDAM3Km8CxQnErCw",
        net_leverage: 0.0,
        open_ms: 1715323220993,
        orders: [
          {
            leverage: -0.298906,
            order_type: "SHORT",
            order_uuid: "9285e761-6a0f-44f0-8721-e555a33de682",
            price: 1.25342,
            price_sources: [],
            processed_ms: 1715323214921,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "40ce23cb-16ca-49d6-9580-3dcfc66be852",
            price: 1.25259,
            price_sources: [],
            processed_ms: 1715328216915,
          },
        ],
        position_type: "FLAT",
        position_uuid: "9c502258-9df1-4e5c-8ae8-48f2d6dec286",
        return_at_close: 1.0001770044805178,
        trade_pair: ["GBPUSD", "GBP/USD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 1.25279,
        close_ms: 1715348878343,
        current_return: 1.000197987188595,
        initial_entry_price: 1.25279,
        is_closed_position: true,
        miner_hotkey: "5G3ys2356ovgUivX3endMP7f37LPEjRkzDAM3Km8CxQnErCw",
        net_leverage: 0.0,
        open_ms: 1715336411540,
        orders: [
          {
            leverage: 0.298839,
            order_type: "LONG",
            order_uuid: "b89169a1-0562-45c3-aaa4-89d103df7ab0",
            price: 1.25279,
            price_sources: [],
            processed_ms: 1715336411348,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "3c0deabb-78b9-4604-bc99-4901964a4b90",
            price: 1.25362,
            price_sources: [],
            processed_ms: 1715348878343,
          },
        ],
        position_type: "FLAT",
        position_uuid: "fe03e926-d3ba-42e9-b039-e69a624639aa",
        return_at_close: 1.0001770643169545,
        trade_pair: ["GBPUSD", "GBP/USD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 1.25299,
        close_ms: 1715547664904,
        current_return: 1.0003552828115148,
        initial_entry_price: 1.25299,
        is_closed_position: true,
        miner_hotkey: "5G3ys2356ovgUivX3endMP7f37LPEjRkzDAM3Km8CxQnErCw",
        net_leverage: 0.0,
        open_ms: 1715358615351,
        orders: [
          {
            leverage: -0.298769,
            order_type: "SHORT",
            order_uuid: "8902cc9c-0df5-44b0-8104-a495c0c391da",
            price: 1.25299,
            price_sources: [],
            processed_ms: 1715358615142,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "da9a43dd-3797-4cf4-b725-5386cd0c7822",
            price: 1.2515,
            price_sources: [],
            processed_ms: 1715547664904,
          },
        ],
        position_type: "FLAT",
        position_uuid: "0cd214d7-acfb-4d26-8f9a-19b5a8d2359a",
        return_at_close: 1.0003343615511904,
        trade_pair: ["GBPUSD", "GBP/USD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 1.25259,
        close_ms: 1715584401294,
        current_return: 1.0001883893213261,
        initial_entry_price: 1.25259,
        is_closed_position: true,
        miner_hotkey: "5G3ys2356ovgUivX3endMP7f37LPEjRkzDAM3Km8CxQnErCw",
        net_leverage: 0.0,
        open_ms: 1715573165648,
        orders: [
          {
            leverage: -0.298702,
            order_type: "SHORT",
            order_uuid: "5ce51625-c0d2-43db-b854-937df9a365b5",
            price: 1.25259,
            price_sources: [],
            processed_ms: 1715573164951,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "85f81ede-ca21-49d9-a2a0-30f7639967c9",
            price: 1.2518,
            price_sources: [],
            processed_ms: 1715584401294,
          },
        ],
        position_type: "FLAT",
        position_uuid: "a7b30a3b-612a-49bc-8c32-bea77176f6e9",
        return_at_close: 1.0001674762422674,
        trade_pair: ["GBPUSD", "GBP/USD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 1.25221,
        close_ms: 1715590458519,
        current_return: 1.0001860230951678,
        initial_entry_price: 1.25221,
        is_closed_position: true,
        miner_hotkey: "5G3ys2356ovgUivX3endMP7f37LPEjRkzDAM3Km8CxQnErCw",
        net_leverage: 0.0,
        open_ms: 1715585113364,
        orders: [
          {
            leverage: 0.298641,
            order_type: "LONG",
            order_uuid: "1ee80de0-72b0-4c0d-8b6a-638421773767",
            price: 1.25221,
            price_sources: [],
            processed_ms: 1715585112183,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "9f10c205-e680-4090-a8b1-e487de590647",
            price: 1.25299,
            price_sources: [],
            processed_ms: 1715590458519,
          },
        ],
        position_type: "FLAT",
        position_uuid: "0d0b8107-01ba-4b68-891c-bdce25a8b9fc",
        return_at_close: 1.0001651143363792,
        trade_pair: ["GBPUSD", "GBP/USD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 1.25298,
        close_ms: 1715674503721,
        current_return: 1.0002359058324954,
        initial_entry_price: 1.25298,
        is_closed_position: true,
        miner_hotkey: "5G3ys2356ovgUivX3endMP7f37LPEjRkzDAM3Km8CxQnErCw",
        net_leverage: 0.0,
        open_ms: 1715591719251,
        orders: [
          {
            leverage: -0.298571,
            order_type: "SHORT",
            order_uuid: "02cdd7f1-f350-45ef-b2fb-4e39b53c0fd2",
            price: 1.25298,
            price_sources: [],
            processed_ms: 1715591718152,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "03b44a50-5220-4f3a-84df-ccc8aa089a5b",
            price: 1.25199,
            price_sources: [],
            processed_ms: 1715674503721,
          },
        ],
        position_type: "FLAT",
        position_uuid: "682f1099-59d8-4871-9897-36aa8a9f8ca9",
        return_at_close: 1.0002150009320707,
        trade_pair: ["GBPUSD", "GBP/USD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 1.25498,
        close_ms: 1715689808730,
        current_return: 1.0006445843121006,
        initial_entry_price: 1.25498,
        is_closed_position: true,
        miner_hotkey: "5G3ys2356ovgUivX3endMP7f37LPEjRkzDAM3Km8CxQnErCw",
        net_leverage: 0.0,
        open_ms: 1715682914757,
        orders: [
          {
            leverage: -0.298502,
            order_type: "SHORT",
            order_uuid: "02fbb182-a03d-4173-8edf-611550c9c361",
            price: 1.25498,
            price_sources: [],
            processed_ms: 1715682913907,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "d61ab453-734d-4cd8-bf6e-1bd6a193d74d",
            price: 1.25227,
            price_sources: [],
            processed_ms: 1715689808730,
          },
        ],
        position_type: "FLAT",
        position_uuid: "e2cd778c-7a93-43c7-96ab-e6ed16f71c40",
        return_at_close: 1.000623675703421,
        trade_pair: ["GBPUSD", "GBP/USD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 1.25549,
        close_ms: 1715691454077,
        current_return: 1.00021630701957,
        initial_entry_price: 1.25549,
        is_closed_position: true,
        miner_hotkey: "5G3ys2356ovgUivX3endMP7f37LPEjRkzDAM3Km8CxQnErCw",
        net_leverage: 0.0,
        open_ms: 1715690724997,
        orders: [
          {
            leverage: 0.29843,
            order_type: "LONG",
            order_uuid: "11a78884-9ba0-4b5d-85d3-6c842ddba06c",
            price: 1.25549,
            price_sources: [],
            processed_ms: 1715690723061,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "c5467adc-8f6d-4ac1-a56a-e7203a042ea1",
            price: 1.2564,
            price_sources: [],
            processed_ms: 1715691454077,
          },
        ],
        position_type: "FLAT",
        position_uuid: "b6a6b13a-94de-4df8-8f86-cc1ed6f700ec",
        return_at_close: 1.0001954124008947,
        trade_pair: ["GBPUSD", "GBP/USD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.60277,
        close_ms: 1715713333832,
        current_return: 1.0006596382533968,
        initial_entry_price: 0.60277,
        is_closed_position: true,
        miner_hotkey: "5G3ys2356ovgUivX3endMP7f37LPEjRkzDAM3Km8CxQnErCw",
        net_leverage: 0.0,
        open_ms: 1715290153244,
        orders: [
          {
            leverage: 0.298955,
            order_type: "LONG",
            order_uuid: "20129e39-caef-46e7-99a3-151a4a79d1dd",
            price: 0.60277,
            price_sources: [],
            processed_ms: 1715290151624,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "b5e14fef-997d-493a-8335-e9129bff9954",
            price: 0.6041,
            price_sources: [],
            processed_ms: 1715713333832,
          },
        ],
        position_type: "FLAT",
        position_uuid: "a289bd1e-b74a-443a-a7a7-2d403758fb4e",
        return_at_close: 1.000638697599246,
        trade_pair: ["NZDUSD", "NZD/USD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 1.2585,
        close_ms: 1715743052447,
        current_return: 1.000298647532777,
        initial_entry_price: 1.2585,
        is_closed_position: true,
        miner_hotkey: "5G3ys2356ovgUivX3endMP7f37LPEjRkzDAM3Km8CxQnErCw",
        net_leverage: 0.0,
        open_ms: 1715721504509,
        orders: [
          {
            leverage: 0.298292,
            order_type: "LONG",
            order_uuid: "d6504de0-a81f-4103-a649-686632b23465",
            price: 1.2585,
            price_sources: [],
            processed_ms: 1715721501708,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "bdddc34d-49f8-41c8-a990-1fd82fb68d9a",
            price: 1.25976,
            price_sources: [],
            processed_ms: 1715743052447,
          },
        ],
        position_type: "FLAT",
        position_uuid: "7fcf5931-3184-4288-bd75-f698ef223fd8",
        return_at_close: 1.0002777608568851,
        trade_pair: ["GBPUSD", "GBP/USD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 1.25929,
        close_ms: 1715761043244,
        current_return: 1.0002131536818366,
        initial_entry_price: 1.25929,
        is_closed_position: true,
        miner_hotkey: "5G3ys2356ovgUivX3endMP7f37LPEjRkzDAM3Km8CxQnErCw",
        net_leverage: 0.0,
        open_ms: 1715748619598,
        orders: [
          {
            leverage: 0.298247,
            order_type: "LONG",
            order_uuid: "162f0216-f27b-4b57-9eaa-77a921eba5c5",
            price: 1.25929,
            price_sources: [],
            processed_ms: 1715748619202,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "323e19b1-1351-405d-9f67-40e0b1fe8a9d",
            price: 1.26019,
            price_sources: [],
            processed_ms: 1715761043244,
          },
        ],
        position_type: "FLAT",
        position_uuid: "016149ce-9314-4705-afb3-f07cd086eb5c",
        return_at_close: 1.0001922719417653,
        trade_pair: ["GBPUSD", "GBP/USD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 1.26108,
        close_ms: 1715774637973,
        current_return: 1.0001867915120373,
        initial_entry_price: 1.26108,
        is_closed_position: true,
        miner_hotkey: "5G3ys2356ovgUivX3endMP7f37LPEjRkzDAM3Km8CxQnErCw",
        net_leverage: 0.0,
        open_ms: 1715771714247,
        orders: [
          {
            leverage: 0.298176,
            order_type: "LONG",
            order_uuid: "dc65df98-b7e7-445f-b990-e52d7f46efab",
            price: 1.26108,
            price_sources: [],
            processed_ms: 1715771713936,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "897807a4-6ac4-473d-9a20-b1bc24e97712",
            price: 1.26187,
            price_sources: [],
            processed_ms: 1715774637973,
          },
        ],
        position_type: "FLAT",
        position_uuid: "5448a654-068b-443d-a653-0cc9a0457bf2",
        return_at_close: 1.000165915293265,
        trade_pair: ["GBPUSD", "GBP/USD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.6057,
        close_ms: 1715774884429,
        current_return: 1.0004529006438831,
        initial_entry_price: 0.6057,
        is_closed_position: true,
        miner_hotkey: "5G3ys2356ovgUivX3endMP7f37LPEjRkzDAM3Km8CxQnErCw",
        net_leverage: 0.0,
        open_ms: 1715770512309,
        orders: [
          {
            leverage: 0.298176,
            order_type: "LONG",
            order_uuid: "28859c1a-4818-45e9-b541-b939c9cd1ecc",
            price: 0.6057,
            price_sources: [],
            processed_ms: 1715770511634,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "93298bc1-b20d-428d-820d-b8b4693798bf",
            price: 0.60662,
            price_sources: [],
            processed_ms: 1715774884429,
          },
        ],
        position_type: "FLAT",
        position_uuid: "3c3d22d9-323d-468c-8221-9a2b1fb637a6",
        return_at_close: 1.000432018870796,
        trade_pair: ["NZDUSD", "NZD/USD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 1.26209,
        close_ms: 1715782260752,
        current_return: 1.0001204382967934,
        initial_entry_price: 1.26209,
        is_closed_position: true,
        miner_hotkey: "5G3ys2356ovgUivX3endMP7f37LPEjRkzDAM3Km8CxQnErCw",
        net_leverage: 0.0,
        open_ms: 1715781912404,
        orders: [
          {
            leverage: 0.298047,
            order_type: "LONG",
            order_uuid: "70d017cc-0988-42b0-a8c2-99c876030f30",
            price: 1.26209,
            price_sources: [],
            processed_ms: 1715781911367,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "26a2e8c0-10b8-4ef7-aef0-b9ae9177ea01",
            price: 1.2626,
            price_sources: [],
            processed_ms: 1715782260752,
          },
        ],
        position_type: "FLAT",
        position_uuid: "d6cdbe23-2a1d-4484-b0c0-db67c13c3889",
        return_at_close: 1.0000995724940545,
        trade_pair: ["GBPUSD", "GBP/USD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.60612,
        close_ms: 1715782879270,
        current_return: 1.00080640954235,
        initial_entry_price: 0.60614,
        is_closed_position: true,
        miner_hotkey: "5G3ys2356ovgUivX3endMP7f37LPEjRkzDAM3Km8CxQnErCw",
        net_leverage: 0.0,
        open_ms: 1715781935071,
        orders: [
          {
            leverage: 0.298047,
            order_type: "LONG",
            order_uuid: "bf6828c3-fbef-43cd-a1f7-fbee10009235",
            price: 0.60614,
            price_sources: [],
            processed_ms: 1715781932895,
          },
          {
            leverage: 0.298047,
            order_type: "LONG",
            order_uuid: "35dd94a6-78c4-4d7d-ae69-5d68682b666d",
            price: 0.6061,
            price_sources: [],
            processed_ms: 1715781964306,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "8f66195b-016a-41e6-9916-5abc0e1ab58e",
            price: 0.60694,
            price_sources: [],
            processed_ms: 1715782879270,
          },
        ],
        position_type: "FLAT",
        position_uuid: "e8baedaa-8603-4526-a2bc-ebe4a8ff6a61",
        return_at_close: 1.0007646493136375,
        trade_pair: ["NZDUSD", "NZD/USD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.6116,
        close_ms: 1715814997972,
        current_return: 1.0004383982995422,
        initial_entry_price: 0.6116,
        is_closed_position: true,
        miner_hotkey: "5G3ys2356ovgUivX3endMP7f37LPEjRkzDAM3Km8CxQnErCw",
        net_leverage: 0.0,
        open_ms: 1715808824536,
        orders: [
          {
            leverage: 0.297916,
            order_type: "LONG",
            order_uuid: "7ec8f128-f94b-4cee-b46a-5840e516a383",
            price: 0.6116,
            price_sources: [],
            processed_ms: 1715808822849,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "fdb23934-a090-4908-b31e-275cc53d148a",
            price: 0.6125,
            price_sources: [],
            processed_ms: 1715814997972,
          },
        ],
        position_type: "FLAT",
        position_uuid: "b3d77066-0783-4338-b0c7-532cd76c93ed",
        return_at_close: 1.0004175350371314,
        trade_pair: ["NZDUSD", "NZD/USD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 1.26784,
        close_ms: 1715815988552,
        current_return: 1.0002890243879354,
        initial_entry_price: 1.26784,
        is_closed_position: true,
        miner_hotkey: "5G3ys2356ovgUivX3endMP7f37LPEjRkzDAM3Km8CxQnErCw",
        net_leverage: 0.0,
        open_ms: 1715807926443,
        orders: [
          {
            leverage: 0.297916,
            order_type: "LONG",
            order_uuid: "39def0f3-fdf8-4a65-accb-ca0a4b0c0388",
            price: 1.26784,
            price_sources: [],
            processed_ms: 1715807923859,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "fcf163cf-53d8-4b05-adeb-bafdacc459ce",
            price: 1.26907,
            price_sources: [],
            processed_ms: 1715815988552,
          },
        ],
        position_type: "FLAT",
        position_uuid: "3a9e3cad-f6fd-418b-af7e-7a407a01ee87",
        return_at_close: 1.0002681642405862,
        trade_pair: ["GBPUSD", "GBP/USD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 1.2685,
        close_ms: 1715957042493,
        current_return: 1.0001549478123768,
        initial_entry_price: 1.2685,
        is_closed_position: true,
        miner_hotkey: "5G3ys2356ovgUivX3endMP7f37LPEjRkzDAM3Km8CxQnErCw",
        net_leverage: 0.0,
        open_ms: 1715837417717,
        orders: [
          {
            leverage: 0.297805,
            order_type: "LONG",
            order_uuid: "10268bf6-a8de-476e-bfc3-50ae3d16ff05",
            price: 1.2685,
            price_sources: [],
            processed_ms: 1715837416226,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "ff1bcfa3-b9ea-45ac-b0df-ee6199dcf97a",
            price: 1.26916,
            price_sources: [],
            processed_ms: 1715957042493,
          },
        ],
        position_type: "FLAT",
        position_uuid: "b4f18825-a3e8-4d5d-9bd4-b5d91f04245a",
        return_at_close: 1.0001340982322804,
        trade_pair: ["GBPUSD", "GBP/USD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.6122299999999999,
        close_ms: 1715957341645,
        current_return: 1.0005544630083294,
        initial_entry_price: 0.6123,
        is_closed_position: true,
        miner_hotkey: "5G3ys2356ovgUivX3endMP7f37LPEjRkzDAM3Km8CxQnErCw",
        net_leverage: 0.0,
        open_ms: 1715824833213,
        orders: [
          {
            leverage: 0.297805,
            order_type: "LONG",
            order_uuid: "3b4c5467-b465-49b5-90e2-d88cbe95d62d",
            price: 0.6123,
            price_sources: [],
            processed_ms: 1715824831674,
          },
          {
            leverage: 0.297805,
            order_type: "LONG",
            order_uuid: "d60af400-76ae-403f-8a07-7b0e8c83402e",
            price: 0.61216,
            price_sources: [],
            processed_ms: 1715824862323,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "edda57cd-c9eb-43de-93bf-d6da05ed5fd6",
            price: 0.6128,
            price_sources: [],
            processed_ms: 1715957341645,
          },
        ],
        position_type: "FLAT",
        position_uuid: "3ceaf05e-e3f5-406e-8975-e093e1e66de2",
        return_at_close: 1.0005127471912696,
        trade_pair: ["NZDUSD", "NZD/USD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 1.26916,
        close_ms: 1716162304502,
        current_return: 1.000367056596489,
        initial_entry_price: 1.26916,
        is_closed_position: true,
        miner_hotkey: "5G3ys2356ovgUivX3endMP7f37LPEjRkzDAM3Km8CxQnErCw",
        net_leverage: 0.0,
        open_ms: 1716154497293,
        orders: [
          {
            leverage: 0.29767,
            order_type: "LONG",
            order_uuid: "508c16be-1d18-48e8-9a8c-da138463772e",
            price: 1.26916,
            price_sources: [],
            processed_ms: 1716154496462,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "f7851bf4-6891-47ee-86e7-8f14727a80c3",
            price: 1.270725,
            price_sources: [],
            processed_ms: 1716162304502,
          },
        ],
        position_type: "FLAT",
        position_uuid: "d5ef986e-7311-4313-9640-4ac1a288d128",
        return_at_close: 1.0003462120481674,
        trade_pair: ["GBPUSD", "GBP/USD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 1.27017,
        close_ms: 1716178595500,
        current_return: 1.000189783745483,
        initial_entry_price: 1.27017,
        is_closed_position: true,
        miner_hotkey: "5G3ys2356ovgUivX3endMP7f37LPEjRkzDAM3Km8CxQnErCw",
        net_leverage: 0.0,
        open_ms: 1716164417999,
        orders: [
          {
            leverage: 0.297602,
            order_type: "LONG",
            order_uuid: "80a6f690-1da1-4f22-91f1-62f617f5bd3e",
            price: 1.27017,
            price_sources: [],
            processed_ms: 1716164417676,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "f0acd38d-3682-43a3-808d-b486c09ec978",
            price: 1.27098,
            price_sources: [],
            processed_ms: 1716178595500,
          },
        ],
        position_type: "FLAT",
        position_uuid: "74a66318-51f6-4837-b6e7-e631ece6ec5c",
        return_at_close: 1.0001689476518814,
        trade_pair: ["GBPUSD", "GBP/USD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 1.27026,
        close_ms: 1716215578952,
        current_return: 1.0002086211602348,
        initial_entry_price: 1.27026,
        is_closed_position: true,
        miner_hotkey: "5G3ys2356ovgUivX3endMP7f37LPEjRkzDAM3Km8CxQnErCw",
        net_leverage: 0.0,
        open_ms: 1716186920323,
        orders: [
          {
            leverage: 0.317369,
            order_type: "LONG",
            order_uuid: "47152683-e3f2-4518-8660-fb9fd299ae4f",
            price: 1.27026,
            price_sources: [],
            processed_ms: 1716186920102,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "606b5e4c-9687-47ec-934f-e409a616e074",
            price: 1.2710949999999999,
            price_sources: [],
            processed_ms: 1716215578952,
          },
        ],
        position_type: "FLAT",
        position_uuid: "e691f9ca-828b-4569-8f00-f69528ad0f71",
        return_at_close: 1.0001864006955425,
        trade_pair: ["GBPUSD", "GBP/USD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 1.27091,
        close_ms: 1716275529232,
        current_return: 1.000203625221298,
        initial_entry_price: 1.27091,
        is_closed_position: true,
        miner_hotkey: "5G3ys2356ovgUivX3endMP7f37LPEjRkzDAM3Km8CxQnErCw",
        net_leverage: 0.0,
        open_ms: 1716229812970,
        orders: [
          {
            leverage: 0.297459,
            order_type: "LONG",
            order_uuid: "2c65f454-b9f9-4124-b9cb-e035785720ad",
            price: 1.27091,
            price_sources: [],
            processed_ms: 1716229812826,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "d78b5f09-f7d1-4e68-b4ab-aeef46b11d04",
            price: 1.27178,
            price_sources: [],
            processed_ms: 1716275529232,
          },
        ],
        position_type: "FLAT",
        position_uuid: "2e703c81-6df0-482e-92e5-fa2a9d5d228c",
        return_at_close: 1.0001827988513872,
        trade_pair: ["GBPUSD", "GBP/USD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 1.2698649999999998,
        close_ms: 1716296903398,
        current_return: 1.0001978914254666,
        initial_entry_price: 1.2698649999999998,
        is_closed_position: true,
        miner_hotkey: "5G3ys2356ovgUivX3endMP7f37LPEjRkzDAM3Km8CxQnErCw",
        net_leverage: 0.0,
        open_ms: 1716296418113,
        orders: [
          {
            leverage: 0.297391,
            order_type: "LONG",
            order_uuid: "a6b0b2fd-c879-47eb-99f7-af9e51e154be",
            price: 1.2698649999999998,
            price_sources: [],
            processed_ms: 1716296417543,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "2ed4dc13-aaf9-4a20-8b35-984fb95ecd1a",
            price: 1.27071,
            price_sources: [],
            processed_ms: 1716296903398,
          },
        ],
        position_type: "FLAT",
        position_uuid: "cc2ff27e-a776-4b4d-9080-88a92c6c79e2",
        return_at_close: 1.0001770699358874,
        trade_pair: ["GBPUSD", "GBP/USD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 1.27062,
        close_ms: 1716299227783,
        current_return: 1.000184854354567,
        initial_entry_price: 1.27062,
        is_closed_position: true,
        miner_hotkey: "5G3ys2356ovgUivX3endMP7f37LPEjRkzDAM3Km8CxQnErCw",
        net_leverage: 0.0,
        open_ms: 1716297309906,
        orders: [
          {
            leverage: 0.297316,
            order_type: "LONG",
            order_uuid: "6165d344-7bd6-4025-8888-5d8f4991861b",
            price: 1.27062,
            price_sources: [],
            processed_ms: 1716297309436,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "f191b7f4-7146-436e-ba43-4aba6970b0b3",
            price: 1.27141,
            price_sources: [],
            processed_ms: 1716299227783,
          },
        ],
        position_type: "FLAT",
        position_uuid: "7f4e4dd6-ef78-42ce-a63a-4303a309ff3d",
        return_at_close: 1.000164038387356,
        trade_pair: ["GBPUSD", "GBP/USD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 1.2706300000000001,
        close_ms: 1716319460512,
        current_return: 1.0002035235670494,
        initial_entry_price: 1.2706300000000001,
        is_closed_position: true,
        miner_hotkey: "5G3ys2356ovgUivX3endMP7f37LPEjRkzDAM3Km8CxQnErCw",
        net_leverage: 0.0,
        open_ms: 1716307812929,
        orders: [
          {
            leverage: 0.297245,
            order_type: "LONG",
            order_uuid: "01067c73-742d-4e23-852b-e6b3db0cae77",
            price: 1.2706300000000001,
            price_sources: [],
            processed_ms: 1716307812811,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "3888fe99-e562-4a49-ae8f-d52e7e248165",
            price: 1.2715,
            price_sources: [],
            processed_ms: 1716319460512,
          },
        ],
        position_type: "FLAT",
        position_uuid: "c376b91f-787d-4bfb-88e6-1f35f8fc2268",
        return_at_close: 1.0001827121823041,
        trade_pair: ["GBPUSD", "GBP/USD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.6111972888001092,
        close_ms: 1716343215493,
        current_return: 1.0015703325719507,
        initial_entry_price: 0.61292,
        is_closed_position: true,
        miner_hotkey: "5G3ys2356ovgUivX3endMP7f37LPEjRkzDAM3Km8CxQnErCw",
        net_leverage: 0.0,
        open_ms: 1716153813113,
        orders: [
          {
            leverage: 0.29767,
            order_type: "LONG",
            order_uuid: "5d90e664-a0d7-494f-b2c7-6003579a9e56",
            price: 0.61292,
            price_sources: [],
            processed_ms: 1716153811892,
          },
          {
            leverage: 0.347122,
            order_type: "LONG",
            order_uuid: "11c1ce42-f61f-41b3-8658-8fba8cc6273c",
            price: 0.60972,
            price_sources: [],
            processed_ms: 1716208216544,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "8f13d929-08ca-412e-be77-d6936253a0ef",
            price: 0.61269,
            price_sources: [],
            processed_ms: 1716343215493,
          },
        ],
        position_type: "FLAT",
        position_uuid: "0463eb4d-ef15-45ce-a415-34c8ce6850bf",
        return_at_close: 1.0015251262542992,
        trade_pair: ["NZDUSD", "NZD/USD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.6117,
        close_ms: 1716355841401,
        current_return: 1.0003641601275135,
        initial_entry_price: 0.6117,
        is_closed_position: true,
        miner_hotkey: "5G3ys2356ovgUivX3endMP7f37LPEjRkzDAM3Km8CxQnErCw",
        net_leverage: 0.0,
        open_ms: 1716351912526,
        orders: [
          {
            leverage: 0.297009,
            order_type: "LONG",
            order_uuid: "95cda932-a5fa-4f75-88f7-88c5a1a792b4",
            price: 0.6117,
            price_sources: [],
            processed_ms: 1716351912413,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "614dc04f-13f0-4c1c-abf9-b3059f9eaa1f",
            price: 0.61245,
            price_sources: [],
            processed_ms: 1716355841401,
          },
        ],
        position_type: "FLAT",
        position_uuid: "2c6e92d7-3196-475c-b7ec-7e80185aa60c",
        return_at_close: 1.000343361926395,
        trade_pair: ["NZDUSD", "NZD/USD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 1.27123,
        close_ms: 1716378236229,
        current_return: 1.0001776649386815,
        initial_entry_price: 1.27123,
        is_closed_position: true,
        miner_hotkey: "5G3ys2356ovgUivX3endMP7f37LPEjRkzDAM3Km8CxQnErCw",
        net_leverage: 0.0,
        open_ms: 1716334394262,
        orders: [
          {
            leverage: -0.297175,
            order_type: "SHORT",
            order_uuid: "2e027d34-817b-430b-b97f-970afa4d7162",
            price: 1.27123,
            price_sources: [],
            processed_ms: 1716334393052,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "61d3ca4c-189d-4447-9f1c-edcb6ce03626",
            price: 1.27047,
            price_sources: [],
            processed_ms: 1716378236229,
          },
        ],
        position_type: "FLAT",
        position_uuid: "54b56b9d-58a2-43a8-b589-08e215302035",
        return_at_close: 1.0001568589928511,
        trade_pair: ["GBPUSD", "GBP/USD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 1.27272,
        close_ms: 1716402081117,
        current_return: 1.0002157664686655,
        initial_entry_price: 1.27272,
        is_closed_position: true,
        miner_hotkey: "5G3ys2356ovgUivX3endMP7f37LPEjRkzDAM3Km8CxQnErCw",
        net_leverage: 0.0,
        open_ms: 1716382817368,
        orders: [
          {
            leverage: -0.296876,
            order_type: "SHORT",
            order_uuid: "41e4db72-7bf9-4939-99f5-59109f89308b",
            price: 1.27272,
            price_sources: [],
            processed_ms: 1716382817102,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "bf79e66a-c8d6-4d4a-a49c-16601a56108f",
            price: 1.271795,
            price_sources: [],
            processed_ms: 1716402081117,
          },
        ],
        position_type: "FLAT",
        position_uuid: "97d830cc-8f0e-4a20-8c7f-63d9bdcd0b3a",
        return_at_close: 1.0001949806647534,
        trade_pair: ["GBPUSD", "GBP/USD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 1.27167,
        close_ms: 1716433672724,
        current_return: 1.0002287314004419,
        initial_entry_price: 1.27167,
        is_closed_position: true,
        miner_hotkey: "5G3ys2356ovgUivX3endMP7f37LPEjRkzDAM3Km8CxQnErCw",
        net_leverage: 0.0,
        open_ms: 1716403810669,
        orders: [
          {
            leverage: 0.296807,
            order_type: "LONG",
            order_uuid: "d189d14a-2cfa-47b0-9d08-0dfd7cfd34ae",
            price: 1.27167,
            price_sources: [],
            processed_ms: 1716403810603,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "426cf7e2-a0eb-4fbe-b3b7-40aa9c0e0f32",
            price: 1.27265,
            price_sources: [],
            processed_ms: 1716433672724,
          },
        ],
        position_type: "FLAT",
        position_uuid: "7db9caf3-bee7-46dd-94c5-e0b1100074de",
        return_at_close: 1.0002079501582062,
        trade_pair: ["GBPUSD", "GBP/USD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 1.27245,
        close_ms: 1716449406435,
        current_return: 1.0000536370230657,
        initial_entry_price: 1.27245,
        is_closed_position: true,
        miner_hotkey: "5G3ys2356ovgUivX3endMP7f37LPEjRkzDAM3Km8CxQnErCw",
        net_leverage: 0.0,
        open_ms: 1716444318055,
        orders: [
          {
            leverage: 0.296741,
            order_type: "LONG",
            order_uuid: "c4f9eded-f70d-42c8-baaf-539cf45aff17",
            price: 1.27245,
            price_sources: [],
            processed_ms: 1716444318040,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "77bd7ed0-232e-4b87-a03e-95ad3ce468f8",
            price: 1.27268,
            price_sources: [],
            processed_ms: 1716449406435,
          },
        ],
        position_type: "FLAT",
        position_uuid: "b3f7d62c-478c-4e55-87d3-c63e0d6194b8",
        return_at_close: 1.0000328640389244,
        trade_pair: ["GBPUSD", "GBP/USD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 1.27135,
        close_ms: 1716457902862,
        current_return: 1.0002053504070476,
        initial_entry_price: 1.27135,
        is_closed_position: true,
        miner_hotkey: "5G3ys2356ovgUivX3endMP7f37LPEjRkzDAM3Km8CxQnErCw",
        net_leverage: 0.0,
        open_ms: 1716456615229,
        orders: [
          {
            leverage: 0.296673,
            order_type: "LONG",
            order_uuid: "30ed402d-b705-4bdb-94b7-b5200579066e",
            price: 1.27135,
            price_sources: [],
            processed_ms: 1716456614988,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "d3113d8d-b380-4d47-82c7-1cb7ec0c4494",
            price: 1.27223,
            price_sources: [],
            processed_ms: 1716457902862,
          },
        ],
        position_type: "FLAT",
        position_uuid: "a53e584c-6db5-4dcf-aaa8-33b5e1fa4daf",
        return_at_close: 1.0001845790325132,
        trade_pair: ["GBPUSD", "GBP/USD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.61172,
        close_ms: 1716458024461,
        current_return: 1.0002330030733015,
        initial_entry_price: 0.61172,
        is_closed_position: true,
        miner_hotkey: "5G3ys2356ovgUivX3endMP7f37LPEjRkzDAM3Km8CxQnErCw",
        net_leverage: 0.0,
        open_ms: 1716360915611,
        orders: [
          {
            leverage: 0.296943,
            order_type: "LONG",
            order_uuid: "447c68e9-7c48-4b2e-a670-ea216c442287",
            price: 0.61172,
            price_sources: [],
            processed_ms: 1716360915376,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "9ec02d59-6ab7-4faa-822f-d2fd1c87114c",
            price: 0.6122,
            price_sources: [],
            processed_ms: 1716458024461,
          },
        ],
        position_type: "FLAT",
        position_uuid: "a1390511-d897-428c-9aae-83d0e2f4de37",
        return_at_close: 1.0002122122200974,
        trade_pair: ["NZDUSD", "NZD/USD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.6127,
        close_ms: 1716471909273,
        current_return: 1.0007985601436267,
        initial_entry_price: 0.6127,
        is_closed_position: true,
        miner_hotkey: "5G3ys2356ovgUivX3endMP7f37LPEjRkzDAM3Km8CxQnErCw",
        net_leverage: 0.0,
        open_ms: 1716460820783,
        orders: [
          {
            leverage: -0.296532,
            order_type: "SHORT",
            order_uuid: "06e7c80d-0d5a-4409-b0ea-2ddee50a9014",
            price: 0.6127,
            price_sources: [],
            processed_ms: 1716460820358,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "c48e0e8c-566e-4d8d-96c0-905e4de66a3b",
            price: 0.61105,
            price_sources: [],
            processed_ms: 1716471909273,
          },
        ],
        position_type: "FLAT",
        position_uuid: "e82f78e7-1f6f-496b-b9d1-bc58ae00da60",
        return_at_close: 1.000777786327722,
        trade_pair: ["NZDUSD", "NZD/USD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 1.27317,
        close_ms: 1716471909275,
        current_return: 1.0004704749876292,
        initial_entry_price: 1.27317,
        is_closed_position: true,
        miner_hotkey: "5G3ys2356ovgUivX3endMP7f37LPEjRkzDAM3Km8CxQnErCw",
        net_leverage: 0.0,
        open_ms: 1716464719853,
        orders: [
          {
            leverage: -0.296532,
            order_type: "SHORT",
            order_uuid: "73b5f366-7d26-4271-a6ce-673ed7dd80a8",
            price: 1.27317,
            price_sources: [],
            processed_ms: 1716464719793,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "988ca952-1013-4067-809f-4b42d3132da0",
            price: 1.27115,
            price_sources: [],
            processed_ms: 1716471909275,
          },
        ],
        position_type: "FLAT",
        position_uuid: "df77ad93-b7fd-463d-a146-2aa92f4f4ea8",
        return_at_close: 1.000449707981867,
        trade_pair: ["GBPUSD", "GBP/USD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 1.27155,
        close_ms: 1716473605407,
        current_return: 1.0002144503322716,
        initial_entry_price: 1.27155,
        is_closed_position: true,
        miner_hotkey: "5G3ys2356ovgUivX3endMP7f37LPEjRkzDAM3Km8CxQnErCw",
        net_leverage: 0.0,
        open_ms: 1716473123511,
        orders: [
          {
            leverage: 0.296396,
            order_type: "LONG",
            order_uuid: "7a3cdc1c-89a5-4779-9970-c23f7dd8d9e0",
            price: 1.27155,
            price_sources: [],
            processed_ms: 1716473123292,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "e44f25b2-e55d-4271-9e48-a2c16a17faee",
            price: 1.27247,
            price_sources: [],
            processed_ms: 1716473605407,
          },
        ],
        position_type: "FLAT",
        position_uuid: "1b7ebda4-0a3f-477e-a7f2-023b10320204",
        return_at_close: 1.000193698162916,
        trade_pair: ["GBPUSD", "GBP/USD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.611185,
        close_ms: 1716473605506,
        current_return: 1.0004097852859608,
        initial_entry_price: 0.611185,
        is_closed_position: true,
        miner_hotkey: "5G3ys2356ovgUivX3endMP7f37LPEjRkzDAM3Km8CxQnErCw",
        net_leverage: 0.0,
        open_ms: 1716473415558,
        orders: [
          {
            leverage: 0.296396,
            order_type: "LONG",
            order_uuid: "c41c12dd-b115-42ac-8ac4-8c0cddf4469e",
            price: 0.611185,
            price_sources: [],
            processed_ms: 1716473415455,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "6b9a8c31-12ce-4de9-8df2-1658a3196cb9",
            price: 0.61203,
            price_sources: [],
            processed_ms: 1716473605506,
          },
        ],
        position_type: "FLAT",
        position_uuid: "bb476cb8-c1ed-4706-a7ca-1193e67160d1",
        return_at_close: 1.0003890290638504,
        trade_pair: ["NZDUSD", "NZD/USD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 1.27139,
        close_ms: 1716480246756,
        current_return: 1.0002003990278356,
        initial_entry_price: 1.27139,
        is_closed_position: true,
        miner_hotkey: "5G3ys2356ovgUivX3endMP7f37LPEjRkzDAM3Km8CxQnErCw",
        net_leverage: 0.0,
        open_ms: 1716474618318,
        orders: [
          {
            leverage: 0.296262,
            order_type: "LONG",
            order_uuid: "981d56e4-ca92-4928-a330-c88d409a1fa1",
            price: 1.27139,
            price_sources: [],
            processed_ms: 1716474618303,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "6b39d9a0-166b-40ef-b69d-6c064b365083",
            price: 1.27225,
            price_sources: [],
            processed_ms: 1716480246756,
          },
        ],
        position_type: "FLAT",
        position_uuid: "f944e7b7-2474-4547-8657-6997e8c5b2f8",
        return_at_close: 1.0001796565318923,
        trade_pair: ["GBPUSD", "GBP/USD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 1.26918,
        close_ms: 1716522596091,
        current_return: 1.0001096855528766,
        initial_entry_price: 1.26918,
        is_closed_position: true,
        miner_hotkey: "5G3ys2356ovgUivX3endMP7f37LPEjRkzDAM3Km8CxQnErCw",
        net_leverage: 0.0,
        open_ms: 1716498850002,
        orders: [
          {
            leverage: -0.296193,
            order_type: "SHORT",
            order_uuid: "1edbe95c-c018-4c48-9fdc-53da41c03993",
            price: 1.26918,
            price_sources: [],
            processed_ms: 1716498849796,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "0d0d7968-dfa8-44d4-ad6d-dfda771164df",
            price: 1.26871,
            price_sources: [],
            processed_ms: 1716522596091,
          },
        ],
        position_type: "FLAT",
        position_uuid: "dd106911-98bb-432e-8545-b13f5e229adf",
        return_at_close: 1.0000889497687102,
        trade_pair: ["GBPUSD", "GBP/USD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.61147,
        close_ms: 1716563159802,
        current_return: 1.0003706484864343,
        initial_entry_price: 0.61147,
        is_closed_position: true,
        miner_hotkey: "5G3ys2356ovgUivX3endMP7f37LPEjRkzDAM3Km8CxQnErCw",
        net_leverage: 0.0,
        open_ms: 1716473720144,
        orders: [
          {
            leverage: 0.296262,
            order_type: "LONG",
            order_uuid: "9866d233-6eaa-44a5-a4ef-e7035d4795e4",
            price: 0.61147,
            price_sources: [],
            processed_ms: 1716473720093,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "0b0f31be-0203-4c14-b20e-88a092456dd6",
            price: 0.612235,
            price_sources: [],
            processed_ms: 1716563159802,
          },
        ],
        position_type: "FLAT",
        position_uuid: "b6c14c3d-276a-4f5d-bd38-abca2cf5be80",
        return_at_close: 1.0003499024598,
        trade_pair: ["NZDUSD", "NZD/USD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.61152,
        close_ms: 1716859334243,
        current_return: 1.0020869584968604,
        initial_entry_price: 0.61152,
        is_closed_position: true,
        miner_hotkey: "5G3ys2356ovgUivX3endMP7f37LPEjRkzDAM3Km8CxQnErCw",
        net_leverage: 0.0,
        open_ms: 1716759800446,
        orders: [
          {
            leverage: 0.296106,
            order_type: "LONG",
            order_uuid: "f7d7b558-b8f8-49a8-91c5-26d0f74d8be5",
            price: 0.61152,
            price_sources: [],
            processed_ms: 1716759800253,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "70d8e542-327b-4105-9b77-469f1b18a04c",
            price: 0.61583,
            price_sources: [],
            processed_ms: 1716859334243,
          },
        ],
        position_type: "FLAT",
        position_uuid: "c5671db4-e7a6-490c-8135-e65f0b1c1a0f",
        return_at_close: 1.0020661878195951,
        trade_pair: ["NZDUSD", "NZD/USD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.61584,
        close_ms: 1716898264136,
        current_return: 1.0003243483291113,
        initial_entry_price: 0.61584,
        is_closed_position: true,
        miner_hotkey: "5G3ys2356ovgUivX3endMP7f37LPEjRkzDAM3Km8CxQnErCw",
        net_leverage: 0.0,
        open_ms: 1716885616678,
        orders: [
          {
            leverage: 0.295921,
            order_type: "LONG",
            order_uuid: "602f15e7-52e8-4971-b759-f4484da011e2",
            price: 0.61584,
            price_sources: [],
            processed_ms: 1716885616463,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "310013f0-bc61-496d-a999-12186f287c56",
            price: 0.6165149999999999,
            price_sources: [],
            processed_ms: 1716898264136,
          },
        ],
        position_type: "FLAT",
        position_uuid: "b9c4e4b8-2e31-4d01-b70f-72fe624e2f93",
        return_at_close: 1.0003036271404075,
        trade_pair: ["NZDUSD", "NZD/USD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.6123,
        close_ms: 1717135556942,
        current_return: 1.0003584860689205,
        initial_entry_price: 0.6123,
        is_closed_position: true,
        miner_hotkey: "5G3ys2356ovgUivX3endMP7f37LPEjRkzDAM3Km8CxQnErCw",
        net_leverage: 0.0,
        open_ms: 1717092919597,
        orders: [
          {
            leverage: 0.296623,
            order_type: "LONG",
            order_uuid: "a443ac15-c295-4342-b5f0-c382298692e9",
            price: 0.6123,
            price_sources: [],
            processed_ms: 1717092919582,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "4291ec46-6c64-4053-840a-f6a94b401f5b",
            price: 0.61304,
            price_sources: [],
            processed_ms: 1717135556942,
          },
        ],
        position_type: "FLAT",
        position_uuid: "dc6d4075-90f4-40ce-93c6-3a35241ffb8f",
        return_at_close: 1.0003377150154555,
        trade_pair: ["NZDUSD", "NZD/USD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.6119,
        close_ms: 1717144367312,
        current_return: 1.000339252328812,
        initial_entry_price: 0.6119,
        is_closed_position: true,
        miner_hotkey: "5G3ys2356ovgUivX3endMP7f37LPEjRkzDAM3Km8CxQnErCw",
        net_leverage: 0.0,
        open_ms: 1717141819743,
        orders: [
          {
            leverage: 0.296555,
            order_type: "LONG",
            order_uuid: "e599c0b7-ba83-45a0-935f-e7aa9375b972",
            price: 0.6119,
            price_sources: [],
            processed_ms: 1717141819555,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "ea7c48f4-283d-4d9f-b3c4-ffe2a00623ca",
            price: 0.6126,
            price_sources: [],
            processed_ms: 1717144367312,
          },
        ],
        position_type: "FLAT",
        position_uuid: "ee83cf44-ed31-4fb1-bcf6-4d247712a5b6",
        return_at_close: 1.0003184864363237,
        trade_pair: ["NZDUSD", "NZD/USD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.61422,
        close_ms: 1717160152276,
        current_return: 1.000552708288561,
        initial_entry_price: 0.61422,
        is_closed_position: true,
        miner_hotkey: "5G3ys2356ovgUivX3endMP7f37LPEjRkzDAM3Km8CxQnErCw",
        net_leverage: 0.0,
        open_ms: 1717158959667,
        orders: [
          {
            leverage: 0.296493,
            order_type: "LONG",
            order_uuid: "b83001a5-a992-4cb5-b44c-ad58cac08e35",
            price: 0.61422,
            price_sources: [],
            processed_ms: 1717158959651,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "0bdbe331-20a1-42c7-9b90-cf39a720abf9",
            price: 0.6153649999999999,
            price_sources: [],
            processed_ms: 1717160152276,
          },
        ],
        position_type: "FLAT",
        position_uuid: "77166011-c290-4148-a14a-7ab6b6a2b752",
        return_at_close: 1.0005319423073713,
        trade_pair: ["NZDUSD", "NZD/USD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.6139,
        close_ms: 1717185015582,
        current_return: 1.000338001140251,
        initial_entry_price: 0.6139,
        is_closed_position: true,
        miner_hotkey: "5G3ys2356ovgUivX3endMP7f37LPEjRkzDAM3Km8CxQnErCw",
        net_leverage: 0.0,
        open_ms: 1717170621895,
        orders: [
          {
            leverage: 0.296427,
            order_type: "LONG",
            order_uuid: "03e9e655-a85f-40f5-85bd-bc7aba465275",
            price: 0.6139,
            price_sources: [],
            processed_ms: 1717170621650,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "3660165c-ce46-40d1-a5ae-6d1a0796ee8e",
            price: 0.6146,
            price_sources: [],
            processed_ms: 1717185015582,
          },
        ],
        position_type: "FLAT",
        position_uuid: "8dee5fde-ee5a-4812-a78e-3a5a5ce35058",
        return_at_close: 1.0003172442367645,
        trade_pair: ["NZDUSD", "NZD/USD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.61362,
        close_ms: 1717374250121,
        current_return: 1.0006737498533294,
        initial_entry_price: 0.61362,
        is_closed_position: true,
        miner_hotkey: "5G3ys2356ovgUivX3endMP7f37LPEjRkzDAM3Km8CxQnErCw",
        net_leverage: 0.0,
        open_ms: 1717363411357,
        orders: [
          {
            leverage: 0.296363,
            order_type: "LONG",
            order_uuid: "b985dd34-0a7d-4807-87c1-a177e9371f4c",
            price: 0.61362,
            price_sources: [],
            processed_ms: 1717363411073,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "0c587a45-bd5d-4422-a51c-04e57b6be0e2",
            price: 0.615015,
            price_sources: [],
            processed_ms: 1717374250121,
          },
        ],
        position_type: "FLAT",
        position_uuid: "b985dd34-0a7d-4807-87c1-a177e9371f4c",
        return_at_close: 1.0006529904661123,
        trade_pair: ["NZDUSD", "NZD/USD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.61474,
        close_ms: 1717413500080,
        current_return: 1.0004072780687772,
        initial_entry_price: 0.61474,
        is_closed_position: true,
        miner_hotkey: "5G3ys2356ovgUivX3endMP7f37LPEjRkzDAM3Km8CxQnErCw",
        net_leverage: 0.0,
        open_ms: 1717386016504,
        orders: [
          {
            leverage: 0.296296,
            order_type: "LONG",
            order_uuid: "5fd22867-ad1d-497e-8509-ba99ab78845b",
            price: 0.61474,
            price_sources: [],
            processed_ms: 1717386015445,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "7644fd14-0b91-4063-b8e4-40ce5beca62f",
            price: 0.615585,
            price_sources: [],
            processed_ms: 1717413500080,
          },
        ],
        position_type: "FLAT",
        position_uuid: "5fd22867-ad1d-497e-8509-ba99ab78845b",
        return_at_close: 1.0003865289015368,
        trade_pair: ["NZDUSD", "NZD/USD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 1.27774,
        close_ms: 1717426386518,
        current_return: 1.0001928900715327,
        initial_entry_price: 1.27774,
        is_closed_position: true,
        miner_hotkey: "5G3ys2356ovgUivX3endMP7f37LPEjRkzDAM3Km8CxQnErCw",
        net_leverage: 0.0,
        open_ms: 1717423824409,
        orders: [
          {
            leverage: -0.385099,
            order_type: "SHORT",
            order_uuid: "ead9b5e0-70d8-4b16-808e-aa74fa2af668",
            price: 1.27774,
            price_sources: [],
            processed_ms: 1717423824393,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "d7bd579d-8cec-4607-9e65-77746ab33296",
            price: 1.2771,
            price_sources: [],
            processed_ms: 1717426386518,
          },
        ],
        position_type: "FLAT",
        position_uuid: "ead9b5e0-70d8-4b16-808e-aa74fa2af668",
        return_at_close: 1.0001659279418085,
        trade_pair: ["GBPUSD", "GBP/USD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.6180082511958013,
        close_ms: 1717491932879,
        current_return: 1.0006782254893998,
        initial_entry_price: 0.61555,
        is_closed_position: true,
        miner_hotkey: "5G3ys2356ovgUivX3endMP7f37LPEjRkzDAM3Km8CxQnErCw",
        net_leverage: 0.0,
        open_ms: 1717415411540,
        orders: [
          {
            leverage: -0.29623,
            order_type: "SHORT",
            order_uuid: "32c2fb9b-4dc4-4301-8212-dad59dabea5b",
            price: 0.61555,
            price_sources: [],
            processed_ms: 1717415410063,
          },
          {
            leverage: -0.296144,
            order_type: "SHORT",
            order_uuid: "017b7f0d-fef1-4cca-b9ef-5560b1287add",
            price: 0.6191,
            price_sources: [],
            processed_ms: 1717452232840,
          },
          {
            leverage: 0.29592,
            order_type: "LONG",
            order_uuid: "36d1ccde-4550-45a1-9c7a-b7998ecb4543",
            price: 0.61664,
            price_sources: [],
            processed_ms: 1717491897456,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "e88ec30e-521c-47ae-bbd8-cdb0815a6a8b",
            price: 0.6166,
            price_sources: [],
            processed_ms: 1717491932879,
          },
        ],
        position_type: "FLAT",
        position_uuid: "32c2fb9b-4dc4-4301-8212-dad59dabea5b",
        return_at_close: 1.0006367311859796,
        trade_pair: ["NZDUSD", "NZD/USD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.6175,
        close_ms: 1717548314977,
        current_return: 1.000412291659919,
        initial_entry_price: 0.6175,
        is_closed_position: true,
        miner_hotkey: "5G3ys2356ovgUivX3endMP7f37LPEjRkzDAM3Km8CxQnErCw",
        net_leverage: 0.0,
        open_ms: 1717536389503,
        orders: [
          {
            leverage: 0.296035,
            order_type: "LONG",
            order_uuid: "dfd82059-541a-4193-8277-3d1a0582f962",
            price: 0.6175,
            price_sources: [],
            processed_ms: 1717536389164,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "bfbc04a0-0f17-42c9-8a04-5a200be8a3d7",
            price: 0.61836,
            price_sources: [],
            processed_ms: 1717548314977,
          },
        ],
        position_type: "FLAT",
        position_uuid: "dfd82059-541a-4193-8277-3d1a0582f962",
        return_at_close: 1.0003915606662257,
        trade_pair: ["NZDUSD", "NZD/USD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.6181300000000001,
        close_ms: 1717571151655,
        current_return: 1.0003878389497354,
        initial_entry_price: 0.6181300000000001,
        is_closed_position: true,
        miner_hotkey: "5G3ys2356ovgUivX3endMP7f37LPEjRkzDAM3Km8CxQnErCw",
        net_leverage: 0.0,
        open_ms: 1717565719112,
        orders: [
          {
            leverage: 0.295969,
            order_type: "LONG",
            order_uuid: "04d3c737-185d-4eeb-a6ae-2bb761fd1e07",
            price: 0.6181300000000001,
            price_sources: [],
            processed_ms: 1717565716972,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "c7a6ec9d-fd52-42d1-875c-2f6833047c56",
            price: 0.61894,
            price_sources: [],
            processed_ms: 1717571151655,
          },
        ],
        position_type: "FLAT",
        position_uuid: "04d3c737-185d-4eeb-a6ae-2bb761fd1e07",
        return_at_close: 1.000367113084554,
        trade_pair: ["NZDUSD", "NZD/USD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.61854,
        close_ms: 1717594293026,
        current_return: 1.0003635796553174,
        initial_entry_price: 0.61854,
        is_closed_position: true,
        miner_hotkey: "5G3ys2356ovgUivX3endMP7f37LPEjRkzDAM3Km8CxQnErCw",
        net_leverage: 0.0,
        open_ms: 1717579525072,
        orders: [
          {
            leverage: 0.295906,
            order_type: "LONG",
            order_uuid: "693339e8-c0b7-4200-ad61-7aa243e01b07",
            price: 0.61854,
            price_sources: [],
            processed_ms: 1717579521556,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "251cc563-66b3-489b-bcc9-1935e8ebe452",
            price: 0.6193,
            price_sources: [],
            processed_ms: 1717594293026,
          },
        ],
        position_type: "FLAT",
        position_uuid: "693339e8-c0b7-4200-ad61-7aa243e01b07",
        return_at_close: 1.0003428587043393,
        trade_pair: ["NZDUSD", "NZD/USD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.61865,
        close_ms: 1717596006712,
        current_return: 1.0006575329346157,
        initial_entry_price: 0.61865,
        is_closed_position: true,
        miner_hotkey: "5G3ys2356ovgUivX3endMP7f37LPEjRkzDAM3Km8CxQnErCw",
        net_leverage: 0.0,
        open_ms: 1717595440799,
        orders: [
          {
            leverage: -0.295842,
            order_type: "SHORT",
            order_uuid: "fd007424-78f0-48b1-8c04-fd3c1b2df6f4",
            price: 0.61865,
            price_sources: [],
            processed_ms: 1717595440784,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "f263fced-4db2-4856-95cd-00e73b511aed",
            price: 0.617275,
            price_sources: [],
            processed_ms: 1717596006712,
          },
        ],
        position_type: "FLAT",
        position_uuid: "fd007424-78f0-48b1-8c04-fd3c1b2df6f4",
        return_at_close: 1.0006368103778056,
        trade_pair: ["NZDUSD", "NZD/USD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.6186,
        close_ms: 1717661260825,
        current_return: 1.0002916640155188,
        initial_entry_price: 0.6186,
        is_closed_position: true,
        miner_hotkey: "5G3ys2356ovgUivX3endMP7f37LPEjRkzDAM3Km8CxQnErCw",
        net_leverage: 0.0,
        open_ms: 1717611019421,
        orders: [
          {
            leverage: -0.295776,
            order_type: "SHORT",
            order_uuid: "8c809e29-c9bf-49d5-850c-6047c65af14b",
            price: 0.6186,
            price_sources: [],
            processed_ms: 1717611019406,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "2836c0fc-e8e9-4c67-bf17-d991e335aed2",
            price: 0.61799,
            price_sources: [],
            processed_ms: 1717661260825,
          },
        ],
        position_type: "FLAT",
        position_uuid: "8c809e29-c9bf-49d5-850c-6047c65af14b",
        return_at_close: 1.0002709536568137,
        trade_pair: ["NZDUSD", "NZD/USD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.61909,
        close_ms: 1717683491917,
        current_return: 1.0003773660372484,
        initial_entry_price: 0.61909,
        is_closed_position: true,
        miner_hotkey: "5G3ys2356ovgUivX3endMP7f37LPEjRkzDAM3Km8CxQnErCw",
        net_leverage: 0.0,
        open_ms: 1717681818773,
        orders: [
          {
            leverage: -0.295726,
            order_type: "SHORT",
            order_uuid: "c6477897-2c7e-441e-b80b-86bfdeb319bb",
            price: 0.61909,
            price_sources: [],
            processed_ms: 1717681818551,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "4c87d9d3-5bcc-4348-bd6a-9630311753c6",
            price: 0.6183,
            price_sources: [],
            processed_ms: 1717683491917,
          },
        ],
        position_type: "FLAT",
        position_uuid: "c6477897-2c7e-441e-b80b-86bfdeb319bb",
        return_at_close: 1.0003566574054619,
        trade_pair: ["NZDUSD", "NZD/USD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.61947,
        close_ms: 1717757681299,
        current_return: 1.0004128477004537,
        initial_entry_price: 0.61947,
        is_closed_position: true,
        miner_hotkey: "5G3ys2356ovgUivX3endMP7f37LPEjRkzDAM3Km8CxQnErCw",
        net_leverage: 0.0,
        open_ms: 1717688112700,
        orders: [
          {
            leverage: -0.295661,
            order_type: "SHORT",
            order_uuid: "b97b2442-44bd-4048-b965-03550968355b",
            price: 0.61947,
            price_sources: [],
            processed_ms: 1717688112493,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "ed2913ba-ae61-4c66-88e9-6558fab851ae",
            price: 0.618605,
            price_sources: [],
            processed_ms: 1717757681299,
          },
        ],
        position_type: "FLAT",
        position_uuid: "b97b2442-44bd-4048-b965-03550968355b",
        return_at_close: 1.0003921428860463,
        trade_pair: ["NZDUSD", "NZD/USD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 1.2798,
        close_ms: 1717761416676,
        current_return: 1.0001941333333333,
        initial_entry_price: 1.2798,
        is_closed_position: true,
        miner_hotkey: "5G3ys2356ovgUivX3endMP7f37LPEjRkzDAM3Km8CxQnErCw",
        net_leverage: 0.0,
        open_ms: 1717646465229,
        orders: [
          {
            leverage: 0.295776,
            order_type: "LONG",
            order_uuid: "21454b4b-9036-4399-bce1-d2e8bedb6fda",
            price: 1.2798,
            price_sources: [],
            processed_ms: 1717646464826,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "cf63641a-32d6-4e3e-9422-86f4b7d0b824",
            price: 1.28064,
            price_sources: [],
            processed_ms: 1717761416676,
          },
        ],
        position_type: "FLAT",
        position_uuid: "21454b4b-9036-4399-bce1-d2e8bedb6fda",
        return_at_close: 1.0001734249939347,
        trade_pair: ["GBPUSD", "GBP/USD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.61932,
        close_ms: 1717763414767,
        current_return: 1.001631982561519,
        initial_entry_price: 0.61932,
        is_closed_position: true,
        miner_hotkey: "5G3ys2356ovgUivX3endMP7f37LPEjRkzDAM3Km8CxQnErCw",
        net_leverage: 0.0,
        open_ms: 1717761625592,
        orders: [
          {
            leverage: -0.295532,
            order_type: "SHORT",
            order_uuid: "4a81eec7-8b46-47ec-892c-8757d91b09fe",
            price: 0.61932,
            price_sources: [],
            processed_ms: 1717761625570,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "f0e532c1-708e-473f-bd4a-7c443fd9e230",
            price: 0.6159,
            price_sources: [],
            processed_ms: 1717763414767,
          },
        ],
        position_type: "FLAT",
        position_uuid: "4a81eec7-8b46-47ec-892c-8757d91b09fe",
        return_at_close: 1.0016112615603041,
        trade_pair: ["NZDUSD", "NZD/USD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.61078,
        close_ms: 1718030326680,
        current_return: 1.000353140099545,
        initial_entry_price: 0.61078,
        is_closed_position: true,
        miner_hotkey: "5G3ys2356ovgUivX3endMP7f37LPEjRkzDAM3Km8CxQnErCw",
        net_leverage: 0.0,
        open_ms: 1718027155083,
        orders: [
          {
            leverage: 0.295467,
            order_type: "LONG",
            order_uuid: "d23021cb-5723-4aeb-83eb-3c8f772637e1",
            price: 0.61078,
            price_sources: [],
            processed_ms: 1718027154601,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "9bccb4d7-25aa-4e57-a5ea-ca78d9184295",
            price: 0.61151,
            price_sources: [],
            processed_ms: 1718030326680,
          },
        ],
        position_type: "FLAT",
        position_uuid: "d23021cb-5723-4aeb-83eb-3c8f772637e1",
        return_at_close: 1.0003324501056576,
        trade_pair: ["NZDUSD", "NZD/USD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 1.27139,
        close_ms: 1718030771702,
        current_return: 1.0001533819048443,
        initial_entry_price: 1.27139,
        is_closed_position: true,
        miner_hotkey: "5G3ys2356ovgUivX3endMP7f37LPEjRkzDAM3Km8CxQnErCw",
        net_leverage: 0.0,
        open_ms: 1718027154541,
        orders: [
          {
            leverage: 0.295467,
            order_type: "LONG",
            order_uuid: "9f423c56-03c0-4aca-9b52-c13a9efe4c2a",
            price: 1.27139,
            price_sources: [],
            processed_ms: 1718027154432,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "4338aa60-91c3-429d-a9d8-49eab5203023",
            price: 1.27205,
            price_sources: [],
            processed_ms: 1718030771702,
          },
        ],
        position_type: "FLAT",
        position_uuid: "9f423c56-03c0-4aca-9b52-c13a9efe4c2a",
        return_at_close: 1.0001326960424939,
        trade_pair: ["GBPUSD", "GBP/USD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.612115,
        close_ms: 1718088463715,
        current_return: 1.0004173448534996,
        initial_entry_price: 0.612115,
        is_closed_position: true,
        miner_hotkey: "5G3ys2356ovgUivX3endMP7f37LPEjRkzDAM3Km8CxQnErCw",
        net_leverage: 0.0,
        open_ms: 1718070012158,
        orders: [
          {
            leverage: 0.295333,
            order_type: "LONG",
            order_uuid: "0d93f1dd-d349-43f2-9fc2-20aca5c5cfd1",
            price: 0.612115,
            price_sources: [],
            processed_ms: 1718070011874,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "0e40a00c-3b90-490f-9740-8ff270b7e26f",
            price: 0.61298,
            price_sources: [],
            processed_ms: 1718088463715,
          },
        ],
        position_type: "FLAT",
        position_uuid: "0d93f1dd-d349-43f2-9fc2-20aca5c5cfd1",
        return_at_close: 1.0003966629156,
        trade_pair: ["NZDUSD", "NZD/USD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.612815,
        close_ms: 1718095843592,
        current_return: 1.0003348665747411,
        initial_entry_price: 0.612815,
        is_closed_position: true,
        miner_hotkey: "5G3ys2356ovgUivX3endMP7f37LPEjRkzDAM3Km8CxQnErCw",
        net_leverage: 0.0,
        open_ms: 1718089209234,
        orders: [
          {
            leverage: -0.295268,
            order_type: "SHORT",
            order_uuid: "0ba421c5-a050-409e-82f7-02a69ad734b3",
            price: 0.612815,
            price_sources: [],
            processed_ms: 1718089209111,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "da2ca13d-f448-4e1a-9d09-836f24056156",
            price: 0.61212,
            price_sources: [],
            processed_ms: 1718095843592,
          },
        ],
        position_type: "FLAT",
        position_uuid: "0ba421c5-a050-409e-82f7-02a69ad734b3",
        return_at_close: 1.0003141908934643,
        trade_pair: ["NZDUSD", "NZD/USD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 1.27326,
        close_ms: 1718100446449,
        current_return: 1.0002389087774688,
        initial_entry_price: 1.27326,
        is_closed_position: true,
        miner_hotkey: "5G3ys2356ovgUivX3endMP7f37LPEjRkzDAM3Km8CxQnErCw",
        net_leverage: 0.0,
        open_ms: 1718056992461,
        orders: [
          {
            leverage: 0.295333,
            order_type: "LONG",
            order_uuid: "25cc8c22-0349-4841-a25d-9cab1e901772",
            price: 1.27326,
            price_sources: [],
            processed_ms: 1718056992353,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "b8c4c43b-093f-4260-86fa-2f488d392586",
            price: 1.27429,
            price_sources: [],
            processed_ms: 1718100446449,
          },
        ],
        position_type: "FLAT",
        position_uuid: "25cc8c22-0349-4841-a25d-9cab1e901772",
        return_at_close: 1.0002182305284335,
        trade_pair: ["GBPUSD", "GBP/USD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.61206,
        close_ms: 1718107867431,
        current_return: 1.0003664753128778,
        initial_entry_price: 0.61206,
        is_closed_position: true,
        miner_hotkey: "5G3ys2356ovgUivX3endMP7f37LPEjRkzDAM3Km8CxQnErCw",
        net_leverage: 0.0,
        open_ms: 1718105108298,
        orders: [
          {
            leverage: 0.295138,
            order_type: "LONG",
            order_uuid: "78defb29-d7b6-4946-b02a-d3b6ed30e5a1",
            price: 0.61206,
            price_sources: [],
            processed_ms: 1718105108154,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "28017baa-4c8b-4818-be9d-780915ef777f",
            price: 0.61282,
            price_sources: [],
            processed_ms: 1718107867431,
          },
        ],
        position_type: "FLAT",
        position_uuid: "78defb29-d7b6-4946-b02a-d3b6ed30e5a1",
        return_at_close: 1.0003458080816225,
        trade_pair: ["NZDUSD", "NZD/USD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.61208,
        close_ms: 1718116932870,
        current_return: 1.0003153645928637,
        initial_entry_price: 0.61208,
        is_closed_position: true,
        miner_hotkey: "5G3ys2356ovgUivX3endMP7f37LPEjRkzDAM3Km8CxQnErCw",
        net_leverage: 0.0,
        open_ms: 1718114408946,
        orders: [
          {
            leverage: 0.245896,
            order_type: "LONG",
            order_uuid: "bd77efd3-099b-45ae-b847-ff389af66bf3",
            price: 0.61208,
            price_sources: [],
            processed_ms: 1718114408862,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "1fc3f91b-c7a5-4acb-910a-8dd27457244a",
            price: 0.612865,
            price_sources: [],
            processed_ms: 1718116932870,
          },
        ],
        position_type: "FLAT",
        position_uuid: "bd77efd3-099b-45ae-b847-ff389af66bf3",
        return_at_close: 1.0002981464445813,
        trade_pair: ["NZDUSD", "NZD/USD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 1.27202,
        close_ms: 1718120181265,
        current_return: 1.0000628262134243,
        initial_entry_price: 1.27202,
        is_closed_position: true,
        miner_hotkey: "5G3ys2356ovgUivX3endMP7f37LPEjRkzDAM3Km8CxQnErCw",
        net_leverage: 0.0,
        open_ms: 1718115011999,
        orders: [
          {
            leverage: 0.245896,
            order_type: "LONG",
            order_uuid: "908fbbda-fa8f-459f-bf3f-67603d6620d3",
            price: 1.27202,
            price_sources: [],
            processed_ms: 1718115011873,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "84c96237-8e64-4fac-aa27-820df996351b",
            price: 1.272345,
            price_sources: [],
            processed_ms: 1718120181265,
          },
        ],
        position_type: "FLAT",
        position_uuid: "908fbbda-fa8f-459f-bf3f-67603d6620d3",
        return_at_close: 1.0000456124120143,
        trade_pair: ["GBPUSD", "GBP/USD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 1.27378,
        close_ms: 1718171480754,
        current_return: 1.0001543770509822,
        initial_entry_price: 1.27378,
        is_closed_position: true,
        miner_hotkey: "5G3ys2356ovgUivX3endMP7f37LPEjRkzDAM3Km8CxQnErCw",
        net_leverage: 0.0,
        open_ms: 1718138732745,
        orders: [
          {
            leverage: 0.245803,
            order_type: "LONG",
            order_uuid: "33661487-e9bd-483a-874b-afd9e68d42af",
            price: 1.27378,
            price_sources: [],
            processed_ms: 1718138732504,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "22785ab9-1979-4871-ae42-2d5a7363f634",
            price: 1.27458,
            price_sources: [],
            processed_ms: 1718171480754,
          },
        ],
        position_type: "FLAT",
        position_uuid: "33661487-e9bd-483a-874b-afd9e68d42af",
        return_at_close: 1.0001371681847382,
        trade_pair: ["GBPUSD", "GBP/USD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 1.27419,
        close_ms: 1718178922151,
        current_return: 1.0001620140795329,
        initial_entry_price: 1.27419,
        is_closed_position: true,
        miner_hotkey: "5G3ys2356ovgUivX3endMP7f37LPEjRkzDAM3Km8CxQnErCw",
        net_leverage: 0.0,
        open_ms: 1718176234726,
        orders: [
          {
            leverage: 0.245758,
            order_type: "LONG",
            order_uuid: "6b59d584-4f1f-4215-a506-6e77cf1261b0",
            price: 1.27419,
            price_sources: [],
            processed_ms: 1718176234412,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "11810386-632a-436c-b344-fbd206adff5c",
            price: 1.27503,
            price_sources: [],
            processed_ms: 1718178922151,
          },
        ],
        position_type: "FLAT",
        position_uuid: "6b59d584-4f1f-4215-a506-6e77cf1261b0",
        return_at_close: 1.000144808232395,
        trade_pair: ["GBPUSD", "GBP/USD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.613935,
        close_ms: 1718195409928,
        current_return: 1.0016835684803767,
        initial_entry_price: 0.613935,
        is_closed_position: true,
        miner_hotkey: "5G3ys2356ovgUivX3endMP7f37LPEjRkzDAM3Km8CxQnErCw",
        net_leverage: 0.0,
        open_ms: 1718140707566,
        orders: [
          {
            leverage: 0.245803,
            order_type: "LONG",
            order_uuid: "ab1afe9d-4755-47e7-94d2-731a2f4c0034",
            price: 0.613935,
            price_sources: [],
            processed_ms: 1718140707318,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "6c49348a-d446-4687-895a-105dde717096",
            price: 0.61814,
            price_sources: [],
            processed_ms: 1718195409928,
          },
        ],
        position_type: "FLAT",
        position_uuid: "ab1afe9d-4755-47e7-94d2-731a2f4c0034",
        return_at_close: 1.0016663333025437,
        trade_pair: ["NZDUSD", "NZD/USD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.6133938336395661,
        close_ms: 1718720935012,
        current_return: 1.0015264659540903,
        initial_entry_price: 0.62122,
        is_closed_position: true,
        miner_hotkey: "5G3ys2356ovgUivX3endMP7f37LPEjRkzDAM3Km8CxQnErCw",
        net_leverage: 0.0,
        open_ms: 1718214610389,
        orders: [
          {
            leverage: 0.245718,
            order_type: "LONG",
            order_uuid: "7e469928-457b-4765-a0ad-4d80e4ad4a6a",
            price: 0.62122,
            price_sources: [],
            processed_ms: 1718214609622,
          },
          {
            leverage: 0.275204,
            order_type: "LONG",
            order_uuid: "598ac959-0b8f-471b-a098-b006ebc5f9e6",
            price: 0.6172,
            price_sources: [],
            processed_ms: 1718243410058,
          },
          {
            leverage: 0.294861,
            order_type: "LONG",
            order_uuid: "fb36ba18-98db-4233-8cfd-f09a3e80c672",
            price: 0.61527,
            price_sources: [],
            processed_ms: 1718324111581,
          },
          {
            leverage: 0.324347,
            order_type: "LONG",
            order_uuid: "85261f93-6433-429a-b4e0-a7351868da00",
            price: 0.61371,
            price_sources: [],
            processed_ms: 1718345708566,
          },
          {
            leverage: 0.373491,
            order_type: "LONG",
            order_uuid: "3c3d9c4b-fe86-4c34-b948-cc12b0c004a9",
            price: 0.61252,
            price_sources: [],
            processed_ms: 1718357712749,
          },
          {
            leverage: 0.393148,
            order_type: "LONG",
            order_uuid: "7d5899df-31c5-4cef-8997-78f2c8cdc09b",
            price: 0.61167,
            price_sources: [],
            processed_ms: 1718373012691,
          },
          {
            leverage: 0.442292,
            order_type: "LONG",
            order_uuid: "c593e4aa-0fb1-4d75-bcd2-5a084d7b3b60",
            price: 0.61076,
            price_sources: [],
            processed_ms: 1718606415898,
          },
          {
            leverage: 0.471778,
            order_type: "LONG",
            order_uuid: "7379bf79-f2a2-4715-bf11-8b9f32e1010a",
            price: 0.610305,
            price_sources: [],
            processed_ms: 1718697016650,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "2754fa37-5c76-4dd4-98ed-69be60df30b2",
            price: 0.61373,
            price_sources: [],
            processed_ms: 1718720935012,
          },
        ],
        position_type: "FLAT",
        position_uuid: "7e469928-457b-4765-a0ad-4d80e4ad4a6a",
        return_at_close: 1.0013287058100617,
        trade_pair: ["NZDUSD", "NZD/USD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 1.27062,
        close_ms: 1718776816105,
        current_return: 1.000145503037887,
        initial_entry_price: 1.27062,
        is_closed_position: true,
        miner_hotkey: "5G3ys2356ovgUivX3endMP7f37LPEjRkzDAM3Km8CxQnErCw",
        net_leverage: 0.0,
        open_ms: 1718745569825,
        orders: [
          {
            leverage: 0.147314,
            order_type: "LONG",
            order_uuid: "76c02906-aaee-47a7-b419-06521596ff17",
            price: 1.27062,
            price_sources: [],
            processed_ms: 1718745539346,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "32881bad-cdd7-4020-bdb7-2913c64ed820",
            price: 1.271875,
            price_sources: [],
            processed_ms: 1718776816105,
          },
        ],
        position_type: "FLAT",
        position_uuid: "76c02906-aaee-47a7-b419-06521596ff17",
        return_at_close: 1.0001351895574626,
        trade_pair: ["GBPUSD", "GBP/USD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 1.2715,
        close_ms: 1718860936844,
        current_return: 1.0000764582618955,
        initial_entry_price: 1.2715,
        is_closed_position: true,
        miner_hotkey: "5G3ys2356ovgUivX3endMP7f37LPEjRkzDAM3Km8CxQnErCw",
        net_leverage: 0.0,
        open_ms: 1718778315129,
        orders: [
          {
            leverage: -0.147298,
            order_type: "SHORT",
            order_uuid: "647a6a3f-943e-4df4-8064-06d18ac4090e",
            price: 1.2715,
            price_sources: [],
            processed_ms: 1718778313265,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "3fdfb1b5-de4f-4df4-bc9b-6c473e2d522a",
            price: 1.27084,
            price_sources: [],
            processed_ms: 1718860936844,
          },
        ],
        position_type: "FLAT",
        position_uuid: "647a6a3f-943e-4df4-8064-06d18ac4090e",
        return_at_close: 1.000066146613545,
        trade_pair: ["GBPUSD", "GBP/USD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 1.26783,
        close_ms: 1718910003452,
        current_return: 1.0000888689571945,
        initial_entry_price: 1.26783,
        is_closed_position: true,
        miner_hotkey: "5G3ys2356ovgUivX3endMP7f37LPEjRkzDAM3Km8CxQnErCw",
        net_leverage: 0.0,
        open_ms: 1718908815123,
        orders: [
          {
            leverage: -0.147282,
            order_type: "SHORT",
            order_uuid: "86e9ff83-007b-4d68-be0e-0200c94b9178",
            price: 1.26783,
            price_sources: [],
            processed_ms: 1718908815020,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "59c289a5-d7e0-451a-82bc-784738c0cd32",
            price: 1.267065,
            price_sources: [],
            processed_ms: 1718910003452,
          },
        ],
        position_type: "FLAT",
        position_uuid: "86e9ff83-007b-4d68-be0e-0200c94b9178",
        return_at_close: 1.0000785583009786,
        trade_pair: ["GBPUSD", "GBP/USD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 1.26623,
        close_ms: 1718949607729,
        current_return: 1.0000616400259037,
        initial_entry_price: 1.26623,
        is_closed_position: true,
        miner_hotkey: "5G3ys2356ovgUivX3endMP7f37LPEjRkzDAM3Km8CxQnErCw",
        net_leverage: 0.0,
        open_ms: 1718912119064,
        orders: [
          {
            leverage: 0.147265,
            order_type: "LONG",
            order_uuid: "40b62816-6532-49f7-a14e-df3c9cf03b21",
            price: 1.26623,
            price_sources: [],
            processed_ms: 1718912118827,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "07e8a69f-0f1c-46b1-8a24-66b9952ad221",
            price: 1.26676,
            price_sources: [],
            processed_ms: 1718949607729,
          },
        ],
        position_type: "FLAT",
        position_uuid: "40b62816-6532-49f7-a14e-df3c9cf03b21",
        return_at_close: 1.0000513308404844,
        trade_pair: ["GBPUSD", "GBP/USD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 1.26448,
        close_ms: 1718957472199,
        current_return: 1.0000692874224977,
        initial_entry_price: 1.26448,
        is_closed_position: true,
        miner_hotkey: "5G3ys2356ovgUivX3endMP7f37LPEjRkzDAM3Km8CxQnErCw",
        net_leverage: 0.0,
        open_ms: 1718955015173,
        orders: [
          {
            leverage: 0.147248,
            order_type: "LONG",
            order_uuid: "3a3318fb-ff9f-479b-a92c-edbe031da3e3",
            price: 1.26448,
            price_sources: [],
            processed_ms: 1718955014660,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "61ad4f73-0614-4399-98e0-4f4e4e4258fc",
            price: 1.265075,
            price_sources: [],
            processed_ms: 1718957472199,
          },
        ],
        position_type: "FLAT",
        position_uuid: "3a3318fb-ff9f-479b-a92c-edbe031da3e3",
        return_at_close: 1.0000589793483272,
        trade_pair: ["GBPUSD", "GBP/USD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 1.26523,
        close_ms: 1718964796511,
        current_return: 1.0000919305422729,
        initial_entry_price: 1.26523,
        is_closed_position: true,
        miner_hotkey: "5G3ys2356ovgUivX3endMP7f37LPEjRkzDAM3Km8CxQnErCw",
        net_leverage: 0.0,
        open_ms: 1718960112503,
        orders: [
          {
            leverage: -0.147232,
            order_type: "SHORT",
            order_uuid: "e2a0ad3b-8fb9-42cd-9f3b-e76e029af4e6",
            price: 1.26523,
            price_sources: [],
            processed_ms: 1718960112276,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "8528bdea-2d50-4a64-b130-0a117cf871ea",
            price: 1.26444,
            price_sources: [],
            processed_ms: 1718964796511,
          },
        ],
        position_type: "FLAT",
        position_uuid: "e2a0ad3b-8fb9-42cd-9f3b-e76e029af4e6",
        return_at_close: 1.0000816233548147,
        trade_pair: ["GBPUSD", "GBP/USD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 1.26428,
        close_ms: 1718990490909,
        current_return: 1.0000803453665328,
        initial_entry_price: 1.26428,
        is_closed_position: true,
        miner_hotkey: "5G3ys2356ovgUivX3endMP7f37LPEjRkzDAM3Km8CxQnErCw",
        net_leverage: 0.0,
        open_ms: 1718988912748,
        orders: [
          {
            leverage: -0.147216,
            order_type: "SHORT",
            order_uuid: "22195087-cf01-4bd9-9b12-d45b3284b98f",
            price: 1.26428,
            price_sources: [],
            processed_ms: 1718988912213,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "245b9380-5adb-407c-b62d-82af8cc7a2d9",
            price: 1.26359,
            price_sources: [],
            processed_ms: 1718990490909,
          },
        ],
        position_type: "FLAT",
        position_uuid: "22195087-cf01-4bd9-9b12-d45b3284b98f",
        return_at_close: 1.0000700394185642,
        trade_pair: ["GBPUSD", "GBP/USD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 1.264285,
        close_ms: 1719408079102,
        current_return: 1.0000902322063459,
        initial_entry_price: 1.264285,
        is_closed_position: true,
        miner_hotkey: "5G3ys2356ovgUivX3endMP7f37LPEjRkzDAM3Km8CxQnErCw",
        net_leverage: 0.0,
        open_ms: 1719200715181,
        orders: [
          {
            leverage: -0.147199,
            order_type: "SHORT",
            order_uuid: "eb85c4b2-cf9c-4dba-9290-f5565835f1a3",
            price: 1.264285,
            price_sources: [],
            processed_ms: 1719200714773,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "37930044-eb87-4418-921f-69e7b1514304",
            price: 1.26351,
            price_sources: [],
            processed_ms: 1719408079102,
          },
        ],
        position_type: "FLAT",
        position_uuid: "eb85c4b2-cf9c-4dba-9290-f5565835f1a3",
        return_at_close: 1.0000799273465995,
        trade_pair: ["GBPUSD", "GBP/USD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 1.26351,
        close_ms: 1719425477703,
        current_return: 1.0000733875632168,
        initial_entry_price: 1.26351,
        is_closed_position: true,
        miner_hotkey: "5G3ys2356ovgUivX3endMP7f37LPEjRkzDAM3Km8CxQnErCw",
        net_leverage: 0.0,
        open_ms: 1719424211185,
        orders: [
          {
            leverage: -0.147184,
            order_type: "SHORT",
            order_uuid: "98a05e03-21c0-48f4-ad54-d88a409a4873",
            price: 1.26351,
            price_sources: [],
            processed_ms: 1719424210202,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "c3112b95-d03a-495d-832f-1af72abfe916",
            price: 1.26288,
            price_sources: [],
            processed_ms: 1719425477703,
          },
        ],
        position_type: "FLAT",
        position_uuid: "98a05e03-21c0-48f4-ad54-d88a409a4873",
        return_at_close: 1.0000630839271136,
        trade_pair: ["GBPUSD", "GBP/USD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.6098617215743185,
        close_ms: 1719492188386,
        current_return: 1.001016268884365,
        initial_entry_price: 0.614,
        is_closed_position: true,
        miner_hotkey: "5G3ys2356ovgUivX3endMP7f37LPEjRkzDAM3Km8CxQnErCw",
        net_leverage: 0.0,
        open_ms: 1718745499315,
        orders: [
          {
            leverage: 0.147314,
            order_type: "LONG",
            order_uuid: "3d2307b1-fe87-4adc-a998-81d2a9f52d06",
            price: 0.614,
            price_sources: [],
            processed_ms: 1718745498738,
          },
          {
            leverage: 0.166826,
            order_type: "LONG",
            order_uuid: "5ac243d4-b323-4372-831e-6f7bd03e5aae",
            price: 0.61063,
            price_sources: [],
            processed_ms: 1719391511376,
          },
          {
            leverage: 0.176639,
            order_type: "LONG",
            order_uuid: "8298ebc2-47b5-4489-a672-a4b9c3a9bedd",
            price: 0.6087750000000001,
            price_sources: [],
            processed_ms: 1719406212486,
          },
          {
            leverage: 0.196223,
            order_type: "LONG",
            order_uuid: "49d06b7f-5b38-462e-834a-eee7b5de03b2",
            price: 0.60708,
            price_sources: [],
            processed_ms: 1719448809292,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "795036fc-89c4-4f1b-9ad2-42c54edf055b",
            price: 0.61077,
            price_sources: [],
            processed_ms: 1719492188386,
          },
        ],
        position_type: "FLAT",
        position_uuid: "3d2307b1-fe87-4adc-a998-81d2a9f52d06",
        return_at_close: 1.000968129871852,
        trade_pair: ["NZDUSD", "NZD/USD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.6088450000000001,
        close_ms: 1719580153669,
        current_return: 1.0001968799694503,
        initial_entry_price: 0.6088450000000001,
        is_closed_position: true,
        miner_hotkey: "5G3ys2356ovgUivX3endMP7f37LPEjRkzDAM3Km8CxQnErCw",
        net_leverage: 0.0,
        open_ms: 1719502812244,
        orders: [
          {
            leverage: 0.147079,
            order_type: "LONG",
            order_uuid: "b15ab1e9-047a-4e40-be45-a09dfc2f9cfb",
            price: 0.6088450000000001,
            price_sources: [],
            processed_ms: 1719502811627,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "ae95a317-2ae7-4f94-a216-d5feef8e368c",
            price: 0.60966,
            price_sources: [],
            processed_ms: 1719580153669,
          },
        ],
        position_type: "FLAT",
        position_uuid: "b15ab1e9-047a-4e40-be45-a09dfc2f9cfb",
        return_at_close: 1.0001865824124667,
        trade_pair: ["NZDUSD", "NZD/USD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.6093,
        close_ms: 1719789092499,
        current_return: 1.0002027456425406,
        initial_entry_price: 0.6093,
        is_closed_position: true,
        miner_hotkey: "5G3ys2356ovgUivX3endMP7f37LPEjRkzDAM3Km8CxQnErCw",
        net_leverage: 0.0,
        open_ms: 1719601216213,
        orders: [
          {
            leverage: 0.147063,
            order_type: "LONG",
            order_uuid: "95c6426f-e31a-437d-8f8f-17d3971dde0f",
            price: 0.6093,
            price_sources: [],
            processed_ms: 1719601215355,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "f146aba4-722d-45b5-8a51-46655a9b8d94",
            price: 0.61014,
            price_sources: [],
            processed_ms: 1719789092499,
          },
        ],
        position_type: "FLAT",
        position_uuid: "95c6426f-e31a-437d-8f8f-17d3971dde0f",
        return_at_close: 1.000192449145394,
        trade_pair: ["NZDUSD", "NZD/USD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.6097,
        close_ms: 1719815300337,
        current_return: 1.0001845008856816,
        initial_entry_price: 0.6097,
        is_closed_position: true,
        miner_hotkey: "5G3ys2356ovgUivX3endMP7f37LPEjRkzDAM3Km8CxQnErCw",
        net_leverage: 0.0,
        open_ms: 1719810012494,
        orders: [
          {
            leverage: 0.147046,
            order_type: "LONG",
            order_uuid: "9355c3e1-40ba-449f-88fa-1b00ec995f33",
            price: 0.6097,
            price_sources: [],
            processed_ms: 1719810012355,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "1cf05d1c-f92e-4941-8adb-f1ff2925e2f5",
            price: 0.610465,
            price_sources: [],
            processed_ms: 1719815300337,
          },
        ],
        position_type: "FLAT",
        position_uuid: "9355c3e1-40ba-449f-88fa-1b00ec995f33",
        return_at_close: 1.0001742057665732,
        trade_pair: ["NZDUSD", "NZD/USD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.6093,
        close_ms: 1719843382070,
        current_return: 1.0001930477597243,
        initial_entry_price: 0.6093,
        is_closed_position: true,
        miner_hotkey: "5G3ys2356ovgUivX3endMP7f37LPEjRkzDAM3Km8CxQnErCw",
        net_leverage: 0.0,
        open_ms: 1719841814777,
        orders: [
          {
            leverage: -0.156832,
            order_type: "SHORT",
            order_uuid: "ee949a1a-d7c8-4261-9642-a728554ac1e2",
            price: 0.6093,
            price_sources: [],
            processed_ms: 1719841814667,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "5429a5b3-7cac-4251-8de7-116648184a86",
            price: 0.60855,
            price_sources: [],
            processed_ms: 1719843382070,
          },
        ],
        position_type: "FLAT",
        position_uuid: "ee949a1a-d7c8-4261-9642-a728554ac1e2",
        return_at_close: 1.0001820674003996,
        trade_pair: ["NZDUSD", "NZD/USD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 1.266537062284531,
        close_ms: 1719844500847,
        current_return: 1.0002005478097593,
        initial_entry_price: 1.26219,
        is_closed_position: true,
        miner_hotkey: "5G3ys2356ovgUivX3endMP7f37LPEjRkzDAM3Km8CxQnErCw",
        net_leverage: 0.0,
        open_ms: 1719452712567,
        orders: [
          {
            leverage: -0.147167,
            order_type: "SHORT",
            order_uuid: "3e6bfe29-0bc1-49d9-90a2-4cc13dc2c7ad",
            price: 1.26219,
            price_sources: [],
            processed_ms: 1719452712383,
          },
          {
            leverage: -0.205842,
            order_type: "SHORT",
            order_uuid: "8fde844e-2e09-4ae9-be7b-7eab40d10cfb",
            price: 1.2696450000000001,
            price_sources: [],
            processed_ms: 1719842423275,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "1df10a73-4642-468a-a469-f250dd03582b",
            price: 1.26582,
            price_sources: [],
            processed_ms: 1719844500847,
          },
        ],
        position_type: "FLAT",
        position_uuid: "3e6bfe29-0bc1-49d9-90a2-4cc13dc2c7ad",
        return_at_close: 1.0001758322240966,
        trade_pair: ["GBPUSD", "GBP/USD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.607,
        close_ms: 1719876742072,
        current_return: 1.000129129324547,
        initial_entry_price: 0.607,
        is_closed_position: true,
        miner_hotkey: "5G3ys2356ovgUivX3endMP7f37LPEjRkzDAM3Km8CxQnErCw",
        net_leverage: 0.0,
        open_ms: 1719863127183,
        orders: [
          {
            leverage: -0.156763,
            order_type: "SHORT",
            order_uuid: "18d14fe2-4442-4264-8637-7914928bb311",
            price: 0.607,
            price_sources: [],
            processed_ms: 1719863124871,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "2d4b2189-1032-4a76-b090-a09fa27fb170",
            price: 0.6065,
            price_sources: [],
            processed_ms: 1719876742072,
          },
        ],
        position_type: "FLAT",
        position_uuid: "18d14fe2-4442-4264-8637-7914928bb311",
        return_at_close: 1.000118154497558,
        trade_pair: ["NZDUSD", "NZD/USD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 1.26442,
        close_ms: 1719921472966,
        current_return: 1.000110330380728,
        initial_entry_price: 1.26442,
        is_closed_position: true,
        miner_hotkey: "5G3ys2356ovgUivX3endMP7f37LPEjRkzDAM3Km8CxQnErCw",
        net_leverage: 0.0,
        open_ms: 1719877999176,
        orders: [
          {
            leverage: 0.156746,
            order_type: "LONG",
            order_uuid: "6c510380-26d9-4b14-b6e3-331194ec9de7",
            price: 1.26442,
            price_sources: [],
            processed_ms: 1719877998028,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "49870092-386c-4111-8d79-0494a0848bdf",
            price: 1.26531,
            price_sources: [],
            processed_ms: 1719921472966,
          },
        ],
        position_type: "FLAT",
        position_uuid: "6c510380-26d9-4b14-b6e3-331194ec9de7",
        return_at_close: 1.0000993569501586,
        trade_pair: ["GBPUSD", "GBP/USD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 1.2668,
        close_ms: 1719942955550,
        current_return: 1.0001051609962108,
        initial_entry_price: 1.2668,
        is_closed_position: true,
        miner_hotkey: "5G3ys2356ovgUivX3endMP7f37LPEjRkzDAM3Km8CxQnErCw",
        net_leverage: 0.0,
        open_ms: 1719939614706,
        orders: [
          {
            leverage: 0.156727,
            order_type: "LONG",
            order_uuid: "6a45b3b8-52b2-4b71-9ced-d16422974b6d",
            price: 1.2668,
            price_sources: [],
            processed_ms: 1719939614602,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "ab5e8aed-e4d3-4c68-9114-8cc22bff5335",
            price: 1.26765,
            price_sources: [],
            processed_ms: 1719942955550,
          },
        ],
        position_type: "FLAT",
        position_uuid: "6a45b3b8-52b2-4b71-9ced-d16422974b6d",
        return_at_close: 1.000094188952501,
        trade_pair: ["GBPUSD", "GBP/USD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 1.26806,
        close_ms: 1719991374709,
        current_return: 1.0001618909830765,
        initial_entry_price: 1.26806,
        is_closed_position: true,
        miner_hotkey: "5G3ys2356ovgUivX3endMP7f37LPEjRkzDAM3Km8CxQnErCw",
        net_leverage: 0.0,
        open_ms: 1719955097910,
        orders: [
          {
            leverage: 0.156708,
            order_type: "LONG",
            order_uuid: "71ca9cab-f89d-4b46-a0f5-aee280d2e0cc",
            price: 1.26806,
            price_sources: [],
            processed_ms: 1719955096996,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "58474a52-b965-4157-b516-156b487c112a",
            price: 1.26937,
            price_sources: [],
            processed_ms: 1719991374709,
          },
        ],
        position_type: "FLAT",
        position_uuid: "71ca9cab-f89d-4b46-a0f5-aee280d2e0cc",
        return_at_close: 1.0001509196472036,
        trade_pair: ["GBPUSD", "GBP/USD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 1.27536,
        close_ms: 1720080881111,
        current_return: 1.0000989012082864,
        initial_entry_price: 1.27536,
        is_closed_position: true,
        miner_hotkey: "5G3ys2356ovgUivX3endMP7f37LPEjRkzDAM3Km8CxQnErCw",
        net_leverage: 0.0,
        open_ms: 1720026014616,
        orders: [
          {
            leverage: 0.156689,
            order_type: "LONG",
            order_uuid: "00e682cc-f3dd-497e-90c5-846541f74a9a",
            price: 1.27536,
            price_sources: [
              {
                close: 1.27536,
                high: 1.27536,
                lag_ms: 493,
                low: 1.27536,
                open: 1.27536,
                source: "Polygon_ws",
                start_ms: 1720026014000,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 1.27536,
                websocket: true,
              },
              {
                close: 1.27534,
                high: 1.27534,
                lag_ms: 493,
                low: 1.27534,
                open: 1.27534,
                source: "TwelveData_ws",
                start_ms: 1720026014000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1720026014493,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "74449741-2b0f-426a-80c2-c7129026ab4e",
            price: 1.276165,
            price_sources: [
              {
                close: 1.276165,
                high: 1.276165,
                lag_ms: 111,
                low: 1.276165,
                open: 1.276165,
                source: "Polygon_ws",
                start_ms: 1720080881000,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 1.27617,
                websocket: true,
              },
              {
                close: 1.27613,
                high: 1.27613,
                lag_ms: 111,
                low: 1.27613,
                open: 1.27613,
                source: "TwelveData_ws",
                start_ms: 1720080881000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1720080881111,
          },
        ],
        position_type: "FLAT",
        position_uuid: "00e682cc-f3dd-497e-90c5-846541f74a9a",
        return_at_close: 1.0000879318935152,
        trade_pair: ["GBPUSD", "GBP/USD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 1.27498,
        close_ms: 1720101643728,
        current_return: 1.0000933890727697,
        initial_entry_price: 1.27498,
        is_closed_position: true,
        miner_hotkey: "5G3ys2356ovgUivX3endMP7f37LPEjRkzDAM3Km8CxQnErCw",
        net_leverage: 0.0,
        open_ms: 1720098613014,
        orders: [
          {
            leverage: 0.15667,
            order_type: "LONG",
            order_uuid: "7cc498db-2147-4395-a65c-ff89a8fb86bb",
            price: 1.27498,
            price_sources: [
              {
                close: 1.27498,
                high: 1.27498,
                lag_ms: 7,
                low: 1.27498,
                open: 1.27498,
                source: "Polygon_ws",
                start_ms: 1720098613000,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 1.27499,
                websocket: true,
              },
              {
                close: 1.27498,
                high: 1.27498,
                lag_ms: 7,
                low: 1.27498,
                open: 1.27498,
                source: "TwelveData_ws",
                start_ms: 1720098613000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1720098613007,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "b1980759-bdb4-4149-a19c-534ebc2a76ea",
            price: 1.27574,
            price_sources: [
              {
                close: 1.27574,
                high: 1.27574,
                lag_ms: 271,
                low: 1.27574,
                open: 1.27574,
                source: "Polygon_ws",
                start_ms: 1720101643999,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 1.27574,
                websocket: true,
              },
              {
                close: 1.27575,
                high: 1.27575,
                lag_ms: 272,
                low: 1.27575,
                open: 1.27575,
                source: "TwelveData_ws",
                start_ms: 1720101644000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1720101643728,
          },
        ],
        position_type: "FLAT",
        position_uuid: "7cc498db-2147-4395-a65c-ff89a8fb86bb",
        return_at_close: 1.000082421148581,
        trade_pair: ["GBPUSD", "GBP/USD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 1.2761,
        close_ms: 1720149767960,
        current_return: 1.0000920688033854,
        initial_entry_price: 1.2761,
        is_closed_position: true,
        miner_hotkey: "5G3ys2356ovgUivX3endMP7f37LPEjRkzDAM3Km8CxQnErCw",
        net_leverage: 0.0,
        open_ms: 1720127316240,
        orders: [
          {
            leverage: 0.156652,
            order_type: "LONG",
            order_uuid: "6a6cf817-baa4-4561-a42d-5dfdb2436028",
            price: 1.2761,
            price_sources: [
              {
                close: 1.2761,
                high: 1.2761,
                lag_ms: 214,
                low: 1.2761,
                open: 1.2761,
                source: "Polygon_ws",
                start_ms: 1720127315999,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 1.2761,
                websocket: true,
              },
              {
                close: 1.27588,
                high: 1.27588,
                lag_ms: 5785,
                low: 1.27588,
                open: 1.27588,
                source: "TwelveData_ws",
                start_ms: 1720127310000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1720127315785,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "13c25413-551f-473a-931c-99bd57abeda0",
            price: 1.27685,
            price_sources: [
              {
                close: 1.27685,
                high: 1.27685,
                lag_ms: 39,
                low: 1.27685,
                open: 1.27685,
                source: "Polygon_ws",
                start_ms: 1720149767999,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 1.2769,
                websocket: true,
              },
              {
                close: 1.27694,
                high: 1.27694,
                lag_ms: 40,
                low: 1.27694,
                open: 1.27694,
                source: "TwelveData_ws",
                start_ms: 1720149768000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1720149767960,
          },
        ],
        position_type: "FLAT",
        position_uuid: "6a6cf817-baa4-4561-a42d-5dfdb2436028",
        return_at_close: 1.000081102153792,
        trade_pair: ["GBPUSD", "GBP/USD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 1.2767249999999999,
        close_ms: 1720165257315,
        current_return: 1.000103669482465,
        initial_entry_price: 1.2767249999999999,
        is_closed_position: true,
        miner_hotkey: "5G3ys2356ovgUivX3endMP7f37LPEjRkzDAM3Km8CxQnErCw",
        net_leverage: 0.0,
        open_ms: 1720163721325,
        orders: [
          {
            leverage: 0.156636,
            order_type: "LONG",
            order_uuid: "205293b9-74a9-44eb-8b35-f70ecde86c3e",
            price: 1.2767249999999999,
            price_sources: [
              {
                close: 1.2767249999999999,
                high: 1.2767249999999999,
                lag_ms: 198,
                low: 1.2767249999999999,
                open: 1.2767249999999999,
                source: "Polygon_ws",
                start_ms: 1720163721000,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 1.27674,
                websocket: true,
              },
              {
                close: 1.27675,
                high: 1.27675,
                lag_ms: 198,
                low: 1.27675,
                open: 1.27675,
                source: "TwelveData_ws",
                start_ms: 1720163721000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1720163721198,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "0091ac5b-c4f3-4862-8f53-cc1021b65225",
            price: 1.27757,
            price_sources: [
              {
                close: 1.27757,
                high: 1.27757,
                lag_ms: 315,
                low: 1.27757,
                open: 1.27757,
                source: "Polygon_ws",
                start_ms: 1720165257000,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 1.27757,
                websocket: true,
              },
              {
                close: 1.27753,
                high: 1.27753,
                lag_ms: 315,
                low: 1.27753,
                open: 1.27753,
                source: "TwelveData_ws",
                start_ms: 1720165257000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1720165257315,
          },
        ],
        position_type: "FLAT",
        position_uuid: "205293b9-74a9-44eb-8b35-f70ecde86c3e",
        return_at_close: 1.0000927038257788,
        trade_pair: ["GBPUSD", "GBP/USD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 1.28018,
        close_ms: 1720390334628,
        current_return: 1.0001590417753754,
        initial_entry_price: 1.28018,
        is_closed_position: true,
        miner_hotkey: "5G3ys2356ovgUivX3endMP7f37LPEjRkzDAM3Km8CxQnErCw",
        net_leverage: 0.0,
        open_ms: 1720387157503,
        orders: [
          {
            leverage: 0.156617,
            order_type: "LONG",
            order_uuid: "d4b131e0-feae-44d1-9313-0b78a063b65a",
            price: 1.28018,
            price_sources: [
              {
                close: 1.28018,
                high: 1.28018,
                lag_ms: 6538,
                low: 1.28018,
                open: 1.28018,
                source: "TwelveData_ws",
                start_ms: 1720387150000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
              {
                close: 1.28018,
                high: 1.28018,
                lag_ms: 43462,
                low: 1.28018,
                open: 1.28018,
                source: "Polygon_ws",
                start_ms: 1720387200000,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 1.28018,
                websocket: true,
              },
              {
                close: 1.28145,
                high: 1.2816,
                lag_ms: 173956539,
                low: 1.2814,
                open: 1.28155,
                source: "TwelveData_rest",
                start_ms: 1720213140000,
                timespan_ms: 60000,
                volume: null,
                vwap: null,
                websocket: false,
              },
            ],
            processed_ms: 1720387156538,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "66b9a05a-1173-4153-bd2e-2a7e832dca35",
            price: 1.28148,
            price_sources: [
              {
                close: 1.28148,
                high: 1.28148,
                lag_ms: 1629,
                low: 1.28148,
                open: 1.28148,
                source: "Polygon_ws",
                start_ms: 1720390332999,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 1.28148,
                websocket: true,
              },
              {
                close: 1.28147,
                high: 1.28147,
                lag_ms: 3628,
                low: 1.28147,
                open: 1.28147,
                source: "TwelveData_ws",
                start_ms: 1720390331000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1720390334628,
          },
        ],
        position_type: "FLAT",
        position_uuid: "d4b131e0-feae-44d1-9313-0b78a063b65a",
        return_at_close: 1.00014807684177,
        trade_pair: ["GBPUSD", "GBP/USD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 1.2809599999999999,
        close_ms: 1720425746487,
        current_return: 1.0001026904196852,
        initial_entry_price: 1.2809599999999999,
        is_closed_position: true,
        miner_hotkey: "5G3ys2356ovgUivX3endMP7f37LPEjRkzDAM3Km8CxQnErCw",
        net_leverage: 0.0,
        open_ms: 1720418414585,
        orders: [
          {
            leverage: 0.156598,
            order_type: "LONG",
            order_uuid: "a69d3930-1635-4074-bd5d-b1c6e9b07067",
            price: 1.2809599999999999,
            price_sources: [
              {
                close: 1.2809599999999999,
                high: 1.2809599999999999,
                lag_ms: 398,
                low: 1.2809599999999999,
                open: 1.2809599999999999,
                source: "Polygon_ws",
                start_ms: 1720418414000,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 1.2809,
                websocket: true,
              },
              {
                close: 1.28101,
                high: 1.28101,
                lag_ms: 398,
                low: 1.28101,
                open: 1.28101,
                source: "TwelveData_ws",
                start_ms: 1720418414000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1720418414398,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "9801db48-fd92-4337-90d8-87bba67bcefe",
            price: 1.2818,
            price_sources: [
              {
                close: 1.2818,
                high: 1.2818,
                lag_ms: 487,
                low: 1.2818,
                open: 1.2818,
                source: "Polygon_ws",
                start_ms: 1720425746000,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 1.28178,
                websocket: true,
              },
              {
                close: 1.28176,
                high: 1.28176,
                lag_ms: 487,
                low: 1.28176,
                open: 1.28176,
                source: "TwelveData_ws",
                start_ms: 1720425746000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1720425746487,
          },
        ],
        position_type: "FLAT",
        position_uuid: "a69d3930-1635-4074-bd5d-b1c6e9b07067",
        return_at_close: 1.000091727434007,
        trade_pair: ["GBPUSD", "GBP/USD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 1.28138,
        close_ms: 1720488171968,
        current_return: 1.0001032552833664,
        initial_entry_price: 1.28138,
        is_closed_position: true,
        miner_hotkey: "5G3ys2356ovgUivX3endMP7f37LPEjRkzDAM3Km8CxQnErCw",
        net_leverage: 0.0,
        open_ms: 1720426823109,
        orders: [
          {
            leverage: -0.156579,
            order_type: "SHORT",
            order_uuid: "d003f9bb-cca2-43fe-8f33-f297f93df07d",
            price: 1.28138,
            price_sources: [
              {
                close: 1.28138,
                high: 1.28138,
                lag_ms: 99,
                low: 1.28138,
                open: 1.28138,
                source: "Polygon_ws",
                start_ms: 1720426822999,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 1.28138,
                websocket: true,
              },
              {
                close: 1.28138,
                high: 1.28138,
                lag_ms: 100,
                low: 1.28138,
                open: 1.28138,
                source: "TwelveData_ws",
                start_ms: 1720426823000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1720426822900,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "313f7009-3971-45b4-a3a1-73f0b517dfb7",
            price: 1.280535,
            price_sources: [
              {
                close: 1.280535,
                high: 1.280535,
                lag_ms: 31,
                low: 1.280535,
                open: 1.280535,
                source: "Polygon_ws",
                start_ms: 1720488171999,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 1.28057,
                websocket: true,
              },
              {
                close: 1.28056,
                high: 1.28056,
                lag_ms: 1032,
                low: 1.28056,
                open: 1.28056,
                source: "TwelveData_ws",
                start_ms: 1720488173000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1720488171968,
          },
        ],
        position_type: "FLAT",
        position_uuid: "d003f9bb-cca2-43fe-8f33-f297f93df07d",
        return_at_close: 1.0000922936216337,
        trade_pair: ["GBPUSD", "GBP/USD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 1.28068,
        close_ms: 1720511191711,
        current_return: 1.0000892412858793,
        initial_entry_price: 1.28068,
        is_closed_position: true,
        miner_hotkey: "5G3ys2356ovgUivX3endMP7f37LPEjRkzDAM3Km8CxQnErCw",
        net_leverage: 0.0,
        open_ms: 1720499416149,
        orders: [
          {
            leverage: -0.156561,
            order_type: "SHORT",
            order_uuid: "bec6e269-17e1-4d5c-89bc-bc01ecb3e7c0",
            price: 1.28068,
            price_sources: [
              {
                close: 1.28068,
                high: 1.28068,
                lag_ms: 170,
                low: 1.28068,
                open: 1.28068,
                source: "Polygon_ws",
                start_ms: 1720499413000,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 1.28068,
                websocket: true,
              },
              {
                close: 1.28068,
                high: 1.28068,
                lag_ms: 830,
                low: 1.28068,
                open: 1.28068,
                source: "TwelveData_ws",
                start_ms: 1720499414000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1720499413170,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "c99a5bb0-76c3-4494-9ff5-a216c2c45d73",
            price: 1.27995,
            price_sources: [
              {
                close: 1.27995,
                high: 1.27995,
                lag_ms: 288,
                low: 1.27995,
                open: 1.27995,
                source: "Polygon_ws",
                start_ms: 1720511191999,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 1.27995,
                websocket: true,
              },
              {
                close: 1.27999,
                high: 1.27999,
                lag_ms: 289,
                low: 1.27999,
                open: 1.27999,
                source: "TwelveData_ws",
                start_ms: 1720511192000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1720511191711,
          },
        ],
        position_type: "FLAT",
        position_uuid: "bec6e269-17e1-4d5c-89bc-bc01ecb3e7c0",
        return_at_close: 1.00007828103786,
        trade_pair: ["GBPUSD", "GBP/USD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 1.28,
        close_ms: 1720516530077,
        current_return: 1.000113737546875,
        initial_entry_price: 1.28,
        is_closed_position: true,
        miner_hotkey: "5G3ys2356ovgUivX3endMP7f37LPEjRkzDAM3Km8CxQnErCw",
        net_leverage: 0.0,
        open_ms: 1720512612202,
        orders: [
          {
            leverage: 0.156542,
            order_type: "LONG",
            order_uuid: "3ad85379-2430-4be7-914d-36f156d9eae4",
            price: 1.28,
            price_sources: [
              {
                close: 1.28,
                high: 1.28,
                lag_ms: 209,
                low: 1.28,
                open: 1.28,
                source: "Polygon_ws",
                start_ms: 1720512612000,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 1.28,
                websocket: true,
              },
              {
                close: 1.28012,
                high: 1.28012,
                lag_ms: 209,
                low: 1.28012,
                open: 1.28012,
                source: "TwelveData_ws",
                start_ms: 1720512612000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
              {
                close: 1.280115,
                high: 1.280115,
                lag_ms: 2792,
                low: 1.280115,
                open: 1.280115,
                source: "Polygon_rest",
                start_ms: 1720512608000,
                timespan_ms: 1000,
                volume: 2.0,
                vwap: null,
                websocket: false,
              },
              {
                close: 1.2799,
                high: 1.2801,
                lag_ms: 731792,
                low: 1.2799,
                open: 1.28,
                source: "TwelveData_rest",
                start_ms: 1720511820000,
                timespan_ms: 60000,
                volume: null,
                vwap: null,
                websocket: false,
              },
            ],
            processed_ms: 1720512611791,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "7fa9e056-6de1-4874-8aae-8cd6ea161ad5",
            price: 1.28093,
            price_sources: [
              {
                close: 1.28093,
                high: 1.28093,
                lag_ms: 77,
                low: 1.28093,
                open: 1.28093,
                source: "TwelveData_ws",
                start_ms: 1720516530000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
              {
                close: 1.28065,
                high: 1.28065,
                lag_ms: 90078,
                low: 1.28065,
                open: 1.28065,
                source: "TwelveData_rest",
                start_ms: 1720516380000,
                timespan_ms: 60000,
                volume: null,
                vwap: null,
                websocket: false,
              },
              {
                close: 1.28066,
                high: 1.28066,
                lag_ms: 268078,
                low: 1.28066,
                open: 1.28066,
                source: "Polygon_ws",
                start_ms: 1720516261999,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 1.28067,
                websocket: true,
              },
            ],
            processed_ms: 1720516530077,
          },
        ],
        position_type: "FLAT",
        position_uuid: "3ad85379-2430-4be7-914d-36f156d9eae4",
        return_at_close: 1.0001027783605458,
        trade_pair: ["GBPUSD", "GBP/USD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 1.2800150000000001,
        close_ms: 1720530008474,
        current_return: 1.0000923230313707,
        initial_entry_price: 1.2800150000000001,
        is_closed_position: true,
        miner_hotkey: "5G3ys2356ovgUivX3endMP7f37LPEjRkzDAM3Km8CxQnErCw",
        net_leverage: 0.0,
        open_ms: 1720526410943,
        orders: [
          {
            leverage: 0.156523,
            order_type: "LONG",
            order_uuid: "e954a2e9-6f1d-4477-9a76-f84f3e554644",
            price: 1.2800150000000001,
            price_sources: [
              {
                close: 1.2800150000000001,
                high: 1.2800150000000001,
                lag_ms: 68,
                low: 1.2800150000000001,
                open: 1.2800150000000001,
                source: "Polygon_ws",
                start_ms: 1720526410999,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 1.28004,
                websocket: true,
              },
              {
                close: 1.28,
                high: 1.28,
                lag_ms: 69,
                low: 1.28,
                open: 1.28,
                source: "TwelveData_ws",
                start_ms: 1720526411000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1720526410931,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "f7132644-74dd-48b0-8fdb-f4d2c6300e7b",
            price: 1.28077,
            price_sources: [
              {
                close: 1.28084,
                high: 1.28084,
                lag_ms: 474,
                low: 1.28084,
                open: 1.28084,
                source: "Polygon_ws",
                start_ms: 1720530008000,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 1.28077,
                websocket: true,
              },
              {
                close: 1.2806,
                high: 1.2806,
                lag_ms: 12474,
                low: 1.2806,
                open: 1.2806,
                source: "TwelveData_ws",
                start_ms: 1720529996000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1720530008474,
          },
        ],
        position_type: "FLAT",
        position_uuid: "e954a2e9-6f1d-4477-9a76-f84f3e554644",
        return_at_close: 1.0000813654098233,
        trade_pair: ["GBPUSD", "GBP/USD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 1.2816049999999999,
        close_ms: 1720533847324,
        current_return: 1.0001025771278982,
        initial_entry_price: 1.2816049999999999,
        is_closed_position: true,
        miner_hotkey: "5G3ys2356ovgUivX3endMP7f37LPEjRkzDAM3Km8CxQnErCw",
        net_leverage: 0.0,
        open_ms: 1720533611505,
        orders: [
          {
            leverage: -0.156504,
            order_type: "SHORT",
            order_uuid: "50319a09-764d-49c3-9d4c-304c30cb9d00",
            price: 1.2816049999999999,
            price_sources: [
              {
                close: 1.2816049999999999,
                high: 1.2816049999999999,
                lag_ms: 489,
                low: 1.2816049999999999,
                open: 1.2816049999999999,
                source: "Polygon_ws",
                start_ms: 1720533611000,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 1.28162,
                websocket: true,
              },
              {
                close: 1.28165,
                high: 1.28165,
                lag_ms: 489,
                low: 1.28165,
                open: 1.28165,
                source: "TwelveData_ws",
                start_ms: 1720533611000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1720533611489,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "a2bb9c8a-ec02-4ad4-8154-4525e1d3d4e2",
            price: 1.280765,
            price_sources: [
              {
                close: 1.280765,
                high: 1.280765,
                lag_ms: 324,
                low: 1.280765,
                open: 1.280765,
                source: "Polygon_ws",
                start_ms: 1720533847000,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 1.28076,
                websocket: true,
              },
              {
                close: 1.28078,
                high: 1.28078,
                lag_ms: 676,
                low: 1.28078,
                open: 1.28078,
                source: "TwelveData_ws",
                start_ms: 1720533848000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1720533847324,
          },
        ],
        position_type: "FLAT",
        position_uuid: "50319a09-764d-49c3-9d4c-304c30cb9d00",
        return_at_close: 1.000091620724137,
        trade_pair: ["GBPUSD", "GBP/USD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 1.2799,
        close_ms: 1720536208017,
        current_return: 1.0000562411907181,
        initial_entry_price: 1.2799,
        is_closed_position: true,
        miner_hotkey: "5G3ys2356ovgUivX3endMP7f37LPEjRkzDAM3Km8CxQnErCw",
        net_leverage: 0.0,
        open_ms: 1720533919353,
        orders: [
          {
            leverage: -0.156485,
            order_type: "SHORT",
            order_uuid: "b7f583dc-b024-40cf-9738-1bffb5c24752",
            price: 1.2799,
            price_sources: [
              {
                close: 1.2799,
                high: 1.2799,
                lag_ms: 345,
                low: 1.2799,
                open: 1.2799,
                source: "Polygon_ws",
                start_ms: 1720533919000,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 1.2799,
                websocket: true,
              },
              {
                close: 1.2799,
                high: 1.2799,
                lag_ms: 345,
                low: 1.2799,
                open: 1.2799,
                source: "TwelveData_ws",
                start_ms: 1720533919000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1720533919345,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "4c9c36ef-e85e-410d-8d7f-b2ab9c46164a",
            price: 1.27944,
            price_sources: [
              {
                close: 1.27944,
                high: 1.27944,
                lag_ms: 17,
                low: 1.27944,
                open: 1.27944,
                source: "Polygon_ws",
                start_ms: 1720536208000,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 1.27937,
                websocket: true,
              },
              {
                close: 1.27947,
                high: 1.27947,
                lag_ms: 983,
                low: 1.27947,
                open: 1.27947,
                source: "TwelveData_ws",
                start_ms: 1720536209000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1720536208017,
          },
        ],
        position_type: "FLAT",
        position_uuid: "b7f583dc-b024-40cf-9738-1bffb5c24752",
        return_at_close: 1.000045286624655,
        trade_pair: ["GBPUSD", "GBP/USD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 1.27934,
        close_ms: 1720603071490,
        current_return: 1.0001186338190005,
        initial_entry_price: 1.27934,
        is_closed_position: true,
        miner_hotkey: "5G3ys2356ovgUivX3endMP7f37LPEjRkzDAM3Km8CxQnErCw",
        net_leverage: 0.0,
        open_ms: 1720536920289,
        orders: [
          {
            leverage: 0.156467,
            order_type: "LONG",
            order_uuid: "c0dfef36-9ec1-434f-87b7-9d5f12d4a6ff",
            price: 1.27934,
            price_sources: [
              {
                close: 1.27934,
                high: 1.27934,
                lag_ms: 281,
                low: 1.27934,
                open: 1.27934,
                source: "Polygon_ws",
                start_ms: 1720536920000,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 1.27936,
                websocket: true,
              },
              {
                close: 1.27935,
                high: 1.27935,
                lag_ms: 281,
                low: 1.27935,
                open: 1.27935,
                source: "TwelveData_ws",
                start_ms: 1720536920000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1720536920281,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "b881ff75-9138-4e0a-94bc-1229df37e08e",
            price: 1.28031,
            price_sources: [
              {
                close: 1.28031,
                high: 1.28031,
                lag_ms: 490,
                low: 1.28031,
                open: 1.28031,
                source: "Polygon_ws",
                start_ms: 1720603071000,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 1.28031,
                websocket: true,
              },
              {
                close: 1.28028,
                high: 1.28028,
                lag_ms: 490,
                low: 1.28028,
                open: 1.28028,
                source: "TwelveData_ws",
                start_ms: 1720603071000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1720603071490,
          },
        ],
        position_type: "FLAT",
        position_uuid: "c0dfef36-9ec1-434f-87b7-9d5f12d4a6ff",
        return_at_close: 1.000107679829641,
        trade_pair: ["GBPUSD", "GBP/USD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.611918557600557,
        close_ms: 0,
        current_return: 1.006023177193446,
        initial_entry_price: 0.60544,
        is_closed_position: false,
        miner_hotkey: "5G3ys2356ovgUivX3endMP7f37LPEjRkzDAM3Km8CxQnErCw",
        net_leverage: -0.9306159999999999,
        open_ms: 1719894613986,
        orders: [
          {
            leverage: -0.156746,
            order_type: "SHORT",
            order_uuid: "8e3ba790-d702-4aea-9c09-27c7bb2a530e",
            price: 0.60544,
            price_sources: [],
            processed_ms: 1719894613834,
          },
          {
            leverage: -0.176275,
            order_type: "SHORT",
            order_uuid: "fd81c67c-86c0-4840-ae86-eee413339653",
            price: 0.60936,
            price_sources: [],
            processed_ms: 1720013716524,
          },
          {
            leverage: -0.186068,
            order_type: "SHORT",
            order_uuid: "f10d2044-d298-444c-b80a-dec72d5d5300",
            price: 0.611575,
            price_sources: [],
            processed_ms: 1720015548041,
          },
          {
            leverage: -0.20556,
            order_type: "SHORT",
            order_uuid: "e4f87e73-6515-433d-b87d-f80c5d56b5a3",
            price: 0.61281,
            price_sources: [
              {
                close: 0.61281,
                high: 0.61281,
                lag_ms: 424,
                low: 0.61281,
                open: 0.61281,
                source: "Polygon_ws",
                start_ms: 1720182310000,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 0.61281,
                websocket: true,
              },
              {
                close: 0.61278,
                high: 0.61278,
                lag_ms: 424,
                low: 0.61278,
                open: 0.61278,
                source: "TwelveData_ws",
                start_ms: 1720182310000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1720182309576,
          },
          {
            leverage: -0.234925,
            order_type: "SHORT",
            order_uuid: "d9d87ea7-f2c3-4d72-a7f9-ad8f301cf71a",
            price: 0.61374,
            price_sources: [
              {
                close: 0.61374,
                high: 0.61374,
                lag_ms: 89,
                low: 0.61374,
                open: 0.61374,
                source: "Polygon_ws",
                start_ms: 1720193407999,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 0.61374,
                websocket: true,
              },
              {
                close: 0.61376,
                high: 0.61376,
                lag_ms: 912,
                low: 0.61376,
                open: 0.61376,
                source: "TwelveData_ws",
                start_ms: 1720193409000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1720193408088,
          },
          {
            leverage: -0.254503,
            order_type: "SHORT",
            order_uuid: "664d3219-d645-4434-a0d9-c1060aeacb42",
            price: 0.61476,
            price_sources: [
              {
                close: 0.61476,
                high: 0.61476,
                lag_ms: 3355,
                low: 0.61476,
                open: 0.61476,
                source: "Polygon_ws",
                start_ms: 1720209319999,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 0.61476,
                websocket: true,
              },
              {
                close: 0.61476,
                high: 0.61476,
                lag_ms: 3355,
                low: 0.61476,
                open: 0.61476,
                source: "Polygon_rest",
                start_ms: 1720209319000,
                timespan_ms: 1000,
                volume: 1.0,
                vwap: null,
                websocket: false,
              },
              {
                close: 0.6148,
                high: 0.6148,
                lag_ms: 4354,
                low: 0.6148,
                open: 0.6148,
                source: "TwelveData_ws",
                start_ms: 1720209319000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
              {
                close: 0.6148,
                high: 0.61485,
                lag_ms: 36646,
                low: 0.6148,
                open: 0.61485,
                source: "TwelveData_rest",
                start_ms: 1720209360000,
                timespan_ms: 60000,
                volume: null,
                vwap: null,
                websocket: false,
              },
            ],
            processed_ms: 1720209323354,
          },
          {
            leverage: 0.234573,
            order_type: "LONG",
            order_uuid: "3e06dc18-6b77-4a12-8dab-dd8dfa56b6ba",
            price: 0.61023,
            price_sources: [
              {
                close: 0.61023,
                high: 0.61023,
                lag_ms: 353,
                low: 0.61023,
                open: 0.61023,
                source: "Polygon_ws",
                start_ms: 1720576865000,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 0.61023,
                websocket: true,
              },
              {
                close: 0.61026,
                high: 0.61026,
                lag_ms: 353,
                low: 0.61026,
                open: 0.61026,
                source: "TwelveData_ws",
                start_ms: 1720576865000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1720576865353,
          },
          {
            leverage: 0.205251,
            order_type: "LONG",
            order_uuid: "9610d5bb-6d79-4078-83e8-38ac28182670",
            price: 0.6099,
            price_sources: [
              {
                close: 0.6099,
                high: 0.6099,
                lag_ms: 194,
                low: 0.6099,
                open: 0.6099,
                source: "Polygon_ws",
                start_ms: 1720576919000,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 0.60991,
                websocket: true,
              },
              {
                close: 0.60988,
                high: 0.60988,
                lag_ms: 806,
                low: 0.60988,
                open: 0.60988,
                source: "TwelveData_ws",
                start_ms: 1720576920000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1720576919194,
          },
          {
            leverage: -0.156363,
            order_type: "SHORT",
            order_uuid: "4271a15b-d8d1-4e60-a762-a1205aed2def",
            price: 0.60799,
            price_sources: [
              {
                close: 0.60799,
                high: 0.60799,
                lag_ms: 15,
                low: 0.60799,
                open: 0.60799,
                source: "Polygon_ws",
                start_ms: 1720614612000,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 0.60802,
                websocket: true,
              },
              {
                close: 0.608,
                high: 0.608,
                lag_ms: 15,
                low: 0.608,
                open: 0.608,
                source: "TwelveData_ws",
                start_ms: 1720614612000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1720614612015,
          },
        ],
        position_type: "SHORT",
        position_uuid: "8e3ba790-d702-4aea-9c09-27c7bb2a530e",
        return_at_close: 1.0059594363295166,
        trade_pair: ["NZDUSD", "NZD/USD", 7e-5, 0.001, 500],
      },
    ],
    thirty_day_returns: 1.0091440446714908,
  },
  "5G43ARm9LSU2aF5send9PvwMEr7P1fRFC1dVujv9Znn4pcXp": {
    all_time_returns: 1.0,
    n_positions: 0,
    percentage_profitable: 0.0,
    positions: [
      {
        average_entry_price: 65327.99,
        close_ms: 1711313733453,
        current_return: 0.9724041716268935,
        initial_entry_price: 65327.99,
        is_closed_position: true,
        miner_hotkey: "5G43ARm9LSU2aF5send9PvwMEr7P1fRFC1dVujv9Znn4pcXp",
        net_leverage: 0.0,
        open_ms: 1711282395268,
        orders: [
          {
            leverage: -2.0,
            order_type: "SHORT",
            order_uuid: "1dbd884c-bd0c-4918-99e7-0a5412d618db",
            price: 65327.99,
            price_sources: [],
            processed_ms: 1711282395268,
          },
          {
            leverage: 0.25,
            order_type: "FLAT",
            order_uuid: "e4780e05-5986-4ee7-8ed7-1b695e28309f",
            price: 66229.38,
            price_sources: [],
            processed_ms: 1711313733453,
          },
        ],
        position_type: "FLAT",
        position_uuid: "2bccac9e-7ef4-4faf-bfa4-edc581e16b42",
        return_at_close: 0.968514554940386,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 71038.57,
        close_ms: 1711397757670,
        current_return: 1.0298739121578604,
        initial_entry_price: 71038.57,
        is_closed_position: true,
        miner_hotkey: "5G43ARm9LSU2aF5send9PvwMEr7P1fRFC1dVujv9Znn4pcXp",
        net_leverage: 0.0,
        open_ms: 1711396970799,
        orders: [
          {
            leverage: -10.0,
            order_type: "SHORT",
            order_uuid: "3bb58790-e7e2-4b4f-bdf3-5ddc73a36ada",
            price: 71038.57,
            price_sources: [],
            processed_ms: 1711396970798,
          },
          {
            leverage: 10.0,
            order_type: "FLAT",
            order_uuid: "1093ec82-6be6-4173-9250-aa4278974007",
            price: 70826.35,
            price_sources: [],
            processed_ms: 1711397757670,
          },
        ],
        position_type: "FLAT",
        position_uuid: "b90a2e3b-c083-4262-bd09-454b85eb1355",
        return_at_close: 1.0092764339147031,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 70863.38,
        close_ms: 1711398966302,
        current_return: 1.0188122553567156,
        initial_entry_price: 70863.38,
        is_closed_position: true,
        miner_hotkey: "5G43ARm9LSU2aF5send9PvwMEr7P1fRFC1dVujv9Znn4pcXp",
        net_leverage: 0.0,
        open_ms: 1711397871054,
        orders: [
          {
            leverage: -10.0,
            order_type: "SHORT",
            order_uuid: "03130026-8a10-4ca1-9428-c8c5705743d2",
            price: 70863.38,
            price_sources: [],
            processed_ms: 1711397871053,
          },
          {
            leverage: 10.0,
            order_type: "FLAT",
            order_uuid: "11f1738f-ce8f-404f-820a-82ea2cf76ab6",
            price: 70730.07,
            price_sources: [],
            processed_ms: 1711398966302,
          },
        ],
        position_type: "FLAT",
        position_uuid: "499f0966-0d4a-4711-8387-d9004a152980",
        return_at_close: 0.9984360102495813,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 70806.6,
        close_ms: 1711399584406,
        current_return: 0.9874178395799267,
        initial_entry_price: 70806.6,
        is_closed_position: true,
        miner_hotkey: "5G43ARm9LSU2aF5send9PvwMEr7P1fRFC1dVujv9Znn4pcXp",
        net_leverage: 0.0,
        open_ms: 1711399090683,
        orders: [
          {
            leverage: -10.0,
            order_type: "SHORT",
            order_uuid: "e49ec904-edc8-4847-8766-f334521aa7fd",
            price: 70806.6,
            price_sources: [],
            processed_ms: 1711399090394,
          },
          {
            leverage: 10.0,
            order_type: "FLAT",
            order_uuid: "8434956f-7953-44df-afe9-0c96c3018249",
            price: 70895.69,
            price_sources: [],
            processed_ms: 1711399584406,
          },
        ],
        position_type: "FLAT",
        position_uuid: "55968497-d635-4e46-adfd-5fba77872668",
        return_at_close: 0.9676694827883281,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 71124.51,
        close_ms: 1711439201072,
        current_return: 1.0107848240313375,
        initial_entry_price: 69623.76,
        is_closed_position: true,
        miner_hotkey: "5G43ARm9LSU2aF5send9PvwMEr7P1fRFC1dVujv9Znn4pcXp",
        net_leverage: 0.0,
        open_ms: 1711402182235,
        orders: [
          {
            leverage: 1.0,
            order_type: "LONG",
            order_uuid: "46d875cd-a8ee-46f1-a8a5-861eefc1d8da",
            price: 69623.76,
            price_sources: [],
            processed_ms: 1711402182234,
          },
          {
            leverage: 9.0,
            order_type: "LONG",
            order_uuid: "807ee888-b18c-4515-a470-e7eca450ce09",
            price: 71164.26,
            price_sources: [],
            processed_ms: 1711438924101,
          },
          {
            leverage: 9.0,
            order_type: "LONG",
            order_uuid: "84ddacc2-b17f-42ad-a018-87eeb5e9f537",
            price: 71251.51,
            price_sources: [],
            processed_ms: 1711439044828,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "88ed2b9d-fcac-4c4c-aea7-016e111ff378",
            price: 71164.03,
            price_sources: [],
            processed_ms: 1711439201072,
          },
        ],
        position_type: "FLAT",
        position_uuid: "09f03dbe-a4ad-4721-826d-7887e09d9412",
        return_at_close: 0.9723750007181466,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 70940.31,
        close_ms: 1711439729668,
        current_return: 0.973323095538693,
        initial_entry_price: 71056.97,
        is_closed_position: true,
        miner_hotkey: "5G43ARm9LSU2aF5send9PvwMEr7P1fRFC1dVujv9Znn4pcXp",
        net_leverage: 0.0,
        open_ms: 1711439543509,
        orders: [
          {
            leverage: 9.0,
            order_type: "LONG",
            order_uuid: "9f154455-4b6c-44a9-a1d7-fdd49710c4a3",
            price: 71056.97,
            price_sources: [],
            processed_ms: 1711439543508,
          },
          {
            leverage: 9.0,
            order_type: "LONG",
            order_uuid: "9104efe0-2d0d-4efb-a77f-1f8e3c85f28c",
            price: 70823.65,
            price_sources: [],
            processed_ms: 1711439654084,
          },
          {
            leverage: 2.0,
            order_type: "FLAT",
            order_uuid: "d9c3f570-68f2-4db2-8899-ef0fa09546b9",
            price: 70835.0,
            price_sources: [],
            processed_ms: 1711439729668,
          },
        ],
        position_type: "FLAT",
        position_uuid: "c9d6b867-8e83-4e97-b7a9-0d761aae9eeb",
        return_at_close: 0.9382834640993001,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 70559.09,
        close_ms: 1711440098964,
        current_return: 1.012561811667354,
        initial_entry_price: 70559.09,
        is_closed_position: true,
        miner_hotkey: "5G43ARm9LSU2aF5send9PvwMEr7P1fRFC1dVujv9Znn4pcXp",
        net_leverage: 0.0,
        open_ms: 1711439834612,
        orders: [
          {
            leverage: -19.0,
            order_type: "SHORT",
            order_uuid: "acc2a2c4-0c91-4dff-9e61-7db92ff23ed8",
            price: 70559.09,
            price_sources: [],
            processed_ms: 1711439834610,
          },
          {
            leverage: 0.5,
            order_type: "FLAT",
            order_uuid: "238ff8b5-4913-481a-ab9d-3efc9bb34c69",
            price: 70512.44,
            price_sources: [],
            processed_ms: 1711440098964,
          },
        ],
        position_type: "FLAT",
        position_uuid: "94933179-b38e-46fe-ab39-db47f301bfbd",
        return_at_close: 0.9740844628239945,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 70653.49,
        close_ms: 1711441092499,
        current_return: 1.0359032512052824,
        initial_entry_price: 70653.49,
        is_closed_position: true,
        miner_hotkey: "5G43ARm9LSU2aF5send9PvwMEr7P1fRFC1dVujv9Znn4pcXp",
        net_leverage: 0.0,
        open_ms: 1711440187594,
        orders: [
          {
            leverage: 19.0,
            order_type: "LONG",
            order_uuid: "7d940e37-bd5f-42f7-af1f-45f46233bd98",
            price: 70653.49,
            price_sources: [],
            processed_ms: 1711440187593,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "8928c8e5-f611-4fd7-8286-81eeb63fd9bd",
            price: 70787.0,
            price_sources: [],
            processed_ms: 1711441092499,
          },
        ],
        position_type: "FLAT",
        position_uuid: "860ce53b-4c0d-42b0-9418-87b0962ae824",
        return_at_close: 0.9965389276594816,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 3484.89,
        close_ms: 1711776212937,
        current_return: 1.035303840293381,
        initial_entry_price: 3484.89,
        is_closed_position: true,
        miner_hotkey: "5G43ARm9LSU2aF5send9PvwMEr7P1fRFC1dVujv9Znn4pcXp",
        net_leverage: 0.0,
        open_ms: 1711734241632,
        orders: [
          {
            leverage: 3.0,
            order_type: "LONG",
            order_uuid: "da7f74af-aa8f-4156-b2cf-21f41e95b608",
            price: 3484.89,
            price_sources: [],
            processed_ms: 1711734241631,
          },
          {
            leverage: 4.0,
            order_type: "FLAT",
            order_uuid: "d6135bed-1796-4b04-8f95-3396ced9e62c",
            price: 3525.9,
            price_sources: [],
            processed_ms: 1711776212937,
          },
        ],
        position_type: "FLAT",
        position_uuid: "e6bfe04e-dcf7-4f1f-81d5-2b66043c06dd",
        return_at_close: 1.0290920172516207,
        trade_pair: ["ETHUSD", "ETH/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 69886.20666666667,
        close_ms: 1711891827487,
        current_return: 0.9486410080350997,
        initial_entry_price: 69935.66,
        is_closed_position: true,
        miner_hotkey: "5G43ARm9LSU2aF5send9PvwMEr7P1fRFC1dVujv9Znn4pcXp",
        net_leverage: 0.0,
        open_ms: 1711786209330,
        orders: [
          {
            leverage: -1.5,
            order_type: "SHORT",
            order_uuid: "0ae3c4d1-9eed-46e9-9150-0fc9f814696b",
            price: 69935.66,
            price_sources: [],
            processed_ms: 1711786209329,
          },
          {
            leverage: -1.5,
            order_type: "SHORT",
            order_uuid: "929a556b-8f96-49ad-8026-07867c3d8b31",
            price: 69933.29,
            price_sources: [],
            processed_ms: 1711787957864,
          },
          {
            leverage: -1.5,
            order_type: "SHORT",
            order_uuid: "57fb0cee-0037-4a02-9f3a-508dcdcafca7",
            price: 69789.67,
            price_sources: [],
            processed_ms: 1711838937976,
          },
          {
            leverage: 12.0,
            order_type: "FLAT",
            order_uuid: "82363019-5da1-45a2-8d0f-cd493d12e259",
            price: 70684.39,
            price_sources: [],
            processed_ms: 1711891827487,
          },
        ],
        position_type: "FLAT",
        position_uuid: "b98561e6-f3dd-4d03-a673-c7cf09ca9a10",
        return_at_close: 0.9401032389627837,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 70612.9125,
        close_ms: 1711910394519,
        current_return: 0.9317113356875137,
        initial_entry_price: 70634.71,
        is_closed_position: true,
        miner_hotkey: "5G43ARm9LSU2aF5send9PvwMEr7P1fRFC1dVujv9Znn4pcXp",
        net_leverage: -20.0,
        open_ms: 1711891953984,
        orders: [
          {
            leverage: -15.0,
            order_type: "SHORT",
            order_uuid: "5a674c29-bed5-4475-b246-e6cbd4b350e9",
            price: 70634.71,
            price_sources: [],
            processed_ms: 1711891953983,
          },
          {
            leverage: -5.0,
            order_type: "SHORT",
            order_uuid: "51f03d99-0809-4cdb-b706-788dd15f3f59",
            price: 70547.52,
            price_sources: [],
            processed_ms: 1711894157265,
          },
        ],
        position_type: "FLAT",
        position_uuid: "d9ae2ea0-2ad7-40a2-aae8-6f36f173c823",
        return_at_close: 0.8944428822600131,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
    ],
    thirty_day_returns: 1.0,
  },
  "5G6oRVwVvda9cE9B61wgHJAVV3CbVqqNpzRFdZizBEqeTLUz": {
    all_time_returns: 1.0,
    n_positions: 0,
    percentage_profitable: 0.0,
    positions: [
      {
        average_entry_price: 3450.1,
        close_ms: 0,
        current_return: 0.9971862699631895,
        initial_entry_price: 3450.1,
        is_closed_position: false,
        miner_hotkey: "5G6oRVwVvda9cE9B61wgHJAVV3CbVqqNpzRFdZizBEqeTLUz",
        net_leverage: 0.031,
        open_ms: 1719573951155,
        orders: [
          {
            leverage: 0.031,
            order_type: "LONG",
            order_uuid: "0906b451-97d4-4b14-95cd-92f118746b11",
            price: 3450.1,
            price_sources: [],
            processed_ms: 1719573950722,
          },
        ],
        position_type: "LONG",
        position_uuid: "4b91c9bc-082c-449a-8870-6ad8f2d2f819",
        return_at_close: 0.9971708135760051,
        trade_pair: ["ETHUSD", "ETH/USD", 0.003, 0.001, 20],
      },
    ],
    thirty_day_returns: 1.0,
  },
  "5G72RWDzakKcYTvDzzjpftDDTLFDzjEa7F8HFrnEfg6fRSj8": {
    all_time_returns: 1.0,
    n_positions: 0,
    percentage_profitable: 0.0,
    positions: [
      {
        average_entry_price: 3491.7300000000005,
        close_ms: 1711353681268,
        current_return: 1.1415630647272277,
        initial_entry_price: 3491.73,
        is_closed_position: true,
        miner_hotkey: "5G72RWDzakKcYTvDzzjpftDDTLFDzjEa7F8HFrnEfg6fRSj8",
        net_leverage: 0.0,
        open_ms: 1711343488678,
        orders: [
          {
            leverage: -10.0,
            order_type: "SHORT",
            order_uuid: "304e3aaf-d021-499d-ba4c-051e3ef86540",
            price: 3491.73,
            price_sources: [],
            processed_ms: 1711343488677,
          },
          {
            leverage: 10.0,
            order_type: "FLAT",
            order_uuid: "7f6b7cb1-483a-42f5-8d12-d4769fb1dcae",
            price: 3442.3,
            price_sources: [],
            processed_ms: 1711353681268,
          },
        ],
        position_type: "FLAT",
        position_uuid: "0001ca22-f45a-4d0f-8aea-7f33d6ae0bf0",
        return_at_close: 1.1187318034326832,
        trade_pair: ["ETHUSD", "ETH/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 3449.4799999999996,
        close_ms: 1711354332020,
        current_return: 1.0000127845356401,
        initial_entry_price: 3449.48,
        is_closed_position: true,
        miner_hotkey: "5G72RWDzakKcYTvDzzjpftDDTLFDzjEa7F8HFrnEfg6fRSj8",
        net_leverage: 0.0,
        open_ms: 1711354004955,
        orders: [
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "907acfb3-533b-4fe8-864e-ed32074a81b2",
            price: 3449.48,
            price_sources: [],
            processed_ms: 1711354004955,
          },
          {
            leverage: 0.01,
            order_type: "FLAT",
            order_uuid: "f0bed579-ad88-41e9-8efa-a4cd32a98389",
            price: 3453.89,
            price_sources: [],
            processed_ms: 1711354332020,
          },
        ],
        position_type: "FLAT",
        position_uuid: "e1fb3595-6568-4d36-8488-a2686c63adeb",
        return_at_close: 0.9999927842799494,
        trade_pair: ["ETHUSD", "ETH/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 66919.5,
        close_ms: 1711354420460,
        current_return: 1.0000293621440686,
        initial_entry_price: 66919.5,
        is_closed_position: true,
        miner_hotkey: "5G72RWDzakKcYTvDzzjpftDDTLFDzjEa7F8HFrnEfg6fRSj8",
        net_leverage: 0.0,
        open_ms: 1711353768362,
        orders: [
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "cc6906f0-27c4-4da4-9e36-f15d13e08d38",
            price: 66919.5,
            price_sources: [],
            processed_ms: 1711353768362,
          },
          {
            leverage: 0.01,
            order_type: "FLAT",
            order_uuid: "8c2b1b7f-d199-4c9a-b09c-91f633035125",
            price: 67115.99,
            price_sources: [],
            processed_ms: 1711354420460,
          },
        ],
        position_type: "FLAT",
        position_uuid: "bed49ca5-5d83-46a6-a32b-5f7466f60262",
        return_at_close: 1.0000093615568257,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 151.345,
        close_ms: 1711366043552,
        current_return: 0.9999983488057088,
        initial_entry_price: 151.345,
        is_closed_position: true,
        miner_hotkey: "5G72RWDzakKcYTvDzzjpftDDTLFDzjEa7F8HFrnEfg6fRSj8",
        net_leverage: 0.0,
        open_ms: 1711365061657,
        orders: [
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "37440628-c6ba-4cc8-86cc-c7dd4b7b458a",
            price: 151.345,
            price_sources: [],
            processed_ms: 1711365061656,
          },
          {
            leverage: 0.01,
            order_type: "FLAT",
            order_uuid: "bcef1237-1aeb-4c24-b59c-468852302c66",
            price: 151.32001,
            price_sources: [],
            processed_ms: 1711366043552,
          },
        ],
        position_type: "FLAT",
        position_uuid: "ab52bdfb-a50a-486a-bc65-97fc0553af92",
        return_at_close: 0.9999976488068647,
        trade_pair: ["USDJPY", "USD/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 7895.02979,
        close_ms: 1711440521427,
        current_return: 0.9508504704451436,
        initial_entry_price: 7895.02979,
        is_closed_position: true,
        miner_hotkey: "5G72RWDzakKcYTvDzzjpftDDTLFDzjEa7F8HFrnEfg6fRSj8",
        net_leverage: 0.0,
        open_ms: 1711440083826,
        orders: [
          {
            leverage: -100.0,
            order_type: "SHORT",
            order_uuid: "584842cc-b517-4167-84b1-748c1faab0ca",
            price: 7895.02979,
            price_sources: [],
            processed_ms: 1711440083825,
          },
          {
            leverage: 100.0,
            order_type: "FLAT",
            order_uuid: "8de93c86-31b3-43ac-8f23-4ba1766e2089",
            price: 7898.91016,
            price_sources: [],
            processed_ms: 1711440521427,
          },
        ],
        position_type: "FLAT",
        position_uuid: "1e3d3c23-5b1c-457e-92c6-a7e42fb0ee2e",
        return_at_close: 0.9422928162111373,
        trade_pair: ["FTSE", "FTSE", 9e-5, 0.001, 500],
      },
      {
        average_entry_price: 7925.4502,
        close_ms: 1711527564081,
        current_return: 0.9179857315865791,
        initial_entry_price: 7925.4502,
        is_closed_position: true,
        miner_hotkey: "5G72RWDzakKcYTvDzzjpftDDTLFDzjEa7F8HFrnEfg6fRSj8",
        net_leverage: 0.0,
        open_ms: 1711527122676,
        orders: [
          {
            leverage: 100.0,
            order_type: "LONG",
            order_uuid: "79d8d3c0-38ae-4773-a063-4c2c3eb4fd85",
            price: 7925.4502,
            price_sources: [],
            processed_ms: 1711527122675,
          },
          {
            leverage: 100.0,
            order_type: "FLAT",
            order_uuid: "07fd9fbb-ccca-49a4-b8ad-4cc8e924a4c7",
            price: 7918.9502,
            price_sources: [],
            processed_ms: 1711527564081,
          },
        ],
        position_type: "FLAT",
        position_uuid: "5dd98db3-fe3e-4ff1-8787-7d7bbb707da5",
        return_at_close: 0.9097238600022999,
        trade_pair: ["FTSE", "FTSE", 9e-5, 0.001, 500],
      },
      {
        average_entry_price: 7931.25228503006,
        close_ms: 1711614551854,
        current_return: 2.4877964731581743,
        initial_entry_price: 7931.2998,
        is_closed_position: true,
        miner_hotkey: "5G72RWDzakKcYTvDzzjpftDDTLFDzjEa7F8HFrnEfg6fRSj8",
        net_leverage: 0.0,
        open_ms: 1711612780955,
        orders: [
          {
            leverage: 500.0,
            order_type: "LONG",
            order_uuid: "50b2c85e-54f5-4d22-a30b-1ff7558586fe",
            price: 7931.2998,
            price_sources: [],
            processed_ms: 1711612780954,
          },
          {
            leverage: -1.0,
            order_type: "SHORT",
            order_uuid: "4e8287a7-7285-479d-bd26-e047d9c932a1",
            price: 7955.00977,
            price_sources: [],
            processed_ms: 1711614369428,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "2acc5653-edca-408d-86da-aedb093f7d74",
            price: 7954.8999,
            price_sources: [],
            processed_ms: 1711614551854,
          },
        ],
        position_type: "FLAT",
        position_uuid: "6a5201e6-b95a-4532-b26c-fbab08ddc701",
        return_at_close: 2.3758456318660564,
        trade_pair: ["FTSE", "FTSE", 9e-5, 0.001, 500],
      },
      {
        average_entry_price: 39759.23828,
        close_ms: 1711632876874,
        current_return: 0.990420867791332,
        initial_entry_price: 39759.23828,
        is_closed_position: true,
        miner_hotkey: "5G72RWDzakKcYTvDzzjpftDDTLFDzjEa7F8HFrnEfg6fRSj8",
        net_leverage: 0.0,
        open_ms: 1711632688584,
        orders: [
          {
            leverage: 100.0,
            order_type: "LONG",
            order_uuid: "ba9e1947-acbd-4e86-b190-397b26cc7698",
            price: 39759.23828,
            price_sources: [],
            processed_ms: 1711632688583,
          },
          {
            leverage: 100.0,
            order_type: "FLAT",
            order_uuid: "598e621d-e79e-42db-884c-941fab91a3c1",
            price: 39755.42969,
            price_sources: [],
            processed_ms: 1711632876874,
          },
        ],
        position_type: "FLAT",
        position_uuid: "e17a9578-d50b-4b86-966b-8af2b8f48f51",
        return_at_close: 0.98150707998121,
        trade_pair: ["DJI", "DJI", 9e-5, 0.001, 500],
      },
      {
        average_entry_price: 151.375,
        close_ms: 1711782043773,
        current_return: 1.0,
        initial_entry_price: 151.375,
        is_closed_position: true,
        miner_hotkey: "5G72RWDzakKcYTvDzzjpftDDTLFDzjEa7F8HFrnEfg6fRSj8",
        net_leverage: 0.0,
        open_ms: 1711780432717,
        orders: [
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "f9dc917c-cfd4-4c21-9e16-d795711e4a6f",
            price: 151.375,
            price_sources: [],
            processed_ms: 1711780432715,
          },
          {
            leverage: 0.01,
            order_type: "FLAT",
            order_uuid: "7fedfe4d-69f9-4a7d-965b-8c1c0736d50f",
            price: 151.375,
            price_sources: [],
            processed_ms: 1711782043773,
          },
        ],
        position_type: "FLAT",
        position_uuid: "956849d4-0e9d-4f5d-b196-8867d47354ec",
        return_at_close: 0.9999993,
        trade_pair: ["USDJPY", "USD/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.666,
        close_ms: 1711782269606,
        current_return: 1.0,
        initial_entry_price: 0.666,
        is_closed_position: true,
        miner_hotkey: "5G72RWDzakKcYTvDzzjpftDDTLFDzjEa7F8HFrnEfg6fRSj8",
        net_leverage: 0.0,
        open_ms: 1711782183727,
        orders: [
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "cd75c69b-2846-45fb-bee1-d2a5d1fbe2ff",
            price: 0.666,
            price_sources: [],
            processed_ms: 1711782183726,
          },
          {
            leverage: 0.01,
            order_type: "FLAT",
            order_uuid: "4b934255-bab3-4d43-b3a6-4a4c5257fddc",
            price: 0.666,
            price_sources: [],
            processed_ms: 1711782269606,
          },
        ],
        position_type: "FLAT",
        position_uuid: "bc8a6e31-211f-4f6f-889c-5c9032701419",
        return_at_close: 0.9999993,
        trade_pair: ["CADCHF", "CAD/CHF", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.9737499999999999,
        close_ms: 1711782478736,
        current_return: 1.0,
        initial_entry_price: 0.97375,
        is_closed_position: true,
        miner_hotkey: "5G72RWDzakKcYTvDzzjpftDDTLFDzjEa7F8HFrnEfg6fRSj8",
        net_leverage: 0.0,
        open_ms: 1711782368732,
        orders: [
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "8c3d7783-8e45-4327-a3df-73b6983dde80",
            price: 0.97375,
            price_sources: [],
            processed_ms: 1711782368731,
          },
          {
            leverage: 0.01,
            order_type: "FLAT",
            order_uuid: "e2f1c83e-19a9-4011-add9-38f73ef7dcb0",
            price: 0.97375,
            price_sources: [],
            processed_ms: 1711782478736,
          },
        ],
        position_type: "FLAT",
        position_uuid: "a03407ca-5532-45e2-8ad9-6c41502413b2",
        return_at_close: 0.9999993,
        trade_pair: ["EURCHF", "EUR/CHF", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 39535.28125,
        close_ms: 1711979511800,
        current_return: 1.1293148761904912,
        initial_entry_price: 39535.28125,
        is_closed_position: true,
        miner_hotkey: "5G72RWDzakKcYTvDzzjpftDDTLFDzjEa7F8HFrnEfg6fRSj8",
        net_leverage: 0.0,
        open_ms: 1711979141349,
        orders: [
          {
            leverage: -400.0,
            order_type: "SHORT",
            order_uuid: "fc26db6a-40a0-4edb-b0ac-c5fc0864f766",
            price: 39535.28125,
            price_sources: [],
            processed_ms: 1711979141348,
          },
          {
            leverage: 400.0,
            order_type: "FLAT",
            order_uuid: "f5fb0dc4-f108-4eee-8b89-8c81dafe8da1",
            price: 39522.5,
            price_sources: [],
            processed_ms: 1711979511800,
          },
        ],
        position_type: "FLAT",
        position_uuid: "b38daf61-cae0-4ba6-88f9-9d468bc7552d",
        return_at_close: 1.0886595406476334,
        trade_pair: ["DJI", "DJI", 9e-5, 0.001, 500],
      },
      {
        average_entry_price: 5236.217467272727,
        close_ms: 1711981312679,
        current_return: 0.9810381202803212,
        initial_entry_price: 5236.39014,
        is_closed_position: true,
        miner_hotkey: "5G72RWDzakKcYTvDzzjpftDDTLFDzjEa7F8HFrnEfg6fRSj8",
        net_leverage: 0.0,
        open_ms: 1711979984022,
        orders: [
          {
            leverage: -10.0,
            order_type: "SHORT",
            order_uuid: "f5c2c0f0-e591-486f-9ea3-5517dfcb9f5f",
            price: 5236.39014,
            price_sources: [],
            processed_ms: 1711979984021,
          },
          {
            leverage: -100.0,
            order_type: "SHORT",
            order_uuid: "0a6accd0-2390-498a-8d7c-36d368f6f974",
            price: 5236.2002,
            price_sources: [],
            processed_ms: 1711980203907,
          },
          {
            leverage: 100.0,
            order_type: "FLAT",
            order_uuid: "d72e1580-4530-4a8d-aa4e-b0a5f8a9babb",
            price: 5237.12012,
            price_sources: [],
            processed_ms: 1711981312679,
          },
        ],
        position_type: "FLAT",
        position_uuid: "f881c0f0-7a96-4ac7-9e14-549ccb2ee1cd",
        return_at_close: 0.971325842889546,
        trade_pair: ["SPX", "SPX", 9e-5, 0.001, 500],
      },
    ],
    thirty_day_returns: 1.0,
  },
  "5G9yGe6TEDxx7wD2mpM2XSu8P7gVt6wwUvuXRrHGAYJDA955": {
    all_time_returns: 1.0083938197923077,
    n_positions: 5,
    percentage_profitable: 0.8,
    positions: [
      {
        average_entry_price: 2391.839999999977,
        close_ms: 1719779143946,
        current_return: 1.002936293277404,
        initial_entry_price: 3523.49,
        is_closed_position: true,
        miner_hotkey: "5G9yGe6TEDxx7wD2mpM2XSu8P7gVt6wwUvuXRrHGAYJDA955",
        net_leverage: 0.0,
        open_ms: 1718998249772,
        orders: [
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "fd5b7290-36a3-4571-b3e0-c51e5d48b148",
            price: 3523.49,
            price_sources: [],
            processed_ms: 1718998248897,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "cebfdcf1-765f-44fe-965f-2935c0d6ea38",
            price: 3350.04,
            price_sources: [],
            processed_ms: 1719266590511,
          },
          {
            leverage: -0.49,
            order_type: "SHORT",
            order_uuid: "9d6365e0-6165-4690-b4cc-f96db482925d",
            price: 3458.09,
            price_sources: [],
            processed_ms: 1719511178451,
          },
          {
            leverage: 0.01,
            order_type: "FLAT",
            order_uuid: "4040b4fc-cf5e-48bb-be12-b83ef297cacb",
            price: 3426.44,
            price_sources: [],
            processed_ms: 1719779143946,
          },
        ],
        position_type: "FLAT",
        position_uuid: "fd5b7290-36a3-4571-b3e0-c51e5d48b148",
        return_at_close: 1.0024348251307653,
        trade_pair: ["ETHUSD", "ETH/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 3304.5,
        close_ms: 1720153350591,
        current_return: 0.9662180360114995,
        initial_entry_price: 3304.5,
        is_closed_position: true,
        miner_hotkey: "5G9yGe6TEDxx7wD2mpM2XSu8P7gVt6wwUvuXRrHGAYJDA955",
        net_leverage: 0.0,
        open_ms: 1720014219029,
        orders: [
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "aa55159b-f13a-4b08-968d-f3ff4d570a07",
            price: 3304.5,
            price_sources: [],
            processed_ms: 1720014218276,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "4aa35985-28f7-42e1-89d9-b549a700c96b",
            price: 2857.97,
            price_sources: [
              {
                close: 2857.97,
                high: 2859.22,
                lag_ms: 408,
                low: 2857.87,
                open: 2857.97,
                source: "Polygon_ws",
                start_ms: 1720153350999,
                timespan_ms: 0,
                volume: 10.66483852,
                vwap: 2858.5169,
                websocket: true,
              },
              {
                close: 2857.64,
                high: 2857.64,
                lag_ms: 2591,
                low: 2857.64,
                open: 2857.64,
                source: "TwelveData_ws",
                start_ms: 1720153348000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
              {
                close: 2856.53,
                high: 2857.4,
                lag_ms: 2592,
                low: 2852.94,
                open: 2855.41,
                source: "Polygon_rest",
                start_ms: 1720153347000,
                timespan_ms: 1000,
                volume: 13.49060489,
                vwap: 2855.5618,
                websocket: false,
              },
              {
                close: 2840.55005,
                high: 2869.8501,
                lag_ms: 150592,
                low: 2838.03003,
                open: 2869.06006,
                source: "TwelveData_rest",
                start_ms: 1720153140000,
                timespan_ms: 60000,
                volume: null,
                vwap: null,
                websocket: false,
              },
            ],
            processed_ms: 1720153350591,
          },
        ],
        position_type: "FLAT",
        position_uuid: "aa55159b-f13a-4b08-968d-f3ff4d570a07",
        return_at_close: 0.9659764815024966,
        trade_pair: ["ETHUSD", "ETH/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 0.9706170000000001,
        close_ms: 1720386008612,
        current_return: 1.0081078706048985,
        initial_entry_price: 0.96727,
        is_closed_position: true,
        miner_hotkey: "5G9yGe6TEDxx7wD2mpM2XSu8P7gVt6wwUvuXRrHGAYJDA955",
        net_leverage: 0.0,
        open_ms: 1719811557601,
        orders: [
          {
            leverage: -0.5,
            order_type: "SHORT",
            order_uuid: "21af1bc5-cd51-4ae1-89d2-f5ed034cc832",
            price: 0.96727,
            price_sources: [],
            processed_ms: 1719811557586,
          },
          {
            leverage: -0.5,
            order_type: "SHORT",
            order_uuid: "be3c4e69-7013-406d-bf84-e8b11e6db4cd",
            price: 0.969885,
            price_sources: [],
            processed_ms: 1719839192132,
          },
          {
            leverage: -0.5,
            order_type: "SHORT",
            order_uuid: "17f5f778-58aa-41f9-bb6b-4d4cc73875cc",
            price: 0.97106,
            price_sources: [],
            processed_ms: 1719941731933,
          },
          {
            leverage: -0.5,
            order_type: "SHORT",
            order_uuid: "46ba159c-a715-4082-b83b-a3f0adbaec80",
            price: 0.97269,
            price_sources: [],
            processed_ms: 1720010321656,
          },
          {
            leverage: -0.5,
            order_type: "SHORT",
            order_uuid: "c8179dfd-b85f-451f-a331-e9e4a20412a1",
            price: 0.97218,
            price_sources: [
              {
                close: 0.97218,
                high: 0.97218,
                lag_ms: 384,
                low: 0.97218,
                open: 0.97218,
                source: "Polygon_ws",
                start_ms: 1720051247000,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 0.97218,
                websocket: true,
              },
              {
                close: 0.97232,
                high: 0.97232,
                lag_ms: 1616,
                low: 0.97232,
                open: 0.97232,
                source: "TwelveData_ws",
                start_ms: 1720051249000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1720051247384,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "20a6840b-a822-4b09-8bda-b22555316a14",
            price: 0.96748,
            price_sources: [
              {
                close: 0.96748,
                high: 0.96748,
                lag_ms: 7613,
                low: 0.96748,
                open: 0.96748,
                source: "Polygon_ws",
                start_ms: 1720386000999,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 0.96748,
                websocket: true,
              },
              {
                close: 0.96748,
                high: 0.96748,
                lag_ms: 7613,
                low: 0.96748,
                open: 0.96748,
                source: "Polygon_rest",
                start_ms: 1720386000000,
                timespan_ms: 1000,
                volume: 1.0,
                vwap: null,
                websocket: false,
              },
              {
                close: 0.96768,
                high: 0.96768,
                lag_ms: 18388,
                low: 0.96768,
                open: 0.96768,
                source: "TwelveData_ws",
                start_ms: 1720386027000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
              {
                close: 0.97086,
                high: 0.97086,
                lag_ms: 172808613,
                low: 0.9708,
                open: 0.9708,
                source: "TwelveData_rest",
                start_ms: 1720213140000,
                timespan_ms: 60000,
                volume: null,
                vwap: null,
                websocket: false,
              },
            ],
            processed_ms: 1720386008612,
          },
        ],
        position_type: "FLAT",
        position_uuid: "21af1bc5-cd51-4ae1-89d2-f5ed034cc832",
        return_at_close: 1.0079314517275426,
        trade_pair: ["EURCHF", "EUR/CHF", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 1.0827,
        close_ms: 1720539198701,
        current_return: 1.0087197698346726,
        initial_entry_price: 1.0827,
        is_closed_position: true,
        miner_hotkey: "5G9yGe6TEDxx7wD2mpM2XSu8P7gVt6wwUvuXRrHGAYJDA955",
        net_leverage: 0.0,
        open_ms: 1720392143007,
        orders: [
          {
            leverage: -4.968892,
            order_type: "SHORT",
            order_uuid: "1f4f9bbc-cef1-46cd-836c-9d73df8c9e61",
            price: 1.0827,
            price_sources: [
              {
                close: 1.0827,
                high: 1.0827,
                lag_ms: 36,
                low: 1.0827,
                open: 1.0827,
                source: "Polygon_ws",
                start_ms: 1720392143000,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 1.08271,
                websocket: true,
              },
              {
                close: 1.0827,
                high: 1.0827,
                lag_ms: 1964,
                low: 1.0827,
                open: 1.0827,
                source: "TwelveData_ws",
                start_ms: 1720392141000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1720392142964,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "2f526c0c-20c7-406f-9ac6-12fbf204f663",
            price: 1.0808,
            price_sources: [
              {
                close: 1.0808,
                high: 1.0808,
                lag_ms: 298,
                low: 1.0808,
                open: 1.0808,
                source: "Polygon_ws",
                start_ms: 1720539198999,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 1.0808,
                websocket: true,
              },
              {
                close: 1.0809,
                high: 1.0809,
                lag_ms: 299,
                low: 1.0809,
                open: 1.0809,
                source: "TwelveData_ws",
                start_ms: 1720539199000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1720539198701,
          },
        ],
        position_type: "FLAT",
        position_uuid: "1f4f9bbc-cef1-46cd-836c-9d73df8c9e61",
        return_at_close: 1.0083689144630525,
        trade_pair: ["EURUSD", "EUR/USD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.60716,
        close_ms: 1720620693353,
        current_return: 1.0253134254562224,
        initial_entry_price: 0.60716,
        is_closed_position: true,
        miner_hotkey: "5G9yGe6TEDxx7wD2mpM2XSu8P7gVt6wwUvuXRrHGAYJDA955",
        net_leverage: 0.0,
        open_ms: 1720608105088,
        orders: [
          {
            leverage: 9.852115,
            order_type: "LONG",
            order_uuid: "73e83d9c-b316-4996-b28f-50a6bb19c4f6",
            price: 0.60716,
            price_sources: [
              {
                close: 0.60716,
                high: 0.60716,
                lag_ms: 431,
                low: 0.60716,
                open: 0.60716,
                source: "Polygon_ws",
                start_ms: 1720608104999,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 0.60716,
                websocket: true,
              },
              {
                close: 0.60714,
                high: 0.60714,
                lag_ms: 432,
                low: 0.60714,
                open: 0.60714,
                source: "TwelveData_ws",
                start_ms: 1720608105000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1720608104568,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "b6403e9f-6555-43dd-8e5d-2c6ff6f9f42f",
            price: 0.60872,
            price_sources: [
              {
                close: 0.60872,
                high: 0.60872,
                lag_ms: 353,
                low: 0.60872,
                open: 0.60872,
                source: "Polygon_ws",
                start_ms: 1720620693000,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 0.60872,
                websocket: true,
              },
              {
                close: 0.60874,
                high: 0.60874,
                lag_ms: 647,
                low: 0.60874,
                open: 0.60874,
                source: "TwelveData_ws",
                start_ms: 1720620694000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1720620693353,
          },
        ],
        position_type: "FLAT",
        position_uuid: "73e83d9c-b316-4996-b28f-50a6bb19c4f6",
        return_at_close: 1.0246063200517177,
        trade_pair: ["NZDUSD", "NZD/USD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.828052853466684,
        close_ms: 0,
        current_return: 0.999514379899917,
        initial_entry_price: 0.828052853466684,
        is_closed_position: false,
        miner_hotkey: "5G9yGe6TEDxx7wD2mpM2XSu8P7gVt6wwUvuXRrHGAYJDA955",
        net_leverage: 4.853377,
        open_ms: 1720628459348,
        orders: [
          {
            leverage: 4.853377,
            order_type: "LONG",
            order_uuid: "55e083e6-11fc-46c7-93e2-3bc2ebc39e13",
            price: 0.828052853466684,
            price_sources: [
              {
                close: 0.828052853466684,
                high: 0.828052853466684,
                lag_ms: 125,
                low: 0.828052853466684,
                open: 0.828052853466684,
                source: "Polygon_ws",
                start_ms: 1720628458999,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 0.828052853466684,
                websocket: true,
              },
              {
                close: 0.82801,
                high: 0.82801,
                lag_ms: 126,
                low: 0.82801,
                open: 0.82801,
                source: "TwelveData_ws",
                start_ms: 1720628459000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1720628458874,
          },
        ],
        position_type: "LONG",
        position_uuid: "55e083e6-11fc-46c7-93e2-3bc2ebc39e13",
        return_at_close: 0.9993445941963268,
        trade_pair: ["NZDCAD", "NZD/CAD", 7e-5, 0.001, 500],
      },
    ],
    thirty_day_returns: 1.0083938197923077,
  },
  "5GBYYSHa8VofFPc2z6kygY7dzp5XJk6b1eLTdbFF1LaouD1Y": {
    all_time_returns: 1.0,
    n_positions: 0,
    percentage_profitable: 0.0,
    positions: [
      {
        average_entry_price: 64474.93,
        close_ms: 1711196808005,
        current_return: 1.0010680895659756,
        initial_entry_price: 64474.93,
        is_closed_position: true,
        miner_hotkey: "5GBYYSHa8VofFPc2z6kygY7dzp5XJk6b1eLTdbFF1LaouD1Y",
        net_leverage: 0.0,
        open_ms: 1711193235221,
        orders: [
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "83fe77a2-8ee7-43c7-b7d2-fe98fea0cd0a",
            price: 64474.93,
            price_sources: [],
            processed_ms: 1711193235221,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "5d0eea81-b786-4f2f-8a52-572796885e35",
            price: 64704.48,
            price_sources: [],
            processed_ms: 1711196808005,
          },
        ],
        position_type: "FLAT",
        position_uuid: "2e8c2d7c-7fcd-41a1-8ebb-5f5292091a46",
        return_at_close: 1.000467448712236,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 65447.28999999999,
        close_ms: 1711211551619,
        current_return: 1.0,
        initial_entry_price: 65447.29,
        is_closed_position: true,
        miner_hotkey: "5GBYYSHa8VofFPc2z6kygY7dzp5XJk6b1eLTdbFF1LaouD1Y",
        net_leverage: 0.0,
        open_ms: 1711211531824,
        orders: [
          {
            leverage: -0.3,
            order_type: "SHORT",
            order_uuid: "447e14f9-8420-494b-9e4f-809a89ad4c5f",
            price: 65447.29,
            price_sources: [],
            processed_ms: 1711211531823,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "6dcfcccf-d34d-49f4-b466-668777d6a66f",
            price: 65447.29,
            price_sources: [],
            processed_ms: 1711211551619,
          },
        ],
        position_type: "FLAT",
        position_uuid: "6e6b70cb-2404-4098-9da2-74cffb80836f",
        return_at_close: 0.9994,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 65897.21,
        close_ms: 1711218784396,
        current_return: 1.0015290176928584,
        initial_entry_price: 65897.21,
        is_closed_position: true,
        miner_hotkey: "5GBYYSHa8VofFPc2z6kygY7dzp5XJk6b1eLTdbFF1LaouD1Y",
        net_leverage: 0.0,
        open_ms: 1711215205846,
        orders: [
          {
            leverage: -0.3,
            order_type: "SHORT",
            order_uuid: "91b85cdc-f11b-4a03-894e-66ebbc3293c5",
            price: 65897.21,
            price_sources: [],
            processed_ms: 1711215205845,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "98374f27-6b41-4cba-ae04-c7c22e7c4f7a",
            price: 65561.35,
            price_sources: [],
            processed_ms: 1711218784396,
          },
        ],
        position_type: "FLAT",
        position_uuid: "5e584727-26fc-4665-be78-6d2f788c529c",
        return_at_close: 1.0009281002822425,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 65479.00000000001,
        close_ms: 1711222449129,
        current_return: 1.001999602926129,
        initial_entry_price: 65479.0,
        is_closed_position: true,
        miner_hotkey: "5GBYYSHa8VofFPc2z6kygY7dzp5XJk6b1eLTdbFF1LaouD1Y",
        net_leverage: 0.0,
        open_ms: 1711218916266,
        orders: [
          {
            leverage: -0.3,
            order_type: "SHORT",
            order_uuid: "15e71bac-9f2a-47ae-a8bf-1974b55597b5",
            price: 65479.0,
            price_sources: [],
            processed_ms: 1711218916265,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "987dc07f-3a10-4e9c-af52-4d31756eb599",
            price: 65042.56,
            price_sources: [],
            processed_ms: 1711222449129,
          },
        ],
        position_type: "FLAT",
        position_uuid: "f08a389d-4784-4a2b-9ba6-d39989f52315",
        return_at_close: 1.0013984031643735,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 65105.56,
        close_ms: 1711226163105,
        current_return: 1.0005660806849677,
        initial_entry_price: 65105.56,
        is_closed_position: true,
        miner_hotkey: "5GBYYSHa8VofFPc2z6kygY7dzp5XJk6b1eLTdbFF1LaouD1Y",
        net_leverage: 0.0,
        open_ms: 1711222591367,
        orders: [
          {
            leverage: -0.3,
            order_type: "SHORT",
            order_uuid: "9811deb3-8ce2-43a9-a257-44fc1b69c26f",
            price: 65105.56,
            price_sources: [],
            processed_ms: 1711222591365,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "b818d166-66f5-4559-9f6d-46e62f025df2",
            price: 64982.71,
            price_sources: [],
            processed_ms: 1711226163105,
          },
        ],
        position_type: "FLAT",
        position_uuid: "7184ab67-680f-4a45-a7f4-f7ba86d9ccde",
        return_at_close: 0.9999657410365567,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 64998.41,
        close_ms: 1711229792370,
        current_return: 1.0002308210308528,
        initial_entry_price: 64998.41,
        is_closed_position: true,
        miner_hotkey: "5GBYYSHa8VofFPc2z6kygY7dzp5XJk6b1eLTdbFF1LaouD1Y",
        net_leverage: 0.0,
        open_ms: 1711226185038,
        orders: [
          {
            leverage: -0.3,
            order_type: "SHORT",
            order_uuid: "14c2c75d-2e65-4d4e-9420-f7a9f547f261",
            price: 64998.41,
            price_sources: [],
            processed_ms: 1711226185037,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "cdb0789c-0c76-4731-bbe6-6abab319d6f4",
            price: 64948.4,
            price_sources: [],
            processed_ms: 1711229792370,
          },
        ],
        position_type: "FLAT",
        position_uuid: "a7ed21bf-3eac-4027-bcf7-e8e775f7b3ca",
        return_at_close: 0.9996306825382343,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 64920.625,
        close_ms: 1711233506872,
        current_return: 0.9990848093618814,
        initial_entry_price: 64855.34,
        is_closed_position: true,
        miner_hotkey: "5GBYYSHa8VofFPc2z6kygY7dzp5XJk6b1eLTdbFF1LaouD1Y",
        net_leverage: 0.0,
        open_ms: 1711229897126,
        orders: [
          {
            leverage: -0.3,
            order_type: "SHORT",
            order_uuid: "ce3faf67-ca48-4107-8c9a-e8ab4cb4779f",
            price: 64855.34,
            price_sources: [],
            processed_ms: 1711229896687,
          },
          {
            leverage: -0.3,
            order_type: "SHORT",
            order_uuid: "9eeb3268-abdf-4d0f-9842-9c2c3727540a",
            price: 64985.91,
            price_sources: [],
            processed_ms: 1711233485881,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "9e310411-0b97-47d7-b083-89e6a384b280",
            price: 65019.55,
            price_sources: [],
            processed_ms: 1711233506872,
          },
        ],
        position_type: "FLAT",
        position_uuid: "53e558d7-721b-4458-b42a-3cd4e952ecad",
        return_at_close: 0.9978859075906471,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 64464.33,
        close_ms: 1711244429029,
        current_return: 1.000181402645463,
        initial_entry_price: 64464.33,
        is_closed_position: true,
        miner_hotkey: "5GBYYSHa8VofFPc2z6kygY7dzp5XJk6b1eLTdbFF1LaouD1Y",
        net_leverage: 0.0,
        open_ms: 1711240851016,
        orders: [
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "3660c146-e1d9-4525-af92-75653ecd2592",
            price: 64464.33,
            price_sources: [],
            processed_ms: 1711240851015,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "f75dbde1-6109-4f6c-96a5-ebf29c8f22ee",
            price: 64503.31,
            price_sources: [],
            processed_ms: 1711244429029,
          },
        ],
        position_type: "FLAT",
        position_uuid: "36e3d344-8522-4922-ae17-6555dd11556e",
        return_at_close: 0.9995812938038756,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 64371.579999999994,
        close_ms: 1711251754881,
        current_return: 0.9988268735985664,
        initial_entry_price: 64371.58,
        is_closed_position: true,
        miner_hotkey: "5GBYYSHa8VofFPc2z6kygY7dzp5XJk6b1eLTdbFF1LaouD1Y",
        net_leverage: 0.0,
        open_ms: 1711248210887,
        orders: [
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "6411cee8-a399-4d87-b959-c75bdaec1fcb",
            price: 64371.58,
            price_sources: [],
            processed_ms: 1711248210886,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "8413bcf5-675c-497c-9e71-ac003d56804e",
            price: 64119.86,
            price_sources: [],
            processed_ms: 1711251754881,
          },
        ],
        position_type: "FLAT",
        position_uuid: "072b71fa-13ec-4654-9d5e-78eea4254591",
        return_at_close: 0.9982275774744072,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 64086.850000000006,
        close_ms: 1711255424369,
        current_return: 0.9997006406150404,
        initial_entry_price: 64086.85,
        is_closed_position: true,
        miner_hotkey: "5GBYYSHa8VofFPc2z6kygY7dzp5XJk6b1eLTdbFF1LaouD1Y",
        net_leverage: 0.0,
        open_ms: 1711251842145,
        orders: [
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "15d2c4dc-8263-4b4b-8d44-5d3809ba2370",
            price: 64086.85,
            price_sources: [],
            processed_ms: 1711251842144,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "f480704a-795c-4bd3-a9b1-5c6b9b7dd7f9",
            price: 64022.9,
            price_sources: [],
            processed_ms: 1711255424369,
          },
        ],
        position_type: "FLAT",
        position_uuid: "40d727ea-7958-4aa4-8cac-3bac89a2b957",
        return_at_close: 0.9991008202306713,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 64077.99999999999,
        close_ms: 1711256626114,
        current_return: 1.0008093885576954,
        initial_entry_price: 64078.0,
        is_closed_position: true,
        miner_hotkey: "5GBYYSHa8VofFPc2z6kygY7dzp5XJk6b1eLTdbFF1LaouD1Y",
        net_leverage: 0.0,
        open_ms: 1711255512996,
        orders: [
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "b8bee920-68dd-4353-bfcb-305766d4a5eb",
            price: 64078.0,
            price_sources: [],
            processed_ms: 1711255512995,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "323e07f7-ae8d-4717-8304-8bfb465d370d",
            price: 64250.88,
            price_sources: [],
            processed_ms: 1711256626114,
          },
        ],
        position_type: "FLAT",
        position_uuid: "abbfcfcc-1e0a-4791-98f3-3086ad9e409e",
        return_at_close: 1.0002089029245607,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 64241.56999999999,
        close_ms: 1711260159060,
        current_return: 1.000341367746772,
        initial_entry_price: 64241.57,
        is_closed_position: true,
        miner_hotkey: "5GBYYSHa8VofFPc2z6kygY7dzp5XJk6b1eLTdbFF1LaouD1Y",
        net_leverage: 0.0,
        open_ms: 1711256712937,
        orders: [
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "44cb1f58-0a62-4406-a72a-2baa6d556319",
            price: 64241.57,
            price_sources: [],
            processed_ms: 1711256712936,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "4d45a386-6d76-4b65-9adb-1d82f4bcbaa7",
            price: 64314.67,
            price_sources: [],
            processed_ms: 1711260159060,
          },
        ],
        position_type: "FLAT",
        position_uuid: "8d41fe26-4e34-422c-8438-8f6acdfe2dee",
        return_at_close: 0.9997411629261239,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 64239.62999999999,
        close_ms: 1711263824934,
        current_return: 0.9984785482108165,
        initial_entry_price: 64239.63,
        is_closed_position: true,
        miner_hotkey: "5GBYYSHa8VofFPc2z6kygY7dzp5XJk6b1eLTdbFF1LaouD1Y",
        net_leverage: 0.0,
        open_ms: 1711260301178,
        orders: [
          {
            leverage: 1.25,
            order_type: "LONG",
            order_uuid: "6da499e8-607e-4179-9120-2a508b30dc45",
            price: 64239.63,
            price_sources: [],
            processed_ms: 1711260301176,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "51b2e41b-5346-48a1-858d-0179cf894b55",
            price: 64161.44,
            price_sources: [],
            processed_ms: 1711263824934,
          },
        ],
        position_type: "FLAT",
        position_uuid: "8427889a-128a-40cf-8382-f4637e3cacee",
        return_at_close: 0.9959823518402895,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 64363.01000000001,
        close_ms: 1711267537543,
        current_return: 1.0089473547981487,
        initial_entry_price: 64133.48,
        is_closed_position: true,
        miner_hotkey: "5GBYYSHa8VofFPc2z6kygY7dzp5XJk6b1eLTdbFF1LaouD1Y",
        net_leverage: 0.0,
        open_ms: 1711263911790,
        orders: [
          {
            leverage: 1.25,
            order_type: "LONG",
            order_uuid: "99dd0fb2-ce8e-48a1-ac48-aea0930ab3e2",
            price: 64133.48,
            price_sources: [],
            processed_ms: 1711263911788,
          },
          {
            leverage: 1.25,
            order_type: "LONG",
            order_uuid: "039ff8fe-176a-4b53-bbe3-39ed429f2298",
            price: 64592.54,
            price_sources: [],
            processed_ms: 1711267517758,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "77c218d9-efba-4cd6-9ede-059a4b564f2f",
            price: 64592.54,
            price_sources: [],
            processed_ms: 1711267537543,
          },
        ],
        position_type: "FLAT",
        position_uuid: "1cffe177-82f6-43b0-8a0d-5238a8e2593a",
        return_at_close: 1.003902618024158,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 65048.17,
        close_ms: 1711274857432,
        current_return: 0.9992586263379892,
        initial_entry_price: 65048.17,
        is_closed_position: true,
        miner_hotkey: "5GBYYSHa8VofFPc2z6kygY7dzp5XJk6b1eLTdbFF1LaouD1Y",
        net_leverage: 0.0,
        open_ms: 1711271233851,
        orders: [
          {
            leverage: 1.25,
            order_type: "LONG",
            order_uuid: "0d83cf73-0c0e-4b55-a7ac-af677b5891d4",
            price: 65048.17,
            price_sources: [],
            processed_ms: 1711271233850,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "d269f93f-d714-4751-a40d-12331331204f",
            price: 65009.59,
            price_sources: [],
            processed_ms: 1711274857432,
          },
        ],
        position_type: "FLAT",
        position_uuid: "b9e3c489-5d8e-4b92-94e4-81b98af6ab12",
        return_at_close: 0.9967604797721443,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 64891.419999999984,
        close_ms: 1711286654996,
        current_return: 1.0141905111377671,
        initial_entry_price: 64968.59,
        is_closed_position: true,
        miner_hotkey: "5GBYYSHa8VofFPc2z6kygY7dzp5XJk6b1eLTdbFF1LaouD1Y",
        net_leverage: 0.0,
        open_ms: 1711278563758,
        orders: [
          {
            leverage: 1.25,
            order_type: "LONG",
            order_uuid: "666e914a-a410-4944-bd55-d2ddf7bfeaec",
            price: 64968.59,
            price_sources: [],
            processed_ms: 1711278563757,
          },
          {
            leverage: 1.25,
            order_type: "LONG",
            order_uuid: "781502ee-1d37-4e68-8d66-70ff867c2a50",
            price: 65133.54,
            price_sources: [],
            processed_ms: 1711279330854,
          },
          {
            leverage: -1.25,
            order_type: "SHORT",
            order_uuid: "e0d0c73b-a00a-44e7-98ba-cf988d262682",
            price: 65210.71,
            price_sources: [],
            processed_ms: 1711281145265,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "63ede7b8-c559-4c39-a6a3-7e6ae06570e8",
            price: 65628.97,
            price_sources: [],
            processed_ms: 1711286654996,
          },
        ],
        position_type: "FLAT",
        position_uuid: "a2fbb075-51ab-4628-b8be-584f06bd984e",
        return_at_close: 1.0091195585820782,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 65556.13,
        close_ms: 1711293976989,
        current_return: 0.9995772706534078,
        initial_entry_price: 65556.13,
        is_closed_position: true,
        miner_hotkey: "5GBYYSHa8VofFPc2z6kygY7dzp5XJk6b1eLTdbFF1LaouD1Y",
        net_leverage: 0.0,
        open_ms: 1711292150061,
        orders: [
          {
            leverage: 1.25,
            order_type: "LONG",
            order_uuid: "6b5b5d7b-462c-48cc-af20-a16ef77c994f",
            price: 65556.13,
            price_sources: [],
            processed_ms: 1711292150060,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "f1549f93-d512-4fc1-9f52-3b4b6bd1c133",
            price: 65533.96,
            price_sources: [],
            processed_ms: 1711293976989,
          },
        ],
        position_type: "FLAT",
        position_uuid: "cf6d8542-18bd-4f4c-bc6d-4918a669e025",
        return_at_close: 0.9970783274767744,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 65685.78,
        close_ms: 1711297644128,
        current_return: 1.0029842836607863,
        initial_entry_price: 65685.78,
        is_closed_position: true,
        miner_hotkey: "5GBYYSHa8VofFPc2z6kygY7dzp5XJk6b1eLTdbFF1LaouD1Y",
        net_leverage: 0.0,
        open_ms: 1711295817103,
        orders: [
          {
            leverage: -1.25,
            order_type: "SHORT",
            order_uuid: "5d87773d-a330-414b-985a-1d6c4f1dcc2c",
            price: 65685.78,
            price_sources: [],
            processed_ms: 1711295817102,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "554550bc-55cc-4d02-bcf9-55f4311e1f3c",
            price: 65528.96,
            price_sources: [],
            processed_ms: 1711297644128,
          },
        ],
        position_type: "FLAT",
        position_uuid: "ce36de5a-7857-4f6d-ae7f-28d8369a926f",
        return_at_close: 1.0004768229516343,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 64938.509999999995,
        close_ms: 1711303148368,
        current_return: 1.0082760984198744,
        initial_entry_price: 64938.51,
        is_closed_position: true,
        miner_hotkey: "5GBYYSHa8VofFPc2z6kygY7dzp5XJk6b1eLTdbFF1LaouD1Y",
        net_leverage: 0.0,
        open_ms: 1711301322365,
        orders: [
          {
            leverage: 1.25,
            order_type: "LONG",
            order_uuid: "8513fe84-9ae1-449b-88ad-d2d55eb48f08",
            price: 64938.51,
            price_sources: [],
            processed_ms: 1711301322364,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "a93384c7-35ba-4dbf-ab32-6246ebc9dc72",
            price: 65368.46,
            price_sources: [],
            processed_ms: 1711303148368,
          },
        ],
        position_type: "FLAT",
        position_uuid: "06a73fda-0fee-4310-8c2b-39c5ab038d85",
        return_at_close: 1.0057554081738247,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 66230.33375000002,
        close_ms: 1711318305385,
        current_return: 0.9910041465711473,
        initial_entry_price: 66252.33,
        is_closed_position: true,
        miner_hotkey: "5GBYYSHa8VofFPc2z6kygY7dzp5XJk6b1eLTdbFF1LaouD1Y",
        net_leverage: 0.0,
        open_ms: 1711315519229,
        orders: [
          {
            leverage: -1.25,
            order_type: "SHORT",
            order_uuid: "6af63f3e-2c76-41a4-a1ee-d57d4cfca051",
            price: 66252.33,
            price_sources: [],
            processed_ms: 1711315519227,
          },
          {
            leverage: 0.125,
            order_type: "LONG",
            order_uuid: "311dc40b-8a71-411b-a03c-d78ce163c3a9",
            price: 66341.12,
            price_sources: [],
            processed_ms: 1711316480968,
          },
          {
            leverage: 0.125,
            order_type: "LONG",
            order_uuid: "2d8780d3-97d9-4a34-b213-9478a14ba71e",
            price: 66339.51,
            price_sources: [],
            processed_ms: 1711317382554,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "7da96b1a-f040-4844-910a-e154e4e417ea",
            price: 66826.33,
            price_sources: [],
            processed_ms: 1711318305385,
          },
        ],
        position_type: "FLAT",
        position_uuid: "8fea0006-4371-40e0-9e19-58cc8e338443",
        return_at_close: 0.9885266362047195,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 460168.6766666629,
        close_ms: 1711346763755,
        current_return: 1.0068343699530484,
        initial_entry_price: 67367.4,
        is_closed_position: true,
        miner_hotkey: "5GBYYSHa8VofFPc2z6kygY7dzp5XJk6b1eLTdbFF1LaouD1Y",
        net_leverage: 0.0,
        open_ms: 1711322974050,
        orders: [
          {
            leverage: -1.25,
            order_type: "SHORT",
            order_uuid: "f1e4d76b-f429-46c8-8a6f-a1b4473df14f",
            price: 67367.4,
            price_sources: [],
            processed_ms: 1711322974049,
          },
          {
            leverage: 0.625,
            order_type: "LONG",
            order_uuid: "795ec602-2569-47c4-a0a3-7b2302a094d8",
            price: 67257.17,
            price_sources: [],
            processed_ms: 1711323895844,
          },
          {
            leverage: 0.3125,
            order_type: "LONG",
            order_uuid: "4f07e625-d1c2-4bce-8de2-5fc753fbf693",
            price: 67204.93,
            price_sources: [],
            processed_ms: 1711324848529,
          },
          {
            leverage: -1.25,
            order_type: "SHORT",
            order_uuid: "3436f46c-ca18-42c0-a77f-f6ceb943d4ef",
            price: 66868.58,
            price_sources: [],
            processed_ms: 1711325746960,
          },
          {
            leverage: 0.125,
            order_type: "LONG",
            order_uuid: "67d73e21-bec8-471b-bc38-12178c94f14f",
            price: 66890.28,
            price_sources: [],
            processed_ms: 1711326684873,
          },
          {
            leverage: 0.125,
            order_type: "LONG",
            order_uuid: "77bf4e81-fc51-4c80-834a-f0f143ab7106",
            price: 66609.37,
            price_sources: [],
            processed_ms: 1711327617791,
          },
          {
            leverage: 0.125,
            order_type: "LONG",
            order_uuid: "93eb92e7-5d8c-432a-8b2e-bd0660cbeb06",
            price: 66656.77,
            price_sources: [],
            processed_ms: 1711328562872,
          },
          {
            leverage: 0.125,
            order_type: "LONG",
            order_uuid: "01c3926c-ad91-424d-9f13-10561e70c008",
            price: 66786.66,
            price_sources: [],
            processed_ms: 1711329627573,
          },
          {
            leverage: 0.125,
            order_type: "LONG",
            order_uuid: "c5d2e52c-70dd-4ae4-9007-be829bcee4b7",
            price: 66583.38,
            price_sources: [],
            processed_ms: 1711332098856,
          },
          {
            leverage: 0.46875,
            order_type: "LONG",
            order_uuid: "f45d1237-0f72-4bd5-9f79-5edb178d385a",
            price: 66797.51,
            price_sources: [],
            processed_ms: 1711333935765,
          },
          {
            leverage: 0.375,
            order_type: "LONG",
            order_uuid: "9bb5c49d-7c12-4f5c-a885-1ccbeb3f6eb9",
            price: 66679.69,
            price_sources: [],
            processed_ms: 1711335768132,
          },
          {
            leverage: 0.046875,
            order_type: "LONG",
            order_uuid: "562a3524-a7d2-4bc7-b1c9-f383ef8b28cd",
            price: 66881.47,
            price_sources: [],
            processed_ms: 1711337597987,
          },
          {
            leverage: 0.0234375,
            order_type: "LONG",
            order_uuid: "bfb49609-0311-48f1-a05b-0a6b4b7cf661",
            price: 67225.6,
            price_sources: [],
            processed_ms: 1711339441559,
          },
          {
            leverage: 0.01171875,
            order_type: "LONG",
            order_uuid: "b39d29c2-7119-4a6b-b59c-8c2af176d3e8",
            price: 67202.14,
            price_sources: [],
            processed_ms: 1711341268977,
          },
          {
            leverage: 0.009375,
            order_type: "LONG",
            order_uuid: "840ef160-6972-4979-a1a9-a2e6965c8291",
            price: 67617.78,
            price_sources: [],
            processed_ms: 1711343138621,
          },
          {
            leverage: 0.0011718750000000002,
            order_type: "LONG",
            order_uuid: "a61f47c6-f082-4b87-9a19-a33af7ad3296",
            price: 67676.35,
            price_sources: [],
            processed_ms: 1711344933916,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "38ccb3da-df0c-4ea1-a8d6-65a592c6fb82",
            price: 67282.29,
            price_sources: [],
            processed_ms: 1711346763755,
          },
        ],
        position_type: "FLAT",
        position_uuid: "771d4250-2053-4c4c-b3ac-dc338d15c427",
        return_at_close: 1.0036880125469452,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 66850.01,
        close_ms: 1711359586141,
        current_return: 0.9999807404666058,
        initial_entry_price: 66850.01,
        is_closed_position: true,
        miner_hotkey: "5GBYYSHa8VofFPc2z6kygY7dzp5XJk6b1eLTdbFF1LaouD1Y",
        net_leverage: 0.0,
        open_ms: 1711357766207,
        orders: [
          {
            leverage: 1.25,
            order_type: "LONG",
            order_uuid: "8a3f5c7e-7b43-4feb-b74a-88869c27fc0b",
            price: 66850.01,
            price_sources: [],
            processed_ms: 1711357766205,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "2b4f7ecb-6fc3-40b9-92b5-cddc53a3d8ed",
            price: 66848.98,
            price_sources: [],
            processed_ms: 1711359586141,
          },
        ],
        position_type: "FLAT",
        position_uuid: "c33c011d-39cd-4452-a45b-ed5f5b66bf96",
        return_at_close: 0.9974807886154393,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 12516.919655172733,
        close_ms: 1711384941677,
        current_return: 1.0500517755669607,
        initial_entry_price: 66898.35,
        is_closed_position: true,
        miner_hotkey: "5GBYYSHa8VofFPc2z6kygY7dzp5XJk6b1eLTdbFF1LaouD1Y",
        net_leverage: 0.0,
        open_ms: 1711368467978,
        orders: [
          {
            leverage: 1.25,
            order_type: "LONG",
            order_uuid: "703fe7bf-58b1-4280-be15-7b18d6a88b1d",
            price: 66898.35,
            price_sources: [],
            processed_ms: 1711368467976,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "5346ed37-1956-43a4-8dd8-da7fed34c810",
            price: 67216.31,
            price_sources: [],
            processed_ms: 1711370290384,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "ef33741b-5466-4a97-bbb9-fb70cad23ccd",
            price: 67181.19,
            price_sources: [],
            processed_ms: 1711372179397,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "8b68b664-809c-4a4e-a445-79f91d1a8d1c",
            price: 67143.56,
            price_sources: [],
            processed_ms: 1711373959662,
          },
          {
            leverage: -1.6,
            order_type: "SHORT",
            order_uuid: "a03c3961-e976-415b-aa01-d9f94f550ceb",
            price: 68490.01,
            price_sources: [],
            processed_ms: 1711375799455,
          },
          {
            leverage: -0.31999999999999995,
            order_type: "SHORT",
            order_uuid: "b9c546ad-956f-41a3-9c1c-0e3f8ed8992e",
            price: 69235.28,
            price_sources: [],
            processed_ms: 1711376545873,
          },
          {
            leverage: -0.03999999999999998,
            order_type: "SHORT",
            order_uuid: "b187b8c4-a527-4ee1-92ad-27b298fb57a5",
            price: 69296.54,
            price_sources: [],
            processed_ms: 1711376856754,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "0d86cbcf-8a48-471d-9859-626a9ea96377",
            price: 69144.78,
            price_sources: [],
            processed_ms: 1711378023387,
          },
          {
            leverage: -0.23199999999999998,
            order_type: "SHORT",
            order_uuid: "97947335-931a-461a-9e58-10976eef4e3b",
            price: 69212.28,
            price_sources: [],
            processed_ms: 1711378284378,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "bf4f6847-44b6-4570-86c7-1bd4610b071f",
            price: 70247.63,
            price_sources: [],
            processed_ms: 1711384941677,
          },
        ],
        position_type: "FLAT",
        position_uuid: "52562f35-74d4-4214-baf3-6c054d870ae3",
        return_at_close: 1.045851568464693,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 70823.92789473683,
        close_ms: 1711396914420,
        current_return: 1.0505986902432682,
        initial_entry_price: 70797.88,
        is_closed_position: true,
        miner_hotkey: "5GBYYSHa8VofFPc2z6kygY7dzp5XJk6b1eLTdbFF1LaouD1Y",
        net_leverage: 0.0,
        open_ms: 1711391145681,
        orders: [
          {
            leverage: 5.0,
            order_type: "LONG",
            order_uuid: "97dda1a6-782d-4213-8d63-6f362d0806f1",
            price: 70797.88,
            price_sources: [],
            processed_ms: 1711391145679,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "109767f3-3620-46ec-a5d1-a8aa6e8ff934",
            price: 70911.15,
            price_sources: [],
            processed_ms: 1711393102475,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "7bf3b87b-5d80-46fc-8010-07c68de443ec",
            price: 70640.5,
            price_sources: [],
            processed_ms: 1711394766979,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "e5b51530-b059-47d5-9d57-24733d3c3090",
            price: 71006.96,
            price_sources: [],
            processed_ms: 1711396584202,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "3fa91e08-d92d-4fb1-90cf-77ce3b88acdd",
            price: 71201.01,
            price_sources: [],
            processed_ms: 1711396914420,
          },
        ],
        position_type: "FLAT",
        position_uuid: "90e03ebc-7871-44aa-b993-c9be5c74d41e",
        return_at_close: 1.030637315128646,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 70883.49846153846,
        close_ms: 1711401234593,
        current_return: 0.968937068406777,
        initial_entry_price: 70879.82,
        is_closed_position: true,
        miner_hotkey: "5GBYYSHa8VofFPc2z6kygY7dzp5XJk6b1eLTdbFF1LaouD1Y",
        net_leverage: 0.0,
        open_ms: 1711398400661,
        orders: [
          {
            leverage: 5.0,
            order_type: "LONG",
            order_uuid: "adc4e660-831d-4afd-b663-6c37661cad22",
            price: 70879.82,
            price_sources: [],
            processed_ms: 1711398400659,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "dcc673e7-571b-4c42-8785-9fb78a88cc2e",
            price: 70895.76,
            price_sources: [],
            processed_ms: 1711400216783,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "e54e3022-1e94-4b04-b35a-673b368af0a3",
            price: 70544.77,
            price_sources: [],
            processed_ms: 1711401234593,
          },
        ],
        position_type: "FLAT",
        position_uuid: "11807546-8776-4304-a3ae-f27921e86921",
        return_at_close: 0.9563408865174888,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 70187.82428571429,
        close_ms: 1711410047843,
        current_return: 0.9676715575436741,
        initial_entry_price: 69922.02,
        is_closed_position: true,
        miner_hotkey: "5GBYYSHa8VofFPc2z6kygY7dzp5XJk6b1eLTdbFF1LaouD1Y",
        net_leverage: 0.0,
        open_ms: 1711402029848,
        orders: [
          {
            leverage: 5.0,
            order_type: "LONG",
            order_uuid: "2376b6f7-60c1-479b-92f7-8e2ee5e20d13",
            price: 69922.02,
            price_sources: [],
            processed_ms: 1711402029845,
          },
          {
            leverage: -2.5,
            order_type: "SHORT",
            order_uuid: "b751ba87-6960-4020-abf4-709285ecfe4d",
            price: 69935.46,
            price_sources: [],
            processed_ms: 1711403848299,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "566bdb22-ebe1-4c9b-9139-d6d35ce718ef",
            price: 70368.12,
            price_sources: [],
            processed_ms: 1711405667443,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "717af59e-a046-47de-97ec-41b30beeb146",
            price: 70414.29,
            price_sources: [],
            processed_ms: 1711407484019,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "73313df0-0a89-4b2e-9270-9e83789d55d6",
            price: 70246.47,
            price_sources: [],
            processed_ms: 1711409318169,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "23534b1a-1a8c-40a3-8050-9555ce415462",
            price: 69864.9,
            price_sources: [],
            processed_ms: 1711410047843,
          },
        ],
        position_type: "FLAT",
        position_uuid: "8480f3f1-de8d-477b-b9b3-0f200cbbcdf8",
        return_at_close: 0.9541241557380626,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 62211.969024390106,
        close_ms: 1711438371276,
        current_return: 1.0420425250825371,
        initial_entry_price: 69969.0,
        is_closed_position: true,
        miner_hotkey: "5GBYYSHa8VofFPc2z6kygY7dzp5XJk6b1eLTdbFF1LaouD1Y",
        net_leverage: 0.0,
        open_ms: 1711411132339,
        orders: [
          {
            leverage: 5.0,
            order_type: "LONG",
            order_uuid: "2d32ad30-4c32-46bf-9600-0bac3d758ab3",
            price: 69969.0,
            price_sources: [],
            processed_ms: 1711411132337,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "94ab8715-b171-4b3b-bfa4-4ee681145267",
            price: 69939.82,
            price_sources: [],
            processed_ms: 1711412929131,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "093444bc-b78b-4a09-965a-4b9e82ad71b0",
            price: 69903.08,
            price_sources: [],
            processed_ms: 1711414784475,
          },
          {
            leverage: -4.0,
            order_type: "SHORT",
            order_uuid: "23c5fb75-0e92-4472-9e16-dc261a4e3c6b",
            price: 70271.46,
            price_sources: [],
            processed_ms: 1711416569992,
          },
          {
            leverage: -2.0,
            order_type: "SHORT",
            order_uuid: "a57fce44-5b2a-489b-847f-cc058d5ed73f",
            price: 70268.99,
            price_sources: [],
            processed_ms: 1711418384397,
          },
          {
            leverage: -1.0,
            order_type: "SHORT",
            order_uuid: "dc0bdb0d-2558-431a-a516-1ff9bc5a364a",
            price: 70274.83,
            price_sources: [],
            processed_ms: 1711420222685,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "1d095486-7e5c-4649-bbae-088e1d98085a",
            price: 70543.04,
            price_sources: [],
            processed_ms: 1711422024133,
          },
          {
            leverage: -1.25,
            order_type: "SHORT",
            order_uuid: "9b972b10-9384-4c80-a847-9edd4861fd5f",
            price: 70500.54,
            price_sources: [],
            processed_ms: 1711423838385,
          },
          {
            leverage: -0.625,
            order_type: "SHORT",
            order_uuid: "dab4e398-df60-4924-9eaf-d8d16ea0f374",
            price: 70436.78,
            price_sources: [],
            processed_ms: 1711425651696,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "9f704613-4712-46cc-b0de-b2427b28079b",
            price: 70606.87,
            price_sources: [],
            processed_ms: 1711427523661,
          },
          {
            leverage: -1.0625,
            order_type: "SHORT",
            order_uuid: "cc9b72a2-7793-4ae9-8239-0106140a98a4",
            price: 70613.05,
            price_sources: [],
            processed_ms: 1711429288827,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "1d6a8f1a-40de-49ac-949b-9dbcb806a5ba",
            price: 70372.88,
            price_sources: [],
            processed_ms: 1711431101278,
          },
          {
            leverage: -1.28125,
            order_type: "SHORT",
            order_uuid: "c41919cf-06a6-43eb-a04c-8ee9e0d79995",
            price: 70319.58,
            price_sources: [],
            processed_ms: 1711432983941,
          },
          {
            leverage: -0.640625,
            order_type: "SHORT",
            order_uuid: "375a32d6-467a-4dae-87d9-e1f5fca662c5",
            price: 70491.02,
            price_sources: [],
            processed_ms: 1711434766161,
          },
          {
            leverage: -0.3203125,
            order_type: "SHORT",
            order_uuid: "2c0e2158-8f09-4698-b3c8-a3ebc26b839d",
            price: 70672.59,
            price_sources: [],
            processed_ms: 1711436556304,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "b41212ef-86ee-4b21-bc57-6b2288f7e2dd",
            price: 71395.73,
            price_sources: [],
            processed_ms: 1711438371276,
          },
        ],
        position_type: "FLAT",
        position_uuid: "0d93e9c8-7a58-40cc-9631-d187f47579c8",
        return_at_close: 1.0253698446812165,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 69819.6400000001,
        close_ms: 1711447449913,
        current_return: 0.9905935998349134,
        initial_entry_price: 70653.49,
        is_closed_position: true,
        miner_hotkey: "5GBYYSHa8VofFPc2z6kygY7dzp5XJk6b1eLTdbFF1LaouD1Y",
        net_leverage: 0.0,
        open_ms: 1711440204690,
        orders: [
          {
            leverage: -5.0,
            order_type: "SHORT",
            order_uuid: "062953a6-768b-4889-929a-5371c8dc2f6c",
            price: 70653.49,
            price_sources: [],
            processed_ms: 1711440204687,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "3f7ec11e-7fd7-4648-856b-8a94d2ed7010",
            price: 70544.76,
            price_sources: [],
            processed_ms: 1711442002978,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "daf0eb72-35c2-4e3e-bc63-df3300a65a06",
            price: 70724.69,
            price_sources: [],
            processed_ms: 1711443822448,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "48113239-3e53-4197-87dc-b774f37d6305",
            price: 70968.97,
            price_sources: [],
            processed_ms: 1711445637477,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "d988fd3e-f98c-49a0-b0f0-6b91dc1b02d5",
            price: 71148.83,
            price_sources: [],
            processed_ms: 1711447449913,
          },
        ],
        position_type: "FLAT",
        position_uuid: "5ae859b8-4df7-464c-89ce-ee3c52ca4ef8",
        return_at_close: 0.9806876638365642,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 74604.32000000018,
        close_ms: 1711456539414,
        current_return: 1.0126693536823528,
        initial_entry_price: 70926.27,
        is_closed_position: true,
        miner_hotkey: "5GBYYSHa8VofFPc2z6kygY7dzp5XJk6b1eLTdbFF1LaouD1Y",
        net_leverage: 0.0,
        open_ms: 1711449270218,
        orders: [
          {
            leverage: -5.0,
            order_type: "SHORT",
            order_uuid: "83d0641b-3dda-40aa-8cec-275dafa929c9",
            price: 70926.27,
            price_sources: [],
            processed_ms: 1711449270215,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "43009d95-4b51-4871-8123-226172acc460",
            price: 70912.64,
            price_sources: [],
            processed_ms: 1711451085246,
          },
          {
            leverage: 1.75,
            order_type: "LONG",
            order_uuid: "9d832089-5d54-42a7-8532-24b2a099c0ee",
            price: 70679.26,
            price_sources: [],
            processed_ms: 1711452898501,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "c02da183-2089-4933-ab9f-aaa3c5394347",
            price: 70615.07,
            price_sources: [],
            processed_ms: 1711454714282,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "c8e3e5f2-5d67-4e11-b09a-b3db09de1e31",
            price: 71009.96,
            price_sources: [],
            processed_ms: 1711456539414,
          },
        ],
        position_type: "FLAT",
        position_uuid: "b3e55360-2871-4b81-9835-e9a58401a162",
        return_at_close: 1.0025426601455292,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 71806.6228571429,
        close_ms: 1711463617437,
        current_return: 1.0559320771055174,
        initial_entry_price: 70999.33,
        is_closed_position: true,
        miner_hotkey: "5GBYYSHa8VofFPc2z6kygY7dzp5XJk6b1eLTdbFF1LaouD1Y",
        net_leverage: 0.0,
        open_ms: 1711458351261,
        orders: [
          {
            leverage: -5.0,
            order_type: "SHORT",
            order_uuid: "0cf5ae5e-95e5-4834-a97f-77f13c3b9191",
            price: 70999.33,
            price_sources: [],
            processed_ms: 1711458351258,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "305f5799-05db-4a5b-83bc-3be44e9d2edb",
            price: 70790.4,
            price_sources: [],
            processed_ms: 1711460163138,
          },
          {
            leverage: 1.75,
            order_type: "LONG",
            order_uuid: "dea6e7bb-fc39-46c2-a506-de700f8cf67e",
            price: 70371.12,
            price_sources: [],
            processed_ms: 1711461996452,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "68fa66ae-245b-47d4-8f9a-37a956b8cd6f",
            price: 69537.4,
            price_sources: [],
            processed_ms: 1711463617437,
          },
        ],
        position_type: "FLAT",
        position_uuid: "321d5080-e9f9-468d-858b-5322493696c5",
        return_at_close: 1.0453727563344621,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 69783.49,
        close_ms: 1711464608408,
        current_return: 0.9677860766207027,
        initial_entry_price: 69783.49,
        is_closed_position: true,
        miner_hotkey: "5GBYYSHa8VofFPc2z6kygY7dzp5XJk6b1eLTdbFF1LaouD1Y",
        net_leverage: 0.0,
        open_ms: 1711463804602,
        orders: [
          {
            leverage: -5.0,
            order_type: "SHORT",
            order_uuid: "26c548db-d03b-4380-8d58-6131e7c2efeb",
            price: 69783.49,
            price_sources: [],
            processed_ms: 1711463804599,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "3d29ac9e-8513-401a-bb0f-075346bdc070",
            price: 70233.09,
            price_sources: [],
            processed_ms: 1711464608408,
          },
        ],
        position_type: "FLAT",
        position_uuid: "dbf76fb6-d2ab-4e6b-beeb-e53139305eed",
        return_at_close: 0.9581082158544957,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 70013.3952631579,
        close_ms: 1711471471236,
        current_return: 0.9624317910253627,
        initial_entry_price: 70040.27,
        is_closed_position: true,
        miner_hotkey: "5GBYYSHa8VofFPc2z6kygY7dzp5XJk6b1eLTdbFF1LaouD1Y",
        net_leverage: 0.0,
        open_ms: 1711465616528,
        orders: [
          {
            leverage: 5.0,
            order_type: "LONG",
            order_uuid: "bbc2804f-e4f4-4162-9b52-e6a3490330ed",
            price: 70040.27,
            price_sources: [],
            processed_ms: 1711465616525,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "4829e6d5-8a14-4c5b-8d5b-ce876501a5f6",
            price: 70325.59,
            price_sources: [],
            processed_ms: 1711467564026,
          },
          {
            leverage: -3.25,
            order_type: "SHORT",
            order_uuid: "fa6009b4-ff09-4e51-a0b8-09fa2ca003a3",
            price: 70056.31,
            price_sources: [],
            processed_ms: 1711469260600,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "1b922ef8-630b-4fc9-a13f-358eb787eb03",
            price: 69704.6,
            price_sources: [],
            processed_ms: 1711471070384,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "c52c9cfc-7db1-4d5f-bf2d-224eceddeb6a",
            price: 69459.44,
            price_sources: [],
            processed_ms: 1711471471236,
          },
        ],
        position_type: "FLAT",
        position_uuid: "b905d70f-91d0-448e-920f-e948e4e3beae",
        return_at_close: 0.949920177742033,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 69798.33853658538,
        close_ms: 1711489109581,
        current_return: 1.058477308392243,
        initial_entry_price: 69918.36,
        is_closed_position: true,
        miner_hotkey: "5GBYYSHa8VofFPc2z6kygY7dzp5XJk6b1eLTdbFF1LaouD1Y",
        net_leverage: 0.0,
        open_ms: 1711472880023,
        orders: [
          {
            leverage: 5.0,
            order_type: "LONG",
            order_uuid: "5bd885b7-d567-45f0-bacc-6b29b7ffa3f5",
            price: 69918.36,
            price_sources: [],
            processed_ms: 1711472880021,
          },
          {
            leverage: -2.5,
            order_type: "SHORT",
            order_uuid: "3874f011-092e-4b06-ae9c-e3f75696e00a",
            price: 70159.48,
            price_sources: [],
            processed_ms: 1711474702720,
          },
          {
            leverage: -1.25,
            order_type: "SHORT",
            order_uuid: "55c77607-04a1-4218-8717-3e9017e1424f",
            price: 70190.1,
            price_sources: [],
            processed_ms: 1711476518137,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "6051028d-6f78-4c71-9d51-f11e9242f6c5",
            price: 70406.0,
            price_sources: [],
            processed_ms: 1711478349257,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "1e9c419b-b05a-48cc-93ac-0ecf32b6ec40",
            price: 69837.96,
            price_sources: [],
            processed_ms: 1711480165885,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "76d2976c-2233-40ab-963e-01010880a4c0",
            price: 69775.68,
            price_sources: [],
            processed_ms: 1711481982114,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "ce2c7583-3345-40ba-a6d9-f763b083a1b9",
            price: 69686.28,
            price_sources: [],
            processed_ms: 1711483806121,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "8724337b-0744-493f-9e5b-29daf6c39334",
            price: 69855.83,
            price_sources: [],
            processed_ms: 1711485615945,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "83830ad3-9e6c-4184-8785-470669702a2c",
            price: 69756.58,
            price_sources: [],
            processed_ms: 1711487433346,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "ef0d7fa3-2b9f-4071-b531-23dec670b298",
            price: 70197.23,
            price_sources: [],
            processed_ms: 1711489109581,
          },
        ],
        position_type: "FLAT",
        position_uuid: "bed95c44-eac9-4906-978c-732bc7660b45",
        return_at_close: 1.036778523570202,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 70074.59240000001,
        close_ms: 1711500072170,
        current_return: 1.0582146631050153,
        initial_entry_price: 70162.22,
        is_closed_position: true,
        miner_hotkey: "5GBYYSHa8VofFPc2z6kygY7dzp5XJk6b1eLTdbFF1LaouD1Y",
        net_leverage: 0.0,
        open_ms: 1711489250750,
        orders: [
          {
            leverage: 5.0,
            order_type: "LONG",
            order_uuid: "bfbee296-b3df-4a17-80ce-068322723a3d",
            price: 70162.22,
            price_sources: [],
            processed_ms: 1711489250747,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "7fda81f4-5017-41da-92d9-84625a1db052",
            price: 69894.13,
            price_sources: [],
            processed_ms: 1711491084245,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "8667cacc-710f-4e2d-8354-1ec78f2df867",
            price: 69926.55,
            price_sources: [],
            processed_ms: 1711492894075,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "62c6d89d-0b4a-4306-b885-6267771e4fab",
            price: 70308.91,
            price_sources: [],
            processed_ms: 1711494713361,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "ceb9e0f1-a990-480c-be9b-aad075a94486",
            price: 70078.78,
            price_sources: [],
            processed_ms: 1711496532476,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "0c1772d4-a8b5-4ebc-bdf8-214c15e0130c",
            price: 69872.5,
            price_sources: [],
            processed_ms: 1711498342160,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "f1f12571-f49b-44a4-a2ec-21749bda5e45",
            price: 70401.35,
            price_sources: [],
            processed_ms: 1711500072170,
          },
        ],
        position_type: "FLAT",
        position_uuid: "e9617255-2c5c-42f6-9dc6-240b38de2f28",
        return_at_close: 1.0317592965273898,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 70420.17806451615,
        close_ms: 1711513031758,
        current_return: 0.9662621642637582,
        initial_entry_price: 70396.78,
        is_closed_position: true,
        miner_hotkey: "5GBYYSHa8VofFPc2z6kygY7dzp5XJk6b1eLTdbFF1LaouD1Y",
        net_leverage: 0.0,
        open_ms: 1711500159062,
        orders: [
          {
            leverage: 5.0,
            order_type: "LONG",
            order_uuid: "7c933cfc-1cc1-4c58-a9a3-a9edbc30698b",
            price: 70396.78,
            price_sources: [],
            processed_ms: 1711500159059,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "6bd4a82b-23ad-4ca4-b6c2-3b3a0d75c13b",
            price: 70623.93,
            price_sources: [],
            processed_ms: 1711501978604,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "4600d50a-e226-4dc2-9739-d1cea52d812d",
            price: 70520.65,
            price_sources: [],
            processed_ms: 1711503797375,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "0b5f01f8-ed40-4836-99a2-7955e97e6e7a",
            price: 70297.31,
            price_sources: [],
            processed_ms: 1711505606946,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "11e150f0-ce7a-42e3-a0c1-faf1ad98710d",
            price: 70363.37,
            price_sources: [],
            processed_ms: 1711507425232,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "a335116c-8dc6-4a2e-8e13-6f09e1194587",
            price: 70531.24,
            price_sources: [],
            processed_ms: 1711509238228,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "46664417-bda0-40fa-996f-fbbdb7953d3b",
            price: 70383.1,
            price_sources: [],
            processed_ms: 1711511056941,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "988db418-6445-4824-9913-fe52a9765a42",
            price: 70299.64,
            price_sources: [],
            processed_ms: 1711512878551,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "7711628d-5913-4548-bfec-5b01128ec1c6",
            price: 70266.95,
            price_sources: [],
            processed_ms: 1711513031758,
          },
        ],
        position_type: "FLAT",
        position_uuid: "85975453-8319-4636-885d-c415972aa289",
        return_at_close: 0.9363080371715817,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 70355.21409090908,
        close_ms: 1711524229665,
        current_return: 0.9299318009203368,
        initial_entry_price: 70335.26,
        is_closed_position: true,
        miner_hotkey: "5GBYYSHa8VofFPc2z6kygY7dzp5XJk6b1eLTdbFF1LaouD1Y",
        net_leverage: 0.0,
        open_ms: 1711514686334,
        orders: [
          {
            leverage: 5.0,
            order_type: "LONG",
            order_uuid: "e0055dca-e347-45e2-9e62-f11dd4ec7e50",
            price: 70335.26,
            price_sources: [],
            processed_ms: 1711514686331,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "1444e2c3-ff88-45c0-9f13-7ac0a555debe",
            price: 70332.0,
            price_sources: [],
            processed_ms: 1711516501983,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "124b4712-ad2b-459b-8dc6-20db5c0afe28",
            price: 70467.82,
            price_sources: [],
            processed_ms: 1711518322238,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "635f450a-1322-4e76-ba82-ade8b4a06613",
            price: 70424.41,
            price_sources: [],
            processed_ms: 1711520134245,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "cce104c6-d65a-434f-8870-bcf539efdd32",
            price: 70263.14,
            price_sources: [],
            processed_ms: 1711521955369,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "604105e7-f2e7-43a9-b200-4450fa3fc79a",
            price: 69907.19,
            price_sources: [],
            processed_ms: 1711524229665,
          },
        ],
        position_type: "FLAT",
        position_uuid: "17481722-516f-4009-8e1e-00b29581d962",
        return_at_close: 0.9094733013000894,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
    ],
    thirty_day_returns: 1.0,
  },
  "5GCRF1NLkU41tipELQbVsFuH8ZAYxtwWpn4FYiXiu6QHUaHA": {
    all_time_returns: 1.0557735035871558,
    n_positions: 10,
    percentage_profitable: 0.8,
    positions: [
      {
        average_entry_price: 2986.1,
        close_ms: 1720417957850,
        current_return: 1.0383960468227453,
        initial_entry_price: 2986.1,
        is_closed_position: true,
        miner_hotkey: "5GCRF1NLkU41tipELQbVsFuH8ZAYxtwWpn4FYiXiu6QHUaHA",
        net_leverage: 0.0,
        open_ms: 1720209014547,
        orders: [
          {
            leverage: -1.5836247985828715,
            order_type: "SHORT",
            order_uuid: "c165b58c-23e7-4336-a475-7011515f223a",
            price: 2986.1,
            price_sources: [
              {
                close: 2986.1,
                high: 2986.1,
                lag_ms: 373,
                low: 2986.09,
                open: 2986.1,
                source: "Polygon_ws",
                start_ms: 1720209013000,
                timespan_ms: 0,
                volume: 0.01757029,
                vwap: 2986.0991,
                websocket: true,
              },
              {
                close: 2985.15,
                high: 2985.15,
                lag_ms: 627,
                low: 2985.15,
                open: 2985.15,
                source: "TwelveData_ws",
                start_ms: 1720209014000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
              {
                close: 2985.21,
                high: 2985.21,
                lag_ms: 2374,
                low: 2985.2,
                open: 2985.2,
                source: "Polygon_rest",
                start_ms: 1720209010000,
                timespan_ms: 1000,
                volume: 0.72796635,
                vwap: 2985.2082,
                websocket: false,
              },
              {
                close: 2990.84009,
                high: 2991.54004,
                lag_ms: 73374,
                low: 2990.6499,
                open: 2991.51001,
                source: "TwelveData_rest",
                start_ms: 1720208880000,
                timespan_ms: 60000,
                volume: null,
                vwap: null,
                websocket: false,
              },
            ],
            processed_ms: 1720209013373,
          },
          {
            leverage: 1.5906936946057437,
            order_type: "FLAT",
            order_uuid: "d801704a-3ea4-43f9-994c-292499ed3a58",
            price: 2913.7,
            price_sources: [
              {
                close: 2913.7,
                high: 2913.7,
                lag_ms: 149,
                low: 2913.7,
                open: 2913.7,
                source: "Polygon_ws",
                start_ms: 1720417957999,
                timespan_ms: 0,
                volume: 0.00074572,
                vwap: 2913.7,
                websocket: true,
              },
              {
                close: 2913.96,
                high: 2913.96,
                lag_ms: 2850,
                low: 2913.96,
                open: 2913.96,
                source: "TwelveData_ws",
                start_ms: 1720417955000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
              {
                close: 2913.87,
                high: 2913.87,
                lag_ms: 6851,
                low: 2913.87,
                open: 2913.87,
                source: "Polygon_rest",
                start_ms: 1720417950000,
                timespan_ms: 1000,
                volume: 0.0009933,
                vwap: 2913.87,
                websocket: false,
              },
              {
                close: 2912.73999,
                high: 2914.47998,
                lag_ms: 277851,
                low: 2912.73999,
                open: 2914.47998,
                source: "TwelveData_rest",
                start_ms: 1720417620000,
                timespan_ms: 60000,
                volume: null,
                vwap: null,
                websocket: false,
              },
            ],
            processed_ms: 1720417957850,
          },
        ],
        position_type: "FLAT",
        position_uuid: "c165b58c-23e7-4336-a475-7011515f223a",
        return_at_close: 1.0367516170922464,
        trade_pair: ["ETHUSD", "ETH/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 3058.65,
        close_ms: 1720467916886,
        current_return: 1.0126551259086696,
        initial_entry_price: 3058.65,
        is_closed_position: true,
        miner_hotkey: "5GCRF1NLkU41tipELQbVsFuH8ZAYxtwWpn4FYiXiu6QHUaHA",
        net_leverage: 0.0,
        open_ms: 1720430048396,
        orders: [
          {
            leverage: -0.5969710188240632,
            order_type: "SHORT",
            order_uuid: "b9928360-ed82-46e1-ada6-c98571b45bb6",
            price: 3058.65,
            price_sources: [
              {
                close: 3058.65,
                high: 3058.65,
                lag_ms: 323,
                low: 3058.65,
                open: 3058.65,
                source: "Polygon_ws",
                start_ms: 1720430047000,
                timespan_ms: 0,
                volume: 1e-8,
                vwap: 3058.65,
                websocket: true,
              },
              {
                close: 3058.94,
                high: 3058.94,
                lag_ms: 2323,
                low: 3058.94,
                open: 3058.94,
                source: "TwelveData_ws",
                start_ms: 1720430045000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
              {
                close: 3058.65,
                high: 3058.65,
                lag_ms: 2324,
                low: 3058.65,
                open: 3058.65,
                source: "Polygon_rest",
                start_ms: 1720430044000,
                timespan_ms: 1000,
                volume: 2e-8,
                vwap: 3058.65,
                websocket: false,
              },
              {
                close: 3068.61011,
                high: 3069.71997,
                lag_ms: 307324,
                low: 3068.27002,
                open: 3068.27002,
                source: "TwelveData_rest",
                start_ms: 1720429680000,
                timespan_ms: 60000,
                volume: null,
                vwap: null,
                websocket: false,
              },
            ],
            processed_ms: 1720430047323,
          },
          {
            leverage: 1.1963468098304357,
            order_type: "FLAT",
            order_uuid: "4401d59d-1d93-4995-b8ba-0696f673b7b1",
            price: 2993.81,
            price_sources: [
              {
                close: 2993.81,
                high: 2993.81,
                lag_ms: 113,
                low: 2993.8,
                open: 2993.81,
                source: "Polygon_ws",
                start_ms: 1720467916999,
                timespan_ms: 0,
                volume: 0.00744368,
                vwap: 2993.809,
                websocket: true,
              },
              {
                close: 2994.26,
                high: 2994.26,
                lag_ms: 3886,
                low: 2994.26,
                open: 2994.26,
                source: "TwelveData_ws",
                start_ms: 1720467913000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
              {
                close: 2993.68,
                high: 2993.68,
                lag_ms: 3887,
                low: 2993.24,
                open: 2993.27,
                source: "Polygon_rest",
                start_ms: 1720467912000,
                timespan_ms: 1000,
                volume: 0.71603821,
                vwap: 2993.6596,
                websocket: false,
              },
              {
                close: 2996.70996,
                high: 2996.70996,
                lag_ms: 316887,
                low: 2993.97998,
                open: 2993.97998,
                source: "TwelveData_rest",
                start_ms: 1720467540000,
                timespan_ms: 60000,
                volume: null,
                vwap: null,
                websocket: false,
              },
            ],
            processed_ms: 1720467916886,
          },
        ],
        position_type: "FLAT",
        position_uuid: "b9928360-ed82-46e1-ada6-c98571b45bb6",
        return_at_close: 1.0120506001464384,
        trade_pair: ["ETHUSD", "ETH/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 0.919236006255393,
        close_ms: 1720473046911,
        current_return: 1.0002020861373764,
        initial_entry_price: 0.919236006255393,
        is_closed_position: true,
        miner_hotkey: "5GCRF1NLkU41tipELQbVsFuH8ZAYxtwWpn4FYiXiu6QHUaHA",
        net_leverage: 0.0,
        open_ms: 1720199047212,
        orders: [
          {
            leverage: -0.32530628864037026,
            order_type: "SHORT",
            order_uuid: "889c2613-9f08-4fac-aa5d-7df4562ed3b9",
            price: 0.919236006255393,
            price_sources: [
              {
                close: 0.919236006255393,
                high: 0.919236006255393,
                lag_ms: 208,
                low: 0.919236006255393,
                open: 0.919236006255393,
                source: "Polygon_ws",
                start_ms: 1720199047000,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 0.919236006255393,
                websocket: true,
              },
              {
                close: 0.91915,
                high: 0.91915,
                lag_ms: 792,
                low: 0.91915,
                open: 0.91915,
                source: "TwelveData_ws",
                start_ms: 1720199048000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1720199047208,
          },
          {
            leverage: 1.1907441841207511,
            order_type: "FLAT",
            order_uuid: "3a6538c0-1873-404c-bfd3-9f0135c928b3",
            price: 0.918664960258656,
            price_sources: [
              {
                close: 0.918664960258656,
                high: 0.918664960258656,
                lag_ms: 88,
                low: 0.918664960258656,
                open: 0.918664960258656,
                source: "Polygon_ws",
                start_ms: 1720473046999,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 0.918664960258656,
                websocket: true,
              },
              {
                close: 0.91834,
                high: 0.91834,
                lag_ms: 89,
                low: 0.91834,
                open: 0.91834,
                source: "TwelveData_ws",
                start_ms: 1720473047000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1720473046911,
          },
        ],
        position_type: "FLAT",
        position_uuid: "889c2613-9f08-4fac-aa5d-7df4562ed3b9",
        return_at_close: 1.000179310095379,
        trade_pair: ["AUDCAD", "AUD/CAD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 1.4742,
        close_ms: 1720473069046,
        current_return: 0.9999430040792797,
        initial_entry_price: 1.4742,
        is_closed_position: true,
        miner_hotkey: "5GCRF1NLkU41tipELQbVsFuH8ZAYxtwWpn4FYiXiu6QHUaHA",
        net_leverage: 0.0,
        open_ms: 1720386371653,
        orders: [
          {
            leverage: -0.06001670451845407,
            order_type: "SHORT",
            order_uuid: "3b13dc69-5d05-437a-ae3f-ceabd09da966",
            price: 1.4742,
            price_sources: [
              {
                close: 1.4742,
                high: 1.4742,
                lag_ms: 2373,
                low: 1.4742,
                open: 1.4742,
                source: "TwelveData_ws",
                start_ms: 1720386369000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
              {
                close: 1.47394,
                high: 1.47394,
                lag_ms: 67374,
                low: 1.47394,
                open: 1.47394,
                source: "Polygon_ws",
                start_ms: 1720386303999,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 1.47394,
                websocket: true,
              },
              {
                close: 1.47438,
                high: 1.47438,
                lag_ms: 252627,
                low: 1.47438,
                open: 1.47438,
                source: "Polygon_rest",
                start_ms: 1720386624000,
                timespan_ms: 1000,
                volume: 1.0,
                vwap: null,
                websocket: false,
              },
              {
                close: 1.4787,
                high: 1.4788,
                lag_ms: 173171374,
                low: 1.4785,
                open: 1.4785,
                source: "TwelveData_rest",
                start_ms: 1720213140000,
                timespan_ms: 60000,
                volume: null,
                vwap: null,
                websocket: false,
              },
            ],
            processed_ms: 1720386371373,
          },
          {
            leverage: 1.125311336908121,
            order_type: "FLAT",
            order_uuid: "faedfab3-47ca-43f2-a772-1cc4d7f970a5",
            price: 1.4756,
            price_sources: [
              {
                close: 1.4756,
                high: 1.4756,
                lag_ms: 46,
                low: 1.4756,
                open: 1.4756,
                source: "Polygon_ws",
                start_ms: 1720473069000,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 1.4756,
                websocket: true,
              },
              {
                close: 1.4755,
                high: 1.4755,
                lag_ms: 2046,
                low: 1.4755,
                open: 1.4755,
                source: "TwelveData_ws",
                start_ms: 1720473067000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
              {
                close: 1.4755,
                high: 1.4755,
                lag_ms: 4047,
                low: 1.4755,
                open: 1.4755,
                source: "Polygon_rest",
                start_ms: 1720473064000,
                timespan_ms: 1000,
                volume: 1.0,
                vwap: null,
                websocket: false,
              },
              {
                close: 1.4759,
                high: 1.4763,
                lag_ms: 129047,
                low: 1.4759,
                open: 1.476,
                source: "TwelveData_rest",
                start_ms: 1720472880000,
                timespan_ms: 60000,
                volume: null,
                vwap: null,
                websocket: false,
              },
            ],
            processed_ms: 1720473069046,
          },
        ],
        position_type: "FLAT",
        position_uuid: "3b13dc69-5d05-437a-ae3f-ceabd09da966",
        return_at_close: 0.9999388031494129,
        trade_pair: ["EURCAD", "EUR/CAD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 160.625,
        close_ms: 1720473241484,
        current_return: 1.0027446047393929,
        initial_entry_price: 160.625,
        is_closed_position: true,
        miner_hotkey: "5GCRF1NLkU41tipELQbVsFuH8ZAYxtwWpn4FYiXiu6QHUaHA",
        net_leverage: 0.0,
        open_ms: 1720444210687,
        orders: [
          {
            leverage: 2.6398331512872075,
            order_type: "LONG",
            order_uuid: "4dba0da9-9f27-4778-82d1-53913e0c71c2",
            price: 160.625,
            price_sources: [
              {
                close: 160.625,
                high: 160.625,
                lag_ms: 353,
                low: 160.625,
                open: 160.625,
                source: "Polygon_ws",
                start_ms: 1720444210999,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 160.625,
                websocket: true,
              },
              {
                close: 160.63,
                high: 160.63,
                lag_ms: 354,
                low: 160.63,
                open: 160.63,
                source: "TwelveData_ws",
                start_ms: 1720444211000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1720444210646,
          },
          {
            leverage: 1.1890160769362572,
            order_type: "FLAT",
            order_uuid: "24586eab-be10-401b-9be0-357324b0e666",
            price: 160.792,
            price_sources: [
              {
                close: 160.792,
                high: 160.792,
                lag_ms: 1485,
                low: 160.792,
                open: 160.792,
                source: "Polygon_ws",
                start_ms: 1720473239999,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 160.792,
                websocket: true,
              },
              {
                close: 160.775,
                high: 160.775,
                lag_ms: 3484,
                low: 160.775,
                open: 160.775,
                source: "TwelveData_ws",
                start_ms: 1720473238000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1720473241484,
          },
        ],
        position_type: "FLAT",
        position_uuid: "4dba0da9-9f27-4778-82d1-53913e0c71c2",
        return_at_close: 1.0025593092479024,
        trade_pair: ["USDJPY", "USD/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 160.895,
        close_ms: 1720490765676,
        current_return: 1.0002651320646876,
        initial_entry_price: 160.895,
        is_closed_position: true,
        miner_hotkey: "5GCRF1NLkU41tipELQbVsFuH8ZAYxtwWpn4FYiXiu6QHUaHA",
        net_leverage: 0.0,
        open_ms: 1720486698918,
        orders: [
          {
            leverage: 0.27881322580346074,
            order_type: "LONG",
            order_uuid: "08878a4f-2883-4544-8c54-43b4d2884b02",
            price: 160.895,
            price_sources: [
              {
                close: 160.895,
                high: 160.895,
                lag_ms: 288,
                low: 160.895,
                open: 160.895,
                source: "TwelveData_ws",
                start_ms: 1720486698000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
              {
                close: 160.897,
                high: 160.897,
                lag_ms: 712,
                low: 160.897,
                open: 160.897,
                source: "Polygon_ws",
                start_ms: 1720486699000,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 160.897,
                websocket: true,
              },
              {
                close: 160.897,
                high: 160.897,
                lag_ms: 2289,
                low: 160.897,
                open: 160.897,
                source: "Polygon_rest",
                start_ms: 1720486695000,
                timespan_ms: 1000,
                volume: 1.0,
                vwap: null,
                websocket: false,
              },
              {
                close: 160.86501,
                high: 160.875,
                lag_ms: 318289,
                low: 160.85001,
                open: 160.875,
                source: "TwelveData_rest",
                start_ms: 1720486320000,
                timespan_ms: 60000,
                volume: null,
                vwap: null,
                websocket: false,
              },
            ],
            processed_ms: 1720486698288,
          },
          {
            leverage: 1.1078023117837774,
            order_type: "FLAT",
            order_uuid: "92ff349c-78e9-4616-8918-7e71fcded7ef",
            price: 161.048,
            price_sources: [
              {
                close: 161.048,
                high: 161.048,
                lag_ms: 324,
                low: 161.048,
                open: 161.048,
                source: "Polygon_ws",
                start_ms: 1720490766000,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 161.048,
                websocket: true,
              },
              {
                close: 161.053,
                high: 161.053,
                lag_ms: 324,
                low: 161.053,
                open: 161.053,
                source: "TwelveData_ws",
                start_ms: 1720490766000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1720490765676,
          },
        ],
        position_type: "FLAT",
        position_uuid: "08878a4f-2883-4544-8c54-43b4d2884b02",
        return_at_close: 1.0002456099643184,
        trade_pair: ["USDJPY", "USD/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 161.09449999999998,
        close_ms: 1720513817404,
        current_return: 0.9997894536159679,
        initial_entry_price: 161.09449999999998,
        is_closed_position: true,
        miner_hotkey: "5GCRF1NLkU41tipELQbVsFuH8ZAYxtwWpn4FYiXiu6QHUaHA",
        net_leverage: 0.0,
        open_ms: 1720491231169,
        orders: [
          {
            leverage: 0.30556634650859543,
            order_type: "LONG",
            order_uuid: "14bdb7e6-937e-461a-8eb2-49c75b4749ea",
            price: 161.09449999999998,
            price_sources: [
              {
                close: 161.09449999999998,
                high: 161.09449999999998,
                lag_ms: 158,
                low: 161.09449999999998,
                open: 161.09449999999998,
                source: "Polygon_ws",
                start_ms: 1720491231000,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 161.094,
                websocket: true,
              },
              {
                close: 161.094,
                high: 161.094,
                lag_ms: 158,
                low: 161.094,
                open: 161.094,
                source: "TwelveData_ws",
                start_ms: 1720491231000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1720491231158,
          },
          {
            leverage: 1.1652436775112365,
            order_type: "FLAT",
            order_uuid: "a6b8cda8-9ae7-4802-98ae-89ce45a24f7b",
            price: 160.9835,
            price_sources: [
              {
                close: 160.9835,
                high: 160.9835,
                lag_ms: 404,
                low: 160.9835,
                open: 160.9835,
                source: "Polygon_ws",
                start_ms: 1720513817000,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 160.986,
                websocket: true,
              },
              {
                close: 160.991,
                high: 160.991,
                lag_ms: 404,
                low: 160.991,
                open: 160.991,
                source: "TwelveData_ws",
                start_ms: 1720513817000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1720513817404,
          },
        ],
        position_type: "FLAT",
        position_uuid: "14bdb7e6-937e-461a-8eb2-49c75b4749ea",
        return_at_close: 0.9997680684752246,
        trade_pair: ["USDJPY", "USD/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 1.2816,
        close_ms: 1720544616647,
        current_return: 1.0001099435606542,
        initial_entry_price: 1.2816,
        is_closed_position: true,
        miner_hotkey: "5GCRF1NLkU41tipELQbVsFuH8ZAYxtwWpn4FYiXiu6QHUaHA",
        net_leverage: 0.0,
        open_ms: 1720415014041,
        orders: [
          {
            leverage: -0.04784504833084684,
            order_type: "SHORT",
            order_uuid: "32835c9d-f299-4010-8f92-0828e97d4aee",
            price: 1.2816,
            price_sources: [
              {
                close: 1.2816,
                high: 1.2816,
                lag_ms: 18,
                low: 1.2816,
                open: 1.2816,
                source: "Polygon_ws",
                start_ms: 1720415014000,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 1.2816,
                websocket: true,
              },
              {
                close: 1.2816,
                high: 1.2816,
                lag_ms: 18,
                low: 1.2816,
                open: 1.2816,
                source: "TwelveData_ws",
                start_ms: 1720415014000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1720415014018,
          },
          {
            leverage: 1.1605250442945767,
            order_type: "FLAT",
            order_uuid: "1b79b6a0-c3da-4c51-8a7c-6a118e03a689",
            price: 1.278655,
            price_sources: [
              {
                close: 1.278655,
                high: 1.278655,
                lag_ms: 352,
                low: 1.278655,
                open: 1.278655,
                source: "Polygon_ws",
                start_ms: 1720544616999,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 1.27865,
                websocket: true,
              },
              {
                close: 1.2787,
                high: 1.2787,
                lag_ms: 353,
                low: 1.2787,
                open: 1.2787,
                source: "TwelveData_ws",
                start_ms: 1720544617000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1720544616647,
          },
        ],
        position_type: "FLAT",
        position_uuid: "32835c9d-f299-4010-8f92-0828e97d4aee",
        return_at_close: 1.0001065940390532,
        trade_pair: ["GBPUSD", "GBP/USD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.82996,
        close_ms: 1720608030152,
        current_return: 1.0033950467336472,
        initial_entry_price: 0.82996,
        is_closed_position: true,
        miner_hotkey: "5GCRF1NLkU41tipELQbVsFuH8ZAYxtwWpn4FYiXiu6QHUaHA",
        net_leverage: 0.0,
        open_ms: 1720591691731,
        orders: [
          {
            leverage: -1.1939631301092006,
            order_type: "SHORT",
            order_uuid: "efc6b14b-e2b0-4253-80cc-050430678724",
            price: 0.82996,
            price_sources: [
              {
                close: 0.82996,
                high: 0.82996,
                lag_ms: 270,
                low: 0.82996,
                open: 0.82996,
                source: "Polygon_ws",
                start_ms: 1720591691999,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 0.82996,
                websocket: true,
              },
              {
                close: 0.82994,
                high: 0.82994,
                lag_ms: 271,
                low: 0.82994,
                open: 0.82994,
                source: "TwelveData_ws",
                start_ms: 1720591692000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1720591691729,
          },
          {
            leverage: 1.1162876391485872,
            order_type: "FLAT",
            order_uuid: "4c162ec9-90f3-452d-82eb-f1231d7d6329",
            price: 0.8276,
            price_sources: [
              {
                close: 0.8276,
                high: 0.8276,
                lag_ms: 152,
                low: 0.8276,
                open: 0.8276,
                source: "TwelveData_ws",
                start_ms: 1720608030000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
              {
                close: 0.82763,
                high: 0.82763,
                lag_ms: 153,
                low: 0.82763,
                open: 0.82763,
                source: "Polygon_ws",
                start_ms: 1720608029999,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 0.82763,
                websocket: true,
              },
            ],
            processed_ms: 1720608030152,
          },
        ],
        position_type: "FLAT",
        position_uuid: "efc6b14b-e2b0-4253-80cc-050430678724",
        return_at_close: 1.0033111855652959,
        trade_pair: ["NZDCAD", "NZD/CAD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 1.36346,
        close_ms: 1720615676446,
        current_return: 1.0001224892140055,
        initial_entry_price: 1.36346,
        is_closed_position: true,
        miner_hotkey: "5GCRF1NLkU41tipELQbVsFuH8ZAYxtwWpn4FYiXiu6QHUaHA",
        net_leverage: 0.0,
        open_ms: 1720487391256,
        orders: [
          {
            leverage: -0.2830663453015592,
            order_type: "SHORT",
            order_uuid: "2d7bc074-3f44-4a51-b971-ed76867e281c",
            price: 1.36346,
            price_sources: [
              {
                close: 1.36346,
                high: 1.36346,
                lag_ms: 246,
                low: 1.36346,
                open: 1.36346,
                source: "Polygon_ws",
                start_ms: 1720487390999,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 1.36346,
                websocket: true,
              },
              {
                close: 1.36345,
                high: 1.36345,
                lag_ms: 755,
                low: 1.36345,
                open: 1.36345,
                source: "TwelveData_ws",
                start_ms: 1720487392000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1720487391245,
          },
          {
            leverage: 1.12890732994473,
            order_type: "FLAT",
            order_uuid: "aa1a24c7-cb3d-4edf-b624-d84f042c92de",
            price: 1.36287,
            price_sources: [
              {
                close: 1.36287,
                high: 1.36287,
                lag_ms: 446,
                low: 1.36287,
                open: 1.36287,
                source: "Polygon_ws",
                start_ms: 1720615676000,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 1.36287,
                websocket: true,
              },
              {
                close: 1.36287,
                high: 1.36287,
                lag_ms: 446,
                low: 1.36287,
                open: 1.36287,
                source: "TwelveData_ws",
                start_ms: 1720615676000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1720615676446,
          },
        ],
        position_type: "FLAT",
        position_uuid: "2d7bc074-3f44-4a51-b971-ed76867e281c",
        return_at_close: 1.0001026721427542,
        trade_pair: ["USDCAD", "USD/CAD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 161.02049999999997,
        close_ms: 0,
        current_return: 1.000886989118243,
        initial_entry_price: 161.0205,
        is_closed_position: false,
        miner_hotkey: "5GCRF1NLkU41tipELQbVsFuH8ZAYxtwWpn4FYiXiu6QHUaHA",
        net_leverage: 0.20988013418671161,
        open_ms: 1720513941846,
        orders: [
          {
            leverage: 0.20988013418671161,
            order_type: "LONG",
            order_uuid: "6f35526e-5c76-4702-bd00-c1e5ee286908",
            price: 161.0205,
            price_sources: [
              {
                close: 161.0205,
                high: 161.0205,
                lag_ms: 159,
                low: 161.0205,
                open: 161.0205,
                source: "Polygon_ws",
                start_ms: 1720513941999,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 161.02,
                websocket: true,
              },
              {
                close: 161.02,
                high: 161.02,
                lag_ms: 160,
                low: 161.02,
                open: 161.02,
                source: "TwelveData_ws",
                start_ms: 1720513942000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1720513941840,
          },
        ],
        position_type: "LONG",
        position_uuid: "6f35526e-5c76-4702-bd00-c1e5ee286908",
        return_at_close: 1.0008796367978976,
        trade_pair: ["USDJPY", "USD/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.60796,
        close_ms: 0,
        current_return: 0.9999312597120815,
        initial_entry_price: 0.60796,
        is_closed_position: false,
        miner_hotkey: "5GCRF1NLkU41tipELQbVsFuH8ZAYxtwWpn4FYiXiu6QHUaHA",
        net_leverage: -1.04478363607273,
        open_ms: 1720614865084,
        orders: [
          {
            leverage: -1.04478363607273,
            order_type: "SHORT",
            order_uuid: "fce773af-9e59-454d-adee-b0a9e641c2c7",
            price: 0.60796,
            price_sources: [
              {
                close: 0.60796,
                high: 0.60796,
                lag_ms: 82,
                low: 0.60796,
                open: 0.60796,
                source: "TwelveData_ws",
                start_ms: 1720614865000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
              {
                close: 0.60798,
                high: 0.60798,
                lag_ms: 83,
                low: 0.60798,
                open: 0.60798,
                source: "Polygon_ws",
                start_ms: 1720614864999,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 0.60798,
                websocket: true,
              },
            ],
            processed_ms: 1720614865082,
          },
        ],
        position_type: "SHORT",
        position_uuid: "fce773af-9e59-454d-adee-b0a9e641c2c7",
        return_at_close: 0.9998946947984745,
        trade_pair: ["NZDUSD", "NZD/USD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 3085.4000000000005,
        close_ms: 0,
        current_return: 0.9981519429268467,
        initial_entry_price: 3085.4,
        is_closed_position: false,
        miner_hotkey: "5GCRF1NLkU41tipELQbVsFuH8ZAYxtwWpn4FYiXiu6QHUaHA",
        net_leverage: -0.11061096592642627,
        open_ms: 1720507175270,
        orders: [
          {
            leverage: -0.11061096592642627,
            order_type: "SHORT",
            order_uuid: "e7108401-efb7-4923-9c8c-beadd0b98b7b",
            price: 3085.4,
            price_sources: [
              {
                close: 3085.4,
                high: 3085.4,
                lag_ms: 347,
                low: 3085.36,
                open: 3085.4,
                source: "Polygon_ws",
                start_ms: 1720507175000,
                timespan_ms: 0,
                volume: 1.00165133,
                vwap: 3085.3999,
                websocket: true,
              },
              {
                close: 3086.0,
                high: 3086.0,
                lag_ms: 2654,
                low: 3086.0,
                open: 3086.0,
                source: "Polygon_rest",
                start_ms: 1720507171000,
                timespan_ms: 1000,
                volume: 0.0111,
                vwap: 3086.0,
                websocket: false,
              },
              {
                close: 3087.25,
                high: 3087.25,
                lag_ms: 3653,
                low: 3087.25,
                open: 3087.25,
                source: "TwelveData_ws",
                start_ms: 1720507171000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
              {
                close: 3079.94995,
                high: 3079.98999,
                lag_ms: 1054654,
                low: 3077.05005,
                open: 3077.05005,
                source: "TwelveData_rest",
                start_ms: 1720506060000,
                timespan_ms: 60000,
                volume: null,
                vwap: null,
                websocket: false,
              },
            ],
            processed_ms: 1720507174653,
          },
        ],
        position_type: "SHORT",
        position_uuid: "e7108401-efb7-4923-9c8c-beadd0b98b7b",
        return_at_close: 0.9980967396515725,
        trade_pair: ["ETHUSD", "ETH/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 0.67528,
        close_ms: 0,
        current_return: 1.000103917872569,
        initial_entry_price: 0.67528,
        is_closed_position: false,
        miner_hotkey: "5GCRF1NLkU41tipELQbVsFuH8ZAYxtwWpn4FYiXiu6QHUaHA",
        net_leverage: -0.13759541370280867,
        open_ms: 1720411393542,
        orders: [
          {
            leverage: -0.13759541370280867,
            order_type: "SHORT",
            order_uuid: "ffe7b393-fc42-4f3e-86d3-8ecfdefd1ecc",
            price: 0.67528,
            price_sources: [
              {
                close: 0.67528,
                high: 0.67528,
                lag_ms: 470,
                low: 0.67528,
                open: 0.67528,
                source: "Polygon_ws",
                start_ms: 1720411393999,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 0.67528,
                websocket: true,
              },
              {
                close: 0.67524,
                high: 0.67524,
                lag_ms: 471,
                low: 0.67524,
                open: 0.67524,
                source: "TwelveData_ws",
                start_ms: 1720411394000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1720411393529,
          },
        ],
        position_type: "SHORT",
        position_uuid: "ffe7b393-fc42-4f3e-86d3-8ecfdefd1ecc",
        return_at_close: 1.0000991015326375,
        trade_pair: ["AUDUSD", "AUD/USD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 56818.69999999999,
        close_ms: 0,
        current_return: 0.9966080389750981,
        initial_entry_price: 56818.7,
        is_closed_position: false,
        miner_hotkey: "5GCRF1NLkU41tipELQbVsFuH8ZAYxtwWpn4FYiXiu6QHUaHA",
        net_leverage: -0.14619344298383818,
        open_ms: 1720260214534,
        orders: [
          {
            leverage: -0.14619344298383818,
            order_type: "SHORT",
            order_uuid: "96e9c988-f253-47f8-ade2-514c751878b0",
            price: 56818.7,
            price_sources: [
              {
                close: 56818.7,
                high: 56819.26,
                lag_ms: 826,
                low: 56816.21,
                open: 56818.7,
                source: "Polygon_ws",
                start_ms: 1720260212999,
                timespan_ms: 0,
                volume: 0.27777005,
                vwap: 56816.3275,
                websocket: true,
              },
              {
                close: 56765.3,
                high: 56765.3,
                lag_ms: 2175,
                low: 56765.3,
                open: 56765.3,
                source: "TwelveData_ws",
                start_ms: 1720260216000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
              {
                close: 56813.99,
                high: 56818.77,
                lag_ms: 3826,
                low: 56813.99,
                open: 56818.77,
                source: "Polygon_rest",
                start_ms: 1720260209000,
                timespan_ms: 1000,
                volume: 0.00343895,
                vwap: 56817.4333,
                websocket: false,
              },
              {
                close: 56771.13,
                high: 56771.13,
                lag_ms: 33826,
                low: 56722.24,
                open: 56726.64,
                source: "TwelveData_rest",
                start_ms: 1720260120000,
                timespan_ms: 60000,
                volume: null,
                vwap: null,
                websocket: false,
              },
            ],
            processed_ms: 1720260213825,
          },
        ],
        position_type: "SHORT",
        position_uuid: "96e9c988-f253-47f8-ade2-514c751878b0",
        return_at_close: 0.9965351901948365,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 1.08028,
        close_ms: 0,
        current_return: 0.9973596581638364,
        initial_entry_price: 1.08028,
        is_closed_position: false,
        miner_hotkey: "5GCRF1NLkU41tipELQbVsFuH8ZAYxtwWpn4FYiXiu6QHUaHA",
        net_leverage: -1.3713021532551093,
        open_ms: 1720386240309,
        orders: [
          {
            leverage: -1.3713021532551093,
            order_type: "SHORT",
            order_uuid: "2db58dcd-2fcc-4865-b5fb-b6ff82d9ceb5",
            price: 1.08028,
            price_sources: [
              {
                close: 1.08028,
                high: 1.08028,
                lag_ms: 303,
                low: 1.08028,
                open: 1.08028,
                source: "Polygon_ws",
                start_ms: 1720386240000,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 1.08028,
                websocket: true,
              },
              {
                close: 1.0809,
                high: 1.0809,
                lag_ms: 1697,
                low: 1.0809,
                open: 1.0809,
                source: "TwelveData_ws",
                start_ms: 1720386242000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1720386240303,
          },
        ],
        position_type: "SHORT",
        position_uuid: "2db58dcd-2fcc-4865-b5fb-b6ff82d9ceb5",
        return_at_close: 0.9973117893131981,
        trade_pair: ["EURUSD", "EUR/USD", 7e-5, 0.001, 500],
      },
    ],
    thirty_day_returns: 1.0557735035871558,
  },
  "5GE8GPLG2y66XyDNGY94dG7h7QPCFQuUYRDipDj29CAEKi7r": {
    all_time_returns: 1.0065963819763293,
    n_positions: 2,
    percentage_profitable: 0.5,
    positions: [
      {
        average_entry_price: 157.27624999999998,
        close_ms: 1718388101496,
        current_return: 0.999838575396094,
        initial_entry_price: 157.349,
        is_closed_position: true,
        miner_hotkey: "5GE8GPLG2y66XyDNGY94dG7h7QPCFQuUYRDipDj29CAEKi7r",
        net_leverage: 0.0,
        open_ms: 1718122679796,
        orders: [
          {
            leverage: -0.5,
            order_type: "SHORT",
            order_uuid: "ac02f9fa-1d96-41b6-ba33-3dc1bffc988f",
            price: 157.349,
            price_sources: [],
            processed_ms: 1718122679773,
          },
          {
            leverage: -0.3,
            order_type: "SHORT",
            order_uuid: "2b4822d0-f3d8-45e8-9a70-d247baba1600",
            price: 157.155,
            price_sources: [],
            processed_ms: 1718273556295,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "e873c0bb-b5d5-447c-9016-6cd8690b415f",
            price: 157.308,
            price_sources: [],
            processed_ms: 1718388101496,
          },
        ],
        position_type: "FLAT",
        position_uuid: "6f1f5c15-af9a-4023-a62b-7ff04e95617c",
        return_at_close: 0.9997825844358719,
        trade_pair: ["USDJPY", "USD/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 65309.417499999996,
        close_ms: 1719216117762,
        current_return: 1.0069763955115305,
        initial_entry_price: 65310.46,
        is_closed_position: true,
        miner_hotkey: "5GE8GPLG2y66XyDNGY94dG7h7QPCFQuUYRDipDj29CAEKi7r",
        net_leverage: 0.0,
        open_ms: 1718388212768,
        orders: [
          {
            leverage: -0.13,
            order_type: "SHORT",
            order_uuid: "516ba0d6-5732-4de8-ae22-6e48630300df",
            price: 65310.46,
            price_sources: [],
            processed_ms: 1718388212302,
          },
          {
            leverage: -0.03,
            order_type: "SHORT",
            order_uuid: "ba171966-5e2e-43a3-814e-3c36c2d76a6d",
            price: 65304.9,
            price_sources: [],
            processed_ms: 1718388410353,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "0651280d-9fb9-443f-8ef6-05f47ae5fd94",
            price: 62461.72,
            price_sources: [],
            processed_ms: 1719216117762,
          },
        ],
        position_type: "FLAT",
        position_uuid: "e7e56cc1-9090-4002-a175-8cb885df5a4b",
        return_at_close: 1.0068152792882485,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 3368.72,
        close_ms: 0,
        current_return: 0.9965599693652188,
        initial_entry_price: 3368.72,
        is_closed_position: false,
        miner_hotkey: "5GE8GPLG2y66XyDNGY94dG7h7QPCFQuUYRDipDj29CAEKi7r",
        net_leverage: 0.05,
        open_ms: 1719216154116,
        orders: [
          {
            leverage: 0.05,
            order_type: "LONG",
            order_uuid: "9d7a7a37-67a7-4a19-8cb5-369702912576",
            price: 3368.72,
            price_sources: [],
            processed_ms: 1719216152962,
          },
        ],
        position_type: "LONG",
        position_uuid: "66f92dd7-76be-4297-a77c-563790611583",
        return_at_close: 0.9965350553659846,
        trade_pair: ["ETHUSD", "ETH/USD", 0.003, 0.001, 20],
      },
    ],
    thirty_day_returns: 1.0065963819763293,
  },
  "5GEv5g4fsEuDT96PPS52Crn8gDYckTxU3qPDgqm9NEKRYoyy": {
    all_time_returns: 1.0,
    n_positions: 0,
    percentage_profitable: 0.0,
    positions: [
      {
        average_entry_price: 64278.990000000005,
        close_ms: 1711195541592,
        current_return: 1.0033197472455617,
        initial_entry_price: 64278.99,
        is_closed_position: true,
        miner_hotkey: "5GEv5g4fsEuDT96PPS52Crn8gDYckTxU3qPDgqm9NEKRYoyy",
        net_leverage: 0.0,
        open_ms: 1711191973494,
        orders: [
          {
            leverage: 0.6,
            order_type: "LONG",
            order_uuid: "ce47e7f5-d766-4a77-b74e-fd960b734b59",
            price: 64278.99,
            price_sources: [],
            processed_ms: 1711191973493,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "a9b06c66-d9bd-4a5b-84bc-85e0a63df77a",
            price: 64634.64,
            price_sources: [],
            processed_ms: 1711195541592,
          },
        ],
        position_type: "FLAT",
        position_uuid: "7de2634a-a177-4e27-b88f-c9b9310514da",
        return_at_close: 1.0021157635488671,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 65101.27,
        close_ms: 1711213865769,
        current_return: 0.9950537370469117,
        initial_entry_price: 65101.27,
        is_closed_position: true,
        miner_hotkey: "5GEv5g4fsEuDT96PPS52Crn8gDYckTxU3qPDgqm9NEKRYoyy",
        net_leverage: 0.0,
        open_ms: 1711210276645,
        orders: [
          {
            leverage: -0.6,
            order_type: "SHORT",
            order_uuid: "424e4bde-b045-4d83-a44e-756f781ecd79",
            price: 65101.27,
            price_sources: [],
            processed_ms: 1711210276645,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "f224c284-f673-4b67-996d-7e5d8857e637",
            price: 65637.95,
            price_sources: [],
            processed_ms: 1711213865769,
          },
        ],
        position_type: "FLAT",
        position_uuid: "81e2078a-173b-4a55-9d58-f2cb6164ee67",
        return_at_close: 0.9938596725624554,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 65544.21,
        close_ms: 1711221178852,
        current_return: 1.0016013313761811,
        initial_entry_price: 65544.21,
        is_closed_position: true,
        miner_hotkey: "5GEv5g4fsEuDT96PPS52Crn8gDYckTxU3qPDgqm9NEKRYoyy",
        net_leverage: 0.0,
        open_ms: 1711217647944,
        orders: [
          {
            leverage: -0.6,
            order_type: "SHORT",
            order_uuid: "d881e047-80e7-4995-874b-559acd520d43",
            price: 65544.21,
            price_sources: [],
            processed_ms: 1711217647943,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "e0bfc04a-fa05-4e01-8585-134b111c99a6",
            price: 65369.28,
            price_sources: [],
            processed_ms: 1711221178852,
          },
        ],
        position_type: "FLAT",
        position_uuid: "64bc115b-7530-46ea-b608-c27f3a123543",
        return_at_close: 1.0003994097785298,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 65376.6,
        close_ms: 1711224849866,
        current_return: 1.005810702912051,
        initial_entry_price: 65376.6,
        is_closed_position: true,
        miner_hotkey: "5GEv5g4fsEuDT96PPS52Crn8gDYckTxU3qPDgqm9NEKRYoyy",
        net_leverage: 0.0,
        open_ms: 1711221316897,
        orders: [
          {
            leverage: -0.6,
            order_type: "SHORT",
            order_uuid: "67002691-5cca-424c-92c8-2251f976ebdb",
            price: 65376.6,
            price_sources: [],
            processed_ms: 1711221316896,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "d3eb5af4-c83a-44f4-b7e9-09b7f2e27a23",
            price: 64743.46,
            price_sources: [],
            processed_ms: 1711224849866,
          },
        ],
        position_type: "FLAT",
        position_uuid: "6c8fdda6-b65c-4131-8bd2-c433329db420",
        return_at_close: 1.0046037300685566,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 64921.42,
        close_ms: 1711228509896,
        current_return: 1.0000408493837627,
        initial_entry_price: 64921.42,
        is_closed_position: true,
        miner_hotkey: "5GEv5g4fsEuDT96PPS52Crn8gDYckTxU3qPDgqm9NEKRYoyy",
        net_leverage: 0.0,
        open_ms: 1711224936662,
        orders: [
          {
            leverage: -0.6,
            order_type: "SHORT",
            order_uuid: "a820f613-f8e3-47c2-a59e-d5b35e082808",
            price: 64921.42,
            price_sources: [],
            processed_ms: 1711224936661,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "86a62e4c-e302-45d2-9b73-e4b17594c5a2",
            price: 64917.0,
            price_sources: [],
            processed_ms: 1711228509896,
          },
        ],
        position_type: "FLAT",
        position_uuid: "2c3e83d4-b1d1-4ed2-896c-cfcb117e7a50",
        return_at_close: 0.9988408003645022,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 64887.37,
        close_ms: 1711232190946,
        current_return: 1.0001338935450768,
        initial_entry_price: 64887.37,
        is_closed_position: true,
        miner_hotkey: "5GEv5g4fsEuDT96PPS52Crn8gDYckTxU3qPDgqm9NEKRYoyy",
        net_leverage: 0.0,
        open_ms: 1711228596688,
        orders: [
          {
            leverage: -0.6,
            order_type: "SHORT",
            order_uuid: "6b843740-99b9-4f96-8e6c-c6be953e4367",
            price: 64887.37,
            price_sources: [],
            processed_ms: 1711228596687,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "3ee02359-6856-4ead-b42c-bed786e7cbc1",
            price: 64872.89,
            price_sources: [],
            processed_ms: 1711232190946,
          },
        ],
        position_type: "FLAT",
        position_uuid: "6ececbe6-e717-4576-ac2b-c2fde42a7ee1",
        return_at_close: 0.9989337328728227,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 64347.51,
        close_ms: 1711250483859,
        current_return: 0.9979689656989058,
        initial_entry_price: 64347.51,
        is_closed_position: true,
        miner_hotkey: "5GEv5g4fsEuDT96PPS52Crn8gDYckTxU3qPDgqm9NEKRYoyy",
        net_leverage: 0.0,
        open_ms: 1711246908630,
        orders: [
          {
            leverage: 0.6,
            order_type: "LONG",
            order_uuid: "5f9ad76e-6dc0-4b3e-a4bf-3f0611a79dc3",
            price: 64347.51,
            price_sources: [],
            processed_ms: 1711246908629,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "5f184f0f-354c-462a-860c-22de10af9a18",
            price: 64129.69,
            price_sources: [],
            processed_ms: 1711250483859,
          },
        ],
        position_type: "FLAT",
        position_uuid: "60637977-a774-495a-8388-1ed3aa0a8148",
        return_at_close: 0.9967714029400672,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 64238.11000000001,
        close_ms: 1711254161512,
        current_return: 0.9979836268532807,
        initial_entry_price: 64238.11,
        is_closed_position: true,
        miner_hotkey: "5GEv5g4fsEuDT96PPS52Crn8gDYckTxU3qPDgqm9NEKRYoyy",
        net_leverage: 0.0,
        open_ms: 1711250570741,
        orders: [
          {
            leverage: 0.6,
            order_type: "LONG",
            order_uuid: "6752b3ca-15e9-498f-a135-7dff454e6be8",
            price: 64238.11,
            price_sources: [],
            processed_ms: 1711250570740,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "ada36b7d-8ec9-4994-90f3-a8f715becb4d",
            price: 64022.23,
            price_sources: [],
            processed_ms: 1711254161512,
          },
        ],
        position_type: "FLAT",
        position_uuid: "31c8db5e-69f9-4933-9243-46224cd57474",
        return_at_close: 0.9967860465010567,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 63923.69999999999,
        close_ms: 1711256763852,
        current_return: 1.002983588246613,
        initial_entry_price: 63923.7,
        is_closed_position: true,
        miner_hotkey: "5GEv5g4fsEuDT96PPS52Crn8gDYckTxU3qPDgqm9NEKRYoyy",
        net_leverage: 0.0,
        open_ms: 1711254265753,
        orders: [
          {
            leverage: 0.6,
            order_type: "LONG",
            order_uuid: "3a5a3c82-fe22-4b39-8e79-6d4ed6317754",
            price: 63923.7,
            price_sources: [],
            processed_ms: 1711254265440,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "646d7792-e137-49c0-83bf-456361d67a03",
            price: 64241.57,
            price_sources: [],
            processed_ms: 1711256763852,
          },
        ],
        position_type: "FLAT",
        position_uuid: "0aabbbf8-cb81-4eb4-9dcc-d9350d2c2752",
        return_at_close: 1.001780007940717,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 64216.06,
        close_ms: 1711260352603,
        current_return: 1.000395975710749,
        initial_entry_price: 64216.06,
        is_closed_position: true,
        miner_hotkey: "5GEv5g4fsEuDT96PPS52Crn8gDYckTxU3qPDgqm9NEKRYoyy",
        net_leverage: 0.0,
        open_ms: 1711257137926,
        orders: [
          {
            leverage: 0.6,
            order_type: "LONG",
            order_uuid: "7ed1e048-dab4-41a8-8eab-062d9a641fe2",
            price: 64216.06,
            price_sources: [],
            processed_ms: 1711257137925,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "efcb9687-e5c8-48d7-b9cf-2eb71d8883dc",
            price: 64258.44,
            price_sources: [],
            processed_ms: 1711260352603,
          },
        ],
        position_type: "FLAT",
        position_uuid: "b22c6968-4db8-4f32-8491-e0b113b3c7aa",
        return_at_close: 0.9991955005398961,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 64243.69,
        close_ms: 1711264005070,
        current_return: 0.9987613569519433,
        initial_entry_price: 64243.69,
        is_closed_position: true,
        miner_hotkey: "5GEv5g4fsEuDT96PPS52Crn8gDYckTxU3qPDgqm9NEKRYoyy",
        net_leverage: 0.0,
        open_ms: 1711260456263,
        orders: [
          {
            leverage: 1.25,
            order_type: "LONG",
            order_uuid: "cbb008f1-f71f-4bf9-a7e0-92acbf0f1b49",
            price: 64243.69,
            price_sources: [],
            processed_ms: 1711260456262,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "b0427449-7d54-4186-882d-ea1af87f3967",
            price: 64180.03,
            price_sources: [],
            processed_ms: 1711264005070,
          },
        ],
        position_type: "FLAT",
        position_uuid: "8f8cdbb7-b3d3-4975-b696-5c473d4b087f",
        return_at_close: 0.9962644535595635,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 64120.81,
        close_ms: 1711267668200,
        current_return: 1.0093598708438025,
        initial_entry_price: 64120.81,
        is_closed_position: true,
        miner_hotkey: "5GEv5g4fsEuDT96PPS52Crn8gDYckTxU3qPDgqm9NEKRYoyy",
        net_leverage: 0.0,
        open_ms: 1711264092456,
        orders: [
          {
            leverage: 1.25,
            order_type: "LONG",
            order_uuid: "8167a1b1-bc2f-48ec-8214-c535b0f863c8",
            price: 64120.81,
            price_sources: [],
            processed_ms: 1711264092455,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "757bb0ac-8bdf-4066-bab1-93ba34159b94",
            price: 64600.94,
            price_sources: [],
            processed_ms: 1711267668200,
          },
        ],
        position_type: "FLAT",
        position_uuid: "15359724-3ca0-457f-b851-d33ba078f350",
        return_at_close: 1.006836471166693,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 64884.46,
        close_ms: 1711271389221,
        current_return: 1.007347243568331,
        initial_entry_price: 64694.33,
        is_closed_position: true,
        miner_hotkey: "5GEv5g4fsEuDT96PPS52Crn8gDYckTxU3qPDgqm9NEKRYoyy",
        net_leverage: 0.0,
        open_ms: 1711267797793,
        orders: [
          {
            leverage: 1.25,
            order_type: "LONG",
            order_uuid: "5e28945a-b09e-49e8-ab1e-4da863bdce7d",
            price: 64694.33,
            price_sources: [],
            processed_ms: 1711267797791,
          },
          {
            leverage: 1.25,
            order_type: "LONG",
            order_uuid: "bab2ef22-6fba-48cd-adab-7606765d3cbf",
            price: 65074.59,
            price_sources: [],
            processed_ms: 1711271369168,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "b174458c-5010-431a-b6db-6f77b1e81534",
            price: 65074.59,
            price_sources: [],
            processed_ms: 1711271389221,
          },
        ],
        position_type: "FLAT",
        position_uuid: "ae7de103-026d-449e-860b-f962b0661d84",
        return_at_close: 1.0023105073504894,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 65013.77,
        close_ms: 1711280534152,
        current_return: 1.002651361396209,
        initial_entry_price: 65013.77,
        is_closed_position: true,
        miner_hotkey: "5GEv5g4fsEuDT96PPS52Crn8gDYckTxU3qPDgqm9NEKRYoyy",
        net_leverage: 0.0,
        open_ms: 1711278743842,
        orders: [
          {
            leverage: 1.25,
            order_type: "LONG",
            order_uuid: "ec9a284a-b9d3-4662-aec6-6e6539ef797d",
            price: 65013.77,
            price_sources: [],
            processed_ms: 1711278743841,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "60953d7a-2c99-49ff-89d8-a3089099a440",
            price: 65151.67,
            price_sources: [],
            processed_ms: 1711280534152,
          },
        ],
        position_type: "FLAT",
        position_uuid: "1210dc7d-83de-4c6e-97cc-474c88ee39bc",
        return_at_close: 1.0001447329927184,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 65327.990000000005,
        close_ms: 1711285993195,
        current_return: 1.0030974472044831,
        initial_entry_price: 65327.99,
        is_closed_position: true,
        miner_hotkey: "5GEv5g4fsEuDT96PPS52Crn8gDYckTxU3qPDgqm9NEKRYoyy",
        net_leverage: 0.0,
        open_ms: 1711282338101,
        orders: [
          {
            leverage: 1.25,
            order_type: "LONG",
            order_uuid: "876fb393-befa-4923-b50b-21eddec0b190",
            price: 65327.99,
            price_sources: [],
            processed_ms: 1711282338099,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "00671dce-028d-430d-a99f-8f7adbe2aeb8",
            price: 65489.87,
            price_sources: [],
            processed_ms: 1711285993195,
          },
        ],
        position_type: "FLAT",
        position_uuid: "1f6b3f2c-856d-427a-9bdc-d1dba625c29f",
        return_at_close: 1.000589703586472,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 65552.51,
        close_ms: 1711319084995,
        current_return: 0.9966337409768337,
        initial_entry_price: 66325.11,
        is_closed_position: true,
        miner_hotkey: "5GEv5g4fsEuDT96PPS52Crn8gDYckTxU3qPDgqm9NEKRYoyy",
        net_leverage: 0.0,
        open_ms: 1711314440197,
        orders: [
          {
            leverage: -1.25,
            order_type: "SHORT",
            order_uuid: "0bafb556-50f2-40c1-a616-d83a45afd653",
            price: 66325.11,
            price_sources: [],
            processed_ms: 1711314440196,
          },
          {
            leverage: 0.625,
            order_type: "LONG",
            order_uuid: "53803260-1779-4441-aae4-3ddd180a9d9f",
            price: 66395.22,
            price_sources: [],
            processed_ms: 1711315365246,
          },
          {
            leverage: 0.125,
            order_type: "LONG",
            order_uuid: "12b83bfd-1b4b-4f9e-9380-2e7bd4a5fafd",
            price: 66330.0,
            price_sources: [],
            processed_ms: 1711316296843,
          },
          {
            leverage: 0.125,
            order_type: "LONG",
            order_uuid: "c7e6fafd-0380-4475-b497-096362420aa6",
            price: 66393.75,
            price_sources: [],
            processed_ms: 1711317294743,
          },
          {
            leverage: 0.1875,
            order_type: "LONG",
            order_uuid: "47258ea6-14b9-4945-8fa2-381236293aa9",
            price: 66814.99,
            price_sources: [],
            processed_ms: 1711318159182,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "138036c6-514c-4f2a-963f-3bac237876ff",
            price: 66743.27,
            price_sources: [],
            processed_ms: 1711319084995,
          },
        ],
        position_type: "FLAT",
        position_uuid: "d57d51ed-1a55-4aed-8d95-9f1b099a5153",
        return_at_close: 0.9941421566243916,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 66696.27111111123,
        close_ms: 1711327480682,
        current_return: 1.000096611320026,
        initial_entry_price: 66848.08,
        is_closed_position: true,
        miner_hotkey: "5GEv5g4fsEuDT96PPS52Crn8gDYckTxU3qPDgqm9NEKRYoyy",
        net_leverage: 0.0,
        open_ms: 1711320016254,
        orders: [
          {
            leverage: -1.25,
            order_type: "SHORT",
            order_uuid: "e7ae79d8-9ab5-4185-b712-e2a6c4eb6632",
            price: 66848.08,
            price_sources: [],
            processed_ms: 1711320016253,
          },
          {
            leverage: 0.625,
            order_type: "LONG",
            order_uuid: "42affea0-5e34-4dc5-8537-80e1c1017584",
            price: 66895.29,
            price_sources: [],
            processed_ms: 1711320945961,
          },
          {
            leverage: 0.3125,
            order_type: "LONG",
            order_uuid: "946450d2-165e-462d-8a50-0b205c631b78",
            price: 67324.66,
            price_sources: [],
            processed_ms: 1711321883026,
          },
          {
            leverage: -1.25,
            order_type: "SHORT",
            order_uuid: "24593d3a-c418-47f0-a5be-1e1542c30b3a",
            price: 67335.4,
            price_sources: [],
            processed_ms: 1711322828030,
          },
          {
            leverage: 0.78125,
            order_type: "LONG",
            order_uuid: "991618a6-90b2-4bd7-8743-7b505b5a1688",
            price: 67195.94,
            price_sources: [],
            processed_ms: 1711323752178,
          },
          {
            leverage: 0.390625,
            order_type: "LONG",
            order_uuid: "1803a158-e086-4be4-a241-dfa28e0c32f5",
            price: 67225.58,
            price_sources: [],
            processed_ms: 1711324689428,
          },
          {
            leverage: 0.1953125,
            order_type: "LONG",
            order_uuid: "df030a19-6c31-4fe0-9c8b-2a173d8971f6",
            price: 66942.16,
            price_sources: [],
            processed_ms: 1711325622195,
          },
          {
            leverage: 0.125,
            order_type: "LONG",
            order_uuid: "40ea1a72-75f7-4e26-8de2-c530eddd141f",
            price: 66878.36,
            price_sources: [],
            processed_ms: 1711326555010,
          },
          {
            leverage: 0.125,
            order_type: "LONG",
            order_uuid: "b7f563dd-d90a-43c6-84bf-107a994d9014",
            price: 66604.42,
            price_sources: [],
            processed_ms: 1711327480682,
          },
        ],
        position_type: "FLAT",
        position_uuid: "dbb4e252-a7a2-4ace-992c-de814d6f1336",
        return_at_close: 0.996971309409651,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: -108962.2499999931,
        close_ms: 1711350492668,
        current_return: 0.99709159197487,
        initial_entry_price: 66577.69,
        is_closed_position: true,
        miner_hotkey: "5GEv5g4fsEuDT96PPS52Crn8gDYckTxU3qPDgqm9NEKRYoyy",
        net_leverage: 0.0,
        open_ms: 1711328416916,
        orders: [
          {
            leverage: -1.25,
            order_type: "SHORT",
            order_uuid: "a3e82933-f233-4445-be42-cec134dde8e5",
            price: 66577.69,
            price_sources: [],
            processed_ms: 1711328416914,
          },
          {
            leverage: 0.125,
            order_type: "LONG",
            order_uuid: "6ac95663-2331-4e7a-9140-4bd09ca1763d",
            price: 66796.48,
            price_sources: [],
            processed_ms: 1711329357732,
          },
          {
            leverage: 0.5625,
            order_type: "LONG",
            order_uuid: "ee548e98-5edd-452f-8b9f-a6f4fe577a97",
            price: 66648.86,
            price_sources: [],
            processed_ms: 1711332157974,
          },
          {
            leverage: 0.28125,
            order_type: "LONG",
            order_uuid: "f6bc3c42-5acf-444b-a211-4ccddf44a558",
            price: 66757.12,
            price_sources: [],
            processed_ms: 1711333992246,
          },
          {
            leverage: 0.140625,
            order_type: "LONG",
            order_uuid: "93c147f9-cac4-4366-964f-f9fe6eb5e9ed",
            price: 66619.0,
            price_sources: [],
            processed_ms: 1711335852933,
          },
          {
            leverage: 0.0703125,
            order_type: "LONG",
            order_uuid: "3d5824d8-1d32-4944-9529-e447c04b34ec",
            price: 66863.94,
            price_sources: [],
            processed_ms: 1711337659050,
          },
          {
            leverage: 0.03515625,
            order_type: "LONG",
            order_uuid: "228b2b8d-64ef-49b2-a660-4689119d0f38",
            price: 67203.83,
            price_sources: [],
            processed_ms: 1711339488172,
          },
          {
            leverage: 0.017578125,
            order_type: "LONG",
            order_uuid: "aa0cd33b-174f-4009-8165-2d8df1cf6146",
            price: 67211.33,
            price_sources: [],
            processed_ms: 1711341323378,
          },
          {
            leverage: 0.0087890625,
            order_type: "LONG",
            order_uuid: "77d88be7-0d7e-464f-be51-fb1cb0505d34",
            price: 67617.78,
            price_sources: [],
            processed_ms: 1711343151332,
          },
          {
            leverage: 0.00439453125,
            order_type: "LONG",
            order_uuid: "cbb1a94d-8233-4430-adf2-bf25e9b5e62f",
            price: 67649.53,
            price_sources: [],
            processed_ms: 1711345024972,
          },
          {
            leverage: 0.002197265625,
            order_type: "LONG",
            order_uuid: "07854799-5a3f-4b15-83a1-07200e3c7ff7",
            price: 67282.98,
            price_sources: [],
            processed_ms: 1711346817126,
          },
          {
            leverage: 0.0010986328125,
            order_type: "LONG",
            order_uuid: "111ffc08-be4a-4a9d-a8e4-7597083ba847",
            price: 67050.01,
            price_sources: [],
            processed_ms: 1711348652266,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "62d10c7c-e3fa-48bf-beee-d38b12888ede",
            price: 67288.71,
            price_sources: [],
            processed_ms: 1711350492668,
          },
        ],
        position_type: "FLAT",
        position_uuid: "f5d8907c-260f-4927-8bcc-cab1c56e13f2",
        return_at_close: 0.9945988629949328,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 66875.3,
        close_ms: 1711359651275,
        current_return: 1.0006250439250366,
        initial_entry_price: 66875.3,
        is_closed_position: true,
        miner_hotkey: "5GEv5g4fsEuDT96PPS52Crn8gDYckTxU3qPDgqm9NEKRYoyy",
        net_leverage: 0.0,
        open_ms: 1711357821796,
        orders: [
          {
            leverage: 1.25,
            order_type: "LONG",
            order_uuid: "f4f36997-45f0-488d-846b-42df5be0b451",
            price: 66875.3,
            price_sources: [],
            processed_ms: 1711357820761,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "02e6d588-aabe-4b07-b2af-a79955348eb9",
            price: 66908.74,
            price_sources: [],
            processed_ms: 1711359651275,
          },
        ],
        position_type: "FLAT",
        position_uuid: "f808273a-420d-4c87-92dd-6fc0848f6add",
        return_at_close: 0.9981234813152241,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 66963.54999999999,
        close_ms: 1711376131402,
        current_return: 1.0563335458455951,
        initial_entry_price: 66866.73,
        is_closed_position: true,
        miner_hotkey: "5GEv5g4fsEuDT96PPS52Crn8gDYckTxU3qPDgqm9NEKRYoyy",
        net_leverage: 0.0,
        open_ms: 1711365143155,
        orders: [
          {
            leverage: 1.25,
            order_type: "LONG",
            order_uuid: "15f78297-72f4-4c3d-91c3-7b6f1e1b0e54",
            price: 66866.73,
            price_sources: [],
            processed_ms: 1711365143153,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "657c1b25-47a1-4f92-b590-5f7717506e58",
            price: 67163.02,
            price_sources: [],
            processed_ms: 1711370513802,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "0d0c7b6b-c1a6-4778-93f2-6bd0dd2d3d39",
            price: 67090.48,
            price_sources: [],
            processed_ms: 1711372336747,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "08b44bd0-bb2e-47d9-9c4c-b91e2b8c43f1",
            price: 67121.25,
            price_sources: [],
            processed_ms: 1711374167121,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "73f9f4ab-2c3e-4083-bb6e-280ae707b706",
            price: 68846.97,
            price_sources: [],
            processed_ms: 1711376131402,
          },
        ],
        position_type: "FLAT",
        position_uuid: "0b722d2e-ed9c-44fa-a1cf-d752966904f4",
        return_at_close: 1.0521082116622127,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 69545.52000000008,
        close_ms: 1711385189376,
        current_return: 1.0010535440366068,
        initial_entry_price: 69272.14,
        is_closed_position: true,
        miner_hotkey: "5GEv5g4fsEuDT96PPS52Crn8gDYckTxU3qPDgqm9NEKRYoyy",
        net_leverage: 0.0,
        open_ms: 1711376932427,
        orders: [
          {
            leverage: 1.25,
            order_type: "LONG",
            order_uuid: "e5822990-2898-4273-b57f-a76df5bf498b",
            price: 69272.14,
            price_sources: [],
            processed_ms: 1711376932426,
          },
          {
            leverage: -0.625,
            order_type: "SHORT",
            order_uuid: "db6295a1-6522-48dc-92a7-8c24326e5e5d",
            price: 69244.36,
            price_sources: [],
            processed_ms: 1711378079850,
          },
          {
            leverage: -0.5,
            order_type: "SHORT",
            order_uuid: "4caa1379-6f63-4aed-844d-59063605bebf",
            price: 69238.52,
            price_sources: [],
            processed_ms: 1711378357976,
          },
          {
            leverage: -5.125,
            order_type: "SHORT",
            order_uuid: "9a534630-d296-4fd4-a659-07600edb58d1",
            price: 70129.37,
            price_sources: [],
            processed_ms: 1711385189376,
          },
        ],
        position_type: "FLAT",
        position_uuid: "912bbdc0-5721-4d98-b9d3-c2a489d2486d",
        return_at_close: 0.9985509101765154,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 70750.63294117646,
        close_ms: 1711396947603,
        current_return: 1.0346492889680203,
        initial_entry_price: 70635.36,
        is_closed_position: true,
        miner_hotkey: "5GEv5g4fsEuDT96PPS52Crn8gDYckTxU3qPDgqm9NEKRYoyy",
        net_leverage: 0.0,
        open_ms: 1711387002316,
        orders: [
          {
            leverage: 5.0,
            order_type: "LONG",
            order_uuid: "be1cf15c-08b4-44f3-9c05-a3053d0d2a9d",
            price: 70635.36,
            price_sources: [],
            processed_ms: 1711387002314,
          },
          {
            leverage: -2.5,
            order_type: "SHORT",
            order_uuid: "5333f151-6d03-44a6-ba79-19fd81d1898f",
            price: 70404.43,
            price_sources: [],
            processed_ms: 1711388815449,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "2f63d516-aabc-4ba1-9936-9629fe0afea4",
            price: 70594.54,
            price_sources: [],
            processed_ms: 1711390636879,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "26a0839c-7f23-41df-ab3f-1ae9ff4b5ef8",
            price: 70717.99,
            price_sources: [],
            processed_ms: 1711392452489,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "55dc17b2-feca-4606-8552-891a3da796af",
            price: 70653.2,
            price_sources: [],
            processed_ms: 1711394265015,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "002f86d2-6ebe-4f2e-a357-67d42f9d0443",
            price: 70844.04,
            price_sources: [],
            processed_ms: 1711396090297,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "76415a9e-b37f-4489-b622-5b5312b6e4ad",
            price: 71038.57,
            price_sources: [],
            processed_ms: 1711396947603,
          },
        ],
        position_type: "FLAT",
        position_uuid: "1a57c1f9-162f-471a-ab5f-ee7674fd6759",
        return_at_close: 1.017060251055564,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 70875.68923076923,
        close_ms: 1711401261642,
        current_return: 0.9696461698552908,
        initial_entry_price: 70863.38,
        is_closed_position: true,
        miner_hotkey: "5GEv5g4fsEuDT96PPS52Crn8gDYckTxU3qPDgqm9NEKRYoyy",
        net_leverage: 0.0,
        open_ms: 1711397902176,
        orders: [
          {
            leverage: 5.0,
            order_type: "LONG",
            order_uuid: "969c9a44-4bf2-4e80-99a9-162ae267c6c6",
            price: 70863.38,
            price_sources: [],
            processed_ms: 1711397902174,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "07d02eaa-2596-4a32-9ca6-b7574f9dc383",
            price: 70916.72,
            price_sources: [],
            processed_ms: 1711399717986,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "4f50a034-702f-4f4a-9c7e-7dc8a6fb447f",
            price: 70544.77,
            price_sources: [],
            processed_ms: 1711401261642,
          },
        ],
        position_type: "FLAT",
        position_uuid: "ee98613f-d70d-473b-a5f1-d4d46985eeb0",
        return_at_close: 0.957040769647172,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 70093.43,
        close_ms: 1711402236215,
        current_return: 0.9664968599767483,
        initial_entry_price: 70093.43,
        is_closed_position: true,
        miner_hotkey: "5GEv5g4fsEuDT96PPS52Crn8gDYckTxU3qPDgqm9NEKRYoyy",
        net_leverage: 0.0,
        open_ms: 1711401535175,
        orders: [
          {
            leverage: 5.0,
            order_type: "LONG",
            order_uuid: "e60b906b-44ec-4c8b-80d5-e72804197ea9",
            price: 70093.43,
            price_sources: [],
            processed_ms: 1711401535173,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "1585a9d9-ef44-41fd-8963-38e7998c387d",
            price: 69623.76,
            price_sources: [],
            processed_ms: 1711402236215,
          },
        ],
        position_type: "FLAT",
        position_uuid: "eb4a259c-3f6a-4709-9107-d7012ab8c552",
        return_at_close: 0.9568318913769808,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 70069.76076923076,
        close_ms: 1711406752229,
        current_return: 1.0496564484219693,
        initial_entry_price: 69986.58,
        is_closed_position: true,
        miner_hotkey: "5GEv5g4fsEuDT96PPS52Crn8gDYckTxU3qPDgqm9NEKRYoyy",
        net_leverage: 0.0,
        open_ms: 1711403350442,
        orders: [
          {
            leverage: 5.0,
            order_type: "LONG",
            order_uuid: "ef171b12-7449-4754-89b9-af35206bcf26",
            price: 69986.58,
            price_sources: [],
            processed_ms: 1711403350418,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "3bb5663b-1452-43cb-9e93-f8ed28d48e7a",
            price: 70347.03,
            price_sources: [],
            processed_ms: 1711405168060,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "ae2ef4f5-7867-4456-b3b6-d100679cfd77",
            price: 70604.42,
            price_sources: [],
            processed_ms: 1711406752229,
          },
        ],
        position_type: "FLAT",
        position_uuid: "699db451-775b-4e8b-9fa7-bd6917851b47",
        return_at_close: 1.0360109145924836,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 70447.87846153845,
        close_ms: 1711409896687,
        current_return: 0.9522214544833763,
        initial_entry_price: 70466.67,
        is_closed_position: true,
        miner_hotkey: "5GEv5g4fsEuDT96PPS52Crn8gDYckTxU3qPDgqm9NEKRYoyy",
        net_leverage: 0.0,
        open_ms: 1711406979506,
        orders: [
          {
            leverage: 5.0,
            order_type: "LONG",
            order_uuid: "5fc204cc-8549-4bb9-8736-c30c7f1b6526",
            price: 70466.67,
            price_sources: [],
            processed_ms: 1711406979504,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "f6aad5b8-5ffa-407d-8c54-170c905d69d7",
            price: 70385.24,
            price_sources: [],
            processed_ms: 1711408798300,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "a47e979a-f04c-4582-b646-b77b24f1e286",
            price: 69929.91,
            price_sources: [],
            processed_ms: 1711409896687,
          },
        ],
        position_type: "FLAT",
        position_uuid: "2babd263-8a15-4fd5-ac62-721f82b8eb2c",
        return_at_close: 0.9398425755750923,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 69193.31594936714,
        close_ms: 1711428348149,
        current_return: 1.0508433400442736,
        initial_entry_price: 69930.57,
        is_closed_position: true,
        miner_hotkey: "5GEv5g4fsEuDT96PPS52Crn8gDYckTxU3qPDgqm9NEKRYoyy",
        net_leverage: 0.0,
        open_ms: 1711410617462,
        orders: [
          {
            leverage: 5.0,
            order_type: "LONG",
            order_uuid: "4fbb72a2-4dad-4118-80db-135a61278eea",
            price: 69930.57,
            price_sources: [],
            processed_ms: 1711410617460,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "791b4f1d-aad8-4905-a50e-7214bb3147df",
            price: 69912.2,
            price_sources: [],
            processed_ms: 1711412429153,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "780398ae-d833-41ff-8558-bb29ee564130",
            price: 70029.21,
            price_sources: [],
            processed_ms: 1711414248225,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "b2cbac7e-1410-4adb-9282-78047b7b6d11",
            price: 70235.88,
            price_sources: [],
            processed_ms: 1711416064787,
          },
          {
            leverage: -4.75,
            order_type: "SHORT",
            order_uuid: "4dcd44a0-6f05-463a-9148-4b85847c2615",
            price: 70235.6,
            price_sources: [],
            processed_ms: 1711417881775,
          },
          {
            leverage: -2.375,
            order_type: "SHORT",
            order_uuid: "630eeeb9-b852-43aa-bdeb-427b1c3935a2",
            price: 70244.0,
            price_sources: [],
            processed_ms: 1711419695019,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "3c124f5d-d3ff-42b4-ae6c-ca3fedae52b8",
            price: 70315.29,
            price_sources: [],
            processed_ms: 1711421510602,
          },
          {
            leverage: -1.9375,
            order_type: "SHORT",
            order_uuid: "bc989079-0e17-4526-a1ee-36426388a2a2",
            price: 70502.95,
            price_sources: [],
            processed_ms: 1711423330958,
          },
          {
            leverage: -0.96875,
            order_type: "SHORT",
            order_uuid: "bf6abb2a-fc1e-4c98-b9e9-1604eba85fce",
            price: 70517.58,
            price_sources: [],
            processed_ms: 1711425148219,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "225814ab-1fda-4bcd-93c9-6f6b3f9eb563",
            price: 70527.5,
            price_sources: [],
            processed_ms: 1711426960937,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "e85f1c60-07c0-4723-9837-0a64f2223520",
            price: 70633.52,
            price_sources: [],
            processed_ms: 1711428348149,
          },
        ],
        position_type: "FLAT",
        position_uuid: "9a166db9-fdb7-469b-a797-e72943320f22",
        return_at_close: 1.0308773165834324,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 71455.36999999995,
        close_ms: 1711437858261,
        current_return: 0.9974068585043775,
        initial_entry_price: 70534.62,
        is_closed_position: true,
        miner_hotkey: "5GEv5g4fsEuDT96PPS52Crn8gDYckTxU3qPDgqm9NEKRYoyy",
        net_leverage: 0.0,
        open_ms: 1711430594761,
        orders: [
          {
            leverage: 5.0,
            order_type: "LONG",
            order_uuid: "386ecfc3-8063-44ec-b0b2-209e34aafeac",
            price: 70534.62,
            price_sources: [],
            processed_ms: 1711430594757,
          },
          {
            leverage: -2.5,
            order_type: "SHORT",
            order_uuid: "b63900c2-742c-4f58-991a-728ff8ecf7bd",
            price: 70418.3,
            price_sources: [],
            processed_ms: 1711432409369,
          },
          {
            leverage: -1.25,
            order_type: "SHORT",
            order_uuid: "37accaf6-d361-4042-ad19-7e8cbc1df0ab",
            price: 70331.21,
            price_sources: [],
            processed_ms: 1711434226997,
          },
          {
            leverage: -0.625,
            order_type: "SHORT",
            order_uuid: "a46fb0f0-b371-4e82-a671-f54bcd9bf8cf",
            price: 70485.97,
            price_sources: [],
            processed_ms: 1711436038489,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "4e06ae9d-c90b-426d-9a57-b57e2b641700",
            price: 71162.72,
            price_sources: [],
            processed_ms: 1711437858261,
          },
        ],
        position_type: "FLAT",
        position_uuid: "2adc14d2-5646-4b02-9ceb-b3502358b6fb",
        return_at_close: 0.9874327899193337,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 71389.73000000004,
        close_ms: 1711446939162,
        current_return: 1.0013990259052732,
        initial_entry_price: 70835.0,
        is_closed_position: true,
        miner_hotkey: "5GEv5g4fsEuDT96PPS52Crn8gDYckTxU3qPDgqm9NEKRYoyy",
        net_leverage: 0.0,
        open_ms: 1711439669568,
        orders: [
          {
            leverage: -5.0,
            order_type: "SHORT",
            order_uuid: "b8749336-3bfd-4ad0-9fb0-ea26b1647163",
            price: 70835.0,
            price_sources: [],
            processed_ms: 1711439669566,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "ceacfef7-80ff-47cf-a510-72a83440f763",
            price: 70656.65,
            price_sources: [],
            processed_ms: 1711441498092,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "77b74a3e-ac22-42c7-8e61-e213edb30be4",
            price: 70601.06,
            price_sources: [],
            processed_ms: 1711443309721,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "3c3f7ccc-74be-4ba4-8fcc-ef2acaf19da8",
            price: 71062.38,
            price_sources: [],
            processed_ms: 1711445127151,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "0295fd87-8e65-4ed2-afb4-be2af2be1be1",
            price: 71191.53,
            price_sources: [],
            processed_ms: 1711446939162,
          },
        ],
        position_type: "FLAT",
        position_uuid: "c1731b66-33db-424f-8de8-3cf3a0546aa7",
        return_at_close: 0.9913850356462205,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 74585.1300000002,
        close_ms: 1711457835247,
        current_return: 1.0131433419495721,
        initial_entry_price: 71020.94,
        is_closed_position: true,
        miner_hotkey: "5GEv5g4fsEuDT96PPS52Crn8gDYckTxU3qPDgqm9NEKRYoyy",
        net_leverage: 0.0,
        open_ms: 1711448758974,
        orders: [
          {
            leverage: -5.0,
            order_type: "SHORT",
            order_uuid: "cde084c1-41b7-4203-9b26-82a2466b97a8",
            price: 71020.94,
            price_sources: [],
            processed_ms: 1711448758971,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "31a84e59-c5ae-44d8-9207-9de42ac74fe1",
            price: 70961.87,
            price_sources: [],
            processed_ms: 1711450572302,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "44dbad84-ef4d-4559-9456-cd6c5c644f45",
            price: 70837.0,
            price_sources: [],
            processed_ms: 1711452406071,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "6c478894-b53f-49c7-8f61-b48f4471976c",
            price: 70695.0,
            price_sources: [],
            processed_ms: 1711454286993,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "b73521cc-4a90-4461-b032-3f371972a92f",
            price: 70870.45,
            price_sources: [],
            processed_ms: 1711456029218,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "089a8b97-6f16-468b-b3fe-cf215952e29f",
            price: 70851.32,
            price_sources: [],
            processed_ms: 1711457835247,
          },
        ],
        position_type: "FLAT",
        position_uuid: "a451f89e-8674-4205-8cb7-d863b5cc2893",
        return_at_close: 1.0030119085300764,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 72434.98999999998,
        close_ms: 1711463496580,
        current_return: 1.0503702971603623,
        initial_entry_price: 70775.59,
        is_closed_position: true,
        miner_hotkey: "5GEv5g4fsEuDT96PPS52Crn8gDYckTxU3qPDgqm9NEKRYoyy",
        net_leverage: 0.0,
        open_ms: 1711459678380,
        orders: [
          {
            leverage: -5.0,
            order_type: "SHORT",
            order_uuid: "a9ee34fb-07e5-4649-9740-1d7c8c2fa51c",
            price: 70775.59,
            price_sources: [],
            processed_ms: 1711459678377,
          },
          {
            leverage: 2.5,
            order_type: "LONG",
            order_uuid: "66b41dd5-412a-4eb0-ba2b-f7003d8bae33",
            price: 70382.93,
            price_sources: [],
            processed_ms: 1711461571986,
          },
          {
            leverage: 1.25,
            order_type: "LONG",
            order_uuid: "a124e971-8831-4398-9760-31b4de03aa33",
            price: 69901.51,
            price_sources: [],
            processed_ms: 1711463293086,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "2a02c0bf-17b1-436a-86e1-c237d84208a7",
            price: 69583.0,
            price_sources: [],
            processed_ms: 1711463496580,
          },
        ],
        position_type: "FLAT",
        position_uuid: "7d5e7612-2a1b-45ac-997b-0576485156a3",
        return_at_close: 1.0398665941887588,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 70272.98,
        close_ms: 1711466434043,
        current_return: 0.963097480710225,
        initial_entry_price: 70272.98,
        is_closed_position: true,
        miner_hotkey: "5GEv5g4fsEuDT96PPS52Crn8gDYckTxU3qPDgqm9NEKRYoyy",
        net_leverage: 0.0,
        open_ms: 1711465101251,
        orders: [
          {
            leverage: 5.0,
            order_type: "LONG",
            order_uuid: "9f20bff3-6954-4d70-a51f-e5172eaa4eff",
            price: 70272.98,
            price_sources: [],
            processed_ms: 1711465101248,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "2aea12b8-61ef-4238-8d71-9b7dcdc65881",
            price: 69754.33,
            price_sources: [],
            processed_ms: 1711466434043,
          },
        ],
        position_type: "FLAT",
        position_uuid: "915dc63e-aa86-467d-861a-02d69b24e16f",
        return_at_close: 0.9534665059031228,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 70284.34,
        close_ms: 1711470391659,
        current_return: 0.9612751574532825,
        initial_entry_price: 70284.34,
        is_closed_position: true,
        miner_hotkey: "5GEv5g4fsEuDT96PPS52Crn8gDYckTxU3qPDgqm9NEKRYoyy",
        net_leverage: 0.0,
        open_ms: 1711468737901,
        orders: [
          {
            leverage: 5.0,
            order_type: "LONG",
            order_uuid: "aedffe43-cd26-495c-be19-d792c04f00ed",
            price: 70284.34,
            price_sources: [],
            processed_ms: 1711468737898,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "d6fce934-6e09-43d7-9d39-1324740a6529",
            price: 69739.99,
            price_sources: [],
            processed_ms: 1711470391659,
          },
        ],
        position_type: "FLAT",
        position_uuid: "e6145d9d-201d-4c75-9664-2795d19dc526",
        return_at_close: 0.9516624058787497,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 69594.91495867768,
        close_ms: 1711488677562,
        current_return: 1.0571288653532187,
        initial_entry_price: 69725.84,
        is_closed_position: true,
        miner_hotkey: "5GEv5g4fsEuDT96PPS52Crn8gDYckTxU3qPDgqm9NEKRYoyy",
        net_leverage: 0.0,
        open_ms: 1711470555240,
        orders: [
          {
            leverage: 5.0,
            order_type: "LONG",
            order_uuid: "57cf3999-6190-48cc-9580-546a59d14ec7",
            price: 69725.84,
            price_sources: [],
            processed_ms: 1711470555238,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "fee1c568-1fdd-4fc3-848e-4021eb6035ab",
            price: 69800.56,
            price_sources: [],
            processed_ms: 1711472373417,
          },
          {
            leverage: -3.25,
            order_type: "SHORT",
            order_uuid: "4e28e4f0-4fe1-49d5-8dcc-2dc49e5c76b3",
            price: 70006.98,
            price_sources: [],
            processed_ms: 1711474185318,
          },
          {
            leverage: -1.625,
            order_type: "SHORT",
            order_uuid: "37666d71-3f1a-4981-90f1-985ca0551d2b",
            price: 70061.89,
            price_sources: [],
            processed_ms: 1711476000538,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "300bfb02-68c5-4bba-adb4-1158ce02c8aa",
            price: 70248.39,
            price_sources: [],
            processed_ms: 1711477824829,
          },
          {
            leverage: -1.5625,
            order_type: "SHORT",
            order_uuid: "a18f7b92-00fa-409e-b4a3-09d0fe82e0d6",
            price: 69968.23,
            price_sources: [],
            processed_ms: 1711479633331,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "28d51338-6cc4-4d6e-a7f3-310dd83761c6",
            price: 69767.61,
            price_sources: [],
            processed_ms: 1711481445372,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "8fb07d6b-2d31-4ba0-8a9e-b69f4a5ab2b0",
            price: 69445.99,
            price_sources: [],
            processed_ms: 1711483270745,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "b0f9680a-a239-46ef-afb6-0c468a42a75c",
            price: 69772.85,
            price_sources: [],
            processed_ms: 1711485083234,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "c79048bf-b5ce-48ae-a25f-a6dafc71bf6d",
            price: 69885.24,
            price_sources: [],
            processed_ms: 1711486898515,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "8e4db606-1ab9-4cd4-99be-bcad17a08bbd",
            price: 70121.64,
            price_sources: [],
            processed_ms: 1711488677562,
          },
        ],
        position_type: "FLAT",
        position_uuid: "14c0842f-b1f7-4896-8903-6cf78313fa30",
        return_at_close: 1.0411397912647513,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 70165.44230769231,
        close_ms: 1711491770349,
        current_return: 0.9648202627993803,
        initial_entry_price: 70209.45,
        is_closed_position: true,
        miner_hotkey: "5GEv5g4fsEuDT96PPS52Crn8gDYckTxU3qPDgqm9NEKRYoyy",
        net_leverage: 0.0,
        open_ms: 1711488780146,
        orders: [
          {
            leverage: 5.0,
            order_type: "LONG",
            order_uuid: "3085fd15-dbc6-47cc-b1ed-d2aeb673e90a",
            price: 70209.45,
            price_sources: [],
            processed_ms: 1711488780141,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "2c35fcec-b4ac-47cd-b494-84bd69c58c9a",
            price: 70018.75,
            price_sources: [],
            processed_ms: 1711490638931,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "8595344d-25ee-4fc2-b0d3-ecb682edd737",
            price: 69785.45,
            price_sources: [],
            processed_ms: 1711491770349,
          },
        ],
        position_type: "FLAT",
        position_uuid: "8b431761-a9f2-426b-a36e-b48da784a0d2",
        return_at_close: 0.9522775993829883,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 70008.51318181818,
        close_ms: 1711500212301,
        current_return: 1.05041262701334,
        initial_entry_price: 69886.36,
        is_closed_position: true,
        miner_hotkey: "5GEv5g4fsEuDT96PPS52Crn8gDYckTxU3qPDgqm9NEKRYoyy",
        net_leverage: 0.0,
        open_ms: 1711492349833,
        orders: [
          {
            leverage: 5.0,
            order_type: "LONG",
            order_uuid: "494b8c30-c940-44d3-842b-e545f645a60f",
            price: 69886.36,
            price_sources: [],
            processed_ms: 1711492349830,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "57cdfe16-be12-4d25-9a83-8031000b3e7b",
            price: 70250.13,
            price_sources: [],
            processed_ms: 1711494168388,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "ce01d58d-503d-4739-b90a-65b367cfd73d",
            price: 70078.5,
            price_sources: [],
            processed_ms: 1711495980972,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "6fb029c1-d110-4405-9e4e-318707ead055",
            price: 69976.4,
            price_sources: [],
            processed_ms: 1711497793542,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "518d7a36-e7ac-456a-8cdc-8b2490593299",
            price: 70136.2,
            price_sources: [],
            processed_ms: 1711499620941,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "cc281a21-59dd-49d9-9ea6-154cff76ca08",
            price: 70328.8,
            price_sources: [],
            processed_ms: 1711500212301,
          },
        ],
        position_type: "FLAT",
        position_uuid: "c7e200e5-4dc2-4feb-a5f8-752682c87958",
        return_at_close: 1.0273035492190465,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 70501.234375,
        close_ms: 1711505323744,
        current_return: 0.9741484767280241,
        initial_entry_price: 70555.03,
        is_closed_position: true,
        miner_hotkey: "5GEv5g4fsEuDT96PPS52Crn8gDYckTxU3qPDgqm9NEKRYoyy",
        net_leverage: 0.0,
        open_ms: 1711501429523,
        orders: [
          {
            leverage: 5.0,
            order_type: "LONG",
            order_uuid: "ae7eea55-deec-4ae9-8bbc-3c7281b4d0b8",
            price: 70555.03,
            price_sources: [],
            processed_ms: 1711501429520,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "a13eade6-aea4-4798-9eb5-c75d0eb71a92",
            price: 70519.28,
            price_sources: [],
            processed_ms: 1711503247919,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "94af8a1e-c112-4e21-a619-28dfc9e48040",
            price: 70303.87,
            price_sources: [],
            processed_ms: 1711505061376,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "9772a82a-bd24-4a0c-964b-c48d96bdc22b",
            price: 70273.24,
            price_sources: [],
            processed_ms: 1711505323744,
          },
        ],
        position_type: "FLAT",
        position_uuid: "88a434dd-816b-4ea7-8fbb-3915bbac9e65",
        return_at_close: 0.9585621011003757,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 70466.38727272727,
        close_ms: 1711515151433,
        current_return: 0.9428313135428286,
        initial_entry_price: 70495.76,
        is_closed_position: true,
        miner_hotkey: "5GEv5g4fsEuDT96PPS52Crn8gDYckTxU3qPDgqm9NEKRYoyy",
        net_leverage: 0.0,
        open_ms: 1711506886522,
        orders: [
          {
            leverage: 5.0,
            order_type: "LONG",
            order_uuid: "1d2e9acd-e727-4728-9fbb-fe0c75e6bd59",
            price: 70495.76,
            price_sources: [],
            processed_ms: 1711506886516,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "cc5779fa-cf0c-4bb9-bc5c-575486475a37",
            price: 70585.35,
            price_sources: [],
            processed_ms: 1711508693243,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "a985a1bb-d03a-4dd5-be46-7493fd27bf2a",
            price: 70465.51,
            price_sources: [],
            processed_ms: 1711510512147,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "9c71bd52-1a4b-47e3-8d97-3af28bcd0c07",
            price: 70397.93,
            price_sources: [],
            processed_ms: 1711512353055,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "7424a586-bfba-4622-80cf-d8d696814b33",
            price: 70318.85,
            price_sources: [],
            processed_ms: 1711514199137,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "bdab84b1-2bad-4526-aaa3-e70adb0853dd",
            price: 70100.01,
            price_sources: [],
            processed_ms: 1711515151433,
          },
        ],
        position_type: "FLAT",
        position_uuid: "8819f67a-5de5-4f57-add9-a84079ab8b53",
        return_at_close: 0.9220890246448863,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
    ],
    thirty_day_returns: 1.0,
  },
  "5GGyWJGvhh8AZx3GQZHsGzHhBueZnyQKuTBGGdXe4yVca34H": {
    all_time_returns: 1.0260639441449373,
    n_positions: 2,
    percentage_profitable: 1.0,
    positions: [
      {
        average_entry_price: 160.59283333333335,
        close_ms: 1719578517723,
        current_return: 1.001061531469272,
        initial_entry_price: 160.617,
        is_closed_position: true,
        miner_hotkey: "5GGyWJGvhh8AZx3GQZHsGzHhBueZnyQKuTBGGdXe4yVca34H",
        net_leverage: 0.0,
        open_ms: 1719577315027,
        orders: [
          {
            leverage: -3.0,
            order_type: "SHORT",
            order_uuid: "b38347eb-32a5-4a81-81f7-0d0aa20e51c2",
            price: 160.617,
            price_sources: [],
            processed_ms: 1719577315020,
          },
          {
            leverage: 2.5,
            order_type: "LONG",
            order_uuid: "c72c947f-113d-478d-88ba-218565c6c80a",
            price: 160.612,
            price_sources: [],
            processed_ms: 1719577358150,
          },
          {
            leverage: -2.5,
            order_type: "SHORT",
            order_uuid: "f7d0ed7c-e3c9-4a92-a47f-cb30d05a5169",
            price: 160.583,
            price_sources: [],
            processed_ms: 1719577731860,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "9effb826-adf4-46a5-9c94-db2172593f96",
            price: 160.536,
            price_sources: [],
            processed_ms: 1719578517723,
          },
        ],
        position_type: "FLAT",
        position_uuid: "b87d8f48-70d9-40cf-9d9d-c49dd64d173a",
        return_at_close: 1.0006761227796563,
        trade_pair: ["USDJPY", "USD/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 3385.1599999999994,
        close_ms: 1720001566300,
        current_return: 1.0267053846790106,
        initial_entry_price: 3385.16,
        is_closed_position: true,
        miner_hotkey: "5GGyWJGvhh8AZx3GQZHsGzHhBueZnyQKuTBGGdXe4yVca34H",
        net_leverage: 0.0,
        open_ms: 1719737322296,
        orders: [
          {
            leverage: -1.3,
            order_type: "SHORT",
            order_uuid: "03905d7a-4e23-4eb1-9754-438a676d9b03",
            price: 3385.16,
            price_sources: [],
            processed_ms: 1719737320555,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "bc045308-ba1c-4195-aee4-9813f9b95c8b",
            price: 3315.62,
            price_sources: [],
            processed_ms: 1720001566300,
          },
        ],
        position_type: "FLAT",
        position_uuid: "44b08e9e-876f-4d61-bcb0-1058229a95bd",
        return_at_close: 1.025370667678928,
        trade_pair: ["ETHUSD", "ETH/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 65076.0,
        close_ms: 0,
        current_return: 0.9882707910750507,
        initial_entry_price: 65076.0,
        is_closed_position: false,
        miner_hotkey: "5GGyWJGvhh8AZx3GQZHsGzHhBueZnyQKuTBGGdXe4yVca34H",
        net_leverage: 0.11,
        open_ms: 1718389421894,
        orders: [
          {
            leverage: 0.11,
            order_type: "LONG",
            order_uuid: "2ca50b2c-1011-4f08-9e23-0ae33f2232f2",
            price: 65076.0,
            price_sources: [],
            processed_ms: 1718389421066,
          },
        ],
        position_type: "LONG",
        position_uuid: "937362ff-1e62-48e1-a5f8-9a678c4d9c99",
        return_at_close: 0.9882164361815415,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
    ],
    thirty_day_returns: 1.0260639441449373,
  },
  "5GKMqtvfWMF7phS6DnTDtym1DHJGPqbfq1jTM23aKnUG5Njx": {
    all_time_returns: 1.0,
    n_positions: 0,
    percentage_profitable: 0.0,
    positions: [
      {
        average_entry_price: 64312.52,
        close_ms: 1711241592737,
        current_return: 1.001099894701685,
        initial_entry_price: 64312.52,
        is_closed_position: true,
        miner_hotkey: "5GKMqtvfWMF7phS6DnTDtym1DHJGPqbfq1jTM23aKnUG5Njx",
        net_leverage: 0.0,
        open_ms: 1711238015580,
        orders: [
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "cfe661a7-3e69-45cb-b6ee-18eda57e04a2",
            price: 64312.52,
            price_sources: [],
            processed_ms: 1711238015579,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "e9b05afb-d952-4bbd-963d-e863208dc37f",
            price: 64548.31,
            price_sources: [],
            processed_ms: 1711241592737,
          },
        ],
        position_type: "FLAT",
        position_uuid: "319d58fc-68ac-44d9-bff0-22070f748aa9",
        return_at_close: 1.0004992347648638,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 64222.560000000005,
        close_ms: 1711252581655,
        current_return: 0.9994251085599827,
        initial_entry_price: 64222.56,
        is_closed_position: true,
        miner_hotkey: "5GKMqtvfWMF7phS6DnTDtym1DHJGPqbfq1jTM23aKnUG5Njx",
        net_leverage: 0.0,
        open_ms: 1711249055331,
        orders: [
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "e216f452-3131-4ef6-af91-29ce31250537",
            price: 64222.56,
            price_sources: [],
            processed_ms: 1711249055330,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "ce1492e0-5988-46f0-bf56-97dcc9d0fa27",
            price: 64099.49,
            price_sources: [],
            processed_ms: 1711252581655,
          },
        ],
        position_type: "FLAT",
        position_uuid: "194f1d17-f154-431d-982d-e862fb88afe0",
        return_at_close: 0.9988254534948466,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 64044.49,
        close_ms: 1711256746690,
        current_return: 1.0009231707520818,
        initial_entry_price: 64044.49,
        is_closed_position: true,
        miner_hotkey: "5GKMqtvfWMF7phS6DnTDtym1DHJGPqbfq1jTM23aKnUG5Njx",
        net_leverage: 0.0,
        open_ms: 1711252668693,
        orders: [
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "a5f7d571-08cf-48f3-8e63-4d300d80f91f",
            price: 64044.49,
            price_sources: [],
            processed_ms: 1711252668692,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "b297e82e-0b1e-463a-aa68-ae18083fc7d2",
            price: 64241.57,
            price_sources: [],
            processed_ms: 1711256746690,
          },
        ],
        position_type: "FLAT",
        position_uuid: "cab874d6-a765-4e08-a3db-fa0197a05a04",
        return_at_close: 1.0003226168496304,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 64182.34,
        close_ms: 1711257226513,
        current_return: 0.9997454439959652,
        initial_entry_price: 64182.34,
        is_closed_position: true,
        miner_hotkey: "5GKMqtvfWMF7phS6DnTDtym1DHJGPqbfq1jTM23aKnUG5Njx",
        net_leverage: 0.0,
        open_ms: 1711256833547,
        orders: [
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "db7748dc-674f-47ce-a8c1-9345d9755f16",
            price: 64182.34,
            price_sources: [],
            processed_ms: 1711256833546,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "cd32901c-13c0-475a-9190-e9333fea1539",
            price: 64127.88,
            price_sources: [],
            processed_ms: 1711257226513,
          },
        ],
        position_type: "FLAT",
        position_uuid: "75692b7b-218f-4bcd-b84e-d8194bfe47f4",
        return_at_close: 0.9991455967295676,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 64170.56999999999,
        close_ms: 1711260921184,
        current_return: 1.0034223242835463,
        initial_entry_price: 64170.57,
        is_closed_position: true,
        miner_hotkey: "5GKMqtvfWMF7phS6DnTDtym1DHJGPqbfq1jTM23aKnUG5Njx",
        net_leverage: 0.0,
        open_ms: 1711257313459,
        orders: [
          {
            leverage: 1.25,
            order_type: "LONG",
            order_uuid: "717f7d97-50a6-4d86-893d-28e30c586a02",
            price: 64170.57,
            price_sources: [],
            processed_ms: 1711257313458,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "60ead512-9003-4c1c-93d5-33bb1791a1b5",
            price: 64346.26,
            price_sources: [],
            processed_ms: 1711260921184,
          },
        ],
        position_type: "FLAT",
        position_uuid: "6b87ce10-e49f-4d2a-a723-97d748623f4c",
        return_at_close: 1.0009137684728375,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 64346.259999999995,
        close_ms: 1711264550692,
        current_return: 0.9963581799470553,
        initial_entry_price: 64346.26,
        is_closed_position: true,
        miner_hotkey: "5GKMqtvfWMF7phS6DnTDtym1DHJGPqbfq1jTM23aKnUG5Njx",
        net_leverage: 0.0,
        open_ms: 1711260940693,
        orders: [
          {
            leverage: 1.25,
            order_type: "LONG",
            order_uuid: "0adf5055-4fac-4626-8586-b37781e79a06",
            price: 64346.26,
            price_sources: [],
            processed_ms: 1711260940692,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "8e56329e-e521-47a0-9427-4ad56e3d5b63",
            price: 64158.79,
            price_sources: [],
            processed_ms: 1711264550692,
          },
        ],
        position_type: "FLAT",
        position_uuid: "6013dc52-f427-4db7-b28e-dac7bb989641",
        return_at_close: 0.9938672844971878,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 64135.87000000001,
        close_ms: 1711268209172,
        current_return: 1.0154359798970527,
        initial_entry_price: 64135.87,
        is_closed_position: true,
        miner_hotkey: "5GKMqtvfWMF7phS6DnTDtym1DHJGPqbfq1jTM23aKnUG5Njx",
        net_leverage: 0.0,
        open_ms: 1711264637343,
        orders: [
          {
            leverage: 1.25,
            order_type: "LONG",
            order_uuid: "1b297754-faaa-4f18-b312-271119bcd52f",
            price: 64135.87,
            price_sources: [],
            processed_ms: 1711264637342,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "f96fe5aa-a08f-4274-bbfb-597c45b8c22f",
            price: 64927.87,
            price_sources: [],
            processed_ms: 1711268209172,
          },
        ],
        position_type: "FLAT",
        position_uuid: "6dead574-441b-4b1b-80d5-b248e42c1780",
        return_at_close: 1.0128973899473102,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 64813.41000000001,
        close_ms: 1711271876710,
        current_return: 1.0047895180950976,
        initial_entry_price: 64813.41,
        is_closed_position: true,
        miner_hotkey: "5GKMqtvfWMF7phS6DnTDtym1DHJGPqbfq1jTM23aKnUG5Njx",
        net_leverage: 0.0,
        open_ms: 1711268312408,
        orders: [
          {
            leverage: 1.25,
            order_type: "LONG",
            order_uuid: "10d02926-063a-4a1d-8b3f-88aeaa6d5a4f",
            price: 64813.41,
            price_sources: [],
            processed_ms: 1711268312407,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "0a7a9306-388f-48b5-b8cb-a15b82063d74",
            price: 65061.75,
            price_sources: [],
            processed_ms: 1711271876710,
          },
        ],
        position_type: "FLAT",
        position_uuid: "682062b9-6169-438c-9dba-0eef49b04c9f",
        return_at_close: 1.00227754429986,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 64988.48,
        close_ms: 1711275600562,
        current_return: 1.0012631693681504,
        initial_entry_price: 64955.66,
        is_closed_position: true,
        miner_hotkey: "5GKMqtvfWMF7phS6DnTDtym1DHJGPqbfq1jTM23aKnUG5Njx",
        net_leverage: 0.0,
        open_ms: 1711271963363,
        orders: [
          {
            leverage: 1.25,
            order_type: "LONG",
            order_uuid: "79a238ed-5574-4b16-8959-c467d1eaa764",
            price: 64955.66,
            price_sources: [],
            processed_ms: 1711271963362,
          },
          {
            leverage: 1.25,
            order_type: "LONG",
            order_uuid: "870171d3-b66c-466c-b653-ed6a16c28226",
            price: 65021.3,
            price_sources: [],
            processed_ms: 1711275580846,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "39a2feda-87d9-4303-854a-946569faa817",
            price: 65021.3,
            price_sources: [],
            processed_ms: 1711275600562,
          },
        ],
        position_type: "FLAT",
        position_uuid: "5a3b1822-0807-419d-bc16-df1a73d16211",
        return_at_close: 0.9962568535213097,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 65706.06,
        close_ms: 1711309544715,
        current_return: 1.002701805586882,
        initial_entry_price: 65706.06,
        is_closed_position: true,
        miner_hotkey: "5GKMqtvfWMF7phS6DnTDtym1DHJGPqbfq1jTM23aKnUG5Njx",
        net_leverage: 0.0,
        open_ms: 1711308649731,
        orders: [
          {
            leverage: 1.25,
            order_type: "LONG",
            order_uuid: "0d6e6132-fb40-4aff-a3b2-3dc2cc850476",
            price: 65706.06,
            price_sources: [],
            processed_ms: 1711308649729,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "05eab41c-e1cf-489e-a414-4604ae93ad19",
            price: 65848.08,
            price_sources: [],
            processed_ms: 1711309544715,
          },
        ],
        position_type: "FLAT",
        position_uuid: "4f4cfe0c-ae6d-4412-adc8-02315af04e70",
        return_at_close: 1.0001950510729147,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 66899.51,
        close_ms: 1711326393496,
        current_return: 1.0010863308266382,
        initial_entry_price: 66899.51,
        is_closed_position: true,
        miner_hotkey: "5GKMqtvfWMF7phS6DnTDtym1DHJGPqbfq1jTM23aKnUG5Njx",
        net_leverage: 0.0,
        open_ms: 1711325388915,
        orders: [
          {
            leverage: 1.25,
            order_type: "LONG",
            order_uuid: "63584541-2d84-4444-858e-332aca529cce",
            price: 66899.51,
            price_sources: [],
            processed_ms: 1711325388913,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "242f9329-5be1-4ba3-aeec-0a88912ba111",
            price: 66957.65,
            price_sources: [],
            processed_ms: 1711326393496,
          },
        ],
        position_type: "FLAT",
        position_uuid: "87baaf25-a99a-4632-ad9a-6a04e6f192e9",
        return_at_close: 0.9985836149995717,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 66631.13,
        close_ms: 1711328194998,
        current_return: 0.9986080079986634,
        initial_entry_price: 66631.13,
        is_closed_position: true,
        miner_hotkey: "5GKMqtvfWMF7phS6DnTDtym1DHJGPqbfq1jTM23aKnUG5Njx",
        net_leverage: 0.0,
        open_ms: 1711327252822,
        orders: [
          {
            leverage: 1.25,
            order_type: "LONG",
            order_uuid: "90eb88e4-5ff8-412c-b81f-f9b2a3d13faf",
            price: 66631.13,
            price_sources: [],
            processed_ms: 1711327252821,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "66e21e6f-2472-40e2-86b9-7a8fbfbfcc01",
            price: 66556.93,
            price_sources: [],
            processed_ms: 1711328194998,
          },
        ],
        position_type: "FLAT",
        position_uuid: "a267722c-6604-49e5-9cb1-60d9215e0cea",
        return_at_close: 0.9961114879786669,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 67168.05,
        close_ms: 1711342078800,
        current_return: 1.0033150061673666,
        initial_entry_price: 67168.05,
        is_closed_position: true,
        miner_hotkey: "5GKMqtvfWMF7phS6DnTDtym1DHJGPqbfq1jTM23aKnUG5Njx",
        net_leverage: 0.0,
        open_ms: 1711340242891,
        orders: [
          {
            leverage: 1.25,
            order_type: "LONG",
            order_uuid: "60e0dcaf-5825-425d-a94a-28298aea5832",
            price: 67168.05,
            price_sources: [],
            processed_ms: 1711340242889,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "93bf47be-2814-40eb-a187-ec0c7a66ae2f",
            price: 67346.18,
            price_sources: [],
            processed_ms: 1711342078800,
          },
        ],
        position_type: "FLAT",
        position_uuid: "8f0d4d48-d3aa-41c4-911d-3e47628e1944",
        return_at_close: 1.0008067186519483,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 66854.68,
        close_ms: 1711354903945,
        current_return: 1.002633510473762,
        initial_entry_price: 66854.68,
        is_closed_position: true,
        miner_hotkey: "5GKMqtvfWMF7phS6DnTDtym1DHJGPqbfq1jTM23aKnUG5Njx",
        net_leverage: 0.0,
        open_ms: 1711353073856,
        orders: [
          {
            leverage: 1.25,
            order_type: "LONG",
            order_uuid: "2ba0c1bb-7144-4047-85c8-90593a143de4",
            price: 66854.68,
            price_sources: [],
            processed_ms: 1711353073855,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "1436bb35-2d36-404c-b9e2-7a572c24bc28",
            price: 66995.53,
            price_sources: [],
            processed_ms: 1711354903945,
          },
        ],
        position_type: "FLAT",
        position_uuid: "516a849f-4b14-4e95-b67e-f141b9cdd7e4",
        return_at_close: 1.0001269266975776,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 67245.27,
        close_ms: 1711364064819,
        current_return: 0.9956521105499314,
        initial_entry_price: 67245.27,
        is_closed_position: true,
        miner_hotkey: "5GKMqtvfWMF7phS6DnTDtym1DHJGPqbfq1jTM23aKnUG5Njx",
        net_leverage: 0.0,
        open_ms: 1711362233744,
        orders: [
          {
            leverage: 1.25,
            order_type: "LONG",
            order_uuid: "23d0b935-e222-432f-a8cd-1e9815c18dbf",
            price: 67245.27,
            price_sources: [],
            processed_ms: 1711362233743,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "30f6792d-2dae-4e66-99f1-34e75a970118",
            price: 67011.37,
            price_sources: [],
            processed_ms: 1711364064819,
          },
        ],
        position_type: "FLAT",
        position_uuid: "696f9405-0793-48e3-98fc-922f533e8e1c",
        return_at_close: 0.9931629802735567,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: -2376367.2529824236,
        close_ms: 1711387195149,
        current_return: 1.0415357299525858,
        initial_entry_price: 67159.91,
        is_closed_position: true,
        miner_hotkey: "5GKMqtvfWMF7phS6DnTDtym1DHJGPqbfq1jTM23aKnUG5Njx",
        net_leverage: 0.0,
        open_ms: 1711369907070,
        orders: [
          {
            leverage: 1.25,
            order_type: "LONG",
            order_uuid: "f64ae64a-b6d9-4dea-97a0-fd7e03a92c6a",
            price: 67159.91,
            price_sources: [],
            processed_ms: 1711369907069,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "4e743a0c-10b2-4fe9-a8cf-bcede05b66c2",
            price: 67127.19,
            price_sources: [],
            processed_ms: 1711371733916,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "4cac9357-bc11-41ef-b844-5b00dcb8d391",
            price: 67188.75,
            price_sources: [],
            processed_ms: 1711373574448,
          },
          {
            leverage: -0.875,
            order_type: "SHORT",
            order_uuid: "0b93a74b-e987-47ef-885f-4ff27b16501c",
            price: 68187.31,
            price_sources: [],
            processed_ms: 1711375399064,
          },
          {
            leverage: -0.7,
            order_type: "SHORT",
            order_uuid: "66ffa44d-35fa-44e5-8eab-97621b2ae41a",
            price: 69235.28,
            price_sources: [],
            processed_ms: 1711376553335,
          },
          {
            leverage: -0.14000000000000004,
            order_type: "SHORT",
            order_uuid: "9d75a925-04ce-41a7-8850-723d8854837a",
            price: 69358.28,
            price_sources: [],
            processed_ms: 1711376865938,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "30085049-7186-4447-b0ea-28e82d192d58",
            price: 69144.78,
            price_sources: [],
            processed_ms: 1711378011157,
          },
          {
            leverage: -0.22800000000000004,
            order_type: "SHORT",
            order_uuid: "d0fe3123-b292-4c3e-a00b-8d96cae66e6c",
            price: 69212.28,
            price_sources: [],
            processed_ms: 1711378294380,
          },
          {
            leverage: -0.045599999999999995,
            order_type: "SHORT",
            order_uuid: "fe402782-8027-4eb0-9fbf-5d83090691f4",
            price: 69920.49,
            price_sources: [],
            processed_ms: 1711381707455,
          },
          {
            leverage: -0.0057,
            order_type: "SHORT",
            order_uuid: "6527dd14-5f77-48b4-ac5e-77a8ae6645d4",
            price: 69690.68,
            price_sources: [],
            processed_ms: 1711383559538,
          },
          {
            leverage: -0.00456,
            order_type: "SHORT",
            order_uuid: "7ebfc25b-92e9-4d97-85a0-e261c05bbe7d",
            price: 70035.89,
            price_sources: [],
            processed_ms: 1711385354252,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "3c8fd2ea-ac1e-4406-b366-c8fee9e3f677",
            price: 70594.05,
            price_sources: [],
            processed_ms: 1711387195149,
          },
        ],
        position_type: "FLAT",
        position_uuid: "c7c771c8-426b-4d56-a4e3-1168f138818a",
        return_at_close: 1.0378903548977518,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: -53873.109999997614,
        close_ms: 1711407140789,
        current_return: 0.9978020496777843,
        initial_entry_price: 70654.52,
        is_closed_position: true,
        miner_hotkey: "5GKMqtvfWMF7phS6DnTDtym1DHJGPqbfq1jTM23aKnUG5Njx",
        net_leverage: 0.0,
        open_ms: 1711390843689,
        orders: [
          {
            leverage: -1.25,
            order_type: "SHORT",
            order_uuid: "1f3da369-7542-41e1-ae44-f03ab314267a",
            price: 70654.52,
            price_sources: [],
            processed_ms: 1711390843686,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "22c9c57a-f31d-46ad-b5f4-8e02e5a563da",
            price: 70765.57,
            price_sources: [],
            processed_ms: 1711392650571,
          },
          {
            leverage: 0.5,
            order_type: "LONG",
            order_uuid: "37aa0fce-e5cf-42a2-823e-7f5edca8b230",
            price: 70758.49,
            price_sources: [],
            processed_ms: 1711394484741,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "e0c325c3-674a-46eb-b601-3f10f458392e",
            price: 70908.02,
            price_sources: [],
            processed_ms: 1711396325582,
          },
          {
            leverage: 0.125,
            order_type: "LONG",
            order_uuid: "b88e5743-3ad8-46f0-bba6-706f4164609d",
            price: 70872.35,
            price_sources: [],
            processed_ms: 1711398059421,
          },
          {
            leverage: 0.0625,
            order_type: "LONG",
            order_uuid: "ef44aa02-2018-43e9-a212-dd0e3afbda34",
            price: 70935.84,
            price_sources: [],
            processed_ms: 1711399871073,
          },
          {
            leverage: 0.03125,
            order_type: "LONG",
            order_uuid: "d9b8ef97-18ac-4f98-a7aa-4d926600a123",
            price: 70195.15,
            price_sources: [],
            processed_ms: 1711401691158,
          },
          {
            leverage: 0.025,
            order_type: "LONG",
            order_uuid: "a0bfa5d3-3688-4cbd-a39f-96113f33dd74",
            price: 69994.83,
            price_sources: [],
            processed_ms: 1711403510273,
          },
          {
            leverage: 0.004999999999999999,
            order_type: "LONG",
            order_uuid: "9a7d5286-645b-40e1-a1f6-760a2cc464b6",
            price: 70369.19,
            price_sources: [],
            processed_ms: 1711405329302,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "59b44db5-d42a-4dd4-9d55-92e9717b69d8",
            price: 70362.99,
            price_sources: [],
            processed_ms: 1711407140789,
          },
        ],
        position_type: "FLAT",
        position_uuid: "ab45e041-4291-4dea-b98d-651ca812ffe6",
        return_at_close: 0.9953075445535899,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: -84072.96172434949,
        close_ms: 1711457999229,
        current_return: 1.0032322936095226,
        initial_entry_price: 70390.28,
        is_closed_position: true,
        miner_hotkey: "5GKMqtvfWMF7phS6DnTDtym1DHJGPqbfq1jTM23aKnUG5Njx",
        net_leverage: 0.0,
        open_ms: 1711408959726,
        orders: [
          {
            leverage: 1.25,
            order_type: "LONG",
            order_uuid: "dd608986-b2c2-42b0-92e0-1c4850ef555d",
            price: 70390.28,
            price_sources: [],
            processed_ms: 1711408959724,
          },
          {
            leverage: -0.625,
            order_type: "SHORT",
            order_uuid: "4fb4e9de-3e6b-4d66-8290-4cb56cafd891",
            price: 69906.71,
            price_sources: [],
            processed_ms: 1711410771721,
          },
          {
            leverage: -0.3125,
            order_type: "SHORT",
            order_uuid: "1f962ac9-2ff2-45c7-859e-fdbb4c78d40d",
            price: 69899.85,
            price_sources: [],
            processed_ms: 1711412583501,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "b91da4b0-a8b3-41f8-ae37-23500bc9cd07",
            price: 69966.59,
            price_sources: [],
            processed_ms: 1711414399095,
          },
          {
            leverage: -0.28125,
            order_type: "SHORT",
            order_uuid: "43e170ea-cf01-4d9a-8535-4c87fb8e2a1e",
            price: 70198.71,
            price_sources: [],
            processed_ms: 1711416216131,
          },
          {
            leverage: -0.140625,
            order_type: "SHORT",
            order_uuid: "f8f33c41-0918-4651-9e9e-75c5a88cd586",
            price: 70293.69,
            price_sources: [],
            processed_ms: 1711418036425,
          },
          {
            leverage: -0.0703125,
            order_type: "SHORT",
            order_uuid: "e696b0ba-d715-4154-87b3-0107260e0f27",
            price: 70290.43,
            price_sources: [],
            processed_ms: 1711419854957,
          },
          {
            leverage: -0.03515625,
            order_type: "SHORT",
            order_uuid: "6662ac7b-32e7-45c2-89e5-c28e662d4a97",
            price: 70483.06,
            price_sources: [],
            processed_ms: 1711421667372,
          },
          {
            leverage: -0.017578125,
            order_type: "SHORT",
            order_uuid: "6229c90b-787a-4a90-ae49-e96b4d2b3697",
            price: 70482.52,
            price_sources: [],
            processed_ms: 1711423490155,
          },
          {
            leverage: -0.0087890625,
            order_type: "SHORT",
            order_uuid: "b7e7c29e-942e-499d-94b7-2e18341660c1",
            price: 70536.05,
            price_sources: [],
            processed_ms: 1711425305837,
          },
          {
            leverage: -0.00439453125,
            order_type: "SHORT",
            order_uuid: "c8630e8d-f25d-4741-b559-2022926c2f0f",
            price: 70533.43,
            price_sources: [],
            processed_ms: 1711427124177,
          },
          {
            leverage: -0.002197265625,
            order_type: "SHORT",
            order_uuid: "c40ccaf7-3ea9-4cff-a4e3-f1965e58c5cf",
            price: 70570.24,
            price_sources: [],
            processed_ms: 1711428929123,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "d4b497d0-e9ca-404a-b967-4724ba60ffb8",
            price: 70456.43,
            price_sources: [],
            processed_ms: 1711430750853,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "67da40f9-9e77-48e6-82c3-67e325d2e2db",
            price: 70402.16,
            price_sources: [],
            processed_ms: 1711432575520,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "6776f8a8-cba4-44c7-a03c-a4dce6c366fd",
            price: 70369.56,
            price_sources: [],
            processed_ms: 1711434381117,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "6654e9f6-8e42-4397-9e6a-151f11635780",
            price: 70551.52,
            price_sources: [],
            processed_ms: 1711436240697,
          },
          {
            leverage: -0.5010986328125,
            order_type: "SHORT",
            order_uuid: "3e230f43-ca06-43d4-b878-b23c3addac35",
            price: 71319.23,
            price_sources: [],
            processed_ms: 1711438013937,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "1f88cf5f-9f4b-4a5c-9104-338e2aa5b950",
            price: 70559.09,
            price_sources: [],
            processed_ms: 1711439838923,
          },
          {
            leverage: -0.37554931640625,
            order_type: "SHORT",
            order_uuid: "fa1a5987-00f0-443f-98bc-0736468129bf",
            price: 70670.45,
            price_sources: [],
            processed_ms: 1711441651419,
          },
          {
            leverage: -0.187774658203125,
            order_type: "SHORT",
            order_uuid: "12314ebd-2ff8-432f-825e-693402424271",
            price: 70632.77,
            price_sources: [],
            processed_ms: 1711443468422,
          },
          {
            leverage: -0.0938873291015625,
            order_type: "SHORT",
            order_uuid: "4d979abf-d4ee-4e61-bcbd-bce335e1441b",
            price: 71071.82,
            price_sources: [],
            processed_ms: 1711445285398,
          },
          {
            leverage: -0.04694366455078125,
            order_type: "SHORT",
            order_uuid: "31e2b385-f037-4051-8278-27bccbbce5ae",
            price: 71179.85,
            price_sources: [],
            processed_ms: 1711447103216,
          },
          {
            leverage: -0.023471832275390625,
            order_type: "SHORT",
            order_uuid: "d6b2d345-14e7-4694-a730-19eaa824aca9",
            price: 70936.34,
            price_sources: [],
            processed_ms: 1711448914086,
          },
          {
            leverage: -0.011735916137695312,
            order_type: "SHORT",
            order_uuid: "2be3c3b0-981a-4a17-a18e-cadffbd5b4e0",
            price: 70946.31,
            price_sources: [],
            processed_ms: 1711450758817,
          },
          {
            leverage: -0.005867958068847656,
            order_type: "SHORT",
            order_uuid: "f596b8c1-f26e-46c1-b9aa-9f3a3a1571e4",
            price: 70762.73,
            price_sources: [],
            processed_ms: 1711452549460,
          },
          {
            leverage: -0.002933979034423828,
            order_type: "SHORT",
            order_uuid: "5ab9eba3-8fe8-48e0-9e0a-303ed008d6d1",
            price: 70674.19,
            price_sources: [],
            processed_ms: 1711454368892,
          },
          {
            leverage: -0.001466989517211914,
            order_type: "SHORT",
            order_uuid: "841f893f-fa79-4d6b-84d2-ff46e2ba8a2a",
            price: 70824.43,
            price_sources: [],
            processed_ms: 1711456182018,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "36419ad4-8ccc-4e9c-9913-ab5f739df458",
            price: 71021.57,
            price_sources: [],
            processed_ms: 1711457999229,
          },
        ],
        position_type: "FLAT",
        position_uuid: "935a6a67-489c-4cd6-8da4-507f1b420d7d",
        return_at_close: 1.000724212875499,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 70190.15415993622,
        close_ms: 1711548264079,
        current_return: 0.9693779785557974,
        initial_entry_price: 70810.59,
        is_closed_position: true,
        miner_hotkey: "5GKMqtvfWMF7phS6DnTDtym1DHJGPqbfq1jTM23aKnUG5Njx",
        net_leverage: 0.0,
        open_ms: 1711459817273,
        orders: [
          {
            leverage: 1.25,
            order_type: "LONG",
            order_uuid: "cb263bb0-3492-40a9-ac69-7bc4204be458",
            price: 70810.59,
            price_sources: [],
            processed_ms: 1711459817271,
          },
          {
            leverage: -0.625,
            order_type: "SHORT",
            order_uuid: "a87148c5-9adc-4b1e-96ba-2306f6fc6f13",
            price: 70409.83,
            price_sources: [],
            processed_ms: 1711461633879,
          },
          {
            leverage: -0.3125,
            order_type: "SHORT",
            order_uuid: "e3b3be0d-36c0-485b-9fad-22ab9ad7c59e",
            price: 69583.0,
            price_sources: [],
            processed_ms: 1711463454643,
          },
          {
            leverage: -0.15625,
            order_type: "SHORT",
            order_uuid: "388548be-5a01-469e-8d86-6f772743b7f1",
            price: 70333.5,
            price_sources: [],
            processed_ms: 1711465260304,
          },
          {
            leverage: -0.078125,
            order_type: "SHORT",
            order_uuid: "7369d58c-3554-41c4-be64-292826d20cf1",
            price: 70115.71,
            price_sources: [],
            processed_ms: 1711467093523,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "1cb012ec-69b0-4af4-9156-d5d271bff726",
            price: 70302.23,
            price_sources: [],
            processed_ms: 1711468906510,
          },
          {
            leverage: -0.1640625,
            order_type: "SHORT",
            order_uuid: "8c363abe-9ab1-4711-86ff-cc04e6fdadd4",
            price: 69881.02,
            price_sources: [],
            processed_ms: 1711470712067,
          },
          {
            leverage: -0.08203125,
            order_type: "SHORT",
            order_uuid: "b44ea039-4990-418c-b1ed-54cafa751b27",
            price: 69866.89,
            price_sources: [],
            processed_ms: 1711472610286,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "ea693ba3-0ddb-4f06-a780-80fe251d3003",
            price: 70136.26,
            price_sources: [],
            processed_ms: 1711474348936,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "e1ecd515-380a-4f5b-9fd9-589718ea0892",
            price: 70060.6,
            price_sources: [],
            processed_ms: 1711476168933,
          },
          {
            leverage: -0.291015625,
            order_type: "SHORT",
            order_uuid: "a94dc044-c39e-4603-a169-1689c581cb26",
            price: 70233.37,
            price_sources: [],
            processed_ms: 1711477989143,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "191728a6-f4f8-4977-8118-8abcb4d3b6de",
            price: 70046.72,
            price_sources: [],
            processed_ms: 1711479804906,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "be936e76-3df4-469c-9e04-cc94674deae5",
            price: 69727.79,
            price_sources: [],
            processed_ms: 1711481619085,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "f4626dd9-924e-4876-bdfe-016f8899b588",
            price: 69566.17,
            price_sources: [],
            processed_ms: 1711483438132,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "5a5039c2-6a6c-416f-89d5-a90926d66eff",
            price: 69851.85,
            price_sources: [],
            processed_ms: 1711485249153,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "fb5de6ec-4f2e-4e9b-b25c-1bbc63c89106",
            price: 69803.65,
            price_sources: [],
            processed_ms: 1711487066872,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "d6222338-921f-428d-9dc1-756b148459aa",
            price: 70090.27,
            price_sources: [],
            processed_ms: 1711488885246,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "322aa5c3-a78e-4bc4-81ef-c4c01fa7283a",
            price: 69998.74,
            price_sources: [],
            processed_ms: 1711490703496,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "6a951be1-8d92-48b2-b74c-27d3db8a781f",
            price: 69939.92,
            price_sources: [],
            processed_ms: 1711492511466,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "f7443fe7-7b43-46b1-a248-eba425ef71da",
            price: 70248.22,
            price_sources: [],
            processed_ms: 1711494328716,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "461a4b93-efda-4c89-80c7-eca2a1d1b692",
            price: 70068.01,
            price_sources: [],
            processed_ms: 1711496149870,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "a3b2df01-6d17-4afb-95cc-35ffe2454d26",
            price: 70002.42,
            price_sources: [],
            processed_ms: 1711497969238,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "05652bdb-f6f6-4a0c-be1f-3b05933fd684",
            price: 70227.77,
            price_sources: [],
            processed_ms: 1711499777889,
          },
          {
            leverage: -1.6455078125,
            order_type: "SHORT",
            order_uuid: "e79512b6-14e2-4a5f-8c56-14ee49c7a952",
            price: 70511.93,
            price_sources: [],
            processed_ms: 1711501596937,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "755c8518-4b32-4cc3-bd44-59026fb5c68b",
            price: 70548.99,
            price_sources: [],
            processed_ms: 1711503413800,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "a00dacf5-119e-4051-b6a5-0da7a2328d3b",
            price: 70232.57,
            price_sources: [],
            processed_ms: 1711505285076,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "b38e3994-dacb-451a-92b6-5113edda9167",
            price: 70392.22,
            price_sources: [],
            processed_ms: 1711507041547,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "fce8f60f-b092-4ca1-9a3f-3b86aacb033b",
            price: 70605.29,
            price_sources: [],
            processed_ms: 1711508866938,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "2702214b-63eb-483f-99b4-275165cb7589",
            price: 70447.48,
            price_sources: [],
            processed_ms: 1711510681640,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "58ff6119-f22d-4235-aa3c-e43dfeee8500",
            price: 70489.47,
            price_sources: [],
            processed_ms: 1711512509689,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "8f37926b-e793-4386-b691-4cfa4f713d99",
            price: 70333.52,
            price_sources: [],
            processed_ms: 1711514323033,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "8451a424-e3e8-47c9-99a9-06fc327a0bcb",
            price: 70258.65,
            price_sources: [],
            processed_ms: 1711516139775,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "85f3f8bd-f808-49db-ac5f-57aaaf92aa46",
            price: 70468.34,
            price_sources: [],
            processed_ms: 1711517959113,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "c3f14b3e-67df-4285-841c-a63143986113",
            price: 70485.12,
            price_sources: [],
            processed_ms: 1711519769789,
          },
          {
            leverage: -2.07275390625,
            order_type: "SHORT",
            order_uuid: "50317eb1-84f7-43bb-b6aa-523e494ce0f5",
            price: 70302.69,
            price_sources: [],
            processed_ms: 1711521587012,
          },
          {
            leverage: -1.036376953125,
            order_type: "SHORT",
            order_uuid: "f26eedda-1efa-435e-872b-dd097362bc4c",
            price: 70095.93,
            price_sources: [],
            processed_ms: 1711523861771,
          },
          {
            leverage: -0.5181884765625,
            order_type: "SHORT",
            order_uuid: "7e38c300-8a44-4959-ade1-376892153610",
            price: 69778.2,
            price_sources: [],
            processed_ms: 1711525950084,
          },
          {
            leverage: -0.25909423828125,
            order_type: "SHORT",
            order_uuid: "4c15f9d0-d8bf-4f26-b220-c6a4b5e3c032",
            price: 69920.94,
            price_sources: [],
            processed_ms: 1711527768803,
          },
          {
            leverage: -0.129547119140625,
            order_type: "SHORT",
            order_uuid: "c4ce633f-ea91-4edd-8a45-265b832846f7",
            price: 69712.25,
            price_sources: [],
            processed_ms: 1711529612826,
          },
          {
            leverage: 0.375,
            order_type: "LONG",
            order_uuid: "e1d463d3-32bd-4bf1-8ee6-9509da1eddda",
            price: 69804.34,
            price_sources: [],
            processed_ms: 1711531396685,
          },
          {
            leverage: 0.375,
            order_type: "LONG",
            order_uuid: "b2b0ce80-7255-47a2-8e1a-93c6e7e449e0",
            price: 69933.85,
            price_sources: [],
            processed_ms: 1711533213000,
          },
          {
            leverage: 0.375,
            order_type: "LONG",
            order_uuid: "136e64d9-a742-4f75-b356-33c1890d6373",
            price: 70102.43,
            price_sources: [],
            processed_ms: 1711535026979,
          },
          {
            leverage: 0.375,
            order_type: "LONG",
            order_uuid: "e1ba2f4c-fb6e-43d0-9ab3-a515d6d07ba9",
            price: 70039.33,
            price_sources: [],
            processed_ms: 1711536398208,
          },
          {
            leverage: 0.375,
            order_type: "LONG",
            order_uuid: "ba015f87-53dd-49b8-ac5e-25cd3558ed4c",
            price: 70278.78,
            price_sources: [],
            processed_ms: 1711540007700,
          },
          {
            leverage: 0.375,
            order_type: "LONG",
            order_uuid: "4be03795-532e-4472-baae-3f783171f98a",
            price: 69998.77,
            price_sources: [],
            processed_ms: 1711543646949,
          },
          {
            leverage: 0.375,
            order_type: "LONG",
            order_uuid: "20065562-a0b8-4dcf-8879-a19d0c443e04",
            price: 69971.7,
            price_sources: [],
            processed_ms: 1711547254148,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "797f1551-82a5-444c-8253-c2ca047d5717",
            price: 69402.96,
            price_sources: [],
            processed_ms: 1711548264079,
          },
        ],
        position_type: "FLAT",
        position_uuid: "f9d1fe27-3113-448d-8aa7-bc1aeb8f069e",
        return_at_close: 0.9613408505890604,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 99342.71000000002,
        close_ms: 1711568969927,
        current_return: 1.013752522039851,
        initial_entry_price: 69760.0,
        is_closed_position: true,
        miner_hotkey: "5GKMqtvfWMF7phS6DnTDtym1DHJGPqbfq1jTM23aKnUG5Njx",
        net_leverage: 0.0,
        open_ms: 1711550884877,
        orders: [
          {
            leverage: -1.25,
            order_type: "SHORT",
            order_uuid: "cd47e425-7b04-4777-ba26-7a3bd023fcf5",
            price: 69760.0,
            price_sources: [],
            processed_ms: 1711550884875,
          },
          {
            leverage: 0.625,
            order_type: "LONG",
            order_uuid: "2c985f6f-e900-4bf9-bf47-77e14a3b1c59",
            price: 69013.55,
            price_sources: [],
            processed_ms: 1711554489579,
          },
          {
            leverage: 0.3125,
            order_type: "LONG",
            order_uuid: "9f44998f-afac-435e-b569-9a85b81b3a0f",
            price: 69064.3,
            price_sources: [],
            processed_ms: 1711558108545,
          },
          {
            leverage: 0.15625,
            order_type: "LONG",
            order_uuid: "0c2717db-cd89-4956-b7c9-0107ff998473",
            price: 69036.65,
            price_sources: [],
            processed_ms: 1711561738569,
          },
          {
            leverage: 0.125,
            order_type: "LONG",
            order_uuid: "5b316cdd-8582-43aa-9ab1-6a1dba736738",
            price: 68740.01,
            price_sources: [],
            processed_ms: 1711565371484,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "77c014a2-0070-4bf2-9192-8ef782ce1113",
            price: 68642.68,
            price_sources: [],
            processed_ms: 1711568969927,
          },
        ],
        position_type: "FLAT",
        position_uuid: "3b56bf44-90ae-458b-b1ff-6c82cb253147",
        return_at_close: 1.0112181407347514,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: -68320.85489361806,
        close_ms: 1711634101071,
        current_return: 1.046345577761084,
        initial_entry_price: 68889.44,
        is_closed_position: true,
        miner_hotkey: "5GKMqtvfWMF7phS6DnTDtym1DHJGPqbfq1jTM23aKnUG5Njx",
        net_leverage: 0.0,
        open_ms: 1711572584015,
        orders: [
          {
            leverage: 1.25,
            order_type: "LONG",
            order_uuid: "212c62ae-802a-48e3-80b3-f3a34ebe8eac",
            price: 68889.44,
            price_sources: [],
            processed_ms: 1711572584013,
          },
          {
            leverage: -0.625,
            order_type: "SHORT",
            order_uuid: "cf433d5f-53d9-418c-8b24-c18d4bcf9476",
            price: 69099.39,
            price_sources: [],
            processed_ms: 1711576207713,
          },
          {
            leverage: -0.3125,
            order_type: "SHORT",
            order_uuid: "b1106127-4859-4607-9c5c-cd3a318cdda4",
            price: 69403.84,
            price_sources: [],
            processed_ms: 1711579819782,
          },
          {
            leverage: 0.375,
            order_type: "LONG",
            order_uuid: "e0aa18b6-3603-433c-a788-9c876155eaf5",
            price: 69243.81,
            price_sources: [],
            processed_ms: 1711583439385,
          },
          {
            leverage: 0.375,
            order_type: "LONG",
            order_uuid: "ebe7e6de-14eb-449f-8fc7-6dc6f3a908db",
            price: 69686.4,
            price_sources: [],
            processed_ms: 1711587053807,
          },
          {
            leverage: 0.375,
            order_type: "LONG",
            order_uuid: "2c76ba82-d1a4-4af7-91ac-f62542177a03",
            price: 69392.76,
            price_sources: [],
            processed_ms: 1711590695533,
          },
          {
            leverage: 0.375,
            order_type: "LONG",
            order_uuid: "59effcf3-3cd0-401a-8a28-580daaf1710c",
            price: 69015.04,
            price_sources: [],
            processed_ms: 1711594300798,
          },
          {
            leverage: 0.375,
            order_type: "LONG",
            order_uuid: "fcc05cb9-29bb-4ddc-bb9d-9cbe8e44d68d",
            price: 69232.33,
            price_sources: [],
            processed_ms: 1711597924086,
          },
          {
            leverage: 0.375,
            order_type: "LONG",
            order_uuid: "185348a7-20df-4505-a911-2ddee84db507",
            price: 69657.73,
            price_sources: [],
            processed_ms: 1711601535634,
          },
          {
            leverage: 0.375,
            order_type: "LONG",
            order_uuid: "3292a0b5-8eb7-4877-af53-525d6e86b6ff",
            price: 69636.01,
            price_sources: [],
            processed_ms: 1711605185556,
          },
          {
            leverage: -1.46875,
            order_type: "SHORT",
            order_uuid: "97cf7e6d-29d6-4437-969c-c7f23b993d01",
            price: 70215.87,
            price_sources: [],
            processed_ms: 1711608767139,
          },
          {
            leverage: -0.734375,
            order_type: "SHORT",
            order_uuid: "81529ae5-d4ec-41e7-9a18-cff62ccb0f55",
            price: 70321.82,
            price_sources: [],
            processed_ms: 1711612387120,
          },
          {
            leverage: -0.3671875,
            order_type: "SHORT",
            order_uuid: "69e7e94a-be87-4018-b673-5e433f714159",
            price: 70615.59,
            price_sources: [],
            processed_ms: 1711616005174,
          },
          {
            leverage: -0.18359375,
            order_type: "SHORT",
            order_uuid: "eacd0cd8-7caf-4e8a-beb8-df2c20078f1d",
            price: 70599.59,
            price_sources: [],
            processed_ms: 1711619622521,
          },
          {
            leverage: -0.091796875,
            order_type: "SHORT",
            order_uuid: "aee904c2-6cdb-4da7-b3a3-af7b1bf27494",
            price: 70597.83,
            price_sources: [],
            processed_ms: 1711623231554,
          },
          {
            leverage: -0.0458984375,
            order_type: "SHORT",
            order_uuid: "b09e6b32-24bf-43ed-b192-8364ff9d1f09",
            price: 70630.82,
            price_sources: [],
            processed_ms: 1711626856833,
          },
          {
            leverage: -0.02294921875,
            order_type: "SHORT",
            order_uuid: "4f7b0437-7926-42cc-a77b-e81988517634",
            price: 70480.22,
            price_sources: [],
            processed_ms: 1711630478947,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "0b0b5848-22b6-4931-b341-cb2f0ac15d0a",
            price: 70800.26,
            price_sources: [],
            processed_ms: 1711634101071,
          },
        ],
        position_type: "FLAT",
        position_uuid: "28945971-8c6c-4d37-93dc-f9c2f4588a75",
        return_at_close: 1.0401982974917376,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 71213.86838709677,
        close_ms: 1711692513136,
        current_return: 0.970023162076059,
        initial_entry_price: 70801.08,
        is_closed_position: true,
        miner_hotkey: "5GKMqtvfWMF7phS6DnTDtym1DHJGPqbfq1jTM23aKnUG5Njx",
        net_leverage: 0.0,
        open_ms: 1711663080506,
        orders: [
          {
            leverage: 1.25,
            order_type: "LONG",
            order_uuid: "ca26fdec-c26a-4871-8689-672b08bb28f8",
            price: 70801.08,
            price_sources: [],
            processed_ms: 1711663080503,
          },
          {
            leverage: 0.375,
            order_type: "LONG",
            order_uuid: "928cd683-10e9-4327-95fa-d2e8ea86095d",
            price: 70860.56,
            price_sources: [],
            processed_ms: 1711666777220,
          },
          {
            leverage: 0.375,
            order_type: "LONG",
            order_uuid: "4f1b9616-0e8d-4f1d-a023-b28fbdd90798",
            price: 70762.14,
            price_sources: [],
            processed_ms: 1711670340946,
          },
          {
            leverage: 0.375,
            order_type: "LONG",
            order_uuid: "56abe543-7ebb-487e-9b40-af997747767e",
            price: 70720.8,
            price_sources: [],
            processed_ms: 1711673932422,
          },
          {
            leverage: 0.375,
            order_type: "LONG",
            order_uuid: "94ecdbbd-5278-4ad4-a736-231b0fb1da9b",
            price: 70809.05,
            price_sources: [],
            processed_ms: 1711677556885,
          },
          {
            leverage: 0.375,
            order_type: "LONG",
            order_uuid: "5431db51-963b-4129-afe4-fbcb8c962783",
            price: 70648.11,
            price_sources: [],
            processed_ms: 1711681244332,
          },
          {
            leverage: 0.375,
            order_type: "LONG",
            order_uuid: "a994f468-47fc-4fd3-a94c-37ab4e22f208",
            price: 70463.11,
            price_sources: [],
            processed_ms: 1711684796753,
          },
          {
            leverage: 0.375,
            order_type: "LONG",
            order_uuid: "fef86db3-17e2-46bf-b190-bb8af87ed51b",
            price: 70370.95,
            price_sources: [],
            processed_ms: 1711688459794,
          },
          {
            leverage: -1.9375,
            order_type: "SHORT",
            order_uuid: "08f97504-ff4e-43ac-b7ec-b46647d3f90f",
            price: 70200.0,
            price_sources: [],
            processed_ms: 1711692023929,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "01359622-b43c-4655-833a-e3ecc48bdba9",
            price: 70118.44,
            price_sources: [],
            processed_ms: 1711692513136,
          },
        ],
        position_type: "FLAT",
        position_uuid: "fb904771-70d5-4108-9afd-8974e2e515f4",
        return_at_close: 0.9625054825699695,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 70197.0055,
        close_ms: 1711726987840,
        current_return: 0.9673618494231144,
        initial_entry_price: 69998.26,
        is_closed_position: true,
        miner_hotkey: "5GKMqtvfWMF7phS6DnTDtym1DHJGPqbfq1jTM23aKnUG5Njx",
        net_leverage: 0.0,
        open_ms: 1711702879148,
        orders: [
          {
            leverage: 1.25,
            order_type: "LONG",
            order_uuid: "c3adbebb-5fc3-4b5a-8cce-ae300d667e8f",
            price: 69998.26,
            price_sources: [],
            processed_ms: 1711702879145,
          },
          {
            leverage: -0.625,
            order_type: "SHORT",
            order_uuid: "7a8ee031-70da-4fcb-9d65-a7365bee6d8b",
            price: 69754.54,
            price_sources: [],
            processed_ms: 1711706494031,
          },
          {
            leverage: 0.375,
            order_type: "LONG",
            order_uuid: "fe090928-3974-4bab-b8d3-1c526431bc62",
            price: 70126.65,
            price_sources: [],
            processed_ms: 1711710115802,
          },
          {
            leverage: 0.375,
            order_type: "LONG",
            order_uuid: "03e913f1-6aba-4ad8-9167-0f3201474ffe",
            price: 70221.55,
            price_sources: [],
            processed_ms: 1711713732738,
          },
          {
            leverage: 0.375,
            order_type: "LONG",
            order_uuid: "e592c1d0-aac7-4d65-9914-4fcd512e7e1d",
            price: 70171.91,
            price_sources: [],
            processed_ms: 1711717348686,
          },
          {
            leverage: 0.375,
            order_type: "LONG",
            order_uuid: "45592614-2ca9-4a81-b1d0-de4c1d621617",
            price: 70378.33,
            price_sources: [],
            processed_ms: 1711720966833,
          },
          {
            leverage: 0.375,
            order_type: "LONG",
            order_uuid: "c6b93c01-cafb-4059-9b56-bc156456245c",
            price: 70011.63,
            price_sources: [],
            processed_ms: 1711724580668,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "010336f1-29a2-4f2f-baf3-ba1b8e2eb038",
            price: 69283.16,
            price_sources: [],
            processed_ms: 1711726987840,
          },
        ],
        position_type: "FLAT",
        position_uuid: "b1b83554-acba-4b38-994b-9a754dcff9b1",
        return_at_close: 0.9625250401759988,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 69713.78807123084,
        close_ms: 1711984425683,
        current_return: 0.9632101699512209,
        initial_entry_price: 69558.87,
        is_closed_position: true,
        miner_hotkey: "5GKMqtvfWMF7phS6DnTDtym1DHJGPqbfq1jTM23aKnUG5Njx",
        net_leverage: 0.0,
        open_ms: 1711749916644,
        orders: [
          {
            leverage: 1.25,
            order_type: "LONG",
            order_uuid: "4f2decf2-4574-4980-9b1e-f6f3b6b75f5f",
            price: 69558.87,
            price_sources: [],
            processed_ms: 1711749916641,
          },
          {
            leverage: 0.375,
            order_type: "LONG",
            order_uuid: "923fd4d1-5079-4cbd-bdd9-76f19bcc3fca",
            price: 69813.93,
            price_sources: [],
            processed_ms: 1711753565977,
          },
          {
            leverage: 0.375,
            order_type: "LONG",
            order_uuid: "eaf70fa9-d37a-4249-8ca6-13d7993f9dd8",
            price: 69886.8,
            price_sources: [],
            processed_ms: 1711757151444,
          },
          {
            leverage: 0.375,
            order_type: "LONG",
            order_uuid: "07a7a8bf-b67c-4ea7-a6fe-7505d37c3754",
            price: 69913.54,
            price_sources: [],
            processed_ms: 1711760792227,
          },
          {
            leverage: 0.375,
            order_type: "LONG",
            order_uuid: "dcb1235e-2d00-40fb-add6-4c59d96aad36",
            price: 70000.35,
            price_sources: [],
            processed_ms: 1711764380721,
          },
          {
            leverage: 0.375,
            order_type: "LONG",
            order_uuid: "dff717dc-6f82-487e-8237-f9b6162d240c",
            price: 70056.48,
            price_sources: [],
            processed_ms: 1711768011849,
          },
          {
            leverage: 0.375,
            order_type: "LONG",
            order_uuid: "2be136a9-1afa-4d24-b1a6-f4b27d399c51",
            price: 69840.19,
            price_sources: [],
            processed_ms: 1711771633505,
          },
          {
            leverage: 0.375,
            order_type: "LONG",
            order_uuid: "801d8372-1432-46f5-ad25-6485df667381",
            price: 70348.93,
            price_sources: [],
            processed_ms: 1711775262218,
          },
          {
            leverage: 0.375,
            order_type: "LONG",
            order_uuid: "3ef08ec8-4e04-4fe8-bb57-bcc7ca0d166b",
            price: 69988.2,
            price_sources: [],
            processed_ms: 1711778870771,
          },
          {
            leverage: 0.375,
            order_type: "LONG",
            order_uuid: "b9d83b85-9ecc-404d-a7d4-c8042df7d072",
            price: 69990.0,
            price_sources: [],
            processed_ms: 1711782494797,
          },
          {
            leverage: 0.375,
            order_type: "LONG",
            order_uuid: "bc92ea7c-3b2c-44ab-9747-444dee2cef4c",
            price: 69913.48,
            price_sources: [],
            processed_ms: 1711786112463,
          },
          {
            leverage: -2.5,
            order_type: "SHORT",
            order_uuid: "177b3c08-22ac-4dca-8b01-d4cd88934862",
            price: 69979.97,
            price_sources: [],
            processed_ms: 1711789726388,
          },
          {
            leverage: 0.375,
            order_type: "LONG",
            order_uuid: "8bf2d076-5ead-4e34-9ef3-f0b4bf1c91fe",
            price: 70039.86,
            price_sources: [],
            processed_ms: 1711793340577,
          },
          {
            leverage: -1.4375,
            order_type: "SHORT",
            order_uuid: "5f45dfc9-b7aa-46ac-9363-41f23b70af20",
            price: 70159.46,
            price_sources: [],
            processed_ms: 1711796966811,
          },
          {
            leverage: -0.71875,
            order_type: "SHORT",
            order_uuid: "a6a56cd6-df95-4f23-99ce-bce4bbe95e22",
            price: 70192.68,
            price_sources: [],
            processed_ms: 1711800579511,
          },
          {
            leverage: -0.359375,
            order_type: "SHORT",
            order_uuid: "0e39795a-dc50-4f14-94fe-4fcb05ac075a",
            price: 70273.91,
            price_sources: [],
            processed_ms: 1711804196272,
          },
          {
            leverage: 0.375,
            order_type: "LONG",
            order_uuid: "b0a7dec9-df37-4251-b9f3-90e9b9a4972d",
            price: 70169.23,
            price_sources: [],
            processed_ms: 1711807808431,
          },
          {
            leverage: 0.375,
            order_type: "LONG",
            order_uuid: "815af028-d719-422a-87be-3c2759a2caee",
            price: 70180.4,
            price_sources: [],
            processed_ms: 1711811426780,
          },
          {
            leverage: 0.375,
            order_type: "LONG",
            order_uuid: "4a6877f9-16f4-43ef-a5e0-98d3a4b0693b",
            price: 70007.32,
            price_sources: [],
            processed_ms: 1711815047651,
          },
          {
            leverage: 0.375,
            order_type: "LONG",
            order_uuid: "7699420a-f208-44a8-b51d-96018be3e3a1",
            price: 70155.25,
            price_sources: [],
            processed_ms: 1711818685428,
          },
          {
            leverage: 0.375,
            order_type: "LONG",
            order_uuid: "5165b851-2bbb-486c-a17c-5f2b2ccb4747",
            price: 69982.31,
            price_sources: [],
            processed_ms: 1711822285502,
          },
          {
            leverage: 0.375,
            order_type: "LONG",
            order_uuid: "963305a7-9e65-42da-9327-1691c46cb31f",
            price: 69879.47,
            price_sources: [],
            processed_ms: 1711825904141,
          },
          {
            leverage: 0.375,
            order_type: "LONG",
            order_uuid: "ad1a35ac-1ecb-4f59-93ec-f4b66f711661",
            price: 69827.46,
            price_sources: [],
            processed_ms: 1711829527724,
          },
          {
            leverage: -1.4921875,
            order_type: "SHORT",
            order_uuid: "c8d641a4-5795-4d99-bd61-3f558fb47701",
            price: 69803.24,
            price_sources: [],
            processed_ms: 1711833142518,
          },
          {
            leverage: 0.375,
            order_type: "LONG",
            order_uuid: "8f634270-3fe3-4478-bc40-89e24d494976",
            price: 69686.61,
            price_sources: [],
            processed_ms: 1711836822557,
          },
          {
            leverage: -0.93359375,
            order_type: "SHORT",
            order_uuid: "689a1bc5-1536-4953-a5c5-fa3ddc398505",
            price: 69729.21,
            price_sources: [],
            processed_ms: 1711840492946,
          },
          {
            leverage: 0.375,
            order_type: "LONG",
            order_uuid: "d23b19d7-82d9-4e4a-9e08-117524b97478",
            price: 69730.31,
            price_sources: [],
            processed_ms: 1711843988899,
          },
          {
            leverage: 0.375,
            order_type: "LONG",
            order_uuid: "531c771b-96b0-4279-8881-a5d8b25931d6",
            price: 70020.36,
            price_sources: [],
            processed_ms: 1711847608359,
          },
          {
            leverage: -0.841796875,
            order_type: "SHORT",
            order_uuid: "b556ba7a-eedc-40f4-9a02-643242a76f97",
            price: 70120.32,
            price_sources: [],
            processed_ms: 1711851225637,
          },
          {
            leverage: -0.4208984375,
            order_type: "SHORT",
            order_uuid: "d3f6ef5c-83b6-448d-a80c-22a34425650e",
            price: 69891.99,
            price_sources: [],
            processed_ms: 1711854840457,
          },
          {
            leverage: 0.375,
            order_type: "LONG",
            order_uuid: "0d1c9f67-fffa-444c-941a-b6f7ac5c8fab",
            price: 69929.09,
            price_sources: [],
            processed_ms: 1711858470548,
          },
          {
            leverage: 0.375,
            order_type: "LONG",
            order_uuid: "56069c75-9ad8-41c9-85ba-f34fb2015552",
            price: 70192.12,
            price_sources: [],
            processed_ms: 1711862085362,
          },
          {
            leverage: -0.58544921875,
            order_type: "SHORT",
            order_uuid: "fa9c0629-8e99-4618-9a2f-5d437ec312dd",
            price: 70298.1,
            price_sources: [],
            processed_ms: 1711865713215,
          },
          {
            leverage: -0.292724609375,
            order_type: "SHORT",
            order_uuid: "dc17bd46-6de8-4671-b68b-9704ba92bf6c",
            price: 70290.51,
            price_sources: [],
            processed_ms: 1711869336765,
          },
          {
            leverage: -0.1463623046875,
            order_type: "SHORT",
            order_uuid: "5b2c8493-5c75-4e73-8834-7724532fb3ea",
            price: 70314.99,
            price_sources: [],
            processed_ms: 1711872953271,
          },
          {
            leverage: 0.375,
            order_type: "LONG",
            order_uuid: "c032fb0b-ac02-4b73-8f5f-a4a0b7d5fc80",
            price: 70246.94,
            price_sources: [],
            processed_ms: 1711876568851,
          },
          {
            leverage: -0.26068115234375,
            order_type: "SHORT",
            order_uuid: "8e89ec10-f6ba-4ee7-9744-92cc28acbc37",
            price: 70289.52,
            price_sources: [],
            processed_ms: 1711880190053,
          },
          {
            leverage: -0.130340576171875,
            order_type: "SHORT",
            order_uuid: "08454e3e-0390-4495-9a20-a1f41701c399",
            price: 70397.86,
            price_sources: [],
            processed_ms: 1711883839041,
          },
          {
            leverage: -0.0651702880859375,
            order_type: "SHORT",
            order_uuid: "1c02ae55-231f-4048-bc6c-e637c0bbcba3",
            price: 70560.68,
            price_sources: [],
            processed_ms: 1711887415553,
          },
          {
            leverage: -0.03258514404296875,
            order_type: "SHORT",
            order_uuid: "cb9ade66-99ed-4b0b-93ee-edbed1a39ee3",
            price: 70749.4,
            price_sources: [],
            processed_ms: 1711891038481,
          },
          {
            leverage: 0.375,
            order_type: "LONG",
            order_uuid: "8ad86904-927d-4dbc-9a6a-3e71465f5b24",
            price: 70513.28,
            price_sources: [],
            processed_ms: 1711894657861,
          },
          {
            leverage: 0.375,
            order_type: "LONG",
            order_uuid: "811a10e7-805e-438d-9ee7-08d27dc98636",
            price: 70427.78,
            price_sources: [],
            processed_ms: 1711898278906,
          },
          {
            leverage: 0.375,
            order_type: "LONG",
            order_uuid: "13ba720b-ec08-4551-ab65-a2a61432e031",
            price: 70646.4,
            price_sources: [],
            processed_ms: 1711901937342,
          },
          {
            leverage: 0.375,
            order_type: "LONG",
            order_uuid: "e86568ff-d97a-4117-a878-798136ae8714",
            price: 70517.97,
            price_sources: [],
            processed_ms: 1711905515706,
          },
          {
            leverage: 0.375,
            order_type: "LONG",
            order_uuid: "3549fd50-808f-47f4-a55e-ce9ccc5109ad",
            price: 70536.23,
            price_sources: [],
            processed_ms: 1711909138769,
          },
          {
            leverage: 0.375,
            order_type: "LONG",
            order_uuid: "8cfffc5f-9d26-4212-b228-13bd3b074a00",
            price: 70930.04,
            price_sources: [],
            processed_ms: 1711912755850,
          },
          {
            leverage: 0.375,
            order_type: "LONG",
            order_uuid: "af6fa544-4a0a-4390-ad16-4a6a464b65b3",
            price: 71024.42,
            price_sources: [],
            processed_ms: 1711916378153,
          },
          {
            leverage: 0.375,
            order_type: "LONG",
            order_uuid: "46adcb99-9741-4870-bab7-4f21997e9ee1",
            price: 70930.46,
            price_sources: [],
            processed_ms: 1711920019540,
          },
          {
            leverage: 0.375,
            order_type: "LONG",
            order_uuid: "1eed35ff-090e-4043-8836-e78e7532ff4a",
            price: 70874.59,
            price_sources: [],
            processed_ms: 1711923619252,
          },
          {
            leverage: 0.375,
            order_type: "LONG",
            order_uuid: "d785c420-ec3f-49f8-93d9-4e3713966135",
            price: 71113.5,
            price_sources: [],
            processed_ms: 1711927234379,
          },
          {
            leverage: 0.375,
            order_type: "LONG",
            order_uuid: "245a86ef-37cb-4bc8-a06a-5615917ea3c2",
            price: 71222.5,
            price_sources: [],
            processed_ms: 1711930856401,
          },
          {
            leverage: 0.375,
            order_type: "LONG",
            order_uuid: "048596db-a760-4c2c-a9eb-11e04ad80bc1",
            price: 71002.97,
            price_sources: [],
            processed_ms: 1711934487738,
          },
          {
            leverage: -2.2662925720214844,
            order_type: "SHORT",
            order_uuid: "53cbe420-2eec-4e6d-8686-c67f44c83453",
            price: 70884.7,
            price_sources: [],
            processed_ms: 1711938105492,
          },
          {
            leverage: 0.375,
            order_type: "LONG",
            order_uuid: "387ac87b-6b52-4c0c-a065-6c85d16f6a71",
            price: 70792.41,
            price_sources: [],
            processed_ms: 1711941719591,
          },
          {
            leverage: -1.3206462860107422,
            order_type: "SHORT",
            order_uuid: "56b97dd1-a67d-4e53-b0f6-64cb70fae6b6",
            price: 70573.61,
            price_sources: [],
            processed_ms: 1711945353311,
          },
          {
            leverage: -0.6603231430053711,
            order_type: "SHORT",
            order_uuid: "cf895978-f838-4d4a-a0bf-2626afb25064",
            price: 70444.6,
            price_sources: [],
            processed_ms: 1711948988313,
          },
          {
            leverage: -0.5282585144042968,
            order_type: "SHORT",
            order_uuid: "c38172ee-f604-4fdd-b0cb-a8375865d3d0",
            price: 69435.6,
            price_sources: [],
            processed_ms: 1711952604602,
          },
          {
            leverage: -0.06603231430053713,
            order_type: "SHORT",
            order_uuid: "ac16d0f9-7655-471a-b8b1-368f6aba7e92",
            price: 69718.8,
            price_sources: [],
            processed_ms: 1711956585231,
          },
          {
            leverage: -0.033016157150268566,
            order_type: "SHORT",
            order_uuid: "ae5f447b-d8c5-4696-8b0a-765ec2b7d6fd",
            price: 69421.7,
            price_sources: [],
            processed_ms: 1711960143085,
          },
          {
            leverage: 0.375,
            order_type: "LONG",
            order_uuid: "2bcfdcc9-a492-4f9f-b24c-1794df684ebb",
            price: 69570.9,
            price_sources: [],
            processed_ms: 1711963765364,
          },
          {
            leverage: -0.20400807857513428,
            order_type: "SHORT",
            order_uuid: "3a3b43fd-2e5e-48ed-b532-8b3b349e9f0d",
            price: 69338.4,
            price_sources: [],
            processed_ms: 1711967383232,
          },
          {
            leverage: 0.375,
            order_type: "LONG",
            order_uuid: "e4b98e5c-46d6-4a36-a792-e4603a1bc6d9",
            price: 69502.6,
            price_sources: [],
            processed_ms: 1711971550128,
          },
          {
            leverage: 0.375,
            order_type: "LONG",
            order_uuid: "72ac6807-5e28-43e6-b520-1e858310b002",
            price: 69697.5,
            price_sources: [],
            processed_ms: 1711975084104,
          },
          {
            leverage: 0.3750000000000001,
            order_type: "LONG",
            order_uuid: "54c084c6-f4e9-4614-9eef-edf68e205272",
            price: 70025.1,
            price_sources: [],
            processed_ms: 1711979484423,
          },
          {
            leverage: 0.375,
            order_type: "LONG",
            order_uuid: "1afbb873-39b8-4801-81b1-ffbc211c474b",
            price: 69055.3,
            price_sources: [],
            processed_ms: 1711983114843,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "5cda8232-8fa6-4d65-95f9-f403181201c6",
            price: 68212.0,
            price_sources: [],
            processed_ms: 1711984425683,
          },
        ],
        position_type: "FLAT",
        position_uuid: "eb7a13b2-51e2-4433-8300-26b8122ee220",
        return_at_close: 0.9535780682517087,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
    ],
    thirty_day_returns: 1.0,
  },
  "5GMxwkXtcALBn5gktSnmZQQf2QgdB6mW2jPx1a5UQM9EZD8E": {
    all_time_returns: 1.0,
    n_positions: 0,
    percentage_profitable: 0.0,
    positions: [
      {
        average_entry_price: 63813.59,
        close_ms: 1711135789987,
        current_return: 1.0054725020172033,
        initial_entry_price: 63813.59,
        is_closed_position: true,
        miner_hotkey: "5GMxwkXtcALBn5gktSnmZQQf2QgdB6mW2jPx1a5UQM9EZD8E",
        net_leverage: 0.0,
        open_ms: 1711132991385,
        orders: [
          {
            leverage: -2.0,
            order_type: "SHORT",
            order_uuid: "7982b80b-4251-41f3-ace9-731a9425970f",
            price: 63813.59,
            price_sources: [],
            processed_ms: 1711132991384,
          },
          {
            leverage: 10.0,
            order_type: "FLAT",
            order_uuid: "40c4cb8f-9836-4106-b3ef-603a6fbc0385",
            price: 63638.98,
            price_sources: [],
            processed_ms: 1711135789987,
          },
        ],
        position_type: "FLAT",
        position_uuid: "24918b33-50d5-420d-9875-7dadb923ea59",
        return_at_close: 1.0014506120091344,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 64277.903999999995,
        close_ms: 1711178499379,
        current_return: 1.0089416948071306,
        initial_entry_price: 64207.66,
        is_closed_position: true,
        miner_hotkey: "5GMxwkXtcALBn5gktSnmZQQf2QgdB6mW2jPx1a5UQM9EZD8E",
        net_leverage: 0.0,
        open_ms: 1711176501704,
        orders: [
          {
            leverage: 0.21,
            order_type: "LONG",
            order_uuid: "3e83fa6a-c4f5-470f-a965-afd687b6daf2",
            price: 64207.66,
            price_sources: [],
            processed_ms: 1711176501704,
          },
          {
            leverage: 0.21,
            order_type: "LONG",
            order_uuid: "16a05b27-5d0b-4f69-8203-fabcac9c73db",
            price: 64134.13,
            price_sources: [],
            processed_ms: 1711176898909,
          },
          {
            leverage: 0.21,
            order_type: "LONG",
            order_uuid: "7a892f15-8469-4d1c-84c3-bc307af73e37",
            price: 64193.82,
            price_sources: [],
            processed_ms: 1711177303766,
          },
          {
            leverage: 0.21,
            order_type: "LONG",
            order_uuid: "f3844ad6-3aeb-46fa-99de-06f6bbe3e325",
            price: 64288.32,
            price_sources: [],
            processed_ms: 1711177699202,
          },
          {
            leverage: 0.21,
            order_type: "LONG",
            order_uuid: "ad8a99cd-32bb-4bed-82bc-8edaeed06190",
            price: 64565.59,
            price_sources: [],
            processed_ms: 1711178107854,
          },
          {
            leverage: 0.21,
            order_type: "FLAT",
            order_uuid: "c8b85574-cce5-40b9-a3b4-9898710b3880",
            price: 64824.69,
            price_sources: [],
            processed_ms: 1711178499379,
          },
        ],
        position_type: "FLAT",
        position_uuid: "4a75db23-ad5e-4e22-8a7a-82ea0f3d6434",
        return_at_close: 1.0068229172480356,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 64567.042,
        close_ms: 1711180902175,
        current_return: 1.0001650939051978,
        initial_entry_price: 64605.05,
        is_closed_position: true,
        miner_hotkey: "5GMxwkXtcALBn5gktSnmZQQf2QgdB6mW2jPx1a5UQM9EZD8E",
        net_leverage: 0.0,
        open_ms: 1711178911755,
        orders: [
          {
            leverage: 0.21,
            order_type: "LONG",
            order_uuid: "2530196d-9eb9-4bb9-becf-9564cdfcc2dc",
            price: 64605.05,
            price_sources: [],
            processed_ms: 1711178910873,
          },
          {
            leverage: 0.21,
            order_type: "LONG",
            order_uuid: "6888114b-ab16-4f80-8bbf-12e72013a3ba",
            price: 64469.8,
            price_sources: [],
            processed_ms: 1711179307138,
          },
          {
            leverage: 0.21,
            order_type: "LONG",
            order_uuid: "d31ad976-afb5-4ee7-8eb5-922f20b933ea",
            price: 64662.1,
            price_sources: [],
            processed_ms: 1711179705998,
          },
          {
            leverage: 0.21,
            order_type: "LONG",
            order_uuid: "7069973d-9ee5-410d-8483-026a8862b6a8",
            price: 64588.26,
            price_sources: [],
            processed_ms: 1711180103035,
          },
          {
            leverage: 0.21,
            order_type: "LONG",
            order_uuid: "3362695d-0ab1-4253-9e8b-76dc6a48b452",
            price: 64510.0,
            price_sources: [],
            processed_ms: 1711180509983,
          },
          {
            leverage: 0.21,
            order_type: "FLAT",
            order_uuid: "e3a54b94-e601-44aa-bf70-4b7f55b0dc34",
            price: 64577.2,
            price_sources: [],
            processed_ms: 1711180902175,
          },
        ],
        position_type: "FLAT",
        position_uuid: "9b80194c-f1e7-4868-9944-3c0130b4226a",
        return_at_close: 0.9980647472079969,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 64559.40399999999,
        close_ms: 1711183324580,
        current_return: 0.9980558871466447,
        initial_entry_price: 64797.01,
        is_closed_position: true,
        miner_hotkey: "5GMxwkXtcALBn5gktSnmZQQf2QgdB6mW2jPx1a5UQM9EZD8E",
        net_leverage: 0.0,
        open_ms: 1711181307728,
        orders: [
          {
            leverage: 0.21,
            order_type: "LONG",
            order_uuid: "a89e4d09-8713-4226-9c22-9a16ccc9983f",
            price: 64797.01,
            price_sources: [],
            processed_ms: 1711181307727,
          },
          {
            leverage: 0.21,
            order_type: "LONG",
            order_uuid: "e0c38d1f-70f5-46ef-8dda-dcbd0bf470ca",
            price: 64596.32,
            price_sources: [],
            processed_ms: 1711181718747,
          },
          {
            leverage: 0.21,
            order_type: "LONG",
            order_uuid: "9bf04a91-cb78-4cd0-9f17-d88e29b24ff3",
            price: 64588.64,
            price_sources: [],
            processed_ms: 1711182104834,
          },
          {
            leverage: 0.21,
            order_type: "LONG",
            order_uuid: "74eeaee9-e7bd-4f60-8b08-d432eaefd83e",
            price: 64489.96,
            price_sources: [],
            processed_ms: 1711182509773,
          },
          {
            leverage: 0.21,
            order_type: "LONG",
            order_uuid: "07b493f5-ac7c-4017-810b-061de4a51307",
            price: 64325.09,
            price_sources: [],
            processed_ms: 1711182918370,
          },
          {
            leverage: 0.21,
            order_type: "FLAT",
            order_uuid: "47837abc-7e65-4179-b019-3f6fae2e74c1",
            price: 64439.43,
            price_sources: [],
            processed_ms: 1711183324580,
          },
        ],
        position_type: "FLAT",
        position_uuid: "c01ebece-5862-41a1-8f9c-c65d83bad2be",
        return_at_close: 0.9959599697836368,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 64409.231999999996,
        close_ms: 1711185703871,
        current_return: 0.9983081018265026,
        initial_entry_price: 64469.66,
        is_closed_position: true,
        miner_hotkey: "5GMxwkXtcALBn5gktSnmZQQf2QgdB6mW2jPx1a5UQM9EZD8E",
        net_leverage: 0.0,
        open_ms: 1711183709545,
        orders: [
          {
            leverage: 0.21,
            order_type: "LONG",
            order_uuid: "a81151b6-3f78-413a-a0bb-3e5cd9be6437",
            price: 64469.66,
            price_sources: [],
            processed_ms: 1711183709544,
          },
          {
            leverage: 0.21,
            order_type: "LONG",
            order_uuid: "636e2cc5-d538-409c-8c71-a4f593d7c81e",
            price: 64363.53,
            price_sources: [],
            processed_ms: 1711184109010,
          },
          {
            leverage: 0.21,
            order_type: "LONG",
            order_uuid: "d98b1db6-41f1-43a9-bffa-1e7d057dab5a",
            price: 64538.5,
            price_sources: [],
            processed_ms: 1711184516901,
          },
          {
            leverage: 0.21,
            order_type: "LONG",
            order_uuid: "65f63004-e016-404e-b3b1-9467d015f2a4",
            price: 64417.38,
            price_sources: [],
            processed_ms: 1711184936247,
          },
          {
            leverage: 0.21,
            order_type: "LONG",
            order_uuid: "4b3252ca-84cb-4ae5-bb41-638381357ae2",
            price: 64257.09,
            price_sources: [],
            processed_ms: 1711185305597,
          },
          {
            leverage: 0.21,
            order_type: "FLAT",
            order_uuid: "8ab306d8-6fd4-4c05-ad73-146e8e64dd41",
            price: 64305.35,
            price_sources: [],
            processed_ms: 1711185703871,
          },
        ],
        position_type: "FLAT",
        position_uuid: "c7ce85c7-dafe-4e16-abee-82dbd6a46e3d",
        return_at_close: 0.996211654812667,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 64369.749999999985,
        close_ms: 1711188125976,
        current_return: 1.0028704798987513,
        initial_entry_price: 64324.61,
        is_closed_position: true,
        miner_hotkey: "5GMxwkXtcALBn5gktSnmZQQf2QgdB6mW2jPx1a5UQM9EZD8E",
        net_leverage: 0.0,
        open_ms: 1711186113958,
        orders: [
          {
            leverage: 0.21,
            order_type: "LONG",
            order_uuid: "7314f210-b31a-40d3-bb42-86164b484758",
            price: 64324.61,
            price_sources: [],
            processed_ms: 1711186113957,
          },
          {
            leverage: 0.21,
            order_type: "LONG",
            order_uuid: "ea09f406-b3e4-4736-ad22-5f031d84ac8f",
            price: 64370.98,
            price_sources: [],
            processed_ms: 1711186525462,
          },
          {
            leverage: 0.21,
            order_type: "LONG",
            order_uuid: "c399c64a-be64-41d7-a15f-b4a101c8ddcc",
            price: 64364.31,
            price_sources: [],
            processed_ms: 1711186923344,
          },
          {
            leverage: 0.21,
            order_type: "LONG",
            order_uuid: "72508404-0baf-49b1-a1f8-c9dfc3893a51",
            price: 64375.01,
            price_sources: [],
            processed_ms: 1711187308026,
          },
          {
            leverage: 0.21,
            order_type: "LONG",
            order_uuid: "88edb349-2b2f-4e37-803c-6ea1741bce01",
            price: 64413.84,
            price_sources: [],
            processed_ms: 1711187706939,
          },
          {
            leverage: 0.21,
            order_type: "FLAT",
            order_uuid: "447e1dd0-6e50-4cdd-bb7f-33438d305cca",
            price: 64545.6,
            price_sources: [],
            processed_ms: 1711188125976,
          },
        ],
        position_type: "FLAT",
        position_uuid: "61e6d5d1-4da6-43f2-bb21-caba0812e401",
        return_at_close: 1.000764451890964,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 64561.87799999999,
        close_ms: 1711190509151,
        current_return: 0.9993191778668616,
        initial_entry_price: 64571.05,
        is_closed_position: true,
        miner_hotkey: "5GMxwkXtcALBn5gktSnmZQQf2QgdB6mW2jPx1a5UQM9EZD8E",
        net_leverage: 0.0,
        open_ms: 1711188525880,
        orders: [
          {
            leverage: 0.21,
            order_type: "LONG",
            order_uuid: "080fe9eb-9b32-4255-8c78-39f8c25fd9a7",
            price: 64571.05,
            price_sources: [],
            processed_ms: 1711188525879,
          },
          {
            leverage: 0.21,
            order_type: "LONG",
            order_uuid: "7327c1eb-1aeb-4dc3-906c-8f02b2b1f83b",
            price: 64450.18,
            price_sources: [],
            processed_ms: 1711188928254,
          },
          {
            leverage: 0.21,
            order_type: "LONG",
            order_uuid: "968c07d7-ff84-4713-9953-a9bc2b197537",
            price: 64585.66,
            price_sources: [],
            processed_ms: 1711189331361,
          },
          {
            leverage: 0.21,
            order_type: "LONG",
            order_uuid: "dbd0c002-0787-40ae-aa67-dd59c2d29b5e",
            price: 64598.67,
            price_sources: [],
            processed_ms: 1711189733029,
          },
          {
            leverage: 0.21,
            order_type: "LONG",
            order_uuid: "2d726cb0-8d2d-41bc-b349-bbb44f3f386d",
            price: 64603.83,
            price_sources: [],
            processed_ms: 1711190131025,
          },
          {
            leverage: 0.21,
            order_type: "FLAT",
            order_uuid: "3cf66be1-9b07-4c20-894f-a4100dea752b",
            price: 64520.01,
            price_sources: [],
            processed_ms: 1711190509151,
          },
        ],
        position_type: "FLAT",
        position_uuid: "d1b7e99f-2656-4603-b818-b22a604fc5e4",
        return_at_close: 0.9972206075933412,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 64368.55199999999,
        close_ms: 1711192930631,
        current_return: 1.001667019705781,
        initial_entry_price: 64534.87,
        is_closed_position: true,
        miner_hotkey: "5GMxwkXtcALBn5gktSnmZQQf2QgdB6mW2jPx1a5UQM9EZD8E",
        net_leverage: 0.0,
        open_ms: 1711190911015,
        orders: [
          {
            leverage: 0.21,
            order_type: "LONG",
            order_uuid: "f68854a3-b9c3-46a1-85fe-2278fb507062",
            price: 64534.87,
            price_sources: [],
            processed_ms: 1711190911013,
          },
          {
            leverage: 0.21,
            order_type: "LONG",
            order_uuid: "12935569-8464-41b5-95fe-c1620a929eed",
            price: 64266.55,
            price_sources: [],
            processed_ms: 1711191314201,
          },
          {
            leverage: 0.21,
            order_type: "LONG",
            order_uuid: "51acc76d-e0de-4c6c-af01-6a2e4c924b3e",
            price: 64353.2,
            price_sources: [],
            processed_ms: 1711191715063,
          },
          {
            leverage: 0.21,
            order_type: "LONG",
            order_uuid: "2dc42519-010b-4a8b-b847-b95ffaba852b",
            price: 64272.65,
            price_sources: [],
            processed_ms: 1711192125237,
          },
          {
            leverage: 0.21,
            order_type: "LONG",
            order_uuid: "931a2c90-355e-4c2d-a657-bb30fb9fa727",
            price: 64415.49,
            price_sources: [],
            processed_ms: 1711192529630,
          },
          {
            leverage: 0.21,
            order_type: "FLAT",
            order_uuid: "75827ea3-ef77-4906-823c-6687d8ad9416",
            price: 64471.01,
            price_sources: [],
            processed_ms: 1711192930631,
          },
        ],
        position_type: "FLAT",
        position_uuid: "f9da290f-0671-4ca1-a36d-8fca14376c2c",
        return_at_close: 0.999563518964399,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 64529.98999999999,
        close_ms: 1711195314266,
        current_return: 1.0008713948461498,
        initial_entry_price: 64453.56,
        is_closed_position: true,
        miner_hotkey: "5GMxwkXtcALBn5gktSnmZQQf2QgdB6mW2jPx1a5UQM9EZD8E",
        net_leverage: 0.0,
        open_ms: 1711193333229,
        orders: [
          {
            leverage: 0.21,
            order_type: "LONG",
            order_uuid: "b1952aea-bcb0-4a09-be7d-6e296cf19ff8",
            price: 64453.56,
            price_sources: [],
            processed_ms: 1711193333228,
          },
          {
            leverage: 0.21,
            order_type: "LONG",
            order_uuid: "de20faac-35d1-4c0a-a83c-9dbc61d69a5b",
            price: 64569.37,
            price_sources: [],
            processed_ms: 1711193718378,
          },
          {
            leverage: 0.21,
            order_type: "LONG",
            order_uuid: "4a9ed1b2-0626-4ce3-84b0-4472356baeb6",
            price: 64536.1,
            price_sources: [],
            processed_ms: 1711194118136,
          },
          {
            leverage: 0.21,
            order_type: "LONG",
            order_uuid: "908aa609-f65a-4c92-9b00-42edfbc625cd",
            price: 64553.69,
            price_sources: [],
            processed_ms: 1711194541382,
          },
          {
            leverage: 0.21,
            order_type: "LONG",
            order_uuid: "4ac4630f-1d79-4a96-9400-6321551db3f3",
            price: 64537.23,
            price_sources: [],
            processed_ms: 1711194914427,
          },
          {
            leverage: 0.21,
            order_type: "FLAT",
            order_uuid: "90322178-984d-4eb1-86ec-0f977312278d",
            price: 64583.48,
            price_sources: [],
            processed_ms: 1711195314266,
          },
        ],
        position_type: "FLAT",
        position_uuid: "15b625ca-7b19-4504-bc49-09e3a87d2726",
        return_at_close: 0.9987695649169729,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 64661.48,
        close_ms: 1711197730815,
        current_return: 1.0014358118031776,
        initial_entry_price: 64682.92,
        is_closed_position: true,
        miner_hotkey: "5GMxwkXtcALBn5gktSnmZQQf2QgdB6mW2jPx1a5UQM9EZD8E",
        net_leverage: 0.0,
        open_ms: 1711195716674,
        orders: [
          {
            leverage: 0.21,
            order_type: "LONG",
            order_uuid: "619da09e-969b-4c93-afc8-00173a158134",
            price: 64682.92,
            price_sources: [],
            processed_ms: 1711195716673,
          },
          {
            leverage: 0.21,
            order_type: "LONG",
            order_uuid: "6e39bb02-64af-42e4-8c81-ca0fbabc2242",
            price: 64625.47,
            price_sources: [],
            processed_ms: 1711196124524,
          },
          {
            leverage: 0.21,
            order_type: "LONG",
            order_uuid: "d4e62bcc-4630-43c7-a0dd-df83278f6957",
            price: 64662.28,
            price_sources: [],
            processed_ms: 1711196524752,
          },
          {
            leverage: 0.21,
            order_type: "LONG",
            order_uuid: "c8786cd2-de3a-4bb6-ab58-4add51a776c7",
            price: 64687.02,
            price_sources: [],
            processed_ms: 1711196924242,
          },
          {
            leverage: 0.21,
            order_type: "LONG",
            order_uuid: "f44a491c-0a9f-41a8-8a31-a90563376e7d",
            price: 64649.71,
            price_sources: [],
            processed_ms: 1711197324260,
          },
          {
            leverage: 0.21,
            order_type: "FLAT",
            order_uuid: "d13b9c4c-9ec9-4b28-92fd-68de339af5a9",
            price: 64749.93,
            price_sources: [],
            processed_ms: 1711197730815,
          },
        ],
        position_type: "FLAT",
        position_uuid: "30d8b9e8-4f70-467c-a6c4-b1308acac68d",
        return_at_close: 0.999332796598391,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 64706.40799999999,
        close_ms: 1711200115338,
        current_return: 0.9985847162486938,
        initial_entry_price: 64818.38,
        is_closed_position: true,
        miner_hotkey: "5GMxwkXtcALBn5gktSnmZQQf2QgdB6mW2jPx1a5UQM9EZD8E",
        net_leverage: 0.0,
        open_ms: 1711198129164,
        orders: [
          {
            leverage: 0.21,
            order_type: "LONG",
            order_uuid: "0f8d8b74-a07a-4ff8-bb8a-dbbb070c7d27",
            price: 64818.38,
            price_sources: [],
            processed_ms: 1711198129162,
          },
          {
            leverage: 0.21,
            order_type: "LONG",
            order_uuid: "fe11f1e6-d036-4beb-875a-aea892c88729",
            price: 64857.77,
            price_sources: [],
            processed_ms: 1711198535412,
          },
          {
            leverage: 0.21,
            order_type: "LONG",
            order_uuid: "77fccf9a-6071-42c1-8ab1-8dc984a7f5c4",
            price: 64644.85,
            price_sources: [],
            processed_ms: 1711198940825,
          },
          {
            leverage: 0.21,
            order_type: "LONG",
            order_uuid: "0fb2348b-2124-420b-831e-97527c839b60",
            price: 64586.79,
            price_sources: [],
            processed_ms: 1711199314327,
          },
          {
            leverage: 0.21,
            order_type: "LONG",
            order_uuid: "3081f057-0ba0-4bd5-88fc-2e2cf115811a",
            price: 64624.25,
            price_sources: [],
            processed_ms: 1711199714978,
          },
          {
            leverage: 0.21,
            order_type: "FLAT",
            order_uuid: "0192bb71-98eb-49a3-aa71-33b77eaabbf3",
            price: 64619.04,
            price_sources: [],
            processed_ms: 1711200115338,
          },
        ],
        position_type: "FLAT",
        position_uuid: "d8e22198-bcae-4a07-bf88-9fefa8a51248",
        return_at_close: 0.9964876883445716,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 64486.796,
        close_ms: 1711202522917,
        current_return: 1.002031950001922,
        initial_entry_price: 64517.68,
        is_closed_position: true,
        miner_hotkey: "5GMxwkXtcALBn5gktSnmZQQf2QgdB6mW2jPx1a5UQM9EZD8E",
        net_leverage: 0.0,
        open_ms: 1711200520383,
        orders: [
          {
            leverage: 0.21,
            order_type: "LONG",
            order_uuid: "4750b87f-eb44-4530-b265-275536294336",
            price: 64517.68,
            price_sources: [],
            processed_ms: 1711200520382,
          },
          {
            leverage: 0.21,
            order_type: "LONG",
            order_uuid: "756abcef-857b-455c-a731-73ea6cd71c70",
            price: 64454.56,
            price_sources: [],
            processed_ms: 1711200918156,
          },
          {
            leverage: 0.21,
            order_type: "LONG",
            order_uuid: "53916281-6203-4945-9a44-f0d2c7a87c38",
            price: 64497.5,
            price_sources: [],
            processed_ms: 1711201316251,
          },
          {
            leverage: 0.21,
            order_type: "LONG",
            order_uuid: "c38dcc88-7465-42b7-bd9e-1d3fae85b5d7",
            price: 64305.85,
            price_sources: [],
            processed_ms: 1711201719317,
          },
          {
            leverage: 0.21,
            order_type: "LONG",
            order_uuid: "997fbf91-a314-48e3-9bd9-5e66b78f87f2",
            price: 64658.39,
            price_sources: [],
            processed_ms: 1711202124111,
          },
          {
            leverage: 0.21,
            order_type: "FLAT",
            order_uuid: "1a313050-190f-4d9f-a2b7-196b4eb0acba",
            price: 64611.65,
            price_sources: [],
            processed_ms: 1711202522917,
          },
        ],
        position_type: "FLAT",
        position_uuid: "d0233af9-9ea8-4498-9add-a64527274336",
        return_at_close: 0.9999276829069179,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 64781.791999999994,
        close_ms: 1711204926648,
        current_return: 1.0059067522050695,
        initial_entry_price: 64618.15,
        is_closed_position: true,
        miner_hotkey: "5GMxwkXtcALBn5gktSnmZQQf2QgdB6mW2jPx1a5UQM9EZD8E",
        net_leverage: 0.0,
        open_ms: 1711202929329,
        orders: [
          {
            leverage: 0.21,
            order_type: "LONG",
            order_uuid: "c16eaeef-f9e0-4ae1-8a97-268b6891406e",
            price: 64618.15,
            price_sources: [],
            processed_ms: 1711202929327,
          },
          {
            leverage: 0.21,
            order_type: "LONG",
            order_uuid: "5a5dc0a6-aaef-4b2f-97ed-4efee9214d66",
            price: 64562.31,
            price_sources: [],
            processed_ms: 1711203333186,
          },
          {
            leverage: 0.21,
            order_type: "LONG",
            order_uuid: "ae8cd283-b47a-40d7-a3f1-6f737ceb5e68",
            price: 64655.82,
            price_sources: [],
            processed_ms: 1711203733131,
          },
          {
            leverage: 0.21,
            order_type: "LONG",
            order_uuid: "e1d0c383-a93d-4b44-a7b6-7d7627a6faab",
            price: 64765.41,
            price_sources: [],
            processed_ms: 1711204136191,
          },
          {
            leverage: 0.21,
            order_type: "LONG",
            order_uuid: "bebec4e8-ece2-45fc-9337-4ea908bc0e1a",
            price: 65307.27,
            price_sources: [],
            processed_ms: 1711204521336,
          },
          {
            leverage: 0.21,
            order_type: "FLAT",
            order_uuid: "834ae48a-1400-4404-8ebb-4d73f6ba8023",
            price: 65145.3,
            price_sources: [],
            processed_ms: 1711204926648,
          },
        ],
        position_type: "FLAT",
        position_uuid: "5681b9f6-7750-4323-becb-f24ba0190c8d",
        return_at_close: 1.003794348025439,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 65190.328,
        close_ms: 1711207322790,
        current_return: 0.9937940893018878,
        initial_entry_price: 65152.71,
        is_closed_position: true,
        miner_hotkey: "5GMxwkXtcALBn5gktSnmZQQf2QgdB6mW2jPx1a5UQM9EZD8E",
        net_leverage: 0.0,
        open_ms: 1711205327328,
        orders: [
          {
            leverage: 0.21,
            order_type: "LONG",
            order_uuid: "333cecdc-6afa-4b25-aa56-39308461e506",
            price: 65152.71,
            price_sources: [],
            processed_ms: 1711205327325,
          },
          {
            leverage: 0.21,
            order_type: "LONG",
            order_uuid: "b7fd9fa3-a227-4940-b38d-cb2902ffd34f",
            price: 65273.36,
            price_sources: [],
            processed_ms: 1711205730138,
          },
          {
            leverage: 0.21,
            order_type: "LONG",
            order_uuid: "45672837-4e6e-403c-b23f-b5275683e899",
            price: 65177.19,
            price_sources: [],
            processed_ms: 1711206131945,
          },
          {
            leverage: 0.21,
            order_type: "LONG",
            order_uuid: "e6f6b261-485c-4edb-a7a3-8d6c561beb07",
            price: 65268.35,
            price_sources: [],
            processed_ms: 1711206539263,
          },
          {
            leverage: 0.21,
            order_type: "LONG",
            order_uuid: "e7b26176-584d-4c1c-a3b1-9e9ddbd565f9",
            price: 65080.03,
            price_sources: [],
            processed_ms: 1711206940240,
          },
          {
            leverage: 0.21,
            order_type: "FLAT",
            order_uuid: "487f2e56-c74e-4ec7-8aba-62d0e85c2cb9",
            price: 64805.25,
            price_sources: [],
            processed_ms: 1711207322790,
          },
        ],
        position_type: "FLAT",
        position_uuid: "0ac139c2-9b63-4a1f-a617-cce8f38ef44d",
        return_at_close: 0.9917071217143539,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 64917.21,
        close_ms: 1711209727286,
        current_return: 1.0031401386525276,
        initial_entry_price: 64819.59,
        is_closed_position: true,
        miner_hotkey: "5GMxwkXtcALBn5gktSnmZQQf2QgdB6mW2jPx1a5UQM9EZD8E",
        net_leverage: 0.0,
        open_ms: 1711207725172,
        orders: [
          {
            leverage: 0.21,
            order_type: "LONG",
            order_uuid: "ece80d54-b82f-413f-bc58-be52908c6aae",
            price: 64819.59,
            price_sources: [],
            processed_ms: 1711207725170,
          },
          {
            leverage: 0.21,
            order_type: "LONG",
            order_uuid: "bcdf11f4-85dd-4820-b7c1-ed361336447a",
            price: 64929.98,
            price_sources: [],
            processed_ms: 1711208133879,
          },
          {
            leverage: 0.21,
            order_type: "LONG",
            order_uuid: "e88760fe-4548-4e69-87b4-b8c3fad0b63e",
            price: 64815.83,
            price_sources: [],
            processed_ms: 1711208541137,
          },
          {
            leverage: 0.21,
            order_type: "LONG",
            order_uuid: "3c7789fd-f345-4ce1-a987-55c5f41eeb44",
            price: 64916.03,
            price_sources: [],
            processed_ms: 1711208943697,
          },
          {
            leverage: 0.21,
            order_type: "LONG",
            order_uuid: "0300367c-db28-4977-acc5-eec068f45d73",
            price: 65104.62,
            price_sources: [],
            processed_ms: 1711209323248,
          },
          {
            leverage: 0.21,
            order_type: "FLAT",
            order_uuid: "d8a403b7-562b-4089-a040-4061cfd486f2",
            price: 65111.06,
            price_sources: [],
            processed_ms: 1711209727286,
          },
        ],
        position_type: "FLAT",
        position_uuid: "3c43b9d2-a114-46ca-a71f-c12321d7be01",
        return_at_close: 1.0010335443613574,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 65198.31399999999,
        close_ms: 1711212143164,
        current_return: 1.0046565095340354,
        initial_entry_price: 64933.68,
        is_closed_position: true,
        miner_hotkey: "5GMxwkXtcALBn5gktSnmZQQf2QgdB6mW2jPx1a5UQM9EZD8E",
        net_leverage: 0.0,
        open_ms: 1711210126468,
        orders: [
          {
            leverage: 0.21,
            order_type: "LONG",
            order_uuid: "5728b81a-bd98-4172-9162-ffc17e0059f4",
            price: 64933.68,
            price_sources: [],
            processed_ms: 1711210126466,
          },
          {
            leverage: 0.21,
            order_type: "LONG",
            order_uuid: "b3417cdf-840a-4639-bb8d-080c85410aa8",
            price: 65220.79,
            price_sources: [],
            processed_ms: 1711210533205,
          },
          {
            leverage: 0.21,
            order_type: "LONG",
            order_uuid: "b6021829-b756-4676-a574-cff4322b2afd",
            price: 65137.81,
            price_sources: [],
            processed_ms: 1711210939117,
          },
          {
            leverage: 0.21,
            order_type: "LONG",
            order_uuid: "69357ae8-8730-4870-97bc-09a0180c5a04",
            price: 65208.67,
            price_sources: [],
            processed_ms: 1711211342308,
          },
          {
            leverage: 0.21,
            order_type: "LONG",
            order_uuid: "731576cf-bd79-428b-b017-5656d7a42001",
            price: 65490.62,
            price_sources: [],
            processed_ms: 1711211743149,
          },
          {
            leverage: 0.21,
            order_type: "FLAT",
            order_uuid: "ac09c4cf-e98a-4120-9c81-5fce3f5de6d0",
            price: 65486.28,
            price_sources: [],
            processed_ms: 1711212143164,
          },
        ],
        position_type: "FLAT",
        position_uuid: "4d5e3219-4452-4a88-a3e8-9d7cd86e49e1",
        return_at_close: 1.002546730864014,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 65634.934,
        close_ms: 1711214531000,
        current_return: 0.9987194960308016,
        initial_entry_price: 65750.05,
        is_closed_position: true,
        miner_hotkey: "5GMxwkXtcALBn5gktSnmZQQf2QgdB6mW2jPx1a5UQM9EZD8E",
        net_leverage: 0.0,
        open_ms: 1711212523240,
        orders: [
          {
            leverage: 0.21,
            order_type: "LONG",
            order_uuid: "4ec2c7b3-02bf-4189-908b-2a855afe0690",
            price: 65750.05,
            price_sources: [],
            processed_ms: 1711212523238,
          },
          {
            leverage: 0.21,
            order_type: "LONG",
            order_uuid: "79c9e089-f2d9-427e-9779-ce69b7eb6758",
            price: 65564.52,
            price_sources: [],
            processed_ms: 1711212937285,
          },
          {
            leverage: 0.21,
            order_type: "LONG",
            order_uuid: "2412fbe3-df55-440c-bbac-372e58446857",
            price: 65724.05,
            price_sources: [],
            processed_ms: 1711213346480,
          },
          {
            leverage: 0.21,
            order_type: "LONG",
            order_uuid: "06c3cbb4-8014-4dac-b401-01319ae21c97",
            price: 65607.56,
            price_sources: [],
            processed_ms: 1711213724409,
          },
          {
            leverage: 0.21,
            order_type: "LONG",
            order_uuid: "a316ab66-8023-47c2-8827-cb59bb7061d4",
            price: 65528.49,
            price_sources: [],
            processed_ms: 1711214127319,
          },
          {
            leverage: 0.21,
            order_type: "FLAT",
            order_uuid: "1e838b9b-9816-4248-a511-436b0b02bf53",
            price: 65554.75,
            price_sources: [],
            processed_ms: 1711214531000,
          },
        ],
        position_type: "FLAT",
        position_uuid: "46c6ae32-78db-419c-8d67-64fb36e0b44f",
        return_at_close: 0.9966221850891369,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 65758.98,
        close_ms: 1711216941210,
        current_return: 0.9984449340200255,
        initial_entry_price: 65644.16,
        is_closed_position: true,
        miner_hotkey: "5GMxwkXtcALBn5gktSnmZQQf2QgdB6mW2jPx1a5UQM9EZD8E",
        net_leverage: 0.0,
        open_ms: 1711214930409,
        orders: [
          {
            leverage: 0.21,
            order_type: "LONG",
            order_uuid: "1283dfbf-6536-4d81-ae0c-a5bb8d9a3e8c",
            price: 65644.16,
            price_sources: [],
            processed_ms: 1711214930407,
          },
          {
            leverage: 0.21,
            order_type: "LONG",
            order_uuid: "8d118c51-2766-4aa1-b5b4-3e7ab7c311de",
            price: 65963.99,
            price_sources: [],
            processed_ms: 1711215328203,
          },
          {
            leverage: 0.21,
            order_type: "LONG",
            order_uuid: "a7cda6d0-dc37-4b7a-8669-87339a9c581b",
            price: 65765.84,
            price_sources: [],
            processed_ms: 1711215732033,
          },
          {
            leverage: 0.21,
            order_type: "LONG",
            order_uuid: "dcb575bc-2d3e-4cf7-9e74-33dbb21a23d1",
            price: 65771.39,
            price_sources: [],
            processed_ms: 1711216133462,
          },
          {
            leverage: 0.21,
            order_type: "LONG",
            order_uuid: "44786f66-af60-4cbd-bbcd-b052d4c78e53",
            price: 65649.52,
            price_sources: [],
            processed_ms: 1711216534128,
          },
          {
            leverage: 0.21,
            order_type: "FLAT",
            order_uuid: "39fb110a-fc09-4b58-b261-4596ca4398e1",
            price: 65661.76,
            price_sources: [],
            processed_ms: 1711216941210,
          },
        ],
        position_type: "FLAT",
        position_uuid: "bf12cbe1-345f-47ea-98f5-33933fa48a61",
        return_at_close: 0.9963481996585835,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 65548.46999999999,
        close_ms: 1711219333396,
        current_return: 0.997832688859056,
        initial_entry_price: 65616.79,
        is_closed_position: true,
        miner_hotkey: "5GMxwkXtcALBn5gktSnmZQQf2QgdB6mW2jPx1a5UQM9EZD8E",
        net_leverage: 0.0,
        open_ms: 1711217344223,
        orders: [
          {
            leverage: 0.21,
            order_type: "LONG",
            order_uuid: "782ab04f-4c90-4de8-8bc6-839902c751ad",
            price: 65616.79,
            price_sources: [],
            processed_ms: 1711217344221,
          },
          {
            leverage: 0.21,
            order_type: "LONG",
            order_uuid: "738eec1d-d2f2-4d41-8342-52014aedc7cf",
            price: 65576.27,
            price_sources: [],
            processed_ms: 1711217743207,
          },
          {
            leverage: 0.21,
            order_type: "LONG",
            order_uuid: "2a6c3254-df02-47a2-a87d-e4d3d578be30",
            price: 65546.13,
            price_sources: [],
            processed_ms: 1711218125333,
          },
          {
            leverage: 0.21,
            order_type: "LONG",
            order_uuid: "99f49e25-f24e-43ab-a061-bc84c74ed79b",
            price: 65524.16,
            price_sources: [],
            processed_ms: 1711218535538,
          },
          {
            leverage: 0.21,
            order_type: "LONG",
            order_uuid: "7e221605-14a5-4ff2-8088-0bf6a61b6672",
            price: 65479.0,
            price_sources: [],
            processed_ms: 1711218933456,
          },
          {
            leverage: 0.21,
            order_type: "FLAT",
            order_uuid: "d8e0ad7a-e98f-40ef-be1b-0d24f07cd2d2",
            price: 65413.03,
            price_sources: [],
            processed_ms: 1711219333396,
          },
        ],
        position_type: "FLAT",
        position_uuid: "3fa94851-a95c-45ad-b235-25d6cafcf2fa",
        return_at_close: 0.9957372402124519,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 65383.63199999999,
        close_ms: 1711221731261,
        current_return: 0.9987092407501067,
        initial_entry_price: 65348.05,
        is_closed_position: true,
        miner_hotkey: "5GMxwkXtcALBn5gktSnmZQQf2QgdB6mW2jPx1a5UQM9EZD8E",
        net_leverage: 0.0,
        open_ms: 1711219734151,
        orders: [
          {
            leverage: 0.21,
            order_type: "LONG",
            order_uuid: "ab107a54-009d-451b-a7a5-feaf498e8e48",
            price: 65348.05,
            price_sources: [],
            processed_ms: 1711219734149,
          },
          {
            leverage: 0.21,
            order_type: "LONG",
            order_uuid: "e17af485-b3fe-49f8-a56a-883f5cc3b8df",
            price: 65417.03,
            price_sources: [],
            processed_ms: 1711220135228,
          },
          {
            leverage: 0.21,
            order_type: "LONG",
            order_uuid: "21752443-34ef-462e-bbaf-54ba517b0d80",
            price: 65426.23,
            price_sources: [],
            processed_ms: 1711220543276,
          },
          {
            leverage: 0.21,
            order_type: "LONG",
            order_uuid: "b6e958ea-6814-4426-9357-c8294234a110",
            price: 65350.25,
            price_sources: [],
            processed_ms: 1711220927717,
          },
          {
            leverage: 0.21,
            order_type: "LONG",
            order_uuid: "505466f1-9e62-4915-8957-7af2fe711bea",
            price: 65376.6,
            price_sources: [],
            processed_ms: 1711221328850,
          },
          {
            leverage: 0.21,
            order_type: "FLAT",
            order_uuid: "3cf16eee-7333-4d76-8b3e-75e8970415e0",
            price: 65303.3,
            price_sources: [],
            processed_ms: 1711221731261,
          },
        ],
        position_type: "FLAT",
        position_uuid: "4a0e2673-e7f9-4097-9cd3-511dbb921294",
        return_at_close: 0.9966119513445315,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 65038.86799999999,
        close_ms: 1711224145696,
        current_return: 0.9975857793745188,
        initial_entry_price: 65154.94,
        is_closed_position: true,
        miner_hotkey: "5GMxwkXtcALBn5gktSnmZQQf2QgdB6mW2jPx1a5UQM9EZD8E",
        net_leverage: 0.0,
        open_ms: 1711222132240,
        orders: [
          {
            leverage: 0.21,
            order_type: "LONG",
            order_uuid: "3ce86676-0431-4b40-a99f-a0c359fa0283",
            price: 65154.94,
            price_sources: [],
            processed_ms: 1711222132238,
          },
          {
            leverage: 0.21,
            order_type: "LONG",
            order_uuid: "7ebbb6aa-c2c5-4dc7-9619-65518cdbb66b",
            price: 65085.13,
            price_sources: [],
            processed_ms: 1711222536246,
          },
          {
            leverage: 0.21,
            order_type: "LONG",
            order_uuid: "8106dcaa-2f58-430d-9888-f973b6cf4752",
            price: 65015.85,
            price_sources: [],
            processed_ms: 1711222941346,
          },
          {
            leverage: 0.21,
            order_type: "LONG",
            order_uuid: "f4760e69-eabf-4611-98e2-d88f937ed8e2",
            price: 64929.71,
            price_sources: [],
            processed_ms: 1711223341243,
          },
          {
            leverage: 0.21,
            order_type: "LONG",
            order_uuid: "6c7dea3e-42b1-4689-82b1-d43e2fdc8413",
            price: 65008.71,
            price_sources: [],
            processed_ms: 1711223746659,
          },
          {
            leverage: 0.21,
            order_type: "FLAT",
            order_uuid: "ae114ff5-d615-4a6d-8d61-74925f298355",
            price: 64889.06,
            price_sources: [],
            processed_ms: 1711224145696,
          },
        ],
        position_type: "FLAT",
        position_uuid: "45335c8a-43e2-454d-b374-25401d19d9de",
        return_at_close: 0.9954908492378323,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 64919.284,
        close_ms: 1711226530549,
        current_return: 1.000092567829556,
        initial_entry_price: 64836.78,
        is_closed_position: true,
        miner_hotkey: "5GMxwkXtcALBn5gktSnmZQQf2QgdB6mW2jPx1a5UQM9EZD8E",
        net_leverage: 0.0,
        open_ms: 1711224544375,
        orders: [
          {
            leverage: 0.21,
            order_type: "LONG",
            order_uuid: "771b9918-70cd-4edb-9d54-774a1f9d237a",
            price: 64836.78,
            price_sources: [],
            processed_ms: 1711224544373,
          },
          {
            leverage: 0.21,
            order_type: "LONG",
            order_uuid: "2237861d-9753-4593-843f-b5df3c15bc4d",
            price: 64921.42,
            price_sources: [],
            processed_ms: 1711224951322,
          },
          {
            leverage: 0.21,
            order_type: "LONG",
            order_uuid: "62c35fa5-e8b1-4e5c-a018-5093011742ab",
            price: 64932.92,
            price_sources: [],
            processed_ms: 1711225350100,
          },
          {
            leverage: 0.21,
            order_type: "LONG",
            order_uuid: "c65fd211-abc1-4cff-bc7a-b4046c9082e8",
            price: 64922.59,
            price_sources: [],
            processed_ms: 1711225753950,
          },
          {
            leverage: 0.21,
            order_type: "LONG",
            order_uuid: "7d04ef02-8240-485a-b927-d694e76e24ae",
            price: 64982.71,
            price_sources: [],
            processed_ms: 1711226130696,
          },
          {
            leverage: 0.21,
            order_type: "FLAT",
            order_uuid: "42f0198b-0bfc-47a8-9b0b-455933f2b755",
            price: 64925.0,
            price_sources: [],
            processed_ms: 1711226530549,
          },
        ],
        position_type: "FLAT",
        position_uuid: "c73d0c9f-4813-417d-8b93-ee97dcb1697d",
        return_at_close: 0.997992373437114,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 64952.112,
        close_ms: 1711228943359,
        current_return: 0.9995227217631617,
        initial_entry_price: 64969.65,
        is_closed_position: true,
        miner_hotkey: "5GMxwkXtcALBn5gktSnmZQQf2QgdB6mW2jPx1a5UQM9EZD8E",
        net_leverage: 0.0,
        open_ms: 1711226930382,
        orders: [
          {
            leverage: 0.21,
            order_type: "LONG",
            order_uuid: "ba85cbf2-27e7-4923-bf5d-dd551173853a",
            price: 64969.65,
            price_sources: [],
            processed_ms: 1711226930379,
          },
          {
            leverage: 0.21,
            order_type: "LONG",
            order_uuid: "099215c4-3ff1-4ac9-9c33-9657245c5be4",
            price: 64992.31,
            price_sources: [],
            processed_ms: 1711227335221,
          },
          {
            leverage: 0.21,
            order_type: "LONG",
            order_uuid: "34757e1a-995d-44d6-a526-3e545dbe21b5",
            price: 64974.45,
            price_sources: [],
            processed_ms: 1711227734633,
          },
          {
            leverage: 0.21,
            order_type: "LONG",
            order_uuid: "19e3f17f-c105-49d2-b8ec-e68035344ed4",
            price: 64909.31,
            price_sources: [],
            processed_ms: 1711228136170,
          },
          {
            leverage: 0.21,
            order_type: "LONG",
            order_uuid: "d872b3bf-47ed-401a-b68e-5c2d296c5620",
            price: 64914.84,
            price_sources: [],
            processed_ms: 1711228544926,
          },
          {
            leverage: 0.21,
            order_type: "FLAT",
            order_uuid: "7c712676-de9d-4e13-8319-fbb9776d1c27",
            price: 64922.58,
            price_sources: [],
            processed_ms: 1711228943359,
          },
        ],
        position_type: "FLAT",
        position_uuid: "f9e32d3f-33e1-4eec-952e-a1dc6d33dfa5",
        return_at_close: 0.997423724047459,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 64913.238,
        close_ms: 1711231357288,
        current_return: 1.0012781245772435,
        initial_entry_price: 64901.42,
        is_closed_position: true,
        miner_hotkey: "5GMxwkXtcALBn5gktSnmZQQf2QgdB6mW2jPx1a5UQM9EZD8E",
        net_leverage: 0.0,
        open_ms: 1711229347300,
        orders: [
          {
            leverage: 0.21,
            order_type: "LONG",
            order_uuid: "76b17a36-1967-4a85-b3e4-011ed3fb46ea",
            price: 64901.42,
            price_sources: [],
            processed_ms: 1711229347298,
          },
          {
            leverage: 0.21,
            order_type: "LONG",
            order_uuid: "4fdcb6e3-2f19-44f4-93f0-61633dba8095",
            price: 64980.47,
            price_sources: [],
            processed_ms: 1711229748169,
          },
          {
            leverage: 0.21,
            order_type: "LONG",
            order_uuid: "1316f0f8-3b70-4999-acfd-8de785775452",
            price: 64778.38,
            price_sources: [],
            processed_ms: 1711230151251,
          },
          {
            leverage: 0.21,
            order_type: "LONG",
            order_uuid: "748524b8-c6ef-4912-8823-89414641cc48",
            price: 64948.44,
            price_sources: [],
            processed_ms: 1711230557373,
          },
          {
            leverage: 0.21,
            order_type: "LONG",
            order_uuid: "274a510e-7749-43c9-a4b8-51e226af1688",
            price: 64957.48,
            price_sources: [],
            processed_ms: 1711230957320,
          },
          {
            leverage: 0.21,
            order_type: "FLAT",
            order_uuid: "85ce0576-43ec-443e-91ae-eec726b5b9d5",
            price: 64992.24,
            price_sources: [],
            processed_ms: 1711231357288,
          },
        ],
        position_type: "FLAT",
        position_uuid: "2e030e06-45f2-4756-82b0-7b11f82219fc",
        return_at_close: 0.9991754405156313,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 64938.011999999995,
        close_ms: 1711233746424,
        current_return: 0.9998703308778648,
        initial_entry_price: 64958.41,
        is_closed_position: true,
        miner_hotkey: "5GMxwkXtcALBn5gktSnmZQQf2QgdB6mW2jPx1a5UQM9EZD8E",
        net_leverage: 0.0,
        open_ms: 1711231736225,
        orders: [
          {
            leverage: 0.21,
            order_type: "LONG",
            order_uuid: "9994bad7-0783-47ed-bcfb-ae23ae10ed56",
            price: 64958.41,
            price_sources: [],
            processed_ms: 1711231736222,
          },
          {
            leverage: 0.21,
            order_type: "LONG",
            order_uuid: "ec7ede1e-80fd-4678-be71-cceda21d4e20",
            price: 64924.25,
            price_sources: [],
            processed_ms: 1711232139318,
          },
          {
            leverage: 0.21,
            order_type: "LONG",
            order_uuid: "fdc0173a-900d-426e-8650-e0d2a062a27b",
            price: 64851.79,
            price_sources: [],
            processed_ms: 1711232542291,
          },
          {
            leverage: 0.21,
            order_type: "LONG",
            order_uuid: "2855c1ed-d7ca-4554-bd68-3c848b0d2bee",
            price: 64940.77,
            price_sources: [],
            processed_ms: 1711232943185,
          },
          {
            leverage: 0.21,
            order_type: "LONG",
            order_uuid: "623d6346-61ec-4bf6-81ef-86db4f6d3679",
            price: 65014.84,
            price_sources: [],
            processed_ms: 1711233343059,
          },
          {
            leverage: 0.21,
            order_type: "FLAT",
            order_uuid: "490e6ebd-0f42-4ff6-8757-cc501d9d3a67",
            price: 64929.99,
            price_sources: [],
            processed_ms: 1711233746424,
          },
        ],
        position_type: "FLAT",
        position_uuid: "e56775e7-75e7-4ac3-8816-0c83803f75b2",
        return_at_close: 0.9977706031830214,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 64769.82999999999,
        close_ms: 1711236139165,
        current_return: 0.9994204210092126,
        initial_entry_price: 64875.54,
        is_closed_position: true,
        miner_hotkey: "5GMxwkXtcALBn5gktSnmZQQf2QgdB6mW2jPx1a5UQM9EZD8E",
        net_leverage: 0.0,
        open_ms: 1711234143295,
        orders: [
          {
            leverage: 0.21,
            order_type: "LONG",
            order_uuid: "b7339b27-c9f9-4109-a830-706cbcd88ab1",
            price: 64875.54,
            price_sources: [],
            processed_ms: 1711234143293,
          },
          {
            leverage: 0.21,
            order_type: "LONG",
            order_uuid: "afe126e4-da31-4204-a626-3e733fe72c9d",
            price: 64826.49,
            price_sources: [],
            processed_ms: 1711234548202,
          },
          {
            leverage: 0.21,
            order_type: "LONG",
            order_uuid: "f585c4fe-8a14-45b4-adc9-547fd0500501",
            price: 64739.0,
            price_sources: [],
            processed_ms: 1711234950175,
          },
          {
            leverage: 0.21,
            order_type: "LONG",
            order_uuid: "fb1b7698-5925-428d-ae8d-39e195c55e04",
            price: 64777.61,
            price_sources: [],
            processed_ms: 1711235358169,
          },
          {
            leverage: 0.21,
            order_type: "LONG",
            order_uuid: "bb59225e-2df3-47d8-b55e-d02866af6cee",
            price: 64630.51,
            price_sources: [],
            processed_ms: 1711235759144,
          },
          {
            leverage: 0.21,
            order_type: "FLAT",
            order_uuid: "1f75983d-5696-4757-a13c-fa17b3564982",
            price: 64734.02,
            price_sources: [],
            processed_ms: 1711236139165,
          },
        ],
        position_type: "FLAT",
        position_uuid: "174d5c7c-3c0e-4723-a87a-da0a438b80eb",
        return_at_close: 0.9973216381250932,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 64389.36599999999,
        close_ms: 1711238555367,
        current_return: 0.996122232870624,
        initial_entry_price: 64527.16,
        is_closed_position: true,
        miner_hotkey: "5GMxwkXtcALBn5gktSnmZQQf2QgdB6mW2jPx1a5UQM9EZD8E",
        net_leverage: 0.0,
        open_ms: 1711236536184,
        orders: [
          {
            leverage: 0.21,
            order_type: "LONG",
            order_uuid: "c024e671-eb9c-451e-9cd2-0e2242a4d0e5",
            price: 64527.16,
            price_sources: [],
            processed_ms: 1711236536181,
          },
          {
            leverage: 0.21,
            order_type: "LONG",
            order_uuid: "78fb601f-1ef4-445a-ab79-3136aadccea6",
            price: 64464.74,
            price_sources: [],
            processed_ms: 1711236938382,
          },
          {
            leverage: 0.21,
            order_type: "LONG",
            order_uuid: "a814fd19-7d71-461f-808b-c48ce78dd65f",
            price: 64371.75,
            price_sources: [],
            processed_ms: 1711237338315,
          },
          {
            leverage: 0.21,
            order_type: "LONG",
            order_uuid: "5ead2b97-026f-4682-bebf-d9a2f3bb8ec6",
            price: 64337.69,
            price_sources: [],
            processed_ms: 1711237737222,
          },
          {
            leverage: 0.21,
            order_type: "LONG",
            order_uuid: "2ea8252a-1ae4-4816-ba0c-e185367c862c",
            price: 64245.49,
            price_sources: [],
            processed_ms: 1711238146195,
          },
          {
            leverage: 0.21,
            order_type: "FLAT",
            order_uuid: "9d143770-cc02-4e42-b66e-893f8a8844e4",
            price: 64151.06,
            price_sources: [],
            processed_ms: 1711238555367,
          },
        ],
        position_type: "FLAT",
        position_uuid: "730884ed-35a4-4981-95e1-1ccaf4a5428b",
        return_at_close: 0.9940303761815956,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
    ],
    thirty_day_returns: 1.0,
  },
  "5GNAi7DBGfLftEsnYpY7MzSSLsMU79nD9ASC8VgLSsnHWko5": {
    all_time_returns: 1.0,
    n_positions: 0,
    percentage_profitable: 0.0,
    positions: [
      {
        average_entry_price: 3377.51,
        close_ms: 0,
        current_return: 1.0071224067434292,
        initial_entry_price: 3377.51,
        is_closed_position: false,
        miner_hotkey: "5GNAi7DBGfLftEsnYpY7MzSSLsMU79nD9ASC8VgLSsnHWko5",
        net_leverage: -0.1,
        open_ms: 1719611296592,
        orders: [
          {
            leverage: -0.1,
            order_type: "SHORT",
            order_uuid: "c514b4da-4f05-404e-828f-dfaef5740772",
            price: 3377.51,
            price_sources: [],
            processed_ms: 1719611296107,
          },
        ],
        position_type: "SHORT",
        position_uuid: "493704bd-eeea-4dd5-892f-e0f7127d2d4d",
        return_at_close: 1.0070720506230921,
        trade_pair: ["ETHUSD", "ETH/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 171.358,
        close_ms: 0,
        current_return: 0.9946493598197925,
        initial_entry_price: 171.358,
        is_closed_position: false,
        miner_hotkey: "5GNAi7DBGfLftEsnYpY7MzSSLsMU79nD9ASC8VgLSsnHWko5",
        net_leverage: -0.25,
        open_ms: 1719399882025,
        orders: [
          {
            leverage: -0.25,
            order_type: "SHORT",
            order_uuid: "e55734bc-0b92-4969-921b-201448bbcee5",
            price: 171.358,
            price_sources: [],
            processed_ms: 1719399882017,
          },
        ],
        position_type: "SHORT",
        position_uuid: "021b29d9-0a45-4934-ae90-b5bb982fd487",
        return_at_close: 0.9946406566378941,
        trade_pair: ["EURJPY", "EUR/JPY", 7e-5, 0.001, 500],
      },
    ],
    thirty_day_returns: 1.0,
  },
  "5GRFAJ3iwukm1CQpDTBsxTh2xjm227KtbVC1za8ukk6WqeyN": {
    all_time_returns: 0.9950209652780793,
    n_positions: 11,
    percentage_profitable: 0.9090909090909091,
    positions: [
      {
        average_entry_price: 101.59500000000001,
        close_ms: 1714051241319,
        current_return: 1.000678182981446,
        initial_entry_price: 101.595,
        is_closed_position: true,
        miner_hotkey: "5GRFAJ3iwukm1CQpDTBsxTh2xjm227KtbVC1za8ukk6WqeyN",
        net_leverage: 0.0,
        open_ms: 1714046063364,
        orders: [
          {
            leverage: -0.1,
            order_type: "SHORT",
            order_uuid: "e040a80a-c73d-479e-9186-d7680362e152",
            price: 101.595,
            price_sources: [],
            processed_ms: 1714046063364,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "412634f2-5f15-4641-9809-1444fb4e6e1a",
            price: 100.906,
            price_sources: [],
            processed_ms: 1714051241319,
          },
        ],
        position_type: "FLAT",
        position_uuid: "2d4d16dc-8aed-4635-bf78-ec3e5aaff6ba",
        return_at_close: 1.000671178234165,
        trade_pair: ["AUDJPY", "AUD/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 156.073,
        close_ms: 1714366576419,
        current_return: 1.000347234947749,
        initial_entry_price: 156.073,
        is_closed_position: true,
        miner_hotkey: "5GRFAJ3iwukm1CQpDTBsxTh2xjm227KtbVC1za8ukk6WqeyN",
        net_leverage: 0.0,
        open_ms: 1714103212927,
        orders: [
          {
            leverage: -0.098,
            order_type: "SHORT",
            order_uuid: "8e973be2-44e3-4228-ad97-9613c9223ffa",
            price: 156.073,
            price_sources: [],
            processed_ms: 1714103212926,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "cdae90e5-0663-44d5-9fc6-f8b5471d9993",
            price: 155.52,
            price_sources: [],
            processed_ms: 1714366576419,
          },
        ],
        position_type: "FLAT",
        position_uuid: "df215fa2-f682-46f5-8db4-ce1cfba2be53",
        return_at_close: 1.0003403725657172,
        trade_pair: ["USDJPY", "USD/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.89285,
        close_ms: 1714366682084,
        current_return: 1.0004950439603517,
        initial_entry_price: 0.89285,
        is_closed_position: true,
        miner_hotkey: "5GRFAJ3iwukm1CQpDTBsxTh2xjm227KtbVC1za8ukk6WqeyN",
        net_leverage: 0.0,
        open_ms: 1714046037600,
        orders: [
          {
            leverage: 0.1,
            order_type: "LONG",
            order_uuid: "b91b142f-e280-4832-83a4-ec8b81f29ecd",
            price: 0.89285,
            price_sources: [],
            processed_ms: 1714046037599,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "0482ba69-0852-4f3c-8adf-a774f1141c83",
            price: 0.89727,
            price_sources: [],
            processed_ms: 1714366682084,
          },
        ],
        position_type: "FLAT",
        position_uuid: "2b8cef1a-3625-4031-abfa-88cba384760f",
        return_at_close: 1.000488040495044,
        trade_pair: ["AUDCAD", "AUD/CAD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 1.37226,
        close_ms: 1714670273430,
        current_return: 1.0002696282045676,
        initial_entry_price: 1.37226,
        is_closed_position: true,
        miner_hotkey: "5GRFAJ3iwukm1CQpDTBsxTh2xjm227KtbVC1za8ukk6WqeyN",
        net_leverage: 0.0,
        open_ms: 1714649921002,
        orders: [
          {
            leverage: -0.1,
            order_type: "SHORT",
            order_uuid: "8cf1ff4d-f01e-4913-adb9-d51bddd54a3c",
            price: 1.37226,
            price_sources: [],
            processed_ms: 1714649921000,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "0537ecff-3bd7-4be5-beab-79f1ed15bb37",
            price: 1.36856,
            price_sources: [],
            processed_ms: 1714670273430,
          },
        ],
        position_type: "FLAT",
        position_uuid: "6c9919a6-8336-4380-9d6b-620938b93059",
        return_at_close: 1.0002626263171701,
        trade_pair: ["USDCAD", "USD/CAD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.8782300000000013,
        close_ms: 1715045607732,
        current_return: 1.0003131491367179,
        initial_entry_price: 0.89542,
        is_closed_position: true,
        miner_hotkey: "5GRFAJ3iwukm1CQpDTBsxTh2xjm227KtbVC1za8ukk6WqeyN",
        net_leverage: 0.0,
        open_ms: 1714649887575,
        orders: [
          {
            leverage: 0.1,
            order_type: "LONG",
            order_uuid: "22071839-7a16-4973-98af-39e5f53ad7f3",
            price: 0.89542,
            price_sources: [],
            processed_ms: 1714649887395,
          },
          {
            leverage: -0.09,
            order_type: "SHORT",
            order_uuid: "3a30654f-5508-425f-827c-d4ac45b3c895",
            price: 0.89733,
            price_sources: [],
            processed_ms: 1714670289645,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "7139dabb-a261-46f1-9646-5fc51329dd16",
            price: 0.90627,
            price_sources: [],
            processed_ms: 1715045607732,
          },
        ],
        position_type: "FLAT",
        position_uuid: "f83dd1e1-d1ef-4453-a456-5015b1313d0b",
        return_at_close: 1.000306146944674,
        trade_pair: ["AUDCAD", "AUD/CAD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 112.803,
        close_ms: 1715240407697,
        current_return: 1.0005895233282802,
        initial_entry_price: 112.803,
        is_closed_position: true,
        miner_hotkey: "5GRFAJ3iwukm1CQpDTBsxTh2xjm227KtbVC1za8ukk6WqeyN",
        net_leverage: 0.0,
        open_ms: 1714649946805,
        orders: [
          {
            leverage: 0.1,
            order_type: "LONG",
            order_uuid: "afbd0892-2ffa-4863-a533-dc968a6819ad",
            price: 112.803,
            price_sources: [],
            processed_ms: 1714649946630,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "f9f7cefb-1e9c-4f8f-be78-c6b0f278fa9e",
            price: 113.468,
            price_sources: [],
            processed_ms: 1715240407697,
          },
        ],
        position_type: "FLAT",
        position_uuid: "ddc42c10-e9ed-48d0-8afd-1c707c07cab3",
        return_at_close: 1.000582519201617,
        trade_pair: ["CADJPY", "CAD/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 155.655,
        close_ms: 1715275878102,
        current_return: 1.0000783784651954,
        initial_entry_price: 155.655,
        is_closed_position: true,
        miner_hotkey: "5GRFAJ3iwukm1CQpDTBsxTh2xjm227KtbVC1za8ukk6WqeyN",
        net_leverage: 0.0,
        open_ms: 1715272953748,
        orders: [
          {
            leverage: -0.1,
            order_type: "SHORT",
            order_uuid: "4aebc37f-54ce-4522-9c5c-97d16dd4a12f",
            price: 155.655,
            price_sources: [],
            processed_ms: 1715272953530,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "bcf97eb1-cf99-4a41-8c2b-30de037adbf6",
            price: 155.533,
            price_sources: [],
            processed_ms: 1715275878102,
          },
        ],
        position_type: "FLAT",
        position_uuid: "fa93ba68-144b-42c6-a7bb-2bbc1059d63a",
        return_at_close: 1.0000713779165462,
        trade_pair: ["USDJPY", "USD/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.6610816780045352,
        close_ms: 1715306505128,
        current_return: 1.0257953779194582,
        initial_entry_price: 0.65252,
        is_closed_position: true,
        miner_hotkey: "5GRFAJ3iwukm1CQpDTBsxTh2xjm227KtbVC1za8ukk6WqeyN",
        net_leverage: 0.0,
        open_ms: 1714649904420,
        orders: [
          {
            leverage: -0.1,
            order_type: "SHORT",
            order_uuid: "1d2fe6a0-eba9-4d0f-a264-2333ba1b0e27",
            price: 0.65252,
            price_sources: [],
            processed_ms: 1714649904234,
          },
          {
            leverage: -1.0,
            order_type: "SHORT",
            order_uuid: "9a8c8111-3af0-450d-afba-d1275983e9b7",
            price: 0.661,
            price_sources: [],
            processed_ms: 1715274959378,
          },
          {
            leverage: -3.0,
            order_type: "SHORT",
            order_uuid: "2424a9ae-ad3c-471f-b916-018df3880323",
            price: 0.66115,
            price_sources: [],
            processed_ms: 1715275135327,
          },
          {
            leverage: -40.0,
            order_type: "SHORT",
            order_uuid: "f02d4273-de94-43e7-8251-bd224f98b2e1",
            price: 0.6611,
            price_sources: [],
            processed_ms: 1715302685424,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "1019987a-8761-4c48-bb68-668f39c7e5df",
            price: 0.6607,
            price_sources: [],
            processed_ms: 1715306505128,
          },
        ],
        position_type: "FLAT",
        position_uuid: "a8ff4459-9fc9-4751-bdd1-29887ff02154",
        return_at_close: 1.0226287475878209,
        trade_pair: ["AUDUSD", "AUD/USD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 1.077732727272727,
        close_ms: 1715306525982,
        current_return: 1.0001253063042992,
        initial_entry_price: 1.07736,
        is_closed_position: true,
        miner_hotkey: "5GRFAJ3iwukm1CQpDTBsxTh2xjm227KtbVC1za8ukk6WqeyN",
        net_leverage: 0.0,
        open_ms: 1715272936138,
        orders: [
          {
            leverage: -0.1,
            order_type: "SHORT",
            order_uuid: "807ea6b4-2764-489b-b8cf-40d3f6028291",
            price: 1.07736,
            price_sources: [],
            processed_ms: 1715272935958,
          },
          {
            leverage: -1.0,
            order_type: "SHORT",
            order_uuid: "4d5f5d40-6e1a-4ef7-960f-5b703d24acf3",
            price: 1.07777,
            price_sources: [],
            processed_ms: 1715306300960,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "73f6e73f-ca84-4213-aa6b-853823c84ca2",
            price: 1.07761,
            price_sources: [],
            processed_ms: 1715306525982,
          },
        ],
        position_type: "FLAT",
        position_uuid: "340898ce-30c4-41d4-8a91-67176f71d6a7",
        return_at_close: 1.0000482966557138,
        trade_pair: ["EURUSD", "EUR/USD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 167.705,
        close_ms: 1715306541760,
        current_return: 1.0000465102411973,
        initial_entry_price: 167.705,
        is_closed_position: true,
        miner_hotkey: "5GRFAJ3iwukm1CQpDTBsxTh2xjm227KtbVC1za8ukk6WqeyN",
        net_leverage: 0.0,
        open_ms: 1715272878184,
        orders: [
          {
            leverage: 0.1,
            order_type: "LONG",
            order_uuid: "bda4556f-c914-426c-b0f8-2caa33a42388",
            price: 167.705,
            price_sources: [],
            processed_ms: 1715272877473,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "118d305f-d9a7-415a-aaa1-be5799492f41",
            price: 167.783,
            price_sources: [],
            processed_ms: 1715306541760,
          },
        ],
        position_type: "FLAT",
        position_uuid: "ebcabf3e-bdaf-486f-8c16-3a4902423b05",
        return_at_close: 1.0000395099156256,
        trade_pair: ["EURJPY", "EUR/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 5607.939999999999,
        close_ms: 1719319193722,
        current_return: 0.9705160230681482,
        initial_entry_price: 3787.04,
        is_closed_position: true,
        miner_hotkey: "5GRFAJ3iwukm1CQpDTBsxTh2xjm227KtbVC1za8ukk6WqeyN",
        net_leverage: 0.0,
        open_ms: 1716301325144,
        orders: [
          {
            leverage: 0.15,
            order_type: "LONG",
            order_uuid: "918841ea-9548-44a3-838b-9355a288d5a7",
            price: 3787.04,
            price_sources: [],
            processed_ms: 1716301323161,
          },
          {
            leverage: 0.1,
            order_type: "LONG",
            order_uuid: "6824a139-7ac9-4f39-a94c-e71aa9a9fb0e",
            price: 3868.8,
            price_sources: [],
            processed_ms: 1717619931497,
          },
          {
            leverage: -0.1,
            order_type: "SHORT",
            order_uuid: "4459fd27-f88e-4430-a08a-ef4028b65869",
            price: 3373.69,
            price_sources: [],
            processed_ms: 1719318421130,
          },
          {
            leverage: -0.1,
            order_type: "SHORT",
            order_uuid: "064ead59-7fdc-4d11-bb88-f12ff7486e0e",
            price: 3371.7,
            price_sources: [],
            processed_ms: 1719318673881,
          },
          {
            leverage: -0.1,
            order_type: "SHORT",
            order_uuid: "a4fcea7d-c5c3-4fd2-9600-951ee8036eb8",
            price: 3374.8,
            price_sources: [],
            processed_ms: 1719319193722,
          },
        ],
        position_type: "FLAT",
        position_uuid: "fd64a7d7-a591-4f76-a568-20574d625516",
        return_at_close: 0.9702733940623812,
        trade_pair: ["ETHUSD", "ETH/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 159.678,
        close_ms: 0,
        current_return: 0.8099612971104351,
        initial_entry_price: 159.678,
        is_closed_position: false,
        miner_hotkey: "5GRFAJ3iwukm1CQpDTBsxTh2xjm227KtbVC1za8ukk6WqeyN",
        net_leverage: -15.0,
        open_ms: 1719337463842,
        orders: [
          {
            leverage: -15.0,
            order_type: "SHORT",
            order_uuid: "802df71e-d954-4552-8805-e366dffd5b2c",
            price: 159.678,
            price_sources: [],
            processed_ms: 1719337463424,
          },
        ],
        position_type: "SHORT",
        position_uuid: "67372ba6-e8d1-4339-9be9-526cf8decfd1",
        return_at_close: 0.809536067429452,
        trade_pair: ["USDJPY", "USD/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 63787.57,
        close_ms: 0,
        current_return: 0.9999114158134571,
        initial_entry_price: 63787.57,
        is_closed_position: false,
        miner_hotkey: "5GRFAJ3iwukm1CQpDTBsxTh2xjm227KtbVC1za8ukk6WqeyN",
        net_leverage: 0.001,
        open_ms: 1714834975811,
        orders: [
          {
            leverage: 0.001,
            order_type: "LONG",
            order_uuid: "fdfef47e-bbf5-44db-a1da-f229dd963e3d",
            price: 63787.57,
            price_sources: [],
            processed_ms: 1714834975603,
          },
        ],
        position_type: "LONG",
        position_uuid: "f7c930d2-70fd-4820-a249-4f8ee46282f1",
        return_at_close: 0.9999109158577493,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 0.5485300000002632,
        close_ms: 0,
        current_return: 1.0005873789121458,
        initial_entry_price: 1.25219,
        is_closed_position: false,
        miner_hotkey: "5GRFAJ3iwukm1CQpDTBsxTh2xjm227KtbVC1za8ukk6WqeyN",
        net_leverage: 0.001000000000000112,
        open_ms: 1715275020649,
        orders: [
          {
            leverage: 0.1,
            order_type: "LONG",
            order_uuid: "4a1d6044-d77f-47c3-8e2b-3b2b8a6098c1",
            price: 1.25219,
            price_sources: [],
            processed_ms: 1715275020279,
          },
          {
            leverage: 1.0,
            order_type: "LONG",
            order_uuid: "b84aa281-c519-4e74-813f-dcf784976dc7",
            price: 1.25186,
            price_sources: [],
            processed_ms: 1715306323116,
          },
          {
            leverage: -1.099,
            order_type: "SHORT",
            order_uuid: "f0bad73d-4595-40ac-8bc7-89f4a1340c64",
            price: 1.25253,
            price_sources: [],
            processed_ms: 1715320921997,
          },
        ],
        position_type: "LONG",
        position_uuid: "0280c7f5-4892-4c97-abb7-e062430181fe",
        return_at_close: 1.0005103687045278,
        trade_pair: ["GBPUSD", "GBP/USD", 7e-5, 0.001, 500],
      },
    ],
    thirty_day_returns: 1.0,
  },
  "5GTBRqGS3Zi6QZaYw85o67f1VhEhKvb8ndpnyFcWuNpWeC1f": {
    all_time_returns: 1.0,
    n_positions: 0,
    percentage_profitable: 0.0,
    positions: [
      {
        average_entry_price: 5234.27002,
        close_ms: 1711374545729,
        current_return: 1.2185584609943414,
        initial_entry_price: 5234.27002,
        is_closed_position: true,
        miner_hotkey: "5GTBRqGS3Zi6QZaYw85o67f1VhEhKvb8ndpnyFcWuNpWeC1f",
        net_leverage: 0.0,
        open_ms: 1711373142303,
        orders: [
          {
            leverage: -100.0,
            order_type: "SHORT",
            order_uuid: "1bfadad6-604a-4015-a19d-da8ac93fd39d",
            price: 5234.27002,
            price_sources: [],
            processed_ms: 1711373142302,
          },
          {
            leverage: 100.0,
            order_type: "FLAT",
            order_uuid: "78d2243a-fd3b-4197-9483-da16ebde62de",
            price: 5222.83008,
            price_sources: [],
            processed_ms: 1711374545729,
          },
        ],
        position_type: "FLAT",
        position_uuid: "a0509ecb-cf45-4da5-a333-f00ad2070ed0",
        return_at_close: 1.2075914348453922,
        trade_pair: ["SPX", "SPX", 9e-5, 0.001, 500],
      },
      {
        average_entry_price: 63424.049999999996,
        close_ms: 1711384656721,
        current_return: 1.0062635268794242,
        initial_entry_price: 63498.57,
        is_closed_position: true,
        miner_hotkey: "5GTBRqGS3Zi6QZaYw85o67f1VhEhKvb8ndpnyFcWuNpWeC1f",
        net_leverage: 0.0,
        open_ms: 1711138583002,
        orders: [
          {
            leverage: 0.05,
            order_type: "LONG",
            order_uuid: "97a089f2-b143-408d-8a0a-ee3bf6c9a3eb",
            price: 63498.57,
            price_sources: [],
            processed_ms: 1711138583002,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "938cd8d3-2026-43ea-9a72-602d9b56d07e",
            price: 63051.45,
            price_sources: [],
            processed_ms: 1711142366833,
          },
          {
            leverage: 0.06,
            order_type: "FLAT",
            order_uuid: "0fd4e689-8490-4785-9653-24aaa67a7c25",
            price: 70052.8,
            price_sources: [],
            processed_ms: 1711384656721,
          },
        ],
        position_type: "FLAT",
        position_uuid: "1213bad7-66cd-42a4-923b-d92acbd0fa0f",
        return_at_close: 1.0061427752561987,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 3295.18,
        close_ms: 1711422869677,
        current_return: 1.0009646817472793,
        initial_entry_price: 3295.18,
        is_closed_position: true,
        miner_hotkey: "5GTBRqGS3Zi6QZaYw85o67f1VhEhKvb8ndpnyFcWuNpWeC1f",
        net_leverage: 0.0,
        open_ms: 1711142839167,
        orders: [
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "58fd6a60-7e8f-4b05-9179-5b156009bf10",
            price: 3295.18,
            price_sources: [],
            processed_ms: 1711142839166,
          },
          {
            leverage: 0.01,
            order_type: "FLAT",
            order_uuid: "7efdd7a8-28ab-4aaf-8cc5-42d4f854b25a",
            price: 3613.06,
            price_sources: [],
            processed_ms: 1711422869677,
          },
        ],
        position_type: "FLAT",
        position_uuid: "aa7178a5-1397-442d-9235-8cf73d033805",
        return_at_close: 1.0009446624536442,
        trade_pair: ["ETHUSD", "ETH/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 5219.06006,
        close_ms: 1711460859401,
        current_return: 1.2500411922831902,
        initial_entry_price: 5219.06006,
        is_closed_position: true,
        miner_hotkey: "5GTBRqGS3Zi6QZaYw85o67f1VhEhKvb8ndpnyFcWuNpWeC1f",
        net_leverage: 0.0,
        open_ms: 1711459721024,
        orders: [
          {
            leverage: 100.0,
            order_type: "LONG",
            order_uuid: "62a35b5d-0774-4430-b3db-9042e97240e5",
            price: 5219.06006,
            price_sources: [],
            processed_ms: 1711459721023,
          },
          {
            leverage: 100.0,
            order_type: "FLAT",
            order_uuid: "593432aa-328f-457e-960d-853b7350fbbe",
            price: 5232.10986,
            price_sources: [],
            processed_ms: 1711460859401,
          },
        ],
        position_type: "FLAT",
        position_uuid: "be311b50-2c32-4e4b-8a2f-200b06da6804",
        return_at_close: 1.2387908215526415,
        trade_pair: ["SPX", "SPX", 9e-5, 0.001, 500],
      },
      {
        average_entry_price: 70509.495,
        close_ms: 1711461206409,
        current_return: 0.9983662151671406,
        initial_entry_price: 70422.37,
        is_closed_position: true,
        miner_hotkey: "5GTBRqGS3Zi6QZaYw85o67f1VhEhKvb8ndpnyFcWuNpWeC1f",
        net_leverage: 0.0,
        open_ms: 1711422539993,
        orders: [
          {
            leverage: -0.5,
            order_type: "SHORT",
            order_uuid: "603d9c6d-5623-4201-8cce-e6f0f1eab4ec",
            price: 70422.37,
            price_sources: [],
            processed_ms: 1711422539992,
          },
          {
            leverage: -0.5,
            order_type: "SHORT",
            order_uuid: "15c67834-7a12-479b-9402-1d2d0f2f9f3c",
            price: 70596.62,
            price_sources: [],
            processed_ms: 1711424637402,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "33cb0bd5-54b4-457c-94db-9d440a79b533",
            price: 70624.55,
            price_sources: [],
            processed_ms: 1711461206409,
          },
        ],
        position_type: "FLAT",
        position_uuid: "68a8e94f-9efe-46a4-ae95-62f259cede88",
        return_at_close: 0.9963694827368064,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 70266.8,
        close_ms: 1711472008356,
        current_return: 1.00182856626458,
        initial_entry_price: 70266.8,
        is_closed_position: true,
        miner_hotkey: "5GTBRqGS3Zi6QZaYw85o67f1VhEhKvb8ndpnyFcWuNpWeC1f",
        net_leverage: 0.0,
        open_ms: 1711468588319,
        orders: [
          {
            leverage: -0.25,
            order_type: "SHORT",
            order_uuid: "5f214dc7-3c44-410f-8261-cb1827fa99cf",
            price: 70266.8,
            price_sources: [],
            processed_ms: 1711468588318,
          },
          {
            leverage: 0.5,
            order_type: "FLAT",
            order_uuid: "329390a1-353c-4abf-8fec-707b22ee73d6",
            price: 69752.85,
            price_sources: [],
            processed_ms: 1711472008356,
          },
        ],
        position_type: "FLAT",
        position_uuid: "e97a1a63-69f5-4346-b133-acce9c5f766f",
        return_at_close: 1.0013276519814478,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 5204.18018,
        close_ms: 1711547317654,
        current_return: 1.1196341361109425,
        initial_entry_price: 5204.18018,
        is_closed_position: true,
        miner_hotkey: "5GTBRqGS3Zi6QZaYw85o67f1VhEhKvb8ndpnyFcWuNpWeC1f",
        net_leverage: 0.0,
        open_ms: 1711546262041,
        orders: [
          {
            leverage: 15.0,
            order_type: "LONG",
            order_uuid: "e4997990-523f-472f-ab1f-a8a2283d4776",
            price: 5204.18018,
            price_sources: [],
            processed_ms: 1711546262040,
          },
          {
            leverage: 5.0,
            order_type: "LONG",
            order_uuid: "ba7f52b8-c674-427a-9e20-c3e2155d4b81",
            price: 5204.18018,
            price_sources: [],
            processed_ms: 1711546406944,
          },
          {
            leverage: 20.0,
            order_type: "FLAT",
            order_uuid: "05f14436-7c93-476b-982f-14b33458c49f",
            price: 5235.31006,
            price_sources: [],
            processed_ms: 1711547317654,
          },
        ],
        position_type: "FLAT",
        position_uuid: "7350100b-7638-40df-9319-855f894ae571",
        return_at_close: 1.1176187946659428,
        trade_pair: ["SPX", "SPX", 9e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.65215,
        close_ms: 1711583404179,
        current_return: 1.0690025300927624,
        initial_entry_price: 0.65215,
        is_closed_position: true,
        miner_hotkey: "5GTBRqGS3Zi6QZaYw85o67f1VhEhKvb8ndpnyFcWuNpWeC1f",
        net_leverage: 0.0,
        open_ms: 1711583211079,
        orders: [
          {
            leverage: 300.0,
            order_type: "LONG",
            order_uuid: "80dbdb6a-951d-4c89-8f40-43ceab9844a7",
            price: 0.65215,
            price_sources: [],
            processed_ms: 1711583211078,
          },
          {
            leverage: 200.0,
            order_type: "FLAT",
            order_uuid: "22ffdd6f-19ef-45f7-b858-ca5659d9d69d",
            price: 0.6523,
            price_sources: [],
            processed_ms: 1711583404179,
          },
        ],
        position_type: "FLAT",
        position_uuid: "1cebc6bb-067b-441d-9e45-aa34e58ce0f0",
        return_at_close: 1.0465534769608142,
        trade_pair: ["AUDUSD", "AUD/USD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.65225,
        close_ms: 1711583559952,
        current_return: 1.022997316979683,
        initial_entry_price: 0.65225,
        is_closed_position: true,
        miner_hotkey: "5GTBRqGS3Zi6QZaYw85o67f1VhEhKvb8ndpnyFcWuNpWeC1f",
        net_leverage: 0.0,
        open_ms: 1711583494602,
        orders: [
          {
            leverage: 300.0,
            order_type: "LONG",
            order_uuid: "397f782c-750f-404f-9b4e-e2be73f5a65a",
            price: 0.65225,
            price_sources: [],
            processed_ms: 1711583494601,
          },
          {
            leverage: 200.0,
            order_type: "FLAT",
            order_uuid: "260c3158-ea53-4b1d-8080-61da1ba9af65",
            price: 0.6523,
            price_sources: [],
            processed_ms: 1711583559952,
          },
        ],
        position_type: "FLAT",
        position_uuid: "f5a68f3a-f4c5-47dd-a23a-aaa73029c379",
        return_at_close: 1.0015143733231098,
        trade_pair: ["AUDUSD", "AUD/USD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.6523,
        close_ms: 1711583980600,
        current_return: 1.0,
        initial_entry_price: 0.6523,
        is_closed_position: true,
        miner_hotkey: "5GTBRqGS3Zi6QZaYw85o67f1VhEhKvb8ndpnyFcWuNpWeC1f",
        net_leverage: 0.0,
        open_ms: 1711583791367,
        orders: [
          {
            leverage: 300.0,
            order_type: "LONG",
            order_uuid: "1159e3f4-ee66-4327-b08d-49b7da0bb094",
            price: 0.6523,
            price_sources: [],
            processed_ms: 1711583791366,
          },
          {
            leverage: 200.0,
            order_type: "FLAT",
            order_uuid: "e2249d18-bb88-427d-b082-e94b66f371af",
            price: 0.6523,
            price_sources: [],
            processed_ms: 1711583980600,
          },
        ],
        position_type: "FLAT",
        position_uuid: "7d9b301b-b813-49f0-a1b1-b123c8dda8ca",
        return_at_close: 0.979,
        trade_pair: ["AUDUSD", "AUD/USD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.65155,
        close_ms: 1711584950129,
        current_return: 1.0115110122016717,
        initial_entry_price: 0.65155,
        is_closed_position: true,
        miner_hotkey: "5GTBRqGS3Zi6QZaYw85o67f1VhEhKvb8ndpnyFcWuNpWeC1f",
        net_leverage: 0.0,
        open_ms: 1711584899371,
        orders: [
          {
            leverage: 50.0,
            order_type: "LONG",
            order_uuid: "d7d40c2f-63be-4f05-8282-083519fdce45",
            price: 0.65155,
            price_sources: [],
            processed_ms: 1711584899370,
          },
          {
            leverage: 50.0,
            order_type: "FLAT",
            order_uuid: "df3e48be-6fd2-44f5-9e5c-5de6d719d246",
            price: 0.6517,
            price_sources: [],
            processed_ms: 1711584950129,
          },
        ],
        position_type: "FLAT",
        position_uuid: "3f8c2d8b-9a84-4116-8117-40a1ded3d3c0",
        return_at_close: 1.007970723658966,
        trade_pair: ["AUDUSD", "AUD/USD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.6531,
        close_ms: 1711585924325,
        current_return: 1.0612463634971776,
        initial_entry_price: 0.6531,
        is_closed_position: true,
        miner_hotkey: "5GTBRqGS3Zi6QZaYw85o67f1VhEhKvb8ndpnyFcWuNpWeC1f",
        net_leverage: 0.0,
        open_ms: 1711585863661,
        orders: [
          {
            leverage: -100.0,
            order_type: "SHORT",
            order_uuid: "c423ce3f-48de-4119-8657-badc3836e385",
            price: 0.6531,
            price_sources: [],
            processed_ms: 1711585863660,
          },
          {
            leverage: 100.0,
            order_type: "FLAT",
            order_uuid: "f624cd7e-1e59-44f3-9936-0d671de283c5",
            price: 0.6527,
            price_sources: [],
            processed_ms: 1711585924325,
          },
        ],
        position_type: "FLAT",
        position_uuid: "a03cd3dd-7aaf-442e-bf6d-b6d4c0702cf6",
        return_at_close: 1.0538176389526974,
        trade_pair: ["AUDUSD", "AUD/USD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.6521000000000001,
        close_ms: 1711587470335,
        current_return: 1.0306701426161513,
        initial_entry_price: 0.6521,
        is_closed_position: true,
        miner_hotkey: "5GTBRqGS3Zi6QZaYw85o67f1VhEhKvb8ndpnyFcWuNpWeC1f",
        net_leverage: 0.0,
        open_ms: 1711587355143,
        orders: [
          {
            leverage: 50.0,
            order_type: "LONG",
            order_uuid: "7d07de25-8cec-4959-a98c-27a9d1a64f2f",
            price: 0.6521,
            price_sources: [],
            processed_ms: 1711587355141,
          },
          {
            leverage: 50.0,
            order_type: "FLAT",
            order_uuid: "676cecef-33f1-4713-a23c-e1fe67a6c906",
            price: 0.6525,
            price_sources: [],
            processed_ms: 1711587470335,
          },
        ],
        position_type: "FLAT",
        position_uuid: "34ff831a-5fdd-41ce-8557-4298bd0620cd",
        return_at_close: 1.0270627971169948,
        trade_pair: ["AUDUSD", "AUD/USD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.6524,
        close_ms: 1711588386701,
        current_return: 1.0,
        initial_entry_price: 0.6524,
        is_closed_position: true,
        miner_hotkey: "5GTBRqGS3Zi6QZaYw85o67f1VhEhKvb8ndpnyFcWuNpWeC1f",
        net_leverage: 0.0,
        open_ms: 1711588356488,
        orders: [
          {
            leverage: 50.0,
            order_type: "LONG",
            order_uuid: "85336f14-7ae9-4f1c-9390-e9562bc02664",
            price: 0.6524,
            price_sources: [],
            processed_ms: 1711588356487,
          },
          {
            leverage: 50.0,
            order_type: "FLAT",
            order_uuid: "d2f24d1a-7e2b-43d5-af23-3b99f31c65b9",
            price: 0.6524,
            price_sources: [],
            processed_ms: 1711588386701,
          },
        ],
        position_type: "FLAT",
        position_uuid: "9c4b92f6-7402-4f00-b943-4d0f6d36158d",
        return_at_close: 0.9965,
        trade_pair: ["AUDUSD", "AUD/USD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.65385,
        close_ms: 1711597050808,
        current_return: 1.0,
        initial_entry_price: 0.65385,
        is_closed_position: true,
        miner_hotkey: "5GTBRqGS3Zi6QZaYw85o67f1VhEhKvb8ndpnyFcWuNpWeC1f",
        net_leverage: 0.0,
        open_ms: 1711597002095,
        orders: [
          {
            leverage: 50.0,
            order_type: "LONG",
            order_uuid: "6a3479f0-89f2-493f-9065-7f1ca45c3a5b",
            price: 0.65385,
            price_sources: [],
            processed_ms: 1711597002093,
          },
          {
            leverage: 50.0,
            order_type: "FLAT",
            order_uuid: "076f02b3-75ed-45d6-9f9e-724879050f90",
            price: 0.65385,
            price_sources: [],
            processed_ms: 1711597050808,
          },
        ],
        position_type: "FLAT",
        position_uuid: "d29b80d5-bc0c-449c-a195-20a2a05f0dd1",
        return_at_close: 0.9965,
        trade_pair: ["AUDUSD", "AUD/USD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.6534,
        close_ms: 1711597699560,
        current_return: 1.0153045607591045,
        initial_entry_price: 0.6534,
        is_closed_position: true,
        miner_hotkey: "5GTBRqGS3Zi6QZaYw85o67f1VhEhKvb8ndpnyFcWuNpWeC1f",
        net_leverage: 0.0,
        open_ms: 1711597512682,
        orders: [
          {
            leverage: 100.0,
            order_type: "LONG",
            order_uuid: "8fdb584f-b320-4608-9a9d-5abcbddc9a1c",
            price: 0.6534,
            price_sources: [],
            processed_ms: 1711597512681,
          },
          {
            leverage: 100.0,
            order_type: "FLAT",
            order_uuid: "83854d85-d40d-4bc5-9167-efdc42ea503f",
            price: 0.6535,
            price_sources: [],
            processed_ms: 1711597699560,
          },
        ],
        position_type: "FLAT",
        position_uuid: "020a87c1-d245-4328-bacc-e9912236a9ec",
        return_at_close: 1.0081974288337907,
        trade_pair: ["AUDUSD", "AUD/USD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 39765.71094,
        close_ms: 1711632753780,
        current_return: 1.1279466625826742,
        initial_entry_price: 39765.71094,
        is_closed_position: true,
        miner_hotkey: "5GTBRqGS3Zi6QZaYw85o67f1VhEhKvb8ndpnyFcWuNpWeC1f",
        net_leverage: 0.0,
        open_ms: 1711632600990,
        orders: [
          {
            leverage: 100.0,
            order_type: "LONG",
            order_uuid: "813e7dca-61de-48ef-b742-e3d13b375813",
            price: 39765.71094,
            price_sources: [],
            processed_ms: 1711632600988,
          },
          {
            leverage: 100.0,
            order_type: "FLAT",
            order_uuid: "a79bc8c8-61bf-417f-8e4d-16cba8c9ea9a",
            price: 39816.58984,
            price_sources: [],
            processed_ms: 1711632753780,
          },
        ],
        position_type: "FLAT",
        position_uuid: "c0b2a973-3b4b-43fe-97bc-cc4e0de77402",
        return_at_close: 1.1177951426194301,
        trade_pair: ["DJI", "DJI", 9e-5, 0.001, 500],
      },
      {
        average_entry_price: 5249.12988,
        close_ms: 1711633587609,
        current_return: 0.7990276437967962,
        initial_entry_price: 5249.12988,
        is_closed_position: true,
        miner_hotkey: "5GTBRqGS3Zi6QZaYw85o67f1VhEhKvb8ndpnyFcWuNpWeC1f",
        net_leverage: 500.0,
        open_ms: 1711633384507,
        orders: [
          {
            leverage: 500.0,
            order_type: "LONG",
            order_uuid: "ec8a7ec9-62fb-4607-bf1a-225a4dcfbdec",
            price: 5249.12988,
            price_sources: [],
            processed_ms: 1711633384506,
          },
        ],
        position_type: "FLAT",
        position_uuid: "d9d63074-2f7f-4bd1-8ce5-0df708cbc875",
        return_at_close: 0.7630713998259404,
        trade_pair: ["SPX", "SPX", 9e-5, 0.001, 500],
      },
    ],
    thirty_day_returns: 1.0,
  },
  "5GTdjPFtqAEBntWoEA9GE6cbq3UKdTNfbShasT6gKD5ZYqKs": {
    all_time_returns: 1.0,
    n_positions: 0,
    percentage_profitable: 0.0,
    positions: [
      {
        average_entry_price: 60202.0,
        close_ms: 0,
        current_return: 0.9975989169795023,
        initial_entry_price: 60202.0,
        is_closed_position: false,
        miner_hotkey: "5GTdjPFtqAEBntWoEA9GE6cbq3UKdTNfbShasT6gKD5ZYqKs",
        net_leverage: 0.07,
        open_ms: 1719611259970,
        orders: [
          {
            leverage: 0.07,
            order_type: "LONG",
            order_uuid: "bd9d6441-e7d5-44ef-99b4-2073e3f48b81",
            price: 60202.0,
            price_sources: [],
            processed_ms: 1719611259365,
          },
        ],
        position_type: "LONG",
        position_uuid: "57674095-7b03-44c9-b7f7-f896236ac963",
        return_at_close: 0.997564001017408,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 0.84427,
        close_ms: 0,
        current_return: 0.9995131889087614,
        initial_entry_price: 0.84427,
        is_closed_position: false,
        miner_hotkey: "5GTdjPFtqAEBntWoEA9GE6cbq3UKdTNfbShasT6gKD5ZYqKs",
        net_leverage: 0.3,
        open_ms: 1719336243585,
        orders: [
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "9dc8bb5c-a939-48c5-8d9d-97c0ae2a8f06",
            price: 0.84427,
            price_sources: [],
            processed_ms: 1719336243576,
          },
        ],
        position_type: "LONG",
        position_uuid: "0a2bcc5f-45a8-4818-9397-bb3b86abe7f2",
        return_at_close: 0.9995026940202778,
        trade_pair: ["EURGBP", "EUR/GBP", 7e-5, 0.001, 500],
      },
    ],
    thirty_day_returns: 1.0,
  },
  "5GTgyjvN7TQk6dcRdfxGYfNQtad7NnMquhvTGy5iSR1L4RTf": {
    all_time_returns: 1.0,
    n_positions: 0,
    percentage_profitable: 0.0,
    positions: [
      {
        average_entry_price: 3405.9133333333334,
        close_ms: 1711214833262,
        current_return: 0.9979202082136535,
        initial_entry_price: 3411.88,
        is_closed_position: true,
        miner_hotkey: "5GTgyjvN7TQk6dcRdfxGYfNQtad7NnMquhvTGy5iSR1L4RTf",
        net_leverage: 0.0,
        open_ms: 1711213485254,
        orders: [
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "844e341c-6c81-4302-b92e-c268aa0fe89d",
            price: 3411.88,
            price_sources: [],
            processed_ms: 1711213485254,
          },
          {
            leverage: 0.4,
            order_type: "LONG",
            order_uuid: "64c8eb15-a360-48b7-94ea-026701885f77",
            price: 3406.38,
            price_sources: [],
            processed_ms: 1711213938540,
          },
          {
            leverage: 0.5,
            order_type: "LONG",
            order_uuid: "6b168b59-2761-4ace-8207-767300d7cefd",
            price: 3401.96,
            price_sources: [],
            processed_ms: 1711214379878,
          },
          {
            leverage: 0.6,
            order_type: "FLAT",
            order_uuid: "23abd849-ecf7-4b9c-bbcd-3d88aa1a99be",
            price: 3400.0,
            price_sources: [],
            processed_ms: 1711214833262,
          },
        ],
        position_type: "FLAT",
        position_uuid: "913daf58-0ab7-4bbb-b71a-74364f18f6e4",
        return_at_close: 0.9955251997139407,
        trade_pair: ["ETHUSD", "ETH/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 3409.9375000000005,
        close_ms: 1711216639597,
        current_return: 0.9938532437797541,
        initial_entry_price: 3415.46,
        is_closed_position: true,
        miner_hotkey: "5GTgyjvN7TQk6dcRdfxGYfNQtad7NnMquhvTGy5iSR1L4RTf",
        net_leverage: 0.0,
        open_ms: 1711215287640,
        orders: [
          {
            leverage: 0.7,
            order_type: "LONG",
            order_uuid: "6459b85e-a086-464e-b2f5-7bfb1867b2af",
            price: 3415.46,
            price_sources: [],
            processed_ms: 1711215287639,
          },
          {
            leverage: 0.8,
            order_type: "LONG",
            order_uuid: "d6c0299c-62d5-4b6a-9ab7-3886d338c576",
            price: 3410.66,
            price_sources: [],
            processed_ms: 1711215739987,
          },
          {
            leverage: 0.9,
            order_type: "LONG",
            order_uuid: "6b281caf-c453-4a87-a14b-fbbb0fb54fa2",
            price: 3405.0,
            price_sources: [],
            processed_ms: 1711216186213,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "beb426d4-e499-434b-a405-5018abbea9a7",
            price: 3401.19,
            price_sources: [],
            processed_ms: 1711216639597,
          },
        ],
        position_type: "FLAT",
        position_uuid: "0bd6b276-574a-4c26-9094-2bbffff463fc",
        return_at_close: 0.9890827482096113,
        trade_pair: ["ETHUSD", "ETH/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 3406.470277777778,
        close_ms: 1711218433547,
        current_return: 0.9986664236816346,
        initial_entry_price: 3402.13,
        is_closed_position: true,
        miner_hotkey: "5GTgyjvN7TQk6dcRdfxGYfNQtad7NnMquhvTGy5iSR1L4RTf",
        net_leverage: 0.0,
        open_ms: 1711217081934,
        orders: [
          {
            leverage: 1.1,
            order_type: "LONG",
            order_uuid: "389cc1e7-28a2-43fe-b462-931dd26e3f1c",
            price: 3402.13,
            price_sources: [],
            processed_ms: 1711217081933,
          },
          {
            leverage: 1.2,
            order_type: "LONG",
            order_uuid: "5da8d910-46c6-4c76-a5c3-0a80574dc13e",
            price: 3408.38,
            price_sources: [],
            processed_ms: 1711217538594,
          },
          {
            leverage: 1.3,
            order_type: "LONG",
            order_uuid: "f9b0307e-fba2-4f35-bddc-f6f6b7e3d317",
            price: 3408.38,
            price_sources: [],
            processed_ms: 1711217980979,
          },
          {
            leverage: 1.4,
            order_type: "FLAT",
            order_uuid: "f6f0ed04-f545-4210-a53a-9c7d8bded465",
            price: 3405.21,
            price_sources: [],
            processed_ms: 1711218433547,
          },
        ],
        position_type: "FLAT",
        position_uuid: "811d8e40-c7c8-47b2-8b6a-15b723f73a17",
        return_at_close: 0.9914760254311269,
        trade_pair: ["ETHUSD", "ETH/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 3403.715625,
        close_ms: 1711220237514,
        current_return: 1.0066015466732436,
        initial_entry_price: 3406.02,
        is_closed_position: true,
        miner_hotkey: "5GTgyjvN7TQk6dcRdfxGYfNQtad7NnMquhvTGy5iSR1L4RTf",
        net_leverage: 0.0,
        open_ms: 1711218889323,
        orders: [
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "7f30b720-9bec-41db-8587-071c946044d6",
            price: 3406.02,
            price_sources: [],
            processed_ms: 1711218889322,
          },
          {
            leverage: 1.6,
            order_type: "LONG",
            order_uuid: "d12b37e1-45ba-47fb-b7ae-135e1a06e914",
            price: 3400.01,
            price_sources: [],
            processed_ms: 1711219332768,
          },
          {
            leverage: 1.7,
            order_type: "LONG",
            order_uuid: "1249fb7a-da17-4cc8-9b1f-6705f08099ab",
            price: 3405.17,
            price_sources: [],
            processed_ms: 1711219783541,
          },
          {
            leverage: 1.8,
            order_type: "FLAT",
            order_uuid: "c2175983-cde2-4284-9866-d5a22cc8414f",
            price: 3408.4,
            price_sources: [],
            processed_ms: 1711220237514,
          },
        ],
        position_type: "FLAT",
        position_uuid: "f38b0d83-5038-4259-b4b0-8df25b8d700f",
        return_at_close: 0.9969381718251804,
        trade_pair: ["ETHUSD", "ETH/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 3404.710333333334,
        close_ms: 1711222034952,
        current_return: 0.9918782113391457,
        initial_entry_price: 3405.9,
        is_closed_position: true,
        miner_hotkey: "5GTgyjvN7TQk6dcRdfxGYfNQtad7NnMquhvTGy5iSR1L4RTf",
        net_leverage: 0.0,
        open_ms: 1711220691367,
        orders: [
          {
            leverage: 1.9,
            order_type: "LONG",
            order_uuid: "f033557e-aca0-4166-bb0a-f7b0277cf976",
            price: 3405.9,
            price_sources: [],
            processed_ms: 1711220691367,
          },
          {
            leverage: 2.0,
            order_type: "LONG",
            order_uuid: "67f4f07e-a343-4589-83fb-550fe1ffbf48",
            price: 3405.88,
            price_sources: [],
            processed_ms: 1711221133157,
          },
          {
            leverage: 2.1,
            order_type: "LONG",
            order_uuid: "c62f08e2-8b74-4dfa-b57d-4b905198e9bd",
            price: 3402.52,
            price_sources: [],
            processed_ms: 1711221589416,
          },
          {
            leverage: 2.2,
            order_type: "FLAT",
            order_uuid: "ede9b99c-c85b-4d8e-993a-cdc99ca343e2",
            price: 3400.1,
            price_sources: [],
            processed_ms: 1711222034952,
          },
        ],
        position_type: "FLAT",
        position_uuid: "cb0f3e3a-0594-46f7-8e65-f5ccf731c13a",
        return_at_close: 0.979975672803076,
        trade_pair: ["ETHUSD", "ETH/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 3390.9452777777783,
        close_ms: 1711223840371,
        current_return: 0.9960238650552447,
        initial_entry_price: 3395.76,
        is_closed_position: true,
        miner_hotkey: "5GTgyjvN7TQk6dcRdfxGYfNQtad7NnMquhvTGy5iSR1L4RTf",
        net_leverage: 0.0,
        open_ms: 1711222492071,
        orders: [
          {
            leverage: 2.3,
            order_type: "LONG",
            order_uuid: "818cdc2d-b9cc-4224-9c7b-8a2febcecf77",
            price: 3395.76,
            price_sources: [],
            processed_ms: 1711222492070,
          },
          {
            leverage: 2.4,
            order_type: "LONG",
            order_uuid: "52efaa87-f9bf-4a91-955c-949a6a290a8c",
            price: 3391.92,
            price_sources: [],
            processed_ms: 1711222935322,
          },
          {
            leverage: 2.5,
            order_type: "LONG",
            order_uuid: "514aaa59-af67-4e9f-9f30-f2cdaf8aa696",
            price: 3385.58,
            price_sources: [],
            processed_ms: 1711223389176,
          },
          {
            leverage: 2.6,
            order_type: "FLAT",
            order_uuid: "db283a25-9424-4c27-9136-9874d63d8082",
            price: 3389.07,
            price_sources: [],
            processed_ms: 1711223840371,
          },
        ],
        position_type: "FLAT",
        position_uuid: "fdb5f02e-c237-4015-966d-6f34eb174f1a",
        return_at_close: 0.9816811213984492,
        trade_pair: ["ETHUSD", "ETH/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 3385.6697619047613,
        close_ms: 1711225645450,
        current_return: 1.0043644327949381,
        initial_entry_price: 3387.84,
        is_closed_position: true,
        miner_hotkey: "5GTgyjvN7TQk6dcRdfxGYfNQtad7NnMquhvTGy5iSR1L4RTf",
        net_leverage: 0.0,
        open_ms: 1711224293372,
        orders: [
          {
            leverage: 2.7,
            order_type: "LONG",
            order_uuid: "61ff2893-25f5-4fb1-acea-214434421e9e",
            price: 3387.84,
            price_sources: [],
            processed_ms: 1711224293370,
          },
          {
            leverage: 2.8,
            order_type: "LONG",
            order_uuid: "928afd0e-1e7b-44a2-a5ed-a7050fd711be",
            price: 3381.06,
            price_sources: [],
            processed_ms: 1711224738758,
          },
          {
            leverage: 2.9,
            order_type: "LONG",
            order_uuid: "9957266a-3c73-4679-a9eb-b0e90c67469a",
            price: 3388.1,
            price_sources: [],
            processed_ms: 1711225190931,
          },
          {
            leverage: 3.0,
            order_type: "FLAT",
            order_uuid: "ae343456-89c6-444b-ae12-f7c9c4f78a70",
            price: 3387.43,
            price_sources: [],
            processed_ms: 1711225645450,
          },
        ],
        position_type: "FLAT",
        position_uuid: "ad68f776-ca95-499a-90db-bca83e4baf97",
        return_at_close: 0.9874911103239832,
        trade_pair: ["ETHUSD", "ETH/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 3389.1308333333327,
        close_ms: 1711227445552,
        current_return: 0.9896847461028586,
        initial_entry_price: 3388.38,
        is_closed_position: true,
        miner_hotkey: "5GTgyjvN7TQk6dcRdfxGYfNQtad7NnMquhvTGy5iSR1L4RTf",
        net_leverage: 0.0,
        open_ms: 1711226091079,
        orders: [
          {
            leverage: 3.1,
            order_type: "LONG",
            order_uuid: "624e35ae-2017-44a4-911e-3d707043d915",
            price: 3388.38,
            price_sources: [],
            processed_ms: 1711226091078,
          },
          {
            leverage: 3.2,
            order_type: "LONG",
            order_uuid: "92d18193-1460-4b2a-9fc8-11fbb3caaf15",
            price: 3389.56,
            price_sources: [],
            processed_ms: 1711226544799,
          },
          {
            leverage: 3.3,
            order_type: "LONG",
            order_uuid: "c054a8b0-ede3-411d-afc1-7a41f58673d8",
            price: 3389.42,
            price_sources: [],
            processed_ms: 1711226992516,
          },
          {
            leverage: 3.4,
            order_type: "FLAT",
            order_uuid: "dc755f01-2ddb-4754-a837-02aac3bee231",
            price: 3385.49,
            price_sources: [],
            processed_ms: 1711227445552,
          },
        ],
        position_type: "FLAT",
        position_uuid: "c27f53d6-4f53-45d2-a2f5-fc6fa0109083",
        return_at_close: 0.9706827989776837,
        trade_pair: ["ETHUSD", "ETH/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 3379.902129629629,
        close_ms: 1711229237757,
        current_return: 0.996034268179844,
        initial_entry_price: 3382.73,
        is_closed_position: true,
        miner_hotkey: "5GTgyjvN7TQk6dcRdfxGYfNQtad7NnMquhvTGy5iSR1L4RTf",
        net_leverage: 0.0,
        open_ms: 1711227887620,
        orders: [
          {
            leverage: 3.5,
            order_type: "LONG",
            order_uuid: "3c1a63b7-ef95-4f25-a18e-81c2d129f284",
            price: 3382.73,
            price_sources: [],
            processed_ms: 1711227887619,
          },
          {
            leverage: 3.6,
            order_type: "LONG",
            order_uuid: "8a66f733-6879-48c1-a276-8bcf50f32c66",
            price: 3377.71,
            price_sources: [],
            processed_ms: 1711228342133,
          },
          {
            leverage: 3.7,
            order_type: "LONG",
            order_uuid: "5a11d9bc-17f3-436c-8816-cf9aa379252c",
            price: 3379.36,
            price_sources: [],
            processed_ms: 1711228795241,
          },
          {
            leverage: 3.8,
            order_type: "FLAT",
            order_uuid: "b46e7d13-29b2-4ca8-944b-8cc8c500dd0a",
            price: 3378.66,
            price_sources: [],
            processed_ms: 1711229237757,
          },
        ],
        position_type: "FLAT",
        position_uuid: "c4458e57-c432-4715-b8ef-03acf699a2be",
        return_at_close: 0.9745199279871594,
        trade_pair: ["ETHUSD", "ETH/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 3381.831583333333,
        close_ms: 1711231048408,
        current_return: 1.0269941974065961,
        initial_entry_price: 3377.8,
        is_closed_position: true,
        miner_hotkey: "5GTgyjvN7TQk6dcRdfxGYfNQtad7NnMquhvTGy5iSR1L4RTf",
        net_leverage: 0.0,
        open_ms: 1711229695199,
        orders: [
          {
            leverage: 3.9,
            order_type: "LONG",
            order_uuid: "e5a91882-3e6d-4b74-91cd-96e5182822e6",
            price: 3377.8,
            price_sources: [],
            processed_ms: 1711229695197,
          },
          {
            leverage: 4.0,
            order_type: "LONG",
            order_uuid: "4fe512c8-ff54-4e60-9dd9-4dd27a6e6a41",
            price: 3377.81,
            price_sources: [],
            processed_ms: 1711230139331,
          },
          {
            leverage: 4.1,
            order_type: "LONG",
            order_uuid: "4e8c3131-9ea9-46fc-bc57-7c785b7f42f6",
            price: 3389.59,
            price_sources: [],
            processed_ms: 1711230594571,
          },
          {
            leverage: 4.2,
            order_type: "FLAT",
            order_uuid: "ac09006a-f05e-4933-92f6-c5dd0b82eab4",
            price: 3389.43,
            price_sources: [],
            processed_ms: 1711231048408,
          },
        ],
        position_type: "FLAT",
        position_uuid: "bf4e57b3-808c-4499-acde-f1af380afcf4",
        return_at_close: 1.0023463366688379,
        trade_pair: ["ETHUSD", "ETH/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 3383.7497727272726,
        close_ms: 1711232847524,
        current_return: 0.9730489150639989,
        initial_entry_price: 3389.14,
        is_closed_position: true,
        miner_hotkey: "5GTgyjvN7TQk6dcRdfxGYfNQtad7NnMquhvTGy5iSR1L4RTf",
        net_leverage: 0.0,
        open_ms: 1711231495559,
        orders: [
          {
            leverage: 4.3,
            order_type: "LONG",
            order_uuid: "2a325a83-8c57-433f-9a7f-41b78cf778b5",
            price: 3389.14,
            price_sources: [],
            processed_ms: 1711231495557,
          },
          {
            leverage: 4.4,
            order_type: "LONG",
            order_uuid: "8d14026b-eaf7-4464-99c6-b2a35eb23991",
            price: 3386.95,
            price_sources: [],
            processed_ms: 1711231947377,
          },
          {
            leverage: 4.5,
            order_type: "LONG",
            order_uuid: "74cd01c8-0efa-498c-bff3-061565760c3f",
            price: 3375.47,
            price_sources: [],
            processed_ms: 1711232391595,
          },
          {
            leverage: 4.6,
            order_type: "FLAT",
            order_uuid: "987c5ec0-64bc-4ac3-8a9a-830b53ce13ac",
            price: 3376.83,
            price_sources: [],
            processed_ms: 1711232847524,
          },
        ],
        position_type: "FLAT",
        position_uuid: "32706221-226a-4a99-baa8-b59fea77229f",
        return_at_close: 0.9473604237063094,
        trade_pair: ["ETHUSD", "ETH/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 3385.057083333333,
        close_ms: 1711234650156,
        current_return: 1.0243678106634637,
        initial_entry_price: 3387.83,
        is_closed_position: true,
        miner_hotkey: "5GTgyjvN7TQk6dcRdfxGYfNQtad7NnMquhvTGy5iSR1L4RTf",
        net_leverage: 0.0,
        open_ms: 1711233295558,
        orders: [
          {
            leverage: 4.7,
            order_type: "LONG",
            order_uuid: "e0b8e45c-8397-42cf-8598-fedf41b40d3f",
            price: 3387.83,
            price_sources: [],
            processed_ms: 1711233295557,
          },
          {
            leverage: 4.8,
            order_type: "LONG",
            order_uuid: "91eb153b-bb65-49d6-8401-71644f59f3fb",
            price: 3386.31,
            price_sources: [],
            processed_ms: 1711233741266,
          },
          {
            leverage: 4.9,
            order_type: "LONG",
            order_uuid: "32546ea7-4bcb-4367-8413-fd2a83a01890",
            price: 3381.17,
            price_sources: [],
            processed_ms: 1711234194749,
          },
          {
            leverage: 5.0,
            order_type: "FLAT",
            order_uuid: "31e26d34-a4f6-4dee-be1a-b503baf7e8e1",
            price: 3390.79,
            price_sources: [],
            processed_ms: 1711234650156,
          },
        ],
        position_type: "FLAT",
        position_uuid: "e7be059d-d1ec-44ef-b749-e20a31f0a73c",
        return_at_close: 0.994866017716356,
        trade_pair: ["ETHUSD", "ETH/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 3372.725833333333,
        close_ms: 1711236445061,
        current_return: 0.9849486741818104,
        initial_entry_price: 3374.52,
        is_closed_position: true,
        miner_hotkey: "5GTgyjvN7TQk6dcRdfxGYfNQtad7NnMquhvTGy5iSR1L4RTf",
        net_leverage: 0.0,
        open_ms: 1711235093970,
        orders: [
          {
            leverage: 5.1,
            order_type: "LONG",
            order_uuid: "ecfd5289-8eab-4d17-b9ce-608e572e2782",
            price: 3374.52,
            price_sources: [],
            processed_ms: 1711235093969,
          },
          {
            leverage: 5.2,
            order_type: "LONG",
            order_uuid: "2a222c96-a763-4110-b55d-6f2b538ea70f",
            price: 3373.51,
            price_sources: [],
            processed_ms: 1711235548694,
          },
          {
            leverage: 5.3,
            order_type: "LONG",
            order_uuid: "dd621508-4586-4166-90b1-627bb1d6db5c",
            price: 3370.23,
            price_sources: [],
            processed_ms: 1711236000936,
          },
          {
            leverage: 4.399999999999999,
            order_type: "FLAT",
            order_uuid: "e977b33d-8ece-474c-a703-e9571da17379",
            price: 3369.47,
            price_sources: [],
            processed_ms: 1711236445061,
          },
        ],
        position_type: "FLAT",
        position_uuid: "53a94a14-ee2e-4585-9da7-f05c74146b06",
        return_at_close: 0.954218275547338,
        trade_pair: ["ETHUSD", "ETH/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 3357.6086309523807,
        close_ms: 1711238197015,
        current_return: 0.8837426639258812,
        initial_entry_price: 3356.7,
        is_closed_position: true,
        miner_hotkey: "5GTgyjvN7TQk6dcRdfxGYfNQtad7NnMquhvTGy5iSR1L4RTf",
        net_leverage: 16.8,
        open_ms: 1711236893333,
        orders: [
          {
            leverage: 5.5,
            order_type: "LONG",
            order_uuid: "4d9d748f-af6c-4248-86aa-a4abcafc1f3c",
            price: 3356.7,
            price_sources: [],
            processed_ms: 1711236893331,
          },
          {
            leverage: 5.6,
            order_type: "LONG",
            order_uuid: "cf7b0991-b204-4ede-a2a2-87206d0b7a61",
            price: 3360.8,
            price_sources: [],
            processed_ms: 1711237350597,
          },
          {
            leverage: 5.7,
            order_type: "LONG",
            order_uuid: "9e587146-aa3c-42be-ae14-88842e087fa6",
            price: 3355.35,
            price_sources: [],
            processed_ms: 1711237796020,
          },
        ],
        position_type: "FLAT",
        position_uuid: "f89f4054-2690-445f-b6d0-f32d91191b3b",
        return_at_close: 0.8540489104179716,
        trade_pair: ["ETHUSD", "ETH/USD", 0.003, 0.001, 20],
      },
    ],
    thirty_day_returns: 1.0,
  },
  "5GUKCpZi73ikDgiHadDQ38GPUs4ZzifAyooa2pWfeaHdbeND": {
    all_time_returns: 1.0,
    n_positions: 0,
    percentage_profitable: 0.0,
    positions: [
      {
        average_entry_price: 64194.05,
        close_ms: 1711254190720,
        current_return: 0.9991970283850294,
        initial_entry_price: 64194.05,
        is_closed_position: true,
        miner_hotkey: "5GUKCpZi73ikDgiHadDQ38GPUs4ZzifAyooa2pWfeaHdbeND",
        net_leverage: 0.0,
        open_ms: 1711250674507,
        orders: [
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "5d0eb061-cb53-4cb8-9311-800214ef60d4",
            price: 64194.05,
            price_sources: [],
            processed_ms: 1711250674506,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "060c571d-0f5c-4d43-b979-43e7bb33e2ab",
            price: 64022.23,
            price_sources: [],
            processed_ms: 1711254190720,
          },
        ],
        position_type: "FLAT",
        position_uuid: "ffece7e3-7b07-48ce-b21e-b4b6f340451a",
        return_at_close: 0.9985975101679984,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 63923.69999999999,
        close_ms: 1711259683815,
        current_return: 1.0020741602879684,
        initial_entry_price: 63923.7,
        is_closed_position: true,
        miner_hotkey: "5GUKCpZi73ikDgiHadDQ38GPUs4ZzifAyooa2pWfeaHdbeND",
        net_leverage: 0.0,
        open_ms: 1711254279125,
        orders: [
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "16813484-45b1-4923-acab-190c4b740028",
            price: 63923.7,
            price_sources: [],
            processed_ms: 1711254279124,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "b9a6e07d-0d0d-4e0b-a557-f084edbb8bd2",
            price: 64365.66,
            price_sources: [],
            processed_ms: 1711259683815,
          },
        ],
        position_type: "FLAT",
        position_uuid: "8286d655-d79f-4456-815d-f7fb50d5c4ab",
        return_at_close: 1.0014729157917954,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 64352.94,
        close_ms: 1711263345685,
        current_return: 0.9969304044228593,
        initial_entry_price: 64352.94,
        is_closed_position: true,
        miner_hotkey: "5GUKCpZi73ikDgiHadDQ38GPUs4ZzifAyooa2pWfeaHdbeND",
        net_leverage: 0.0,
        open_ms: 1711259771493,
        orders: [
          {
            leverage: 1.25,
            order_type: "LONG",
            order_uuid: "05eb570d-f191-47c2-ab00-142f2cb77741",
            price: 64352.94,
            price_sources: [],
            processed_ms: 1711259771492,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "9f8a6db0-296a-4c94-aa31-943d10730e0a",
            price: 64194.91,
            price_sources: [],
            processed_ms: 1711263345685,
          },
        ],
        position_type: "FLAT",
        position_uuid: "030814ed-cae0-4737-b5e2-4a745a91cca5",
        return_at_close: 0.9944380784118022,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 64212.62000000001,
        close_ms: 1711267008715,
        current_return: 1.0022468324762326,
        initial_entry_price: 64212.62,
        is_closed_position: true,
        miner_hotkey: "5GUKCpZi73ikDgiHadDQ38GPUs4ZzifAyooa2pWfeaHdbeND",
        net_leverage: 0.0,
        open_ms: 1711263432715,
        orders: [
          {
            leverage: 1.25,
            order_type: "LONG",
            order_uuid: "b1e35ec0-8872-4946-9aaf-6a9529a7e5e8",
            price: 64212.62,
            price_sources: [],
            processed_ms: 1711263432714,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "e2fcecc9-a61b-4c2a-8979-3f646a6193d6",
            price: 64328.04,
            price_sources: [],
            processed_ms: 1711267008715,
          },
        ],
        position_type: "FLAT",
        position_uuid: "a04c262d-09b9-4018-8556-4beb23207df5",
        return_at_close: 0.999741215395042,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 64494.44,
        close_ms: 1711270666745,
        current_return: 1.0124931311908436,
        initial_entry_price: 64494.44,
        is_closed_position: true,
        miner_hotkey: "5GUKCpZi73ikDgiHadDQ38GPUs4ZzifAyooa2pWfeaHdbeND",
        net_leverage: 0.0,
        open_ms: 1711267095698,
        orders: [
          {
            leverage: 1.25,
            order_type: "LONG",
            order_uuid: "02c121b9-23ed-4b4f-a3ac-12dca5e58ec1",
            price: 64494.44,
            price_sources: [],
            processed_ms: 1711267095697,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "994bb10d-09dc-404b-84e8-e53b6dfa32c6",
            price: 65139.03,
            price_sources: [],
            processed_ms: 1711270666745,
          },
        ],
        position_type: "FLAT",
        position_uuid: "9de5e84b-211d-49b5-886f-c976021ab7f3",
        return_at_close: 1.0099618983628667,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 65172.48,
        close_ms: 1711274331697,
        current_return: 0.9939865338866957,
        initial_entry_price: 65172.48,
        is_closed_position: true,
        miner_hotkey: "5GUKCpZi73ikDgiHadDQ38GPUs4ZzifAyooa2pWfeaHdbeND",
        net_leverage: 0.0,
        open_ms: 1711270753664,
        orders: [
          {
            leverage: 1.25,
            order_type: "LONG",
            order_uuid: "ce9792f7-31d1-438a-9d89-d5131cbf5827",
            price: 65172.48,
            price_sources: [],
            processed_ms: 1711270753663,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "bf58e7b3-b86f-462f-8974-8aaf50cfe52e",
            price: 64858.95,
            price_sources: [],
            processed_ms: 1711274331697,
          },
        ],
        position_type: "FLAT",
        position_uuid: "b11920a4-ff9c-47f7-ba7e-7ce7cfbf4728",
        return_at_close: 0.9915015675519789,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 64885.58999999999,
        close_ms: 1711278009585,
        current_return: 1.004254796789241,
        initial_entry_price: 64885.59,
        is_closed_position: true,
        miner_hotkey: "5GUKCpZi73ikDgiHadDQ38GPUs4ZzifAyooa2pWfeaHdbeND",
        net_leverage: 0.0,
        open_ms: 1711274418674,
        orders: [
          {
            leverage: 1.25,
            order_type: "LONG",
            order_uuid: "c2464a2e-3824-408d-91c9-61660d226d8e",
            price: 64885.59,
            price_sources: [],
            processed_ms: 1711274418673,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "59be99ee-08e5-4a36-861d-a530afca1432",
            price: 65106.45,
            price_sources: [],
            processed_ms: 1711278009585,
          },
        ],
        position_type: "FLAT",
        position_uuid: "031e84f5-f52b-4171-83a1-4bd2b1fd30c0",
        return_at_close: 1.001744159797268,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 65078.56999999999,
        close_ms: 1711282152120,
        current_return: 1.0105910440257064,
        initial_entry_price: 65078.57,
        is_closed_position: true,
        miner_hotkey: "5GUKCpZi73ikDgiHadDQ38GPUs4ZzifAyooa2pWfeaHdbeND",
        net_leverage: 0.0,
        open_ms: 1711278096633,
        orders: [
          {
            leverage: 1.25,
            order_type: "LONG",
            order_uuid: "d04b8269-a88e-4783-b29c-f79249a4c23a",
            price: 65078.57,
            price_sources: [],
            processed_ms: 1711278096632,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "a3421412-e9de-4ccb-a1d4-8d470251d097",
            price: 65629.97,
            price_sources: [],
            processed_ms: 1711282152120,
          },
        ],
        position_type: "FLAT",
        position_uuid: "4d9bc151-f8df-4cd0-8dc8-1b46efa76444",
        return_at_close: 1.0080645664156422,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 65710.02,
        close_ms: 1711307117596,
        current_return: 1.0028947259489496,
        initial_entry_price: 65710.02,
        is_closed_position: true,
        miner_hotkey: "5GUKCpZi73ikDgiHadDQ38GPUs4ZzifAyooa2pWfeaHdbeND",
        net_leverage: 0.0,
        open_ms: 1711306185798,
        orders: [
          {
            leverage: 1.25,
            order_type: "LONG",
            order_uuid: "f3fe1cc6-a190-4a7e-a339-4c92e9d3f92d",
            price: 65710.02,
            price_sources: [],
            processed_ms: 1711306185797,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "20b2aa09-9283-4ea7-b5b7-dd213754f0b0",
            price: 65862.19,
            price_sources: [],
            processed_ms: 1711307117596,
          },
        ],
        position_type: "FLAT",
        position_uuid: "cf59e501-849c-48e2-86af-9aa4bf460ca4",
        return_at_close: 1.0003874891340772,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 66919.93,
        close_ms: 1711326671460,
        current_return: 0.9994461664858287,
        initial_entry_price: 66919.93,
        is_closed_position: true,
        miner_hotkey: "5GUKCpZi73ikDgiHadDQ38GPUs4ZzifAyooa2pWfeaHdbeND",
        net_leverage: 0.0,
        open_ms: 1711325721728,
        orders: [
          {
            leverage: 1.25,
            order_type: "LONG",
            order_uuid: "b50602fa-e636-4ebd-83d5-50071589fe96",
            price: 66919.93,
            price_sources: [],
            processed_ms: 1711325721727,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "1a0afe21-1877-4d2c-bbc4-69b77ece09f4",
            price: 66890.28,
            price_sources: [],
            processed_ms: 1711326671460,
          },
        ],
        position_type: "FLAT",
        position_uuid: "809ce994-435c-4b40-ba07-23a8e48d7709",
        return_at_close: 0.9969475510696142,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 66609.37,
        close_ms: 1711328526499,
        current_return: 0.9999117991958189,
        initial_entry_price: 66609.37,
        is_closed_position: true,
        miner_hotkey: "5GUKCpZi73ikDgiHadDQ38GPUs4ZzifAyooa2pWfeaHdbeND",
        net_leverage: 0.0,
        open_ms: 1711327585767,
        orders: [
          {
            leverage: 1.25,
            order_type: "LONG",
            order_uuid: "51dfba48-4333-4c0d-9f90-25e98c82c446",
            price: 66609.37,
            price_sources: [],
            processed_ms: 1711327585766,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "ae464dee-6ca1-4450-b7ad-256cb266343a",
            price: 66604.67,
            price_sources: [],
            processed_ms: 1711328526499,
          },
        ],
        position_type: "FLAT",
        position_uuid: "9cd6ec1f-9d2d-49d9-8cf8-32ead444f275",
        return_at_close: 0.9974120196978293,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 67111.05,
        close_ms: 1711342346162,
        current_return: 1.0068099813667049,
        initial_entry_price: 67111.05,
        is_closed_position: true,
        miner_hotkey: "5GUKCpZi73ikDgiHadDQ38GPUs4ZzifAyooa2pWfeaHdbeND",
        net_leverage: 0.0,
        open_ms: 1711340495888,
        orders: [
          {
            leverage: 1.25,
            order_type: "LONG",
            order_uuid: "b9bc57ee-1c38-495c-bfd1-53245886811b",
            price: 67111.05,
            price_sources: [],
            processed_ms: 1711340495887,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "5407107c-067e-4862-b5b7-ebb5ea00b877",
            price: 67476.67,
            price_sources: [],
            processed_ms: 1711342346162,
          },
        ],
        position_type: "FLAT",
        position_uuid: "ff12103a-4340-4fec-9a7d-411f4bcecd84",
        return_at_close: 1.004292956413288,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 66899.22,
        close_ms: 1711358802808,
        current_return: 0.999932174097097,
        initial_entry_price: 66899.22,
        is_closed_position: true,
        miner_hotkey: "5GUKCpZi73ikDgiHadDQ38GPUs4ZzifAyooa2pWfeaHdbeND",
        net_leverage: 0.0,
        open_ms: 1711356982396,
        orders: [
          {
            leverage: 1.25,
            order_type: "LONG",
            order_uuid: "2a342015-1067-4c65-87cd-6cc0c045fdcc",
            price: 66899.22,
            price_sources: [],
            processed_ms: 1711356982394,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "6a8ab7ca-1aa7-4e40-b6c6-957125f4ad1d",
            price: 66895.59,
            price_sources: [],
            processed_ms: 1711358802808,
          },
        ],
        position_type: "FLAT",
        position_uuid: "35a2e1a8-2306-4d61-b99d-72debe5155f4",
        return_at_close: 0.9974323436618543,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 67220.6,
        close_ms: 1711364290870,
        current_return: 0.996953500861343,
        initial_entry_price: 67220.6,
        is_closed_position: true,
        miner_hotkey: "5GUKCpZi73ikDgiHadDQ38GPUs4ZzifAyooa2pWfeaHdbeND",
        net_leverage: 0.0,
        open_ms: 1711362467678,
        orders: [
          {
            leverage: 1.25,
            order_type: "LONG",
            order_uuid: "ad5212df-4cba-4f2c-acc1-deb5d257cacd",
            price: 67220.6,
            price_sources: [],
            processed_ms: 1711362467677,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "8e2ead8b-a573-4d5c-aaa9-9ed63601b173",
            price: 67056.77,
            price_sources: [],
            processed_ms: 1711364290870,
          },
        ],
        position_type: "FLAT",
        position_uuid: "e69b5c15-80aa-4812-a846-1f76c410cc23",
        return_at_close: 0.9944611171091897,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: -1037853.138771915,
        close_ms: 1711385286781,
        current_return: 1.0376131122510432,
        initial_entry_price: 67159.91,
        is_closed_position: true,
        miner_hotkey: "5GUKCpZi73ikDgiHadDQ38GPUs4ZzifAyooa2pWfeaHdbeND",
        net_leverage: 0.0,
        open_ms: 1711369898905,
        orders: [
          {
            leverage: 1.25,
            order_type: "LONG",
            order_uuid: "c13de907-a204-417a-8683-fce4ca041c3e",
            price: 67159.91,
            price_sources: [],
            processed_ms: 1711369898902,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "8c69b66e-e375-4ea4-af4e-d71895cb8e46",
            price: 67127.19,
            price_sources: [],
            processed_ms: 1711371739172,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "4f3e7643-1612-4cef-a3f1-c9fa4536db1a",
            price: 67188.75,
            price_sources: [],
            processed_ms: 1711373568818,
          },
          {
            leverage: -0.875,
            order_type: "SHORT",
            order_uuid: "8c809908-310c-4925-87c5-3aa1b333a76e",
            price: 68187.31,
            price_sources: [],
            processed_ms: 1711375404534,
          },
          {
            leverage: -0.7,
            order_type: "SHORT",
            order_uuid: "b6e70a3a-fa84-4170-85fc-af9fe2ba5de9",
            price: 68925.28,
            price_sources: [],
            processed_ms: 1711376399465,
          },
          {
            leverage: -0.14000000000000004,
            order_type: "SHORT",
            order_uuid: "017e1d3d-8f99-400c-a6d4-4354aff8c471",
            price: 69210.38,
            price_sources: [],
            processed_ms: 1711376728528,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "57fa4a5c-e7b7-43fa-9adc-fed1a147d06d",
            price: 69095.56,
            price_sources: [],
            processed_ms: 1711377877075,
          },
          {
            leverage: -0.22800000000000004,
            order_type: "SHORT",
            order_uuid: "2f6b069c-98d8-4350-95c9-d140930ddf7c",
            price: 69074.0,
            price_sources: [],
            processed_ms: 1711378164961,
          },
          {
            leverage: -0.045599999999999995,
            order_type: "SHORT",
            order_uuid: "fa3ea66c-5da0-4685-b6be-d257fc5ba80b",
            price: 69824.77,
            price_sources: [],
            processed_ms: 1711381644059,
          },
          {
            leverage: -0.00912,
            order_type: "SHORT",
            order_uuid: "1ebb98d6-2199-4824-8260-56d1e5942a5e",
            price: 69642.62,
            price_sources: [],
            processed_ms: 1711383472620,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "8ef16f5f-9e65-40cc-8133-2ba40cfa62f8",
            price: 70082.49,
            price_sources: [],
            processed_ms: 1711385286781,
          },
        ],
        position_type: "FLAT",
        position_uuid: "89fbd239-b1c3-411b-8c8f-8c4f6ce4d486",
        return_at_close: 1.0339814663581646,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 97851.86000000166,
        close_ms: 1711403450052,
        current_return: 1.0015455172614465,
        initial_entry_price: 70625.02,
        is_closed_position: true,
        miner_hotkey: "5GUKCpZi73ikDgiHadDQ38GPUs4ZzifAyooa2pWfeaHdbeND",
        net_leverage: 0.0,
        open_ms: 1711387100015,
        orders: [
          {
            leverage: -1.25,
            order_type: "SHORT",
            order_uuid: "56df28ea-37df-486a-843a-916dc1e20371",
            price: 70625.02,
            price_sources: [],
            processed_ms: 1711387100014,
          },
          {
            leverage: 0.625,
            order_type: "LONG",
            order_uuid: "1612b2e2-74ee-49d2-b36e-2a399da24611",
            price: 70348.66,
            price_sources: [],
            processed_ms: 1711388932774,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "169acb9f-4212-42c9-974d-ca5cfeaa09c3",
            price: 70644.4,
            price_sources: [],
            processed_ms: 1711390725936,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "6701de10-1e13-4959-bcca-865ed42f0d88",
            price: 70788.29,
            price_sources: [],
            processed_ms: 1711392548547,
          },
          {
            leverage: 0.0625,
            order_type: "LONG",
            order_uuid: "e9315520-6fb4-428e-b46a-0f833ea41658",
            price: 70767.87,
            price_sources: [],
            processed_ms: 1711394364033,
          },
          {
            leverage: 0.03125,
            order_type: "LONG",
            order_uuid: "0eb6b396-76e6-47dd-95e5-c61d4e386a66",
            price: 70890.01,
            price_sources: [],
            processed_ms: 1711396180973,
          },
          {
            leverage: 0.015625,
            order_type: "LONG",
            order_uuid: "eb0f0d8e-da3a-40aa-a71d-e5e0859f333c",
            price: 70876.59,
            price_sources: [],
            processed_ms: 1711397994030,
          },
          {
            leverage: 0.0078125,
            order_type: "LONG",
            order_uuid: "6b655d7e-3170-4c20-8cec-047e2a13e8b4",
            price: 70837.56,
            price_sources: [],
            processed_ms: 1711399818124,
          },
          {
            leverage: 0.00390625,
            order_type: "LONG",
            order_uuid: "01a98b92-7610-4d59-906d-87ef7be168fe",
            price: 70089.3,
            price_sources: [],
            processed_ms: 1711401633895,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "2aafb8f5-45e5-4e52-82c1-c955112399fb",
            price: 69908.9,
            price_sources: [],
            processed_ms: 1711403450052,
          },
        ],
        position_type: "FLAT",
        position_uuid: "219874bc-9872-4a19-afe7-095c0894a3c2",
        return_at_close: 0.9990416534682929,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 518972.9328571394,
        close_ms: 1711428900893,
        current_return: 0.9891102254168449,
        initial_entry_price: 70369.19,
        is_closed_position: true,
        miner_hotkey: "5GUKCpZi73ikDgiHadDQ38GPUs4ZzifAyooa2pWfeaHdbeND",
        net_leverage: 0.0,
        open_ms: 1711405281624,
        orders: [
          {
            leverage: 1.25,
            order_type: "LONG",
            order_uuid: "bcc773d2-e6f8-4666-a449-2939133f06be",
            price: 70369.19,
            price_sources: [],
            processed_ms: 1711405281623,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "e2f026f8-58ff-4b76-9870-aac6a5fc4458",
            price: 70371.69,
            price_sources: [],
            processed_ms: 1711407081814,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "e055a746-d4a0-4a6e-8a52-b24885fdce45",
            price: 70433.13,
            price_sources: [],
            processed_ms: 1711408904024,
          },
          {
            leverage: -0.875,
            order_type: "SHORT",
            order_uuid: "062949fe-94a1-4c41-a802-fb8907fc8cd9",
            price: 69890.81,
            price_sources: [],
            processed_ms: 1711410731631,
          },
          {
            leverage: -0.4375,
            order_type: "SHORT",
            order_uuid: "35699620-6ee4-4356-940f-249f5cafc41c",
            price: 69899.85,
            price_sources: [],
            processed_ms: 1711412542870,
          },
          {
            leverage: -0.21875,
            order_type: "SHORT",
            order_uuid: "2dccd5fa-ab91-49ed-b7f7-0dcdbb4862eb",
            price: 69912.76,
            price_sources: [],
            processed_ms: 1711414358086,
          },
          {
            leverage: -0.109375,
            order_type: "SHORT",
            order_uuid: "8e56e652-3d18-4f43-8a75-b892a0743041",
            price: 70208.9,
            price_sources: [],
            processed_ms: 1711416194566,
          },
          {
            leverage: -0.0546875,
            order_type: "SHORT",
            order_uuid: "45c6e73f-a050-4b42-aa8b-0f54df2aba5d",
            price: 70233.21,
            price_sources: [],
            processed_ms: 1711417994061,
          },
          {
            leverage: -0.02734375,
            order_type: "SHORT",
            order_uuid: "28e3497e-f066-425b-a609-57c022e7af2e",
            price: 70257.69,
            price_sources: [],
            processed_ms: 1711419806456,
          },
          {
            leverage: -0.013671875,
            order_type: "SHORT",
            order_uuid: "c29390df-2e85-4640-a6ab-f34938429f2b",
            price: 70371.38,
            price_sources: [],
            processed_ms: 1711421638179,
          },
          {
            leverage: -0.0068359375,
            order_type: "SHORT",
            order_uuid: "7771f34e-3e76-4ff5-919f-12064cd89aa5",
            price: 70493.83,
            price_sources: [],
            processed_ms: 1711423438852,
          },
          {
            leverage: -0.00341796875,
            order_type: "SHORT",
            order_uuid: "7357438d-5809-444e-b2ec-69d2100f61ca",
            price: 70536.05,
            price_sources: [],
            processed_ms: 1711425266300,
          },
          {
            leverage: -0.001708984375,
            order_type: "SHORT",
            order_uuid: "cf1259c7-caed-4822-9f5e-6807d957e012",
            price: 70533.43,
            price_sources: [],
            processed_ms: 1711427100817,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "e5fe38d5-f0d2-45cb-9edd-290ca2058867",
            price: 70575.26,
            price_sources: [],
            processed_ms: 1711428900893,
          },
        ],
        position_type: "FLAT",
        position_uuid: "05985152-4afd-4a22-ac6c-7b8332deecee",
        return_at_close: 0.985648339627886,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: -362043.26363635086,
        close_ms: 1711457979387,
        current_return: 1.0082533187087508,
        initial_entry_price: 70457.47,
        is_closed_position: true,
        miner_hotkey: "5GUKCpZi73ikDgiHadDQ38GPUs4ZzifAyooa2pWfeaHdbeND",
        net_leverage: 0.0,
        open_ms: 1711430712914,
        orders: [
          {
            leverage: 1.25,
            order_type: "LONG",
            order_uuid: "e876d3f0-5a2b-4eaf-bee1-6acb1da0ebc1",
            price: 70457.47,
            price_sources: [],
            processed_ms: 1711430712913,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "7f076f8c-bf2f-4d41-8dcc-979c4dbbf6dd",
            price: 70430.82,
            price_sources: [],
            processed_ms: 1711432537813,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "fadd2162-3e3d-4100-b5d3-3160ee642674",
            price: 70371.64,
            price_sources: [],
            processed_ms: 1711434348080,
          },
          {
            leverage: -0.875,
            order_type: "SHORT",
            order_uuid: "3ecda7d2-9943-475b-a00a-39c151986179",
            price: 70468.05,
            price_sources: [],
            processed_ms: 1711436157963,
          },
          {
            leverage: -0.4375,
            order_type: "SHORT",
            order_uuid: "9e87e7d1-e5f8-432c-ac87-74c43a6e5cc9",
            price: 71319.23,
            price_sources: [],
            processed_ms: 1711437985597,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "a65dcbea-b107-4b59-8186-b46534fdd8a4",
            price: 70559.09,
            price_sources: [],
            processed_ms: 1711439799953,
          },
          {
            leverage: -0.34375,
            order_type: "SHORT",
            order_uuid: "58f85192-86ec-475d-b90e-599b092d585a",
            price: 70670.45,
            price_sources: [],
            processed_ms: 1711441611803,
          },
          {
            leverage: -0.171875,
            order_type: "SHORT",
            order_uuid: "1a27a5b4-b0ac-4d66-9986-4e0d0f3dc9b6",
            price: 70572.28,
            price_sources: [],
            processed_ms: 1711443449355,
          },
          {
            leverage: -0.0859375,
            order_type: "SHORT",
            order_uuid: "a11092c1-a7a3-4e57-8917-a187683d7357",
            price: 71058.78,
            price_sources: [],
            processed_ms: 1711445246972,
          },
          {
            leverage: -0.04296875,
            order_type: "SHORT",
            order_uuid: "89bc3636-f9a5-4cab-970e-31098f3c16cf",
            price: 71124.1,
            price_sources: [],
            processed_ms: 1711447068120,
          },
          {
            leverage: -0.021484375,
            order_type: "SHORT",
            order_uuid: "0c9e2bc7-ef0e-453c-ba6f-64c332e61a70",
            price: 70936.34,
            price_sources: [],
            processed_ms: 1711448926916,
          },
          {
            leverage: -0.0107421875,
            order_type: "SHORT",
            order_uuid: "7fd4d879-7888-45e0-8f65-9e04e8272ad0",
            price: 70970.0,
            price_sources: [],
            processed_ms: 1711450696793,
          },
          {
            leverage: -0.00537109375,
            order_type: "SHORT",
            order_uuid: "c2bd868f-a2df-466b-aa63-a7e8f2a00efb",
            price: 70762.73,
            price_sources: [],
            processed_ms: 1711452510261,
          },
          {
            leverage: -0.002685546875,
            order_type: "SHORT",
            order_uuid: "98ab5719-73c2-4a22-953b-6aa8edc84b2e",
            price: 70670.55,
            price_sources: [],
            processed_ms: 1711454329058,
          },
          {
            leverage: -0.0013427734375,
            order_type: "SHORT",
            order_uuid: "f5784d04-ce84-417e-98a3-2a0868ed7255",
            price: 70820.96,
            price_sources: [],
            processed_ms: 1711456143636,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "bfe3a4f2-e135-48f9-adb3-50385b8b1678",
            price: 71021.57,
            price_sources: [],
            processed_ms: 1711457979387,
          },
        ],
        position_type: "FLAT",
        position_uuid: "7b0caf9a-6a84-40d6-8e38-10507404027e",
        return_at_close: 1.0047244320932702,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: -40992.847006604134,
        close_ms: 1711633975157,
        current_return: 1.0396148239998317,
        initial_entry_price: 70810.59,
        is_closed_position: true,
        miner_hotkey: "5GUKCpZi73ikDgiHadDQ38GPUs4ZzifAyooa2pWfeaHdbeND",
        net_leverage: 0.0,
        open_ms: 1711459770856,
        orders: [
          {
            leverage: 1.25,
            order_type: "LONG",
            order_uuid: "8011c1d2-a043-42a9-9690-731438a7e9c4",
            price: 70810.59,
            price_sources: [],
            processed_ms: 1711459770854,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "02d3179c-73e3-43d8-8426-e62a2ca9c343",
            price: 70431.78,
            price_sources: [],
            processed_ms: 1711461594059,
          },
          {
            leverage: -0.75,
            order_type: "SHORT",
            order_uuid: "8937981e-b051-4bc5-a1d0-cd12e80aa5ff",
            price: 69870.89,
            price_sources: [],
            processed_ms: 1711463409189,
          },
          {
            leverage: -0.375,
            order_type: "SHORT",
            order_uuid: "216249f6-4291-421f-adbc-207e6fbe8fce",
            price: 70333.5,
            price_sources: [],
            processed_ms: 1711465225975,
          },
          {
            leverage: -0.1875,
            order_type: "SHORT",
            order_uuid: "ef320b72-0ab0-49ba-975f-9ba8b8bc27dc",
            price: 70167.21,
            price_sources: [],
            processed_ms: 1711467059724,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "a9979431-14e6-4b21-929f-1016340ce3c4",
            price: 70273.13,
            price_sources: [],
            processed_ms: 1711468859872,
          },
          {
            leverage: -0.21875,
            order_type: "SHORT",
            order_uuid: "ef3ab1d6-d64e-46f1-a233-f8b973391d7a",
            price: 69899.57,
            price_sources: [],
            processed_ms: 1711470674014,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "e526581b-92c9-4a5b-9984-f430950fe6da",
            price: 69814.39,
            price_sources: [],
            processed_ms: 1711472514046,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "49f824bb-bef8-41bc-ae14-3e2558124dd1",
            price: 70027.32,
            price_sources: [],
            processed_ms: 1711474306494,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "a8718d1b-9597-4857-9cea-6dba4ca92434",
            price: 70070.99,
            price_sources: [],
            processed_ms: 1711476129641,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "c493ee49-2bf2-4f8f-bedd-6dbda14dd3d9",
            price: 70233.37,
            price_sources: [],
            processed_ms: 1711477952899,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "9b3318db-dbfa-44ad-aa8a-e5278717ca29",
            price: 70062.04,
            price_sources: [],
            processed_ms: 1711479757980,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "8514f7b2-e2e1-455b-887a-3cbd6daaf13b",
            price: 69804.68,
            price_sources: [],
            processed_ms: 1711481574517,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "cddf602c-d788-416b-82fe-e54c919eb0c8",
            price: 69566.17,
            price_sources: [],
            processed_ms: 1711483410440,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "4ee6acb4-c21c-4b26-a413-ec47ee3b7514",
            price: 69851.85,
            price_sources: [],
            processed_ms: 1711485209965,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "0ce1dccf-f35c-4bd2-8f13-815f5bc4cf74",
            price: 69890.04,
            price_sources: [],
            processed_ms: 1711487020061,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "b0188f68-c46d-4c0f-b601-77567ae2081c",
            price: 70168.93,
            price_sources: [],
            processed_ms: 1711488848932,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "355f6a93-9b98-4df8-bcc8-579316bae615",
            price: 69998.74,
            price_sources: [],
            processed_ms: 1711490667805,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "a8796ea2-9b0c-41c7-88cb-4efbc8ac94da",
            price: 69939.92,
            price_sources: [],
            processed_ms: 1711492472161,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "0ed98289-4a6c-4071-8233-e8ef3c4af4d9",
            price: 70248.22,
            price_sources: [],
            processed_ms: 1711494299663,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "61727d06-07ee-4d8c-85b1-98732d181a56",
            price: 70059.89,
            price_sources: [],
            processed_ms: 1711496111557,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "350c9c35-94db-409a-88cc-90ab3af0543d",
            price: 70022.73,
            price_sources: [],
            processed_ms: 1711497918111,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "90d05ff5-410c-4279-9719-523423511ab4",
            price: 70227.77,
            price_sources: [],
            processed_ms: 1711499751708,
          },
          {
            leverage: -2.109375,
            order_type: "SHORT",
            order_uuid: "037fb5a0-346c-4f2b-8ec6-22a6e3f818d9",
            price: 70498.08,
            price_sources: [],
            processed_ms: 1711501568931,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "8ba0ca7b-4267-4dd0-a813-aac9d0a35125",
            price: 70533.28,
            price_sources: [],
            processed_ms: 1711503379109,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "503faabe-ee4b-4f44-9a95-9758f0e8ac70",
            price: 70222.27,
            price_sources: [],
            processed_ms: 1711505195111,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "c15d87de-a01c-4a1c-b8dc-043d43a6db97",
            price: 70392.22,
            price_sources: [],
            processed_ms: 1711507020448,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "1e692793-6bd9-44f2-a2cb-6de5b9c814fc",
            price: 70605.29,
            price_sources: [],
            processed_ms: 1711508844771,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "54c9dc64-730f-4d73-a839-ae568bbafe67",
            price: 70424.26,
            price_sources: [],
            processed_ms: 1711510650048,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "be1ce127-cf2a-4222-af31-df1d55f4c911",
            price: 70489.47,
            price_sources: [],
            processed_ms: 1711512508628,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "5da48154-c06f-48fb-b007-b57d13f32803",
            price: 70305.68,
            price_sources: [],
            processed_ms: 1711514280858,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "4ef5efc5-8945-4a15-b2b1-17739bddd2cd",
            price: 70232.47,
            price_sources: [],
            processed_ms: 1711516095034,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "b901cbec-c064-419b-bc33-af6e7debe357",
            price: 70468.34,
            price_sources: [],
            processed_ms: 1711517909083,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "70bb5cb7-8f44-4e79-9e9e-8751f34c0836",
            price: 70485.12,
            price_sources: [],
            processed_ms: 1711519772243,
          },
          {
            leverage: -2.3046875,
            order_type: "SHORT",
            order_uuid: "d6b2b260-20a9-4e81-9447-77f2bad40eb8",
            price: 70289.09,
            price_sources: [],
            processed_ms: 1711521548684,
          },
          {
            leverage: -1.15234375,
            order_type: "SHORT",
            order_uuid: "76293af9-c2d7-4a13-93fd-cd9b59a90a74",
            price: 69899.06,
            price_sources: [],
            processed_ms: 1711527045778,
          },
          {
            leverage: -0.576171875,
            order_type: "SHORT",
            order_uuid: "6b142b58-6f2c-450c-acc3-f9214a702ad0",
            price: 69811.89,
            price_sources: [],
            processed_ms: 1711528849959,
          },
          {
            leverage: 0.375,
            order_type: "LONG",
            order_uuid: "61dc8f74-cd2e-4ca2-aded-aecf06c51c14",
            price: 69731.47,
            price_sources: [],
            processed_ms: 1711530691764,
          },
          {
            leverage: 0.375,
            order_type: "LONG",
            order_uuid: "1a943779-fafa-4828-8e17-e54fc1516487",
            price: 69877.93,
            price_sources: [],
            processed_ms: 1711532481187,
          },
          {
            leverage: 0.375,
            order_type: "LONG",
            order_uuid: "b5bddf3e-19f9-4d61-a164-89624c5892d0",
            price: 69981.17,
            price_sources: [],
            processed_ms: 1711534297192,
          },
          {
            leverage: 0.375,
            order_type: "LONG",
            order_uuid: "08f441d7-57d3-4cb2-8536-74a80587efb1",
            price: 70059.04,
            price_sources: [],
            processed_ms: 1711536225125,
          },
          {
            leverage: 0.375,
            order_type: "LONG",
            order_uuid: "a5823026-f10c-46d8-b5de-526c1fc692f3",
            price: 70286.95,
            price_sources: [],
            processed_ms: 1711539832998,
          },
          {
            leverage: 0.375,
            order_type: "LONG",
            order_uuid: "d88b93f2-bbbc-4dcc-8ece-9515ead4a625",
            price: 70054.52,
            price_sources: [],
            processed_ms: 1711543466742,
          },
          {
            leverage: -1.4130859375,
            order_type: "SHORT",
            order_uuid: "3b33a1a0-26d0-4141-a950-e55cb0f11b8d",
            price: 70479.99,
            price_sources: [],
            processed_ms: 1711547086840,
          },
          {
            leverage: -0.70654296875,
            order_type: "SHORT",
            order_uuid: "9ed465ce-39d1-4e26-8f73-2115edf56167",
            price: 69923.63,
            price_sources: [],
            processed_ms: 1711550716795,
          },
          {
            leverage: -0.353271484375,
            order_type: "SHORT",
            order_uuid: "10962dbd-9849-4c7a-a2d1-0b6e23b011fc",
            price: 69110.73,
            price_sources: [],
            processed_ms: 1711554330162,
          },
          {
            leverage: -0.1766357421875,
            order_type: "SHORT",
            order_uuid: "300d2107-f83a-4b82-96ae-981774215960",
            price: 69168.0,
            price_sources: [],
            processed_ms: 1711557942321,
          },
          {
            leverage: -0.08831787109375,
            order_type: "SHORT",
            order_uuid: "81f31d67-7182-44e4-88ad-70edd65fd86e",
            price: 69098.9,
            price_sources: [],
            processed_ms: 1711561571962,
          },
          {
            leverage: 0.375,
            order_type: "LONG",
            order_uuid: "3545f993-3bdd-4689-b2f6-15e084760791",
            price: 68748.2,
            price_sources: [],
            processed_ms: 1711565189795,
          },
          {
            leverage: 0.375,
            order_type: "LONG",
            order_uuid: "1b20757d-b761-48f4-9144-b9132a8ecd83",
            price: 68529.91,
            price_sources: [],
            processed_ms: 1711568821999,
          },
          {
            leverage: -0.419158935546875,
            order_type: "SHORT",
            order_uuid: "c9a990cc-19ec-49df-a56d-c056e6a50a78",
            price: 68936.45,
            price_sources: [],
            processed_ms: 1711572441962,
          },
          {
            leverage: -0.2095794677734375,
            order_type: "SHORT",
            order_uuid: "6458ee31-41e7-4c8d-8f28-0842c6dcecbe",
            price: 69103.95,
            price_sources: [],
            processed_ms: 1711576056013,
          },
          {
            leverage: 0.375,
            order_type: "LONG",
            order_uuid: "d170eaa3-a5d9-469a-88ce-e5798ff00182",
            price: 69355.27,
            price_sources: [],
            processed_ms: 1711579664183,
          },
          {
            leverage: 0.375,
            order_type: "LONG",
            order_uuid: "1ff4dc7a-323e-4fdf-ae24-d11a21bff318",
            price: 69175.97,
            price_sources: [],
            processed_ms: 1711583299330,
          },
          {
            leverage: 0.375,
            order_type: "LONG",
            order_uuid: "4fc93d14-3641-4ab7-8f3f-392008c444c9",
            price: 69743.34,
            price_sources: [],
            processed_ms: 1711586913998,
          },
          {
            leverage: 0.375,
            order_type: "LONG",
            order_uuid: "4996dfe0-3674-465d-8635-8ad853de4747",
            price: 69469.52,
            price_sources: [],
            processed_ms: 1711590559190,
          },
          {
            leverage: 0.375,
            order_type: "LONG",
            order_uuid: "6656ef0b-167d-468a-a2b9-1e09c7530b9a",
            price: 69074.87,
            price_sources: [],
            processed_ms: 1711594152771,
          },
          {
            leverage: 0.375,
            order_type: "LONG",
            order_uuid: "691d86c8-ee2f-4526-8d93-b6c579bf6988",
            price: 69076.93,
            price_sources: [],
            processed_ms: 1711597769619,
          },
          {
            leverage: 0.375,
            order_type: "LONG",
            order_uuid: "4905bcb2-a91f-416e-8389-e6280fc052cb",
            price: 69593.39,
            price_sources: [],
            processed_ms: 1711601385495,
          },
          {
            leverage: 0.375,
            order_type: "LONG",
            order_uuid: "435cc847-7238-4d88-96bc-09c0e0445bb4",
            price: 69672.39,
            price_sources: [],
            processed_ms: 1711605004670,
          },
          {
            leverage: -1.6047897338867188,
            order_type: "SHORT",
            order_uuid: "b819b507-2c91-40a9-8e3b-ab23afabe3e8",
            price: 70210.88,
            price_sources: [],
            processed_ms: 1711608615138,
          },
          {
            leverage: -0.8023948669433594,
            order_type: "SHORT",
            order_uuid: "d7044396-a447-4b17-9c4d-b25c3db49627",
            price: 70380.73,
            price_sources: [],
            processed_ms: 1711612238990,
          },
          {
            leverage: -0.4011974334716797,
            order_type: "SHORT",
            order_uuid: "49959e7f-733e-4b9e-b51b-3d97d0013994",
            price: 70782.73,
            price_sources: [],
            processed_ms: 1711615846606,
          },
          {
            leverage: -0.20059871673583984,
            order_type: "SHORT",
            order_uuid: "f0579925-9db9-49ba-b367-619a413b5914",
            price: 70638.76,
            price_sources: [],
            processed_ms: 1711619468819,
          },
          {
            leverage: -0.10029935836791992,
            order_type: "SHORT",
            order_uuid: "47371a12-fccc-4209-93b7-9bb4daafe315",
            price: 70607.65,
            price_sources: [],
            processed_ms: 1711623101783,
          },
          {
            leverage: -0.05014967918395996,
            order_type: "SHORT",
            order_uuid: "22958106-b2ca-4d0c-8c9a-f3cdb88c23af",
            price: 70651.6,
            price_sources: [],
            processed_ms: 1711626731526,
          },
          {
            leverage: -0.02507483959197998,
            order_type: "SHORT",
            order_uuid: "6f08a421-8cd8-4f92-92ad-8d1a0a7ed25d",
            price: 70438.61,
            price_sources: [],
            processed_ms: 1711630347950,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "d793b4c4-c5db-4230-ac72-13a82447bd6b",
            price: 70878.22,
            price_sources: [],
            processed_ms: 1711633975157,
          },
        ],
        position_type: "FLAT",
        position_uuid: "65403f65-bda1-4ab8-b503-b1112a8a9087",
        return_at_close: 1.0300308748410831,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 70800.3854054054,
        close_ms: 1711726034859,
        current_return: 0.9683079971626617,
        initial_entry_price: 70418.1,
        is_closed_position: true,
        miner_hotkey: "5GUKCpZi73ikDgiHadDQ38GPUs4ZzifAyooa2pWfeaHdbeND",
        net_leverage: 0.0,
        open_ms: 1711652057394,
        orders: [
          {
            leverage: 1.25,
            order_type: "LONG",
            order_uuid: "d2c8ec7d-9405-4182-a4ea-0e335f51d4f8",
            price: 70418.1,
            price_sources: [],
            processed_ms: 1711652057391,
          },
          {
            leverage: -0.625,
            order_type: "SHORT",
            order_uuid: "8a3b23c2-ca7b-494f-a27a-415a4ec8d38e",
            price: 70768.7,
            price_sources: [],
            processed_ms: 1711655667183,
          },
          {
            leverage: 0.375,
            order_type: "LONG",
            order_uuid: "116477b8-37e5-443f-8504-bb1f4530bd84",
            price: 70712.55,
            price_sources: [],
            processed_ms: 1711659284994,
          },
          {
            leverage: 0.375,
            order_type: "LONG",
            order_uuid: "fcd89ed4-2709-4e5b-b64e-bf54251a5389",
            price: 70781.95,
            price_sources: [],
            processed_ms: 1711662900095,
          },
          {
            leverage: 0.375,
            order_type: "LONG",
            order_uuid: "d249c257-0fcd-4b3a-9a6a-41df56ef799a",
            price: 70882.73,
            price_sources: [],
            processed_ms: 1711666519032,
          },
          {
            leverage: 0.375,
            order_type: "LONG",
            order_uuid: "27c29751-7b51-43c8-afcd-fbe8f2f95e64",
            price: 70727.13,
            price_sources: [],
            processed_ms: 1711670151086,
          },
          {
            leverage: 0.375,
            order_type: "LONG",
            order_uuid: "a683bec9-f30c-485f-ae9d-3fd4cfa39693",
            price: 70677.27,
            price_sources: [],
            processed_ms: 1711673762353,
          },
          {
            leverage: 0.375,
            order_type: "LONG",
            order_uuid: "fca42fe9-6ec4-45c2-b186-1f812ad46b65",
            price: 70788.76,
            price_sources: [],
            processed_ms: 1711677426603,
          },
          {
            leverage: 0.375,
            order_type: "LONG",
            order_uuid: "d4fa5f05-9da9-44f2-94e1-7abceddc7ee6",
            price: 70671.74,
            price_sources: [],
            processed_ms: 1711680991923,
          },
          {
            leverage: 0.375,
            order_type: "LONG",
            order_uuid: "efc8e0a6-8b79-4466-b5e1-f092624001c2",
            price: 70456.0,
            price_sources: [],
            processed_ms: 1711684607546,
          },
          {
            leverage: 0.375,
            order_type: "LONG",
            order_uuid: "e8b30b76-00a2-45ba-a2b1-b5812cc57ee6",
            price: 70321.18,
            price_sources: [],
            processed_ms: 1711688225254,
          },
          {
            leverage: -2.0,
            order_type: "SHORT",
            order_uuid: "399a16e0-8d1f-4d72-ae22-1221fdad382f",
            price: 70242.36,
            price_sources: [],
            processed_ms: 1711691849341,
          },
          {
            leverage: -1.0,
            order_type: "SHORT",
            order_uuid: "109b5eff-183f-41dd-a930-2c31e99b2d9a",
            price: 70460.13,
            price_sources: [],
            processed_ms: 1711695459052,
          },
          {
            leverage: -0.5,
            order_type: "SHORT",
            order_uuid: "f3a7132c-766f-4b76-b8f1-306791842b45",
            price: 69933.7,
            price_sources: [],
            processed_ms: 1711699073099,
          },
          {
            leverage: 0.375,
            order_type: "LONG",
            order_uuid: "e13f8316-c0d7-4f94-9ac0-efa6f986e369",
            price: 69958.23,
            price_sources: [],
            processed_ms: 1711702714899,
          },
          {
            leverage: -0.4375,
            order_type: "SHORT",
            order_uuid: "5cd925bc-0a75-498d-9dbc-6d5bde309eb1",
            price: 69743.98,
            price_sources: [],
            processed_ms: 1711706314194,
          },
          {
            leverage: 0.375,
            order_type: "LONG",
            order_uuid: "d3090e3d-251c-4108-98c2-8988e0a3fad2",
            price: 70126.92,
            price_sources: [],
            processed_ms: 1711709934841,
          },
          {
            leverage: 0.375,
            order_type: "LONG",
            order_uuid: "31d1c4d1-67e0-46b8-a64b-b442ac5b18dd",
            price: 70173.13,
            price_sources: [],
            processed_ms: 1711713558037,
          },
          {
            leverage: 0.375,
            order_type: "LONG",
            order_uuid: "462fcb81-5591-4e49-98e4-a6d57cf85d11",
            price: 70157.06,
            price_sources: [],
            processed_ms: 1711717186328,
          },
          {
            leverage: 0.375,
            order_type: "LONG",
            order_uuid: "370125ac-2019-42cd-8879-056dce970f39",
            price: 70452.27,
            price_sources: [],
            processed_ms: 1711720807521,
          },
          {
            leverage: 0.375,
            order_type: "LONG",
            order_uuid: "1083e73b-ba61-493f-8815-57ac73ec1155",
            price: 70068.8,
            price_sources: [],
            processed_ms: 1711724425628,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "9767155c-f021-4c62-a3a7-4ca4c4ac6934",
            price: 69835.33,
            price_sources: [],
            processed_ms: 1711726034859,
          },
        ],
        position_type: "FLAT",
        position_uuid: "d26821a7-eac1-427b-bc9f-82fa669f4a69",
        return_at_close: 0.9605615331853604,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 64750.13000000031,
        close_ms: 1711749770112,
        current_return: 0.9972771409894153,
        initial_entry_price: 69213.21,
        is_closed_position: true,
        miner_hotkey: "5GUKCpZi73ikDgiHadDQ38GPUs4ZzifAyooa2pWfeaHdbeND",
        net_leverage: 0.0,
        open_ms: 1711728047811,
        orders: [
          {
            leverage: -1.25,
            order_type: "SHORT",
            order_uuid: "82091afc-bf95-4332-a7c9-b9e297dd156b",
            price: 69213.21,
            price_sources: [],
            processed_ms: 1711728047808,
          },
          {
            leverage: 0.625,
            order_type: "LONG",
            order_uuid: "db58549e-3045-4a95-95a0-219fcedad15c",
            price: 69206.13,
            price_sources: [],
            processed_ms: 1711731655341,
          },
          {
            leverage: 0.3125,
            order_type: "LONG",
            order_uuid: "6784a83d-526d-4dc4-8210-084e4a20092a",
            price: 69440.17,
            price_sources: [],
            processed_ms: 1711735288329,
          },
          {
            leverage: 0.15625,
            order_type: "LONG",
            order_uuid: "ac007c7f-8a3e-411e-998e-1ac96ae79bd0",
            price: 69544.04,
            price_sources: [],
            processed_ms: 1711738949855,
          },
          {
            leverage: 0.078125,
            order_type: "LONG",
            order_uuid: "71a1fc8a-ff62-4b77-bd46-e5f9453d726f",
            price: 69728.67,
            price_sources: [],
            processed_ms: 1711742538167,
          },
          {
            leverage: 0.0390625,
            order_type: "LONG",
            order_uuid: "8c5b15a9-4820-4616-a488-ac2c9551a1cf",
            price: 69619.65,
            price_sources: [],
            processed_ms: 1711746154300,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "0aca1932-ddfc-4dba-b395-2f4addfd39df",
            price: 69574.65,
            price_sources: [],
            processed_ms: 1711749770112,
          },
        ],
        position_type: "FLAT",
        position_uuid: "3b139532-6907-44be-92a4-fe9299b8bdbe",
        return_at_close: 0.9947839481369418,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 71462.78796121733,
        close_ms: 1711876433819,
        current_return: 0.9994625404249151,
        initial_entry_price: 69849.16,
        is_closed_position: true,
        miner_hotkey: "5GUKCpZi73ikDgiHadDQ38GPUs4ZzifAyooa2pWfeaHdbeND",
        net_leverage: 0.0,
        open_ms: 1711753381020,
        orders: [
          {
            leverage: 1.25,
            order_type: "LONG",
            order_uuid: "b3efb488-49a9-48ac-a99a-ef3698c706dd",
            price: 69849.16,
            price_sources: [],
            processed_ms: 1711753381018,
          },
          {
            leverage: 0.375,
            order_type: "LONG",
            order_uuid: "59215dca-bb93-465b-be71-26a2224a0e10",
            price: 69843.44,
            price_sources: [],
            processed_ms: 1711757057540,
          },
          {
            leverage: 0.375,
            order_type: "LONG",
            order_uuid: "d2280643-dfc3-49dc-92ce-f1cd04d4c74c",
            price: 69990.29,
            price_sources: [],
            processed_ms: 1711760649608,
          },
          {
            leverage: 0.375,
            order_type: "LONG",
            order_uuid: "fc95500d-ea87-4f26-a9f0-68a43c22035e",
            price: 70000.94,
            price_sources: [],
            processed_ms: 1711764254803,
          },
          {
            leverage: 0.375,
            order_type: "LONG",
            order_uuid: "c5d783c3-9d7e-4f43-b2c9-907868f143f4",
            price: 69969.95,
            price_sources: [],
            processed_ms: 1711767871316,
          },
          {
            leverage: 0.375,
            order_type: "LONG",
            order_uuid: "5a2eb65e-1de9-4bdc-a22d-0c3b9efaccd7",
            price: 69837.64,
            price_sources: [],
            processed_ms: 1711771527499,
          },
          {
            leverage: 0.375,
            order_type: "LONG",
            order_uuid: "b9e78e37-c85f-473b-9459-274e15703ea6",
            price: 70343.29,
            price_sources: [],
            processed_ms: 1711775101581,
          },
          {
            leverage: 0.375,
            order_type: "LONG",
            order_uuid: "3cc4720f-1e41-42e9-abdb-1364d01ad8b0",
            price: 69996.36,
            price_sources: [],
            processed_ms: 1711778720176,
          },
          {
            leverage: 0.375,
            order_type: "LONG",
            order_uuid: "0f53a948-0385-4c43-8148-6d4b0540c0dd",
            price: 70069.14,
            price_sources: [],
            processed_ms: 1711782337126,
          },
          {
            leverage: 0.375,
            order_type: "LONG",
            order_uuid: "ef4c73ae-72dd-41b3-9bb4-9ba4e0bbad75",
            price: 69921.58,
            price_sources: [],
            processed_ms: 1711785974573,
          },
          {
            leverage: -2.3125,
            order_type: "SHORT",
            order_uuid: "9fa90a4e-24cb-4f23-8fdf-621ddedb122f",
            price: 69970.15,
            price_sources: [],
            processed_ms: 1711789590013,
          },
          {
            leverage: 0.375,
            order_type: "LONG",
            order_uuid: "7ec78b99-e5de-46f0-9559-43f3811e9e8f",
            price: 70036.83,
            price_sources: [],
            processed_ms: 1711793205272,
          },
          {
            leverage: -1.34375,
            order_type: "SHORT",
            order_uuid: "923f9df9-1cbe-4e75-a7d2-510bbb0771dc",
            price: 70137.79,
            price_sources: [],
            processed_ms: 1711796823906,
          },
          {
            leverage: -0.671875,
            order_type: "SHORT",
            order_uuid: "f113acb1-bcb5-409b-a11f-bb065dc217d6",
            price: 70194.02,
            price_sources: [],
            processed_ms: 1711800434712,
          },
          {
            leverage: -0.3359375,
            order_type: "SHORT",
            order_uuid: "0ba27b9e-6fa8-470e-9754-aec99ba4c28c",
            price: 70241.78,
            price_sources: [],
            processed_ms: 1711804052824,
          },
          {
            leverage: 0.375,
            order_type: "LONG",
            order_uuid: "16050150-62c1-4d26-a58e-48c712b4f7e1",
            price: 70170.08,
            price_sources: [],
            processed_ms: 1711807664668,
          },
          {
            leverage: 0.375,
            order_type: "LONG",
            order_uuid: "88ae2f11-691c-4955-b7fe-acfdd0ec7650",
            price: 70153.59,
            price_sources: [],
            processed_ms: 1711811284662,
          },
          {
            leverage: 0.375,
            order_type: "LONG",
            order_uuid: "eeac146b-d7ae-4dcc-b8d6-dc676028b7b1",
            price: 70032.2,
            price_sources: [],
            processed_ms: 1711814906710,
          },
          {
            leverage: 0.375,
            order_type: "LONG",
            order_uuid: "e0a00f1a-0987-42c2-a658-14e4cce452b2",
            price: 70182.05,
            price_sources: [],
            processed_ms: 1711818515075,
          },
          {
            leverage: 0.375,
            order_type: "LONG",
            order_uuid: "21e3268c-054f-4f09-a696-4e76f1f42738",
            price: 70030.76,
            price_sources: [],
            processed_ms: 1711822136900,
          },
          {
            leverage: 0.375,
            order_type: "LONG",
            order_uuid: "930b1a3b-0c8f-4058-ac7b-380c2ee39161",
            price: 69942.78,
            price_sources: [],
            processed_ms: 1711825755214,
          },
          {
            leverage: 0.375,
            order_type: "LONG",
            order_uuid: "0abc9c30-7164-4b56-bd32-bf45744ffbc6",
            price: 69891.17,
            price_sources: [],
            processed_ms: 1711829377883,
          },
          {
            leverage: 0.375,
            order_type: "LONG",
            order_uuid: "d3fb14d8-a64a-4f99-a421-9f85aa58898a",
            price: 69823.44,
            price_sources: [],
            processed_ms: 1711832996799,
          },
          {
            leverage: 0.375,
            order_type: "LONG",
            order_uuid: "a7a30e3f-b688-4d58-9408-37412945a9ad",
            price: 69715.65,
            price_sources: [],
            processed_ms: 1711836716588,
          },
          {
            leverage: -1.85546875,
            order_type: "SHORT",
            order_uuid: "acae290d-7caf-487b-a080-df92e1d56151",
            price: 69751.98,
            price_sources: [],
            processed_ms: 1711840230361,
          },
          {
            leverage: -0.927734375,
            order_type: "SHORT",
            order_uuid: "461664e7-1ff4-4c61-b9dd-c1a71d927599",
            price: 69715.5,
            price_sources: [],
            processed_ms: 1711843856759,
          },
          {
            leverage: -0.4638671875,
            order_type: "SHORT",
            order_uuid: "bc373209-2067-41fa-acde-2007dc69ce9f",
            price: 70048.84,
            price_sources: [],
            processed_ms: 1711847482181,
          },
          {
            leverage: -0.23193359375,
            order_type: "SHORT",
            order_uuid: "5584bd61-6a04-4268-b5c7-88d13e494811",
            price: 70032.83,
            price_sources: [],
            processed_ms: 1711851101943,
          },
          {
            leverage: -0.115966796875,
            order_type: "SHORT",
            order_uuid: "22a938e7-63dd-4d10-941c-82fbc9056216",
            price: 69906.76,
            price_sources: [],
            processed_ms: 1711854733138,
          },
          {
            leverage: 0.375,
            order_type: "LONG",
            order_uuid: "7f3e63e0-ed56-4b36-881d-7ed6ece825a0",
            price: 69909.04,
            price_sources: [],
            processed_ms: 1711858345251,
          },
          {
            leverage: -0.2454833984375,
            order_type: "SHORT",
            order_uuid: "232f40d8-36d0-47f7-93d0-5f081b2203b3",
            price: 70108.46,
            price_sources: [],
            processed_ms: 1711861970478,
          },
          {
            leverage: -0.12274169921875,
            order_type: "SHORT",
            order_uuid: "7d19f86a-4ca0-4177-9d51-714793716525",
            price: 70293.61,
            price_sources: [],
            processed_ms: 1711865582718,
          },
          {
            leverage: -0.061370849609375,
            order_type: "SHORT",
            order_uuid: "ad64ee93-d047-4eea-9b4f-f42fb5c035bd",
            price: 70305.91,
            price_sources: [],
            processed_ms: 1711869207606,
          },
          {
            leverage: -0.0306854248046875,
            order_type: "SHORT",
            order_uuid: "9e2a5b96-b269-4312-8f3a-bb5ca4886ea8",
            price: 70315.41,
            price_sources: [],
            processed_ms: 1711872815375,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "e16eba9e-a998-4679-8453-b76c18987ba3",
            price: 70239.37,
            price_sources: [],
            processed_ms: 1711876433819,
          },
        ],
        position_type: "FLAT",
        position_uuid: "b5d13b60-3cc9-4d95-a179-8a613b8cee1e",
        return_at_close: 0.9902175119259846,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 71420.55051051479,
        close_ms: 1711993165666,
        current_return: 0.969950006977349,
        initial_entry_price: 70549.72,
        is_closed_position: true,
        miner_hotkey: "5GUKCpZi73ikDgiHadDQ38GPUs4ZzifAyooa2pWfeaHdbeND",
        net_leverage: 0.0,
        open_ms: 1711894531821,
        orders: [
          {
            leverage: 1.25,
            order_type: "LONG",
            order_uuid: "706e33db-d26a-4d1d-8a5a-7694618ff1a8",
            price: 70549.72,
            price_sources: [],
            processed_ms: 1711894531818,
          },
          {
            leverage: 0.375,
            order_type: "LONG",
            order_uuid: "4b6e9357-cbaf-464c-9cfb-d5d16620eeb7",
            price: 70425.36,
            price_sources: [],
            processed_ms: 1711898158049,
          },
          {
            leverage: 0.375,
            order_type: "LONG",
            order_uuid: "eeeff445-6d12-4798-bd42-eb6a300fafce",
            price: 70606.15,
            price_sources: [],
            processed_ms: 1711901796394,
          },
          {
            leverage: 0.375,
            order_type: "LONG",
            order_uuid: "daf5e515-6b7f-4913-8c19-cd2348767e4d",
            price: 70532.2,
            price_sources: [],
            processed_ms: 1711905408454,
          },
          {
            leverage: -1.1875,
            order_type: "SHORT",
            order_uuid: "ecbb87f0-e0a1-4ae0-9f53-6cf8e85a98a9",
            price: 70548.98,
            price_sources: [],
            processed_ms: 1711909023184,
          },
          {
            leverage: 0.375,
            order_type: "LONG",
            order_uuid: "a4b11e8d-b984-459f-9279-1b227e9fcab3",
            price: 70984.06,
            price_sources: [],
            processed_ms: 1711912639544,
          },
          {
            leverage: 0.375,
            order_type: "LONG",
            order_uuid: "05eb73a0-ea5a-47a6-bf50-1b7e6ed5dc1c",
            price: 71054.12,
            price_sources: [],
            processed_ms: 1711916258987,
          },
          {
            leverage: 0.375,
            order_type: "LONG",
            order_uuid: "1a4daef3-9000-4ec5-8874-76f8850cfe9e",
            price: 70932.45,
            price_sources: [],
            processed_ms: 1711919889507,
          },
          {
            leverage: 0.375,
            order_type: "LONG",
            order_uuid: "d53234da-09f8-42a3-9eed-a3b03bed06a6",
            price: 70889.1,
            price_sources: [],
            processed_ms: 1711923507430,
          },
          {
            leverage: 0.375,
            order_type: "LONG",
            order_uuid: "e5828cb3-6154-4471-a171-b1b9bd588014",
            price: 71089.47,
            price_sources: [],
            processed_ms: 1711927132058,
          },
          {
            leverage: 0.375,
            order_type: "LONG",
            order_uuid: "6c0bde2f-294b-46e4-a269-aef8ada08078",
            price: 71206.7,
            price_sources: [],
            processed_ms: 1711930792044,
          },
          {
            leverage: 0.375,
            order_type: "LONG",
            order_uuid: "c3453b69-492c-4654-a4e7-af335c2da961",
            price: 71026.26,
            price_sources: [],
            processed_ms: 1711934367176,
          },
          {
            leverage: -1.90625,
            order_type: "SHORT",
            order_uuid: "105e3ee9-a851-4375-b2fc-a039e822a834",
            price: 70863.06,
            price_sources: [],
            processed_ms: 1711937988671,
          },
          {
            leverage: 0.375,
            order_type: "LONG",
            order_uuid: "6e02bad7-47d5-434b-87ab-98530a16b13e",
            price: 70800.74,
            price_sources: [],
            processed_ms: 1711941615617,
          },
          {
            leverage: -1.140625,
            order_type: "SHORT",
            order_uuid: "73fd03a0-a186-4303-94e9-0443b499bd42",
            price: 70580.6,
            price_sources: [],
            processed_ms: 1711945235573,
          },
          {
            leverage: -0.5703125,
            order_type: "SHORT",
            order_uuid: "7e84824b-a162-47ec-b851-3d6b00cf1b40",
            price: 70465.6,
            price_sources: [],
            processed_ms: 1711948917356,
          },
          {
            leverage: -0.45625,
            order_type: "SHORT",
            order_uuid: "f07aa772-1569-4302-9103-2186b59bb1b1",
            price: 69428.0,
            price_sources: [],
            processed_ms: 1711952513400,
          },
          {
            leverage: -0.057031250000000006,
            order_type: "SHORT",
            order_uuid: "91e68d90-71e1-4ba0-9062-d6c62fbdebcd",
            price: 69729.8,
            price_sources: [],
            processed_ms: 1711956116498,
          },
          {
            leverage: -0.028515625000000003,
            order_type: "SHORT",
            order_uuid: "cf6ef601-6072-4c0c-9945-597298e88b43",
            price: 69382.3,
            price_sources: [],
            processed_ms: 1711959800309,
          },
          {
            leverage: 0.375,
            order_type: "LONG",
            order_uuid: "b0b4d5a3-8834-4e75-a350-8656e426ca46",
            price: 69528.5,
            price_sources: [],
            processed_ms: 1711963368163,
          },
          {
            leverage: -0.2017578125,
            order_type: "SHORT",
            order_uuid: "e5c2c26e-b17a-41f4-bd82-aed8c09aebf6",
            price: 69327.5,
            price_sources: [],
            processed_ms: 1711966990807,
          },
          {
            leverage: -0.10087890625,
            order_type: "SHORT",
            order_uuid: "9f116cd7-a981-4c97-bb54-ae0f98067422",
            price: 69674.4,
            price_sources: [],
            processed_ms: 1711970619337,
          },
          {
            leverage: 0.375,
            order_type: "LONG",
            order_uuid: "ffb9acb4-7e83-46a4-9876-b820f51e2ddf",
            price: 69710.4,
            price_sources: [],
            processed_ms: 1711974232545,
          },
          {
            leverage: -0.237939453125,
            order_type: "SHORT",
            order_uuid: "25175d83-7e55-4fc9-8e94-a06ebd6b359e",
            price: 70053.2,
            price_sources: [],
            processed_ms: 1711978305794,
          },
          {
            leverage: 0.375,
            order_type: "LONG",
            order_uuid: "3237ad9e-c651-4f41-b03b-79373b398057",
            price: 69448.0,
            price_sources: [],
            processed_ms: 1711981964451,
          },
          {
            leverage: -0.3064697265625,
            order_type: "SHORT",
            order_uuid: "a44d6b67-b17b-453b-b601-19c8c3afcbd7",
            price: 68540.0,
            price_sources: [],
            processed_ms: 1711986828566,
          },
          {
            leverage: 0.37500000000000006,
            order_type: "LONG",
            order_uuid: "535871e3-87ac-4fdb-bfd2-7df83ea9c335",
            price: 68722.5,
            price_sources: [],
            processed_ms: 1711990395626,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "91654c5b-b398-4a9f-a9bf-98199cf8da61",
            price: 68309.6,
            price_sources: [],
            processed_ms: 1711993165666,
          },
        ],
        position_type: "FLAT",
        position_uuid: "02b554a7-c58a-4f41-8912-7c6f97af59a0",
        return_at_close: 0.9625541381741467,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 87112.07368421051,
        close_ms: 1712038570435,
        current_return: 0.9720479541300717,
        initial_entry_price: 68611.4,
        is_closed_position: true,
        miner_hotkey: "5GUKCpZi73ikDgiHadDQ38GPUs4ZzifAyooa2pWfeaHdbeND",
        net_leverage: 0.0,
        open_ms: 1711995560015,
        orders: [
          {
            leverage: 1.25,
            order_type: "LONG",
            order_uuid: "ec005fc1-5c25-4a8d-8d65-2cd806b22383",
            price: 68611.4,
            price_sources: [],
            processed_ms: 1711995560011,
          },
          {
            leverage: -0.625,
            order_type: "SHORT",
            order_uuid: "4983cbb5-e063-4f17-a188-a49c20529988",
            price: 69235.8,
            price_sources: [],
            processed_ms: 1711999184037,
          },
          {
            leverage: 0.375,
            order_type: "LONG",
            order_uuid: "7271c82a-6bea-4882-8a9d-99c5130bc217",
            price: 69703.4,
            price_sources: [],
            processed_ms: 1712004789919,
          },
          {
            leverage: 0.375,
            order_type: "LONG",
            order_uuid: "cf266097-7b00-49cb-9977-8864ef2bfd5f",
            price: 69685.3,
            price_sources: [],
            processed_ms: 1712008407347,
          },
          {
            leverage: 0.375,
            order_type: "LONG",
            order_uuid: "48775513-a210-4af7-963b-28678eadbe43",
            price: 69813.4,
            price_sources: [],
            processed_ms: 1712014390445,
          },
          {
            leverage: -0.875,
            order_type: "SHORT",
            order_uuid: "67c084b2-662b-45f0-8670-cd7a3674160f",
            price: 69449.9,
            price_sources: [],
            processed_ms: 1712017766643,
          },
          {
            leverage: -0.65,
            order_type: "SHORT",
            order_uuid: "c622fa82-5140-4bbe-8c27-ca320cb95365",
            price: 66523.2,
            price_sources: [],
            processed_ms: 1712031306734,
          },
          {
            leverage: -0.12999999999999998,
            order_type: "SHORT",
            order_uuid: "a229f5cb-8edb-497e-9aa6-6554a41d592f",
            price: 66602.1,
            price_sources: [],
            processed_ms: 1712034968661,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "f6c75117-15ed-4691-8efd-4338fd333542",
            price: 66924.4,
            price_sources: [],
            processed_ms: 1712038570435,
          },
        ],
        position_type: "FLAT",
        position_uuid: "eb40980e-a6da-4655-9aa5-2b4e2236fd64",
        return_at_close: 0.9686457862906165,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 66090.2,
        close_ms: 1712049330821,
        current_return: 0.9948082317802034,
        initial_entry_price: 66090.2,
        is_closed_position: true,
        miner_hotkey: "5GUKCpZi73ikDgiHadDQ38GPUs4ZzifAyooa2pWfeaHdbeND",
        net_leverage: -1.25,
        open_ms: 1712049022829,
        orders: [
          {
            leverage: -1.25,
            order_type: "SHORT",
            order_uuid: "e0ea6ba4-1df4-49f3-b21c-5ccdc93f8f99",
            price: 66090.2,
            price_sources: [],
            processed_ms: 1712049022826,
          },
        ],
        position_type: "FLAT",
        position_uuid: "bc7e664a-4420-4568-a6f3-7dedd59e05ba",
        return_at_close: 0.9923212112007529,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
    ],
    thirty_day_returns: 1.0,
  },
  "5GVyVwjpxmQvVyDQQ4do18CEZU2vjhXixoPBACu7c2Vw1ShQ": {
    all_time_returns: 1.0375679011493462,
    n_positions: 119,
    percentage_profitable: 0.7983193277310925,
    positions: [
      {
        average_entry_price: 114.71806249999999,
        close_ms: 1718635998885,
        current_return: 1.000369868386647,
        initial_entry_price: 114.73,
        is_closed_position: true,
        miner_hotkey: "5GVyVwjpxmQvVyDQQ4do18CEZU2vjhXixoPBACu7c2Vw1ShQ",
        net_leverage: 0.0,
        open_ms: 1718635449379,
        orders: [
          {
            leverage: 0.531,
            order_type: "LONG",
            order_uuid: "1bc11341-667e-48bc-8451-dc9ed0e26722",
            price: 114.73,
            price_sources: [],
            processed_ms: 1718635449364,
          },
          {
            leverage: 0.573,
            order_type: "LONG",
            order_uuid: "8755399e-52bb-4f7c-b8f5-36d1bde3707c",
            price: 114.707,
            price_sources: [],
            processed_ms: 1718635714928,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "58bd6e25-1ccb-4e52-a4a9-dfcb5df43e45",
            price: 114.7565,
            price_sources: [],
            processed_ms: 1718635998885,
          },
        ],
        position_type: "FLAT",
        position_uuid: "854b296a-cf47-4941-991c-983803d4ab9a",
        return_at_close: 1.0002925598032182,
        trade_pair: ["CADJPY", "CAD/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 114.71274191374664,
        close_ms: 1718636893345,
        current_return: 1.0005658356787466,
        initial_entry_price: 114.763,
        is_closed_position: true,
        miner_hotkey: "5GVyVwjpxmQvVyDQQ4do18CEZU2vjhXixoPBACu7c2Vw1ShQ",
        net_leverage: 0.0,
        open_ms: 1718636028939,
        orders: [
          {
            leverage: 0.431,
            order_type: "LONG",
            order_uuid: "4f4154f5-52a4-4c99-8306-7850e5307cd9",
            price: 114.763,
            price_sources: [],
            processed_ms: 1718636028672,
          },
          {
            leverage: 0.531,
            order_type: "LONG",
            order_uuid: "f03df764-cf32-4781-9324-626b2f4c0dd0",
            price: 114.712,
            price_sources: [],
            processed_ms: 1718636072858,
          },
          {
            leverage: 0.522,
            order_type: "LONG",
            order_uuid: "b02603af-0330-4421-b690-bc8a5336d1a4",
            price: 114.672,
            price_sources: [],
            processed_ms: 1718636565287,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "2cda4b56-e6ce-4c12-a7e6-559e60e14f09",
            price: 114.7565,
            price_sources: [],
            processed_ms: 1718636893345,
          },
        ],
        position_type: "FLAT",
        position_uuid: "e904a31e-4e00-453b-b84a-73ada290eeb7",
        return_at_close: 1.0004618968997363,
        trade_pair: ["CADJPY", "CAD/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 114.74823529411766,
        close_ms: 1718643493441,
        current_return: 1.0006140801868295,
        initial_entry_price: 114.757,
        is_closed_position: true,
        miner_hotkey: "5GVyVwjpxmQvVyDQQ4do18CEZU2vjhXixoPBACu7c2Vw1ShQ",
        net_leverage: 0.0,
        open_ms: 1718636922860,
        orders: [
          {
            leverage: 0.471,
            order_type: "LONG",
            order_uuid: "450e0df4-3c83-472b-a876-2d0075e98952",
            price: 114.757,
            price_sources: [],
            processed_ms: 1718636922847,
          },
          {
            leverage: 0.447,
            order_type: "LONG",
            order_uuid: "54a11239-8223-49c5-80e2-2f904ed98b12",
            price: 114.739,
            price_sources: [],
            processed_ms: 1718636993156,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "aa1d751e-040f-4b03-9941-c6554b2f3e1b",
            price: 114.825,
            price_sources: [],
            processed_ms: 1718643493441,
          },
        ],
        position_type: "FLAT",
        position_uuid: "94cabaed-3f71-4783-a8af-00bf9ed2ef82",
        return_at_close: 1.0005497807260366,
        trade_pair: ["CADJPY", "CAD/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 114.8585243362832,
        close_ms: 1718659181788,
        current_return: 1.0002714146375449,
        initial_entry_price: 114.828,
        is_closed_position: true,
        miner_hotkey: "5GVyVwjpxmQvVyDQQ4do18CEZU2vjhXixoPBACu7c2Vw1ShQ",
        net_leverage: 0.0,
        open_ms: 1718643524000,
        orders: [
          {
            leverage: 0.466,
            order_type: "LONG",
            order_uuid: "f0da008b-9e60-4fa3-acd5-5b8cbd1f8060",
            price: 114.828,
            price_sources: [],
            processed_ms: 1718643523981,
          },
          {
            leverage: 0.438,
            order_type: "LONG",
            order_uuid: "7b7d2f68-d43c-4f5d-b6c5-80920b73cdaf",
            price: 114.891,
            price_sources: [],
            processed_ms: 1718659150579,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "ba573705-8552-4523-b03c-b908636f9bb4",
            price: 114.893,
            price_sources: [],
            processed_ms: 1718659181788,
          },
        ],
        position_type: "FLAT",
        position_uuid: "e508efbf-d811-482b-a3bb-a191efb87bfa",
        return_at_close: 1.0002081174624267,
        trade_pair: ["CADJPY", "CAD/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 114.89000000000001,
        close_ms: 1718664313192,
        current_return: 1.0005484637479327,
        initial_entry_price: 114.89,
        is_closed_position: true,
        miner_hotkey: "5GVyVwjpxmQvVyDQQ4do18CEZU2vjhXixoPBACu7c2Vw1ShQ",
        net_leverage: 0.0,
        open_ms: 1718659215792,
        orders: [
          {
            leverage: 0.522,
            order_type: "LONG",
            order_uuid: "1fa8f2dd-126b-4f8e-9582-9b9eeb5672f4",
            price: 114.89,
            price_sources: [],
            processed_ms: 1718659215670,
          },
          {
            leverage: 0.511,
            order_type: "LONG",
            order_uuid: "165e18bd-cf58-4446-83e8-caacc0240b3b",
            price: 114.89,
            price_sources: [],
            processed_ms: 1718659449850,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "099b2439-af74-46a6-ae94-ea86305ac3f9",
            price: 114.951,
            price_sources: [],
            processed_ms: 1718664313192,
          },
        ],
        position_type: "FLAT",
        position_uuid: "4a8b76b2-44d2-4d4f-a8fb-d5b1f39a73ed",
        return_at_close: 1.0004761140885192,
        trade_pair: ["CADJPY", "CAD/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 114.9234429097606,
        close_ms: 1718668684903,
        current_return: 1.0006182530335035,
        initial_entry_price: 114.945,
        is_closed_position: true,
        miner_hotkey: "5GVyVwjpxmQvVyDQQ4do18CEZU2vjhXixoPBACu7c2Vw1ShQ",
        net_leverage: 0.0,
        open_ms: 1718664342754,
        orders: [
          {
            leverage: 0.515,
            order_type: "LONG",
            order_uuid: "54f3f1ef-0fb4-4e68-b606-2729eff1fcbb",
            price: 114.945,
            price_sources: [],
            processed_ms: 1718664342741,
          },
          {
            leverage: 0.571,
            order_type: "LONG",
            order_uuid: "d958dc9a-9ee3-4792-bde7-a3eac02f1cec",
            price: 114.904,
            price_sources: [],
            processed_ms: 1718666267340,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "e44496cd-1e49-44e1-aa98-81d1e663c877",
            price: 114.9888803820774,
            price_sources: [],
            processed_ms: 1718668684903,
          },
        ],
        position_type: "FLAT",
        position_uuid: "1f45dab4-1b7e-45f2-8e32-06b73357e160",
        return_at_close: 1.0005421860339079,
        trade_pair: ["CADJPY", "CAD/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 114.96954673182651,
        close_ms: 1718670876369,
        current_return: 1.0003623469458747,
        initial_entry_price: 114.992,
        is_closed_position: true,
        miner_hotkey: "5GVyVwjpxmQvVyDQQ4do18CEZU2vjhXixoPBACu7c2Vw1ShQ",
        net_leverage: 0.0,
        open_ms: 1718668714759,
        orders: [
          {
            leverage: 0.459,
            order_type: "LONG",
            order_uuid: "c7144386-dfbd-4a32-9ace-dd26d985c74e",
            price: 114.992,
            price_sources: [],
            processed_ms: 1718668714706,
          },
          {
            leverage: 0.598,
            order_type: "LONG",
            order_uuid: "147ab32e-eba0-472f-b2d0-09f8497bb6cd",
            price: 114.98,
            price_sources: [],
            processed_ms: 1718668828942,
          },
          {
            leverage: 0.58,
            order_type: "LONG",
            order_uuid: "08fa8ca0-2073-44e7-b803-45b324ea56ee",
            price: 114.941,
            price_sources: [],
            processed_ms: 1718669377291,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "423a7c56-63d2-404c-87a7-ecef23b52e09",
            price: 114.995,
            price_sources: [],
            processed_ms: 1718670876369,
          },
        ],
        position_type: "FLAT",
        position_uuid: "5fc78a42-9239-4873-9da1-8f36973f3b64",
        return_at_close: 1.0002477154245382,
        trade_pair: ["CADJPY", "CAD/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 114.82813431583993,
        close_ms: 1718688697838,
        current_return: 1.000920611589942,
        initial_entry_price: 114.979,
        is_closed_position: true,
        miner_hotkey: "5GVyVwjpxmQvVyDQQ4do18CEZU2vjhXixoPBACu7c2Vw1ShQ",
        net_leverage: 0.0,
        open_ms: 1718670906750,
        orders: [
          {
            leverage: 0.598,
            order_type: "LONG",
            order_uuid: "1f4923f3-cf9a-455c-8619-09a6d4cb5008",
            price: 114.979,
            price_sources: [],
            processed_ms: 1718670906702,
          },
          {
            leverage: 0.455,
            order_type: "LONG",
            order_uuid: "f4861eed-b5f5-4223-8a41-e257c9a4eace",
            price: 114.943,
            price_sources: [],
            processed_ms: 1718671051487,
          },
          {
            leverage: 0.565,
            order_type: "LONG",
            order_uuid: "89ff1516-d1ae-4132-a683-5484804fe968",
            price: 114.87,
            price_sources: [],
            processed_ms: 1718672815764,
          },
          {
            leverage: 0.504,
            order_type: "LONG",
            order_uuid: "650c84a1-d338-4488-85b6-f9caf85c0a04",
            price: 114.859,
            price_sources: [],
            processed_ms: 1718673176187,
          },
          {
            leverage: 0.505,
            order_type: "LONG",
            order_uuid: "400fd1b4-1149-4ddb-aa0a-711e61cee629",
            price: 114.78049999999999,
            price_sources: [],
            processed_ms: 1718674821581,
          },
          {
            leverage: 0.595,
            order_type: "LONG",
            order_uuid: "d3e8ed93-3aef-490f-ab4e-7071f424dae2",
            price: 114.764,
            price_sources: [],
            processed_ms: 1718674860907,
          },
          {
            leverage: 0.447,
            order_type: "LONG",
            order_uuid: "8a4f27d1-3451-4a9c-9989-cac7fe923c40",
            price: 114.729,
            price_sources: [],
            processed_ms: 1718680012709,
          },
          {
            leverage: 0.504,
            order_type: "LONG",
            order_uuid: "1d13f847-ab6a-4ba9-a17f-4fdc14dab3d7",
            price: 114.679,
            price_sources: [],
            processed_ms: 1718684881862,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "fe70bdc6-a3cb-496a-a598-1847162367e7",
            price: 114.8535,
            price_sources: [],
            processed_ms: 1718688697838,
          },
        ],
        position_type: "FLAT",
        position_uuid: "a5d41132-ae8f-4f3a-8a52-5fb13dd1283a",
        return_at_close: 1.0006282326700906,
        trade_pair: ["CADJPY", "CAD/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 114.857,
        close_ms: 1718691718121,
        current_return: 1.0005170908172771,
        initial_entry_price: 114.857,
        is_closed_position: true,
        miner_hotkey: "5GVyVwjpxmQvVyDQQ4do18CEZU2vjhXixoPBACu7c2Vw1ShQ",
        net_leverage: 0.0,
        open_ms: 1718688727197,
        orders: [
          {
            leverage: 0.497,
            order_type: "LONG",
            order_uuid: "5cefff56-82a4-46c0-9d83-0db6c6877ab9",
            price: 114.857,
            price_sources: [],
            processed_ms: 1718688727181,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "0d21147e-ccdc-4cf6-aaad-8f894a2dad84",
            price: 114.9765,
            price_sources: [],
            processed_ms: 1718691718121,
          },
        ],
        position_type: "FLAT",
        position_uuid: "be74d2c2-6746-48a7-a1eb-3edfc6ffcb99",
        return_at_close: 1.0004822828276876,
        trade_pair: ["CADJPY", "CAD/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 114.96708506429279,
        close_ms: 1718712540761,
        current_return: 1.0003157528588198,
        initial_entry_price: 114.995,
        is_closed_position: true,
        miner_hotkey: "5GVyVwjpxmQvVyDQQ4do18CEZU2vjhXixoPBACu7c2Vw1ShQ",
        net_leverage: 0.0,
        open_ms: 1718691748608,
        orders: [
          {
            leverage: 0.463,
            order_type: "LONG",
            order_uuid: "f5b86aa2-bc47-452d-a044-8a17cf7550b6",
            price: 114.995,
            price_sources: [],
            processed_ms: 1718691748588,
          },
          {
            leverage: 0.548,
            order_type: "LONG",
            order_uuid: "f84f6714-54d3-4d30-b8f6-ab0a5350e684",
            price: 114.9435,
            price_sources: [],
            processed_ms: 1718710994329,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "f32d23d4-b02e-4c20-ad4d-723ead82d992",
            price: 115.003,
            price_sources: [],
            processed_ms: 1718712540761,
          },
        ],
        position_type: "FLAT",
        position_uuid: "9b6ccd37-e261-4bb9-bd62-1f882bbd260f",
        return_at_close: 1.00024496051299,
        trade_pair: ["CADJPY", "CAD/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 114.90627290260367,
        close_ms: 1718717099766,
        current_return: 1.0007434764840397,
        initial_entry_price: 115.007,
        is_closed_position: true,
        miner_hotkey: "5GVyVwjpxmQvVyDQQ4do18CEZU2vjhXixoPBACu7c2Vw1ShQ",
        net_leverage: 0.0,
        open_ms: 1718712569909,
        orders: [
          {
            leverage: 0.567,
            order_type: "LONG",
            order_uuid: "0b011166-4142-49df-809a-d2aea306fe39",
            price: 115.007,
            price_sources: [],
            processed_ms: 1718712569885,
          },
          {
            leverage: 0.541,
            order_type: "LONG",
            order_uuid: "00fa29d7-f7d8-4e99-8b7e-b276f8ed22a2",
            price: 114.895,
            price_sources: [],
            processed_ms: 1718713804988,
          },
          {
            leverage: 0.434,
            order_type: "LONG",
            order_uuid: "caa5d035-6e56-4b07-bf1d-cc6caff82a6b",
            price: 114.881,
            price_sources: [],
            processed_ms: 1718714015206,
          },
          {
            leverage: 0.532,
            order_type: "LONG",
            order_uuid: "44ac198d-9edc-4dfa-9352-495238e96fb4",
            price: 114.831,
            price_sources: [],
            processed_ms: 1718714229452,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "998d4379-201a-4d0e-b676-33a9451c09d9",
            price: 114.94749999999999,
            price_sources: [],
            processed_ms: 1718717099766,
          },
        ],
        position_type: "FLAT",
        position_uuid: "b5d424e5-60c0-4192-af59-820ed0b89378",
        return_at_close: 1.0005981885461237,
        trade_pair: ["CADJPY", "CAD/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 114.92510298661173,
        close_ms: 1718717961288,
        current_return: 1.0006453651746487,
        initial_entry_price: 114.945,
        is_closed_position: true,
        miner_hotkey: "5GVyVwjpxmQvVyDQQ4do18CEZU2vjhXixoPBACu7c2Vw1ShQ",
        net_leverage: 0.0,
        open_ms: 1718717129698,
        orders: [
          {
            leverage: 0.419,
            order_type: "LONG",
            order_uuid: "b483c96d-6a8a-4cb2-958c-6cb3e7c48d82",
            price: 114.945,
            price_sources: [],
            processed_ms: 1718717129664,
          },
          {
            leverage: 0.552,
            order_type: "LONG",
            order_uuid: "3a42993e-9490-4a90-be0b-4c08d20c97e2",
            price: 114.91,
            price_sources: [],
            processed_ms: 1718717486871,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "1eaae08b-a3a9-4acd-8b35-10ae75b24b06",
            price: 115.0015,
            price_sources: [],
            processed_ms: 1718717961288,
          },
        ],
        position_type: "FLAT",
        position_uuid: "e90e057e-38eb-42c2-9f04-610957807d22",
        return_at_close: 1.0005773513091778,
        trade_pair: ["CADJPY", "CAD/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 114.99072929652716,
        close_ms: 1718719606633,
        current_return: 1.000715485682982,
        initial_entry_price: 115.003,
        is_closed_position: true,
        miner_hotkey: "5GVyVwjpxmQvVyDQQ4do18CEZU2vjhXixoPBACu7c2Vw1ShQ",
        net_leverage: 0.0,
        open_ms: 1718717989596,
        orders: [
          {
            leverage: 0.593,
            order_type: "LONG",
            order_uuid: "3a9cece2-24d9-49a9-b38a-71e22081c00e",
            price: 115.003,
            price_sources: [],
            processed_ms: 1718717989047,
          },
          {
            leverage: 0.53,
            order_type: "LONG",
            order_uuid: "311104cb-df10-43f0-8842-cc65f6174970",
            price: 114.977,
            price_sources: [],
            processed_ms: 1718719116366,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "bb5abd6c-6c06-4fcc-b44e-c51dc450d4a4",
            price: 115.064,
            price_sources: [],
            processed_ms: 1718719606633,
          },
        ],
        position_type: "FLAT",
        position_uuid: "3ca31334-9fd7-4e1d-8005-af8ff16d1fbf",
        return_at_close: 1.0006368194386526,
        trade_pair: ["CADJPY", "CAD/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 115.02305022601708,
        close_ms: 1718733411357,
        current_return: 1.0003624868557126,
        initial_entry_price: 115.069,
        is_closed_position: true,
        miner_hotkey: "5GVyVwjpxmQvVyDQQ4do18CEZU2vjhXixoPBACu7c2Vw1ShQ",
        net_leverage: 0.0,
        open_ms: 1718719636218,
        orders: [
          {
            leverage: 0.447,
            order_type: "LONG",
            order_uuid: "fa8577ed-1751-407a-8fcf-7c3ff8bda350",
            price: 115.069,
            price_sources: [],
            processed_ms: 1718719636180,
          },
          {
            leverage: 0.43,
            order_type: "LONG",
            order_uuid: "799fe082-a4cb-45d9-b339-6c8a83164a45",
            price: 115.067,
            price_sources: [],
            processed_ms: 1718722426304,
          },
          {
            leverage: 0.56,
            order_type: "LONG",
            order_uuid: "0349ec97-c04b-4711-9fab-919a7091172f",
            price: 115.015,
            price_sources: [],
            processed_ms: 1718725500501,
          },
          {
            leverage: 0.554,
            order_type: "LONG",
            order_uuid: "95f56831-ea61-4b9b-b753-95fa0d852c76",
            price: 114.96,
            price_sources: [],
            processed_ms: 1718727655684,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "1de1f747-f18b-4fa2-bf34-32527b4ec561",
            price: 115.044,
            price_sources: [],
            processed_ms: 1718733411357,
          },
        ],
        position_type: "FLAT",
        position_uuid: "239acdce-d6d5-48cb-996e-9c589f11e3cc",
        return_at_close: 1.0002230663359195,
        trade_pair: ["CADJPY", "CAD/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 115.03598177995794,
        close_ms: 1718744500719,
        current_return: 1.0002979622352821,
        initial_entry_price: 115.028,
        is_closed_position: true,
        miner_hotkey: "5GVyVwjpxmQvVyDQQ4do18CEZU2vjhXixoPBACu7c2Vw1ShQ",
        net_leverage: 0.0,
        open_ms: 1718733442307,
        orders: [
          {
            leverage: 0.458,
            order_type: "LONG",
            order_uuid: "7d3cdc10-f19b-47fa-983b-d32c0c3852e2",
            price: 115.028,
            price_sources: [],
            processed_ms: 1718733442278,
          },
          {
            leverage: 0.431,
            order_type: "LONG",
            order_uuid: "0e39addb-a293-47e6-9366-9e809302c2fc",
            price: 115.002,
            price_sources: [],
            processed_ms: 1718733785387,
          },
          {
            leverage: 0.538,
            order_type: "LONG",
            order_uuid: "475e747c-5681-42b3-a1a3-e51586e2a518",
            price: 115.07,
            price_sources: [],
            processed_ms: 1718744470871,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "23ed148d-dbf5-4df5-83a9-764e57d6ed73",
            price: 115.06,
            price_sources: [],
            processed_ms: 1718744500719,
          },
        ],
        position_type: "FLAT",
        position_uuid: "2a346ccf-310f-4dd4-8d87-28b254ce7a16",
        return_at_close: 1.0001980424718344,
        trade_pair: ["CADJPY", "CAD/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 115.0320225314778,
        close_ms: 1718748151947,
        current_return: 1.0005310701305925,
        initial_entry_price: 115.014,
        is_closed_position: true,
        miner_hotkey: "5GVyVwjpxmQvVyDQQ4do18CEZU2vjhXixoPBACu7c2Vw1ShQ",
        net_leverage: 0.0,
        open_ms: 1718744531413,
        orders: [
          {
            leverage: 0.448,
            order_type: "LONG",
            order_uuid: "3ad3ac65-1040-4d1e-bc38-f92a4cbe82ba",
            price: 115.014,
            price_sources: [],
            processed_ms: 1718744530586,
          },
          {
            leverage: 0.524,
            order_type: "LONG",
            order_uuid: "83af035a-3c0c-405a-833e-c89c95ade1f5",
            price: 115.07,
            price_sources: [],
            processed_ms: 1718744588788,
          },
          {
            leverage: 0.537,
            order_type: "LONG",
            order_uuid: "4f7c35fb-1777-4ca1-b5e7-08ecf634b4bf",
            price: 115.01,
            price_sources: [],
            processed_ms: 1718747694663,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "3ff0be0b-fec7-4308-9f0d-3a4c902cd753",
            price: 115.07249999999999,
            price_sources: [],
            processed_ms: 1718748151947,
          },
        ],
        position_type: "FLAT",
        position_uuid: "93185884-e7fb-4db4-88a4-f7f1ec884aa9",
        return_at_close: 1.0004253840336546,
        trade_pair: ["CADJPY", "CAD/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 115.04287132593076,
        close_ms: 1718765972155,
        current_return: 1.0004274628058956,
        initial_entry_price: 115.072,
        is_closed_position: true,
        miner_hotkey: "5GVyVwjpxmQvVyDQQ4do18CEZU2vjhXixoPBACu7c2Vw1ShQ",
        net_leverage: 0.0,
        open_ms: 1718748185479,
        orders: [
          {
            leverage: 0.549,
            order_type: "LONG",
            order_uuid: "94c668c0-52da-4b5d-b5d3-34ef2d2fe73c",
            price: 115.072,
            price_sources: [],
            processed_ms: 1718748185152,
          },
          {
            leverage: 0.43,
            order_type: "LONG",
            order_uuid: "493d0aa2-00fc-4380-be9a-9ad27f4ce694",
            price: 115.062,
            price_sources: [],
            processed_ms: 1718748376946,
          },
          {
            leverage: 0.552,
            order_type: "LONG",
            order_uuid: "41f8363c-bd8e-441a-8b2a-db3671fbb96f",
            price: 114.999,
            price_sources: [],
            processed_ms: 1718759729800,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "cd62a973-e692-4d98-ab7b-946234a1c22c",
            price: 115.075,
            price_sources: [],
            processed_ms: 1718765972155,
          },
        ],
        position_type: "FLAT",
        position_uuid: "e16f79fe-1b56-4f0e-a87c-198fe87c1831",
        return_at_close: 1.0003202469947066,
        trade_pair: ["CADJPY", "CAD/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 114.98875306799339,
        close_ms: 1718781015721,
        current_return: 1.0002553559403033,
        initial_entry_price: 115.0825,
        is_closed_position: true,
        miner_hotkey: "5GVyVwjpxmQvVyDQQ4do18CEZU2vjhXixoPBACu7c2Vw1ShQ",
        net_leverage: 0.0,
        open_ms: 1718766001987,
        orders: [
          {
            leverage: 0.477,
            order_type: "LONG",
            order_uuid: "e4aaa506-81d5-4620-83e8-105e100373b7",
            price: 115.0825,
            price_sources: [],
            processed_ms: 1718766001892,
          },
          {
            leverage: 0.578,
            order_type: "LONG",
            order_uuid: "9ed44779-3ac5-4f50-969d-831927635fbc",
            price: 115.075,
            price_sources: [],
            processed_ms: 1718766608676,
          },
          {
            leverage: 0.593,
            order_type: "LONG",
            order_uuid: "f4d03d88-c1c0-4eed-aacd-42c9b76c2959",
            price: 115.017,
            price_sources: [],
            processed_ms: 1718773012085,
          },
          {
            leverage: 0.483,
            order_type: "LONG",
            order_uuid: "53f32cfd-1a11-479a-b69c-e3f4aef9acb2",
            price: 114.95,
            price_sources: [],
            processed_ms: 1718779037992,
          },
          {
            leverage: 0.417,
            order_type: "LONG",
            order_uuid: "c004a86f-3573-472b-bd03-06f94540a75c",
            price: 114.912,
            price_sources: [],
            processed_ms: 1718779509260,
          },
          {
            leverage: 0.467,
            order_type: "LONG",
            order_uuid: "b047a453-7271-4a2c-a679-184e53771dfa",
            price: 114.859,
            price_sources: [],
            processed_ms: 1718780297934,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "859bde4d-0c79-4587-89f7-6abbafc89677",
            price: 114.9985,
            price_sources: [],
            processed_ms: 1718781015721,
          },
        ],
        position_type: "FLAT",
        position_uuid: "0839d308-82ed-4b47-b4dd-65f2655ace5c",
        return_at_close: 1.0000442520474322,
        trade_pair: ["CADJPY", "CAD/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 114.96099022004893,
        close_ms: 1718783345176,
        current_return: 1.0004838849946946,
        initial_entry_price: 114.986,
        is_closed_position: true,
        miner_hotkey: "5GVyVwjpxmQvVyDQQ4do18CEZU2vjhXixoPBACu7c2Vw1ShQ",
        net_leverage: 0.0,
        open_ms: 1718781045355,
        orders: [
          {
            leverage: 0.509,
            order_type: "LONG",
            order_uuid: "281a8cd6-7f9a-4bbd-8d2a-dec58bc52911",
            price: 114.986,
            price_sources: [],
            processed_ms: 1718781045331,
          },
          {
            leverage: 0.577,
            order_type: "LONG",
            order_uuid: "c5eb9189-8a52-40fc-8fe3-74c6d4a6d262",
            price: 114.978,
            price_sources: [],
            processed_ms: 1718781153122,
          },
          {
            leverage: 0.55,
            order_type: "LONG",
            order_uuid: "b7879b25-f73e-4f7b-b031-6e2ced133ede",
            price: 114.92,
            price_sources: [],
            processed_ms: 1718781375387,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "56a953d3-fdb8-43e6-989d-ab6f67335652",
            price: 114.995,
            price_sources: [],
            processed_ms: 1718783345176,
          },
        ],
        position_type: "FLAT",
        position_uuid: "d1ed38b4-828e-442d-abac-90ee672e87ec",
        return_at_close: 1.000369309580185,
        trade_pair: ["CADJPY", "CAD/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 114.98949999999999,
        close_ms: 1718791939963,
        current_return: 1.0007137695180865,
        initial_entry_price: 114.98949999999999,
        is_closed_position: true,
        miner_hotkey: "5GVyVwjpxmQvVyDQQ4do18CEZU2vjhXixoPBACu7c2Vw1ShQ",
        net_leverage: 0.0,
        open_ms: 1718783375136,
        orders: [
          {
            leverage: 0.568,
            order_type: "LONG",
            order_uuid: "8032564c-910c-4905-963c-d4edfdfc530d",
            price: 114.98949999999999,
            price_sources: [],
            processed_ms: 1718783374512,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "8b650fbf-7e42-4649-97ae-eb8a819fcd00",
            price: 115.134,
            price_sources: [],
            processed_ms: 1718791939963,
          },
        ],
        position_type: "FLAT",
        position_uuid: "ed37299e-1258-435f-92f4-b38b26e84992",
        return_at_close: 1.0006739811386105,
        trade_pair: ["CADJPY", "CAD/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 115.13244549499443,
        close_ms: 1718798409652,
        current_return: 1.0004727944624898,
        initial_entry_price: 115.142,
        is_closed_position: true,
        miner_hotkey: "5GVyVwjpxmQvVyDQQ4do18CEZU2vjhXixoPBACu7c2Vw1ShQ",
        net_leverage: 0.0,
        open_ms: 1718791971452,
        orders: [
          {
            leverage: 0.48,
            order_type: "LONG",
            order_uuid: "a0b4393f-3e48-4f07-8090-92d45274c000",
            price: 115.142,
            price_sources: [],
            processed_ms: 1718791971409,
          },
          {
            leverage: 0.419,
            order_type: "LONG",
            order_uuid: "4fef1e99-2453-47e8-b664-a4b9d9415c18",
            price: 115.1215,
            price_sources: [],
            processed_ms: 1718792160112,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "6a842420-3086-4f11-a16e-1e1d5bbbed00",
            price: 115.193,
            price_sources: [],
            processed_ms: 1718798409652,
          },
        ],
        position_type: "FLAT",
        position_uuid: "95fc3635-927d-4522-af7d-9fb0828eab60",
        return_at_close: 1.0004098347095343,
        trade_pair: ["CADJPY", "CAD/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 115.19552993744415,
        close_ms: 1718807431916,
        current_return: 1.0002376992256674,
        initial_entry_price: 115.196,
        is_closed_position: true,
        miner_hotkey: "5GVyVwjpxmQvVyDQQ4do18CEZU2vjhXixoPBACu7c2Vw1ShQ",
        net_leverage: 0.0,
        open_ms: 1718798439638,
        orders: [
          {
            leverage: 0.593,
            order_type: "LONG",
            order_uuid: "6ad24611-bd06-4b71-b83c-ac85e73ee6b3",
            price: 115.196,
            price_sources: [],
            processed_ms: 1718798439589,
          },
          {
            leverage: 0.526,
            order_type: "LONG",
            order_uuid: "e574b9f4-860f-4da4-8e45-5c48d7cfc4b3",
            price: 115.195,
            price_sources: [],
            processed_ms: 1718799132372,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "044b36ba-7854-4cc8-9b94-336129e04532",
            price: 115.22,
            price_sources: [],
            processed_ms: 1718807431916,
          },
        ],
        position_type: "FLAT",
        position_uuid: "c315bd33-d58a-468e-add9-4b747bbebd7b",
        return_at_close: 1.000159350606687,
        trade_pair: ["CADJPY", "CAD/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 115.19235907723167,
        close_ms: 1718823525980,
        current_return: 1.0001322225114992,
        initial_entry_price: 115.23,
        is_closed_position: true,
        miner_hotkey: "5GVyVwjpxmQvVyDQQ4do18CEZU2vjhXixoPBACu7c2Vw1ShQ",
        net_leverage: 0.0,
        open_ms: 1718807462027,
        orders: [
          {
            leverage: 0.553,
            order_type: "LONG",
            order_uuid: "afb82f3e-334e-43d2-a3ef-16a93e9e5501",
            price: 115.23,
            price_sources: [],
            processed_ms: 1718807461907,
          },
          {
            leverage: 0.403,
            order_type: "LONG",
            order_uuid: "073de0a8-b7db-4dd7-b9ea-57c5d1706a06",
            price: 115.238,
            price_sources: [],
            processed_ms: 1718810036290,
          },
          {
            leverage: 0.464,
            order_type: "LONG",
            order_uuid: "032c8ad5-adf3-4b85-879e-e976439fb5ca",
            price: 115.185,
            price_sources: [],
            processed_ms: 1718813336794,
          },
          {
            leverage: 0.574,
            order_type: "LONG",
            order_uuid: "9a6db92a-b882-4a1c-b496-bccc79a6bed4",
            price: 115.13,
            price_sources: [],
            processed_ms: 1718814900217,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "a5f3f4d9-ca20-4ec0-9d6e-06c7d810fc35",
            price: 115.2,
            price_sources: [],
            processed_ms: 1718823525980,
          },
        ],
        position_type: "FLAT",
        position_uuid: "c413d5f1-0c5e-4f32-a695-2441ed8e36d7",
        return_at_close: 0.9999926240558811,
        trade_pair: ["CADJPY", "CAD/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 115.24655602409638,
        close_ms: 1718830871007,
        current_return: 0.99947335899294,
        initial_entry_price: 115.2265,
        is_closed_position: true,
        miner_hotkey: "5GVyVwjpxmQvVyDQQ4do18CEZU2vjhXixoPBACu7c2Vw1ShQ",
        net_leverage: 0.0,
        open_ms: 1718823557671,
        orders: [
          {
            leverage: 0.594,
            order_type: "LONG",
            order_uuid: "fc7da5fd-70ec-43e4-b10d-7ccd769b5be9",
            price: 115.2265,
            price_sources: [],
            processed_ms: 1718823557626,
          },
          {
            leverage: 0.558,
            order_type: "LONG",
            order_uuid: "757fb367-015d-4605-908c-c4e22b3ab560",
            price: 115.298,
            price_sources: [],
            processed_ms: 1718830803048,
          },
          {
            leverage: 0.508,
            order_type: "LONG",
            order_uuid: "c034c75d-9d20-4d0a-aa36-eca9d66b58b1",
            price: 115.21350000000001,
            price_sources: [],
            processed_ms: 1718830831677,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "76776554-90e8-4c12-9690-010c678a0697",
            price: 115.21,
            price_sources: [],
            processed_ms: 1718830871007,
          },
        ],
        position_type: "FLAT",
        position_uuid: "c895dfe3-8596-45fe-8fbc-8f24843d6276",
        return_at_close: 0.999357220188625,
        trade_pair: ["CADJPY", "CAD/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 115.23688406881077,
        close_ms: 1718831023863,
        current_return: 1.0000394473480543,
        initial_entry_price: 115.199,
        is_closed_position: true,
        miner_hotkey: "5GVyVwjpxmQvVyDQQ4do18CEZU2vjhXixoPBACu7c2Vw1ShQ",
        net_leverage: 0.0,
        open_ms: 1718830899706,
        orders: [
          {
            leverage: 0.451,
            order_type: "LONG",
            order_uuid: "77fa207c-e012-4ae5-a685-e2d497bdc803",
            price: 115.199,
            price_sources: [],
            processed_ms: 1718830899659,
          },
          {
            leverage: 0.43,
            order_type: "LONG",
            order_uuid: "14d96e12-8228-410a-b7c4-39464049eca4",
            price: 115.2415,
            price_sources: [],
            processed_ms: 1718830961799,
          },
          {
            leverage: 0.456,
            order_type: "LONG",
            order_uuid: "69a1f7a0-5fbb-4300-bda8-87d59ced7fc9",
            price: 115.27,
            price_sources: [],
            processed_ms: 1718830991691,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "50b34c3d-fa34-4e3d-9b3a-180e4586ba94",
            price: 115.2402829431926,
            price_sources: [],
            processed_ms: 1718831023863,
          },
        ],
        position_type: "FLAT",
        position_uuid: "21482348-5e72-4b64-b311-a39272752848",
        return_at_close: 0.9999458536561769,
        trade_pair: ["CADJPY", "CAD/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 115.23264538558786,
        close_ms: 1718831155757,
        current_return: 0.9994348916959673,
        initial_entry_price: 115.185,
        is_closed_position: true,
        miner_hotkey: "5GVyVwjpxmQvVyDQQ4do18CEZU2vjhXixoPBACu7c2Vw1ShQ",
        net_leverage: 0.0,
        open_ms: 1718831054070,
        orders: [
          {
            leverage: 0.499,
            order_type: "LONG",
            order_uuid: "745f7fba-0374-4d4d-b403-d4fa9247bc93",
            price: 115.185,
            price_sources: [],
            processed_ms: 1718831053950,
          },
          {
            leverage: 0.585,
            order_type: "LONG",
            order_uuid: "cc2790b3-8ec8-4a64-8bab-c6a497a169e0",
            price: 115.25,
            price_sources: [],
            processed_ms: 1718831089688,
          },
          {
            leverage: 0.498,
            order_type: "LONG",
            order_uuid: "65c6a294-0f62-40a8-9aa1-99e112ec8573",
            price: 115.26,
            price_sources: [],
            processed_ms: 1718831121716,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "816ab715-990d-4c4c-8a38-9b6a3426c66f",
            price: 115.19149999999999,
            price_sources: [],
            processed_ms: 1718831155757,
          },
        ],
        position_type: "FLAT",
        position_uuid: "7f00122d-b879-443f-bfd2-36adcfa5ad85",
        return_at_close: 0.9993242142760609,
        trade_pair: ["CADJPY", "CAD/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 115.22911190053286,
        close_ms: 1718831255746,
        current_return: 1.000008676789588,
        initial_entry_price: 115.25,
        is_closed_position: true,
        miner_hotkey: "5GVyVwjpxmQvVyDQQ4do18CEZU2vjhXixoPBACu7c2Vw1ShQ",
        net_leverage: 0.0,
        open_ms: 1718831187607,
        orders: [
          {
            leverage: 0.538,
            order_type: "LONG",
            order_uuid: "e9d69ed9-0084-492b-83a7-41a284ec4b84",
            price: 115.25,
            price_sources: [],
            processed_ms: 1718831185792,
          },
          {
            leverage: 0.588,
            order_type: "LONG",
            order_uuid: "ce06bfdb-3f2f-4b01-ac59-32bfe40d5d12",
            price: 115.21,
            price_sources: [],
            processed_ms: 1718831221820,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "b9d3db38-2723-4b65-b99f-6aeb7d1974bf",
            price: 115.23,
            price_sources: [],
            processed_ms: 1718831255746,
          },
        ],
        position_type: "FLAT",
        position_uuid: "62144f31-dce1-4a96-b267-836118218e04",
        return_at_close: 0.9999298561056833,
        trade_pair: ["CADJPY", "CAD/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 115.22096057692308,
        close_ms: 1718831359926,
        current_return: 1.0001086533955814,
        initial_entry_price: 115.238,
        is_closed_position: true,
        miner_hotkey: "5GVyVwjpxmQvVyDQQ4do18CEZU2vjhXixoPBACu7c2Vw1ShQ",
        net_leverage: 0.0,
        open_ms: 1718831287787,
        orders: [
          {
            leverage: 0.503,
            order_type: "LONG",
            order_uuid: "f009c3c1-d4c0-4bc2-9b35-cdc84ddf1814",
            price: 115.238,
            price_sources: [],
            processed_ms: 1718831287726,
          },
          {
            leverage: 0.537,
            order_type: "LONG",
            order_uuid: "19ef75e9-7361-4084-88e1-3ca9106f5d72",
            price: 115.205,
            price_sources: [],
            processed_ms: 1718831322188,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "9690cd4d-fd73-41d5-a16a-702b8e6c108f",
            price: 115.233,
            price_sources: [],
            processed_ms: 1718831359926,
          },
        ],
        position_type: "FLAT",
        position_uuid: "3221fbeb-9fad-4788-a6ff-6feef9d29f48",
        return_at_close: 1.0000358454856142,
        trade_pair: ["CADJPY", "CAD/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 115.24292095588234,
        close_ms: 1718831951925,
        current_return: 0.9999535331794277,
        initial_entry_price: 115.222,
        is_closed_position: true,
        miner_hotkey: "5GVyVwjpxmQvVyDQQ4do18CEZU2vjhXixoPBACu7c2Vw1ShQ",
        net_leverage: 0.0,
        open_ms: 1718831399044,
        orders: [
          {
            leverage: 0.489,
            order_type: "LONG",
            order_uuid: "6bdd0c7d-5867-406c-a981-3e6e8d99e8d5",
            price: 115.222,
            price_sources: [],
            processed_ms: 1718831398300,
          },
          {
            leverage: 0.599,
            order_type: "LONG",
            order_uuid: "f8cf0aec-a2ce-47ad-ad0c-dc53746a7926",
            price: 115.26,
            price_sources: [],
            processed_ms: 1718831922091,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "7f87b9b6-81c1-46d5-9ca4-35e5a2ab90ff",
            price: 115.238,
            price_sources: [],
            processed_ms: 1718831951925,
          },
        ],
        position_type: "FLAT",
        position_uuid: "4c242997-e79b-4eba-a64d-1ef6a89ad51c",
        return_at_close: 0.9998773767183408,
        trade_pair: ["CADJPY", "CAD/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 115.23,
        close_ms: 1718832035748,
        current_return: 1.000045734617721,
        initial_entry_price: 115.23,
        is_closed_position: true,
        miner_hotkey: "5GVyVwjpxmQvVyDQQ4do18CEZU2vjhXixoPBACu7c2Vw1ShQ",
        net_leverage: 0.0,
        open_ms: 1718831981807,
        orders: [
          {
            leverage: 0.527,
            order_type: "LONG",
            order_uuid: "b0eecfef-b01b-4a98-a69f-d49b849f357b",
            price: 115.23,
            price_sources: [],
            processed_ms: 1718831981754,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "933f04e9-8ad5-4c96-9619-1551d269a862",
            price: 115.24,
            price_sources: [],
            processed_ms: 1718832035748,
          },
        ],
        position_type: "FLAT",
        position_uuid: "c2160374-0f63-4fed-a00f-fb9e3b7eba10",
        return_at_close: 1.000008842930571,
        trade_pair: ["CADJPY", "CAD/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 115.211,
        close_ms: 1718832129155,
        current_return: 0.9999740562967078,
        initial_entry_price: 115.211,
        is_closed_position: true,
        miner_hotkey: "5GVyVwjpxmQvVyDQQ4do18CEZU2vjhXixoPBACu7c2Vw1ShQ",
        net_leverage: 0.0,
        open_ms: 1718832067751,
        orders: [
          {
            leverage: 0.427,
            order_type: "LONG",
            order_uuid: "60acc932-1ee5-4adc-bf29-462552e3dc84",
            price: 115.211,
            price_sources: [],
            processed_ms: 1718832067691,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "47d79de6-2a35-4542-9834-37315cf0af06",
            price: 115.204,
            price_sources: [],
            processed_ms: 1718832129155,
          },
        ],
        position_type: "FLAT",
        position_uuid: "eacb7d3b-f667-42bb-9a4e-0f9b68cb6fd4",
        return_at_close: 0.999944167072165,
        trade_pair: ["CADJPY", "CAD/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 115.208,
        close_ms: 1718832187678,
        current_return: 1.0001502673425455,
        initial_entry_price: 115.208,
        is_closed_position: true,
        miner_hotkey: "5GVyVwjpxmQvVyDQQ4do18CEZU2vjhXixoPBACu7c2Vw1ShQ",
        net_leverage: 0.0,
        open_ms: 1718832158405,
        orders: [
          {
            leverage: 0.541,
            order_type: "LONG",
            order_uuid: "2a908ae0-6836-4d32-951a-95895bc4adfc",
            price: 115.208,
            price_sources: [],
            processed_ms: 1718832157721,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "59d16171-b547-463a-947b-d6e5de5a7e08",
            price: 115.24,
            price_sources: [],
            processed_ms: 1718832187678,
          },
        ],
        position_type: "FLAT",
        position_uuid: "31da8efb-83a8-4c59-8b67-d933dc333657",
        return_at_close: 1.0001123916519212,
        trade_pair: ["CADJPY", "CAD/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 115.202,
        close_ms: 1718832255611,
        current_return: 1.000103626673148,
        initial_entry_price: 115.202,
        is_closed_position: true,
        miner_hotkey: "5GVyVwjpxmQvVyDQQ4do18CEZU2vjhXixoPBACu7c2Vw1ShQ",
        net_leverage: 0.0,
        open_ms: 1718832225639,
        orders: [
          {
            leverage: 0.508,
            order_type: "LONG",
            order_uuid: "9bb50913-0445-4b57-adc5-caf2826b4fda",
            price: 115.202,
            price_sources: [],
            processed_ms: 1718832225608,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "1639ab0b-14a1-4a8d-bb5b-4702078849a8",
            price: 115.2255,
            price_sources: [],
            processed_ms: 1718832255611,
          },
        ],
        position_type: "FLAT",
        position_uuid: "f930e030-a770-4c63-b112-f665a1ba4df6",
        return_at_close: 1.0000680629881835,
        trade_pair: ["CADJPY", "CAD/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 115.21746341463415,
        close_ms: 1718832362210,
        current_return: 1.0000814611618458,
        initial_entry_price: 115.196,
        is_closed_position: true,
        miner_hotkey: "5GVyVwjpxmQvVyDQQ4do18CEZU2vjhXixoPBACu7c2Vw1ShQ",
        net_leverage: 0.0,
        open_ms: 1718832286310,
        orders: [
          {
            leverage: 0.504,
            order_type: "LONG",
            order_uuid: "8ce0f272-c7e5-4c8e-a1d2-80e123a77d64",
            price: 115.196,
            price_sources: [],
            processed_ms: 1718832285816,
          },
          {
            leverage: 0.48,
            order_type: "LONG",
            order_uuid: "24f7dfc2-4566-43f2-bd62-f4d68435e987",
            price: 115.24,
            price_sources: [],
            processed_ms: 1718832315850,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "c1420b19-8c0e-4835-b6a8-40166073c7a8",
            price: 115.227,
            price_sources: [],
            processed_ms: 1718832362210,
          },
        ],
        position_type: "FLAT",
        position_uuid: "d936294b-15ce-4e76-a27b-52f0590142e2",
        return_at_close: 1.000012575550801,
        trade_pair: ["CADJPY", "CAD/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 115.22753792298721,
        close_ms: 1718832441705,
        current_return: 0.9999327264810125,
        initial_entry_price: 115.2275379229872,
        is_closed_position: true,
        miner_hotkey: "5GVyVwjpxmQvVyDQQ4do18CEZU2vjhXixoPBACu7c2Vw1ShQ",
        net_leverage: 0.0,
        open_ms: 1718832392396,
        orders: [
          {
            leverage: 0.442,
            order_type: "LONG",
            order_uuid: "a0c56ade-6253-4af8-96f7-5471be274d45",
            price: 115.2275379229872,
            price_sources: [],
            processed_ms: 1718832391762,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "8474bf7e-bc39-4085-baf2-09b2da7a246a",
            price: 115.21,
            price_sources: [],
            processed_ms: 1718832441705,
          },
        ],
        position_type: "FLAT",
        position_uuid: "57206527-859f-4253-b3f1-0f8ec5743215",
        return_at_close: 0.9999017885624552,
        trade_pair: ["CADJPY", "CAD/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 115.22373538306451,
        close_ms: 1718832607618,
        current_return: 1.0000453184658105,
        initial_entry_price: 115.24,
        is_closed_position: true,
        miner_hotkey: "5GVyVwjpxmQvVyDQQ4do18CEZU2vjhXixoPBACu7c2Vw1ShQ",
        net_leverage: 0.0,
        open_ms: 1718832479643,
        orders: [
          {
            leverage: 0.463,
            order_type: "LONG",
            order_uuid: "756ecd2c-70a5-4eed-8411-be5e0c6bbb2a",
            price: 115.24,
            price_sources: [],
            processed_ms: 1718832479584,
          },
          {
            leverage: 0.529,
            order_type: "LONG",
            order_uuid: "a6b4773a-1931-4a60-bdc8-ed5ed074a655",
            price: 115.20949999999999,
            price_sources: [],
            processed_ms: 1718832557687,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "99b60778-5543-4235-ace6-4a9ce0350d81",
            price: 115.229,
            price_sources: [],
            processed_ms: 1718832607618,
          },
        ],
        position_type: "FLAT",
        position_uuid: "d22574e4-128f-4eba-86e4-ed31678b783f",
        return_at_close: 0.9999758753188962,
        trade_pair: ["CADJPY", "CAD/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 115.26,
        close_ms: 1718832719782,
        current_return: 0.9998125976054139,
        initial_entry_price: 115.26,
        is_closed_position: true,
        miner_hotkey: "5GVyVwjpxmQvVyDQQ4do18CEZU2vjhXixoPBACu7c2Vw1ShQ",
        net_leverage: 0.0,
        open_ms: 1718832640240,
        orders: [
          {
            leverage: 0.54,
            order_type: "LONG",
            order_uuid: "6324ef6f-c07c-462a-8d35-11214bacb0f9",
            price: 115.26,
            price_sources: [],
            processed_ms: 1718832639795,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "94e8843f-e6ea-4c8b-b247-f8639e2349a1",
            price: 115.22,
            price_sources: [],
            processed_ms: 1718832719782,
          },
        ],
        position_type: "FLAT",
        position_uuid: "bf1367ea-1781-4749-8cef-6dc19eedca5b",
        return_at_close: 0.9997748046892244,
        trade_pair: ["CADJPY", "CAD/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 115.22884384568279,
        close_ms: 1718833210605,
        current_return: 1.0002006335705607,
        initial_entry_price: 115.22,
        is_closed_position: true,
        miner_hotkey: "5GVyVwjpxmQvVyDQQ4do18CEZU2vjhXixoPBACu7c2Vw1ShQ",
        net_leverage: 0.0,
        open_ms: 1718832749732,
        orders: [
          {
            leverage: 0.585,
            order_type: "LONG",
            order_uuid: "0587ab3f-fad5-469a-8c50-73a193d88f5a",
            price: 115.22,
            price_sources: [],
            processed_ms: 1718832749687,
          },
          {
            leverage: 0.582,
            order_type: "LONG",
            order_uuid: "415cfb69-e9e6-4bb2-8f65-fe239636bb2c",
            price: 115.228,
            price_sources: [],
            processed_ms: 1718832901770,
          },
          {
            leverage: 0.466,
            order_type: "LONG",
            order_uuid: "94d4555e-9a4e-4c6e-90f4-21f26d0a002f",
            price: 115.241,
            price_sources: [],
            processed_ms: 1718832938627,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "7401f635-a9f6-4428-9102-75bc7010f003",
            price: 115.243,
            price_sources: [],
            processed_ms: 1718833210605,
          },
        ],
        position_type: "FLAT",
        position_uuid: "89e14ef8-8ef4-45b9-ad61-5f52a3a75bae",
        return_at_close: 1.0000863006361373,
        trade_pair: ["CADJPY", "CAD/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 115.25047086031454,
        close_ms: 1718835365187,
        current_return: 1.0003051000399117,
        initial_entry_price: 115.25399999999999,
        is_closed_position: true,
        miner_hotkey: "5GVyVwjpxmQvVyDQQ4do18CEZU2vjhXixoPBACu7c2Vw1ShQ",
        net_leverage: 0.0,
        open_ms: 1718833240090,
        orders: [
          {
            leverage: 0.536,
            order_type: "LONG",
            order_uuid: "dac6ce14-af1d-424c-99d1-187a0ad4dfe7",
            price: 115.25399999999999,
            price_sources: [],
            processed_ms: 1718833239731,
          },
          {
            leverage: 0.545,
            order_type: "LONG",
            order_uuid: "f2713faf-5a31-47a0-9697-acd573d3ed60",
            price: 115.247,
            price_sources: [],
            processed_ms: 1718833852203,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "0cf76d91-3d5b-4eec-83f9-e9173c70e6e5",
            price: 115.283,
            price_sources: [],
            processed_ms: 1718835365187,
          },
        ],
        position_type: "FLAT",
        position_uuid: "92eeb6e7-0618-4fa1-be31-3be30e988300",
        return_at_close: 1.0002294069529918,
        trade_pair: ["CADJPY", "CAD/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 115.26088428974599,
        close_ms: 1718854517032,
        current_return: 1.000448286780014,
        initial_entry_price: 115.28,
        is_closed_position: true,
        miner_hotkey: "5GVyVwjpxmQvVyDQQ4do18CEZU2vjhXixoPBACu7c2Vw1ShQ",
        net_leverage: 0.0,
        open_ms: 1718835396440,
        orders: [
          {
            leverage: 0.555,
            order_type: "LONG",
            order_uuid: "6a35fb94-0d85-4132-947e-d81d2380f583",
            price: 115.28,
            price_sources: [],
            processed_ms: 1718835394813,
          },
          {
            leverage: 0.508,
            order_type: "LONG",
            order_uuid: "80425918-8ec1-481e-938b-9987af7c6263",
            price: 115.24,
            price_sources: [],
            processed_ms: 1718838737817,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "a843628c-fe6a-456b-89e6-28e2c9af5aff",
            price: 115.3095,
            price_sources: [],
            processed_ms: 1718854517032,
          },
        ],
        position_type: "FLAT",
        position_uuid: "c19cdd5d-a374-49db-a6fe-76314936b80e",
        return_at_close: 1.0003738434229947,
        trade_pair: ["CADJPY", "CAD/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 115.2793282208589,
        close_ms: 1718857517252,
        current_return: 1.0004420934397862,
        initial_entry_price: 115.2935,
        is_closed_position: true,
        miner_hotkey: "5GVyVwjpxmQvVyDQQ4do18CEZU2vjhXixoPBACu7c2Vw1ShQ",
        net_leverage: 0.0,
        open_ms: 1718854546662,
        orders: [
          {
            leverage: 0.553,
            order_type: "LONG",
            order_uuid: "29dd1ddb-5b26-4d38-b277-d0e44c7d14cb",
            price: 115.2935,
            price_sources: [],
            processed_ms: 1718854546605,
          },
          {
            leverage: 0.588,
            order_type: "LONG",
            order_uuid: "f823766d-2e2c-4551-9b5f-ee32e7c0f142",
            price: 115.266,
            price_sources: [],
            processed_ms: 1718855355312,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "8b25bbe7-a64f-4307-b87b-5adb42e776a7",
            price: 115.324,
            price_sources: [],
            processed_ms: 1718857517252,
          },
        ],
        position_type: "FLAT",
        position_uuid: "96b383b4-1bdd-465a-834c-cd57fcab5b05",
        return_at_close: 1.000362188129783,
        trade_pair: ["CADJPY", "CAD/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 115.32936663385827,
        close_ms: 1718867879880,
        current_return: 1.000450441975298,
        initial_entry_price: 115.33449999999999,
        is_closed_position: true,
        miner_hotkey: "5GVyVwjpxmQvVyDQQ4do18CEZU2vjhXixoPBACu7c2Vw1ShQ",
        net_leverage: 0.0,
        open_ms: 1718857546861,
        orders: [
          {
            leverage: 0.467,
            order_type: "LONG",
            order_uuid: "54da1aed-4e23-4a92-9e97-a39e539228fd",
            price: 115.33449999999999,
            price_sources: [],
            processed_ms: 1718857546769,
          },
          {
            leverage: 0.549,
            order_type: "LONG",
            order_uuid: "6dafa610-7281-4521-bfcf-aa074e298d00",
            price: 115.325,
            price_sources: [],
            processed_ms: 1718858277250,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "59d44fa5-d2b1-4263-b460-317fddac1d29",
            price: 115.3805,
            price_sources: [],
            processed_ms: 1718867879880,
          },
        ],
        position_type: "FLAT",
        position_uuid: "520c9cb7-21a4-400d-8bf8-e9858fdce068",
        return_at_close: 1.0003792899398647,
        trade_pair: ["CADJPY", "CAD/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 115.35086732570238,
        close_ms: 1718870479822,
        current_return: 1.0007882809283377,
        initial_entry_price: 115.3675,
        is_closed_position: true,
        miner_hotkey: "5GVyVwjpxmQvVyDQQ4do18CEZU2vjhXixoPBACu7c2Vw1ShQ",
        net_leverage: 0.0,
        open_ms: 1718867914497,
        orders: [
          {
            leverage: 0.517,
            order_type: "LONG",
            order_uuid: "36116399-11bc-4099-921a-3e8342d64efb",
            price: 115.3675,
            price_sources: [],
            processed_ms: 1718867914248,
          },
          {
            leverage: 0.444,
            order_type: "LONG",
            order_uuid: "d480fc07-cccc-459e-81a7-775c74cb584f",
            price: 115.3315,
            price_sources: [],
            processed_ms: 1718868187843,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "6b1ce0ff-ba67-4234-821d-a188bb5b8aaa",
            price: 115.4455,
            price_sources: [],
            processed_ms: 1718870479822,
          },
        ],
        position_type: "FLAT",
        position_uuid: "e745831d-51db-427f-906d-3cd699825ba9",
        return_at_close: 1.0007209579006797,
        trade_pair: ["CADJPY", "CAD/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 115.446,
        close_ms: 1718881723005,
        current_return: 1.000554804843823,
        initial_entry_price: 115.446,
        is_closed_position: true,
        miner_hotkey: "5GVyVwjpxmQvVyDQQ4do18CEZU2vjhXixoPBACu7c2Vw1ShQ",
        net_leverage: 0.0,
        open_ms: 1718870509856,
        orders: [
          {
            leverage: 0.525,
            order_type: "LONG",
            order_uuid: "b12a5e25-99ad-4f46-9219-f5eacd6fa2e2",
            price: 115.446,
            price_sources: [],
            processed_ms: 1718870509652,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "51daf935-2006-4635-ab17-79e799956dbf",
            price: 115.568,
            price_sources: [],
            processed_ms: 1718881723005,
          },
        ],
        position_type: "FLAT",
        position_uuid: "de116047-0100-4506-8787-93c7a2953cca",
        return_at_close: 1.000518034454745,
        trade_pair: ["CADJPY", "CAD/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 115.53407682206173,
        close_ms: 1718886924811,
        current_return: 1.000526170953854,
        initial_entry_price: 115.5575,
        is_closed_position: true,
        miner_hotkey: "5GVyVwjpxmQvVyDQQ4do18CEZU2vjhXixoPBACu7c2Vw1ShQ",
        net_leverage: 0.0,
        open_ms: 1718881754153,
        orders: [
          {
            leverage: 0.566,
            order_type: "LONG",
            order_uuid: "04c19752-d607-4799-8f89-37dba3b31dda",
            price: 115.5575,
            price_sources: [],
            processed_ms: 1718881752922,
          },
          {
            leverage: 0.532,
            order_type: "LONG",
            order_uuid: "f3f1ce03-9d06-4e32-acb2-ff80bfe34c09",
            price: 115.522,
            price_sources: [],
            processed_ms: 1718881839347,
          },
          {
            leverage: 0.425,
            order_type: "LONG",
            order_uuid: "6512936e-e820-490a-a32e-9d31f67c48de",
            price: 115.518,
            price_sources: [],
            processed_ms: 1718884673563,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "1566b35b-6cf8-416f-923e-4703aecca6f2",
            price: 115.574,
            price_sources: [],
            processed_ms: 1718886924811,
          },
        ],
        position_type: "FLAT",
        position_uuid: "272bbd0a-e23b-43f6-b87e-f361b50671ed",
        return_at_close: 1.0004195048587685,
        trade_pair: ["CADJPY", "CAD/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 115.579,
        close_ms: 1718888295912,
        current_return: 1.0005598638160913,
        initial_entry_price: 115.579,
        is_closed_position: true,
        miner_hotkey: "5GVyVwjpxmQvVyDQQ4do18CEZU2vjhXixoPBACu7c2Vw1ShQ",
        net_leverage: 0.0,
        open_ms: 1718886954611,
        orders: [
          {
            leverage: 0.537,
            order_type: "LONG",
            order_uuid: "2a2a38c4-d7b7-4e65-9717-80ef1c2cf101",
            price: 115.579,
            price_sources: [],
            processed_ms: 1718886954584,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "183ec148-d9e3-4f1c-958c-3c26dbec3aad",
            price: 115.6995,
            price_sources: [],
            processed_ms: 1718888295912,
          },
        ],
        position_type: "FLAT",
        position_uuid: "44aaf0d5-720d-40b4-bb18-a25d010cf16c",
        return_at_close: 1.0005222527708104,
        trade_pair: ["CADJPY", "CAD/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 115.69804644808744,
        close_ms: 1718889983242,
        current_return: 1.000525460712511,
        initial_entry_price: 115.7175,
        is_closed_position: true,
        miner_hotkey: "5GVyVwjpxmQvVyDQQ4do18CEZU2vjhXixoPBACu7c2Vw1ShQ",
        net_leverage: 0.0,
        open_ms: 1718888325828,
        orders: [
          {
            leverage: 0.47,
            order_type: "LONG",
            order_uuid: "8aa86304-51c9-44e9-bbfb-22ccca963e17",
            price: 115.7175,
            price_sources: [],
            processed_ms: 1718888325731,
          },
          {
            leverage: 0.445,
            order_type: "LONG",
            order_uuid: "80ffb3ae-2517-4bec-adfd-f215aea0c906",
            price: 115.67750000000001,
            price_sources: [],
            processed_ms: 1718888892393,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "b1084894-c231-4df1-983e-0dc0c9712ebb",
            price: 115.7645,
            price_sources: [],
            processed_ms: 1718889983242,
          },
        ],
        position_type: "FLAT",
        position_uuid: "359ffe80-c2cc-41f4-a295-318f3ef7c485",
        return_at_close: 1.0004613770567523,
        trade_pair: ["CADJPY", "CAD/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 115.773,
        close_ms: 1718892289038,
        current_return: 1.0005917312326709,
        initial_entry_price: 115.773,
        is_closed_position: true,
        miner_hotkey: "5GVyVwjpxmQvVyDQQ4do18CEZU2vjhXixoPBACu7c2Vw1ShQ",
        net_leverage: 0.0,
        open_ms: 1718890013799,
        orders: [
          {
            leverage: 0.419,
            order_type: "LONG",
            order_uuid: "7bb282ae-8731-43df-84fb-f15e1ac1dc94",
            price: 115.773,
            price_sources: [],
            processed_ms: 1718890013007,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "26e2872f-77cb-471f-a675-b32184f9f4e4",
            price: 115.9365,
            price_sources: [],
            processed_ms: 1718892289038,
          },
        ],
        position_type: "FLAT",
        position_uuid: "3fa2bd88-2022-4ef5-b700-6ee539b0b05b",
        return_at_close: 1.0005623838771938,
        trade_pair: ["CADJPY", "CAD/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 115.90025129778198,
        close_ms: 1718895541231,
        current_return: 1.0003974515426575,
        initial_entry_price: 115.9525,
        is_closed_position: true,
        miner_hotkey: "5GVyVwjpxmQvVyDQQ4do18CEZU2vjhXixoPBACu7c2Vw1ShQ",
        net_leverage: 0.0,
        open_ms: 1718892320874,
        orders: [
          {
            leverage: 0.443,
            order_type: "LONG",
            order_uuid: "aee50f7a-e6fc-430c-a707-f48e4e26a097",
            price: 115.9525,
            price_sources: [],
            processed_ms: 1718892320793,
          },
          {
            leverage: 0.579,
            order_type: "LONG",
            order_uuid: "ec8dd0d2-cb3a-43f3-b36b-3ef89b1c1525",
            price: 115.949,
            price_sources: [],
            processed_ms: 1718892378711,
          },
          {
            leverage: 0.498,
            order_type: "LONG",
            order_uuid: "21c4f2d2-37ee-44fd-ae9c-8704a8c8015d",
            price: 115.884,
            price_sources: [],
            processed_ms: 1718892686937,
          },
          {
            leverage: 0.599,
            order_type: "LONG",
            order_uuid: "d25c29b7-ea73-4c94-add5-e423d1266a19",
            price: 115.828,
            price_sources: [],
            processed_ms: 1718892778725,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "c8f44f87-528c-4f4a-b11c-957e234adca6",
            price: 115.922,
            price_sources: [],
            processed_ms: 1718895541231,
          },
        ],
        position_type: "FLAT",
        position_uuid: "11454b98-223c-4e7d-a95c-0a8517e39e1d",
        return_at_close: 1.0002490625886702,
        trade_pair: ["CADJPY", "CAD/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 115.87785555555557,
        close_ms: 1718901605084,
        current_return: 1.000501449237856,
        initial_entry_price: 115.923,
        is_closed_position: true,
        miner_hotkey: "5GVyVwjpxmQvVyDQQ4do18CEZU2vjhXixoPBACu7c2Vw1ShQ",
        net_leverage: 0.0,
        open_ms: 1718895570776,
        orders: [
          {
            leverage: 0.482,
            order_type: "LONG",
            order_uuid: "7d039a75-ca81-4d36-a3f2-ecbcf59051b5",
            price: 115.923,
            price_sources: [],
            processed_ms: 1718895570671,
          },
          {
            leverage: 0.523,
            order_type: "LONG",
            order_uuid: "d5a569c4-0f3f-49dd-96d6-77c773b5ff7f",
            price: 115.8765,
            price_sources: [],
            processed_ms: 1718896569756,
          },
          {
            leverage: 0.48,
            order_type: "LONG",
            order_uuid: "f6cc8a38-99f3-490a-9465-516d1acaa6d4",
            price: 115.834,
            price_sources: [],
            processed_ms: 1718896987037,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "127215dc-b6ff-4f88-9907-8465519b4275",
            price: 115.917,
            price_sources: [],
            processed_ms: 1718901605084,
          },
        ],
        position_type: "FLAT",
        position_uuid: "1d0458d8-35de-41b6-97c6-d007290d6b7e",
        return_at_close: 1.000397447112208,
        trade_pair: ["CADJPY", "CAD/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 115.90867806841047,
        close_ms: 1718906232654,
        current_return: 1.0006759468553188,
        initial_entry_price: 115.91,
        is_closed_position: true,
        miner_hotkey: "5GVyVwjpxmQvVyDQQ4do18CEZU2vjhXixoPBACu7c2Vw1ShQ",
        net_leverage: 0.0,
        open_ms: 1718901635103,
        orders: [
          {
            leverage: 0.556,
            order_type: "LONG",
            order_uuid: "0d198ba2-bf09-4d62-8e5a-7d5cf5729b8d",
            price: 115.91,
            price_sources: [],
            processed_ms: 1718901634815,
          },
          {
            leverage: 0.438,
            order_type: "LONG",
            order_uuid: "cd8eb5f4-8d5c-4145-b3cc-45b14020d2ec",
            price: 115.90700000000001,
            price_sources: [],
            processed_ms: 1718904133237,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "2bee2be8-3b28-4adf-b747-021dfe684eec",
            price: 115.9875,
            price_sources: [],
            processed_ms: 1718906232654,
          },
        ],
        position_type: "FLAT",
        position_uuid: "5801019d-47e0-42c5-a616-c55234ec68eb",
        return_at_close: 1.0006063198229367,
        trade_pair: ["CADJPY", "CAD/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 115.98,
        close_ms: 1718912888948,
        current_return: 1.00071606311433,
        initial_entry_price: 115.98,
        is_closed_position: true,
        miner_hotkey: "5GVyVwjpxmQvVyDQQ4do18CEZU2vjhXixoPBACu7c2Vw1ShQ",
        net_leverage: 0.0,
        open_ms: 1718906262791,
        orders: [
          {
            leverage: 0.589,
            order_type: "LONG",
            order_uuid: "46e77142-7d5d-4f67-a7a1-67a85099b6cb",
            price: 115.98,
            price_sources: [],
            processed_ms: 1718906262723,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "5f747e53-996a-4ed4-ad7c-dc17594259b4",
            price: 116.121,
            price_sources: [],
            processed_ms: 1718912888948,
          },
        ],
        position_type: "FLAT",
        position_uuid: "eb2bf6b0-9575-4cf0-946f-b54f4d6d43fa",
        return_at_close: 1.0006748035910478,
        trade_pair: ["CADJPY", "CAD/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 116.1016549442897,
        close_ms: 1718917426139,
        current_return: 1.0002268546824542,
        initial_entry_price: 116.125,
        is_closed_position: true,
        miner_hotkey: "5GVyVwjpxmQvVyDQQ4do18CEZU2vjhXixoPBACu7c2Vw1ShQ",
        net_leverage: 0.0,
        open_ms: 1718912918731,
        orders: [
          {
            leverage: 0.495,
            order_type: "LONG",
            order_uuid: "e8f2bbd4-f3fd-420a-8184-8e9466994e54",
            price: 116.125,
            price_sources: [],
            processed_ms: 1718912918636,
          },
          {
            leverage: 0.402,
            order_type: "LONG",
            order_uuid: "6a1767d5-e47c-4ced-857f-556c3131de95",
            price: 116.112,
            price_sources: [],
            processed_ms: 1718913473214,
          },
          {
            leverage: 0.539,
            order_type: "LONG",
            order_uuid: "21bbb7d7-7930-4369-845f-a4f8afd6d119",
            price: 116.07249999999999,
            price_sources: [],
            processed_ms: 1718915734764,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "64b672fa-4a17-4f03-969d-3baa61a6eace",
            price: 116.12,
            price_sources: [],
            processed_ms: 1718917426139,
          },
        ],
        position_type: "FLAT",
        position_uuid: "03d90e6e-de25-40f5-a661-38c5e07eee6c",
        return_at_close: 1.0001263118790216,
        trade_pair: ["CADJPY", "CAD/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 116.07163215712384,
        close_ms: 1718918291019,
        current_return: 0.9997375502828029,
        initial_entry_price: 116.11,
        is_closed_position: true,
        miner_hotkey: "5GVyVwjpxmQvVyDQQ4do18CEZU2vjhXixoPBACu7c2Vw1ShQ",
        net_leverage: 0.0,
        open_ms: 1718917469647,
        orders: [
          {
            leverage: 0.419,
            order_type: "LONG",
            order_uuid: "c17e3534-2177-4475-afb2-32142910300a",
            price: 116.11,
            price_sources: [],
            processed_ms: 1718917469592,
          },
          {
            leverage: 0.507,
            order_type: "LONG",
            order_uuid: "8a346711-6e5c-4b1e-b368-10e562539534",
            price: 116.06450000000001,
            price_sources: [],
            processed_ms: 1718917505957,
          },
          {
            leverage: 0.576,
            order_type: "LONG",
            order_uuid: "c38d4814-56f0-4795-8163-79cc6333d939",
            price: 116.05,
            price_sources: [],
            processed_ms: 1718918217060,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "cde55815-c3f9-4f53-957a-0bb5e1a9b8a7",
            price: 116.0513438504236,
            price_sources: [],
            processed_ms: 1718918291019,
          },
        ],
        position_type: "FLAT",
        position_uuid: "5645fc74-0ef0-4a24-8ab4-73a3b21ae0e5",
        return_at_close: 0.9996324378767661,
        trade_pair: ["CADJPY", "CAD/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 116.02630334394905,
        close_ms: 1718919712462,
        current_return: 1.0000400086169754,
        initial_entry_price: 116.05,
        is_closed_position: true,
        miner_hotkey: "5GVyVwjpxmQvVyDQQ4do18CEZU2vjhXixoPBACu7c2Vw1ShQ",
        net_leverage: 0.0,
        open_ms: 1718918329961,
        orders: [
          {
            leverage: 0.407,
            order_type: "LONG",
            order_uuid: "db1d6fe5-ecb2-4fca-a24b-c059998ffc83",
            price: 116.05,
            price_sources: [],
            processed_ms: 1718918329708,
          },
          {
            leverage: 0.446,
            order_type: "LONG",
            order_uuid: "648f2616-5e22-4b0e-ad89-cc5b6bd4a1d0",
            price: 116.042,
            price_sources: [],
            processed_ms: 1718918457074,
          },
          {
            leverage: 0.403,
            order_type: "LONG",
            order_uuid: "7f690aea-5969-47b5-9f37-021af6f0b798",
            price: 115.985,
            price_sources: [],
            processed_ms: 1718918906087,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "2c3a4083-0963-4443-b978-8d2ab9ccb619",
            price: 116.03,
            price_sources: [],
            processed_ms: 1718919712462,
          },
        ],
        position_type: "FLAT",
        position_uuid: "d9609131-338d-4c52-9967-032a2490db14",
        return_at_close: 0.9999520850994178,
        trade_pair: ["CADJPY", "CAD/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 116.05604809494066,
        close_ms: 1718921246104,
        current_return: 1.0003580483096781,
        initial_entry_price: 116.043,
        is_closed_position: true,
        miner_hotkey: "5GVyVwjpxmQvVyDQQ4do18CEZU2vjhXixoPBACu7c2Vw1ShQ",
        net_leverage: 0.0,
        open_ms: 1718919744178,
        orders: [
          {
            leverage: 0.58,
            order_type: "LONG",
            order_uuid: "59b65996-5e0c-4b84-a31d-a7454da42c8e",
            price: 116.043,
            price_sources: [],
            processed_ms: 1718919743369,
          },
          {
            leverage: 0.534,
            order_type: "LONG",
            order_uuid: "3c21a630-5c26-4790-80b1-33ae0a22f956",
            price: 116.073,
            price_sources: [],
            processed_ms: 1718920408218,
          },
          {
            leverage: 0.487,
            order_type: "LONG",
            order_uuid: "4dde9d62-fdf0-46b9-b8f8-4a489813edad",
            price: 116.053,
            price_sources: [],
            processed_ms: 1718920791987,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "541cd978-2219-441d-8101-6912024e2bf5",
            price: 116.082,
            price_sources: [],
            processed_ms: 1718921246104,
          },
        ],
        position_type: "FLAT",
        position_uuid: "3b35763a-c6bd-43d0-80a0-39a535b19f7d",
        return_at_close: 1.000245938183204,
        trade_pair: ["CADJPY", "CAD/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 116.06582347826085,
        close_ms: 1718926209940,
        current_return: 1.0005813009881035,
        initial_entry_price: 116.081,
        is_closed_position: true,
        miner_hotkey: "5GVyVwjpxmQvVyDQQ4do18CEZU2vjhXixoPBACu7c2Vw1ShQ",
        net_leverage: 0.0,
        open_ms: 1718921275816,
        orders: [
          {
            leverage: 0.587,
            order_type: "LONG",
            order_uuid: "51a79d26-b514-46b5-8bf5-3d88f5903f48",
            price: 116.081,
            price_sources: [],
            processed_ms: 1718921275689,
          },
          {
            leverage: 0.563,
            order_type: "LONG",
            order_uuid: "68fbf91a-6fbc-4527-a48e-2b94d794833b",
            price: 116.05,
            price_sources: [],
            processed_ms: 1718925781387,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "c0c2ed90-b112-40a4-9a72-d01f5baedf70",
            price: 116.12450000000001,
            price_sources: [],
            processed_ms: 1718926209940,
          },
        ],
        position_type: "FLAT",
        position_uuid: "e36bf1cb-aedf-466a-9d28-a89a0305e4c4",
        return_at_close: 1.000500754193374,
        trade_pair: ["CADJPY", "CAD/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 116.11008416979725,
        close_ms: 1718931327827,
        current_return: 1.0005536744840697,
        initial_entry_price: 116.132,
        is_closed_position: true,
        miner_hotkey: "5GVyVwjpxmQvVyDQQ4do18CEZU2vjhXixoPBACu7c2Vw1ShQ",
        net_leverage: 0.0,
        open_ms: 1718926239803,
        orders: [
          {
            leverage: 0.595,
            order_type: "LONG",
            order_uuid: "603d0c5a-b73a-439c-8560-8f512197aab3",
            price: 116.132,
            price_sources: [],
            processed_ms: 1718926239703,
          },
          {
            leverage: 0.411,
            order_type: "LONG",
            order_uuid: "32be21e7-cd60-4fd6-9274-53572358dbcb",
            price: 116.0783568730317,
            price_sources: [],
            processed_ms: 1718927240487,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "f5a41641-0770-48ed-adb8-b73640e3c85a",
            price: 116.174,
            price_sources: [],
            processed_ms: 1718931327827,
          },
        ],
        position_type: "FLAT",
        position_uuid: "f5065b5c-4dc2-4bd5-a093-d188aa4e15e4",
        return_at_close: 1.0004832154943126,
        trade_pair: ["CADJPY", "CAD/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 116.15083141503048,
        close_ms: 1718947315199,
        current_return: 1.0004471435888385,
        initial_entry_price: 116.1685,
        is_closed_position: true,
        miner_hotkey: "5GVyVwjpxmQvVyDQQ4do18CEZU2vjhXixoPBACu7c2Vw1ShQ",
        net_leverage: 0.0,
        open_ms: 1718931359786,
        orders: [
          {
            leverage: 0.588,
            order_type: "LONG",
            order_uuid: "2e61c40d-e094-4d74-8470-d6f95841459f",
            price: 116.1685,
            price_sources: [],
            processed_ms: 1718931357223,
          },
          {
            leverage: 0.405,
            order_type: "LONG",
            order_uuid: "0550c6ff-206e-4d2b-bb4e-cc882510541b",
            price: 116.168,
            price_sources: [],
            processed_ms: 1718932544588,
          },
          {
            leverage: 0.484,
            order_type: "LONG",
            order_uuid: "caba41ef-87e4-4186-966a-f67e71606cce",
            price: 116.115,
            price_sources: [],
            processed_ms: 1718937374417,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "5e0ac779-8909-4637-af4d-de29a491c835",
            price: 116.186,
            price_sources: [],
            processed_ms: 1718947315199,
          },
        ],
        position_type: "FLAT",
        position_uuid: "81ac3476-5c99-4105-9bf3-a5bb4d853dc8",
        return_at_close: 1.0003437073586627,
        trade_pair: ["CADJPY", "CAD/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 116.0621789510985,
        close_ms: 1718977664482,
        current_return: 1.0003985927615442,
        initial_entry_price: 116.185,
        is_closed_position: true,
        miner_hotkey: "5GVyVwjpxmQvVyDQQ4do18CEZU2vjhXixoPBACu7c2Vw1ShQ",
        net_leverage: 0.0,
        open_ms: 1718947346945,
        orders: [
          {
            leverage: 0.434,
            order_type: "LONG",
            order_uuid: "c4449255-cf48-4434-a13c-e7c5eb3eaa49",
            price: 116.185,
            price_sources: [],
            processed_ms: 1718947346696,
          },
          {
            leverage: 0.445,
            order_type: "LONG",
            order_uuid: "123c239e-09c0-4b37-bfc8-8b76caeed2a6",
            price: 116.0245,
            price_sources: [],
            processed_ms: 1718974898791,
          },
          {
            leverage: 0.532,
            order_type: "LONG",
            order_uuid: "1d7750c7-115e-4030-a870-57657bc2c2bb",
            price: 115.9935,
            price_sources: [],
            processed_ms: 1718976832456,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "1f080d92-a7f3-414e-b669-ee7025e22281",
            price: 116.095,
            price_sources: [],
            processed_ms: 1718977664482,
          },
        ],
        position_type: "FLAT",
        position_uuid: "4b3b8c75-955a-4192-b88b-952c3db26bae",
        return_at_close: 1.0002997833925371,
        trade_pair: ["CADJPY", "CAD/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 116.08500000000001,
        close_ms: 1718978913518,
        current_return: 1.0006901408450704,
        initial_entry_price: 116.08500000000001,
        is_closed_position: true,
        miner_hotkey: "5GVyVwjpxmQvVyDQQ4do18CEZU2vjhXixoPBACu7c2Vw1ShQ",
        net_leverage: 0.0,
        open_ms: 1718977694054,
        orders: [
          {
            leverage: 0.545,
            order_type: "LONG",
            order_uuid: "9552d093-863b-499f-be67-ce9e76a7a12c",
            price: 116.08500000000001,
            price_sources: [],
            processed_ms: 1718977693878,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "186b7bac-9106-44ba-bd28-e849d8a6ef6d",
            price: 116.232,
            price_sources: [],
            processed_ms: 1718978913518,
          },
        ],
        position_type: "FLAT",
        position_uuid: "66dfa1e5-8627-4371-a77b-083f4bb6e922",
        return_at_close: 1.000651964516197,
        trade_pair: ["CADJPY", "CAD/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 116.245,
        close_ms: 1718980428198,
        current_return: 1.0007537098369823,
        initial_entry_price: 116.245,
        is_closed_position: true,
        miner_hotkey: "5GVyVwjpxmQvVyDQQ4do18CEZU2vjhXixoPBACu7c2Vw1ShQ",
        net_leverage: 0.0,
        open_ms: 1718978942960,
        orders: [
          {
            leverage: 0.495,
            order_type: "LONG",
            order_uuid: "b42f4753-3af3-4d17-91df-74dfccd6ea20",
            price: 116.245,
            price_sources: [],
            processed_ms: 1718978942821,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "e6a19e31-56d7-4370-8c0a-be1c7d5d6d4b",
            price: 116.422,
            price_sources: [],
            processed_ms: 1718980428198,
          },
        ],
        position_type: "FLAT",
        position_uuid: "41435d8b-e1f6-4943-8f5a-d33e7d0353a2",
        return_at_close: 1.0007190337209364,
        trade_pair: ["CADJPY", "CAD/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 116.3639646191646,
        close_ms: 1718981867967,
        current_return: 1.0003502177479624,
        initial_entry_price: 116.41900000000001,
        is_closed_position: true,
        miner_hotkey: "5GVyVwjpxmQvVyDQQ4do18CEZU2vjhXixoPBACu7c2Vw1ShQ",
        net_leverage: 0.0,
        open_ms: 1718980457905,
        orders: [
          {
            leverage: 0.54,
            order_type: "LONG",
            order_uuid: "7153fb01-3362-4d1e-8094-72ca3dc8cd91",
            price: 116.41900000000001,
            price_sources: [],
            processed_ms: 1718980457769,
          },
          {
            leverage: 0.492,
            order_type: "LONG",
            order_uuid: "777a2170-f774-45cd-a13b-adc26c5ae948",
            price: 116.39,
            price_sources: [],
            processed_ms: 1718980719379,
          },
          {
            leverage: 0.405,
            order_type: "LONG",
            order_uuid: "7230dbb4-4a1c-4fc7-8729-4fee401b3a96",
            price: 116.363,
            price_sources: [],
            processed_ms: 1718981125868,
          },
          {
            leverage: 0.598,
            order_type: "LONG",
            order_uuid: "16f858e4-895e-44b4-a2a5-319982fb733d",
            price: 116.2935,
            price_sources: [],
            processed_ms: 1718981598747,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "9a29fabd-92da-42bd-b793-2d41a1b0fbb9",
            price: 116.384,
            price_sources: [],
            processed_ms: 1718981867967,
          },
        ],
        position_type: "FLAT",
        position_uuid: "8eeb79c7-7473-4841-a70b-e0daf43b855f",
        return_at_close: 1.0002077178594442,
        trade_pair: ["CADJPY", "CAD/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 116.37836394557824,
        close_ms: 1718990934784,
        current_return: 1.0005868161885658,
        initial_entry_price: 116.41550000000001,
        is_closed_position: true,
        miner_hotkey: "5GVyVwjpxmQvVyDQQ4do18CEZU2vjhXixoPBACu7c2Vw1ShQ",
        net_leverage: 0.0,
        open_ms: 1718981898679,
        orders: [
          {
            leverage: 0.457,
            order_type: "LONG",
            order_uuid: "d58d5fde-9d39-482c-a9d7-053e85f13ed3",
            price: 116.41550000000001,
            price_sources: [],
            processed_ms: 1718981898548,
          },
          {
            leverage: 0.434,
            order_type: "LONG",
            order_uuid: "c3823100-63d7-45b9-b689-88cc3f4a3884",
            price: 116.366,
            price_sources: [],
            processed_ms: 1718983032749,
          },
          {
            leverage: 0.432,
            order_type: "LONG",
            order_uuid: "437ff22c-4981-4ceb-9962-bfbb9f1522b1",
            price: 116.3515,
            price_sources: [],
            processed_ms: 1718983142837,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "cb883723-6e22-448c-94f8-1a1b1f456cb0",
            price: 116.43,
            price_sources: [],
            processed_ms: 1718990934784,
          },
        ],
        position_type: "FLAT",
        position_uuid: "0558f495-c0b7-4670-a6d0-5d1c1f9aa766",
        return_at_close: 1.0004941518435186,
        trade_pair: ["CADJPY", "CAD/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 116.41365695488722,
        close_ms: 1718992435252,
        current_return: 1.0003960784650532,
        initial_entry_price: 116.434,
        is_closed_position: true,
        miner_hotkey: "5GVyVwjpxmQvVyDQQ4do18CEZU2vjhXixoPBACu7c2Vw1ShQ",
        net_leverage: 0.0,
        open_ms: 1718990967510,
        orders: [
          {
            leverage: 0.479,
            order_type: "LONG",
            order_uuid: "dad6c0dd-d092-41c7-8bfb-3081eed2474e",
            price: 116.434,
            price_sources: [],
            processed_ms: 1718990966886,
          },
          {
            leverage: 0.585,
            order_type: "LONG",
            order_uuid: "72677d5a-95b8-4030-82b5-29f84bf9a695",
            price: 116.397,
            price_sources: [],
            processed_ms: 1718991349156,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "41c6c39b-05bb-43e6-8542-ea20d1084de6",
            price: 116.457,
            price_sources: [],
            processed_ms: 1718992435252,
          },
        ],
        position_type: "FLAT",
        position_uuid: "ef8ea6ed-8e8d-49d0-ad98-be7d36854a39",
        return_at_close: 1.000321568965129,
        trade_pair: ["CADJPY", "CAD/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 116.43954815772463,
        close_ms: 1718994667213,
        current_return: 1.0005505653670808,
        initial_entry_price: 116.473,
        is_closed_position: true,
        miner_hotkey: "5GVyVwjpxmQvVyDQQ4do18CEZU2vjhXixoPBACu7c2Vw1ShQ",
        net_leverage: 0.0,
        open_ms: 1718992465464,
        orders: [
          {
            leverage: 0.493,
            order_type: "LONG",
            order_uuid: "5bdc8547-096a-4853-8cf5-19b8467864f8",
            price: 116.473,
            price_sources: [],
            processed_ms: 1718992465294,
          },
          {
            leverage: 0.455,
            order_type: "LONG",
            order_uuid: "16285a79-5814-4656-8fc6-48724d74da40",
            price: 116.458,
            price_sources: [],
            processed_ms: 1718992731012,
          },
          {
            leverage: 0.599,
            order_type: "LONG",
            order_uuid: "ce01f6fa-64c0-4ef9-86d3-f2ed148b1969",
            price: 116.398,
            price_sources: [],
            processed_ms: 1718992957276,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "72d00209-519d-4264-be83-d00cc70d8398",
            price: 116.481,
            price_sources: [],
            processed_ms: 1718994667213,
          },
        ],
        position_type: "FLAT",
        position_uuid: "4a8732f9-c6a6-4c69-a400-c9d2eccf813a",
        return_at_close: 1.000442215746357,
        trade_pair: ["CADJPY", "CAD/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 116.48149999999998,
        close_ms: 1719002886836,
        current_return: 1.0004600902289205,
        initial_entry_price: 116.4815,
        is_closed_position: true,
        miner_hotkey: "5GVyVwjpxmQvVyDQQ4do18CEZU2vjhXixoPBACu7c2Vw1ShQ",
        net_leverage: 0.0,
        open_ms: 1718994698528,
        orders: [
          {
            leverage: 0.406,
            order_type: "LONG",
            order_uuid: "4b14d3af-79c7-4779-8c89-1e17d0eac545",
            price: 116.4815,
            price_sources: [],
            processed_ms: 1718994696318,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "36a2dc4e-cc84-41eb-ba55-80c87662c066",
            price: 116.61349999999999,
            price_sources: [],
            processed_ms: 1719002886836,
          },
        ],
        position_type: "FLAT",
        position_uuid: "81253528-7046-4aeb-b561-c42e027fddc2",
        return_at_close: 1.0004316571531562,
        trade_pair: ["CADJPY", "CAD/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 116.60730498533724,
        close_ms: 1719003529320,
        current_return: 0.9998087265586816,
        initial_entry_price: 116.621,
        is_closed_position: true,
        miner_hotkey: "5GVyVwjpxmQvVyDQQ4do18CEZU2vjhXixoPBACu7c2Vw1ShQ",
        net_leverage: 0.0,
        open_ms: 1719002917223,
        orders: [
          {
            leverage: 0.556,
            order_type: "LONG",
            order_uuid: "8ab57024-1a9e-4a20-83c6-bcd969249854",
            price: 116.621,
            price_sources: [],
            processed_ms: 1719002915224,
          },
          {
            leverage: 0.467,
            order_type: "LONG",
            order_uuid: "b7847a9f-58fd-48c5-a1a5-aca19fd4ceee",
            price: 116.591,
            price_sources: [],
            processed_ms: 1719003495804,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "e91922a5-5bc3-4e42-ac03-dd3b58de40b6",
            price: 116.5855,
            price_sources: [],
            processed_ms: 1719003529320,
          },
        ],
        position_type: "FLAT",
        position_uuid: "4d3ad270-16ce-453a-bf53-eb8e9919b381",
        return_at_close: 0.9997371302557727,
        trade_pair: ["CADJPY", "CAD/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 116.63559514170039,
        close_ms: 1719215954604,
        current_return: 1.0005879145375356,
        initial_entry_price: 116.636,
        is_closed_position: true,
        miner_hotkey: "5GVyVwjpxmQvVyDQQ4do18CEZU2vjhXixoPBACu7c2Vw1ShQ",
        net_leverage: 0.0,
        open_ms: 1719003561442,
        orders: [
          {
            leverage: 0.588,
            order_type: "LONG",
            order_uuid: "71fe95dc-4de1-46ea-a613-7319c57a0bb6",
            price: 116.636,
            price_sources: [],
            processed_ms: 1719003560108,
          },
          {
            leverage: 0.4,
            order_type: "LONG",
            order_uuid: "379159c1-0493-477f-87cc-85c143a7d64a",
            price: 116.63499999999999,
            price_sources: [],
            processed_ms: 1719003590817,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "eb43acf4-fe03-4f83-b570-2911a65577fe",
            price: 116.705,
            price_sources: [],
            processed_ms: 1719215954604,
          },
        ],
        position_type: "FLAT",
        position_uuid: "8bffc575-dc0e-45d8-a89b-49e1b0ce40d5",
        return_at_close: 1.0005187138773661,
        trade_pair: ["CADJPY", "CAD/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 116.41099999999997,
        close_ms: 1719230411504,
        current_return: 1.0000069320445024,
        initial_entry_price: 116.488,
        is_closed_position: true,
        miner_hotkey: "5GVyVwjpxmQvVyDQQ4do18CEZU2vjhXixoPBACu7c2Vw1ShQ",
        net_leverage: 0.0,
        open_ms: 1719229984826,
        orders: [
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "a8bc2e1b-88e5-4061-b0af-cac6d4dcaa08",
            price: 116.488,
            price_sources: [],
            processed_ms: 1719229984670,
          },
          {
            leverage: -0.005,
            order_type: "SHORT",
            order_uuid: "ee482473-850e-413a-b9b7-18d0aa01c635",
            price: 116.565,
            price_sources: [],
            processed_ms: 1719230333964,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "b034af7f-681f-444a-b13f-20127d1b2cdd",
            price: 116.57249999999999,
            price_sources: [],
            processed_ms: 1719230411504,
          },
        ],
        position_type: "FLAT",
        position_uuid: "a8bc2e1b-88e5-4061-b0af-cac6d4dcaa08",
        return_at_close: 1.0000062320396501,
        trade_pair: ["CADJPY", "CAD/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 116.61087087576375,
        close_ms: 1719318605954,
        current_return: 1.0078711714799897,
        initial_entry_price: 116.69,
        is_closed_position: true,
        miner_hotkey: "5GVyVwjpxmQvVyDQQ4do18CEZU2vjhXixoPBACu7c2Vw1ShQ",
        net_leverage: 0.0,
        open_ms: 1719313526981,
        orders: [
          {
            leverage: 0.484,
            order_type: "LONG",
            order_uuid: "40252b1f-fc2a-4e66-9d8d-f415dc3c246b",
            price: 116.69,
            price_sources: [],
            processed_ms: 1719313526389,
          },
          {
            leverage: 0.484,
            order_type: "LONG",
            order_uuid: "9266da9c-426c-49cc-97e0-1e18c6e68dd4",
            price: 116.6585,
            price_sources: [],
            processed_ms: 1719314824728,
          },
          {
            leverage: 0.552,
            order_type: "LONG",
            order_uuid: "8c9e330f-e877-45b9-9f71-92816205a77b",
            price: 116.6,
            price_sources: [],
            processed_ms: 1719315168770,
          },
          {
            leverage: 0.442,
            order_type: "LONG",
            order_uuid: "bb174d03-1053-4b7e-9489-561bcfcba66c",
            price: 116.558,
            price_sources: [],
            processed_ms: 1719315784796,
          },
          {
            leverage: 0.493,
            order_type: "LONG",
            order_uuid: "7c757f1e-15d9-4d07-b00d-f68cb9b2dee9",
            price: 116.546,
            price_sources: [],
            processed_ms: 1719315886837,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "abc6939e-bf65-4f33-9a1c-e929c15b0ed3",
            price: 116.985,
            price_sources: [],
            processed_ms: 1719318605954,
          },
        ],
        position_type: "FLAT",
        position_uuid: "89845e26-43a2-46c7-9657-f2d2de50c74a",
        return_at_close: 1.007697968819171,
        trade_pair: ["CADJPY", "CAD/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 3509.930000000001,
        close_ms: 1719320034380,
        current_return: 0.9918295519177379,
        initial_entry_price: 3551.58,
        is_closed_position: true,
        miner_hotkey: "5GVyVwjpxmQvVyDQQ4do18CEZU2vjhXixoPBACu7c2Vw1ShQ",
        net_leverage: 0.0,
        open_ms: 1718529632309,
        orders: [
          {
            leverage: 0.1,
            order_type: "LONG",
            order_uuid: "6ea156bd-194a-417d-9610-dc58bae96809",
            price: 3551.58,
            price_sources: [],
            processed_ms: 1718529631762,
          },
          {
            leverage: 0.1,
            order_type: "LONG",
            order_uuid: "15cec064-f851-4118-9882-6a6fbc42c981",
            price: 3487.31,
            price_sources: [],
            processed_ms: 1719046086078,
          },
          {
            leverage: 0.5,
            order_type: "LONG",
            order_uuid: "9a05d1da-99fa-457b-bd16-969d19f8a8e0",
            price: 3497.17,
            price_sources: [],
            processed_ms: 1719070308656,
          },
          {
            leverage: -0.3,
            order_type: "SHORT",
            order_uuid: "354a388c-b981-43b3-ba8f-79a738d7c36c",
            price: 3501.22,
            price_sources: [],
            processed_ms: 1719070614427,
          },
          {
            leverage: -0.2,
            order_type: "SHORT",
            order_uuid: "2181dae3-e382-4b90-8a2c-ed1485cd3fad",
            price: 3500.61,
            price_sources: [],
            processed_ms: 1719071094049,
          },
          {
            leverage: -0.2,
            order_type: "SHORT",
            order_uuid: "f6a95bd4-d08a-481d-8d3d-d03a7964d9f4",
            price: 3364.84,
            price_sources: [],
            processed_ms: 1719320034380,
          },
        ],
        position_type: "FLAT",
        position_uuid: "01d27d6e-66fb-4493-8013-827024000af4",
        return_at_close: 0.9911352712313954,
        trade_pair: ["ETHUSD", "ETH/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 116.93565561569689,
        close_ms: 1719320907446,
        current_return: 1.000446655152365,
        initial_entry_price: 116.956,
        is_closed_position: true,
        miner_hotkey: "5GVyVwjpxmQvVyDQQ4do18CEZU2vjhXixoPBACu7c2Vw1ShQ",
        net_leverage: 0.0,
        open_ms: 1719318636388,
        orders: [
          {
            leverage: 0.485,
            order_type: "LONG",
            order_uuid: "24026c8d-1db2-49c7-8c85-99037ae75b54",
            price: 116.956,
            price_sources: [],
            processed_ms: 1719318635825,
          },
          {
            leverage: 0.504,
            order_type: "LONG",
            order_uuid: "44e0ac2a-5fae-4354-a294-2ed34ac05a47",
            price: 116.94,
            price_sources: [],
            processed_ms: 1719318713765,
          },
          {
            leverage: 0.489,
            order_type: "LONG",
            order_uuid: "bf1cf67a-5768-46a2-be45-393f373d3053",
            price: 116.911,
            price_sources: [],
            processed_ms: 1719319521298,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "67f84fd6-d51d-4861-9b59-5e42c61105b7",
            price: 116.971,
            price_sources: [],
            processed_ms: 1719320907446,
          },
        ],
        position_type: "FLAT",
        position_uuid: "69b94484-7799-4f35-97aa-1225acb99b27",
        return_at_close: 1.000343148941423,
        trade_pair: ["CADJPY", "CAD/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 116.89272210884356,
        close_ms: 1719327916149,
        current_return: 1.0003839305938411,
        initial_entry_price: 116.9925,
        is_closed_position: true,
        miner_hotkey: "5GVyVwjpxmQvVyDQQ4do18CEZU2vjhXixoPBACu7c2Vw1ShQ",
        net_leverage: 0.0,
        open_ms: 1719320938022,
        orders: [
          {
            leverage: 0.421,
            order_type: "LONG",
            order_uuid: "e357fd04-625b-45a0-b664-8e52acf48946",
            price: 116.9925,
            price_sources: [],
            processed_ms: 1719320937342,
          },
          {
            leverage: 0.463,
            order_type: "LONG",
            order_uuid: "62727a20-ee67-4a16-85f8-44ef1bab7c67",
            price: 116.9805,
            price_sources: [],
            processed_ms: 1719321592265,
          },
          {
            leverage: 0.425,
            order_type: "LONG",
            order_uuid: "6e4061de-a9c6-4f69-8d2a-87e7dcf5a373",
            price: 116.904,
            price_sources: [],
            processed_ms: 1719322245115,
          },
          {
            leverage: 0.585,
            order_type: "LONG",
            order_uuid: "07c4fa8f-16f1-4e85-b3fe-cc56f77f6a1e",
            price: 116.861,
            price_sources: [],
            processed_ms: 1719323252411,
          },
          {
            leverage: 0.583,
            order_type: "LONG",
            order_uuid: "92714faf-30d1-48ae-925f-bfedc5042b7c",
            price: 116.849,
            price_sources: [],
            processed_ms: 1719323336364,
          },
          {
            leverage: 0.463,
            order_type: "LONG",
            order_uuid: "9b5d272d-7312-4f49-98a9-b86c581f8a3b",
            price: 116.799,
            price_sources: [],
            processed_ms: 1719325981337,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "2245a408-f9f3-435e-8201-8b56d92ca644",
            price: 116.908,
            price_sources: [],
            processed_ms: 1719327916149,
          },
        ],
        position_type: "FLAT",
        position_uuid: "553c7f0a-1008-4b8f-985d-94a3b4ec0b09",
        return_at_close: 1.0001780515809249,
        trade_pair: ["CADJPY", "CAD/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 116.86518211636495,
        close_ms: 1719334793346,
        current_return: 1.0004852345085162,
        initial_entry_price: 116.906,
        is_closed_position: true,
        miner_hotkey: "5GVyVwjpxmQvVyDQQ4do18CEZU2vjhXixoPBACu7c2Vw1ShQ",
        net_leverage: 0.0,
        open_ms: 1719327946641,
        orders: [
          {
            leverage: 0.408,
            order_type: "LONG",
            order_uuid: "7bc04a03-ab55-40a6-b8d8-ab1040c376a0",
            price: 116.906,
            price_sources: [],
            processed_ms: 1719327945947,
          },
          {
            leverage: 0.584,
            order_type: "LONG",
            order_uuid: "6f9511b4-f8d0-4ac6-976d-ff062f2a6f39",
            price: 116.855,
            price_sources: [],
            processed_ms: 1719331573966,
          },
          {
            leverage: 0.508,
            order_type: "LONG",
            order_uuid: "2ecd838b-a05f-42fe-b48e-2153d5e5dea3",
            price: 116.84410467430595,
            price_sources: [],
            processed_ms: 1719332526434,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "e7070329-075a-4aef-93cf-6643b6d40022",
            price: 116.903,
            price_sources: [],
            processed_ms: 1719334793346,
          },
        ],
        position_type: "FLAT",
        position_uuid: "96cda532-12ec-4599-afe3-98402ab3e57a",
        return_at_close: 1.0003801835588928,
        trade_pair: ["CADJPY", "CAD/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 116.87529919802591,
        close_ms: 1719336598945,
        current_return: 0.9999265221631054,
        initial_entry_price: 116.906,
        is_closed_position: true,
        miner_hotkey: "5GVyVwjpxmQvVyDQQ4do18CEZU2vjhXixoPBACu7c2Vw1ShQ",
        net_leverage: 0.0,
        open_ms: 1719334823933,
        orders: [
          {
            leverage: 0.576,
            order_type: "LONG",
            order_uuid: "9cf24aac-1651-41c9-b8cd-8bc3ad42652a",
            price: 116.906,
            price_sources: [],
            processed_ms: 1719334823396,
          },
          {
            leverage: 0.479,
            order_type: "LONG",
            order_uuid: "5b46a008-58dc-4403-b8f4-b2d115319ee4",
            price: 116.886,
            price_sources: [],
            processed_ms: 1719334963012,
          },
          {
            leverage: 0.566,
            order_type: "LONG",
            order_uuid: "0ac7c6f6-af82-40b9-b31e-8ea9e6e86163",
            price: 116.835,
            price_sources: [],
            processed_ms: 1719335195526,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "371912a7-f929-4741-98f3-2d9605cd6b23",
            price: 116.87,
            price_sources: [],
            processed_ms: 1719336598945,
          },
        ],
        position_type: "FLAT",
        position_uuid: "6bc44f8a-8cf8-4191-959c-a664608b06b8",
        return_at_close: 0.9998130605006356,
        trade_pair: ["CADJPY", "CAD/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 116.88904936305732,
        close_ms: 1719349210408,
        current_return: 0.9998867791361182,
        initial_entry_price: 116.89099999999999,
        is_closed_position: true,
        miner_hotkey: "5GVyVwjpxmQvVyDQQ4do18CEZU2vjhXixoPBACu7c2Vw1ShQ",
        net_leverage: 0.0,
        open_ms: 1719336629717,
        orders: [
          {
            leverage: 0.417,
            order_type: "LONG",
            order_uuid: "2a2a0099-b244-4c87-928c-d12dc6256a1f",
            price: 116.89099999999999,
            price_sources: [],
            processed_ms: 1719336629560,
          },
          {
            leverage: 0.525,
            order_type: "LONG",
            order_uuid: "2db85947-e500-43fe-b60f-178de96bdcd5",
            price: 116.8875,
            price_sources: [],
            processed_ms: 1719345347766,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "a5e46a86-3fa5-4ce2-b367-e2b812e95281",
            price: 116.875,
            price_sources: [],
            processed_ms: 1719349210408,
          },
        ],
        position_type: "FLAT",
        position_uuid: "35e563bf-1937-43d7-97f8-af2fe666f0cd",
        return_at_close: 0.999820846601902,
        trade_pair: ["CADJPY", "CAD/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 116.89314937286201,
        close_ms: 1719349567649,
        current_return: 0.9998713650359221,
        initial_entry_price: 116.92,
        is_closed_position: true,
        miner_hotkey: "5GVyVwjpxmQvVyDQQ4do18CEZU2vjhXixoPBACu7c2Vw1ShQ",
        net_leverage: 0.0,
        open_ms: 1719349265920,
        orders: [
          {
            leverage: 0.471,
            order_type: "LONG",
            order_uuid: "5a1f6d66-1921-4307-bdbd-4e57e22e140d",
            price: 116.92,
            price_sources: [],
            processed_ms: 1719349265832,
          },
          {
            leverage: 0.406,
            order_type: "LONG",
            order_uuid: "aafb9c32-a2df-4dce-9bbb-87aa1892763c",
            price: 116.862,
            price_sources: [],
            processed_ms: 1719349538052,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "4b92efec-16ce-48a6-87e5-a80bdc83d4ec",
            price: 116.876,
            price_sources: [],
            processed_ms: 1719349567649,
          },
        ],
        position_type: "FLAT",
        position_uuid: "b5e0eda0-1042-4627-8579-fc44fa14becd",
        return_at_close: 0.9998099829328225,
        trade_pair: ["CADJPY", "CAD/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 116.82745893561105,
        close_ms: 1719350751122,
        current_return: 1.000124248128342,
        initial_entry_price: 116.875,
        is_closed_position: true,
        miner_hotkey: "5GVyVwjpxmQvVyDQQ4do18CEZU2vjhXixoPBACu7c2Vw1ShQ",
        net_leverage: 0.0,
        open_ms: 1719349600058,
        orders: [
          {
            leverage: 0.545,
            order_type: "LONG",
            order_uuid: "423bd604-2498-4288-ad36-6ebe6e167b94",
            price: 116.875,
            price_sources: [],
            processed_ms: 1719349599823,
          },
          {
            leverage: 0.564,
            order_type: "LONG",
            order_uuid: "ae519ae6-f562-4567-aee9-931651d2e8c0",
            price: 116.843,
            price_sources: [],
            processed_ms: 1719350164644,
          },
          {
            leverage: 0.413,
            order_type: "LONG",
            order_uuid: "fe8a2fb4-47f6-4c22-bd9b-64089c997bd7",
            price: 116.7435,
            price_sources: [],
            processed_ms: 1719350582488,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "2bf34efb-12f2-4c89-ae74-daec0b021582",
            price: 116.837,
            price_sources: [],
            processed_ms: 1719350751122,
          },
        ],
        position_type: "FLAT",
        position_uuid: "ef31b258-ea33-42f7-8011-db234e6a6cb7",
        return_at_close: 1.0000176948909465,
        trade_pair: ["CADJPY", "CAD/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 116.851,
        close_ms: 1719351592996,
        current_return: 1.0000943509255376,
        initial_entry_price: 116.851,
        is_closed_position: true,
        miner_hotkey: "5GVyVwjpxmQvVyDQQ4do18CEZU2vjhXixoPBACu7c2Vw1ShQ",
        net_leverage: 0.0,
        open_ms: 1719350780303,
        orders: [
          {
            leverage: 0.525,
            order_type: "LONG",
            order_uuid: "b4fbba8b-5ffa-4c8c-a5b2-47738335ea0e",
            price: 116.851,
            price_sources: [],
            processed_ms: 1719350779668,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "23aa6a7c-26f2-4c74-b91c-b3fec2650016",
            price: 116.872,
            price_sources: [],
            processed_ms: 1719351592996,
          },
        ],
        position_type: "FLAT",
        position_uuid: "55ce9d16-407b-4c63-bf81-ea1fe9371a1e",
        return_at_close: 1.000057597458141,
        trade_pair: ["CADJPY", "CAD/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 116.86702290076336,
        close_ms: 1719361381152,
        current_return: 1.0004212724083343,
        initial_entry_price: 116.865,
        is_closed_position: true,
        miner_hotkey: "5GVyVwjpxmQvVyDQQ4do18CEZU2vjhXixoPBACu7c2Vw1ShQ",
        net_leverage: 0.0,
        open_ms: 1719351627610,
        orders: [
          {
            leverage: 0.518,
            order_type: "LONG",
            order_uuid: "84ea67ba-8a41-425f-947e-647224e9b485",
            price: 116.865,
            price_sources: [],
            processed_ms: 1719351627083,
          },
          {
            leverage: 0.53,
            order_type: "LONG",
            order_uuid: "00c631c6-c59d-42f9-ba9c-5ea77242393f",
            price: 116.869,
            price_sources: [],
            processed_ms: 1719351815134,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "df111eee-6172-4dbf-9289-59390223c569",
            price: 116.914,
            price_sources: [],
            processed_ms: 1719361381152,
          },
        ],
        position_type: "FLAT",
        position_uuid: "44b78637-5eef-4ca6-af27-d87f3c8804df",
        return_at_close: 1.0003478815037905,
        trade_pair: ["CADJPY", "CAD/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 116.90518021032504,
        close_ms: 1719364820298,
        current_return: 1.0006604639426573,
        initial_entry_price: 116.911,
        is_closed_position: true,
        miner_hotkey: "5GVyVwjpxmQvVyDQQ4do18CEZU2vjhXixoPBACu7c2Vw1ShQ",
        net_leverage: 0.0,
        open_ms: 1719361410628,
        orders: [
          {
            leverage: 0.559,
            order_type: "LONG",
            order_uuid: "d854aab0-1fe9-437f-88a7-5aafd8a91e12",
            price: 116.911,
            price_sources: [],
            processed_ms: 1719361410601,
          },
          {
            leverage: 0.487,
            order_type: "LONG",
            order_uuid: "0a13b18d-6445-4fcb-a520-c5fbc92d1c92",
            price: 116.8985,
            price_sources: [],
            processed_ms: 1719361811422,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "98b99814-0ba9-4513-bf81-51d47eea1b00",
            price: 116.979,
            price_sources: [],
            processed_ms: 1719364820298,
          },
        ],
        position_type: "FLAT",
        position_uuid: "284b95cc-65cf-4232-92ca-f412e97f31d8",
        return_at_close: 1.0005871955834875,
        trade_pair: ["CADJPY", "CAD/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 116.97334259259259,
        close_ms: 1719383571289,
        current_return: 1.0005587868219044,
        initial_entry_price: 116.982,
        is_closed_position: true,
        miner_hotkey: "5GVyVwjpxmQvVyDQQ4do18CEZU2vjhXixoPBACu7c2Vw1ShQ",
        net_leverage: 0.0,
        open_ms: 1719364850079,
        orders: [
          {
            leverage: 0.424,
            order_type: "LONG",
            order_uuid: "89f999a7-0af2-480f-afb1-d3c6fdf2bad3",
            price: 116.982,
            price_sources: [],
            processed_ms: 1719364849832,
          },
          {
            leverage: 0.44,
            order_type: "LONG",
            order_uuid: "1f721a41-3962-479c-b7fe-da1012f31ce1",
            price: 116.965,
            price_sources: [],
            processed_ms: 1719377870839,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "aab57a61-f2fa-4bb4-9b93-b37b706401f2",
            price: 117.049,
            price_sources: [],
            processed_ms: 1719383571289,
          },
        ],
        position_type: "FLAT",
        position_uuid: "429ce98b-be8d-46e7-a900-25dbb8837744",
        return_at_close: 1.0004982730264773,
        trade_pair: ["CADJPY", "CAD/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 116.9999511111111,
        close_ms: 1719393209031,
        current_return: 1.000565863786666,
        initial_entry_price: 117.037,
        is_closed_position: true,
        miner_hotkey: "5GVyVwjpxmQvVyDQQ4do18CEZU2vjhXixoPBACu7c2Vw1ShQ",
        net_leverage: 0.0,
        open_ms: 1719383600939,
        orders: [
          {
            leverage: 0.519,
            order_type: "LONG",
            order_uuid: "ac95d81d-1400-4c76-a86d-bb575998cb29",
            price: 117.037,
            price_sources: [],
            processed_ms: 1719383600876,
          },
          {
            leverage: 0.56,
            order_type: "LONG",
            order_uuid: "b4e37dbf-1a95-4d7b-a08a-aa5f96d7aded",
            price: 117.001,
            price_sources: [],
            processed_ms: 1719384137473,
          },
          {
            leverage: 0.496,
            order_type: "LONG",
            order_uuid: "895c0e94-f1ee-496f-bc2e-ff0b82379dde",
            price: 116.96,
            price_sources: [],
            processed_ms: 1719388127970,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "c6e6f346-d55c-471b-8f03-de5ab820d648",
            price: 117.042,
            price_sources: [],
            processed_ms: 1719393209031,
          },
        ],
        position_type: "FLAT",
        position_uuid: "da0d60ba-3e0f-4e4d-bcfb-5d7c890abfd8",
        return_at_close: 1.0004555514001834,
        trade_pair: ["CADJPY", "CAD/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 117.04266037735849,
        close_ms: 1719394531483,
        current_return: 1.0004739875010145,
        initial_entry_price: 117.0495,
        is_closed_position: true,
        miner_hotkey: "5GVyVwjpxmQvVyDQQ4do18CEZU2vjhXixoPBACu7c2Vw1ShQ",
        net_leverage: 0.0,
        open_ms: 1719393238374,
        orders: [
          {
            leverage: 0.56,
            order_type: "LONG",
            order_uuid: "bfd45fcb-20c5-46d0-9166-08033fc9e45e",
            price: 117.0495,
            price_sources: [],
            processed_ms: 1719393238194,
          },
          {
            leverage: 0.5,
            order_type: "LONG",
            order_uuid: "949dfec5-8861-4bb8-b975-4ae121d80cc1",
            price: 117.035,
            price_sources: [],
            processed_ms: 1719394022899,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "a3f62f87-2b6d-4715-ac37-7a0d81d3ebbe",
            price: 117.095,
            price_sources: [],
            processed_ms: 1719394531483,
          },
        ],
        position_type: "FLAT",
        position_uuid: "0ef83bbd-71be-418d-b7a1-f45e21ab1038",
        return_at_close: 1.0003997523311419,
        trade_pair: ["CADJPY", "CAD/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 117.03915319148938,
        close_ms: 1719399271719,
        current_return: 1.000777746452401,
        initial_entry_price: 117.051,
        is_closed_position: true,
        miner_hotkey: "5GVyVwjpxmQvVyDQQ4do18CEZU2vjhXixoPBACu7c2Vw1ShQ",
        net_leverage: 0.0,
        open_ms: 1719394560899,
        orders: [
          {
            leverage: 0.476,
            order_type: "LONG",
            order_uuid: "d24a7d78-ebde-40f6-b463-376840bbf9e0",
            price: 117.051,
            price_sources: [],
            processed_ms: 1719394560710,
          },
          {
            leverage: 0.464,
            order_type: "LONG",
            order_uuid: "3205ec67-412a-4e6a-b593-ce3e16904d5f",
            price: 117.027,
            price_sources: [],
            processed_ms: 1719395209596,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "2dc64ca7-87c1-478f-ad92-4ed660f8ca79",
            price: 117.136,
            price_sources: [],
            processed_ms: 1719399271719,
          },
        ],
        position_type: "FLAT",
        position_uuid: "480a968c-f4a1-4958-8982-ad8a25c8e62f",
        return_at_close: 1.0007118952766845,
        trade_pair: ["CADJPY", "CAD/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 117.066191875,
        close_ms: 1719402345292,
        current_return: 1.0005985329781057,
        initial_entry_price: 117.108,
        is_closed_position: true,
        miner_hotkey: "5GVyVwjpxmQvVyDQQ4do18CEZU2vjhXixoPBACu7c2Vw1ShQ",
        net_leverage: 0.0,
        open_ms: 1719399301734,
        orders: [
          {
            leverage: 0.534,
            order_type: "LONG",
            order_uuid: "de9b3b67-0fff-4f3e-b6c9-5c6623c550e6",
            price: 117.108,
            price_sources: [],
            processed_ms: 1719399301680,
          },
          {
            leverage: 0.534,
            order_type: "LONG",
            order_uuid: "f656e9f8-8655-447d-8d81-2e0b7a5b8f1b",
            price: 117.0445,
            price_sources: [],
            processed_ms: 1719399977096,
          },
          {
            leverage: 0.532,
            order_type: "LONG",
            order_uuid: "3c48de41-ff68-4c04-a584-d5ca491ca757",
            price: 117.046,
            price_sources: [],
            processed_ms: 1719401044714,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "ad16a9ee-2850-456c-987d-0725fb26bcad",
            price: 117.11,
            price_sources: [],
            processed_ms: 1719402345292,
          },
        ],
        position_type: "FLAT",
        position_uuid: "6b77e17c-03a9-4a0c-8773-c4c8e3118e2e",
        return_at_close: 1.0004864659424122,
        trade_pair: ["CADJPY", "CAD/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 117.10209791477787,
        close_ms: 1719403384106,
        current_return: 1.0007054528997883,
        initial_entry_price: 117.112,
        is_closed_position: true,
        miner_hotkey: "5GVyVwjpxmQvVyDQQ4do18CEZU2vjhXixoPBACu7c2Vw1ShQ",
        net_leverage: 0.0,
        open_ms: 1719402377038,
        orders: [
          {
            leverage: 0.595,
            order_type: "LONG",
            order_uuid: "2e32b459-2f07-445d-8a5d-3403b53c1ccf",
            price: 117.112,
            price_sources: [],
            processed_ms: 1719402376976,
          },
          {
            leverage: 0.508,
            order_type: "LONG",
            order_uuid: "7bb6d11f-3608-4dbb-a1f1-5167aebd0b4a",
            price: 117.09049999999999,
            price_sources: [],
            processed_ms: 1719402543000,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "b16d4c7e-27ea-481e-9f14-17eeb862b9f6",
            price: 117.177,
            price_sources: [],
            processed_ms: 1719403384106,
          },
        ],
        position_type: "FLAT",
        position_uuid: "a67b2f51-c10e-4cde-8fb3-9123691dfbe9",
        return_at_close: 1.0006281884317698,
        trade_pair: ["CADJPY", "CAD/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 117.07190791515778,
        close_ms: 1719407581188,
        current_return: 1.0012057210884064,
        initial_entry_price: 117.1805,
        is_closed_position: true,
        miner_hotkey: "5GVyVwjpxmQvVyDQQ4do18CEZU2vjhXixoPBACu7c2Vw1ShQ",
        net_leverage: 0.0,
        open_ms: 1719403413861,
        orders: [
          {
            leverage: 0.44,
            order_type: "LONG",
            order_uuid: "bfc2a07e-fd5e-4dc4-b468-1bd324e7ce5a",
            price: 117.1805,
            price_sources: [],
            processed_ms: 1719403413715,
          },
          {
            leverage: 0.557,
            order_type: "LONG",
            order_uuid: "0d226c96-74f6-4bac-94ca-039b8e7cb70c",
            price: 117.137,
            price_sources: [],
            processed_ms: 1719404152868,
          },
          {
            leverage: 0.441,
            order_type: "LONG",
            order_uuid: "39530457-4a1c-4491-be30-9d9ee824eb5a",
            price: 117.044,
            price_sources: [],
            processed_ms: 1719407112144,
          },
          {
            leverage: 0.495,
            order_type: "LONG",
            order_uuid: "9806cd3b-86be-441c-925c-3319ede7d88c",
            price: 116.927,
            price_sources: [],
            processed_ms: 1719407141661,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "3cecf364-a8d0-4d60-ba89-6cfb8dc9407d",
            price: 117.145,
            price_sources: [],
            processed_ms: 1719407581188,
          },
        ],
        position_type: "FLAT",
        position_uuid: "78995f27-6254-4dd3-83cc-50788e5afbe1",
        return_at_close: 1.001070247942286,
        trade_pair: ["CADJPY", "CAD/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 117.11335796045785,
        close_ms: 1719408555162,
        current_return: 1.0007519210409481,
        initial_entry_price: 117.124,
        is_closed_position: true,
        miner_hotkey: "5GVyVwjpxmQvVyDQQ4do18CEZU2vjhXixoPBACu7c2Vw1ShQ",
        net_leverage: 0.0,
        open_ms: 1719407610311,
        orders: [
          {
            leverage: 0.474,
            order_type: "LONG",
            order_uuid: "c089d8c7-b169-4627-840c-b86258eef820",
            price: 117.124,
            price_sources: [],
            processed_ms: 1719407609932,
          },
          {
            leverage: 0.487,
            order_type: "LONG",
            order_uuid: "c8573328-a802-4e1c-8c7f-707b9598674f",
            price: 117.103,
            price_sources: [],
            processed_ms: 1719407674007,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "f13a753f-8094-4d25-babc-a6003127dc26",
            price: 117.205,
            price_sources: [],
            processed_ms: 1719408555162,
          },
        ],
        position_type: "FLAT",
        position_uuid: "01675a0e-a547-4cec-ab23-7ab96f08f054",
        return_at_close: 1.0006846004592198,
        trade_pair: ["CADJPY", "CAD/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 117.18376463700234,
        close_ms: 1719411244960,
        current_return: 1.000621009435733,
        initial_entry_price: 117.214,
        is_closed_position: true,
        miner_hotkey: "5GVyVwjpxmQvVyDQQ4do18CEZU2vjhXixoPBACu7c2Vw1ShQ",
        net_leverage: 0.0,
        open_ms: 1719408584736,
        orders: [
          {
            leverage: 0.401,
            order_type: "LONG",
            order_uuid: "ebc5520e-038d-4257-8e41-717bade6b902",
            price: 117.214,
            price_sources: [],
            processed_ms: 1719408584660,
          },
          {
            leverage: 0.453,
            order_type: "LONG",
            order_uuid: "ca17ef36-02f9-41be-927b-2ecd16e0b0d7",
            price: 117.15700000000001,
            price_sources: [],
            processed_ms: 1719408773023,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "32ae982a-54d1-40d4-935a-e5e13147a709",
            price: 117.269,
            price_sources: [],
            processed_ms: 1719411244960,
          },
        ],
        position_type: "FLAT",
        position_uuid: "c0fd2485-f346-4da2-83ac-26c53e2eff3e",
        return_at_close: 1.000561192311789,
        trade_pair: ["CADJPY", "CAD/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 117.23923586744641,
        close_ms: 1719419202006,
        current_return: 1.0004967248908296,
        initial_entry_price: 117.248,
        is_closed_position: true,
        miner_hotkey: "5GVyVwjpxmQvVyDQQ4do18CEZU2vjhXixoPBACu7c2Vw1ShQ",
        net_leverage: 0.0,
        open_ms: 1719411274695,
        orders: [
          {
            leverage: 0.464,
            order_type: "LONG",
            order_uuid: "95ab3995-b037-45e3-bb2e-92ac0a34e752",
            price: 117.248,
            price_sources: [],
            processed_ms: 1719411274612,
          },
          {
            leverage: 0.562,
            order_type: "LONG",
            order_uuid: "bdf6224f-7355-4ec7-85f5-6345f2833932",
            price: 117.232,
            price_sources: [],
            processed_ms: 1719418990446,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "99caf7fe-b299-4c5c-82fb-43e2d5da4fd4",
            price: 117.296,
            price_sources: [],
            processed_ms: 1719419202006,
          },
        ],
        position_type: "FLAT",
        position_uuid: "d548bffe-f67a-4f50-86b2-138ddda52422",
        return_at_close: 1.000424869216048,
        trade_pair: ["CADJPY", "CAD/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 117.2574538077403,
        close_ms: 1719422755196,
        current_return: 1.00059474321375,
        initial_entry_price: 117.296,
        is_closed_position: true,
        miner_hotkey: "5GVyVwjpxmQvVyDQQ4do18CEZU2vjhXixoPBACu7c2Vw1ShQ",
        net_leverage: 0.0,
        open_ms: 1719419231928,
        orders: [
          {
            leverage: 0.586,
            order_type: "LONG",
            order_uuid: "70d0d673-0b5c-4a40-8e09-78dd531b9aa9",
            price: 117.296,
            price_sources: [],
            processed_ms: 1719419231790,
          },
          {
            leverage: 0.47,
            order_type: "LONG",
            order_uuid: "b58c7029-ac12-4832-a093-0aa7b4f826af",
            price: 117.25,
            price_sources: [],
            processed_ms: 1719421148010,
          },
          {
            leverage: 0.546,
            order_type: "LONG",
            order_uuid: "b4a1ae0c-a98c-4beb-98b6-178214434223",
            price: 117.2225,
            price_sources: [],
            processed_ms: 1719421451941,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "246d9b0e-8360-464e-96ea-98bc66c3c09e",
            price: 117.301,
            price_sources: [],
            processed_ms: 1719422755196,
          },
        ],
        position_type: "FLAT",
        position_uuid: "c8a6f6e2-896c-42c2-877b-d23ee9e6eb47",
        return_at_close: 1.000482536519246,
        trade_pair: ["CADJPY", "CAD/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 117.28342054433716,
        close_ms: 1719430936741,
        current_return: 1.0005639791638246,
        initial_entry_price: 117.296,
        is_closed_position: true,
        miner_hotkey: "5GVyVwjpxmQvVyDQQ4do18CEZU2vjhXixoPBACu7c2Vw1ShQ",
        net_leverage: 0.0,
        open_ms: 1719422786080,
        orders: [
          {
            leverage: 0.542,
            order_type: "LONG",
            order_uuid: "a4371f91-55c7-4985-855c-c13b7f657816",
            price: 117.296,
            price_sources: [],
            processed_ms: 1719422785547,
          },
          {
            leverage: 0.597,
            order_type: "LONG",
            order_uuid: "680e8ec1-cdb6-49e3-8596-12768f9ae7c8",
            price: 117.272,
            price_sources: [],
            processed_ms: 1719423103970,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "27bc761e-edbd-424d-bf23-a3d7e1b5c23d",
            price: 117.3415,
            price_sources: [],
            processed_ms: 1719430936741,
          },
        ],
        position_type: "FLAT",
        position_uuid: "f03e7dbb-943b-463a-8f62-c839b5614b43",
        return_at_close: 1.000484204197766,
        trade_pair: ["CADJPY", "CAD/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 117.31467194244605,
        close_ms: 1719431863712,
        current_return: 1.0006435602219172,
        initial_entry_price: 117.341,
        is_closed_position: true,
        miner_hotkey: "5GVyVwjpxmQvVyDQQ4do18CEZU2vjhXixoPBACu7c2Vw1ShQ",
        net_leverage: 0.0,
        open_ms: 1719430967162,
        orders: [
          {
            leverage: 0.434,
            order_type: "LONG",
            order_uuid: "92a5e075-e606-4379-87ef-43f1e5373c75",
            price: 117.341,
            price_sources: [],
            processed_ms: 1719430965851,
          },
          {
            leverage: 0.492,
            order_type: "LONG",
            order_uuid: "513fb352-8981-4dd4-bbe4-1ff0332d9fc8",
            price: 117.31,
            price_sources: [],
            processed_ms: 1719431059658,
          },
          {
            leverage: 0.464,
            order_type: "LONG",
            order_uuid: "4cad6583-8b54-4972-bdf0-d6e99d9f347b",
            price: 117.295,
            price_sources: [],
            processed_ms: 1719431198825,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "6fc9f2ad-84fa-4543-bb28-3bcd3025c137",
            price: 117.369,
            price_sources: [],
            processed_ms: 1719431863712,
          },
        ],
        position_type: "FLAT",
        position_uuid: "1c0d662c-1357-43a7-af15-ec8c1789eb5f",
        return_at_close: 1.0005461976035077,
        trade_pair: ["CADJPY", "CAD/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 117.32072137791288,
        close_ms: 1719435910840,
        current_return: 0.9998953704334785,
        initial_entry_price: 117.376,
        is_closed_position: true,
        miner_hotkey: "5GVyVwjpxmQvVyDQQ4do18CEZU2vjhXixoPBACu7c2Vw1ShQ",
        net_leverage: 0.0,
        open_ms: 1719431895805,
        orders: [
          {
            leverage: 0.535,
            order_type: "LONG",
            order_uuid: "42ba5b99-562c-42a1-b9bd-0baf9b6f4283",
            price: 117.376,
            price_sources: [],
            processed_ms: 1719431892798,
          },
          {
            leverage: 0.433,
            order_type: "LONG",
            order_uuid: "2bddd822-fd28-41b2-b9b1-0f42509ee8ff",
            price: 117.304,
            price_sources: [],
            processed_ms: 1719435603704,
          },
          {
            leverage: 0.496,
            order_type: "LONG",
            order_uuid: "7b019351-b57a-4e98-bb5f-a31b4104a50f",
            price: 117.297,
            price_sources: [],
            processed_ms: 1719435651479,
          },
          {
            leverage: 0.51,
            order_type: "LONG",
            order_uuid: "9ebdb731-7d6d-4209-863b-96fabbe44974",
            price: 117.3,
            price_sources: [],
            processed_ms: 1719435838403,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "628fa925-7262-4fd9-b00e-4796f57251fa",
            price: 117.3145,
            price_sources: [],
            processed_ms: 1719435910840,
          },
        ],
        position_type: "FLAT",
        position_uuid: "a99f6108-3258-48d8-b001-19c996288a7d",
        return_at_close: 0.999757204891192,
        trade_pair: ["CADJPY", "CAD/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 117.30831910112359,
        close_ms: 1719436029603,
        current_return: 1.0003161680729566,
        initial_entry_price: 117.33,
        is_closed_position: true,
        miner_hotkey: "5GVyVwjpxmQvVyDQQ4do18CEZU2vjhXixoPBACu7c2Vw1ShQ",
        net_leverage: 0.0,
        open_ms: 1719435949170,
        orders: [
          {
            leverage: 0.488,
            order_type: "LONG",
            order_uuid: "16bd4a26-cab5-436e-887d-3a90c9c4d0cb",
            price: 117.33,
            price_sources: [],
            processed_ms: 1719435949040,
          },
          {
            leverage: 0.402,
            order_type: "LONG",
            order_uuid: "95881752-506b-4757-b84a-3f4d6c14743a",
            price: 117.282,
            price_sources: [],
            processed_ms: 1719435997288,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "6fe8929b-a545-423c-861f-c72084107a28",
            price: 117.35,
            price_sources: [],
            processed_ms: 1719436029603,
          },
        ],
        position_type: "FLAT",
        position_uuid: "93da5aba-260e-4d75-a014-8ece05043d32",
        return_at_close: 1.0002538483756858,
        trade_pair: ["CADJPY", "CAD/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 117.28369828134944,
        close_ms: 1719436159178,
        current_return: 0.9999370973240158,
        initial_entry_price: 117.34,
        is_closed_position: true,
        miner_hotkey: "5GVyVwjpxmQvVyDQQ4do18CEZU2vjhXixoPBACu7c2Vw1ShQ",
        net_leverage: 0.0,
        open_ms: 1719436060999,
        orders: [
          {
            leverage: 0.49,
            order_type: "LONG",
            order_uuid: "a779b958-deb3-40f9-9120-4e556b153c83",
            price: 117.34,
            price_sources: [],
            processed_ms: 1719436058841,
          },
          {
            leverage: 0.52,
            order_type: "LONG",
            order_uuid: "4d98bf97-f8a3-4690-a949-1faeddbdb715",
            price: 117.267,
            price_sources: [],
            processed_ms: 1719436092368,
          },
          {
            leverage: 0.561,
            order_type: "LONG",
            order_uuid: "46375ded-e23c-48d9-9091-192ddfdca4e2",
            price: 117.25,
            price_sources: [],
            processed_ms: 1719436128076,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "9cd100f4-f194-419c-ae24-459c2112050b",
            price: 117.279,
            price_sources: [],
            processed_ms: 1719436159178,
          },
        ],
        position_type: "FLAT",
        position_uuid: "bb692f8b-9919-4081-9789-d70d30bc13db",
        return_at_close: 0.9998271342414231,
        trade_pair: ["CADJPY", "CAD/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 117.32356653225806,
        close_ms: 1719436330950,
        current_return: 0.9998152646382,
        initial_entry_price: 117.33,
        is_closed_position: true,
        miner_hotkey: "5GVyVwjpxmQvVyDQQ4do18CEZU2vjhXixoPBACu7c2Vw1ShQ",
        net_leverage: 0.0,
        open_ms: 1719436194989,
        orders: [
          {
            leverage: 0.53,
            order_type: "LONG",
            order_uuid: "4457cebc-f3dd-473d-b665-6a0dff6bdff3",
            price: 117.33,
            price_sources: [],
            processed_ms: 1719436192939,
          },
          {
            leverage: 0.473,
            order_type: "LONG",
            order_uuid: "fcc0cdc5-f65c-4135-8012-9ce29c293868",
            price: 117.279,
            price_sources: [],
            processed_ms: 1719436225124,
          },
          {
            leverage: 0.485,
            order_type: "LONG",
            order_uuid: "de17d477-ba4a-4c81-936e-783d6c00b718",
            price: 117.36,
            price_sources: [],
            processed_ms: 1719436256990,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "7027ad27-53b0-49d3-bfb1-cbd852fd0f7f",
            price: 117.309,
            price_sources: [],
            processed_ms: 1719436330950,
          },
        ],
        position_type: "FLAT",
        position_uuid: "c4ba864d-ea43-493e-a025-d9318c689c86",
        return_at_close: 0.9997111238802353,
        trade_pair: ["CADJPY", "CAD/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 117.27053276955603,
        close_ms: 1719436487524,
        current_return: 1.000100553324637,
        initial_entry_price: 117.291,
        is_closed_position: true,
        miner_hotkey: "5GVyVwjpxmQvVyDQQ4do18CEZU2vjhXixoPBACu7c2Vw1ShQ",
        net_leverage: 0.0,
        open_ms: 1719436373711,
        orders: [
          {
            leverage: 0.485,
            order_type: "LONG",
            order_uuid: "c55bc02d-325f-4c75-90e1-bf11ff8de888",
            price: 117.291,
            price_sources: [],
            processed_ms: 1719436373158,
          },
          {
            leverage: 0.461,
            order_type: "LONG",
            order_uuid: "daaf59a9-9304-45c2-8204-a1a91320b1e7",
            price: 117.249,
            price_sources: [],
            processed_ms: 1719436457004,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "48f2567b-882e-4905-ab0d-f57ba9f41845",
            price: 117.283,
            price_sources: [],
            processed_ms: 1719436487524,
          },
        ],
        position_type: "FLAT",
        position_uuid: "b2b8bed8-1412-48a8-b6fb-44655186c939",
        return_at_close: 1.000034326665996,
        trade_pair: ["CADJPY", "CAD/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 117.29644260027663,
        close_ms: 1719436692805,
        current_return: 0.9998220063069972,
        initial_entry_price: 117.33,
        is_closed_position: true,
        miner_hotkey: "5GVyVwjpxmQvVyDQQ4do18CEZU2vjhXixoPBACu7c2Vw1ShQ",
        net_leverage: 0.0,
        open_ms: 1719436517222,
        orders: [
          {
            leverage: 0.506,
            order_type: "LONG",
            order_uuid: "f47fbd65-caab-4889-8256-d0ce40ff0cd2",
            price: 117.33,
            price_sources: [],
            processed_ms: 1719436516762,
          },
          {
            leverage: 0.508,
            order_type: "LONG",
            order_uuid: "fa6205ad-00fe-4d96-ac44-918f2fed1fd0",
            price: 117.277,
            price_sources: [],
            processed_ms: 1719436564892,
          },
          {
            leverage: 0.432,
            order_type: "LONG",
            order_uuid: "b9e67ab7-6759-40f5-bbd9-49984c916344",
            price: 117.28,
            price_sources: [],
            processed_ms: 1719436654882,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "37416b64-c663-4adf-a8ec-22e270c09b67",
            price: 117.282,
            price_sources: [],
            processed_ms: 1719436692805,
          },
        ],
        position_type: "FLAT",
        position_uuid: "e7259860-3976-4e1f-b86b-17c3107fd7f7",
        return_at_close: 0.9997208043235188,
        trade_pair: ["CADJPY", "CAD/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 117.28151063829786,
        close_ms: 1719436834235,
        current_return: 1.000043177981276,
        initial_entry_price: 117.282,
        is_closed_position: true,
        miner_hotkey: "5GVyVwjpxmQvVyDQQ4do18CEZU2vjhXixoPBACu7c2Vw1ShQ",
        net_leverage: 0.0,
        open_ms: 1719436731274,
        orders: [
          {
            leverage: 0.576,
            order_type: "LONG",
            order_uuid: "31f4700f-1a1d-4a6b-a39d-7d7ef4f16732",
            price: 117.282,
            price_sources: [],
            processed_ms: 1719436730639,
          },
          {
            leverage: 0.552,
            order_type: "LONG",
            order_uuid: "67fc433b-2512-44da-99f3-023d60cedb66",
            price: 117.281,
            price_sources: [],
            processed_ms: 1719436777437,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "543895a4-f831-4af6-bb59-551bdf1ac4bd",
            price: 117.286,
            price_sources: [],
            processed_ms: 1719436834235,
          },
        ],
        position_type: "FLAT",
        position_uuid: "454679f1-482d-40a3-b493-5db187d0e35c",
        return_at_close: 0.9999642145719426,
        trade_pair: ["CADJPY", "CAD/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 117.25706153846154,
        close_ms: 1719436971101,
        current_return: 1.0004400780996878,
        initial_entry_price: 117.286,
        is_closed_position: true,
        miner_hotkey: "5GVyVwjpxmQvVyDQQ4do18CEZU2vjhXixoPBACu7c2Vw1ShQ",
        net_leverage: 0.0,
        open_ms: 1719436861751,
        orders: [
          {
            leverage: 0.48,
            order_type: "LONG",
            order_uuid: "2541cd2c-7fc5-48b2-b4d8-fce028c411e1",
            price: 117.286,
            price_sources: [],
            processed_ms: 1719436861127,
          },
          {
            leverage: 0.495,
            order_type: "LONG",
            order_uuid: "7f7e6dad-039a-4443-86d7-a34dc8279fc6",
            price: 117.229,
            price_sources: [],
            processed_ms: 1719436897544,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "0dd0e3bc-84bf-4b52-a030-9ca794108b2b",
            price: 117.31,
            price_sources: [],
            processed_ms: 1719436971101,
          },
        ],
        position_type: "FLAT",
        position_uuid: "d7a8028e-d0c2-4c0d-920a-39c7b450d80e",
        return_at_close: 1.0003717980643576,
        trade_pair: ["CADJPY", "CAD/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 117.28472845953003,
        close_ms: 1719437070726,
        current_return: 0.9999242818284915,
        initial_entry_price: 117.277,
        is_closed_position: true,
        miner_hotkey: "5GVyVwjpxmQvVyDQQ4do18CEZU2vjhXixoPBACu7c2Vw1ShQ",
        net_leverage: 0.0,
        open_ms: 1719437007030,
        orders: [
          {
            leverage: 0.557,
            order_type: "LONG",
            order_uuid: "1bf71a4a-5258-43d5-902b-b74029c103c6",
            price: 117.277,
            price_sources: [],
            processed_ms: 1719437005087,
          },
          {
            leverage: 0.592,
            order_type: "LONG",
            order_uuid: "d40a5c40-c5c1-4198-abbb-88ad461dbf7e",
            price: 117.292,
            price_sources: [],
            processed_ms: 1719437036824,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "ca8b25b4-36c7-4842-83a1-8f7ad0cebba6",
            price: 117.277,
            price_sources: [],
            processed_ms: 1719437070726,
          },
        ],
        position_type: "FLAT",
        position_uuid: "b3252472-008c-453a-9cab-b31be5d2b81b",
        return_at_close: 0.9998438579185039,
        trade_pair: ["CADJPY", "CAD/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 117.2885502183406,
        close_ms: 1719437229424,
        current_return: 1.0001675349812837,
        initial_entry_price: 117.277,
        is_closed_position: true,
        miner_hotkey: "5GVyVwjpxmQvVyDQQ4do18CEZU2vjhXixoPBACu7c2Vw1ShQ",
        net_leverage: 0.0,
        open_ms: 1719437103247,
        orders: [
          {
            leverage: 0.456,
            order_type: "LONG",
            order_uuid: "9de1777e-6b5a-41eb-8160-e23e0f09d567",
            price: 117.277,
            price_sources: [],
            processed_ms: 1719437103152,
          },
          {
            leverage: 0.46,
            order_type: "LONG",
            order_uuid: "d7dcdd7c-6c19-4cf1-be8a-21fab7eb0d09",
            price: 117.3,
            price_sources: [],
            processed_ms: 1719437137134,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "3821abaa-6372-48c6-a1da-9b48644e119f",
            price: 117.31,
            price_sources: [],
            processed_ms: 1719437229424,
          },
        ],
        position_type: "FLAT",
        position_uuid: "b93764d5-2124-4d31-8da1-9208f7de143b",
        return_at_close: 1.0001034042389407,
        trade_pair: ["CADJPY", "CAD/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 117.26599641897941,
        close_ms: 1719437391365,
        current_return: 1.0000381368218176,
        initial_entry_price: 117.262,
        is_closed_position: true,
        miner_hotkey: "5GVyVwjpxmQvVyDQQ4do18CEZU2vjhXixoPBACu7c2Vw1ShQ",
        net_leverage: 0.0,
        open_ms: 1719437261320,
        orders: [
          {
            leverage: 0.559,
            order_type: "LONG",
            order_uuid: "f9f061ac-33db-4fbf-8eb7-c38426443e99",
            price: 117.262,
            price_sources: [],
            processed_ms: 1719437261188,
          },
          {
            leverage: 0.558,
            order_type: "LONG",
            order_uuid: "5a23135e-cb55-4fcf-baf9-26788a135505",
            price: 117.27,
            price_sources: [],
            processed_ms: 1719437312867,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "ff3a12e3-8c01-4ef3-b565-b57becd48bf6",
            price: 117.27,
            price_sources: [],
            processed_ms: 1719437391365,
          },
        ],
        position_type: "FLAT",
        position_uuid: "4e91e756-2d49-48b4-8f5e-ca84e9b563bb",
        return_at_close: 0.9999599438398996,
        trade_pair: ["CADJPY", "CAD/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 117.29146327683617,
        close_ms: 1719438681113,
        current_return: 1.0000773048194003,
        initial_entry_price: 117.276,
        is_closed_position: true,
        miner_hotkey: "5GVyVwjpxmQvVyDQQ4do18CEZU2vjhXixoPBACu7c2Vw1ShQ",
        net_leverage: 0.0,
        open_ms: 1719437426431,
        orders: [
          {
            leverage: 0.579,
            order_type: "LONG",
            order_uuid: "35d84fec-6790-49b8-924d-2cd281e8b693",
            price: 117.276,
            price_sources: [],
            processed_ms: 1719437425126,
          },
          {
            leverage: 0.483,
            order_type: "LONG",
            order_uuid: "a53361c4-b1b2-477f-bf3b-a42acb8df9dc",
            price: 117.31,
            price_sources: [],
            processed_ms: 1719437457117,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "d960572b-cdb2-4f9e-99f0-312671b66e02",
            price: 117.3,
            price_sources: [],
            processed_ms: 1719438681113,
          },
        ],
        position_type: "FLAT",
        position_uuid: "be51e871-ac07-4060-8a5a-e48af899ad5b",
        return_at_close: 1.00000295907256,
        trade_pair: ["CADJPY", "CAD/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 117.18021511627909,
        close_ms: 1719449895868,
        current_return: 1.0003377764133001,
        initial_entry_price: 117.261,
        is_closed_position: true,
        miner_hotkey: "5GVyVwjpxmQvVyDQQ4do18CEZU2vjhXixoPBACu7c2Vw1ShQ",
        net_leverage: 0.0,
        open_ms: 1719438713305,
        orders: [
          {
            leverage: 0.408,
            order_type: "LONG",
            order_uuid: "2ac14009-e67e-43be-ab76-7eb4970aec4d",
            price: 117.261,
            price_sources: [],
            processed_ms: 1719438711764,
          },
          {
            leverage: 0.42,
            order_type: "LONG",
            order_uuid: "da98669c-8921-4a0f-917a-5a8fbc031852",
            price: 117.179,
            price_sources: [],
            processed_ms: 1719443907456,
          },
          {
            leverage: 0.548,
            order_type: "LONG",
            order_uuid: "5190103b-1154-4b2b-bea5-c4211b46e577",
            price: 117.121,
            price_sources: [],
            processed_ms: 1719446790184,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "0d4d2b44-69f2-404b-b8fc-384061c0b486",
            price: 117.209,
            price_sources: [],
            processed_ms: 1719449895868,
          },
        ],
        position_type: "FLAT",
        position_uuid: "7fc7dfef-ab1e-4197-b145-709ddfa24dc9",
        return_at_close: 1.0002414238786759,
        trade_pair: ["CADJPY", "CAD/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 117.12267113690953,
        close_ms: 1719466221201,
        current_return: 1.0005825319350463,
        initial_entry_price: 117.191,
        is_closed_position: true,
        miner_hotkey: "5GVyVwjpxmQvVyDQQ4do18CEZU2vjhXixoPBACu7c2Vw1ShQ",
        net_leverage: 0.0,
        open_ms: 1719449925340,
        orders: [
          {
            leverage: 0.591,
            order_type: "LONG",
            order_uuid: "274816b8-99da-4427-8e7e-f7203904723e",
            price: 117.191,
            price_sources: [],
            processed_ms: 1719449925163,
          },
          {
            leverage: 0.404,
            order_type: "LONG",
            order_uuid: "e8dc7d9d-b1d5-4416-a1e2-6d7dd58408af",
            price: 117.18350000000001,
            price_sources: [],
            processed_ms: 1719450545932,
          },
          {
            leverage: 0.579,
            order_type: "LONG",
            order_uuid: "729eed02-7656-480f-83b0-fad0cff6e4d6",
            price: 117.136,
            price_sources: [],
            processed_ms: 1719452573284,
          },
          {
            leverage: 0.491,
            order_type: "LONG",
            order_uuid: "4e87e83a-0094-4f7a-a56d-50f067723256",
            price: 117.0555,
            price_sources: [],
            processed_ms: 1719453167212,
          },
          {
            leverage: 0.433,
            order_type: "LONG",
            order_uuid: "eda096fb-88b2-415a-903a-f2264e6708f9",
            price: 117.031,
            price_sources: [],
            processed_ms: 1719461201922,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "6ab1373a-6bac-4841-b27a-1abe8eefc304",
            price: 117.15,
            price_sources: [],
            processed_ms: 1719466221201,
          },
        ],
        position_type: "FLAT",
        position_uuid: "34169eef-5109-4397-99db-6a9db82e72aa",
        return_at_close: 1.0004075700735122,
        trade_pair: ["CADJPY", "CAD/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 117.1333516500507,
        close_ms: 1719471975957,
        current_return: 1.0006688941977862,
        initial_entry_price: 117.1382683603446,
        is_closed_position: true,
        miner_hotkey: "5GVyVwjpxmQvVyDQQ4do18CEZU2vjhXixoPBACu7c2Vw1ShQ",
        net_leverage: 0.0,
        open_ms: 1719466252580,
        orders: [
          {
            leverage: 0.566,
            order_type: "LONG",
            order_uuid: "e4a77467-ead6-42c0-9dcb-11b8e78e2de2",
            price: 117.1382683603446,
            price_sources: [],
            processed_ms: 1719466251047,
          },
          {
            leverage: 0.52,
            order_type: "LONG",
            order_uuid: "d1cfa7dd-7006-449f-9bb5-04b8f214ee64",
            price: 117.128,
            price_sources: [],
            processed_ms: 1719466668432,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "e6eebf3e-6d18-4590-85c2-1565d54410ba",
            price: 117.2055,
            price_sources: [],
            processed_ms: 1719471975957,
          },
        ],
        position_type: "FLAT",
        position_uuid: "06c5bcbb-b6f3-4ffd-bc85-8df28fc4e608",
        return_at_close: 1.0005928233484493,
        trade_pair: ["CADJPY", "CAD/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 117.17910253807105,
        close_ms: 1719490836081,
        current_return: 1.0004109486186243,
        initial_entry_price: 117.202,
        is_closed_position: true,
        miner_hotkey: "5GVyVwjpxmQvVyDQQ4do18CEZU2vjhXixoPBACu7c2Vw1ShQ",
        net_leverage: 0.0,
        open_ms: 1719472010475,
        orders: [
          {
            leverage: 0.448,
            order_type: "LONG",
            order_uuid: "56a706fa-c097-4805-9993-c65c8e26e12f",
            price: 117.202,
            price_sources: [],
            processed_ms: 1719472008305,
          },
          {
            leverage: 0.537,
            order_type: "LONG",
            order_uuid: "9c12a1cd-6b3a-41a8-9905-b5a69c889730",
            price: 117.16,
            price_sources: [],
            processed_ms: 1719489919668,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "e157b95f-f639-4a75-a386-56440767fdba",
            price: 117.228,
            price_sources: [],
            processed_ms: 1719490836081,
          },
        ],
        position_type: "FLAT",
        position_uuid: "d6fabfd3-9e61-430c-ae04-3b727796f3f3",
        return_at_close: 1.000341970283717,
        trade_pair: ["CADJPY", "CAD/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 117.23576365868631,
        close_ms: 1719491434412,
        current_return: 1.0002672967509147,
        initial_entry_price: 117.233,
        is_closed_position: true,
        miner_hotkey: "5GVyVwjpxmQvVyDQQ4do18CEZU2vjhXixoPBACu7c2Vw1ShQ",
        net_leverage: 0.0,
        open_ms: 1719490868662,
        orders: [
          {
            leverage: 0.578,
            order_type: "LONG",
            order_uuid: "8985832a-cc98-496f-bd51-0b63226beb07",
            price: 117.233,
            price_sources: [],
            processed_ms: 1719490866307,
          },
          {
            leverage: 0.584,
            order_type: "LONG",
            order_uuid: "161bf1fb-be5a-406c-a3d1-7650c6341b90",
            price: 117.26150000000001,
            price_sources: [],
            processed_ms: 1719491328752,
          },
          {
            leverage: 0.467,
            order_type: "LONG",
            order_uuid: "e70fa593-5f42-48be-b437-fbf7d5720078",
            price: 117.207,
            price_sources: [],
            processed_ms: 1719491404230,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "60089124-0f9b-4b53-80b4-9ae1b3afd48d",
            price: 117.255,
            price_sources: [],
            processed_ms: 1719491434412,
          },
        ],
        position_type: "FLAT",
        position_uuid: "bae9c93e-940f-48cc-a999-008815b05abd",
        return_at_close: 1.0001532362710663,
        trade_pair: ["CADJPY", "CAD/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 117.17606815020861,
        close_ms: 1719494568404,
        current_return: 1.000563504048186,
        initial_entry_price: 117.213,
        is_closed_position: true,
        miner_hotkey: "5GVyVwjpxmQvVyDQQ4do18CEZU2vjhXixoPBACu7c2Vw1ShQ",
        net_leverage: 0.0,
        open_ms: 1719491466658,
        orders: [
          {
            leverage: 0.492,
            order_type: "LONG",
            order_uuid: "03e16176-cc1c-4c76-9b05-aa5936ed47a9",
            price: 117.213,
            price_sources: [],
            processed_ms: 1719491463965,
          },
          {
            leverage: 0.528,
            order_type: "LONG",
            order_uuid: "8b340c83-729c-4609-952a-7b5c8446a724",
            price: 117.19,
            price_sources: [],
            processed_ms: 1719492453434,
          },
          {
            leverage: 0.418,
            order_type: "LONG",
            order_uuid: "b0d1e61b-4044-4cce-b99a-558e27dabf94",
            price: 117.115,
            price_sources: [],
            processed_ms: 1719493021631,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "ffb53541-fb1c-4d23-a33f-bee33257dbc3",
            price: 117.222,
            price_sources: [],
            processed_ms: 1719494568404,
          },
        ],
        position_type: "FLAT",
        position_uuid: "d6626a50-74b2-4c11-9b81-deb994f0971f",
        return_at_close: 1.0004627873258685,
        trade_pair: ["CADJPY", "CAD/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 117.17827868852459,
        close_ms: 1719495398097,
        current_return: 1.0006597269624573,
        initial_entry_price: 117.2,
        is_closed_position: true,
        miner_hotkey: "5GVyVwjpxmQvVyDQQ4do18CEZU2vjhXixoPBACu7c2Vw1ShQ",
        net_leverage: 0.0,
        open_ms: 1719494600010,
        orders: [
          {
            leverage: 0.446,
            order_type: "LONG",
            order_uuid: "5285932b-ca6b-493e-9ef1-4ce97fc737ac",
            price: 117.2,
            price_sources: [],
            processed_ms: 1719494596925,
          },
          {
            leverage: 0.53,
            order_type: "LONG",
            order_uuid: "27a9dba0-18a9-494a-a68d-20db34a2753d",
            price: 117.16,
            price_sources: [],
            processed_ms: 1719495033944,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "b66e3c9e-ceef-434d-b24a-252a054090f0",
            price: 117.2575,
            price_sources: [],
            processed_ms: 1719495398097,
          },
        ],
        position_type: "FLAT",
        position_uuid: "f29b12e6-387a-4107-be60-8e98de0066a7",
        return_at_close: 1.0005913618899112,
        trade_pair: ["CADJPY", "CAD/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 117.244,
        close_ms: 1719509277652,
        current_return: 1.0006897069359626,
        initial_entry_price: 117.244,
        is_closed_position: true,
        miner_hotkey: "5GVyVwjpxmQvVyDQQ4do18CEZU2vjhXixoPBACu7c2Vw1ShQ",
        net_leverage: 0.0,
        open_ms: 1719495429999,
        orders: [
          {
            leverage: 0.448,
            order_type: "LONG",
            order_uuid: "acf6c11b-d720-4042-a66d-4fdc7a0d83d5",
            price: 117.244,
            price_sources: [],
            processed_ms: 1719495427137,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "fc9d51e8-2e5e-4026-98bd-d9be4a187b4e",
            price: 117.4245,
            price_sources: [],
            processed_ms: 1719509277652,
          },
        ],
        position_type: "FLAT",
        position_uuid: "623b6b22-f972-4317-b99b-802f863b18ca",
        return_at_close: 1.0006583253067531,
        trade_pair: ["CADJPY", "CAD/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 117.36772768562399,
        close_ms: 1719522852100,
        current_return: 0.9994021722470117,
        initial_entry_price: 117.436,
        is_closed_position: true,
        miner_hotkey: "5GVyVwjpxmQvVyDQQ4do18CEZU2vjhXixoPBACu7c2Vw1ShQ",
        net_leverage: 0.0,
        open_ms: 1719509307082,
        orders: [
          {
            leverage: 0.584,
            order_type: "LONG",
            order_uuid: "1bb0d474-d2da-4340-ac59-13d98cb38409",
            price: 117.436,
            price_sources: [],
            processed_ms: 1719509306590,
          },
          {
            leverage: 0.472,
            order_type: "LONG",
            order_uuid: "8a5ac533-6d9f-45e6-b2f0-4c3c697118d7",
            price: 117.405,
            price_sources: [],
            processed_ms: 1719510835620,
          },
          {
            leverage: 0.539,
            order_type: "LONG",
            order_uuid: "5cfa16e7-78fa-4066-b893-9eddd4d2aa45",
            price: 117.3825,
            price_sources: [],
            processed_ms: 1719511383423,
          },
          {
            leverage: 0.432,
            order_type: "LONG",
            order_uuid: "4324b498-e5fb-4301-b070-abb3d8783242",
            price: 117.32,
            price_sources: [],
            processed_ms: 1719521858665,
          },
          {
            leverage: 0.505,
            order_type: "LONG",
            order_uuid: "6df6f09a-27e2-459c-93fb-7661ef99c3b8",
            price: 117.279,
            price_sources: [],
            processed_ms: 1719522006956,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "73bfa9ee-53f4-4354-bef7-94c0df316297",
            price: 117.34,
            price_sources: [],
            processed_ms: 1719522852100,
          },
        ],
        position_type: "FLAT",
        position_uuid: "57490bd6-48ec-4766-9251-d35613721ff6",
        return_at_close: 0.9992250382060026,
        trade_pair: ["CADJPY", "CAD/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 117.29414346895075,
        close_ms: 1719535984678,
        current_return: 1.0005418233443328,
        initial_entry_price: 117.279,
        is_closed_position: true,
        miner_hotkey: "5GVyVwjpxmQvVyDQQ4do18CEZU2vjhXixoPBACu7c2Vw1ShQ",
        net_leverage: 0.0,
        open_ms: 1719522881771,
        orders: [
          {
            leverage: 0.425,
            order_type: "LONG",
            order_uuid: "815e24b2-552b-4370-88fd-2f3a1b1a2d3b",
            price: 117.279,
            price_sources: [],
            processed_ms: 1719522881673,
          },
          {
            leverage: 0.556,
            order_type: "LONG",
            order_uuid: "86f8801c-ea85-42db-8749-e614a4cd5a23",
            price: 117.33,
            price_sources: [],
            processed_ms: 1719523155990,
          },
          {
            leverage: 0.42,
            order_type: "LONG",
            order_uuid: "9da3add0-300c-48ba-ad24-c87ac134b379",
            price: 117.262,
            price_sources: [],
            processed_ms: 1719529142256,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "cd8c3977-69ed-45ba-b59f-896eb40d5803",
            price: 117.3395,
            price_sources: [],
            processed_ms: 1719535984678,
          },
        ],
        position_type: "FLAT",
        position_uuid: "5bb12086-245a-4cdd-9729-8468b58bc441",
        return_at_close: 1.0004437002077176,
        trade_pair: ["CADJPY", "CAD/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 117.34354657794677,
        close_ms: 1719536370982,
        current_return: 1.0006135763338617,
        initial_entry_price: 117.366,
        is_closed_position: true,
        miner_hotkey: "5GVyVwjpxmQvVyDQQ4do18CEZU2vjhXixoPBACu7c2Vw1ShQ",
        net_leverage: 0.0,
        open_ms: 1719536015012,
        orders: [
          {
            leverage: 0.454,
            order_type: "LONG",
            order_uuid: "2a4ec77b-bc5d-4279-85a7-d26951fd157f",
            price: 117.366,
            price_sources: [],
            processed_ms: 1719536014668,
          },
          {
            leverage: 0.598,
            order_type: "LONG",
            order_uuid: "b841cbd0-ad05-4f09-ac18-6eb5482dadc2",
            price: 117.32650000000001,
            price_sources: [],
            processed_ms: 1719536226992,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "1547ca3f-526d-45e7-b3e2-5a57d765c8c6",
            price: 117.412,
            price_sources: [],
            processed_ms: 1719536370982,
          },
        ],
        position_type: "FLAT",
        position_uuid: "6b1186c0-ece7-49fa-89e4-23cfdcc0e23b",
        return_at_close: 1.0005398911501004,
        trade_pair: ["CADJPY", "CAD/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 117.35412859813084,
        close_ms: 1719538214707,
        current_return: 1.0014549232328223,
        initial_entry_price: 117.433,
        is_closed_position: true,
        miner_hotkey: "5GVyVwjpxmQvVyDQQ4do18CEZU2vjhXixoPBACu7c2Vw1ShQ",
        net_leverage: 0.0,
        open_ms: 1719536401333,
        orders: [
          {
            leverage: 0.478,
            order_type: "LONG",
            order_uuid: "e8fd269a-e21d-42cb-ab13-a4f0f85a1ffa",
            price: 117.433,
            price_sources: [],
            processed_ms: 1719536401056,
          },
          {
            leverage: 0.593,
            order_type: "LONG",
            order_uuid: "923278c3-e2fe-40c0-9e69-e3dcaf1d9acf",
            price: 117.392,
            price_sources: [],
            processed_ms: 1719536512915,
          },
          {
            leverage: 0.468,
            order_type: "LONG",
            order_uuid: "d7c72e5a-64d3-4084-93bc-27ac97e4dacd",
            price: 117.355,
            price_sources: [],
            processed_ms: 1719536610797,
          },
          {
            leverage: 0.589,
            order_type: "LONG",
            order_uuid: "a9061f73-a21c-453b-9ea7-528db3d71ed0",
            price: 117.322,
            price_sources: [],
            processed_ms: 1719536815047,
          },
          {
            leverage: 0.547,
            order_type: "LONG",
            order_uuid: "a0be517e-ccb7-408b-8986-4e022907371d",
            price: 117.278,
            price_sources: [],
            processed_ms: 1719537077039,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "91bc818f-ceb8-4ffb-810a-56a40c706287",
            price: 117.418,
            price_sources: [],
            processed_ms: 1719538214707,
          },
        ],
        position_type: "FLAT",
        position_uuid: "5a48f308-4179-4cf4-9bd7-5f13aaecc9b2",
        return_at_close: 1.001267400798447,
        trade_pair: ["CADJPY", "CAD/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 117.30309628770301,
        close_ms: 1719556434803,
        current_return: 1.000585613594259,
        initial_entry_price: 117.4025,
        is_closed_position: true,
        miner_hotkey: "5GVyVwjpxmQvVyDQQ4do18CEZU2vjhXixoPBACu7c2Vw1ShQ",
        net_leverage: 0.0,
        open_ms: 1719538243980,
        orders: [
          {
            leverage: 0.459,
            order_type: "LONG",
            order_uuid: "998c8ba4-13d0-4215-9d7d-80b17ab7d506",
            price: 117.4025,
            price_sources: [],
            processed_ms: 1719538243768,
          },
          {
            leverage: 0.556,
            order_type: "LONG",
            order_uuid: "c59b3d9b-5ece-4b6e-8136-0e48c3bdd63b",
            price: 117.352,
            price_sources: [],
            processed_ms: 1719538370008,
          },
          {
            leverage: 0.559,
            order_type: "LONG",
            order_uuid: "ec9a98ce-f6fb-4717-9466-fd15dccf1be8",
            price: 117.22749999999999,
            price_sources: [],
            processed_ms: 1719538862740,
          },
          {
            leverage: 0.581,
            order_type: "LONG",
            order_uuid: "594f2e84-70ee-47b9-8ee2-7c8745a3c023",
            price: 117.2505,
            price_sources: [],
            processed_ms: 1719551811717,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "501aaf9e-15d3-4ef7-a539-b1607929dbf7",
            price: 117.335,
            price_sources: [],
            processed_ms: 1719556434803,
          },
        ],
        position_type: "FLAT",
        position_uuid: "e2a53716-d737-4a07-b5af-344dd4e99c94",
        return_at_close: 1.0004346752544482,
        trade_pair: ["CADJPY", "CAD/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 117.31798094170405,
        close_ms: 1719560997206,
        current_return: 1.0006159779755206,
        initial_entry_price: 117.324,
        is_closed_position: true,
        miner_hotkey: "5GVyVwjpxmQvVyDQQ4do18CEZU2vjhXixoPBACu7c2Vw1ShQ",
        net_leverage: 0.0,
        open_ms: 1719556465001,
        orders: [
          {
            leverage: 0.479,
            order_type: "LONG",
            order_uuid: "6c0b333c-730a-4438-909d-2d16bf4fb3f5",
            price: 117.324,
            price_sources: [],
            processed_ms: 1719556464845,
          },
          {
            leverage: 0.413,
            order_type: "LONG",
            order_uuid: "bc181ce8-3d70-48ad-a2c8-54e8cb43b934",
            price: 117.311,
            price_sources: [],
            processed_ms: 1719559163998,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "9f4a82f7-b9f9-490d-bb86-695573997ec8",
            price: 117.399,
            price_sources: [],
            processed_ms: 1719560997206,
          },
        ],
        position_type: "FLAT",
        position_uuid: "ca444672-7a78-41a6-9daa-6da7d5846331",
        return_at_close: 1.0005534995138559,
        trade_pair: ["CADJPY", "CAD/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 117.3319655812621,
        close_ms: 1719568396876,
        current_return: 1.0003025323370327,
        initial_entry_price: 117.3965,
        is_closed_position: true,
        miner_hotkey: "5GVyVwjpxmQvVyDQQ4do18CEZU2vjhXixoPBACu7c2Vw1ShQ",
        net_leverage: 0.0,
        open_ms: 1719561027045,
        orders: [
          {
            leverage: 0.408,
            order_type: "LONG",
            order_uuid: "53ca1f84-6361-453a-82fe-96e05df4377f",
            price: 117.3965,
            price_sources: [],
            processed_ms: 1719561026915,
          },
          {
            leverage: 0.51,
            order_type: "LONG",
            order_uuid: "5b82bba9-db75-49b2-bfda-0e0c3189ea14",
            price: 117.384,
            price_sources: [],
            processed_ms: 1719561145013,
          },
          {
            leverage: 0.434,
            order_type: "LONG",
            order_uuid: "31193cc0-1710-47ed-9dcd-6c2da1a2f0ff",
            price: 117.338,
            price_sources: [],
            processed_ms: 1719562061264,
          },
          {
            leverage: 0.403,
            order_type: "LONG",
            order_uuid: "04972279-b614-49d9-86d0-abdb2c3ef61d",
            price: 117.275,
            price_sources: [],
            processed_ms: 1719565761284,
          },
          {
            leverage: 0.46,
            order_type: "LONG",
            order_uuid: "13e7cac3-a547-4ba3-b417-cbc9bf44d98a",
            price: 117.26124948368596,
            price_sources: [],
            processed_ms: 1719567369140,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "cd806343-168e-4b33-bf85-b1a4c9f581be",
            price: 117.348,
            price_sources: [],
            processed_ms: 1719568396876,
          },
        ],
        position_type: "FLAT",
        position_uuid: "c3c38043-82b6-4620-8a92-8236cb1340b6",
        return_at_close: 1.0001474354293938,
        trade_pair: ["CADJPY", "CAD/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 117.27254409246576,
        close_ms: 1719592556927,
        current_return: 1.0052845053858737,
        initial_entry_price: 117.344,
        is_closed_position: true,
        miner_hotkey: "5GVyVwjpxmQvVyDQQ4do18CEZU2vjhXixoPBACu7c2Vw1ShQ",
        net_leverage: 0.0,
        open_ms: 1719568426935,
        orders: [
          {
            leverage: 0.404,
            order_type: "LONG",
            order_uuid: "c99d3e7b-cb92-420d-bd69-89f988f3b560",
            price: 117.344,
            price_sources: [],
            processed_ms: 1719568426785,
          },
          {
            leverage: 0.553,
            order_type: "LONG",
            order_uuid: "d9699ca1-3b54-48bf-9c5b-8d3b187b704b",
            price: 117.30600000000001,
            price_sources: [],
            processed_ms: 1719569670376,
          },
          {
            leverage: 0.474,
            order_type: "LONG",
            order_uuid: "9dcdcd73-53e0-4d57-ba5a-d457659b7ede",
            price: 117.276,
            price_sources: [],
            processed_ms: 1719570461475,
          },
          {
            leverage: 0.44,
            order_type: "LONG",
            order_uuid: "62b74e90-db00-4f79-96a0-93574a21b1c1",
            price: 117.241,
            price_sources: [],
            processed_ms: 1719571109884,
          },
          {
            leverage: 0.465,
            order_type: "LONG",
            order_uuid: "08d27e2e-f2bc-41ef-ac6f-2aee92609bda",
            price: 117.197,
            price_sources: [],
            processed_ms: 1719575759934,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "35f6dfdd-147f-44eb-8aa4-d3e957d41ff0",
            price: 117.538,
            price_sources: [],
            processed_ms: 1719592556927,
          },
        ],
        position_type: "FLAT",
        position_uuid: "5b41b759-ea81-48fd-aa39-ed38dc44d771",
        return_at_close: 1.005120121263553,
        trade_pair: ["CADJPY", "CAD/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 3448.1617394757745,
        close_ms: 0,
        current_return: 0.988450773897075,
        initial_entry_price: 3392.57,
        is_closed_position: false,
        miner_hotkey: "5GVyVwjpxmQvVyDQQ4do18CEZU2vjhXixoPBACu7c2Vw1ShQ",
        net_leverage: 0.1259,
        open_ms: 1719595262571,
        orders: [
          {
            leverage: 0.0259,
            order_type: "LONG",
            order_uuid: "fe3bf770-43be-494b-9ea9-9c80349e62c8",
            price: 3392.57,
            price_sources: [],
            processed_ms: 1719595261454,
          },
          {
            leverage: 0.1,
            order_type: "LONG",
            order_uuid: "e90412c3-72f8-4aca-9fc7-3c7d8ff94c51",
            price: 3462.56,
            price_sources: [],
            processed_ms: 1719842962937,
          },
        ],
        position_type: "LONG",
        position_uuid: "2cb39720-b25e-469f-bf79-2f7f585bc07c",
        return_at_close: 0.9883885509208582,
        trade_pair: ["ETHUSD", "ETH/USD", 0.003, 0.001, 20],
      },
    ],
    thirty_day_returns: 1.0375679011493462,
  },
  "5GWeYf1RUoZLdRVkLJRQUwvUzHRY9216ju52xBYErrYWrXx2": {
    all_time_returns: 1.0,
    n_positions: 0,
    percentage_profitable: 0.0,
    positions: [
      {
        average_entry_price: 3406.5299999999997,
        close_ms: 1711223202647,
        current_return: 0.8771127659012865,
        initial_entry_price: 3409.63,
        is_closed_position: true,
        miner_hotkey: "5GWeYf1RUoZLdRVkLJRQUwvUzHRY9216ju52xBYErrYWrXx2",
        net_leverage: 20.0,
        open_ms: 1711217470690,
        orders: [
          {
            leverage: 1.0,
            order_type: "LONG",
            order_uuid: "62dd9454-4d0b-42c7-8fb6-f97b0b25e902",
            price: 3409.63,
            price_sources: [],
            processed_ms: 1711217470689,
          },
          {
            leverage: 1.0,
            order_type: "LONG",
            order_uuid: "f8ec06e2-a47c-48a6-8882-8184a84436f0",
            price: 3408.38,
            price_sources: [],
            processed_ms: 1711217555063,
          },
          {
            leverage: 1.0,
            order_type: "LONG",
            order_uuid: "c71c75bf-3984-431a-9921-0a213301eef9",
            price: 3408.38,
            price_sources: [],
            processed_ms: 1711217640064,
          },
          {
            leverage: 1.0,
            order_type: "LONG",
            order_uuid: "9e963f6b-03a9-43e7-ba31-9af979681ec8",
            price: 3408.38,
            price_sources: [],
            processed_ms: 1711217725070,
          },
          {
            leverage: 1.0,
            order_type: "LONG",
            order_uuid: "d90b3d1b-ce57-40b4-8210-009a199d7bfb",
            price: 3408.38,
            price_sources: [],
            processed_ms: 1711217823447,
          },
          {
            leverage: 1.0,
            order_type: "LONG",
            order_uuid: "7a002144-abac-43ac-9881-c28fcc6f4a61",
            price: 3408.38,
            price_sources: [],
            processed_ms: 1711217908057,
          },
          {
            leverage: 1.0,
            order_type: "LONG",
            order_uuid: "b1f6f42e-f982-4212-9ff3-4eb5aa5edc8a",
            price: 3408.38,
            price_sources: [],
            processed_ms: 1711217993056,
          },
          {
            leverage: 1.0,
            order_type: "LONG",
            order_uuid: "60d35e4e-7fb9-4bc8-be62-8f3226ecd048",
            price: 3408.38,
            price_sources: [],
            processed_ms: 1711218078073,
          },
          {
            leverage: 1.0,
            order_type: "LONG",
            order_uuid: "b1e3a606-a8b1-4c0f-8837-d954373d570c",
            price: 3408.4,
            price_sources: [],
            processed_ms: 1711218175516,
          },
          {
            leverage: 1.0,
            order_type: "LONG",
            order_uuid: "26786403-5dc5-486a-83bb-37f6b472461f",
            price: 3410.25,
            price_sources: [],
            processed_ms: 1711218260071,
          },
          {
            leverage: 1.0,
            order_type: "LONG",
            order_uuid: "7f554045-5dd5-4b92-9395-c846c82670a4",
            price: 3405.37,
            price_sources: [],
            processed_ms: 1711218345071,
          },
          {
            leverage: 1.0,
            order_type: "LONG",
            order_uuid: "7bd62ba7-e1d8-4e8f-ba7d-5992d314e8e9",
            price: 3405.21,
            price_sources: [],
            processed_ms: 1711218430066,
          },
          {
            leverage: 1.0,
            order_type: "LONG",
            order_uuid: "161dcc5e-6686-421b-818d-2d39308d04d6",
            price: 3405.21,
            price_sources: [],
            processed_ms: 1711218527475,
          },
          {
            leverage: 1.0,
            order_type: "LONG",
            order_uuid: "c12c4379-5b29-43db-97a2-acec3050b774",
            price: 3405.21,
            price_sources: [],
            processed_ms: 1711218612063,
          },
          {
            leverage: 1.0,
            order_type: "LONG",
            order_uuid: "391e6da5-c3e0-483d-8820-3b73470f33b8",
            price: 3405.21,
            price_sources: [],
            processed_ms: 1711218697077,
          },
          {
            leverage: 1.0,
            order_type: "LONG",
            order_uuid: "7f0ad428-c3f3-4302-9156-33f35c46c6a5",
            price: 3404.66,
            price_sources: [],
            processed_ms: 1711218782119,
          },
          {
            leverage: 1.0,
            order_type: "LONG",
            order_uuid: "5da8e106-67a6-4130-8c57-4b108f1f7503",
            price: 3406.02,
            price_sources: [],
            processed_ms: 1711218878366,
          },
          {
            leverage: 1.0,
            order_type: "LONG",
            order_uuid: "35cc7afd-ca77-481b-a255-4a40b7ea5476",
            price: 3404.64,
            price_sources: [],
            processed_ms: 1711218963090,
          },
          {
            leverage: 1.0,
            order_type: "LONG",
            order_uuid: "b2e77915-6e9e-4164-a245-3fd1ba78a853",
            price: 3400.98,
            price_sources: [],
            processed_ms: 1711219048103,
          },
          {
            leverage: 1.0,
            order_type: "LONG",
            order_uuid: "d95be1bb-6305-40f4-8511-820863bb267b",
            price: 3401.15,
            price_sources: [],
            processed_ms: 1711219133692,
          },
        ],
        position_type: "FLAT",
        position_uuid: "919dee5e-6952-48c2-8c45-82f831ce8240",
        return_at_close: 0.8420282552652351,
        trade_pair: ["ETHUSD", "ETH/USD", 0.003, 0.001, 20],
      },
    ],
    thirty_day_returns: 1.0,
  },
  "5GZBYzPXRzVGgN2CprhAdEQ2rrWVtiK1KDYfFqov63uwZcET": {
    all_time_returns: 1.0,
    n_positions: 0,
    percentage_profitable: 0.0,
    positions: [
      {
        average_entry_price: 171.04057142857144,
        close_ms: 0,
        current_return: 1.0081553327096247,
        initial_entry_price: 171.01999999999998,
        is_closed_position: false,
        miner_hotkey: "5GZBYzPXRzVGgN2CprhAdEQ2rrWVtiK1KDYfFqov63uwZcET",
        net_leverage: 0.35,
        open_ms: 1719342719340,
        orders: [
          {
            leverage: 0.15,
            order_type: "LONG",
            order_uuid: "949709e1-6887-47de-b112-34d50dc9b03e",
            price: 171.01999999999998,
            price_sources: [],
            processed_ms: 1719342719335,
          },
          {
            leverage: 0.2,
            order_type: "LONG",
            order_uuid: "dc5039da-c2a8-4f8d-be73-4866f93972d0",
            price: 171.056,
            price_sources: [],
            processed_ms: 1719343551816,
          },
        ],
        position_type: "LONG",
        position_uuid: "039f0479-6525-48d8-889a-54f5aee54c1b",
        return_at_close: 1.008142982806799,
        trade_pair: ["EURJPY", "EUR/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 60427.99999999999,
        close_ms: 0,
        current_return: 1.0026539021645595,
        initial_entry_price: 60428.0,
        is_closed_position: false,
        miner_hotkey: "5GZBYzPXRzVGgN2CprhAdEQ2rrWVtiK1KDYfFqov63uwZcET",
        net_leverage: -0.07,
        open_ms: 1719611354308,
        orders: [
          {
            leverage: -0.07,
            order_type: "SHORT",
            order_uuid: "5ceb5dac-e6be-49b9-9009-9919dd9bc85f",
            price: 60428.0,
            price_sources: [],
            processed_ms: 1719611353827,
          },
        ],
        position_type: "SHORT",
        position_uuid: "390f7a0b-b10e-4e27-8495-4ec06c1d03ed",
        return_at_close: 1.0026188092779837,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
    ],
    thirty_day_returns: 1.0,
  },
  "5GedR4ruCK8xAmVdU6DKmu7D8zJZ6UfPty7SFQNF7bFXE3Qb": {
    all_time_returns: 1.0,
    n_positions: 0,
    percentage_profitable: 0.0,
    positions: [
      {
        average_entry_price: 69676.44,
        close_ms: 1711591221714,
        current_return: 1.003902610408913,
        initial_entry_price: 69676.44,
        is_closed_position: true,
        miner_hotkey: "5GedR4ruCK8xAmVdU6DKmu7D8zJZ6UfPty7SFQNF7bFXE3Qb",
        net_leverage: 0.0,
        open_ms: 1711587708020,
        orders: [
          {
            leverage: -1.0,
            order_type: "SHORT",
            order_uuid: "8fca9ee1-c2bd-417b-8173-e2841397de32",
            price: 69676.44,
            price_sources: [],
            processed_ms: 1711587708019,
          },
          {
            leverage: 2.0,
            order_type: "LONG",
            order_uuid: "b2acc554-0b8e-4502-a9a4-cd83d70a7591",
            price: 69404.52,
            price_sources: [],
            processed_ms: 1711591221714,
          },
        ],
        position_type: "FLAT",
        position_uuid: "093c3c23-00ae-4eea-81af-8c94570b74f3",
        return_at_close: 1.0018948051880951,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 67305.81000000003,
        close_ms: 1711638513460,
        current_return: 1.1177714512916932,
        initial_entry_price: 69088.73,
        is_closed_position: true,
        miner_hotkey: "5GedR4ruCK8xAmVdU6DKmu7D8zJZ6UfPty7SFQNF7bFXE3Qb",
        net_leverage: 0.0,
        open_ms: 1711594816610,
        orders: [
          {
            leverage: 2.0,
            order_type: "LONG",
            order_uuid: "5129b522-cbbb-4a57-ac58-9fba4789a018",
            price: 69088.73,
            price_sources: [],
            processed_ms: 1711594816610,
          },
          {
            leverage: 2.0,
            order_type: "LONG",
            order_uuid: "506aef92-c81a-4629-bf3e-5d336bd7d76b",
            price: 69695.16,
            price_sources: [],
            processed_ms: 1711605621715,
          },
          {
            leverage: -2.0,
            order_type: "SHORT",
            order_uuid: "2da40259-253a-4376-88e9-f9492b5dda44",
            price: 70564.7,
            price_sources: [],
            processed_ms: 1711616425091,
          },
          {
            leverage: 2.0,
            order_type: "LONG",
            order_uuid: "d8076708-bb7c-4fb0-9be8-8dc5baebb4db",
            price: 70608.22,
            price_sources: [],
            processed_ms: 1711627227077,
          },
          {
            leverage: -2.0,
            order_type: "SHORT",
            order_uuid: "e225f48b-aa23-4fa5-b82f-63f007a9a39b",
            price: 71521.6,
            price_sources: [],
            processed_ms: 1711638310272,
          },
          {
            leverage: -2.0,
            order_type: "SHORT",
            order_uuid: "adf3657b-b8c5-41bf-9f80-778c7cb606dc",
            price: 71374.15,
            price_sources: [],
            processed_ms: 1711638513460,
          },
        ],
        position_type: "FLAT",
        position_uuid: "f754d831-d7d6-4c60-b9e2-0c23af5c51f5",
        return_at_close: 1.1088292796813597,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 70629.83,
        close_ms: 1711682965909,
        current_return: 0.9353089197581259,
        initial_entry_price: 70629.83,
        is_closed_position: true,
        miner_hotkey: "5GedR4ruCK8xAmVdU6DKmu7D8zJZ6UfPty7SFQNF7bFXE3Qb",
        net_leverage: 19.0,
        open_ms: 1711645228192,
        orders: [
          {
            leverage: 19.0,
            order_type: "LONG",
            order_uuid: "ffff05b4-1930-4248-b1b4-bc8ce9e96cf4",
            price: 70629.83,
            price_sources: [],
            processed_ms: 1711645228191,
          },
        ],
        position_type: "FLAT",
        position_uuid: "0f177c4e-c592-4a62-8d7a-05c944131687",
        return_at_close: 0.8997671808073171,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
    ],
    thirty_day_returns: 1.0,
  },
  "5GgbDcvvmt2wp5LczcEaz9ChLUTt18469CuLvny2mrxNgAfe": {
    all_time_returns: 1.036823606221656,
    n_positions: 8,
    percentage_profitable: 0.875,
    positions: [
      {
        average_entry_price: 1.26825,
        close_ms: 1720081682115,
        current_return: 0.996976148235758,
        initial_entry_price: 1.26825,
        is_closed_position: true,
        miner_hotkey: "5GgbDcvvmt2wp5LczcEaz9ChLUTt18469CuLvny2mrxNgAfe",
        net_leverage: 0.0,
        open_ms: 1719986667167,
        orders: [
          {
            leverage: -0.5,
            order_type: "SHORT",
            order_uuid: "be373a89-7d6b-4fb1-b544-f01b8066843c",
            price: 1.26825,
            price_sources: [],
            processed_ms: 1719986667093,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "6ee4c0bd-6afc-4e8f-903c-79576c882a41",
            price: 1.27592,
            price_sources: [
              {
                close: 1.27592,
                high: 1.27592,
                lag_ms: 115,
                low: 1.27592,
                open: 1.27592,
                source: "Polygon_ws",
                start_ms: 1720081682000,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 1.27592,
                websocket: true,
              },
              {
                close: 1.27591,
                high: 1.27591,
                lag_ms: 115,
                low: 1.27591,
                open: 1.27591,
                source: "TwelveData_ws",
                start_ms: 1720081682000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1720081682115,
          },
        ],
        position_type: "FLAT",
        position_uuid: "ec5954e2-d663-4137-90d9-da828d4a259e",
        return_at_close: 0.9969412540705698,
        trade_pair: ["GBPUSD", "GBP/USD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 58073.05,
        close_ms: 1720110682777,
        current_return: 1.0035908222488745,
        initial_entry_price: 58073.05,
        is_closed_position: true,
        miner_hotkey: "5GgbDcvvmt2wp5LczcEaz9ChLUTt18469CuLvny2mrxNgAfe",
        net_leverage: 0.0,
        open_ms: 1720081713483,
        orders: [
          {
            leverage: -1.0,
            order_type: "SHORT",
            order_uuid: "9851b9b0-c306-4f0d-aa4b-35bd4c6fc71d",
            price: 58073.05,
            price_sources: [
              {
                close: 58073.05,
                high: 58073.26,
                lag_ms: 131,
                low: 58073.05,
                open: 58073.05,
                source: "Polygon_ws",
                start_ms: 1720081711999,
                timespan_ms: 0,
                volume: 0.01845802,
                vwap: 58073.1118,
                websocket: true,
              },
              {
                close: 58065.02,
                high: 58070.0,
                lag_ms: 2869,
                low: 58065.02,
                open: 58066.98,
                source: "Polygon_rest",
                start_ms: 1720081708000,
                timespan_ms: 1000,
                volume: 0.27053991,
                vwap: 58065.1265,
                websocket: false,
              },
              {
                close: 58111.6,
                high: 58111.6,
                lag_ms: 9868,
                low: 58111.6,
                open: 58111.6,
                source: "TwelveData_ws",
                start_ms: 1720081702000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
              {
                close: 58148.68,
                high: 58178.66,
                lag_ms: 91869,
                low: 58146.2,
                open: 58158.21,
                source: "TwelveData_rest",
                start_ms: 1720081560000,
                timespan_ms: 60000,
                volume: null,
                vwap: null,
                websocket: false,
              },
            ],
            processed_ms: 1720081711868,
          },
          {
            leverage: 0.01,
            order_type: "FLAT",
            order_uuid: "156f6708-a925-44e8-8434-d140f23883ed",
            price: 57864.52,
            price_sources: [
              {
                close: 57864.52,
                high: 57867.47,
                lag_ms: 222,
                low: 57860.67,
                open: 57864.52,
                source: "Polygon_ws",
                start_ms: 1720110682999,
                timespan_ms: 0,
                volume: 0.00577423,
                vwap: 57865.0023,
                websocket: true,
              },
              {
                close: 57843.4,
                high: 57843.4,
                lag_ms: 2777,
                low: 57843.4,
                open: 57843.4,
                source: "TwelveData_ws",
                start_ms: 1720110680000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
              {
                close: 57858.22,
                high: 57893.7,
                lag_ms: 2778,
                low: 57858.22,
                open: 57858.57,
                source: "Polygon_rest",
                start_ms: 1720110679000,
                timespan_ms: 1000,
                volume: 0.01758794,
                vwap: 57858.7733,
                websocket: false,
              },
              {
                close: 57826.65,
                high: 57896.43,
                lag_ms: 22778,
                low: 57826.64,
                open: 57896.43,
                source: "TwelveData_rest",
                start_ms: 1720110600000,
                timespan_ms: 60000,
                volume: null,
                vwap: null,
                websocket: false,
              },
            ],
            processed_ms: 1720110682777,
          },
        ],
        position_type: "FLAT",
        position_uuid: "4c71b203-db62-43d8-a278-40fdf2be722e",
        return_at_close: 1.0025872314266258,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 2926.365,
        close_ms: 1720198188479,
        current_return: 1.0163944687325464,
        initial_entry_price: 3115.38,
        is_closed_position: true,
        miner_hotkey: "5GgbDcvvmt2wp5LczcEaz9ChLUTt18469CuLvny2mrxNgAfe",
        net_leverage: 0.0,
        open_ms: 1720110714191,
        orders: [
          {
            leverage: 1.0,
            order_type: "LONG",
            order_uuid: "e3ee630d-a30b-4b7e-b5eb-be327a2df366",
            price: 3115.38,
            price_sources: [
              {
                close: 3115.38,
                high: 3115.4,
                lag_ms: 165,
                low: 3115.38,
                open: 3115.38,
                source: "Polygon_ws",
                start_ms: 1720110713999,
                timespan_ms: 0,
                volume: 0.57304395,
                vwap: 3115.4,
                websocket: true,
              },
              {
                close: 3115.36,
                high: 3115.37,
                lag_ms: 2835,
                low: 3115.36,
                open: 3115.37,
                source: "Polygon_rest",
                start_ms: 1720110710000,
                timespan_ms: 1000,
                volume: 0.06693558,
                vwap: 3115.3601,
                websocket: false,
              },
              {
                close: 3119.35,
                high: 3119.35,
                lag_ms: 3834,
                low: 3119.35,
                open: 3119.35,
                source: "TwelveData_ws",
                start_ms: 1720110710000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
              {
                close: 3124.78003,
                high: 3125.56006,
                lag_ms: 113835,
                low: 3124.53003,
                open: 3125.56006,
                source: "TwelveData_rest",
                start_ms: 1720110540000,
                timespan_ms: 60000,
                volume: null,
                vwap: null,
                websocket: false,
              },
            ],
            processed_ms: 1720110713834,
          },
          {
            leverage: -0.75,
            order_type: "SHORT",
            order_uuid: "c76ae386-eaee-4182-9b79-141b2d46eee7",
            price: 3133.4,
            price_sources: [
              {
                close: 3133.4,
                high: 3133.4,
                lag_ms: 201,
                low: 3133.4,
                open: 3133.4,
                source: "Polygon_ws",
                start_ms: 1720125958999,
                timespan_ms: 0,
                volume: 0.01979716,
                vwap: 3133.4,
                websocket: true,
              },
              {
                close: 3133.08,
                high: 3133.08,
                lag_ms: 2799,
                low: 3133.08,
                open: 3133.08,
                source: "Polygon_rest",
                start_ms: 1720125955000,
                timespan_ms: 1000,
                volume: 0.0333007,
                vwap: 3133.08,
                websocket: false,
              },
              {
                close: 3134.62,
                high: 3134.62,
                lag_ms: 7202,
                low: 3134.62,
                open: 3134.62,
                source: "TwelveData_ws",
                start_ms: 1720125966000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
              {
                close: 3133.63989,
                high: 3133.63989,
                lag_ms: 178799,
                low: 3132.3501,
                open: 3132.3501,
                source: "TwelveData_rest",
                start_ms: 1720125720000,
                timespan_ms: 60000,
                volume: null,
                vwap: null,
                websocket: false,
              },
            ],
            processed_ms: 1720125958798,
          },
          {
            leverage: 0.75,
            order_type: "LONG",
            order_uuid: "12a4233f-a5c4-40ee-abb0-6c821cdb5dbc",
            price: 2881.38,
            price_sources: [
              {
                close: 2881.38,
                high: 2881.38,
                lag_ms: 56,
                low: 2881.01,
                open: 2881.38,
                source: "Polygon_ws",
                start_ms: 1720159999000,
                timespan_ms: 0,
                volume: 1.43841494,
                vwap: 2881.3766,
                websocket: true,
              },
              {
                close: 2881.16,
                high: 2881.48,
                lag_ms: 2057,
                low: 2880.87,
                open: 2880.87,
                source: "Polygon_rest",
                start_ms: 1720159996000,
                timespan_ms: 1000,
                volume: 6.84347257,
                vwap: 2880.9439,
                websocket: false,
              },
              {
                close: 2881.16,
                high: 2881.16,
                lag_ms: 3944,
                low: 2881.16,
                open: 2881.16,
                source: "TwelveData_ws",
                start_ms: 1720160003000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
              {
                close: 2875.03003,
                high: 2878.13989,
                lag_ms: 259057,
                low: 2874.16992,
                open: 2875.40991,
                source: "TwelveData_rest",
                start_ms: 1720159680000,
                timespan_ms: 60000,
                volume: null,
                vwap: null,
                websocket: false,
              },
            ],
            processed_ms: 1720159999056,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "ed9be99e-7c4d-47f8-a5da-0b1de6dca68f",
            price: 2977.44,
            price_sources: [
              {
                close: 2977.44,
                high: 2977.99,
                lag_ms: 479,
                low: 2977.44,
                open: 2977.44,
                source: "Polygon_ws",
                start_ms: 1720198188000,
                timespan_ms: 0,
                volume: 0.70072862,
                vwap: 2977.6661,
                websocket: true,
              },
              {
                close: 2976.41,
                high: 2976.48,
                lag_ms: 2480,
                low: 2976.1,
                open: 2976.48,
                source: "Polygon_rest",
                start_ms: 1720198185000,
                timespan_ms: 1000,
                volume: 0.13589809,
                vwap: 2976.2773,
                websocket: false,
              },
              {
                close: 2977.26,
                high: 2977.26,
                lag_ms: 2521,
                low: 2977.26,
                open: 2977.26,
                source: "TwelveData_ws",
                start_ms: 1720198191000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
              {
                close: 2975.69995,
                high: 2975.69995,
                lag_ms: 48480,
                low: 2973.1001,
                open: 2973.1001,
                source: "TwelveData_rest",
                start_ms: 1720198080000,
                timespan_ms: 60000,
                volume: null,
                vwap: null,
                websocket: false,
              },
            ],
            processed_ms: 1720198188479,
          },
        ],
        position_type: "FLAT",
        position_uuid: "74eb5285-90c2-4225-b4ea-39fee4d9a4fc",
        return_at_close: 1.0146157784122645,
        trade_pair: ["ETHUSD", "ETH/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 57782.28,
        close_ms: 1720363100187,
        current_return: 1.016496060730037,
        initial_entry_price: 57782.28,
        is_closed_position: true,
        miner_hotkey: "5GgbDcvvmt2wp5LczcEaz9ChLUTt18469CuLvny2mrxNgAfe",
        net_leverage: 0.0,
        open_ms: 1720291762562,
        orders: [
          {
            leverage: -1.0,
            order_type: "SHORT",
            order_uuid: "4d0b9702-5c8e-43a0-a3df-ceb5daced3d4",
            price: 57782.28,
            price_sources: [
              {
                close: 57782.28,
                high: 57782.28,
                lag_ms: 243,
                low: 57782.26,
                open: 57782.28,
                source: "Polygon_ws",
                start_ms: 1720291761999,
                timespan_ms: 0,
                volume: 2e-8,
                vwap: 57782.27,
                websocket: true,
              },
              {
                close: 57782.39,
                high: 57782.39,
                lag_ms: 2757,
                low: 57782.33,
                open: 57782.33,
                source: "Polygon_rest",
                start_ms: 1720291758000,
                timespan_ms: 1000,
                volume: 2e-8,
                vwap: 57782.36,
                websocket: false,
              },
              {
                close: 57785.5,
                high: 57785.5,
                lag_ms: 5756,
                low: 57785.5,
                open: 57785.5,
                source: "TwelveData_ws",
                start_ms: 1720291756000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
              {
                close: 57791.42,
                high: 57793.14,
                lag_ms: 81757,
                low: 57745.65,
                open: 57767.36,
                source: "TwelveData_rest",
                start_ms: 1720291620000,
                timespan_ms: 60000,
                volume: null,
                vwap: null,
                websocket: false,
              },
            ],
            processed_ms: 1720291761756,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "8ff028a9-8f58-4036-8057-ffcae0e6d731",
            price: 56829.1,
            price_sources: [
              {
                close: 56829.1,
                high: 56829.1,
                lag_ms: 187,
                low: 56829.1,
                open: 56829.1,
                source: "Polygon_ws",
                start_ms: 1720363100000,
                timespan_ms: 0,
                volume: 0.00055396,
                vwap: 56829.1,
                websocket: true,
              },
              {
                close: 56794.0,
                high: 56794.0,
                lag_ms: 813,
                low: 56794.0,
                open: 56794.0,
                source: "TwelveData_ws",
                start_ms: 1720363101000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
              {
                close: 56944.0,
                high: 56953.0,
                lag_ms: 2188,
                low: 56779.96,
                open: 56792.16,
                source: "Polygon_rest",
                start_ms: 1720363097000,
                timespan_ms: 1000,
                volume: 0.80719124,
                vwap: 56948.5525,
                websocket: false,
              },
              {
                close: 56780.74,
                high: 56833.12,
                lag_ms: 80188,
                low: 56761.76,
                open: 56833.12,
                source: "TwelveData_rest",
                start_ms: 1720362960000,
                timespan_ms: 60000,
                volume: null,
                vwap: null,
                websocket: false,
              },
            ],
            processed_ms: 1720363100187,
          },
        ],
        position_type: "FLAT",
        position_uuid: "7957476c-9a50-431d-8d1c-cf45b4b4e294",
        return_at_close: 1.015479564669307,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 2972.77,
        close_ms: 1720363131884,
        current_return: 1.0025498104461494,
        initial_entry_price: 2972.77,
        is_closed_position: true,
        miner_hotkey: "5GgbDcvvmt2wp5LczcEaz9ChLUTt18469CuLvny2mrxNgAfe",
        net_leverage: 0.0,
        open_ms: 1720198221116,
        orders: [
          {
            leverage: -1.0,
            order_type: "SHORT",
            order_uuid: "e0a1ab66-c1ec-44ab-ae58-f4290c1386a5",
            price: 2972.77,
            price_sources: [
              {
                close: 2972.77,
                high: 2973.02,
                lag_ms: 267,
                low: 2972.77,
                open: 2972.77,
                source: "Polygon_ws",
                start_ms: 1720198220999,
                timespan_ms: 0,
                volume: 2.09441595,
                vwap: 2973.0013,
                websocket: true,
              },
              {
                close: 2976.69,
                high: 2978.1,
                lag_ms: 2733,
                low: 2973.7,
                open: 2978.1,
                source: "Polygon_rest",
                start_ms: 1720198217000,
                timespan_ms: 1000,
                volume: 0.45474857,
                vwap: 2977.2845,
                websocket: false,
              },
              {
                close: 2974.9,
                high: 2974.9,
                lag_ms: 3732,
                low: 2974.9,
                open: 2974.9,
                source: "TwelveData_ws",
                start_ms: 1720198217000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
              {
                close: 2975.69995,
                high: 2975.69995,
                lag_ms: 80733,
                low: 2973.1001,
                open: 2973.1001,
                source: "TwelveData_rest",
                start_ms: 1720198080000,
                timespan_ms: 60000,
                volume: null,
                vwap: null,
                websocket: false,
              },
            ],
            processed_ms: 1720198220732,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "34af52af-b571-41b4-a47f-7937d800f0e2",
            price: 2965.19,
            price_sources: [
              {
                close: 2965.19,
                high: 2965.43,
                lag_ms: 116,
                low: 2965.19,
                open: 2965.19,
                source: "Polygon_ws",
                start_ms: 1720363132000,
                timespan_ms: 0,
                volume: 0.09856046,
                vwap: 2965.2713,
                websocket: true,
              },
              {
                close: 2966.04,
                high: 2966.04,
                lag_ms: 1884,
                low: 2966.04,
                open: 2966.04,
                source: "TwelveData_ws",
                start_ms: 1720363130000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1720363131884,
          },
        ],
        position_type: "FLAT",
        position_uuid: "4418185c-2585-4315-ae11-19d93f666518",
        return_at_close: 1.0015472606357032,
        trade_pair: ["ETHUSD", "ETH/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 1.75976,
        close_ms: 1720417128854,
        current_return: 1.0006989589489474,
        initial_entry_price: 1.75976,
        is_closed_position: true,
        miner_hotkey: "5GgbDcvvmt2wp5LczcEaz9ChLUTt18469CuLvny2mrxNgAfe",
        net_leverage: 0.0,
        open_ms: 1720386243493,
        orders: [
          {
            leverage: 0.5,
            order_type: "LONG",
            order_uuid: "0359ca4a-9e8f-416a-b07a-a88c9edb8d86",
            price: 1.75976,
            price_sources: [
              {
                close: 1.75976,
                high: 1.75976,
                lag_ms: 2312,
                low: 1.75976,
                open: 1.75976,
                source: "TwelveData_ws",
                start_ms: 1720386245000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
              {
                close: 1.75859,
                high: 1.75859,
                lag_ms: 241689,
                low: 1.75859,
                open: 1.75859,
                source: "Polygon_ws",
                start_ms: 1720386000999,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 1.75859,
                websocket: true,
              },
              {
                close: 1.7639,
                high: 1.7639,
                lag_ms: 173042689,
                low: 1.7636,
                open: 1.76384,
                source: "TwelveData_rest",
                start_ms: 1720213140000,
                timespan_ms: 60000,
                volume: null,
                vwap: null,
                websocket: false,
              },
            ],
            processed_ms: 1720386242688,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "b78f690b-dbce-4ab7-935c-4bd5af0e78e3",
            price: 1.76222,
            price_sources: [
              {
                close: 1.76222,
                high: 1.76222,
                lag_ms: 145,
                low: 1.76222,
                open: 1.76222,
                source: "Polygon_ws",
                start_ms: 1720417128999,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 1.76224,
                websocket: true,
              },
              {
                close: 1.76212,
                high: 1.76212,
                lag_ms: 854,
                low: 1.76212,
                open: 1.76212,
                source: "TwelveData_ws",
                start_ms: 1720417128000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1720417128854,
          },
        ],
        position_type: "FLAT",
        position_uuid: "4ca79b6d-6e08-47a9-9977-f2a3c5c4dc2e",
        return_at_close: 1.0006639344853843,
        trade_pair: ["EURNZD", "EUR/NZD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 3086.48,
        close_ms: 1720554977058,
        current_return: 1.001521960291335,
        initial_entry_price: 3086.48,
        is_closed_position: true,
        miner_hotkey: "5GgbDcvvmt2wp5LczcEaz9ChLUTt18469CuLvny2mrxNgAfe",
        net_leverage: 0.0,
        open_ms: 1720507149139,
        orders: [
          {
            leverage: -0.25,
            order_type: "SHORT",
            order_uuid: "8be419be-ec12-46ac-b328-49b50457e5f4",
            price: 3086.48,
            price_sources: [
              {
                close: 3086.48,
                high: 3086.48,
                lag_ms: 441,
                low: 3086.48,
                open: 3086.48,
                source: "Polygon_ws",
                start_ms: 1720507146999,
                timespan_ms: 0,
                volume: 0.26647,
                vwap: 3086.48,
                websocket: true,
              },
              {
                close: 3085.23,
                high: 3085.23,
                lag_ms: 2440,
                low: 3085.23,
                open: 3085.23,
                source: "TwelveData_ws",
                start_ms: 1720507145000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
              {
                close: 3086.23,
                high: 3086.23,
                lag_ms: 6441,
                low: 3086.0,
                open: 3086.0,
                source: "Polygon_rest",
                start_ms: 1720507140000,
                timespan_ms: 1000,
                volume: 1.03208348,
                vwap: 3086.0067,
                websocket: false,
              },
              {
                close: 3079.94995,
                high: 3079.98999,
                lag_ms: 1027441,
                low: 3077.05005,
                open: 3077.05005,
                source: "TwelveData_rest",
                start_ms: 1720506060000,
                timespan_ms: 60000,
                volume: null,
                vwap: null,
                websocket: false,
              },
            ],
            processed_ms: 1720507147440,
          },
          {
            leverage: 0.01,
            order_type: "FLAT",
            order_uuid: "bc718ca4-8a6b-40f1-9c1e-32c329697ce7",
            price: 3067.69,
            price_sources: [
              {
                close: 3067.69,
                high: 3067.69,
                lag_ms: 58,
                low: 3067.68,
                open: 3067.69,
                source: "Polygon_ws",
                start_ms: 1720554977000,
                timespan_ms: 0,
                volume: 0.03815457,
                vwap: 3067.6892,
                websocket: true,
              },
              {
                close: 3068.86,
                high: 3068.86,
                lag_ms: 58,
                low: 3068.86,
                open: 3068.86,
                source: "TwelveData_ws",
                start_ms: 1720554977000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
              {
                close: 3067.57,
                high: 3067.57,
                lag_ms: 2059,
                low: 3067.57,
                open: 3067.57,
                source: "Polygon_rest",
                start_ms: 1720554974000,
                timespan_ms: 1000,
                volume: 0.00326376,
                vwap: 3067.57,
                websocket: false,
              },
              {
                close: 3065.08008,
                high: 3065.54004,
                lag_ms: 77059,
                low: 3063.22998,
                open: 3063.22998,
                source: "TwelveData_rest",
                start_ms: 1720554840000,
                timespan_ms: 60000,
                volume: null,
                vwap: null,
                websocket: false,
              },
            ],
            processed_ms: 1720554977058,
          },
        ],
        position_type: "FLAT",
        position_uuid: "81789960-671a-44e4-9ed3-9fb7e8aa80ba",
        return_at_close: 1.0012715798012621,
        trade_pair: ["ETHUSD", "ETH/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 58957.15,
        close_ms: 1720619434218,
        current_return: 1.0034457652718967,
        initial_entry_price: 58957.15,
        is_closed_position: true,
        miner_hotkey: "5GgbDcvvmt2wp5LczcEaz9ChLUTt18469CuLvny2mrxNgAfe",
        net_leverage: 0.0,
        open_ms: 1720590269564,
        orders: [
          {
            leverage: -0.15,
            order_type: "SHORT",
            order_uuid: "93f82bcc-12aa-4862-baf7-077725331368",
            price: 58957.15,
            price_sources: [
              {
                close: 58957.15,
                high: 58957.15,
                lag_ms: 374,
                low: 58957.15,
                open: 58957.15,
                source: "Polygon_ws",
                start_ms: 1720590268999,
                timespan_ms: 0,
                volume: 0.00042733,
                vwap: 58957.15,
                websocket: true,
              },
              {
                close: 58956.1,
                high: 58956.1,
                lag_ms: 2374,
                low: 58948.36,
                open: 58948.36,
                source: "Polygon_rest",
                start_ms: 1720590266000,
                timespan_ms: 1000,
                volume: 0.0307438,
                vwap: 58951.3343,
                websocket: false,
              },
              {
                close: 58936.9,
                high: 58936.9,
                lag_ms: 19373,
                low: 58936.9,
                open: 58936.9,
                source: "TwelveData_ws",
                start_ms: 1720590250000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
              {
                close: 58948.42,
                high: 58965.08,
                lag_ms: 29374,
                low: 58936.95,
                open: 58965.08,
                source: "TwelveData_rest",
                start_ms: 1720590180000,
                timespan_ms: 60000,
                volume: null,
                vwap: null,
                websocket: false,
              },
            ],
            processed_ms: 1720590269373,
          },
          {
            leverage: 0.01,
            order_type: "FLAT",
            order_uuid: "53a151cd-5fd7-44aa-bb94-2328664106d8",
            price: 57602.8,
            price_sources: [
              {
                close: 57602.8,
                high: 57717.0,
                lag_ms: 218,
                low: 57575.2,
                open: 57602.8,
                source: "Polygon_ws",
                start_ms: 1720619434000,
                timespan_ms: 0,
                volume: 1.69365179,
                vwap: 57580.4577,
                websocket: true,
              },
              {
                close: 57699.1,
                high: 57699.1,
                lag_ms: 782,
                low: 57699.1,
                open: 57699.1,
                source: "TwelveData_ws",
                start_ms: 1720619435000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
              {
                close: 57575.81,
                high: 57717.0,
                lag_ms: 2219,
                low: 57575.81,
                open: 57577.9,
                source: "Polygon_rest",
                start_ms: 1720619431000,
                timespan_ms: 1000,
                volume: 1.38760024,
                vwap: 57598.5169,
                websocket: false,
              },
              {
                close: 57651.98,
                high: 57766.17,
                lag_ms: 34219,
                low: 57645.53,
                open: 57713.84,
                source: "TwelveData_rest",
                start_ms: 1720619340000,
                timespan_ms: 60000,
                volume: null,
                vwap: null,
                websocket: false,
              },
            ],
            processed_ms: 1720619434218,
          },
        ],
        position_type: "FLAT",
        position_uuid: "d6b8f61a-9ec0-4c8a-be10-20085fddba01",
        return_at_close: 1.0032952484071058,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 159.024,
        close_ms: 0,
        current_return: 0.9916254246726189,
        initial_entry_price: 159.829,
        is_closed_position: false,
        miner_hotkey: "5GgbDcvvmt2wp5LczcEaz9ChLUTt18469CuLvny2mrxNgAfe",
        net_leverage: -0.5,
        open_ms: 1719383623207,
        orders: [
          {
            leverage: -1.0,
            order_type: "SHORT",
            order_uuid: "85429940-30a0-4204-ac12-a5bd505fc836",
            price: 159.829,
            price_sources: [],
            processed_ms: 1719383623198,
          },
          {
            leverage: 0.5,
            order_type: "LONG",
            order_uuid: "305e1b34-bf00-4260-97cd-fd49cab88f32",
            price: 160.63400000000001,
            price_sources: [
              {
                close: 160.63400000000001,
                high: 160.63400000000001,
                lag_ms: 445,
                low: 160.63400000000001,
                open: 160.63400000000001,
                source: "Polygon_ws",
                start_ms: 1720417173000,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 160.63,
                websocket: true,
              },
              {
                close: 160.64,
                high: 160.64,
                lag_ms: 445,
                low: 160.64,
                open: 160.64,
                source: "TwelveData_ws",
                start_ms: 1720417173000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1720417173445,
          },
        ],
        position_type: "SHORT",
        position_uuid: "fcc8f4b8-2999-43aa-8e80-58b59a741c1f",
        return_at_close: 0.9915733643378236,
        trade_pair: ["USDJPY", "USD/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 3119.76,
        close_ms: 0,
        current_return: 0.9994489960766213,
        initial_entry_price: 3119.76,
        is_closed_position: false,
        miner_hotkey: "5GgbDcvvmt2wp5LczcEaz9ChLUTt18469CuLvny2mrxNgAfe",
        net_leverage: -0.1,
        open_ms: 1720626469211,
        orders: [
          {
            leverage: -0.1,
            order_type: "SHORT",
            order_uuid: "2cdf2275-51a8-4f5d-b97c-ad28fd084384",
            price: 3119.76,
            price_sources: [
              {
                close: 3119.76,
                high: 3119.76,
                lag_ms: 234,
                low: 3119.76,
                open: 3119.76,
                source: "Polygon_ws",
                start_ms: 1720626468999,
                timespan_ms: 0,
                volume: 0.77847902,
                vwap: 3119.76,
                websocket: true,
              },
              {
                close: 3119.47,
                high: 3119.47,
                lag_ms: 235,
                low: 3119.47,
                open: 3119.47,
                source: "TwelveData_ws",
                start_ms: 1720626469000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
              {
                close: 3119.81,
                high: 3119.82,
                lag_ms: 2766,
                low: 3119.81,
                open: 3119.82,
                source: "Polygon_rest",
                start_ms: 1720626465000,
                timespan_ms: 1000,
                volume: 1.84127736,
                vwap: 3119.8101,
                websocket: false,
              },
              {
                close: 3116.19995,
                high: 3121.06006,
                lag_ms: 48766,
                low: 3116.19995,
                open: 3121.06006,
                source: "TwelveData_rest",
                start_ms: 1720626360000,
                timespan_ms: 60000,
                volume: null,
                vwap: null,
                websocket: false,
              },
            ],
            processed_ms: 1720626468765,
          },
        ],
        position_type: "SHORT",
        position_uuid: "a6211adf-977f-4352-a1d4-ac7083794f1e",
        return_at_close: 0.9993990236268174,
        trade_pair: ["ETHUSD", "ETH/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 57882.04,
        close_ms: 0,
        current_return: 0.9995595179437352,
        initial_entry_price: 57882.04,
        is_closed_position: false,
        miner_hotkey: "5GgbDcvvmt2wp5LczcEaz9ChLUTt18469CuLvny2mrxNgAfe",
        net_leverage: -0.1,
        open_ms: 1720626398887,
        orders: [
          {
            leverage: -0.1,
            order_type: "SHORT",
            order_uuid: "23b5fcc8-4e0d-43c3-a535-b550a48fe8a1",
            price: 57882.04,
            price_sources: [
              {
                close: 57882.04,
                high: 57882.04,
                lag_ms: 137,
                low: 57882.04,
                open: 57882.04,
                source: "Polygon_ws",
                start_ms: 1720626398999,
                timespan_ms: 0,
                volume: 1.724e-5,
                vwap: 57882.04,
                websocket: true,
              },
              {
                close: 57995.4,
                high: 57995.4,
                lag_ms: 1862,
                low: 57995.4,
                open: 57995.4,
                source: "TwelveData_ws",
                start_ms: 1720626397000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1720626398862,
          },
        ],
        position_type: "SHORT",
        position_uuid: "73ba0f87-a433-4424-a471-3ed4084e16a0",
        return_at_close: 0.999509539967838,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
    ],
    thirty_day_returns: 1.036823606221656,
  },

  "5GhCxfBcA7Ur5iiAS343xwvrYHTUfBjBi4JimiL5LhujRT9t": {
    all_time_returns: 1.1603039530206414,
    n_positions: 152,
    percentage_profitable: 0.9342105263157895,
    positions: [
      {
        average_entry_price: 0.9773,
        close_ms: 1711464715810,
        current_return: 0.9996393123912821,
        is_closed_position: true,
        miner_hotkey: "5GhCxfBcA7Ur5iiAS343xwvrYHTUfBjBi4JimiL5LhujRT9t",
        net_leverage: 0,
        open_ms: 1711443744886,
        orders: [
          {
            leverage: -0.25,
            order_type: "SHORT",
            order_uuid: "cf5419db-926d-4d7c-a13f-2005e393b09d",
            price: 0.9773,
            price_sources: [],
            processed_ms: 1711443744885,
          },
          {
            leverage: 0,
            order_type: "FLAT",
            order_uuid: "00b30a62-fa2f-4d03-9420-23022bd697cd",
            price: 0.97871,
            price_sources: [],
            processed_ms: 1711464715810,
          },
        ],
        position_type: "FLAT",
        position_uuid: "d40580d0-3f3e-4f8a-9b88-e1d2220da8fe",
        return_at_close: 0.9996218187033152,
        trade_pair: ["EURCHF", "EUR/CHF", 0.00007, 0.1, 5],
      },
      {
        average_entry_price: 98.261,
        close_ms: 1711645786645,
        current_return: 1.0040316096925535,
        is_closed_position: true,
        miner_hotkey: "5GhCxfBcA7Ur5iiAS343xwvrYHTUfBjBi4JimiL5LhujRT9t",
        net_leverage: 0,
        open_ms: 1711616677822,
        orders: [
          {
            leverage: 0.95,
            order_type: "LONG",
            order_uuid: "d0c8095d-dfd9-4a26-b1aa-f5c3275ea869",
            price: 98.261,
            price_sources: [],
            processed_ms: 1711616677822,
          },
          {
            leverage: 0,
            order_type: "FLAT",
            order_uuid: "1620860a-fdf5-4262-9e57-61c9c0296c21",
            price: 98.678,
            price_sources: [],
            processed_ms: 1711645786645,
          },
        ],
        position_type: "FLAT",
        position_uuid: "e4204786-ec96-4bf5-827f-29ab96e6d138",
        return_at_close: 1.003964841590509,
        trade_pair: ["AUDJPY", "AUD/JPY", 0.00007, 0.1, 5],
      },
      {
        average_entry_price: 162.83899999999997,
        close_ms: 1712077268344,
        current_return: 1.0000620244536014,
        is_closed_position: true,
        miner_hotkey: "5GhCxfBcA7Ur5iiAS343xwvrYHTUfBjBi4JimiL5LhujRT9t",
        net_leverage: 0,
        open_ms: 1712032744263,
        orders: [
          {
            leverage: 0.025,
            order_type: "LONG",
            order_uuid: "18f03242-98a5-4b45-9a06-faf8f01c5069",
            price: 162.839,
            price_sources: [],
            processed_ms: 1712032744262,
          },
          {
            leverage: 0,
            order_type: "FLAT",
            order_uuid: "f8897a0f-43fc-4c1d-be30-7e0b38565f8a",
            price: 163.243,
            price_sources: [],
            processed_ms: 1712077268344,
          },
        ],
        position_type: "FLAT",
        position_uuid: "3ff261d3-b8ee-441c-98e3-7e1a5db02327",
        return_at_close: 1.0000602743450586,
        trade_pair: ["EURJPY", "EUR/JPY", 0.00007, 0.1, 5],
      },
      {
        average_entry_price: 64366.24,
        close_ms: 1712232070809,
        current_return: 1.0009608635856577,
        is_closed_position: true,
        miner_hotkey: "5GhCxfBcA7Ur5iiAS343xwvrYHTUfBjBi4JimiL5LhujRT9t",
        net_leverage: 0,
        open_ms: 1712059272674,
        orders: [
          {
            leverage: 0.033,
            order_type: "LONG",
            order_uuid: "ccb941db-d5b5-466c-9253-9104e2f24546",
            price: 65520.31,
            price_sources: [],
            processed_ms: 1712059272673,
          },
          {
            leverage: 0.033,
            order_type: "LONG",
            order_uuid: "ad8b6b05-2d73-4e2f-9e62-c2ec1a60650a",
            price: 64930.26,
            price_sources: [],
            processed_ms: 1712073646514,
          },
          {
            leverage: -0.033,
            order_type: "SHORT",
            order_uuid: "4703e224-a652-48e8-bc3e-309b0a027895",
            price: 66084.33,
            price_sources: [],
            processed_ms: 1712088846262,
          },
          {
            leverage: 0,
            order_type: "FLAT",
            order_uuid: "8a65811a-7440-477e-8941-f92a03dbeb72",
            price: 66274,
            price_sources: [],
            processed_ms: 1712232070809,
          },
        ],
        position_type: "FLAT",
        position_uuid: "37e59df9-26a4-43eb-b1f6-d37828ac6132",
        return_at_close: 1.000894800168661,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.01, 0.5],
      },
      {
        average_entry_price: 1.35037,
        close_ms: 1712257308226,
        current_return: 1.0003232447403305,
        is_closed_position: true,
        miner_hotkey: "5GhCxfBcA7Ur5iiAS343xwvrYHTUfBjBi4JimiL5LhujRT9t",
        net_leverage: 0,
        open_ms: 1712221246316,
        orders: [
          {
            leverage: 0.11249999999999999,
            order_type: "LONG",
            order_uuid: "e1ccf098-d314-4c39-a7df-d3ff7fb851e7",
            price: 1.35037,
            price_sources: [],
            processed_ms: 1712221246315,
          },
          {
            leverage: 0,
            order_type: "FLAT",
            order_uuid: "6a446d93-a944-4f7e-b89d-80cdca5baf3c",
            price: 1.35425,
            price_sources: [],
            processed_ms: 1712257308226,
          },
        ],
        position_type: "FLAT",
        position_uuid: "abc2bceb-f9f4-4b94-a0b7-98efe42fab10",
        return_at_close: 1.0003153671947782,
        trade_pair: ["USDCAD", "USD/CAD", 0.00007, 0.1, 5],
      },
      {
        average_entry_price: 3255.0157999999997,
        close_ms: 1712448117532,
        current_return: 1.0007666151350727,
        is_closed_position: true,
        miner_hotkey: "5GhCxfBcA7Ur5iiAS343xwvrYHTUfBjBi4JimiL5LhujRT9t",
        net_leverage: 0,
        open_ms: 1712102444689,
        orders: [
          {
            leverage: 0.00825,
            order_type: "LONG",
            order_uuid: "2e5f26a4-3776-4902-b79e-1400cfb83c92",
            price: 3286.01,
            price_sources: [],
            processed_ms: 1712102444688,
          },
          {
            leverage: 0.01675,
            order_type: "LONG",
            order_uuid: "17131408-6dd4-48d3-bc59-7fa21169fa51",
            price: 3239.75,
            price_sources: [],
            processed_ms: 1712318462218,
          },
          {
            leverage: 0,
            order_type: "FLAT",
            order_uuid: "0d3079ea-06e6-4594-b61c-b1eeb19b524d",
            price: 3355.78,
            price_sources: [],
            processed_ms: 1712448117532,
          },
        ],
        position_type: "FLAT",
        position_uuid: "a269a550-05f0-4bf0-94ba-14b0d848db73",
        return_at_close: 1.0007415959696944,
        trade_pair: ["ETHUSD", "ETH/USD", 0.003, 0.01, 0.5],
      },
      {
        average_entry_price: 0.66242,
        close_ms: 1712552492583,
        current_return: 1.000867840644908,
        is_closed_position: true,
        miner_hotkey: "5GhCxfBcA7Ur5iiAS343xwvrYHTUfBjBi4JimiL5LhujRT9t",
        net_leverage: 0,
        open_ms: 1712523999142,
        orders: [
          {
            leverage: 0.2625,
            order_type: "LONG",
            order_uuid: "7141be06-fe3c-4e5d-96bc-189d09e65ca7",
            price: 0.66242,
            price_sources: [],
            processed_ms: 1712523999141,
          },
          {
            leverage: 0,
            order_type: "FLAT",
            order_uuid: "ef135ac7-fa45-42ae-a22c-f6aa240ff019",
            price: 0.66461,
            price_sources: [],
            processed_ms: 1712552492583,
          },
        ],
        position_type: "FLAT",
        position_uuid: "a23c69e4-4d37-4ac1-a797-3bdaba93cfc1",
        return_at_close: 1.0008494496983362,
        trade_pair: ["CADCHF", "CAD/CHF", 0.00007, 0.1, 5],
      },
      {
        average_entry_price: 1.2541,
        close_ms: 1712840573074,
        current_return: 1.000387528905191,
        is_closed_position: true,
        miner_hotkey: "5GhCxfBcA7Ur5iiAS343xwvrYHTUfBjBi4JimiL5LhujRT9t",
        net_leverage: 0,
        open_ms: 1712764910478,
        orders: [
          {
            leverage: 0.22500000000000003,
            order_type: "LONG",
            order_uuid: "4f10857c-ed43-4c16-8062-5a583ece54f4",
            price: 1.2541,
            price_sources: [],
            processed_ms: 1712764910474,
          },
          {
            leverage: 0,
            order_type: "FLAT",
            order_uuid: "31271769-0694-47a3-83b6-a52d1bac0938",
            price: 1.25626,
            price_sources: [],
            processed_ms: 1712840573074,
          },
        ],
        position_type: "FLAT",
        position_uuid: "f68d0de3-d307-4ddc-9141-59a9c45f144d",
        return_at_close: 1.0003717728016106,
        trade_pair: ["GBPUSD", "GBP/USD", 0.00007, 0.1, 5],
      },
      {
        average_entry_price: 65127.49999999998,
        close_ms: 1712953829528,
        current_return: 1.0004277070610341,
        is_closed_position: true,
        miner_hotkey: "5GhCxfBcA7Ur5iiAS343xwvrYHTUfBjBi4JimiL5LhujRT9t",
        net_leverage: 0,
        open_ms: 1712948121709,
        orders: [
          {
            leverage: 0.0125,
            order_type: "LONG",
            order_uuid: "c8052fa2-e97e-4ee5-8162-db56fb25809d",
            price: 66415.2,
            price_sources: [],
            processed_ms: 1712948121707,
          },
          {
            leverage: 0.0125,
            order_type: "LONG",
            order_uuid: "da87fa5d-7cfa-417c-ad02-58b0d9471462",
            price: 66457.99,
            price_sources: [],
            processed_ms: 1712948487747,
          },
          {
            leverage: 0.012500000000000004,
            order_type: "LONG",
            order_uuid: "f133deaf-b346-4fce-a77e-908982f0b90d",
            price: 66386.05,
            price_sources: [],
            processed_ms: 1712948505762,
          },
          {
            leverage: -0.025000000000000005,
            order_type: "SHORT",
            order_uuid: "3a363b59-677a-4b12-8ece-a24648a819e6",
            price: 67065.87,
            price_sources: [],
            processed_ms: 1712950526216,
          },
          {
            leverage: 0,
            order_type: "FLAT",
            order_uuid: "19551aa2-9e1c-4fb5-a80b-ec1826164c8f",
            price: 67400,
            price_sources: [],
            processed_ms: 1712953829528,
          },
        ],
        position_type: "FLAT",
        position_uuid: "e557a021-2459-4c73-b25d-13e9f6eb1499",
        return_at_close: 1.0003901910220192,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.01, 0.5],
      },
      {
        average_entry_price: 3192.7742857142853,
        close_ms: 1712980838666,
        current_return: 1.0005052506669976,
        is_closed_position: true,
        miner_hotkey: "5GhCxfBcA7Ur5iiAS343xwvrYHTUfBjBi4JimiL5LhujRT9t",
        net_leverage: 0,
        open_ms: 1712948715838,
        orders: [
          {
            leverage: 0.00625,
            order_type: "LONG",
            order_uuid: "f128ea12-3959-4de7-a920-a377ea5d4590",
            price: 3223.4,
            price_sources: [],
            processed_ms: 1712948715836,
          },
          {
            leverage: 0.00625,
            order_type: "LONG",
            order_uuid: "e3b17854-9007-4f51-872b-76e3de8a4864",
            price: 3220.2,
            price_sources: [],
            processed_ms: 1712949317115,
          },
          {
            leverage: 0.006250000000000002,
            order_type: "LONG",
            order_uuid: "afdfe352-751d-4b00-98ad-0eb8c6ccf33f",
            price: 3204.12,
            price_sources: [],
            processed_ms: 1712952021755,
          },
          {
            leverage: -0.006250000000000002,
            order_type: "SHORT",
            order_uuid: "326868d3-a534-4a76-88d7-1a560f842bcd",
            price: 3244.06,
            price_sources: [],
            processed_ms: 1712952922610,
          },
          {
            leverage: 0.006250000000000002,
            order_type: "LONG",
            order_uuid: "29fc5ac0-590d-47b5-a477-554b68f06f0d",
            price: 3221.8,
            price_sources: [],
            processed_ms: 1712955638894,
          },
          {
            leverage: 0.006249999999999999,
            order_type: "LONG",
            order_uuid: "33feedb7-e959-49ff-af4d-900242933e91",
            price: 3195.36,
            price_sources: [],
            processed_ms: 1712971875011,
          },
          {
            leverage: 0.012499999999999997,
            order_type: "LONG",
            order_uuid: "f70bec44-a822-431c-9703-c82e3792dc15",
            price: 3181.14,
            price_sources: [],
            processed_ms: 1712972769297,
          },
          {
            leverage: 0.006249999999999999,
            order_type: "LONG",
            order_uuid: "b7737763-6aef-4ce4-ae6a-717be0df75a1",
            price: 3166.32,
            price_sources: [],
            processed_ms: 1712973622235,
          },
          {
            leverage: 0,
            order_type: "FLAT",
            order_uuid: "3156903c-857e-4c8d-a6d5-98fc82e524d5",
            price: 3230,
            price_sources: [],
            processed_ms: 1712980838666,
          },
        ],
        position_type: "FLAT",
        position_uuid: "3fb6a7ac-ad58-4de1-b74d-91fa237ec511",
        return_at_close: 1.000461478562281,
        trade_pair: ["ETHUSD", "ETH/USD", 0.003, 0.01, 0.5],
      },
      {
        average_entry_price: 2890.82,
        close_ms: 1713045056527,
        current_return: 1.0000497912357047,
        is_closed_position: true,
        miner_hotkey: "5GhCxfBcA7Ur5iiAS343xwvrYHTUfBjBi4JimiL5LhujRT9t",
        net_leverage: 0,
        open_ms: 1713043554177,
        orders: [
          {
            leverage: 0.00625,
            order_type: "LONG",
            order_uuid: "50a6c7e5-a7f6-4b42-bfbf-f52a70a178a6",
            price: 2890.82,
            price_sources: [],
            processed_ms: 1713043554176,
          },
          {
            leverage: 0,
            order_type: "FLAT",
            order_uuid: "bf5bbf84-44c7-47af-9b09-c31d9277b7fa",
            price: 2913.85,
            price_sources: [],
            processed_ms: 1713045056527,
          },
        ],
        position_type: "FLAT",
        position_uuid: "8ceddf4b-f189-4a42-8e9c-d55290334f53",
        return_at_close: 1.0000435409245094,
        trade_pair: ["ETHUSD", "ETH/USD", 0.003, 0.01, 0.5],
      },
      {
        average_entry_price: 0.89064514855,
        close_ms: 1713146463675,
        current_return: 1.0008400482381914,
        is_closed_position: true,
        miner_hotkey: "5GhCxfBcA7Ur5iiAS343xwvrYHTUfBjBi4JimiL5LhujRT9t",
        net_leverage: 0,
        open_ms: 1712927037328,
        orders: [
          {
            leverage: 0.375,
            order_type: "LONG",
            order_uuid: "328d4874-cf7d-48c4-8e35-519b0a6a5469",
            price: 0.891338144,
            price_sources: [],
            processed_ms: 1712927037327,
          },
          {
            leverage: 0.375,
            order_type: "LONG",
            order_uuid: "7c2189ff-3ca2-4b7f-873d-72263700f66f",
            price: 0.8899521531,
            price_sources: [],
            processed_ms: 1712933541775,
          },
          {
            leverage: 0,
            order_type: "FLAT",
            order_uuid: "762f59c8-cfa8-431f-92ec-79f013ff0f56",
            price: 0.8916435046,
            price_sources: [],
            processed_ms: 1713146463675,
          },
        ],
        position_type: "FLAT",
        position_uuid: "358cb3df-6d69-45d7-9eed-5acac4aa100d",
        return_at_close: 1.0007875041356589,
        trade_pair: ["AUDCAD", "AUD/CAD", 0.00007, 0.1, 5],
      },
      {
        average_entry_price: 60717.960000000014,
        close_ms: 1713165266185,
        current_return: 1.006571846562991,
        is_closed_position: true,
        miner_hotkey: "5GhCxfBcA7Ur5iiAS343xwvrYHTUfBjBi4JimiL5LhujRT9t",
        net_leverage: 0,
        open_ms: 1713038462418,
        orders: [
          {
            leverage: 0.08750000000000001,
            order_type: "LONG",
            order_uuid: "e01ac593-9d07-45d5-b935-395d19902470",
            price: 64840.68,
            price_sources: [],
            processed_ms: 1713038462417,
          },
          {
            leverage: 0.1375,
            order_type: "LONG",
            order_uuid: "3bdd3bed-9a76-42ad-89dd-52107a39c5a1",
            price: 64242,
            price_sources: [],
            processed_ms: 1713052875506,
          },
          {
            leverage: 0.05000000000000002,
            order_type: "LONG",
            order_uuid: "c4d014ad-0b46-42a4-9dc6-a7fe8c3d8ccb",
            price: 63435.17,
            price_sources: [],
            processed_ms: 1713067248767,
          },
          {
            leverage: -0.2,
            order_type: "SHORT",
            order_uuid: "a27019a6-75e5-4f7f-b9f8-36bc1cc882c4",
            price: 65623.73,
            price_sources: [],
            processed_ms: 1713139270505,
          },
          {
            leverage: 0,
            order_type: "FLAT",
            order_uuid: "93638fe6-2bd9-433c-8d76-aea44880cd7e",
            price: 66399.6,
            price_sources: [],
            processed_ms: 1713165266185,
          },
        ],
        position_type: "FLAT",
        position_uuid: "90cc81fe-59e7-4104-b352-15141c2246d2",
        return_at_close: 1.0062950393051862,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.01, 0.5],
      },
      {
        average_entry_price: 0.9683599999999999,
        close_ms: 1713373339155,
        current_return: 1.000214925234417,
        is_closed_position: true,
        miner_hotkey: "5GhCxfBcA7Ur5iiAS343xwvrYHTUfBjBi4JimiL5LhujRT9t",
        net_leverage: 0,
        open_ms: 1713344522331,
        orders: [
          {
            leverage: 0.11250000000000002,
            order_type: "LONG",
            order_uuid: "e5ee897c-c2b4-4020-8793-79120defb9c8",
            price: 0.96836,
            price_sources: [],
            processed_ms: 1713344522329,
          },
          {
            leverage: 0,
            order_type: "FLAT",
            order_uuid: "3d97a541-b72e-451c-8b74-fd2fbead95d2",
            price: 0.97021,
            price_sources: [],
            processed_ms: 1713373339155,
          },
        ],
        position_type: "FLAT",
        position_uuid: "3712c5a9-6063-44c8-9950-2ed979978933",
        return_at_close: 1.0002070485418808,
        trade_pair: ["EURCHF", "EUR/CHF", 0.00007, 0.1, 5],
      },
      {
        average_entry_price: 0.6594800000000001,
        close_ms: 1713387962919,
        current_return: 1.0011774689721014,
        is_closed_position: true,
        miner_hotkey: "5GhCxfBcA7Ur5iiAS343xwvrYHTUfBjBi4JimiL5LhujRT9t",
        net_leverage: 0,
        open_ms: 1713272487891,
        orders: [
          {
            leverage: 0.2625,
            order_type: "LONG",
            order_uuid: "8eb0af2e-93f4-4ea2-93d7-c01617197500",
            price: 0.65989,
            price_sources: [],
            processed_ms: 1713272487889,
          },
          {
            leverage: 0.2625,
            order_type: "LONG",
            order_uuid: "2e2484ea-c293-4cd2-b2ee-fea5efb9369d",
            price: 0.65907,
            price_sources: [],
            processed_ms: 1713344883055,
          },
          {
            leverage: 0,
            order_type: "FLAT",
            order_uuid: "7fcb65a1-75b4-4b64-9549-aa4f4f69c639",
            price: 0.66096,
            price_sources: [],
            processed_ms: 1713387962919,
          },
        ],
        position_type: "FLAT",
        position_uuid: "5fe40822-b2fc-4e1e-98c0-1a9f90036e67",
        return_at_close: 1.0011406757001167,
        trade_pair: ["CADCHF", "CAD/CHF", 0.00007, 0.1, 5],
      },
      {
        average_entry_price: 59223.46761904762,
        close_ms: 1713525354836,
        current_return: 1.011810442143612,
        is_closed_position: true,
        miner_hotkey: "5GhCxfBcA7Ur5iiAS343xwvrYHTUfBjBi4JimiL5LhujRT9t",
        net_leverage: 0,
        open_ms: 1713211254196,
        orders: [
          {
            leverage: 0.025,
            order_type: "LONG",
            order_uuid: "4e4071f5-f720-4c62-886e-5d56a8c40128",
            price: 63417.63,
            price_sources: [],
            processed_ms: 1713211253436,
          },
          {
            leverage: 0.025,
            order_type: "LONG",
            order_uuid: "8151782f-ebd4-407f-b310-207a4a17a5b5",
            price: 62943.21,
            price_sources: [],
            processed_ms: 1713240101659,
          },
          {
            leverage: 0.075,
            order_type: "LONG",
            order_uuid: "36fac9c6-6850-47d4-8f64-7649f55d8c96",
            price: 63003.2,
            price_sources: [],
            processed_ms: 1713268919550,
          },
          {
            leverage: 0.03750000000000003,
            order_type: "LONG",
            order_uuid: "ab3ac4ac-17c9-4eaa-b1b8-6e7942919f57",
            price: 61826.71,
            price_sources: [],
            processed_ms: 1713283314504,
          },
          {
            leverage: -0.012500000000000011,
            order_type: "SHORT",
            order_uuid: "1cbfb0e7-845c-4f66-8bf5-6371284da204",
            price: 62626.43,
            price_sources: [],
            processed_ms: 1713286887882,
          },
          {
            leverage: -0.05000000000000002,
            order_type: "SHORT",
            order_uuid: "34d100af-e90f-4737-86e2-0a63aa335b32",
            price: 63694.3,
            price_sources: [],
            processed_ms: 1713312143108,
          },
          {
            leverage: 0.01874999999999999,
            order_type: "LONG",
            order_uuid: "25013396-b0a8-4f76-a2aa-f612c5751988",
            price: 63437.2,
            price_sources: [],
            processed_ms: 1713341068172,
          },
          {
            leverage: 0.012500000000000011,
            order_type: "LONG",
            order_uuid: "62a7f69a-2af4-4b33-905f-e66d0f32ec27",
            price: 60200,
            price_sources: [],
            processed_ms: 1713368035784,
          },
          {
            leverage: 0.012500000000000011,
            order_type: "LONG",
            order_uuid: "db4791ec-cab4-49bd-b57c-0517d1aeddbe",
            price: 60122.89,
            price_sources: [],
            processed_ms: 1713368617694,
          },
          {
            leverage: 0.05000000000000002,
            order_type: "LONG",
            order_uuid: "a01667dc-88ef-41cf-a9df-94a1136d410b",
            price: 60271.8,
            price_sources: [],
            processed_ms: 1713369757950,
          },
          {
            leverage: 0.012499999999999983,
            order_type: "LONG",
            order_uuid: "58fab945-acf9-4ee8-80e6-a365037c1863",
            price: 60320.74,
            price_sources: [],
            processed_ms: 1713370176492,
          },
          {
            leverage: -0.037500000000000006,
            order_type: "SHORT",
            order_uuid: "749190f6-bfb3-4073-ae37-3cb23e2ebb32",
            price: 61071.74,
            price_sources: [],
            processed_ms: 1713370637911,
          },
          {
            leverage: 0.024999999999999994,
            order_type: "LONG",
            order_uuid: "850dab47-b0b7-4dcf-9f17-cf8b2731a248",
            price: 61395.8,
            price_sources: [],
            processed_ms: 1713377021066,
          },
          {
            leverage: 0.07499999999999998,
            order_type: "LONG",
            order_uuid: "35292aef-47df-41d3-981e-ecec197410b8",
            price: 61275.57,
            price_sources: [],
            processed_ms: 1713398553621,
          },
          {
            leverage: -0.04999999999999999,
            order_type: "SHORT",
            order_uuid: "941f3038-e667-40f2-9ed8-ae9bf4d6287a",
            price: 62724.7,
            price_sources: [],
            processed_ms: 1713441633845,
          },
          {
            leverage: -0.125,
            order_type: "SHORT",
            order_uuid: "ff1bf8ad-135f-4e31-9b58-4d9f21568e4a",
            price: 63556.37,
            price_sources: [],
            processed_ms: 1713456027781,
          },
          {
            leverage: 0.037500000000000006,
            order_type: "LONG",
            order_uuid: "8c16a030-af94-43ba-871a-cd634f66cf5c",
            price: 61791.7,
            price_sources: [],
            processed_ms: 1713499247079,
          },
          {
            leverage: 0,
            order_type: "FLAT",
            order_uuid: "b43ea04b-a2c5-423b-87f7-be372f42fda1",
            price: 64930.06,
            price_sources: [],
            processed_ms: 1713525354836,
          },
        ],
        position_type: "FLAT",
        position_uuid: "e0496323-7d8b-4d13-81a0-97201df36616",
        return_at_close: 1.0113993941514912,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.01, 0.5],
      },
      {
        average_entry_price: 64924.6,
        close_ms: 1713532568652,
        current_return: 1.000023912353715,
        is_closed_position: true,
        miner_hotkey: "5GhCxfBcA7Ur5iiAS343xwvrYHTUfBjBi4JimiL5LhujRT9t",
        net_leverage: 0,
        open_ms: 1713525524485,
        orders: [
          {
            leverage: -0.0125,
            order_type: "SHORT",
            order_uuid: "69fc32ed-c6c1-4186-aabc-6c3c2ffc8968",
            price: 64924.6,
            price_sources: [],
            processed_ms: 1713525524482,
          },
          {
            leverage: 0,
            order_type: "FLAT",
            order_uuid: "2132968b-4f1a-45d4-bfcc-307f339ed3c2",
            price: 64800.4,
            price_sources: [],
            processed_ms: 1713532568652,
          },
        ],
        position_type: "FLAT",
        position_uuid: "42d2ee6b-49da-4fe4-ad70-ba97db03e54b",
        return_at_close: 1.0000114120548105,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.01, 0.5],
      },
      {
        average_entry_price: 64124.02,
        close_ms: 1713683121262,
        current_return: 1.0007452851158098,
        is_closed_position: true,
        miner_hotkey: "5GhCxfBcA7Ur5iiAS343xwvrYHTUfBjBi4JimiL5LhujRT9t",
        net_leverage: 0,
        open_ms: 1713556860110,
        orders: [
          {
            leverage: 0.025,
            order_type: "LONG",
            order_uuid: "5ddd29c1-245a-42cf-b0bf-9f194bea53b7",
            price: 64289.49,
            price_sources: [],
            processed_ms: 1713556860107,
          },
          {
            leverage: 0.025,
            order_type: "LONG",
            order_uuid: "6df566a3-5ed1-47bb-830f-e303842f0ab4",
            price: 63958.55,
            price_sources: [],
            processed_ms: 1713585661631,
          },
          {
            leverage: 0,
            order_type: "FLAT",
            order_uuid: "86b4e65a-a042-48c3-853b-63fcef282f3e",
            price: 65082.3,
            price_sources: [],
            processed_ms: 1713683121262,
          },
        ],
        position_type: "FLAT",
        position_uuid: "bd68d81d-f7bc-4468-9121-416776117e7f",
        return_at_close: 1.000695247851554,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.01, 0.5],
      },
      {
        average_entry_price: 2934.0199999999995,
        close_ms: 1713683999170,
        current_return: 1.0020175936528977,
        is_closed_position: true,
        miner_hotkey: "5GhCxfBcA7Ur5iiAS343xwvrYHTUfBjBi4JimiL5LhujRT9t",
        net_leverage: 0,
        open_ms: 1713355435995,
        orders: [
          {
            leverage: 0.018750000000000003,
            order_type: "LONG",
            order_uuid: "edaf9a28-2d22-40d6-bb29-c5ebb491df4f",
            price: 3047.69,
            price_sources: [],
            processed_ms: 1713355435992,
          },
          {
            leverage: 0.025,
            order_type: "LONG",
            order_uuid: "aed3c346-afd0-46af-8a3c-101d91240b8b",
            price: 3016.61,
            price_sources: [],
            processed_ms: 1713377022618,
          },
          {
            leverage: -0.018750000000000003,
            order_type: "SHORT",
            order_uuid: "3fc82f73-1d68-4f79-87f0-5aab3c66fa03",
            price: 3157.81,
            price_sources: [],
            processed_ms: 1713657661381,
          },
          {
            leverage: 0,
            order_type: "FLAT",
            order_uuid: "2e131c86-33cb-46b6-a2d2-4f56d907d924",
            price: 3179.98,
            price_sources: [],
            processed_ms: 1713683999170,
          },
        ],
        position_type: "FLAT",
        position_uuid: "fcd6ad9c-2a56-4569-aee1-291fdc8261fc",
        return_at_close: 1.0019737553831753,
        trade_pair: ["ETHUSD", "ETH/USD", 0.003, 0.01, 0.5],
      },
      {
        average_entry_price: 0.8828200000000002,
        close_ms: 1713834020919,
        current_return: 1.0001393262499716,
        is_closed_position: true,
        miner_hotkey: "5GhCxfBcA7Ur5iiAS343xwvrYHTUfBjBi4JimiL5LhujRT9t",
        net_leverage: 0,
        open_ms: 1713488423950,
        orders: [
          {
            leverage: 0.07500000000000001,
            order_type: "LONG",
            order_uuid: "21e3ff0e-44df-4031-b437-dcd22f159011",
            price: 0.88282,
            price_sources: [],
            processed_ms: 1713488423946,
          },
          {
            leverage: 0,
            order_type: "FLAT",
            order_uuid: "d2241e62-c316-4f3d-9f52-96f8ed193cac",
            price: 0.88446,
            price_sources: [],
            processed_ms: 1713834020919,
          },
        ],
        position_type: "FLAT",
        position_uuid: "2dc5d8f1-8de7-4691-a314-bf1bef7db555",
        return_at_close: 1.0001340755185089,
        trade_pair: ["AUDCAD", "AUD/CAD", 0.00007, 0.1, 5],
      },
      {
        average_entry_price: 0.7901861042002737,
        close_ms: 1713891617349,
        current_return: 1.0029915418501414,
        is_closed_position: true,
        miner_hotkey: "5GhCxfBcA7Ur5iiAS343xwvrYHTUfBjBi4JimiL5LhujRT9t",
        net_leverage: 0,
        open_ms: 1713734446259,
        orders: [
          {
            leverage: 0.8624999999999999,
            order_type: "LONG",
            order_uuid: "c0e88844-f8f2-44f5-8ec0-efc8f20adf58",
            price: 0.8083,
            price_sources: [],
            processed_ms: 1713734444026,
          },
          {
            leverage: -0.7499999999999999,
            order_type: "SHORT",
            order_uuid: "38978c2d-f916-422e-a1da-53cf2b5c8cce",
            price: 0.811017084369959,
            price_sources: [],
            processed_ms: 1713765885973,
          },
          {
            leverage: 0,
            order_type: "FLAT",
            order_uuid: "3bba7597-344b-42fd-8392-34eac667284a",
            price: 0.81168,
            price_sources: [],
            processed_ms: 1713891617349,
          },
        ],
        position_type: "FLAT",
        position_uuid: "dae14b01-b0eb-4003-b4a7-5964bd01f33f",
        return_at_close: 1.0029309862358022,
        trade_pair: ["NZDCAD", "NZD/CAD", 0.00007, 0.1, 5],
      },
      {
        average_entry_price: 1.3678800000000002,
        close_ms: 1713963614998,
        current_return: 1.0005387569169673,
        is_closed_position: true,
        miner_hotkey: "5GhCxfBcA7Ur5iiAS343xwvrYHTUfBjBi4JimiL5LhujRT9t",
        net_leverage: 0,
        open_ms: 1713808819450,
        orders: [
          {
            leverage: 0.11250000000000002,
            order_type: "LONG",
            order_uuid: "755a69c4-9b1b-4511-ab6d-a6d3b00c3d39",
            price: 1.36982,
            price_sources: [],
            processed_ms: 1713808819446,
          },
          {
            leverage: 0.11250000000000002,
            order_type: "LONG",
            order_uuid: "286b3b52-6507-4532-9ce2-8fff2aca23da",
            price: 1.36594,
            price_sources: [],
            processed_ms: 1713895217881,
          },
          {
            leverage: 0,
            order_type: "FLAT",
            order_uuid: "403868cb-e6cc-433c-b582-e3785b8e30db",
            price: 1.37116,
            price_sources: [],
            processed_ms: 1713963614998,
          },
        ],
        position_type: "FLAT",
        position_uuid: "e9935d08-54fa-4c69-b13a-41a5cbbbf202",
        return_at_close: 1.0005229984315458,
        trade_pair: ["USDCAD", "USD/CAD", 0.00007, 0.1, 5],
      },
      {
        average_entry_price: 0.6515,
        close_ms: 1713978008655,
        current_return: 1.0002613200306985,
        is_closed_position: true,
        miner_hotkey: "5GhCxfBcA7Ur5iiAS343xwvrYHTUfBjBi4JimiL5LhujRT9t",
        net_leverage: 0,
        open_ms: 1713934818269,
        orders: [
          {
            leverage: -0.07500000000000001,
            order_type: "SHORT",
            order_uuid: "a87726a0-2f02-466b-9119-ee8238e6a199",
            price: 0.6515,
            price_sources: [],
            processed_ms: 1713934818263,
          },
          {
            leverage: 0,
            order_type: "FLAT",
            order_uuid: "e0709c80-1664-4e50-a015-e3409257f6f5",
            price: 0.64923,
            price_sources: [],
            processed_ms: 1713978008655,
          },
        ],
        position_type: "FLAT",
        position_uuid: "271ba0a5-95dc-42fe-9d67-b7d90230ca7a",
        return_at_close: 1.0002560686587683,
        trade_pair: ["AUDUSD", "AUD/USD", 0.00007, 0.1, 5],
      },
      {
        average_entry_price: 63482.00000000001,
        close_ms: 1714044608071,
        current_return: 1.0000724614851455,
        is_closed_position: true,
        miner_hotkey: "5GhCxfBcA7Ur5iiAS343xwvrYHTUfBjBi4JimiL5LhujRT9t",
        net_leverage: 0,
        open_ms: 1714041007565,
        orders: [
          {
            leverage: 0.0125,
            order_type: "LONG",
            order_uuid: "8cdbb6f3-eb9d-493a-919e-9ca3143bc8f7",
            price: 63482,
            price_sources: [],
            processed_ms: 1714041007561,
          },
          {
            leverage: 0,
            order_type: "FLAT",
            order_uuid: "ecb46b57-3456-4f03-ad1b-7ea0554d5a9e",
            price: 63850,
            price_sources: [],
            processed_ms: 1714044608071,
          },
        ],
        position_type: "FLAT",
        position_uuid: "e62d708d-fbe4-402c-9046-f78654b7a358",
        return_at_close: 1.000059960579377,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.01, 0.5],
      },
      {
        average_entry_price: 1.24765,
        close_ms: 1714143611958,
        current_return: 1.0005166475315728,
        is_closed_position: true,
        miner_hotkey: "5GhCxfBcA7Ur5iiAS343xwvrYHTUfBjBi4JimiL5LhujRT9t",
        net_leverage: 0,
        open_ms: 1713895218076,
        orders: [
          {
            leverage: -0.22500000000000003,
            order_type: "SHORT",
            order_uuid: "897fbb0b-097b-4fb2-8f37-e8af22d85203",
            price: 1.24553,
            price_sources: [],
            processed_ms: 1713895218073,
          },
          {
            leverage: -0.22500000000000003,
            order_type: "SHORT",
            order_uuid: "1ac8c479-988d-4c53-8f69-d9a2f65b244c",
            price: 1.24977,
            price_sources: [],
            processed_ms: 1714028411856,
          },
          {
            leverage: 0,
            order_type: "FLAT",
            order_uuid: "ab5aec97-2f47-448c-a716-22624b5914c6",
            price: 1.24622,
            price_sources: [],
            processed_ms: 1714143611958,
          },
        ],
        position_type: "FLAT",
        position_uuid: "d7c2e735-2296-4458-9fba-e8db68131a6c",
        return_at_close: 1.0004851312571756,
        trade_pair: ["GBPUSD", "GBP/USD", 0.00007, 0.1, 5],
      },
      {
        average_entry_price: 3150.8250000000003,
        close_ms: 1714402820987,
        current_return: 1.0001858051635937,
        is_closed_position: true,
        miner_hotkey: "5GhCxfBcA7Ur5iiAS343xwvrYHTUfBjBi4JimiL5LhujRT9t",
        net_leverage: 0,
        open_ms: 1714380305957,
        orders: [
          {
            leverage: 0.00625,
            order_type: "LONG",
            order_uuid: "9451f3b9-8dc9-46d0-8342-cfb19d60ba48",
            price: 3170.66,
            price_sources: [],
            processed_ms: 1714380305953,
          },
          {
            leverage: 0.00625,
            order_type: "LONG",
            order_uuid: "7a5a3273-6784-4e34-ae5d-041c5f39e766",
            price: 3160.76,
            price_sources: [],
            processed_ms: 1714391106811,
          },
          {
            leverage: 0.006249999999999999,
            order_type: "LONG",
            order_uuid: "87c6cf7f-ba5c-4932-9206-68e6ba6048ec",
            price: 3147.9,
            price_sources: [],
            processed_ms: 1714397429548,
          },
          {
            leverage: 0.006250000000000002,
            order_type: "LONG",
            order_uuid: "689b8a6b-fbc9-4a0b-a9cc-7eb0554ad5ef",
            price: 3123.98,
            price_sources: [],
            processed_ms: 1714398316774,
          },
          {
            leverage: 0,
            order_type: "FLAT",
            order_uuid: "74e88c1c-daa7-4eeb-934c-88ee1e07c2f9",
            price: 3174.39,
            price_sources: [],
            processed_ms: 1714402820987,
          },
        ],
        position_type: "FLAT",
        position_uuid: "d96ac2f9-70de-43db-a304-7bda9b186b74",
        return_at_close: 1.0001608005184646,
        trade_pair: ["ETHUSD", "ETH/USD", 0.003, 0.01, 0.5],
      },
      {
        average_entry_price: 62602.885,
        close_ms: 1714435214606,
        current_return: 1.0009989839208904,
        is_closed_position: true,
        miner_hotkey: "5GhCxfBcA7Ur5iiAS343xwvrYHTUfBjBi4JimiL5LhujRT9t",
        net_leverage: 0,
        open_ms: 1714219208587,
        orders: [
          {
            leverage: 0.025,
            order_type: "LONG",
            order_uuid: "a85cd0f4-0d2b-4008-91ed-49c7ad4e732b",
            price: 62810.07,
            price_sources: [],
            processed_ms: 1714219208580,
          },
          {
            leverage: 0.025,
            order_type: "LONG",
            order_uuid: "acde25de-651e-425c-ae47-d67341019b71",
            price: 62395.7,
            price_sources: [],
            processed_ms: 1714377614146,
          },
          {
            leverage: 0,
            order_type: "FLAT",
            order_uuid: "8a89cd97-a9d7-4b5b-9522-82dbdd3a05d6",
            price: 63857.81,
            price_sources: [],
            processed_ms: 1714435214606,
          },
        ],
        position_type: "FLAT",
        position_uuid: "77f8b657-c7d1-473e-9cea-50602c52795c",
        return_at_close: 1.0009489339716944,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.01, 0.5],
      },
      {
        average_entry_price: 2957.7211111111124,
        close_ms: 1714516211882,
        current_return: 1.0010521457290555,
        is_closed_position: true,
        miner_hotkey: "5GhCxfBcA7Ur5iiAS343xwvrYHTUfBjBi4JimiL5LhujRT9t",
        net_leverage: 0,
        open_ms: 1714470007077,
        orders: [
          {
            leverage: 0.00625,
            order_type: "LONG",
            order_uuid: "2e29cb97-d056-4c85-860e-db9b27f566c8",
            price: 3046.75,
            price_sources: [],
            processed_ms: 1714470007072,
          },
          {
            leverage: 0.00625,
            order_type: "LONG",
            order_uuid: "47318c55-ab2c-4cbb-b48a-74eb2827e849",
            price: 3044.43,
            price_sources: [],
            processed_ms: 1714472103898,
          },
          {
            leverage: 0.006250000000000002,
            order_type: "LONG",
            order_uuid: "98617d3c-6885-425b-98ff-27032d2bdc30",
            price: 3037.15,
            price_sources: [],
            processed_ms: 1714473306424,
          },
          {
            leverage: 0.006249999999999999,
            order_type: "LONG",
            order_uuid: "adb085e0-d86b-41c3-9fce-a38ac146d8f3",
            price: 3028.45,
            price_sources: [],
            processed_ms: 1714475413712,
          },
          {
            leverage: 0.006249999999999999,
            order_type: "LONG",
            order_uuid: "a2f69d95-6702-4286-a58c-5637edf26470",
            price: 3017.01,
            price_sources: [],
            processed_ms: 1714476606553,
          },
          {
            leverage: 0.012499999999999997,
            order_type: "LONG",
            order_uuid: "61723020-ba44-4e13-aeee-9f9c42719d5a",
            price: 3011.06,
            price_sources: [],
            processed_ms: 1714477206233,
          },
          {
            leverage: 0.0062500000000000056,
            order_type: "LONG",
            order_uuid: "0c37f73d-33bf-4122-9ffd-90d3d7f32a77",
            price: 3007.36,
            price_sources: [],
            processed_ms: 1714478420967,
          },
          {
            leverage: 0.012499999999999997,
            order_type: "LONG",
            order_uuid: "1f2a6c4f-e5e4-4745-a1fe-f14fe63cfd2c",
            price: 2992.36,
            price_sources: [],
            processed_ms: 1714479012373,
          },
          {
            leverage: 0.0062500000000000056,
            order_type: "LONG",
            order_uuid: "2dad8ce3-0f8a-484b-8cf8-d6a4d2db2f30",
            price: 2997.6,
            price_sources: [],
            processed_ms: 1714479307348,
          },
          {
            leverage: 0.0062500000000000056,
            order_type: "LONG",
            order_uuid: "b7853d79-13ea-49d7-803e-30877c51c88b",
            price: 2987,
            price_sources: [],
            processed_ms: 1714485307784,
          },
          {
            leverage: 0.006249999999999978,
            order_type: "LONG",
            order_uuid: "0d8fe0d0-b086-486d-8895-5e6cbfbe87d8",
            price: 2988.56,
            price_sources: [],
            processed_ms: 1714485339654,
          },
          {
            leverage: 0.0062500000000000056,
            order_type: "LONG",
            order_uuid: "183a3ca5-9e20-4a87-b165-151528bb1887",
            price: 2990.92,
            price_sources: [],
            processed_ms: 1714485614572,
          },
          {
            leverage: 0.018750000000000003,
            order_type: "LONG",
            order_uuid: "cc39b451-0d13-4354-8bfa-966a85b32984",
            price: 2986.34,
            price_sources: [],
            processed_ms: 1714486507611,
          },
          {
            leverage: -0.03125,
            order_type: "SHORT",
            order_uuid: "1284d3de-f555-45bb-8832-370f89c9362e",
            price: 3012.73,
            price_sources: [],
            processed_ms: 1714488309718,
          },
          {
            leverage: -0.04375,
            order_type: "SHORT",
            order_uuid: "8c97f5f0-1a95-4cff-84b9-00f4f87d06eb",
            price: 3030.6,
            price_sources: [],
            processed_ms: 1714488609122,
          },
          {
            leverage: 0.006249999999999999,
            order_type: "LONG",
            order_uuid: "30ef587d-4b32-4e4c-abf1-e4a65c16187c",
            price: 2963.87,
            price_sources: [],
            processed_ms: 1714494611233,
          },
          {
            leverage: 0.006249999999999999,
            order_type: "LONG",
            order_uuid: "dcfe8eed-bb7d-463c-8850-d3bc47a32f92",
            price: 2951.43,
            price_sources: [],
            processed_ms: 1714504513082,
          },
          {
            leverage: 0.0062500000000000056,
            order_type: "LONG",
            order_uuid: "3a1d683c-702d-4e24-9265-2a79b1b6a8ca",
            price: 2936.98,
            price_sources: [],
            processed_ms: 1714505412835,
          },
          {
            leverage: 0.006249999999999999,
            order_type: "LONG",
            order_uuid: "c15c25b1-a8de-4211-9d67-270fb4339ca9",
            price: 2933.97,
            price_sources: [],
            processed_ms: 1714507216443,
          },
          {
            leverage: 0,
            order_type: "FLAT",
            order_uuid: "a73ca019-3d9d-4be7-8729-0712363478cf",
            price: 3014.71,
            price_sources: [],
            processed_ms: 1714516211882,
          },
        ],
        position_type: "FLAT",
        position_uuid: "84835ca4-7001-4df9-9cd1-a0741cdede8a",
        return_at_close: 1.0009207576349286,
        trade_pair: ["ETHUSD", "ETH/USD", 0.003, 0.01, 0.5],
      },
      {
        average_entry_price: 0.6474424999999999,
        close_ms: 1714559414149,
        current_return: 1.0001682288374674,
        is_closed_position: true,
        miner_hotkey: "5GhCxfBcA7Ur5iiAS343xwvrYHTUfBjBi4JimiL5LhujRT9t",
        net_leverage: 0,
        open_ms: 1714496414893,
        orders: [
          {
            leverage: 0.037500000000000006,
            order_type: "LONG",
            order_uuid: "87bf7538-51de-4e62-8910-3949d211d8d8",
            price: 0.64867,
            price_sources: [],
            processed_ms: 1714496414887,
          },
          {
            leverage: 0.037500000000000006,
            order_type: "LONG",
            order_uuid: "ad9c379e-5f57-4ba2-ba81-6982d14254a1",
            price: 0.6477,
            price_sources: [],
            processed_ms: 1714507207040,
          },
          {
            leverage: 0.03749999999999998,
            order_type: "LONG",
            order_uuid: "696ca94f-a24a-4523-b589-4d3d5e85d700",
            price: 0.64684,
            price_sources: [],
            processed_ms: 1714523410851,
          },
          {
            leverage: 0.03750000000000003,
            order_type: "LONG",
            order_uuid: "6a217c94-32ed-481b-a347-453502819b71",
            price: 0.64656,
            price_sources: [],
            processed_ms: 1714525209384,
          },
          {
            leverage: 0,
            order_type: "FLAT",
            order_uuid: "96a8501d-41b0-4eb9-90dd-4006562fcce7",
            price: 0.64817,
            price_sources: [],
            processed_ms: 1714559414149,
          },
        ],
        position_type: "FLAT",
        position_uuid: "e58b6725-a647-4f42-8b5c-ae3b5bb4c841",
        return_at_close: 1.0001577270710647,
        trade_pair: ["AUDUSD", "AUD/USD", 0.00007, 0.1, 5],
      },
      {
        average_entry_price: 164.51649999999998,
        close_ms: 1714669205824,
        current_return: 1.0001287164501902,
        is_closed_position: true,
        miner_hotkey: "5GhCxfBcA7Ur5iiAS343xwvrYHTUfBjBi4JimiL5LhujRT9t",
        net_leverage: 0,
        open_ms: 1714662908669,
        orders: [
          {
            leverage: 0.037500000000000006,
            order_type: "LONG",
            order_uuid: "6c5e2130-ccd0-458e-827b-2847ecb799c9",
            price: 164.606,
            price_sources: [],
            processed_ms: 1714662907634,
          },
          {
            leverage: 0.037500000000000006,
            order_type: "LONG",
            order_uuid: "c9465ae5-6bd2-4e37-90f0-3fa9d08bfb48",
            price: 164.427,
            price_sources: [],
            processed_ms: 1714667411663,
          },
          {
            leverage: 0,
            order_type: "FLAT",
            order_uuid: "480d6e9f-6f4e-402d-a56b-6865c0d52711",
            price: 164.799,
            price_sources: [],
            processed_ms: 1714669205824,
          },
        ],
        position_type: "FLAT",
        position_uuid: "afc4fbea-4e99-44f2-b0d2-0debcd14febd",
        return_at_close: 1.000123465774429,
        trade_pair: ["EURJPY", "EUR/JPY", 0.00007, 0.1, 5],
      },
      {
        average_entry_price: 39937.05499999993,
        close_ms: 1714672815858,
        current_return: 1.0077831391842016,
        is_closed_position: true,
        miner_hotkey: "5GhCxfBcA7Ur5iiAS343xwvrYHTUfBjBi4JimiL5LhujRT9t",
        net_leverage: 0,
        open_ms: 1714467616053,
        orders: [
          {
            leverage: 0.018750000000000003,
            order_type: "LONG",
            order_uuid: "9df02ebb-5f66-49a3-ad3f-026feb51583e",
            price: 61806.44,
            price_sources: [],
            processed_ms: 1714467616048,
          },
          {
            leverage: 0.012499999999999997,
            order_type: "LONG",
            order_uuid: "bf9aef60-1560-4d1e-a789-f457207bae74",
            price: 61100,
            price_sources: [],
            processed_ms: 1714479011614,
          },
          {
            leverage: 0.02500000000000001,
            order_type: "LONG",
            order_uuid: "66cedc72-2fef-4a7c-af67-b40d738e2ed6",
            price: 60733.9,
            price_sources: [],
            processed_ms: 1714486807664,
          },
          {
            leverage: -0.037500000000000006,
            order_type: "SHORT",
            order_uuid: "9709646f-cd31-4b0d-8b2b-9c5c85e01585",
            price: 61159.75,
            price_sources: [],
            processed_ms: 1714488005422,
          },
          {
            leverage: 0.012499999999999997,
            order_type: "LONG",
            order_uuid: "45126570-d5d6-43af-8c0b-d175ae4ff6b7",
            price: 60042.6,
            price_sources: [],
            processed_ms: 1714504506674,
          },
          {
            leverage: 0.012500000000000004,
            order_type: "LONG",
            order_uuid: "838cca5e-df69-4cf1-ac6d-f08e5d51dcec",
            price: 59924.95,
            price_sources: [],
            processed_ms: 1714505413224,
          },
          {
            leverage: -0.025,
            order_type: "SHORT",
            order_uuid: "87754983-c3a1-4c56-9f97-c1ea0e67e0a4",
            price: 60250.23,
            price_sources: [],
            processed_ms: 1714512609700,
          },
          {
            leverage: 0.018750000000000003,
            order_type: "LONG",
            order_uuid: "4d28574f-5161-4c29-affb-62a76f69eb58",
            price: 60413.84,
            price_sources: [],
            processed_ms: 1714519010206,
          },
          {
            leverage: 0.037500000000000006,
            order_type: "LONG",
            order_uuid: "8879d11a-496a-4be1-ab57-b7a507857e9d",
            price: 59859.23,
            price_sources: [],
            processed_ms: 1714531335722,
          },
          {
            leverage: 0.11249999999999999,
            order_type: "LONG",
            order_uuid: "727a5d28-dfc3-4cf0-b936-fc0a415f4ac1",
            price: 57480,
            price_sources: [],
            processed_ms: 1714550420885,
          },
          {
            leverage: 0.012500000000000011,
            order_type: "LONG",
            order_uuid: "553591dd-c645-46fb-b57e-ee9916303737",
            price: 56955.33,
            price_sources: [],
            processed_ms: 1714552504718,
          },
          {
            leverage: 0.01874999999999996,
            order_type: "LONG",
            order_uuid: "09935aa3-4853-4ee1-adf2-a9ff60bbc523",
            price: 57047,
            price_sources: [],
            processed_ms: 1714554003632,
          },
          {
            leverage: -0.012499999999999983,
            order_type: "SHORT",
            order_uuid: "c4e43f10-21aa-4807-b9da-742e39dbd851",
            price: 57248.26,
            price_sources: [],
            processed_ms: 1714554308893,
          },
          {
            leverage: 0.018750000000000044,
            order_type: "LONG",
            order_uuid: "0990cb91-7b67-4b17-8bab-31fd117e8d52",
            price: 57052.14,
            price_sources: [],
            processed_ms: 1714557611379,
          },
          {
            leverage: 0.01874999999999999,
            order_type: "LONG",
            order_uuid: "cf9e8cda-4d50-415e-8dde-65756e796c4a",
            price: 57269,
            price_sources: [],
            processed_ms: 1714558810254,
          },
          {
            leverage: 0.31875,
            order_type: "LONG",
            order_uuid: "4d83e26d-371a-4e7f-8203-f27a9da038ee",
            price: 56772.2,
            price_sources: [],
            processed_ms: 1714579210726,
          },
          {
            leverage: 0.025000000000000022,
            order_type: "LONG",
            order_uuid: "ab856aba-c49a-4b6f-80d0-c780988be09e",
            price: 57469.89,
            price_sources: [],
            processed_ms: 1714586422797,
          },
          {
            leverage: -0.4125,
            order_type: "SHORT",
            order_uuid: "796af9cc-81bb-4f26-a54d-a220aec39c05",
            price: 57903,
            price_sources: [],
            processed_ms: 1714586733776,
          },
          {
            leverage: -0.15000000000000002,
            order_type: "SHORT",
            order_uuid: "4a393c80-35f9-4d3c-a0de-00442de078a6",
            price: 58958.4,
            price_sources: [],
            processed_ms: 1714590009435,
          },
          {
            leverage: 0,
            order_type: "FLAT",
            order_uuid: "e2aa984d-7a01-4577-b85c-b35b0498850d",
            price: 59178.98,
            price_sources: [],
            processed_ms: 1714672815858,
          },
        ],
        position_type: "FLAT",
        position_uuid: "24274be4-5487-45f2-8639-839719fdfa6b",
        return_at_close: 1.0071154828544922,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.01, 0.5],
      },
      {
        average_entry_price: 111.975,
        close_ms: 1714681812256,
        current_return: 1.0001105157401207,
        is_closed_position: true,
        miner_hotkey: "5GhCxfBcA7Ur5iiAS343xwvrYHTUfBjBi4JimiL5LhujRT9t",
        net_leverage: 0,
        open_ms: 1714676422438,
        orders: [
          {
            leverage: 0.037500000000000006,
            order_type: "LONG",
            order_uuid: "e88ca7a8-fa37-4ee7-8c36-85134c2ec119",
            price: 111.975,
            price_sources: [],
            processed_ms: 1714676422255,
          },
          {
            leverage: 0,
            order_type: "FLAT",
            order_uuid: "d934afe0-5a50-4f31-a97e-12f890c2a522",
            price: 112.305,
            price_sources: [],
            processed_ms: 1714681812256,
          },
        ],
        position_type: "FLAT",
        position_uuid: "94805813-bb30-4566-a502-2a27d47cc4ec",
        return_at_close: 1.000107890450017,
        trade_pair: ["CADJPY", "CAD/JPY", 0.00007, 0.1, 5],
      },
      {
        average_entry_price: 191.962,
        close_ms: 1714682704762,
        current_return: 1.0001396760817245,
        is_closed_position: true,
        miner_hotkey: "5GhCxfBcA7Ur5iiAS343xwvrYHTUfBjBi4JimiL5LhujRT9t",
        net_leverage: 0,
        open_ms: 1714673710393,
        orders: [
          {
            leverage: 0.037500000000000006,
            order_type: "LONG",
            order_uuid: "9c724096-5f16-4662-a7df-7d20817d203e",
            price: 191.962,
            price_sources: [],
            processed_ms: 1714673710044,
          },
          {
            leverage: 0,
            order_type: "FLAT",
            order_uuid: "e5b3e6d9-7ad6-423a-90ec-dd2e1209e4e7",
            price: 192.677,
            price_sources: [],
            processed_ms: 1714682704762,
          },
        ],
        position_type: "FLAT",
        position_uuid: "19e3dac8-95c9-4dc8-a84b-06f29030edf5",
        return_at_close: 1.0001370507150746,
        trade_pair: ["GBPJPY", "GBP/JPY", 0.00007, 0.1, 5],
      },
      {
        average_entry_price: 168.1027242933224,
        close_ms: 1714726811290,
        current_return: 1.0002824102081798,
        is_closed_position: true,
        miner_hotkey: "5GhCxfBcA7Ur5iiAS343xwvrYHTUfBjBi4JimiL5LhujRT9t",
        net_leverage: 0,
        open_ms: 1714712404513,
        orders: [
          {
            leverage: 0.07500000000000001,
            order_type: "LONG",
            order_uuid: "9be77e90-1ccf-46ce-ac6a-d923f963d705",
            price: 168.1027242933224,
            price_sources: [],
            processed_ms: 1714712404226,
          },
          {
            leverage: 0,
            order_type: "FLAT",
            order_uuid: "63af00c3-8ac0-4103-beff-e742c82d7fab",
            price: 168.7357099648324,
            price_sources: [],
            processed_ms: 1714726811290,
          },
        ],
        position_type: "FLAT",
        position_uuid: "2fc1946a-7260-4de5-9170-17a3f37b3152",
        return_at_close: 1.0002771587255261,
        trade_pair: ["CHFJPY", "CHF/JPY", 0.00007, 0.1, 5],
      },
      {
        average_entry_price: 3139.3920000000003,
        close_ms: 1714843805712,
        current_return: 1.0001296795290093,
        is_closed_position: true,
        miner_hotkey: "5GhCxfBcA7Ur5iiAS343xwvrYHTUfBjBi4JimiL5LhujRT9t",
        net_leverage: 0,
        open_ms: 1714768209430,
        orders: [
          {
            leverage: -0.008749999999999999,
            order_type: "SHORT",
            order_uuid: "a9958031-1d08-442e-8a01-d2f07bf6500d",
            price: 3110.89,
            price_sources: [],
            processed_ms: 1714768209189,
          },
          {
            leverage: -0.004375000000000002,
            order_type: "SHORT",
            order_uuid: "c3ac10f8-590b-47b7-b4d0-c292625b92ca",
            price: 3117.57,
            price_sources: [],
            processed_ms: 1714769103923,
          },
          {
            leverage: -0.004374999999999997,
            order_type: "SHORT",
            order_uuid: "51a7bfc1-3944-4291-8782-2a8e0d9a6520",
            price: 3123.34,
            price_sources: [],
            processed_ms: 1714783504364,
          },
          {
            leverage: -0.004375,
            order_type: "SHORT",
            order_uuid: "f5aa06ff-b4f4-4a6e-b17d-a786741e7ab6",
            price: 3127.19,
            price_sources: [],
            processed_ms: 1714786207820,
          },
          {
            leverage: 0.008749999999999999,
            order_type: "LONG",
            order_uuid: "e11a326b-efb7-4a19-971b-1d3da1b83166",
            price: 3096.71,
            price_sources: [],
            processed_ms: 1714809608475,
          },
          {
            leverage: -0.004374999999999999,
            order_type: "SHORT",
            order_uuid: "da9e9574-0485-4e7b-8f6d-5df79f1f76bb",
            price: 3131.1,
            price_sources: [],
            processed_ms: 1714819504638,
          },
          {
            leverage: -0.004375,
            order_type: "SHORT",
            order_uuid: "16d9056b-83a2-46c6-ad3a-60774d68347e",
            price: 3169.4,
            price_sources: [],
            processed_ms: 1714820410487,
          },
          {
            leverage: 0,
            order_type: "FLAT",
            order_uuid: "ceaabeb5-1773-4e71-aab5-c8278279984a",
            price: 3120.95,
            price_sources: [],
            processed_ms: 1714843805712,
          },
        ],
        position_type: "FLAT",
        position_uuid: "b6bedbec-8f52-4292-9297-11ec59c6f8bb",
        return_at_close: 1.0000990505575738,
        trade_pair: ["ETHUSD", "ETH/USD", 0.003, 0.01, 0.5],
      },
      {
        average_entry_price: 63789.88999999999,
        close_ms: 1714845607373,
        current_return: 1.0000900194677322,
        is_closed_position: true,
        miner_hotkey: "5GhCxfBcA7Ur5iiAS343xwvrYHTUfBjBi4JimiL5LhujRT9t",
        net_leverage: 0,
        open_ms: 1714799708943,
        orders: [
          {
            leverage: -0.008749999999999999,
            order_type: "SHORT",
            order_uuid: "e1b72269-0818-4388-b042-19cc9147c21e",
            price: 63186.61,
            price_sources: [],
            processed_ms: 1714799708674,
          },
          {
            leverage: -0.008749999999999999,
            order_type: "SHORT",
            order_uuid: "6164c25e-ec4c-4e94-8d07-5cf6048b7bbb",
            price: 64393.17,
            price_sources: [],
            processed_ms: 1714820410017,
          },
          {
            leverage: 0,
            order_type: "FLAT",
            order_uuid: "c80c7389-61b9-41bd-9f5d-c9523ea8a9b6",
            price: 63464.86,
            price_sources: [],
            processed_ms: 1714845607373,
          },
        ],
        position_type: "FLAT",
        position_uuid: "0fcf807c-5487-4251-8843-fa786d0f1fd7",
        return_at_close: 1.0000725178923915,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.01, 0.5],
      },
      {
        average_entry_price: 164.13,
        close_ms: 1714943476842,
        current_return: 1.0000856790349113,
        is_closed_position: true,
        miner_hotkey: "5GhCxfBcA7Ur5iiAS343xwvrYHTUfBjBi4JimiL5LhujRT9t",
        net_leverage: 0,
        open_ms: 1714712409270,
        orders: [
          {
            leverage: 0.037500000000000006,
            order_type: "LONG",
            order_uuid: "b525f2b6-c6d3-45f6-85f9-e04ae87562d7",
            price: 164.13,
            price_sources: [],
            processed_ms: 1714712409098,
          },
          {
            leverage: 0,
            order_type: "FLAT",
            order_uuid: "16b02c53-a9a0-4262-9da3-48bb43ecf2b5",
            price: 164.505,
            price_sources: [],
            processed_ms: 1714943476842,
          },
        ],
        position_type: "FLAT",
        position_uuid: "dfa896d1-622e-48be-a087-8b4890b39939",
        return_at_close: 1.0000830538100038,
        trade_pair: ["EURJPY", "EUR/JPY", 0.00007, 0.1, 5],
      },
      {
        average_entry_price: 111.68,
        close_ms: 1714957214431,
        current_return: 1.0002813843123208,
        is_closed_position: true,
        miner_hotkey: "5GhCxfBcA7Ur5iiAS343xwvrYHTUfBjBi4JimiL5LhujRT9t",
        net_leverage: 0,
        open_ms: 1714741223674,
        orders: [
          {
            leverage: 0.07500000000000001,
            order_type: "LONG",
            order_uuid: "69e986a5-e7c4-4f0f-a5cf-6eb7b642a057",
            price: 111.68,
            price_sources: [],
            processed_ms: 1714741223488,
          },
          {
            leverage: 0,
            order_type: "FLAT",
            order_uuid: "2ee3a539-b6e7-4b1a-8a4d-00f9e6009727",
            price: 112.099,
            price_sources: [],
            processed_ms: 1714957214431,
          },
        ],
        position_type: "FLAT",
        position_uuid: "7324a992-47dc-49f1-b867-7a9551f4d3d6",
        return_at_close: 1.0002761328350533,
        trade_pair: ["CADJPY", "CAD/JPY", 0.00007, 0.1, 5],
      },
      {
        average_entry_price: 0.66096,
        close_ms: 1715014805767,
        current_return: 1.00036424291939,
        is_closed_position: true,
        miner_hotkey: "5GhCxfBcA7Ur5iiAS343xwvrYHTUfBjBi4JimiL5LhujRT9t",
        net_leverage: 0,
        open_ms: 1714946437514,
        orders: [
          {
            leverage: 0.11250000000000002,
            order_type: "LONG",
            order_uuid: "f3ac12b2-1da1-4a5f-9f05-d293c0669fe2",
            price: 0.66096,
            price_sources: [],
            processed_ms: 1714946437289,
          },
          {
            leverage: 0,
            order_type: "FLAT",
            order_uuid: "bcc1610c-1fc2-4f2a-8a5d-83d807f4e3ac",
            price: 0.6631,
            price_sources: [],
            processed_ms: 1715014805767,
          },
        ],
        position_type: "FLAT",
        position_uuid: "a1a062e2-e269-4c17-9615-d2500a16a391",
        return_at_close: 1.000356365050977,
        trade_pair: ["CADCHF", "CAD/CHF", 0.00007, 0.1, 5],
      },
      {
        average_entry_price: 0.822648579824884,
        close_ms: 1715014816087,
        current_return: 1.0001707475509523,
        is_closed_position: true,
        miner_hotkey: "5GhCxfBcA7Ur5iiAS343xwvrYHTUfBjBi4JimiL5LhujRT9t",
        net_leverage: 0,
        open_ms: 1714741219902,
        orders: [
          {
            leverage: -0.11250000000000002,
            order_type: "SHORT",
            order_uuid: "3dd5f719-6a77-4d46-84e9-f88877372c85",
            price: 0.822648579824884,
            price_sources: [],
            processed_ms: 1714741219704,
          },
          {
            leverage: 0,
            order_type: "FLAT",
            order_uuid: "98a06d3b-5048-4fad-b68d-a57e89443bdb",
            price: 0.8214,
            price_sources: [],
            processed_ms: 1715014816087,
          },
        ],
        position_type: "FLAT",
        position_uuid: "f7f43c1c-b2c8-4eee-af13-6179d27788f4",
        return_at_close: 1.0001628712063153,
        trade_pair: ["NZDCAD", "NZD/CAD", 0.00007, 0.1, 5],
      },
      {
        average_entry_price: 0.9119096991419553,
        close_ms: 1715072437655,
        current_return: 1.0015356167198177,
        is_closed_position: true,
        miner_hotkey: "5GhCxfBcA7Ur5iiAS343xwvrYHTUfBjBi4JimiL5LhujRT9t",
        net_leverage: 0,
        open_ms: 1714957209255,
        orders: [
          {
            leverage: -0.037500000000000006,
            order_type: "SHORT",
            order_uuid: "78986651-d936-4594-b715-7036bf5534e3",
            price: 0.90547,
            price_sources: [],
            processed_ms: 1714957209051,
          },
          {
            leverage: -0.037500000000000006,
            order_type: "SHORT",
            order_uuid: "f9f64429-edc3-416d-93e7-48cf9d4140ba",
            price: 0.90518,
            price_sources: [],
            processed_ms: 1714971613724,
          },
          {
            leverage: -0.07500000000000001,
            order_type: "SHORT",
            order_uuid: "3c0ff0b4-a412-4dd5-8918-2e203100f602",
            price: 0.90655,
            price_sources: [],
            processed_ms: 1714986019783,
          },
          {
            leverage: -0.09999999999999998,
            order_type: "SHORT",
            order_uuid: "968c4ae5-29c8-41c3-8a7b-856bb803e820",
            price: 0.907169984198016,
            price_sources: [],
            processed_ms: 1715043603459,
          },
          {
            leverage: -0.050000000000000044,
            order_type: "SHORT",
            order_uuid: "e18d47bb-e7e8-4eaa-b23d-378443b60da9",
            price: 0.906953202787919,
            price_sources: [],
            processed_ms: 1715043616206,
          },
          {
            leverage: -0.050000000000000044,
            order_type: "SHORT",
            order_uuid: "725b0528-da8b-4f4e-b602-501262d968ba",
            price: 0.907143426241916,
            price_sources: [],
            processed_ms: 1715043642329,
          },
          {
            leverage: 0.20000000000000004,
            order_type: "LONG",
            order_uuid: "1e0cab28-404b-42b5-b798-7e6f03b2d7b7",
            price: 0.90263,
            price_sources: [],
            processed_ms: 1715072404403,
          },
          {
            leverage: 0,
            order_type: "FLAT",
            order_uuid: "d3cf595c-1e91-4ca0-b489-53fbb4ffcc0f",
            price: 0.90264,
            price_sources: [],
            processed_ms: 1715072437655,
          },
        ],
        position_type: "FLAT",
        position_uuid: "dd267b51-e240-4e98-a486-5d988a5ba57e",
        return_at_close: 1.0015110790972082,
        trade_pair: ["AUDCAD", "AUD/CAD", 0.00007, 0.1, 5],
      },
      {
        average_entry_price: 1.0752978142076504,
        close_ms: 1715115613546,
        current_return: 0.9999346214805147,
        is_closed_position: true,
        miner_hotkey: "5GhCxfBcA7Ur5iiAS343xwvrYHTUfBjBi4JimiL5LhujRT9t",
        net_leverage: 0,
        open_ms: 1714683611440,
        orders: [
          {
            leverage: -0.22500000000000003,
            order_type: "SHORT",
            order_uuid: "0ce012ba-d608-4396-9158-1039e77b51b5",
            price: 1.0726,
            price_sources: [],
            processed_ms: 1714683610553,
          },
          {
            leverage: -0.22500000000000003,
            order_type: "SHORT",
            order_uuid: "115c4976-4637-4420-a5aa-b9bad284a508",
            price: 1.0743,
            price_sources: [],
            processed_ms: 1714726815513,
          },
          {
            leverage: -0.22499999999999987,
            order_type: "SHORT",
            order_uuid: "73943819-6853-4f0c-8fdf-8e164be67238",
            price: 1.07889,
            price_sources: [],
            processed_ms: 1714741219764,
          },
          {
            leverage: -0.007499999999999951,
            order_type: "SHORT",
            order_uuid: "f28d3009-ee96-4012-b54e-b898b67c34e0",
            price: 1.07669,
            price_sources: [],
            processed_ms: 1714957204341,
          },
          {
            leverage: -0.003750000000000031,
            order_type: "SHORT",
            order_uuid: "f0b1d713-8ae0-46ff-880e-948428125de0",
            price: 1.07872,
            price_sources: [],
            processed_ms: 1715000411193,
          },
          {
            leverage: 0,
            order_type: "FLAT",
            order_uuid: "7e15c4a3-2c14-4a2c-bd50-59099455e758",
            price: 1.0754,
            price_sources: [],
            processed_ms: 1715115613546,
          },
        ],
        position_type: "FLAT",
        position_uuid: "c6e2084f-8fbd-4542-92c8-677ea5799e35",
        return_at_close: 0.9998865871211353,
        trade_pair: ["EURUSD", "EUR/USD", 0.00007, 0.1, 5],
      },
      {
        average_entry_price: 0.6616298837209301,
        close_ms: 1715116772374,
        current_return: 1.0013353885225587,
        is_closed_position: true,
        miner_hotkey: "5GhCxfBcA7Ur5iiAS343xwvrYHTUfBjBi4JimiL5LhujRT9t",
        net_leverage: 0,
        open_ms: 1714640405241,
        orders: [
          {
            leverage: -0.037500000000000006,
            order_type: "SHORT",
            order_uuid: "5fd82f98-f57b-4330-9b43-89a9e926bac4",
            price: 0.65363,
            price_sources: [],
            processed_ms: 1714640404992,
          },
          {
            leverage: -0.037500000000000006,
            order_type: "SHORT",
            order_uuid: "c53b0e6b-a1ef-40e0-95cc-7da9a59ebbf2",
            price: 0.6561,
            price_sources: [],
            processed_ms: 1714683609140,
          },
          {
            leverage: -0.04499999999999997,
            order_type: "SHORT",
            order_uuid: "7ca3b59b-4569-413b-9dda-ef3b88fe4b71",
            price: 0.65768,
            price_sources: [],
            processed_ms: 1714698007694,
          },
          {
            leverage: -0.03750000000000002,
            order_type: "SHORT",
            order_uuid: "0d4fd1c6-e886-4b41-adbc-22b3909f02fc",
            price: 0.6581,
            price_sources: [],
            processed_ms: 1714726811316,
          },
          {
            leverage: -0.04500000000000001,
            order_type: "SHORT",
            order_uuid: "814d7eeb-c151-4724-95c2-471dcfb11003",
            price: 0.66327,
            price_sources: [],
            processed_ms: 1714741219772,
          },
          {
            leverage: -0.037500000000000006,
            order_type: "SHORT",
            order_uuid: "7198996e-89ef-4668-8211-2ddbafde9516",
            price: 0.66349,
            price_sources: [],
            processed_ms: 1715000420797,
          },
          {
            leverage: -0.12999999999999998,
            order_type: "SHORT",
            order_uuid: "72d03e25-4350-44c8-990e-103dbe061b99",
            price: 0.66395,
            price_sources: [],
            processed_ms: 1715043617310,
          },
          {
            leverage: -0.12999999999999998,
            order_type: "SHORT",
            order_uuid: "e12277c1-1f0f-40e2-96fe-f3b1e16a7b1d",
            price: 0.66395,
            price_sources: [],
            processed_ms: 1715043642335,
          },
          {
            leverage: 0.07,
            order_type: "LONG",
            order_uuid: "fa34ea42-eb79-4890-97b1-c14228d7f7d3",
            price: 0.66062,
            price_sources: [],
            processed_ms: 1715058009602,
          },
          {
            leverage: 0,
            order_type: "FLAT",
            order_uuid: "f7bbf65d-46ea-49e8-b338-df944b9b98e7",
            price: 0.6596,
            price_sources: [],
            processed_ms: 1715116772374,
          },
        ],
        position_type: "FLAT",
        position_uuid: "b797fa8b-0c81-44ad-be5c-e81eab378660",
        return_at_close: 1.0013003417839603,
        trade_pair: ["AUDUSD", "AUD/USD", 0.00007, 0.1, 5],
      },
      {
        average_entry_price: 1.4792140000000003,
        close_ms: 1715202545909,
        current_return: 1.0002645769340064,
        is_closed_position: true,
        miner_hotkey: "5GhCxfBcA7Ur5iiAS343xwvrYHTUfBjBi4JimiL5LhujRT9t",
        net_leverage: 0,
        open_ms: 1715158810538,
        orders: [
          {
            leverage: -0.03000000000000001,
            order_type: "SHORT",
            order_uuid: "2360005c-6120-4e0f-9c6a-3c3db0879a9b",
            price: 1.47802,
            price_sources: [],
            processed_ms: 1715158810356,
          },
          {
            leverage: -0.07499999999999998,
            order_type: "SHORT",
            order_uuid: "b393b794-edb2-4b04-b1dd-6838b080df16",
            price: 1.47801,
            price_sources: [],
            processed_ms: 1715173212861,
          },
          {
            leverage: 0.029999999999999985,
            order_type: "LONG",
            order_uuid: "b6134c29-02ab-47cf-84ae-29a0df032546",
            price: 1.47501,
            price_sources: [],
            processed_ms: 1715187616393,
          },
          {
            leverage: 0,
            order_type: "FLAT",
            order_uuid: "66e3e875-fb6d-4c3d-88ee-a0b14ec759db",
            price: 1.474,
            price_sources: [],
            processed_ms: 1715202545909,
          },
        ],
        position_type: "FLAT",
        position_uuid: "67062389-d491-40e2-bd0f-1dce9c32ae39",
        return_at_close: 1.000257224989366,
        trade_pair: ["EURCAD", "EUR/CAD", 0.00007, 0.1, 5],
      },
      {
        average_entry_price: 61052.33,
        close_ms: 1715270414027,
        current_return: 1.000268336196178,
        is_closed_position: true,
        miner_hotkey: "5GhCxfBcA7Ur5iiAS343xwvrYHTUfBjBi4JimiL5LhujRT9t",
        net_leverage: 0,
        open_ms: 1715256014100,
        orders: [
          {
            leverage: 0.015,
            order_type: "LONG",
            order_uuid: "57fec91f-5c58-4551-8e3c-d9e308da156f",
            price: 61052.33,
            price_sources: [],
            processed_ms: 1715256013006,
          },
          {
            leverage: 0,
            order_type: "FLAT",
            order_uuid: "cb83f1df-b522-4e24-9a7c-f0ef651ef9b6",
            price: 62144.5,
            price_sources: [],
            processed_ms: 1715270414027,
          },
        ],
        position_type: "FLAT",
        position_uuid: "cddfb84c-047e-4cf4-bddf-b76764b787ba",
        return_at_close: 1.000253332171135,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.01, 0.5],
      },
      {
        average_entry_price: 2935.6380000000004,
        close_ms: 1715299213875,
        current_return: 1.000831614569775,
        is_closed_position: true,
        miner_hotkey: "5GhCxfBcA7Ur5iiAS343xwvrYHTUfBjBi4JimiL5LhujRT9t",
        net_leverage: 0,
        open_ms: 1715227207387,
        orders: [
          {
            leverage: 0.025,
            order_type: "LONG",
            order_uuid: "a67f327f-e15f-43be-8fd3-f6952243340b",
            price: 2996.34,
            price_sources: [],
            processed_ms: 1715227207201,
          },
          {
            leverage: 0.015,
            order_type: "LONG",
            order_uuid: "023bebdb-56bf-43e5-ae40-fc151c317ab2",
            price: 2953.9,
            price_sources: [],
            processed_ms: 1715252415568,
          },
          {
            leverage: 0.015,
            order_type: "LONG",
            order_uuid: "1522d3bf-de5b-4f80-939b-8b43f69d8c5c",
            price: 2977.25,
            price_sources: [],
            processed_ms: 1715256012711,
          },
          {
            leverage: -0.03,
            order_type: "SHORT",
            order_uuid: "35251fde-f1d5-4625-8bc5-b40bc2230c99",
            price: 3016.16,
            price_sources: [],
            processed_ms: 1715281210045,
          },
          {
            leverage: 0,
            order_type: "FLAT",
            order_uuid: "251cebde-ea33-4193-a6e4-2abc2ae97f10",
            price: 3035.31,
            price_sources: [],
            processed_ms: 1715299213875,
          },
        ],
        position_type: "FLAT",
        position_uuid: "e7be3187-ee4e-4c74-8e58-321acf620b87",
        return_at_close: 1.0007765688309738,
        trade_pair: ["ETHUSD", "ETH/USD", 0.003, 0.01, 0.5],
      },
      {
        average_entry_price: 2903.06,
        close_ms: 1715515207625,
        current_return: 1.0001687656391784,
        is_closed_position: true,
        miner_hotkey: "5GhCxfBcA7Ur5iiAS343xwvrYHTUfBjBi4JimiL5LhujRT9t",
        net_leverage: 0,
        open_ms: 1715385614511,
        orders: [
          {
            leverage: 0.020000000000000004,
            order_type: "LONG",
            order_uuid: "d2540864-5b12-469b-8210-2ae04725cba3",
            price: 2909.36,
            price_sources: [],
            processed_ms: 1715385614282,
          },
          {
            leverage: 0.020000000000000004,
            order_type: "LONG",
            order_uuid: "64848b72-863d-47b9-8238-92c0f13fec34",
            price: 2904.31,
            price_sources: [],
            processed_ms: 1715428810538,
          },
          {
            leverage: -0.020000000000000004,
            order_type: "SHORT",
            order_uuid: "922fdc59-2700-4d4a-b6ee-2e3d7f0c3abf",
            price: 2910.61,
            price_sources: [],
            processed_ms: 1715472012441,
          },
          {
            leverage: 0,
            order_type: "FLAT",
            order_uuid: "97c57ddc-3074-4dad-a61a-fb119d585805",
            price: 2927.61,
            price_sources: [],
            processed_ms: 1715515207625,
          },
        ],
        position_type: "FLAT",
        position_uuid: "3633bf93-fb9b-40ca-ae7b-caeb4fec3625",
        return_at_close: 1.0001287588885528,
        trade_pair: ["ETHUSD", "ETH/USD", 0.003, 0.01, 0.5],
      },
      {
        average_entry_price: 60255.123333333366,
        close_ms: 1715536808401,
        current_return: 1.000999698897983,
        is_closed_position: true,
        miner_hotkey: "5GhCxfBcA7Ur5iiAS343xwvrYHTUfBjBi4JimiL5LhujRT9t",
        net_leverage: 0,
        open_ms: 1715353233158,
        orders: [
          {
            leverage: 0.015,
            order_type: "LONG",
            order_uuid: "711dd178-910d-499c-98e2-55e1f1b0f9ec",
            price: 60942.8,
            price_sources: [],
            processed_ms: 1715353232900,
          },
          {
            leverage: 0.03,
            order_type: "LONG",
            order_uuid: "5b48126c-647a-4c90-a371-c21bb37f1a86",
            price: 60668.27,
            price_sources: [],
            processed_ms: 1715361307285,
          },
          {
            leverage: 0.015,
            order_type: "LONG",
            order_uuid: "0ada7976-d03b-4f4d-b161-0d5643c15fb4",
            price: 60414.05,
            price_sources: [],
            processed_ms: 1715361905784,
          },
          {
            leverage: 0.015,
            order_type: "LONG",
            order_uuid: "13fe2698-164e-49db-80a8-a1afe015df33",
            price: 60385.43,
            price_sources: [],
            processed_ms: 1715361938238,
          },
          {
            leverage: 0.015,
            order_type: "LONG",
            order_uuid: "22e984dc-4a0c-4866-9c9d-62028ed87169",
            price: 60442,
            price_sources: [],
            processed_ms: 1715362503868,
          },
          {
            leverage: 0.015,
            order_type: "LONG",
            order_uuid: "fe59f9f8-e5de-413b-b37c-3c4ca96e5174",
            price: 60357.37,
            price_sources: [],
            processed_ms: 1715362536848,
          },
          {
            leverage: 0.045,
            order_type: "LONG",
            order_uuid: "4e964ac4-5a57-4f22-860d-2590542bb104",
            price: 60330.1,
            price_sources: [],
            processed_ms: 1715363407203,
          },
          {
            leverage: 0.04500000000000001,
            order_type: "LONG",
            order_uuid: "e07f1094-14f0-4399-80e6-dac32589f7c4",
            price: 60297.33,
            price_sources: [],
            processed_ms: 1715363706968,
          },
          {
            leverage: -0.18,
            order_type: "SHORT",
            order_uuid: "f0226bb3-4e1d-43c0-875c-bf8660c99a25",
            price: 60541.22,
            price_sources: [],
            processed_ms: 1715366106673,
          },
          {
            leverage: 0.015,
            order_type: "LONG",
            order_uuid: "dbe6c69a-86f4-4ae1-8bd7-fd3d082e3efe",
            price: 60800.26,
            price_sources: [],
            processed_ms: 1715385614285,
          },
          {
            leverage: 0.015,
            order_type: "LONG",
            order_uuid: "97a99395-c3d0-4df7-81e7-97c0c7ae0e6c",
            price: 60699.27,
            price_sources: [],
            processed_ms: 1715428811387,
          },
          {
            leverage: 0,
            order_type: "FLAT",
            order_uuid: "a238d443-a1d9-4496-bda8-dbfc7b6fdafd",
            price: 61609,
            price_sources: [],
            processed_ms: 1715536808401,
          },
        ],
        position_type: "FLAT",
        position_uuid: "3b4b306f-fb8e-428d-a460-8f3d285e8f44",
        return_at_close: 1.000774473965731,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.01, 0.5],
      },
      {
        average_entry_price: 0.6622099999999999,
        close_ms: 1715619618830,
        current_return: 1.0000684073028194,
        is_closed_position: true,
        miner_hotkey: "5GhCxfBcA7Ur5iiAS343xwvrYHTUfBjBi4JimiL5LhujRT9t",
        net_leverage: 0,
        open_ms: 1715605209967,
        orders: [
          {
            leverage: -0.03000000000000001,
            order_type: "SHORT",
            order_uuid: "a8cff9c0-303b-4818-be27-d46ed4e702e9",
            price: 0.66221,
            price_sources: [],
            processed_ms: 1715605209244,
          },
          {
            leverage: 0,
            order_type: "FLAT",
            order_uuid: "d67cae0f-7999-4720-9f53-8a49c34a7b79",
            price: 0.6607,
            price_sources: [],
            processed_ms: 1715619618830,
          },
        ],
        position_type: "FLAT",
        position_uuid: "374214d8-c640-4104-971d-320c434721d2",
        return_at_close: 1.000066307159164,
        trade_pair: ["AUDUSD", "AUD/USD", 0.00007, 0.1, 5],
      },
      {
        average_entry_price: 172.2509489608403,
        close_ms: 1715773846221,
        current_return: 1.0064076869448264,
        is_closed_position: true,
        miner_hotkey: "5GhCxfBcA7Ur5iiAS343xwvrYHTUfBjBi4JimiL5LhujRT9t",
        net_leverage: 0,
        open_ms: 1715173208145,
        orders: [
          {
            leverage: -0.45000000000000007,
            order_type: "SHORT",
            order_uuid: "5837623e-72e6-48f3-930c-cda38349268a",
            price: 171.2541984107479,
            price_sources: [],
            processed_ms: 1715173207845,
          },
          {
            leverage: -0.45000000000000007,
            order_type: "SHORT",
            order_uuid: "390e24a5-aa45-46bd-82d7-e0d5ad225a39",
            price: 171.719,
            price_sources: [],
            processed_ms: 1715306407745,
          },
          {
            leverage: -0.44999999999999973,
            order_type: "SHORT",
            order_uuid: "b8543823-4021-4516-a7f2-7b1a1b037129",
            price: 172.16,
            price_sources: [],
            processed_ms: 1715619618716,
          },
          {
            leverage: -0.4500000000000004,
            order_type: "SHORT",
            order_uuid: "c7f80517-0cbc-43d8-b6b6-50e8e54edb9e",
            price: 172.3084933688628,
            price_sources: [],
            processed_ms: 1715670012174,
          },
          {
            leverage: -0.44999999999999973,
            order_type: "SHORT",
            order_uuid: "1a05fb0d-41dc-414d-85e9-1e6b4307749f",
            price: 172.406,
            price_sources: [],
            processed_ms: 1715677209513,
          },
          {
            leverage: -0.44999999999999973,
            order_type: "SHORT",
            order_uuid: "7d855562-4ad7-4e62-9229-95925a5a8518",
            price: 172.5485696802815,
            price_sources: [],
            processed_ms: 1715691619423,
          },
          {
            leverage: -0.44999999999999973,
            order_type: "SHORT",
            order_uuid: "7079bcc2-3688-463f-b943-adb64f9044b2",
            price: 172.597100502974,
            price_sources: [],
            processed_ms: 1715695209258,
          },
          {
            leverage: -0.45000000000000107,
            order_type: "SHORT",
            order_uuid: "26eaf466-111a-4c65-8721-80068b8dda31",
            price: 172.5981786846963,
            price_sources: [],
            processed_ms: 1715706020153,
          },
          {
            leverage: -0.4499999999999993,
            order_type: "SHORT",
            order_uuid: "4f6f242f-45b8-4611-8053-f8a63dc412e8",
            price: 172.667,
            price_sources: [],
            processed_ms: 1715742009478,
          },
          {
            leverage: 0,
            order_type: "FLAT",
            order_uuid: "1ba1f5e6-2532-4472-b2f9-10e46e0871d3",
            price: 171.98,
            price_sources: [],
            processed_ms: 1715773846221,
          },
        ],
        position_type: "FLAT",
        position_uuid: "2f1c4761-7e51-4624-ba75-8b6404f8fc02",
        return_at_close: 1.0061223703655775,
        trade_pair: ["CHFJPY", "CHF/JPY", 0.00007, 0.1, 5],
      },
      {
        average_entry_price: 3026.2749999999996,
        close_ms: 1715828408620,
        current_return: 1.0011123800594466,
        is_closed_position: true,
        miner_hotkey: "5GhCxfBcA7Ur5iiAS343xwvrYHTUfBjBi4JimiL5LhujRT9t",
        net_leverage: 0,
        open_ms: 1715801422615,
        orders: [
          {
            leverage: -0.025,
            order_type: "SHORT",
            order_uuid: "3abd3b3e-43d7-4d70-b3c2-93403f8880af",
            price: 3007.74,
            price_sources: [],
            processed_ms: 1715801419940,
          },
          {
            leverage: -0.025,
            order_type: "SHORT",
            order_uuid: "31905d02-99da-4dc9-ab5b-9a0b48d49dda",
            price: 3022.56,
            price_sources: [],
            processed_ms: 1715805915191,
          },
          {
            leverage: -0.025,
            order_type: "SHORT",
            order_uuid: "62b7b789-b61d-498a-9d54-569d1c7aade2",
            price: 3022.6,
            price_sources: [],
            processed_ms: 1715805938816,
          },
          {
            leverage: -0.024999999999999994,
            order_type: "SHORT",
            order_uuid: "b91d402b-2376-40ed-9d22-82c1e33cbe43",
            price: 3037.4,
            price_sources: [],
            processed_ms: 1715816713936,
          },
          {
            leverage: -0.024999999999999994,
            order_type: "SHORT",
            order_uuid: "7a202c54-4bf4-4577-b554-291df0dc0d1f",
            price: 3037.8,
            price_sources: [],
            processed_ms: 1715816739924,
          },
          {
            leverage: -0.02500000000000001,
            order_type: "SHORT",
            order_uuid: "8ed795a1-ea59-45b2-8e33-cb612be30b83",
            price: 3029.55,
            price_sources: [],
            processed_ms: 1715819413864,
          },
          {
            leverage: 0,
            order_type: "FLAT",
            order_uuid: "9a068580-a1df-4f4d-979d-f7a33dac7ee4",
            price: 3003.97,
            price_sources: [],
            processed_ms: 1715828408620,
          },
        ],
        position_type: "FLAT",
        position_uuid: "9d31b640-7c7b-4a81-a754-abdf8f77684b",
        return_at_close: 1.0009622132024376,
        trade_pair: ["ETHUSD", "ETH/USD", 0.003, 0.01, 0.5],
      },
      {
        average_entry_price: 66020.54583333335,
        close_ms: 1715837413347,
        current_return: 1.0020555588911317,
        is_closed_position: true,
        miner_hotkey: "5GhCxfBcA7Ur5iiAS343xwvrYHTUfBjBi4JimiL5LhujRT9t",
        net_leverage: 0,
        open_ms: 1715793311456,
        orders: [
          {
            leverage: -0.05,
            order_type: "SHORT",
            order_uuid: "f63f3986-04de-4315-80a7-c8cdf62d5e67",
            price: 64956.3,
            price_sources: [],
            processed_ms: 1715793309953,
          },
          {
            leverage: -0.05,
            order_type: "SHORT",
            order_uuid: "9a374443-928d-4c35-a71a-d5f6ee50755b",
            price: 65385.69,
            price_sources: [],
            processed_ms: 1715797811091,
          },
          {
            leverage: -0.04999999999999999,
            order_type: "SHORT",
            order_uuid: "36c3fcec-a878-4769-8344-2576707cfedb",
            price: 65936.51,
            price_sources: [],
            processed_ms: 1715801418741,
          },
          {
            leverage: -0.05000000000000002,
            order_type: "SHORT",
            order_uuid: "a28fc2f8-4269-4227-a751-40f1d844f3b4",
            price: 66059.25,
            price_sources: [],
            processed_ms: 1715805007346,
          },
          {
            leverage: -0.04999999999999999,
            order_type: "SHORT",
            order_uuid: "251c3ddb-dc0a-4a09-8dc0-237e6c9fec43",
            price: 66024,
            price_sources: [],
            processed_ms: 1715809507991,
          },
          {
            leverage: -0.04999999999999999,
            order_type: "SHORT",
            order_uuid: "546d8a9c-5740-4b5f-bdcc-41782beec1b3",
            price: 66176.44,
            price_sources: [],
            processed_ms: 1715812205829,
          },
          {
            leverage: -0.04999999999999999,
            order_type: "SHORT",
            order_uuid: "27ba24cf-bb93-42f2-8199-361616eb1d89",
            price: 66190.28,
            price_sources: [],
            processed_ms: 1715813107621,
          },
          {
            leverage: -0.050000000000000044,
            order_type: "SHORT",
            order_uuid: "4e8bb04a-5cf2-4125-97d8-ff7d7e195b33",
            price: 66181.35,
            price_sources: [],
            processed_ms: 1715814914280,
          },
          {
            leverage: -0.04999999999999999,
            order_type: "SHORT",
            order_uuid: "738e6fdc-4e32-42f0-b3d6-274fd0c570f7",
            price: 66237.1,
            price_sources: [],
            processed_ms: 1715815810870,
          },
          {
            leverage: -0.04999999999999999,
            order_type: "SHORT",
            order_uuid: "d86b092d-22ba-45c1-9cb4-c456f3c1fb56",
            price: 66354.95,
            price_sources: [],
            processed_ms: 1715816714228,
          },
          {
            leverage: -0.04999999999999999,
            order_type: "SHORT",
            order_uuid: "4d9ab1bd-576f-488a-a622-928e49798498",
            price: 66344.8,
            price_sources: [],
            processed_ms: 1715816739804,
          },
          {
            leverage: -0.050000000000000044,
            order_type: "SHORT",
            order_uuid: "810f07ac-b2de-43bc-a292-22484c101339",
            price: 66399.88,
            price_sources: [],
            processed_ms: 1715818508819,
          },
          {
            leverage: 0,
            order_type: "FLAT",
            order_uuid: "0f114e9e-e1f9-44f4-a083-17aea5c4df51",
            price: 65798.01,
            price_sources: [],
            processed_ms: 1715837413347,
          },
        ],
        position_type: "FLAT",
        position_uuid: "8b1386d5-d57f-459f-b8b0-ae3f8a428185",
        return_at_close: 1.001454325555797,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.01, 0.5],
      },
      {
        average_entry_price: 0.6721514285714284,
        close_ms: 1715839213040,
        current_return: 1.0006494272614586,
        is_closed_position: true,
        miner_hotkey: "5GhCxfBcA7Ur5iiAS343xwvrYHTUfBjBi4JimiL5LhujRT9t",
        net_leverage: 0,
        open_ms: 1715814015794,
        orders: [
          {
            leverage: -0.03000000000000001,
            order_type: "SHORT",
            order_uuid: "c6cd34a3-ae3d-4b00-acfa-f1a109942173",
            price: 0.66959,
            price_sources: [],
            processed_ms: 1715814013261,
          },
          {
            leverage: -0.052500000000000005,
            order_type: "SHORT",
            order_uuid: "c80fd2f7-9f98-4828-8768-ba40745a6e11",
            price: 0.67106,
            price_sources: [],
            processed_ms: 1715821216327,
          },
          {
            leverage: -0.014999999999999972,
            order_type: "SHORT",
            order_uuid: "58e91a79-34a4-41f3-aa6b-6b2deef9c845",
            price: 0.67108,
            price_sources: [],
            processed_ms: 1715821234628,
          },
          {
            leverage: -0.014999999999999972,
            order_type: "SHORT",
            order_uuid: "d93a9cca-2bb6-4975-b52b-5af6f3c22ad8",
            price: 0.67104,
            price_sources: [],
            processed_ms: 1715821245857,
          },
          {
            leverage: -0.052500000000000005,
            order_type: "SHORT",
            order_uuid: "40ec5bbb-f041-49ca-bb81-5cefede89a78",
            price: 0.6711,
            price_sources: [],
            processed_ms: 1715821284837,
          },
          {
            leverage: 0.059999999999999984,
            order_type: "LONG",
            order_uuid: "28ea6fb5-229a-4f4e-b5d4-e890e14776ba",
            price: 0.66845,
            price_sources: [],
            processed_ms: 1715824809985,
          },
          {
            leverage: 0,
            order_type: "FLAT",
            order_uuid: "b04db863-a8ae-4a56-8366-bdedbe693f66",
            price: 0.66801,
            price_sources: [],
            processed_ms: 1715839213040,
          },
        ],
        position_type: "FLAT",
        position_uuid: "3df192b8-76bf-4063-a964-0ec794ce4b0f",
        return_at_close: 1.0006378697605738,
        trade_pair: ["AUDUSD", "AUD/USD", 0.00007, 0.1, 5],
      },
      {
        average_entry_price: 1.090684736842106,
        close_ms: 1715936450227,
        current_return: 1.0008135922330097,
        is_closed_position: true,
        miner_hotkey: "5GhCxfBcA7Ur5iiAS343xwvrYHTUfBjBi4JimiL5LhujRT9t",
        net_leverage: 0,
        open_ms: 1715706022854,
        orders: [
          {
            leverage: -0.0225,
            order_type: "SHORT",
            order_uuid: "ae023080-9b81-455c-803a-a5972b3720f0",
            price: 1.0815,
            price_sources: [],
            processed_ms: 1715706021453,
          },
          {
            leverage: -0.0225,
            order_type: "SHORT",
            order_uuid: "646a67d3-9789-4c69-b336-ac116cd55c1a",
            price: 1.0819,
            price_sources: [],
            processed_ms: 1715720414160,
          },
          {
            leverage: -0.022499999999999992,
            order_type: "SHORT",
            order_uuid: "9dc94b2e-ec06-424c-b65e-7d0bdd8454bb",
            price: 1.08302,
            price_sources: [],
            processed_ms: 1715763609596,
          },
          {
            leverage: -0.1125,
            order_type: "SHORT",
            order_uuid: "3c67c10c-dc11-4414-b438-3342dfab3ff4",
            price: 1.08577,
            price_sources: [],
            processed_ms: 1715778019633,
          },
          {
            leverage: -0.015000000000000013,
            order_type: "SHORT",
            order_uuid: "30d9ba7b-c246-4ec0-a1fa-3b5bfee47211",
            price: 1.08566,
            price_sources: [],
            processed_ms: 1715778052331,
          },
          {
            leverage: -0.09750000000000003,
            order_type: "SHORT",
            order_uuid: "1c4aeaaf-fd6f-47ab-8e12-4e3f18d30b5b",
            price: 1.0871,
            price_sources: [],
            processed_ms: 1715792418597,
          },
          {
            leverage: -0.022499999999999964,
            order_type: "SHORT",
            order_uuid: "d6272a9c-1208-4500-a7bf-5fe302e69d93",
            price: 1.0884,
            price_sources: [],
            processed_ms: 1715806812212,
          },
          {
            leverage: -0.03749999999999998,
            order_type: "SHORT",
            order_uuid: "3da58b47-e975-426f-b0fb-8ddc13f28593",
            price: 1.0885,
            price_sources: [],
            processed_ms: 1715806846227,
          },
          {
            leverage: -0.04500000000000004,
            order_type: "SHORT",
            order_uuid: "ede38ed3-641d-42ec-81ee-87b7ea728f35",
            price: 1.08926,
            price_sources: [],
            processed_ms: 1715821234600,
          },
          {
            leverage: -0.04500000000000004,
            order_type: "SHORT",
            order_uuid: "7ed0cce8-10ae-4dff-a9ff-bc422150e479",
            price: 1.08924,
            price_sources: [],
            processed_ms: 1715821247944,
          },
          {
            leverage: 0.030000000000000027,
            order_type: "LONG",
            order_uuid: "a478c148-352b-414f-a8a0-1c6b723ee3fc",
            price: 1.08632,
            price_sources: [],
            processed_ms: 1715907612038,
          },
          {
            leverage: 0.26999999999999996,
            order_type: "LONG",
            order_uuid: "eac5b1d4-79e9-4e3b-8e86-513712f0bcc2",
            price: 1.08444,
            price_sources: [],
            processed_ms: 1715936422292,
          },
          {
            leverage: 0,
            order_type: "FLAT",
            order_uuid: "1f2d171c-2137-46e1-aee0-ff8d665b538c",
            price: 1.08451,
            price_sources: [],
            processed_ms: 1715936450227,
          },
        ],
        position_type: "FLAT",
        position_uuid: "06bf4d62-0b4f-4015-90c7-594788c183f0",
        return_at_close: 1.0007825920319902,
        trade_pair: ["EURUSD", "EUR/USD", 0.00007, 0.1, 5],
      },
      {
        average_entry_price: 1.47896,
        close_ms: 1715950812077,
        current_return: 1.000056036057985,
        is_closed_position: true,
        miner_hotkey: "5GhCxfBcA7Ur5iiAS343xwvrYHTUfBjBi4JimiL5LhujRT9t",
        net_leverage: 0,
        open_ms: 1715706022238,
        orders: [
          {
            leverage: -0.03000000000000001,
            order_type: "SHORT",
            order_uuid: "161559b4-403c-42ac-a608-d9b51ffc397a",
            price: 1.47762,
            price_sources: [],
            processed_ms: 1715706021135,
          },
          {
            leverage: -0.03000000000000001,
            order_type: "SHORT",
            order_uuid: "5e7ff60b-a006-47ae-9d49-d6e4b6b0b0de",
            price: 1.4803,
            price_sources: [],
            processed_ms: 1715807078524,
          },
          {
            leverage: 0,
            order_type: "FLAT",
            order_uuid: "559eaa60-b1c4-4d14-9e8b-b6494aaade04",
            price: 1.47758,
            price_sources: [],
            processed_ms: 1715950812077,
          },
        ],
        position_type: "FLAT",
        position_uuid: "28e2b644-53a1-483a-8bbc-2fadf40860dc",
        return_at_close: 1.0000518358226336,
        trade_pair: ["EURCAD", "EUR/CAD", 0.00007, 0.1, 5],
      },
      {
        average_entry_price: 3116.3,
        close_ms: 1715969707868,
        current_return: 1.00034544170972,
        is_closed_position: true,
        miner_hotkey: "5GhCxfBcA7Ur5iiAS343xwvrYHTUfBjBi4JimiL5LhujRT9t",
        net_leverage: 0,
        open_ms: 1715961623114,
        orders: [
          {
            leverage: -0.025,
            order_type: "SHORT",
            order_uuid: "dd0f56cd-e7bb-4ed9-a4dc-dcc95ab0ff8b",
            price: 3116.3,
            price_sources: [],
            processed_ms: 1715961621462,
          },
          {
            leverage: 0,
            order_type: "FLAT",
            order_uuid: "654e93f9-c2c4-409c-8dbe-438fb53b46d6",
            price: 3073.24,
            price_sources: [],
            processed_ms: 1715969707868,
          },
        ],
        position_type: "FLAT",
        position_uuid: "b96e2dee-2a03-4cc6-aaeb-b40d0f674a45",
        return_at_close: 1.0003204330736772,
        trade_pair: ["ETHUSD", "ETH/USD", 0.003, 0.01, 0.5],
      },
      {
        average_entry_price: 3126.276666666667,
        close_ms: 1716040807330,
        current_return: 1.0004153962191482,
        is_closed_position: true,
        miner_hotkey: "5GhCxfBcA7Ur5iiAS343xwvrYHTUfBjBi4JimiL5LhujRT9t",
        net_leverage: 0,
        open_ms: 1716004810697,
        orders: [
          {
            leverage: -0.025,
            order_type: "SHORT",
            order_uuid: "2a254bab-bab3-4d92-bbc4-64fdfb68f576",
            price: 3115.7,
            price_sources: [],
            processed_ms: 1716004808509,
          },
          {
            leverage: -0.025,
            order_type: "SHORT",
            order_uuid: "6d23bf5f-1501-40ed-9a70-6b5336a151c8",
            price: 3124.85,
            price_sources: [],
            processed_ms: 1716026410088,
          },
          {
            leverage: -0.024999999999999994,
            order_type: "SHORT",
            order_uuid: "124c9ac5-4afa-4af0-a7ea-b1d05ee5b482",
            price: 3138.28,
            price_sources: [],
            processed_ms: 1716030909002,
          },
          {
            leverage: 0,
            order_type: "FLAT",
            order_uuid: "1e347745-a149-42aa-b8f5-9670070df53f",
            price: 3109.02,
            price_sources: [],
            processed_ms: 1716040807330,
          },
        ],
        position_type: "FLAT",
        position_uuid: "e5084527-44e4-49a5-ab8f-c9e20c412666",
        return_at_close: 1.0003403650644318,
        trade_pair: ["ETHUSD", "ETH/USD", 0.003, 0.01, 0.5],
      },
      {
        average_entry_price: 0.6667,
        close_ms: 1716157556805,
        current_return: 0.9993565321733913,
        is_closed_position: true,
        miner_hotkey: "5GhCxfBcA7Ur5iiAS343xwvrYHTUfBjBi4JimiL5LhujRT9t",
        net_leverage: 0,
        open_ms: 1715864418538,
        orders: [
          {
            leverage: -0.15000000000000002,
            order_type: "SHORT",
            order_uuid: "b9eb5fe9-8cc5-4eb8-a348-1303360909f2",
            price: 0.6667,
            price_sources: [],
            processed_ms: 1715864417332,
          },
          {
            leverage: 0,
            order_type: "FLAT",
            order_uuid: "957eec6c-2707-444b-bcdb-3cc40e0f435b",
            price: 0.66956,
            price_sources: [],
            processed_ms: 1716157556805,
          },
        ],
        position_type: "FLAT",
        position_uuid: "cb48e349-9809-4652-8d00-3a7bc8904671",
        return_at_close: 0.9993460389298034,
        trade_pair: ["AUDUSD", "AUD/USD", 0.00007, 0.1, 5],
      },
      {
        average_entry_price: 0.9247478901474265,
        close_ms: 1716278658611,
        current_return: 1.0055042813904767,
        is_closed_position: true,
        miner_hotkey: "5GhCxfBcA7Ur5iiAS343xwvrYHTUfBjBi4JimiL5LhujRT9t",
        net_leverage: 0,
        open_ms: 1715778025165,
        orders: [
          {
            leverage: -0.15000000000000002,
            order_type: "SHORT",
            order_uuid: "02efce50-a48f-43e2-9fba-9b43947522d3",
            price: 0.907360414543662,
            price_sources: [],
            processed_ms: 1715778024120,
          },
          {
            leverage: -0.30000000000000004,
            order_type: "SHORT",
            order_uuid: "a69413d6-ac73-48a4-b911-ee276093a258",
            price: 0.90955753514439,
            price_sources: [],
            processed_ms: 1715792415203,
          },
          {
            leverage: -0.03750000000000003,
            order_type: "SHORT",
            order_uuid: "c7e36e06-8e23-4b47-8b24-c45fb58d1f12",
            price: 0.909951370261611,
            price_sources: [],
            processed_ms: 1715799617899,
          },
          {
            leverage: -0.03749999999999992,
            order_type: "SHORT",
            order_uuid: "ad901fac-4ebf-4b50-ab99-4782a901899b",
            price: 0.910723492584285,
            price_sources: [],
            processed_ms: 1715814013624,
          },
          {
            leverage: -0.03749999999999998,
            order_type: "SHORT",
            order_uuid: "ad934273-bc85-47f7-9a81-a8d4a8dc7759",
            price: 0.910925411126369,
            price_sources: [],
            processed_ms: 1715817617975,
          },
          {
            leverage: -0.03750000000000009,
            order_type: "SHORT",
            order_uuid: "f93060f8-93ef-47a8-a927-1619fa1f2350",
            price: 0.912063909597848,
            price_sources: [],
            processed_ms: 1715821216903,
          },
          {
            leverage: 0.03749999999999998,
            order_type: "LONG",
            order_uuid: "63a17fd9-8d0d-481a-bf89-374dfd1b4a99",
            price: 0.909444952872097,
            price_sources: [],
            processed_ms: 1715839213132,
          },
          {
            leverage: 0.11250000000000004,
            order_type: "LONG",
            order_uuid: "7e99b3ea-87a0-40b5-8fd4-4fee36ac1ccc",
            price: 0.90937,
            price_sources: [],
            processed_ms: 1715839246493,
          },
          {
            leverage: -0.29999999999999993,
            order_type: "SHORT",
            order_uuid: "89806685-db45-4367-8bbc-d39d53c348cf",
            price: 0.910038612617909,
            price_sources: [],
            processed_ms: 1715850011979,
          },
          {
            leverage: 0.29999999999999993,
            order_type: "LONG",
            order_uuid: "93c4e122-6be1-4426-9fac-5143f17d27ef",
            price: 0.90746,
            price_sources: [],
            processed_ms: 1715936418912,
          },
          {
            leverage: -0.8099999999999999,
            order_type: "SHORT",
            order_uuid: "40baeacc-aea0-4edd-b871-8e032fb0eede",
            price: 0.911737697917155,
            price_sources: [],
            processed_ms: 1716166815676,
          },
          {
            leverage: -0.3750000000000002,
            order_type: "SHORT",
            order_uuid: "f6ccff09-c732-40d5-946b-3959d2cdc2c9",
            price: 0.9121893484829084,
            price_sources: [],
            processed_ms: 1716181229426,
          },
          {
            leverage: 0.06000000000000005,
            order_type: "LONG",
            order_uuid: "fb4c5f05-9037-438c-ab09-4cbaeef3fc58",
            price: 0.91089,
            price_sources: [],
            processed_ms: 1716195620761,
          },
          {
            leverage: 0.07500000000000018,
            order_type: "LONG",
            order_uuid: "7345505c-ed14-4fa5-aaaa-1f0171099a11",
            price: 0.910330354815642,
            price_sources: [],
            processed_ms: 1716206414450,
          },
          {
            leverage: 1.2,
            order_type: "LONG",
            order_uuid: "372bdc6f-5bf6-43c7-95d0-7375a42fa2d3",
            price: 0.9085,
            price_sources: [],
            processed_ms: 1716238996311,
          },
          {
            leverage: 0,
            order_type: "FLAT",
            order_uuid: "8e842ac5-390a-4684-90b3-48ea258a137d",
            price: 0.9081,
            price_sources: [],
            processed_ms: 1716278658611,
          },
        ],
        position_type: "FLAT",
        position_uuid: "b6ae9d4a-e224-4b72-a9c8-644010581f5d",
        return_at_close: 1.0053575280406077,
        trade_pair: ["AUDCAD", "AUD/CAD", 0.00007, 0.1, 5],
      },
      {
        average_entry_price: 0.6681292307692307,
        close_ms: 1716296410254,
        current_return: 1.0006010417758087,
        is_closed_position: true,
        miner_hotkey: "5GhCxfBcA7Ur5iiAS343xwvrYHTUfBjBi4JimiL5LhujRT9t",
        net_leverage: 0,
        open_ms: 1716166815947,
        orders: [
          {
            leverage: -0.165,
            order_type: "SHORT",
            order_uuid: "1c443990-138b-4860-80ab-c8f2c32454df",
            price: 0.66809,
            price_sources: [],
            processed_ms: 1716166815691,
          },
          {
            leverage: -0.03,
            order_type: "SHORT",
            order_uuid: "07cfa082-5f7e-4853-ad92-87b907af9871",
            price: 0.668345,
            price_sources: [],
            processed_ms: 1716181232947,
          },
          {
            leverage: 0,
            order_type: "FLAT",
            order_uuid: "24ebd639-e1c1-4522-8bd6-73e1b051f4d4",
            price: 0.66607,
            price_sources: [],
            processed_ms: 1716296410254,
          },
        ],
        position_type: "FLAT",
        position_uuid: "8e2f9fd4-c196-4a6f-a65c-947e61e0a1ac",
        return_at_close: 1.0005873835715884,
        trade_pair: ["CADCHF", "CAD/CHF", 0.00007, 0.1, 5],
      },
      {
        average_entry_price: 69067.6,
        close_ms: 1716425110570,
        current_return: 1.0001545587221794,
        is_closed_position: true,
        miner_hotkey: "5GhCxfBcA7Ur5iiAS343xwvrYHTUfBjBi4JimiL5LhujRT9t",
        net_leverage: 0,
        open_ms: 1716419707313,
        orders: [
          {
            leverage: 0.025,
            order_type: "LONG",
            order_uuid: "58e7bf94-355b-4a74-a200-56f373911a93",
            price: 69067.6,
            price_sources: [],
            processed_ms: 1716419707026,
          },
          {
            leverage: 0,
            order_type: "FLAT",
            order_uuid: "b79d65ad-7181-4f3a-9068-17c4ee92297c",
            price: 69494.6,
            price_sources: [],
            processed_ms: 1716425110570,
          },
        ],
        position_type: "FLAT",
        position_uuid: "9eb25b05-ee2b-46cd-95c1-53571b3c5409",
        return_at_close: 1.0001295548582112,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.01, 0.5],
      },
      {
        average_entry_price: 0.6682,
        close_ms: 1716483623209,
        current_return: 1.000085316569376,
        is_closed_position: true,
        miner_hotkey: "5GhCxfBcA7Ur5iiAS343xwvrYHTUfBjBi4JimiL5LhujRT9t",
        net_leverage: 0,
        open_ms: 1716382816036,
        orders: [
          {
            leverage: -0.03000000000000001,
            order_type: "SHORT",
            order_uuid: "8cdfe572-4287-478e-80cb-d1f0ad8e8d60",
            price: 0.6681,
            price_sources: [],
            processed_ms: 1716382815896,
          },
          {
            leverage: -0.03000000000000001,
            order_type: "SHORT",
            order_uuid: "58c29177-208c-47af-99a9-077a7af5e1bb",
            price: 0.6683,
            price_sources: [],
            processed_ms: 1716397220308,
          },
          {
            leverage: 0,
            order_type: "FLAT",
            order_uuid: "5a4eaffe-51ac-40dc-9501-790aeec62e52",
            price: 0.66725,
            price_sources: [],
            processed_ms: 1716483623209,
          },
        ],
        position_type: "FLAT",
        position_uuid: "ee66b7ee-3ea2-4000-b9ef-54265954d3c5",
        return_at_close: 1.0000811162110463,
        trade_pair: ["CADCHF", "CAD/CHF", 0.00007, 0.1, 5],
      },
      {
        average_entry_price: 66977.55,
        close_ms: 1716489608121,
        current_return: 1.0001444776342319,
        is_closed_position: true,
        miner_hotkey: "5GhCxfBcA7Ur5iiAS343xwvrYHTUfBjBi4JimiL5LhujRT9t",
        net_leverage: 0,
        open_ms: 1716488706773,
        orders: [
          {
            leverage: 0.015,
            order_type: "LONG",
            order_uuid: "f9064611-820a-47bb-a1eb-ccf2709c5973",
            price: 66955,
            price_sources: [],
            processed_ms: 1716488706285,
          },
          {
            leverage: 0.015,
            order_type: "LONG",
            order_uuid: "151de95e-c5e0-4053-9ef4-fa5095a4a7d2",
            price: 67000.1,
            price_sources: [],
            processed_ms: 1716489007391,
          },
          {
            leverage: 0,
            order_type: "FLAT",
            order_uuid: "c443adea-52b8-4b61-956c-1d2653f90c04",
            price: 67300,
            price_sources: [],
            processed_ms: 1716489608121,
          },
        ],
        position_type: "FLAT",
        position_uuid: "d2d315ef-d8e6-412a-9dd4-75d991ccdadc",
        return_at_close: 1.0001144732999028,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.01, 0.5],
      },
      {
        average_entry_price: 1.2716705555555554,
        close_ms: 1716526810232,
        current_return: 1.0005553885702987,
        is_closed_position: true,
        miner_hotkey: "5GhCxfBcA7Ur5iiAS343xwvrYHTUfBjBi4JimiL5LhujRT9t",
        net_leverage: 0,
        open_ms: 1715778020094,
        orders: [
          {
            leverage: -0.015000000000000005,
            order_type: "SHORT",
            order_uuid: "f6256bb0-a324-4a55-b01b-854d16d84f7a",
            price: 1.26425,
            price_sources: [],
            processed_ms: 1715778019627,
          },
          {
            leverage: -0.0225,
            order_type: "SHORT",
            order_uuid: "ca599d48-3af2-44f5-b2ff-f7d0941941b2",
            price: 1.26825,
            price_sources: [],
            processed_ms: 1715806811312,
          },
          {
            leverage: -0.015,
            order_type: "SHORT",
            order_uuid: "b5d743cb-1daf-48b4-968f-c85b88f4820f",
            price: 1.2675,
            price_sources: [],
            processed_ms: 1715850011809,
          },
          {
            leverage: 0.022499999999999996,
            order_type: "LONG",
            order_uuid: "497857f3-5951-4b98-92db-1c08e5e56178",
            price: 1.26536,
            price_sources: [],
            processed_ms: 1715864406748,
          },
          {
            leverage: -0.022499999999999996,
            order_type: "SHORT",
            order_uuid: "e3613994-2036-4ea3-86f2-5c3840b34c2e",
            price: 1.26645,
            price_sources: [],
            processed_ms: 1715907612116,
          },
          {
            leverage: 0.030000000000000006,
            order_type: "LONG",
            order_uuid: "6dbe0e78-29ef-4a9a-8627-68fe54d69bad",
            price: 1.26488,
            price_sources: [],
            processed_ms: 1715936422263,
          },
          {
            leverage: -0.0675,
            order_type: "SHORT",
            order_uuid: "4cd3151b-f407-4808-b739-2f620803c1a8",
            price: 1.26979,
            price_sources: [],
            processed_ms: 1716210022052,
          },
          {
            leverage: -0.044999999999999984,
            order_type: "SHORT",
            order_uuid: "948c8f28-3001-4427-959a-6d6faef4bbbf",
            price: 1.271115,
            price_sources: [],
            processed_ms: 1716282012283,
          },
          {
            leverage: -0.04500000000000001,
            order_type: "SHORT",
            order_uuid: "965df8b9-9914-4e83-8110-028285815c67",
            price: 1.2711549999999998,
            price_sources: [],
            processed_ms: 1716339614759,
          },
          {
            leverage: -0.044999999999999984,
            order_type: "SHORT",
            order_uuid: "b8e0dfe9-2515-48ab-b474-ff9ccd1a6829",
            price: 1.27282,
            price_sources: [],
            processed_ms: 1716368410135,
          },
          {
            leverage: -0.044999999999999984,
            order_type: "SHORT",
            order_uuid: "7abc5b2d-7405-42e8-911a-b23a2e544428",
            price: 1.27271,
            price_sources: [],
            processed_ms: 1716382816251,
          },
          {
            leverage: -0.044999999999999984,
            order_type: "SHORT",
            order_uuid: "aaf6b693-3f31-4598-b080-6545801f2279",
            price: 1.27328,
            price_sources: [],
            processed_ms: 1716469221875,
          },
          {
            leverage: 0.044999999999999984,
            order_type: "LONG",
            order_uuid: "18a6ade0-a7e3-42d8-bea8-79627821cdba",
            price: 1.271075,
            price_sources: [],
            processed_ms: 1716483624012,
          },
          {
            leverage: 0,
            order_type: "FLAT",
            order_uuid: "67926887-38d5-49ee-b96e-1a08c60fbc7d",
            price: 1.26907,
            price_sources: [],
            processed_ms: 1716526810232,
          },
        ],
        position_type: "FLAT",
        position_uuid: "9ac51503-3b55-4bea-8cb6-1819d3ddbdad",
        return_at_close: 1.0005296492829276,
        trade_pair: ["GBPUSD", "GBP/USD", 0.00007, 0.1, 5],
      },
      {
        average_entry_price: 1.487865,
        close_ms: 1716570008632,
        current_return: 1.0005318816472604,
        is_closed_position: true,
        miner_hotkey: "5GhCxfBcA7Ur5iiAS343xwvrYHTUfBjBi4JimiL5LhujRT9t",
        net_leverage: 0,
        open_ms: 1716555605339,
        orders: [
          {
            leverage: -0.06000000000000002,
            order_type: "SHORT",
            order_uuid: "0a33e6a1-0276-4d90-bfd5-118a8f3bce8b",
            price: 1.487925,
            price_sources: [],
            processed_ms: 1716555605318,
          },
          {
            leverage: -0.06000000000000002,
            order_type: "SHORT",
            order_uuid: "28fe7d2d-357a-4ca2-ab6c-e6926a6efd67",
            price: 1.487805,
            price_sources: [],
            processed_ms: 1716555640809,
          },
          {
            leverage: 0,
            order_type: "FLAT",
            order_uuid: "3712f433-ba68-407a-9482-4aaa15c708c2",
            price: 1.4812699999999999,
            price_sources: [],
            processed_ms: 1716570008632,
          },
        ],
        position_type: "FLAT",
        position_uuid: "0a94da51-714c-470d-b385-5cbb462d9fcf",
        return_at_close: 1.0005234771794544,
        trade_pair: ["EURCAD", "EUR/CAD", 0.00007, 0.1, 5],
      },
      {
        average_entry_price: 67727.30333333333,
        close_ms: 1716873306590,
        current_return: 1.0001747765391922,
        is_closed_position: true,
        miner_hotkey: "5GhCxfBcA7Ur5iiAS343xwvrYHTUfBjBi4JimiL5LhujRT9t",
        net_leverage: 0,
        open_ms: 1716869708761,
        orders: [
          {
            leverage: 0.03,
            order_type: "LONG",
            order_uuid: "600b7ad5-1e44-4f04-a539-6ee61895e8fb",
            price: 67909,
            price_sources: [],
            processed_ms: 1716869704482,
          },
          {
            leverage: 0.03,
            order_type: "LONG",
            order_uuid: "9ca085dd-6cf5-4c37-9a92-83c8a68eaa96",
            price: 67654.53,
            price_sources: [],
            processed_ms: 1716870609023,
          },
          {
            leverage: 0.03,
            order_type: "LONG",
            order_uuid: "434be915-32b3-48dc-bde3-f038f14272db",
            price: 67618.38,
            price_sources: [],
            processed_ms: 1716871507957,
          },
          {
            leverage: 0,
            order_type: "FLAT",
            order_uuid: "a6bfdefa-267e-4f7e-a405-5f30d8d4c3b2",
            price: 67859.18,
            price_sources: [],
            processed_ms: 1716873306590,
          },
        ],
        position_type: "FLAT",
        position_uuid: "fc872f5a-f459-4699-9fb5-7e64e5dbb022",
        return_at_close: 1.0000847608093035,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.01, 0.5],
      },
      {
        average_entry_price: 1.276985,
        close_ms: 1716930005760,
        current_return: 1.0000197339827797,
        is_closed_position: true,
        miner_hotkey: "5GhCxfBcA7Ur5iiAS343xwvrYHTUfBjBi4JimiL5LhujRT9t",
        net_leverage: 0,
        open_ms: 1716858005237,
        orders: [
          {
            leverage: -0.03000000000000001,
            order_type: "SHORT",
            order_uuid: "448a74f8-11c1-4353-a79a-b4487ee00d9a",
            price: 1.276985,
            price_sources: [],
            processed_ms: 1716858005219,
          },
          {
            leverage: 0,
            order_type: "FLAT",
            order_uuid: "0a8e5899-b3a4-412a-a3e6-9cfa33bcfc47",
            price: 1.276145,
            price_sources: [],
            processed_ms: 1716930005760,
          },
        ],
        position_type: "FLAT",
        position_uuid: "ab244a6c-8bd8-40ad-8960-5242fc517e22",
        return_at_close: 1.0000176339413382,
        trade_pair: ["GBPUSD", "GBP/USD", 0.00007, 0.1, 5],
      },
      {
        average_entry_price: 1.0011372115384627,
        close_ms: 1717016409895,
        current_return: 1.011855819031178,
        is_closed_position: true,
        miner_hotkey: "5GhCxfBcA7Ur5iiAS343xwvrYHTUfBjBi4JimiL5LhujRT9t",
        net_leverage: 0,
        open_ms: 1715893213782,
        orders: [
          {
            leverage: -0.15000000000000002,
            order_type: "SHORT",
            order_uuid: "5ba82826-5546-4309-b46c-43a560b9f1b5",
            price: 0.9847,
            price_sources: [],
            processed_ms: 1715893212787,
          },
          {
            leverage: -0.022499999999999964,
            order_type: "SHORT",
            order_uuid: "72f720e8-b451-4d74-ae06-cd81397e480c",
            price: 0.9849,
            price_sources: [],
            processed_ms: 1715922012413,
          },
          {
            leverage: -0.5625,
            order_type: "SHORT",
            order_uuid: "3bc68fcd-3168-4397-b98b-dae99c9ad5c7",
            price: 0.9867,
            price_sources: [],
            processed_ms: 1715965215538,
          },
          {
            leverage: -0.29999999999999993,
            order_type: "SHORT",
            order_uuid: "761e51ef-8445-472a-949d-b681c58bae42",
            price: 0.98876,
            price_sources: [],
            processed_ms: 1716166815158,
          },
          {
            leverage: -0.30000000000000004,
            order_type: "SHORT",
            order_uuid: "db5a469f-ef17-46e4-8564-928a6209d6fb",
            price: 0.98928,
            price_sources: [],
            processed_ms: 1716181213393,
          },
          {
            leverage: -0.0900000000000003,
            order_type: "SHORT",
            order_uuid: "dfaca914-ba7c-495d-9cec-fffa61cda7b5",
            price: 0.98928,
            price_sources: [],
            processed_ms: 1716181248593,
          },
          {
            leverage: -0.04499999999999993,
            order_type: "SHORT",
            order_uuid: "b94c46aa-78e7-4ee1-aab2-35d179c104d2",
            price: 0.98828,
            price_sources: [],
            processed_ms: 1716195607878,
          },
          {
            leverage: -0.2999999999999996,
            order_type: "SHORT",
            order_uuid: "e97cb06c-0606-4368-8576-4f01366c2d93",
            price: 0.989205,
            price_sources: [],
            processed_ms: 1716253208580,
          },
          {
            leverage: -0.345,
            order_type: "SHORT",
            order_uuid: "4c181eb8-1c2f-47f5-b77c-e8014aee6123",
            price: 0.99025,
            price_sources: [],
            processed_ms: 1716368410095,
          },
          {
            leverage: -0.04499999999999993,
            order_type: "SHORT",
            order_uuid: "8dcb3408-2984-4e5c-9f5a-623e59f2b0cb",
            price: 0.990205,
            price_sources: [],
            processed_ms: 1716368443150,
          },
          {
            leverage: -0.13500000000000023,
            order_type: "SHORT",
            order_uuid: "d3431225-d75c-4a1f-9922-a65007de9a1a",
            price: 0.9904,
            price_sources: [],
            processed_ms: 1716382834566,
          },
          {
            leverage: -0.30000000000000027,
            order_type: "SHORT",
            order_uuid: "58bf64e5-38d9-4f03-ae0f-0ce989ea5c64",
            price: 0.99034,
            price_sources: [],
            processed_ms: 1716382854250,
          },
          {
            leverage: -0.3900000000000001,
            order_type: "SHORT",
            order_uuid: "ba4afec5-6839-41f8-8fe5-ec2d0b2914d5",
            price: 0.99076,
            price_sources: [],
            processed_ms: 1716397211873,
          },
          {
            leverage: -0.38999999999999924,
            order_type: "SHORT",
            order_uuid: "d7858f10-0c8d-4fd7-9bd8-e0899479cbc2",
            price: 0.99098,
            price_sources: [],
            processed_ms: 1716411607401,
          },
          {
            leverage: -0.3000000000000007,
            order_type: "SHORT",
            order_uuid: "de6a6f49-2f55-4154-84bd-a450896a34ac",
            price: 0.991305,
            price_sources: [],
            processed_ms: 1716454808639,
          },
          {
            leverage: -0.08999999999999986,
            order_type: "SHORT",
            order_uuid: "c26dd47a-c972-4163-9a10-3e83bde49480",
            price: 0.99129,
            price_sources: [],
            processed_ms: 1716454842375,
          },
          {
            leverage: -0.3450000000000002,
            order_type: "SHORT",
            order_uuid: "b531e29a-def7-4a2f-93a7-2a36f0fc0d16",
            price: 0.99131,
            price_sources: [],
            processed_ms: 1716469212983,
          },
          {
            leverage: -0.04500000000000082,
            order_type: "SHORT",
            order_uuid: "4c1f14d5-926d-498f-a5dd-f1497b40cd7f",
            price: 0.99127,
            price_sources: [],
            processed_ms: 1716469249323,
          },
          {
            leverage: 3.300000000000001,
            order_type: "LONG",
            order_uuid: "3f9dc071-abdd-44ac-b19c-d9263ee59a43",
            price: 0.98846,
            price_sources: [],
            processed_ms: 1716488920763,
          },
          {
            leverage: 0.09000000000000008,
            order_type: "LONG",
            order_uuid: "6f64b005-b692-4462-af76-b2c2a4b80d39",
            price: 0.9887,
            price_sources: [],
            processed_ms: 1716498009810,
          },
          {
            leverage: -1.5899999999999999,
            order_type: "SHORT",
            order_uuid: "a43e6cc6-7694-4877-b30d-8315d69b3fa2",
            price: 0.99232,
            price_sources: [],
            processed_ms: 1716555614352,
          },
          {
            leverage: 0.7199999999999998,
            order_type: "LONG",
            order_uuid: "6b175462-7cff-4572-9fd1-707d18b4bba2",
            price: 0.98948,
            price_sources: [],
            processed_ms: 1716930070766,
          },
          {
            leverage: 0.405,
            order_type: "LONG",
            order_uuid: "e6ec9aa7-7420-4882-897c-3589e4f71e11",
            price: 0.98915,
            price_sources: [],
            processed_ms: 1716973211812,
          },
          {
            leverage: 0.45000000000000007,
            order_type: "LONG",
            order_uuid: "dea6ba78-7c4d-49c3-8c7a-ae5880d74b02",
            price: 0.98807,
            price_sources: [],
            processed_ms: 1717002016374,
          },
          {
            leverage: 0,
            order_type: "FLAT",
            order_uuid: "28495392-e662-4ff0-a0cd-55b23d883987",
            price: 0.98617,
            price_sources: [],
            processed_ms: 1717016409895,
          },
        ],
        position_type: "FLAT",
        position_uuid: "58900aae-d25a-4a20-8603-5670a1ed3506",
        return_at_close: 1.0114489012135546,
        trade_pair: ["EURCHF", "EUR/CHF", 0.00007, 0.1, 5],
      },
      {
        average_entry_price: 0.660515,
        close_ms: 1717088415840,
        current_return: 1.0000401959077387,
        is_closed_position: true,
        miner_hotkey: "5GhCxfBcA7Ur5iiAS343xwvrYHTUfBjBi4JimiL5LhujRT9t",
        net_leverage: 0,
        open_ms: 1717074014612,
        orders: [
          {
            leverage: 0.03000000000000001,
            order_type: "LONG",
            order_uuid: "acaba033-2d94-4320-8e23-bd746e4f009c",
            price: 0.660515,
            price_sources: [],
            processed_ms: 1717074014539,
          },
          {
            leverage: 0,
            order_type: "FLAT",
            order_uuid: "9c1e2e61-81c7-44cc-9727-7795e1a1ce55",
            price: 0.6614,
            price_sources: [],
            processed_ms: 1717088415840,
          },
        ],
        position_type: "FLAT",
        position_uuid: "6772f544-46c1-468e-b7cb-6993632e416f",
        return_at_close: 1.0000380958233273,
        trade_pair: ["CADCHF", "CAD/CHF", 0.00007, 0.1, 5],
      },
      {
        average_entry_price: 0.65986,
        close_ms: 1717131606853,
        current_return: 1.0001245718788834,
        is_closed_position: true,
        miner_hotkey: "5GhCxfBcA7Ur5iiAS343xwvrYHTUfBjBi4JimiL5LhujRT9t",
        net_leverage: 0,
        open_ms: 1717117210197,
        orders: [
          {
            leverage: 0.06000000000000002,
            order_type: "LONG",
            order_uuid: "f8240a29-4d46-4087-b6c9-e6abcbd3b9ff",
            price: 0.65986,
            price_sources: [],
            processed_ms: 1717117210179,
          },
          {
            leverage: 0,
            order_type: "FLAT",
            order_uuid: "0a9ec6b2-4d86-4f9e-a5f4-c02929a42518",
            price: 0.66123,
            price_sources: [],
            processed_ms: 1717131606853,
          },
        ],
        position_type: "FLAT",
        position_uuid: "d9904f45-a843-43ee-9499-43ee084bdbc8",
        return_at_close: 1.0001203713556814,
        trade_pair: ["CADCHF", "CAD/CHF", 0.00007, 0.1, 5],
      },
      {
        average_entry_price: 0.8495199999999999,
        close_ms: 1717146005965,
        current_return: 1.0002463155664376,
        is_closed_position: true,
        miner_hotkey: "5GhCxfBcA7Ur5iiAS343xwvrYHTUfBjBi4JimiL5LhujRT9t",
        net_leverage: 0,
        open_ms: 1716973212841,
        orders: [
          {
            leverage: 0.07500000000000001,
            order_type: "LONG",
            order_uuid: "79fe3ca2-8fbf-4efc-b4e6-ea6147e2749f",
            price: 0.84952,
            price_sources: [],
            processed_ms: 1716973211693,
          },
          {
            leverage: 0,
            order_type: "FLAT",
            order_uuid: "29711725-8300-4a69-8c2a-1cfd67b4293d",
            price: 0.85231,
            price_sources: [],
            processed_ms: 1717146005965,
          },
        ],
        position_type: "FLAT",
        position_uuid: "3946d545-545c-4070-a39b-d68de2a927ba",
        return_at_close: 1.0002410642732809,
        trade_pair: ["EURGBP", "EUR/GBP", 0.00007, 0.1, 5],
      },
      {
        average_entry_price: 67150.2,
        close_ms: 1717185615285,
        current_return: 1.0008405038257517,
        is_closed_position: true,
        miner_hotkey: "5GhCxfBcA7Ur5iiAS343xwvrYHTUfBjBi4JimiL5LhujRT9t",
        net_leverage: 0,
        open_ms: 1717171218945,
        orders: [
          {
            leverage: 0.1,
            order_type: "LONG",
            order_uuid: "6f721af2-8458-499e-b044-5a172eacce22",
            price: 67150.2,
            price_sources: [],
            processed_ms: 1717171214273,
          },
          {
            leverage: 0,
            order_type: "FLAT",
            order_uuid: "97b7797c-d9a4-4232-8533-22c0138208ff",
            price: 67714.6,
            price_sources: [],
            processed_ms: 1717185615285,
          },
        ],
        position_type: "FLAT",
        position_uuid: "42a28bc5-6a6e-4eb5-b66b-5df7ec1a9fb9",
        return_at_close: 1.000740419775369,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.01, 0.5],
      },
      {
        average_entry_price: 68393.73,
        close_ms: 1717416011765,
        current_return: 1.0005059528117564,
        is_closed_position: true,
        miner_hotkey: "5GhCxfBcA7Ur5iiAS343xwvrYHTUfBjBi4JimiL5LhujRT9t",
        net_leverage: 0,
        open_ms: 1717387211603,
        orders: [
          {
            leverage: 0.05,
            order_type: "LONG",
            order_uuid: "32be93c7-f61e-4455-9565-318512ff538f",
            price: 68393.73,
            price_sources: [],
            processed_ms: 1717387211085,
          },
          {
            leverage: 0,
            order_type: "FLAT",
            order_uuid: "cea3e957-661a-4faa-b8f0-286ee6947174",
            price: 69085.81,
            price_sources: [],
            processed_ms: 1717416011765,
          },
        ],
        position_type: "FLAT",
        position_uuid: "32be93c7-f61e-4455-9565-318512ff538f",
        return_at_close: 1.0004559275141158,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.01, 0.5],
      },
      {
        average_entry_price: 1.0901114285714284,
        close_ms: 1717491623932,
        current_return: 1.000382297274949,
        is_closed_position: true,
        miner_hotkey: "5GhCxfBcA7Ur5iiAS343xwvrYHTUfBjBi4JimiL5LhujRT9t",
        net_leverage: 0,
        open_ms: 1717434015108,
        orders: [
          {
            leverage: -0.045,
            order_type: "SHORT",
            order_uuid: "f78cba0d-5ffd-401c-92b2-8299342acb26",
            price: 1.08842,
            price_sources: [],
            processed_ms: 1717434015090,
          },
          {
            leverage: -0.12000000000000001,
            order_type: "SHORT",
            order_uuid: "0150a508-4959-4b84-831c-0ee4d8a0af67",
            price: 1.0903,
            price_sources: [],
            processed_ms: 1717448411308,
          },
          {
            leverage: -0.04500000000000001,
            order_type: "SHORT",
            order_uuid: "7c2a900b-219c-464d-ba16-db80d4b37b47",
            price: 1.0913,
            price_sources: [],
            processed_ms: 1717462814654,
          },
          {
            leverage: 0,
            order_type: "FLAT",
            order_uuid: "c98a931c-ea0b-4ee0-8add-8a4b5b4899ed",
            price: 1.08813,
            price_sources: [],
            processed_ms: 1717491623932,
          },
        ],
        position_type: "FLAT",
        position_uuid: "f78cba0d-5ffd-401c-92b2-8299342acb26",
        return_at_close: 1.000367591655179,
        trade_pair: ["EURUSD", "EUR/USD", 0.00007, 0.1, 5],
      },
      {
        average_entry_price: 1.2826633333333333,
        close_ms: 1717506059094,
        current_return: 1.0002160409476364,
        is_closed_position: true,
        miner_hotkey: "5GhCxfBcA7Ur5iiAS343xwvrYHTUfBjBi4JimiL5LhujRT9t",
        net_leverage: 0,
        open_ms: 1717448411470,
        orders: [
          {
            leverage: -0.07500000000000001,
            order_type: "SHORT",
            order_uuid: "b847bddb-ee33-4162-bdb4-747f580f4efe",
            price: 1.28066,
            price_sources: [],
            processed_ms: 1717448410907,
          },
          {
            leverage: 0.030000000000000013,
            order_type: "LONG",
            order_uuid: "9c55d4fa-3a35-4f0d-97c6-d0e3629409eb",
            price: 1.277655,
            price_sources: [],
            processed_ms: 1717491623942,
          },
          {
            leverage: 0,
            order_type: "FLAT",
            order_uuid: "802f0c64-dd9a-4da2-b705-15c37a7d1e0e",
            price: 1.276515,
            price_sources: [],
            processed_ms: 1717506059094,
          },
        ],
        position_type: "FLAT",
        position_uuid: "b847bddb-ee33-4162-bdb4-747f580f4efe",
        return_at_close: 1.0002107898134214,
        trade_pair: ["GBPUSD", "GBP/USD", 0.00007, 0.1, 5],
      },
      {
        average_entry_price: 112.82224999999998,
        close_ms: 1717549213400,
        current_return: 1.0003773826671734,
        is_closed_position: true,
        miner_hotkey: "5GhCxfBcA7Ur5iiAS343xwvrYHTUfBjBi4JimiL5LhujRT9t",
        net_leverage: 0,
        open_ms: 1717498813919,
        orders: [
          {
            leverage: 0.06000000000000002,
            order_type: "LONG",
            order_uuid: "d53364c4-66c6-4c49-bbed-5749eb02c923",
            price: 113.161,
            price_sources: [],
            processed_ms: 1717498812715,
          },
          {
            leverage: -0.03000000000000001,
            order_type: "SHORT",
            order_uuid: "c2893b0a-884d-408f-9199-69ab47a66bcc",
            price: 113.43950000000001,
            price_sources: [],
            processed_ms: 1717506059094,
          },
          {
            leverage: 0.03000000000000001,
            order_type: "LONG",
            order_uuid: "359c59b0-0c9d-4261-9599-26d7c085fb28",
            price: 113.031,
            price_sources: [],
            processed_ms: 1717509628959,
          },
          {
            leverage: 0.030000000000000006,
            order_type: "LONG",
            order_uuid: "54f0c26f-a715-4d9b-9d60-f8d43a5d78a2",
            price: 113.097,
            price_sources: [],
            processed_ms: 1717520425546,
          },
          {
            leverage: 0.030000000000000013,
            order_type: "LONG",
            order_uuid: "b34bbdef-ebb8-44d5-975a-b86effff1969",
            price: 113.03399999999999,
            price_sources: [],
            processed_ms: 1717524007325,
          },
          {
            leverage: -0.06000000000000002,
            order_type: "SHORT",
            order_uuid: "79330c09-594e-470b-b53c-10c3015bfb32",
            price: 113.2,
            price_sources: [],
            processed_ms: 1717535004243,
          },
          {
            leverage: 0,
            order_type: "FLAT",
            order_uuid: "81b1fe24-0a14-4590-bf4c-0f16cb70811e",
            price: 113.534,
            price_sources: [],
            processed_ms: 1717549213400,
          },
        ],
        position_type: "FLAT",
        position_uuid: "d53364c4-66c6-4c49-bbed-5749eb02c923",
        return_at_close: 1.0003668787046553,
        trade_pair: ["CADJPY", "CAD/JPY", 0.00007, 0.1, 5],
      },
      {
        average_entry_price: 103.05775,
        close_ms: 1717549214955,
        current_return: 1.0002407760598697,
        is_closed_position: true,
        miner_hotkey: "5GhCxfBcA7Ur5iiAS343xwvrYHTUfBjBi4JimiL5LhujRT9t",
        net_leverage: 0,
        open_ms: 1717491611658,
        orders: [
          {
            leverage: 0.06000000000000002,
            order_type: "LONG",
            order_uuid: "0afa5e2a-53e3-4bf4-b11c-7d6861dcd3cb",
            price: 103.291,
            price_sources: [],
            processed_ms: 1717491611596,
          },
          {
            leverage: 0.06000000000000002,
            order_type: "LONG",
            order_uuid: "a51c6606-0cc9-41cb-a5e7-e0f632085130",
            price: 102.8245,
            price_sources: [],
            processed_ms: 1717520413424,
          },
          {
            leverage: 0,
            order_type: "FLAT",
            order_uuid: "eb142f3f-474a-4d3d-ae67-5803d348cb09",
            price: 103.265,
            price_sources: [],
            processed_ms: 1717549214955,
          },
        ],
        position_type: "FLAT",
        position_uuid: "0afa5e2a-53e3-4bf4-b11c-7d6861dcd3cb",
        return_at_close: 1.0002323740373509,
        trade_pair: ["AUDJPY", "AUD/JPY", 0.00007, 0.1, 5],
      },
      {
        average_entry_price: 168.4834285714286,
        close_ms: 1717549214957,
        current_return: 1.0006480733948093,
        is_closed_position: true,
        miner_hotkey: "5GhCxfBcA7Ur5iiAS343xwvrYHTUfBjBi4JimiL5LhujRT9t",
        net_leverage: 0,
        open_ms: 1717491623670,
        orders: [
          {
            leverage: 0.03000000000000001,
            order_type: "LONG",
            order_uuid: "61288f53-01ae-4a2f-a44a-52b042eed8b3",
            price: 169.004,
            price_sources: [],
            processed_ms: 1717491623635,
          },
          {
            leverage: 0.03000000000000001,
            order_type: "LONG",
            order_uuid: "5d8dbe31-f078-4cde-9ca6-2d52bda5f421",
            price: 168.14350000000002,
            price_sources: [],
            processed_ms: 1717497908159,
          },
          {
            leverage: -0.03000000000000001,
            order_type: "SHORT",
            order_uuid: "338175a1-f7be-466f-97d0-7c8d15d204fb",
            price: 168.573,
            price_sources: [],
            processed_ms: 1717499707099,
          },
          {
            leverage: 0.18000000000000005,
            order_type: "LONG",
            order_uuid: "bcf27f6a-4731-47a0-90a2-79f76002dbb4",
            price: 168.56,
            price_sources: [],
            processed_ms: 1717506059349,
          },
          {
            leverage: 0.02999999999999997,
            order_type: "LONG",
            order_uuid: "5b236b69-39d0-4ff6-8475-951c0eac6233",
            price: 168.0815,
            price_sources: [],
            processed_ms: 1717509628938,
          },
          {
            leverage: -0.02999999999999997,
            order_type: "SHORT",
            order_uuid: "c31f1048-c7be-4aa9-a378-a1f0277353c5",
            price: 168.632,
            price_sources: [],
            processed_ms: 1717510511502,
          },
          {
            leverage: 0,
            order_type: "FLAT",
            order_uuid: "d853c81c-8a4e-4995-bea3-f2c06ca369b7",
            price: 169.00498569531595,
            price_sources: [],
            processed_ms: 1717549214957,
          },
        ],
        position_type: "FLAT",
        position_uuid: "61288f53-01ae-4a2f-a44a-52b042eed8b3",
        return_at_close: 1.0006291611462221,
        trade_pair: ["EURJPY", "EUR/JPY", 0.00007, 0.1, 5],
      },
      {
        average_entry_price: 0.9647400000000004,
        close_ms: 1717592409461,
        current_return: 1.0002806792546124,
        is_closed_position: true,
        miner_hotkey: "5GhCxfBcA7Ur5iiAS343xwvrYHTUfBjBi4JimiL5LhujRT9t",
        net_leverage: 0,
        open_ms: 1717506059183,
        orders: [
          {
            leverage: 0.045,
            order_type: "LONG",
            order_uuid: "15e5a8e0-c452-4d0f-a6de-e98184921897",
            price: 0.97077,
            price_sources: [],
            processed_ms: 1717506059093,
          },
          {
            leverage: 0.08999999999999998,
            order_type: "LONG",
            order_uuid: "71213b64-9b57-4cdc-aa27-5be37ac6a7cf",
            price: 0.967545,
            price_sources: [],
            processed_ms: 1717520425923,
          },
          {
            leverage: 0.060000000000000026,
            order_type: "LONG",
            order_uuid: "c5556a9e-2a88-40d5-be9c-49adf4680465",
            price: 0.96825,
            price_sources: [],
            processed_ms: 1717534810997,
          },
          {
            leverage: -0.09000000000000001,
            order_type: "SHORT",
            order_uuid: "47dd49b3-7dde-441a-83ce-195187df87e2",
            price: 0.9691799999999999,
            price_sources: [],
            processed_ms: 1717549214532,
          },
          {
            leverage: -0.06,
            order_type: "SHORT",
            order_uuid: "d8d24cfe-3ba2-42ea-9d36-72ef3b70a177",
            price: 0.97032,
            price_sources: [],
            processed_ms: 1717578006438,
          },
          {
            leverage: 0,
            order_type: "FLAT",
            order_uuid: "9defd73d-41d9-4087-bf7b-a997afd9e07e",
            price: 0.9707950000000001,
            price_sources: [],
            processed_ms: 1717592409461,
          },
        ],
        position_type: "FLAT",
        position_uuid: "15e5a8e0-c452-4d0f-a6de-e98184921897",
        return_at_close: 1.0002670254233406,
        trade_pair: ["EURCHF", "EUR/CHF", 0.00007, 0.1, 5],
      },
      {
        average_entry_price: 0.91193645799308,
        close_ms: 1717650012082,
        current_return: 1.00005766572783,
        is_closed_position: true,
        miner_hotkey: "5GhCxfBcA7Ur5iiAS343xwvrYHTUfBjBi4JimiL5LhujRT9t",
        net_leverage: 0,
        open_ms: 1717635615925,
        orders: [
          {
            leverage: -0.06000000000000002,
            order_type: "SHORT",
            order_uuid: "801587d3-1e28-4cfb-8174-afcd68e9af15",
            price: 0.91193645799308,
            price_sources: [],
            processed_ms: 1717635613405,
          },
          {
            leverage: 0,
            order_type: "FLAT",
            order_uuid: "57fa466c-f9c1-40d5-83a5-310a810680a6",
            price: 0.91106,
            price_sources: [],
            processed_ms: 1717650012082,
          },
        ],
        position_type: "FLAT",
        position_uuid: "801587d3-1e28-4cfb-8174-afcd68e9af15",
        return_at_close: 1.000053465485634,
        trade_pair: ["AUDCAD", "AUD/CAD", 0.00007, 0.1, 5],
      },
      {
        average_entry_price: 0.6518349999999999,
        close_ms: 1717765210869,
        current_return: 1.0000844165774583,
        is_closed_position: true,
        miner_hotkey: "5GhCxfBcA7Ur5iiAS343xwvrYHTUfBjBi4JimiL5LhujRT9t",
        net_leverage: 0,
        open_ms: 1717491611657,
        orders: [
          {
            leverage: 0.06000000000000002,
            order_type: "LONG",
            order_uuid: "1472c1dd-e776-473e-ac8e-a416ddb9e284",
            price: 0.6539,
            price_sources: [],
            processed_ms: 1717491611597,
          },
          {
            leverage: 0.06000000000000002,
            order_type: "LONG",
            order_uuid: "4149b4e4-7a55-4722-89f2-4c0ed470eeb8",
            price: 0.64977,
            price_sources: [],
            processed_ms: 1717520425264,
          },
          {
            leverage: 0,
            order_type: "FLAT",
            order_uuid: "b73f3826-d3c8-4149-a3cc-522746f5535f",
            price: 0.6522950000000001,
            price_sources: [],
            processed_ms: 1717765210869,
          },
        ],
        position_type: "FLAT",
        position_uuid: "1472c1dd-e776-473e-ac8e-a416ddb9e284",
        return_at_close: 1.000076015868359,
        trade_pair: ["CADCHF", "CAD/CHF", 0.00007, 0.1, 5],
      },
      {
        average_entry_price: 0.912106872063221,
        close_ms: 1717765218305,
        current_return: 1.0003799660735075,
        is_closed_position: true,
        miner_hotkey: "5GhCxfBcA7Ur5iiAS343xwvrYHTUfBjBi4JimiL5LhujRT9t",
        net_leverage: 0,
        open_ms: 1717736424084,
        orders: [
          {
            leverage: -0.06000000000000002,
            order_type: "SHORT",
            order_uuid: "81911656-5cef-41fe-9dbe-fc3f2b7bd492",
            price: 0.912106872063221,
            price_sources: [],
            processed_ms: 1717736424068,
          },
          {
            leverage: 0,
            order_type: "FLAT",
            order_uuid: "f60c0e7e-a336-480e-81c8-e6f2d6e47f0f",
            price: 0.906330710949937,
            price_sources: [],
            processed_ms: 1717765218305,
          },
        ],
        position_type: "FLAT",
        position_uuid: "81911656-5cef-41fe-9dbe-fc3f2b7bd492",
        return_at_close: 1.00037576447765,
        trade_pair: ["AUDCAD", "AUD/CAD", 0.00007, 0.1, 5],
      },
      {
        average_entry_price: 1.4896366666666672,
        close_ms: 1717779610717,
        current_return: 1.0005443595167078,
        is_closed_position: true,
        miner_hotkey: "5GhCxfBcA7Ur5iiAS343xwvrYHTUfBjBi4JimiL5LhujRT9t",
        net_leverage: 0,
        open_ms: 1717462809380,
        orders: [
          {
            leverage: -0.06000000000000002,
            order_type: "SHORT",
            order_uuid: "57edf8f0-87e3-4eba-a114-4dae87be7020",
            price: 1.486885,
            price_sources: [],
            processed_ms: 1717462807791,
          },
          {
            leverage: -0.06000000000000002,
            order_type: "SHORT",
            order_uuid: "7b415a8c-696a-4ccd-96f9-5d1482dd4cee",
            price: 1.4888,
            price_sources: [],
            processed_ms: 1717520413927,
          },
          {
            leverage: -0.06000000000000001,
            order_type: "SHORT",
            order_uuid: "9359ac10-c71a-43f3-b2ef-e6ca8ee6051d",
            price: 1.48865,
            price_sources: [],
            processed_ms: 1717635613408,
          },
          {
            leverage: -0.05999999999999997,
            order_type: "SHORT",
            order_uuid: "a752daa4-e15e-44e9-8d70-54a5d191b48e",
            price: 1.490805,
            price_sources: [],
            processed_ms: 1717678808724,
          },
          {
            leverage: 0.06,
            order_type: "LONG",
            order_uuid: "4508dfe5-941d-41af-b827-1b2459f463e1",
            price: 1.48623,
            price_sources: [],
            processed_ms: 1717765210454,
          },
          {
            leverage: 0,
            order_type: "FLAT",
            order_uuid: "688df19b-1c9f-4302-b40c-3b165382ca17",
            price: 1.48514,
            price_sources: [],
            processed_ms: 1717779610717,
          },
        ],
        position_type: "FLAT",
        position_uuid: "57edf8f0-87e3-4eba-a114-4dae87be7020",
        return_at_close: 1.000527550371468,
        trade_pair: ["EURCAD", "EUR/CAD", 0.00007, 0.1, 5],
      },
      {
        average_entry_price: 53663.5200000009,
        close_ms: 1718204664615,
        current_return: 1.010804002826535,
        is_closed_position: true,
        miner_hotkey: "5GhCxfBcA7Ur5iiAS343xwvrYHTUfBjBi4JimiL5LhujRT9t",
        net_leverage: 0,
        open_ms: 1718082010421,
        orders: [
          {
            leverage: 0.1,
            order_type: "LONG",
            order_uuid: "d3a279ea-c8f9-4e1d-ab4d-d3582bd89f53",
            price: 67955.98,
            price_sources: [],
            processed_ms: 1718082010336,
          },
          {
            leverage: 0.1,
            order_type: "LONG",
            order_uuid: "ef4a7ac5-bec4-4e39-b4f6-9a1aa489ec8a",
            price: 67608.21,
            price_sources: [],
            processed_ms: 1718089210431,
          },
          {
            leverage: 0.09999999999999998,
            order_type: "LONG",
            order_uuid: "b5c10075-abdc-4ae4-ad49-a84fa1de5d38",
            price: 67469.23,
            price_sources: [],
            processed_ms: 1718096416728,
          },
          {
            leverage: 0.2,
            order_type: "LONG",
            order_uuid: "bd596f8b-0b3e-4451-ad1c-8a3fb30117aa",
            price: 67023.29,
            price_sources: [],
            processed_ms: 1718100010523,
          },
          {
            leverage: 0.09999999999999998,
            order_type: "LONG",
            order_uuid: "7dd031b7-3747-4069-a87c-682e1761d2c0",
            price: 66867.83,
            price_sources: [],
            processed_ms: 1718103613772,
          },
          {
            leverage: 0.020000000000000018,
            order_type: "LONG",
            order_uuid: "9947c22c-178b-4117-8cde-d677cd500819",
            price: 66854,
            price_sources: [],
            processed_ms: 1718104207890,
          },
          {
            leverage: 0.15000000000000002,
            order_type: "LONG",
            order_uuid: "fd2d4aa2-e671-4684-bc37-b7a7449b61ac",
            price: 66782.37,
            price_sources: [],
            processed_ms: 1718107216617,
          },
          {
            leverage: 0.025000000000000022,
            order_type: "LONG",
            order_uuid: "a661ffd1-c86f-425e-ab68-6f9c4b6ccc2c",
            price: 66964.7,
            price_sources: [],
            processed_ms: 1718110813818,
          },
          {
            leverage: 0.20000000000000007,
            order_type: "LONG",
            order_uuid: "3906d715-4c4b-4dba-b98f-9959e1c4f266",
            price: 66762.08,
            price_sources: [],
            processed_ms: 1718114414857,
          },
          {
            leverage: 0.09999999999999987,
            order_type: "LONG",
            order_uuid: "80bced4b-1b7e-4db5-9482-0a7da54bdcc9",
            price: 66823.78,
            price_sources: [],
            processed_ms: 1718114455580,
          },
          {
            leverage: 0.21499999999999986,
            order_type: "LONG",
            order_uuid: "d4de71e2-d6f6-4f2c-a54f-af7ef9f2d837",
            price: 66210.02,
            price_sources: [],
            processed_ms: 1718121612749,
          },
          {
            leverage: 0.10000000000000031,
            order_type: "LONG",
            order_uuid: "5ae1dbf9-a6e1-4c61-928f-34d2631f18bf",
            price: 66244.01,
            price_sources: [],
            processed_ms: 1718121639260,
          },
          {
            leverage: 0.014999999999999902,
            order_type: "LONG",
            order_uuid: "e43ec30c-264c-47a2-9ff2-48314b7dc328",
            price: 66291.99,
            price_sources: [],
            processed_ms: 1718121656505,
          },
          {
            leverage: 0.025000000000000133,
            order_type: "LONG",
            order_uuid: "9a3e57db-1c3d-4492-b836-1e7021b06f27",
            price: 66297,
            price_sources: [],
            processed_ms: 1718121717252,
          },
          {
            leverage: 0.02499999999999969,
            order_type: "LONG",
            order_uuid: "fa4bfd23-6ace-4d61-baa5-db198c235d64",
            price: 66603.01,
            price_sources: [],
            processed_ms: 1718122511984,
          },
          {
            leverage: -0.01499999999999968,
            order_type: "SHORT",
            order_uuid: "7c6f1403-88b3-4f72-8ff0-b8b83be631ba",
            price: 66752.57,
            price_sources: [],
            processed_ms: 1718128816131,
          },
          {
            leverage: -0.050000000000000044,
            order_type: "SHORT",
            order_uuid: "e320616e-db31-4a21-a43f-f1929d781bb3",
            price: 67173.41,
            price_sources: [],
            processed_ms: 1718131508573,
          },
          {
            leverage: -0.31500000000000017,
            order_type: "SHORT",
            order_uuid: "0c39157e-5180-4ab6-8e9d-0e1174704a10",
            price: 67126.43,
            price_sources: [],
            processed_ms: 1718132413276,
          },
          {
            leverage: -1,
            order_type: "SHORT",
            order_uuid: "8c1a3ee7-62f7-48d0-b7ed-5e5aba555ebd",
            price: 67349.8,
            price_sources: [],
            processed_ms: 1718136021122,
          },
          {
            leverage: -0.049999999999999996,
            order_type: "SHORT",
            order_uuid: "611a5c18-f8da-41ff-ade6-7dc6c8f74715",
            price: 67302.45,
            price_sources: [],
            processed_ms: 1718136056885,
          },
          {
            leverage: 0,
            order_type: "FLAT",
            order_uuid: "3903d4c1-c2e3-4fbe-9a4f-96e9b5b0eb41",
            price: 69979,
            price_sources: [],
            processed_ms: 1718204664615,
          },
        ],
        position_type: "FLAT",
        position_uuid: "d3a279ea-c8f9-4e1d-ab4d-d3582bd89f53",
        return_at_close: 1.009313066922366,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.01, 0.5],
      },
      {
        average_entry_price: 3514.957047619048,
        close_ms: 1718204783045,
        current_return: 1.017181709348324,
        is_closed_position: true,
        miner_hotkey: "5GhCxfBcA7Ur5iiAS343xwvrYHTUfBjBi4JimiL5LhujRT9t",
        net_leverage: 0,
        open_ms: 1718006413780,
        orders: [
          {
            leverage: 0.025,
            order_type: "LONG",
            order_uuid: "2b1353a4-dd38-4497-8884-da8cc0b1a9bb",
            price: 3650.28,
            price_sources: [],
            processed_ms: 1718006413497,
          },
          {
            leverage: 0.07500000000000001,
            order_type: "LONG",
            order_uuid: "ea8e6dd3-cc72-4881-9093-56984b671f0e",
            price: 3674.16,
            price_sources: [],
            processed_ms: 1718020813147,
          },
          {
            leverage: 0.065,
            order_type: "LONG",
            order_uuid: "633fbccc-1e31-4e56-be07-f0a2179d4a60",
            price: 3529.91,
            price_sources: [],
            processed_ms: 1718092814370,
          },
          {
            leverage: 0.07500000000000001,
            order_type: "LONG",
            order_uuid: "f9f485c4-cdba-4f99-ae5d-6667f39fdde3",
            price: 3525.37,
            price_sources: [],
            processed_ms: 1718107212281,
          },
          {
            leverage: 0.014999999999999986,
            order_type: "LONG",
            order_uuid: "8aa4bbfe-adcd-4510-bf4f-5e89fcbaa7e4",
            price: 3518.6,
            price_sources: [],
            processed_ms: 1718114423208,
          },
          {
            leverage: 0.09000000000000002,
            order_type: "LONG",
            order_uuid: "3e583fce-a253-4a2a-a8bb-f20bd1ce19b3",
            price: 3452.37,
            price_sources: [],
            processed_ms: 1718121621472,
          },
          {
            leverage: 0.10000000000000003,
            order_type: "LONG",
            order_uuid: "9e46743a-548a-429f-be6f-452ce1907730",
            price: 3470.04,
            price_sources: [],
            processed_ms: 1718128815518,
          },
          {
            leverage: 0.015000000000000013,
            order_type: "LONG",
            order_uuid: "ff9f1b3f-986b-4f4e-8a15-c5c2cfb03623",
            price: 3500.81,
            price_sources: [],
            processed_ms: 1718143208380,
          },
          {
            leverage: 0.14,
            order_type: "LONG",
            order_uuid: "b692d19a-990e-440b-8ace-168e81b573ed",
            price: 3496.59,
            price_sources: [],
            processed_ms: 1718150412456,
          },
          {
            leverage: -0.04500000000000004,
            order_type: "SHORT",
            order_uuid: "697cbacb-9bc4-4529-8554-430735abf18f",
            price: 3525.7,
            price_sources: [],
            processed_ms: 1718179212502,
          },
          {
            leverage: -0.030000000000000027,
            order_type: "SHORT",
            order_uuid: "6a80d2aa-53b1-4349-89c4-e61653d689fa",
            price: 3639.6,
            price_sources: [],
            processed_ms: 1718197231956,
          },
          {
            leverage: 0,
            order_type: "FLAT",
            order_uuid: "a03a74ae-e3df-46e8-937e-b69a19a9fc99",
            price: 3634.42,
            price_sources: [],
            processed_ms: 1718204783045,
          },
        ],
        position_type: "FLAT",
        position_uuid: "2b1353a4-dd38-4497-8884-da8cc0b1a9bb",
        return_at_close: 1.0165714003227149,
        trade_pair: ["ETHUSD", "ETH/USD", 0.003, 0.01, 0.5],
      },
      {
        average_entry_price: 69814.68,
        close_ms: 1718209815462,
        current_return: 1.0003048642491807,
        is_closed_position: true,
        miner_hotkey: "5GhCxfBcA7Ur5iiAS343xwvrYHTUfBjBi4JimiL5LhujRT9t",
        net_leverage: 0,
        open_ms: 1718205311551,
        orders: [
          {
            leverage: -0.05,
            order_type: "SHORT",
            order_uuid: "396a413d-a249-4228-9fb6-7ecc27fcb176",
            price: 69814.68,
            price_sources: [],
            processed_ms: 1718205309765,
          },
          {
            leverage: 0,
            order_type: "FLAT",
            order_uuid: "cd05a837-9b63-4d84-963f-f8436a608488",
            price: 69389,
            price_sources: [],
            processed_ms: 1718209815462,
          },
        ],
        position_type: "FLAT",
        position_uuid: "396a413d-a249-4228-9fb6-7ecc27fcb176",
        return_at_close: 1.0002548490059684,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.01, 0.5],
      },
      {
        average_entry_price: 0.9645364705882354,
        close_ms: 1718211621310,
        current_return: 1.0009306320102644,
        is_closed_position: true,
        miner_hotkey: "5GhCxfBcA7Ur5iiAS343xwvrYHTUfBjBi4JimiL5LhujRT9t",
        net_leverage: 0,
        open_ms: 1717981215559,
        orders: [
          {
            leverage: 0.09,
            order_type: "LONG",
            order_uuid: "29c7aa85-b21c-4a29-b81f-17d3b3fc82a6",
            price: 0.96644,
            price_sources: [],
            processed_ms: 1717981215551,
          },
          {
            leverage: 0.044999999999999984,
            order_type: "LONG",
            order_uuid: "6ea0a4d8-c338-4fe0-8ac9-5fa1dd1c61ea",
            price: 0.96641,
            price_sources: [],
            processed_ms: 1717981251992,
          },
          {
            leverage: 0.15000000000000005,
            order_type: "LONG",
            order_uuid: "6dd59fe6-cacc-4670-8b66-778615d10754",
            price: 0.96481,
            price_sources: [],
            processed_ms: 1718010008210,
          },
          {
            leverage: 0.044999999999999984,
            order_type: "LONG",
            order_uuid: "a1accf04-b908-4d0b-9fea-274ddcd66319",
            price: 0.96484,
            price_sources: [],
            processed_ms: 1718010025603,
          },
          {
            leverage: 0.09000000000000002,
            order_type: "LONG",
            order_uuid: "c6ca1380-41ca-4277-86ec-c3cfa3c7ac0f",
            price: 0.9633,
            price_sources: [],
            processed_ms: 1718024408739,
          },
          {
            leverage: -0.04500000000000004,
            order_type: "SHORT",
            order_uuid: "4cfe791c-59e7-4773-b716-eacef4450186",
            price: 0.96543,
            price_sources: [],
            processed_ms: 1718067614095,
          },
          {
            leverage: 0.08999999999999997,
            order_type: "LONG",
            order_uuid: "6945f973-fb09-44bd-b9ec-8ca5a0e177c6",
            price: 0.96359,
            price_sources: [],
            processed_ms: 1718096417804,
          },
          {
            leverage: 0.04499999999999993,
            order_type: "LONG",
            order_uuid: "3f2a950e-daf1-481a-b61b-cb11f81c7ff1",
            price: 0.9629,
            price_sources: [],
            processed_ms: 1718110818704,
          },
          {
            leverage: 0,
            order_type: "FLAT",
            order_uuid: "f0cd798a-c739-4d62-9514-fd5a4f4b59b0",
            price: 0.9663,
            price_sources: [],
            processed_ms: 1718211621310,
          },
        ],
        position_type: "FLAT",
        position_uuid: "29c7aa85-b21c-4a29-b81f-17d3b3fc82a6",
        return_at_close: 1.000891745855211,
        trade_pair: ["EURCHF", "EUR/CHF", 0.00007, 0.1, 5],
      },
      {
        average_entry_price: 155.796,
        close_ms: 1718226009875,
        current_return: 1.0001211841125575,
        is_closed_position: true,
        miner_hotkey: "5GhCxfBcA7Ur5iiAS343xwvrYHTUfBjBi4JimiL5LhujRT9t",
        net_leverage: 0,
        open_ms: 1718211621370,
        orders: [
          {
            leverage: 0.020000000000000004,
            order_type: "LONG",
            order_uuid: "94f9c168-ddbe-475a-b46b-6a6ae5f6953e",
            price: 155.796,
            price_sources: [],
            processed_ms: 1718211621252,
          },
          {
            leverage: 0,
            order_type: "FLAT",
            order_uuid: "a28c5337-c8bd-4646-8f15-679ce01b3d7c",
            price: 156.74,
            price_sources: [],
            processed_ms: 1718226009875,
          },
        ],
        position_type: "FLAT",
        position_uuid: "94f9c168-ddbe-475a-b46b-6a6ae5f6953e",
        return_at_close: 1.0001197839428997,
        trade_pair: ["USDJPY", "USD/JPY", 0.00007, 0.1, 5],
      },
      {
        average_entry_price: 1.4746,
        close_ms: 1718326814467,
        current_return: 1.0000195307201953,
        is_closed_position: true,
        miner_hotkey: "5GhCxfBcA7Ur5iiAS343xwvrYHTUfBjBi4JimiL5LhujRT9t",
        net_leverage: 0,
        open_ms: 1718312707549,
        orders: [
          {
            leverage: 0.020000000000000004,
            order_type: "LONG",
            order_uuid: "bda60332-b9fa-44e8-b700-492e537b874a",
            price: 1.4746,
            price_sources: [],
            processed_ms: 1718312704857,
          },
          {
            leverage: 0,
            order_type: "FLAT",
            order_uuid: "a3484707-b26b-4614-844c-6bb5aa08b1a8",
            price: 1.47604,
            price_sources: [],
            processed_ms: 1718326814467,
          },
        ],
        position_type: "FLAT",
        position_uuid: "bda60332-b9fa-44e8-b700-492e537b874a",
        return_at_close: 1.0000181306928524,
        trade_pair: ["EURCAD", "EUR/CAD", 0.00007, 0.1, 5],
      },
      {
        average_entry_price: 168.11149999999998,
        close_ms: 1718384422575,
        current_return: 1.000012937841849,
        is_closed_position: true,
        miner_hotkey: "5GhCxfBcA7Ur5iiAS343xwvrYHTUfBjBi4JimiL5LhujRT9t",
        net_leverage: 0,
        open_ms: 1718370021187,
        orders: [
          {
            leverage: 0.010000000000000002,
            order_type: "LONG",
            order_uuid: "d38800db-f4b5-4964-b3dd-ffdbb01e1790",
            price: 168.11149999999998,
            price_sources: [],
            processed_ms: 1718370020745,
          },
          {
            leverage: 0,
            order_type: "FLAT",
            order_uuid: "414cc363-7cdc-4683-8e7f-9a964bc054d1",
            price: 168.329,
            price_sources: [],
            processed_ms: 1718384422575,
          },
        ],
        position_type: "FLAT",
        position_uuid: "d38800db-f4b5-4964-b3dd-ffdbb01e1790",
        return_at_close: 1.0000122378327925,
        trade_pair: ["EURJPY", "EUR/JPY", 0.00007, 0.1, 5],
      },
      {
        average_entry_price: 65747.93,
        close_ms: 1718402406865,
        current_return: 1.0000320671694234,
        is_closed_position: true,
        miner_hotkey: "5GhCxfBcA7Ur5iiAS343xwvrYHTUfBjBi4JimiL5LhujRT9t",
        net_leverage: 0,
        open_ms: 1718398809300,
        orders: [
          {
            leverage: 0.0050000000250000004,
            order_type: "LONG",
            order_uuid: "efeae704-6a79-4409-9c90-5466c6925d41",
            price: 65747.93,
            price_sources: [],
            processed_ms: 1718398808683,
          },
          {
            leverage: 0,
            order_type: "FLAT",
            order_uuid: "f1b84855-a84d-440f-8d24-6a3e6f6d1613",
            price: 66169.6,
            price_sources: [],
            processed_ms: 1718402406865,
          },
        ],
        position_type: "FLAT",
        position_uuid: "efeae704-6a79-4409-9c90-5466c6925d41",
        return_at_close: 1.0000270670090625,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.01, 0.5],
      },
      {
        average_entry_price: 3420.617466666667,
        close_ms: 1718589785316,
        current_return: 1.0064106403767332,
        is_closed_position: true,
        miner_hotkey: "5GhCxfBcA7Ur5iiAS343xwvrYHTUfBjBi4JimiL5LhujRT9t",
        net_leverage: 0,
        open_ms: 1718280018790,
        orders: [
          {
            leverage: 0.10833333387500002,
            order_type: "LONG",
            order_uuid: "07745e35-b046-4109-a4e0-83c241c8e0bb",
            price: 3496.19,
            price_sources: [],
            processed_ms: 1718280015637,
          },
          {
            leverage: 0.005000000024999993,
            order_type: "LONG",
            order_uuid: "9b0b10d4-ec8d-4b46-a047-727f67886655",
            price: 3468.73,
            price_sources: [],
            processed_ms: 1718298010874,
          },
          {
            leverage: 0.00500000002500002,
            order_type: "LONG",
            order_uuid: "dbbe36bf-fef6-4247-a89c-95f66a630b75",
            price: 3473.05,
            price_sources: [],
            processed_ms: 1718305212967,
          },
          {
            leverage: 0.061666666975000006,
            order_type: "LONG",
            order_uuid: "73a9070a-63d4-451d-95ad-6e7c320a024f",
            price: 3467.75,
            price_sources: [],
            processed_ms: 1718323210733,
          },
          {
            leverage: 0.005000000024999979,
            order_type: "LONG",
            order_uuid: "4752e045-f68f-4c75-8bbc-4448f08906df",
            price: 3472.59,
            price_sources: [],
            processed_ms: 1718326815182,
          },
          {
            leverage: -0.01500000007500002,
            order_type: "SHORT",
            order_uuid: "033e290f-d171-415b-9997-9d6ca03c10db",
            price: 3520.33,
            price_sources: [],
            processed_ms: 1718344812447,
          },
          {
            leverage: -0.006666666700000018,
            order_type: "SHORT",
            order_uuid: "3fee5e6c-8daf-411a-9eb8-e63ca345fa60",
            price: 3514.11,
            price_sources: [],
            processed_ms: 1718366413383,
          },
          {
            leverage: 0.005000000025000034,
            order_type: "LONG",
            order_uuid: "5af446ea-f9fc-428c-beb8-ae105961a402",
            price: 3463.55,
            price_sources: [],
            processed_ms: 1718380821192,
          },
          {
            leverage: 0.02500000012499995,
            order_type: "LONG",
            order_uuid: "4c4fcdd1-9872-4d72-b29c-30dcab272034",
            price: 3402.94,
            price_sources: [],
            processed_ms: 1718395221403,
          },
          {
            leverage: -0.010000000049999985,
            order_type: "SHORT",
            order_uuid: "f54866e0-b625-48eb-b4fa-9921cf81d537",
            price: 3527.89,
            price_sources: [],
            processed_ms: 1718438416225,
          },
          {
            leverage: -0.025000000124999977,
            order_type: "SHORT",
            order_uuid: "fc97eaaa-d31d-46b1-ba43-690a7ec10373",
            price: 3562.02,
            price_sources: [],
            processed_ms: 1718467208634,
          },
          {
            leverage: -0.033333333500000006,
            order_type: "SHORT",
            order_uuid: "d86aeb8d-e9b1-4303-ad68-a02de522d93c",
            price: 3567.68,
            price_sources: [],
            processed_ms: 1718496008006,
          },
          {
            leverage: 0,
            order_type: "FLAT",
            order_uuid: "32ae6407-dcb5-4845-a2a1-7286cec44172",
            price: 3599.92,
            price_sources: [],
            processed_ms: 1718589785316,
          },
        ],
        position_type: "FLAT",
        position_uuid: "07745e35-b046-4109-a4e0-83c241c8e0bb",
        return_at_close: 1.0061942620879702,
        trade_pair: ["ETHUSD", "ETH/USD", 0.003, 0.01, 0.5],
      },
      {
        average_entry_price: 0.8441291428571425,
        close_ms: 1718614820196,
        current_return: 1.0001485690731364,
        is_closed_position: true,
        miner_hotkey: "5GhCxfBcA7Ur5iiAS343xwvrYHTUfBjBi4JimiL5LhujRT9t",
        net_leverage: 0,
        open_ms: 1717779615965,
        orders: [
          {
            leverage: 0.07500000000000001,
            order_type: "LONG",
            order_uuid: "a7470bbb-5521-4407-8660-f04f94ea16b9",
            price: 0.8491,
            price_sources: [],
            processed_ms: 1717779615955,
          },
          {
            leverage: 0.045,
            order_type: "LONG",
            order_uuid: "f71c01b6-6d91-4df7-9bb5-77725f657cba",
            price: 0.84603,
            price_sources: [],
            processed_ms: 1717966821368,
          },
          {
            leverage: 0.045,
            order_type: "LONG",
            order_uuid: "0120f4e2-0d40-4871-9b20-ab2faf170b27",
            price: 0.84699,
            price_sources: [],
            processed_ms: 1717981215589,
          },
          {
            leverage: 0.07500000000000001,
            order_type: "LONG",
            order_uuid: "4c4cf44f-cd44-44a1-b76b-b96bc57604c0",
            price: 0.84645,
            price_sources: [],
            processed_ms: 1718010008483,
          },
          {
            leverage: 0.04500000000000001,
            order_type: "LONG",
            order_uuid: "b9a8f179-8a50-48c5-851c-b42c893539a0",
            price: 0.84494,
            price_sources: [],
            processed_ms: 1718024408691,
          },
          {
            leverage: 0.07500000000000001,
            order_type: "LONG",
            order_uuid: "f0026964-9934-43a9-b0b5-01ff5fc7d12e",
            price: 0.84551,
            price_sources: [],
            processed_ms: 1718053209136,
          },
          {
            leverage: 0.07500000000000007,
            order_type: "LONG",
            order_uuid: "53628945-81b6-4997-91ba-c65cd564fcea",
            price: 0.84439,
            price_sources: [],
            processed_ms: 1718096406442,
          },
          {
            leverage: 0.04499999999999993,
            order_type: "LONG",
            order_uuid: "43f7c809-b8be-4947-bfaa-298a0e3159eb",
            price: 0.8443700000000001,
            price_sources: [],
            processed_ms: 1718096417782,
          },
          {
            leverage: 0.12000000000000005,
            order_type: "LONG",
            order_uuid: "d6e595ef-f035-44d6-929e-537d760f8f05",
            price: 0.84215,
            price_sources: [],
            processed_ms: 1718110817582,
          },
          {
            leverage: -0.4750000000000001,
            order_type: "SHORT",
            order_uuid: "d74ebdff-9794-401f-9371-5c0a8c16a3c2",
            price: 0.84524,
            price_sources: [],
            processed_ms: 1718254807569,
          },
          {
            leverage: 0.024999999999999994,
            order_type: "LONG",
            order_uuid: "98de0787-42b0-46bd-840a-0b68211edf3c",
            price: 0.84123,
            price_sources: [],
            processed_ms: 1718312410537,
          },
          {
            leverage: 0.024999999999999994,
            order_type: "LONG",
            order_uuid: "24fff8c6-194d-4fb5-b431-89d4371210e8",
            price: 0.84037,
            price_sources: [],
            processed_ms: 1718355620185,
          },
          {
            leverage: 0,
            order_type: "FLAT",
            order_uuid: "bc15026b-cbaa-49c1-994d-638bae4e39b1",
            price: 0.84485,
            price_sources: [],
            processed_ms: 1718614820196,
          },
        ],
        position_type: "FLAT",
        position_uuid: "a7470bbb-5521-4407-8660-f04f94ea16b9",
        return_at_close: 1.0001030623132436,
        trade_pair: ["EURGBP", "EUR/GBP", 0.00007, 0.1, 5],
      },
      {
        average_entry_price: 0.64837,
        close_ms: 1718614820455,
        current_return: 1.0000088683930473,
        is_closed_position: true,
        miner_hotkey: "5GhCxfBcA7Ur5iiAS343xwvrYHTUfBjBi4JimiL5LhujRT9t",
        net_leverage: 0,
        open_ms: 1718355607701,
        orders: [
          {
            leverage: 0.010000000000000002,
            order_type: "LONG",
            order_uuid: "d65b6feb-47b7-4ef1-a9b7-f29a3087d0b2",
            price: 0.64837,
            price_sources: [],
            processed_ms: 1718355607610,
          },
          {
            leverage: 0,
            order_type: "FLAT",
            order_uuid: "590aa961-1bc4-4984-9a8c-7a76d41c7650",
            price: 0.6489450000000001,
            price_sources: [],
            processed_ms: 1718614820455,
          },
        ],
        position_type: "FLAT",
        position_uuid: "d65b6feb-47b7-4ef1-a9b7-f29a3087d0b2",
        return_at_close: 1.0000081683868394,
        trade_pair: ["CADCHF", "CAD/CHF", 0.00007, 0.1, 5],
      },
      {
        average_entry_price: 0.952694,
        close_ms: 1718614821029,
        current_return: 1.0001836709020002,
        is_closed_position: true,
        miner_hotkey: "5GhCxfBcA7Ur5iiAS343xwvrYHTUfBjBi4JimiL5LhujRT9t",
        net_leverage: 0,
        open_ms: 1718370012985,
        orders: [
          {
            leverage: 0.015,
            order_type: "LONG",
            order_uuid: "32404998-417d-4e7d-90c1-a1b4f76af68a",
            price: 0.95388,
            price_sources: [],
            processed_ms: 1718370012846,
          },
          {
            leverage: 0.03,
            order_type: "LONG",
            order_uuid: "c2f9fac8-8f11-410e-a3e6-18c01dbed895",
            price: 0.9540299999999999,
            price_sources: [],
            processed_ms: 1718370050627,
          },
          {
            leverage: 0.015,
            order_type: "LONG",
            order_uuid: "d7fb0f61-ab67-41df-b0f6-9a3afc3a41da",
            price: 0.95287,
            price_sources: [],
            processed_ms: 1718571847798,
          },
          {
            leverage: 0.045,
            order_type: "LONG",
            order_uuid: "e191d526-e5fe-486b-bd0c-9b0a005e2836",
            price: 0.95287,
            price_sources: [],
            processed_ms: 1718571857882,
          },
          {
            leverage: -0.03,
            order_type: "SHORT",
            order_uuid: "40fcfa6c-ae09-4320-b909-8a659a2d2ba2",
            price: 0.954975,
            price_sources: [],
            processed_ms: 1718614810739,
          },
          {
            leverage: 0,
            order_type: "FLAT",
            order_uuid: "c7ee9066-b5b0-406e-9013-84eceddd34ce",
            price: 0.95503,
            price_sources: [],
            processed_ms: 1718614821029,
          },
        ],
        position_type: "FLAT",
        position_uuid: "32404998-417d-4e7d-90c1-a1b4f76af68a",
        return_at_close: 1.000176319552019,
        trade_pair: ["EURCHF", "EUR/CHF", 0.00007, 0.1, 5],
      },
      {
        average_entry_price: 1.4642900000000003,
        close_ms: 1718629223018,
        current_return: 1.0001465049560145,
        is_closed_position: true,
        miner_hotkey: "5GhCxfBcA7Ur5iiAS343xwvrYHTUfBjBi4JimiL5LhujRT9t",
        net_leverage: 0,
        open_ms: 1718355613604,
        orders: [
          {
            leverage: 0.020000000000000004,
            order_type: "LONG",
            order_uuid: "df9241c5-c7ae-4b99-a69a-edf8736f0539",
            price: 1.47094,
            price_sources: [],
            processed_ms: 1718355613430,
          },
          {
            leverage: 0.025,
            order_type: "LONG",
            order_uuid: "622a3c6b-6933-48d3-b79f-55ba0061868e",
            price: 1.46912,
            price_sources: [],
            processed_ms: 1718384422874,
          },
          {
            leverage: 0.025,
            order_type: "LONG",
            order_uuid: "be0f2faf-e6d9-4327-865e-115b3e2831a7",
            price: 1.4685,
            price_sources: [],
            processed_ms: 1718571857484,
          },
          {
            leverage: -0.020000000000000004,
            order_type: "SHORT",
            order_uuid: "31f43914-5593-4869-8d44-3dc2565c873f",
            price: 1.471465,
            price_sources: [],
            processed_ms: 1718614810942,
          },
          {
            leverage: -0.025,
            order_type: "SHORT",
            order_uuid: "a0fbb8b5-1761-419d-ba3b-d5971523f820",
            price: 1.47291,
            price_sources: [],
            processed_ms: 1718629210889,
          },
          {
            leverage: 0,
            order_type: "FLAT",
            order_uuid: "4563be8d-e16b-4b9e-908f-a5c0a95b790e",
            price: 1.4729100000000002,
            price_sources: [],
            processed_ms: 1718629223018,
          },
        ],
        position_type: "FLAT",
        position_uuid: "df9241c5-c7ae-4b99-a69a-edf8736f0539",
        return_at_close: 1.0001416042381404,
        trade_pair: ["EURCAD", "EUR/CAD", 0.00007, 0.1, 5],
      },
      {
        average_entry_price: 3408.699333333333,
        close_ms: 1718773225868,
        current_return: 1.0065948529100766,
        is_closed_position: true,
        miner_hotkey: "5GhCxfBcA7Ur5iiAS343xwvrYHTUfBjBi4JimiL5LhujRT9t",
        net_leverage: 0,
        open_ms: 1718697611063,
        orders: [
          {
            leverage: 0.0050000000250000004,
            order_type: "LONG",
            order_uuid: "035080be-4ff7-45d2-9b1e-bce080f0dccc",
            price: 3442.7,
            price_sources: [],
            processed_ms: 1718697610616,
          },
          {
            leverage: 0.15000000074999997,
            order_type: "LONG",
            order_uuid: "c4d392b2-5199-4eee-8b9f-532e452fec88",
            price: 3411.97,
            price_sources: [],
            processed_ms: 1718712013143,
          },
          {
            leverage: 0.0050000000250000065,
            order_type: "LONG",
            order_uuid: "a68e00df-1598-48d9-9252-8c4e98035b05",
            price: 3423.34,
            price_sources: [],
            processed_ms: 1718740811682,
          },
          {
            leverage: -0.010000000049999985,
            order_type: "SHORT",
            order_uuid: "72d7f8cd-34b6-4ade-908a-f315803e8435",
            price: 3482.08,
            price_sources: [],
            processed_ms: 1718755206530,
          },
          {
            leverage: 0,
            order_type: "FLAT",
            order_uuid: "54cf96b0-a2c2-46ff-8084-e147cbae95d0",
            price: 3560.06,
            price_sources: [],
            processed_ms: 1718773225868,
          },
        ],
        position_type: "FLAT",
        position_uuid: "035080be-4ff7-45d2-9b1e-bce080f0dccc",
        return_at_close: 1.0064337977328057,
        trade_pair: ["ETHUSD", "ETH/USD", 0.003, 0.01, 0.5],
      },
      {
        average_entry_price: 0.9146388251338791,
        close_ms: 1718798418636,
        current_return: 1.0000077911142105,
        is_closed_position: true,
        miner_hotkey: "5GhCxfBcA7Ur5iiAS343xwvrYHTUfBjBi4JimiL5LhujRT9t",
        net_leverage: 0,
        open_ms: 1718776811590,
        orders: [
          {
            leverage: -0.020000000000000004,
            order_type: "SHORT",
            order_uuid: "b9c437de-872e-44fe-a74c-9b6fef83fad3",
            price: 0.9146388251338791,
            price_sources: [],
            processed_ms: 1718776811277,
          },
          {
            leverage: 0,
            order_type: "FLAT",
            order_uuid: "355d4204-9c79-4cc6-920f-ff948802cb67",
            price: 0.9142825223564816,
            price_sources: [],
            processed_ms: 1718798418636,
          },
        ],
        position_type: "FLAT",
        position_uuid: "b9c437de-872e-44fe-a74c-9b6fef83fad3",
        return_at_close: 1.0000063911033028,
        trade_pair: ["AUDCAD", "AUD/CAD", 0.00007, 0.1, 5],
      },
      {
        average_entry_price: 0.914759423924862,
        close_ms: 1718831051246,
        current_return: 1.0000288348567992,
        is_closed_position: true,
        miner_hotkey: "5GhCxfBcA7Ur5iiAS343xwvrYHTUfBjBi4JimiL5LhujRT9t",
        net_leverage: 0,
        open_ms: 1718816410747,
        orders: [
          {
            leverage: -0.04000000000000001,
            order_type: "SHORT",
            order_uuid: "ac337b73-3b9a-4089-b7a3-35984e2ed8dd",
            price: 0.914759423924862,
            price_sources: [],
            processed_ms: 1718816410687,
          },
          {
            leverage: 0,
            order_type: "FLAT",
            order_uuid: "bbf03899-c42f-4c0f-839f-03eecc17dc1d",
            price: 0.9141,
            price_sources: [],
            processed_ms: 1718831051246,
          },
        ],
        position_type: "FLAT",
        position_uuid: "ac337b73-3b9a-4089-b7a3-35984e2ed8dd",
        return_at_close: 1.0000260347760617,
        trade_pair: ["AUDCAD", "AUD/CAD", 0.00007, 0.1, 5],
      },
      {
        average_entry_price: 0.8397452469143375,
        close_ms: 1718845220064,
        current_return: 1.0000802677441127,
        is_closed_position: true,
        miner_hotkey: "5GhCxfBcA7Ur5iiAS343xwvrYHTUfBjBi4JimiL5LhujRT9t",
        net_leverage: 0,
        open_ms: 1718802031858,
        orders: [
          {
            leverage: 0.015,
            order_type: "LONG",
            order_uuid: "e5ba368b-6622-4d3d-9c3a-3a51f76fd897",
            price: 0.84075098765735,
            price_sources: [],
            processed_ms: 1718802031003,
          },
          {
            leverage: 0.045,
            order_type: "LONG",
            order_uuid: "781e704c-bdb9-45cc-9c0f-49a6628ca42e",
            price: 0.83941,
            price_sources: [],
            processed_ms: 1718831051549,
          },
          {
            leverage: 0,
            order_type: "FLAT",
            order_uuid: "661782ca-9b89-4753-a102-5034092ca81c",
            price: 0.84087,
            price_sources: [],
            processed_ms: 1718845220064,
          },
        ],
        position_type: "FLAT",
        position_uuid: "e5ba368b-6622-4d3d-9c3a-3a51f76fd897",
        return_at_close: 1.0000760674069882,
        trade_pair: ["NZDCAD", "NZD/CAD", 0.00007, 0.1, 5],
      },
      {
        average_entry_price: 0.9493966666666667,
        close_ms: 1718874014594,
        current_return: 1.0001793256722737,
        is_closed_position: true,
        miner_hotkey: "5GhCxfBcA7Ur5iiAS343xwvrYHTUfBjBi4JimiL5LhujRT9t",
        net_leverage: 0,
        open_ms: 1718730061490,
        orders: [
          {
            leverage: 0.03,
            order_type: "LONG",
            order_uuid: "d456bf5a-659e-471c-b782-9db86040e381",
            price: 0.94939,
            price_sources: [],
            processed_ms: 1718730061392,
          },
          {
            leverage: 0.015,
            order_type: "LONG",
            order_uuid: "e13a3b62-8601-4554-be94-f6c71579782c",
            price: 0.94941,
            price_sources: [],
            processed_ms: 1718758818983,
          },
          {
            leverage: 0,
            order_type: "FLAT",
            order_uuid: "cadf6cc5-1c6f-40aa-acdb-971dface0caf",
            price: 0.95318,
            price_sources: [],
            processed_ms: 1718874014594,
          },
        ],
        position_type: "FLAT",
        position_uuid: "d456bf5a-659e-471c-b782-9db86040e381",
        return_at_close: 1.0001761751073979,
        trade_pair: ["EURCHF", "EUR/CHF", 0.00007, 0.1, 5],
      },
      {
        average_entry_price: 0.6449187500000001,
        close_ms: 1718874014654,
        current_return: 1.000202524396567,
        is_closed_position: true,
        miner_hotkey: "5GhCxfBcA7Ur5iiAS343xwvrYHTUfBjBi4JimiL5LhujRT9t",
        net_leverage: 0,
        open_ms: 1718701214497,
        orders: [
          {
            leverage: 0.020000000000000004,
            order_type: "LONG",
            order_uuid: "528f2ba8-c609-44c8-8718-ad466457d7a8",
            price: 0.6460950000000001,
            price_sources: [],
            processed_ms: 1718701213606,
          },
          {
            leverage: 0.010000000000000002,
            order_type: "LONG",
            order_uuid: "c951c30f-3838-4571-9bda-11c3253fe4a5",
            price: 0.6441,
            price_sources: [],
            processed_ms: 1718715615549,
          },
          {
            leverage: 0.020000000000000004,
            order_type: "LONG",
            order_uuid: "d559dab2-566e-4f79-9450-e215f3a71f44",
            price: 0.643985,
            price_sources: [],
            processed_ms: 1718730056855,
          },
          {
            leverage: -0.010000000000000002,
            order_type: "SHORT",
            order_uuid: "f60a01f4-75e0-433e-9caa-d1cdce792c04",
            price: 0.644585,
            price_sources: [],
            processed_ms: 1718787606328,
          },
          {
            leverage: 0,
            order_type: "FLAT",
            order_uuid: "de40f884-4eac-47e6-a0e8-08637334cad5",
            price: 0.64819,
            price_sources: [],
            processed_ms: 1718874014654,
          },
        ],
        position_type: "FLAT",
        position_uuid: "528f2ba8-c609-44c8-8718-ad466457d7a8",
        return_at_close: 1.0001990236877316,
        trade_pair: ["CADCHF", "CAD/CHF", 0.00007, 0.1, 5],
      },
      {
        average_entry_price: 1.4641725,
        close_ms: 1719234011432,
        current_return: 1.0002549118663044,
        is_closed_position: true,
        miner_hotkey: "5GhCxfBcA7Ur5iiAS343xwvrYHTUfBjBi4JimiL5LhujRT9t",
        net_leverage: 0,
        open_ms: 1718917410182,
        orders: [
          {
            leverage: 0.04000000000000001,
            order_type: "LONG",
            order_uuid: "9b460e96-3188-4236-95e4-abf08df38343",
            price: 1.46482,
            price_sources: [],
            processed_ms: 1718917409369,
          },
          {
            leverage: 0.04000000000000001,
            order_type: "LONG",
            order_uuid: "675831bf-a868-443a-b8af-5517ec542ac1",
            price: 1.4635250000000002,
            price_sources: [],
            processed_ms: 1718960420032,
          },
          {
            leverage: 0,
            order_type: "FLAT",
            order_uuid: "142ba1e1-1d11-4bcc-a31e-23b8c3dd00e8",
            price: 1.46884,
            price_sources: [],
            processed_ms: 1719234011432,
          },
        ],
        position_type: "FLAT",
        position_uuid: "9b460e96-3188-4236-95e4-abf08df38343",
        return_at_close: 1.000249310438798,
        trade_pair: ["EURCAD", "EUR/CAD", 0.00007, 0.1, 5],
      },
      {
        average_entry_price: 3298.842571428572,
        close_ms: 1719338518580,
        current_return: 1.0015405942443056,
        is_closed_position: true,
        miner_hotkey: "5GhCxfBcA7Ur5iiAS343xwvrYHTUfBjBi4JimiL5LhujRT9t",
        net_leverage: 0,
        open_ms: 1719230420058,
        orders: [
          {
            leverage: 0.058333333625,
            order_type: "LONG",
            order_uuid: "1515169a-6bfb-4a57-8743-8263dfb00181",
            price: 3315.29,
            price_sources: [],
            processed_ms: 1719230418889,
          },
          {
            leverage: 0.008333333375000002,
            order_type: "LONG",
            order_uuid: "f4b69da3-e0fe-4f4a-aca7-b7da6819f1ea",
            price: 3259.38,
            price_sources: [],
            processed_ms: 1719248414980,
          },
          {
            leverage: -0.008333333375000002,
            order_type: "SHORT",
            order_uuid: "8aac7047-fffb-4593-b1d4-d3c20db9ed5d",
            price: 3304.99,
            price_sources: [],
            processed_ms: 1719253809219,
          },
          {
            leverage: 0.005000000024999993,
            order_type: "LONG",
            order_uuid: "97832a26-2c88-49e8-a3cf-c449232daa1a",
            price: 3300,
            price_sources: [],
            processed_ms: 1719259221778,
          },
          {
            leverage: 0.00500000002500002,
            order_type: "LONG",
            order_uuid: "29fd7a12-04d4-42a7-90b8-02a68ab5a36a",
            price: 3307.32,
            price_sources: [],
            processed_ms: 1719262812788,
          },
          {
            leverage: -0.00500000002500002,
            order_type: "SHORT",
            order_uuid: "7cfb4554-6881-4175-883a-bb0a757e7ef0",
            price: 3350.71,
            price_sources: [],
            processed_ms: 1719273614316,
          },
          {
            leverage: -0.005000000024999993,
            order_type: "SHORT",
            order_uuid: "df1d1531-290a-4a82-bae7-2d870c04e2dc",
            price: 3372.48,
            price_sources: [],
            processed_ms: 1719288019810,
          },
          {
            leverage: 0,
            order_type: "FLAT",
            order_uuid: "41c0c98d-93fa-44b9-a45c-460e9330e26f",
            price: 3386.4,
            price_sources: [],
            processed_ms: 1719338518580,
          },
        ],
        position_type: "FLAT",
        position_uuid: "1515169a-6bfb-4a57-8743-8263dfb00181",
        return_at_close: 1.0014638094650297,
        trade_pair: ["ETHUSD", "ETH/USD", 0.003, 0.01, 0.5],
      },
      {
        average_entry_price: 53159.871999999945,
        close_ms: 1719371017248,
        current_return: 1.0033505957776658,
        is_closed_position: true,
        miner_hotkey: "5GhCxfBcA7Ur5iiAS343xwvrYHTUfBjBi4JimiL5LhujRT9t",
        net_leverage: 0,
        open_ms: 1718409618231,
        orders: [
          {
            leverage: 0.008333333375,
            order_type: "LONG",
            order_uuid: "3c458583-8bcf-4168-bcec-104729835390",
            price: 66011.38,
            price_sources: [],
            processed_ms: 1718409614724,
          },
          {
            leverage: 0.008333333375,
            order_type: "LONG",
            order_uuid: "1bdb986c-8ba6-463a-8f7d-3df3d6aec710",
            price: 65305.16,
            price_sources: [],
            processed_ms: 1718640015828,
          },
          {
            leverage: -0.008333333375,
            order_type: "SHORT",
            order_uuid: "2960eebb-f600-4461-bc0c-fa90d5e4ec21",
            price: 66479.41,
            price_sources: [],
            processed_ms: 1718668817347,
          },
          {
            leverage: 0.008333333375,
            order_type: "LONG",
            order_uuid: "4e082ab5-aefc-429a-8613-d27483924e99",
            price: 64914,
            price_sources: [],
            processed_ms: 1718717422156,
          },
          {
            leverage: 0.008333333375000005,
            order_type: "LONG",
            order_uuid: "4812bc8d-1653-45a8-ab36-cb9121be114b",
            price: 64634.6,
            price_sources: [],
            processed_ms: 1718726409682,
          },
          {
            leverage: 0.008333333374999995,
            order_type: "LONG",
            order_uuid: "411fd33f-d95b-40fc-9c47-b87767069937",
            price: 64577,
            price_sources: [],
            processed_ms: 1718727311776,
          },
          {
            leverage: 0.0333333335,
            order_type: "LONG",
            order_uuid: "8bbead84-e52a-4892-a9ed-35239be35a3c",
            price: 64702.7,
            price_sources: [],
            processed_ms: 1718730057765,
          },
          {
            leverage: 0.03333333350000002,
            order_type: "LONG",
            order_uuid: "b3708f62-0e60-4c47-9ced-b985241788e8",
            price: 64542.37,
            price_sources: [],
            processed_ms: 1718733617565,
          },
          {
            leverage: 0.033333333500000006,
            order_type: "LONG",
            order_uuid: "ad1e63ee-7b0e-4818-97af-d016f655ebde",
            price: 64667.43,
            price_sources: [],
            processed_ms: 1718737208747,
          },
          {
            leverage: 0.008333333374999974,
            order_type: "LONG",
            order_uuid: "56767d83-08ba-4306-b397-0dceed854825",
            price: 64377.54,
            price_sources: [],
            processed_ms: 1718739909214,
          },
          {
            leverage: 0.033333333500000006,
            order_type: "LONG",
            order_uuid: "367aa8fe-4510-41d8-aab4-9bb87a6f7eff",
            price: 64437.45,
            price_sources: [],
            processed_ms: 1718740817092,
          },
          {
            leverage: -0.058333333625,
            order_type: "SHORT",
            order_uuid: "87221380-dfc9-46f7-b4cc-554f9169c186",
            price: 64944.46,
            price_sources: [],
            processed_ms: 1718744413061,
          },
          {
            leverage: -0.1000000005,
            order_type: "SHORT",
            order_uuid: "f5a0b8f8-9d05-4719-8d3f-6ba53f2152c6",
            price: 64843.34,
            price_sources: [],
            processed_ms: 1718744449575,
          },
          {
            leverage: 0.008333333375000005,
            order_type: "LONG",
            order_uuid: "1d02b090-8f1f-4059-8595-983a6010f967",
            price: 65145.47,
            price_sources: [],
            processed_ms: 1718755213401,
          },
          {
            leverage: 0.008333333374999995,
            order_type: "LONG",
            order_uuid: "9ff16620-0ea9-4b0f-81bf-38e35ab4e359",
            price: 64945.12,
            price_sources: [],
            processed_ms: 1718841616264,
          },
          {
            leverage: -0.008333333374999995,
            order_type: "SHORT",
            order_uuid: "ee6c5a46-5b29-462c-85ef-1abe38d5a911",
            price: 66201.1,
            price_sources: [],
            processed_ms: 1718884842792,
          },
          {
            leverage: 0.008333333374999995,
            order_type: "LONG",
            order_uuid: "5db5b686-a73e-4c7f-816e-74670ea6dbad",
            price: 64201.93,
            price_sources: [],
            processed_ms: 1718956810339,
          },
          {
            leverage: 0.008333333375000002,
            order_type: "LONG",
            order_uuid: "9b687812-5e7d-4d60-831a-7419d88fb4ce",
            price: 63721.89,
            price_sources: [],
            processed_ms: 1718971212122,
          },
          {
            leverage: 0.025000000124999998,
            order_type: "LONG",
            order_uuid: "713d4ef3-f41c-4533-a4b3-50e639a7be62",
            price: 63719,
            price_sources: [],
            processed_ms: 1718971225468,
          },
          {
            leverage: 0.016666666750000003,
            order_type: "LONG",
            order_uuid: "65aa6cb5-282b-4d59-aa34-a2f16599986f",
            price: 63646.09,
            price_sources: [],
            processed_ms: 1718985612999,
          },
          {
            leverage: -0.008333333375000002,
            order_type: "SHORT",
            order_uuid: "c25c6fac-123f-48e0-a78e-741f9f7752b5",
            price: 64301.97,
            price_sources: [],
            processed_ms: 1719000026248,
          },
          {
            leverage: 0.01666666674999999,
            order_type: "LONG",
            order_uuid: "1c4ff3f0-c937-4056-a3d1-42c03fe6ccb0",
            price: 63133.33,
            price_sources: [],
            processed_ms: 1719187211454,
          },
          {
            leverage: 0.016666666750000003,
            order_type: "LONG",
            order_uuid: "0899340f-79cc-4781-b4f9-092fb331efb5",
            price: 63176.74,
            price_sources: [],
            processed_ms: 1719187249392,
          },
          {
            leverage: 0.008333333375000002,
            order_type: "LONG",
            order_uuid: "eba77e6a-7a3b-45ae-9305-19ded38b6577",
            price: 62817.38,
            price_sources: [],
            processed_ms: 1719201608113,
          },
          {
            leverage: 0.008333333375000002,
            order_type: "LONG",
            order_uuid: "5ecd8c00-25de-4a7d-88b8-c593db17709d",
            price: 62274.62,
            price_sources: [],
            processed_ms: 1719210606369,
          },
          {
            leverage: 0.0050000000250000065,
            order_type: "LONG",
            order_uuid: "7f2a12c5-243c-48c2-b0df-146c5f01320a",
            price: 62341.9,
            price_sources: [],
            processed_ms: 1719212411487,
          },
          {
            leverage: 0.033333333500000006,
            order_type: "LONG",
            order_uuid: "1047a995-80fe-4f04-9659-f853a8b1e009",
            price: 62424.81,
            price_sources: [],
            processed_ms: 1719216010624,
          },
          {
            leverage: 0.00833333337500003,
            order_type: "LONG",
            order_uuid: "b72a97ec-d548-4c69-927f-c1454dca179c",
            price: 62457.34,
            price_sources: [],
            processed_ms: 1719216050849,
          },
          {
            leverage: -0.008333333375000057,
            order_type: "SHORT",
            order_uuid: "15e181c1-5683-4833-b6e1-ffe9ded17803",
            price: 62804.3,
            price_sources: [],
            processed_ms: 1719218709438,
          },
          {
            leverage: 0.008333333375000057,
            order_type: "LONG",
            order_uuid: "1ac6110a-18a0-4479-be1f-c7282fe96f11",
            price: 61057.69,
            price_sources: [],
            processed_ms: 1719222314596,
          },
          {
            leverage: 0.0050000000250000065,
            order_type: "LONG",
            order_uuid: "50c85bab-a29f-474d-8928-66a28d9835d3",
            price: 61148.19,
            price_sources: [],
            processed_ms: 1719225913381,
          },
          {
            leverage: 0.005000000024999979,
            order_type: "LONG",
            order_uuid: "29725990-93e6-4ddd-a55d-68b30e8a0867",
            price: 61149.58,
            price_sources: [],
            processed_ms: 1719226804839,
          },
          {
            leverage: 0.0050000000250000065,
            order_type: "LONG",
            order_uuid: "d90b6088-8032-411d-93b0-5c03297e211b",
            price: 61118.48,
            price_sources: [],
            processed_ms: 1719226843191,
          },
          {
            leverage: 0.07500000037500001,
            order_type: "LONG",
            order_uuid: "cfc565eb-e7ae-431c-9a05-a2428dd60bb6",
            price: 61229.51,
            price_sources: [],
            processed_ms: 1719230418352,
          },
          {
            leverage: 0.0050000000250000065,
            order_type: "LONG",
            order_uuid: "b3349795-36e5-48f5-8de6-3e541fe39824",
            price: 61033.78,
            price_sources: [],
            processed_ms: 1719234908623,
          },
          {
            leverage: -0.010000000050000013,
            order_type: "SHORT",
            order_uuid: "e6c7b5e7-134c-4460-b01c-00c6dab4653a",
            price: 61509.09,
            price_sources: [],
            processed_ms: 1719237615941,
          },
          {
            leverage: 0.01333333339999998,
            order_type: "LONG",
            order_uuid: "8873d903-c04a-477d-a578-e1fa08cb3ed1",
            price: 60781.25,
            price_sources: [],
            processed_ms: 1719244810542,
          },
          {
            leverage: 0.055000000275000016,
            order_type: "LONG",
            order_uuid: "07f7c358-6b4b-4783-b1ad-816c753c93e6",
            price: 60788.17,
            price_sources: [],
            processed_ms: 1719244849721,
          },
          {
            leverage: 0.008333333374999974,
            order_type: "LONG",
            order_uuid: "a7131421-0c12-4c3a-bb2a-b18b4d94cf31",
            price: 60492.42,
            price_sources: [],
            processed_ms: 1719247513832,
          },
          {
            leverage: 0.006666666700000046,
            order_type: "LONG",
            order_uuid: "635677cf-c092-4688-a712-ec1f1329a649",
            price: 60077.9,
            price_sources: [],
            processed_ms: 1719248113917,
          },
          {
            leverage: 0.05166666692499999,
            order_type: "LONG",
            order_uuid: "4733eead-9ce4-490d-b1cd-c391e5be71af",
            price: 60154.81,
            price_sources: [],
            processed_ms: 1719248415131,
          },
          {
            leverage: 0.00833333337500003,
            order_type: "LONG",
            order_uuid: "d5c631fb-7e4e-47e8-9789-2161ddb4be76",
            price: 60117.89,
            price_sources: [],
            processed_ms: 1719249309158,
          },
          {
            leverage: 0.00666666669999999,
            order_type: "LONG",
            order_uuid: "f8c1824b-8a9f-4a8a-bfb3-06f541cda377",
            price: 59981.9,
            price_sources: [],
            processed_ms: 1719249906506,
          },
          {
            leverage: 0.005000000024999951,
            order_type: "LONG",
            order_uuid: "ea552973-a592-49ad-90e5-da3ce9f6501b",
            price: 59875.36,
            price_sources: [],
            processed_ms: 1719250214692,
          },
          {
            leverage: -0.018333333424999987,
            order_type: "SHORT",
            order_uuid: "a05511b8-dc5f-4959-a1d0-e90b5c48860f",
            price: 60219.45,
            price_sources: [],
            processed_ms: 1719250509933,
          },
          {
            leverage: 0.04666666689999999,
            order_type: "LONG",
            order_uuid: "dbc70fc7-24d3-48d4-bd6f-f71a999bb3e1",
            price: 60228,
            price_sources: [],
            processed_ms: 1719252012699,
          },
          {
            leverage: 0.03833333352500001,
            order_type: "LONG",
            order_uuid: "a68d8efc-3161-41a6-96ab-2b45a59be584",
            price: 60389.6,
            price_sources: [],
            processed_ms: 1719255613523,
          },
          {
            leverage: 0.008333333374999974,
            order_type: "LONG",
            order_uuid: "f5cebbd0-700c-4be4-8874-5d67bfa3c62c",
            price: 60145.02,
            price_sources: [],
            processed_ms: 1719256509366,
          },
          {
            leverage: 0.00833333337500003,
            order_type: "LONG",
            order_uuid: "86c48787-e220-4c43-9a2e-18c8541d0d4f",
            price: 59842.6,
            price_sources: [],
            processed_ms: 1719258310135,
          },
          {
            leverage: 0.05166666692500005,
            order_type: "LONG",
            order_uuid: "b8d22f90-37f3-4ee4-bdb5-164c36d070c2",
            price: 59507,
            price_sources: [],
            processed_ms: 1719259222785,
          },
          {
            leverage: 0.016666666749999948,
            order_type: "LONG",
            order_uuid: "e625aa40-eb69-4c70-81db-0a88d2c8bc13",
            price: 59259.85,
            price_sources: [],
            processed_ms: 1719260109672,
          },
          {
            leverage: 0.008333333374999974,
            order_type: "LONG",
            order_uuid: "3e0a98d2-fc17-4709-9b5c-bdc3d6259387",
            price: 58778,
            price_sources: [],
            processed_ms: 1719261013028,
          },
          {
            leverage: -0.016666666749999948,
            order_type: "SHORT",
            order_uuid: "ea1d190b-09ea-4286-8eae-5daf90a9f605",
            price: 59814.4,
            price_sources: [],
            processed_ms: 1719264607934,
          },
          {
            leverage: 0.0050000000249998955,
            order_type: "LONG",
            order_uuid: "fb16d71e-6a34-4eac-b6a8-d0afcb7fc5dc",
            price: 60069.09,
            price_sources: [],
            processed_ms: 1719266414447,
          },
          {
            leverage: 0.0050000000250000065,
            order_type: "LONG",
            order_uuid: "a993b23a-3a7a-4fa4-b2df-ed378b81e406",
            price: 59996.65,
            price_sources: [],
            processed_ms: 1719270004847,
          },
          {
            leverage: 0.033333333500000006,
            order_type: "LONG",
            order_uuid: "5294072c-e73f-4319-990a-3207338bb89f",
            price: 60251.64,
            price_sources: [],
            processed_ms: 1719273614176,
          },
          {
            leverage: -0.033333333499999895,
            order_type: "SHORT",
            order_uuid: "81721710-052d-4b4d-8b96-0199a3110ae3",
            price: 60464.8,
            price_sources: [],
            processed_ms: 1719280815147,
          },
          {
            leverage: -0.06666666699999996,
            order_type: "SHORT",
            order_uuid: "d0881aeb-a5eb-4629-ae42-51e018f98b6d",
            price: 60684.5,
            price_sources: [],
            processed_ms: 1719284410373,
          },
          {
            leverage: -0.2600000013000001,
            order_type: "SHORT",
            order_uuid: "3a24a188-9dbf-4a78-833d-9350e79782f0",
            price: 61317.09,
            price_sources: [],
            processed_ms: 1719288010960,
          },
          {
            leverage: -0.016666666749999975,
            order_type: "SHORT",
            order_uuid: "e89c7091-1135-4928-bfb3-443a323bef64",
            price: 61326.21,
            price_sources: [],
            processed_ms: 1719288021593,
          },
          {
            leverage: -0.0050000000250000065,
            order_type: "SHORT",
            order_uuid: "053f564c-12db-4cc2-ac6d-6068819b0e2b",
            price: 61780,
            price_sources: [],
            processed_ms: 1719327612198,
          },
          {
            leverage: -0.05000000025000001,
            order_type: "SHORT",
            order_uuid: "f01e7962-a53a-4bc6-b621-a227071c00ac",
            price: 61854,
            price_sources: [],
            processed_ms: 1719356865609,
          },
          {
            leverage: -0.04166666687500001,
            order_type: "SHORT",
            order_uuid: "f01f05f4-d06c-4485-a24f-fa73a94ba88c",
            price: 61876.8,
            price_sources: [],
            processed_ms: 1719356902222,
          },
          {
            leverage: -0.10000000049999999,
            order_type: "SHORT",
            order_uuid: "268a99fc-ce4d-48c4-92ad-840961ddec3b",
            price: 62126,
            price_sources: [],
            processed_ms: 1719370920326,
          },
          {
            leverage: 0,
            order_type: "FLAT",
            order_uuid: "f206d8cf-66cc-41d7-9b26-b193b7444a68",
            price: 62006.97,
            price_sources: [],
            processed_ms: 1719371017248,
          },
        ],
        position_type: "FLAT",
        position_uuid: "3c458583-8bcf-4168-bcec-104729835390",
        return_at_close: 1.0025127980260025,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.01, 0.5],
      },
      {
        average_entry_price: 1.46173,
        close_ms: 1719493212352,
        current_return: 1.000170004036313,
        is_closed_position: true,
        miner_hotkey: "5GhCxfBcA7Ur5iiAS343xwvrYHTUfBjBi4JimiL5LhujRT9t",
        net_leverage: 0,
        open_ms: 1719320407381,
        orders: [
          {
            leverage: 0.05,
            order_type: "LONG",
            order_uuid: "1b5a4378-d0ed-4586-8039-30d27ef84166",
            price: 1.46173,
            price_sources: [],
            processed_ms: 1719320405840,
          },
          {
            leverage: 0,
            order_type: "FLAT",
            order_uuid: "2015322e-9769-4206-bd9a-1fc925bd4228",
            price: 1.4667,
            price_sources: [],
            processed_ms: 1719493212352,
          },
        ],
        position_type: "FLAT",
        position_uuid: "1b5a4378-d0ed-4586-8039-30d27ef84166",
        return_at_close: 1.000166503441299,
        trade_pair: ["EURCAD", "EUR/CAD", 0.00007, 0.1, 5],
      },
      {
        average_entry_price: 0.8333770487186465,
        close_ms: 1719493212643,
        current_return: 1.0003808667530236,
        is_closed_position: true,
        miner_hotkey: "5GhCxfBcA7Ur5iiAS343xwvrYHTUfBjBi4JimiL5LhujRT9t",
        net_leverage: 0,
        open_ms: 1719263639517,
        orders: [
          {
            leverage: 0.03,
            order_type: "LONG",
            order_uuid: "66259838-6aba-408c-818b-3735ed10d753",
            price: 0.8361,
            price_sources: [],
            processed_ms: 1719263639416,
          },
          {
            leverage: 0.03,
            order_type: "LONG",
            order_uuid: "9f27aff5-0a95-415c-a8d0-a572bf5c89c2",
            price: 0.835783578663892,
            price_sources: [],
            processed_ms: 1719277207982,
          },
          {
            leverage: -0.03,
            order_type: "SHORT",
            order_uuid: "a1a513dc-a45e-48cd-862b-e8518f581b8d",
            price: 0.836293405543167,
            price_sources: [],
            processed_ms: 1719306007617,
          },
          {
            leverage: 0.06,
            order_type: "LONG",
            order_uuid: "5f932c54-1eb3-4eb9-8de2-c9a79632862b",
            price: 0.83483,
            price_sources: [],
            processed_ms: 1719334820290,
          },
          {
            leverage: -0.03,
            order_type: "SHORT",
            order_uuid: "93dcc3f8-595d-4bc7-8ec4-67178e7a6f1a",
            price: 0.8350416381824739,
            price_sources: [],
            processed_ms: 1719349520106,
          },
          {
            leverage: 0.03,
            order_type: "LONG",
            order_uuid: "dac061c0-06b7-4492-9bd1-a9d1ab23cbf6",
            price: 0.833219688898359,
            price_sources: [],
            processed_ms: 1719406810792,
          },
          {
            leverage: 0.03,
            order_type: "LONG",
            order_uuid: "015b9969-7957-4765-879f-3a511c2c0224",
            price: 0.832664911982105,
            price_sources: [],
            processed_ms: 1719421213580,
          },
          {
            leverage: 0.06,
            order_type: "LONG",
            order_uuid: "098abdc6-16fe-41f4-b71c-a5f37fc5995e",
            price: 0.83211,
            price_sources: [],
            processed_ms: 1719450008177,
          },
          {
            leverage: -0.03,
            order_type: "SHORT",
            order_uuid: "4c7c5b32-e685-4a31-bb9a-bf8a5307d351",
            price: 0.8334278922254821,
            price_sources: [],
            processed_ms: 1719464412164,
          },
          {
            leverage: 0,
            order_type: "FLAT",
            order_uuid: "e8c0fbf9-7da2-4018-a5ad-a2de7a8c1414",
            price: 0.8355,
            price_sources: [],
            processed_ms: 1719493212643,
          },
        ],
        position_type: "FLAT",
        position_uuid: "66259838-6aba-408c-818b-3735ed10d753",
        return_at_close: 1.0003640603544621,
        trade_pair: ["NZDCAD", "NZD/CAD", 0.00007, 0.1, 5],
      },
      {
        average_entry_price: 1.4736900000000002,
        close_ms: 1719910809884,
        current_return: 1.0000955937266618,
        is_closed_position: true,
        miner_hotkey: "5GhCxfBcA7Ur5iiAS343xwvrYHTUfBjBi4JimiL5LhujRT9t",
        net_leverage: 0,
        open_ms: 1719838816239,
        orders: [
          {
            leverage: -0.04000000000000001,
            order_type: "SHORT",
            order_uuid: "61866727-0f84-4796-8a82-617acfc70c8b",
            price: 1.4729,
            price_sources: [],
            processed_ms: 1719838816058,
          },
          {
            leverage: -0.04000000000000001,
            order_type: "SHORT",
            order_uuid: "3ea9eac0-012e-4002-a49b-ee00514dfce3",
            price: 1.47448,
            price_sources: [],
            processed_ms: 1719868212213,
          },
          {
            leverage: 0,
            order_type: "FLAT",
            order_uuid: "3b083171-38b6-477c-b521-4a25ee9680f5",
            price: 1.47193,
            price_sources: [],
            processed_ms: 1719910809884,
          },
        ],
        position_type: "FLAT",
        position_uuid: "61866727-0f84-4796-8a82-617acfc70c8b",
        return_at_close: 1.000089993191337,
        trade_pair: ["EURCAD", "EUR/CAD", 0.00007, 0.1, 5],
      },
      {
        average_entry_price: 60301.31333333333,
        close_ms: 1720024212235,
        current_return: 1.0000876586146261,
        is_closed_position: true,
        miner_hotkey: "5GhCxfBcA7Ur5iiAS343xwvrYHTUfBjBi4JimiL5LhujRT9t",
        net_leverage: 0,
        open_ms: 1719999911613,
        orders: [
          {
            leverage: 0.008333333375,
            order_type: "LONG",
            order_uuid: "41c47774-f11d-48de-93ca-f4a4729b5a6f",
            price: 60537.88,
            price_sources: [],
            processed_ms: 1719999911544,
          },
          {
            leverage: 0.016666666750000003,
            order_type: "LONG",
            order_uuid: "95925257-8872-4177-b862-43decd431de6",
            price: 60183.03,
            price_sources: [],
            processed_ms: 1720009814744,
          },
          {
            leverage: 0,
            order_type: "FLAT",
            order_uuid: "1f1a0722-044d-41f0-a413-ca25ede9919f",
            price: 60513.58,
            price_sources: [],
            processed_ms: 1720024212235,
          },
        ],
        position_type: "FLAT",
        position_uuid: "41c47774-f11d-48de-93ca-f4a4729b5a6f",
        return_at_close: 1.0000626564230357,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.01, 0.5],
      },
      {
        average_entry_price: 0.8303,
        close_ms: 1720026018068,
        current_return: 1.0000971241118066,
        is_closed_position: true,
        miner_hotkey: "5GhCxfBcA7Ur5iiAS343xwvrYHTUfBjBi4JimiL5LhujRT9t",
        net_leverage: 0,
        open_ms: 1720011623726,
        orders: [
          {
            leverage: 0.03,
            order_type: "LONG",
            order_uuid: "2df1a885-b967-4066-8606-5878337a29d2",
            price: 0.8303,
            price_sources: [],
            processed_ms: 1720011623230,
          },
          {
            leverage: 0,
            order_type: "FLAT",
            order_uuid: "c8351788-0e78-4052-aee1-8b4c6e528a2d",
            price: 0.832988071667767,
            price_sources: [],
            processed_ms: 1720026018068,
          },
        ],
        position_type: "FLAT",
        position_uuid: "2df1a885-b967-4066-8606-5878337a29d2",
        return_at_close: 1.0000950239078459,
        trade_pair: ["NZDCAD", "NZD/CAD", 0.00007, 0.1, 5],
      },
      {
        average_entry_price: 59549.990000000005,
        close_ms: 1720043107614,
        current_return: 1.0000435776733627,
        is_closed_position: true,
        miner_hotkey: "5GhCxfBcA7Ur5iiAS343xwvrYHTUfBjBi4JimiL5LhujRT9t",
        net_leverage: 0,
        open_ms: 1720040412927,
        orders: [
          {
            leverage: 0.0050000000250000004,
            order_type: "LONG",
            order_uuid: "94f0ab0e-db53-4406-b3e2-a7f47be7e8da",
            price: 59549.99,
            price_sources: [],
            processed_ms: 1720040412304,
          },
          {
            leverage: 0,
            order_type: "FLAT",
            order_uuid: "ed52aab1-fedf-4faf-88db-0cc32f7045a2",
            price: 60069,
            price_sources: [],
            processed_ms: 1720043107614,
          },
        ],
        position_type: "FLAT",
        position_uuid: "94f0ab0e-db53-4406-b3e2-a7f47be7e8da",
        return_at_close: 1.0000385774554494,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.01, 0.5],
      },
      {
        average_entry_price: 0.9155961568391288,
        close_ms: 1720127072892,
        current_return: 1.0000258762139043,
        is_closed_position: true,
        miner_hotkey: "5GhCxfBcA7Ur5iiAS343xwvrYHTUfBjBi4JimiL5LhujRT9t",
        net_leverage: 0,
        open_ms: 1720083626320,
        orders: [
          {
            leverage: -0.04000000000000001,
            order_type: "SHORT",
            order_uuid: "8acfc9d1-653d-4f8c-ac24-59b091864a5c",
            price: 0.9156110402398125,
            price_sources: [],
            processed_ms: 1720083626097,
          },
          {
            leverage: -0.04000000000000001,
            order_type: "SHORT",
            order_uuid: "1f509673-bdbe-48d1-8b16-c9e47d9055a2",
            price: 0.915581273438445,
            price_sources: [],
            processed_ms: 1720083655460,
          },
          {
            leverage: 0,
            order_type: "FLAT",
            order_uuid: "e980e960-358c-4510-99f1-5dffff661537",
            price: 0.9153,
            price_sources: [],
            processed_ms: 1720127072892,
          },
        ],
        position_type: "FLAT",
        position_uuid: "8acfc9d1-653d-4f8c-ac24-59b091864a5c",
        return_at_close: 1.0000202760689973,
        trade_pair: ["AUDCAD", "AUD/CAD", 0.00007, 0.1, 5],
      },
      {
        average_entry_price: 0.9715697761194032,
        close_ms: 1720386014377,
        current_return: 1.0026936115676002,
        is_closed_position: true,
        miner_hotkey: "5GhCxfBcA7Ur5iiAS343xwvrYHTUfBjBi4JimiL5LhujRT9t",
        net_leverage: 0,
        open_ms: 1719810011842,
        orders: [
          {
            leverage: -0.020000000000000004,
            order_type: "SHORT",
            order_uuid: "22f1cb96-17b8-489b-99bc-f8dcab530cb7",
            price: 0.96753,
            price_sources: [],
            processed_ms: 1719810010455,
          },
          {
            leverage: -0.04000000000000001,
            order_type: "SHORT",
            order_uuid: "2a40bb27-75a1-4dfd-8e5a-708d5d4258a1",
            price: 0.96942,
            price_sources: [],
            processed_ms: 1719824410120,
          },
          {
            leverage: -0.020000000000000004,
            order_type: "SHORT",
            order_uuid: "c090f091-ca86-4fa4-b24f-3d3ef1400eaa",
            price: 0.96984,
            price_sources: [],
            processed_ms: 1719838815824,
          },
          {
            leverage: -0.020000000000000004,
            order_type: "SHORT",
            order_uuid: "51335a5a-a35b-43b6-b597-6e11399abe7c",
            price: 0.96963,
            price_sources: [],
            processed_ms: 1719853217235,
          },
          {
            leverage: -0.029999999999999985,
            order_type: "SHORT",
            order_uuid: "84adf674-5e48-483a-8776-23c5062dd94a",
            price: 0.9695,
            price_sources: [],
            processed_ms: 1719867609187,
          },
          {
            leverage: -0.030000000000000027,
            order_type: "SHORT",
            order_uuid: "425c068a-f2ac-4052-937a-e164f27347c4",
            price: 0.9703200000000001,
            price_sources: [],
            processed_ms: 1719925210939,
          },
          {
            leverage: -0.019999999999999962,
            order_type: "SHORT",
            order_uuid: "925ebec0-6cc7-446e-b75c-548f9ef22db0",
            price: 0.9706,
            price_sources: [],
            processed_ms: 1719939626040,
          },
          {
            leverage: -0.040000000000000036,
            order_type: "SHORT",
            order_uuid: "7de3a0db-0e47-4b74-9287-fd128e9684e6",
            price: 0.97073,
            price_sources: [],
            processed_ms: 1719939663490,
          },
          {
            leverage: -0.06,
            order_type: "SHORT",
            order_uuid: "98e39345-de4d-435b-be98-4acd8e116bc0",
            price: 0.9709,
            price_sources: [],
            processed_ms: 1719954006032,
          },
          {
            leverage: -0.04999999999999999,
            order_type: "SHORT",
            order_uuid: "e4cf02f6-d8fa-4c58-a6b0-76c529d5bf0a",
            price: 0.9713149999999999,
            price_sources: [],
            processed_ms: 1719968410673,
          },
          {
            leverage: -0.019999999999999962,
            order_type: "SHORT",
            order_uuid: "92b7e1e6-bde8-4b86-863a-2bbbf57be522",
            price: 0.97214,
            price_sources: [],
            processed_ms: 1719997209526,
          },
          {
            leverage: -0.020000000000000018,
            order_type: "SHORT",
            order_uuid: "effcefff-57c9-4ed3-8008-dec5d73c6e7f",
            price: 0.97214,
            price_sources: [],
            processed_ms: 1719997219703,
          },
          {
            leverage: -0.07,
            order_type: "SHORT",
            order_uuid: "05e8498a-c55a-4210-9950-26eada170e35",
            price: 0.97214,
            price_sources: [],
            processed_ms: 1719997247767,
          },
          {
            leverage: -0.06,
            order_type: "SHORT",
            order_uuid: "0aee58b4-db1e-49a7-839c-950dc120d73e",
            price: 0.97249,
            price_sources: [],
            processed_ms: 1720011611361,
          },
          {
            leverage: -0.10000000000000009,
            order_type: "SHORT",
            order_uuid: "86a00756-b021-457a-9ba8-b0bfc93eebc5",
            price: 0.97256,
            price_sources: [],
            processed_ms: 1720011623120,
          },
          {
            leverage: -0.019999999999999907,
            order_type: "SHORT",
            order_uuid: "9a5b0110-0a1a-41cb-9d14-c6653c3236a9",
            price: 0.97358,
            price_sources: [],
            processed_ms: 1720083605036,
          },
          {
            leverage: -0.10999999999999999,
            order_type: "SHORT",
            order_uuid: "ef673f69-af23-453d-b758-8aac2d867112",
            price: 0.97339,
            price_sources: [],
            processed_ms: 1720083646322,
          },
          {
            leverage: 0.05999999999999994,
            order_type: "LONG",
            order_uuid: "5f82aa5c-ae08-49d5-9c8e-d2a0d194ec22",
            price: 0.971765,
            price_sources: [],
            processed_ms: 1720098013558,
          },
          {
            leverage: 0,
            order_type: "FLAT",
            order_uuid: "4dab07a2-bab9-4fec-a9fb-2f1718af9d2d",
            price: 0.96768,
            price_sources: [],
            processed_ms: 1720386014377,
          },
        ],
        position_type: "FLAT",
        position_uuid: "22f1cb96-17b8-489b-99bc-f8dcab530cb7",
        return_at_close: 1.002642373924049,
        trade_pair: ["EURCHF", "EUR/CHF", 0.00007, 0.1, 5],
      },
      {
        average_entry_price: 0.9181017855204843,
        close_ms: 1720472784380,
        current_return: 0.9999911104854895,
        is_closed_position: true,
        miner_hotkey: "5GhCxfBcA7Ur5iiAS343xwvrYHTUfBjBi4JimiL5LhujRT9t",
        net_leverage: 0,
        open_ms: 1720191617659,
        orders: [
          {
            leverage: -0.04000000000000001,
            order_type: "SHORT",
            order_uuid: "3fd0fde6-b035-46fc-bb28-c636e9f38316",
            price: 0.918016835016835,
            price_sources: [],
            processed_ms: 1720191617500,
          },
          {
            leverage: -0.05,
            order_type: "SHORT",
            order_uuid: "a6e92b44-320d-4b33-9f39-cf7932752f52",
            price: 0.9191926512393129,
            price_sources: [],
            processed_ms: 1720195210812,
          },
          {
            leverage: -0.039999999999999994,
            order_type: "SHORT",
            order_uuid: "56ae066f-4f42-40ed-8f06-3f2f00ca2d6b",
            price: 0.9187594278568395,
            price_sources: [],
            processed_ms: 1720195250492,
          },
          {
            leverage: -0.08000000000000002,
            order_type: "SHORT",
            order_uuid: "b7bbc895-00b4-4002-8df0-ca74df43d4c7",
            price: 0.91908,
            price_sources: [],
            processed_ms: 1720198813432,
          },
          {
            leverage: -0.03999999999999998,
            order_type: "SHORT",
            order_uuid: "869b758d-e316-4472-b281-1b4a76a964cb",
            price: 0.919678863817218,
            price_sources: [],
            processed_ms: 1720202410983,
          },
          {
            leverage: -0.04999999999999999,
            order_type: "SHORT",
            order_uuid: "f7d75b3d-a71f-42f9-adc4-41e77fd925f9",
            price: 0.9196717205055236,
            price_sources: [],
            processed_ms: 1720202422492,
          },
          {
            leverage: -0.040000000000000036,
            order_type: "SHORT",
            order_uuid: "21d7dc91-1870-4fef-8b07-9266994e26a0",
            price: 0.920004995679412,
            price_sources: [],
            processed_ms: 1720206016229,
          },
          {
            leverage: -0.040000000000000036,
            order_type: "SHORT",
            order_uuid: "1a6a6885-1442-4fe8-b3b6-33cd8ce446fb",
            price: 0.92035,
            price_sources: [],
            processed_ms: 1720209611131,
          },
          {
            leverage: -0.03999999999999998,
            order_type: "SHORT",
            order_uuid: "1dfd26f6-34b7-4943-9f64-a42bf2f561b2",
            price: 0.91879,
            price_sources: [],
            processed_ms: 1720386198351,
          },
          {
            leverage: 0.24000000000000002,
            order_type: "LONG",
            order_uuid: "a5c36bb7-3ce1-4a1e-a381-cd11d26a15ee",
            price: 0.920398899216231,
            price_sources: [],
            processed_ms: 1720389608784,
          },
          {
            leverage: -0.04999999999999999,
            order_type: "SHORT",
            order_uuid: "d356fb08-282c-4058-bf3f-ac6ca538db58",
            price: 0.9209700321173111,
            price_sources: [],
            processed_ms: 1720411217642,
          },
          {
            leverage: 0.07999999999999999,
            order_type: "LONG",
            order_uuid: "1d5f5b6a-ec26-498d-880b-b2f9abbfc89f",
            price: 0.91851,
            price_sources: [],
            processed_ms: 1720429224854,
          },
          {
            leverage: 0.10000000000000002,
            order_type: "LONG",
            order_uuid: "e81aea81-d416-4d19-8505-24d7afc46472",
            price: 0.9186049999999999,
            price_sources: [],
            processed_ms: 1720429265620,
          },
          {
            leverage: 0,
            order_type: "FLAT",
            order_uuid: "8774a353-7bc9-4d12-bdd3-baa3ea057199",
            price: 0.918265,
            price_sources: [],
            processed_ms: 1720472784380,
          },
        ],
        position_type: "FLAT",
        position_uuid: "3fd0fde6-b035-46fc-bb28-c636e9f38316",
        return_at_close: 0.9999582107779545,
        trade_pair: ["AUDCAD", "AUD/CAD", 0.00007, 0.1, 5],
      },
      {
        average_entry_price: 1.4736,
        close_ms: 1720472785224,
        current_return: 0.9999489685124864,
        is_closed_position: true,
        miner_hotkey: "5GhCxfBcA7Ur5iiAS343xwvrYHTUfBjBi4JimiL5LhujRT9t",
        net_leverage: 0,
        open_ms: 1720386200976,
        orders: [
          {
            leverage: -0.04000000000000001,
            order_type: "SHORT",
            order_uuid: "2cde42a6-d3e4-4cf8-a16d-89e900f60288",
            price: 1.4736,
            price_sources: [],
            processed_ms: 1720386198400,
          },
          {
            leverage: 0,
            order_type: "FLAT",
            order_uuid: "4cb41752-4450-4f41-807f-d7b1c8b3b157",
            price: 1.4754800000000001,
            price_sources: [],
            processed_ms: 1720472785224,
          },
        ],
        position_type: "FLAT",
        position_uuid: "2cde42a6-d3e4-4cf8-a16d-89e900f60288",
        return_at_close: 0.9999461686553746,
        trade_pair: ["EURCAD", "EUR/CAD", 0.00007, 0.1, 5],
      },
      {
        average_entry_price: 0.6751180000000001,
        close_ms: 1720530018803,
        current_return: 1.0001444418080436,
        is_closed_position: true,
        miner_hotkey: "5GhCxfBcA7Ur5iiAS343xwvrYHTUfBjBi4JimiL5LhujRT9t",
        net_leverage: 0,
        open_ms: 1720195218583,
        orders: [
          {
            leverage: -0.05,
            order_type: "SHORT",
            order_uuid: "2cec14aa-c12e-4195-bed2-3e3ab7ea4748",
            price: 0.67432,
            price_sources: [],
            processed_ms: 1720195218466,
          },
          {
            leverage: -0.04000000000000001,
            order_type: "SHORT",
            order_uuid: "b409c3fa-e797-4e5e-b354-699365a71a72",
            price: 0.67544,
            price_sources: [],
            processed_ms: 1720411217235,
          },
          {
            leverage: 0.020000000000000004,
            order_type: "LONG",
            order_uuid: "bf55dc5d-b61d-4a65-bf63-ea81a7be259e",
            price: 0.674485,
            price_sources: [],
            processed_ms: 1720418407428,
          },
          {
            leverage: 0.020000000000000004,
            order_type: "LONG",
            order_uuid: "42338784-d084-4350-a21b-6897524bdd0a",
            price: 0.6744,
            price_sources: [],
            processed_ms: 1720418446520,
          },
          {
            leverage: 0,
            order_type: "FLAT",
            order_uuid: "7cf303b6-8766-4bda-8312-03330adbfa6e",
            price: 0.67317,
            price_sources: [],
            processed_ms: 1720530018803,
          },
        ],
        position_type: "FLAT",
        position_uuid: "2cec14aa-c12e-4195-bed2-3e3ab7ea4748",
        return_at_close: 1.0001381408980603,
        trade_pair: ["AUDUSD", "AUD/USD", 0.00007, 0.1, 5],
      },
      {
        average_entry_price: 1.2866062499999995,
        close_ms: 1720544409519,
        current_return: 1.0002574027180042,
        is_closed_position: true,
        miner_hotkey: "5GhCxfBcA7Ur5iiAS343xwvrYHTUfBjBi4JimiL5LhujRT9t",
        net_leverage: 0,
        open_ms: 1720414812717,
        orders: [
          {
            leverage: -0.03,
            order_type: "SHORT",
            order_uuid: "56b8743c-8e76-4011-a054-50c50c8de990",
            price: 1.281455,
            price_sources: [],
            processed_ms: 1720414812172,
          },
          {
            leverage: -0.04000000000000001,
            order_type: "SHORT",
            order_uuid: "985a40e5-93b0-400f-8106-fdd6f2978b1e",
            price: 1.2817850000000002,
            price_sources: [],
            processed_ms: 1720429233935,
          },
          {
            leverage: -0.020000000000000004,
            order_type: "SHORT",
            order_uuid: "28893ab0-676f-465d-91cf-52a47e6a0e18",
            price: 1.28379,
            price_sources: [],
            processed_ms: 1720443610863,
          },
          {
            leverage: -0.029999999999999985,
            order_type: "SHORT",
            order_uuid: "0a5660fa-2530-4162-84e6-3f8883bc6e69",
            price: 1.28378,
            price_sources: [],
            processed_ms: 1720443653016,
          },
          {
            leverage: 0.07999999999999999,
            order_type: "LONG",
            order_uuid: "841958bc-80ab-4d5f-8587-b8d39419c332",
            price: 1.2805,
            price_sources: [],
            processed_ms: 1720472417525,
          },
          {
            leverage: 0,
            order_type: "FLAT",
            order_uuid: "7a210dce-2ee5-4a2b-8973-26cf5f5a760b",
            price: 1.27836,
            price_sources: [],
            processed_ms: 1720544409519,
          },
        ],
        position_type: "FLAT",
        position_uuid: "56b8743c-8e76-4011-a054-50c50c8de990",
        return_at_close: 1.0002490005558213,
        trade_pair: ["GBPUSD", "GBP/USD", 0.00007, 0.1, 5],
      },
      {
        average_entry_price: 0.8253035735117721,
        close_ms: 1720659618071,
        current_return: 1.0001448417935932,
        is_closed_position: true,
        miner_hotkey: "5GhCxfBcA7Ur5iiAS343xwvrYHTUfBjBi4JimiL5LhujRT9t",
        net_leverage: 0,
        open_ms: 1720602008867,
        orders: [
          {
            leverage: 0.03,
            order_type: "LONG",
            order_uuid: "735088c3-8858-4e0a-89c8-d77602460350",
            price: 0.82775,
            price_sources: [],
            processed_ms: 1720602008180,
          },
          {
            leverage: 0.06,
            order_type: "LONG",
            order_uuid: "27d298b5-77e9-4889-8fa5-a80cfd09c03c",
            price: 0.82812,
            price_sources: [],
            processed_ms: 1720630810068,
          },
          {
            leverage: -0.06,
            order_type: "SHORT",
            order_uuid: "8c2a943b-fb9f-43ee-899a-32c8f698dda9",
            price: 0.829343213244114,
            price_sources: [],
            processed_ms: 1720659607309,
          },
          {
            leverage: 0,
            order_type: "FLAT",
            order_uuid: "fca9c01e-d76c-4bdc-92db-ff524f01b00d",
            price: 0.8293,
            price_sources: [],
            processed_ms: 1720659618071,
          },
        ],
        position_type: "FLAT",
        position_uuid: "735088c3-8858-4e0a-89c8-d77602460350",
        return_at_close: 1.0001385408810899,
        trade_pair: ["NZDCAD", "NZD/CAD", 0.00007, 0.1, 5],
      },
      {
        average_entry_price: 2742.34923076923,
        close_ms: 1720713919910,
        current_return: 1.0023741788793747,
        is_closed_position: true,
        miner_hotkey: "5GhCxfBcA7Ur5iiAS343xwvrYHTUfBjBi4JimiL5LhujRT9t",
        net_leverage: 0,
        open_ms: 1720008018913,
        orders: [
          {
            leverage: 0.013333333400000003,
            order_type: "LONG",
            order_uuid: "248d8731-2458-46e6-83fc-34afe28b5bab",
            price: 3300.32,
            price_sources: [],
            processed_ms: 1720008018504,
          },
          {
            leverage: 0.005000000024999998,
            order_type: "LONG",
            order_uuid: "6d5f2f7d-9756-4e6b-aabd-fe8ad34145a5",
            price: 3298.25,
            price_sources: [],
            processed_ms: 1720022420967,
          },
          {
            leverage: 0.005000000025,
            order_type: "LONG",
            order_uuid: "8638ddea-52b8-4c44-981c-0ce368c22244",
            price: 3294.6,
            price_sources: [],
            processed_ms: 1720044013414,
          },
          {
            leverage: 0.0016666666750000045,
            order_type: "LONG",
            order_uuid: "bc7fa1fe-208a-4482-9fbe-061b3c97f9a3",
            price: 3296.31,
            price_sources: [],
            processed_ms: 1720051216209,
          },
          {
            leverage: 0.005000000025,
            order_type: "LONG",
            order_uuid: "c81e56e1-e983-468d-b807-ffb8bfb39fee",
            price: 3296,
            price_sources: [],
            processed_ms: 1720051255523,
          },
          {
            leverage: 0.005000000025,
            order_type: "LONG",
            order_uuid: "fd86a6a7-1edb-4a5b-b7b3-f4bc1cf55052",
            price: 3201.41,
            price_sources: [],
            processed_ms: 1720076414611,
          },
          {
            leverage: 0.010000000050000006,
            order_type: "LONG",
            order_uuid: "1070e133-04d4-4055-aad9-1f05c5badeae",
            price: 3178.51,
            price_sources: [],
            processed_ms: 1720080016183,
          },
          {
            leverage: 0.005000000025,
            order_type: "LONG",
            order_uuid: "1aa778f3-9452-4ed7-86e8-4a7401ba8a4b",
            price: 3165,
            price_sources: [],
            processed_ms: 1720083613371,
          },
          {
            leverage: 0.005000000024999986,
            order_type: "LONG",
            order_uuid: "9a9773d1-58f2-4376-8c69-8b8b127478e9",
            price: 3156.04,
            price_sources: [],
            processed_ms: 1720094415984,
          },
          {
            leverage: 0.0016666666750000045,
            order_type: "LONG",
            order_uuid: "22bd7524-8429-4c31-af51-4915918416da",
            price: 3153.27,
            price_sources: [],
            processed_ms: 1720094427347,
          },
          {
            leverage: 0.01000000005,
            order_type: "LONG",
            order_uuid: "6b00fd92-6393-4430-872c-8162b4a5fe68",
            price: 3121.33,
            price_sources: [],
            processed_ms: 1720105217042,
          },
          {
            leverage: 0.0050000000250000065,
            order_type: "LONG",
            order_uuid: "0aa6da4f-7293-46ba-a2bb-583c24a48c6c",
            price: 3134.16,
            price_sources: [],
            processed_ms: 1720108808780,
          },
          {
            leverage: 0.010000000049999985,
            order_type: "LONG",
            order_uuid: "7613bc9b-4d0c-40c2-bcd2-acb6dd7cbb33",
            price: 3133.14,
            price_sources: [],
            processed_ms: 1720123211616,
          },
          {
            leverage: 0.00500000002500002,
            order_type: "LONG",
            order_uuid: "06488bb0-2735-49ad-82fd-b8e948b631b0",
            price: 3125.52,
            price_sources: [],
            processed_ms: 1720134014837,
          },
          {
            leverage: 0.008333333375000015,
            order_type: "LONG",
            order_uuid: "dd7394da-a649-4186-ad20-365093ea7d47",
            price: 3056.71,
            price_sources: [],
            processed_ms: 1720137611417,
          },
          {
            leverage: 0.006666666699999976,
            order_type: "LONG",
            order_uuid: "fa08ddd3-e7cb-4390-933d-8e4eaca53d2a",
            price: 3064.89,
            price_sources: [],
            processed_ms: 1720137652541,
          },
          {
            leverage: 0.005000000024999993,
            order_type: "LONG",
            order_uuid: "ba207276-5258-495a-b841-733bcc67e4af",
            price: 3105.09,
            price_sources: [],
            processed_ms: 1720141218511,
          },
          {
            leverage: 0.010000000050000013,
            order_type: "LONG",
            order_uuid: "3bf38dcc-3f78-4778-aa25-14ee22b6ff8d",
            price: 3091.49,
            price_sources: [],
            processed_ms: 1720144811112,
          },
          {
            leverage: 0.005000000024999993,
            order_type: "LONG",
            order_uuid: "3375db36-066d-4754-b032-0dcc7a98b335",
            price: 2918.74,
            price_sources: [],
            processed_ms: 1720152012729,
          },
          {
            leverage: 0.010000000050000013,
            order_type: "LONG",
            order_uuid: "508551e2-dc6d-479c-9618-861ac0bb01c4",
            price: 2915.3,
            price_sources: [],
            processed_ms: 1720152051887,
          },
          {
            leverage: 0.008333333375000002,
            order_type: "LONG",
            order_uuid: "9f2cbd52-4e17-4c89-9387-06dc02094dd4",
            price: 2896.28,
            price_sources: [],
            processed_ms: 1720153807262,
          },
          {
            leverage: 0.010000000049999985,
            order_type: "LONG",
            order_uuid: "99f70987-e82c-4077-b370-5b4e463bc246",
            price: 2860.04,
            price_sources: [],
            processed_ms: 1720155615350,
          },
          {
            leverage: 0.008333333375000002,
            order_type: "LONG",
            order_uuid: "248db265-a154-442f-b41d-b5e6c3a49262",
            price: 2879.25,
            price_sources: [],
            processed_ms: 1720158311782,
          },
          {
            leverage: 0.0050000000250000065,
            order_type: "LONG",
            order_uuid: "4ab3eee5-b2b8-41ad-9ae1-47a3aaba8e36",
            price: 2899.53,
            price_sources: [],
            processed_ms: 1720159212707,
          },
          {
            leverage: 0.005000000024999979,
            order_type: "LONG",
            order_uuid: "f2d01e13-d50e-4296-b2e9-40a7cb57dbaf",
            price: 2896.1,
            price_sources: [],
            processed_ms: 1720159251329,
          },
          {
            leverage: 0.013333333400000036,
            order_type: "LONG",
            order_uuid: "6ba403af-7629-4715-b457-e4efa6485d75",
            price: 2861.11,
            price_sources: [],
            processed_ms: 1720162812318,
          },
          {
            leverage: 0.015000000074999964,
            order_type: "LONG",
            order_uuid: "c6befdb7-6540-4a25-ad98-b4325e213090",
            price: 2850.7,
            price_sources: [],
            processed_ms: 1720170016662,
          },
          {
            leverage: 0.008333333375000002,
            order_type: "LONG",
            order_uuid: "984e6e23-3c9c-43a2-a866-b32d8553e6fe",
            price: 2850.19,
            price_sources: [],
            processed_ms: 1720170057983,
          },
          {
            leverage: 0.00833333337500003,
            order_type: "LONG",
            order_uuid: "33fe6f57-a468-4033-b732-d47f69135a24",
            price: 2836.16,
            price_sources: [],
            processed_ms: 1720170911316,
          },
          {
            leverage: -0.04166666687500001,
            order_type: "SHORT",
            order_uuid: "8a5a1cf3-78ed-45e5-93fd-4a8925a92759",
            price: 2921.1,
            price_sources: [],
            processed_ms: 1720174516016,
          },
          {
            leverage: 0.020000000100000026,
            order_type: "LONG",
            order_uuid: "966ad551-79eb-4192-88d2-ad29b56c571b",
            price: 2945.97,
            price_sources: [],
            processed_ms: 1720180818437,
          },
          {
            leverage: 0.005000000024999979,
            order_type: "LONG",
            order_uuid: "9d2992f6-8559-4395-a6e3-793f1e07a6bb",
            price: 2945.11,
            price_sources: [],
            processed_ms: 1720180855329,
          },
          {
            leverage: -0.020000000100000026,
            order_type: "SHORT",
            order_uuid: "6d0a62b2-4cad-44e1-91b2-f5bd4222de35",
            price: 3005.4,
            price_sources: [],
            processed_ms: 1720252808975,
          },
          {
            leverage: -0.005000000024999951,
            order_type: "SHORT",
            order_uuid: "01965716-fdca-4cfc-bb34-fc985b46f185",
            price: 3004.84,
            price_sources: [],
            processed_ms: 1720252821855,
          },
          {
            leverage: -0.020000000100000026,
            order_type: "SHORT",
            order_uuid: "ec70c3df-2446-4765-ba32-ba5059aa86d6",
            price: 3050,
            price_sources: [],
            processed_ms: 1720285209684,
          },
          {
            leverage: -0.006666666700000018,
            order_type: "SHORT",
            order_uuid: "0460607e-b92d-40d2-bd40-e508ca095843",
            price: 3068.94,
            price_sources: [],
            processed_ms: 1720310412420,
          },
          {
            leverage: 0.0050000000250000065,
            order_type: "LONG",
            order_uuid: "ff15c237-2f88-407f-84e2-5b408fb4b380",
            price: 3004.73,
            price_sources: [],
            processed_ms: 1720339210898,
          },
          {
            leverage: -0.0050000000250000065,
            order_type: "SHORT",
            order_uuid: "ec82f26c-b540-4b15-af11-8049a35e9c4b",
            price: 3018.8,
            price_sources: [],
            processed_ms: 1720353611784,
          },
          {
            leverage: 0.0016666666750000114,
            order_type: "LONG",
            order_uuid: "aa5d3f1b-80bd-423e-bab9-971a9dfeb9dc",
            price: 2931.91,
            price_sources: [],
            processed_ms: 1720396811532,
          },
          {
            leverage: 0.005000000024999979,
            order_type: "LONG",
            order_uuid: "d67af18c-a90f-449d-aea7-46e8e6195612",
            price: 2936.74,
            price_sources: [],
            processed_ms: 1720396849459,
          },
          {
            leverage: 0.005000000025000034,
            order_type: "LONG",
            order_uuid: "cdcd7e4c-d62e-4e4f-b27b-a455c13067d5",
            price: 2878.21,
            price_sources: [],
            processed_ms: 1720411207214,
          },
          {
            leverage: 0.005000000024999979,
            order_type: "LONG",
            order_uuid: "6975d45b-d70e-42f9-90f4-606dbe0860e8",
            price: 2880.21,
            price_sources: [],
            processed_ms: 1720411218128,
          },
          {
            leverage: -0.03500000017499999,
            order_type: "SHORT",
            order_uuid: "6f119773-f884-4747-813d-bc1ac788a4a6",
            price: 3054.54,
            price_sources: [],
            processed_ms: 1720440012913,
          },
          {
            leverage: -0.015000000075000006,
            order_type: "SHORT",
            order_uuid: "ee7db79a-7fe7-4c4a-9136-23e19719101f",
            price: 3055.74,
            price_sources: [],
            processed_ms: 1720440025840,
          },
          {
            leverage: -0.04000000020000001,
            order_type: "SHORT",
            order_uuid: "903157b7-aa7d-4608-8c4b-9ee86ef67c5d",
            price: 3076.71,
            price_sources: [],
            processed_ms: 1720508413254,
          },
          {
            leverage: -0.050000000249999996,
            order_type: "SHORT",
            order_uuid: "80cc38c1-2460-470f-8fb4-36ef75dd6640",
            price: 3118.7,
            price_sources: [],
            processed_ms: 1720587616919,
          },
          {
            leverage: 0,
            order_type: "FLAT",
            order_uuid: "5b382f60-68bc-45fb-907a-6715f83ea0ee",
            price: 3103.99,
            price_sources: [],
            processed_ms: 1720713919910,
          },
        ],
        position_type: "FLAT",
        position_uuid: "248d8731-2458-46e6-83fc-34afe28b5bab",
        return_at_close: 1.002113561591563,
        trade_pair: ["ETHUSD", "ETH/USD", 0.003, 0.01, 0.5],
      },
      {
        average_entry_price: 0.9742999999999999,
        close_ms: 1720717217640,
        current_return: 1.0000985322795855,
        is_closed_position: true,
        miner_hotkey: "5GhCxfBcA7Ur5iiAS343xwvrYHTUfBjBi4JimiL5LhujRT9t",
        net_leverage: 0,
        open_ms: 1720645208539,
        orders: [
          {
            leverage: -0.04000000000000001,
            order_type: "SHORT",
            order_uuid: "6fa4e8a6-53ff-4196-b601-e0a2a5e7c10c",
            price: 0.9743,
            price_sources: [],
            processed_ms: 1720645208033,
          },
          {
            leverage: 0,
            order_type: "FLAT",
            order_uuid: "0205ad1c-c2b8-4440-86af-af2d547a45f9",
            price: 0.9719,
            price_sources: [],
            processed_ms: 1720717217640,
          },
        ],
        position_type: "FLAT",
        position_uuid: "6fa4e8a6-53ff-4196-b601-e0a2a5e7c10c",
        return_at_close: 1.0000957320036952,
        trade_pair: ["EURCHF", "EUR/CHF", 0.00007, 0.1, 5],
      },
      {
        average_entry_price: 172.368,
        close_ms: 1720731609466,
        current_return: 1.000053825184154,
        is_closed_position: true,
        miner_hotkey: "5GhCxfBcA7Ur5iiAS343xwvrYHTUfBjBi4JimiL5LhujRT9t",
        net_leverage: 0,
        open_ms: 1720702867230,
        orders: [
          {
            leverage: 0.020000000000000004,
            order_type: "LONG",
            order_uuid: "2ddf8f31-10aa-4fff-bc40-28dc7af0c4d1",
            price: 172.41,
            price_sources: [],
            processed_ms: 1720702867220,
          },
          {
            leverage: 0.020000000000000004,
            order_type: "LONG",
            order_uuid: "206544cc-1ae7-4dcd-8bee-2afcafd533de",
            price: 172.326,
            price_sources: [],
            processed_ms: 1720717216102,
          },
          {
            leverage: 0,
            order_type: "FLAT",
            order_uuid: "462703ae-e41e-40f3-b6f8-6df583b51bc7",
            price: 172.6,
            price_sources: [],
            processed_ms: 1720731609466,
          },
        ],
        position_type: "FLAT",
        position_uuid: "2ddf8f31-10aa-4fff-bc40-28dc7af0c4d1",
        return_at_close: 1.0000510250334436,
        trade_pair: ["EURJPY", "EUR/JPY", 0.00007, 0.1, 5],
      },
      {
        average_entry_price: 177.34,
        close_ms: 1720746009325,
        current_return: 1.0001542799142888,
        is_closed_position: true,
        miner_hotkey: "5GhCxfBcA7Ur5iiAS343xwvrYHTUfBjBi4JimiL5LhujRT9t",
        net_leverage: 0,
        open_ms: 1720732265222,
        orders: [
          {
            leverage: 0.19,
            order_type: "LONG",
            order_uuid: "0b9157c0-0ea8-4803-a1f7-e64fd7d742bd",
            price: 177.34,
            price_sources: [],
            processed_ms: 1720732264965,
          },
          {
            leverage: 0,
            order_type: "FLAT",
            order_uuid: "1d80516c-cab0-43b5-bff7-a997d963b520",
            price: 177.484,
            price_sources: [],
            processed_ms: 1720746009325,
          },
        ],
        position_type: "FLAT",
        position_uuid: "0b9157c0-0ea8-4803-a1f7-e64fd7d742bd",
        return_at_close: 1.000140977862366,
        trade_pair: ["CHFJPY", "CHF/JPY", 0.00007, 0.1, 5],
      },
      {
        average_entry_price: 158.08549999999997,
        close_ms: 1720746050145,
        current_return: 1.000131863973892,
        is_closed_position: true,
        miner_hotkey: "5GhCxfBcA7Ur5iiAS343xwvrYHTUfBjBi4JimiL5LhujRT9t",
        net_leverage: 0,
        open_ms: 1720717216606,
        orders: [
          {
            leverage: 0.04000000000000001,
            order_type: "LONG",
            order_uuid: "7bced480-1cd7-414f-ac66-ce86eeb7edf6",
            price: 158.5725,
            price_sources: [],
            processed_ms: 1720717216596,
          },
          {
            leverage: -0.020000000000000004,
            order_type: "SHORT",
            order_uuid: "cbb97be3-143d-4166-8473-8ae02d3a41a8",
            price: 159.0595,
            price_sources: [],
            processed_ms: 1720746009380,
          },
          {
            leverage: 0,
            order_type: "FLAT",
            order_uuid: "b299067a-23e8-4c63-99f8-27c39e0868da",
            price: 159.131,
            price_sources: [],
            processed_ms: 1720746050145,
          },
        ],
        position_type: "FLAT",
        position_uuid: "7bced480-1cd7-414f-ac66-ce86eeb7edf6",
        return_at_close: 1.000129063604673,
        trade_pair: ["USDJPY", "USD/JPY", 0.00007, 0.1, 5],
      },
      {
        average_entry_price: 107.2595,
        close_ms: 1720760409632,
        current_return: 1.0001657662025276,
        is_closed_position: true,
        miner_hotkey: "5GhCxfBcA7Ur5iiAS343xwvrYHTUfBjBi4JimiL5LhujRT9t",
        net_leverage: 0,
        open_ms: 1720717221149,
        orders: [
          {
            leverage: 0.04000000000000001,
            order_type: "LONG",
            order_uuid: "e249f554-11cf-474d-90c7-568744fc9e23",
            price: 107.2595,
            price_sources: [],
            processed_ms: 1720717221149,
          },
          {
            leverage: 0,
            order_type: "FLAT",
            order_uuid: "325049bc-87b5-42fa-b37b-311930a203ae",
            price: 107.704,
            price_sources: [],
            processed_ms: 1720760409632,
          },
        ],
        position_type: "FLAT",
        position_uuid: "e249f554-11cf-474d-90c7-568744fc9e23",
        return_at_close: 1.0001629657383824,
        trade_pair: ["AUDJPY", "AUD/JPY", 0.00007, 0.1, 5],
      },
      {
        average_entry_price: 0.67886,
        close_ms: 1720994414709,
        current_return: 1.0000530300798398,
        is_closed_position: true,
        miner_hotkey: "5GhCxfBcA7Ur5iiAS343xwvrYHTUfBjBi4JimiL5LhujRT9t",
        net_leverage: 0,
        open_ms: 1720796419479,
        orders: [
          {
            leverage: -0.020000000000000004,
            order_type: "SHORT",
            order_uuid: "e061ba58-996f-469f-8037-838858fe2050",
            price: 0.67886,
            price_sources: [],
            processed_ms: 1720796419479,
          },
          {
            leverage: 0,
            order_type: "FLAT",
            order_uuid: "95e21454-f07d-46f4-9a92-059dbc9de762",
            price: 0.67706,
            price_sources: [],
            processed_ms: 1720994414709,
          },
        ],
        position_type: "FLAT",
        position_uuid: "e061ba58-996f-469f-8037-838858fe2050",
        return_at_close: 1.0000516300055977,
        trade_pair: ["AUDUSD", "AUD/USD", 0.00007, 0.1, 5],
      },
      {
        average_entry_price: 0.83996,
        close_ms: 1721048500324,
        current_return: 1.0000926657439693,
        is_closed_position: true,
        miner_hotkey: "5GhCxfBcA7Ur5iiAS343xwvrYHTUfBjBi4JimiL5LhujRT9t",
        net_leverage: 0,
        open_ms: 1720702861513,
        orders: [
          {
            leverage: 0.03,
            order_type: "LONG",
            order_uuid: "3bbdeb00-bc3a-4cb5-9562-e5b82928aa3d",
            price: 0.841735,
            price_sources: [],
            processed_ms: 1720702861513,
          },
          {
            leverage: 0.09,
            order_type: "LONG",
            order_uuid: "c992fa0a-6037-46a7-98e7-a7bdc6059285",
            price: 0.839595,
            price_sources: [],
            processed_ms: 1720803617709,
          },
          {
            leverage: 0.03,
            order_type: "LONG",
            order_uuid: "a623e753-9564-4072-bb8e-f58840676d7a",
            price: 0.83928,
            price_sources: [],
            processed_ms: 1720991052602,
          },
          {
            leverage: 0,
            order_type: "FLAT",
            order_uuid: "3ad85f98-09ae-402f-84bd-eedb95118109",
            price: 0.84048,
            price_sources: [],
            processed_ms: 1721048500324,
          },
        ],
        position_type: "FLAT",
        position_uuid: "3bbdeb00-bc3a-4cb5-9562-e5b82928aa3d",
        return_at_close: 1.000082164770979,
        trade_pair: ["EURGBP", "EUR/GBP", 0.00007, 0.1, 5],
      },
      {
        average_entry_price: 47518.763888888774,
        close_ms: 1721088768172,
        current_return: 1.0085843259339693,
        is_closed_position: true,
        miner_hotkey: "5GhCxfBcA7Ur5iiAS343xwvrYHTUfBjBi4JimiL5LhujRT9t",
        net_leverage: 0,
        open_ms: 1720051217045,
        orders: [
          {
            leverage: 0.011666666724999997,
            order_type: "LONG",
            order_uuid: "cb52f8c5-3a6b-48e9-b6ed-38b7d52153aa",
            price: 60179.54,
            price_sources: [],
            processed_ms: 1720051217045,
          },
          {
            leverage: 0.008333333375000005,
            order_type: "LONG",
            order_uuid: "60f9d7d7-1d5d-4700-bd73-e0db6df03542",
            price: 59018,
            price_sources: [],
            processed_ms: 1720060211415,
          },
          {
            leverage: 0.008333333374999998,
            order_type: "LONG",
            order_uuid: "9b5c12de-e785-44f0-95ff-0d463074ee99",
            price: 59044.73,
            price_sources: [],
            processed_ms: 1720065613285,
          },
          {
            leverage: 0.021666666774999996,
            order_type: "LONG",
            order_uuid: "f369c574-4a32-4227-9f30-44d06bc298b6",
            price: 59052.47,
            price_sources: [],
            processed_ms: 1720065638946,
          },
          {
            leverage: 0.008333333375000009,
            order_type: "LONG",
            order_uuid: "c106ffb0-dfe5-4bf7-a841-04a672feef1f",
            price: 58816.6,
            price_sources: [],
            processed_ms: 1720070106028,
          },
          {
            leverage: 0.008333333375000009,
            order_type: "LONG",
            order_uuid: "8f183727-aceb-4d95-87e0-439b2006df88",
            price: 58387,
            price_sources: [],
            processed_ms: 1720078209720,
          },
          {
            leverage: 0.008333333374999974,
            order_type: "LONG",
            order_uuid: "e3da1517-2eff-47ac-b765-552021a6f436",
            price: 58419.9,
            price_sources: [],
            processed_ms: 1720078221753,
          },
          {
            leverage: 0.010000000050000027,
            order_type: "LONG",
            order_uuid: "b244d37c-2389-4bb3-bd21-6401992e26df",
            price: 58205.7,
            price_sources: [],
            processed_ms: 1720080015521,
          },
          {
            leverage: 0.008333333374999974,
            order_type: "LONG",
            order_uuid: "5cac498b-6c43-4718-a1ba-1e90363d6164",
            price: 58019.62,
            price_sources: [],
            processed_ms: 1720081809346,
          },
          {
            leverage: 0.008333333374999988,
            order_type: "LONG",
            order_uuid: "0a9148df-98df-4cc8-819e-e74e6f4f7d84",
            price: 57600,
            price_sources: [],
            processed_ms: 1720082714202,
          },
          {
            leverage: 0.006666666700000004,
            order_type: "LONG",
            order_uuid: "892e1f34-fa94-4922-82c4-009f0dd8c0a0",
            price: 57530.97,
            price_sources: [],
            processed_ms: 1720083006380,
          },
          {
            leverage: 0.016666666750000017,
            order_type: "LONG",
            order_uuid: "fa3b276a-af9c-4823-abe7-5ee9123c5f2c",
            price: 57628.66,
            price_sources: [],
            processed_ms: 1720083624711,
          },
          {
            leverage: 0.006666666700000018,
            order_type: "LONG",
            order_uuid: "b43e05e1-ef85-4ebf-ad46-80301d3ef128",
            price: 57389.94,
            price_sources: [],
            processed_ms: 1720084210108,
          },
          {
            leverage: 0.008333333375000002,
            order_type: "LONG",
            order_uuid: "7ffff07f-b0b3-4203-8c9c-8bce8340e580",
            price: 57393.47,
            price_sources: [],
            processed_ms: 1720084509868,
          },
          {
            leverage: 0.00666666669999999,
            order_type: "LONG",
            order_uuid: "faa9378c-cb59-4ffd-8dc1-e684f17fe81c",
            price: 57136.17,
            price_sources: [],
            processed_ms: 1720084813047,
          },
          {
            leverage: -0.020000000100000026,
            order_type: "SHORT",
            order_uuid: "d7a03fe8-3f25-4c91-8a99-378c7f0f9616",
            price: 57675.16,
            price_sources: [],
            processed_ms: 1720085707129,
          },
          {
            leverage: 0.008333333374999974,
            order_type: "LONG",
            order_uuid: "a5efbab5-ed23-463f-9de3-5cf355a8da52",
            price: 57547.16,
            price_sources: [],
            processed_ms: 1720088105039,
          },
          {
            leverage: 0.033333333500000006,
            order_type: "LONG",
            order_uuid: "6f561645-50ea-433f-8b54-e287921c80b8",
            price: 57874.35,
            price_sources: [],
            processed_ms: 1720090820695,
          },
          {
            leverage: 0.001666666675000067,
            order_type: "LONG",
            order_uuid: "06238d14-2ffc-448a-a9a5-61d45917b86c",
            price: 57691,
            price_sources: [],
            processed_ms: 1720094408158,
          },
          {
            leverage: 0.001666666675000067,
            order_type: "LONG",
            order_uuid: "4deecdb6-892e-4148-8ab3-7379487ea1bc",
            price: 57678.61,
            price_sources: [],
            processed_ms: 1720094421468,
          },
          {
            leverage: 0.008333333374999946,
            order_type: "LONG",
            order_uuid: "e1c1fdf1-5d60-40f9-88ed-5185e433e5a5",
            price: 57661.9,
            price_sources: [],
            processed_ms: 1720094449347,
          },
          {
            leverage: 0.008333333375000002,
            order_type: "LONG",
            order_uuid: "8aa12629-88d2-4326-b5f5-69263b6c9aad",
            price: 57545.95,
            price_sources: [],
            processed_ms: 1720095309600,
          },
          {
            leverage: 0.008333333375000002,
            order_type: "LONG",
            order_uuid: "d0525e47-1bfb-40ea-9c54-63036ce39bee",
            price: 57454.13,
            price_sources: [],
            processed_ms: 1720096211104,
          },
          {
            leverage: 0.016666666750000003,
            order_type: "LONG",
            order_uuid: "5906c850-baf0-4244-a88d-79900487de1b",
            price: 57197.85,
            price_sources: [],
            processed_ms: 1720097705019,
          },
          {
            leverage: 0.008333333374999974,
            order_type: "LONG",
            order_uuid: "46cdc61a-e898-4b57-a9b6-e4de9a8f5472",
            price: 57202.95,
            price_sources: [],
            processed_ms: 1720098019547,
          },
          {
            leverage: 0.008333333374999974,
            order_type: "LONG",
            order_uuid: "ad2375da-5d62-490d-a5fc-c3b35cc02391",
            price: 57178.33,
            price_sources: [],
            processed_ms: 1720098058167,
          },
          {
            leverage: 0.025000000125000005,
            order_type: "LONG",
            order_uuid: "547fd0d5-c29d-4f3e-a499-bf45bd67bafb",
            price: 56899.9,
            price_sources: [],
            processed_ms: 1720099806994,
          },
          {
            leverage: 0.0050000000250000065,
            order_type: "LONG",
            order_uuid: "093c98e5-8b6d-4e6b-8ae2-8c6bc3f872d0",
            price: 56850.56,
            price_sources: [],
            processed_ms: 1720100406544,
          },
          {
            leverage: 0.00833333337500003,
            order_type: "LONG",
            order_uuid: "36442b22-e8f9-4f72-b525-62bc57b0def6",
            price: 56807.08,
            price_sources: [],
            processed_ms: 1720100707934,
          },
          {
            leverage: 0.008333333374999974,
            order_type: "LONG",
            order_uuid: "a427bb7b-6b12-4e8d-adaa-202a13f32bc0",
            price: 56804.68,
            price_sources: [],
            processed_ms: 1720100747188,
          },
          {
            leverage: -0.0050000000250000065,
            order_type: "SHORT",
            order_uuid: "c67463ba-a649-4738-ae2d-5a1c5e78f5bc",
            price: 57182.2,
            price_sources: [],
            processed_ms: 1720101006069,
          },
          {
            leverage: -0.03333333349999995,
            order_type: "SHORT",
            order_uuid: "898becb2-fa29-4b99-9277-b6747d70b605",
            price: 57378.47,
            price_sources: [],
            processed_ms: 1720101306802,
          },
          {
            leverage: 0.03333333349999995,
            order_type: "LONG",
            order_uuid: "348ded9c-bb14-46f9-9461-074b316da72d",
            price: 57339.78,
            price_sources: [],
            processed_ms: 1720101623977,
          },
          {
            leverage: 0.005000000024999951,
            order_type: "LONG",
            order_uuid: "bbf91c7c-a647-489e-84a6-dec8fb1d300d",
            price: 56971.01,
            price_sources: [],
            processed_ms: 1720103410123,
          },
          {
            leverage: -0.005000000024999951,
            order_type: "SHORT",
            order_uuid: "dfa7f7ae-e6fb-4ad7-a002-cfd317890098",
            price: 57394.68,
            price_sources: [],
            processed_ms: 1720105212410,
          },
          {
            leverage: -0.141666667375,
            order_type: "SHORT",
            order_uuid: "278ede48-9b6d-4b5a-bbe2-61125338f424",
            price: 58079,
            price_sources: [],
            processed_ms: 1720108813876,
          },
          {
            leverage: -0.066666667,
            order_type: "SHORT",
            order_uuid: "97d8dd60-0815-4085-93fe-f934e07db8ee",
            price: 58102,
            price_sources: [],
            processed_ms: 1720108831311,
          },
          {
            leverage: 0.018333333425000015,
            order_type: "LONG",
            order_uuid: "f8e65be2-6e44-46bc-86fc-11a448a99122",
            price: 57118,
            price_sources: [],
            processed_ms: 1720137619688,
          },
          {
            leverage: 0.0016666666749999837,
            order_type: "LONG",
            order_uuid: "6c9f5c6b-78f8-45d9-b2e5-b7d7f772729a",
            price: 57152,
            price_sources: [],
            processed_ms: 1720137632246,
          },
          {
            leverage: 0.0050000000250000065,
            order_type: "LONG",
            order_uuid: "1022d2c3-82b6-46c7-977b-df0d67f95121",
            price: 57364.63,
            price_sources: [],
            processed_ms: 1720141218412,
          },
          {
            leverage: 0.0016666666750000114,
            order_type: "LONG",
            order_uuid: "fd0bb4f8-72c6-404b-9563-1de18bbf1730",
            price: 55853.53,
            price_sources: [],
            processed_ms: 1720148412188,
          },
          {
            leverage: 0.01666666674999999,
            order_type: "LONG",
            order_uuid: "a0b2a066-ac1c-442a-a7c5-25c886ce0943",
            price: 55415.45,
            price_sources: [],
            processed_ms: 1720150209530,
          },
          {
            leverage: 0.015000000075000006,
            order_type: "LONG",
            order_uuid: "8993c675-607c-4cc0-8399-2e335b90797c",
            price: 55324.86,
            price_sources: [],
            processed_ms: 1720151109126,
          },
          {
            leverage: 0.026666666800000002,
            order_type: "LONG",
            order_uuid: "7401bf23-5649-4d54-a33e-a0cae40bb4f0",
            price: 55195.2,
            price_sources: [],
            processed_ms: 1720152012608,
          },
          {
            leverage: 0.02333333345000002,
            order_type: "LONG",
            order_uuid: "d1ee4b34-3647-4f6a-8de1-7480b48fd1fc",
            price: 55052.29,
            price_sources: [],
            processed_ms: 1720152913971,
          },
          {
            leverage: 0.006666666700000018,
            order_type: "LONG",
            order_uuid: "1a0d9620-35d0-4678-a52c-11fde5319b97",
            price: 54249.67,
            price_sources: [],
            processed_ms: 1720153504781,
          },
          {
            leverage: 0.005000000024999951,
            order_type: "LONG",
            order_uuid: "3cdf8c28-4e8b-48c4-b9d0-4733db94b1e5",
            price: 54255.77,
            price_sources: [],
            processed_ms: 1720153542364,
          },
          {
            leverage: 0.00666666669999999,
            order_type: "LONG",
            order_uuid: "80fe27b6-73a8-4855-b505-381e65c22105",
            price: 54439.03,
            price_sources: [],
            processed_ms: 1720153812101,
          },
          {
            leverage: 0.006666666700000018,
            order_type: "LONG",
            order_uuid: "69360d18-9eff-4016-9262-984011bef20c",
            price: 54312.52,
            price_sources: [],
            processed_ms: 1720154108907,
          },
          {
            leverage: 0.00666666669999999,
            order_type: "LONG",
            order_uuid: "e3a9e583-96f6-4b50-a3af-de8557ec0d8f",
            price: 54203.71,
            price_sources: [],
            processed_ms: 1720155310126,
          },
          {
            leverage: 0.03000000015000001,
            order_type: "LONG",
            order_uuid: "2a003dab-7be7-4954-bbb5-14ae3266088a",
            price: 53875.9,
            price_sources: [],
            processed_ms: 1720155615280,
          },
          {
            leverage: 0.01666666675000003,
            order_type: "LONG",
            order_uuid: "0dbc65ee-b5e6-4825-93c7-38cbd176475d",
            price: 54036.62,
            price_sources: [],
            processed_ms: 1720155903666,
          },
          {
            leverage: -0.010000000049999985,
            order_type: "SHORT",
            order_uuid: "74519438-c77a-4f13-84dc-5a793a7e77f6",
            price: 54433.05,
            price_sources: [],
            processed_ms: 1720156513292,
          },
          {
            leverage: -0.07000000035000004,
            order_type: "SHORT",
            order_uuid: "4f51694d-cfe7-40cd-a96e-60abb906ecbf",
            price: 54740.78,
            price_sources: [],
            processed_ms: 1720158609341,
          },
          {
            leverage: 0.008333333375000057,
            order_type: "LONG",
            order_uuid: "16ca05db-1a64-4196-a50d-a7982b063ecb",
            price: 54295.39,
            price_sources: [],
            processed_ms: 1720161010710,
          },
          {
            leverage: 0.005000000024999951,
            order_type: "LONG",
            order_uuid: "7193decb-4086-4d30-9812-64317005e99c",
            price: 54406,
            price_sources: [],
            processed_ms: 1720161910760,
          },
          {
            leverage: 0.0016666666749999837,
            order_type: "LONG",
            order_uuid: "deeda9f7-5dd8-4124-8f35-707ac3f4db21",
            price: 54381.18,
            price_sources: [],
            processed_ms: 1720166416912,
          },
          {
            leverage: 0.008333333375000002,
            order_type: "LONG",
            order_uuid: "57c07144-708a-4769-80ab-c96704863f97",
            price: 54385.87,
            price_sources: [],
            processed_ms: 1720166427894,
          },
          {
            leverage: 0.008333333375000002,
            order_type: "LONG",
            order_uuid: "c421a4cf-5122-4dfc-be21-edabda234ea6",
            price: 54372.73,
            price_sources: [],
            processed_ms: 1720166455527,
          },
          {
            leverage: 0.021666666774999982,
            order_type: "LONG",
            order_uuid: "dcd3ff6e-c69f-44f4-868c-8e4eb1c45227",
            price: 54074,
            price_sources: [],
            processed_ms: 1720170017005,
          },
          {
            leverage: 0.025000000124999977,
            order_type: "LONG",
            order_uuid: "9d3bb751-5042-4c5a-8b61-177e51c8ddb7",
            price: 53931.62,
            price_sources: [],
            processed_ms: 1720170911140,
          },
          {
            leverage: -0.008333333375000002,
            order_type: "SHORT",
            order_uuid: "8f0f3815-21ab-4677-83e3-a4f1c28c5d5a",
            price: 54336.8,
            price_sources: [],
            processed_ms: 1720171812943,
          },
          {
            leverage: -0.07666666705,
            order_type: "SHORT",
            order_uuid: "52b516b0-476b-438e-8836-67ade0c1029f",
            price: 54629.11,
            price_sources: [],
            processed_ms: 1720172709461,
          },
          {
            leverage: -0.03333333349999998,
            order_type: "SHORT",
            order_uuid: "4f36d4b6-549b-4ba2-88ff-ba2f131eb2ce",
            price: 55170.39,
            price_sources: [],
            processed_ms: 1720174515444,
          },
          {
            leverage: 0.0016666666750000114,
            order_type: "LONG",
            order_uuid: "1ac7d644-08df-4f3e-b9c8-d1590bc0ef12",
            price: 55449.41,
            price_sources: [],
            processed_ms: 1720180810110,
          },
          {
            leverage: -0.003333333349999995,
            order_type: "SHORT",
            order_uuid: "627f2b0b-6eef-4658-9fae-cf87635509f7",
            price: 55432.02,
            price_sources: [],
            processed_ms: 1720180851304,
          },
          {
            leverage: 0.0050000000250000065,
            order_type: "LONG",
            order_uuid: "7ba27e4a-8c0a-4f0c-b0f5-bfcdcb61ae8c",
            price: 55064.21,
            price_sources: [],
            processed_ms: 1720184415504,
          },
          {
            leverage: -0.010000000050000013,
            order_type: "SHORT",
            order_uuid: "854fdfd2-0510-4e6f-835d-b504b727c6c8",
            price: 55853.58,
            price_sources: [],
            processed_ms: 1720188017992,
          },
          {
            leverage: -0.01666666675000003,
            order_type: "SHORT",
            order_uuid: "f45ce2d2-ecb8-4d20-a439-796af920dbdd",
            price: 56731.11,
            price_sources: [],
            processed_ms: 1720195223407,
          },
          {
            leverage: -0.010000000049999985,
            order_type: "SHORT",
            order_uuid: "ab7f849d-4b0a-4464-b8fd-9f7acfe21e83",
            price: 56763.22,
            price_sources: [],
            processed_ms: 1720260008316,
          },
          {
            leverage: -0.050000000249999996,
            order_type: "SHORT",
            order_uuid: "2a738a7e-ccea-417e-885d-f783895ac113",
            price: 57322.11,
            price_sources: [],
            processed_ms: 1720281609126,
          },
          {
            leverage: 0.006666666700000011,
            order_type: "LONG",
            order_uuid: "76cd661d-8c7d-415f-b906-6a3889dcdb6b",
            price: 55062.6,
            price_sources: [],
            processed_ms: 1720400413156,
          },
          {
            leverage: 0.006666666699999997,
            order_type: "LONG",
            order_uuid: "e8188553-3b99-490b-97e7-5d891ca4756b",
            price: 54531.84,
            price_sources: [],
            processed_ms: 1720401308328,
          },
          {
            leverage: 0.00666666669999999,
            order_type: "LONG",
            order_uuid: "d349bded-e74c-4865-b527-da20f997329e",
            price: 54478.8,
            price_sources: [],
            processed_ms: 1720401605214,
          },
          {
            leverage: 0.0050000000250000065,
            order_type: "LONG",
            order_uuid: "02df62b7-9d9e-4e30-acfd-7de458b97d5a",
            price: 54389.56,
            price_sources: [],
            processed_ms: 1720401908141,
          },
          {
            leverage: -0.0050000000250000065,
            order_type: "SHORT",
            order_uuid: "515ae106-ed73-42ac-ba41-b1cea5447bc2",
            price: 54764.88,
            price_sources: [],
            processed_ms: 1720402210418,
          },
          {
            leverage: -0.0200000001,
            order_type: "SHORT",
            order_uuid: "74fb7ba9-98d7-44a6-b868-94c0db7718e9",
            price: 54818.63,
            price_sources: [],
            processed_ms: 1720403709159,
          },
          {
            leverage: 0.008333333375000009,
            order_type: "LONG",
            order_uuid: "ce792779-d616-46f7-bf39-843339a4ab64",
            price: 55254.29,
            price_sources: [],
            processed_ms: 1720405823260,
          },
          {
            leverage: -0.008333333375000009,
            order_type: "SHORT",
            order_uuid: "162c4685-018a-49b7-afdd-e7389f54671b",
            price: 55563.55,
            price_sources: [],
            processed_ms: 1720415706826,
          },
          {
            leverage: 0.006666666700000011,
            order_type: "LONG",
            order_uuid: "a275e466-6dc1-43c3-9b01-176fd58c9c38",
            price: 56599.69,
            price_sources: [],
            processed_ms: 1720747814430,
          },
          {
            leverage: -0.006666666700000011,
            order_type: "SHORT",
            order_uuid: "bedd4fa5-a6b9-4433-8f5a-a66f80443853",
            price: 56875.32,
            price_sources: [],
            processed_ms: 1720749310397,
          },
          {
            leverage: -0.01666666675,
            order_type: "SHORT",
            order_uuid: "3c5cf81b-07e7-49ef-94b1-44e4666d016a",
            price: 64816.6,
            price_sources: [],
            processed_ms: 1721088021303,
          },
          {
            leverage: 0,
            order_type: "FLAT",
            order_uuid: "f9df4665-4a16-4619-b955-fb877fa081f2",
            price: 64738.79,
            price_sources: [],
            processed_ms: 1721088768172,
          },
        ],
        position_type: "FLAT",
        position_uuid: "cb52f8c5-3a6b-48e9-b6ed-38b7d52153aa",
        return_at_close: 1.0078922552379534,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.01, 0.5],
      },
      {
        average_entry_price: 171.97899999999998,
        close_ms: 1721091756670,
        current_return: 1.0001811267654772,
        is_closed_position: true,
        miner_hotkey: "5GhCxfBcA7Ur5iiAS343xwvrYHTUfBjBi4JimiL5LhujRT9t",
        net_leverage: 0,
        open_ms: 1721062870010,
        orders: [
          {
            leverage: 0.05,
            order_type: "LONG",
            order_uuid: "565af13a-fdd5-490a-b4f6-057924c60a39",
            price: 171.979,
            price_sources: [],
            processed_ms: 1721062870010,
          },
          {
            leverage: 0,
            order_type: "FLAT",
            order_uuid: "4d25a1c6-e603-45b7-8a18-8b41ed04ea4f",
            price: 172.602,
            price_sources: [],
            processed_ms: 1721091756670,
          },
        ],
        position_type: "FLAT",
        position_uuid: "565af13a-fdd5-490a-b4f6-057924c60a39",
        return_at_close: 1.0001734529049198,
        trade_pair: ["EURJPY", "EUR/JPY", 0.00007, 0.1, 5],
      },
      {
        average_entry_price: 158.078,
        close_ms: 1721091757615,
        current_return: 1.0000501018484547,
        is_closed_position: true,
        miner_hotkey: "5GhCxfBcA7Ur5iiAS343xwvrYHTUfBjBi4JimiL5LhujRT9t",
        net_leverage: 0,
        open_ms: 1720991050513,
        orders: [
          {
            leverage: 0.020000000000000004,
            order_type: "LONG",
            order_uuid: "2e76ab39-6989-48ee-a273-f3bba379ca92",
            price: 158.078,
            price_sources: [],
            processed_ms: 1720991050513,
          },
          {
            leverage: 0,
            order_type: "FLAT",
            order_uuid: "7e0c5401-54e6-4d70-82c0-1ffd135ae084",
            price: 158.474,
            price_sources: [],
            processed_ms: 1721091757615,
          },
        ],
        position_type: "FLAT",
        position_uuid: "2e76ab39-6989-48ee-a273-f3bba379ca92",
        return_at_close: 1.0000470327007513,
        trade_pair: ["USDJPY", "USD/JPY", 0.00007, 0.1, 5],
      },
      {
        average_entry_price: 176.2194548156598,
        close_ms: 1721091763562,
        current_return: 1.000234423320399,
        is_closed_position: true,
        miner_hotkey: "5GhCxfBcA7Ur5iiAS343xwvrYHTUfBjBi4JimiL5LhujRT9t",
        net_leverage: 0,
        open_ms: 1720803610916,
        orders: [
          {
            leverage: 0.04000000000000001,
            order_type: "LONG",
            order_uuid: "8fbc46fb-f0de-4a6c-a247-86dfc2fc53bf",
            price: 176.46800000000002,
            price_sources: [],
            processed_ms: 1720803610916,
          },
          {
            leverage: 0.04000000000000001,
            order_type: "LONG",
            order_uuid: "d99df415-a98a-4758-9e15-14666f8e8553",
            price: 176.4040453721066,
            price_sources: [],
            processed_ms: 1721005208633,
          },
          {
            leverage: -0.04000000000000001,
            order_type: "SHORT",
            order_uuid: "27799725-01cd-407a-8456-e2b07ac51885",
            price: 176.682,
            price_sources: [],
            processed_ms: 1721048497932,
          },
          {
            leverage: 0.020000000000000004,
            order_type: "LONG",
            order_uuid: "304430c1-6238-4863-aabd-b0793da0ce67",
            price: 176.2782737027662,
            price_sources: [],
            processed_ms: 1721062873777,
          },
          {
            leverage: 0,
            order_type: "FLAT",
            order_uuid: "0721b626-4222-43cc-95ca-3781ea50284c",
            price: 176.9089250573959,
            price_sources: [],
            processed_ms: 1721091763562,
          },
        ],
        position_type: "FLAT",
        position_uuid: "8fbc46fb-f0de-4a6c-a247-86dfc2fc53bf",
        return_at_close: 1.0002274216794358,
        trade_pair: ["CHFJPY", "CHF/JPY", 0.00007, 0.1, 5],
      },
      {
        average_entry_price: 115.50141666666663,
        close_ms: 1721095223950,
        current_return: 1.0004143233307907,
        is_closed_position: true,
        miner_hotkey: "5GhCxfBcA7Ur5iiAS343xwvrYHTUfBjBi4JimiL5LhujRT9t",
        net_leverage: 0,
        open_ms: 1720792812146,
        orders: [
          {
            leverage: 0.020000000000000004,
            order_type: "LONG",
            order_uuid: "1d37c54c-a122-475c-afd2-22054494319c",
            price: 116.0205,
            price_sources: [],
            processed_ms: 1720792812146,
          },
          {
            leverage: 0.04000000000000001,
            order_type: "LONG",
            order_uuid: "a18692bc-a7f9-4fc1-8875-2161e3fbfaf1",
            price: 115.78,
            price_sources: [],
            processed_ms: 1720796411682,
          },
          {
            leverage: 0.020000000000000004,
            order_type: "LONG",
            order_uuid: "eefc1e70-89ed-47d2-8295-e627b40cc9be",
            price: 115.875,
            price_sources: [],
            processed_ms: 1720800015381,
          },
          {
            leverage: 0.060000000000000026,
            order_type: "LONG",
            order_uuid: "3be98873-d0fc-46b0-b38d-a243e7434dec",
            price: 115.774,
            price_sources: [],
            processed_ms: 1720814411374,
          },
          {
            leverage: 0.01999999999999999,
            order_type: "LONG",
            order_uuid: "3ce610fc-22c1-4aa7-9a1b-c3830d890c52",
            price: 115.835,
            price_sources: [],
            processed_ms: 1720991052458,
          },
          {
            leverage: -0.1,
            order_type: "SHORT",
            order_uuid: "0659567f-c3a0-4dd3-a759-e8b9b70e4f7d",
            price: 115.921,
            price_sources: [],
            processed_ms: 1720994414872,
          },
          {
            leverage: 0.020000000000000004,
            order_type: "LONG",
            order_uuid: "9eeb36a1-5e9d-40ea-a0e5-959ec1fdc76d",
            price: 115.6845,
            price_sources: [],
            processed_ms: 1721019611619,
          },
          {
            leverage: 0.01999999999999999,
            order_type: "LONG",
            order_uuid: "fc5a3398-e968-4d2a-93c7-58ffd3d0fbc5",
            price: 115.5895,
            price_sources: [],
            processed_ms: 1721055623079,
          },
          {
            leverage: 0.02000000000000003,
            order_type: "LONG",
            order_uuid: "ec87839b-ed46-470f-930e-f1ba88ca4fc8",
            price: 115.549,
            price_sources: [],
            processed_ms: 1721059210685,
          },
          {
            leverage: 0.03999999999999998,
            order_type: "LONG",
            order_uuid: "ab58b860-339a-4be5-a2db-1f4f6abe0fb9",
            price: 115.381,
            price_sources: [],
            processed_ms: 1721062870057,
          },
          {
            leverage: -0.04000000000000001,
            order_type: "SHORT",
            order_uuid: "141c5912-cb06-4c95-8722-d326872d3024",
            price: 115.792,
            price_sources: [],
            processed_ms: 1721091685572,
          },
          {
            leverage: 0,
            order_type: "FLAT",
            order_uuid: "bedb67d5-8abe-444b-9fd0-959e4a7338f5",
            price: 115.902,
            price_sources: [],
            processed_ms: 1721095223950,
          },
        ],
        position_type: "FLAT",
        position_uuid: "1d37c54c-a122-475c-afd2-22054494319c",
        return_at_close: 1.0003961157901062,
        trade_pair: ["CADJPY", "CAD/JPY", 0.00007, 0.1, 5],
      },
      {
        average_entry_price: 0.9251660706744712,
        close_ms: 1721106062106,
        current_return: 1.0004394113202246,
        is_closed_position: true,
        miner_hotkey: "5GhCxfBcA7Ur5iiAS343xwvrYHTUfBjBi4JimiL5LhujRT9t",
        net_leverage: 0,
        open_ms: 1720702868090,
        orders: [
          {
            leverage: -0.09000000000000001,
            order_type: "SHORT",
            order_uuid: "e126ab7b-2a93-46a9-bb4a-d134c8950651",
            price: 0.92376,
            price_sources: [],
            processed_ms: 1720702868090,
          },
          {
            leverage: 0.04000000000000001,
            order_type: "LONG",
            order_uuid: "9ce1305b-b5ee-4db6-b99e-1e2e294e53a1",
            price: 0.9217,
            price_sources: [],
            processed_ms: 1720717221091,
          },
          {
            leverage: -0.19,
            order_type: "SHORT",
            order_uuid: "a068c07a-4bc5-42ef-a250-ce08ff0431fe",
            price: 0.92342,
            price_sources: [],
            processed_ms: 1720991054179,
          },
          {
            leverage: 0.09999999999999999,
            order_type: "LONG",
            order_uuid: "c134fdb8-6be3-4849-b125-b55784994304",
            price: 0.92357,
            price_sources: [],
            processed_ms: 1720994415498,
          },
          {
            leverage: -0.039999999999999994,
            order_type: "SHORT",
            order_uuid: "4ef8da12-423c-4377-9842-d181db9cf801",
            price: 0.92518,
            price_sources: [],
            processed_ms: 1721019611399,
          },
          {
            leverage: -0.04000000000000001,
            order_type: "SHORT",
            order_uuid: "495e19df-04d2-401b-b87c-ba324dd64d2f",
            price: 0.92542,
            price_sources: [],
            processed_ms: 1721030413054,
          },
          {
            leverage: -0.050000000000000044,
            order_type: "SHORT",
            order_uuid: "0fd6e374-279f-4625-b838-b7b4b128a141",
            price: 0.925253755582623,
            price_sources: [],
            processed_ms: 1721062870029,
          },
          {
            leverage: 0.040000000000000036,
            order_type: "LONG",
            order_uuid: "16124db7-0aa6-4f1c-96bc-9d76c0c0a093",
            price: 0.924674429449014,
            price_sources: [],
            processed_ms: 1721066410833,
          },
          {
            leverage: 0.08000000000000002,
            order_type: "LONG",
            order_uuid: "0308f7ab-5aef-4d6b-bfba-c5aff4059f5f",
            price: 0.9236,
            price_sources: [],
            processed_ms: 1721091683591,
          },
          {
            leverage: 0,
            order_type: "FLAT",
            order_uuid: "ca6ffc20-073d-4272-a16e-8c2ea674c37e",
            price: 0.92246,
            price_sources: [],
            processed_ms: 1721106062106,
          },
        ],
        position_type: "FLAT",
        position_uuid: "e126ab7b-2a93-46a9-bb4a-d134c8950651",
        return_at_close: 1.0004106987091197,
        trade_pair: ["AUDCAD", "AUD/CAD", 0.00007, 0.1, 5],
      },
      {
        average_entry_price: 62600.46,
        close_ms: 1721120109158,
        current_return: 1.000021058418313,
        is_closed_position: true,
        miner_hotkey: "5GhCxfBcA7Ur5iiAS343xwvrYHTUfBjBi4JimiL5LhujRT9t",
        net_leverage: 0,
        open_ms: 1721118912444,
        orders: [
          {
            leverage: 0.0066666667000000015,
            order_type: "LONG",
            order_uuid: "1826c3c0-b5d9-4a04-bf71-36f024e9d7e6",
            price: 62600.46,
            price_sources: [],
            processed_ms: 1721118912444,
          },
          {
            leverage: 0,
            order_type: "FLAT",
            order_uuid: "765360a1-20f0-4fd8-ab5d-4b0079207b11",
            price: 62798.2,
            price_sources: [],
            processed_ms: 1721120109158,
          },
        ],
        position_type: "FLAT",
        position_uuid: "1826c3c0-b5d9-4a04-bf71-36f024e9d7e6",
        return_at_close: 1.0000143916112234,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.01, 0.5],
      },
      {
        average_entry_price: 1.2967450000000003,
        close_ms: 1721134811419,
        current_return: 1.000130686645555,
        is_closed_position: true,
        miner_hotkey: "5GhCxfBcA7Ur5iiAS343xwvrYHTUfBjBi4JimiL5LhujRT9t",
        net_leverage: 0,
        open_ms: 1720774811665,
        orders: [
          {
            leverage: -0.06000000000000001,
            order_type: "SHORT",
            order_uuid: "c235b841-6630-489e-831b-9785a648c66a",
            price: 1.2947000000000002,
            price_sources: [],
            processed_ms: 1720774811665,
          },
          {
            leverage: -0.020000000000000004,
            order_type: "SHORT",
            order_uuid: "7e45851a-1005-4472-9b0f-31b457fc1705",
            price: 1.298775,
            price_sources: [],
            processed_ms: 1720803618223,
          },
          {
            leverage: -0.04000000000000001,
            order_type: "SHORT",
            order_uuid: "b6ffa481-ec87-42cf-ba14-5e5920122d9d",
            price: 1.29716,
            price_sources: [],
            processed_ms: 1720991052594,
          },
          {
            leverage: -0.01999999999999999,
            order_type: "SHORT",
            order_uuid: "ef8bf656-c149-4c68-a706-36443b8b79b3",
            price: 1.29891,
            price_sources: [],
            processed_ms: 1721034004387,
          },
          {
            leverage: 0.039999999999999994,
            order_type: "LONG",
            order_uuid: "9acbc16a-1292-428f-8a78-a0ce90dcf373",
            price: 1.29644,
            price_sources: [],
            processed_ms: 1721091763428,
          },
          {
            leverage: 0.020000000000000004,
            order_type: "LONG",
            order_uuid: "0d4acd22-84f9-4d92-a5f6-19d329fa1370",
            price: 1.2962449999999999,
            price_sources: [],
            processed_ms: 1721106059591,
          },
          {
            leverage: 0,
            order_type: "FLAT",
            order_uuid: "1484d48b-69ba-43aa-b1f2-dc8ca91f45ac",
            price: 1.29463,
            price_sources: [],
            processed_ms: 1721134811419,
          },
        ],
        position_type: "FLAT",
        position_uuid: "c235b841-6630-489e-831b-9785a648c66a",
        return_at_close: 1.000120885364826,
        trade_pair: ["GBPUSD", "GBP/USD", 0.00007, 0.1, 5],
      },
      {
        average_entry_price: 0.82633,
        close_ms: 1721178011699,
        current_return: 1.000169341057241,
        is_closed_position: true,
        miner_hotkey: "5GhCxfBcA7Ur5iiAS343xwvrYHTUfBjBi4JimiL5LhujRT9t",
        net_leverage: 0,
        open_ms: 1721149227667,
        orders: [
          {
            leverage: 0.03,
            order_type: "LONG",
            order_uuid: "25eb5c2e-b626-4c02-85dc-522696661f81",
            price: 0.82633,
            price_sources: [],
            processed_ms: 1721149227667,
          },
          {
            leverage: 0,
            order_type: "FLAT",
            order_uuid: "3ddb01eb-468e-4420-a22a-37843a0fbdc0",
            price: 0.830994386527667,
            price_sources: [],
            processed_ms: 1721178011699,
          },
        ],
        position_type: "FLAT",
        position_uuid: "25eb5c2e-b626-4c02-85dc-522696661f81",
        return_at_close: 1.0001597289842568,
        trade_pair: ["NZDCAD", "NZD/CAD", 0.00007, 0.1, 5],
      },
      {
        average_entry_price: 176.01130386449555,
        close_ms: 1721221267762,
        current_return: 1.0006910860577931,
        is_closed_position: true,
        miner_hotkey: "5GhCxfBcA7Ur5iiAS343xwvrYHTUfBjBi4JimiL5LhujRT9t",
        net_leverage: 0,
        open_ms: 1721206962030,
        orders: [
          {
            leverage: 0.1,
            order_type: "LONG",
            order_uuid: "2d132a29-537e-496c-89c2-6243872ad6cc",
            price: 175.99154695609195,
            price_sources: [],
            processed_ms: 1721206962030,
          },
          {
            leverage: 0.08000000000000002,
            order_type: "LONG",
            order_uuid: "04898a10-0224-4c05-a886-1eee787e3835",
            price: 176.036,
            price_sources: [],
            processed_ms: 1721206972981,
          },
          {
            leverage: 0,
            order_type: "FLAT",
            order_uuid: "740cf100-7335-4814-a664-1076d61c0d5b",
            price: 176.687,
            price_sources: [],
            processed_ms: 1721221267762,
          },
        ],
        position_type: "FLAT",
        position_uuid: "2d132a29-537e-496c-89c2-6243872ad6cc",
        return_at_close: 1.0006784773501087,
        trade_pair: ["CHFJPY", "CHF/JPY", 0.00007, 0.1, 5],
      },
      {
        average_entry_price: 155.593,
        close_ms: 1721293261483,
        current_return: 1.0002134907726394,
        is_closed_position: true,
        miner_hotkey: "5GhCxfBcA7Ur5iiAS343xwvrYHTUfBjBi4JimiL5LhujRT9t",
        net_leverage: 0,
        open_ms: 1721206949962,
        orders: [
          {
            leverage: 0.020000000000000004,
            order_type: "LONG",
            order_uuid: "be895b88-2587-482f-9912-d4a5b81f5c00",
            price: 156.166,
            price_sources: [],
            processed_ms: 1721206949962,
          },
          {
            leverage: 0.020000000000000004,
            order_type: "LONG",
            order_uuid: "d91d9d4e-19d0-46bb-910a-1b107da40c01",
            price: 156.14,
            price_sources: [],
            processed_ms: 1721250020497,
          },
          {
            leverage: 0.04000000000000001,
            order_type: "LONG",
            order_uuid: "03896239-4732-40e1-995d-b4ac88fb3613",
            price: 155.778,
            price_sources: [],
            processed_ms: 1721264470178,
          },
          {
            leverage: -0.04000000000000001,
            order_type: "SHORT",
            order_uuid: "f9ad82e3-d5a5-4638-88f7-ed0a66c55f1b",
            price: 156.338,
            price_sources: [],
            processed_ms: 1721278819370,
          },
          {
            leverage: 0,
            order_type: "FLAT",
            order_uuid: "73c9759e-962d-450e-84d6-76c5cb0fb7db",
            price: 156.4265,
            price_sources: [],
            processed_ms: 1721293261483,
          },
        ],
        position_type: "FLAT",
        position_uuid: "be895b88-2587-482f-9912-d4a5b81f5c00",
        return_at_close: 1.0001978735594155,
        trade_pair: ["USDJPY", "USD/JPY", 0.00007, 0.1, 5],
      },
      {
        average_entry_price: 169.83849999999993,
        close_ms: 1721293261640,
        current_return: 1.0006594892690295,
        is_closed_position: true,
        miner_hotkey: "5GhCxfBcA7Ur5iiAS343xwvrYHTUfBjBi4JimiL5LhujRT9t",
        net_leverage: 0,
        open_ms: 1721235605423,
        orders: [
          {
            leverage: 0.05,
            order_type: "LONG",
            order_uuid: "ce057ed5-e78d-4d49-a3dd-8b8c661f26da",
            price: 170.814,
            price_sources: [],
            processed_ms: 1721235605423,
          },
          {
            leverage: 0.020000000000000004,
            order_type: "LONG",
            order_uuid: "a0952ecb-34ab-4301-bd20-c65add6a6e69",
            price: 170.69,
            price_sources: [],
            processed_ms: 1721255405863,
          },
          {
            leverage: 0.020000000000000004,
            order_type: "LONG",
            order_uuid: "8c0e9ecf-985f-475d-8272-32c145c70c77",
            price: 170.521,
            price_sources: [],
            processed_ms: 1721258106291,
          },
          {
            leverage: 0.020000000000000004,
            order_type: "LONG",
            order_uuid: "213b7e6e-8352-48c8-8867-d7b6f3cbe2dd",
            price: 170.2855,
            price_sources: [],
            processed_ms: 1721259006276,
          },
          {
            leverage: 0.01999999999999999,
            order_type: "LONG",
            order_uuid: "aec587a2-b278-4bfa-acf8-b671b8e0fb78",
            price: 170.0565,
            price_sources: [],
            processed_ms: 1721259908081,
          },
          {
            leverage: 0.020000000000000018,
            order_type: "LONG",
            order_uuid: "c51e71eb-34c2-4212-a824-ba05b042d8c6",
            price: 170.177,
            price_sources: [],
            processed_ms: 1721262610305,
          },
          {
            leverage: 0.15000000000000002,
            order_type: "LONG",
            order_uuid: "4ec5ee7b-8e4c-422f-a3d8-203976b4b3a9",
            price: 170.358,
            price_sources: [],
            processed_ms: 1721264470101,
          },
          {
            leverage: -0.1,
            order_type: "SHORT",
            order_uuid: "db738896-1e90-4f3d-814b-6ad4063ab49d",
            price: 170.5115,
            price_sources: [],
            processed_ms: 1721266208423,
          },
          {
            leverage: -0.10000000000000003,
            order_type: "SHORT",
            order_uuid: "6efd2909-4098-4689-8388-bce9c4c509c8",
            price: 170.94,
            price_sources: [],
            processed_ms: 1721278819287,
          },
          {
            leverage: 0,
            order_type: "FLAT",
            order_uuid: "8c4c9524-82ec-4f0b-aa51-a1a99a8a8727",
            price: 170.965,
            price_sources: [],
            processed_ms: 1721293261640,
          },
        ],
        position_type: "FLAT",
        position_uuid: "ce057ed5-e78d-4d49-a3dd-8b8c661f26da",
        return_at_close: 1.0006134247840612,
        trade_pair: ["EURJPY", "EUR/JPY", 0.00007, 0.1, 5],
      },
      {
        average_entry_price: 1.3031700000000002,
        close_ms: 1721293263819,
        current_return: 1.0001818725970946,
        is_closed_position: true,
        miner_hotkey: "5GhCxfBcA7Ur5iiAS343xwvrYHTUfBjBi4JimiL5LhujRT9t",
        net_leverage: 0,
        open_ms: 1721206944946,
        orders: [
          {
            leverage: -0.03,
            order_type: "SHORT",
            order_uuid: "bdc2e278-e7e0-4e2c-b60f-ce16b2093d1b",
            price: 1.30311,
            price_sources: [],
            processed_ms: 1721206944946,
          },
          {
            leverage: -0.020000000000000004,
            order_type: "SHORT",
            order_uuid: "ccb35f65-72db-4aba-8a2a-5cdbb520bbbd",
            price: 1.30326,
            price_sources: [],
            processed_ms: 1721221267611,
          },
          {
            leverage: 0,
            order_type: "FLAT",
            order_uuid: "599fb440-3561-421e-8d6a-33b27867c29a",
            price: 1.29843,
            price_sources: [],
            processed_ms: 1721293263819,
          },
        ],
        position_type: "FLAT",
        position_uuid: "bdc2e278-e7e0-4e2c-b60f-ce16b2093d1b",
        return_at_close: 1.0001658523236021,
        trade_pair: ["GBPUSD", "GBP/USD", 0.00007, 0.1, 5],
      },
      {
        average_entry_price: 113.78324999999994,
        close_ms: 1721322167344,
        current_return: 1.0003429825405012,
        is_closed_position: true,
        miner_hotkey: "5GhCxfBcA7Ur5iiAS343xwvrYHTUfBjBi4JimiL5LhujRT9t",
        net_leverage: 0,
        open_ms: 1721207029441,
        orders: [
          {
            leverage: 0.020000000000000004,
            order_type: "LONG",
            order_uuid: "b0c84bc1-2418-4946-b553-f7e8cf72fc80",
            price: 114.379,
            price_sources: [],
            processed_ms: 1721207029441,
          },
          {
            leverage: 0.020000000000000004,
            order_type: "LONG",
            order_uuid: "bb5b5e37-7b02-4639-828b-06f0899b9f5f",
            price: 114.454,
            price_sources: [],
            processed_ms: 1721217615906,
          },
          {
            leverage: 0.020000000000000004,
            order_type: "LONG",
            order_uuid: "b15d0156-6be6-4c8a-a253-c450a734352b",
            price: 114.34,
            price_sources: [],
            processed_ms: 1721228414470,
          },
          {
            leverage: 0.01999999999999999,
            order_type: "LONG",
            order_uuid: "991a8df4-6210-487d-a84d-e62d41aaab86",
            price: 114.115,
            price_sources: [],
            processed_ms: 1721235718824,
          },
          {
            leverage: 0.02000000000000003,
            order_type: "LONG",
            order_uuid: "e0c38992-35eb-41d8-9feb-7aaa76bdd894",
            price: 114.0415,
            price_sources: [],
            processed_ms: 1721242808860,
          },
          {
            leverage: 0.019999999999999962,
            order_type: "LONG",
            order_uuid: "be8d7f1d-c221-4dfb-a461-48a4c43681f6",
            price: 114.11,
            price_sources: [],
            processed_ms: 1721250229155,
          },
          {
            leverage: 0.020000000000000018,
            order_type: "LONG",
            order_uuid: "6cc2d1b0-d82a-4a16-a191-06d35fd2943f",
            price: 113.79400000000001,
            price_sources: [],
            processed_ms: 1721260858676,
          },
          {
            leverage: 0.01999999999999999,
            order_type: "LONG",
            order_uuid: "aa9781b1-32ac-4ca2-9a96-b253112852f2",
            price: 113.845,
            price_sources: [],
            processed_ms: 1721264481940,
          },
          {
            leverage: -0.12,
            order_type: "SHORT",
            order_uuid: "9feed82d-aa85-4661-bcdd-fc00ee40b31f",
            price: 114.25200000000001,
            price_sources: [],
            processed_ms: 1721268016525,
          },
          {
            leverage: 0,
            order_type: "FLAT",
            order_uuid: "b329bcfb-92da-4d7e-93bb-e5bd467fc618",
            price: 114.764,
            price_sources: [],
            processed_ms: 1721322167344,
          },
        ],
        position_type: "FLAT",
        position_uuid: "b0c84bc1-2418-4946-b553-f7e8cf72fc80",
        return_at_close: 1.0003217614408035,
        trade_pair: ["CADJPY", "CAD/JPY", 0.00007, 0.1, 5],
      },
      {
        average_entry_price: 104.09644444444446,
        close_ms: 1721322179822,
        current_return: 1.0026711454736525,
        is_closed_position: true,
        miner_hotkey: "5GhCxfBcA7Ur5iiAS343xwvrYHTUfBjBi4JimiL5LhujRT9t",
        net_leverage: 0,
        open_ms: 1721163813338,
        orders: [
          {
            leverage: 0.020000000000000004,
            order_type: "LONG",
            order_uuid: "ac14e7d7-df97-4815-8823-6169aa25beaa",
            price: 106.576,
            price_sources: [],
            processed_ms: 1721163813338,
          },
          {
            leverage: 0.04000000000000001,
            order_type: "LONG",
            order_uuid: "d4bdc3d1-f34f-4713-a884-d4db8bb2b212",
            price: 105.486,
            price_sources: [],
            processed_ms: 1721206976697,
          },
          {
            leverage: 0.10000000000000002,
            order_type: "LONG",
            order_uuid: "c60d70a8-39c9-40db-8b44-2040085d7faa",
            price: 105.4975,
            price_sources: [],
            processed_ms: 1721221265027,
          },
          {
            leverage: 0.12,
            order_type: "LONG",
            order_uuid: "90ed8fd0-27df-4af0-9ff8-45bb0f2061b9",
            price: 105.111,
            price_sources: [],
            processed_ms: 1721235740319,
          },
          {
            leverage: 0.09999999999999998,
            order_type: "LONG",
            order_uuid: "398b7d25-e5ac-4329-87f8-a906281ce756",
            price: 105.02,
            price_sources: [],
            processed_ms: 1721250262019,
          },
          {
            leverage: 0.04000000000000009,
            order_type: "LONG",
            order_uuid: "2311ad04-e361-4038-96b0-4cde95d4656c",
            price: 104.733,
            price_sources: [],
            processed_ms: 1721260852655,
          },
          {
            leverage: 0.039999999999999925,
            order_type: "LONG",
            order_uuid: "c4e64a68-1a8f-4b7b-8d48-989326691c03",
            price: 104.705,
            price_sources: [],
            processed_ms: 1721260914058,
          },
          {
            leverage: 0.1800000000000001,
            order_type: "LONG",
            order_uuid: "60bb7420-7c89-4b7f-881e-0c44b9899db8",
            price: 104.6225,
            price_sources: [],
            processed_ms: 1721264469310,
          },
          {
            leverage: -0.16000000000000014,
            order_type: "SHORT",
            order_uuid: "debbd012-097d-43ba-9e40-868fdc9fbd39",
            price: 105.237,
            price_sources: [],
            processed_ms: 1721268016526,
          },
          {
            leverage: -0.12,
            order_type: "SHORT",
            order_uuid: "53356234-3593-4c6b-9154-e506fb33cbe7",
            price: 105.329,
            price_sources: [],
            processed_ms: 1721278817729,
          },
          {
            leverage: -0.040000000000000036,
            order_type: "SHORT",
            order_uuid: "9bd72954-9698-4df5-8b98-efa04cd9047a",
            price: 105.377,
            price_sources: [],
            processed_ms: 1721293261493,
          },
          {
            leverage: -0.13999999999999996,
            order_type: "SHORT",
            order_uuid: "404e0acb-887d-4f6f-b128-807a243bdbdd",
            price: 105.684,
            price_sources: [],
            processed_ms: 1721322163367,
          },
          {
            leverage: 0,
            order_type: "FLAT",
            order_uuid: "fa165f15-8e6c-4228-9414-f22c14b76404",
            price: 105.678,
            price_sources: [],
            processed_ms: 1721322179822,
          },
        ],
        position_type: "FLAT",
        position_uuid: "ac14e7d7-df97-4815-8823-6169aa25beaa",
        return_at_close: 1.0026212056763977,
        trade_pair: ["AUDJPY", "AUD/JPY", 0.00007, 0.1, 5],
      },
      {
        average_entry_price: 0.6473038888888888,
        close_ms: 1721365214807,
        current_return: 1.0004012553582364,
        is_closed_position: true,
        miner_hotkey: "5GhCxfBcA7Ur5iiAS343xwvrYHTUfBjBi4JimiL5LhujRT9t",
        net_leverage: 0,
        open_ms: 1721163909418,
        orders: [
          {
            leverage: 0.020000000000000004,
            order_type: "LONG",
            order_uuid: "8ccfbede-1c65-48c6-8bbd-f9fce6c35ff7",
            price: 0.6532,
            price_sources: [],
            processed_ms: 1721163909418,
          },
          {
            leverage: 0.020000000000000004,
            order_type: "LONG",
            order_uuid: "070b563b-215a-40d3-b222-2b307aa6b804",
            price: 0.64941,
            price_sources: [],
            processed_ms: 1721206963480,
          },
          {
            leverage: 0.06,
            order_type: "LONG",
            order_uuid: "f4187b60-838b-4d1b-8986-8222e73198b6",
            price: 0.64794,
            price_sources: [],
            processed_ms: 1721221267608,
          },
          {
            leverage: 0.060000000000000026,
            order_type: "LONG",
            order_uuid: "6272cced-d9e4-4c48-93a8-4353eff8ac67",
            price: 0.645885,
            price_sources: [],
            processed_ms: 1721235713170,
          },
          {
            leverage: 0.06,
            order_type: "LONG",
            order_uuid: "d1337b77-95b4-4916-823c-097237a37357",
            price: 0.64515,
            price_sources: [],
            processed_ms: 1721250311916,
          },
          {
            leverage: -0.04000000000000001,
            order_type: "SHORT",
            order_uuid: "dbc613ee-3455-4735-ad79-7a9ffb97aa65",
            price: 0.6469,
            price_sources: [],
            processed_ms: 1721322179612,
          },
          {
            leverage: 0,
            order_type: "FLAT",
            order_uuid: "38cb5652-5ebb-4021-a732-9d8bb67721de",
            price: 0.64876,
            price_sources: [],
            processed_ms: 1721365214807,
          },
        ],
        position_type: "FLAT",
        position_uuid: "8ccfbede-1c65-48c6-8bbd-f9fce6c35ff7",
        return_at_close: 1.0003207352736696,
        trade_pair: ["CADCHF", "CAD/CHF", 0.00007, 0.1, 5],
      },
      {
        average_entry_price: 113.34249999999999,
        close_ms: 1721732409279,
        current_return: 1.000020645388976,
        is_closed_position: true,
        miner_hotkey: "5GhCxfBcA7Ur5iiAS343xwvrYHTUfBjBi4JimiL5LhujRT9t",
        net_leverage: 0,
        open_ms: 1721725351968,
        orders: [
          {
            leverage: 0.04000000000000001,
            order_type: "LONG",
            order_uuid: "08e431d4-d022-4933-86b2-c793fa173942",
            price: 113.3425,
            price_sources: [
              {
                close: 113.3425,
                high: 113.3425,
                lag_ms: 31,
                low: 113.3425,
                open: 113.3425,
                source: "Polygon_ws",
                start_ms: 1721725351999,
                timespan_ms: 0,
                volume: 1,
                vwap: 113.342,
                websocket: true,
              },
              {
                close: 113.339,
                high: 113.339,
                lag_ms: 32,
                low: 113.339,
                open: 113.339,
                source: "TwelveData_ws",
                start_ms: 1721725352000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1721725351968,
          },
          {
            leverage: 0,
            order_type: "FLAT",
            order_uuid: "36d5fe03-7b67-4d3a-80fd-5db8e898315c",
            price: 113.401,
            price_sources: [
              {
                close: 113.401,
                high: 113.401,
                lag_ms: 279,
                low: 113.401,
                open: 113.401,
                source: "Polygon_ws",
                start_ms: 1721732409000,
                timespan_ms: 0,
                volume: 1,
                vwap: 113.401,
                websocket: true,
              },
              {
                close: 113.401,
                high: 113.401,
                lag_ms: 279,
                low: 113.401,
                open: 113.401,
                source: "TwelveData_ws",
                start_ms: 1721732409000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1721732409279,
          },
        ],
        position_type: "FLAT",
        position_uuid: "08e431d4-d022-4933-86b2-c793fa173942",
        return_at_close: 1.000017845331169,
        trade_pair: ["CADJPY", "CAD/JPY", 0.00007, 0.1, 5],
      },
      {
        average_entry_price: 1.0828218918918942,
        close_ms: 1721771118294,
        current_return: 0.9991524118023787,
        is_closed_position: true,
        miner_hotkey: "5GhCxfBcA7Ur5iiAS343xwvrYHTUfBjBi4JimiL5LhujRT9t",
        net_leverage: 0,
        open_ms: 1720011611036,
        orders: [
          {
            leverage: -0.03,
            order_type: "SHORT",
            order_uuid: "328048a9-f2ce-44e8-9476-0e4a8bf1f639",
            price: 1.07741,
            price_sources: [],
            processed_ms: 1720011611036,
          },
          {
            leverage: -0.05,
            order_type: "SHORT",
            order_uuid: "b5bc47cb-da2a-4140-b859-dafeeab911ca",
            price: 1.0774949999999999,
            price_sources: [],
            processed_ms: 1720011624015,
          },
          {
            leverage: -0.06000000000000004,
            order_type: "SHORT",
            order_uuid: "59ca5020-faba-4d6b-9a48-02d55aef2000",
            price: 1.07949,
            price_sources: [],
            processed_ms: 1720026012077,
          },
          {
            leverage: -0.04999999999999996,
            order_type: "SHORT",
            order_uuid: "51811c6b-4b03-4626-834d-98904a997704",
            price: 1.079285,
            price_sources: [],
            processed_ms: 1720054809476,
          },
          {
            leverage: -0.10999999999999993,
            order_type: "SHORT",
            order_uuid: "b881d178-c79d-4d8f-876f-00ba9d3fd3b4",
            price: 1.08116,
            price_sources: [],
            processed_ms: 1720112408260,
          },
          {
            leverage: -0.030000000000000082,
            order_type: "SHORT",
            order_uuid: "4c23b6e7-07f2-4396-bfdd-f47b5b75a67c",
            price: 1.081245,
            price_sources: [],
            processed_ms: 1720112446246,
          },
          {
            leverage: -0.02999999999999997,
            order_type: "SHORT",
            order_uuid: "889225c9-4f1e-4110-9936-c162780eb2cd",
            price: 1.08144,
            price_sources: [],
            processed_ms: 1720141206564,
          },
          {
            leverage: -0.050000000000000044,
            order_type: "SHORT",
            order_uuid: "b01000e0-6e1f-49f4-8d46-73607e535b97",
            price: 1.08141,
            price_sources: [],
            processed_ms: 1720141218423,
          },
          {
            leverage: -0.04999999999999993,
            order_type: "SHORT",
            order_uuid: "a995ade0-50ec-4c82-8440-25cbdae11db6",
            price: 1.082195,
            price_sources: [],
            processed_ms: 1720155607236,
          },
          {
            leverage: -0.08000000000000007,
            order_type: "SHORT",
            order_uuid: "5c51e1ef-00db-4181-b779-71e4dfc72444",
            price: 1.08274,
            price_sources: [],
            processed_ms: 1720170006407,
          },
          {
            leverage: -0.05999999999999994,
            order_type: "SHORT",
            order_uuid: "7f420f96-5800-4bc1-bed6-a544c6c5ac40",
            price: 1.08271,
            price_sources: [],
            processed_ms: 1720170016891,
          },
          {
            leverage: -0.030000000000000027,
            order_type: "SHORT",
            order_uuid: "d9874fa7-25fa-433a-b8b9-d56f6ed79fe0",
            price: 1.08284,
            price_sources: [],
            processed_ms: 1720184407658,
          },
          {
            leverage: -0.029999999999999916,
            order_type: "SHORT",
            order_uuid: "c9b362d2-163c-44da-968d-19e6d84ed332",
            price: 1.083035,
            price_sources: [],
            processed_ms: 1720184420810,
          },
          {
            leverage: -0.09999999999999998,
            order_type: "SHORT",
            order_uuid: "1cbd9c17-0d9c-4a0d-9973-84460ae5d128",
            price: 1.08314,
            price_sources: [],
            processed_ms: 1720184447553,
          },
          {
            leverage: -0.1100000000000001,
            order_type: "SHORT",
            order_uuid: "d5fe6fb8-44a4-4c25-a21b-6110deb667b3",
            price: 1.0804,
            price_sources: [],
            processed_ms: 1720386013604,
          },
          {
            leverage: -0.030000000000000027,
            order_type: "SHORT",
            order_uuid: "21e36a27-1eb1-4528-beac-0c5cfe014480",
            price: 1.08375,
            price_sources: [],
            processed_ms: 1720429207400,
          },
          {
            leverage: -0.02000000000000013,
            order_type: "SHORT",
            order_uuid: "08feb133-d2c6-4a7c-b92d-bdc97a4b2138",
            price: 1.08368,
            price_sources: [],
            processed_ms: 1720429223890,
          },
          {
            leverage: -0.029999999999999916,
            order_type: "SHORT",
            order_uuid: "2c79c3ee-0454-4c3d-8c9e-2357c9d2c136",
            price: 1.08366,
            price_sources: [],
            processed_ms: 1720429248300,
          },
          {
            leverage: -0.029999999999999916,
            order_type: "SHORT",
            order_uuid: "b1511890-ae9d-4ebd-af76-9a0b86d8274a",
            price: 1.08363,
            price_sources: [],
            processed_ms: 1720429264662,
          },
          {
            leverage: -0.09000000000000008,
            order_type: "SHORT",
            order_uuid: "476a1629-3aa3-4104-9012-f2c3830bd3a7",
            price: 1.08442,
            price_sources: [],
            processed_ms: 1720443612479,
          },
          {
            leverage: -0.019999999999999796,
            order_type: "SHORT",
            order_uuid: "3378ccce-4569-4e21-9aad-de8db6650558",
            price: 1.08437,
            price_sources: [],
            processed_ms: 1720443623332,
          },
          {
            leverage: -0.030000000000000027,
            order_type: "SHORT",
            order_uuid: "2c8aa0a0-7133-42fe-bdd7-561cd6150bf4",
            price: 1.08421,
            price_sources: [],
            processed_ms: 1720443654291,
          },
          {
            leverage: 0.08999999999999986,
            order_type: "LONG",
            order_uuid: "9d8353ab-1dca-4777-a3aa-53deb6003e77",
            price: 1.08233,
            price_sources: [],
            processed_ms: 1720472419457,
          },
          {
            leverage: 0.20999999999999996,
            order_type: "LONG",
            order_uuid: "ca28a9b9-c6ae-4ee5-a14c-571b5cf1e799",
            price: 1.08161,
            price_sources: [],
            processed_ms: 1720530006642,
          },
          {
            leverage: 0.26,
            order_type: "LONG",
            order_uuid: "cf2e070e-85b5-4c9b-b628-fbf1033d663d",
            price: 1.08102,
            price_sources: [],
            processed_ms: 1720544409221,
          },
          {
            leverage: -0.10999999999999999,
            order_type: "SHORT",
            order_uuid: "9f339d6d-cb2c-4c33-8d94-9c9bbf5ccda8",
            price: 1.084715,
            price_sources: [],
            processed_ms: 1720688407646,
          },
          {
            leverage: -0.04999999999999993,
            order_type: "SHORT",
            order_uuid: "94dda452-c009-4b64-8772-2c0a8cdb504b",
            price: 1.08892,
            price_sources: [],
            processed_ms: 1720702822297,
          },
          {
            leverage: -0.030000000000000027,
            order_type: "SHORT",
            order_uuid: "2e737b38-d3a1-44fb-935c-80c9f7ff7707",
            price: 1.0889,
            price_sources: [],
            processed_ms: 1720702868892,
          },
          {
            leverage: -0.030000000000000027,
            order_type: "SHORT",
            order_uuid: "0cb51723-cd4a-4fe1-af5d-db470bb7057d",
            price: 1.08924,
            price_sources: [],
            processed_ms: 1720702924159,
          },
          {
            leverage: -0.21999999999999997,
            order_type: "SHORT",
            order_uuid: "9560604d-2faf-4118-851a-8c6dc5ccb466",
            price: 1.09048,
            price_sources: [],
            processed_ms: 1720803611162,
          },
          {
            leverage: 0.09000000000000008,
            order_type: "LONG",
            order_uuid: "fb20483f-786c-4262-99b7-30278ab72abe",
            price: 1.08888,
            price_sources: [],
            processed_ms: 1721005213847,
          },
          {
            leverage: -0.030000000000000027,
            order_type: "SHORT",
            order_uuid: "fcabd284-4f3d-45e3-b913-a1eee8f9a5d1",
            price: 1.0908,
            price_sources: [],
            processed_ms: 1721034009389,
          },
          {
            leverage: -0.050000000000000044,
            order_type: "SHORT",
            order_uuid: "cf27c817-f89b-4460-a8ec-2e80cb5408fe",
            price: 1.0909,
            price_sources: [],
            processed_ms: 1721034047153,
          },
          {
            leverage: -0.07999999999999985,
            order_type: "SHORT",
            order_uuid: "dae422b3-8ec0-4970-ae2b-f0f28917704e",
            price: 1.09422,
            price_sources: [],
            processed_ms: 1721221267856,
          },
          {
            leverage: 0.030000000000000027,
            order_type: "LONG",
            order_uuid: "3c2a86b8-ed85-46b8-aedc-b13f1cea084c",
            price: 1.09275,
            price_sources: [],
            processed_ms: 1721307613740,
          },
          {
            leverage: 0.030000000000000027,
            order_type: "LONG",
            order_uuid: "76da50f7-618e-4e74-9536-eb798c6e1857",
            price: 1.09147,
            price_sources: [],
            processed_ms: 1721322160820,
          },
          {
            leverage: 0.33999999999999986,
            order_type: "LONG",
            order_uuid: "65ebc75d-b02b-49e6-959a-202f096aecb0",
            price: 1.08844,
            price_sources: [
              {
                close: 1.08844,
                high: 1.08844,
                lag_ms: 160,
                low: 1.08844,
                open: 1.08844,
                source: "Polygon_ws",
                start_ms: 1721660581000,
                timespan_ms: 0,
                volume: 1,
                vwap: 1.08843,
                websocket: true,
              },
              {
                close: 1.0884,
                high: 1.0884,
                lag_ms: 2840,
                low: 1.0884,
                open: 1.0884,
                source: "TwelveData_ws",
                start_ms: 1721660578000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1721660580840,
          },
          {
            leverage: 0.30000000000000004,
            order_type: "LONG",
            order_uuid: "9334b512-2c59-4591-90bb-803f0a7cb125",
            price: 1.0854,
            price_sources: [
              {
                close: 1.0854,
                high: 1.0854,
                lag_ms: 809,
                low: 1.0854,
                open: 1.0854,
                source: "TwelveData_ws",
                start_ms: 1721770954000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
              {
                close: 1.0853,
                high: 1.0853,
                lag_ms: 810,
                low: 1.0853,
                open: 1.0853,
                source: "Polygon_ws",
                start_ms: 1721770953999,
                timespan_ms: 0,
                volume: 1,
                vwap: 1.0853,
                websocket: true,
              },
            ],
            processed_ms: 1721770954809,
          },
          {
            leverage: 0,
            order_type: "FLAT",
            order_uuid: "1f860033-4df0-4991-a930-592568698133",
            price: 1.08529,
            price_sources: [
              {
                close: 1.08529,
                high: 1.08529,
                lag_ms: 706,
                low: 1.08529,
                open: 1.08529,
                source: "Polygon_ws",
                start_ms: 1721771119000,
                timespan_ms: 0,
                volume: 1,
                vwap: 1.08529,
                websocket: true,
              },
              {
                close: 1.0854,
                high: 1.0854,
                lag_ms: 706,
                low: 1.0854,
                open: 1.0854,
                source: "TwelveData_ws",
                start_ms: 1721771119000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1721771118294,
          },
        ],
        position_type: "FLAT",
        position_uuid: "328048a9-f2ce-44e8-9476-0e4a8bf1f639",
        return_at_close: 0.9980413467024207,
        trade_pair: ["EURUSD", "EUR/USD", 0.00007, 0.1, 5],
      },
      {
        average_entry_price: 199.112,
        close_ms: 1721810717269,
        current_return: 1.0000619751697537,
        is_closed_position: true,
        miner_hotkey: "5GhCxfBcA7Ur5iiAS343xwvrYHTUfBjBi4JimiL5LhujRT9t",
        net_leverage: 0,
        open_ms: 1721801711841,
        orders: [
          {
            leverage: 0.020000000000000004,
            order_type: "LONG",
            order_uuid: "d724f8fc-310e-4d3f-a4c7-dfe6f7a1f134",
            price: 199.112,
            price_sources: [
              {
                close: 199.112,
                high: 199.112,
                lag_ms: 158,
                low: 199.112,
                open: 199.112,
                source: "Polygon_rest",
                start_ms: 1721801711000,
                timespan_ms: 1000,
                volume: 1,
                vwap: null,
                websocket: false,
              },
              {
                close: 199.112,
                high: 199.112,
                lag_ms: 159,
                low: 199.112,
                open: 199.112,
                source: "TwelveData_ws",
                start_ms: 1721801712000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
              {
                close: 199.33,
                high: 199.36,
                lag_ms: 191842,
                low: 199.31,
                open: 199.31,
                source: "TwelveData_rest",
                start_ms: 1721801460000,
                timespan_ms: 60000,
                volume: null,
                vwap: null,
                websocket: false,
              },
              {
                close: 199.7635,
                high: 199.7635,
                lag_ms: 8038842,
                low: 199.7635,
                open: 199.7635,
                source: "Polygon_ws",
                start_ms: 1721793672999,
                timespan_ms: 0,
                volume: 1,
                vwap: 199.759,
                websocket: true,
              },
            ],
            processed_ms: 1721801711841,
          },
          {
            leverage: 0,
            order_type: "FLAT",
            order_uuid: "7711c6f5-e2ce-4dc9-bfb4-914fbf025e54",
            price: 199.729,
            price_sources: [
              {
                close: 199.729,
                high: 199.729,
                lag_ms: 269,
                low: 199.729,
                open: 199.729,
                source: "TwelveData_ws",
                start_ms: 1721810717000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
              {
                close: 199.7335,
                high: 199.7335,
                lag_ms: 270,
                low: 199.7335,
                open: 199.7335,
                source: "Polygon_rest",
                start_ms: 1721810716000,
                timespan_ms: 1000,
                volume: 4,
                vwap: null,
                websocket: false,
              },
              {
                close: 199.72,
                high: 199.72,
                lag_ms: 137270,
                low: 199.66,
                open: 199.69,
                source: "TwelveData_rest",
                start_ms: 1721810520000,
                timespan_ms: 60000,
                volume: null,
                vwap: null,
                websocket: false,
              },
              {
                close: 199.7635,
                high: 199.7635,
                lag_ms: 17044270,
                low: 199.7635,
                open: 199.7635,
                source: "Polygon_ws",
                start_ms: 1721793672999,
                timespan_ms: 0,
                volume: 1,
                vwap: 199.759,
                websocket: true,
              },
            ],
            processed_ms: 1721810717269,
          },
        ],
        position_type: "FLAT",
        position_uuid: "d724f8fc-310e-4d3f-a4c7-dfe6f7a1f134",
        return_at_close: 1.0000605750829885,
        trade_pair: ["GBPJPY", "GBP/JPY", 0.00007, 0.1, 5],
      },
      {
        average_entry_price: 197.16750000000002,
        close_ms: 1721911509865,
        current_return: 0.9999454667931498,
        is_closed_position: true,
        miner_hotkey: "5GhCxfBcA7Ur5iiAS343xwvrYHTUfBjBi4JimiL5LhujRT9t",
        net_leverage: 0,
        open_ms: 1721832310958,
        orders: [
          {
            leverage: 0.020000000000000004,
            order_type: "LONG",
            order_uuid: "24c3d713-a845-4c4c-ab8f-d9928b1f3f43",
            price: 198.18200000000002,
            price_sources: [
              {
                close: 198.18200000000002,
                high: 198.18200000000002,
                lag_ms: 41,
                low: 198.18200000000002,
                open: 198.18200000000002,
                source: "Polygon_rest",
                start_ms: 1721832310000,
                timespan_ms: 1000,
                volume: 4,
                vwap: null,
                websocket: false,
              },
              {
                close: 198.191,
                high: 198.191,
                lag_ms: 42,
                low: 198.191,
                open: 198.191,
                source: "TwelveData_ws",
                start_ms: 1721832311000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
              {
                close: 198.14999,
                high: 198.2,
                lag_ms: 70959,
                low: 198.14999,
                open: 198.16,
                source: "TwelveData_rest",
                start_ms: 1721832180000,
                timespan_ms: 60000,
                volume: null,
                vwap: null,
                websocket: false,
              },
              {
                close: 199.7635,
                high: 199.7635,
                lag_ms: 38637959,
                low: 199.7635,
                open: 199.7635,
                source: "Polygon_ws",
                start_ms: 1721793672999,
                timespan_ms: 0,
                volume: 1,
                vwap: 199.759,
                websocket: true,
              },
            ],
            processed_ms: 1721832310958,
          },
          {
            leverage: 0.0049999999999999975,
            order_type: "LONG",
            order_uuid: "GBPJPY-1721871008.miner_signal",
            price: 197.202,
            price_sources: [
              {
                close: 197.202,
                high: 197.202,
                lag_ms: 482,
                low: 197.202,
                open: 197.202,
                source: "TwelveData_ws",
                start_ms: 1721871013000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
              {
                close: 197.19850000000002,
                high: 197.19850000000002,
                lag_ms: 482,
                low: 197.19850000000002,
                open: 197.19850000000002,
                source: "Polygon_rest",
                start_ms: 1721871013000,
                timespan_ms: 1000,
                volume: 2,
                vwap: null,
                websocket: false,
              },
              {
                close: 197.46001,
                high: 197.53,
                lag_ms: 1093483,
                low: 197.45,
                open: 197.53,
                source: "TwelveData_rest",
                start_ms: 1721869860000,
                timespan_ms: 60000,
                volume: null,
                vwap: null,
                websocket: false,
              },
              {
                close: 199.7635,
                high: 199.7635,
                lag_ms: 77340483,
                low: 199.7635,
                open: 199.7635,
                source: "Polygon_ws",
                start_ms: 1721793672999,
                timespan_ms: 0,
                volume: 1,
                vwap: 199.759,
                websocket: true,
              },
            ],
            processed_ms: 1721871013482,
          },
          {
            leverage: 0.0050000000000000044,
            order_type: "LONG",
            order_uuid: "GBPJPY-1721873705.miner_signal",
            price: 196.773,
            price_sources: [
              {
                close: 196.773,
                high: 196.773,
                lag_ms: 38,
                low: 196.773,
                open: 196.773,
                source: "Polygon_rest",
                start_ms: 1721873707000,
                timespan_ms: 1000,
                volume: 5,
                vwap: null,
                websocket: false,
              },
              {
                close: 196.793,
                high: 196.793,
                lag_ms: 963,
                low: 196.793,
                open: 196.793,
                source: "TwelveData_ws",
                start_ms: 1721873709000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
              {
                close: 196.86,
                high: 196.92999,
                lag_ms: 68038,
                low: 196.85001,
                open: 196.89999,
                source: "TwelveData_rest",
                start_ms: 1721873580000,
                timespan_ms: 60000,
                volume: null,
                vwap: null,
                websocket: false,
              },
              {
                close: 199.7635,
                high: 199.7635,
                lag_ms: 80035038,
                low: 199.7635,
                open: 199.7635,
                source: "Polygon_ws",
                start_ms: 1721793672999,
                timespan_ms: 0,
                volume: 1,
                vwap: 199.759,
                websocket: true,
              },
            ],
            processed_ms: 1721873708037,
          },
          {
            leverage: 0.0049999999999999975,
            order_type: "LONG",
            order_uuid: "GBPJPY-1721875502.miner_signal",
            price: 196.857,
            price_sources: [
              {
                close: 196.857,
                high: 196.857,
                lag_ms: 211,
                low: 196.857,
                open: 196.857,
                source: "Polygon_rest",
                start_ms: 1721875506000,
                timespan_ms: 1000,
                volume: 2,
                vwap: null,
                websocket: false,
              },
              {
                close: 196.868,
                high: 196.868,
                lag_ms: 788,
                low: 196.868,
                open: 196.868,
                source: "TwelveData_ws",
                start_ms: 1721875506000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
              {
                close: 196.88,
                high: 196.89799,
                lag_ms: 126789,
                low: 196.88,
                open: 196.89,
                source: "TwelveData_rest",
                start_ms: 1721875320000,
                timespan_ms: 60000,
                volume: null,
                vwap: null,
                websocket: false,
              },
              {
                close: 199.7635,
                high: 199.7635,
                lag_ms: 81833789,
                low: 199.7635,
                open: 199.7635,
                source: "Polygon_ws",
                start_ms: 1721793672999,
                timespan_ms: 0,
                volume: 1,
                vwap: 199.759,
                websocket: true,
              },
            ],
            processed_ms: 1721875506788,
          },
          {
            leverage: 0.0049999999999999975,
            order_type: "LONG",
            order_uuid: "GBPJPY-1721876406.miner_signal",
            price: 196.709,
            price_sources: [
              {
                close: 196.709,
                high: 196.709,
                lag_ms: 144,
                low: 196.709,
                open: 196.709,
                source: "TwelveData_ws",
                start_ms: 1721876415000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
              {
                close: 196.713,
                high: 196.713,
                lag_ms: 145,
                low: 196.713,
                open: 196.713,
                source: "Polygon_rest",
                start_ms: 1721876414000,
                timespan_ms: 1000,
                volume: 3,
                vwap: null,
                websocket: false,
              },
              {
                close: 196.62,
                high: 196.67999,
                lag_ms: 195145,
                low: 196.618,
                open: 196.67,
                source: "TwelveData_rest",
                start_ms: 1721876160000,
                timespan_ms: 60000,
                volume: null,
                vwap: null,
                websocket: false,
              },
              {
                close: 199.7635,
                high: 199.7635,
                lag_ms: 82742145,
                low: 199.7635,
                open: 199.7635,
                source: "Polygon_ws",
                start_ms: 1721793672999,
                timespan_ms: 0,
                volume: 1,
                vwap: 199.759,
                websocket: true,
              },
            ],
            processed_ms: 1721876415144,
          },
          {
            leverage: 0.0049999999999999975,
            order_type: "LONG",
            order_uuid: "GBPJPY-1721878204.miner_signal",
            price: 196.353,
            price_sources: [
              {
                close: 196.353,
                high: 196.353,
                lag_ms: 277,
                low: 196.353,
                open: 196.353,
                source: "TwelveData_ws",
                start_ms: 1721878206000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
              {
                close: 196.353,
                high: 196.353,
                lag_ms: 277,
                low: 196.353,
                open: 196.353,
                source: "Polygon_rest",
                start_ms: 1721878206000,
                timespan_ms: 1000,
                volume: 1,
                vwap: null,
                websocket: false,
              },
              {
                close: 196.58,
                high: 196.62,
                lag_ms: 1086278,
                low: 196.55,
                open: 196.60001,
                source: "TwelveData_rest",
                start_ms: 1721877060000,
                timespan_ms: 60000,
                volume: null,
                vwap: null,
                websocket: false,
              },
              {
                close: 199.7635,
                high: 199.7635,
                lag_ms: 84533278,
                low: 199.7635,
                open: 199.7635,
                source: "Polygon_ws",
                start_ms: 1721793672999,
                timespan_ms: 0,
                volume: 1,
                vwap: 199.759,
                websocket: true,
              },
            ],
            processed_ms: 1721878206277,
          },
          {
            leverage: 0.005000000000000011,
            order_type: "LONG",
            order_uuid: "GBPJPY-1721892610.miner_signal",
            price: 196.21949999999998,
            price_sources: [
              {
                close: 196.21949999999998,
                high: 196.21949999999998,
                lag_ms: 144,
                low: 196.21949999999998,
                open: 196.21949999999998,
                source: "Polygon_ws",
                start_ms: 1721892613000,
                timespan_ms: 0,
                volume: 1,
                vwap: 196.21,
                websocket: true,
              },
              {
                close: 196.221,
                high: 196.221,
                lag_ms: 144,
                low: 196.221,
                open: 196.221,
                source: "TwelveData_ws",
                start_ms: 1721892613000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1721892613144,
          },
          {
            leverage: 0.0049999999999999975,
            order_type: "LONG",
            order_uuid: "GBPJPY-1721893503.miner_signal",
            price: 196.001,
            price_sources: [
              {
                close: 196.001,
                high: 196.001,
                lag_ms: 126,
                low: 196.001,
                open: 196.001,
                source: "Polygon_ws",
                start_ms: 1721893508000,
                timespan_ms: 0,
                volume: 1,
                vwap: 196.007,
                websocket: true,
              },
              {
                close: 195.994,
                high: 195.994,
                lag_ms: 126,
                low: 195.994,
                open: 195.994,
                source: "TwelveData_ws",
                start_ms: 1721893508000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1721893508126,
          },
          {
            leverage: 0,
            order_type: "FLAT",
            order_uuid: "GBPJPY-1721911507.miner_signal",
            price: 196.971,
            price_sources: [
              {
                close: 196.971,
                high: 196.971,
                lag_ms: 134,
                low: 196.971,
                open: 196.971,
                source: "Polygon_ws",
                start_ms: 1721911509999,
                timespan_ms: 0,
                volume: 1,
                vwap: 196.97,
                websocket: true,
              },
              {
                close: 196.964,
                high: 196.964,
                lag_ms: 135,
                low: 196.964,
                open: 196.964,
                source: "TwelveData_ws",
                start_ms: 1721911510000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1721911509865,
          },
        ],
        position_type: "FLAT",
        position_uuid: "24c3d713-a845-4c4c-ab8f-d9928b1f3f43",
        return_at_close: 0.999936610314949,
        trade_pair: ["GBPJPY", "GBP/JPY", 0.00007, 0.1, 5],
      },
      {
        average_entry_price: 64081.08625000001,
        close_ms: 1721919628182,
        current_return: 1.0007489153343012,
        is_closed_position: true,
        miner_hotkey: "5GhCxfBcA7Ur5iiAS343xwvrYHTUfBjBi4JimiL5LhujRT9t",
        net_leverage: 0,
        open_ms: 1721887218565,
        orders: [
          {
            leverage: 0.0333333335,
            order_type: "LONG",
            order_uuid: "BTCUSD-1721887216.miner_signal",
            price: 64263.05,
            price_sources: [
              {
                close: 64263.05,
                high: 64263.05,
                lag_ms: 435,
                low: 64263.05,
                open: 64263.05,
                source: "Polygon_ws",
                start_ms: 1721887219000,
                timespan_ms: 0,
                volume: 0.00003097,
                vwap: 64263.05,
                websocket: true,
              },
              {
                close: 64253.46,
                high: 64253.46,
                lag_ms: 2566,
                low: 64253.46,
                open: 64253.46,
                source: "Polygon_rest",
                start_ms: 1721887215000,
                timespan_ms: 1000,
                volume: 0.00117516,
                vwap: 64253.46,
                websocket: false,
              },
              {
                close: 64254.9,
                high: 64254.9,
                lag_ms: 4565,
                low: 64254.9,
                open: 64254.9,
                source: "TwelveData_ws",
                start_ms: 1721887214000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
              {
                close: 64248.46,
                high: 64257.46,
                lag_ms: 78566,
                low: 64242.47,
                open: 64253.28,
                source: "TwelveData_rest",
                start_ms: 1721887080000,
                timespan_ms: 60000,
                volume: null,
                vwap: null,
                websocket: false,
              },
            ],
            processed_ms: 1721887218565,
          },
          {
            leverage: 0.008333333375000009,
            order_type: "LONG",
            order_uuid: "BTCUSD-1721903417.miner_signal",
            price: 64041.05,
            price_sources: [
              {
                close: 64041.05,
                high: 64041.05,
                lag_ms: 428,
                low: 64032.41,
                open: 64041.05,
                source: "Polygon_ws",
                start_ms: 1721903420000,
                timespan_ms: 0,
                volume: 0.21782296,
                vwap: 64037.699,
                websocket: true,
              },
              {
                close: 64045.4,
                high: 64045.4,
                lag_ms: 1428,
                low: 64045.4,
                open: 64045.4,
                source: "TwelveData_ws",
                start_ms: 1721903419000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1721903420428,
          },
          {
            leverage: 0.03333333349999999,
            order_type: "LONG",
            order_uuid: "BTCUSD-1721908823.miner_signal",
            price: 64075.36,
            price_sources: [
              {
                close: 64075.36,
                high: 64075.36,
                lag_ms: 230,
                low: 64075.35,
                open: 64075.36,
                source: "Polygon_ws",
                start_ms: 1721908826000,
                timespan_ms: 0,
                volume: 0.00161494,
                vwap: 64075.3527,
                websocket: true,
              },
              {
                close: 64075.6,
                high: 64075.6,
                lag_ms: 1770,
                low: 64075.6,
                open: 64075.6,
                source: "TwelveData_ws",
                start_ms: 1721908824000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1721908825770,
          },
          {
            leverage: -0.008333333375000002,
            order_type: "SHORT",
            order_uuid: "BTCUSD-1721918721.miner_signal",
            price: 64746,
            price_sources: [
              {
                close: 64746,
                high: 64824,
                lag_ms: 86,
                low: 64746,
                open: 64746,
                source: "Polygon_ws",
                start_ms: 1721918723000,
                timespan_ms: 0,
                volume: 0.03271932,
                vwap: 64755.9648,
                websocket: true,
              },
              {
                close: 64740.7,
                high: 64740.7,
                lag_ms: 1086,
                low: 64740.7,
                open: 64740.7,
                source: "TwelveData_ws",
                start_ms: 1721918722000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1721918723086,
          },
          {
            leverage: 0,
            order_type: "FLAT",
            order_uuid: "BTCUSD-1721919625.miner_signal",
            price: 64803,
            price_sources: [
              {
                close: 64803,
                high: 64803,
                lag_ms: 182,
                low: 64803,
                open: 64803,
                source: "Polygon_ws",
                start_ms: 1721919628000,
                timespan_ms: 0,
                volume: 0.01339633,
                vwap: 64803,
                websocket: true,
              },
              {
                close: 64729.1,
                high: 64729.1,
                lag_ms: 1818,
                low: 64729.1,
                open: 64729.1,
                source: "TwelveData_ws",
                start_ms: 1721919630000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
              {
                close: 64801.52,
                high: 64801.52,
                lag_ms: 2183,
                low: 64793.08,
                open: 64793.08,
                source: "Polygon_rest",
                start_ms: 1721919625000,
                timespan_ms: 1000,
                volume: 0.00335202,
                vwap: 64800.4459,
                websocket: false,
              },
              {
                close: 64814.71,
                high: 64827.55,
                lag_ms: 28183,
                low: 64726.13,
                open: 64763.08,
                source: "TwelveData_rest",
                start_ms: 1721919540000,
                timespan_ms: 60000,
                volume: null,
                vwap: null,
                websocket: false,
              },
            ],
            processed_ms: 1721919628182,
          },
        ],
        position_type: "FLAT",
        position_uuid: "BTCUSD-1721887216.miner_signal",
        return_at_close: 1.0006694432569927,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.01, 0.5],
      },
      {
        average_entry_price: 111.13716598997911,
        close_ms: 1721926816836,
        current_return: 1.0006361696006143,
        is_closed_position: true,
        miner_hotkey: "5GhCxfBcA7Ur5iiAS343xwvrYHTUfBjBi4JimiL5LhujRT9t",
        net_leverage: 0,
        open_ms: 1721757623690,
        orders: [
          {
            leverage: 0.020000000000000004,
            order_type: "LONG",
            order_uuid: "ad784a39-36a3-46de-a480-d27e6c493be9",
            price: 113.04,
            price_sources: [
              {
                close: 113.04,
                high: 113.04,
                lag_ms: 309,
                low: 113.04,
                open: 113.04,
                source: "Polygon_ws",
                start_ms: 1721757623999,
                timespan_ms: 0,
                volume: 1,
                vwap: 113.04,
                websocket: true,
              },
              {
                close: 113.044,
                high: 113.044,
                lag_ms: 310,
                low: 113.044,
                open: 113.044,
                source: "TwelveData_ws",
                start_ms: 1721757624000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1721757623690,
          },
          {
            leverage: 0.04000000000000001,
            order_type: "LONG",
            order_uuid: "cc7a8246-ccc0-477f-9ed4-1ee8b83616a5",
            price: 112.826184708664,
            price_sources: [
              {
                close: 112.826184708664,
                high: 112.826184708664,
                lag_ms: 184,
                low: 112.826184708664,
                open: 112.826184708664,
                source: "Polygon_ws",
                start_ms: 1721768602000,
                timespan_ms: 0,
                volume: 1,
                vwap: 112.826184708664,
                websocket: true,
              },
              {
                close: 112.826,
                high: 112.826,
                lag_ms: 7816,
                low: 112.826,
                open: 112.826,
                source: "TwelveData_ws",
                start_ms: 1721768594000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1721768601816,
          },
          {
            leverage: -0.04000000000000001,
            order_type: "SHORT",
            order_uuid: "c7149f53-d776-44f5-bd17-1673a46b52fb",
            price: 112.995,
            price_sources: [
              {
                close: 112.995,
                high: 112.995,
                lag_ms: 411,
                low: 112.995,
                open: 112.995,
                source: "Polygon_ws",
                start_ms: 1721779211000,
                timespan_ms: 0,
                volume: 1,
                vwap: 112.995,
                websocket: true,
              },
              {
                close: 112.997,
                high: 112.997,
                lag_ms: 589,
                low: 112.997,
                open: 112.997,
                source: "TwelveData_ws",
                start_ms: 1721779212000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1721779211411,
          },
          {
            leverage: 0.020000000000000004,
            order_type: "LONG",
            order_uuid: "2c408507-691a-4483-a337-f361131717ea",
            price: 112.362,
            price_sources: [
              {
                close: 112.362,
                high: 112.362,
                lag_ms: 256,
                low: 112.362,
                open: 112.362,
                source: "Polygon_ws",
                start_ms: 1721793625999,
                timespan_ms: 0,
                volume: 1,
                vwap: 112.362,
                websocket: true,
              },
              {
                close: 112.367,
                high: 112.367,
                lag_ms: 257,
                low: 112.367,
                open: 112.367,
                source: "TwelveData_ws",
                start_ms: 1721793626000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1721793625743,
          },
          {
            leverage: 0.04000000000000001,
            order_type: "LONG",
            order_uuid: "96cdcaf6-6573-4149-a879-b6e2692b6185",
            price: 112.07,
            price_sources: [
              {
                close: 112.07,
                high: 112.07,
                lag_ms: 34,
                low: 112.07,
                open: 112.07,
                source: "TwelveData_ws",
                start_ms: 1721800861000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
              {
                close: 112.074,
                high: 112.074,
                lag_ms: 35,
                low: 112.074,
                open: 112.074,
                source: "Polygon_rest",
                start_ms: 1721800860000,
                timespan_ms: 1000,
                volume: 1,
                vwap: null,
                websocket: false,
              },
              {
                close: 112.112,
                high: 112.14,
                lag_ms: 121035,
                low: 112.11,
                open: 112.12,
                source: "TwelveData_rest",
                start_ms: 1721800680000,
                timespan_ms: 60000,
                volume: null,
                vwap: null,
                websocket: false,
              },
              {
                close: 112.334,
                high: 112.334,
                lag_ms: 7188035,
                low: 112.334,
                open: 112.334,
                source: "Polygon_ws",
                start_ms: 1721793672999,
                timespan_ms: 0,
                volume: 1,
                vwap: 112.332,
                websocket: true,
              },
            ],
            processed_ms: 1721800861034,
          },
          {
            leverage: 0.020000000000000018,
            order_type: "LONG",
            order_uuid: "a35d4b5c-ba43-4e9e-a42b-5d82c41a50a1",
            price: 112.009,
            price_sources: [
              {
                close: 112.009,
                high: 112.009,
                lag_ms: 199,
                low: 112.009,
                open: 112.009,
                source: "TwelveData_ws",
                start_ms: 1721803966000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
              {
                close: 112.011,
                high: 112.011,
                lag_ms: 200,
                low: 112.011,
                open: 112.011,
                source: "Polygon_rest",
                start_ms: 1721803965000,
                timespan_ms: 1000,
                volume: 3,
                vwap: null,
                websocket: false,
              },
              {
                close: 112,
                high: 112.03,
                lag_ms: 106200,
                low: 112,
                open: 112.011,
                source: "TwelveData_rest",
                start_ms: 1721803800000,
                timespan_ms: 60000,
                volume: null,
                vwap: null,
                websocket: false,
              },
              {
                close: 112.334,
                high: 112.334,
                lag_ms: 10293200,
                low: 112.334,
                open: 112.334,
                source: "Polygon_ws",
                start_ms: 1721793672999,
                timespan_ms: 0,
                volume: 1,
                vwap: 112.332,
                websocket: true,
              },
            ],
            processed_ms: 1721803966199,
          },
          {
            leverage: -0.04000000000000001,
            order_type: "SHORT",
            order_uuid: "25268e47-c910-4ba8-b681-1fe627b1ccd5",
            price: 112.249,
            price_sources: [
              {
                close: 112.249,
                high: 112.249,
                lag_ms: 836,
                low: 112.249,
                open: 112.249,
                source: "TwelveData_ws",
                start_ms: 1721811618000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
              {
                close: 112.334,
                high: 112.334,
                lag_ms: 17944165,
                low: 112.334,
                open: 112.334,
                source: "Polygon_ws",
                start_ms: 1721793672999,
                timespan_ms: 0,
                volume: 1,
                vwap: 112.332,
                websocket: true,
              },
            ],
            processed_ms: 1721811617164,
          },
          {
            leverage: 0.04000000000000002,
            order_type: "LONG",
            order_uuid: "04959ab4-abff-4ceb-9f0c-86a6e7d14017",
            price: 111.847,
            price_sources: [
              {
                close: 111.847,
                high: 111.847,
                lag_ms: 296,
                low: 111.847,
                open: 111.847,
                source: "TwelveData_ws",
                start_ms: 1721822419000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
              {
                close: 112.334,
                high: 112.334,
                lag_ms: 28745705,
                low: 112.334,
                open: 112.334,
                source: "Polygon_ws",
                start_ms: 1721793672999,
                timespan_ms: 0,
                volume: 1,
                vwap: 112.332,
                websocket: true,
              },
            ],
            processed_ms: 1721822418704,
          },
          {
            leverage: 0.060000000000000026,
            order_type: "LONG",
            order_uuid: "eeafd584-b142-4df3-ae92-03e87c9439da",
            price: 111.171,
            price_sources: [
              {
                close: 111.171,
                high: 111.171,
                lag_ms: 343,
                low: 111.171,
                open: 111.171,
                source: "Polygon_rest",
                start_ms: 1721829739000,
                timespan_ms: 1000,
                volume: 7,
                vwap: null,
                websocket: false,
              },
              {
                close: 111.166,
                high: 111.166,
                lag_ms: 658,
                low: 111.166,
                open: 111.166,
                source: "TwelveData_ws",
                start_ms: 1721829741000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
              {
                close: 111.25,
                high: 111.28,
                lag_ms: 80343,
                low: 111.22,
                open: 111.22,
                source: "TwelveData_rest",
                start_ms: 1721829600000,
                timespan_ms: 60000,
                volume: null,
                vwap: null,
                websocket: false,
              },
              {
                close: 112.334,
                high: 112.334,
                lag_ms: 36067343,
                low: 112.334,
                open: 112.334,
                source: "Polygon_ws",
                start_ms: 1721793672999,
                timespan_ms: 0,
                volume: 1,
                vwap: 112.332,
                websocket: true,
              },
            ],
            processed_ms: 1721829740342,
          },
          {
            leverage: 0.020000000000000046,
            order_type: "LONG",
            order_uuid: "186ab606-a546-4e11-8a35-9a93ba33d4e1",
            price: 111.338,
            price_sources: [
              {
                close: 111.338,
                high: 111.338,
                lag_ms: 487,
                low: 111.338,
                open: 111.338,
                source: "Polygon_rest",
                start_ms: 1721840714000,
                timespan_ms: 1000,
                volume: 2,
                vwap: null,
                websocket: false,
              },
              {
                close: 111.339,
                high: 111.339,
                lag_ms: 514,
                low: 111.339,
                open: 111.339,
                source: "TwelveData_ws",
                start_ms: 1721840716000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
              {
                close: 111.27,
                high: 111.28,
                lag_ms: 255487,
                low: 111.27,
                open: 111.28,
                source: "TwelveData_rest",
                start_ms: 1721840400000,
                timespan_ms: 60000,
                volume: null,
                vwap: null,
                websocket: false,
              },
              {
                close: 112.334,
                high: 112.334,
                lag_ms: 47042487,
                low: 112.334,
                open: 112.334,
                source: "Polygon_ws",
                start_ms: 1721793672999,
                timespan_ms: 0,
                volume: 1,
                vwap: 112.332,
                websocket: true,
              },
            ],
            processed_ms: 1721840715486,
          },
          {
            leverage: 0.009999999999999995,
            order_type: "LONG",
            order_uuid: "CADJPY-1721855168.miner_signal",
            price: 111.432,
            price_sources: [
              {
                close: 111.432,
                high: 111.432,
                lag_ms: 596,
                low: 111.432,
                open: 111.432,
                source: "TwelveData_ws",
                start_ms: 1721855172000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
              {
                close: 111.399,
                high: 111.399,
                lag_ms: 2405,
                low: 111.399,
                open: 111.399,
                source: "Polygon_rest",
                start_ms: 1721855168000,
                timespan_ms: 1000,
                volume: 1,
                vwap: null,
                websocket: false,
              },
              {
                close: 111.47,
                high: 111.47,
                lag_ms: 431405,
                low: 111.47,
                open: 111.47,
                source: "TwelveData_rest",
                start_ms: 1721854680000,
                timespan_ms: 60000,
                volume: null,
                vwap: null,
                websocket: false,
              },
              {
                close: 112.334,
                high: 112.334,
                lag_ms: 61498405,
                low: 112.334,
                open: 112.334,
                source: "Polygon_ws",
                start_ms: 1721793672999,
                timespan_ms: 0,
                volume: 1,
                vwap: 112.332,
                websocket: true,
              },
            ],
            processed_ms: 1721855171404,
          },
          {
            leverage: 0.020000000000000004,
            order_type: "LONG",
            order_uuid: "CADJPY-1721872809.miner_signal",
            price: 110.588,
            price_sources: [
              {
                close: 110.588,
                high: 110.588,
                lag_ms: 124,
                low: 110.588,
                open: 110.588,
                source: "TwelveData_ws",
                start_ms: 1721872815000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
              {
                close: 112.334,
                high: 112.334,
                lag_ms: 79141877,
                low: 112.334,
                open: 112.334,
                source: "Polygon_ws",
                start_ms: 1721793672999,
                timespan_ms: 0,
                volume: 1,
                vwap: 112.332,
                websocket: true,
              },
            ],
            processed_ms: 1721872814876,
          },
          {
            leverage: 0.025000000000000022,
            order_type: "LONG",
            order_uuid: "CADJPY-1721876406.miner_signal",
            price: 110.434,
            price_sources: [
              {
                close: 110.434,
                high: 110.434,
                lag_ms: 422,
                low: 110.434,
                open: 110.434,
                source: "TwelveData_ws",
                start_ms: 1721876415000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
              {
                close: 110.424,
                high: 110.424,
                lag_ms: 422,
                low: 110.424,
                open: 110.424,
                source: "Polygon_rest",
                start_ms: 1721876415000,
                timespan_ms: 1000,
                volume: 1,
                vwap: null,
                websocket: false,
              },
              {
                close: 110.42,
                high: 110.47,
                lag_ms: 315423,
                low: 110.39,
                open: 110.47,
                source: "TwelveData_rest",
                start_ms: 1721876040000,
                timespan_ms: 60000,
                volume: null,
                vwap: null,
                websocket: false,
              },
              {
                close: 112.334,
                high: 112.334,
                lag_ms: 82742423,
                low: 112.334,
                open: 112.334,
                source: "Polygon_ws",
                start_ms: 1721793672999,
                timespan_ms: 0,
                volume: 1,
                vwap: 112.332,
                websocket: true,
              },
            ],
            processed_ms: 1721876415422,
          },
          {
            leverage: 0.019999999999999976,
            order_type: "LONG",
            order_uuid: "CADJPY-1721880013.miner_signal",
            price: 110.285,
            price_sources: [
              {
                close: 110.285,
                high: 110.285,
                lag_ms: 351,
                low: 110.285,
                open: 110.285,
                source: "TwelveData_ws",
                start_ms: 1721880019000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
              {
                close: 110.28,
                high: 110.28,
                lag_ms: 352,
                low: 110.28,
                open: 110.28,
                source: "Polygon_rest",
                start_ms: 1721880018000,
                timespan_ms: 1000,
                volume: 5,
                vwap: null,
                websocket: false,
              },
              {
                close: 110.292,
                high: 110.292,
                lag_ms: 58649,
                low: 110.292,
                open: 110.292,
                source: "Polygon_ws",
                start_ms: 1721880078000,
                timespan_ms: 0,
                volume: 1,
                vwap: 110.292,
                websocket: true,
              },
              {
                close: 110.28,
                high: 110.31,
                lag_ms: 1519352,
                low: 110.27,
                open: 110.31,
                source: "TwelveData_rest",
                start_ms: 1721878440000,
                timespan_ms: 60000,
                volume: null,
                vwap: null,
                websocket: false,
              },
            ],
            processed_ms: 1721880019351,
          },
          {
            leverage: 0.024999999999999994,
            order_type: "LONG",
            order_uuid: "CADJPY-1721894412.miner_signal",
            price: 110.0695,
            price_sources: [
              {
                close: 110.0695,
                high: 110.0695,
                lag_ms: 98,
                low: 110.0695,
                open: 110.0695,
                source: "Polygon_ws",
                start_ms: 1721894417000,
                timespan_ms: 0,
                volume: 1,
                vwap: 110.074,
                websocket: true,
              },
              {
                close: 110.074,
                high: 110.074,
                lag_ms: 98,
                low: 110.074,
                open: 110.074,
                source: "TwelveData_ws",
                start_ms: 1721894417000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
              {
                close: 110.074,
                high: 110.074,
                lag_ms: 1099,
                low: 110.074,
                open: 110.074,
                source: "Polygon_rest",
                start_ms: 1721894415000,
                timespan_ms: 1000,
                volume: 5,
                vwap: null,
                websocket: false,
              },
              {
                close: 110.08,
                high: 110.11,
                lag_ms: 197099,
                low: 110.06,
                open: 110.11,
                source: "TwelveData_rest",
                start_ms: 1721894160000,
                timespan_ms: 60000,
                volume: null,
                vwap: null,
                websocket: false,
              },
            ],
            processed_ms: 1721894417098,
          },
          {
            leverage: -0.024999999999999994,
            order_type: "SHORT",
            order_uuid: "CADJPY-1721898013.miner_signal",
            price: 110.424,
            price_sources: [
              {
                close: 110.424,
                high: 110.424,
                lag_ms: 324,
                low: 110.424,
                open: 110.424,
                source: "Polygon_ws",
                start_ms: 1721898021000,
                timespan_ms: 0,
                volume: 1,
                vwap: 110.433,
                websocket: true,
              },
              {
                close: 110.425,
                high: 110.425,
                lag_ms: 676,
                low: 110.425,
                open: 110.425,
                source: "TwelveData_ws",
                start_ms: 1721898022000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1721898021324,
          },
          {
            leverage: 0.014999999999999986,
            order_type: "LONG",
            order_uuid: "CADJPY-1721901609.miner_signal",
            price: 110.06049999999999,
            price_sources: [
              {
                close: 110.06049999999999,
                high: 110.06049999999999,
                lag_ms: 385,
                low: 110.06049999999999,
                open: 110.06049999999999,
                source: "Polygon_ws",
                start_ms: 1721901612000,
                timespan_ms: 0,
                volume: 1,
                vwap: 110.062,
                websocket: true,
              },
              {
                close: 110.062,
                high: 110.062,
                lag_ms: 385,
                low: 110.062,
                open: 110.062,
                source: "TwelveData_ws",
                start_ms: 1721901612000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1721901612385,
          },
          {
            leverage: 0.015000000000000013,
            order_type: "LONG",
            order_uuid: "CADJPY-1721905215.miner_signal",
            price: 109.994,
            price_sources: [
              {
                close: 109.994,
                high: 109.994,
                lag_ms: 387,
                low: 109.994,
                open: 109.994,
                source: "Polygon_ws",
                start_ms: 1721905219000,
                timespan_ms: 0,
                volume: 1,
                vwap: 109.994,
                websocket: true,
              },
              {
                close: 109.995,
                high: 109.995,
                lag_ms: 387,
                low: 109.995,
                open: 109.995,
                source: "TwelveData_ws",
                start_ms: 1721905219000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1721905219387,
          },
          {
            leverage: -0.12000000000000001,
            order_type: "SHORT",
            order_uuid: "CADJPY-1721916017.miner_signal",
            price: 111.268,
            price_sources: [
              {
                close: 111.268,
                high: 111.268,
                lag_ms: 3,
                low: 111.268,
                open: 111.268,
                source: "Polygon_ws",
                start_ms: 1721916025000,
                timespan_ms: 0,
                volume: 1,
                vwap: 111.262,
                websocket: true,
              },
              {
                close: 111.274,
                high: 111.274,
                lag_ms: 997,
                low: 111.274,
                open: 111.274,
                source: "TwelveData_ws",
                start_ms: 1721916026000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1721916025003,
          },
          {
            leverage: 0,
            order_type: "FLAT",
            order_uuid: "CADJPY-1721926809.miner_signal",
            price: 111.573,
            price_sources: [
              {
                close: 111.573,
                high: 111.573,
                lag_ms: 163,
                low: 111.573,
                open: 111.573,
                source: "Polygon_ws",
                start_ms: 1721926816999,
                timespan_ms: 0,
                volume: 1,
                vwap: 111.573,
                websocket: true,
              },
              {
                close: 111.583,
                high: 111.583,
                lag_ms: 164,
                low: 111.583,
                open: 111.583,
                source: "TwelveData_ws",
                start_ms: 1721926817000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1721926816836,
          },
        ],
        position_type: "FLAT",
        position_uuid: "ad784a39-36a3-46de-a480-d27e6c493be9",
        return_at_close: 1.0005888122677444,
        trade_pair: ["CADJPY", "CAD/JPY", 0.00007, 0.1, 5],
      },
      {
        average_entry_price: 0.6545235929648242,
        close_ms: 1721955619312,
        current_return: 1.000069398521101,
        is_closed_position: true,
        miner_hotkey: "5GhCxfBcA7Ur5iiAS343xwvrYHTUfBjBi4JimiL5LhujRT9t",
        net_leverage: 0,
        open_ms: 1721649612323,
        orders: [
          {
            leverage: 0.020000000000000004,
            order_type: "LONG",
            order_uuid: "1dc8fc56-708b-4857-8271-0f154553e5d6",
            price: 0.66536,
            price_sources: [
              {
                close: 0.66536,
                high: 0.66536,
                lag_ms: 323,
                low: 0.66536,
                open: 0.66536,
                source: "Polygon_ws",
                start_ms: 1721649612000,
                timespan_ms: 0,
                volume: 1,
                vwap: 0.66537,
                websocket: true,
              },
              {
                close: 0.66539,
                high: 0.66539,
                lag_ms: 677,
                low: 0.66539,
                open: 0.66539,
                source: "TwelveData_ws",
                start_ms: 1721649613000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1721649612323,
          },
          {
            leverage: 0.020000000000000004,
            order_type: "LONG",
            order_uuid: "295ed42a-601f-4140-9f6a-67d94bcc57a0",
            price: 0.66387,
            price_sources: [
              {
                close: 0.66387,
                high: 0.66387,
                lag_ms: 174,
                low: 0.66387,
                open: 0.66387,
                source: "TwelveData_ws",
                start_ms: 1721660421000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
              {
                close: 0.66389,
                high: 0.66389,
                lag_ms: 826,
                low: 0.66389,
                open: 0.66389,
                source: "Polygon_ws",
                start_ms: 1721660422000,
                timespan_ms: 0,
                volume: 1,
                vwap: 0.66387,
                websocket: true,
              },
            ],
            processed_ms: 1721660421174,
          },
          {
            leverage: 0.01999999999999999,
            order_type: "LONG",
            order_uuid: "3bba8125-d8a5-4a43-b921-5ec1e0c13817",
            price: 0.66236,
            price_sources: [
              {
                close: 0.66236,
                high: 0.66236,
                lag_ms: 375,
                low: 0.66236,
                open: 0.66236,
                source: "Polygon_ws",
                start_ms: 1721721618000,
                timespan_ms: 0,
                volume: 1,
                vwap: 0.66236,
                websocket: true,
              },
              {
                close: 0.66236,
                high: 0.66236,
                lag_ms: 625,
                low: 0.66236,
                open: 0.66236,
                source: "TwelveData_ws",
                start_ms: 1721721619000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1721721618375,
          },
          {
            leverage: 0.020000000000000018,
            order_type: "LONG",
            order_uuid: "14eeee52-645e-4893-982b-0d1dc4a24330",
            price: 0.66161,
            price_sources: [
              {
                close: 0.66161,
                high: 0.66161,
                lag_ms: 24,
                low: 0.66161,
                open: 0.66161,
                source: "Polygon_ws",
                start_ms: 1721743213999,
                timespan_ms: 0,
                volume: 1,
                vwap: 0.66162,
                websocket: true,
              },
              {
                close: 0.6616,
                high: 0.6616,
                lag_ms: 25,
                low: 0.6616,
                open: 0.6616,
                source: "TwelveData_ws",
                start_ms: 1721743214000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1721743213975,
          },
          {
            leverage: 0.01999999999999999,
            order_type: "LONG",
            order_uuid: "6c2f59cd-f9cb-42cf-a196-026c36909080",
            price: 0.6615150000000001,
            price_sources: [
              {
                close: 0.6615150000000001,
                high: 0.6615150000000001,
                lag_ms: 379,
                low: 0.6615150000000001,
                open: 0.6615150000000001,
                source: "Polygon_ws",
                start_ms: 1721746818000,
                timespan_ms: 0,
                volume: 1,
                vwap: 0.66152,
                websocket: true,
              },
              {
                close: 0.66149,
                high: 0.66149,
                lag_ms: 379,
                low: 0.66149,
                open: 0.66149,
                source: "TwelveData_ws",
                start_ms: 1721746818000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1721746818379,
          },
          {
            leverage: 0.01999999999999999,
            order_type: "LONG",
            order_uuid: "4c635d93-2e8a-46c9-b742-480a0bcdba7e",
            price: 0.66124,
            price_sources: [
              {
                close: 0.66124,
                high: 0.66124,
                lag_ms: 277,
                low: 0.66124,
                open: 0.66124,
                source: "Polygon_ws",
                start_ms: 1721757660999,
                timespan_ms: 0,
                volume: 1,
                vwap: 0.6612,
                websocket: true,
              },
              {
                close: 0.66124,
                high: 0.66124,
                lag_ms: 277,
                low: 0.66124,
                open: 0.66124,
                source: "Polygon_rest",
                start_ms: 1721757660000,
                timespan_ms: 1000,
                volume: 2,
                vwap: null,
                websocket: false,
              },
              {
                close: 0.66128,
                high: 0.66128,
                lag_ms: 278,
                low: 0.66128,
                open: 0.66128,
                source: "TwelveData_ws",
                start_ms: 1721757661000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
              {
                close: 0.6613,
                high: 0.6613,
                lag_ms: 120723,
                low: 0.6613,
                open: 0.6613,
                source: "TwelveData_rest",
                start_ms: 1721757480000,
                timespan_ms: 60000,
                volume: null,
                vwap: null,
                websocket: false,
              },
            ],
            processed_ms: 1721757660722,
          },
          {
            leverage: 0.01999999999999999,
            order_type: "LONG",
            order_uuid: "f8d88261-2d46-4f9b-a5e5-dc1f7ce9665f",
            price: 0.66105,
            price_sources: [
              {
                close: 0.66105,
                high: 0.66105,
                lag_ms: 488,
                low: 0.66105,
                open: 0.66105,
                source: "Polygon_ws",
                start_ms: 1721782819999,
                timespan_ms: 0,
                volume: 1,
                vwap: 0.661,
                websocket: true,
              },
              {
                close: 0.66107,
                high: 0.66107,
                lag_ms: 511,
                low: 0.66107,
                open: 0.66107,
                source: "TwelveData_ws",
                start_ms: 1721782819000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1721782819511,
          },
          {
            leverage: 0.020000000000000046,
            order_type: "LONG",
            order_uuid: "3d05ae8d-e412-442c-9cc3-934dcb98366b",
            price: 0.66065,
            price_sources: [
              {
                close: 0.66065,
                high: 0.66065,
                lag_ms: 250,
                low: 0.66065,
                open: 0.66065,
                source: "Polygon_ws",
                start_ms: 1721786414000,
                timespan_ms: 0,
                volume: 1,
                vwap: 0.66065,
                websocket: true,
              },
              {
                close: 0.66067,
                high: 0.66067,
                lag_ms: 250,
                low: 0.66067,
                open: 0.66067,
                source: "TwelveData_ws",
                start_ms: 1721786414000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1721786414250,
          },
          {
            leverage: 0.01999999999999999,
            order_type: "LONG",
            order_uuid: "25ab4c0b-5479-4ca5-a294-760a9c35a3a4",
            price: 0.66016,
            price_sources: [
              {
                close: 0.66016,
                high: 0.66016,
                lag_ms: 200,
                low: 0.66016,
                open: 0.66016,
                source: "Polygon_ws",
                start_ms: 1721790051999,
                timespan_ms: 0,
                volume: 1,
                vwap: 0.66016,
                websocket: true,
              },
              {
                close: 0.66011,
                high: 0.66011,
                lag_ms: 201,
                low: 0.66011,
                open: 0.66011,
                source: "TwelveData_ws",
                start_ms: 1721790052000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1721790051799,
          },
          {
            leverage: 0.01999999999999999,
            order_type: "LONG",
            order_uuid: "d5d5fd37-e515-4cd8-834e-1df9c46ac8db",
            price: 0.6595,
            price_sources: [
              {
                close: 0.6595,
                high: 0.6595,
                lag_ms: 132,
                low: 0.6595,
                open: 0.6595,
                source: "Polygon_ws",
                start_ms: 1721793615000,
                timespan_ms: 0,
                volume: 1,
                vwap: 0.6595,
                websocket: true,
              },
              {
                close: 0.65952,
                high: 0.65952,
                lag_ms: 868,
                low: 0.65952,
                open: 0.65952,
                source: "TwelveData_ws",
                start_ms: 1721793616000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1721793615132,
          },
          {
            leverage: 0.020000000000000018,
            order_type: "LONG",
            order_uuid: "07618172-1a6c-472a-9366-4d9c23503585",
            price: 0.65987,
            price_sources: [
              {
                close: 0.65987,
                high: 0.65987,
                lag_ms: 706,
                low: 0.65987,
                open: 0.65987,
                source: "TwelveData_ws",
                start_ms: 1721804544000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
              {
                close: 0.65939,
                high: 0.65939,
                lag_ms: 10870295,
                low: 0.65939,
                open: 0.65939,
                source: "Polygon_ws",
                start_ms: 1721793672999,
                timespan_ms: 0,
                volume: 1,
                vwap: 0.65939,
                websocket: true,
              },
            ],
            processed_ms: 1721804543294,
          },
          {
            leverage: -0.16000000000000003,
            order_type: "SHORT",
            order_uuid: "AUDUSD-1721847611.miner_signal",
            price: 0.65794,
            price_sources: [
              {
                close: 0.65794,
                high: 0.65794,
                lag_ms: 1228,
                low: 0.65794,
                open: 0.65794,
                source: "Polygon_rest",
                start_ms: 1721847614000,
                timespan_ms: 1000,
                volume: 2,
                vwap: null,
                websocket: false,
              },
              {
                close: 0.65798,
                high: 0.65798,
                lag_ms: 3227,
                low: 0.65798,
                open: 0.65798,
                source: "TwelveData_ws",
                start_ms: 1721847613000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
              {
                close: 0.6581,
                high: 0.6581,
                lag_ms: 16228,
                low: 0.658,
                open: 0.658,
                source: "TwelveData_rest",
                start_ms: 1721847540000,
                timespan_ms: 60000,
                volume: null,
                vwap: null,
                websocket: false,
              },
              {
                close: 0.65939,
                high: 0.65939,
                lag_ms: 53943228,
                low: 0.65939,
                open: 0.65939,
                source: "Polygon_ws",
                start_ms: 1721793672999,
                timespan_ms: 0,
                volume: 1,
                vwap: 0.65939,
                websocket: true,
              },
            ],
            processed_ms: 1721847616227,
          },
          {
            leverage: 0.0050000000000000044,
            order_type: "LONG",
            order_uuid: "AUDUSD-1721851205.miner_signal",
            price: 0.65789,
            price_sources: [
              {
                close: 0.65789,
                high: 0.65789,
                lag_ms: 457,
                low: 0.65789,
                open: 0.65789,
                source: "TwelveData_ws",
                start_ms: 1721851210000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
              {
                close: 0.6579,
                high: 0.6579,
                lag_ms: 458,
                low: 0.6579,
                open: 0.6579,
                source: "Polygon_rest",
                start_ms: 1721851209000,
                timespan_ms: 1000,
                volume: 1,
                vwap: null,
                websocket: false,
              },
              {
                close: 0.65795,
                high: 0.65795,
                lag_ms: 70458,
                low: 0.6579,
                open: 0.6579,
                source: "TwelveData_rest",
                start_ms: 1721851080000,
                timespan_ms: 60000,
                volume: null,
                vwap: null,
                websocket: false,
              },
              {
                close: 0.65939,
                high: 0.65939,
                lag_ms: 57537458,
                low: 0.65939,
                open: 0.65939,
                source: "Polygon_ws",
                start_ms: 1721793672999,
                timespan_ms: 0,
                volume: 1,
                vwap: 0.65939,
                websocket: true,
              },
            ],
            processed_ms: 1721851210457,
          },
          {
            leverage: 0.0049999999999999906,
            order_type: "LONG",
            order_uuid: "AUDUSD-1721865604.miner_signal",
            price: 0.65731,
            price_sources: [
              {
                close: 0.65731,
                high: 0.65731,
                lag_ms: 308,
                low: 0.65731,
                open: 0.65731,
                source: "TwelveData_ws",
                start_ms: 1721865608000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
              {
                close: 0.65734,
                high: 0.65734,
                lag_ms: 308,
                low: 0.65734,
                open: 0.65734,
                source: "Polygon_rest",
                start_ms: 1721865608000,
                timespan_ms: 1000,
                volume: 1,
                vwap: null,
                websocket: false,
              },
              {
                close: 0.6582,
                high: 0.65825,
                lag_ms: 6668309,
                low: 0.6582,
                open: 0.65825,
                source: "TwelveData_rest",
                start_ms: 1721858880000,
                timespan_ms: 60000,
                volume: null,
                vwap: null,
                websocket: false,
              },
              {
                close: 0.65939,
                high: 0.65939,
                lag_ms: 71935309,
                low: 0.65939,
                open: 0.65939,
                source: "Polygon_ws",
                start_ms: 1721793672999,
                timespan_ms: 0,
                volume: 1,
                vwap: 0.65939,
                websocket: true,
              },
            ],
            processed_ms: 1721865608308,
          },
          {
            leverage: 0.005000000000000018,
            order_type: "LONG",
            order_uuid: "AUDUSD-1721869200.miner_signal",
            price: 0.65634,
            price_sources: [
              {
                close: 0.65634,
                high: 0.65634,
                lag_ms: 575,
                low: 0.65634,
                open: 0.65634,
                source: "TwelveData_ws",
                start_ms: 1721869205000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
              {
                close: 0.65639,
                high: 0.65639,
                lag_ms: 2576,
                low: 0.65639,
                open: 0.65639,
                source: "Polygon_rest",
                start_ms: 1721869202000,
                timespan_ms: 1000,
                volume: 1,
                vwap: null,
                websocket: false,
              },
              {
                close: 0.6582,
                high: 0.65825,
                lag_ms: 10265576,
                low: 0.6582,
                open: 0.65825,
                source: "TwelveData_rest",
                start_ms: 1721858880000,
                timespan_ms: 60000,
                volume: null,
                vwap: null,
                websocket: false,
              },
              {
                close: 0.65939,
                high: 0.65939,
                lag_ms: 75532576,
                low: 0.65939,
                open: 0.65939,
                source: "Polygon_ws",
                start_ms: 1721793672999,
                timespan_ms: 0,
                volume: 1,
                vwap: 0.65939,
                websocket: true,
              },
            ],
            processed_ms: 1721869205575,
          },
          {
            leverage: 0.0050000000000000044,
            order_type: "LONG",
            order_uuid: "AUDUSD-1721872809.miner_signal",
            price: 0.65632,
            price_sources: [
              {
                close: 0.65632,
                high: 0.65632,
                lag_ms: 125,
                low: 0.65632,
                open: 0.65632,
                source: "TwelveData_ws",
                start_ms: 1721872815000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
              {
                close: 0.65939,
                high: 0.65939,
                lag_ms: 79141876,
                low: 0.65939,
                open: 0.65939,
                source: "Polygon_ws",
                start_ms: 1721793672999,
                timespan_ms: 0,
                volume: 1,
                vwap: 0.65939,
                websocket: true,
              },
            ],
            processed_ms: 1721872814875,
          },
          {
            leverage: 0.0049999999999999906,
            order_type: "LONG",
            order_uuid: "AUDUSD-1721876406.miner_signal",
            price: 0.65539,
            price_sources: [
              {
                close: 0.65539,
                high: 0.65539,
                lag_ms: 481,
                low: 0.65539,
                open: 0.65539,
                source: "TwelveData_ws",
                start_ms: 1721876414000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
              {
                close: 0.655385,
                high: 0.655385,
                lag_ms: 520,
                low: 0.655385,
                open: 0.655385,
                source: "Polygon_rest",
                start_ms: 1721876412000,
                timespan_ms: 1000,
                volume: 2,
                vwap: null,
                websocket: false,
              },
              {
                close: 0.6554,
                high: 0.6554,
                lag_ms: 193520,
                low: 0.6553,
                open: 0.6553,
                source: "TwelveData_rest",
                start_ms: 1721876160000,
                timespan_ms: 60000,
                volume: null,
                vwap: null,
                websocket: false,
              },
              {
                close: 0.65939,
                high: 0.65939,
                lag_ms: 82740520,
                low: 0.65939,
                open: 0.65939,
                source: "Polygon_ws",
                start_ms: 1721793672999,
                timespan_ms: 0,
                volume: 1,
                vwap: 0.65939,
                websocket: true,
              },
            ],
            processed_ms: 1721876413519,
          },
          {
            leverage: 0.0050000000000000044,
            order_type: "LONG",
            order_uuid: "AUDUSD-1721880013.miner_signal",
            price: 0.65503,
            price_sources: [
              {
                close: 0.65503,
                high: 0.65503,
                lag_ms: 228,
                low: 0.65503,
                open: 0.65503,
                source: "Polygon_rest",
                start_ms: 1721880017000,
                timespan_ms: 1000,
                volume: 2,
                vwap: null,
                websocket: false,
              },
              {
                close: 0.655,
                high: 0.655,
                lag_ms: 771,
                low: 0.655,
                open: 0.655,
                source: "TwelveData_ws",
                start_ms: 1721880017000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
              {
                close: 0.655045,
                high: 0.655045,
                lag_ms: 58229,
                low: 0.655045,
                open: 0.655045,
                source: "Polygon_ws",
                start_ms: 1721880076000,
                timespan_ms: 0,
                volume: 1,
                vwap: 0.65505,
                websocket: true,
              },
              {
                close: 0.6553,
                high: 0.6554,
                lag_ms: 797772,
                low: 0.6553,
                open: 0.6554,
                source: "TwelveData_rest",
                start_ms: 1721879160000,
                timespan_ms: 60000,
                volume: null,
                vwap: null,
                websocket: false,
              },
            ],
            processed_ms: 1721880017771,
          },
          {
            leverage: 0.905,
            order_type: "LONG",
            order_uuid: "AUDUSD-1721894412.miner_signal",
            price: 0.653355,
            price_sources: [
              {
                close: 0.653355,
                high: 0.653355,
                lag_ms: 217,
                low: 0.653355,
                open: 0.653355,
                source: "Polygon_ws",
                start_ms: 1721894417000,
                timespan_ms: 0,
                volume: 1,
                vwap: 0.65335,
                websocket: true,
              },
              {
                close: 0.65339,
                high: 0.65339,
                lag_ms: 217,
                low: 0.65339,
                open: 0.65339,
                source: "TwelveData_ws",
                start_ms: 1721894417000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1721894417217,
          },
          {
            leverage: 0,
            order_type: "FLAT",
            order_uuid: "AUDUSD-1721955617.miner_signal",
            price: 0.65457,
            price_sources: [
              {
                close: 0.65457,
                high: 0.65457,
                lag_ms: 312,
                low: 0.65457,
                open: 0.65457,
                source: "Polygon_ws",
                start_ms: 1721955619000,
                timespan_ms: 0,
                volume: 1,
                vwap: 0.65457,
                websocket: true,
              },
              {
                close: 0.65454,
                high: 0.65454,
                lag_ms: 312,
                low: 0.65454,
                open: 0.65454,
                source: "TwelveData_ws",
                start_ms: 1721955619000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1721955619312,
          },
        ],
        position_type: "FLAT",
        position_uuid: "1dc8fc56-708b-4857-8271-0f154553e5d6",
        return_at_close: 0.9999117728440039,
        trade_pair: ["AUDUSD", "AUD/USD", 0.00007, 0.1, 5],
      },
      {
        average_entry_price: 67054.31,
        close_ms: 1721977584775,
        current_return: 1.000022302817037,
        is_closed_position: true,
        miner_hotkey: "5GhCxfBcA7Ur5iiAS343xwvrYHTUfBjBi4JimiL5LhujRT9t",
        net_leverage: 0,
        open_ms: 1721966424633,
        orders: [
          {
            leverage: -0.01666666675,
            order_type: "SHORT",
            order_uuid: "BTCUSD-1721966422.miner_signal",
            price: 67054.31,
            price_sources: [
              {
                close: 67054.31,
                high: 67055.04,
                lag_ms: 366,
                low: 67054.3,
                open: 67054.31,
                source: "Polygon_ws",
                start_ms: 1721966424999,
                timespan_ms: 0,
                volume: 0.01117528,
                vwap: 67054.3008,
                websocket: true,
              },
              {
                close: 67070.1,
                high: 67070.1,
                lag_ms: 1633,
                low: 67070.1,
                open: 67070.1,
                source: "TwelveData_ws",
                start_ms: 1721966423000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1721966424633,
          },
          {
            leverage: 0,
            order_type: "FLAT",
            order_uuid: "BTCUSD-1721977582.miner_signal",
            price: 66964.58,
            price_sources: [
              {
                close: 66964.58,
                high: 66964.58,
                lag_ms: 224,
                low: 66963.28,
                open: 66964.58,
                source: "Polygon_ws",
                start_ms: 1721977584999,
                timespan_ms: 0,
                volume: 0.00780488,
                vwap: 66964.5709,
                websocket: true,
              },
              {
                close: 66955.4,
                high: 66955.4,
                lag_ms: 225,
                low: 66955.4,
                open: 66955.4,
                source: "TwelveData_ws",
                start_ms: 1721977585000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
              {
                close: 66955.38,
                high: 66955.38,
                lag_ms: 2776,
                low: 66955.38,
                open: 66955.38,
                source: "Polygon_rest",
                start_ms: 1721977581000,
                timespan_ms: 1000,
                volume: 0.00002167,
                vwap: 66955.38,
                websocket: false,
              },
              {
                close: 66961.05,
                high: 67014.47,
                lag_ms: 84776,
                low: 66956.42,
                open: 67007,
                source: "TwelveData_rest",
                start_ms: 1721977440000,
                timespan_ms: 60000,
                volume: null,
                vwap: null,
                websocket: false,
              },
            ],
            processed_ms: 1721977584775,
          },
        ],
        position_type: "FLAT",
        position_uuid: "BTCUSD-1721966422.miner_signal",
        return_at_close: 1.0000056357785734,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.01, 0.5],
      },
      {
        average_entry_price: 0.9587191666666666,
        close_ms: 1721984416553,
        current_return: 1.0000057055617504,
        is_closed_position: true,
        miner_hotkey: "5GhCxfBcA7Ur5iiAS343xwvrYHTUfBjBi4JimiL5LhujRT9t",
        net_leverage: 0,
        open_ms: 1721840649716,
        orders: [
          {
            leverage: 0.03,
            order_type: "LONG",
            order_uuid: "4ed9445c-fcce-4b5a-badd-71c127dc15f4",
            price: 0.95959,
            price_sources: [
              {
                close: 0.95959,
                high: 0.95959,
                lag_ms: 283,
                low: 0.95959,
                open: 0.95959,
                source: "Polygon_rest",
                start_ms: 1721840649000,
                timespan_ms: 1000,
                volume: 5,
                vwap: null,
                websocket: false,
              },
              {
                close: 0.95961,
                high: 0.95961,
                lag_ms: 284,
                low: 0.95961,
                open: 0.95961,
                source: "TwelveData_ws",
                start_ms: 1721840650000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
              {
                close: 0.9596,
                high: 0.9596,
                lag_ms: 69717,
                low: 0.9595,
                open: 0.9595,
                source: "TwelveData_rest",
                start_ms: 1721840520000,
                timespan_ms: 60000,
                volume: null,
                vwap: null,
                websocket: false,
              },
              {
                close: 0.967115,
                high: 0.967115,
                lag_ms: 46976717,
                low: 0.967115,
                open: 0.967115,
                source: "Polygon_ws",
                start_ms: 1721793672999,
                timespan_ms: 0,
                volume: 1,
                vwap: 0.96713,
                websocket: true,
              },
            ],
            processed_ms: 1721840649716,
          },
          {
            leverage: 0.03,
            order_type: "LONG",
            order_uuid: "0f504092-26d9-424c-aa7d-2fe12c1b57bf",
            price: 0.95951,
            price_sources: [
              {
                close: 0.95951,
                high: 0.95951,
                lag_ms: 438,
                low: 0.95951,
                open: 0.95951,
                source: "TwelveData_ws",
                start_ms: 1721840717000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
              {
                close: 0.95952,
                high: 0.95952,
                lag_ms: 438,
                low: 0.95952,
                open: 0.95952,
                source: "Polygon_rest",
                start_ms: 1721840717000,
                timespan_ms: 1000,
                volume: 1,
                vwap: null,
                websocket: false,
              },
              {
                close: 0.95954,
                high: 0.9596,
                lag_ms: 77439,
                low: 0.9595,
                open: 0.9596,
                source: "TwelveData_rest",
                start_ms: 1721840580000,
                timespan_ms: 60000,
                volume: null,
                vwap: null,
                websocket: false,
              },
              {
                close: 0.967115,
                high: 0.967115,
                lag_ms: 47044439,
                low: 0.967115,
                open: 0.967115,
                source: "Polygon_ws",
                start_ms: 1721793672999,
                timespan_ms: 0,
                volume: 1,
                vwap: 0.96713,
                websocket: true,
              },
            ],
            processed_ms: 1721840717438,
          },
          {
            leverage: 0.0075,
            order_type: "LONG",
            order_uuid: "EURCHF-1721869211.miner_signal",
            price: 0.95863,
            price_sources: [
              {
                close: 0.95863,
                high: 0.95863,
                lag_ms: 377,
                low: 0.95863,
                open: 0.95863,
                source: "Polygon_rest",
                start_ms: 1721869226000,
                timespan_ms: 1000,
                volume: 1,
                vwap: null,
                websocket: false,
              },
              {
                close: 0.95864,
                high: 0.95864,
                lag_ms: 378,
                low: 0.95864,
                open: 0.95864,
                source: "TwelveData_ws",
                start_ms: 1721869227000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
              {
                close: 0.95935,
                high: 0.95935,
                lag_ms: 8906623,
                low: 0.9592,
                open: 0.9592,
                source: "TwelveData_rest",
                start_ms: 1721860260000,
                timespan_ms: 60000,
                volume: null,
                vwap: null,
                websocket: false,
              },
              {
                close: 0.967115,
                high: 0.967115,
                lag_ms: 75553623,
                low: 0.967115,
                open: 0.967115,
                source: "Polygon_ws",
                start_ms: 1721793672999,
                timespan_ms: 0,
                volume: 1,
                vwap: 0.96713,
                websocket: true,
              },
            ],
            processed_ms: 1721869226622,
          },
          {
            leverage: 0.015,
            order_type: "LONG",
            order_uuid: "EURCHF-1721883608.miner_signal",
            price: 0.9572,
            price_sources: [
              {
                close: 0.9572,
                high: 0.9572,
                lag_ms: 323,
                low: 0.9572,
                open: 0.9572,
                source: "Polygon_ws",
                start_ms: 1721883623000,
                timespan_ms: 0,
                volume: 1,
                vwap: 0.9572,
                websocket: true,
              },
              {
                close: 0.95704,
                high: 0.95704,
                lag_ms: 323,
                low: 0.95704,
                open: 0.95704,
                source: "TwelveData_ws",
                start_ms: 1721883623000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1721883623323,
          },
          {
            leverage: 0.0075,
            order_type: "LONG",
            order_uuid: "EURCHF-1721898001.miner_signal",
            price: 0.9552,
            price_sources: [
              {
                close: 0.9552,
                high: 0.9552,
                lag_ms: 377,
                low: 0.9552,
                open: 0.9552,
                source: "Polygon_ws",
                start_ms: 1721898007000,
                timespan_ms: 0,
                volume: 1,
                vwap: 0.9552,
                websocket: true,
              },
              {
                close: 0.95505,
                high: 0.95505,
                lag_ms: 623,
                low: 0.95505,
                open: 0.95505,
                source: "TwelveData_ws",
                start_ms: 1721898008000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1721898007377,
          },
          {
            leverage: 0,
            order_type: "FLAT",
            order_uuid: "EURCHF-1721984408.miner_signal",
            price: 0.95878,
            price_sources: [
              {
                close: 0.95878,
                high: 0.95878,
                lag_ms: 446,
                low: 0.95878,
                open: 0.95878,
                source: "Polygon_ws",
                start_ms: 1721984416999,
                timespan_ms: 0,
                volume: 1,
                vwap: 0.95877,
                websocket: true,
              },
              {
                close: 0.95877,
                high: 0.95877,
                lag_ms: 4553,
                low: 0.95877,
                open: 0.95877,
                source: "TwelveData_ws",
                start_ms: 1721984412000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1721984416553,
          },
        ],
        position_type: "FLAT",
        position_uuid: "4ed9445c-fcce-4b5a-badd-71c127dc15f4",
        return_at_close: 0.9999850105330645,
        trade_pair: ["EURCHF", "EUR/CHF", 0.00007, 0.1, 5],
      },
      {
        average_entry_price: 67771.40111111112,
        close_ms: 1722058223550,
        current_return: 0.9998036081074662,
        is_closed_position: true,
        miner_hotkey: "5GhCxfBcA7Ur5iiAS343xwvrYHTUfBjBi4JimiL5LhujRT9t",
        net_leverage: 0,
        open_ms: 1721986217883,
        orders: [
          {
            leverage: -0.013333333400000003,
            order_type: "SHORT",
            order_uuid: "BTCUSD-1721986212.miner_signal",
            price: 67306.92,
            price_sources: [
              {
                close: 67306.92,
                high: 67306.92,
                lag_ms: 117,
                low: 67306.92,
                open: 67306.92,
                source: "Polygon_ws",
                start_ms: 1721986218000,
                timespan_ms: 0,
                volume: 0.002,
                vwap: 67306.92,
                websocket: true,
              },
              {
                close: 67389,
                high: 67393,
                lag_ms: 2884,
                low: 67295.18,
                open: 67295.18,
                source: "Polygon_rest",
                start_ms: 1721986214000,
                timespan_ms: 1000,
                volume: 1.46774038,
                vwap: 67309.6558,
                websocket: false,
              },
              {
                close: 67306.9,
                high: 67306.9,
                lag_ms: 8117,
                low: 67306.9,
                open: 67306.9,
                source: "TwelveData_ws",
                start_ms: 1721986226000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
              {
                close: 67260.13,
                high: 67290.19,
                lag_ms: 77884,
                low: 67252.07,
                open: 67257.64,
                source: "TwelveData_rest",
                start_ms: 1721986080000,
                timespan_ms: 60000,
                volume: null,
                vwap: null,
                websocket: false,
              },
            ],
            processed_ms: 1721986217883,
          },
          {
            leverage: -0.006666666699999995,
            order_type: "SHORT",
            order_uuid: "BTCUSD-1722001523.miner_signal",
            price: 67729,
            price_sources: [
              {
                close: 67729,
                high: 67729,
                lag_ms: 130,
                low: 67581.65,
                open: 67729,
                source: "Polygon_ws",
                start_ms: 1722001527999,
                timespan_ms: 0,
                volume: 0.0918044,
                vwap: 67632.7374,
                websocket: true,
              },
              {
                close: 67605.7,
                high: 67605.7,
                lag_ms: 131,
                low: 67605.7,
                open: 67605.7,
                source: "TwelveData_ws",
                start_ms: 1722001528000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
              {
                close: 67590.73,
                high: 67593.13,
                lag_ms: 2870,
                low: 67590.73,
                open: 67593.11,
                source: "Polygon_rest",
                start_ms: 1722001524000,
                timespan_ms: 1000,
                volume: 0.0422018,
                vwap: 67591.0546,
                websocket: false,
              },
              {
                close: 67406.63,
                high: 67412.12,
                lag_ms: 147870,
                low: 67307.07,
                open: 67386.31,
                source: "TwelveData_rest",
                start_ms: 1722001320000,
                timespan_ms: 60000,
                volume: null,
                vwap: null,
                websocket: false,
              },
            ],
            processed_ms: 1722001527869,
          },
          {
            leverage: -0.0066666667000000075,
            order_type: "SHORT",
            order_uuid: "BTCUSD-1722002427.miner_signal",
            price: 67959.99,
            price_sources: [
              {
                close: 67959.99,
                high: 67960,
                lag_ms: 363,
                low: 67954.88,
                open: 67959.99,
                source: "Polygon_ws",
                start_ms: 1722002429999,
                timespan_ms: 0,
                volume: 0.00100775,
                vwap: 67959.5563,
                websocket: true,
              },
              {
                close: 67944.59,
                high: 67944.59,
                lag_ms: 2637,
                low: 67928.81,
                open: 67928.81,
                source: "Polygon_rest",
                start_ms: 1722002426000,
                timespan_ms: 1000,
                volume: 0.60721937,
                vwap: 67943.2074,
                websocket: false,
              },
              {
                close: 67946.3,
                high: 67946.3,
                lag_ms: 6364,
                low: 67946.3,
                open: 67946.3,
                source: "TwelveData_ws",
                start_ms: 1722002436000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
              {
                close: 67893.96,
                high: 67946.51,
                lag_ms: 89637,
                low: 67870.01,
                open: 67917.46,
                source: "TwelveData_rest",
                start_ms: 1722002280000,
                timespan_ms: 60000,
                volume: null,
                vwap: null,
                websocket: false,
              },
            ],
            processed_ms: 1722002429636,
          },
          {
            leverage: -0.006666666699999994,
            order_type: "SHORT",
            order_uuid: "BTCUSD-1722003320.miner_signal",
            price: 67775.89,
            price_sources: [
              {
                close: 67775.89,
                high: 67783.59,
                lag_ms: 351,
                low: 67769.98,
                open: 67775.89,
                source: "Polygon_ws",
                start_ms: 1722003322999,
                timespan_ms: 0,
                volume: 0.51776813,
                vwap: 67780.7366,
                websocket: true,
              },
              {
                close: 67774,
                high: 67774,
                lag_ms: 1648,
                low: 67774,
                open: 67774,
                source: "TwelveData_ws",
                start_ms: 1722003321000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1722003322648,
          },
          {
            leverage: -0.006666666699999997,
            order_type: "SHORT",
            order_uuid: "BTCUSD-1722022215.miner_signal",
            price: 67855.79,
            price_sources: [
              {
                close: 67855.79,
                high: 67855.79,
                lag_ms: 145,
                low: 67855.79,
                open: 67855.79,
                source: "Polygon_ws",
                start_ms: 1722022227999,
                timespan_ms: 0,
                volume: 0.1079125,
                vwap: 67855.79,
                websocket: true,
              },
              {
                close: 67862.29,
                high: 67863.47,
                lag_ms: 2855,
                low: 67862.29,
                open: 67863.47,
                source: "Polygon_rest",
                start_ms: 1722022224000,
                timespan_ms: 1000,
                volume: 0.14170358,
                vwap: 67863.3638,
                websocket: false,
              },
              {
                close: 67877.4,
                high: 67877.4,
                lag_ms: 9854,
                low: 67877.4,
                open: 67877.4,
                source: "TwelveData_ws",
                start_ms: 1722022218000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
              {
                close: 67865.99,
                high: 67877.55,
                lag_ms: 87855,
                low: 67847.3,
                open: 67862.82,
                source: "TwelveData_rest",
                start_ms: 1722022080000,
                timespan_ms: 60000,
                volume: null,
                vwap: null,
                websocket: false,
              },
            ],
            processed_ms: 1722022227854,
          },
          {
            leverage: -0.00666666669999999,
            order_type: "SHORT",
            order_uuid: "BTCUSD-1722023120.miner_signal",
            price: 67948.02,
            price_sources: [
              {
                close: 67948.02,
                high: 67948.02,
                lag_ms: 153,
                low: 67948.02,
                open: 67948.02,
                source: "Polygon_ws",
                start_ms: 1722023125999,
                timespan_ms: 0,
                volume: 0.08744794,
                vwap: 67948.02,
                websocket: true,
              },
              {
                close: 67962,
                high: 67962,
                lag_ms: 846,
                low: 67962,
                open: 67962,
                source: "TwelveData_ws",
                start_ms: 1722023125000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
              {
                close: 67951.04,
                high: 67951.04,
                lag_ms: 2847,
                low: 67951.04,
                open: 67951.04,
                source: "Polygon_rest",
                start_ms: 1722023122000,
                timespan_ms: 1000,
                volume: 0.01938854,
                vwap: 67951.04,
                websocket: false,
              },
              {
                close: 67924.54,
                high: 67933.98,
                lag_ms: 85847,
                low: 67846.07,
                open: 67849.69,
                source: "TwelveData_rest",
                start_ms: 1722022980000,
                timespan_ms: 60000,
                volume: null,
                vwap: null,
                websocket: false,
              },
            ],
            processed_ms: 1722023125846,
          },
          {
            leverage: -0.006666666700000025,
            order_type: "SHORT",
            order_uuid: "BTCUSD-1722024025.miner_signal",
            price: 67983.7,
            price_sources: [
              {
                close: 67983.7,
                high: 67983.7,
                lag_ms: 330,
                low: 67977.4,
                open: 67983.7,
                source: "Polygon_ws",
                start_ms: 1722024030000,
                timespan_ms: 0,
                volume: 0.12516933,
                vwap: 67977.4913,
                websocket: true,
              },
              {
                close: 67981.57,
                high: 67981.57,
                lag_ms: 2331,
                low: 67981.57,
                open: 67981.57,
                source: "Polygon_rest",
                start_ms: 1722024027000,
                timespan_ms: 1000,
                volume: 0.00001706,
                vwap: 67981.57,
                websocket: false,
              },
              {
                close: 68007.5,
                high: 68007.5,
                lag_ms: 2670,
                low: 68007.5,
                open: 68007.5,
                source: "TwelveData_ws",
                start_ms: 1722024033000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
              {
                close: 68008.63,
                high: 68059.61,
                lag_ms: 30331,
                low: 67898.06,
                open: 68008.81,
                source: "TwelveData_rest",
                start_ms: 1722023940000,
                timespan_ms: 60000,
                volume: null,
                vwap: null,
                websocket: false,
              },
            ],
            processed_ms: 1722024030330,
          },
          {
            leverage: -0.00666666669999999,
            order_type: "SHORT",
            order_uuid: "BTCUSD-1722030309.miner_signal",
            price: 68076.38,
            price_sources: [
              {
                close: 68076.38,
                high: 68076.38,
                lag_ms: 192,
                low: 68076.38,
                open: 68076.38,
                source: "Polygon_ws",
                start_ms: 1722030312999,
                timespan_ms: 0,
                volume: 0.00001298,
                vwap: 68076.38,
                websocket: true,
              },
              {
                close: 68003.3,
                high: 68003.3,
                lag_ms: 1193,
                low: 68003.3,
                open: 68003.3,
                source: "TwelveData_ws",
                start_ms: 1722030314000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
              {
                close: 68070.9,
                high: 68186,
                lag_ms: 2808,
                low: 68018.7,
                open: 68083.32,
                source: "Polygon_rest",
                start_ms: 1722030309000,
                timespan_ms: 1000,
                volume: 0.08636316,
                vwap: 68094.1506,
                websocket: false,
              },
              {
                close: 67985.95,
                high: 68004.95,
                lag_ms: 72808,
                low: 67929.76,
                open: 67929.76,
                source: "TwelveData_rest",
                start_ms: 1722030180000,
                timespan_ms: 60000,
                volume: null,
                vwap: null,
                websocket: false,
              },
            ],
            processed_ms: 1722030312807,
          },
          {
            leverage: 0,
            order_type: "FLAT",
            order_uuid: "BTCUSD-1722058220.miner_signal",
            price: 67991.71,
            price_sources: [
              {
                close: 67991.71,
                high: 67991.71,
                lag_ms: 449,
                low: 67991.71,
                open: 67991.71,
                source: "Polygon_ws",
                start_ms: 1722058223999,
                timespan_ms: 0,
                volume: 0.00069905,
                vwap: 67991.71,
                websocket: true,
              },
              {
                close: 67991.71,
                high: 67991.71,
                lag_ms: 2551,
                low: 67990.3,
                open: 67990.3,
                source: "Polygon_rest",
                start_ms: 1722058220000,
                timespan_ms: 1000,
                volume: 0.00210171,
                vwap: 67990.7697,
                websocket: false,
              },
              {
                close: 67990.3,
                high: 67990.3,
                lag_ms: 12450,
                low: 67990.3,
                open: 67990.3,
                source: "TwelveData_ws",
                start_ms: 1722058236000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
              {
                close: 67989.81,
                high: 67993.8,
                lag_ms: 23551,
                low: 67980.44,
                open: 67984.41,
                source: "TwelveData_rest",
                start_ms: 1722058140000,
                timespan_ms: 60000,
                volume: null,
                vwap: null,
                websocket: false,
              },
            ],
            processed_ms: 1722058223550,
          },
        ],
        position_type: "FLAT",
        position_uuid: "BTCUSD-1721986212.miner_signal",
        return_at_close: 0.9997273845509157,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.01, 0.5],
      },
      {
        average_entry_price: 154.6872222222222,
        close_ms: 0,
        current_return: 0.999462336305757,
        is_closed_position: false,
        miner_hotkey: "5GhCxfBcA7Ur5iiAS343xwvrYHTUfBjBi4JimiL5LhujRT9t",
        net_leverage: 0.08999999999999996,
        open_ms: 1721725349302,
        orders: [
          {
            leverage: 0.020000000000000004,
            order_type: "LONG",
            order_uuid: "999cd87b-6cdc-48f9-ba27-0aceacafe243",
            price: 156.0455,
            price_sources: [
              {
                close: 156.0455,
                high: 156.0455,
                lag_ms: 302,
                low: 156.0455,
                open: 156.0455,
                source: "Polygon_ws",
                start_ms: 1721725349000,
                timespan_ms: 0,
                volume: 1,
                vwap: 156.046,
                websocket: true,
              },
              {
                close: 156.047,
                high: 156.047,
                lag_ms: 698,
                low: 156.047,
                open: 156.047,
                source: "TwelveData_ws",
                start_ms: 1721725350000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1721725349302,
          },
          {
            leverage: 0.020000000000000004,
            order_type: "LONG",
            order_uuid: "b35d6bd0-b878-4e5b-ba0f-2559ad4feef6",
            price: 155.55,
            price_sources: [
              {
                close: 155.55,
                high: 155.55,
                lag_ms: 511,
                low: 155.55,
                open: 155.55,
                source: "Polygon_ws",
                start_ms: 1721768406000,
                timespan_ms: 0,
                volume: 1,
                vwap: 155.55,
                websocket: true,
              },
              {
                close: 155.544,
                high: 155.544,
                lag_ms: 511,
                low: 155.544,
                open: 155.544,
                source: "TwelveData_ws",
                start_ms: 1721768406000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1721768405489,
          },
          {
            leverage: 0.019999999999999997,
            order_type: "LONG",
            order_uuid: "68631b10-49a5-417b-bf58-71909bd37ec0",
            price: 154.664,
            price_sources: [
              {
                close: 154.664,
                high: 154.664,
                lag_ms: 476,
                low: 154.664,
                open: 154.664,
                source: "TwelveData_ws",
                start_ms: 1721805006000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
              {
                close: 154.998,
                high: 154.998,
                lag_ms: 11331525,
                low: 154.998,
                open: 154.998,
                source: "Polygon_ws",
                start_ms: 1721793673999,
                timespan_ms: 0,
                volume: 1,
                vwap: 154.998,
                websocket: true,
              },
            ],
            processed_ms: 1721805005524,
          },
          {
            leverage: 0.019999999999999962,
            order_type: "LONG",
            order_uuid: "9ca3a039-20e0-422f-95a6-0858b5f03d20",
            price: 153.528,
            price_sources: [
              {
                close: 153.528,
                high: 153.528,
                lag_ms: 374,
                low: 153.528,
                open: 153.528,
                source: "TwelveData_ws",
                start_ms: 1721840644000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
              {
                close: 153.529,
                high: 153.529,
                lag_ms: 374,
                low: 153.529,
                open: 153.529,
                source: "Polygon_rest",
                start_ms: 1721840644000,
                timespan_ms: 1000,
                volume: 1,
                vwap: null,
                websocket: false,
              },
              {
                close: 153.53,
                high: 153.56,
                lag_ms: 64375,
                low: 153.49001,
                open: 153.49001,
                source: "TwelveData_rest",
                start_ms: 1721840520000,
                timespan_ms: 60000,
                volume: null,
                vwap: null,
                websocket: false,
              },
              {
                close: 154.998,
                high: 154.998,
                lag_ms: 46970375,
                low: 154.998,
                open: 154.998,
                source: "Polygon_ws",
                start_ms: 1721793673999,
                timespan_ms: 0,
                volume: 1,
                vwap: 154.998,
                websocket: true,
              },
            ],
            processed_ms: 1721840644374,
          },
          {
            leverage: 0.009999999999999995,
            order_type: "LONG",
            order_uuid: "USDJPY-1721883608.miner_signal",
            price: 152.61,
            price_sources: [
              {
                close: 152.61,
                high: 152.61,
                lag_ms: 247,
                low: 152.61,
                open: 152.61,
                source: "Polygon_ws",
                start_ms: 1721883623000,
                timespan_ms: 0,
                volume: 1,
                vwap: 152.61,
                websocket: true,
              },
              {
                close: 152.617,
                high: 152.617,
                lag_ms: 753,
                low: 152.617,
                open: 152.617,
                source: "TwelveData_ws",
                start_ms: 1721883624000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1721883623247,
          },
        ],
        position_type: "LONG",
        position_uuid: "999cd87b-6cdc-48f9-ba27-0aceacafe243",
        return_at_close: 0.9993991383813924,
        trade_pair: ["USDJPY", "USD/JPY", 0.00007, 0.1, 5],
      },
      {
        average_entry_price: 101.93208474576274,
        close_ms: 0,
        current_return: 0.9897206709317743,
        is_closed_position: false,
        miner_hotkey: "5GhCxfBcA7Ur5iiAS343xwvrYHTUfBjBi4JimiL5LhujRT9t",
        net_leverage: 0.5900000000000001,
        open_ms: 1721725293311,
        orders: [
          {
            leverage: 0.04000000000000001,
            order_type: "LONG",
            order_uuid: "973d86c4-20d5-4e6d-9d1f-11d617d2455e",
            price: 103.319,
            price_sources: [
              {
                close: 103.319,
                high: 103.319,
                lag_ms: 311,
                low: 103.319,
                open: 103.319,
                source: "Polygon_ws",
                start_ms: 1721725293000,
                timespan_ms: 0,
                volume: 1,
                vwap: 103.322,
                websocket: true,
              },
              {
                close: 103.322,
                high: 103.322,
                lag_ms: 311,
                low: 103.322,
                open: 103.322,
                source: "TwelveData_ws",
                start_ms: 1721725293000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1721725293311,
          },
          {
            leverage: 0.060000000000000026,
            order_type: "LONG",
            order_uuid: "7f83867a-62b0-4ec0-babb-c0ab74c06fe5",
            price: 103.327,
            price_sources: [
              {
                close: 103.327,
                high: 103.327,
                lag_ms: 89,
                low: 103.327,
                open: 103.327,
                source: "Polygon_ws",
                start_ms: 1721739622000,
                timespan_ms: 0,
                volume: 1,
                vwap: 103.325,
                websocket: true,
              },
              {
                close: 103.333,
                high: 103.333,
                lag_ms: 89,
                low: 103.333,
                open: 103.333,
                source: "TwelveData_ws",
                start_ms: 1721739622000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1721739622089,
          },
          {
            leverage: 0.08000000000000002,
            order_type: "LONG",
            order_uuid: "dee41929-857e-48dd-a293-50ea979b9c32",
            price: 102.88,
            price_sources: [
              {
                close: 102.88,
                high: 102.88,
                lag_ms: 106,
                low: 102.88,
                open: 102.88,
                source: "Polygon_ws",
                start_ms: 1721768415999,
                timespan_ms: 0,
                volume: 1,
                vwap: 102.88,
                websocket: true,
              },
              {
                close: 102.88,
                high: 102.88,
                lag_ms: 106,
                low: 102.88,
                open: 102.88,
                source: "Polygon_rest",
                start_ms: 1721768415000,
                timespan_ms: 1000,
                volume: 1,
                vwap: null,
                websocket: false,
              },
              {
                close: 102.845,
                high: 102.845,
                lag_ms: 5893,
                low: 102.845,
                open: 102.845,
                source: "TwelveData_ws",
                start_ms: 1721768410000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
              {
                close: 102.93,
                high: 102.97,
                lag_ms: 75894,
                low: 102.92,
                open: 102.96,
                source: "TwelveData_rest",
                start_ms: 1721768280000,
                timespan_ms: 60000,
                volume: null,
                vwap: null,
                websocket: false,
              },
            ],
            processed_ms: 1721768415893,
          },
          {
            leverage: 0.04000000000000001,
            order_type: "LONG",
            order_uuid: "6195220e-c520-4cf5-b0a5-05e975bb79a2",
            price: 103.032,
            price_sources: [
              {
                close: 103.032,
                high: 103.032,
                lag_ms: 429,
                low: 103.032,
                open: 103.032,
                source: "Polygon_ws",
                start_ms: 1721782819000,
                timespan_ms: 0,
                volume: 1,
                vwap: 103.032,
                websocket: true,
              },
              {
                close: 103.034,
                high: 103.034,
                lag_ms: 429,
                low: 103.034,
                open: 103.034,
                source: "TwelveData_ws",
                start_ms: 1721782819000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1721782819429,
          },
          {
            leverage: 0.07999999999999999,
            order_type: "LONG",
            order_uuid: "b168e688-568e-423c-a0c5-841d4b0357d9",
            price: 101.95599999999999,
            price_sources: [
              {
                close: 101.95599999999999,
                high: 101.95599999999999,
                lag_ms: 237,
                low: 101.95599999999999,
                open: 101.95599999999999,
                source: "Polygon_rest",
                start_ms: 1721804356000,
                timespan_ms: 1000,
                volume: 2,
                vwap: null,
                websocket: false,
              },
              {
                close: 101.959,
                high: 101.959,
                lag_ms: 238,
                low: 101.959,
                open: 101.959,
                source: "TwelveData_ws",
                start_ms: 1721804357000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
              {
                close: 101.94,
                high: 101.94,
                lag_ms: 76763,
                low: 101.92,
                open: 101.92,
                source: "TwelveData_rest",
                start_ms: 1721804220000,
                timespan_ms: 60000,
                volume: null,
                vwap: null,
                websocket: false,
              },
              {
                close: 102.195,
                high: 102.195,
                lag_ms: 10683763,
                low: 102.195,
                open: 102.195,
                source: "Polygon_ws",
                start_ms: 1721793672999,
                timespan_ms: 0,
                volume: 1,
                vwap: 102.192,
                websocket: true,
              },
            ],
            processed_ms: 1721804356762,
          },
          {
            leverage: 0.1200000000000001,
            order_type: "LONG",
            order_uuid: "944c31b6-e647-4883-a697-254e3c9175f8",
            price: 101.668,
            price_sources: [
              {
                close: 101.668,
                high: 101.668,
                lag_ms: 118,
                low: 101.668,
                open: 101.668,
                source: "TwelveData_ws",
                start_ms: 1721826150000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
              {
                close: 101.664,
                high: 101.664,
                lag_ms: 119,
                low: 101.664,
                open: 101.664,
                source: "Polygon_rest",
                start_ms: 1721826149000,
                timespan_ms: 1000,
                volume: 5,
                vwap: null,
                websocket: false,
              },
              {
                close: 101.62,
                high: 101.62,
                lag_ms: 810119,
                low: 101.59,
                open: 101.61,
                source: "TwelveData_rest",
                start_ms: 1721825280000,
                timespan_ms: 60000,
                volume: null,
                vwap: null,
                websocket: false,
              },
              {
                close: 102.195,
                high: 102.195,
                lag_ms: 32477119,
                low: 102.195,
                open: 102.195,
                source: "Polygon_ws",
                start_ms: 1721793672999,
                timespan_ms: 0,
                volume: 1,
                vwap: 102.192,
                websocket: true,
              },
            ],
            processed_ms: 1721826150118,
          },
          {
            leverage: 0.03999999999999998,
            order_type: "LONG",
            order_uuid: "50aa5794-75d5-4009-a793-bbd5a4eaf67c",
            price: 101.268,
            price_sources: [
              {
                close: 101.268,
                high: 101.268,
                lag_ms: 192,
                low: 101.268,
                open: 101.268,
                source: "TwelveData_ws",
                start_ms: 1721829702000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
              {
                close: 102.195,
                high: 102.195,
                lag_ms: 36028809,
                low: 102.195,
                open: 102.195,
                source: "Polygon_ws",
                start_ms: 1721793672999,
                timespan_ms: 0,
                volume: 1,
                vwap: 102.192,
                websocket: true,
              },
            ],
            processed_ms: 1721829701808,
          },
          {
            leverage: 0.09999999999999998,
            order_type: "LONG",
            order_uuid: "8d75c11b-1a6d-4d3f-be8b-dc65a78b24ac",
            price: 101.286,
            price_sources: [
              {
                close: 101.286,
                high: 101.286,
                lag_ms: 933,
                low: 101.286,
                open: 101.286,
                source: "TwelveData_ws",
                start_ms: 1721840644000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
              {
                close: 102.195,
                high: 102.195,
                lag_ms: 46971934,
                low: 102.195,
                open: 102.195,
                source: "Polygon_ws",
                start_ms: 1721793672999,
                timespan_ms: 0,
                volume: 1,
                vwap: 102.192,
                websocket: true,
              },
            ],
            processed_ms: 1721840644933,
          },
          {
            leverage: 0.01999999999999999,
            order_type: "LONG",
            order_uuid: "AUDJPY-1721854810.miner_signal",
            price: 101.27,
            price_sources: [
              {
                close: 101.27,
                high: 101.27,
                lag_ms: 51,
                low: 101.27,
                open: 101.27,
                source: "Polygon_rest",
                start_ms: 1721854814000,
                timespan_ms: 1000,
                volume: 1,
                vwap: null,
                websocket: false,
              },
              {
                close: 101.208,
                high: 101.208,
                lag_ms: 3948,
                low: 101.208,
                open: 101.208,
                source: "TwelveData_ws",
                start_ms: 1721854811000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
              {
                close: 101.27,
                high: 101.28,
                lag_ms: 14949,
                low: 101.25,
                open: 101.28,
                source: "TwelveData_rest",
                start_ms: 1721854740000,
                timespan_ms: 60000,
                volume: null,
                vwap: null,
                websocket: false,
              },
              {
                close: 102.195,
                high: 102.195,
                lag_ms: 61141949,
                low: 102.195,
                open: 102.195,
                source: "Polygon_ws",
                start_ms: 1721793672999,
                timespan_ms: 0,
                volume: 1,
                vwap: 102.192,
                websocket: true,
              },
            ],
            processed_ms: 1721854814948,
          },
          {
            leverage: 0.010000000000000009,
            order_type: "LONG",
            order_uuid: "AUDJPY-1721872809.miner_signal",
            price: 100.308,
            price_sources: [
              {
                close: 100.308,
                high: 100.308,
                lag_ms: 133,
                low: 100.308,
                open: 100.308,
                source: "TwelveData_ws",
                start_ms: 1721872815000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
              {
                close: 102.195,
                high: 102.195,
                lag_ms: 79141868,
                low: 102.195,
                open: 102.195,
                source: "Polygon_ws",
                start_ms: 1721793672999,
                timespan_ms: 0,
                volume: 1,
                vwap: 102.192,
                websocket: true,
              },
            ],
            processed_ms: 1721872814867,
          },
          {
            leverage: 0.010000000000000009,
            order_type: "LONG",
            order_uuid: "AUDJPY-1721876406.miner_signal",
            price: 100.026,
            price_sources: [
              {
                close: 100.026,
                high: 100.026,
                lag_ms: 485,
                low: 100.026,
                open: 100.026,
                source: "TwelveData_ws",
                start_ms: 1721876413000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
              {
                close: 100.023,
                high: 100.023,
                lag_ms: 485,
                low: 100.023,
                open: 100.023,
                source: "Polygon_rest",
                start_ms: 1721876413000,
                timespan_ms: 1000,
                volume: 1,
                vwap: null,
                websocket: false,
              },
              {
                close: 99.97,
                high: 100,
                lag_ms: 253486,
                low: 99.97,
                open: 99.98,
                source: "TwelveData_rest",
                start_ms: 1721876100000,
                timespan_ms: 60000,
                volume: null,
                vwap: null,
                websocket: false,
              },
              {
                close: 102.195,
                high: 102.195,
                lag_ms: 82740486,
                low: 102.195,
                open: 102.195,
                source: "Polygon_ws",
                start_ms: 1721793672999,
                timespan_ms: 0,
                volume: 1,
                vwap: 102.192,
                websocket: true,
              },
            ],
            processed_ms: 1721876413485,
          },
          {
            leverage: 0.010000000000000009,
            order_type: "LONG",
            order_uuid: "AUDJPY-1721880013.miner_signal",
            price: 99.782,
            price_sources: [
              {
                close: 99.782,
                high: 99.782,
                lag_ms: 432,
                low: 99.782,
                open: 99.782,
                source: "Polygon_rest",
                start_ms: 1721880017000,
                timespan_ms: 1000,
                volume: 1,
                vwap: null,
                websocket: false,
              },
              {
                close: 99.798,
                high: 99.798,
                lag_ms: 433,
                low: 99.798,
                open: 99.798,
                source: "TwelveData_ws",
                start_ms: 1721880018000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
              {
                close: 99.81,
                high: 99.81,
                lag_ms: 58433,
                low: 99.81,
                open: 99.81,
                source: "Polygon_ws",
                start_ms: 1721880076000,
                timespan_ms: 0,
                volume: 1,
                vwap: 99.81,
                websocket: true,
              },
              {
                close: 99.86,
                high: 99.87,
                lag_ms: 857568,
                low: 99.84,
                open: 99.85,
                source: "TwelveData_rest",
                start_ms: 1721879100000,
                timespan_ms: 60000,
                volume: null,
                vwap: null,
                websocket: false,
              },
            ],
            processed_ms: 1721880017567,
          },
          {
            leverage: 0.030000000000000027,
            order_type: "LONG",
            order_uuid: "AUDJPY-1721883608.miner_signal",
            price: 99.832,
            price_sources: [
              {
                close: 99.832,
                high: 99.832,
                lag_ms: 343,
                low: 99.832,
                open: 99.832,
                source: "Polygon_ws",
                start_ms: 1721883623999,
                timespan_ms: 0,
                volume: 1,
                vwap: 99.832,
                websocket: true,
              },
              {
                close: 99.84,
                high: 99.84,
                lag_ms: 344,
                low: 99.84,
                open: 99.84,
                source: "TwelveData_ws",
                start_ms: 1721883624000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1721883623656,
          },
          {
            leverage: 0.010000000000000009,
            order_type: "LONG",
            order_uuid: "AUDJPY-1721894412.miner_signal",
            price: 99.40950000000001,
            price_sources: [
              {
                close: 99.40950000000001,
                high: 99.40950000000001,
                lag_ms: 98,
                low: 99.40950000000001,
                open: 99.40950000000001,
                source: "Polygon_ws",
                start_ms: 1721894417000,
                timespan_ms: 0,
                volume: 1,
                vwap: 99.405,
                websocket: true,
              },
              {
                close: 99.416,
                high: 99.416,
                lag_ms: 98,
                low: 99.416,
                open: 99.416,
                source: "TwelveData_ws",
                start_ms: 1721894417000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1721894417098,
          },
          {
            leverage: 0.025000000000000022,
            order_type: "LONG",
            order_uuid: "AUDJPY-1721898013.miner_signal",
            price: 99.791,
            price_sources: [
              {
                close: 99.791,
                high: 99.791,
                lag_ms: 11,
                low: 99.791,
                open: 99.791,
                source: "Polygon_ws",
                start_ms: 1721898020999,
                timespan_ms: 0,
                volume: 1,
                vwap: 99.793,
                websocket: true,
              },
              {
                close: 99.792,
                high: 99.792,
                lag_ms: 12,
                low: 99.792,
                open: 99.792,
                source: "TwelveData_ws",
                start_ms: 1721898021000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1721898020988,
          },
          {
            leverage: 0.010000000000000009,
            order_type: "LONG",
            order_uuid: "AUDJPY-1721901609.miner_signal",
            price: 99.363,
            price_sources: [
              {
                close: 99.363,
                high: 99.363,
                lag_ms: 424,
                low: 99.363,
                open: 99.363,
                source: "Polygon_ws",
                start_ms: 1721901612000,
                timespan_ms: 0,
                volume: 1,
                vwap: 99.363,
                websocket: true,
              },
              {
                close: 99.373,
                high: 99.373,
                lag_ms: 424,
                low: 99.373,
                open: 99.373,
                source: "TwelveData_ws",
                start_ms: 1721901612000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1721901612424,
          },
          {
            leverage: 0.010000000000000009,
            order_type: "LONG",
            order_uuid: "AUDJPY-1721905215.miner_signal",
            price: 99.2765,
            price_sources: [
              {
                close: 99.2765,
                high: 99.2765,
                lag_ms: 86,
                low: 99.2765,
                open: 99.2765,
                source: "Polygon_ws",
                start_ms: 1721905218000,
                timespan_ms: 0,
                volume: 1,
                vwap: 99.278,
                websocket: true,
              },
              {
                close: 99.281,
                high: 99.281,
                lag_ms: 86,
                low: 99.281,
                open: 99.281,
                source: "TwelveData_ws",
                start_ms: 1721905218000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1721905218086,
          },
          {
            leverage: -0.08000000000000013,
            order_type: "SHORT",
            order_uuid: "AUDJPY-1721916017.miner_signal",
            price: 100.54750000000001,
            price_sources: [
              {
                close: 100.54750000000001,
                high: 100.54750000000001,
                lag_ms: 24,
                low: 100.54750000000001,
                open: 100.54750000000001,
                source: "Polygon_ws",
                start_ms: 1721916022999,
                timespan_ms: 0,
                volume: 1,
                vwap: 100.54,
                websocket: true,
              },
              {
                close: 100.554,
                high: 100.554,
                lag_ms: 975,
                low: 100.554,
                open: 100.554,
                source: "TwelveData_ws",
                start_ms: 1721916022000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1721916022975,
          },
          {
            leverage: -0.024999999999999967,
            order_type: "SHORT",
            order_uuid: "AUDJPY-1721984408.miner_signal",
            price: 101.323,
            price_sources: [
              {
                close: 101.323,
                high: 101.323,
                lag_ms: 300,
                low: 101.323,
                open: 101.323,
                source: "Polygon_ws",
                start_ms: 1721984416999,
                timespan_ms: 0,
                volume: 1,
                vwap: 101.323,
                websocket: true,
              },
              {
                close: 101.326,
                high: 101.326,
                lag_ms: 4699,
                low: 101.326,
                open: 101.326,
                source: "TwelveData_ws",
                start_ms: 1721984412000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1721984416699,
          },
        ],
        position_type: "LONG",
        position_uuid: "973d86c4-20d5-4e6d-9d1f-11d617d2455e",
        return_at_close: 0.9892706022667354,
        trade_pair: ["AUDJPY", "AUD/JPY", 0.00007, 0.1, 5],
      },
      {
        average_entry_price: 167.6748230088496,
        close_ms: 0,
        current_return: 0.9986933954480628,
        is_closed_position: false,
        miner_hotkey: "5GhCxfBcA7Ur5iiAS343xwvrYHTUfBjBi4JimiL5LhujRT9t",
        net_leverage: 0.2825,
        open_ms: 1721725349312,
        orders: [
          {
            leverage: 0.05,
            order_type: "LONG",
            order_uuid: "ddc768e0-c362-4905-90a6-077c4a2d1b1c",
            price: 169.686,
            price_sources: [
              {
                close: 169.686,
                high: 169.686,
                lag_ms: 312,
                low: 169.686,
                open: 169.686,
                source: "Polygon_ws",
                start_ms: 1721725349000,
                timespan_ms: 0,
                volume: 1,
                vwap: 169.686,
                websocket: true,
              },
              {
                close: 169.686,
                high: 169.686,
                lag_ms: 312,
                low: 169.686,
                open: 169.686,
                source: "TwelveData_ws",
                start_ms: 1721725349000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1721725349312,
          },
          {
            leverage: 0.05,
            order_type: "LONG",
            order_uuid: "fae09da0-ed9e-4911-b8c4-a05e14560607",
            price: 169.09,
            price_sources: [
              {
                close: 169.09,
                high: 169.09,
                lag_ms: 432,
                low: 169.09,
                open: 169.09,
                source: "Polygon_ws",
                start_ms: 1721754335999,
                timespan_ms: 0,
                volume: 1,
                vwap: 169.09,
                websocket: true,
              },
              {
                close: 169.078,
                high: 169.078,
                lag_ms: 433,
                low: 169.078,
                open: 169.078,
                source: "TwelveData_ws",
                start_ms: 1721754336000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1721754335567,
          },
          {
            leverage: 0.04999999999999996,
            order_type: "LONG",
            order_uuid: "ba921ef2-b57b-4729-a2f6-1860fe42e328",
            price: 167.6895,
            price_sources: [
              {
                close: 167.6895,
                high: 167.6895,
                lag_ms: 193,
                low: 167.6895,
                open: 167.6895,
                source: "Polygon_rest",
                start_ms: 1721797404000,
                timespan_ms: 1000,
                volume: 2,
                vwap: null,
                websocket: false,
              },
              {
                close: 167.695,
                high: 167.695,
                lag_ms: 806,
                low: 167.695,
                open: 167.695,
                source: "TwelveData_ws",
                start_ms: 1721797404000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
              {
                close: 167.7,
                high: 167.73,
                lag_ms: 84807,
                low: 167.67999,
                open: 167.69,
                source: "TwelveData_rest",
                start_ms: 1721797260000,
                timespan_ms: 60000,
                volume: null,
                vwap: null,
                websocket: false,
              },
              {
                close: 168.102,
                high: 168.102,
                lag_ms: 3730807,
                low: 168.102,
                open: 168.102,
                source: "Polygon_ws",
                start_ms: 1721793673999,
                timespan_ms: 0,
                volume: 1,
                vwap: 168.102,
                websocket: true,
              },
            ],
            processed_ms: 1721797404806,
          },
          {
            leverage: 0.04000000000000001,
            order_type: "LONG",
            order_uuid: "c3752d7d-8b7b-4ff4-a089-84009fdffccb",
            price: 167.734,
            price_sources: [
              {
                close: 167.734,
                high: 167.734,
                lag_ms: 327,
                low: 167.734,
                open: 167.734,
                source: "TwelveData_ws",
                start_ms: 1721797432000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
              {
                close: 167.726,
                high: 167.726,
                lag_ms: 327,
                low: 167.726,
                open: 167.726,
                source: "Polygon_rest",
                start_ms: 1721797432000,
                timespan_ms: 1000,
                volume: 1,
                vwap: null,
                websocket: false,
              },
              {
                close: 167.69,
                high: 167.72,
                lag_ms: 52328,
                low: 167.67999,
                open: 167.7,
                source: "TwelveData_rest",
                start_ms: 1721797320000,
                timespan_ms: 60000,
                volume: null,
                vwap: null,
                websocket: false,
              },
              {
                close: 168.102,
                high: 168.102,
                lag_ms: 3758328,
                low: 168.102,
                open: 168.102,
                source: "Polygon_ws",
                start_ms: 1721793673999,
                timespan_ms: 0,
                volume: 1,
                vwap: 168.102,
                websocket: true,
              },
            ],
            processed_ms: 1721797432327,
          },
          {
            leverage: 0.020000000000000018,
            order_type: "LONG",
            order_uuid: "172b5038-ec69-45c2-aba9-8e1c02db70fb",
            price: 166.933,
            price_sources: [
              {
                close: 166.933,
                high: 166.933,
                lag_ms: 337,
                low: 166.933,
                open: 166.933,
                source: "TwelveData_ws",
                start_ms: 1721828716000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
              {
                close: 166.92000000000002,
                high: 166.92000000000002,
                lag_ms: 338,
                low: 166.92000000000002,
                open: 166.92000000000002,
                source: "Polygon_rest",
                start_ms: 1721828715000,
                timespan_ms: 1000,
                volume: 6,
                vwap: null,
                websocket: false,
              },
              {
                close: 166.85001,
                high: 166.88,
                lag_ms: 16338,
                low: 166.82001,
                open: 166.88,
                source: "TwelveData_rest",
                start_ms: 1721828640000,
                timespan_ms: 60000,
                volume: null,
                vwap: null,
                websocket: false,
              },
              {
                close: 168.102,
                high: 168.102,
                lag_ms: 35042338,
                low: 168.102,
                open: 168.102,
                source: "Polygon_ws",
                start_ms: 1721793673999,
                timespan_ms: 0,
                volume: 1,
                vwap: 168.102,
                websocket: true,
              },
            ],
            processed_ms: 1721828716337,
          },
          {
            leverage: 0.019999999999999962,
            order_type: "LONG",
            order_uuid: "c372a0d0-6b18-4d51-9c3d-baf142f0d414",
            price: 166.522,
            price_sources: [
              {
                close: 166.522,
                high: 166.522,
                lag_ms: 151,
                low: 166.522,
                open: 166.522,
                source: "TwelveData_ws",
                start_ms: 1721829738000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
              {
                close: 166.521,
                high: 166.521,
                lag_ms: 152,
                low: 166.521,
                open: 166.521,
                source: "Polygon_rest",
                start_ms: 1721829737000,
                timespan_ms: 1000,
                volume: 7,
                vwap: null,
                websocket: false,
              },
              {
                close: 166.60001,
                high: 166.63049,
                lag_ms: 78152,
                low: 166.56,
                open: 166.56,
                source: "TwelveData_rest",
                start_ms: 1721829600000,
                timespan_ms: 60000,
                volume: null,
                vwap: null,
                websocket: false,
              },
              {
                close: 168.102,
                high: 168.102,
                lag_ms: 36064152,
                low: 168.102,
                open: 168.102,
                source: "Polygon_ws",
                start_ms: 1721793673999,
                timespan_ms: 0,
                volume: 1,
                vwap: 168.102,
                websocket: true,
              },
            ],
            processed_ms: 1721829738151,
          },
          {
            leverage: 0.020000000000000073,
            order_type: "LONG",
            order_uuid: "8928720f-d17c-46ac-b658-b8388f84655f",
            price: 166.367,
            price_sources: [
              {
                close: 166.367,
                high: 166.367,
                lag_ms: 404,
                low: 166.367,
                open: 166.367,
                source: "Polygon_rest",
                start_ms: 1721833221000,
                timespan_ms: 1000,
                volume: 1,
                vwap: null,
                websocket: false,
              },
              {
                close: 166.372,
                high: 166.372,
                lag_ms: 596,
                low: 166.372,
                open: 166.372,
                source: "TwelveData_ws",
                start_ms: 1721833222000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
              {
                close: 166.32001,
                high: 166.42,
                lag_ms: 441405,
                low: 166.32001,
                open: 166.39999,
                source: "TwelveData_rest",
                start_ms: 1721832720000,
                timespan_ms: 60000,
                volume: null,
                vwap: null,
                websocket: false,
              },
              {
                close: 168.102,
                high: 168.102,
                lag_ms: 39547405,
                low: 168.102,
                open: 168.102,
                source: "Polygon_ws",
                start_ms: 1721793673999,
                timespan_ms: 0,
                volume: 1,
                vwap: 168.102,
                websocket: true,
              },
            ],
            processed_ms: 1721833221404,
          },
          {
            leverage: 0.08999999999999997,
            order_type: "LONG",
            order_uuid: "bde2169f-cf2b-4d5c-a274-e0c50ccd29ed",
            price: 166.642,
            price_sources: [
              {
                close: 166.642,
                high: 166.642,
                lag_ms: 242,
                low: 166.642,
                open: 166.642,
                source: "TwelveData_ws",
                start_ms: 1721840717000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
              {
                close: 166.643,
                high: 166.643,
                lag_ms: 243,
                low: 166.643,
                open: 166.643,
                source: "Polygon_rest",
                start_ms: 1721840716000,
                timespan_ms: 1000,
                volume: 6,
                vwap: null,
                websocket: false,
              },
              {
                close: 166.64,
                high: 166.64,
                lag_ms: 77243,
                low: 166.60001,
                open: 166.61,
                source: "TwelveData_rest",
                start_ms: 1721840580000,
                timespan_ms: 60000,
                volume: null,
                vwap: null,
                websocket: false,
              },
              {
                close: 168.102,
                high: 168.102,
                lag_ms: 47043243,
                low: 168.102,
                open: 168.102,
                source: "Polygon_ws",
                start_ms: 1721793673999,
                timespan_ms: 0,
                volume: 1,
                vwap: 168.102,
                websocket: true,
              },
            ],
            processed_ms: 1721840717242,
          },
          {
            leverage: -0.015,
            order_type: "SHORT",
            order_uuid: "EURJPY-1721841306.miner_signal",
            price: 166.713,
            price_sources: [
              {
                close: 166.713,
                high: 166.713,
                lag_ms: 278,
                low: 166.713,
                open: 166.713,
                source: "Polygon_rest",
                start_ms: 1721842383000,
                timespan_ms: 1000,
                volume: 3,
                vwap: null,
                websocket: false,
              },
              {
                close: 166.718,
                high: 166.718,
                lag_ms: 721,
                low: 166.718,
                open: 166.718,
                source: "TwelveData_ws",
                start_ms: 1721842383000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
              {
                close: 166.74001,
                high: 166.77,
                lag_ms: 63722,
                low: 166.74001,
                open: 166.75999,
                source: "TwelveData_rest",
                start_ms: 1721842260000,
                timespan_ms: 60000,
                volume: null,
                vwap: null,
                websocket: false,
              },
              {
                close: 168.102,
                high: 168.102,
                lag_ms: 48709722,
                low: 168.102,
                open: 168.102,
                source: "Polygon_ws",
                start_ms: 1721793673999,
                timespan_ms: 0,
                volume: 1,
                vwap: 168.102,
                websocket: true,
              },
            ],
            processed_ms: 1721842383721,
          },
          {
            leverage: 0.009999999999999995,
            order_type: "LONG",
            order_uuid: "EURJPY-1721854810.miner_signal",
            price: 166.78,
            price_sources: [
              {
                close: 166.78,
                high: 166.78,
                lag_ms: 951,
                low: 166.78,
                open: 166.78,
                source: "Polygon_rest",
                start_ms: 1721854813000,
                timespan_ms: 1000,
                volume: 1,
                vwap: null,
                websocket: false,
              },
              {
                close: 166.727,
                high: 166.727,
                lag_ms: 1950,
                low: 166.727,
                open: 166.727,
                source: "TwelveData_ws",
                start_ms: 1721854813000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
              {
                close: 166.82001,
                high: 166.84,
                lag_ms: 74951,
                low: 166.82001,
                open: 166.8275,
                source: "TwelveData_rest",
                start_ms: 1721854680000,
                timespan_ms: 60000,
                volume: null,
                vwap: null,
                websocket: false,
              },
              {
                close: 168.102,
                high: 168.102,
                lag_ms: 61140951,
                low: 168.102,
                open: 168.102,
                source: "Polygon_ws",
                start_ms: 1721793673999,
                timespan_ms: 0,
                volume: 1,
                vwap: 168.102,
                websocket: true,
              },
            ],
            processed_ms: 1721854814950,
          },
          {
            leverage: 0.022499999999999964,
            order_type: "LONG",
            order_uuid: "EURJPY-1721883608.miner_signal",
            price: 165.433,
            price_sources: [
              {
                close: 165.433,
                high: 165.433,
                lag_ms: 116,
                low: 165.433,
                open: 165.433,
                source: "Polygon_ws",
                start_ms: 1721883623000,
                timespan_ms: 0,
                volume: 1,
                vwap: 165.44,
                websocket: true,
              },
              {
                close: 165.439,
                high: 165.439,
                lag_ms: 884,
                low: 165.439,
                open: 165.439,
                source: "TwelveData_ws",
                start_ms: 1721883624000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1721883623116,
          },
          {
            leverage: 0.0050000000000000044,
            order_type: "LONG",
            order_uuid: "EURJPY-1721893503.miner_signal",
            price: 164.94400000000002,
            price_sources: [
              {
                close: 164.94400000000002,
                high: 164.94400000000002,
                lag_ms: 127,
                low: 164.94400000000002,
                open: 164.94400000000002,
                source: "Polygon_ws",
                start_ms: 1721893508000,
                timespan_ms: 0,
                volume: 1,
                vwap: 164.949,
                websocket: true,
              },
              {
                close: 164.943,
                high: 164.943,
                lag_ms: 127,
                low: 164.943,
                open: 164.943,
                source: "TwelveData_ws",
                start_ms: 1721893508000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1721893508127,
          },
          {
            leverage: -0.0050000000000000044,
            order_type: "SHORT",
            order_uuid: "EURJPY-1721896204.miner_signal",
            price: 165.179,
            price_sources: [
              {
                close: 165.179,
                high: 165.179,
                lag_ms: 326,
                low: 165.179,
                open: 165.179,
                source: "Polygon_ws",
                start_ms: 1721896209000,
                timespan_ms: 0,
                volume: 1,
                vwap: 165.176,
                websocket: true,
              },
              {
                close: 165.177,
                high: 165.177,
                lag_ms: 326,
                low: 165.177,
                open: 165.177,
                source: "TwelveData_ws",
                start_ms: 1721896209000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1721896209326,
          },
          {
            leverage: -0.07499999999999998,
            order_type: "SHORT",
            order_uuid: "EURJPY-1721926809.miner_signal",
            price: 167.29399999999998,
            price_sources: [
              {
                close: 167.29399999999998,
                high: 167.29399999999998,
                lag_ms: 446,
                low: 167.29399999999998,
                open: 167.29399999999998,
                source: "Polygon_ws",
                start_ms: 1721926821999,
                timespan_ms: 0,
                volume: 1,
                vwap: 167.279,
                websocket: true,
              },
              {
                close: 167.309,
                high: 167.309,
                lag_ms: 447,
                low: 167.309,
                open: 167.309,
                source: "TwelveData_ws",
                start_ms: 1721926822000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1721926821553,
          },
          {
            leverage: 0.0050000000000000044,
            order_type: "LONG",
            order_uuid: "EURJPY-1721984408.miner_signal",
            price: 167.477,
            price_sources: [
              {
                close: 167.473,
                high: 167.473,
                lag_ms: 431,
                low: 167.473,
                open: 167.473,
                source: "Polygon_ws",
                start_ms: 1721984416999,
                timespan_ms: 0,
                volume: 1,
                vwap: 167.477,
                websocket: true,
              },
              {
                close: 167.474,
                high: 167.474,
                lag_ms: 4568,
                low: 167.474,
                open: 167.474,
                source: "TwelveData_ws",
                start_ms: 1721984412000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1721984416568,
          },
          {
            leverage: -0.0050000000000000044,
            order_type: "SHORT",
            order_uuid: "EURJPY-1721998810.miner_signal",
            price: 167.445,
            price_sources: [
              {
                close: 167.445,
                high: 167.445,
                lag_ms: 309,
                low: 167.445,
                open: 167.445,
                source: "Polygon_ws",
                start_ms: 1721998818000,
                timespan_ms: 0,
                volume: 1,
                vwap: 167.45,
                websocket: true,
              },
              {
                close: 167.424,
                high: 167.424,
                lag_ms: 691,
                low: 167.424,
                open: 167.424,
                source: "TwelveData_ws",
                start_ms: 1721998819000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1721998818309,
          },
        ],
        position_type: "LONG",
        position_uuid: "ddc768e0-c362-4905-90a6-077c4a2d1b1c",
        return_at_close: 0.9984265429864247,
        trade_pair: ["EURJPY", "EUR/JPY", 0.00007, 0.1, 5],
      },
      {
        average_entry_price: 174.73613326203858,
        close_ms: 0,
        current_return: 0.9921833686166646,
        is_closed_position: false,
        miner_hotkey: "5GhCxfBcA7Ur5iiAS343xwvrYHTUfBjBi4JimiL5LhujRT9t",
        net_leverage: 1.6849999999999998,
        open_ms: 1721696404752,
        orders: [
          {
            leverage: 0.17,
            order_type: "LONG",
            order_uuid: "d0a3bf69-ad27-4283-84d0-eb1c5af60cf9",
            price: 176.1605958093724,
            price_sources: [
              {
                close: 176.1605958093724,
                high: 176.1605958093724,
                lag_ms: 247,
                low: 176.1605958093724,
                open: 176.1605958093724,
                source: "Polygon_ws",
                start_ms: 1721696404999,
                timespan_ms: 0,
                volume: 1,
                vwap: 176.1438009703727,
                websocket: true,
              },
              {
                close: 176.157,
                high: 176.157,
                lag_ms: 248,
                low: 176.157,
                open: 176.157,
                source: "TwelveData_ws",
                start_ms: 1721696405000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1721696404752,
          },
          {
            leverage: 0.01999999999999999,
            order_type: "LONG",
            order_uuid: "bf681cfc-dcfe-4d33-b150-ce571752f4bb",
            price: 176.17011077615575,
            price_sources: [
              {
                close: 176.17011077615575,
                high: 176.17011077615575,
                lag_ms: 379,
                low: 176.17011077615575,
                open: 176.17011077615575,
                source: "Polygon_ws",
                start_ms: 1721696419000,
                timespan_ms: 0,
                volume: 1,
                vwap: 176.17,
                websocket: true,
              },
              {
                close: 176.171,
                high: 176.171,
                lag_ms: 621,
                low: 176.171,
                open: 176.171,
                source: "TwelveData_ws",
                start_ms: 1721696420000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1721696419379,
          },
          {
            leverage: 0.15000000000000008,
            order_type: "LONG",
            order_uuid: "028df94f-ad67-4085-80d4-9680158b51ea",
            price: 175.8095,
            price_sources: [
              {
                close: 175.8095,
                high: 175.8095,
                lag_ms: 282,
                low: 175.8095,
                open: 175.8095,
                source: "Polygon_ws",
                start_ms: 1721710855000,
                timespan_ms: 0,
                volume: 1,
                vwap: 175.81,
                websocket: true,
              },
              {
                close: 175.814,
                high: 175.814,
                lag_ms: 1282,
                low: 175.814,
                open: 175.814,
                source: "TwelveData_ws",
                start_ms: 1721710854000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1721710855282,
          },
          {
            leverage: 0.06,
            order_type: "LONG",
            order_uuid: "146da7e5-e107-4920-85dd-adbd0fda9b78",
            price: 175.81564050397606,
            price_sources: [
              {
                close: 175.81564050397606,
                high: 175.81564050397606,
                lag_ms: 271,
                low: 175.81564050397606,
                open: 175.81564050397606,
                source: "Polygon_ws",
                start_ms: 1721710912999,
                timespan_ms: 0,
                volume: 1,
                vwap: 175.818,
                websocket: true,
              },
              {
                close: 175.831,
                high: 175.831,
                lag_ms: 272,
                low: 175.831,
                open: 175.831,
                source: "TwelveData_ws",
                start_ms: 1721710913000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1721710912728,
          },
          {
            leverage: 0.07999999999999996,
            order_type: "LONG",
            order_uuid: "7dac1074-207e-4710-abeb-46aa748dc58f",
            price: 175.2659574468085,
            price_sources: [
              {
                close: 175.2659574468085,
                high: 175.2659574468085,
                lag_ms: 135,
                low: 175.2659574468085,
                open: 175.2659574468085,
                source: "Polygon_ws",
                start_ms: 1721725305999,
                timespan_ms: 0,
                volume: 1,
                vwap: 175.267,
                websocket: true,
              },
              {
                close: 175.271,
                high: 175.271,
                lag_ms: 136,
                low: 175.271,
                open: 175.271,
                source: "TwelveData_ws",
                start_ms: 1721725306000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1721725305864,
          },
          {
            leverage: 0.12999999999999995,
            order_type: "LONG",
            order_uuid: "3b2b1d63-4e0e-4739-ac81-5eb687c746e6",
            price: 175.2815,
            price_sources: [
              {
                close: 175.2815,
                high: 175.2815,
                lag_ms: 32,
                low: 175.2815,
                open: 175.2815,
                source: "Polygon_ws",
                start_ms: 1721725347999,
                timespan_ms: 0,
                volume: 1,
                vwap: 175.279,
                websocket: true,
              },
              {
                close: 175.297,
                high: 175.297,
                lag_ms: 33,
                low: 175.297,
                open: 175.297,
                source: "TwelveData_ws",
                start_ms: 1721725348000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1721725347967,
          },
          {
            leverage: 0.2100000000000002,
            order_type: "LONG",
            order_uuid: "fc4cbc39-2370-40d9-8e0a-f21171ace9af",
            price: 175.076,
            price_sources: [
              {
                close: 175.076,
                high: 175.076,
                lag_ms: 234,
                low: 175.076,
                open: 175.076,
                source: "Polygon_ws",
                start_ms: 1721739656999,
                timespan_ms: 0,
                volume: 1,
                vwap: 175.0718123579252,
                websocket: true,
              },
              {
                close: 175.072,
                high: 175.072,
                lag_ms: 235,
                low: 175.072,
                open: 175.072,
                source: "TwelveData_ws",
                start_ms: 1721739657000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1721739656765,
          },
          {
            leverage: 0.18999999999999984,
            order_type: "LONG",
            order_uuid: "7c42c934-f095-4006-9b6c-ff9cdf698c3b",
            price: 174.70600000000002,
            price_sources: [
              {
                close: 174.70600000000002,
                high: 174.70600000000002,
                lag_ms: 146,
                low: 174.70600000000002,
                open: 174.70600000000002,
                source: "Polygon_ws",
                start_ms: 1721754351999,
                timespan_ms: 0,
                volume: 1,
                vwap: 174.6951723567827,
                websocket: true,
              },
              {
                close: 174.712,
                high: 174.712,
                lag_ms: 147,
                low: 174.712,
                open: 174.712,
                source: "TwelveData_ws",
                start_ms: 1721754352000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1721754351853,
          },
          {
            leverage: 0.020000000000000018,
            order_type: "LONG",
            order_uuid: "da7ea924-58ba-4167-92cd-59a08c6c939b",
            price: 174.701092291953,
            price_sources: [
              {
                close: 174.701092291953,
                high: 174.701092291953,
                lag_ms: 333,
                low: 174.701092291953,
                open: 174.701092291953,
                source: "Polygon_ws",
                start_ms: 1721754363000,
                timespan_ms: 0,
                volume: 1,
                vwap: 174.697,
                websocket: true,
              },
              {
                close: 174.703,
                high: 174.703,
                lag_ms: 333,
                low: 174.703,
                open: 174.703,
                source: "TwelveData_ws",
                start_ms: 1721754363000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1721754363333,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "8c2c7cab-4fe8-46f7-973a-c411bdda5999",
            price: 174.52,
            price_sources: [
              {
                close: 174.52,
                high: 174.52,
                lag_ms: 444,
                low: 174.52,
                open: 174.52,
                source: "Polygon_ws",
                start_ms: 1721768416000,
                timespan_ms: 0,
                volume: 1,
                vwap: 174.52,
                websocket: true,
              },
              {
                close: 174.454,
                high: 174.454,
                lag_ms: 3445,
                low: 174.454,
                open: 174.454,
                source: "Polygon_rest",
                start_ms: 1721768412000,
                timespan_ms: 1000,
                volume: 1,
                vwap: null,
                websocket: false,
              },
              {
                close: 174.521,
                high: 174.521,
                lag_ms: 4556,
                low: 174.521,
                open: 174.521,
                source: "TwelveData_ws",
                start_ms: 1721768421000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
              {
                close: 174.56,
                high: 174.60001,
                lag_ms: 76445,
                low: 174.53999,
                open: 174.60001,
                source: "TwelveData_rest",
                start_ms: 1721768280000,
                timespan_ms: 60000,
                volume: null,
                vwap: null,
                websocket: false,
              },
            ],
            processed_ms: 1721768416444,
          },
          {
            leverage: 0.010000000000000009,
            order_type: "LONG",
            order_uuid: "177152d4-3d4b-44c7-84e7-0ba985f0b3fc",
            price: 174.062,
            price_sources: [
              {
                close: 174.062,
                high: 174.062,
                lag_ms: 217,
                low: 174.062,
                open: 174.062,
                source: "Polygon_ws",
                start_ms: 1721790051999,
                timespan_ms: 0,
                volume: 1,
                vwap: 174.062,
                websocket: true,
              },
              {
                close: 174.063,
                high: 174.063,
                lag_ms: 218,
                low: 174.063,
                open: 174.063,
                source: "TwelveData_ws",
                start_ms: 1721790052000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1721790051782,
          },
          {
            leverage: 0.20999999999999996,
            order_type: "LONG",
            order_uuid: "ab3c6563-9c88-4c77-987b-80a47d8760b6",
            price: 173.379,
            price_sources: [
              {
                close: 173.379,
                high: 173.379,
                lag_ms: 142,
                low: 173.379,
                open: 173.379,
                source: "Polygon_rest",
                start_ms: 1721803753000,
                timespan_ms: 1000,
                volume: 3,
                vwap: null,
                websocket: false,
              },
              {
                close: 173.378,
                high: 173.378,
                lag_ms: 857,
                low: 173.378,
                open: 173.378,
                source: "TwelveData_ws",
                start_ms: 1721803753000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
              {
                close: 173.44,
                high: 173.47,
                lag_ms: 133858,
                low: 173.42999,
                open: 173.42999,
                source: "TwelveData_rest",
                start_ms: 1721803560000,
                timespan_ms: 60000,
                volume: null,
                vwap: null,
                websocket: false,
              },
              {
                close: 173.8120838674993,
                high: 173.8120838674993,
                lag_ms: 10080858,
                low: 173.8120838674993,
                open: 173.8120838674993,
                source: "Polygon_ws",
                start_ms: 1721793672999,
                timespan_ms: 0,
                volume: 1,
                vwap: 173.787,
                websocket: true,
              },
            ],
            processed_ms: 1721803753857,
          },
          {
            leverage: 0.009999999999999787,
            order_type: "LONG",
            order_uuid: "4b34e1ab-c9b3-469e-a9fb-664fe70e37de",
            price: 173.495,
            price_sources: [
              {
                close: 173.495,
                high: 173.495,
                lag_ms: 433,
                low: 173.495,
                open: 173.495,
                source: "TwelveData_ws",
                start_ms: 1721804416000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
              {
                close: 173.45425574001246,
                high: 173.45425574001246,
                lag_ms: 433,
                low: 173.45425574001246,
                open: 173.45425574001246,
                source: "Polygon_rest",
                start_ms: 1721804416000,
                timespan_ms: 1000,
                volume: 2,
                vwap: null,
                websocket: false,
              },
              {
                close: 173.46001,
                high: 173.49001,
                lag_ms: 196434,
                low: 173.44,
                open: 173.44,
                source: "TwelveData_rest",
                start_ms: 1721804160000,
                timespan_ms: 60000,
                volume: null,
                vwap: null,
                websocket: false,
              },
              {
                close: 173.8120838674993,
                high: 173.8120838674993,
                lag_ms: 10743434,
                low: 173.8120838674993,
                open: 173.8120838674993,
                source: "Polygon_ws",
                start_ms: 1721793672999,
                timespan_ms: 0,
                volume: 1,
                vwap: 173.787,
                websocket: true,
              },
            ],
            processed_ms: 1721804416433,
          },
          {
            leverage: 0.019999999999999796,
            order_type: "LONG",
            order_uuid: "99017b9e-7975-4f11-91bb-f9802d1e8109",
            price: 174.136,
            price_sources: [
              {
                close: 174.136,
                high: 174.136,
                lag_ms: 206,
                low: 174.136,
                open: 174.136,
                source: "TwelveData_ws",
                start_ms: 1721811650000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
              {
                close: 174.1335,
                high: 174.1335,
                lag_ms: 207,
                low: 174.1335,
                open: 174.1335,
                source: "Polygon_rest",
                start_ms: 1721811649000,
                timespan_ms: 1000,
                volume: 4,
                vwap: null,
                websocket: false,
              },
              {
                close: 174.14999,
                high: 174.153,
                lag_ms: 110207,
                low: 174.14,
                open: 174.14,
                source: "TwelveData_rest",
                start_ms: 1721811480000,
                timespan_ms: 60000,
                volume: null,
                vwap: null,
                websocket: false,
              },
              {
                close: 173.8120838674993,
                high: 173.8120838674993,
                lag_ms: 17977207,
                low: 173.8120838674993,
                open: 173.8120838674993,
                source: "Polygon_ws",
                start_ms: 1721793672999,
                timespan_ms: 0,
                volume: 1,
                vwap: 173.787,
                websocket: true,
              },
            ],
            processed_ms: 1721811650206,
          },
          {
            leverage: 0.04000000000000026,
            order_type: "LONG",
            order_uuid: "1aa70169-7797-493a-bf97-bf3741248779",
            price: 173.637,
            price_sources: [
              {
                close: 173.637,
                high: 173.637,
                lag_ms: 221,
                low: 173.637,
                open: 173.637,
                source: "TwelveData_ws",
                start_ms: 1721840663000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
              {
                close: 173.8120838674993,
                high: 173.8120838674993,
                lag_ms: 46989780,
                low: 173.8120838674993,
                open: 173.8120838674993,
                source: "Polygon_ws",
                start_ms: 1721793672999,
                timespan_ms: 0,
                volume: 1,
                vwap: 173.787,
                websocket: true,
              },
            ],
            processed_ms: 1721840662779,
          },
          {
            leverage: 0.009999999999999898,
            order_type: "LONG",
            order_uuid: "CHFJPY-1721855134.miner_signal",
            price: 173.8325174265718,
            price_sources: [
              {
                close: 173.8325174265718,
                high: 173.8325174265718,
                lag_ms: 400,
                low: 173.8325174265718,
                open: 173.8325174265718,
                source: "Polygon_rest",
                start_ms: 1721855136000,
                timespan_ms: 1000,
                volume: 1,
                vwap: null,
                websocket: false,
              },
              {
                close: 173.852,
                high: 173.852,
                lag_ms: 57601,
                low: 173.852,
                open: 173.852,
                source: "TwelveData_ws",
                start_ms: 1721855195000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
              {
                close: 173.86,
                high: 173.92149,
                lag_ms: 397400,
                low: 173.84,
                open: 173.84,
                source: "TwelveData_rest",
                start_ms: 1721854680000,
                timespan_ms: 60000,
                volume: null,
                vwap: null,
                websocket: false,
              },
              {
                close: 173.8120838674993,
                high: 173.8120838674993,
                lag_ms: 61464400,
                low: 173.8120838674993,
                open: 173.8120838674993,
                source: "Polygon_ws",
                start_ms: 1721793672999,
                timespan_ms: 0,
                volume: 1,
                vwap: 173.787,
                websocket: true,
              },
            ],
            processed_ms: 1721855137399,
          },
          {
            leverage: 0.07250000000000018,
            order_type: "LONG",
            order_uuid: "CHFJPY-1721869211.miner_signal",
            price: 173.309,
            price_sources: [
              {
                close: 173.309,
                high: 173.309,
                lag_ms: 142,
                low: 173.309,
                open: 173.309,
                source: "TwelveData_ws",
                start_ms: 1721869227000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
              {
                close: 173.31108018607176,
                high: 173.31108018607176,
                lag_ms: 142,
                low: 173.31108018607176,
                open: 173.31108018607176,
                source: "Polygon_rest",
                start_ms: 1721869227000,
                timespan_ms: 1000,
                volume: 2,
                vwap: null,
                websocket: false,
              },
              {
                close: 173.59,
                high: 173.60001,
                lag_ms: 10167143,
                low: 173.55,
                open: 173.55,
                source: "TwelveData_rest",
                start_ms: 1721859000000,
                timespan_ms: 60000,
                volume: null,
                vwap: null,
                websocket: false,
              },
              {
                close: 173.8120838674993,
                high: 173.8120838674993,
                lag_ms: 75554143,
                low: 173.8120838674993,
                open: 173.8120838674993,
                source: "Polygon_ws",
                start_ms: 1721793672999,
                timespan_ms: 0,
                volume: 1,
                vwap: 173.787,
                websocket: true,
              },
            ],
            processed_ms: 1721869227142,
          },
          {
            leverage: 0.07250000000000001,
            order_type: "LONG",
            order_uuid: "CHFJPY-1721883608.miner_signal",
            price: 172.8288009695045,
            price_sources: [
              {
                close: 172.8288009695045,
                high: 172.8288009695045,
                lag_ms: 153,
                low: 172.8288009695045,
                open: 172.8288009695045,
                source: "Polygon_ws",
                start_ms: 1721883622999,
                timespan_ms: 0,
                volume: 1,
                vwap: 172.85,
                websocket: true,
              },
              {
                close: 172.85,
                high: 172.85,
                lag_ms: 154,
                low: 172.85,
                open: 172.85,
                source: "TwelveData_ws",
                start_ms: 1721883623000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1721883622846,
          },
          {
            leverage: -0.04000000000000009,
            order_type: "SHORT",
            order_uuid: "CHFJPY-1721926809.miner_signal",
            price: 174.9837893239625,
            price_sources: [
              {
                close: 174.9837893239625,
                high: 174.9837893239625,
                lag_ms: 299,
                low: 174.9837893239625,
                open: 174.9837893239625,
                source: "Polygon_ws",
                start_ms: 1721926820000,
                timespan_ms: 0,
                volume: 1,
                vwap: 174.9837893239625,
                websocket: true,
              },
              {
                close: 174.981,
                high: 174.981,
                lag_ms: 299,
                low: 174.981,
                open: 174.981,
                source: "TwelveData_ws",
                start_ms: 1721926820000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1721926820299,
          },
        ],
        position_type: "LONG",
        position_uuid: "d0a3bf69-ad27-4283-84d0-eb1c5af60cf9",
        return_at_close: 0.9907813312376567,
        trade_pair: ["CHFJPY", "CHF/JPY", 0.00007, 0.1, 5],
      },
      {
        average_entry_price: 0.9262382826621927,
        close_ms: 0,
        current_return: 0.9988188630227826,
        is_closed_position: false,
        miner_hotkey: "5GhCxfBcA7Ur5iiAS343xwvrYHTUfBjBi4JimiL5LhujRT9t",
        net_leverage: 0.05250000000000002,
        open_ms: 1721696454426,
        orders: [
          {
            leverage: 0.04000000000000001,
            order_type: "LONG",
            order_uuid: "c1ba5c06-0a38-414f-a065-e7c8c476de49",
            price: 0.9133443966054915,
            price_sources: [
              {
                close: 0.9133443966054915,
                high: 0.9133443966054915,
                lag_ms: 426,
                low: 0.9133443966054915,
                open: 0.9133443966054915,
                source: "Polygon_ws",
                start_ms: 1721696454000,
                timespan_ms: 0,
                volume: 1,
                vwap: 0.913418793210983,
                websocket: true,
              },
              {
                close: 0.91326,
                high: 0.91326,
                lag_ms: 426,
                low: 0.91326,
                open: 0.91326,
                source: "TwelveData_ws",
                start_ms: 1721696454000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1721696454426,
          },
          {
            leverage: 0.04000000000000001,
            order_type: "LONG",
            order_uuid: "2c17dc84-bc91-4b3c-9520-caa2b3c140e8",
            price: 0.91166,
            price_sources: [
              {
                close: 0.91166,
                high: 0.91166,
                lag_ms: 441,
                low: 0.91166,
                open: 0.91166,
                source: "Polygon_ws",
                start_ms: 1721725353999,
                timespan_ms: 0,
                volume: 1,
                vwap: 0.91166,
                websocket: true,
              },
              {
                close: 0.91162,
                high: 0.91162,
                lag_ms: 442,
                low: 0.91162,
                open: 0.91162,
                source: "TwelveData_ws",
                start_ms: 1721725354000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1721725353558,
          },
          {
            leverage: 0.08000000000000002,
            order_type: "LONG",
            order_uuid: "20b1ea53-20fa-4173-9035-da921733a9e7",
            price: 0.9108709098728349,
            price_sources: [
              {
                close: 0.9108709098728349,
                high: 0.9108709098728349,
                lag_ms: 52,
                low: 0.9108709098728349,
                open: 0.9108709098728349,
                source: "Polygon_ws",
                start_ms: 1721743224000,
                timespan_ms: 0,
                volume: 1,
                vwap: 0.91088181974567,
                websocket: true,
              },
              {
                close: 0.91082,
                high: 0.91082,
                lag_ms: 52,
                low: 0.91082,
                open: 0.91082,
                source: "TwelveData_ws",
                start_ms: 1721743224000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1721743224052,
          },
          {
            leverage: 0.04999999999999999,
            order_type: "LONG",
            order_uuid: "3929dd76-1c14-4ecc-9928-df35859d7e4f",
            price: 0.91096,
            price_sources: [
              {
                close: 0.91096,
                high: 0.91096,
                lag_ms: 175,
                low: 0.91096,
                open: 0.91096,
                source: "Polygon_ws",
                start_ms: 1721754262999,
                timespan_ms: 0,
                volume: 1,
                vwap: 0.91096,
                websocket: true,
              },
              {
                close: 0.91093,
                high: 0.91093,
                lag_ms: 176,
                low: 0.91093,
                open: 0.91093,
                source: "TwelveData_ws",
                start_ms: 1721754263000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1721754262824,
          },
          {
            leverage: -0.08000000000000002,
            order_type: "SHORT",
            order_uuid: "843011d2-49bd-4f77-82ca-b5a0876ba55d",
            price: 0.9111,
            price_sources: [
              {
                close: 0.9111,
                high: 0.9111,
                lag_ms: 70,
                low: 0.9111,
                open: 0.9111,
                source: "Polygon_ws",
                start_ms: 1721764814000,
                timespan_ms: 0,
                volume: 1,
                vwap: 0.9109,
                websocket: true,
              },
              {
                close: 0.91129,
                high: 0.91129,
                lag_ms: 70,
                low: 0.91129,
                open: 0.91129,
                source: "TwelveData_ws",
                start_ms: 1721764814000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1721764814070,
          },
          {
            leverage: 0.09000000000000002,
            order_type: "LONG",
            order_uuid: "64f6976a-137a-4f4f-9144-5f57138a97e4",
            price: 0.911510334704241,
            price_sources: [
              {
                close: 0.911510334704241,
                high: 0.911510334704241,
                lag_ms: 8,
                low: 0.911510334704241,
                open: 0.911510334704241,
                source: "Polygon_ws",
                start_ms: 1721768709999,
                timespan_ms: 0,
                volume: 1,
                vwap: 0.911510334704241,
                websocket: true,
              },
              {
                close: 0.91134,
                high: 0.91134,
                lag_ms: 9,
                low: 0.91134,
                open: 0.91134,
                source: "TwelveData_ws",
                start_ms: 1721768710000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1721768709991,
          },
          {
            leverage: 0.08000000000000004,
            order_type: "LONG",
            order_uuid: "2dd12252-2c97-4616-9512-8445795ffac1",
            price: 0.910284492768922,
            price_sources: [
              {
                close: 0.910284492768922,
                high: 0.910284492768922,
                lag_ms: 221,
                low: 0.910284492768922,
                open: 0.910284492768922,
                source: "Polygon_rest",
                start_ms: 1721804723000,
                timespan_ms: 1000,
                volume: 1,
                vwap: null,
                websocket: false,
              },
              {
                close: 0.91024,
                high: 0.91024,
                lag_ms: 779,
                low: 0.91024,
                open: 0.91024,
                source: "TwelveData_ws",
                start_ms: 1721804724000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
              {
                close: 0.91,
                high: 0.9101,
                lag_ms: 623222,
                low: 0.9099,
                open: 0.9099,
                source: "TwelveData_rest",
                start_ms: 1721804040000,
                timespan_ms: 60000,
                volume: null,
                vwap: null,
                websocket: false,
              },
              {
                close: 0.90972,
                high: 0.90972,
                lag_ms: 11050222,
                low: 0.90972,
                open: 0.90972,
                source: "Polygon_ws",
                start_ms: 1721793672999,
                timespan_ms: 0,
                volume: 1,
                vwap: 0.90972,
                websocket: true,
              },
            ],
            processed_ms: 1721804723221,
          },
          {
            leverage: 0.08999999999999997,
            order_type: "LONG",
            order_uuid: "57d9a80e-63a2-41db-89a5-dc20e87be873",
            price: 0.90971773395766,
            price_sources: [
              {
                close: 0.90971773395766,
                high: 0.90971773395766,
                lag_ms: 428,
                low: 0.90971773395766,
                open: 0.90971773395766,
                source: "Polygon_rest",
                start_ms: 1721811620000,
                timespan_ms: 1000,
                volume: 1,
                vwap: null,
                websocket: false,
              },
              {
                close: 0.90968,
                high: 0.90968,
                lag_ms: 2428,
                low: 0.90968,
                open: 0.90968,
                source: "TwelveData_ws",
                start_ms: 1721811618000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
              {
                close: 0.90999,
                high: 0.90999,
                lag_ms: 20428,
                low: 0.90982,
                open: 0.90982,
                source: "TwelveData_rest",
                start_ms: 1721811600000,
                timespan_ms: 60000,
                volume: null,
                vwap: null,
                websocket: false,
              },
              {
                close: 0.90972,
                high: 0.90972,
                lag_ms: 17947429,
                low: 0.90972,
                open: 0.90972,
                source: "Polygon_ws",
                start_ms: 1721793672999,
                timespan_ms: 0,
                volume: 1,
                vwap: 0.90972,
                websocket: true,
              },
            ],
            processed_ms: 1721811620428,
          },
          {
            leverage: -0.26,
            order_type: "SHORT",
            order_uuid: "AUDCAD-1721855000.miner_signal",
            price: 0.90784,
            price_sources: [
              {
                close: 0.90784,
                high: 0.90784,
                lag_ms: 879,
                low: 0.90784,
                open: 0.90784,
                source: "TwelveData_ws",
                start_ms: 1721855004000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
              {
                close: 0.90858552631579,
                high: 0.90858552631579,
                lag_ms: 2122,
                low: 0.90858552631579,
                open: 0.90858552631579,
                source: "Polygon_rest",
                start_ms: 1721855000000,
                timespan_ms: 1000,
                volume: 1,
                vwap: null,
                websocket: false,
              },
              {
                close: 0.90885,
                high: 0.90902,
                lag_ms: 143122,
                low: 0.90885,
                open: 0.90902,
                source: "TwelveData_rest",
                start_ms: 1721854800000,
                timespan_ms: 60000,
                volume: null,
                vwap: null,
                websocket: false,
              },
              {
                close: 0.90972,
                high: 0.90972,
                lag_ms: 61330122,
                low: 0.90972,
                open: 0.90972,
                source: "Polygon_ws",
                start_ms: 1721793672999,
                timespan_ms: 0,
                volume: 1,
                vwap: 0.90972,
                websocket: true,
              },
            ],
            processed_ms: 1721855003121,
          },
          {
            leverage: 0.044999999999999984,
            order_type: "LONG",
            order_uuid: "AUDCAD-1721869200.miner_signal",
            price: 0.90654,
            price_sources: [
              {
                close: 0.90654,
                high: 0.90654,
                lag_ms: 577,
                low: 0.90654,
                open: 0.90654,
                source: "TwelveData_ws",
                start_ms: 1721869205000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
              {
                close: 0.90972,
                high: 0.90972,
                lag_ms: 75532578,
                low: 0.90972,
                open: 0.90972,
                source: "Polygon_ws",
                start_ms: 1721793672999,
                timespan_ms: 0,
                volume: 1,
                vwap: 0.90972,
                websocket: true,
              },
            ],
            processed_ms: 1721869205577,
          },
          {
            leverage: 0.010000000000000037,
            order_type: "LONG",
            order_uuid: "AUDCAD-1721883608.miner_signal",
            price: 0.9040513400888379,
            price_sources: [
              {
                close: 0.9040513400888379,
                high: 0.9040513400888379,
                lag_ms: 187,
                low: 0.9040513400888379,
                open: 0.9040513400888379,
                source: "Polygon_ws",
                start_ms: 1721883623000,
                timespan_ms: 0,
                volume: 1,
                vwap: 0.904041527380727,
                websocket: true,
              },
              {
                close: 0.90397,
                high: 0.90397,
                lag_ms: 813,
                low: 0.90397,
                open: 0.90397,
                source: "TwelveData_ws",
                start_ms: 1721883624000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1721883623187,
          },
          {
            leverage: 0.0325,
            order_type: "LONG",
            order_uuid: "AUDCAD-1721898013.miner_signal",
            price: 0.903665167834362,
            price_sources: [
              {
                close: 0.903665167834362,
                high: 0.903665167834362,
                lag_ms: 184,
                low: 0.903665167834362,
                open: 0.903665167834362,
                source: "Polygon_ws",
                start_ms: 1721898021000,
                timespan_ms: 0,
                volume: 1,
                vwap: 0.903665167834362,
                websocket: true,
              },
              {
                close: 0.90358,
                high: 0.90358,
                lag_ms: 184,
                low: 0.90358,
                open: 0.90358,
                source: "TwelveData_ws",
                start_ms: 1721898021000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1721898021184,
          },
          {
            leverage: 0.044999999999999984,
            order_type: "LONG",
            order_uuid: "AUDCAD-1721941523.miner_signal",
            price: 0.9035,
            price_sources: [
              {
                close: 0.9035,
                high: 0.9035,
                lag_ms: 9,
                low: 0.9035,
                open: 0.9035,
                source: "Polygon_ws",
                start_ms: 1721941524999,
                timespan_ms: 0,
                volume: 1,
                vwap: 0.9035,
                websocket: true,
              },
              {
                close: 0.9032,
                high: 0.9032,
                lag_ms: 990,
                low: 0.9032,
                open: 0.9032,
                source: "TwelveData_ws",
                start_ms: 1721941524000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1721941524990,
          },
          {
            leverage: -0.06,
            order_type: "SHORT",
            order_uuid: "AUDCAD-1721984408.miner_signal",
            price: 0.90653,
            price_sources: [
              {
                close: 0.90653,
                high: 0.90653,
                lag_ms: 382,
                low: 0.90653,
                open: 0.90653,
                source: "Polygon_ws",
                start_ms: 1721984451000,
                timespan_ms: 0,
                volume: 1,
                vwap: 0.90653,
                websocket: true,
              },
              {
                close: 0.90648,
                high: 0.90648,
                lag_ms: 13618,
                low: 0.90648,
                open: 0.90648,
                source: "TwelveData_ws",
                start_ms: 1721984465000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1721984451382,
          },
          {
            leverage: -0.07500000000000001,
            order_type: "SHORT",
            order_uuid: "AUDCAD-1721998810.miner_signal",
            price: 0.906979743611618,
            price_sources: [
              {
                close: 0.906979743611618,
                high: 0.906979743611618,
                lag_ms: 252,
                low: 0.906979743611618,
                open: 0.906979743611618,
                source: "Polygon_ws",
                start_ms: 1721998818000,
                timespan_ms: 0,
                volume: 1,
                vwap: 0.906989487223236,
                websocket: true,
              },
              {
                close: 0.90697,
                high: 0.90697,
                lag_ms: 748,
                low: 0.90697,
                open: 0.90697,
                source: "TwelveData_ws",
                start_ms: 1721998819000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1721998818252,
          },
          {
            leverage: -0.07499999999999998,
            order_type: "SHORT",
            order_uuid: "AUDCAD-1722013209.miner_signal",
            price: 0.9072450000000001,
            price_sources: [
              {
                close: 0.9072450000000001,
                high: 0.9072450000000001,
                lag_ms: 224,
                low: 0.9072450000000001,
                open: 0.9072450000000001,
                source: "Polygon_ws",
                start_ms: 1722013214000,
                timespan_ms: 0,
                volume: 1,
                vwap: 0.90725,
                websocket: true,
              },
              {
                close: 0.90715,
                high: 0.90715,
                lag_ms: 224,
                low: 0.90715,
                open: 0.90715,
                source: "TwelveData_ws",
                start_ms: 1722013214000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1722013214224,
          },
        ],
        position_type: "LONG",
        position_uuid: "c1ba5c06-0a38-414f-a065-e7c8c476de49",
        return_at_close: 0.9984948136946913,
        trade_pair: ["AUDCAD", "AUD/CAD", 0.00007, 0.1, 5],
      },
      {
        average_entry_price: 0.6421824999999999,
        close_ms: 0,
        current_return: 0.9996987975174554,
        is_closed_position: false,
        miner_hotkey: "5GhCxfBcA7Ur5iiAS343xwvrYHTUfBjBi4JimiL5LhujRT9t",
        net_leverage: 0.05000000000000001,
        open_ms: 1721811650212,
        orders: [
          {
            leverage: 0.020000000000000004,
            order_type: "LONG",
            order_uuid: "5b85daf4-b112-4410-9dd5-b875f7a03681",
            price: 0.6445,
            price_sources: [
              {
                close: 0.6445,
                high: 0.6445,
                lag_ms: 212,
                low: 0.6445,
                open: 0.6445,
                source: "TwelveData_ws",
                start_ms: 1721811650000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
              {
                close: 0.64452,
                high: 0.64452,
                lag_ms: 213,
                low: 0.64452,
                open: 0.64452,
                source: "Polygon_rest",
                start_ms: 1721811649000,
                timespan_ms: 1000,
                volume: 3,
                vwap: null,
                websocket: false,
              },
              {
                close: 0.6446,
                high: 0.6447,
                lag_ms: 110213,
                low: 0.6446,
                open: 0.6447,
                source: "TwelveData_rest",
                start_ms: 1721811480000,
                timespan_ms: 60000,
                volume: null,
                vwap: null,
                websocket: false,
              },
              {
                close: 0.646255,
                high: 0.646255,
                lag_ms: 17979213,
                low: 0.646255,
                open: 0.646255,
                source: "Polygon_ws",
                start_ms: 1721793670999,
                timespan_ms: 0,
                volume: 1,
                vwap: 0.64624,
                websocket: true,
              },
            ],
            processed_ms: 1721811650212,
          },
          {
            leverage: 0.020000000000000004,
            order_type: "LONG",
            order_uuid: "46d51ad6-b397-4fb4-9022-1818ac080b33",
            price: 0.64203,
            price_sources: [
              {
                close: 0.64203,
                high: 0.64203,
                lag_ms: 425,
                low: 0.64203,
                open: 0.64203,
                source: "TwelveData_ws",
                start_ms: 1721826151000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
              {
                close: 0.64201,
                high: 0.64201,
                lag_ms: 576,
                low: 0.64201,
                open: 0.64201,
                source: "Polygon_rest",
                start_ms: 1721826149000,
                timespan_ms: 1000,
                volume: 5,
                vwap: null,
                websocket: false,
              },
              {
                close: 0.6422,
                high: 0.6424,
                lag_ms: 810576,
                low: 0.6422,
                open: 0.6424,
                source: "TwelveData_rest",
                start_ms: 1721825280000,
                timespan_ms: 60000,
                volume: null,
                vwap: null,
                websocket: false,
              },
              {
                close: 0.646255,
                high: 0.646255,
                lag_ms: 32479576,
                low: 0.646255,
                open: 0.646255,
                source: "Polygon_ws",
                start_ms: 1721793670999,
                timespan_ms: 0,
                volume: 1,
                vwap: 0.64624,
                websocket: true,
              },
            ],
            processed_ms: 1721826150575,
          },
          {
            leverage: 0.0050000000000000044,
            order_type: "LONG",
            order_uuid: "CADCHF-1721883608.miner_signal",
            price: 0.63879,
            price_sources: [
              {
                close: 0.63879,
                high: 0.63879,
                lag_ms: 190,
                low: 0.63879,
                open: 0.63879,
                source: "Polygon_ws",
                start_ms: 1721883625999,
                timespan_ms: 0,
                volume: 1,
                vwap: 0.63879,
                websocket: true,
              },
              {
                close: 0.6388,
                high: 0.6388,
                lag_ms: 1191,
                low: 0.6388,
                open: 0.6388,
                source: "TwelveData_ws",
                start_ms: 1721883627000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1721883625809,
          },
          {
            leverage: 0.0049999999999999975,
            order_type: "LONG",
            order_uuid: "CADCHF-1721898024.miner_signal",
            price: 0.636915,
            price_sources: [
              {
                close: 0.636915,
                high: 0.636915,
                lag_ms: 152,
                low: 0.636915,
                open: 0.636915,
                source: "Polygon_ws",
                start_ms: 1721898028000,
                timespan_ms: 0,
                volume: 1,
                vwap: 0.63691,
                websocket: true,
              },
              {
                close: 0.63693,
                high: 0.63693,
                lag_ms: 152,
                low: 0.63693,
                open: 0.63693,
                source: "TwelveData_ws",
                start_ms: 1721898028000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1721898028152,
          },
        ],
        position_type: "LONG",
        position_uuid: "5b85daf4-b112-4410-9dd5-b875f7a03681",
        return_at_close: 0.9996807803794878,
        trade_pair: ["CADCHF", "CAD/CHF", 0.00007, 0.1, 5],
      },
      {
        average_entry_price: 1.4822439344262295,
        close_ms: 0,
        current_return: 0.9919854947192134,
        is_closed_position: false,
        miner_hotkey: "5GhCxfBcA7Ur5iiAS343xwvrYHTUfBjBi4JimiL5LhujRT9t",
        net_leverage: -0.6100000000000001,
        open_ms: 1720702822941,
        orders: [
          {
            leverage: -0.04000000000000001,
            order_type: "SHORT",
            order_uuid: "c1d67bd0-7796-4aea-9f6b-059a0ffd6adb",
            price: 1.4808400000000002,
            price_sources: [],
            processed_ms: 1720702822941,
          },
          {
            leverage: -0.04000000000000001,
            order_type: "SHORT",
            order_uuid: "e4d679b7-05b0-4653-b8f2-cbc0ee19bd90",
            price: 1.4807649999999999,
            price_sources: [],
            processed_ms: 1720702868708,
          },
          {
            leverage: -0.16000000000000003,
            order_type: "SHORT",
            order_uuid: "85244e36-9c40-4c40-9f3a-8915654619ac",
            price: 1.4848,
            price_sources: [],
            processed_ms: 1720991052316,
          },
          {
            leverage: -0.07999999999999996,
            order_type: "SHORT",
            order_uuid: "f8177765-6f56-4475-86d4-dd74a32d4c48",
            price: 1.48751,
            price_sources: [],
            processed_ms: 1721019611339,
          },
          {
            leverage: -0.08000000000000002,
            order_type: "SHORT",
            order_uuid: "af8f578d-1db5-4344-91ec-b9f990669bab",
            price: 1.488515,
            price_sources: [],
            processed_ms: 1721048469821,
          },
          {
            leverage: -0.12,
            order_type: "SHORT",
            order_uuid: "ee59bb15-a016-4f6f-bf00-750e89b0373b",
            price: 1.49043,
            price_sources: [],
            processed_ms: 1721062869950,
          },
          {
            leverage: -0.040000000000000036,
            order_type: "SHORT",
            order_uuid: "48c462f3-bc06-4a50-b9fa-2f4ef3d25276",
            price: 1.4903,
            price_sources: [],
            processed_ms: 1721062927015,
          },
          {
            leverage: -0.08000000000000007,
            order_type: "SHORT",
            order_uuid: "46f1d0af-316f-4a0e-8c9e-bfb9b7c48ede",
            price: 1.48982,
            price_sources: [],
            processed_ms: 1721077537375,
          },
          {
            leverage: -0.07999999999999985,
            order_type: "SHORT",
            order_uuid: "5e196151-9ee3-4dcb-ba74-86c0b699cd87",
            price: 1.49047,
            price_sources: [],
            processed_ms: 1721106062107,
          },
          {
            leverage: -0.16000000000000014,
            order_type: "SHORT",
            order_uuid: "da8d0774-7113-4110-b9d0-2b000dfa7a33",
            price: 1.4916049999999998,
            price_sources: [],
            processed_ms: 1721120409194,
          },
          {
            leverage: -0.04000000000000015,
            order_type: "SHORT",
            order_uuid: "63211c97-4558-40d6-883d-bc32519c6e44",
            price: 1.49164,
            price_sources: [],
            processed_ms: 1721120447253,
          },
          {
            leverage: -0.039999999999999925,
            order_type: "SHORT",
            order_uuid: "9a7b23ef-8e7a-4f0d-b724-0e175d143518",
            price: 1.49057,
            price_sources: [],
            processed_ms: 1721178016785,
          },
          {
            leverage: -0.16000000000000014,
            order_type: "SHORT",
            order_uuid: "9b8c21f4-75b7-43c8-9cf1-e7f78e351e30",
            price: 1.495055,
            price_sources: [],
            processed_ms: 1721206957468,
          },
          {
            leverage: -0.07999999999999985,
            order_type: "SHORT",
            order_uuid: "27d8bf3b-b9ae-4e33-ad37-6be9283145a2",
            price: 1.49499,
            price_sources: [],
            processed_ms: 1721206975461,
          },
          {
            leverage: -0.19999999999999996,
            order_type: "SHORT",
            order_uuid: "1f96af9f-fa19-4d54-9dce-f0487e5f0978",
            price: 1.49603,
            price_sources: [],
            processed_ms: 1721221267856,
          },
          {
            leverage: -0.039999999999999813,
            order_type: "SHORT",
            order_uuid: "e8c055f6-c525-498e-b34e-b12a3ba0a7bc",
            price: 1.4965950000000001,
            price_sources: [],
            processed_ms: 1721235689318,
          },
          {
            leverage: -0.08000000000000007,
            order_type: "SHORT",
            order_uuid: "a69bcc39-b9e7-4c05-974d-f5536955e510",
            price: 1.49679,
            price_sources: [],
            processed_ms: 1721235716842,
          },
          {
            leverage: -0.0800000000000003,
            order_type: "SHORT",
            order_uuid: "493f25dc-ace9-4341-8b81-123fe6ef062d",
            price: 1.49676,
            price_sources: [],
            processed_ms: 1721235727182,
          },
          {
            leverage: -0.039999999999999813,
            order_type: "SHORT",
            order_uuid: "87750d25-1472-4a14-a154-2502f03bce81",
            price: 1.49647,
            price_sources: [],
            processed_ms: 1721250209647,
          },
          {
            leverage: -0.04000000000000026,
            order_type: "SHORT",
            order_uuid: "49751b50-8a46-4565-a9fc-8882bc3da74d",
            price: 1.49656,
            price_sources: [],
            processed_ms: 1721264480752,
          },
          {
            leverage: 0.40000000000000013,
            order_type: "LONG",
            order_uuid: "b0cc458c-7af1-4189-ac0c-d6350b70b30d",
            price: 1.4929,
            price_sources: [],
            processed_ms: 1721336746034,
          },
          {
            leverage: -0.040000000000000036,
            order_type: "SHORT",
            order_uuid: "67f5d4fc-458f-4c00-b387-d33d80d2a07c",
            price: 1.49655,
            price_sources: [
              {
                close: 1.49655,
                high: 1.49655,
                lag_ms: 63,
                low: 1.49655,
                open: 1.49655,
                source: "Polygon_ws",
                start_ms: 1721667613000,
                timespan_ms: 0,
                volume: 1,
                vwap: 1.49656,
                websocket: true,
              },
              {
                close: 1.49654,
                high: 1.49654,
                lag_ms: 63,
                low: 1.49654,
                open: 1.49654,
                source: "TwelveData_ws",
                start_ms: 1721667613000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1721667613063,
          },
          {
            leverage: -0.07999999999999985,
            order_type: "SHORT",
            order_uuid: "e49997c9-20c2-4d8a-ac82-629d38c07d53",
            price: 1.496575,
            price_sources: [
              {
                close: 1.496575,
                high: 1.496575,
                lag_ms: 243,
                low: 1.496575,
                open: 1.496575,
                source: "Polygon_ws",
                start_ms: 1721667651000,
                timespan_ms: 0,
                volume: 1,
                vwap: 1.49658,
                websocket: true,
              },
              {
                close: 1.49656,
                high: 1.49656,
                lag_ms: 243,
                low: 1.49656,
                open: 1.49656,
                source: "TwelveData_ws",
                start_ms: 1721667651000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1721667651243,
          },
          {
            leverage: -0.15999999999999992,
            order_type: "SHORT",
            order_uuid: "32060dea-a993-43d6-9b23-f395fe1e025e",
            price: 1.4974,
            price_sources: [
              {
                close: 1.4974,
                high: 1.4974,
                lag_ms: 3,
                low: 1.4974,
                open: 1.4974,
                source: "Polygon_ws",
                start_ms: 1721682264000,
                timespan_ms: 0,
                volume: 1,
                vwap: 1.4974,
                websocket: true,
              },
              {
                close: 1.4973,
                high: 1.4973,
                lag_ms: 1003,
                low: 1.4973,
                open: 1.4973,
                source: "TwelveData_ws",
                start_ms: 1721682263000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
              {
                close: 1.4976,
                high: 1.4976,
                lag_ms: 4004,
                low: 1.4976,
                open: 1.4976,
                source: "Polygon_rest",
                start_ms: 1721682259000,
                timespan_ms: 1000,
                volume: 1,
                vwap: null,
                websocket: false,
              },
              {
                close: 1.49829,
                high: 1.49829,
                lag_ms: 84004,
                low: 1.4977,
                open: 1.4977,
                source: "TwelveData_rest",
                start_ms: 1721682120000,
                timespan_ms: 60000,
                volume: null,
                vwap: null,
                websocket: false,
              },
            ],
            processed_ms: 1721682264003,
          },
          {
            leverage: -0.2000000000000004,
            order_type: "SHORT",
            order_uuid: "f624579c-365a-4a36-b549-2b1a7f9bb685",
            price: 1.49896,
            price_sources: [
              {
                close: 1.49896,
                high: 1.49896,
                lag_ms: 483,
                low: 1.49896,
                open: 1.49896,
                source: "Polygon_ws",
                start_ms: 1721696419000,
                timespan_ms: 0,
                volume: 1,
                vwap: 1.49896,
                websocket: true,
              },
              {
                close: 1.49895,
                high: 1.49895,
                lag_ms: 483,
                low: 1.49895,
                open: 1.49895,
                source: "TwelveData_ws",
                start_ms: 1721696419000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1721696419483,
          },
          {
            leverage: -0.15999999999999925,
            order_type: "SHORT",
            order_uuid: "7960b80a-b8c4-42d3-8cc3-5118e3923e1a",
            price: 1.49911,
            price_sources: [
              {
                close: 1.49911,
                high: 1.49911,
                lag_ms: 460,
                low: 1.49911,
                open: 1.49911,
                source: "Polygon_ws",
                start_ms: 1721710856000,
                timespan_ms: 0,
                volume: 1,
                vwap: 1.49911,
                websocket: true,
              },
              {
                close: 1.49911,
                high: 1.49911,
                lag_ms: 460,
                low: 1.49911,
                open: 1.49911,
                source: "TwelveData_ws",
                start_ms: 1721710856000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1721710856460,
          },
          {
            leverage: -0.04000000000000026,
            order_type: "SHORT",
            order_uuid: "2c913b78-79a9-41ab-9fcc-8d48b5ccb0bc",
            price: 1.49908,
            price_sources: [
              {
                close: 1.49908,
                high: 1.49908,
                lag_ms: 423,
                low: 1.49908,
                open: 1.49908,
                source: "Polygon_ws",
                start_ms: 1721710914000,
                timespan_ms: 0,
                volume: 1,
                vwap: 1.49907,
                websocket: true,
              },
              {
                close: 1.49907,
                high: 1.49907,
                lag_ms: 423,
                low: 1.49907,
                open: 1.49907,
                source: "TwelveData_ws",
                start_ms: 1721710914000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1721710913577,
          },
          {
            leverage: 0.1200000000000001,
            order_type: "LONG",
            order_uuid: "8df88358-6968-4f24-a4f2-74657379346c",
            price: 1.49358,
            price_sources: [
              {
                close: 1.49358,
                high: 1.49358,
                lag_ms: 315,
                low: 1.49358,
                open: 1.49358,
                source: "Polygon_ws",
                start_ms: 1721739658999,
                timespan_ms: 0,
                volume: 1,
                vwap: 1.49358,
                websocket: true,
              },
              {
                close: 1.49358,
                high: 1.49358,
                lag_ms: 684,
                low: 1.49358,
                open: 1.49358,
                source: "TwelveData_ws",
                start_ms: 1721739658000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1721739658684,
          },
          {
            leverage: 1.3299999999999998,
            order_type: "LONG",
            order_uuid: "EURCAD-1721898013.miner_signal",
            price: 1.49955,
            price_sources: [
              {
                close: 1.49955,
                high: 1.49955,
                lag_ms: 254,
                low: 1.49955,
                open: 1.49955,
                source: "Polygon_ws",
                start_ms: 1721898021000,
                timespan_ms: 0,
                volume: 1,
                vwap: 1.49955,
                websocket: true,
              },
              {
                close: 1.49945,
                high: 1.49945,
                lag_ms: 254,
                low: 1.49945,
                open: 1.49945,
                source: "TwelveData_ws",
                start_ms: 1721898021000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1721898021254,
          },
          {
            leverage: -0.040000000000000036,
            order_type: "SHORT",
            order_uuid: "EURCAD-1721998810.miner_signal",
            price: 1.50112,
            price_sources: [
              {
                close: 1.50112,
                high: 1.50112,
                lag_ms: 235,
                low: 1.50112,
                open: 1.50112,
                source: "Polygon_ws",
                start_ms: 1721998818000,
                timespan_ms: 0,
                volume: 1,
                vwap: 1.50111,
                websocket: true,
              },
              {
                close: 1.5011,
                high: 1.5011,
                lag_ms: 235,
                low: 1.5011,
                open: 1.5011,
                source: "TwelveData_ws",
                start_ms: 1721998818000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1721998818235,
          },
          {
            leverage: -0.06000000000000005,
            order_type: "SHORT",
            order_uuid: "EURCAD-1722013209.miner_signal",
            price: 1.501685,
            price_sources: [
              {
                close: 1.501685,
                high: 1.501685,
                lag_ms: 211,
                low: 1.501685,
                open: 1.501685,
                source: "Polygon_ws",
                start_ms: 1722013214999,
                timespan_ms: 0,
                volume: 1,
                vwap: 1.50169,
                websocket: true,
              },
              {
                close: 1.50167,
                high: 1.50167,
                lag_ms: 212,
                low: 1.50167,
                open: 1.50167,
                source: "TwelveData_ws",
                start_ms: 1722013215000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1722013214788,
          },
        ],
        position_type: "SHORT",
        position_uuid: "c1d67bd0-7796-4aea-9f6b-059a0ffd6adb",
        return_at_close: 0.990188473082615,
        trade_pair: ["EURCAD", "EUR/CAD", 0.00007, 0.1, 5],
      },
      {
        average_entry_price: 0.8202605092808549,
        close_ms: 0,
        current_return: 0.9956128427117725,
        is_closed_position: false,
        miner_hotkey: "5GhCxfBcA7Ur5iiAS343xwvrYHTUfBjBi4JimiL5LhujRT9t",
        net_leverage: 0.6200000000000001,
        open_ms: 1721365209187,
        orders: [
          {
            leverage: 0.03,
            order_type: "LONG",
            order_uuid: "8b0b5c72-2cc3-4685-b50d-4bafa31c4838",
            price: 0.82539,
            price_sources: [],
            processed_ms: 1721365209187,
          },
          {
            leverage: 0.03,
            order_type: "LONG",
            order_uuid: "dd4d44ff-6b80-4743-a9c7-e77c1a512dbd",
            price: 0.8244661991214,
            price_sources: [
              {
                close: 0.8244661991214,
                high: 0.8244661991214,
                lag_ms: 767,
                low: 0.8244661991214,
                open: 0.8244661991214,
                source: "Polygon_ws",
                start_ms: 1721595924000,
                timespan_ms: 0,
                volume: 1,
                vwap: 0.8244661991214,
                websocket: true,
              },
              {
                close: 0.82462,
                high: 0.82462,
                lag_ms: 7233,
                low: 0.82462,
                open: 0.82462,
                source: "TwelveData_ws",
                start_ms: 1721595916000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
              {
                close: 0.825,
                high: 0.825,
                lag_ms: 173123234,
                low: 0.8249,
                open: 0.8249,
                source: "TwelveData_rest",
                start_ms: 1721422740000,
                timespan_ms: 60000,
                volume: null,
                vwap: null,
                websocket: false,
              },
            ],
            processed_ms: 1721595923233,
          },
          {
            leverage: 0.03,
            order_type: "LONG",
            order_uuid: "9805e5c7-7bc5-40ff-8928-9ef518cccc3a",
            price: 0.8229107677464975,
            price_sources: [
              {
                close: 0.8229107677464975,
                high: 0.8229107677464975,
                lag_ms: 261,
                low: 0.8229107677464975,
                open: 0.8229107677464975,
                source: "Polygon_ws",
                start_ms: 1721624415000,
                timespan_ms: 0,
                volume: 1,
                vwap: 0.822921535492995,
                websocket: true,
              },
              {
                close: 0.82284,
                high: 0.82284,
                lag_ms: 261,
                low: 0.82284,
                open: 0.82284,
                source: "TwelveData_ws",
                start_ms: 1721624415000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1721624415261,
          },
          {
            leverage: 0.03,
            order_type: "LONG",
            order_uuid: "db861c30-15bd-493a-bdb1-c1a68d2f6d7c",
            price: 0.823027654734826,
            price_sources: [
              {
                close: 0.823027654734826,
                high: 0.823027654734826,
                lag_ms: 205,
                low: 0.823027654734826,
                open: 0.823027654734826,
                source: "Polygon_ws",
                start_ms: 1721653215000,
                timespan_ms: 0,
                volume: 1,
                vwap: 0.823027654734826,
                websocket: true,
              },
              {
                close: 0.823,
                high: 0.823,
                lag_ms: 205,
                low: 0.823,
                open: 0.823,
                source: "TwelveData_ws",
                start_ms: 1721653215000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1721653215205,
          },
          {
            leverage: 0.06,
            order_type: "LONG",
            order_uuid: "6beb2b7d-87d9-44d5-8330-4b87af27b7c2",
            price: 0.8213,
            price_sources: [
              {
                close: 0.8213,
                high: 0.8213,
                lag_ms: 302,
                low: 0.8213,
                open: 0.8213,
                source: "Polygon_ws",
                start_ms: 1721667616000,
                timespan_ms: 0,
                volume: 1,
                vwap: 0.8213,
                websocket: true,
              },
              {
                close: 0.82129,
                high: 0.82129,
                lag_ms: 302,
                low: 0.82129,
                open: 0.82129,
                source: "TwelveData_ws",
                start_ms: 1721667616000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1721667616302,
          },
          {
            leverage: 0.03,
            order_type: "LONG",
            order_uuid: "7169f099-ba01-4473-8118-96f9823a7793",
            price: 0.82184,
            price_sources: [
              {
                close: 0.82184,
                high: 0.82184,
                lag_ms: 996,
                low: 0.82184,
                open: 0.82184,
                source: "TwelveData_ws",
                start_ms: 1721682263000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
              {
                close: 0.822077052727207,
                high: 0.822077052727207,
                lag_ms: 2997,
                low: 0.822077052727207,
                open: 0.822077052727207,
                source: "Polygon_ws",
                start_ms: 1721682260999,
                timespan_ms: 0,
                volume: 1,
                vwap: 0.822077052727207,
                websocket: true,
              },
              {
                close: 0.822077052727207,
                high: 0.822077052727207,
                lag_ms: 2997,
                low: 0.822077052727207,
                open: 0.822077052727207,
                source: "Polygon_rest",
                start_ms: 1721682260000,
                timespan_ms: 1000,
                volume: 1,
                vwap: null,
                websocket: false,
              },
              {
                close: 0.82249,
                high: 0.82249,
                lag_ms: 323997,
                low: 0.82249,
                open: 0.82249,
                source: "TwelveData_rest",
                start_ms: 1721681880000,
                timespan_ms: 60000,
                volume: null,
                vwap: null,
                websocket: false,
              },
            ],
            processed_ms: 1721682263996,
          },
          {
            leverage: 0.03,
            order_type: "LONG",
            order_uuid: "5c8bac6c-73ec-42a6-bea1-853afa242537",
            price: 0.8216,
            price_sources: [
              {
                close: 0.8216,
                high: 0.8216,
                lag_ms: 62,
                low: 0.8216,
                open: 0.8216,
                source: "TwelveData_ws",
                start_ms: 1721696457000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
              {
                close: 0.82161,
                high: 0.82161,
                lag_ms: 2063,
                low: 0.82161,
                open: 0.82161,
                source: "Polygon_ws",
                start_ms: 1721696454999,
                timespan_ms: 0,
                volume: 1,
                vwap: 0.821733420879843,
                websocket: true,
              },
              {
                close: 0.82161,
                high: 0.82161,
                lag_ms: 2063,
                low: 0.82161,
                open: 0.82161,
                source: "Polygon_rest",
                start_ms: 1721696454000,
                timespan_ms: 1000,
                volume: 3,
                vwap: null,
                websocket: false,
              },
              {
                close: 0.8216,
                high: 0.8216,
                lag_ms: 117063,
                low: 0.8215,
                open: 0.8215,
                source: "TwelveData_rest",
                start_ms: 1721696280000,
                timespan_ms: 60000,
                volume: null,
                vwap: null,
                websocket: false,
              },
            ],
            processed_ms: 1721696457062,
          },
          {
            leverage: 0.030000000000000027,
            order_type: "LONG",
            order_uuid: "a2551b7f-96cf-4235-8e82-78c959a08458",
            price: 0.821258679,
            price_sources: [
              {
                close: 0.821258679,
                high: 0.821258679,
                lag_ms: 231,
                low: 0.821258679,
                open: 0.821258679,
                source: "Polygon_ws",
                start_ms: 1721710856000,
                timespan_ms: 0,
                volume: 1,
                vwap: 0.821258679,
                websocket: true,
              },
              {
                close: 0.82115,
                high: 0.82115,
                lag_ms: 1231,
                low: 0.82115,
                open: 0.82115,
                source: "TwelveData_ws",
                start_ms: 1721710855000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
              {
                close: 0.82118,
                high: 0.82118,
                lag_ms: 4232,
                low: 0.82118,
                open: 0.82118,
                source: "Polygon_rest",
                start_ms: 1721710851000,
                timespan_ms: 1000,
                volume: 1,
                vwap: null,
                websocket: false,
              },
              {
                close: 0.8213,
                high: 0.8213,
                lag_ms: 116232,
                low: 0.82124,
                open: 0.82124,
                source: "TwelveData_rest",
                start_ms: 1721710680000,
                timespan_ms: 60000,
                volume: null,
                vwap: null,
                websocket: false,
              },
            ],
            processed_ms: 1721710856231,
          },
          {
            leverage: 0.02999999999999997,
            order_type: "LONG",
            order_uuid: "b9f051ca-461a-440a-9863-23439f43d546",
            price: 0.82099,
            price_sources: [
              {
                close: 0.82099,
                high: 0.82099,
                lag_ms: 181,
                low: 0.82099,
                open: 0.82099,
                source: "Polygon_ws",
                start_ms: 1721725301999,
                timespan_ms: 0,
                volume: 1,
                vwap: 0.82099,
                websocket: true,
              },
              {
                close: 0.82097,
                high: 0.82097,
                lag_ms: 818,
                low: 0.82097,
                open: 0.82097,
                source: "TwelveData_ws",
                start_ms: 1721725301000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1721725301818,
          },
          {
            leverage: 0.06,
            order_type: "LONG",
            order_uuid: "187989a9-7de3-45de-bed1-3c61a7e24aa9",
            price: 0.8197,
            price_sources: [
              {
                close: 0.8197,
                high: 0.8197,
                lag_ms: 336,
                low: 0.8197,
                open: 0.8197,
                source: "Polygon_ws",
                start_ms: 1721739658999,
                timespan_ms: 0,
                volume: 1,
                vwap: 0.8197,
                websocket: true,
              },
              {
                close: 0.81998,
                high: 0.81998,
                lag_ms: 337,
                low: 0.81998,
                open: 0.81998,
                source: "TwelveData_ws",
                start_ms: 1721739659000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1721739658663,
          },
          {
            leverage: 0.02999999999999997,
            order_type: "LONG",
            order_uuid: "04dc4d15-e712-45ad-8852-4cffd204a2aa",
            price: 0.819851955956004,
            price_sources: [
              {
                close: 0.819851955956004,
                high: 0.819851955956004,
                lag_ms: 415,
                low: 0.819851955956004,
                open: 0.819851955956004,
                source: "Polygon_ws",
                start_ms: 1721754365999,
                timespan_ms: 0,
                volume: 1,
                vwap: 0.819851955956004,
                websocket: true,
              },
              {
                close: 0.8198,
                high: 0.8198,
                lag_ms: 416,
                low: 0.8198,
                open: 0.8198,
                source: "TwelveData_ws",
                start_ms: 1721754366000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1721754365584,
          },
          {
            leverage: 0.06,
            order_type: "LONG",
            order_uuid: "3afda902-842b-46e0-ac7f-0be1cecafbf7",
            price: 0.81926,
            price_sources: [
              {
                close: 0.81926,
                high: 0.81926,
                lag_ms: 253,
                low: 0.81926,
                open: 0.81926,
                source: "Polygon_ws",
                start_ms: 1721782819999,
                timespan_ms: 0,
                volume: 1,
                vwap: 0.81926,
                websocket: true,
              },
              {
                close: 0.81921,
                high: 0.81921,
                lag_ms: 254,
                low: 0.81921,
                open: 0.81921,
                source: "TwelveData_ws",
                start_ms: 1721782820000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1721782819746,
          },
          {
            leverage: 0.13000000000000006,
            order_type: "LONG",
            order_uuid: "e2254dde-99fa-4344-8392-f9aeb38a55b3",
            price: 0.817940325411508,
            price_sources: [
              {
                close: 0.817940325411508,
                high: 0.817940325411508,
                lag_ms: 81,
                low: 0.817940325411508,
                open: 0.817940325411508,
                source: "Polygon_rest",
                start_ms: 1721803529000,
                timespan_ms: 1000,
                volume: 1,
                vwap: null,
                websocket: false,
              },
              {
                close: 0.8179,
                high: 0.8179,
                lag_ms: 920,
                low: 0.8179,
                open: 0.8179,
                source: "TwelveData_ws",
                start_ms: 1721803531000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
              {
                close: 0.8178,
                high: 0.8179,
                lag_ms: 150081,
                low: 0.8178,
                open: 0.8179,
                source: "TwelveData_rest",
                start_ms: 1721803320000,
                timespan_ms: 60000,
                volume: null,
                vwap: null,
                websocket: false,
              },
              {
                close: 0.817803252957773,
                high: 0.817803252957773,
                lag_ms: 9857081,
                low: 0.817803252957773,
                open: 0.817803252957773,
                source: "Polygon_ws",
                start_ms: 1721793672999,
                timespan_ms: 0,
                volume: 1,
                vwap: 0.8178,
                websocket: true,
              },
            ],
            processed_ms: 1721803530080,
          },
          {
            leverage: 0.014999999999999986,
            order_type: "LONG",
            order_uuid: "NZDCAD-1721926809.miner_signal",
            price: 0.81504,
            price_sources: [
              {
                close: 0.81504,
                high: 0.81504,
                lag_ms: 446,
                low: 0.81504,
                open: 0.81504,
                source: "Polygon_ws",
                start_ms: 1721926821999,
                timespan_ms: 0,
                volume: 1,
                vwap: 0.81504,
                websocket: true,
              },
              {
                close: 0.81499,
                high: 0.81499,
                lag_ms: 447,
                low: 0.81499,
                open: 0.81499,
                source: "TwelveData_ws",
                start_ms: 1721926822000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1721926821553,
          },
          {
            leverage: 0.025000000000000022,
            order_type: "LONG",
            order_uuid: "NZDCAD-1721941601.miner_signal",
            price: 0.81392063015489,
            price_sources: [
              {
                close: 0.81392063015489,
                high: 0.81392063015489,
                lag_ms: 378,
                low: 0.81392063015489,
                open: 0.81392063015489,
                source: "Polygon_ws",
                start_ms: 1721941604000,
                timespan_ms: 0,
                volume: 1,
                vwap: 0.81392063015489,
                websocket: true,
              },
              {
                close: 0.81365,
                high: 0.81365,
                lag_ms: 622,
                low: 0.81365,
                open: 0.81365,
                source: "TwelveData_ws",
                start_ms: 1721941603000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1721941603622,
          },
        ],
        position_type: "LONG",
        position_uuid: "8b0b5c72-2cc3-4685-b50d-4bafa31c4838",
        return_at_close: 0.9955638212395578,
        trade_pair: ["NZDCAD", "NZD/CAD", 0.00007, 0.1, 5],
      },
    ],
    thirty_day_returns: 1.0196592052763316,
  },
  "5GhRAbtoaXXvkZKz8v7RiqRYy8omY5jpEtd1vTcHW1owH4gt": {
    all_time_returns: 1.0342945738726728,
    n_positions: 17,
    percentage_profitable: 0.8235294117647058,
    positions: [
      {
        average_entry_price: 157.22782142857145,
        close_ms: 1718365234822,
        current_return: 1.000002991268043,
        initial_entry_price: 157.124,
        is_closed_position: true,
        miner_hotkey: "5GhRAbtoaXXvkZKz8v7RiqRYy8omY5jpEtd1vTcHW1owH4gt",
        net_leverage: 0.0,
        open_ms: 1718363691465,
        orders: [
          {
            leverage: 0.1,
            order_type: "LONG",
            order_uuid: "44204da6-54b0-4888-a5c6-5e62f5201f2c",
            price: 157.124,
            price_sources: [],
            processed_ms: 1718363691458,
          },
          {
            leverage: 0.18000000000000002,
            order_type: "LONG",
            order_uuid: "95e81764-9afc-45f1-b35a-171a1592303f",
            price: 157.2855,
            price_sources: [],
            processed_ms: 1718365004480,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "2069df12-432f-49a4-a9f0-da015fed290e",
            price: 157.2295,
            price_sources: [],
            processed_ms: 1718365234822,
          },
        ],
        position_type: "FLAT",
        position_uuid: "a03b9502-c54d-489b-b44a-7043a5b9ecd7",
        return_at_close: 0.9999833912094142,
        trade_pair: ["USDJPY", "USD/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 157.147,
        close_ms: 1718365904145,
        current_return: 0.999952910332364,
        initial_entry_price: 157.147,
        is_closed_position: true,
        miner_hotkey: "5GhRAbtoaXXvkZKz8v7RiqRYy8omY5jpEtd1vTcHW1owH4gt",
        net_leverage: 0.0,
        open_ms: 1718365481244,
        orders: [
          {
            leverage: 0.1,
            order_type: "LONG",
            order_uuid: "e16a86e4-5d91-4380-b62d-0edac87064b9",
            price: 157.147,
            price_sources: [],
            processed_ms: 1718365481127,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "d7108b15-acfc-4ce6-b798-2faaa8743b93",
            price: 157.073,
            price_sources: [],
            processed_ms: 1718365904145,
          },
        ],
        position_type: "FLAT",
        position_uuid: "1112fb51-3d47-48a9-9ce5-ca3b3c29c294",
        return_at_close: 0.9999459106619917,
        trade_pair: ["USDJPY", "USD/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 157.0633548387097,
        close_ms: 1718368301861,
        current_return: 1.0004460541971278,
        initial_entry_price: 157.167,
        is_closed_position: true,
        miner_hotkey: "5GhRAbtoaXXvkZKz8v7RiqRYy8omY5jpEtd1vTcHW1owH4gt",
        net_leverage: 0.0,
        open_ms: 1718366238316,
        orders: [
          {
            leverage: 0.1,
            order_type: "LONG",
            order_uuid: "2122056d-21c4-4e91-b31d-3789572aa860",
            price: 157.167,
            price_sources: [],
            processed_ms: 1718366238301,
          },
          {
            leverage: 0.21000000000000002,
            order_type: "LONG",
            order_uuid: "f7fde9e5-9301-4361-b028-00a0dd0408d9",
            price: 157.014,
            price_sources: [],
            processed_ms: 1718366718437,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "ba379891-d359-40d8-b9f9-35814245bc7a",
            price: 157.2895,
            price_sources: [],
            processed_ms: 1718368301861,
          },
        ],
        position_type: "FLAT",
        position_uuid: "f50f7e84-c4dd-426f-8ea0-03e3e504e53f",
        return_at_close: 1.0004243445177519,
        trade_pair: ["USDJPY", "USD/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 157.10079743888247,
        close_ms: 1718374529533,
        current_return: 1.0010657247454522,
        initial_entry_price: 157.338,
        is_closed_position: true,
        miner_hotkey: "5GhRAbtoaXXvkZKz8v7RiqRYy8omY5jpEtd1vTcHW1owH4gt",
        net_leverage: 0.0,
        open_ms: 1718368331253,
        orders: [
          {
            leverage: 0.1,
            order_type: "LONG",
            order_uuid: "f210b68f-ac89-4f09-b7ba-2cbeb078b4c0",
            price: 157.338,
            price_sources: [],
            processed_ms: 1718368331229,
          },
          {
            leverage: 0.22999999999999998,
            order_type: "LONG",
            order_uuid: "5af578a3-54f9-42d6-80bc-5f9651cdaadb",
            price: 157.149,
            price_sources: [],
            processed_ms: 1718372211867,
          },
          {
            leverage: 0.5289999999999999,
            order_type: "LONG",
            order_uuid: "60813dca-36ac-4725-a4f8-30aba9c85536",
            price: 157.035,
            price_sources: [],
            processed_ms: 1718374119131,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "44717840-1bbf-420a-b52a-cb774ffc2b4f",
            price: 157.296,
            price_sources: [],
            processed_ms: 1718374529533,
          },
        ],
        position_type: "FLAT",
        position_uuid: "a95d4f76-fda5-41bf-a127-70b4a5e9f48b",
        return_at_close: 1.0010055306634231,
        trade_pair: ["USDJPY", "USD/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 157.297,
        close_ms: 1718376777143,
        current_return: 1.0001099830257412,
        initial_entry_price: 157.297,
        is_closed_position: true,
        miner_hotkey: "5GhRAbtoaXXvkZKz8v7RiqRYy8omY5jpEtd1vTcHW1owH4gt",
        net_leverage: 0.0,
        open_ms: 1718374559290,
        orders: [
          {
            leverage: 0.1,
            order_type: "LONG",
            order_uuid: "c313b08e-8d61-4ee2-a344-cc05fecde435",
            price: 157.297,
            price_sources: [],
            processed_ms: 1718374559280,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "e240ebfb-5384-4473-94b3-7744a3e3ea28",
            price: 157.47,
            price_sources: [],
            processed_ms: 1718376777143,
          },
        ],
        position_type: "FLAT",
        position_uuid: "d36830cd-67fc-4929-a8c5-fd330e142705",
        return_at_close: 1.00010298225586,
        trade_pair: ["USDJPY", "USD/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 169.46548387096777,
        close_ms: 1718387332684,
        current_return: 1.0105836737110048,
        initial_entry_price: 168.93,
        is_closed_position: true,
        miner_hotkey: "5GhRAbtoaXXvkZKz8v7RiqRYy8omY5jpEtd1vTcHW1owH4gt",
        net_leverage: 0.0,
        open_ms: 1718122770862,
        orders: [
          {
            leverage: -0.55,
            order_type: "SHORT",
            order_uuid: "653311bd-25c9-4b86-8b0a-5827bf620d42",
            price: 168.93,
            price_sources: [],
            processed_ms: 1718122770852,
          },
          {
            leverage: -1.0,
            order_type: "SHORT",
            order_uuid: "6942bc96-dc03-46f0-845a-d427779faac7",
            price: 169.76,
            price_sources: [],
            processed_ms: 1718273484718,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "5053177e-045f-4085-99b3-1f6a429da36f",
            price: 168.312,
            price_sources: [],
            processed_ms: 1718387332684,
          },
        ],
        position_type: "FLAT",
        position_uuid: "e4d701a3-83a5-4555-897d-a8848d58ebe2",
        return_at_close: 1.0104740253824072,
        trade_pair: ["EURJPY", "EUR/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 65116.226424707784,
        close_ms: 1718389972967,
        current_return: 1.0228600158971162,
        initial_entry_price: 65360.99,
        is_closed_position: true,
        miner_hotkey: "5GhRAbtoaXXvkZKz8v7RiqRYy8omY5jpEtd1vTcHW1owH4gt",
        net_leverage: 0.0,
        open_ms: 1718387363147,
        orders: [
          {
            leverage: 0.03,
            order_type: "LONG",
            order_uuid: "e66872d3-2ed7-4033-8816-1a6656fb0735",
            price: 65360.99,
            price_sources: [],
            processed_ms: 1718387362385,
          },
          {
            leverage: 0.054,
            order_type: "LONG",
            order_uuid: "516f25b7-b53b-4896-bc84-76553e5e496d",
            price: 65359.99,
            price_sources: [],
            processed_ms: 1718387400107,
          },
          {
            leverage: 0.09179999999999999,
            order_type: "LONG",
            order_uuid: "ee4a0f40-c361-4ffa-81c8-b1110a55830d",
            price: 65316.91,
            price_sources: [],
            processed_ms: 1718387892297,
          },
          {
            leverage: 0.20196,
            order_type: "LONG",
            order_uuid: "78280c85-ecd2-4c88-bc78-6b1cd244fe69",
            price: 65266.81,
            price_sources: [],
            processed_ms: 1718388434282,
          },
          {
            leverage: 0.44431200000000004,
            order_type: "LONG",
            order_uuid: "0929b03d-eabb-40c1-961e-af3c96924d6f",
            price: 65210.41,
            price_sources: [],
            processed_ms: 1718388504932,
          },
          {
            leverage: 0.8441928000000001,
            order_type: "LONG",
            order_uuid: "067ad0f1-ae13-4d69-aa82-6fd52b2fa096",
            price: 65135.9,
            price_sources: [],
            processed_ms: 1718388593918,
          },
          {
            leverage: 1.6883856000000002,
            order_type: "LONG",
            order_uuid: "e42375c7-ca32-40c1-aa77-2f9d825dd9a2",
            price: 65106.33,
            price_sources: [],
            processed_ms: 1718388646437,
          },
          {
            leverage: 3.88328688,
            order_type: "LONG",
            order_uuid: "7daf8dde-5108-448c-b553-7beab150e2b1",
            price: 65087.62,
            price_sources: [],
            processed_ms: 1718388738106,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "d6b8ef2e-54dc-4b3d-9043-529e89327141",
            price: 65322.66,
            price_sources: [],
            processed_ms: 1718389972967,
          },
        ],
        position_type: "FLAT",
        position_uuid: "87dc49c2-62de-45b6-9da2-673d7129ccd6",
        return_at_close: 1.015456619255833,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 65286.0,
        close_ms: 1718393394375,
        current_return: 1.00014662714824,
        initial_entry_price: 65286.0,
        is_closed_position: true,
        miner_hotkey: "5GhRAbtoaXXvkZKz8v7RiqRYy8omY5jpEtd1vTcHW1owH4gt",
        net_leverage: 0.0,
        open_ms: 1718390003709,
        orders: [
          {
            leverage: 0.03,
            order_type: "LONG",
            order_uuid: "2a4d8e27-c83b-46ae-9379-453c8ff8593c",
            price: 65286.0,
            price_sources: [],
            processed_ms: 1718390003196,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "4d6aaa83-2da6-4e7d-bace-2909251a4318",
            price: 65605.09,
            price_sources: [],
            processed_ms: 1718393394375,
          },
        ],
        position_type: "FLAT",
        position_uuid: "794b711a-9e7c-4ea6-867c-2ec8ca4c87d3",
        return_at_close: 1.0001166227494256,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 65433.14546033261,
        close_ms: 1718395434804,
        current_return: 1.0018502128573563,
        initial_entry_price: 65553.29,
        is_closed_position: true,
        miner_hotkey: "5GhRAbtoaXXvkZKz8v7RiqRYy8omY5jpEtd1vTcHW1owH4gt",
        net_leverage: 0.0,
        open_ms: 1718393425110,
        orders: [
          {
            leverage: 0.03,
            order_type: "LONG",
            order_uuid: "47afaa7a-cf34-4950-8ff1-6cf1d41f956f",
            price: 65553.29,
            price_sources: [],
            processed_ms: 1718393424688,
          },
          {
            leverage: 0.06,
            order_type: "LONG",
            order_uuid: "ea9cd06c-ff49-4107-a640-4af5348d7549",
            price: 65560.0,
            price_sources: [],
            processed_ms: 1718393888176,
          },
          {
            leverage: 0.102,
            order_type: "LONG",
            order_uuid: "869e2d0b-6757-495b-bbde-8d8dbfce92eb",
            price: 65420.89,
            price_sources: [],
            processed_ms: 1718393952060,
          },
          {
            leverage: 0.19379999999999997,
            order_type: "LONG",
            order_uuid: "0fad986c-b17b-480e-a411-61278247ebef",
            price: 65401.0,
            price_sources: [],
            processed_ms: 1718394238076,
          },
          {
            leverage: 0.36821999999999994,
            order_type: "LONG",
            order_uuid: "1a5d155b-f471-49ac-87b8-c994c56404b7",
            price: 65423.0,
            price_sources: [],
            processed_ms: 1718394768444,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "0abaec38-132b-4675-89e5-bad4efa6889e",
            price: 65594.0,
            price_sources: [],
            processed_ms: 1718395434804,
          },
        ],
        position_type: "FLAT",
        position_uuid: "f4a29086-b9e4-49dc-99b6-82ff2d972c04",
        return_at_close: 1.0010947977598577,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 65520.435,
        close_ms: 1718396883216,
        current_return: 1.000340701047911,
        initial_entry_price: 65559.0,
        is_closed_position: true,
        miner_hotkey: "5GhRAbtoaXXvkZKz8v7RiqRYy8omY5jpEtd1vTcHW1owH4gt",
        net_leverage: 0.0,
        open_ms: 1718395466821,
        orders: [
          {
            leverage: 0.03,
            order_type: "LONG",
            order_uuid: "da445433-5665-4efd-8a9b-976666df97ec",
            price: 65559.0,
            price_sources: [],
            processed_ms: 1718395466020,
          },
          {
            leverage: 0.054,
            order_type: "LONG",
            order_uuid: "a8a7011d-08d6-4959-91a9-8640cead3eed",
            price: 65499.01,
            price_sources: [],
            processed_ms: 1718395600107,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "e4af2dfd-8b73-490f-84df-939411c0a8fc",
            price: 65786.34,
            price_sources: [],
            processed_ms: 1718396883216,
          },
        ],
        position_type: "FLAT",
        position_uuid: "c05be938-bf09-4983-b99c-b0e25c06d0f2",
        return_at_close: 1.0002566724290232,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 65723.7665625,
        close_ms: 1718400451349,
        current_return: 1.000783492307669,
        initial_entry_price: 65786.3,
        is_closed_position: true,
        miner_hotkey: "5GhRAbtoaXXvkZKz8v7RiqRYy8omY5jpEtd1vTcHW1owH4gt",
        net_leverage: 0.0,
        open_ms: 1718396913796,
        orders: [
          {
            leverage: 0.03,
            order_type: "LONG",
            order_uuid: "691ca270-d110-4c33-ae67-06398a363d6d",
            price: 65786.3,
            price_sources: [],
            processed_ms: 1718396912890,
          },
          {
            leverage: 0.06,
            order_type: "LONG",
            order_uuid: "b65b00de-9846-43b0-87c5-0fd5f00e27cb",
            price: 65747.37,
            price_sources: [],
            processed_ms: 1718397617587,
          },
          {
            leverage: 0.102,
            order_type: "LONG",
            order_uuid: "ce83444f-c8db-43f2-bc52-b7b9ddc6c1f9",
            price: 65691.49,
            price_sources: [],
            processed_ms: 1718398023292,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "186d41fe-7f6e-459b-8e8f-f1a3b525b470",
            price: 65992.22,
            price_sources: [],
            processed_ms: 1718400451349,
          },
        ],
        position_type: "FLAT",
        position_uuid: "d4160cb1-8fe6-401c-a84a-782be37d0067",
        return_at_close: 1.000591341877146,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 66047.41,
        close_ms: 1718400956130,
        current_return: 1.0001070912546004,
        initial_entry_price: 66047.41,
        is_closed_position: true,
        miner_hotkey: "5GhRAbtoaXXvkZKz8v7RiqRYy8omY5jpEtd1vTcHW1owH4gt",
        net_leverage: 0.0,
        open_ms: 1718400481755,
        orders: [
          {
            leverage: 0.03,
            order_type: "LONG",
            order_uuid: "17b56971-36f6-449d-828a-db1fdd272349",
            price: 66047.41,
            price_sources: [],
            processed_ms: 1718400480867,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "db978088-a0b1-44e6-890f-5ec79b2585da",
            price: 66283.18,
            price_sources: [],
            processed_ms: 1718400956130,
          },
        ],
        position_type: "FLAT",
        position_uuid: "e7bce7f7-9d2a-4b24-8f3b-55be4c71e220",
        return_at_close: 1.0000770880418628,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 66069.54828025478,
        close_ms: 1718402069765,
        current_return: 1.001357609176118,
        initial_entry_price: 66375.78,
        is_closed_position: true,
        miner_hotkey: "5GhRAbtoaXXvkZKz8v7RiqRYy8omY5jpEtd1vTcHW1owH4gt",
        net_leverage: 0.0,
        open_ms: 1718400987026,
        orders: [
          {
            leverage: 0.03,
            order_type: "LONG",
            order_uuid: "63e949e5-9914-41c4-94dc-bfe66836a248",
            price: 66375.78,
            price_sources: [],
            processed_ms: 1718400986272,
          },
          {
            leverage: 0.051,
            order_type: "LONG",
            order_uuid: "1bd60b68-b5ba-4e15-b65b-e2c994f1b9c9",
            price: 66221.37,
            price_sources: [],
            processed_ms: 1718401373436,
          },
          {
            leverage: 0.102,
            order_type: "LONG",
            order_uuid: "512e5fd6-f0c4-4d17-a7d1-78ca55131be2",
            price: 66102.8,
            price_sources: [],
            processed_ms: 1718401403070,
          },
          {
            leverage: 0.19379999999999997,
            order_type: "LONG",
            order_uuid: "d0d1d3e5-4d84-489b-9264-e3d0e5c1ce37",
            price: 65964.69,
            price_sources: [],
            processed_ms: 1718401438870,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "6ef29582-e85e-4851-bda9-9d7dd8c355b2",
            price: 66308.7,
            price_sources: [],
            processed_ms: 1718402069765,
          },
        ],
        position_type: "FLAT",
        position_uuid: "58ea2bac-ccd1-4b2a-86d6-9f6c4a54a558",
        return_at_close: 1.0009802976289806,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 65924.69639543789,
        close_ms: 1718408757740,
        current_return: 1.0015701085698185,
        initial_entry_price: 66238.74,
        is_closed_position: true,
        miner_hotkey: "5GhRAbtoaXXvkZKz8v7RiqRYy8omY5jpEtd1vTcHW1owH4gt",
        net_leverage: 0.0,
        open_ms: 1718402099847,
        orders: [
          {
            leverage: 0.03,
            order_type: "LONG",
            order_uuid: "0669f346-32c4-44b9-8e9c-d3377bc9305c",
            price: 66238.74,
            price_sources: [],
            processed_ms: 1718402099116,
          },
          {
            leverage: 0.066,
            order_type: "LONG",
            order_uuid: "549adb00-357a-490f-9e0f-8aac7d7e2132",
            price: 66175.5,
            price_sources: [],
            processed_ms: 1718402142826,
          },
          {
            leverage: 0.11220000000000001,
            order_type: "LONG",
            order_uuid: "274bb456-72ff-49de-a8d2-adb38afc8bff",
            price: 65937.18,
            price_sources: [],
            processed_ms: 1718402525375,
          },
          {
            leverage: 0.24684000000000003,
            order_type: "LONG",
            order_uuid: "9a3d4e6e-fdb5-49a8-a560-9015b3e739c0",
            price: 65976.97,
            price_sources: [],
            processed_ms: 1718402562900,
          },
          {
            leverage: 0.41962800000000006,
            order_type: "LONG",
            order_uuid: "8f0b3824-f557-4f33-b472-6108f400532a",
            price: 65931.0,
            price_sources: [],
            processed_ms: 1718402607522,
          },
          {
            leverage: 0.7972932,
            order_type: "LONG",
            order_uuid: "bc9ac0df-8f9f-4b7f-9b4b-b59300642c2b",
            price: 65870.86,
            price_sources: [],
            processed_ms: 1718402676900,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "ec31f38e-ffe1-4a14-bc5d-5c41f08becf1",
            price: 65986.9,
            price_sources: [],
            processed_ms: 1718408757740,
          },
        ],
        position_type: "FLAT",
        position_uuid: "d44c9e5c-be3c-4301-b8eb-1bc8ce675f8c",
        return_at_close: 0.99989552220921,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 66197.92,
        close_ms: 1718464769793,
        current_return: 1.0002052837170556,
        initial_entry_price: 66263.2,
        is_closed_position: true,
        miner_hotkey: "5GhRAbtoaXXvkZKz8v7RiqRYy8omY5jpEtd1vTcHW1owH4gt",
        net_leverage: 0.0,
        open_ms: 1718449209462,
        orders: [
          {
            leverage: 0.03,
            order_type: "LONG",
            order_uuid: "8e201405-e54a-4575-9bbb-79167b9a3847",
            price: 66263.2,
            price_sources: [],
            processed_ms: 1718449207132,
          },
          {
            leverage: 0.024,
            order_type: "LONG",
            order_uuid: "de00eefd-35de-41f1-a460-347ea9b35d19",
            price: 66167.44,
            price_sources: [],
            processed_ms: 1718461721584,
          },
          {
            leverage: 0.019200000000000002,
            order_type: "LONG",
            order_uuid: "dae1242f-7315-44fb-be0c-520195f59e29",
            price: 66134.02,
            price_sources: [],
            processed_ms: 1718463182923,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "147c447b-48d9-46b8-9d94-796624adff19",
            price: 66383.75,
            price_sources: [],
            processed_ms: 1718464769793,
          },
        ],
        position_type: "FLAT",
        position_uuid: "ef2f6444-4a6d-461b-a3c6-a137d0502965",
        return_at_close: 1.0001320686902875,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 66273.48507636026,
        close_ms: 1718547936580,
        current_return: 1.0009328924435286,
        initial_entry_price: 66392.1,
        is_closed_position: true,
        miner_hotkey: "5GhRAbtoaXXvkZKz8v7RiqRYy8omY5jpEtd1vTcHW1owH4gt",
        net_leverage: 0.0,
        open_ms: 1718464800487,
        orders: [
          {
            leverage: 0.03,
            order_type: "LONG",
            order_uuid: "a583df18-2c45-418f-b804-c862f1f39612",
            price: 66392.1,
            price_sources: [],
            processed_ms: 1718464800089,
          },
          {
            leverage: 0.03,
            order_type: "LONG",
            order_uuid: "744cb901-dad4-4414-be2e-c85b556d6ec1",
            price: 66304.45,
            price_sources: [],
            processed_ms: 1718465146288,
          },
          {
            leverage: 0.033,
            order_type: "LONG",
            order_uuid: "dc1b7443-b554-4aca-9700-361fe1b9b3f9",
            price: 66281.83,
            price_sources: [],
            processed_ms: 1718465384158,
          },
          {
            leverage: 0.0396,
            order_type: "LONG",
            order_uuid: "5993397c-033e-4982-80cc-f835a0386142",
            price: 66240.47,
            price_sources: [],
            processed_ms: 1718465620045,
          },
          {
            leverage: 0.04356000000000001,
            order_type: "LONG",
            order_uuid: "a7a3eee4-b518-4776-b617-892779156337",
            price: 66240.0,
            price_sources: [],
            processed_ms: 1718466068473,
          },
          {
            leverage: 0.030492000000000005,
            order_type: "LONG",
            order_uuid: "225d66b6-1832-48ce-97cf-06afa0045501",
            price: 66208.0,
            price_sources: [],
            processed_ms: 1718466111936,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "9c7951a5-25f4-4fec-8051-7801d1ee00ce",
            price: 66573.2,
            price_sources: [],
            processed_ms: 1718547936580,
          },
        ],
        position_type: "FLAT",
        position_uuid: "2ec638d9-3e15-478e-b977-64441a8ae1ae",
        return_at_close: 1.0007260476594393,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 157.2790821917808,
        close_ms: 1718625545150,
        current_return: 1.0026859477892012,
        initial_entry_price: 157.477,
        is_closed_position: true,
        miner_hotkey: "5GhRAbtoaXXvkZKz8v7RiqRYy8omY5jpEtd1vTcHW1owH4gt",
        net_leverage: 0.0,
        open_ms: 1718376806822,
        orders: [
          {
            leverage: 0.1,
            order_type: "LONG",
            order_uuid: "d70907d5-8e44-455b-893b-ff1ca5e91d55",
            price: 157.477,
            price_sources: [],
            processed_ms: 1718376806803,
          },
          {
            leverage: 0.21000000000000002,
            order_type: "LONG",
            order_uuid: "06702289-940e-4303-a8b0-7c2888e8ebf5",
            price: 157.319,
            price_sources: [],
            processed_ms: 1718378188279,
          },
          {
            leverage: 0.42000000000000004,
            order_type: "LONG",
            order_uuid: "f80a4f09-5bcd-4576-aac8-468a7ed34405",
            price: 157.212,
            price_sources: [],
            processed_ms: 1718379723721,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "6dc12d70-eea2-4cf6-b2aa-af25304f6fd5",
            price: 157.8585,
            price_sources: [],
            processed_ms: 1718625545150,
          },
        ],
        position_type: "FLAT",
        position_uuid: "2aa250c1-2af9-4ea7-877e-789f9f43f3f2",
        return_at_close: 1.0026347105372693,
        trade_pair: ["USDJPY", "USD/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 61348.36956521739,
        close_ms: 0,
        current_return: 1.012032989858673,
        initial_entry_price: 61382.5,
        is_closed_position: false,
        miner_hotkey: "5GhRAbtoaXXvkZKz8v7RiqRYy8omY5jpEtd1vTcHW1owH4gt",
        net_leverage: -0.23,
        open_ms: 1719231321764,
        orders: [
          {
            leverage: -0.13,
            order_type: "SHORT",
            order_uuid: "e847535a-7d0e-4feb-8da9-deb0821c2414",
            price: 61382.5,
            price_sources: [],
            processed_ms: 1719231320834,
          },
          {
            leverage: -0.1,
            order_type: "SHORT",
            order_uuid: "171f1be4-f40b-4398-ab10-ffc486bebb4e",
            price: 61304.0,
            price_sources: [],
            processed_ms: 1719315109507,
          },
        ],
        position_type: "SHORT",
        position_uuid: "fdd9783f-beec-47b1-9f2c-c0612e98561e",
        return_at_close: 1.0119166060648392,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
    ],
    thirty_day_returns: 1.0342945738726728,
  },
  "5GhRddUNcwWSaaa8o5ipcYr4HLCYMg1WwH3rUWdF6RHgE581": {
    all_time_returns: 1.252528040007705,
    n_positions: 4,
    percentage_profitable: 1.0,
    positions: [
      {
        average_entry_price: 59899.64500000001,
        close_ms: 1719357795668,
        current_return: 1.1765895569105478,
        initial_entry_price: 60861.3,
        is_closed_position: true,
        miner_hotkey: "5GhRddUNcwWSaaa8o5ipcYr4HLCYMg1WwH3rUWdF6RHgE581",
        net_leverage: 0.0,
        open_ms: 1719244509561,
        orders: [
          {
            leverage: 1.0,
            order_type: "LONG",
            order_uuid: "9d60cac9-bebe-493c-9bd9-524518e0ee8c",
            price: 60861.3,
            price_sources: [],
            processed_ms: 1719244509131,
          },
          {
            leverage: 1.0,
            order_type: "LONG",
            order_uuid: "afa8c391-c014-4514-bbde-d4521057351e",
            price: 60254.77,
            price_sources: [],
            processed_ms: 1719247886047,
          },
          {
            leverage: 1.0,
            order_type: "LONG",
            order_uuid: "1793d72e-2eb9-4ff1-b1d5-914f0b08bb0e",
            price: 60263.09,
            price_sources: [],
            processed_ms: 1719248524014,
          },
          {
            leverage: 1.0,
            order_type: "LONG",
            order_uuid: "a9246e90-729b-41cc-aed5-acc0b3d080ad",
            price: 59344.69,
            price_sources: [],
            processed_ms: 1719262206153,
          },
          {
            leverage: 1.0,
            order_type: "LONG",
            order_uuid: "b26f4a25-304f-48ed-812e-75c651ea92d8",
            price: 59343.12,
            price_sources: [],
            processed_ms: 1719262243377,
          },
          {
            leverage: 1.0,
            order_type: "LONG",
            order_uuid: "b3e377c7-2232-4351-b149-259fd5ab1339",
            price: 59330.9,
            price_sources: [],
            processed_ms: 1719262297450,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "5e08c58c-68a9-4a37-a608-b9cee87044f1",
            price: 61690.89,
            price_sources: [],
            processed_ms: 1719357795668,
          },
        ],
        position_type: "FLAT",
        position_uuid: "9d60cac9-bebe-493c-9bd9-524518e0ee8c",
        return_at_close: 1.1695300195690845,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 3266.28,
        close_ms: 1719365078595,
        current_return: 1.0415181797029036,
        initial_entry_price: 3266.28,
        is_closed_position: true,
        miner_hotkey: "5GhRddUNcwWSaaa8o5ipcYr4HLCYMg1WwH3rUWdF6RHgE581",
        net_leverage: 0.0,
        open_ms: 1719244644225,
        orders: [
          {
            leverage: 1.0,
            order_type: "LONG",
            order_uuid: "7a61ba04-33a8-451f-b889-024be4fea34f",
            price: 3266.28,
            price_sources: [],
            processed_ms: 1719244643776,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "65d49ae9-e56e-4c90-a4b2-b907e98a7991",
            price: 3401.89,
            price_sources: [],
            processed_ms: 1719365078595,
          },
        ],
        position_type: "FLAT",
        position_uuid: "7a61ba04-33a8-451f-b889-024be4fea34f",
        return_at_close: 1.0404766615232006,
        trade_pair: ["ETHUSD", "ETH/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 1.0681225,
        close_ms: 1719501439884,
        current_return: 1.0290903321542877,
        initial_entry_price: 1.07149,
        is_closed_position: true,
        miner_hotkey: "5GhRddUNcwWSaaa8o5ipcYr4HLCYMg1WwH3rUWdF6RHgE581",
        net_leverage: 0.0,
        open_ms: 1719364573694,
        orders: [
          {
            leverage: 3.0,
            order_type: "LONG",
            order_uuid: "97b89a69-19bd-4bc5-8ae2-df685b69cc76",
            price: 1.07149,
            price_sources: [],
            processed_ms: 1719364573284,
          },
          {
            leverage: 9.0,
            order_type: "LONG",
            order_uuid: "ad2d8c82-1b25-48ae-9eb5-cbb1ba8905b8",
            price: 1.067,
            price_sources: [],
            processed_ms: 1719408055186,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "9507034a-ce32-4bcd-8f3e-c71e91e24e85",
            price: 1.07072,
            price_sources: [],
            processed_ms: 1719501439884,
          },
        ],
        position_type: "FLAT",
        position_uuid: "97b89a69-19bd-4bc5-8ae2-df685b69cc76",
        return_at_close: 1.0282258962752782,
        trade_pair: ["EURUSD", "EUR/USD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 61595.38,
        close_ms: 1719509611062,
        current_return: 1.0015494668593652,
        initial_entry_price: 61595.38,
        is_closed_position: true,
        miner_hotkey: "5GhRddUNcwWSaaa8o5ipcYr4HLCYMg1WwH3rUWdF6RHgE581",
        net_leverage: 0.0,
        open_ms: 1719504252472,
        orders: [
          {
            leverage: 0.5,
            order_type: "LONG",
            order_uuid: "91aa8732-f8ac-4f3c-9fde-1b29da35537e",
            price: 61595.38,
            price_sources: [],
            processed_ms: 1719504252084,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "aa88a489-9ab1-4036-b4a7-285341e1eb4b",
            price: 61786.26,
            price_sources: [],
            processed_ms: 1719509611062,
          },
        ],
        position_type: "FLAT",
        position_uuid: "91aa8732-f8ac-4f3c-9fde-1b29da35537e",
        return_at_close: 1.0010486921259356,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 61017.115000000005,
        close_ms: 0,
        current_return: 0.9534484135270922,
        initial_entry_price: 61869.32,
        is_closed_position: false,
        miner_hotkey: "5GhRddUNcwWSaaa8o5ipcYr4HLCYMg1WwH3rUWdF6RHgE581",
        net_leverage: 1.0,
        open_ms: 1719540826381,
        orders: [
          {
            leverage: 0.5,
            order_type: "LONG",
            order_uuid: "105b8a3e-1878-45e1-a8f4-26e0204dd492",
            price: 61869.32,
            price_sources: [],
            processed_ms: 1719540826034,
          },
          {
            leverage: 0.5,
            order_type: "LONG",
            order_uuid: "8ae6fe63-827c-46d3-a156-60f8893b46aa",
            price: 60164.91,
            price_sources: [],
            processed_ms: 1719606570374,
          },
        ],
        position_type: "LONG",
        position_uuid: "105b8a3e-1878-45e1-a8f4-26e0204dd492",
        return_at_close: 0.9529716893203287,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
    ],
    thirty_day_returns: 1.252528040007705,
  },
  "5GjNQTm5MXBb8FSzrZvPjC6kj5cXZdi6hWgBUGeXhUUKFr29": {
    all_time_returns: 1.0,
    n_positions: 0,
    percentage_profitable: 0.0,
    positions: [
      {
        average_entry_price: 65212.592500000006,
        close_ms: 1711207180903,
        current_return: 1.063126337199771,
        initial_entry_price: 65187.53,
        is_closed_position: true,
        miner_hotkey: "5GjNQTm5MXBb8FSzrZvPjC6kj5cXZdi6hWgBUGeXhUUKFr29",
        net_leverage: 0.0,
        open_ms: 1711204722040,
        orders: [
          {
            leverage: -5.0,
            order_type: "SHORT",
            order_uuid: "d3699235-3a57-440a-96d5-40f1bce5ff2e",
            price: 65187.53,
            price_sources: [],
            processed_ms: 1711204722040,
          },
          {
            leverage: -5.0,
            order_type: "SHORT",
            order_uuid: "c106042e-b227-4bde-a959-2758f2fc5540",
            price: 65105.47,
            price_sources: [],
            processed_ms: 1711205079751,
          },
          {
            leverage: -5.0,
            order_type: "SHORT",
            order_uuid: "5f6b611d-846e-4711-8429-a3ba555f8423",
            price: 65284.01,
            price_sources: [],
            processed_ms: 1711205443937,
          },
          {
            leverage: -5.0,
            order_type: "SHORT",
            order_uuid: "e6e51ed4-9516-41cd-bb4b-8e317e2bd6b5",
            price: 65273.36,
            price_sources: [],
            processed_ms: 1711205763156,
          },
          {
            leverage: 5.0,
            order_type: "FLAT",
            order_uuid: "a43a0c78-a4cf-4aef-95b5-3d770b199793",
            price: 65006.84,
            price_sources: [],
            processed_ms: 1711207180903,
          },
        ],
        position_type: "FLAT",
        position_uuid: "5ea739cd-c31b-43f9-9c8b-b3a819d82bd1",
        return_at_close: 1.0206012837117802,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 3395.418,
        close_ms: 1711210460667,
        current_return: 0.9960142277676443,
        initial_entry_price: 3404.61,
        is_closed_position: true,
        miner_hotkey: "5GjNQTm5MXBb8FSzrZvPjC6kj5cXZdi6hWgBUGeXhUUKFr29",
        net_leverage: 0.0,
        open_ms: 1711207456940,
        orders: [
          {
            leverage: 0.5,
            order_type: "LONG",
            order_uuid: "d365db49-7845-43f3-bf28-1754031474aa",
            price: 3404.61,
            price_sources: [],
            processed_ms: 1711207456940,
          },
          {
            leverage: 0.5,
            order_type: "LONG",
            order_uuid: "1f99c584-af69-4786-a07c-9dbe169065f1",
            price: 3384.92,
            price_sources: [],
            processed_ms: 1711208052518,
          },
          {
            leverage: 0.5,
            order_type: "LONG",
            order_uuid: "62c6e4dd-d6f9-4467-890d-0fe5edb32491",
            price: 3393.16,
            price_sources: [],
            processed_ms: 1711208657188,
          },
          {
            leverage: 0.5,
            order_type: "LONG",
            order_uuid: "991b3ccf-f209-47f4-a632-0f7255928e33",
            price: 3397.44,
            price_sources: [],
            processed_ms: 1711209256180,
          },
          {
            leverage: 0.5,
            order_type: "LONG",
            order_uuid: "40fe19a6-0578-47e4-95aa-3ea978effff6",
            price: 3396.96,
            price_sources: [],
            processed_ms: 1711209880027,
          },
          {
            leverage: 0.5,
            order_type: "FLAT",
            order_uuid: "86ecd5ec-d542-499f-b740-1c53f3a3e7e9",
            price: 3389.99,
            price_sources: [],
            processed_ms: 1711210460667,
          },
        ],
        position_type: "FLAT",
        position_uuid: "f43bace0-3bfd-43e1-a52c-5c3191b49770",
        return_at_close: 0.991034156628806,
        trade_pair: ["ETHUSD", "ETH/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 3413.54,
        close_ms: 1711214056375,
        current_return: 0.9969944150499706,
        initial_entry_price: 3402.0,
        is_closed_position: true,
        miner_hotkey: "5GjNQTm5MXBb8FSzrZvPjC6kj5cXZdi6hWgBUGeXhUUKFr29",
        net_leverage: 0.0,
        open_ms: 1711211058337,
        orders: [
          {
            leverage: 0.5,
            order_type: "LONG",
            order_uuid: "5e3ea43b-2198-424b-9ca1-fe4b2aaaff5c",
            price: 3402.0,
            price_sources: [],
            processed_ms: 1711211058336,
          },
          {
            leverage: 0.5,
            order_type: "LONG",
            order_uuid: "30ee2216-4681-4644-8908-a02ec8c70cb0",
            price: 3414.34,
            price_sources: [],
            processed_ms: 1711211655081,
          },
          {
            leverage: 0.5,
            order_type: "LONG",
            order_uuid: "3257610f-133e-41d4-a787-ae70f4f2cb2e",
            price: 3415.33,
            price_sources: [],
            processed_ms: 1711212254478,
          },
          {
            leverage: 0.5,
            order_type: "LONG",
            order_uuid: "127f9925-d0f7-47fc-b4b1-4bc564620f2f",
            price: 3424.44,
            price_sources: [],
            processed_ms: 1711212859976,
          },
          {
            leverage: 0.5,
            order_type: "LONG",
            order_uuid: "4b2c625f-82eb-40ff-8192-a654335b2695",
            price: 3411.59,
            price_sources: [],
            processed_ms: 1711213460034,
          },
          {
            leverage: 0.5,
            order_type: "FLAT",
            order_uuid: "658d4867-f194-47a3-8e8a-d7bc025b2c8b",
            price: 3409.45,
            price_sources: [],
            processed_ms: 1711214056375,
          },
        ],
        position_type: "FLAT",
        position_uuid: "6fdaef6b-1f6a-482e-939d-658a16923b3b",
        return_at_close: 0.9920094429747207,
        trade_pair: ["ETHUSD", "ETH/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 3405.7940000000003,
        close_ms: 1711217654873,
        current_return: 1.0019002577766543,
        initial_entry_price: 3402.17,
        is_closed_position: true,
        miner_hotkey: "5GjNQTm5MXBb8FSzrZvPjC6kj5cXZdi6hWgBUGeXhUUKFr29",
        net_leverage: 0.0,
        open_ms: 1711214661222,
        orders: [
          {
            leverage: 0.5,
            order_type: "LONG",
            order_uuid: "9099ac48-a692-4565-8aa3-836363550cad",
            price: 3402.17,
            price_sources: [],
            processed_ms: 1711214661221,
          },
          {
            leverage: 0.5,
            order_type: "LONG",
            order_uuid: "5e88f0e5-bbdf-47e5-aee3-2d14c0086511",
            price: 3413.13,
            price_sources: [],
            processed_ms: 1711215261492,
          },
          {
            leverage: 0.5,
            order_type: "LONG",
            order_uuid: "d9615e0f-d320-470d-aa72-82a7f40b1e15",
            price: 3409.29,
            price_sources: [],
            processed_ms: 1711215859849,
          },
          {
            leverage: 0.5,
            order_type: "LONG",
            order_uuid: "a87c450c-e238-477b-ae79-a501ad24229d",
            price: 3402.25,
            price_sources: [],
            processed_ms: 1711216470222,
          },
          {
            leverage: 0.5,
            order_type: "LONG",
            order_uuid: "38162c30-a817-45c2-a72c-bc7ade021aa7",
            price: 3402.13,
            price_sources: [],
            processed_ms: 1711217059944,
          },
          {
            leverage: 0.5,
            order_type: "FLAT",
            order_uuid: "90652afc-6a19-4883-a86d-70eb9d2847e4",
            price: 3408.38,
            price_sources: [],
            processed_ms: 1711217654873,
          },
        ],
        position_type: "FLAT",
        position_uuid: "4f7a9fdf-8b84-46ea-95a9-4145576784e6",
        return_at_close: 0.996890756487771,
        trade_pair: ["ETHUSD", "ETH/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 3406.264,
        close_ms: 1711221257484,
        current_return: 1.0019983872150133,
        initial_entry_price: 3410.25,
        is_closed_position: true,
        miner_hotkey: "5GjNQTm5MXBb8FSzrZvPjC6kj5cXZdi6hWgBUGeXhUUKFr29",
        net_leverage: 0.0,
        open_ms: 1711218263187,
        orders: [
          {
            leverage: 0.5,
            order_type: "LONG",
            order_uuid: "57ddad48-4ef9-4bf2-a151-3ca6933f1100",
            price: 3410.25,
            price_sources: [],
            processed_ms: 1711218263186,
          },
          {
            leverage: 0.5,
            order_type: "LONG",
            order_uuid: "f150bdce-cb44-4fe7-93d5-9d922fc15257",
            price: 3404.66,
            price_sources: [],
            processed_ms: 1711218860481,
          },
          {
            leverage: 0.5,
            order_type: "LONG",
            order_uuid: "a5d91d1b-fdfb-43f4-8c81-82ccabb04ddc",
            price: 3398.0,
            price_sources: [],
            processed_ms: 1711219457289,
          },
          {
            leverage: 0.5,
            order_type: "LONG",
            order_uuid: "40d51f78-e6cb-4a2a-8932-820aac3f96cb",
            price: 3408.85,
            price_sources: [],
            processed_ms: 1711220064440,
          },
          {
            leverage: 0.5,
            order_type: "LONG",
            order_uuid: "5847f17b-ad07-4158-bef3-8f369fb589a9",
            price: 3409.56,
            price_sources: [],
            processed_ms: 1711220656290,
          },
          {
            leverage: 0.5,
            order_type: "FLAT",
            order_uuid: "d2078a0d-82d4-45df-bafd-451811be18ef",
            price: 3408.99,
            price_sources: [],
            processed_ms: 1711221257484,
          },
        ],
        position_type: "FLAT",
        position_uuid: "1efb6eac-207f-4584-8977-091b4d996318",
        return_at_close: 0.9969883952789382,
        trade_pair: ["ETHUSD", "ETH/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 3396.2760000000003,
        close_ms: 1711224867883,
        current_return: 0.9923010794332608,
        initial_entry_price: 3408.27,
        is_closed_position: true,
        miner_hotkey: "5GjNQTm5MXBb8FSzrZvPjC6kj5cXZdi6hWgBUGeXhUUKFr29",
        net_leverage: 0.0,
        open_ms: 1711221856732,
        orders: [
          {
            leverage: 0.5,
            order_type: "LONG",
            order_uuid: "5bfa6929-9a91-4563-98e9-7a36763e6fa9",
            price: 3408.27,
            price_sources: [],
            processed_ms: 1711221856731,
          },
          {
            leverage: 0.5,
            order_type: "LONG",
            order_uuid: "872cfc9c-0c52-422d-ac06-6f29ac005750",
            price: 3399.75,
            price_sources: [],
            processed_ms: 1711222470177,
          },
          {
            leverage: 0.5,
            order_type: "LONG",
            order_uuid: "82aa1333-a5f3-4575-99ee-76811b794bfb",
            price: 3392.59,
            price_sources: [],
            processed_ms: 1711223063846,
          },
          {
            leverage: 0.5,
            order_type: "LONG",
            order_uuid: "9f41c876-93da-43f6-8958-86cd25fdc302",
            price: 3392.93,
            price_sources: [],
            processed_ms: 1711223661877,
          },
          {
            leverage: 0.5,
            order_type: "LONG",
            order_uuid: "efc12315-21f3-4a12-ae0a-1a5ac9a8028c",
            price: 3387.84,
            price_sources: [],
            processed_ms: 1711224259909,
          },
          {
            leverage: 0.5,
            order_type: "FLAT",
            order_uuid: "154eb325-09ee-4ad3-aa52-211432849148",
            price: 3385.78,
            price_sources: [],
            processed_ms: 1711224867883,
          },
        ],
        position_type: "FLAT",
        position_uuid: "490e44b1-f377-4447-ace6-b4cd159f7cb6",
        return_at_close: 0.9873395740360945,
        trade_pair: ["ETHUSD", "ETH/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 3388.4900000000002,
        close_ms: 1711228463081,
        current_return: 0.992128458830438,
        initial_entry_price: 3388.79,
        is_closed_position: true,
        miner_hotkey: "5GjNQTm5MXBb8FSzrZvPjC6kj5cXZdi6hWgBUGeXhUUKFr29",
        net_leverage: 0.0,
        open_ms: 1711225463773,
        orders: [
          {
            leverage: 0.5,
            order_type: "LONG",
            order_uuid: "f39be541-a646-4192-89b5-885faa70857e",
            price: 3388.79,
            price_sources: [],
            processed_ms: 1711225463772,
          },
          {
            leverage: 0.5,
            order_type: "LONG",
            order_uuid: "e27fcf61-1c38-4225-9857-b12c781fcd76",
            price: 3388.38,
            price_sources: [],
            processed_ms: 1711226082039,
          },
          {
            leverage: 0.5,
            order_type: "LONG",
            order_uuid: "79b28dbd-5dc4-42a1-acf4-814154a9af19",
            price: 3389.56,
            price_sources: [],
            processed_ms: 1711226663765,
          },
          {
            leverage: 0.5,
            order_type: "LONG",
            order_uuid: "f5e5efdd-c136-448a-9161-3dc7d96e8fa0",
            price: 3387.17,
            price_sources: [],
            processed_ms: 1711227260159,
          },
          {
            leverage: 0.5,
            order_type: "LONG",
            order_uuid: "09744a4d-adc0-41e3-b331-e437d2db21cb",
            price: 3388.55,
            price_sources: [],
            processed_ms: 1711227869149,
          },
          {
            leverage: 0.5,
            order_type: "FLAT",
            order_uuid: "36c0d3c2-256b-4c48-afc1-32250c555253",
            price: 3377.82,
            price_sources: [],
            processed_ms: 1711228463081,
          },
        ],
        position_type: "FLAT",
        position_uuid: "16c6cd46-159f-475e-8be6-596fd7110025",
        return_at_close: 0.9871678165362858,
        trade_pair: ["ETHUSD", "ETH/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 3385.772,
        close_ms: 1711232066841,
        current_return: 1.000871567371122,
        initial_entry_price: 3378.97,
        is_closed_position: true,
        miner_hotkey: "5GjNQTm5MXBb8FSzrZvPjC6kj5cXZdi6hWgBUGeXhUUKFr29",
        net_leverage: 0.0,
        open_ms: 1711229070463,
        orders: [
          {
            leverage: 0.5,
            order_type: "LONG",
            order_uuid: "a8100695-aa5b-4640-b0e8-8ba3ca423898",
            price: 3378.97,
            price_sources: [],
            processed_ms: 1711229070462,
          },
          {
            leverage: 0.5,
            order_type: "LONG",
            order_uuid: "93e8388c-dbce-4f9f-a860-889ec1bf0294",
            price: 3377.8,
            price_sources: [],
            processed_ms: 1711229668671,
          },
          {
            leverage: 0.5,
            order_type: "LONG",
            order_uuid: "28b83b59-86af-45a1-828d-1ecea1d273d5",
            price: 3392.05,
            price_sources: [],
            processed_ms: 1711230264100,
          },
          {
            leverage: 0.5,
            order_type: "LONG",
            order_uuid: "1d5712be-1a15-4f00-9201-aceb71fbd6eb",
            price: 3390.9,
            price_sources: [],
            processed_ms: 1711230870234,
          },
          {
            leverage: 0.5,
            order_type: "LONG",
            order_uuid: "62dccbaf-3206-4c1e-847f-00ba5592af45",
            price: 3389.14,
            price_sources: [],
            processed_ms: 1711231464369,
          },
          {
            leverage: 0.5,
            order_type: "FLAT",
            order_uuid: "529a34ea-5f0c-4249-9e54-6e9b7d8ef6a7",
            price: 3386.95,
            price_sources: [],
            processed_ms: 1711232066841,
          },
        ],
        position_type: "FLAT",
        position_uuid: "62e4b3a4-30ff-444c-8f30-c90be43b12a4",
        return_at_close: 0.9958672095342663,
        trade_pair: ["ETHUSD", "ETH/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 3381.3840000000005,
        close_ms: 1711235669863,
        current_return: 0.9941687550729015,
        initial_entry_price: 3375.78,
        is_closed_position: true,
        miner_hotkey: "5GjNQTm5MXBb8FSzrZvPjC6kj5cXZdi6hWgBUGeXhUUKFr29",
        net_leverage: 0.0,
        open_ms: 1711232667527,
        orders: [
          {
            leverage: 0.5,
            order_type: "LONG",
            order_uuid: "bc16e598-252a-4d78-980b-5c6ed4d768bc",
            price: 3375.78,
            price_sources: [],
            processed_ms: 1711232667525,
          },
          {
            leverage: 0.5,
            order_type: "LONG",
            order_uuid: "36724936-0a7a-456d-8142-e1352744d466",
            price: 3387.83,
            price_sources: [],
            processed_ms: 1711233265168,
          },
          {
            leverage: 0.5,
            order_type: "LONG",
            order_uuid: "1f276417-04a2-4033-a211-474adfb9b070",
            price: 3382.18,
            price_sources: [],
            processed_ms: 1711233861793,
          },
          {
            leverage: 0.5,
            order_type: "LONG",
            order_uuid: "e6b91325-f56d-4cd1-822a-ef3eaf198b16",
            price: 3386.61,
            price_sources: [],
            processed_ms: 1711234466673,
          },
          {
            leverage: 0.5,
            order_type: "LONG",
            order_uuid: "fdcbc3a6-6bdd-473e-9dcb-45527040e711",
            price: 3374.52,
            price_sources: [],
            processed_ms: 1711235075749,
          },
          {
            leverage: 0.5,
            order_type: "FLAT",
            order_uuid: "ee842c61-f051-4326-b6d9-c0683e297e11",
            price: 3373.51,
            price_sources: [],
            processed_ms: 1711235669863,
          },
        ],
        position_type: "FLAT",
        position_uuid: "a80d2386-5131-4836-8837-777de66eb111",
        return_at_close: 0.989197911297537,
        trade_pair: ["ETHUSD", "ETH/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 3353.1380000000004,
        close_ms: 1711239268564,
        current_return: 0.9978349710785371,
        initial_entry_price: 3369.47,
        is_closed_position: true,
        miner_hotkey: "5GjNQTm5MXBb8FSzrZvPjC6kj5cXZdi6hWgBUGeXhUUKFr29",
        net_leverage: 0.0,
        open_ms: 1711236271508,
        orders: [
          {
            leverage: 0.5,
            order_type: "LONG",
            order_uuid: "e24d23b2-560f-4568-b6f5-1a3277db0c81",
            price: 3369.47,
            price_sources: [],
            processed_ms: 1711236271507,
          },
          {
            leverage: 0.5,
            order_type: "LONG",
            order_uuid: "4b63c20a-7e45-4a23-bb1c-bba65287720f",
            price: 3356.7,
            price_sources: [],
            processed_ms: 1711236875842,
          },
          {
            leverage: 0.5,
            order_type: "LONG",
            order_uuid: "0cb3ae88-79b1-43f9-9e65-dc18dec4f6d5",
            price: 3360.49,
            price_sources: [],
            processed_ms: 1711237472209,
          },
          {
            leverage: 0.5,
            order_type: "LONG",
            order_uuid: "d4cc3c58-0a10-4d5b-870a-82da1343e74e",
            price: 3344.88,
            price_sources: [],
            processed_ms: 1711238076088,
          },
          {
            leverage: 0.5,
            order_type: "LONG",
            order_uuid: "ee25205c-c2db-4e07-9c9c-024b395542e2",
            price: 3334.15,
            price_sources: [],
            processed_ms: 1711238667326,
          },
          {
            leverage: 0.5,
            order_type: "FLAT",
            order_uuid: "46b9a205-04dd-4509-bec0-4f6920e4a4d9",
            price: 3350.22,
            price_sources: [],
            processed_ms: 1711239268564,
          },
        ],
        position_type: "FLAT",
        position_uuid: "34553707-8a5c-490d-9bdc-8163af6191ea",
        return_at_close: 0.9928457962231444,
        trade_pair: ["ETHUSD", "ETH/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 3353.3779999999997,
        close_ms: 1711242870592,
        current_return: 1.0037457662079874,
        initial_entry_price: 3345.11,
        is_closed_position: true,
        miner_hotkey: "5GjNQTm5MXBb8FSzrZvPjC6kj5cXZdi6hWgBUGeXhUUKFr29",
        net_leverage: 0.0,
        open_ms: 1711239873225,
        orders: [
          {
            leverage: 0.5,
            order_type: "LONG",
            order_uuid: "3c37f4a8-518c-4c84-9ec8-1978b5a0be00",
            price: 3345.11,
            price_sources: [],
            processed_ms: 1711239873224,
          },
          {
            leverage: 0.5,
            order_type: "LONG",
            order_uuid: "4cb30582-c96b-41b9-9d26-8ebeec807ef3",
            price: 3350.29,
            price_sources: [],
            processed_ms: 1711240471212,
          },
          {
            leverage: 0.5,
            order_type: "LONG",
            order_uuid: "6d3e56c2-6249-4026-96c2-0d6b66469a41",
            price: 3355.86,
            price_sources: [],
            processed_ms: 1711241070826,
          },
          {
            leverage: 0.5,
            order_type: "LONG",
            order_uuid: "27510faa-f596-4b65-b5ae-e5170a55886a",
            price: 3358.14,
            price_sources: [],
            processed_ms: 1711241694993,
          },
          {
            leverage: 0.5,
            order_type: "LONG",
            order_uuid: "b8c77843-8c5e-4d26-b39a-c31f984e4e9e",
            price: 3357.49,
            price_sources: [],
            processed_ms: 1711242277961,
          },
          {
            leverage: 0.5,
            order_type: "FLAT",
            order_uuid: "a67a06f2-8cf1-4fab-8ef2-fc0ad078ca47",
            price: 3358.39,
            price_sources: [],
            processed_ms: 1711242870592,
          },
        ],
        position_type: "FLAT",
        position_uuid: "6d6d88ec-7818-47d6-ac6f-89ead671e6f0",
        return_at_close: 0.9987270373769475,
        trade_pair: ["ETHUSD", "ETH/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 3352.4660000000003,
        close_ms: 1711246479113,
        current_return: 0.9987044079980414,
        initial_entry_price: 3349.82,
        is_closed_position: true,
        miner_hotkey: "5GjNQTm5MXBb8FSzrZvPjC6kj5cXZdi6hWgBUGeXhUUKFr29",
        net_leverage: 0.0,
        open_ms: 1711243469330,
        orders: [
          {
            leverage: 0.5,
            order_type: "LONG",
            order_uuid: "540a8df9-1b22-4a51-b8f6-c67cce490bbb",
            price: 3349.82,
            price_sources: [],
            processed_ms: 1711243469329,
          },
          {
            leverage: 0.5,
            order_type: "LONG",
            order_uuid: "2d41c8bd-ece4-40bd-8e80-5e2b092a5d21",
            price: 3360.11,
            price_sources: [],
            processed_ms: 1711244071743,
          },
          {
            leverage: 0.5,
            order_type: "LONG",
            order_uuid: "ece01bf1-4f24-49d7-899d-6e5f6cbaf493",
            price: 3351.61,
            price_sources: [],
            processed_ms: 1711244673991,
          },
          {
            leverage: 0.5,
            order_type: "LONG",
            order_uuid: "677f5303-e7f9-4f6e-99f6-9ddaee5b506a",
            price: 3354.15,
            price_sources: [],
            processed_ms: 1711245275150,
          },
          {
            leverage: 0.5,
            order_type: "LONG",
            order_uuid: "3c830332-c681-4c9e-bfcc-c96ea8c9504f",
            price: 3346.64,
            price_sources: [],
            processed_ms: 1711245870193,
          },
          {
            leverage: 0.5,
            order_type: "FLAT",
            order_uuid: "e72bba4e-14f3-4e8c-bc99-d2c6785a0490",
            price: 3350.73,
            price_sources: [],
            processed_ms: 1711246479113,
          },
        ],
        position_type: "FLAT",
        position_uuid: "d5a7128c-c586-4eee-aa2f-bf10e4cd8277",
        return_at_close: 0.9937108859580511,
        trade_pair: ["ETHUSD", "ETH/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 3342.6440000000002,
        close_ms: 1711250076667,
        current_return: 0.9771111649425648,
        initial_entry_price: 3343.77,
        is_closed_position: true,
        miner_hotkey: "5GjNQTm5MXBb8FSzrZvPjC6kj5cXZdi6hWgBUGeXhUUKFr29",
        net_leverage: 0.0,
        open_ms: 1711247069384,
        orders: [
          {
            leverage: 0.5,
            order_type: "LONG",
            order_uuid: "53e92469-30cd-4ead-a572-54276f3d6c2c",
            price: 3343.77,
            price_sources: [],
            processed_ms: 1711247069382,
          },
          {
            leverage: 0.5,
            order_type: "LONG",
            order_uuid: "7ace641e-3afc-4638-b2c8-105f13a156aa",
            price: 3340.77,
            price_sources: [],
            processed_ms: 1711247676054,
          },
          {
            leverage: 0.5,
            order_type: "LONG",
            order_uuid: "a79b6bb3-f0ca-4d46-9f27-45e6cedfa3da",
            price: 3344.94,
            price_sources: [],
            processed_ms: 1711248268129,
          },
          {
            leverage: 0.5,
            order_type: "LONG",
            order_uuid: "61375fe5-92d8-46ae-a0a3-233075197aa0",
            price: 3344.78,
            price_sources: [],
            processed_ms: 1711248875621,
          },
          {
            leverage: 0.5,
            order_type: "LONG",
            order_uuid: "77d49940-868f-45a9-a0b5-820ed1919b5e",
            price: 3338.96,
            price_sources: [],
            processed_ms: 1711249469087,
          },
          {
            leverage: 0.5,
            order_type: "FLAT",
            order_uuid: "2f93b3de-e10c-4960-8007-b60e5596da01",
            price: 3312.03,
            price_sources: [],
            processed_ms: 1711250076667,
          },
        ],
        position_type: "FLAT",
        position_uuid: "9b9a6eb4-8de5-4495-8626-f97d868b715d",
        return_at_close: 0.9722256091178519,
        trade_pair: ["ETHUSD", "ETH/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 3318.6980000000003,
        close_ms: 1711253671267,
        current_return: 0.9982112338725205,
        initial_entry_price: 3323.52,
        is_closed_position: true,
        miner_hotkey: "5GjNQTm5MXBb8FSzrZvPjC6kj5cXZdi6hWgBUGeXhUUKFr29",
        net_leverage: 0.0,
        open_ms: 1711250681007,
        orders: [
          {
            leverage: 0.5,
            order_type: "LONG",
            order_uuid: "a4db2fbc-aa66-4fbc-83da-811ba03b3f42",
            price: 3323.52,
            price_sources: [],
            processed_ms: 1711250681006,
          },
          {
            leverage: 0.5,
            order_type: "LONG",
            order_uuid: "2d0f2e90-a263-4509-99ee-398e00b3aafe",
            price: 3319.29,
            price_sources: [],
            processed_ms: 1711251271365,
          },
          {
            leverage: 0.5,
            order_type: "LONG",
            order_uuid: "40a31310-2d82-4a58-a216-dfa27c6f264a",
            price: 3317.91,
            price_sources: [],
            processed_ms: 1711251869539,
          },
          {
            leverage: 0.5,
            order_type: "LONG",
            order_uuid: "3a25cf34-7c09-4ac2-8e44-cf85cf24444c",
            price: 3320.12,
            price_sources: [],
            processed_ms: 1711252469662,
          },
          {
            leverage: 0.5,
            order_type: "LONG",
            order_uuid: "c1479d83-8d79-4f59-9a54-d6e2fb4884a8",
            price: 3312.65,
            price_sources: [],
            processed_ms: 1711253073580,
          },
          {
            leverage: 0.5,
            order_type: "FLAT",
            order_uuid: "50422245-18d0-4082-a0af-27f2db249162",
            price: 3316.32,
            price_sources: [],
            processed_ms: 1711253671267,
          },
        ],
        position_type: "FLAT",
        position_uuid: "d1870bc7-a531-4068-9b0e-5b248d668288",
        return_at_close: 0.993220177703158,
        trade_pair: ["ETHUSD", "ETH/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 3314.5699999999997,
        close_ms: 1711257279293,
        current_return: 1.002386764634795,
        initial_entry_price: 3309.92,
        is_closed_position: true,
        miner_hotkey: "5GjNQTm5MXBb8FSzrZvPjC6kj5cXZdi6hWgBUGeXhUUKFr29",
        net_leverage: 0.0,
        open_ms: 1711254277415,
        orders: [
          {
            leverage: 0.5,
            order_type: "LONG",
            order_uuid: "24691e95-046d-4926-bec3-164b5f0a0731",
            price: 3309.92,
            price_sources: [],
            processed_ms: 1711254277413,
          },
          {
            leverage: 0.5,
            order_type: "LONG",
            order_uuid: "bbd8d26d-10d1-492a-a9af-f9ad623af3a7",
            price: 3305.35,
            price_sources: [],
            processed_ms: 1711254878312,
          },
          {
            leverage: 0.5,
            order_type: "LONG",
            order_uuid: "89ff8ede-d75b-4aa0-a6a2-6cf53011f0c9",
            price: 3317.67,
            price_sources: [],
            processed_ms: 1711255476766,
          },
          {
            leverage: 0.5,
            order_type: "LONG",
            order_uuid: "e6e37223-fbdc-4824-828d-8b6f3c0d9dc7",
            price: 3317.48,
            price_sources: [],
            processed_ms: 1711256073111,
          },
          {
            leverage: 0.5,
            order_type: "LONG",
            order_uuid: "18197379-7dbc-4cb4-b2c5-f85b59e246f6",
            price: 3322.43,
            price_sources: [],
            processed_ms: 1711256671976,
          },
          {
            leverage: 0.5,
            order_type: "FLAT",
            order_uuid: "54e89ce2-400f-40f1-8c64-5b72c82b19a5",
            price: 3317.73,
            price_sources: [],
            processed_ms: 1711257279293,
          },
        ],
        position_type: "FLAT",
        position_uuid: "56ad58f8-fa0f-4d8a-b86e-466302154738",
        return_at_close: 0.997374830811621,
        trade_pair: ["ETHUSD", "ETH/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 3329.022,
        close_ms: 1711260876002,
        current_return: 0.9980070699419255,
        initial_entry_price: 3326.76,
        is_closed_position: true,
        miner_hotkey: "5GjNQTm5MXBb8FSzrZvPjC6kj5cXZdi6hWgBUGeXhUUKFr29",
        net_leverage: 0.0,
        open_ms: 1711257877350,
        orders: [
          {
            leverage: 0.5,
            order_type: "LONG",
            order_uuid: "a92a0be1-d71c-4093-b31f-3cfe83428665",
            price: 3326.76,
            price_sources: [],
            processed_ms: 1711257877348,
          },
          {
            leverage: 0.5,
            order_type: "LONG",
            order_uuid: "755a35a9-c995-4c81-bb87-22bd843bf25b",
            price: 3332.03,
            price_sources: [],
            processed_ms: 1711258479960,
          },
          {
            leverage: 0.5,
            order_type: "LONG",
            order_uuid: "92b51fbf-c13d-43e4-9eb2-a27f03de648a",
            price: 3331.71,
            price_sources: [],
            processed_ms: 1711259080307,
          },
          {
            leverage: 0.5,
            order_type: "LONG",
            order_uuid: "93afc3b6-c915-4ec2-a9ee-e092f4ad19d9",
            price: 3332.51,
            price_sources: [],
            processed_ms: 1711259694894,
          },
          {
            leverage: 0.5,
            order_type: "LONG",
            order_uuid: "890a30ec-5752-4d2e-abbb-bce5d60843bf",
            price: 3322.1,
            price_sources: [],
            processed_ms: 1711260291413,
          },
          {
            leverage: 0.5,
            order_type: "FLAT",
            order_uuid: "a5d6154c-a69c-43cd-af9f-2eda0315b5d1",
            price: 3326.37,
            price_sources: [],
            processed_ms: 1711260876002,
          },
        ],
        position_type: "FLAT",
        position_uuid: "f2bc604c-c35e-435a-9aab-d80ac7ff7640",
        return_at_close: 0.9930170345922158,
        trade_pair: ["ETHUSD", "ETH/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 3323.9800000000005,
        close_ms: 1711264482981,
        current_return: 1.0027269449202196,
        initial_entry_price: 3327.9,
        is_closed_position: true,
        miner_hotkey: "5GjNQTm5MXBb8FSzrZvPjC6kj5cXZdi6hWgBUGeXhUUKFr29",
        net_leverage: 0.0,
        open_ms: 1711261478837,
        orders: [
          {
            leverage: 0.5,
            order_type: "LONG",
            order_uuid: "8d1f8f4b-089f-47d8-8d04-065445929235",
            price: 3327.9,
            price_sources: [],
            processed_ms: 1711261478835,
          },
          {
            leverage: 0.5,
            order_type: "LONG",
            order_uuid: "56e40923-9752-49fa-b488-db159115f430",
            price: 3322.82,
            price_sources: [],
            processed_ms: 1711262095885,
          },
          {
            leverage: 0.5,
            order_type: "LONG",
            order_uuid: "352977b1-26c8-4058-86b3-b52a5a5630d8",
            price: 3320.34,
            price_sources: [],
            processed_ms: 1711262680479,
          },
          {
            leverage: 0.5,
            order_type: "LONG",
            order_uuid: "10094520-80c4-4147-87d2-bb85dbd64f7b",
            price: 3325.07,
            price_sources: [],
            processed_ms: 1711263278437,
          },
          {
            leverage: 0.5,
            order_type: "LONG",
            order_uuid: "e7b19ad3-04b0-4455-8960-815d30fcfbaa",
            price: 3323.77,
            price_sources: [],
            processed_ms: 1711263874614,
          },
          {
            leverage: 0.5,
            order_type: "FLAT",
            order_uuid: "dd5a3cb4-30af-49a4-b01f-1bb5b554548a",
            price: 3327.61,
            price_sources: [],
            processed_ms: 1711264482981,
          },
        ],
        position_type: "FLAT",
        position_uuid: "76913002-d169-4eb5-943b-2358e0f3b7ec",
        return_at_close: 0.9977133101956185,
        trade_pair: ["ETHUSD", "ETH/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 3327.868,
        close_ms: 1711268080228,
        current_return: 1.0178448970752776,
        initial_entry_price: 3324.76,
        is_closed_position: true,
        miner_hotkey: "5GjNQTm5MXBb8FSzrZvPjC6kj5cXZdi6hWgBUGeXhUUKFr29",
        net_leverage: 0.0,
        open_ms: 1711265076828,
        orders: [
          {
            leverage: 0.5,
            order_type: "LONG",
            order_uuid: "7b52be01-4441-44c3-8da0-477778bf074e",
            price: 3324.76,
            price_sources: [],
            processed_ms: 1711265076826,
          },
          {
            leverage: 0.5,
            order_type: "LONG",
            order_uuid: "af371264-8688-4581-a27c-34cd7c021421",
            price: 3321.51,
            price_sources: [],
            processed_ms: 1711265682643,
          },
          {
            leverage: 0.5,
            order_type: "LONG",
            order_uuid: "50d5be22-d186-463b-990a-8f7c0b88068d",
            price: 3320.83,
            price_sources: [],
            processed_ms: 1711266275821,
          },
          {
            leverage: 0.5,
            order_type: "LONG",
            order_uuid: "55d566d9-147a-48fd-b3a4-3189bfd46829",
            price: 3335.49,
            price_sources: [],
            processed_ms: 1711266884541,
          },
          {
            leverage: 0.5,
            order_type: "LONG",
            order_uuid: "ea46cf44-2373-40d9-987d-86dccb10e507",
            price: 3336.75,
            price_sources: [],
            processed_ms: 1711267486265,
          },
          {
            leverage: 0.5,
            order_type: "FLAT",
            order_uuid: "cad1377e-7114-4f54-b26f-1d326bc9a570",
            price: 3351.6,
            price_sources: [],
            processed_ms: 1711268080228,
          },
        ],
        position_type: "FLAT",
        position_uuid: "e7de97fd-4277-448d-bc59-3b41bdc68ace",
        return_at_close: 1.0127556725899012,
        trade_pair: ["ETHUSD", "ETH/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 3350.79,
        close_ms: 1711272933840,
        current_return: 1.0022845358855672,
        initial_entry_price: 3350.79,
        is_closed_position: true,
        miner_hotkey: "5GjNQTm5MXBb8FSzrZvPjC6kj5cXZdi6hWgBUGeXhUUKFr29",
        net_leverage: 0.0,
        open_ms: 1711268682270,
        orders: [
          {
            leverage: 0.5,
            order_type: "LONG",
            order_uuid: "b90f3fca-6259-4bd4-b1c2-860ac3b778b5",
            price: 3350.79,
            price_sources: [],
            processed_ms: 1711268682268,
          },
          {
            leverage: 19.0,
            order_type: "FLAT",
            order_uuid: "7c0d804c-3093-479b-8444-62b84559eed0",
            price: 3366.1,
            price_sources: [],
            processed_ms: 1711272933840,
          },
        ],
        position_type: "FLAT",
        position_uuid: "1e8b2d71-0e82-4ca2-b4de-4467509acde9",
        return_at_close: 1.0012822513496817,
        trade_pair: ["ETHUSD", "ETH/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 64977.5555,
        close_ms: 1711274796645,
        current_return: 1.0055214493639208,
        initial_entry_price: 65002.86,
        is_closed_position: true,
        miner_hotkey: "5GjNQTm5MXBb8FSzrZvPjC6kj5cXZdi6hWgBUGeXhUUKFr29",
        net_leverage: 0.0,
        open_ms: 1711273270827,
        orders: [
          {
            leverage: -9.0,
            order_type: "SHORT",
            order_uuid: "280b1fbc-ca5e-4df2-8e3d-530c2dc94e8e",
            price: 65002.86,
            price_sources: [],
            processed_ms: 1711273270824,
          },
          {
            leverage: -9.0,
            order_type: "SHORT",
            order_uuid: "c34fb2e0-5319-4873-8622-53f0017eb214",
            price: 64945.37,
            price_sources: [],
            processed_ms: 1711273415919,
          },
          {
            leverage: -2.0,
            order_type: "SHORT",
            order_uuid: "8756d245-4ad5-4f75-ac3f-8e3d34128b71",
            price: 65008.52,
            price_sources: [],
            processed_ms: 1711273962117,
          },
          {
            leverage: 2.0,
            order_type: "FLAT",
            order_uuid: "448b2b41-e4d6-4ee9-8502-c00e4dfd9d55",
            price: 64959.61,
            price_sources: [],
            processed_ms: 1711274796645,
          },
        ],
        position_type: "FLAT",
        position_uuid: "29163865-a05f-46bb-b7e7-3b0c2b41b412",
        return_at_close: 0.965300591389364,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 64937.67,
        close_ms: 1711275581556,
        current_return: 0.975530843653613,
        initial_entry_price: 64937.67,
        is_closed_position: true,
        miner_hotkey: "5GjNQTm5MXBb8FSzrZvPjC6kj5cXZdi6hWgBUGeXhUUKFr29",
        net_leverage: 0.0,
        open_ms: 1711275220438,
        orders: [
          {
            leverage: -19.0,
            order_type: "SHORT",
            order_uuid: "e6b1bbe8-095a-4c12-aaee-b7c4124cd528",
            price: 64937.67,
            price_sources: [],
            processed_ms: 1711275220435,
          },
          {
            leverage: 19.0,
            order_type: "FLAT",
            order_uuid: "21397df3-280e-43ef-a5a7-f44f5e8c3f0c",
            price: 65021.3,
            price_sources: [],
            processed_ms: 1711275581556,
          },
        ],
        position_type: "FLAT",
        position_uuid: "f7b2b412-d206-4329-b511-96ad863b11b2",
        return_at_close: 0.9384606715947756,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 65102.13,
        close_ms: 1711277043457,
        current_return: 0.9819374573458658,
        initial_entry_price: 65102.13,
        is_closed_position: true,
        miner_hotkey: "5GjNQTm5MXBb8FSzrZvPjC6kj5cXZdi6hWgBUGeXhUUKFr29",
        net_leverage: 0.0,
        open_ms: 1711276011216,
        orders: [
          {
            leverage: -19.0,
            order_type: "SHORT",
            order_uuid: "9794d883-1d36-42a7-ab88-67abc76be228",
            price: 65102.13,
            price_sources: [],
            processed_ms: 1711276011214,
          },
          {
            leverage: 19.0,
            order_type: "FLAT",
            order_uuid: "6f1a153a-003f-4afc-b63f-d12dca0d22d6",
            price: 65164.02,
            price_sources: [],
            processed_ms: 1711277043457,
          },
        ],
        position_type: "FLAT",
        position_uuid: "ca9f16b5-2a77-4ce0-ab3a-08d4c8ab2f32",
        return_at_close: 0.9446238339667229,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
    ],
    thirty_day_returns: 1.0,
  },
  "5Gmgq2Y8mLqDzBLeXtQ9votYjtLJTMFtHdgZMCYuNna2KQts": {
    all_time_returns: 1.0,
    n_positions: 0,
    percentage_profitable: 0.0,
    positions: [
      {
        average_entry_price: 3411.562,
        close_ms: 1711207719079,
        current_return: 0.9794518624272994,
        initial_entry_price: 3418.12,
        is_closed_position: true,
        miner_hotkey: "5Gmgq2Y8mLqDzBLeXtQ9votYjtLJTMFtHdgZMCYuNna2KQts",
        net_leverage: 0.0,
        open_ms: 1711206576161,
        orders: [
          {
            leverage: 0.6,
            order_type: "LONG",
            order_uuid: "6abba313-fa92-4bd0-bbac-6f42439a83b0",
            price: 3418.12,
            price_sources: [],
            processed_ms: 1711206576150,
          },
          {
            leverage: 0.6,
            order_type: "LONG",
            order_uuid: "637c3da6-69b4-4c4e-9b8c-8e764babe9c9",
            price: 3418.12,
            price_sources: [],
            processed_ms: 1711206612995,
          },
          {
            leverage: 0.6,
            order_type: "LONG",
            order_uuid: "09f883b9-e1d0-4cda-8ae6-57618f0078f7",
            price: 3409.08,
            price_sources: [],
            processed_ms: 1711206826949,
          },
          {
            leverage: 0.6,
            order_type: "LONG",
            order_uuid: "f7e5eb12-128b-4e80-9f99-92f8d71cce31",
            price: 3407.88,
            price_sources: [],
            processed_ms: 1711207116611,
          },
          {
            leverage: 0.6,
            order_type: "LONG",
            order_uuid: "9055d78b-6211-43ce-a4ed-e8a21b9cab23",
            price: 3404.61,
            price_sources: [],
            processed_ms: 1711207418447,
          },
          {
            leverage: 0.6,
            order_type: "FLAT",
            order_uuid: "6e676e66-b7dc-4552-9abd-8f11b21e731f",
            price: 3388.15,
            price_sources: [],
            processed_ms: 1711207719079,
          },
        ],
        position_type: "FLAT",
        position_uuid: "d32789b5-b60d-4446-8305-783c4e5772d2",
        return_at_close: 0.9735751512527356,
        trade_pair: ["ETHUSD", "ETH/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 3392.544,
        close_ms: 1711209520225,
        current_return: 1.0120056013140637,
        initial_entry_price: 3384.92,
        is_closed_position: true,
        miner_hotkey: "5Gmgq2Y8mLqDzBLeXtQ9votYjtLJTMFtHdgZMCYuNna2KQts",
        net_leverage: 0.0,
        open_ms: 1711208019316,
        orders: [
          {
            leverage: 0.6,
            order_type: "LONG",
            order_uuid: "32c5787b-e291-46b5-84f0-2e69708fb1cf",
            price: 3384.92,
            price_sources: [],
            processed_ms: 1711208019316,
          },
          {
            leverage: 0.6,
            order_type: "LONG",
            order_uuid: "ed64f845-cfe7-424d-8071-4a6508e3b3bd",
            price: 3394.69,
            price_sources: [],
            processed_ms: 1711208319197,
          },
          {
            leverage: 0.6,
            order_type: "LONG",
            order_uuid: "7f06d5c5-ac03-4798-ad02-e56bfd27e42a",
            price: 3393.16,
            price_sources: [],
            processed_ms: 1711208622163,
          },
          {
            leverage: 0.6,
            order_type: "LONG",
            order_uuid: "6b3a2408-fe2a-4a97-9fee-7a0d34663df8",
            price: 3394.94,
            price_sources: [],
            processed_ms: 1711208917157,
          },
          {
            leverage: 0.6,
            order_type: "LONG",
            order_uuid: "976bfc9b-f2b4-4100-818e-e4a27564e7da",
            price: 3395.01,
            price_sources: [],
            processed_ms: 1711209218738,
          },
          {
            leverage: 0.6,
            order_type: "FLAT",
            order_uuid: "ab944ad6-fe82-4930-a669-a2be1765da1a",
            price: 3406.09,
            price_sources: [],
            processed_ms: 1711209520225,
          },
        ],
        position_type: "FLAT",
        position_uuid: "ef104570-dea9-4ab1-a654-6419cfcbc669",
        return_at_close: 1.0059335677061794,
        trade_pair: ["ETHUSD", "ETH/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 3393.788,
        close_ms: 1711211317486,
        current_return: 1.0070636898764387,
        initial_entry_price: 3394.26,
        is_closed_position: true,
        miner_hotkey: "5Gmgq2Y8mLqDzBLeXtQ9votYjtLJTMFtHdgZMCYuNna2KQts",
        net_leverage: 0.0,
        open_ms: 1711209820428,
        orders: [
          {
            leverage: 0.6,
            order_type: "LONG",
            order_uuid: "08d4baad-5f2e-4df1-839f-7f083c8d7ec5",
            price: 3394.26,
            price_sources: [],
            processed_ms: 1711209820428,
          },
          {
            leverage: 0.6,
            order_type: "LONG",
            order_uuid: "99228939-83e4-4a7b-9a90-f5a36b0afdf2",
            price: 3386.89,
            price_sources: [],
            processed_ms: 1711210120343,
          },
          {
            leverage: 0.6,
            order_type: "LONG",
            order_uuid: "deebc859-dfc2-4c96-8648-53bb7a88d928",
            price: 3389.99,
            price_sources: [],
            processed_ms: 1711210421415,
          },
          {
            leverage: 0.6,
            order_type: "LONG",
            order_uuid: "08de52b9-13dd-494d-83cc-6fb4db2255a3",
            price: 3398.9,
            price_sources: [],
            processed_ms: 1711210722454,
          },
          {
            leverage: 0.6,
            order_type: "LONG",
            order_uuid: "0a2532f7-f6ca-45d2-a8b7-e143f0f14522",
            price: 3398.9,
            price_sources: [],
            processed_ms: 1711211017583,
          },
          {
            leverage: 0.6,
            order_type: "FLAT",
            order_uuid: "c4e0988f-afef-4352-a38d-a4dd38b9b16a",
            price: 3401.78,
            price_sources: [],
            processed_ms: 1711211317486,
          },
        ],
        position_type: "FLAT",
        position_uuid: "edc7c626-5bc0-4399-88e3-50f6ba456c19",
        return_at_close: 1.0010213077371801,
        trade_pair: ["ETHUSD", "ETH/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 3419.3160000000003,
        close_ms: 1711213118800,
        current_return: 1.002787625775438,
        initial_entry_price: 3415.81,
        is_closed_position: true,
        miner_hotkey: "5Gmgq2Y8mLqDzBLeXtQ9votYjtLJTMFtHdgZMCYuNna2KQts",
        net_leverage: 0.0,
        open_ms: 1711211620344,
        orders: [
          {
            leverage: 0.6,
            order_type: "LONG",
            order_uuid: "d80415cf-cba6-45bf-a942-f06dc89c3e5c",
            price: 3415.81,
            price_sources: [],
            processed_ms: 1711211620343,
          },
          {
            leverage: 0.6,
            order_type: "LONG",
            order_uuid: "1d9236cc-1cc0-4234-b20c-fdcaade64ae7",
            price: 3416.56,
            price_sources: [],
            processed_ms: 1711211923018,
          },
          {
            leverage: 0.6,
            order_type: "LONG",
            order_uuid: "f96a05a6-f2ff-422f-83f6-611419033b78",
            price: 3415.33,
            price_sources: [],
            processed_ms: 1711212225541,
          },
          {
            leverage: 0.6,
            order_type: "LONG",
            order_uuid: "a0917096-c132-484f-b920-134b5a50b19e",
            price: 3424.44,
            price_sources: [],
            processed_ms: 1711212519037,
          },
          {
            leverage: 0.6,
            order_type: "LONG",
            order_uuid: "17ffa54f-081e-4753-a352-2eb988f3cfd3",
            price: 3424.44,
            price_sources: [],
            processed_ms: 1711212822713,
          },
          {
            leverage: 0.6,
            order_type: "FLAT",
            order_uuid: "f69b101e-b29f-40a9-81cf-f6329c1d427b",
            price: 3422.49,
            price_sources: [],
            processed_ms: 1711213118800,
          },
        ],
        position_type: "FLAT",
        position_uuid: "1855f7fd-43f5-4c09-a1a5-75603a748a51",
        return_at_close: 0.9967709000207854,
        trade_pair: ["ETHUSD", "ETH/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 3406.1259999999997,
        close_ms: 1711214925725,
        current_return: 0.9971280253488846,
        initial_entry_price: 3411.59,
        is_closed_position: true,
        miner_hotkey: "5Gmgq2Y8mLqDzBLeXtQ9votYjtLJTMFtHdgZMCYuNna2KQts",
        net_leverage: 0.0,
        open_ms: 1711213437833,
        orders: [
          {
            leverage: 0.6,
            order_type: "LONG",
            order_uuid: "c9fd53e1-50fd-4c96-a3cf-842d6146e27d",
            price: 3411.59,
            price_sources: [],
            processed_ms: 1711213437832,
          },
          {
            leverage: 0.6,
            order_type: "LONG",
            order_uuid: "8fa54694-14ab-4680-bf5e-08ebb30705f7",
            price: 3405.46,
            price_sources: [],
            processed_ms: 1711213717657,
          },
          {
            leverage: 0.6,
            order_type: "LONG",
            order_uuid: "f896e9af-41e7-4774-9274-bc6d8249f370",
            price: 3409.45,
            price_sources: [],
            processed_ms: 1711214026848,
          },
          {
            leverage: 0.6,
            order_type: "LONG",
            order_uuid: "456282b3-9abe-408d-958c-1b377f0640b0",
            price: 3401.96,
            price_sources: [],
            processed_ms: 1711214322149,
          },
          {
            leverage: 0.6,
            order_type: "LONG",
            order_uuid: "c06595c4-c5c8-4eef-87f4-cadc5a750eaf",
            price: 3402.17,
            price_sources: [],
            processed_ms: 1711214619640,
          },
          {
            leverage: 0.6,
            order_type: "FLAT",
            order_uuid: "8e379e7f-8811-4225-b581-a74a86442bfa",
            price: 3402.86,
            price_sources: [],
            processed_ms: 1711214925725,
          },
        ],
        position_type: "FLAT",
        position_uuid: "be989161-d4a6-43f6-bea4-44684050b28c",
        return_at_close: 0.9911452571967913,
        trade_pair: ["ETHUSD", "ETH/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 3408.386,
        close_ms: 1711216731950,
        current_return: 0.9936666823131807,
        initial_entry_price: 3408.64,
        is_closed_position: true,
        miner_hotkey: "5Gmgq2Y8mLqDzBLeXtQ9votYjtLJTMFtHdgZMCYuNna2KQts",
        net_leverage: 0.0,
        open_ms: 1711215223148,
        orders: [
          {
            leverage: 0.6,
            order_type: "LONG",
            order_uuid: "4e8e269c-6f90-4be4-8db4-71927b6f5646",
            price: 3408.64,
            price_sources: [],
            processed_ms: 1711215223147,
          },
          {
            leverage: 0.6,
            order_type: "LONG",
            order_uuid: "06708ebd-2aff-4ad8-88ec-c14af98e72a5",
            price: 3416.75,
            price_sources: [],
            processed_ms: 1711215520427,
          },
          {
            leverage: 0.6,
            order_type: "LONG",
            order_uuid: "88536bbe-abb0-4dfb-9273-4f0c99cbd8a2",
            price: 3409.29,
            price_sources: [],
            processed_ms: 1711215819169,
          },
          {
            leverage: 0.6,
            order_type: "LONG",
            order_uuid: "5b0351f2-5b36-48fb-b6f4-9ab195a6b4fc",
            price: 3405.0,
            price_sources: [],
            processed_ms: 1711216126149,
          },
          {
            leverage: 0.6,
            order_type: "LONG",
            order_uuid: "02faa984-12d8-4ecc-b8c5-fc26ccff3ef7",
            price: 3402.25,
            price_sources: [],
            processed_ms: 1711216424069,
          },
          {
            leverage: 0.6,
            order_type: "FLAT",
            order_uuid: "ee4aed4d-8300-42f0-a1e7-2cab571dfb1d",
            price: 3401.19,
            price_sources: [],
            processed_ms: 1711216731950,
          },
        ],
        position_type: "FLAT",
        position_uuid: "f5018e47-4411-402b-8864-fdd31a46c833",
        return_at_close: 0.9877046822193016,
        trade_pair: ["ETHUSD", "ETH/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 3407.0519999999997,
        close_ms: 1711218524435,
        current_return: 0.9983757234438428,
        initial_entry_price: 3402.13,
        is_closed_position: true,
        miner_hotkey: "5Gmgq2Y8mLqDzBLeXtQ9votYjtLJTMFtHdgZMCYuNna2KQts",
        net_leverage: 0.0,
        open_ms: 1711217023453,
        orders: [
          {
            leverage: 0.6,
            order_type: "LONG",
            order_uuid: "238a9ce1-57c8-4f3e-894c-928b59045ab0",
            price: 3402.13,
            price_sources: [],
            processed_ms: 1711217023025,
          },
          {
            leverage: 0.6,
            order_type: "LONG",
            order_uuid: "0b6f0b5d-44cf-4c67-b208-331f77e16572",
            price: 3406.12,
            price_sources: [],
            processed_ms: 1711217329738,
          },
          {
            leverage: 0.6,
            order_type: "LONG",
            order_uuid: "f0ba7770-edb0-4511-87ec-ca7b7769a32f",
            price: 3408.38,
            price_sources: [],
            processed_ms: 1711217627173,
          },
          {
            leverage: 0.6,
            order_type: "LONG",
            order_uuid: "8b123a7c-7323-43e8-ab1d-f1b956b45107",
            price: 3408.38,
            price_sources: [],
            processed_ms: 1711217926999,
          },
          {
            leverage: 0.6,
            order_type: "LONG",
            order_uuid: "1a866cff-2697-431e-9b0c-5b516a066db0",
            price: 3410.25,
            price_sources: [],
            processed_ms: 1711218226810,
          },
          {
            leverage: 0.6,
            order_type: "FLAT",
            order_uuid: "2dc53f6f-6803-4361-bde0-6b135db8e778",
            price: 3405.21,
            price_sources: [],
            processed_ms: 1711218524435,
          },
        ],
        position_type: "FLAT",
        position_uuid: "17df067b-8514-41ad-9de7-f911d66f3810",
        return_at_close: 0.9923854691031798,
        trade_pair: ["ETHUSD", "ETH/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 3403.476,
        close_ms: 1711220322987,
        current_return: 1.0043387592299966,
        initial_entry_price: 3404.66,
        is_closed_position: true,
        miner_hotkey: "5Gmgq2Y8mLqDzBLeXtQ9votYjtLJTMFtHdgZMCYuNna2KQts",
        net_leverage: 0.0,
        open_ms: 1711218825003,
        orders: [
          {
            leverage: 0.6,
            order_type: "LONG",
            order_uuid: "8ec8c65e-68ec-4b06-8344-2ff0fc2b35f5",
            price: 3404.66,
            price_sources: [],
            processed_ms: 1711218825002,
          },
          {
            leverage: 0.6,
            order_type: "LONG",
            order_uuid: "9a56e8d2-6ae0-49a3-a1df-b137a139b17b",
            price: 3401.15,
            price_sources: [],
            processed_ms: 1711219125143,
          },
          {
            leverage: 0.6,
            order_type: "LONG",
            order_uuid: "d6e95885-30dd-4fbb-b687-805b07918912",
            price: 3398.0,
            price_sources: [],
            processed_ms: 1711219424621,
          },
          {
            leverage: 0.6,
            order_type: "LONG",
            order_uuid: "668a7c13-a97f-498b-ad1b-a411ac463f42",
            price: 3404.72,
            price_sources: [],
            processed_ms: 1711219744730,
          },
          {
            leverage: 0.6,
            order_type: "LONG",
            order_uuid: "f7f96a0a-b8c5-4c29-8ec7-470f1de4ffd8",
            price: 3408.85,
            price_sources: [],
            processed_ms: 1711220025981,
          },
          {
            leverage: 0.6,
            order_type: "FLAT",
            order_uuid: "08cb0f8d-43e0-4b60-ad44-93f1f862c733",
            price: 3408.4,
            price_sources: [],
            processed_ms: 1711220322987,
          },
        ],
        position_type: "FLAT",
        position_uuid: "99d04fa7-524b-4720-8fa0-a4a746747db1",
        return_at_close: 0.9983127266746166,
        trade_pair: ["ETHUSD", "ETH/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 3408.2419999999997,
        close_ms: 1711222128446,
        current_return: 0.9925280681378245,
        initial_entry_price: 3409.56,
        is_closed_position: true,
        miner_hotkey: "5Gmgq2Y8mLqDzBLeXtQ9votYjtLJTMFtHdgZMCYuNna2KQts",
        net_leverage: 0.0,
        open_ms: 1711220631588,
        orders: [
          {
            leverage: 0.6,
            order_type: "LONG",
            order_uuid: "97f2e96b-3714-4dfd-a729-82d888fe180b",
            price: 3409.56,
            price_sources: [],
            processed_ms: 1711220631587,
          },
          {
            leverage: 0.6,
            order_type: "LONG",
            order_uuid: "49066006-db0d-45a8-bd98-2509e29c7a35",
            price: 3405.4,
            price_sources: [],
            processed_ms: 1711220931340,
          },
          {
            leverage: 0.6,
            order_type: "LONG",
            order_uuid: "9e3588af-5c74-4063-b969-05510f41def3",
            price: 3408.99,
            price_sources: [],
            processed_ms: 1711221229196,
          },
          {
            leverage: 0.6,
            order_type: "LONG",
            order_uuid: "68644d49-cf84-4431-9e24-902c326045f2",
            price: 3408.99,
            price_sources: [],
            processed_ms: 1711221527078,
          },
          {
            leverage: 0.6,
            order_type: "LONG",
            order_uuid: "79ea3c20-5b79-477b-9047-67eb72c15f34",
            price: 3408.27,
            price_sources: [],
            processed_ms: 1711221826843,
          },
          {
            leverage: 0.6,
            order_type: "FLAT",
            order_uuid: "ac4214cb-24a1-42c5-887d-9b8f1aa9bc06",
            price: 3399.75,
            price_sources: [],
            processed_ms: 1711222128446,
          },
        ],
        position_type: "FLAT",
        position_uuid: "e0d36d8e-87dd-4b96-bf51-ad88a6ce3563",
        return_at_close: 0.9865728997289975,
        trade_pair: ["ETHUSD", "ETH/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 3392.99,
        close_ms: 1711223930803,
        current_return: 0.9954202514890804,
        initial_entry_price: 3399.75,
        is_closed_position: true,
        miner_hotkey: "5Gmgq2Y8mLqDzBLeXtQ9votYjtLJTMFtHdgZMCYuNna2KQts",
        net_leverage: 0.0,
        open_ms: 1711222436143,
        orders: [
          {
            leverage: 0.6,
            order_type: "LONG",
            order_uuid: "74ed4258-cc41-438b-bb5d-fe9ab1e03919",
            price: 3399.75,
            price_sources: [],
            processed_ms: 1711222436141,
          },
          {
            leverage: 0.6,
            order_type: "LONG",
            order_uuid: "e5bd6531-e19a-477a-ad90-9dfde2ce313a",
            price: 3394.1,
            price_sources: [],
            processed_ms: 1711222726220,
          },
          {
            leverage: 0.6,
            order_type: "LONG",
            order_uuid: "d1a4b76c-8d69-495f-8661-2a8a3fcaa105",
            price: 3392.59,
            price_sources: [],
            processed_ms: 1711223034514,
          },
          {
            leverage: 0.6,
            order_type: "LONG",
            order_uuid: "c5d6567b-082a-4cd1-a9a2-d6b49b0cc9f4",
            price: 3385.58,
            price_sources: [],
            processed_ms: 1711223332209,
          },
          {
            leverage: 0.6,
            order_type: "LONG",
            order_uuid: "388e99d0-28ed-4ed4-a222-9b18da7eaffc",
            price: 3392.93,
            price_sources: [],
            processed_ms: 1711223633774,
          },
          {
            leverage: 0.6,
            order_type: "FLAT",
            order_uuid: "44f2207a-17a5-484c-abd2-3317a222cb8f",
            price: 3387.8,
            price_sources: [],
            processed_ms: 1711223930803,
          },
        ],
        position_type: "FLAT",
        position_uuid: "0ee08a6d-f1c5-44d6-8d86-70896cddf99f",
        return_at_close: 0.989447729980146,
        trade_pair: ["ETHUSD", "ETH/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 3387.24,
        close_ms: 1711225754411,
        current_return: 0.9976090960612073,
        initial_entry_price: 3387.84,
        is_closed_position: true,
        miner_hotkey: "5Gmgq2Y8mLqDzBLeXtQ9votYjtLJTMFtHdgZMCYuNna2KQts",
        net_leverage: 0.0,
        open_ms: 1711224228181,
        orders: [
          {
            leverage: 0.6,
            order_type: "LONG",
            order_uuid: "88e596a7-dfc3-4cec-94da-a984353763c6",
            price: 3387.84,
            price_sources: [],
            processed_ms: 1711224228179,
          },
          {
            leverage: 0.6,
            order_type: "LONG",
            order_uuid: "f0dddae3-7207-4a1a-af3f-c48d5f106412",
            price: 3385.5,
            price_sources: [],
            processed_ms: 1711224526087,
          },
          {
            leverage: 0.6,
            order_type: "LONG",
            order_uuid: "fb3331db-920f-45f2-aa53-440f56a12226",
            price: 3385.78,
            price_sources: [],
            processed_ms: 1711224835887,
          },
          {
            leverage: 0.6,
            order_type: "LONG",
            order_uuid: "6af2b0ca-eb4c-4765-a6bf-bd004bbad7ea",
            price: 3388.29,
            price_sources: [],
            processed_ms: 1711225136146,
          },
          {
            leverage: 0.6,
            order_type: "LONG",
            order_uuid: "332857a0-7e2f-4694-94cc-0b466f20b119",
            price: 3388.79,
            price_sources: [],
            processed_ms: 1711225433140,
          },
          {
            leverage: 0.6,
            order_type: "FLAT",
            order_uuid: "b7259e12-8e74-4519-962e-311db63f2519",
            price: 3384.54,
            price_sources: [],
            processed_ms: 1711225754411,
          },
        ],
        position_type: "FLAT",
        position_uuid: "625933e0-0b7e-4d5d-9b20-53f22534d0a6",
        return_at_close: 0.99162344148484,
        trade_pair: ["ETHUSD", "ETH/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 3388.582,
        close_ms: 1711227533480,
        current_return: 0.9999716678766847,
        initial_entry_price: 3388.38,
        is_closed_position: true,
        miner_hotkey: "5Gmgq2Y8mLqDzBLeXtQ9votYjtLJTMFtHdgZMCYuNna2KQts",
        net_leverage: 0.0,
        open_ms: 1711226033899,
        orders: [
          {
            leverage: 0.6,
            order_type: "LONG",
            order_uuid: "8378809a-76dc-43fd-b2f1-ece8d5d9c425",
            price: 3388.38,
            price_sources: [],
            processed_ms: 1711226033898,
          },
          {
            leverage: 0.6,
            order_type: "LONG",
            order_uuid: "a10dbfe7-f28b-4d57-b533-005a2a7148da",
            price: 3388.38,
            price_sources: [],
            processed_ms: 1711226332507,
          },
          {
            leverage: 0.6,
            order_type: "LONG",
            order_uuid: "75356a87-97d6-4931-abf9-b67a9a7efeee",
            price: 3389.56,
            price_sources: [],
            processed_ms: 1711226629051,
          },
          {
            leverage: 0.6,
            order_type: "LONG",
            order_uuid: "34eebc9d-a8c4-407e-b49e-2d362a5c9c15",
            price: 3389.42,
            price_sources: [],
            processed_ms: 1711226936026,
          },
          {
            leverage: 0.6,
            order_type: "LONG",
            order_uuid: "4b4218f8-c767-4278-80fc-99911e34a43a",
            price: 3387.17,
            price_sources: [],
            processed_ms: 1711227233244,
          },
          {
            leverage: 0.6,
            order_type: "FLAT",
            order_uuid: "8d88c0d3-5251-42d2-8249-cb86e122ffd6",
            price: 3388.55,
            price_sources: [],
            processed_ms: 1711227533480,
          },
        ],
        position_type: "FLAT",
        position_uuid: "0a74f0d8-5956-4d26-8a34-1460d2916d2d",
        return_at_close: 0.9939718378694246,
        trade_pair: ["ETHUSD", "ETH/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 3380.354,
        close_ms: 1711229338433,
        current_return: 0.9984913901226191,
        initial_entry_price: 3388.55,
        is_closed_position: true,
        miner_hotkey: "5Gmgq2Y8mLqDzBLeXtQ9votYjtLJTMFtHdgZMCYuNna2KQts",
        net_leverage: 0.0,
        open_ms: 1711227830649,
        orders: [
          {
            leverage: 0.6,
            order_type: "LONG",
            order_uuid: "4741e1e2-409d-43a7-b3b7-4ffbc6ea554c",
            price: 3388.55,
            price_sources: [],
            processed_ms: 1711227830648,
          },
          {
            leverage: 0.6,
            order_type: "LONG",
            order_uuid: "e9da0c03-5ca9-4a2a-8515-7973ea22d8c2",
            price: 3377.07,
            price_sources: [],
            processed_ms: 1711228139112,
          },
          {
            leverage: 0.6,
            order_type: "LONG",
            order_uuid: "d7d3e5d1-f783-4c26-aa0c-cd03e18311d0",
            price: 3377.82,
            price_sources: [],
            processed_ms: 1711228437527,
          },
          {
            leverage: 0.6,
            order_type: "LONG",
            order_uuid: "1957cc8d-820c-438f-aafa-55ffbf2577f5",
            price: 3379.36,
            price_sources: [],
            processed_ms: 1711228760783,
          },
          {
            leverage: 0.6,
            order_type: "LONG",
            order_uuid: "1a3cfbf4-3e3b-4bab-96d0-781fa29f95db",
            price: 3378.97,
            price_sources: [],
            processed_ms: 1711229030760,
          },
          {
            leverage: 0.6,
            order_type: "FLAT",
            order_uuid: "a30d701c-41f3-494e-a38f-5849c3c69736",
            price: 3378.65,
            price_sources: [],
            processed_ms: 1711229338433,
          },
        ],
        position_type: "FLAT",
        position_uuid: "5b5a8d35-2f44-4570-943b-7713e673dfe2",
        return_at_close: 0.9925004417818833,
        trade_pair: ["ETHUSD", "ETH/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 3385.9319999999993,
        close_ms: 1711231138255,
        current_return: 1.0014547930605726,
        initial_entry_price: 3377.8,
        is_closed_position: true,
        miner_hotkey: "5Gmgq2Y8mLqDzBLeXtQ9votYjtLJTMFtHdgZMCYuNna2KQts",
        net_leverage: 0.0,
        open_ms: 1711229638298,
        orders: [
          {
            leverage: 0.6,
            order_type: "LONG",
            order_uuid: "4b552262-687b-4fb6-8c48-65e583f190f1",
            price: 3377.8,
            price_sources: [],
            processed_ms: 1711229638296,
          },
          {
            leverage: 0.6,
            order_type: "LONG",
            order_uuid: "c0c9ab27-ef77-4c7e-8541-5a0b7c7e2c0d",
            price: 3379.32,
            price_sources: [],
            processed_ms: 1711229935273,
          },
          {
            leverage: 0.6,
            order_type: "LONG",
            order_uuid: "ba333805-c25b-4b3b-9e70-d1e3a934ae6a",
            price: 3392.05,
            price_sources: [],
            processed_ms: 1711230232986,
          },
          {
            leverage: 0.6,
            order_type: "LONG",
            order_uuid: "3408276a-a2eb-496a-a2f6-575a676b9444",
            price: 3389.59,
            price_sources: [],
            processed_ms: 1711230534460,
          },
          {
            leverage: 0.6,
            order_type: "LONG",
            order_uuid: "e80f8f80-defe-4c89-a2e0-aef32a6aa6b1",
            price: 3390.9,
            price_sources: [],
            processed_ms: 1711230831293,
          },
          {
            leverage: 0.6,
            order_type: "FLAT",
            order_uuid: "36a1f3d2-abc0-46f4-b622-49f2ddcc05fb",
            price: 3387.57,
            price_sources: [],
            processed_ms: 1711231138255,
          },
        ],
        position_type: "FLAT",
        position_uuid: "24917085-3a8c-4714-9696-2021c7630c59",
        return_at_close: 0.9954460643022092,
        trade_pair: ["ETHUSD", "ETH/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 3384.036,
        close_ms: 1711232936530,
        current_return: 1.0022873059242166,
        initial_entry_price: 3389.14,
        is_closed_position: true,
        miner_hotkey: "5Gmgq2Y8mLqDzBLeXtQ9votYjtLJTMFtHdgZMCYuNna2KQts",
        net_leverage: 0.0,
        open_ms: 1711231438145,
        orders: [
          {
            leverage: 0.6,
            order_type: "LONG",
            order_uuid: "94f05f51-603e-427c-a7d7-dd99b5aff05e",
            price: 3389.14,
            price_sources: [],
            processed_ms: 1711231438143,
          },
          {
            leverage: 0.6,
            order_type: "LONG",
            order_uuid: "f5182e6d-01a3-4c50-8303-3bed81932b54",
            price: 3389.49,
            price_sources: [],
            processed_ms: 1711231738306,
          },
          {
            leverage: 0.6,
            order_type: "LONG",
            order_uuid: "5eb3e4d4-f427-4f86-b3f9-ae081d8fd1d0",
            price: 3386.95,
            price_sources: [],
            processed_ms: 1711232057145,
          },
          {
            leverage: 0.6,
            order_type: "LONG",
            order_uuid: "814ca82e-4916-46da-93f2-7e1c1864c73b",
            price: 3378.82,
            price_sources: [],
            processed_ms: 1711232336883,
          },
          {
            leverage: 0.6,
            order_type: "LONG",
            order_uuid: "ead12498-4a4e-47fc-a1d6-ddb2a774e140",
            price: 3375.78,
            price_sources: [],
            processed_ms: 1711232637457,
          },
          {
            leverage: 0.6,
            order_type: "FLAT",
            order_uuid: "f0fb49dd-4db5-4a7d-8737-00b47d0fbf63",
            price: 3386.62,
            price_sources: [],
            processed_ms: 1711232936530,
          },
        ],
        position_type: "FLAT",
        position_uuid: "5d6f4592-6e3c-409d-8452-3ee125452ceb",
        return_at_close: 0.9962735820886712,
        trade_pair: ["ETHUSD", "ETH/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 3386.224,
        close_ms: 1711234757723,
        current_return: 0.9993411711921789,
        initial_entry_price: 3387.83,
        is_closed_position: true,
        miner_hotkey: "5Gmgq2Y8mLqDzBLeXtQ9votYjtLJTMFtHdgZMCYuNna2KQts",
        net_leverage: 0.0,
        open_ms: 1711233242688,
        orders: [
          {
            leverage: 0.6,
            order_type: "LONG",
            order_uuid: "b34a2d21-9a66-423a-a157-c0ef912c036c",
            price: 3387.83,
            price_sources: [],
            processed_ms: 1711233242686,
          },
          {
            leverage: 0.6,
            order_type: "LONG",
            order_uuid: "a2117ce4-7887-4232-bd53-1959eabb8f21",
            price: 3394.69,
            price_sources: [],
            processed_ms: 1711233540210,
          },
          {
            leverage: 0.6,
            order_type: "LONG",
            order_uuid: "9252a65a-ec56-4e77-b10d-ddd8391a9d66",
            price: 3382.18,
            price_sources: [],
            processed_ms: 1711233841501,
          },
          {
            leverage: 0.6,
            order_type: "LONG",
            order_uuid: "1e581618-94cf-4780-8287-c16102d0bc25",
            price: 3379.81,
            price_sources: [],
            processed_ms: 1711234140183,
          },
          {
            leverage: 0.6,
            order_type: "LONG",
            order_uuid: "4af752f6-18d5-4cc2-a0fc-00236ac4cead",
            price: 3386.61,
            price_sources: [],
            processed_ms: 1711234438071,
          },
          {
            leverage: 0.6,
            order_type: "FLAT",
            order_uuid: "9ee77081-8a48-4058-a8f1-9981b5ae9f96",
            price: 3385.48,
            price_sources: [],
            processed_ms: 1711234757723,
          },
        ],
        position_type: "FLAT",
        position_uuid: "8c888343-4a27-4a1d-9a7b-bc4c516c37f4",
        return_at_close: 0.9933451241650259,
        trade_pair: ["ETHUSD", "ETH/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 3371.8959999999993,
        close_ms: 1711236538165,
        current_return: 0.9921713310337478,
        initial_entry_price: 3374.52,
        is_closed_position: true,
        miner_hotkey: "5Gmgq2Y8mLqDzBLeXtQ9votYjtLJTMFtHdgZMCYuNna2KQts",
        net_leverage: 0.0,
        open_ms: 1711235038040,
        orders: [
          {
            leverage: 0.6,
            order_type: "LONG",
            order_uuid: "3c7f708f-9283-49d3-8d18-012ffba3cd6d",
            price: 3374.52,
            price_sources: [],
            processed_ms: 1711235038038,
          },
          {
            leverage: 0.6,
            order_type: "LONG",
            order_uuid: "38babfbc-7b4d-4da8-8bc3-6975c9350983",
            price: 3378.88,
            price_sources: [],
            processed_ms: 1711235336168,
          },
          {
            leverage: 0.6,
            order_type: "LONG",
            order_uuid: "a18d4ee8-22d4-4681-8fc6-47a892bfbade",
            price: 3373.51,
            price_sources: [],
            processed_ms: 1711235643498,
          },
          {
            leverage: 0.6,
            order_type: "LONG",
            order_uuid: "d87f640f-9deb-40f3-8af9-1f55f9b61d7f",
            price: 3363.1,
            price_sources: [],
            processed_ms: 1711235943148,
          },
          {
            leverage: 0.6,
            order_type: "LONG",
            order_uuid: "69f64b5a-d960-4bc8-b2bf-59d1f5e5d3bf",
            price: 3369.47,
            price_sources: [],
            processed_ms: 1711236241162,
          },
          {
            leverage: 0.6,
            order_type: "FLAT",
            order_uuid: "475d3ac4-6d70-4a08-b75c-4db018833574",
            price: 3363.09,
            price_sources: [],
            processed_ms: 1711236538165,
          },
        ],
        position_type: "FLAT",
        position_uuid: "0392b564-a466-4b0a-8def-8432bebf2782",
        return_at_close: 0.9862183030475453,
        trade_pair: ["ETHUSD", "ETH/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 3358.28,
        close_ms: 1711238344339,
        current_return: 0.9815354365895073,
        initial_entry_price: 3356.7,
        is_closed_position: true,
        miner_hotkey: "5Gmgq2Y8mLqDzBLeXtQ9votYjtLJTMFtHdgZMCYuNna2KQts",
        net_leverage: 0.0,
        open_ms: 1711236838365,
        orders: [
          {
            leverage: 0.6,
            order_type: "LONG",
            order_uuid: "596d71ce-da75-4e11-8b50-af1c1e086c8a",
            price: 3356.7,
            price_sources: [],
            processed_ms: 1711236838363,
          },
          {
            leverage: 0.6,
            order_type: "LONG",
            order_uuid: "4f6235ad-ea72-4f75-9b4e-1925d8cc7c12",
            price: 3362.17,
            price_sources: [],
            processed_ms: 1711237136732,
          },
          {
            leverage: 0.6,
            order_type: "LONG",
            order_uuid: "ae5cbe75-3115-4f6d-aef9-9a417c5803c1",
            price: 3360.49,
            price_sources: [],
            processed_ms: 1711237455961,
          },
          {
            leverage: 0.6,
            order_type: "LONG",
            order_uuid: "f6630c38-6294-4401-9574-9b9735ca62da",
            price: 3355.35,
            price_sources: [],
            processed_ms: 1711237745945,
          },
          {
            leverage: 0.6,
            order_type: "LONG",
            order_uuid: "d38a1f18-5bd5-4623-990b-7fe72209f998",
            price: 3356.69,
            price_sources: [],
            processed_ms: 1711238044634,
          },
          {
            leverage: 0.6,
            order_type: "FLAT",
            order_uuid: "5e8e9e01-1984-44e4-ba81-b1e98c66934b",
            price: 3337.62,
            price_sources: [],
            processed_ms: 1711238344339,
          },
        ],
        position_type: "FLAT",
        position_uuid: "2f7871a5-7946-46a5-85cf-ea4d2d2756cd",
        return_at_close: 0.9756462239699702,
        trade_pair: ["ETHUSD", "ETH/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 3344.154,
        close_ms: 1711240147449,
        current_return: 1.006879714469955,
        initial_entry_price: 3334.15,
        is_closed_position: true,
        miner_hotkey: "5Gmgq2Y8mLqDzBLeXtQ9votYjtLJTMFtHdgZMCYuNna2KQts",
        net_leverage: 0.0,
        open_ms: 1711238641238,
        orders: [
          {
            leverage: 0.6,
            order_type: "LONG",
            order_uuid: "8ce926c3-e16d-497e-8ef3-5cdeb0bfc8dd",
            price: 3334.15,
            price_sources: [],
            processed_ms: 1711238641234,
          },
          {
            leverage: 0.6,
            order_type: "LONG",
            order_uuid: "33769fe4-6aa3-400c-839b-d0cb4f9b8a0d",
            price: 3339.63,
            price_sources: [],
            processed_ms: 1711238941441,
          },
          {
            leverage: 0.6,
            order_type: "LONG",
            order_uuid: "2a2ab5b9-5be6-4f4a-ace1-cfd4fdb5dc61",
            price: 3350.22,
            price_sources: [],
            processed_ms: 1711239242697,
          },
          {
            leverage: 0.6,
            order_type: "LONG",
            order_uuid: "34f38c57-8add-48c5-ac0b-4aca0ea91bf7",
            price: 3351.66,
            price_sources: [],
            processed_ms: 1711239545711,
          },
          {
            leverage: 0.6,
            order_type: "LONG",
            order_uuid: "253123d9-cf54-4201-ab48-40945ec18a10",
            price: 3345.11,
            price_sources: [],
            processed_ms: 1711239846760,
          },
          {
            leverage: 0.6,
            order_type: "FLAT",
            order_uuid: "2bbc88ad-5513-43a0-bd24-e1a1fda319df",
            price: 3351.8,
            price_sources: [],
            processed_ms: 1711240147449,
          },
        ],
        position_type: "FLAT",
        position_uuid: "dff2190b-1cca-49d6-a810-7f6b852f3fc5",
        return_at_close: 1.0008384361831353,
        trade_pair: ["ETHUSD", "ETH/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 3355.0399999999995,
        close_ms: 1711241942263,
        current_return: 1.0017013452566799,
        initial_entry_price: 3350.29,
        is_closed_position: true,
        miner_hotkey: "5Gmgq2Y8mLqDzBLeXtQ9votYjtLJTMFtHdgZMCYuNna2KQts",
        net_leverage: 0.0,
        open_ms: 1711240465853,
        orders: [
          {
            leverage: 0.6,
            order_type: "LONG",
            order_uuid: "05fb630d-9a73-4c8a-846f-d78680968f1f",
            price: 3350.29,
            price_sources: [],
            processed_ms: 1711240465851,
          },
          {
            leverage: 0.6,
            order_type: "LONG",
            order_uuid: "568e058e-be29-481c-8adc-d6f931862664",
            price: 3354.12,
            price_sources: [],
            processed_ms: 1711240747012,
          },
          {
            leverage: 0.6,
            order_type: "LONG",
            order_uuid: "5de81b76-0543-4107-9e35-d3341d2fcd6b",
            price: 3355.86,
            price_sources: [],
            processed_ms: 1711241045111,
          },
          {
            leverage: 0.6,
            order_type: "LONG",
            order_uuid: "1dd8e05d-4863-46da-8700-41988fd960a0",
            price: 3356.79,
            price_sources: [],
            processed_ms: 1711241348607,
          },
          {
            leverage: 0.6,
            order_type: "LONG",
            order_uuid: "b3df12ee-80d1-441b-8831-c6442b5dbd32",
            price: 3358.14,
            price_sources: [],
            processed_ms: 1711241642979,
          },
          {
            leverage: 0.6,
            order_type: "FLAT",
            order_uuid: "2694f047-4d67-4d6e-b5b2-cfcdf96d92c8",
            price: 3356.94,
            price_sources: [],
            processed_ms: 1711241942263,
          },
        ],
        position_type: "FLAT",
        position_uuid: "9125b8ae-f4d3-4f4e-b0ca-ae5b825383ad",
        return_at_close: 0.9956911371851398,
        trade_pair: ["ETHUSD", "ETH/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 3353.7419999999997,
        close_ms: 1711243747869,
        current_return: 1.013242040437944,
        initial_entry_price: 3354.77,
        is_closed_position: true,
        miner_hotkey: "5Gmgq2Y8mLqDzBLeXtQ9votYjtLJTMFtHdgZMCYuNna2KQts",
        net_leverage: 0.0,
        open_ms: 1711242241582,
        orders: [
          {
            leverage: 0.6,
            order_type: "LONG",
            order_uuid: "a3c68be1-7244-4841-b710-1342762a29d9",
            price: 3354.77,
            price_sources: [],
            processed_ms: 1711242241580,
          },
          {
            leverage: 0.6,
            order_type: "LONG",
            order_uuid: "b6f951b3-7d15-4b1d-9b62-dcefc7928df2",
            price: 3353.69,
            price_sources: [],
            processed_ms: 1711242541890,
          },
          {
            leverage: 0.6,
            order_type: "LONG",
            order_uuid: "d7ee50eb-2505-4013-ba13-bbe9ce50d75f",
            price: 3358.39,
            price_sources: [],
            processed_ms: 1711242843824,
          },
          {
            leverage: 0.6,
            order_type: "LONG",
            order_uuid: "910357c4-b43b-4adb-8b8e-d2500f6e9985",
            price: 3352.04,
            price_sources: [],
            processed_ms: 1711243145137,
          },
          {
            leverage: 0.6,
            order_type: "LONG",
            order_uuid: "156e8b07-d7b2-47c0-80b2-71e7c913ac83",
            price: 3349.82,
            price_sources: [],
            processed_ms: 1711243467809,
          },
          {
            leverage: 0.6,
            order_type: "FLAT",
            order_uuid: "e59e9be9-b357-4578-b1c7-814f3a723073",
            price: 3368.55,
            price_sources: [],
            processed_ms: 1711243747869,
          },
        ],
        position_type: "FLAT",
        position_uuid: "3590d86c-46d9-4c51-a636-d912ff2f05f4",
        return_at_close: 1.0071625881953163,
        trade_pair: ["ETHUSD", "ETH/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 3354.5579999999995,
        close_ms: 1711245547009,
        current_return: 1.0000999967262982,
        initial_entry_price: 3360.11,
        is_closed_position: true,
        miner_hotkey: "5Gmgq2Y8mLqDzBLeXtQ9votYjtLJTMFtHdgZMCYuNna2KQts",
        net_leverage: 0.0,
        open_ms: 1711244041028,
        orders: [
          {
            leverage: 0.6,
            order_type: "LONG",
            order_uuid: "b315d6c1-2726-49dd-b687-726abd77f122",
            price: 3360.11,
            price_sources: [],
            processed_ms: 1711244041025,
          },
          {
            leverage: 0.6,
            order_type: "LONG",
            order_uuid: "7a49da92-829c-497d-9b4f-c33df4788d7e",
            price: 3353.93,
            price_sources: [],
            processed_ms: 1711244344594,
          },
          {
            leverage: 0.6,
            order_type: "LONG",
            order_uuid: "dd43c2d2-de83-4d38-be94-7cdd1ca78a4c",
            price: 3351.61,
            price_sources: [],
            processed_ms: 1711244643411,
          },
          {
            leverage: 0.6,
            order_type: "LONG",
            order_uuid: "792c439a-fb5d-4707-82a0-f782d9b1ab42",
            price: 3352.99,
            price_sources: [],
            processed_ms: 1711244946592,
          },
          {
            leverage: 0.6,
            order_type: "LONG",
            order_uuid: "cc0f964e-0ff2-418f-b5b5-8ba98ccd1345",
            price: 3354.15,
            price_sources: [],
            processed_ms: 1711245246230,
          },
          {
            leverage: 0.6,
            order_type: "FLAT",
            order_uuid: "2fa8cc23-4969-4fc8-9587-9e4ae6ec3ae7",
            price: 3354.67,
            price_sources: [],
            processed_ms: 1711245547009,
          },
        ],
        position_type: "FLAT",
        position_uuid: "f5562a0c-b341-4968-9b44-905fd99ce9b1",
        return_at_close: 0.9940993967459404,
        trade_pair: ["ETHUSD", "ETH/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 3346.984,
        close_ms: 1711247351989,
        current_return: 0.9972981856429134,
        initial_entry_price: 3346.64,
        is_closed_position: true,
        miner_hotkey: "5Gmgq2Y8mLqDzBLeXtQ9votYjtLJTMFtHdgZMCYuNna2KQts",
        net_leverage: 0.0,
        open_ms: 1711245845276,
        orders: [
          {
            leverage: 0.6,
            order_type: "LONG",
            order_uuid: "99bb007b-caff-43db-ab01-da3f6f2ac956",
            price: 3346.64,
            price_sources: [],
            processed_ms: 1711245845273,
          },
          {
            leverage: 0.6,
            order_type: "LONG",
            order_uuid: "d31fc3d6-64d3-457e-ab7c-cd0dbe08977f",
            price: 3350.87,
            price_sources: [],
            processed_ms: 1711246170235,
          },
          {
            leverage: 0.6,
            order_type: "LONG",
            order_uuid: "379103e6-3565-494c-ba4a-e8c9eef4d903",
            price: 3350.45,
            price_sources: [],
            processed_ms: 1711246450198,
          },
          {
            leverage: 0.6,
            order_type: "LONG",
            order_uuid: "90c4cb11-ed75-4bb8-ba67-2cdcad367a8c",
            price: 3343.19,
            price_sources: [],
            processed_ms: 1711246749041,
          },
          {
            leverage: 0.6,
            order_type: "LONG",
            order_uuid: "f6b42321-44ec-449e-8653-84041e8abdb9",
            price: 3343.77,
            price_sources: [],
            processed_ms: 1711247047350,
          },
          {
            leverage: 0.6,
            order_type: "FLAT",
            order_uuid: "a103e4c1-1dcb-46d4-952e-99519be579fc",
            price: 3343.97,
            price_sources: [],
            processed_ms: 1711247351989,
          },
        ],
        position_type: "FLAT",
        position_uuid: "d4c203b1-b7c6-4b57-befa-326898662385",
        return_at_close: 0.991314396529056,
        trade_pair: ["ETHUSD", "ETH/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 3342.858,
        close_ms: 1711249164967,
        current_return: 1.0017265695883004,
        initial_entry_price: 3339.57,
        is_closed_position: true,
        miner_hotkey: "5Gmgq2Y8mLqDzBLeXtQ9votYjtLJTMFtHdgZMCYuNna2KQts",
        net_leverage: 0.0,
        open_ms: 1711247650490,
        orders: [
          {
            leverage: 0.6,
            order_type: "LONG",
            order_uuid: "63c99970-5758-4b55-885d-2fe3a3dfa6ea",
            price: 3339.57,
            price_sources: [],
            processed_ms: 1711247650488,
          },
          {
            leverage: 0.6,
            order_type: "LONG",
            order_uuid: "d65a45d5-41ee-4244-a62a-17e9bc766d1a",
            price: 3341.28,
            price_sources: [],
            processed_ms: 1711247948910,
          },
          {
            leverage: 0.6,
            order_type: "LONG",
            order_uuid: "aede7a71-242a-4cc8-ade7-0114513e7a57",
            price: 3344.94,
            price_sources: [],
            processed_ms: 1711248246526,
          },
          {
            leverage: 0.6,
            order_type: "LONG",
            order_uuid: "6295a06d-577d-4553-b7d9-723557b05c8c",
            price: 3343.72,
            price_sources: [],
            processed_ms: 1711248554247,
          },
          {
            leverage: 0.6,
            order_type: "LONG",
            order_uuid: "4839a453-0230-48eb-91c1-d337d0f92b43",
            price: 3344.78,
            price_sources: [],
            processed_ms: 1711248853071,
          },
          {
            leverage: 0.6,
            order_type: "FLAT",
            order_uuid: "a522b73c-e2d4-452d-a6fa-5203c9afe018",
            price: 3344.78,
            price_sources: [],
            processed_ms: 1711249164967,
          },
        ],
        position_type: "FLAT",
        position_uuid: "e568b633-d758-4284-919e-2af0aeeb689b",
        return_at_close: 0.9957162101707706,
        trade_pair: ["ETHUSD", "ETH/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 3326.6,
        close_ms: 1711250947345,
        current_return: 0.9978346551021876,
        initial_entry_price: 3338.96,
        is_closed_position: true,
        miner_hotkey: "5Gmgq2Y8mLqDzBLeXtQ9votYjtLJTMFtHdgZMCYuNna2KQts",
        net_leverage: 0.0,
        open_ms: 1711249455455,
        orders: [
          {
            leverage: 0.6,
            order_type: "LONG",
            order_uuid: "4c48a2ad-afef-4e55-be6d-a805ae34c5f1",
            price: 3338.96,
            price_sources: [],
            processed_ms: 1711249455452,
          },
          {
            leverage: 0.6,
            order_type: "LONG",
            order_uuid: "82e29d8b-e31d-4b51-86c3-c83903051074",
            price: 3330.69,
            price_sources: [],
            processed_ms: 1711249755442,
          },
          {
            leverage: 0.6,
            order_type: "LONG",
            order_uuid: "ed15a47e-1d5e-41a2-83e2-9824e2b2d796",
            price: 3315.61,
            price_sources: [],
            processed_ms: 1711250047440,
          },
          {
            leverage: 0.6,
            order_type: "LONG",
            order_uuid: "547121e7-691f-4da1-96ac-210b0997bf39",
            price: 3324.22,
            price_sources: [],
            processed_ms: 1711250352234,
          },
          {
            leverage: 0.6,
            order_type: "LONG",
            order_uuid: "4b1923d5-c9d7-48ba-be74-9efa2bea4133",
            price: 3323.52,
            price_sources: [],
            processed_ms: 1711250649512,
          },
          {
            leverage: 0.6,
            order_type: "FLAT",
            order_uuid: "68d800d8-f29a-471a-8126-8a0dbfc8bfe1",
            price: 3324.19,
            price_sources: [],
            processed_ms: 1711250947345,
          },
        ],
        position_type: "FLAT",
        position_uuid: "491f402c-c561-40c2-ba5b-a81c00ecbc7d",
        return_at_close: 0.9918476471715745,
        trade_pair: ["ETHUSD", "ETH/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 3318.9999999999995,
        close_ms: 1711252752578,
        current_return: 0.9992498395741263,
        initial_entry_price: 3319.29,
        is_closed_position: true,
        miner_hotkey: "5Gmgq2Y8mLqDzBLeXtQ9votYjtLJTMFtHdgZMCYuNna2KQts",
        net_leverage: 0.0,
        open_ms: 1711251246969,
        orders: [
          {
            leverage: 0.6,
            order_type: "LONG",
            order_uuid: "be2c231b-ca96-4ff6-8b2a-088e62fbf71d",
            price: 3319.29,
            price_sources: [],
            processed_ms: 1711251246965,
          },
          {
            leverage: 0.6,
            order_type: "LONG",
            order_uuid: "05f17b8e-5ece-48c5-891a-a6410099bd88",
            price: 3316.18,
            price_sources: [],
            processed_ms: 1711251556706,
          },
          {
            leverage: 0.6,
            order_type: "LONG",
            order_uuid: "d9b06bc2-48b6-46ac-9a97-5ea3354167e3",
            price: 3317.91,
            price_sources: [],
            processed_ms: 1711251859449,
          },
          {
            leverage: 0.6,
            order_type: "LONG",
            order_uuid: "c18dce5c-5b1f-4a5c-84b4-c88974e1353e",
            price: 3321.5,
            price_sources: [],
            processed_ms: 1711252149000,
          },
          {
            leverage: 0.6,
            order_type: "LONG",
            order_uuid: "e943277f-9299-42ea-813a-5fc0faf7ad8f",
            price: 3320.12,
            price_sources: [],
            processed_ms: 1711252453474,
          },
          {
            leverage: 0.6,
            order_type: "FLAT",
            order_uuid: "ccd30901-4f51-4540-9e1c-fb1e90484c0b",
            price: 3318.17,
            price_sources: [],
            processed_ms: 1711252752578,
          },
        ],
        position_type: "FLAT",
        position_uuid: "a9ffc295-bca8-421b-a245-aeb251ea6be0",
        return_at_close: 0.9932543405366815,
        trade_pair: ["ETHUSD", "ETH/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 3313.83,
        close_ms: 1711254560176,
        current_return: 0.9913875598086127,
        initial_entry_price: 3312.65,
        is_closed_position: true,
        miner_hotkey: "5Gmgq2Y8mLqDzBLeXtQ9votYjtLJTMFtHdgZMCYuNna2KQts",
        net_leverage: 0.0,
        open_ms: 1711253052144,
        orders: [
          {
            leverage: 0.6,
            order_type: "LONG",
            order_uuid: "ce87e620-da69-4f5a-b6de-2ab0d2e13c23",
            price: 3312.65,
            price_sources: [],
            processed_ms: 1711253052141,
          },
          {
            leverage: 0.6,
            order_type: "LONG",
            order_uuid: "39112a49-7e6a-4b1d-97e6-105201df78a2",
            price: 3312.65,
            price_sources: [],
            processed_ms: 1711253354653,
          },
          {
            leverage: 0.6,
            order_type: "LONG",
            order_uuid: "cb366eae-3316-4a09-84a3-6856c39352c5",
            price: 3316.32,
            price_sources: [],
            processed_ms: 1711253658396,
          },
          {
            leverage: 0.6,
            order_type: "LONG",
            order_uuid: "53260dcf-9f69-458f-b0cc-f67a73efd2dc",
            price: 3317.61,
            price_sources: [],
            processed_ms: 1711253955931,
          },
          {
            leverage: 0.6,
            order_type: "LONG",
            order_uuid: "0e7bb7b9-72ce-4102-9a28-e6529a595b86",
            price: 3309.92,
            price_sources: [],
            processed_ms: 1711254283112,
          },
          {
            leverage: 0.6,
            order_type: "FLAT",
            order_uuid: "bb02742f-83e7-44f4-bb9b-8f8ccd51569a",
            price: 3304.32,
            price_sources: [],
            processed_ms: 1711254560176,
          },
        ],
        position_type: "FLAT",
        position_uuid: "28ce454e-2584-4d7c-861b-a337cfc799b4",
        return_at_close: 0.985439234449761,
        trade_pair: ["ETHUSD", "ETH/USD", 0.003, 0.001, 20],
      },
    ],
    thirty_day_returns: 1.0,
  },
  "5Gn6Ys7QJFUicrXF17KyXqUnCV5tgRgF5RFrqDxHS981fDPf": {
    all_time_returns: 1.0,
    n_positions: 0,
    percentage_profitable: 0.0,
    positions: [
      {
        average_entry_price: 64576.1,
        close_ms: 1711198689309,
        current_return: 1.001317886958178,
        initial_entry_price: 64576.1,
        is_closed_position: true,
        miner_hotkey: "5Gn6Ys7QJFUicrXF17KyXqUnCV5tgRgF5RFrqDxHS981fDPf",
        net_leverage: 0.0,
        open_ms: 1711195097397,
        orders: [
          {
            leverage: 0.6,
            order_type: "LONG",
            order_uuid: "863e9eca-9cac-4733-90ef-5bc6bb23fcc2",
            price: 64576.1,
            price_sources: [],
            processed_ms: 1711195097396,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "2379d0ad-0b26-4cfc-bc29-a117153cafd1",
            price: 64717.94,
            price_sources: [],
            processed_ms: 1711198689309,
          },
        ],
        position_type: "FLAT",
        position_uuid: "13732985-c1f1-4627-ab72-205ca9afcc6a",
        return_at_close: 1.0001163054938282,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 64749.799999999996,
        close_ms: 1711202326735,
        current_return: 0.9987178029893529,
        initial_entry_price: 64749.8,
        is_closed_position: true,
        miner_hotkey: "5Gn6Ys7QJFUicrXF17KyXqUnCV5tgRgF5RFrqDxHS981fDPf",
        net_leverage: 0.0,
        open_ms: 1711198777702,
        orders: [
          {
            leverage: 0.6,
            order_type: "LONG",
            order_uuid: "1615b6ba-3dca-4c5d-a7f4-01456420391c",
            price: 64749.8,
            price_sources: [],
            processed_ms: 1711198777701,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "516ef271-b10b-498b-90a4-9fee4eca6043",
            price: 64611.43,
            price_sources: [],
            processed_ms: 1711202326735,
          },
        ],
        position_type: "FLAT",
        position_uuid: "84aa9687-20a4-4123-9f6d-b0885df486b8",
        return_at_close: 0.9975193416257656,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 64597.28999999999,
        close_ms: 1711205997916,
        current_return: 1.0064137675125382,
        initial_entry_price: 64597.29,
        is_closed_position: true,
        miner_hotkey: "5Gn6Ys7QJFUicrXF17KyXqUnCV5tgRgF5RFrqDxHS981fDPf",
        net_leverage: 0.0,
        open_ms: 1711202454190,
        orders: [
          {
            leverage: 0.6,
            order_type: "LONG",
            order_uuid: "900997f1-2ef2-4202-825a-827e44c604b8",
            price: 64597.29,
            price_sources: [],
            processed_ms: 1711202454189,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "7afabd1d-b34a-43e9-ad2f-0006007fd31c",
            price: 65287.81,
            price_sources: [],
            processed_ms: 1711205997916,
          },
        ],
        position_type: "FLAT",
        position_uuid: "6af72367-575d-479f-97f7-b0747f983abe",
        return_at_close: 1.0052060709915231,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 65256.89,
        close_ms: 1711209660428,
        current_return: 0.9984552435765787,
        initial_entry_price: 65256.89,
        is_closed_position: true,
        miner_hotkey: "5Gn6Ys7QJFUicrXF17KyXqUnCV5tgRgF5RFrqDxHS981fDPf",
        net_leverage: 0.0,
        open_ms: 1711206085423,
        orders: [
          {
            leverage: 0.6,
            order_type: "LONG",
            order_uuid: "4aef4315-ff9f-4c3e-a2b2-3632c909c24b",
            price: 65256.89,
            price_sources: [],
            processed_ms: 1711206085422,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "5db1a28b-27c0-4625-8a74-6ea03ed39d8a",
            price: 65088.88,
            price_sources: [],
            processed_ms: 1711209660428,
          },
        ],
        position_type: "FLAT",
        position_uuid: "8028cb08-f58b-44de-8e81-2b9198da84b3",
        return_at_close: 0.9972570972842868,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 65063.11000000001,
        close_ms: 1711213326810,
        current_return: 1.0061973367089276,
        initial_entry_price: 65063.11,
        is_closed_position: true,
        miner_hotkey: "5Gn6Ys7QJFUicrXF17KyXqUnCV5tgRgF5RFrqDxHS981fDPf",
        net_leverage: 0.0,
        open_ms: 1711209748963,
        orders: [
          {
            leverage: 0.6,
            order_type: "LONG",
            order_uuid: "252c95a2-b869-4d45-b303-e3465c0100a2",
            price: 65063.11,
            price_sources: [],
            processed_ms: 1711209748962,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "2b73cc4f-2308-4019-ab5c-33f5e23d2f9d",
            price: 65735.14,
            price_sources: [],
            processed_ms: 1711213326810,
          },
        ],
        position_type: "FLAT",
        position_uuid: "b84e416b-109e-4eca-a80f-bcb3c744befe",
        return_at_close: 1.004989899904877,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 65722.76,
        close_ms: 1711216988562,
        current_return: 0.9994431152921758,
        initial_entry_price: 65722.76,
        is_closed_position: true,
        miner_hotkey: "5Gn6Ys7QJFUicrXF17KyXqUnCV5tgRgF5RFrqDxHS981fDPf",
        net_leverage: 0.0,
        open_ms: 1711213460259,
        orders: [
          {
            leverage: 0.6,
            order_type: "LONG",
            order_uuid: "f79404f0-0238-42d3-8817-fdc98e5eea67",
            price: 65722.76,
            price_sources: [],
            processed_ms: 1711213460258,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "074d1ea1-7ad9-497e-9632-790c27ecc467",
            price: 65661.76,
            price_sources: [],
            processed_ms: 1711216988562,
          },
        ],
        position_type: "FLAT",
        position_uuid: "9743d97e-772d-4de1-9dce-453ef11e46a4",
        return_at_close: 0.9982437835538251,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 65575.34,
        close_ms: 1711220649440,
        current_return: 0.998882079757421,
        initial_entry_price: 65575.34,
        is_closed_position: true,
        miner_hotkey: "5Gn6Ys7QJFUicrXF17KyXqUnCV5tgRgF5RFrqDxHS981fDPf",
        net_leverage: 0.0,
        open_ms: 1711217075827,
        orders: [
          {
            leverage: 0.6,
            order_type: "LONG",
            order_uuid: "a447de47-647b-41d9-9012-1b66fb29b79e",
            price: 65575.34,
            price_sources: [],
            processed_ms: 1711217075826,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "da29cf1b-bb3a-4153-88c5-a46cdc078657",
            price: 65453.16,
            price_sources: [],
            processed_ms: 1711220649440,
          },
        ],
        position_type: "FLAT",
        position_uuid: "87b74d27-b0c9-4186-a4b3-54ad7e2c555e",
        return_at_close: 0.9976834212617122,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 65405.21,
        close_ms: 1711224308602,
        current_return: 0.9958163883274742,
        initial_entry_price: 65405.21,
        is_closed_position: true,
        miner_hotkey: "5Gn6Ys7QJFUicrXF17KyXqUnCV5tgRgF5RFrqDxHS981fDPf",
        net_leverage: 0.0,
        open_ms: 1711220769345,
        orders: [
          {
            leverage: 0.6,
            order_type: "LONG",
            order_uuid: "b01abd93-e9db-47dd-b414-3c83fc67107b",
            price: 65405.21,
            price_sources: [],
            processed_ms: 1711220769344,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "3e1076ec-7821-417d-ad57-09fb13f6359d",
            price: 64949.16,
            price_sources: [],
            processed_ms: 1711224308602,
          },
        ],
        position_type: "FLAT",
        position_uuid: "0f7d6d00-20e9-4298-8196-b06b900ca29a",
        return_at_close: 0.9946214086614813,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 64945.37,
        close_ms: 1711227969024,
        current_return: 0.9995934121246827,
        initial_entry_price: 64945.37,
        is_closed_position: true,
        miner_hotkey: "5Gn6Ys7QJFUicrXF17KyXqUnCV5tgRgF5RFrqDxHS981fDPf",
        net_leverage: 0.0,
        open_ms: 1711224395689,
        orders: [
          {
            leverage: 0.6,
            order_type: "LONG",
            order_uuid: "a3dd28cb-c518-4e76-8abc-abfc017b470e",
            price: 64945.37,
            price_sources: [],
            processed_ms: 1711224395688,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "d96fdebb-f98a-45b4-a98e-d154bf38848e",
            price: 64901.36,
            price_sources: [],
            processed_ms: 1711227969024,
          },
        ],
        position_type: "FLAT",
        position_uuid: "dda55516-1ead-40b4-a7c6-bc5e13deb437",
        return_at_close: 0.9983939000301331,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 64870.31999999999,
        close_ms: 1711231632904,
        current_return: 1.0009712608169654,
        initial_entry_price: 64870.32,
        is_closed_position: true,
        miner_hotkey: "5Gn6Ys7QJFUicrXF17KyXqUnCV5tgRgF5RFrqDxHS981fDPf",
        net_leverage: 0.0,
        open_ms: 1711228057022,
        orders: [
          {
            leverage: 0.6,
            order_type: "LONG",
            order_uuid: "db729211-d369-411f-9eba-d165de4eedd1",
            price: 64870.32,
            price_sources: [],
            processed_ms: 1711228056454,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "74ee275b-5c65-4410-ae73-12f3811bcb25",
            price: 64975.33,
            price_sources: [],
            processed_ms: 1711231632904,
          },
        ],
        position_type: "FLAT",
        position_uuid: "3953471e-4f12-429f-98aa-9d758cc5bbe0",
        return_at_close: 0.9997700953039851,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 65017.21000000001,
        close_ms: 1711235310375,
        current_return: 0.997788893125374,
        initial_entry_price: 65017.21,
        is_closed_position: true,
        miner_hotkey: "5Gn6Ys7QJFUicrXF17KyXqUnCV5tgRgF5RFrqDxHS981fDPf",
        net_leverage: 0.0,
        open_ms: 1711231768199,
        orders: [
          {
            leverage: 0.6,
            order_type: "LONG",
            order_uuid: "40c3e1dd-490c-4a76-b1fd-abbeca5b7ac1",
            price: 65017.21,
            price_sources: [],
            processed_ms: 1711231768198,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "c63a3b44-19f1-4489-a677-ef8d57f93b0f",
            price: 64777.61,
            price_sources: [],
            processed_ms: 1711235310375,
          },
        ],
        position_type: "FLAT",
        position_uuid: "43cef0d6-4b14-4488-9315-e316e52a06fa",
        return_at_close: 0.9965915464536236,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 64747.07,
        close_ms: 1711238956678,
        current_return: 0.9955986270884536,
        initial_entry_price: 64747.07,
        is_closed_position: true,
        miner_hotkey: "5Gn6Ys7QJFUicrXF17KyXqUnCV5tgRgF5RFrqDxHS981fDPf",
        net_leverage: 0.0,
        open_ms: 1711235413984,
        orders: [
          {
            leverage: 0.6,
            order_type: "LONG",
            order_uuid: "4f1cbe66-a459-429c-9016-96077a2e5036",
            price: 64747.07,
            price_sources: [],
            processed_ms: 1711235413983,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "4f40f8fa-6acd-47fb-aae1-526f8bc66ecd",
            price: 64272.11,
            price_sources: [],
            processed_ms: 1711238956678,
          },
        ],
        position_type: "FLAT",
        position_uuid: "df847ab0-47df-4938-b405-cbb5adc569ce",
        return_at_close: 0.9944039087359474,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 64308.26,
        close_ms: 1711242636669,
        current_return: 1.0018140437946852,
        initial_entry_price: 64308.26,
        is_closed_position: true,
        miner_hotkey: "5Gn6Ys7QJFUicrXF17KyXqUnCV5tgRgF5RFrqDxHS981fDPf",
        net_leverage: 0.0,
        open_ms: 1711239084063,
        orders: [
          {
            leverage: 0.6,
            order_type: "LONG",
            order_uuid: "c83b7298-729c-49b3-bcc5-05602da90373",
            price: 64308.26,
            price_sources: [],
            processed_ms: 1711239084062,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "502be14e-7c3d-4bb0-8260-a09fcd82e1f3",
            price: 64502.69,
            price_sources: [],
            processed_ms: 1711242636669,
          },
        ],
        position_type: "FLAT",
        position_uuid: "f7bb0c31-a929-44cc-9d5e-989f3d9446bb",
        return_at_close: 1.0006118669421316,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 64478.03,
        close_ms: 1711246286654,
        current_return: 1.0000587176748421,
        initial_entry_price: 64478.03,
        is_closed_position: true,
        miner_hotkey: "5Gn6Ys7QJFUicrXF17KyXqUnCV5tgRgF5RFrqDxHS981fDPf",
        net_leverage: 0.0,
        open_ms: 1711242740557,
        orders: [
          {
            leverage: 0.6,
            order_type: "LONG",
            order_uuid: "ae1ddc9b-4ca6-4e62-a063-250d88ef27ef",
            price: 64478.03,
            price_sources: [],
            processed_ms: 1711242740555,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "11ebb183-2c2b-4367-8bd4-9fdee445f460",
            price: 64484.34,
            price_sources: [],
            processed_ms: 1711246286654,
          },
        ],
        position_type: "FLAT",
        position_uuid: "9e149b63-5784-43a9-a1dd-be25e718379a",
        return_at_close: 0.9988586472136324,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 64440.79000000001,
        close_ms: 1711249949997,
        current_return: 0.9955286395464735,
        initial_entry_price: 64440.79,
        is_closed_position: true,
        miner_hotkey: "5Gn6Ys7QJFUicrXF17KyXqUnCV5tgRgF5RFrqDxHS981fDPf",
        net_leverage: 0.0,
        open_ms: 1711246374737,
        orders: [
          {
            leverage: 0.6,
            order_type: "LONG",
            order_uuid: "1923d1f5-6fb9-4b8b-be17-deab32f4a460",
            price: 64440.79,
            price_sources: [],
            processed_ms: 1711246374736,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "ac47a1be-7d21-4b45-936d-6b7fc9400758",
            price: 63960.56,
            price_sources: [],
            processed_ms: 1711249949997,
          },
        ],
        position_type: "FLAT",
        position_uuid: "f8f180ac-8a2d-4a26-a6ed-03d1e488538d",
        return_at_close: 0.9943340051790178,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 63990.780000000006,
        close_ms: 1711253610041,
        current_return: 1.0011683870707624,
        initial_entry_price: 63990.78,
        is_closed_position: true,
        miner_hotkey: "5Gn6Ys7QJFUicrXF17KyXqUnCV5tgRgF5RFrqDxHS981fDPf",
        net_leverage: 0.0,
        open_ms: 1711250052797,
        orders: [
          {
            leverage: 0.6,
            order_type: "LONG",
            order_uuid: "bd68f1c7-3ab6-4fae-80c9-66ba2962adee",
            price: 63990.78,
            price_sources: [],
            processed_ms: 1711250052795,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "288ef652-d53d-4e15-be37-08b565dc99ae",
            price: 64115.39,
            price_sources: [],
            processed_ms: 1711253610041,
          },
        ],
        position_type: "FLAT",
        position_uuid: "ee88b204-a043-410c-bfb3-c5040348d392",
        return_at_close: 0.9999669850062775,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 64091.310000000005,
        close_ms: 1711256440935,
        current_return: 1.001349574536704,
        initial_entry_price: 64091.31,
        is_closed_position: true,
        miner_hotkey: "5Gn6Ys7QJFUicrXF17KyXqUnCV5tgRgF5RFrqDxHS981fDPf",
        net_leverage: 0.0,
        open_ms: 1711253696838,
        orders: [
          {
            leverage: 0.6,
            order_type: "LONG",
            order_uuid: "259e85ac-46f9-4d4a-b52a-152e24774639",
            price: 64091.31,
            price_sources: [],
            processed_ms: 1711253696837,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "16e7a68e-d0e2-4c81-a43a-feb7442f9e49",
            price: 64235.47,
            price_sources: [],
            processed_ms: 1711256440935,
          },
        ],
        position_type: "FLAT",
        position_uuid: "99470242-0efc-4bcf-82f6-e8b11b4befe2",
        return_at_close: 1.00014795504726,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 64189.64,
        close_ms: 1711259814444,
        current_return: 1.0012246836093799,
        initial_entry_price: 64189.64,
        is_closed_position: true,
        miner_hotkey: "5Gn6Ys7QJFUicrXF17KyXqUnCV5tgRgF5RFrqDxHS981fDPf",
        net_leverage: 0.0,
        open_ms: 1711256580486,
        orders: [
          {
            leverage: 0.6,
            order_type: "LONG",
            order_uuid: "f041e4c0-7a79-408f-9c7d-a5a94fb5a052",
            price: 64189.64,
            price_sources: [],
            processed_ms: 1711256580485,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "91624a75-d891-4088-b1d1-26fa8bf694fc",
            price: 64320.66,
            price_sources: [],
            processed_ms: 1711259814444,
          },
        ],
        position_type: "FLAT",
        position_uuid: "2e253dd8-b599-42f1-879a-23ca7f7ea693",
        return_at_close: 1.0000232139890486,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 64318.4,
        close_ms: 1711263466178,
        current_return: 0.9979442119206946,
        initial_entry_price: 64318.4,
        is_closed_position: true,
        miner_hotkey: "5Gn6Ys7QJFUicrXF17KyXqUnCV5tgRgF5RFrqDxHS981fDPf",
        net_leverage: 0.0,
        open_ms: 1711259901474,
        orders: [
          {
            leverage: 1.25,
            order_type: "LONG",
            order_uuid: "d095fbca-410c-42e7-beeb-f5cfb6c2083c",
            price: 64318.4,
            price_sources: [],
            processed_ms: 1711259901473,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "5506a772-780d-4e1c-aa49-c7340011d55c",
            price: 64212.62,
            price_sources: [],
            processed_ms: 1711263466178,
          },
        ],
        position_type: "FLAT",
        position_uuid: "866cbf60-3d76-4668-8fa6-a7940de194ab",
        return_at_close: 0.995449351390893,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 64253.44,
        close_ms: 1711267135333,
        current_return: 1.0046884649288816,
        initial_entry_price: 64253.44,
        is_closed_position: true,
        miner_hotkey: "5Gn6Ys7QJFUicrXF17KyXqUnCV5tgRgF5RFrqDxHS981fDPf",
        net_leverage: 0.0,
        open_ms: 1711263593595,
        orders: [
          {
            leverage: 1.25,
            order_type: "LONG",
            order_uuid: "59867be5-5bc2-4d4b-a277-7d26ee4c8b8a",
            price: 64253.44,
            price_sources: [],
            processed_ms: 1711263593594,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "0d4caa84-041c-440e-8dbd-a534a0c8327d",
            price: 64494.44,
            price_sources: [],
            processed_ms: 1711267135333,
          },
        ],
        position_type: "FLAT",
        position_uuid: "38e914d1-bb33-44bd-af5a-df668d9af136",
        return_at_close: 1.0021767437665594,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 64591.67,
        close_ms: 1711270787829,
        current_return: 1.012627982834319,
        initial_entry_price: 64591.67,
        is_closed_position: true,
        miner_hotkey: "5Gn6Ys7QJFUicrXF17KyXqUnCV5tgRgF5RFrqDxHS981fDPf",
        net_leverage: 0.0,
        open_ms: 1711267222466,
        orders: [
          {
            leverage: 1.25,
            order_type: "LONG",
            order_uuid: "2adb1c41-7b30-462d-b86b-0dbfc9bf4210",
            price: 64591.67,
            price_sources: [],
            processed_ms: 1711267222465,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "fc8520aa-c8ba-412c-883b-560ba2893660",
            price: 65244.2,
            price_sources: [],
            processed_ms: 1711270787829,
          },
        ],
        position_type: "FLAT",
        position_uuid: "c24cd760-253c-4824-bfe2-922aea7b0b97",
        return_at_close: 1.0100964128772332,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 65243.62999999999,
        close_ms: 1711274497539,
        current_return: 0.9935635478896563,
        initial_entry_price: 65243.63,
        is_closed_position: true,
        miner_hotkey: "5Gn6Ys7QJFUicrXF17KyXqUnCV5tgRgF5RFrqDxHS981fDPf",
        net_leverage: 0.0,
        open_ms: 1711270874459,
        orders: [
          {
            leverage: 1.25,
            order_type: "LONG",
            order_uuid: "4c69e458-933c-45f0-9d2b-bf12c9fd1ca8",
            price: 65243.63,
            price_sources: [],
            processed_ms: 1711270874457,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "37496b25-58ba-4c4f-bc3b-7fd380276dbc",
            price: 64907.68,
            price_sources: [],
            processed_ms: 1711274497539,
          },
        ],
        position_type: "FLAT",
        position_uuid: "0b3841c3-d853-4a2e-b88f-fc1f1560b638",
        return_at_close: 0.9910796390199322,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 64907.68000000001,
        close_ms: 1711278117705,
        current_return: 1.0032910204154577,
        initial_entry_price: 64907.68,
        is_closed_position: true,
        miner_hotkey: "5Gn6Ys7QJFUicrXF17KyXqUnCV5tgRgF5RFrqDxHS981fDPf",
        net_leverage: 0.0,
        open_ms: 1711274516364,
        orders: [
          {
            leverage: 1.25,
            order_type: "LONG",
            order_uuid: "f66785be-8e1f-425f-be1b-1dc6a97ea963",
            price: 64907.68,
            price_sources: [],
            processed_ms: 1711274516363,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "aad28657-ffce-45f7-bc83-64495478820f",
            price: 65078.57,
            price_sources: [],
            processed_ms: 1711278117705,
          },
        ],
        position_type: "FLAT",
        position_uuid: "7d0b84fc-6f85-42ce-ab3e-2644098c2b9d",
        return_at_close: 1.000782792864419,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 64596.51000000001,
        close_ms: 1711300071935,
        current_return: 1.001247241824555,
        initial_entry_price: 65047.53,
        is_closed_position: true,
        miner_hotkey: "5Gn6Ys7QJFUicrXF17KyXqUnCV5tgRgF5RFrqDxHS981fDPf",
        net_leverage: 0.0,
        open_ms: 1711278204448,
        orders: [
          {
            leverage: 1.25,
            order_type: "LONG",
            order_uuid: "7188ac11-d96b-4cf0-88b6-7e16e092e951",
            price: 65047.53,
            price_sources: [],
            processed_ms: 1711278204446,
          },
          {
            leverage: -0.75,
            order_type: "SHORT",
            order_uuid: "72d9f7a3-1528-4612-ba9b-700d376ac2b6",
            price: 65348.21,
            price_sources: [],
            processed_ms: 1711299430613,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "f98ff297-f802-4501-a875-37757e7f1af4",
            price: 64758.77,
            price_sources: [],
            processed_ms: 1711300071935,
          },
        ],
        position_type: "FLAT",
        position_uuid: "9a67f792-5309-4882-9489-cfa0f276c184",
        return_at_close: 0.9987441237199937,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 64830.95,
        close_ms: 1711305403785,
        current_return: 1.0070094323881464,
        initial_entry_price: 65025.95,
        is_closed_position: true,
        miner_hotkey: "5Gn6Ys7QJFUicrXF17KyXqUnCV5tgRgF5RFrqDxHS981fDPf",
        net_leverage: 0.0,
        open_ms: 1711301733064,
        orders: [
          {
            leverage: 0.5,
            order_type: "LONG",
            order_uuid: "f3961b6c-5524-448a-bf18-c03040e23e2a",
            price: 65025.95,
            price_sources: [],
            processed_ms: 1711301733062,
          },
          {
            leverage: 0.5,
            order_type: "LONG",
            order_uuid: "a12eb9d2-8a5a-47c0-a24a-7d4bab8f946b",
            price: 65418.47,
            price_sources: [],
            processed_ms: 1711303544237,
          },
          {
            leverage: -0.5,
            order_type: "SHORT",
            order_uuid: "fd7bfdb9-1d43-4d5d-ba83-00ed69f2d9f3",
            price: 65613.47,
            price_sources: [],
            processed_ms: 1711304470820,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "b875581f-14f5-4972-a4d0-0c49c9368b69",
            price: 65742.54,
            price_sources: [],
            processed_ms: 1711305403785,
          },
        ],
        position_type: "FLAT",
        position_uuid: "c5f82ca4-b5f6-4cae-a96e-af14b7f2ebce",
        return_at_close: 1.00499541352337,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 65090.30999999997,
        close_ms: 1711322161844,
        current_return: 1.0186091062772074,
        initial_entry_price: 65761.89,
        is_closed_position: true,
        miner_hotkey: "5Gn6Ys7QJFUicrXF17KyXqUnCV5tgRgF5RFrqDxHS981fDPf",
        net_leverage: 0.0,
        open_ms: 1711306345985,
        orders: [
          {
            leverage: 0.5,
            order_type: "LONG",
            order_uuid: "91dd8d04-53ef-42ef-8c5a-efe37d8342c0",
            price: 65761.89,
            price_sources: [],
            processed_ms: 1711306345983,
          },
          {
            leverage: 0.5,
            order_type: "LONG",
            order_uuid: "cbf71b26-e791-47e7-964e-274aa8cf6072",
            price: 65750.01,
            price_sources: [],
            processed_ms: 1711307257766,
          },
          {
            leverage: -0.5,
            order_type: "SHORT",
            order_uuid: "f30c1df4-160a-499a-9d48-2ff6f5c1b050",
            price: 65832.71,
            price_sources: [],
            processed_ms: 1711308197927,
          },
          {
            leverage: 0.5,
            order_type: "LONG",
            order_uuid: "a4cc719b-ebba-431d-8963-b86797dcc898",
            price: 65780.04,
            price_sources: [],
            processed_ms: 1711310979747,
          },
          {
            leverage: -0.5,
            order_type: "SHORT",
            order_uuid: "4cf5d07a-6eda-4718-9535-8ad8ce9907b7",
            price: 66413.23,
            price_sources: [],
            processed_ms: 1711311911695,
          },
          {
            leverage: 0.5,
            order_type: "LONG",
            order_uuid: "2a7c0992-712b-419d-ac94-2228054d5ad4",
            price: 66337.85,
            price_sources: [],
            processed_ms: 1711314706890,
          },
          {
            leverage: -0.5,
            order_type: "SHORT",
            order_uuid: "0cb6ff21-cee9-412f-93f7-a79de3c2737f",
            price: 66198.99,
            price_sources: [],
            processed_ms: 1711315641642,
          },
          {
            leverage: 0.5,
            order_type: "LONG",
            order_uuid: "483dce52-79ea-4a3b-8501-fd771f185f2b",
            price: 66867.67,
            price_sources: [],
            processed_ms: 1711318435517,
          },
          {
            leverage: -0.5,
            order_type: "SHORT",
            order_uuid: "eb373e86-37f4-4734-8599-f5580756cd8a",
            price: 66962.22,
            price_sources: [],
            processed_ms: 1711319372165,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "8dc56a8c-ab48-42e5-8bfb-d7ca3efc0517",
            price: 67537.85,
            price_sources: [],
            processed_ms: 1711322161844,
          },
        ],
        position_type: "FLAT",
        position_uuid: "292439ab-6175-4558-b90f-49c8493764e8",
        return_at_close: 1.016571888064653,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 67266.01,
        close_ms: 1711326819702,
        current_return: 0.9967763808199714,
        initial_entry_price: 67266.01,
        is_closed_position: true,
        miner_hotkey: "5Gn6Ys7QJFUicrXF17KyXqUnCV5tgRgF5RFrqDxHS981fDPf",
        net_leverage: 0.0,
        open_ms: 1711323089972,
        orders: [
          {
            leverage: 0.5,
            order_type: "LONG",
            order_uuid: "23bd5051-7c2d-4b31-8a2d-fb0dcce9015d",
            price: 67266.01,
            price_sources: [],
            processed_ms: 1711323089970,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "451f1223-31e3-4f3f-8058-9dc77f9c4abb",
            price: 66832.33,
            price_sources: [],
            processed_ms: 1711326819702,
          },
        ],
        position_type: "FLAT",
        position_uuid: "55a91145-4f82-4805-b382-375b09a2168a",
        return_at_close: 0.9957796044391515,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 69160.68535999999,
        close_ms: 1711384727242,
        current_return: 1.0740234245522995,
        initial_entry_price: 66764.99,
        is_closed_position: true,
        miner_hotkey: "5Gn6Ys7QJFUicrXF17KyXqUnCV5tgRgF5RFrqDxHS981fDPf",
        net_leverage: 0.0,
        open_ms: 1711328689711,
        orders: [
          {
            leverage: 0.5,
            order_type: "LONG",
            order_uuid: "306c6369-887c-4c16-bf36-25950bf460d0",
            price: 66764.99,
            price_sources: [],
            processed_ms: 1711328689709,
          },
          {
            leverage: 0.09999999999999998,
            order_type: "LONG",
            order_uuid: "444c84c6-b502-43f4-a763-562db08b5c32",
            price: 66880.43,
            price_sources: [],
            processed_ms: 1711367325717,
          },
          {
            leverage: 0.09999999999999998,
            order_type: "LONG",
            order_uuid: "e54a18bc-69f8-4def-b190-5c443af7222d",
            price: 67092.12,
            price_sources: [],
            processed_ms: 1711369152960,
          },
          {
            leverage: 0.09999999999999998,
            order_type: "LONG",
            order_uuid: "e1d0463d-4e32-4d87-8813-e7eff6dc6fe0",
            price: 67143.06,
            price_sources: [],
            processed_ms: 1711370989174,
          },
          {
            leverage: 0.09999999999999998,
            order_type: "LONG",
            order_uuid: "3a79290f-7862-417a-89d0-88110f1fcfcc",
            price: 66898.93,
            price_sources: [],
            processed_ms: 1711372818317,
          },
          {
            leverage: 0.09999999999999998,
            order_type: "LONG",
            order_uuid: "30bff244-ee45-4ed9-9339-14943511b12d",
            price: 67236.63,
            price_sources: [],
            processed_ms: 1711374653971,
          },
          {
            leverage: -0.7999999999999999,
            order_type: "SHORT",
            order_uuid: "6b71dd1b-52d7-432f-b48c-9649f5a655f5",
            price: 69235.28,
            price_sources: [],
            processed_ms: 1711376587173,
          },
          {
            leverage: -0.15999999999999998,
            order_type: "SHORT",
            order_uuid: "22ae058f-3e37-44dc-b4c7-0408f5ea501e",
            price: 69358.28,
            price_sources: [],
            processed_ms: 1711376891831,
          },
          {
            leverage: 0.1,
            order_type: "LONG",
            order_uuid: "95e519d8-2796-4ad1-8a4e-5dc55f9a0831",
            price: 69065.38,
            price_sources: [],
            processed_ms: 1711377243730,
          },
          {
            leverage: -0.06999999999999999,
            order_type: "SHORT",
            order_uuid: "835e71fb-b1c5-4260-a2a5-dad855d802aa",
            price: 69244.36,
            price_sources: [],
            processed_ms: 1711378076376,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "93e7daa0-b88c-4200-b8a6-dfed87e267c8",
            price: 69295.36,
            price_sources: [],
            processed_ms: 1711378337881,
          },
          {
            leverage: -0.16,
            order_type: "SHORT",
            order_uuid: "80825323-ab89-4410-8ab8-77a26d7b3f6d",
            price: 69479.79,
            price_sources: [],
            processed_ms: 1711380995978,
          },
          {
            leverage: 4.84,
            order_type: "LONG",
            order_uuid: "249bb168-ec71-4b9d-908f-94501416eddf",
            price: 69651.83,
            price_sources: [],
            processed_ms: 1711384346957,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "a0285578-b7d3-4ece-8834-2048d25f94aa",
            price: 70149.12,
            price_sources: [],
            processed_ms: 1711384727242,
          },
        ],
        position_type: "FLAT",
        position_uuid: "bf46c3c9-cafd-4e83-b1b8-3fd107c6f16f",
        return_at_close: 1.0632831903067765,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 70453.52631578948,
        close_ms: 1711392969504,
        current_return: 1.0513962461932258,
        initial_entry_price: 70289.18,
        is_closed_position: true,
        miner_hotkey: "5Gn6Ys7QJFUicrXF17KyXqUnCV5tgRgF5RFrqDxHS981fDPf",
        net_leverage: 0.0,
        open_ms: 1711386162053,
        orders: [
          {
            leverage: 5.0,
            order_type: "LONG",
            order_uuid: "04e129fa-aa62-49a5-89f0-3a723a25079b",
            price: 70289.18,
            price_sources: [],
            processed_ms: 1711386162049,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "8b37dae7-258a-4809-a488-a82f5fbc6113",
            price: 70729.72,
            price_sources: [],
            processed_ms: 1711387976174,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "3aa71579-6720-472f-81a3-49dc7abc00ba",
            price: 70606.1,
            price_sources: [],
            processed_ms: 1711389802059,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "34ad7c8a-56c3-45a0-bd04-b3229dc872a7",
            price: 70572.58,
            price_sources: [],
            processed_ms: 1711391610992,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "faa3535a-5912-4dd3-a271-db4eed9897e6",
            price: 70833.8,
            price_sources: [],
            processed_ms: 1711392969504,
          },
        ],
        position_type: "FLAT",
        position_uuid: "c8508c4e-e385-48a6-b2f8-3d2bb36b5ac8",
        return_at_close: 1.0314197175155544,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 70815.16045454546,
        close_ms: 1711401233631,
        current_return: 0.9579989872179331,
        initial_entry_price: 70814.84,
        is_closed_position: true,
        miner_hotkey: "5Gn6Ys7QJFUicrXF17KyXqUnCV5tgRgF5RFrqDxHS981fDPf",
        net_leverage: 0.0,
        open_ms: 1711393432835,
        orders: [
          {
            leverage: 5.0,
            order_type: "LONG",
            order_uuid: "7707ee00-89d8-405d-911c-be322d4ad6f1",
            price: 70814.84,
            price_sources: [],
            processed_ms: 1711393432832,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "8c650c0b-1b54-4680-afbf-0a2c0de73be3",
            price: 70708.35,
            price_sources: [],
            processed_ms: 1711395273287,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "25afb31a-18ae-472b-b53b-e2e823521c4f",
            price: 70993.43,
            price_sources: [],
            processed_ms: 1711397066174,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "059dd343-d720-4b31-bbd0-e0233b6013a2",
            price: 70771.53,
            price_sources: [],
            processed_ms: 1711398880108,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "79cac169-7072-4545-ba8d-596d3199ae7b",
            price: 70788.4,
            price_sources: [],
            processed_ms: 1711400689857,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "0c1ebdc6-ede0-4469-bc6e-0647371db826",
            price: 70544.77,
            price_sources: [],
            processed_ms: 1711401233631,
          },
        ],
        position_type: "FLAT",
        position_uuid: "23dadd1d-3047-40bd-b607-d79a7a6f1457",
        return_at_close: 0.9369230094991385,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 65433.318000000014,
        close_ms: 1711415228102,
        current_return: 1.0205714358653202,
        initial_entry_price: 70019.45,
        is_closed_position: true,
        miner_hotkey: "5Gn6Ys7QJFUicrXF17KyXqUnCV5tgRgF5RFrqDxHS981fDPf",
        net_leverage: 0.0,
        open_ms: 1711402509258,
        orders: [
          {
            leverage: 5.0,
            order_type: "LONG",
            order_uuid: "5590aa68-b243-4d8c-888f-65b3b8960af2",
            price: 70019.45,
            price_sources: [],
            processed_ms: 1711402509256,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "5f9eb2a1-e1a9-4637-ae74-272217cb3656",
            price: 70063.81,
            price_sources: [],
            processed_ms: 1711404354262,
          },
          {
            leverage: -3.25,
            order_type: "SHORT",
            order_uuid: "894e142d-2ed8-4f26-a357-147bd2ea45d1",
            price: 70398.77,
            price_sources: [],
            processed_ms: 1711406151906,
          },
          {
            leverage: -1.625,
            order_type: "SHORT",
            order_uuid: "4709e5c5-0309-473e-9af9-f3a15b4da01b",
            price: 70309.85,
            price_sources: [],
            processed_ms: 1711407956054,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "7cda1807-0396-42ce-9582-b4a35d510721",
            price: 70110.31,
            price_sources: [],
            processed_ms: 1711409773298,
          },
          {
            leverage: -1.5625,
            order_type: "SHORT",
            order_uuid: "ddf74ac1-0623-4c45-90a5-7dfd5b902dd2",
            price: 69806.35,
            price_sources: [],
            processed_ms: 1711411587422,
          },
          {
            leverage: -1.25,
            order_type: "SHORT",
            order_uuid: "49371955-4a24-46b4-979d-c70ca7ad045e",
            price: 70230.87,
            price_sources: [],
            processed_ms: 1711413407322,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "2cb1774e-bcd0-4cba-ab88-7a12ad5e7904",
            price: 70042.6,
            price_sources: [],
            processed_ms: 1711415228102,
          },
        ],
        position_type: "FLAT",
        position_uuid: "a76660e5-1f5e-45ab-8dd9-e51a3aeed6bb",
        return_at_close: 1.007304007199071,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 70127.62000000011,
        close_ms: 1711424304102,
        current_return: 0.997660093052495,
        initial_entry_price: 70248.52,
        is_closed_position: true,
        miner_hotkey: "5Gn6Ys7QJFUicrXF17KyXqUnCV5tgRgF5RFrqDxHS981fDPf",
        net_leverage: 0.0,
        open_ms: 1711417034945,
        orders: [
          {
            leverage: -5.0,
            order_type: "SHORT",
            order_uuid: "a584895a-c7b8-4146-921f-7a405097d3b6",
            price: 70248.52,
            price_sources: [],
            processed_ms: 1711417034943,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "71db4a51-6c91-4ee2-91e5-98556ba14986",
            price: 70118.14,
            price_sources: [],
            processed_ms: 1711418863116,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "c74fe0e1-242e-4a69-9481-ca9ebf720664",
            price: 70245.35,
            price_sources: [],
            processed_ms: 1711420677868,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "3fbfac0a-5e4f-43d2-83ba-2bd1198878b3",
            price: 70422.37,
            price_sources: [],
            processed_ms: 1711422517448,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "3f7053b7-24f7-4dfc-b437-4c8a6bd14c98",
            price: 70456.37,
            price_sources: [],
            processed_ms: 1711424304102,
          },
        ],
        position_type: "FLAT",
        position_uuid: "9f82739d-57c5-4e58-b231-331883389ed8",
        return_at_close: 0.98768349212197,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 71753.28999999994,
        close_ms: 1711435207156,
        current_return: 1.0091311211059575,
        initial_entry_price: 70603.05,
        is_closed_position: true,
        miner_hotkey: "5Gn6Ys7QJFUicrXF17KyXqUnCV5tgRgF5RFrqDxHS981fDPf",
        net_leverage: 0.0,
        open_ms: 1711426117980,
        orders: [
          {
            leverage: -5.0,
            order_type: "SHORT",
            order_uuid: "df2347c9-64c0-47fc-9e8a-47aa39eba7df",
            price: 70603.05,
            price_sources: [],
            processed_ms: 1711426117978,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "be3d092c-a89e-4a5e-b549-4e467edd5398",
            price: 70595.47,
            price_sources: [],
            processed_ms: 1711427935315,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "f6819e4b-0173-4b49-b1dd-f3b8e9286503",
            price: 70478.43,
            price_sources: [],
            processed_ms: 1711429754552,
          },
          {
            leverage: 1.0,
            order_type: "LONG",
            order_uuid: "20a89000-9ea4-45be-97cd-cad2e9267e88",
            price: 70323.73,
            price_sources: [],
            processed_ms: 1711431570962,
          },
          {
            leverage: 0.5,
            order_type: "LONG",
            order_uuid: "c179e3f9-4c84-4ff2-8455-9414f0addaef",
            price: 70408.05,
            price_sources: [],
            processed_ms: 1711433387169,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "f44335ba-a2c4-4f3a-8a32-d21b369819f5",
            price: 70463.92,
            price_sources: [],
            processed_ms: 1711435207156,
          },
        ],
        position_type: "FLAT",
        position_uuid: "df6de10c-bd8b-4a68-8a31-9f63b5a3d78f",
        return_at_close: 0.9990398098948979,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 70778.74,
        close_ms: 1711437912886,
        current_return: 0.9599244066791817,
        initial_entry_price: 70778.74,
        is_closed_position: true,
        miner_hotkey: "5Gn6Ys7QJFUicrXF17KyXqUnCV5tgRgF5RFrqDxHS981fDPf",
        net_leverage: 0.0,
        open_ms: 1711437022383,
        orders: [
          {
            leverage: -5.0,
            order_type: "SHORT",
            order_uuid: "0fd903e4-aacd-47c2-9044-d524a6780d12",
            price: 70778.74,
            price_sources: [],
            processed_ms: 1711437022380,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "7f7cdbee-05e3-449f-9137-a6f28bf13225",
            price: 71346.04,
            price_sources: [],
            processed_ms: 1711437912886,
          },
        ],
        position_type: "FLAT",
        position_uuid: "116ae417-4c32-4831-8fad-6a5d2fd04860",
        return_at_close: 0.9503251626123899,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 75620.09999999998,
        close_ms: 1711446124508,
        current_return: 1.032419726430994,
        initial_entry_price: 71141.1,
        is_closed_position: true,
        miner_hotkey: "5Gn6Ys7QJFUicrXF17KyXqUnCV5tgRgF5RFrqDxHS981fDPf",
        net_leverage: 0.0,
        open_ms: 1711438834824,
        orders: [
          {
            leverage: -5.0,
            order_type: "SHORT",
            order_uuid: "26e3c685-0ab5-4804-946f-831e05bf216e",
            price: 71141.1,
            price_sources: [],
            processed_ms: 1711438834599,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "ec0aca5e-cde0-45b3-adc6-3dc953db74d3",
            price: 70728.84,
            price_sources: [],
            processed_ms: 1711440652606,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "eb5f580c-a2c9-4b3f-aaba-f4213b76aaa7",
            price: 70526.82,
            price_sources: [],
            processed_ms: 1711442469527,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "62b0099b-4789-437b-a1a0-8e8a6898090e",
            price: 70674.64,
            price_sources: [],
            processed_ms: 1711444284043,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "6c9f0c1c-a0af-4efc-9c7e-5a7765e6cd3c",
            price: 71007.35,
            price_sources: [],
            processed_ms: 1711446124508,
          },
        ],
        position_type: "FLAT",
        position_uuid: "11f2466c-e0f1-4fe3-bb02-2487ba2f2336",
        return_at_close: 1.022095529166684,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 72540.47428571426,
        close_ms: 1711455182861,
        current_return: 1.0226150736531552,
        initial_entry_price: 71126.62,
        is_closed_position: true,
        miner_hotkey: "5Gn6Ys7QJFUicrXF17KyXqUnCV5tgRgF5RFrqDxHS981fDPf",
        net_leverage: 0.0,
        open_ms: 1711447921961,
        orders: [
          {
            leverage: -5.0,
            order_type: "SHORT",
            order_uuid: "9792ee58-496b-42d5-b6d3-af0a9ce8c663",
            price: 71126.62,
            price_sources: [],
            processed_ms: 1711447921958,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "94e70571-d533-4e5a-b4e9-1d599ca5676b",
            price: 70953.63,
            price_sources: [],
            processed_ms: 1711449737112,
          },
          {
            leverage: 1.75,
            order_type: "LONG",
            order_uuid: "45f5663e-c55c-48db-a16e-d4b902b2b687",
            price: 70782.71,
            price_sources: [],
            processed_ms: 1711451546881,
          },
          {
            leverage: 0.875,
            order_type: "LONG",
            order_uuid: "fcd9baa2-0879-453e-94b1-5048f084d9ac",
            price: 70697.14,
            price_sources: [],
            processed_ms: 1711453361225,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "c917f904-93b6-4330-80f2-8749549d80a0",
            price: 70702.15,
            price_sources: [],
            processed_ms: 1711455182861,
          },
        ],
        position_type: "FLAT",
        position_uuid: "376a5b21-62c3-4c48-8fdd-d4aae0d9d8eb",
        return_at_close: 1.0123889229166236,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 77674.45571428571,
        close_ms: 1711464271154,
        current_return: 1.038444958304197,
        initial_entry_price: 71074.3,
        is_closed_position: true,
        miner_hotkey: "5Gn6Ys7QJFUicrXF17KyXqUnCV5tgRgF5RFrqDxHS981fDPf",
        net_leverage: 0.0,
        open_ms: 1711456999857,
        orders: [
          {
            leverage: -5.0,
            order_type: "SHORT",
            order_uuid: "4812dc6d-9d1c-410a-9af0-7122d33e5050",
            price: 71074.3,
            price_sources: [],
            processed_ms: 1711456999855,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "6191585c-0bce-420a-9103-c7a2a2350a28",
            price: 70909.08,
            price_sources: [],
            processed_ms: 1711458810997,
          },
          {
            leverage: 1.75,
            order_type: "LONG",
            order_uuid: "2d16e55f-5199-4ec2-b5bc-fbd7fc401491",
            price: 70429.99,
            price_sources: [],
            processed_ms: 1711460631082,
          },
          {
            leverage: 1.4,
            order_type: "LONG",
            order_uuid: "5b9d37d8-0bf5-4164-b620-647455f938d0",
            price: 70406.67,
            price_sources: [],
            processed_ms: 1711462455884,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "9a377d39-5a82-4de1-a88c-02f1f7138215",
            price: 69867.46,
            price_sources: [],
            processed_ms: 1711464271154,
          },
        ],
        position_type: "FLAT",
        position_uuid: "d99076be-ebc8-4770-97ea-d092d91e93aa",
        return_at_close: 1.028060508721155,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 57271.97000000005,
        close_ms: 1711493334978,
        current_return: 1.036902551327425,
        initial_entry_price: 70066.1,
        is_closed_position: true,
        miner_hotkey: "5Gn6Ys7QJFUicrXF17KyXqUnCV5tgRgF5RFrqDxHS981fDPf",
        net_leverage: 0.0,
        open_ms: 1711466093769,
        orders: [
          {
            leverage: 5.0,
            order_type: "LONG",
            order_uuid: "7b4aeaa9-1fb9-42d7-8321-309c6963a0e8",
            price: 70066.1,
            price_sources: [],
            processed_ms: 1711466093762,
          },
          {
            leverage: -2.5,
            order_type: "SHORT",
            order_uuid: "9287f2e4-289f-474c-a7f5-5a48ce72ff17",
            price: 70252.89,
            price_sources: [],
            processed_ms: 1711467912669,
          },
          {
            leverage: -2.0,
            order_type: "SHORT",
            order_uuid: "80ad7809-76a4-488b-a815-81d5166ca093",
            price: 70073.59,
            price_sources: [],
            processed_ms: 1711469726826,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "2b08b2ea-366c-4c98-b356-b2a111967c90",
            price: 69550.21,
            price_sources: [],
            processed_ms: 1711471541965,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "f6dba186-8cc9-49a1-907a-3209a8b9fd48",
            price: 69665.33,
            price_sources: [],
            processed_ms: 1711473356055,
          },
          {
            leverage: -1.75,
            order_type: "SHORT",
            order_uuid: "04a828d6-e57e-4fc0-81ab-849090967836",
            price: 70113.27,
            price_sources: [],
            processed_ms: 1711475233027,
          },
          {
            leverage: -0.875,
            order_type: "SHORT",
            order_uuid: "a694f430-4b48-448f-82a9-aceac5bd3798",
            price: 70264.0,
            price_sources: [],
            processed_ms: 1711476987065,
          },
          {
            leverage: -0.4375,
            order_type: "SHORT",
            order_uuid: "d3422b41-bb04-47ae-afd8-46ed280e09fb",
            price: 70385.38,
            price_sources: [],
            processed_ms: 1711478810767,
          },
          {
            leverage: -0.21875,
            order_type: "SHORT",
            order_uuid: "ccdfcc60-41da-4e32-866b-21f65261638c",
            price: 69806.83,
            price_sources: [],
            processed_ms: 1711480628482,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "6e070b5e-ee2f-42de-83f4-1277aeb77a27",
            price: 69787.63,
            price_sources: [],
            processed_ms: 1711482440310,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "94366194-bc1e-4a63-927f-b955b12d205c",
            price: 69621.78,
            price_sources: [],
            processed_ms: 1711484253243,
          },
          {
            leverage: -1.609375,
            order_type: "SHORT",
            order_uuid: "4b7e4d9e-b3e0-4317-8519-2a1a47325ea7",
            price: 69748.81,
            price_sources: [],
            processed_ms: 1711486074274,
          },
          {
            leverage: -0.8046875,
            order_type: "SHORT",
            order_uuid: "6847f9c0-c2ca-4716-a091-a37bb13b9171",
            price: 69871.69,
            price_sources: [],
            processed_ms: 1711487888859,
          },
          {
            leverage: -0.40234375,
            order_type: "SHORT",
            order_uuid: "e841d9d6-8b51-45f6-b379-cdcf08344f94",
            price: 70076.02,
            price_sources: [],
            processed_ms: 1711489717657,
          },
          {
            leverage: -0.201171875,
            order_type: "SHORT",
            order_uuid: "96c150b0-7074-491c-a235-b7e0d68c0fd1",
            price: 69852.83,
            price_sources: [],
            processed_ms: 1711491524885,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "765c0549-a8e1-4a67-b804-7d8211ca8b56",
            price: 70124.75,
            price_sources: [],
            processed_ms: 1711493334978,
          },
        ],
        position_type: "FLAT",
        position_uuid: "71e0cedd-fa93-4fe2-8051-52883cdc9f6e",
        return_at_close: 1.0265335258141508,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 69786.71673469385,
        close_ms: 1711518761782,
        current_return: 1.0027127872451582,
        initial_entry_price: 70363.24,
        is_closed_position: true,
        miner_hotkey: "5Gn6Ys7QJFUicrXF17KyXqUnCV5tgRgF5RFrqDxHS981fDPf",
        net_leverage: 0.0,
        open_ms: 1711500601068,
        orders: [
          {
            leverage: 5.0,
            order_type: "LONG",
            order_uuid: "bb813918-343b-42ec-a05d-01502fec4118",
            price: 70363.24,
            price_sources: [],
            processed_ms: 1711500601065,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "6731b6f0-2580-480c-bcf1-ea0c692fa090",
            price: 70670.08,
            price_sources: [],
            processed_ms: 1711502415067,
          },
          {
            leverage: -3.25,
            order_type: "SHORT",
            order_uuid: "1fbf76fa-38d1-4447-b23b-8af42ae3c7fb",
            price: 70366.71,
            price_sources: [],
            processed_ms: 1711504234878,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "9b2b9f3d-eeda-4ea4-9641-88f520047b6f",
            price: 70208.88,
            price_sources: [],
            processed_ms: 1711506049795,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "4a9b9629-b7a0-4d91-8b7f-e7d84d9844d7",
            price: 70456.35,
            price_sources: [],
            processed_ms: 1711507870076,
          },
          {
            leverage: -3.125,
            order_type: "SHORT",
            order_uuid: "ccd98a28-6e58-42e0-b888-68ca558bef9c",
            price: 70531.52,
            price_sources: [],
            processed_ms: 1711509683211,
          },
          {
            leverage: -1.5625,
            order_type: "SHORT",
            order_uuid: "e41fbd79-1cdd-4e03-96da-5e2c88939cd6",
            price: 70450.49,
            price_sources: [],
            processed_ms: 1711511501407,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "6baf320a-d9b6-4f64-9bd2-26feb49e5249",
            price: 70303.29,
            price_sources: [],
            processed_ms: 1711513311242,
          },
          {
            leverage: -1.53125,
            order_type: "SHORT",
            order_uuid: "c603539a-da5a-4620-81ec-70c61a50c9de",
            price: 70177.02,
            price_sources: [],
            processed_ms: 1711515133930,
          },
          {
            leverage: -1.225,
            order_type: "SHORT",
            order_uuid: "a9fcfe58-c05e-49f6-b265-483f19f179bd",
            price: 70417.68,
            price_sources: [],
            processed_ms: 1711516952839,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "8b8ea850-a6e0-48fe-b640-7afbbb846dab",
            price: 70410.0,
            price_sources: [],
            processed_ms: 1711518761782,
          },
        ],
        position_type: "FLAT",
        position_uuid: "1198f583-08be-41c1-a808-23dfab06be84",
        return_at_close: 0.9896775210109712,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 102756.88999999876,
        close_ms: 1711538794751,
        current_return: 1.0181814993542064,
        initial_entry_price: 70254.73,
        is_closed_position: true,
        miner_hotkey: "5Gn6Ys7QJFUicrXF17KyXqUnCV5tgRgF5RFrqDxHS981fDPf",
        net_leverage: 0.0,
        open_ms: 1711522394804,
        orders: [
          {
            leverage: -5.0,
            order_type: "SHORT",
            order_uuid: "0ee86d2a-f494-4877-9335-4ecebe25ca4d",
            price: 70254.73,
            price_sources: [],
            processed_ms: 1711522394801,
          },
          {
            leverage: 2.5,
            order_type: "LONG",
            order_uuid: "b85304c0-1b45-4d97-875d-cfbb497e4f48",
            price: 70166.53,
            price_sources: [],
            processed_ms: 1711523427026,
          },
          {
            leverage: 1.25,
            order_type: "LONG",
            order_uuid: "9da8e4b3-50e8-4a85-b27e-ae9fa5795298",
            price: 69858.96,
            price_sources: [],
            processed_ms: 1711524894846,
          },
          {
            leverage: 0.625,
            order_type: "LONG",
            order_uuid: "1293683e-ea1d-4976-8cfd-799264c277e8",
            price: 69835.98,
            price_sources: [],
            processed_ms: 1711526684270,
          },
          {
            leverage: 0.3125,
            order_type: "LONG",
            order_uuid: "f40bbefe-e0da-4ed5-91fe-c4737acd7c42",
            price: 69850.0,
            price_sources: [],
            processed_ms: 1711528497060,
          },
          {
            leverage: 0.15625,
            order_type: "LONG",
            order_uuid: "41fecd57-cb52-4823-9645-6d2204004faf",
            price: 69502.96,
            price_sources: [],
            processed_ms: 1711530348162,
          },
          {
            leverage: 0.078125,
            order_type: "LONG",
            order_uuid: "2ee59d7b-60d9-42e3-9624-c5474ad389fa",
            price: 69747.82,
            price_sources: [],
            processed_ms: 1711532137793,
          },
          {
            leverage: 0.0390625,
            order_type: "LONG",
            order_uuid: "89cebeed-1284-403e-aff0-629d0c9a6e14",
            price: 70020.75,
            price_sources: [],
            processed_ms: 1711533955969,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "727e4728-eff4-410e-a06a-8df1db6e0715",
            price: 70057.08,
            price_sources: [],
            processed_ms: 1711538794751,
          },
        ],
        position_type: "FLAT",
        position_uuid: "76da21ef-6e83-4fb3-b1de-6ece54065c22",
        return_at_close: 1.0079996843606644,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 70442.9153846154,
        close_ms: 1711547269435,
        current_return: 0.956360634496911,
        initial_entry_price: 70186.63,
        is_closed_position: true,
        miner_hotkey: "5Gn6Ys7QJFUicrXF17KyXqUnCV5tgRgF5RFrqDxHS981fDPf",
        net_leverage: 0.0,
        open_ms: 1711542415772,
        orders: [
          {
            leverage: 5.0,
            order_type: "LONG",
            order_uuid: "75e6925c-35ce-4f33-baf1-1ed953d63721",
            price: 70186.63,
            price_sources: [],
            processed_ms: 1711542415769,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "9192f26b-b38e-4d1b-ae5c-7c557ae46996",
            price: 71297.2,
            price_sources: [],
            processed_ms: 1711546031796,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "7be8e70e-b582-44a1-8a2a-8e4b5ef166e9",
            price: 69971.7,
            price_sources: [],
            processed_ms: 1711547269435,
          },
        ],
        position_type: "FLAT",
        position_uuid: "237b5246-aa3b-4755-ac37-3a03ffa9c0d4",
        return_at_close: 0.9439279462484511,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 96033.60999999993,
        close_ms: 1711585816150,
        current_return: 1.029648931745648,
        initial_entry_price: 69149.73,
        is_closed_position: true,
        miner_hotkey: "5Gn6Ys7QJFUicrXF17KyXqUnCV5tgRgF5RFrqDxHS981fDPf",
        net_leverage: 0.0,
        open_ms: 1711560496204,
        orders: [
          {
            leverage: -5.0,
            order_type: "SHORT",
            order_uuid: "65835068-06a5-4183-b88d-2cfb93e96048",
            price: 69149.73,
            price_sources: [],
            processed_ms: 1711560496200,
          },
          {
            leverage: 2.5,
            order_type: "LONG",
            order_uuid: "75d307a4-1f16-42e2-9fd4-43fa15fde3fd",
            price: 68557.19,
            price_sources: [],
            processed_ms: 1711564113740,
          },
          {
            leverage: 1.25,
            order_type: "LONG",
            order_uuid: "eafa4711-0915-442e-ac37-96b17050c917",
            price: 68746.98,
            price_sources: [],
            processed_ms: 1711567788214,
          },
          {
            leverage: 0.625,
            order_type: "LONG",
            order_uuid: "248728df-b542-4aae-8242-43dbfeb85e7b",
            price: 68941.36,
            price_sources: [],
            processed_ms: 1711571350970,
          },
          {
            leverage: 0.3125,
            order_type: "LONG",
            order_uuid: "32e3f41e-86f7-4eba-971b-8c630acb862b",
            price: 69149.81,
            price_sources: [],
            processed_ms: 1711574967942,
          },
          {
            leverage: 0.15625,
            order_type: "LONG",
            order_uuid: "182a5b9e-712f-4ff9-a060-33daa9dbd786",
            price: 69218.61,
            price_sources: [],
            processed_ms: 1711578587122,
          },
          {
            leverage: 0.078125,
            order_type: "LONG",
            order_uuid: "f74f618a-678e-49e7-9f0e-397702ee7a7d",
            price: 69200.01,
            price_sources: [],
            processed_ms: 1711582199184,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "7c271e13-1c35-48db-bf89-292ec00716b0",
            price: 69790.85,
            price_sources: [],
            processed_ms: 1711585816150,
          },
        ],
        position_type: "FLAT",
        position_uuid: "a301d356-b3fd-474d-83ac-a2afcdd28a1a",
        return_at_close: 1.0193524424281917,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 51083.329999999114,
        close_ms: 1711632879871,
        current_return: 0.9821771194915235,
        initial_entry_price: 70290.09,
        is_closed_position: true,
        miner_hotkey: "5Gn6Ys7QJFUicrXF17KyXqUnCV5tgRgF5RFrqDxHS981fDPf",
        net_leverage: 0.0,
        open_ms: 1711611130889,
        orders: [
          {
            leverage: -5.0,
            order_type: "SHORT",
            order_uuid: "c1616b1b-3b65-4cae-a169-12d2829a37ec",
            price: 70290.09,
            price_sources: [],
            processed_ms: 1711611130886,
          },
          {
            leverage: 2.5,
            order_type: "LONG",
            order_uuid: "e5d26601-84d3-4845-9041-3a446869b761",
            price: 70531.23,
            price_sources: [],
            processed_ms: 1711614779199,
          },
          {
            leverage: 1.25,
            order_type: "LONG",
            order_uuid: "30663e39-8205-4382-b90d-a3edd4c990c1",
            price: 70651.95,
            price_sources: [],
            processed_ms: 1711618389183,
          },
          {
            leverage: 1.0,
            order_type: "LONG",
            order_uuid: "6647ea5e-84f2-48f6-881e-b54680e2b844",
            price: 70378.31,
            price_sources: [],
            processed_ms: 1711622022248,
          },
          {
            leverage: 0.125,
            order_type: "LONG",
            order_uuid: "e6fef69f-5726-4943-93c2-2d37575f9d78",
            price: 70689.65,
            price_sources: [],
            processed_ms: 1711625637467,
          },
          {
            leverage: 0.0625,
            order_type: "LONG",
            order_uuid: "58f2dde5-29fd-4c79-b8eb-a6c3503bdb56",
            price: 70403.41,
            price_sources: [],
            processed_ms: 1711629250236,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "5c14a367-51c1-45c2-be73-fdf684250eb0",
            price: 71127.68,
            price_sources: [],
            processed_ms: 1711632879871,
          },
        ],
        position_type: "FLAT",
        position_uuid: "d671e134-820f-484c-a49e-de9206ae644d",
        return_at_close: 0.9723553482966083,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 80790.41000000003,
        close_ms: 1711650963928,
        current_return: 1.0349477997822034,
        initial_entry_price: 71433.61,
        is_closed_position: true,
        miner_hotkey: "5Gn6Ys7QJFUicrXF17KyXqUnCV5tgRgF5RFrqDxHS981fDPf",
        net_leverage: 0.0,
        open_ms: 1711636493872,
        orders: [
          {
            leverage: -5.0,
            order_type: "SHORT",
            order_uuid: "ef4fd774-41f9-42c1-b6ca-709ee88b2b67",
            price: 71433.61,
            price_sources: [],
            processed_ms: 1711636493868,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "4bfd58d5-2c25-4d99-9329-80dbcbc13eb9",
            price: 71159.76,
            price_sources: [],
            processed_ms: 1711640107106,
          },
          {
            leverage: 1.75,
            order_type: "LONG",
            order_uuid: "485c76f3-6a84-4b5b-9701-e5ddecf76a1f",
            price: 70973.37,
            price_sources: [],
            processed_ms: 1711643734144,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "3b4c9678-67aa-4a3d-8774-436b5c3548ee",
            price: 70684.94,
            price_sources: [],
            processed_ms: 1711647347913,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "96a3a4f7-dd97-4217-9b6b-5f8ffa46a58e",
            price: 70804.62,
            price_sources: [],
            processed_ms: 1711650963928,
          },
        ],
        position_type: "FLAT",
        position_uuid: "a07d231f-f0f2-4426-b7b4-6f2f16a138cf",
        return_at_close: 1.0245983217843813,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 82127.21999999876,
        close_ms: 1711683522012,
        current_return: 1.0051341034667107,
        initial_entry_price: 70871.49,
        is_closed_position: true,
        miner_hotkey: "5Gn6Ys7QJFUicrXF17KyXqUnCV5tgRgF5RFrqDxHS981fDPf",
        net_leverage: 0.0,
        open_ms: 1711654586541,
        orders: [
          {
            leverage: -5.0,
            order_type: "SHORT",
            order_uuid: "63c4144f-db27-46a6-b494-99470ebd1811",
            price: 70871.49,
            price_sources: [],
            processed_ms: 1711654586537,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "3f67a166-ea5e-4bd0-ae12-3710ddc805c6",
            price: 70689.27,
            price_sources: [],
            processed_ms: 1711658250876,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "c6b1ba88-c9d7-4a99-b9e7-8a86957f8426",
            price: 70843.97,
            price_sources: [],
            processed_ms: 1711661809793,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "ea6a233e-ebc3-4b85-80b5-3c11cc71a563",
            price: 70868.13,
            price_sources: [],
            processed_ms: 1711665429143,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "1af7b8d7-88ab-4f66-8bc3-33184e74ee6c",
            price: 70839.84,
            price_sources: [],
            processed_ms: 1711669054417,
          },
          {
            leverage: 0.125,
            order_type: "LONG",
            order_uuid: "c404c74b-b2ce-4179-8dda-4707e2bbc834",
            price: 70693.08,
            price_sources: [],
            processed_ms: 1711672661035,
          },
          {
            leverage: 0.0625,
            order_type: "LONG",
            order_uuid: "449ff520-3821-4fc1-92d8-50b0ca591724",
            price: 70892.7,
            price_sources: [],
            processed_ms: 1711676304170,
          },
          {
            leverage: 0.03125,
            order_type: "LONG",
            order_uuid: "dcd24092-6b31-4d4c-9f15-c36db8695e3d",
            price: 70768.98,
            price_sources: [],
            processed_ms: 1711679897179,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "903fb0bb-d877-4a6f-9843-e1e1746fbd59",
            price: 70483.65,
            price_sources: [],
            processed_ms: 1711683522012,
          },
        ],
        position_type: "FLAT",
        position_uuid: "41c9bf89-7ac9-4f24-b58d-162b1472bfff",
        return_at_close: 0.9950827624320436,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 70472.04230769232,
        close_ms: 1711692517673,
        current_return: 0.9674162551904125,
        initial_entry_price: 70538.7,
        is_closed_position: true,
        miner_hotkey: "5Gn6Ys7QJFUicrXF17KyXqUnCV5tgRgF5RFrqDxHS981fDPf",
        net_leverage: 0.0,
        open_ms: 1711687161461,
        orders: [
          {
            leverage: 5.0,
            order_type: "LONG",
            order_uuid: "e79d49dc-5415-4406-ad2c-05f12e367ee2",
            price: 70538.7,
            price_sources: [],
            processed_ms: 1711687161456,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "89db5495-9c7a-48d3-b688-766a41924d44",
            price: 70249.85,
            price_sources: [],
            processed_ms: 1711690756284,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "b80111aa-9c67-4391-9803-8c6acd255689",
            price: 70118.44,
            price_sources: [],
            processed_ms: 1711692517673,
          },
        ],
        position_type: "FLAT",
        position_uuid: "fda666d1-22ee-4df8-96d0-6683da313df4",
        return_at_close: 0.9548398438729372,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 70049.76307692307,
        close_ms: 1711704998796,
        current_return: 0.97391144783374,
        initial_entry_price: 70082.08,
        is_closed_position: true,
        miner_hotkey: "5Gn6Ys7QJFUicrXF17KyXqUnCV5tgRgF5RFrqDxHS981fDPf",
        net_leverage: 0.0,
        open_ms: 1711697990048,
        orders: [
          {
            leverage: 5.0,
            order_type: "LONG",
            order_uuid: "795698ec-3818-4cfe-8ea6-0bfaa11568e0",
            price: 70082.08,
            price_sources: [],
            processed_ms: 1711697990043,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "09b8613b-822f-4d60-a708-a3d8be14f96f",
            price: 69942.04,
            price_sources: [],
            processed_ms: 1711701615179,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "eced4afc-9876-46c8-9900-e1d3979c7346",
            price: 69768.48,
            price_sources: [],
            processed_ms: 1711704998796,
          },
        ],
        position_type: "FLAT",
        position_uuid: "0dfae979-5755-47ac-bcb0-bdd8a89b49d1",
        return_at_close: 0.9612505990119014,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: -24788.970026777544,
        close_ms: 1711788469756,
        current_return: 1.0526196928105167,
        initial_entry_price: 69700.17,
        is_closed_position: true,
        miner_hotkey: "5Gn6Ys7QJFUicrXF17KyXqUnCV5tgRgF5RFrqDxHS981fDPf",
        net_leverage: 0.0,
        open_ms: 1711705234145,
        orders: [
          {
            leverage: 5.0,
            order_type: "LONG",
            order_uuid: "c33bb56b-760f-4f0f-bfd5-39844b9ac955",
            price: 69700.17,
            price_sources: [],
            processed_ms: 1711705234141,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "53d61c02-9378-43de-8f0f-3d4e2156fb8c",
            price: 69938.43,
            price_sources: [],
            processed_ms: 1711708847746,
          },
          {
            leverage: -3.25,
            order_type: "SHORT",
            order_uuid: "817c4a0d-ad50-4e38-a4a3-2405c1103347",
            price: 70219.75,
            price_sources: [],
            processed_ms: 1711712500448,
          },
          {
            leverage: -1.625,
            order_type: "SHORT",
            order_uuid: "e8c50d8c-d23f-469a-a337-d1ae426dad6f",
            price: 70097.38,
            price_sources: [],
            processed_ms: 1711716078728,
          },
          {
            leverage: -0.8125,
            order_type: "SHORT",
            order_uuid: "02952892-30a7-4c4b-b952-19b9d8ba1233",
            price: 70536.89,
            price_sources: [],
            processed_ms: 1711719699336,
          },
          {
            leverage: -0.40625,
            order_type: "SHORT",
            order_uuid: "f086dd9d-56cb-413a-b4db-f4eb2420c112",
            price: 70409.34,
            price_sources: [],
            processed_ms: 1711723313314,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "02f82558-a75b-4024-9e17-dcf86e7d3d16",
            price: 69283.16,
            price_sources: [],
            processed_ms: 1711726954285,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "8f8abf36-6af7-4417-90cc-d4c0d7a231a0",
            price: 69330.23,
            price_sources: [],
            processed_ms: 1711730556587,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "574cb975-e9a8-43b6-bdb2-26abea5a848e",
            price: 69431.04,
            price_sources: [],
            processed_ms: 1711734170764,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "31879d20-7849-409c-87be-fe3d7a7765f6",
            price: 69501.39,
            price_sources: [],
            processed_ms: 1711737827170,
          },
          {
            leverage: -3.203125,
            order_type: "SHORT",
            order_uuid: "02e85c41-6e0b-4052-9643-502c13c27e04",
            price: 69502.7,
            price_sources: [],
            processed_ms: 1711741435779,
          },
          {
            leverage: -1.6015625,
            order_type: "SHORT",
            order_uuid: "ee38be2c-909c-4d61-8c20-0f409f0b5b87",
            price: 69619.58,
            price_sources: [],
            processed_ms: 1711745018000,
          },
          {
            leverage: -0.80078125,
            order_type: "SHORT",
            order_uuid: "14de3418-4dd0-4198-ac00-f4ed1b4b43cb",
            price: 69407.76,
            price_sources: [],
            processed_ms: 1711748697071,
          },
          {
            leverage: -0.400390625,
            order_type: "SHORT",
            order_uuid: "6ce7e788-2fd6-4895-a409-866883889285",
            price: 69776.69,
            price_sources: [],
            processed_ms: 1711752253125,
          },
          {
            leverage: -0.2001953125,
            order_type: "SHORT",
            order_uuid: "a7d86adf-3314-4e8b-b1e2-bd82398b2714",
            price: 69937.03,
            price_sources: [],
            processed_ms: 1711755920311,
          },
          {
            leverage: -0.10009765625,
            order_type: "SHORT",
            order_uuid: "5c60c12f-6512-411a-ba18-3681e6b4733c",
            price: 69929.94,
            price_sources: [],
            processed_ms: 1711759484904,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "edc06bfc-f4a1-432f-a3ac-f86ae7afd555",
            price: 70093.29,
            price_sources: [],
            processed_ms: 1711763165749,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "42726846-92c1-473f-ad31-4e9a9c5dd9ac",
            price: 69997.84,
            price_sources: [],
            processed_ms: 1711766719038,
          },
          {
            leverage: -1.550048828125,
            order_type: "SHORT",
            order_uuid: "1d740d90-27ef-41f0-b1e4-a7df4d4ef209",
            price: 69887.82,
            price_sources: [],
            processed_ms: 1711770341317,
          },
          {
            leverage: -0.7750244140625,
            order_type: "SHORT",
            order_uuid: "a586733a-ea2d-471f-bf2d-d68e5d7a8fa8",
            price: 69976.29,
            price_sources: [],
            processed_ms: 1711773969649,
          },
          {
            leverage: -0.38751220703125,
            order_type: "SHORT",
            order_uuid: "47bcaca7-13f4-4957-b289-21f263f336d8",
            price: 70077.88,
            price_sources: [],
            processed_ms: 1711777601436,
          },
          {
            leverage: -0.193756103515625,
            order_type: "SHORT",
            order_uuid: "146f3cd8-0645-4b84-a9e2-0e3d1e9c1019",
            price: 70043.14,
            price_sources: [],
            processed_ms: 1711781215979,
          },
          {
            leverage: -0.1550048828125,
            order_type: "SHORT",
            order_uuid: "62756593-d018-4b9d-ab29-8bd2fa1e8c1a",
            price: 70008.7,
            price_sources: [],
            processed_ms: 1711784846798,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "a562b824-c70a-4b3a-a3f6-3025f4460de4",
            price: 69855.83,
            price_sources: [],
            processed_ms: 1711788469756,
          },
        ],
        position_type: "FLAT",
        position_uuid: "5609e7ca-2d1c-4810-b8ed-4013fdbf8ca7",
        return_at_close: 1.03893563680398,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 63781.36571428704,
        close_ms: 1711813779434,
        current_return: 0.9968854696840838,
        initial_entry_price: 70098.82,
        is_closed_position: true,
        miner_hotkey: "5Gn6Ys7QJFUicrXF17KyXqUnCV5tgRgF5RFrqDxHS981fDPf",
        net_leverage: 0.0,
        open_ms: 1711792072674,
        orders: [
          {
            leverage: -5.0,
            order_type: "SHORT",
            order_uuid: "ae3137d2-a776-4e88-9525-345a704cc392",
            price: 70098.82,
            price_sources: [],
            processed_ms: 1711792072670,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "eeaa5894-93bd-4ed2-b350-dfaec52ae8b6",
            price: 70035.58,
            price_sources: [],
            processed_ms: 1711795782948,
          },
          {
            leverage: 1.75,
            order_type: "LONG",
            order_uuid: "22332b3a-6fd9-4c9b-a8b9-e75d3b1d015c",
            price: 70183.2,
            price_sources: [],
            processed_ms: 1711799309475,
          },
          {
            leverage: 0.875,
            order_type: "LONG",
            order_uuid: "9471e6ec-71f3-49f7-ad2f-b53c06c5a8b8",
            price: 70250.0,
            price_sources: [],
            processed_ms: 1711802929168,
          },
          {
            leverage: 0.7,
            order_type: "LONG",
            order_uuid: "7e831512-02d7-41db-875f-b2c336c8b183",
            price: 70158.5,
            price_sources: [],
            processed_ms: 1711806540674,
          },
          {
            leverage: 0.14000000000000004,
            order_type: "LONG",
            order_uuid: "5c67abcd-9a3b-4051-9fde-c0438d51b0f4",
            price: 70057.73,
            price_sources: [],
            processed_ms: 1711810235308,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "d43d69bd-a32b-4409-9555-358b7c35a43f",
            price: 70019.22,
            price_sources: [],
            processed_ms: 1711813779434,
          },
        ],
        position_type: "FLAT",
        position_uuid: "39a61aa1-2e72-4ee3-815b-780dee47671c",
        return_at_close: 0.9869166149872429,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 63305.77000000022,
        close_ms: 1711860865676,
        current_return: 1.0030496505799276,
        initial_entry_price: 69694.05,
        is_closed_position: true,
        miner_hotkey: "5Gn6Ys7QJFUicrXF17KyXqUnCV5tgRgF5RFrqDxHS981fDPf",
        net_leverage: 0.0,
        open_ms: 1711835613096,
        orders: [
          {
            leverage: 5.0,
            order_type: "LONG",
            order_uuid: "ac34b2e7-2101-4293-b99e-ec034d516752",
            price: 69694.05,
            price_sources: [],
            processed_ms: 1711835613092,
          },
          {
            leverage: -4.0,
            order_type: "SHORT",
            order_uuid: "3151ada5-a608-4e54-b6bc-319890a94eac",
            price: 69714.17,
            price_sources: [],
            processed_ms: 1711839207893,
          },
          {
            leverage: -0.5,
            order_type: "SHORT",
            order_uuid: "d013a10d-f03c-4fb4-ab71-2a831e8eb9ca",
            price: 69678.92,
            price_sources: [],
            processed_ms: 1711842745573,
          },
          {
            leverage: -0.25,
            order_type: "SHORT",
            order_uuid: "d2531c5a-ca1b-4961-a2ff-c46b2e9f90c2",
            price: 69917.66,
            price_sources: [],
            processed_ms: 1711846398308,
          },
          {
            leverage: -0.125,
            order_type: "SHORT",
            order_uuid: "a8ed57a9-86da-46d0-af0e-0f3499faec1c",
            price: 70066.22,
            price_sources: [],
            processed_ms: 1711849988237,
          },
          {
            leverage: -0.0625,
            order_type: "SHORT",
            order_uuid: "258e8ade-172f-4246-b18d-85ed3e8974b9",
            price: 69951.86,
            price_sources: [],
            processed_ms: 1711853596272,
          },
          {
            leverage: -0.03125,
            order_type: "SHORT",
            order_uuid: "c6e96b4a-f39f-4f5b-a787-99bc673093f7",
            price: 69955.87,
            price_sources: [],
            processed_ms: 1711857349774,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "ae0aea1d-f9c3-4b12-8fa4-dd09390bf17d",
            price: 70107.13,
            price_sources: [],
            processed_ms: 1711860865676,
          },
        ],
        position_type: "FLAT",
        position_uuid: "4db33ec2-d219-4652-80d9-c4c86934ab42",
        return_at_close: 0.9930191540741283,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 70329.81000000003,
        close_ms: 1711878939074,
        current_return: 1.0013147116248609,
        initial_entry_price: 70291.08,
        is_closed_position: true,
        miner_hotkey: "5Gn6Ys7QJFUicrXF17KyXqUnCV5tgRgF5RFrqDxHS981fDPf",
        net_leverage: 0.0,
        open_ms: 1711868090595,
        orders: [
          {
            leverage: -5.0,
            order_type: "SHORT",
            order_uuid: "37131b53-9d5e-46bb-abd0-f244a4e8932c",
            price: 70291.08,
            price_sources: [],
            processed_ms: 1711868090591,
          },
          {
            leverage: 2.5,
            order_type: "LONG",
            order_uuid: "71caeb6d-4c3e-4c9e-9adc-0eef69b3ca18",
            price: 70287.25,
            price_sources: [],
            processed_ms: 1711871708097,
          },
          {
            leverage: 1.25,
            order_type: "LONG",
            order_uuid: "2be4d206-9e04-4ad3-b1c3-f5ab0c633e8c",
            price: 70260.01,
            price_sources: [],
            processed_ms: 1711875323237,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "4fe7c36c-ea5e-4350-aee5-67ac1359b7c1",
            price: 70255.88,
            price_sources: [],
            processed_ms: 1711878939074,
          },
        ],
        position_type: "FLAT",
        position_uuid: "62a432b2-7fdd-43a4-a5f5-430167d7a2d0",
        return_at_close: 0.9913015645086123,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 68220.63999999993,
        close_ms: 1711897044741,
        current_return: 0.9833611606353468,
        initial_entry_price: 70296.67,
        is_closed_position: true,
        miner_hotkey: "5Gn6Ys7QJFUicrXF17KyXqUnCV5tgRgF5RFrqDxHS981fDPf",
        net_leverage: 0.0,
        open_ms: 1711882570661,
        orders: [
          {
            leverage: -5.0,
            order_type: "SHORT",
            order_uuid: "a817699d-4d4d-4c24-a744-6821e3ae5fcd",
            price: 70296.67,
            price_sources: [],
            processed_ms: 1711882570657,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "c58c9c9a-bd28-4612-8eb9-49e2bcfb4b48",
            price: 70429.96,
            price_sources: [],
            processed_ms: 1711886215781,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "7816e9d6-ae94-4513-b0b7-464633b4d9c5",
            price: 70519.46,
            price_sources: [],
            processed_ms: 1711889801046,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "88cebc44-5a6d-4176-908f-962022005254",
            price: 70632.6,
            price_sources: [],
            processed_ms: 1711893429251,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "b4d47138-150e-4283-a5da-e0f1b4b867be",
            price: 70559.95,
            price_sources: [],
            processed_ms: 1711897044741,
          },
        ],
        position_type: "FLAT",
        position_uuid: "1bc4a4dd-1d87-4cc2-8860-12c7fd3c29bc",
        return_at_close: 0.9735275490289934,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 69686.87499999994,
        close_ms: 1711915301435,
        current_return: 0.9797845451844033,
        initial_entry_price: 70400.84,
        is_closed_position: true,
        miner_hotkey: "5Gn6Ys7QJFUicrXF17KyXqUnCV5tgRgF5RFrqDxHS981fDPf",
        net_leverage: 0.0,
        open_ms: 1711900800689,
        orders: [
          {
            leverage: -5.0,
            order_type: "SHORT",
            order_uuid: "29d99a03-a240-43ca-8e31-55f6023fb45c",
            price: 70400.84,
            price_sources: [],
            processed_ms: 1711900800681,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "1259d915-4e18-44f2-ae06-3a8ec0d5f311",
            price: 70468.01,
            price_sources: [],
            processed_ms: 1711904296471,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "7e48b3c5-71f5-4d5b-8fee-5d40bb07c504",
            price: 70503.64,
            price_sources: [],
            processed_ms: 1711907911870,
          },
          {
            leverage: 1.0,
            order_type: "LONG",
            order_uuid: "f7079b5c-0658-412f-8d22-55df0a3acb0b",
            price: 70859.85,
            price_sources: [],
            processed_ms: 1711911580099,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "fadac4fa-ce9d-4ac0-9b2f-ec433431b6a5",
            price: 71110.06,
            price_sources: [],
            processed_ms: 1711915301435,
          },
        ],
        position_type: "FLAT",
        position_uuid: "050e3ec0-564e-4c56-9914-037edf06bab5",
        return_at_close: 0.9699866997325592,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 69164.03999999998,
        close_ms: 1711933403104,
        current_return: 0.9859680325560615,
        initial_entry_price: 70843.95,
        is_closed_position: true,
        miner_hotkey: "5Gn6Ys7QJFUicrXF17KyXqUnCV5tgRgF5RFrqDxHS981fDPf",
        net_leverage: 0.0,
        open_ms: 1711918829521,
        orders: [
          {
            leverage: -5.0,
            order_type: "SHORT",
            order_uuid: "9ca4fc31-2b2b-4604-b6fe-b473d46d9291",
            price: 70843.95,
            price_sources: [],
            processed_ms: 1711918829514,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "87bfde9b-df77-46e5-8d01-510263870cef",
            price: 70867.72,
            price_sources: [],
            processed_ms: 1711922506313,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "acee6e2d-e0a8-4a55-ac16-e722d5f219de",
            price: 70976.37,
            price_sources: [],
            processed_ms: 1711926122730,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "7a340cfe-3e88-43f2-8792-42b663172af7",
            price: 71247.73,
            price_sources: [],
            processed_ms: 1711929753170,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "f9d163d5-2e6b-4164-9af7-19f15fba9b09",
            price: 71152.2,
            price_sources: [],
            processed_ms: 1711933403104,
          },
        ],
        position_type: "FLAT",
        position_uuid: "1d5afa4a-ac60-438e-9b57-714900d97a66",
        return_at_close: 0.9761083522305009,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 83244.63999999949,
        close_ms: 1711959138697,
        current_return: 1.0242186105984161,
        initial_entry_price: 70894.55,
        is_closed_position: true,
        miner_hotkey: "5Gn6Ys7QJFUicrXF17KyXqUnCV5tgRgF5RFrqDxHS981fDPf",
        net_leverage: 0.0,
        open_ms: 1711936989757,
        orders: [
          {
            leverage: -5.0,
            order_type: "SHORT",
            order_uuid: "893706f5-1fb9-4839-99b3-ebf4f3133b72",
            price: 70894.55,
            price_sources: [],
            processed_ms: 1711936989747,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "8ad9af5e-1d03-483d-a0dc-c19f7d1f77da",
            price: 70906.36,
            price_sources: [],
            processed_ms: 1711940619950,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "f912db45-c966-4655-b70b-a20d11e86bdf",
            price: 70606.85,
            price_sources: [],
            processed_ms: 1711944238224,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "51045187-67ed-4813-a9bd-2b7f2b2062e8",
            price: 70487.42,
            price_sources: [],
            processed_ms: 1711947934375,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "0654c384-d18b-4b7e-a728-f4fc5bf2df47",
            price: 69383.7,
            price_sources: [],
            processed_ms: 1711951795119,
          },
          {
            leverage: 0.125,
            order_type: "LONG",
            order_uuid: "47c2a4f4-19c8-45f0-aa4c-ca9c0b987abf",
            price: 69762.4,
            price_sources: [],
            processed_ms: 1711955424433,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "c5cd979f-88ef-4cd6-b00a-174cdbb22285",
            price: 69508.9,
            price_sources: [],
            processed_ms: 1711959138697,
          },
        ],
        position_type: "FLAT",
        position_uuid: "f09d7130-dec9-4a73-9dd8-c68cd303d7f2",
        return_at_close: 1.0139764244924319,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 71876.99999999962,
        close_ms: 1711988908556,
        current_return: 1.0022885201042253,
        initial_entry_price: 69579.9,
        is_closed_position: true,
        miner_hotkey: "5Gn6Ys7QJFUicrXF17KyXqUnCV5tgRgF5RFrqDxHS981fDPf",
        net_leverage: 0.0,
        open_ms: 1711962780571,
        orders: [
          {
            leverage: -5.0,
            order_type: "SHORT",
            order_uuid: "9df410e3-b6ef-4ef9-87b3-cbff5933f5ec",
            price: 69579.9,
            price_sources: [],
            processed_ms: 1711962780566,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "a781d563-3988-4873-ae6a-e9a801607ac4",
            price: 69463.1,
            price_sources: [],
            processed_ms: 1711966299875,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "121250bf-0205-4516-b0a9-8e03e5ec3b74",
            price: 69699.5,
            price_sources: [],
            processed_ms: 1711969916559,
          },
          {
            leverage: 1.0,
            order_type: "LONG",
            order_uuid: "105b9d60-e762-4ace-881c-a4a0e504b0f8",
            price: 69577.9,
            price_sources: [],
            processed_ms: 1711973545310,
          },
          {
            leverage: 0.5,
            order_type: "LONG",
            order_uuid: "e8724ed8-40a3-45eb-9d8a-88de0550c670",
            price: 69807.0,
            price_sources: [],
            processed_ms: 1711977209076,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "d29f2bf0-a21f-4208-9612-28d722751ae8",
            price: 69751.8,
            price_sources: [],
            processed_ms: 1711980804638,
          },
          {
            leverage: 0.2,
            order_type: "LONG",
            order_uuid: "f720e6e6-a0f5-4cd0-b59d-3af8cb0a4919",
            price: 68212.0,
            price_sources: [],
            processed_ms: 1711984441346,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "5865de2b-baf6-4f32-9e0a-b36b60aaafed",
            price: 68692.3,
            price_sources: [],
            processed_ms: 1711988908556,
          },
        ],
        position_type: "FLAT",
        position_uuid: "fcee3e12-075c-43fa-96e8-11462e028f84",
        return_at_close: 0.9922656349031831,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 69300.01304347825,
        close_ms: 1712031045665,
        current_return: 0.5678025802133012,
        initial_entry_price: 68420.7,
        is_closed_position: true,
        miner_hotkey: "5Gn6Ys7QJFUicrXF17KyXqUnCV5tgRgF5RFrqDxHS981fDPf",
        net_leverage: 11.5,
        open_ms: 1711992530534,
        orders: [
          {
            leverage: 5.0,
            order_type: "LONG",
            order_uuid: "8c55554b-a65b-4d47-9f61-64b29c140698",
            price: 68420.7,
            price_sources: [],
            processed_ms: 1711992530529,
          },
          {
            leverage: -2.5,
            order_type: "SHORT",
            order_uuid: "9e3b987e-99eb-433a-9a7c-31e0d7f47993",
            price: 68851.8,
            price_sources: [],
            processed_ms: 1711997322819,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "6a48db23-3299-4b27-aca8-7409072d4d64",
            price: 69418.8,
            price_sources: [],
            processed_ms: 1712001000638,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "9ed6dc88-e041-424b-a008-3692108ab21e",
            price: 69784.9,
            price_sources: [],
            processed_ms: 1712006350718,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "fbf756a4-30f8-44a2-845f-d3e54518fc1c",
            price: 69745.0,
            price_sources: [],
            processed_ms: 1712009975554,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "86d0dafc-7cc1-4ea1-9300-49139a38ee08",
            price: 69836.3,
            price_sources: [],
            processed_ms: 1712015348260,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "a1008d33-d9a6-4d94-a192-b473a59b0fe1",
            price: 69862.1,
            price_sources: [],
            processed_ms: 1712015418420,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "8f2bb6a3-16e7-4467-94d4-fd69ed3193ce",
            price: 69337.0,
            price_sources: [],
            processed_ms: 1712018974564,
          },
        ],
        position_type: "FLAT",
        position_uuid: "b1d94ab0-c7c4-4c80-9c97-d5b54c00d436",
        return_at_close: 0.5547431208683953,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
    ],
    thirty_day_returns: 1.0,
  },
  "5Gp8ifdA1WGbKfhZZBAKKmATRoK4YoQZwyFwgVkqnHDSino7": {
    all_time_returns: 1.0,
    n_positions: 0,
    percentage_profitable: 0.0,
    positions: [
      {
        average_entry_price: 64223.16999999999,
        close_ms: 1711194961499,
        current_return: 1.0032473326993983,
        initial_entry_price: 64223.17,
        is_closed_position: true,
        miner_hotkey: "5Gp8ifdA1WGbKfhZZBAKKmATRoK4YoQZwyFwgVkqnHDSino7",
        net_leverage: 0.0,
        open_ms: 1711191377577,
        orders: [
          {
            leverage: 0.6,
            order_type: "LONG",
            order_uuid: "51dfb683-1fff-49dd-b46a-980124eb8dea",
            price: 64223.17,
            price_sources: [],
            processed_ms: 1711191377576,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "af225099-e432-4a74-a584-8d85245160fe",
            price: 64570.76,
            price_sources: [],
            processed_ms: 1711194961499,
          },
        ],
        position_type: "FLAT",
        position_uuid: "15761f52-18c1-49a7-84b8-294c8ba77d88",
        return_at_close: 1.002043435900159,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 64576.1,
        close_ms: 1711198655477,
        current_return: 1.001317886958178,
        initial_entry_price: 64576.1,
        is_closed_position: true,
        miner_hotkey: "5Gp8ifdA1WGbKfhZZBAKKmATRoK4YoQZwyFwgVkqnHDSino7",
        net_leverage: 0.0,
        open_ms: 1711195064682,
        orders: [
          {
            leverage: 0.6,
            order_type: "LONG",
            order_uuid: "fa6cdd40-eca3-4944-9aa0-5cadad83be46",
            price: 64576.1,
            price_sources: [],
            processed_ms: 1711195064681,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "33c5da7f-482d-4bd7-83b2-2e8d140e63a8",
            price: 64717.94,
            price_sources: [],
            processed_ms: 1711198655477,
          },
        ],
        position_type: "FLAT",
        position_uuid: "b68c8429-173b-4644-adca-dd9fb0fb2677",
        return_at_close: 1.0001163054938282,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 64717.94000000001,
        close_ms: 1711202279894,
        current_return: 0.9988996250498702,
        initial_entry_price: 64717.94,
        is_closed_position: true,
        miner_hotkey: "5Gp8ifdA1WGbKfhZZBAKKmATRoK4YoQZwyFwgVkqnHDSino7",
        net_leverage: 0.0,
        open_ms: 1711198675442,
        orders: [
          {
            leverage: 0.6,
            order_type: "LONG",
            order_uuid: "bcb21c81-6c75-4b33-b635-18b23b90010f",
            price: 64717.94,
            price_sources: [],
            processed_ms: 1711198675441,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "a5d31867-a1ee-4a09-831b-210eacc280df",
            price: 64599.25,
            price_sources: [],
            processed_ms: 1711202279894,
          },
        ],
        position_type: "FLAT",
        position_uuid: "0e0c8e37-2545-4c8a-afb6-c14bbe1f6790",
        return_at_close: 0.9977009454998104,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 64597.28999999999,
        close_ms: 1711205946678,
        current_return: 1.0064390317302785,
        initial_entry_price: 64597.29,
        is_closed_position: true,
        miner_hotkey: "5Gp8ifdA1WGbKfhZZBAKKmATRoK4YoQZwyFwgVkqnHDSino7",
        net_leverage: 0.0,
        open_ms: 1711202415104,
        orders: [
          {
            leverage: 0.6,
            order_type: "LONG",
            order_uuid: "9ff39b69-d62f-4ecc-918a-a74ebcd1a92e",
            price: 64597.29,
            price_sources: [],
            processed_ms: 1711202415103,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "625abd9d-8b3c-4393-adc2-3b98511046f2",
            price: 65290.53,
            price_sources: [],
            processed_ms: 1711205946678,
          },
        ],
        position_type: "FLAT",
        position_uuid: "6e3105a7-9047-4828-ac14-ea9d09ef65a4",
        return_at_close: 1.0052313048922021,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 65255.37,
        close_ms: 1711209607112,
        current_return: 0.9986864529310001,
        initial_entry_price: 65255.37,
        is_closed_position: true,
        miner_hotkey: "5Gp8ifdA1WGbKfhZZBAKKmATRoK4YoQZwyFwgVkqnHDSino7",
        net_leverage: 0.0,
        open_ms: 1711206032707,
        orders: [
          {
            leverage: 0.6,
            order_type: "LONG",
            order_uuid: "08e68f29-5f4d-4087-97e3-b0d51d5c0e0b",
            price: 65255.37,
            price_sources: [],
            processed_ms: 1711206032706,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "d12ece18-978d-4007-9d48-37b71765fe97",
            price: 65112.51,
            price_sources: [],
            processed_ms: 1711209607112,
          },
        ],
        position_type: "FLAT",
        position_uuid: "ac6372a5-0e0c-4c20-a15a-68f255f5ca58",
        return_at_close: 0.9974880291874829,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 65111.06,
        close_ms: 1711213289299,
        current_return: 1.0057509123641974,
        initial_entry_price: 65111.06,
        is_closed_position: true,
        miner_hotkey: "5Gp8ifdA1WGbKfhZZBAKKmATRoK4YoQZwyFwgVkqnHDSino7",
        net_leverage: 0.0,
        open_ms: 1711209693653,
        orders: [
          {
            leverage: 0.6,
            order_type: "LONG",
            order_uuid: "de60df69-bd42-4ad4-b1e8-be62eee3ddf0",
            price: 65111.06,
            price_sources: [],
            processed_ms: 1711209693652,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "24277810-999f-43d6-9aec-c56af51eaa27",
            price: 65735.14,
            price_sources: [],
            processed_ms: 1711213289299,
          },
        ],
        position_type: "FLAT",
        position_uuid: "8abd6363-90e6-40fa-b796-9c54e893dfa0",
        return_at_close: 1.0045440112693604,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 65710.89,
        close_ms: 1711216928639,
        current_return: 0.9992488003130074,
        initial_entry_price: 65710.89,
        is_closed_position: true,
        miner_hotkey: "5Gp8ifdA1WGbKfhZZBAKKmATRoK4YoQZwyFwgVkqnHDSino7",
        net_leverage: 0.0,
        open_ms: 1711213392857,
        orders: [
          {
            leverage: 0.6,
            order_type: "LONG",
            order_uuid: "48b413e1-1f0a-4777-8c3e-cd34fc906a3e",
            price: 65710.89,
            price_sources: [],
            processed_ms: 1711213392856,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "da04b7d9-7b80-4ca5-9147-a38541599320",
            price: 65628.62,
            price_sources: [],
            processed_ms: 1711216928639,
          },
        ],
        position_type: "FLAT",
        position_uuid: "415fc3dd-aa08-45fb-ae5e-3509ef18141c",
        return_at_close: 0.9980497017526319,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 65579.0,
        close_ms: 1711220605025,
        current_return: 0.9988486558196984,
        initial_entry_price: 65579.0,
        is_closed_position: true,
        miner_hotkey: "5Gp8ifdA1WGbKfhZZBAKKmATRoK4YoQZwyFwgVkqnHDSino7",
        net_leverage: 0.0,
        open_ms: 1711217015706,
        orders: [
          {
            leverage: 0.6,
            order_type: "LONG",
            order_uuid: "8833afd7-7000-494d-914f-cdef8685a6a4",
            price: 65579.0,
            price_sources: [],
            processed_ms: 1711217015706,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "1cc1639d-bb6e-4509-afb1-d9e8e10a7932",
            price: 65453.16,
            price_sources: [],
            processed_ms: 1711220605025,
          },
        ],
        position_type: "FLAT",
        position_uuid: "45ddd9e5-5e54-4b48-b02d-9f295b8c5813",
        return_at_close: 0.9976500374327147,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 65405.21,
        close_ms: 1711224252055,
        current_return: 0.9958163883274742,
        initial_entry_price: 65405.21,
        is_closed_position: true,
        miner_hotkey: "5Gp8ifdA1WGbKfhZZBAKKmATRoK4YoQZwyFwgVkqnHDSino7",
        net_leverage: 0.0,
        open_ms: 1711220711263,
        orders: [
          {
            leverage: 0.6,
            order_type: "LONG",
            order_uuid: "41fb7027-1716-4225-8aa7-7e44414a4f0d",
            price: 65405.21,
            price_sources: [],
            processed_ms: 1711220711261,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "d5346335-8fb6-48ff-823a-04687f287600",
            price: 64949.16,
            price_sources: [],
            processed_ms: 1711224252055,
          },
        ],
        position_type: "FLAT",
        position_uuid: "1a4d2393-f211-448f-b242-4beed09ed615",
        return_at_close: 0.9946214086614813,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 64945.37,
        close_ms: 1711227915818,
        current_return: 0.9995934121246827,
        initial_entry_price: 64945.37,
        is_closed_position: true,
        miner_hotkey: "5Gp8ifdA1WGbKfhZZBAKKmATRoK4YoQZwyFwgVkqnHDSino7",
        net_leverage: 0.0,
        open_ms: 1711224383338,
        orders: [
          {
            leverage: 0.6,
            order_type: "LONG",
            order_uuid: "0f4a2701-c2c0-4e6c-9fcd-a591a884f034",
            price: 64945.37,
            price_sources: [],
            processed_ms: 1711224383337,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "fef7b220-ad90-460a-88f1-85cdbb68c5b0",
            price: 64901.36,
            price_sources: [],
            processed_ms: 1711227915818,
          },
        ],
        position_type: "FLAT",
        position_uuid: "cf608ba4-0514-4c6a-92e8-8c7a81b76bc7",
        return_at_close: 0.9983939000301331,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 64889.72000000001,
        close_ms: 1711231569848,
        current_return: 1.0006596422360892,
        initial_entry_price: 64889.72,
        is_closed_position: true,
        miner_hotkey: "5Gp8ifdA1WGbKfhZZBAKKmATRoK4YoQZwyFwgVkqnHDSino7",
        net_leverage: 0.0,
        open_ms: 1711228002717,
        orders: [
          {
            leverage: 0.6,
            order_type: "LONG",
            order_uuid: "a8ac5011-b36d-4e11-9d1e-8bb5ecad143c",
            price: 64889.72,
            price_sources: [],
            processed_ms: 1711228002716,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "ad22ea12-64f7-4044-af92-6012ce3a0b49",
            price: 64961.06,
            price_sources: [],
            processed_ms: 1711231569848,
          },
        ],
        position_type: "FLAT",
        position_uuid: "7377ddc9-7e02-410c-9e57-5d2c6e8664c9",
        return_at_close: 0.9994588506654059,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 64964.60999999999,
        close_ms: 1711235240076,
        current_return: 0.9983496552969379,
        initial_entry_price: 64964.61,
        is_closed_position: true,
        miner_hotkey: "5Gp8ifdA1WGbKfhZZBAKKmATRoK4YoQZwyFwgVkqnHDSino7",
        net_leverage: 0.0,
        open_ms: 1711231656728,
        orders: [
          {
            leverage: 0.6,
            order_type: "LONG",
            order_uuid: "163d64ad-64bb-42d2-82da-4a9975e0668e",
            price: 64964.61,
            price_sources: [],
            processed_ms: 1711231656727,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "deca19ec-3b15-4d0e-8f10-4e1f56cf18af",
            price: 64785.92,
            price_sources: [],
            processed_ms: 1711235240076,
          },
        ],
        position_type: "FLAT",
        position_uuid: "37c77d18-86b2-4818-b35c-f6a0a5daad88",
        return_at_close: 0.9971516357105815,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 64747.07,
        close_ms: 1711238892798,
        current_return: 0.9950672671365669,
        initial_entry_price: 64747.07,
        is_closed_position: true,
        miner_hotkey: "5Gp8ifdA1WGbKfhZZBAKKmATRoK4YoQZwyFwgVkqnHDSino7",
        net_leverage: 0.0,
        open_ms: 1711235379499,
        orders: [
          {
            leverage: 0.6,
            order_type: "LONG",
            order_uuid: "44226bd7-4d73-4f7d-b3f4-d45120d4c946",
            price: 64747.07,
            price_sources: [],
            processed_ms: 1711235379498,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "dbc37302-1c64-4e60-bde2-056131232e4f",
            price: 64214.77,
            price_sources: [],
            processed_ms: 1711238892798,
          },
        ],
        position_type: "FLAT",
        position_uuid: "51071274-b6a0-4627-8625-2c952ee1519b",
        return_at_close: 0.9938731864160031,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 64272.10999999999,
        close_ms: 1711242575257,
        current_return: 1.0022958325158455,
        initial_entry_price: 64272.11,
        is_closed_position: true,
        miner_hotkey: "5Gp8ifdA1WGbKfhZZBAKKmATRoK4YoQZwyFwgVkqnHDSino7",
        net_leverage: 0.0,
        open_ms: 1711238979649,
        orders: [
          {
            leverage: 0.6,
            order_type: "LONG",
            order_uuid: "db1051f3-c054-4bba-a642-e4831ddf2388",
            price: 64272.11,
            price_sources: [],
            processed_ms: 1711238979647,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "c66487d2-0636-4f1f-8e07-c5dfb2b55e3d",
            price: 64518.04,
            price_sources: [],
            processed_ms: 1711242575257,
          },
        ],
        position_type: "FLAT",
        position_uuid: "683fc7c1-0f65-48d1-a508-2911080ad634",
        return_at_close: 1.0010930775168265,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 64502.69,
        close_ms: 1711246217903,
        current_return: 0.9998642847298306,
        initial_entry_price: 64502.69,
        is_closed_position: true,
        miner_hotkey: "5Gp8ifdA1WGbKfhZZBAKKmATRoK4YoQZwyFwgVkqnHDSino7",
        net_leverage: 0.0,
        open_ms: 1711242678736,
        orders: [
          {
            leverage: 0.6,
            order_type: "LONG",
            order_uuid: "50180144-b5c0-43a6-9267-8bdfd5ec30c1",
            price: 64502.69,
            price_sources: [],
            processed_ms: 1711242678735,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "f35b4cfb-b554-4dd6-88bb-e568ff1b434f",
            price: 64488.1,
            price_sources: [],
            processed_ms: 1711246217903,
          },
        ],
        position_type: "FLAT",
        position_uuid: "e6f1c7d4-4126-4e42-b36c-92e1d1a4fb5d",
        return_at_close: 0.9986644475881548,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 64484.340000000004,
        close_ms: 1711249913850,
        current_return: 0.9955603174352098,
        initial_entry_price: 64484.34,
        is_closed_position: true,
        miner_hotkey: "5Gp8ifdA1WGbKfhZZBAKKmATRoK4YoQZwyFwgVkqnHDSino7",
        net_leverage: 0.0,
        open_ms: 1711246304841,
        orders: [
          {
            leverage: 0.6,
            order_type: "LONG",
            order_uuid: "066d5936-3731-49d7-9f60-4fab46efb46f",
            price: 64484.34,
            price_sources: [],
            processed_ms: 1711246304840,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "29d9a47f-131b-4e4f-8082-622ae23daa4c",
            price: 64007.19,
            price_sources: [],
            processed_ms: 1711249913850,
          },
        ],
        position_type: "FLAT",
        position_uuid: "8ba23ed3-eb26-4220-8e5b-e557e197cd3c",
        return_at_close: 0.9943656450542876,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 64007.19,
        close_ms: 1711253545864,
        current_return: 1.000974890477148,
        initial_entry_price: 64007.19,
        is_closed_position: true,
        miner_hotkey: "5Gp8ifdA1WGbKfhZZBAKKmATRoK4YoQZwyFwgVkqnHDSino7",
        net_leverage: 0.0,
        open_ms: 1711249933333,
        orders: [
          {
            leverage: 0.6,
            order_type: "LONG",
            order_uuid: "029427d8-c92d-424b-9fff-afd71a665016",
            price: 64007.19,
            price_sources: [],
            processed_ms: 1711249933332,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "9d8558cc-7656-4c47-9eed-885598cb6882",
            price: 64111.19,
            price_sources: [],
            processed_ms: 1711253545864,
          },
        ],
        position_type: "FLAT",
        position_uuid: "84428e92-7e75-447d-8070-25ddc8f4e877",
        return_at_close: 0.9997737206085755,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 64115.39,
        close_ms: 1711257645757,
        current_return: 1.0012475319888094,
        initial_entry_price: 64115.39,
        is_closed_position: true,
        miner_hotkey: "5Gp8ifdA1WGbKfhZZBAKKmATRoK4YoQZwyFwgVkqnHDSino7",
        net_leverage: 0.0,
        open_ms: 1711253632738,
        orders: [
          {
            leverage: 0.6,
            order_type: "LONG",
            order_uuid: "b7a1f7b0-9fed-4d08-b798-03ceeac265c2",
            price: 64115.39,
            price_sources: [],
            processed_ms: 1711253632736,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "e63a1826-29d9-445f-9ff3-40ec2ef0b315",
            price: 64248.7,
            price_sources: [],
            processed_ms: 1711257645757,
          },
        ],
        position_type: "FLAT",
        position_uuid: "843b8e91-7473-4af1-91cb-e422f6ecd482",
        return_at_close: 1.000046034950423,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 64323.23,
        close_ms: 1711261311875,
        current_return: 0.9999391743853658,
        initial_entry_price: 64323.23,
        is_closed_position: true,
        miner_hotkey: "5Gp8ifdA1WGbKfhZZBAKKmATRoK4YoQZwyFwgVkqnHDSino7",
        net_leverage: 0.0,
        open_ms: 1711257733923,
        orders: [
          {
            leverage: 1.25,
            order_type: "LONG",
            order_uuid: "3a446f43-e282-49de-9b4d-63c93f057c7b",
            price: 64323.23,
            price_sources: [],
            processed_ms: 1711257732995,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "d7e9fefb-2e30-4f8a-9b6e-2ff74e5b260f",
            price: 64320.1,
            price_sources: [],
            processed_ms: 1711261311875,
          },
        ],
        position_type: "FLAT",
        position_uuid: "74cd465b-f235-4c97-9719-ee93c7f0fba9",
        return_at_close: 0.9974393264494025,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 64313.469999999994,
        close_ms: 1711264966171,
        current_return: 0.9963596273066903,
        initial_entry_price: 64313.47,
        is_closed_position: true,
        miner_hotkey: "5Gp8ifdA1WGbKfhZZBAKKmATRoK4YoQZwyFwgVkqnHDSino7",
        net_leverage: 0.0,
        open_ms: 1711261434935,
        orders: [
          {
            leverage: 1.25,
            order_type: "LONG",
            order_uuid: "fdc5ecef-cb83-45f4-9a5b-05d5c087daeb",
            price: 64313.47,
            price_sources: [],
            processed_ms: 1711261434933,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "48c5bfdf-4f0a-4b87-b174-a367a6a2f8ce",
            price: 64126.17,
            price_sources: [],
            processed_ms: 1711264966171,
          },
        ],
        position_type: "FLAT",
        position_uuid: "d371a470-b32e-4049-8e7d-c09e4b945f6e",
        return_at_close: 0.9938687282384235,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 64149.7,
        close_ms: 1711268625931,
        current_return: 1.0127087500019487,
        initial_entry_price: 64149.7,
        is_closed_position: true,
        miner_hotkey: "5Gp8ifdA1WGbKfhZZBAKKmATRoK4YoQZwyFwgVkqnHDSino7",
        net_leverage: 0.0,
        open_ms: 1711265051632,
        orders: [
          {
            leverage: 1.25,
            order_type: "LONG",
            order_uuid: "640c8b10-e905-412e-8da7-dea01fd384a3",
            price: 64149.7,
            price_sources: [],
            processed_ms: 1711265051631,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "2c187c65-200d-4902-b6bb-6f6e2edb5e54",
            price: 64801.91,
            price_sources: [],
            processed_ms: 1711268625931,
          },
        ],
        position_type: "FLAT",
        position_uuid: "a6fb4ffe-3e65-46be-ab38-838a8e78c438",
        return_at_close: 1.0101769781269438,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 64898.259999999995,
        close_ms: 1711275954180,
        current_return: 1.003793437605261,
        initial_entry_price: 64898.26,
        is_closed_position: true,
        miner_hotkey: "5Gp8ifdA1WGbKfhZZBAKKmATRoK4YoQZwyFwgVkqnHDSino7",
        net_leverage: 0.0,
        open_ms: 1711272395723,
        orders: [
          {
            leverage: 1.25,
            order_type: "LONG",
            order_uuid: "799da5aa-6d1d-4a7e-869b-8d866e9ebb18",
            price: 64898.26,
            price_sources: [],
            processed_ms: 1711272395722,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "9d4702fa-9a21-4a93-ac58-c6e8d8e02bac",
            price: 65095.21,
            price_sources: [],
            processed_ms: 1711275954180,
          },
        ],
        position_type: "FLAT",
        position_uuid: "0bf4fe1a-26ee-4f72-834f-2cff3d8734ce",
        return_at_close: 1.001283954011248,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 65544.65999999999,
        close_ms: 1711315036542,
        current_return: 1.015948445113015,
        initial_entry_price: 65094.12,
        is_closed_position: true,
        miner_hotkey: "5Gp8ifdA1WGbKfhZZBAKKmATRoK4YoQZwyFwgVkqnHDSino7",
        net_leverage: 0.0,
        open_ms: 1711276041951,
        orders: [
          {
            leverage: 1.25,
            order_type: "LONG",
            order_uuid: "a2cc9564-10b3-40cf-8de3-23719a8f18cd",
            price: 65094.12,
            price_sources: [],
            processed_ms: 1711276041949,
          },
          {
            leverage: 1.25,
            order_type: "LONG",
            order_uuid: "8ce20d63-5fd3-40cc-b267-3ce40977649e",
            price: 65052.03,
            price_sources: [],
            processed_ms: 1711279818895,
          },
          {
            leverage: -1.25,
            order_type: "SHORT",
            order_uuid: "4443326c-f7d7-41f6-9ce5-a0c55b951dfc",
            price: 65289.3,
            price_sources: [],
            processed_ms: 1711281614802,
          },
          {
            leverage: 1.25,
            order_type: "LONG",
            order_uuid: "850cc75d-70be-4ec3-81de-c5068f57cfc3",
            price: 65736.8,
            price_sources: [],
            processed_ms: 1711285333854,
          },
          {
            leverage: -1.25,
            order_type: "SHORT",
            order_uuid: "e49cca3a-8fe8-4d49-91cd-877c967f73e3",
            price: 65450.5,
            price_sources: [],
            processed_ms: 1711287110589,
          },
          {
            leverage: 1.25,
            order_type: "LONG",
            order_uuid: "c8aac9b9-ff7c-49a9-a347-4e9373482140",
            price: 65775.44,
            price_sources: [],
            processed_ms: 1711290767817,
          },
          {
            leverage: -1.25,
            order_type: "SHORT",
            order_uuid: "c447e59b-dd19-4d6d-b0d6-321b773307a8",
            price: 65633.73,
            price_sources: [],
            processed_ms: 1711292597879,
          },
          {
            leverage: 1.25,
            order_type: "LONG",
            order_uuid: "a595d6a1-e1cf-4104-8806-2023f86cc79d",
            price: 65783.43,
            price_sources: [],
            processed_ms: 1711296280039,
          },
          {
            leverage: -1.25,
            order_type: "SHORT",
            order_uuid: "e1853013-41df-4b46-b7a0-22e00ae10658",
            price: 65605.38,
            price_sources: [],
            processed_ms: 1711298079745,
          },
          {
            leverage: 1.25,
            order_type: "LONG",
            order_uuid: "ddc2880e-2e0b-439e-b58d-d98da311a551",
            price: 66235.38,
            price_sources: [],
            processed_ms: 1711313131741,
          },
          {
            leverage: -1.25,
            order_type: "SHORT",
            order_uuid: "15d8b0cb-bf82-4837-bc82-277cf8d39135",
            price: 66153.63,
            price_sources: [],
            processed_ms: 1711314064851,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "1cdd2dc6-339a-4371-beb1-1b5cb9a99a06",
            price: 66375.18,
            price_sources: [],
            processed_ms: 1711315036542,
          },
        ],
        position_type: "FLAT",
        position_uuid: "8f53a1fd-b668-42a5-ad2d-d7da8a45e148",
        return_at_close: 1.01086870288745,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 65958.27,
        close_ms: 1711318726818,
        current_return: 1.0154766416701282,
        initial_entry_price: 66463.87,
        is_closed_position: true,
        miner_hotkey: "5Gp8ifdA1WGbKfhZZBAKKmATRoK4YoQZwyFwgVkqnHDSino7",
        net_leverage: 0.0,
        open_ms: 1711315928972,
        orders: [
          {
            leverage: 1.25,
            order_type: "LONG",
            order_uuid: "a1171cda-ac71-4024-b0d1-56b2b10766b7",
            price: 66463.87,
            price_sources: [],
            processed_ms: 1711315928971,
          },
          {
            leverage: 1.25,
            order_type: "LONG",
            order_uuid: "89b8acd1-bae1-4356-9722-a556248ec696",
            price: 66263.13,
            price_sources: [],
            processed_ms: 1711316853831,
          },
          {
            leverage: -1.25,
            order_type: "SHORT",
            order_uuid: "639716c9-35d2-4d39-ac16-824f23650810",
            price: 66768.73,
            price_sources: [],
            processed_ms: 1711317790722,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "8adf0bee-db34-42ec-8319-c7212d39b53f",
            price: 66781.18,
            price_sources: [],
            processed_ms: 1711318726818,
          },
        ],
        position_type: "FLAT",
        position_uuid: "69c65de1-35a4-4026-8ddd-d5fe4f51e66d",
        return_at_close: 1.0103992584617776,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 127306.0585714249,
        close_ms: 1711328992685,
        current_return: 1.003155633493135,
        initial_entry_price: 67174.84,
        is_closed_position: true,
        miner_hotkey: "5Gp8ifdA1WGbKfhZZBAKKmATRoK4YoQZwyFwgVkqnHDSino7",
        net_leverage: 0.0,
        open_ms: 1711321522674,
        orders: [
          {
            leverage: -1.25,
            order_type: "SHORT",
            order_uuid: "5a23e5ee-7759-4597-bab8-b131955c35d0",
            price: 67174.84,
            price_sources: [],
            processed_ms: 1711321522672,
          },
          {
            leverage: 0.125,
            order_type: "LONG",
            order_uuid: "81d6a817-6b3f-4aa4-b475-76421f8b936f",
            price: 67567.32,
            price_sources: [],
            processed_ms: 1711322454694,
          },
          {
            leverage: 0.125,
            order_type: "LONG",
            order_uuid: "c08c131b-eb67-4064-ae17-f439b1a54732",
            price: 67271.93,
            price_sources: [],
            processed_ms: 1711323387683,
          },
          {
            leverage: 0.125,
            order_type: "LONG",
            order_uuid: "1bf56a0a-7fa8-4b64-bc70-49ea2d011229",
            price: 67334.41,
            price_sources: [],
            processed_ms: 1711324314995,
          },
          {
            leverage: 0.7,
            order_type: "LONG",
            order_uuid: "758868d2-3c86-4740-9409-a4282733bd5f",
            price: 66865.16,
            price_sources: [],
            processed_ms: 1711325247317,
          },
          {
            leverage: 0.08750000000000002,
            order_type: "LONG",
            order_uuid: "d20249ee-57b3-494c-8cbd-c64edef70628",
            price: 66875.89,
            price_sources: [],
            processed_ms: 1711326188584,
          },
          {
            leverage: 0.07000000000000002,
            order_type: "LONG",
            order_uuid: "9f06ac10-a1f1-4f45-979f-f3b112cbc974",
            price: 66601.0,
            price_sources: [],
            processed_ms: 1711327116688,
          },
          {
            leverage: 0.014000000000000002,
            order_type: "LONG",
            order_uuid: "05da9082-14b4-47a7-b72d-db3a9694ec9d",
            price: 66567.78,
            price_sources: [],
            processed_ms: 1711328046821,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "a927cd63-5fcf-49b8-864d-f0a4657a1069",
            price: 66740.58,
            price_sources: [],
            processed_ms: 1711328992685,
          },
        ],
        position_type: "FLAT",
        position_uuid: "e1a086c9-5b2d-4b99-9b19-398574144f24",
        return_at_close: 1.0006477444094022,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 66480.83,
        close_ms: 1711335398732,
        current_return: 1.0039204910648678,
        initial_entry_price: 66480.83,
        is_closed_position: true,
        miner_hotkey: "5Gp8ifdA1WGbKfhZZBAKKmATRoK4YoQZwyFwgVkqnHDSino7",
        net_leverage: 0.0,
        open_ms: 1711331735411,
        orders: [
          {
            leverage: 1.25,
            order_type: "LONG",
            order_uuid: "b93549dc-5eb2-4669-b333-48909ab8a0bc",
            price: 66480.83,
            price_sources: [],
            processed_ms: 1711331734316,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "7e811dea-7f0b-4931-87d4-c6a1e8cb9dce",
            price: 66689.34,
            price_sources: [],
            processed_ms: 1711335398732,
          },
        ],
        position_type: "FLAT",
        position_uuid: "62bf431d-1219-4620-83e1-b772b92f3b3a",
        return_at_close: 1.0014106898372057,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 119491.43999999933,
        close_ms: 1711355560515,
        current_return: 1.0024284379336337,
        initial_entry_price: 67525.3,
        is_closed_position: true,
        miner_hotkey: "5Gp8ifdA1WGbKfhZZBAKKmATRoK4YoQZwyFwgVkqnHDSino7",
        net_leverage: 0.0,
        open_ms: 1711342723954,
        orders: [
          {
            leverage: -1.25,
            order_type: "SHORT",
            order_uuid: "d5d6d5d9-2083-481d-ac2c-4acd5d3d32cb",
            price: 67525.3,
            price_sources: [],
            processed_ms: 1711342723953,
          },
          {
            leverage: 0.625,
            order_type: "LONG",
            order_uuid: "c90cadb5-40d7-41eb-9934-d61d9cf80216",
            price: 67500.0,
            price_sources: [],
            processed_ms: 1711344574561,
          },
          {
            leverage: 0.3125,
            order_type: "LONG",
            order_uuid: "5194178c-71e2-42cb-a1ef-594e256b37cd",
            price: 67417.23,
            price_sources: [],
            processed_ms: 1711346398566,
          },
          {
            leverage: 0.15625,
            order_type: "LONG",
            order_uuid: "22142c23-1d37-46e1-937c-5ec8605b8a99",
            price: 67172.63,
            price_sources: [],
            processed_ms: 1711348230182,
          },
          {
            leverage: 0.125,
            order_type: "LONG",
            order_uuid: "1c33e17a-f760-4c3c-ae16-c8eb2b944fce",
            price: 67174.42,
            price_sources: [],
            processed_ms: 1711350059742,
          },
          {
            leverage: 0.015625,
            order_type: "LONG",
            order_uuid: "2538aa9a-91ff-4719-abe3-5b8d99f2049d",
            price: 67152.66,
            price_sources: [],
            processed_ms: 1711351888857,
          },
          {
            leverage: 0.0125,
            order_type: "LONG",
            order_uuid: "cfb02e52-bda4-474f-9d49-3e7e54e5b066",
            price: 66883.49,
            price_sources: [],
            processed_ms: 1711353722749,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "6df82098-882a-44cb-8887-9bb36054d7cb",
            price: 67017.52,
            price_sources: [],
            processed_ms: 1711355560515,
          },
        ],
        position_type: "FLAT",
        position_uuid: "067f9b90-4c9a-4764-be22-e45aa36ac0a4",
        return_at_close: 0.9999223668387996,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 66984.42,
        close_ms: 1711361055818,
        current_return: 1.002564402289368,
        initial_entry_price: 66984.42,
        is_closed_position: true,
        miner_hotkey: "5Gp8ifdA1WGbKfhZZBAKKmATRoK4YoQZwyFwgVkqnHDSino7",
        net_leverage: 0.0,
        open_ms: 1711357389233,
        orders: [
          {
            leverage: 1.25,
            order_type: "LONG",
            order_uuid: "4cd51c6b-9f46-4105-96a7-8214d7c60468",
            price: 66984.42,
            price_sources: [],
            processed_ms: 1711357389230,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "8b0731ce-83ea-40dc-8150-326415b08c03",
            price: 67121.84,
            price_sources: [],
            processed_ms: 1711361055818,
          },
        ],
        position_type: "FLAT",
        position_uuid: "bbb573cf-3061-49ae-91a3-c80282921013",
        return_at_close: 1.0000579912836447,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: -885562.9943137361,
        close_ms: 1711386012316,
        current_return: 1.0145483273923857,
        initial_entry_price: 67015.12,
        is_closed_position: true,
        miner_hotkey: "5Gp8ifdA1WGbKfhZZBAKKmATRoK4YoQZwyFwgVkqnHDSino7",
        net_leverage: 0.0,
        open_ms: 1711364710095,
        orders: [
          {
            leverage: 1.25,
            order_type: "LONG",
            order_uuid: "74fb83db-f29b-4a0f-a5b1-9b0104c3bc2b",
            price: 67015.12,
            price_sources: [],
            processed_ms: 1711364710093,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "0b7656e2-5967-418c-a929-cc283c86549b",
            price: 66889.87,
            price_sources: [],
            processed_ms: 1711366722817,
          },
          {
            leverage: -0.75,
            order_type: "SHORT",
            order_uuid: "0edfd040-3fe1-48bb-bd83-17f1fc26ee0c",
            price: 67159.91,
            price_sources: [],
            processed_ms: 1711369905913,
          },
          {
            leverage: -0.375,
            order_type: "SHORT",
            order_uuid: "11ffe3d8-5570-4c02-8ad2-99969e3c96eb",
            price: 67105.11,
            price_sources: [],
            processed_ms: 1711371758948,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "dbc06c5d-41ef-4a02-93b9-aa46f6972341",
            price: 67188.75,
            price_sources: [],
            processed_ms: 1711373564800,
          },
          {
            leverage: -0.5,
            order_type: "SHORT",
            order_uuid: "b515bd95-4eb8-4166-be3e-444569cee2a0",
            price: 68187.31,
            price_sources: [],
            processed_ms: 1711375392227,
          },
          {
            leverage: -0.1,
            order_type: "SHORT",
            order_uuid: "93c16720-5635-46b8-8e30-ff0ad44c92ff",
            price: 68868.54,
            price_sources: [],
            processed_ms: 1711376447153,
          },
          {
            leverage: -0.019999999999999997,
            order_type: "SHORT",
            order_uuid: "20d843b0-c400-4dd9-bc0a-452ec7018519",
            price: 69210.38,
            price_sources: [],
            processed_ms: 1711376765227,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "4748fa5f-1c68-424f-9c40-4ed6f6625c0f",
            price: 69095.56,
            price_sources: [],
            processed_ms: 1711377901820,
          },
          {
            leverage: -0.20400000000000001,
            order_type: "SHORT",
            order_uuid: "b014a3b7-2d30-461f-93ab-fa1284dabeb1",
            price: 69061.84,
            price_sources: [],
            processed_ms: 1711378209598,
          },
          {
            leverage: -0.04079999999999999,
            order_type: "SHORT",
            order_uuid: "d3c2e198-bc9f-4f22-8842-458035da50f3",
            price: 69543.81,
            price_sources: [],
            processed_ms: 1711380567981,
          },
          {
            leverage: -0.00816,
            order_type: "SHORT",
            order_uuid: "f835980a-b06d-4474-93db-801381340405",
            price: 69810.35,
            price_sources: [],
            processed_ms: 1711382417473,
          },
          {
            leverage: -0.00102,
            order_type: "SHORT",
            order_uuid: "0e880b78-718f-44db-afaf-fdb7a844125a",
            price: 69606.51,
            price_sources: [],
            processed_ms: 1711384202282,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "bb13b92c-ff13-4d2e-a726-bb100ef595c2",
            price: 70278.09,
            price_sources: [],
            processed_ms: 1711386012316,
          },
        ],
        position_type: "FLAT",
        position_uuid: "d44e4c81-045e-4f2f-9906-a3fe0f6c3231",
        return_at_close: 1.0115046824102085,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 70711.92,
        close_ms: 1711391485984,
        current_return: 0.9998518637310371,
        initial_entry_price: 70711.92,
        is_closed_position: true,
        miner_hotkey: "5Gp8ifdA1WGbKfhZZBAKKmATRoK4YoQZwyFwgVkqnHDSino7",
        net_leverage: 0.0,
        open_ms: 1711387838559,
        orders: [
          {
            leverage: -1.25,
            order_type: "SHORT",
            order_uuid: "7190c637-3bb6-4d09-8086-9ee0683a916d",
            price: 70711.92,
            price_sources: [],
            processed_ms: 1711387838557,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "05d30481-782a-4f5a-be3f-3484867456d8",
            price: 70720.3,
            price_sources: [],
            processed_ms: 1711391485984,
          },
        ],
        position_type: "FLAT",
        position_uuid: "d939137e-a23b-418f-9b40-d239ad10cf82",
        return_at_close: 0.9973522340717096,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 70522.74000000008,
        close_ms: 1711400589196,
        current_return: 0.9974667592412279,
        initial_entry_price: 70861.8,
        is_closed_position: true,
        miner_hotkey: "5Gp8ifdA1WGbKfhZZBAKKmATRoK4YoQZwyFwgVkqnHDSino7",
        net_leverage: 0.0,
        open_ms: 1711393305409,
        orders: [
          {
            leverage: -5.0,
            order_type: "SHORT",
            order_uuid: "4f26739b-c02a-4282-8a6b-7aff58ab96dd",
            price: 70861.8,
            price_sources: [],
            processed_ms: 1711393305406,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "294d4873-e5f5-42bc-82fc-9dc424244bbc",
            price: 70672.94,
            price_sources: [],
            processed_ms: 1711395116003,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "a1743fec-77a7-499b-9e75-bb44993bc9b1",
            price: 71201.01,
            price_sources: [],
            processed_ms: 1711396942159,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "75a2948e-3dd0-450f-bf14-a67851275550",
            price: 70824.47,
            price_sources: [],
            processed_ms: 1711398753020,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "1504e98d-17b5-4684-8a39-22a3dd77e53f",
            price: 70881.76,
            price_sources: [],
            processed_ms: 1711400589196,
          },
        ],
        position_type: "FLAT",
        position_uuid: "99032fc3-7a47-44f3-a807-7c61a0540037",
        return_at_close: 0.9874920916488156,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 69514.39153846154,
        close_ms: 1711406653506,
        current_return: 1.0556235424028964,
        initial_entry_price: 69948.17,
        is_closed_position: true,
        miner_hotkey: "5Gp8ifdA1WGbKfhZZBAKKmATRoK4YoQZwyFwgVkqnHDSino7",
        net_leverage: 0.0,
        open_ms: 1711402399219,
        orders: [
          {
            leverage: 5.0,
            order_type: "LONG",
            order_uuid: "5683b1b0-02c8-458d-a219-994528627c1a",
            price: 69948.17,
            price_sources: [],
            processed_ms: 1711402399217,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "31a681b9-1ca4-4599-b5c3-f9e07a808789",
            price: 70021.84,
            price_sources: [],
            processed_ms: 1711404222213,
          },
          {
            leverage: -3.25,
            order_type: "SHORT",
            order_uuid: "fe1f40a1-090d-4ead-bafe-e241953f326b",
            price: 70415.95,
            price_sources: [],
            processed_ms: 1711406083103,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "38d65930-2c36-4545-9126-e765ee91ec50",
            price: 70711.55,
            price_sources: [],
            processed_ms: 1711406653506,
          },
        ],
        position_type: "FLAT",
        position_uuid: "ae8aa10a-6474-4c3b-83d2-9662239d05ac",
        return_at_close: 1.0419004363516586,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 69048.90526315803,
        close_ms: 1711422394578,
        current_return: 1.0223434942896243,
        initial_entry_price: 70129.39,
        is_closed_position: true,
        miner_hotkey: "5Gp8ifdA1WGbKfhZZBAKKmATRoK4YoQZwyFwgVkqnHDSino7",
        net_leverage: 0.0,
        open_ms: 1711409665856,
        orders: [
          {
            leverage: 5.0,
            order_type: "LONG",
            order_uuid: "2c0e5ef0-7f29-4a5c-99a7-9feb68e563a3",
            price: 70129.39,
            price_sources: [],
            processed_ms: 1711409665853,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "747373de-a159-4ddf-9c6a-4fd7c66557eb",
            price: 69789.99,
            price_sources: [],
            processed_ms: 1711411485300,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "c09690b0-fbd6-411a-9ecf-6832f7999d48",
            price: 70115.67,
            price_sources: [],
            processed_ms: 1711413305404,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "3384da77-1877-4229-ac57-de2a445872c8",
            price: 69961.36,
            price_sources: [],
            processed_ms: 1711415151777,
          },
          {
            leverage: -4.75,
            order_type: "SHORT",
            order_uuid: "b3a4326a-41e1-4bfe-b827-72d4e3654421",
            price: 70207.4,
            price_sources: [],
            processed_ms: 1711416949139,
          },
          {
            leverage: -2.375,
            order_type: "SHORT",
            order_uuid: "47385bd5-de44-4d2d-ad8a-3b762c3c3382",
            price: 70134.28,
            price_sources: [],
            processed_ms: 1711418753443,
          },
          {
            leverage: -1.1875,
            order_type: "SHORT",
            order_uuid: "9d7fdd9c-8e07-4a32-9bd4-339427059bfc",
            price: 70229.76,
            price_sources: [],
            processed_ms: 1711420569792,
          },
          {
            leverage: -5.0,
            order_type: "SHORT",
            order_uuid: "11adef0d-559b-4679-a87f-c7d09a05d31c",
            price: 70368.43,
            price_sources: [],
            processed_ms: 1711422394578,
          },
        ],
        position_type: "FLAT",
        position_uuid: "a0cad78f-a90f-49a9-82b9-4fa02ca7e6f7",
        return_at_close: 1.0029189678981214,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 70517.96750000001,
        close_ms: 1711431504825,
        current_return: 0.9834424219520289,
        initial_entry_price: 70386.2,
        is_closed_position: true,
        miner_hotkey: "5Gp8ifdA1WGbKfhZZBAKKmATRoK4YoQZwyFwgVkqnHDSino7",
        net_leverage: 0.0,
        open_ms: 1711424213828,
        orders: [
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "91ccafe8-de36-48c2-82ea-fb0b42b82de7",
            price: 70386.2,
            price_sources: [],
            processed_ms: 1711424213826,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "4e5d263f-ca1f-4918-bb1d-894fa279807a",
            price: 70647.58,
            price_sources: [],
            processed_ms: 1711426016990,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "e0f4f67c-0929-405e-8607-e6b63cd39cff",
            price: 70564.42,
            price_sources: [],
            processed_ms: 1711427852006,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "3375b61a-c34c-49e9-bcbf-613d803b2a10",
            price: 70473.67,
            price_sources: [],
            processed_ms: 1711429690206,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "de7c077f-ba06-4ed5-98af-fe862e9fad3d",
            price: 70323.73,
            price_sources: [],
            processed_ms: 1711431504825,
          },
        ],
        position_type: "FLAT",
        position_uuid: "d632abb3-1f1c-4ca5-ba6d-4f6ed41408d6",
        return_at_close: 0.9716411128886046,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 70015.45249999996,
        close_ms: 1711437834017,
        current_return: 0.9674082982647035,
        initial_entry_price: 70402.43,
        is_closed_position: true,
        miner_hotkey: "5Gp8ifdA1WGbKfhZZBAKKmATRoK4YoQZwyFwgVkqnHDSino7",
        net_leverage: 0.0,
        open_ms: 1711433295650,
        orders: [
          {
            leverage: -5.0,
            order_type: "SHORT",
            order_uuid: "6f3701c8-d565-4b24-800c-47aebf1a7ff4",
            price: 70402.43,
            price_sources: [],
            processed_ms: 1711433295645,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "f83c981d-f3b7-4eed-9705-d46205e4d965",
            price: 70500.1,
            price_sources: [],
            processed_ms: 1711435116997,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "c397f3c9-f62c-4505-a4b7-ee5e0e23779b",
            price: 70820.73,
            price_sources: [],
            processed_ms: 1711436935165,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "d613182b-d02f-41c9-97a7-1e645f94eaf2",
            price: 71162.72,
            price_sources: [],
            processed_ms: 1711437834017,
          },
        ],
        position_type: "FLAT",
        position_uuid: "43f1aee1-7a2a-462b-b8cd-23447b598e9e",
        return_at_close: 0.9577342152820565,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 71267.99,
        close_ms: 1711439984972,
        current_return: 1.0501880297171282,
        initial_entry_price: 71267.99,
        is_closed_position: true,
        miner_hotkey: "5Gp8ifdA1WGbKfhZZBAKKmATRoK4YoQZwyFwgVkqnHDSino7",
        net_leverage: 0.0,
        open_ms: 1711438747146,
        orders: [
          {
            leverage: -5.0,
            order_type: "SHORT",
            order_uuid: "cf5a319b-5637-4427-bf88-27a87c552428",
            price: 71267.99,
            price_sources: [],
            processed_ms: 1711438747143,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "860823fa-b984-4e53-8cb0-e41c9c050234",
            price: 70552.63,
            price_sources: [],
            processed_ms: 1711439984972,
          },
        ],
        position_type: "FLAT",
        position_uuid: "2292968d-7709-4df7-8cec-3d2a44308d00",
        return_at_close: 1.039686149419957,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 65805.27461538454,
        close_ms: 1711453291402,
        current_return: 1.0140426605290562,
        initial_entry_price: 70727.85,
        is_closed_position: true,
        miner_hotkey: "5Gp8ifdA1WGbKfhZZBAKKmATRoK4YoQZwyFwgVkqnHDSino7",
        net_leverage: 0.0,
        open_ms: 1711440567545,
        orders: [
          {
            leverage: 5.0,
            order_type: "LONG",
            order_uuid: "b7b71f03-c679-4e59-a3cf-f35304ca916c",
            price: 70727.85,
            price_sources: [],
            processed_ms: 1711440567541,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "7912c780-2ebc-4e9c-bb46-a1635c8d35fa",
            price: 70521.37,
            price_sources: [],
            processed_ms: 1711442381852,
          },
          {
            leverage: -3.25,
            order_type: "SHORT",
            order_uuid: "6cd3c248-b709-4955-967d-6811b5d31368",
            price: 70674.64,
            price_sources: [],
            processed_ms: 1711444245961,
          },
          {
            leverage: -1.625,
            order_type: "SHORT",
            order_uuid: "722735b1-a049-4d2e-8599-3b241bfd7e69",
            price: 71014.31,
            price_sources: [],
            processed_ms: 1711446015193,
          },
          {
            leverage: -0.8125,
            order_type: "SHORT",
            order_uuid: "ccc872a4-7740-4122-a7bb-de6294e854e0",
            price: 71096.18,
            price_sources: [],
            processed_ms: 1711447845238,
          },
          {
            leverage: -0.40625,
            order_type: "SHORT",
            order_uuid: "a2a7449b-efcf-4eab-8ef2-4d56797320f3",
            price: 70950.02,
            price_sources: [],
            processed_ms: 1711449661310,
          },
          {
            leverage: -0.203125,
            order_type: "SHORT",
            order_uuid: "54c62c05-2bd9-4715-87d5-0d5870fa9353",
            price: 70767.67,
            price_sources: [],
            processed_ms: 1711451475167,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "98a13ebb-6431-4160-a966-84295765ddfd",
            price: 70694.91,
            price_sources: [],
            processed_ms: 1711453291402,
          },
        ],
        position_type: "FLAT",
        position_uuid: "1c7c97ca-43e5-4601-bedd-073749fd5f0f",
        return_at_close: 1.0008601059421784,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 70115.32529411765,
        close_ms: 1711463509143,
        current_return: 0.9784635004934945,
        initial_entry_price: 70699.86,
        is_closed_position: true,
        miner_hotkey: "5Gp8ifdA1WGbKfhZZBAKKmATRoK4YoQZwyFwgVkqnHDSino7",
        net_leverage: 0.0,
        open_ms: 1711455106200,
        orders: [
          {
            leverage: 5.0,
            order_type: "LONG",
            order_uuid: "2de9a28b-99f4-4bfd-8dd2-d60cd1f76ded",
            price: 70699.86,
            price_sources: [],
            processed_ms: 1711455106197,
          },
          {
            leverage: -2.5,
            order_type: "SHORT",
            order_uuid: "f229eed1-5e95-4410-9d7e-83e5b88e6836",
            price: 71060.06,
            price_sources: [],
            processed_ms: 1711456918184,
          },
          {
            leverage: -1.25,
            order_type: "SHORT",
            order_uuid: "84b80263-d972-41a0-bc0e-448775a93a4c",
            price: 70947.07,
            price_sources: [],
            processed_ms: 1711458741090,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "2c9fc510-78f2-41d0-91f8-87af7cbfb14c",
            price: 70129.07,
            price_sources: [],
            processed_ms: 1711460555138,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "2e58a0b7-2e05-4e85-bb59-74657c64e585",
            price: 70420.81,
            price_sources: [],
            processed_ms: 1711462372078,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "16c788cc-b9c6-4e4b-a19d-ac339127bca4",
            price: 69757.06,
            price_sources: [],
            processed_ms: 1711463509143,
          },
        ],
        position_type: "FLAT",
        position_uuid: "00a04082-d5b1-4f38-a324-c2ff060f8048",
        return_at_close: 0.9686788654885595,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 69905.57437500001,
        close_ms: 1711468208773,
        current_return: 1.0502223323097188,
        initial_entry_price: 69762.69,
        is_closed_position: true,
        miner_hotkey: "5Gp8ifdA1WGbKfhZZBAKKmATRoK4YoQZwyFwgVkqnHDSino7",
        net_leverage: 0.0,
        open_ms: 1711464189038,
        orders: [
          {
            leverage: 5.0,
            order_type: "LONG",
            order_uuid: "13fe413c-5248-4382-941e-33d23b9370cd",
            price: 69762.69,
            price_sources: [],
            processed_ms: 1711464189035,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "90d61e19-b5a1-4173-b92b-1c97bc329169",
            price: 70101.47,
            price_sources: [],
            processed_ms: 1711466003085,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "621a1be7-b0b0-4ad4-a407-24c360d5ebd6",
            price: 70185.96,
            price_sources: [],
            processed_ms: 1711467839122,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "05ad6493-3ee2-475e-8fb2-083ac74e0573",
            price: 70343.53,
            price_sources: [],
            processed_ms: 1711468208773,
          },
        ],
        position_type: "FLAT",
        position_uuid: "86956c74-bd4a-4346-9600-697adebf103d",
        return_at_close: 1.0334187749927632,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 70078.49,
        close_ms: 1711471344961,
        current_return: 0.9686394498511591,
        initial_entry_price: 70078.49,
        is_closed_position: true,
        miner_hotkey: "5Gp8ifdA1WGbKfhZZBAKKmATRoK4YoQZwyFwgVkqnHDSino7",
        net_leverage: 0.0,
        open_ms: 1711469655442,
        orders: [
          {
            leverage: 5.0,
            order_type: "LONG",
            order_uuid: "c5db7642-c76d-44fb-a0af-6486837767a3",
            price: 70078.49,
            price_sources: [],
            processed_ms: 1711469655437,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "53bdd39b-c524-4ce2-baf7-17ae5bda3406",
            price: 69638.95,
            price_sources: [],
            processed_ms: 1711471344961,
          },
        ],
        position_type: "FLAT",
        position_uuid: "971dd1c5-c66c-49a4-a77e-5760ae5c78aa",
        return_at_close: 0.9589530553526475,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 69495.33384615385,
        close_ms: 1711474290957,
        current_return: 1.0549412722014457,
        initial_entry_price: 69459.44,
        is_closed_position: true,
        miner_hotkey: "5Gp8ifdA1WGbKfhZZBAKKmATRoK4YoQZwyFwgVkqnHDSino7",
        net_leverage: 0.0,
        open_ms: 1711471471788,
        orders: [
          {
            leverage: 5.0,
            order_type: "LONG",
            order_uuid: "c65d6d31-9a93-47b4-95ca-ce0799f46f79",
            price: 69459.44,
            price_sources: [],
            processed_ms: 1711471471785,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "4c5447e2-2f38-4351-8b0f-0e0c8b194217",
            price: 69614.98,
            price_sources: [],
            processed_ms: 1711473282710,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "3618676e-d472-4cda-b23a-dc229246a374",
            price: 70082.44,
            price_sources: [],
            processed_ms: 1711474290957,
          },
        ],
        position_type: "FLAT",
        position_uuid: "66c59fa1-14a2-4347-b5b9-7f5395d14b24",
        return_at_close: 1.041227035662827,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 70191.1225,
        close_ms: 1711480174276,
        current_return: 0.9597168473489096,
        initial_entry_price: 70136.02,
        is_closed_position: true,
        miner_hotkey: "5Gp8ifdA1WGbKfhZZBAKKmATRoK4YoQZwyFwgVkqnHDSino7",
        net_leverage: 0.0,
        open_ms: 1711475097154,
        orders: [
          {
            leverage: 5.0,
            order_type: "LONG",
            order_uuid: "e797a0dc-b366-4b89-900b-427ca618cb76",
            price: 70136.02,
            price_sources: [],
            processed_ms: 1711475097151,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "1eaafca1-c7f5-49c9-b453-6af6cbee5308",
            price: 70264.0,
            price_sources: [],
            processed_ms: 1711476990215,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "988be130-f5da-4233-845c-17a90ed77029",
            price: 70301.92,
            price_sources: [],
            processed_ms: 1711478734519,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "8c7a7808-7e47-4b8a-a9e2-009317d9ccb8",
            price: 69837.96,
            price_sources: [],
            processed_ms: 1711480174276,
          },
        ],
        position_type: "FLAT",
        position_uuid: "501e151b-2a07-4c41-950b-ab1a8a44569e",
        return_at_close: 0.944361377791327,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 69826.68461538461,
        close_ms: 1711483336143,
        current_return: 0.9658790773626569,
        initial_entry_price: 69835.89,
        is_closed_position: true,
        miner_hotkey: "5Gp8ifdA1WGbKfhZZBAKKmATRoK4YoQZwyFwgVkqnHDSino7",
        net_leverage: 0.0,
        open_ms: 1711480554700,
        orders: [
          {
            leverage: 5.0,
            order_type: "LONG",
            order_uuid: "3e13968f-7664-4e79-a974-78745ff5b7fd",
            price: 69835.89,
            price_sources: [],
            processed_ms: 1711480554697,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "1f595102-7225-42d9-a9c3-996080e131ab",
            price: 69796.0,
            price_sources: [],
            processed_ms: 1711482359939,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "0ea7ca56-3809-45e0-ad09-659123a8c4a9",
            price: 69460.09,
            price_sources: [],
            processed_ms: 1711483336143,
          },
        ],
        position_type: "FLAT",
        position_uuid: "4e24af51-b0fa-42c7-8e04-bd689da64ef3",
        return_at_close: 0.9533226493569423,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 69695.1,
        close_ms: 1711488810532,
        current_return: 1.059102500351184,
        initial_entry_price: 69621.42,
        is_closed_position: true,
        miner_hotkey: "5Gp8ifdA1WGbKfhZZBAKKmATRoK4YoQZwyFwgVkqnHDSino7",
        net_leverage: 0.0,
        open_ms: 1711484178936,
        orders: [
          {
            leverage: 5.0,
            order_type: "LONG",
            order_uuid: "a016f3af-2331-47df-a3fb-e3d2d572a8b7",
            price: 69621.42,
            price_sources: [],
            processed_ms: 1711484178933,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "d7e4b729-5c3e-43b1-9fde-07bacd79659c",
            price: 69758.0,
            price_sources: [],
            processed_ms: 1711486001150,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "634c63f4-7d78-4e87-955a-1c733ec6c58a",
            price: 69877.8,
            price_sources: [],
            processed_ms: 1711487813655,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "ad0ba126-0f07-4310-a75c-d208df4cdb96",
            price: 70209.45,
            price_sources: [],
            processed_ms: 1711488810532,
          },
        ],
        position_type: "FLAT",
        position_uuid: "33c31d1c-c0a4-4b75-845f-ad6ac3663e68",
        return_at_close: 1.042156860345565,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 70041.52461538462,
        close_ms: 1711491946099,
        current_return: 0.977057744145622,
        initial_entry_price: 70089.01,
        is_closed_position: true,
        miner_hotkey: "5Gp8ifdA1WGbKfhZZBAKKmATRoK4YoQZwyFwgVkqnHDSino7",
        net_leverage: 0.0,
        open_ms: 1711489651760,
        orders: [
          {
            leverage: 5.0,
            order_type: "LONG",
            order_uuid: "b427593f-8181-4fbb-a15d-70aacbe1d93e",
            price: 70089.01,
            price_sources: [],
            processed_ms: 1711489651756,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "914e538a-1e32-47d0-a23a-2154da17bb4a",
            price: 69883.24,
            price_sources: [],
            processed_ms: 1711491457905,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "cb39e04a-d180-44f6-ae18-828bd507186a",
            price: 69794.14,
            price_sources: [],
            processed_ms: 1711491946099,
          },
        ],
        position_type: "FLAT",
        position_uuid: "2b754294-964c-4fe0-b792-e52b20091136",
        return_at_close: 0.9643559934717288,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 69841.45914893618,
        close_ms: 1711508011910,
        current_return: 1.0572686763617858,
        initial_entry_price: 70108.81,
        is_closed_position: true,
        miner_hotkey: "5Gp8ifdA1WGbKfhZZBAKKmATRoK4YoQZwyFwgVkqnHDSino7",
        net_leverage: 0.0,
        open_ms: 1711493266151,
        orders: [
          {
            leverage: 5.0,
            order_type: "LONG",
            order_uuid: "c51bd25e-f184-4691-8f6c-d6fd5790011e",
            price: 70108.81,
            price_sources: [],
            processed_ms: 1711493266148,
          },
          {
            leverage: -2.5,
            order_type: "SHORT",
            order_uuid: "83932c0e-ca94-43d8-8e0b-a640ad104ed4",
            price: 70305.7,
            price_sources: [],
            processed_ms: 1711495089141,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "2fe9a06d-5fb8-496a-8a0c-c6c246fad32f",
            price: 69959.4,
            price_sources: [],
            processed_ms: 1711496906861,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "d2bfe0fc-a2c1-46d4-835f-28a9d7e8228f",
            price: 69889.96,
            price_sources: [],
            processed_ms: 1711498729184,
          },
          {
            leverage: -2.75,
            order_type: "SHORT",
            order_uuid: "169c5fe0-b443-4e5a-8974-959498069962",
            price: 70379.27,
            price_sources: [],
            processed_ms: 1711500538261,
          },
          {
            leverage: -1.375,
            order_type: "SHORT",
            order_uuid: "b61d6dc5-d53d-4f85-90b6-fc8355ebda08",
            price: 70698.0,
            price_sources: [],
            processed_ms: 1711502355398,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "a952acf3-bacd-4dfc-acb9-f1ff2113eb67",
            price: 70398.82,
            price_sources: [],
            processed_ms: 1711504172516,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "74e66204-0496-4cd4-a30e-2d4ff2fd6a39",
            price: 70214.4,
            price_sources: [],
            processed_ms: 1711506025233,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "d87d64ed-3e6f-4108-aa6e-204dc4ea4ed8",
            price: 70398.43,
            price_sources: [],
            processed_ms: 1711507824984,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "dcc71cae-8f6a-4981-b2ed-1294a6100065",
            price: 70524.87,
            price_sources: [],
            processed_ms: 1711508011910,
          },
        ],
        position_type: "FLAT",
        position_uuid: "5e667d13-3754-4d65-a2ae-6206d9dff8ca",
        return_at_close: 1.0448457694145348,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 70441.74421052632,
        close_ms: 1711515334626,
        current_return: 0.9531685266388702,
        initial_entry_price: 70531.52,
        is_closed_position: true,
        miner_hotkey: "5Gp8ifdA1WGbKfhZZBAKKmATRoK4YoQZwyFwgVkqnHDSino7",
        net_leverage: 0.0,
        open_ms: 1711509629144,
        orders: [
          {
            leverage: 5.0,
            order_type: "LONG",
            order_uuid: "b6a573a1-3494-4518-b764-73fb02a0fa19",
            price: 70531.52,
            price_sources: [],
            processed_ms: 1711509629140,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "4613da57-bd40-42d4-9f8d-515db974a5c8",
            price: 70503.36,
            price_sources: [],
            processed_ms: 1711511437865,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "bc18aed6-db57-43b8-97cb-95a6e9814ffb",
            price: 70267.28,
            price_sources: [],
            processed_ms: 1711513256898,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "a3553bdf-1385-4644-a267-174e15e0c00e",
            price: 70255.34,
            price_sources: [],
            processed_ms: 1711515076295,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "e9fc5aef-54f8-4385-ab66-81aee0123a7e",
            price: 70094.05,
            price_sources: [],
            processed_ms: 1711515334626,
          },
        ],
        position_type: "FLAT",
        position_uuid: "3cad20ef-a786-4962-8bfc-3c83433b61b5",
        return_at_close: 0.9350583246327316,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 70307.12200000002,
        close_ms: 1711524207612,
        current_return: 0.9313415512289007,
        initial_entry_price: 70381.36,
        is_closed_position: true,
        miner_hotkey: "5Gp8ifdA1WGbKfhZZBAKKmATRoK4YoQZwyFwgVkqnHDSino7",
        net_leverage: 0.0,
        open_ms: 1711516891957,
        orders: [
          {
            leverage: 5.0,
            order_type: "LONG",
            order_uuid: "9fe073d3-daab-4a82-b77a-c69337dee320",
            price: 70381.36,
            price_sources: [],
            processed_ms: 1711516891953,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "5aeb185a-acda-4c48-912c-d564241a1e9e",
            price: 70405.59,
            price_sources: [],
            processed_ms: 1711518705950,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "d7e07d0f-9576-4601-bcee-63664fd2fb56",
            price: 70404.42,
            price_sources: [],
            processed_ms: 1711520527220,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "72ea1acb-c76c-4d5a-b765-932c46506d44",
            price: 70254.73,
            price_sources: [],
            processed_ms: 1711522352113,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "e313ad1c-0f30-427e-b1c4-f93af54d41e0",
            price: 70166.53,
            price_sources: [],
            processed_ms: 1711523483066,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "72100e01-68cf-4c0e-8f2c-79eaab7d9f03",
            price: 70056.88,
            price_sources: [],
            processed_ms: 1711523720662,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "7ca5c21e-7ac5-46bf-921b-f64449bca34c",
            price: 69920.54,
            price_sources: [],
            processed_ms: 1711524207612,
          },
        ],
        position_type: "FLAT",
        position_uuid: "79c23b89-b4c8-49e7-8c48-2203197be0d9",
        return_at_close: 0.9080580124481782,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
    ],
    thirty_day_returns: 1.0,
  },
  "5GpFa5Vf9DAPMZdmeLQxhEKyJPECZ32WGQoh5hPuPYpXdRPp": {
    all_time_returns: 1.0,
    n_positions: 0,
    percentage_profitable: 0.0,
    positions: [
      {
        average_entry_price: 5234.27002,
        close_ms: 1711301756724,
        current_return: 1.0,
        initial_entry_price: 5234.27002,
        is_closed_position: true,
        miner_hotkey: "5GpFa5Vf9DAPMZdmeLQxhEKyJPECZ32WGQoh5hPuPYpXdRPp",
        net_leverage: 0.0,
        open_ms: 1711289126135,
        orders: [
          {
            leverage: 1.0,
            order_type: "LONG",
            order_uuid: "edd534ee-e2ee-45b9-af23-47ba647b7513",
            price: 5234.27002,
            price_sources: [],
            processed_ms: 1711289126134,
          },
          {
            leverage: 1.0,
            order_type: "LONG",
            order_uuid: "39f1200d-4432-4289-924d-6728f45a5873",
            price: 5234.27002,
            price_sources: [],
            processed_ms: 1711289455191,
          },
          {
            leverage: 1.0,
            order_type: "LONG",
            order_uuid: "1c43d23a-2d51-4e5d-937e-db6d6773522c",
            price: 5234.27002,
            price_sources: [],
            processed_ms: 1711289577229,
          },
          {
            leverage: -1.0,
            order_type: "SHORT",
            order_uuid: "3ccdc3d2-3aba-48c8-a66b-3a74b43430db",
            price: 5234.27002,
            price_sources: [],
            processed_ms: 1711301584627,
          },
          {
            leverage: -1.0,
            order_type: "SHORT",
            order_uuid: "9a53012c-4771-40b7-8fb5-ff985771e99a",
            price: 5234.27002,
            price_sources: [],
            processed_ms: 1711301756602,
          },
          {
            leverage: -1.0,
            order_type: "SHORT",
            order_uuid: "92d49c7b-d3f8-400c-bc9c-74fdb8e8e8d9",
            price: 5234.27002,
            price_sources: [],
            processed_ms: 1711301756724,
          },
        ],
        position_type: "FLAT",
        position_uuid: "95f5b56a-ec9b-4b09-94a4-daf7a6bfcb43",
        return_at_close: 0.99973,
        trade_pair: ["SPX", "SPX", 9e-5, 0.001, 500],
      },
      {
        average_entry_price: 5234.27002,
        close_ms: 1711305537080,
        current_return: 1.0,
        initial_entry_price: 5234.27002,
        is_closed_position: true,
        miner_hotkey: "5GpFa5Vf9DAPMZdmeLQxhEKyJPECZ32WGQoh5hPuPYpXdRPp",
        net_leverage: 0.0,
        open_ms: 1711303870889,
        orders: [
          {
            leverage: -1.0,
            order_type: "SHORT",
            order_uuid: "21890f1c-c127-490f-b1eb-be303316dd4d",
            price: 5234.27002,
            price_sources: [],
            processed_ms: 1711303870889,
          },
          {
            leverage: 1.0,
            order_type: "LONG",
            order_uuid: "bc38d539-1b7c-4a8a-9455-e4d74c55d433",
            price: 5234.27002,
            price_sources: [],
            processed_ms: 1711305537080,
          },
        ],
        position_type: "FLAT",
        position_uuid: "e39058f3-1508-4160-b4c2-40f5780eeec9",
        return_at_close: 0.99991,
        trade_pair: ["SPX", "SPX", 9e-5, 0.001, 500],
      },
      {
        average_entry_price: 65892.01,
        close_ms: 1711306350941,
        current_return: 1.0019844734625478,
        initial_entry_price: 65569.03,
        is_closed_position: true,
        miner_hotkey: "5GpFa5Vf9DAPMZdmeLQxhEKyJPECZ32WGQoh5hPuPYpXdRPp",
        net_leverage: 0.0,
        open_ms: 1711304176313,
        orders: [
          {
            leverage: -1.0,
            order_type: "SHORT",
            order_uuid: "f22088bc-b236-4b16-8a26-779fbc080344",
            price: 65569.03,
            price_sources: [],
            processed_ms: 1711304176312,
          },
          {
            leverage: -1.0,
            order_type: "SHORT",
            order_uuid: "6a524db0-2e2a-4202-9c12-e56a4b380b29",
            price: 65630.43,
            price_sources: [],
            processed_ms: 1711304497825,
          },
          {
            leverage: 1.0,
            order_type: "LONG",
            order_uuid: "1a623e4d-c27b-4736-b6a0-94497602a938",
            price: 65563.94,
            price_sources: [],
            processed_ms: 1711304770710,
          },
          {
            leverage: -1.0,
            order_type: "SHORT",
            order_uuid: "40d5b1ca-0926-4124-95cb-a71a70cc6a06",
            price: 65689.68,
            price_sources: [],
            processed_ms: 1711305107917,
          },
          {
            leverage: 1.0,
            order_type: "LONG",
            order_uuid: "957ab197-51ca-40ef-a60c-58ed805067d3",
            price: 65683.81,
            price_sources: [],
            processed_ms: 1711305221320,
          },
          {
            leverage: -1.0,
            order_type: "SHORT",
            order_uuid: "f4db5da1-6f98-4d95-a149-7ca49adb9fe0",
            price: 65993.16,
            price_sources: [],
            processed_ms: 1711305369173,
          },
          {
            leverage: 1.0,
            order_type: "LONG",
            order_uuid: "ab306ed0-c1cf-4d6c-9abf-fed8cb2f07a0",
            price: 65742.54,
            price_sources: [],
            processed_ms: 1711305419038,
          },
          {
            leverage: 1.0,
            order_type: "LONG",
            order_uuid: "3ca06271-b352-4be8-8a66-835260b5f253",
            price: 65761.89,
            price_sources: [],
            processed_ms: 1711306350941,
          },
        ],
        position_type: "FLAT",
        position_uuid: "6eb9cd1d-65ed-4b10-92a2-58449e8f79af",
        return_at_close: 0.9979765355686976,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 65734.52,
        close_ms: 1711307633816,
        current_return: 0.9990489015512704,
        initial_entry_price: 65734.52,
        is_closed_position: true,
        miner_hotkey: "5GpFa5Vf9DAPMZdmeLQxhEKyJPECZ32WGQoh5hPuPYpXdRPp",
        net_leverage: 0.0,
        open_ms: 1711307356421,
        orders: [
          {
            leverage: -1.0,
            order_type: "SHORT",
            order_uuid: "72a39460-a1cb-49fc-96cb-1efcd33af0e9",
            price: 65734.52,
            price_sources: [],
            processed_ms: 1711307356420,
          },
          {
            leverage: 1.0,
            order_type: "LONG",
            order_uuid: "f9e1ef02-f66e-44c4-8a1e-0171df1f6158",
            price: 65797.04,
            price_sources: [],
            processed_ms: 1711307633816,
          },
        ],
        position_type: "FLAT",
        position_uuid: "37881421-c2c8-447b-8f44-49523eb993bd",
        return_at_close: 0.9970508037481678,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 65832.71,
        close_ms: 1711308991410,
        current_return: 1.0013859371731773,
        initial_entry_price: 65832.71,
        is_closed_position: true,
        miner_hotkey: "5GpFa5Vf9DAPMZdmeLQxhEKyJPECZ32WGQoh5hPuPYpXdRPp",
        net_leverage: 0.0,
        open_ms: 1711308158562,
        orders: [
          {
            leverage: -1.0,
            order_type: "SHORT",
            order_uuid: "62e635c2-c9c8-4411-993a-e58ca154011e",
            price: 65832.71,
            price_sources: [],
            processed_ms: 1711308158561,
          },
          {
            leverage: 1.0,
            order_type: "LONG",
            order_uuid: "ba705d32-1b58-4816-951f-8ba407e0616b",
            price: 65741.47,
            price_sources: [],
            processed_ms: 1711308991410,
          },
        ],
        position_type: "FLAT",
        position_uuid: "74c1fccf-7bcd-4803-99ec-2b49cf2687ce",
        return_at_close: 0.9993831652988309,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 65889.76,
        close_ms: 1711311051363,
        current_return: 1.001412055530328,
        initial_entry_price: 65889.76,
        is_closed_position: true,
        miner_hotkey: "5GpFa5Vf9DAPMZdmeLQxhEKyJPECZ32WGQoh5hPuPYpXdRPp",
        net_leverage: 0.0,
        open_ms: 1711310288556,
        orders: [
          {
            leverage: -1.0,
            order_type: "SHORT",
            order_uuid: "d2fa76b4-c33f-4bc9-aa44-9ffff85452b5",
            price: 65889.76,
            price_sources: [],
            processed_ms: 1711310288555,
          },
          {
            leverage: 1.0,
            order_type: "LONG",
            order_uuid: "7d956aea-c634-4dd5-b407-80dd33d204c2",
            price: 65796.72,
            price_sources: [],
            processed_ms: 1711311051363,
          },
        ],
        position_type: "FLAT",
        position_uuid: "715086d7-446e-4ba3-82f7-8c5e76ff16dc",
        return_at_close: 0.9994092314192674,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 66366.88,
        close_ms: 1711312969494,
        current_return: 1.001878949259028,
        initial_entry_price: 66366.88,
        is_closed_position: true,
        miner_hotkey: "5GpFa5Vf9DAPMZdmeLQxhEKyJPECZ32WGQoh5hPuPYpXdRPp",
        net_leverage: 0.0,
        open_ms: 1711312432941,
        orders: [
          {
            leverage: -1.0,
            order_type: "SHORT",
            order_uuid: "1e609f62-26f1-4d05-98e6-140468ee2e8e",
            price: 66366.88,
            price_sources: [],
            processed_ms: 1711312432940,
          },
          {
            leverage: 1.0,
            order_type: "LONG",
            order_uuid: "f5651eca-bc80-4e3d-b42e-eb9fe64442dd",
            price: 66242.18,
            price_sources: [],
            processed_ms: 1711312969494,
          },
        ],
        position_type: "FLAT",
        position_uuid: "170c396a-7323-4109-bbf5-9301d5e4c1d1",
        return_at_close: 0.9998751913605101,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 66259.32,
        close_ms: 1711314092364,
        current_return: 1.0006361369238321,
        initial_entry_price: 66259.32,
        is_closed_position: true,
        miner_hotkey: "5GpFa5Vf9DAPMZdmeLQxhEKyJPECZ32WGQoh5hPuPYpXdRPp",
        net_leverage: 0.0,
        open_ms: 1711313631536,
        orders: [
          {
            leverage: -1.0,
            order_type: "SHORT",
            order_uuid: "93a1210e-703b-492c-b98b-71069cea5e08",
            price: 66259.32,
            price_sources: [],
            processed_ms: 1711313631535,
          },
          {
            leverage: 1.0,
            order_type: "LONG",
            order_uuid: "299ad65a-b2c1-4b76-9cc8-6b8d3f1b8a1c",
            price: 66217.17,
            price_sources: [],
            processed_ms: 1711314092364,
          },
        ],
        position_type: "FLAT",
        position_uuid: "779d795d-dfe0-4e66-ac6d-408277e2135e",
        return_at_close: 0.9986348646499844,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 66395.22,
        close_ms: 1711315797700,
        current_return: 1.0001813383553817,
        initial_entry_price: 66395.22,
        is_closed_position: true,
        miner_hotkey: "5GpFa5Vf9DAPMZdmeLQxhEKyJPECZ32WGQoh5hPuPYpXdRPp",
        net_leverage: 0.0,
        open_ms: 1711315362653,
        orders: [
          {
            leverage: -1.0,
            order_type: "SHORT",
            order_uuid: "b4895819-3617-402f-9c9b-5c24bfa002c6",
            price: 66395.22,
            price_sources: [],
            processed_ms: 1711315362651,
          },
          {
            leverage: 1.0,
            order_type: "LONG",
            order_uuid: "9c7a595d-bc51-4759-8d67-2aa64cbc4e03",
            price: 66383.18,
            price_sources: [],
            processed_ms: 1711315797700,
          },
        ],
        position_type: "FLAT",
        position_uuid: "17fa28b9-c216-48ab-a227-bb85051c0336",
        return_at_close: 0.9981809756786709,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 66263.13,
        close_ms: 1711317431674,
        current_return: 0.9988473227871972,
        initial_entry_price: 66263.13,
        is_closed_position: true,
        miner_hotkey: "5GpFa5Vf9DAPMZdmeLQxhEKyJPECZ32WGQoh5hPuPYpXdRPp",
        net_leverage: 0.0,
        open_ms: 1711316818820,
        orders: [
          {
            leverage: -1.0,
            order_type: "SHORT",
            order_uuid: "797aab49-8739-4b05-ac02-72e17edcf844",
            price: 66263.13,
            price_sources: [],
            processed_ms: 1711316818819,
          },
          {
            leverage: 1.0,
            order_type: "LONG",
            order_uuid: "6707c3f6-c44a-4f60-952b-7b59713db5f9",
            price: 66339.51,
            price_sources: [],
            processed_ms: 1711317431674,
          },
        ],
        position_type: "FLAT",
        position_uuid: "052b3f26-7c2e-4959-ba59-54295a0b0b8f",
        return_at_close: 0.9968496281416228,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 71099.427,
        close_ms: 1711826073011,
        current_return: 0.9820503106124117,
        initial_entry_price: 70711.92,
        is_closed_position: true,
        miner_hotkey: "5GpFa5Vf9DAPMZdmeLQxhEKyJPECZ32WGQoh5hPuPYpXdRPp",
        net_leverage: 0.0,
        open_ms: 1711387861402,
        orders: [
          {
            leverage: 1.0,
            order_type: "LONG",
            order_uuid: "9d4bb430-89cf-4cd2-8d1e-b50e3c22906b",
            price: 70711.92,
            price_sources: [],
            processed_ms: 1711387861400,
          },
          {
            leverage: -0.3,
            order_type: "SHORT",
            order_uuid: "d1e5d613-a13b-49bb-a0a7-75f2d6331322",
            price: 69866.88,
            price_sources: [],
            processed_ms: 1711463164001,
          },
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "d0c9cac0-0a78-403c-9dd2-acdcff155980",
            price: 70831.84,
            price_sources: [],
            processed_ms: 1711544745688,
          },
          {
            leverage: -0.3,
            order_type: "SHORT",
            order_uuid: "24f9149e-dacb-4697-969e-73474f29a184",
            price: 70610.56,
            price_sources: [],
            processed_ms: 1711645014118,
          },
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "4ac086b1-c287-4e37-a170-28fc91e1e91b",
            price: 70303.95,
            price_sources: [],
            processed_ms: 1711711529115,
          },
          {
            leverage: -0.3,
            order_type: "SHORT",
            order_uuid: "98c46b8a-4f75-4d1a-a212-c4d8f7c65a52",
            price: 69612.47,
            price_sources: [],
            processed_ms: 1711726802278,
          },
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "3eedf82b-a578-475c-a156-0dd492e6d31e",
            price: 70245.81,
            price_sources: [],
            processed_ms: 1711774970201,
          },
          {
            leverage: 19.0,
            order_type: "FLAT",
            order_uuid: "73baaaeb-61b7-4ea9-8b29-8f16a44264a5",
            price: 69830.17,
            price_sources: [],
            processed_ms: 1711826073011,
          },
        ],
        position_type: "FLAT",
        position_uuid: "8e7c4734-dfb5-4743-b98a-a8289ac2cf2f",
        return_at_close: 0.9800862099911868,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 69992.2,
        close_ms: 1711942206519,
        current_return: 1.182969616614425,
        initial_entry_price: 69992.2,
        is_closed_position: true,
        miner_hotkey: "5GpFa5Vf9DAPMZdmeLQxhEKyJPECZ32WGQoh5hPuPYpXdRPp",
        net_leverage: 0.0,
        open_ms: 1711847195106,
        orders: [
          {
            leverage: 19.9,
            order_type: "LONG",
            order_uuid: "b738a335-b375-4756-bf9e-2b2e69938c97",
            price: 69992.2,
            price_sources: [],
            processed_ms: 1711847195105,
          },
          {
            leverage: 0.10000000000000142,
            order_type: "FLAT",
            order_uuid: "f02f4525-db9f-4497-bdfd-83a275e99865",
            price: 70635.74,
            price_sources: [],
            processed_ms: 1711942206519,
          },
        ],
        position_type: "FLAT",
        position_uuid: "0be36607-2afe-42fe-8733-e2420fc4d287",
        return_at_close: 1.135887425873171,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 69222.8,
        close_ms: 1712031045669,
        current_return: 0.2829735289528893,
        initial_entry_price: 69222.8,
        is_closed_position: true,
        miner_hotkey: "5GpFa5Vf9DAPMZdmeLQxhEKyJPECZ32WGQoh5hPuPYpXdRPp",
        net_leverage: 19.9,
        open_ms: 1711999043677,
        orders: [
          {
            leverage: 19.9,
            order_type: "LONG",
            order_uuid: "c8db200b-3717-404b-b0b9-3dbb04701557",
            price: 69222.8,
            price_sources: [],
            processed_ms: 1711999043676,
          },
        ],
        position_type: "FLAT",
        position_uuid: "e0121325-1047-49b5-8388-38064f6bdde5",
        return_at_close: 0.2717111825005643,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
    ],
    thirty_day_returns: 1.0,
  },
  "5GpPC45ZmJGZfR1AnceXW4p8BP4e58L1NVGx8cd8AukF22Fz": {
    all_time_returns: 1.0,
    n_positions: 0,
    percentage_profitable: 0.0,
    positions: [
      {
        average_entry_price: 64177.99999999999,
        close_ms: 1711254299500,
        current_return: 0.9988112748917075,
        initial_entry_price: 64178.0,
        is_closed_position: true,
        miner_hotkey: "5GpPC45ZmJGZfR1AnceXW4p8BP4e58L1NVGx8cd8AukF22Fz",
        net_leverage: 0.0,
        open_ms: 1711250731466,
        orders: [
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "2f4e5cf6-a7c0-44f0-a14c-b1e1c3758178",
            price: 64178.0,
            price_sources: [],
            processed_ms: 1711250731466,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "c9b45c3a-d7c9-4436-b2a4-0dcdba3e4599",
            price: 63923.7,
            price_sources: [],
            processed_ms: 1711254299500,
          },
        ],
        position_type: "FLAT",
        position_uuid: "9c024bd8-acd2-4806-ae83-18b793694fe1",
        return_at_close: 0.9982119881267725,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 63892.95,
        close_ms: 1711258419705,
        current_return: 1.0021660605747582,
        initial_entry_price: 63892.95,
        is_closed_position: true,
        miner_hotkey: "5GpPC45ZmJGZfR1AnceXW4p8BP4e58L1NVGx8cd8AukF22Fz",
        net_leverage: 0.0,
        open_ms: 1711254386500,
        orders: [
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "051f6774-c3cf-4ccf-8bf6-c7d9a22fabbd",
            price: 63892.95,
            price_sources: [],
            processed_ms: 1711254386499,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "bba6528b-b801-4221-ba0c-7a70e5640fb4",
            price: 64354.27,
            price_sources: [],
            processed_ms: 1711258419705,
          },
        ],
        position_type: "FLAT",
        position_uuid: "31ebc2a6-a49a-4ab0-a702-8d7657744a1b",
        return_at_close: 1.0015647609384133,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 64337.89,
        close_ms: 1711262081331,
        current_return: 0.9974787407544762,
        initial_entry_price: 64337.89,
        is_closed_position: true,
        miner_hotkey: "5GpPC45ZmJGZfR1AnceXW4p8BP4e58L1NVGx8cd8AukF22Fz",
        net_leverage: 0.0,
        open_ms: 1711258548934,
        orders: [
          {
            leverage: 1.25,
            order_type: "LONG",
            order_uuid: "9c271ab7-d93c-4fa0-b121-46f16daf69b5",
            price: 64337.89,
            price_sources: [],
            processed_ms: 1711258548933,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "d13e634b-7ce3-4057-a6a6-3166cbe40bbe",
            price: 64208.12,
            price_sources: [],
            processed_ms: 1711262081331,
          },
        ],
        position_type: "FLAT",
        position_uuid: "d73b6dff-a4b0-4b82-b683-014480cf1b06",
        return_at_close: 0.99498504390259,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 64232.5,
        close_ms: 1711265750420,
        current_return: 0.9973549215739695,
        initial_entry_price: 64232.5,
        is_closed_position: true,
        miner_hotkey: "5GpPC45ZmJGZfR1AnceXW4p8BP4e58L1NVGx8cd8AukF22Fz",
        net_leverage: 0.0,
        open_ms: 1711262171871,
        orders: [
          {
            leverage: 1.25,
            order_type: "LONG",
            order_uuid: "d2957ba6-98c1-41dd-812b-5c97b27c6bb5",
            price: 64232.5,
            price_sources: [],
            processed_ms: 1711262171871,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "35f8089a-7bd1-4a3a-b0d9-97fcb74562e4",
            price: 64096.58,
            price_sources: [],
            processed_ms: 1711265750420,
          },
        ],
        position_type: "FLAT",
        position_uuid: "78b1f224-2ed3-463b-b7c5-e0226053a652",
        return_at_close: 0.9948615342700347,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 64118.4,
        close_ms: 1711269404426,
        current_return: 1.0128711103209063,
        initial_entry_price: 64118.4,
        is_closed_position: true,
        miner_hotkey: "5GpPC45ZmJGZfR1AnceXW4p8BP4e58L1NVGx8cd8AukF22Fz",
        net_leverage: 0.0,
        open_ms: 1711265837343,
        orders: [
          {
            leverage: 1.25,
            order_type: "LONG",
            order_uuid: "09ab5eff-4472-4a86-a18c-4be7f9bfbb34",
            price: 64118.4,
            price_sources: [],
            processed_ms: 1711265837343,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "3c9bf916-0a68-40a1-8693-8a2caaab6dc9",
            price: 64778.62,
            price_sources: [],
            processed_ms: 1711269404426,
          },
        ],
        position_type: "FLAT",
        position_uuid: "e60b0ea5-a4f3-4122-bbd9-e4048dd663ab",
        return_at_close: 1.010338932545104,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 64815.73,
        close_ms: 1711273071809,
        current_return: 1.0063015181654207,
        initial_entry_price: 64815.73,
        is_closed_position: true,
        miner_hotkey: "5GpPC45ZmJGZfR1AnceXW4p8BP4e58L1NVGx8cd8AukF22Fz",
        net_leverage: 0.0,
        open_ms: 1711269491404,
        orders: [
          {
            leverage: 1.25,
            order_type: "LONG",
            order_uuid: "afbc6d9c-4622-4d4f-8077-32cb0723eda3",
            price: 64815.73,
            price_sources: [],
            processed_ms: 1711269491403,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "c33648a7-e97c-4fd4-9fee-1103d6db0ba7",
            price: 65142.48,
            price_sources: [],
            processed_ms: 1711273071809,
          },
        ],
        position_type: "FLAT",
        position_uuid: "a9f20595-7dc6-42d4-80eb-7c2a576fbbb3",
        return_at_close: 1.0037857643700072,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 65008.67,
        close_ms: 1711276723536,
        current_return: 1.000508201444515,
        initial_entry_price: 65008.67,
        is_closed_position: true,
        miner_hotkey: "5GpPC45ZmJGZfR1AnceXW4p8BP4e58L1NVGx8cd8AukF22Fz",
        net_leverage: 0.0,
        open_ms: 1711273158498,
        orders: [
          {
            leverage: 1.25,
            order_type: "LONG",
            order_uuid: "d6e39611-8e1e-4cf9-9529-1a43b8a3f8db",
            price: 65008.67,
            price_sources: [],
            processed_ms: 1711273158497,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "28e1390f-4060-4782-8f24-b9d91f431f42",
            price: 65035.1,
            price_sources: [],
            processed_ms: 1711276723536,
          },
        ],
        position_type: "FLAT",
        position_uuid: "fa00cc0a-427c-43a9-84db-3e0b81a6859f",
        return_at_close: 0.9980069309409038,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 64999.77,
        close_ms: 1711282306857,
        current_return: 1.0077538735906297,
        initial_entry_price: 64999.77,
        is_closed_position: true,
        miner_hotkey: "5GpPC45ZmJGZfR1AnceXW4p8BP4e58L1NVGx8cd8AukF22Fz",
        net_leverage: 0.0,
        open_ms: 1711276810448,
        orders: [
          {
            leverage: 1.25,
            order_type: "LONG",
            order_uuid: "cfbb00c2-02b1-4f73-9b3f-6dcd19b6e19c",
            price: 64999.77,
            price_sources: [],
            processed_ms: 1711276810447,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "623d2380-cbb8-49c0-9a7f-c07efc4865d5",
            price: 65402.97,
            price_sources: [],
            processed_ms: 1711282306857,
          },
        ],
        position_type: "FLAT",
        position_uuid: "000f1957-a22a-47ba-b6d1-8d67e173f8e0",
        return_at_close: 1.0052344889066531,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 66942.16,
        close_ms: 1711326585720,
        current_return: 0.9988975557406572,
        initial_entry_price: 66942.16,
        is_closed_position: true,
        miner_hotkey: "5GpPC45ZmJGZfR1AnceXW4p8BP4e58L1NVGx8cd8AukF22Fz",
        net_leverage: 0.0,
        open_ms: 1711325658596,
        orders: [
          {
            leverage: 1.25,
            order_type: "LONG",
            order_uuid: "15edb0fc-2b82-4145-b11a-5262d1ca7b39",
            price: 66942.16,
            price_sources: [],
            processed_ms: 1711325658595,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "d67a877f-d3bc-463b-923c-711c39cebbc6",
            price: 66883.12,
            price_sources: [],
            processed_ms: 1711326585720,
          },
        ],
        position_type: "FLAT",
        position_uuid: "32e957b5-b4d5-453f-9852-635161de7084",
        return_at_close: 0.9964003118513056,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 66604.42,
        close_ms: 1711328451508,
        current_return: 0.9994431675855747,
        initial_entry_price: 66604.42,
        is_closed_position: true,
        miner_hotkey: "5GpPC45ZmJGZfR1AnceXW4p8BP4e58L1NVGx8cd8AukF22Fz",
        net_leverage: 0.0,
        open_ms: 1711327516835,
        orders: [
          {
            leverage: 1.25,
            order_type: "LONG",
            order_uuid: "504a1177-fcba-4c5c-b7a1-2b3dc5613248",
            price: 66604.42,
            price_sources: [],
            processed_ms: 1711327516833,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "6bfcc281-ce20-4a03-99c8-bba2a851b4fe",
            price: 66574.75,
            price_sources: [],
            processed_ms: 1711328451508,
          },
        ],
        position_type: "FLAT",
        position_uuid: "edf48495-3416-403a-b320-08f3cf2a25c7",
        return_at_close: 0.9969445596666109,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 66882.95,
        close_ms: 1711338055207,
        current_return: 1.0018160457934346,
        initial_entry_price: 66882.95,
        is_closed_position: true,
        miner_hotkey: "5GpPC45ZmJGZfR1AnceXW4p8BP4e58L1NVGx8cd8AukF22Fz",
        net_leverage: 0.0,
        open_ms: 1711336224789,
        orders: [
          {
            leverage: 1.25,
            order_type: "LONG",
            order_uuid: "1faf25b9-8551-45e8-adb6-d02d611153b2",
            price: 66882.95,
            price_sources: [],
            processed_ms: 1711336224788,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "5be9bd3b-8f28-4ea1-abe5-c4aec9120fc5",
            price: 66980.12,
            price_sources: [],
            processed_ms: 1711338055207,
          },
        ],
        position_type: "FLAT",
        position_uuid: "6db156b3-995b-470b-8248-f9a617dc0e8f",
        return_at_close: 0.9993115056789511,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 67079.1,
        close_ms: 1711358205004,
        current_return: 0.9947465007729678,
        initial_entry_price: 67079.1,
        is_closed_position: true,
        miner_hotkey: "5GpPC45ZmJGZfR1AnceXW4p8BP4e58L1NVGx8cd8AukF22Fz",
        net_leverage: 0.0,
        open_ms: 1711356381359,
        orders: [
          {
            leverage: 1.25,
            order_type: "LONG",
            order_uuid: "a2eb70ec-5be7-407e-b7ee-25a19b7fb2c4",
            price: 67079.1,
            price_sources: [],
            processed_ms: 1711356381358,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "9b39451f-ef12-4576-8df8-8e396cdef133",
            price: 66797.18,
            price_sources: [],
            processed_ms: 1711358205004,
          },
        ],
        position_type: "FLAT",
        position_uuid: "70a41dfd-6f35-45ae-b691-41545a980aa0",
        return_at_close: 0.9922596345210354,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 67147.00800000002,
        close_ms: 1711375946717,
        current_return: 1.0629318533931937,
        initial_entry_price: 67204.52,
        is_closed_position: true,
        miner_hotkey: "5GpPC45ZmJGZfR1AnceXW4p8BP4e58L1NVGx8cd8AukF22Fz",
        net_leverage: 0.0,
        open_ms: 1711361862706,
        orders: [
          {
            leverage: 1.25,
            order_type: "LONG",
            order_uuid: "003889b0-fa7b-460d-bab4-0c52e8d32089",
            price: 67204.52,
            price_sources: [],
            processed_ms: 1711361862705,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "20ce1061-921e-446d-8d4e-9aaa53c2abac",
            price: 66831.7,
            price_sources: [],
            processed_ms: 1711367445866,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "fd488402-5355-4d26-b9fd-0131b48f63cc",
            price: 67081.23,
            price_sources: [],
            processed_ms: 1711369272980,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "f4b83643-fb1c-42bd-8728-33ec69b016fc",
            price: 67118.88,
            price_sources: [],
            processed_ms: 1711371111541,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "ec3d3fd2-385c-4f25-b528-a872b1cb59b1",
            price: 66895.37,
            price_sources: [],
            processed_ms: 1711372951693,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "10f5622b-ec1b-46fa-b250-edade78bedd1",
            price: 67520.3,
            price_sources: [],
            processed_ms: 1711374806381,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "cd4ab93d-f3b6-4b42-8e7a-d43f3076a3d3",
            price: 68838.73,
            price_sources: [],
            processed_ms: 1711375946717,
          },
        ],
        position_type: "FLAT",
        position_uuid: "40e0c572-69c9-4afd-9e7e-9e00b065b548",
        return_at_close: 1.0576171941262278,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 69141.81,
        close_ms: 1711376519638,
        current_return: 1.0,
        initial_entry_price: 69141.81,
        is_closed_position: true,
        miner_hotkey: "5GpPC45ZmJGZfR1AnceXW4p8BP4e58L1NVGx8cd8AukF22Fz",
        net_leverage: 0.0,
        open_ms: 1711376488991,
        orders: [
          {
            leverage: -1.25,
            order_type: "SHORT",
            order_uuid: "41c0c443-83dd-4551-847b-fcf25a6ef09e",
            price: 69141.81,
            price_sources: [],
            processed_ms: 1711376488990,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "20366338-152b-44b7-a9b5-53feb7ec45d3",
            price: 69141.81,
            price_sources: [],
            processed_ms: 1711376519638,
          },
        ],
        position_type: "FLAT",
        position_uuid: "46ce6de4-4740-4354-996b-c285375b1022",
        return_at_close: 0.9975,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 69669.66000000003,
        close_ms: 1711378214154,
        current_return: 1.0021955521700647,
        initial_entry_price: 69210.38,
        is_closed_position: true,
        miner_hotkey: "5GpPC45ZmJGZfR1AnceXW4p8BP4e58L1NVGx8cd8AukF22Fz",
        net_leverage: 0.0,
        open_ms: 1711376777063,
        orders: [
          {
            leverage: -1.25,
            order_type: "SHORT",
            order_uuid: "8902f04c-1fe1-43f4-ad70-883573819b47",
            price: 69210.38,
            price_sources: [],
            processed_ms: 1711376777062,
          },
          {
            leverage: 1.0,
            order_type: "LONG",
            order_uuid: "9469804f-f535-4ebb-94ca-5674ccbea24b",
            price: 69095.56,
            price_sources: [],
            processed_ms: 1711377930851,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "5951db03-91ac-47ed-ae02-5e2202b2089c",
            price: 69061.84,
            price_sources: [],
            processed_ms: 1711378214154,
          },
        ],
        position_type: "FLAT",
        position_uuid: "b7655ccb-2aec-4af5-bce6-10fc613445fd",
        return_at_close: 0.9996900632896396,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: -137101.83999999787,
        close_ms: 1711400868795,
        current_return: 0.9941809779489865,
        initial_entry_price: 69772.53,
        is_closed_position: true,
        miner_hotkey: "5GpPC45ZmJGZfR1AnceXW4p8BP4e58L1NVGx8cd8AukF22Fz",
        net_leverage: 0.0,
        open_ms: 1711382685658,
        orders: [
          {
            leverage: -1.25,
            order_type: "SHORT",
            order_uuid: "03e78f3f-93cb-4ea3-9c51-2a08805e2e2c",
            price: 69772.53,
            price_sources: [],
            processed_ms: 1711382685657,
          },
          {
            leverage: 0.625,
            order_type: "LONG",
            order_uuid: "de67a4fb-2024-4d33-889b-558fb3626669",
            price: 69716.65,
            price_sources: [],
            processed_ms: 1711384499920,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "4010f2ef-255c-4383-b5e6-0059c82dc14d",
            price: 70200.01,
            price_sources: [],
            processed_ms: 1711386317019,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "f9a40a39-b92c-453a-ac54-4894861f9f29",
            price: 70673.03,
            price_sources: [],
            processed_ms: 1711388139074,
          },
          {
            leverage: 0.0625,
            order_type: "LONG",
            order_uuid: "ce7dad72-8c5a-4b35-8614-af2e7657c28f",
            price: 70611.98,
            price_sources: [],
            processed_ms: 1711389952655,
          },
          {
            leverage: 0.03125,
            order_type: "LONG",
            order_uuid: "d603382a-1cfc-413d-b375-d0908d27677d",
            price: 70497.34,
            price_sources: [],
            processed_ms: 1711391765990,
          },
          {
            leverage: 0.015625,
            order_type: "LONG",
            order_uuid: "aaf23175-4aae-4992-a922-92c2990f9187",
            price: 70909.62,
            price_sources: [],
            processed_ms: 1711393607749,
          },
          {
            leverage: 0.0078125,
            order_type: "LONG",
            order_uuid: "93562b26-126b-40cb-bab4-c3b2f52df449",
            price: 70710.16,
            price_sources: [],
            processed_ms: 1711395401853,
          },
          {
            leverage: 0.00390625,
            order_type: "LONG",
            order_uuid: "cf89c66c-858f-4115-a0f2-cae2bd4e6fea",
            price: 71019.27,
            price_sources: [],
            processed_ms: 1711397227325,
          },
          {
            leverage: 0.001953125,
            order_type: "LONG",
            order_uuid: "9267ab42-61c9-49bc-85db-4f0bbae3da83",
            price: 70746.98,
            price_sources: [],
            processed_ms: 1711399044601,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "4219dbfc-cf95-40b5-b830-d8a9d7579d0a",
            price: 70774.2,
            price_sources: [],
            processed_ms: 1711400868795,
          },
        ],
        position_type: "FLAT",
        position_uuid: "ec0c89c8-42e2-49b3-b3df-b523531b2090",
        return_at_close: 0.9916955255041141,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 736.9428571378396,
        close_ms: 1711426295361,
        current_return: 1.0017039853100211,
        initial_entry_price: 70061.93,
        is_closed_position: true,
        miner_hotkey: "5GpPC45ZmJGZfR1AnceXW4p8BP4e58L1NVGx8cd8AukF22Fz",
        net_leverage: 0.0,
        open_ms: 1711402712439,
        orders: [
          {
            leverage: 1.25,
            order_type: "LONG",
            order_uuid: "db9c6d07-822f-4c37-8af7-974ec5222477",
            price: 70061.93,
            price_sources: [],
            processed_ms: 1711402712437,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "a3c283ab-ea4d-42e3-9ffa-0b7f276d841b",
            price: 70100.02,
            price_sources: [],
            processed_ms: 1711404491474,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "90e4ee4b-8c35-460a-9f1b-6590f40801a3",
            price: 70434.19,
            price_sources: [],
            processed_ms: 1711406308012,
          },
          {
            leverage: -0.875,
            order_type: "SHORT",
            order_uuid: "08998b16-db21-4935-aed8-300ea6b25279",
            price: 70380.31,
            price_sources: [],
            processed_ms: 1711408126213,
          },
          {
            leverage: -0.4375,
            order_type: "SHORT",
            order_uuid: "5fe6cc10-0281-4a26-98d2-18c918900363",
            price: 69929.91,
            price_sources: [],
            processed_ms: 1711409949531,
          },
          {
            leverage: -0.21875,
            order_type: "SHORT",
            order_uuid: "6bc88af0-fc0e-47a9-be0e-b8dc4cade6ae",
            price: 69981.86,
            price_sources: [],
            processed_ms: 1711411764960,
          },
          {
            leverage: -0.109375,
            order_type: "SHORT",
            order_uuid: "ac43ffc3-4bb7-4ead-a4ba-85ae0dd072ed",
            price: 70148.17,
            price_sources: [],
            processed_ms: 1711413594089,
          },
          {
            leverage: -0.0546875,
            order_type: "SHORT",
            order_uuid: "268b3381-b44b-4764-9a8c-c9ea01a43620",
            price: 70033.96,
            price_sources: [],
            processed_ms: 1711415395308,
          },
          {
            leverage: -0.02734375,
            order_type: "SHORT",
            order_uuid: "74cf606f-0793-435d-9f42-cba0dd35d357",
            price: 70227.81,
            price_sources: [],
            processed_ms: 1711417208397,
          },
          {
            leverage: -0.013671875,
            order_type: "SHORT",
            order_uuid: "cd3d36c9-b117-4818-acfc-dbe29c9b5687",
            price: 70210.48,
            price_sources: [],
            processed_ms: 1711419026811,
          },
          {
            leverage: -0.0068359375,
            order_type: "SHORT",
            order_uuid: "9d290e0f-f69d-4199-8412-0dcffcae215c",
            price: 70259.1,
            price_sources: [],
            processed_ms: 1711420879279,
          },
          {
            leverage: -0.00341796875,
            order_type: "SHORT",
            order_uuid: "e7c38d5f-bd62-450b-b1a1-26cf0213f5c5",
            price: 70400.77,
            price_sources: [],
            processed_ms: 1711422678799,
          },
          {
            leverage: -0.001708984375,
            order_type: "SHORT",
            order_uuid: "abff4f9b-131f-41ec-a57d-7d7bc637dbcd",
            price: 70517.62,
            price_sources: [],
            processed_ms: 1711424479842,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "fac0510a-9d80-4b30-91d1-ae7203706230",
            price: 70593.93,
            price_sources: [],
            processed_ms: 1711426295361,
          },
        ],
        position_type: "FLAT",
        position_uuid: "24a3a47d-8ff9-4285-ae52-b1d827bef0e6",
        return_at_close: 0.9981980213614361,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: -276137.57666666544,
        close_ms: 1711453550869,
        current_return: 1.007222247707441,
        initial_entry_price: 70336.25,
        is_closed_position: true,
        miner_hotkey: "5GpPC45ZmJGZfR1AnceXW4p8BP4e58L1NVGx8cd8AukF22Fz",
        net_leverage: 0.0,
        open_ms: 1711431744897,
        orders: [
          {
            leverage: 1.25,
            order_type: "LONG",
            order_uuid: "eb6b2991-2bfc-45f3-967c-53799dc5d4d0",
            price: 70336.25,
            price_sources: [],
            processed_ms: 1711431744896,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "b806e905-f6d8-4a34-8c53-0f2d0da1fd0c",
            price: 70421.77,
            price_sources: [],
            processed_ms: 1711433567263,
          },
          {
            leverage: -0.75,
            order_type: "SHORT",
            order_uuid: "d1cb1404-8b97-4d40-8189-bb9d54d31b8c",
            price: 70446.05,
            price_sources: [],
            processed_ms: 1711435379637,
          },
          {
            leverage: -0.375,
            order_type: "SHORT",
            order_uuid: "79c43f8a-e9b3-4389-a500-c641993158d1",
            price: 70873.83,
            price_sources: [],
            processed_ms: 1711437206014,
          },
          {
            leverage: -0.1875,
            order_type: "SHORT",
            order_uuid: "d2b68fa0-29bf-4993-88eb-dd7cceaf1b5f",
            price: 71251.51,
            price_sources: [],
            processed_ms: 1711439016481,
          },
          {
            leverage: -0.09375,
            order_type: "SHORT",
            order_uuid: "ed732b64-38dd-45b3-97d2-de8b835e9d48",
            price: 70699.06,
            price_sources: [],
            processed_ms: 1711440854956,
          },
          {
            leverage: -0.046875,
            order_type: "SHORT",
            order_uuid: "e05e7d0f-19c0-4100-9b25-e691a5da8576",
            price: 70679.55,
            price_sources: [],
            processed_ms: 1711442649478,
          },
          {
            leverage: -0.0234375,
            order_type: "SHORT",
            order_uuid: "9be9965d-7234-432e-83f4-cefc64af5727",
            price: 70691.58,
            price_sources: [],
            processed_ms: 1711444468101,
          },
          {
            leverage: -0.01171875,
            order_type: "SHORT",
            order_uuid: "be537f5f-977a-4a06-a4a6-cf1409161793",
            price: 71026.69,
            price_sources: [],
            processed_ms: 1711446313559,
          },
          {
            leverage: -0.005859375,
            order_type: "SHORT",
            order_uuid: "98923a76-12ad-4d0a-941f-a2f6ff1853a0",
            price: 71079.2,
            price_sources: [],
            processed_ms: 1711448098086,
          },
          {
            leverage: -0.0029296875,
            order_type: "SHORT",
            order_uuid: "9c568793-9235-4f05-b54c-0e70a9a1e25c",
            price: 70972.79,
            price_sources: [],
            processed_ms: 1711449917879,
          },
          {
            leverage: -0.00146484375,
            order_type: "SHORT",
            order_uuid: "6cede058-226f-4219-879a-cd0115f94a3a",
            price: 70755.01,
            price_sources: [],
            processed_ms: 1711451751060,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "f79cda97-f6e1-4ad7-80ea-c40bc0396551",
            price: 70647.41,
            price_sources: [],
            processed_ms: 1711453550869,
          },
        ],
        position_type: "FLAT",
        position_uuid: "afbc3f09-a3ff-4e17-8ef9-10297d90a1c4",
        return_at_close: 1.0042005809643186,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 65581.33062412086,
        close_ms: 1711618454599,
        current_return: 1.0391335130126533,
        initial_entry_price: 69903.47,
        is_closed_position: true,
        miner_hotkey: "5GpPC45ZmJGZfR1AnceXW4p8BP4e58L1NVGx8cd8AukF22Fz",
        net_leverage: 0.0,
        open_ms: 1711466299414,
        orders: [
          {
            leverage: 1.25,
            order_type: "LONG",
            order_uuid: "537a292f-7bb1-4eea-a7e5-4bfe0e945c8e",
            price: 69903.47,
            price_sources: [],
            processed_ms: 1711466299412,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "d98f9a96-e63e-4ec8-8e10-bff16749712a",
            price: 70205.94,
            price_sources: [],
            processed_ms: 1711468088586,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "4d0fab8e-9047-4e89-82f7-e0f5a6b1ec2d",
            price: 69929.34,
            price_sources: [],
            processed_ms: 1711469908816,
          },
          {
            leverage: -0.875,
            order_type: "SHORT",
            order_uuid: "568b8b65-423d-48ef-b16e-7f1568b05129",
            price: 69643.53,
            price_sources: [],
            processed_ms: 1711471737422,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "534c223a-c356-4b4d-a0e7-e2304c584892",
            price: 69741.1,
            price_sources: [],
            processed_ms: 1711473554886,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "fc47bd3e-76a0-428d-9a17-a54265950575",
            price: 70153.77,
            price_sources: [],
            processed_ms: 1711475372914,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "755a8a8f-de21-4574-816a-bf448dd6b8c9",
            price: 70254.24,
            price_sources: [],
            processed_ms: 1711477199795,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "c003a5bc-b2dc-4756-ae5e-53f53af55862",
            price: 70367.86,
            price_sources: [],
            processed_ms: 1711478997592,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "4a484ae1-2905-42dc-84a5-107da9083209",
            price: 69861.64,
            price_sources: [],
            processed_ms: 1711480812830,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "a6a7f491-4720-4595-b7f7-01951e573945",
            price: 69898.25,
            price_sources: [],
            processed_ms: 1711482632042,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "9f697a09-443a-4760-ad67-15006e3ae5b1",
            price: 69716.06,
            price_sources: [],
            processed_ms: 1711484455956,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "38156088-c75c-4ab0-9317-0e92693ffc57",
            price: 69743.28,
            price_sources: [],
            processed_ms: 1711486268047,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "1d14dec8-15bf-4320-9118-969394d243c8",
            price: 69936.23,
            price_sources: [],
            processed_ms: 1711488109188,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "e9b826a4-570c-49c0-bbf1-a41d9318c1ff",
            price: 70081.62,
            price_sources: [],
            processed_ms: 1711489907834,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "c0ce5923-31d5-4e28-b34d-bc75cc3f2186",
            price: 69805.21,
            price_sources: [],
            processed_ms: 1711491721386,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "913a308d-8e7f-41f0-9930-87479b42ce85",
            price: 70082.2,
            price_sources: [],
            processed_ms: 1711493538147,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "0ac95947-945d-48cd-943f-7aadc11712ab",
            price: 70210.17,
            price_sources: [],
            processed_ms: 1711495379212,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "7aa8f19a-ab00-4957-994f-975f3e3cd9c7",
            price: 70016.82,
            price_sources: [],
            processed_ms: 1711497194850,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "fdc07e6e-e114-4e81-b46a-c46387e717d9",
            price: 70071.17,
            price_sources: [],
            processed_ms: 1711499003004,
          },
          {
            leverage: -2.3125,
            order_type: "SHORT",
            order_uuid: "d2858a94-44ae-43cc-81ef-23bac9ffa944",
            price: 70422.62,
            price_sources: [],
            processed_ms: 1711500846895,
          },
          {
            leverage: -1.15625,
            order_type: "SHORT",
            order_uuid: "305ac2fc-ab43-428f-82d6-0514303e46ad",
            price: 70576.31,
            price_sources: [],
            processed_ms: 1711502633504,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "bbdaf62a-a3ed-446a-b3e9-45ffc2f69a98",
            price: 70333.01,
            price_sources: [],
            processed_ms: 1711504452961,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "dfeb21e2-13a5-403c-8189-8fb01f50dd55",
            price: 70312.03,
            price_sources: [],
            processed_ms: 1711506291236,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "7c69c386-12b8-4209-881d-b2ac6bafb30b",
            price: 70537.89,
            price_sources: [],
            processed_ms: 1711508085687,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "a9160961-4203-4c9a-b752-e855070aaf6a",
            price: 70550.69,
            price_sources: [],
            processed_ms: 1711509895060,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "1193d188-9f06-44cb-9d31-6203a154876d",
            price: 70443.48,
            price_sources: [],
            processed_ms: 1711511713547,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "2702654d-5377-49b0-bd35-3c465ba2675c",
            price: 70269.0,
            price_sources: [],
            processed_ms: 1711513536211,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "43d8c611-df56-467a-a308-d00eb1bf268f",
            price: 70157.01,
            price_sources: [],
            processed_ms: 1711515376145,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "e023d8f2-7fdd-4598-9dd9-cccca1ba02db",
            price: 70431.36,
            price_sources: [],
            processed_ms: 1711517158798,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "4dde6016-90b0-433c-aded-ebb9f65bbcf4",
            price: 70377.96,
            price_sources: [],
            processed_ms: 1711518976851,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "49fb4f27-87b9-4ad4-8fd4-8d6736572fd5",
            price: 70364.52,
            price_sources: [],
            processed_ms: 1711520799129,
          },
          {
            leverage: -1.828125,
            order_type: "SHORT",
            order_uuid: "2781a0dc-2e64-42bc-ad09-90f7610cf3b9",
            price: 70266.76,
            price_sources: [],
            processed_ms: 1711522639803,
          },
          {
            leverage: -0.9140625,
            order_type: "SHORT",
            order_uuid: "759940db-a9dd-445c-8ce6-16654ab7c3dd",
            price: 69858.96,
            price_sources: [],
            processed_ms: 1711524879652,
          },
          {
            leverage: -0.45703125,
            order_type: "SHORT",
            order_uuid: "0ab4c4c4-96f7-485e-8f60-2ad7aab3e881",
            price: 69835.98,
            price_sources: [],
            processed_ms: 1711526698882,
          },
          {
            leverage: -0.228515625,
            order_type: "SHORT",
            order_uuid: "294a86d8-d192-4d2c-b83e-aee9d31fc82d",
            price: 69850.0,
            price_sources: [],
            processed_ms: 1711528510968,
          },
          {
            leverage: -0.1142578125,
            order_type: "SHORT",
            order_uuid: "8b52163b-870d-4398-8157-0ae375dc18e8",
            price: 69561.53,
            price_sources: [],
            processed_ms: 1711530332475,
          },
          {
            leverage: 0.375,
            order_type: "LONG",
            order_uuid: "c7a2bc32-4310-4aff-9c64-b0dc664689d8",
            price: 69749.81,
            price_sources: [],
            processed_ms: 1711532147146,
          },
          {
            leverage: 0.375,
            order_type: "LONG",
            order_uuid: "a564dba3-cabb-400f-b676-0127c1bdbaf2",
            price: 70020.75,
            price_sources: [],
            processed_ms: 1711533968949,
          },
          {
            leverage: 0.375,
            order_type: "LONG",
            order_uuid: "d119179b-cd82-4d9f-b6b9-c34d70881aac",
            price: 70039.59,
            price_sources: [],
            processed_ms: 1711536329667,
          },
          {
            leverage: 0.375,
            order_type: "LONG",
            order_uuid: "4fd4860a-70ec-48cc-b18d-716cad490a20",
            price: 70225.57,
            price_sources: [],
            processed_ms: 1711539961951,
          },
          {
            leverage: 0.375,
            order_type: "LONG",
            order_uuid: "36a7e86b-15f3-4465-9667-eaa19454898d",
            price: 70024.4,
            price_sources: [],
            processed_ms: 1711543567500,
          },
          {
            leverage: -0.99462890625,
            order_type: "SHORT",
            order_uuid: "9d34eb63-658d-4b94-bb72-e5d871046614",
            price: 70560.33,
            price_sources: [],
            processed_ms: 1711547173985,
          },
          {
            leverage: -0.795703125,
            order_type: "SHORT",
            order_uuid: "759e70ce-1dac-4380-b91e-c82d0a611232",
            price: 69858.5,
            price_sources: [],
            processed_ms: 1711550813872,
          },
          {
            leverage: -0.09946289062500002,
            order_type: "SHORT",
            order_uuid: "36d39038-ca66-4433-8036-315e4c314830",
            price: 69030.59,
            price_sources: [],
            processed_ms: 1711554406051,
          },
          {
            leverage: -0.04973144531250001,
            order_type: "SHORT",
            order_uuid: "c5f0d5a1-897e-43f8-844b-c7769d705c7f",
            price: 69064.3,
            price_sources: [],
            processed_ms: 1711558047181,
          },
          {
            leverage: -0.024865722656250006,
            order_type: "SHORT",
            order_uuid: "158f8d82-b22d-451c-8021-b0c9e2754b5d",
            price: 69038.2,
            price_sources: [],
            processed_ms: 1711561674701,
          },
          {
            leverage: 0.375,
            order_type: "LONG",
            order_uuid: "0f304934-930d-4174-b8b2-90c12c109c87",
            price: 68808.37,
            price_sources: [],
            processed_ms: 1711565286452,
          },
          {
            leverage: 0.375,
            order_type: "LONG",
            order_uuid: "d82fdf2d-d318-4ec9-a611-56e9d738c906",
            price: 68566.61,
            price_sources: [],
            processed_ms: 1711568936372,
          },
          {
            leverage: 0.3750000000000001,
            order_type: "LONG",
            order_uuid: "e82624f2-b686-4145-9f5a-8de66f048db2",
            price: 68881.72,
            price_sources: [],
            processed_ms: 1711572514894,
          },
          {
            leverage: -0.574932861328125,
            order_type: "SHORT",
            order_uuid: "76fb3854-c3e2-474b-b1d1-ace33da1f7ed",
            price: 69099.39,
            price_sources: [],
            processed_ms: 1711576172861,
          },
          {
            leverage: -0.2874664306640625,
            order_type: "SHORT",
            order_uuid: "819fdf6c-e130-40be-a046-31e540bd0a0c",
            price: 69410.53,
            price_sources: [],
            processed_ms: 1711579751087,
          },
          {
            leverage: 0.375,
            order_type: "LONG",
            order_uuid: "5b67789e-fb14-48b2-90c8-f7337bc77256",
            price: 69196.28,
            price_sources: [],
            processed_ms: 1711583376677,
          },
          {
            leverage: 0.3749999999999999,
            order_type: "LONG",
            order_uuid: "66e3ee69-0578-4cd5-938a-72d53e59eb5e",
            price: 69692.63,
            price_sources: [],
            processed_ms: 1711586996094,
          },
          {
            leverage: 0.375,
            order_type: "LONG",
            order_uuid: "14d688f8-240e-4aae-8b68-17c9b858e909",
            price: 69453.22,
            price_sources: [],
            processed_ms: 1711590625975,
          },
          {
            leverage: 0.375,
            order_type: "LONG",
            order_uuid: "1e29daab-f311-4a9c-818a-01331c3af442",
            price: 69046.86,
            price_sources: [],
            processed_ms: 1711594252357,
          },
          {
            leverage: 0.375,
            order_type: "LONG",
            order_uuid: "3a55efd6-6a85-4a7b-8c34-639351b91196",
            price: 69232.33,
            price_sources: [],
            processed_ms: 1711597885808,
          },
          {
            leverage: -1.0812332153320312,
            order_type: "SHORT",
            order_uuid: "e53fbe39-bdea-4665-8e56-a9143b39ac4c",
            price: 69679.43,
            price_sources: [],
            processed_ms: 1711600888830,
          },
          {
            leverage: -0.5406166076660156,
            order_type: "SHORT",
            order_uuid: "7ac93175-d2ef-47d0-81ef-5a7cc16823fc",
            price: 69644.36,
            price_sources: [],
            processed_ms: 1711602705936,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "47eb7700-85f0-44b6-aad2-e456e031819f",
            price: 70641.42,
            price_sources: [],
            processed_ms: 1711618454599,
          },
        ],
        position_type: "FLAT",
        position_uuid: "b886a2eb-519c-48b7-a5b1-7ca684196dec",
        return_at_close: 1.0295215280172862,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 70794.56,
        close_ms: 1711633282782,
        current_return: 0.9985904990439939,
        initial_entry_price: 70794.56,
        is_closed_position: true,
        miner_hotkey: "5GpPC45ZmJGZfR1AnceXW4p8BP4e58L1NVGx8cd8AukF22Fz",
        net_leverage: 0.0,
        open_ms: 1711633195897,
        orders: [
          {
            leverage: -3.5,
            order_type: "SHORT",
            order_uuid: "8d5e66ca-20a5-4cfa-a4f3-38c786d75fbb",
            price: 70794.56,
            price_sources: [],
            processed_ms: 1711633195895,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "82b94d70-6f8c-465e-a1d3-461d0cbf4319",
            price: 70823.07,
            price_sources: [],
            processed_ms: 1711633282782,
          },
        ],
        position_type: "FLAT",
        position_uuid: "962552e7-3c6c-418d-9007-c259e3f0694e",
        return_at_close: 0.9916003655506859,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 71111.44,
        close_ms: 1711635111943,
        current_return: 1.0131817046596163,
        initial_entry_price: 71111.44,
        is_closed_position: true,
        miner_hotkey: "5GpPC45ZmJGZfR1AnceXW4p8BP4e58L1NVGx8cd8AukF22Fz",
        net_leverage: 0.0,
        open_ms: 1711634994990,
        orders: [
          {
            leverage: -3.5,
            order_type: "SHORT",
            order_uuid: "3b958a5a-7312-4efb-894e-e10353cbe33c",
            price: 71111.44,
            price_sources: [],
            processed_ms: 1711634994987,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "049f744e-bdd5-4143-8c4a-2d6d00198ea9",
            price: 70843.62,
            price_sources: [],
            processed_ms: 1711635111943,
          },
        ],
        position_type: "FLAT",
        position_uuid: "3594db25-a955-455a-b7a1-507c7e3a8f70",
        return_at_close: 1.006089432726999,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 71376.89,
        close_ms: 1711636935904,
        current_return: 0.9978929454617593,
        initial_entry_price: 71376.89,
        is_closed_position: true,
        miner_hotkey: "5GpPC45ZmJGZfR1AnceXW4p8BP4e58L1NVGx8cd8AukF22Fz",
        net_leverage: 0.0,
        open_ms: 1711636832941,
        orders: [
          {
            leverage: -3.5,
            order_type: "SHORT",
            order_uuid: "e09af165-62dd-48b3-b950-6c4774870f2d",
            price: 71376.89,
            price_sources: [],
            processed_ms: 1711636832939,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "8dadcf5e-d7b2-4ed0-b993-6000e6a3eaad",
            price: 71419.86,
            price_sources: [],
            processed_ms: 1711636935904,
          },
        ],
        position_type: "FLAT",
        position_uuid: "3de723a6-1fcd-4d00-8a93-b2194e574f5a",
        return_at_close: 0.990907694843527,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 71113.24285714279,
        close_ms: 1711680230726,
        current_return: 1.0026204322520296,
        initial_entry_price: 70808.69,
        is_closed_position: true,
        miner_hotkey: "5GpPC45ZmJGZfR1AnceXW4p8BP4e58L1NVGx8cd8AukF22Fz",
        net_leverage: 0.0,
        open_ms: 1711662147426,
        orders: [
          {
            leverage: -5.0,
            order_type: "SHORT",
            order_uuid: "90dc8742-e8cd-4bac-ab4c-2c6411171cd1",
            price: 70808.69,
            price_sources: [],
            processed_ms: 1711662147423,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "8a01d1c6-27b9-406c-b85c-ee6106d39009",
            price: 70836.59,
            price_sources: [],
            processed_ms: 1711665761794,
          },
          {
            leverage: 1.75,
            order_type: "LONG",
            order_uuid: "d0b208ac-ff38-4123-916d-039d44c0e388",
            price: 70759.08,
            price_sources: [],
            processed_ms: 1711669398910,
          },
          {
            leverage: 0.875,
            order_type: "LONG",
            order_uuid: "0c57e9d7-44ff-4e35-b54a-d6096a54657b",
            price: 70666.75,
            price_sources: [],
            processed_ms: 1711672999916,
          },
          {
            leverage: 0.4375,
            order_type: "LONG",
            order_uuid: "cce79be9-74b1-41dd-aa62-31514520436c",
            price: 70890.8,
            price_sources: [],
            processed_ms: 1711676617882,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "edf65ecd-d220-445a-9bc6-5fbfe63cc153",
            price: 70689.13,
            price_sources: [],
            processed_ms: 1711680230726,
          },
        ],
        position_type: "FLAT",
        position_uuid: "9785ca66-b741-4e70-bbf9-4a8c77a9b909",
        return_at_close: 0.9925942279295092,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 70336.24090909092,
        close_ms: 1711698141838,
        current_return: 0.9694485527689952,
        initial_entry_price: 70405.83,
        is_closed_position: true,
        miner_hotkey: "5GpPC45ZmJGZfR1AnceXW4p8BP4e58L1NVGx8cd8AukF22Fz",
        net_leverage: 0.0,
        open_ms: 1711683848824,
        orders: [
          {
            leverage: 5.0,
            order_type: "LONG",
            order_uuid: "2898c13e-cbad-4988-9aac-784939a5b0ff",
            price: 70405.83,
            price_sources: [],
            processed_ms: 1711683848821,
          },
          {
            leverage: -2.5,
            order_type: "SHORT",
            order_uuid: "8f5ee02d-9a42-4971-8de7-c34a19294628",
            price: 70500.42,
            price_sources: [],
            processed_ms: 1711687485466,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "06c9e934-719a-4d54-bab7-b1e7c9d015ba",
            price: 70255.98,
            price_sources: [],
            processed_ms: 1711691100712,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "adfef838-ac4c-4fdc-82c9-adf1bac2b74b",
            price: 70458.17,
            price_sources: [],
            processed_ms: 1711694720353,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "483a1c84-162d-458b-83d1-3b54fe943bac",
            price: 69945.15,
            price_sources: [],
            processed_ms: 1711698141838,
          },
        ],
        position_type: "FLAT",
        position_uuid: "3277d031-5d06-48e2-b293-77bdbd97eb05",
        return_at_close: 0.9587846186885363,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 70013.18923076923,
        close_ms: 1711705325999,
        current_return: 0.9691641889555938,
        initial_entry_price: 70027.67,
        is_closed_position: true,
        miner_hotkey: "5GpPC45ZmJGZfR1AnceXW4p8BP4e58L1NVGx8cd8AukF22Fz",
        net_leverage: 0.0,
        open_ms: 1711698339177,
        orders: [
          {
            leverage: 5.0,
            order_type: "LONG",
            order_uuid: "9370452e-8758-40fb-9508-409e19147e9e",
            price: 70027.67,
            price_sources: [],
            processed_ms: 1711698339174,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "da8b4bad-04f7-4ded-b710-d7e9ff789a10",
            price: 69964.92,
            price_sources: [],
            processed_ms: 1711701950286,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "90149da2-1e1d-481c-a48b-79b71a9e121e",
            price: 69680.98,
            price_sources: [],
            processed_ms: 1711705325999,
          },
        ],
        position_type: "FLAT",
        position_uuid: "987bea60-ed36-4b37-8b06-ad2eaba3d0da",
        return_at_close: 0.9565650544991711,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 66400.094876033,
        close_ms: 1711752632673,
        current_return: 1.0459399158294382,
        initial_entry_price: 69660.34,
        is_closed_position: true,
        miner_hotkey: "5GpPC45ZmJGZfR1AnceXW4p8BP4e58L1NVGx8cd8AukF22Fz",
        net_leverage: 0.0,
        open_ms: 1711705586331,
        orders: [
          {
            leverage: 5.0,
            order_type: "LONG",
            order_uuid: "9dcd10ee-375a-44a2-80aa-0184d49ff226",
            price: 69660.34,
            price_sources: [],
            processed_ms: 1711705586328,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "86d39e51-d0b1-4ff4-bc16-5b2b0617d553",
            price: 69882.92,
            price_sources: [],
            processed_ms: 1711709199154,
          },
          {
            leverage: -3.25,
            order_type: "SHORT",
            order_uuid: "a73750d4-6363-4586-ad22-3135f645da67",
            price: 70166.36,
            price_sources: [],
            processed_ms: 1711712825985,
          },
          {
            leverage: -1.625,
            order_type: "SHORT",
            order_uuid: "fde24c82-e9a8-40f6-89b2-12205d38148c",
            price: 70050.76,
            price_sources: [],
            processed_ms: 1711716443324,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "6c3e7996-b2fe-4e44-bde8-43f223e9f97c",
            price: 70482.45,
            price_sources: [],
            processed_ms: 1711720063450,
          },
          {
            leverage: -1.5625,
            order_type: "SHORT",
            order_uuid: "657ae163-9bfa-41f3-b019-e626b2250343",
            price: 70402.42,
            price_sources: [],
            processed_ms: 1711723678125,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "4e51a05e-3aca-4c61-8231-37eb2efbac10",
            price: 69301.38,
            price_sources: [],
            processed_ms: 1711727340834,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "0cdaf128-b901-4241-8816-fe699249b869",
            price: 69359.01,
            price_sources: [],
            processed_ms: 1711730915231,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "3ad1bc97-76ba-4150-8698-6631a8de287a",
            price: 69462.4,
            price_sources: [],
            processed_ms: 1711734577507,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "7ce69aca-8545-4499-b778-b9c332ac2c24",
            price: 69466.49,
            price_sources: [],
            processed_ms: 1711738139894,
          },
          {
            leverage: -3.78125,
            order_type: "SHORT",
            order_uuid: "68e00504-9252-4576-901a-1de8f34b30b2",
            price: 69621.13,
            price_sources: [],
            processed_ms: 1711741757280,
          },
          {
            leverage: -1.890625,
            order_type: "SHORT",
            order_uuid: "53026276-d916-411b-968f-59945623a816",
            price: 69624.31,
            price_sources: [],
            processed_ms: 1711745384860,
          },
          {
            leverage: -0.9453125,
            order_type: "SHORT",
            order_uuid: "788c56dd-021c-449e-a784-0b79430613cb",
            price: 69500.34,
            price_sources: [],
            processed_ms: 1711749002065,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "f3f126fc-0892-40f7-b734-ef320f372fe5",
            price: 69785.42,
            price_sources: [],
            processed_ms: 1711752632673,
          },
        ],
        position_type: "FLAT",
        position_uuid: "3e6adfc2-9ffc-4c17-8413-0b03ddea4041",
        return_at_close: 1.0301200746025179,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 71843.01999999987,
        close_ms: 1711781608807,
        current_return: 0.9920593157324807,
        initial_entry_price: 70054.18,
        is_closed_position: true,
        miner_hotkey: "5GpPC45ZmJGZfR1AnceXW4p8BP4e58L1NVGx8cd8AukF22Fz",
        net_leverage: 0.0,
        open_ms: 1711763505714,
        orders: [
          {
            leverage: 5.0,
            order_type: "LONG",
            order_uuid: "ae283a51-148c-45b6-9430-b73bcf703f1f",
            price: 70054.18,
            price_sources: [],
            processed_ms: 1711763505711,
          },
          {
            leverage: -2.5,
            order_type: "SHORT",
            order_uuid: "b4340eb1-71a4-4ff6-a8af-2972e2050fbc",
            price: 69922.06,
            price_sources: [],
            processed_ms: 1711767130512,
          },
          {
            leverage: -1.25,
            order_type: "SHORT",
            order_uuid: "545290fa-ce37-42bf-90a6-f3c9d262cb4f",
            price: 69880.78,
            price_sources: [],
            processed_ms: 1711770743832,
          },
          {
            leverage: -0.625,
            order_type: "SHORT",
            order_uuid: "b9bbb8ed-5340-4c39-beb2-2ead97b4850d",
            price: 70026.69,
            price_sources: [],
            processed_ms: 1711774360850,
          },
          {
            leverage: -0.3125,
            order_type: "SHORT",
            order_uuid: "42785d7b-4b3f-4f79-9c4a-37790e88cfe6",
            price: 70070.88,
            price_sources: [],
            processed_ms: 1711777975892,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "8b437d6d-a3a9-47d8-80c5-fbe7db609096",
            price: 70062.93,
            price_sources: [],
            processed_ms: 1711781608807,
          },
        ],
        position_type: "FLAT",
        position_uuid: "f2b71dd7-7a37-4885-bd26-32a83a130b19",
        return_at_close: 0.9821387225751559,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 69510.67000000007,
        close_ms: 1711806952591,
        current_return: 0.9917130039554931,
        initial_entry_price: 70044.38,
        is_closed_position: true,
        miner_hotkey: "5GpPC45ZmJGZfR1AnceXW4p8BP4e58L1NVGx8cd8AukF22Fz",
        net_leverage: 0.0,
        open_ms: 1711792452928,
        orders: [
          {
            leverage: -5.0,
            order_type: "SHORT",
            order_uuid: "b59f4543-9ad7-44e8-b305-4c058da50a81",
            price: 70044.38,
            price_sources: [],
            processed_ms: 1711792452925,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "25a9d4a0-8064-40d5-80ff-81d8caf32fd9",
            price: 70063.98,
            price_sources: [],
            processed_ms: 1711796068193,
          },
          {
            leverage: 1.75,
            order_type: "LONG",
            order_uuid: "97f8ac0d-a9f8-4939-a729-340b51d49127",
            price: 70199.76,
            price_sources: [],
            processed_ms: 1711799686130,
          },
          {
            leverage: 0.875,
            order_type: "LONG",
            order_uuid: "d0deec80-ae5a-4629-bad1-b27668b23a93",
            price: 70233.73,
            price_sources: [],
            processed_ms: 1711803298831,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "851289e9-d5e9-4a6d-a238-445c815ea4ee",
            price: 70174.05,
            price_sources: [],
            processed_ms: 1711806952591,
          },
        ],
        position_type: "FLAT",
        position_uuid: "3fe37fb1-95d6-418e-a52f-8cb9cc33838c",
        return_at_close: 0.9817958739159381,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 69765.2399999999,
        close_ms: 1711828652104,
        current_return: 1.0007577184661447,
        initial_entry_price: 70099.42,
        is_closed_position: true,
        miner_hotkey: "5GpPC45ZmJGZfR1AnceXW4p8BP4e58L1NVGx8cd8AukF22Fz",
        net_leverage: 0.0,
        open_ms: 1711810541054,
        orders: [
          {
            leverage: 5.0,
            order_type: "LONG",
            order_uuid: "75e7b5f7-b000-4d70-8ab2-9565f5c416f6",
            price: 70099.42,
            price_sources: [],
            processed_ms: 1711810541051,
          },
          {
            leverage: -2.5,
            order_type: "SHORT",
            order_uuid: "c8dd852c-3924-408b-993d-8df493a1ef86",
            price: 70053.83,
            price_sources: [],
            processed_ms: 1711814164912,
          },
          {
            leverage: -1.25,
            order_type: "SHORT",
            order_uuid: "e21f150c-c5be-4a6c-845f-57b2111d9120",
            price: 70293.8,
            price_sources: [],
            processed_ms: 1711817835510,
          },
          {
            leverage: -0.625,
            order_type: "SHORT",
            order_uuid: "eb5b3346-9955-4775-83b9-10ae3b1b511a",
            price: 70111.59,
            price_sources: [],
            processed_ms: 1711821390467,
          },
          {
            leverage: -0.3125,
            order_type: "SHORT",
            order_uuid: "d8ff6f58-2fe2-4e59-9d96-085081d66e9c",
            price: 69996.46,
            price_sources: [],
            processed_ms: 1711825002965,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "311efffe-bcc1-49ab-b260-66ee9cbf22be",
            price: 69935.21,
            price_sources: [],
            processed_ms: 1711828652104,
          },
        ],
        position_type: "FLAT",
        position_uuid: "d34cd696-b116-449e-beb4-3cf26881f62a",
        return_at_close: 0.9907501412814832,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 68653.7699999999,
        close_ms: 1711893759447,
        current_return: 0.9867949028967252,
        initial_entry_price: 70240.68,
        is_closed_position: true,
        miner_hotkey: "5GpPC45ZmJGZfR1AnceXW4p8BP4e58L1NVGx8cd8AukF22Fz",
        net_leverage: 0.0,
        open_ms: 1711879275055,
        orders: [
          {
            leverage: -5.0,
            order_type: "SHORT",
            order_uuid: "15c30c60-2261-4dd0-b535-8ecddfe64669",
            price: 70240.68,
            price_sources: [],
            processed_ms: 1711879275052,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "8b0de579-6463-4906-82ae-dc944bb8ecfd",
            price: 70296.8,
            price_sources: [],
            processed_ms: 1711882906882,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "5d80b883-cb3d-4289-8409-97c77712ca7f",
            price: 70416.64,
            price_sources: [],
            processed_ms: 1711886523967,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "f85a5e44-d2a1-4773-a705-75d9de7f718d",
            price: 70537.57,
            price_sources: [],
            processed_ms: 1711890140897,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "a654f703-9643-437e-a381-da4e05fc3e39",
            price: 70508.84,
            price_sources: [],
            processed_ms: 1711893759447,
          },
        ],
        position_type: "FLAT",
        position_uuid: "5ce756b0-bff9-4f96-9c20-7f264b588c8e",
        return_at_close: 0.976926953867758,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 70731.46999999999,
        close_ms: 1711915453356,
        current_return: 0.9986191563885621,
        initial_entry_price: 70498.57,
        is_closed_position: true,
        miner_hotkey: "5GpPC45ZmJGZfR1AnceXW4p8BP4e58L1NVGx8cd8AukF22Fz",
        net_leverage: 0.0,
        open_ms: 1711897369949,
        orders: [
          {
            leverage: -5.0,
            order_type: "SHORT",
            order_uuid: "ae31684c-bc97-4b7f-b417-11abb3b003a8",
            price: 70498.57,
            price_sources: [],
            processed_ms: 1711897369941,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "1e8d1138-7903-4fd3-93c5-e277eaa5760e",
            price: 70465.12,
            price_sources: [],
            processed_ms: 1711900989480,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "67fc36f1-82d1-418d-81cb-018631d78901",
            price: 70469.41,
            price_sources: [],
            processed_ms: 1711904624403,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "332493e4-df54-47e6-84cb-fb948da128c9",
            price: 70492.08,
            price_sources: [],
            processed_ms: 1711908247984,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "4429bdeb-3919-4c62-974a-9f1cb60ad359",
            price: 70680.27,
            price_sources: [],
            processed_ms: 1711911836880,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "3a8c44f6-33c7-46b9-be44-26d6055e7473",
            price: 71120.86,
            price_sources: [],
            processed_ms: 1711915453356,
          },
        ],
        position_type: "FLAT",
        position_uuid: "b192e491-aefa-43c2-97a1-9e448bb0b6ec",
        return_at_close: 0.9886329648246766,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 68543.72999999998,
        close_ms: 1711933548950,
        current_return: 0.9816538904841761,
        initial_entry_price: 70823.19,
        is_closed_position: true,
        miner_hotkey: "5GpPC45ZmJGZfR1AnceXW4p8BP4e58L1NVGx8cd8AukF22Fz",
        net_leverage: 0.0,
        open_ms: 1711919067071,
        orders: [
          {
            leverage: -5.0,
            order_type: "SHORT",
            order_uuid: "a4f02645-f2fa-4507-9710-a454c90312ee",
            price: 70823.19,
            price_sources: [],
            processed_ms: 1711919067066,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "4b7e7269-232f-4e47-b90c-204ebda17bb9",
            price: 71015.05,
            price_sources: [],
            processed_ms: 1711922688863,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "2ab509ee-4713-4a1c-b430-3173278cccb3",
            price: 70960.42,
            price_sources: [],
            processed_ms: 1711926299465,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "406eebf8-ce21-4e5c-909b-06299d34ac41",
            price: 71253.92,
            price_sources: [],
            processed_ms: 1711929920527,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "2c1ed8b4-dc55-4c47-8cca-a5da34c88819",
            price: 71142.39,
            price_sources: [],
            processed_ms: 1711933548950,
          },
        ],
        position_type: "FLAT",
        position_uuid: "e0655a10-370e-447f-a2be-430d0571dc2d",
        return_at_close: 0.9718373515793343,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
    ],
    thirty_day_returns: 1.0,
  },
  "5Gq2KAnCcmfwdx3Lhf8zfC43Nv8Uvpstk5S84MnwVrrvvcYQ": {
    all_time_returns: 1.0172284785120982,
    n_positions: 10,
    percentage_profitable: 0.4,
    positions: [
      {
        average_entry_price: 157.251,
        close_ms: 1718195465390,
        current_return: 1.030333670374116,
        initial_entry_price: 157.251,
        is_closed_position: true,
        miner_hotkey: "5Gq2KAnCcmfwdx3Lhf8zfC43Nv8Uvpstk5S84MnwVrrvvcYQ",
        net_leverage: 0.0,
        open_ms: 1718194657107,
        orders: [
          {
            leverage: -6.0,
            order_type: "SHORT",
            order_uuid: "24a29b6c-0bbb-4e12-a605-c73af9730b6b",
            price: 157.251,
            price_sources: [],
            processed_ms: 1718194657100,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "15d4d766-87aa-4c71-bf7e-bdd6571fc2fd",
            price: 156.456,
            price_sources: [],
            processed_ms: 1718195465390,
          },
        ],
        position_type: "FLAT",
        position_uuid: "b9a65fc8-5df7-4ede-bc6e-64956a5ff174",
        return_at_close: 1.0299009302325588,
        trade_pair: ["USDJPY", "USD/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 67113.85,
        close_ms: 1718196435497,
        current_return: 1.0026106682897793,
        initial_entry_price: 67113.85,
        is_closed_position: true,
        miner_hotkey: "5Gq2KAnCcmfwdx3Lhf8zfC43Nv8Uvpstk5S84MnwVrrvvcYQ",
        net_leverage: 0.0,
        open_ms: 1716112356072,
        orders: [
          {
            leverage: 0.08,
            order_type: "LONG",
            order_uuid: "02dbd264-dd94-41af-b3da-9cc6b67f6d4b",
            price: 67113.85,
            price_sources: [],
            processed_ms: 1716112352408,
          },
          {
            leverage: -2.0,
            order_type: "SHORT",
            order_uuid: "ee72b4ba-183d-4b9f-8903-0911ab3fb60b",
            price: 69304.0,
            price_sources: [],
            processed_ms: 1718196435497,
          },
        ],
        position_type: "FLAT",
        position_uuid: "1bff2d96-a6ab-4694-8883-648150cb9887",
        return_at_close: 1.0025304594363162,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 68716.39999999998,
        close_ms: 1718196777376,
        current_return: 1.0024659653984203,
        initial_entry_price: 69173.72,
        is_closed_position: true,
        miner_hotkey: "5Gq2KAnCcmfwdx3Lhf8zfC43Nv8Uvpstk5S84MnwVrrvvcYQ",
        net_leverage: 0.0,
        open_ms: 1718196470858,
        orders: [
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "ff5af5d9-6006-49ec-bc88-2b1e7ad54f05",
            price: 69173.72,
            price_sources: [],
            processed_ms: 1718196467592,
          },
          {
            leverage: -1.2,
            order_type: "SHORT",
            order_uuid: "3833c22d-e658-458e-9a33-9dd9828df9b8",
            price: 69288.05,
            price_sources: [],
            processed_ms: 1718196740191,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "85b6d2bf-315e-4809-848e-106a052b8ac7",
            price: 69285.0,
            price_sources: [],
            processed_ms: 1718196777376,
          },
        ],
        position_type: "FLAT",
        position_uuid: "8c7a3d18-6818-4c7a-943c-79c864efcc7f",
        return_at_close: 1.0009622664503226,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 68095.97000000003,
        close_ms: 1718197121507,
        current_return: 1.0038327061578944,
        initial_entry_price: 69228.37,
        is_closed_position: true,
        miner_hotkey: "5Gq2KAnCcmfwdx3Lhf8zfC43Nv8Uvpstk5S84MnwVrrvvcYQ",
        net_leverage: 0.0,
        open_ms: 1718196985848,
        orders: [
          {
            leverage: 4.0,
            order_type: "LONG",
            order_uuid: "91eda446-39cc-4d7d-a68e-f4d32e132de0",
            price: 69228.37,
            price_sources: [],
            processed_ms: 1718196985019,
          },
          {
            leverage: -3.8,
            order_type: "SHORT",
            order_uuid: "a31ab790-4d82-4665-ba16-135ef973d6a3",
            price: 69287.97,
            price_sources: [],
            processed_ms: 1718197041259,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "257f5ac4-2250-4b9e-9077-83a9cd3492fc",
            price: 69422.63,
            price_sources: [],
            processed_ms: 1718197121507,
          },
        ],
        position_type: "FLAT",
        position_uuid: "c4d67323-060c-441a-ae79-f961017a2dc7",
        return_at_close: 0.9998173753332629,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 3646.9325,
        close_ms: 1718197577294,
        current_return: 1.0003450477123392,
        initial_entry_price: 3634.28,
        is_closed_position: true,
        miner_hotkey: "5Gq2KAnCcmfwdx3Lhf8zfC43Nv8Uvpstk5S84MnwVrrvvcYQ",
        net_leverage: 0.0,
        open_ms: 1718196821839,
        orders: [
          {
            leverage: -1.2,
            order_type: "SHORT",
            order_uuid: "ad8b2dd4-314d-49dc-abea-c2634eec35c9",
            price: 3634.28,
            price_sources: [],
            processed_ms: 1718196821828,
          },
          {
            leverage: 1.0,
            order_type: "LONG",
            order_uuid: "8d3e41bb-61c6-4fc4-8158-2d297f0a5fea",
            price: 3628.56,
            price_sources: [],
            processed_ms: 1718196923659,
          },
          {
            leverage: -2.0,
            order_type: "SHORT",
            order_uuid: "6af4c9d2-1d04-4f98-9c1b-c64d83749487",
            price: 3640.75,
            price_sources: [],
            processed_ms: 1718197361420,
          },
          {
            leverage: 1.8,
            order_type: "LONG",
            order_uuid: "7bfea6fd-408d-46b9-a574-a2669cdbddee",
            price: 3637.61,
            price_sources: [],
            processed_ms: 1718197433596,
          },
          {
            leverage: -2.0,
            order_type: "SHORT",
            order_uuid: "dd487fe6-1fad-4162-8eb4-75c7c4a198db",
            price: 3643.13,
            price_sources: [],
            processed_ms: 1718197540697,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "d49e4f9e-c1b1-4114-be02-5e7ccce6f9f9",
            price: 3646.41,
            price_sources: [],
            processed_ms: 1718197577294,
          },
        ],
        position_type: "FLAT",
        position_uuid: "cd1649e9-0dd3-4f21-b351-a916e32d0aef",
        return_at_close: 0.995143253464235,
        trade_pair: ["ETHUSD", "ETH/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 3632.4400000000005,
        close_ms: 1718203926800,
        current_return: 1.00020649495464,
        initial_entry_price: 3632.05,
        is_closed_position: true,
        miner_hotkey: "5Gq2KAnCcmfwdx3Lhf8zfC43Nv8Uvpstk5S84MnwVrrvvcYQ",
        net_leverage: 0.0,
        open_ms: 1718203672722,
        orders: [
          {
            leverage: -2.0,
            order_type: "SHORT",
            order_uuid: "1f256fd8-2e77-4200-9ea8-cf93aab9929b",
            price: 3632.05,
            price_sources: [],
            processed_ms: 1718203672231,
          },
          {
            leverage: 1.0,
            order_type: "LONG",
            order_uuid: "0c17b824-6447-4d37-93b3-c47ccf036648",
            price: 3631.66,
            price_sources: [],
            processed_ms: 1718203762973,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "78a768b9-83f3-44c4-bbca-07008d7d8e4a",
            price: 3631.69,
            price_sources: [],
            processed_ms: 1718203926800,
          },
        ],
        position_type: "FLAT",
        position_uuid: "75f5cb22-2d11-48f9-90ed-60ad65743214",
        return_at_close: 0.9982060819647308,
        trade_pair: ["ETHUSD", "ETH/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 69832.35999999999,
        close_ms: 1718204228539,
        current_return: 0.9994912649242201,
        initial_entry_price: 69820.23,
        is_closed_position: true,
        miner_hotkey: "5Gq2KAnCcmfwdx3Lhf8zfC43Nv8Uvpstk5S84MnwVrrvvcYQ",
        net_leverage: 0.0,
        open_ms: 1718203829227,
        orders: [
          {
            leverage: -1.0,
            order_type: "SHORT",
            order_uuid: "bac0a390-3a0e-471b-aeaf-d32b24f242d0",
            price: 69820.23,
            price_sources: [],
            processed_ms: 1718203828626,
          },
          {
            leverage: -1.0,
            order_type: "SHORT",
            order_uuid: "f5a4d6d8-c5fc-4510-8b82-ac98eef859dd",
            price: 69853.51,
            price_sources: [],
            processed_ms: 1718204061064,
          },
          {
            leverage: 1.0,
            order_type: "LONG",
            order_uuid: "786078c2-e2d5-4edd-8101-ca7e31a36d6a",
            price: 69841.38,
            price_sources: [],
            processed_ms: 1718204126585,
          },
          {
            leverage: 1.0,
            order_type: "LONG",
            order_uuid: "163cd25d-7314-45ec-8a4b-0e2d8a22c088",
            price: 69867.88,
            price_sources: [],
            processed_ms: 1718204228539,
          },
        ],
        position_type: "FLAT",
        position_uuid: "72fbef20-88c5-4b9f-8c33-0876d629a17b",
        return_at_close: 0.9974922823943717,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 69940.62,
        close_ms: 1718204635995,
        current_return: 0.9994452436938647,
        initial_entry_price: 69940.62,
        is_closed_position: true,
        miner_hotkey: "5Gq2KAnCcmfwdx3Lhf8zfC43Nv8Uvpstk5S84MnwVrrvvcYQ",
        net_leverage: 0.0,
        open_ms: 1718204465878,
        orders: [
          {
            leverage: -2.0,
            order_type: "SHORT",
            order_uuid: "81c5083f-e2c8-4993-a8c9-2df7b97e0a26",
            price: 69940.62,
            price_sources: [],
            processed_ms: 1718204465340,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "3289f332-4378-4eb6-a085-96ab63481bb6",
            price: 69960.02,
            price_sources: [],
            processed_ms: 1718204635995,
          },
        ],
        position_type: "FLAT",
        position_uuid: "4ce61b71-cf66-4a6e-8983-cc3ebdc0f3dc",
        return_at_close: 0.9974463532064769,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 69801.84,
        close_ms: 1718205128971,
        current_return: 0.9979914569587276,
        initial_entry_price: 69801.84,
        is_closed_position: true,
        miner_hotkey: "5Gq2KAnCcmfwdx3Lhf8zfC43Nv8Uvpstk5S84MnwVrrvvcYQ",
        net_leverage: 0.0,
        open_ms: 1718204874139,
        orders: [
          {
            leverage: -2.0,
            order_type: "SHORT",
            order_uuid: "bba4a464-c6a8-4f07-91eb-504f59317d35",
            price: 69801.84,
            price_sources: [],
            processed_ms: 1718204873623,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "3ab22222-76cf-432f-bd4b-e86cf8913894",
            price: 69871.94,
            price_sources: [],
            processed_ms: 1718205128971,
          },
        ],
        position_type: "FLAT",
        position_uuid: "1bc94ef0-3890-4a21-8e01-7fa99cdfcfff",
        return_at_close: 0.9959954740448101,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 60799.38233128835,
        close_ms: 1719622858994,
        current_return: 1.00168992186646,
        initial_entry_price: 67149.14,
        is_closed_position: true,
        miner_hotkey: "5Gq2KAnCcmfwdx3Lhf8zfC43Nv8Uvpstk5S84MnwVrrvvcYQ",
        net_leverage: 0.0,
        open_ms: 1718353341737,
        orders: [
          {
            leverage: 0.13,
            order_type: "LONG",
            order_uuid: "b7eab4f4-ca3a-42bf-961a-461e1acd7be9",
            price: 67149.14,
            price_sources: [],
            processed_ms: 1718353341053,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "f9364443-f154-4858-a107-a9a4605891de",
            price: 60249.07,
            price_sources: [],
            processed_ms: 1719612141449,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "60ae94ae-7dff-4fad-8899-e8eae5bb9db7",
            price: 60869.0,
            price_sources: [],
            processed_ms: 1719622858994,
          },
        ],
        position_type: "FLAT",
        position_uuid: "02bb67fd-98f5-4156-af4a-f1732e8e2f94",
        return_at_close: 1.0000571672938177,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
    ],
    thirty_day_returns: 1.0146609202118866,
  },
  "5Gs2TsVu8YgnRQvtUXajFjjewJgEZVcEDuNVwg6MfkFJu3c8": {
    all_time_returns: 1.0,
    n_positions: 0,
    percentage_profitable: 0.0,
    positions: [
      {
        average_entry_price: 64637.75000000001,
        close_ms: 1711198787561,
        current_return: 1.0011471315137053,
        initial_entry_price: 64637.75,
        is_closed_position: true,
        miner_hotkey: "5Gs2TsVu8YgnRQvtUXajFjjewJgEZVcEDuNVwg6MfkFJu3c8",
        net_leverage: 0.0,
        open_ms: 1711195242755,
        orders: [
          {
            leverage: 0.6,
            order_type: "LONG",
            order_uuid: "819dd4b4-c2fa-4fd7-8220-6326ebc651a4",
            price: 64637.75,
            price_sources: [],
            processed_ms: 1711195242754,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "b553fb31-46fd-4df6-9b17-10080be38513",
            price: 64761.33,
            price_sources: [],
            processed_ms: 1711198787561,
          },
        ],
        position_type: "FLAT",
        position_uuid: "f79d369a-34d1-4dc9-ba41-bfc3377c161e",
        return_at_close: 0.9999457549558889,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 64703.04,
        close_ms: 1711202449283,
        current_return: 0.9990193660143326,
        initial_entry_price: 64703.04,
        is_closed_position: true,
        miner_hotkey: "5Gs2TsVu8YgnRQvtUXajFjjewJgEZVcEDuNVwg6MfkFJu3c8",
        net_leverage: 0.0,
        open_ms: 1711198874669,
        orders: [
          {
            leverage: 0.6,
            order_type: "LONG",
            order_uuid: "fc434611-b7f2-48cd-9dbe-6a8418b50db7",
            price: 64703.04,
            price_sources: [],
            processed_ms: 1711198874668,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "06668bfe-93e5-4216-8533-1cce63b2c86d",
            price: 64597.29,
            price_sources: [],
            processed_ms: 1711202449283,
          },
        ],
        position_type: "FLAT",
        position_uuid: "055cbcd8-8ac0-4baa-a234-35449e26a229",
        return_at_close: 0.9978205427751154,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 64611.65,
        close_ms: 1711206124599,
        current_return: 1.0059918606009908,
        initial_entry_price: 64611.65,
        is_closed_position: true,
        miner_hotkey: "5Gs2TsVu8YgnRQvtUXajFjjewJgEZVcEDuNVwg6MfkFJu3c8",
        net_leverage: 0.0,
        open_ms: 1711202553574,
        orders: [
          {
            leverage: 0.6,
            order_type: "LONG",
            order_uuid: "4a5bbbcc-aa7a-4e72-9f05-560ab55f25e3",
            price: 64611.65,
            price_sources: [],
            processed_ms: 1711202553573,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "fdc980ff-d2ec-43ea-8878-df9988a2c508",
            price: 65256.89,
            price_sources: [],
            processed_ms: 1711206124599,
          },
        ],
        position_type: "FLAT",
        position_uuid: "f19a1186-0bfa-4029-baaa-47a718387884",
        return_at_close: 1.0047846703682697,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 65162.27999999999,
        close_ms: 1711209771775,
        current_return: 0.9990868643638621,
        initial_entry_price: 65162.28,
        is_closed_position: true,
        miner_hotkey: "5Gs2TsVu8YgnRQvtUXajFjjewJgEZVcEDuNVwg6MfkFJu3c8",
        net_leverage: 0.0,
        open_ms: 1711206227575,
        orders: [
          {
            leverage: 0.6,
            order_type: "LONG",
            order_uuid: "23df8a0d-b2bb-4586-800f-d9bd17c4e7fe",
            price: 65162.28,
            price_sources: [],
            processed_ms: 1711206227574,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "6bc76465-8ecf-4c9b-8abf-4d533071790d",
            price: 65063.11,
            price_sources: [],
            processed_ms: 1711209771775,
          },
        ],
        position_type: "FLAT",
        position_uuid: "88af5e20-0390-48a2-ae98-678416f51acc",
        return_at_close: 0.9978879601266255,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 65070.48999999999,
        close_ms: 1711213439019,
        current_return: 1.0059049808907234,
        initial_entry_price: 65070.49,
        is_closed_position: true,
        miner_hotkey: "5Gs2TsVu8YgnRQvtUXajFjjewJgEZVcEDuNVwg6MfkFJu3c8",
        net_leverage: 0.0,
        open_ms: 1711209912166,
        orders: [
          {
            leverage: 0.6,
            order_type: "LONG",
            order_uuid: "f41ca8e8-72a2-4380-ae1d-913ae847125e",
            price: 65070.49,
            price_sources: [],
            processed_ms: 1711209912165,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "e1c41aca-1595-4a85-a8ef-738dd4c9f3fa",
            price: 65710.89,
            price_sources: [],
            processed_ms: 1711213439019,
          },
        ],
        position_type: "FLAT",
        position_uuid: "8e3fcc20-b342-4e70-a32f-ec8d5a432ad3",
        return_at_close: 1.0046978949136547,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 65593.78,
        close_ms: 1711217120480,
        current_return: 0.9999377075082424,
        initial_entry_price: 65593.78,
        is_closed_position: true,
        miner_hotkey: "5Gs2TsVu8YgnRQvtUXajFjjewJgEZVcEDuNVwg6MfkFJu3c8",
        net_leverage: 0.0,
        open_ms: 1711213524611,
        orders: [
          {
            leverage: 0.6,
            order_type: "LONG",
            order_uuid: "eb37e2ce-3e14-4be7-a4df-b05ce53a683c",
            price: 65593.78,
            price_sources: [],
            processed_ms: 1711213524610,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "b69b529c-2d35-4fd3-ac6b-022bb858ac2b",
            price: 65586.97,
            price_sources: [],
            processed_ms: 1711217120480,
          },
        ],
        position_type: "FLAT",
        position_uuid: "4f64694c-c9e0-47e1-83b0-6b517a228b28",
        return_at_close: 0.9987377822592325,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 65654.36,
        close_ms: 1711220765270,
        current_return: 0.9977230758170516,
        initial_entry_price: 65654.36,
        is_closed_position: true,
        miner_hotkey: "5Gs2TsVu8YgnRQvtUXajFjjewJgEZVcEDuNVwg6MfkFJu3c8",
        net_leverage: 0.0,
        open_ms: 1711217224479,
        orders: [
          {
            leverage: 0.6,
            order_type: "LONG",
            order_uuid: "8d025800-6c50-49a0-b31d-d9fbdd43ea80",
            price: 65654.36,
            price_sources: [],
            processed_ms: 1711217224478,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "54dd0322-036c-4e8e-84bf-efd040dfd8aa",
            price: 65405.21,
            price_sources: [],
            processed_ms: 1711220765270,
          },
        ],
        position_type: "FLAT",
        position_uuid: "e27268d4-f186-442d-8e2b-18dc6e0b6e82",
        return_at_close: 0.9965258081260712,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 65368.98,
        close_ms: 1711224442268,
        current_return: 0.9960172852628265,
        initial_entry_price: 65368.98,
        is_closed_position: true,
        miner_hotkey: "5Gs2TsVu8YgnRQvtUXajFjjewJgEZVcEDuNVwg6MfkFJu3c8",
        net_leverage: 0.0,
        open_ms: 1711220851680,
        orders: [
          {
            leverage: 0.6,
            order_type: "LONG",
            order_uuid: "6672e0d2-00e2-468d-9fce-ec5dc335dda2",
            price: 65368.98,
            price_sources: [],
            processed_ms: 1711220851679,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "e258d2ef-8106-4d2c-a656-185b932fdd7b",
            price: 64935.07,
            price_sources: [],
            processed_ms: 1711224442268,
          },
        ],
        position_type: "FLAT",
        position_uuid: "7a76c795-c7a0-4c84-b802-100ea45fea9e",
        return_at_close: 0.9948220645205111,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 64836.78,
        close_ms: 1711228088271,
        current_return: 1.000310379386515,
        initial_entry_price: 64836.78,
        is_closed_position: true,
        miner_hotkey: "5Gs2TsVu8YgnRQvtUXajFjjewJgEZVcEDuNVwg6MfkFJu3c8",
        net_leverage: 0.0,
        open_ms: 1711224547754,
        orders: [
          {
            leverage: 0.6,
            order_type: "LONG",
            order_uuid: "695320b8-f548-47e8-9c90-df8564ad88f6",
            price: 64836.78,
            price_sources: [],
            processed_ms: 1711224547753,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "407e2227-71f4-4025-9b59-213cbe020c85",
            price: 64870.32,
            price_sources: [],
            processed_ms: 1711228088271,
          },
        ],
        position_type: "FLAT",
        position_uuid: "584eedf6-3cc4-40b8-b88c-fae33f19feec",
        return_at_close: 0.9991100069312511,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 64909.31,
        close_ms: 1711231749428,
        current_return: 1.0004538640142686,
        initial_entry_price: 64909.31,
        is_closed_position: true,
        miner_hotkey: "5Gs2TsVu8YgnRQvtUXajFjjewJgEZVcEDuNVwg6MfkFJu3c8",
        net_leverage: 0.0,
        open_ms: 1711228208568,
        orders: [
          {
            leverage: 0.6,
            order_type: "LONG",
            order_uuid: "0cd1e1fe-d49a-4d8a-becf-e8d467fe5592",
            price: 64909.31,
            price_sources: [],
            processed_ms: 1711228208567,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "06fc818a-b330-4f59-b5a6-6d2606df2d00",
            price: 64958.41,
            price_sources: [],
            processed_ms: 1711231749428,
          },
        ],
        position_type: "FLAT",
        position_uuid: "12962b37-b104-4f21-8a39-1b4937de47a6",
        return_at_close: 0.9992533193774515,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 64969.87,
        close_ms: 1711235409433,
        current_return: 0.9979424308529476,
        initial_entry_price: 64969.87,
        is_closed_position: true,
        miner_hotkey: "5Gs2TsVu8YgnRQvtUXajFjjewJgEZVcEDuNVwg6MfkFJu3c8",
        net_leverage: 0.0,
        open_ms: 1711231836714,
        orders: [
          {
            leverage: 0.6,
            order_type: "LONG",
            order_uuid: "662b12fa-8901-4245-8b13-490309665c20",
            price: 64969.87,
            price_sources: [],
            processed_ms: 1711231836713,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "3f95feed-c11b-4195-8642-6858c7db923a",
            price: 64747.07,
            price_sources: [],
            processed_ms: 1711235409433,
          },
        ],
        position_type: "FLAT",
        position_uuid: "836dab6c-8571-43dd-b0b5-171da06d6536",
        return_at_close: 0.9967448999359241,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 64548.94,
        close_ms: 1711239074793,
        current_return: 0.9975877837808026,
        initial_entry_price: 64548.94,
        is_closed_position: true,
        miner_hotkey: "5Gs2TsVu8YgnRQvtUXajFjjewJgEZVcEDuNVwg6MfkFJu3c8",
        net_leverage: 0.0,
        open_ms: 1711235552460,
        orders: [
          {
            leverage: 0.6,
            order_type: "LONG",
            order_uuid: "11b880ba-9371-4202-98dc-2066e6d61e48",
            price: 64548.94,
            price_sources: [],
            processed_ms: 1711235552459,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "49ec35cf-c871-4f9f-8d6d-c1f5701f60e3",
            price: 64289.43,
            price_sources: [],
            processed_ms: 1711239074793,
          },
        ],
        position_type: "FLAT",
        position_uuid: "6cecfd8f-a229-40f9-a9ff-7e7918bacdd8",
        return_at_close: 0.9963906784402656,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 64408.785,
        close_ms: 1711242786331,
        current_return: 1.001291491981447,
        initial_entry_price: 64339.54,
        is_closed_position: true,
        miner_hotkey: "5Gs2TsVu8YgnRQvtUXajFjjewJgEZVcEDuNVwg6MfkFJu3c8",
        net_leverage: 0.0,
        open_ms: 1711239161496,
        orders: [
          {
            leverage: 0.6,
            order_type: "LONG",
            order_uuid: "56f4a3fe-465a-443d-9604-2d266bef1033",
            price: 64339.54,
            price_sources: [],
            processed_ms: 1711239161494,
          },
          {
            leverage: 0.6,
            order_type: "LONG",
            order_uuid: "3a399ba0-0463-415c-8e81-f92cd3bc5ceb",
            price: 64478.03,
            price_sources: [],
            processed_ms: 1711242765756,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "84ac8ccd-0f50-4c04-bb1e-0ebb43338192",
            price: 64478.03,
            price_sources: [],
            processed_ms: 1711242786331,
          },
        ],
        position_type: "FLAT",
        position_uuid: "f6b40995-c685-4430-abd8-be53506f6c06",
        return_at_close: 0.9988883924006916,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 64470.35,
        close_ms: 1711250063624,
        current_return: 0.9951687558699465,
        initial_entry_price: 64470.35,
        is_closed_position: true,
        miner_hotkey: "5Gs2TsVu8YgnRQvtUXajFjjewJgEZVcEDuNVwg6MfkFJu3c8",
        net_leverage: 0.0,
        open_ms: 1711246481680,
        orders: [
          {
            leverage: 0.6,
            order_type: "LONG",
            order_uuid: "2f20e07b-6cd8-446c-93c0-2fb357e7dfc1",
            price: 64470.35,
            price_sources: [],
            processed_ms: 1711246481678,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "0080553b-8f55-4be7-ab0a-c3390f624ad2",
            price: 63951.23,
            price_sources: [],
            processed_ms: 1711250063624,
          },
        ],
        position_type: "FLAT",
        position_uuid: "85ed88d8-ae41-4665-aa17-efd275a21b6a",
        return_at_close: 0.9939745533629025,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 64156.92999999999,
        close_ms: 1711253719101,
        current_return: 0.9993863172692334,
        initial_entry_price: 64156.93,
        is_closed_position: true,
        miner_hotkey: "5Gs2TsVu8YgnRQvtUXajFjjewJgEZVcEDuNVwg6MfkFJu3c8",
        net_leverage: 0.0,
        open_ms: 1711250195156,
        orders: [
          {
            leverage: 0.6,
            order_type: "LONG",
            order_uuid: "96cd0ff5-7532-40c1-8fbe-f74b11e1ed66",
            price: 64156.93,
            price_sources: [],
            processed_ms: 1711250195154,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "cb1a5872-f0c3-43ad-b6fd-ba9506191e77",
            price: 64091.31,
            price_sources: [],
            processed_ms: 1711253719101,
          },
        ],
        position_type: "FLAT",
        position_uuid: "0f91dd93-ab28-4496-9ffd-de206f380d9b",
        return_at_close: 0.9981870536885104,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 64102.560000000005,
        close_ms: 1711257399743,
        current_return: 1.0009933768635761,
        initial_entry_price: 64102.56,
        is_closed_position: true,
        miner_hotkey: "5Gs2TsVu8YgnRQvtUXajFjjewJgEZVcEDuNVwg6MfkFJu3c8",
        net_leverage: 0.0,
        open_ms: 1711253805870,
        orders: [
          {
            leverage: 0.6,
            order_type: "LONG",
            order_uuid: "4760ab46-d63e-47c0-81aa-7bb2d6853ab6",
            price: 64102.56,
            price_sources: [],
            processed_ms: 1711253805869,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "caaa09f7-a359-4cf8-8568-278a5db40042",
            price: 64208.69,
            price_sources: [],
            processed_ms: 1711257399743,
          },
        ],
        position_type: "FLAT",
        position_uuid: "1dc4046c-8362-4db5-9137-32a618396e2f",
        return_at_close: 0.9997921848113399,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 64231.3,
        close_ms: 1711261059433,
        current_return: 1.0027436000828256,
        initial_entry_price: 64231.3,
        is_closed_position: true,
        miner_hotkey: "5Gs2TsVu8YgnRQvtUXajFjjewJgEZVcEDuNVwg6MfkFJu3c8",
        net_leverage: 0.0,
        open_ms: 1711257538803,
        orders: [
          {
            leverage: 1.25,
            order_type: "LONG",
            order_uuid: "ae271d76-d30e-4149-9002-ede290146dda",
            price: 64231.3,
            price_sources: [],
            processed_ms: 1711257538802,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "39c1ea11-0562-4ca2-949d-54a5c0ed232e",
            price: 64372.28,
            price_sources: [],
            processed_ms: 1711261059433,
          },
        ],
        position_type: "FLAT",
        position_uuid: "1c7bb08f-62e2-4672-bca7-6bc63fec3c4c",
        return_at_close: 1.0002367410826185,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 64339.81999999999,
        close_ms: 1711264721642,
        current_return: 0.9961660601475107,
        initial_entry_price: 64339.82,
        is_closed_position: true,
        miner_hotkey: "5Gs2TsVu8YgnRQvtUXajFjjewJgEZVcEDuNVwg6MfkFJu3c8",
        net_leverage: 0.0,
        open_ms: 1711261148344,
        orders: [
          {
            leverage: 1.25,
            order_type: "LONG",
            order_uuid: "a8de99f7-c103-4cb6-976c-be40f08d4792",
            price: 64339.82,
            price_sources: [],
            processed_ms: 1711261148342,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "d7d0622f-5f6a-4bec-a74e-61c211530f96",
            price: 64142.48,
            price_sources: [],
            processed_ms: 1711264721642,
          },
        ],
        position_type: "FLAT",
        position_uuid: "54616c8b-1059-4d06-a3d5-b8d9c4883064",
        return_at_close: 0.993675644997142,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 64115.56,
        close_ms: 1711268390489,
        current_return: 1.0125893776799268,
        initial_entry_price: 64115.56,
        is_closed_position: true,
        miner_hotkey: "5Gs2TsVu8YgnRQvtUXajFjjewJgEZVcEDuNVwg6MfkFJu3c8",
        net_leverage: 0.0,
        open_ms: 1711264809363,
        orders: [
          {
            leverage: 1.25,
            order_type: "LONG",
            order_uuid: "94582731-aa69-41ea-9cdc-b023c9154ffc",
            price: 64115.56,
            price_sources: [],
            processed_ms: 1711264809362,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "23f9eb63-8b3d-4ded-8345-af3fc3f70afd",
            price: 64761.3,
            price_sources: [],
            processed_ms: 1711268390489,
          },
        ],
        position_type: "FLAT",
        position_uuid: "8e6feb62-d1b0-4ca3-a77d-22d799660e10",
        return_at_close: 1.0100579042357272,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 64828.1,
        close_ms: 1711272045955,
        current_return: 1.0011553631835577,
        initial_entry_price: 64828.1,
        is_closed_position: true,
        miner_hotkey: "5Gs2TsVu8YgnRQvtUXajFjjewJgEZVcEDuNVwg6MfkFJu3c8",
        net_leverage: 0.0,
        open_ms: 1711268477555,
        orders: [
          {
            leverage: 1.25,
            order_type: "LONG",
            order_uuid: "cefe57f7-3bff-4153-a7dc-269e0c06cc1d",
            price: 64828.1,
            price_sources: [],
            processed_ms: 1711268477554,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "e70016fa-bdb3-4912-a0b3-731c0e5532c4",
            price: 64888.02,
            price_sources: [],
            processed_ms: 1711272045955,
          },
        ],
        position_type: "FLAT",
        position_uuid: "1cd80b6e-f396-43ba-a16c-df381df84a21",
        return_at_close: 0.9986524747755988,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 64877.89,
        close_ms: 1711275712701,
        current_return: 1.004360499393553,
        initial_entry_price: 64877.89,
        is_closed_position: true,
        miner_hotkey: "5Gs2TsVu8YgnRQvtUXajFjjewJgEZVcEDuNVwg6MfkFJu3c8",
        net_leverage: 0.0,
        open_ms: 1711272172491,
        orders: [
          {
            leverage: 1.25,
            order_type: "LONG",
            order_uuid: "5dc20230-e589-4e25-9e67-94d54a6e081b",
            price: 64877.89,
            price_sources: [],
            processed_ms: 1711272172490,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "33af2dc6-0153-42d7-a86e-de23380fce18",
            price: 65104.21,
            price_sources: [],
            processed_ms: 1711275712701,
          },
        ],
        position_type: "FLAT",
        position_uuid: "de45e3e0-30f1-431e-adec-8b422e9e36ac",
        return_at_close: 1.0018495981450692,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 64702.85000000002,
        close_ms: 1711313689759,
        current_return: 1.0281697931590839,
        initial_entry_price: 65127.83,
        is_closed_position: true,
        miner_hotkey: "5Gs2TsVu8YgnRQvtUXajFjjewJgEZVcEDuNVwg6MfkFJu3c8",
        net_leverage: 0.0,
        open_ms: 1711275801555,
        orders: [
          {
            leverage: 1.25,
            order_type: "LONG",
            order_uuid: "81035f3b-b280-4f4f-ba1e-38cada0b42ea",
            price: 65127.83,
            price_sources: [],
            processed_ms: 1711275801554,
          },
          {
            leverage: 1.25,
            order_type: "LONG",
            order_uuid: "a896563c-85d3-4685-95a7-dda82bb14f68",
            price: 65318.47,
            price_sources: [],
            processed_ms: 1711299547199,
          },
          {
            leverage: -1.25,
            order_type: "SHORT",
            order_uuid: "53098e76-a906-481a-bd7d-6a291d4b1365",
            price: 65035.28,
            price_sources: [],
            processed_ms: 1711301369843,
          },
          {
            leverage: 1.25,
            order_type: "LONG",
            order_uuid: "ec6faf2c-b73c-4056-8b01-5d28e8e4cf09",
            price: 65386.41,
            price_sources: [],
            processed_ms: 1711303456479,
          },
          {
            leverage: -1.25,
            order_type: "SHORT",
            order_uuid: "be043d8b-bca5-41be-860f-87efad340022",
            price: 65600.5,
            price_sources: [],
            processed_ms: 1711304388853,
          },
          {
            leverage: 1.25,
            order_type: "LONG",
            order_uuid: "d1ac6af5-4d8c-4a83-9414-25fe6abcfb62",
            price: 65870.21,
            price_sources: [],
            processed_ms: 1711307176017,
          },
          {
            leverage: -1.25,
            order_type: "SHORT",
            order_uuid: "86ab1307-bce4-4988-b5be-adca24172e5a",
            price: 65864.72,
            price_sources: [],
            processed_ms: 1711308101166,
          },
          {
            leverage: 1.25,
            order_type: "LONG",
            order_uuid: "d99489c2-5cab-48e3-b7b6-a1acc91109cc",
            price: 65773.9,
            price_sources: [],
            processed_ms: 1711310890708,
          },
          {
            leverage: -1.25,
            order_type: "SHORT",
            order_uuid: "1d692099-0845-462f-a645-16ba99a213d9",
            price: 66273.47,
            price_sources: [],
            processed_ms: 1711311822371,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "c1ab03ca-a70e-43fa-88bf-9a196d4b22ca",
            price: 66170.56,
            price_sources: [],
            processed_ms: 1711313689759,
          },
        ],
        position_type: "FLAT",
        position_uuid: "031199af-6312-4a01-8005-283f68385c55",
        return_at_close: 1.0230289441932885,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 66220.75,
        close_ms: 1711316484645,
        current_return: 1.0022721352446173,
        initial_entry_price: 66220.75,
        is_closed_position: true,
        miner_hotkey: "5Gs2TsVu8YgnRQvtUXajFjjewJgEZVcEDuNVwg6MfkFJu3c8",
        net_leverage: 0.0,
        open_ms: 1711315552261,
        orders: [
          {
            leverage: 1.25,
            order_type: "LONG",
            order_uuid: "d2ccaf7a-de98-4f37-acff-0b209ad79439",
            price: 66220.75,
            price_sources: [],
            processed_ms: 1711315552260,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "daeff92b-4e61-4398-8162-23b25a05a787",
            price: 66341.12,
            price_sources: [],
            processed_ms: 1711316484645,
          },
        ],
        position_type: "FLAT",
        position_uuid: "0c64edca-eeb5-4e08-ad32-707e62cea55b",
        return_at_close: 0.9997664549065058,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 66339.51,
        close_ms: 1711318370926,
        current_return: 1.009076416150798,
        initial_entry_price: 66339.51,
        is_closed_position: true,
        miner_hotkey: "5Gs2TsVu8YgnRQvtUXajFjjewJgEZVcEDuNVwg6MfkFJu3c8",
        net_leverage: 0.0,
        open_ms: 1711317411550,
        orders: [
          {
            leverage: 1.25,
            order_type: "LONG",
            order_uuid: "d40c25a2-0d91-454f-928a-c264f8417da6",
            price: 66339.51,
            price_sources: [],
            processed_ms: 1711317411548,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "4fd28981-1215-42a3-933f-2bcb2e7beb4b",
            price: 66821.21,
            price_sources: [],
            processed_ms: 1711318370926,
          },
        ],
        position_type: "FLAT",
        position_uuid: "ed054024-8fbb-46a5-8793-54cafb30aadf",
        return_at_close: 1.006553725110421,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 341516.7250000069,
        close_ms: 1711333213939,
        current_return: 1.0081664598226696,
        initial_entry_price: 67345.44,
        is_closed_position: true,
        miner_hotkey: "5Gs2TsVu8YgnRQvtUXajFjjewJgEZVcEDuNVwg6MfkFJu3c8",
        net_leverage: 0.0,
        open_ms: 1711323021596,
        orders: [
          {
            leverage: -1.25,
            order_type: "SHORT",
            order_uuid: "8481f6e2-edab-406d-b82f-55dcc2d3e384",
            price: 67345.44,
            price_sources: [],
            processed_ms: 1711323021595,
          },
          {
            leverage: 0.125,
            order_type: "LONG",
            order_uuid: "718a53c9-3f33-478b-a4ae-1aad6b644070",
            price: 67259.45,
            price_sources: [],
            processed_ms: 1711323950822,
          },
          {
            leverage: 0.125,
            order_type: "LONG",
            order_uuid: "5d46f14c-7882-4822-a223-215ec5c7fa57",
            price: 67297.56,
            price_sources: [],
            processed_ms: 1711324888226,
          },
          {
            leverage: 0.5,
            order_type: "LONG",
            order_uuid: "d9587a46-70a3-45c2-8005-1906d0a32453",
            price: 66886.16,
            price_sources: [],
            processed_ms: 1711325811560,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "b4e45694-ca81-41cf-a05d-9abfe8b529cc",
            price: 66862.47,
            price_sources: [],
            processed_ms: 1711326750652,
          },
          {
            leverage: 0.2,
            order_type: "LONG",
            order_uuid: "edfec4c1-42ab-458d-b9c2-be9aa599b0d0",
            price: 66602.55,
            price_sources: [],
            processed_ms: 1711327681856,
          },
          {
            leverage: 0.039999999999999994,
            order_type: "LONG",
            order_uuid: "36a61375-5b87-4636-ae94-be7972304cc4",
            price: 66680.37,
            price_sources: [],
            processed_ms: 1711328619593,
          },
          {
            leverage: 0.007999999999999997,
            order_type: "LONG",
            order_uuid: "1e5b48db-a7af-489f-b2db-dc139032d221",
            price: 66589.75,
            price_sources: [],
            processed_ms: 1711331392958,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "3d7cce5c-4d3a-466d-aa46-f19234594fa9",
            price: 66529.81,
            price_sources: [],
            processed_ms: 1711333213939,
          },
        ],
        position_type: "FLAT",
        position_uuid: "1ee47a8d-b8bb-4a8e-aaf8-6e054dfc223a",
        return_at_close: 1.005646043673113,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: -121343.36333332653,
        close_ms: 1711353381287,
        current_return: 0.9957855082012839,
        initial_entry_price: 66976.71,
        is_closed_position: true,
        miner_hotkey: "5Gs2TsVu8YgnRQvtUXajFjjewJgEZVcEDuNVwg6MfkFJu3c8",
        net_leverage: 0.0,
        open_ms: 1711338711403,
        orders: [
          {
            leverage: -1.25,
            order_type: "SHORT",
            order_uuid: "7b757363-60df-4f39-bde1-98f7f2f7d7fe",
            price: 66976.71,
            price_sources: [],
            processed_ms: 1711338711401,
          },
          {
            leverage: 0.625,
            order_type: "LONG",
            order_uuid: "dade5681-53c4-4d6f-84eb-42fb423d92d6",
            price: 67044.93,
            price_sources: [],
            processed_ms: 1711340558908,
          },
          {
            leverage: 0.125,
            order_type: "LONG",
            order_uuid: "674b4ef5-38ce-4766-8e06-8f320966597b",
            price: 67476.67,
            price_sources: [],
            processed_ms: 1711342381594,
          },
          {
            leverage: 0.125,
            order_type: "LONG",
            order_uuid: "7549a228-af09-4f1a-8c21-99c457a81f73",
            price: 67435.8,
            price_sources: [],
            processed_ms: 1711344213086,
          },
          {
            leverage: 0.1875,
            order_type: "LONG",
            order_uuid: "0e07082a-842b-4386-8f89-1b90af79b787",
            price: 67399.55,
            price_sources: [],
            processed_ms: 1711346050655,
          },
          {
            leverage: 0.15,
            order_type: "LONG",
            order_uuid: "f95ab677-71f7-48cd-96cb-4326be74a3e4",
            price: 67210.96,
            price_sources: [],
            processed_ms: 1711347878024,
          },
          {
            leverage: 0.030000000000000006,
            order_type: "LONG",
            order_uuid: "eb46c26c-698a-4ac5-bb50-99f6009b6277",
            price: 67137.73,
            price_sources: [],
            processed_ms: 1711349711367,
          },
          {
            leverage: 0.006,
            order_type: "LONG",
            order_uuid: "8c0be5c8-4000-492e-9a97-f7a6a573fd45",
            price: 67095.17,
            price_sources: [],
            processed_ms: 1711351553736,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "b95892f9-157b-44ff-9614-a626b3b76aff",
            price: 66838.5,
            price_sources: [],
            processed_ms: 1711353381287,
          },
        ],
        position_type: "FLAT",
        position_uuid: "20510f7e-fb04-46f3-854e-2a270a9d4501",
        return_at_close: 0.9932960444307807,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 67047.4,
        close_ms: 1711362541200,
        current_return: 1.0032290588449368,
        initial_entry_price: 67047.4,
        is_closed_position: true,
        miner_hotkey: "5Gs2TsVu8YgnRQvtUXajFjjewJgEZVcEDuNVwg6MfkFJu3c8",
        net_leverage: 0.0,
        open_ms: 1711355210179,
        orders: [
          {
            leverage: 1.25,
            order_type: "LONG",
            order_uuid: "e4587d68-e108-4ddc-a1cf-9870ce6b131a",
            price: 67047.4,
            price_sources: [],
            processed_ms: 1711355210177,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "1049f932-0dd0-4ada-aa69-be9cd5177775",
            price: 67220.6,
            price_sources: [],
            processed_ms: 1711362541200,
          },
        ],
        position_type: "FLAT",
        position_uuid: "1ba04c30-c5c6-4e11-97f9-74764b546292",
        return_at_close: 1.0007209861978246,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 63484.08392426863,
        close_ms: 1711395162177,
        current_return: 1.030490204200759,
        initial_entry_price: 67029.96,
        is_closed_position: true,
        miner_hotkey: "5Gs2TsVu8YgnRQvtUXajFjjewJgEZVcEDuNVwg6MfkFJu3c8",
        net_leverage: 0.0,
        open_ms: 1711364366137,
        orders: [
          {
            leverage: 1.25,
            order_type: "LONG",
            order_uuid: "c396410f-60e3-49d6-a01b-dc9aaa4104fa",
            price: 67029.96,
            price_sources: [],
            processed_ms: 1711364366135,
          },
          {
            leverage: -0.625,
            order_type: "SHORT",
            order_uuid: "644b6a4a-79f1-4cb0-a30d-5b427564127e",
            price: 67078.4,
            price_sources: [],
            processed_ms: 1711368894173,
          },
          {
            leverage: -0.3125,
            order_type: "SHORT",
            order_uuid: "552195ab-2bfb-46d2-a1dd-b346ab0dc140",
            price: 67092.73,
            price_sources: [],
            processed_ms: 1711370715886,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "ef7e131c-8f8d-4a7b-8be1-996b3dea0fd6",
            price: 66982.9,
            price_sources: [],
            processed_ms: 1711372552098,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "076864da-fe24-40c0-9f74-494f3163e0dd",
            price: 67204.24,
            price_sources: [],
            processed_ms: 1711374375502,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "b13343be-3eb2-4302-8d97-d4f4a8e7efdd",
            price: 68578.47,
            price_sources: [],
            processed_ms: 1711375914596,
          },
          {
            leverage: -0.53125,
            order_type: "SHORT",
            order_uuid: "44b71b6a-35f8-4da6-a202-2a1246766c7e",
            price: 68868.54,
            price_sources: [],
            processed_ms: 1711376425896,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "036edf3d-029f-4daf-9f06-6cb791279bcb",
            price: 69210.38,
            price_sources: [],
            processed_ms: 1711376747276,
          },
          {
            leverage: -0.625,
            order_type: "SHORT",
            order_uuid: "88f0eb54-8c44-40cc-9857-475b6a8871b1",
            price: 69095.56,
            price_sources: [],
            processed_ms: 1711377896484,
          },
          {
            leverage: -0.078125,
            order_type: "SHORT",
            order_uuid: "adb1ba32-3e29-46e9-ad95-8c6ca81ebbb3",
            price: 69074.0,
            price_sources: [],
            processed_ms: 1711378177922,
          },
          {
            leverage: -0.0390625,
            order_type: "SHORT",
            order_uuid: "6b82cae5-7038-4abb-bdb0-230386e22ed5",
            price: 69531.86,
            price_sources: [],
            processed_ms: 1711380696378,
          },
          {
            leverage: -0.01953125,
            order_type: "SHORT",
            order_uuid: "64950049-2770-46e8-b498-7dfb696ccbfe",
            price: 69840.64,
            price_sources: [],
            processed_ms: 1711382506887,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "75f8be0b-9691-474e-b0ad-0b77a016992a",
            price: 69653.49,
            price_sources: [],
            processed_ms: 1711384323064,
          },
          {
            leverage: -0.134765625,
            order_type: "SHORT",
            order_uuid: "4310651f-bf1c-434e-a76b-f436c68e4c53",
            price: 70289.18,
            price_sources: [],
            processed_ms: 1711386143482,
          },
          {
            leverage: -0.0673828125,
            order_type: "SHORT",
            order_uuid: "634a1f5e-6f50-4f71-989d-e2887053af98",
            price: 70729.72,
            price_sources: [],
            processed_ms: 1711387955890,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "d458d6c5-193d-4807-b651-c812e58b6d44",
            price: 70606.1,
            price_sources: [],
            processed_ms: 1711389770307,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "a5399514-ba1f-404d-8de2-1a50251c31ad",
            price: 70572.58,
            price_sources: [],
            processed_ms: 1711391613098,
          },
          {
            leverage: -0.28369140625,
            order_type: "SHORT",
            order_uuid: "cd0b15ae-9ab1-4845-ac7a-947c51cec77e",
            price: 70861.8,
            price_sources: [],
            processed_ms: 1711393343936,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "e8ed1b5b-707e-43d0-94ba-e7d60dd7d947",
            price: 70688.24,
            price_sources: [],
            processed_ms: 1711395162177,
          },
        ],
        position_type: "FLAT",
        position_uuid: "391b2ce0-27e3-4dd7-90cb-78d40593fbe1",
        return_at_close: 1.0279139786902574,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 67848.28000000007,
        close_ms: 1711420612785,
        current_return: 1.0206285253597387,
        initial_entry_price: 70019.45,
        is_closed_position: true,
        miner_hotkey: "5Gs2TsVu8YgnRQvtUXajFjjewJgEZVcEDuNVwg6MfkFJu3c8",
        net_leverage: 0.0,
        open_ms: 1711402454718,
        orders: [
          {
            leverage: 3.0,
            order_type: "LONG",
            order_uuid: "d4b418d7-b381-4e34-84ae-5c19fdb01d47",
            price: 70019.45,
            price_sources: [],
            processed_ms: 1711402454716,
          },
          {
            leverage: 0.8999999999999999,
            order_type: "LONG",
            order_uuid: "9234dc40-acd5-48cd-a576-e7bc6d9e6d7d",
            price: 70021.84,
            price_sources: [],
            processed_ms: 1711404246493,
          },
          {
            leverage: 0.8999999999999999,
            order_type: "LONG",
            order_uuid: "cb7c5abf-a421-48b8-bf75-af39f62a1497",
            price: 70415.95,
            price_sources: [],
            processed_ms: 1711406057963,
          },
          {
            leverage: -2.4,
            order_type: "SHORT",
            order_uuid: "651460fc-1be3-4581-a45a-c04ea7ef2a8f",
            price: 70309.85,
            price_sources: [],
            processed_ms: 1711407899842,
          },
          {
            leverage: 0.8999999999999999,
            order_type: "LONG",
            order_uuid: "7f2a399c-1c16-444f-ae09-690d378619e5",
            price: 70129.39,
            price_sources: [],
            processed_ms: 1711409695970,
          },
          {
            leverage: 0.8999999999999995,
            order_type: "LONG",
            order_uuid: "faa2bc55-a5e0-44ec-9732-10c5cdfc4384",
            price: 69789.99,
            price_sources: [],
            processed_ms: 1711411515377,
          },
          {
            leverage: 0.9000000000000004,
            order_type: "LONG",
            order_uuid: "981d3f69-df81-4d29-be1f-fa4c16044ac8",
            price: 70184.55,
            price_sources: [],
            processed_ms: 1711413350234,
          },
          {
            leverage: 0.9000000000000004,
            order_type: "LONG",
            order_uuid: "9d40d9fb-5197-4117-a747-b2553661af4f",
            price: 69961.36,
            price_sources: [],
            processed_ms: 1711415149846,
          },
          {
            leverage: -4.8,
            order_type: "SHORT",
            order_uuid: "cd35c2e1-aff9-42c5-be66-9cd4c6a68153",
            price: 70207.4,
            price_sources: [],
            processed_ms: 1711416975000,
          },
          {
            leverage: -0.6000000000000001,
            order_type: "SHORT",
            order_uuid: "108f0968-1737-4b4d-b328-1061fde8cfbb",
            price: 70104.99,
            price_sources: [],
            processed_ms: 1711418834072,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "d8679a23-2793-4dc9-b85d-e8ef52b4c7d2",
            price: 70255.61,
            price_sources: [],
            processed_ms: 1711420612785,
          },
        ],
        position_type: "FLAT",
        position_uuid: "07e5116e-bf51-4943-b176-5fc423db5b3d",
        return_at_close: 1.0083809830554218,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 68690.07999999997,
        close_ms: 1711429692646,
        current_return: 0.9923960645420111,
        initial_entry_price: 70368.43,
        is_closed_position: true,
        miner_hotkey: "5Gs2TsVu8YgnRQvtUXajFjjewJgEZVcEDuNVwg6MfkFJu3c8",
        net_leverage: 0.0,
        open_ms: 1711422420981,
        orders: [
          {
            leverage: -3.0,
            order_type: "SHORT",
            order_uuid: "e0eab8ef-e35f-4e60-b6d3-cf7aa31944d5",
            price: 70368.43,
            price_sources: [],
            processed_ms: 1711422420978,
          },
          {
            leverage: 0.8999999999999999,
            order_type: "LONG",
            order_uuid: "e839f1b0-3415-4be3-8083-f054f49f1204",
            price: 70424.87,
            price_sources: [],
            processed_ms: 1711424259829,
          },
          {
            leverage: 0.8999999999999999,
            order_type: "LONG",
            order_uuid: "5f9f1c0a-11c3-482b-a17a-7c78a4533675",
            price: 70675.45,
            price_sources: [],
            processed_ms: 1711426056030,
          },
          {
            leverage: 0.8999999999999999,
            order_type: "LONG",
            order_uuid: "4665e71c-3a6a-421f-bec3-46e1d16929d4",
            price: 70564.42,
            price_sources: [],
            processed_ms: 1711427874887,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "b9547847-eb35-40be-8473-445c9cce2ef9",
            price: 70473.67,
            price_sources: [],
            processed_ms: 1711429692646,
          },
        ],
        position_type: "FLAT",
        position_uuid: "d8f451ed-59f7-4b4f-8a19-e5d8e3df869f",
        return_at_close: 0.9864416881547591,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 66549.52999999993,
        close_ms: 1711440601943,
        current_return: 0.9821952736574572,
        initial_entry_price: 70402.43,
        is_closed_position: true,
        miner_hotkey: "5Gs2TsVu8YgnRQvtUXajFjjewJgEZVcEDuNVwg6MfkFJu3c8",
        net_leverage: 0.0,
        open_ms: 1711433329166,
        orders: [
          {
            leverage: -3.0,
            order_type: "SHORT",
            order_uuid: "2dff0f7a-0f23-4541-8b83-34cef02169f2",
            price: 70402.43,
            price_sources: [],
            processed_ms: 1711433329163,
          },
          {
            leverage: 0.8999999999999999,
            order_type: "LONG",
            order_uuid: "6ac7caa6-386e-4473-8ada-2075c91575d3",
            price: 70500.1,
            price_sources: [],
            processed_ms: 1711435133878,
          },
          {
            leverage: 0.8999999999999999,
            order_type: "LONG",
            order_uuid: "0b00c558-93b7-4831-b13a-6d78b2b3bab8",
            price: 70806.17,
            price_sources: [],
            processed_ms: 1711436968295,
          },
          {
            leverage: 0.8999999999999999,
            order_type: "LONG",
            order_uuid: "3d1410ac-b5be-4558-acab-d4c879fe4571",
            price: 71185.32,
            price_sources: [],
            processed_ms: 1711438805681,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "bc350f76-d6df-4314-86be-59e45d8827a9",
            price: 70727.85,
            price_sources: [],
            processed_ms: 1711440601943,
          },
        ],
        position_type: "FLAT",
        position_uuid: "7e76e92c-0e54-4c3c-9515-4c6bccc2c53e",
        return_at_close: 0.9763021020155125,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 70460.30508287293,
        close_ms: 1711463244121,
        current_return: 0.944554258872168,
        initial_entry_price: 70521.37,
        is_closed_position: true,
        miner_hotkey: "5Gs2TsVu8YgnRQvtUXajFjjewJgEZVcEDuNVwg6MfkFJu3c8",
        net_leverage: 0.0,
        open_ms: 1711442417062,
        orders: [
          {
            leverage: 3.0,
            order_type: "LONG",
            order_uuid: "68cf9e92-74ee-4269-9e19-fab0395510b1",
            price: 70521.37,
            price_sources: [],
            processed_ms: 1711442417058,
          },
          {
            leverage: 0.8999999999999999,
            order_type: "LONG",
            order_uuid: "71d29fe5-86db-49a8-94dd-fbe7dac72803",
            price: 70674.64,
            price_sources: [],
            processed_ms: 1711444230037,
          },
          {
            leverage: -1.95,
            order_type: "SHORT",
            order_uuid: "28340de3-02a0-4a0e-a576-50e4a965d176",
            price: 70989.82,
            price_sources: [],
            processed_ms: 1711446047987,
          },
          {
            leverage: -0.975,
            order_type: "SHORT",
            order_uuid: "f6a51af6-6176-40c1-b50b-fc7e717479e1",
            price: 71096.18,
            price_sources: [],
            processed_ms: 1711447858210,
          },
          {
            leverage: -0.4875,
            order_type: "SHORT",
            order_uuid: "469c3acd-d218-4bcd-86c3-eb579feb4098",
            price: 70950.02,
            price_sources: [],
            processed_ms: 1711449680415,
          },
          {
            leverage: 0.8999999999999999,
            order_type: "LONG",
            order_uuid: "c180150c-281b-4939-9c2f-ffbdf9413926",
            price: 70767.67,
            price_sources: [],
            processed_ms: 1711451494142,
          },
          {
            leverage: 0.8999999999999997,
            order_type: "LONG",
            order_uuid: "4ddc1a34-99b9-492e-88fe-1cb35339a305",
            price: 70694.91,
            price_sources: [],
            processed_ms: 1711453306182,
          },
          {
            leverage: 0.8999999999999999,
            order_type: "LONG",
            order_uuid: "abe7f8f6-35c2-4abb-afff-c721d427dc67",
            price: 70699.86,
            price_sources: [],
            processed_ms: 1711455128830,
          },
          {
            leverage: 0.8999999999999999,
            order_type: "LONG",
            order_uuid: "58e477e5-dbb1-42b6-bd5f-30d8a2f40c85",
            price: 71074.3,
            price_sources: [],
            processed_ms: 1711456949287,
          },
          {
            leverage: 0.8999999999999995,
            order_type: "LONG",
            order_uuid: "a7e4a6ad-3a82-427e-9714-2489b31dec08",
            price: 70909.08,
            price_sources: [],
            processed_ms: 1711458765061,
          },
          {
            leverage: 0.9000000000000004,
            order_type: "LONG",
            order_uuid: "9abc2ab8-79f4-46de-8c42-3c636513f320",
            price: 70353.17,
            price_sources: [],
            processed_ms: 1711460593000,
          },
          {
            leverage: 0.9000000000000004,
            order_type: "LONG",
            order_uuid: "16da51d8-27b9-4a0b-a412-ecb062fd66fa",
            price: 70406.67,
            price_sources: [],
            processed_ms: 1711462420815,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "1cf77604-6b09-45f9-8db4-8f69e4e7bc26",
            price: 69884.23,
            price_sources: [],
            processed_ms: 1711463244121,
          },
        ],
        position_type: "FLAT",
        position_uuid: "52246680-5a39-4b4a-b2f5-4d20e818b8c2",
        return_at_close: 0.9317319348079783,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 69868.31727272728,
        close_ms: 1711471604738,
        current_return: 0.973858605509621,
        initial_entry_price: 69762.69,
        is_closed_position: true,
        miner_hotkey: "5Gs2TsVu8YgnRQvtUXajFjjewJgEZVcEDuNVwg6MfkFJu3c8",
        net_leverage: 0.0,
        open_ms: 1711464211844,
        orders: [
          {
            leverage: 3.0,
            order_type: "LONG",
            order_uuid: "ce0bf013-1b5d-4280-adbe-2a0422d10274",
            price: 69762.69,
            price_sources: [],
            processed_ms: 1711464211842,
          },
          {
            leverage: 0.8999999999999999,
            order_type: "LONG",
            order_uuid: "e06102bd-ef14-4450-86c6-10e7958aa926",
            price: 70101.47,
            price_sources: [],
            processed_ms: 1711466025004,
          },
          {
            leverage: 0.8999999999999999,
            order_type: "LONG",
            order_uuid: "708c38c7-f90c-4f8e-b1e4-5c7e868dc9ef",
            price: 70185.96,
            price_sources: [],
            processed_ms: 1711467841045,
          },
          {
            leverage: 0.8999999999999995,
            order_type: "LONG",
            order_uuid: "31da0ec9-2ac4-4571-94ce-f10c441fa41f",
            price: 70078.49,
            price_sources: [],
            processed_ms: 1711469661941,
          },
          {
            leverage: 0.9000000000000004,
            order_type: "LONG",
            order_uuid: "52d60a45-e843-485c-bc92-bec7afad60b0",
            price: 69459.44,
            price_sources: [],
            processed_ms: 1711471475985,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "e36b42e7-bb66-4fbc-9fd0-9bca4769f139",
            price: 69592.0,
            price_sources: [],
            processed_ms: 1711471604738,
          },
        ],
        position_type: "FLAT",
        position_uuid: "072fba51-135d-4a90-9842-e0e518da3fe9",
        return_at_close: 0.9610036719168941,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
    ],
    thirty_day_returns: 1.0,
  },
  "5GsNcT3ENpxQdNnM2LTSC5beBneEddZjpUhNVCcrdUbicp1w": {
    all_time_returns: 1.030600848253962,
    n_positions: 25,
    percentage_profitable: 0.68,
    positions: [
      {
        average_entry_price: 158.172,
        close_ms: 1718353556123,
        current_return: 1.0016880358091191,
        initial_entry_price: 158.172,
        is_closed_position: true,
        miner_hotkey: "5GsNcT3ENpxQdNnM2LTSC5beBneEddZjpUhNVCcrdUbicp1w",
        net_leverage: 0.0,
        open_ms: 1718346500791,
        orders: [
          {
            leverage: -0.5,
            order_type: "SHORT",
            order_uuid: "715e258a-a32f-4131-8424-e3ab7575cb60",
            price: 158.172,
            price_sources: [],
            processed_ms: 1718346500770,
          },
          {
            leverage: 0.5,
            order_type: "FLAT",
            order_uuid: "ab0ac7cf-fa53-4d51-8aef-d3508190c663",
            price: 157.638,
            price_sources: [],
            processed_ms: 1718353556123,
          },
        ],
        position_type: "FLAT",
        position_uuid: "d237902e-6b50-4d73-8abf-8241187eb94a",
        return_at_close: 1.001652976727866,
        trade_pair: ["USDJPY", "USD/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 115.049,
        close_ms: 1718353840031,
        current_return: 1.0017970603829673,
        initial_entry_price: 115.049,
        is_closed_position: true,
        miner_hotkey: "5GsNcT3ENpxQdNnM2LTSC5beBneEddZjpUhNVCcrdUbicp1w",
        net_leverage: 0.0,
        open_ms: 1718346463240,
        orders: [
          {
            leverage: -0.5,
            order_type: "SHORT",
            order_uuid: "9754c6e3-1ca8-4557-b562-c9885d826ea4",
            price: 115.049,
            price_sources: [],
            processed_ms: 1718346463232,
          },
          {
            leverage: 0.5,
            order_type: "FLAT",
            order_uuid: "8460f524-1762-41b4-90be-245a99ffb85d",
            price: 114.63550000000001,
            price_sources: [],
            processed_ms: 1718353840031,
          },
        ],
        position_type: "FLAT",
        position_uuid: "0f5231e8-da97-4b5a-b1b4-a949347bbd29",
        return_at_close: 1.001761997485854,
        trade_pair: ["CADJPY", "CAD/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 96.9345,
        close_ms: 1718354063392,
        current_return: 1.002120912610164,
        initial_entry_price: 97.128,
        is_closed_position: true,
        miner_hotkey: "5GsNcT3ENpxQdNnM2LTSC5beBneEddZjpUhNVCcrdUbicp1w",
        net_leverage: 0.0,
        open_ms: 1718346417194,
        orders: [
          {
            leverage: -0.5,
            order_type: "SHORT",
            order_uuid: "e5e353f6-494c-48df-afef-e4b2270dcc50",
            price: 97.128,
            price_sources: [],
            processed_ms: 1718346417159,
          },
          {
            leverage: -0.5,
            order_type: "SHORT",
            order_uuid: "f91ed2c6-a52a-45ff-b6a3-846b0dfd9625",
            price: 96.741,
            price_sources: [],
            processed_ms: 1718353648856,
          },
          {
            leverage: 0.5,
            order_type: "FLAT",
            order_uuid: "3ab5abf5-1db2-40c2-a1c8-bb0e4fcca26c",
            price: 96.7285,
            price_sources: [],
            processed_ms: 1718354063392,
          },
        ],
        position_type: "FLAT",
        position_uuid: "adb8f205-dfa1-4a81-b8a2-0ce35eccedae",
        return_at_close: 1.0020507641462812,
        trade_pair: ["NZDJPY", "NZD/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 169.66,
        close_ms: 1718354099106,
        current_return: 1.0026906754685843,
        initial_entry_price: 169.66,
        is_closed_position: true,
        miner_hotkey: "5GsNcT3ENpxQdNnM2LTSC5beBneEddZjpUhNVCcrdUbicp1w",
        net_leverage: 0.0,
        open_ms: 1718346530453,
        orders: [
          {
            leverage: -0.5,
            order_type: "SHORT",
            order_uuid: "3517f033-f32a-4d04-941f-d2c4105b7ec1",
            price: 169.66,
            price_sources: [],
            processed_ms: 1718346530446,
          },
          {
            leverage: 0.5,
            order_type: "FLAT",
            order_uuid: "e5763d3e-ce90-46d4-8319-7e064a3e7cda",
            price: 168.747,
            price_sources: [],
            processed_ms: 1718354099106,
          },
        ],
        position_type: "FLAT",
        position_uuid: "b86bec19-b836-4a83-8dd8-ad7a5a80e033",
        return_at_close: 1.002655581294943,
        trade_pair: ["EURJPY", "EUR/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 168.468,
        close_ms: 1718356957104,
        current_return: 1.0008325023149798,
        initial_entry_price: 168.468,
        is_closed_position: true,
        miner_hotkey: "5GsNcT3ENpxQdNnM2LTSC5beBneEddZjpUhNVCcrdUbicp1w",
        net_leverage: 0.0,
        open_ms: 1718354570579,
        orders: [
          {
            leverage: -0.5,
            order_type: "SHORT",
            order_uuid: "39be915f-93fb-4df4-b660-67e5efbc4349",
            price: 168.468,
            price_sources: [],
            processed_ms: 1718354570571,
          },
          {
            leverage: 0.5,
            order_type: "FLAT",
            order_uuid: "83b525d5-af63-482b-a346-09ccabc9d843",
            price: 168.1875,
            price_sources: [],
            processed_ms: 1718356957104,
          },
        ],
        position_type: "FLAT",
        position_uuid: "b2d6d9c4-0256-41ca-83df-75a95fa0c7c1",
        return_at_close: 1.0007974731773988,
        trade_pair: ["EURJPY", "EUR/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 104.17600000000002,
        close_ms: 1718357044475,
        current_return: 1.000263743430391,
        initial_entry_price: 104.268,
        is_closed_position: true,
        miner_hotkey: "5GsNcT3ENpxQdNnM2LTSC5beBneEddZjpUhNVCcrdUbicp1w",
        net_leverage: 0.0,
        open_ms: 1718354141654,
        orders: [
          {
            leverage: -0.1,
            order_type: "SHORT",
            order_uuid: "6a8082cb-7065-4c49-961d-50f138edddf5",
            price: 104.268,
            price_sources: [],
            processed_ms: 1718354141579,
          },
          {
            leverage: -0.4,
            order_type: "SHORT",
            order_uuid: "3eb716b2-b130-4971-8552-102d2beb8c24",
            price: 104.153,
            price_sources: [],
            processed_ms: 1718354540165,
          },
          {
            leverage: 0.5,
            order_type: "FLAT",
            order_uuid: "35b0f5b7-65fe-46ee-b5d6-fda7692ecdf1",
            price: 104.121,
            price_sources: [],
            processed_ms: 1718357044475,
          },
        ],
        position_type: "FLAT",
        position_uuid: "f7be364f-ccc5-449e-8f01-6f73ddc64ddb",
        return_at_close: 1.0002287341993708,
        trade_pair: ["AUDJPY", "AUD/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 114.49,
        close_ms: 1718357082079,
        current_return: 1.000275133199406,
        initial_entry_price: 114.49,
        is_closed_position: true,
        miner_hotkey: "5GsNcT3ENpxQdNnM2LTSC5beBneEddZjpUhNVCcrdUbicp1w",
        net_leverage: 0.0,
        open_ms: 1718354606757,
        orders: [
          {
            leverage: -0.5,
            order_type: "SHORT",
            order_uuid: "0210493d-ff3f-4618-9691-7cb3db40aef3",
            price: 114.49,
            price_sources: [],
            processed_ms: 1718354606718,
          },
          {
            leverage: 0.5,
            order_type: "FLAT",
            order_uuid: "8b837e82-865c-409e-9bd6-f6c747918045",
            price: 114.427,
            price_sources: [],
            processed_ms: 1718357082079,
          },
        ],
        position_type: "FLAT",
        position_uuid: "2f4a4b63-1b7a-4a16-8653-8a456afde446",
        return_at_close: 1.000240123569744,
        trade_pair: ["CADJPY", "CAD/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 168.1365,
        close_ms: 1718358434895,
        current_return: 1.002250953012471,
        initial_entry_price: 168.151,
        is_closed_position: true,
        miner_hotkey: "5GsNcT3ENpxQdNnM2LTSC5beBneEddZjpUhNVCcrdUbicp1w",
        net_leverage: 0.0,
        open_ms: 1718357394449,
        orders: [
          {
            leverage: -0.5,
            order_type: "SHORT",
            order_uuid: "9e084a00-e32c-4abf-bad8-4146718fd2fd",
            price: 168.151,
            price_sources: [],
            processed_ms: 1718357394371,
          },
          {
            leverage: -0.5,
            order_type: "SHORT",
            order_uuid: "c2fd3d34-ad6c-420a-97f0-1034adcda60c",
            price: 168.122,
            price_sources: [],
            processed_ms: 1718357449364,
          },
          {
            leverage: 2.0,
            order_type: "FLAT",
            order_uuid: "70589b0b-dee9-45ab-bbd9-bf8cdebc0bfc",
            price: 167.758,
            price_sources: [],
            processed_ms: 1718358434895,
          },
        ],
        position_type: "FLAT",
        position_uuid: "c33e1453-30ff-41d5-8e40-0e1aa12b2303",
        return_at_close: 1.0021807954457602,
        trade_pair: ["EURJPY", "EUR/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 96.5635,
        close_ms: 1718360832533,
        current_return: 1.0015858151037893,
        initial_entry_price: 96.638,
        is_closed_position: true,
        miner_hotkey: "5GsNcT3ENpxQdNnM2LTSC5beBneEddZjpUhNVCcrdUbicp1w",
        net_leverage: 0.0,
        open_ms: 1718354644127,
        orders: [
          {
            leverage: -0.5,
            order_type: "SHORT",
            order_uuid: "1d43a308-2f15-432f-95a0-ad3c576ac233",
            price: 96.638,
            price_sources: [],
            processed_ms: 1718354644105,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "f8286066-2eef-4da9-8eda-eccb75080836",
            price: 96.572,
            price_sources: [],
            processed_ms: 1718357190526,
          },
          {
            leverage: -0.25,
            order_type: "SHORT",
            order_uuid: "0337b8da-c24c-4fa9-b3d4-0b859f4fe916",
            price: 96.423,
            price_sources: [],
            processed_ms: 1718357480471,
          },
          {
            leverage: 0.01,
            order_type: "FLAT",
            order_uuid: "571d250e-b32c-4015-b18d-480c8571bba7",
            price: 96.257,
            price_sources: [],
            processed_ms: 1718360832533,
          },
        ],
        position_type: "FLAT",
        position_uuid: "f0666549-87f1-437d-8d3e-367f728dee93",
        return_at_close: 1.0015332318484964,
        trade_pair: ["NZDJPY", "NZD/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 1.0693,
        close_ms: 1718367810882,
        current_return: 1.000231459833536,
        initial_entry_price: 1.0693,
        is_closed_position: true,
        miner_hotkey: "5GsNcT3ENpxQdNnM2LTSC5beBneEddZjpUhNVCcrdUbicp1w",
        net_leverage: 0.0,
        open_ms: 1718366301877,
        orders: [
          {
            leverage: -0.5,
            order_type: "SHORT",
            order_uuid: "01365219-6140-4591-a07a-589d5130c403",
            price: 1.0693,
            price_sources: [],
            processed_ms: 1718366301852,
          },
          {
            leverage: 0.5,
            order_type: "FLAT",
            order_uuid: "ce34a9f3-e440-4537-8a56-1670e40eaa31",
            price: 1.068805,
            price_sources: [],
            processed_ms: 1718367810882,
          },
        ],
        position_type: "FLAT",
        position_uuid: "cb1037ea-43ce-42ba-bdf7-09675988cb42",
        return_at_close: 1.0001964517324418,
        trade_pair: ["EURUSD", "EUR/USD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 175.78400000000002,
        close_ms: 1718371842579,
        current_return: 1.000474732188606,
        initial_entry_price: 167.842,
        is_closed_position: true,
        miner_hotkey: "5GsNcT3ENpxQdNnM2LTSC5beBneEddZjpUhNVCcrdUbicp1w",
        net_leverage: 0.0,
        open_ms: 1718359230136,
        orders: [
          {
            leverage: -0.5,
            order_type: "SHORT",
            order_uuid: "4895c147-6686-4d58-8fc9-ed637afea5b6",
            price: 167.842,
            price_sources: [],
            processed_ms: 1718359230124,
          },
          {
            leverage: -0.5,
            order_type: "SHORT",
            order_uuid: "a0ba86f0-6df7-4f64-9612-d3767add832d",
            price: 168.121,
            price_sources: [],
            processed_ms: 1718365551970,
          },
          {
            leverage: 0.5,
            order_type: "LONG",
            order_uuid: "21efa2e4-6b10-4a01-8e9a-6fa4b01e7a78",
            price: 168.092,
            price_sources: [],
            processed_ms: 1718366028931,
          },
          {
            leverage: 0.49,
            order_type: "LONG",
            order_uuid: "dbdbd036-c3c1-4c93-9ac6-2db800b4a28e",
            price: 167.859,
            price_sources: [],
            processed_ms: 1718367857955,
          },
          {
            leverage: -0.5,
            order_type: "SHORT",
            order_uuid: "080f3943-5e8a-4e85-b769-014985b0c7e1",
            price: 168.053,
            price_sources: [],
            processed_ms: 1718369407067,
          },
          {
            leverage: 0.5,
            order_type: "LONG",
            order_uuid: "fca19cd0-1750-417f-a5a1-79b0ed512b2e",
            price: 167.9065,
            price_sources: [],
            processed_ms: 1718371766915,
          },
          {
            leverage: 0.01,
            order_type: "FLAT",
            order_uuid: "e1b075ad-9959-4477-8c52-fe9ca8375576",
            price: 167.816,
            price_sources: [],
            processed_ms: 1718371842579,
          },
        ],
        position_type: "FLAT",
        position_uuid: "d2efa997-6ed1-49b4-a49e-a3c9edbb32f0",
        return_at_close: 1.0003696823417263,
        trade_pair: ["EURJPY", "EUR/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 96.28518811881189,
        close_ms: 1718604616157,
        current_return: 1.0020057336352495,
        initial_entry_price: 96.274,
        is_closed_position: true,
        miner_hotkey: "5GsNcT3ENpxQdNnM2LTSC5beBneEddZjpUhNVCcrdUbicp1w",
        net_leverage: 0.0,
        open_ms: 1718362562086,
        orders: [
          {
            leverage: -0.5,
            order_type: "SHORT",
            order_uuid: "9afdb3a8-d23c-406b-a74f-53b6122417c6",
            price: 96.274,
            price_sources: [],
            processed_ms: 1718362562047,
          },
          {
            leverage: 0.49,
            order_type: "LONG",
            order_uuid: "a20cdf18-1dfa-4544-8ca1-40fe12483ab6",
            price: 96.404,
            price_sources: [],
            processed_ms: 1718368330788,
          },
          {
            leverage: -0.5,
            order_type: "SHORT",
            order_uuid: "bb9e7069-2434-47ae-8e9b-61557547d32c",
            price: 96.369,
            price_sources: [],
            processed_ms: 1718369438936,
          },
          {
            leverage: 0.5,
            order_type: "LONG",
            order_uuid: "894a38cb-4215-47f0-b394-09037bdd3242",
            price: 96.442,
            price_sources: [],
            processed_ms: 1718370292112,
          },
          {
            leverage: -0.5,
            order_type: "SHORT",
            order_uuid: "dc7248a7-8474-4e1b-ab6a-fb814d23e3b0",
            price: 96.289,
            price_sources: [],
            processed_ms: 1718371477628,
          },
          {
            leverage: -0.5,
            order_type: "SHORT",
            order_uuid: "514d778f-80a2-4df3-9494-e81de87e8720",
            price: 96.482,
            price_sources: [],
            processed_ms: 1718379793826,
          },
          {
            leverage: 0.01,
            order_type: "FLAT",
            order_uuid: "cfedf4c6-8635-441a-bdbd-31c10a32e31a",
            price: 96.094,
            price_sources: [],
            processed_ms: 1718604616157,
          },
        ],
        position_type: "FLAT",
        position_uuid: "09e80e50-ca98-477a-ad61-65b61840d4d8",
        return_at_close: 1.0018654528325406,
        trade_pair: ["NZDJPY", "NZD/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.611435,
        close_ms: 1718629901292,
        current_return: 0.9999632013214814,
        initial_entry_price: 0.611435,
        is_closed_position: true,
        miner_hotkey: "5GsNcT3ENpxQdNnM2LTSC5beBneEddZjpUhNVCcrdUbicp1w",
        net_leverage: 0.0,
        open_ms: 1718604647654,
        orders: [
          {
            leverage: -0.5,
            order_type: "SHORT",
            order_uuid: "e0238fd5-ce7e-421e-8ae7-a030610eeb84",
            price: 0.611435,
            price_sources: [],
            processed_ms: 1718604647476,
          },
          {
            leverage: 0.5,
            order_type: "FLAT",
            order_uuid: "efcfa4e1-76c7-404d-a4df-273a6ba73285",
            price: 0.61148,
            price_sources: [],
            processed_ms: 1718629901292,
          },
        ],
        position_type: "FLAT",
        position_uuid: "ccc36a27-f967-4150-9a38-c8b8ed19ea6a",
        return_at_close: 0.9999282026094352,
        trade_pair: ["NZDUSD", "NZD/USD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.84012,
        close_ms: 1718697325188,
        current_return: 1.0008094081797838,
        initial_entry_price: 0.84012,
        is_closed_position: true,
        miner_hotkey: "5GsNcT3ENpxQdNnM2LTSC5beBneEddZjpUhNVCcrdUbicp1w",
        net_leverage: 0.0,
        open_ms: 1718626163501,
        orders: [
          {
            leverage: -0.5,
            order_type: "SHORT",
            order_uuid: "60b6857d-3275-459f-8c61-81a785105262",
            price: 0.84012,
            price_sources: [],
            processed_ms: 1718626163359,
          },
          {
            leverage: 0.01,
            order_type: "FLAT",
            order_uuid: "3c995ac1-5c2d-4b1f-b3e2-ad45fcf2775e",
            price: 0.83876,
            price_sources: [],
            processed_ms: 1718697325188,
          },
        ],
        position_type: "FLAT",
        position_uuid: "96f0fd4f-61a1-441f-a301-b9923e9ebe69",
        return_at_close: 1.0007743798504976,
        trade_pair: ["NZDCAD", "NZD/CAD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 65090.0,
        close_ms: 1718871760157,
        current_return: 1.0027066369642035,
        initial_entry_price: 65090.0,
        is_closed_position: true,
        miner_hotkey: "5GsNcT3ENpxQdNnM2LTSC5beBneEddZjpUhNVCcrdUbicp1w",
        net_leverage: 0.0,
        open_ms: 1718790218103,
        orders: [
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "a77d546a-188b-485b-9cab-5198c65422af",
            price: 65090.0,
            price_sources: [],
            processed_ms: 1718790217174,
          },
          {
            leverage: 0.01,
            order_type: "FLAT",
            order_uuid: "d7c199fa-174d-4090-aee9-0ea950105da3",
            price: 65794.7,
            price_sources: [],
            processed_ms: 1718871760157,
          },
        ],
        position_type: "FLAT",
        position_uuid: "3245cc8b-6b76-459d-bf6f-2ea5a6a30753",
        return_at_close: 1.0024559603049625,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 1.0658749999999997,
        close_ms: 1718907917081,
        current_return: 0.9973140418522816,
        initial_entry_price: 1.06852,
        is_closed_position: true,
        miner_hotkey: "5GsNcT3ENpxQdNnM2LTSC5beBneEddZjpUhNVCcrdUbicp1w",
        net_leverage: 0.0,
        open_ms: 1718368267615,
        orders: [
          {
            leverage: -0.5,
            order_type: "SHORT",
            order_uuid: "649025bc-cb08-4341-a727-04f976363a7e",
            price: 1.06852,
            price_sources: [],
            processed_ms: 1718368267543,
          },
          {
            leverage: -0.5,
            order_type: "SHORT",
            order_uuid: "9452b265-4c6a-4afe-a25f-72941017d15e",
            price: 1.0689549999999999,
            price_sources: [],
            processed_ms: 1718604684626,
          },
          {
            leverage: 0.5,
            order_type: "LONG",
            order_uuid: "59fbee3f-ef41-4c56-8b9f-0dc86c18e6e4",
            price: 1.0716,
            price_sources: [],
            processed_ms: 1718623717584,
          },
          {
            leverage: 0.01,
            order_type: "FLAT",
            order_uuid: "38a8a975-a5af-4894-929e-cd05623ab7dd",
            price: 1.071615,
            price_sources: [],
            processed_ms: 1718907917081,
          },
        ],
        position_type: "FLAT",
        position_uuid: "f60c118d-7fd7-425d-b415-78ce94c17ec9",
        return_at_close: 0.9972442298693519,
        trade_pair: ["EURUSD", "EUR/USD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 1.2662782352941178,
        close_ms: 1718907973636,
        current_return: 0.9992298121926655,
        initial_entry_price: 1.265925,
        is_closed_position: true,
        miner_hotkey: "5GsNcT3ENpxQdNnM2LTSC5beBneEddZjpUhNVCcrdUbicp1w",
        net_leverage: 0.0,
        open_ms: 1718371919836,
        orders: [
          {
            leverage: -0.5,
            order_type: "SHORT",
            order_uuid: "4aa84c9c-1c67-410f-9733-e13380645252",
            price: 1.265925,
            price_sources: [],
            processed_ms: 1718371919793,
          },
          {
            leverage: 0.49,
            order_type: "LONG",
            order_uuid: "2cf19a9d-3137-486e-9f42-f31ebcf30056",
            price: 1.26794,
            price_sources: [],
            processed_ms: 1718378494519,
          },
          {
            leverage: -0.5,
            order_type: "SHORT",
            order_uuid: "8b1dfd56-d337-4918-8742-3e06340b6bcc",
            price: 1.2682600000000002,
            price_sources: [],
            processed_ms: 1718388549732,
          },
          {
            leverage: 0.01,
            order_type: "FLAT",
            order_uuid: "d03f41a0-3ddc-402b-8ded-8b1990eb0954",
            price: 1.26819,
            price_sources: [],
            processed_ms: 1718907973636,
          },
        ],
        position_type: "FLAT",
        position_uuid: "664273fc-6d2c-4467-b832-9cc8d3dba776",
        return_at_close: 0.999159866105812,
        trade_pair: ["GBPUSD", "GBP/USD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 64987.0,
        close_ms: 1718908283462,
        current_return: 0.9996276947697231,
        initial_entry_price: 64987.0,
        is_closed_position: true,
        miner_hotkey: "5GsNcT3ENpxQdNnM2LTSC5beBneEddZjpUhNVCcrdUbicp1w",
        net_leverage: 0.0,
        open_ms: 1718892758773,
        orders: [
          {
            leverage: -0.25,
            order_type: "SHORT",
            order_uuid: "1aaec266-7329-4ddd-bb1d-b90e948c98e3",
            price: 64987.0,
            price_sources: [],
            processed_ms: 1718892758142,
          },
          {
            leverage: 0.01,
            order_type: "FLAT",
            order_uuid: "c54bfb03-d148-4a5c-96ce-f26caf5f8439",
            price: 65083.78,
            price_sources: [],
            processed_ms: 1718908283462,
          },
        ],
        position_type: "FLAT",
        position_uuid: "8edd0fb4-69f9-4405-942d-243e05824b3a",
        return_at_close: 0.9993777878460307,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 1.06906,
        close_ms: 1718958527035,
        current_return: 1.0008231530503433,
        initial_entry_price: 1.06906,
        is_closed_position: true,
        miner_hotkey: "5GsNcT3ENpxQdNnM2LTSC5beBneEddZjpUhNVCcrdUbicp1w",
        net_leverage: 0.0,
        open_ms: 1718954104808,
        orders: [
          {
            leverage: -1.0,
            order_type: "SHORT",
            order_uuid: "51c96586-45b1-40df-b687-891ccbdd0029",
            price: 1.06906,
            price_sources: [],
            processed_ms: 1718954104633,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "d9d04c26-7c63-472b-bb22-bad922d2e3f3",
            price: 1.06818,
            price_sources: [],
            processed_ms: 1718958527035,
          },
        ],
        position_type: "FLAT",
        position_uuid: "0116a7a0-0831-40db-be00-bf8a0d481a5c",
        return_at_close: 1.0007530954296298,
        trade_pair: ["EURUSD", "EUR/USD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 158.46422310756975,
        close_ms: 1718980540806,
        current_return: 0.9842794947577621,
        initial_entry_price: 156.994,
        is_closed_position: true,
        miner_hotkey: "5GsNcT3ENpxQdNnM2LTSC5beBneEddZjpUhNVCcrdUbicp1w",
        net_leverage: 0.0,
        open_ms: 1718359265908,
        orders: [
          {
            leverage: -0.5,
            order_type: "SHORT",
            order_uuid: "259c3ec4-aa06-4911-9268-b848ba65e51b",
            price: 156.994,
            price_sources: [],
            processed_ms: 1718359265811,
          },
          {
            leverage: 0.49,
            order_type: "LONG",
            order_uuid: "686d9063-4224-4bc7-98b2-a0f4fcc25ed7",
            price: 157.07,
            price_sources: [],
            processed_ms: 1718367951045,
          },
          {
            leverage: -0.5,
            order_type: "SHORT",
            order_uuid: "79c3874c-2ae2-4fb0-8150-4dd85f3a4a67",
            price: 157.25,
            price_sources: [],
            processed_ms: 1718369270788,
          },
          {
            leverage: 0.5,
            order_type: "LONG",
            order_uuid: "8616dedf-abf2-4866-ba71-242a9209de70",
            price: 157.35250000000002,
            price_sources: [],
            processed_ms: 1718370259576,
          },
          {
            leverage: -0.5,
            order_type: "SHORT",
            order_uuid: "c39fad99-d5a9-4645-b7d2-49294deb8d39",
            price: 157.17849999999999,
            price_sources: [],
            processed_ms: 1718372128342,
          },
          {
            leverage: -0.5,
            order_type: "SHORT",
            order_uuid: "17aa11cc-0446-49db-80b0-c1d9a226efbd",
            price: 157.38850000000002,
            price_sources: [],
            processed_ms: 1718377589776,
          },
          {
            leverage: 1.0,
            order_type: "LONG",
            order_uuid: "adff2810-7dcb-40a9-8a71-a88b1652691a",
            price: 157.339,
            price_sources: [],
            processed_ms: 1718571661909,
          },
          {
            leverage: -0.5,
            order_type: "SHORT",
            order_uuid: "82b6d714-e50b-42bb-821c-435803d77f1d",
            price: 157.216,
            price_sources: [],
            processed_ms: 1718604569323,
          },
          {
            leverage: 0.5,
            order_type: "LONG",
            order_uuid: "30b84a17-f254-4912-aed4-868b1aecaffc",
            price: 157.649,
            price_sources: [],
            processed_ms: 1718614836797,
          },
          {
            leverage: -0.5,
            order_type: "SHORT",
            order_uuid: "94850c1e-0a54-4a00-b301-e267b1e3ed0b",
            price: 157.839,
            price_sources: [],
            processed_ms: 1718629931508,
          },
          {
            leverage: -0.5,
            order_type: "SHORT",
            order_uuid: "16d85052-4320-41de-8461-f3770310afbd",
            price: 157.8505,
            price_sources: [],
            processed_ms: 1718629962571,
          },
          {
            leverage: -0.5,
            order_type: "SHORT",
            order_uuid: "daa4f1b6-476d-4e7d-b183-179be89bcbf8",
            price: 158.11,
            price_sources: [],
            processed_ms: 1718697424212,
          },
          {
            leverage: 1.0,
            order_type: "LONG",
            order_uuid: "321e70bf-0934-47de-a01a-2239ba429126",
            price: 157.76,
            price_sources: [],
            processed_ms: 1718719060116,
          },
          {
            leverage: -2.0,
            order_type: "SHORT",
            order_uuid: "d69b6bec-ea03-45fb-9ce2-11e48e479cd6",
            price: 158.698,
            price_sources: [],
            processed_ms: 1718908004310,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "92587c2c-bc3b-4dee-b1b3-1a8b55ce2fa0",
            price: 159.4475,
            price_sources: [],
            processed_ms: 1718980540806,
          },
        ],
        position_type: "FLAT",
        position_uuid: "10410a07-243d-42b1-ae41-7ca054fab4e9",
        return_at_close: 0.9838660973699639,
        trade_pair: ["USDJPY", "USD/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 115.3735,
        close_ms: 1718982156092,
        current_return: 0.9904252261980442,
        initial_entry_price: 114.833,
        is_closed_position: true,
        miner_hotkey: "5GsNcT3ENpxQdNnM2LTSC5beBneEddZjpUhNVCcrdUbicp1w",
        net_leverage: 0.0,
        open_ms: 1718645320805,
        orders: [
          {
            leverage: -0.5,
            order_type: "SHORT",
            order_uuid: "1aec440a-8899-4c28-a499-9866104f773f",
            price: 114.833,
            price_sources: [],
            processed_ms: 1718645320548,
          },
          {
            leverage: -0.5,
            order_type: "SHORT",
            order_uuid: "d2a8b301-5b8f-48d2-86be-b81433f53be1",
            price: 115.914,
            price_sources: [],
            processed_ms: 1718958593747,
          },
          {
            leverage: 1.5,
            order_type: "FLAT",
            order_uuid: "78dd6f4a-9e40-4fc5-97da-6f6f30a9a89e",
            price: 116.473,
            price_sources: [],
            processed_ms: 1718982156092,
          },
        ],
        position_type: "FLAT",
        position_uuid: "eb53d541-862d-4ad8-986e-ba4dda36987b",
        return_at_close: 0.9903558964322103,
        trade_pair: ["CADJPY", "CAD/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 64408.8,
        close_ms: 1719177123638,
        current_return: 0.996879463675771,
        initial_entry_price: 64408.8,
        is_closed_position: true,
        miner_hotkey: "5GsNcT3ENpxQdNnM2LTSC5beBneEddZjpUhNVCcrdUbicp1w",
        net_leverage: 0.0,
        open_ms: 1719039130021,
        orders: [
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "ed53a4c7-30b3-4984-8aa9-6682b5bfaf34",
            price: 64408.8,
            price_sources: [],
            processed_ms: 1719039129630,
          },
          {
            leverage: 0.01,
            order_type: "FLAT",
            order_uuid: "b80e73ab-0e50-4308-8fdb-a47a73ba0efe",
            price: 63604.84,
            price_sources: [],
            processed_ms: 1719177123638,
          },
        ],
        position_type: "FLAT",
        position_uuid: "a2dee93a-ff6b-4796-83b4-c6a88587b07b",
        return_at_close: 0.996630243809852,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 3376.72,
        close_ms: 1719957623801,
        current_return: 1.0131991992229146,
        initial_entry_price: 3376.72,
        is_closed_position: true,
        miner_hotkey: "5GsNcT3ENpxQdNnM2LTSC5beBneEddZjpUhNVCcrdUbicp1w",
        net_leverage: 0.0,
        open_ms: 1719214834795,
        orders: [
          {
            leverage: 1.0,
            order_type: "LONG",
            order_uuid: "7b6fb9c4-21f2-4746-9564-92a80079cee9",
            price: 3376.72,
            price_sources: [],
            processed_ms: 1719214833211,
          },
          {
            leverage: 0.01,
            order_type: "FLAT",
            order_uuid: "a3a8f455-12d1-4c00-bb08-9b7ddbe57671",
            price: 3421.29,
            price_sources: [],
            processed_ms: 1719957623801,
          },
        ],
        position_type: "FLAT",
        position_uuid: "8efaa9d2-b0c5-4d62-a654-111bc310e6d8",
        return_at_close: 1.0121860000236917,
        trade_pair: ["ETHUSD", "ETH/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 97.51675,
        close_ms: 1719957671319,
        current_return: 0.9739717454422343,
        initial_entry_price: 97.471,
        is_closed_position: true,
        miner_hotkey: "5GsNcT3ENpxQdNnM2LTSC5beBneEddZjpUhNVCcrdUbicp1w",
        net_leverage: 0.0,
        open_ms: 1718981707031,
        orders: [
          {
            leverage: -3.0,
            order_type: "SHORT",
            order_uuid: "f237456c-43ec-44d5-9d60-edae30a62266",
            price: 97.471,
            price_sources: [],
            processed_ms: 1718981706940,
          },
          {
            leverage: -1.0,
            order_type: "SHORT",
            order_uuid: "d806e905-3f49-4f00-9d1d-ea77b45a1ab7",
            price: 97.654,
            price_sources: [],
            processed_ms: 1719177181574,
          },
          {
            leverage: 0.01,
            order_type: "FLAT",
            order_uuid: "db07d38e-6bbe-442c-8819-6558d3890246",
            price: 98.151,
            price_sources: [],
            processed_ms: 1719957671319,
          },
        ],
        position_type: "FLAT",
        position_uuid: "fee7ac8d-1e17-4d8b-be47-e5fa61b90e2b",
        return_at_close: 0.9736990333535105,
        trade_pair: ["NZDJPY", "NZD/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 61892.0,
        close_ms: 1720111450330,
        current_return: 1.061635267885995,
        initial_entry_price: 61892.0,
        is_closed_position: true,
        miner_hotkey: "5GsNcT3ENpxQdNnM2LTSC5beBneEddZjpUhNVCcrdUbicp1w",
        net_leverage: 0.0,
        open_ms: 1719957701758,
        orders: [
          {
            leverage: -1.0,
            order_type: "SHORT",
            order_uuid: "b0219e54-2916-4153-85bc-4598ae5df851",
            price: 61892.0,
            price_sources: [],
            processed_ms: 1719957701419,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "43a3886e-5f56-4f13-8a72-ba031976dafe",
            price: 58077.27,
            price_sources: [
              {
                close: 58077.27,
                high: 58085.3,
                lag_ms: 330,
                low: 58073.99,
                open: 58077.27,
                source: "Polygon_ws",
                start_ms: 1720111450000,
                timespan_ms: 0,
                volume: 0.13201217,
                vwap: 58075.6882,
                websocket: true,
              },
              {
                close: 58069.5,
                high: 58069.5,
                lag_ms: 1330,
                low: 58069.5,
                open: 58069.5,
                source: "TwelveData_ws",
                start_ms: 1720111449000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1720111450330,
          },
        ],
        position_type: "FLAT",
        position_uuid: "cdf3565e-8582-41f7-bdeb-79e3bb69716d",
        return_at_close: 1.060573632618109,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 0.7894850000000003,
        close_ms: 0,
        current_return: 0.9953781209843036,
        initial_entry_price: 0.83267,
        is_closed_position: false,
        miner_hotkey: "5GsNcT3ENpxQdNnM2LTSC5beBneEddZjpUhNVCcrdUbicp1w",
        net_leverage: -0.1,
        open_ms: 1720111500458,
        orders: [
          {
            leverage: -1.0,
            order_type: "SHORT",
            order_uuid: "759e1f3c-548d-4273-8cff-0f5c2c2acbfb",
            price: 0.83267,
            price_sources: [
              {
                close: 0.83267,
                high: 0.83267,
                lag_ms: 133,
                low: 0.83267,
                open: 0.83267,
                source: "TwelveData_ws",
                start_ms: 1720111500000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
              {
                close: 0.832685800992407,
                high: 0.832685800992407,
                lag_ms: 1867,
                low: 0.832685800992407,
                open: 0.832685800992407,
                source: "Polygon_ws",
                start_ms: 1720111502000,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 0.832685800992407,
                websocket: true,
              },
              {
                close: 0.832746985150605,
                high: 0.832746985150605,
                lag_ms: 6134,
                low: 0.832746985150605,
                open: 0.832746985150605,
                source: "Polygon_rest",
                start_ms: 1720111493000,
                timespan_ms: 1000,
                volume: 1.0,
                vwap: null,
                websocket: false,
              },
              {
                close: 0.8325,
                high: 0.83261,
                lag_ms: 1200134,
                low: 0.8325,
                open: 0.83261,
                source: "TwelveData_rest",
                start_ms: 1720110240000,
                timespan_ms: 60000,
                volume: null,
                vwap: null,
                websocket: false,
              },
            ],
            processed_ms: 1720111500133,
          },
          {
            leverage: 0.75,
            order_type: "LONG",
            order_uuid: "66a3b03e-669a-4fba-a2aa-9bb4042b0992",
            price: 0.8374,
            price_sources: [
              {
                close: 0.8374,
                high: 0.8374,
                lag_ms: 176,
                low: 0.8374,
                open: 0.8374,
                source: "Polygon_ws",
                start_ms: 1720418168000,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 0.8374,
                websocket: true,
              },
              {
                close: 0.83764,
                high: 0.83764,
                lag_ms: 824,
                low: 0.83764,
                open: 0.83764,
                source: "TwelveData_ws",
                start_ms: 1720418167000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1720418167824,
          },
          {
            leverage: 0.15,
            order_type: "LONG",
            order_uuid: "66ce1a73-8cb6-400c-a055-40d943bb198c",
            price: 0.83781,
            price_sources: [
              {
                close: 0.83781,
                high: 0.83781,
                lag_ms: 62,
                low: 0.83781,
                open: 0.83781,
                source: "Polygon_ws",
                start_ms: 1720418439999,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 0.83781,
                websocket: true,
              },
              {
                close: 0.83779,
                high: 0.83779,
                lag_ms: 1061,
                low: 0.83779,
                open: 0.83779,
                source: "TwelveData_ws",
                start_ms: 1720418439000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1720418440061,
          },
        ],
        position_type: "SHORT",
        position_uuid: "829a4ab2-52a1-470d-8ea0-0c321abf3586",
        return_at_close: 0.9953119283392582,
        trade_pair: ["NZDCAD", "NZD/CAD", 7e-5, 0.001, 500],
      },
    ],
    thirty_day_returns: 1.030600848253962,
  },
  "5Gx57AnGeGAzbZCY4aQxtDECcmq1RVLitsdHtqc7iVznPHDS": {
    all_time_returns: 1.0,
    n_positions: 0,
    percentage_profitable: 0.0,
    positions: [
      {
        average_entry_price: 64481.759999999995,
        close_ms: 1711196423809,
        current_return: 1.0015015409008687,
        initial_entry_price: 64481.76,
        is_closed_position: true,
        miner_hotkey: "5Gx57AnGeGAzbZCY4aQxtDECcmq1RVLitsdHtqc7iVznPHDS",
        net_leverage: 0.0,
        open_ms: 1711192834486,
        orders: [
          {
            leverage: 0.6,
            order_type: "LONG",
            order_uuid: "1b4b39c9-dcbe-4aab-ac97-cd7d0e71686e",
            price: 64481.76,
            price_sources: [],
            processed_ms: 1711192834485,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "668063f8-da75-4032-972e-954b908a2c8e",
            price: 64643.13,
            price_sources: [],
            processed_ms: 1711196423809,
          },
        ],
        position_type: "FLAT",
        position_uuid: "4c408fb8-35b2-4865-aaec-c87ae4e248b2",
        return_at_close: 1.0002997390517878,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 64616.91,
        close_ms: 1711200050027,
        current_return: 0.9996201303962075,
        initial_entry_price: 64616.91,
        is_closed_position: true,
        miner_hotkey: "5Gx57AnGeGAzbZCY4aQxtDECcmq1RVLitsdHtqc7iVznPHDS",
        net_leverage: 0.0,
        open_ms: 1711196442814,
        orders: [
          {
            leverage: 0.6,
            order_type: "LONG",
            order_uuid: "01027330-a8e6-48c6-9c53-b67a40cdd348",
            price: 64616.91,
            price_sources: [],
            processed_ms: 1711196442813,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "6a27fec6-da9c-4fe5-8423-fc4cde9b7529",
            price: 64576.0,
            price_sources: [],
            processed_ms: 1711200050027,
          },
        ],
        position_type: "FLAT",
        position_uuid: "13698893-abea-427a-9b5f-407396b64752",
        return_at_close: 0.9984205862397321,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 64597.10999999999,
        close_ms: 1711203716779,
        current_return: 1.0002623956396812,
        initial_entry_price: 64597.11,
        is_closed_position: true,
        miner_hotkey: "5Gx57AnGeGAzbZCY4aQxtDECcmq1RVLitsdHtqc7iVznPHDS",
        net_leverage: 0.0,
        open_ms: 1711200185014,
        orders: [
          {
            leverage: 0.6,
            order_type: "LONG",
            order_uuid: "49359730-62a6-4aa0-9f5c-70f5fd53a30f",
            price: 64597.11,
            price_sources: [],
            processed_ms: 1711200185013,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "d77f2d96-4eca-4525-a53d-9f7793869f97",
            price: 64625.36,
            price_sources: [],
            processed_ms: 1711203716779,
          },
        ],
        position_type: "FLAT",
        position_uuid: "36136417-c434-44fd-b433-4d3572d75288",
        return_at_close: 0.9990620807649137,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 64659.99,
        close_ms: 1711207377636,
        current_return: 1.0011904424977487,
        initial_entry_price: 64659.99,
        is_closed_position: true,
        miner_hotkey: "5Gx57AnGeGAzbZCY4aQxtDECcmq1RVLitsdHtqc7iVznPHDS",
        net_leverage: 0.0,
        open_ms: 1711203803962,
        orders: [
          {
            leverage: 0.6,
            order_type: "LONG",
            order_uuid: "52a55059-dc13-4685-aaac-a3c43391e23f",
            price: 64659.99,
            price_sources: [],
            processed_ms: 1711203803962,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "37bbf86e-a0e6-42f8-a0c8-b3cce8312717",
            price: 64788.28,
            price_sources: [],
            processed_ms: 1711207377636,
          },
        ],
        position_type: "FLAT",
        position_uuid: "f1867381-c1c1-45ef-8dd0-be2e9954d3e0",
        return_at_close: 0.9999890139667514,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 64823.87999999999,
        close_ms: 1711211047743,
        current_return: 1.002905688459253,
        initial_entry_price: 64823.88,
        is_closed_position: true,
        miner_hotkey: "5Gx57AnGeGAzbZCY4aQxtDECcmq1RVLitsdHtqc7iVznPHDS",
        net_leverage: 0.0,
        open_ms: 1711207464776,
        orders: [
          {
            leverage: 0.6,
            order_type: "LONG",
            order_uuid: "db39822d-a768-4b36-90e2-2d5a9fdefade",
            price: 64823.88,
            price_sources: [],
            processed_ms: 1711207464775,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "156cac3d-ecac-46d5-b6f7-c678f54c8b15",
            price: 65137.81,
            price_sources: [],
            processed_ms: 1711211047743,
          },
        ],
        position_type: "FLAT",
        position_uuid: "f9e7e7b6-ca7f-4401-b25e-2ba258f07969",
        return_at_close: 1.001702201633102,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 65213.68,
        close_ms: 1711214701975,
        current_return: 1.002898256930141,
        initial_entry_price: 65213.68,
        is_closed_position: true,
        miner_hotkey: "5Gx57AnGeGAzbZCY4aQxtDECcmq1RVLitsdHtqc7iVznPHDS",
        net_leverage: 0.0,
        open_ms: 1711211133613,
        orders: [
          {
            leverage: 0.6,
            order_type: "LONG",
            order_uuid: "1bc84c3f-013d-40bb-90e6-05d2850ba09f",
            price: 65213.68,
            price_sources: [],
            processed_ms: 1711211133612,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "7484a0b1-183a-41e2-8b89-fb7bfa328afb",
            price: 65528.69,
            price_sources: [],
            processed_ms: 1711214701975,
          },
        ],
        position_type: "FLAT",
        position_uuid: "0146796e-b9f3-4d2b-94d5-08cfe5104370",
        return_at_close: 1.001694779021825,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 65571.73,
        close_ms: 1711218362017,
        current_return: 0.9995474269170571,
        initial_entry_price: 65571.73,
        is_closed_position: true,
        miner_hotkey: "5Gx57AnGeGAzbZCY4aQxtDECcmq1RVLitsdHtqc7iVznPHDS",
        net_leverage: 0.0,
        open_ms: 1711214836181,
        orders: [
          {
            leverage: 0.6,
            order_type: "LONG",
            order_uuid: "18a84017-9611-4fb3-8735-7ab1d27d1f40",
            price: 65571.73,
            price_sources: [],
            processed_ms: 1711214836180,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "9936f610-8506-496a-b02b-9b770699b8f0",
            price: 65522.27,
            price_sources: [],
            processed_ms: 1711218362017,
          },
        ],
        position_type: "FLAT",
        position_uuid: "4d6b6c49-d68a-4250-829d-f3d0f85765cc",
        return_at_close: 0.9983479700047566,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 65520.12999999999,
        close_ms: 1711222029792,
        current_return: 0.9966984497741382,
        initial_entry_price: 65520.13,
        is_closed_position: true,
        miner_hotkey: "5Gx57AnGeGAzbZCY4aQxtDECcmq1RVLitsdHtqc7iVznPHDS",
        net_leverage: 0.0,
        open_ms: 1711218448818,
        orders: [
          {
            leverage: 0.6,
            order_type: "LONG",
            order_uuid: "cbbbcb35-9788-4c85-9bee-22ea7887a712",
            price: 65520.13,
            price_sources: [],
            processed_ms: 1711218448817,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "1731a3ac-abda-47a9-b612-d9feec38ae1d",
            price: 65159.6,
            price_sources: [],
            processed_ms: 1711222029792,
          },
        ],
        position_type: "FLAT",
        position_uuid: "3edadaf2-c624-4be1-8edc-d3e102877fda",
        return_at_close: 0.9955024116344093,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 65154.94,
        close_ms: 1711225700868,
        current_return: 0.9978603310815726,
        initial_entry_price: 65154.94,
        is_closed_position: true,
        miner_hotkey: "5Gx57AnGeGAzbZCY4aQxtDECcmq1RVLitsdHtqc7iVznPHDS",
        net_leverage: 0.0,
        open_ms: 1711222116745,
        orders: [
          {
            leverage: 0.6,
            order_type: "LONG",
            order_uuid: "98161bfe-9394-492e-a69e-cb1305378d1d",
            price: 65154.94,
            price_sources: [],
            processed_ms: 1711222116744,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "6bff6bf1-865e-4c05-b2bb-8507cb56855f",
            price: 64922.59,
            price_sources: [],
            processed_ms: 1711225700868,
          },
        ],
        position_type: "FLAT",
        position_uuid: "69e156cf-7d91-4d7b-9810-128866799efd",
        return_at_close: 0.9966628986842747,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 64976.28,
        close_ms: 1711229350877,
        current_return: 0.9993087323558689,
        initial_entry_price: 64976.28,
        is_closed_position: true,
        miner_hotkey: "5Gx57AnGeGAzbZCY4aQxtDECcmq1RVLitsdHtqc7iVznPHDS",
        net_leverage: 0.0,
        open_ms: 1711225806354,
        orders: [
          {
            leverage: 0.6,
            order_type: "LONG",
            order_uuid: "d43be54a-bc45-441c-8dd3-3b8f596fccb5",
            price: 64976.28,
            price_sources: [],
            processed_ms: 1711225806353,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "fac5f610-f96a-4ab6-84c9-03b10345113c",
            price: 64901.42,
            price_sources: [],
            processed_ms: 1711229350877,
          },
        ],
        position_type: "FLAT",
        position_uuid: "01eccb41-4399-44ee-ac94-e6ee43ed7c4d",
        return_at_close: 0.9981095618770419,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 64858.53999999999,
        close_ms: 1711233007523,
        current_return: 1.000833136237726,
        initial_entry_price: 64858.54,
        is_closed_position: true,
        miner_hotkey: "5Gx57AnGeGAzbZCY4aQxtDECcmq1RVLitsdHtqc7iVznPHDS",
        net_leverage: 0.0,
        open_ms: 1711229466812,
        orders: [
          {
            leverage: 0.6,
            order_type: "LONG",
            order_uuid: "65a85888-807a-498f-a181-9ce3e3ebd3bf",
            price: 64858.54,
            price_sources: [],
            processed_ms: 1711229466810,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "1e5b8b5f-c795-42d3-a780-9e34b8becf1f",
            price: 64948.6,
            price_sources: [],
            processed_ms: 1711233007523,
          },
        ],
        position_type: "FLAT",
        position_uuid: "a2281305-87c1-46ce-90bc-99b8dab01fc5",
        return_at_close: 0.9996321364742409,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 64976.759999999995,
        close_ms: 1711236684029,
        current_return: 0.9948427714770636,
        initial_entry_price: 64976.76,
        is_closed_position: true,
        miner_hotkey: "5Gx57AnGeGAzbZCY4aQxtDECcmq1RVLitsdHtqc7iVznPHDS",
        net_leverage: 0.0,
        open_ms: 1711233093799,
        orders: [
          {
            leverage: 0.6,
            order_type: "LONG",
            order_uuid: "3a90b648-70ff-4b3b-8738-9ed5d1fbc8c7",
            price: 64976.76,
            price_sources: [],
            processed_ms: 1711233093797,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "f0c0b274-7082-44ba-a2f7-208ff8901de3",
            price: 64418.26,
            price_sources: [],
            processed_ms: 1711236684029,
          },
        ],
        position_type: "FLAT",
        position_uuid: "a6ebf556-9bbb-4efd-a971-a0029f6919d0",
        return_at_close: 0.9936489601512911,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 64399.259999999995,
        close_ms: 1711240335921,
        current_return: 1.0000143479909553,
        initial_entry_price: 64399.26,
        is_closed_position: true,
        miner_hotkey: "5Gx57AnGeGAzbZCY4aQxtDECcmq1RVLitsdHtqc7iVznPHDS",
        net_leverage: 0.0,
        open_ms: 1711236788424,
        orders: [
          {
            leverage: 0.6,
            order_type: "LONG",
            order_uuid: "064655c3-37c4-4856-be18-dda53bbfa841",
            price: 64399.26,
            price_sources: [],
            processed_ms: 1711236788423,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "22811317-72f2-4329-8316-6d884272c779",
            price: 64400.8,
            price_sources: [],
            processed_ms: 1711240335921,
          },
        ],
        position_type: "FLAT",
        position_uuid: "03c9bd79-7d72-41ca-9b00-21da1ceecd91",
        return_at_close: 0.9988143307733662,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 64398.719999999994,
        close_ms: 1711244012971,
        current_return: 1.002182030947199,
        initial_entry_price: 64398.72,
        is_closed_position: true,
        miner_hotkey: "5Gx57AnGeGAzbZCY4aQxtDECcmq1RVLitsdHtqc7iVznPHDS",
        net_leverage: 0.0,
        open_ms: 1711240422877,
        orders: [
          {
            leverage: 0.6,
            order_type: "LONG",
            order_uuid: "752caae9-cda2-491d-be3f-ef9c1d3ede51",
            price: 64398.72,
            price_sources: [],
            processed_ms: 1711240422876,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "abcd65e5-6cb0-424b-82ea-a6f19b621da1",
            price: 64632.92,
            price_sources: [],
            processed_ms: 1711244012971,
          },
        ],
        position_type: "FLAT",
        position_uuid: "02b6b4df-8f9b-4a3d-9f39-c7b46949e2f4",
        return_at_close: 1.0009794125100624,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 64562.75000000001,
        close_ms: 1711247669994,
        current_return: 0.9976819760620481,
        initial_entry_price: 64562.75,
        is_closed_position: true,
        miner_hotkey: "5Gx57AnGeGAzbZCY4aQxtDECcmq1RVLitsdHtqc7iVznPHDS",
        net_leverage: 0.0,
        open_ms: 1711244114770,
        orders: [
          {
            leverage: 0.6,
            order_type: "LONG",
            order_uuid: "4e6748e8-a7e6-4872-bb11-2d869f19bb6c",
            price: 64562.75,
            price_sources: [],
            processed_ms: 1711244114769,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "3e808d5c-9187-42ff-a092-2f02e3083c4d",
            price: 64313.32,
            price_sources: [],
            processed_ms: 1711247669994,
          },
        ],
        position_type: "FLAT",
        position_uuid: "93628807-b83e-4ee0-8334-74fe89b7ef0e",
        return_at_close: 0.9964847576907737,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 64311.8,
        close_ms: 1711251341996,
        current_return: 0.9979988120376043,
        initial_entry_price: 64311.8,
        is_closed_position: true,
        miner_hotkey: "5Gx57AnGeGAzbZCY4aQxtDECcmq1RVLitsdHtqc7iVznPHDS",
        net_leverage: 0.0,
        open_ms: 1711247758180,
        orders: [
          {
            leverage: 0.6,
            order_type: "LONG",
            order_uuid: "bfb3b88b-f309-4c28-9932-043709529ceb",
            price: 64311.8,
            price_sources: [],
            processed_ms: 1711247758178,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "76606dde-86dd-4219-b213-7cb31ca2a347",
            price: 64097.3,
            price_sources: [],
            processed_ms: 1711251341996,
          },
        ],
        position_type: "FLAT",
        position_uuid: "7acb673d-3560-42f9-9d9d-3225d878b818",
        return_at_close: 0.9968012134631592,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 64111.850000000006,
        close_ms: 1711254991861,
        current_return: 0.998998905818503,
        initial_entry_price: 64111.85,
        is_closed_position: true,
        miner_hotkey: "5Gx57AnGeGAzbZCY4aQxtDECcmq1RVLitsdHtqc7iVznPHDS",
        net_leverage: 0.0,
        open_ms: 1711251444810,
        orders: [
          {
            leverage: 0.6,
            order_type: "LONG",
            order_uuid: "221beea1-ac07-4b04-8619-9958fe4de394",
            price: 64111.85,
            price_sources: [],
            processed_ms: 1711251444809,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "8fdd416b-416e-4151-9cc3-6350ae795e75",
            price: 64004.88,
            price_sources: [],
            processed_ms: 1711254991861,
          },
        ],
        position_type: "FLAT",
        position_uuid: "c1c3a545-60b5-4846-a992-b2f6283837e2",
        return_at_close: 0.9978001071315209,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 63960.92,
        close_ms: 1711259679999,
        current_return: 1.003796755894068,
        initial_entry_price: 63960.92,
        is_closed_position: true,
        miner_hotkey: "5Gx57AnGeGAzbZCY4aQxtDECcmq1RVLitsdHtqc7iVznPHDS",
        net_leverage: 0.0,
        open_ms: 1711255080175,
        orders: [
          {
            leverage: 0.6,
            order_type: "LONG",
            order_uuid: "40a8a1df-beee-467e-b8b5-25e84076437e",
            price: 63960.92,
            price_sources: [],
            processed_ms: 1711255080173,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "7ca55745-d6ac-4784-b81a-26e90ea4c13c",
            price: 64365.66,
            price_sources: [],
            processed_ms: 1711259679999,
          },
        ],
        position_type: "FLAT",
        position_uuid: "6897e62c-9dfd-4a36-bb67-8c5cff3648c6",
        return_at_close: 1.002592199786995,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 64352.94,
        close_ms: 1711263346908,
        current_return: 0.9969304044228593,
        initial_entry_price: 64352.94,
        is_closed_position: true,
        miner_hotkey: "5Gx57AnGeGAzbZCY4aQxtDECcmq1RVLitsdHtqc7iVznPHDS",
        net_leverage: 0.0,
        open_ms: 1711259769689,
        orders: [
          {
            leverage: 1.25,
            order_type: "LONG",
            order_uuid: "aaf980d9-728a-46bc-ae8b-ab7e5b996ee7",
            price: 64352.94,
            price_sources: [],
            processed_ms: 1711259769687,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "60340683-66d2-425c-a523-aa4b94f887e6",
            price: 64194.91,
            price_sources: [],
            processed_ms: 1711263346908,
          },
        ],
        position_type: "FLAT",
        position_uuid: "068d7e07-dd39-421f-acb9-4d6db23306d7",
        return_at_close: 0.9944380784118022,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 64274.52,
        close_ms: 1711267067229,
        current_return: 1.0024099624030292,
        initial_entry_price: 64212.62,
        is_closed_position: true,
        miner_hotkey: "5Gx57AnGeGAzbZCY4aQxtDECcmq1RVLitsdHtqc7iVznPHDS",
        net_leverage: 0.0,
        open_ms: 1711263433926,
        orders: [
          {
            leverage: 1.25,
            order_type: "LONG",
            order_uuid: "277849ca-5877-4c73-9cd6-8e9688186411",
            price: 64212.62,
            price_sources: [],
            processed_ms: 1711263433924,
          },
          {
            leverage: 1.25,
            order_type: "LONG",
            order_uuid: "581603ef-84a6-4417-b170-cd61157582a7",
            price: 64336.42,
            price_sources: [],
            processed_ms: 1711267047627,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "f2011309-9d3d-44ae-b584-af19ba073071",
            price: 64336.42,
            price_sources: [],
            processed_ms: 1711267067229,
          },
        ],
        position_type: "FLAT",
        position_uuid: "1422a3f1-d425-4043-8722-2c581f91af33",
        return_at_close: 0.997397912591014,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 64885.58999999999,
        close_ms: 1711277997802,
        current_return: 1.004254796789241,
        initial_entry_price: 64885.59,
        is_closed_position: true,
        miner_hotkey: "5Gx57AnGeGAzbZCY4aQxtDECcmq1RVLitsdHtqc7iVznPHDS",
        net_leverage: 0.0,
        open_ms: 1711274417769,
        orders: [
          {
            leverage: 1.25,
            order_type: "LONG",
            order_uuid: "0bda98fe-071d-4a96-b5b1-f5b243947da2",
            price: 64885.59,
            price_sources: [],
            processed_ms: 1711274417768,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "c2170e2e-12b7-4d98-bca4-7f252163730b",
            price: 65106.45,
            price_sources: [],
            processed_ms: 1711277997802,
          },
        ],
        position_type: "FLAT",
        position_uuid: "b42fe6a9-8a8c-431a-adad-d4d6ea6f3e0b",
        return_at_close: 1.001744159797268,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 64826.82999999998,
        close_ms: 1711312331422,
        current_return: 1.0292041220327985,
        initial_entry_price: 65078.57,
        is_closed_position: true,
        miner_hotkey: "5Gx57AnGeGAzbZCY4aQxtDECcmq1RVLitsdHtqc7iVznPHDS",
        net_leverage: 0.0,
        open_ms: 1711278085139,
        orders: [
          {
            leverage: 1.25,
            order_type: "LONG",
            order_uuid: "aa1ebc0d-af0a-46ae-ace6-6a19fc80c4e8",
            price: 65078.57,
            price_sources: [],
            processed_ms: 1711278085137,
          },
          {
            leverage: 1.25,
            order_type: "LONG",
            order_uuid: "90344205-0306-498b-b9b1-7e3e72210cbe",
            price: 65706.59,
            price_sources: [],
            processed_ms: 1711304921612,
          },
          {
            leverage: -1.25,
            order_type: "SHORT",
            order_uuid: "54ceb65b-e2c3-4671-af07-6fb8aabc36bb",
            price: 65824.31,
            price_sources: [],
            processed_ms: 1711305819105,
          },
          {
            leverage: 1.25,
            order_type: "LONG",
            order_uuid: "2ee22c78-e9ff-4fa6-91ff-210b54572bcf",
            price: 65714.06,
            price_sources: [],
            processed_ms: 1711308611013,
          },
          {
            leverage: -1.25,
            order_type: "SHORT",
            order_uuid: "a8c8fe8d-541a-46e9-aaa5-99d75183f1fd",
            price: 65848.08,
            price_sources: [],
            processed_ms: 1711309541065,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "128dad6d-1cf1-45c2-8f01-d604ce9bccd3",
            price: 66347.28,
            price_sources: [],
            processed_ms: 1711312331422,
          },
        ],
        position_type: "FLAT",
        position_uuid: "5410b3c4-9388-4e49-9320-2c54ec7110ac",
        return_at_close: 1.0240581014226346,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 66216.83,
        close_ms: 1711315125028,
        current_return: 1.0030073547767235,
        initial_entry_price: 66216.83,
        is_closed_position: true,
        miner_hotkey: "5Gx57AnGeGAzbZCY4aQxtDECcmq1RVLitsdHtqc7iVznPHDS",
        net_leverage: 0.0,
        open_ms: 1711313263255,
        orders: [
          {
            leverage: 1.25,
            order_type: "LONG",
            order_uuid: "5731ef92-c5ad-4f26-8b4e-c64bb3653336",
            price: 66216.83,
            price_sources: [],
            processed_ms: 1711313263253,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "24ca4c67-6bba-4506-994d-3794d13780e7",
            price: 66376.14,
            price_sources: [],
            processed_ms: 1711315125028,
          },
        ],
        position_type: "FLAT",
        position_uuid: "eef60752-de9b-490d-8e3e-872818ef1bdb",
        return_at_close: 1.0004998363897817,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 66294.23,
        close_ms: 1711317934168,
        current_return: 1.0090007833260903,
        initial_entry_price: 66294.23,
        is_closed_position: true,
        miner_hotkey: "5Gx57AnGeGAzbZCY4aQxtDECcmq1RVLitsdHtqc7iVznPHDS",
        net_leverage: 0.0,
        open_ms: 1711316064008,
        orders: [
          {
            leverage: 1.25,
            order_type: "LONG",
            order_uuid: "f2075e82-cdb8-4e3d-969c-e3e81884750f",
            price: 66294.23,
            price_sources: [],
            processed_ms: 1711316064006,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "5e28d815-ff6a-4797-892a-bcea8013ebd4",
            price: 66771.59,
            price_sources: [],
            processed_ms: 1711317934168,
          },
        ],
        position_type: "FLAT",
        position_uuid: "42624484-1042-4e5a-ae86-5033ba241694",
        return_at_close: 1.006478281367775,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 154094.75142856923,
        close_ms: 1711329118124,
        current_return: 1.0045480217470433,
        initial_entry_price: 67253.28,
        is_closed_position: true,
        miner_hotkey: "5Gx57AnGeGAzbZCY4aQxtDECcmq1RVLitsdHtqc7iVznPHDS",
        net_leverage: 0.0,
        open_ms: 1711321656973,
        orders: [
          {
            leverage: -1.25,
            order_type: "SHORT",
            order_uuid: "50ac2de3-e3ea-4cfd-877c-2a7c550e6ddb",
            price: 67253.28,
            price_sources: [],
            processed_ms: 1711321656971,
          },
          {
            leverage: 0.125,
            order_type: "LONG",
            order_uuid: "ff6b3c39-e4f5-484f-816f-03d5bec54a11",
            price: 67429.88,
            price_sources: [],
            processed_ms: 1711322591848,
          },
          {
            leverage: 0.125,
            order_type: "LONG",
            order_uuid: "195afc06-a40f-4d69-bba9-446f481642c2",
            price: 67278.44,
            price_sources: [],
            processed_ms: 1711323525075,
          },
          {
            leverage: 0.125,
            order_type: "LONG",
            order_uuid: "717ca73c-301e-44da-afcb-25230a5e62dc",
            price: 67253.02,
            price_sources: [],
            processed_ms: 1711324463009,
          },
          {
            leverage: 0.7,
            order_type: "LONG",
            order_uuid: "4c49660f-a4c8-419b-a8ec-c76baaf65412",
            price: 66899.51,
            price_sources: [],
            processed_ms: 1711325402286,
          },
          {
            leverage: 0.08750000000000002,
            order_type: "LONG",
            order_uuid: "166c0c4a-7594-44ab-8f42-0e21ca7edaf6",
            price: 66931.06,
            price_sources: [],
            processed_ms: 1711326321992,
          },
          {
            leverage: 0.07000000000000002,
            order_type: "LONG",
            order_uuid: "d6890eaa-ed89-4ea9-b5fc-336b88e285d9",
            price: 66631.13,
            price_sources: [],
            processed_ms: 1711327252997,
          },
          {
            leverage: 0.014000000000000002,
            order_type: "LONG",
            order_uuid: "59c575d9-d612-4dd2-b8f3-63cb123878ca",
            price: 66556.93,
            price_sources: [],
            processed_ms: 1711328199128,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "23f54a37-9281-4eee-b35b-3e3253131be3",
            price: 66703.5,
            price_sources: [],
            processed_ms: 1711329118124,
          },
        ],
        position_type: "FLAT",
        position_uuid: "f96f2034-131f-4316-a5c2-573912c310a1",
        return_at_close: 1.0020366516926758,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 66535.97,
        close_ms: 1711334659010,
        current_return: 1.0041019166024032,
        initial_entry_price: 66535.97,
        is_closed_position: true,
        miner_hotkey: "5Gx57AnGeGAzbZCY4aQxtDECcmq1RVLitsdHtqc7iVznPHDS",
        net_leverage: 0.0,
        open_ms: 1711332818945,
        orders: [
          {
            leverage: 1.25,
            order_type: "LONG",
            order_uuid: "f7f8c30c-7deb-44a9-b721-4f777105d750",
            price: 66535.97,
            price_sources: [],
            processed_ms: 1711332818943,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "8ed9791f-d575-4e0c-9767-930fa315f673",
            price: 66754.31,
            price_sources: [],
            processed_ms: 1711334659010,
          },
        ],
        position_type: "FLAT",
        position_uuid: "deab8dad-24f2-4222-9e14-8f345543b409",
        return_at_close: 1.0015916618108973,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: -6329.304999993957,
        close_ms: 1711352985519,
        current_return: 0.99782244885698,
        initial_entry_price: 67243.78,
        is_closed_position: true,
        miner_hotkey: "5Gx57AnGeGAzbZCY4aQxtDECcmq1RVLitsdHtqc7iVznPHDS",
        net_leverage: 0.0,
        open_ms: 1711340159360,
        orders: [
          {
            leverage: -1.25,
            order_type: "SHORT",
            order_uuid: "d0707856-49e7-49a0-a24c-41a8370d0fb6",
            price: 67243.78,
            price_sources: [],
            processed_ms: 1711340159358,
          },
          {
            leverage: 0.625,
            order_type: "LONG",
            order_uuid: "ba71db24-e021-405f-80c1-f23646895c61",
            price: 67356.97,
            price_sources: [],
            processed_ms: 1711342005924,
          },
          {
            leverage: 0.125,
            order_type: "LONG",
            order_uuid: "d213c129-cae6-44dc-9367-4df4600e5c05",
            price: 67471.6,
            price_sources: [],
            processed_ms: 1711343817170,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "6dca0b38-6517-4f21-b730-04a2892a3a23",
            price: 67450.0,
            price_sources: [],
            processed_ms: 1711345650265,
          },
          {
            leverage: 0.2,
            order_type: "LONG",
            order_uuid: "e388bc6f-88a9-4beb-8564-2d9e622a0a52",
            price: 67266.79,
            price_sources: [],
            processed_ms: 1711347493142,
          },
          {
            leverage: 0.039999999999999994,
            order_type: "LONG",
            order_uuid: "30afab69-5bf6-479d-a30f-f51b367fa511",
            price: 67072.41,
            price_sources: [],
            processed_ms: 1711349322997,
          },
          {
            leverage: 0.007999999999999997,
            order_type: "LONG",
            order_uuid: "4147a72b-f104-459a-a301-3fd54cdbae4a",
            price: 67071.62,
            price_sources: [],
            processed_ms: 1711351150322,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "9c530fb2-2469-4fb8-9845-f162e71885b0",
            price: 66884.08,
            price_sources: [],
            processed_ms: 1711352985519,
          },
        ],
        position_type: "FLAT",
        position_uuid: "27684609-31a2-495b-a129-bcc5e864cd8c",
        return_at_close: 0.9953278927348376,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 67019.98,
        close_ms: 1711362142073,
        current_return: 1.004201918592038,
        initial_entry_price: 67019.98,
        is_closed_position: true,
        miner_hotkey: "5Gx57AnGeGAzbZCY4aQxtDECcmq1RVLitsdHtqc7iVznPHDS",
        net_leverage: 0.0,
        open_ms: 1711354836762,
        orders: [
          {
            leverage: 1.25,
            order_type: "LONG",
            order_uuid: "d052845c-e828-4b6a-a978-9fcbb5560bf9",
            price: 67019.98,
            price_sources: [],
            processed_ms: 1711354836760,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "5e404254-b660-4fab-baa5-7ebf2e8dbd35",
            price: 67245.27,
            price_sources: [],
            processed_ms: 1711362142073,
          },
        ],
        position_type: "FLAT",
        position_uuid: "fd4a9b4b-980e-4d89-ad76-e7a71659d639",
        return_at_close: 1.001691413795558,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: -920779.8509909902,
        close_ms: 1711383543000,
        current_return: 1.0328238717142273,
        initial_entry_price: 66989.19,
        is_closed_position: true,
        miner_hotkey: "5Gx57AnGeGAzbZCY4aQxtDECcmq1RVLitsdHtqc7iVznPHDS",
        net_leverage: 0.0,
        open_ms: 1711363979223,
        orders: [
          {
            leverage: 1.25,
            order_type: "LONG",
            order_uuid: "2b21c982-2919-4f26-a66d-f4cce12e4822",
            price: 66989.19,
            price_sources: [],
            processed_ms: 1711363979221,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "fdd43638-b732-43ed-b69c-367ea0a9c8a5",
            price: 66848.16,
            price_sources: [],
            processed_ms: 1711366539068,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "a439b707-0ea4-4f8b-9045-25ecfeef1a1e",
            price: 66812.56,
            price_sources: [],
            processed_ms: 1711367679248,
          },
          {
            leverage: -0.875,
            order_type: "SHORT",
            order_uuid: "c0bfeb03-bc27-47e3-b999-ff84f838cff7",
            price: 67150.59,
            price_sources: [],
            processed_ms: 1711369519269,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "292b7af3-9d98-4e5b-90ef-cb6febf8e48b",
            price: 67017.66,
            price_sources: [],
            processed_ms: 1711371350064,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "bdafee5f-0e19-42c9-9a79-a57245a968bc",
            price: 66934.32,
            price_sources: [],
            processed_ms: 1711373175348,
          },
          {
            leverage: -0.6875,
            order_type: "SHORT",
            order_uuid: "22d0811b-3db3-4b08-a6b8-797df3417959",
            price: 67630.76,
            price_sources: [],
            processed_ms: 1711375025482,
          },
          {
            leverage: -0.55,
            order_type: "SHORT",
            order_uuid: "e59d415a-00b9-4231-be6f-600c5931cb3b",
            price: 69141.81,
            price_sources: [],
            processed_ms: 1711376542711,
          },
          {
            leverage: -0.10999999999999996,
            order_type: "SHORT",
            order_uuid: "1df40f8c-e810-407f-b47d-2ce8dea1bd96",
            price: 69358.28,
            price_sources: [],
            processed_ms: 1711376862500,
          },
          {
            leverage: 0.24999999999999997,
            order_type: "LONG",
            order_uuid: "41127aac-fb21-4780-a28d-8ad9db91756f",
            price: 69144.78,
            price_sources: [],
            processed_ms: 1711378014142,
          },
          {
            leverage: -0.22199999999999998,
            order_type: "SHORT",
            order_uuid: "3ddd3d70-2190-48db-85d6-28c2f76b5269",
            price: 69212.28,
            price_sources: [],
            processed_ms: 1711378286913,
          },
          {
            leverage: -0.044399999999999995,
            order_type: "SHORT",
            order_uuid: "f5dddcc7-3a0c-4fbc-9136-37ca99426819",
            price: 69226.32,
            price_sources: [],
            processed_ms: 1711379908430,
          },
          {
            leverage: -0.008879999999999999,
            order_type: "SHORT",
            order_uuid: "65dfdd37-4d44-44cd-a94b-35eea764758a",
            price: 69920.49,
            price_sources: [],
            processed_ms: 1711381750479,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "d4855a0b-97bf-4e0d-b48a-848c0b51ca3b",
            price: 69690.68,
            price_sources: [],
            processed_ms: 1711383543000,
          },
        ],
        position_type: "FLAT",
        position_uuid: "50c23ff0-b874-4726-b17f-9ef6a4158296",
        return_at_close: 1.0292089881632276,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 69746.52,
        close_ms: 1711390134443,
        current_return: 0.9896482360686786,
        initial_entry_price: 70035.89,
        is_closed_position: true,
        miner_hotkey: "5Gx57AnGeGAzbZCY4aQxtDECcmq1RVLitsdHtqc7iVznPHDS",
        net_leverage: 0.0,
        open_ms: 1711385356956,
        orders: [
          {
            leverage: -1.25,
            order_type: "SHORT",
            order_uuid: "4b0ef994-973b-4be6-a81e-145e10967ede",
            price: 70035.89,
            price_sources: [],
            processed_ms: 1711385356954,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "5d01cb3a-d6f5-4a2d-bc09-18e98d64bf69",
            price: 70594.05,
            price_sources: [],
            processed_ms: 1711387194017,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "b3f5883f-d96f-424b-9d8b-ce131df391f3",
            price: 70345.84,
            price_sources: [],
            processed_ms: 1711388997923,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "a0a8153e-580d-4e51-95b2-5df64c119078",
            price: 70713.18,
            price_sources: [],
            processed_ms: 1711390134443,
          },
        ],
        position_type: "FLAT",
        position_uuid: "55a73e93-6ec9-4afa-bc8d-827efaa7f709",
        return_at_close: 0.987174115478507,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 70487.5343902439,
        close_ms: 1711396673207,
        current_return: 0.9655992775480514,
        initial_entry_price: 70559.71,
        is_closed_position: true,
        miner_hotkey: "5Gx57AnGeGAzbZCY4aQxtDECcmq1RVLitsdHtqc7iVznPHDS",
        net_leverage: 0.0,
        open_ms: 1711391928154,
        orders: [
          {
            leverage: -5.0,
            order_type: "SHORT",
            order_uuid: "6287fd87-57fc-4f73-b4f1-f6aea8cced25",
            price: 70559.71,
            price_sources: [],
            processed_ms: 1711391928152,
          },
          {
            leverage: 0.9000000000000004,
            order_type: "LONG",
            order_uuid: "208a3fee-49e6-435c-bb92-f2c251e6be78",
            price: 70888.51,
            price_sources: [],
            processed_ms: 1711396166042,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "56fa7551-2b33-4424-b0e8-d48ba6172580",
            price: 71079.56,
            price_sources: [],
            processed_ms: 1711396673207,
          },
        ],
        position_type: "FLAT",
        position_uuid: "df54bfc1-242b-4c35-be09-d20810f8e6ad",
        return_at_close: 0.9559432847725708,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 74109.38571428572,
        close_ms: 1711403433284,
        current_return: 1.0248912087898134,
        initial_entry_price: 70876.59,
        is_closed_position: true,
        miner_hotkey: "5Gx57AnGeGAzbZCY4aQxtDECcmq1RVLitsdHtqc7iVznPHDS",
        net_leverage: 0.0,
        open_ms: 1711398008577,
        orders: [
          {
            leverage: -3.0,
            order_type: "SHORT",
            order_uuid: "ee3d7243-5966-41f4-b169-a340b1d4cdda",
            price: 70876.59,
            price_sources: [],
            processed_ms: 1711398008575,
          },
          {
            leverage: 0.8999999999999999,
            order_type: "LONG",
            order_uuid: "ec95d829-a244-4390-b1fb-504e262f4df1",
            price: 70837.56,
            price_sources: [],
            processed_ms: 1711399799701,
          },
          {
            leverage: 1.6800000000000002,
            order_type: "LONG",
            order_uuid: "86b331d9-e176-4324-bf5d-def9d9224f3c",
            price: 70089.3,
            price_sources: [],
            processed_ms: 1711401617210,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "6c386b3d-98a6-48a5-a03f-c02e8a940d6f",
            price: 69908.9,
            price_sources: [],
            processed_ms: 1711403433284,
          },
        ],
        position_type: "FLAT",
        position_uuid: "13db8c2d-4591-4080-b9a9-1999ea193bde",
        return_at_close: 1.0187418615370745,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 66694.60695652154,
        close_ms: 1711423410184,
        current_return: 1.0116402346149465,
        initial_entry_price: 70377.23,
        is_closed_position: true,
        miner_hotkey: "5Gx57AnGeGAzbZCY4aQxtDECcmq1RVLitsdHtqc7iVznPHDS",
        net_leverage: 0.0,
        open_ms: 1711405243080,
        orders: [
          {
            leverage: 3.0,
            order_type: "LONG",
            order_uuid: "ddc621a9-de45-4909-b373-20d00eccbc22",
            price: 70377.23,
            price_sources: [],
            processed_ms: 1711405243078,
          },
          {
            leverage: -1.5,
            order_type: "SHORT",
            order_uuid: "1d685aa1-a330-4079-be63-eb9f6d9e2d4a",
            price: 70371.69,
            price_sources: [],
            processed_ms: 1711407084904,
          },
          {
            leverage: -0.75,
            order_type: "SHORT",
            order_uuid: "922d79e3-b226-4376-b44d-c67078bee518",
            price: 70433.13,
            price_sources: [],
            processed_ms: 1711408875550,
          },
          {
            leverage: 0.8999999999999999,
            order_type: "LONG",
            order_uuid: "b139e1c4-f630-487f-8be1-d7af621cd07f",
            price: 69890.81,
            price_sources: [],
            processed_ms: 1711410693122,
          },
          {
            leverage: 0.8999999999999999,
            order_type: "LONG",
            order_uuid: "66196f6e-0c67-456f-a66b-b869a02bb011",
            price: 69899.85,
            price_sources: [],
            processed_ms: 1711412542146,
          },
          {
            leverage: 0.8999999999999999,
            order_type: "LONG",
            order_uuid: "95f79e63-788f-4595-b9bb-ac94ec87632e",
            price: 69992.44,
            price_sources: [],
            processed_ms: 1711414331643,
          },
          {
            leverage: -1.7249999999999999,
            order_type: "SHORT",
            order_uuid: "fde2eb0b-ed41-4ca3-9123-5225ca50358d",
            price: 70208.9,
            price_sources: [],
            processed_ms: 1711416150103,
          },
          {
            leverage: -0.8624999999999999,
            order_type: "SHORT",
            order_uuid: "95b13623-5f45-4117-bc87-2715ee13df7e",
            price: 70233.21,
            price_sources: [],
            processed_ms: 1711417961699,
          },
          {
            leverage: -0.43124999999999997,
            order_type: "SHORT",
            order_uuid: "87c8abc7-dc04-4669-8840-e2d90632e414",
            price: 70290.43,
            price_sources: [],
            processed_ms: 1711419829184,
          },
          {
            leverage: -0.21562499999999998,
            order_type: "SHORT",
            order_uuid: "b1a94731-74d8-45c1-9504-0852a7922545",
            price: 70371.38,
            price_sources: [],
            processed_ms: 1711421611992,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "30771c94-967d-4502-b7bb-26f65f7f7a63",
            price: 70493.83,
            price_sources: [],
            processed_ms: 1711423410184,
          },
        ],
        position_type: "FLAT",
        position_uuid: "766031c9-1012-4d68-b0a0-1f759d9f52bd",
        return_at_close: 1.0046599169961032,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 70268.14000000007,
        close_ms: 1711432497147,
        current_return: 0.9993326735204181,
        initial_entry_price: 70494.73,
        is_closed_position: true,
        miner_hotkey: "5Gx57AnGeGAzbZCY4aQxtDECcmq1RVLitsdHtqc7iVznPHDS",
        net_leverage: 0.0,
        open_ms: 1711425224346,
        orders: [
          {
            leverage: -3.0,
            order_type: "SHORT",
            order_uuid: "17ed1068-877b-4c41-b774-ed5a819ffd39",
            price: 70494.73,
            price_sources: [],
            processed_ms: 1711425224343,
          },
          {
            leverage: 0.8999999999999999,
            order_type: "LONG",
            order_uuid: "1ff5e60d-4bd3-4d7f-aded-8f5d7fe32da4",
            price: 70526.99,
            price_sources: [],
            processed_ms: 1711427044695,
          },
          {
            leverage: 0.8999999999999999,
            order_type: "LONG",
            order_uuid: "4cd1c575-17f4-4134-b570-0f4d864b476c",
            price: 70575.26,
            price_sources: [],
            processed_ms: 1711428865267,
          },
          {
            leverage: 0.8999999999999999,
            order_type: "LONG",
            order_uuid: "00c843f3-f99b-4c8b-b856-7062d28322ed",
            price: 70457.47,
            price_sources: [],
            processed_ms: 1711430678143,
          },
          {
            leverage: 0.8999999999999999,
            order_type: "LONG",
            order_uuid: "c6830e4a-6417-4667-89e2-721a69e37a38",
            price: 70424.95,
            price_sources: [],
            processed_ms: 1711432497147,
          },
        ],
        position_type: "FLAT",
        position_uuid: "8c3fc027-5d9d-4589-b805-2d0a7ede3176",
        return_at_close: 0.9933366774792955,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 70468.05,
        close_ms: 1711437910427,
        current_return: 0.9626217839148384,
        initial_entry_price: 70468.05,
        is_closed_position: true,
        miner_hotkey: "5Gx57AnGeGAzbZCY4aQxtDECcmq1RVLitsdHtqc7iVznPHDS",
        net_leverage: 0.0,
        open_ms: 1711436130486,
        orders: [
          {
            leverage: -3.0,
            order_type: "SHORT",
            order_uuid: "b61c1608-c870-4f70-8261-f744f63df732",
            price: 70468.05,
            price_sources: [],
            processed_ms: 1711436130483,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "b1f2d17e-a561-4e36-8ea4-1dc373962884",
            price: 71346.04,
            price_sources: [],
            processed_ms: 1711437910427,
          },
        ],
        position_type: "FLAT",
        position_uuid: "97aa39d3-2c4c-44d3-8a00-4a4d30f1936a",
        return_at_close: 0.9568460532113494,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 73939.84999999999,
        close_ms: 1711441596499,
        current_return: 1.0275031437431974,
        initial_entry_price: 71324.21,
        is_closed_position: true,
        miner_hotkey: "5Gx57AnGeGAzbZCY4aQxtDECcmq1RVLitsdHtqc7iVznPHDS",
        net_leverage: 0.0,
        open_ms: 1711438070491,
        orders: [
          {
            leverage: -3.0,
            order_type: "SHORT",
            order_uuid: "8a671e7f-27ea-4998-9a2f-9b8c0f233dbc",
            price: 71324.21,
            price_sources: [],
            processed_ms: 1711438070488,
          },
          {
            leverage: 2.4,
            order_type: "LONG",
            order_uuid: "adbf6a31-d2b5-4d52-8b87-3e14f5cd3a5d",
            price: 70670.3,
            price_sources: [],
            processed_ms: 1711439756777,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "08c6ec0c-171e-4f5f-ac94-0404669e314f",
            price: 70670.45,
            price_sources: [],
            processed_ms: 1711441596499,
          },
        ],
        position_type: "FLAT",
        position_uuid: "d844ad33-f2c1-4488-b063-e7b44f2ea3fa",
        return_at_close: 1.0213381248807383,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 71444.35000000008,
        close_ms: 1711454287544,
        current_return: 1.0027681924035288,
        initial_entry_price: 71058.78,
        is_closed_position: true,
        miner_hotkey: "5Gx57AnGeGAzbZCY4aQxtDECcmq1RVLitsdHtqc7iVznPHDS",
        net_leverage: 0.0,
        open_ms: 1711445209649,
        orders: [
          {
            leverage: -3.0,
            order_type: "SHORT",
            order_uuid: "a52c1992-2e29-4cc9-bef7-c0295fd7aaf7",
            price: 71058.78,
            price_sources: [],
            processed_ms: 1711445209644,
          },
          {
            leverage: 0.8999999999999999,
            order_type: "LONG",
            order_uuid: "749e3880-4490-417a-9702-be08a50cefe8",
            price: 71202.84,
            price_sources: [],
            processed_ms: 1711447018341,
          },
          {
            leverage: 1.05,
            order_type: "LONG",
            order_uuid: "e274be54-a777-4efa-be9a-e203de186704",
            price: 70959.54,
            price_sources: [],
            processed_ms: 1711448837088,
          },
          {
            leverage: 0.525,
            order_type: "LONG",
            order_uuid: "a3f06135-0602-45e1-9ed1-841b944a329f",
            price: 70944.87,
            price_sources: [],
            processed_ms: 1711450653118,
          },
          {
            leverage: 0.2625,
            order_type: "LONG",
            order_uuid: "d6325688-6e41-4646-b7ac-fe52ec58e045",
            price: 70804.07,
            price_sources: [],
            processed_ms: 1711452475975,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "86709f85-ae05-472a-83e4-c20d8b5587b1",
            price: 70695.0,
            price_sources: [],
            processed_ms: 1711454287544,
          },
        ],
        position_type: "FLAT",
        position_uuid: "b20d0148-82ab-4797-be1f-3cd12b5c7028",
        return_at_close: 0.9967515832491076,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 70691.64538461537,
        close_ms: 1711463230072,
        current_return: 0.955513838811058,
        initial_entry_price: 70784.26,
        is_closed_position: true,
        miner_hotkey: "5Gx57AnGeGAzbZCY4aQxtDECcmq1RVLitsdHtqc7iVznPHDS",
        net_leverage: 0.0,
        open_ms: 1711459730255,
        orders: [
          {
            leverage: 3.0,
            order_type: "LONG",
            order_uuid: "617e295f-4d1a-4162-86b3-608c417baff1",
            price: 70784.26,
            price_sources: [],
            processed_ms: 1711459730252,
          },
          {
            leverage: 0.8999999999999999,
            order_type: "LONG",
            order_uuid: "9a6df70f-711e-4f5e-af2f-417ccfabd3f7",
            price: 70382.93,
            price_sources: [],
            processed_ms: 1711461552216,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "629d6fce-c90a-433a-ae38-ec4fcb3dce1d",
            price: 69884.23,
            price_sources: [],
            processed_ms: 1711463230072,
          },
        ],
        position_type: "FLAT",
        position_uuid: "b2d77ca5-ebde-406d-959c-f9ba3fe9a4c0",
        return_at_close: 0.9480608308683317,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 70028.85545454548,
        close_ms: 1711471089618,
        current_return: 0.9693708934137022,
        initial_entry_price: 69870.99,
        is_closed_position: true,
        miner_hotkey: "5Gx57AnGeGAzbZCY4aQxtDECcmq1RVLitsdHtqc7iVznPHDS",
        net_leverage: 0.0,
        open_ms: 1711463367022,
        orders: [
          {
            leverage: 3.0,
            order_type: "LONG",
            order_uuid: "b5af38ce-a048-497d-b42f-302f1a92337c",
            price: 69870.99,
            price_sources: [],
            processed_ms: 1711463367020,
          },
          {
            leverage: 0.8999999999999999,
            order_type: "LONG",
            order_uuid: "959aa6f4-e041-46be-aab3-7f718cc65876",
            price: 70306.66,
            price_sources: [],
            processed_ms: 1711465189684,
          },
          {
            leverage: 0.8999999999999999,
            order_type: "LONG",
            order_uuid: "8771b909-782c-4f54-9df7-a8af14c5bc64",
            price: 70204.14,
            price_sources: [],
            processed_ms: 1711467001886,
          },
          {
            leverage: 0.8999999999999995,
            order_type: "LONG",
            order_uuid: "c2370c7e-7c0e-41d7-8fc2-e3b668fd8b3b",
            price: 70231.27,
            price_sources: [],
            processed_ms: 1711468819085,
          },
          {
            leverage: 0.9000000000000004,
            order_type: "LONG",
            order_uuid: "18a702c6-a6d9-4551-a35b-823cb8a904fe",
            price: 69899.57,
            price_sources: [],
            processed_ms: 1711470639161,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "211df93e-9200-4421-a1ba-c5ba2b54430d",
            price: 69704.6,
            price_sources: [],
            processed_ms: 1711471089618,
          },
        ],
        position_type: "FLAT",
        position_uuid: "73f34c10-e6ef-41f3-809a-2977a2ea3bf5",
        return_at_close: 0.9565751976206414,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
    ],
    thirty_day_returns: 1.0,
  },
  "5GxUMPC6UXYtEAJudz4zw2C1MRfSPrWzWxT22UeXCQ8fUxoq": {
    all_time_returns: 1.034669055094771,
    n_positions: 3,
    percentage_profitable: 1.0,
    positions: [
      {
        average_entry_price: 67681.00744379684,
        close_ms: 1718195471916,
        current_return: 1.028119699137906,
        initial_entry_price: 67182.94,
        is_closed_position: true,
        miner_hotkey: "5GxUMPC6UXYtEAJudz4zw2C1MRfSPrWzWxT22UeXCQ8fUxoq",
        net_leverage: 0.0,
        open_ms: 1716112667048,
        orders: [
          {
            leverage: 0.001,
            order_type: "LONG",
            order_uuid: "f7e4bd3f-c786-4125-a411-05e9913f13ee",
            price: 67182.94,
            price_sources: [],
            processed_ms: 1716112665495,
          },
          {
            leverage: 0.1,
            order_type: "LONG",
            order_uuid: "3dd25474-5a69-4a2c-8dc1-44cea4f469cf",
            price: 66271.47,
            price_sources: [],
            processed_ms: 1716161746676,
          },
          {
            leverage: 1.1,
            order_type: "LONG",
            order_uuid: "ba3fbd8a-5e73-43a3-b423-fff4f2752633",
            price: 67809.6,
            price_sources: [],
            processed_ms: 1718194279326,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "39839be6-dfea-4f7a-90ec-7c614a32c439",
            price: 69254.0,
            price_sources: [],
            processed_ms: 1718195471916,
          },
        ],
        position_type: "FLAT",
        position_uuid: "7eb12bf2-1c6c-4e54-89dc-aad4acf73e59",
        return_at_close: 1.0268849273792413,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 160.15065384615386,
        close_ms: 1719577801037,
        current_return: 1.0070099350401223,
        initial_entry_price: 157.02,
        is_closed_position: true,
        miner_hotkey: "5GxUMPC6UXYtEAJudz4zw2C1MRfSPrWzWxT22UeXCQ8fUxoq",
        net_leverage: 0.0,
        open_ms: 1718283943441,
        orders: [
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "ed226e1f-0d4d-487b-826d-6f049d4ca943",
            price: 157.02,
            price_sources: [],
            processed_ms: 1718283943428,
          },
          {
            leverage: 2.3,
            order_type: "LONG",
            order_uuid: "92d5704c-e199-4f1f-98c1-edb6196bc5d9",
            price: 160.559,
            price_sources: [],
            processed_ms: 1719577761499,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "125041cc-6f15-485e-bcab-6af1683b7cd0",
            price: 160.574,
            price_sources: [],
            processed_ms: 1719577801037,
          },
        ],
        position_type: "FLAT",
        position_uuid: "c5eadb53-357a-4cab-bb86-d176026133d5",
        return_at_close: 1.006826659231945,
        trade_pair: ["USDJPY", "USD/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 160.546,
        close_ms: 1719578398316,
        current_return: 1.0009097081210365,
        initial_entry_price: 160.546,
        is_closed_position: true,
        miner_hotkey: "5GxUMPC6UXYtEAJudz4zw2C1MRfSPrWzWxT22UeXCQ8fUxoq",
        net_leverage: 0.0,
        open_ms: 1719577970214,
        orders: [
          {
            leverage: -2.3,
            order_type: "SHORT",
            order_uuid: "7a8da72a-5108-49c0-b9a5-d1c8addc61e1",
            price: 160.546,
            price_sources: [],
            processed_ms: 1719577970207,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "74e48c33-3219-43a6-b474-3600d2fa14be",
            price: 160.48250000000002,
            price_sources: [],
            processed_ms: 1719578398316,
          },
        ],
        position_type: "FLAT",
        position_uuid: "1e4e39b4-208a-4321-8344-4fe5d737fc6c",
        return_at_close: 1.0007485616580292,
        trade_pair: ["USDJPY", "USD/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 67775.65,
        close_ms: 0,
        current_return: 0.9573357835741892,
        initial_entry_price: 67775.65,
        is_closed_position: false,
        miner_hotkey: "5GxUMPC6UXYtEAJudz4zw2C1MRfSPrWzWxT22UeXCQ8fUxoq",
        net_leverage: 0.3,
        open_ms: 1718283855392,
        orders: [
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "f76cbd6b-284a-4acf-a1a9-c3696ca229f5",
            price: 67775.65,
            price_sources: [],
            processed_ms: 1718283853395,
          },
        ],
        position_type: "LONG",
        position_uuid: "765e42bd-32a1-4d92-833f-1968b21f7d14",
        return_at_close: 0.9571921832066531,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
    ],
    thirty_day_returns: 1.0075803310653277,
  },
  "5GzYKUYSD5d7TJfK4jsawtmS2bZDgFuUYw8kdLdnEDxSykTU": {
    all_time_returns: 1.014531342118389,
    n_positions: 1,
    percentage_profitable: 1.0,
    positions: [
      {
        average_entry_price: 63454.35,
        close_ms: 1716116534717,
        current_return: 1.0147850383779835,
        initial_entry_price: 63454.35,
        is_closed_position: true,
        miner_hotkey: "5GzYKUYSD5d7TJfK4jsawtmS2bZDgFuUYw8kdLdnEDxSykTU",
        net_leverage: 0.0,
        open_ms: 1713211374195,
        orders: [
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "fb26dd8b-e1ad-4e0e-abcf-afee63368d37",
            price: 63454.35,
            price_sources: [],
            processed_ms: 1713211374195,
          },
          {
            leverage: 0.2,
            order_type: "FLAT",
            order_uuid: "d739ad7b-adbb-4398-8a2c-2242cb502f0d",
            price: 67207.05,
            price_sources: [],
            processed_ms: 1716116534717,
          },
        ],
        position_type: "FLAT",
        position_uuid: "831ca717-a7f0-4c6c-8aa6-16d6f6b992e2",
        return_at_close: 1.014531342118389,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 3108.83,
        close_ms: 0,
        current_return: 1.0022613008752488,
        initial_entry_price: 3108.83,
        is_closed_position: false,
        miner_hotkey: "5GzYKUYSD5d7TJfK4jsawtmS2bZDgFuUYw8kdLdnEDxSykTU",
        net_leverage: 0.25,
        open_ms: 1713211373638,
        orders: [
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "ab7e8f3d-579b-4299-854d-55d42e4987b3",
            price: 3108.83,
            price_sources: [],
            processed_ms: 1713211373638,
          },
        ],
        position_type: "LONG",
        position_uuid: "3e9d4f41-2b6b-4630-9cb5-1fe77b25abac",
        return_at_close: 1.0021360182126393,
        trade_pair: ["ETHUSD", "ETH/USD", 0.003, 0.001, 20],
      },
    ],
    thirty_day_returns: 1.0,
  },
  "5H1DHYFbj9KVTqjtwzibVQAwNHcxbiPp1FuS9CReUqHiDMc2": {
    all_time_returns: 1.0,
    n_positions: 0,
    percentage_profitable: 0.0,
    positions: [
      {
        average_entry_price: 69666.45950000003,
        close_ms: 1711843228465,
        current_return: 0.9881653948066887,
        initial_entry_price: 66904.64,
        is_closed_position: true,
        miner_hotkey: "5H1DHYFbj9KVTqjtwzibVQAwNHcxbiPp1FuS9CReUqHiDMc2",
        net_leverage: 20.0,
        open_ms: 1711321047640,
        orders: [
          {
            leverage: 0.1,
            order_type: "LONG",
            order_uuid: "c2c29bf6-0099-4f5b-b41d-a421f97f8779",
            price: 66904.64,
            price_sources: [],
            processed_ms: 1711321047639,
          },
          {
            leverage: 0.1,
            order_type: "LONG",
            order_uuid: "d646bd2b-1b09-402a-ac26-804436bf4e75",
            price: 66904.64,
            price_sources: [],
            processed_ms: 1711321073644,
          },
          {
            leverage: 0.1,
            order_type: "LONG",
            order_uuid: "c8f78860-ee3e-4a8e-9f7f-6463dd66effb",
            price: 67241.75,
            price_sources: [],
            processed_ms: 1711323157004,
          },
          {
            leverage: -0.1,
            order_type: "SHORT",
            order_uuid: "22fca78e-0a63-4b97-9567-9c03d9adf8c2",
            price: 67212.1,
            price_sources: [],
            processed_ms: 1711323244716,
          },
          {
            leverage: 1.0,
            order_type: "LONG",
            order_uuid: "3b286078-d2ca-40a8-b82a-08e43342efd4",
            price: 67352.21,
            price_sources: [],
            processed_ms: 1711339218922,
          },
          {
            leverage: -1.0,
            order_type: "SHORT",
            order_uuid: "65bd3a62-f661-4482-a99d-0d46308198a3",
            price: 67174.42,
            price_sources: [],
            processed_ms: 1711350017387,
          },
          {
            leverage: 1.0,
            order_type: "LONG",
            order_uuid: "13d1aec6-936f-48e3-a358-473d6b8e64c4",
            price: 67220.6,
            price_sources: [],
            processed_ms: 1711362612271,
          },
          {
            leverage: -1.0,
            order_type: "SHORT",
            order_uuid: "24e2533c-dcce-4ec6-92fa-74b07140fd32",
            price: 67027.62,
            price_sources: [],
            processed_ms: 1711364427510,
          },
          {
            leverage: 1.0,
            order_type: "LONG",
            order_uuid: "1e3d27f1-7bc9-4359-92d9-69313dd86a31",
            price: 67131.99,
            price_sources: [],
            processed_ms: 1711369819456,
          },
          {
            leverage: -1.0,
            order_type: "SHORT",
            order_uuid: "76418572-5773-46c1-8e32-3c1243ae8b0f",
            price: 66938.32,
            price_sources: [],
            processed_ms: 1711373434606,
          },
          {
            leverage: 1.0,
            order_type: "LONG",
            order_uuid: "ae5b5a77-5fa0-42fc-8d3d-368573679299",
            price: 68029.3,
            price_sources: [],
            processed_ms: 1711375222531,
          },
          {
            leverage: -0.3,
            order_type: "SHORT",
            order_uuid: "bbf5c038-89d9-47b4-9eef-cbc014915c95",
            price: 69623.76,
            price_sources: [],
            processed_ms: 1711402219000,
          },
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "f0ed2ab5-f0a8-4c15-95fe-df44255ff4c1",
            price: 70283.8,
            price_sources: [],
            processed_ms: 1711416634748,
          },
          {
            leverage: -0.3,
            order_type: "SHORT",
            order_uuid: "ab545d28-2b30-4e78-8b75-23f343e49a92",
            price: 70384.99,
            price_sources: [],
            processed_ms: 1711431015729,
          },
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "fb38023d-44eb-47ba-bf7a-2c2cb1e95cb4",
            price: 70628.88,
            price_sources: [],
            processed_ms: 1711436412594,
          },
          {
            leverage: -0.3,
            order_type: "SHORT",
            order_uuid: "6f0e679d-66cf-4d68-9405-17b4869849cb",
            price: 70552.63,
            price_sources: [],
            processed_ms: 1711440024834,
          },
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "87fb89d7-3aa0-47fb-92a5-f0f3f2e1928c",
            price: 70656.63,
            price_sources: [],
            processed_ms: 1711443620362,
          },
          {
            leverage: -0.3,
            order_type: "SHORT",
            order_uuid: "0d8529e3-ad34-4349-9109-3408f58325b5",
            price: 70759.97,
            price_sources: [],
            processed_ms: 1711452618938,
          },
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "b72928cb-268e-4d9d-bfbf-e9c7e138d68b",
            price: 71021.57,
            price_sources: [],
            processed_ms: 1711458018829,
          },
          {
            leverage: -0.3,
            order_type: "SHORT",
            order_uuid: "203da68c-a7fd-41a5-a611-e1b261a27fd2",
            price: 70810.59,
            price_sources: [],
            processed_ms: 1711459825442,
          },
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "69367368-4fc2-46db-9b8a-cc14289faa5d",
            price: 70061.89,
            price_sources: [],
            processed_ms: 1711476019420,
          },
          {
            leverage: -0.3,
            order_type: "SHORT",
            order_uuid: "b2c72ab5-dd9d-4564-a18b-be56b1d6f942",
            price: 70088.88,
            price_sources: [],
            processed_ms: 1711479617844,
          },
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "b46c9fec-b625-4195-b656-108aa07c99f8",
            price: 70105.44,
            price_sources: [],
            processed_ms: 1711488617577,
          },
          {
            leverage: -0.3,
            order_type: "SHORT",
            order_uuid: "16b09eff-3807-442b-b393-e2c9855694a1",
            price: 69895.23,
            price_sources: [],
            processed_ms: 1711492212650,
          },
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "b86ceff1-92e5-421c-a17f-f9b7f7706d47",
            price: 70250.86,
            price_sources: [],
            processed_ms: 1711494022485,
          },
          {
            leverage: -0.3,
            order_type: "SHORT",
            order_uuid: "e5425417-7d9b-4b7f-8a8b-2a9a5240bedc",
            price: 70370.22,
            price_sources: [],
            processed_ms: 1711513812589,
          },
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "d4d432d6-95c5-4c5c-b097-dce70931bb45",
            price: 70478.82,
            price_sources: [],
            processed_ms: 1711519216193,
          },
          {
            leverage: -0.3,
            order_type: "SHORT",
            order_uuid: "c801ba0e-8ad8-4471-ad19-fa993e67e460",
            price: 70358.63,
            price_sources: [],
            processed_ms: 1711521020104,
          },
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "cb68307f-962e-45c6-bef1-73dab15d1a7d",
            price: 70023.9,
            price_sources: [],
            processed_ms: 1711533626454,
          },
          {
            leverage: -0.3,
            order_type: "SHORT",
            order_uuid: "6204800a-75a8-4cfd-be40-c9bc4bcb3681",
            price: 69986.81,
            price_sources: [],
            processed_ms: 1711548017784,
          },
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "182e327c-bbaf-4ac0-94d0-08e942a6334a",
            price: 68912.32,
            price_sources: [],
            processed_ms: 1711571417668,
          },
          {
            leverage: -0.3,
            order_type: "SHORT",
            order_uuid: "2c2f111e-8547-4437-9657-30ad22d430a2",
            price: 69405.57,
            price_sources: [],
            processed_ms: 1711593016540,
          },
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "271f9497-f315-41a0-99b1-eadc999389a8",
            price: 69600.1,
            price_sources: [],
            processed_ms: 1711600216435,
          },
          {
            leverage: -0.3,
            order_type: "SHORT",
            order_uuid: "58a1d590-bb44-4949-bef0-0b9201b48412",
            price: 70373.58,
            price_sources: [],
            processed_ms: 1711621815039,
          },
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "4a1aaf47-8d7d-4a8d-8176-5958bc63dc53",
            price: 70739.27,
            price_sources: [],
            processed_ms: 1711623622876,
          },
          {
            leverage: -0.3,
            order_type: "SHORT",
            order_uuid: "9217b196-cdec-4d46-8636-22e27aa0c076",
            price: 70514.35,
            price_sources: [],
            processed_ms: 1711629014647,
          },
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "a801106a-6839-404c-a82c-dabcc23739ff",
            price: 70680.01,
            price_sources: [],
            processed_ms: 1711630824478,
          },
          {
            leverage: -0.3,
            order_type: "SHORT",
            order_uuid: "18f36c73-b170-444b-adb4-139381653076",
            price: 70939.39,
            price_sources: [],
            processed_ms: 1711643418938,
          },
          {
            leverage: 19.1,
            order_type: "LONG",
            order_uuid: "931db8a5-0e1e-4568-bbd3-32d5e58703bf",
            price: 69491.72,
            price_sources: [],
            processed_ms: 1711738848424,
          },
          {
            leverage: -19.9,
            order_type: "SHORT",
            order_uuid: "262b18e9-aeba-4cfa-81bb-c75b0a97b7c7",
            price: 69514.62,
            price_sources: [],
            processed_ms: 1711747819450,
          },
          {
            leverage: 19.9,
            order_type: "LONG",
            order_uuid: "8c3a200c-7da6-49eb-8eb5-416dfd68f7cd",
            price: 69778.24,
            price_sources: [],
            processed_ms: 1711751417427,
          },
        ],
        position_type: "FLAT",
        position_uuid: "75bd5b65-29dc-48a1-a5d3-6457c1a289cb",
        return_at_close: 0.9486387790144212,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
    ],
    thirty_day_returns: 1.0,
  },
  "5H3xb7TkddmLQZ3r3igrZkTVC1uRqs7e41n2R6pP9K88b3P5": {
    all_time_returns: 1.0,
    n_positions: 0,
    percentage_profitable: 0.0,
    positions: [
      {
        average_entry_price: 160.8201489361702,
        close_ms: 0,
        current_return: 0.9974230814717068,
        initial_entry_price: 160.65699999999998,
        is_closed_position: false,
        miner_hotkey: "5H3xb7TkddmLQZ3r3igrZkTVC1uRqs7e41n2R6pP9K88b3P5",
        net_leverage: -0.47,
        open_ms: 1719501525958,
        orders: [
          {
            leverage: -0.35,
            order_type: "SHORT",
            order_uuid: "fabc0ee6-9550-47bf-996f-3b97ea4787af",
            price: 160.65699999999998,
            price_sources: [],
            processed_ms: 1719501524870,
          },
          {
            leverage: -0.12,
            order_type: "SHORT",
            order_uuid: "2af705b7-ea6d-41b1-9859-55f159a9692b",
            price: 161.296,
            price_sources: [],
            processed_ms: 1719842562819,
          },
        ],
        position_type: "SHORT",
        position_uuid: "7fde2b40-0cf2-4fb9-a263-48205fbd002f",
        return_at_close: 0.9974066738620166,
        trade_pair: ["USDJPY", "USD/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 3378.7,
        close_ms: 0,
        current_return: 0.998926732175097,
        initial_entry_price: 3378.7,
        is_closed_position: false,
        miner_hotkey: "5H3xb7TkddmLQZ3r3igrZkTVC1uRqs7e41n2R6pP9K88b3P5",
        net_leverage: 0.015,
        open_ms: 1719611084717,
        orders: [
          {
            leverage: 0.015,
            order_type: "LONG",
            order_uuid: "25a3eb36-790e-40d7-8006-a7e4afc03127",
            price: 3378.7,
            price_sources: [],
            processed_ms: 1719611084148,
          },
        ],
        position_type: "LONG",
        position_uuid: "35ae635a-579e-48e7-b7da-161c3a2b2bf3",
        return_at_close: 0.9989192402246057,
        trade_pair: ["ETHUSD", "ETH/USD", 0.003, 0.001, 20],
      },
    ],
    thirty_day_returns: 1.0,
  },
  "5H67WyekqLpuXAd4PC6AsnzybFkqK8Apj2dptZ7GQqLcDimc": {
    all_time_returns: 1.0169121323707575,
    n_positions: 7,
    percentage_profitable: 1.0,
    positions: [
      {
        average_entry_price: 60319.30818181819,
        close_ms: 1719670684365,
        current_return: 1.00470744469097,
        initial_entry_price: 60286.72,
        is_closed_position: true,
        miner_hotkey: "5H67WyekqLpuXAd4PC6AsnzybFkqK8Apj2dptZ7GQqLcDimc",
        net_leverage: 0.0,
        open_ms: 1719616888423,
        orders: [
          {
            leverage: 0.08,
            order_type: "LONG",
            order_uuid: "ccbc26bc-cffb-4dbe-a789-d3539dc84104",
            price: 60286.72,
            price_sources: [],
            processed_ms: 1719616887331,
          },
          {
            leverage: 0.18,
            order_type: "LONG",
            order_uuid: "a7c7c119-e22d-4d67-8115-2765a11a5cde",
            price: 60291.55,
            price_sources: [],
            processed_ms: 1719617680323,
          },
          {
            leverage: 0.18,
            order_type: "LONG",
            order_uuid: "d245f1e4-3647-48ca-b269-64879b4dfe5e",
            price: 60361.55,
            price_sources: [],
            processed_ms: 1719620158380,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "c43a890c-e132-4b3a-8b60-76050dd058ef",
            price: 60964.3,
            price_sources: [],
            processed_ms: 1719670684365,
          },
        ],
        position_type: "FLAT",
        position_uuid: "ccbc26bc-cffb-4dbe-a789-d3539dc84104",
        return_at_close: 1.004265373415306,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 3341.0324999999993,
        close_ms: 1719797179959,
        current_return: 1.009014881409159,
        initial_entry_price: 3388.12,
        is_closed_position: true,
        miner_hotkey: "5H67WyekqLpuXAd4PC6AsnzybFkqK8Apj2dptZ7GQqLcDimc",
        net_leverage: 0.0,
        open_ms: 1719676599463,
        orders: [
          {
            leverage: 0.1,
            order_type: "LONG",
            order_uuid: "f9b90a11-872f-4ee8-a7a4-3804fefc839a",
            price: 3388.12,
            price_sources: [],
            processed_ms: 1719676597988,
          },
          {
            leverage: 0.1,
            order_type: "LONG",
            order_uuid: "bc817a83-2fa8-4a3e-8ee7-b91d39833e72",
            price: 3378.13,
            price_sources: [],
            processed_ms: 1719707112968,
          },
          {
            leverage: 0.1,
            order_type: "LONG",
            order_uuid: "f8cde83a-3070-440f-b488-beeec5ce549c",
            price: 3370.0,
            price_sources: [],
            processed_ms: 1719713316437,
          },
          {
            leverage: -0.15,
            order_type: "SHORT",
            order_uuid: "f9ffade8-33c7-48cd-a78c-0515f1571459",
            price: 3376.95,
            price_sources: [],
            processed_ms: 1719751116596,
          },
          {
            leverage: 0.15,
            order_type: "LONG",
            order_uuid: "d9880c09-c6d3-424f-bfd8-26a771ea962c",
            price: 3393.0,
            price_sources: [],
            processed_ms: 1719762665499,
          },
          {
            leverage: -0.1,
            order_type: "SHORT",
            order_uuid: "4426a392-6631-4804-a1ea-07a59907c1b6",
            price: 3478.26,
            price_sources: [],
            processed_ms: 1719796473906,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "1f5b0e92-0025-4282-a921-71dd22dbfdc9",
            price: 3493.75,
            price_sources: [],
            processed_ms: 1719797179959,
          },
        ],
        position_type: "FLAT",
        position_uuid: "f9b90a11-872f-4ee8-a7a4-3804fefc839a",
        return_at_close: 1.008560824712525,
        trade_pair: ["ETHUSD", "ETH/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 3509.726666666666,
        close_ms: 1719805251458,
        current_return: 1.0013697770475343,
        initial_entry_price: 3505.68,
        is_closed_position: true,
        miner_hotkey: "5H67WyekqLpuXAd4PC6AsnzybFkqK8Apj2dptZ7GQqLcDimc",
        net_leverage: 0.0,
        open_ms: 1719798793221,
        orders: [
          {
            leverage: -0.1,
            order_type: "SHORT",
            order_uuid: "f7643d3c-489c-4ceb-849c-f1d417f7e81b",
            price: 3505.68,
            price_sources: [],
            processed_ms: 1719798791498,
          },
          {
            leverage: -0.1,
            order_type: "SHORT",
            order_uuid: "9c39918e-fa4d-4419-a43b-ccf3bc1dd8c6",
            price: 3510.3,
            price_sources: [],
            processed_ms: 1719799007080,
          },
          {
            leverage: -0.1,
            order_type: "SHORT",
            order_uuid: "e8052fd9-2ba7-44d0-9535-a70942375344",
            price: 3513.2,
            price_sources: [],
            processed_ms: 1719799162596,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "88eb76fe-56c3-4e3c-a1eb-9b0a91461efc",
            price: 3493.72,
            price_sources: [],
            processed_ms: 1719805251458,
          },
        ],
        position_type: "FLAT",
        position_uuid: "f7643d3c-489c-4ceb-849c-f1d417f7e81b",
        return_at_close: 1.00106936611442,
        trade_pair: ["ETHUSD", "ETH/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 1.0748925,
        close_ms: 1719839672808,
        current_return: 1.000100009303191,
        initial_entry_price: 1.0749,
        is_closed_position: true,
        miner_hotkey: "5H67WyekqLpuXAd4PC6AsnzybFkqK8Apj2dptZ7GQqLcDimc",
        net_leverage: 0.0,
        open_ms: 1719796515251,
        orders: [
          {
            leverage: 0.1,
            order_type: "LONG",
            order_uuid: "6349b20f-f3ea-4ada-91b3-1b31cff4bd1b",
            price: 1.0749,
            price_sources: [],
            processed_ms: 1719796515246,
          },
          {
            leverage: 0.1,
            order_type: "LONG",
            order_uuid: "0ac866a0-a004-4b1c-b6cf-bc6ebb0c837d",
            price: 1.074885,
            price_sources: [],
            processed_ms: 1719796671407,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "a3786964-6e55-44c2-9ada-1f0922869025",
            price: 1.07543,
            price_sources: [],
            processed_ms: 1719839672808,
          },
        ],
        position_type: "FLAT",
        position_uuid: "6349b20f-f3ea-4ada-91b3-1b31cff4bd1b",
        return_at_close: 1.000086007903061,
        trade_pair: ["EURUSD", "EUR/USD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 1.072623333333333,
        close_ms: 1719864489203,
        current_return: 1.000719934347956,
        initial_entry_price: 1.07232,
        is_closed_position: true,
        miner_hotkey: "5H67WyekqLpuXAd4PC6AsnzybFkqK8Apj2dptZ7GQqLcDimc",
        net_leverage: 0.0,
        open_ms: 1719848716051,
        orders: [
          {
            leverage: 0.2,
            order_type: "LONG",
            order_uuid: "c02751b6-2255-4202-8e1b-d2d5b3965d0a",
            price: 1.07232,
            price_sources: [],
            processed_ms: 1719848716040,
          },
          {
            leverage: 0.2,
            order_type: "LONG",
            order_uuid: "cd59a9b7-23a6-4057-9377-7608204e21e5",
            price: 1.07253,
            price_sources: [],
            processed_ms: 1719850012163,
          },
          {
            leverage: 0.2,
            order_type: "LONG",
            order_uuid: "315db3e9-11c7-49bc-beb1-4b889d512ab3",
            price: 1.07302,
            price_sources: [],
            processed_ms: 1719850629370,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "5c48ae82-ebee-49fe-aa72-567e86a2895c",
            price: 1.07391,
            price_sources: [],
            processed_ms: 1719864489203,
          },
        ],
        position_type: "FLAT",
        position_uuid: "c02751b6-2255-4202-8e1b-d2d5b3965d0a",
        return_at_close: 1.0006779041107134,
        trade_pair: ["EURUSD", "EUR/USD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 3466.6200000000003,
        close_ms: 1719873205060,
        current_return: 1.0009631860428891,
        initial_entry_price: 3466.62,
        is_closed_position: true,
        miner_hotkey: "5H67WyekqLpuXAd4PC6AsnzybFkqK8Apj2dptZ7GQqLcDimc",
        net_leverage: 0.0,
        open_ms: 1719847591384,
        orders: [
          {
            leverage: -0.1,
            order_type: "SHORT",
            order_uuid: "b4e286fd-6abc-4b52-a5b4-e41f95f6b7b6",
            price: 3466.62,
            price_sources: [],
            processed_ms: 1719847589772,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "d23e5ba2-b5e9-46ce-a6b5-0e5f5bc73b81",
            price: 3433.23,
            price_sources: [],
            processed_ms: 1719873205060,
          },
        ],
        position_type: "FLAT",
        position_uuid: "b4e286fd-6abc-4b52-a5b4-e41f95f6b7b6",
        return_at_close: 1.000863089724285,
        trade_pair: ["ETHUSD", "ETH/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 63211.409999999974,
        close_ms: 1719873462352,
        current_return: 1.0018968092113092,
        initial_entry_price: 63009.5,
        is_closed_position: true,
        miner_hotkey: "5H67WyekqLpuXAd4PC6AsnzybFkqK8Apj2dptZ7GQqLcDimc",
        net_leverage: 0.0,
        open_ms: 1719853864896,
        orders: [
          {
            leverage: -0.3,
            order_type: "SHORT",
            order_uuid: "0e10aca2-e4bd-46bd-a964-aa069e6da8b2",
            price: 63009.5,
            price_sources: [],
            processed_ms: 1719853863234,
          },
          {
            leverage: -0.3,
            order_type: "SHORT",
            order_uuid: "e2224eff-4823-4d85-932b-97b7680acf0f",
            price: 63647.35,
            price_sources: [],
            processed_ms: 1719857016434,
          },
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "e761955d-3bfe-4cee-b82e-3c593136009e",
            price: 63445.44,
            price_sources: [],
            processed_ms: 1719858727279,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "c1f2a6e9-74c9-4df2-8ffe-afd0c906fcbb",
            price: 62813.02,
            price_sources: [],
            processed_ms: 1719873462352,
          },
        ],
        position_type: "FLAT",
        position_uuid: "0e10aca2-e4bd-46bd-a964-aa069e6da8b2",
        return_at_close: 1.0012956711257823,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 107.39666666666666,
        close_ms: 0,
        current_return: 0.990402341165177,
        initial_entry_price: 107.297,
        is_closed_position: false,
        miner_hotkey: "5H67WyekqLpuXAd4PC6AsnzybFkqK8Apj2dptZ7GQqLcDimc",
        net_leverage: -0.6000000000000001,
        open_ms: 1719803195790,
        orders: [
          {
            leverage: -0.1,
            order_type: "SHORT",
            order_uuid: "5f300539-a72d-41cd-bfc9-6577d4f4a470",
            price: 107.297,
            price_sources: [],
            processed_ms: 1719803195783,
          },
          {
            leverage: -0.2,
            order_type: "SHORT",
            order_uuid: "db0de961-06bb-4927-b6d1-1435cb1fcbad",
            price: 107.45949999999999,
            price_sources: [],
            processed_ms: 1719849052682,
          },
          {
            leverage: -0.3,
            order_type: "SHORT",
            order_uuid: "e01ed262-ee08-4f68-b3b8-1dab5ad0aa07",
            price: 107.388,
            price_sources: [],
            processed_ms: 1719859835352,
          },
        ],
        position_type: "SHORT",
        position_uuid: "5f300539-a72d-41cd-bfc9-6577d4f4a470",
        return_at_close: 0.9903815427160125,
        trade_pair: ["AUDJPY", "AUD/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 3353.4858823529407,
        close_ms: 0,
        current_return: 0.8922473699308602,
        initial_entry_price: 3416.26,
        is_closed_position: false,
        miner_hotkey: "5H67WyekqLpuXAd4PC6AsnzybFkqK8Apj2dptZ7GQqLcDimc",
        net_leverage: 1.7000000000000002,
        open_ms: 1719933160198,
        orders: [
          {
            leverage: 0.2,
            order_type: "LONG",
            order_uuid: "f54b2ad7-0d4b-4723-9c4a-dec254917149",
            price: 3416.26,
            price_sources: [],
            processed_ms: 1719933159618,
          },
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "30503bd5-3efa-4ac5-98e9-4aca1d49899a",
            price: 3389.27,
            price_sources: [],
            processed_ms: 1719972449788,
          },
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "8dac7dcd-9ddb-4696-84cb-ebe4ec3c530d",
            price: 3379.78,
            price_sources: [],
            processed_ms: 1719974378258,
          },
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "5f75cea9-9b11-49ad-aaa2-5658f4b3f9bb",
            price: 3347.2,
            price_sources: [],
            processed_ms: 1719976502031,
          },
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "beda4201-1ee5-4b71-a6fc-833c86923189",
            price: 3304.85,
            price_sources: [],
            processed_ms: 1720007469844,
          },
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "f9e0ff8c-9d74-4e12-ae03-b34e2647192f",
            price: 3304.48,
            price_sources: [],
            processed_ms: 1720007495776,
          },
        ],
        position_type: "LONG",
        position_uuid: "f54b2ad7-0d4b-4723-9c4a-dec254917149",
        return_at_close: 0.891488959666419,
        trade_pair: ["ETHUSD", "ETH/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 173.411,
        close_ms: 0,
        current_return: 0.9953448743159315,
        initial_entry_price: 173.411,
        is_closed_position: false,
        miner_hotkey: "5H67WyekqLpuXAd4PC6AsnzybFkqK8Apj2dptZ7GQqLcDimc",
        net_leverage: -0.5,
        open_ms: 1719866782270,
        orders: [
          {
            leverage: -0.5,
            order_type: "SHORT",
            order_uuid: "30ecb168-21e0-40c4-ae3a-d0fc1d6eb061",
            price: 173.411,
            price_sources: [],
            processed_ms: 1719866782262,
          },
        ],
        position_type: "SHORT",
        position_uuid: "30ecb168-21e0-40c4-ae3a-d0fc1d6eb061",
        return_at_close: 0.995327455780631,
        trade_pair: ["EURJPY", "EUR/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 1.0737366666666668,
        close_ms: 0,
        current_return: 0.9855461402365213,
        initial_entry_price: 1.0739,
        is_closed_position: false,
        miner_hotkey: "5H67WyekqLpuXAd4PC6AsnzybFkqK8Apj2dptZ7GQqLcDimc",
        net_leverage: -1.8,
        open_ms: 1719873570149,
        orders: [
          {
            leverage: -0.5,
            order_type: "SHORT",
            order_uuid: "ebdab040-937c-4bda-ae9c-435c9adb4bb1",
            price: 1.0739,
            price_sources: [],
            processed_ms: 1719873568637,
          },
          {
            leverage: -0.5,
            order_type: "SHORT",
            order_uuid: "e3e9304f-d41a-453e-852e-dd205c7e144d",
            price: 1.07332,
            price_sources: [],
            processed_ms: 1719883313867,
          },
          {
            leverage: -0.5,
            order_type: "SHORT",
            order_uuid: "1429addf-5ffd-4f72-8cd3-24fadc6a95dc",
            price: 1.07331,
            price_sources: [],
            processed_ms: 1719926527323,
          },
          {
            leverage: -0.3,
            order_type: "SHORT",
            order_uuid: "a2ea39da-7f36-43a6-9fb4-ef6aaa73ffc1",
            price: 1.07487,
            price_sources: [],
            processed_ms: 1719964850752,
          },
        ],
        position_type: "SHORT",
        position_uuid: "ebdab040-937c-4bda-ae9c-435c9adb4bb1",
        return_at_close: 0.9854840508296864,
        trade_pair: ["EURUSD", "EUR/USD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.9034628995972894,
        close_ms: 0,
        current_return: 1.0103877575114215,
        initial_entry_price: 0.90283,
        is_closed_position: false,
        miner_hotkey: "5H67WyekqLpuXAd4PC6AsnzybFkqK8Apj2dptZ7GQqLcDimc",
        net_leverage: -2.2,
        open_ms: 1719866631290,
        orders: [
          {
            leverage: -0.5,
            order_type: "SHORT",
            order_uuid: "71b45aff-9879-428b-9a7f-7f05ab2c86e0",
            price: 0.90283,
            price_sources: [],
            processed_ms: 1719866631264,
          },
          {
            leverage: -0.5,
            order_type: "SHORT",
            order_uuid: "16c6027a-8dfb-4f33-97cf-59012731651e",
            price: 0.9027287582280734,
            price_sources: [],
            processed_ms: 1719866740732,
          },
          {
            leverage: -0.5,
            order_type: "SHORT",
            order_uuid: "655e9bcd-a7c7-4f01-b352-b4eb4eabd51a",
            price: 0.90318,
            price_sources: [],
            processed_ms: 1719877143884,
          },
          {
            leverage: -0.5,
            order_type: "SHORT",
            order_uuid: "df890278-5bfb-4747-992e-be63588a182d",
            price: 0.90464,
            price_sources: [],
            processed_ms: 1719926551316,
          },
          {
            leverage: -0.2,
            order_type: "SHORT",
            order_uuid: "4f736c4d-62c1-444e-85a3-ce44326bb7ba",
            price: 0.9046449999999999,
            price_sources: [],
            processed_ms: 1719936053133,
          },
        ],
        position_type: "SHORT",
        position_uuid: "71b45aff-9879-428b-9a7f-7f05ab2c86e0",
        return_at_close: 1.010309957654093,
        trade_pair: ["USDCHF", "USD/CHF", 7e-5, 0.001, 500],
      },
    ],
    thirty_day_returns: 1.0169121323707575,
  },
  "5H6NhFqEPpKmhzpf81HwuQ2TgaioNDmUxpnjmxff5uWWLzzN": {
    all_time_returns: 1.0,
    n_positions: 0,
    percentage_profitable: 0.0,
    positions: [
      {
        average_entry_price: 64337.69,
        close_ms: 1711241297029,
        current_return: 1.0006703846532259,
        initial_entry_price: 64337.69,
        is_closed_position: true,
        miner_hotkey: "5H6NhFqEPpKmhzpf81HwuQ2TgaioNDmUxpnjmxff5uWWLzzN",
        net_leverage: 0.0,
        open_ms: 1711237734092,
        orders: [
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "e7eb6ebe-8f78-4a4b-947f-06ee654a97a7",
            price: 64337.69,
            price_sources: [],
            processed_ms: 1711237734092,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "1f6b0339-c931-45e7-bc71-a83e2ffc284a",
            price: 64481.46,
            price_sources: [],
            processed_ms: 1711241297029,
          },
        ],
        position_type: "FLAT",
        position_uuid: "4f58469e-ab82-46a9-8f09-f8ca54d24bb9",
        return_at_close: 1.000069982422434,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 66234.50000000025,
        close_ms: 1711337398964,
        current_return: 0.9993425860006421,
        initial_entry_price: 66656.77,
        is_closed_position: true,
        miner_hotkey: "5H6NhFqEPpKmhzpf81HwuQ2TgaioNDmUxpnjmxff5uWWLzzN",
        net_leverage: 0.0,
        open_ms: 1711328546302,
        orders: [
          {
            leverage: -1.25,
            order_type: "SHORT",
            order_uuid: "cfb29e56-d1ea-43c6-9204-585c97e1282a",
            price: 66656.77,
            price_sources: [],
            processed_ms: 1711328546301,
          },
          {
            leverage: 0.625,
            order_type: "LONG",
            order_uuid: "24696f7c-108e-4458-919e-a52be1abc8b5",
            price: 66757.49,
            price_sources: [],
            processed_ms: 1711330067191,
          },
          {
            leverage: 0.3125,
            order_type: "LONG",
            order_uuid: "49601d8a-63ba-4ece-affb-d923b058fa02",
            price: 66540.09,
            price_sources: [],
            processed_ms: 1711331891945,
          },
          {
            leverage: 0.15625,
            order_type: "LONG",
            order_uuid: "093c04fd-f548-446b-80ab-6a1c23e7697b",
            price: 66669.59,
            price_sources: [],
            processed_ms: 1711333732182,
          },
          {
            leverage: 0.078125,
            order_type: "LONG",
            order_uuid: "53b8e658-eaf5-4fd1-9cd7-8e77c229e271",
            price: 66714.36,
            price_sources: [],
            processed_ms: 1711335571627,
          },
          {
            leverage: 0.125,
            order_type: "LONG",
            order_uuid: "59cf1ad5-1af2-4cfb-8577-c417403cafe0",
            price: 66795.41,
            price_sources: [],
            processed_ms: 1711337398964,
          },
        ],
        position_type: "FLAT",
        position_uuid: "d0b4ad4f-ae9a-4561-a106-6ffbd08b0aa3",
        return_at_close: 0.9968442295356406,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 41129.24199999966,
        close_ms: 1711393714203,
        current_return: 1.0338566766847679,
        initial_entry_price: 68838.73,
        is_closed_position: true,
        miner_hotkey: "5H6NhFqEPpKmhzpf81HwuQ2TgaioNDmUxpnjmxff5uWWLzzN",
        net_leverage: 0.0,
        open_ms: 1711375972882,
        orders: [
          {
            leverage: 1.25,
            order_type: "LONG",
            order_uuid: "2f393c5f-1665-4ac2-b6cd-9d3b8d6510a7",
            price: 68838.73,
            price_sources: [],
            processed_ms: 1711375972881,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "0e6b90fd-405a-49b3-95c0-e0c60e99c43a",
            price: 69141.81,
            price_sources: [],
            processed_ms: 1711376489768,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "83c17e5b-befa-4e0a-b0da-c717d26fe634",
            price: 69296.54,
            price_sources: [],
            processed_ms: 1711376805060,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "58afcba5-6249-4682-a212-3101f21b169f",
            price: 69142.29,
            price_sources: [],
            processed_ms: 1711377945161,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "fb53fa6a-5cf2-4744-b34c-25eb9610cd65",
            price: 69212.28,
            price_sources: [],
            processed_ms: 1711378229040,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "3afec035-8a0e-4a78-ba06-d58cf2f4c04a",
            price: 69941.84,
            price_sources: [],
            processed_ms: 1711381823290,
          },
          {
            leverage: -1.25,
            order_type: "SHORT",
            order_uuid: "2bbb3c06-81a1-4ddd-8799-10a82352c5b9",
            price: 69711.85,
            price_sources: [],
            processed_ms: 1711383656179,
          },
          {
            leverage: -0.625,
            order_type: "SHORT",
            order_uuid: "75db8fcd-f4da-4766-ab4d-41e6b16c1d25",
            price: 70118.77,
            price_sources: [],
            processed_ms: 1711385468373,
          },
          {
            leverage: -0.3125,
            order_type: "SHORT",
            order_uuid: "b935957e-42e9-43bd-8aa0-19ad31ffd458",
            price: 70500.0,
            price_sources: [],
            processed_ms: 1711387284418,
          },
          {
            leverage: -0.15625,
            order_type: "SHORT",
            order_uuid: "1b2de4d1-ccf8-4063-bf6d-72de48555874",
            price: 70409.46,
            price_sources: [],
            processed_ms: 1711389105604,
          },
          {
            leverage: -0.078125,
            order_type: "SHORT",
            order_uuid: "f03e3fc8-7b64-4d8c-b7b5-dc15f92f6fd8",
            price: 70682.99,
            price_sources: [],
            processed_ms: 1711390916407,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "649e60cb-1bd8-4314-a67b-d1db4b6724a2",
            price: 70961.57,
            price_sources: [],
            processed_ms: 1711393714203,
          },
        ],
        position_type: "FLAT",
        position_uuid: "51aadb53-96e0-4835-b1f5-63184417fd81",
        return_at_close: 1.028687393301344,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 68972.53999999995,
        close_ms: 1711410068124,
        current_return: 1.0047697088743655,
        initial_entry_price: 70158.37,
        is_closed_position: true,
        miner_hotkey: "5H6NhFqEPpKmhzpf81HwuQ2TgaioNDmUxpnjmxff5uWWLzzN",
        net_leverage: 0.0,
        open_ms: 1711402798507,
        orders: [
          {
            leverage: 3.0,
            order_type: "LONG",
            order_uuid: "da1c1a9c-adc8-4a33-9460-70c3f078a1a5",
            price: 70158.37,
            price_sources: [],
            processed_ms: 1711402798505,
          },
          {
            leverage: -1.5,
            order_type: "SHORT",
            order_uuid: "add6dc63-00f6-4d63-b78d-73aad5935ee9",
            price: 70219.66,
            price_sources: [],
            processed_ms: 1711404615437,
          },
          {
            leverage: -0.75,
            order_type: "SHORT",
            order_uuid: "8496286d-2b10-43c7-b4bc-573102311d7e",
            price: 70534.0,
            price_sources: [],
            processed_ms: 1711406435194,
          },
          {
            leverage: -0.375,
            order_type: "SHORT",
            order_uuid: "ba2db0e8-3aeb-4c2c-8762-96a76f2be299",
            price: 70347.78,
            price_sources: [],
            processed_ms: 1711408258294,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "9487d341-cb05-4cd8-9829-941e6e64503f",
            price: 69864.9,
            price_sources: [],
            processed_ms: 1711410068124,
          },
        ],
        position_type: "FLAT",
        position_uuid: "af2171ca-65fb-43b9-918b-e6d4e036dc37",
        return_at_close: 0.9987410906211193,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 72112.74089171975,
        close_ms: 1711484553232,
        current_return: 0.9873806842463787,
        initial_entry_price: 70233.09,
        is_closed_position: true,
        miner_hotkey: "5H6NhFqEPpKmhzpf81HwuQ2TgaioNDmUxpnjmxff5uWWLzzN",
        net_leverage: 0.0,
        open_ms: 1711464565995,
        orders: [
          {
            leverage: 3.0,
            order_type: "LONG",
            order_uuid: "3df32260-158e-4f9e-aef8-340281d3cfdd",
            price: 70233.09,
            price_sources: [],
            processed_ms: 1711464565993,
          },
          {
            leverage: 0.8999999999999999,
            order_type: "LONG",
            order_uuid: "c74d3c35-cca0-4a74-a400-dc12c2b99599",
            price: 69816.2,
            price_sources: [],
            processed_ms: 1711466377047,
          },
          {
            leverage: -1.95,
            order_type: "SHORT",
            order_uuid: "1950f048-9b9e-489c-bf3f-d91ddce9f61a",
            price: 70343.53,
            price_sources: [],
            processed_ms: 1711468213228,
          },
          {
            leverage: -0.975,
            order_type: "SHORT",
            order_uuid: "5ddbceaa-e52f-4b6c-b22c-0a5b08756e9c",
            price: 69873.78,
            price_sources: [],
            processed_ms: 1711470090872,
          },
          {
            leverage: -0.4875,
            order_type: "SHORT",
            order_uuid: "5a2ce4b2-a29a-4581-b24e-b70c29ac3821",
            price: 69668.01,
            price_sources: [],
            processed_ms: 1711471831188,
          },
          {
            leverage: -0.24375,
            order_type: "SHORT",
            order_uuid: "f859b7f2-fdee-4b09-9cdf-e26ca2423ab7",
            price: 69836.42,
            price_sources: [],
            processed_ms: 1711473650408,
          },
          {
            leverage: 0.8999999999999998,
            order_type: "LONG",
            order_uuid: "34ae747c-b870-4b34-b5ec-40bdb1e489e3",
            price: 70143.95,
            price_sources: [],
            processed_ms: 1711475516794,
          },
          {
            leverage: -0.5718749999999999,
            order_type: "SHORT",
            order_uuid: "98274e91-8b86-4480-9029-126b7f5fc523",
            price: 70252.23,
            price_sources: [],
            processed_ms: 1711477339611,
          },
          {
            leverage: 0.8999999999999999,
            order_type: "LONG",
            order_uuid: "060ebe6b-97fd-4e14-a76d-59291b99a050",
            price: 70338.43,
            price_sources: [],
            processed_ms: 1711479198287,
          },
          {
            leverage: -0.7359374999999999,
            order_type: "SHORT",
            order_uuid: "99749541-0d8c-4d3a-918c-ebc1daf6d216",
            price: 69723.75,
            price_sources: [],
            processed_ms: 1711480918203,
          },
          {
            leverage: -0.36796874999999996,
            order_type: "SHORT",
            order_uuid: "c6ab30d5-c1f6-4bc1-b6f3-ca3c10bd52be",
            price: 69740.54,
            price_sources: [],
            processed_ms: 1711482739491,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "d699cec6-fbaf-4601-999d-e4ce3fa5f460",
            price: 69704.13,
            price_sources: [],
            processed_ms: 1711484553232,
          },
        ],
        position_type: "FLAT",
        position_uuid: "b2df50b5-85cc-44b7-b4b1-63c12d9177a2",
        return_at_close: 0.979679114909257,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 69402.96,
        close_ms: 1711548854053,
        current_return: 0.9686664660988521,
        initial_entry_price: 69402.96,
        is_closed_position: true,
        miner_hotkey: "5H6NhFqEPpKmhzpf81HwuQ2TgaioNDmUxpnjmxff5uWWLzzN",
        net_leverage: 0.0,
        open_ms: 1711548258340,
        orders: [
          {
            leverage: 3.0,
            order_type: "LONG",
            order_uuid: "6e9298c6-5277-42d8-8bce-4390be406168",
            price: 69402.96,
            price_sources: [],
            processed_ms: 1711548258339,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "84227cca-7e2c-439b-93a8-f1ca8209ea67",
            price: 68678.08,
            price_sources: [],
            processed_ms: 1711548854053,
          },
        ],
        position_type: "FLAT",
        position_uuid: "4b015c29-ba61-41ff-b46b-d488f472c95a",
        return_at_close: 0.962854467302259,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 58191.60000000105,
        close_ms: 1711609809470,
        current_return: 0.9959245007810773,
        initial_entry_price: 69404.17,
        is_closed_position: true,
        miner_hotkey: "5H6NhFqEPpKmhzpf81HwuQ2TgaioNDmUxpnjmxff5uWWLzzN",
        net_leverage: 0.0,
        open_ms: 1711580851000,
        orders: [
          {
            leverage: -3.0,
            order_type: "SHORT",
            order_uuid: "11eeb060-035a-442a-bf20-ad75e4fc5083",
            price: 69404.17,
            price_sources: [],
            processed_ms: 1711580850998,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "61c02117-e772-4739-b022-5c5067cca9b6",
            price: 69460.78,
            price_sources: [],
            processed_ms: 1711584496386,
          },
          {
            leverage: 0.75,
            order_type: "LONG",
            order_uuid: "3015ea36-7615-4fc3-80dd-febb3e2ac22c",
            price: 69626.42,
            price_sources: [],
            processed_ms: 1711588063224,
          },
          {
            leverage: 0.375,
            order_type: "LONG",
            order_uuid: "9f0e98ed-41f7-4075-946c-f9b287e64089",
            price: 69468.37,
            price_sources: [],
            processed_ms: 1711591684611,
          },
          {
            leverage: 0.1875,
            order_type: "LONG",
            order_uuid: "65629a00-9974-4bb7-9a8c-45f26cb93c10",
            price: 69224.71,
            price_sources: [],
            processed_ms: 1711595294498,
          },
          {
            leverage: 0.09375,
            order_type: "LONG",
            order_uuid: "f57f04f7-9586-4660-bd25-779ae0bab926",
            price: 69430.48,
            price_sources: [],
            processed_ms: 1711598914919,
          },
          {
            leverage: 0.046875,
            order_type: "LONG",
            order_uuid: "723140ac-7855-4f71-89e0-b9b5fb653ea6",
            price: 69631.03,
            price_sources: [],
            processed_ms: 1711602536151,
          },
          {
            leverage: 0.0234375,
            order_type: "LONG",
            order_uuid: "409cf509-11fe-4238-be72-5d362c9c7751",
            price: 69731.22,
            price_sources: [],
            processed_ms: 1711606154226,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "854f213e-ed71-40be-87de-06e97e85139a",
            price: 70260.15,
            price_sources: [],
            processed_ms: 1711609809470,
          },
        ],
        position_type: "FLAT",
        position_uuid: "18d72dea-2fe1-46ae-9c61-c4f5dac691c2",
        return_at_close: 0.9899489537763908,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 70033.44169811344,
        close_ms: 1711823279646,
        current_return: 0.9999909351938979,
        initial_entry_price: 70030.24,
        is_closed_position: true,
        miner_hotkey: "5H6NhFqEPpKmhzpf81HwuQ2TgaioNDmUxpnjmxff5uWWLzzN",
        net_leverage: 0.0,
        open_ms: 1711779860922,
        orders: [
          {
            leverage: 3.0,
            order_type: "LONG",
            order_uuid: "d5ffc0a7-6820-48a7-819d-b7f0f68f1b47",
            price: 70030.24,
            price_sources: [],
            processed_ms: 1711779860921,
          },
          {
            leverage: -1.5,
            order_type: "SHORT",
            order_uuid: "e299c80a-e947-4844-b41d-66f27d0f86ea",
            price: 70003.55,
            price_sources: [],
            processed_ms: 1711783484215,
          },
          {
            leverage: -0.75,
            order_type: "SHORT",
            order_uuid: "414aab1a-577b-4b82-843a-9e4e76eafffe",
            price: 69988.35,
            price_sources: [],
            processed_ms: 1711787178122,
          },
          {
            leverage: -0.375,
            order_type: "SHORT",
            order_uuid: "d06e3705-f871-4739-8a22-2078bf4e4788",
            price: 70140.69,
            price_sources: [],
            processed_ms: 1711790752674,
          },
          {
            leverage: -0.1875,
            order_type: "SHORT",
            order_uuid: "0385fdef-1624-4da5-8cad-af565d05b9e7",
            price: 70051.93,
            price_sources: [],
            processed_ms: 1711794332655,
          },
          {
            leverage: -0.09375,
            order_type: "SHORT",
            order_uuid: "e446d012-f537-4f1f-adfd-3ee036acb363",
            price: 70102.82,
            price_sources: [],
            processed_ms: 1711797952123,
          },
          {
            leverage: 0.8999999999999999,
            order_type: "LONG",
            order_uuid: "26df9e24-2b8a-4786-8c57-4ff660f6828f",
            price: 70172.54,
            price_sources: [],
            processed_ms: 1711801567124,
          },
          {
            leverage: -0.49687499999999996,
            order_type: "SHORT",
            order_uuid: "b769601e-881d-4907-a97c-d55cd2950d9b",
            price: 70240.61,
            price_sources: [],
            processed_ms: 1711805187192,
          },
          {
            leverage: -0.24843749999999998,
            order_type: "SHORT",
            order_uuid: "9605506b-1cd8-47fc-bcae-e5ace72849a2",
            price: 70177.21,
            price_sources: [],
            processed_ms: 1711808906745,
          },
          {
            leverage: -0.12421874999999999,
            order_type: "SHORT",
            order_uuid: "a56dfde4-eeab-4e0c-9824-4be1689198d3",
            price: 70056.09,
            price_sources: [],
            processed_ms: 1711812420372,
          },
          {
            leverage: -0.062109374999999994,
            order_type: "SHORT",
            order_uuid: "198548cc-e343-48ee-ac61-89f777e9e845",
            price: 70053.0,
            price_sources: [],
            processed_ms: 1711816046014,
          },
          {
            leverage: -0.031054687499999997,
            order_type: "SHORT",
            order_uuid: "6cfc8f4e-a102-450a-809d-4dc9724695b7",
            price: 70077.51,
            price_sources: [],
            processed_ms: 1711819705735,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "022537d2-af66-4b9d-8361-20ccf467e6f8",
            price: 70013.0,
            price_sources: [],
            processed_ms: 1711823279646,
          },
        ],
        position_type: "FLAT",
        position_uuid: "9b803230-6ade-4fe9-926e-cc3a1169ebf4",
        return_at_close: 0.9939909895827346,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 111402.43999999986,
        close_ms: 1711971779847,
        current_return: 1.0222800133635004,
        initial_entry_price: 70580.31,
        is_closed_position: true,
        miner_hotkey: "5H6NhFqEPpKmhzpf81HwuQ2TgaioNDmUxpnjmxff5uWWLzzN",
        net_leverage: 0.0,
        open_ms: 1711942809485,
        orders: [
          {
            leverage: -3.0,
            order_type: "SHORT",
            order_uuid: "9c09b1ca-b8f3-40ac-b1af-48d1a61fd773",
            price: 70580.31,
            price_sources: [],
            processed_ms: 1711942809483,
          },
          {
            leverage: 0.8999999999999999,
            order_type: "LONG",
            order_uuid: "d864fcee-53bc-45b2-9986-dbbae2a8a5ff",
            price: 70540.84,
            price_sources: [],
            processed_ms: 1711946524640,
          },
          {
            leverage: 0.8999999999999999,
            order_type: "LONG",
            order_uuid: "d0963f22-d878-400f-ad42-472e9df100af",
            price: 70242.7,
            price_sources: [],
            processed_ms: 1711950032545,
          },
          {
            leverage: 0.6000000000000001,
            order_type: "LONG",
            order_uuid: "1481de29-ce08-4943-95ef-6bbe06acd82c",
            price: 69572.3,
            price_sources: [],
            processed_ms: 1711953660605,
          },
          {
            leverage: 0.30000000000000004,
            order_type: "LONG",
            order_uuid: "ae6aa237-54be-499a-b1aa-557bb45e64ec",
            price: 69575.0,
            price_sources: [],
            processed_ms: 1711957282209,
          },
          {
            leverage: 0.15000000000000002,
            order_type: "LONG",
            order_uuid: "28de1f7e-339e-4478-baf3-979bb52fc64f",
            price: 69498.5,
            price_sources: [],
            processed_ms: 1711960915111,
          },
          {
            leverage: 0.07500000000000001,
            order_type: "LONG",
            order_uuid: "ea506616-7051-415c-9d65-010817a638f7",
            price: 69498.0,
            price_sources: [],
            processed_ms: 1711964629930,
          },
          {
            leverage: 0.037500000000000006,
            order_type: "LONG",
            order_uuid: "b276ebf6-9270-4174-b689-9af200c8ba02",
            price: 69470.6,
            price_sources: [],
            processed_ms: 1711968242064,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "c051b211-df9f-4312-b0ba-9a0c51bf4242",
            price: 69468.3,
            price_sources: [],
            processed_ms: 1711971779847,
          },
        ],
        position_type: "FLAT",
        position_uuid: "7903a700-6025-4974-8a31-05bd49d46974",
        return_at_close: 1.0161463332833194,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 66386.60937557236,
        close_ms: 1712087745876,
        current_return: 0.999048198443389,
        initial_entry_price: 69720.1,
        is_closed_position: true,
        miner_hotkey: "5H6NhFqEPpKmhzpf81HwuQ2TgaioNDmUxpnjmxff5uWWLzzN",
        net_leverage: 0.0,
        open_ms: 1712006266526,
        orders: [
          {
            leverage: 3.0,
            order_type: "LONG",
            order_uuid: "636083c7-5fe7-4175-a1c4-01512e9ba701",
            price: 69720.1,
            price_sources: [],
            processed_ms: 1712006266525,
          },
          {
            leverage: 0.8999999999999999,
            order_type: "LONG",
            order_uuid: "0d3f67eb-e819-4760-a831-b8ba4bf15539",
            price: 69740.3,
            price_sources: [],
            processed_ms: 1712009869447,
          },
          {
            leverage: -1.95,
            order_type: "SHORT",
            order_uuid: "3b3f514f-d857-4467-94f8-43b898012589",
            price: 69803.4,
            price_sources: [],
            processed_ms: 1712013575364,
          },
          {
            leverage: -0.975,
            order_type: "SHORT",
            order_uuid: "101d94ea-fd59-4b4e-a5b5-1fb18ddd4ccf",
            price: 69506.7,
            price_sources: [],
            processed_ms: 1712017131198,
          },
          {
            leverage: -0.4875,
            order_type: "SHORT",
            order_uuid: "f29a36e6-6b52-408d-89a8-0efedbe731fa",
            price: 69407.1,
            price_sources: [],
            processed_ms: 1712021391211,
          },
          {
            leverage: 0.8999999999999999,
            order_type: "LONG",
            order_uuid: "9d2ad971-b038-4833-b1ee-2032cffd0e28",
            price: 66645.5,
            price_sources: [],
            processed_ms: 1712032284987,
          },
          {
            leverage: 0.8999999999999999,
            order_type: "LONG",
            order_uuid: "2f4e4066-1882-4bb4-bcbc-ffef01b15022",
            price: 66440.0,
            price_sources: [],
            processed_ms: 1712036005405,
          },
          {
            leverage: -0.9609374999999999,
            order_type: "SHORT",
            order_uuid: "eae0d436-43cf-4fcf-98b0-d38427fd5045",
            price: 66864.2,
            price_sources: [],
            processed_ms: 1712042409144,
          },
          {
            leverage: -0.48046874999999994,
            order_type: "SHORT",
            order_uuid: "f5dd57bb-d3d2-465a-96e5-7a183716524e",
            price: 66415.1,
            price_sources: [],
            processed_ms: 1712046028711,
          },
          {
            leverage: -0.38437499999999997,
            order_type: "SHORT",
            order_uuid: "57e1f21c-7dfd-4173-a6e0-38fcfe1f5f42",
            price: 66570.8,
            price_sources: [],
            processed_ms: 1712049728522,
          },
          {
            leverage: -0.04804687499999999,
            order_type: "SHORT",
            order_uuid: "de167862-6ad4-4a4e-9159-bb875ef4bac1",
            price: 66244.6,
            price_sources: [],
            processed_ms: 1712053369443,
          },
          {
            leverage: 0.8999999999999999,
            order_type: "LONG",
            order_uuid: "34abf2b6-ffb1-40d9-843f-e1a1125fa151",
            price: 65482.9,
            price_sources: [],
            processed_ms: 1712056954926,
          },
          {
            leverage: -0.4740234375,
            order_type: "SHORT",
            order_uuid: "830d0f99-1102-454c-ad86-f1452ac2052f",
            price: 65730.0,
            price_sources: [],
            processed_ms: 1712060583461,
          },
          {
            leverage: 0.9,
            order_type: "LONG",
            order_uuid: "9a9cfdc7-f0d5-4b81-badd-be49784fe050",
            price: 64670.4,
            price_sources: [],
            processed_ms: 1712065360185,
          },
          {
            leverage: -1.09921875,
            order_type: "SHORT",
            order_uuid: "9c4d5178-4d0e-44ec-9ccf-7f02c548b579",
            price: 66145.1,
            price_sources: [],
            processed_ms: 1712069018533,
          },
          {
            leverage: -0.13740234375000004,
            order_type: "SHORT",
            order_uuid: "7812721b-73f0-4a2f-8583-2cfc9e575f2f",
            price: 65612.8,
            price_sources: [],
            processed_ms: 1712072605392,
          },
          {
            leverage: -0.06870117187500002,
            order_type: "SHORT",
            order_uuid: "45022bc8-a5b9-4ace-9c90-e463431c2f4e",
            price: 65338.2,
            price_sources: [],
            processed_ms: 1712080507575,
          },
          {
            leverage: -0.03435058593750001,
            order_type: "SHORT",
            order_uuid: "9a6b1704-9852-4fc8-a028-f11aa11cb2f7",
            price: 65893.8,
            price_sources: [],
            processed_ms: 1712084128775,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "da2be376-f1ee-498b-a852-b1ffdf82e6d7",
            price: 66220.7,
            price_sources: [],
            processed_ms: 1712087745876,
          },
        ],
        position_type: "FLAT",
        position_uuid: "ea2517f6-382c-49fb-8d98-29e0ceefb7a9",
        return_at_close: 0.9912556224955306,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 19820.978260871347,
        close_ms: 1712182769933,
        current_return: 1.019023239991636,
        initial_entry_price: 65464.0,
        is_closed_position: true,
        miner_hotkey: "5H6NhFqEPpKmhzpf81HwuQ2TgaioNDmUxpnjmxff5uWWLzzN",
        net_leverage: 0.0,
        open_ms: 1712101689030,
        orders: [
          {
            leverage: 3.0,
            order_type: "LONG",
            order_uuid: "b734a87d-cb11-4296-8a6b-185771a5897f",
            price: 65464.0,
            price_sources: [],
            processed_ms: 1712101689028,
          },
          {
            leverage: -1.5,
            order_type: "SHORT",
            order_uuid: "4a76af4c-a245-4bd2-8936-8d72d42420ef",
            price: 65511.0,
            price_sources: [],
            processed_ms: 1712107766808,
          },
          {
            leverage: 0.8999999999999999,
            order_type: "LONG",
            order_uuid: "5e3fb50f-d142-4f74-b11b-c28b2639c7fb",
            price: 65907.6,
            price_sources: [],
            processed_ms: 1712111296896,
          },
          {
            leverage: 0.8999999999999999,
            order_type: "LONG",
            order_uuid: "56457dd5-2fb5-49a8-8136-fa5458819f8a",
            price: 65909.9,
            price_sources: [],
            processed_ms: 1712114933365,
          },
          {
            leverage: -1.65,
            order_type: "SHORT",
            order_uuid: "fbdaaca6-9a46-4804-ac63-69d692d906ee",
            price: 66119.8,
            price_sources: [],
            processed_ms: 1712121668486,
          },
          {
            leverage: 0.8999999999999999,
            order_type: "LONG",
            order_uuid: "907a25f1-496b-4d61-aa55-71c5b653385d",
            price: 66176.6,
            price_sources: [],
            processed_ms: 1712125292755,
          },
          {
            leverage: 0.8999999999999999,
            order_type: "LONG",
            order_uuid: "d3d1d8ab-5276-4586-a60a-2255f3317462",
            price: 66443.0,
            price_sources: [],
            processed_ms: 1712128910648,
          },
          {
            leverage: -1.7249999999999999,
            order_type: "SHORT",
            order_uuid: "13dc98e2-20e6-46ff-acc5-27a4ddc5c976",
            price: 66227.7,
            price_sources: [],
            processed_ms: 1712141955190,
          },
          {
            leverage: -0.8624999999999999,
            order_type: "SHORT",
            order_uuid: "368c5331-765e-45de-bb1e-7b02b2b4cc22",
            price: 66096.5,
            price_sources: [],
            processed_ms: 1712145488886,
          },
          {
            leverage: -0.43124999999999997,
            order_type: "SHORT",
            order_uuid: "a77876d4-68af-4b99-8094-40acdd83080a",
            price: 65942.1,
            price_sources: [],
            processed_ms: 1712149184824,
          },
          {
            leverage: -0.21562499999999998,
            order_type: "SHORT",
            order_uuid: "4f7f930e-e2d8-479e-87f6-5f1642fd0ebf",
            price: 66531.1,
            price_sources: [],
            processed_ms: 1712155864515,
          },
          {
            leverage: -0.10781249999999999,
            order_type: "SHORT",
            order_uuid: "43659fea-1ace-4b4e-8037-ae5bfc792613",
            price: 65964.7,
            price_sources: [],
            processed_ms: 1712159487583,
          },
          {
            leverage: -0.053906249999999996,
            order_type: "SHORT",
            order_uuid: "fcf19cf3-6ddf-43b5-91c9-9ca9a828b315",
            price: 66270.8,
            price_sources: [],
            processed_ms: 1712163101888,
          },
          {
            leverage: -0.026953124999999998,
            order_type: "SHORT",
            order_uuid: "6ad698c6-17c9-4a00-8406-a070724e1611",
            price: 65611.7,
            price_sources: [],
            processed_ms: 1712179151486,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "721bce0f-3606-4d19-8e36-c8b9fc8e8350",
            price: 66024.8,
            price_sources: [],
            processed_ms: 1712182769933,
          },
        ],
        position_type: "FLAT",
        position_uuid: "65125683-3655-4d7f-bd53-99477b1d8792",
        return_at_close: 1.0119919796356935,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 67508.1,
        close_ms: 1712361619727,
        current_return: 0.9845174134659399,
        initial_entry_price: 67508.1,
        is_closed_position: true,
        miner_hotkey: "5H6NhFqEPpKmhzpf81HwuQ2TgaioNDmUxpnjmxff5uWWLzzN",
        net_leverage: -3.0,
        open_ms: 1712342018429,
        orders: [
          {
            leverage: -3.0,
            order_type: "SHORT",
            order_uuid: "03ad5f4d-210f-4acb-907a-3af22c40bb1b",
            price: 67508.1,
            price_sources: [],
            processed_ms: 1712342018427,
          },
        ],
        position_type: "FLAT",
        position_uuid: "3a77dcc2-c681-4457-a657-e672290647c5",
        return_at_close: 0.9786103089851442,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
    ],
    thirty_day_returns: 1.0,
  },
  "5H8miZycwvhUnF9Bc38J7Gxgn5gHuygFKbf9anRpMes93Zd8": {
    all_time_returns: 1.0,
    n_positions: 0,
    percentage_profitable: 0.0,
    positions: [
      {
        average_entry_price: 64563.06,
        close_ms: 1711197546609,
        current_return: 1.001303376884553,
        initial_entry_price: 64563.06,
        is_closed_position: true,
        miner_hotkey: "5H8miZycwvhUnF9Bc38J7Gxgn5gHuygFKbf9anRpMes93Zd8",
        net_leverage: 0.0,
        open_ms: 1711193965559,
        orders: [
          {
            leverage: 0.6,
            order_type: "LONG",
            order_uuid: "db52cd83-3ea9-4575-b921-e6f2454ad8e2",
            price: 64563.06,
            price_sources: [],
            processed_ms: 1711193965558,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "9e089cbd-8be5-49b3-afd8-2d975234a3fd",
            price: 64703.31,
            price_sources: [],
            processed_ms: 1711197546609,
          },
        ],
        position_type: "FLAT",
        position_uuid: "ee3107ee-12ae-4887-9b38-9d3acf542a8e",
        return_at_close: 1.0001018128322916,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 64707.25,
        close_ms: 1711201213846,
        current_return: 0.9984347039937566,
        initial_entry_price: 64707.25,
        is_closed_position: true,
        miner_hotkey: "5H8miZycwvhUnF9Bc38J7Gxgn5gHuygFKbf9anRpMes93Zd8",
        net_leverage: 0.0,
        open_ms: 1711197633525,
        orders: [
          {
            leverage: 0.6,
            order_type: "LONG",
            order_uuid: "c19ac9d3-2101-45e2-8521-8e9c6fdbf454",
            price: 64707.25,
            price_sources: [],
            processed_ms: 1711197633524,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "9dced1ea-a6f6-43e7-972b-4bcbdab2bf99",
            price: 64538.44,
            price_sources: [],
            processed_ms: 1711201213846,
          },
        ],
        position_type: "FLAT",
        position_uuid: "602555a2-a81a-4312-a618-b86adbf06200",
        return_at_close: 0.9972365823489641,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 64497.5,
        close_ms: 1711204858659,
        current_return: 1.0063742005504088,
        initial_entry_price: 64497.5,
        is_closed_position: true,
        miner_hotkey: "5H8miZycwvhUnF9Bc38J7Gxgn5gHuygFKbf9anRpMes93Zd8",
        net_leverage: 0.0,
        open_ms: 1711201316569,
        orders: [
          {
            leverage: 0.6,
            order_type: "LONG",
            order_uuid: "e7ed6264-a94e-4064-a27d-be2df588e408",
            price: 64497.5,
            price_sources: [],
            processed_ms: 1711201316568,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "9ae0ceb6-21e5-4d4e-aee4-479814959b33",
            price: 65182.7,
            price_sources: [],
            processed_ms: 1711204858659,
          },
        ],
        position_type: "FLAT",
        position_uuid: "edc462d9-b16b-4b12-b051-d96a8cc08101",
        return_at_close: 1.0051665515097483,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 65105.09,
        close_ms: 1711208518648,
        current_return: 0.9977846893384219,
        initial_entry_price: 65105.09,
        is_closed_position: true,
        miner_hotkey: "5H8miZycwvhUnF9Bc38J7Gxgn5gHuygFKbf9anRpMes93Zd8",
        net_leverage: 0.0,
        open_ms: 1711204982352,
        orders: [
          {
            leverage: 0.6,
            order_type: "LONG",
            order_uuid: "7a768c7f-a2dd-497d-8bc8-8fc7b0560b89",
            price: 65105.09,
            price_sources: [],
            processed_ms: 1711204982351,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "76b81ff4-e72a-47c9-9521-9eaf5f687dcf",
            price: 64864.71,
            price_sources: [],
            processed_ms: 1711208518648,
          },
        ],
        position_type: "FLAT",
        position_uuid: "786e413c-6851-4e36-b6b4-32ae52743db2",
        return_at_close: 0.9965873477112158,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 64845.58,
        close_ms: 1711212180547,
        current_return: 1.005928237514415,
        initial_entry_price: 64845.58,
        is_closed_position: true,
        miner_hotkey: "5H8miZycwvhUnF9Bc38J7Gxgn5gHuygFKbf9anRpMes93Zd8",
        net_leverage: 0.0,
        open_ms: 1711208605499,
        orders: [
          {
            leverage: 0.6,
            order_type: "LONG",
            order_uuid: "73075df3-41da-471d-8d40-568140dae4bd",
            price: 64845.58,
            price_sources: [],
            processed_ms: 1711208605498,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "ac6e02aa-899b-4046-8ecc-ebee76d8088f",
            price: 65486.28,
            price_sources: [],
            processed_ms: 1711212180547,
          },
        ],
        position_type: "FLAT",
        position_uuid: "cf475549-b0dc-41d6-8d7e-a43b59929ce3",
        return_at_close: 1.0047211236293976,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 65668.43,
        close_ms: 1711215841809,
        current_return: 1.0005628275261036,
        initial_entry_price: 65668.43,
        is_closed_position: true,
        miner_hotkey: "5H8miZycwvhUnF9Bc38J7Gxgn5gHuygFKbf9anRpMes93Zd8",
        net_leverage: 0.0,
        open_ms: 1711212267611,
        orders: [
          {
            leverage: 0.6,
            order_type: "LONG",
            order_uuid: "635dc949-7147-4009-bbf9-9c6c559454e0",
            price: 65668.43,
            price_sources: [],
            processed_ms: 1711212267610,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "d288a1b8-b15f-4cd8-9a50-ea7f14ccad27",
            price: 65730.03,
            price_sources: [],
            processed_ms: 1711215841809,
          },
        ],
        position_type: "FLAT",
        position_uuid: "cbb75139-f4b8-472e-9fa9-d0c4c05a3631",
        return_at_close: 0.9993621521330723,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 65647.49,
        close_ms: 1711219506823,
        current_return: 0.9980190560217915,
        initial_entry_price: 65647.49,
        is_closed_position: true,
        miner_hotkey: "5H8miZycwvhUnF9Bc38J7Gxgn5gHuygFKbf9anRpMes93Zd8",
        net_leverage: 0.0,
        open_ms: 1711215929040,
        orders: [
          {
            leverage: 0.6,
            order_type: "LONG",
            order_uuid: "db3f1994-bffc-4e4a-8b19-167a622f456b",
            price: 65647.49,
            price_sources: [],
            processed_ms: 1711215929039,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "45fac34f-4683-4066-af5a-5b30337ca682",
            price: 65430.75,
            price_sources: [],
            processed_ms: 1711219506823,
          },
        ],
        position_type: "FLAT",
        position_uuid: "253460b8-abea-4ca2-8f34-70da5160f3a6",
        return_at_close: 0.9968214331545654,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 65351.409999999996,
        close_ms: 1711223185611,
        current_return: 0.9951968901665627,
        initial_entry_price: 65351.41,
        is_closed_position: true,
        miner_hotkey: "5H8miZycwvhUnF9Bc38J7Gxgn5gHuygFKbf9anRpMes93Zd8",
        net_leverage: 0.0,
        open_ms: 1711219592659,
        orders: [
          {
            leverage: 0.6,
            order_type: "LONG",
            order_uuid: "e1d11a8a-1e5f-4646-b15e-264b8904c35c",
            price: 65351.41,
            price_sources: [],
            processed_ms: 1711219592659,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "a946360f-2a7f-457f-948a-7f7423f95ebd",
            price: 64828.26,
            price_sources: [],
            processed_ms: 1711223185611,
          },
        ],
        position_type: "FLAT",
        position_uuid: "d94b39c0-c9ff-4ec9-8a1e-4a096bcd403f",
        return_at_close: 0.9940026538983628,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 64918.814999999995,
        close_ms: 1711226893089,
        current_return: 1.0007776897647649,
        initial_entry_price: 64876.77,
        is_closed_position: true,
        miner_hotkey: "5H8miZycwvhUnF9Bc38J7Gxgn5gHuygFKbf9anRpMes93Zd8",
        net_leverage: 0.0,
        open_ms: 1711223271708,
        orders: [
          {
            leverage: 0.6,
            order_type: "LONG",
            order_uuid: "332f5c2f-1a17-4601-adb9-27ebae0af3d0",
            price: 64876.77,
            price_sources: [],
            processed_ms: 1711223271707,
          },
          {
            leverage: 0.6,
            order_type: "LONG",
            order_uuid: "7b87b2d4-61a7-4da7-811f-d6ed6a429585",
            price: 64960.86,
            price_sources: [],
            processed_ms: 1711226873831,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "12739d30-a507-4b82-a809-dd1ff2b56e66",
            price: 64960.86,
            price_sources: [],
            processed_ms: 1711226893089,
          },
        ],
        position_type: "FLAT",
        position_uuid: "80d50394-91d2-41ea-a20f-052893d690ec",
        return_at_close: 0.9983758233093295,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 64948.44,
        close_ms: 1711234151774,
        current_return: 0.9993265427160375,
        initial_entry_price: 64948.44,
        is_closed_position: true,
        miner_hotkey: "5H8miZycwvhUnF9Bc38J7Gxgn5gHuygFKbf9anRpMes93Zd8",
        net_leverage: 0.0,
        open_ms: 1711230607674,
        orders: [
          {
            leverage: 0.6,
            order_type: "LONG",
            order_uuid: "ef1d7b22-ec67-410d-a35f-5e85a96afb27",
            price: 64948.44,
            price_sources: [],
            processed_ms: 1711230607673,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "2db6a68f-07dd-421f-8bb7-601f3bcb28f3",
            price: 64875.54,
            price_sources: [],
            processed_ms: 1711234151774,
          },
        ],
        position_type: "FLAT",
        position_uuid: "e6dba886-3dd5-4b93-b756-0f1b19866479",
        return_at_close: 0.9981273508647782,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 64826.46,
        close_ms: 1711237818828,
        current_return: 0.9951882610896847,
        initial_entry_price: 64826.46,
        is_closed_position: true,
        miner_hotkey: "5H8miZycwvhUnF9Bc38J7Gxgn5gHuygFKbf9anRpMes93Zd8",
        net_leverage: 0.0,
        open_ms: 1711234238610,
        orders: [
          {
            leverage: 0.6,
            order_type: "LONG",
            order_uuid: "8c5775df-088e-4886-8ea4-9b4e66f82c5b",
            price: 64826.46,
            price_sources: [],
            processed_ms: 1711234238609,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "29c4aad8-d4fd-4ae2-8088-85b720cedc3d",
            price: 64306.58,
            price_sources: [],
            processed_ms: 1711237818828,
          },
        ],
        position_type: "FLAT",
        position_uuid: "0795e0dc-f5d5-41b3-80ac-b9c81436b202",
        return_at_close: 0.9939940351763771,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 64393.22,
        close_ms: 1711241508406,
        current_return: 1.001618866085591,
        initial_entry_price: 64393.22,
        is_closed_position: true,
        miner_hotkey: "5H8miZycwvhUnF9Bc38J7Gxgn5gHuygFKbf9anRpMes93Zd8",
        net_leverage: 0.0,
        open_ms: 1711237905518,
        orders: [
          {
            leverage: 0.6,
            order_type: "LONG",
            order_uuid: "3fca04de-b630-4273-9cab-4636a43ac8a3",
            price: 64393.22,
            price_sources: [],
            processed_ms: 1711237905517,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "9703fdd8-9519-4e7e-9e87-0b77f3ed12dd",
            price: 64566.96,
            price_sources: [],
            processed_ms: 1711241508406,
          },
        ],
        position_type: "FLAT",
        position_uuid: "2cb897ba-5b9b-401b-9a20-d9ac06c68574",
        return_at_close: 1.0004169234462883,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 64566.96,
        close_ms: 1711245140180,
        current_return: 0.9996775440565887,
        initial_entry_price: 64566.96,
        is_closed_position: true,
        miner_hotkey: "5H8miZycwvhUnF9Bc38J7Gxgn5gHuygFKbf9anRpMes93Zd8",
        net_leverage: 0.0,
        open_ms: 1711241527360,
        orders: [
          {
            leverage: 0.6,
            order_type: "LONG",
            order_uuid: "92de37c6-fefb-40b4-b94c-f17abde8809b",
            price: 64566.96,
            price_sources: [],
            processed_ms: 1711241527359,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "9250b46f-3e11-47ed-9633-754f2756a34e",
            price: 64532.26,
            price_sources: [],
            processed_ms: 1711245140180,
          },
        ],
        position_type: "FLAT",
        position_uuid: "c260bdc0-7545-4309-91af-f65126486aa3",
        return_at_close: 0.9984779310037208,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 64543.100000000006,
        close_ms: 1711248804634,
        current_return: 0.9971504622492566,
        initial_entry_price: 64543.1,
        is_closed_position: true,
        miner_hotkey: "5H8miZycwvhUnF9Bc38J7Gxgn5gHuygFKbf9anRpMes93Zd8",
        net_leverage: 0.0,
        open_ms: 1711245275918,
        orders: [
          {
            leverage: 0.6,
            order_type: "LONG",
            order_uuid: "f5897bd8-e4fa-4db3-8efa-2b7ee0231a2a",
            price: 64543.1,
            price_sources: [],
            processed_ms: 1711245275916,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "11e1d916-7abf-4c27-bdae-f056d7a11826",
            price: 64236.57,
            price_sources: [],
            processed_ms: 1711248804634,
          },
        ],
        position_type: "FLAT",
        position_uuid: "15903e1a-5aed-4ee1-9d40-3297f2647a7a",
        return_at_close: 0.9959538816945576,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 64213.990000000005,
        close_ms: 1711252462901,
        current_return: 0.9986433797370323,
        initial_entry_price: 64213.99,
        is_closed_position: true,
        miner_hotkey: "5H8miZycwvhUnF9Bc38J7Gxgn5gHuygFKbf9anRpMes93Zd8",
        net_leverage: 0.0,
        open_ms: 1711248891601,
        orders: [
          {
            leverage: 0.6,
            order_type: "LONG",
            order_uuid: "d7573493-f137-4141-bf5b-282a2c55dd90",
            price: 64213.99,
            price_sources: [],
            processed_ms: 1711248891596,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "2b4ff886-cbb1-428c-ae78-6e3c9277a747",
            price: 64068.8,
            price_sources: [],
            processed_ms: 1711252462901,
          },
        ],
        position_type: "FLAT",
        position_uuid: "3499ac93-6b19-4711-a6be-ee4e56a29d9a",
        return_at_close: 0.9974450076813479,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 64099.49,
        close_ms: 1711260245881,
        current_return: 1.0014909322991494,
        initial_entry_price: 64099.49,
        is_closed_position: true,
        miner_hotkey: "5H8miZycwvhUnF9Bc38J7Gxgn5gHuygFKbf9anRpMes93Zd8",
        net_leverage: 0.0,
        open_ms: 1711252615200,
        orders: [
          {
            leverage: 0.6,
            order_type: "LONG",
            order_uuid: "4b60fba6-def0-47e2-b5bf-927502398645",
            price: 64099.49,
            price_sources: [],
            processed_ms: 1711252615199,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "8810d71d-4907-4f91-aa5b-5e9dbe26fbc7",
            price: 64258.77,
            price_sources: [],
            processed_ms: 1711260245881,
          },
        ],
        position_type: "FLAT",
        position_uuid: "93f4263a-fe65-4ab8-a703-627d56b45180",
        return_at_close: 1.0002891431803904,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 64258.44,
        close_ms: 1711263885722,
        current_return: 0.9975691909109528,
        initial_entry_price: 64258.44,
        is_closed_position: true,
        miner_hotkey: "5H8miZycwvhUnF9Bc38J7Gxgn5gHuygFKbf9anRpMes93Zd8",
        net_leverage: 0.0,
        open_ms: 1711260348580,
        orders: [
          {
            leverage: 1.25,
            order_type: "LONG",
            order_uuid: "89b6a714-ded8-4bae-9b33-1c7186aad20c",
            price: 64258.44,
            price_sources: [],
            processed_ms: 1711260348579,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "26f00129-9524-4e51-b2a5-3a04df91e263",
            price: 64133.48,
            price_sources: [],
            processed_ms: 1711263885722,
          },
        ],
        position_type: "FLAT",
        position_uuid: "56629bd2-b02c-43c8-9de2-84e43a9ad178",
        return_at_close: 0.9950752679336754,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 64180.030000000006,
        close_ms: 1711267544958,
        current_return: 1.0080342358830308,
        initial_entry_price: 64180.03,
        is_closed_position: true,
        miner_hotkey: "5H8miZycwvhUnF9Bc38J7Gxgn5gHuygFKbf9anRpMes93Zd8",
        net_leverage: 0.0,
        open_ms: 1711264016879,
        orders: [
          {
            leverage: 1.25,
            order_type: "LONG",
            order_uuid: "54c6b1e0-8b14-4035-bf84-5e933487c988",
            price: 64180.03,
            price_sources: [],
            processed_ms: 1711264016878,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "417bad0e-2f6b-4c2c-957b-ed223c02dbd0",
            price: 64592.54,
            price_sources: [],
            processed_ms: 1711267544958,
          },
        ],
        position_type: "FLAT",
        position_uuid: "910d34aa-06a6-4cc9-8481-5e09febc7b51",
        return_at_close: 1.0055141502933231,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 64600.94,
        close_ms: 1711271206884,
        current_return: 1.0086537053485598,
        initial_entry_price: 64600.94,
        is_closed_position: true,
        miner_hotkey: "5H8miZycwvhUnF9Bc38J7Gxgn5gHuygFKbf9anRpMes93Zd8",
        net_leverage: 0.0,
        open_ms: 1711267631599,
        orders: [
          {
            leverage: 1.25,
            order_type: "LONG",
            order_uuid: "6ad34f83-1c77-4beb-964b-0e85e81f8e2e",
            price: 64600.94,
            price_sources: [],
            processed_ms: 1711267631598,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "106b0bd1-12dd-4057-9194-f4a629bf1d83",
            price: 65048.17,
            price_sources: [],
            processed_ms: 1711271206884,
          },
        ],
        position_type: "FLAT",
        position_uuid: "4dea7b17-94cf-4b48-924c-c5c5fa52f0f6",
        return_at_close: 1.0061320710851884,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 64988.73,
        close_ms: 1711278541178,
        current_return: 1.0004546942215982,
        initial_entry_price: 64988.73,
        is_closed_position: true,
        miner_hotkey: "5H8miZycwvhUnF9Bc38J7Gxgn5gHuygFKbf9anRpMes93Zd8",
        net_leverage: 0.0,
        open_ms: 1711274976664,
        orders: [
          {
            leverage: 1.25,
            order_type: "LONG",
            order_uuid: "81b28589-b9c4-4414-a81c-e1e88a03b3fa",
            price: 64988.73,
            price_sources: [],
            processed_ms: 1711274976663,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "54655dca-d71a-48fe-b69f-d4d5ac6110ec",
            price: 65012.37,
            price_sources: [],
            processed_ms: 1711278541178,
          },
        ],
        position_type: "FLAT",
        position_uuid: "2f13e385-d057-4d07-b05c-a0aa8ad1d7d9",
        return_at_close: 0.9979535574860442,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 64795.18000000001,
        close_ms: 1711314985674,
        current_return: 1.0304012050883764,
        initial_entry_price: 64964.53,
        is_closed_position: true,
        miner_hotkey: "5H8miZycwvhUnF9Bc38J7Gxgn5gHuygFKbf9anRpMes93Zd8",
        net_leverage: 0.0,
        open_ms: 1711278669164,
        orders: [
          {
            leverage: 1.25,
            order_type: "LONG",
            order_uuid: "c20cf9a1-0d16-4b5e-acd2-43735e108e54",
            price: 64964.53,
            price_sources: [],
            processed_ms: 1711278669162,
          },
          {
            leverage: 1.25,
            order_type: "LONG",
            order_uuid: "76f6c294-f9f1-42e7-a4a9-3eaafca6696c",
            price: 66198.02,
            price_sources: [],
            processed_ms: 1711311265842,
          },
          {
            leverage: -1.25,
            order_type: "SHORT",
            order_uuid: "2eae0bf8-623b-4ceb-8568-902d57d07e06",
            price: 66367.37,
            price_sources: [],
            processed_ms: 1711312195387,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "6b62f5cc-69f8-4f31-8f80-0f663a22a7c1",
            price: 66375.18,
            price_sources: [],
            processed_ms: 1711314985674,
          },
        ],
        position_type: "FLAT",
        position_uuid: "258501c6-9032-49bc-9028-1543fa6bfc9e",
        return_at_close: 1.0252491990629344,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 66463.87,
        close_ms: 1711318718691,
        current_return: 1.0059677159936669,
        initial_entry_price: 66463.87,
        is_closed_position: true,
        miner_hotkey: "5H8miZycwvhUnF9Bc38J7Gxgn5gHuygFKbf9anRpMes93Zd8",
        net_leverage: 0.0,
        open_ms: 1711315936062,
        orders: [
          {
            leverage: 1.25,
            order_type: "LONG",
            order_uuid: "28eb5482-664c-459b-b162-e90470dd2258",
            price: 66463.87,
            price_sources: [],
            processed_ms: 1711315936061,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "0f6e2c44-d62f-47e4-b56f-6fc20e66cce8",
            price: 66781.18,
            price_sources: [],
            processed_ms: 1711318718691,
          },
        ],
        position_type: "FLAT",
        position_uuid: "e3edab06-6803-4ce4-8d89-b56b85659f56",
        return_at_close: 1.0034527967036828,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 123588.20142857275,
        close_ms: 1711328977162,
        current_return: 1.0029623816505255,
        initial_entry_price: 67164.43,
        is_closed_position: true,
        miner_hotkey: "5H8miZycwvhUnF9Bc38J7Gxgn5gHuygFKbf9anRpMes93Zd8",
        net_leverage: 0.0,
        open_ms: 1711321514808,
        orders: [
          {
            leverage: -1.25,
            order_type: "SHORT",
            order_uuid: "e8b7cd5e-3579-4603-bcbe-508460ceb035",
            price: 67164.43,
            price_sources: [],
            processed_ms: 1711321514807,
          },
          {
            leverage: 0.125,
            order_type: "LONG",
            order_uuid: "5588e571-06be-4fe5-a035-1db319d4a2e8",
            price: 67567.32,
            price_sources: [],
            processed_ms: 1711322460031,
          },
          {
            leverage: 0.125,
            order_type: "LONG",
            order_uuid: "3ca7bf50-1f4f-4fc6-908a-172d1267bb80",
            price: 67271.93,
            price_sources: [],
            processed_ms: 1711323381887,
          },
          {
            leverage: 0.125,
            order_type: "LONG",
            order_uuid: "fecffbd9-1f62-4f61-9a56-c6844ba184ad",
            price: 67334.41,
            price_sources: [],
            processed_ms: 1711324319581,
          },
          {
            leverage: 0.7,
            order_type: "LONG",
            order_uuid: "f8ec7ac4-cda1-4d32-b193-23112aa79f1a",
            price: 66865.16,
            price_sources: [],
            processed_ms: 1711325257908,
          },
          {
            leverage: 0.08750000000000002,
            order_type: "LONG",
            order_uuid: "9d7a1f5d-6de7-4924-97e7-56488fcf7879",
            price: 66875.89,
            price_sources: [],
            processed_ms: 1711326178878,
          },
          {
            leverage: 0.07000000000000002,
            order_type: "LONG",
            order_uuid: "2a16d1e4-6822-4a4c-a7d5-2d74699cd774",
            price: 66601.0,
            price_sources: [],
            processed_ms: 1711327116798,
          },
          {
            leverage: 0.014000000000000002,
            order_type: "LONG",
            order_uuid: "79a318c2-1454-4493-a0f8-bbaf0e62ae94",
            price: 66567.78,
            price_sources: [],
            processed_ms: 1711328042124,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "ea17fed3-5b57-44ee-82bb-bf826cd08eaa",
            price: 66740.58,
            price_sources: [],
            processed_ms: 1711328977162,
          },
        ],
        position_type: "FLAT",
        position_uuid: "3e811835-b8ab-4837-9fd1-65830692f0bb",
        return_at_close: 1.0004549756963992,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 66695.84,
        close_ms: 1711335796711,
        current_return: 0.9984661712034815,
        initial_entry_price: 66695.84,
        is_closed_position: true,
        miner_hotkey: "5H8miZycwvhUnF9Bc38J7Gxgn5gHuygFKbf9anRpMes93Zd8",
        net_leverage: 0.0,
        open_ms: 1711330318726,
        orders: [
          {
            leverage: 1.25,
            order_type: "LONG",
            order_uuid: "cb00890a-f03b-42f0-90ab-ea02e3a44698",
            price: 66695.84,
            price_sources: [],
            processed_ms: 1711330318725,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "511cdb24-965b-4998-90f6-0192ddb076ff",
            price: 66614.0,
            price_sources: [],
            processed_ms: 1711335796711,
          },
        ],
        position_type: "FLAT",
        position_uuid: "259f5b3e-ce2d-43dc-9023-5164d9541089",
        return_at_close: 0.9959700057754728,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 5812.759999998465,
        close_ms: 1711355950437,
        current_return: 0.9982925532294811,
        initial_entry_price: 67203.83,
        is_closed_position: true,
        miner_hotkey: "5H8miZycwvhUnF9Bc38J7Gxgn5gHuygFKbf9anRpMes93Zd8",
        net_leverage: 0.0,
        open_ms: 1711339464132,
        orders: [
          {
            leverage: -1.25,
            order_type: "SHORT",
            order_uuid: "590fa16f-2144-4441-9ad2-8b90d6ddf7a4",
            price: 67203.83,
            price_sources: [],
            processed_ms: 1711339464130,
          },
          {
            leverage: 0.625,
            order_type: "LONG",
            order_uuid: "03ae8a78-7e21-4092-aa74-aaf0545037a2",
            price: 67211.33,
            price_sources: [],
            processed_ms: 1711341335877,
          },
          {
            leverage: 0.125,
            order_type: "LONG",
            order_uuid: "6d10c6f7-d0a8-4302-86ae-b2efc92fe950",
            price: 67617.78,
            price_sources: [],
            processed_ms: 1711343131210,
          },
          {
            leverage: 0.125,
            order_type: "LONG",
            order_uuid: "0fdbb3d5-b58c-4da3-938c-a8151882a18b",
            price: 67676.35,
            price_sources: [],
            processed_ms: 1711344965784,
          },
          {
            leverage: 0.1875,
            order_type: "LONG",
            order_uuid: "0f007ed4-d507-4a93-b4f7-4c699a1f4e0c",
            price: 67282.98,
            price_sources: [],
            processed_ms: 1711346789006,
          },
          {
            leverage: 0.15,
            order_type: "LONG",
            order_uuid: "be9febfe-81f5-45cd-a057-315edd250f62",
            price: 67108.66,
            price_sources: [],
            processed_ms: 1711348622761,
          },
          {
            leverage: 0.018750000000000003,
            order_type: "LONG",
            order_uuid: "1017d87e-cbfc-4ec7-abbf-78f4ba9c8633",
            price: 67288.71,
            price_sources: [],
            processed_ms: 1711350461103,
          },
          {
            leverage: 0.009375000000000001,
            order_type: "LONG",
            order_uuid: "4a740058-6860-4126-9c0f-9e1df8982777",
            price: 67067.14,
            price_sources: [],
            processed_ms: 1711352305811,
          },
          {
            leverage: 0.0075000000000000015,
            order_type: "LONG",
            order_uuid: "7c125797-6814-4860-a4c2-77a924be17a9",
            price: 67035.41,
            price_sources: [],
            processed_ms: 1711354123864,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "0cc7fb76-2b64-4319-949d-3c4a13e9140e",
            price: 67011.14,
            price_sources: [],
            processed_ms: 1711355950437,
          },
        ],
        position_type: "FLAT",
        position_uuid: "44b4f020-26dd-4b97-a955-8b0d8b25efcd",
        return_at_close: 0.9957968218464074,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 66875.3,
        close_ms: 1711361445303,
        current_return: 1.0081646362707906,
        initial_entry_price: 66875.3,
        is_closed_position: true,
        miner_hotkey: "5H8miZycwvhUnF9Bc38J7Gxgn5gHuygFKbf9anRpMes93Zd8",
        net_leverage: 0.0,
        open_ms: 1711357826040,
        orders: [
          {
            leverage: 1.25,
            order_type: "LONG",
            order_uuid: "af92e228-8e8a-4711-92bc-1180c4a47596",
            price: 66875.3,
            price_sources: [],
            processed_ms: 1711357826039,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "9fb34936-6548-4329-8ea6-afba59054f7f",
            price: 67312.11,
            price_sources: [],
            processed_ms: 1711361445303,
          },
        ],
        position_type: "FLAT",
        position_uuid: "3ec39c25-927e-4e32-9825-ca4bf78154dc",
        return_at_close: 1.0056442246801136,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: -948687.6895238123,
        close_ms: 1711386985895,
        current_return: 1.0160110617312466,
        initial_entry_price: 66846.86,
        is_closed_position: true,
        miner_hotkey: "5H8miZycwvhUnF9Bc38J7Gxgn5gHuygFKbf9anRpMes93Zd8",
        net_leverage: 0.0,
        open_ms: 1711365107219,
        orders: [
          {
            leverage: 1.25,
            order_type: "LONG",
            order_uuid: "54161692-e212-4661-9506-7c2866e2d120",
            price: 66846.86,
            price_sources: [],
            processed_ms: 1711365107215,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "3c314508-4c30-461c-a402-2fa79fd620bb",
            price: 66831.7,
            price_sources: [],
            processed_ms: 1711367444678,
          },
          {
            leverage: -0.75,
            order_type: "SHORT",
            order_uuid: "48b44256-7f82-4c43-a275-28f778eeeb87",
            price: 67013.0,
            price_sources: [],
            processed_ms: 1711369281038,
          },
          {
            leverage: -0.375,
            order_type: "SHORT",
            order_uuid: "87337fe7-b13e-4391-afa4-151d27f46be5",
            price: 67118.88,
            price_sources: [],
            processed_ms: 1711371114100,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "29a78e10-dcf8-48e1-ac12-3cb364d0d864",
            price: 66895.37,
            price_sources: [],
            processed_ms: 1711372972514,
          },
          {
            leverage: -0.3125,
            order_type: "SHORT",
            order_uuid: "f12dad53-cfd8-4ab1-81d0-ab04d0f6100f",
            price: 67385.44,
            price_sources: [],
            processed_ms: 1711374774786,
          },
          {
            leverage: -0.25,
            order_type: "SHORT",
            order_uuid: "e87c2585-4f97-4efc-a8b8-0a9b68a966af",
            price: 68868.54,
            price_sources: [],
            processed_ms: 1711376430645,
          },
          {
            leverage: -0.05,
            order_type: "SHORT",
            order_uuid: "0c136d1d-1c57-48b9-bb55-8969f5ed603c",
            price: 69210.38,
            price_sources: [],
            processed_ms: 1711376753000,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "9a5a1409-0cfa-4947-b0ed-bc98afc6883b",
            price: 69095.56,
            price_sources: [],
            processed_ms: 1711377909763,
          },
          {
            leverage: -0.21000000000000002,
            order_type: "SHORT",
            order_uuid: "15de188d-e664-4cce-9914-827824f1106b",
            price: 69061.84,
            price_sources: [],
            processed_ms: 1711378190919,
          },
          {
            leverage: -0.041999999999999996,
            order_type: "SHORT",
            order_uuid: "b6b26553-6a33-4783-8f76-52ae87cd3451",
            price: 69808.57,
            price_sources: [],
            processed_ms: 1711381527277,
          },
          {
            leverage: -0.005249999999999998,
            order_type: "SHORT",
            order_uuid: "0fd6455f-9f7e-4196-bd5d-35c2d68ae2d7",
            price: 69701.12,
            price_sources: [],
            processed_ms: 1711383388080,
          },
          {
            leverage: -0.004199999999999998,
            order_type: "SHORT",
            order_uuid: "09dbd105-c06c-4528-82b9-237cd2645479",
            price: 70129.37,
            price_sources: [],
            processed_ms: 1711385172869,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "38638854-e342-4e2b-b757-795cfdf033be",
            price: 70635.36,
            price_sources: [],
            processed_ms: 1711386985895,
          },
        ],
        position_type: "FLAT",
        position_uuid: "cda92502-65fa-45d5-8837-9e761ed838d0",
        return_at_close: 1.012963028546053,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 69107.86428571427,
        close_ms: 1711398225206,
        current_return: 0.9904443797073564,
        initial_entry_price: 70404.43,
        is_closed_position: true,
        miner_hotkey: "5H8miZycwvhUnF9Bc38J7Gxgn5gHuygFKbf9anRpMes93Zd8",
        net_leverage: 0.0,
        open_ms: 1711388808187,
        orders: [
          {
            leverage: -1.25,
            order_type: "SHORT",
            order_uuid: "0faed25c-514c-4ac3-b4e3-f5883c82c0cc",
            price: 70404.43,
            price_sources: [],
            processed_ms: 1711388808185,
          },
          {
            leverage: 0.8999999999999999,
            order_type: "LONG",
            order_uuid: "124f76b4-eaad-43d9-8068-98cdc3afbbfd",
            price: 70908.65,
            price_sources: [],
            processed_ms: 1711396407087,
          },
          {
            leverage: 0.8999999999999999,
            order_type: "LONG",
            order_uuid: "2dedf980-358c-4073-b0a4-5ab86b1feff3",
            price: 71030.03,
            price_sources: [],
            processed_ms: 1711398225206,
          },
        ],
        position_type: "FLAT",
        position_uuid: "7cc49e48-dd81-4d21-82ab-cc50b5668f44",
        return_at_close: 0.9879682687580881,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 74757.06999999999,
        close_ms: 1711403673042,
        current_return: 1.0408205314301273,
        initial_entry_price: 70986.19,
        is_closed_position: true,
        miner_hotkey: "5H8miZycwvhUnF9Bc38J7Gxgn5gHuygFKbf9anRpMes93Zd8",
        net_leverage: 0.0,
        open_ms: 1711400044258,
        orders: [
          {
            leverage: -3.0,
            order_type: "SHORT",
            order_uuid: "21a4fcb2-8c66-44e4-bef8-fa9ac97e6b0d",
            price: 70986.19,
            price_sources: [],
            processed_ms: 1711400044256,
          },
          {
            leverage: 2.4,
            order_type: "LONG",
            order_uuid: "1cce1ed7-d2dd-4bc2-a0d7-184f3fb36b01",
            price: 70043.47,
            price_sources: [],
            processed_ms: 1711401881956,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "a66f7a3d-c559-43dd-8c6d-7608cf18afe2",
            price: 69927.58,
            price_sources: [],
            processed_ms: 1711403673042,
          },
        ],
        position_type: "FLAT",
        position_uuid: "948caee0-5098-4b3b-b16f-ad8f45b1679c",
        return_at_close: 1.0345756082415465,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 69325.06903225782,
        close_ms: 1711421851980,
        current_return: 1.0094223857662428,
        initial_entry_price: 70385.06,
        is_closed_position: true,
        miner_hotkey: "5H8miZycwvhUnF9Bc38J7Gxgn5gHuygFKbf9anRpMes93Zd8",
        net_leverage: 0.0,
        open_ms: 1711405498234,
        orders: [
          {
            leverage: 3.0,
            order_type: "LONG",
            order_uuid: "6bd2a2e4-3e88-405b-a03b-1973717165f3",
            price: 70385.06,
            price_sources: [],
            processed_ms: 1711405498232,
          },
          {
            leverage: 0.8999999999999999,
            order_type: "LONG",
            order_uuid: "ffdf47a6-fe94-41ee-ad55-3b50d94e7245",
            price: 70337.87,
            price_sources: [],
            processed_ms: 1711407306335,
          },
          {
            leverage: -1.95,
            order_type: "SHORT",
            order_uuid: "7e941885-23dd-486d-8868-cca321c073ae",
            price: 70342.5,
            price_sources: [],
            processed_ms: 1711409128070,
          },
          {
            leverage: 0.8999999999999997,
            order_type: "LONG",
            order_uuid: "7f0ff183-31c5-4735-b29f-07710b2a3704",
            price: 69924.06,
            price_sources: [],
            processed_ms: 1711410976208,
          },
          {
            leverage: 0.8999999999999999,
            order_type: "LONG",
            order_uuid: "3cc31d68-227e-4bc3-bc9b-6b41b76cb913",
            price: 69899.66,
            price_sources: [],
            processed_ms: 1711412762057,
          },
          {
            leverage: 0.8999999999999999,
            order_type: "LONG",
            order_uuid: "6a5280ec-6161-4e22-a1d2-f9f498bccb28",
            price: 69929.34,
            price_sources: [],
            processed_ms: 1711414576035,
          },
          {
            leverage: -2.3249999999999997,
            order_type: "SHORT",
            order_uuid: "77b8ec91-2f2e-4e60-9cd8-60a1471e315d",
            price: 70199.55,
            price_sources: [],
            processed_ms: 1711416393254,
          },
          {
            leverage: -1.1624999999999999,
            order_type: "SHORT",
            order_uuid: "9a024c6b-f563-4b84-976c-763271ca9f78",
            price: 70299.97,
            price_sources: [],
            processed_ms: 1711418208111,
          },
          {
            leverage: -0.5812499999999999,
            order_type: "SHORT",
            order_uuid: "c4b4a620-1f9e-47c2-9aab-b4bfab1eea82",
            price: 70255.96,
            price_sources: [],
            processed_ms: 1711420055663,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "3cd12066-fd46-4b99-850d-b09fbb7fa997",
            price: 70466.05,
            price_sources: [],
            processed_ms: 1711421851980,
          },
        ],
        position_type: "FLAT",
        position_uuid: "f7015953-801e-4d97-ad35-19589ddae6de",
        return_at_close: 1.0000347575786168,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 70051.75999999983,
        close_ms: 1711430918244,
        current_return: 0.9985757971637099,
        initial_entry_price: 70483.64,
        is_closed_position: true,
        miner_hotkey: "5H8miZycwvhUnF9Bc38J7Gxgn5gHuygFKbf9anRpMes93Zd8",
        net_leverage: 0.0,
        open_ms: 1711423655037,
        orders: [
          {
            leverage: -3.0,
            order_type: "SHORT",
            order_uuid: "add85c9a-3002-4f2c-b33c-c3c0b31a0445",
            price: 70483.64,
            price_sources: [],
            processed_ms: 1711423655034,
          },
          {
            leverage: 0.8999999999999999,
            order_type: "LONG",
            order_uuid: "a210160c-87b0-4841-be1e-12c723d0d3a4",
            price: 70442.56,
            price_sources: [],
            processed_ms: 1711425473244,
          },
          {
            leverage: 0.8999999999999999,
            order_type: "LONG",
            order_uuid: "9d885174-1bc0-4c9d-892f-7153f83d9783",
            price: 70567.1,
            price_sources: [],
            processed_ms: 1711427290467,
          },
          {
            leverage: 0.8999999999999999,
            order_type: "LONG",
            order_uuid: "0806ae95-cf49-4fca-b165-c14931857717",
            price: 70585.22,
            price_sources: [],
            processed_ms: 1711429098108,
          },
          {
            leverage: 0.8999999999999999,
            order_type: "LONG",
            order_uuid: "0f0ba1b4-97ec-49c6-9c24-e5dfc99eaba6",
            price: 70386.37,
            price_sources: [],
            processed_ms: 1711430918244,
          },
        ],
        position_type: "FLAT",
        position_uuid: "fbd28c5c-240c-486d-82e0-45ee9023b1f0",
        return_at_close: 0.9925843423807277,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 70628.88,
        close_ms: 1711437903673,
        current_return: 0.9695382398814767,
        initial_entry_price: 70628.88,
        is_closed_position: true,
        miner_hotkey: "5H8miZycwvhUnF9Bc38J7Gxgn5gHuygFKbf9anRpMes93Zd8",
        net_leverage: 0.0,
        open_ms: 1711436398486,
        orders: [
          {
            leverage: -3.0,
            order_type: "SHORT",
            order_uuid: "006d4a81-0733-4280-83f8-d5f625bd68cf",
            price: 70628.88,
            price_sources: [],
            processed_ms: 1711436398483,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "0606756a-de1b-478f-9daf-df37b76dc072",
            price: 71346.04,
            price_sources: [],
            processed_ms: 1711437903673,
          },
        ],
        position_type: "FLAT",
        position_uuid: "b770387e-a7d8-4e5a-8193-c2e874352519",
        return_at_close: 0.9637210104421878,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 74884.08,
        close_ms: 1711441818680,
        current_return: 1.035818575805963,
        initial_entry_price: 71418.92,
        is_closed_position: true,
        miner_hotkey: "5H8miZycwvhUnF9Bc38J7Gxgn5gHuygFKbf9anRpMes93Zd8",
        net_leverage: 0.0,
        open_ms: 1711438196086,
        orders: [
          {
            leverage: -3.0,
            order_type: "SHORT",
            order_uuid: "bb54d5a5-3304-4340-bbb1-3163966bb37e",
            price: 71418.92,
            price_sources: [],
            processed_ms: 1711438196084,
          },
          {
            leverage: 2.4,
            order_type: "LONG",
            order_uuid: "9b84695f-befa-4d08-a525-46612ae6765a",
            price: 70552.63,
            price_sources: [],
            processed_ms: 1711440001153,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "5d699487-98a5-4db1-9ecd-be9271a05317",
            price: 70620.54,
            price_sources: [],
            processed_ms: 1711441818680,
          },
        ],
        position_type: "FLAT",
        position_uuid: "5ab056d8-5159-479a-a50a-5a87eaffa3f2",
        return_at_close: 1.0296036643511273,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 71667.19000000002,
        close_ms: 1711454527410,
        current_return: 1.0036387387538288,
        initial_entry_price: 71045.29,
        is_closed_position: true,
        miner_hotkey: "5H8miZycwvhUnF9Bc38J7Gxgn5gHuygFKbf9anRpMes93Zd8",
        net_leverage: 0.0,
        open_ms: 1711445447546,
        orders: [
          {
            leverage: -3.0,
            order_type: "SHORT",
            order_uuid: "5e6a0aa6-f571-44e4-a26e-2cd49c9bef48",
            price: 71045.29,
            price_sources: [],
            processed_ms: 1711445447544,
          },
          {
            leverage: 0.8999999999999999,
            order_type: "LONG",
            order_uuid: "129835b4-98b2-4d15-b7c1-6be454c7c153",
            price: 71161.0,
            price_sources: [],
            processed_ms: 1711447261164,
          },
          {
            leverage: 1.05,
            order_type: "LONG",
            order_uuid: "dc83fbf5-c19b-4bde-b0fd-013c3ac53e2d",
            price: 70933.23,
            price_sources: [],
            processed_ms: 1711449083727,
          },
          {
            leverage: 0.525,
            order_type: "LONG",
            order_uuid: "f18c3a3c-e611-416c-a094-6406cf6ea95c",
            price: 70919.95,
            price_sources: [],
            processed_ms: 1711450900059,
          },
          {
            leverage: 0.2625,
            order_type: "LONG",
            order_uuid: "d2522191-962e-4535-a20d-a56d101eeb33",
            price: 70725.59,
            price_sources: [],
            processed_ms: 1711452710822,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "2b111d00-9da8-45c5-8b6e-bab7f1b699a1",
            price: 70682.37,
            price_sources: [],
            processed_ms: 1711454527410,
          },
        ],
        position_type: "FLAT",
        position_uuid: "edb3a0be-cbca-46e2-9a58-42c5c6387e34",
        return_at_close: 0.9976169063213058,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 70623.99461538461,
        close_ms: 1711463227370,
        current_return: 0.9591941461882971,
        initial_entry_price: 70702.65,
        is_closed_position: true,
        miner_hotkey: "5H8miZycwvhUnF9Bc38J7Gxgn5gHuygFKbf9anRpMes93Zd8",
        net_leverage: 0.0,
        open_ms: 1711459976275,
        orders: [
          {
            leverage: 3.0,
            order_type: "LONG",
            order_uuid: "74eb4859-3ed1-429f-8a1a-3cb0f51e1ab1",
            price: 70702.65,
            price_sources: [],
            processed_ms: 1711459976271,
          },
          {
            leverage: 0.8999999999999999,
            order_type: "LONG",
            order_uuid: "70a87c38-29c1-4b2d-a0bb-98fd4ee266df",
            price: 70361.81,
            price_sources: [],
            processed_ms: 1711461799323,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "7549e3da-96dc-4ddd-9eea-b47165bcf46d",
            price: 69884.23,
            price_sources: [],
            processed_ms: 1711463227370,
          },
        ],
        position_type: "FLAT",
        position_uuid: "d73d7a80-009f-46fc-b0b7-2932bbd4c90a",
        return_at_close: 0.9517124318480283,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 69862.57545454545,
        close_ms: 1711471520255,
        current_return: 0.9617372234222161,
        initial_entry_price: 69537.4,
        is_closed_position: true,
        miner_hotkey: "5H8miZycwvhUnF9Bc38J7Gxgn5gHuygFKbf9anRpMes93Zd8",
        net_leverage: 0.0,
        open_ms: 1711463611124,
        orders: [
          {
            leverage: 3.0,
            order_type: "LONG",
            order_uuid: "67ac8b95-00cc-456b-aedb-63cce863f2be",
            price: 69537.4,
            price_sources: [],
            processed_ms: 1711463611119,
          },
          {
            leverage: 0.8999999999999999,
            order_type: "LONG",
            order_uuid: "0e26ff5f-a58d-4df9-9688-f028d0f2a0e1",
            price: 70062.65,
            price_sources: [],
            processed_ms: 1711465426208,
          },
          {
            leverage: 0.8999999999999999,
            order_type: "LONG",
            order_uuid: "16bb438b-52a0-4c90-b47a-8122945a1a3d",
            price: 70067.08,
            price_sources: [],
            processed_ms: 1711467248853,
          },
          {
            leverage: 0.8999999999999995,
            order_type: "LONG",
            order_uuid: "cceabecf-ca31-48de-98f8-eaea2d597529",
            price: 70519.76,
            price_sources: [],
            processed_ms: 1711469061138,
          },
          {
            leverage: 0.9000000000000004,
            order_type: "LONG",
            order_uuid: "7cd22a94-ab78-438c-a8a1-8a9eb45fbdab",
            price: 69884.73,
            price_sources: [],
            processed_ms: 1711470878824,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "a62d7b54-c639-430d-9ba9-e1aa764dfb16",
            price: 69459.44,
            price_sources: [],
            processed_ms: 1711471520255,
          },
        ],
        position_type: "FLAT",
        position_uuid: "c6fd8f33-e1e4-41f3-835e-0f6ab91d7afc",
        return_at_close: 0.9490422920730429,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 70020.41727272728,
        close_ms: 1711481102492,
        current_return: 0.9655127531483629,
        initial_entry_price: 69883.92,
        is_closed_position: true,
        miner_hotkey: "5H8miZycwvhUnF9Bc38J7Gxgn5gHuygFKbf9anRpMes93Zd8",
        net_leverage: 0.0,
        open_ms: 1711472694082,
        orders: [
          {
            leverage: 3.0,
            order_type: "LONG",
            order_uuid: "514793af-2a2b-428a-b585-0c2e945879de",
            price: 69883.92,
            price_sources: [],
            processed_ms: 1711472694079,
          },
          {
            leverage: 0.8999999999999999,
            order_type: "LONG",
            order_uuid: "94fd7495-a30b-4c33-9791-f5f5381c9b3b",
            price: 70024.86,
            price_sources: [],
            processed_ms: 1711474507694,
          },
          {
            leverage: 0.8999999999999999,
            order_type: "LONG",
            order_uuid: "f9789e02-35e1-4118-b3b4-92b3255e75e6",
            price: 70183.43,
            price_sources: [],
            processed_ms: 1711476343979,
          },
          {
            leverage: 0.8999999999999995,
            order_type: "LONG",
            order_uuid: "d6dff001-909d-4869-9bb7-cadc1822503e",
            price: 70308.85,
            price_sources: [],
            processed_ms: 1711478148571,
          },
          {
            leverage: 0.9000000000000004,
            order_type: "LONG",
            order_uuid: "e6d9350c-c487-40e6-8e35-8741bac4b228",
            price: 70019.52,
            price_sources: [],
            processed_ms: 1711479960096,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "2e14d31c-37fa-4a98-9837-104e0a22f079",
            price: 69655.25,
            price_sources: [],
            processed_ms: 1711481102492,
          },
        ],
        position_type: "FLAT",
        position_uuid: "31127217-204e-41b6-a1b5-914c585ed344",
        return_at_close: 0.9527679848068046,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
    ],
    thirty_day_returns: 1.0,
  },
  "5HCJ6okRkmCsu7iLEWotBxgcZy11RhbxSzs8MXT4Dei9osUx": {
    all_time_returns: 1.0744327855990357,
    n_positions: 76,
    percentage_profitable: 0.7763157894736842,
    positions: [
      {
        average_entry_price: 112.825,
        close_ms: 1715089147869,
        current_return: 1.002526394170678,
        initial_entry_price: 112.809,
        is_closed_position: true,
        miner_hotkey: "5HCJ6okRkmCsu7iLEWotBxgcZy11RhbxSzs8MXT4Dei9osUx",
        net_leverage: 0.0,
        open_ms: 1715087860884,
        orders: [
          {
            leverage: 2.5,
            order_type: "LONG",
            order_uuid: "50d2d5e1-341b-41fa-a45c-235d35cdb108",
            price: 112.809,
            price_sources: [],
            processed_ms: 1715087860343,
          },
          {
            leverage: 2.5,
            order_type: "LONG",
            order_uuid: "3c02b485-5911-4033-890d-4bd50594ea87",
            price: 112.841,
            price_sources: [],
            processed_ms: 1715088176536,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "cc2b7e5a-4909-4b91-86e0-8816b951ec46",
            price: 112.882,
            price_sources: [],
            processed_ms: 1715089147869,
          },
        ],
        position_type: "FLAT",
        position_uuid: "f445b103-6df2-4b9a-9f4e-21f374a7f260",
        return_at_close: 1.0021755099327183,
        trade_pair: ["CADJPY", "CAD/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 166.28,
        close_ms: 1715089242821,
        current_return: 1.001220832331008,
        initial_entry_price: 166.28,
        is_closed_position: true,
        miner_hotkey: "5HCJ6okRkmCsu7iLEWotBxgcZy11RhbxSzs8MXT4Dei9osUx",
        net_leverage: 0.0,
        open_ms: 1715087943887,
        orders: [
          {
            leverage: 1.0,
            order_type: "LONG",
            order_uuid: "7379b301-1107-450e-beaa-47825dbe9c28",
            price: 166.28,
            price_sources: [],
            processed_ms: 1715087943105,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "f0fe8078-bc99-44b0-bd55-9f2232b2cf72",
            price: 166.483,
            price_sources: [],
            processed_ms: 1715089242821,
          },
        ],
        position_type: "FLAT",
        position_uuid: "e75f5cc9-0015-43fc-854b-51bf91e64652",
        return_at_close: 1.0011507468727447,
        trade_pair: ["EURJPY", "EUR/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 92.818,
        close_ms: 1715094063280,
        current_return: 1.0009911870542352,
        initial_entry_price: 92.818,
        is_closed_position: true,
        miner_hotkey: "5HCJ6okRkmCsu7iLEWotBxgcZy11RhbxSzs8MXT4Dei9osUx",
        net_leverage: 0.0,
        open_ms: 1715088039340,
        orders: [
          {
            leverage: 1.0,
            order_type: "LONG",
            order_uuid: "34c66fd9-e4e1-4b29-bfdf-08ee2881b944",
            price: 92.818,
            price_sources: [],
            processed_ms: 1715088039138,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "9452648c-6afe-44d4-b486-ea6731df39eb",
            price: 92.91,
            price_sources: [],
            processed_ms: 1715094063280,
          },
        ],
        position_type: "FLAT",
        position_uuid: "2837af31-fbe5-45e0-b2db-d388e1723d7a",
        return_at_close: 1.0009211176711414,
        trade_pair: ["NZDJPY", "NZD/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 112.821,
        close_ms: 1715094093459,
        current_return: 1.0052267432074484,
        initial_entry_price: 112.881,
        is_closed_position: true,
        miner_hotkey: "5HCJ6okRkmCsu7iLEWotBxgcZy11RhbxSzs8MXT4Dei9osUx",
        net_leverage: 0.0,
        open_ms: 1715089182908,
        orders: [
          {
            leverage: 5.0,
            order_type: "LONG",
            order_uuid: "2a69a373-849b-4407-9189-0a83b25b6e34",
            price: 112.881,
            price_sources: [],
            processed_ms: 1715089182389,
          },
          {
            leverage: 5.0,
            order_type: "LONG",
            order_uuid: "337ebc47-9341-48f8-af29-91f67e6f1a8b",
            price: 112.761,
            price_sources: [],
            processed_ms: 1715090596482,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "51ed981c-cf58-402a-9f21-e251aeba8c9e",
            price: 112.88,
            price_sources: [],
            processed_ms: 1715094093459,
          },
        ],
        position_type: "FLAT",
        position_uuid: "f36e5efc-01e6-45af-8c4f-9c55f2f5ee35",
        return_at_close: 1.0045230844872033,
        trade_pair: ["CADJPY", "CAD/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 1.4401849999999854,
        close_ms: 1715094261575,
        current_return: 1.0526529418217614,
        initial_entry_price: 1.36735,
        is_closed_position: true,
        miner_hotkey: "5HCJ6okRkmCsu7iLEWotBxgcZy11RhbxSzs8MXT4Dei9osUx",
        net_leverage: 0.0,
        open_ms: 1715087802998,
        orders: [
          {
            leverage: -2.5,
            order_type: "SHORT",
            order_uuid: "086ccc3b-27fb-4c14-83b2-5bc8dfcac3ae",
            price: 1.36735,
            price_sources: [],
            processed_ms: 1715087802818,
          },
          {
            leverage: -2.5,
            order_type: "SHORT",
            order_uuid: "2af7beb3-4e45-49cd-9fd7-4d6ce12601ec",
            price: 1.3678,
            price_sources: [],
            processed_ms: 1715088378277,
          },
          {
            leverage: -5.0,
            order_type: "SHORT",
            order_uuid: "4dbb7332-4329-49d4-9b6e-399c9f3fd7f0",
            price: 1.3686,
            price_sources: [],
            processed_ms: 1715090234972,
          },
          {
            leverage: -10.0,
            order_type: "SHORT",
            order_uuid: "fc8c08f9-d8d3-43bc-b35c-0ad5b514bd7a",
            price: 1.36874,
            price_sources: [],
            processed_ms: 1715090440329,
          },
          {
            leverage: -80.0,
            order_type: "SHORT",
            order_uuid: "b92b0a1d-effb-48e1-8244-6e7273e32e31",
            price: 1.36886,
            price_sources: [],
            processed_ms: 1715091111775,
          },
          {
            leverage: 99.0,
            order_type: "LONG",
            order_uuid: "167a0158-31c3-4086-a901-060866772700",
            price: 1.36911,
            price_sources: [],
            processed_ms: 1715091333093,
          },
          {
            leverage: -100.0,
            order_type: "SHORT",
            order_uuid: "788651f6-8c9e-4c3a-82c3-eccc73f7ef19",
            price: 1.36924,
            price_sources: [],
            processed_ms: 1715091413895,
          },
          {
            leverage: 100.0,
            order_type: "LONG",
            order_uuid: "fe1a8d5e-c16d-435b-8860-e6bf904ec1f3",
            price: 1.36891,
            price_sources: [],
            processed_ms: 1715091520443,
          },
          {
            leverage: -100.0,
            order_type: "SHORT",
            order_uuid: "fe644704-fd2f-458b-8d5c-adf220530010",
            price: 1.3687,
            price_sources: [],
            processed_ms: 1715091929961,
          },
          {
            leverage: -100.0,
            order_type: "SHORT",
            order_uuid: "4ab2be20-92a8-4726-a78f-f3565b8d76bc",
            price: 1.36884,
            price_sources: [],
            processed_ms: 1715092003133,
          },
          {
            leverage: 200.0,
            order_type: "LONG",
            order_uuid: "207ff6e6-93d5-4c82-8cab-3c073a4a34ef",
            price: 1.36861,
            price_sources: [],
            processed_ms: 1715092129944,
          },
          {
            leverage: -50.0,
            order_type: "SHORT",
            order_uuid: "17674c25-107a-4be3-9023-067f2cf0838f",
            price: 1.36867,
            price_sources: [],
            processed_ms: 1715092324802,
          },
          {
            leverage: -100.0,
            order_type: "SHORT",
            order_uuid: "a8970131-969c-4e91-828b-3f2e247cf1ec",
            price: 1.36883,
            price_sources: [],
            processed_ms: 1715092444874,
          },
          {
            leverage: -100.0,
            order_type: "SHORT",
            order_uuid: "45076806-f501-4071-90f1-b95e98b8b14f",
            price: 1.36891,
            price_sources: [],
            processed_ms: 1715092548342,
          },
          {
            leverage: 250.0,
            order_type: "LONG",
            order_uuid: "96c33b85-745f-4f16-ac6e-b7d9263c8c4d",
            price: 1.36867,
            price_sources: [],
            processed_ms: 1715093477428,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "c58b823a-b7b3-4e7a-9ef3-ac6e72e0ac8e",
            price: 1.36819,
            price_sources: [],
            processed_ms: 1715094261575,
          },
        ],
        position_type: "FLAT",
        position_uuid: "4ddec142-1ecf-4af8-a4e9-aba8120824c1",
        return_at_close: 1.0047572329688712,
        trade_pair: ["USDCAD", "USD/CAD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 154.277,
        close_ms: 1715095984972,
        current_return: 1.0014324883164698,
        initial_entry_price: 154.277,
        is_closed_position: true,
        miner_hotkey: "5HCJ6okRkmCsu7iLEWotBxgcZy11RhbxSzs8MXT4Dei9osUx",
        net_leverage: 0.0,
        open_ms: 1715087911011,
        orders: [
          {
            leverage: 1.0,
            order_type: "LONG",
            order_uuid: "2c219a56-5016-49ce-8489-1f973fc63744",
            price: 154.277,
            price_sources: [],
            processed_ms: 1715087910815,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "52751e1e-3cca-4fce-a4d3-86d3c51ca0a8",
            price: 154.498,
            price_sources: [],
            processed_ms: 1715095984972,
          },
        ],
        position_type: "FLAT",
        position_uuid: "1b6d7131-3000-4637-8fd6-cd60fe915db9",
        return_at_close: 1.0013623880422877,
        trade_pair: ["USDJPY", "USD/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 154.519,
        close_ms: 1715141785217,
        current_return: 1.0018411975226347,
        initial_entry_price: 154.519,
        is_closed_position: true,
        miner_hotkey: "5HCJ6okRkmCsu7iLEWotBxgcZy11RhbxSzs8MXT4Dei9osUx",
        net_leverage: 0.0,
        open_ms: 1715096060039,
        orders: [
          {
            leverage: 0.5,
            order_type: "LONG",
            order_uuid: "aa083a33-6503-408d-a98a-4a712f588a99",
            price: 154.519,
            price_sources: [],
            processed_ms: 1715096059849,
          },
          {
            leverage: 0.01,
            order_type: "FLAT",
            order_uuid: "b17b51c1-3dc3-487a-bc0e-6f2a5e7a7a5c",
            price: 155.088,
            price_sources: [],
            processed_ms: 1715141785217,
          },
        ],
        position_type: "FLAT",
        position_uuid: "2ef63281-b474-4c9b-ad1e-25a45fbf1637",
        return_at_close: 1.0018061330807213,
        trade_pair: ["USDJPY", "USD/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 170.2885491649686,
        close_ms: 1715141822816,
        current_return: 1.0016491687705862,
        initial_entry_price: 170.2885491649686,
        is_closed_position: true,
        miner_hotkey: "5HCJ6okRkmCsu7iLEWotBxgcZy11RhbxSzs8MXT4Dei9osUx",
        net_leverage: 0.0,
        open_ms: 1715088080207,
        orders: [
          {
            leverage: 1.0,
            order_type: "LONG",
            order_uuid: "b927e0c8-8b8b-445f-9b56-a85d37c8389e",
            price: 170.2885491649686,
            price_sources: [],
            processed_ms: 1715088080014,
          },
          {
            leverage: 0.01,
            order_type: "FLAT",
            order_uuid: "e1c64123-6998-4f82-a6e7-0be3c32704ce",
            price: 170.5693837222399,
            price_sources: [],
            processed_ms: 1715141822816,
          },
        ],
        position_type: "FLAT",
        position_uuid: "19f8791b-efb8-4afa-934c-d780a274dd58",
        return_at_close: 1.0015790533287723,
        trade_pair: ["CHFJPY", "CHF/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 166.48,
        close_ms: 1715149712688,
        current_return: 1.0020362806343104,
        initial_entry_price: 166.48,
        is_closed_position: true,
        miner_hotkey: "5HCJ6okRkmCsu7iLEWotBxgcZy11RhbxSzs8MXT4Dei9osUx",
        net_leverage: 0.0,
        open_ms: 1715089280217,
        orders: [
          {
            leverage: 1.0,
            order_type: "LONG",
            order_uuid: "ba0e9d15-d0f3-4d6a-a9b5-0d36fdc8d286",
            price: 166.48,
            price_sources: [],
            processed_ms: 1715089280008,
          },
          {
            leverage: 0.01,
            order_type: "FLAT",
            order_uuid: "0ec14fdc-ec40-42f7-a140-e02005e314f9",
            price: 166.819,
            price_sources: [],
            processed_ms: 1715149712688,
          },
        ],
        position_type: "FLAT",
        position_uuid: "9ddf1214-5a6a-4c61-a765-dcc265b6bd89",
        return_at_close: 1.001966138094666,
        trade_pair: ["EURJPY", "EUR/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 155.096,
        close_ms: 1715150073477,
        current_return: 1.0006898952906587,
        initial_entry_price: 155.096,
        is_closed_position: true,
        miner_hotkey: "5HCJ6okRkmCsu7iLEWotBxgcZy11RhbxSzs8MXT4Dei9osUx",
        net_leverage: 0.0,
        open_ms: 1715141857654,
        orders: [
          {
            leverage: 1.0,
            order_type: "LONG",
            order_uuid: "119a0471-194a-4e97-9248-e797d6a09db9",
            price: 155.096,
            price_sources: [],
            processed_ms: 1715141857411,
          },
          {
            leverage: 0.01,
            order_type: "FLAT",
            order_uuid: "916ef0d2-ddb4-4f41-974f-9becc86109bd",
            price: 155.203,
            price_sources: [],
            processed_ms: 1715150073477,
          },
        ],
        position_type: "FLAT",
        position_uuid: "de7f8383-0672-4ca4-9998-07f1c721028c",
        return_at_close: 1.0006198469979883,
        trade_pair: ["USDJPY", "USD/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 92.59100000000001,
        close_ms: 1715184310923,
        current_return: 1.0038141246341883,
        initial_entry_price: 92.944,
        is_closed_position: true,
        miner_hotkey: "5HCJ6okRkmCsu7iLEWotBxgcZy11RhbxSzs8MXT4Dei9osUx",
        net_leverage: 0.0,
        open_ms: 1715094157300,
        orders: [
          {
            leverage: 1.0,
            order_type: "LONG",
            order_uuid: "41e56354-dccd-485d-a04d-892e0b6944fb",
            price: 92.944,
            price_sources: [],
            processed_ms: 1715094156335,
          },
          {
            leverage: -0.5,
            order_type: "SHORT",
            order_uuid: "ced80fd7-5ba8-450c-8934-c29e41bcf6bf",
            price: 93.297,
            price_sources: [],
            processed_ms: 1715184105781,
          },
          {
            leverage: 0.01,
            order_type: "FLAT",
            order_uuid: "c2d4708e-fb7a-49a1-918c-f35004bf9519",
            price: 93.3,
            price_sources: [],
            processed_ms: 1715184310923,
          },
        ],
        position_type: "FLAT",
        position_uuid: "87196b62-29d2-453c-8b52-bd4cd49ac808",
        return_at_close: 1.0037438576454638,
        trade_pair: ["NZDJPY", "NZD/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 169.86876560900723,
        close_ms: 1715244446289,
        current_return: 1.0022178059072742,
        initial_entry_price: 170.5383828045036,
        is_closed_position: true,
        miner_hotkey: "5HCJ6okRkmCsu7iLEWotBxgcZy11RhbxSzs8MXT4Dei9osUx",
        net_leverage: 0.0,
        open_ms: 1715141888369,
        orders: [
          {
            leverage: 0.5,
            order_type: "LONG",
            order_uuid: "46193af1-b637-4856-8f3a-e826a1e9bc82",
            price: 170.5383828045036,
            price_sources: [],
            processed_ms: 1715141888365,
          },
          {
            leverage: -0.25,
            order_type: "SHORT",
            order_uuid: "f15c4641-c0ff-4540-877a-5782fa37c675",
            price: 171.208,
            price_sources: [],
            processed_ms: 1715184693893,
          },
          {
            leverage: 0.001,
            order_type: "FLAT",
            order_uuid: "e5b7d4d0-4a35-4ac6-a389-b682496050b3",
            price: 171.3816497402105,
            price_sources: [],
            processed_ms: 1715244446289,
          },
        ],
        position_type: "FLAT",
        position_uuid: "0a92da43-f831-4e47-8512-2c0e2f674dc7",
        return_at_close: 1.0021827282840674,
        trade_pair: ["CHFJPY", "CHF/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 1.0756919999999999,
        close_ms: 1715269230297,
        current_return: 1.0042173537844836,
        initial_entry_price: 1.07769,
        is_closed_position: true,
        miner_hotkey: "5HCJ6okRkmCsu7iLEWotBxgcZy11RhbxSzs8MXT4Dei9osUx",
        net_leverage: 0.0,
        open_ms: 1715087922349,
        orders: [
          {
            leverage: 1.0,
            order_type: "LONG",
            order_uuid: "177079ee-e245-470b-aab0-d70d137360ec",
            price: 1.07769,
            price_sources: [],
            processed_ms: 1715087922168,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "3ef7b603-2d82-422f-82b7-72d920b135fa",
            price: 1.07469,
            price_sources: [],
            processed_ms: 1715149852557,
          },
          {
            leverage: -1.5,
            order_type: "SHORT",
            order_uuid: "e10ade1d-8b4b-40e5-b355-8061e6741b15",
            price: 1.07458,
            price_sources: [],
            processed_ms: 1715194660444,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "99f1def2-15eb-49bb-a9c3-64518c6b964a",
            price: 1.07425,
            price_sources: [],
            processed_ms: 1715235686971,
          },
          {
            leverage: 0.001,
            order_type: "FLAT",
            order_uuid: "ae446d1a-2f41-4aec-877e-581487afe6d6",
            price: 1.07751,
            price_sources: [],
            processed_ms: 1715269230297,
          },
        ],
        position_type: "FLAT",
        position_uuid: "220bdad4-0f63-4c86-aaf7-05c763c15c41",
        return_at_close: 1.003936172925424,
        trade_pair: ["EURUSD", "EUR/USD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 112.433,
        close_ms: 1715321063531,
        current_return: 1.0054267412086793,
        initial_entry_price: 112.867,
        is_closed_position: true,
        miner_hotkey: "5HCJ6okRkmCsu7iLEWotBxgcZy11RhbxSzs8MXT4Dei9osUx",
        net_leverage: 0.0,
        open_ms: 1715094188983,
        orders: [
          {
            leverage: 1.0,
            order_type: "LONG",
            order_uuid: "c7e90767-54b7-4a35-a033-18f6e2324c3e",
            price: 112.867,
            price_sources: [],
            processed_ms: 1715094188789,
          },
          {
            leverage: -0.5,
            order_type: "SHORT",
            order_uuid: "82d249fb-2d54-4708-8f4d-18093ba0c3e7",
            price: 113.301,
            price_sources: [],
            processed_ms: 1715184165614,
          },
          {
            leverage: 0.01,
            order_type: "FLAT",
            order_uuid: "2aa5a146-8ee0-4d73-b964-37765994fac2",
            price: 113.658,
            price_sources: [],
            processed_ms: 1715321063531,
          },
        ],
        position_type: "FLAT",
        position_uuid: "c1e69312-f248-494d-93d8-cdcd73f79644",
        return_at_close: 1.0053563613367946,
        trade_pair: ["CADJPY", "CAD/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.6491200000000008,
        close_ms: 1715369045884,
        current_return: 1.004405585950488,
        initial_entry_price: 0.66166,
        is_closed_position: true,
        miner_hotkey: "5HCJ6okRkmCsu7iLEWotBxgcZy11RhbxSzs8MXT4Dei9osUx",
        net_leverage: 0.0,
        open_ms: 1715088693610,
        orders: [
          {
            leverage: 1.0,
            order_type: "LONG",
            order_uuid: "3d48fa58-5553-4c4c-9ce5-6c25dbe55062",
            price: 0.66166,
            price_sources: [],
            processed_ms: 1715088693336,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "d6699300-a328-4a29-8bc8-689ecf35b535",
            price: 0.65803,
            price_sources: [],
            processed_ms: 1715149913901,
          },
          {
            leverage: -1.5,
            order_type: "SHORT",
            order_uuid: "2b5f746b-ed91-4cbf-a0bf-39c7911ec607",
            price: 0.6577,
            price_sources: [],
            processed_ms: 1715194630344,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "bd73e1f0-dd05-4439-84de-d7d2d6ae76e9",
            price: 0.65705,
            price_sources: [],
            processed_ms: 1715235530889,
          },
          {
            leverage: -2.25,
            order_type: "SHORT",
            order_uuid: "024be935-2daf-412a-b880-d671af44599f",
            price: 0.6602,
            price_sources: [],
            processed_ms: 1715258911017,
          },
          {
            leverage: 0.01,
            order_type: "FLAT",
            order_uuid: "1158f5db-a1d9-4898-affd-0a017e064691",
            price: 0.66078,
            price_sources: [],
            processed_ms: 1715369045884,
          },
        ],
        position_type: "FLAT",
        position_uuid: "2f4ee9a9-f9d0-4e73-bd15-097ff88578cf",
        return_at_close: 1.0041243523864218,
        trade_pair: ["AUDUSD", "AUD/USD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 165.732,
        close_ms: 1715581583751,
        current_return: 1.0032268604281698,
        initial_entry_price: 166.803,
        is_closed_position: true,
        miner_hotkey: "5HCJ6okRkmCsu7iLEWotBxgcZy11RhbxSzs8MXT4Dei9osUx",
        net_leverage: 0.0,
        open_ms: 1715149994109,
        orders: [
          {
            leverage: 0.5,
            order_type: "LONG",
            order_uuid: "6b173b07-b943-44bf-af43-8efe573456ec",
            price: 166.803,
            price_sources: [],
            processed_ms: 1715149993821,
          },
          {
            leverage: -0.25,
            order_type: "SHORT",
            order_uuid: "6b722b0d-e9ce-4546-83ea-07a3eacaaeb5",
            price: 167.874,
            price_sources: [],
            processed_ms: 1715369133637,
          },
          {
            leverage: 0.001,
            order_type: "FLAT",
            order_uuid: "390e2bc0-3e10-40f8-9abb-191f02cf3e45",
            price: 167.885,
            price_sources: [],
            processed_ms: 1715581583751,
          },
        ],
        position_type: "FLAT",
        position_uuid: "b00637c4-b206-4249-8615-7f64e7548528",
        return_at_close: 1.0031917474880547,
        trade_pair: ["EURJPY", "EUR/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 1.2401899999999977,
        close_ms: 1715607958924,
        current_return: 1.0031690168881533,
        initial_entry_price: 1.25591,
        is_closed_position: true,
        miner_hotkey: "5HCJ6okRkmCsu7iLEWotBxgcZy11RhbxSzs8MXT4Dei9osUx",
        net_leverage: 0.0,
        open_ms: 1715088727415,
        orders: [
          {
            leverage: 1.0,
            order_type: "LONG",
            order_uuid: "3c154285-0c9c-4073-8995-0ebd4d892488",
            price: 1.25591,
            price_sources: [],
            processed_ms: 1715088727021,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "6f240d03-1cb7-4391-a180-6ecc332e33ad",
            price: 1.24876,
            price_sources: [],
            processed_ms: 1715149808313,
          },
          {
            leverage: -1.0,
            order_type: "SHORT",
            order_uuid: "cc1e8fcf-3cb9-4618-aa1c-663f1d9d5a78",
            price: 1.24963,
            price_sources: [],
            processed_ms: 1715194694268,
          },
          {
            leverage: 1.0,
            order_type: "LONG",
            order_uuid: "cb910caf-bbaa-475f-bee9-821d36cf352a",
            price: 1.24825,
            price_sources: [],
            processed_ms: 1715235574697,
          },
          {
            leverage: 1.0,
            order_type: "LONG",
            order_uuid: "2aa4349c-a964-4f52-8efd-040a959e4cf8",
            price: 1.24823,
            price_sources: [],
            processed_ms: 1715235614260,
          },
          {
            leverage: -3.0,
            order_type: "SHORT",
            order_uuid: "cd76b4f1-2363-4cae-ac6b-b2b60c4d3911",
            price: 1.25103,
            price_sources: [],
            processed_ms: 1715269152827,
          },
          {
            leverage: -0.25,
            order_type: "SHORT",
            order_uuid: "c3538f30-a287-460c-aa76-a777927d0413",
            price: 1.25105,
            price_sources: [],
            processed_ms: 1715269296451,
          },
          {
            leverage: 0.001,
            order_type: "FLAT",
            order_uuid: "9bf43b5a-8831-4f58-8df0-18244fad4562",
            price: 1.25611,
            price_sources: [],
            processed_ms: 1715607958924,
          },
        ],
        position_type: "FLAT",
        position_uuid: "12d860da-a98d-4dba-bd2b-9fdb37e9b207",
        return_at_close: 1.0028530186478335,
        trade_pair: ["GBPUSD", "GBP/USD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 192.15200000000004,
        close_ms: 1715668551813,
        current_return: 1.0054784269199009,
        initial_entry_price: 193.76,
        is_closed_position: true,
        miner_hotkey: "5HCJ6okRkmCsu7iLEWotBxgcZy11RhbxSzs8MXT4Dei9osUx",
        net_leverage: 0.0,
        open_ms: 1715088118867,
        orders: [
          {
            leverage: 1.0,
            order_type: "LONG",
            order_uuid: "4273b99f-ff12-461b-abd4-88af36ed02a2",
            price: 193.76,
            price_sources: [],
            processed_ms: 1715088117080,
          },
          {
            leverage: -0.75,
            order_type: "SHORT",
            order_uuid: "fbb5d882-64c2-4f7e-ba6f-63e4dbf04e7c",
            price: 194.296,
            price_sources: [],
            processed_ms: 1715184737716,
          },
          {
            leverage: 0.01,
            order_type: "FLAT",
            order_uuid: "4cf3d549-7ca5-4d17-ad67-aca1a6dcfe6c",
            price: 196.398,
            price_sources: [],
            processed_ms: 1715668551813,
          },
        ],
        position_type: "FLAT",
        position_uuid: "a9185538-4f25-41e2-99c5-317f8129376c",
        return_at_close: 1.0054080434300166,
        trade_pair: ["GBPJPY", "GBP/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 60.7290000000002,
        close_ms: 1715754202397,
        current_return: 1.0042188479010026,
        initial_entry_price: 102.144,
        is_closed_position: true,
        miner_hotkey: "5HCJ6okRkmCsu7iLEWotBxgcZy11RhbxSzs8MXT4Dei9osUx",
        net_leverage: 0.0,
        open_ms: 1715088005644,
        orders: [
          {
            leverage: 1.0,
            order_type: "LONG",
            order_uuid: "4cc9387e-fa5e-4caa-8116-8f4ba7653e80",
            price: 102.144,
            price_sources: [],
            processed_ms: 1715088005642,
          },
          {
            leverage: -0.75,
            order_type: "SHORT",
            order_uuid: "5162fcfd-1efa-4658-9915-2ec85ad1e0a5",
            price: 102.357,
            price_sources: [],
            processed_ms: 1715194536357,
          },
          {
            leverage: -0.24,
            order_type: "SHORT",
            order_uuid: "23761d1e-0410-4e15-81b0-8d8536b66290",
            price: 103.204,
            price_sources: [],
            processed_ms: 1715625023240,
          },
          {
            leverage: 0.01,
            order_type: "FLAT",
            order_uuid: "6d67f4c9-a1be-438b-8ce5-7a255440f5a9",
            price: 103.822,
            price_sources: [],
            processed_ms: 1715754202397,
          },
        ],
        position_type: "FLAT",
        position_uuid: "bb4ce975-f481-40a9-b583-370f1e791df9",
        return_at_close: 1.0041485525816496,
        trade_pair: ["AUDJPY", "AUD/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 71.9520000000006,
        close_ms: 1715838648882,
        current_return: 1.0023807393203366,
        initial_entry_price: 93.87,
        is_closed_position: true,
        miner_hotkey: "5HCJ6okRkmCsu7iLEWotBxgcZy11RhbxSzs8MXT4Dei9osUx",
        net_leverage: 0.0,
        open_ms: 1715608035114,
        orders: [
          {
            leverage: 1.0,
            order_type: "LONG",
            order_uuid: "03eab0dc-faf9-4aa4-9c8d-1c63b2120755",
            price: 93.87,
            price_sources: [],
            processed_ms: 1715608034608,
          },
          {
            leverage: -0.75,
            order_type: "SHORT",
            order_uuid: "007bd251-2cf6-4df1-8f30-c0151ef09f28",
            price: 94.0,
            price_sources: [],
            processed_ms: 1715625641977,
          },
          {
            leverage: -0.2,
            order_type: "SHORT",
            order_uuid: "1e4e0c27-fb01-4273-a40b-5c383322d8d9",
            price: 94.384,
            price_sources: [],
            processed_ms: 1715763728770,
          },
          {
            leverage: -0.04,
            order_type: "SHORT",
            order_uuid: "691683a5-a66d-4391-92db-2c8483f32af6",
            price: 94.342,
            price_sources: [],
            processed_ms: 1715764301281,
          },
          {
            leverage: 0.01,
            order_type: "FLAT",
            order_uuid: "8edbe191-bd55-4f71-aaf2-b1c7fb043d6f",
            price: 94.3,
            price_sources: [],
            processed_ms: 1715838648882,
          },
        ],
        position_type: "FLAT",
        position_uuid: "833edbee-2981-4f5a-9fc3-4e5acd82794e",
        return_at_close: 1.0023105726685841,
        trade_pair: ["NZDJPY", "NZD/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: -11.368000000214675,
        close_ms: 1715876600655,
        current_return: 0.989356889845263,
        initial_entry_price: 195.643,
        is_closed_position: true,
        miner_hotkey: "5HCJ6okRkmCsu7iLEWotBxgcZy11RhbxSzs8MXT4Dei9osUx",
        net_leverage: 0.0,
        open_ms: 1715845620014,
        orders: [
          {
            leverage: -1.0,
            order_type: "SHORT",
            order_uuid: "63448973-7c77-4910-bbea-e19b079292b6",
            price: 195.643,
            price_sources: [],
            processed_ms: 1715845618216,
          },
          {
            leverage: 0.99,
            order_type: "LONG",
            order_uuid: "3f4683a8-da57-455e-a885-033137c6c80f",
            price: 196.532,
            price_sources: [],
            processed_ms: 1715864431924,
          },
          {
            leverage: -20.0,
            order_type: "SHORT",
            order_uuid: "92105c0e-32f6-48a8-9f63-3ebf7525e562",
            price: 196.653,
            price_sources: [],
            processed_ms: 1715871978855,
          },
          {
            leverage: 20.0,
            order_type: "LONG",
            order_uuid: "4738c0a2-f310-4f96-a4c9-63fd4b44a477",
            price: 196.561,
            price_sources: [],
            processed_ms: 1715872210102,
          },
          {
            leverage: -30.0,
            order_type: "SHORT",
            order_uuid: "6e9a44ad-d39f-462c-88ae-4e16c37bca18",
            price: 196.684,
            price_sources: [],
            processed_ms: 1715873041998,
          },
          {
            leverage: 30.0,
            order_type: "LONG",
            order_uuid: "bf40c074-fa87-44fd-b381-b2bbf163c4ea",
            price: 196.712,
            price_sources: [],
            processed_ms: 1715873095364,
          },
          {
            leverage: -30.0,
            order_type: "SHORT",
            order_uuid: "b631d86e-2a7b-4bd4-a680-9697d358ee14",
            price: 196.739,
            price_sources: [],
            processed_ms: 1715873835209,
          },
          {
            leverage: -30.0,
            order_type: "SHORT",
            order_uuid: "29f99136-4735-4363-a72e-51b4a5093327",
            price: 196.761,
            price_sources: [],
            processed_ms: 1715873989923,
          },
          {
            leverage: -30.0,
            order_type: "SHORT",
            order_uuid: "87ff6be8-b5f8-42e2-ac68-da0266a950a7",
            price: 196.77,
            price_sources: [],
            processed_ms: 1715874500468,
          },
          {
            leverage: 90.0,
            order_type: "LONG",
            order_uuid: "bc846916-b683-419e-acbf-179371920d18",
            price: 196.781,
            price_sources: [],
            processed_ms: 1715874607302,
          },
          {
            leverage: 0.01,
            order_type: "FLAT",
            order_uuid: "acec5455-b5f7-45f0-beb3-e9879c06a8b0",
            price: 196.857,
            price_sources: [],
            processed_ms: 1715876600655,
          },
        ],
        position_type: "FLAT",
        position_uuid: "0efd547b-d46f-4600-be48-931487d98904",
        return_at_close: 0.9795919373424903,
        trade_pair: ["GBPJPY", "GBP/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 448.8330000000116,
        close_ms: 1715876634201,
        current_return: 1.0189396324781883,
        initial_entry_price: 154.984,
        is_closed_position: true,
        miner_hotkey: "5HCJ6okRkmCsu7iLEWotBxgcZy11RhbxSzs8MXT4Dei9osUx",
        net_leverage: 0.0,
        open_ms: 1715788164727,
        orders: [
          {
            leverage: -0.5,
            order_type: "SHORT",
            order_uuid: "ce6bb41c-380b-4740-8575-e3158b5df5dc",
            price: 154.984,
            price_sources: [],
            processed_ms: 1715788164362,
          },
          {
            leverage: 0.49,
            order_type: "LONG",
            order_uuid: "bfab36ee-261a-47dd-abb9-89e39307a449",
            price: 155.283,
            price_sources: [],
            processed_ms: 1715864467920,
          },
          {
            leverage: -5.0,
            order_type: "SHORT",
            order_uuid: "76b407e2-2e9e-457d-aede-1ac9131e8409",
            price: 155.228,
            price_sources: [],
            processed_ms: 1715870540135,
          },
          {
            leverage: -5.0,
            order_type: "SHORT",
            order_uuid: "9f820f2d-f934-4247-b04b-a2f26a72e4f8",
            price: 155.285,
            price_sources: [],
            processed_ms: 1715870719908,
          },
          {
            leverage: -10.0,
            order_type: "SHORT",
            order_uuid: "54d1f01d-9aaa-4c27-8630-cea52a6fed51",
            price: 155.296,
            price_sources: [],
            processed_ms: 1715870797734,
          },
          {
            leverage: 20.0,
            order_type: "LONG",
            order_uuid: "c648428d-1966-4204-a2db-7f501a732711",
            price: 155.305,
            price_sources: [],
            processed_ms: 1715870828432,
          },
          {
            leverage: -20.0,
            order_type: "SHORT",
            order_uuid: "a42deb52-7ad7-41ea-a0e3-574a65eabf99",
            price: 155.292,
            price_sources: [],
            processed_ms: 1715871034261,
          },
          {
            leverage: -30.0,
            order_type: "SHORT",
            order_uuid: "c8dc7520-1f2a-4df1-99eb-c50bb40e4206",
            price: 155.339,
            price_sources: [],
            processed_ms: 1715871176172,
          },
          {
            leverage: 50.0,
            order_type: "LONG",
            order_uuid: "f958070e-a1c3-48ed-905a-dfd7a8fccbc9",
            price: 155.247,
            price_sources: [],
            processed_ms: 1715871880347,
          },
          {
            leverage: 0.01,
            order_type: "FLAT",
            order_uuid: "27820b3a-d109-400f-8a26-571cd48c1c08",
            price: 155.299,
            price_sources: [],
            processed_ms: 1715876634201,
          },
        ],
        position_type: "FLAT",
        position_uuid: "7da216af-d96e-4823-b61c-06868d46163d",
        return_at_close: 1.0139111653919084,
        trade_pair: ["USDJPY", "USD/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.34520000000000073,
        close_ms: 1715876665709,
        current_return: 1.0048792876687478,
        initial_entry_price: 0.66149,
        is_closed_position: true,
        miner_hotkey: "5HCJ6okRkmCsu7iLEWotBxgcZy11RhbxSzs8MXT4Dei9osUx",
        net_leverage: 0.0,
        open_ms: 1715691643691,
        orders: [
          {
            leverage: 1.0,
            order_type: "LONG",
            order_uuid: "ac97b9d6-49e4-447a-b4a4-3a4a1729a85a",
            price: 0.66149,
            price_sources: [],
            processed_ms: 1715691642664,
          },
          {
            leverage: -0.5,
            order_type: "SHORT",
            order_uuid: "10869ec6-ccb0-4c7d-9610-e7e5d097744f",
            price: 0.66271,
            price_sources: [],
            processed_ms: 1715701850376,
          },
          {
            leverage: -0.49,
            order_type: "SHORT",
            order_uuid: "5b96bb56-f8ae-458f-b252-43894d47243f",
            price: 0.6667,
            price_sources: [],
            processed_ms: 1715777353872,
          },
          {
            leverage: 0.01,
            order_type: "FLAT",
            order_uuid: "b39ec187-7139-42fc-841f-796405409651",
            price: 0.66796,
            price_sources: [],
            processed_ms: 1715876665709,
          },
        ],
        position_type: "FLAT",
        position_uuid: "36450de9-39b0-4e51-a938-5a121780a2d2",
        return_at_close: 1.004808946118611,
        trade_pair: ["AUDUSD", "AUD/USD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 1.26739,
        close_ms: 1715922717649,
        current_return: 1.0011756444346256,
        initial_entry_price: 1.26739,
        is_closed_position: true,
        miner_hotkey: "5HCJ6okRkmCsu7iLEWotBxgcZy11RhbxSzs8MXT4Dei9osUx",
        net_leverage: 0.0,
        open_ms: 1715878037456,
        orders: [
          {
            leverage: -1.0,
            order_type: "SHORT",
            order_uuid: "57ec0f6b-6d90-47cd-a2fd-8e5d34d44fcf",
            price: 1.26739,
            price_sources: [],
            processed_ms: 1715878036241,
          },
          {
            leverage: 0.01,
            order_type: "FLAT",
            order_uuid: "081ba4c0-15e4-448f-bec1-e2d5c52fe8d1",
            price: 1.2659,
            price_sources: [],
            processed_ms: 1715922717649,
          },
        ],
        position_type: "FLAT",
        position_uuid: "cdd9d071-f170-4d03-82df-494f59527d14",
        return_at_close: 1.001105562139515,
        trade_pair: ["GBPUSD", "GBP/USD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 103.876,
        close_ms: 1715961009453,
        current_return: 1.0006449998074627,
        initial_entry_price: 103.876,
        is_closed_position: true,
        miner_hotkey: "5HCJ6okRkmCsu7iLEWotBxgcZy11RhbxSzs8MXT4Dei9osUx",
        net_leverage: 0.0,
        open_ms: 1715922915591,
        orders: [
          {
            leverage: 1.0,
            order_type: "LONG",
            order_uuid: "9a79df54-0f34-48c7-88e9-a91dc44c2580",
            price: 103.876,
            price_sources: [],
            processed_ms: 1715922914700,
          },
          {
            leverage: 0.01,
            order_type: "FLAT",
            order_uuid: "441c07b4-6631-4894-aa3c-3a98687165c8",
            price: 103.943,
            price_sources: [],
            processed_ms: 1715961009453,
          },
        ],
        position_type: "FLAT",
        position_uuid: "dd7f358e-0313-4f31-acb6-5cf4c47e2c00",
        return_at_close: 1.0005749546574763,
        trade_pair: ["AUDJPY", "AUD/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 148.5579453746627,
        close_ms: 1716185303926,
        current_return: 1.0026376408429072,
        initial_entry_price: 172.554,
        is_closed_position: true,
        miner_hotkey: "5HCJ6okRkmCsu7iLEWotBxgcZy11RhbxSzs8MXT4Dei9osUx",
        net_leverage: 0.0,
        open_ms: 1715754233547,
        orders: [
          {
            leverage: 1.0,
            order_type: "LONG",
            order_uuid: "ada320b7-c4b4-4b7f-996b-e38e64a9cc7a",
            price: 172.554,
            price_sources: [],
            processed_ms: 1715754232646,
          },
          {
            leverage: -0.99,
            order_type: "SHORT",
            order_uuid: "3e6f6f12-0047-4f93-80da-9271a81d081d",
            price: 171.970067943931,
            price_sources: [],
            processed_ms: 1715763242585,
          },
          {
            leverage: 5.0,
            order_type: "LONG",
            order_uuid: "7b077f58-74fd-4cde-a7ff-e5064cc07a0b",
            price: 171.699,
            price_sources: [],
            processed_ms: 1715864539237,
          },
          {
            leverage: -4.99,
            order_type: "SHORT",
            order_uuid: "e83134c2-1a54-4720-a54d-0122b3dacbfb",
            price: 171.696,
            price_sources: [],
            processed_ms: 1715870400190,
          },
          {
            leverage: 10.0,
            order_type: "LONG",
            order_uuid: "a373c978-0a59-4ec3-b98b-937427301b11",
            price: 171.58,
            price_sources: [],
            processed_ms: 1715874894054,
          },
          {
            leverage: 15.0,
            order_type: "LONG",
            order_uuid: "04acd235-f0e2-4a8e-a12c-591e57c1227b",
            price: 171.61,
            price_sources: [],
            processed_ms: 1715876078203,
          },
          {
            leverage: -25.0,
            order_type: "SHORT",
            order_uuid: "2bdfc62a-e442-4e31-bccf-265d77aa841b",
            price: 171.6405773531206,
            price_sources: [],
            processed_ms: 1715876461288,
          },
          {
            leverage: 0.01,
            order_type: "FLAT",
            order_uuid: "52375a31-80d7-4d1a-a5a4-5d6508f25950",
            price: 171.31471927501366,
            price_sources: [],
            processed_ms: 1716185303926,
          },
        ],
        position_type: "FLAT",
        position_uuid: "90362ba8-ed39-4b47-a5df-2ba8f2282c72",
        return_at_close: 1.0004619171622782,
        trade_pair: ["CHFJPY", "CHF/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.66946,
        close_ms: 1716210385437,
        current_return: 1.0040181638932872,
        initial_entry_price: 0.66946,
        is_closed_position: true,
        miner_hotkey: "5HCJ6okRkmCsu7iLEWotBxgcZy11RhbxSzs8MXT4Dei9osUx",
        net_leverage: 0.0,
        open_ms: 1716189601488,
        orders: [
          {
            leverage: -1.0,
            order_type: "SHORT",
            order_uuid: "20aa8ad2-5455-4955-bac9-116a07d5b825",
            price: 0.66946,
            price_sources: [],
            processed_ms: 1716189601314,
          },
          {
            leverage: 0.01,
            order_type: "FLAT",
            order_uuid: "1131959e-4ff9-4fcc-8686-758192973bc8",
            price: 0.66677,
            price_sources: [],
            processed_ms: 1716210385437,
          },
        ],
        position_type: "FLAT",
        position_uuid: "1913ef91-9d56-41ee-9121-dde130d0d6c1",
        return_at_close: 1.0039478826218147,
        trade_pair: ["AUDUSD", "AUD/USD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 1.08614,
        close_ms: 1716296917984,
        current_return: 1.0005892426390706,
        initial_entry_price: 1.08614,
        is_closed_position: true,
        miner_hotkey: "5HCJ6okRkmCsu7iLEWotBxgcZy11RhbxSzs8MXT4Dei9osUx",
        net_leverage: 0.0,
        open_ms: 1716279195816,
        orders: [
          {
            leverage: -1.0,
            order_type: "SHORT",
            order_uuid: "9c1fc561-9ac1-49ee-b7ad-13f08332242a",
            price: 1.08614,
            price_sources: [],
            processed_ms: 1716279195485,
          },
          {
            leverage: 0.01,
            order_type: "FLAT",
            order_uuid: "2dce3359-6b8e-4bef-875f-a7236059df77",
            price: 1.0855,
            price_sources: [],
            processed_ms: 1716296917984,
          },
        ],
        position_type: "FLAT",
        position_uuid: "a3b3a59f-6922-4316-ac79-33f3ec99caf4",
        return_at_close: 1.0005192013920858,
        trade_pair: ["EURUSD", "EUR/USD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 169.617,
        close_ms: 1716313584415,
        current_return: 1.0005836679106457,
        initial_entry_price: 169.617,
        is_closed_position: true,
        miner_hotkey: "5HCJ6okRkmCsu7iLEWotBxgcZy11RhbxSzs8MXT4Dei9osUx",
        net_leverage: 0.0,
        open_ms: 1716279288336,
        orders: [
          {
            leverage: -1.0,
            order_type: "SHORT",
            order_uuid: "4ff60ce7-1a93-4f75-8533-d3811ddf94bb",
            price: 169.617,
            price_sources: [],
            processed_ms: 1716279288124,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "05c8e01d-7be2-4a68-9db5-d62fac2c6d64",
            price: 169.518,
            price_sources: [],
            processed_ms: 1716313584415,
          },
        ],
        position_type: "FLAT",
        position_uuid: "9e330828-c902-4dab-82b5-e89b62550612",
        return_at_close: 1.000513627053892,
        trade_pair: ["EURJPY", "EUR/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 1.2819049999999983,
        close_ms: 1716361396632,
        current_return: 1.005491888146575,
        initial_entry_price: 1.270055,
        is_closed_position: true,
        miner_hotkey: "5HCJ6okRkmCsu7iLEWotBxgcZy11RhbxSzs8MXT4Dei9osUx",
        net_leverage: 0.0,
        open_ms: 1716210415824,
        orders: [
          {
            leverage: -1.0,
            order_type: "SHORT",
            order_uuid: "6c99d058-6aec-4f4c-976f-e7046e33ee50",
            price: 1.270055,
            price_sources: [],
            processed_ms: 1716210415690,
          },
          {
            leverage: -15.0,
            order_type: "SHORT",
            order_uuid: "e9e55a75-9e8d-4ca7-bc47-2c39995a8e91",
            price: 1.27596,
            price_sources: [],
            processed_ms: 1716360709020,
          },
          {
            leverage: 15.0,
            order_type: "LONG",
            order_uuid: "1169b378-4d88-400a-9454-5b88ccc3c82f",
            price: 1.27517,
            price_sources: [],
            processed_ms: 1716361334375,
          },
          {
            leverage: 0.01,
            order_type: "FLAT",
            order_uuid: "8c539d9d-588c-43b5-9ed1-8e5c67b59b05",
            price: 1.27493,
            price_sources: [],
            processed_ms: 1716361396632,
          },
        ],
        position_type: "FLAT",
        position_uuid: "27378952-cf1b-47ed-b93a-db55db8e24e4",
        return_at_close: 1.0043657372318509,
        trade_pair: ["GBPUSD", "GBP/USD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 171.556,
        close_ms: 1716375888308,
        current_return: 1.0025951528023922,
        initial_entry_price: 171.556,
        is_closed_position: true,
        miner_hotkey: "5HCJ6okRkmCsu7iLEWotBxgcZy11RhbxSzs8MXT4Dei9osUx",
        net_leverage: 0.0,
        open_ms: 1716279248363,
        orders: [
          {
            leverage: -1.0,
            order_type: "SHORT",
            order_uuid: "c7009282-c099-46cb-ba8c-daaaf90e5bde",
            price: 171.556,
            price_sources: [],
            processed_ms: 1716279248125,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "34df7c50-b700-47ad-849f-f68b2caaaa06",
            price: 171.1107859658328,
            price_sources: [],
            processed_ms: 1716375888308,
          },
        ],
        position_type: "FLAT",
        position_uuid: "6c25738e-bfda-440a-9683-127d3364c19d",
        return_at_close: 1.002524971141696,
        trade_pair: ["CHFJPY", "CHF/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 176.68000000001408,
        close_ms: 1716379789270,
        current_return: 1.0011318846881092,
        initial_entry_price: 197.794,
        is_closed_position: true,
        miner_hotkey: "5HCJ6okRkmCsu7iLEWotBxgcZy11RhbxSzs8MXT4Dei9osUx",
        net_leverage: 0.0,
        open_ms: 1716187044899,
        orders: [
          {
            leverage: 1.0,
            order_type: "LONG",
            order_uuid: "47e7dc5e-f559-4b25-9e66-8a7f22a89ae0",
            price: 197.794,
            price_sources: [],
            processed_ms: 1716187044345,
          },
          {
            leverage: -0.99,
            order_type: "SHORT",
            order_uuid: "f1dcf89b-a9f4-44af-abc8-2b3dad334aeb",
            price: 197.78,
            price_sources: [],
            processed_ms: 1716207409908,
          },
          {
            leverage: 5.0,
            order_type: "LONG",
            order_uuid: "0e608140-fa09-4129-933c-5a4d5ca3624b",
            price: 199.013,
            price_sources: [],
            processed_ms: 1716379325687,
          },
          {
            leverage: -5.0,
            order_type: "SHORT",
            order_uuid: "242da815-94c8-46ab-85ac-d1ac36e4958a",
            price: 199.058,
            price_sources: [],
            processed_ms: 1716379699557,
          },
          {
            leverage: 0.01,
            order_type: "FLAT",
            order_uuid: "af09ff69-0306-478d-8a97-e9c654456297",
            price: 199.068,
            price_sources: [],
            processed_ms: 1716379789270,
          },
        ],
        position_type: "FLAT",
        position_uuid: "9dc87c74-3f86-432c-9145-3fe6def460fb",
        return_at_close: 1.0007114092965401,
        trade_pair: ["GBPJPY", "GBP/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 1.083285,
        close_ms: 1716444261591,
        current_return: 1.0008538842502204,
        initial_entry_price: 1.083285,
        is_closed_position: true,
        miner_hotkey: "5HCJ6okRkmCsu7iLEWotBxgcZy11RhbxSzs8MXT4Dei9osUx",
        net_leverage: 0.0,
        open_ms: 1716386903776,
        orders: [
          {
            leverage: -1.0,
            order_type: "SHORT",
            order_uuid: "15e175b0-1976-4fc3-97ee-a67f618253da",
            price: 1.083285,
            price_sources: [],
            processed_ms: 1716386903709,
          },
          {
            leverage: 0.01,
            order_type: "FLAT",
            order_uuid: "80a6b1c5-583c-4d63-9ef3-563188cf6dc5",
            price: 1.08236,
            price_sources: [],
            processed_ms: 1716444261591,
          },
        ],
        position_type: "FLAT",
        position_uuid: "31d393d5-ab7a-48da-ae71-225374f592b8",
        return_at_close: 1.000783824478323,
        trade_pair: ["EURUSD", "EUR/USD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 1.27258,
        close_ms: 1716490377941,
        current_return: 1.002986059815493,
        initial_entry_price: 1.27258,
        is_closed_position: true,
        miner_hotkey: "5HCJ6okRkmCsu7iLEWotBxgcZy11RhbxSzs8MXT4Dei9osUx",
        net_leverage: 0.0,
        open_ms: 1716375995331,
        orders: [
          {
            leverage: -1.0,
            order_type: "SHORT",
            order_uuid: "bbef4e3c-50bb-4b36-a27c-626664f2ffee",
            price: 1.27258,
            price_sources: [],
            processed_ms: 1716375995261,
          },
          {
            leverage: 0.01,
            order_type: "FLAT",
            order_uuid: "87ca3ea6-f0d7-4d52-820a-89952221707a",
            price: 1.26878,
            price_sources: [],
            processed_ms: 1716490377941,
          },
        ],
        position_type: "FLAT",
        position_uuid: "dc834f7b-84fa-40aa-b5b3-5c27fa349cbb",
        return_at_close: 1.0029158507913059,
        trade_pair: ["GBPUSD", "GBP/USD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 1.26924,
        close_ms: 1716530542824,
        current_return: 1.0008193879802085,
        initial_entry_price: 1.26924,
        is_closed_position: true,
        miner_hotkey: "5HCJ6okRkmCsu7iLEWotBxgcZy11RhbxSzs8MXT4Dei9osUx",
        net_leverage: 0.0,
        open_ms: 1716526204672,
        orders: [
          {
            leverage: -1.0,
            order_type: "SHORT",
            order_uuid: "ed5cd577-dabb-45a7-94fa-c76918f565f6",
            price: 1.26924,
            price_sources: [],
            processed_ms: 1716526204662,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "611b0efa-1790-4083-970f-d7047f60c27d",
            price: 1.2682,
            price_sources: [],
            processed_ms: 1716530542824,
          },
        ],
        position_type: "FLAT",
        position_uuid: "7275ab20-6c99-428b-a79d-847916fee00b",
        return_at_close: 1.00074933062305,
        trade_pair: ["GBPUSD", "GBP/USD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.8516,
        close_ms: 1716559534666,
        current_return: 1.0010509628933772,
        initial_entry_price: 0.8516,
        is_closed_position: true,
        miner_hotkey: "5HCJ6okRkmCsu7iLEWotBxgcZy11RhbxSzs8MXT4Dei9osUx",
        net_leverage: 0.0,
        open_ms: 1716531243635,
        orders: [
          {
            leverage: 1.0,
            order_type: "LONG",
            order_uuid: "886701ef-d8b7-43f8-a218-5eb0da1a9242",
            price: 0.8516,
            price_sources: [],
            processed_ms: 1716531243625,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "de9ccb34-78f0-468b-b28f-6ced9bdd3fec",
            price: 0.852495,
            price_sources: [],
            processed_ms: 1716559534666,
          },
        ],
        position_type: "FLAT",
        position_uuid: "5655e333-e782-4f9e-ab8b-361368439475",
        return_at_close: 1.0009808893259746,
        trade_pair: ["EURGBP", "EUR/GBP", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.6668618518518521,
        close_ms: 1716877557997,
        current_return: 1.0307552458147218,
        initial_entry_price: 0.66005,
        is_closed_position: true,
        miner_hotkey: "5HCJ6okRkmCsu7iLEWotBxgcZy11RhbxSzs8MXT4Dei9osUx",
        net_leverage: 0.0,
        open_ms: 1716536206741,
        orders: [
          {
            leverage: -1.0,
            order_type: "SHORT",
            order_uuid: "86cb824e-ac39-48df-affa-3340d7b3705f",
            price: 0.66005,
            price_sources: [],
            processed_ms: 1716536203076,
          },
          {
            leverage: -1.0,
            order_type: "SHORT",
            order_uuid: "a831491b-864a-4f65-ae5b-5ff6cd28c85e",
            price: 0.66172,
            price_sources: [],
            processed_ms: 1716554912156,
          },
          {
            leverage: -25.0,
            order_type: "SHORT",
            order_uuid: "7b17efb6-832f-4e06-afe1-319bed6bc9b3",
            price: 0.66682,
            price_sources: [],
            processed_ms: 1716873261953,
          },
          {
            leverage: -25.0,
            order_type: "SHORT",
            order_uuid: "3a89600d-6fbb-415c-b740-59df84b7c567",
            price: 0.66666,
            price_sources: [],
            processed_ms: 1716875341769,
          },
          {
            leverage: 25.0,
            order_type: "LONG",
            order_uuid: "d8846cbc-1315-4838-8aa5-3ddb5f95276d",
            price: 0.66614,
            price_sources: [],
            processed_ms: 1716877476626,
          },
          {
            leverage: 25.0,
            order_type: "FLAT",
            order_uuid: "4b838a01-dd61-4104-aa1b-5daea4c2ba89",
            price: 0.66611,
            price_sources: [],
            processed_ms: 1716877557997,
          },
        ],
        position_type: "FLAT",
        position_uuid: "761be3a7-ccc2-4488-9945-4bd8d5a8de4e",
        return_at_close: 1.0270032967199563,
        trade_pair: ["AUDUSD", "AUD/USD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 1.08588,
        close_ms: 1716960093448,
        current_return: 1.0010314215198732,
        initial_entry_price: 1.08588,
        is_closed_position: true,
        miner_hotkey: "5HCJ6okRkmCsu7iLEWotBxgcZy11RhbxSzs8MXT4Dei9osUx",
        net_leverage: 0.0,
        open_ms: 1716920844978,
        orders: [
          {
            leverage: -1.0,
            order_type: "SHORT",
            order_uuid: "1225fbf5-0250-4013-b0e3-635e07255704",
            price: 1.08588,
            price_sources: [],
            processed_ms: 1716920842493,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "4ebbf71b-b4e9-4ce0-a3ca-0668ea9a639e",
            price: 1.08476,
            price_sources: [],
            processed_ms: 1716960093448,
          },
        ],
        position_type: "FLAT",
        position_uuid: "62651960-3ceb-458b-9e78-7e90f4a7b765",
        return_at_close: 1.0009613493203668,
        trade_pair: ["EURUSD", "EUR/USD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 165.047499999995,
        close_ms: 1717051436631,
        current_return: 1.00730434600086,
        initial_entry_price: 95.283,
        is_closed_position: true,
        miner_hotkey: "5HCJ6okRkmCsu7iLEWotBxgcZy11RhbxSzs8MXT4Dei9osUx",
        net_leverage: 0.0,
        open_ms: 1716405256089,
        orders: [
          {
            leverage: -1.0,
            order_type: "SHORT",
            order_uuid: "c4006c27-d40f-4675-872f-f9f332677fd8",
            price: 95.283,
            price_sources: [],
            processed_ms: 1716405256020,
          },
          {
            leverage: -10.0,
            order_type: "SHORT",
            order_uuid: "d97b16ee-73e2-46ac-8b75-7bf7a0fcac03",
            price: 96.6875,
            price_sources: [],
            processed_ms: 1716881705139,
          },
          {
            leverage: 10.99,
            order_type: "LONG",
            order_uuid: "4a44c848-57bc-4598-aae4-04222b10a352",
            price: 96.4975,
            price_sources: [],
            processed_ms: 1716904971629,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "52499956-9393-4cfa-b6bf-23d1f63ff39a",
            price: 95.4495,
            price_sources: [],
            processed_ms: 1717051436631,
          },
        ],
        position_type: "FLAT",
        position_uuid: "c4999920-cb5d-4a44-9d84-f0d8ea635bfc",
        return_at_close: 1.0065287216544392,
        trade_pair: ["NZDJPY", "NZD/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 1.8183799999999846,
        close_ms: 1717160078061,
        current_return: 1.0042655150251594,
        initial_entry_price: 1.27586,
        is_closed_position: true,
        miner_hotkey: "5HCJ6okRkmCsu7iLEWotBxgcZy11RhbxSzs8MXT4Dei9osUx",
        net_leverage: 0.0,
        open_ms: 1716920874750,
        orders: [
          {
            leverage: -1.0,
            order_type: "SHORT",
            order_uuid: "83dfc185-d9e0-4b7e-bcb4-826ee33f7912",
            price: 1.27586,
            price_sources: [],
            processed_ms: 1716920873369,
          },
          {
            leverage: 0.99,
            order_type: "LONG",
            order_uuid: "0e099e1e-a767-45cd-834a-92366d7f8b1a",
            price: 1.27038,
            price_sources: [],
            processed_ms: 1717058486055,
          },
          {
            leverage: 0.01,
            order_type: "FLAT",
            order_uuid: "ab02c635-7ded-4a9b-b5a8-696c839cc207",
            price: 1.27416,
            price_sources: [],
            processed_ms: 1717160078061,
          },
        ],
        position_type: "FLAT",
        position_uuid: "573d92b3-dbe3-40ea-a0b1-c7eaec94b66f",
        return_at_close: 1.0041952164391077,
        trade_pair: ["GBPUSD", "GBP/USD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.9467999999999912,
        close_ms: 1717414463299,
        current_return: 1.0042315504400812,
        initial_entry_price: 0.66465,
        is_closed_position: true,
        miner_hotkey: "5HCJ6okRkmCsu7iLEWotBxgcZy11RhbxSzs8MXT4Dei9osUx",
        net_leverage: 0.0,
        open_ms: 1716920813803,
        orders: [
          {
            leverage: -1.0,
            order_type: "SHORT",
            order_uuid: "12afb835-0573-4467-9118-b890c13b8e95",
            price: 0.66465,
            price_sources: [],
            processed_ms: 1716920812021,
          },
          {
            leverage: 0.99,
            order_type: "LONG",
            order_uuid: "df3c7171-7949-45da-b66f-b5db39b1f4ba",
            price: 0.6618,
            price_sources: [],
            processed_ms: 1717005815250,
          },
          {
            leverage: 0.01,
            order_type: "FLAT",
            order_uuid: "ae2e7b5d-5628-45b7-86ef-0486f575892a",
            price: 0.66555,
            price_sources: [],
            processed_ms: 1717414463299,
          },
        ],
        position_type: "FLAT",
        position_uuid: "4d84d115-8f9e-4b10-b6fe-0765a6cbd2f4",
        return_at_close: 1.0041612542315503,
        trade_pair: ["AUDUSD", "AUD/USD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 103.57760396039623,
        close_ms: 1717485135510,
        current_return: 0.9981045701156546,
        initial_entry_price: 103.586,
        is_closed_position: true,
        miner_hotkey: "5HCJ6okRkmCsu7iLEWotBxgcZy11RhbxSzs8MXT4Dei9osUx",
        net_leverage: 0.0,
        open_ms: 1716405295154,
        orders: [
          {
            leverage: -1.0,
            order_type: "SHORT",
            order_uuid: "a779bfae-668e-47c8-85ac-3ff151c0882b",
            price: 103.586,
            price_sources: [],
            processed_ms: 1716405294372,
          },
          {
            leverage: -10.0,
            order_type: "SHORT",
            order_uuid: "938528e4-d6fd-4216-986e-1d8e9ca2f21f",
            price: 104.513,
            price_sources: [],
            processed_ms: 1716884428376,
          },
          {
            leverage: 10.0,
            order_type: "LONG",
            order_uuid: "1dc42d1d-eb3b-453e-8969-5f22c08e93b9",
            price: 104.4205,
            price_sources: [],
            processed_ms: 1716920762978,
          },
          {
            leverage: 0.99,
            order_type: "LONG",
            order_uuid: "c56e5d44-fe11-47cd-a1f9-b744baa6af7a",
            price: 104.338,
            price_sources: [],
            processed_ms: 1717005134842,
          },
          {
            leverage: -1.0,
            order_type: "SHORT",
            order_uuid: "2eb6cab9-c0fd-4a82-b8a0-52407890fb1e",
            price: 103.39699999999999,
            price_sources: [],
            processed_ms: 1717051502713,
          },
          {
            leverage: 0.01,
            order_type: "FLAT",
            order_uuid: "c7277b1f-1bf2-400e-b983-e7f4a3212c27",
            price: 103.772,
            price_sources: [],
            processed_ms: 1717485135510,
          },
        ],
        position_type: "FLAT",
        position_uuid: "9145e978-ce8b-4417-aa4f-6e6843491fe2",
        return_at_close: 0.9972661622767575,
        trade_pair: ["AUDJPY", "AUD/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 156.67649999999998,
        close_ms: 1717596847127,
        current_return: 1.0016403225754977,
        initial_entry_price: 156.67649999999998,
        is_closed_position: true,
        miner_hotkey: "5HCJ6okRkmCsu7iLEWotBxgcZy11RhbxSzs8MXT4Dei9osUx",
        net_leverage: 0.0,
        open_ms: 1717052573106,
        orders: [
          {
            leverage: -1.0,
            order_type: "SHORT",
            order_uuid: "88c45ea8-724f-4550-9a28-3cf2dc49f4bc",
            price: 156.67649999999998,
            price_sources: [],
            processed_ms: 1717052573090,
          },
          {
            leverage: 0.01,
            order_type: "FLAT",
            order_uuid: "a6b15b74-1ce5-45c5-8631-c5a0bdbc71cf",
            price: 156.4195,
            price_sources: [],
            processed_ms: 1717596847127,
          },
        ],
        position_type: "FLAT",
        position_uuid: "904d707a-33e8-4466-ab33-239102f539c1",
        return_at_close: 1.0015702077529174,
        trade_pair: ["USDJPY", "USD/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 113.965,
        close_ms: 1717650794293,
        current_return: 1.0003685341990962,
        initial_entry_price: 113.965,
        is_closed_position: true,
        miner_hotkey: "5HCJ6okRkmCsu7iLEWotBxgcZy11RhbxSzs8MXT4Dei9osUx",
        net_leverage: 0.0,
        open_ms: 1717485279370,
        orders: [
          {
            leverage: -1.0,
            order_type: "SHORT",
            order_uuid: "cd117b6a-7d68-4145-94fc-d3a5b4a1a37d",
            price: 113.965,
            price_sources: [],
            processed_ms: 1717485279360,
          },
          {
            leverage: 0.01,
            order_type: "FLAT",
            order_uuid: "93df9e78-196c-466f-9593-4d1c5515b1e2",
            price: 113.923,
            price_sources: [],
            processed_ms: 1717650794293,
          },
        ],
        position_type: "FLAT",
        position_uuid: "4d3c448e-7e24-42b1-aec4-4bba6a8f3433",
        return_at_close: 1.0002985084017022,
        trade_pair: ["CADJPY", "CAD/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 197.819,
        close_ms: 1717677005061,
        current_return: 0.9920482865649912,
        initial_entry_price: 197.819,
        is_closed_position: true,
        miner_hotkey: "5HCJ6okRkmCsu7iLEWotBxgcZy11RhbxSzs8MXT4Dei9osUx",
        net_leverage: 0.0,
        open_ms: 1717508648346,
        orders: [
          {
            leverage: -1.0,
            order_type: "SHORT",
            order_uuid: "758f66fb-5eed-4ae9-99e5-5e35561c0320",
            price: 197.819,
            price_sources: [],
            processed_ms: 1717508648326,
          },
          {
            leverage: 0.01,
            order_type: "FLAT",
            order_uuid: "33153abd-fe82-433e-aba1-c48ca6310985",
            price: 199.392,
            price_sources: [],
            processed_ms: 1717677005061,
          },
        ],
        position_type: "FLAT",
        position_uuid: "dedcc704-93fd-470b-b77f-3ffc2a6699fc",
        return_at_close: 0.9919788431849317,
        trade_pair: ["GBPJPY", "GBP/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.8471007522342979,
        close_ms: 1717771452130,
        current_return: 0.9924365554651546,
        initial_entry_price: 0.8471007522342979,
        is_closed_position: true,
        miner_hotkey: "5HCJ6okRkmCsu7iLEWotBxgcZy11RhbxSzs8MXT4Dei9osUx",
        net_leverage: 0.0,
        open_ms: 1717682782005,
        orders: [
          {
            leverage: 1.0,
            order_type: "LONG",
            order_uuid: "f2945d57-9702-451d-baca-c92052484ccc",
            price: 0.8471007522342979,
            price_sources: [],
            processed_ms: 1717682781990,
          },
          {
            leverage: 0.5,
            order_type: "FLAT",
            order_uuid: "becaa49d-b824-4b8a-bb53-cd4776ae6cef",
            price: 0.840693752679348,
            price_sources: [],
            processed_ms: 1717771452130,
          },
        ],
        position_type: "FLAT",
        position_uuid: "becb46be-657c-4f5a-b515-793daba231e5",
        return_at_close: 0.992367084906272,
        trade_pair: ["NZDCAD", "NZD/CAD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 1.3659,
        close_ms: 1717967849572,
        current_return: 1.0037609633908555,
        initial_entry_price: 1.36933,
        is_closed_position: true,
        miner_hotkey: "5HCJ6okRkmCsu7iLEWotBxgcZy11RhbxSzs8MXT4Dei9osUx",
        net_leverage: 0.0,
        open_ms: 1717619553841,
        orders: [
          {
            leverage: 1.0,
            order_type: "LONG",
            order_uuid: "130f4d67-e02b-4590-b48a-ba8b4a07bfe1",
            price: 1.36933,
            price_sources: [],
            processed_ms: 1717619553809,
          },
          {
            leverage: -0.5,
            order_type: "SHORT",
            order_uuid: "479d049a-8199-4d53-88b4-540a78dca904",
            price: 1.37276,
            price_sources: [],
            processed_ms: 1717771482413,
          },
          {
            leverage: 0.01,
            order_type: "FLAT",
            order_uuid: "edffc943-da95-4529-b321-06a23c4f1f91",
            price: 1.3762,
            price_sources: [],
            processed_ms: 1717967849572,
          },
        ],
        position_type: "FLAT",
        position_uuid: "90725f83-51b2-4f6d-9633-f7a34b949447",
        return_at_close: 1.003690700123418,
        trade_pair: ["USDCAD", "USD/CAD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.6548240000000002,
        close_ms: 1718088330585,
        current_return: 1.0026754674793636,
        initial_entry_price: 0.65297,
        is_closed_position: true,
        miner_hotkey: "5HCJ6okRkmCsu7iLEWotBxgcZy11RhbxSzs8MXT4Dei9osUx",
        net_leverage: 0.0,
        open_ms: 1717574413388,
        orders: [
          {
            leverage: -1.0,
            order_type: "SHORT",
            order_uuid: "89e4bbd8-21d7-4e15-b38e-acffbb943044",
            price: 0.65297,
            price_sources: [],
            processed_ms: 1717574413373,
          },
          {
            leverage: 0.8,
            order_type: "LONG",
            order_uuid: "8aa4d456-8fbb-45da-936d-8e8ab0cd1780",
            price: 0.65135,
            price_sources: [],
            processed_ms: 1717650739845,
          },
          {
            leverage: -0.3,
            order_type: "SHORT",
            order_uuid: "9926e761-d4c9-4246-ae17-b3dcd0c348e2",
            price: 0.65174,
            price_sources: [],
            processed_ms: 1717775792961,
          },
          {
            leverage: 0.01,
            order_type: "FLAT",
            order_uuid: "e2751c35-532d-4bfa-ada7-b8416947fe06",
            price: 0.65133,
            price_sources: [],
            processed_ms: 1718088330585,
          },
        ],
        position_type: "FLAT",
        position_uuid: "6d5968a9-264f-48ff-98b1-e35ba2088a47",
        return_at_close: 1.002584224011823,
        trade_pair: ["CADCHF", "CAD/CHF", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.84563,
        close_ms: 1718196501420,
        current_return: 0.9981700034293959,
        initial_entry_price: 0.84563,
        is_closed_position: true,
        miner_hotkey: "5HCJ6okRkmCsu7iLEWotBxgcZy11RhbxSzs8MXT4Dei9osUx",
        net_leverage: 0.0,
        open_ms: 1717999537049,
        orders: [
          {
            leverage: 0.5,
            order_type: "LONG",
            order_uuid: "2c4c081c-0567-4526-8c33-8ebda2e4b8ba",
            price: 0.84563,
            price_sources: [],
            processed_ms: 1717999537034,
          },
          {
            leverage: 0.5,
            order_type: "FLAT",
            order_uuid: "4c6c2f2a-2bef-4f19-9a56-06c7c837e59c",
            price: 0.842535,
            price_sources: [],
            processed_ms: 1718196501420,
          },
        ],
        position_type: "FLAT",
        position_uuid: "1d125e43-70d0-41f0-91e2-895ed83ae91f",
        return_at_close: 0.9981350674792758,
        trade_pair: ["EURGBP", "EUR/GBP", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 1.07597,
        close_ms: 1718196550009,
        current_return: 1.0055020121378848,
        initial_entry_price: 1.07597,
        is_closed_position: true,
        miner_hotkey: "5HCJ6okRkmCsu7iLEWotBxgcZy11RhbxSzs8MXT4Dei9osUx",
        net_leverage: 0.0,
        open_ms: 1718189389390,
        orders: [
          {
            leverage: 1.0,
            order_type: "LONG",
            order_uuid: "e48fd1b9-1411-46e7-b7ee-be20719ea21d",
            price: 1.07597,
            price_sources: [],
            processed_ms: 1718189387656,
          },
          {
            leverage: 0.5,
            order_type: "FLAT",
            order_uuid: "d0249435-852a-4bdf-b5be-ad347b5880d4",
            price: 1.08189,
            price_sources: [],
            processed_ms: 1718196550009,
          },
        ],
        position_type: "FLAT",
        position_uuid: "77a77949-18ba-4c72-8d2c-960afcbfcb74",
        return_at_close: 1.0054316269970351,
        trade_pair: ["EURUSD", "EUR/USD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.66476,
        close_ms: 1718282422641,
        current_return: 1.0020598096555458,
        initial_entry_price: 0.66511,
        is_closed_position: true,
        miner_hotkey: "5HCJ6okRkmCsu7iLEWotBxgcZy11RhbxSzs8MXT4Dei9osUx",
        net_leverage: 0.0,
        open_ms: 1718265612702,
        orders: [
          {
            leverage: 0.5,
            order_type: "LONG",
            order_uuid: "36922d1c-3274-4818-8b5a-13a4c3f699d7",
            price: 0.66511,
            price_sources: [],
            processed_ms: 1718265612327,
          },
          {
            leverage: 0.5,
            order_type: "LONG",
            order_uuid: "a18a57df-811f-48c0-a2e2-1451c1a861b5",
            price: 0.66441,
            price_sources: [],
            processed_ms: 1718280992200,
          },
          {
            leverage: 0.01,
            order_type: "FLAT",
            order_uuid: "d6fd3263-8fe9-4014-bde1-14eee3d8a1bc",
            price: 0.66613,
            price_sources: [],
            processed_ms: 1718282422641,
          },
        ],
        position_type: "FLAT",
        position_uuid: "21b41323-b262-47e7-987d-829e509f665a",
        return_at_close: 1.0019896654688698,
        trade_pair: ["AUDUSD", "AUD/USD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.6514125000000001,
        close_ms: 1718345772240,
        current_return: 1.0014761703922397,
        initial_entry_price: 0.6520250000000001,
        is_closed_position: true,
        miner_hotkey: "5HCJ6okRkmCsu7iLEWotBxgcZy11RhbxSzs8MXT4Dei9osUx",
        net_leverage: 0.0,
        open_ms: 1718189067036,
        orders: [
          {
            leverage: -0.5,
            order_type: "SHORT",
            order_uuid: "9e3a100c-189b-440b-a71d-764f229b2827",
            price: 0.6520250000000001,
            price_sources: [],
            processed_ms: 1718189066878,
          },
          {
            leverage: -0.5,
            order_type: "SHORT",
            order_uuid: "59edeafa-0c72-44b4-9687-1a17f6072713",
            price: 0.6508,
            price_sources: [],
            processed_ms: 1718209937164,
          },
          {
            leverage: 0.01,
            order_type: "FLAT",
            order_uuid: "c10b6166-150f-49d0-95f7-49efc1db6480",
            price: 0.65045,
            price_sources: [],
            processed_ms: 1718345772240,
          },
        ],
        position_type: "FLAT",
        position_uuid: "e2bb0c21-2791-45a6-8a6d-32f996ea90f5",
        return_at_close: 1.0014060670603122,
        trade_pair: ["CADCHF", "CAD/CHF", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 1.476855,
        close_ms: 1718352208538,
        current_return: 0.9968314920669199,
        initial_entry_price: 1.48177,
        is_closed_position: true,
        miner_hotkey: "5HCJ6okRkmCsu7iLEWotBxgcZy11RhbxSzs8MXT4Dei9osUx",
        net_leverage: 0.0,
        open_ms: 1718088431926,
        orders: [
          {
            leverage: 0.5,
            order_type: "LONG",
            order_uuid: "78952e86-92ab-4f75-b33c-4bcfd1eaa2e7",
            price: 1.48177,
            price_sources: [],
            processed_ms: 1718088431732,
          },
          {
            leverage: 0.5,
            order_type: "LONG",
            order_uuid: "76fadeb5-8154-4b75-98b2-5cceeacdd705",
            price: 1.47969,
            price_sources: [],
            processed_ms: 1718096045335,
          },
          {
            leverage: -0.5,
            order_type: "SHORT",
            order_uuid: "e8b9e45b-ab6c-4ada-a378-9ac881aa7a5c",
            price: 1.484375,
            price_sources: [],
            processed_ms: 1718203613345,
          },
          {
            leverage: 0.5,
            order_type: "LONG",
            order_uuid: "b6a950c2-7c9b-454b-b3a2-7131c7233200",
            price: 1.476625,
            price_sources: [],
            processed_ms: 1718300107193,
          },
          {
            leverage: 0.5,
            order_type: "FLAT",
            order_uuid: "09431864-58d3-4fd9-888a-c96ea1a7288e",
            price: 1.47216,
            price_sources: [],
            processed_ms: 1718352208538,
          },
        ],
        position_type: "FLAT",
        position_uuid: "6ce864e5-a1d6-4eca-a665-2406b95af890",
        return_at_close: 0.9967268247602529,
        trade_pair: ["EURCAD", "EUR/CAD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 169.707,
        close_ms: 1718360947651,
        current_return: 1.0054476244350556,
        initial_entry_price: 169.707,
        is_closed_position: true,
        miner_hotkey: "5HCJ6okRkmCsu7iLEWotBxgcZy11RhbxSzs8MXT4Dei9osUx",
        net_leverage: 0.0,
        open_ms: 1718345802630,
        orders: [
          {
            leverage: -0.5,
            order_type: "SHORT",
            order_uuid: "c19ce64d-1c6d-48c6-be05-2a4b6b85812c",
            price: 169.707,
            price_sources: [],
            processed_ms: 1718345802577,
          },
          {
            leverage: 0.01,
            order_type: "FLAT",
            order_uuid: "a04e733d-d7c3-4af6-a381-b2800db29c6e",
            price: 167.858,
            price_sources: [],
            processed_ms: 1718360947651,
          },
        ],
        position_type: "FLAT",
        position_uuid: "19eb3627-8e53-4b34-84f3-38751526ef6a",
        return_at_close: 1.0054124337682004,
        trade_pair: ["EURJPY", "EUR/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 114.5735,
        close_ms: 1718364390600,
        current_return: 1.0011891929634689,
        initial_entry_price: 114.5735,
        is_closed_position: true,
        miner_hotkey: "5HCJ6okRkmCsu7iLEWotBxgcZy11RhbxSzs8MXT4Dei9osUx",
        net_leverage: 0.0,
        open_ms: 1718352292342,
        orders: [
          {
            leverage: -0.5,
            order_type: "SHORT",
            order_uuid: "4e061550-f945-4d0e-a381-91d2761c4aba",
            price: 114.5735,
            price_sources: [],
            processed_ms: 1718352292033,
          },
          {
            leverage: 0.01,
            order_type: "FLAT",
            order_uuid: "17a1d3b9-d036-4f5a-bcbb-7fbd3fb046db",
            price: 114.301,
            price_sources: [],
            processed_ms: 1718364390600,
          },
        ],
        position_type: "FLAT",
        position_uuid: "cc696c80-ef58-4fcd-9f75-b6f135e24b60",
        return_at_close: 1.001154151341715,
        trade_pair: ["CADJPY", "CAD/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.91191,
        close_ms: 1718604072478,
        current_return: 0.9970336985009486,
        initial_entry_price: 0.91191,
        is_closed_position: true,
        miner_hotkey: "5HCJ6okRkmCsu7iLEWotBxgcZy11RhbxSzs8MXT4Dei9osUx",
        net_leverage: 0.0,
        open_ms: 1718309848095,
        orders: [
          {
            leverage: 0.5,
            order_type: "LONG",
            order_uuid: "46fab630-5d3b-4f1c-b524-4920455443cf",
            price: 0.91191,
            price_sources: [],
            processed_ms: 1718309847800,
          },
          {
            leverage: 0.01,
            order_type: "FLAT",
            order_uuid: "863010d8-f1b4-4e31-978c-b31d8f7db2c2",
            price: 0.9065,
            price_sources: [],
            processed_ms: 1718604072478,
          },
        ],
        position_type: "FLAT",
        position_uuid: "57598cc2-30b1-4fc6-914d-871efb6650ce",
        return_at_close: 0.9969988023215011,
        trade_pair: ["AUDCAD", "AUD/CAD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.61182,
        close_ms: 1718626770094,
        current_return: 1.000596580693668,
        initial_entry_price: 0.61182,
        is_closed_position: true,
        miner_hotkey: "5HCJ6okRkmCsu7iLEWotBxgcZy11RhbxSzs8MXT4Dei9osUx",
        net_leverage: 0.0,
        open_ms: 1718373579963,
        orders: [
          {
            leverage: -0.5,
            order_type: "SHORT",
            order_uuid: "f4d7f900-459e-40d4-92b6-0c4135f59790",
            price: 0.61182,
            price_sources: [],
            processed_ms: 1718373579918,
          },
          {
            leverage: 0.01,
            order_type: "FLAT",
            order_uuid: "98c56ee9-1c7b-4d8d-a6ce-e5342f714947",
            price: 0.61109,
            price_sources: [],
            processed_ms: 1718626770094,
          },
        ],
        position_type: "FLAT",
        position_uuid: "aef008b5-b006-4d5e-9547-005173094380",
        return_at_close: 1.0005615598133437,
        trade_pair: ["NZDUSD", "NZD/USD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 1.75283,
        close_ms: 1718697014077,
        current_return: 1.0011695372625982,
        initial_entry_price: 1.75283,
        is_closed_position: true,
        miner_hotkey: "5HCJ6okRkmCsu7iLEWotBxgcZy11RhbxSzs8MXT4Dei9osUx",
        net_leverage: 0.0,
        open_ms: 1718626263083,
        orders: [
          {
            leverage: 0.5,
            order_type: "LONG",
            order_uuid: "50bfa454-6b31-42c2-b4f7-11f6d9c921f2",
            price: 1.75283,
            price_sources: [],
            processed_ms: 1718626262475,
          },
          {
            leverage: 0.01,
            order_type: "FLAT",
            order_uuid: "231d2f78-8160-4a46-bd20-36e53996578f",
            price: 1.75693,
            price_sources: [],
            processed_ms: 1718697014077,
          },
        ],
        position_type: "FLAT",
        position_uuid: "a4d7f4c6-4ec7-448f-8d94-b87bebd45f34",
        return_at_close: 1.001134496328794,
        trade_pair: ["EURNZD", "EUR/NZD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.906816796518952,
        close_ms: 1718780627558,
        current_return: 0.9952012338575678,
        initial_entry_price: 0.906816796518952,
        is_closed_position: true,
        miner_hotkey: "5HCJ6okRkmCsu7iLEWotBxgcZy11RhbxSzs8MXT4Dei9osUx",
        net_leverage: 0.0,
        open_ms: 1718604120885,
        orders: [
          {
            leverage: -0.5,
            order_type: "SHORT",
            order_uuid: "4c9045e1-6f3d-4cdf-abb2-f6fd84dff2d6",
            price: 0.906816796518952,
            price_sources: [],
            processed_ms: 1718604120488,
          },
          {
            leverage: 0.01,
            order_type: "FLAT",
            order_uuid: "cf784cc7-1e4c-4078-993d-e551377285c5",
            price: 0.91552,
            price_sources: [],
            processed_ms: 1718780627558,
          },
        ],
        position_type: "FLAT",
        position_uuid: "ceed3e75-32bb-4711-9708-5835ca290133",
        return_at_close: 0.9951664018143828,
        trade_pair: ["AUDCAD", "AUD/CAD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 65164.68000000001,
        close_ms: 1718862660896,
        current_return: 1.0003908252139042,
        initial_entry_price: 65164.68,
        is_closed_position: true,
        miner_hotkey: "5HCJ6okRkmCsu7iLEWotBxgcZy11RhbxSzs8MXT4Dei9osUx",
        net_leverage: 0.0,
        open_ms: 1718787544036,
        orders: [
          {
            leverage: 0.1,
            order_type: "LONG",
            order_uuid: "fa49796b-c561-41f4-9639-5be11e7f4060",
            price: 65164.68,
            price_sources: [],
            processed_ms: 1718787542020,
          },
          {
            leverage: 0.01,
            order_type: "FLAT",
            order_uuid: "5d8785b9-6def-441b-b3ba-b69c590d0b58",
            price: 65419.36,
            price_sources: [],
            processed_ms: 1718862660896,
          },
        ],
        position_type: "FLAT",
        position_uuid: "e8434388-07a9-40f3-af16-00427b7fa4b1",
        return_at_close: 1.0002907861313828,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 0.9487,
        close_ms: 1718870968277,
        current_return: 1.0062190365763677,
        initial_entry_price: 0.9487,
        is_closed_position: true,
        miner_hotkey: "5HCJ6okRkmCsu7iLEWotBxgcZy11RhbxSzs8MXT4Dei9osUx",
        net_leverage: 0.0,
        open_ms: 1718862815320,
        orders: [
          {
            leverage: 1.0,
            order_type: "LONG",
            order_uuid: "bdddbc77-4a1c-43cf-a48b-14fe1470581b",
            price: 0.9487,
            price_sources: [],
            processed_ms: 1718862815279,
          },
          {
            leverage: 0.01,
            order_type: "FLAT",
            order_uuid: "7d64c747-12cc-4f57-8341-5fdbcd9f8580",
            price: 0.9546,
            price_sources: [],
            processed_ms: 1718870968277,
          },
        ],
        position_type: "FLAT",
        position_uuid: "15d3645b-0c95-4d6a-8ba6-f5c5622d9c54",
        return_at_close: 1.0061486012438072,
        trade_pair: ["EURCHF", "EUR/CHF", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 1.268,
        close_ms: 1718906865938,
        current_return: 1.0004376971608833,
        initial_entry_price: 1.268,
        is_closed_position: true,
        miner_hotkey: "5HCJ6okRkmCsu7iLEWotBxgcZy11RhbxSzs8MXT4Dei9osUx",
        net_leverage: 0.0,
        open_ms: 1718884685257,
        orders: [
          {
            leverage: -0.5,
            order_type: "SHORT",
            order_uuid: "d8ffbbc1-8475-4a6f-ae8e-d15c5ba78475",
            price: 1.268,
            price_sources: [],
            processed_ms: 1718884685102,
          },
          {
            leverage: 0.01,
            order_type: "FLAT",
            order_uuid: "b119b9ab-59db-4232-bb99-030f6ce34b6e",
            price: 1.26689,
            price_sources: [],
            processed_ms: 1718906865938,
          },
        ],
        position_type: "FLAT",
        position_uuid: "a1e9fb77-b8c2-478b-87f3-ac9b85dd7219",
        return_at_close: 1.0004026818414826,
        trade_pair: ["GBPUSD", "GBP/USD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 1.0702375,
        close_ms: 1718906907096,
        current_return: 0.9992582990949845,
        initial_entry_price: 1.06849,
        is_closed_position: true,
        miner_hotkey: "5HCJ6okRkmCsu7iLEWotBxgcZy11RhbxSzs8MXT4Dei9osUx",
        net_leverage: 0.0,
        open_ms: 1718367052445,
        orders: [
          {
            leverage: -0.5,
            order_type: "SHORT",
            order_uuid: "5d811362-ccad-4895-9833-8a6ea829fc32",
            price: 1.06849,
            price_sources: [],
            processed_ms: 1718367052134,
          },
          {
            leverage: -0.5,
            order_type: "SHORT",
            order_uuid: "630cef71-8417-4d4d-bc8e-acc8fcfcdd7a",
            price: 1.071985,
            price_sources: [],
            processed_ms: 1718871006385,
          },
          {
            leverage: 0.01,
            order_type: "FLAT",
            order_uuid: "32d60519-26a2-4741-b49f-8a6b37833c66",
            price: 1.07103,
            price_sources: [],
            processed_ms: 1718906907096,
          },
        ],
        position_type: "FLAT",
        position_uuid: "62e7e20b-fb47-4ca0-9fda-bc2acc0df35d",
        return_at_close: 0.9991883510140479,
        trade_pair: ["EURUSD", "EUR/USD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 1.0696,
        close_ms: 1718958728594,
        current_return: 1.0007759910246823,
        initial_entry_price: 1.0696,
        is_closed_position: true,
        miner_hotkey: "5HCJ6okRkmCsu7iLEWotBxgcZy11RhbxSzs8MXT4Dei9osUx",
        net_leverage: 0.0,
        open_ms: 1718954379178,
        orders: [
          {
            leverage: -1.0,
            order_type: "SHORT",
            order_uuid: "357dc418-a2f7-47a3-8fc8-b60213611bcb",
            price: 1.0696,
            price_sources: [],
            processed_ms: 1718954379147,
          },
          {
            leverage: 0.01,
            order_type: "FLAT",
            order_uuid: "4da089e4-c7fc-4d74-9d4f-12fe258e7402",
            price: 1.06877,
            price_sources: [],
            processed_ms: 1718958728594,
          },
        ],
        position_type: "FLAT",
        position_uuid: "37515847-7125-49a1-bf9f-7e480afc875b",
        return_at_close: 1.0007059367053106,
        trade_pair: ["EURUSD", "EUR/USD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 3504.88,
        close_ms: 1719159901742,
        current_return: 1.0022490070986738,
        initial_entry_price: 3504.88,
        is_closed_position: true,
        miner_hotkey: "5HCJ6okRkmCsu7iLEWotBxgcZy11RhbxSzs8MXT4Dei9osUx",
        net_leverage: 0.0,
        open_ms: 1719038492769,
        orders: [
          {
            leverage: -0.25,
            order_type: "SHORT",
            order_uuid: "3e3e985c-d45e-45fa-a62f-9d7843347906",
            price: 3504.88,
            price_sources: [],
            processed_ms: 1719038492013,
          },
          {
            leverage: 0.01,
            order_type: "FLAT",
            order_uuid: "f66b505b-bfa5-4718-8bba-6748e713c000",
            price: 3473.35,
            price_sources: [],
            processed_ms: 1719159901742,
          },
        ],
        position_type: "FLAT",
        position_uuid: "576ed81d-62e1-45d0-842c-6d8a8e14be80",
        return_at_close: 1.0019984448468993,
        trade_pair: ["ETHUSD", "ETH/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 62494.48,
        close_ms: 1719224624493,
        current_return: 0.995189695153876,
        initial_entry_price: 62494.48,
        is_closed_position: true,
        miner_hotkey: "5HCJ6okRkmCsu7iLEWotBxgcZy11RhbxSzs8MXT4Dei9osUx",
        net_leverage: 0.0,
        open_ms: 1719214800717,
        orders: [
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "192ae6ca-0beb-4978-bcfc-3841a0246719",
            price: 62494.48,
            price_sources: [],
            processed_ms: 1719214798552,
          },
          {
            leverage: 0.01,
            order_type: "FLAT",
            order_uuid: "09770276-831a-4412-8ad2-c2a21d8b417a",
            price: 61292.01,
            price_sources: [],
            processed_ms: 1719224624493,
          },
        ],
        position_type: "FLAT",
        position_uuid: "9189e482-98e3-40c7-a105-9621d361bb42",
        return_at_close: 0.9949408977300875,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 3302.59,
        close_ms: 1719293642233,
        current_return: 1.0066902037491787,
        initial_entry_price: 3302.59,
        is_closed_position: true,
        miner_hotkey: "5HCJ6okRkmCsu7iLEWotBxgcZy11RhbxSzs8MXT4Dei9osUx",
        net_leverage: 0.0,
        open_ms: 1719234925202,
        orders: [
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "1d735a13-142c-49fd-a767-1ca94bfa54b7",
            price: 3302.59,
            price_sources: [],
            processed_ms: 1719234924509,
          },
          {
            leverage: 0.25,
            order_type: "FLAT",
            order_uuid: "b4ca8cb1-2a76-4669-b349-c4f9bab47820",
            price: 3390.97,
            price_sources: [],
            processed_ms: 1719293642233,
          },
        ],
        position_type: "FLAT",
        position_uuid: "dfb918c2-75aa-4b9a-8d0a-79330abfaa6a",
        return_at_close: 1.0064385311982416,
        trade_pair: ["ETHUSD", "ETH/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 158.874,
        close_ms: 1719319562196,
        current_return: 0.9952792779183504,
        initial_entry_price: 158.874,
        is_closed_position: true,
        miner_hotkey: "5HCJ6okRkmCsu7iLEWotBxgcZy11RhbxSzs8MXT4Dei9osUx",
        net_leverage: 0.0,
        open_ms: 1718906966729,
        orders: [
          {
            leverage: -1.0,
            order_type: "SHORT",
            order_uuid: "e7d2c9c3-9683-4047-a735-90dae84fc811",
            price: 158.874,
            price_sources: [],
            processed_ms: 1718906966586,
          },
          {
            leverage: 2.0,
            order_type: "FLAT",
            order_uuid: "2b1e51ce-ef5f-4d2e-a879-26d08e744ef3",
            price: 159.624,
            price_sources: [],
            processed_ms: 1719319562196,
          },
        ],
        position_type: "FLAT",
        position_uuid: "ed3ac0bc-97d6-498c-a60e-150bcac6588c",
        return_at_close: 0.995209608368896,
        trade_pair: ["USDJPY", "USD/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 114.9145,
        close_ms: 1719319619103,
        current_return: 0.9829568940386113,
        initial_entry_price: 114.9145,
        is_closed_position: true,
        miner_hotkey: "5HCJ6okRkmCsu7iLEWotBxgcZy11RhbxSzs8MXT4Dei9osUx",
        net_leverage: 0.0,
        open_ms: 1718780704348,
        orders: [
          {
            leverage: -1.0,
            order_type: "SHORT",
            order_uuid: "1989a3c0-fd4f-4291-987c-c9a42091073c",
            price: 114.9145,
            price_sources: [],
            processed_ms: 1718780703858,
          },
          {
            leverage: 2.0,
            order_type: "FLAT",
            order_uuid: "b58c8a08-840e-4d5f-ab11-7ee466254252",
            price: 116.873,
            price_sources: [],
            processed_ms: 1719319619103,
          },
        ],
        position_type: "FLAT",
        position_uuid: "bcf44b27-eb3a-4808-b5b2-492d82a8bdfa",
        return_at_close: 0.9828880870560286,
        trade_pair: ["CADJPY", "CAD/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 61022.37,
        close_ms: 1719340645444,
        current_return: 0.9962786597767344,
        initial_entry_price: 61022.37,
        is_closed_position: true,
        miner_hotkey: "5HCJ6okRkmCsu7iLEWotBxgcZy11RhbxSzs8MXT4Dei9osUx",
        net_leverage: 0.0,
        open_ms: 1719294467220,
        orders: [
          {
            leverage: -0.25,
            order_type: "SHORT",
            order_uuid: "37532231-7d66-4c7d-978c-82b853c507ff",
            price: 61022.37,
            price_sources: [],
            processed_ms: 1719294466564,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "990b4d66-d46e-4445-b5ec-de820cf3da3c",
            price: 61930.71,
            price_sources: [],
            processed_ms: 1719340645444,
          },
        ],
        position_type: "FLAT",
        position_uuid: "caad45db-ae9d-484c-95f4-3434cdeeb722",
        return_at_close: 0.9960295901117902,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 1.4619599999999997,
        close_ms: 1719956937939,
        current_return: 0.9949182334876784,
        initial_entry_price: 1.46209,
        is_closed_position: true,
        miner_hotkey: "5HCJ6okRkmCsu7iLEWotBxgcZy11RhbxSzs8MXT4Dei9osUx",
        net_leverage: 0.0,
        open_ms: 1719319530825,
        orders: [
          {
            leverage: -2.0,
            order_type: "SHORT",
            order_uuid: "9588ace5-b337-4a96-b7ae-45c5ffd6b2bc",
            price: 1.46209,
            price_sources: [],
            processed_ms: 1719319530789,
          },
          {
            leverage: 1.0,
            order_type: "LONG",
            order_uuid: "6e7e281b-52be-4cf9-bc6d-b8a2a303ad08",
            price: 1.46222,
            price_sources: [],
            processed_ms: 1719381095719,
          },
          {
            leverage: 0.01,
            order_type: "FLAT",
            order_uuid: "c818bad2-b7b8-46fc-a718-a9df351617fa",
            price: 1.46939,
            price_sources: [],
            processed_ms: 1719956937939,
          },
        ],
        position_type: "FLAT",
        position_uuid: "3e9c3213-4ce3-4f61-bcf2-b0a67cd2cd53",
        return_at_close: 0.9947789449349901,
        trade_pair: ["EURCAD", "EUR/CAD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 159.6745,
        close_ms: 1719956985466,
        current_return: 0.9888178763672345,
        initial_entry_price: 159.6745,
        is_closed_position: true,
        miner_hotkey: "5HCJ6okRkmCsu7iLEWotBxgcZy11RhbxSzs8MXT4Dei9osUx",
        net_leverage: 0.0,
        open_ms: 1719340728272,
        orders: [
          {
            leverage: -1.0,
            order_type: "SHORT",
            order_uuid: "36069714-26a0-476e-a40a-e3b296323798",
            price: 159.6745,
            price_sources: [],
            processed_ms: 1719340728076,
          },
          {
            leverage: 0.01,
            order_type: "FLAT",
            order_uuid: "bb38377a-7e01-4a35-ab62-b759e4b1635b",
            price: 161.46,
            price_sources: [],
            processed_ms: 1719956985466,
          },
        ],
        position_type: "FLAT",
        position_uuid: "4327c3e3-5e0f-4137-b79e-eaf317ef5926",
        return_at_close: 0.9887486591158888,
        trade_pair: ["USDJPY", "USD/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 96.488,
        close_ms: 1719986152749,
        current_return: 0.9906568692479893,
        initial_entry_price: 96.488,
        is_closed_position: true,
        miner_hotkey: "5HCJ6okRkmCsu7iLEWotBxgcZy11RhbxSzs8MXT4Dei9osUx",
        net_leverage: 0.0,
        open_ms: 1718697120400,
        orders: [
          {
            leverage: -0.5,
            order_type: "SHORT",
            order_uuid: "30cae8c5-7357-4147-9cb1-1c94c6bfadc9",
            price: 96.488,
            price_sources: [],
            processed_ms: 1718697120365,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "7f3e1158-6970-4b05-9b37-3f0eb5aaa2f3",
            price: 98.291,
            price_sources: [],
            processed_ms: 1719986152749,
          },
        ],
        position_type: "FLAT",
        position_uuid: "407ba1e0-3431-4127-a9e5-b2561c738324",
        return_at_close: 0.9906221962575656,
        trade_pair: ["NZDJPY", "NZD/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 58336.6,
        close_ms: 1720590480443,
        current_return: 1.0122118875628678,
        initial_entry_price: 58336.6,
        is_closed_position: true,
        miner_hotkey: "5HCJ6okRkmCsu7iLEWotBxgcZy11RhbxSzs8MXT4Dei9osUx",
        net_leverage: 0.0,
        open_ms: 1720080261960,
        orders: [
          {
            leverage: 1.0,
            order_type: "LONG",
            order_uuid: "951c1662-ac99-4a0e-95a2-b9ef70baec60",
            price: 58336.6,
            price_sources: [
              {
                close: 58336.6,
                high: 58336.6,
                lag_ms: 224,
                low: 58336.6,
                open: 58336.6,
                source: "TwelveData_ws",
                start_ms: 1720080260000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
              {
                close: 58458.0,
                high: 58458.0,
                lag_ms: 776,
                low: 58339.8,
                open: 58458.0,
                source: "Polygon_ws",
                start_ms: 1720080261000,
                timespan_ms: 0,
                volume: 0.16371378,
                vwap: 58341.1866,
                websocket: true,
              },
              {
                close: 58342.34,
                high: 58345.62,
                lag_ms: 2225,
                low: 58341.99,
                open: 58345.62,
                source: "Polygon_rest",
                start_ms: 1720080257000,
                timespan_ms: 1000,
                volume: 0.00153459,
                vwap: 58344.0768,
                websocket: false,
              },
              {
                close: 58297.06,
                high: 58312.68,
                lag_ms: 80225,
                low: 58270.02,
                open: 58290.69,
                source: "TwelveData_rest",
                start_ms: 1720080120000,
                timespan_ms: 60000,
                volume: null,
                vwap: null,
                websocket: false,
              },
            ],
            processed_ms: 1720080260224,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "f55cd09d-2deb-43dd-8723-0c175f691675",
            price: 59049.0,
            price_sources: [
              {
                close: 59049.0,
                high: 59049.0,
                lag_ms: 443,
                low: 59049.0,
                open: 59049.0,
                source: "Polygon_ws",
                start_ms: 1720590480000,
                timespan_ms: 0,
                volume: 0.01728395,
                vwap: 59049.0,
                websocket: true,
              },
              {
                close: 59055.4,
                high: 59055.4,
                lag_ms: 2557,
                low: 59055.4,
                open: 59055.4,
                source: "TwelveData_ws",
                start_ms: 1720590483000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
              {
                close: 59051.41,
                high: 59051.41,
                lag_ms: 3444,
                low: 59051.41,
                open: 59051.41,
                source: "Polygon_rest",
                start_ms: 1720590476000,
                timespan_ms: 1000,
                volume: 9.289e-5,
                vwap: 59051.41,
                websocket: false,
              },
              {
                close: 59005.64,
                high: 59026.05,
                lag_ms: 60444,
                low: 58964.03,
                open: 58971.7,
                source: "TwelveData_rest",
                start_ms: 1720590360000,
                timespan_ms: 60000,
                volume: null,
                vwap: null,
                websocket: false,
              },
            ],
            processed_ms: 1720590480443,
          },
        ],
        position_type: "FLAT",
        position_uuid: "99a0bfe0-d1cc-48d7-b28e-6bf039322542",
        return_at_close: 1.011199675675305,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 205.46,
        close_ms: 1720590517914,
        current_return: 0.9891745470312828,
        initial_entry_price: 205.257,
        is_closed_position: true,
        miner_hotkey: "5HCJ6okRkmCsu7iLEWotBxgcZy11RhbxSzs8MXT4Dei9osUx",
        net_leverage: 0.0,
        open_ms: 1719986183511,
        orders: [
          {
            leverage: -1.0,
            order_type: "SHORT",
            order_uuid: "bfafd5b5-db5f-4adb-a9ed-b8878383c6fe",
            price: 205.257,
            price_sources: [],
            processed_ms: 1719986183430,
          },
          {
            leverage: -1.0,
            order_type: "SHORT",
            order_uuid: "1c789374-e5f3-4ace-8782-b94ace9914d3",
            price: 205.663,
            price_sources: [
              {
                close: 205.663,
                high: 205.663,
                lag_ms: 171,
                low: 205.663,
                open: 205.663,
                source: "Polygon_ws",
                start_ms: 1720079064999,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 205.664,
                websocket: true,
              },
              {
                close: 205.659,
                high: 205.659,
                lag_ms: 1172,
                low: 205.659,
                open: 205.659,
                source: "TwelveData_ws",
                start_ms: 1720079066000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1720079064828,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "82ceb678-c59a-424c-9949-4ee120a6b6a5",
            price: 206.571,
            price_sources: [
              {
                close: 206.571,
                high: 206.571,
                lag_ms: 85,
                low: 206.571,
                open: 206.571,
                source: "Polygon_ws",
                start_ms: 1720590517999,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 206.571,
                websocket: true,
              },
              {
                close: 206.574,
                high: 206.574,
                lag_ms: 1086,
                low: 206.574,
                open: 206.574,
                source: "TwelveData_ws",
                start_ms: 1720590519000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1720590517914,
          },
        ],
        position_type: "FLAT",
        position_uuid: "3ae68d6b-8762-441a-ac9e-abe38b19fa70",
        return_at_close: 0.9890360625946985,
        trade_pair: ["GBPJPY", "GBP/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 1.107706674356428,
        close_ms: 1720626977332,
        current_return: 1.0013797205333803,
        initial_entry_price: 1.107706674356428,
        is_closed_position: true,
        miner_hotkey: "5HCJ6okRkmCsu7iLEWotBxgcZy11RhbxSzs8MXT4Dei9osUx",
        net_leverage: 0.0,
        open_ms: 1720591547536,
        orders: [
          {
            leverage: 1.0,
            order_type: "LONG",
            order_uuid: "51139a12-a849-49ab-84a8-d4b132f8df88",
            price: 1.107706674356428,
            price_sources: [
              {
                close: 1.107706674356428,
                high: 1.107706674356428,
                lag_ms: 478,
                low: 1.107706674356428,
                open: 1.107706674356428,
                source: "Polygon_ws",
                start_ms: 1720591547999,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 1.107706674356428,
                websocket: true,
              },
              {
                close: 1.10781,
                high: 1.10781,
                lag_ms: 479,
                low: 1.10781,
                open: 1.10781,
                source: "TwelveData_ws",
                start_ms: 1720591548000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1720591547521,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "bb7a7181-3b86-4a0b-9754-164bc2709f8f",
            price: 1.109235,
            price_sources: [
              {
                close: 1.109235,
                high: 1.109235,
                lag_ms: 332,
                low: 1.109235,
                open: 1.109235,
                source: "Polygon_ws",
                start_ms: 1720626977000,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 1.1092,
                websocket: true,
              },
              {
                close: 1.10918,
                high: 1.10918,
                lag_ms: 668,
                low: 1.10918,
                open: 1.10918,
                source: "TwelveData_ws",
                start_ms: 1720626978000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1720626977332,
          },
        ],
        position_type: "FLAT",
        position_uuid: "db82a49f-243b-484e-8903-f4760eceb3f7",
        return_at_close: 1.0013096239529429,
        trade_pair: ["AUDNZD", "AUD/NZD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 161.508,
        close_ms: 0,
        current_return: 0.9994025063773931,
        initial_entry_price: 161.508,
        is_closed_position: false,
        miner_hotkey: "5HCJ6okRkmCsu7iLEWotBxgcZy11RhbxSzs8MXT4Dei9osUx",
        net_leverage: -0.5,
        open_ms: 1720590529167,
        orders: [
          {
            leverage: -0.5,
            order_type: "SHORT",
            order_uuid: "ca790fad-ad9b-45f0-ba21-012a38c2d56f",
            price: 161.508,
            price_sources: [
              {
                close: 161.508,
                high: 161.508,
                lag_ms: 162,
                low: 161.508,
                open: 161.508,
                source: "TwelveData_ws",
                start_ms: 1720590529000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
              {
                close: 161.51,
                high: 161.51,
                lag_ms: 163,
                low: 161.51,
                open: 161.51,
                source: "Polygon_ws",
                start_ms: 1720590528999,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 161.512,
                websocket: true,
              },
            ],
            processed_ms: 1720590529162,
          },
        ],
        position_type: "SHORT",
        position_uuid: "78e9c265-1864-4c08-bd24-2a4e17f789d0",
        return_at_close: 0.9993850168335315,
        trade_pair: ["USDJPY", "USD/JPY", 7e-5, 0.001, 500],
      },
    ],
    thirty_day_returns: 0.9659874005542015,
  },
  "5HDmzyhrEco9w6Jv8eE3hDMcXSE4AGg1MuezPR4u2covxKwZ": {
    all_time_returns: 1.116019486606261,
    n_positions: 20,
    percentage_profitable: 0.95,
    positions: [
      {
        average_entry_price: 1.24577,
        close_ms: 1713531342909,
        current_return: 1.0001107748621334,
        initial_entry_price: 1.24577,
        is_closed_position: true,
        miner_hotkey: "5HDmzyhrEco9w6Jv8eE3hDMcXSE4AGg1MuezPR4u2covxKwZ",
        net_leverage: 0.0,
        open_ms: 1713530976143,
        orders: [
          {
            leverage: -0.2,
            order_type: "SHORT",
            order_uuid: "55eba08f-3ecd-4ef0-a583-2a1aa32fc05c",
            price: 1.24577,
            price_sources: [],
            processed_ms: 1713530976142,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "e32af1dc-b887-4785-8262-b0e5b74b0a67",
            price: 1.24508,
            price_sources: [],
            processed_ms: 1713531342909,
          },
        ],
        position_type: "FLAT",
        position_uuid: "de3f5dea-4533-4dc8-b3cb-f12222120298",
        return_at_close: 1.0000967733112853,
        trade_pair: ["GBPUSD", "GBP/USD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.90715,
        close_ms: 1713531387526,
        current_return: 1.0001366918370722,
        initial_entry_price: 0.90715,
        is_closed_position: true,
        miner_hotkey: "5HDmzyhrEco9w6Jv8eE3hDMcXSE4AGg1MuezPR4u2covxKwZ",
        net_leverage: 0.0,
        open_ms: 1713530927952,
        orders: [
          {
            leverage: 0.2,
            order_type: "LONG",
            order_uuid: "729eb89d-b3b6-4407-88c5-d32f41fa4c83",
            price: 0.90715,
            price_sources: [],
            processed_ms: 1713530927951,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "c8399fcb-723a-43ab-9d8b-95e8fa888cde",
            price: 0.90777,
            price_sources: [],
            processed_ms: 1713531387526,
          },
        ],
        position_type: "FLAT",
        position_uuid: "9ae8bcfe-3517-49f8-b0cc-75eadaa89d36",
        return_at_close: 1.0001226899233866,
        trade_pair: ["USDCHF", "USD/CHF", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 99.26200000000001,
        close_ms: 1713531408815,
        current_return: 1.000104773226411,
        initial_entry_price: 99.262,
        is_closed_position: true,
        miner_hotkey: "5HDmzyhrEco9w6Jv8eE3hDMcXSE4AGg1MuezPR4u2covxKwZ",
        net_leverage: 0.0,
        open_ms: 1713531162200,
        orders: [
          {
            leverage: -0.2,
            order_type: "SHORT",
            order_uuid: "f0f8a2bc-b332-42d9-8c0b-23d2eaf147e5",
            price: 99.262,
            price_sources: [],
            processed_ms: 1713531162198,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "f790a428-03ac-4dad-bfed-777d0a91069d",
            price: 99.21,
            price_sources: [],
            processed_ms: 1713531408815,
          },
        ],
        position_type: "FLAT",
        position_uuid: "c76c28bc-c2fa-472d-a096-38679139f4c7",
        return_at_close: 1.000090771759586,
        trade_pair: ["AUDJPY", "AUD/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 112.38500000000002,
        close_ms: 1713531530152,
        current_return: 1.0001370289629399,
        initial_entry_price: 112.385,
        is_closed_position: true,
        miner_hotkey: "5HDmzyhrEco9w6Jv8eE3hDMcXSE4AGg1MuezPR4u2covxKwZ",
        net_leverage: 0.0,
        open_ms: 1713531146345,
        orders: [
          {
            leverage: -0.2,
            order_type: "SHORT",
            order_uuid: "600ed25a-4653-4f12-b51e-b3d9a6141520",
            price: 112.385,
            price_sources: [],
            processed_ms: 1713531146344,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "6adc60ef-a09a-46f2-a17e-cc29564cfde7",
            price: 112.308,
            price_sources: [],
            processed_ms: 1713531530152,
          },
        ],
        position_type: "FLAT",
        position_uuid: "dd111585-ed12-4b06-b1fc-2c8bc6b5336e",
        return_at_close: 1.0001230270445345,
        trade_pair: ["CADJPY", "CAD/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 154.506,
        close_ms: 1713531922170,
        current_return: 1.0000699001980506,
        initial_entry_price: 154.506,
        is_closed_position: true,
        miner_hotkey: "5HDmzyhrEco9w6Jv8eE3hDMcXSE4AGg1MuezPR4u2covxKwZ",
        net_leverage: 0.0,
        open_ms: 1713530911486,
        orders: [
          {
            leverage: 0.2,
            order_type: "LONG",
            order_uuid: "4665cf89-1b04-4730-931a-adc59d1ca4b6",
            price: 154.506,
            price_sources: [],
            processed_ms: 1713530911485,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "923aee23-e22e-4fbc-a530-a2542f1f2101",
            price: 154.56,
            price_sources: [],
            processed_ms: 1713531922170,
          },
        ],
        position_type: "FLAT",
        position_uuid: "ba53c278-b309-4a52-a31c-f7fd21c30f20",
        return_at_close: 1.000055899219448,
        trade_pair: ["USDJPY", "USD/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.8108100000000001,
        close_ms: 1713532721868,
        current_return: 1.000074000074,
        initial_entry_price: 0.81081,
        is_closed_position: true,
        miner_hotkey: "5HDmzyhrEco9w6Jv8eE3hDMcXSE4AGg1MuezPR4u2covxKwZ",
        net_leverage: 0.0,
        open_ms: 1713531014855,
        orders: [
          {
            leverage: 0.2,
            order_type: "LONG",
            order_uuid: "7e97f0d0-541d-4e33-8a26-94df26a2b5ea",
            price: 0.81081,
            price_sources: [],
            processed_ms: 1713531014854,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "ecc3989e-cadb-4dec-9b46-441a0bde8494",
            price: 0.81111,
            price_sources: [],
            processed_ms: 1713532721868,
          },
        ],
        position_type: "FLAT",
        position_uuid: "ddc692b6-7b51-4cd7-a2c9-61a4f40e683b",
        return_at_close: 1.000059999037999,
        trade_pair: ["NZDCAD", "NZD/CAD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 192.473,
        close_ms: 1713533286539,
        current_return: 1.0001340447751113,
        initial_entry_price: 192.473,
        is_closed_position: true,
        miner_hotkey: "5HDmzyhrEco9w6Jv8eE3hDMcXSE4AGg1MuezPR4u2covxKwZ",
        net_leverage: 0.0,
        open_ms: 1713530960165,
        orders: [
          {
            leverage: 0.2,
            order_type: "LONG",
            order_uuid: "8c967a29-db67-40a7-889e-b47cbfcfbf13",
            price: 192.473,
            price_sources: [],
            processed_ms: 1713530960164,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "52d26d61-6e44-47db-b252-bd2f39a0d6af",
            price: 192.602,
            price_sources: [],
            processed_ms: 1713533286539,
          },
        ],
        position_type: "FLAT",
        position_uuid: "85fbe1de-11b5-435d-a27f-1be5366b2c03",
        return_at_close: 1.0001200428984844,
        trade_pair: ["GBPJPY", "GBP/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.85657,
        close_ms: 1713533342455,
        current_return: 1.000109740009573,
        initial_entry_price: 0.85657,
        is_closed_position: true,
        miner_hotkey: "5HDmzyhrEco9w6Jv8eE3hDMcXSE4AGg1MuezPR4u2covxKwZ",
        net_leverage: 0.0,
        open_ms: 1713531097265,
        orders: [
          {
            leverage: -0.2,
            order_type: "SHORT",
            order_uuid: "ae1fa594-bf85-478d-9620-fed795f2b7e7",
            price: 0.85657,
            price_sources: [],
            processed_ms: 1713531097264,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "d16333a4-90c7-4c84-8582-149e809507ab",
            price: 0.8561,
            price_sources: [],
            processed_ms: 1713533342455,
          },
        ],
        position_type: "FLAT",
        position_uuid: "a63fbec8-09dc-4c51-a1f3-3940d9d2507a",
        return_at_close: 1.000095738473213,
        trade_pair: ["EURGBP", "EUR/GBP", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 164.875,
        close_ms: 1713533539894,
        current_return: 1.000084912812737,
        initial_entry_price: 164.875,
        is_closed_position: true,
        miner_hotkey: "5HDmzyhrEco9w6Jv8eE3hDMcXSE4AGg1MuezPR4u2covxKwZ",
        net_leverage: 0.0,
        open_ms: 1713531076670,
        orders: [
          {
            leverage: 0.2,
            order_type: "LONG",
            order_uuid: "6f2cdeae-24ee-44d3-a8c2-acaea1ff53d7",
            price: 164.875,
            price_sources: [],
            processed_ms: 1713531076668,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "1fea84e3-c8e5-46a1-8f69-00b6ccc89293",
            price: 164.945,
            price_sources: [],
            processed_ms: 1713533539894,
          },
        ],
        position_type: "FLAT",
        position_uuid: "fb568f2f-3884-455d-9e90-0e7c1d09d23d",
        return_at_close: 1.0000709116239577,
        trade_pair: ["EURJPY", "EUR/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 1.06685,
        close_ms: 1713533585337,
        current_return: 1.0001031072784365,
        initial_entry_price: 1.06685,
        is_closed_position: true,
        miner_hotkey: "5HDmzyhrEco9w6Jv8eE3hDMcXSE4AGg1MuezPR4u2covxKwZ",
        net_leverage: 0.0,
        open_ms: 1713531113599,
        orders: [
          {
            leverage: 0.2,
            order_type: "LONG",
            order_uuid: "cd4601f0-8831-4a95-b4a2-4a812a0fe74e",
            price: 1.06685,
            price_sources: [],
            processed_ms: 1713531113598,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "fd9e9ffe-c497-425f-ab4b-a1226fc0f508",
            price: 1.0674,
            price_sources: [],
            processed_ms: 1713533585337,
          },
        ],
        position_type: "FLAT",
        position_uuid: "6367d3ae-5610-46ff-98bf-60deef1c4eec",
        return_at_close: 1.0000891058349346,
        trade_pair: ["EURUSD", "EUR/USD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.6418300000000001,
        close_ms: 1713766809366,
        current_return: 1.0012533864789959,
        initial_entry_price: 0.64226,
        is_closed_position: true,
        miner_hotkey: "5HDmzyhrEco9w6Jv8eE3hDMcXSE4AGg1MuezPR4u2covxKwZ",
        net_leverage: 0.0,
        open_ms: 1713531178373,
        orders: [
          {
            leverage: 0.2,
            order_type: "LONG",
            order_uuid: "93e0daca-a84f-4543-afe0-318d72abc2a0",
            price: 0.64226,
            price_sources: [],
            processed_ms: 1713531178372,
          },
          {
            leverage: 0.1,
            order_type: "LONG",
            order_uuid: "34bcb80f-7edc-4125-942c-174525ec9db8",
            price: 0.64191,
            price_sources: [],
            processed_ms: 1713532776010,
          },
          {
            leverage: 0.2,
            order_type: "LONG",
            order_uuid: "631ecca7-1343-42db-84bf-c3a74810476d",
            price: 0.64136,
            price_sources: [],
            processed_ms: 1713552528855,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "39ae7b35-e7dc-4ef5-883b-0a33d25df8da",
            price: 0.64344,
            price_sources: [],
            processed_ms: 1713766809366,
          },
        ],
        position_type: "FLAT",
        position_uuid: "b891e878-6e17-4d8e-ae08-7b3838edee76",
        return_at_close: 1.001218342610469,
        trade_pair: ["AUDUSD", "AUD/USD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 1.8225699999999996,
        close_ms: 1714156583433,
        current_return: 1.0001270945165268,
        initial_entry_price: 1.80889,
        is_closed_position: true,
        miner_hotkey: "5HDmzyhrEco9w6Jv8eE3hDMcXSE4AGg1MuezPR4u2covxKwZ",
        net_leverage: 0.0,
        open_ms: 1713531037305,
        orders: [
          {
            leverage: -0.2,
            order_type: "SHORT",
            order_uuid: "f061ac40-843a-4849-a5d1-b277f3b1d64a",
            price: 1.80889,
            price_sources: [],
            processed_ms: 1713531037298,
          },
          {
            leverage: 0.19,
            order_type: "LONG",
            order_uuid: "c567b3db-b1f1-4a1a-9e2a-77e0a1ba2389",
            price: 1.80817,
            price_sources: [],
            processed_ms: 1713546035744,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "74c87582-5594-4b8d-a989-96adcbaa481d",
            price: 1.79958,
            price_sources: [],
            processed_ms: 1714156583433,
          },
        ],
        position_type: "FLAT",
        position_uuid: "59a86dbe-206b-4abc-880e-fc0b7eb1f508",
        return_at_close: 1.0001130927372037,
        trade_pair: ["EURNZD", "EUR/NZD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 1.409059999999994,
        close_ms: 1714156620731,
        current_return: 1.000310535963687,
        initial_entry_price: 1.37472,
        is_closed_position: true,
        miner_hotkey: "5HDmzyhrEco9w6Jv8eE3hDMcXSE4AGg1MuezPR4u2covxKwZ",
        net_leverage: 0.0,
        open_ms: 1713530943966,
        orders: [
          {
            leverage: -0.2,
            order_type: "SHORT",
            order_uuid: "6436dcd3-944b-4be0-a9c6-1fe86202931d",
            price: 1.37472,
            price_sources: [],
            processed_ms: 1713530943966,
          },
          {
            leverage: -0.2,
            order_type: "SHORT",
            order_uuid: "e1bdcf5c-f439-4bac-951b-44e8e328c622",
            price: 1.37593,
            price_sources: [],
            processed_ms: 1713532760235,
          },
          {
            leverage: 0.39,
            order_type: "LONG",
            order_uuid: "cab9a708-cc20-42ff-b242-6b9f39bac974",
            price: 1.37446,
            price_sources: [],
            processed_ms: 1713533855318,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "4c03ab5a-e4fa-48ca-93af-b91d6a834294",
            price: 1.36637,
            price_sources: [],
            processed_ms: 1714156620731,
          },
        ],
        position_type: "FLAT",
        position_uuid: "5263673b-fcb8-42b4-90c7-1d0a5fd18806",
        return_at_close: 1.00028252726868,
        trade_pair: ["USDCAD", "USD/CAD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: -132106.50000000306,
        close_ms: 1714240338654,
        current_return: 1.0310083028010637,
        initial_entry_price: 63083.53,
        is_closed_position: true,
        miner_hotkey: "5HDmzyhrEco9w6Jv8eE3hDMcXSE4AGg1MuezPR4u2covxKwZ",
        net_leverage: 0.0,
        open_ms: 1714230385309,
        orders: [
          {
            leverage: 2.0,
            order_type: "LONG",
            order_uuid: "52f43efc-d2d2-41bc-9346-616dd3bc8567",
            price: 63083.53,
            price_sources: [],
            processed_ms: 1714230385304,
          },
          {
            leverage: 2.88,
            order_type: "LONG",
            order_uuid: "1fb319d1-9edf-475c-a21c-61b8e89ba356",
            price: 63128.39,
            price_sources: [],
            processed_ms: 1714230712385,
          },
          {
            leverage: -4.87,
            order_type: "SHORT",
            order_uuid: "69794437-01a2-47a1-98bc-388753b3bf56",
            price: 63510.86,
            price_sources: [],
            processed_ms: 1714240298250,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "5d2d71ad-abe6-40b0-8e1e-7d98a74ed970",
            price: 63504.82,
            price_sources: [],
            processed_ms: 1714240338654,
          },
        ],
        position_type: "FLAT",
        position_uuid: "8598a9ba-4d47-44d6-a7e9-f5f5c6c8fb93",
        return_at_close: 1.0259769822833946,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 62847.264873949585,
        close_ms: 1714272876389,
        current_return: 1.095816924654434,
        initial_entry_price: 63127.67,
        is_closed_position: true,
        miner_hotkey: "5HDmzyhrEco9w6Jv8eE3hDMcXSE4AGg1MuezPR4u2covxKwZ",
        net_leverage: 0.0,
        open_ms: 1714242870829,
        orders: [
          {
            leverage: 4.88,
            order_type: "LONG",
            order_uuid: "17f64b86-8407-4824-826b-1055601511fd",
            price: 63127.67,
            price_sources: [],
            processed_ms: 1714242870824,
          },
          {
            leverage: 4.88,
            order_type: "LONG",
            order_uuid: "8eda1c2e-0b63-4fc7-b9b8-54a2f80460a6",
            price: 63624.99,
            price_sources: [],
            processed_ms: 1714266184327,
          },
          {
            leverage: -5.0,
            order_type: "SHORT",
            order_uuid: "09c0e67f-c913-4472-a970-03cec5d22aa3",
            price: 63880.0,
            price_sources: [],
            processed_ms: 1714272773936,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "3984cdd6-1975-41fe-87ac-96fa7d83fce3",
            price: 64118.0,
            price_sources: [],
            processed_ms: 1714272876389,
          },
        ],
        position_type: "FLAT",
        position_uuid: "774ca0c7-180b-4e60-bbf6-b7dc5f58a462",
        return_at_close: 1.0851217514698068,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 63958.65,
        close_ms: 1714293980260,
        current_return: 1.0009094000576935,
        initial_entry_price: 63958.65,
        is_closed_position: true,
        miner_hotkey: "5HDmzyhrEco9w6Jv8eE3hDMcXSE4AGg1MuezPR4u2covxKwZ",
        net_leverage: 0.0,
        open_ms: 1714278241544,
        orders: [
          {
            leverage: -0.2,
            order_type: "SHORT",
            order_uuid: "2787a8ae-6390-4b53-9457-9130399791e5",
            price: 63958.65,
            price_sources: [],
            processed_ms: 1714278241541,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "79730f65-3933-4f18-9c63-fff338c80955",
            price: 63667.83,
            price_sources: [],
            processed_ms: 1714293980260,
          },
        ],
        position_type: "FLAT",
        position_uuid: "0751fa92-d77d-43ea-9dc8-901894fac3bb",
        return_at_close: 1.000709218177682,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 1.5000100000000003,
        close_ms: 1714566698565,
        current_return: 1.0002079825488257,
        initial_entry_price: 1.46695,
        is_closed_position: true,
        miner_hotkey: "5HDmzyhrEco9w6Jv8eE3hDMcXSE4AGg1MuezPR4u2covxKwZ",
        net_leverage: 0.0,
        open_ms: 1713531130138,
        orders: [
          {
            leverage: -0.2,
            order_type: "SHORT",
            order_uuid: "8afd9d79-46b0-42a2-933c-78a9800c9197",
            price: 1.46695,
            price_sources: [],
            processed_ms: 1713531130136,
          },
          {
            leverage: 0.19,
            order_type: "LONG",
            order_uuid: "7cd01561-e70c-439b-8972-88fcd1184d43",
            price: 1.46521,
            price_sources: [],
            processed_ms: 1713535892489,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "1db6d0a3-f0be-4166-bc13-9638f1cb40bd",
            price: 1.4695,
            price_sources: [],
            processed_ms: 1714566698565,
          },
        ],
        position_type: "FLAT",
        position_uuid: "2a4969bf-2055-4b4a-9d0e-93b3b5a1c068",
        return_at_close: 1.00019397963707,
        trade_pair: ["EURCAD", "EUR/CAD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 152.79,
        close_ms: 1714965556745,
        current_return: 1.0000653184108907,
        initial_entry_price: 152.79,
        is_closed_position: true,
        miner_hotkey: "5HDmzyhrEco9w6Jv8eE3hDMcXSE4AGg1MuezPR4u2covxKwZ",
        net_leverage: 0.0,
        open_ms: 1714754499519,
        orders: [
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "61a21f68-172b-42eb-84e3-74db6a7a7372",
            price: 152.79,
            price_sources: [],
            processed_ms: 1714754499293,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "cb955ac8-05b1-49b5-834d-d32f0a7b05b8",
            price: 153.788,
            price_sources: [],
            processed_ms: 1714965556745,
          },
        ],
        position_type: "FLAT",
        position_uuid: "d27468bd-d75f-47c4-9509-5765b96c8857",
        return_at_close: 1.0000646183651678,
        trade_pair: ["USDJPY", "USD/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 157.141,
        close_ms: 1718388826856,
        current_return: 1.004352778714658,
        initial_entry_price: 157.141,
        is_closed_position: true,
        miner_hotkey: "5HDmzyhrEco9w6Jv8eE3hDMcXSE4AGg1MuezPR4u2covxKwZ",
        net_leverage: 0.0,
        open_ms: 1717158323896,
        orders: [
          {
            leverage: 4.0,
            order_type: "LONG",
            order_uuid: "fc37ffa8-7f07-49d3-aa68-e5aace288478",
            price: 157.141,
            price_sources: [],
            processed_ms: 1717158323888,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "fd9b4ee7-44e1-4172-8383-a40b7cbeba15",
            price: 157.312,
            price_sources: [],
            processed_ms: 1718388826856,
          },
        ],
        position_type: "FLAT",
        position_uuid: "6b47d984-6ca4-4adc-8943-bce870c94b4c",
        return_at_close: 1.004071559936618,
        trade_pair: ["USDJPY", "USD/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 68407.24745098039,
        close_ms: 1718390537764,
        current_return: 0.9949765581053168,
        initial_entry_price: 63233.37,
        is_closed_position: true,
        miner_hotkey: "5HDmzyhrEco9w6Jv8eE3hDMcXSE4AGg1MuezPR4u2covxKwZ",
        net_leverage: 0.0,
        open_ms: 1714814391492,
        orders: [
          {
            leverage: 0.002,
            order_type: "LONG",
            order_uuid: "1c1dd348-467e-4ec2-b182-1b91fa0e0de4",
            price: 63233.37,
            price_sources: [],
            processed_ms: 1714814391270,
          },
          {
            leverage: 0.05,
            order_type: "LONG",
            order_uuid: "56b79be4-98fa-449c-b515-e4fec8ba7cf2",
            price: 68488.0,
            price_sources: [],
            processed_ms: 1716229009003,
          },
          {
            leverage: 0.05,
            order_type: "LONG",
            order_uuid: "bdac8bfb-9846-4acb-bde8-44dd44dd382e",
            price: 68533.45,
            price_sources: [],
            processed_ms: 1716571798925,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "894a1b83-0fb4-4da2-9f1d-95bbb6bb745d",
            price: 65293.04,
            price_sources: [],
            processed_ms: 1718390537764,
          },
        ],
        position_type: "FLAT",
        position_uuid: "9adf7935-0727-4705-863c-9de5d233e276",
        return_at_close: 0.99487507049639,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 57578.34999999999,
        close_ms: 0,
        current_return: 1.0126131610231972,
        initial_entry_price: 57578.35,
        is_closed_position: false,
        miner_hotkey: "5HDmzyhrEco9w6Jv8eE3hDMcXSE4AGg1MuezPR4u2covxKwZ",
        net_leverage: 1.3,
        open_ms: 1720094879143,
        orders: [
          {
            leverage: 1.3,
            order_type: "LONG",
            order_uuid: "bb46f22c-3a2b-4618-8efc-9b140724f871",
            price: 57578.35,
            price_sources: [
              {
                close: 57578.35,
                high: 57581.42,
                lag_ms: 325,
                low: 57578.35,
                open: 57578.35,
                source: "Polygon_ws",
                start_ms: 1720094878999,
                timespan_ms: 0,
                volume: 0.00655938,
                vwap: 57580.1971,
                websocket: true,
              },
              {
                close: 57580.72,
                high: 57580.72,
                lag_ms: 2675,
                low: 57580.72,
                open: 57580.72,
                source: "Polygon_rest",
                start_ms: 1720094875000,
                timespan_ms: 1000,
                volume: 0.0048,
                vwap: 57580.72,
                websocket: false,
              },
              {
                close: 57633.6,
                high: 57633.6,
                lag_ms: 6674,
                low: 57633.6,
                open: 57633.6,
                source: "TwelveData_ws",
                start_ms: 1720094872000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
              {
                close: 57629.97,
                high: 57673.01,
                lag_ms: 58675,
                low: 57622.04,
                open: 57651.54,
                source: "TwelveData_rest",
                start_ms: 1720094760000,
                timespan_ms: 60000,
                volume: null,
                vwap: null,
                websocket: false,
              },
            ],
            processed_ms: 1720094878674,
          },
        ],
        position_type: "LONG",
        position_uuid: "942e3574-4705-4391-9848-37aa1520d248",
        return_at_close: 1.011954962468532,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
    ],
    thirty_day_returns: 1.0,
  },
  "5HK1P1eWwFznqdjvpS2PKFrtdkvKbKMqcsss5KbyDAEeKEzf": {
    all_time_returns: 1.0,
    n_positions: 0,
    percentage_profitable: 0.0,
    positions: [
      {
        average_entry_price: 65105.47,
        close_ms: 1711208632967,
        current_return: 0.9976049017079517,
        initial_entry_price: 65105.47,
        is_closed_position: true,
        miner_hotkey: "5HK1P1eWwFznqdjvpS2PKFrtdkvKbKMqcsss5KbyDAEeKEzf",
        net_leverage: 0.0,
        open_ms: 1711205096594,
        orders: [
          {
            leverage: 0.6,
            order_type: "LONG",
            order_uuid: "c8d565b8-f49d-4d76-8b22-2c74daa05dfb",
            price: 65105.47,
            price_sources: [],
            processed_ms: 1711205096594,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "88c22b36-5092-4c34-a483-d79716a650e6",
            price: 64845.58,
            price_sources: [],
            processed_ms: 1711208632967,
          },
        ],
        position_type: "FLAT",
        position_uuid: "45dcb213-6478-42c8-8805-ee4ed46c00be",
        return_at_close: 0.9964077758259021,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 65671.68,
        close_ms: 1711215949822,
        current_return: 1.0002210085077767,
        initial_entry_price: 65671.68,
        is_closed_position: true,
        miner_hotkey: "5HK1P1eWwFznqdjvpS2PKFrtdkvKbKMqcsss5KbyDAEeKEzf",
        net_leverage: 0.0,
        open_ms: 1711212383007,
        orders: [
          {
            leverage: -0.6,
            order_type: "SHORT",
            order_uuid: "d59a5e6e-2208-4e9f-871c-7847e1e73880",
            price: 65671.68,
            price_sources: [],
            processed_ms: 1711212383006,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "cef843cc-3942-49ae-9c7f-1de5c7e4ff46",
            price: 65647.49,
            price_sources: [],
            processed_ms: 1711215949822,
          },
        ],
        position_type: "FLAT",
        position_uuid: "7e1abeb2-eb35-4418-8021-cd9cc2b920e0",
        return_at_close: 0.9990207432975674,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 65682.69,
        close_ms: 1711219623173,
        current_return: 1.0030261854378986,
        initial_entry_price: 65682.69,
        is_closed_position: true,
        miner_hotkey: "5HK1P1eWwFznqdjvpS2PKFrtdkvKbKMqcsss5KbyDAEeKEzf",
        net_leverage: 0.0,
        open_ms: 1711216036872,
        orders: [
          {
            leverage: -0.6,
            order_type: "SHORT",
            order_uuid: "a37f5d50-7e92-4427-97b5-0dbce47bc9ff",
            price: 65682.69,
            price_sources: [],
            processed_ms: 1711216036871,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "1c41d143-da6f-4a07-bcc8-5fb9d5b0a803",
            price: 65351.41,
            price_sources: [],
            processed_ms: 1711219623173,
          },
        ],
        position_type: "FLAT",
        position_uuid: "1b186eb0-b7ec-4cc8-b767-d8a130555cad",
        return_at_close: 1.0018225540153731,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 65348.05,
        close_ms: 1711223275976,
        current_return: 1.0043271069297401,
        initial_entry_price: 65348.05,
        is_closed_position: true,
        miner_hotkey: "5HK1P1eWwFznqdjvpS2PKFrtdkvKbKMqcsss5KbyDAEeKEzf",
        net_leverage: 0.0,
        open_ms: 1711219709686,
        orders: [
          {
            leverage: -0.6,
            order_type: "SHORT",
            order_uuid: "ae3ffd7a-db58-4077-bc97-983dd658c6dc",
            price: 65348.05,
            price_sources: [],
            processed_ms: 1711219709685,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "a326ee0d-a6bf-463f-ba7f-83bdc1120836",
            price: 64876.77,
            price_sources: [],
            processed_ms: 1711223275976,
          },
        ],
        position_type: "FLAT",
        position_uuid: "2d69062a-7424-4a23-a3cd-afd197cf4352",
        return_at_close: 1.0031219144014245,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 64901.27,
        close_ms: 1711226935793,
        current_return: 0.9993678397972797,
        initial_entry_price: 64901.27,
        is_closed_position: true,
        miner_hotkey: "5HK1P1eWwFznqdjvpS2PKFrtdkvKbKMqcsss5KbyDAEeKEzf",
        net_leverage: 0.0,
        open_ms: 1711223406725,
        orders: [
          {
            leverage: -0.6,
            order_type: "SHORT",
            order_uuid: "5c415b92-8016-42ce-84fb-d5d3f66fde0b",
            price: 64901.27,
            price_sources: [],
            processed_ms: 1711223406724,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "bc0a2f1c-d1b7-45cb-a3bb-16dd9fbc5475",
            price: 64969.65,
            price_sources: [],
            processed_ms: 1711226935793,
          },
        ],
        position_type: "FLAT",
        position_uuid: "698ea246-eebe-4667-a244-8a0e7506b997",
        return_at_close: 0.998168598389523,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 64946.1,
        close_ms: 1711230600012,
        current_return: 0.9999783820737503,
        initial_entry_price: 64946.1,
        is_closed_position: true,
        miner_hotkey: "5HK1P1eWwFznqdjvpS2PKFrtdkvKbKMqcsss5KbyDAEeKEzf",
        net_leverage: 0.0,
        open_ms: 1711227022714,
        orders: [
          {
            leverage: -0.6,
            order_type: "SHORT",
            order_uuid: "c24fe5bd-9157-4d9d-a133-b345c38e0bd9",
            price: 64946.1,
            price_sources: [],
            processed_ms: 1711227022714,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "635720c3-be18-453d-9e06-987878d282d2",
            price: 64948.44,
            price_sources: [],
            processed_ms: 1711230600012,
          },
        ],
        position_type: "FLAT",
        position_uuid: "1608a744-932e-4bf4-a030-cb77cb8a824b",
        return_at_close: 0.9987784080152619,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 64951.759999999995,
        close_ms: 1711234261736,
        current_return: 1.0011574744086995,
        initial_entry_price: 64951.76,
        is_closed_position: true,
        miner_hotkey: "5HK1P1eWwFznqdjvpS2PKFrtdkvKbKMqcsss5KbyDAEeKEzf",
        net_leverage: 0.0,
        open_ms: 1711230686734,
        orders: [
          {
            leverage: -0.6,
            order_type: "SHORT",
            order_uuid: "ea92e977-7f24-46fa-bddd-1f21a7c48320",
            price: 64951.76,
            price_sources: [],
            processed_ms: 1711230686733,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "108087af-3a3d-48a9-a8cf-cc457f068afe",
            price: 64826.46,
            price_sources: [],
            processed_ms: 1711234261736,
          },
        ],
        position_type: "FLAT",
        position_uuid: "915bfaec-76f4-4283-853c-4cfb48c67cee",
        return_at_close: 0.9999560854394091,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 64263.87999999999,
        close_ms: 1711252576918,
        current_return: 0.9982836703915171,
        initial_entry_price: 64263.88,
        is_closed_position: true,
        miner_hotkey: "5HK1P1eWwFznqdjvpS2PKFrtdkvKbKMqcsss5KbyDAEeKEzf",
        net_leverage: 0.0,
        open_ms: 1711249012756,
        orders: [
          {
            leverage: 0.6,
            order_type: "LONG",
            order_uuid: "131bcb7c-ae7d-4047-976f-1b1159b3ce87",
            price: 64263.88,
            price_sources: [],
            processed_ms: 1711249012754,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "961bffa0-a7a2-423f-baf3-6bbd5543128d",
            price: 64080.05,
            price_sources: [],
            processed_ms: 1711252576918,
          },
        ],
        position_type: "FLAT",
        position_uuid: "6a530526-e80b-42b8-a8db-282ee7df95bc",
        return_at_close: 0.9970857299870474,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 64044.49,
        close_ms: 1711256447046,
        current_return: 1.0017891937307957,
        initial_entry_price: 64044.49,
        is_closed_position: true,
        miner_hotkey: "5HK1P1eWwFznqdjvpS2PKFrtdkvKbKMqcsss5KbyDAEeKEzf",
        net_leverage: 0.0,
        open_ms: 1711252691024,
        orders: [
          {
            leverage: 0.6,
            order_type: "LONG",
            order_uuid: "915387ca-dd1c-41f9-8933-e4a5bf0d3fcd",
            price: 64044.49,
            price_sources: [],
            processed_ms: 1711252691023,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "9927d07e-d360-49c0-90d7-d938277965db",
            price: 64235.47,
            price_sources: [],
            processed_ms: 1711256447046,
          },
        ],
        position_type: "FLAT",
        position_uuid: "1e078ec0-28e9-47c8-962d-1594fec798c7",
        return_at_close: 1.0005870466983187,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 64203.69,
        close_ms: 1711259503206,
        current_return: 1.0016547647027765,
        initial_entry_price: 64203.69,
        is_closed_position: true,
        miner_hotkey: "5HK1P1eWwFznqdjvpS2PKFrtdkvKbKMqcsss5KbyDAEeKEzf",
        net_leverage: 0.0,
        open_ms: 1711256533755,
        orders: [
          {
            leverage: 0.6,
            order_type: "LONG",
            order_uuid: "e0c4a4af-dd0d-4200-9fbe-a915a188dfc1",
            price: 64203.69,
            price_sources: [],
            processed_ms: 1711256533754,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "86f0006a-f907-4795-92a8-58cb2f308012",
            price: 64380.76,
            price_sources: [],
            processed_ms: 1711259503206,
          },
        ],
        position_type: "FLAT",
        position_uuid: "eb04c44e-d419-4cb7-a787-fb0f4cad1c46",
        return_at_close: 1.0004527789851332,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 64368.530000000006,
        close_ms: 1711263167795,
        current_return: 0.9957187930188865,
        initial_entry_price: 64368.53,
        is_closed_position: true,
        miner_hotkey: "5HK1P1eWwFznqdjvpS2PKFrtdkvKbKMqcsss5KbyDAEeKEzf",
        net_leverage: 0.0,
        open_ms: 1711259632046,
        orders: [
          {
            leverage: 1.25,
            order_type: "LONG",
            order_uuid: "a01a9083-4d68-4fa4-bb21-ab77e6eaecd6",
            price: 64368.53,
            price_sources: [],
            processed_ms: 1711259632045,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "03046101-d23e-4da6-b23d-ce636248bed7",
            price: 64148.07,
            price_sources: [],
            processed_ms: 1711263167795,
          },
        ],
        position_type: "FLAT",
        position_uuid: "44b79267-8d20-43e4-a0cd-81b36677c746",
        return_at_close: 0.9932294960363394,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 64203.15,
        close_ms: 1711266822832,
        current_return: 1.003822631755607,
        initial_entry_price: 64203.15,
        is_closed_position: true,
        miner_hotkey: "5HK1P1eWwFznqdjvpS2PKFrtdkvKbKMqcsss5KbyDAEeKEzf",
        net_leverage: 0.0,
        open_ms: 1711263255902,
        orders: [
          {
            leverage: 1.25,
            order_type: "LONG",
            order_uuid: "4c0ad8d0-01c8-4ef2-8330-35b2f961fb01",
            price: 64203.15,
            price_sources: [],
            processed_ms: 1711263255901,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "947d6c72-ce3c-4622-b6a1-b7429e1c841f",
            price: 64399.49,
            price_sources: [],
            processed_ms: 1711266822832,
          },
        ],
        position_type: "FLAT",
        position_uuid: "2016dcd0-317b-4040-89aa-0b9f46a9ecf6",
        return_at_close: 1.001313075176218,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 64375.0,
        close_ms: 1711270494744,
        current_return: 1.0132598058252427,
        initial_entry_price: 64375.0,
        is_closed_position: true,
        miner_hotkey: "5HK1P1eWwFznqdjvpS2PKFrtdkvKbKMqcsss5KbyDAEeKEzf",
        net_leverage: 0.0,
        open_ms: 1711266909742,
        orders: [
          {
            leverage: 1.25,
            order_type: "LONG",
            order_uuid: "3935407a-5924-4aac-996e-ab8b7c960017",
            price: 64375.0,
            price_sources: [],
            processed_ms: 1711266909741,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "e1bb1c75-0b37-4502-8a97-ce337b0233bd",
            price: 65057.88,
            price_sources: [],
            processed_ms: 1711270494744,
          },
        ],
        position_type: "FLAT",
        position_uuid: "4f8cb214-78fc-4b57-b800-badb8ab72a2d",
        return_at_close: 1.0107266563106796,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 65068.02,
        close_ms: 1711274154997,
        current_return: 0.9993366557027554,
        initial_entry_price: 65068.02,
        is_closed_position: true,
        miner_hotkey: "5HK1P1eWwFznqdjvpS2PKFrtdkvKbKMqcsss5KbyDAEeKEzf",
        net_leverage: 0.0,
        open_ms: 1711270582059,
        orders: [
          {
            leverage: 1.25,
            order_type: "LONG",
            order_uuid: "64807210-33e3-4ba8-b507-df2de7b5894a",
            price: 65068.02,
            price_sources: [],
            processed_ms: 1711270582058,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "b8d9ceeb-3858-4933-aee4-8bc3b210c38c",
            price: 65033.49,
            price_sources: [],
            processed_ms: 1711274154997,
          },
        ],
        position_type: "FLAT",
        position_uuid: "0512aa1f-2080-4863-9530-10d268a45d49",
        return_at_close: 0.9968383140634985,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 65142.73,
        close_ms: 1711286134456,
        current_return: 1.0078245492628264,
        initial_entry_price: 65142.73,
        is_closed_position: true,
        miner_hotkey: "5HK1P1eWwFznqdjvpS2PKFrtdkvKbKMqcsss5KbyDAEeKEzf",
        net_leverage: 0.0,
        open_ms: 1711277904694,
        orders: [
          {
            leverage: 1.25,
            order_type: "LONG",
            order_uuid: "76059026-89e4-4749-a0e6-3a7955732742",
            price: 65142.73,
            price_sources: [],
            processed_ms: 1711277904693,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "35bec070-7f83-4277-92b1-fba00df1de40",
            price: 65550.5,
            price_sources: [],
            processed_ms: 1711286134456,
          },
        ],
        position_type: "FLAT",
        position_uuid: "5db8929b-6bca-4197-9ceb-add9afd32532",
        return_at_close: 1.0053049878896694,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: -15213.619999998802,
        close_ms: 1711313869028,
        current_return: 0.9975792384412017,
        initial_entry_price: 65655.83,
        is_closed_position: true,
        miner_hotkey: "5HK1P1eWwFznqdjvpS2PKFrtdkvKbKMqcsss5KbyDAEeKEzf",
        net_leverage: 0.0,
        open_ms: 1711305473766,
        orders: [
          {
            leverage: -1.25,
            order_type: "SHORT",
            order_uuid: "b962cdea-3784-4907-b56d-0050dfc3f1f4",
            price: 65655.83,
            price_sources: [],
            processed_ms: 1711305473765,
          },
          {
            leverage: 0.625,
            order_type: "LONG",
            order_uuid: "7954c49a-15e1-413e-baf7-f730a670cc03",
            price: 65761.89,
            price_sources: [],
            processed_ms: 1711306397088,
          },
          {
            leverage: 0.3125,
            order_type: "LONG",
            order_uuid: "f545d861-bc39-482b-ae13-156116e9886d",
            price: 65734.52,
            price_sources: [],
            processed_ms: 1711307332025,
          },
          {
            leverage: 0.15625,
            order_type: "LONG",
            order_uuid: "b4c09d11-2dae-4bca-aa8d-9ecad7ba5848",
            price: 65810.72,
            price_sources: [],
            processed_ms: 1711308270537,
          },
          {
            leverage: 0.125,
            order_type: "LONG",
            order_uuid: "2fb2030b-f9eb-495d-8334-c9afc5972a8a",
            price: 65919.74,
            price_sources: [],
            processed_ms: 1711309193865,
          },
          {
            leverage: 0.015625,
            order_type: "LONG",
            order_uuid: "6c6e0d13-0a15-4cd4-a4d8-588102355792",
            price: 65881.43,
            price_sources: [],
            processed_ms: 1711310128872,
          },
          {
            leverage: 0.0078125,
            order_type: "LONG",
            order_uuid: "0ccf9054-8aa9-493c-adce-06732bfa57c2",
            price: 65999.33,
            price_sources: [],
            processed_ms: 1711311084690,
          },
          {
            leverage: 0.00390625,
            order_type: "LONG",
            order_uuid: "2bdc9cf7-7bf6-411f-af93-20e669bb047a",
            price: 66305.22,
            price_sources: [],
            processed_ms: 1711311997002,
          },
          {
            leverage: 0.001953125,
            order_type: "LONG",
            order_uuid: "44980dba-b643-46b9-b315-c3dcb8185911",
            price: 66236.66,
            price_sources: [],
            processed_ms: 1711312936722,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "1ce4fc74-0a85-4a95-af45-3a1ab0f0d1c6",
            price: 66162.18,
            price_sources: [],
            processed_ms: 1711313869028,
          },
        ],
        position_type: "FLAT",
        position_uuid: "90d5e9bd-9dee-46b9-83a7-c8733c1c642a",
        return_at_close: 0.9950852903450987,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 60511.45000000001,
        close_ms: 1711322255732,
        current_return: 0.990174466030882,
        initial_entry_price: 66308.61,
        is_closed_position: true,
        miner_hotkey: "5HK1P1eWwFznqdjvpS2PKFrtdkvKbKMqcsss5KbyDAEeKEzf",
        net_leverage: 0.0,
        open_ms: 1711315730992,
        orders: [
          {
            leverage: -1.25,
            order_type: "SHORT",
            order_uuid: "fdd83442-5530-4452-ba6a-3712291753bc",
            price: 66308.61,
            price_sources: [],
            processed_ms: 1711315730991,
          },
          {
            leverage: 0.125,
            order_type: "LONG",
            order_uuid: "3f14e0a8-5102-4a0b-8cdc-9f1f1cfcfec6",
            price: 66332.71,
            price_sources: [],
            processed_ms: 1711316660003,
          },
          {
            leverage: 0.125,
            order_type: "LONG",
            order_uuid: "706ec82e-ace0-42cc-84ce-590264ccd779",
            price: 66552.29,
            price_sources: [],
            processed_ms: 1711317593505,
          },
          {
            leverage: 0.5,
            order_type: "LONG",
            order_uuid: "9c1f541b-f34a-45a3-b6fd-3c2c6b6f0338",
            price: 66835.03,
            price_sources: [],
            processed_ms: 1711318531077,
          },
          {
            leverage: 0.125,
            order_type: "LONG",
            order_uuid: "368aa272-eff8-458f-b469-0c414ac01f28",
            price: 66956.3,
            price_sources: [],
            processed_ms: 1711319462830,
          },
          {
            leverage: 0.1875,
            order_type: "LONG",
            order_uuid: "dbd41d10-da79-4618-a5a3-66f66085dfa7",
            price: 66853.89,
            price_sources: [],
            processed_ms: 1711320384934,
          },
          {
            leverage: 0.09375,
            order_type: "LONG",
            order_uuid: "9897b7c9-61f9-41a7-8623-247f1f2bf799",
            price: 66987.01,
            price_sources: [],
            processed_ms: 1711321319872,
          },
          {
            leverage: 0.125,
            order_type: "LONG",
            order_uuid: "c4938c1a-8d37-4529-9807-f387d37c2bc3",
            price: 67460.97,
            price_sources: [],
            processed_ms: 1711322255732,
          },
        ],
        position_type: "FLAT",
        position_uuid: "9761bc8d-11ed-4868-ba0d-fc6b999cb4a0",
        return_at_close: 0.9876990298658048,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 122883.16999998779,
        close_ms: 1711347309278,
        current_return: 1.001302301792402,
        initial_entry_price: 66767.37,
        is_closed_position: true,
        miner_hotkey: "5HK1P1eWwFznqdjvpS2PKFrtdkvKbKMqcsss5KbyDAEeKEzf",
        net_leverage: 0.0,
        open_ms: 1711326933271,
        orders: [
          {
            leverage: -1.25,
            order_type: "SHORT",
            order_uuid: "1738788d-3f97-438e-8f87-64976a540215",
            price: 66767.37,
            price_sources: [],
            processed_ms: 1711326933269,
          },
          {
            leverage: 0.125,
            order_type: "LONG",
            order_uuid: "0f3cd9f8-7f6e-42e0-a3fd-0f4442235b13",
            price: 66520.63,
            price_sources: [],
            processed_ms: 1711327849105,
          },
          {
            leverage: 0.125,
            order_type: "LONG",
            order_uuid: "e7fb4007-83b5-445d-82ac-bb0a6785cc1d",
            price: 66742.74,
            price_sources: [],
            processed_ms: 1711328779019,
          },
          {
            leverage: 0.5,
            order_type: "LONG",
            order_uuid: "e86bfc24-54d7-435f-a6de-7753b4235636",
            price: 66651.45,
            price_sources: [],
            processed_ms: 1711332657903,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "b981d0ee-9512-4400-995b-1050dedd1600",
            price: 66645.1,
            price_sources: [],
            processed_ms: 1711334472198,
          },
          {
            leverage: 0.2,
            order_type: "LONG",
            order_uuid: "cd795a57-5db3-460a-a574-840c57c25f4c",
            price: 66834.34,
            price_sources: [],
            processed_ms: 1711336305157,
          },
          {
            leverage: 0.024999999999999994,
            order_type: "LONG",
            order_uuid: "8ac0ac1f-3137-4e20-a2e2-a4d1db0bee15",
            price: 67066.73,
            price_sources: [],
            processed_ms: 1711338164779,
          },
          {
            leverage: 0.012499999999999997,
            order_type: "LONG",
            order_uuid: "a4b236b0-d35c-46f8-b28b-df33c8ea370f",
            price: 67317.88,
            price_sources: [],
            processed_ms: 1711339972132,
          },
          {
            leverage: 0.006249999999999999,
            order_type: "LONG",
            order_uuid: "c39b83e6-ab6f-43e3-8eb0-74f5e53056aa",
            price: 67350.82,
            price_sources: [],
            processed_ms: 1711341806089,
          },
          {
            leverage: 0.0031249999999999993,
            order_type: "LONG",
            order_uuid: "0896983f-8fa3-42e2-88b3-c67c27fbe328",
            price: 67496.68,
            price_sources: [],
            processed_ms: 1711343652890,
          },
          {
            leverage: 0.0015624999999999997,
            order_type: "LONG",
            order_uuid: "99da16cd-70a7-4603-9474-4f673beb6a14",
            price: 67460.35,
            price_sources: [],
            processed_ms: 1711345471934,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "371e7788-65ae-42c8-8c60-82a237afb84e",
            price: 67234.36,
            price_sources: [],
            processed_ms: 1711347309278,
          },
        ],
        position_type: "FLAT",
        position_uuid: "0afde747-65c7-4501-83b3-0ecd76f20c78",
        return_at_close: 0.9987990460379211,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 66994.75,
        close_ms: 1711361958407,
        current_return: 1.0039139260912235,
        initial_entry_price: 66994.75,
        is_closed_position: true,
        miner_hotkey: "5HK1P1eWwFznqdjvpS2PKFrtdkvKbKMqcsss5KbyDAEeKEzf",
        net_leverage: 0.0,
        open_ms: 1711356470101,
        orders: [
          {
            leverage: 1.25,
            order_type: "LONG",
            order_uuid: "650e63b8-7fc6-48d4-93ac-e6502a40d3e6",
            price: 66994.75,
            price_sources: [],
            processed_ms: 1711356470099,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "f06630c3-4285-4c2e-aba5-5a2e9ed7598c",
            price: 67204.52,
            price_sources: [],
            processed_ms: 1711361958407,
          },
        ],
        position_type: "FLAT",
        position_uuid: "f1ff2157-c3a4-415b-b328-f5c50308af90",
        return_at_close: 1.0014041412759955,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: -1688847.366666671,
        close_ms: 1711383150058,
        current_return: 1.0472976277624206,
        initial_entry_price: 66925.0,
        is_closed_position: true,
        miner_hotkey: "5HK1P1eWwFznqdjvpS2PKFrtdkvKbKMqcsss5KbyDAEeKEzf",
        net_leverage: 0.0,
        open_ms: 1711363799432,
        orders: [
          {
            leverage: 1.25,
            order_type: "LONG",
            order_uuid: "e0606fe3-ad63-4986-88dc-4fc527a2d11b",
            price: 66925.0,
            price_sources: [],
            processed_ms: 1711363799431,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "912f10ee-3d8c-4150-b976-c037b65d53db",
            price: 67172.98,
            price_sources: [],
            processed_ms: 1711370328944,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "02b435e3-e1c7-41c4-ad9a-64e36fd7c883",
            price: 67181.19,
            price_sources: [],
            processed_ms: 1711372161056,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "8fee64f1-e06b-4165-9655-7db4bffa0c79",
            price: 67222.56,
            price_sources: [],
            processed_ms: 1711373985235,
          },
          {
            leverage: -1.6,
            order_type: "SHORT",
            order_uuid: "82df95c1-3ef7-47be-a2a1-9149d861c6a8",
            price: 68434.58,
            price_sources: [],
            processed_ms: 1711375828996,
          },
          {
            leverage: -0.19999999999999996,
            order_type: "SHORT",
            order_uuid: "f1e112a3-b0f9-412b-a432-a00f178ac06d",
            price: 69368.89,
            price_sources: [],
            processed_ms: 1711376625169,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "d90e0d3c-6db5-490c-907b-2b1ba6a3c85e",
            price: 69272.14,
            price_sources: [],
            processed_ms: 1711376923624,
          },
          {
            leverage: -0.22499999999999998,
            order_type: "SHORT",
            order_uuid: "1ab22966-4cd2-4757-968f-3b538fd7baff",
            price: 69244.36,
            price_sources: [],
            processed_ms: 1711378092122,
          },
          {
            leverage: -0.18,
            order_type: "SHORT",
            order_uuid: "675c17ee-8d49-41ac-8588-1a87f550ce54",
            price: 69238.52,
            price_sources: [],
            processed_ms: 1711378353112,
          },
          {
            leverage: -0.03599999999999999,
            order_type: "SHORT",
            order_uuid: "5faef872-f4b2-4470-aa2e-4845e1c7314e",
            price: 69291.62,
            price_sources: [],
            processed_ms: 1711379739937,
          },
          {
            leverage: -0.0071999999999999955,
            order_type: "SHORT",
            order_uuid: "344f9cea-23e7-4399-8ecf-fb239109c7e7",
            price: 69704.7,
            price_sources: [],
            processed_ms: 1711381558905,
          },
          {
            leverage: -5.0018,
            order_type: "SHORT",
            order_uuid: "b2fae9c9-a1e3-494d-b644-0932f1a7e753",
            price: 69704.71,
            price_sources: [],
            processed_ms: 1711383150058,
          },
        ],
        position_type: "FLAT",
        position_uuid: "183b52d2-76a8-48eb-9cc2-593da4c87912",
        return_at_close: 1.043108437251371,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 70647.12588235294,
        close_ms: 1711396688650,
        current_return: 1.0521226602382308,
        initial_entry_price: 70520.0,
        is_closed_position: true,
        miner_hotkey: "5HK1P1eWwFznqdjvpS2PKFrtdkvKbKMqcsss5KbyDAEeKEzf",
        net_leverage: 0.0,
        open_ms: 1711386781192,
        orders: [
          {
            leverage: 5.0,
            order_type: "LONG",
            order_uuid: "0cf7d852-fdc8-4480-a237-69b97cad4afc",
            price: 70520.0,
            price_sources: [],
            processed_ms: 1711386781190,
          },
          {
            leverage: -2.5,
            order_type: "SHORT",
            order_uuid: "445032b6-b668-481d-b217-cef60783eefc",
            price: 70527.68,
            price_sources: [],
            processed_ms: 1711388597617,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "d6662c74-e892-4b0a-9fa3-33b407086503",
            price: 70667.02,
            price_sources: [],
            processed_ms: 1711390440601,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "e8a18297-b2ce-462e-a3c2-196edf469d18",
            price: 70706.26,
            price_sources: [],
            processed_ms: 1711392231183,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "0196c8b4-9192-4510-b833-f3e39d36c95e",
            price: 70673.59,
            price_sources: [],
            processed_ms: 1711394048671,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "da7a12dc-6034-49b1-82f0-160ace48bb1b",
            price: 70766.31,
            price_sources: [],
            processed_ms: 1711395865241,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "889086fd-ef75-4b04-9214-9544d647ba7c",
            price: 71079.56,
            price_sources: [],
            processed_ms: 1711396688650,
          },
        ],
        position_type: "FLAT",
        position_uuid: "87177cda-4cbc-496c-a271-31292cceb470",
        return_at_close: 1.0342365750141809,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 70778.85062499999,
        close_ms: 1711401566958,
        current_return: 0.9089016305372235,
        initial_entry_price: 70826.35,
        is_closed_position: true,
        miner_hotkey: "5HK1P1eWwFznqdjvpS2PKFrtdkvKbKMqcsss5KbyDAEeKEzf",
        net_leverage: 0.0,
        open_ms: 1711397729060,
        orders: [
          {
            leverage: 5.0,
            order_type: "LONG",
            order_uuid: "75af958e-3df5-496c-8961-8537982f5dde",
            price: 70826.35,
            price_sources: [],
            processed_ms: 1711397729058,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "f1d305d7-366e-494e-ad40-b47b8f92de51",
            price: 70880.66,
            price_sources: [],
            processed_ms: 1711399502429,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "ff188426-d938-4e5a-8c32-3adadd2a5acf",
            price: 70518.71,
            price_sources: [],
            processed_ms: 1711401317225,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "294bee90-4c27-4ac2-a783-c606c1f67240",
            price: 69972.33,
            price_sources: [],
            processed_ms: 1711401566958,
          },
        ],
        position_type: "FLAT",
        position_uuid: "6be26f63-0820-4fd8-a9dd-cf8766c055c6",
        return_at_close: 0.8943592044486279,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 70301.93684210525,
        close_ms: 1711409994153,
        current_return: 0.9562052974523905,
        initial_entry_price: 70138.62,
        is_closed_position: true,
        miner_hotkey: "5HK1P1eWwFznqdjvpS2PKFrtdkvKbKMqcsss5KbyDAEeKEzf",
        net_leverage: 0.0,
        open_ms: 1711403149230,
        orders: [
          {
            leverage: 5.0,
            order_type: "LONG",
            order_uuid: "30f6ae0f-0732-481f-9fdc-cb63ae864017",
            price: 70138.62,
            price_sources: [],
            processed_ms: 1711403149227,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "3f3920c1-66ce-43c4-bce2-dd70e79cb295",
            price: 70497.69,
            price_sources: [],
            processed_ms: 1711404991348,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "abfc115f-3c37-47f0-8c48-ef4841756f9f",
            price: 70504.39,
            price_sources: [],
            processed_ms: 1711406774067,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "c39028cf-472e-4194-984f-575e70e57583",
            price: 70448.12,
            price_sources: [],
            processed_ms: 1711408593211,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "44f47f70-6f6e-416d-98ec-1c2efe9fa5fd",
            price: 69978.6,
            price_sources: [],
            processed_ms: 1711409994153,
          },
        ],
        position_type: "FLAT",
        position_uuid: "e0e226f8-5bd3-4bd7-8d04-584d464daccc",
        return_at_close: 0.938037396800795,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
    ],
    thirty_day_returns: 1.0,
  },
  "5HNQURvmjjYhTSksi8Wfsw676b4owGwfLR2BFAQzG7H3HhYf": {
    all_time_returns: 1.0,
    n_positions: 0,
    percentage_profitable: 0.0,
    positions: [
      {
        average_entry_price: 2912.090397350993,
        close_ms: 1715797896026,
        current_return: 0.9500053523639604,
        initial_entry_price: 2914.6,
        is_closed_position: true,
        miner_hotkey: "5HNQURvmjjYhTSksi8Wfsw676b4owGwfLR2BFAQzG7H3HhYf",
        net_leverage: -1.51,
        open_ms: 1715673004026,
        orders: [
          {
            leverage: -0.5,
            order_type: "SHORT",
            order_uuid: "51751a9b-f1b6-4541-aa2f-fbfbaa0d5e8c",
            price: 2914.6,
            price_sources: [],
            processed_ms: 1715673003827,
          },
          {
            leverage: -0.5,
            order_type: "SHORT",
            order_uuid: "ecac3914-2ffc-49ac-89ab-4b5b8fb62b56",
            price: 2904.2,
            price_sources: [],
            processed_ms: 1715683181900,
          },
          {
            leverage: -0.5,
            order_type: "SHORT",
            order_uuid: "0a514f7e-4cd8-427d-8944-55848d59d4bc",
            price: 2917.38,
            price_sources: [],
            processed_ms: 1715688853373,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "205aeb8a-a75c-4ffb-a611-97600fa1d0e3",
            price: 2891.42,
            price_sources: [],
            processed_ms: 1715690112401,
          },
          {
            leverage: -0.01,
            order_type: "SHORT",
            order_uuid: "b703b5bb-0911-42b0-ac4e-3a4254651249",
            price: 2913.2,
            price_sources: [],
            processed_ms: 1715768250618,
          },
          {
            leverage: -0.01,
            order_type: "SHORT",
            order_uuid: "9923e4bd-9cac-4368-be7f-d18450cfb3c1",
            price: 2894.87,
            price_sources: [],
            processed_ms: 1715772896916,
          },
        ],
        position_type: "FLAT",
        position_uuid: "287841f4-48cb-4da8-bca8-5397d84643ff",
        return_at_close: 0.949278598269402,
        trade_pair: ["ETHUSD", "ETH/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 61907.15,
        close_ms: 1715797896041,
        current_return: 1.0005682461557348,
        initial_entry_price: 61907.15,
        is_closed_position: true,
        miner_hotkey: "5HNQURvmjjYhTSksi8Wfsw676b4owGwfLR2BFAQzG7H3HhYf",
        net_leverage: 0.01,
        open_ms: 1715694174655,
        orders: [
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "6de0d63e-167d-4f02-a725-dd77f469652b",
            price: 61907.15,
            price_sources: [],
            processed_ms: 1715694174169,
          },
        ],
        position_type: "FLAT",
        position_uuid: "8fc73583-1794-4a09-abb8-cd4f6823adfa",
        return_at_close: 1.000563243314504,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
    ],
    thirty_day_returns: 1.0,
  },
  "5HQPp23LTQFq27GefUaR2CFN31UAfAu6zXSiMJeRDaVzxvoG": {
    all_time_returns: 1.0,
    n_positions: 0,
    percentage_profitable: 0.0,
    positions: [
      {
        average_entry_price: 65137.80999999999,
        close_ms: 1711214572722,
        current_return: 0.9980797328003504,
        initial_entry_price: 65137.81,
        is_closed_position: true,
        miner_hotkey: "5HQPp23LTQFq27GefUaR2CFN31UAfAu6zXSiMJeRDaVzxvoG",
        net_leverage: 0.0,
        open_ms: 1711210999737,
        orders: [
          {
            leverage: -0.3,
            order_type: "SHORT",
            order_uuid: "238fc4b6-b5c7-4d9e-83ca-bab811c8c846",
            price: 65137.81,
            price_sources: [],
            processed_ms: 1711210999736,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "0b9e9db6-13e3-4f2c-9ef6-c99f824b6ac8",
            price: 65554.75,
            price_sources: [],
            processed_ms: 1711214572722,
          },
        ],
        position_type: "FLAT",
        position_uuid: "6f356fa0-be97-4fd3-ad92-71f5ad4be311",
        return_at_close: 0.9974808849606702,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 65522.26999999999,
        close_ms: 1711221889724,
        current_return: 1.0009739589302995,
        initial_entry_price: 65522.27,
        is_closed_position: true,
        miner_hotkey: "5HQPp23LTQFq27GefUaR2CFN31UAfAu6zXSiMJeRDaVzxvoG",
        net_leverage: 0.0,
        open_ms: 1711218313629,
        orders: [
          {
            leverage: -0.3,
            order_type: "SHORT",
            order_uuid: "79e3ee3f-ec74-4013-b552-0ae13aaeac4d",
            price: 65522.27,
            price_sources: [],
            processed_ms: 1711218313628,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "4faadc40-9916-46d1-8c64-a834a89cde91",
            price: 65309.55,
            price_sources: [],
            processed_ms: 1711221889724,
          },
        ],
        position_type: "FLAT",
        position_uuid: "0a1bf23d-a3af-4a2a-8427-d696b33182ae",
        return_at_close: 1.0003733745549412,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 65159.59999999999,
        close_ms: 1711225551683,
        current_return: 1.0012184697266404,
        initial_entry_price: 65159.6,
        is_closed_position: true,
        miner_hotkey: "5HQPp23LTQFq27GefUaR2CFN31UAfAu6zXSiMJeRDaVzxvoG",
        net_leverage: 0.0,
        open_ms: 1711221976587,
        orders: [
          {
            leverage: -0.3,
            order_type: "SHORT",
            order_uuid: "e92a6285-05c8-49d4-94aa-582d10eb4291",
            price: 65159.6,
            price_sources: [],
            processed_ms: 1711221976585,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "c68935a7-18a7-4f33-9469-2dee02e4e2fc",
            price: 64894.95,
            price_sources: [],
            processed_ms: 1711225551683,
          },
        ],
        position_type: "FLAT",
        position_uuid: "772f59ab-f467-442e-9e28-947b54c4dece",
        return_at_close: 1.0006177386448043,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 64911.64,
        close_ms: 1711229211876,
        current_return: 1.0000027730003431,
        initial_entry_price: 64911.64,
        is_closed_position: true,
        miner_hotkey: "5HQPp23LTQFq27GefUaR2CFN31UAfAu6zXSiMJeRDaVzxvoG",
        net_leverage: 0.0,
        open_ms: 1711225638591,
        orders: [
          {
            leverage: -0.3,
            order_type: "SHORT",
            order_uuid: "54431353-24ef-4c08-ba30-c11a3fa6178f",
            price: 64911.64,
            price_sources: [],
            processed_ms: 1711225638590,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "49ec9004-a7be-4022-8933-5987d0da97bd",
            price: 64911.04,
            price_sources: [],
            processed_ms: 1711229211876,
          },
        ],
        position_type: "FLAT",
        position_uuid: "9083c27c-ec65-4231-b469-72066c7404ef",
        return_at_close: 0.9994027713365429,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 64901.42,
        close_ms: 1711232871687,
        current_return: 1.0000736809764716,
        initial_entry_price: 64901.42,
        is_closed_position: true,
        miner_hotkey: "5HQPp23LTQFq27GefUaR2CFN31UAfAu6zXSiMJeRDaVzxvoG",
        net_leverage: 0.0,
        open_ms: 1711229298680,
        orders: [
          {
            leverage: -0.3,
            order_type: "SHORT",
            order_uuid: "8912215f-a012-490f-9ec5-46a8de50a871",
            price: 64901.42,
            price_sources: [],
            processed_ms: 1711229298679,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "cdd7e966-71c4-44b9-9d8b-623148954b52",
            price: 64885.48,
            price_sources: [],
            processed_ms: 1711232871687,
          },
        ],
        position_type: "FLAT",
        position_uuid: "c11c71c1-d217-4fb5-afcb-6b344fb8d7b1",
        return_at_close: 0.9994736367678857,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 64400.80000000001,
        close_ms: 1711243882936,
        current_return: 1.001001726686625,
        initial_entry_price: 64400.8,
        is_closed_position: true,
        miner_hotkey: "5HQPp23LTQFq27GefUaR2CFN31UAfAu6zXSiMJeRDaVzxvoG",
        net_leverage: 0.0,
        open_ms: 1711240306528,
        orders: [
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "bf147e77-fe20-49fe-83c9-f8081d561541",
            price: 64400.8,
            price_sources: [],
            processed_ms: 1711240306527,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "be4c7de3-1d06-45eb-873c-7b72efe80058",
            price: 64615.84,
            price_sources: [],
            processed_ms: 1711243882936,
          },
        ],
        position_type: "FLAT",
        position_uuid: "454d6b56-3aa2-45ff-852f-a8adb1deeb6f",
        return_at_close: 1.000401125650613,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 64107.86,
        close_ms: 1711254846763,
        current_return: 0.999412240558334,
        initial_entry_price: 64107.86,
        is_closed_position: true,
        miner_hotkey: "5HQPp23LTQFq27GefUaR2CFN31UAfAu6zXSiMJeRDaVzxvoG",
        net_leverage: 0.0,
        open_ms: 1711251319863,
        orders: [
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "af21f54a-9de7-4b7b-9abc-07fa52d12827",
            price: 64107.86,
            price_sources: [],
            processed_ms: 1711251319862,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "6fc82392-8cdb-45d0-91e8-c787541f140d",
            price: 63982.26,
            price_sources: [],
            processed_ms: 1711254846763,
          },
        ],
        position_type: "FLAT",
        position_uuid: "d7193f9d-f6dc-4efb-be7e-d00e166fe1ea",
        return_at_close: 0.998812593213999,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 63970.45,
        close_ms: 1711256964808,
        current_return: 1.0007947106828232,
        initial_entry_price: 63970.45,
        is_closed_position: true,
        miner_hotkey: "5HQPp23LTQFq27GefUaR2CFN31UAfAu6zXSiMJeRDaVzxvoG",
        net_leverage: 0.0,
        open_ms: 1711254933694,
        orders: [
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "866807ec-5e2c-4ef6-9c94-353117f63b32",
            price: 63970.45,
            price_sources: [],
            processed_ms: 1711254933693,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "a2b9fd82-7d66-41e5-b26a-26595351299c",
            price: 64139.91,
            price_sources: [],
            processed_ms: 1711256964808,
          },
        ],
        position_type: "FLAT",
        position_uuid: "8c896508-651c-4025-b986-bf1df07ee247",
        return_at_close: 1.0001942338564134,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 64188.16000000001,
        close_ms: 1711260632297,
        current_return: 1.0015787568922367,
        initial_entry_price: 64188.16,
        is_closed_position: true,
        miner_hotkey: "5HQPp23LTQFq27GefUaR2CFN31UAfAu6zXSiMJeRDaVzxvoG",
        net_leverage: 0.0,
        open_ms: 1711257068869,
        orders: [
          {
            leverage: 1.25,
            order_type: "LONG",
            order_uuid: "e10b389a-d2a4-408f-8907-5044279220c2",
            price: 64188.16,
            price_sources: [],
            processed_ms: 1711257068868,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "f5adf6de-c777-4fd9-a764-f0f4927c112a",
            price: 64269.23,
            price_sources: [],
            processed_ms: 1711260632297,
          },
        ],
        position_type: "FLAT",
        position_uuid: "bcc1896a-3838-4e1a-a7d4-13fa764b8643",
        return_at_close: 0.9990748100000062,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 64353.56,
        close_ms: 1711264297141,
        current_return: 0.9957529544597068,
        initial_entry_price: 64353.56,
        is_closed_position: true,
        miner_hotkey: "5HQPp23LTQFq27GefUaR2CFN31UAfAu6zXSiMJeRDaVzxvoG",
        net_leverage: 0.0,
        open_ms: 1711260735544,
        orders: [
          {
            leverage: 1.25,
            order_type: "LONG",
            order_uuid: "39a4c566-53d0-4e5e-9e6c-f69fbf2e3c02",
            price: 64353.56,
            price_sources: [],
            processed_ms: 1711260735542,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "cfb916f8-d538-4dcf-8542-b33b9deebca1",
            price: 64134.91,
            price_sources: [],
            processed_ms: 1711264297141,
          },
        ],
        position_type: "FLAT",
        position_uuid: "1238de76-3674-4cc2-a416-91970e674310",
        return_at_close: 0.9932635720735575,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 64167.79,
        close_ms: 1711267980210,
        current_return: 1.0148008447852108,
        initial_entry_price: 64167.79,
        is_closed_position: true,
        miner_hotkey: "5HQPp23LTQFq27GefUaR2CFN31UAfAu6zXSiMJeRDaVzxvoG",
        net_leverage: 0.0,
        open_ms: 1711264399592,
        orders: [
          {
            leverage: 1.25,
            order_type: "LONG",
            order_uuid: "5739d946-4046-46d8-8bbf-08c07b2d1a65",
            price: 64167.79,
            price_sources: [],
            processed_ms: 1711264399591,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "da8ef6f5-7e61-4b36-9902-04d7dee8b235",
            price: 64927.58,
            price_sources: [],
            processed_ms: 1711267980210,
          },
        ],
        position_type: "FLAT",
        position_uuid: "50970011-0f60-4e3a-88c5-554d950c270a",
        return_at_close: 1.0122638426732478,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 64950.475,
        close_ms: 1711271676134,
        current_return: 0.9996830679057512,
        initial_entry_price: 64958.71,
        is_closed_position: true,
        miner_hotkey: "5HQPp23LTQFq27GefUaR2CFN31UAfAu6zXSiMJeRDaVzxvoG",
        net_leverage: 0.0,
        open_ms: 1711268082493,
        orders: [
          {
            leverage: 1.25,
            order_type: "LONG",
            order_uuid: "d3664ef6-0576-4349-b92d-ab63e49d5a6a",
            price: 64958.71,
            price_sources: [],
            processed_ms: 1711268082492,
          },
          {
            leverage: 1.25,
            order_type: "LONG",
            order_uuid: "7e3c767b-370c-42e2-9f0a-d11701a09bca",
            price: 64942.24,
            price_sources: [],
            processed_ms: 1711271656762,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "c5ab4f4a-43fb-4058-84c3-1264740d2e60",
            price: 64942.24,
            price_sources: [],
            processed_ms: 1711271676134,
          },
        ],
        position_type: "FLAT",
        position_uuid: "e517e220-dfdc-44bc-9f44-f2342870cb29",
        return_at_close: 0.9946846525662225,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 64921.35,
        close_ms: 1711275339073,
        current_return: 1.0,
        initial_entry_price: 64921.35,
        is_closed_position: true,
        miner_hotkey: "5HQPp23LTQFq27GefUaR2CFN31UAfAu6zXSiMJeRDaVzxvoG",
        net_leverage: 0.0,
        open_ms: 1711275319761,
        orders: [
          {
            leverage: 1.25,
            order_type: "LONG",
            order_uuid: "9716e2d0-2956-44d1-8f13-e90944c9d61d",
            price: 64921.35,
            price_sources: [],
            processed_ms: 1711275319760,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "ffb970cf-8b54-4001-beae-0b64f13dd393",
            price: 64921.35,
            price_sources: [],
            processed_ms: 1711275339073,
          },
        ],
        position_type: "FLAT",
        position_uuid: "166f9b4d-d385-4a0b-9080-8af51aa9bd9c",
        return_at_close: 0.9975,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 65140.375,
        close_ms: 1711280560753,
        current_return: 1.0000869223101057,
        initial_entry_price: 65144.38,
        is_closed_position: true,
        miner_hotkey: "5HQPp23LTQFq27GefUaR2CFN31UAfAu6zXSiMJeRDaVzxvoG",
        net_leverage: 0.0,
        open_ms: 1711279076355,
        orders: [
          {
            leverage: 1.25,
            order_type: "LONG",
            order_uuid: "385a5b84-63e8-46e8-b453-bb7962cd2498",
            price: 65144.38,
            price_sources: [],
            processed_ms: 1711279076353,
          },
          {
            leverage: 1.25,
            order_type: "LONG",
            order_uuid: "a622f7c9-9006-48bb-800b-5208f392588a",
            price: 65136.37,
            price_sources: [],
            processed_ms: 1711279228046,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "abd4d577-8150-4e2c-b4eb-0f01030b7f63",
            price: 65142.64,
            price_sources: [],
            processed_ms: 1711280560753,
          },
        ],
        position_type: "FLAT",
        position_uuid: "48fb732d-d8d5-4272-aff9-7e8ab1402f83",
        return_at_close: 0.9950864876985551,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 65297.83999999999,
        close_ms: 1711286073186,
        current_return: 1.0056079573229375,
        initial_entry_price: 65297.84,
        is_closed_position: true,
        miner_hotkey: "5HQPp23LTQFq27GefUaR2CFN31UAfAu6zXSiMJeRDaVzxvoG",
        net_leverage: 0.0,
        open_ms: 1711282399650,
        orders: [
          {
            leverage: 1.25,
            order_type: "LONG",
            order_uuid: "7b214360-5d59-43ec-bef5-1579614e3c9f",
            price: 65297.84,
            price_sources: [],
            processed_ms: 1711282399649,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "c660495c-3e13-4781-bf64-49cbd6453b17",
            price: 65590.79,
            price_sources: [],
            processed_ms: 1711286073186,
          },
        ],
        position_type: "FLAT",
        position_uuid: "64a4afdd-de18-4f26-a4bc-3191644b6300",
        return_at_close: 1.0030939374296302,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 65304.88000000003,
        close_ms: 1711308482078,
        current_return: 0.9980012710471943,
        initial_entry_price: 65607.32,
        is_closed_position: true,
        miner_hotkey: "5HQPp23LTQFq27GefUaR2CFN31UAfAu6zXSiMJeRDaVzxvoG",
        net_leverage: 0.0,
        open_ms: 1711305671769,
        orders: [
          {
            leverage: -1.25,
            order_type: "SHORT",
            order_uuid: "330808be-4648-4a8e-83ca-52966914c6b4",
            price: 65607.32,
            price_sources: [],
            processed_ms: 1711305671768,
          },
          {
            leverage: 0.625,
            order_type: "LONG",
            order_uuid: "d47baec4-ae73-455f-8b32-d7bca99c1267",
            price: 65668.75,
            price_sources: [],
            processed_ms: 1711306603655,
          },
          {
            leverage: 0.3125,
            order_type: "LONG",
            order_uuid: "d255cff0-91f3-4bc5-ae5c-f4681da23302",
            price: 65786.9,
            price_sources: [],
            processed_ms: 1711307540723,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "157f8194-36c9-400f-99d3-7170c5164779",
            price: 65724.5,
            price_sources: [],
            processed_ms: 1711308482078,
          },
        ],
        position_type: "FLAT",
        position_uuid: "0d268562-5994-4a6b-adcb-a6e404e7fdf9",
        return_at_close: 0.9955062678695763,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 66221.84,
        close_ms: 1711312209116,
        current_return: 0.9972529833058097,
        initial_entry_price: 66221.84,
        is_closed_position: true,
        miner_hotkey: "5HQPp23LTQFq27GefUaR2CFN31UAfAu6zXSiMJeRDaVzxvoG",
        net_leverage: 0.0,
        open_ms: 1711311254630,
        orders: [
          {
            leverage: -1.25,
            order_type: "SHORT",
            order_uuid: "d793c263-f989-4d54-8096-e2764f094859",
            price: 66221.84,
            price_sources: [],
            processed_ms: 1711311254628,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "f7ecf128-8b15-409b-a38b-73d57d59316a",
            price: 66367.37,
            price_sources: [],
            processed_ms: 1711312209116,
          },
        ],
        position_type: "FLAT",
        position_uuid: "49867282-aed5-4d3a-9600-cfebb97731d4",
        return_at_close: 0.9947598508475453,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 63249.1557142858,
        close_ms: 1711322439637,
        current_return: 0.9928938953035689,
        initial_entry_price: 66463.87,
        is_closed_position: true,
        miner_hotkey: "5HQPp23LTQFq27GefUaR2CFN31UAfAu6zXSiMJeRDaVzxvoG",
        net_leverage: 0.0,
        open_ms: 1711315933953,
        orders: [
          {
            leverage: -1.25,
            order_type: "SHORT",
            order_uuid: "d3c3fac7-9a2c-4767-9be1-d412c2f3cb6e",
            price: 66463.87,
            price_sources: [],
            processed_ms: 1711315933951,
          },
          {
            leverage: 0.125,
            order_type: "LONG",
            order_uuid: "c3760405-037b-44bf-9dd3-c13fd80db08c",
            price: 66263.13,
            price_sources: [],
            processed_ms: 1711316855063,
          },
          {
            leverage: 0.125,
            order_type: "LONG",
            order_uuid: "ad323877-dd34-43fe-a485-5bc49c5e8c23",
            price: 66768.73,
            price_sources: [],
            processed_ms: 1711317788230,
          },
          {
            leverage: 0.125,
            order_type: "LONG",
            order_uuid: "c7f92e59-3a94-476b-b4d0-89a3b1830949",
            price: 66781.18,
            price_sources: [],
            processed_ms: 1711318714715,
          },
          {
            leverage: 0.4375,
            order_type: "LONG",
            order_uuid: "e36a7560-44d2-4761-a3a7-e47777bd646d",
            price: 66761.25,
            price_sources: [],
            processed_ms: 1711319657680,
          },
          {
            leverage: 0.21875,
            order_type: "LONG",
            order_uuid: "a62b1c99-a521-41fe-91ea-4a756e33b0b4",
            price: 66885.37,
            price_sources: [],
            processed_ms: 1711320585802,
          },
          {
            leverage: 0.109375,
            order_type: "LONG",
            order_uuid: "7077f8d6-a8fe-4391-ad72-321977609a8a",
            price: 67164.43,
            price_sources: [],
            processed_ms: 1711321510786,
          },
          {
            leverage: 0.125,
            order_type: "LONG",
            order_uuid: "5ed23c07-8902-44dd-8c13-2fe11f59ea92",
            price: 67567.32,
            price_sources: [],
            processed_ms: 1711322439637,
          },
        ],
        position_type: "FLAT",
        position_uuid: "cdbef0ea-df48-49ab-91bd-e26e5d2ea565",
        return_at_close: 0.99041166056531,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 249804.30428572092,
        close_ms: 1711346949620,
        current_return: 1.0037323976408017,
        initial_entry_price: 66875.89,
        is_closed_position: true,
        miner_hotkey: "5HQPp23LTQFq27GefUaR2CFN31UAfAu6zXSiMJeRDaVzxvoG",
        net_leverage: 0.0,
        open_ms: 1711326175765,
        orders: [
          {
            leverage: -1.25,
            order_type: "SHORT",
            order_uuid: "d4ed0c7c-4f3b-42a8-9a91-31f7aa970c8a",
            price: 66875.89,
            price_sources: [],
            processed_ms: 1711326175763,
          },
          {
            leverage: 0.125,
            order_type: "LONG",
            order_uuid: "a988e28a-4634-40fb-b9cc-4e0e098f6e07",
            price: 66601.0,
            price_sources: [],
            processed_ms: 1711327118546,
          },
          {
            leverage: 0.125,
            order_type: "LONG",
            order_uuid: "7c43852a-0945-42a5-b59b-5ce73c68fae6",
            price: 66567.78,
            price_sources: [],
            processed_ms: 1711328038847,
          },
          {
            leverage: 0.125,
            order_type: "LONG",
            order_uuid: "e94ac9d1-fc8a-4e1e-89ab-8d5d438a31e8",
            price: 66713.1,
            price_sources: [],
            processed_ms: 1711328974580,
          },
          {
            leverage: 0.4375,
            order_type: "LONG",
            order_uuid: "db24ca62-fb3b-4a25-b947-f45203f43c2e",
            price: 66607.01,
            price_sources: [],
            processed_ms: 1711332281803,
          },
          {
            leverage: 0.21875,
            order_type: "LONG",
            order_uuid: "ad685a6c-3e7e-4601-8d0b-23ad82cec4c9",
            price: 66733.17,
            price_sources: [],
            processed_ms: 1711334129704,
          },
          {
            leverage: 0.109375,
            order_type: "LONG",
            order_uuid: "b4e2ab08-b99b-458a-9740-0af4553c918d",
            price: 66619.0,
            price_sources: [],
            processed_ms: 1711335950705,
          },
          {
            leverage: 0.0546875,
            order_type: "LONG",
            order_uuid: "ffcb8efd-8536-45fe-9333-c1b9050da4a2",
            price: 66824.57,
            price_sources: [],
            processed_ms: 1711337791850,
          },
          {
            leverage: 0.02734375,
            order_type: "LONG",
            order_uuid: "faf9ddb1-cd05-43f6-9403-cc3e0b24d5dd",
            price: 67204.12,
            price_sources: [],
            processed_ms: 1711339612321,
          },
          {
            leverage: 0.013671875,
            order_type: "LONG",
            order_uuid: "35018ecd-4c44-4b4f-b240-a34b812ccbf6",
            price: 67324.49,
            price_sources: [],
            processed_ms: 1711341476179,
          },
          {
            leverage: 0.0109375,
            order_type: "LONG",
            order_uuid: "ba9cda72-9c84-41f9-af97-88b920e3574b",
            price: 67500.46,
            price_sources: [],
            processed_ms: 1711343275816,
          },
          {
            leverage: 0.0013671875000000003,
            order_type: "LONG",
            order_uuid: "bfc9bfab-2c61-4c96-aec8-14c2a44821c8",
            price: 67567.63,
            price_sources: [],
            processed_ms: 1711345105031,
          },
          {
            leverage: 0.125,
            order_type: "LONG",
            order_uuid: "a902684a-4116-4230-b328-0746559fe6b6",
            price: 67234.31,
            price_sources: [],
            processed_ms: 1711346949620,
          },
        ],
        position_type: "FLAT",
        position_uuid: "d425c7de-8783-4432-83d9-581764b7ac2d",
        return_at_close: 1.0012230666466997,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 66988.59,
        close_ms: 1711350597296,
        current_return: 0.9951064427539078,
        initial_entry_price: 66988.59,
        is_closed_position: true,
        miner_hotkey: "5HQPp23LTQFq27GefUaR2CFN31UAfAu6zXSiMJeRDaVzxvoG",
        net_leverage: 0.0,
        open_ms: 1711348776928,
        orders: [
          {
            leverage: -1.25,
            order_type: "SHORT",
            order_uuid: "6d4c43a4-3df5-44a1-89b0-2849f9b08113",
            price: 66988.59,
            price_sources: [],
            processed_ms: 1711348776926,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "874c1fd8-6aa3-4353-b2f7-699e34a0880c",
            price: 67250.84,
            price_sources: [],
            processed_ms: 1711350597296,
          },
        ],
        position_type: "FLAT",
        position_uuid: "36ccdb4b-f30c-4f2c-adaa-86cbc618c7b6",
        return_at_close: 0.9926186766470231,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 66948.65,
        close_ms: 1711361596427,
        current_return: 1.0047773554806558,
        initial_entry_price: 66948.65,
        is_closed_position: true,
        miner_hotkey: "5HQPp23LTQFq27GefUaR2CFN31UAfAu6zXSiMJeRDaVzxvoG",
        net_leverage: 0.0,
        open_ms: 1711359764903,
        orders: [
          {
            leverage: 1.25,
            order_type: "LONG",
            order_uuid: "9c323ac8-4174-4d50-8113-d53c02a55a37",
            price: 66948.65,
            price_sources: [],
            processed_ms: 1711359764901,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "bb0b745b-e051-4c24-9ece-2ce28b0061c2",
            price: 67204.52,
            price_sources: [],
            processed_ms: 1711361596427,
          },
        ],
        position_type: "FLAT",
        position_uuid: "066ea8d9-ce8d-4370-b3fa-665ad0171ae3",
        return_at_close: 1.0022654120919543,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 67030.6609090909,
        close_ms: 1711376022451,
        current_return: 1.076376965933167,
        initial_entry_price: 66905.25,
        is_closed_position: true,
        miner_hotkey: "5HQPp23LTQFq27GefUaR2CFN31UAfAu6zXSiMJeRDaVzxvoG",
        net_leverage: 0.0,
        open_ms: 1711365258974,
        orders: [
          {
            leverage: 1.25,
            order_type: "LONG",
            order_uuid: "6e9c0242-8d1a-417c-926f-2451a8d7d101",
            price: 66905.25,
            price_sources: [],
            processed_ms: 1711365258972,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "fc9361b7-0461-4670-b3de-a47e63fb21cc",
            price: 66884.72,
            price_sources: [],
            processed_ms: 1711366424891,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "6186d889-30d2-4ae0-a8a2-df4cbf341635",
            price: 66820.0,
            price_sources: [],
            processed_ms: 1711367872606,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "0218fa0f-3a22-4c22-ba2b-610ad925d84e",
            price: 67111.65,
            price_sources: [],
            processed_ms: 1711369692931,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "f902caeb-8a8a-418b-8c54-c500bf2907a5",
            price: 67033.56,
            price_sources: [],
            processed_ms: 1711371558879,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "918b4e84-fca1-4569-be18-75fb90a68400",
            price: 66931.79,
            price_sources: [],
            processed_ms: 1711373354896,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "58d1df3b-e84e-48a3-96e0-3f206cb43772",
            price: 68029.3,
            price_sources: [],
            processed_ms: 1711375221221,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "ab800846-530d-4b04-9b9b-a45e8285dab1",
            price: 68888.85,
            price_sources: [],
            processed_ms: 1711376022451,
          },
        ],
        position_type: "FLAT",
        position_uuid: "1ff62de2-9329-4dad-a264-7dd32d7d4c69",
        return_at_close: 1.0704568926205347,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 70627.08000000007,
        close_ms: 1711378282815,
        current_return: 1.0025494137213384,
        initial_entry_price: 69368.89,
        is_closed_position: true,
        miner_hotkey: "5HQPp23LTQFq27GefUaR2CFN31UAfAu6zXSiMJeRDaVzxvoG",
        net_leverage: 0.0,
        open_ms: 1711376622771,
        orders: [
          {
            leverage: -1.25,
            order_type: "SHORT",
            order_uuid: "8e4b4dd7-7600-4b1e-bde9-3960b04f4908",
            price: 69368.89,
            price_sources: [],
            processed_ms: 1711376622770,
          },
          {
            leverage: 0.625,
            order_type: "LONG",
            order_uuid: "a9c688aa-7da5-482a-92e3-36d1904c114f",
            price: 69296.54,
            price_sources: [],
            processed_ms: 1711376851590,
          },
          {
            leverage: 0.5,
            order_type: "LONG",
            order_uuid: "451ed1b4-99c4-4208-96d7-59fae1007e4e",
            price: 69144.78,
            price_sources: [],
            processed_ms: 1711378035788,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "5bf68eab-f88b-42be-be44-a9c2f25bcad0",
            price: 69212.28,
            price_sources: [],
            processed_ms: 1711378282815,
          },
        ],
        position_type: "FLAT",
        position_uuid: "7888ff2a-e4e5-4826-a862-f7faa152a355",
        return_at_close: 1.000043040187035,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 67658.2100000001,
        close_ms: 1711391905893,
        current_return: 0.9947942196191627,
        initial_entry_price: 69670.15,
        is_closed_position: true,
        miner_hotkey: "5HQPp23LTQFq27GefUaR2CFN31UAfAu6zXSiMJeRDaVzxvoG",
        net_leverage: 0.0,
        open_ms: 1711379065849,
        orders: [
          {
            leverage: -1.25,
            order_type: "SHORT",
            order_uuid: "bdedab83-f69a-46ad-99a5-4b3fcd8b98aa",
            price: 69670.15,
            price_sources: [],
            processed_ms: 1711379065847,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "b7dbb91f-c61d-47c5-9643-5271e8bead21",
            price: 69478.87,
            price_sources: [],
            processed_ms: 1711380885948,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "62e876f0-af00-41b8-b540-0d158f2ada94",
            price: 69772.53,
            price_sources: [],
            processed_ms: 1711382704196,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "d84d1df6-1a1d-4c74-8aa0-1de82c31bcc5",
            price: 69716.65,
            price_sources: [],
            processed_ms: 1711384528809,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "b0afae6d-ffc5-4aa9-bb96-854afe5b38c7",
            price: 70200.01,
            price_sources: [],
            processed_ms: 1711386335839,
          },
          {
            leverage: 0.125,
            order_type: "LONG",
            order_uuid: "6f87fbfb-7472-4068-b696-de48b132f07a",
            price: 70707.17,
            price_sources: [],
            processed_ms: 1711388158131,
          },
          {
            leverage: 5.125,
            order_type: "LONG",
            order_uuid: "a2bfe866-4bae-4255-9a78-29a6f0596820",
            price: 70559.71,
            price_sources: [],
            processed_ms: 1711391905893,
          },
        ],
        position_type: "FLAT",
        position_uuid: "5f689724-bdc4-4a50-9935-bafdad9200a6",
        return_at_close: 0.9923072340701149,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 70861.6809090909,
        close_ms: 1711401343466,
        current_return: 0.9314544478088665,
        initial_entry_price: 70961.57,
        is_closed_position: true,
        miner_hotkey: "5HQPp23LTQFq27GefUaR2CFN31UAfAu6zXSiMJeRDaVzxvoG",
        net_leverage: 0.0,
        open_ms: 1711393726014,
        orders: [
          {
            leverage: 5.0,
            order_type: "LONG",
            order_uuid: "9c7b846a-4362-47a7-a353-86f237fd8623",
            price: 70961.57,
            price_sources: [],
            processed_ms: 1711393726012,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "394de0f7-a802-4311-935b-0404a2200730",
            price: 70673.68,
            price_sources: [],
            processed_ms: 1711395549432,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "b8f87390-8a09-49ab-82b3-79994562a5c9",
            price: 70905.25,
            price_sources: [],
            processed_ms: 1711397372331,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "2dfafe73-6781-4091-b9dc-25601508fe9b",
            price: 70840.76,
            price_sources: [],
            processed_ms: 1711399179780,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "df37776d-a748-43ba-829b-9f0a080b1d93",
            price: 70694.07,
            price_sources: [],
            processed_ms: 1711400997154,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "b6b3b966-28c9-4f7f-9e30-aefb1a5491c2",
            price: 70419.49,
            price_sources: [],
            processed_ms: 1711401343466,
          },
        ],
        position_type: "FLAT",
        position_uuid: "96d82557-2d48-4102-aee9-0b2f15a1dd73",
        return_at_close: 0.9109624499570714,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 70302.62125000001,
        close_ms: 1711409959096,
        current_return: 0.9630848397727924,
        initial_entry_price: 70219.66,
        is_closed_position: true,
        miner_hotkey: "5HQPp23LTQFq27GefUaR2CFN31UAfAu6zXSiMJeRDaVzxvoG",
        net_leverage: 0.0,
        open_ms: 1711404632225,
        orders: [
          {
            leverage: 5.0,
            order_type: "LONG",
            order_uuid: "aabc0ac0-a091-44c0-b128-1f438daaf600",
            price: 70219.66,
            price_sources: [],
            processed_ms: 1711404632223,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "732c2a0e-16db-48b7-910c-44fa1c8b2259",
            price: 70534.0,
            price_sources: [],
            processed_ms: 1711406450864,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "833f8db0-fe72-431e-86ad-a4f9056c700d",
            price: 70347.78,
            price_sources: [],
            processed_ms: 1711408267949,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "38b93527-82d7-4f68-a188-70c286493916",
            price: 69978.6,
            price_sources: [],
            processed_ms: 1711409959096,
          },
        ],
        position_type: "FLAT",
        position_uuid: "dd536512-e420-4fd6-9d72-cafe4836dd64",
        return_at_close: 0.9476754823364276,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
    ],
    thirty_day_returns: 1.0,
  },
  "5HQRFdgyzXT3nEGBUxkaLMPY5qUxCasoJoLxrgjxKwdkrNgx": {
    all_time_returns: 1.0,
    n_positions: 0,
    percentage_profitable: 0.0,
    positions: [
      {
        average_entry_price: 64585.11,
        close_ms: 1711199085473,
        current_return: 1.0002936590183094,
        initial_entry_price: 64585.11,
        is_closed_position: true,
        miner_hotkey: "5HQRFdgyzXT3nEGBUxkaLMPY5qUxCasoJoLxrgjxKwdkrNgx",
        net_leverage: 0.0,
        open_ms: 1711195509791,
        orders: [
          {
            leverage: 0.6,
            order_type: "LONG",
            order_uuid: "9c6b70be-6bcb-4a8a-97ea-576571e90ee3",
            price: 64585.11,
            price_sources: [],
            processed_ms: 1711195509785,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "3b43bef1-be3e-4701-ab62-fd3fe9dcd50d",
            price: 64616.72,
            price_sources: [],
            processed_ms: 1711199085473,
          },
        ],
        position_type: "FLAT",
        position_uuid: "36308828-52f0-4ac4-8fe9-05ad459818de",
        return_at_close: 0.9990933066274874,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 64626.97,
        close_ms: 1711202746402,
        current_return: 0.9996855492374159,
        initial_entry_price: 64626.97,
        is_closed_position: true,
        miner_hotkey: "5HQRFdgyzXT3nEGBUxkaLMPY5qUxCasoJoLxrgjxKwdkrNgx",
        net_leverage: 0.0,
        open_ms: 1711199172393,
        orders: [
          {
            leverage: 0.6,
            order_type: "LONG",
            order_uuid: "12449087-7d00-4581-bcf5-a9b1137180c3",
            price: 64626.97,
            price_sources: [],
            processed_ms: 1711199172392,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "b8f08a1f-2b9b-4d4f-8b76-693e4e50a67b",
            price: 64593.1,
            price_sources: [],
            processed_ms: 1711202746402,
          },
        ],
        position_type: "FLAT",
        position_uuid: "7614801f-ffba-4f1d-b41c-110d316725e1",
        return_at_close: 0.998485926578331,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 64618.15,
        close_ms: 1711206402555,
        current_return: 1.0059823439699218,
        initial_entry_price: 64618.15,
        is_closed_position: true,
        miner_hotkey: "5HQRFdgyzXT3nEGBUxkaLMPY5qUxCasoJoLxrgjxKwdkrNgx",
        net_leverage: 0.0,
        open_ms: 1711202833366,
        orders: [
          {
            leverage: 0.6,
            order_type: "LONG",
            order_uuid: "14212418-1c4f-4171-ae49-409fd69c57f9",
            price: 64618.15,
            price_sources: [],
            processed_ms: 1711202833365,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "27783db5-becd-461c-afd4-9acf97776f6a",
            price: 65262.43,
            price_sources: [],
            processed_ms: 1711206402555,
          },
        ],
        position_type: "FLAT",
        position_uuid: "d4df2e27-5e09-484a-905b-43b9dcd71608",
        return_at_close: 1.004775165157158,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 65261.01,
        close_ms: 1711210070659,
        current_return: 0.9976420530420844,
        initial_entry_price: 65261.01,
        is_closed_position: true,
        miner_hotkey: "5HQRFdgyzXT3nEGBUxkaLMPY5qUxCasoJoLxrgjxKwdkrNgx",
        net_leverage: 0.0,
        open_ms: 1711206489434,
        orders: [
          {
            leverage: 0.6,
            order_type: "LONG",
            order_uuid: "7a560e95-bc4a-4bd6-ba9c-5abaed8d16f5",
            price: 65261.01,
            price_sources: [],
            processed_ms: 1711206489434,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "8b522337-ad86-4f0d-8b0e-7a75caa9ae7b",
            price: 65004.54,
            price_sources: [],
            processed_ms: 1711210070659,
          },
        ],
        position_type: "FLAT",
        position_uuid: "5a4075b8-12f6-4b2f-acb6-8c6397f04697",
        return_at_close: 0.9964448825784339,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 64939.31,
        close_ms: 1711213728523,
        current_return: 1.0061742263661255,
        initial_entry_price: 64939.31,
        is_closed_position: true,
        miner_hotkey: "5HQRFdgyzXT3nEGBUxkaLMPY5qUxCasoJoLxrgjxKwdkrNgx",
        net_leverage: 0.0,
        open_ms: 1711210157824,
        orders: [
          {
            leverage: 0.6,
            order_type: "LONG",
            order_uuid: "2ccdf846-38b0-43f0-9e26-8374c6d08241",
            price: 64939.31,
            price_sources: [],
            processed_ms: 1711210157823,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "535b2d09-206b-4fb7-ae48-535992315a0f",
            price: 65607.56,
            price_sources: [],
            processed_ms: 1711213728523,
          },
        ],
        position_type: "FLAT",
        position_uuid: "df2f4d86-94ac-47b1-8bd9-2ab3819e532d",
        return_at_close: 1.0049668172944861,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 65637.95,
        close_ms: 1711217392540,
        current_return: 1.0000768762583232,
        initial_entry_price: 65637.95,
        is_closed_position: true,
        miner_hotkey: "5HQRFdgyzXT3nEGBUxkaLMPY5qUxCasoJoLxrgjxKwdkrNgx",
        net_leverage: 0.0,
        open_ms: 1711213815569,
        orders: [
          {
            leverage: 0.6,
            order_type: "LONG",
            order_uuid: "dab5e397-117b-4dbd-824b-67259161a642",
            price: 65637.95,
            price_sources: [],
            processed_ms: 1711213815568,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "932a5f71-d1a2-484f-b05f-45c3f80c7d10",
            price: 65646.36,
            price_sources: [],
            processed_ms: 1711217392540,
          },
        ],
        position_type: "FLAT",
        position_uuid: "961a618d-98ff-4e64-bbb3-c8f0b836ad16",
        return_at_close: 0.9988767840068132,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 65596.72,
        close_ms: 1711221074634,
        current_return: 0.9976895186222726,
        initial_entry_price: 65596.72,
        is_closed_position: true,
        miner_hotkey: "5HQRFdgyzXT3nEGBUxkaLMPY5qUxCasoJoLxrgjxKwdkrNgx",
        net_leverage: 0.0,
        open_ms: 1711217479462,
        orders: [
          {
            leverage: 0.6,
            order_type: "LONG",
            order_uuid: "bf3963c7-98c8-41ba-87e0-772e94d804cf",
            price: 65596.72,
            price_sources: [],
            processed_ms: 1711217479461,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "7d517058-51e1-4dcd-a89f-a030f787a63d",
            price: 65344.12,
            price_sources: [],
            processed_ms: 1711221074634,
          },
        ],
        position_type: "FLAT",
        position_uuid: "ad12f2db-e551-42f9-bd8f-11b8c1651966",
        return_at_close: 0.9964922911999259,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 65369.28,
        close_ms: 1711224721441,
        current_return: 0.9938505977119527,
        initial_entry_price: 65369.28,
        is_closed_position: true,
        miner_hotkey: "5HQRFdgyzXT3nEGBUxkaLMPY5qUxCasoJoLxrgjxKwdkrNgx",
        net_leverage: 0.0,
        open_ms: 1711221162724,
        orders: [
          {
            leverage: 0.6,
            order_type: "LONG",
            order_uuid: "415a7b4a-7233-4912-8506-61692650d326",
            price: 65369.28,
            price_sources: [],
            processed_ms: 1711221162723,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "9348f8cc-4317-48b8-9261-860d2fe4d5bc",
            price: 64699.31,
            price_sources: [],
            processed_ms: 1711224721441,
          },
        ],
        position_type: "FLAT",
        position_uuid: "0520e87a-8a57-4a29-98e0-5926b187d801",
        return_at_close: 0.9926579769946984,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 64743.46000000001,
        close_ms: 1711228396341,
        current_return: 1.0018283545550393,
        initial_entry_price: 64743.46,
        is_closed_position: true,
        miner_hotkey: "5HQRFdgyzXT3nEGBUxkaLMPY5qUxCasoJoLxrgjxKwdkrNgx",
        net_leverage: 0.0,
        open_ms: 1711224824455,
        orders: [
          {
            leverage: 0.6,
            order_type: "LONG",
            order_uuid: "acd12b87-6864-4713-8dd4-a8f5bf50ffe6",
            price: 64743.46,
            price_sources: [],
            processed_ms: 1711224824454,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "127d8326-ce91-4954-af89-e2a277cba68e",
            price: 64940.75,
            price_sources: [],
            processed_ms: 1711228396341,
          },
        ],
        position_type: "FLAT",
        position_uuid: "0de68ed1-bc12-4a9a-8321-53b9c799d8ee",
        return_at_close: 1.0006261605295732,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 64917.0,
        close_ms: 1711232037775,
        current_return: 1.0004053791764869,
        initial_entry_price: 64917.0,
        is_closed_position: true,
        miner_hotkey: "5HQRFdgyzXT3nEGBUxkaLMPY5qUxCasoJoLxrgjxKwdkrNgx",
        net_leverage: 0.0,
        open_ms: 1711228500568,
        orders: [
          {
            leverage: 0.6,
            order_type: "LONG",
            order_uuid: "9a1ab9da-6d9b-4c11-afa6-8d22321a584e",
            price: 64917.0,
            price_sources: [],
            processed_ms: 1711228500567,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "12bf5a72-6673-4220-98ef-738eb57d3967",
            price: 64960.86,
            price_sources: [],
            processed_ms: 1711232037775,
          },
        ],
        position_type: "FLAT",
        position_uuid: "22a585fd-a343-4f83-b11d-8bd6bde5eb50",
        return_at_close: 0.9992048927214751,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 64924.24999999999,
        close_ms: 1711235708263,
        current_return: 0.9971390043011664,
        initial_entry_price: 64924.25,
        is_closed_position: true,
        miner_hotkey: "5HQRFdgyzXT3nEGBUxkaLMPY5qUxCasoJoLxrgjxKwdkrNgx",
        net_leverage: 0.0,
        open_ms: 1711232173329,
        orders: [
          {
            leverage: 0.6,
            order_type: "LONG",
            order_uuid: "c2cea272-a8c8-4149-8182-6b3f5b392ce2",
            price: 64924.25,
            price_sources: [],
            processed_ms: 1711232173328,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "ac98c886-c629-4f16-ac72-807b14c83169",
            price: 64614.67,
            price_sources: [],
            processed_ms: 1711235708263,
          },
        ],
        position_type: "FLAT",
        position_uuid: "24e95bbc-d722-4ee2-8e9b-677b73504b0e",
        return_at_close: 0.9959424374960051,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 64639.01000000001,
        close_ms: 1711239383507,
        current_return: 0.9964819077519905,
        initial_entry_price: 64639.01,
        is_closed_position: true,
        miner_hotkey: "5HQRFdgyzXT3nEGBUxkaLMPY5qUxCasoJoLxrgjxKwdkrNgx",
        net_leverage: 0.0,
        open_ms: 1711235795369,
        orders: [
          {
            leverage: 0.6,
            order_type: "LONG",
            order_uuid: "1b2642b9-1dee-4bbf-8be4-ce653a941b1b",
            price: 64639.01,
            price_sources: [],
            processed_ms: 1711235795368,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "66380592-d905-4337-9da2-26154d5d42d8",
            price: 64260.0,
            price_sources: [],
            processed_ms: 1711239383507,
          },
        ],
        position_type: "FLAT",
        position_uuid: "a3187a5c-e906-4ec4-aa06-1d770e0d5faf",
        return_at_close: 0.9952861294626881,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 64273.35999999999,
        close_ms: 1711243031051,
        current_return: 1.0011782797725217,
        initial_entry_price: 64273.36,
        is_closed_position: true,
        miner_hotkey: "5HQRFdgyzXT3nEGBUxkaLMPY5qUxCasoJoLxrgjxKwdkrNgx",
        net_leverage: 0.0,
        open_ms: 1711239470529,
        orders: [
          {
            leverage: 0.6,
            order_type: "LONG",
            order_uuid: "0e9da0dd-9bc7-4c31-a3dc-24efb3d385ef",
            price: 64273.36,
            price_sources: [],
            processed_ms: 1711239470527,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "25147f51-6697-4470-9e1a-f29751fa4882",
            price: 64399.58,
            price_sources: [],
            processed_ms: 1711243031051,
          },
        ],
        position_type: "FLAT",
        position_uuid: "ef610026-46b8-4c2a-8fdd-debf12009b62",
        return_at_close: 0.9999768658367947,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 64401.11,
        close_ms: 1711246697534,
        current_return: 1.0002037542520619,
        initial_entry_price: 64401.11,
        is_closed_position: true,
        miner_hotkey: "5HQRFdgyzXT3nEGBUxkaLMPY5qUxCasoJoLxrgjxKwdkrNgx",
        net_leverage: 0.0,
        open_ms: 1711243166295,
        orders: [
          {
            leverage: 0.6,
            order_type: "LONG",
            order_uuid: "c52c3cc6-3caa-47cc-9744-b8afc7876a3b",
            price: 64401.11,
            price_sources: [],
            processed_ms: 1711243166294,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "7ba65471-5795-4b33-a2aa-b05d1eb62918",
            price: 64422.98,
            price_sources: [],
            processed_ms: 1711246697534,
          },
        ],
        position_type: "FLAT",
        position_uuid: "797336ea-85f7-4820-9ff4-b8ba39397749",
        return_at_close: 0.9990035097469594,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 64362.479999999996,
        close_ms: 1711250349417,
        current_return: 0.9984502772422691,
        initial_entry_price: 64362.48,
        is_closed_position: true,
        miner_hotkey: "5HQRFdgyzXT3nEGBUxkaLMPY5qUxCasoJoLxrgjxKwdkrNgx",
        net_leverage: 0.0,
        open_ms: 1711246784498,
        orders: [
          {
            leverage: 0.6,
            order_type: "LONG",
            order_uuid: "ae3210dd-993e-402c-9edb-6b62262c70ee",
            price: 64362.48,
            price_sources: [],
            processed_ms: 1711246784497,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "ded3d40f-551b-48a1-8a0a-3b3866167251",
            price: 64196.24,
            price_sources: [],
            processed_ms: 1711250349417,
          },
        ],
        position_type: "FLAT",
        position_uuid: "f9f9fc88-b9a7-470a-aa50-2f580e33ecc2",
        return_at_close: 0.9972521369095784,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 64210.49999999999,
        close_ms: 1711254023481,
        current_return: 0.9989202700492911,
        initial_entry_price: 64210.5,
        is_closed_position: true,
        miner_hotkey: "5HQRFdgyzXT3nEGBUxkaLMPY5qUxCasoJoLxrgjxKwdkrNgx",
        net_leverage: 0.0,
        open_ms: 1711250436398,
        orders: [
          {
            leverage: 0.6,
            order_type: "LONG",
            order_uuid: "f5c29342-403d-47be-a4f9-e78a508ad934",
            price: 64210.5,
            price_sources: [],
            processed_ms: 1711250436396,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "575c0201-a7c5-4279-a0eb-a69f685bff93",
            price: 64094.95,
            price_sources: [],
            processed_ms: 1711254023481,
          },
        ],
        position_type: "FLAT",
        position_uuid: "c581eb4b-cd34-45b7-8bb0-0bd64869ff97",
        return_at_close: 0.997721565725232,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 64040.45,
        close_ms: 1711256941422,
        current_return: 1.00113665659751,
        initial_entry_price: 64040.45,
        is_closed_position: true,
        miner_hotkey: "5HQRFdgyzXT3nEGBUxkaLMPY5qUxCasoJoLxrgjxKwdkrNgx",
        net_leverage: 0.0,
        open_ms: 1711254110443,
        orders: [
          {
            leverage: 0.6,
            order_type: "LONG",
            order_uuid: "774bd099-27d6-4c7e-ab7d-2c57215a6b23",
            price: 64040.45,
            price_sources: [],
            processed_ms: 1711254110442,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "f1e8db47-7f74-4123-8346-80a727e73cbf",
            price: 64161.77,
            price_sources: [],
            processed_ms: 1711256941422,
          },
        ],
        position_type: "FLAT",
        position_uuid: "3a4ba9e6-040e-4a22-8f74-598e0bcaa469",
        return_at_close: 0.9999352926095929,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 64158.02,
        close_ms: 1711257647543,
        current_return: 1.0016952659182006,
        initial_entry_price: 64188.16,
        is_closed_position: true,
        miner_hotkey: "5HQRFdgyzXT3nEGBUxkaLMPY5qUxCasoJoLxrgjxKwdkrNgx",
        net_leverage: 0.0,
        open_ms: 1711257028529,
        orders: [
          {
            leverage: 0.6,
            order_type: "LONG",
            order_uuid: "b0e5d582-e474-4610-bcb7-487d4246692c",
            price: 64188.16,
            price_sources: [],
            processed_ms: 1711257028527,
          },
          {
            leverage: 0.6,
            order_type: "LONG",
            order_uuid: "c3efd96c-e0d7-4cff-bb97-9aa10565856f",
            price: 64127.88,
            price_sources: [],
            processed_ms: 1711257224559,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "333955fe-6df8-424e-90b6-d9a0f62134dd",
            price: 64248.7,
            price_sources: [],
            processed_ms: 1711257647543,
          },
        ],
        position_type: "FLAT",
        position_uuid: "45f6badb-f8dc-4dae-b4d0-2c51bb511914",
        return_at_close: 0.9992911972799969,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 64323.23,
        close_ms: 1711261309543,
        current_return: 0.9999391743853658,
        initial_entry_price: 64323.23,
        is_closed_position: true,
        miner_hotkey: "5HQRFdgyzXT3nEGBUxkaLMPY5qUxCasoJoLxrgjxKwdkrNgx",
        net_leverage: 0.0,
        open_ms: 1711257736189,
        orders: [
          {
            leverage: 1.25,
            order_type: "LONG",
            order_uuid: "3d5854be-e04b-4df6-b165-c90dc4aac719",
            price: 64323.23,
            price_sources: [],
            processed_ms: 1711257735425,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "92c1d86e-fd9c-48fe-96e0-f173702dc832",
            price: 64320.1,
            price_sources: [],
            processed_ms: 1711261309543,
          },
        ],
        position_type: "FLAT",
        position_uuid: "943a6ca9-3388-49b8-8bb4-c3f4735d2805",
        return_at_close: 0.9974393264494025,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 64313.469999999994,
        close_ms: 1711264966673,
        current_return: 0.9963596273066903,
        initial_entry_price: 64313.47,
        is_closed_position: true,
        miner_hotkey: "5HQRFdgyzXT3nEGBUxkaLMPY5qUxCasoJoLxrgjxKwdkrNgx",
        net_leverage: 0.0,
        open_ms: 1711261396532,
        orders: [
          {
            leverage: 1.25,
            order_type: "LONG",
            order_uuid: "15c8ca70-c287-465b-b570-770ba9661cc8",
            price: 64313.47,
            price_sources: [],
            processed_ms: 1711261396530,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "836790b6-8eaf-48c1-ae3f-25044a1aff5c",
            price: 64126.17,
            price_sources: [],
            processed_ms: 1711264966673,
          },
        ],
        position_type: "FLAT",
        position_uuid: "8aff09e6-70f3-4132-bd63-41bc2fd0efeb",
        return_at_close: 0.9938687282384235,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 64149.7,
        close_ms: 1711268638878,
        current_return: 1.0127087500019487,
        initial_entry_price: 64149.7,
        is_closed_position: true,
        miner_hotkey: "5HQRFdgyzXT3nEGBUxkaLMPY5qUxCasoJoLxrgjxKwdkrNgx",
        net_leverage: 0.0,
        open_ms: 1711265069516,
        orders: [
          {
            leverage: 1.25,
            order_type: "LONG",
            order_uuid: "c3cef597-3bed-4e5b-ba2f-960c85c512f3",
            price: 64149.7,
            price_sources: [],
            processed_ms: 1711265069514,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "6673f4c3-7bb3-4c7f-8b6c-5d1687e96b84",
            price: 64801.91,
            price_sources: [],
            processed_ms: 1711268638878,
          },
        ],
        position_type: "FLAT",
        position_uuid: "d58d5d4f-7754-4562-8d31-dbede00591e9",
        return_at_close: 1.0101769781269438,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 64811.86,
        close_ms: 1711272305606,
        current_return: 1.0007805284403195,
        initial_entry_price: 64811.86,
        is_closed_position: true,
        miner_hotkey: "5HQRFdgyzXT3nEGBUxkaLMPY5qUxCasoJoLxrgjxKwdkrNgx",
        net_leverage: 0.0,
        open_ms: 1711268742520,
        orders: [
          {
            leverage: 1.25,
            order_type: "LONG",
            order_uuid: "62ad061b-f567-4fba-8033-959bcc21f733",
            price: 64811.86,
            price_sources: [],
            processed_ms: 1711268742518,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "0b74a086-215a-44c6-a4d8-7a8b6ac600b8",
            price: 64852.33,
            price_sources: [],
            processed_ms: 1711272305606,
          },
        ],
        position_type: "FLAT",
        position_uuid: "31a1c899-5e5e-44b7-858e-95af674be4a0",
        return_at_close: 0.9982785771192187,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 65000.19499999999,
        close_ms: 1711276010784,
        current_return: 1.0039267231509752,
        initial_entry_price: 64898.26,
        is_closed_position: true,
        miner_hotkey: "5HQRFdgyzXT3nEGBUxkaLMPY5qUxCasoJoLxrgjxKwdkrNgx",
        net_leverage: 0.0,
        open_ms: 1711272407542,
        orders: [
          {
            leverage: 1.25,
            order_type: "LONG",
            order_uuid: "83576e13-8f06-4141-9392-e779a3580baf",
            price: 64898.26,
            price_sources: [],
            processed_ms: 1711272407540,
          },
          {
            leverage: 1.25,
            order_type: "LONG",
            order_uuid: "01e55e08-eabc-4f36-951e-17604d438920",
            price: 65102.13,
            price_sources: [],
            processed_ms: 1711275991485,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "48f61dfa-004e-4745-87ff-5f338cec2073",
            price: 65102.13,
            price_sources: [],
            processed_ms: 1711276010784,
          },
        ],
        position_type: "FLAT",
        position_uuid: "59a1c6f6-a51d-445c-bcda-5ac2e280c9d7",
        return_at_close: 0.9989070895352203,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 65280.319999999985,
        close_ms: 1711295543924,
        current_return: 1.0066792815983285,
        initial_entry_price: 65287.71,
        is_closed_position: true,
        miner_hotkey: "5HQRFdgyzXT3nEGBUxkaLMPY5qUxCasoJoLxrgjxKwdkrNgx",
        net_leverage: 0.0,
        open_ms: 1711282721711,
        orders: [
          {
            leverage: 1.25,
            order_type: "LONG",
            order_uuid: "a14f8b4d-a58d-4c07-aafb-79aedf8e4484",
            price: 65287.71,
            price_sources: [],
            processed_ms: 1711282721709,
          },
          {
            leverage: 1.25,
            order_type: "LONG",
            order_uuid: "0298b376-7d7d-42fe-8105-535e53bf7c22",
            price: 65640.92,
            price_sources: [],
            processed_ms: 1711284545695,
          },
          {
            leverage: -1.25,
            order_type: "SHORT",
            order_uuid: "43429f87-0d9c-4b68-b798-61c3ca1d4912",
            price: 65648.31,
            price_sources: [],
            processed_ms: 1711286412331,
          },
          {
            leverage: -1.25,
            order_type: "SHORT",
            order_uuid: "4787d035-8199-427e-8a7b-7412b5c9d628",
            price: 65629.18,
            price_sources: [],
            processed_ms: 1711295543924,
          },
        ],
        position_type: "FLAT",
        position_uuid: "9d5f5aed-93b5-4457-b133-8fc25abc55d2",
        return_at_close: 1.001645885190337,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 65348.21,
        close_ms: 1711300060599,
        current_return: 0.9954900065357567,
        initial_entry_price: 65348.21,
        is_closed_position: true,
        miner_hotkey: "5HQRFdgyzXT3nEGBUxkaLMPY5qUxCasoJoLxrgjxKwdkrNgx",
        net_leverage: 0.0,
        open_ms: 1711299402605,
        orders: [
          {
            leverage: 0.5,
            order_type: "LONG",
            order_uuid: "09694168-425b-454a-96c8-19c9fed4899b",
            price: 65348.21,
            price_sources: [],
            processed_ms: 1711299402602,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "4bc7d13b-7833-406d-9f6a-0fbb3c095fae",
            price: 64758.77,
            price_sources: [],
            processed_ms: 1711300060599,
          },
        ],
        position_type: "FLAT",
        position_uuid: "0e9511ce-d9e3-433f-818f-a7a282a56bb4",
        return_at_close: 0.9944945165292209,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 65183.509999999995,
        close_ms: 1711318287836,
        current_return: 1.0126320338264956,
        initial_entry_price: 65025.95,
        is_closed_position: true,
        miner_hotkey: "5HQRFdgyzXT3nEGBUxkaLMPY5qUxCasoJoLxrgjxKwdkrNgx",
        net_leverage: 0.0,
        open_ms: 1711301757794,
        orders: [
          {
            leverage: 0.5,
            order_type: "LONG",
            order_uuid: "5f4cfb24-c06b-4188-b7e9-7b11de0da0bd",
            price: 65025.95,
            price_sources: [],
            processed_ms: 1711301757792,
          },
          {
            leverage: 0.5,
            order_type: "LONG",
            order_uuid: "c17f4e3f-4c51-4613-836c-c14d95b361f2",
            price: 65859.69,
            price_sources: [],
            processed_ms: 1711308034663,
          },
          {
            leverage: -0.5,
            order_type: "SHORT",
            order_uuid: "cb9458c3-24dc-48a9-96ad-acba610b5580",
            price: 65702.13,
            price_sources: [],
            processed_ms: 1711308970815,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "7bae78d7-99b4-4aa8-91ca-4be291c02eb9",
            price: 66826.33,
            price_sources: [],
            processed_ms: 1711318287836,
          },
        ],
        position_type: "FLAT",
        position_uuid: "27d1cf72-9f94-49b6-8eb6-187be5694cbd",
        return_at_close: 1.0106067697588426,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 66824.1,
        close_ms: 1711327584878,
        current_return: 0.998605143952556,
        initial_entry_price: 66824.1,
        is_closed_position: true,
        miner_hotkey: "5HQRFdgyzXT3nEGBUxkaLMPY5qUxCasoJoLxrgjxKwdkrNgx",
        net_leverage: 0.0,
        open_ms: 1711319217604,
        orders: [
          {
            leverage: 0.5,
            order_type: "LONG",
            order_uuid: "f7a04abe-3d08-4482-82a6-e2b122cf751d",
            price: 66824.1,
            price_sources: [],
            processed_ms: 1711319217602,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "7265b3f8-c802-4262-8089-6ef5ff59f770",
            price: 66637.68,
            price_sources: [],
            processed_ms: 1711327584878,
          },
        ],
        position_type: "FLAT",
        position_uuid: "9c710bd8-6a3b-413a-b859-1761961e7c8d",
        return_at_close: 0.9976065388086034,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 66851.57500000001,
        close_ms: 1711329940769,
        current_return: 0.9981692853286237,
        initial_entry_price: 66916.49,
        is_closed_position: true,
        miner_hotkey: "5HQRFdgyzXT3nEGBUxkaLMPY5qUxCasoJoLxrgjxKwdkrNgx",
        net_leverage: 0.0,
        open_ms: 1711329448703,
        orders: [
          {
            leverage: 0.5,
            order_type: "LONG",
            order_uuid: "1d1102c5-b9a7-40e6-974f-129c424b3dac",
            price: 66916.49,
            price_sources: [],
            processed_ms: 1711329448701,
          },
          {
            leverage: 0.5,
            order_type: "LONG",
            order_uuid: "3eae16cf-0f61-4d7f-9eb3-3a06b022cc71",
            price: 66786.66,
            price_sources: [],
            processed_ms: 1711329646163,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "503ed7b0-3f7b-4e41-9284-895caba77455",
            price: 66729.07,
            price_sources: [],
            processed_ms: 1711329940769,
          },
        ],
        position_type: "FLAT",
        position_uuid: "f7fb33fa-e3af-4f68-b8b6-eba1d83ce3ae",
        return_at_close: 0.9961729467579664,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 69058.90827617329,
        close_ms: 1711386499849,
        current_return: 1.0519552358438133,
        initial_entry_price: 66484.89,
        is_closed_position: true,
        miner_hotkey: "5HQRFdgyzXT3nEGBUxkaLMPY5qUxCasoJoLxrgjxKwdkrNgx",
        net_leverage: 0.0,
        open_ms: 1711331774672,
        orders: [
          {
            leverage: 0.5,
            order_type: "LONG",
            order_uuid: "06da9e42-3791-4489-bd06-67f105794d22",
            price: 66484.89,
            price_sources: [],
            processed_ms: 1711331774670,
          },
          {
            leverage: 0.5,
            order_type: "LONG",
            order_uuid: "95638a1a-d032-4a28-abcc-bfe77d7ce638",
            price: 66807.36,
            price_sources: [],
            processed_ms: 1711337256823,
          },
          {
            leverage: -0.5,
            order_type: "SHORT",
            order_uuid: "32fcb16b-0b82-42e4-8f9e-ab06ec2fede8",
            price: 67209.93,
            price_sources: [],
            processed_ms: 1711339101641,
          },
          {
            leverage: 0.09999999999999998,
            order_type: "LONG",
            order_uuid: "994865ba-757c-4feb-b46e-4493fc80d98c",
            price: 67244.48,
            price_sources: [],
            processed_ms: 1711370202470,
          },
          {
            leverage: 0.09999999999999998,
            order_type: "LONG",
            order_uuid: "a9e519e9-37fe-421c-88a3-adda2e8e95ed",
            price: 67218.78,
            price_sources: [],
            processed_ms: 1711372031117,
          },
          {
            leverage: 0.09999999999999998,
            order_type: "LONG",
            order_uuid: "82143aa5-bd5b-4aba-9f2c-336b2b43b729",
            price: 67182.73,
            price_sources: [],
            processed_ms: 1711373865604,
          },
          {
            leverage: -0.39999999999999997,
            order_type: "SHORT",
            order_uuid: "993d296d-ca62-4029-8b12-6fb3897a95d0",
            price: 68234.56,
            price_sources: [],
            processed_ms: 1711375717494,
          },
          {
            leverage: -0.31999999999999995,
            order_type: "SHORT",
            order_uuid: "64fca153-5317-4e6e-a54f-11d4db255f79",
            price: 69141.81,
            price_sources: [],
            processed_ms: 1711376527265,
          },
          {
            leverage: -0.06400000000000002,
            order_type: "SHORT",
            order_uuid: "e640db4f-5cb4-4e7b-95da-ace835007d41",
            price: 69296.54,
            price_sources: [],
            processed_ms: 1711376834463,
          },
          {
            leverage: 0.1,
            order_type: "LONG",
            order_uuid: "6da281c8-aac1-4afe-8665-9269ef5abdfa",
            price: 69065.38,
            price_sources: [],
            processed_ms: 1711377257229,
          },
          {
            leverage: 0.10000000000000002,
            order_type: "LONG",
            order_uuid: "6a695666-b293-4096-a750-6ccd6ecd3b8b",
            price: 69144.78,
            price_sources: [],
            processed_ms: 1711378009538,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "dff217d6-12e8-48e5-9bcf-cc3a40785142",
            price: 69212.28,
            price_sources: [],
            processed_ms: 1711378285953,
          },
          {
            leverage: 0.24999999999999994,
            order_type: "LONG",
            order_uuid: "f4755e94-ca6c-4200-bb59-c5d2a2144e81",
            price: 69804.53,
            price_sources: [],
            processed_ms: 1711381409897,
          },
          {
            leverage: 1.5000000000000002,
            order_type: "LONG",
            order_uuid: "728a16f3-85a2-4c02-b50b-3c5ebc915ef7",
            price: 70071.83,
            price_sources: [],
            processed_ms: 1711385072747,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "024ef7d9-5560-4dd3-a5a1-52abae15da4c",
            price: 70617.68,
            price_sources: [],
            processed_ms: 1711386499849,
          },
        ],
        position_type: "FLAT",
        position_uuid: "002e2d8c-d796-46ed-a6c7-8fc476f831a8",
        return_at_close: 1.0472929702385536,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 70541.98894736842,
        close_ms: 1711393749831,
        current_return: 1.056569910748855,
        initial_entry_price: 70461.84,
        is_closed_position: true,
        miner_hotkey: "5HQRFdgyzXT3nEGBUxkaLMPY5qUxCasoJoLxrgjxKwdkrNgx",
        net_leverage: 0.0,
        open_ms: 1711386882615,
        orders: [
          {
            leverage: 5.0,
            order_type: "LONG",
            order_uuid: "d331f4c5-b7ee-4834-9358-ca7a239cb2ee",
            price: 70461.84,
            price_sources: [],
            processed_ms: 1711386882612,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "341049fa-bddb-478e-845c-7d15e31c965c",
            price: 70487.31,
            price_sources: [],
            processed_ms: 1711388700008,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "f26c8d7d-83f1-4d0d-8788-7f7d2da95fc9",
            price: 70679.61,
            price_sources: [],
            processed_ms: 1711390517882,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "763683c4-f41b-4d78-aa42-48fea9ebb00f",
            price: 70726.21,
            price_sources: [],
            processed_ms: 1711392332167,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "48d1917f-a1ae-4e90-b3cf-1aab84c44da4",
            price: 70961.57,
            price_sources: [],
            processed_ms: 1711393749831,
          },
        ],
        position_type: "FLAT",
        position_uuid: "90eb8d4f-8db3-4d45-a4dc-078b3a455dbf",
        return_at_close: 1.0364950824446268,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 70745.77263157895,
        close_ms: 1711401385809,
        current_return: 0.9320665947887523,
        initial_entry_price: 70647.57,
        is_closed_position: true,
        miner_hotkey: "5HQRFdgyzXT3nEGBUxkaLMPY5qUxCasoJoLxrgjxKwdkrNgx",
        net_leverage: 0.0,
        open_ms: 1711394160814,
        orders: [
          {
            leverage: 5.0,
            order_type: "LONG",
            order_uuid: "f134f033-da98-4b2d-b94a-786e77f4e3d0",
            price: 70647.57,
            price_sources: [],
            processed_ms: 1711394160812,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "4e6b43a9-20c7-42d0-8ef4-99d965955d4a",
            price: 70797.7,
            price_sources: [],
            processed_ms: 1711395979453,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "3e16ba37-16cc-470a-b713-f8068e8633ff",
            price: 70881.69,
            price_sources: [],
            processed_ms: 1711397787010,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "64d7de0c-e4f9-44a6-b2ab-80bd3dfd42a1",
            price: 70885.27,
            price_sources: [],
            processed_ms: 1711399611135,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "8ae5eee4-b3c5-4be5-b736-38f2774a148f",
            price: 70240.58,
            price_sources: [],
            processed_ms: 1711401385809,
          },
        ],
        position_type: "FLAT",
        position_uuid: "3f2394ec-9ca2-4213-baea-9d53c15253f7",
        return_at_close: 0.914357329487766,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 70123.98,
        close_ms: 1711402172071,
        current_return: 0.9643331710493328,
        initial_entry_price: 70123.98,
        is_closed_position: true,
        miner_hotkey: "5HQRFdgyzXT3nEGBUxkaLMPY5qUxCasoJoLxrgjxKwdkrNgx",
        net_leverage: 0.0,
        open_ms: 1711401472825,
        orders: [
          {
            leverage: 5.0,
            order_type: "LONG",
            order_uuid: "e2d8a3f9-a983-496e-a8a6-eb29b6c43a5d",
            price: 70123.98,
            price_sources: [],
            processed_ms: 1711401472821,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "ce8e03f1-bab8-41cd-b682-f4a5f0014003",
            price: 69623.76,
            price_sources: [],
            processed_ms: 1711402172071,
          },
        ],
        position_type: "FLAT",
        position_uuid: "1a1ffa5d-3164-4ba1-bcc8-ec862471d079",
        return_at_close: 0.9546898393388394,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
    ],
    thirty_day_returns: 1.0,
  },
  "5HTVmEBicnSE1iFrSRtYnmyv5bhj6SRmiZHT3Twfbh9jon14": {
    all_time_returns: 1.0,
    n_positions: 0,
    percentage_profitable: 0.0,
    positions: [
      {
        average_entry_price: 62976.79,
        close_ms: 1711157379994,
        current_return: 0.9998452668038494,
        initial_entry_price: 62976.79,
        is_closed_position: true,
        miner_hotkey: "5HTVmEBicnSE1iFrSRtYnmyv5bhj6SRmiZHT3Twfbh9jon14",
        net_leverage: 0.0,
        open_ms: 1711146621630,
        orders: [
          {
            leverage: -0.01,
            order_type: "SHORT",
            order_uuid: "45e0394c-b51a-47ef-bc9a-00880a634209",
            price: 62976.79,
            price_sources: [],
            processed_ms: 1711146621629,
          },
          {
            leverage: 0.01,
            order_type: "FLAT",
            order_uuid: "1675d057-3931-4110-9e33-1be4b05bb5ac",
            price: 63951.25,
            price_sources: [],
            processed_ms: 1711157379994,
          },
        ],
        position_type: "FLAT",
        position_uuid: "8415d417-0637-4cc9-abb6-c88374341c9f",
        return_at_close: 0.9998352683511814,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 70154.1,
        close_ms: 1711489322292,
        current_return: 1.0112774185088222,
        initial_entry_price: 70157.9,
        is_closed_position: true,
        miner_hotkey: "5HTVmEBicnSE1iFrSRtYnmyv5bhj6SRmiZHT3Twfbh9jon14",
        net_leverage: 0.0,
        open_ms: 1711488997519,
        orders: [
          {
            leverage: -5.0,
            order_type: "SHORT",
            order_uuid: "a2551563-0b2c-4eb6-86a1-f3ee62316bee",
            price: 70157.9,
            price_sources: [],
            processed_ms: 1711488997517,
          },
          {
            leverage: -5.0,
            order_type: "SHORT",
            order_uuid: "8e65c242-637e-40b8-843c-176d48be2764",
            price: 70150.3,
            price_sources: [],
            processed_ms: 1711489120159,
          },
          {
            leverage: 15.0,
            order_type: "FLAT",
            order_uuid: "92df5b79-ec9a-450e-825d-a3d5644d21bd",
            price: 70074.98,
            price_sources: [],
            processed_ms: 1711489322292,
          },
        ],
        position_type: "FLAT",
        position_uuid: "6ed46597-9c1f-44ca-a861-2c07b7c346ee",
        return_at_close: 1.001164644323734,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 39570.26,
        close_ms: 1711546751919,
        current_return: 1.008737622648927,
        initial_entry_price: 39570.26,
        is_closed_position: true,
        miner_hotkey: "5HTVmEBicnSE1iFrSRtYnmyv5bhj6SRmiZHT3Twfbh9jon14",
        net_leverage: 0.0,
        open_ms: 1711546446144,
        orders: [
          {
            leverage: 15.0,
            order_type: "LONG",
            order_uuid: "79f1c984-572a-462b-9db7-b24ff9fb3f2d",
            price: 39570.26,
            price_sources: [],
            processed_ms: 1711546446143,
          },
          {
            leverage: 15.0,
            order_type: "FLAT",
            order_uuid: "bfd9d37f-a3a4-4ebf-8f19-9754261a80d0",
            price: 39593.31,
            price_sources: [],
            processed_ms: 1711546751919,
          },
        ],
        position_type: "FLAT",
        position_uuid: "80d894a4-ce53-42d8-9641-3e01505ec637",
        return_at_close: 1.007375826858351,
        trade_pair: ["DJI", "DJI", 9e-5, 0.001, 500],
      },
      {
        average_entry_price: 5232.9,
        close_ms: 1711547355395,
        current_return: 1.0019109862600089,
        initial_entry_price: 5232.9,
        is_closed_position: true,
        miner_hotkey: "5HTVmEBicnSE1iFrSRtYnmyv5bhj6SRmiZHT3Twfbh9jon14",
        net_leverage: 0.0,
        open_ms: 1711547039067,
        orders: [
          {
            leverage: 10.0,
            order_type: "LONG",
            order_uuid: "11a776a5-78b9-4c83-88f1-98397e011ef1",
            price: 5232.9,
            price_sources: [],
            processed_ms: 1711547039066,
          },
          {
            leverage: 10.0,
            order_type: "FLAT",
            order_uuid: "5f1408c1-9f0b-49a7-8560-4563f557cee9",
            price: 5233.9,
            price_sources: [],
            processed_ms: 1711547355395,
          },
        ],
        position_type: "FLAT",
        position_uuid: "d52c15b4-16e2-49ee-b3f8-27e58f95811f",
        return_at_close: 1.0010092663723749,
        trade_pair: ["SPX", "SPX", 9e-5, 0.001, 500],
      },
      {
        average_entry_price: 151.61,
        close_ms: 1711547461372,
        current_return: 1.0000916826066881,
        initial_entry_price: 151.61,
        is_closed_position: true,
        miner_hotkey: "5HTVmEBicnSE1iFrSRtYnmyv5bhj6SRmiZHT3Twfbh9jon14",
        net_leverage: 0.0,
        open_ms: 1711142402063,
        orders: [
          {
            leverage: -0.05,
            order_type: "SHORT",
            order_uuid: "e1734e81-e9db-46e2-b199-b76d76dd8b60",
            price: 151.61,
            price_sources: [],
            processed_ms: 1711142402062,
          },
          {
            leverage: 0.05,
            order_type: "FLAT",
            order_uuid: "d25ca1da-62ac-4a14-a654-ae21f01147eb",
            price: 151.332,
            price_sources: [],
            processed_ms: 1711547461372,
          },
        ],
        position_type: "FLAT",
        position_uuid: "376413b8-654f-4dfd-9157-db88b2b2b611",
        return_at_close: 1.000088182285799,
        trade_pair: ["USDJPY", "USD/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 70543.45,
        close_ms: 1711684088483,
        current_return: 0.9998960357056538,
        initial_entry_price: 70543.45,
        is_closed_position: true,
        miner_hotkey: "5HTVmEBicnSE1iFrSRtYnmyv5bhj6SRmiZHT3Twfbh9jon14",
        net_leverage: 0.0,
        open_ms: 1711682427344,
        orders: [
          {
            leverage: 0.1,
            order_type: "LONG",
            order_uuid: "02f3b3ec-61ad-4297-b0a8-b35b96fa9689",
            price: 70543.45,
            price_sources: [],
            processed_ms: 1711682427340,
          },
          {
            leverage: 0.1,
            order_type: "FLAT",
            order_uuid: "c973fce4-0d06-4570-9369-e0fd93839870",
            price: 70470.11,
            price_sources: [],
            processed_ms: 1711684088483,
          },
        ],
        position_type: "FLAT",
        position_uuid: "23d346d4-938b-48dd-8fd8-81a2f2b61135",
        return_at_close: 0.9997960461020832,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 3557.81,
        close_ms: 1711686882308,
        current_return: 1.0004109269466328,
        initial_entry_price: 3557.81,
        is_closed_position: true,
        miner_hotkey: "5HTVmEBicnSE1iFrSRtYnmyv5bhj6SRmiZHT3Twfbh9jon14",
        net_leverage: 0.0,
        open_ms: 1711683086318,
        orders: [
          {
            leverage: 0.1,
            order_type: "LONG",
            order_uuid: "c9035bc7-e4c4-4a50-bbfb-5214e80d784b",
            price: 3557.81,
            price_sources: [],
            processed_ms: 1711683086317,
          },
          {
            leverage: 0.1,
            order_type: "FLAT",
            order_uuid: "8f69219c-fd7f-4709-9892-16680a16076c",
            price: 3572.43,
            price_sources: [],
            processed_ms: 1711686882308,
          },
        ],
        position_type: "FLAT",
        position_uuid: "a47a5e95-1291-42a5-8e30-4e18fbbf2bb3",
        return_at_close: 1.0003108858539382,
        trade_pair: ["ETHUSD", "ETH/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 151.306,
        close_ms: 1711767495769,
        current_return: 0.9998988804145241,
        initial_entry_price: 151.306,
        is_closed_position: true,
        miner_hotkey: "5HTVmEBicnSE1iFrSRtYnmyv5bhj6SRmiZHT3Twfbh9jon14",
        net_leverage: 0.0,
        open_ms: 1711682370406,
        orders: [
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "8213cd63-9a6e-45c5-84d3-345acfe9106a",
            price: 151.306,
            price_sources: [],
            processed_ms: 1711682370405,
          },
          {
            leverage: 0.3,
            order_type: "FLAT",
            order_uuid: "94f3d36f-f038-40ad-bf64-03c7854415c5",
            price: 151.255,
            price_sources: [],
            processed_ms: 1711767495769,
          },
        ],
        position_type: "FLAT",
        position_uuid: "4be8b5ba-1019-4e2b-9bf4-09fa7f0c32ff",
        return_at_close: 0.9998778825380353,
        trade_pair: ["USDJPY", "USD/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 98.55,
        close_ms: 1711980363328,
        current_return: 0.9999705733130391,
        initial_entry_price: 98.55,
        is_closed_position: true,
        miner_hotkey: "5HTVmEBicnSE1iFrSRtYnmyv5bhj6SRmiZHT3Twfbh9jon14",
        net_leverage: 0.0,
        open_ms: 1711682398638,
        orders: [
          {
            leverage: 0.1,
            order_type: "LONG",
            order_uuid: "4aa2df8a-ccb9-402f-a1ca-60d46420e4bf",
            price: 98.55,
            price_sources: [],
            processed_ms: 1711682398637,
          },
          {
            leverage: 0.1,
            order_type: "FLAT",
            order_uuid: "ef651990-cc83-4f09-b914-7135be94dd6f",
            price: 98.521,
            price_sources: [],
            processed_ms: 1711980363328,
          },
        ],
        position_type: "FLAT",
        position_uuid: "9657ab74-7689-4033-a305-19b559098859",
        return_at_close: 0.9999635735190259,
        trade_pair: ["AUDJPY", "AUD/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 1.0776,
        close_ms: 1711980453935,
        current_return: 0.9993202487008167,
        initial_entry_price: 1.0776,
        is_closed_position: true,
        miner_hotkey: "5HTVmEBicnSE1iFrSRtYnmyv5bhj6SRmiZHT3Twfbh9jon14",
        net_leverage: 0.0,
        open_ms: 1711682307811,
        orders: [
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "f8c8ea6d-f934-4064-ae1a-b28ab72d5fec",
            price: 1.0776,
            price_sources: [],
            processed_ms: 1711682307810,
          },
          {
            leverage: 0.25,
            order_type: "FLAT",
            order_uuid: "8627a75a-16e1-4e4b-b354-4aa0906ba100",
            price: 1.07467,
            price_sources: [],
            processed_ms: 1711980453935,
          },
        ],
        position_type: "FLAT",
        position_uuid: "c8bc3111-27f6-4f93-9696-0f9f692317a7",
        return_at_close: 0.9993027605964644,
        trade_pair: ["EURUSD", "EUR/USD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.97878,
        close_ms: 1712114175551,
        current_return: 1.0000357588017736,
        initial_entry_price: 0.97878,
        is_closed_position: true,
        miner_hotkey: "5HTVmEBicnSE1iFrSRtYnmyv5bhj6SRmiZHT3Twfbh9jon14",
        net_leverage: 0.0,
        open_ms: 1712113774215,
        orders: [
          {
            leverage: 0.5,
            order_type: "LONG",
            order_uuid: "b43464b7-dd40-450e-a5ae-df3da44e0eab",
            price: 0.97878,
            price_sources: [],
            processed_ms: 1712113774213,
          },
          {
            leverage: 0.5,
            order_type: "FLAT",
            order_uuid: "21ab6ead-e54f-46bb-9437-88090b954780",
            price: 0.97885,
            price_sources: [],
            processed_ms: 1712114175551,
          },
        ],
        position_type: "FLAT",
        position_uuid: "0e3d7435-867b-4b8f-b6b0-34fe81e67e20",
        return_at_close: 1.0000007575502157,
        trade_pair: ["EURCHF", "EUR/CHF", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.6516523076923075,
        close_ms: 1712116927628,
        current_return: 1.0102770193576156,
        initial_entry_price: 0.65194,
        is_closed_position: true,
        miner_hotkey: "5HTVmEBicnSE1iFrSRtYnmyv5bhj6SRmiZHT3Twfbh9jon14",
        net_leverage: 0.0,
        open_ms: 1712096260469,
        orders: [
          {
            leverage: 10.0,
            order_type: "LONG",
            order_uuid: "8c22a6cb-8925-42aa-aca1-de40d253f5cd",
            price: 0.65194,
            price_sources: [],
            processed_ms: 1712096258731,
          },
          {
            leverage: 5.0,
            order_type: "LONG",
            order_uuid: "8724fdb6-7d32-4eff-b10a-8823d0dd1cbf",
            price: 0.65189,
            price_sources: [],
            processed_ms: 1712097592040,
          },
          {
            leverage: 5.0,
            order_type: "LONG",
            order_uuid: "e04ab28f-e5e8-462e-81ea-4b8255c7cebb",
            price: 0.65147,
            price_sources: [],
            processed_ms: 1712098584817,
          },
          {
            leverage: 3.0,
            order_type: "LONG",
            order_uuid: "ed09d043-5601-40e6-99e4-0d0cf06af972",
            price: 0.65132,
            price_sources: [],
            processed_ms: 1712099834590,
          },
          {
            leverage: 1.0,
            order_type: "LONG",
            order_uuid: "c41b6d42-23e6-44c1-baa5-b37a0c871e90",
            price: 0.65108,
            price_sources: [],
            processed_ms: 1712105526216,
          },
          {
            leverage: 2.0,
            order_type: "LONG",
            order_uuid: "4de9e8fd-7f5d-490e-ac8f-8fefdcaa3c8a",
            price: 0.65086,
            price_sources: [],
            processed_ms: 1712106120279,
          },
          {
            leverage: 20.0,
            order_type: "FLAT",
            order_uuid: "f263afa5-79c9-4d2f-b96c-7000d0e0d189",
            price: 0.65191,
            price_sources: [],
            processed_ms: 1712116927628,
          },
        ],
        position_type: "FLAT",
        position_uuid: "31596d92-7c66-47e6-8dd0-632aa322399a",
        return_at_close: 1.0084383151823848,
        trade_pair: ["AUDUSD", "AUD/USD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.65231,
        close_ms: 1712117689578,
        current_return: 1.000076650672227,
        initial_entry_price: 0.65231,
        is_closed_position: true,
        miner_hotkey: "5HTVmEBicnSE1iFrSRtYnmyv5bhj6SRmiZHT3Twfbh9jon14",
        net_leverage: 0.0,
        open_ms: 1712117358718,
        orders: [
          {
            leverage: 5.0,
            order_type: "LONG",
            order_uuid: "d6a53421-6b5e-484f-92b1-c7fdfb23bab3",
            price: 0.65231,
            price_sources: [],
            processed_ms: 1712117358717,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "e8af7467-3d21-48f2-a843-bce3c45bddc7",
            price: 0.65232,
            price_sources: [],
            processed_ms: 1712117689578,
          },
        ],
        position_type: "FLAT",
        position_uuid: "7c314560-0eed-4d1a-a9d8-a3a87c70db27",
        return_at_close: 0.9997266238444917,
        trade_pair: ["AUDUSD", "AUD/USD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.65205,
        close_ms: 1712118419675,
        current_return: 1.0000843493597116,
        initial_entry_price: 0.65205,
        is_closed_position: true,
        miner_hotkey: "5HTVmEBicnSE1iFrSRtYnmyv5bhj6SRmiZHT3Twfbh9jon14",
        net_leverage: 0.0,
        open_ms: 1712118088218,
        orders: [
          {
            leverage: 0.5,
            order_type: "LONG",
            order_uuid: "0c7dc645-6340-4f80-97c2-570377005d4a",
            price: 0.65205,
            price_sources: [],
            processed_ms: 1712118088217,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "1c456696-0b3c-4fa1-bbf7-58576f4e68af",
            price: 0.65216,
            price_sources: [],
            processed_ms: 1712118419675,
          },
        ],
        position_type: "FLAT",
        position_uuid: "8ba51d08-98ee-43a4-b6c6-26a182bafcae",
        return_at_close: 1.000049346407484,
        trade_pair: ["AUDUSD", "AUD/USD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.65226,
        close_ms: 1712119156282,
        current_return: 0.9998773495231963,
        initial_entry_price: 0.65226,
        is_closed_position: true,
        miner_hotkey: "5HTVmEBicnSE1iFrSRtYnmyv5bhj6SRmiZHT3Twfbh9jon14",
        net_leverage: 0.0,
        open_ms: 1712119039242,
        orders: [
          {
            leverage: 0.5,
            order_type: "LONG",
            order_uuid: "f7a12123-caf7-43b7-9e23-dd2a3d604fcc",
            price: 0.65226,
            price_sources: [],
            processed_ms: 1712119039241,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "607fe4ac-9579-42db-a78e-a05d14daf1d3",
            price: 0.6521,
            price_sources: [],
            processed_ms: 1712119156282,
          },
        ],
        position_type: "FLAT",
        position_uuid: "be948955-d728-406b-be92-1fe0e46d70d3",
        return_at_close: 0.999842353815963,
        trade_pair: ["AUDUSD", "AUD/USD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.65232,
        close_ms: 1712119819691,
        current_return: 1.0001379690949228,
        initial_entry_price: 0.65232,
        is_closed_position: true,
        miner_hotkey: "5HTVmEBicnSE1iFrSRtYnmyv5bhj6SRmiZHT3Twfbh9jon14",
        net_leverage: 0.0,
        open_ms: 1712119375397,
        orders: [
          {
            leverage: -0.5,
            order_type: "SHORT",
            order_uuid: "9640c628-6867-4ba3-a8f5-a3d1f93d6e3f",
            price: 0.65232,
            price_sources: [],
            processed_ms: 1712119375395,
          },
          {
            leverage: 0.5,
            order_type: "FLAT",
            order_uuid: "5baa3237-bf24-4904-a5c8-0a89805b4d3d",
            price: 0.65214,
            price_sources: [],
            processed_ms: 1712119819691,
          },
        ],
        position_type: "FLAT",
        position_uuid: "c9ec5d39-0e0d-42eb-8be4-7fdcbfd8c9b1",
        return_at_close: 1.0001029642660044,
        trade_pair: ["AUDUSD", "AUD/USD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.651895,
        close_ms: 1712120808601,
        current_return: 1.0001150412614659,
        initial_entry_price: 0.65194,
        is_closed_position: true,
        miner_hotkey: "5HTVmEBicnSE1iFrSRtYnmyv5bhj6SRmiZHT3Twfbh9jon14",
        net_leverage: 0.0,
        open_ms: 1712120322565,
        orders: [
          {
            leverage: -0.5,
            order_type: "SHORT",
            order_uuid: "200b4ef2-4ee7-428d-91fa-500ef3295365",
            price: 0.65194,
            price_sources: [],
            processed_ms: 1712120322564,
          },
          {
            leverage: -0.5,
            order_type: "SHORT",
            order_uuid: "b2a80772-c722-4ce6-baf2-caddf457a425",
            price: 0.65185,
            price_sources: [],
            processed_ms: 1712120462015,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "2346cef4-ce92-4f0d-b661-5e8d3b2e89a6",
            price: 0.65182,
            price_sources: [],
            processed_ms: 1712120808601,
          },
        ],
        position_type: "FLAT",
        position_uuid: "680b1a05-88cb-4457-a649-4e58a1943333",
        return_at_close: 1.0000450332085775,
        trade_pair: ["AUDUSD", "AUD/USD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 91.13100000000001,
        close_ms: 1712166572404,
        current_return: 1.0004608750041153,
        initial_entry_price: 91.131,
        is_closed_position: true,
        miner_hotkey: "5HTVmEBicnSE1iFrSRtYnmyv5bhj6SRmiZHT3Twfbh9jon14",
        net_leverage: 0.0,
        open_ms: 1712165611747,
        orders: [
          {
            leverage: -3.0,
            order_type: "SHORT",
            order_uuid: "e86c4e9a-c4e1-48f4-a28f-a2ce821b0707",
            price: 91.131,
            price_sources: [],
            processed_ms: 1712165611746,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "163bc012-34b9-41c2-bec9-24ec53708ddc",
            price: 91.117,
            price_sources: [],
            processed_ms: 1712166572404,
          },
        ],
        position_type: "FLAT",
        position_uuid: "8c0e3e3c-abfc-4d3c-bc58-de65fdb462fa",
        return_at_close: 1.0002507782203645,
        trade_pair: ["NZDJPY", "NZD/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 91.145,
        close_ms: 1712166848074,
        current_return: 1.0004388611553026,
        initial_entry_price: 91.145,
        is_closed_position: true,
        miner_hotkey: "5HTVmEBicnSE1iFrSRtYnmyv5bhj6SRmiZHT3Twfbh9jon14",
        net_leverage: 0.0,
        open_ms: 1712166762542,
        orders: [
          {
            leverage: 5.0,
            order_type: "LONG",
            order_uuid: "d5f24837-d4c3-447b-b4d8-f700841e178d",
            price: 91.145,
            price_sources: [],
            processed_ms: 1712166762541,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "4f743db1-2292-4ff5-b9ef-44ce6ebabc65",
            price: 91.153,
            price_sources: [],
            processed_ms: 1712166848074,
          },
        ],
        position_type: "FLAT",
        position_uuid: "14020ed9-3daa-4672-b732-9e13ab3c3f8d",
        return_at_close: 1.0000887075538982,
        trade_pair: ["NZDJPY", "NZD/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 91.14,
        close_ms: 1712167334927,
        current_return: 1.0150866798332254,
        initial_entry_price: 91.14,
        is_closed_position: true,
        miner_hotkey: "5HTVmEBicnSE1iFrSRtYnmyv5bhj6SRmiZHT3Twfbh9jon14",
        net_leverage: 0.0,
        open_ms: 1712167082193,
        orders: [
          {
            leverage: -25.0,
            order_type: "SHORT",
            order_uuid: "8f777539-1eca-4775-8135-2c01f306c03c",
            price: 91.14,
            price_sources: [],
            processed_ms: 1712167082191,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "e2ee92b0-2358-4b75-b122-6e7d5abfca15",
            price: 91.085,
            price_sources: [],
            processed_ms: 1712167334927,
          },
        ],
        position_type: "FLAT",
        position_uuid: "d502ca06-c85a-42e0-bbe3-f2f27e899bd1",
        return_at_close: 1.0133102781435173,
        trade_pair: ["NZDJPY", "NZD/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 91.126,
        close_ms: 1712168759190,
        current_return: 0.9974211531286344,
        initial_entry_price: 91.126,
        is_closed_position: true,
        miner_hotkey: "5HTVmEBicnSE1iFrSRtYnmyv5bhj6SRmiZHT3Twfbh9jon14",
        net_leverage: 0.0,
        open_ms: 1712167391656,
        orders: [
          {
            leverage: 5.0,
            order_type: "LONG",
            order_uuid: "e9a0f781-b910-4153-9475-694a81d725ab",
            price: 91.126,
            price_sources: [],
            processed_ms: 1712167391654,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "7fc80552-f96d-4194-b93b-38a07d3e1b49",
            price: 91.079,
            price_sources: [],
            processed_ms: 1712168759190,
          },
        ],
        position_type: "FLAT",
        position_uuid: "9e3338e4-6ae5-4ee6-93c0-3d2cc2293e31",
        return_at_close: 0.9970720557250394,
        trade_pair: ["NZDJPY", "NZD/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.6670999999999999,
        close_ms: 1712171506205,
        current_return: 0.9925787106446765,
        initial_entry_price: 0.667,
        is_closed_position: true,
        miner_hotkey: "5HTVmEBicnSE1iFrSRtYnmyv5bhj6SRmiZHT3Twfbh9jon14",
        net_leverage: 0.0,
        open_ms: 1712169550561,
        orders: [
          {
            leverage: -5.0,
            order_type: "SHORT",
            order_uuid: "d77e7fb1-0f3d-48d7-8294-955f12d7916d",
            price: 0.667,
            price_sources: [],
            processed_ms: 1712169550559,
          },
          {
            leverage: -5.0,
            order_type: "SHORT",
            order_uuid: "7cd342a5-d265-45ea-b89e-d6cce1105337",
            price: 0.667,
            price_sources: [],
            processed_ms: 1712170138670,
          },
          {
            leverage: -5.0,
            order_type: "SHORT",
            order_uuid: "5fac5189-6bee-4a20-ac1a-14d5fd2da2cf",
            price: 0.6673,
            price_sources: [],
            processed_ms: 1712170487427,
          },
          {
            leverage: 5.0,
            order_type: "FLAT",
            order_uuid: "89e4d7ae-a524-4aaa-a8a5-4a1608d0068d",
            price: 0.66743,
            price_sources: [],
            processed_ms: 1712171506205,
          },
        ],
        position_type: "FLAT",
        position_uuid: "9a380f38-55a3-479a-80b4-f5d80162d16e",
        return_at_close: 0.9915365029984996,
        trade_pair: ["CADCHF", "CAD/CHF", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.66733,
        close_ms: 1712171927628,
        current_return: 1.000224776347534,
        initial_entry_price: 0.66733,
        is_closed_position: true,
        miner_hotkey: "5HTVmEBicnSE1iFrSRtYnmyv5bhj6SRmiZHT3Twfbh9jon14",
        net_leverage: 0.0,
        open_ms: 1712171697042,
        orders: [
          {
            leverage: -5.0,
            order_type: "SHORT",
            order_uuid: "de93d9c5-375e-44fa-8d8a-99fc842e185b",
            price: 0.66733,
            price_sources: [],
            processed_ms: 1712171697040,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "84382b00-3fab-4c00-b7b5-f9261a0afda5",
            price: 0.6673,
            price_sources: [],
            processed_ms: 1712171927628,
          },
        ],
        position_type: "FLAT",
        position_uuid: "dc14d691-4f58-41aa-b4ef-7216b789d57e",
        return_at_close: 0.9998746976758125,
        trade_pair: ["CADCHF", "CAD/CHF", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.6675270000000002,
        close_ms: 1712176225800,
        current_return: 1.0009394947406278,
        initial_entry_price: 0.66738,
        is_closed_position: true,
        miner_hotkey: "5HTVmEBicnSE1iFrSRtYnmyv5bhj6SRmiZHT3Twfbh9jon14",
        net_leverage: 0.0,
        open_ms: 1712172729912,
        orders: [
          {
            leverage: -0.1,
            order_type: "SHORT",
            order_uuid: "ca281c56-d691-4f53-8e35-05d5161a20b1",
            price: 0.66738,
            price_sources: [],
            processed_ms: 1712172729910,
          },
          {
            leverage: -0.1,
            order_type: "SHORT",
            order_uuid: "b4cc441c-33f7-4ad8-87cd-0eaf829af346",
            price: 0.6671,
            price_sources: [],
            processed_ms: 1712172807402,
          },
          {
            leverage: -0.1,
            order_type: "SHORT",
            order_uuid: "1a331144-449b-4a18-afb0-75681eb3f95b",
            price: 0.66754,
            price_sources: [],
            processed_ms: 1712173388871,
          },
          {
            leverage: -0.1,
            order_type: "SHORT",
            order_uuid: "33ae58c5-9a98-4b45-b9d3-720d9db13304",
            price: 0.66764,
            price_sources: [],
            processed_ms: 1712173476271,
          },
          {
            leverage: -0.1,
            order_type: "SHORT",
            order_uuid: "f55e563a-8b3b-4861-8eba-612ec967dbdd",
            price: 0.6671,
            price_sources: [],
            processed_ms: 1712173536564,
          },
          {
            leverage: -0.1,
            order_type: "SHORT",
            order_uuid: "baba3d63-4bb5-484e-b67d-5752a5dc7779",
            price: 0.66734,
            price_sources: [],
            processed_ms: 1712175230720,
          },
          {
            leverage: -0.1,
            order_type: "SHORT",
            order_uuid: "9899eec8-7b24-4901-986e-c865df1cd270",
            price: 0.6691,
            price_sources: [],
            processed_ms: 1712175381858,
          },
          {
            leverage: -0.1,
            order_type: "SHORT",
            order_uuid: "8ce29d87-306d-4951-827e-0c18bc6ee88c",
            price: 0.66744,
            price_sources: [],
            processed_ms: 1712175506860,
          },
          {
            leverage: -0.1,
            order_type: "SHORT",
            order_uuid: "6481fb5b-80b6-4cf2-af0c-ba051bdd1aae",
            price: 0.6672,
            price_sources: [],
            processed_ms: 1712175629251,
          },
          {
            leverage: -0.1,
            order_type: "SHORT",
            order_uuid: "0144209b-6795-42ce-8dac-56e67e09f1d4",
            price: 0.66743,
            price_sources: [],
            processed_ms: 1712175785248,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "bf4e9077-cd25-4f14-af15-f47d25b3f635",
            price: 0.6669,
            price_sources: [],
            processed_ms: 1712176225800,
          },
        ],
        position_type: "FLAT",
        position_uuid: "9aee4604-770b-4a93-ad08-5896a26bb90f",
        return_at_close: 1.000869428975996,
        trade_pair: ["CADCHF", "CAD/CHF", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.6675,
        close_ms: 1712176706468,
        current_return: 0.9999101123595505,
        initial_entry_price: 0.6675,
        is_closed_position: true,
        miner_hotkey: "5HTVmEBicnSE1iFrSRtYnmyv5bhj6SRmiZHT3Twfbh9jon14",
        net_leverage: 0.0,
        open_ms: 1712176478958,
        orders: [
          {
            leverage: -0.5,
            order_type: "SHORT",
            order_uuid: "aec5d4ff-0268-4af5-830f-7eaef9acce56",
            price: 0.6675,
            price_sources: [],
            processed_ms: 1712176478956,
          },
          {
            leverage: 0.5,
            order_type: "FLAT",
            order_uuid: "7f6bbcfe-944c-4b75-9eb9-f394780293ea",
            price: 0.66762,
            price_sources: [],
            processed_ms: 1712176706468,
          },
        ],
        position_type: "FLAT",
        position_uuid: "9da82461-b937-4122-a134-67cd47912972",
        return_at_close: 0.9998751155056179,
        trade_pair: ["CADCHF", "CAD/CHF", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.66752,
        close_ms: 1712177217893,
        current_return: 0.9984419942473634,
        initial_entry_price: 0.66752,
        is_closed_position: true,
        miner_hotkey: "5HTVmEBicnSE1iFrSRtYnmyv5bhj6SRmiZHT3Twfbh9jon14",
        net_leverage: 0.0,
        open_ms: 1712176955344,
        orders: [
          {
            leverage: -0.5,
            order_type: "SHORT",
            order_uuid: "809294ae-7f1b-4052-9324-a5119afcaa82",
            price: 0.66752,
            price_sources: [],
            processed_ms: 1712176955342,
          },
          {
            leverage: 0.5,
            order_type: "FLAT",
            order_uuid: "0b209893-ef42-4888-96cb-23b13252f27e",
            price: 0.6696,
            price_sources: [],
            processed_ms: 1712177217893,
          },
        ],
        position_type: "FLAT",
        position_uuid: "efc16134-f4bc-4fbe-bb15-58dce4dfa4ac",
        return_at_close: 0.9984070487775647,
        trade_pair: ["CADCHF", "CAD/CHF", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.6671,
        close_ms: 1712178046581,
        current_return: 0.9981262179583271,
        initial_entry_price: 0.6671,
        is_closed_position: true,
        miner_hotkey: "5HTVmEBicnSE1iFrSRtYnmyv5bhj6SRmiZHT3Twfbh9jon14",
        net_leverage: 0.0,
        open_ms: 1712177950144,
        orders: [
          {
            leverage: -0.5,
            order_type: "SHORT",
            order_uuid: "2c22046c-075b-45d6-848c-d5d9aaa99f9d",
            price: 0.6671,
            price_sources: [],
            processed_ms: 1712177950142,
          },
          {
            leverage: 0.5,
            order_type: "FLAT",
            order_uuid: "5a06e812-3396-4a69-8fab-f96c6b2cebfa",
            price: 0.6696,
            price_sources: [],
            processed_ms: 1712178046581,
          },
        ],
        position_type: "FLAT",
        position_uuid: "98d85fd4-3360-423d-aa7f-fb1bd6a2b73d",
        return_at_close: 0.9980912835406985,
        trade_pair: ["CADCHF", "CAD/CHF", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.6695,
        close_ms: 1712178354668,
        current_return: 0.9635548917102316,
        initial_entry_price: 0.6695,
        is_closed_position: true,
        miner_hotkey: "5HTVmEBicnSE1iFrSRtYnmyv5bhj6SRmiZHT3Twfbh9jon14",
        net_leverage: 0.0,
        open_ms: 1712178274144,
        orders: [
          {
            leverage: 10.0,
            order_type: "LONG",
            order_uuid: "6d86952b-f35d-4a4e-8b47-84872119bfe4",
            price: 0.6695,
            price_sources: [],
            processed_ms: 1712178274141,
          },
          {
            leverage: 10.0,
            order_type: "FLAT",
            order_uuid: "4d3cfe80-f180-4716-bd9c-b79d9ecc6f33",
            price: 0.66706,
            price_sources: [],
            processed_ms: 1712178354668,
          },
        ],
        position_type: "FLAT",
        position_uuid: "ec97c844-9132-4ae3-bc47-60d5b336eb10",
        return_at_close: 0.9628804032860344,
        trade_pair: ["CADCHF", "CAD/CHF", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.6671,
        close_ms: 1712178600920,
        current_return: 0.966721630939889,
        initial_entry_price: 0.6671,
        is_closed_position: true,
        miner_hotkey: "5HTVmEBicnSE1iFrSRtYnmyv5bhj6SRmiZHT3Twfbh9jon14",
        net_leverage: 0.0,
        open_ms: 1712178526914,
        orders: [
          {
            leverage: -10.0,
            order_type: "SHORT",
            order_uuid: "28fb38a3-6c3a-4b1a-b0d1-4d351c43b304",
            price: 0.6671,
            price_sources: [],
            processed_ms: 1712178526911,
          },
          {
            leverage: 10.0,
            order_type: "FLAT",
            order_uuid: "d92858bf-2001-4bbf-9936-5c06784a399f",
            price: 0.66932,
            price_sources: [],
            processed_ms: 1712178600920,
          },
        ],
        position_type: "FLAT",
        position_uuid: "42cf1da9-5be9-4283-908a-d36a4cd14314",
        return_at_close: 0.9660449257982311,
        trade_pair: ["CADCHF", "CAD/CHF", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.6671,
        close_ms: 1712178761016,
        current_return: 1.001049317943337,
        initial_entry_price: 0.6671,
        is_closed_position: true,
        miner_hotkey: "5HTVmEBicnSE1iFrSRtYnmyv5bhj6SRmiZHT3Twfbh9jon14",
        net_leverage: 0.0,
        open_ms: 1712178668109,
        orders: [
          {
            leverage: 10.0,
            order_type: "LONG",
            order_uuid: "9dc23ea2-5998-41b8-9fb3-11f3034db2fa",
            price: 0.6671,
            price_sources: [],
            processed_ms: 1712178668107,
          },
          {
            leverage: 10.0,
            order_type: "FLAT",
            order_uuid: "ea546e39-451f-443d-9198-2020651b9dac",
            price: 0.66717,
            price_sources: [],
            processed_ms: 1712178761016,
          },
        ],
        position_type: "FLAT",
        position_uuid: "7f773f61-3b2b-4e0a-8b3e-c73a5351da5d",
        return_at_close: 1.0003485834207766,
        trade_pair: ["CADCHF", "CAD/CHF", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.66829,
        close_ms: 1712179881536,
        current_return: 1.0028387867921709,
        initial_entry_price: 0.6693,
        is_closed_position: true,
        miner_hotkey: "5HTVmEBicnSE1iFrSRtYnmyv5bhj6SRmiZHT3Twfbh9jon14",
        net_leverage: 0.0,
        open_ms: 1712178887899,
        orders: [
          {
            leverage: -1.0,
            order_type: "SHORT",
            order_uuid: "ebcf1c18-04c6-47d7-9842-4656eb383457",
            price: 0.6693,
            price_sources: [],
            processed_ms: 1712178887897,
          },
          {
            leverage: -1.0,
            order_type: "SHORT",
            order_uuid: "f0330f08-75d5-4907-8b79-5afe0c5a8ea1",
            price: 0.66728,
            price_sources: [],
            processed_ms: 1712179196532,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "9ab0f64f-ff33-44d7-bfe6-7f5ef1b71e08",
            price: 0.66734,
            price_sources: [],
            processed_ms: 1712179881536,
          },
        ],
        position_type: "FLAT",
        position_uuid: "ad6c8731-d845-4802-8e70-c2f674b40423",
        return_at_close: 1.0026983893620198,
        trade_pair: ["CADCHF", "CAD/CHF", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.6691,
        close_ms: 1712180395412,
        current_return: 1.0032879988043641,
        initial_entry_price: 0.6691,
        is_closed_position: true,
        miner_hotkey: "5HTVmEBicnSE1iFrSRtYnmyv5bhj6SRmiZHT3Twfbh9jon14",
        net_leverage: 0.0,
        open_ms: 1712180281340,
        orders: [
          {
            leverage: -1.0,
            order_type: "SHORT",
            order_uuid: "0fe1217b-a18f-430f-9680-50dae7e36b76",
            price: 0.6691,
            price_sources: [],
            processed_ms: 1712180281338,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "57333f8f-78a8-4c40-b5df-ef0c24bdf439",
            price: 0.6669,
            price_sources: [],
            processed_ms: 1712180395412,
          },
        ],
        position_type: "FLAT",
        position_uuid: "3abd0149-ab80-451d-a0f4-1ebea70ef18d",
        return_at_close: 1.003217768644448,
        trade_pair: ["CADCHF", "CAD/CHF", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.66722,
        close_ms: 1712180996824,
        current_return: 0.9970324630556638,
        initial_entry_price: 0.66722,
        is_closed_position: true,
        miner_hotkey: "5HTVmEBicnSE1iFrSRtYnmyv5bhj6SRmiZHT3Twfbh9jon14",
        net_leverage: 0.0,
        open_ms: 1712180557014,
        orders: [
          {
            leverage: -1.0,
            order_type: "SHORT",
            order_uuid: "cbf4cfd7-290a-459c-bf29-8f0d7e5ba3f6",
            price: 0.66722,
            price_sources: [],
            processed_ms: 1712180557012,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "1b29b019-8348-4b21-bbbc-bc8b3a396f9f",
            price: 0.6692,
            price_sources: [],
            processed_ms: 1712180996824,
          },
        ],
        position_type: "FLAT",
        position_uuid: "cc27b8ce-a4ae-4c16-bd83-fb90bf88cda5",
        return_at_close: 0.99696267078325,
        trade_pair: ["CADCHF", "CAD/CHF", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.66741,
        close_ms: 1712181932459,
        current_return: 0.9974678233769346,
        initial_entry_price: 0.66741,
        is_closed_position: true,
        miner_hotkey: "5HTVmEBicnSE1iFrSRtYnmyv5bhj6SRmiZHT3Twfbh9jon14",
        net_leverage: 0.0,
        open_ms: 1712181636364,
        orders: [
          {
            leverage: -1.0,
            order_type: "SHORT",
            order_uuid: "4da7e9ce-d5d7-4d65-a3eb-bf460376a00b",
            price: 0.66741,
            price_sources: [],
            processed_ms: 1712181636362,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "7c8c56a6-0092-439a-b94a-e4d3ab4d7514",
            price: 0.6691,
            price_sources: [],
            processed_ms: 1712181932459,
          },
        ],
        position_type: "FLAT",
        position_uuid: "a76bb24a-b216-47a0-a19b-91d3956493a3",
        return_at_close: 0.9973980006292982,
        trade_pair: ["CADCHF", "CAD/CHF", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.6695,
        close_ms: 1712184811083,
        current_return: 1.00336071695295,
        initial_entry_price: 0.6695,
        is_closed_position: true,
        miner_hotkey: "5HTVmEBicnSE1iFrSRtYnmyv5bhj6SRmiZHT3Twfbh9jon14",
        net_leverage: 0.0,
        open_ms: 1712184595292,
        orders: [
          {
            leverage: -1.0,
            order_type: "SHORT",
            order_uuid: "89359896-7e2a-4fae-b045-603ef16b9849",
            price: 0.6695,
            price_sources: [],
            processed_ms: 1712184595289,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "857bf96d-95f4-4b8e-b6bf-2ba6b66db087",
            price: 0.66725,
            price_sources: [],
            processed_ms: 1712184811083,
          },
        ],
        position_type: "FLAT",
        position_uuid: "93e92248-21db-412a-aefc-77cd051ce70a",
        return_at_close: 1.0032904817027632,
        trade_pair: ["CADCHF", "CAD/CHF", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.6694999999999999,
        close_ms: 1712185347346,
        current_return: 1.009858103061986,
        initial_entry_price: 0.6695,
        is_closed_position: true,
        miner_hotkey: "5HTVmEBicnSE1iFrSRtYnmyv5bhj6SRmiZHT3Twfbh9jon14",
        net_leverage: 0.0,
        open_ms: 1712185146251,
        orders: [
          {
            leverage: -3.0,
            order_type: "SHORT",
            order_uuid: "2fc1cc55-6ee0-42e0-9bb3-3739211ec13c",
            price: 0.6695,
            price_sources: [],
            processed_ms: 1712185146248,
          },
          {
            leverage: 3.0,
            order_type: "FLAT",
            order_uuid: "65b96ef4-cc50-4a5d-aac2-17fb3db74058",
            price: 0.6673,
            price_sources: [],
            processed_ms: 1712185347346,
          },
        ],
        position_type: "FLAT",
        position_uuid: "f3397682-60f0-490b-bbf9-85b015cd405e",
        return_at_close: 1.009646032860343,
        trade_pair: ["CADCHF", "CAD/CHF", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.6672466666666668,
        close_ms: 1712186605944,
        current_return: 0.9964485337089602,
        initial_entry_price: 0.66733,
        is_closed_position: true,
        miner_hotkey: "5HTVmEBicnSE1iFrSRtYnmyv5bhj6SRmiZHT3Twfbh9jon14",
        net_leverage: 0.0,
        open_ms: 1712185831943,
        orders: [
          {
            leverage: -3.0,
            order_type: "SHORT",
            order_uuid: "2424ef96-ba5d-41aa-9233-132007bcfc0d",
            price: 0.66733,
            price_sources: [],
            processed_ms: 1712185831941,
          },
          {
            leverage: -3.0,
            order_type: "SHORT",
            order_uuid: "2277e21c-2394-4666-b451-09d0ae721817",
            price: 0.66741,
            price_sources: [],
            processed_ms: 1712186134665,
          },
          {
            leverage: -3.0,
            order_type: "SHORT",
            order_uuid: "0b6ead2b-83d9-44ac-b2c2-917935d880bc",
            price: 0.667,
            price_sources: [],
            processed_ms: 1712186486908,
          },
          {
            leverage: 3.0,
            order_type: "FLAT",
            order_uuid: "953f526e-590b-4f2f-ac55-840490ba8b38",
            price: 0.66751,
            price_sources: [],
            processed_ms: 1712186605944,
          },
        ],
        position_type: "FLAT",
        position_uuid: "58b5d4e8-6d91-4749-9c76-354eb29cca1d",
        return_at_close: 0.9958207711327236,
        trade_pair: ["CADCHF", "CAD/CHF", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.667615,
        close_ms: 1712187153453,
        current_return: 1.0028311637730871,
        initial_entry_price: 0.66757,
        is_closed_position: true,
        miner_hotkey: "5HTVmEBicnSE1iFrSRtYnmyv5bhj6SRmiZHT3Twfbh9jon14",
        net_leverage: 0.0,
        open_ms: 1712186678855,
        orders: [
          {
            leverage: -3.0,
            order_type: "SHORT",
            order_uuid: "54d498b9-5fe1-433b-bcac-d0e1c292986c",
            price: 0.66757,
            price_sources: [],
            processed_ms: 1712186678852,
          },
          {
            leverage: -3.0,
            order_type: "SHORT",
            order_uuid: "a903828e-310d-4931-90f8-086c510f662a",
            price: 0.66766,
            price_sources: [],
            processed_ms: 1712187082215,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "fe53b793-0392-4c52-a805-20b0d54a831b",
            price: 0.6673,
            price_sources: [],
            processed_ms: 1712187153453,
          },
        ],
        position_type: "FLAT",
        position_uuid: "a1ecd663-337d-41c8-9969-ae08630dcb47",
        return_at_close: 1.0024099746843025,
        trade_pair: ["CADCHF", "CAD/CHF", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.66763,
        close_ms: 1712187395656,
        current_return: 1.0001048484939261,
        initial_entry_price: 0.66763,
        is_closed_position: true,
        miner_hotkey: "5HTVmEBicnSE1iFrSRtYnmyv5bhj6SRmiZHT3Twfbh9jon14",
        net_leverage: 0.0,
        open_ms: 1712187316692,
        orders: [
          {
            leverage: -1.0,
            order_type: "SHORT",
            order_uuid: "adc0ac6d-b1e1-4c9e-a929-0dbb5e1862bf",
            price: 0.66763,
            price_sources: [],
            processed_ms: 1712187316689,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "54d58512-0df6-4ede-8aac-c2bbc76d97c6",
            price: 0.66756,
            price_sources: [],
            processed_ms: 1712187395656,
          },
        ],
        position_type: "FLAT",
        position_uuid: "afd777c3-bb77-4df8-8a7f-c904f3cb0370",
        return_at_close: 1.0000348411545315,
        trade_pair: ["CADCHF", "CAD/CHF", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.66759,
        close_ms: 1712188116562,
        current_return: 1.0,
        initial_entry_price: 0.66759,
        is_closed_position: true,
        miner_hotkey: "5HTVmEBicnSE1iFrSRtYnmyv5bhj6SRmiZHT3Twfbh9jon14",
        net_leverage: 0.0,
        open_ms: 1712187858467,
        orders: [
          {
            leverage: 1.0,
            order_type: "LONG",
            order_uuid: "c7146ac3-d54c-4112-b62e-c6e35a1e8c2e",
            price: 0.66759,
            price_sources: [],
            processed_ms: 1712187858464,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "cab6e99c-41d0-4182-abac-3942975285bc",
            price: 0.66759,
            price_sources: [],
            processed_ms: 1712188116562,
          },
        ],
        position_type: "FLAT",
        position_uuid: "5c2c1b05-292b-4c4e-8651-7b2e72bdfbf8",
        return_at_close: 0.99993,
        trade_pair: ["CADCHF", "CAD/CHF", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.6699,
        close_ms: 1712191499011,
        current_return: 0.9346171070308994,
        initial_entry_price: 0.6699,
        is_closed_position: true,
        miner_hotkey: "5HTVmEBicnSE1iFrSRtYnmyv5bhj6SRmiZHT3Twfbh9jon14",
        net_leverage: 0.0,
        open_ms: 1712190919796,
        orders: [
          {
            leverage: 20.0,
            order_type: "LONG",
            order_uuid: "d4c9de2f-3d17-4312-bf36-5f06902bafb1",
            price: 0.6699,
            price_sources: [],
            processed_ms: 1712190919793,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "6a7270b2-0a8d-40f5-996d-b540304ff2a4",
            price: 0.66771,
            price_sources: [],
            processed_ms: 1712191499011,
          },
        ],
        position_type: "FLAT",
        position_uuid: "188be31e-290d-4e96-9310-bb2c6e520b68",
        return_at_close: 0.9333086430810562,
        trade_pair: ["CADCHF", "CAD/CHF", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.668,
        close_ms: 1712209314966,
        current_return: 1.0007784431137725,
        initial_entry_price: 0.668,
        is_closed_position: true,
        miner_hotkey: "5HTVmEBicnSE1iFrSRtYnmyv5bhj6SRmiZHT3Twfbh9jon14",
        net_leverage: 0.0,
        open_ms: 1712208921765,
        orders: [
          {
            leverage: 1.0,
            order_type: "LONG",
            order_uuid: "3e7431a5-e3e6-4ff0-9f1e-5c2de4dc2e87",
            price: 0.668,
            price_sources: [],
            processed_ms: 1712208921763,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "92885b29-7c34-42aa-8f78-0da1eea2b771",
            price: 0.66852,
            price_sources: [],
            processed_ms: 1712209314966,
          },
        ],
        position_type: "FLAT",
        position_uuid: "301f95d5-a2c7-437e-8e4f-205ba93cfbf8",
        return_at_close: 1.0007083886227546,
        trade_pair: ["CADCHF", "CAD/CHF", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.6683,
        close_ms: 1712209912093,
        current_return: 0.9998503666018256,
        initial_entry_price: 0.6683,
        is_closed_position: true,
        miner_hotkey: "5HTVmEBicnSE1iFrSRtYnmyv5bhj6SRmiZHT3Twfbh9jon14",
        net_leverage: 0.0,
        open_ms: 1712209823071,
        orders: [
          {
            leverage: 1.0,
            order_type: "LONG",
            order_uuid: "b469bd5e-30d3-4736-add4-1abf20b0e87c",
            price: 0.6683,
            price_sources: [],
            processed_ms: 1712209823068,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "ce79f501-2bf1-40e4-b425-30858c349e1a",
            price: 0.6682,
            price_sources: [],
            processed_ms: 1712209912093,
          },
        ],
        position_type: "FLAT",
        position_uuid: "45a635e7-dd46-45c6-9e33-5daf2d059dba",
        return_at_close: 0.9997803770761634,
        trade_pair: ["CADCHF", "CAD/CHF", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.66816,
        close_ms: 1712239577628,
        current_return: 1.0440881763527057,
        initial_entry_price: 0.66866,
        is_closed_position: true,
        miner_hotkey: "5HTVmEBicnSE1iFrSRtYnmyv5bhj6SRmiZHT3Twfbh9jon14",
        net_leverage: 0.0,
        open_ms: 1712210003350,
        orders: [
          {
            leverage: 1.0,
            order_type: "LONG",
            order_uuid: "7e3b38bc-b253-4e1a-b90b-b9b7f7393d39",
            price: 0.66866,
            price_sources: [],
            processed_ms: 1712210003347,
          },
          {
            leverage: 10.0,
            order_type: "LONG",
            order_uuid: "ce64e881-615d-4254-9ce7-687540211208",
            price: 0.66811,
            price_sources: [],
            processed_ms: 1712211698180,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "e7b5e6af-6172-4eee-99d3-549865059b14",
            price: 0.67084,
            price_sources: [],
            processed_ms: 1712239577628,
          },
        ],
        position_type: "FLAT",
        position_uuid: "c92eaa37-eb48-438c-99c2-593f92f607d9",
        return_at_close: 1.0432842284569142,
        trade_pair: ["CADCHF", "CAD/CHF", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.659955,
        close_ms: 1712615889257,
        current_return: 1.0040356101026287,
        initial_entry_price: 0.66161,
        is_closed_position: true,
        miner_hotkey: "5HTVmEBicnSE1iFrSRtYnmyv5bhj6SRmiZHT3Twfbh9jon14",
        net_leverage: 0.0,
        open_ms: 1712253423908,
        orders: [
          {
            leverage: 3.0,
            order_type: "LONG",
            order_uuid: "4255a965-c24a-4097-b3c5-e150aab8b961",
            price: 0.66161,
            price_sources: [],
            processed_ms: 1712253423905,
          },
          {
            leverage: 3.0,
            order_type: "LONG",
            order_uuid: "7056da3e-fa17-4fcb-b1fc-6184ec8d3445",
            price: 0.6583,
            price_sources: [],
            processed_ms: 1712262810141,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "7e58c3e6-fd97-4adc-a1d6-9c65b0baf958",
            price: 0.6604,
            price_sources: [],
            processed_ms: 1712615889257,
          },
        ],
        position_type: "FLAT",
        position_uuid: "10cb371b-b6e5-4240-af04-7fad2fc3c417",
        return_at_close: 1.0036139151463856,
        trade_pair: ["AUDUSD", "AUD/USD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.66625,
        close_ms: 1712617138438,
        current_return: 1.0080156109276488,
        initial_entry_price: 0.6662,
        is_closed_position: true,
        miner_hotkey: "5HTVmEBicnSE1iFrSRtYnmyv5bhj6SRmiZHT3Twfbh9jon14",
        net_leverage: 0.0,
        open_ms: 1712616498937,
        orders: [
          {
            leverage: 3.0,
            order_type: "LONG",
            order_uuid: "78279c31-6351-45dd-be6c-b929aa11e855",
            price: 0.6662,
            price_sources: [],
            processed_ms: 1712616494745,
          },
          {
            leverage: 3.0,
            order_type: "LONG",
            order_uuid: "ea06630e-8099-4f4b-aeda-cf7e6a94a18a",
            price: 0.6663,
            price_sources: [],
            processed_ms: 1712616856433,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "9fc94809-d3a6-4a5f-b4bb-92e478eb516f",
            price: 0.66714,
            price_sources: [],
            processed_ms: 1712617138438,
          },
        ],
        position_type: "FLAT",
        position_uuid: "1d290c93-a4ef-4a0e-83aa-f00af8611437",
        return_at_close: 1.0075922443710592,
        trade_pair: ["CADCHF", "CAD/CHF", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.6662,
        close_ms: 1712617778340,
        current_return: 1.0042779945962175,
        initial_entry_price: 0.6662,
        is_closed_position: true,
        miner_hotkey: "5HTVmEBicnSE1iFrSRtYnmyv5bhj6SRmiZHT3Twfbh9jon14",
        net_leverage: 0.0,
        open_ms: 1712617214977,
        orders: [
          {
            leverage: 3.0,
            order_type: "LONG",
            order_uuid: "d1a6d456-1cec-4e73-a562-a2a79e23ec1c",
            price: 0.6662,
            price_sources: [],
            processed_ms: 1712617214974,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "083d316f-fd42-4e12-b771-099d9206adc9",
            price: 0.66715,
            price_sources: [],
            processed_ms: 1712617778340,
          },
        ],
        position_type: "FLAT",
        position_uuid: "63863490-a17f-4443-887a-620a65781381",
        return_at_close: 1.0040670962173521,
        trade_pair: ["CADCHF", "CAD/CHF", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.66681,
        close_ms: 1712621292313,
        current_return: 1.0002999355138649,
        initial_entry_price: 0.66681,
        is_closed_position: true,
        miner_hotkey: "5HTVmEBicnSE1iFrSRtYnmyv5bhj6SRmiZHT3Twfbh9jon14",
        net_leverage: 0.0,
        open_ms: 1712621170227,
        orders: [
          {
            leverage: 10.0,
            order_type: "LONG",
            order_uuid: "79534677-dda4-43bb-a081-0f4d370004c8",
            price: 0.66681,
            price_sources: [],
            processed_ms: 1712621170224,
          },
          {
            leverage: 10.0,
            order_type: "FLAT",
            order_uuid: "ea73a790-5c28-42aa-b8bd-2c25f455dcf9",
            price: 0.66683,
            price_sources: [],
            processed_ms: 1712621292313,
          },
        ],
        position_type: "FLAT",
        position_uuid: "f66d643a-fcdd-4078-ab49-d608ad4f591b",
        return_at_close: 0.9995997255590051,
        trade_pair: ["CADCHF", "CAD/CHF", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.66698,
        close_ms: 1712626341416,
        current_return: 0.9977510570032088,
        initial_entry_price: 0.66698,
        is_closed_position: true,
        miner_hotkey: "5HTVmEBicnSE1iFrSRtYnmyv5bhj6SRmiZHT3Twfbh9jon14",
        net_leverage: 0.0,
        open_ms: 1712626038159,
        orders: [
          {
            leverage: 30.0,
            order_type: "LONG",
            order_uuid: "e36b16dd-b997-432b-9800-aad07db5969e",
            price: 0.66698,
            price_sources: [],
            processed_ms: 1712626034005,
          },
          {
            leverage: 30.0,
            order_type: "FLAT",
            order_uuid: "1078cdf5-e72c-4f6e-bfc9-4036f603c660",
            price: 0.66693,
            price_sources: [],
            processed_ms: 1712626341416,
          },
        ],
        position_type: "FLAT",
        position_uuid: "6b6fcbd9-22ac-4045-bb87-11497ee7553d",
        return_at_close: 0.9956557797835021,
        trade_pair: ["CADCHF", "CAD/CHF", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.6668666666666667,
        close_ms: 1712631375390,
        current_return: 0.9181286549707482,
        initial_entry_price: 0.6669,
        is_closed_position: true,
        miner_hotkey: "5HTVmEBicnSE1iFrSRtYnmyv5bhj6SRmiZHT3Twfbh9jon14",
        net_leverage: 0.0,
        open_ms: 1712630713470,
        orders: [
          {
            leverage: 30.0,
            order_type: "LONG",
            order_uuid: "4d12309e-45a0-4f25-971d-29a91b3aa959",
            price: 0.6669,
            price_sources: [],
            processed_ms: 1712630713467,
          },
          {
            leverage: 30.0,
            order_type: "LONG",
            order_uuid: "173d7c7a-a031-4dfc-b5bb-d3450c056eeb",
            price: 0.6668,
            price_sources: [],
            processed_ms: 1712630894544,
          },
          {
            leverage: 30.0,
            order_type: "LONG",
            order_uuid: "3905a281-36b2-4e2a-8019-45ad24daf366",
            price: 0.6669,
            price_sources: [],
            processed_ms: 1712631073400,
          },
          {
            leverage: 30.0,
            order_type: "FLAT",
            order_uuid: "91fb89ce-604e-4fdc-ae13-ea1db299c613",
            price: 0.66626,
            price_sources: [],
            processed_ms: 1712631375390,
          },
        ],
        position_type: "FLAT",
        position_uuid: "a0cdad3f-cb83-47a2-b93b-6d19b8280263",
        return_at_close: 0.9123444444444325,
        trade_pair: ["CADCHF", "CAD/CHF", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.6659642857142857,
        close_ms: 1712635892252,
        current_return: 0.9797473671577577,
        initial_entry_price: 0.66658,
        is_closed_position: true,
        miner_hotkey: "5HTVmEBicnSE1iFrSRtYnmyv5bhj6SRmiZHT3Twfbh9jon14",
        net_leverage: 0.0,
        open_ms: 1712634672099,
        orders: [
          {
            leverage: 30.0,
            order_type: "LONG",
            order_uuid: "1904920d-e196-43f2-a94c-d1fd7fc9d20d",
            price: 0.66658,
            price_sources: [],
            processed_ms: 1712634672095,
          },
          {
            leverage: 30.0,
            order_type: "LONG",
            order_uuid: "1b91f39e-f187-4fa8-bd12-2e960514175b",
            price: 0.6658,
            price_sources: [],
            processed_ms: 1712634854069,
          },
          {
            leverage: 30.0,
            order_type: "LONG",
            order_uuid: "94ce3da2-d1b8-45e2-8e96-8db7ba69247b",
            price: 0.6656,
            price_sources: [],
            processed_ms: 1712635031175,
          },
          {
            leverage: 30.0,
            order_type: "LONG",
            order_uuid: "e11cc1cf-5a7d-496c-94ba-390d3ee51d3f",
            price: 0.6657,
            price_sources: [],
            processed_ms: 1712635211669,
          },
          {
            leverage: 30.0,
            order_type: "LONG",
            order_uuid: "df4dfe20-38e0-423f-87da-e54076dee0be",
            price: 0.66617,
            price_sources: [],
            processed_ms: 1712635393207,
          },
          {
            leverage: 30.0,
            order_type: "LONG",
            order_uuid: "020ccded-f485-4849-88e2-02921659e60c",
            price: 0.6659,
            price_sources: [],
            processed_ms: 1712635571399,
          },
          {
            leverage: 30.0,
            order_type: "LONG",
            order_uuid: "626d9a70-a37a-46a9-932b-259261cada24",
            price: 0.666,
            price_sources: [],
            processed_ms: 1712635753639,
          },
          {
            leverage: 30.0,
            order_type: "FLAT",
            order_uuid: "38628630-2399-4c45-a3aa-ed001c91a44c",
            price: 0.6659,
            price_sources: [],
            processed_ms: 1712635892252,
          },
        ],
        position_type: "FLAT",
        position_uuid: "aedde5b9-1847-4d18-afda-556bd44028d7",
        return_at_close: 0.9653450808605386,
        trade_pair: ["CADCHF", "CAD/CHF", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.6648499999999999,
        close_ms: 1712678650460,
        current_return: 0.9984959013311342,
        initial_entry_price: 0.66485,
        is_closed_position: true,
        miner_hotkey: "5HTVmEBicnSE1iFrSRtYnmyv5bhj6SRmiZHT3Twfbh9jon14",
        net_leverage: 0.0,
        open_ms: 1712678342637,
        orders: [
          {
            leverage: 100.0,
            order_type: "LONG",
            order_uuid: "48a4d2cd-0135-41c7-b733-40dd0d32403b",
            price: 0.66485,
            price_sources: [],
            processed_ms: 1712678342634,
          },
          {
            leverage: 100.0,
            order_type: "FLAT",
            order_uuid: "9b9f1868-5a06-4b43-a8ec-7bd02feacf3e",
            price: 0.66484,
            price_sources: [],
            processed_ms: 1712678650460,
          },
        ],
        position_type: "FLAT",
        position_uuid: "8263838f-f9c7-4de8-8497-d1dade6ba285",
        return_at_close: 0.9915064300218163,
        trade_pair: ["CADCHF", "CAD/CHF", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.6648,
        close_ms: 1712683098488,
        current_return: 1.0361010830324937,
        initial_entry_price: 0.6648,
        is_closed_position: true,
        miner_hotkey: "5HTVmEBicnSE1iFrSRtYnmyv5bhj6SRmiZHT3Twfbh9jon14",
        net_leverage: 0.0,
        open_ms: 1712682794471,
        orders: [
          {
            leverage: 100.0,
            order_type: "LONG",
            order_uuid: "ca8ad7b9-938f-4d8e-a30c-c1c7f15e4737",
            price: 0.6648,
            price_sources: [],
            processed_ms: 1712682794468,
          },
          {
            leverage: 100.0,
            order_type: "FLAT",
            order_uuid: "eb00f215-4beb-4c6d-913a-b4a206cbf571",
            price: 0.66504,
            price_sources: [],
            processed_ms: 1712683098488,
          },
        ],
        position_type: "FLAT",
        position_uuid: "a5e5be90-fc65-4d90-9301-c567e6a25f1b",
        return_at_close: 1.0288483754512663,
        trade_pair: ["CADCHF", "CAD/CHF", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.6653,
        close_ms: 1712687057570,
        current_return: 0.9398767473320373,
        initial_entry_price: 0.6653,
        is_closed_position: true,
        miner_hotkey: "5HTVmEBicnSE1iFrSRtYnmyv5bhj6SRmiZHT3Twfbh9jon14",
        net_leverage: 0.0,
        open_ms: 1712686752164,
        orders: [
          {
            leverage: 100.0,
            order_type: "LONG",
            order_uuid: "3ccb64fb-02ff-46f6-84bb-bbfef248dd6b",
            price: 0.6653,
            price_sources: [],
            processed_ms: 1712686752160,
          },
          {
            leverage: 100.0,
            order_type: "FLAT",
            order_uuid: "c2721438-c123-4a86-b388-1a78135fdbeb",
            price: 0.6649,
            price_sources: [],
            processed_ms: 1712687057570,
          },
        ],
        position_type: "FLAT",
        position_uuid: "cfee79fd-9c68-4774-ade1-45af651857ed",
        return_at_close: 0.933297610100713,
        trade_pair: ["CADCHF", "CAD/CHF", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.66535,
        close_ms: 1712699540086,
        current_return: 1.0000676386592515,
        initial_entry_price: 0.6653,
        is_closed_position: true,
        miner_hotkey: "5HTVmEBicnSE1iFrSRtYnmyv5bhj6SRmiZHT3Twfbh9jon14",
        net_leverage: 0.0,
        open_ms: 1712698105221,
        orders: [
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "89d57bac-0246-46c1-b968-16de3a1ba5e4",
            price: 0.6653,
            price_sources: [],
            processed_ms: 1712698105216,
          },
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "928f3474-d44f-4edd-8079-efd00ecce079",
            price: 0.66536,
            price_sources: [],
            processed_ms: 1712698571112,
          },
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "6cbba5a2-8e33-41e8-a16f-8041950af7d1",
            price: 0.66539,
            price_sources: [],
            processed_ms: 1712698723206,
          },
          {
            leverage: 0.3,
            order_type: "FLAT",
            order_uuid: "9d2fd03e-e44d-4b09-a980-bc585a6e31ca",
            price: 0.6654,
            price_sources: [],
            processed_ms: 1712699540086,
          },
        ],
        position_type: "FLAT",
        position_uuid: "40979af8-9600-4e85-be29-9d8af48ea744",
        return_at_close: 1.000004634398016,
        trade_pair: ["CADCHF", "CAD/CHF", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.6648999999999999,
        close_ms: 1712705012520,
        current_return: 1.056859205776185,
        initial_entry_price: 0.6648,
        is_closed_position: true,
        miner_hotkey: "5HTVmEBicnSE1iFrSRtYnmyv5bhj6SRmiZHT3Twfbh9jon14",
        net_leverage: 0.0,
        open_ms: 1712704152307,
        orders: [
          {
            leverage: 30.0,
            order_type: "LONG",
            order_uuid: "40579c55-6faf-474a-b7bc-c1e553cb3e1f",
            price: 0.6648,
            price_sources: [],
            processed_ms: 1712704152304,
          },
          {
            leverage: 30.0,
            order_type: "LONG",
            order_uuid: "8474f3f4-f4b3-4d1c-99ad-05d8c3f2076e",
            price: 0.6648,
            price_sources: [],
            processed_ms: 1712704694509,
          },
          {
            leverage: 30.0,
            order_type: "LONG",
            order_uuid: "be9cf79d-0a17-48eb-99ee-eedbfdef8280",
            price: 0.6651,
            price_sources: [],
            processed_ms: 1712704874299,
          },
          {
            leverage: 30.0,
            order_type: "FLAT",
            order_uuid: "a77ffa7f-93b3-49e1-a163-b03528246f80",
            price: 0.66532,
            price_sources: [],
            processed_ms: 1712705012520,
          },
        ],
        position_type: "FLAT",
        position_uuid: "bf3f9905-ab8a-4b7e-b1c1-4363d0f4fb2b",
        return_at_close: 1.050200992779795,
        trade_pair: ["CADCHF", "CAD/CHF", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.8915107476,
        close_ms: 1713145232405,
        current_return: 0.9998537608207742,
        initial_entry_price: 0.8915107476,
        is_closed_position: true,
        miner_hotkey: "5HTVmEBicnSE1iFrSRtYnmyv5bhj6SRmiZHT3Twfbh9jon14",
        net_leverage: 0.0,
        open_ms: 1713144903310,
        orders: [
          {
            leverage: 0.5,
            order_type: "LONG",
            order_uuid: "dc12cd74-b08f-43bd-b11f-78e0409faab6",
            price: 0.8915107476,
            price_sources: [],
            processed_ms: 1713144903307,
          },
          {
            leverage: 0.5,
            order_type: "FLAT",
            order_uuid: "77439aa7-4bff-463a-ab46-8219580b4f2a",
            price: 0.89125,
            price_sources: [],
            processed_ms: 1713145232405,
          },
        ],
        position_type: "FLAT",
        position_uuid: "579c81af-f6ce-4438-a78f-75f2c973f159",
        return_at_close: 0.9998187659391454,
        trade_pair: ["AUDCAD", "AUD/CAD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 1.79264,
        close_ms: 1713145549521,
        current_return: 1.000142248304177,
        initial_entry_price: 1.79264,
        is_closed_position: true,
        miner_hotkey: "5HTVmEBicnSE1iFrSRtYnmyv5bhj6SRmiZHT3Twfbh9jon14",
        net_leverage: 0.0,
        open_ms: 1713145180391,
        orders: [
          {
            leverage: 0.5,
            order_type: "LONG",
            order_uuid: "6ef6340a-cd6f-48ca-81b8-8a6684b3c3e2",
            price: 1.79264,
            price_sources: [],
            processed_ms: 1713145180387,
          },
          {
            leverage: 0.5,
            order_type: "FLAT",
            order_uuid: "0bcfcdef-5943-40aa-a1f1-832ed1900f1f",
            price: 1.79315,
            price_sources: [],
            processed_ms: 1713145549521,
          },
        ],
        position_type: "FLAT",
        position_uuid: "5d2b40ba-c1dc-4faf-a624-51777beb180b",
        return_at_close: 1.0001072433254865,
        trade_pair: ["EURNZD", "EUR/NZD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.8174480337500001,
        close_ms: 1713145894319,
        current_return: 0.9996378827107878,
        initial_entry_price: 0.8176011166,
        is_closed_position: true,
        miner_hotkey: "5HTVmEBicnSE1iFrSRtYnmyv5bhj6SRmiZHT3Twfbh9jon14",
        net_leverage: 0.0,
        open_ms: 1713144989345,
        orders: [
          {
            leverage: 0.5,
            order_type: "LONG",
            order_uuid: "32201364-a5e3-46f1-96d1-077eed4fd04e",
            price: 0.8176011166,
            price_sources: [],
            processed_ms: 1713144989342,
          },
          {
            leverage: 0.5,
            order_type: "LONG",
            order_uuid: "a24985b3-9bcf-4ba4-8f83-6ed0134e4b97",
            price: 0.81752,
            price_sources: [],
            processed_ms: 1713145157337,
          },
          {
            leverage: 0.5,
            order_type: "LONG",
            order_uuid: "9d66ad1e-a1b0-4a76-8194-80cb6cb2954f",
            price: 0.8174084741,
            price_sources: [],
            processed_ms: 1713145288346,
          },
          {
            leverage: 0.5,
            order_type: "LONG",
            order_uuid: "024363dd-292a-4bfd-879c-98b485f89ef2",
            price: 0.8172625443,
            price_sources: [],
            processed_ms: 1713145592294,
          },
          {
            leverage: 2.0,
            order_type: "FLAT",
            order_uuid: "da7fceb6-132c-41b1-8fe1-67fb473513fb",
            price: 0.8173,
            price_sources: [],
            processed_ms: 1713145894319,
          },
        ],
        position_type: "FLAT",
        position_uuid: "3818552a-ed61-42b8-8099-c4a56a015910",
        return_at_close: 0.9994979334072083,
        trade_pair: ["NZDCAD", "NZD/CAD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.5937199999999999,
        close_ms: 1713145909339,
        current_return: 1.0002778151939657,
        initial_entry_price: 0.59392,
        is_closed_position: true,
        miner_hotkey: "5HTVmEBicnSE1iFrSRtYnmyv5bhj6SRmiZHT3Twfbh9jon14",
        net_leverage: 0.0,
        open_ms: 1713144971911,
        orders: [
          {
            leverage: 0.5,
            order_type: "LONG",
            order_uuid: "b6391498-92a0-4c00-9bd1-bc437850e88b",
            price: 0.59392,
            price_sources: [],
            processed_ms: 1713144968537,
          },
          {
            leverage: 0.5,
            order_type: "LONG",
            order_uuid: "7441ddb9-f00a-49bb-be41-459be144b0f4",
            price: 0.5935,
            price_sources: [],
            processed_ms: 1713145125336,
          },
          {
            leverage: 0.5,
            order_type: "LONG",
            order_uuid: "9ffeda06-7ae0-42cc-9f0f-d959a7a9e386",
            price: 0.59374,
            price_sources: [],
            processed_ms: 1713145468325,
          },
          {
            leverage: 1.5,
            order_type: "FLAT",
            order_uuid: "42cadc84-3324-4295-91ea-ff8dd9d0e4cc",
            price: 0.59383,
            price_sources: [],
            processed_ms: 1713145909339,
          },
        ],
        position_type: "FLAT",
        position_uuid: "2416c59a-c172-4612-9354-97d11aa86ecc",
        return_at_close: 1.0001727860233705,
        trade_pair: ["NZDUSD", "NZD/USD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.891435,
        close_ms: 1713146705373,
        current_return: 1.000501254655598,
        initial_entry_price: 0.8914,
        is_closed_position: true,
        miner_hotkey: "5HTVmEBicnSE1iFrSRtYnmyv5bhj6SRmiZHT3Twfbh9jon14",
        net_leverage: 0.0,
        open_ms: 1713146052341,
        orders: [
          {
            leverage: 0.5,
            order_type: "LONG",
            order_uuid: "5ffa0829-3b7d-4a5f-bc89-e9daf59d1c68",
            price: 0.8914,
            price_sources: [],
            processed_ms: 1713146052338,
          },
          {
            leverage: 0.5,
            order_type: "LONG",
            order_uuid: "a387f0b1-9e62-4eb0-a3fd-58a2a66656d2",
            price: 0.89147,
            price_sources: [],
            processed_ms: 1713146404670,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "1ca9e956-e33d-4654-b34d-bed760740129",
            price: 0.8918818184,
            price_sources: [],
            processed_ms: 1713146705373,
          },
        ],
        position_type: "FLAT",
        position_uuid: "4f4ef5d1-5b5a-441d-9c45-6fd619852e24",
        return_at_close: 1.000431219567772,
        trade_pair: ["AUDCAD", "AUD/CAD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.59434,
        close_ms: 1713146977377,
        current_return: 1.000050476158428,
        initial_entry_price: 0.59434,
        is_closed_position: true,
        miner_hotkey: "5HTVmEBicnSE1iFrSRtYnmyv5bhj6SRmiZHT3Twfbh9jon14",
        net_leverage: 0.0,
        open_ms: 1713146676335,
        orders: [
          {
            leverage: 0.5,
            order_type: "LONG",
            order_uuid: "c17db3cf-0d75-426a-8bb5-b025ee183e32",
            price: 0.59434,
            price_sources: [],
            processed_ms: 1713146676331,
          },
          {
            leverage: 0.5,
            order_type: "FLAT",
            order_uuid: "c5eb7c1b-5849-4b9f-8f41-c330e1cf88fd",
            price: 0.5944,
            price_sources: [],
            processed_ms: 1713146977377,
          },
        ],
        position_type: "FLAT",
        position_uuid: "3c26dbad-39d1-4349-95df-82f858f88b38",
        return_at_close: 1.0000154743917624,
        trade_pair: ["NZDUSD", "NZD/USD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.8176960015200001,
        close_ms: 1713148319335,
        current_return: 1.0005624869465264,
        initial_entry_price: 0.81779,
        is_closed_position: true,
        miner_hotkey: "5HTVmEBicnSE1iFrSRtYnmyv5bhj6SRmiZHT3Twfbh9jon14",
        net_leverage: 0.0,
        open_ms: 1713146962371,
        orders: [
          {
            leverage: 0.5,
            order_type: "LONG",
            order_uuid: "da4bce4e-30fa-4f80-a317-070a18911b8b",
            price: 0.81779,
            price_sources: [],
            processed_ms: 1713146962368,
          },
          {
            leverage: 1.0,
            order_type: "LONG",
            order_uuid: "0e728a64-3f94-4507-a54a-a396e2cf08fd",
            price: 0.8175,
            price_sources: [],
            processed_ms: 1713147446359,
          },
          {
            leverage: 1.0,
            order_type: "LONG",
            order_uuid: "7f15ba65-8247-4d7c-b084-863699d92ab3",
            price: 0.8178450038,
            price_sources: [],
            processed_ms: 1713147945098,
          },
          {
            leverage: 2.5,
            order_type: "FLAT",
            order_uuid: "bedb2357-7816-49df-9ee9-a89246422262",
            price: 0.81788,
            price_sources: [],
            processed_ms: 1713148319335,
          },
        ],
        position_type: "FLAT",
        position_uuid: "96051838-eb3e-4df1-a37a-212132610780",
        return_at_close: 1.0003873885113108,
        trade_pair: ["NZDCAD", "NZD/CAD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.8913581120333335,
        close_ms: 1713149470382,
        current_return: 1.0004706678781348,
        initial_entry_price: 0.89148,
        is_closed_position: true,
        miner_hotkey: "5HTVmEBicnSE1iFrSRtYnmyv5bhj6SRmiZHT3Twfbh9jon14",
        net_leverage: 0.0,
        open_ms: 1713148086365,
        orders: [
          {
            leverage: 1.0,
            order_type: "LONG",
            order_uuid: "cadda8bd-a12f-4822-9567-71d439d94277",
            price: 0.89148,
            price_sources: [],
            processed_ms: 1713148086361,
          },
          {
            leverage: 1.0,
            order_type: "LONG",
            order_uuid: "c75e68ab-ab3d-45ae-ab4b-0a730c793f14",
            price: 0.8912899883,
            price_sources: [],
            processed_ms: 1713148470308,
          },
          {
            leverage: 1.0,
            order_type: "LONG",
            order_uuid: "5b0baeaf-cc72-4fcf-beaa-2c202964b20c",
            price: 0.8913043478,
            price_sources: [],
            processed_ms: 1713148839324,
          },
          {
            leverage: 3.0,
            order_type: "FLAT",
            order_uuid: "49bb0a22-c325-44e3-ac20-90c8d35be464",
            price: 0.8914979757,
            price_sources: [],
            processed_ms: 1713149470382,
          },
        ],
        position_type: "FLAT",
        position_uuid: "4a206ce7-fb4a-4286-b300-e4ea9b833f5e",
        return_at_close: 1.0002605690378803,
        trade_pair: ["AUDCAD", "AUD/CAD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.59434,
        close_ms: 1713150829329,
        current_return: 1.0003196823367098,
        initial_entry_price: 0.59434,
        is_closed_position: true,
        miner_hotkey: "5HTVmEBicnSE1iFrSRtYnmyv5bhj6SRmiZHT3Twfbh9jon14",
        net_leverage: 0.0,
        open_ms: 1713148238345,
        orders: [
          {
            leverage: 1.0,
            order_type: "LONG",
            order_uuid: "11e94cb4-b5b3-49ff-ab20-55e1610e35e2",
            price: 0.59434,
            price_sources: [],
            processed_ms: 1713148238342,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "1a03b91a-beb8-41ef-a338-43277faa16d2",
            price: 0.59453,
            price_sources: [],
            processed_ms: 1713150829329,
          },
        ],
        position_type: "FLAT",
        position_uuid: "6add6169-63c6-4d17-9c5b-b495c1cbe9cf",
        return_at_close: 1.000249659958946,
        trade_pair: ["NZDUSD", "NZD/USD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.891695,
        close_ms: 1713155748362,
        current_return: 1.0005268526718156,
        initial_entry_price: 0.89209,
        is_closed_position: true,
        miner_hotkey: "5HTVmEBicnSE1iFrSRtYnmyv5bhj6SRmiZHT3Twfbh9jon14",
        net_leverage: 0.0,
        open_ms: 1713154662358,
        orders: [
          {
            leverage: 1.0,
            order_type: "LONG",
            order_uuid: "e8d9120f-15e5-40c0-b20d-006c8b24cee3",
            price: 0.89209,
            price_sources: [],
            processed_ms: 1713154662354,
          },
          {
            leverage: 1.0,
            order_type: "LONG",
            order_uuid: "7b4c0fa2-415d-4cd8-883f-20e2d71c2d19",
            price: 0.8913,
            price_sources: [],
            processed_ms: 1713155437727,
          },
          {
            leverage: 2.0,
            order_type: "FLAT",
            order_uuid: "79bb3911-9331-4168-8c66-8cec230e970c",
            price: 0.89193,
            price_sources: [],
            processed_ms: 1713155748362,
          },
        ],
        position_type: "FLAT",
        position_uuid: "b88667d9-ed5b-4f44-aebc-600f878055f4",
        return_at_close: 1.0003867789124414,
        trade_pair: ["AUDCAD", "AUD/CAD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.59449,
        close_ms: 1713159246301,
        current_return: 1.0000168211408098,
        initial_entry_price: 0.59449,
        is_closed_position: true,
        miner_hotkey: "5HTVmEBicnSE1iFrSRtYnmyv5bhj6SRmiZHT3Twfbh9jon14",
        net_leverage: 0.0,
        open_ms: 1713158493355,
        orders: [
          {
            leverage: 1.0,
            order_type: "LONG",
            order_uuid: "6223ba42-59be-47a5-bd28-28a622a86394",
            price: 0.59449,
            price_sources: [],
            processed_ms: 1713158493351,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "d717bdef-5422-40a7-9749-832591d20e1b",
            price: 0.5945,
            price_sources: [],
            processed_ms: 1713159246301,
          },
        ],
        position_type: "FLAT",
        position_uuid: "97fae955-e6cb-4934-ac7b-fa96505416e1",
        return_at_close: 0.9999468199633299,
        trade_pair: ["NZDUSD", "NZD/USD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.8185348804,
        close_ms: 1713159273367,
        current_return: 0.9983333440809,
        initial_entry_price: 0.81879,
        is_closed_position: true,
        miner_hotkey: "5HTVmEBicnSE1iFrSRtYnmyv5bhj6SRmiZHT3Twfbh9jon14",
        net_leverage: 0.0,
        open_ms: 1713154104410,
        orders: [
          {
            leverage: 1.0,
            order_type: "LONG",
            order_uuid: "ee0a79be-88d9-4d3c-b887-aac07df116b2",
            price: 0.81879,
            price_sources: [],
            processed_ms: 1713154104405,
          },
          {
            leverage: 1.0,
            order_type: "LONG",
            order_uuid: "f314d9a7-41bb-4011-9801-6fe0cad2d979",
            price: 0.8185146412,
            price_sources: [],
            processed_ms: 1713154646402,
          },
          {
            leverage: 1.0,
            order_type: "LONG",
            order_uuid: "9f3a733a-3568-447e-a8f4-4e4be6d1c506",
            price: 0.8183,
            price_sources: [],
            processed_ms: 1713155098591,
          },
          {
            leverage: 3.0,
            order_type: "FLAT",
            order_uuid: "3d109a80-8f91-4b11-8647-c1fbf2a21857",
            price: 0.81808,
            price_sources: [],
            processed_ms: 1713159273367,
          },
        ],
        position_type: "FLAT",
        position_uuid: "09b9e3bb-0764-45e3-9f74-4da72da5c4d0",
        return_at_close: 0.998123694078643,
        trade_pair: ["NZDCAD", "NZD/CAD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.8147641074,
        close_ms: 1713193324788,
        current_return: 1.000352876796156,
        initial_entry_price: 0.8149082148,
        is_closed_position: true,
        miner_hotkey: "5HTVmEBicnSE1iFrSRtYnmyv5bhj6SRmiZHT3Twfbh9jon14",
        net_leverage: 0.0,
        open_ms: 1713192764353,
        orders: [
          {
            leverage: 0.5,
            order_type: "LONG",
            order_uuid: "5acf1421-701f-4a5b-ac26-22f894fe97cc",
            price: 0.8149082148,
            price_sources: [],
            processed_ms: 1713192764349,
          },
          {
            leverage: 0.5,
            order_type: "LONG",
            order_uuid: "12bf32f3-b678-462c-a3f3-c86bdde1c298",
            price: 0.81462,
            price_sources: [],
            processed_ms: 1713193016820,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "9b19a6a8-44c7-4710-8865-92f9642303ad",
            price: 0.8150516696,
            price_sources: [],
            processed_ms: 1713193324788,
          },
        ],
        position_type: "FLAT",
        position_uuid: "98541286-64c8-46c2-ade0-9e9927f5a0cc",
        return_at_close: 1.0002828520947802,
        trade_pair: ["NZDCAD", "NZD/CAD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.591755,
        close_ms: 1713193383568,
        current_return: 0.9999915511997296,
        initial_entry_price: 0.5918,
        is_closed_position: true,
        miner_hotkey: "5HTVmEBicnSE1iFrSRtYnmyv5bhj6SRmiZHT3Twfbh9jon14",
        net_leverage: 0.0,
        open_ms: 1713192848415,
        orders: [
          {
            leverage: 0.5,
            order_type: "LONG",
            order_uuid: "7539a488-3a48-4a1b-9463-6d5a9a065776",
            price: 0.5918,
            price_sources: [],
            processed_ms: 1713192848411,
          },
          {
            leverage: 0.5,
            order_type: "LONG",
            order_uuid: "44a7196f-464f-4ee6-a8e3-eb4deeead80b",
            price: 0.59171,
            price_sources: [],
            processed_ms: 1713193236447,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "96c64e66-c7f9-4697-aeab-2929a3789943",
            price: 0.59175,
            price_sources: [],
            processed_ms: 1713193383568,
          },
        ],
        position_type: "FLAT",
        position_uuid: "a4cef462-99b1-4c69-9aae-c7e3caf45700",
        return_at_close: 0.9999215517911456,
        trade_pair: ["NZDUSD", "NZD/USD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.8890030017666667,
        close_ms: 1713193986043,
        current_return: 0.9999780609089269,
        initial_entry_price: 0.8889452136,
        is_closed_position: true,
        miner_hotkey: "5HTVmEBicnSE1iFrSRtYnmyv5bhj6SRmiZHT3Twfbh9jon14",
        net_leverage: 0.0,
        open_ms: 1713192576395,
        orders: [
          {
            leverage: 0.5,
            order_type: "LONG",
            order_uuid: "b5901314-50cf-4a1f-af10-84d42ef3a85b",
            price: 0.8889452136,
            price_sources: [],
            processed_ms: 1713192576391,
          },
          {
            leverage: 0.5,
            order_type: "LONG",
            order_uuid: "15c139c3-bcae-43a4-89d3-9a45ef6e3bd3",
            price: 0.8890237917,
            price_sources: [],
            processed_ms: 1713192732894,
          },
          {
            leverage: 0.5,
            order_type: "LONG",
            order_uuid: "abd78b45-7b74-41ce-8603-1a98678f10b9",
            price: 0.88904,
            price_sources: [],
            processed_ms: 1713193214143,
          },
          {
            leverage: 1.5,
            order_type: "FLAT",
            order_uuid: "8f885e88-a8e1-4bc8-846f-87a8d44cedf0",
            price: 0.88899,
            price_sources: [],
            processed_ms: 1713193986043,
          },
        ],
        position_type: "FLAT",
        position_uuid: "42495627-70ed-455f-b30e-4726aac2343f",
        return_at_close: 0.9998730632125314,
        trade_pair: ["AUDCAD", "AUD/CAD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 1.7959366666666667,
        close_ms: 1713194829335,
        current_return: 1.0002700565726756,
        initial_entry_price: 1.79592,
        is_closed_position: true,
        miner_hotkey: "5HTVmEBicnSE1iFrSRtYnmyv5bhj6SRmiZHT3Twfbh9jon14",
        net_leverage: 0.0,
        open_ms: 1713193968979,
        orders: [
          {
            leverage: 0.5,
            order_type: "LONG",
            order_uuid: "c83e774e-3375-4784-9a7d-01456810efdf",
            price: 1.79592,
            price_sources: [],
            processed_ms: 1713193968974,
          },
          {
            leverage: 0.5,
            order_type: "LONG",
            order_uuid: "99d408cb-1a7b-4297-853f-ff08c4353b59",
            price: 1.79582,
            price_sources: [],
            processed_ms: 1713194125388,
          },
          {
            leverage: 0.5,
            order_type: "LONG",
            order_uuid: "794c55bf-5608-40e7-87a7-b3fabf8adc07",
            price: 1.79607,
            price_sources: [],
            processed_ms: 1713194709028,
          },
          {
            leverage: 1.5,
            order_type: "FLAT",
            order_uuid: "a33990f3-2234-4d08-ba5b-7a7d5ad8cd51",
            price: 1.79626,
            price_sources: [],
            processed_ms: 1713194829335,
          },
        ],
        position_type: "FLAT",
        position_uuid: "f3ef46dd-c359-495f-984d-35a3a4225288",
        return_at_close: 1.0001650282167356,
        trade_pair: ["EURNZD", "EUR/NZD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 168.978,
        close_ms: 1713215707791,
        current_return: 0.9860330492726863,
        initial_entry_price: 168.978,
        is_closed_position: true,
        miner_hotkey: "5HTVmEBicnSE1iFrSRtYnmyv5bhj6SRmiZHT3Twfbh9jon14",
        net_leverage: 0.0,
        open_ms: 1713215576295,
        orders: [
          {
            leverage: 2.0,
            order_type: "LONG",
            order_uuid: "4b560283-6699-4c84-8bc6-fad18b800034",
            price: 168.978,
            price_sources: [],
            processed_ms: 1713215576291,
          },
          {
            leverage: 2.0,
            order_type: "FLAT",
            order_uuid: "1a4b8bfa-302f-4a02-b7a5-355d5c29fffa",
            price: 167.7979463,
            price_sources: [],
            processed_ms: 1713215707791,
          },
        ],
        position_type: "FLAT",
        position_uuid: "cdff5aca-ab34-44c6-b825-21302395eb02",
        return_at_close: 0.985895004645788,
        trade_pair: ["CHFJPY", "CHF/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.8112330522,
        close_ms: 1713241156290,
        current_return: 1.0003850820424272,
        initial_entry_price: 0.8108,
        is_closed_position: true,
        miner_hotkey: "5HTVmEBicnSE1iFrSRtYnmyv5bhj6SRmiZHT3Twfbh9jon14",
        net_leverage: 0.0,
        open_ms: 1713240268085,
        orders: [
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "301e0a1f-23b7-43c6-bbeb-a6dfc6a9f299",
            price: 0.8108,
            price_sources: [],
            processed_ms: 1713240262921,
          },
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "df2a72f9-bec6-4250-be85-dcf05b13470f",
            price: 0.8114395071,
            price_sources: [],
            processed_ms: 1713240393322,
          },
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "b2b02f64-f849-4c26-a155-1af98117502f",
            price: 0.81131,
            price_sources: [],
            processed_ms: 1713240696351,
          },
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "0c603171-b7c6-4ede-af7d-fb0cb2474d01",
            price: 0.8113827017,
            price_sources: [],
            processed_ms: 1713240854320,
          },
          {
            leverage: 1.2,
            order_type: "FLAT",
            order_uuid: "cccc9dc9-19c5-48db-8009-d2fced85dcc1",
            price: 0.8114932393,
            price_sources: [],
            processed_ms: 1713241156290,
          },
        ],
        position_type: "FLAT",
        position_uuid: "7928174b-5934-4b53-a095-442f2ff3f147",
        return_at_close: 1.0003010496955356,
        trade_pair: ["NZDCAD", "NZD/CAD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.8856660221,
        close_ms: 1713242478377,
        current_return: 0.9999329110414141,
        initial_entry_price: 0.8856880663,
        is_closed_position: true,
        miner_hotkey: "5HTVmEBicnSE1iFrSRtYnmyv5bhj6SRmiZHT3Twfbh9jon14",
        net_leverage: 0.0,
        open_ms: 1713241356350,
        orders: [
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "b017e9ae-2332-45a9-8349-f5b713f18a25",
            price: 0.8856880663,
            price_sources: [],
            processed_ms: 1713241356345,
          },
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "26ffb32b-06a7-42e5-8fb8-4046138bf67b",
            price: 0.88574,
            price_sources: [],
            processed_ms: 1713241842344,
          },
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "247b1052-f0ca-4e91-821d-76f782fb638e",
            price: 0.88557,
            price_sources: [],
            processed_ms: 1713242134333,
          },
          {
            leverage: 0.8999999999999999,
            order_type: "FLAT",
            order_uuid: "a0aaf5f4-c869-43ad-b6d5-2fba635e0cfa",
            price: 0.8856,
            price_sources: [],
            processed_ms: 1713242478377,
          },
        ],
        position_type: "FLAT",
        position_uuid: "26590c7b-9113-44e5-bbdd-b3bf1c49edf3",
        return_at_close: 0.9998699152680185,
        trade_pair: ["AUDCAD", "AUD/CAD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 1.80493,
        close_ms: 1713287350932,
        current_return: 1.0000099726859213,
        initial_entry_price: 1.80493,
        is_closed_position: true,
        miner_hotkey: "5HTVmEBicnSE1iFrSRtYnmyv5bhj6SRmiZHT3Twfbh9jon14",
        net_leverage: 0.0,
        open_ms: 1713240210306,
        orders: [
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "06ae442a-54cf-4b81-b9c6-6826f52d5a93",
            price: 1.80493,
            price_sources: [],
            processed_ms: 1713240210302,
          },
          {
            leverage: 0.3,
            order_type: "FLAT",
            order_uuid: "241e7dd5-e5df-454e-b068-394d26ee2f2a",
            price: 1.80499,
            price_sources: [],
            processed_ms: 1713287350932,
          },
        ],
        position_type: "FLAT",
        position_uuid: "a1eeadc2-12bd-4df2-a44f-ff6406e857d1",
        return_at_close: 0.9999889724764949,
        trade_pair: ["EURNZD", "EUR/NZD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.8134657252,
        close_ms: 1713306018269,
        current_return: 1.0000126402867158,
        initial_entry_price: 0.8134657252,
        is_closed_position: true,
        miner_hotkey: "5HTVmEBicnSE1iFrSRtYnmyv5bhj6SRmiZHT3Twfbh9jon14",
        net_leverage: 0.0,
        open_ms: 1713287536397,
        orders: [
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "9e3de3a8-fc1f-4f3f-a46d-8d1865b02392",
            price: 0.8134657252,
            price_sources: [],
            processed_ms: 1713287536392,
          },
          {
            leverage: 0.01,
            order_type: "FLAT",
            order_uuid: "ee1e34d7-48c4-43c6-9578-f2af61b97cbc",
            price: 0.8135,
            price_sources: [],
            processed_ms: 1713306018269,
          },
        ],
        position_type: "FLAT",
        position_uuid: "bd1f8b1a-dd74-4a85-99a7-a3132c4e06f5",
        return_at_close: 0.9999916400212697,
        trade_pair: ["NZDCAD", "NZD/CAD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.8154448533500002,
        close_ms: 1713318630305,
        current_return: 1.0000185028818795,
        initial_entry_price: 0.81544,
        is_closed_position: true,
        miner_hotkey: "5HTVmEBicnSE1iFrSRtYnmyv5bhj6SRmiZHT3Twfbh9jon14",
        net_leverage: 0.0,
        open_ms: 1713318030837,
        orders: [
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "ad7a9561-ecc3-4519-b993-4d49c4d4a75d",
            price: 0.81544,
            price_sources: [],
            processed_ms: 1713318030832,
          },
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "87bb7d6f-c200-4127-9e87-e755d337f238",
            price: 0.8154497067,
            price_sources: [],
            processed_ms: 1713318299320,
          },
          {
            leverage: 0.6,
            order_type: "FLAT",
            order_uuid: "d9308e8d-ec68-45d7-bdf4-19af596fb7f6",
            price: 0.81547,
            price_sources: [],
            processed_ms: 1713318630305,
          },
        ],
        position_type: "FLAT",
        position_uuid: "a5669b2a-eb64-4159-9886-35a23bff4237",
        return_at_close: 0.9999765021047585,
        trade_pair: ["NZDCAD", "NZD/CAD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 1.80012,
        close_ms: 1713318850338,
        current_return: 0.9998166656481424,
        initial_entry_price: 1.79999,
        is_closed_position: true,
        miner_hotkey: "5HTVmEBicnSE1iFrSRtYnmyv5bhj6SRmiZHT3Twfbh9jon14",
        net_leverage: 0.0,
        open_ms: 1713318130345,
        orders: [
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "9088deed-df28-4a74-8064-095619aee321",
            price: 1.79999,
            price_sources: [],
            processed_ms: 1713318130340,
          },
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "e2d43bdc-1f3e-4f51-b0db-613b104534e8",
            price: 1.80025,
            price_sources: [],
            processed_ms: 1713318512403,
          },
          {
            leverage: 0.6,
            order_type: "FLAT",
            order_uuid: "49df1d7f-f533-4447-a336-2cdf8102faf8",
            price: 1.79957,
            price_sources: [],
            processed_ms: 1713318850338,
          },
        ],
        position_type: "FLAT",
        position_uuid: "5e17ba2e-e409-4801-a610-1bbb705d5e5d",
        return_at_close: 0.9997746733481853,
        trade_pair: ["EURNZD", "EUR/NZD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.8155421951666667,
        close_ms: 1713319657334,
        current_return: 0.9996224616131109,
        initial_entry_price: 0.8157465855,
        is_closed_position: true,
        miner_hotkey: "5HTVmEBicnSE1iFrSRtYnmyv5bhj6SRmiZHT3Twfbh9jon14",
        net_leverage: 0.0,
        open_ms: 1713318823344,
        orders: [
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "cde2d3c5-80dd-4515-aff6-32d08c1b45d8",
            price: 0.8157465855,
            price_sources: [],
            processed_ms: 1713318823338,
          },
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "9247df74-dd0b-42aa-94ad-812ea48d4694",
            price: 0.81545,
            price_sources: [],
            processed_ms: 1713319090375,
          },
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "eb9212d7-bded-4ae9-9cf0-50bc847e2831",
            price: 0.81543,
            price_sources: [],
            processed_ms: 1713319302360,
          },
          {
            leverage: 0.8999999999999999,
            order_type: "FLAT",
            order_uuid: "6f00411b-cf4a-4e9a-887e-8e03191d48c1",
            price: 0.8152,
            price_sources: [],
            processed_ms: 1713319657334,
          },
        ],
        position_type: "FLAT",
        position_uuid: "fa9ac6c1-5177-4708-954e-f8c46c00f694",
        return_at_close: 0.9995594853980292,
        trade_pair: ["NZDCAD", "NZD/CAD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.8865810190166666,
        close_ms: 1713320181792,
        current_return: 0.9995873945070685,
        initial_entry_price: 0.8866322341,
        is_closed_position: true,
        miner_hotkey: "5HTVmEBicnSE1iFrSRtYnmyv5bhj6SRmiZHT3Twfbh9jon14",
        net_leverage: 0.0,
        open_ms: 1713317610382,
        orders: [
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "440ea93c-57be-476a-a3b7-5f6276d7c6c0",
            price: 0.8866322341,
            price_sources: [],
            processed_ms: 1713317610377,
          },
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "0fed95ac-18d1-45c3-882a-e8359f4a1f5b",
            price: 0.8865858353,
            price_sources: [],
            processed_ms: 1713318378312,
          },
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "92b2a925-a65c-468c-b8d1-2b070d10fc52",
            price: 0.8863980447,
            price_sources: [],
            processed_ms: 1713318710899,
          },
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "8ce4c5f7-9dde-49d9-b9c0-b7ac0d29e984",
            price: 0.88671,
            price_sources: [],
            processed_ms: 1713319036323,
          },
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "be62eb64-46a8-44da-9ddd-16e47e4fb513",
            price: 0.88649,
            price_sources: [],
            processed_ms: 1713319212505,
          },
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "80221472-6ea8-4381-8528-e1e413b5e301",
            price: 0.88667,
            price_sources: [],
            processed_ms: 1713319328364,
          },
          {
            leverage: 1.8,
            order_type: "FLAT",
            order_uuid: "9f601884-942f-480a-a80b-a891a8f38c0f",
            price: 0.8863777805,
            price_sources: [],
            processed_ms: 1713320181792,
          },
        ],
        position_type: "FLAT",
        position_uuid: "17ff99f4-f202-463d-9a39-eb9b558c1b39",
        return_at_close: 0.9994614464953606,
        trade_pair: ["AUDCAD", "AUD/CAD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.8155107438000001,
        close_ms: 1713320760785,
        current_return: 1.0002054587590847,
        initial_entry_price: 0.8155102306,
        is_closed_position: true,
        miner_hotkey: "5HTVmEBicnSE1iFrSRtYnmyv5bhj6SRmiZHT3Twfbh9jon14",
        net_leverage: 0.0,
        open_ms: 1713319844382,
        orders: [
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "d7f41f9a-d004-4965-873d-c8eb60c60f5a",
            price: 0.8155102306,
            price_sources: [],
            processed_ms: 1713319844377,
          },
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "03903a17-019b-4457-9665-1f8ff6b06390",
            price: 0.815511257,
            price_sources: [],
            processed_ms: 1713320432318,
          },
          {
            leverage: 0.6,
            order_type: "FLAT",
            order_uuid: "b917ad1a-706e-4613-bc66-8c80a9889dc6",
            price: 0.81579,
            price_sources: [],
            processed_ms: 1713320760785,
          },
        ],
        position_type: "FLAT",
        position_uuid: "f83f6c27-cf0a-45b4-81de-41f43918794c",
        return_at_close: 1.0001634501298169,
        trade_pair: ["NZDCAD", "NZD/CAD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.8157,
        close_ms: 1713322117432,
        current_return: 1.0001213666621718,
        initial_entry_price: 0.81571,
        is_closed_position: true,
        miner_hotkey: "5HTVmEBicnSE1iFrSRtYnmyv5bhj6SRmiZHT3Twfbh9jon14",
        net_leverage: 0.0,
        open_ms: 1713320900824,
        orders: [
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "6e08a96f-92b3-4195-b093-30c79462d5e0",
            price: 0.81571,
            price_sources: [],
            processed_ms: 1713320897454,
          },
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "90439a86-6c83-49bc-b2d4-67f795ae2687",
            price: 0.81571,
            price_sources: [],
            processed_ms: 1713321182529,
          },
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "3791edbc-17ea-4421-afaf-ac0b16d8363d",
            price: 0.81568,
            price_sources: [],
            processed_ms: 1713321769365,
          },
          {
            leverage: 0.8999999999999999,
            order_type: "FLAT",
            order_uuid: "3fb8a27f-af5b-47d6-8057-71527113051b",
            price: 0.81581,
            price_sources: [],
            processed_ms: 1713322117432,
          },
        ],
        position_type: "FLAT",
        position_uuid: "5f968d33-8315-40b0-8843-a3a3fe4220c0",
        return_at_close: 1.000058359016072,
        trade_pair: ["NZDCAD", "NZD/CAD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.8157918096666665,
        close_ms: 1713323399959,
        current_return: 0.9999960071425058,
        initial_entry_price: 0.815806726,
        is_closed_position: true,
        miner_hotkey: "5HTVmEBicnSE1iFrSRtYnmyv5bhj6SRmiZHT3Twfbh9jon14",
        net_leverage: 0.0,
        open_ms: 1713322183888,
        orders: [
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "94637667-8951-4bc8-857e-9cd6848d9a24",
            price: 0.815806726,
            price_sources: [],
            processed_ms: 1713322180507,
          },
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "a2317c60-205d-4325-99b3-d6f910b182a4",
            price: 0.8157254469,
            price_sources: [],
            processed_ms: 1713322403401,
          },
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "8fde25e4-f270-4fe8-b1c9-a6d96bc409d6",
            price: 0.8157280877,
            price_sources: [],
            processed_ms: 1713322681289,
          },
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "c8fbf3b0-41d0-4750-b5ed-d8d18cd51565",
            price: 0.81575,
            price_sources: [],
            processed_ms: 1713322953402,
          },
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "0ae54da4-00ab-4f92-a12f-e5095941247d",
            price: 0.8158427829,
            price_sources: [],
            processed_ms: 1713323078380,
          },
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "41ae78a0-10b5-456f-b020-71dda6a30af7",
            price: 0.8158978145,
            price_sources: [],
            processed_ms: 1713323205370,
          },
          {
            leverage: 1.5,
            order_type: "FLAT",
            order_uuid: "ead40994-8069-4d17-b168-a1b66a32ffe7",
            price: 0.81579,
            price_sources: [],
            processed_ms: 1713323399959,
          },
        ],
        position_type: "FLAT",
        position_uuid: "47d00911-504d-4f3a-88f1-4b0a8b103c2a",
        return_at_close: 0.9998700076456059,
        trade_pair: ["NZDCAD", "NZD/CAD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.8158223523000001,
        close_ms: 1713324140543,
        current_return: 0.9999399355020592,
        initial_entry_price: 0.81584,
        is_closed_position: true,
        miner_hotkey: "5HTVmEBicnSE1iFrSRtYnmyv5bhj6SRmiZHT3Twfbh9jon14",
        net_leverage: 0.0,
        open_ms: 1713323522366,
        orders: [
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "99459e1b-f4d5-41d0-ab99-0a017e490ae9",
            price: 0.81584,
            price_sources: [],
            processed_ms: 1713323522361,
          },
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "65012e72-192d-4642-a4f1-8ea8800bb1a5",
            price: 0.8158047046,
            price_sources: [],
            processed_ms: 1713323797384,
          },
          {
            leverage: 0.6,
            order_type: "FLAT",
            order_uuid: "d58803fb-4cc1-4f1b-a99a-f6c58b79c291",
            price: 0.8157406806,
            price_sources: [],
            processed_ms: 1713324140543,
          },
        ],
        position_type: "FLAT",
        position_uuid: "a7574e37-8bcb-4284-87eb-21fca6593806",
        return_at_close: 0.9998979380247681,
        trade_pair: ["NZDCAD", "NZD/CAD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.81591206475,
        close_ms: 1713325012408,
        current_return: 1.0000100829196543,
        initial_entry_price: 0.81585,
        is_closed_position: true,
        miner_hotkey: "5HTVmEBicnSE1iFrSRtYnmyv5bhj6SRmiZHT3Twfbh9jon14",
        net_leverage: 0.0,
        open_ms: 1713324250432,
        orders: [
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "a336b0ac-040a-42a7-94b6-86588b818276",
            price: 0.81585,
            price_sources: [],
            processed_ms: 1713324250426,
          },
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "e4de88a7-0cfa-4c61-bfec-d0118772976c",
            price: 0.8159741295,
            price_sources: [],
            processed_ms: 1713324410620,
          },
          {
            leverage: 0.6,
            order_type: "FLAT",
            order_uuid: "21281699-93ea-4ff2-a042-b6a4f7f6f865",
            price: 0.815925775,
            price_sources: [],
            processed_ms: 1713325012408,
          },
        ],
        position_type: "FLAT",
        position_uuid: "b24254bf-5cf1-4984-bcb5-d23abca4e26f",
        return_at_close: 0.9999680824961718,
        trade_pair: ["NZDCAD", "NZD/CAD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.8866855819333334,
        close_ms: 1713325421860,
        current_return: 1.0000742424556814,
        initial_entry_price: 0.88676,
        is_closed_position: true,
        miner_hotkey: "5HTVmEBicnSE1iFrSRtYnmyv5bhj6SRmiZHT3Twfbh9jon14",
        net_leverage: 0.0,
        open_ms: 1713324851409,
        orders: [
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "245aca6c-3d7f-427a-bcfc-fcdecd8fd8af",
            price: 0.88676,
            price_sources: [],
            processed_ms: 1713324851404,
          },
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "cd06d1af-57bf-47d4-a6e7-6ced476eb688",
            price: 0.88678,
            price_sources: [],
            processed_ms: 1713324988409,
          },
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "3b9587eb-b5b9-48a8-a56c-9371622e4085",
            price: 0.8865167458,
            price_sources: [],
            processed_ms: 1713325120442,
          },
          {
            leverage: 0.8999999999999999,
            order_type: "FLAT",
            order_uuid: "ec9f6db2-1319-4e5c-bb78-1dd870c5cdee",
            price: 0.8867587322,
            price_sources: [],
            processed_ms: 1713325421860,
          },
        ],
        position_type: "FLAT",
        position_uuid: "2d2b7a30-2a90-4d74-bfa5-d0467cd790de",
        return_at_close: 1.0000112377784067,
        trade_pair: ["AUDCAD", "AUD/CAD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.8159685741999999,
        close_ms: 1713326591206,
        current_return: 1.0001113623253053,
        initial_entry_price: 0.8158547314,
        is_closed_position: true,
        miner_hotkey: "5HTVmEBicnSE1iFrSRtYnmyv5bhj6SRmiZHT3Twfbh9jon14",
        net_leverage: 0.0,
        open_ms: 1713325257227,
        orders: [
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "d5d25b49-0a03-4f40-ac7a-69a3b11a52f3",
            price: 0.8158547314,
            price_sources: [],
            processed_ms: 1713325257222,
          },
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "8c48335b-e6bd-4e3f-8f9a-3f566548f761",
            price: 0.816082417,
            price_sources: [],
            processed_ms: 1713326206441,
          },
          {
            leverage: 0.6,
            order_type: "FLAT",
            order_uuid: "4bbce95d-89d6-4b31-845a-7fda159b0c14",
            price: 0.81612,
            price_sources: [],
            processed_ms: 1713326591206,
          },
        ],
        position_type: "FLAT",
        position_uuid: "65fe8034-85d5-4402-a91a-482d3e516763",
        return_at_close: 1.0000693576480877,
        trade_pair: ["NZDCAD", "NZD/CAD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 1.7993800000000002,
        close_ms: 1713326905519,
        current_return: 0.9999949982771843,
        initial_entry_price: 1.79938,
        is_closed_position: true,
        miner_hotkey: "5HTVmEBicnSE1iFrSRtYnmyv5bhj6SRmiZHT3Twfbh9jon14",
        net_leverage: 0.0,
        open_ms: 1713326256379,
        orders: [
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "6d32f2a2-b311-44a2-adbb-4a2aa47abd1c",
            price: 1.79938,
            price_sources: [],
            processed_ms: 1713326256372,
          },
          {
            leverage: 0.3,
            order_type: "FLAT",
            order_uuid: "38d0e03f-74b5-4ce8-a2ae-3bdc3babef54",
            price: 1.79935,
            price_sources: [],
            processed_ms: 1713326905519,
          },
        ],
        position_type: "FLAT",
        position_uuid: "e147c841-dd08-4458-ae00-142af645f37c",
        return_at_close: 0.9999739983822205,
        trade_pair: ["EURNZD", "EUR/NZD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.81614100495,
        close_ms: 1713327345473,
        current_return: 0.9999952318409058,
        initial_entry_price: 0.81612,
        is_closed_position: true,
        miner_hotkey: "5HTVmEBicnSE1iFrSRtYnmyv5bhj6SRmiZHT3Twfbh9jon14",
        net_leverage: 0.0,
        open_ms: 1713326888505,
        orders: [
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "e0ae0f2e-5209-4a32-b5fc-21f8c320d8ea",
            price: 0.81612,
            price_sources: [],
            processed_ms: 1713326888499,
          },
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "48d44640-3de6-42f4-9e58-f84ac8be253e",
            price: 0.8161620099,
            price_sources: [],
            processed_ms: 1713327009448,
          },
          {
            leverage: 0.6,
            order_type: "FLAT",
            order_uuid: "a97f5172-33a2-492f-8b5a-abb50b864b3a",
            price: 0.8161345193,
            price_sources: [],
            processed_ms: 1713327345473,
          },
        ],
        position_type: "FLAT",
        position_uuid: "d982c6f4-da78-409e-b269-d48600a68a07",
        return_at_close: 0.9999532320411685,
        trade_pair: ["NZDCAD", "NZD/CAD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.88661122645,
        close_ms: 1713327636512,
        current_return: 1.0000397772576533,
        initial_entry_price: 0.88654,
        is_closed_position: true,
        miner_hotkey: "5HTVmEBicnSE1iFrSRtYnmyv5bhj6SRmiZHT3Twfbh9jon14",
        net_leverage: 0.0,
        open_ms: 1713326725467,
        orders: [
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "7dcec629-c053-4d36-bc29-e0489c4aa256",
            price: 0.88654,
            price_sources: [],
            processed_ms: 1713326725461,
          },
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "04ebf746-065c-4cd0-8e15-3c5b1f6eef0c",
            price: 0.8866824529,
            price_sources: [],
            processed_ms: 1713327272949,
          },
          {
            leverage: 0.6,
            order_type: "FLAT",
            order_uuid: "047a90de-78f6-4572-96b3-c5ca09c70508",
            price: 0.88667,
            price_sources: [],
            processed_ms: 1713327636512,
          },
        ],
        position_type: "FLAT",
        position_uuid: "c1aac655-d5b9-42fe-8123-0129d31c0939",
        return_at_close: 0.9999977755870084,
        trade_pair: ["AUDCAD", "AUD/CAD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.88671,
        close_ms: 1713328077488,
        current_return: 0.9998951179077714,
        initial_entry_price: 0.88671,
        is_closed_position: true,
        miner_hotkey: "5HTVmEBicnSE1iFrSRtYnmyv5bhj6SRmiZHT3Twfbh9jon14",
        net_leverage: 0.0,
        open_ms: 1713327776466,
        orders: [
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "bba74f1b-d4bd-4f9d-83e0-954532684766",
            price: 0.88671,
            price_sources: [],
            processed_ms: 1713327776460,
          },
          {
            leverage: 0.3,
            order_type: "FLAT",
            order_uuid: "7476cc2f-ce50-412e-824f-ecde52c067e9",
            price: 0.8864,
            price_sources: [],
            processed_ms: 1713328077488,
          },
        ],
        position_type: "FLAT",
        position_uuid: "61cdb154-3a97-483c-9f3a-5adde2b550d3",
        return_at_close: 0.9998741201102953,
        trade_pair: ["AUDCAD", "AUD/CAD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 1.8034075000000003,
        close_ms: 1713387232859,
        current_return: 1.0003077196036791,
        initial_entry_price: 1.80359,
        is_closed_position: true,
        miner_hotkey: "5HTVmEBicnSE1iFrSRtYnmyv5bhj6SRmiZHT3Twfbh9jon14",
        net_leverage: 0.0,
        open_ms: 1713380491778,
        orders: [
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "2de4d628-7077-4bc2-bb44-09a8d614d078",
            price: 1.80359,
            price_sources: [],
            processed_ms: 1713380487311,
          },
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "3ca65526-ede8-4115-8735-9ced996c4129",
            price: 1.80404,
            price_sources: [],
            processed_ms: 1713382753529,
          },
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "a570920a-6b68-439f-bfda-c05c7b5601c0",
            price: 1.8026,
            price_sources: [],
            processed_ms: 1713383784648,
          },
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "334078dd-2cae-43fb-83ba-cad097ff569c",
            price: 1.8034,
            price_sources: [],
            processed_ms: 1713384574632,
          },
          {
            leverage: 1.2,
            order_type: "FLAT",
            order_uuid: "96b92728-9f5d-4387-b608-364b18c71f79",
            price: 1.80387,
            price_sources: [],
            processed_ms: 1713387232859,
          },
        ],
        position_type: "FLAT",
        position_uuid: "988bdb26-b06a-4c44-b97e-d0e018ea1e30",
        return_at_close: 1.0002236937552325,
        trade_pair: ["EURNZD", "EUR/NZD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.8864503437021268,
        close_ms: 1713387825980,
        current_return: 1.000717903591672,
        initial_entry_price: 0.8867,
        is_closed_position: true,
        miner_hotkey: "5HTVmEBicnSE1iFrSRtYnmyv5bhj6SRmiZHT3Twfbh9jon14",
        net_leverage: 0.0,
        open_ms: 1713379316529,
        orders: [
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "25ce08b1-7393-4ca8-950b-f50df3d341ac",
            price: 0.8867,
            price_sources: [],
            processed_ms: 1713379316520,
          },
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "a0dd2ae3-2b44-457f-a25f-4cc84da7e68a",
            price: 0.886797255066207,
            price_sources: [],
            processed_ms: 1713379919003,
          },
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "cc36b2a8-a596-47d2-a732-cd311a9350b3",
            price: 0.886347105735748,
            price_sources: [],
            processed_ms: 1713380366571,
          },
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "3c4a752f-70aa-42dd-b511-ab5419ff993c",
            price: 0.886337317773003,
            price_sources: [],
            processed_ms: 1713380922249,
          },
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "20f3a5f2-23b2-4b96-9560-fb18e4106b9c",
            price: 0.886340383637803,
            price_sources: [],
            processed_ms: 1713384697827,
          },
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "641e0ae2-97b8-49c3-89d8-6790e5469ac6",
            price: 0.88618,
            price_sources: [],
            processed_ms: 1713387140760,
          },
          {
            leverage: 1.8,
            order_type: "FLAT",
            order_uuid: "66530db2-6358-4b2d-b57d-57f605511c43",
            price: 0.886803990988091,
            price_sources: [],
            processed_ms: 1713387825980,
          },
        ],
        position_type: "FLAT",
        position_uuid: "38fe5e7d-3e39-4a10-8f0e-3e6516c93572",
        return_at_close: 1.0005918131358196,
        trade_pair: ["AUDCAD", "AUD/CAD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.8850020879509168,
        close_ms: 1713388277656,
        current_return: 1.0004583181889897,
        initial_entry_price: 0.885002087950917,
        is_closed_position: true,
        miner_hotkey: "5HTVmEBicnSE1iFrSRtYnmyv5bhj6SRmiZHT3Twfbh9jon14",
        net_leverage: 0.0,
        open_ms: 1713387920592,
        orders: [
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "412ff271-f192-4c3b-84dd-2cc3c2efb20f",
            price: 0.885002087950917,
            price_sources: [],
            processed_ms: 1713387920585,
          },
          {
            leverage: 0.3,
            order_type: "FLAT",
            order_uuid: "132b5a8a-831b-4b79-9dbe-430043e68850",
            price: 0.886354129798256,
            price_sources: [],
            processed_ms: 1713388277656,
          },
        ],
        position_type: "FLAT",
        position_uuid: "0a311131-3c8b-4ebe-8694-ee948d46a1fd",
        return_at_close: 1.0004373085643077,
        trade_pair: ["AUDCAD", "AUD/CAD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.641445,
        close_ms: 1713388569267,
        current_return: 1.0004025900900901,
        initial_entry_price: 0.63936,
        is_closed_position: true,
        miner_hotkey: "5HTVmEBicnSE1iFrSRtYnmyv5bhj6SRmiZHT3Twfbh9jon14",
        net_leverage: 0.0,
        open_ms: 1713387910738,
        orders: [
          {
            leverage: 0.06,
            order_type: "LONG",
            order_uuid: "70f8728f-2e17-42a4-8ca7-9c7eeab4851b",
            price: 0.63936,
            price_sources: [],
            processed_ms: 1713387905209,
          },
          {
            leverage: 0.06,
            order_type: "LONG",
            order_uuid: "db953ebe-d5a3-40cc-a33d-3e7def1ba07d",
            price: 0.64353,
            price_sources: [],
            processed_ms: 1713388234234,
          },
          {
            leverage: 0.12,
            order_type: "FLAT",
            order_uuid: "fd4b3998-6452-478c-a9a4-5f382965c577",
            price: 0.64359,
            price_sources: [],
            processed_ms: 1713388569267,
          },
        ],
        position_type: "FLAT",
        position_uuid: "fb785c6b-23a0-4ce3-8518-1fdf027ae467",
        return_at_close: 1.0003941867083335,
        trade_pair: ["AUDUSD", "AUD/USD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.8148167477053572,
        close_ms: 1713391314299,
        current_return: 0.9998258417126169,
        initial_entry_price: 0.8161432461,
        is_closed_position: true,
        miner_hotkey: "5HTVmEBicnSE1iFrSRtYnmyv5bhj6SRmiZHT3Twfbh9jon14",
        net_leverage: 0.0,
        open_ms: 1713327615475,
        orders: [
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "362eba8c-88b7-4e86-9b99-636b8724b59a",
            price: 0.8161432461,
            price_sources: [],
            processed_ms: 1713327615469,
          },
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "c313c265-3341-493a-a166-5689b5279e1e",
            price: 0.81613,
            price_sources: [],
            processed_ms: 1713327807407,
          },
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "c7ec5c3c-3173-49e4-bc6d-86add8e1cece",
            price: 0.81617,
            price_sources: [],
            processed_ms: 1713328050454,
          },
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "5d081a7d-8ffb-4bc6-a821-9dccf7c6e8cc",
            price: 0.8151426694,
            price_sources: [],
            processed_ms: 1713379284757,
          },
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "2ad90732-821f-4ed5-8bcb-6f7c3536fb75",
            price: 0.81513,
            price_sources: [],
            processed_ms: 1713379438180,
          },
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "d9d33462-6a95-403f-8949-aa3340dab249",
            price: 0.81509,
            price_sources: [],
            processed_ms: 1713379986512,
          },
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "600db603-cb26-4a3a-bdaa-e9b42483809b",
            price: 0.81491,
            price_sources: [],
            processed_ms: 1713380102578,
          },
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "63b4e04e-f194-4e86-9e8f-6bc738b20170",
            price: 0.8144,
            price_sources: [],
            processed_ms: 1713380633834,
          },
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "478fd464-9745-44a1-bed9-4efe77f25190",
            price: 0.81488,
            price_sources: [],
            processed_ms: 1713384722558,
          },
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "43eeed0c-5d10-430a-b642-7f300cee34e7",
            price: 0.81376,
            price_sources: [],
            processed_ms: 1713388210651,
          },
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "cb546ae2-00dd-46ed-8a99-91c2a747f4a4",
            price: 0.81401,
            price_sources: [],
            processed_ms: 1713388333841,
          },
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "96544fcb-c246-4409-8d1e-aeeb6eac534e",
            price: 0.8143,
            price_sources: [],
            processed_ms: 1713388587874,
          },
          {
            leverage: 1.0,
            order_type: "LONG",
            order_uuid: "89b3645c-b9da-4c29-b85b-c99bf6281b96",
            price: 0.8142940125,
            price_sources: [],
            processed_ms: 1713390133078,
          },
          {
            leverage: 1.0,
            order_type: "LONG",
            order_uuid: "54b23bce-c93e-4f7c-9f95-8895bb464150",
            price: 0.81466,
            price_sources: [],
            processed_ms: 1713390231621,
          },
          {
            leverage: 5.6,
            order_type: "FLAT",
            order_uuid: "06011737-8362-4669-8022-af985c3603df",
            price: 0.8147913659,
            price_sources: [],
            processed_ms: 1713391314299,
          },
        ],
        position_type: "FLAT",
        position_uuid: "6ba6fca0-f3f4-4d53-aeb7-cb6e1dd7bf50",
        return_at_close: 0.9994339099826656,
        trade_pair: ["NZDCAD", "NZD/CAD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 169.3869991833327,
        close_ms: 1713391563590,
        current_return: 1.0032113278820642,
        initial_entry_price: 169.5028857727801,
        is_closed_position: true,
        miner_hotkey: "5HTVmEBicnSE1iFrSRtYnmyv5bhj6SRmiZHT3Twfbh9jon14",
        net_leverage: 0.0,
        open_ms: 1713387008905,
        orders: [
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "eff112cd-d59f-48ed-94d4-510345aabe50",
            price: 169.5028857727801,
            price_sources: [],
            processed_ms: 1713387008894,
          },
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "769248a6-d839-4ba0-97bc-86342aff6515",
            price: 169.5,
            price_sources: [],
            processed_ms: 1713387069538,
          },
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "3e7b2ffc-a502-4802-a800-6d9da6a3b4aa",
            price: 169.508,
            price_sources: [],
            processed_ms: 1713387157561,
          },
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "7e4fefa8-05a6-456c-bbf4-adb5f10b2f57",
            price: 169.513,
            price_sources: [],
            processed_ms: 1713387251583,
          },
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "07ddc572-76bf-40cc-9926-46ea36c473ad",
            price: 169.494,
            price_sources: [],
            processed_ms: 1713387315645,
          },
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "866056e1-8a9b-4fb6-992e-6be40c167040",
            price: 169.4319011715817,
            price_sources: [],
            processed_ms: 1713387394926,
          },
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "7a3eddfd-86f1-4500-bcbd-e10eb6de2058",
            price: 169.235,
            price_sources: [],
            processed_ms: 1713387470272,
          },
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "33b6fd76-39c3-4b2c-abc9-4bcabf312f82",
            price: 169.4296468458833,
            price_sources: [],
            processed_ms: 1713387683711,
          },
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "aecbb330-bcfe-4615-b65a-b3d26ea25024",
            price: 169.1910162518001,
            price_sources: [],
            processed_ms: 1713387846915,
          },
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "f6a2aa63-1296-4a28-806d-9a4aa6e4f740",
            price: 169.4435873701533,
            price_sources: [],
            processed_ms: 1713387923073,
          },
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "2e58f013-ac45-4252-8853-73406d483bb2",
            price: 169.45,
            price_sources: [],
            processed_ms: 1713388053644,
          },
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "c0091a9f-c6a5-4692-a361-23f8caada6da",
            price: 169.4301584036207,
            price_sources: [],
            processed_ms: 1713388123770,
          },
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "c7294638-a19d-4d12-b2c9-cceee60dc29f",
            price: 168.9885104636849,
            price_sources: [],
            processed_ms: 1713388298974,
          },
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "e3164ebc-413b-4be8-b7ec-66fa16d4fa38",
            price: 169.044,
            price_sources: [],
            processed_ms: 1713388445558,
          },
          {
            leverage: 1.0,
            order_type: "LONG",
            order_uuid: "c191e1d6-5f11-4954-9538-4f1634ec8b79",
            price: 169.4167266742105,
            price_sources: [],
            processed_ms: 1713390088955,
          },
          {
            leverage: 1.0,
            order_type: "LONG",
            order_uuid: "6ea69723-431b-45c8-881f-9a64e38283b0",
            price: 169.4341563786008,
            price_sources: [],
            processed_ms: 1713390157783,
          },
          {
            leverage: 6.499999999999999,
            order_type: "FLAT",
            order_uuid: "7b3bcf62-1d3e-4258-80ec-c2b2a410fe36",
            price: 169.4747942386831,
            price_sources: [],
            processed_ms: 1713391563590,
          },
        ],
        position_type: "FLAT",
        position_uuid: "905a2ca8-3e21-4f2e-9552-d741db35ed96",
        return_at_close: 1.0027759341657634,
        trade_pair: ["CHFJPY", "CHF/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.5913559523809524,
        close_ms: 1713391902970,
        current_return: 1.000516990701606,
        initial_entry_price: 0.5915,
        is_closed_position: true,
        miner_hotkey: "5HTVmEBicnSE1iFrSRtYnmyv5bhj6SRmiZHT3Twfbh9jon14",
        net_leverage: 0.0,
        open_ms: 1713387666685,
        orders: [
          {
            leverage: 0.06,
            order_type: "LONG",
            order_uuid: "3de51f9d-6f89-498e-88fc-b9921af75153",
            price: 0.5915,
            price_sources: [],
            processed_ms: 1713387666672,
          },
          {
            leverage: 0.06,
            order_type: "LONG",
            order_uuid: "30481957-e48c-4cd4-88de-1d3022eb74c5",
            price: 0.5914,
            price_sources: [],
            processed_ms: 1713388032021,
          },
          {
            leverage: 0.06,
            order_type: "LONG",
            order_uuid: "f5646680-6eaf-4306-8453-2f8e0fe2908a",
            price: 0.5912,
            price_sources: [],
            processed_ms: 1713388212033,
          },
          {
            leverage: 0.06,
            order_type: "LONG",
            order_uuid: "39870ae1-f402-4e09-99e8-f3bf019d3926",
            price: 0.59125,
            price_sources: [],
            processed_ms: 1713388331782,
          },
          {
            leverage: 0.2,
            order_type: "LONG",
            order_uuid: "c4b4b11a-9fa5-4147-9ffc-bd34eefc9a50",
            price: 0.59158,
            price_sources: [],
            processed_ms: 1713390021817,
          },
          {
            leverage: 0.2,
            order_type: "LONG",
            order_uuid: "666bed88-f8bc-4bed-a565-c9d43ba3d51a",
            price: 0.59156,
            price_sources: [],
            processed_ms: 1713390281586,
          },
          {
            leverage: 0.2,
            order_type: "LONG",
            order_uuid: "08a1f68f-c436-4f3c-b8b9-44a241f2283a",
            price: 0.59095,
            price_sources: [],
            processed_ms: 1713390848578,
          },
          {
            leverage: 0.8400000000000001,
            order_type: "FLAT",
            order_uuid: "42fee040-1fe0-4d87-846f-af540b402a15",
            price: 0.59172,
            price_sources: [],
            processed_ms: 1713391902970,
          },
        ],
        position_type: "FLAT",
        position_uuid: "cc70b62b-9334-40e0-a76f-27d4989ffe66",
        return_at_close: 1.0004581603025529,
        trade_pair: ["NZDUSD", "NZD/USD", 7e-5, 0.001, 500],
      },
    ],
    thirty_day_returns: 1.0,
  },
  "5HVzpPQ3Todm6wGtVm7Xf2Dfzx2rcrRaSkduUsckQhWBumLG": {
    all_time_returns: 1.0,
    n_positions: 0,
    percentage_profitable: 0.0,
    positions: [
      {
        average_entry_price: 64333.58,
        close_ms: 1711195368208,
        current_return: 1.0023904467931055,
        initial_entry_price: 64333.58,
        is_closed_position: true,
        miner_hotkey: "5HVzpPQ3Todm6wGtVm7Xf2Dfzx2rcrRaSkduUsckQhWBumLG",
        net_leverage: 0.0,
        open_ms: 1711191793752,
        orders: [
          {
            leverage: 0.6,
            order_type: "LONG",
            order_uuid: "bf389f3e-1a3b-4f93-85f3-09eec3fb700d",
            price: 64333.58,
            price_sources: [],
            processed_ms: 1711191793752,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "b93920b9-2142-40d0-a7de-92ac24191304",
            price: 64589.89,
            price_sources: [],
            processed_ms: 1711195368208,
          },
        ],
        position_type: "FLAT",
        position_uuid: "b93387c3-2b44-4106-b4da-094722d7ee80",
        return_at_close: 1.0011875782569537,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 64569.16,
        close_ms: 1711199040687,
        current_return: 1.0003382419718638,
        initial_entry_price: 64569.16,
        is_closed_position: true,
        miner_hotkey: "5HVzpPQ3Todm6wGtVm7Xf2Dfzx2rcrRaSkduUsckQhWBumLG",
        net_leverage: 0.0,
        open_ms: 1711195454882,
        orders: [
          {
            leverage: 0.6,
            order_type: "LONG",
            order_uuid: "4cc7537f-1327-4547-94fb-cde5e8854370",
            price: 64569.16,
            price_sources: [],
            processed_ms: 1711195454881,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "eb5bf833-d1ca-4191-9d8f-39cb66500794",
            price: 64605.56,
            price_sources: [],
            processed_ms: 1711199040687,
          },
        ],
        position_type: "FLAT",
        position_uuid: "5b2dd961-c137-4276-b11a-317a35490f05",
        return_at_close: 0.9991378360814976,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 64616.72,
        close_ms: 1711202687999,
        current_return: 0.9999668506850858,
        initial_entry_price: 64616.72,
        is_closed_position: true,
        miner_hotkey: "5HVzpPQ3Todm6wGtVm7Xf2Dfzx2rcrRaSkduUsckQhWBumLG",
        net_leverage: 0.0,
        open_ms: 1711199128172,
        orders: [
          {
            leverage: 0.6,
            order_type: "LONG",
            order_uuid: "26d7b82f-3037-46f6-9186-ff2065c67320",
            price: 64616.72,
            price_sources: [],
            processed_ms: 1711199128171,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "49c135a3-bb71-431b-9e46-a2b67d444db4",
            price: 64613.15,
            price_sources: [],
            processed_ms: 1711202687999,
          },
        ],
        position_type: "FLAT",
        position_uuid: "9883eb7e-a95a-46f4-8aa8-0abffb485511",
        return_at_close: 0.9987668904642638,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 64618.93,
        close_ms: 1711206361193,
        current_return: 1.005432494781328,
        initial_entry_price: 64618.93,
        is_closed_position: true,
        miner_hotkey: "5HVzpPQ3Todm6wGtVm7Xf2Dfzx2rcrRaSkduUsckQhWBumLG",
        net_leverage: 0.0,
        open_ms: 1711202823530,
        orders: [
          {
            leverage: 0.6,
            order_type: "LONG",
            order_uuid: "b2b90581-f622-47c8-a768-c90c778464fe",
            price: 64618.93,
            price_sources: [],
            processed_ms: 1711202823530,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "97c2f2a7-9b63-499a-a2af-32dc59ae8c21",
            price: 65204.0,
            price_sources: [],
            processed_ms: 1711206361193,
          },
        ],
        position_type: "FLAT",
        position_uuid: "dcd6cefd-5249-4bcd-a6e0-9394ffa09809",
        return_at_close: 1.0042259757875904,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 65261.01,
        close_ms: 1711210037843,
        current_return: 0.9976420530420844,
        initial_entry_price: 65261.01,
        is_closed_position: true,
        miner_hotkey: "5HVzpPQ3Todm6wGtVm7Xf2Dfzx2rcrRaSkduUsckQhWBumLG",
        net_leverage: 0.0,
        open_ms: 1711206448073,
        orders: [
          {
            leverage: 0.6,
            order_type: "LONG",
            order_uuid: "5c03395c-7ddb-4482-ab5e-6753cc05e028",
            price: 65261.01,
            price_sources: [],
            processed_ms: 1711206448072,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "76cdbd54-865f-4cbf-900e-4ae7ac1d94c4",
            price: 65004.54,
            price_sources: [],
            processed_ms: 1711210037843,
          },
        ],
        position_type: "FLAT",
        position_uuid: "21928f2c-64b4-4c4d-bd95-eb59b914c4f1",
        return_at_close: 0.9964448825784339,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 64933.68,
        close_ms: 1711213687667,
        current_return: 1.0061183964931604,
        initial_entry_price: 64933.68,
        is_closed_position: true,
        miner_hotkey: "5HVzpPQ3Todm6wGtVm7Xf2Dfzx2rcrRaSkduUsckQhWBumLG",
        net_leverage: 0.0,
        open_ms: 1711210124807,
        orders: [
          {
            leverage: 0.6,
            order_type: "LONG",
            order_uuid: "cbde15c3-3e00-4a26-a0d9-f53857bf477b",
            price: 64933.68,
            price_sources: [],
            processed_ms: 1711210124806,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "80163e72-b179-4fe4-93b8-bc3ae02a40f0",
            price: 65595.83,
            price_sources: [],
            processed_ms: 1711213687667,
          },
        ],
        position_type: "FLAT",
        position_uuid: "7556f615-ed01-4848-8a98-501880a876af",
        return_at_close: 1.0049110544173685,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 65637.95,
        close_ms: 1711217350843,
        current_return: 1.0000768762583232,
        initial_entry_price: 65637.95,
        is_closed_position: true,
        miner_hotkey: "5HVzpPQ3Todm6wGtVm7Xf2Dfzx2rcrRaSkduUsckQhWBumLG",
        net_leverage: 0.0,
        open_ms: 1711213823468,
        orders: [
          {
            leverage: 0.6,
            order_type: "LONG",
            order_uuid: "eac9d2f6-e262-4e9d-9799-de9daab32b61",
            price: 65637.95,
            price_sources: [],
            processed_ms: 1711213823467,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "c4b36f31-0b7f-40fb-94f2-07c2a059a0f7",
            price: 65646.36,
            price_sources: [],
            processed_ms: 1711217350843,
          },
        ],
        position_type: "FLAT",
        position_uuid: "db4e3952-cee6-474a-a4f8-d0abcd71b00f",
        return_at_close: 0.9988767840068132,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 65656.23,
        close_ms: 1711221044211,
        current_return: 0.9971477801878056,
        initial_entry_price: 65656.23,
        is_closed_position: true,
        miner_hotkey: "5HVzpPQ3Todm6wGtVm7Xf2Dfzx2rcrRaSkduUsckQhWBumLG",
        net_leverage: 0.0,
        open_ms: 1711217437989,
        orders: [
          {
            leverage: 0.6,
            order_type: "LONG",
            order_uuid: "6f59f7d6-5112-435d-b72d-d625afd0a674",
            price: 65656.23,
            price_sources: [],
            processed_ms: 1711217437988,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "761bcb53-7df6-4d46-b77f-f047bb7fb50d",
            price: 65344.12,
            price_sources: [],
            processed_ms: 1711221044211,
          },
        ],
        position_type: "FLAT",
        position_uuid: "8a4b9906-4b21-4b94-861e-25f39f748d48",
        return_at_close: 0.9959512028515802,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 65344.12,
        close_ms: 1711224670551,
        current_return: 0.9940792530376107,
        initial_entry_price: 65344.12,
        is_closed_position: true,
        miner_hotkey: "5HVzpPQ3Todm6wGtVm7Xf2Dfzx2rcrRaSkduUsckQhWBumLG",
        net_leverage: 0.0,
        open_ms: 1711221064151,
        orders: [
          {
            leverage: 0.6,
            order_type: "LONG",
            order_uuid: "c9bebc2e-529a-4f01-9558-91c78a83e4c2",
            price: 65344.12,
            price_sources: [],
            processed_ms: 1711221064150,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "7eec7e89-2ab1-4bbf-a438-2526f257466a",
            price: 64699.31,
            price_sources: [],
            processed_ms: 1711224670551,
          },
        ],
        position_type: "FLAT",
        position_uuid: "66b9b79c-9d88-4113-a32f-918bf48ccd3b",
        return_at_close: 0.9928863579339656,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 64842.105,
        close_ms: 1711228394130,
        current_return: 1.0018283545550393,
        initial_entry_price: 64743.46,
        is_closed_position: true,
        miner_hotkey: "5HVzpPQ3Todm6wGtVm7Xf2Dfzx2rcrRaSkduUsckQhWBumLG",
        net_leverage: 0.0,
        open_ms: 1711224756966,
        orders: [
          {
            leverage: 0.6,
            order_type: "LONG",
            order_uuid: "494aa631-9d4d-49c2-a37d-56d04b916fa4",
            price: 64743.46,
            price_sources: [],
            processed_ms: 1711224756965,
          },
          {
            leverage: 0.6,
            order_type: "LONG",
            order_uuid: "63e37f64-a5d8-4c87-85a1-b7efecc272a4",
            price: 64940.75,
            price_sources: [],
            processed_ms: 1711228375283,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "e5d55a5d-4eb1-4b95-a135-0a9c123d7fdf",
            price: 64940.75,
            price_sources: [],
            processed_ms: 1711228394130,
          },
        ],
        position_type: "FLAT",
        position_uuid: "c007b4a1-3d2a-4d9b-890c-aae21fbcfce4",
        return_at_close: 0.9994239665041073,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 64773.46000000001,
        close_ms: 1711235710978,
        current_return: 0.9970654335865459,
        initial_entry_price: 64932.25,
        is_closed_position: true,
        miner_hotkey: "5HVzpPQ3Todm6wGtVm7Xf2Dfzx2rcrRaSkduUsckQhWBumLG",
        net_leverage: 0.0,
        open_ms: 1711232080166,
        orders: [
          {
            leverage: 0.6,
            order_type: "LONG",
            order_uuid: "bfefad9e-3fef-41ad-bfb8-d5f999bfcdf7",
            price: 64932.25,
            price_sources: [],
            processed_ms: 1711232080165,
          },
          {
            leverage: 0.6,
            order_type: "LONG",
            order_uuid: "096975d5-4728-473e-8630-9f8ecdbbdbff",
            price: 64614.67,
            price_sources: [],
            processed_ms: 1711235692060,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "682b61b2-ad80-4c87-8ce5-1ad02faa22c7",
            price: 64614.67,
            price_sources: [],
            processed_ms: 1711235710978,
          },
        ],
        position_type: "FLAT",
        position_uuid: "df830db2-bbd4-41d7-9680-d12e038e72ab",
        return_at_close: 0.9946724765459382,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 64273.35999999999,
        close_ms: 1711242986809,
        current_return: 1.0011782797725217,
        initial_entry_price: 64273.36,
        is_closed_position: true,
        miner_hotkey: "5HVzpPQ3Todm6wGtVm7Xf2Dfzx2rcrRaSkduUsckQhWBumLG",
        net_leverage: 0.0,
        open_ms: 1711239459928,
        orders: [
          {
            leverage: 0.6,
            order_type: "LONG",
            order_uuid: "fbc7d854-8d02-4293-8300-41b26992f91d",
            price: 64273.36,
            price_sources: [],
            processed_ms: 1711239459926,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "6feda228-829c-43c0-b93a-64bc9cb40c08",
            price: 64399.58,
            price_sources: [],
            processed_ms: 1711242986809,
          },
        ],
        position_type: "FLAT",
        position_uuid: "d6e41801-b9f2-4845-a8ad-7eb70ccd2bbb",
        return_at_close: 0.9999768658367947,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 64428.68,
        close_ms: 1711246655912,
        current_return: 0.9999469180495395,
        initial_entry_price: 64428.68,
        is_closed_position: true,
        miner_hotkey: "5HVzpPQ3Todm6wGtVm7Xf2Dfzx2rcrRaSkduUsckQhWBumLG",
        net_leverage: 0.0,
        open_ms: 1711243074963,
        orders: [
          {
            leverage: 0.6,
            order_type: "LONG",
            order_uuid: "d9b3e278-e689-42a9-a4bb-f53002a4cff6",
            price: 64428.68,
            price_sources: [],
            processed_ms: 1711243074962,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "70d601ec-a64f-4c07-89d7-19231ff5b747",
            price: 64422.98,
            price_sources: [],
            processed_ms: 1711246655912,
          },
        ],
        position_type: "FLAT",
        position_uuid: "3a79a05b-8101-47b3-a890-f262b8fe71c8",
        return_at_close: 0.9987469817478801,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 64362.479999999996,
        close_ms: 1711250359623,
        current_return: 0.9984502772422691,
        initial_entry_price: 64362.48,
        is_closed_position: true,
        miner_hotkey: "5HVzpPQ3Todm6wGtVm7Xf2Dfzx2rcrRaSkduUsckQhWBumLG",
        net_leverage: 0.0,
        open_ms: 1711246772343,
        orders: [
          {
            leverage: 0.6,
            order_type: "LONG",
            order_uuid: "af35a6dc-3e4f-47dd-9bee-8304429a39a5",
            price: 64362.48,
            price_sources: [],
            processed_ms: 1711246771315,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "c36b09f6-7099-4b80-91c5-e35d71442e99",
            price: 64196.24,
            price_sources: [],
            processed_ms: 1711250359623,
          },
        ],
        position_type: "FLAT",
        position_uuid: "75f64411-2291-4795-922f-286fe479754c",
        return_at_close: 0.9972521369095784,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 64169.02,
        close_ms: 1711253994094,
        current_return: 0.9994254236701761,
        initial_entry_price: 64169.02,
        is_closed_position: true,
        miner_hotkey: "5HVzpPQ3Todm6wGtVm7Xf2Dfzx2rcrRaSkduUsckQhWBumLG",
        net_leverage: 0.0,
        open_ms: 1711250379195,
        orders: [
          {
            leverage: 0.6,
            order_type: "LONG",
            order_uuid: "ef329946-e75e-473e-8f1a-b97579393100",
            price: 64169.02,
            price_sources: [],
            processed_ms: 1711250379194,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "f2cef5ff-ff68-430b-93d9-f10c89e060f1",
            price: 64107.57,
            price_sources: [],
            processed_ms: 1711253994094,
          },
        ],
        position_type: "FLAT",
        position_uuid: "e3695e14-8759-48c9-99cb-b2bbd05c2db7",
        return_at_close: 0.9982261131617719,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 64040.45,
        close_ms: 1711260157405,
        current_return: 1.0025691886924593,
        initial_entry_price: 64040.45,
        is_closed_position: true,
        miner_hotkey: "5HVzpPQ3Todm6wGtVm7Xf2Dfzx2rcrRaSkduUsckQhWBumLG",
        net_leverage: 0.0,
        open_ms: 1711254096826,
        orders: [
          {
            leverage: 0.6,
            order_type: "LONG",
            order_uuid: "e2d862cd-8a15-4ba9-9774-dd4713ffd207",
            price: 64040.45,
            price_sources: [],
            processed_ms: 1711254096825,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "354c40a3-7916-447e-b870-a1edbab9aaa0",
            price: 64314.67,
            price_sources: [],
            processed_ms: 1711260157405,
          },
        ],
        position_type: "FLAT",
        position_uuid: "6577e180-4bdf-443d-b4f7-59ec9988c0d7",
        return_at_close: 1.0013661056660283,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 64239.62999999999,
        close_ms: 1711263828829,
        current_return: 0.9984785482108165,
        initial_entry_price: 64239.63,
        is_closed_position: true,
        miner_hotkey: "5HVzpPQ3Todm6wGtVm7Xf2Dfzx2rcrRaSkduUsckQhWBumLG",
        net_leverage: 0.0,
        open_ms: 1711260296713,
        orders: [
          {
            leverage: 1.25,
            order_type: "LONG",
            order_uuid: "7c59e98d-9ed1-40f3-bb8f-7e4ae9b56f68",
            price: 64239.63,
            price_sources: [],
            processed_ms: 1711260296712,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "5f1db368-0228-4090-abb5-54ed48897b88",
            price: 64161.44,
            price_sources: [],
            processed_ms: 1711263828829,
          },
        ],
        position_type: "FLAT",
        position_uuid: "0ea7ac85-121e-47c3-9c6b-56875ee49a23",
        return_at_close: 0.9959823518402895,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 64133.48,
        close_ms: 1711267484735,
        current_return: 1.009910385340075,
        initial_entry_price: 64133.48,
        is_closed_position: true,
        miner_hotkey: "5HVzpPQ3Todm6wGtVm7Xf2Dfzx2rcrRaSkduUsckQhWBumLG",
        net_leverage: 0.0,
        open_ms: 1711263915762,
        orders: [
          {
            leverage: 1.25,
            order_type: "LONG",
            order_uuid: "a352de71-df1c-419c-9a4b-5ddd2fa70dfd",
            price: 64133.48,
            price_sources: [],
            processed_ms: 1711263915761,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "522cb3bb-7c9b-4865-ac50-b6fc0b4cafe5",
            price: 64641.95,
            price_sources: [],
            processed_ms: 1711267484735,
          },
        ],
        position_type: "FLAT",
        position_uuid: "961c7ea2-bb55-42e0-baf8-dd44854f50f8",
        return_at_close: 1.0073856093767248,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 64630.33999999999,
        close_ms: 1711271210087,
        current_return: 1.0080811504318252,
        initial_entry_price: 64630.34,
        is_closed_position: true,
        miner_hotkey: "5HVzpPQ3Todm6wGtVm7Xf2Dfzx2rcrRaSkduUsckQhWBumLG",
        net_leverage: 0.0,
        open_ms: 1711267571691,
        orders: [
          {
            leverage: 1.25,
            order_type: "LONG",
            order_uuid: "4aa29802-4ba2-44a9-8b33-2776db86c03e",
            price: 64630.34,
            price_sources: [],
            processed_ms: 1711267571689,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "d14cdeed-15f1-4394-be96-ce48b7a1cc6b",
            price: 65048.17,
            price_sources: [],
            processed_ms: 1711271210087,
          },
        ],
        position_type: "FLAT",
        position_uuid: "528687af-e25c-4103-8da4-1438c532b8de",
        return_at_close: 1.0055609475557457,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 64988.3,
        close_ms: 1711278479807,
        current_return: 0.9998838252423897,
        initial_entry_price: 64988.3,
        is_closed_position: true,
        miner_hotkey: "5HVzpPQ3Todm6wGtVm7Xf2Dfzx2rcrRaSkduUsckQhWBumLG",
        net_leverage: 0.0,
        open_ms: 1711274899800,
        orders: [
          {
            leverage: 1.25,
            order_type: "LONG",
            order_uuid: "5f3fc7ce-83f6-4046-b061-456b4849b52d",
            price: 64988.3,
            price_sources: [],
            processed_ms: 1711274899798,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "370b0421-81f4-474d-88c6-ab3e4c4c7220",
            price: 64982.26,
            price_sources: [],
            processed_ms: 1711278479807,
          },
        ],
        position_type: "FLAT",
        position_uuid: "d46b97be-98fb-4b81-9ad5-436c0cadf451",
        return_at_close: 0.9973841156792838,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 64968.58999999999,
        close_ms: 1711282150933,
        current_return: 1.012724995263096,
        initial_entry_price: 64968.59,
        is_closed_position: true,
        miner_hotkey: "5HVzpPQ3Todm6wGtVm7Xf2Dfzx2rcrRaSkduUsckQhWBumLG",
        net_leverage: 0.0,
        open_ms: 1711278582764,
        orders: [
          {
            leverage: 1.25,
            order_type: "LONG",
            order_uuid: "df6c0dfa-73ee-44b2-b830-5b83f3f27c02",
            price: 64968.59,
            price_sources: [],
            processed_ms: 1711278582762,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "3618dc4b-04a6-47ad-8fd3-7ff19b2b5d8a",
            price: 65629.97,
            price_sources: [],
            processed_ms: 1711282150933,
          },
        ],
        position_type: "FLAT",
        position_uuid: "321d8328-0c03-4539-96f5-1aa42c7e653d",
        return_at_close: 1.0101931827749382,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 65628.38999999998,
        close_ms: 1711299999648,
        current_return: 0.9713615276942786,
        initial_entry_price: 65569.14,
        is_closed_position: true,
        miner_hotkey: "5HVzpPQ3Todm6wGtVm7Xf2Dfzx2rcrRaSkduUsckQhWBumLG",
        net_leverage: 0.0,
        open_ms: 1711284017491,
        orders: [
          {
            leverage: 1.25,
            order_type: "LONG",
            order_uuid: "7a086acc-af1e-47cb-afd1-7e199ef15403",
            price: 65569.14,
            price_sources: [],
            processed_ms: 1711284017489,
          },
          {
            leverage: 1.25,
            order_type: "LONG",
            order_uuid: "d68a14e1-1e81-4e69-89f5-11929213899b",
            price: 65461.7,
            price_sources: [],
            processed_ms: 1711287649002,
          },
          {
            leverage: -1.25,
            order_type: "SHORT",
            order_uuid: "fc6e436b-3aad-4e06-9a70-7d0c96ee9e47",
            price: 65419.33,
            price_sources: [],
            processed_ms: 1711289473945,
          },
          {
            leverage: 1.25,
            order_type: "LONG",
            order_uuid: "49ea8814-11d5-4fdb-a336-dbe7f1262253",
            price: 65645.27,
            price_sources: [],
            processed_ms: 1711298648086,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "848d45ab-72e3-4638-8696-b1cb94b36ba8",
            price: 64877.27,
            price_sources: [],
            processed_ms: 1711299999648,
          },
        ],
        position_type: "FLAT",
        position_uuid: "5f5007e4-e590-4b16-b535-0d5d811787ec",
        return_at_close: 0.9665047200558072,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 64700.99000000001,
        close_ms: 1711315009016,
        current_return: 1.032291375100026,
        initial_entry_price: 64807.94,
        is_closed_position: true,
        miner_hotkey: "5HVzpPQ3Todm6wGtVm7Xf2Dfzx2rcrRaSkduUsckQhWBumLG",
        net_leverage: 0.0,
        open_ms: 1711300479850,
        orders: [
          {
            leverage: 1.25,
            order_type: "LONG",
            order_uuid: "08f818ae-2a19-4a5c-a23c-bacc5788736f",
            price: 64807.94,
            price_sources: [],
            processed_ms: 1711300479848,
          },
          {
            leverage: 1.25,
            order_type: "LONG",
            order_uuid: "64e602e0-b479-44b5-8250-7a31794a792e",
            price: 65786.9,
            price_sources: [],
            processed_ms: 1711307555383,
          },
          {
            leverage: -1.25,
            order_type: "SHORT",
            order_uuid: "1db3fbce-0d9d-4476-94f5-6c83ce30a9db",
            price: 65724.5,
            price_sources: [],
            processed_ms: 1711308490873,
          },
          {
            leverage: 1.25,
            order_type: "LONG",
            order_uuid: "30663e7e-f54d-462f-bad5-e5cd6e8d50ab",
            price: 66198.02,
            price_sources: [],
            processed_ms: 1711311276777,
          },
          {
            leverage: -1.25,
            order_type: "SHORT",
            order_uuid: "d8fc2963-20b5-4b8f-a857-90ae40da86cd",
            price: 66367.37,
            price_sources: [],
            processed_ms: 1711312215943,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "47bd9814-52d9-4bac-b8ef-857a29172185",
            price: 66375.18,
            price_sources: [],
            processed_ms: 1711315009016,
          },
        ],
        position_type: "FLAT",
        position_uuid: "ee38a329-180a-439f-8cea-6377a306d3dd",
        return_at_close: 1.027129918224526,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 66353.09,
        close_ms: 1711317811989,
        current_return: 1.0067773784159866,
        initial_entry_price: 66353.09,
        is_closed_position: true,
        miner_hotkey: "5HVzpPQ3Todm6wGtVm7Xf2Dfzx2rcrRaSkduUsckQhWBumLG",
        net_leverage: 0.0,
        open_ms: 1711315942929,
        orders: [
          {
            leverage: 1.25,
            order_type: "LONG",
            order_uuid: "1e699e06-6b18-4bf7-a033-7abd78c8ea57",
            price: 66353.09,
            price_sources: [],
            processed_ms: 1711315942927,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "65c92635-2240-4634-b075-043acaa3d0f9",
            price: 66712.85,
            price_sources: [],
            processed_ms: 1711317811989,
          },
        ],
        position_type: "FLAT",
        position_uuid: "db9a00f3-51b3-4cf1-81c4-e1d337a76f62",
        return_at_close: 1.0042604349699467,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 67949.8885714285,
        close_ms: 1711326192613,
        current_return: 1.0027979188338965,
        initial_entry_price: 67174.84,
        is_closed_position: true,
        miner_hotkey: "5HVzpPQ3Todm6wGtVm7Xf2Dfzx2rcrRaSkduUsckQhWBumLG",
        net_leverage: 0.0,
        open_ms: 1711321525991,
        orders: [
          {
            leverage: -1.25,
            order_type: "SHORT",
            order_uuid: "468298af-6f83-4d4e-a8fb-56b5605434d9",
            price: 67174.84,
            price_sources: [],
            processed_ms: 1711321525989,
          },
          {
            leverage: 0.125,
            order_type: "LONG",
            order_uuid: "4972aafe-23f6-4eb1-bbfe-a615cec51ce1",
            price: 67567.32,
            price_sources: [],
            processed_ms: 1711322482489,
          },
          {
            leverage: 0.125,
            order_type: "LONG",
            order_uuid: "ec866c32-0a46-45d0-ac67-26147feeba03",
            price: 67271.93,
            price_sources: [],
            processed_ms: 1711323400151,
          },
          {
            leverage: 0.125,
            order_type: "LONG",
            order_uuid: "dbe42d0b-c333-4f95-b502-afac2dab2aa2",
            price: 67334.41,
            price_sources: [],
            processed_ms: 1711324331974,
          },
          {
            leverage: 0.7,
            order_type: "LONG",
            order_uuid: "8c254e0e-3c48-4eac-8cd8-9f5a75318af0",
            price: 66865.16,
            price_sources: [],
            processed_ms: 1711325258700,
          },
          {
            leverage: 1.25,
            order_type: "LONG",
            order_uuid: "e6b9b8ed-1f0c-4e95-8ee3-c10c4d8e07e6",
            price: 66875.89,
            price_sources: [],
            processed_ms: 1711326192613,
          },
        ],
        position_type: "FLAT",
        position_uuid: "71c668e2-155e-4f09-a75e-ab1f0f066aad",
        return_at_close: 1.0002909240368119,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 66644.13,
        close_ms: 1711332672844,
        current_return: 0.9975844957282921,
        initial_entry_price: 66601.0,
        is_closed_position: true,
        miner_hotkey: "5HVzpPQ3Todm6wGtVm7Xf2Dfzx2rcrRaSkduUsckQhWBumLG",
        net_leverage: 0.0,
        open_ms: 1711327123255,
        orders: [
          {
            leverage: 1.25,
            order_type: "LONG",
            order_uuid: "adc34f8f-d9c7-4ec5-bd54-5135649917bc",
            price: 66601.0,
            price_sources: [],
            processed_ms: 1711327123253,
          },
          {
            leverage: 1.25,
            order_type: "LONG",
            order_uuid: "3eac53a1-44e6-4dab-a5d0-2622eeba146e",
            price: 66687.26,
            price_sources: [],
            processed_ms: 1711330859705,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "78393376-0c0a-46ae-9874-ee3b7c7892cd",
            price: 66579.78,
            price_sources: [],
            processed_ms: 1711332672844,
          },
        ],
        position_type: "FLAT",
        position_uuid: "92c732c8-1a0f-497e-a700-054ebc53b7c9",
        return_at_close: 0.9925965732496507,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 66824.91,
        close_ms: 1711347326981,
        current_return: 0.9985793033008914,
        initial_entry_price: 67282.13,
        is_closed_position: true,
        miner_hotkey: "5HVzpPQ3Todm6wGtVm7Xf2Dfzx2rcrRaSkduUsckQhWBumLG",
        net_leverage: 0.0,
        open_ms: 1711340002575,
        orders: [
          {
            leverage: -1.25,
            order_type: "SHORT",
            order_uuid: "1051f25d-bbd7-4db4-ad1f-82bf1656e9a4",
            price: 67282.13,
            price_sources: [],
            processed_ms: 1711340002573,
          },
          {
            leverage: 0.625,
            order_type: "LONG",
            order_uuid: "c44614ed-7d31-492d-b377-68fc1d37dada",
            price: 67350.82,
            price_sources: [],
            processed_ms: 1711341828948,
          },
          {
            leverage: 0.125,
            order_type: "LONG",
            order_uuid: "54cae1a7-de60-44c8-939e-651e6ba62a1b",
            price: 67496.68,
            price_sources: [],
            processed_ms: 1711343669437,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "2f80f49d-4a34-41bf-ade2-b56e1c12aa52",
            price: 67460.35,
            price_sources: [],
            processed_ms: 1711345500989,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "621fc86d-2b58-416e-ac81-2289579d9503",
            price: 67207.26,
            price_sources: [],
            processed_ms: 1711347326981,
          },
        ],
        position_type: "FLAT",
        position_uuid: "209f964e-8526-4ea2-a187-252e40082d6d",
        return_at_close: 0.9960828550426393,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 67038.49,
        close_ms: 1711350983899,
        current_return: 1.0016263045304272,
        initial_entry_price: 67038.49,
        is_closed_position: true,
        miner_hotkey: "5HVzpPQ3Todm6wGtVm7Xf2Dfzx2rcrRaSkduUsckQhWBumLG",
        net_leverage: 0.0,
        open_ms: 1711349191854,
        orders: [
          {
            leverage: 1.25,
            order_type: "LONG",
            order_uuid: "901f4182-cea2-4e31-9f4f-db048dc1d3ed",
            price: 67038.49,
            price_sources: [],
            processed_ms: 1711349191852,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "5e7f1d1d-96cb-4e98-a1d8-66a8ccb0d54c",
            price: 67125.71,
            price_sources: [],
            processed_ms: 1711350983899,
          },
        ],
        position_type: "FLAT",
        position_uuid: "0fd8a548-4a73-4c3f-862d-f0c5fd516d37",
        return_at_close: 0.9991222387691012,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 66948.63999999998,
        close_ms: 1711361974008,
        current_return: 1.0055282645032755,
        initial_entry_price: 67071.23,
        is_closed_position: true,
        miner_hotkey: "5HVzpPQ3Todm6wGtVm7Xf2Dfzx2rcrRaSkduUsckQhWBumLG",
        net_leverage: 0.0,
        open_ms: 1711354658140,
        orders: [
          {
            leverage: 1.25,
            order_type: "LONG",
            order_uuid: "917ff01c-b19a-4994-bd10-5a17e03c5356",
            price: 67071.23,
            price_sources: [],
            processed_ms: 1711354658138,
          },
          {
            leverage: 1.25,
            order_type: "LONG",
            order_uuid: "25b3e72b-096f-4465-8195-a4a8a0e98c10",
            price: 66841.41,
            price_sources: [],
            processed_ms: 1711358316944,
          },
          {
            leverage: -1.25,
            order_type: "SHORT",
            order_uuid: "1cbedb3a-331e-44af-85de-e78c695cc8b4",
            price: 66964.0,
            price_sources: [],
            processed_ms: 1711360146020,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "5103a4f8-a320-46f6-8b30-f19586440444",
            price: 67245.27,
            price_sources: [],
            processed_ms: 1711361974008,
          },
        ],
        position_type: "FLAT",
        position_uuid: "28dd7a4d-fb52-4941-a0f3-6908b2beda40",
        return_at_close: 1.0005006231807592,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 59141.95000000007,
        close_ms: 1711376048285,
        current_return: 1.018204890817349,
        initial_entry_price: 66925.01,
        is_closed_position: true,
        miner_hotkey: "5HVzpPQ3Todm6wGtVm7Xf2Dfzx2rcrRaSkduUsckQhWBumLG",
        net_leverage: 0.0,
        open_ms: 1711363821409,
        orders: [
          {
            leverage: 1.25,
            order_type: "LONG",
            order_uuid: "bcb27768-58f2-48b3-9123-5df00f33c4e6",
            price: 66925.01,
            price_sources: [],
            processed_ms: 1711363821407,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "9580ff10-312f-4938-b8ba-92a37a2e30a8",
            price: 66883.25,
            price_sources: [],
            processed_ms: 1711366899982,
          },
          {
            leverage: -0.75,
            order_type: "SHORT",
            order_uuid: "bc5a9995-629d-48f6-847c-c8e8c74e3c45",
            price: 67172.98,
            price_sources: [],
            processed_ms: 1711370325807,
          },
          {
            leverage: -0.375,
            order_type: "SHORT",
            order_uuid: "393be949-48bb-4ad3-bef0-09b22d356e32",
            price: 67181.19,
            price_sources: [],
            processed_ms: 1711372162974,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "0bbcce9b-18df-41be-8823-06e449371d3b",
            price: 67222.56,
            price_sources: [],
            processed_ms: 1711373994174,
          },
          {
            leverage: -0.5,
            order_type: "SHORT",
            order_uuid: "769f05d2-17cb-4477-8166-7c318c6c8390",
            price: 68434.58,
            price_sources: [],
            processed_ms: 1711375865639,
          },
          {
            leverage: -0.5,
            order_type: "SHORT",
            order_uuid: "544897fa-76b1-4d4d-b7e1-ae7af8edc14b",
            price: 68888.85,
            price_sources: [],
            processed_ms: 1711376048285,
          },
        ],
        position_type: "FLAT",
        position_uuid: "61e86ed5-3b02-4868-84a9-143625b7a7ba",
        return_at_close: 1.015150276144897,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 69443.53000000001,
        close_ms: 1711378309745,
        current_return: 1.0004280187788654,
        initial_entry_price: 69235.28,
        is_closed_position: true,
        miner_hotkey: "5HVzpPQ3Todm6wGtVm7Xf2Dfzx2rcrRaSkduUsckQhWBumLG",
        net_leverage: 0.0,
        open_ms: 1711376558183,
        orders: [
          {
            leverage: -1.25,
            order_type: "SHORT",
            order_uuid: "df345835-f4b0-44a7-9424-c3c6ca5aadbb",
            price: 69235.28,
            price_sources: [],
            processed_ms: 1711376558181,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "496e7a30-4f28-44f6-9c15-0077d9e4d783",
            price: 69358.28,
            price_sources: [],
            processed_ms: 1711376872647,
          },
          {
            leverage: 0.8,
            order_type: "LONG",
            order_uuid: "1448b086-c565-4074-b77d-dfe417ee65a4",
            price: 69144.78,
            price_sources: [],
            processed_ms: 1711378031530,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "7110429c-4530-4ad1-98e0-c112fb396e18",
            price: 69295.36,
            price_sources: [],
            processed_ms: 1711378309745,
          },
        ],
        position_type: "FLAT",
        position_uuid: "b15483bb-0b68-4b0f-a6cf-fa1869d7ea9d",
        return_at_close: 0.9979269487319183,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 66135.10999999997,
        close_ms: 1711388813151,
        current_return: 0.9845965500590114,
        initial_entry_price: 69291.62,
        is_closed_position: true,
        miner_hotkey: "5HVzpPQ3Todm6wGtVm7Xf2Dfzx2rcrRaSkduUsckQhWBumLG",
        net_leverage: 0.0,
        open_ms: 1711379738549,
        orders: [
          {
            leverage: -1.25,
            order_type: "SHORT",
            order_uuid: "fdf84c21-768a-448e-93b0-41e7b824abfa",
            price: 69291.62,
            price_sources: [],
            processed_ms: 1711379738545,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "43ad2543-5654-4e64-9fd6-b1115e77db44",
            price: 69808.57,
            price_sources: [],
            processed_ms: 1711381543022,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "acc768fd-8653-4f1a-b8d3-257a95105e63",
            price: 69749.69,
            price_sources: [],
            processed_ms: 1711383358384,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "1bbb608a-ff02-4116-a658-5d9efe6a79dd",
            price: 70129.37,
            price_sources: [],
            processed_ms: 1711385178432,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "0121a3ef-99be-4b14-93e1-04ac95591d09",
            price: 70635.36,
            price_sources: [],
            processed_ms: 1711386995167,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "722ac2ab-651a-443e-b67d-71f18d21e48c",
            price: 70404.43,
            price_sources: [],
            processed_ms: 1711388813151,
          },
        ],
        position_type: "FLAT",
        position_uuid: "047d6a44-0a7b-421c-ad8d-4fef6ee9dc52",
        return_at_close: 0.9821350586838639,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 67820.89000000007,
        close_ms: 1711399169972,
        current_return: 0.978600606493423,
        initial_entry_price: 70559.71,
        is_closed_position: true,
        miner_hotkey: "5HVzpPQ3Todm6wGtVm7Xf2Dfzx2rcrRaSkduUsckQhWBumLG",
        net_leverage: 0.0,
        open_ms: 1711391905579,
        orders: [
          {
            leverage: -5.0,
            order_type: "SHORT",
            order_uuid: "0ad2867b-564e-4dcd-84cd-d0998d4220d5",
            price: 70559.71,
            price_sources: [],
            processed_ms: 1711391905577,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "07bec3b8-bb9e-4f9d-8f66-13e04980c68c",
            price: 70961.57,
            price_sources: [],
            processed_ms: 1711393727942,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "ee860b42-c46d-4285-813b-6a72b587c233",
            price: 70673.68,
            price_sources: [],
            processed_ms: 1711395533995,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "07e56b60-a749-44bd-8b28-5c785a396210",
            price: 70956.82,
            price_sources: [],
            processed_ms: 1711397350284,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "557f9d69-f63e-4dba-a56b-11153ba16fd3",
            price: 70840.76,
            price_sources: [],
            processed_ms: 1711399169972,
          },
        ],
        position_type: "FLAT",
        position_uuid: "e609ba80-932c-4929-87c0-e159484b3261",
        return_at_close: 0.9688146004284888,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 70694.07,
        close_ms: 1711401595635,
        current_return: 1.0510467143849551,
        initial_entry_price: 70694.07,
        is_closed_position: true,
        miner_hotkey: "5HVzpPQ3Todm6wGtVm7Xf2Dfzx2rcrRaSkduUsckQhWBumLG",
        net_leverage: 0.0,
        open_ms: 1711400985415,
        orders: [
          {
            leverage: -5.0,
            order_type: "SHORT",
            order_uuid: "bfc23961-04b8-4b10-b582-afaa69bc9738",
            price: 70694.07,
            price_sources: [],
            processed_ms: 1711400985411,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "5868da39-1e26-4a37-a793-8b1e00367d03",
            price: 69972.33,
            price_sources: [],
            processed_ms: 1711401595635,
          },
        ],
        position_type: "FLAT",
        position_uuid: "328b3567-bf92-4fe6-b8b0-7ea32cddebe8",
        return_at_close: 1.0405362472411055,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 63702.11540983619,
        close_ms: 1711424593943,
        current_return: 1.0468174070855687,
        initial_entry_price: 70158.37,
        is_closed_position: true,
        miner_hotkey: "5HVzpPQ3Todm6wGtVm7Xf2Dfzx2rcrRaSkduUsckQhWBumLG",
        net_leverage: 0.0,
        open_ms: 1711402807634,
        orders: [
          {
            leverage: 5.0,
            order_type: "LONG",
            order_uuid: "06b83716-0e6d-4d7f-a75f-fe912bb5ff7c",
            price: 70158.37,
            price_sources: [],
            processed_ms: 1711402807631,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "e55a87e6-41bd-4855-b5d4-d39888f2c0dd",
            price: 70219.66,
            price_sources: [],
            processed_ms: 1711404613988,
          },
          {
            leverage: -3.25,
            order_type: "SHORT",
            order_uuid: "55dafc21-d1bc-4686-8d58-f3f214073443",
            price: 70534.0,
            price_sources: [],
            processed_ms: 1711406435002,
          },
          {
            leverage: -1.625,
            order_type: "SHORT",
            order_uuid: "4aad7c2d-e6c8-4196-ae05-f53f794e3be3",
            price: 70347.78,
            price_sources: [],
            processed_ms: 1711408248066,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "4dde1a9a-a06b-4bc9-ac77-2314a34eb16a",
            price: 69864.9,
            price_sources: [],
            processed_ms: 1711410067851,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "eead3eb4-9109-43ad-b590-6a1f50da61df",
            price: 69951.06,
            price_sources: [],
            processed_ms: 1711411908903,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "5d4f51ed-4315-42a8-bc16-ba77dc2cb9d2",
            price: 70138.96,
            price_sources: [],
            processed_ms: 1711413699743,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "09068b0f-52db-48c2-a231-c83dbd1c5c95",
            price: 70029.27,
            price_sources: [],
            processed_ms: 1711415520770,
          },
          {
            leverage: -3.8125,
            order_type: "SHORT",
            order_uuid: "29d04487-1543-4957-949d-4eb95a31cbe9",
            price: 70250.86,
            price_sources: [],
            processed_ms: 1711417333956,
          },
          {
            leverage: -1.90625,
            order_type: "SHORT",
            order_uuid: "a6407a02-a62c-498c-ba73-871d0700637e",
            price: 70228.65,
            price_sources: [],
            processed_ms: 1711419149938,
          },
          {
            leverage: -0.953125,
            order_type: "SHORT",
            order_uuid: "5b595e93-7f35-43ac-b4b0-021699fb7696",
            price: 70264.17,
            price_sources: [],
            processed_ms: 1711420962036,
          },
          {
            leverage: -0.4765625,
            order_type: "SHORT",
            order_uuid: "bc2803b7-5ef8-4b78-9fc2-c5301ce85b2d",
            price: 70323.7,
            price_sources: [],
            processed_ms: 1711422778018,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "c7d229ff-4684-4c5f-bc86-5167daa26bc4",
            price: 70594.46,
            price_sources: [],
            processed_ms: 1711424593943,
          },
        ],
        position_type: "FLAT",
        position_uuid: "5c8d8c58-e45a-418c-8f9d-1002c8a85c79",
        return_at_close: 1.0308534416275137,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 72184.49999999994,
        close_ms: 1711437314202,
        current_return: 1.0047279928913992,
        initial_entry_price: 70584.92,
        is_closed_position: true,
        miner_hotkey: "5HVzpPQ3Todm6wGtVm7Xf2Dfzx2rcrRaSkduUsckQhWBumLG",
        net_leverage: 0.0,
        open_ms: 1711426422401,
        orders: [
          {
            leverage: -5.0,
            order_type: "SHORT",
            order_uuid: "03a9afe3-c97e-4287-b1dc-6ef4f0cbb26b",
            price: 70584.92,
            price_sources: [],
            processed_ms: 1711426422398,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "d5965bab-e28e-4332-aa58-b33603311737",
            price: 70615.82,
            price_sources: [],
            processed_ms: 1711428232765,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "898c8831-8152-4985-9c18-e28ddceb748b",
            price: 70542.86,
            price_sources: [],
            processed_ms: 1711430053462,
          },
          {
            leverage: 1.0,
            order_type: "LONG",
            order_uuid: "adc7d623-e469-4d6d-a7c0-3fbd7d2d1ee4",
            price: 70353.78,
            price_sources: [],
            processed_ms: 1711431864620,
          },
          {
            leverage: 0.5,
            order_type: "LONG",
            order_uuid: "fa708de5-785a-4d33-8c72-77dd37eff5a4",
            price: 70352.19,
            price_sources: [],
            processed_ms: 1711433674161,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "6f0bae47-fde7-4359-a64a-a78317df1abc",
            price: 70442.32,
            price_sources: [],
            processed_ms: 1711435495983,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "ce89453a-1a6e-4741-b5b8-afab4cf58523",
            price: 70849.6,
            price_sources: [],
            processed_ms: 1711437314202,
          },
        ],
        position_type: "FLAT",
        position_uuid: "5e06f6da-7fd6-4a9c-a4c9-7f7552188d9c",
        return_at_close: 0.9946807129624853,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 71225.81,
        close_ms: 1711440089564,
        current_return: 1.0500780545703865,
        initial_entry_price: 71225.81,
        is_closed_position: true,
        miner_hotkey: "5HVzpPQ3Todm6wGtVm7Xf2Dfzx2rcrRaSkduUsckQhWBumLG",
        net_leverage: 0.0,
        open_ms: 1711439127370,
        orders: [
          {
            leverage: -5.0,
            order_type: "SHORT",
            order_uuid: "62e78889-0179-4548-8c55-c4f1c88c0b88",
            price: 71225.81,
            price_sources: [],
            processed_ms: 1711439127365,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "2500425a-faef-4e29-83f7-9b9874a9642e",
            price: 70512.44,
            price_sources: [],
            processed_ms: 1711440089564,
          },
        ],
        position_type: "FLAT",
        position_uuid: "2a6caef9-90db-4425-aecc-defe2e43eb75",
        return_at_close: 1.0395772740246827,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 68200.77000000008,
        close_ms: 1711448209019,
        current_return: 1.0101621301456911,
        initial_entry_price: 70656.2,
        is_closed_position: true,
        miner_hotkey: "5HVzpPQ3Todm6wGtVm7Xf2Dfzx2rcrRaSkduUsckQhWBumLG",
        net_leverage: 0.0,
        open_ms: 1711440947087,
        orders: [
          {
            leverage: 5.0,
            order_type: "LONG",
            order_uuid: "aaaf291d-594a-4305-ab0a-66d9bf69e470",
            price: 70656.2,
            price_sources: [],
            processed_ms: 1711440947083,
          },
          {
            leverage: -2.5,
            order_type: "SHORT",
            order_uuid: "29f8c300-f814-4c9b-9584-f7a99ccc4d27",
            price: 70734.36,
            price_sources: [],
            processed_ms: 1711442756120,
          },
          {
            leverage: -1.25,
            order_type: "SHORT",
            order_uuid: "48657346-b3a6-483e-a459-2926b7f71058",
            price: 70665.07,
            price_sources: [],
            processed_ms: 1711444582211,
          },
          {
            leverage: -1.0,
            order_type: "SHORT",
            order_uuid: "4f9bcbb5-6ead-4f94-b4cc-5701a3efd0b5",
            price: 71063.57,
            price_sources: [],
            processed_ms: 1711446395122,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "bad72520-7d4b-4d3b-b5ff-7a887e853d74",
            price: 71072.84,
            price_sources: [],
            processed_ms: 1711448209019,
          },
        ],
        position_type: "FLAT",
        position_uuid: "21a0db9c-4718-4a57-b2d6-57a55e11d0d2",
        return_at_close: 1.0000605088442343,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 70278.05103448276,
        close_ms: 1711463462016,
        current_return: 0.9643446852133095,
        initial_entry_price: 70664.36,
        is_closed_position: true,
        miner_hotkey: "5HVzpPQ3Todm6wGtVm7Xf2Dfzx2rcrRaSkduUsckQhWBumLG",
        net_leverage: 0.0,
        open_ms: 1711453656767,
        orders: [
          {
            leverage: 5.0,
            order_type: "LONG",
            order_uuid: "7155097c-c29c-4188-a798-1dc404820e00",
            price: 70664.36,
            price_sources: [],
            processed_ms: 1711453656765,
          },
          {
            leverage: -2.5,
            order_type: "SHORT",
            order_uuid: "6e3c2f96-d159-48aa-bd39-c139e6eb99b6",
            price: 70871.73,
            price_sources: [],
            processed_ms: 1711455475244,
          },
          {
            leverage: -1.25,
            order_type: "SHORT",
            order_uuid: "a98c7b89-ca5a-4fdf-aa4d-7349aaac5536",
            price: 70964.97,
            price_sources: [],
            processed_ms: 1711457287892,
          },
          {
            leverage: -0.625,
            order_type: "SHORT",
            order_uuid: "a35a6fa8-5f06-444c-837c-e5770b8189e0",
            price: 70847.18,
            price_sources: [],
            processed_ms: 1711459108367,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "0d7fd14f-acc4-443e-92e7-78384a8278e0",
            price: 70874.89,
            price_sources: [],
            processed_ms: 1711460931297,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "ca96c286-c3c5-46c7-a9be-235cda251e83",
            price: 70192.55,
            price_sources: [],
            processed_ms: 1711462743612,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "9987c881-8359-4860-9443-4e87ddae03b8",
            price: 69583.0,
            price_sources: [],
            processed_ms: 1711463462016,
          },
        ],
        position_type: "FLAT",
        position_uuid: "ddc7825f-add8-4537-ab5d-d9332128ce63",
        return_at_close: 0.9547012383611764,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 70158.62307692308,
        close_ms: 1711466458356,
        current_return: 0.9625981425065135,
        initial_entry_price: 70261.35,
        is_closed_position: true,
        miner_hotkey: "5HVzpPQ3Todm6wGtVm7Xf2Dfzx2rcrRaSkduUsckQhWBumLG",
        net_leverage: 0.0,
        open_ms: 1711464557010,
        orders: [
          {
            leverage: 5.0,
            order_type: "LONG",
            order_uuid: "e582b249-50e4-4784-854c-b036e1608626",
            price: 70261.35,
            price_sources: [],
            processed_ms: 1711464557007,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "55397750-a8d5-4fd6-a43c-bcfdc205f02a",
            price: 69816.2,
            price_sources: [],
            processed_ms: 1711466370997,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "6c220051-6cdb-400b-a009-616a66d07577",
            price: 69754.33,
            price_sources: [],
            processed_ms: 1711466458356,
          },
        ],
        position_type: "FLAT",
        position_uuid: "458d3248-ec4d-41b1-b295-5fd924dc1c00",
        return_at_close: 0.9500843666539288,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 70239.01000000001,
        close_ms: 1711470137920,
        current_return: 0.9662514093335938,
        initial_entry_price: 70343.53,
        is_closed_position: true,
        miner_hotkey: "5HVzpPQ3Todm6wGtVm7Xf2Dfzx2rcrRaSkduUsckQhWBumLG",
        net_leverage: 0.0,
        open_ms: 1711468189068,
        orders: [
          {
            leverage: 5.0,
            order_type: "LONG",
            order_uuid: "35b826cd-7d19-47a3-8a7a-6ffafef59709",
            price: 70343.53,
            price_sources: [],
            processed_ms: 1711468189065,
          },
          {
            leverage: 1.5,
            order_type: "LONG",
            order_uuid: "cf763eab-5d3b-49d5-b48e-2760473d9afa",
            price: 69890.61,
            price_sources: [],
            processed_ms: 1711470011015,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "8e34bdc2-5eb0-49ba-956c-d39a8a311185",
            price: 69873.78,
            price_sources: [],
            processed_ms: 1711470137920,
          },
        ],
        position_type: "FLAT",
        position_uuid: "13a88168-be7f-4fed-832a-5e4baad97f88",
        return_at_close: 0.953690141012257,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
    ],
    thirty_day_returns: 1.0,
  },
  "5HWAxTrb6CgBhUZDnE15yvLiwmjmYYMDAKkPtCXyCkdmMNGP": {
    all_time_returns: 1.0008638212866656,
    n_positions: 1,
    percentage_profitable: 1.0,
    positions: [
      {
        average_entry_price: 160.368,
        close_ms: 1719603703654,
        current_return: 1.000884839868303,
        initial_entry_price: 160.368,
        is_closed_position: true,
        miner_hotkey: "5HWAxTrb6CgBhUZDnE15yvLiwmjmYYMDAKkPtCXyCkdmMNGP",
        net_leverage: 0.0,
        open_ms: 1719399790094,
        orders: [
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "de64900d-1d09-4f42-be9e-73c96b5caeca",
            price: 160.368,
            price_sources: [],
            processed_ms: 1719399790089,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "4189d68f-5abd-454a-9aaa-32624399dee6",
            price: 160.841,
            price_sources: [],
            processed_ms: 1719603703654,
          },
        ],
        position_type: "FLAT",
        position_uuid: "0d9312b6-cbae-45c1-9d88-fcf57a0313ed",
        return_at_close: 1.0008638212866656,
        trade_pair: ["USDJPY", "USD/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 60345.299999999996,
        close_ms: 0,
        current_return: 0.9939619241266512,
        initial_entry_price: 60345.3,
        is_closed_position: false,
        miner_hotkey: "5HWAxTrb6CgBhUZDnE15yvLiwmjmYYMDAKkPtCXyCkdmMNGP",
        net_leverage: 0.165,
        open_ms: 1719603832400,
        orders: [
          {
            leverage: 0.165,
            order_type: "LONG",
            order_uuid: "4811e032-a202-442d-9501-0df8c688048f",
            price: 60345.3,
            price_sources: [],
            processed_ms: 1719603830969,
          },
        ],
        position_type: "LONG",
        position_uuid: "e1d1a86c-7c82-40db-90c0-38d10144250e",
        return_at_close: 0.9938799222679108,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
    ],
    thirty_day_returns: 1.0008638212866656,
  },
  "5HY6RF4RVDnX8CQ5JrcnzjM2rixQXZpW2eh48aNLUYT1V9LW": {
    all_time_returns: 1.0945863722456766,
    n_positions: 24,
    percentage_profitable: 0.9583333333333334,
    positions: [
      {
        average_entry_price: 3231.53,
        close_ms: 1712989168447,
        current_return: 1.0054401475462087,
        initial_entry_price: 3231.53,
        is_closed_position: true,
        miner_hotkey: "5HY6RF4RVDnX8CQ5JrcnzjM2rixQXZpW2eh48aNLUYT1V9LW",
        net_leverage: 0.0,
        open_ms: 1712948270201,
        orders: [
          {
            leverage: 1.0,
            order_type: "LONG",
            order_uuid: "51e962f6-4885-4932-9983-5591bf285fa3",
            price: 3231.53,
            price_sources: [],
            processed_ms: 1712948270200,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "806c69ab-dee9-40d1-bf9d-c482ffcb1279",
            price: 3249.11,
            price_sources: [],
            processed_ms: 1712989168447,
          },
        ],
        position_type: "FLAT",
        position_uuid: "a79e6561-a6c4-4ad5-83fc-e326604bbebc",
        return_at_close: 1.0044347073986626,
        trade_pair: ["ETHUSD", "ETH/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 66099.19,
        close_ms: 1712989666715,
        current_return: 1.0183769271605294,
        initial_entry_price: 66099.19,
        is_closed_position: true,
        miner_hotkey: "5HY6RF4RVDnX8CQ5JrcnzjM2rixQXZpW2eh48aNLUYT1V9LW",
        net_leverage: 0.0,
        open_ms: 1712947650449,
        orders: [
          {
            leverage: 1.0,
            order_type: "LONG",
            order_uuid: "b4d16b72-88ce-486c-97c1-ebab2fd93258",
            price: 66099.19,
            price_sources: [],
            processed_ms: 1712947650449,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "70e1a110-4804-4d65-8b89-c83c5f087d48",
            price: 67313.89,
            price_sources: [],
            processed_ms: 1712989666715,
          },
        ],
        position_type: "FLAT",
        position_uuid: "635b86bc-212d-4471-aec3-8cefbd7048f1",
        return_at_close: 1.0173585502333689,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 3018.94,
        close_ms: 1713097766611,
        current_return: 1.0047334494888935,
        initial_entry_price: 3018.94,
        is_closed_position: true,
        miner_hotkey: "5HY6RF4RVDnX8CQ5JrcnzjM2rixQXZpW2eh48aNLUYT1V9LW",
        net_leverage: 0.0,
        open_ms: 1713052969639,
        orders: [
          {
            leverage: 1.0,
            order_type: "LONG",
            order_uuid: "cc72d885-52e8-4dd5-ae56-d9a292a84ba5",
            price: 3018.94,
            price_sources: [],
            processed_ms: 1713052969638,
          },
          {
            leverage: 0.3,
            order_type: "FLAT",
            order_uuid: "1e9a5c9d-b8c7-4bdb-8582-80fbb6f385fd",
            price: 3033.23,
            price_sources: [],
            processed_ms: 1713097766611,
          },
        ],
        position_type: "FLAT",
        position_uuid: "774525fb-8e06-47f5-b9a1-b3156d2f9bff",
        return_at_close: 1.0037287160394046,
        trade_pair: ["ETHUSD", "ETH/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 64470.93,
        close_ms: 1713100862825,
        current_return: 1.0026554603757072,
        initial_entry_price: 64470.93,
        is_closed_position: true,
        miner_hotkey: "5HY6RF4RVDnX8CQ5JrcnzjM2rixQXZpW2eh48aNLUYT1V9LW",
        net_leverage: 0.0,
        open_ms: 1713052300516,
        orders: [
          {
            leverage: 1.0,
            order_type: "LONG",
            order_uuid: "40c6792e-d02a-4c42-90a5-c97ceb08e239",
            price: 64470.93,
            price_sources: [],
            processed_ms: 1713052300516,
          },
          {
            leverage: 0.3,
            order_type: "FLAT",
            order_uuid: "8be3d4a0-9eee-4baa-9246-167424b9d90d",
            price: 64642.13,
            price_sources: [],
            processed_ms: 1713100862825,
          },
        ],
        position_type: "FLAT",
        position_uuid: "0c0aa92e-2aba-41ea-a907-a092fef3ea37",
        return_at_close: 1.0016528049153315,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 3057.38,
        close_ms: 1713112388395,
        current_return: 1.00313307472411,
        initial_entry_price: 3057.38,
        is_closed_position: true,
        miner_hotkey: "5HY6RF4RVDnX8CQ5JrcnzjM2rixQXZpW2eh48aNLUYT1V9LW",
        net_leverage: 0.0,
        open_ms: 1713100231409,
        orders: [
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "6fa86286-6a28-43c9-8fa0-c0eb5260c830",
            price: 3057.38,
            price_sources: [],
            processed_ms: 1713100231409,
          },
          {
            leverage: 0.25,
            order_type: "FLAT",
            order_uuid: "430caf78-9640-4f3e-8582-efd1f6d3623e",
            price: 3089.31,
            price_sources: [],
            processed_ms: 1713112388395,
          },
        ],
        position_type: "FLAT",
        position_uuid: "3109080e-1c9f-454b-a225-05f10b30b5c4",
        return_at_close: 1.0028321348016929,
        trade_pair: ["ETHUSD", "ETH/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 64120.61,
        close_ms: 1713113073067,
        current_return: 1.001367423048533,
        initial_entry_price: 64120.61,
        is_closed_position: true,
        miner_hotkey: "5HY6RF4RVDnX8CQ5JrcnzjM2rixQXZpW2eh48aNLUYT1V9LW",
        net_leverage: 0.0,
        open_ms: 1713107855577,
        orders: [
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "71c9b704-9cac-4f1e-8b13-667e670ce47e",
            price: 64120.61,
            price_sources: [],
            processed_ms: 1713107855577,
          },
          {
            leverage: 0.25,
            order_type: "FLAT",
            order_uuid: "626be2b6-2089-45e2-8913-7e1c2551b190",
            price: 64471.33,
            price_sources: [],
            processed_ms: 1713113073067,
          },
        ],
        position_type: "FLAT",
        position_uuid: "a1898290-8ebe-4100-bbce-ca2eef6a705b",
        return_at_close: 1.001117081192771,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 63913.62,
        close_ms: 1713119911397,
        current_return: 1.0025159347882344,
        initial_entry_price: 63913.62,
        is_closed_position: true,
        miner_hotkey: "5HY6RF4RVDnX8CQ5JrcnzjM2rixQXZpW2eh48aNLUYT1V9LW",
        net_leverage: 0.0,
        open_ms: 1713116555789,
        orders: [
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "38130321-a6ae-494b-a2ae-c5695f782ce2",
            price: 63913.62,
            price_sources: [],
            processed_ms: 1713116555788,
          },
          {
            leverage: 0.25,
            order_type: "FLAT",
            order_uuid: "45a2c865-af02-43e2-b2d1-7f53a849bcc5",
            price: 64556.83,
            price_sources: [],
            processed_ms: 1713119911397,
          },
        ],
        position_type: "FLAT",
        position_uuid: "6dbd34ea-442c-4904-86eb-b0ff1d58550b",
        return_at_close: 1.0022653058045374,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 3055.15,
        close_ms: 1713121112143,
        current_return: 1.0024082287285403,
        initial_entry_price: 3055.15,
        is_closed_position: true,
        miner_hotkey: "5HY6RF4RVDnX8CQ5JrcnzjM2rixQXZpW2eh48aNLUYT1V9LW",
        net_leverage: 0.0,
        open_ms: 1713115999583,
        orders: [
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "31d7db70-6780-4a8e-af02-e0d3b07649c1",
            price: 3055.15,
            price_sources: [],
            processed_ms: 1713115999582,
          },
          {
            leverage: 0.25,
            order_type: "FLAT",
            order_uuid: "fb9b68fc-5fcb-422e-948f-1600c43dd8b1",
            price: 3084.58,
            price_sources: [],
            processed_ms: 1713121112143,
          },
        ],
        position_type: "FLAT",
        position_uuid: "7b96543c-cee7-4e80-89d4-b82fcee7b116",
        return_at_close: 1.002157626671358,
        trade_pair: ["ETHUSD", "ETH/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 3072.5,
        close_ms: 1713134249472,
        current_return: 1.00793327908869,
        initial_entry_price: 3072.5,
        is_closed_position: true,
        miner_hotkey: "5HY6RF4RVDnX8CQ5JrcnzjM2rixQXZpW2eh48aNLUYT1V9LW",
        net_leverage: 0.0,
        open_ms: 1713122164491,
        orders: [
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "eeb3586a-dec5-43df-b41c-c6cb9e5da8a5",
            price: 3072.5,
            price_sources: [],
            processed_ms: 1713122164490,
          },
          {
            leverage: 0.25,
            order_type: "FLAT",
            order_uuid: "99b1aef2-862d-4cf3-8a77-6f3882e63dfb",
            price: 3170.0,
            price_sources: [],
            processed_ms: 1713134249472,
          },
        ],
        position_type: "FLAT",
        position_uuid: "7280b6a3-10ac-417a-89e3-d855cd618cc1",
        return_at_close: 1.0076812957689179,
        trade_pair: ["ETHUSD", "ETH/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 64038.89,
        close_ms: 1713134972718,
        current_return: 1.008298985811351,
        initial_entry_price: 64051.2,
        is_closed_position: true,
        miner_hotkey: "5HY6RF4RVDnX8CQ5JrcnzjM2rixQXZpW2eh48aNLUYT1V9LW",
        net_leverage: 0.0,
        open_ms: 1713122164375,
        orders: [
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "336ca9e9-e2c7-40bf-b9c7-6a3319ffc7fe",
            price: 64051.2,
            price_sources: [],
            processed_ms: 1713122164374,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "52b45386-4327-42cd-8cbe-135993b85b2d",
            price: 64026.58,
            price_sources: [],
            processed_ms: 1713122809125,
          },
          {
            leverage: 0.25,
            order_type: "FLAT",
            order_uuid: "3ae79d70-fc21-4623-8de5-8ac232a52f45",
            price: 65102.01,
            price_sources: [],
            processed_ms: 1713134972718,
          },
        ],
        position_type: "FLAT",
        position_uuid: "a7b4cb64-f973-491e-8c6b-d1870e61a645",
        return_at_close: 1.0077948363184452,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 64243.51,
        close_ms: 1716115711578,
        current_return: 1.0173034701050134,
        initial_entry_price: 64235.52,
        is_closed_position: true,
        miner_hotkey: "5HY6RF4RVDnX8CQ5JrcnzjM2rixQXZpW2eh48aNLUYT1V9LW",
        net_leverage: 0.0,
        open_ms: 1713986522270,
        orders: [
          {
            leverage: 0.17,
            order_type: "LONG",
            order_uuid: "e2041b3f-cbcc-4db4-b28c-ec53e3fdb058",
            price: 64235.52,
            price_sources: [],
            processed_ms: 1713986521588,
          },
          {
            leverage: 0.17,
            order_type: "LONG",
            order_uuid: "24d03066-c0ef-4e4b-beb9-b9fe2263dfa4",
            price: 64251.5,
            price_sources: [],
            processed_ms: 1713986549676,
          },
          {
            leverage: 0.2,
            order_type: "FLAT",
            order_uuid: "1d4d7653-b667-4f39-b8d1-5ccd63f13be7",
            price: 67512.62,
            price_sources: [],
            processed_ms: 1716115711578,
          },
        ],
        position_type: "FLAT",
        position_uuid: "69d880d0-27b9-448d-8979-12e424a25db1",
        return_at_close: 1.0169575869251777,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 67065.32666666669,
        close_ms: 1716118956552,
        current_return: 1.001795805597159,
        initial_entry_price: 67067.95,
        is_closed_position: true,
        miner_hotkey: "5HY6RF4RVDnX8CQ5JrcnzjM2rixQXZpW2eh48aNLUYT1V9LW",
        net_leverage: 0.0,
        open_ms: 1716118055614,
        orders: [
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "61dbc261-954b-4524-bcab-e6c8a64a6753",
            price: 67067.95,
            price_sources: [],
            processed_ms: 1716118053970,
          },
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "5d0fd2f7-a65d-4ea6-8379-164fbe71ae36",
            price: 67086.46,
            price_sources: [],
            processed_ms: 1716118267851,
          },
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "3a8c0703-27e4-4b87-91af-c0d6f62691ba",
            price: 67041.57,
            price_sources: [],
            processed_ms: 1716118304880,
          },
          {
            leverage: 0.01,
            order_type: "FLAT",
            order_uuid: "c8052f9b-583b-489e-a667-6da46355fa7a",
            price: 67199.15,
            price_sources: [],
            processed_ms: 1716118956552,
          },
        ],
        position_type: "FLAT",
        position_uuid: "4e02484b-7d0e-467b-9c98-d518dd4a7fc4",
        return_at_close: 1.0008941893721215,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 66903.0,
        close_ms: 1716128392585,
        current_return: 1.0011142998071836,
        initial_entry_price: 66903.0,
        is_closed_position: true,
        miner_hotkey: "5HY6RF4RVDnX8CQ5JrcnzjM2rixQXZpW2eh48aNLUYT1V9LW",
        net_leverage: 0.0,
        open_ms: 1716123092829,
        orders: [
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "ed025f92-cb0d-4b57-9caf-cc246c10b79e",
            price: 66903.0,
            price_sources: [],
            processed_ms: 1716123090080,
          },
          {
            leverage: 0.4,
            order_type: "FLAT",
            order_uuid: "5edc7aae-6d34-4c9a-910f-c081c2ed8d12",
            price: 67151.5,
            price_sources: [],
            processed_ms: 1716128392585,
          },
        ],
        position_type: "FLAT",
        position_uuid: "3f46f977-0296-446e-9590-33e9ba5506c0",
        return_at_close: 1.0008139655172414,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 66257.73,
        close_ms: 1716182814720,
        current_return: 1.0050621716137875,
        initial_entry_price: 66257.73,
        is_closed_position: true,
        miner_hotkey: "5HY6RF4RVDnX8CQ5JrcnzjM2rixQXZpW2eh48aNLUYT1V9LW",
        net_leverage: 0.0,
        open_ms: 1716146106715,
        orders: [
          {
            leverage: 0.4,
            order_type: "LONG",
            order_uuid: "a61f4306-0465-4228-bdc4-031cf2ad81a6",
            price: 66257.73,
            price_sources: [],
            processed_ms: 1716146106168,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "ba9ad1b2-4cdc-47f0-9c54-74a2be8cbb4e",
            price: 67096.25,
            price_sources: [],
            processed_ms: 1716182814720,
          },
        ],
        position_type: "FLAT",
        position_uuid: "5eef1155-251c-4260-9ed0-89dc8f0f1a9a",
        return_at_close: 1.0046601467451421,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 3141.75,
        close_ms: 1716233114331,
        current_return: 1.0060201161772897,
        initial_entry_price: 3141.75,
        is_closed_position: true,
        miner_hotkey: "5HY6RF4RVDnX8CQ5JrcnzjM2rixQXZpW2eh48aNLUYT1V9LW",
        net_leverage: 0.0,
        open_ms: 1713986552252,
        orders: [
          {
            leverage: 0.13,
            order_type: "LONG",
            order_uuid: "39917cbb-60f2-4676-9a73-d85d11cdccbf",
            price: 3141.75,
            price_sources: [],
            processed_ms: 1713986552251,
          },
          {
            leverage: 0.3,
            order_type: "FLAT",
            order_uuid: "74281a71-8805-4ace-bc18-4e9e98b26780",
            price: 3287.24,
            price_sources: [],
            processed_ms: 1716233114331,
          },
        ],
        position_type: "FLAT",
        position_uuid: "e99527d3-714d-4d31-b981-a37365e41f31",
        return_at_close: 1.0058893335621868,
        trade_pair: ["ETHUSD", "ETH/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 66819.9,
        close_ms: 1716545485625,
        current_return: 1.0033244587316055,
        initial_entry_price: 66819.9,
        is_closed_position: true,
        miner_hotkey: "5HY6RF4RVDnX8CQ5JrcnzjM2rixQXZpW2eh48aNLUYT1V9LW",
        net_leverage: 0.0,
        open_ms: 1716539672535,
        orders: [
          {
            leverage: 0.4,
            order_type: "LONG",
            order_uuid: "824534bf-ae5b-46fd-9af3-c474a10c519f",
            price: 66819.9,
            price_sources: [],
            processed_ms: 1716539672293,
          },
          {
            leverage: 0.4,
            order_type: "FLAT",
            order_uuid: "08dd7692-bb60-4d6d-93c9-05392396925c",
            price: 67375.25,
            price_sources: [],
            processed_ms: 1716545485625,
          },
        ],
        position_type: "FLAT",
        position_uuid: "79ee6e81-4c81-4091-9c58-6c3ca3a7fc48",
        return_at_close: 1.0029231289481129,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 67775.68,
        close_ms: 1717095303560,
        current_return: 1.003348081494719,
        initial_entry_price: 67775.68,
        is_closed_position: true,
        miner_hotkey: "5HY6RF4RVDnX8CQ5JrcnzjM2rixQXZpW2eh48aNLUYT1V9LW",
        net_leverage: 0.0,
        open_ms: 1716981212171,
        orders: [
          {
            leverage: 0.15,
            order_type: "LONG",
            order_uuid: "813486f5-146a-4efc-99dc-de78ece9d9f0",
            price: 67775.68,
            price_sources: [],
            processed_ms: 1716981211299,
          },
          {
            leverage: 0.2,
            order_type: "FLAT",
            order_uuid: "03c61a6c-0d08-4084-8218-1d304fe490f0",
            price: 69288.47,
            price_sources: [],
            processed_ms: 1717095303560,
          },
        ],
        position_type: "FLAT",
        position_uuid: "c6d7b54a-3de4-4119-b892-7a174f04b08b",
        return_at_close: 1.003197579282495,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 68668.9,
        close_ms: 1717429522769,
        current_return: 1.0016257141151235,
        initial_entry_price: 68668.9,
        is_closed_position: true,
        miner_hotkey: "5HY6RF4RVDnX8CQ5JrcnzjM2rixQXZpW2eh48aNLUYT1V9LW",
        net_leverage: 0.0,
        open_ms: 1717426716777,
        orders: [
          {
            leverage: 0.2,
            order_type: "LONG",
            order_uuid: "2207eb44-0371-42a3-8166-0282eee67d20",
            price: 68668.9,
            price_sources: [],
            processed_ms: 1717426716566,
          },
          {
            leverage: 0.2,
            order_type: "FLAT",
            order_uuid: "d760aee3-3429-48f4-ae8c-77d95d3e3514",
            price: 69227.08,
            price_sources: [],
            processed_ms: 1717429522769,
          },
        ],
        position_type: "FLAT",
        position_uuid: "bee53e89-67ec-4c9d-816c-e75e259ef034",
        return_at_close: 1.0014253889723004,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 68807.9,
        close_ms: 1717614214653,
        current_return: 1.003403678938029,
        initial_entry_price: 68807.9,
        is_closed_position: true,
        miner_hotkey: "5HY6RF4RVDnX8CQ5JrcnzjM2rixQXZpW2eh48aNLUYT1V9LW",
        net_leverage: 0.0,
        open_ms: 1717496790535,
        orders: [
          {
            leverage: 0.1,
            order_type: "LONG",
            order_uuid: "d950dc1c-6feb-4148-a583-c8b72ebbd5e0",
            price: 68807.9,
            price_sources: [],
            processed_ms: 1717496790349,
          },
          {
            leverage: 0.5,
            order_type: "FLAT",
            order_uuid: "8ab640f7-592e-4bca-ae2f-619683128c90",
            price: 71149.9,
            price_sources: [],
            processed_ms: 1717614214653,
          },
        ],
        position_type: "FLAT",
        position_uuid: "4a493c81-2530-4714-af33-65e5e9a2fd87",
        return_at_close: 1.003303338570135,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 3818.6100000000006,
        close_ms: 1717614592289,
        current_return: 1.0011261951338315,
        initial_entry_price: 3818.61,
        is_closed_position: true,
        miner_hotkey: "5HY6RF4RVDnX8CQ5JrcnzjM2rixQXZpW2eh48aNLUYT1V9LW",
        net_leverage: 0.0,
        open_ms: 1716981190318,
        orders: [
          {
            leverage: 0.15,
            order_type: "LONG",
            order_uuid: "c0a9d8ef-a787-4e50-931e-80e6667ef882",
            price: 3818.61,
            price_sources: [],
            processed_ms: 1716981189179,
          },
          {
            leverage: 0.5,
            order_type: "FLAT",
            order_uuid: "5e81c3eb-e8fa-4e35-9721-3e39c2e93e78",
            price: 3847.28,
            price_sources: [],
            processed_ms: 1717614592289,
          },
        ],
        position_type: "FLAT",
        position_uuid: "f350feaa-5d1f-45b1-b488-25472e8d1807",
        return_at_close: 1.0009760262045615,
        trade_pair: ["ETHUSD", "ETH/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 3790.98,
        close_ms: 1717713606993,
        current_return: 1.0007143271660626,
        initial_entry_price: 3790.98,
        is_closed_position: true,
        miner_hotkey: "5HY6RF4RVDnX8CQ5JrcnzjM2rixQXZpW2eh48aNLUYT1V9LW",
        net_leverage: 0.0,
        open_ms: 1717703656758,
        orders: [
          {
            leverage: 0.2,
            order_type: "LONG",
            order_uuid: "668e7d84-d6d2-4744-b062-8180d5e6bfda",
            price: 3790.98,
            price_sources: [],
            processed_ms: 1717703656279,
          },
          {
            leverage: 0.2,
            order_type: "FLAT",
            order_uuid: "ee4c0f97-31e1-4810-9a9a-97560303f567",
            price: 3804.52,
            price_sources: [],
            processed_ms: 1717713606993,
          },
        ],
        position_type: "FLAT",
        position_uuid: "a4b1c7bc-3a3a-4acc-abc4-7123d1880f9a",
        return_at_close: 1.0005141843006293,
        trade_pair: ["ETHUSD", "ETH/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 70790.485,
        close_ms: 1717766589831,
        current_return: 1.001487361713551,
        initial_entry_price: 70777.0,
        is_closed_position: true,
        miner_hotkey: "5HY6RF4RVDnX8CQ5JrcnzjM2rixQXZpW2eh48aNLUYT1V9LW",
        net_leverage: 0.0,
        open_ms: 1717763910083,
        orders: [
          {
            leverage: 0.1,
            order_type: "LONG",
            order_uuid: "5eb2b7e4-4c67-4d66-bfbd-e0314bee1348",
            price: 70777.0,
            price_sources: [],
            processed_ms: 1717763909873,
          },
          {
            leverage: 0.1,
            order_type: "LONG",
            order_uuid: "40005e72-6d66-44f2-b992-932983a65ba7",
            price: 70803.97,
            price_sources: [],
            processed_ms: 1717763955658,
          },
          {
            leverage: 0.2,
            order_type: "FLAT",
            order_uuid: "e6d1fbe9-121c-4f6a-9069-ffff6d3d721a",
            price: 71316.84,
            price_sources: [],
            processed_ms: 1717766589831,
          },
        ],
        position_type: "FLAT",
        position_uuid: "c5c860dc-382e-47f9-b189-44f1dcec56f8",
        return_at_close: 1.0012870642412082,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 3677.62,
        close_ms: 1718095246135,
        current_return: 0.9922487913378761,
        initial_entry_price: 3677.62,
        is_closed_position: true,
        miner_hotkey: "5HY6RF4RVDnX8CQ5JrcnzjM2rixQXZpW2eh48aNLUYT1V9LW",
        net_leverage: 0.0,
        open_ms: 1718030125877,
        orders: [
          {
            leverage: 0.2,
            order_type: "LONG",
            order_uuid: "60abad7e-7dc0-4635-b019-4e4632fcce6d",
            price: 3677.62,
            price_sources: [],
            processed_ms: 1718030125348,
          },
          {
            leverage: 0.2,
            order_type: "FLAT",
            order_uuid: "3c790457-6279-4355-bf63-53249c20ad57",
            price: 3535.09,
            price_sources: [],
            processed_ms: 1718095246135,
          },
        ],
        position_type: "FLAT",
        position_uuid: "8ed317ef-75c1-4d27-8d57-ec40ec8be741",
        return_at_close: 0.9920503415796086,
        trade_pair: ["ETHUSD", "ETH/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 67568.69,
        close_ms: 1718195962659,
        current_return: 1.0051305123719285,
        initial_entry_price: 67568.69,
        is_closed_position: true,
        miner_hotkey: "5HY6RF4RVDnX8CQ5JrcnzjM2rixQXZpW2eh48aNLUYT1V9LW",
        net_leverage: 0.0,
        open_ms: 1718095485761,
        orders: [
          {
            leverage: 0.2,
            order_type: "LONG",
            order_uuid: "288db77c-3364-4f8c-bab1-370bbf4ebb92",
            price: 67568.69,
            price_sources: [],
            processed_ms: 1718095484989,
          },
          {
            leverage: 0.2,
            order_type: "FLAT",
            order_uuid: "7753c457-0c3a-4086-8dd6-4f41cd655884",
            price: 69302.0,
            price_sources: [],
            processed_ms: 1718195962659,
          },
        ],
        position_type: "FLAT",
        position_uuid: "ce645f5b-91b3-4b35-96a6-b89b34354848",
        return_at_close: 1.0049294862694542,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 3535.29,
        close_ms: 0,
        current_return: 0.977464932155495,
        initial_entry_price: 3535.29,
        is_closed_position: false,
        miner_hotkey: "5HY6RF4RVDnX8CQ5JrcnzjM2rixQXZpW2eh48aNLUYT1V9LW",
        net_leverage: 0.2,
        open_ms: 1718095467124,
        orders: [
          {
            leverage: 0.2,
            order_type: "LONG",
            order_uuid: "434e1a3d-14af-468f-9059-1c411e21b5e2",
            price: 3535.29,
            price_sources: [],
            processed_ms: 1718095466818,
          },
        ],
        position_type: "LONG",
        position_uuid: "6a192d40-3d9b-4e7c-b84b-5240bd9bc033",
        return_at_close: 0.9773671856622794,
        trade_pair: ["ETHUSD", "ETH/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 68843.72,
        close_ms: 0,
        current_return: 0.9688955797275336,
        initial_entry_price: 68843.72,
        is_closed_position: false,
        miner_hotkey: "5HY6RF4RVDnX8CQ5JrcnzjM2rixQXZpW2eh48aNLUYT1V9LW",
        net_leverage: 0.2,
        open_ms: 1718219538555,
        orders: [
          {
            leverage: 0.2,
            order_type: "LONG",
            order_uuid: "7520e95b-04b5-4aa2-b151-bffb106f4f5f",
            price: 68843.72,
            price_sources: [],
            processed_ms: 1718219537508,
          },
        ],
        position_type: "LONG",
        position_uuid: "f698697e-8419-4845-8412-1c0fae85a6b2",
        return_at_close: 0.9687986901695609,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
    ],
    thirty_day_returns: 1.0049294862694542,
  },
  "5HY9Zv2ATwsyhyqjWy8a9tvKedw7BUqfrcGUhb5v1HMif1eN": {
    all_time_returns: 1.0,
    n_positions: 0,
    percentage_profitable: 0.0,
    positions: [
      {
        average_entry_price: 3398.83,
        close_ms: 0,
        current_return: 0.9970720983397228,
        initial_entry_price: 3398.83,
        is_closed_position: false,
        miner_hotkey: "5HY9Zv2ATwsyhyqjWy8a9tvKedw7BUqfrcGUhb5v1HMif1eN",
        net_leverage: 0.038,
        open_ms: 1719592263458,
        orders: [
          {
            leverage: 0.038,
            order_type: "LONG",
            order_uuid: "3a3543ca-674c-4e33-981c-c235c08a8d3c",
            price: 3398.83,
            price_sources: [],
            processed_ms: 1719592262518,
          },
        ],
        position_type: "LONG",
        position_uuid: "f7634cec-7f44-4823-ab7d-743529a642cb",
        return_at_close: 0.9970531539698543,
        trade_pair: ["ETHUSD", "ETH/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 62652.00000000001,
        close_ms: 0,
        current_return: 0.9927935261444167,
        initial_entry_price: 62652.0,
        is_closed_position: false,
        miner_hotkey: "5HY9Zv2ATwsyhyqjWy8a9tvKedw7BUqfrcGUhb5v1HMif1eN",
        net_leverage: 0.1,
        open_ms: 1719842529348,
        orders: [
          {
            leverage: 0.1,
            order_type: "LONG",
            order_uuid: "733fdcb0-7d20-45fd-9db5-c966b98c0726",
            price: 62652.0,
            price_sources: [],
            processed_ms: 1719842528840,
          },
        ],
        position_type: "LONG",
        position_uuid: "5bdfa36e-9c92-4ac1-ba79-9bc0c12cf5a6",
        return_at_close: 0.9927438864681095,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
    ],
    thirty_day_returns: 1.0,
  },
  "5HYRAnpjhcT45f6udFAbfJXwUmqqeaNvte4sTjuQvDxTaQB3": {
    all_time_returns: 1.2022328891780292,
    n_positions: 28,
    percentage_profitable: 0.9642857142857143,
    positions: [
      {
        average_entry_price: 3357.24,
        close_ms: 1713052456854,
        current_return: 1.1002400781594404,
        initial_entry_price: 3357.24,
        is_closed_position: true,
        miner_hotkey: "5HYRAnpjhcT45f6udFAbfJXwUmqqeaNvte4sTjuQvDxTaQB3",
        net_leverage: 0.0,
        open_ms: 1711191002516,
        orders: [
          {
            leverage: -1.0,
            order_type: "SHORT",
            order_uuid: "cfcc97e2-f284-4785-b95e-695a31bf94f6",
            price: 3357.24,
            price_sources: [],
            processed_ms: 1711191002515,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "b605489b-5b5f-4b89-983e-6e931c6ea417",
            price: 3020.71,
            price_sources: [],
            processed_ms: 1713052456854,
          },
        ],
        position_type: "FLAT",
        position_uuid: "5d0e3081-f960-428e-b626-f8512c80f213",
        return_at_close: 1.099139838081281,
        trade_pair: ["ETHUSD", "ETH/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 3032.32,
        close_ms: 1713100817581,
        current_return: 1.0023368246095399,
        initial_entry_price: 3032.32,
        is_closed_position: true,
        miner_hotkey: "5HYRAnpjhcT45f6udFAbfJXwUmqqeaNvte4sTjuQvDxTaQB3",
        net_leverage: 0.0,
        open_ms: 1713097681985,
        orders: [
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "666dd20d-7215-4395-a55e-bf09a1ef6d42",
            price: 3032.32,
            price_sources: [],
            processed_ms: 1713097681984,
          },
          {
            leverage: 0.3,
            order_type: "FLAT",
            order_uuid: "860b1d4f-9b6d-4fc4-9e65-0922f95350c8",
            price: 3055.94,
            price_sources: [],
            processed_ms: 1713100817581,
          },
        ],
        position_type: "FLAT",
        position_uuid: "4e246ae6-e358-47a7-8133-92969029deff",
        return_at_close: 1.002036123562157,
        trade_pair: ["ETHUSD", "ETH/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 64523.0,
        close_ms: 1713162196877,
        current_return: 1.0225714861367265,
        initial_entry_price: 64523.0,
        is_closed_position: true,
        miner_hotkey: "5HYRAnpjhcT45f6udFAbfJXwUmqqeaNvte4sTjuQvDxTaQB3",
        net_leverage: 0.0,
        open_ms: 1711190916977,
        orders: [
          {
            leverage: 1.0,
            order_type: "LONG",
            order_uuid: "5d0983e2-3139-4556-af82-16217c4fa728",
            price: 64523.0,
            price_sources: [],
            processed_ms: 1711190916977,
          },
          {
            leverage: 0.25,
            order_type: "FLAT",
            order_uuid: "a2d93975-1762-41b7-bbf2-9831b2e77e00",
            price: 65979.38,
            price_sources: [],
            processed_ms: 1713162196877,
          },
        ],
        position_type: "FLAT",
        position_uuid: "02c7c14b-801f-4037-b2e7-80d3c6b96b14",
        return_at_close: 1.0215489146505898,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 62951.34,
        close_ms: 1713208441676,
        current_return: 1.0002917650362964,
        initial_entry_price: 62951.34,
        is_closed_position: true,
        miner_hotkey: "5HYRAnpjhcT45f6udFAbfJXwUmqqeaNvte4sTjuQvDxTaQB3",
        net_leverage: 0.0,
        open_ms: 1713208210922,
        orders: [
          {
            leverage: 0.1,
            order_type: "LONG",
            order_uuid: "20def421-c2f1-47f5-91e2-8afa07812e1a",
            price: 62951.34,
            price_sources: [],
            processed_ms: 1713208210878,
          },
          {
            leverage: 0.1,
            order_type: "FLAT",
            order_uuid: "9e922fae-9ec2-45a5-90d8-8f6f4ddcfada",
            price: 63135.01,
            price_sources: [],
            processed_ms: 1713208441676,
          },
        ],
        position_type: "FLAT",
        position_uuid: "a2974a5e-2733-43c5-ab1f-c42852a978b4",
        return_at_close: 1.0001917358597927,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 3061.63,
        close_ms: 1713208591676,
        current_return: 1.0003723506759472,
        initial_entry_price: 3061.63,
        is_closed_position: true,
        miner_hotkey: "5HYRAnpjhcT45f6udFAbfJXwUmqqeaNvte4sTjuQvDxTaQB3",
        net_leverage: 0.0,
        open_ms: 1713208295967,
        orders: [
          {
            leverage: 0.1,
            order_type: "LONG",
            order_uuid: "40aa39e5-5d49-4035-8cc4-178b50da6976",
            price: 3061.63,
            price_sources: [],
            processed_ms: 1713208295966,
          },
          {
            leverage: 0.1,
            order_type: "FLAT",
            order_uuid: "b9c3b8bc-6cda-4208-bfd6-844c9a67b1c5",
            price: 3073.03,
            price_sources: [],
            processed_ms: 1713208591676,
          },
        ],
        position_type: "FLAT",
        position_uuid: "bb628edb-4812-4c97-a592-8e777f41a69f",
        return_at_close: 1.0002723134408795,
        trade_pair: ["ETHUSD", "ETH/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 62731.6,
        close_ms: 1713209004039,
        current_return: 1.0006460221005045,
        initial_entry_price: 62731.6,
        is_closed_position: true,
        miner_hotkey: "5HYRAnpjhcT45f6udFAbfJXwUmqqeaNvte4sTjuQvDxTaQB3",
        net_leverage: 0.0,
        open_ms: 1713208819721,
        orders: [
          {
            leverage: 0.1,
            order_type: "LONG",
            order_uuid: "3fe174a9-2df9-400e-9db6-7cee464ea6f7",
            price: 62731.6,
            price_sources: [],
            processed_ms: 1713208819720,
          },
          {
            leverage: 0.1,
            order_type: "FLAT",
            order_uuid: "cb5c86f6-70ac-46d5-8591-6ddf4678ab0e",
            price: 63136.86,
            price_sources: [],
            processed_ms: 1713209004039,
          },
        ],
        position_type: "FLAT",
        position_uuid: "c3c7873c-f2b7-4511-bb42-b483f7421b25",
        return_at_close: 1.0005459574982944,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 3069.72,
        close_ms: 1713209114097,
        current_return: 1.0005309930547412,
        initial_entry_price: 3069.72,
        is_closed_position: true,
        miner_hotkey: "5HYRAnpjhcT45f6udFAbfJXwUmqqeaNvte4sTjuQvDxTaQB3",
        net_leverage: 0.0,
        open_ms: 1713208905060,
        orders: [
          {
            leverage: 0.1,
            order_type: "LONG",
            order_uuid: "d45d63f9-47f9-4407-95a6-724f0d35a682",
            price: 3069.72,
            price_sources: [],
            processed_ms: 1713208905059,
          },
          {
            leverage: 0.1,
            order_type: "FLAT",
            order_uuid: "4dd8ece3-2be5-4768-90c5-d6b7031c8134",
            price: 3086.02,
            price_sources: [],
            processed_ms: 1713209114097,
          },
        ],
        position_type: "FLAT",
        position_uuid: "305d99ec-fca4-41c9-b24e-c30d05244e57",
        return_at_close: 1.0004309399554359,
        trade_pair: ["ETHUSD", "ETH/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 3088.2,
        close_ms: 1713209700867,
        current_return: 1.0004329382811994,
        initial_entry_price: 3088.2,
        is_closed_position: true,
        miner_hotkey: "5HYRAnpjhcT45f6udFAbfJXwUmqqeaNvte4sTjuQvDxTaQB3",
        net_leverage: 0.0,
        open_ms: 1713209286763,
        orders: [
          {
            leverage: 0.1,
            order_type: "LONG",
            order_uuid: "f2fb85bb-4cee-42b7-af7b-afeb165c19fe",
            price: 3088.2,
            price_sources: [],
            processed_ms: 1713209286329,
          },
          {
            leverage: 0.1,
            order_type: "FLAT",
            order_uuid: "1f81fca0-d20f-4b0f-8bc4-336b199ac2b5",
            price: 3101.57,
            price_sources: [],
            processed_ms: 1713209700867,
          },
        ],
        position_type: "FLAT",
        position_uuid: "6b57efaf-1ae0-47c7-8a26-018032eb666a",
        return_at_close: 1.0003328949873713,
        trade_pair: ["ETHUSD", "ETH/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 3098.5,
        close_ms: 1713210379512,
        current_return: 1.0002949814426336,
        initial_entry_price: 3098.5,
        is_closed_position: true,
        miner_hotkey: "5HYRAnpjhcT45f6udFAbfJXwUmqqeaNvte4sTjuQvDxTaQB3",
        net_leverage: 0.0,
        open_ms: 1713209852677,
        orders: [
          {
            leverage: 0.1,
            order_type: "LONG",
            order_uuid: "3c7faa4b-b0a9-4986-a575-f15708a405bd",
            price: 3098.5,
            price_sources: [],
            processed_ms: 1713209852676,
          },
          {
            leverage: 0.1,
            order_type: "FLAT",
            order_uuid: "d27a69ae-184e-4818-9fd3-f707046aaad9",
            price: 3107.64,
            price_sources: [],
            processed_ms: 1713210379512,
          },
        ],
        position_type: "FLAT",
        position_uuid: "d1181cd9-e80e-4b74-839e-b4a2196bd783",
        return_at_close: 1.0001949519444893,
        trade_pair: ["ETHUSD", "ETH/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 63256.33,
        close_ms: 1713210541825,
        current_return: 1.000531029858988,
        initial_entry_price: 63256.33,
        is_closed_position: true,
        miner_hotkey: "5HYRAnpjhcT45f6udFAbfJXwUmqqeaNvte4sTjuQvDxTaQB3",
        net_leverage: 0.0,
        open_ms: 1713209200701,
        orders: [
          {
            leverage: 0.1,
            order_type: "LONG",
            order_uuid: "d135d7fb-b74f-4a50-b0af-fee0e6215e1c",
            price: 63256.33,
            price_sources: [],
            processed_ms: 1713209200700,
          },
          {
            leverage: 0.1,
            order_type: "FLAT",
            order_uuid: "4a360a00-007e-4397-ac9b-80145583027e",
            price: 63592.24,
            price_sources: [],
            processed_ms: 1713210541825,
          },
        ],
        position_type: "FLAT",
        position_uuid: "7b7ce939-72bd-4995-8f68-575ab8108d29",
        return_at_close: 1.000430976756002,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 62620.01,
        close_ms: 1713452030122,
        current_return: 1.0021853956906106,
        initial_entry_price: 62620.01,
        is_closed_position: true,
        miner_hotkey: "5HYRAnpjhcT45f6udFAbfJXwUmqqeaNvte4sTjuQvDxTaQB3",
        net_leverage: 0.0,
        open_ms: 1713356724137,
        orders: [
          {
            leverage: 0.15,
            order_type: "LONG",
            order_uuid: "ed1f8f88-9f1d-461a-97ef-7d00102cb529",
            price: 62620.01,
            price_sources: [],
            processed_ms: 1713356724135,
          },
          {
            leverage: 0.15,
            order_type: "FLAT",
            order_uuid: "a13aec06-dad8-45b5-98b4-3275e112b573",
            price: 63532.34,
            price_sources: [],
            processed_ms: 1713452030122,
          },
        ],
        position_type: "FLAT",
        position_uuid: "48bb479e-8ab7-4b90-b32a-ae50fe393e16",
        return_at_close: 1.002035067881257,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 3042.57,
        close_ms: 1713452607385,
        current_return: 1.00210562123468,
        initial_entry_price: 3042.57,
        is_closed_position: true,
        miner_hotkey: "5HYRAnpjhcT45f6udFAbfJXwUmqqeaNvte4sTjuQvDxTaQB3",
        net_leverage: 0.0,
        open_ms: 1713356591191,
        orders: [
          {
            leverage: 0.15,
            order_type: "LONG",
            order_uuid: "a9100bb9-106c-4e66-82b0-221544a00287",
            price: 3042.57,
            price_sources: [],
            processed_ms: 1713356591190,
          },
          {
            leverage: 0.15,
            order_type: "FLAT",
            order_uuid: "7782d7bf-0971-4d73-9f9d-ab86c6030321",
            price: 3085.28,
            price_sources: [],
            processed_ms: 1713452607385,
          },
        ],
        position_type: "FLAT",
        position_uuid: "55604702-4cea-4a16-9b29-f097c7d6824c",
        return_at_close: 1.0019553053914947,
        trade_pair: ["ETHUSD", "ETH/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 64342.89000000001,
        close_ms: 1716116172292,
        current_return: 1.0091823976200012,
        initial_entry_price: 64342.89,
        is_closed_position: true,
        miner_hotkey: "5HYRAnpjhcT45f6udFAbfJXwUmqqeaNvte4sTjuQvDxTaQB3",
        net_leverage: 0.0,
        open_ms: 1713986997477,
        orders: [
          {
            leverage: 0.2,
            order_type: "LONG",
            order_uuid: "f78fceae-4cce-445b-8708-8845104df98d",
            price: 64342.89,
            price_sources: [],
            processed_ms: 1713986997475,
          },
          {
            leverage: 0.2,
            order_type: "FLAT",
            order_uuid: "16320c7e-a6e9-4fe6-a64d-c35603d101a4",
            price: 67297.0,
            price_sources: [],
            processed_ms: 1716116172292,
          },
        ],
        position_type: "FLAT",
        position_uuid: "9c66bb29-c1c1-4ca9-90b7-6110b5105c27",
        return_at_close: 1.0089805611404772,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 66878.52,
        close_ms: 1716125014595,
        current_return: 1.0002690849019984,
        initial_entry_price: 66878.52,
        is_closed_position: true,
        miner_hotkey: "5HYRAnpjhcT45f6udFAbfJXwUmqqeaNvte4sTjuQvDxTaQB3",
        net_leverage: 0.0,
        open_ms: 1716123353172,
        orders: [
          {
            leverage: 0.4,
            order_type: "LONG",
            order_uuid: "6889fb7f-08c4-4235-a356-cb6c40fa60a0",
            price: 66878.52,
            price_sources: [],
            processed_ms: 1716123351358,
          },
          {
            leverage: 0.4,
            order_type: "FLAT",
            order_uuid: "fec82204-bb44-4dd8-bb93-89ac47f5d0cd",
            price: 66923.51,
            price_sources: [],
            processed_ms: 1716125014595,
          },
        ],
        position_type: "FLAT",
        position_uuid: "ff6c99a8-578d-4cf8-837c-125b88208b2b",
        return_at_close: 0.9998689772680377,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 66303.0,
        close_ms: 1716182411932,
        current_return: 1.012552071550307,
        initial_entry_price: 66303.0,
        is_closed_position: true,
        miner_hotkey: "5HYRAnpjhcT45f6udFAbfJXwUmqqeaNvte4sTjuQvDxTaQB3",
        net_leverage: 0.0,
        open_ms: 1716146232776,
        orders: [
          {
            leverage: 1.0,
            order_type: "LONG",
            order_uuid: "3beb95ee-02a5-45e9-8ea7-b1247ac516f4",
            price: 66303.0,
            price_sources: [],
            processed_ms: 1716146232244,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "9806722f-001e-480e-bd0f-a02e17d91e38",
            price: 67135.24,
            price_sources: [],
            processed_ms: 1716182411932,
          },
        ],
        position_type: "FLAT",
        position_uuid: "5f628970-2df5-4b19-a460-5c354476b2b7",
        return_at_close: 1.0115395194787566,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 3146.5,
        close_ms: 1716231212484,
        current_return: 1.000259335769903,
        initial_entry_price: 3146.5,
        is_closed_position: true,
        miner_hotkey: "5HYRAnpjhcT45f6udFAbfJXwUmqqeaNvte4sTjuQvDxTaQB3",
        net_leverage: 0.0,
        open_ms: 1713987294891,
        orders: [
          {
            leverage: 0.2,
            order_type: "LONG",
            order_uuid: "1e5dcf36-07c3-40cc-99f2-b44dc5da0631",
            price: 3146.5,
            price_sources: [],
            processed_ms: 1713987294889,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "0809c406-0990-433a-a5bf-ee2c6f9296d1",
            price: 3150.58,
            price_sources: [],
            processed_ms: 1716231212484,
          },
        ],
        position_type: "FLAT",
        position_uuid: "09e127ab-8a92-4a8d-8f48-a5305c35d8e9",
        return_at_close: 1.0000592839027491,
        trade_pair: ["ETHUSD", "ETH/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 3147.13,
        close_ms: 1716233199999,
        current_return: 1.0187656690381395,
        initial_entry_price: 3147.13,
        is_closed_position: true,
        miner_hotkey: "5HYRAnpjhcT45f6udFAbfJXwUmqqeaNvte4sTjuQvDxTaQB3",
        net_leverage: 0.0,
        open_ms: 1716232630811,
        orders: [
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "339f3bc1-4df0-4d43-928c-85f2f9620bc8",
            price: 3147.13,
            price_sources: [],
            processed_ms: 1716232629641,
          },
          {
            leverage: 0.3,
            order_type: "FLAT",
            order_uuid: "71e8a73b-0175-489d-bd5b-8ade372d449d",
            price: 3343.99,
            price_sources: [],
            processed_ms: 1716233199999,
          },
        ],
        position_type: "FLAT",
        position_uuid: "8b5a7c1b-87d4-43b3-a1a4-90e065fbfca0",
        return_at_close: 1.0184600393374281,
        trade_pair: ["ETHUSD", "ETH/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 69891.26,
        close_ms: 1716385630681,
        current_return: 1.002442794707092,
        initial_entry_price: 69891.26,
        is_closed_position: true,
        miner_hotkey: "5HYRAnpjhcT45f6udFAbfJXwUmqqeaNvte4sTjuQvDxTaQB3",
        net_leverage: 0.0,
        open_ms: 1716366337426,
        orders: [
          {
            leverage: -0.5,
            order_type: "SHORT",
            order_uuid: "2538ffe5-f63d-429d-a446-126a6930d8a3",
            price: 69891.26,
            price_sources: [],
            processed_ms: 1716366336864,
          },
          {
            leverage: 0.5,
            order_type: "FLAT",
            order_uuid: "7f2578b6-590c-4c26-ba64-28464e9e1a45",
            price: 69549.8,
            price_sources: [],
            processed_ms: 1716385630681,
          },
        ],
        position_type: "FLAT",
        position_uuid: "68754938-d757-403f-8e13-2b90ca00466f",
        return_at_close: 1.0019415733097385,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 70371.77,
        close_ms: 1716399729980,
        current_return: 1.0036582140821526,
        initial_entry_price: 70371.77,
        is_closed_position: true,
        miner_hotkey: "5HYRAnpjhcT45f6udFAbfJXwUmqqeaNvte4sTjuQvDxTaQB3",
        net_leverage: 0.0,
        open_ms: 1716395619777,
        orders: [
          {
            leverage: -0.5,
            order_type: "SHORT",
            order_uuid: "5816489b-06fb-4bf7-89af-551c0ddc01dd",
            price: 70371.77,
            price_sources: [],
            processed_ms: 1716395619573,
          },
          {
            leverage: 0.5,
            order_type: "FLAT",
            order_uuid: "d2336718-e21a-4a17-ab66-46639967f741",
            price: 69856.9,
            price_sources: [],
            processed_ms: 1716399729980,
          },
        ],
        position_type: "FLAT",
        position_uuid: "5e58d8ed-7cbb-4cb0-98a6-bfa92a7fd87e",
        return_at_close: 1.0031563849751115,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 3652.6,
        close_ms: 1716545722574,
        current_return: 1.0042599791929037,
        initial_entry_price: 3652.6,
        is_closed_position: true,
        miner_hotkey: "5HYRAnpjhcT45f6udFAbfJXwUmqqeaNvte4sTjuQvDxTaQB3",
        net_leverage: 0.0,
        open_ms: 1716539565992,
        orders: [
          {
            leverage: 0.4,
            order_type: "LONG",
            order_uuid: "4f06c203-5dd6-43c4-8b98-4513a6ecbe61",
            price: 3652.6,
            price_sources: [],
            processed_ms: 1716539565989,
          },
          {
            leverage: 0.4,
            order_type: "FLAT",
            order_uuid: "30f537dc-8747-4ed6-b379-b2fa15fd3a39",
            price: 3691.5,
            price_sources: [],
            processed_ms: 1716545722574,
          },
        ],
        position_type: "FLAT",
        position_uuid: "08b247b4-d2a6-4b10-be1b-6c88e1ddc20d",
        return_at_close: 1.0038582752012266,
        trade_pair: ["ETHUSD", "ETH/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 3731.48,
        close_ms: 1717073416353,
        current_return: 1.0017022736286942,
        initial_entry_price: 3731.48,
        is_closed_position: true,
        miner_hotkey: "5HYRAnpjhcT45f6udFAbfJXwUmqqeaNvte4sTjuQvDxTaQB3",
        net_leverage: 0.0,
        open_ms: 1717060380770,
        orders: [
          {
            leverage: 0.2,
            order_type: "LONG",
            order_uuid: "b879e0bf-903d-4496-aadd-5ad942c6b83b",
            price: 3731.48,
            price_sources: [],
            processed_ms: 1717060380242,
          },
          {
            leverage: 0.2,
            order_type: "FLAT",
            order_uuid: "02660281-266e-4d97-b371-4e754327a257",
            price: 3763.24,
            price_sources: [],
            processed_ms: 1717073416353,
          },
        ],
        position_type: "FLAT",
        position_uuid: "53e8c9db-c3f7-43e7-9833-03fc776c1934",
        return_at_close: 1.0015019331739685,
        trade_pair: ["ETHUSD", "ETH/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 67637.59,
        close_ms: 1717073428784,
        current_return: 1.0012616948652369,
        initial_entry_price: 67637.59,
        is_closed_position: true,
        miner_hotkey: "5HYRAnpjhcT45f6udFAbfJXwUmqqeaNvte4sTjuQvDxTaQB3",
        net_leverage: 0.0,
        open_ms: 1717060363978,
        orders: [
          {
            leverage: 0.15,
            order_type: "LONG",
            order_uuid: "1a15792f-b8d4-4ecd-ab50-7aaadd31624b",
            price: 67637.59,
            price_sources: [],
            processed_ms: 1717060363792,
          },
          {
            leverage: 0.2,
            order_type: "FLAT",
            order_uuid: "02859cf0-e120-43d1-96c5-309cbd7e4aa2",
            price: 68206.51,
            price_sources: [],
            processed_ms: 1717073428784,
          },
        ],
        position_type: "FLAT",
        position_uuid: "da5fc617-8470-42cc-b5aa-1448628d879a",
        return_at_close: 1.0011115056110071,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 3767.5800000000004,
        close_ms: 1717429549851,
        current_return: 1.0005077529873287,
        initial_entry_price: 3767.58,
        is_closed_position: true,
        miner_hotkey: "5HYRAnpjhcT45f6udFAbfJXwUmqqeaNvte4sTjuQvDxTaQB3",
        net_leverage: 0.0,
        open_ms: 1717426759075,
        orders: [
          {
            leverage: 0.1,
            order_type: "LONG",
            order_uuid: "266a65a1-e2f1-484d-85b6-4d19b7e9c754",
            price: 3767.58,
            price_sources: [],
            processed_ms: 1717426758835,
          },
          {
            leverage: 0.2,
            order_type: "FLAT",
            order_uuid: "8aece696-37e3-4bac-a98b-a44a263c1ebd",
            price: 3786.71,
            price_sources: [],
            processed_ms: 1717429549851,
          },
        ],
        position_type: "FLAT",
        position_uuid: "c460b8be-a4e2-4849-b821-88838324bf95",
        return_at_close: 1.0004077022120301,
        trade_pair: ["ETHUSD", "ETH/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 68738.68,
        close_ms: 1717429839053,
        current_return: 1.0015100377254844,
        initial_entry_price: 68738.68,
        is_closed_position: true,
        miner_hotkey: "5HYRAnpjhcT45f6udFAbfJXwUmqqeaNvte4sTjuQvDxTaQB3",
        net_leverage: 0.0,
        open_ms: 1717426782443,
        orders: [
          {
            leverage: 0.2,
            order_type: "LONG",
            order_uuid: "2df89986-1c3b-4096-bf89-3e509172a28e",
            price: 68738.68,
            price_sources: [],
            processed_ms: 1717426782212,
          },
          {
            leverage: 0.2,
            order_type: "FLAT",
            order_uuid: "435719db-c3d7-412f-9d07-8fc2ee3345cc",
            price: 69257.67,
            price_sources: [],
            processed_ms: 1717429839053,
          },
        ],
        position_type: "FLAT",
        position_uuid: "b277b7eb-cec8-42b6-81f0-a1a37dea2688",
        return_at_close: 1.0013097357179392,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 3758.31,
        close_ms: 1717614106535,
        current_return: 1.004797369030229,
        initial_entry_price: 3758.31,
        is_closed_position: true,
        miner_hotkey: "5HYRAnpjhcT45f6udFAbfJXwUmqqeaNvte4sTjuQvDxTaQB3",
        net_leverage: 0.0,
        open_ms: 1717496898157,
        orders: [
          {
            leverage: 0.2,
            order_type: "LONG",
            order_uuid: "f1e791b9-948d-4741-9e4b-c00d054edc88",
            price: 3758.31,
            price_sources: [],
            processed_ms: 1717496897928,
          },
          {
            leverage: 0.2,
            order_type: "FLAT",
            order_uuid: "745616ca-1129-45b9-88c3-ea7ebb730882",
            price: 3848.46,
            price_sources: [],
            processed_ms: 1717614106535,
          },
        ],
        position_type: "FLAT",
        position_uuid: "425bbfca-810b-4d3b-b085-d8134197a1a6",
        return_at_close: 1.004596409556423,
        trade_pair: ["ETHUSD", "ETH/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 70495.01,
        close_ms: 1717713562463,
        current_return: 1.0008543016023403,
        initial_entry_price: 70495.01,
        is_closed_position: true,
        miner_hotkey: "5HYRAnpjhcT45f6udFAbfJXwUmqqeaNvte4sTjuQvDxTaQB3",
        net_leverage: 0.0,
        open_ms: 1717703747560,
        orders: [
          {
            leverage: 0.2,
            order_type: "LONG",
            order_uuid: "097d155b-a2e1-47af-8c1e-dda9ea122790",
            price: 70495.01,
            price_sources: [],
            processed_ms: 1717703747162,
          },
          {
            leverage: 0.2,
            order_type: "FLAT",
            order_uuid: "0b5e2475-4db5-4812-b42a-0536225c3be4",
            price: 70796.13,
            price_sources: [],
            processed_ms: 1717713562463,
          },
        ],
        position_type: "FLAT",
        position_uuid: "0e6bdb98-e2fe-4cc9-8bcb-59b4e5923959",
        return_at_close: 1.0006541307420198,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 3780.71,
        close_ms: 1717766606595,
        current_return: 1.001205593658334,
        initial_entry_price: 3780.71,
        is_closed_position: true,
        miner_hotkey: "5HYRAnpjhcT45f6udFAbfJXwUmqqeaNvte4sTjuQvDxTaQB3",
        net_leverage: 0.0,
        open_ms: 1717764008281,
        orders: [
          {
            leverage: 0.2,
            order_type: "LONG",
            order_uuid: "265ac535-7d4d-45d6-bdf5-7e72c84dbd5e",
            price: 3780.71,
            price_sources: [],
            processed_ms: 1717764008278,
          },
          {
            leverage: 0.2,
            order_type: "FLAT",
            order_uuid: "309e2bb9-edfe-4bdc-9542-2ad3c3d7fdc5",
            price: 3803.5,
            price_sources: [],
            processed_ms: 1717766606595,
          },
        ],
        position_type: "FLAT",
        position_uuid: "92e4b267-2bd0-49f2-900f-bbfb641ee925",
        return_at_close: 1.0010053525396023,
        trade_pair: ["ETHUSD", "ETH/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 3526.69,
        close_ms: 1718231559822,
        current_return: 1.0019661495623375,
        initial_entry_price: 3526.69,
        is_closed_position: true,
        miner_hotkey: "5HYRAnpjhcT45f6udFAbfJXwUmqqeaNvte4sTjuQvDxTaQB3",
        net_leverage: 0.0,
        open_ms: 1718221941265,
        orders: [
          {
            leverage: 0.2,
            order_type: "LONG",
            order_uuid: "ab7c3925-85a1-4cf8-a471-016eec145f1a",
            price: 3526.69,
            price_sources: [],
            processed_ms: 1718221940520,
          },
          {
            leverage: 0.2,
            order_type: "FLAT",
            order_uuid: "d519186f-d58e-497e-871a-8ad65e0fef08",
            price: 3561.36,
            price_sources: [],
            processed_ms: 1718231559822,
          },
        ],
        position_type: "FLAT",
        position_uuid: "d79b8984-fcf1-4a96-a944-cd9965e1da1d",
        return_at_close: 1.0017657563324252,
        trade_pair: ["ETHUSD", "ETH/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 3500.5,
        close_ms: 0,
        current_return: 0.9792286816169119,
        initial_entry_price: 3500.5,
        is_closed_position: false,
        miner_hotkey: "5HYRAnpjhcT45f6udFAbfJXwUmqqeaNvte4sTjuQvDxTaQB3",
        net_leverage: 0.2,
        open_ms: 1718264806384,
        orders: [
          {
            leverage: 0.2,
            order_type: "LONG",
            order_uuid: "7e6e00aa-86f0-406b-ad30-9a7bd4d79156",
            price: 3500.5,
            price_sources: [],
            processed_ms: 1718264804440,
          },
        ],
        position_type: "LONG",
        position_uuid: "74240656-c9fb-46a1-9a6f-61607d37cc9f",
        return_at_close: 0.9791307587487502,
        trade_pair: ["ETHUSD", "ETH/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 69475.39,
        close_ms: 0,
        current_return: 0.9673599817143883,
        initial_entry_price: 69475.39,
        is_closed_position: false,
        miner_hotkey: "5HYRAnpjhcT45f6udFAbfJXwUmqqeaNvte4sTjuQvDxTaQB3",
        net_leverage: 0.2,
        open_ms: 1718030094993,
        orders: [
          {
            leverage: 0.2,
            order_type: "LONG",
            order_uuid: "447ab7d5-9488-4793-9aeb-2d068fd21bb0",
            price: 69475.39,
            price_sources: [],
            processed_ms: 1718030094461,
          },
        ],
        position_type: "LONG",
        position_uuid: "1f239ea8-32ad-424f-b5b5-a419f93a36ea",
        return_at_close: 0.9672632457162169,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
    ],
    thirty_day_returns: 1.0017657563324252,
  },
  "5HYWkHt2KGBLvacHur1RJrB5onqkC5FfXbyMWKbP6UXTwVSg": {
    all_time_returns: 1.0,
    n_positions: 0,
    percentage_profitable: 0.0,
    positions: [
      {
        average_entry_price: 63794.490000000005,
        close_ms: 1711135599955,
        current_return: 1.0339355326768824,
        initial_entry_price: 63794.49,
        is_closed_position: true,
        miner_hotkey: "5HYWkHt2KGBLvacHur1RJrB5onqkC5FfXbyMWKbP6UXTwVSg",
        net_leverage: 0.0,
        open_ms: 1711134627970,
        orders: [
          {
            leverage: -10.0,
            order_type: "SHORT",
            order_uuid: "482bdf91-3140-4163-9199-234db3bb73ce",
            price: 63794.49,
            price_sources: [],
            processed_ms: 1711134627970,
          },
          {
            leverage: 10.0,
            order_type: "FLAT",
            order_uuid: "da22b658-7be7-48b8-9c60-5d32bb11c805",
            price: 63578.0,
            price_sources: [],
            processed_ms: 1711135599955,
          },
        ],
        position_type: "FLAT",
        position_uuid: "fa8f22f3-5d70-4211-a082-f03b9990a644",
        return_at_close: 1.0132568220233447,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 3330.671653543306,
        close_ms: 1711178430444,
        current_return: 1.0128420394004365,
        initial_entry_price: 3260.37,
        is_closed_position: true,
        miner_hotkey: "5HYWkHt2KGBLvacHur1RJrB5onqkC5FfXbyMWKbP6UXTwVSg",
        net_leverage: 0.0,
        open_ms: 1711143751848,
        orders: [
          {
            leverage: 0.02,
            order_type: "LONG",
            order_uuid: "bcc2cb3e-3991-438b-b333-2681fd3ebb69",
            price: 3260.37,
            price_sources: [],
            processed_ms: 1711143751848,
          },
          {
            leverage: 0.02,
            order_type: "LONG",
            order_uuid: "4c0338ce-35fb-4d8e-90d6-bf25ec882af3",
            price: 3290.94,
            price_sources: [],
            processed_ms: 1711144646636,
          },
          {
            leverage: 0.02,
            order_type: "LONG",
            order_uuid: "315087be-6a58-407c-bbdf-40325def2551",
            price: 3277.99,
            price_sources: [],
            processed_ms: 1711145557987,
          },
          {
            leverage: 0.02,
            order_type: "LONG",
            order_uuid: "cbfe31a2-eb2c-4fe4-8e09-4798b0e19c85",
            price: 3265.07,
            price_sources: [],
            processed_ms: 1711146450809,
          },
          {
            leverage: 0.02,
            order_type: "LONG",
            order_uuid: "866fae20-bb35-4f8b-b855-1aa4108ccbef",
            price: 3292.71,
            price_sources: [],
            processed_ms: 1711147335901,
          },
          {
            leverage: 0.02,
            order_type: "LONG",
            order_uuid: "ca5d1f8f-40d6-4991-92a2-32e0a72a21d2",
            price: 3283.82,
            price_sources: [],
            processed_ms: 1711148252387,
          },
          {
            leverage: 0.02,
            order_type: "LONG",
            order_uuid: "7af33d0b-999e-4d07-baf3-2c123310ddf8",
            price: 3295.61,
            price_sources: [],
            processed_ms: 1711149135867,
          },
          {
            leverage: 0.02,
            order_type: "LONG",
            order_uuid: "255d9a00-d4a4-4942-8f66-2ba8dd55680a",
            price: 3311.99,
            price_sources: [],
            processed_ms: 1711150057346,
          },
          {
            leverage: 0.02,
            order_type: "LONG",
            order_uuid: "86553f95-20d0-4704-aba8-fa9a65c0e716",
            price: 3314.75,
            price_sources: [],
            processed_ms: 1711150940151,
          },
          {
            leverage: 0.02,
            order_type: "LONG",
            order_uuid: "12524627-daef-4023-8c2d-3c5a9668122c",
            price: 3324.39,
            price_sources: [],
            processed_ms: 1711151856857,
          },
          {
            leverage: 0.02,
            order_type: "LONG",
            order_uuid: "383e0b21-eb2b-421a-b63f-4fc4c669dc97",
            price: 3317.92,
            price_sources: [],
            processed_ms: 1711152738178,
          },
          {
            leverage: 0.02,
            order_type: "LONG",
            order_uuid: "0b503c7a-c127-44c2-b1f0-b912f196cae8",
            price: 3319.07,
            price_sources: [],
            processed_ms: 1711153643613,
          },
          {
            leverage: 0.02,
            order_type: "LONG",
            order_uuid: "d77aa5ca-f419-41e3-979d-c8498aba28c6",
            price: 3323.6,
            price_sources: [],
            processed_ms: 1711154538839,
          },
          {
            leverage: 0.02,
            order_type: "LONG",
            order_uuid: "e9a843fc-3af9-46c2-b2d7-72c66084d2df",
            price: 3322.39,
            price_sources: [],
            processed_ms: 1711155460672,
          },
          {
            leverage: 0.02,
            order_type: "LONG",
            order_uuid: "febfcb3b-17f3-4b85-9390-dedd298bbdbf",
            price: 3331.16,
            price_sources: [],
            processed_ms: 1711156351919,
          },
          {
            leverage: 0.02,
            order_type: "LONG",
            order_uuid: "0c0f3144-a23c-4867-a58f-8b6747152000",
            price: 3338.2,
            price_sources: [],
            processed_ms: 1711157244158,
          },
          {
            leverage: 0.02,
            order_type: "LONG",
            order_uuid: "1903f23a-507d-4a32-b076-dc61c2793ed4",
            price: 3340.89,
            price_sources: [],
            processed_ms: 1711158141841,
          },
          {
            leverage: 0.02,
            order_type: "LONG",
            order_uuid: "ccef114c-ca9d-47a1-ad3d-86926e47d698",
            price: 3351.55,
            price_sources: [],
            processed_ms: 1711159041675,
          },
          {
            leverage: 0.02,
            order_type: "LONG",
            order_uuid: "9e28aa9f-2f18-4647-89f7-488d3eddc836",
            price: 3352.53,
            price_sources: [],
            processed_ms: 1711159942778,
          },
          {
            leverage: 0.02,
            order_type: "LONG",
            order_uuid: "a8671cde-f344-4b79-9fef-0ae9596ccda5",
            price: 3343.95,
            price_sources: [],
            processed_ms: 1711160839328,
          },
          {
            leverage: 0.02,
            order_type: "LONG",
            order_uuid: "6da1629b-7104-4397-95b7-b844f22cbf94",
            price: 3332.23,
            price_sources: [],
            processed_ms: 1711161756601,
          },
          {
            leverage: 0.02,
            order_type: "LONG",
            order_uuid: "5dd97656-67e0-480a-8eb3-333c7a340d81",
            price: 3337.19,
            price_sources: [],
            processed_ms: 1711162645199,
          },
          {
            leverage: 0.02,
            order_type: "LONG",
            order_uuid: "579661a8-b741-4883-90ac-74df95da96d5",
            price: 3330.31,
            price_sources: [],
            processed_ms: 1711163540960,
          },
          {
            leverage: 0.02,
            order_type: "LONG",
            order_uuid: "0cc50361-d161-4ada-a23e-a3f41ca2e042",
            price: 3298.81,
            price_sources: [],
            processed_ms: 1711164447815,
          },
          {
            leverage: 0.02,
            order_type: "LONG",
            order_uuid: "9703685a-0e51-45ea-a9ae-99bbd4ba0aaa",
            price: 3286.1,
            price_sources: [],
            processed_ms: 1711165351725,
          },
          {
            leverage: 0.02,
            order_type: "LONG",
            order_uuid: "72bf49e1-4fb4-4f31-99f6-387e76b6670a",
            price: 3301.84,
            price_sources: [],
            processed_ms: 1711166240939,
          },
          {
            leverage: 0.02,
            order_type: "LONG",
            order_uuid: "9e95cec1-d357-48a7-ba58-5d3a8a3503d2",
            price: 3322.17,
            price_sources: [],
            processed_ms: 1711167147662,
          },
          {
            leverage: 0.02,
            order_type: "LONG",
            order_uuid: "9cbd5254-7cc7-471e-b38e-109fd735552e",
            price: 3324.36,
            price_sources: [],
            processed_ms: 1711168047932,
          },
          {
            leverage: 0.02,
            order_type: "LONG",
            order_uuid: "7fd0eb5c-9ee4-437d-81a8-6fd2106ebaaf",
            price: 3330.51,
            price_sources: [],
            processed_ms: 1711168949686,
          },
          {
            leverage: 0.02,
            order_type: "LONG",
            order_uuid: "6237d814-f34a-4016-af6a-7aa557bbbc3e",
            price: 3328.66,
            price_sources: [],
            processed_ms: 1711169844765,
          },
          {
            leverage: 0.02,
            order_type: "LONG",
            order_uuid: "7f7730a4-0788-404d-ae53-dfe795e355c8",
            price: 3325.64,
            price_sources: [],
            processed_ms: 1711170743722,
          },
          {
            leverage: 0.02,
            order_type: "LONG",
            order_uuid: "e9c83edb-17f8-449b-8ff2-2b3ef8f12fa7",
            price: 3328.84,
            price_sources: [],
            processed_ms: 1711171649823,
          },
          {
            leverage: 0.02,
            order_type: "LONG",
            order_uuid: "d8985f69-c735-480d-bffc-749557653079",
            price: 3323.27,
            price_sources: [],
            processed_ms: 1711172561084,
          },
          {
            leverage: 0.02,
            order_type: "LONG",
            order_uuid: "e1e4b3a3-e8a9-4c78-88bc-b379846f32fb",
            price: 3328.59,
            price_sources: [],
            processed_ms: 1711173450723,
          },
          {
            leverage: 0.02,
            order_type: "LONG",
            order_uuid: "fe264578-ef48-4fca-8728-e8f54c3a87f1",
            price: 3327.19,
            price_sources: [],
            processed_ms: 1711174351907,
          },
          {
            leverage: 0.02,
            order_type: "LONG",
            order_uuid: "28babb85-0662-4362-aa0a-f6872b7ddb41",
            price: 3334.57,
            price_sources: [],
            processed_ms: 1711175243962,
          },
          {
            leverage: 0.11,
            order_type: "LONG",
            order_uuid: "261fedcb-fa05-4c1a-b5b5-548e1a489714",
            price: 3342.78,
            price_sources: [],
            processed_ms: 1711176183036,
          },
          {
            leverage: 0.11,
            order_type: "LONG",
            order_uuid: "647e5543-b440-4f19-b9a1-92d3f5ab0f2b",
            price: 3346.3,
            price_sources: [],
            processed_ms: 1711176621883,
          },
          {
            leverage: 0.11,
            order_type: "LONG",
            order_uuid: "5bb64e60-0b3c-4a70-bc2e-515b6da7fd2b",
            price: 3342.58,
            price_sources: [],
            processed_ms: 1711177102086,
          },
          {
            leverage: 0.11,
            order_type: "LONG",
            order_uuid: "61cd8163-dd3c-4278-83be-7aaf56dbe82c",
            price: 3344.55,
            price_sources: [],
            processed_ms: 1711177518792,
          },
          {
            leverage: 0.11,
            order_type: "LONG",
            order_uuid: "eb81b4f1-92fc-49c1-b868-cf9adb681b00",
            price: 3365.33,
            price_sources: [],
            processed_ms: 1711177968128,
          },
          {
            leverage: 0.11,
            order_type: "FLAT",
            order_uuid: "808f6424-2999-4bdd-adc6-7abbde22f064",
            price: 3363.64,
            price_sources: [],
            processed_ms: 1711178430444,
          },
        ],
        position_type: "FLAT",
        position_uuid: "8dfde17e-db82-4646-9d21-9e4fb1122296",
        return_at_close: 1.0102694206203593,
        trade_pair: ["ETHUSD", "ETH/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 3367.456,
        close_ms: 1711181132725,
        current_return: 1.0003624887018625,
        initial_entry_price: 3374.45,
        is_closed_position: true,
        miner_hotkey: "5HYWkHt2KGBLvacHur1RJrB5onqkC5FfXbyMWKbP6UXTwVSg",
        net_leverage: 0.0,
        open_ms: 1711178877884,
        orders: [
          {
            leverage: 0.11,
            order_type: "LONG",
            order_uuid: "53cddd88-9ecf-48dc-b323-202b6f84712c",
            price: 3374.45,
            price_sources: [],
            processed_ms: 1711178877882,
          },
          {
            leverage: 0.11,
            order_type: "LONG",
            order_uuid: "8b30a0d8-26cb-4bc7-a251-f46bdd8fe4b0",
            price: 3366.76,
            price_sources: [],
            processed_ms: 1711179324257,
          },
          {
            leverage: 0.11,
            order_type: "LONG",
            order_uuid: "3b07e0f3-7fe6-45f2-af0b-598352fd3759",
            price: 3366.94,
            price_sources: [],
            processed_ms: 1711179772794,
          },
          {
            leverage: 0.11,
            order_type: "LONG",
            order_uuid: "3b0d11fb-97fa-4b4e-9563-0236b2579554",
            price: 3368.23,
            price_sources: [],
            processed_ms: 1711180230786,
          },
          {
            leverage: 0.11,
            order_type: "LONG",
            order_uuid: "39d48330-e955-408f-b189-be4c65a04b4a",
            price: 3360.9,
            price_sources: [],
            processed_ms: 1711180672712,
          },
          {
            leverage: 0.11,
            order_type: "FLAT",
            order_uuid: "5863ec74-b16c-400c-b4cb-7dfb78836875",
            price: 3369.68,
            price_sources: [],
            processed_ms: 1711181132725,
          },
        ],
        position_type: "FLAT",
        position_uuid: "20459d45-ad66-4b7c-b28c-db82aa19b71d",
        return_at_close: 0.9992620899642904,
        trade_pair: ["ETHUSD", "ETH/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 3354.039999999999,
        close_ms: 1711183821821,
        current_return: 0.9987614602483328,
        initial_entry_price: 3361.62,
        is_closed_position: true,
        miner_hotkey: "5HYWkHt2KGBLvacHur1RJrB5onqkC5FfXbyMWKbP6UXTwVSg",
        net_leverage: 0.0,
        open_ms: 1711181625603,
        orders: [
          {
            leverage: 0.11,
            order_type: "LONG",
            order_uuid: "4661b43d-d60b-4d23-843c-50d07601c8d8",
            price: 3361.62,
            price_sources: [],
            processed_ms: 1711181625602,
          },
          {
            leverage: 0.11,
            order_type: "LONG",
            order_uuid: "dc69da55-7c05-4885-a920-a25504a92388",
            price: 3361.1,
            price_sources: [],
            processed_ms: 1711182027980,
          },
          {
            leverage: 0.11,
            order_type: "LONG",
            order_uuid: "1b505f9b-091c-43bd-b56e-ad10a7fa2174",
            price: 3355.2,
            price_sources: [],
            processed_ms: 1711182474956,
          },
          {
            leverage: 0.11,
            order_type: "LONG",
            order_uuid: "50dd27c0-aeb3-44f0-9b7e-08319e3b3332",
            price: 3345.24,
            price_sources: [],
            processed_ms: 1711182930181,
          },
          {
            leverage: 0.11,
            order_type: "LONG",
            order_uuid: "4e06c909-537e-4573-8a7b-967381acb43b",
            price: 3347.04,
            price_sources: [],
            processed_ms: 1711183377077,
          },
          {
            leverage: 0.11,
            order_type: "FLAT",
            order_uuid: "a09ced17-b7c4-483b-bcdf-62fd5be61eef",
            price: 3346.47,
            price_sources: [],
            processed_ms: 1711183821821,
          },
        ],
        position_type: "FLAT",
        position_uuid: "a7364b15-177c-4282-a79e-b09c4ea016fc",
        return_at_close: 0.9976628226420596,
        trade_pair: ["ETHUSD", "ETH/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 3339.616,
        close_ms: 1711186552513,
        current_return: 0.9994515290108511,
        initial_entry_price: 3345.3,
        is_closed_position: true,
        miner_hotkey: "5HYWkHt2KGBLvacHur1RJrB5onqkC5FfXbyMWKbP6UXTwVSg",
        net_leverage: 0.0,
        open_ms: 1711184274868,
        orders: [
          {
            leverage: 0.11,
            order_type: "LONG",
            order_uuid: "d77e54ed-8853-4e5c-b5fd-11177480699a",
            price: 3345.3,
            price_sources: [],
            processed_ms: 1711184274867,
          },
          {
            leverage: 0.11,
            order_type: "LONG",
            order_uuid: "9842e440-af39-4330-bf87-f9204d1717cf",
            price: 3347.13,
            price_sources: [],
            processed_ms: 1711184750735,
          },
          {
            leverage: 0.11,
            order_type: "LONG",
            order_uuid: "4ec22c9a-5511-48ba-bc47-1d3815de8d7f",
            price: 3334.8,
            price_sources: [],
            processed_ms: 1711185174996,
          },
          {
            leverage: 0.11,
            order_type: "LONG",
            order_uuid: "89cbfe27-feac-4649-a308-8fa13e867ab0",
            price: 3330.44,
            price_sources: [],
            processed_ms: 1711185626894,
          },
          {
            leverage: 0.11,
            order_type: "LONG",
            order_uuid: "a7cb4950-0acf-462a-8638-fc7505a60c77",
            price: 3340.41,
            price_sources: [],
            processed_ms: 1711186071955,
          },
          {
            leverage: 0.11,
            order_type: "FLAT",
            order_uuid: "c686a9a7-fce5-45bb-a0fb-8e4f89e28823",
            price: 3336.28,
            price_sources: [],
            processed_ms: 1711186552513,
          },
        ],
        position_type: "FLAT",
        position_uuid: "6e19beb6-9599-4f17-ab19-121170783a20",
        return_at_close: 0.9983521323289392,
        trade_pair: ["ETHUSD", "ETH/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 3348.8219999999997,
        close_ms: 1711189224999,
        current_return: 1.0017392281012827,
        initial_entry_price: 3338.78,
        is_closed_position: true,
        miner_hotkey: "5HYWkHt2KGBLvacHur1RJrB5onqkC5FfXbyMWKbP6UXTwVSg",
        net_leverage: 0.0,
        open_ms: 1711186972881,
        orders: [
          {
            leverage: 0.11,
            order_type: "LONG",
            order_uuid: "9c2fd5e4-2520-4252-99aa-4830d6d8d2f0",
            price: 3338.78,
            price_sources: [],
            processed_ms: 1711186972879,
          },
          {
            leverage: 0.11,
            order_type: "LONG",
            order_uuid: "986859e2-4441-47c4-b8ea-7d27a3e9d391",
            price: 3340.71,
            price_sources: [],
            processed_ms: 1711187424999,
          },
          {
            leverage: 0.11,
            order_type: "LONG",
            order_uuid: "3ceb6bbe-655c-4125-9108-6de7d5de0ff3",
            price: 3345.67,
            price_sources: [],
            processed_ms: 1711187875067,
          },
          {
            leverage: 0.11,
            order_type: "LONG",
            order_uuid: "e4e8bc08-5b9b-4a95-9d47-2a1557220ec7",
            price: 3358.89,
            price_sources: [],
            processed_ms: 1711188332017,
          },
          {
            leverage: 0.11,
            order_type: "LONG",
            order_uuid: "cf01d323-d092-47c9-9d4e-410206cf2a99",
            price: 3360.06,
            price_sources: [],
            processed_ms: 1711188777183,
          },
          {
            leverage: 0.11,
            order_type: "FLAT",
            order_uuid: "91d3e970-d972-4d0c-ad1c-cc965e0522e4",
            price: 3359.38,
            price_sources: [],
            processed_ms: 1711189224999,
          },
        ],
        position_type: "FLAT",
        position_uuid: "209894cc-ac85-41b9-a78f-e368adda308b",
        return_at_close: 1.0006373149503713,
        trade_pair: ["ETHUSD", "ETH/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 3362.7879999999996,
        close_ms: 1711191933109,
        current_return: 0.9984829232617047,
        initial_entry_price: 3363.64,
        is_closed_position: true,
        miner_hotkey: "5HYWkHt2KGBLvacHur1RJrB5onqkC5FfXbyMWKbP6UXTwVSg",
        net_leverage: 0.0,
        open_ms: 1711189677315,
        orders: [
          {
            leverage: 0.11,
            order_type: "LONG",
            order_uuid: "e7da7a02-e8a9-4cee-8c9f-5fc4bdb8eab8",
            price: 3363.64,
            price_sources: [],
            processed_ms: 1711189677314,
          },
          {
            leverage: 0.11,
            order_type: "LONG",
            order_uuid: "07b4b587-8d7e-4428-ab15-e22a1e70fb13",
            price: 3374.63,
            price_sources: [],
            processed_ms: 1711190131618,
          },
          {
            leverage: 0.11,
            order_type: "LONG",
            order_uuid: "4acbcb67-0d25-4415-bb64-b9b4515aa240",
            price: 3366.38,
            price_sources: [],
            processed_ms: 1711190580149,
          },
          {
            leverage: 0.11,
            order_type: "LONG",
            order_uuid: "f3b93a0f-4500-4b75-a16d-8e2ebc4f86e3",
            price: 3364.18,
            price_sources: [],
            processed_ms: 1711191034023,
          },
          {
            leverage: 0.11,
            order_type: "LONG",
            order_uuid: "4bbaef8a-82c6-4bf9-a44a-86c2b53161d1",
            price: 3345.11,
            price_sources: [],
            processed_ms: 1711191518103,
          },
          {
            leverage: 0.11,
            order_type: "FLAT",
            order_uuid: "e0f3cde8-1e16-4259-b4ed-9860f82dc64a",
            price: 3353.51,
            price_sources: [],
            processed_ms: 1711191933109,
          },
        ],
        position_type: "FLAT",
        position_uuid: "0bd8cbec-a5c5-4f2b-aa2f-960b0bc4db1c",
        return_at_close: 0.9973845920461168,
        trade_pair: ["ETHUSD", "ETH/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 3358.7960000000003,
        close_ms: 1711194634600,
        current_return: 0.9995585130930582,
        initial_entry_price: 3358.65,
        is_closed_position: true,
        miner_hotkey: "5HYWkHt2KGBLvacHur1RJrB5onqkC5FfXbyMWKbP6UXTwVSg",
        net_leverage: 0.0,
        open_ms: 1711192384253,
        orders: [
          {
            leverage: 0.11,
            order_type: "LONG",
            order_uuid: "01c4b7eb-83e6-4073-9663-fe51b61a9456",
            price: 3358.65,
            price_sources: [],
            processed_ms: 1711192384252,
          },
          {
            leverage: 0.11,
            order_type: "LONG",
            order_uuid: "c0eebd9b-d16f-41b6-9c1d-12c65d5aac50",
            price: 3358.69,
            price_sources: [],
            processed_ms: 1711192826320,
          },
          {
            leverage: 0.11,
            order_type: "LONG",
            order_uuid: "72bac2af-6edc-4a1e-b568-50d257394dfd",
            price: 3359.68,
            price_sources: [],
            processed_ms: 1711193296658,
          },
          {
            leverage: 0.11,
            order_type: "LONG",
            order_uuid: "04a2b1da-467c-4683-a57a-c237c655420c",
            price: 3359.42,
            price_sources: [],
            processed_ms: 1711193733204,
          },
          {
            leverage: 0.11,
            order_type: "LONG",
            order_uuid: "46670784-2540-4310-98d7-b0acfdf0288c",
            price: 3357.54,
            price_sources: [],
            processed_ms: 1711194182479,
          },
          {
            leverage: 0.11,
            order_type: "FLAT",
            order_uuid: "10e10426-de93-4974-aa48-a25f6007891c",
            price: 3356.1,
            price_sources: [],
            processed_ms: 1711194634600,
          },
        ],
        position_type: "FLAT",
        position_uuid: "aa86c3a1-032b-4add-bee1-191affe66dcb",
        return_at_close: 0.9984589987286558,
        trade_pair: ["ETHUSD", "ETH/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 3365.0860000000002,
        close_ms: 1711197335364,
        current_return: 1.0004687481401244,
        initial_entry_price: 3360.44,
        is_closed_position: true,
        miner_hotkey: "5HYWkHt2KGBLvacHur1RJrB5onqkC5FfXbyMWKbP6UXTwVSg",
        net_leverage: 0.0,
        open_ms: 1711195080546,
        orders: [
          {
            leverage: 0.11,
            order_type: "LONG",
            order_uuid: "c0fc59fe-e5f1-43b9-b86a-730fc6f162e9",
            price: 3360.44,
            price_sources: [],
            processed_ms: 1711195080544,
          },
          {
            leverage: 0.11,
            order_type: "LONG",
            order_uuid: "1a38e72c-9ca1-4592-9c31-4794a566dc55",
            price: 3360.26,
            price_sources: [],
            processed_ms: 1711195535151,
          },
          {
            leverage: 0.11,
            order_type: "LONG",
            order_uuid: "d2617c2e-8099-4801-8f2d-38d2f83e443b",
            price: 3367.43,
            price_sources: [],
            processed_ms: 1711195982392,
          },
          {
            leverage: 0.11,
            order_type: "LONG",
            order_uuid: "b85ce8d9-33a1-4a39-98de-cdfea310a1d9",
            price: 3365.11,
            price_sources: [],
            processed_ms: 1711196470488,
          },
          {
            leverage: 0.11,
            order_type: "LONG",
            order_uuid: "e7fbced1-fc16-4c91-96f1-3fae6b05f4bc",
            price: 3372.19,
            price_sources: [],
            processed_ms: 1711196888371,
          },
          {
            leverage: 0.11,
            order_type: "FLAT",
            order_uuid: "806fd300-0b0f-439c-8b1b-a8f795f13bf5",
            price: 3367.95,
            price_sources: [],
            processed_ms: 1711197335364,
          },
        ],
        position_type: "FLAT",
        position_uuid: "fdde5804-baa7-4c4e-a9d5-6f80fb20f32a",
        return_at_close: 0.9993682325171703,
        trade_pair: ["ETHUSD", "ETH/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 3369.7419999999997,
        close_ms: 1711200053722,
        current_return: 0.9996030401144347,
        initial_entry_price: 3369.61,
        is_closed_position: true,
        miner_hotkey: "5HYWkHt2KGBLvacHur1RJrB5onqkC5FfXbyMWKbP6UXTwVSg",
        net_leverage: 0.0,
        open_ms: 1711197789253,
        orders: [
          {
            leverage: 0.11,
            order_type: "LONG",
            order_uuid: "48c89eb1-0476-47bc-a73f-4ea1544ef4c5",
            price: 3369.61,
            price_sources: [],
            processed_ms: 1711197789252,
          },
          {
            leverage: 0.11,
            order_type: "LONG",
            order_uuid: "1821f311-5975-4c28-a2d4-626c02f78155",
            price: 3374.99,
            price_sources: [],
            processed_ms: 1711198262910,
          },
          {
            leverage: 0.11,
            order_type: "LONG",
            order_uuid: "4aec6507-724a-4372-8433-4b909c418cd7",
            price: 3377.14,
            price_sources: [],
            processed_ms: 1711198685289,
          },
          {
            leverage: 0.11,
            order_type: "LONG",
            order_uuid: "13356bc3-f2a7-4179-b239-8647e84da51c",
            price: 3361.39,
            price_sources: [],
            processed_ms: 1711199135302,
          },
          {
            leverage: 0.11,
            order_type: "LONG",
            order_uuid: "4fe153b5-d51d-4fe7-8408-5853985a960c",
            price: 3365.58,
            price_sources: [],
            processed_ms: 1711199590832,
          },
          {
            leverage: 0.11,
            order_type: "FLAT",
            order_uuid: "7832aaf3-3217-4483-bce9-b8dc95f627fe",
            price: 3367.31,
            price_sources: [],
            processed_ms: 1711200053722,
          },
        ],
        position_type: "FLAT",
        position_uuid: "dbf2283a-bdc6-4e0e-ad50-2bd1146fc597",
        return_at_close: 0.9985034767703088,
        trade_pair: ["ETHUSD", "ETH/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 3355.2559999999994,
        close_ms: 1711202741371,
        current_return: 1.0015453501448897,
        initial_entry_price: 3361.18,
        is_closed_position: true,
        miner_hotkey: "5HYWkHt2KGBLvacHur1RJrB5onqkC5FfXbyMWKbP6UXTwVSg",
        net_leverage: 0.0,
        open_ms: 1711200492268,
        orders: [
          {
            leverage: 0.11,
            order_type: "LONG",
            order_uuid: "bf90d4ee-1038-40ff-be85-ae8e9a797354",
            price: 3361.18,
            price_sources: [],
            processed_ms: 1711200492266,
          },
          {
            leverage: 0.11,
            order_type: "LONG",
            order_uuid: "1d5b0119-9368-469d-b5fb-45cb56dfaea2",
            price: 3353.1,
            price_sources: [],
            processed_ms: 1711200935236,
          },
          {
            leverage: 0.11,
            order_type: "LONG",
            order_uuid: "32fffd3f-ba4f-4eb9-afbd-209b11c43f73",
            price: 3350.82,
            price_sources: [],
            processed_ms: 1711201388222,
          },
          {
            leverage: 0.11,
            order_type: "LONG",
            order_uuid: "de9b7b26-4e6d-4c75-99c1-d836e3d9873a",
            price: 3347.41,
            price_sources: [],
            processed_ms: 1711201833651,
          },
          {
            leverage: 0.11,
            order_type: "LONG",
            order_uuid: "84dc8612-4779-4c9a-beff-20bb4cdb5284",
            price: 3363.77,
            price_sources: [],
            processed_ms: 1711202285480,
          },
          {
            leverage: 0.11,
            order_type: "FLAT",
            order_uuid: "e5824c3b-4ecb-4cb2-b4f8-c88b04165471",
            price: 3364.7,
            price_sources: [],
            processed_ms: 1711202741371,
          },
        ],
        position_type: "FLAT",
        position_uuid: "785f2457-19c5-4f5b-931f-924b17288621",
        return_at_close: 1.0004436502597303,
        trade_pair: ["ETHUSD", "ETH/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 3381.2019999999993,
        close_ms: 1711205442199,
        current_return: 1.004804943259462,
        initial_entry_price: 3366.2,
        is_closed_position: true,
        miner_hotkey: "5HYWkHt2KGBLvacHur1RJrB5onqkC5FfXbyMWKbP6UXTwVSg",
        net_leverage: 0.0,
        open_ms: 1711203229479,
        orders: [
          {
            leverage: 0.11,
            order_type: "LONG",
            order_uuid: "9332a27d-370c-479f-befc-fdc2e7e839d4",
            price: 3366.2,
            price_sources: [],
            processed_ms: 1711203229477,
          },
          {
            leverage: 0.11,
            order_type: "LONG",
            order_uuid: "dd989a49-9257-4d28-afad-fdf19643cf74",
            price: 3358.3,
            price_sources: [],
            processed_ms: 1711203641102,
          },
          {
            leverage: 0.11,
            order_type: "LONG",
            order_uuid: "6f641154-b4c0-4ca2-903a-d38b1e56e766",
            price: 3366.48,
            price_sources: [],
            processed_ms: 1711204091999,
          },
          {
            leverage: 0.11,
            order_type: "LONG",
            order_uuid: "b237f349-ed25-463c-833b-78aa3d1441f4",
            price: 3409.53,
            price_sources: [],
            processed_ms: 1711204540315,
          },
          {
            leverage: 0.11,
            order_type: "LONG",
            order_uuid: "7d6bbe67-82c7-4177-862d-4703aae4fb15",
            price: 3405.5,
            price_sources: [],
            processed_ms: 1711204999697,
          },
          {
            leverage: 0.11,
            order_type: "FLAT",
            order_uuid: "e7ce382b-6ad8-4de5-a796-f37bb01701d9",
            price: 3410.61,
            price_sources: [],
            processed_ms: 1711205442199,
          },
        ],
        position_type: "FLAT",
        position_uuid: "3001b1d1-c92a-4797-b4d6-d117287a2786",
        return_at_close: 1.0036996578218764,
        trade_pair: ["ETHUSD", "ETH/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 3366.6575,
        close_ms: 1711268800099,
        current_return: 0.9951885956575773,
        initial_entry_price: 3419.75,
        is_closed_position: true,
        miner_hotkey: "5HYWkHt2KGBLvacHur1RJrB5onqkC5FfXbyMWKbP6UXTwVSg",
        net_leverage: 0.0,
        open_ms: 1711205893041,
        orders: [
          {
            leverage: 0.11,
            order_type: "LONG",
            order_uuid: "35646e6b-e850-4351-88fb-2596cce5820d",
            price: 3419.75,
            price_sources: [],
            processed_ms: 1711205893039,
          },
          {
            leverage: 0.11,
            order_type: "LONG",
            order_uuid: "015ccf22-83b3-40fc-9c4c-4053162a80ca",
            price: 3414.43,
            price_sources: [],
            processed_ms: 1711206343219,
          },
          {
            leverage: 0.11,
            order_type: "LONG",
            order_uuid: "d9841ec9-9148-4357-9d14-a574a0eaa24c",
            price: 3350.79,
            price_sources: [],
            processed_ms: 1711268667250,
          },
          {
            leverage: 0.11,
            order_type: "LONG",
            order_uuid: "575dd4f5-05b1-4253-ab19-0f2ec27cb5a7",
            price: 3350.79,
            price_sources: [],
            processed_ms: 1711268668033,
          },
          {
            leverage: 0.11,
            order_type: "LONG",
            order_uuid: "259638b0-583f-400b-aab6-44eddd592fb7",
            price: 3350.79,
            price_sources: [],
            processed_ms: 1711268686314,
          },
          {
            leverage: 0.11,
            order_type: "LONG",
            order_uuid: "c4591c06-48db-4f25-89c4-35e8d3cd2e28",
            price: 3350.79,
            price_sources: [],
            processed_ms: 1711268696766,
          },
          {
            leverage: 0.11,
            order_type: "LONG",
            order_uuid: "0beaffed-e578-49b4-83cf-7461067ae1b1",
            price: 3347.96,
            price_sources: [],
            processed_ms: 1711268795618,
          },
          {
            leverage: 0.11,
            order_type: "LONG",
            order_uuid: "41587016-91cf-47ff-bc4d-b3377e049732",
            price: 3347.96,
            price_sources: [],
            processed_ms: 1711268797442,
          },
          {
            leverage: 0.11,
            order_type: "FLAT",
            order_uuid: "4f173606-41d5-49f8-bb8c-4ab3e4bb8bd3",
            price: 3347.96,
            price_sources: [],
            processed_ms: 1711268800099,
          },
        ],
        position_type: "FLAT",
        position_uuid: "9edc6eab-6e36-4c58-a3a9-d2de3fbd4f09",
        return_at_close: 0.99343706372922,
        trade_pair: ["ETHUSD", "ETH/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 3347.96,
        close_ms: 1711268820927,
        current_return: 1.0,
        initial_entry_price: 3347.96,
        is_closed_position: true,
        miner_hotkey: "5HYWkHt2KGBLvacHur1RJrB5onqkC5FfXbyMWKbP6UXTwVSg",
        net_leverage: 0.0,
        open_ms: 1711268816519,
        orders: [
          {
            leverage: 0.11,
            order_type: "LONG",
            order_uuid: "34a62a35-a9be-4b57-a7e7-d05c4197a870",
            price: 3347.96,
            price_sources: [],
            processed_ms: 1711268816516,
          },
          {
            leverage: 0.11,
            order_type: "FLAT",
            order_uuid: "c8b20ac2-a299-409c-89bc-f881184fce98",
            price: 3347.96,
            price_sources: [],
            processed_ms: 1711268820927,
          },
        ],
        position_type: "FLAT",
        position_uuid: "3bd75e8b-abe6-4ca3-984a-6dd9ea371d4d",
        return_at_close: 0.99978,
        trade_pair: ["ETHUSD", "ETH/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 69447.18,
        close_ms: 1711873944132,
        current_return: 0.9409652054986243,
        initial_entry_price: 69447.18,
        is_closed_position: true,
        miner_hotkey: "5HYWkHt2KGBLvacHur1RJrB5onqkC5FfXbyMWKbP6UXTwVSg",
        net_leverage: -4.0,
        open_ms: 1711734723572,
        orders: [
          {
            leverage: -4.0,
            order_type: "SHORT",
            order_uuid: "5cbc8f84-8698-43dc-9456-85e5c73aad37",
            price: 69447.18,
            price_sources: [],
            processed_ms: 1711734723570,
          },
        ],
        position_type: "FLAT",
        position_uuid: "faa446f1-7ca2-4898-96fc-b7fd1de34399",
        return_at_close: 0.9334374838546353,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 3619.7699999999995,
        close_ms: 1711873944133,
        current_return: 0.9795290861021548,
        initial_entry_price: 3619.77,
        is_closed_position: true,
        miner_hotkey: "5HYWkHt2KGBLvacHur1RJrB5onqkC5FfXbyMWKbP6UXTwVSg",
        net_leverage: -10.0,
        open_ms: 1711867583999,
        orders: [
          {
            leverage: -10.0,
            order_type: "SHORT",
            order_uuid: "c3e3a263-5818-4c82-8b78-e5d30b508de1",
            price: 3619.77,
            price_sources: [],
            processed_ms: 1711867583997,
          },
        ],
        position_type: "FLAT",
        position_uuid: "c763eeb8-ced5-46c2-ba2b-a5d526e7745d",
        return_at_close: 0.9599385043801117,
        trade_pair: ["ETHUSD", "ETH/USD", 0.003, 0.001, 20],
      },
    ],
    thirty_day_returns: 1.0,
  },
  "5HafDhoVcrea4KTwrGAUyBCFuPVxsENxN3b4vbSwQybcd9sM": {
    all_time_returns: 1.0,
    n_positions: 0,
    percentage_profitable: 0.0,
    positions: [
      {
        average_entry_price: 64961.43,
        close_ms: 1711209461142,
        current_return: 1.0105262671519288,
        initial_entry_price: 64927.1,
        is_closed_position: true,
        miner_hotkey: "5HafDhoVcrea4KTwrGAUyBCFuPVxsENxN3b4vbSwQybcd9sM",
        net_leverage: 0.0,
        open_ms: 1711209067169,
        orders: [
          {
            leverage: 2.0,
            order_type: "LONG",
            order_uuid: "d0f4030d-dc70-43bd-a613-70ec3e9b175d",
            price: 64927.1,
            price_sources: [],
            processed_ms: 1711209067169,
          },
          {
            leverage: 2.0,
            order_type: "LONG",
            order_uuid: "01f3e9de-1dba-471c-b421-d2382c517d21",
            price: 64995.76,
            price_sources: [],
            processed_ms: 1711209243173,
          },
          {
            leverage: 2.0,
            order_type: "FLAT",
            order_uuid: "05e8f6e9-de5b-4091-8715-a6369e83bd67",
            price: 65132.29,
            price_sources: [],
            processed_ms: 1711209461142,
          },
        ],
        position_type: "FLAT",
        position_uuid: "396358c9-b983-4c11-a696-37d584a30a84",
        return_at_close: 1.0024420570147135,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 65144.04,
        close_ms: 1711212053694,
        current_return: 1.0107780622435045,
        initial_entry_price: 65088.88,
        is_closed_position: true,
        miner_hotkey: "5HafDhoVcrea4KTwrGAUyBCFuPVxsENxN3b4vbSwQybcd9sM",
        net_leverage: 0.0,
        open_ms: 1711209611532,
        orders: [
          {
            leverage: 0.5,
            order_type: "LONG",
            order_uuid: "de31cb81-3ded-48ee-95cc-09ba24476e98",
            price: 65088.88,
            price_sources: [],
            processed_ms: 1711209611531,
          },
          {
            leverage: 0.65,
            order_type: "LONG",
            order_uuid: "82ebe188-ddab-431f-acd3-4d8f374651fb",
            price: 65081.24,
            price_sources: [],
            processed_ms: 1711210421176,
          },
          {
            leverage: 0.8,
            order_type: "LONG",
            order_uuid: "40b46575-600e-4368-81fd-5ce1bbe6aaad",
            price: 65229.54,
            price_sources: [],
            processed_ms: 1711211228897,
          },
          {
            leverage: 0.95,
            order_type: "FLAT",
            order_uuid: "e58388b5-ddda-42e3-83a5-538bc0fa8056",
            price: 65503.8,
            price_sources: [],
            processed_ms: 1711212053694,
          },
        ],
        position_type: "FLAT",
        position_uuid: "ee217940-7918-438d-9f80-b96449099387",
        return_at_close: 1.0068360278007549,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 65612.87506666666,
        close_ms: 1711215221447,
        current_return: 1.0162501743121441,
        initial_entry_price: 65615.05,
        is_closed_position: true,
        miner_hotkey: "5HafDhoVcrea4KTwrGAUyBCFuPVxsENxN3b4vbSwQybcd9sM",
        net_leverage: 0.0,
        open_ms: 1711212819078,
        orders: [
          {
            leverage: 1.1,
            order_type: "LONG",
            order_uuid: "d915ff7a-49e3-4285-845a-b493cfa0324c",
            price: 65615.05,
            price_sources: [],
            processed_ms: 1711212819077,
          },
          {
            leverage: 1.25,
            order_type: "LONG",
            order_uuid: "428aa4a1-efb3-43f8-b73a-f0adcc108aff",
            price: 65664.85,
            price_sources: [],
            processed_ms: 1711213611817,
          },
          {
            leverage: 1.4,
            order_type: "LONG",
            order_uuid: "affa9078-4b39-44f7-8046-3d36d88ee2de",
            price: 65564.76,
            price_sources: [],
            processed_ms: 1711214411878,
          },
          {
            leverage: 1.55,
            order_type: "FLAT",
            order_uuid: "940b9995-ca5a-46d5-9b77-48a8b16f467e",
            price: 65897.21,
            price_sources: [],
            processed_ms: 1711215221447,
          },
        ],
        position_type: "FLAT",
        position_uuid: "d1397db8-a26a-49b4-ab67-ea65f6e0d11d",
        return_at_close: 1.0086282980048031,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 65634.54585585586,
        close_ms: 1711218436640,
        current_return: 0.9903361501198524,
        initial_entry_price: 65709.63,
        is_closed_position: true,
        miner_hotkey: "5HafDhoVcrea4KTwrGAUyBCFuPVxsENxN3b4vbSwQybcd9sM",
        net_leverage: 0.0,
        open_ms: 1711216020185,
        orders: [
          {
            leverage: 1.7,
            order_type: "LONG",
            order_uuid: "5bb377be-5078-4952-afd0-220299dc5530",
            price: 65709.63,
            price_sources: [],
            processed_ms: 1711216020184,
          },
          {
            leverage: 1.85,
            order_type: "LONG",
            order_uuid: "2ccb4501-0c8e-4eeb-b2d7-d499e78b4332",
            price: 65663.21,
            price_sources: [],
            processed_ms: 1711216814857,
          },
          {
            leverage: 2.0,
            order_type: "LONG",
            order_uuid: "feef1d8e-eecd-4623-a2e1-37a95262db58",
            price: 65544.21,
            price_sources: [],
            processed_ms: 1711217617060,
          },
          {
            leverage: 2.15,
            order_type: "FLAT",
            order_uuid: "bd1f8a20-7986-428a-96a3-668fb951106f",
            price: 65520.13,
            price_sources: [],
            processed_ms: 1711218436640,
          },
        ],
        position_type: "FLAT",
        position_uuid: "d533379c-5969-4f92-bc1c-85def35f9593",
        return_at_close: 0.979343418853522,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 65390.931428571435,
        close_ms: 1711221630720,
        current_return: 0.990980703448876,
        initial_entry_price: 65382.15,
        is_closed_position: true,
        miner_hotkey: "5HafDhoVcrea4KTwrGAUyBCFuPVxsENxN3b4vbSwQybcd9sM",
        net_leverage: 0.0,
        open_ms: 1711219214591,
        orders: [
          {
            leverage: 2.3,
            order_type: "LONG",
            order_uuid: "cdacda05-301e-4637-a1ca-4621fa8069e2",
            price: 65382.15,
            price_sources: [],
            processed_ms: 1711219214590,
          },
          {
            leverage: 2.45,
            order_type: "LONG",
            order_uuid: "34451607-2783-45a0-b78b-db8c712f8cb1",
            price: 65376.18,
            price_sources: [],
            processed_ms: 1711220032620,
          },
          {
            leverage: 2.6,
            order_type: "LONG",
            order_uuid: "a708c97c-cfc1-491a-909e-f1f496050312",
            price: 65412.6,
            price_sources: [],
            processed_ms: 1711220816345,
          },
          {
            leverage: 2.75,
            order_type: "FLAT",
            order_uuid: "2c9467c1-1605-4750-8d63-82d6f7e89a87",
            price: 65310.7,
            price_sources: [],
            processed_ms: 1711221630720,
          },
        ],
        position_type: "FLAT",
        position_uuid: "2baa045c-8e5b-4824-b1c6-aa0fedf7b5c6",
        return_at_close: 0.9764132871081775,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 64928.76639344264,
        close_ms: 1711224817611,
        current_return: 0.973931630304832,
        initial_entry_price: 65042.56,
        is_closed_position: true,
        miner_hotkey: "5HafDhoVcrea4KTwrGAUyBCFuPVxsENxN3b4vbSwQybcd9sM",
        net_leverage: 0.0,
        open_ms: 1711222423788,
        orders: [
          {
            leverage: 2.9,
            order_type: "LONG",
            order_uuid: "5738c5af-401a-486f-8eeb-279da566088b",
            price: 65042.56,
            price_sources: [],
            processed_ms: 1711222423787,
          },
          {
            leverage: 3.05,
            order_type: "LONG",
            order_uuid: "330a8ec6-cee2-49e2-b49e-9157adff8ea6",
            price: 64876.77,
            price_sources: [],
            processed_ms: 1711223238321,
          },
          {
            leverage: 3.2,
            order_type: "LONG",
            order_uuid: "d67aa5c7-05ec-431c-a42d-d3190c137153",
            price: 64875.2,
            price_sources: [],
            processed_ms: 1711224022181,
          },
          {
            leverage: 3.35,
            order_type: "FLAT",
            order_uuid: "eb2b5c85-79a7-4828-bc27-ed7154f15acb",
            price: 64743.46,
            price_sources: [],
            processed_ms: 1711224817611,
          },
        ],
        position_type: "FLAT",
        position_uuid: "69b3a776-b70e-447f-b4db-9c2777ac0ced",
        return_at_close: 0.9561086814702536,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 64976.53210045662,
        close_ms: 1711228017147,
        current_return: 0.9853640118751674,
        initial_entry_price: 64948.98,
        is_closed_position: true,
        miner_hotkey: "5HafDhoVcrea4KTwrGAUyBCFuPVxsENxN3b4vbSwQybcd9sM",
        net_leverage: 0.0,
        open_ms: 1711225622419,
        orders: [
          {
            leverage: 3.5,
            order_type: "LONG",
            order_uuid: "822c19ef-abea-41b8-b940-29b63645e70d",
            price: 64948.98,
            price_sources: [],
            processed_ms: 1711225622418,
          },
          {
            leverage: 3.65,
            order_type: "LONG",
            order_uuid: "ab509d84-c663-4775-b03e-0c2a82036c93",
            price: 64985.37,
            price_sources: [],
            processed_ms: 1711226441368,
          },
          {
            leverage: 3.8,
            order_type: "LONG",
            order_uuid: "1bdd0af4-57d8-465c-b98c-50c5b25e2ba5",
            price: 64993.42,
            price_sources: [],
            processed_ms: 1711227224073,
          },
          {
            leverage: 3.95,
            order_type: "FLAT",
            order_uuid: "cfcae2e2-eaa7-4286-8221-47e2eb68784c",
            price: 64889.72,
            price_sources: [],
            processed_ms: 1711228017147,
          },
        ],
        position_type: "FLAT",
        position_uuid: "5e427fc9-53b8-4a4a-b404-b821b0412a40",
        return_at_close: 0.9637845400151013,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 64947.2422745098,
        close_ms: 1711231220109,
        current_return: 0.998452827789399,
        initial_entry_price: 64956.57,
        is_closed_position: true,
        miner_hotkey: "5HafDhoVcrea4KTwrGAUyBCFuPVxsENxN3b4vbSwQybcd9sM",
        net_leverage: 0.0,
        open_ms: 1711228832162,
        orders: [
          {
            leverage: 4.1,
            order_type: "LONG",
            order_uuid: "4dbb91ea-2aaf-4942-8ba9-7a3a9282c300",
            price: 64956.57,
            price_sources: [],
            processed_ms: 1711228832161,
          },
          {
            leverage: 4.25,
            order_type: "LONG",
            order_uuid: "649327c7-ed50-44e1-8017-e22df77ba139",
            price: 64914.6,
            price_sources: [],
            processed_ms: 1711229623623,
          },
          {
            leverage: 4.4,
            order_type: "LONG",
            order_uuid: "addaa46f-d622-4314-b1ea-f529b98df63a",
            price: 64970.08,
            price_sources: [],
            processed_ms: 1711230437514,
          },
          {
            leverage: 4.55,
            order_type: "FLAT",
            order_uuid: "96dff1c4-788c-4e41-8ab4-3cd1a6d21082",
            price: 64939.36,
            price_sources: [],
            processed_ms: 1711231220109,
          },
        ],
        position_type: "FLAT",
        position_uuid: "8cd97de4-d868-43ca-94d8-0099f6f404df",
        return_at_close: 0.9729922806807694,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 64928.39814432989,
        close_ms: 1711234421132,
        current_return: 0.9877128166098802,
        initial_entry_price: 64960.86,
        is_closed_position: true,
        miner_hotkey: "5HafDhoVcrea4KTwrGAUyBCFuPVxsENxN3b4vbSwQybcd9sM",
        net_leverage: 0.0,
        open_ms: 1711232033884,
        orders: [
          {
            leverage: 4.7,
            order_type: "LONG",
            order_uuid: "b5481ffc-9e84-4f9f-b67d-8328a93543c3",
            price: 64960.86,
            price_sources: [],
            processed_ms: 1711232033883,
          },
          {
            leverage: 4.85,
            order_type: "LONG",
            order_uuid: "0401d0ce-cff6-43e0-969b-14029d0aefb6",
            price: 64830.66,
            price_sources: [],
            processed_ms: 1711232825632,
          },
          {
            leverage: 5.0,
            order_type: "LONG",
            order_uuid: "9f6fa39b-3995-412a-ad33-5fef34d8eb7a",
            price: 64992.69,
            price_sources: [],
            processed_ms: 1711233638155,
          },
          {
            leverage: 5.15,
            order_type: "FLAT",
            order_uuid: "14b03a20-c717-4630-a319-a3a30d733382",
            price: 64873.54,
            price_sources: [],
            processed_ms: 1711234421132,
          },
        ],
        position_type: "FLAT",
        position_uuid: "51e1a023-88fd-4f42-ae4d-60600f51ba92",
        return_at_close: 0.9589703736465327,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 64633.32825688072,
        close_ms: 1711237628888,
        current_return: 0.923913513924016,
        initial_entry_price: 64785.92,
        is_closed_position: true,
        miner_hotkey: "5HafDhoVcrea4KTwrGAUyBCFuPVxsENxN3b4vbSwQybcd9sM",
        net_leverage: 0.0,
        open_ms: 1711235240283,
        orders: [
          {
            leverage: 5.3,
            order_type: "LONG",
            order_uuid: "6377d308-ac3c-43f2-8e7b-0c5e5bdcfe39",
            price: 64785.92,
            price_sources: [],
            processed_ms: 1711235240281,
          },
          {
            leverage: 5.45,
            order_type: "LONG",
            order_uuid: "60b3ecd1-98b3-4181-b143-e688055475ae",
            price: 64743.86,
            price_sources: [],
            processed_ms: 1711236026821,
          },
          {
            leverage: 5.6,
            order_type: "LONG",
            order_uuid: "eb06f929-7429-4af7-a0ea-be9800ade165",
            price: 64381.34,
            price_sources: [],
            processed_ms: 1711236824590,
          },
          {
            leverage: 3.6499999999999986,
            order_type: "FLAT",
            order_uuid: "ee891de3-be69-4694-918c-429aa24a65c6",
            price: 64331.84,
            price_sources: [],
            processed_ms: 1711237628888,
          },
        ],
        position_type: "FLAT",
        position_uuid: "f0ff4574-1533-4aae-8a6e-a78640149f8b",
        return_at_close: 0.8937015420187007,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
    ],
    thirty_day_returns: 1.0,
  },
  "5Hbae5fSrykduTK1EBy5x8fweg8J5cF24iN55A8ftUtHCG7X": {
    all_time_returns: 1.0,
    n_positions: 0,
    percentage_profitable: 0.0,
    positions: [
      {
        average_entry_price: 63594.06,
        close_ms: 1711138663821,
        current_return: 1.0003632414725534,
        initial_entry_price: 63594.06,
        is_closed_position: true,
        miner_hotkey: "5Hbae5fSrykduTK1EBy5x8fweg8J5cF24iN55A8ftUtHCG7X",
        net_leverage: 0.0,
        open_ms: 1711138222172,
        orders: [
          {
            leverage: 10.0,
            order_type: "LONG",
            order_uuid: "58044dca-c377-4624-9102-d00959b9ec9e",
            price: 63594.06,
            price_sources: [],
            processed_ms: 1711138222172,
          },
          {
            leverage: 10.0,
            order_type: "FLAT",
            order_uuid: "0f7c1b55-4bb0-46d1-826f-5d8f517be056",
            price: 63596.37,
            price_sources: [],
            processed_ms: 1711138663821,
          },
        ],
        position_type: "FLAT",
        position_uuid: "91a55c19-966a-4fb6-9ea0-57811d942808",
        return_at_close: 0.9803559766431024,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 63645.490000000005,
        close_ms: 1711141731095,
        current_return: 1.0471156715110532,
        initial_entry_price: 63645.49,
        is_closed_position: true,
        miner_hotkey: "5Hbae5fSrykduTK1EBy5x8fweg8J5cF24iN55A8ftUtHCG7X",
        net_leverage: 0.0,
        open_ms: 1711138829994,
        orders: [
          {
            leverage: -10.0,
            order_type: "SHORT",
            order_uuid: "345f183c-3f3c-46bb-bc5e-ae8f959cfc6d",
            price: 63645.49,
            price_sources: [],
            processed_ms: 1711138829993,
          },
          {
            leverage: 12.0,
            order_type: "FLAT",
            order_uuid: "a0afb3bd-63b2-4c84-9c01-19a2354ab9a0",
            price: 63345.62,
            price_sources: [],
            processed_ms: 1711141731095,
          },
        ],
        position_type: "FLAT",
        position_uuid: "15e8cc5c-fe8b-453a-99b9-27d59b392cb5",
        return_at_close: 1.026173358080832,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 3342.454,
        close_ms: 1711177610719,
        current_return: 1.0004429845816953,
        initial_entry_price: 3342.78,
        is_closed_position: true,
        miner_hotkey: "5Hbae5fSrykduTK1EBy5x8fweg8J5cF24iN55A8ftUtHCG7X",
        net_leverage: 0.0,
        open_ms: 1711176089746,
        orders: [
          {
            leverage: 0.06,
            order_type: "LONG",
            order_uuid: "deb54bc7-4587-47c7-a2b6-e5421151388d",
            price: 3342.78,
            price_sources: [],
            processed_ms: 1711176089745,
          },
          {
            leverage: 0.06,
            order_type: "LONG",
            order_uuid: "0d3187b0-9cbc-47f2-a955-277ec34677e1",
            price: 3342.78,
            price_sources: [],
            processed_ms: 1711176392572,
          },
          {
            leverage: 0.06,
            order_type: "LONG",
            order_uuid: "d70deb58-7e8a-42e6-b948-045717a3caba",
            price: 3341.55,
            price_sources: [],
            processed_ms: 1711176724565,
          },
          {
            leverage: 0.06,
            order_type: "LONG",
            order_uuid: "cd8b1287-c460-468f-b2bd-0caf9c393ca8",
            price: 3342.58,
            price_sources: [],
            processed_ms: 1711176994776,
          },
          {
            leverage: 0.06,
            order_type: "LONG",
            order_uuid: "482acb9e-e294-46af-831a-3026013fafba",
            price: 3342.58,
            price_sources: [],
            processed_ms: 1711177297109,
          },
          {
            leverage: 0.06,
            order_type: "FLAT",
            order_uuid: "f17b6a0a-c4be-4713-ad43-fe27097b8ebd",
            price: 3347.39,
            price_sources: [],
            processed_ms: 1711177610719,
          },
        ],
        position_type: "FLAT",
        position_uuid: "ce1d91cb-6402-432f-9455-52f9edaf6778",
        return_at_close: 0.9998427187909463,
        trade_pair: ["ETHUSD", "ETH/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 3371.3039999999996,
        close_ms: 1711179405759,
        current_return: 0.9994495904371845,
        initial_entry_price: 3359.68,
        is_closed_position: true,
        miner_hotkey: "5Hbae5fSrykduTK1EBy5x8fweg8J5cF24iN55A8ftUtHCG7X",
        net_leverage: 0.0,
        open_ms: 1711177895748,
        orders: [
          {
            leverage: 0.06,
            order_type: "LONG",
            order_uuid: "e67d10fc-3fb7-43b3-9e8d-e9f328308449",
            price: 3359.68,
            price_sources: [],
            processed_ms: 1711177895747,
          },
          {
            leverage: 0.06,
            order_type: "LONG",
            order_uuid: "733e038c-f57f-4790-b574-9063499ad6ce",
            price: 3363.64,
            price_sources: [],
            processed_ms: 1711178198770,
          },
          {
            leverage: 0.06,
            order_type: "LONG",
            order_uuid: "236af2da-70d4-452e-9aa6-f067cc925d78",
            price: 3379.04,
            price_sources: [],
            processed_ms: 1711178553676,
          },
          {
            leverage: 0.06,
            order_type: "LONG",
            order_uuid: "77e3dda3-7c1b-4682-85fc-023a18cf552b",
            price: 3379.71,
            price_sources: [],
            processed_ms: 1711178791844,
          },
          {
            leverage: 0.06,
            order_type: "LONG",
            order_uuid: "bc32f0a4-08c8-4f67-a434-88ceef6a591c",
            price: 3374.45,
            price_sources: [],
            processed_ms: 1711179121961,
          },
          {
            leverage: 0.06,
            order_type: "FLAT",
            order_uuid: "1ca40cbe-57df-42a7-9e8a-850f3ea09c7e",
            price: 3365.14,
            price_sources: [],
            processed_ms: 1711179405759,
          },
        ],
        position_type: "FLAT",
        position_uuid: "ca081d5c-b773-45cc-9dc5-5eb71e8d0af3",
        return_at_close: 0.9988499206829221,
        trade_pair: ["ETHUSD", "ETH/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 3364.61,
        close_ms: 1711181208750,
        current_return: 1.000677172744391,
        initial_entry_price: 3366.94,
        is_closed_position: true,
        miner_hotkey: "5Hbae5fSrykduTK1EBy5x8fweg8J5cF24iN55A8ftUtHCG7X",
        net_leverage: 0.0,
        open_ms: 1711179702168,
        orders: [
          {
            leverage: 0.06,
            order_type: "LONG",
            order_uuid: "a9d9268f-7ddd-46f6-a422-0690c0e27f8b",
            price: 3366.94,
            price_sources: [],
            processed_ms: 1711179702167,
          },
          {
            leverage: 0.06,
            order_type: "LONG",
            order_uuid: "875e38f3-7321-4812-b5f0-0ecbcdde4e08",
            price: 3366.94,
            price_sources: [],
            processed_ms: 1711180013823,
          },
          {
            leverage: 0.06,
            order_type: "LONG",
            order_uuid: "36fa597b-0279-4407-9ec9-44dda35b0d44",
            price: 3362.89,
            price_sources: [],
            processed_ms: 1711180317037,
          },
          {
            leverage: 0.06,
            order_type: "LONG",
            order_uuid: "83fe8371-bbfe-49a6-b8fc-326164929fdb",
            price: 3360.9,
            price_sources: [],
            processed_ms: 1711180599840,
          },
          {
            leverage: 0.06,
            order_type: "LONG",
            order_uuid: "3db3b6fe-d015-48f2-81dc-ac3ae172ed79",
            price: 3365.38,
            price_sources: [],
            processed_ms: 1711180899230,
          },
          {
            leverage: 0.06,
            order_type: "FLAT",
            order_uuid: "b987d429-9f1f-40f2-a58a-1752732a1007",
            price: 3372.21,
            price_sources: [],
            processed_ms: 1711181208750,
          },
        ],
        position_type: "FLAT",
        position_uuid: "cfef2e9a-e5c1-42c5-abd8-21e0fd356935",
        return_at_close: 1.0000767664407444,
        trade_pair: ["ETHUSD", "ETH/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 3360.374,
        close_ms: 1711182998220,
        current_return: 0.9986515592515592,
        initial_entry_price: 3367.0,
        is_closed_position: true,
        miner_hotkey: "5Hbae5fSrykduTK1EBy5x8fweg8J5cF24iN55A8ftUtHCG7X",
        net_leverage: 0.0,
        open_ms: 1711181513668,
        orders: [
          {
            leverage: 0.06,
            order_type: "LONG",
            order_uuid: "cf66dfc1-3f7c-4944-ac9e-9e3f2cfc0072",
            price: 3367.0,
            price_sources: [],
            processed_ms: 1711181513667,
          },
          {
            leverage: 0.06,
            order_type: "LONG",
            order_uuid: "9d279a02-d816-4169-9584-c22176d174e3",
            price: 3361.1,
            price_sources: [],
            processed_ms: 1711181799675,
          },
          {
            leverage: 0.06,
            order_type: "LONG",
            order_uuid: "529bec16-8e2c-4f15-a524-23fc08e8b75c",
            price: 3361.1,
            price_sources: [],
            processed_ms: 1711182097734,
          },
          {
            leverage: 0.06,
            order_type: "LONG",
            order_uuid: "fc199cdc-84ce-44b8-bcc1-e813cc6502bb",
            price: 3357.47,
            price_sources: [],
            processed_ms: 1711182407946,
          },
          {
            leverage: 0.06,
            order_type: "LONG",
            order_uuid: "8c6c009e-9fa0-4512-91f0-e6111a3d8223",
            price: 3355.2,
            price_sources: [],
            processed_ms: 1711182703900,
          },
          {
            leverage: 0.06,
            order_type: "FLAT",
            order_uuid: "601798eb-ea54-4a8d-8f84-94bdc66f0da4",
            price: 3345.24,
            price_sources: [],
            processed_ms: 1711182998220,
          },
        ],
        position_type: "FLAT",
        position_uuid: "83897ce9-b638-4880-a3ae-9d6828b74f70",
        return_at_close: 0.9980523683160083,
        trade_pair: ["ETHUSD", "ETH/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 3347.494,
        close_ms: 1711184802342,
        current_return: 0.9999673741574645,
        initial_entry_price: 3347.04,
        is_closed_position: true,
        miner_hotkey: "5Hbae5fSrykduTK1EBy5x8fweg8J5cF24iN55A8ftUtHCG7X",
        net_leverage: 0.0,
        open_ms: 1711183317223,
        orders: [
          {
            leverage: 0.06,
            order_type: "LONG",
            order_uuid: "0859a5d3-971b-42c3-8644-725376846175",
            price: 3347.04,
            price_sources: [],
            processed_ms: 1711183317221,
          },
          {
            leverage: 0.06,
            order_type: "LONG",
            order_uuid: "4a3a2f5e-56ca-4b7b-b8aa-ae436c95b148",
            price: 3346.47,
            price_sources: [],
            processed_ms: 1711183605057,
          },
          {
            leverage: 0.06,
            order_type: "LONG",
            order_uuid: "591dd17d-403c-437a-8d2f-aa01c32af69b",
            price: 3348.24,
            price_sources: [],
            processed_ms: 1711183904128,
          },
          {
            leverage: 0.06,
            order_type: "LONG",
            order_uuid: "a709a59b-e2e5-4aa0-829f-41312344aff4",
            price: 3345.3,
            price_sources: [],
            processed_ms: 1711184204777,
          },
          {
            leverage: 0.06,
            order_type: "LONG",
            order_uuid: "93fa9b92-9243-4ee9-8561-93c4a10dcf0f",
            price: 3350.42,
            price_sources: [],
            processed_ms: 1711184507385,
          },
          {
            leverage: 0.06,
            order_type: "FLAT",
            order_uuid: "c7106127-847f-4b86-91b0-57f167c19813",
            price: 3347.13,
            price_sources: [],
            processed_ms: 1711184802342,
          },
        ],
        position_type: "FLAT",
        position_uuid: "53610ab7-21a7-4a7c-8370-b21ccaf897ef",
        return_at_close: 0.99936739373297,
        trade_pair: ["ETHUSD", "ETH/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 3336.208,
        close_ms: 1711186607999,
        current_return: 1.0002971829718297,
        initial_entry_price: 3333.3,
        is_closed_position: true,
        miner_hotkey: "5Hbae5fSrykduTK1EBy5x8fweg8J5cF24iN55A8ftUtHCG7X",
        net_leverage: 0.0,
        open_ms: 1711185105078,
        orders: [
          {
            leverage: 0.06,
            order_type: "LONG",
            order_uuid: "2dcf629e-c503-45ce-8a5f-4ca0282987dc",
            price: 3333.3,
            price_sources: [],
            processed_ms: 1711185105077,
          },
          {
            leverage: 0.06,
            order_type: "LONG",
            order_uuid: "d8ab622c-e51b-4186-abdd-b4db4e0861fd",
            price: 3334.8,
            price_sources: [],
            processed_ms: 1711185401942,
          },
          {
            leverage: 0.06,
            order_type: "LONG",
            order_uuid: "2c69e24a-a181-43ab-931a-550cba6acf13",
            price: 3333.72,
            price_sources: [],
            processed_ms: 1711185699093,
          },
          {
            leverage: 0.06,
            order_type: "LONG",
            order_uuid: "c4e8ebfd-f609-410d-bb24-e0e52f7736eb",
            price: 3340.41,
            price_sources: [],
            processed_ms: 1711186001959,
          },
          {
            leverage: 0.06,
            order_type: "LONG",
            order_uuid: "719dba5f-6be4-4d67-b7d2-d2feb33856be",
            price: 3338.81,
            price_sources: [],
            processed_ms: 1711186306034,
          },
          {
            leverage: 0.06,
            order_type: "FLAT",
            order_uuid: "8eec8e31-111c-478a-a2ba-ad1a7975dfdb",
            price: 3339.51,
            price_sources: [],
            processed_ms: 1711186607999,
          },
        ],
        position_type: "FLAT",
        position_uuid: "c20c9ad5-d2e1-4b17-b6df-5fb21daf0934",
        return_at_close: 0.9996970046620466,
        trade_pair: ["ETHUSD", "ETH/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 3344.158,
        close_ms: 1711188403975,
        current_return: 1.0015175953737838,
        initial_entry_price: 3339.23,
        is_closed_position: true,
        miner_hotkey: "5Hbae5fSrykduTK1EBy5x8fweg8J5cF24iN55A8ftUtHCG7X",
        net_leverage: 0.0,
        open_ms: 1711186904962,
        orders: [
          {
            leverage: 0.06,
            order_type: "LONG",
            order_uuid: "3b7a24b4-82c9-424a-9609-5cbf74ac82cb",
            price: 3339.23,
            price_sources: [],
            processed_ms: 1711186904500,
          },
          {
            leverage: 0.06,
            order_type: "LONG",
            order_uuid: "c2c36b0e-c899-48df-a86f-93534a863435",
            price: 3344.35,
            price_sources: [],
            processed_ms: 1711187202785,
          },
          {
            leverage: 0.06,
            order_type: "LONG",
            order_uuid: "13f5392b-c6f0-4f24-a704-11436af31fe1",
            price: 3337.95,
            price_sources: [],
            processed_ms: 1711187500965,
          },
          {
            leverage: 0.06,
            order_type: "LONG",
            order_uuid: "19f293a7-d01f-4714-9bb0-1e6eec7c0ea7",
            price: 3345.67,
            price_sources: [],
            processed_ms: 1711187802050,
          },
          {
            leverage: 0.06,
            order_type: "LONG",
            order_uuid: "44069a56-1440-4c04-a33a-067a39a50eb6",
            price: 3353.59,
            price_sources: [],
            processed_ms: 1711188103023,
          },
          {
            leverage: 0.06,
            order_type: "FLAT",
            order_uuid: "19ab126f-e70b-4bac-90bb-949b89c95e29",
            price: 3361.05,
            price_sources: [],
            processed_ms: 1711188403975,
          },
        ],
        position_type: "FLAT",
        position_uuid: "3ec79fe9-eae2-4fdb-ab39-1ccf32adca36",
        return_at_close: 1.0009166848165596,
        trade_pair: ["ETHUSD", "ETH/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 3361.372,
        close_ms: 1711190206906,
        current_return: 1.0006434335499537,
        initial_entry_price: 3360.72,
        is_closed_position: true,
        miner_hotkey: "5Hbae5fSrykduTK1EBy5x8fweg8J5cF24iN55A8ftUtHCG7X",
        net_leverage: 0.0,
        open_ms: 1711188705333,
        orders: [
          {
            leverage: 0.06,
            order_type: "LONG",
            order_uuid: "f03507e6-c86e-4e61-9e9c-8df946a020b0",
            price: 3360.72,
            price_sources: [],
            processed_ms: 1711188705332,
          },
          {
            leverage: 0.06,
            order_type: "LONG",
            order_uuid: "3845286a-3ca3-4460-9ed7-5a122a4ed11c",
            price: 3355.03,
            price_sources: [],
            processed_ms: 1711189045421,
          },
          {
            leverage: 0.06,
            order_type: "LONG",
            order_uuid: "4dfa39b9-9443-4d1a-a232-285ffe9ebadf",
            price: 3359.38,
            price_sources: [],
            processed_ms: 1711189302007,
          },
          {
            leverage: 0.06,
            order_type: "LONG",
            order_uuid: "3baffb22-8ea8-46e9-8120-f0c1f67ccfad",
            price: 3363.64,
            price_sources: [],
            processed_ms: 1711189625931,
          },
          {
            leverage: 0.06,
            order_type: "LONG",
            order_uuid: "ef47ce1a-44d5-482e-9ad8-e39b56f5dc39",
            price: 3368.09,
            price_sources: [],
            processed_ms: 1711189905997,
          },
          {
            leverage: 0.06,
            order_type: "FLAT",
            order_uuid: "144230ce-2121-475c-bfc1-38c1a46dbdbf",
            price: 3368.58,
            price_sources: [],
            processed_ms: 1711190206906,
          },
        ],
        position_type: "FLAT",
        position_uuid: "8cc132d1-09f9-4ddb-989c-118747e45f21",
        return_at_close: 1.0000430474898236,
        trade_pair: ["ETHUSD", "ETH/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 3357.638,
        close_ms: 1711192010159,
        current_return: 0.9988434460756064,
        initial_entry_price: 3366.38,
        is_closed_position: true,
        miner_hotkey: "5Hbae5fSrykduTK1EBy5x8fweg8J5cF24iN55A8ftUtHCG7X",
        net_leverage: 0.0,
        open_ms: 1711190506982,
        orders: [
          {
            leverage: 0.06,
            order_type: "LONG",
            order_uuid: "a060a1a6-a5e1-4d43-8501-4abefdda1606",
            price: 3366.38,
            price_sources: [],
            processed_ms: 1711190506981,
          },
          {
            leverage: 0.06,
            order_type: "LONG",
            order_uuid: "236c5d7b-4068-4124-8f82-307fa2f06907",
            price: 3363.8,
            price_sources: [],
            processed_ms: 1711190817649,
          },
          {
            leverage: 0.06,
            order_type: "LONG",
            order_uuid: "c92464c8-78e2-4b0e-b078-c49a43e3f03d",
            price: 3364.07,
            price_sources: [],
            processed_ms: 1711191106753,
          },
          {
            leverage: 0.06,
            order_type: "LONG",
            order_uuid: "b8f4f060-771d-43ff-b40a-dc9f091880d1",
            price: 3345.11,
            price_sources: [],
            processed_ms: 1711191406452,
          },
          {
            leverage: 0.06,
            order_type: "LONG",
            order_uuid: "becef6a3-4eae-470c-bba4-a9eab60b44c8",
            price: 3348.83,
            price_sources: [],
            processed_ms: 1711191704983,
          },
          {
            leverage: 0.06,
            order_type: "FLAT",
            order_uuid: "f318b384-60b5-412e-9d60-ead90a1fcb0d",
            price: 3344.66,
            price_sources: [],
            processed_ms: 1711192010159,
          },
        ],
        position_type: "FLAT",
        position_uuid: "ac67a3fb-ab21-47b1-a7d9-700e1c163017",
        return_at_close: 0.998244140007961,
        trade_pair: ["ETHUSD", "ETH/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 3358.3959999999997,
        close_ms: 1711193813224,
        current_return: 1.0004687807290953,
        initial_entry_price: 3355.94,
        is_closed_position: true,
        miner_hotkey: "5Hbae5fSrykduTK1EBy5x8fweg8J5cF24iN55A8ftUtHCG7X",
        net_leverage: 0.0,
        open_ms: 1711192307127,
        orders: [
          {
            leverage: 0.06,
            order_type: "LONG",
            order_uuid: "29445816-c40d-4dba-ac02-caaca70a4eb5",
            price: 3355.94,
            price_sources: [],
            processed_ms: 1711192307125,
          },
          {
            leverage: 0.06,
            order_type: "LONG",
            order_uuid: "16e419f8-da19-4fee-9324-644bfcfaa488",
            price: 3358.69,
            price_sources: [],
            processed_ms: 1711192614882,
          },
          {
            leverage: 0.06,
            order_type: "LONG",
            order_uuid: "14a2c51f-2414-48ae-8a08-770110f66ce1",
            price: 3357.99,
            price_sources: [],
            processed_ms: 1711192949177,
          },
          {
            leverage: 0.06,
            order_type: "LONG",
            order_uuid: "7dcf6645-d51f-4c79-8cc9-4db9583b0e41",
            price: 3359.68,
            price_sources: [],
            processed_ms: 1711193206282,
          },
          {
            leverage: 0.06,
            order_type: "LONG",
            order_uuid: "65e59634-0194-41e6-b9bc-7e62ba0f9cb7",
            price: 3359.68,
            price_sources: [],
            processed_ms: 1711193513549,
          },
          {
            leverage: 0.06,
            order_type: "FLAT",
            order_uuid: "d121e489-de01-4003-9822-b9c28297b4ee",
            price: 3363.64,
            price_sources: [],
            processed_ms: 1711193813224,
          },
        ],
        position_type: "FLAT",
        position_uuid: "405bb08b-349c-4e10-8258-10a7c7fb547b",
        return_at_close: 0.9998684994606578,
        trade_pair: ["ETHUSD", "ETH/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 3359.4199999999996,
        close_ms: 1711195617211,
        current_return: 1.0006022266302115,
        initial_entry_price: 3357.54,
        is_closed_position: true,
        miner_hotkey: "5Hbae5fSrykduTK1EBy5x8fweg8J5cF24iN55A8ftUtHCG7X",
        net_leverage: 0.0,
        open_ms: 1711194112212,
        orders: [
          {
            leverage: 0.06,
            order_type: "LONG",
            order_uuid: "84c36e9e-b165-494e-ade8-d1453cce8b1c",
            price: 3357.54,
            price_sources: [],
            processed_ms: 1711194112210,
          },
          {
            leverage: 0.06,
            order_type: "LONG",
            order_uuid: "1e25e2a7-592d-4617-b273-0219295ef9da",
            price: 3357.54,
            price_sources: [],
            processed_ms: 1711194413353,
          },
          {
            leverage: 0.06,
            order_type: "LONG",
            order_uuid: "d2eb675c-66ff-42fc-a95b-57ca8601847d",
            price: 3358.59,
            price_sources: [],
            processed_ms: 1711194712184,
          },
          {
            leverage: 0.06,
            order_type: "LONG",
            order_uuid: "cf3fa785-3fa7-4990-8643-822b06936e10",
            price: 3359.41,
            price_sources: [],
            processed_ms: 1711195060357,
          },
          {
            leverage: 0.06,
            order_type: "LONG",
            order_uuid: "b8dcd7e8-fca8-4f02-9f0e-54f88550ce2d",
            price: 3364.02,
            price_sources: [],
            processed_ms: 1711195317194,
          },
          {
            leverage: 0.06,
            order_type: "FLAT",
            order_uuid: "4bece7aa-1db1-41ec-930f-61a8a7121a07",
            price: 3366.16,
            price_sources: [],
            processed_ms: 1711195617211,
          },
        ],
        position_type: "FLAT",
        position_uuid: "192f8e75-c79a-413c-b21f-189d130e635f",
        return_at_close: 1.0000018652942333,
        trade_pair: ["ETHUSD", "ETH/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 3369.106,
        close_ms: 1711197414615,
        current_return: 0.9997411612412859,
        initial_entry_price: 3368.12,
        is_closed_position: true,
        miner_hotkey: "5Hbae5fSrykduTK1EBy5x8fweg8J5cF24iN55A8ftUtHCG7X",
        net_leverage: 0.0,
        open_ms: 1711195918104,
        orders: [
          {
            leverage: 0.06,
            order_type: "LONG",
            order_uuid: "48496c1c-52f4-41ce-9643-dbcf0a314f70",
            price: 3368.12,
            price_sources: [],
            processed_ms: 1711195918102,
          },
          {
            leverage: 0.06,
            order_type: "LONG",
            order_uuid: "6782a986-9756-4d70-98aa-a0fd07e9012d",
            price: 3367.55,
            price_sources: [],
            processed_ms: 1711196214241,
          },
          {
            leverage: 0.06,
            order_type: "LONG",
            order_uuid: "3d2eb9ac-53d6-40e0-b120-2cf654df265b",
            price: 3365.48,
            price_sources: [],
            processed_ms: 1711196517263,
          },
          {
            leverage: 0.06,
            order_type: "LONG",
            order_uuid: "b57a9bc6-3117-47a6-a6f1-1c2c74424d6b",
            price: 3372.19,
            price_sources: [],
            processed_ms: 1711196828198,
          },
          {
            leverage: 0.06,
            order_type: "LONG",
            order_uuid: "3d0eaff3-69d2-49fb-8d4f-e9d0986d5d7b",
            price: 3372.19,
            price_sources: [],
            processed_ms: 1711197115459,
          },
          {
            leverage: 0.06,
            order_type: "FLAT",
            order_uuid: "dd8341d4-25ae-4fd3-a36a-83753962aec5",
            price: 3366.2,
            price_sources: [],
            processed_ms: 1711197414615,
          },
        ],
        position_type: "FLAT",
        position_uuid: "d49b2c51-f6a5-4f63-9419-adf6ab2cefba",
        return_at_close: 0.9991413165445411,
        trade_pair: ["ETHUSD", "ETH/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 3372.296,
        close_ms: 1711199212522,
        current_return: 0.9994501440819561,
        initial_entry_price: 3369.61,
        is_closed_position: true,
        miner_hotkey: "5Hbae5fSrykduTK1EBy5x8fweg8J5cF24iN55A8ftUtHCG7X",
        net_leverage: 0.0,
        open_ms: 1711197710610,
        orders: [
          {
            leverage: 0.06,
            order_type: "LONG",
            order_uuid: "354851fc-b080-4a2b-8a9b-62a56ad4edde",
            price: 3369.61,
            price_sources: [],
            processed_ms: 1711197710608,
          },
          {
            leverage: 0.06,
            order_type: "LONG",
            order_uuid: "3696e49e-d972-428e-b216-ee71986c26ac",
            price: 3367.61,
            price_sources: [],
            processed_ms: 1711198019176,
          },
          {
            leverage: 0.06,
            order_type: "LONG",
            order_uuid: "6bce5949-e007-4147-b310-2d2bda17c0a6",
            price: 3377.58,
            price_sources: [],
            processed_ms: 1711198319147,
          },
          {
            leverage: 0.06,
            order_type: "LONG",
            order_uuid: "3acf76f4-aecf-4b61-a896-1faa9e860728",
            price: 3377.14,
            price_sources: [],
            processed_ms: 1711198623686,
          },
          {
            leverage: 0.06,
            order_type: "LONG",
            order_uuid: "a2cd6478-3503-4413-8771-93831815d54e",
            price: 3369.54,
            price_sources: [],
            processed_ms: 1711198911376,
          },
          {
            leverage: 0.06,
            order_type: "FLAT",
            order_uuid: "bf32338d-b581-40a5-981a-2e487aaa39f4",
            price: 3366.12,
            price_sources: [],
            processed_ms: 1711199212522,
          },
        ],
        position_type: "FLAT",
        position_uuid: "1923bb1f-5848-4936-8afd-dedb6f809e77",
        return_at_close: 0.9988504739955069,
        trade_pair: ["ETHUSD", "ETH/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 3362.992,
        close_ms: 1711201019276,
        current_return: 0.9989203643948442,
        initial_entry_price: 3365.58,
        is_closed_position: true,
        miner_hotkey: "5Hbae5fSrykduTK1EBy5x8fweg8J5cF24iN55A8ftUtHCG7X",
        net_leverage: 0.0,
        open_ms: 1711199518278,
        orders: [
          {
            leverage: 0.06,
            order_type: "LONG",
            order_uuid: "d18b1ed1-66e6-427a-9f82-12a67b5f019f",
            price: 3365.58,
            price_sources: [],
            processed_ms: 1711199518276,
          },
          {
            leverage: 0.06,
            order_type: "LONG",
            order_uuid: "da6bf4cd-e11b-4a2c-be64-294b474f6172",
            price: 3363.11,
            price_sources: [],
            processed_ms: 1711199819012,
          },
          {
            leverage: 0.06,
            order_type: "LONG",
            order_uuid: "d586d31e-6868-4f14-a5a4-df8b7ea4dacd",
            price: 3369.78,
            price_sources: [],
            processed_ms: 1711200112259,
          },
          {
            leverage: 0.06,
            order_type: "LONG",
            order_uuid: "7fab0628-8883-4c7f-b278-59e9a55dbae8",
            price: 3361.18,
            price_sources: [],
            processed_ms: 1711200415820,
          },
          {
            leverage: 0.06,
            order_type: "LONG",
            order_uuid: "ed22ad41-0eb2-4abc-b41a-32b4842fe452",
            price: 3355.31,
            price_sources: [],
            processed_ms: 1711200750468,
          },
          {
            leverage: 0.06,
            order_type: "FLAT",
            order_uuid: "eddc4373-9a6d-453e-a3f2-bfa2e5ac03b9",
            price: 3350.88,
            price_sources: [],
            processed_ms: 1711201019276,
          },
        ],
        position_type: "FLAT",
        position_uuid: "0f490023-40f6-42bf-abfa-628d8f3f7780",
        return_at_close: 0.9983210121762073,
        trade_pair: ["ETHUSD", "ETH/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 3358.2839999999997,
        close_ms: 1711202871234,
        current_return: 1.0010274499973142,
        initial_entry_price: 3350.82,
        is_closed_position: true,
        miner_hotkey: "5Hbae5fSrykduTK1EBy5x8fweg8J5cF24iN55A8ftUtHCG7X",
        net_leverage: 0.0,
        open_ms: 1711201318155,
        orders: [
          {
            leverage: 0.06,
            order_type: "LONG",
            order_uuid: "3bca27ba-5984-48fc-8eb3-a3baf0503192",
            price: 3350.82,
            price_sources: [],
            processed_ms: 1711201318153,
          },
          {
            leverage: 0.06,
            order_type: "LONG",
            order_uuid: "21475c79-ca58-41db-b8df-c0392bc91552",
            price: 3346.26,
            price_sources: [],
            processed_ms: 1711201622378,
          },
          {
            leverage: 0.06,
            order_type: "LONG",
            order_uuid: "b1baee3d-545c-45e9-a5f4-27f53cb836aa",
            price: 3366.91,
            price_sources: [],
            processed_ms: 1711201923438,
          },
          {
            leverage: 0.06,
            order_type: "LONG",
            order_uuid: "d324f5a8-0828-4f82-8edd-c4d772f6295d",
            price: 3363.77,
            price_sources: [],
            processed_ms: 1711202221162,
          },
          {
            leverage: 0.06,
            order_type: "LONG",
            order_uuid: "b673d532-0ec5-4a4f-974a-dec94b7d6ec7",
            price: 3363.66,
            price_sources: [],
            processed_ms: 1711202523057,
          },
          {
            leverage: 0.06,
            order_type: "FLAT",
            order_uuid: "b90b19cf-15ea-4c40-a944-d4862e9087b3",
            price: 3369.76,
            price_sources: [],
            processed_ms: 1711202871234,
          },
        ],
        position_type: "FLAT",
        position_uuid: "88488816-2e73-4e89-84f1-cd89289c80de",
        return_at_close: 1.0004268335273157,
        trade_pair: ["ETHUSD", "ETH/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 3363.3059999999996,
        close_ms: 1711204657083,
        current_return: 1.0043842314776306,
        initial_entry_price: 3366.2,
        is_closed_position: true,
        miner_hotkey: "5Hbae5fSrykduTK1EBy5x8fweg8J5cF24iN55A8ftUtHCG7X",
        net_leverage: 0.0,
        open_ms: 1711203119361,
        orders: [
          {
            leverage: 0.06,
            order_type: "LONG",
            order_uuid: "e07c45a0-9c94-4b3d-b3b7-a9eb94bf6f17",
            price: 3366.2,
            price_sources: [],
            processed_ms: 1711203119359,
          },
          {
            leverage: 0.06,
            order_type: "LONG",
            order_uuid: "8a344d9a-fa40-43f2-8019-bbd742a663d1",
            price: 3359.07,
            price_sources: [],
            processed_ms: 1711203416288,
          },
          {
            leverage: 0.06,
            order_type: "LONG",
            order_uuid: "5e9eec6b-c1bf-476d-9dcf-18df9434ce93",
            price: 3358.3,
            price_sources: [],
            processed_ms: 1711203725432,
          },
          {
            leverage: 0.06,
            order_type: "LONG",
            order_uuid: "1ea9281f-0861-4efb-a2e8-a4214f37e9fb",
            price: 3366.48,
            price_sources: [],
            processed_ms: 1711204018213,
          },
          {
            leverage: 0.06,
            order_type: "LONG",
            order_uuid: "ef6601c2-5726-4848-a324-8828a47b7319",
            price: 3366.48,
            price_sources: [],
            processed_ms: 1711204319093,
          },
          {
            leverage: 0.06,
            order_type: "FLAT",
            order_uuid: "0e237b2e-577a-4537-9add-2293033cc1f0",
            price: 3412.5,
            price_sources: [],
            processed_ms: 1711204657083,
          },
        ],
        position_type: "FLAT",
        position_uuid: "fd6d81d6-9a5b-4c81-9fd0-cd0a699d69f1",
        return_at_close: 1.003781600938744,
        trade_pair: ["ETHUSD", "ETH/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 3410.374,
        close_ms: 1711206420527,
        current_return: 1.0008474337281443,
        initial_entry_price: 3404.16,
        is_closed_position: true,
        miner_hotkey: "5Hbae5fSrykduTK1EBy5x8fweg8J5cF24iN55A8ftUtHCG7X",
        net_leverage: 0.0,
        open_ms: 1711204924216,
        orders: [
          {
            leverage: 0.06,
            order_type: "LONG",
            order_uuid: "9c62488e-edbc-4e64-859e-4dc1fd56955d",
            price: 3404.16,
            price_sources: [],
            processed_ms: 1711204924214,
          },
          {
            leverage: 0.06,
            order_type: "LONG",
            order_uuid: "1705727e-8a68-4d5b-9691-1504ec2b5092",
            price: 3404.14,
            price_sources: [],
            processed_ms: 1711205222464,
          },
          {
            leverage: 0.06,
            order_type: "LONG",
            order_uuid: "3ff48ca3-123a-4cb5-bc88-a4cbaa1b7a1c",
            price: 3412.35,
            price_sources: [],
            processed_ms: 1711205524412,
          },
          {
            leverage: 0.06,
            order_type: "LONG",
            order_uuid: "9d88324d-59cc-4008-a4fd-2b253a9241d4",
            price: 3414.88,
            price_sources: [],
            processed_ms: 1711205826488,
          },
          {
            leverage: 0.06,
            order_type: "LONG",
            order_uuid: "478edf3b-12bc-45f5-9dee-0c025fae37fa",
            price: 3416.34,
            price_sources: [],
            processed_ms: 1711206122268,
          },
          {
            leverage: 0.06,
            order_type: "FLAT",
            order_uuid: "c047d842-a4a2-46e2-9ae6-a0d5bc1c1630",
            price: 3419.99,
            price_sources: [],
            processed_ms: 1711206420527,
          },
        ],
        position_type: "FLAT",
        position_uuid: "e80f6379-c9d7-44a2-b61e-8408647263d0",
        return_at_close: 1.0002469252679074,
        trade_pair: ["ETHUSD", "ETH/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 3399.426,
        close_ms: 1711208228350,
        current_return: 0.9995834896810507,
        initial_entry_price: 3411.2,
        is_closed_position: true,
        miner_hotkey: "5Hbae5fSrykduTK1EBy5x8fweg8J5cF24iN55A8ftUtHCG7X",
        net_leverage: 0.0,
        open_ms: 1711206727593,
        orders: [
          {
            leverage: 0.06,
            order_type: "LONG",
            order_uuid: "85dc5a44-c605-4c55-8568-da0105381678",
            price: 3411.2,
            price_sources: [],
            processed_ms: 1711206727591,
          },
          {
            leverage: 0.06,
            order_type: "LONG",
            order_uuid: "2c0f560e-026a-463d-a559-9649ce4cef77",
            price: 3409.08,
            price_sources: [],
            processed_ms: 1711207030638,
          },
          {
            leverage: 0.06,
            order_type: "LONG",
            order_uuid: "12f6b6be-9619-4ca9-b021-1dfec6927484",
            price: 3404.61,
            price_sources: [],
            processed_ms: 1711207328425,
          },
          {
            leverage: 0.06,
            order_type: "LONG",
            order_uuid: "5655068a-7bdf-4435-8e5a-dc12073b0a9a",
            price: 3384.09,
            price_sources: [],
            processed_ms: 1711207620170,
          },
          {
            leverage: 0.06,
            order_type: "LONG",
            order_uuid: "37c2ccdd-291e-4422-9d28-174373f36c52",
            price: 3388.15,
            price_sources: [],
            processed_ms: 1711207923637,
          },
          {
            leverage: 0.06,
            order_type: "FLAT",
            order_uuid: "f831faa9-fa14-4b26-a6bc-7d14b4b395c9",
            price: 3394.69,
            price_sources: [],
            processed_ms: 1711208228350,
          },
        ],
        position_type: "FLAT",
        position_uuid: "249bc587-3984-49c3-b632-b8d5948606ed",
        return_at_close: 0.9989837395872421,
        trade_pair: ["ETHUSD", "ETH/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 3397.498,
        close_ms: 1711210022323,
        current_return: 0.999431680203704,
        initial_entry_price: 3393.16,
        is_closed_position: true,
        miner_hotkey: "5Hbae5fSrykduTK1EBy5x8fweg8J5cF24iN55A8ftUtHCG7X",
        net_leverage: 0.0,
        open_ms: 1711208525165,
        orders: [
          {
            leverage: 0.06,
            order_type: "LONG",
            order_uuid: "fb2ae50e-2221-4345-a43b-5c33644d75eb",
            price: 3393.16,
            price_sources: [],
            processed_ms: 1711208525163,
          },
          {
            leverage: 0.06,
            order_type: "LONG",
            order_uuid: "bf3c71a4-ed9f-48e9-bb3e-68bdabbef176",
            price: 3389.01,
            price_sources: [],
            processed_ms: 1711208839420,
          },
          {
            leverage: 0.06,
            order_type: "LONG",
            order_uuid: "dc10d3bd-c078-48c2-91cf-ba2eaf9e9e50",
            price: 3393.14,
            price_sources: [],
            processed_ms: 1711209128423,
          },
          {
            leverage: 0.06,
            order_type: "LONG",
            order_uuid: "c208d63b-4cdf-44b6-9f7d-499c4ca4a069",
            price: 3406.09,
            price_sources: [],
            processed_ms: 1711209425428,
          },
          {
            leverage: 0.06,
            order_type: "LONG",
            order_uuid: "d601b6f9-1371-4c24-b808-1102e32ffc24",
            price: 3406.09,
            price_sources: [],
            processed_ms: 1711209723380,
          },
          {
            leverage: 0.06,
            order_type: "FLAT",
            order_uuid: "7cca3eea-4923-48d9-b99f-4418b6918c0d",
            price: 3391.07,
            price_sources: [],
            processed_ms: 1711210022323,
          },
        ],
        position_type: "FLAT",
        position_uuid: "4081f1f6-b35b-48f7-a798-435798929564",
        return_at_close: 0.9988320211955817,
        trade_pair: ["ETHUSD", "ETH/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 3401.9959999999996,
        close_ms: 1711211823446,
        current_return: 1.0015508010348113,
        initial_entry_price: 3389.99,
        is_closed_position: true,
        miner_hotkey: "5Hbae5fSrykduTK1EBy5x8fweg8J5cF24iN55A8ftUtHCG7X",
        net_leverage: 0.0,
        open_ms: 1711210329491,
        orders: [
          {
            leverage: 0.06,
            order_type: "LONG",
            order_uuid: "125d5e9d-5b71-4b89-92ff-3d399d41ed21",
            price: 3389.99,
            price_sources: [],
            processed_ms: 1711210329488,
          },
          {
            leverage: 0.06,
            order_type: "LONG",
            order_uuid: "ba1c2b89-9f6f-4145-902d-6f2e61d8b38c",
            price: 3404.78,
            price_sources: [],
            processed_ms: 1711210627435,
          },
          {
            leverage: 0.06,
            order_type: "LONG",
            order_uuid: "9e76f7e5-c46f-4856-b94e-ed3436da4407",
            price: 3398.9,
            price_sources: [],
            processed_ms: 1711210944464,
          },
          {
            leverage: 0.06,
            order_type: "LONG",
            order_uuid: "76314550-14c8-4623-8507-42561fb35ce8",
            price: 3401.46,
            price_sources: [],
            processed_ms: 1711211222537,
          },
          {
            leverage: 0.06,
            order_type: "LONG",
            order_uuid: "9d777fcc-2878-400f-af65-c65eb56f7fad",
            price: 3414.85,
            price_sources: [],
            processed_ms: 1711211526517,
          },
          {
            leverage: 0.06,
            order_type: "FLAT",
            order_uuid: "c0af0af5-4464-46c1-bbbe-675625e32ed0",
            price: 3419.52,
            price_sources: [],
            processed_ms: 1711211823446,
          },
        ],
        position_type: "FLAT",
        position_uuid: "b51cb330-be7e-4716-9fb8-d3f94a05119f",
        return_at_close: 1.0009498705541904,
        trade_pair: ["ETHUSD", "ETH/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 3421.8379999999997,
        close_ms: 1711213628343,
        current_return: 0.9987739398535427,
        initial_entry_price: 3415.33,
        is_closed_position: true,
        miner_hotkey: "5Hbae5fSrykduTK1EBy5x8fweg8J5cF24iN55A8ftUtHCG7X",
        net_leverage: 0.0,
        open_ms: 1711212126203,
        orders: [
          {
            leverage: 0.06,
            order_type: "LONG",
            order_uuid: "584b8e72-23c8-414a-91d7-dd87536505c1",
            price: 3415.33,
            price_sources: [],
            processed_ms: 1711212126200,
          },
          {
            leverage: 0.06,
            order_type: "LONG",
            order_uuid: "c772ac18-adf0-4a5a-b7a1-669fd2bfd7a6",
            price: 3424.44,
            price_sources: [],
            processed_ms: 1711212431843,
          },
          {
            leverage: 0.06,
            order_type: "LONG",
            order_uuid: "0b23d839-e8d6-4882-ad44-9b3db853bdc7",
            price: 3424.44,
            price_sources: [],
            processed_ms: 1711212728386,
          },
          {
            leverage: 0.06,
            order_type: "LONG",
            order_uuid: "bf112826-32c0-4e05-a00e-3df392c33132",
            price: 3422.49,
            price_sources: [],
            processed_ms: 1711213079246,
          },
          {
            leverage: 0.06,
            order_type: "LONG",
            order_uuid: "02038508-529f-401f-b774-146474dd3db0",
            price: 3422.49,
            price_sources: [],
            processed_ms: 1711213328711,
          },
          {
            leverage: 0.06,
            order_type: "FLAT",
            order_uuid: "f71520d6-9a7b-47e5-b437-d6e81e01e955",
            price: 3407.88,
            price_sources: [],
            processed_ms: 1711213628343,
          },
        ],
        position_type: "FLAT",
        position_uuid: "865ac45b-8dbb-49be-a97b-0ec1c8a8bbc5",
        return_at_close: 0.9981746754896305,
        trade_pair: ["ETHUSD", "ETH/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 3406.2859999999996,
        close_ms: 1711215467000,
        current_return: 1.0009215648283516,
        initial_entry_price: 3406.38,
        is_closed_position: true,
        miner_hotkey: "5Hbae5fSrykduTK1EBy5x8fweg8J5cF24iN55A8ftUtHCG7X",
        net_leverage: 0.0,
        open_ms: 1711213931959,
        orders: [
          {
            leverage: 0.06,
            order_type: "LONG",
            order_uuid: "b0ac165c-5dda-4830-b7da-183a74187bb9",
            price: 3406.38,
            price_sources: [],
            processed_ms: 1711213931957,
          },
          {
            leverage: 0.06,
            order_type: "LONG",
            order_uuid: "d015a468-4994-4a50-beb2-5cd587ef252e",
            price: 3409.45,
            price_sources: [],
            processed_ms: 1711214225227,
          },
          {
            leverage: 0.06,
            order_type: "LONG",
            order_uuid: "8b27e61d-0001-46cd-b2b4-bb0e42876a0c",
            price: 3401.96,
            price_sources: [],
            processed_ms: 1711214525620,
          },
          {
            leverage: 0.06,
            order_type: "LONG",
            order_uuid: "78f85554-e75c-4dce-9749-bcd0c1d2d47a",
            price: 3400.0,
            price_sources: [],
            processed_ms: 1711214831960,
          },
          {
            leverage: 0.06,
            order_type: "LONG",
            order_uuid: "22db5945-c8d1-4078-a6eb-3f2af2496856",
            price: 3413.64,
            price_sources: [],
            processed_ms: 1711215131497,
          },
          {
            leverage: 0.06,
            order_type: "FLAT",
            order_uuid: "2d33976f-48bc-4980-a4c4-13abe3ac593b",
            price: 3416.75,
            price_sources: [],
            processed_ms: 1711215467000,
          },
        ],
        position_type: "FLAT",
        position_uuid: "fd4e3c0e-ea22-4392-9e92-ded2503d2e06",
        return_at_close: 1.0003210118894545,
        trade_pair: ["ETHUSD", "ETH/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 3405.5640000000003,
        close_ms: 1711217233540,
        current_return: 1.0000876076771064,
        initial_entry_price: 3410.66,
        is_closed_position: true,
        miner_hotkey: "5Hbae5fSrykduTK1EBy5x8fweg8J5cF24iN55A8ftUtHCG7X",
        net_leverage: 0.0,
        open_ms: 1711215726313,
        orders: [
          {
            leverage: 0.06,
            order_type: "LONG",
            order_uuid: "33e54ec2-4343-4fa2-a540-8746fe8722a0",
            price: 3410.66,
            price_sources: [],
            processed_ms: 1711215726310,
          },
          {
            leverage: 0.06,
            order_type: "LONG",
            order_uuid: "5397ec3d-4ab7-4c79-b51c-ded55f99dc0a",
            price: 3409.29,
            price_sources: [],
            processed_ms: 1711216029379,
          },
          {
            leverage: 0.06,
            order_type: "LONG",
            order_uuid: "201d66d8-4f0f-409c-9949-0023fd887dc3",
            price: 3404.55,
            price_sources: [],
            processed_ms: 1711216335293,
          },
          {
            leverage: 0.06,
            order_type: "LONG",
            order_uuid: "5f514f6a-843a-464c-949c-57a30c829d2c",
            price: 3401.19,
            price_sources: [],
            processed_ms: 1711216634623,
          },
          {
            leverage: 0.06,
            order_type: "LONG",
            order_uuid: "7abd3c14-f67f-460e-86c8-e77a9b299856",
            price: 3402.13,
            price_sources: [],
            processed_ms: 1711216936217,
          },
          {
            leverage: 0.06,
            order_type: "FLAT",
            order_uuid: "ea492d11-478d-4f2f-a4f0-a9783ef8e539",
            price: 3406.56,
            price_sources: [],
            processed_ms: 1711217233540,
          },
        ],
        position_type: "FLAT",
        position_uuid: "35703cbf-fcc6-4eb2-bdf1-d0a4c9f60812",
        return_at_close: 0.9994875551125001,
        trade_pair: ["ETHUSD", "ETH/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 3407.002,
        close_ms: 1711219036450,
        current_return: 0.9994699534676298,
        initial_entry_price: 3408.38,
        is_closed_position: true,
        miner_hotkey: "5Hbae5fSrykduTK1EBy5x8fweg8J5cF24iN55A8ftUtHCG7X",
        net_leverage: 0.0,
        open_ms: 1711217544903,
        orders: [
          {
            leverage: 0.06,
            order_type: "LONG",
            order_uuid: "a1000b62-a405-484d-bc42-a8252f4712f0",
            price: 3408.38,
            price_sources: [],
            processed_ms: 1711217544900,
          },
          {
            leverage: 0.06,
            order_type: "LONG",
            order_uuid: "4c1344cf-468f-42bf-a7fc-ab29da87b32a",
            price: 3408.38,
            price_sources: [],
            processed_ms: 1711217837813,
          },
          {
            leverage: 0.06,
            order_type: "LONG",
            order_uuid: "d20ad9fa-6037-4619-9c5e-f510ed5c3a7e",
            price: 3408.38,
            price_sources: [],
            processed_ms: 1711218128489,
          },
          {
            leverage: 0.06,
            order_type: "LONG",
            order_uuid: "f94d6abe-3e2e-4ff9-95bb-6b27df69cdcf",
            price: 3405.21,
            price_sources: [],
            processed_ms: 1711218428493,
          },
          {
            leverage: 0.06,
            order_type: "LONG",
            order_uuid: "6b4e1739-9f81-42cf-82d3-b79c6b36fcbf",
            price: 3404.66,
            price_sources: [],
            processed_ms: 1711218738245,
          },
          {
            leverage: 0.06,
            order_type: "FLAT",
            order_uuid: "bf25dc64-3e9b-4174-91ab-7acd79a6d503",
            price: 3400.98,
            price_sources: [],
            processed_ms: 1711219036450,
          },
        ],
        position_type: "FLAT",
        position_uuid: "08cac4ee-6b1c-46d9-ad30-ba934871c30e",
        return_at_close: 0.9988702714955492,
        trade_pair: ["ETHUSD", "ETH/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 3403.996,
        close_ms: 1711220833506,
        current_return: 1.0005297631477554,
        initial_entry_price: 3400.01,
        is_closed_position: true,
        miner_hotkey: "5Hbae5fSrykduTK1EBy5x8fweg8J5cF24iN55A8ftUtHCG7X",
        net_leverage: 0.0,
        open_ms: 1711219335348,
        orders: [
          {
            leverage: 0.06,
            order_type: "LONG",
            order_uuid: "d4792254-55d7-4bde-b880-3cd923412d61",
            price: 3400.01,
            price_sources: [],
            processed_ms: 1711219335345,
          },
          {
            leverage: 0.06,
            order_type: "LONG",
            order_uuid: "7b9593ce-b49f-49ee-8f35-7976c033904d",
            price: 3398.0,
            price_sources: [],
            processed_ms: 1711219652043,
          },
          {
            leverage: 0.06,
            order_type: "LONG",
            order_uuid: "67bdcf8c-53ee-4e11-8404-ca60ef5a88e1",
            price: 3405.17,
            price_sources: [],
            processed_ms: 1711219930640,
          },
          {
            leverage: 0.06,
            order_type: "LONG",
            order_uuid: "5a9cb0de-5d9a-4952-8eef-dcf169b80aa6",
            price: 3408.4,
            price_sources: [],
            processed_ms: 1711220236376,
          },
          {
            leverage: 0.06,
            order_type: "LONG",
            order_uuid: "a5903b50-4fa9-4a4a-9ea5-204128e10c1d",
            price: 3408.4,
            price_sources: [],
            processed_ms: 1711220535310,
          },
          {
            leverage: 0.06,
            order_type: "FLAT",
            order_uuid: "52319335-8530-4914-a951-8baff4a43c6f",
            price: 3410.0,
            price_sources: [],
            processed_ms: 1711220833506,
          },
        ],
        position_type: "FLAT",
        position_uuid: "f25d1fab-66c2-4790-9c4d-22625e2403c5",
        return_at_close: 0.9999294452898667,
        trade_pair: ["ETHUSD", "ETH/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 3403.4480000000003,
        close_ms: 1711222634187,
        current_return: 0.999121108201111,
        initial_entry_price: 3405.88,
        is_closed_position: true,
        miner_hotkey: "5Hbae5fSrykduTK1EBy5x8fweg8J5cF24iN55A8ftUtHCG7X",
        net_leverage: 0.0,
        open_ms: 1711221135391,
        orders: [
          {
            leverage: 0.06,
            order_type: "LONG",
            order_uuid: "e94b7f15-7253-40d5-bf99-a05672581ac0",
            price: 3405.88,
            price_sources: [],
            processed_ms: 1711221135388,
          },
          {
            leverage: 0.06,
            order_type: "LONG",
            order_uuid: "d4ead066-aef1-4172-b4e8-3d1107e97a40",
            price: 3408.99,
            price_sources: [],
            processed_ms: 1711221434935,
          },
          {
            leverage: 0.06,
            order_type: "LONG",
            order_uuid: "05eaad9d-9710-4475-8cec-21027106061b",
            price: 3402.52,
            price_sources: [],
            processed_ms: 1711221761777,
          },
          {
            leverage: 0.06,
            order_type: "LONG",
            order_uuid: "34b4dac5-92c6-48c8-8b20-85e0dbfb7885",
            price: 3400.1,
            price_sources: [],
            processed_ms: 1711222045366,
          },
          {
            leverage: 0.06,
            order_type: "LONG",
            order_uuid: "8dc6bec1-b768-45a9-8f13-b056d18183d3",
            price: 3399.75,
            price_sources: [],
            processed_ms: 1711222335623,
          },
          {
            leverage: 0.06,
            order_type: "FLAT",
            order_uuid: "741fb622-a04b-4028-8e5d-e5040d6361df",
            price: 3393.47,
            price_sources: [],
            processed_ms: 1711222634187,
          },
        ],
        position_type: "FLAT",
        position_uuid: "e681038e-a6ac-45f1-82ba-1d97cf1cf89e",
        return_at_close: 0.9985216355361903,
        trade_pair: ["ETHUSD", "ETH/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 3388.898,
        close_ms: 1711224437678,
        current_return: 1.00003290171938,
        initial_entry_price: 3391.92,
        is_closed_position: true,
        miner_hotkey: "5Hbae5fSrykduTK1EBy5x8fweg8J5cF24iN55A8ftUtHCG7X",
        net_leverage: 0.0,
        open_ms: 1711222942525,
        orders: [
          {
            leverage: 0.06,
            order_type: "LONG",
            order_uuid: "5d4cd27c-bf13-4328-8f95-2f2f9f15f2b5",
            price: 3391.92,
            price_sources: [],
            processed_ms: 1711222942522,
          },
          {
            leverage: 0.06,
            order_type: "LONG",
            order_uuid: "b5a696aa-3f28-4870-97c3-593d49192d4c",
            price: 3385.58,
            price_sources: [],
            processed_ms: 1711223236327,
          },
          {
            leverage: 0.06,
            order_type: "LONG",
            order_uuid: "e6593a5f-169d-451e-9b87-64488e030351",
            price: 3390.12,
            price_sources: [],
            processed_ms: 1711223538316,
          },
          {
            leverage: 0.06,
            order_type: "LONG",
            order_uuid: "792f0465-ca9e-4887-9001-b82c1f974f28",
            price: 3389.07,
            price_sources: [],
            processed_ms: 1711223835447,
          },
          {
            leverage: 0.06,
            order_type: "LONG",
            order_uuid: "dd1f9bfd-4aaa-482a-a745-facbf575a402",
            price: 3387.8,
            price_sources: [],
            processed_ms: 1711224147935,
          },
          {
            leverage: 0.06,
            order_type: "FLAT",
            order_uuid: "0ee2cd8e-e708-4352-bd37-10c4c4882707",
            price: 3389.27,
            price_sources: [],
            processed_ms: 1711224437678,
          },
        ],
        position_type: "FLAT",
        position_uuid: "f2d02011-e1e1-4344-a6f2-b0541a08db43",
        return_at_close: 0.9994328819783485,
        trade_pair: ["ETHUSD", "ETH/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 3386.651999999999,
        close_ms: 1711226239061,
        current_return: 1.0001533246969887,
        initial_entry_price: 3381.06,
        is_closed_position: true,
        miner_hotkey: "5Hbae5fSrykduTK1EBy5x8fweg8J5cF24iN55A8ftUtHCG7X",
        net_leverage: 0.0,
        open_ms: 1711224741303,
        orders: [
          {
            leverage: 0.06,
            order_type: "LONG",
            order_uuid: "5ca5d8ae-ae07-43d0-a8ca-f788104c6ba2",
            price: 3381.06,
            price_sources: [],
            processed_ms: 1711224741300,
          },
          {
            leverage: 0.06,
            order_type: "LONG",
            order_uuid: "9ccf7292-9a17-48e0-90e3-ec84992902b0",
            price: 3388.29,
            price_sources: [],
            processed_ms: 1711225044249,
          },
          {
            leverage: 0.06,
            order_type: "LONG",
            order_uuid: "d7830bea-a408-4c58-b9af-3cd833ac3563",
            price: 3388.1,
            price_sources: [],
            processed_ms: 1711225341207,
          },
          {
            leverage: 0.06,
            order_type: "LONG",
            order_uuid: "050ebf05-2ca2-40c0-80c0-39f93077cc34",
            price: 3387.43,
            price_sources: [],
            processed_ms: 1711225636261,
          },
          {
            leverage: 0.06,
            order_type: "LONG",
            order_uuid: "0fd914f5-0344-4cc4-887e-6f7dc1255d25",
            price: 3388.38,
            price_sources: [],
            processed_ms: 1711225936145,
          },
          {
            leverage: 0.06,
            order_type: "FLAT",
            order_uuid: "573eb5b4-866f-4242-89fe-4dca8f9937f9",
            price: 3388.38,
            price_sources: [],
            processed_ms: 1711226239061,
          },
        ],
        position_type: "FLAT",
        position_uuid: "968e43f0-e4fa-47ce-a7c1-2b96bc4251de",
        return_at_close: 0.9995532327021704,
        trade_pair: ["ETHUSD", "ETH/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 3387.4339999999997,
        close_ms: 1711228039301,
        current_return: 0.9991605990158015,
        initial_entry_price: 3389.56,
        is_closed_position: true,
        miner_hotkey: "5Hbae5fSrykduTK1EBy5x8fweg8J5cF24iN55A8ftUtHCG7X",
        net_leverage: 0.0,
        open_ms: 1711226544434,
        orders: [
          {
            leverage: 0.06,
            order_type: "LONG",
            order_uuid: "ffb40bad-e777-4855-8636-990c65280cf2",
            price: 3389.56,
            price_sources: [],
            processed_ms: 1711226544430,
          },
          {
            leverage: 0.06,
            order_type: "LONG",
            order_uuid: "b864c04e-e799-45d2-8014-67d8d9743acb",
            price: 3389.56,
            price_sources: [],
            processed_ms: 1711226844838,
          },
          {
            leverage: 0.06,
            order_type: "LONG",
            order_uuid: "f9b6fda3-e3dd-49af-acd3-88f1f735a3fc",
            price: 3384.01,
            price_sources: [],
            processed_ms: 1711227138701,
          },
          {
            leverage: 0.06,
            order_type: "LONG",
            order_uuid: "97841474-29b1-44c0-9a13-6ed2cf61bc26",
            price: 3385.49,
            price_sources: [],
            processed_ms: 1711227440350,
          },
          {
            leverage: 0.06,
            order_type: "LONG",
            order_uuid: "3365aa3d-1694-454d-a25c-4f75e7468961",
            price: 3388.55,
            price_sources: [],
            processed_ms: 1711227740350,
          },
          {
            leverage: 0.06,
            order_type: "FLAT",
            order_uuid: "a421e21a-2b2a-4e88-aa5b-716801cd2de6",
            price: 3377.95,
            price_sources: [],
            processed_ms: 1711228039301,
          },
        ],
        position_type: "FLAT",
        position_uuid: "9835d2f9-c6cb-4e24-ae41-bb967e687ffb",
        return_at_close: 0.998561102656392,
        trade_pair: ["ETHUSD", "ETH/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 3377.5860000000002,
        close_ms: 1711229846367,
        current_return: 1.0003884880584775,
        initial_entry_price: 3377.71,
        is_closed_position: true,
        miner_hotkey: "5Hbae5fSrykduTK1EBy5x8fweg8J5cF24iN55A8ftUtHCG7X",
        net_leverage: 0.0,
        open_ms: 1711228343095,
        orders: [
          {
            leverage: 0.06,
            order_type: "LONG",
            order_uuid: "cfaf9305-961b-441b-a938-bc6d4ec059f8",
            price: 3377.71,
            price_sources: [],
            processed_ms: 1711228343092,
          },
          {
            leverage: 0.06,
            order_type: "LONG",
            order_uuid: "47d9b234-78ff-4240-a5c1-abb2f6106d97",
            price: 3377.82,
            price_sources: [],
            processed_ms: 1711228640277,
          },
          {
            leverage: 0.06,
            order_type: "LONG",
            order_uuid: "7420ebd4-0119-4b2a-b136-f346bad35d7d",
            price: 3378.15,
            price_sources: [],
            processed_ms: 1711228969809,
          },
          {
            leverage: 0.06,
            order_type: "LONG",
            order_uuid: "abf649a5-c90b-4f4f-92a2-ae9f079a6ee4",
            price: 3378.66,
            price_sources: [],
            processed_ms: 1711229248592,
          },
          {
            leverage: 0.06,
            order_type: "LONG",
            order_uuid: "ef8f1ff7-5b40-4589-9d48-0f47d458ddd5",
            price: 3375.59,
            price_sources: [],
            processed_ms: 1711229542274,
          },
          {
            leverage: 0.06,
            order_type: "FLAT",
            order_uuid: "11b6f2ce-a72a-484c-9d27-750f086eb0c5",
            price: 3381.96,
            price_sources: [],
            processed_ms: 1711229846367,
          },
        ],
        position_type: "FLAT",
        position_uuid: "0da5b51c-cf30-4fde-92ee-e3083821e6c1",
        return_at_close: 0.9997882549656424,
        trade_pair: ["ETHUSD", "ETH/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 3387.112,
        close_ms: 1711231645052,
        current_return: 1.0001552485190108,
        initial_entry_price: 3377.81,
        is_closed_position: true,
        miner_hotkey: "5Hbae5fSrykduTK1EBy5x8fweg8J5cF24iN55A8ftUtHCG7X",
        net_leverage: 0.0,
        open_ms: 1711230146229,
        orders: [
          {
            leverage: 0.06,
            order_type: "LONG",
            order_uuid: "56631bd3-c7bc-46c1-886d-dfc480b97bb0",
            price: 3377.81,
            price_sources: [],
            processed_ms: 1711230146225,
          },
          {
            leverage: 0.06,
            order_type: "LONG",
            order_uuid: "20204f1f-1a91-41f6-9a45-2a216f675439",
            price: 3389.59,
            price_sources: [],
            processed_ms: 1711230447317,
          },
          {
            leverage: 0.06,
            order_type: "LONG",
            order_uuid: "ed833522-6db6-42fd-843e-55c74deebb86",
            price: 3389.59,
            price_sources: [],
            processed_ms: 1711230748175,
          },
          {
            leverage: 0.06,
            order_type: "LONG",
            order_uuid: "c30c9da2-c230-4987-915e-60acd16a0d47",
            price: 3389.43,
            price_sources: [],
            processed_ms: 1711231040368,
          },
          {
            leverage: 0.06,
            order_type: "LONG",
            order_uuid: "f48d9e19-091c-46f4-b5ea-bef311cad3cf",
            price: 3389.14,
            price_sources: [],
            processed_ms: 1711231353852,
          },
          {
            leverage: 0.06,
            order_type: "FLAT",
            order_uuid: "ff38199a-8816-499b-b038-b318eb7569fa",
            price: 3388.86,
            price_sources: [],
            processed_ms: 1711231645052,
          },
        ],
        position_type: "FLAT",
        position_uuid: "740a0384-dcd9-441d-b07d-3bfdec665863",
        return_at_close: 0.9995551553698994,
        trade_pair: ["ETHUSD", "ETH/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 3382.458,
        close_ms: 1711233447791,
        current_return: 1.0010577658365196,
        initial_entry_price: 3386.95,
        is_closed_position: true,
        miner_hotkey: "5Hbae5fSrykduTK1EBy5x8fweg8J5cF24iN55A8ftUtHCG7X",
        net_leverage: 0.0,
        open_ms: 1711231947428,
        orders: [
          {
            leverage: 0.06,
            order_type: "LONG",
            order_uuid: "01f9f843-20ef-4649-a4c6-b94db325f0a8",
            price: 3386.95,
            price_sources: [],
            processed_ms: 1711231947425,
          },
          {
            leverage: 0.06,
            order_type: "LONG",
            order_uuid: "747b3a91-211e-4fbe-a968-6657ae87a486",
            price: 3385.2,
            price_sources: [],
            processed_ms: 1711232241392,
          },
          {
            leverage: 0.06,
            order_type: "LONG",
            order_uuid: "296a7387-f215-43f9-b974-9558c98a48f8",
            price: 3375.48,
            price_sources: [],
            processed_ms: 1711232547300,
          },
          {
            leverage: 0.06,
            order_type: "LONG",
            order_uuid: "fbe10c63-d007-4b42-a186-dbd5ee962eab",
            price: 3376.83,
            price_sources: [],
            processed_ms: 1711232850360,
          },
          {
            leverage: 0.06,
            order_type: "LONG",
            order_uuid: "a267bd81-2ffe-423f-8902-293124acb6d8",
            price: 3387.83,
            price_sources: [],
            processed_ms: 1711233149177,
          },
          {
            leverage: 0.06,
            order_type: "FLAT",
            order_uuid: "af3f3aac-03a1-4dfa-9a62-75a2a9fd2345",
            price: 3394.4,
            price_sources: [],
            processed_ms: 1711233447791,
          },
        ],
        position_type: "FLAT",
        position_uuid: "2001d19d-1331-4c00-93ca-0b7b0ef63ba4",
        return_at_close: 1.0004571311770176,
        trade_pair: ["ETHUSD", "ETH/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 3382.766,
        close_ms: 1711235247292,
        current_return: 0.9992694703083888,
        initial_entry_price: 3386.31,
        is_closed_position: true,
        miner_hotkey: "5Hbae5fSrykduTK1EBy5x8fweg8J5cF24iN55A8ftUtHCG7X",
        net_leverage: 0.0,
        open_ms: 1711233751922,
        orders: [
          {
            leverage: 0.06,
            order_type: "LONG",
            order_uuid: "d4689b4c-3958-4e32-aeb4-e3dd6277a653",
            price: 3386.31,
            price_sources: [],
            processed_ms: 1711233751918,
          },
          {
            leverage: 0.06,
            order_type: "LONG",
            order_uuid: "78d1776e-0d7b-4ff0-9565-bb6ce91a57c1",
            price: 3379.81,
            price_sources: [],
            processed_ms: 1711234050674,
          },
          {
            leverage: 0.06,
            order_type: "LONG",
            order_uuid: "3a23aae2-4c18-48c2-8309-3f89a620d708",
            price: 3381.17,
            price_sources: [],
            processed_ms: 1711234343359,
          },
          {
            leverage: 0.06,
            order_type: "LONG",
            order_uuid: "fafdd0d5-056c-40fa-b66b-0e7ec47ec4c5",
            price: 3390.79,
            price_sources: [],
            processed_ms: 1711234647458,
          },
          {
            leverage: 0.06,
            order_type: "LONG",
            order_uuid: "504924b1-5ddd-4ae1-935e-eadc95c8a632",
            price: 3375.75,
            price_sources: [],
            processed_ms: 1711234951378,
          },
          {
            leverage: 0.06,
            order_type: "FLAT",
            order_uuid: "78e88a04-9e9d-48fa-a36f-d7be73a40a6a",
            price: 3374.52,
            price_sources: [],
            processed_ms: 1711235247292,
          },
        ],
        position_type: "FLAT",
        position_uuid: "c4416553-b803-478c-a415-c062ffee1521",
        return_at_close: 0.9986699086262036,
        trade_pair: ["ETHUSD", "ETH/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 3366.602,
        close_ms: 1711237046462,
        current_return: 0.999605870443544,
        initial_entry_price: 3373.51,
        is_closed_position: true,
        miner_hotkey: "5Hbae5fSrykduTK1EBy5x8fweg8J5cF24iN55A8ftUtHCG7X",
        net_leverage: 0.0,
        open_ms: 1711235547298,
        orders: [
          {
            leverage: 0.06,
            order_type: "LONG",
            order_uuid: "af6be02e-d62a-4359-898c-c76164d88d80",
            price: 3373.51,
            price_sources: [],
            processed_ms: 1711235547295,
          },
          {
            leverage: 0.06,
            order_type: "LONG",
            order_uuid: "3896ed04-ee6e-47b1-8cc2-6c7882d1937d",
            price: 3363.1,
            price_sources: [],
            processed_ms: 1711235850520,
          },
          {
            leverage: 0.06,
            order_type: "LONG",
            order_uuid: "15a377a6-79b4-4dc1-aba1-4443dc3898be",
            price: 3370.23,
            price_sources: [],
            processed_ms: 1711236153695,
          },
          {
            leverage: 0.06,
            order_type: "LONG",
            order_uuid: "2f3a12a7-4fdb-4e11-ba40-946694828501",
            price: 3369.47,
            price_sources: [],
            processed_ms: 1711236452421,
          },
          {
            leverage: 0.06,
            order_type: "LONG",
            order_uuid: "5417bf97-8001-476b-82d8-f4cc560acb64",
            price: 3356.7,
            price_sources: [],
            processed_ms: 1711236777897,
          },
          {
            leverage: 0.06,
            order_type: "FLAT",
            order_uuid: "fbd51ca9-9e56-4851-a173-76442bd56e16",
            price: 3362.17,
            price_sources: [],
            processed_ms: 1711237046462,
          },
        ],
        position_type: "FLAT",
        position_uuid: "69cbb5dd-a206-468e-888e-4ebf56e43574",
        return_at_close: 0.9990061069212778,
        trade_pair: ["ETHUSD", "ETH/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 3347.8920000000003,
        close_ms: 1711238857808,
        current_return: 0.999262497024518,
        initial_entry_price: 3360.8,
        is_closed_position: true,
        miner_hotkey: "5Hbae5fSrykduTK1EBy5x8fweg8J5cF24iN55A8ftUtHCG7X",
        net_leverage: 0.0,
        open_ms: 1711237350263,
        orders: [
          {
            leverage: 0.06,
            order_type: "LONG",
            order_uuid: "cebf7a55-116b-4d78-b53d-47bfcb0effe9",
            price: 3360.8,
            price_sources: [],
            processed_ms: 1711237350259,
          },
          {
            leverage: 0.06,
            order_type: "LONG",
            order_uuid: "9ffe9fdd-f59a-4276-8680-b1df089ce347",
            price: 3355.64,
            price_sources: [],
            processed_ms: 1711237654228,
          },
          {
            leverage: 0.06,
            order_type: "LONG",
            order_uuid: "02d5f8a2-0caa-4b4e-9d2b-fed84d2b6c12",
            price: 3356.69,
            price_sources: [],
            processed_ms: 1711237947216,
          },
          {
            leverage: 0.06,
            order_type: "LONG",
            order_uuid: "0e333d59-ee0c-4782-b5fe-4e3b69d38eb5",
            price: 3334.38,
            price_sources: [],
            processed_ms: 1711238254350,
          },
          {
            leverage: 0.06,
            order_type: "LONG",
            order_uuid: "0b652e63-3f09-486d-b6ab-8c282ae59aa1",
            price: 3331.95,
            price_sources: [],
            processed_ms: 1711238550031,
          },
          {
            leverage: 0.06,
            order_type: "FLAT",
            order_uuid: "ed1cfac9-a86b-4eaa-9196-24c932b4e440",
            price: 3339.63,
            price_sources: [],
            processed_ms: 1711238857808,
          },
        ],
        position_type: "FLAT",
        position_uuid: "10fd528f-4bea-481a-b004-dab4f6b50b64",
        return_at_close: 0.9986629395263033,
        trade_pair: ["ETHUSD", "ETH/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 3348.2659999999996,
        close_ms: 1711240655674,
        current_return: 1.000526339369719,
        initial_entry_price: 3348.03,
        is_closed_position: true,
        miner_hotkey: "5Hbae5fSrykduTK1EBy5x8fweg8J5cF24iN55A8ftUtHCG7X",
        net_leverage: 0.0,
        open_ms: 1711239156336,
        orders: [
          {
            leverage: 0.06,
            order_type: "LONG",
            order_uuid: "70887253-5fce-4155-82c8-e02c64f2897d",
            price: 3348.03,
            price_sources: [],
            processed_ms: 1711239156332,
          },
          {
            leverage: 0.06,
            order_type: "LONG",
            order_uuid: "65ee54db-e60f-4331-b034-bace567ba6c3",
            price: 3346.1,
            price_sources: [],
            processed_ms: 1711239450311,
          },
          {
            leverage: 0.06,
            order_type: "LONG",
            order_uuid: "fdd6f315-015c-4060-a523-275bb40b5390",
            price: 3345.11,
            price_sources: [],
            processed_ms: 1711239756241,
          },
          {
            leverage: 0.06,
            order_type: "LONG",
            order_uuid: "90e61422-699b-4426-9b88-f8c8b6f14115",
            price: 3351.8,
            price_sources: [],
            processed_ms: 1711240056285,
          },
          {
            leverage: 0.06,
            order_type: "LONG",
            order_uuid: "250b5906-8790-41e3-8da1-72ed0d8da52e",
            price: 3350.29,
            price_sources: [],
            processed_ms: 1711240351256,
          },
          {
            leverage: 0.06,
            order_type: "FLAT",
            order_uuid: "be63b730-97c0-4d0b-9273-04bc347953b5",
            price: 3354.14,
            price_sources: [],
            processed_ms: 1711240655674,
          },
        ],
        position_type: "FLAT",
        position_uuid: "01d971b7-e5df-4074-99c5-4b9e6849af90",
        return_at_close: 0.9999260235660972,
        trade_pair: ["ETHUSD", "ETH/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 3356.486,
        close_ms: 1711242451199,
        current_return: 0.9997500558709158,
        initial_entry_price: 3355.95,
        is_closed_position: true,
        miner_hotkey: "5Hbae5fSrykduTK1EBy5x8fweg8J5cF24iN55A8ftUtHCG7X",
        net_leverage: 0.0,
        open_ms: 1711240987091,
        orders: [
          {
            leverage: 0.06,
            order_type: "LONG",
            order_uuid: "03998fa5-7113-4daf-ac6a-80fb361b8cc4",
            price: 3355.95,
            price_sources: [],
            processed_ms: 1711240987087,
          },
          {
            leverage: 0.06,
            order_type: "LONG",
            order_uuid: "ae69c549-3717-4ff6-a491-d65f916ae9fc",
            price: 3353.34,
            price_sources: [],
            processed_ms: 1711241254415,
          },
          {
            leverage: 0.06,
            order_type: "LONG",
            order_uuid: "740e7e7c-b642-4e80-a59f-40a9e516c752",
            price: 3358.14,
            price_sources: [],
            processed_ms: 1711241553416,
          },
          {
            leverage: 0.06,
            order_type: "LONG",
            order_uuid: "ccd22805-aab8-47e7-a16e-c5af5f836696",
            price: 3356.94,
            price_sources: [],
            processed_ms: 1711241851456,
          },
          {
            leverage: 0.06,
            order_type: "LONG",
            order_uuid: "cd85730f-e6ab-4444-a992-9ec94f79f019",
            price: 3358.06,
            price_sources: [],
            processed_ms: 1711242159298,
          },
          {
            leverage: 0.06,
            order_type: "FLAT",
            order_uuid: "060fdbe9-0173-4329-a7b7-ed6d09982d62",
            price: 3353.69,
            price_sources: [],
            processed_ms: 1711242451199,
          },
        ],
        position_type: "FLAT",
        position_uuid: "45bd4a22-f758-47c1-b514-b92a3b156d52",
        return_at_close: 0.9991502058373932,
        trade_pair: ["ETHUSD", "ETH/USD", 0.003, 0.001, 20],
      },
    ],
    thirty_day_returns: 1.0,
  },
  "5Hbd1S9JiUTFD9rUkbVtywjtfd82nb6HuannsjonMS36N1tb": {
    all_time_returns: 1.0028603624203851,
    n_positions: 1,
    percentage_profitable: 1.0,
    positions: [
      {
        average_entry_price: 3278.28,
        close_ms: 1719843134462,
        current_return: 1.0029155227741378,
        initial_entry_price: 3278.28,
        is_closed_position: true,
        miner_hotkey: "5Hbd1S9JiUTFD9rUkbVtywjtfd82nb6HuannsjonMS36N1tb",
        net_leverage: 0.0,
        open_ms: 1719244163507,
        orders: [
          {
            leverage: 0.055,
            order_type: "LONG",
            order_uuid: "c602dcda-dd7f-4393-963b-05b5ff57ef89",
            price: 3278.28,
            price_sources: [],
            processed_ms: 1719244162824,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "ac442073-5d16-4220-844b-c150fc92f039",
            price: 3452.06,
            price_sources: [],
            processed_ms: 1719843134462,
          },
        ],
        position_type: "FLAT",
        position_uuid: "5f44d3b4-410a-4f1a-a603-5f707221025a",
        return_at_close: 1.0028603624203851,
        trade_pair: ["ETHUSD", "ETH/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 62541.56,
        close_ms: 0,
        current_return: 0.9852105128173969,
        initial_entry_price: 62541.56,
        is_closed_position: false,
        miner_hotkey: "5Hbd1S9JiUTFD9rUkbVtywjtfd82nb6HuannsjonMS36N1tb",
        net_leverage: 0.21,
        open_ms: 1719843242873,
        orders: [
          {
            leverage: 0.21,
            order_type: "LONG",
            order_uuid: "0be59dfd-594a-4ebc-9ef0-f3f578646d6c",
            price: 62541.56,
            price_sources: [],
            processed_ms: 1719843242523,
          },
        ],
        position_type: "LONG",
        position_uuid: "2b30b793-22bc-46c9-996e-3168138a9300",
        return_at_close: 0.9851070657135511,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
    ],
    thirty_day_returns: 1.0028603624203851,
  },
  "5HgBDcx8Z9oEWrGQm7obH4aPE2M5YXWA6S6MP1HtFHguUqek": {
    all_time_returns: 1.2534120341276618,
    n_positions: 108,
    percentage_profitable: 0.9444444444444444,
    positions: [
      {
        average_entry_price: 152.895,
        close_ms: 1712935772333,
        current_return: 1.0000240585774058,
        initial_entry_price: 152.96,
        is_closed_position: true,
        miner_hotkey: "5HgBDcx8Z9oEWrGQm7obH4aPE2M5YXWA6S6MP1HtFHguUqek",
        net_leverage: 0.0,
        open_ms: 1712929925515,
        orders: [
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "7ea135fa-2e8f-4714-849c-14f2be794bb2",
            price: 152.96,
            price_sources: [],
            processed_ms: 1712929925515,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "fbd913c4-d389-47d9-bc59-e7f4d1e5c12e",
            price: 152.83,
            price_sources: [],
            processed_ms: 1712930727195,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "3950fe01-07e3-4daf-85ad-a87481917ad3",
            price: 153.079,
            price_sources: [],
            processed_ms: 1712935772333,
          },
        ],
        position_type: "FLAT",
        position_uuid: "279fefeb-6686-41ea-a4a6-482509e24dc4",
        return_at_close: 1.0000226585437237,
        trade_pair: ["USDJPY", "USD/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 2954.1,
        close_ms: 1713047682607,
        current_return: 1.0001150604244948,
        initial_entry_price: 2954.1,
        is_closed_position: true,
        miner_hotkey: "5HgBDcx8Z9oEWrGQm7obH4aPE2M5YXWA6S6MP1HtFHguUqek",
        net_leverage: 0.0,
        open_ms: 1713047487708,
        orders: [
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "24ad5f14-c109-4e4a-a637-8709e58ce04f",
            price: 2954.1,
            price_sources: [],
            processed_ms: 1713047487707,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "a706e4c9-9094-4f23-bbf9-436954444cba",
            price: 2988.09,
            price_sources: [],
            processed_ms: 1713047682607,
          },
        ],
        position_type: "FLAT",
        position_uuid: "7728d730-83a0-4a1e-98f6-969172e18a3a",
        return_at_close: 1.0001050592738907,
        trade_pair: ["ETHUSD", "ETH/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 0.89161,
        close_ms: 1713164377883,
        current_return: 0.999994952950281,
        initial_entry_price: 0.89161,
        is_closed_position: true,
        miner_hotkey: "5HgBDcx8Z9oEWrGQm7obH4aPE2M5YXWA6S6MP1HtFHguUqek",
        net_leverage: 0.0,
        open_ms: 1713164196401,
        orders: [
          {
            leverage: 0.05,
            order_type: "LONG",
            order_uuid: "50795ec7-8c65-4381-affb-391f58f3c2b2",
            price: 0.89161,
            price_sources: [],
            processed_ms: 1713164196401,
          },
          {
            leverage: -0.05,
            order_type: "SHORT",
            order_uuid: "ecc2bf66-a485-48e6-b317-afbabc719a82",
            price: 0.89152,
            price_sources: [],
            processed_ms: 1713164377883,
          },
        ],
        position_type: "FLAT",
        position_uuid: "e0ed6e20-e17f-4105-9e23-86f15f2adbe2",
        return_at_close: 0.9999914529679456,
        trade_pair: ["AUDCAD", "AUD/CAD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 99.766,
        close_ms: 1713168674879,
        current_return: 1.000005111961991,
        initial_entry_price: 99.766,
        is_closed_position: true,
        miner_hotkey: "5HgBDcx8Z9oEWrGQm7obH4aPE2M5YXWA6S6MP1HtFHguUqek",
        net_leverage: 0.0,
        open_ms: 1713164571720,
        orders: [
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "1d9c5a48-82b5-4a04-8d20-049fbe5cbc7d",
            price: 99.766,
            price_sources: [],
            processed_ms: 1713164571719,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "127fce5c-a388-47c4-a958-5379cb8fb3cb",
            price: 99.817,
            price_sources: [],
            processed_ms: 1713168674879,
          },
        ],
        position_type: "FLAT",
        position_uuid: "c3182493-5a3c-408b-92d2-7251699f176d",
        return_at_close: 1.0000044119584126,
        trade_pair: ["AUDJPY", "AUD/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.6484099999999999,
        close_ms: 1713169913174,
        current_return: 1.0000126500262256,
        initial_entry_price: 0.64822,
        is_closed_position: true,
        miner_hotkey: "5HgBDcx8Z9oEWrGQm7obH4aPE2M5YXWA6S6MP1HtFHguUqek",
        net_leverage: 0.0,
        open_ms: 1713164541483,
        orders: [
          {
            leverage: -0.01,
            order_type: "SHORT",
            order_uuid: "cf865acf-d962-421d-980e-94f50a6e1a13",
            price: 0.64822,
            price_sources: [],
            processed_ms: 1713164541482,
          },
          {
            leverage: -0.01,
            order_type: "SHORT",
            order_uuid: "8ebaad16-967f-4635-90e9-c10eac047c75",
            price: 0.6486,
            price_sources: [],
            processed_ms: 1713169267359,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "308ffa68-227c-4c35-baf4-ddf8a59b2ed8",
            price: 0.648,
            price_sources: [],
            processed_ms: 1713169913174,
          },
        ],
        position_type: "FLAT",
        position_uuid: "c578cd0d-31a4-43f5-a60d-40939320087b",
        return_at_close: 1.0000112500085157,
        trade_pair: ["AUDUSD", "AUD/USD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 99.708,
        close_ms: 1713169929049,
        current_return: 1.000002908492799,
        initial_entry_price: 99.708,
        is_closed_position: true,
        miner_hotkey: "5HgBDcx8Z9oEWrGQm7obH4aPE2M5YXWA6S6MP1HtFHguUqek",
        net_leverage: 0.0,
        open_ms: 1713169301145,
        orders: [
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "e8c1f660-dc73-4225-8884-6ccd55b5623b",
            price: 99.708,
            price_sources: [],
            processed_ms: 1713169301144,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "ddffe7b7-a6a6-438e-8426-3bc0b4fd1761",
            price: 99.737,
            price_sources: [],
            processed_ms: 1713169929049,
          },
        ],
        position_type: "FLAT",
        position_uuid: "1046b8e3-5132-4fea-9f06-c6a8acdfb6d1",
        return_at_close: 1.0000022084907632,
        trade_pair: ["AUDJPY", "AUD/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.97237,
        close_ms: 1713170059690,
        current_return: 1.0000031880868394,
        initial_entry_price: 0.97237,
        is_closed_position: true,
        miner_hotkey: "5HgBDcx8Z9oEWrGQm7obH4aPE2M5YXWA6S6MP1HtFHguUqek",
        net_leverage: 0.0,
        open_ms: 1713169363238,
        orders: [
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "27a5e42e-103d-47c1-a27e-2a89e5ce63b8",
            price: 0.97237,
            price_sources: [],
            processed_ms: 1713169363236,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "fc775750-66c5-4c26-a35b-112627c0c8ef",
            price: 0.97268,
            price_sources: [],
            processed_ms: 1713170059690,
          },
        ],
        position_type: "FLAT",
        position_uuid: "293df291-24ea-4e82-b997-33114433541b",
        return_at_close: 1.0000024880846077,
        trade_pair: ["EURCHF", "EUR/CHF", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 1.46382,
        close_ms: 1713171133041,
        current_return: 1.0000030058340506,
        initial_entry_price: 1.46382,
        is_closed_position: true,
        miner_hotkey: "5HgBDcx8Z9oEWrGQm7obH4aPE2M5YXWA6S6MP1HtFHguUqek",
        net_leverage: 0.0,
        open_ms: 1713169330238,
        orders: [
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "1fd48ea7-eec0-4c4d-a16d-06b2f7f05973",
            price: 1.46382,
            price_sources: [],
            processed_ms: 1713169327943,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "6aadcf6e-1d1a-4502-a5d6-2be5f55ece48",
            price: 1.46426,
            price_sources: [],
            processed_ms: 1713171133041,
          },
        ],
        position_type: "FLAT",
        position_uuid: "8ca51760-8098-4e68-b525-e582e597491e",
        return_at_close: 1.0000023058319465,
        trade_pair: ["EURCAD", "EUR/CAD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.8538699999999999,
        close_ms: 1713172017457,
        current_return: 1.0000043332123156,
        initial_entry_price: 0.85387,
        is_closed_position: true,
        miner_hotkey: "5HgBDcx8Z9oEWrGQm7obH4aPE2M5YXWA6S6MP1HtFHguUqek",
        net_leverage: 0.0,
        open_ms: 1713169379567,
        orders: [
          {
            leverage: -0.01,
            order_type: "SHORT",
            order_uuid: "6bc5e005-f482-4612-b5b2-925b9402f391",
            price: 0.85387,
            price_sources: [],
            processed_ms: 1713169379565,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "92e0cba5-b077-44c0-b28f-d086394aabfe",
            price: 0.8535,
            price_sources: [],
            processed_ms: 1713172017457,
          },
        ],
        position_type: "FLAT",
        position_uuid: "44d576b7-4928-429a-8175-02314f1950d4",
        return_at_close: 1.0000036332092823,
        trade_pair: ["EURGBP", "EUR/GBP", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 1.79394,
        close_ms: 1713172037983,
        current_return: 1.0000039577689332,
        initial_entry_price: 1.79394,
        is_closed_position: true,
        miner_hotkey: "5HgBDcx8Z9oEWrGQm7obH4aPE2M5YXWA6S6MP1HtFHguUqek",
        net_leverage: 0.0,
        open_ms: 1713169422192,
        orders: [
          {
            leverage: -0.01,
            order_type: "SHORT",
            order_uuid: "90a7f08a-1a81-481f-916f-abcaf377bba5",
            price: 1.79394,
            price_sources: [],
            processed_ms: 1713169422191,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "4e796e5c-b54d-4813-a800-85cc5d18375f",
            price: 1.79323,
            price_sources: [],
            processed_ms: 1713172037983,
          },
        ],
        position_type: "FLAT",
        position_uuid: "56659b18-10b0-468e-9574-f3f4883c6579",
        return_at_close: 1.0000032577661628,
        trade_pair: ["EURNZD", "EUR/NZD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 163.945,
        close_ms: 1713172636547,
        current_return: 1.000002256854433,
        initial_entry_price: 163.945,
        is_closed_position: true,
        miner_hotkey: "5HgBDcx8Z9oEWrGQm7obH4aPE2M5YXWA6S6MP1HtFHguUqek",
        net_leverage: 0.0,
        open_ms: 1713169401869,
        orders: [
          {
            leverage: -0.01,
            order_type: "SHORT",
            order_uuid: "f7a58271-ead4-4fd1-8ceb-8628c3d88293",
            price: 163.945,
            price_sources: [],
            processed_ms: 1713169401868,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "cc5b021e-135c-406f-b4bc-622914fafebe",
            price: 163.908,
            price_sources: [],
            processed_ms: 1713172636547,
          },
        ],
        position_type: "FLAT",
        position_uuid: "245b72aa-7565-4d4d-a5b0-f6d2c9eb8071",
        return_at_close: 1.000001556852853,
        trade_pair: ["EURJPY", "EUR/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 153.851,
        close_ms: 1713176614985,
        current_return: 1.0000022749283397,
        initial_entry_price: 153.851,
        is_closed_position: true,
        miner_hotkey: "5HgBDcx8Z9oEWrGQm7obH4aPE2M5YXWA6S6MP1HtFHguUqek",
        net_leverage: 0.0,
        open_ms: 1713171661296,
        orders: [
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "936a949d-3b2e-48a0-8f62-ff49c678a0c4",
            price: 153.851,
            price_sources: [],
            processed_ms: 1713171661295,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "c5e47372-2983-44ee-b656-5ecdc5598215",
            price: 153.886,
            price_sources: [],
            processed_ms: 1713176614985,
          },
        ],
        position_type: "FLAT",
        position_uuid: "e4370191-75cf-421c-9a6e-9e45b5749541",
        return_at_close: 1.0000015749267472,
        trade_pair: ["USDJPY", "USD/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 1.06581,
        close_ms: 1713179649017,
        current_return: 1.0000045974423208,
        initial_entry_price: 1.06581,
        is_closed_position: true,
        miner_hotkey: "5HgBDcx8Z9oEWrGQm7obH4aPE2M5YXWA6S6MP1HtFHguUqek",
        net_leverage: 0.0,
        open_ms: 1713169345361,
        orders: [
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "71e35665-cd75-4b73-9ac0-db1f709584ee",
            price: 1.06581,
            price_sources: [],
            processed_ms: 1713169345360,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "24161276-9976-4929-a05f-5776a8293c5a",
            price: 1.0663,
            price_sources: [],
            processed_ms: 1713179649017,
          },
        ],
        position_type: "FLAT",
        position_uuid: "15e010b3-da35-42c6-885c-055cd45747c7",
        return_at_close: 1.0000038974391026,
        trade_pair: ["EURUSD", "EUR/USD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 192.324,
        close_ms: 1713343808518,
        current_return: 1.0000440922609763,
        initial_entry_price: 192.324,
        is_closed_position: true,
        miner_hotkey: "5HgBDcx8Z9oEWrGQm7obH4aPE2M5YXWA6S6MP1HtFHguUqek",
        net_leverage: 0.0,
        open_ms: 1713320156248,
        orders: [
          {
            leverage: 0.02,
            order_type: "LONG",
            order_uuid: "75b09a1d-7b82-48cb-93db-199714fb7f1e",
            price: 192.324,
            price_sources: [],
            processed_ms: 1713320156247,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "e3e61dde-1857-4752-8bd9-b7ac6f64409b",
            price: 192.748,
            price_sources: [],
            processed_ms: 1713343808518,
          },
        ],
        position_type: "FLAT",
        position_uuid: "6b91922d-8e06-4c11-86a2-9f7e011dc8b3",
        return_at_close: 1.0000426921992471,
        trade_pair: ["GBPJPY", "GBP/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 1.2967795400000026,
        close_ms: 1713733540315,
        current_return: 1.0002386640340366,
        initial_entry_price: 1.24337,
        is_closed_position: true,
        miner_hotkey: "5HgBDcx8Z9oEWrGQm7obH4aPE2M5YXWA6S6MP1HtFHguUqek",
        net_leverage: 0.0,
        open_ms: 1713320172599,
        orders: [
          {
            leverage: -0.02,
            order_type: "SHORT",
            order_uuid: "01af0294-b248-413e-9d86-a9c5af346378",
            price: 1.24337,
            price_sources: [],
            processed_ms: 1713320172597,
          },
          {
            leverage: -0.1,
            order_type: "SHORT",
            order_uuid: "531ac107-d69f-4fc5-ba15-b2d48679fa70",
            price: 1.24803,
            price_sources: [],
            processed_ms: 1713438494193,
          },
          {
            leverage: 0.115,
            order_type: "LONG",
            order_uuid: "0c69e273-2523-4fbc-9d58-8c205372d32c",
            price: 1.24510002,
            price_sources: [],
            processed_ms: 1713450123206,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "847ee024-43b6-4a1f-9cea-aded67a6b897",
            price: 1.23743,
            price_sources: [],
            processed_ms: 1713733540315,
          },
        ],
        position_type: "FLAT",
        position_uuid: "914ded54-54f6-4908-821a-91168b01db3b",
        return_at_close: 1.0002302620292587,
        trade_pair: ["GBPUSD", "GBP/USD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 63322.563636363644,
        close_ms: 1714032750680,
        current_return: 1.0012039946988316,
        initial_entry_price: 64815.9,
        is_closed_position: true,
        miner_hotkey: "5HgBDcx8Z9oEWrGQm7obH4aPE2M5YXWA6S6MP1HtFHguUqek",
        net_leverage: 0.0,
        open_ms: 1713649625869,
        orders: [
          {
            leverage: 0.1,
            order_type: "LONG",
            order_uuid: "7611186a-7a48-4183-8875-69064c22f1bc",
            price: 64815.9,
            price_sources: [],
            processed_ms: 1713649625864,
          },
          {
            leverage: -0.09,
            order_type: "SHORT",
            order_uuid: "8fa8b3a0-7ad2-407a-874b-d4bf2b6f5e23",
            price: 64909.0,
            price_sources: [],
            processed_ms: 1713656083388,
          },
          {
            leverage: 0.1,
            order_type: "LONG",
            order_uuid: "f84cc74c-1a0f-47fb-bc82-c018e891bf6d",
            price: 64833.82,
            price_sources: [],
            processed_ms: 1713658135081,
          },
          {
            leverage: 0.1,
            order_type: "LONG",
            order_uuid: "561a417d-232f-4ffe-b361-ccae79c036af",
            price: 64729.2,
            price_sources: [],
            processed_ms: 1713659788201,
          },
          {
            leverage: -0.1,
            order_type: "SHORT",
            order_uuid: "bc00ffca-5060-4623-8273-8d934c896757",
            price: 66306.0,
            price_sources: [],
            processed_ms: 1713795232924,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "2d2315d2-3093-4cff-b63a-fef51ab4f47f",
            price: 64032.0,
            price_sources: [],
            processed_ms: 1714032750680,
          },
        ],
        position_type: "FLAT",
        position_uuid: "7ae96f4a-91cb-4a72-a0f1-01c47a4623af",
        return_at_close: 1.000903633500422,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 64501.86,
        close_ms: 1714104677852,
        current_return: 1.0003738496843346,
        initial_entry_price: 64501.86,
        is_closed_position: true,
        miner_hotkey: "5HgBDcx8Z9oEWrGQm7obH4aPE2M5YXWA6S6MP1HtFHguUqek",
        net_leverage: 0.0,
        open_ms: 1714100391189,
        orders: [
          {
            leverage: -0.1,
            order_type: "SHORT",
            order_uuid: "afbb9201-3b49-4f0a-ab0b-be6f4e553699",
            price: 64501.86,
            price_sources: [],
            processed_ms: 1714100391186,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "6f2c6f27-10a1-41f2-8f2c-f71b19953fa2",
            price: 64260.72,
            price_sources: [],
            processed_ms: 1714104677852,
          },
        ],
        position_type: "FLAT",
        position_uuid: "423fc0a6-8fcb-4bae-b238-656e9eaa2836",
        return_at_close: 1.0002738122993662,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 196.102,
        close_ms: 1714126809979,
        current_return: 1.0001774586694678,
        initial_entry_price: 196.102,
        is_closed_position: true,
        miner_hotkey: "5HgBDcx8Z9oEWrGQm7obH4aPE2M5YXWA6S6MP1HtFHguUqek",
        net_leverage: 0.0,
        open_ms: 1714126294846,
        orders: [
          {
            leverage: 0.2,
            order_type: "LONG",
            order_uuid: "be86558f-d2ea-46a7-8be5-b74b3c97b7c4",
            price: 196.102,
            price_sources: [],
            processed_ms: 1714126294840,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "2fed3ff6-3d5e-491b-9fae-73f9b7a33954",
            price: 196.276,
            price_sources: [],
            processed_ms: 1714126809979,
          },
        ],
        position_type: "FLAT",
        position_uuid: "5902933c-d023-4416-a65e-ef25eddadc76",
        return_at_close: 1.0001634561850465,
        trade_pair: ["GBPJPY", "GBP/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 102.39,
        close_ms: 1714126919103,
        current_return: 1.0001445453657585,
        initial_entry_price: 102.39,
        is_closed_position: true,
        miner_hotkey: "5HgBDcx8Z9oEWrGQm7obH4aPE2M5YXWA6S6MP1HtFHguUqek",
        net_leverage: 0.0,
        open_ms: 1714126611822,
        orders: [
          {
            leverage: 0.2,
            order_type: "LONG",
            order_uuid: "a1996e8d-ab6d-4ad5-b708-b5f23e34ad61",
            price: 102.39,
            price_sources: [],
            processed_ms: 1714126611818,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "d00a343e-f49c-4b17-b89d-eae487120316",
            price: 102.464,
            price_sources: [],
            processed_ms: 1714126919103,
          },
        ],
        position_type: "FLAT",
        position_uuid: "a15672a0-2c26-4990-872a-3fcfbdc26824",
        return_at_close: 1.0001305433421235,
        trade_pair: ["AUDJPY", "AUD/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.65368,
        close_ms: 1714127475880,
        current_return: 1.000128503243177,
        initial_entry_price: 0.65368,
        is_closed_position: true,
        miner_hotkey: "5HgBDcx8Z9oEWrGQm7obH4aPE2M5YXWA6S6MP1HtFHguUqek",
        net_leverage: 0.0,
        open_ms: 1714126649756,
        orders: [
          {
            leverage: -0.2,
            order_type: "SHORT",
            order_uuid: "ebdb7b6e-f4b0-4024-b0a1-7f11a512086e",
            price: 0.65368,
            price_sources: [],
            processed_ms: 1714126649748,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "fc83f614-f87e-4d46-b4d4-d8cb74d9a295",
            price: 0.65326,
            price_sources: [],
            processed_ms: 1714127475880,
          },
        ],
        position_type: "FLAT",
        position_uuid: "e6fe932d-5f38-4824-8b76-56ecbc0f3ed3",
        return_at_close: 1.0001145014441317,
        trade_pair: ["AUDUSD", "AUD/USD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 102.43000000000002,
        close_ms: 1714130001615,
        current_return: 1.0001757297666698,
        initial_entry_price: 102.43,
        is_closed_position: true,
        miner_hotkey: "5HgBDcx8Z9oEWrGQm7obH4aPE2M5YXWA6S6MP1HtFHguUqek",
        net_leverage: 0.0,
        open_ms: 1714127132686,
        orders: [
          {
            leverage: 0.2,
            order_type: "LONG",
            order_uuid: "6dd88abb-80fe-46c1-b154-3a3c9ef7c76c",
            price: 102.43,
            price_sources: [],
            processed_ms: 1714127132678,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "e9472c01-dd81-46fb-bfbd-08a4eff1ddc2",
            price: 102.52,
            price_sources: [],
            processed_ms: 1714130001615,
          },
        ],
        position_type: "FLAT",
        position_uuid: "5bca3d12-887c-4094-a4e6-45e86295251f",
        return_at_close: 1.0001617273064531,
        trade_pair: ["AUDJPY", "AUD/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 1.09783,
        close_ms: 1714130183463,
        current_return: 1.0001676033629978,
        initial_entry_price: 1.09783,
        is_closed_position: true,
        miner_hotkey: "5HgBDcx8Z9oEWrGQm7obH4aPE2M5YXWA6S6MP1HtFHguUqek",
        net_leverage: 0.0,
        open_ms: 1714126632462,
        orders: [
          {
            leverage: 0.2,
            order_type: "LONG",
            order_uuid: "07a38198-9499-45b1-9a90-92c9ee4092a1",
            price: 1.09783,
            price_sources: [],
            processed_ms: 1714126632458,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "5059b7a8-7369-494c-a546-4ae94a796da6",
            price: 1.09875,
            price_sources: [],
            processed_ms: 1714130183463,
          },
        ],
        position_type: "FLAT",
        position_uuid: "2acc7de0-40f0-4cdc-8525-f67310eb6ae5",
        return_at_close: 1.0001536010165508,
        trade_pair: ["AUDNZD", "AUD/NZD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.8574999999999999,
        close_ms: 1714131827033,
        current_return: 1.0001486880466472,
        initial_entry_price: 0.8575,
        is_closed_position: true,
        miner_hotkey: "5HgBDcx8Z9oEWrGQm7obH4aPE2M5YXWA6S6MP1HtFHguUqek",
        net_leverage: 0.0,
        open_ms: 1714126770062,
        orders: [
          {
            leverage: -0.15,
            order_type: "SHORT",
            order_uuid: "c1d2b64f-1dd9-4654-bf59-a9ebb5ae89c7",
            price: 0.8575,
            price_sources: [],
            processed_ms: 1714126770053,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "6901c644-ce87-4a8d-b5f2-3f55d4dc3606",
            price: 0.85665,
            price_sources: [],
            processed_ms: 1714131827033,
          },
        ],
        position_type: "FLAT",
        position_uuid: "c2d7ad28-43d6-47f8-958f-e901d7988ae1",
        return_at_close: 1.0001381864854226,
        trade_pair: ["EURGBP", "EUR/GBP", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.6678139999999999,
        close_ms: 1714133260585,
        current_return: 1.0001706867897409,
        initial_entry_price: 0.66789,
        is_closed_position: true,
        miner_hotkey: "5HgBDcx8Z9oEWrGQm7obH4aPE2M5YXWA6S6MP1HtFHguUqek",
        net_leverage: 0.0,
        open_ms: 1714126754290,
        orders: [
          {
            leverage: 0.15,
            order_type: "LONG",
            order_uuid: "e6729b06-e305-40b1-a424-2084cd3d7cfa",
            price: 0.66789,
            price_sources: [],
            processed_ms: 1714126754286,
          },
          {
            leverage: 0.1,
            order_type: "LONG",
            order_uuid: "d98fb594-5bcc-4bf6-9684-a77da655bbe8",
            price: 0.6677,
            price_sources: [],
            processed_ms: 1714131181131,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "8cbc5473-6a22-458e-850b-e6a1582e1b3e",
            price: 0.66827,
            price_sources: [],
            processed_ms: 1714133260585,
          },
        ],
        position_type: "FLAT",
        position_uuid: "ccf4874a-28d6-44c1-8368-7035a9b7e52f",
        return_at_close: 1.000153183802722,
        trade_pair: ["CADCHF", "CAD/CHF", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.91211,
        close_ms: 1714133498591,
        current_return: 1.0001491048228832,
        initial_entry_price: 0.91211,
        is_closed_position: true,
        miner_hotkey: "5HgBDcx8Z9oEWrGQm7obH4aPE2M5YXWA6S6MP1HtFHguUqek",
        net_leverage: 0.0,
        open_ms: 1714126496496,
        orders: [
          {
            leverage: 0.2,
            order_type: "LONG",
            order_uuid: "ae4ecdda-5ce4-4141-beb5-cb2496580a69",
            price: 0.91211,
            price_sources: [],
            processed_ms: 1714126496491,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "54147686-a8ad-4cd2-9390-547a60eab461",
            price: 0.91279,
            price_sources: [],
            processed_ms: 1714133498591,
          },
        ],
        position_type: "FLAT",
        position_uuid: "7bf1fa6a-3715-4585-9e47-8ff80be9c4f8",
        return_at_close: 1.0001351027354157,
        trade_pair: ["USDCHF", "USD/CHF", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 1.25161,
        close_ms: 1714133784315,
        current_return: 1.0001326291736243,
        initial_entry_price: 1.25161,
        is_closed_position: true,
        miner_hotkey: "5HgBDcx8Z9oEWrGQm7obH4aPE2M5YXWA6S6MP1HtFHguUqek",
        net_leverage: 0.0,
        open_ms: 1714126312908,
        orders: [
          {
            leverage: -0.2,
            order_type: "SHORT",
            order_uuid: "1d3aa691-a34c-4299-9149-be7a994df2f4",
            price: 1.25161,
            price_sources: [],
            processed_ms: 1714126312424,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "8d0b4be2-c9d6-4890-971e-36bed3308953",
            price: 1.25078,
            price_sources: [],
            processed_ms: 1714133784315,
          },
        ],
        position_type: "FLAT",
        position_uuid: "f5cc7462-50aa-404e-a7ad-76d04b6b065a",
        return_at_close: 1.000118627316816,
        trade_pair: ["GBPUSD", "GBP/USD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.6906478698675648,
        close_ms: 1714133806029,
        current_return: 1.0248704821385686,
        initial_entry_price: 0.89189,
        is_closed_position: true,
        miner_hotkey: "5HgBDcx8Z9oEWrGQm7obH4aPE2M5YXWA6S6MP1HtFHguUqek",
        net_leverage: 0.0,
        open_ms: 1713164516527,
        orders: [
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "c91c6df2-ade0-46ad-8c5b-2a4b0057f0d0",
            price: 0.89189,
            price_sources: [],
            processed_ms: 1713164516526,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "56e3847e-9083-412e-bfce-6e522256f567",
            price: 0.8907698992,
            price_sources: [],
            processed_ms: 1713169283535,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "185fdd1c-d338-4334-9e8d-1ee825628eb8",
            price: 0.89056,
            price_sources: [],
            processed_ms: 1713176706064,
          },
          {
            leverage: 0.03,
            order_type: "LONG",
            order_uuid: "3eaf26e8-8702-4588-bf7e-1da125f40a9a",
            price: 0.8899952062,
            price_sources: [],
            processed_ms: 1713187146391,
          },
          {
            leverage: 0.2,
            order_type: "LONG",
            order_uuid: "c8a6c0f4-36f8-470e-a567-5cd695444bbc",
            price: 0.8854677671,
            price_sources: [],
            processed_ms: 1713235865768,
          },
          {
            leverage: -0.2,
            order_type: "SHORT",
            order_uuid: "14f14190-d7c0-4958-b7c0-6d81caf06035",
            price: 0.88684,
            price_sources: [],
            processed_ms: 1713321389000,
          },
          {
            leverage: 0.3,
            order_type: "LONG",
            order_uuid: "81a55c79-1972-4fdb-bd85-46f7bfacff0b",
            price: 0.88701,
            price_sources: [],
            processed_ms: 1713321679484,
          },
          {
            leverage: 0.1,
            order_type: "LONG",
            order_uuid: "3f44f7a2-b72a-4837-ae49-1a2701006246",
            price: 0.88649,
            price_sources: [],
            processed_ms: 1713438512969,
          },
          {
            leverage: 1.0,
            order_type: "LONG",
            order_uuid: "f9cc760d-0d2f-4f73-b0d6-4e04f62fb57e",
            price: 0.8854693269,
            price_sources: [],
            processed_ms: 1713450155791,
          },
          {
            leverage: 0.6,
            order_type: "LONG",
            order_uuid: "b4251beb-d955-445a-b3b2-a5ff8d6ca11b",
            price: 0.88394,
            price_sources: [],
            processed_ms: 1713473355958,
          },
          {
            leverage: 1.0,
            order_type: "LONG",
            order_uuid: "a17d4e93-21d6-4968-8880-90674f362b11",
            price: 0.8838953235,
            price_sources: [],
            processed_ms: 1713475008600,
          },
          {
            leverage: 2.0,
            order_type: "LONG",
            order_uuid: "87c2cd84-8151-4935-ad99-2c5edfd933f1",
            price: 0.88225,
            price_sources: [],
            processed_ms: 1713510431625,
          },
          {
            leverage: 3.0,
            order_type: "LONG",
            order_uuid: "8bd246cd-ee1d-45e3-97fc-d9cde3e3fc9b",
            price: 0.88259,
            price_sources: [],
            processed_ms: 1713546470497,
          },
          {
            leverage: 3.0,
            order_type: "LONG",
            order_uuid: "8695a723-f797-4f26-acb7-33f8f3e194fb",
            price: 0.882441689511495,
            price_sources: [],
            processed_ms: 1713548686638,
          },
          {
            leverage: -10.0,
            order_type: "SHORT",
            order_uuid: "bf922225-5b0f-4002-b88e-a8a3377f73f1",
            price: 0.885102223827926,
            price_sources: [],
            processed_ms: 1713751347950,
          },
          {
            leverage: -0.8,
            order_type: "SHORT",
            order_uuid: "d7948166-075b-4092-98c5-79c678140e15",
            price: 0.88292,
            price_sources: [],
            processed_ms: 1713795187738,
          },
          {
            leverage: -0.15,
            order_type: "SHORT",
            order_uuid: "9c127543-b098-43c1-90ef-45629fecca73",
            price: 0.892692157118622,
            price_sources: [],
            processed_ms: 1714043104376,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "a7bee83c-df90-4987-b86f-a5a4dabcc52a",
            price: 0.8923,
            price_sources: [],
            processed_ms: 1714133806029,
          },
        ],
        position_type: "FLAT",
        position_uuid: "256c8362-4f6a-4e95-8676-c3a544415820",
        return_at_close: 1.0240626792245469,
        trade_pair: ["AUDCAD", "AUD/CAD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: -206.319999999995,
        close_ms: 1714135959424,
        current_return: 1.110397805484964,
        initial_entry_price: 3022.08,
        is_closed_position: true,
        miner_hotkey: "5HgBDcx8Z9oEWrGQm7obH4aPE2M5YXWA6S6MP1HtFHguUqek",
        net_leverage: 0.0,
        open_ms: 1713438452283,
        orders: [
          {
            leverage: 6.0,
            order_type: "LONG",
            order_uuid: "e6e18db0-f025-4cf0-aee8-05db70408647",
            price: 3022.08,
            price_sources: [],
            processed_ms: 1713438452281,
          },
          {
            leverage: -3.0,
            order_type: "SHORT",
            order_uuid: "2fdb48c7-1e65-4c2a-8cef-2626332f5af7",
            price: 3074.88,
            price_sources: [],
            processed_ms: 1713457862893,
          },
          {
            leverage: -1.0,
            order_type: "SHORT",
            order_uuid: "54efebee-00af-4b3f-b6ed-bf0bfa38bede",
            price: 3066.64,
            price_sources: [],
            processed_ms: 1713458631568,
          },
          {
            leverage: -1.0,
            order_type: "SHORT",
            order_uuid: "88bc9f0d-9586-4e32-8b57-51c7cc63608d",
            price: 3061.53,
            price_sources: [],
            processed_ms: 1713473511436,
          },
          {
            leverage: -0.5,
            order_type: "SHORT",
            order_uuid: "83498985-a7a9-4cae-8247-fc95f9c43378",
            price: 3089.54,
            price_sources: [],
            processed_ms: 1713532096703,
          },
          {
            leverage: -0.2,
            order_type: "SHORT",
            order_uuid: "fa974acb-f49e-4501-8ccd-51f18fba1f53",
            price: 3193.46,
            price_sources: [],
            processed_ms: 1713795851691,
          },
          {
            leverage: -0.2,
            order_type: "SHORT",
            order_uuid: "b4350200-940a-4ee5-8058-80cc1cdb4a50",
            price: 3084.2,
            price_sources: [],
            processed_ms: 1714040930661,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "f53aa3e9-ec84-43b3-a1cc-642f3d9d3c7a",
            price: 3129.99,
            price_sources: [],
            processed_ms: 1714135959424,
          },
        ],
        position_type: "FLAT",
        position_uuid: "56cf56c2-4122-4374-9242-8efa025e2e74",
        return_at_close: 1.103735418652054,
        trade_pair: ["ETHUSD", "ETH/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 96.25900000000014,
        close_ms: 1714477673272,
        current_return: 1.000317295216445,
        initial_entry_price: 93.257,
        is_closed_position: true,
        miner_hotkey: "5HgBDcx8Z9oEWrGQm7obH4aPE2M5YXWA6S6MP1HtFHguUqek",
        net_leverage: 0.0,
        open_ms: 1714126385851,
        orders: [
          {
            leverage: -0.2,
            order_type: "SHORT",
            order_uuid: "e49317d0-07f1-4a56-90e4-85997fcafbbd",
            price: 93.257,
            price_sources: [],
            processed_ms: 1714126385847,
          },
          {
            leverage: 0.19,
            order_type: "LONG",
            order_uuid: "48f648da-4f89-41f0-b1e4-61c83df7dd79",
            price: 93.099,
            price_sources: [],
            processed_ms: 1714366196914,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "0f0c95ff-6d48-4013-b470-5f43828c5c73",
            price: 93.3,
            price_sources: [],
            processed_ms: 1714477673272,
          },
        ],
        position_type: "FLAT",
        position_uuid: "39b555ec-cbc3-489b-b276-3224244acb1b",
        return_at_close: 1.000303290774312,
        trade_pair: ["NZDJPY", "NZD/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.5877600000000005,
        close_ms: 1714964282895,
        current_return: 1.0002133844111767,
        initial_entry_price: 0.59517,
        is_closed_position: true,
        miner_hotkey: "5HgBDcx8Z9oEWrGQm7obH4aPE2M5YXWA6S6MP1HtFHguUqek",
        net_leverage: 0.0,
        open_ms: 1714126403124,
        orders: [
          {
            leverage: 0.2,
            order_type: "LONG",
            order_uuid: "14ff2e2c-fbbb-4d25-aa49-b1aad1d74c31",
            price: 0.59517,
            price_sources: [],
            processed_ms: 1714126403117,
          },
          {
            leverage: -0.19,
            order_type: "SHORT",
            order_uuid: "7305e350-b188-4a3e-be8c-35da3efd8c5f",
            price: 0.59556,
            price_sources: [],
            processed_ms: 1714136450919,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "325b90db-c701-4e53-a28b-11666d11cd26",
            price: 0.60046,
            price_sources: [],
            processed_ms: 1714964282895,
          },
        ],
        position_type: "FLAT",
        position_uuid: "3d9afc1e-1377-46aa-9f19-49d5828cd476",
        return_at_close: 1.000199381423795,
        trade_pair: ["NZDUSD", "NZD/USD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 213.43900000000014,
        close_ms: 1715272624793,
        current_return: 1.0036877137897586,
        initial_entry_price: 156.696,
        is_closed_position: true,
        miner_hotkey: "5HgBDcx8Z9oEWrGQm7obH4aPE2M5YXWA6S6MP1HtFHguUqek",
        net_leverage: 0.0,
        open_ms: 1714126566436,
        orders: [
          {
            leverage: -0.2,
            order_type: "SHORT",
            order_uuid: "48f79db1-e353-469c-b2ad-7a0737658052",
            price: 156.696,
            price_sources: [],
            processed_ms: 1714126566432,
          },
          {
            leverage: -0.2,
            order_type: "SHORT",
            order_uuid: "939d27a2-334f-483a-b0f8-a310cd4f971e",
            price: 156.77,
            price_sources: [],
            processed_ms: 1714132877138,
          },
          {
            leverage: 0.39,
            order_type: "LONG",
            order_uuid: "4d160f0c-c4a1-4d77-bb84-4dc54570b88c",
            price: 155.279,
            price_sources: [],
            processed_ms: 1714366161822,
          },
          {
            leverage: 0.1,
            order_type: "LONG",
            order_uuid: "27e3a5ad-503e-4560-a557-30a4b5c119ee",
            price: 155.654,
            price_sources: [],
            processed_ms: 1715272624793,
          },
        ],
        position_type: "FLAT",
        position_uuid: "cb4ba5ae-d92b-4e44-96a0-c263db09e794",
        return_at_close: 1.0036596105337725,
        trade_pair: ["USDJPY", "USD/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.9046200000000001,
        close_ms: 1715776286252,
        current_return: 1.0481307068161225,
        initial_entry_price: 0.90462,
        is_closed_position: true,
        miner_hotkey: "5HgBDcx8Z9oEWrGQm7obH4aPE2M5YXWA6S6MP1HtFHguUqek",
        net_leverage: 0.0,
        open_ms: 1715776074853,
        orders: [
          {
            leverage: -14.0,
            order_type: "SHORT",
            order_uuid: "3ffe7d23-fd69-445e-a1af-e4503edde4bd",
            price: 0.90462,
            price_sources: [],
            processed_ms: 1715776073988,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "9051a45a-ed3f-4e5e-9a02-73143360130e",
            price: 0.90151,
            price_sources: [],
            processed_ms: 1715776286252,
          },
        ],
        position_type: "FLAT",
        position_uuid: "1b3c608f-d56e-4c6e-ba3b-145ff4aeb8e7",
        return_at_close: 1.0471035387234426,
        trade_pair: ["USDCHF", "USD/CHF", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.7999098965720407,
        close_ms: 1716301848991,
        current_return: 1.0003997853874367,
        initial_entry_price: 0.812810593661658,
        is_closed_position: true,
        miner_hotkey: "5HgBDcx8Z9oEWrGQm7obH4aPE2M5YXWA6S6MP1HtFHguUqek",
        net_leverage: 0.0,
        open_ms: 1714126354412,
        orders: [
          {
            leverage: 0.2,
            order_type: "LONG",
            order_uuid: "d40cae8e-1032-4e1a-a2b6-826db773a0ad",
            price: 0.812810593661658,
            price_sources: [],
            processed_ms: 1714126354409,
          },
          {
            leverage: 0.1,
            order_type: "LONG",
            order_uuid: "34dec46c-123d-4ca9-85ed-f461a0548d4d",
            price: 0.81245,
            price_sources: [],
            processed_ms: 1714131846923,
          },
          {
            leverage: -0.29,
            order_type: "SHORT",
            order_uuid: "eb965895-a5ad-475d-a67e-fb843818f2af",
            price: 0.813131102643487,
            price_sources: [],
            processed_ms: 1714136806512,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "4db89d72-caf3-476e-8e51-8362736ebb05",
            price: 0.832404876382008,
            price_sources: [],
            processed_ms: 1716301848991,
          },
        ],
        position_type: "FLAT",
        position_uuid: "3ad7d654-3854-42d7-b4ba-da9b00310792",
        return_at_close: 1.0003787769919434,
        trade_pair: ["NZDCAD", "NZD/CAD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 63820.21,
        close_ms: 1718389064577,
        current_return: 0.9999803562225822,
        initial_entry_price: 63820.21,
        is_closed_position: true,
        miner_hotkey: "5HgBDcx8Z9oEWrGQm7obH4aPE2M5YXWA6S6MP1HtFHguUqek",
        net_leverage: 0.0,
        open_ms: 1714834882196,
        orders: [
          {
            leverage: -0.001,
            order_type: "SHORT",
            order_uuid: "4fd1650c-bcef-4b45-a2b0-f33a31140a41",
            price: 63820.21,
            price_sources: [],
            processed_ms: 1714834881981,
          },
          {
            leverage: 0.1,
            order_type: "LONG",
            order_uuid: "eb5af7a2-3df6-4329-9085-01801a78ea50",
            price: 65073.88,
            price_sources: [],
            processed_ms: 1718389064577,
          },
        ],
        position_type: "FLAT",
        position_uuid: "fe36a178-6cb2-4401-bfc9-a5489fe5f8fb",
        return_at_close: 0.9999793562422259,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 160.5195,
        close_ms: 1719498444030,
        current_return: 1.0000855815025589,
        initial_entry_price: 160.5195,
        is_closed_position: true,
        miner_hotkey: "5HgBDcx8Z9oEWrGQm7obH4aPE2M5YXWA6S6MP1HtFHguUqek",
        net_leverage: 0.0,
        open_ms: 1719497844344,
        orders: [
          {
            leverage: 0.157,
            order_type: "LONG",
            order_uuid: "712289ca-6a7a-43ca-9196-3954f30eb85e",
            price: 160.5195,
            price_sources: [],
            processed_ms: 1719497841048,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "0f7174d1-4b33-403b-a701-f805dc13a99c",
            price: 160.607,
            price_sources: [],
            processed_ms: 1719498444030,
          },
        ],
        position_type: "FLAT",
        position_uuid: "5b6b9b15-4cb0-469b-ad4a-30810fa636f7",
        return_at_close: 1.000074590562018,
        trade_pair: ["USDJPY", "USD/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 160.55217499999998,
        close_ms: 1719500855586,
        current_return: 1.0002640984846836,
        initial_entry_price: 160.5935,
        is_closed_position: true,
        miner_hotkey: "5HgBDcx8Z9oEWrGQm7obH4aPE2M5YXWA6S6MP1HtFHguUqek",
        net_leverage: 0.0,
        open_ms: 1719498474785,
        orders: [
          {
            leverage: 0.215,
            order_type: "LONG",
            order_uuid: "a7c4f317-5fe0-4adb-8427-8f51b7f6aa68",
            price: 160.5935,
            price_sources: [],
            processed_ms: 1719498473154,
          },
          {
            leverage: 0.285,
            order_type: "LONG",
            order_uuid: "73374020-af61-4590-8bdb-f613ab0c23b2",
            price: 160.521,
            price_sources: [],
            processed_ms: 1719499855362,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "2b0f9ebf-3db9-4fbc-b011-efa9a6dd37e3",
            price: 160.637,
            price_sources: [],
            processed_ms: 1719500855586,
          },
        ],
        position_type: "FLAT",
        position_uuid: "b07d9ec1-0843-4c18-b243-ad71690a65a0",
        return_at_close: 1.0002290892412367,
        trade_pair: ["USDJPY", "USD/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 160.629,
        close_ms: 1719503644576,
        current_return: 1.0001200032372735,
        initial_entry_price: 160.629,
        is_closed_position: true,
        miner_hotkey: "5HgBDcx8Z9oEWrGQm7obH4aPE2M5YXWA6S6MP1HtFHguUqek",
        net_leverage: 0.0,
        open_ms: 1719500888726,
        orders: [
          {
            leverage: 0.244,
            order_type: "LONG",
            order_uuid: "e7738e87-7ad5-45cb-baff-34c23e843478",
            price: 160.629,
            price_sources: [],
            processed_ms: 1719500886100,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "d200de6d-a7ed-4d2b-a359-c6321c7b4015",
            price: 160.708,
            price_sources: [],
            processed_ms: 1719503644576,
          },
        ],
        position_type: "FLAT",
        position_uuid: "83534bb5-2ef9-45d5-ba3b-9945ab438253",
        return_at_close: 1.0001029211876182,
        trade_pair: ["USDJPY", "USD/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 160.7015,
        close_ms: 1719514640470,
        current_return: 1.0001260940314807,
        initial_entry_price: 160.7015,
        is_closed_position: true,
        miner_hotkey: "5HgBDcx8Z9oEWrGQm7obH4aPE2M5YXWA6S6MP1HtFHguUqek",
        net_leverage: 0.0,
        open_ms: 1719503674472,
        orders: [
          {
            leverage: 0.237,
            order_type: "LONG",
            order_uuid: "fe8f82c7-a14a-49f4-8585-a74710ab2be1",
            price: 160.7015,
            price_sources: [],
            processed_ms: 1719503673818,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "704a097f-b55e-4193-80d2-587ba7e137b9",
            price: 160.787,
            price_sources: [],
            processed_ms: 1719514640470,
          },
        ],
        position_type: "FLAT",
        position_uuid: "c9b01085-9892-4eea-b08a-5c30119d83e0",
        return_at_close: 1.0001095019395807,
        trade_pair: ["USDJPY", "USD/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 160.74517375366568,
        close_ms: 1719535879675,
        current_return: 1.0001880438906963,
        initial_entry_price: 160.763,
        is_closed_position: true,
        miner_hotkey: "5HgBDcx8Z9oEWrGQm7obH4aPE2M5YXWA6S6MP1HtFHguUqek",
        net_leverage: 0.0,
        open_ms: 1719514671911,
        orders: [
          {
            leverage: 0.184,
            order_type: "LONG",
            order_uuid: "48abc49e-9a24-41ed-88bf-50675d553e2c",
            price: 160.763,
            price_sources: [],
            processed_ms: 1719514671728,
          },
          {
            leverage: 0.229,
            order_type: "LONG",
            order_uuid: "ac6be0e5-4cc6-48b4-9be2-016b3667bf33",
            price: 160.7475,
            price_sources: [],
            processed_ms: 1719522018936,
          },
          {
            leverage: 0.269,
            order_type: "LONG",
            order_uuid: "24e88728-25e3-4c90-b238-d048375bce4b",
            price: 160.731,
            price_sources: [],
            processed_ms: 1719522066300,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "7f995f42-70c9-4e58-91c3-343b9a84f4ab",
            price: 160.78949999999998,
            price_sources: [],
            processed_ms: 1719535879675,
          },
        ],
        position_type: "FLAT",
        position_uuid: "b7170291-1a8a-40c1-aaf1-0fe745af6acd",
        return_at_close: 1.000140294913481,
        trade_pair: ["USDJPY", "USD/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 160.808,
        close_ms: 1719536013836,
        current_return: 1.0000892928212526,
        initial_entry_price: 160.808,
        is_closed_position: true,
        miner_hotkey: "5HgBDcx8Z9oEWrGQm7obH4aPE2M5YXWA6S6MP1HtFHguUqek",
        net_leverage: 0.0,
        open_ms: 1719535909219,
        orders: [
          {
            leverage: 0.166,
            order_type: "LONG",
            order_uuid: "891c3e6c-7c47-435e-8023-909e0977e97f",
            price: 160.808,
            price_sources: [],
            processed_ms: 1719535909147,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "45f42ed9-730e-4cff-a46e-0dc01bb278d8",
            price: 160.8945,
            price_sources: [],
            processed_ms: 1719536013836,
          },
        ],
        position_type: "FLAT",
        position_uuid: "b6cb2e4c-cfe9-41cb-8ee0-1195104cd95b",
        return_at_close: 1.00007767178367,
        trade_pair: ["USDJPY", "USD/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 160.882,
        close_ms: 1719536104783,
        current_return: 1.0001084459417462,
        initial_entry_price: 160.882,
        is_closed_position: true,
        miner_hotkey: "5HgBDcx8Z9oEWrGQm7obH4aPE2M5YXWA6S6MP1HtFHguUqek",
        net_leverage: 0.0,
        open_ms: 1719536043130,
        orders: [
          {
            leverage: 0.239,
            order_type: "LONG",
            order_uuid: "422e34bc-42b2-48d1-a78b-d17d833c797e",
            price: 160.882,
            price_sources: [],
            processed_ms: 1719536042841,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "b1059d55-bc8b-4827-93df-520e3a8a681d",
            price: 160.955,
            price_sources: [],
            processed_ms: 1719536104783,
          },
        ],
        position_type: "FLAT",
        position_uuid: "32b751ce-3e58-4021-8d6b-ff0df48dd31b",
        return_at_close: 1.0000917141274455,
        trade_pair: ["USDJPY", "USD/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 160.9485,
        close_ms: 1719536332196,
        current_return: 1.0001723781209517,
        initial_entry_price: 160.9485,
        is_closed_position: true,
        miner_hotkey: "5HgBDcx8Z9oEWrGQm7obH4aPE2M5YXWA6S6MP1HtFHguUqek",
        net_leverage: 0.0,
        open_ms: 1719536134855,
        orders: [
          {
            leverage: 0.289,
            order_type: "LONG",
            order_uuid: "04f8a1b6-337f-4f79-bc4c-57bc20da70f5",
            price: 160.9485,
            price_sources: [],
            processed_ms: 1719536134820,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "c742e6f7-d531-46ea-9d09-b19bf4dc9d75",
            price: 161.0445,
            price_sources: [],
            processed_ms: 1719536332196,
          },
        ],
        position_type: "FLAT",
        position_uuid: "f2e9114d-ffc3-4f6e-8648-c58d236a525c",
        return_at_close: 1.0001521446337425,
        trade_pair: ["USDJPY", "USD/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 161.02769334719335,
        close_ms: 1719537918916,
        current_return: 1.0002786544270357,
        initial_entry_price: 161.0615,
        is_closed_position: true,
        miner_hotkey: "5HgBDcx8Z9oEWrGQm7obH4aPE2M5YXWA6S6MP1HtFHguUqek",
        net_leverage: 0.0,
        open_ms: 1719536360916,
        orders: [
          {
            leverage: 0.279,
            order_type: "LONG",
            order_uuid: "fb181783-5026-4283-af6e-1dd00ba2e0ad",
            price: 161.0615,
            price_sources: [],
            processed_ms: 1719536360895,
          },
          {
            leverage: 0.202,
            order_type: "LONG",
            order_uuid: "1ac6606c-cdd3-48f1-bd17-e756873dbf01",
            price: 160.981,
            price_sources: [],
            processed_ms: 1719536651342,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "d9a466aa-f22b-4716-9b2c-712761cbc2f5",
            price: 161.121,
            price_sources: [],
            processed_ms: 1719537918916,
          },
        ],
        position_type: "FLAT",
        position_uuid: "72749691-818e-450e-9866-ee3da83eec57",
        return_at_close: 1.000244975044741,
        trade_pair: ["USDJPY", "USD/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 161.106,
        close_ms: 1719538213147,
        current_return: 1.0002080121162464,
        initial_entry_price: 161.106,
        is_closed_position: true,
        miner_hotkey: "5HgBDcx8Z9oEWrGQm7obH4aPE2M5YXWA6S6MP1HtFHguUqek",
        net_leverage: 0.0,
        open_ms: 1719537948790,
        orders: [
          {
            leverage: 0.236,
            order_type: "LONG",
            order_uuid: "acf708f5-eebf-4805-aad8-b1ffec80e132",
            price: 161.106,
            price_sources: [],
            processed_ms: 1719537948768,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "10d94cb4-0ab4-4a3f-8f39-3a0d8d41aaf3",
            price: 161.248,
            price_sources: [],
            processed_ms: 1719538213147,
          },
        ],
        position_type: "FLAT",
        position_uuid: "1d43bc21-ae34-4ec1-8b13-d7a954236f80",
        return_at_close: 1.0001914886798864,
        trade_pair: ["USDJPY", "USD/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 161.01951633259097,
        close_ms: 1719548611537,
        current_return: 1.0007642575175435,
        initial_entry_price: 161.23950000000002,
        is_closed_position: true,
        miner_hotkey: "5HgBDcx8Z9oEWrGQm7obH4aPE2M5YXWA6S6MP1HtFHguUqek",
        net_leverage: 0.0,
        open_ms: 1719538242894,
        orders: [
          {
            leverage: 0.235,
            order_type: "LONG",
            order_uuid: "e939e298-c213-4d36-8beb-705155394a5b",
            price: 161.23950000000002,
            price_sources: [],
            processed_ms: 1719538242858,
          },
          {
            leverage: 0.185,
            order_type: "LONG",
            order_uuid: "81173b5f-8ae6-443e-8623-293d53aed1b3",
            price: 161.166,
            price_sources: [],
            processed_ms: 1719538364861,
          },
          {
            leverage: 0.168,
            order_type: "LONG",
            order_uuid: "eaadc656-f89c-4902-ae90-e538827a63df",
            price: 160.978,
            price_sources: [],
            processed_ms: 1719538861530,
          },
          {
            leverage: 0.216,
            order_type: "LONG",
            order_uuid: "85c50d4d-a9c4-46d5-951c-f62c9271f681",
            price: 160.9965,
            price_sources: [],
            processed_ms: 1719539281299,
          },
          {
            leverage: 0.293,
            order_type: "LONG",
            order_uuid: "5c0eb075-720c-4d30-9387-00a0229ad2b6",
            price: 160.936,
            price_sources: [],
            processed_ms: 1719540873053,
          },
          {
            leverage: 0.25,
            order_type: "LONG",
            order_uuid: "85fbcb91-7380-4e5b-be67-8299857da16f",
            price: 160.85,
            price_sources: [],
            processed_ms: 1719542199013,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "afe21a7f-4c77-4db4-ba1a-4aeefe6d909f",
            price: 161.111,
            price_sources: [],
            processed_ms: 1719548611537,
          },
        ],
        position_type: "FLAT",
        position_uuid: "3ff539c6-d5a9-458c-8bce-a5b6bdf0dd0f",
        return_at_close: 1.000669895455702,
        trade_pair: ["USDJPY", "USD/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 160.78940356977284,
        close_ms: 1719588743967,
        current_return: 1.0008648407920056,
        initial_entry_price: 161.11,
        is_closed_position: true,
        miner_hotkey: "5HgBDcx8Z9oEWrGQm7obH4aPE2M5YXWA6S6MP1HtFHguUqek",
        net_leverage: 0.0,
        open_ms: 1719548640982,
        orders: [
          {
            leverage: 0.173,
            order_type: "LONG",
            order_uuid: "fb95e937-8b55-478a-9574-7927a8420226",
            price: 161.11,
            price_sources: [],
            processed_ms: 1719548640859,
          },
          {
            leverage: 0.232,
            order_type: "LONG",
            order_uuid: "61b49a05-739d-4ee0-8de0-fdc34d32d773",
            price: 161.0575,
            price_sources: [],
            processed_ms: 1719549477412,
          },
          {
            leverage: 0.156,
            order_type: "LONG",
            order_uuid: "5d27ddc1-5661-479d-868d-a752f9c6c193",
            price: 160.983,
            price_sources: [],
            processed_ms: 1719551578820,
          },
          {
            leverage: 0.267,
            order_type: "LONG",
            order_uuid: "1976a5ea-17fa-4591-8fed-6494619529e0",
            price: 160.901,
            price_sources: [],
            processed_ms: 1719552852298,
          },
          {
            leverage: 0.167,
            order_type: "LONG",
            order_uuid: "5a03e5a6-2d98-4245-844c-5a81130d0655",
            price: 160.84550000000002,
            price_sources: [],
            processed_ms: 1719560644632,
          },
          {
            leverage: 0.168,
            order_type: "LONG",
            order_uuid: "67ecfc36-ce52-45f1-af70-2b6e5f8c9002",
            price: 160.777,
            price_sources: [],
            processed_ms: 1719565761243,
          },
          {
            leverage: 0.264,
            order_type: "LONG",
            order_uuid: "bf9e19d5-1cb6-4b3d-8954-036e2e944eb6",
            price: 160.719,
            price_sources: [],
            processed_ms: 1719567265360,
          },
          {
            leverage: 0.204,
            order_type: "LONG",
            order_uuid: "13017a0c-3387-489a-899e-8f2bf4a5ebfa",
            price: 160.645,
            price_sources: [],
            processed_ms: 1719570407244,
          },
          {
            leverage: 0.296,
            order_type: "LONG",
            order_uuid: "17950ca7-4eb4-42d3-a001-c5081b1af1f7",
            price: 160.558,
            price_sources: [],
            processed_ms: 1719570838611,
          },
          {
            leverage: 0.23,
            order_type: "LONG",
            order_uuid: "4d580d6c-d0ff-46b7-9b84-b18b626704c8",
            price: 160.492,
            price_sources: [],
            processed_ms: 1719572324957,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "c769bc90-5436-4d63-80c9-cb7be097d406",
            price: 160.854,
            price_sources: [],
            processed_ms: 1719588743967,
          },
        ],
        position_type: "FLAT",
        position_uuid: "ef80c4d6-cd08-43f8-b2fe-237ba6d24c66",
        return_at_close: 1.0007137202096945,
        trade_pair: ["USDJPY", "USD/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 161.472,
        close_ms: 1719927499184,
        current_return: 0.9998238858051884,
        initial_entry_price: 161.472,
        is_closed_position: true,
        miner_hotkey: "5HgBDcx8Z9oEWrGQm7obH4aPE2M5YXWA6S6MP1HtFHguUqek",
        net_leverage: 0.0,
        open_ms: 1719926679549,
        orders: [
          {
            leverage: 0.2154356913985463,
            order_type: "LONG",
            order_uuid: "384b751f-2e27-4daa-8dc5-ae03be9fdd9a",
            price: 161.472,
            price_sources: [],
            processed_ms: 1719926679528,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "93168d58-b955-49af-96f4-e7dfa5158f25",
            price: 161.34,
            price_sources: [],
            processed_ms: 1719927499184,
          },
        ],
        position_type: "FLAT",
        position_uuid: "384b751f-2e27-4daa-8dc5-ae03be9fdd9a",
        return_at_close: 0.9998088079626803,
        trade_pair: ["USDJPY", "USD/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 161.333,
        close_ms: 1719929756909,
        current_return: 1.0003113867623037,
        initial_entry_price: 161.333,
        is_closed_position: true,
        miner_hotkey: "5HgBDcx8Z9oEWrGQm7obH4aPE2M5YXWA6S6MP1HtFHguUqek",
        net_leverage: 0.0,
        open_ms: 1719927598280,
        orders: [
          {
            leverage: 0.24269063054459558,
            order_type: "LONG",
            order_uuid: "c29b5af8-9326-4c54-8891-a8e7b95c4d06",
            price: 161.333,
            price_sources: [],
            processed_ms: 1719927598264,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "8d6715bc-e0f8-4d5a-9073-a07c58203c00",
            price: 161.54,
            price_sources: [],
            processed_ms: 1719929756909,
          },
        ],
        position_type: "FLAT",
        position_uuid: "c29b5af8-9326-4c54-8891-a8e7b95c4d06",
        return_at_close: 1.0002943931282202,
        trade_pair: ["USDJPY", "USD/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 161.460528635792,
        close_ms: 1719931372849,
        current_return: 1.0001595499012021,
        initial_entry_price: 161.543,
        is_closed_position: true,
        miner_hotkey: "5HgBDcx8Z9oEWrGQm7obH4aPE2M5YXWA6S6MP1HtFHguUqek",
        net_leverage: 0.0,
        open_ms: 1719929775056,
        orders: [
          {
            leverage: 0.2707351329509702,
            order_type: "LONG",
            order_uuid: "795419bf-810f-4143-952a-e72146b7cf82",
            price: 161.543,
            price_sources: [],
            processed_ms: 1719929774962,
          },
          {
            leverage: 0.2728842977048862,
            order_type: "LONG",
            order_uuid: "922f44de-eeeb-4301-a5e4-ab5c4a879153",
            price: 161.493,
            price_sources: [],
            processed_ms: 1719929975125,
          },
          {
            leverage: 0.28787666234195786,
            order_type: "LONG",
            order_uuid: "08ff0183-fc09-4df7-a2de-c3ca06758f91",
            price: 161.425,
            price_sources: [],
            processed_ms: 1719930626380,
          },
          {
            leverage: 0.2217418659004891,
            order_type: "LONG",
            order_uuid: "6f9ea766-3b6a-4ddd-9b4e-3c26824d323a",
            price: 161.366,
            price_sources: [],
            processed_ms: 1719930763958,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "5fc90c1b-c162-4271-948f-3f701872b857",
            price: 161.485,
            price_sources: [],
            processed_ms: 1719931372849,
          },
        ],
        position_type: "FLAT",
        position_uuid: "795419bf-810f-4143-952a-e72146b7cf82",
        return_at_close: 1.0000858114809983,
        trade_pair: ["USDJPY", "USD/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 161.48404356144727,
        close_ms: 1719933832752,
        current_return: 1.0002162491775424,
        initial_entry_price: 161.487,
        is_closed_position: true,
        miner_hotkey: "5HgBDcx8Z9oEWrGQm7obH4aPE2M5YXWA6S6MP1HtFHguUqek",
        net_leverage: 0.0,
        open_ms: 1719931391079,
        orders: [
          {
            leverage: 0.2653692509767063,
            order_type: "LONG",
            order_uuid: "39cb3a61-485f-45e0-bfb3-cc2b7c8d41d9",
            price: 161.487,
            price_sources: [],
            processed_ms: 1719931390976,
          },
          {
            leverage: 0.24075758062145614,
            order_type: "LONG",
            order_uuid: "98356449-6912-4181-bc21-01f0aec6d643",
            price: 161.538,
            price_sources: [],
            processed_ms: 1719931528580,
          },
          {
            leverage: 0.2700986654669599,
            order_type: "LONG",
            order_uuid: "5dc7608b-a1fc-4e1a-b48f-5a2b58f54eef",
            price: 161.48,
            price_sources: [],
            processed_ms: 1719931846742,
          },
          {
            leverage: 0.1949894602380932,
            order_type: "LONG",
            order_uuid: "15c1aace-ddab-4e58-b219-e9e3c7d45d0b",
            price: 161.419,
            price_sources: [],
            processed_ms: 1719932106256,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "a22dc19d-bb7e-4c80-9bc1-6a4fdf60fd6d",
            price: 161.52,
            price_sources: [],
            processed_ms: 1719933832752,
          },
        ],
        position_type: "FLAT",
        position_uuid: "39cb3a61-485f-45e0-bfb3-cc2b7c8d41d9",
        return_at_close: 1.0001482494288207,
        trade_pair: ["USDJPY", "USD/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 161.4741885262254,
        close_ms: 1719965946605,
        current_return: 1.0002192521860307,
        initial_entry_price: 161.529,
        is_closed_position: true,
        miner_hotkey: "5HgBDcx8Z9oEWrGQm7obH4aPE2M5YXWA6S6MP1HtFHguUqek",
        net_leverage: 0.0,
        open_ms: 1719933850047,
        orders: [
          {
            leverage: 0.2482388661864559,
            order_type: "LONG",
            order_uuid: "4f07d1e1-019f-4df0-83ae-f5561693a6d0",
            price: 161.529,
            price_sources: [],
            processed_ms: 1719933849868,
          },
          {
            leverage: 0.24050381964212453,
            order_type: "LONG",
            order_uuid: "b9a6f951-697d-483c-9c1c-3b0caf4c03be",
            price: 161.468,
            price_sources: [],
            processed_ms: 1719943861493,
          },
          {
            leverage: 0.16334027005530902,
            order_type: "LONG",
            order_uuid: "7a3b5a16-5b75-4ce8-a9f1-1f105db8813e",
            price: 161.4,
            price_sources: [],
            processed_ms: 1719954202482,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "9638fecb-e162-4640-9f66-00d6e37bdd3b",
            price: 161.5285,
            price_sources: [],
            processed_ms: 1719965946605,
          },
        ],
        position_type: "FLAT",
        position_uuid: "4f07d1e1-019f-4df0-83ae-f5561693a6d0",
        return_at_close: 1.0001735963711758,
        trade_pair: ["USDJPY", "USD/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 161.52499999999998,
        close_ms: 1719981019671,
        current_return: 1.0002348188929047,
        initial_entry_price: 161.525,
        is_closed_position: true,
        miner_hotkey: "5HgBDcx8Z9oEWrGQm7obH4aPE2M5YXWA6S6MP1HtFHguUqek",
        net_leverage: 0.0,
        open_ms: 1719965966061,
        orders: [
          {
            leverage: 0.2167378381510554,
            order_type: "LONG",
            order_uuid: "b4ac1e76-ba2f-4423-92a1-74dc5143c43c",
            price: 161.525,
            price_sources: [],
            processed_ms: 1719965965920,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "86e35918-a2ff-45c6-aa0a-2b22e7ea6369",
            price: 161.7,
            price_sources: [],
            processed_ms: 1719981019671,
          },
        ],
        position_type: "FLAT",
        position_uuid: "b4ac1e76-ba2f-4423-92a1-74dc5143c43c",
        return_at_close: 1.0002196436816444,
        trade_pair: ["USDJPY", "USD/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 161.65,
        close_ms: 1719981729953,
        current_return: 1.0001305267847773,
        initial_entry_price: 161.65,
        is_closed_position: true,
        miner_hotkey: "5HgBDcx8Z9oEWrGQm7obH4aPE2M5YXWA6S6MP1HtFHguUqek",
        net_leverage: 0.0,
        open_ms: 1719981036779,
        orders: [
          {
            leverage: 0.297178236046038,
            order_type: "LONG",
            order_uuid: "034f89a7-0a96-4aea-8d31-b288714a1e75",
            price: 161.65,
            price_sources: [],
            processed_ms: 1719981036728,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "7b8cd131-e0bc-440b-9c70-883c6f591004",
            price: 161.721,
            price_sources: [],
            processed_ms: 1719981729953,
          },
        ],
        position_type: "FLAT",
        position_uuid: "034f89a7-0a96-4aea-8d31-b288714a1e75",
        return_at_close: 1.0001097215929737,
        trade_pair: ["USDJPY", "USD/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 161.71550000000002,
        close_ms: 1719984035790,
        current_return: 1.000136843757513,
        initial_entry_price: 161.71550000000002,
        is_closed_position: true,
        miner_hotkey: "5HgBDcx8Z9oEWrGQm7obH4aPE2M5YXWA6S6MP1HtFHguUqek",
        net_leverage: 0.0,
        open_ms: 1719981747888,
        orders: [
          {
            leverage: 0.23172520071333444,
            order_type: "LONG",
            order_uuid: "48870e40-86a1-4384-911a-375304fea369",
            price: 161.71550000000002,
            price_sources: [],
            processed_ms: 1719981747711,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "4cf17fcc-6143-4caf-81e8-db3bc24002d1",
            price: 161.811,
            price_sources: [],
            processed_ms: 1719984035790,
          },
        ],
        position_type: "FLAT",
        position_uuid: "48870e40-86a1-4384-911a-375304fea369",
        return_at_close: 1.000120620773753,
        trade_pair: ["USDJPY", "USD/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 161.813,
        close_ms: 1719987190076,
        current_return: 1.0000967320838654,
        initial_entry_price: 161.813,
        is_closed_position: true,
        miner_hotkey: "5HgBDcx8Z9oEWrGQm7obH4aPE2M5YXWA6S6MP1HtFHguUqek",
        net_leverage: 0.0,
        open_ms: 1719984054824,
        orders: [
          {
            leverage: 0.19813302134829808,
            order_type: "LONG",
            order_uuid: "1c291bc3-8907-4452-9877-f30c82630bb3",
            price: 161.813,
            price_sources: [],
            processed_ms: 1719984054734,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "c3d99e50-bc27-4085-8d37-f002f40315e5",
            price: 161.892,
            price_sources: [],
            processed_ms: 1719987190076,
          },
        ],
        position_type: "FLAT",
        position_uuid: "1c291bc3-8907-4452-9877-f30c82630bb3",
        return_at_close: 1.0000828614307635,
        trade_pair: ["USDJPY", "USD/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 161.79299518298365,
        close_ms: 1719990198648,
        current_return: 1.00029461973966,
        initial_entry_price: 161.887,
        is_closed_position: true,
        miner_hotkey: "5HgBDcx8Z9oEWrGQm7obH4aPE2M5YXWA6S6MP1HtFHguUqek",
        net_leverage: 0.0,
        open_ms: 1719987207748,
        orders: [
          {
            leverage: 0.21480348595820561,
            order_type: "LONG",
            order_uuid: "40329204-f232-457f-88c4-2ab5d8e87b34",
            price: 161.887,
            price_sources: [],
            processed_ms: 1719987207726,
          },
          {
            leverage: 0.18130272088637234,
            order_type: "LONG",
            order_uuid: "c59152d8-9a51-4cd7-9ef2-60039246d8fe",
            price: 161.828,
            price_sources: [],
            processed_ms: 1719989030497,
          },
          {
            leverage: 0.252764271721923,
            order_type: "LONG",
            order_uuid: "540cdf3f-7bf0-4330-bc60-e647903df7a3",
            price: 161.688,
            price_sources: [],
            processed_ms: 1719989110892,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "4dbb3710-119b-46fb-97fa-3b444a5a4a16",
            price: 161.8665,
            price_sources: [],
            processed_ms: 1719990198648,
          },
        ],
        position_type: "FLAT",
        position_uuid: "40329204-f232-457f-88c4-2ab5d8e87b34",
        return_at_close: 1.0002491854242568,
        trade_pair: ["USDJPY", "USD/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 62604.6,
        close_ms: 1719996625575,
        current_return: 1.041446571018743,
        initial_entry_price: 62604.6,
        is_closed_position: true,
        miner_hotkey: "5HgBDcx8Z9oEWrGQm7obH4aPE2M5YXWA6S6MP1HtFHguUqek",
        net_leverage: 0.0,
        open_ms: 1719835328155,
        orders: [
          {
            leverage: -1.4,
            order_type: "SHORT",
            order_uuid: "dae46505-dea5-4cba-9f58-aaf9f359b413",
            price: 62604.6,
            price_sources: [],
            processed_ms: 1719835328016,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "75d9437f-b65c-4316-9aa4-44cd064478e1",
            price: 60751.21,
            price_sources: [],
            processed_ms: 1719996625575,
          },
        ],
        position_type: "FLAT",
        position_uuid: "e43dac1e-ecc4-498e-928f-84968d1a5005",
        return_at_close: 1.0399885458193168,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 161.80416928344525,
        close_ms: 1720004750084,
        current_return: 1.0005158856811607,
        initial_entry_price: 161.875,
        is_closed_position: true,
        miner_hotkey: "5HgBDcx8Z9oEWrGQm7obH4aPE2M5YXWA6S6MP1HtFHguUqek",
        net_leverage: 0.0,
        open_ms: 1719990215869,
        orders: [
          {
            leverage: 0.16278546674990743,
            order_type: "LONG",
            order_uuid: "0c002f84-a10f-4a71-a59e-108a402873db",
            price: 161.875,
            price_sources: [],
            processed_ms: 1719990215780,
          },
          {
            leverage: 0.17368196849002418,
            order_type: "LONG",
            order_uuid: "3d45f078-aa5a-4a54-8275-874de44e3ebf",
            price: 161.809,
            price_sources: [],
            processed_ms: 1719992485669,
          },
          {
            leverage: 0.2738413955588054,
            order_type: "LONG",
            order_uuid: "84216781-8b91-45fe-847a-cd709ca6a89e",
            price: 161.75900000000001,
            price_sources: [],
            processed_ms: 1719993358172,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "f3555a47-67d4-4dda-a728-ec77ede4bd59",
            price: 161.941,
            price_sources: [],
            processed_ms: 1720004750084,
          },
        ],
        position_type: "FLAT",
        position_uuid: "0c002f84-a10f-4a71-a59e-108a402873db",
        return_at_close: 1.0004731420235338,
        trade_pair: ["USDJPY", "USD/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 161.3392315117669,
        close_ms: 1720018870490,
        current_return: 1.0005984241856585,
        initial_entry_price: 161.941,
        is_closed_position: true,
        miner_hotkey: "5HgBDcx8Z9oEWrGQm7obH4aPE2M5YXWA6S6MP1HtFHguUqek",
        net_leverage: 0.0,
        open_ms: 1720004768179,
        orders: [
          {
            leverage: 0.18868279700520113,
            order_type: "LONG",
            order_uuid: "56e79730-80d7-49ce-a426-2be1f93255bf",
            price: 161.941,
            price_sources: [],
            processed_ms: 1720004767850,
          },
          {
            leverage: 0.15225325690843208,
            order_type: "LONG",
            order_uuid: "2beaed46-5478-4362-9785-aa03587d97e3",
            price: 161.882,
            price_sources: [],
            processed_ms: 1720009150204,
          },
          {
            leverage: 0.24778190940466993,
            order_type: "LONG",
            order_uuid: "1c88b29f-eac8-4a39-ae68-6c43fdb7abd4",
            price: 161.8265,
            price_sources: [],
            processed_ms: 1720009812700,
          },
          {
            leverage: 0.1935436415707924,
            order_type: "LONG",
            order_uuid: "f1e3f2f0-58a6-456a-8f27-6ef60dae275a",
            price: 161.724,
            price_sources: [],
            processed_ms: 1720012115419,
          },
          {
            leverage: 0.28149663213496023,
            order_type: "LONG",
            order_uuid: "1bed9884-e384-42f3-a4cf-b95240dc002b",
            price: 161.645,
            price_sources: [],
            processed_ms: 1720012525588,
          },
          {
            leverage: 0.21562588155054602,
            order_type: "LONG",
            order_uuid: "f374aaef-fc66-4858-8a85-3df9a5476a77",
            price: 161.6085,
            price_sources: [],
            processed_ms: 1720013077756,
          },
          {
            leverage: 0.25081847264756374,
            order_type: "LONG",
            order_uuid: "be556c4c-9f67-455c-8935-227c9c81577f",
            price: 161.3465,
            price_sources: [],
            processed_ms: 1720015204479,
          },
          {
            leverage: 0.2849276620061426,
            order_type: "LONG",
            order_uuid: "9f147d55-b854-4da2-b471-3525ac7fa4b8",
            price: 161.372,
            price_sources: [],
            processed_ms: 1720015221836,
          },
          {
            leverage: 0.20390524879248395,
            order_type: "LONG",
            order_uuid: "44549737-a1cc-4eb0-8f16-f4e1d4a4c6af",
            price: 161.322,
            price_sources: [],
            processed_ms: 1720015241761,
          },
          {
            leverage: 0.1893057043241017,
            order_type: "LONG",
            order_uuid: "4677b701-902f-464a-b711-9a6a15f5afc0",
            price: 161.267,
            price_sources: [],
            processed_ms: 1720015281852,
          },
          {
            leverage: 0.29405629325540317,
            order_type: "LONG",
            order_uuid: "db1593cd-618e-4516-aa9c-63d8919a0c5c",
            price: 161.2,
            price_sources: [],
            processed_ms: 1720015720679,
          },
          {
            leverage: 0.2690204369099315,
            order_type: "LONG",
            order_uuid: "bbdc7121-8ba1-4251-ac74-afdfc25f7880",
            price: 161.144,
            price_sources: [],
            processed_ms: 1720015825936,
          },
          {
            leverage: 0.2994774865439557,
            order_type: "LONG",
            order_uuid: "b55adf73-dc72-4a46-b850-0170ed74d3f9",
            price: 161.051,
            price_sources: [],
            processed_ms: 1720015879908,
          },
          {
            leverage: 0.1669633971986862,
            order_type: "LONG",
            order_uuid: "776f023e-9abe-488b-a435-df2b35913b4d",
            price: 160.9135,
            price_sources: [],
            processed_ms: 1720015958856,
          },
          {
            leverage: 0.23961972378335367,
            order_type: "LONG",
            order_uuid: "c1897c26-0164-4ab6-a07a-3776485db50a",
            price: 160.8435,
            price_sources: [],
            processed_ms: 1720015987260,
          },
          {
            leverage: 0.28329353436781113,
            order_type: "LONG",
            order_uuid: "2657be2a-43b5-4b35-9c98-fe713b84ba6b",
            price: 160.775,
            price_sources: [],
            processed_ms: 1720016007912,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "083c20dd-5e75-4b6d-81da-e52b1ddc7625",
            price: 161.365,
            price_sources: [],
            processed_ms: 1720018870490,
          },
        ],
        position_type: "FLAT",
        position_uuid: "56e79730-80d7-49ce-a426-2be1f93255bf",
        return_at_close: 1.0003350126025823,
        trade_pair: ["USDJPY", "USD/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 161.32340231923834,
        close_ms: 1720020033868,
        current_return: 1.000272786051801,
        initial_entry_price: 161.389,
        is_closed_position: true,
        miner_hotkey: "5HgBDcx8Z9oEWrGQm7obH4aPE2M5YXWA6S6MP1HtFHguUqek",
        net_leverage: 0.0,
        open_ms: 1720018892084,
        orders: [
          {
            leverage: 0.15132159524871922,
            order_type: "LONG",
            order_uuid: "51dcf15b-857c-4701-a991-9bd9dcf6cf9d",
            price: 161.389,
            price_sources: [],
            processed_ms: 1720018891882,
          },
          {
            leverage: 0.2520649739971479,
            order_type: "LONG",
            order_uuid: "9f2b8bbd-861c-420f-9c6d-149cc9558ea3",
            price: 161.328,
            price_sources: [],
            processed_ms: 1720018969832,
          },
          {
            leverage: 0.26774490374108534,
            order_type: "LONG",
            order_uuid: "3caa5c84-fb21-4355-baef-24fd8011e27b",
            price: 161.282,
            price_sources: [],
            processed_ms: 1720019322420,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "aa2cd96d-2174-4725-9701-4bdc9285989b",
            price: 161.389,
            price_sources: [],
            processed_ms: 1720020033868,
          },
        ],
        position_type: "FLAT",
        position_uuid: "51dcf15b-857c-4701-a991-9bd9dcf6cf9d",
        return_at_close: 1.0002257940334207,
        trade_pair: ["USDJPY", "USD/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 161.35101094681423,
        close_ms: 1720020543832,
        current_return: 1.000190217235562,
        initial_entry_price: 161.385,
        is_closed_position: true,
        miner_hotkey: "5HgBDcx8Z9oEWrGQm7obH4aPE2M5YXWA6S6MP1HtFHguUqek",
        net_leverage: 0.0,
        open_ms: 1720020049073,
        orders: [
          {
            leverage: 0.18499876351848524,
            order_type: "LONG",
            order_uuid: "0a62e0fe-aca4-439c-a498-00dc38612519",
            price: 161.385,
            price_sources: [],
            processed_ms: 1720020048908,
          },
          {
            leverage: 0.2732583262783934,
            order_type: "LONG",
            order_uuid: "428718bc-4eb3-4046-9ba7-d00ce8247ba4",
            price: 161.328,
            price_sources: [],
            processed_ms: 1720020145150,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "3c7f2c33-70fa-4b43-9990-d36657e193a9",
            price: 161.418,
            price_sources: [],
            processed_ms: 1720020543832,
          },
        ],
        position_type: "FLAT",
        position_uuid: "0a62e0fe-aca4-439c-a498-00dc38612519",
        return_at_close: 1.0001581331374882,
        trade_pair: ["USDJPY", "USD/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 161.434,
        close_ms: 1720025708444,
        current_return: 1.000163113123242,
        initial_entry_price: 161.434,
        is_closed_position: true,
        miner_hotkey: "5HgBDcx8Z9oEWrGQm7obH4aPE2M5YXWA6S6MP1HtFHguUqek",
        net_leverage: 0.0,
        open_ms: 1720020560763,
        orders: [
          {
            leverage: 0.2057187807614294,
            order_type: "LONG",
            order_uuid: "33baac1e-87c2-42e6-9442-8fab475f3447",
            price: 161.434,
            price_sources: [],
            processed_ms: 1720020560718,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "ed0b0747-29a3-4dda-a825-da0dcd06a641",
            price: 161.562,
            price_sources: [
              {
                close: 161.562,
                high: 161.562,
                lag_ms: 444,
                low: 161.562,
                open: 161.562,
                source: "TwelveData_ws",
                start_ms: 1720025708000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
              {
                close: 161.56,
                high: 161.56,
                lag_ms: 445,
                low: 161.56,
                open: 161.56,
                source: "Polygon_ws",
                start_ms: 1720025707999,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 161.56,
                websocket: true,
              },
            ],
            processed_ms: 1720025708444,
          },
        ],
        position_type: "FLAT",
        position_uuid: "33baac1e-87c2-42e6-9442-8fab475f3447",
        return_at_close: 1.0001487104597084,
        trade_pair: ["USDJPY", "USD/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 161.54615973889776,
        close_ms: 1720030740852,
        current_return: 1.0001439373190553,
        initial_entry_price: 161.571,
        is_closed_position: true,
        miner_hotkey: "5HgBDcx8Z9oEWrGQm7obH4aPE2M5YXWA6S6MP1HtFHguUqek",
        net_leverage: 0.0,
        open_ms: 1720025725884,
        orders: [
          {
            leverage: 0.20903976219073778,
            order_type: "LONG",
            order_uuid: "4d5320ec-b75c-481e-a93e-0bbd76a3fe65",
            price: 161.571,
            price_sources: [
              {
                close: 161.571,
                high: 161.571,
                lag_ms: 184,
                low: 161.571,
                open: 161.571,
                source: "Polygon_ws",
                start_ms: 1720025725999,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 161.571,
                websocket: true,
              },
              {
                close: 161.568,
                high: 161.568,
                lag_ms: 185,
                low: 161.568,
                open: 161.568,
                source: "TwelveData_ws",
                start_ms: 1720025726000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1720025725815,
          },
          {
            leverage: 0.2575728931758031,
            order_type: "LONG",
            order_uuid: "e933c21a-454a-4778-9bed-5eeb29b82d62",
            price: 161.526,
            price_sources: [
              {
                close: 161.526,
                high: 161.526,
                lag_ms: 156,
                low: 161.526,
                open: 161.526,
                source: "Polygon_ws",
                start_ms: 1720026935000,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 161.526,
                websocket: true,
              },
              {
                close: 161.516,
                high: 161.516,
                lag_ms: 844,
                low: 161.516,
                open: 161.516,
                source: "TwelveData_ws",
                start_ms: 1720026936000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1720026935156,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "63a269d5-338f-4c37-9210-5dea6c9e2dca",
            price: 161.596,
            price_sources: [
              {
                close: 161.596,
                high: 161.596,
                lag_ms: 148,
                low: 161.596,
                open: 161.596,
                source: "Polygon_ws",
                start_ms: 1720030741000,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 161.596,
                websocket: true,
              },
              {
                close: 161.6,
                high: 161.6,
                lag_ms: 148,
                low: 161.6,
                open: 161.6,
                source: "TwelveData_ws",
                start_ms: 1720030741000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1720030740852,
          },
        ],
        position_type: "FLAT",
        position_uuid: "4d5320ec-b75c-481e-a93e-0bbd76a3fe65",
        return_at_close: 1.0001112697317713,
        trade_pair: ["USDJPY", "USD/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 161.61893293316385,
        close_ms: 1720040656681,
        current_return: 1.0000999994048398,
        initial_entry_price: 161.59550000000002,
        is_closed_position: true,
        miner_hotkey: "5HgBDcx8Z9oEWrGQm7obH4aPE2M5YXWA6S6MP1HtFHguUqek",
        net_leverage: 0.0,
        open_ms: 1720030758063,
        orders: [
          {
            leverage: 0.18480631941648074,
            order_type: "LONG",
            order_uuid: "486f31ce-3796-4aea-98b0-8238e11adffd",
            price: 161.59550000000002,
            price_sources: [
              {
                close: 161.59550000000002,
                high: 161.59550000000002,
                lag_ms: 110,
                low: 161.59550000000002,
                open: 161.59550000000002,
                source: "Polygon_ws",
                start_ms: 1720030757999,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 161.594,
                websocket: true,
              },
              {
                close: 161.6,
                high: 161.6,
                lag_ms: 889,
                low: 161.6,
                open: 161.6,
                source: "TwelveData_ws",
                start_ms: 1720030757000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1720030757889,
          },
          {
            leverage: 0.239693259033742,
            order_type: "LONG",
            order_uuid: "f8428d43-5146-48c9-914b-e8379173d618",
            price: 161.637,
            price_sources: [
              {
                close: 161.637,
                high: 161.637,
                lag_ms: 1689,
                low: 161.637,
                open: 161.637,
                source: "Polygon_ws",
                start_ms: 1720040632999,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 161.637,
                websocket: true,
              },
              {
                close: 161.596,
                high: 161.596,
                lag_ms: 4688,
                low: 161.596,
                open: 161.596,
                source: "TwelveData_ws",
                start_ms: 1720040630000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1720040634688,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "cb26d99b-01d9-4e18-b2b0-56f01ee851f6",
            price: 161.657,
            price_sources: [
              {
                close: 161.657,
                high: 161.657,
                lag_ms: 318,
                low: 161.657,
                open: 161.657,
                source: "Polygon_ws",
                start_ms: 1720040656999,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 161.657,
                websocket: true,
              },
              {
                close: 161.599,
                high: 161.599,
                lag_ms: 1319,
                low: 161.599,
                open: 161.599,
                source: "TwelveData_ws",
                start_ms: 1720040658000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
              {
                close: 161.644,
                high: 161.644,
                lag_ms: 4682,
                low: 161.644,
                open: 161.644,
                source: "Polygon_rest",
                start_ms: 1720040651000,
                timespan_ms: 1000,
                volume: 1.0,
                vwap: null,
                websocket: false,
              },
              {
                close: 161.69501,
                high: 161.69501,
                lag_ms: 196682,
                low: 161.675,
                open: 161.675,
                source: "TwelveData_rest",
                start_ms: 1720040400000,
                timespan_ms: 60000,
                volume: null,
                vwap: null,
                websocket: false,
              },
            ],
            processed_ms: 1720040656681,
          },
        ],
        position_type: "FLAT",
        position_uuid: "486f31ce-3796-4aea-98b0-8238e11adffd",
        return_at_close: 1.000070281462869,
        trade_pair: ["USDJPY", "USD/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 161.62127415372044,
        close_ms: 1720041234282,
        current_return: 1.0000550730937,
        initial_entry_price: 161.614,
        is_closed_position: true,
        miner_hotkey: "5HgBDcx8Z9oEWrGQm7obH4aPE2M5YXWA6S6MP1HtFHguUqek",
        net_leverage: 0.0,
        open_ms: 1720040685105,
        orders: [
          {
            leverage: 0.21462248558201122,
            order_type: "LONG",
            order_uuid: "0604293f-d281-4f73-aafb-25a98f361072",
            price: 161.614,
            price_sources: [
              {
                close: 161.614,
                high: 161.614,
                lag_ms: 71,
                low: 161.614,
                open: 161.614,
                source: "Polygon_ws",
                start_ms: 1720040684999,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 161.614,
                websocket: true,
              },
              {
                close: 161.598,
                high: 161.598,
                lag_ms: 928,
                low: 161.598,
                open: 161.598,
                source: "TwelveData_ws",
                start_ms: 1720040684000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1720040684928,
          },
          {
            leverage: 0.16052042229656513,
            order_type: "LONG",
            order_uuid: "cb79e3d5-7354-4186-8b36-f0a9ff5a4d6e",
            price: 161.631,
            price_sources: [
              {
                close: 161.631,
                high: 161.631,
                lag_ms: 1487,
                low: 161.631,
                open: 161.631,
                source: "Polygon_ws",
                start_ms: 1720041026000,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 161.631,
                websocket: true,
              },
              {
                close: 161.632,
                high: 161.632,
                lag_ms: 2514,
                low: 161.632,
                open: 161.632,
                source: "Polygon_rest",
                start_ms: 1720041021000,
                timespan_ms: 1000,
                volume: 1.0,
                vwap: null,
                websocket: false,
              },
              {
                close: 161.588,
                high: 161.588,
                lag_ms: 4487,
                low: 161.588,
                open: 161.588,
                source: "TwelveData_ws",
                start_ms: 1720041029000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
              {
                close: 161.645,
                high: 161.645,
                lag_ms: 24514,
                low: 161.645,
                open: 161.645,
                source: "TwelveData_rest",
                start_ms: 1720040940000,
                timespan_ms: 60000,
                volume: null,
                vwap: null,
                websocket: false,
              },
            ],
            processed_ms: 1720041024513,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "0cf65f85-a8de-469b-acb0-28b3d97e00d8",
            price: 161.645,
            price_sources: [
              {
                close: 161.645,
                high: 161.645,
                lag_ms: 282,
                low: 161.645,
                open: 161.645,
                source: "Polygon_ws",
                start_ms: 1720041234000,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 161.645,
                websocket: true,
              },
              {
                close: 161.6,
                high: 161.6,
                lag_ms: 2282,
                low: 161.6,
                open: 161.6,
                source: "TwelveData_ws",
                start_ms: 1720041232000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1720041234282,
          },
        ],
        position_type: "FLAT",
        position_uuid: "0604293f-d281-4f73-aafb-25a98f361072",
        return_at_close: 1.0000288116439287,
        trade_pair: ["USDJPY", "USD/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 161.5890939004339,
        close_ms: 1720044026832,
        current_return: 1.000042572260566,
        initial_entry_price: 161.631,
        is_closed_position: true,
        miner_hotkey: "5HgBDcx8Z9oEWrGQm7obH4aPE2M5YXWA6S6MP1HtFHguUqek",
        net_leverage: 0.0,
        open_ms: 1720041252026,
        orders: [
          {
            leverage: 0.22806093897726776,
            order_type: "LONG",
            order_uuid: "ca2a3a30-604a-46f4-a7f5-14a6a2a04bc2",
            price: 161.631,
            price_sources: [
              {
                close: 161.631,
                high: 161.631,
                lag_ms: 1973,
                low: 161.631,
                open: 161.631,
                source: "Polygon_ws",
                start_ms: 1720041249999,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 161.631,
                websocket: true,
              },
              {
                close: 161.628,
                high: 161.628,
                lag_ms: 4972,
                low: 161.628,
                open: 161.628,
                source: "TwelveData_ws",
                start_ms: 1720041247000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1720041251972,
          },
          {
            leverage: 0.2526710150376108,
            order_type: "LONG",
            order_uuid: "55318c1a-eb19-4d1c-bab9-7dc0e3b2511b",
            price: 161.623,
            price_sources: [
              {
                close: 161.623,
                high: 161.623,
                lag_ms: 833,
                low: 161.623,
                open: 161.623,
                source: "Polygon_ws",
                start_ms: 1720041314999,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 161.623,
                websocket: true,
              },
              {
                close: 161.602,
                high: 161.602,
                lag_ms: 3832,
                low: 161.602,
                open: 161.602,
                source: "TwelveData_ws",
                start_ms: 1720041312000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1720041315832,
          },
          {
            leverage: 0.29188427337606837,
            order_type: "LONG",
            order_uuid: "12958e4d-abbe-41ad-8a45-1bc135a0458f",
            price: 161.527,
            price_sources: [
              {
                close: 161.527,
                high: 161.527,
                lag_ms: 284,
                low: 161.527,
                open: 161.527,
                source: "Polygon_ws",
                start_ms: 1720043448000,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 161.527,
                websocket: true,
              },
              {
                close: 161.535,
                high: 161.535,
                lag_ms: 284,
                low: 161.535,
                open: 161.535,
                source: "TwelveData_ws",
                start_ms: 1720043448000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1720043447716,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "d9525d35-9ca6-467e-a112-f5ca3973d957",
            price: 161.598,
            price_sources: [
              {
                close: 161.598,
                high: 161.598,
                lag_ms: 167,
                low: 161.598,
                open: 161.598,
                source: "Polygon_ws",
                start_ms: 1720044026999,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 161.597,
                websocket: true,
              },
              {
                close: 161.596,
                high: 161.596,
                lag_ms: 2168,
                low: 161.596,
                open: 161.596,
                source: "TwelveData_ws",
                start_ms: 1720044029000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1720044026832,
          },
        ],
        position_type: "FLAT",
        position_uuid: "ca2a3a30-604a-46f4-a7f5-14a6a2a04bc2",
        return_at_close: 0.9999884868222072,
        trade_pair: ["USDJPY", "USD/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 161.57572740947404,
        close_ms: 1720049733462,
        current_return: 1.000183065292239,
        initial_entry_price: 161.599,
        is_closed_position: true,
        miner_hotkey: "5HgBDcx8Z9oEWrGQm7obH4aPE2M5YXWA6S6MP1HtFHguUqek",
        net_leverage: 0.0,
        open_ms: 1720044045445,
        orders: [
          {
            leverage: 0.21784690703420506,
            order_type: "LONG",
            order_uuid: "2c9545c9-ba2f-4d09-9597-07fde40a48a7",
            price: 161.599,
            price_sources: [
              {
                close: 161.599,
                high: 161.599,
                lag_ms: 184,
                low: 161.599,
                open: 161.599,
                source: "Polygon_ws",
                start_ms: 1720044044999,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 161.599,
                websocket: true,
              },
              {
                close: 161.613,
                high: 161.613,
                lag_ms: 3816,
                low: 161.613,
                open: 161.613,
                source: "Polygon_rest",
                start_ms: 1720044040000,
                timespan_ms: 1000,
                volume: 1.0,
                vwap: null,
                websocket: false,
              },
              {
                close: 161.598,
                high: 161.598,
                lag_ms: 4185,
                low: 161.598,
                open: 161.598,
                source: "TwelveData_ws",
                start_ms: 1720044049000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
              {
                close: 161.58501,
                high: 161.58501,
                lag_ms: 224816,
                low: 161.58,
                open: 161.58,
                source: "TwelveData_rest",
                start_ms: 1720043760000,
                timespan_ms: 60000,
                volume: null,
                vwap: null,
                websocket: false,
              },
            ],
            processed_ms: 1720044044815,
          },
          {
            leverage: 0.1649947701913735,
            order_type: "LONG",
            order_uuid: "db6cda13-1899-4941-b134-fab6baa5dc70",
            price: 161.545,
            price_sources: [
              {
                close: 161.545,
                high: 161.545,
                lag_ms: 345,
                low: 161.545,
                open: 161.545,
                source: "Polygon_ws",
                start_ms: 1720044148999,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 161.545,
                websocket: true,
              },
              {
                close: 161.548,
                high: 161.548,
                lag_ms: 656,
                low: 161.548,
                open: 161.548,
                source: "TwelveData_ws",
                start_ms: 1720044150000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1720044149344,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "a5284d08-562e-4311-b472-b6123bda7c69",
            price: 161.653,
            price_sources: [
              {
                close: 161.653,
                high: 161.653,
                lag_ms: 462,
                low: 161.653,
                open: 161.653,
                source: "Polygon_ws",
                start_ms: 1720049733000,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 161.653,
                websocket: true,
              },
              {
                close: 161.65,
                high: 161.65,
                lag_ms: 2538,
                low: 161.65,
                open: 161.65,
                source: "TwelveData_ws",
                start_ms: 1720049736000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1720049733462,
          },
        ],
        position_type: "FLAT",
        position_uuid: "2c9545c9-ba2f-4d09-9597-07fde40a48a7",
        return_at_close: 1.0001562614688815,
        trade_pair: ["USDJPY", "USD/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 161.55443969761276,
        close_ms: 1720053755898,
        current_return: 1.0001697228325082,
        initial_entry_price: 161.648,
        is_closed_position: true,
        miner_hotkey: "5HgBDcx8Z9oEWrGQm7obH4aPE2M5YXWA6S6MP1HtFHguUqek",
        net_leverage: 0.0,
        open_ms: 1720049823181,
        orders: [
          {
            leverage: 0.2610758973284465,
            order_type: "LONG",
            order_uuid: "4907a11b-2e3e-46f9-b186-2a019215e49d",
            price: 161.648,
            price_sources: [
              {
                close: 161.648,
                high: 161.648,
                lag_ms: 133,
                low: 161.648,
                open: 161.648,
                source: "Polygon_ws",
                start_ms: 1720049823000,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 161.648,
                websocket: true,
              },
              {
                close: 161.667,
                high: 161.667,
                lag_ms: 133,
                low: 161.667,
                open: 161.667,
                source: "TwelveData_ws",
                start_ms: 1720049823000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
              {
                close: 161.663,
                high: 161.663,
                lag_ms: 2868,
                low: 161.663,
                open: 161.663,
                source: "Polygon_rest",
                start_ms: 1720049819000,
                timespan_ms: 1000,
                volume: 2.0,
                vwap: null,
                websocket: false,
              },
              {
                close: 161.64,
                high: 161.64,
                lag_ms: 182868,
                low: 161.64,
                open: 161.64,
                source: "TwelveData_rest",
                start_ms: 1720049580000,
                timespan_ms: 60000,
                volume: null,
                vwap: null,
                websocket: false,
              },
            ],
            processed_ms: 1720049822867,
          },
          {
            leverage: 0.29853137488486053,
            order_type: "LONG",
            order_uuid: "f84b9dd7-1bf7-4092-91d4-b2349e272858",
            price: 161.618,
            price_sources: [
              {
                close: 161.618,
                high: 161.618,
                lag_ms: 40,
                low: 161.618,
                open: 161.618,
                source: "Polygon_ws",
                start_ms: 1720051059000,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 161.621,
                websocket: true,
              },
              {
                close: 161.622,
                high: 161.622,
                lag_ms: 40,
                low: 161.622,
                open: 161.622,
                source: "TwelveData_ws",
                start_ms: 1720051059000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1720051059040,
          },
          {
            leverage: 0.17945770528708266,
            order_type: "LONG",
            order_uuid: "419d3067-7924-471a-9655-0c8ced60ce42",
            price: 161.5525,
            price_sources: [
              {
                close: 161.5525,
                high: 161.5525,
                lag_ms: 62,
                low: 161.5525,
                open: 161.5525,
                source: "Polygon_ws",
                start_ms: 1720051251000,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 161.552,
                websocket: true,
              },
              {
                close: 161.562,
                high: 161.562,
                lag_ms: 938,
                low: 161.562,
                open: 161.562,
                source: "TwelveData_ws",
                start_ms: 1720051252000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1720051251062,
          },
          {
            leverage: 0.25341508001593,
            order_type: "LONG",
            order_uuid: "ca2b9d2b-d6e3-48ec-899a-d651b5cb65c5",
            price: 161.511,
            price_sources: [
              {
                close: 161.511,
                high: 161.511,
                lag_ms: 62,
                low: 161.511,
                open: 161.511,
                source: "Polygon_ws",
                start_ms: 1720051368999,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 161.507,
                websocket: true,
              },
              {
                close: 161.51,
                high: 161.51,
                lag_ms: 63,
                low: 161.51,
                open: 161.51,
                source: "TwelveData_ws",
                start_ms: 1720051369000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1720051368937,
          },
          {
            leverage: 0.28001398902088903,
            order_type: "LONG",
            order_uuid: "c0f9c311-734c-47e6-9056-677d6eb3eee4",
            price: 161.44,
            price_sources: [
              {
                close: 161.44,
                high: 161.44,
                lag_ms: 217,
                low: 161.44,
                open: 161.44,
                source: "Polygon_ws",
                start_ms: 1720051560000,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 161.443,
                websocket: true,
              },
              {
                close: 161.443,
                high: 161.443,
                lag_ms: 217,
                low: 161.443,
                open: 161.443,
                source: "TwelveData_ws",
                start_ms: 1720051560000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1720051560217,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "8a657f33-4411-437a-a94d-e6ec80e45c74",
            price: 161.576,
            price_sources: [
              {
                close: 161.576,
                high: 161.576,
                lag_ms: 101,
                low: 161.576,
                open: 161.576,
                source: "Polygon_ws",
                start_ms: 1720053755999,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 161.576,
                websocket: true,
              },
              {
                close: 161.573,
                high: 161.573,
                lag_ms: 102,
                low: 161.573,
                open: 161.573,
                source: "TwelveData_ws",
                start_ms: 1720053756000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1720053755898,
          },
        ],
        position_type: "FLAT",
        position_uuid: "4907a11b-2e3e-46f9-b186-2a019215e49d",
        return_at_close: 1.00008063313126,
        trade_pair: ["USDJPY", "USD/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 161.37063520899875,
        close_ms: 1720055863927,
        current_return: 1.000298114397239,
        initial_entry_price: 161.574,
        is_closed_position: true,
        miner_hotkey: "5HgBDcx8Z9oEWrGQm7obH4aPE2M5YXWA6S6MP1HtFHguUqek",
        net_leverage: 0.0,
        open_ms: 1720053772817,
        orders: [
          {
            leverage: 0.264214863582558,
            order_type: "LONG",
            order_uuid: "7767f44f-0c11-4b64-a0b0-920f56ae0ef5",
            price: 161.574,
            price_sources: [
              {
                close: 161.574,
                high: 161.574,
                lag_ms: 1254,
                low: 161.574,
                open: 161.574,
                source: "Polygon_ws",
                start_ms: 1720053774000,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 161.574,
                websocket: true,
              },
              {
                close: 161.571,
                high: 161.571,
                lag_ms: 1254,
                low: 161.571,
                open: 161.571,
                source: "TwelveData_ws",
                start_ms: 1720053774000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1720053772746,
          },
          {
            leverage: 0.2672579329277356,
            order_type: "LONG",
            order_uuid: "81da062c-eb2a-4a06-ba8c-d30384eb6889",
            price: 161.512,
            price_sources: [
              {
                close: 161.512,
                high: 161.512,
                lag_ms: 318,
                low: 161.512,
                open: 161.512,
                source: "Polygon_ws",
                start_ms: 1720054203999,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 161.516,
                websocket: true,
              },
              {
                close: 161.501,
                high: 161.501,
                lag_ms: 319,
                low: 161.501,
                open: 161.501,
                source: "TwelveData_ws",
                start_ms: 1720054204000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1720054203681,
          },
          {
            leverage: 0.1989178195322323,
            order_type: "LONG",
            order_uuid: "9458934f-e071-4725-aed3-d069bb0f3247",
            price: 161.451,
            price_sources: [
              {
                close: 161.451,
                high: 161.451,
                lag_ms: 335,
                low: 161.451,
                open: 161.451,
                source: "Polygon_ws",
                start_ms: 1720054445000,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 161.451,
                websocket: true,
              },
              {
                close: 161.449,
                high: 161.449,
                lag_ms: 665,
                low: 161.449,
                open: 161.449,
                source: "TwelveData_ws",
                start_ms: 1720054446000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1720054445335,
          },
          {
            leverage: 0.2610023642020871,
            order_type: "LONG",
            order_uuid: "346a6aa3-a135-4501-b8b5-37a603dadd4f",
            price: 161.39749999999998,
            price_sources: [
              {
                close: 161.39749999999998,
                high: 161.39749999999998,
                lag_ms: 65,
                low: 161.39749999999998,
                open: 161.39749999999998,
                source: "Polygon_ws",
                start_ms: 1720054498999,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 161.402,
                websocket: true,
              },
              {
                close: 161.376,
                high: 161.376,
                lag_ms: 66,
                low: 161.376,
                open: 161.376,
                source: "TwelveData_ws",
                start_ms: 1720054499000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1720054498934,
          },
          {
            leverage: 0.16972452104140048,
            order_type: "LONG",
            order_uuid: "db26f8bd-b03b-49f0-90c7-364ff6c7bc2f",
            price: 161.3115,
            price_sources: [
              {
                close: 161.3115,
                high: 161.3115,
                lag_ms: 468,
                low: 161.3115,
                open: 161.3115,
                source: "Polygon_ws",
                start_ms: 1720054867000,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 161.319,
                websocket: true,
              },
              {
                close: 161.302,
                high: 161.302,
                lag_ms: 468,
                low: 161.302,
                open: 161.302,
                source: "TwelveData_ws",
                start_ms: 1720054867000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1720054867468,
          },
          {
            leverage: 0.2483362403550986,
            order_type: "LONG",
            order_uuid: "53961544-f4c0-482f-84eb-9dd6850cdb49",
            price: 161.27249999999998,
            price_sources: [
              {
                close: 161.27249999999998,
                high: 161.27249999999998,
                lag_ms: 79,
                low: 161.27249999999998,
                open: 161.27249999999998,
                source: "Polygon_ws",
                start_ms: 1720055058999,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 161.27,
                websocket: true,
              },
              {
                close: 161.285,
                high: 161.285,
                lag_ms: 80,
                low: 161.285,
                open: 161.285,
                source: "TwelveData_ws",
                start_ms: 1720055059000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1720055058920,
          },
          {
            leverage: 0.26673157462246033,
            order_type: "LONG",
            order_uuid: "46ae21ef-228a-4989-b730-b857d3ac0f3f",
            price: 161.211,
            price_sources: [
              {
                close: 161.211,
                high: 161.211,
                lag_ms: 6,
                low: 161.211,
                open: 161.211,
                source: "Polygon_ws",
                start_ms: 1720055173000,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 161.212,
                websocket: true,
              },
              {
                close: 161.211,
                high: 161.211,
                lag_ms: 6,
                low: 161.211,
                open: 161.211,
                source: "TwelveData_ws",
                start_ms: 1720055173000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1720055173006,
          },
          {
            leverage: 0.18609671869109068,
            order_type: "LONG",
            order_uuid: "995adff0-1f7e-42c3-91ca-19ea25a2a6d7",
            price: 161.169,
            price_sources: [
              {
                close: 161.169,
                high: 161.169,
                lag_ms: 179,
                low: 161.169,
                open: 161.169,
                source: "Polygon_ws",
                start_ms: 1720055267000,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 161.169,
                websocket: true,
              },
              {
                close: 161.169,
                high: 161.169,
                lag_ms: 179,
                low: 161.169,
                open: 161.169,
                source: "TwelveData_ws",
                start_ms: 1720055267000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1720055267179,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "b6ecc48e-03c9-4d2a-8413-b717b1a264f9",
            price: 161.3965,
            price_sources: [
              {
                close: 161.3965,
                high: 161.3965,
                lag_ms: 72,
                low: 161.3965,
                open: 161.3965,
                source: "Polygon_ws",
                start_ms: 1720055863999,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 161.39,
                websocket: true,
              },
              {
                close: 161.402,
                high: 161.402,
                lag_ms: 73,
                low: 161.402,
                open: 161.402,
                source: "TwelveData_ws",
                start_ms: 1720055864000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1720055863927,
          },
        ],
        position_type: "FLAT",
        position_uuid: "7767f44f-0c11-4b64-a0b0-920f56ae0ef5",
        return_at_close: 1.000167715792676,
        trade_pair: ["USDJPY", "USD/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 161.37206746498467,
        close_ms: 1720064104908,
        current_return: 1.0001953510111383,
        initial_entry_price: 161.413,
        is_closed_position: true,
        miner_hotkey: "5HgBDcx8Z9oEWrGQm7obH4aPE2M5YXWA6S6MP1HtFHguUqek",
        net_leverage: 0.0,
        open_ms: 1720055885091,
        orders: [
          {
            leverage: 0.167094340265108,
            order_type: "LONG",
            order_uuid: "b2594347-3a7d-4301-a7dc-b29acda8359c",
            price: 161.413,
            price_sources: [
              {
                close: 161.413,
                high: 161.413,
                lag_ms: 26,
                low: 161.413,
                open: 161.413,
                source: "Polygon_ws",
                start_ms: 1720055884999,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 161.411,
                websocket: true,
              },
              {
                close: 161.413,
                high: 161.413,
                lag_ms: 975,
                low: 161.413,
                open: 161.413,
                source: "TwelveData_ws",
                start_ms: 1720055886000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1720055885025,
          },
          {
            leverage: 0.17967035463267533,
            order_type: "LONG",
            order_uuid: "c9bee4e0-26fd-46f1-8680-88602e30a40d",
            price: 161.334,
            price_sources: [
              {
                close: 161.334,
                high: 161.334,
                lag_ms: 315,
                low: 161.334,
                open: 161.334,
                source: "Polygon_ws",
                start_ms: 1720057406999,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 161.334,
                websocket: true,
              },
              {
                close: 161.334,
                high: 161.334,
                lag_ms: 316,
                low: 161.334,
                open: 161.334,
                source: "TwelveData_ws",
                start_ms: 1720057407000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1720057406684,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "6a68e62b-e96c-489d-bef4-88fdd269eb69",
            price: 161.463,
            price_sources: [
              {
                close: 161.463,
                high: 161.463,
                lag_ms: 92,
                low: 161.463,
                open: 161.463,
                source: "Polygon_ws",
                start_ms: 1720064105000,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 161.463,
                websocket: true,
              },
              {
                close: 161.467,
                high: 161.467,
                lag_ms: 1092,
                low: 161.467,
                open: 161.467,
                source: "TwelveData_ws",
                start_ms: 1720064106000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1720064104908,
          },
        ],
        position_type: "FLAT",
        position_uuid: "b2594347-3a7d-4301-a7dc-b29acda8359c",
        return_at_close: 1.000171072740637,
        trade_pair: ["USDJPY", "USD/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 161.40743782891514,
        close_ms: 1720075619981,
        current_return: 1.0001200976543667,
        initial_entry_price: 161.46,
        is_closed_position: true,
        miner_hotkey: "5HgBDcx8Z9oEWrGQm7obH4aPE2M5YXWA6S6MP1HtFHguUqek",
        net_leverage: 0.0,
        open_ms: 1720064122703,
        orders: [
          {
            leverage: 0.2203271855790907,
            order_type: "LONG",
            order_uuid: "85e527dc-f7a3-4dbb-8ed1-8543c65e7aca",
            price: 161.46,
            price_sources: [
              {
                close: 161.46,
                high: 161.46,
                lag_ms: 319,
                low: 161.46,
                open: 161.46,
                source: "Polygon_ws",
                start_ms: 1720064122999,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 161.46,
                websocket: true,
              },
              {
                close: 161.46,
                high: 161.46,
                lag_ms: 1320,
                low: 161.46,
                open: 161.46,
                source: "TwelveData_ws",
                start_ms: 1720064124000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1720064122680,
          },
          {
            leverage: 0.23132032805421163,
            order_type: "LONG",
            order_uuid: "b5a02cef-2b13-478f-ab9d-f8ebba866bbb",
            price: 161.402,
            price_sources: [
              {
                close: 161.402,
                high: 161.402,
                lag_ms: 391,
                low: 161.402,
                open: 161.402,
                source: "Polygon_ws",
                start_ms: 1720073201999,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 161.402,
                websocket: true,
              },
              {
                close: 161.405,
                high: 161.405,
                lag_ms: 1392,
                low: 161.405,
                open: 161.405,
                source: "TwelveData_ws",
                start_ms: 1720073203000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1720073201608,
          },
          {
            leverage: 0.1627261687705957,
            order_type: "LONG",
            order_uuid: "1453578c-106f-4b65-a57a-55a92182e794",
            price: 161.344,
            price_sources: [
              {
                close: 161.344,
                high: 161.344,
                lag_ms: 465,
                low: 161.344,
                open: 161.344,
                source: "Polygon_ws",
                start_ms: 1720074351000,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 161.344,
                websocket: true,
              },
              {
                close: 161.343,
                high: 161.343,
                lag_ms: 535,
                low: 161.343,
                open: 161.343,
                source: "TwelveData_ws",
                start_ms: 1720074352000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1720074351465,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "4aacba5d-1ca7-44d3-b182-023311d806a6",
            price: 161.439,
            price_sources: [
              {
                close: 161.439,
                high: 161.439,
                lag_ms: 18,
                low: 161.439,
                open: 161.439,
                source: "Polygon_ws",
                start_ms: 1720075619999,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 161.439,
                websocket: true,
              },
              {
                close: 161.447,
                high: 161.447,
                lag_ms: 19,
                low: 161.447,
                open: 161.447,
                source: "TwelveData_ws",
                start_ms: 1720075620000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1720075619981,
          },
        ],
        position_type: "FLAT",
        position_uuid: "85e527dc-f7a3-4dbb-8ed1-8543c65e7aca",
        return_at_close: 1.0000770863316597,
        trade_pair: ["USDJPY", "USD/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 161.38181410509776,
        close_ms: 1720077696473,
        current_return: 1.0001812977685138,
        initial_entry_price: 161.444,
        is_closed_position: true,
        miner_hotkey: "5HgBDcx8Z9oEWrGQm7obH4aPE2M5YXWA6S6MP1HtFHguUqek",
        net_leverage: 0.0,
        open_ms: 1720075637972,
        orders: [
          {
            leverage: 0.1846987784853459,
            order_type: "LONG",
            order_uuid: "1cc0af2c-9ccf-42bc-a37d-4e4b3dcc4c8d",
            price: 161.444,
            price_sources: [
              {
                close: 161.444,
                high: 161.444,
                lag_ms: 431,
                low: 161.444,
                open: 161.444,
                source: "Polygon_ws",
                start_ms: 1720075637999,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 161.444,
                websocket: true,
              },
              {
                close: 161.446,
                high: 161.446,
                lag_ms: 432,
                low: 161.446,
                open: 161.446,
                source: "TwelveData_ws",
                start_ms: 1720075638000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1720075637568,
          },
          {
            leverage: 0.28452263026891944,
            order_type: "LONG",
            order_uuid: "8042291c-8cab-4144-b87d-88d370688b72",
            price: 161.382,
            price_sources: [
              {
                close: 161.382,
                high: 161.382,
                lag_ms: 199,
                low: 161.382,
                open: 161.382,
                source: "Polygon_ws",
                start_ms: 1720075957999,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 161.382,
                websocket: true,
              },
              {
                close: 161.38,
                high: 161.38,
                lag_ms: 200,
                low: 161.38,
                open: 161.38,
                source: "TwelveData_ws",
                start_ms: 1720075958000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1720075957800,
          },
          {
            leverage: 0.297277242511463,
            order_type: "LONG",
            order_uuid: "8482cda1-8f45-4c44-bb86-39814e6908f2",
            price: 161.34300000000002,
            price_sources: [
              {
                close: 161.34300000000002,
                high: 161.34300000000002,
                lag_ms: 149,
                low: 161.34300000000002,
                open: 161.34300000000002,
                source: "Polygon_ws",
                start_ms: 1720076448000,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 161.345,
                websocket: true,
              },
              {
                close: 161.344,
                high: 161.344,
                lag_ms: 149,
                low: 161.344,
                open: 161.344,
                source: "TwelveData_ws",
                start_ms: 1720076448000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1720076447851,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "ed40a438-1c3e-4b24-a0d5-3cc0d5103daa",
            price: 161.42,
            price_sources: [
              {
                close: 161.42,
                high: 161.42,
                lag_ms: 474,
                low: 161.42,
                open: 161.42,
                source: "Polygon_ws",
                start_ms: 1720077695999,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 161.419,
                websocket: true,
              },
              {
                close: 161.423,
                high: 161.423,
                lag_ms: 527,
                low: 161.423,
                open: 161.423,
                source: "TwelveData_ws",
                start_ms: 1720077697000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1720077696473,
          },
        ],
        position_type: "FLAT",
        position_uuid: "1cc0af2c-9ccf-42bc-a37d-4e4b3dcc4c8d",
        return_at_close: 1.0001276331354105,
        trade_pair: ["USDJPY", "USD/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 161.1736961814276,
        close_ms: 1720116118654,
        current_return: 1.0003246273040896,
        initial_entry_price: 161.42,
        is_closed_position: true,
        miner_hotkey: "5HgBDcx8Z9oEWrGQm7obH4aPE2M5YXWA6S6MP1HtFHguUqek",
        net_leverage: 0.0,
        open_ms: 1720077713257,
        orders: [
          {
            leverage: 0.24569459779621633,
            order_type: "LONG",
            order_uuid: "17cc21df-75f1-40c0-86b2-9ade41e65b73",
            price: 161.42,
            price_sources: [
              {
                close: 161.42,
                high: 161.42,
                lag_ms: 57,
                low: 161.42,
                open: 161.42,
                source: "Polygon_ws",
                start_ms: 1720077712999,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 161.42,
                websocket: true,
              },
              {
                close: 161.421,
                high: 161.421,
                lag_ms: 58,
                low: 161.421,
                open: 161.421,
                source: "TwelveData_ws",
                start_ms: 1720077713000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1720077712942,
          },
          {
            leverage: 0.1556309113368312,
            order_type: "LONG",
            order_uuid: "ef05b3e4-00b4-4d80-b837-4daac8deaf28",
            price: 161.363,
            price_sources: [
              {
                close: 161.363,
                high: 161.363,
                lag_ms: 372,
                low: 161.363,
                open: 161.363,
                source: "Polygon_ws",
                start_ms: 1720078696999,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 161.363,
                websocket: true,
              },
              {
                close: 161.365,
                high: 161.365,
                lag_ms: 627,
                low: 161.365,
                open: 161.365,
                source: "TwelveData_ws",
                start_ms: 1720078696000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1720078696627,
          },
          {
            leverage: 0.1814337131623261,
            order_type: "LONG",
            order_uuid: "88264a03-1a8c-4642-9506-71e9d26c6fff",
            price: 161.304,
            price_sources: [
              {
                close: 161.304,
                high: 161.304,
                lag_ms: 232,
                low: 161.304,
                open: 161.304,
                source: "Polygon_ws",
                start_ms: 1720079042999,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 161.304,
                websocket: true,
              },
              {
                close: 161.307,
                high: 161.307,
                lag_ms: 233,
                low: 161.307,
                open: 161.307,
                source: "TwelveData_ws",
                start_ms: 1720079043000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1720079042767,
          },
          {
            leverage: 0.18832076377704757,
            order_type: "LONG",
            order_uuid: "3bd91ea5-b44a-4d01-b218-a637007813b0",
            price: 161.231,
            price_sources: [
              {
                close: 161.231,
                high: 161.231,
                lag_ms: 168,
                low: 161.231,
                open: 161.231,
                source: "Polygon_ws",
                start_ms: 1720080372999,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 161.23,
                websocket: true,
              },
              {
                close: 161.234,
                high: 161.234,
                lag_ms: 169,
                low: 161.234,
                open: 161.234,
                source: "TwelveData_ws",
                start_ms: 1720080373000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1720080372831,
          },
          {
            leverage: 0.2536199047632502,
            order_type: "LONG",
            order_uuid: "3308094e-ac55-435f-8953-f26485da97b7",
            price: 161.18,
            price_sources: [
              {
                close: 161.18,
                high: 161.18,
                lag_ms: 108,
                low: 161.18,
                open: 161.18,
                source: "TwelveData_ws",
                start_ms: 1720088450000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
              {
                close: 161.183,
                high: 161.183,
                lag_ms: 892,
                low: 161.183,
                open: 161.183,
                source: "Polygon_ws",
                start_ms: 1720088451000,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 161.184,
                websocket: true,
              },
            ],
            processed_ms: 1720088450108,
          },
          {
            leverage: 0.19280739162179075,
            order_type: "LONG",
            order_uuid: "89616d75-6425-4c5d-998b-407aeb749f27",
            price: 161.12650000000002,
            price_sources: [
              {
                close: 161.12650000000002,
                high: 161.12650000000002,
                lag_ms: 358,
                low: 161.12650000000002,
                open: 161.12650000000002,
                source: "Polygon_ws",
                start_ms: 1720088722000,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 161.127,
                websocket: true,
              },
              {
                close: 161.124,
                high: 161.124,
                lag_ms: 3642,
                low: 161.124,
                open: 161.124,
                source: "TwelveData_ws",
                start_ms: 1720088726000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1720088722358,
          },
          {
            leverage: 0.21279106363887718,
            order_type: "LONG",
            order_uuid: "36675898-db74-4aea-adb9-6bde780a3901",
            price: 161.058,
            price_sources: [
              {
                close: 161.058,
                high: 161.058,
                lag_ms: 400,
                low: 161.058,
                open: 161.058,
                source: "Polygon_ws",
                start_ms: 1720090681000,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 161.058,
                websocket: true,
              },
              {
                close: 161.058,
                high: 161.058,
                lag_ms: 600,
                low: 161.058,
                open: 161.058,
                source: "TwelveData_ws",
                start_ms: 1720090682000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1720090681400,
          },
          {
            leverage: 0.26201710264224765,
            order_type: "LONG",
            order_uuid: "f7adc9f5-147a-4ed0-bd9c-4944ceaa360c",
            price: 161.013,
            price_sources: [
              {
                close: 161.013,
                high: 161.013,
                lag_ms: 374,
                low: 161.013,
                open: 161.013,
                source: "TwelveData_ws",
                start_ms: 1720091053000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
              {
                close: 161.008,
                high: 161.008,
                lag_ms: 375,
                low: 161.008,
                open: 161.008,
                source: "Polygon_ws",
                start_ms: 1720091052999,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 161.01,
                websocket: true,
              },
            ],
            processed_ms: 1720091053374,
          },
          {
            leverage: 0.22687908763057607,
            order_type: "LONG",
            order_uuid: "71b4d7b6-59af-47d3-b8cd-d2a47118b00f",
            price: 160.9525,
            price_sources: [
              {
                close: 160.9525,
                high: 160.9525,
                lag_ms: 67,
                low: 160.9525,
                open: 160.9525,
                source: "Polygon_ws",
                start_ms: 1720091134999,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 160.952,
                websocket: true,
              },
              {
                close: 160.956,
                high: 160.956,
                lag_ms: 932,
                low: 160.956,
                open: 160.956,
                source: "TwelveData_ws",
                start_ms: 1720091134000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1720091134932,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "63c21326-9aa6-4b4c-895b-ae8b0c746b98",
            price: 161.201,
            price_sources: [
              {
                close: 161.201,
                high: 161.201,
                lag_ms: 345,
                low: 161.201,
                open: 161.201,
                source: "Polygon_ws",
                start_ms: 1720116118999,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 161.201,
                websocket: true,
              },
              {
                close: 161.203,
                high: 161.203,
                lag_ms: 346,
                low: 161.203,
                open: 161.203,
                source: "TwelveData_ws",
                start_ms: 1720116119000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1720116118654,
          },
        ],
        position_type: "FLAT",
        position_uuid: "17cc21df-75f1-40c0-86b2-9ade41e65b73",
        return_at_close: 1.0001902400749374,
        trade_pair: ["USDJPY", "USD/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 161.198,
        close_ms: 1720127135424,
        current_return: 1.0001656533249192,
        initial_entry_price: 161.198,
        is_closed_position: true,
        miner_hotkey: "5HgBDcx8Z9oEWrGQm7obH4aPE2M5YXWA6S6MP1HtFHguUqek",
        net_leverage: 0.0,
        open_ms: 1720116135719,
        orders: [
          {
            leverage: 0.24724985805865218,
            order_type: "LONG",
            order_uuid: "f710efa3-22a6-4766-911f-3a306c4ea85e",
            price: 161.198,
            price_sources: [
              {
                close: 161.198,
                high: 161.198,
                lag_ms: 305,
                low: 161.198,
                open: 161.198,
                source: "Polygon_ws",
                start_ms: 1720116135999,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 161.198,
                websocket: true,
              },
              {
                close: 161.201,
                high: 161.201,
                lag_ms: 306,
                low: 161.201,
                open: 161.201,
                source: "TwelveData_ws",
                start_ms: 1720116136000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1720116135694,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "2d1c4ccc-6bb2-47f5-82b1-41fc36484eb2",
            price: 161.306,
            price_sources: [
              {
                close: 161.306,
                high: 161.306,
                lag_ms: 424,
                low: 161.306,
                open: 161.306,
                source: "Polygon_ws",
                start_ms: 1720127135000,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 161.306,
                websocket: true,
              },
              {
                close: 161.268,
                high: 161.268,
                lag_ms: 424,
                low: 161.268,
                open: 161.268,
                source: "TwelveData_ws",
                start_ms: 1720127135000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1720127135424,
          },
        ],
        position_type: "FLAT",
        position_uuid: "f710efa3-22a6-4766-911f-3a306c4ea85e",
        return_at_close: 1.0001483429678117,
        trade_pair: ["USDJPY", "USD/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 161.2502600200435,
        close_ms: 1720132889229,
        current_return: 1.0001109417913063,
        initial_entry_price: 161.309,
        is_closed_position: true,
        miner_hotkey: "5HgBDcx8Z9oEWrGQm7obH4aPE2M5YXWA6S6MP1HtFHguUqek",
        net_leverage: 0.0,
        open_ms: 1720127160252,
        orders: [
          {
            leverage: 0.21665139121816085,
            order_type: "LONG",
            order_uuid: "008eb14f-a0fe-4e8f-a068-a5d408bc0112",
            price: 161.309,
            price_sources: [
              {
                close: 161.309,
                high: 161.309,
                lag_ms: 240,
                low: 161.309,
                open: 161.309,
                source: "Polygon_ws",
                start_ms: 1720127160000,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 161.309,
                websocket: true,
              },
              {
                close: 161.264,
                high: 161.264,
                lag_ms: 240,
                low: 161.264,
                open: 161.264,
                source: "TwelveData_ws",
                start_ms: 1720127160000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
              {
                close: 161.308,
                high: 161.308,
                lag_ms: 3761,
                low: 161.308,
                open: 161.308,
                source: "Polygon_rest",
                start_ms: 1720127155000,
                timespan_ms: 1000,
                volume: 1.0,
                vwap: null,
                websocket: false,
              },
              {
                close: 161.295,
                high: 161.30499,
                lag_ms: 119761,
                low: 161.295,
                open: 161.30499,
                source: "TwelveData_rest",
                start_ms: 1720126980000,
                timespan_ms: 60000,
                volume: null,
                vwap: null,
                websocket: false,
              },
            ],
            processed_ms: 1720127159760,
          },
          {
            leverage: 0.19743079886946094,
            order_type: "LONG",
            order_uuid: "b0a1132b-a5fb-4221-9a8f-f915b9ade77a",
            price: 161.288,
            price_sources: [
              {
                close: 161.288,
                high: 161.288,
                lag_ms: 164,
                low: 161.288,
                open: 161.288,
                source: "Polygon_ws",
                start_ms: 1720127183999,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 161.288,
                websocket: true,
              },
              {
                close: 161.245,
                high: 161.245,
                lag_ms: 2837,
                low: 161.245,
                open: 161.245,
                source: "TwelveData_ws",
                start_ms: 1720127187000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1720127184163,
          },
          {
            leverage: 0.281175127286467,
            order_type: "LONG",
            order_uuid: "f95c886a-0134-4b30-b8ae-e57d7f064040",
            price: 161.17849999999999,
            price_sources: [
              {
                close: 161.17849999999999,
                high: 161.17849999999999,
                lag_ms: 473,
                low: 161.17849999999999,
                open: 161.17849999999999,
                source: "Polygon_ws",
                start_ms: 1720130647999,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 161.179,
                websocket: true,
              },
              {
                close: 161.173,
                high: 161.173,
                lag_ms: 3526,
                low: 161.173,
                open: 161.173,
                source: "TwelveData_ws",
                start_ms: 1720130644000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1720130647526,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "39b4fe18-3857-429e-9f58-2afdef3b4a2c",
            price: 161.276,
            price_sources: [
              {
                close: 161.276,
                high: 161.276,
                lag_ms: 229,
                low: 161.276,
                open: 161.276,
                source: "Polygon_ws",
                start_ms: 1720132889000,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 161.28,
                websocket: true,
              },
              {
                close: 161.278,
                high: 161.278,
                lag_ms: 1229,
                low: 161.278,
                open: 161.278,
                source: "TwelveData_ws",
                start_ms: 1720132888000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1720132889229,
          },
        ],
        position_type: "FLAT",
        position_uuid: "008eb14f-a0fe-4e8f-a068-a5d408bc0112",
        return_at_close: 1.0000622683797737,
        trade_pair: ["USDJPY", "USD/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 161.28,
        close_ms: 1720136602158,
        current_return: 1.0001384724237556,
        initial_entry_price: 161.28,
        is_closed_position: true,
        miner_hotkey: "5HgBDcx8Z9oEWrGQm7obH4aPE2M5YXWA6S6MP1HtFHguUqek",
        net_leverage: 0.0,
        open_ms: 1720132908503,
        orders: [
          {
            leverage: 0.28631836542712985,
            order_type: "LONG",
            order_uuid: "3e400292-12f4-49e2-8f60-d5e6e5552401",
            price: 161.28,
            price_sources: [
              {
                close: 161.28,
                high: 161.28,
                lag_ms: 375,
                low: 161.28,
                open: 161.28,
                source: "Polygon_ws",
                start_ms: 1720132907999,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 161.28,
                websocket: true,
              },
              {
                close: 161.277,
                high: 161.277,
                lag_ms: 3374,
                low: 161.277,
                open: 161.277,
                source: "TwelveData_ws",
                start_ms: 1720132905000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1720132908374,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "026872b5-a153-45c6-a101-68c2bfeac0be",
            price: 161.358,
            price_sources: [
              {
                close: 161.358,
                high: 161.358,
                lag_ms: 158,
                low: 161.358,
                open: 161.358,
                source: "Polygon_ws",
                start_ms: 1720136602000,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 161.358,
                websocket: true,
              },
              {
                close: 161.357,
                high: 161.357,
                lag_ms: 842,
                low: 161.357,
                open: 161.357,
                source: "TwelveData_ws",
                start_ms: 1720136603000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1720136602158,
          },
        ],
        position_type: "FLAT",
        position_uuid: "3e400292-12f4-49e2-8f60-d5e6e5552401",
        return_at_close: 1.0001184273628718,
        trade_pair: ["USDJPY", "USD/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 160.913876200019,
        close_ms: 1720183048648,
        current_return: 1.0007855857889616,
        initial_entry_price: 161.3745,
        is_closed_position: true,
        miner_hotkey: "5HgBDcx8Z9oEWrGQm7obH4aPE2M5YXWA6S6MP1HtFHguUqek",
        net_leverage: 0.0,
        open_ms: 1720136620003,
        orders: [
          {
            leverage: 0.24931079991822877,
            order_type: "LONG",
            order_uuid: "ba303b9b-b8b9-4ebc-9b79-540f87bf0ed0",
            price: 161.3745,
            price_sources: [
              {
                close: 161.3745,
                high: 161.3745,
                lag_ms: 165,
                low: 161.3745,
                open: 161.3745,
                source: "Polygon_ws",
                start_ms: 1720136619999,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 161.374,
                websocket: true,
              },
              {
                close: 161.366,
                high: 161.366,
                lag_ms: 2834,
                low: 161.366,
                open: 161.366,
                source: "TwelveData_ws",
                start_ms: 1720136617000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1720136619834,
          },
          {
            leverage: 0.220944211830373,
            order_type: "LONG",
            order_uuid: "e23bacd6-ccc7-42e8-b72c-7d0d5eb0fb1e",
            price: 161.3145,
            price_sources: [
              {
                close: 161.3145,
                high: 161.3145,
                lag_ms: 233,
                low: 161.3145,
                open: 161.3145,
                source: "Polygon_ws",
                start_ms: 1720138043000,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 161.316,
                websocket: true,
              },
              {
                close: 161.315,
                high: 161.315,
                lag_ms: 1233,
                low: 161.315,
                open: 161.315,
                source: "TwelveData_ws",
                start_ms: 1720138042000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1720138043233,
          },
          {
            leverage: 0.25060522788337836,
            order_type: "LONG",
            order_uuid: "14f788ab-c66c-4e9c-9a90-bc752392af00",
            price: 161.2425,
            price_sources: [
              {
                close: 161.2425,
                high: 161.2425,
                lag_ms: 169,
                low: 161.2425,
                open: 161.2425,
                source: "Polygon_ws",
                start_ms: 1720139047999,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 161.243,
                websocket: true,
              },
              {
                close: 161.247,
                high: 161.247,
                lag_ms: 170,
                low: 161.247,
                open: 161.247,
                source: "TwelveData_ws",
                start_ms: 1720139048000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1720139047830,
          },
          {
            leverage: 0.1953428153669239,
            order_type: "LONG",
            order_uuid: "370b07a7-5361-4853-b3da-09452a3ed7ef",
            price: 161.178,
            price_sources: [
              {
                close: 161.178,
                high: 161.178,
                lag_ms: 331,
                low: 161.178,
                open: 161.178,
                source: "Polygon_ws",
                start_ms: 1720139313000,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 161.176,
                websocket: true,
              },
              {
                close: 161.18,
                high: 161.18,
                lag_ms: 331,
                low: 161.18,
                open: 161.18,
                source: "TwelveData_ws",
                start_ms: 1720139313000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1720139313331,
          },
          {
            leverage: 0.17324286017897134,
            order_type: "LONG",
            order_uuid: "d2137276-440f-4db9-bddf-53f6e31138e0",
            price: 161.121,
            price_sources: [
              {
                close: 161.121,
                high: 161.121,
                lag_ms: 202,
                low: 161.121,
                open: 161.121,
                source: "Polygon_ws",
                start_ms: 1720139479000,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 161.12,
                websocket: true,
              },
              {
                close: 161.126,
                high: 161.126,
                lag_ms: 798,
                low: 161.126,
                open: 161.126,
                source: "TwelveData_ws",
                start_ms: 1720139480000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1720139479202,
          },
          {
            leverage: 0.19476667134382838,
            order_type: "LONG",
            order_uuid: "8ce92392-4ec7-43df-b5fa-442f0d04f486",
            price: 161.0375,
            price_sources: [
              {
                close: 161.0375,
                high: 161.0375,
                lag_ms: 291,
                low: 161.0375,
                open: 161.0375,
                source: "Polygon_ws",
                start_ms: 1720139563999,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 161.044,
                websocket: true,
              },
              {
                close: 161.007,
                high: 161.007,
                lag_ms: 708,
                low: 161.007,
                open: 161.007,
                source: "TwelveData_ws",
                start_ms: 1720139563000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1720139563708,
          },
          {
            leverage: 0.2672067755287921,
            order_type: "LONG",
            order_uuid: "e2f271ae-2633-466f-a689-56aeafcee8c3",
            price: 161.0,
            price_sources: [
              {
                close: 161.0,
                high: 161.0,
                lag_ms: 4,
                low: 161.0,
                open: 161.0,
                source: "Polygon_ws",
                start_ms: 1720139595000,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 160.993,
                websocket: true,
              },
              {
                close: 161.001,
                high: 161.001,
                lag_ms: 996,
                low: 161.001,
                open: 161.001,
                source: "TwelveData_ws",
                start_ms: 1720139596000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1720139595004,
          },
          {
            leverage: 0.2328797389425314,
            order_type: "LONG",
            order_uuid: "c0f1abde-3648-49b0-b2a8-e6a3ce7881a9",
            price: 160.92,
            price_sources: [
              {
                close: 160.92,
                high: 160.92,
                lag_ms: 109,
                low: 160.92,
                open: 160.92,
                source: "Polygon_ws",
                start_ms: 1720140348000,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 160.92,
                websocket: true,
              },
              {
                close: 160.921,
                high: 160.921,
                lag_ms: 891,
                low: 160.921,
                open: 160.921,
                source: "TwelveData_ws",
                start_ms: 1720140349000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1720140348109,
          },
          {
            leverage: 0.25619088619541613,
            order_type: "LONG",
            order_uuid: "e1aa4443-802f-4540-9d44-eb7d2e07bad8",
            price: 160.792,
            price_sources: [
              {
                close: 160.792,
                high: 160.792,
                lag_ms: 38,
                low: 160.792,
                open: 160.792,
                source: "Polygon_ws",
                start_ms: 1720149006000,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 160.794,
                websocket: true,
              },
              {
                close: 160.795,
                high: 160.795,
                lag_ms: 38,
                low: 160.795,
                open: 160.795,
                source: "TwelveData_ws",
                start_ms: 1720149006000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1720149006038,
          },
          {
            leverage: 0.26836352119602125,
            order_type: "LONG",
            order_uuid: "c4aa9fc3-39b0-430c-8a3d-8437d6863f7b",
            price: 160.73950000000002,
            price_sources: [
              {
                close: 160.73950000000002,
                high: 160.73950000000002,
                lag_ms: 160,
                low: 160.73950000000002,
                open: 160.73950000000002,
                source: "Polygon_ws",
                start_ms: 1720149578999,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 160.74,
                websocket: true,
              },
              {
                close: 160.743,
                high: 160.743,
                lag_ms: 161,
                low: 160.743,
                open: 160.743,
                source: "TwelveData_ws",
                start_ms: 1720149579000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1720149578839,
          },
          {
            leverage: 0.22325555997920063,
            order_type: "LONG",
            order_uuid: "265d714e-c831-40d7-ad7c-2db82fe02068",
            price: 160.642,
            price_sources: [
              {
                close: 160.642,
                high: 160.642,
                lag_ms: 244,
                low: 160.642,
                open: 160.642,
                source: "Polygon_ws",
                start_ms: 1720149641000,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 160.642,
                websocket: true,
              },
              {
                close: 160.641,
                high: 160.641,
                lag_ms: 244,
                low: 160.641,
                open: 160.641,
                source: "TwelveData_ws",
                start_ms: 1720149641000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1720149641244,
          },
          {
            leverage: 0.2730845298587073,
            order_type: "LONG",
            order_uuid: "714d506c-4837-42af-89b7-420dbd15c680",
            price: 160.561,
            price_sources: [
              {
                close: 160.561,
                high: 160.561,
                lag_ms: 6,
                low: 160.561,
                open: 160.561,
                source: "Polygon_ws",
                start_ms: 1720149726999,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 160.562,
                websocket: true,
              },
              {
                close: 160.566,
                high: 160.566,
                lag_ms: 7,
                low: 160.566,
                open: 160.566,
                source: "TwelveData_ws",
                start_ms: 1720149727000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1720149726993,
          },
          {
            leverage: 0.2825441648015892,
            order_type: "LONG",
            order_uuid: "a36ee403-e669-43b0-a561-807782cdcffc",
            price: 160.546,
            price_sources: [
              {
                close: 160.546,
                high: 160.546,
                lag_ms: 122,
                low: 160.546,
                open: 160.546,
                source: "Polygon_ws",
                start_ms: 1720149746999,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 160.544,
                websocket: true,
              },
              {
                close: 160.551,
                high: 160.551,
                lag_ms: 123,
                low: 160.551,
                open: 160.551,
                source: "TwelveData_ws",
                start_ms: 1720149747000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1720149746877,
          },
          {
            leverage: 0.1525792297606773,
            order_type: "LONG",
            order_uuid: "c2075bb1-d742-485e-9030-71b7ba7b7ce3",
            price: 160.372,
            price_sources: [
              {
                close: 160.372,
                high: 160.372,
                lag_ms: 19,
                low: 160.372,
                open: 160.372,
                source: "Polygon_ws",
                start_ms: 1720182653999,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 160.371,
                websocket: true,
              },
              {
                close: 160.371,
                high: 160.371,
                lag_ms: 20,
                low: 160.371,
                open: 160.371,
                source: "TwelveData_ws",
                start_ms: 1720182654000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1720182653980,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "c1fe85a0-4016-426b-b1c1-51b8299053f3",
            price: 160.953,
            price_sources: [
              {
                close: 160.953,
                high: 160.953,
                lag_ms: 351,
                low: 160.953,
                open: 160.953,
                source: "Polygon_ws",
                start_ms: 1720183048999,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 160.959,
                websocket: true,
              },
              {
                close: 160.954,
                high: 160.954,
                lag_ms: 352,
                low: 160.954,
                open: 160.954,
                source: "TwelveData_ws",
                start_ms: 1720183049000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1720183048648,
          },
        ],
        position_type: "FLAT",
        position_uuid: "ba303b9b-b8b9-4ebc-9b79-540f87bf0ed0",
        return_at_close: 1.000558585411178,
        trade_pair: ["USDJPY", "USD/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 161.01979132147883,
        close_ms: 1720183151884,
        current_return: 1.000201675238437,
        initial_entry_price: 161.066,
        is_closed_position: true,
        miner_hotkey: "5HgBDcx8Z9oEWrGQm7obH4aPE2M5YXWA6S6MP1HtFHguUqek",
        net_leverage: 0.0,
        open_ms: 1720183066145,
        orders: [
          {
            leverage: 0.2889458186050878,
            order_type: "LONG",
            order_uuid: "d634329b-12c4-4fc7-913c-c6a60f3c8bdf",
            price: 161.066,
            price_sources: [
              {
                close: 161.066,
                high: 161.066,
                lag_ms: 160,
                low: 161.066,
                open: 161.066,
                source: "Polygon_ws",
                start_ms: 1720183065999,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 161.066,
                websocket: true,
              },
              {
                close: 161.067,
                high: 161.067,
                lag_ms: 161,
                low: 161.067,
                open: 161.067,
                source: "TwelveData_ws",
                start_ms: 1720183066000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1720183065839,
          },
          {
            leverage: 0.2290530408855334,
            order_type: "LONG",
            order_uuid: "b701de23-4336-454f-80f2-79cb30b74a46",
            price: 160.9615,
            price_sources: [
              {
                close: 160.9615,
                high: 160.9615,
                lag_ms: 235,
                low: 160.9615,
                open: 160.9615,
                source: "Polygon_ws",
                start_ms: 1720183109999,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 160.965,
                websocket: true,
              },
              {
                close: 160.958,
                high: 160.958,
                lag_ms: 236,
                low: 160.958,
                open: 160.958,
                source: "TwelveData_ws",
                start_ms: 1720183110000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1720183109764,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "9e07dce6-f6d1-4769-bcbe-3a79bc192a65",
            price: 161.08249999999998,
            price_sources: [
              {
                close: 161.08249999999998,
                high: 161.08249999999998,
                lag_ms: 115,
                low: 161.08249999999998,
                open: 161.08249999999998,
                source: "Polygon_ws",
                start_ms: 1720183151999,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 161.081,
                websocket: true,
              },
              {
                close: 161.086,
                high: 161.086,
                lag_ms: 116,
                low: 161.086,
                open: 161.086,
                source: "TwelveData_ws",
                start_ms: 1720183152000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1720183151884,
          },
        ],
        position_type: "FLAT",
        position_uuid: "d634329b-12c4-4fc7-913c-c6a60f3c8bdf",
        return_at_close: 1.0001654080055447,
        trade_pair: ["USDJPY", "USD/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 160.89698265219764,
        close_ms: 1720183748007,
        current_return: 1.000336650255791,
        initial_entry_price: 161.054,
        is_closed_position: true,
        miner_hotkey: "5HgBDcx8Z9oEWrGQm7obH4aPE2M5YXWA6S6MP1HtFHguUqek",
        net_leverage: 0.0,
        open_ms: 1720183170228,
        orders: [
          {
            leverage: 0.1884122309366143,
            order_type: "LONG",
            order_uuid: "6934d471-1404-4b5c-a07a-a55b98bad05e",
            price: 161.054,
            price_sources: [
              {
                close: 161.054,
                high: 161.054,
                lag_ms: 97,
                low: 161.054,
                open: 161.054,
                source: "Polygon_ws",
                start_ms: 1720183169999,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 161.054,
                websocket: true,
              },
              {
                close: 161.099,
                high: 161.099,
                lag_ms: 98,
                low: 161.099,
                open: 161.099,
                source: "TwelveData_ws",
                start_ms: 1720183170000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1720183169902,
          },
          {
            leverage: 0.15407177719444473,
            order_type: "LONG",
            order_uuid: "a5e39e59-3817-4cda-88d4-c49dd661a418",
            price: 160.99349999999998,
            price_sources: [
              {
                close: 160.99349999999998,
                high: 160.99349999999998,
                lag_ms: 7,
                low: 160.99349999999998,
                open: 160.99349999999998,
                source: "Polygon_ws",
                start_ms: 1720183271999,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 160.993,
                websocket: true,
              },
              {
                close: 161.003,
                high: 161.003,
                lag_ms: 8,
                low: 161.003,
                open: 161.003,
                source: "TwelveData_ws",
                start_ms: 1720183272000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1720183271992,
          },
          {
            leverage: 0.21080878771651415,
            order_type: "LONG",
            order_uuid: "8c4c9a7f-5157-4062-994b-3444732eb566",
            price: 160.9,
            price_sources: [
              {
                close: 160.9,
                high: 160.9,
                lag_ms: 121,
                low: 160.9,
                open: 160.9,
                source: "Polygon_ws",
                start_ms: 1720183436000,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 160.933,
                websocket: true,
              },
              {
                close: 160.903,
                high: 160.903,
                lag_ms: 121,
                low: 160.903,
                open: 160.903,
                source: "TwelveData_ws",
                start_ms: 1720183436000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1720183436121,
          },
          {
            leverage: 0.29776179683804954,
            order_type: "LONG",
            order_uuid: "0a6801f6-fdb2-4ea7-b55b-055392febad9",
            price: 160.85649999999998,
            price_sources: [
              {
                close: 160.85649999999998,
                high: 160.85649999999998,
                lag_ms: 76,
                low: 160.85649999999998,
                open: 160.85649999999998,
                source: "Polygon_ws",
                start_ms: 1720183458000,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 160.858,
                websocket: true,
              },
              {
                close: 160.855,
                high: 160.855,
                lag_ms: 1076,
                low: 160.855,
                open: 160.855,
                source: "TwelveData_ws",
                start_ms: 1720183457000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1720183458076,
          },
          {
            leverage: 0.26646053854157237,
            order_type: "LONG",
            order_uuid: "e4408e0a-7ea8-46b1-afee-0e2c246c43bc",
            price: 160.773,
            price_sources: [
              {
                close: 160.773,
                high: 160.773,
                lag_ms: 185,
                low: 160.773,
                open: 160.773,
                source: "Polygon_ws",
                start_ms: 1720183646000,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 160.771,
                websocket: true,
              },
              {
                close: 160.774,
                high: 160.774,
                lag_ms: 185,
                low: 160.774,
                open: 160.774,
                source: "TwelveData_ws",
                start_ms: 1720183646000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1720183646185,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "4c437b68-266a-4a66-87a6-3be40d2067c3",
            price: 160.9455,
            price_sources: [
              {
                close: 160.9455,
                high: 160.9455,
                lag_ms: 7,
                low: 160.9455,
                open: 160.9455,
                source: "Polygon_ws",
                start_ms: 1720183748000,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 160.943,
                websocket: true,
              },
              {
                close: 160.948,
                high: 160.948,
                lag_ms: 7,
                low: 160.948,
                open: 160.948,
                source: "TwelveData_ws",
                start_ms: 1720183748000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1720183748007,
          },
        ],
        position_type: "FLAT",
        position_uuid: "6934d471-1404-4b5c-a07a-a55b98bad05e",
        return_at_close: 1.0002583978617823,
        trade_pair: ["USDJPY", "USD/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 160.93857732946196,
        close_ms: 1720183939376,
        current_return: 1.0002084667242281,
        initial_entry_price: 160.96,
        is_closed_position: true,
        miner_hotkey: "5HgBDcx8Z9oEWrGQm7obH4aPE2M5YXWA6S6MP1HtFHguUqek",
        net_leverage: 0.0,
        open_ms: 1720183765850,
        orders: [
          {
            leverage: 0.1902366061160295,
            order_type: "LONG",
            order_uuid: "fa9c4b73-2fc7-4aa7-981f-ce1b2959759a",
            price: 160.96,
            price_sources: [
              {
                close: 160.96,
                high: 160.96,
                lag_ms: 239,
                low: 160.96,
                open: 160.96,
                source: "Polygon_ws",
                start_ms: 1720183765999,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 160.958,
                websocket: true,
              },
              {
                close: 160.966,
                high: 160.966,
                lag_ms: 760,
                low: 160.966,
                open: 160.966,
                source: "TwelveData_ws",
                start_ms: 1720183765000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1720183765760,
          },
          {
            leverage: 0.27956946076727957,
            order_type: "LONG",
            order_uuid: "104f827f-bfef-4bf5-b65d-2d65a17aa8d1",
            price: 160.924,
            price_sources: [
              {
                close: 160.924,
                high: 160.924,
                lag_ms: 87,
                low: 160.924,
                open: 160.924,
                source: "Polygon_ws",
                start_ms: 1720183913999,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 160.922,
                websocket: true,
              },
              {
                close: 160.927,
                high: 160.927,
                lag_ms: 88,
                low: 160.927,
                open: 160.927,
                source: "TwelveData_ws",
                start_ms: 1720183914000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1720183913912,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "9d5a747c-3564-4ffe-9163-d4ba2c1fdf83",
            price: 161.01,
            price_sources: [
              {
                close: 161.01,
                high: 161.01,
                lag_ms: 376,
                low: 161.01,
                open: 161.01,
                source: "Polygon_ws",
                start_ms: 1720183939000,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 161.01,
                websocket: true,
              },
              {
                close: 161.024,
                high: 161.024,
                lag_ms: 376,
                low: 161.024,
                open: 161.024,
                source: "TwelveData_ws",
                start_ms: 1720183939000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1720183939376,
          },
        ],
        position_type: "FLAT",
        position_uuid: "fa9c4b73-2fc7-4aa7-981f-ce1b2959759a",
        return_at_close: 1.0001755734438211,
        trade_pair: ["USDJPY", "USD/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 160.94892303271104,
        close_ms: 1720184708705,
        current_return: 1.0001574730048295,
        initial_entry_price: 160.99450000000002,
        is_closed_position: true,
        miner_hotkey: "5HgBDcx8Z9oEWrGQm7obH4aPE2M5YXWA6S6MP1HtFHguUqek",
        net_leverage: 0.0,
        open_ms: 1720183956282,
        orders: [
          {
            leverage: 0.27282511356546535,
            order_type: "LONG",
            order_uuid: "f5d4b66e-5769-488b-b6ea-b269041ba850",
            price: 160.99450000000002,
            price_sources: [
              {
                close: 160.99450000000002,
                high: 160.99450000000002,
                lag_ms: 40,
                low: 160.99450000000002,
                open: 160.99450000000002,
                source: "Polygon_ws",
                start_ms: 1720183956000,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 160.993,
                websocket: true,
              },
              {
                close: 160.997,
                high: 160.997,
                lag_ms: 40,
                low: 160.997,
                open: 160.997,
                source: "TwelveData_ws",
                start_ms: 1720183956000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1720183956040,
          },
          {
            leverage: 0.24907423690683345,
            order_type: "LONG",
            order_uuid: "0a0df826-145e-48db-8158-676478ec3882",
            price: 160.899,
            price_sources: [
              {
                close: 160.899,
                high: 160.899,
                lag_ms: 292,
                low: 160.899,
                open: 160.899,
                source: "Polygon_ws",
                start_ms: 1720184260000,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 160.912,
                websocket: true,
              },
              {
                close: 160.895,
                high: 160.895,
                lag_ms: 292,
                low: 160.895,
                open: 160.895,
                source: "TwelveData_ws",
                start_ms: 1720184260000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1720184260292,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "ab91e24c-a000-47a3-9449-1c010ec494b4",
            price: 160.9975,
            price_sources: [
              {
                close: 160.9975,
                high: 160.9975,
                lag_ms: 294,
                low: 160.9975,
                open: 160.9975,
                source: "Polygon_ws",
                start_ms: 1720184708999,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 160.992,
                websocket: true,
              },
              {
                close: 161.004,
                high: 161.004,
                lag_ms: 295,
                low: 161.004,
                open: 161.004,
                source: "TwelveData_ws",
                start_ms: 1720184709000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1720184708705,
          },
        ],
        position_type: "FLAT",
        position_uuid: "f5d4b66e-5769-488b-b6ea-b269041ba850",
        return_at_close: 1.0001209342973423,
        trade_pair: ["USDJPY", "USD/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 160.88521742448202,
        close_ms: 1720185316016,
        current_return: 1.0002222657568598,
        initial_entry_price: 161.0,
        is_closed_position: true,
        miner_hotkey: "5HgBDcx8Z9oEWrGQm7obH4aPE2M5YXWA6S6MP1HtFHguUqek",
        net_leverage: 0.0,
        open_ms: 1720184725937,
        orders: [
          {
            leverage: 0.1519996943190471,
            order_type: "LONG",
            order_uuid: "4297b4fe-d9b4-44fe-ac69-35a4beaecdbc",
            price: 161.0,
            price_sources: [
              {
                close: 161.0,
                high: 161.0,
                lag_ms: 138,
                low: 161.0,
                open: 161.0,
                source: "Polygon_ws",
                start_ms: 1720184725999,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 161.0,
                websocket: true,
              },
              {
                close: 160.999,
                high: 160.999,
                lag_ms: 861,
                low: 160.999,
                open: 160.999,
                source: "TwelveData_ws",
                start_ms: 1720184725000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1720184725861,
          },
          {
            leverage: 0.2273884363028899,
            order_type: "LONG",
            order_uuid: "46b10c36-6194-4bba-90a8-9a5b5946ed03",
            price: 160.937,
            price_sources: [
              {
                close: 160.937,
                high: 160.937,
                lag_ms: 95,
                low: 160.937,
                open: 160.937,
                source: "Polygon_ws",
                start_ms: 1720184806000,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 160.935,
                websocket: true,
              },
              {
                close: 160.932,
                high: 160.932,
                lag_ms: 905,
                low: 160.932,
                open: 160.932,
                source: "TwelveData_ws",
                start_ms: 1720184807000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1720184806095,
          },
          {
            leverage: 0.2220863313051837,
            order_type: "LONG",
            order_uuid: "f2174ec0-6da4-4122-96d9-7efc19e02759",
            price: 160.89249999999998,
            price_sources: [
              {
                close: 160.89249999999998,
                high: 160.89249999999998,
                lag_ms: 252,
                low: 160.89249999999998,
                open: 160.89249999999998,
                source: "Polygon_ws",
                start_ms: 1720185020000,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 160.89,
                websocket: true,
              },
              {
                close: 160.892,
                high: 160.892,
                lag_ms: 252,
                low: 160.892,
                open: 160.892,
                source: "TwelveData_ws",
                start_ms: 1720185020000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1720185020252,
          },
          {
            leverage: 0.26326544131034174,
            order_type: "LONG",
            order_uuid: "e703d0b3-689a-4fbf-b7a9-950ba7ae06be",
            price: 160.834,
            price_sources: [
              {
                close: 160.834,
                high: 160.834,
                lag_ms: 12,
                low: 160.834,
                open: 160.834,
                source: "Polygon_ws",
                start_ms: 1720185165999,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 160.833,
                websocket: true,
              },
              {
                close: 160.827,
                high: 160.827,
                lag_ms: 13,
                low: 160.827,
                open: 160.827,
                source: "TwelveData_ws",
                start_ms: 1720185166000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1720185165987,
          },
          {
            leverage: 0.19452767200612783,
            order_type: "LONG",
            order_uuid: "303543c9-6147-406f-b11b-7754f45ae337",
            price: 160.796,
            price_sources: [
              {
                close: 160.796,
                high: 160.796,
                lag_ms: 362,
                low: 160.796,
                open: 160.796,
                source: "Polygon_ws",
                start_ms: 1720185206000,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 160.796,
                websocket: true,
              },
              {
                close: 160.8,
                high: 160.8,
                lag_ms: 362,
                low: 160.8,
                open: 160.8,
                source: "TwelveData_ws",
                start_ms: 1720185206000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1720185206362,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "f0fd7fd0-9ca0-4be3-b59b-91ef1ae45bef",
            price: 160.919,
            price_sources: [
              {
                close: 160.919,
                high: 160.919,
                lag_ms: 16,
                low: 160.919,
                open: 160.919,
                source: "Polygon_ws",
                start_ms: 1720185316000,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 160.922,
                websocket: true,
              },
              {
                close: 160.928,
                high: 160.928,
                lag_ms: 16,
                low: 160.928,
                open: 160.928,
                source: "TwelveData_ws",
                start_ms: 1720185316000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1720185316016,
          },
        ],
        position_type: "FLAT",
        position_uuid: "4297b4fe-d9b4-44fe-ac69-35a4beaecdbc",
        return_at_close: 1.0001481005458692,
        trade_pair: ["USDJPY", "USD/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 160.91559998504985,
        close_ms: 1720186322767,
        current_return: 1.0001502603225667,
        initial_entry_price: 160.946,
        is_closed_position: true,
        miner_hotkey: "5HgBDcx8Z9oEWrGQm7obH4aPE2M5YXWA6S6MP1HtFHguUqek",
        net_leverage: 0.0,
        open_ms: 1720185333975,
        orders: [
          {
            leverage: 0.17018027769866623,
            order_type: "LONG",
            order_uuid: "00ac972d-f8e7-48c0-a149-f41dca80506b",
            price: 160.946,
            price_sources: [
              {
                close: 160.946,
                high: 160.946,
                lag_ms: 210,
                low: 160.946,
                open: 160.946,
                source: "Polygon_ws",
                start_ms: 1720185333999,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 160.942,
                websocket: true,
              },
              {
                close: 160.947,
                high: 160.947,
                lag_ms: 211,
                low: 160.947,
                open: 160.947,
                source: "TwelveData_ws",
                start_ms: 1720185334000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1720185333789,
          },
          {
            leverage: 0.2511401330504307,
            order_type: "LONG",
            order_uuid: "ece48449-a767-4636-98c6-02f2f56ff96e",
            price: 160.895,
            price_sources: [
              {
                close: 160.895,
                high: 160.895,
                lag_ms: 472,
                low: 160.895,
                open: 160.895,
                source: "Polygon_ws",
                start_ms: 1720185768999,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 160.895,
                websocket: true,
              },
              {
                close: 160.898,
                high: 160.898,
                lag_ms: 473,
                low: 160.898,
                open: 160.898,
                source: "TwelveData_ws",
                start_ms: 1720185769000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1720185768527,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "52aee336-923a-4142-8840-9e2abac4dafa",
            price: 160.973,
            price_sources: [
              {
                close: 160.973,
                high: 160.973,
                lag_ms: 232,
                low: 160.973,
                open: 160.973,
                source: "Polygon_ws",
                start_ms: 1720186322999,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 160.976,
                websocket: true,
              },
              {
                close: 160.973,
                high: 160.973,
                lag_ms: 767,
                low: 160.973,
                open: 160.973,
                source: "TwelveData_ws",
                start_ms: 1720186322000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1720186322767,
          },
        ],
        position_type: "FLAT",
        position_uuid: "00ac972d-f8e7-48c0-a149-f41dca80506b",
        return_at_close: 1.0001207634622724,
        trade_pair: ["USDJPY", "USD/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 160.92368292394673,
        close_ms: 1720187168243,
        current_return: 1.0002256465786425,
        initial_entry_price: 160.9665,
        is_closed_position: true,
        miner_hotkey: "5HgBDcx8Z9oEWrGQm7obH4aPE2M5YXWA6S6MP1HtFHguUqek",
        net_leverage: 0.0,
        open_ms: 1720186341123,
        orders: [
          {
            leverage: 0.2377922359663377,
            order_type: "LONG",
            order_uuid: "86b3b7d2-e8fb-4432-a3d8-18cba029c066",
            price: 160.9665,
            price_sources: [
              {
                close: 160.9665,
                high: 160.9665,
                lag_ms: 31,
                low: 160.9665,
                open: 160.9665,
                source: "Polygon_ws",
                start_ms: 1720186341000,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 160.966,
                websocket: true,
              },
              {
                close: 160.964,
                high: 160.964,
                lag_ms: 5969,
                low: 160.964,
                open: 160.964,
                source: "TwelveData_ws",
                start_ms: 1720186347000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1720186341031,
          },
          {
            leverage: 0.29485255633566265,
            order_type: "LONG",
            order_uuid: "95f88ed8-5b0b-4804-bf10-e3c6b3c5bfbb",
            price: 160.9235,
            price_sources: [
              {
                close: 160.9235,
                high: 160.9235,
                lag_ms: 80,
                low: 160.9235,
                open: 160.9235,
                source: "Polygon_ws",
                start_ms: 1720186404000,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 160.923,
                websocket: true,
              },
              {
                close: 160.927,
                high: 160.927,
                lag_ms: 80,
                low: 160.927,
                open: 160.927,
                source: "TwelveData_ws",
                start_ms: 1720186404000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1720186404080,
          },
          {
            leverage: 0.20384534271372207,
            order_type: "LONG",
            order_uuid: "e881dc91-64fd-4854-8b8b-eee199c0c66c",
            price: 160.874,
            price_sources: [
              {
                close: 160.874,
                high: 160.874,
                lag_ms: 36,
                low: 160.874,
                open: 160.874,
                source: "Polygon_ws",
                start_ms: 1720186466999,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 160.874,
                websocket: true,
              },
              {
                close: 160.877,
                high: 160.877,
                lag_ms: 963,
                low: 160.877,
                open: 160.877,
                source: "TwelveData_ws",
                start_ms: 1720186466000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1720186466963,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "b096f1b2-4b71-48ae-8f28-a31d2f5b9509",
            price: 160.973,
            price_sources: [
              {
                close: 160.973,
                high: 160.973,
                lag_ms: 243,
                low: 160.973,
                open: 160.973,
                source: "Polygon_ws",
                start_ms: 1720187168000,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 160.973,
                websocket: true,
              },
              {
                close: 160.974,
                high: 160.974,
                lag_ms: 243,
                low: 160.974,
                open: 160.974,
                source: "TwelveData_ws",
                start_ms: 1720187168000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1720187168243,
          },
        ],
        position_type: "FLAT",
        position_uuid: "86b3b7d2-e8fb-4432-a3d8-18cba029c066",
        return_at_close: 1.0001740806361379,
        trade_pair: ["USDJPY", "USD/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 160.97,
        close_ms: 1720187486290,
        current_return: 1.0002151541041169,
        initial_entry_price: 160.97,
        is_closed_position: true,
        miner_hotkey: "5HgBDcx8Z9oEWrGQm7obH4aPE2M5YXWA6S6MP1HtFHguUqek",
        net_leverage: 0.0,
        open_ms: 1720187185812,
        orders: [
          {
            leverage: 0.2124745775440693,
            order_type: "LONG",
            order_uuid: "2f39d9fe-f0cc-4819-9224-99132bcbe0cc",
            price: 160.97,
            price_sources: [
              {
                close: 160.97,
                high: 160.97,
                lag_ms: 251,
                low: 160.97,
                open: 160.97,
                source: "Polygon_ws",
                start_ms: 1720187186000,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 160.97,
                websocket: true,
              },
              {
                close: 160.968,
                high: 160.968,
                lag_ms: 749,
                low: 160.968,
                open: 160.968,
                source: "TwelveData_ws",
                start_ms: 1720187185000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1720187185749,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "c2f30b7c-9649-45a1-aef6-59525eb3aaa7",
            price: 161.133,
            price_sources: [
              {
                close: 161.133,
                high: 161.133,
                lag_ms: 290,
                low: 161.133,
                open: 161.133,
                source: "Polygon_ws",
                start_ms: 1720187486000,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 161.133,
                websocket: true,
              },
              {
                close: 161.132,
                high: 161.132,
                lag_ms: 710,
                low: 161.132,
                open: 161.132,
                source: "TwelveData_ws",
                start_ms: 1720187487000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1720187486290,
          },
        ],
        position_type: "FLAT",
        position_uuid: "2f39d9fe-f0cc-4819-9224-99132bcbe0cc",
        return_at_close: 1.0002002776836545,
        trade_pair: ["USDJPY", "USD/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 161.12900000000002,
        close_ms: 1720187584734,
        current_return: 1.0001780774477396,
        initial_entry_price: 161.129,
        is_closed_position: true,
        miner_hotkey: "5HgBDcx8Z9oEWrGQm7obH4aPE2M5YXWA6S6MP1HtFHguUqek",
        net_leverage: 0.0,
        open_ms: 1720187504306,
        orders: [
          {
            leverage: 0.2034995821053031,
            order_type: "LONG",
            order_uuid: "05e93865-800a-4c8a-8e55-2d3529952ccc",
            price: 161.129,
            price_sources: [
              {
                close: 161.129,
                high: 161.129,
                lag_ms: 19,
                low: 161.129,
                open: 161.129,
                source: "Polygon_ws",
                start_ms: 1720187503999,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 161.129,
                websocket: true,
              },
              {
                close: 161.129,
                high: 161.129,
                lag_ms: 20,
                low: 161.129,
                open: 161.129,
                source: "TwelveData_ws",
                start_ms: 1720187504000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1720187503980,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "2f3192ac-c0ea-4fb5-97ea-081803fc5f60",
            price: 161.27,
            price_sources: [
              {
                close: 161.27,
                high: 161.27,
                lag_ms: 265,
                low: 161.27,
                open: 161.27,
                source: "Polygon_ws",
                start_ms: 1720187584999,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 161.271,
                websocket: true,
              },
              {
                close: 161.269,
                high: 161.269,
                lag_ms: 266,
                low: 161.269,
                open: 161.269,
                source: "TwelveData_ws",
                start_ms: 1720187585000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1720187584734,
          },
        ],
        position_type: "FLAT",
        position_uuid: "05e93865-800a-4c8a-8e55-2d3529952ccc",
        return_at_close: 1.0001638299402842,
        trade_pair: ["USDJPY", "USD/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 161.16127864546428,
        close_ms: 1720189802643,
        current_return: 1.0001587133581182,
        initial_entry_price: 161.227,
        is_closed_position: true,
        miner_hotkey: "5HgBDcx8Z9oEWrGQm7obH4aPE2M5YXWA6S6MP1HtFHguUqek",
        net_leverage: 0.0,
        open_ms: 1720187608188,
        orders: [
          {
            leverage: 0.21270739744142236,
            order_type: "LONG",
            order_uuid: "bba48c46-3d29-4d9d-ae4a-365ab95b04ab",
            price: 161.227,
            price_sources: [
              {
                close: 161.227,
                high: 161.227,
                lag_ms: 43,
                low: 161.227,
                open: 161.227,
                source: "Polygon_ws",
                start_ms: 1720187607999,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 161.227,
                websocket: true,
              },
              {
                close: 161.229,
                high: 161.229,
                lag_ms: 44,
                low: 161.229,
                open: 161.229,
                source: "TwelveData_ws",
                start_ms: 1720187608000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1720187607956,
          },
          {
            leverage: 0.29006560815173404,
            order_type: "LONG",
            order_uuid: "76f77678-91ac-4786-bf4a-d0578b1f3994",
            price: 161.161,
            price_sources: [
              {
                close: 161.161,
                high: 161.161,
                lag_ms: 330,
                low: 161.161,
                open: 161.161,
                source: "Polygon_ws",
                start_ms: 1720189008000,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 161.16,
                websocket: true,
              },
              {
                close: 161.163,
                high: 161.163,
                lag_ms: 330,
                low: 161.163,
                open: 161.163,
                source: "TwelveData_ws",
                start_ms: 1720189008000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1720189008330,
          },
          {
            leverage: 0.25606005261713055,
            order_type: "LONG",
            order_uuid: "7405f999-0566-4af2-9777-77aa5bb6c379",
            price: 161.107,
            price_sources: [
              {
                close: 161.107,
                high: 161.107,
                lag_ms: 377,
                low: 161.107,
                open: 161.107,
                source: "Polygon_ws",
                start_ms: 1720189360000,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 161.106,
                websocket: true,
              },
              {
                close: 161.104,
                high: 161.104,
                lag_ms: 377,
                low: 161.104,
                open: 161.104,
                source: "TwelveData_ws",
                start_ms: 1720189360000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1720189360377,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "5c5234f6-5fcb-40ea-99ac-33bceb64a387",
            price: 161.195,
            price_sources: [
              {
                close: 161.195,
                high: 161.195,
                lag_ms: 356,
                low: 161.195,
                open: 161.195,
                source: "Polygon_ws",
                start_ms: 1720189802999,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 161.2,
                websocket: true,
              },
              {
                close: 161.196,
                high: 161.196,
                lag_ms: 357,
                low: 161.196,
                open: 161.196,
                source: "TwelveData_ws",
                start_ms: 1720189803000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1720189802643,
          },
        ],
        position_type: "FLAT",
        position_uuid: "bba48c46-3d29-4d9d-ae4a-365ab95b04ab",
        return_at_close: 1.0001055866134576,
        trade_pair: ["USDJPY", "USD/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 160.8559849321789,
        close_ms: 1720473002528,
        current_return: 0.999152999318152,
        initial_entry_price: 161.165,
        is_closed_position: true,
        miner_hotkey: "5HgBDcx8Z9oEWrGQm7obH4aPE2M5YXWA6S6MP1HtFHguUqek",
        net_leverage: 0.0,
        open_ms: 1720189819698,
        orders: [
          {
            leverage: 0.2533800543998335,
            order_type: "LONG",
            order_uuid: "d64ef3d7-8ca8-4c07-ab6d-966ad2344b53",
            price: 161.165,
            price_sources: [
              {
                close: 161.165,
                high: 161.165,
                lag_ms: 352,
                low: 161.165,
                open: 161.165,
                source: "Polygon_ws",
                start_ms: 1720189819999,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 161.165,
                websocket: true,
              },
              {
                close: 161.16,
                high: 161.16,
                lag_ms: 353,
                low: 161.16,
                open: 161.16,
                source: "TwelveData_ws",
                start_ms: 1720189820000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1720189819647,
          },
          {
            leverage: 0.1834219770205168,
            order_type: "LONG",
            order_uuid: "5b7756cb-386e-47af-b03a-014db338d6f6",
            price: 161.115,
            price_sources: [
              {
                close: 161.115,
                high: 161.115,
                lag_ms: 63,
                low: 161.115,
                open: 161.115,
                source: "Polygon_ws",
                start_ms: 1720189915999,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 161.115,
                websocket: true,
              },
              {
                close: 161.115,
                high: 161.115,
                lag_ms: 64,
                low: 161.115,
                open: 161.115,
                source: "TwelveData_ws",
                start_ms: 1720189916000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1720189915936,
          },
          {
            leverage: 0.2651195151982159,
            order_type: "LONG",
            order_uuid: "50fee5b9-73a5-47f5-8d31-0aee277ad701",
            price: 161.065,
            price_sources: [
              {
                close: 161.065,
                high: 161.065,
                lag_ms: 345,
                low: 161.065,
                open: 161.065,
                source: "Polygon_ws",
                start_ms: 1720190025000,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 161.065,
                websocket: true,
              },
              {
                close: 161.065,
                high: 161.065,
                lag_ms: 345,
                low: 161.065,
                open: 161.065,
                source: "TwelveData_ws",
                start_ms: 1720190025000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1720190025345,
          },
          {
            leverage: 0.2794549147660218,
            order_type: "LONG",
            order_uuid: "5430b10c-caae-4aa1-af21-dfe26d536a2f",
            price: 160.998,
            price_sources: [
              {
                close: 160.998,
                high: 160.998,
                lag_ms: 484,
                low: 160.998,
                open: 160.998,
                source: "Polygon_ws",
                start_ms: 1720190420999,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 160.998,
                websocket: true,
              },
              {
                close: 161.002,
                high: 161.002,
                lag_ms: 485,
                low: 161.002,
                open: 161.002,
                source: "TwelveData_ws",
                start_ms: 1720190421000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1720190420515,
          },
          {
            leverage: 0.24574222370285514,
            order_type: "LONG",
            order_uuid: "b0e315c9-916c-46e4-a576-afe8fc9fa5f7",
            price: 160.937,
            price_sources: [
              {
                close: 160.937,
                high: 160.937,
                lag_ms: 132,
                low: 160.937,
                open: 160.937,
                source: "Polygon_ws",
                start_ms: 1720190548000,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 160.937,
                websocket: true,
              },
              {
                close: 160.939,
                high: 160.939,
                lag_ms: 868,
                low: 160.939,
                open: 160.939,
                source: "TwelveData_ws",
                start_ms: 1720190549000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1720190548132,
          },
          {
            leverage: 0.2741428296825079,
            order_type: "LONG",
            order_uuid: "804258d1-7af9-46ba-9227-2a8e2da5d9c8",
            price: 160.86950000000002,
            price_sources: [
              {
                close: 160.86950000000002,
                high: 160.86950000000002,
                lag_ms: 192,
                low: 160.86950000000002,
                open: 160.86950000000002,
                source: "Polygon_ws",
                start_ms: 1720192385999,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 160.87,
                websocket: true,
              },
              {
                close: 160.871,
                high: 160.871,
                lag_ms: 193,
                low: 160.871,
                open: 160.871,
                source: "TwelveData_ws",
                start_ms: 1720192386000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1720192385807,
          },
          {
            leverage: 0.2752606267114453,
            order_type: "LONG",
            order_uuid: "551d3752-0df2-449b-887c-f72b25311bcf",
            price: 160.804,
            price_sources: [
              {
                close: 160.804,
                high: 160.804,
                lag_ms: 472,
                low: 160.804,
                open: 160.804,
                source: "Polygon_ws",
                start_ms: 1720192776000,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 160.807,
                websocket: true,
              },
              {
                close: 160.809,
                high: 160.809,
                lag_ms: 472,
                low: 160.809,
                open: 160.809,
                source: "TwelveData_ws",
                start_ms: 1720192776000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1720192776472,
          },
          {
            leverage: 0.1572687314099737,
            order_type: "LONG",
            order_uuid: "581cabd4-b119-49b1-a4fa-49bd4fc08f74",
            price: 160.713,
            price_sources: [
              {
                close: 160.713,
                high: 160.713,
                lag_ms: 211,
                low: 160.713,
                open: 160.713,
                source: "Polygon_ws",
                start_ms: 1720192831999,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 160.717,
                websocket: true,
              },
              {
                close: 160.72,
                high: 160.72,
                lag_ms: 212,
                low: 160.72,
                open: 160.72,
                source: "TwelveData_ws",
                start_ms: 1720192832000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1720192831788,
          },
          {
            leverage: 0.17942367551926164,
            order_type: "LONG",
            order_uuid: "09bcf5a7-6a3e-43f0-aac3-3894131b9901",
            price: 160.671,
            price_sources: [
              {
                close: 160.671,
                high: 160.671,
                lag_ms: 369,
                low: 160.671,
                open: 160.671,
                source: "Polygon_ws",
                start_ms: 1720193414000,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 160.671,
                websocket: true,
              },
              {
                close: 160.678,
                high: 160.678,
                lag_ms: 369,
                low: 160.678,
                open: 160.678,
                source: "TwelveData_ws",
                start_ms: 1720193414000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1720193414369,
          },
          {
            leverage: 0.1911615218568337,
            order_type: "LONG",
            order_uuid: "72f7b547-cfe4-4fae-8761-9ccf24708995",
            price: 160.607,
            price_sources: [
              {
                close: 160.607,
                high: 160.607,
                lag_ms: 170,
                low: 160.607,
                open: 160.607,
                source: "Polygon_ws",
                start_ms: 1720443964999,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 160.607,
                websocket: true,
              },
              {
                close: 160.604,
                high: 160.604,
                lag_ms: 829,
                low: 160.604,
                open: 160.604,
                source: "TwelveData_ws",
                start_ms: 1720443964000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1720443964829,
          },
          {
            leverage: 0.17670439452543457,
            order_type: "LONG",
            order_uuid: "dc1194a9-e4ed-41a9-b50b-6c8b20a62507",
            price: 160.542,
            price_sources: [
              {
                close: 160.542,
                high: 160.542,
                lag_ms: 377,
                low: 160.542,
                open: 160.542,
                source: "Polygon_ws",
                start_ms: 1720447952000,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 160.542,
                websocket: true,
              },
              {
                close: 160.542,
                high: 160.542,
                lag_ms: 377,
                low: 160.542,
                open: 160.542,
                source: "TwelveData_ws",
                start_ms: 1720447952000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1720447952377,
          },
          {
            leverage: 0.1963157590767774,
            order_type: "LONG",
            order_uuid: "90c787eb-38d4-4270-a350-7100fad9bd7e",
            price: 160.492,
            price_sources: [
              {
                close: 160.492,
                high: 160.492,
                lag_ms: 247,
                low: 160.492,
                open: 160.492,
                source: "TwelveData_ws",
                start_ms: 1720448591000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
              {
                close: 160.4925,
                high: 160.4925,
                lag_ms: 248,
                low: 160.4925,
                open: 160.4925,
                source: "Polygon_ws",
                start_ms: 1720448590999,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 160.495,
                websocket: true,
              },
            ],
            processed_ms: 1720448591247,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "db6e91f1-8a1f-4c89-b03a-f4a89927de80",
            price: 160.805,
            price_sources: [
              {
                close: 160.805,
                high: 160.805,
                lag_ms: 471,
                low: 160.805,
                open: 160.805,
                source: "Polygon_ws",
                start_ms: 1720473002999,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 160.805,
                websocket: true,
              },
              {
                close: 160.765,
                high: 160.765,
                lag_ms: 528,
                low: 160.765,
                open: 160.765,
                source: "TwelveData_ws",
                start_ms: 1720473002000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1720473002528,
          },
        ],
        position_type: "FLAT",
        position_uuid: "d64ef3d7-8ca8-4c07-ab6d-966ad2344b53",
        return_at_close: 0.9989657403254311,
        trade_pair: ["USDJPY", "USD/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 160.788,
        close_ms: 1720486433215,
        current_return: 1.0001557631812117,
        initial_entry_price: 160.788,
        is_closed_position: true,
        miner_hotkey: "5HgBDcx8Z9oEWrGQm7obH4aPE2M5YXWA6S6MP1HtFHguUqek",
        net_leverage: 0.0,
        open_ms: 1720473023512,
        orders: [
          {
            leverage: 0.2636300040069696,
            order_type: "LONG",
            order_uuid: "ac5a9763-0922-41ff-b250-fed3e1265af4",
            price: 160.788,
            price_sources: [
              {
                close: 160.788,
                high: 160.788,
                lag_ms: 298,
                low: 160.788,
                open: 160.788,
                source: "Polygon_ws",
                start_ms: 1720473022000,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 160.788,
                websocket: true,
              },
              {
                close: 160.76,
                high: 160.76,
                lag_ms: 702,
                low: 160.76,
                open: 160.76,
                source: "TwelveData_ws",
                start_ms: 1720473021000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
              {
                close: 160.784,
                high: 160.784,
                lag_ms: 3703,
                low: 160.784,
                open: 160.784,
                source: "Polygon_rest",
                start_ms: 1720473017000,
                timespan_ms: 1000,
                volume: 1.0,
                vwap: null,
                websocket: false,
              },
              {
                close: 160.81,
                high: 160.81,
                lag_ms: 81703,
                low: 160.8,
                open: 160.8,
                source: "TwelveData_rest",
                start_ms: 1720472880000,
                timespan_ms: 60000,
                volume: null,
                vwap: null,
                websocket: false,
              },
            ],
            processed_ms: 1720473021702,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "09f39f7e-7736-4af4-839e-547918900d89",
            price: 160.88299999999998,
            price_sources: [
              {
                close: 160.88299999999998,
                high: 160.88299999999998,
                lag_ms: 215,
                low: 160.88299999999998,
                open: 160.88299999999998,
                source: "Polygon_ws",
                start_ms: 1720486433000,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 160.881,
                websocket: true,
              },
              {
                close: 160.881,
                high: 160.881,
                lag_ms: 215,
                low: 160.881,
                open: 160.881,
                source: "TwelveData_ws",
                start_ms: 1720486433000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1720486433215,
          },
        ],
        position_type: "FLAT",
        position_uuid: "ac5a9763-0922-41ff-b250-fed3e1265af4",
        return_at_close: 1.0001373062064618,
        trade_pair: ["USDJPY", "USD/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 160.889,
        close_ms: 1720490611110,
        current_return: 1.0001883648678742,
        initial_entry_price: 160.889,
        is_closed_position: true,
        miner_hotkey: "5HgBDcx8Z9oEWrGQm7obH4aPE2M5YXWA6S6MP1HtFHguUqek",
        net_leverage: 0.0,
        open_ms: 1720486451060,
        orders: [
          {
            leverage: 0.24440189699515405,
            order_type: "LONG",
            order_uuid: "5c324cc7-a24a-49c0-91ad-42bd4fe8da30",
            price: 160.889,
            price_sources: [
              {
                close: 160.889,
                high: 160.889,
                lag_ms: 115,
                low: 160.889,
                open: 160.889,
                source: "Polygon_ws",
                start_ms: 1720486450999,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 160.889,
                websocket: true,
              },
              {
                close: 160.885,
                high: 160.885,
                lag_ms: 116,
                low: 160.885,
                open: 160.885,
                source: "TwelveData_ws",
                start_ms: 1720486451000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1720486450884,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "8f50fdfb-afba-410a-a66b-f7068c501414",
            price: 161.013,
            price_sources: [
              {
                close: 161.013,
                high: 161.013,
                lag_ms: 110,
                low: 161.013,
                open: 161.013,
                source: "Polygon_ws",
                start_ms: 1720490611000,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 161.013,
                websocket: true,
              },
              {
                close: 161.013,
                high: 161.013,
                lag_ms: 110,
                low: 161.013,
                open: 161.013,
                source: "TwelveData_ws",
                start_ms: 1720490611000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
              {
                close: 161.02100000000002,
                high: 161.02100000000002,
                lag_ms: 111,
                low: 161.02100000000002,
                open: 161.02100000000002,
                source: "Polygon_rest",
                start_ms: 1720490610000,
                timespan_ms: 1000,
                volume: 2.0,
                vwap: null,
                websocket: false,
              },
              {
                close: 161.015,
                high: 161.015,
                lag_ms: 631111,
                low: 161.015,
                open: 161.015,
                source: "TwelveData_rest",
                start_ms: 1720489920000,
                timespan_ms: 60000,
                volume: null,
                vwap: null,
                websocket: false,
              },
            ],
            processed_ms: 1720490611110,
          },
        ],
        position_type: "FLAT",
        position_uuid: "5c324cc7-a24a-49c0-91ad-42bd4fe8da30",
        return_at_close: 1.0001712535125133,
        trade_pair: ["USDJPY", "USD/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 161.019,
        close_ms: 1720491039007,
        current_return: 1.0001507391229103,
        initial_entry_price: 161.019,
        is_closed_position: true,
        miner_hotkey: "5HgBDcx8Z9oEWrGQm7obH4aPE2M5YXWA6S6MP1HtFHguUqek",
        net_leverage: 0.0,
        open_ms: 1720490628043,
        orders: [
          {
            leverage: 0.2333832964603953,
            order_type: "LONG",
            order_uuid: "6c9bbe2c-f2bb-481c-a929-8afecd669ff3",
            price: 161.019,
            price_sources: [
              {
                close: 161.019,
                high: 161.019,
                lag_ms: 5,
                low: 161.019,
                open: 161.019,
                source: "Polygon_ws",
                start_ms: 1720490628000,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 161.019,
                websocket: true,
              },
              {
                close: 161.017,
                high: 161.017,
                lag_ms: 5,
                low: 161.017,
                open: 161.017,
                source: "TwelveData_ws",
                start_ms: 1720490628000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1720490628005,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "78aedb26-3e6f-48ff-ae0a-953b93fddc38",
            price: 161.123,
            price_sources: [
              {
                close: 161.123,
                high: 161.123,
                lag_ms: 7,
                low: 161.123,
                open: 161.123,
                source: "Polygon_ws",
                start_ms: 1720491039000,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 161.121,
                websocket: true,
              },
              {
                close: 161.126,
                high: 161.126,
                lag_ms: 7,
                low: 161.126,
                open: 161.126,
                source: "TwelveData_ws",
                start_ms: 1720491039000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1720491039007,
          },
        ],
        position_type: "FLAT",
        position_uuid: "6c9bbe2c-f2bb-481c-a929-8afecd669ff3",
        return_at_close: 1.0001343998295587,
        trade_pair: ["USDJPY", "USD/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 160.96265205916976,
        close_ms: 1720513670747,
        current_return: 1.000313689743711,
        initial_entry_price: 161.115,
        is_closed_position: true,
        miner_hotkey: "5HgBDcx8Z9oEWrGQm7obH4aPE2M5YXWA6S6MP1HtFHguUqek",
        net_leverage: 0.0,
        open_ms: 1720491059019,
        orders: [
          {
            leverage: 0.25766917516427623,
            order_type: "LONG",
            order_uuid: "849b7500-bb17-4c7b-9cd8-ab477d5b1889",
            price: 161.115,
            price_sources: [
              {
                close: 161.115,
                high: 161.115,
                lag_ms: 236,
                low: 161.115,
                open: 161.115,
                source: "Polygon_ws",
                start_ms: 1720491058000,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 161.115,
                websocket: true,
              },
              {
                close: 161.111,
                high: 161.111,
                lag_ms: 236,
                low: 161.111,
                open: 161.111,
                source: "TwelveData_ws",
                start_ms: 1720491058000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1720491058236,
          },
          {
            leverage: 0.28784087418979226,
            order_type: "LONG",
            order_uuid: "fa7e871b-2342-46af-8b4b-608cfb39a6c5",
            price: 161.0645,
            price_sources: [
              {
                close: 161.0645,
                high: 161.0645,
                lag_ms: 431,
                low: 161.0645,
                open: 161.0645,
                source: "Polygon_ws",
                start_ms: 1720491648999,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 161.064,
                websocket: true,
              },
              {
                close: 161.072,
                high: 161.072,
                lag_ms: 568,
                low: 161.072,
                open: 161.072,
                source: "TwelveData_ws",
                start_ms: 1720491648000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1720491648568,
          },
          {
            leverage: 0.15995470858191083,
            order_type: "LONG",
            order_uuid: "59f8d2f9-99d8-46e8-b50b-ba93b99a3c6c",
            price: 161.015,
            price_sources: [
              {
                close: 161.015,
                high: 161.015,
                lag_ms: 2,
                low: 161.015,
                open: 161.015,
                source: "Polygon_ws",
                start_ms: 1720492293999,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 161.016,
                websocket: true,
              },
              {
                close: 161.012,
                high: 161.012,
                lag_ms: 3,
                low: 161.012,
                open: 161.012,
                source: "TwelveData_ws",
                start_ms: 1720492294000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1720492293997,
          },
          {
            leverage: 0.15995600589183095,
            order_type: "LONG",
            order_uuid: "de1cff69-d596-4fa7-9c1b-11067eb3ade5",
            price: 160.959,
            price_sources: [
              {
                close: 160.959,
                high: 160.959,
                lag_ms: 456,
                low: 160.959,
                open: 160.959,
                source: "Polygon_ws",
                start_ms: 1720493214999,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 160.959,
                websocket: true,
              },
              {
                close: 160.956,
                high: 160.956,
                lag_ms: 545,
                low: 160.956,
                open: 160.956,
                source: "TwelveData_ws",
                start_ms: 1720493216000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1720493215455,
          },
          {
            leverage: 0.22061378348125532,
            order_type: "LONG",
            order_uuid: "668a2677-b465-4f9f-bb0c-d07addcc28db",
            price: 160.9065,
            price_sources: [
              {
                close: 160.9065,
                high: 160.9065,
                lag_ms: 301,
                low: 160.9065,
                open: 160.9065,
                source: "Polygon_ws",
                start_ms: 1720500814000,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 160.907,
                websocket: true,
              },
              {
                close: 160.905,
                high: 160.905,
                lag_ms: 301,
                low: 160.905,
                open: 160.905,
                source: "TwelveData_ws",
                start_ms: 1720500814000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1720500813699,
          },
          {
            leverage: 0.20665192910193306,
            order_type: "LONG",
            order_uuid: "f36686ef-2ee8-4476-9398-8a0982ea6a0a",
            price: 160.839,
            price_sources: [
              {
                close: 160.839,
                high: 160.839,
                lag_ms: 246,
                low: 160.839,
                open: 160.839,
                source: "Polygon_ws",
                start_ms: 1720504884999,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 160.82,
                websocket: true,
              },
              {
                close: 160.837,
                high: 160.837,
                lag_ms: 247,
                low: 160.837,
                open: 160.837,
                source: "TwelveData_ws",
                start_ms: 1720504885000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1720504884753,
          },
          {
            leverage: 0.20046559778615985,
            order_type: "LONG",
            order_uuid: "c788ea40-5f35-4bb4-aba3-9af94702922a",
            price: 160.77100000000002,
            price_sources: [
              {
                close: 160.77100000000002,
                high: 160.77100000000002,
                lag_ms: 453,
                low: 160.77100000000002,
                open: 160.77100000000002,
                source: "Polygon_ws",
                start_ms: 1720508427000,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 160.781,
                websocket: true,
              },
              {
                close: 160.765,
                high: 160.765,
                lag_ms: 547,
                low: 160.765,
                open: 160.765,
                source: "TwelveData_ws",
                start_ms: 1720508428000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1720508427453,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "f87a9037-2429-4124-a362-728cece3d0db",
            price: 160.99650000000003,
            price_sources: [
              {
                close: 160.99650000000003,
                high: 160.99650000000003,
                lag_ms: 252,
                low: 160.99650000000003,
                open: 160.99650000000003,
                source: "Polygon_ws",
                start_ms: 1720513670999,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 160.997,
                websocket: true,
              },
              {
                close: 160.998,
                high: 160.998,
                lag_ms: 253,
                low: 160.998,
                open: 160.998,
                source: "TwelveData_ws",
                start_ms: 1720513671000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1720513670747,
          },
        ],
        position_type: "FLAT",
        position_uuid: "849b7500-bb17-4c7b-9cd8-ab477d5b1889",
        return_at_close: 1.000209136311463,
        trade_pair: ["USDJPY", "USD/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 160.99,
        close_ms: 1720523673386,
        current_return: 1.000197343263721,
        initial_entry_price: 160.99,
        is_closed_position: true,
        miner_hotkey: "5HgBDcx8Z9oEWrGQm7obH4aPE2M5YXWA6S6MP1HtFHguUqek",
        net_leverage: 0.0,
        open_ms: 1720513690174,
        orders: [
          {
            leverage: 0.1836433065113372,
            order_type: "LONG",
            order_uuid: "d9b753e1-1722-4fa5-bcbb-4aca71547e2b",
            price: 160.99,
            price_sources: [
              {
                close: 160.99,
                high: 160.99,
                lag_ms: 51,
                low: 160.99,
                open: 160.99,
                source: "Polygon_ws",
                start_ms: 1720513689999,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 160.99,
                websocket: true,
              },
              {
                close: 160.989,
                high: 160.989,
                lag_ms: 52,
                low: 160.989,
                open: 160.989,
                source: "TwelveData_ws",
                start_ms: 1720513690000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1720513689948,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "87471596-b207-45de-8633-0005219a9237",
            price: 161.163,
            price_sources: [
              {
                close: 161.163,
                high: 161.163,
                lag_ms: 386,
                low: 161.163,
                open: 161.163,
                source: "Polygon_ws",
                start_ms: 1720523673000,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 161.162,
                websocket: true,
              },
              {
                close: 161.164,
                high: 161.164,
                lag_ms: 386,
                low: 161.164,
                open: 161.164,
                source: "TwelveData_ws",
                start_ms: 1720523673000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1720523673386,
          },
        ],
        position_type: "FLAT",
        position_uuid: "d9b753e1-1722-4fa5-bcbb-4aca71547e2b",
        return_at_close: 1.0001844856954114,
        trade_pair: ["USDJPY", "USD/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 161.10369656261153,
        close_ms: 1720528776733,
        current_return: 1.0001779318015223,
        initial_entry_price: 161.1615,
        is_closed_position: true,
        miner_hotkey: "5HgBDcx8Z9oEWrGQm7obH4aPE2M5YXWA6S6MP1HtFHguUqek",
        net_leverage: 0.0,
        open_ms: 1720523690685,
        orders: [
          {
            leverage: 0.23930093002196706,
            order_type: "LONG",
            order_uuid: "6b0aa45e-d14e-445a-84fd-2fc0fc8df400",
            price: 161.1615,
            price_sources: [
              {
                close: 161.1615,
                high: 161.1615,
                lag_ms: 320,
                low: 161.1615,
                open: 161.1615,
                source: "Polygon_ws",
                start_ms: 1720523690999,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 161.161,
                websocket: true,
              },
              {
                close: 161.158,
                high: 161.158,
                lag_ms: 321,
                low: 161.158,
                open: 161.158,
                source: "TwelveData_ws",
                start_ms: 1720523691000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1720523690679,
          },
          {
            leverage: 0.20844491932641326,
            order_type: "LONG",
            order_uuid: "6351b330-941b-4855-a614-82e99fe1de38",
            price: 161.114,
            price_sources: [
              {
                close: 161.114,
                high: 161.114,
                lag_ms: 82,
                low: 161.114,
                open: 161.114,
                source: "Polygon_ws",
                start_ms: 1720523792999,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 161.114,
                websocket: true,
              },
              {
                close: 161.112,
                high: 161.112,
                lag_ms: 917,
                low: 161.112,
                open: 161.112,
                source: "TwelveData_ws",
                start_ms: 1720523792000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1720523792917,
          },
          {
            leverage: 0.2818533393331025,
            order_type: "LONG",
            order_uuid: "0fc2b723-167e-409a-bc22-a893d97aabd9",
            price: 161.047,
            price_sources: [
              {
                close: 161.047,
                high: 161.047,
                lag_ms: 414,
                low: 161.047,
                open: 161.047,
                source: "Polygon_ws",
                start_ms: 1720528224000,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 161.036,
                websocket: true,
              },
              {
                close: 161.041,
                high: 161.041,
                lag_ms: 414,
                low: 161.041,
                open: 161.041,
                source: "TwelveData_ws",
                start_ms: 1720528224000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1720528224414,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "9c7b3a4b-465a-4be0-b201-7818a3bfd0ba",
            price: 161.143,
            price_sources: [
              {
                close: 161.143,
                high: 161.143,
                lag_ms: 266,
                low: 161.143,
                open: 161.143,
                source: "Polygon_ws",
                start_ms: 1720528776999,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 161.143,
                websocket: true,
              },
              {
                close: 161.142,
                high: 161.142,
                lag_ms: 267,
                low: 161.142,
                open: 161.142,
                source: "TwelveData_ws",
                start_ms: 1720528777000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1720528776733,
          },
        ],
        position_type: "FLAT",
        position_uuid: "6b0aa45e-d14e-445a-84fd-2fc0fc8df400",
        return_at_close: 1.0001268507709917,
        trade_pair: ["USDJPY", "USD/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 161.03435421397177,
        close_ms: 1720533683801,
        current_return: 1.0003345123865455,
        initial_entry_price: 161.143,
        is_closed_position: true,
        miner_hotkey: "5HgBDcx8Z9oEWrGQm7obH4aPE2M5YXWA6S6MP1HtFHguUqek",
        net_leverage: 0.0,
        open_ms: 1720528798178,
        orders: [
          {
            leverage: 0.23666820833360241,
            order_type: "LONG",
            order_uuid: "0a883509-5a9b-44e5-b453-ec77953bc656",
            price: 161.143,
            price_sources: [
              {
                close: 161.143,
                high: 161.143,
                lag_ms: 203,
                low: 161.143,
                open: 161.143,
                source: "Polygon_ws",
                start_ms: 1720528794999,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 161.137,
                websocket: true,
              },
              {
                close: 161.141,
                high: 161.141,
                lag_ms: 204,
                low: 161.141,
                open: 161.141,
                source: "TwelveData_ws",
                start_ms: 1720528795000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1720528794796,
          },
          {
            leverage: 0.17706212673499955,
            order_type: "LONG",
            order_uuid: "779c30e8-0764-4518-9692-3aa25157fd59",
            price: 161.07,
            price_sources: [
              {
                close: 161.07,
                high: 161.07,
                lag_ms: 239,
                low: 161.07,
                open: 161.07,
                source: "Polygon_ws",
                start_ms: 1720530385999,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 161.07,
                websocket: true,
              },
              {
                close: 161.072,
                high: 161.072,
                lag_ms: 240,
                low: 161.072,
                open: 161.072,
                source: "TwelveData_ws",
                start_ms: 1720530386000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1720530385760,
          },
          {
            leverage: 0.2827708311287169,
            order_type: "LONG",
            order_uuid: "4199e1c2-b1b6-4f07-8099-42bdc2fd9d23",
            price: 161.023,
            price_sources: [
              {
                close: 161.023,
                high: 161.023,
                lag_ms: 182,
                low: 161.023,
                open: 161.023,
                source: "Polygon_ws",
                start_ms: 1720532463999,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 161.021,
                websocket: true,
              },
              {
                close: 161.024,
                high: 161.024,
                lag_ms: 817,
                low: 161.024,
                open: 161.024,
                source: "TwelveData_ws",
                start_ms: 1720532463000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1720532463817,
          },
          {
            leverage: 0.27220344474587044,
            order_type: "LONG",
            order_uuid: "56b2c9f5-9592-4d03-990c-abf6a87ba7ec",
            price: 160.92849999999999,
            price_sources: [
              {
                close: 160.92849999999999,
                high: 160.92849999999999,
                lag_ms: 337,
                low: 160.92849999999999,
                open: 160.92849999999999,
                source: "Polygon_ws",
                start_ms: 1720533617999,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 160.927,
                websocket: true,
              },
              {
                close: 160.923,
                high: 160.923,
                lag_ms: 338,
                low: 160.923,
                open: 160.923,
                source: "TwelveData_ws",
                start_ms: 1720533618000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1720533617662,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "12e7dc7d-adba-438b-a8ce-20396ca63c9d",
            price: 161.09,
            price_sources: [
              {
                close: 161.09,
                high: 161.09,
                lag_ms: 198,
                low: 161.09,
                open: 161.09,
                source: "Polygon_ws",
                start_ms: 1720533683999,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 161.094,
                websocket: true,
              },
              {
                close: 161.085,
                high: 161.085,
                lag_ms: 199,
                low: 161.085,
                open: 161.085,
                source: "TwelveData_ws",
                start_ms: 1720533684000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1720533683801,
          },
        ],
        position_type: "FLAT",
        position_uuid: "0a883509-5a9b-44e5-b453-ec77953bc656",
        return_at_close: 1.000266680380721,
        trade_pair: ["USDJPY", "USD/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 161.055,
        close_ms: 1720533893815,
        current_return: 1.000146601166374,
        initial_entry_price: 161.055,
        is_closed_position: true,
        miner_hotkey: "5HgBDcx8Z9oEWrGQm7obH4aPE2M5YXWA6S6MP1HtFHguUqek",
        net_leverage: 0.0,
        open_ms: 1720533701682,
        orders: [
          {
            leverage: 0.1816219296182979,
            order_type: "LONG",
            order_uuid: "ba74ed4b-3712-4805-a954-e0d64796253a",
            price: 161.055,
            price_sources: [
              {
                close: 161.055,
                high: 161.055,
                lag_ms: 324,
                low: 161.055,
                open: 161.055,
                source: "Polygon_ws",
                start_ms: 1720533701999,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 161.055,
                websocket: true,
              },
              {
                close: 161.055,
                high: 161.055,
                lag_ms: 325,
                low: 161.055,
                open: 161.055,
                source: "TwelveData_ws",
                start_ms: 1720533702000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1720533701675,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "1fa92f78-1086-4ba3-8b56-f0f3efb46687",
            price: 161.185,
            price_sources: [
              {
                close: 161.185,
                high: 161.185,
                lag_ms: 184,
                low: 161.185,
                open: 161.185,
                source: "Polygon_ws",
                start_ms: 1720533893999,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 161.187,
                websocket: true,
              },
              {
                close: 161.181,
                high: 161.181,
                lag_ms: 6185,
                low: 161.181,
                open: 161.181,
                source: "TwelveData_ws",
                start_ms: 1720533900000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1720533893815,
          },
        ],
        position_type: "FLAT",
        position_uuid: "ba74ed4b-3712-4805-a954-e0d64796253a",
        return_at_close: 1.0001338857674817,
        trade_pair: ["USDJPY", "USD/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 161.1755221361375,
        close_ms: 1720534931235,
        current_return: 1.0001966824802644,
        initial_entry_price: 161.269,
        is_closed_position: true,
        miner_hotkey: "5HgBDcx8Z9oEWrGQm7obH4aPE2M5YXWA6S6MP1HtFHguUqek",
        net_leverage: 0.0,
        open_ms: 1720533911713,
        orders: [
          {
            leverage: 0.19557093800534447,
            order_type: "LONG",
            order_uuid: "7567cdd8-3685-40fd-a74d-81e608acc099",
            price: 161.269,
            price_sources: [
              {
                close: 161.269,
                high: 161.269,
                lag_ms: 307,
                low: 161.269,
                open: 161.269,
                source: "Polygon_ws",
                start_ms: 1720533911999,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 161.265,
                websocket: true,
              },
              {
                close: 161.29,
                high: 161.29,
                lag_ms: 308,
                low: 161.29,
                open: 161.29,
                source: "TwelveData_ws",
                start_ms: 1720533912000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1720533911692,
          },
          {
            leverage: 0.22952610281525965,
            order_type: "LONG",
            order_uuid: "e3a0f366-1cf9-44b2-8f96-f9639b7b6b16",
            price: 161.15,
            price_sources: [
              {
                close: 161.15,
                high: 161.15,
                lag_ms: 115,
                low: 161.15,
                open: 161.15,
                source: "Polygon_ws",
                start_ms: 1720534037999,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 161.147,
                websocket: true,
              },
              {
                close: 161.164,
                high: 161.164,
                lag_ms: 116,
                low: 161.164,
                open: 161.164,
                source: "TwelveData_ws",
                start_ms: 1720534038000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1720534037884,
          },
          {
            leverage: 0.1910665784480137,
            order_type: "LONG",
            order_uuid: "80cf67c2-1504-45de-a4a1-6e3424da2600",
            price: 161.1105,
            price_sources: [
              {
                close: 161.1105,
                high: 161.1105,
                lag_ms: 177,
                low: 161.1105,
                open: 161.1105,
                source: "Polygon_ws",
                start_ms: 1720534135999,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 161.111,
                websocket: true,
              },
              {
                close: 161.113,
                high: 161.113,
                lag_ms: 178,
                low: 161.113,
                open: 161.113,
                source: "TwelveData_ws",
                start_ms: 1720534136000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1720534135822,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "fd0502d7-ea89-4920-9113-f16f68f6a986",
            price: 161.227,
            price_sources: [
              {
                close: 161.227,
                high: 161.227,
                lag_ms: 235,
                low: 161.227,
                open: 161.227,
                source: "Polygon_ws",
                start_ms: 1720534931000,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 161.229,
                websocket: true,
              },
              {
                close: 161.228,
                high: 161.228,
                lag_ms: 235,
                low: 161.228,
                open: 161.228,
                source: "TwelveData_ws",
                start_ms: 1720534931000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1720534931235,
          },
        ],
        position_type: "FLAT",
        position_uuid: "7567cdd8-3685-40fd-a74d-81e608acc099",
        return_at_close: 1.0001535425437145,
        trade_pair: ["USDJPY", "USD/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 161.199,
        close_ms: 1720536260736,
        current_return: 1.0001502919944425,
        initial_entry_price: 161.199,
        is_closed_position: true,
        miner_hotkey: "5HgBDcx8Z9oEWrGQm7obH4aPE2M5YXWA6S6MP1HtFHguUqek",
        net_leverage: 0.0,
        open_ms: 1720534948687,
        orders: [
          {
            leverage: 0.25502020223311334,
            order_type: "LONG",
            order_uuid: "7c944099-1323-40a3-9220-35eee55d84d1",
            price: 161.199,
            price_sources: [
              {
                close: 161.199,
                high: 161.199,
                lag_ms: 319,
                low: 161.199,
                open: 161.199,
                source: "Polygon_ws",
                start_ms: 1720534948999,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 161.201,
                websocket: true,
              },
              {
                close: 161.205,
                high: 161.205,
                lag_ms: 320,
                low: 161.205,
                open: 161.205,
                source: "TwelveData_ws",
                start_ms: 1720534949000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1720534948680,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "d750477d-85b7-4bc8-afbe-b371f744cd84",
            price: 161.294,
            price_sources: [
              {
                close: 161.294,
                high: 161.294,
                lag_ms: 263,
                low: 161.294,
                open: 161.294,
                source: "Polygon_ws",
                start_ms: 1720536260999,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 161.294,
                websocket: true,
              },
              {
                close: 161.298,
                high: 161.298,
                lag_ms: 264,
                low: 161.298,
                open: 161.298,
                source: "TwelveData_ws",
                start_ms: 1720536261000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1720536260736,
          },
        ],
        position_type: "FLAT",
        position_uuid: "7c944099-1323-40a3-9220-35eee55d84d1",
        return_at_close: 1.0001324378973615,
        trade_pair: ["USDJPY", "USD/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 161.279,
        close_ms: 1720537276566,
        current_return: 1.0001931738636174,
        initial_entry_price: 161.279,
        is_closed_position: true,
        miner_hotkey: "5HgBDcx8Z9oEWrGQm7obH4aPE2M5YXWA6S6MP1HtFHguUqek",
        net_leverage: 0.0,
        open_ms: 1720536283388,
        orders: [
          {
            leverage: 0.2607103560699527,
            order_type: "LONG",
            order_uuid: "abfe21d5-8597-4e5d-b6c0-3d32927550b8",
            price: 161.279,
            price_sources: [
              {
                close: 161.279,
                high: 161.279,
                lag_ms: 130,
                low: 161.279,
                open: 161.279,
                source: "Polygon_ws",
                start_ms: 1720536280999,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 161.278,
                websocket: true,
              },
              {
                close: 161.276,
                high: 161.276,
                lag_ms: 131,
                low: 161.276,
                open: 161.276,
                source: "TwelveData_ws",
                start_ms: 1720536281000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1720536280869,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "22ba9442-20b3-41db-83b1-cb807b9e14d2",
            price: 161.3985,
            price_sources: [
              {
                close: 161.3985,
                high: 161.3985,
                lag_ms: 433,
                low: 161.3985,
                open: 161.3985,
                source: "Polygon_ws",
                start_ms: 1720537276999,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 161.398,
                websocket: true,
              },
              {
                close: 161.397,
                high: 161.397,
                lag_ms: 434,
                low: 161.397,
                open: 161.397,
                source: "TwelveData_ws",
                start_ms: 1720537277000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1720537276566,
          },
        ],
        position_type: "FLAT",
        position_uuid: "abfe21d5-8597-4e5d-b6c0-3d32927550b8",
        return_at_close: 1.0001749206133226,
        trade_pair: ["USDJPY", "USD/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 161.36418221128991,
        close_ms: 1720537992850,
        current_return: 1.000194153916962,
        initial_entry_price: 161.39,
        is_closed_position: true,
        miner_hotkey: "5HgBDcx8Z9oEWrGQm7obH4aPE2M5YXWA6S6MP1HtFHguUqek",
        net_leverage: 0.0,
        open_ms: 1720537293652,
        orders: [
          {
            leverage: 0.217023578692218,
            order_type: "LONG",
            order_uuid: "094a0599-dcec-4476-9117-5a6f2bf185a3",
            price: 161.39,
            price_sources: [
              {
                close: 161.39,
                high: 161.39,
                lag_ms: 354,
                low: 161.39,
                open: 161.39,
                source: "Polygon_ws",
                start_ms: 1720537293999,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 161.39,
                websocket: true,
              },
              {
                close: 161.397,
                high: 161.397,
                lag_ms: 355,
                low: 161.397,
                open: 161.397,
                source: "TwelveData_ws",
                start_ms: 1720537294000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1720537293645,
          },
          {
            leverage: 0.1856414311713834,
            order_type: "LONG",
            order_uuid: "1eb0d935-04e8-4595-8e81-28ffc4ec1b9d",
            price: 161.334,
            price_sources: [
              {
                close: 161.334,
                high: 161.334,
                lag_ms: 260,
                low: 161.334,
                open: 161.334,
                source: "Polygon_ws",
                start_ms: 1720537359999,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 161.333,
                websocket: true,
              },
              {
                close: 161.335,
                high: 161.335,
                lag_ms: 739,
                low: 161.335,
                open: 161.335,
                source: "TwelveData_ws",
                start_ms: 1720537359000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1720537359739,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "2ea1fbd2-c2e1-4c00-8627-a9168c85c2de",
            price: 161.442,
            price_sources: [
              {
                close: 161.442,
                high: 161.442,
                lag_ms: 149,
                low: 161.442,
                open: 161.442,
                source: "Polygon_ws",
                start_ms: 1720537992999,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 161.442,
                websocket: true,
              },
              {
                close: 161.44,
                high: 161.44,
                lag_ms: 850,
                low: 161.44,
                open: 161.44,
                source: "TwelveData_ws",
                start_ms: 1720537992000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1720537992850,
          },
        ],
        position_type: "FLAT",
        position_uuid: "094a0599-dcec-4476-9117-5a6f2bf185a3",
        return_at_close: 1.0001659618937424,
        trade_pair: ["USDJPY", "USD/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 161.36093991848244,
        close_ms: 1720558837734,
        current_return: 0.9996276399005418,
        initial_entry_price: 161.44850000000002,
        is_closed_position: true,
        miner_hotkey: "5HgBDcx8Z9oEWrGQm7obH4aPE2M5YXWA6S6MP1HtFHguUqek",
        net_leverage: 0.0,
        open_ms: 1720538010960,
        orders: [
          {
            leverage: 0.277330580754762,
            order_type: "LONG",
            order_uuid: "48e78328-4c9b-4d65-95fb-41d3e9ae9867",
            price: 161.44850000000002,
            price_sources: [
              {
                close: 161.44850000000002,
                high: 161.44850000000002,
                lag_ms: 58,
                low: 161.44850000000002,
                open: 161.44850000000002,
                source: "Polygon_ws",
                start_ms: 1720538010999,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 161.448,
                websocket: true,
              },
              {
                close: 161.447,
                high: 161.447,
                lag_ms: 59,
                low: 161.447,
                open: 161.447,
                source: "TwelveData_ws",
                start_ms: 1720538011000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1720538010941,
          },
          {
            leverage: 0.2758412830280795,
            order_type: "LONG",
            order_uuid: "48da552f-81f7-4175-8ff8-c61d73525a35",
            price: 161.379,
            price_sources: [
              {
                close: 161.379,
                high: 161.379,
                lag_ms: 431,
                low: 161.379,
                open: 161.379,
                source: "Polygon_ws",
                start_ms: 1720540887999,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 161.379,
                websocket: true,
              },
              {
                close: 161.376,
                high: 161.376,
                lag_ms: 568,
                low: 161.376,
                open: 161.376,
                source: "TwelveData_ws",
                start_ms: 1720540887000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1720540887568,
          },
          {
            leverage: 0.23000188501789381,
            order_type: "LONG",
            order_uuid: "c4ea7574-d4b4-435a-a9a0-37e401df7135",
            price: 161.318,
            price_sources: [
              {
                close: 161.318,
                high: 161.318,
                lag_ms: 442,
                low: 161.318,
                open: 161.318,
                source: "Polygon_ws",
                start_ms: 1720542321000,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 161.32,
                websocket: true,
              },
              {
                close: 161.322,
                high: 161.322,
                lag_ms: 442,
                low: 161.322,
                open: 161.322,
                source: "TwelveData_ws",
                start_ms: 1720542321000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1720542321442,
          },
          {
            leverage: 0.1796234645599622,
            order_type: "LONG",
            order_uuid: "21c23dee-55d9-4596-aac0-d7e30c76f655",
            price: 161.253,
            price_sources: [
              {
                close: 161.253,
                high: 161.253,
                lag_ms: 12,
                low: 161.253,
                open: 161.253,
                source: "Polygon_ws",
                start_ms: 1720544573000,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 161.252,
                websocket: true,
              },
              {
                close: 161.256,
                high: 161.256,
                lag_ms: 2012,
                low: 161.256,
                open: 161.256,
                source: "TwelveData_ws",
                start_ms: 1720544571000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1720544573012,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "6a3ac19d-f914-47a2-b405-c8f2e0dc5c39",
            price: 161.2985,
            price_sources: [
              {
                close: 161.2985,
                high: 161.2985,
                lag_ms: 1266,
                low: 161.2985,
                open: 161.2985,
                source: "Polygon_ws",
                start_ms: 1720558839000,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 161.321,
                websocket: true,
              },
              {
                close: 161.446,
                high: 161.446,
                lag_ms: 3735,
                low: 161.446,
                open: 161.446,
                source: "Polygon_rest",
                start_ms: 1720558833000,
                timespan_ms: 1000,
                volume: 1.0,
                vwap: null,
                websocket: false,
              },
              {
                close: 161.272,
                high: 161.272,
                lag_ms: 4266,
                low: 161.272,
                open: 161.272,
                source: "TwelveData_ws",
                start_ms: 1720558842000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
              {
                close: 161.32001,
                high: 161.32001,
                lag_ms: 157735,
                low: 161.315,
                open: 161.315,
                source: "TwelveData_rest",
                start_ms: 1720558620000,
                timespan_ms: 60000,
                volume: null,
                vwap: null,
                websocket: false,
              },
            ],
            processed_ms: 1720558837734,
          },
        ],
        position_type: "FLAT",
        position_uuid: "48e78328-4c9b-4d65-95fb-41d3e9ae9867",
        return_at_close: 0.9995602691911153,
        trade_pair: ["USDJPY", "USD/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 161.2778758528541,
        close_ms: 1720565872615,
        current_return: 1.000151602033935,
        initial_entry_price: 161.29,
        is_closed_position: true,
        miner_hotkey: "5HgBDcx8Z9oEWrGQm7obH4aPE2M5YXWA6S6MP1HtFHguUqek",
        net_leverage: 0.0,
        open_ms: 1720558863886,
        orders: [
          {
            leverage: 0.18256413784659178,
            order_type: "LONG",
            order_uuid: "3be0c75f-22e2-4953-89e2-f8f2ec7e5ce4",
            price: 161.29,
            price_sources: [
              {
                close: 161.29,
                high: 161.29,
                lag_ms: 299,
                low: 161.29,
                open: 161.29,
                source: "Polygon_ws",
                start_ms: 1720558864000,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 161.29,
                websocket: true,
              },
              {
                close: 161.312,
                high: 161.312,
                lag_ms: 3702,
                low: 161.312,
                open: 161.312,
                source: "Polygon_rest",
                start_ms: 1720558859000,
                timespan_ms: 1000,
                volume: 1.0,
                vwap: null,
                websocket: false,
              },
              {
                close: 161.272,
                high: 161.272,
                lag_ms: 21701,
                low: 161.272,
                open: 161.272,
                source: "TwelveData_ws",
                start_ms: 1720558842000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
              {
                close: 161.32001,
                high: 161.32001,
                lag_ms: 183702,
                low: 161.315,
                open: 161.315,
                source: "TwelveData_rest",
                start_ms: 1720558620000,
                timespan_ms: 60000,
                volume: null,
                vwap: null,
                websocket: false,
              },
            ],
            processed_ms: 1720558863701,
          },
          {
            leverage: 0.22412590623886502,
            order_type: "LONG",
            order_uuid: "34ed3923-8571-4b70-8af2-9ea17a966bd7",
            price: 161.268,
            price_sources: [
              {
                close: 161.268,
                high: 161.268,
                lag_ms: 848,
                low: 161.268,
                open: 161.268,
                source: "TwelveData_ws",
                start_ms: 1720558993000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
              {
                close: 161.297,
                high: 161.297,
                lag_ms: 849,
                low: 161.297,
                open: 161.297,
                source: "Polygon_ws",
                start_ms: 1720558992999,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 161.297,
                websocket: true,
              },
            ],
            processed_ms: 1720558993848,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "39d2ce0c-f02d-4c4f-a1cc-5ec7b8a8af21",
            price: 161.338,
            price_sources: [
              {
                close: 161.338,
                high: 161.338,
                lag_ms: 616,
                low: 161.338,
                open: 161.338,
                source: "Polygon_ws",
                start_ms: 1720565871999,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 161.338,
                websocket: true,
              },
              {
                close: 161.342,
                high: 161.342,
                lag_ms: 2615,
                low: 161.342,
                open: 161.342,
                source: "TwelveData_ws",
                start_ms: 1720565870000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1720565872615,
          },
        ],
        position_type: "FLAT",
        position_uuid: "3be0c75f-22e2-4953-89e2-f8f2ec7e5ce4",
        return_at_close: 1.0001231294149964,
        trade_pair: ["USDJPY", "USD/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 161.343,
        close_ms: 1720572678401,
        current_return: 1.000184253154332,
        initial_entry_price: 161.343,
        is_closed_position: true,
        miner_hotkey: "5HgBDcx8Z9oEWrGQm7obH4aPE2M5YXWA6S6MP1HtFHguUqek",
        net_leverage: 0.0,
        open_ms: 1720565899740,
        orders: [
          {
            leverage: 0.21858791676014117,
            order_type: "LONG",
            order_uuid: "4d77aa6e-d5a6-481a-9344-fccb53827d6b",
            price: 161.343,
            price_sources: [
              {
                close: 161.343,
                high: 161.343,
                lag_ms: 734,
                low: 161.343,
                open: 161.343,
                source: "Polygon_ws",
                start_ms: 1720565898999,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 161.343,
                websocket: true,
              },
              {
                close: 161.338,
                high: 161.338,
                lag_ms: 1733,
                low: 161.338,
                open: 161.338,
                source: "TwelveData_ws",
                start_ms: 1720565898000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1720565899733,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "2311c20a-bb02-4920-b2a1-0ed17dc5e5b4",
            price: 161.47899999999998,
            price_sources: [
              {
                close: 161.47899999999998,
                high: 161.47899999999998,
                lag_ms: 401,
                low: 161.47899999999998,
                open: 161.47899999999998,
                source: "Polygon_ws",
                start_ms: 1720572678000,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 161.476,
                websocket: true,
              },
              {
                close: 161.476,
                high: 161.476,
                lag_ms: 1401,
                low: 161.476,
                open: 161.476,
                source: "TwelveData_ws",
                start_ms: 1720572677000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1720572678401,
          },
        ],
        position_type: "FLAT",
        position_uuid: "4d77aa6e-d5a6-481a-9344-fccb53827d6b",
        return_at_close: 1.0001689491808727,
        trade_pair: ["USDJPY", "USD/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 161.4484859255591,
        close_ms: 1720589372682,
        current_return: 1.0002048206362288,
        initial_entry_price: 161.482,
        is_closed_position: true,
        miner_hotkey: "5HgBDcx8Z9oEWrGQm7obH4aPE2M5YXWA6S6MP1HtFHguUqek",
        net_leverage: 0.0,
        open_ms: 1720572695868,
        orders: [
          {
            leverage: 0.1596930192257768,
            order_type: "LONG",
            order_uuid: "1bcd3568-6c91-4409-a1c6-3a0f81b9d81e",
            price: 161.482,
            price_sources: [
              {
                close: 161.482,
                high: 161.482,
                lag_ms: 358,
                low: 161.482,
                open: 161.482,
                source: "Polygon_ws",
                start_ms: 1720572696000,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 161.482,
                websocket: true,
              },
              {
                close: 161.481,
                high: 161.481,
                lag_ms: 358,
                low: 161.481,
                open: 161.481,
                source: "TwelveData_ws",
                start_ms: 1720572696000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
              {
                close: 161.479,
                high: 161.479,
                lag_ms: 3643,
                low: 161.479,
                open: 161.479,
                source: "Polygon_rest",
                start_ms: 1720572691000,
                timespan_ms: 1000,
                volume: 1.0,
                vwap: null,
                websocket: false,
              },
              {
                close: 161.455,
                high: 161.465,
                lag_ms: 95643,
                low: 161.45,
                open: 161.465,
                source: "TwelveData_rest",
                start_ms: 1720572540000,
                timespan_ms: 60000,
                volume: null,
                vwap: null,
                websocket: false,
              },
            ],
            processed_ms: 1720572695642,
          },
          {
            leverage: 0.15982726009996337,
            order_type: "LONG",
            order_uuid: "0cce08f9-3e95-46bb-99c2-a91dd1684a24",
            price: 161.415,
            price_sources: [
              {
                close: 161.415,
                high: 161.415,
                lag_ms: 148,
                low: 161.415,
                open: 161.415,
                source: "Polygon_ws",
                start_ms: 1720583076000,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 161.414,
                websocket: true,
              },
              {
                close: 161.414,
                high: 161.414,
                lag_ms: 148,
                low: 161.414,
                open: 161.414,
                source: "TwelveData_ws",
                start_ms: 1720583076000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1720583076148,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "24fa9537-b559-4dce-8acb-e7846c1c52f7",
            price: 161.552,
            price_sources: [
              {
                close: 161.552,
                high: 161.552,
                lag_ms: 317,
                low: 161.552,
                open: 161.552,
                source: "Polygon_ws",
                start_ms: 1720589372999,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 161.551,
                websocket: true,
              },
              {
                close: 161.552,
                high: 161.552,
                lag_ms: 318,
                low: 161.552,
                open: 161.552,
                source: "TwelveData_ws",
                start_ms: 1720589373000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1720589372682,
          },
        ],
        position_type: "FLAT",
        position_uuid: "1bcd3568-6c91-4409-a1c6-3a0f81b9d81e",
        return_at_close: 1.0001824496355718,
        trade_pair: ["USDJPY", "USD/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 161.47785432613298,
        close_ms: 1720599641548,
        current_return: 1.0002523104339698,
        initial_entry_price: 161.551,
        is_closed_position: true,
        miner_hotkey: "5HgBDcx8Z9oEWrGQm7obH4aPE2M5YXWA6S6MP1HtFHguUqek",
        net_leverage: 0.0,
        open_ms: 1720589390979,
        orders: [
          {
            leverage: 0.26688301537764675,
            order_type: "LONG",
            order_uuid: "594ddbe0-6bf0-4f25-b16f-25d9136153b8",
            price: 161.551,
            price_sources: [
              {
                close: 161.551,
                high: 161.551,
                lag_ms: 114,
                low: 161.551,
                open: 161.551,
                source: "Polygon_ws",
                start_ms: 1720589390999,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 161.548,
                websocket: true,
              },
              {
                close: 161.552,
                high: 161.552,
                lag_ms: 115,
                low: 161.552,
                open: 161.552,
                source: "TwelveData_ws",
                start_ms: 1720589391000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1720589390885,
          },
          {
            leverage: 0.2832293432212586,
            order_type: "LONG",
            order_uuid: "627df76b-a373-47d9-9787-a07460bf9e05",
            price: 161.496,
            price_sources: [
              {
                close: 161.496,
                high: 161.496,
                lag_ms: 498,
                low: 161.496,
                open: 161.496,
                source: "Polygon_ws",
                start_ms: 1720590715999,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 161.496,
                websocket: true,
              },
              {
                close: 161.5,
                high: 161.5,
                lag_ms: 499,
                low: 161.5,
                open: 161.5,
                source: "TwelveData_ws",
                start_ms: 1720590716000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1720590715501,
          },
          {
            leverage: 0.1937896152486263,
            order_type: "LONG",
            order_uuid: "c5a89590-be0c-496b-afde-e949c810c3c4",
            price: 161.444,
            price_sources: [
              {
                close: 161.444,
                high: 161.444,
                lag_ms: 481,
                low: 161.444,
                open: 161.444,
                source: "Polygon_ws",
                start_ms: 1720592185000,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 161.444,
                websocket: true,
              },
              {
                close: 161.441,
                high: 161.441,
                lag_ms: 481,
                low: 161.441,
                open: 161.441,
                source: "TwelveData_ws",
                start_ms: 1720592185000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1720592185481,
          },
          {
            leverage: 0.24674902508819085,
            order_type: "LONG",
            order_uuid: "b9eb7dbe-c0e5-42e2-907c-279974e36baa",
            price: 161.40449999999998,
            price_sources: [
              {
                close: 161.40449999999998,
                high: 161.40449999999998,
                lag_ms: 139,
                low: 161.40449999999998,
                open: 161.40449999999998,
                source: "Polygon_ws",
                start_ms: 1720592777999,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 161.403,
                websocket: true,
              },
              {
                close: 161.406,
                high: 161.406,
                lag_ms: 140,
                low: 161.406,
                open: 161.406,
                source: "TwelveData_ws",
                start_ms: 1720592778000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1720592777860,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "b7691119-07f6-47df-8a9c-9c7ec7e21cc8",
            price: 161.519,
            price_sources: [
              {
                close: 161.519,
                high: 161.519,
                lag_ms: 452,
                low: 161.519,
                open: 161.519,
                source: "Polygon_ws",
                start_ms: 1720599642000,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 161.519,
                websocket: true,
              },
              {
                close: 161.521,
                high: 161.521,
                lag_ms: 452,
                low: 161.521,
                open: 161.521,
                source: "TwelveData_ws",
                start_ms: 1720599642000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1720599641548,
          },
        ],
        position_type: "FLAT",
        position_uuid: "594ddbe0-6bf0-4f25-b16f-25d9136153b8",
        return_at_close: 1.0001829473674333,
        trade_pair: ["USDJPY", "USD/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 161.50799999999998,
        close_ms: 1720603872695,
        current_return: 1.0001517335076933,
        initial_entry_price: 161.50799999999998,
        is_closed_position: true,
        miner_hotkey: "5HgBDcx8Z9oEWrGQm7obH4aPE2M5YXWA6S6MP1HtFHguUqek",
        net_leverage: 0.0,
        open_ms: 1720599658783,
        orders: [
          {
            leverage: 0.24753712485383694,
            order_type: "LONG",
            order_uuid: "c43a27b3-322e-463f-90b2-8952da545dcb",
            price: 161.50799999999998,
            price_sources: [
              {
                close: 161.50799999999998,
                high: 161.50799999999998,
                lag_ms: 224,
                low: 161.50799999999998,
                open: 161.50799999999998,
                source: "Polygon_ws",
                start_ms: 1720599658999,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 161.509,
                websocket: true,
              },
              {
                close: 161.508,
                high: 161.508,
                lag_ms: 225,
                low: 161.508,
                open: 161.508,
                source: "TwelveData_ws",
                start_ms: 1720599659000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1720599658775,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "5b65f9fa-1629-4a93-8c68-398b2264677e",
            price: 161.607,
            price_sources: [
              {
                close: 161.607,
                high: 161.607,
                lag_ms: 304,
                low: 161.607,
                open: 161.607,
                source: "Polygon_ws",
                start_ms: 1720603872999,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 161.607,
                websocket: true,
              },
              {
                close: 161.607,
                high: 161.607,
                lag_ms: 305,
                low: 161.607,
                open: 161.607,
                source: "TwelveData_ws",
                start_ms: 1720603873000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1720603872695,
          },
        ],
        position_type: "FLAT",
        position_uuid: "c43a27b3-322e-463f-90b2-8952da545dcb",
        return_at_close: 1.000134403279776,
        trade_pair: ["USDJPY", "USD/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 161.51869659601635,
        close_ms: 1720619180982,
        current_return: 1.0002506486841007,
        initial_entry_price: 161.588,
        is_closed_position: true,
        miner_hotkey: "5HgBDcx8Z9oEWrGQm7obH4aPE2M5YXWA6S6MP1HtFHguUqek",
        net_leverage: 0.0,
        open_ms: 1720603890973,
        orders: [
          {
            leverage: 0.2350785786762153,
            order_type: "LONG",
            order_uuid: "c755db36-4694-4d79-bf07-36e029390286",
            price: 161.588,
            price_sources: [
              {
                close: 161.588,
                high: 161.588,
                lag_ms: 51,
                low: 161.588,
                open: 161.588,
                source: "Polygon_ws",
                start_ms: 1720603890999,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 161.588,
                websocket: true,
              },
              {
                close: 161.585,
                high: 161.585,
                lag_ms: 52,
                low: 161.585,
                open: 161.585,
                source: "TwelveData_ws",
                start_ms: 1720603891000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1720603890948,
          },
          {
            leverage: 0.28036315948136725,
            order_type: "LONG",
            order_uuid: "83bb2413-7f5f-4bb4-bda8-16a00431b3cd",
            price: 161.516,
            price_sources: [
              {
                close: 161.516,
                high: 161.516,
                lag_ms: 357,
                low: 161.516,
                open: 161.516,
                source: "Polygon_ws",
                start_ms: 1720607965999,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 161.516,
                websocket: true,
              },
              {
                close: 161.515,
                high: 161.515,
                lag_ms: 642,
                low: 161.515,
                open: 161.515,
                source: "TwelveData_ws",
                start_ms: 1720607965000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1720607965642,
          },
          {
            leverage: 0.2740150312096086,
            order_type: "LONG",
            order_uuid: "a5ce8fc6-d9c6-4446-bca1-393db46c12b7",
            price: 161.462,
            price_sources: [
              {
                close: 161.462,
                high: 161.462,
                lag_ms: 65,
                low: 161.462,
                open: 161.462,
                source: "Polygon_ws",
                start_ms: 1720610163999,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 161.462,
                websocket: true,
              },
              {
                close: 161.464,
                high: 161.464,
                lag_ms: 66,
                low: 161.464,
                open: 161.464,
                source: "TwelveData_ws",
                start_ms: 1720610164000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1720610163934,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "bdd4a016-6df1-4884-9207-cc3b269371bd",
            price: 161.57,
            price_sources: [
              {
                close: 161.57,
                high: 161.57,
                lag_ms: 17,
                low: 161.57,
                open: 161.57,
                source: "Polygon_ws",
                start_ms: 1720619180999,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 161.57,
                websocket: true,
              },
              {
                close: 161.575,
                high: 161.575,
                lag_ms: 18,
                low: 161.575,
                open: 161.575,
                source: "TwelveData_ws",
                start_ms: 1720619181000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1720619180982,
          },
        ],
        position_type: "FLAT",
        position_uuid: "c755db36-4694-4d79-bf07-36e029390286",
        return_at_close: 1.000195372858904,
        trade_pair: ["USDJPY", "USD/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 161.571,
        close_ms: 1720624655621,
        current_return: 1.0001582040086807,
        initial_entry_price: 161.571,
        is_closed_position: true,
        miner_hotkey: "5HgBDcx8Z9oEWrGQm7obH4aPE2M5YXWA6S6MP1HtFHguUqek",
        net_leverage: 0.0,
        open_ms: 1720619199374,
        orders: [
          {
            leverage: 0.2282248204158115,
            order_type: "LONG",
            order_uuid: "7e39b40d-ac6a-42fb-95fc-8f01a1095a3a",
            price: 161.571,
            price_sources: [
              {
                close: 161.571,
                high: 161.571,
                lag_ms: 201,
                low: 161.571,
                open: 161.571,
                source: "Polygon_ws",
                start_ms: 1720619198999,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 161.571,
                websocket: true,
              },
              {
                close: 161.568,
                high: 161.568,
                lag_ms: 1202,
                low: 161.568,
                open: 161.568,
                source: "TwelveData_ws",
                start_ms: 1720619200000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1720619198798,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "0965a987-ce7c-465c-ae54-16fc64a21d6c",
            price: 161.683,
            price_sources: [
              {
                close: 161.683,
                high: 161.683,
                lag_ms: 378,
                low: 161.683,
                open: 161.683,
                source: "Polygon_ws",
                start_ms: 1720624655999,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 161.683,
                websocket: true,
              },
              {
                close: 161.689,
                high: 161.689,
                lag_ms: 379,
                low: 161.689,
                open: 161.689,
                source: "TwelveData_ws",
                start_ms: 1720624656000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1720624655621,
          },
        ],
        position_type: "FLAT",
        position_uuid: "7e39b40d-ac6a-42fb-95fc-8f01a1095a3a",
        return_at_close: 1.000142225743826,
        trade_pair: ["USDJPY", "USD/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 230.4049999999989,
        close_ms: 0,
        current_return: 1.0011616272368897,
        initial_entry_price: 196.087,
        is_closed_position: false,
        miner_hotkey: "5HgBDcx8Z9oEWrGQm7obH4aPE2M5YXWA6S6MP1HtFHguUqek",
        net_leverage: -0.010000000000000064,
        open_ms: 1714130739869,
        orders: [
          {
            leverage: -0.2,
            order_type: "SHORT",
            order_uuid: "d0abb4b9-d0f9-49ea-b6a2-7cc9a4b57746",
            price: 196.087,
            price_sources: [],
            processed_ms: 1714130739865,
          },
          {
            leverage: -0.1,
            order_type: "SHORT",
            order_uuid: "7decac65-27a1-4f35-901f-bd10599bb3ad",
            price: 196.207,
            price_sources: [],
            processed_ms: 1714131863769,
          },
          {
            leverage: 0.29,
            order_type: "LONG",
            order_uuid: "fd5dc579-d6ce-4c41-ae1f-af4fe5aa5e3b",
            price: 194.945,
            price_sources: [],
            processed_ms: 1714366178041,
          },
        ],
        position_type: "SHORT",
        position_uuid: "af32a287-b3da-4bc0-af74-7cdf819fd1d8",
        return_at_close: 1.0011409532492874,
        trade_pair: ["GBPJPY", "GBP/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 161.69399999999996,
        close_ms: 0,
        current_return: 1.0000095016888688,
        initial_entry_price: 161.694,
        is_closed_position: false,
        miner_hotkey: "5HgBDcx8Z9oEWrGQm7obH4aPE2M5YXWA6S6MP1HtFHguUqek",
        net_leverage: 0.2194808685648485,
        open_ms: 1720624672717,
        orders: [
          {
            leverage: 0.2194808685648485,
            order_type: "LONG",
            order_uuid: "e1a2d893-4310-4227-9312-350b4d2f74ee",
            price: 161.694,
            price_sources: [
              {
                close: 161.694,
                high: 161.694,
                lag_ms: 290,
                low: 161.694,
                open: 161.694,
                source: "Polygon_ws",
                start_ms: 1720624672999,
                timespan_ms: 0,
                volume: 1.0,
                vwap: 161.693,
                websocket: true,
              },
              {
                close: 161.696,
                high: 161.696,
                lag_ms: 10709,
                low: 161.696,
                open: 161.696,
                source: "TwelveData_ws",
                start_ms: 1720624662000,
                timespan_ms: 0,
                volume: null,
                vwap: null,
                websocket: true,
              },
            ],
            processed_ms: 1720624672709,
          },
        ],
        position_type: "LONG",
        position_uuid: "e1a2d893-4310-4227-9312-350b4d2f74ee",
        return_at_close: 1.0000018197854788,
        trade_pair: ["USDJPY", "USD/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 3438.6749999999997,
        close_ms: 0,
        current_return: 0.9901749604360823,
        initial_entry_price: 3070.98,
        is_closed_position: false,
        miner_hotkey: "5HgBDcx8Z9oEWrGQm7obH4aPE2M5YXWA6S6MP1HtFHguUqek",
        net_leverage: 0.1,
        open_ms: 1716156463517,
        orders: [
          {
            leverage: 0.05,
            order_type: "LONG",
            order_uuid: "9b08994a-2f15-405d-bd7b-da18e1890ba8",
            price: 3070.98,
            price_sources: [],
            processed_ms: 1716156463464,
          },
          {
            leverage: 0.05,
            order_type: "LONG",
            order_uuid: "aa684998-b10b-4fe9-b922-81aedc6b1c67",
            price: 3806.37,
            price_sources: [],
            processed_ms: 1716301903601,
          },
        ],
        position_type: "LONG",
        position_uuid: "c461a980-cdda-495e-ad8b-0d08f3e99746",
        return_at_close: 0.9901254516880605,
        trade_pair: ["ETHUSD", "ETH/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 148.0190000000006,
        close_ms: 0,
        current_return: 1.001604435493453,
        initial_entry_price: 168.324,
        is_closed_position: false,
        miner_hotkey: "5HgBDcx8Z9oEWrGQm7obH4aPE2M5YXWA6S6MP1HtFHguUqek",
        net_leverage: 0.010000000000000009,
        open_ms: 1714126790600,
        orders: [
          {
            leverage: 0.2,
            order_type: "LONG",
            order_uuid: "e7c0d59f-1b85-47e2-a05d-4c11e0d5ea4c",
            price: 168.324,
            price_sources: [],
            processed_ms: 1714126790592,
          },
          {
            leverage: 0.1,
            order_type: "LONG",
            order_uuid: "61a2140d-7c8f-486a-adf6-b1ec1c24b2a8",
            price: 168.183,
            price_sources: [],
            processed_ms: 1714130696786,
          },
          {
            leverage: 0.1,
            order_type: "LONG",
            order_uuid: "bf41146b-5c60-437e-b0b2-fda30574e144",
            price: 168.131,
            price_sources: [],
            processed_ms: 1714131164822,
          },
          {
            leverage: -0.39,
            order_type: "SHORT",
            order_uuid: "6d0e784d-d672-428c-acdd-5199b765e650",
            price: 168.759,
            price_sources: [],
            processed_ms: 1714156278730,
          },
        ],
        position_type: "LONG",
        position_uuid: "6ddd1856-7e77-4935-94a5-3a8aa701624a",
        return_at_close: 1.0015767411308116,
        trade_pair: ["EURJPY", "EUR/JPY", 7e-5, 0.001, 500],
      },
    ],
    thirty_day_returns: 1.0512453736974638,
  },
  "5Hii8qtRUmPWvJZtGhxSS5CgGKvnwMY9yXuxoGXYidKGBJdS": {
    all_time_returns: 1.0,
    n_positions: 0,
    percentage_profitable: 0.0,
    positions: [
      {
        average_entry_price: 63401.560000000005,
        close_ms: 1711153262953,
        current_return: 1.002263551520337,
        initial_entry_price: 63111.0,
        is_closed_position: true,
        miner_hotkey: "5Hii8qtRUmPWvJZtGhxSS5CgGKvnwMY9yXuxoGXYidKGBJdS",
        net_leverage: 0.0,
        open_ms: 1711148771770,
        orders: [
          {
            leverage: 0.1,
            order_type: "LONG",
            order_uuid: "2a9e145f-5a4a-491d-9dc1-47afcc62f1d4",
            price: 63111.0,
            price_sources: [],
            processed_ms: 1711148771770,
          },
          {
            leverage: 0.1,
            order_type: "LONG",
            order_uuid: "20948428-4ccf-44e6-83fc-517d0f137b25",
            price: 63296.41,
            price_sources: [],
            processed_ms: 1711149655242,
          },
          {
            leverage: 0.1,
            order_type: "LONG",
            order_uuid: "388421be-1016-4736-94c8-61571fb8defd",
            price: 63461.67,
            price_sources: [],
            processed_ms: 1711150573655,
          },
          {
            leverage: 0.1,
            order_type: "LONG",
            order_uuid: "5a2016b4-faf8-4b7e-9c82-fbea777eebdb",
            price: 63414.22,
            price_sources: [],
            processed_ms: 1711151456858,
          },
          {
            leverage: 0.1,
            order_type: "LONG",
            order_uuid: "8c5750f2-0ad1-4ef8-90ef-981db738437e",
            price: 63724.5,
            price_sources: [],
            processed_ms: 1711152368772,
          },
          {
            leverage: 0.1,
            order_type: "FLAT",
            order_uuid: "b14d7baf-3f46-4a7e-9533-0354f387cd7d",
            price: 63687.27,
            price_sources: [],
            processed_ms: 1711153262953,
          },
        ],
        position_type: "FLAT",
        position_uuid: "2fb62131-766a-4dea-bc76-b5bc6de90f7d",
        return_at_close: 1.0012612879688167,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 63758.490000000005,
        close_ms: 1711158655703,
        current_return: 1.0042692730213063,
        initial_entry_price: 63629.1,
        is_closed_position: true,
        miner_hotkey: "5Hii8qtRUmPWvJZtGhxSS5CgGKvnwMY9yXuxoGXYidKGBJdS",
        net_leverage: 0.0,
        open_ms: 1711154156996,
        orders: [
          {
            leverage: 0.1,
            order_type: "LONG",
            order_uuid: "4a54f3c3-df5a-46c5-a7ef-b91ec730cf00",
            price: 63629.1,
            price_sources: [],
            processed_ms: 1711154156996,
          },
          {
            leverage: 0.1,
            order_type: "LONG",
            order_uuid: "232efbb7-9cf0-4dc1-a62a-ef4cc28e23fc",
            price: 63561.62,
            price_sources: [],
            processed_ms: 1711155070591,
          },
          {
            leverage: 0.1,
            order_type: "LONG",
            order_uuid: "ead01020-cada-4238-8869-b601f0283281",
            price: 63746.97,
            price_sources: [],
            processed_ms: 1711155961638,
          },
          {
            leverage: 0.1,
            order_type: "LONG",
            order_uuid: "b9846d42-9e76-47e8-84fa-3c87dfff9d62",
            price: 63744.9,
            price_sources: [],
            processed_ms: 1711156878599,
          },
          {
            leverage: 0.1,
            order_type: "LONG",
            order_uuid: "4ff4d60e-ea3f-47ce-801e-e0df6d5ba497",
            price: 64109.86,
            price_sources: [],
            processed_ms: 1711157762883,
          },
          {
            leverage: 0.1,
            order_type: "FLAT",
            order_uuid: "935022eb-a060-4984-9e67-e3c372b24f05",
            price: 64301.79,
            price_sources: [],
            processed_ms: 1711158655703,
          },
        ],
        position_type: "FLAT",
        position_uuid: "2fc7aae2-09eb-407c-98a7-79236f2ded22",
        return_at_close: 1.003265003748285,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 63904.566000000006,
        close_ms: 1711164061858,
        current_return: 0.9974232035307676,
        initial_entry_price: 64226.26,
        is_closed_position: true,
        miner_hotkey: "5Hii8qtRUmPWvJZtGhxSS5CgGKvnwMY9yXuxoGXYidKGBJdS",
        net_leverage: 0.0,
        open_ms: 1711159573639,
        orders: [
          {
            leverage: 0.1,
            order_type: "LONG",
            order_uuid: "bf42e09b-5a41-4111-b253-b0bd04b0256d",
            price: 64226.26,
            price_sources: [],
            processed_ms: 1711159573638,
          },
          {
            leverage: 0.1,
            order_type: "LONG",
            order_uuid: "39e8edd4-7f96-4b65-a184-c418db382635",
            price: 63964.88,
            price_sources: [],
            processed_ms: 1711160459999,
          },
          {
            leverage: 0.1,
            order_type: "LONG",
            order_uuid: "049950aa-b043-440d-858c-570b892407bb",
            price: 63858.22,
            price_sources: [],
            processed_ms: 1711161377402,
          },
          {
            leverage: 0.1,
            order_type: "LONG",
            order_uuid: "526a75c3-c453-45e8-a08a-f1ee2d5042e6",
            price: 63815.34,
            price_sources: [],
            processed_ms: 1711162260460,
          },
          {
            leverage: 0.1,
            order_type: "LONG",
            order_uuid: "0a7f2c5f-37b0-4acb-93bb-12060752e787",
            price: 63658.13,
            price_sources: [],
            processed_ms: 1711163171317,
          },
          {
            leverage: 0.1,
            order_type: "FLAT",
            order_uuid: "e7d0c813-a6be-4bca-b855-50d068792a1a",
            price: 63573.57,
            price_sources: [],
            processed_ms: 1711164061858,
          },
        ],
        position_type: "FLAT",
        position_uuid: "0488bc0e-7e17-46a4-97bf-f292f89aa878",
        return_at_close: 0.9964257803272368,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 63608.115999999995,
        close_ms: 1711169474023,
        current_return: 1.002889497291868,
        initial_entry_price: 63170.85,
        is_closed_position: true,
        miner_hotkey: "5Hii8qtRUmPWvJZtGhxSS5CgGKvnwMY9yXuxoGXYidKGBJdS",
        net_leverage: 0.0,
        open_ms: 1711164981858,
        orders: [
          {
            leverage: 0.1,
            order_type: "LONG",
            order_uuid: "36e2b4b3-1418-41d5-b3a5-cd93ad3a6d2b",
            price: 63170.85,
            price_sources: [],
            processed_ms: 1711164981857,
          },
          {
            leverage: 0.1,
            order_type: "LONG",
            order_uuid: "795d283e-d275-4063-992c-4dbca0318a23",
            price: 63397.37,
            price_sources: [],
            processed_ms: 1711165861901,
          },
          {
            leverage: 0.1,
            order_type: "LONG",
            order_uuid: "f8dc1d06-908d-413b-8107-eb600e625ed2",
            price: 63618.9,
            price_sources: [],
            processed_ms: 1711166761863,
          },
          {
            leverage: 0.1,
            order_type: "LONG",
            order_uuid: "8566ba1c-b2c2-480f-bc9e-50e0324ceddc",
            price: 63918.69,
            price_sources: [],
            processed_ms: 1711167661521,
          },
          {
            leverage: 0.1,
            order_type: "LONG",
            order_uuid: "4a633704-56b1-4ee1-b91e-a42f9ee35623",
            price: 63934.77,
            price_sources: [],
            processed_ms: 1711168579917,
          },
          {
            leverage: 0.1,
            order_type: "FLAT",
            order_uuid: "c474d8c7-646e-4062-9a43-3af198728db6",
            price: 63973.18,
            price_sources: [],
            processed_ms: 1711169474023,
          },
        ],
        position_type: "FLAT",
        position_uuid: "ce790626-a2ea-471b-9cea-b637316b0e0f",
        return_at_close: 1.0018866077945763,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 63984.270000000004,
        close_ms: 1711174865797,
        current_return: 1.0007561484255336,
        initial_entry_price: 63962.31,
        is_closed_position: true,
        miner_hotkey: "5Hii8qtRUmPWvJZtGhxSS5CgGKvnwMY9yXuxoGXYidKGBJdS",
        net_leverage: 0.0,
        open_ms: 1711170372168,
        orders: [
          {
            leverage: 0.1,
            order_type: "LONG",
            order_uuid: "d249a706-cee9-4445-802e-ba45791cc38e",
            price: 63962.31,
            price_sources: [],
            processed_ms: 1711170372167,
          },
          {
            leverage: 0.1,
            order_type: "LONG",
            order_uuid: "50d56622-35d1-40a4-8e61-7806f3f64a66",
            price: 63912.12,
            price_sources: [],
            processed_ms: 1711171282407,
          },
          {
            leverage: 0.1,
            order_type: "LONG",
            order_uuid: "d9e918c1-6adc-4529-aa5f-63f88143f66d",
            price: 63963.95,
            price_sources: [],
            processed_ms: 1711172166486,
          },
          {
            leverage: 0.1,
            order_type: "LONG",
            order_uuid: "659f3429-a46a-4016-bf22-9f1e03a010e1",
            price: 64025.25,
            price_sources: [],
            processed_ms: 1711173082589,
          },
          {
            leverage: 0.1,
            order_type: "LONG",
            order_uuid: "8a781891-66dc-47a7-b9fa-38f24c4829aa",
            price: 64057.72,
            price_sources: [],
            processed_ms: 1711173964442,
          },
          {
            leverage: 0.1,
            order_type: "FLAT",
            order_uuid: "973abc76-9e65-4689-96b4-f5711cabc532",
            price: 64081.0,
            price_sources: [],
            processed_ms: 1711174865797,
          },
        ],
        position_type: "FLAT",
        position_uuid: "9fa362be-7919-47d8-a2f9-c95936739a20",
        return_at_close: 0.9997553922771081,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 64349.96200000001,
        close_ms: 1711180275604,
        current_return: 1.001797416287155,
        initial_entry_price: 64152.64,
        is_closed_position: true,
        miner_hotkey: "5Hii8qtRUmPWvJZtGhxSS5CgGKvnwMY9yXuxoGXYidKGBJdS",
        net_leverage: 0.0,
        open_ms: 1711175783793,
        orders: [
          {
            leverage: 0.1,
            order_type: "LONG",
            order_uuid: "a1140275-9d7a-4d8e-87d1-f1886839ddf2",
            price: 64152.64,
            price_sources: [],
            processed_ms: 1711175783792,
          },
          {
            leverage: 0.1,
            order_type: "LONG",
            order_uuid: "7da233f2-4a80-41c4-a042-7a4fc6eae5f0",
            price: 64086.52,
            price_sources: [],
            processed_ms: 1711176663671,
          },
          {
            leverage: 0.1,
            order_type: "LONG",
            order_uuid: "0bddf5f2-098c-40cf-8724-3683903f5e17",
            price: 64211.24,
            price_sources: [],
            processed_ms: 1711177563816,
          },
          {
            leverage: 0.1,
            order_type: "LONG",
            order_uuid: "0bb1bc44-84c3-4617-ac58-d3a3fcde7577",
            price: 64824.69,
            price_sources: [],
            processed_ms: 1711178466409,
          },
          {
            leverage: 0.1,
            order_type: "LONG",
            order_uuid: "5c2d848c-bd1b-41a0-8f0f-2d24811d336a",
            price: 64474.72,
            price_sources: [],
            processed_ms: 1711179383602,
          },
          {
            leverage: 0.1,
            order_type: "FLAT",
            order_uuid: "64801f34-6710-45d2-90db-3ceb2b050257",
            price: 64580.58,
            price_sources: [],
            processed_ms: 1711180275604,
          },
        ],
        position_type: "FLAT",
        position_uuid: "8d753eb2-aade-4e3e-8a84-458c1c949e9d",
        return_at_close: 1.0007956188708678,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 64500.47600000001,
        close_ms: 1711185669921,
        current_return: 0.9984287893511037,
        initial_entry_price: 64760.89,
        is_closed_position: true,
        miner_hotkey: "5Hii8qtRUmPWvJZtGhxSS5CgGKvnwMY9yXuxoGXYidKGBJdS",
        net_leverage: 0.0,
        open_ms: 1711181176082,
        orders: [
          {
            leverage: 0.1,
            order_type: "LONG",
            order_uuid: "d052b88b-9cdc-473a-a6e0-31e1fa398291",
            price: 64760.89,
            price_sources: [],
            processed_ms: 1711181176081,
          },
          {
            leverage: 0.1,
            order_type: "LONG",
            order_uuid: "f16521b5-c5b0-4db8-a634-c27194edfe9b",
            price: 64588.64,
            price_sources: [],
            processed_ms: 1711182065831,
          },
          {
            leverage: 0.1,
            order_type: "LONG",
            order_uuid: "c69a9324-42e4-4117-9721-c9e18bcf2637",
            price: 64254.01,
            price_sources: [],
            processed_ms: 1711182972024,
          },
          {
            leverage: 0.1,
            order_type: "LONG",
            order_uuid: "4368706d-5ddc-411b-b629-41aefb04f6f6",
            price: 64501.96,
            price_sources: [],
            processed_ms: 1711183874430,
          },
          {
            leverage: 0.1,
            order_type: "LONG",
            order_uuid: "90ac0444-999d-41ff-b1b8-c45d750961a1",
            price: 64396.88,
            price_sources: [],
            processed_ms: 1711184768950,
          },
          {
            leverage: 0.1,
            order_type: "FLAT",
            order_uuid: "b4c16a3b-fbe4-4525-9a1b-3c606db08d2d",
            price: 64296.97,
            price_sources: [],
            processed_ms: 1711185669921,
          },
        ],
        position_type: "FLAT",
        position_uuid: "ac6273e2-6603-43a1-b654-094bd59d2061",
        return_at_close: 0.9974303605617526,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 64479.47600000001,
        close_ms: 1711191066947,
        current_return: 1.0000575501006115,
        initial_entry_price: 64326.56,
        is_closed_position: true,
        miner_hotkey: "5Hii8qtRUmPWvJZtGhxSS5CgGKvnwMY9yXuxoGXYidKGBJdS",
        net_leverage: 0.0,
        open_ms: 1711186579979,
        orders: [
          {
            leverage: 0.1,
            order_type: "LONG",
            order_uuid: "a58d6c02-93bd-4998-a1f9-44aebe55db61",
            price: 64326.56,
            price_sources: [],
            processed_ms: 1711186579978,
          },
          {
            leverage: 0.1,
            order_type: "LONG",
            order_uuid: "b96aa4dd-0416-4ea9-889d-29df709e92bb",
            price: 64301.14,
            price_sources: [],
            processed_ms: 1711187473787,
          },
          {
            leverage: 0.1,
            order_type: "LONG",
            order_uuid: "121c2df8-42a0-4fd6-99ae-7f8de6024610",
            price: 64593.09,
            price_sources: [],
            processed_ms: 1711188372708,
          },
          {
            leverage: 0.1,
            order_type: "LONG",
            order_uuid: "b2f18eb6-c800-421a-84b0-f060b6b34091",
            price: 64572.76,
            price_sources: [],
            processed_ms: 1711189286141,
          },
          {
            leverage: 0.1,
            order_type: "LONG",
            order_uuid: "bd268f87-7a0e-4a75-a09b-bff2d885e66a",
            price: 64603.83,
            price_sources: [],
            processed_ms: 1711190174298,
          },
          {
            leverage: 0.1,
            order_type: "FLAT",
            order_uuid: "db95585c-6cb9-480f-aab1-09749335dce3",
            price: 64486.88,
            price_sources: [],
            processed_ms: 1711191066947,
          },
        ],
        position_type: "FLAT",
        position_uuid: "367902e4-59d5-4036-a23d-eeef41c7195f",
        return_at_close: 0.9990574925505109,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 64492.552,
        close_ms: 1711196472221,
        current_return: 1.0009673300716144,
        initial_entry_price: 64278.99,
        is_closed_position: true,
        miner_hotkey: "5Hii8qtRUmPWvJZtGhxSS5CgGKvnwMY9yXuxoGXYidKGBJdS",
        net_leverage: 0.0,
        open_ms: 1711191967010,
        orders: [
          {
            leverage: 0.1,
            order_type: "LONG",
            order_uuid: "47952646-7b69-45c5-9fff-113794353bf1",
            price: 64278.99,
            price_sources: [],
            processed_ms: 1711191967008,
          },
          {
            leverage: 0.1,
            order_type: "LONG",
            order_uuid: "536d4b44-d00d-451d-85f1-f2b221951c5f",
            price: 64481.76,
            price_sources: [],
            processed_ms: 1711192885450,
          },
          {
            leverage: 0.1,
            order_type: "LONG",
            order_uuid: "59716336-a592-4301-96af-ed6fb1af8dc0",
            price: 64567.46,
            price_sources: [],
            processed_ms: 1711193791346,
          },
          {
            leverage: 0.1,
            order_type: "LONG",
            order_uuid: "596a64bb-7fce-46d7-9fd8-86772b498982",
            price: 64499.91,
            price_sources: [],
            processed_ms: 1711194711660,
          },
          {
            leverage: 0.1,
            order_type: "LONG",
            order_uuid: "57375e4e-b50d-4702-86f1-53d4e3af77b6",
            price: 64634.64,
            price_sources: [],
            processed_ms: 1711195568454,
          },
          {
            leverage: 0.1,
            order_type: "FLAT",
            order_uuid: "4ea0ffc9-fc9a-476b-86a5-585bff65477b",
            price: 64616.91,
            price_sources: [],
            processed_ms: 1711196472221,
          },
        ],
        position_type: "FLAT",
        position_uuid: "34e1da12-e356-4ec3-bfe7-d8fceba42abc",
        return_at_close: 0.9999663627415428,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 64638.202000000005,
        close_ms: 1711201871639,
        current_return: 0.9997470107563737,
        initial_entry_price: 64650.97,
        is_closed_position: true,
        miner_hotkey: "5Hii8qtRUmPWvJZtGhxSS5CgGKvnwMY9yXuxoGXYidKGBJdS",
        net_leverage: 0.0,
        open_ms: 1711197375589,
        orders: [
          {
            leverage: 0.1,
            order_type: "LONG",
            order_uuid: "e7faa2df-14e5-4d2e-afd1-ce039c4bae76",
            price: 64650.97,
            price_sources: [],
            processed_ms: 1711197375587,
          },
          {
            leverage: 0.1,
            order_type: "LONG",
            order_uuid: "268b9fa3-e860-4d1e-af8b-6e851250e694",
            price: 64821.96,
            price_sources: [],
            processed_ms: 1711198283395,
          },
          {
            leverage: 0.1,
            order_type: "LONG",
            order_uuid: "1f734421-e810-493a-9c92-38479e06609c",
            price: 64644.48,
            price_sources: [],
            processed_ms: 1711199211522,
          },
          {
            leverage: 0.1,
            order_type: "LONG",
            order_uuid: "eea52443-111d-4bc3-8cf1-ed95388a9bb1",
            price: 64619.04,
            price_sources: [],
            processed_ms: 1711200070465,
          },
          {
            leverage: 0.1,
            order_type: "LONG",
            order_uuid: "57422dd8-a111-455e-8f88-7e9fcb0e9182",
            price: 64454.56,
            price_sources: [],
            processed_ms: 1711200968417,
          },
          {
            leverage: 0.1,
            order_type: "FLAT",
            order_uuid: "c5461ecb-f014-4316-9f46-286346d2e541",
            price: 64605.49,
            price_sources: [],
            processed_ms: 1711201871639,
          },
        ],
        position_type: "FLAT",
        position_uuid: "d0aaea6c-6045-4e3b-ad94-1012f5929a98",
        return_at_close: 0.9987472637456174,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 65029.25600000001,
        close_ms: 1711207280483,
        current_return: 0.9982667153417736,
        initial_entry_price: 64618.93,
        is_closed_position: true,
        miner_hotkey: "5Hii8qtRUmPWvJZtGhxSS5CgGKvnwMY9yXuxoGXYidKGBJdS",
        net_leverage: 0.0,
        open_ms: 1711202788910,
        orders: [
          {
            leverage: 0.1,
            order_type: "LONG",
            order_uuid: "92f0bf96-0889-4751-b2b8-4a776f5cb9d0",
            price: 64618.93,
            price_sources: [],
            processed_ms: 1711202788909,
          },
          {
            leverage: 0.1,
            order_type: "LONG",
            order_uuid: "b44b6555-c60f-4a0f-a9d6-bf51be8fb26c",
            price: 64625.36,
            price_sources: [],
            processed_ms: 1711203707513,
          },
          {
            leverage: 0.1,
            order_type: "LONG",
            order_uuid: "091dd599-5f6e-4098-a653-14094965894f",
            price: 65355.55,
            price_sources: [],
            processed_ms: 1711204575069,
          },
          {
            leverage: 0.1,
            order_type: "LONG",
            order_uuid: "5b553a0e-00c3-4260-804d-08f823a2003e",
            price: 65284.01,
            price_sources: [],
            processed_ms: 1711205469465,
          },
          {
            leverage: 0.1,
            order_type: "LONG",
            order_uuid: "3b8e2bfc-e2e0-4e29-856b-fa5633dec8bf",
            price: 65262.43,
            price_sources: [],
            processed_ms: 1711206425017,
          },
          {
            leverage: 0.1,
            order_type: "FLAT",
            order_uuid: "0b690a73-4685-4817-b9c0-1ea28d4ddb11",
            price: 64805.25,
            price_sources: [],
            processed_ms: 1711207280483,
          },
        ],
        position_type: "FLAT",
        position_uuid: "b20bb475-5cd6-4c5e-adbd-5647e7d14a93",
        return_at_close: 0.9972684486264318,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 65102.462,
        close_ms: 1711212691471,
        current_return: 1.003830106535001,
        initial_entry_price: 64849.11,
        is_closed_position: true,
        miner_hotkey: "5Hii8qtRUmPWvJZtGhxSS5CgGKvnwMY9yXuxoGXYidKGBJdS",
        net_leverage: 0.0,
        open_ms: 1711208171547,
        orders: [
          {
            leverage: 0.1,
            order_type: "LONG",
            order_uuid: "eb2879e0-1a14-4d58-8487-2dc5475b628a",
            price: 64849.11,
            price_sources: [],
            processed_ms: 1711208171546,
          },
          {
            leverage: 0.1,
            order_type: "LONG",
            order_uuid: "53058b7b-cf3a-4782-ab6a-ecf7892da452",
            price: 64930.43,
            price_sources: [],
            processed_ms: 1711209070532,
          },
          {
            leverage: 0.1,
            order_type: "LONG",
            order_uuid: "f304b318-c3f7-42eb-96f9-ffc45dd586ba",
            price: 65077.18,
            price_sources: [],
            processed_ms: 1711209978256,
          },
          {
            leverage: 0.1,
            order_type: "LONG",
            order_uuid: "21b09262-41d4-4cdb-98f6-d82244ebd2cd",
            price: 65155.67,
            price_sources: [],
            processed_ms: 1711210877769,
          },
          {
            leverage: 0.1,
            order_type: "LONG",
            order_uuid: "4e812d88-c2a3-416d-921d-683efec1d7ad",
            price: 65499.92,
            price_sources: [],
            processed_ms: 1711211777894,
          },
          {
            leverage: 0.1,
            order_type: "FLAT",
            order_uuid: "5611f965-39bd-4f91-a3ae-f78fc831f93c",
            price: 65599.22,
            price_sources: [],
            processed_ms: 1711212691471,
          },
        ],
        position_type: "FLAT",
        position_uuid: "7da453a7-08ae-4e5c-b8b2-c288e885bd3e",
        return_at_close: 1.0028262764284661,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 65705.288,
        close_ms: 1711218082714,
        current_return: 0.9987881035287525,
        initial_entry_price: 65664.85,
        is_closed_position: true,
        miner_hotkey: "5Hii8qtRUmPWvJZtGhxSS5CgGKvnwMY9yXuxoGXYidKGBJdS",
        net_leverage: 0.0,
        open_ms: 1711213588981,
        orders: [
          {
            leverage: 0.1,
            order_type: "LONG",
            order_uuid: "16ceeb05-3bb0-4598-b629-fdbf4b48c79f",
            price: 65664.85,
            price_sources: [],
            processed_ms: 1711213588979,
          },
          {
            leverage: 0.1,
            order_type: "LONG",
            order_uuid: "997eb401-455c-43b5-87b1-a3697f672932",
            price: 65561.33,
            price_sources: [],
            processed_ms: 1711214496879,
          },
          {
            leverage: 0.1,
            order_type: "LONG",
            order_uuid: "de388bca-dbae-4033-bea9-45a7a660b226",
            price: 65931.17,
            price_sources: [],
            processed_ms: 1711215374815,
          },
          {
            leverage: 0.1,
            order_type: "LONG",
            order_uuid: "9124152d-a02a-46b2-bd76-7c12c87dd139",
            price: 65714.73,
            price_sources: [],
            processed_ms: 1711216288760,
          },
          {
            leverage: 0.1,
            order_type: "LONG",
            order_uuid: "246b9581-da5f-40fb-894c-5c345952c3da",
            price: 65654.36,
            price_sources: [],
            processed_ms: 1711217186353,
          },
          {
            leverage: 0.1,
            order_type: "FLAT",
            order_uuid: "ba81a00a-3f4f-4ff4-b16c-2e825d4ab11d",
            price: 65546.13,
            price_sources: [],
            processed_ms: 1711218082714,
          },
        ],
        position_type: "FLAT",
        position_uuid: "0b487702-d77b-4541-970d-4236dea00c57",
        return_at_close: 0.9977893154252238,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 65348.08000000001,
        close_ms: 1711223476122,
        current_return: 0.996374464763004,
        initial_entry_price: 65428.96,
        is_closed_position: true,
        miner_hotkey: "5Hii8qtRUmPWvJZtGhxSS5CgGKvnwMY9yXuxoGXYidKGBJdS",
        net_leverage: 0.0,
        open_ms: 1711218980079,
        orders: [
          {
            leverage: 0.1,
            order_type: "LONG",
            order_uuid: "e8e179a9-1c55-4fcd-a996-63e1215d0c7f",
            price: 65428.96,
            price_sources: [],
            processed_ms: 1711218980077,
          },
          {
            leverage: 0.1,
            order_type: "LONG",
            order_uuid: "d4db6a96-acbe-4b04-b684-0fcf3666c81c",
            price: 65489.98,
            price_sources: [],
            processed_ms: 1711219896261,
          },
          {
            leverage: 0.1,
            order_type: "LONG",
            order_uuid: "24ba67fb-aee7-4d43-a04f-a3c853d029e3",
            price: 65412.6,
            price_sources: [],
            processed_ms: 1711220800297,
          },
          {
            leverage: 0.1,
            order_type: "LONG",
            order_uuid: "901aef94-fc7c-496f-9192-698d4fcb8f7a",
            price: 65303.3,
            price_sources: [],
            processed_ms: 1711221680359,
          },
          {
            leverage: 0.1,
            order_type: "LONG",
            order_uuid: "d6ef44b6-43b3-472e-bfac-98e1f16881f6",
            price: 65105.56,
            price_sources: [],
            processed_ms: 1711222586025,
          },
          {
            leverage: 0.1,
            order_type: "FLAT",
            order_uuid: "01cb3fee-b216-4ba4-81dd-a906913888b7",
            price: 64873.65,
            price_sources: [],
            processed_ms: 1711223476122,
          },
        ],
        position_type: "FLAT",
        position_uuid: "3d3618e3-7f67-497b-b776-d620d32c7a3e",
        return_at_close: 0.995378090298241,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 64934.46000000001,
        close_ms: 1711228885233,
        current_return: 0.9999085385147547,
        initial_entry_price: 64945.37,
        is_closed_position: true,
        miner_hotkey: "5Hii8qtRUmPWvJZtGhxSS5CgGKvnwMY9yXuxoGXYidKGBJdS",
        net_leverage: 0.0,
        open_ms: 1711224383282,
        orders: [
          {
            leverage: 0.1,
            order_type: "LONG",
            order_uuid: "5172db7c-c461-4896-86a4-9f042e3de99f",
            price: 64945.37,
            price_sources: [],
            processed_ms: 1711224383280,
          },
          {
            leverage: 0.1,
            order_type: "LONG",
            order_uuid: "9309e13c-a9c7-49d4-9a4b-52ef344d2a5f",
            price: 64945.29,
            price_sources: [],
            processed_ms: 1711225298950,
          },
          {
            leverage: 0.1,
            order_type: "LONG",
            order_uuid: "172fddfd-1c14-44ff-b0d8-2f7e8cfeb174",
            price: 64998.41,
            price_sources: [],
            processed_ms: 1711226194863,
          },
          {
            leverage: 0.1,
            order_type: "LONG",
            order_uuid: "23325ee9-8529-4f03-97be-3526254e5a51",
            price: 64893.51,
            price_sources: [],
            processed_ms: 1711227091650,
          },
          {
            leverage: 0.1,
            order_type: "LONG",
            order_uuid: "bf200770-3ff3-49a1-b227-adabf4ce69dd",
            price: 64889.72,
            price_sources: [],
            processed_ms: 1711227996504,
          },
          {
            leverage: 0.1,
            order_type: "FLAT",
            order_uuid: "d37aa3bf-a1e7-4b51-ba77-0d311535ffe0",
            price: 64922.58,
            price_sources: [],
            processed_ms: 1711228885233,
          },
        ],
        position_type: "FLAT",
        position_uuid: "361e25e6-62ae-4bc0-b0b5-d949ac449c32",
        return_at_close: 0.99890862997624,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 64945.804,
        close_ms: 1711234287955,
        current_return: 0.9992607670088871,
        initial_entry_price: 64948.4,
        is_closed_position: true,
        miner_hotkey: "5Hii8qtRUmPWvJZtGhxSS5CgGKvnwMY9yXuxoGXYidKGBJdS",
        net_leverage: 0.0,
        open_ms: 1711229795384,
        orders: [
          {
            leverage: 0.1,
            order_type: "LONG",
            order_uuid: "9a0c74e6-670d-47ec-b924-2ad6b6f3e716",
            price: 64948.4,
            price_sources: [],
            processed_ms: 1711229795382,
          },
          {
            leverage: 0.1,
            order_type: "LONG",
            order_uuid: "bc081e75-034f-4648-83ca-add107e6ae98",
            price: 64951.76,
            price_sources: [],
            processed_ms: 1711230692316,
          },
          {
            leverage: 0.1,
            order_type: "LONG",
            order_uuid: "9639c181-f2e6-4b47-92d0-b78db9482813",
            price: 64975.33,
            price_sources: [],
            processed_ms: 1711231593423,
          },
          {
            leverage: 0.1,
            order_type: "LONG",
            order_uuid: "6a1c3baf-482d-4027-8cf3-a768086340c6",
            price: 64842.1,
            price_sources: [],
            processed_ms: 1711232486367,
          },
          {
            leverage: 0.1,
            order_type: "LONG",
            order_uuid: "3e3f3e63-c649-419d-a753-0ef08798b399",
            price: 65011.43,
            price_sources: [],
            processed_ms: 1711233397813,
          },
          {
            leverage: 0.1,
            order_type: "FLAT",
            order_uuid: "adfcbd29-d324-434e-87f9-a346e437407b",
            price: 64849.78,
            price_sources: [],
            processed_ms: 1711234287955,
          },
        ],
        position_type: "FLAT",
        position_uuid: "793cd5d2-fbf5-488a-b856-12abdddd32b1",
        return_at_close: 0.9982615062418783,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 64500.388,
        close_ms: 1711239682313,
        current_return: 0.9985209131860749,
        initial_entry_price: 64785.92,
        is_closed_position: true,
        miner_hotkey: "5Hii8qtRUmPWvJZtGhxSS5CgGKvnwMY9yXuxoGXYidKGBJdS",
        net_leverage: 0.0,
        open_ms: 1711235181394,
        orders: [
          {
            leverage: 0.1,
            order_type: "LONG",
            order_uuid: "443522f3-029d-4515-878e-1d96ee0542b1",
            price: 64785.92,
            price_sources: [],
            processed_ms: 1711235181392,
          },
          {
            leverage: 0.1,
            order_type: "LONG",
            order_uuid: "c6455de5-8d3e-4cc4-9110-7504f61c223c",
            price: 64764.04,
            price_sources: [],
            processed_ms: 1711236078529,
          },
          {
            leverage: 0.1,
            order_type: "LONG",
            order_uuid: "011a0df0-28ec-4250-b629-01c8eb68f5ae",
            price: 64400.0,
            price_sources: [],
            processed_ms: 1711237003465,
          },
          {
            leverage: 0.1,
            order_type: "LONG",
            order_uuid: "9df441b2-e22a-4751-8698-f7a9089f289e",
            price: 64393.22,
            price_sources: [],
            processed_ms: 1711237903372,
          },
          {
            leverage: 0.1,
            order_type: "LONG",
            order_uuid: "be22cb1f-9937-4d5e-949f-e09ce8137787",
            price: 64158.76,
            price_sources: [],
            processed_ms: 1711238779763,
          },
          {
            leverage: 0.1,
            order_type: "FLAT",
            order_uuid: "108ccc0e-7799-4335-97f3-046fbb6d57ac",
            price: 64308.74,
            price_sources: [],
            processed_ms: 1711239682313,
          },
        ],
        position_type: "FLAT",
        position_uuid: "5ec62c15-822a-47a0-8792-7877cd2f8cba",
        return_at_close: 0.9975223922728889,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 64488.182,
        close_ms: 1711245089672,
        current_return: 1.000341816155982,
        initial_entry_price: 64476.18,
        is_closed_position: true,
        miner_hotkey: "5Hii8qtRUmPWvJZtGhxSS5CgGKvnwMY9yXuxoGXYidKGBJdS",
        net_leverage: 0.0,
        open_ms: 1711240601196,
        orders: [
          {
            leverage: 0.1,
            order_type: "LONG",
            order_uuid: "c003b2b9-fdee-4063-9a96-e9699741704c",
            price: 64476.18,
            price_sources: [],
            processed_ms: 1711240601194,
          },
          {
            leverage: 0.1,
            order_type: "LONG",
            order_uuid: "03e6fdea-11a9-4468-8bf8-32894d438edf",
            price: 64566.96,
            price_sources: [],
            processed_ms: 1711241483454,
          },
          {
            leverage: 0.1,
            order_type: "LONG",
            order_uuid: "b7ab83cc-8a44-44c7-8d9c-86a2f6f8c7a5",
            price: 64457.41,
            price_sources: [],
            processed_ms: 1711242385120,
          },
          {
            leverage: 0.1,
            order_type: "LONG",
            order_uuid: "f0747baf-7b50-4227-adc6-aad062030a7d",
            price: 64402.41,
            price_sources: [],
            processed_ms: 1711243287727,
          },
          {
            leverage: 0.1,
            order_type: "LONG",
            order_uuid: "81f664b2-8815-455b-98b5-9ecf7ec70549",
            price: 64537.95,
            price_sources: [],
            processed_ms: 1711244181712,
          },
          {
            leverage: 0.1,
            order_type: "FLAT",
            order_uuid: "138e75bb-cbce-4072-a351-0cb2d9dbb0a7",
            price: 64532.26,
            price_sources: [],
            processed_ms: 1711245089672,
          },
        ],
        position_type: "FLAT",
        position_uuid: "737372fd-3951-4083-b0aa-392faaf56454",
        return_at_close: 0.9993414743398261,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 64297.941999999995,
        close_ms: 1711250486647,
        current_return: 0.998694040393064,
        initial_entry_price: 64417.0,
        is_closed_position: true,
        miner_hotkey: "5Hii8qtRUmPWvJZtGhxSS5CgGKvnwMY9yXuxoGXYidKGBJdS",
        net_leverage: 0.0,
        open_ms: 1711245990378,
        orders: [
          {
            leverage: 0.1,
            order_type: "LONG",
            order_uuid: "ba373011-2fbc-44df-9cda-38759548ca9e",
            price: 64417.0,
            price_sources: [],
            processed_ms: 1711245990375,
          },
          {
            leverage: 0.1,
            order_type: "LONG",
            order_uuid: "ce81d34b-4044-4f7e-a906-81c3e13ffdf6",
            price: 64347.51,
            price_sources: [],
            processed_ms: 1711246886928,
          },
          {
            leverage: 0.1,
            order_type: "LONG",
            order_uuid: "62db04cd-ddcc-4f8a-9550-3c462cf2d1c2",
            price: 64340.7,
            price_sources: [],
            processed_ms: 1711247786237,
          },
          {
            leverage: 0.1,
            order_type: "LONG",
            order_uuid: "30041fd9-63ce-4855-8979-f0fe2b07c732",
            price: 64273.33,
            price_sources: [],
            processed_ms: 1711248682900,
          },
          {
            leverage: 0.1,
            order_type: "LONG",
            order_uuid: "ebfff631-dc0d-4023-b962-85162fc75047",
            price: 64111.17,
            price_sources: [],
            processed_ms: 1711249611939,
          },
          {
            leverage: 0.1,
            order_type: "FLAT",
            order_uuid: "9befd807-ceb6-45ab-b933-caf190570aaf",
            price: 64129.69,
            price_sources: [],
            processed_ms: 1711250486647,
          },
        ],
        position_type: "FLAT",
        position_uuid: "2ded2f5d-4329-4897-a54c-c31ed88a7df9",
        return_at_close: 0.9976953463526709,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 64083.108,
        close_ms: 1711255913594,
        current_return: 1.0000254399147739,
        initial_entry_price: 64111.85,
        is_closed_position: true,
        miner_hotkey: "5Hii8qtRUmPWvJZtGhxSS5CgGKvnwMY9yXuxoGXYidKGBJdS",
        net_leverage: 0.0,
        open_ms: 1711251391123,
        orders: [
          {
            leverage: 0.1,
            order_type: "LONG",
            order_uuid: "144fbe85-6d52-4598-91fb-62f2e2edef8d",
            price: 64111.85,
            price_sources: [],
            processed_ms: 1711251391121,
          },
          {
            leverage: 0.1,
            order_type: "LONG",
            order_uuid: "85e63641-11ee-49af-a160-826bedc022a4",
            price: 64163.62,
            price_sources: [],
            processed_ms: 1711252291095,
          },
          {
            leverage: 0.1,
            order_type: "LONG",
            order_uuid: "91bc8ed8-b37c-4146-818c-cf2a2b890cb2",
            price: 64094.74,
            price_sources: [],
            processed_ms: 1711253187137,
          },
          {
            leverage: 0.1,
            order_type: "LONG",
            order_uuid: "0a8c0277-1b7c-45e2-a453-ca9e1a942bef",
            price: 64040.45,
            price_sources: [],
            processed_ms: 1711254085835,
          },
          {
            leverage: 0.1,
            order_type: "LONG",
            order_uuid: "84b3ae12-2b90-4489-91e3-4cff629df6a2",
            price: 64004.88,
            price_sources: [],
            processed_ms: 1711254984743,
          },
          {
            leverage: 0.1,
            order_type: "FLAT",
            order_uuid: "d0ad857c-eff7-4bf7-94d6-f73ad3767c7e",
            price: 64086.37,
            price_sources: [],
            processed_ms: 1711255913594,
          },
        ],
        position_type: "FLAT",
        position_uuid: "9b8a5d53-ef76-46da-a8b8-7a80e3808221",
        return_at_close: 0.9990254144748592,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 64283.80000000001,
        close_ms: 1711261286097,
        current_return: 1.0002827880691167,
        initial_entry_price: 64182.34,
        is_closed_position: true,
        miner_hotkey: "5Hii8qtRUmPWvJZtGhxSS5CgGKvnwMY9yXuxoGXYidKGBJdS",
        net_leverage: 0.0,
        open_ms: 1711256791238,
        orders: [
          {
            leverage: 0.1,
            order_type: "LONG",
            order_uuid: "e9a17f49-5121-4b5e-bc9e-5459dea0711e",
            price: 64182.34,
            price_sources: [],
            processed_ms: 1711256791235,
          },
          {
            leverage: 0.1,
            order_type: "LONG",
            order_uuid: "cd32b761-5823-40f5-9123-2d212db9f648",
            price: 64294.94,
            price_sources: [],
            processed_ms: 1711257716543,
          },
          {
            leverage: 0.1,
            order_type: "LONG",
            order_uuid: "ab718bee-4154-4a6c-a5e4-df8b5ba596f7",
            price: 64311.06,
            price_sources: [],
            processed_ms: 1711258591248,
          },
          {
            leverage: 0.1,
            order_type: "LONG",
            order_uuid: "503c7b11-531e-4f98-812e-027387f1cf13",
            price: 64380.76,
            price_sources: [],
            processed_ms: 1711259491756,
          },
          {
            leverage: 0.1,
            order_type: "LONG",
            order_uuid: "9ca66982-e400-4d1d-a242-5e1fa3f93565",
            price: 64249.9,
            price_sources: [],
            processed_ms: 1711260392992,
          },
          {
            leverage: 0.1,
            order_type: "FLAT",
            order_uuid: "e3b78e49-d7dd-4f69-9f5c-f0b252652071",
            price: 64320.1,
            price_sources: [],
            processed_ms: 1711261286097,
          },
        ],
        position_type: "FLAT",
        position_uuid: "51480585-6bfe-47f4-843e-fb273a7586c3",
        return_at_close: 0.9992825052810477,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 64156.914000000004,
        close_ms: 1711266691793,
        current_return: 1.0009515899272174,
        initial_entry_price: 64232.5,
        is_closed_position: true,
        miner_hotkey: "5Hii8qtRUmPWvJZtGhxSS5CgGKvnwMY9yXuxoGXYidKGBJdS",
        net_leverage: 0.0,
        open_ms: 1711262189170,
        orders: [
          {
            leverage: 0.1,
            order_type: "LONG",
            order_uuid: "eee093f6-0df3-430b-9eae-624259507a5b",
            price: 64232.5,
            price_sources: [],
            processed_ms: 1711262189168,
          },
          {
            leverage: 0.1,
            order_type: "LONG",
            order_uuid: "84d79dd3-edb7-4312-9dcf-42a2b7a162c2",
            price: 64117.11,
            price_sources: [],
            processed_ms: 1711263093970,
          },
          {
            leverage: 0.1,
            order_type: "LONG",
            order_uuid: "7d210c0e-3b21-4197-b32a-1f8a138654c8",
            price: 64180.03,
            price_sources: [],
            processed_ms: 1711263992814,
          },
          {
            leverage: 0.1,
            order_type: "LONG",
            order_uuid: "d02bf950-0f01-4a9e-80d1-6199d14509a9",
            price: 64123.63,
            price_sources: [],
            processed_ms: 1711264890959,
          },
          {
            leverage: 0.1,
            order_type: "LONG",
            order_uuid: "504d3d72-0041-405e-b34e-76f0b6539d86",
            price: 64131.3,
            price_sources: [],
            processed_ms: 1711265787386,
          },
          {
            leverage: 0.1,
            order_type: "FLAT",
            order_uuid: "ec3da8bd-d65c-43c9-9504-4a951d67f484",
            price: 64279.16,
            price_sources: [],
            processed_ms: 1711266691793,
          },
        ],
        position_type: "FLAT",
        position_uuid: "2aad7e4d-1994-4008-acbc-9c731d24b83f",
        return_at_close: 0.9999506383372903,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 64729.22,
        close_ms: 1711314432745,
        current_return: 1.004938516492409,
        initial_entry_price: 64630.34,
        is_closed_position: true,
        miner_hotkey: "5Hii8qtRUmPWvJZtGhxSS5CgGKvnwMY9yXuxoGXYidKGBJdS",
        net_leverage: 0.0,
        open_ms: 1711267587894,
        orders: [
          {
            leverage: 0.1,
            order_type: "LONG",
            order_uuid: "644c706a-84b1-43ae-9cb0-6136d678d97a",
            price: 64630.34,
            price_sources: [],
            processed_ms: 1711267587889,
          },
          {
            leverage: 0.1,
            order_type: "LONG",
            order_uuid: "926a6a3e-ba0e-48df-8130-8a35585d9f25",
            price: 64828.1,
            price_sources: [],
            processed_ms: 1711268494950,
          },
          {
            leverage: -19.0,
            order_type: "SHORT",
            order_uuid: "dbb41866-3320-4060-8ccb-b09017e96cb5",
            price: 66325.11,
            price_sources: [],
            processed_ms: 1711314432745,
          },
        ],
        position_type: "FLAT",
        position_uuid: "acc861fc-339b-48ae-98ef-438df875eb69",
        return_at_close: 1.004536541085812,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 66315.63,
        close_ms: 1711317439751,
        current_return: 0.9931581740232308,
        initial_entry_price: 66315.63,
        is_closed_position: true,
        miner_hotkey: "5Hii8qtRUmPWvJZtGhxSS5CgGKvnwMY9yXuxoGXYidKGBJdS",
        net_leverage: 0.0,
        open_ms: 1711314774818,
        orders: [
          {
            leverage: -19.0,
            order_type: "SHORT",
            order_uuid: "86b4e995-b91a-44b1-9f98-49bf30350e33",
            price: 66315.63,
            price_sources: [],
            processed_ms: 1711314774813,
          },
          {
            leverage: 19.0,
            order_type: "FLAT",
            order_uuid: "520e9767-03d0-4212-b1f2-1e971c870dff",
            price: 66339.51,
            price_sources: [],
            processed_ms: 1711317439751,
          },
        ],
        position_type: "FLAT",
        position_uuid: "92902ffa-fcdf-43cd-a155-0afe5c95187f",
        return_at_close: 0.955418163410348,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 66552.29,
        close_ms: 1711317812451,
        current_return: 1.0458382423805437,
        initial_entry_price: 66552.29,
        is_closed_position: true,
        miner_hotkey: "5Hii8qtRUmPWvJZtGhxSS5CgGKvnwMY9yXuxoGXYidKGBJdS",
        net_leverage: 0.0,
        open_ms: 1711317589108,
        orders: [
          {
            leverage: 19.0,
            order_type: "LONG",
            order_uuid: "9aebdf96-c2c8-455d-9afe-34e900ae461a",
            price: 66552.29,
            price_sources: [],
            processed_ms: 1711317589106,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "68a53c81-e973-4942-bdc2-3dac39dc7620",
            price: 66712.85,
            price_sources: [],
            processed_ms: 1711317812451,
          },
        ],
        position_type: "FLAT",
        position_uuid: "d94717ca-3582-4360-bb10-162a5aec9c17",
        return_at_close: 1.006096389170083,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 67169.56,
        close_ms: 1711371581909,
        current_return: 1.0286402054740271,
        initial_entry_price: 67169.56,
        is_closed_position: true,
        miner_hotkey: "5Hii8qtRUmPWvJZtGhxSS5CgGKvnwMY9yXuxoGXYidKGBJdS",
        net_leverage: 0.0,
        open_ms: 1711370430572,
        orders: [
          {
            leverage: -15.0,
            order_type: "SHORT",
            order_uuid: "9960e67e-27d7-4dde-86d6-2a245ee19d7e",
            price: 67169.56,
            price_sources: [],
            processed_ms: 1711370430569,
          },
          {
            leverage: 15.0,
            order_type: "FLAT",
            order_uuid: "9a459e8c-7975-424c-8b69-a6a2481ad2df",
            price: 67041.31,
            price_sources: [],
            processed_ms: 1711371581909,
          },
        ],
        position_type: "FLAT",
        position_uuid: "3559f511-73a9-46d6-a47b-48ff96e1c257",
        return_at_close: 0.9977809993098062,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 0.8087666666666666,
        close_ms: 1711667944988,
        current_return: 1.0009893643334156,
        initial_entry_price: 0.8086,
        is_closed_position: true,
        miner_hotkey: "5Hii8qtRUmPWvJZtGhxSS5CgGKvnwMY9yXuxoGXYidKGBJdS",
        net_leverage: 0.0,
        open_ms: 1711667624895,
        orders: [
          {
            leverage: -1.0,
            order_type: "SHORT",
            order_uuid: "ec947c4f-7404-470e-b249-8790ab148576",
            price: 0.8086,
            price_sources: [],
            processed_ms: 1711667624893,
          },
          {
            leverage: -1.0,
            order_type: "SHORT",
            order_uuid: "03421b57-1215-40db-b4ad-4c903549cd05",
            price: 0.809,
            price_sources: [],
            processed_ms: 1711667732853,
          },
          {
            leverage: -1.0,
            order_type: "SHORT",
            order_uuid: "7247e084-b4cd-4d14-90b0-ff346c061fb0",
            price: 0.8087,
            price_sources: [],
            processed_ms: 1711667838153,
          },
          {
            leverage: 1.0,
            order_type: "FLAT",
            order_uuid: "08a971d7-19b7-491c-b11c-79f9f77939f4",
            price: 0.8085,
            price_sources: [],
            processed_ms: 1711667944988,
          },
        ],
        position_type: "FLAT",
        position_uuid: "e4642eca-907f-40b0-8e37-54bf40675b7a",
        return_at_close: 1.0007791565669055,
        trade_pair: ["NZDCAD", "NZD/CAD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 1.0787,
        close_ms: 1711731282920,
        current_return: 0.9074812274033661,
        initial_entry_price: 1.0787,
        is_closed_position: true,
        miner_hotkey: "5Hii8qtRUmPWvJZtGhxSS5CgGKvnwMY9yXuxoGXYidKGBJdS",
        net_leverage: -499.0,
        open_ms: 1711730717966,
        orders: [
          {
            leverage: -499.0,
            order_type: "SHORT",
            order_uuid: "818c31bf-e368-45d1-867f-bd3ffbc3ccff",
            price: 1.0787,
            price_sources: [],
            processed_ms: 1711730717961,
          },
        ],
        position_type: "FLAT",
        position_uuid: "fe25b40c-86d4-42db-bdeb-935e1b9c1d8d",
        return_at_close: 0.8757829081301666,
        trade_pair: ["EURUSD", "EUR/USD", 7e-5, 0.001, 500],
      },
    ],
    thirty_day_returns: 1.0,
  },
  "5His3c7GyUKpWgRpuWAZfHKxtszZLQuTSMaEWM4NbkS1wsNm": {
    all_time_returns: 1.0198432393256471,
    n_positions: 23,
    percentage_profitable: 0.9130434782608695,
    positions: [
      {
        average_entry_price: 0.64206,
        close_ms: 1713530028512,
        current_return: 1.0000747593682833,
        initial_entry_price: 0.64206,
        is_closed_position: true,
        miner_hotkey: "5His3c7GyUKpWgRpuWAZfHKxtszZLQuTSMaEWM4NbkS1wsNm",
        net_leverage: 0.0,
        open_ms: 1713529701521,
        orders: [
          {
            leverage: 0.15,
            order_type: "LONG",
            order_uuid: "bed5cd57-5c77-49de-b2e0-f6f34e960064",
            price: 0.64206,
            price_sources: [],
            processed_ms: 1713529701520,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "980c8bc3-c0d3-47cf-83ca-1a0b0e81cb65",
            price: 0.64238,
            price_sources: [],
            processed_ms: 1713530028512,
          },
        ],
        position_type: "FLAT",
        position_uuid: "a12eeb11-31da-4918-8be3-a80f88283534",
        return_at_close: 1.00006425858331,
        trade_pair: ["AUDUSD", "AUD/USD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 1.46598,
        close_ms: 1713530045083,
        current_return: 1.0000757172676298,
        initial_entry_price: 1.46598,
        is_closed_position: true,
        miner_hotkey: "5His3c7GyUKpWgRpuWAZfHKxtszZLQuTSMaEWM4NbkS1wsNm",
        net_leverage: 0.0,
        open_ms: 1713529738671,
        orders: [
          {
            leverage: 0.15,
            order_type: "LONG",
            order_uuid: "bf0f5ae3-e753-476f-bbf0-c5d3dac6e10c",
            price: 1.46598,
            price_sources: [],
            processed_ms: 1713529738670,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "241b8991-0c4e-4a1d-9139-a779f7bd0a5a",
            price: 1.46672,
            price_sources: [],
            processed_ms: 1713530045083,
          },
        ],
        position_type: "FLAT",
        position_uuid: "41855e02-b8c1-4a61-abed-76eb0a0c4b2a",
        return_at_close: 1.0000652164725985,
        trade_pair: ["EURCAD", "EUR/CAD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 99.223,
        close_ms: 1713530072356,
        current_return: 1.0000770990596939,
        initial_entry_price: 99.223,
        is_closed_position: true,
        miner_hotkey: "5His3c7GyUKpWgRpuWAZfHKxtszZLQuTSMaEWM4NbkS1wsNm",
        net_leverage: 0.0,
        open_ms: 1713529722527,
        orders: [
          {
            leverage: 0.15,
            order_type: "LONG",
            order_uuid: "3151a522-ff1e-46af-b752-840512bd8af7",
            price: 99.223,
            price_sources: [],
            processed_ms: 1713529722526,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "f5ee934b-3e59-489a-b988-98f4f12ba37b",
            price: 99.274,
            price_sources: [],
            processed_ms: 1713530072356,
          },
        ],
        position_type: "FLAT",
        position_uuid: "118aed1d-72c1-4ace-8a22-e1fd28406fca",
        return_at_close: 1.0000665982501538,
        trade_pair: ["AUDJPY", "AUD/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 91.099,
        close_ms: 1713530518791,
        current_return: 1.0000839745771084,
        initial_entry_price: 91.099,
        is_closed_position: true,
        miner_hotkey: "5His3c7GyUKpWgRpuWAZfHKxtszZLQuTSMaEWM4NbkS1wsNm",
        net_leverage: 0.0,
        open_ms: 1713529856584,
        orders: [
          {
            leverage: 0.15,
            order_type: "LONG",
            order_uuid: "54561fb1-3a3c-4a36-a6a5-e0b96011aa6b",
            price: 91.099,
            price_sources: [],
            processed_ms: 1713529855383,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "c699c70c-0c30-44c0-b6bd-b904ea3acaa7",
            price: 91.15,
            price_sources: [],
            processed_ms: 1713530518791,
          },
        ],
        position_type: "FLAT",
        position_uuid: "328693a8-fc41-41a4-9114-d61f842cb7a5",
        return_at_close: 1.0000734736953754,
        trade_pair: ["NZDJPY", "NZD/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.8560299999999998,
        close_ms: 1713530540558,
        current_return: 1.000057825076224,
        initial_entry_price: 0.85603,
        is_closed_position: true,
        miner_hotkey: "5His3c7GyUKpWgRpuWAZfHKxtszZLQuTSMaEWM4NbkS1wsNm",
        net_leverage: 0.0,
        open_ms: 1713529773182,
        orders: [
          {
            leverage: 0.15,
            order_type: "LONG",
            order_uuid: "ce672e51-99da-40d9-83b3-de7e2297981a",
            price: 0.85603,
            price_sources: [],
            processed_ms: 1713529773180,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "59febc7c-be10-48d7-b4bc-707961f456ec",
            price: 0.85636,
            price_sources: [],
            processed_ms: 1713530540558,
          },
        ],
        position_type: "FLAT",
        position_uuid: "a321a3ca-787d-4c5c-89dd-2da025b2782f",
        return_at_close: 1.0000473244690606,
        trade_pair: ["EURGBP", "EUR/GBP", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 154.509,
        close_ms: 1713530720521,
        current_return: 1.0000291245170185,
        initial_entry_price: 154.509,
        is_closed_position: true,
        miner_hotkey: "5His3c7GyUKpWgRpuWAZfHKxtszZLQuTSMaEWM4NbkS1wsNm",
        net_leverage: 0.0,
        open_ms: 1713529924421,
        orders: [
          {
            leverage: 0.15,
            order_type: "LONG",
            order_uuid: "785c3730-21d3-4b64-816c-3e7c390173bf",
            price: 154.509,
            price_sources: [],
            processed_ms: 1713529922473,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "464378a6-c599-467d-9ecf-1c4aa2cce1e0",
            price: 154.539,
            price_sources: [],
            processed_ms: 1713530720521,
          },
        ],
        position_type: "FLAT",
        position_uuid: "299866d9-d76b-45ea-845f-f9e91b24deae",
        return_at_close: 1.000018624211211,
        trade_pair: ["USDJPY", "USD/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.90804,
        close_ms: 1713531201982,
        current_return: 1.0000825954803754,
        initial_entry_price: 0.90804,
        is_closed_position: true,
        miner_hotkey: "5His3c7GyUKpWgRpuWAZfHKxtszZLQuTSMaEWM4NbkS1wsNm",
        net_leverage: 0.0,
        open_ms: 1713529905222,
        orders: [
          {
            leverage: -0.15,
            order_type: "SHORT",
            order_uuid: "3245a789-c4c9-4146-8546-63fc9d7394d5",
            price: 0.90804,
            price_sources: [],
            processed_ms: 1713529905221,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "ee46efb4-a447-4fa3-a055-80dac604c04a",
            price: 0.90754,
            price_sources: [],
            processed_ms: 1713531201982,
          },
        ],
        position_type: "FLAT",
        position_uuid: "1279eca5-2e55-4933-9e10-266de9fe3fe0",
        return_at_close: 1.0000720946131227,
        trade_pair: ["USDCHF", "USD/CHF", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 1.8086,
        close_ms: 1713531262337,
        current_return: 1.0000547384717462,
        initial_entry_price: 1.8086,
        is_closed_position: true,
        miner_hotkey: "5His3c7GyUKpWgRpuWAZfHKxtszZLQuTSMaEWM4NbkS1wsNm",
        net_leverage: 0.0,
        open_ms: 1713529805651,
        orders: [
          {
            leverage: 0.15,
            order_type: "LONG",
            order_uuid: "70099dd2-4a7e-439d-887b-05db9efa72a7",
            price: 1.8086,
            price_sources: [],
            processed_ms: 1713529805650,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "ac233f06-dca0-40e4-a92b-26093b906811",
            price: 1.80926,
            price_sources: [],
            processed_ms: 1713531262337,
          },
        ],
        position_type: "FLAT",
        position_uuid: "71e44e6d-271d-4f85-9e39-426758a50d1d",
        return_at_close: 1.0000442378969923,
        trade_pair: ["EURNZD", "EUR/NZD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 1.37462,
        close_ms: 1713531475842,
        current_return: 1.000128762858099,
        initial_entry_price: 1.37462,
        is_closed_position: true,
        miner_hotkey: "5His3c7GyUKpWgRpuWAZfHKxtszZLQuTSMaEWM4NbkS1wsNm",
        net_leverage: 0.0,
        open_ms: 1713529876990,
        orders: [
          {
            leverage: 0.15,
            order_type: "LONG",
            order_uuid: "9070f795-4917-405b-b3c8-0eb058e1679a",
            price: 1.37462,
            price_sources: [],
            processed_ms: 1713529876988,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "4087f2bd-0630-4478-b955-e5c8195afb27",
            price: 1.3758,
            price_sources: [],
            processed_ms: 1713531475842,
          },
        ],
        position_type: "FLAT",
        position_uuid: "bc7c9d3f-893d-4d79-b1be-fcb414a45ec1",
        return_at_close: 1.000118261506089,
        trade_pair: ["USDCAD", "USD/CAD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.8836672194752984,
        close_ms: 1713535395545,
        current_return: 1.0000717743648078,
        initial_entry_price: 0.88278,
        is_closed_position: true,
        miner_hotkey: "5His3c7GyUKpWgRpuWAZfHKxtszZLQuTSMaEWM4NbkS1wsNm",
        net_leverage: 0.0,
        open_ms: 1713529682983,
        orders: [
          {
            leverage: -0.15,
            order_type: "SHORT",
            order_uuid: "9baaa2ff-7b00-42ca-9d5c-ba8bf3b05159",
            price: 0.88278,
            price_sources: [],
            processed_ms: 1713529682983,
          },
          {
            leverage: -0.1,
            order_type: "SHORT",
            order_uuid: "a40705d3-819b-45b6-93a8-f1065a524641",
            price: 0.883003858673669,
            price_sources: [],
            processed_ms: 1713531777369,
          },
          {
            leverage: 0.2,
            order_type: "LONG",
            order_uuid: "e1e8d7ec-a392-4200-99a8-b5d9861aa010",
            price: 0.88267012446801,
            price_sources: [],
            processed_ms: 1713534602742,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "5d9f19b6-0108-4b49-b087-f41a2bba1bcc",
            price: 0.8824,
            price_sources: [],
            processed_ms: 1713535395545,
          },
        ],
        position_type: "FLAT",
        position_uuid: "77648177-a83c-4e74-8075-342723aa2401",
        return_at_close: 1.0000542731087563,
        trade_pair: ["AUDCAD", "AUD/CAD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.810456,
        close_ms: 1713535482411,
        current_return: 1.0001072924206769,
        initial_entry_price: 0.81034,
        is_closed_position: true,
        miner_hotkey: "5His3c7GyUKpWgRpuWAZfHKxtszZLQuTSMaEWM4NbkS1wsNm",
        net_leverage: 0.0,
        open_ms: 1713529838938,
        orders: [
          {
            leverage: -0.15,
            order_type: "SHORT",
            order_uuid: "7a03f604-0a8c-4e6c-9d71-992b343bc3e6",
            price: 0.81034,
            price_sources: [],
            processed_ms: 1713529838937,
          },
          {
            leverage: -0.1,
            order_type: "SHORT",
            order_uuid: "7500b588-3ef5-4ab9-a1d8-84758300c543",
            price: 0.81063,
            price_sources: [],
            processed_ms: 1713531760648,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "d999b483-3ddc-4fb6-84fc-9a8f82157319",
            price: 0.810108226639315,
            price_sources: [],
            processed_ms: 1713535482411,
          },
        ],
        position_type: "FLAT",
        position_uuid: "2b36ba1e-fac0-48ab-bed8-98672ed985b1",
        return_at_close: 1.0000897905430595,
        trade_pair: ["NZDCAD", "NZD/CAD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 62893.02000000001,
        close_ms: 1713644964102,
        current_return: 1.0001446757007568,
        initial_entry_price: 65280.14,
        is_closed_position: true,
        miner_hotkey: "5His3c7GyUKpWgRpuWAZfHKxtszZLQuTSMaEWM4NbkS1wsNm",
        net_leverage: 0.0,
        open_ms: 1713529958848,
        orders: [
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "3fdede29-3126-4cc6-8281-6e55b694ede8",
            price: 65280.14,
            price_sources: [],
            processed_ms: 1713529958846,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "2d385fb1-6dc4-484d-a941-9c1f4a10558c",
            price: 64852.0,
            price_sources: [],
            processed_ms: 1713532000414,
          },
          {
            leverage: 0.01,
            order_type: "LONG",
            order_uuid: "b73705ff-cdee-4108-9e15-f20df4e9c4f1",
            price: 64749.9,
            price_sources: [],
            processed_ms: 1713533400458,
          },
          {
            leverage: 0.03,
            order_type: "LONG",
            order_uuid: "8bc8e7af-a8bb-4f8d-9d16-eb9050f25b1e",
            price: 64539.84,
            price_sources: [],
            processed_ms: 1713548599563,
          },
          {
            leverage: -0.055,
            order_type: "SHORT",
            order_uuid: "71d79000-eb65-48b6-8dad-fb85d7957d7b",
            price: 64919.1,
            price_sources: [],
            processed_ms: 1713643520383,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "d5678e62-2309-43c4-ba18-51b95a321a63",
            price: 64781.91,
            price_sources: [],
            processed_ms: 1713644964102,
          },
        ],
        position_type: "FLAT",
        position_uuid: "7e6a0f8c-28aa-4ff1-bdc6-30e0cfd4ea3d",
        return_at_close: 1.0000846670202148,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 154.23299999999983,
        close_ms: 1713821673411,
        current_return: 1.0000192786673137,
        initial_entry_price: 154.575,
        is_closed_position: true,
        miner_hotkey: "5His3c7GyUKpWgRpuWAZfHKxtszZLQuTSMaEWM4NbkS1wsNm",
        net_leverage: 0.0,
        open_ms: 1713534118195,
        orders: [
          {
            leverage: 0.1,
            order_type: "LONG",
            order_uuid: "30e3932f-c012-4edb-8169-6b483584b098",
            price: 154.575,
            price_sources: [],
            processed_ms: 1713534118192,
          },
          {
            leverage: -0.095,
            order_type: "SHORT",
            order_uuid: "f097c15d-3256-47ab-8bf8-fc136e789e8b",
            price: 154.593,
            price_sources: [],
            processed_ms: 1713535996456,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "9a5d42b4-1613-4190-9baa-5ce6fa9fe449",
            price: 154.829,
            price_sources: [],
            processed_ms: 1713821673411,
          },
        ],
        position_type: "FLAT",
        position_uuid: "0ffab5fd-c110-4a14-a077-169f2d00325c",
        return_at_close: 1.000012278532363,
        trade_pair: ["USDJPY", "USD/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 1.0875400000000026,
        close_ms: 1714155575465,
        current_return: 1.0001665885001407,
        initial_entry_price: 1.0661,
        is_closed_position: true,
        miner_hotkey: "5His3c7GyUKpWgRpuWAZfHKxtszZLQuTSMaEWM4NbkS1wsNm",
        net_leverage: 0.0,
        open_ms: 1713529755952,
        orders: [
          {
            leverage: -0.15,
            order_type: "SHORT",
            order_uuid: "f2acc81c-568c-431d-988b-f051bbfac82b",
            price: 1.0661,
            price_sources: [],
            processed_ms: 1713529755951,
          },
          {
            leverage: -0.1,
            order_type: "SHORT",
            order_uuid: "a801b444-9547-47c4-b260-e133ff9ee7de",
            price: 1.06666,
            price_sources: [],
            processed_ms: 1713531313742,
          },
          {
            leverage: 0.24,
            order_type: "LONG",
            order_uuid: "dcf15c35-5cc8-4de5-abfd-ab2fc6b9c643",
            price: 1.06544,
            price_sources: [],
            processed_ms: 1713546401561,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "ede87f35-fc34-4f64-81b7-1bbc40605cbf",
            price: 1.06978,
            price_sources: [],
            processed_ms: 1714155575465,
          },
        ],
        position_type: "FLAT",
        position_uuid: "bd3b05b1-2be5-4403-9456-d49858992420",
        return_at_close: 1.000149085584842,
        trade_pair: ["EURUSD", "EUR/USD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 176.7750000000005,
        close_ms: 1714159611748,
        current_return: 1.000482988861574,
        initial_entry_price: 164.745,
        is_closed_position: true,
        miner_hotkey: "5His3c7GyUKpWgRpuWAZfHKxtszZLQuTSMaEWM4NbkS1wsNm",
        net_leverage: 0.0,
        open_ms: 1713529790135,
        orders: [
          {
            leverage: -0.15,
            order_type: "SHORT",
            order_uuid: "e397ca4e-9274-4bde-b2fb-8b562a8fbd8c",
            price: 164.745,
            price_sources: [],
            processed_ms: 1713529789662,
          },
          {
            leverage: -0.1,
            order_type: "SHORT",
            order_uuid: "5b53437f-c352-4eb8-9046-5a55ff48afd3",
            price: 164.825,
            price_sources: [],
            processed_ms: 1713531296690,
          },
          {
            leverage: -0.5,
            order_type: "SHORT",
            order_uuid: "8a03cd5c-7b48-4299-83d4-c3518af77828",
            price: 164.903,
            price_sources: [],
            processed_ms: 1713533204601,
          },
          {
            leverage: 0.74,
            order_type: "LONG",
            order_uuid: "cb3db4a1-3bc8-47db-9899-7ff409d62272",
            price: 164.7,
            price_sources: [],
            processed_ms: 1713546366008,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "a49ada35-3867-4d4a-889f-64b3ef66d514",
            price: 168.818,
            price_sources: [],
            processed_ms: 1714159611748,
          },
        ],
        position_type: "FLAT",
        position_uuid: "6c424483-a238-4bdd-b630-fb7bc414e2ea",
        return_at_close: 1.0004304635046588,
        trade_pair: ["EURJPY", "EUR/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 1.8027800000000003,
        close_ms: 1714481071234,
        current_return: 1.0002471183394535,
        initial_entry_price: 1.80278,
        is_closed_position: true,
        miner_hotkey: "5His3c7GyUKpWgRpuWAZfHKxtszZLQuTSMaEWM4NbkS1wsNm",
        net_leverage: 0.0,
        open_ms: 1714479640800,
        orders: [
          {
            leverage: 0.15,
            order_type: "LONG",
            order_uuid: "21999b9e-b0fe-4451-9674-87e83effe46f",
            price: 1.80278,
            price_sources: [],
            processed_ms: 1714479640794,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "1759064a-b693-4654-9b0d-dfeb8a660963",
            price: 1.80575,
            price_sources: [],
            processed_ms: 1714481071234,
          },
        ],
        position_type: "FLAT",
        position_uuid: "1332ca6f-8fbd-4db5-8d2e-269211737fcf",
        return_at_close: 1.000236615744711,
        trade_pair: ["EURNZD", "EUR/NZD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 1.46686,
        close_ms: 1714481087608,
        current_return: 1.0002229251598653,
        initial_entry_price: 1.46686,
        is_closed_position: true,
        miner_hotkey: "5His3c7GyUKpWgRpuWAZfHKxtszZLQuTSMaEWM4NbkS1wsNm",
        net_leverage: 0.0,
        open_ms: 1714479576748,
        orders: [
          {
            leverage: 0.15,
            order_type: "LONG",
            order_uuid: "d5e7d2bc-cd58-44b2-8f38-8b30d59279ea",
            price: 1.46686,
            price_sources: [],
            processed_ms: 1714479576644,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "8ecf2e02-f103-4219-9e9d-361e3aac5c4b",
            price: 1.46904,
            price_sources: [],
            processed_ms: 1714481087608,
          },
        ],
        position_type: "FLAT",
        position_uuid: "e81ff103-7abe-4aa1-81fd-b6e55a7c9a01",
        return_at_close: 1.0002124228191511,
        trade_pair: ["EURCAD", "EUR/CAD", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 0.8547399999999999,
        close_ms: 1714491812016,
        current_return: 1.0001439034092239,
        initial_entry_price: 0.85474,
        is_closed_position: true,
        miner_hotkey: "5His3c7GyUKpWgRpuWAZfHKxtszZLQuTSMaEWM4NbkS1wsNm",
        net_leverage: 0.0,
        open_ms: 1714479608111,
        orders: [
          {
            leverage: -0.15,
            order_type: "SHORT",
            order_uuid: "32a6d9d9-e552-468a-b018-e76775a94c55",
            price: 0.85474,
            price_sources: [],
            processed_ms: 1714479608108,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "04e35cb3-02ab-4ff3-b129-5c4f77673400",
            price: 0.85392,
            price_sources: [],
            processed_ms: 1714491812016,
          },
        ],
        position_type: "FLAT",
        position_uuid: "3d485e0a-8df8-4423-b00a-c60eaa248e76",
        return_at_close: 1.000133401898238,
        trade_pair: ["EURGBP", "EUR/GBP", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 168.27,
        close_ms: 1714567803968,
        current_return: 1.0001824448802519,
        initial_entry_price: 168.27,
        is_closed_position: true,
        miner_hotkey: "5His3c7GyUKpWgRpuWAZfHKxtszZLQuTSMaEWM4NbkS1wsNm",
        net_leverage: 0.0,
        open_ms: 1714479624293,
        orders: [
          {
            leverage: 0.1,
            order_type: "LONG",
            order_uuid: "d516868a-d50c-463d-a089-9e01995a66bf",
            price: 168.27,
            price_sources: [],
            processed_ms: 1714479624289,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "503200e4-53d1-4452-b714-bac778e722d3",
            price: 168.577,
            price_sources: [],
            processed_ms: 1714567803968,
          },
        ],
        position_type: "FLAT",
        position_uuid: "acc8c8d7-ca1f-42f9-8bcf-fedd8005987d",
        return_at_close: 1.0001754436031378,
        trade_pair: ["EURJPY", "EUR/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 157.36207692307693,
        close_ms: 1714593836392,
        current_return: 1.023432886055208,
        initial_entry_price: 157.471,
        is_closed_position: true,
        miner_hotkey: "5His3c7GyUKpWgRpuWAZfHKxtszZLQuTSMaEWM4NbkS1wsNm",
        net_leverage: 0.0,
        open_ms: 1714587793441,
        orders: [
          {
            leverage: 10.0,
            order_type: "LONG",
            order_uuid: "1f9f50da-298f-4658-9e43-ad88ff093288",
            price: 157.471,
            price_sources: [],
            processed_ms: 1714587789957,
          },
          {
            leverage: 8.0,
            order_type: "LONG",
            order_uuid: "a5a3ea8b-388f-4e02-916e-54260a56fa57",
            price: 157.451,
            price_sources: [],
            processed_ms: 1714587897541,
          },
          {
            leverage: 8.0,
            order_type: "LONG",
            order_uuid: "cfb4cbc3-c231-48a6-b637-d494e9b3e9f0",
            price: 157.137,
            price_sources: [],
            processed_ms: 1714588998729,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "9b050373-28ba-4f06-b4bb-9e94e42cf3c5",
            price: 157.504,
            price_sources: [],
            processed_ms: 1714593836392,
          },
        ],
        position_type: "FLAT",
        position_uuid: "d969e3b9-ac22-4749-99a3-2418e23ea43d",
        return_at_close: 1.0215702382025875,
        trade_pair: ["USDJPY", "USD/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 1.0164500000000012,
        close_ms: 1714973154781,
        current_return: 1.0004230922525064,
        initial_entry_price: 0.97851,
        is_closed_position: true,
        miner_hotkey: "5His3c7GyUKpWgRpuWAZfHKxtszZLQuTSMaEWM4NbkS1wsNm",
        net_leverage: 0.0,
        open_ms: 1714479592228,
        orders: [
          {
            leverage: -0.15,
            order_type: "SHORT",
            order_uuid: "ec786d85-26fb-4325-8a14-34b25dc43790",
            price: 0.97851,
            price_sources: [],
            processed_ms: 1714479592225,
          },
          {
            leverage: 0.14,
            order_type: "LONG",
            order_uuid: "ac8c96f2-3261-417f-9630-215077d74c87",
            price: 0.9758,
            price_sources: [],
            processed_ms: 1714650176867,
          },
          {
            leverage: 0.0,
            order_type: "FLAT",
            order_uuid: "0c0e9c12-7bfc-4712-92be-b7f8f40a4795",
            price: 0.97505,
            price_sources: [],
            processed_ms: 1714973154781,
          },
        ],
        position_type: "FLAT",
        position_uuid: "959d252d-52d1-4045-a499-a28e7afc83c5",
        return_at_close: 1.0004125878100376,
        trade_pair: ["EURCHF", "EUR/CHF", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 63276.0,
        close_ms: 1717620694109,
        current_return: 0.9998736588912068,
        initial_entry_price: 63276.0,
        is_closed_position: true,
        miner_hotkey: "5His3c7GyUKpWgRpuWAZfHKxtszZLQuTSMaEWM4NbkS1wsNm",
        net_leverage: 0.0,
        open_ms: 1714814038475,
        orders: [
          {
            leverage: -0.001,
            order_type: "SHORT",
            order_uuid: "d7485a9d-194b-4d19-8f52-d3f7ba551134",
            price: 63276.0,
            price_sources: [],
            processed_ms: 1714814038286,
          },
          {
            leverage: 0.2,
            order_type: "LONG",
            order_uuid: "38832ad4-55ce-41c7-a4d9-5d7e4dc7a8de",
            price: 71270.36,
            price_sources: [],
            processed_ms: 1717620694109,
          },
        ],
        position_type: "FLAT",
        position_uuid: "f64db517-d54e-4c3d-9131-cadae005b502",
        return_at_close: 0.999872659017548,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 103.22599999999731,
        close_ms: 1719926348511,
        current_return: 0.9962317378397779,
        initial_entry_price: 154.623,
        is_closed_position: true,
        miner_hotkey: "5His3c7GyUKpWgRpuWAZfHKxtszZLQuTSMaEWM4NbkS1wsNm",
        net_leverage: 0.0,
        open_ms: 1714655166874,
        orders: [
          {
            leverage: -5.0,
            order_type: "SHORT",
            order_uuid: "23f0a95b-c98d-47fc-9b4a-e9ceb4983235",
            price: 154.623,
            price_sources: [],
            processed_ms: 1714655166869,
          },
          {
            leverage: 4.99,
            order_type: "LONG",
            order_uuid: "fb495b99-f36d-4247-ab2a-1175f1c3cd41",
            price: 154.726,
            price_sources: [],
            processed_ms: 1714655537955,
          },
          {
            leverage: 0.17135070175968686,
            order_type: "LONG",
            order_uuid: "b0500bbe-41f6-432e-a121-7bcb5037a435",
            price: 161.492,
            price_sources: [],
            processed_ms: 1719926348511,
          },
        ],
        position_type: "FLAT",
        position_uuid: "cc2f5924-978a-44eb-8703-11879dce27c0",
        return_at_close: 0.995883056731534,
        trade_pair: ["USDJPY", "USD/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 161.36700000000002,
        close_ms: 0,
        current_return: 1.0003401801969198,
        initial_entry_price: 161.36700000000002,
        is_closed_position: false,
        miner_hotkey: "5His3c7GyUKpWgRpuWAZfHKxtszZLQuTSMaEWM4NbkS1wsNm",
        net_leverage: 0.16435286777353247,
        open_ms: 1719928048390,
        orders: [
          {
            leverage: 0.16435286777353247,
            order_type: "LONG",
            order_uuid: "a5ad75e7-bd16-4739-9e56-12230d109693",
            price: 161.36700000000002,
            price_sources: [],
            processed_ms: 1719928048284,
          },
        ],
        position_type: "LONG",
        position_uuid: "a5ad75e7-bd16-4739-9e56-12230d109693",
        return_at_close: 1.0003344258897122,
        trade_pair: ["USDJPY", "USD/JPY", 7e-5, 0.001, 500],
      },
      {
        average_entry_price: 3090.6,
        close_ms: 0,
        current_return: 1.0149970879440886,
        initial_entry_price: 3090.6,
        is_closed_position: false,
        miner_hotkey: "5His3c7GyUKpWgRpuWAZfHKxtszZLQuTSMaEWM4NbkS1wsNm",
        net_leverage: 1.0,
        open_ms: 1716176153357,
        orders: [
          {
            leverage: 1.0,
            order_type: "LONG",
            order_uuid: "e195002c-1b2f-4137-aa3b-b93f8ded79c9",
            price: 3090.6,
            price_sources: [],
            processed_ms: 1716176152687,
          },
        ],
        position_type: "LONG",
        position_uuid: "7d799b63-2354-4d53-aed6-96ec36758eee",
        return_at_close: 1.0144895894001167,
        trade_pair: ["ETHUSD", "ETH/USD", 0.003, 0.001, 20],
      },
    ],
    thirty_day_returns: 1.0,
  },
  "5HjCQByfDVLtdoaHZgANUweHVmghKeh5fKDjK1UL2YVsKgkG": {
    all_time_returns: 1.0,
    n_positions: 0,
    percentage_profitable: 0.0,
    positions: [
      {
        average_entry_price: 70499.90499999998,
        close_ms: 1711544107396,
        current_return: 0.9654213464154408,
        initial_entry_price: 70551.33,
        is_closed_position: true,
        miner_hotkey: "5HjCQByfDVLtdoaHZgANUweHVmghKeh5fKDjK1UL2YVsKgkG",
        net_leverage: 0.0,
        open_ms: 1711462199459,
        orders: [
          {
            leverage: 1.0,
            order_type: "LONG",
            order_uuid: "b8f6dcba-5323-4f51-bbce-c6d995f14a22",
            price: 70551.33,
            price_sources: [],
            processed_ms: 1711462199458,
          },
          {
            leverage: 1.0,
            order_type: "LONG",
            order_uuid: "4691d752-d750-4d9e-84b7-9ae066af2717",
            price: 70551.33,
            price_sources: [],
            processed_ms: 1711462201351,
          },
          {
            leverage: 1.0,
            order_type: "LONG",
            order_uuid: "1bb61a7c-edad-4619-bcd5-5df3a4a172b8",
            price: 70551.33,
            price_sources: [],
            processed_ms: 1711462202176,
          },
          {
            leverage: 1.0,
            order_type: "LONG",
            order_uuid: "27728fe9-e7b6-4677-bf60-6bdf63500f9e",
            price: 70448.48,
            price_sources: [],
            processed_ms: 1711462244632,
          },
          {
            leverage: 1.0,
            order_type: "LONG",
            order_uuid: "ea5b79ac-871b-40d9-b08b-e28d2bb0fd5f",
            price: 70448.48,
            price_sources: [],
            processed_ms: 1711462244864,
          },
          {
            leverage: 1.0,
            order_type: "LONG",
            order_uuid: "c470cd54-e6e5-421d-a4a9-760c9e7d034b",
            price: 70448.48,
            price_sources: [],
            processed_ms: 1711462245057,
          },
          {
            leverage: 6.0,
            order_type: "FLAT",
            order_uuid: "35d1e165-3fcc-405f-a93c-b007df770c3e",
            price: 70093.31,
            price_sources: [],
            processed_ms: 1711544107396,
          },
        ],
        position_type: "FLAT",
        position_uuid: "68c3f2ce-ae3f-4444-bdd0-dab82fb68d6c",
        return_at_close: 0.9538362902584554,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
      {
        average_entry_price: 70245.43,
        close_ms: 1712045897507,
        current_return: 0.9454138155321992,
        initial_entry_price: 70245.43,
        is_closed_position: true,
        miner_hotkey: "5HjCQByfDVLtdoaHZgANUweHVmghKeh5fKDjK1UL2YVsKgkG",
        net_leverage: 1.0,
        open_ms: 1711544537183,
        orders: [
          {
            leverage: 1.0,
            order_type: "LONG",
            order_uuid: "c60bcc7a-863e-4573-9a63-1f3c01bfe534",
            price: 70245.43,
            price_sources: [],
            processed_ms: 1711544537183,
          },
        ],
        position_type: "FLAT",
        position_uuid: "f7135f0a-78fe-4d68-9eb3-8513f12ae369",
        return_at_close: 0.9435229879011348,
        trade_pair: ["BTCUSD", "BTC/USD", 0.003, 0.001, 20],
      },
    ],
    thirty_day_returns: 1.0,
  },
};

export const mockDataConstants = {
  annual_risk_free_rate: 0.08,
};
